import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseNotification, NotificationResult } from '../models/notification.model';

export interface NotificationTemplatesEvent {
	item: BaseNotification;
	type: 'add' | 'remove';
}

@Injectable({
	providedIn: 'root',
})
export class NotificationTemplatesService {
	public notificationTemplates$ = new Subject<NotificationTemplatesEvent>();
	public notificationResult$ = new Subject<NotificationResult>();
}
