import { HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HttpServiceHandler, HttpServiceInterceptor } from '../../core/http/http-request-handler';
import { SpinnerService } from './spinner.service';

@Injectable()
export class SpinnerHttpInterceptorService implements HttpServiceInterceptor {

	constructor(private spinnerService: SpinnerService) {}

	intercept(req: HttpRequest<any>, options: { spinner?: string }, next: HttpServiceHandler): Observable<HttpEvent<any>> {
		if (options.spinner) {
			this.spinnerService.onStart(options.spinner);
			return next.handle(req, options).pipe(
				tap(() => {}, () => {
					this.spinnerService.onError(options.spinner);
				}),
				finalize(() => {
					this.spinnerService.onStop(options.spinner);
				})
			);
		} else {
			return next.handle(req, options);
		}
	}
}
