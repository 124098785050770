import { __ } from 'seed/lib/i18n';

export const identifierSchemeCodeUntdid7143 = {
	'identifierSchemeCodeUntdid7143.AA': __('identifierSchemeCodeUntdid7143.AA'),
	'identifierSchemeCodeUntdid7143.AB': __('identifierSchemeCodeUntdid7143.AB'),
	'identifierSchemeCodeUntdid7143.AC': __('identifierSchemeCodeUntdid7143.AC'),
	'identifierSchemeCodeUntdid7143.AD': __('identifierSchemeCodeUntdid7143.AD'),
	'identifierSchemeCodeUntdid7143.AE': __('identifierSchemeCodeUntdid7143.AE'),
	'identifierSchemeCodeUntdid7143.AF': __('identifierSchemeCodeUntdid7143.AF'),
	'identifierSchemeCodeUntdid7143.AG': __('identifierSchemeCodeUntdid7143.AG'),
	'identifierSchemeCodeUntdid7143.AH': __('identifierSchemeCodeUntdid7143.AH'),
	'identifierSchemeCodeUntdid7143.AI': __('identifierSchemeCodeUntdid7143.AI'),
	'identifierSchemeCodeUntdid7143.AJ': __('identifierSchemeCodeUntdid7143.AJ'),
	'identifierSchemeCodeUntdid7143.AK': __('identifierSchemeCodeUntdid7143.AK'),
	'identifierSchemeCodeUntdid7143.AL': __('identifierSchemeCodeUntdid7143.AL'),
	'identifierSchemeCodeUntdid7143.AM': __('identifierSchemeCodeUntdid7143.AM'),
	'identifierSchemeCodeUntdid7143.AN': __('identifierSchemeCodeUntdid7143.AN'),
	'identifierSchemeCodeUntdid7143.AO': __('identifierSchemeCodeUntdid7143.AO'),
	'identifierSchemeCodeUntdid7143.AP': __('identifierSchemeCodeUntdid7143.AP'),
	'identifierSchemeCodeUntdid7143.AQ': __('identifierSchemeCodeUntdid7143.AQ'),
	'identifierSchemeCodeUntdid7143.AR': __('identifierSchemeCodeUntdid7143.AR'),
	'identifierSchemeCodeUntdid7143.AS': __('identifierSchemeCodeUntdid7143.AS'),
	'identifierSchemeCodeUntdid7143.AT': __('identifierSchemeCodeUntdid7143.AT'),
	'identifierSchemeCodeUntdid7143.AU': __('identifierSchemeCodeUntdid7143.AU'),
	'identifierSchemeCodeUntdid7143.AV': __('identifierSchemeCodeUntdid7143.AV'),
	'identifierSchemeCodeUntdid7143.AW': __('identifierSchemeCodeUntdid7143.AW'),
	'identifierSchemeCodeUntdid7143.AX': __('identifierSchemeCodeUntdid7143.AX'),
	'identifierSchemeCodeUntdid7143.AY': __('identifierSchemeCodeUntdid7143.AY'),
	'identifierSchemeCodeUntdid7143.AZ': __('identifierSchemeCodeUntdid7143.AZ'),
	'identifierSchemeCodeUntdid7143.BA': __('identifierSchemeCodeUntdid7143.BA'),
	'identifierSchemeCodeUntdid7143.BB': __('identifierSchemeCodeUntdid7143.BB'),
	'identifierSchemeCodeUntdid7143.BC': __('identifierSchemeCodeUntdid7143.BC'),
	'identifierSchemeCodeUntdid7143.BD': __('identifierSchemeCodeUntdid7143.BD'),
	'identifierSchemeCodeUntdid7143.BE': __('identifierSchemeCodeUntdid7143.BE'),
	'identifierSchemeCodeUntdid7143.BF': __('identifierSchemeCodeUntdid7143.BF'),
	'identifierSchemeCodeUntdid7143.BG': __('identifierSchemeCodeUntdid7143.BG'),
	'identifierSchemeCodeUntdid7143.BH': __('identifierSchemeCodeUntdid7143.BH'),
	'identifierSchemeCodeUntdid7143.BI': __('identifierSchemeCodeUntdid7143.BI'),
	'identifierSchemeCodeUntdid7143.BJ': __('identifierSchemeCodeUntdid7143.BJ'),
	'identifierSchemeCodeUntdid7143.BK': __('identifierSchemeCodeUntdid7143.BK'),
	'identifierSchemeCodeUntdid7143.BL': __('identifierSchemeCodeUntdid7143.BL'),
	'identifierSchemeCodeUntdid7143.BM': __('identifierSchemeCodeUntdid7143.BM'),
	'identifierSchemeCodeUntdid7143.BN': __('identifierSchemeCodeUntdid7143.BN'),
	'identifierSchemeCodeUntdid7143.BO': __('identifierSchemeCodeUntdid7143.BO'),
	'identifierSchemeCodeUntdid7143.BP': __('identifierSchemeCodeUntdid7143.BP'),
	'identifierSchemeCodeUntdid7143.BQ': __('identifierSchemeCodeUntdid7143.BQ'),
	'identifierSchemeCodeUntdid7143.BR': __('identifierSchemeCodeUntdid7143.BR'),
	'identifierSchemeCodeUntdid7143.BS': __('identifierSchemeCodeUntdid7143.BS'),
	'identifierSchemeCodeUntdid7143.BT': __('identifierSchemeCodeUntdid7143.BT'),
	'identifierSchemeCodeUntdid7143.BU': __('identifierSchemeCodeUntdid7143.BU'),
	'identifierSchemeCodeUntdid7143.BV': __('identifierSchemeCodeUntdid7143.BV'),
	'identifierSchemeCodeUntdid7143.BW': __('identifierSchemeCodeUntdid7143.BW'),
	'identifierSchemeCodeUntdid7143.BX': __('identifierSchemeCodeUntdid7143.BX'),
	'identifierSchemeCodeUntdid7143.BY': __('identifierSchemeCodeUntdid7143.BY'),
	'identifierSchemeCodeUntdid7143.BZ': __('identifierSchemeCodeUntdid7143.BZ'),
	'identifierSchemeCodeUntdid7143.CC': __('identifierSchemeCodeUntdid7143.CC'),
	'identifierSchemeCodeUntdid7143.CG': __('identifierSchemeCodeUntdid7143.CG'),
	'identifierSchemeCodeUntdid7143.CL': __('identifierSchemeCodeUntdid7143.CL'),
	'identifierSchemeCodeUntdid7143.CR': __('identifierSchemeCodeUntdid7143.CR'),
	'identifierSchemeCodeUntdid7143.CV': __('identifierSchemeCodeUntdid7143.CV'),
	'identifierSchemeCodeUntdid7143.DR': __('identifierSchemeCodeUntdid7143.DR'),
	'identifierSchemeCodeUntdid7143.DW': __('identifierSchemeCodeUntdid7143.DW'),
	'identifierSchemeCodeUntdid7143.EC': __('identifierSchemeCodeUntdid7143.EC'),
	'identifierSchemeCodeUntdid7143.EF': __('identifierSchemeCodeUntdid7143.EF'),
	'identifierSchemeCodeUntdid7143.EN': __('identifierSchemeCodeUntdid7143.EN'),
	'identifierSchemeCodeUntdid7143.FS': __('identifierSchemeCodeUntdid7143.FS'),
	'identifierSchemeCodeUntdid7143.GB': __('identifierSchemeCodeUntdid7143.GB'),
	'identifierSchemeCodeUntdid7143.GN': __('identifierSchemeCodeUntdid7143.GN'),
	'identifierSchemeCodeUntdid7143.GS': __('identifierSchemeCodeUntdid7143.GS'),
	'identifierSchemeCodeUntdid7143.HS': __('identifierSchemeCodeUntdid7143.HS'),
	'identifierSchemeCodeUntdid7143.IB': __('identifierSchemeCodeUntdid7143.IB'),
	'identifierSchemeCodeUntdid7143.IN': __('identifierSchemeCodeUntdid7143.IN'),
	'identifierSchemeCodeUntdid7143.IS': __('identifierSchemeCodeUntdid7143.IS'),
	'identifierSchemeCodeUntdid7143.IT': __('identifierSchemeCodeUntdid7143.IT'),
	'identifierSchemeCodeUntdid7143.IZ': __('identifierSchemeCodeUntdid7143.IZ'),
	'identifierSchemeCodeUntdid7143.MA': __('identifierSchemeCodeUntdid7143.MA'),
	'identifierSchemeCodeUntdid7143.MF': __('identifierSchemeCodeUntdid7143.MF'),
	'identifierSchemeCodeUntdid7143.MN': __('identifierSchemeCodeUntdid7143.MN'),
	'identifierSchemeCodeUntdid7143.MP': __('identifierSchemeCodeUntdid7143.MP'),
	'identifierSchemeCodeUntdid7143.NB': __('identifierSchemeCodeUntdid7143.NB'),
	'identifierSchemeCodeUntdid7143.ON': __('identifierSchemeCodeUntdid7143.ON'),
	'identifierSchemeCodeUntdid7143.PD': __('identifierSchemeCodeUntdid7143.PD'),
	'identifierSchemeCodeUntdid7143.PL': __('identifierSchemeCodeUntdid7143.PL'),
	'identifierSchemeCodeUntdid7143.PO': __('identifierSchemeCodeUntdid7143.PO'),
	'identifierSchemeCodeUntdid7143.PV': __('identifierSchemeCodeUntdid7143.PV'),
	'identifierSchemeCodeUntdid7143.QS': __('identifierSchemeCodeUntdid7143.QS'),
	'identifierSchemeCodeUntdid7143.RC': __('identifierSchemeCodeUntdid7143.RC'),
	'identifierSchemeCodeUntdid7143.RN': __('identifierSchemeCodeUntdid7143.RN'),
	'identifierSchemeCodeUntdid7143.RU': __('identifierSchemeCodeUntdid7143.RU'),
	'identifierSchemeCodeUntdid7143.RY': __('identifierSchemeCodeUntdid7143.RY'),
	'identifierSchemeCodeUntdid7143.SA': __('identifierSchemeCodeUntdid7143.SA'),
	'identifierSchemeCodeUntdid7143.SG': __('identifierSchemeCodeUntdid7143.SG'),
	'identifierSchemeCodeUntdid7143.SK': __('identifierSchemeCodeUntdid7143.SK'),
	'identifierSchemeCodeUntdid7143.SN': __('identifierSchemeCodeUntdid7143.SN'),
	'identifierSchemeCodeUntdid7143.SRS': __('identifierSchemeCodeUntdid7143.SRS'),
	'identifierSchemeCodeUntdid7143.SRT': __('identifierSchemeCodeUntdid7143.SRT'),
	'identifierSchemeCodeUntdid7143.SRU': __('identifierSchemeCodeUntdid7143.SRU'),
	'identifierSchemeCodeUntdid7143.SRV': __('identifierSchemeCodeUntdid7143.SRV'),
	'identifierSchemeCodeUntdid7143.SRW': __('identifierSchemeCodeUntdid7143.SRW'),
	'identifierSchemeCodeUntdid7143.SRX': __('identifierSchemeCodeUntdid7143.SRX'),
	'identifierSchemeCodeUntdid7143.SRY': __('identifierSchemeCodeUntdid7143.SRY'),
	'identifierSchemeCodeUntdid7143.SRZ': __('identifierSchemeCodeUntdid7143.SRZ'),
	'identifierSchemeCodeUntdid7143.SS': __('identifierSchemeCodeUntdid7143.SS'),
	'identifierSchemeCodeUntdid7143.SSA': __('identifierSchemeCodeUntdid7143.SSA'),
	'identifierSchemeCodeUntdid7143.SSB': __('identifierSchemeCodeUntdid7143.SSB'),
	'identifierSchemeCodeUntdid7143.SSC': __('identifierSchemeCodeUntdid7143.SSC'),
	'identifierSchemeCodeUntdid7143.SSD': __('identifierSchemeCodeUntdid7143.SSD'),
	'identifierSchemeCodeUntdid7143.SSE': __('identifierSchemeCodeUntdid7143.SSE'),
	'identifierSchemeCodeUntdid7143.SSF': __('identifierSchemeCodeUntdid7143.SSF'),
	'identifierSchemeCodeUntdid7143.SSG': __('identifierSchemeCodeUntdid7143.SSG'),
	'identifierSchemeCodeUntdid7143.SSH': __('identifierSchemeCodeUntdid7143.SSH'),
	'identifierSchemeCodeUntdid7143.SSI': __('identifierSchemeCodeUntdid7143.SSI'),
	'identifierSchemeCodeUntdid7143.SSJ': __('identifierSchemeCodeUntdid7143.SSJ'),
	'identifierSchemeCodeUntdid7143.SSK': __('identifierSchemeCodeUntdid7143.SSK'),
	'identifierSchemeCodeUntdid7143.SSL': __('identifierSchemeCodeUntdid7143.SSL'),
	'identifierSchemeCodeUntdid7143.SSM': __('identifierSchemeCodeUntdid7143.SSM'),
	'identifierSchemeCodeUntdid7143.SSN': __('identifierSchemeCodeUntdid7143.SSN'),
	'identifierSchemeCodeUntdid7143.SSO': __('identifierSchemeCodeUntdid7143.SSO'),
	'identifierSchemeCodeUntdid7143.SSP': __('identifierSchemeCodeUntdid7143.SSP'),
	'identifierSchemeCodeUntdid7143.SSQ': __('identifierSchemeCodeUntdid7143.SSQ'),
	'identifierSchemeCodeUntdid7143.SSR': __('identifierSchemeCodeUntdid7143.SSR'),
	'identifierSchemeCodeUntdid7143.SSS': __('identifierSchemeCodeUntdid7143.SSS'),
	'identifierSchemeCodeUntdid7143.SST': __('identifierSchemeCodeUntdid7143.SST'),
	'identifierSchemeCodeUntdid7143.SSU': __('identifierSchemeCodeUntdid7143.SSU'),
	'identifierSchemeCodeUntdid7143.SSV': __('identifierSchemeCodeUntdid7143.SSV'),
	'identifierSchemeCodeUntdid7143.SSW': __('identifierSchemeCodeUntdid7143.SSW'),
	'identifierSchemeCodeUntdid7143.SSX': __('identifierSchemeCodeUntdid7143.SSX'),
	'identifierSchemeCodeUntdid7143.SSY': __('identifierSchemeCodeUntdid7143.SSY'),
	'identifierSchemeCodeUntdid7143.SSZ': __('identifierSchemeCodeUntdid7143.SSZ'),
	'identifierSchemeCodeUntdid7143.ST': __('identifierSchemeCodeUntdid7143.ST'),
	'identifierSchemeCodeUntdid7143.STA': __('identifierSchemeCodeUntdid7143.STA'),
	'identifierSchemeCodeUntdid7143.STB': __('identifierSchemeCodeUntdid7143.STB'),
	'identifierSchemeCodeUntdid7143.STC': __('identifierSchemeCodeUntdid7143.STC'),
	'identifierSchemeCodeUntdid7143.STD': __('identifierSchemeCodeUntdid7143.STD'),
	'identifierSchemeCodeUntdid7143.STE': __('identifierSchemeCodeUntdid7143.STE'),
	'identifierSchemeCodeUntdid7143.STF': __('identifierSchemeCodeUntdid7143.STF'),
	'identifierSchemeCodeUntdid7143.STG': __('identifierSchemeCodeUntdid7143.STG'),
	'identifierSchemeCodeUntdid7143.STH': __('identifierSchemeCodeUntdid7143.STH'),
	'identifierSchemeCodeUntdid7143.STI': __('identifierSchemeCodeUntdid7143.STI'),
	'identifierSchemeCodeUntdid7143.STJ': __('identifierSchemeCodeUntdid7143.STJ'),
	'identifierSchemeCodeUntdid7143.STK': __('identifierSchemeCodeUntdid7143.STK'),
	'identifierSchemeCodeUntdid7143.STL': __('identifierSchemeCodeUntdid7143.STL'),
	'identifierSchemeCodeUntdid7143.STM': __('identifierSchemeCodeUntdid7143.STM'),
	'identifierSchemeCodeUntdid7143.STN': __('identifierSchemeCodeUntdid7143.STN'),
	'identifierSchemeCodeUntdid7143.STO': __('identifierSchemeCodeUntdid7143.STO'),
	'identifierSchemeCodeUntdid7143.STP': __('identifierSchemeCodeUntdid7143.STP'),
	'identifierSchemeCodeUntdid7143.STQ': __('identifierSchemeCodeUntdid7143.STQ'),
	'identifierSchemeCodeUntdid7143.STR': __('identifierSchemeCodeUntdid7143.STR'),
	'identifierSchemeCodeUntdid7143.STS': __('identifierSchemeCodeUntdid7143.STS'),
	'identifierSchemeCodeUntdid7143.STT': __('identifierSchemeCodeUntdid7143.STT'),
	'identifierSchemeCodeUntdid7143.STU': __('identifierSchemeCodeUntdid7143.STU'),
	'identifierSchemeCodeUntdid7143.STV': __('identifierSchemeCodeUntdid7143.STV'),
	'identifierSchemeCodeUntdid7143.STW': __('identifierSchemeCodeUntdid7143.STW'),
	'identifierSchemeCodeUntdid7143.STX': __('identifierSchemeCodeUntdid7143.STX'),
	'identifierSchemeCodeUntdid7143.STY': __('identifierSchemeCodeUntdid7143.STY'),
	'identifierSchemeCodeUntdid7143.STZ': __('identifierSchemeCodeUntdid7143.STZ'),
	'identifierSchemeCodeUntdid7143.SUA': __('identifierSchemeCodeUntdid7143.SUA'),
	'identifierSchemeCodeUntdid7143.SUB': __('identifierSchemeCodeUntdid7143.SUB'),
	'identifierSchemeCodeUntdid7143.SUC': __('identifierSchemeCodeUntdid7143.SUC'),
	'identifierSchemeCodeUntdid7143.SUD': __('identifierSchemeCodeUntdid7143.SUD'),
	'identifierSchemeCodeUntdid7143.SUE': __('identifierSchemeCodeUntdid7143.SUE'),
	'identifierSchemeCodeUntdid7143.SUF': __('identifierSchemeCodeUntdid7143.SUF'),
	'identifierSchemeCodeUntdid7143.SUG': __('identifierSchemeCodeUntdid7143.SUG'),
	'identifierSchemeCodeUntdid7143.SUH': __('identifierSchemeCodeUntdid7143.SUH'),
	'identifierSchemeCodeUntdid7143.SUI': __('identifierSchemeCodeUntdid7143.SUI'),
	'identifierSchemeCodeUntdid7143.SUJ': __('identifierSchemeCodeUntdid7143.SUJ'),
	'identifierSchemeCodeUntdid7143.SUK': __('identifierSchemeCodeUntdid7143.SUK'),
	'identifierSchemeCodeUntdid7143.SUL': __('identifierSchemeCodeUntdid7143.SUL'),
	'identifierSchemeCodeUntdid7143.SUM': __('identifierSchemeCodeUntdid7143.SUM'),
	'identifierSchemeCodeUntdid7143.TG': __('identifierSchemeCodeUntdid7143.TG'),
	'identifierSchemeCodeUntdid7143.TSN': __('identifierSchemeCodeUntdid7143.TSN'),
	'identifierSchemeCodeUntdid7143.TSO': __('identifierSchemeCodeUntdid7143.TSO'),
	'identifierSchemeCodeUntdid7143.TSP': __('identifierSchemeCodeUntdid7143.TSP'),
	'identifierSchemeCodeUntdid7143.TSQ': __('identifierSchemeCodeUntdid7143.TSQ'),
	'identifierSchemeCodeUntdid7143.TSR': __('identifierSchemeCodeUntdid7143.TSR'),
	'identifierSchemeCodeUntdid7143.TSS': __('identifierSchemeCodeUntdid7143.TSS'),
	'identifierSchemeCodeUntdid7143.TST': __('identifierSchemeCodeUntdid7143.TST'),
	'identifierSchemeCodeUntdid7143.UA': __('identifierSchemeCodeUntdid7143.UA'),
	'identifierSchemeCodeUntdid7143.UP': __('identifierSchemeCodeUntdid7143.UP'),
	'identifierSchemeCodeUntdid7143.VN': __('identifierSchemeCodeUntdid7143.VN'),
	'identifierSchemeCodeUntdid7143.VP': __('identifierSchemeCodeUntdid7143.VP'),
	'identifierSchemeCodeUntdid7143.VS': __('identifierSchemeCodeUntdid7143.VS'),
	'identifierSchemeCodeUntdid7143.VX': __('identifierSchemeCodeUntdid7143.VX'),
	'identifierSchemeCodeUntdid7143.ZZZ': __('identifierSchemeCodeUntdid7143.ZZZ'),
};
