import { Component, Input } from '@angular/core';
import { AppBarParams } from './app-bar.component';

@Component({
	selector: 'ca-app-bar-container',
	template: `<ca-app-bar *ngFor="let appBar of appBars"
					[cookieName]="appBar.cookieName"
					[onClose]="appBar.onClose"
					[text]="appBar.text"
					[type]="appBar.type"></ca-app-bar>`,
})
export class AppBarContainerComponent {
	@Input()
	public appBars: AppBarParams[] = [];
}
