import { Injectable } from '@angular/core';
import { config } from 'config';
import { ConfigModel, ConfigUrls } from './config.model';

@Injectable({
	providedIn: 'root',
})
export class ConfigService {
	protected _config: ConfigModel = null;

	public get urls(): ConfigUrls {
		return this.config.urls;
	}

	public get config(): ConfigModel {
		return <ConfigModel> {
			urls: (<ConfigModel> this._config).urls,
			...this._config,
		};
	}

	constructor() {
		this.initConfig();

		if (!this.config) {
			console.error('ConfigService: no config!!');
		}
	}

	protected initConfig() {
		this._config = <ConfigModel> config;
	}
}
