import { AccountService } from 'shared/app/core/account/account.service';
import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavigationService } from 'framework/app/core/navigation/navigation.service';
import { AuthService } from '../../../auth/auth.service';
import { DropdownConfig } from '@cds/components-angular';
import Popper from 'popper.js';
import { ConfigService } from '../../../config/config.service';
import { ConfigModel } from '../../../config/config.model';

export function optionsFactory(config?: DropdownConfig): Popper.PopperOptions {
	const { positionFixed = true } = config || {};
	return {
		placement: 'top-start',
		positionFixed,
	};
}

@Component({
	selector: 'ca-account-menu',
	templateUrl: 'account-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMenuComponent implements OnInit {
	public user: string;
	public open: boolean = false;

	@Input() expanded: boolean = true;

	private appName: string;

	constructor(private authService: AuthService,
		private navigationService: NavigationService,
		private configService: ConfigService,
		private accountService: AccountService,
		private changeDetector : ChangeDetectorRef) { }

	ngOnInit(): void {
		this.appName = this.configService.config.appName;
		this.authService.getUserPrincipal().subscribe(user => {
			this.user = `${user?.firstName} ${user?.lastName}`;
			this.changeDetector.markForCheck();
		});

		this.accountService.dataChange.subscribe(item => {
			this.user = `${this.accountService.data.firstName} ${this.accountService.data.lastName}`;
			this.changeDetector.markForCheck();
		});
	}

	public goToProfile() {
		this.navigationService.go('profile');
	}

	public logout() {
		const address = `${window.location.protocol}//${window.location.host}/${this.appName}`;
		this.authService.logout(address);
	}
}
