import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ErrorNotification } from '../models/error-notification.model';
import { BaseNotification } from '../models/notification.model';
import { SuccessNotification } from '../models/success-notification.model';
import { NotificationTemplatesService } from './notification-templates.service';
import { WarningNotification } from '../models/warning-notification.model';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	constructor(protected notificationTemplatesService: NotificationTemplatesService) { }

	public showError(message?: string, title?: string): void {
		this.show(new ErrorNotification(message, title));
	}

	public showWarning(message?: string): void {
		this.show(new WarningNotification(message));
	}

	public showSuccess(message?: string, title?: string): void {
		this.show(new SuccessNotification(message, title));
	}

	public show<ResultValueType = void>(item: BaseNotification): Observable<ResultValueType> {
		this.notificationTemplatesService.notificationTemplates$.next({
			item: item,
			type: 'add',
		});

		return this.notificationTemplatesService.notificationResult$
			.pipe(
				filter(result => result.item === item),
				map(result => result.value as ResultValueType));
	}

	public remove(item: BaseNotification): void {
		this.notificationTemplatesService.notificationTemplates$.next({
			item: item,
			type: 'remove',
		});
	}
}
