import { APP_BASE_HREF } from '@angular/common';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	ButtonModule,
	ComponentsModule,
	StepperModule,
} from '@cds/components-angular';
import { config } from 'config';
import { HttpModule } from 'framework/app/core/http/http.module';
import { StoreModule } from 'framework/app/core/store/store.module';
import { DateModule } from 'framework/app/data/date/date.module';
import { ModalRouterModule } from 'framework/app/ui/modal-router/modal-router.module';
import { ModalModule } from 'framework/app/ui/modal/modal.module';
import { SpinnerModule } from 'framework/app/ui/spinner/spinner.module';
import { KeycloakAngularModule } from 'keycloak-angular';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { UserIdleModule } from 'angular-user-idle';
import { filter } from 'rxjs/operators';
import { AuthModule } from 'shared/app/core/auth/auth.module';
import { AuthService } from 'shared/app/core/auth/auth.service';
import { KeycloakAuthModule } from 'shared/app/core/keycloak-auth/keycloak-auth.module';
import { AppBarModule } from 'shared/app/core/app-bar/app-bar.module';
import { LayoutModule } from 'shared/app/core/layout/layout.module';
import { NavigationModule } from 'shared/app/core/navigation/navigation.module';
import { LocalStorageService } from 'shared/app/core/persistance/services/local.storage.service';
import { StorageService } from 'shared/app/core/persistance/services/storage.service';
import { FeatureTogglesModule } from 'shared/app/core/feature-toggles/feature-toggles.module';
import { FeatureTogglesService } from 'shared/app/core/feature-toggles/services/feature-toggles.service';
import { AccountService } from 'shared/app/core/account/account.service';
import { CdsTranslationsModule } from 'shared/app/cds-translations/cds-translations.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EwcConfigModule } from './config/ewc-config.module';
import { CoreModule } from './core/core.module';
import { HomeComponent } from './home/home.component';
import { Store } from './core/store/store';
import { AppConfigService } from './core/data/app-config.service';
import { UserIdleComponent } from 'shared/app/core/user-idle/user-idle.component';
import { CaRoutingModule } from 'shared/app/core/routing/routing.module';

export function BasePathFactory(): string {
	return config.basePath + 'ewc';
}

@NgModule({
	imports: [
		AppRoutingModule,
		NavigationModule,
		EwcConfigModule,
		BrowserModule,
		BrowserAnimationsModule,
		ComponentsModule,
		StepperModule,
		ButtonModule,
		DateModule,
		StoreModule.forRoot(Store),
		CoreModule,
		KeycloakAuthModule.forRoot(),
		KeycloakAngularModule,
		AuthModule,
		HttpModule.forRoot(),
		NgxPermissionsModule.forRoot(),
		ModalModule.forRoot(),
		ModalRouterModule,
		LayoutModule.forRoot(),
		AppBarModule,
		SpinnerModule.forRoot(),
		FeatureTogglesModule,
		UserIdleModule.forRoot({
			idle: 29 * 60 - 10,
			timeout: 60,
			ping: 30,
			idleSensitivity: 10,
		}),
		CdsTranslationsModule,
		CaRoutingModule,
	],
	providers: [
		{
			provide: StorageService,
			useClass: LocalStorageService,
		},
		{
			provide: APP_BASE_HREF,
			useFactory: BasePathFactory,
		},
	],

	declarations: [
		AppComponent,
		HomeComponent,
		UserIdleComponent,
	],
})
export class AppModule implements DoBootstrap {
	constructor(
		private authService: AuthService,
		private permissionsService: NgxPermissionsService,
		private appConfigService: AppConfigService,
		private featureTogglesService: FeatureTogglesService
	) {}

	async ngDoBootstrap(appRef: ApplicationRef) {
		try {
			this.authService
				.getPermissions()
				.pipe(filter((permissions) => !!permissions))
				.subscribe((permissions) => {
					this.permissionsService.loadPermissions(permissions);
				});

			this.authService
				.getUserPrincipal()
				.pipe(filter((principal) => !!principal))
				.subscribe((principal) => {
					AccountService.init(principal);
				});

			await this.authService.initialize();

			await this.appConfigService
				.getFeatures()
				.toPromise()
				.then((response) => {
					this.featureTogglesService.init(response.json());
				});
		} catch (e) {
			console.error(e);
		}

		appRef.bootstrap(AppComponent);
	}
}
