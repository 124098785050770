import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationAndRoutingService } from '../../navigation/services/navigation-and-routing.service';
import { BaseLayoutViewData } from '../components/base-layout/base-layout-view-data.model';

export enum LayoutEventType {
	ViewDataChange, LoadStart, LoadEnd,
}

export interface LayoutEvent {
	type:LayoutEventType;
	viewData?: BaseLayoutViewData;
	forModal?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class LayoutService {
	public layoutEvents: Subject<LayoutEvent> = new Subject<LayoutEvent>();

	constructor(
		protected navigationAndRoutingService: NavigationAndRoutingService,
	) {

	}
	/**
	 * modify current modal layout view data, changes only defined properties
	 * @param viewData data to be changed
	 */
	public setView(viewData: BaseLayoutViewData, forModal: boolean = null) {
		const event = <LayoutEvent> {
			viewData: viewData,
			forModal: forModal !== null ? forModal : this.navigationAndRoutingService.isModalRouteEnabled,
		};

		this.layoutEvents.next(event);
	}
}
