import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouterOutletModule } from 'framework/app/core/router-outlet/router-outlet.module';
import { ModalRouterModule } from 'framework/app/ui/modal-router/modal-router.module';
import { LayoutModule } from '../layout/layout.module';
import { MenuModule } from '../menu/menu.module';
import { NavigationModule } from '../navigation/navigation.module';
import { ModalRouteLayoutComponent } from './modal-route-layout.component';

@NgModule({
	imports: [
		CommonModule,
		MenuModule,
		LayoutModule,
		NavigationModule,
		RouterOutletModule,
		ModalRouterModule,
		RouterModule,
	],
	declarations: [ModalRouteLayoutComponent],
	exports: [ModalRouteLayoutComponent],
})
export class ModalRouteLayoutModule {}
