import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { __ } from 'seed/lib/i18n';
import { TranslateService } from 'shared/app/core/translate/translate.service';
import { FeatureTogglesService } from '../../../../feature-toggles/services/feature-toggles.service';
import { AccountService } from '../../../account.service';
import { ConfigService } from '../../../../config/config.service';

const LANGUAGE_NAMES = {
	EN: __('language.EN'),
	DE: __('language.DE'),
	FR: __('language.FR'),
	HR: __('language.HR'),
	PL: __('language.PL'),
	RO: __('language.RO'),
};

@Component({
	selector: 'ca-language-menu',
	templateUrl: './language-menu.component.html',
})
export class LanguageMenuComponent implements OnInit {
	readonly DEFAULT_LANGUAGE = 'EN';
	readonly LANG_COOKIE_NAME = 'lang';
	readonly COOKIE_LIFE_DAYS = 60;

	public LANGUAGES = {};
	public activeLanguage: string;
	public open: boolean = false;

	private appName: string;

	constructor(private cookieService: CookieService,
		public translateService: TranslateService,
		featureTogglesService: FeatureTogglesService,
		private accountService: AccountService,
		configService: ConfigService) {
		this.appName = configService.config.appName;
		try {
			const langArray = Array.isArray(configService.config.langs) ? configService.config.langs : JSON.parse(configService.config.langs);
			for (const lang of langArray) {
				const upperLang = lang.toUpperCase();
				if (upperLang === this.DEFAULT_LANGUAGE || featureTogglesService.isEnabled(`language.${upperLang}`)) {
					this.LANGUAGES[upperLang] = LANGUAGE_NAMES[upperLang] || upperLang;

					if (!LANGUAGE_NAMES[upperLang]) {
						console.warn('Missing language name for ' + upperLang);
					}
				}
			}
		} catch (e) {
			console.warn('Unable to load languages: ', e);
			this.LANGUAGES = {
				EN: LANGUAGE_NAMES.EN,
			};
		}
	}

	ngOnInit() {
		this.initLanguage();
	}

	initLanguage() {
		let initLang = this.getLangCookieValue();
		if (!initLang) {
			const browserLang = window.navigator.language.substr(0, 2).toUpperCase();
			if (Object.keys(this.LANGUAGES).indexOf(browserLang) !== -1) {
				initLang = browserLang;
			} else {
				initLang = this.DEFAULT_LANGUAGE;
			}
			this.setLangCookieValue(initLang);
		}
		this.activeLanguage = initLang;
	}

	public getLanguages() {
		return Object.keys(this.LANGUAGES);
	}

	public onLanguageChange(lang: string) {
		this.accountService.updateUserProfile({ attributes: { locale: [lang] } }).subscribe(() => {
			this.activeLanguage = lang;
			this.setLangCookieValue(lang);
			this.refreshPage();
		});
	}

	private getLangCookieValue(): string {
		if (this.cookieService.check(this.LANG_COOKIE_NAME)) {
			return this.cookieService.get(this.LANG_COOKIE_NAME).toUpperCase();
		}
		return '';
	}

	private setLangCookieValue(lang: string) {
		this.cookieService.set(this.LANG_COOKIE_NAME, lang.toLowerCase(), this.COOKIE_LIFE_DAYS, `/${this.appName}`);
	}

	private refreshPage() {
		window.location.pathname = `/${this.appName}`;
	}
}
