<ng-container>
	<aside class="app-bar cds-text-2" [ngClass]="typeClass">
		<div class="app-bar__container">
			<div class="app-bar__text">
				<p>
					{{text}}
				</p>
			</div>
			<div class="app-bar__close" (click)="onClose($event)">
				<i class="app-bar__icon-close cds-icon-close"></i>
			</div>
		</div>
	</aside>
</ng-container>