import { Component, OnDestroy } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { BaseNotification, NotificationResult } from '../../models/notification.model';
import { NotificationTemplatesEvent, NotificationTemplatesService } from '../../services/notification-templates.service';

@UntilDestroy()
@Component({
	selector: 'ca-notification-template-list',
	templateUrl: 'notification-template-list.component.html',
})
export class NotificationTemplateListComponent implements OnDestroy {
	items: BaseNotification[] = [];

	constructor(
		protected notificationTemplatesService: NotificationTemplatesService,
	) {
		notificationTemplatesService.notificationTemplates$
			.pipe(untilDestroyed(this))
			.subscribe(this.onNotificationTemplatesEvent);
	}

	public ngOnDestroy() {

	}

	public onNotificationResult(event: NotificationResult) {
		this.removeItem(event.item);
		this.notificationTemplatesService.notificationResult$.next(event);
	}

	protected onNotificationTemplatesEvent = (event: NotificationTemplatesEvent) => {
		switch (event.type) {
			case 'add': {
				this.items.push(event.item);
				break;
			}
			case 'remove': {
				this.removeItem(event.item);
				break;
			}
		}
	};

	protected removeItem(item: BaseNotification) {
		this.items = this.items.filter(x => x != item);
	}
}
