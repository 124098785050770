import { Injectable } from '@angular/core';
import { ConfigService } from 'shared/app/core/config/config.service';
import { EwcConfigModel, EwcConfigUrls, EwcConfigDev } from './ewc-config.model';

@Injectable({
	providedIn: 'root',
})
export class EwcConfigService extends ConfigService {
	public get config(): EwcConfigModel {
		return <EwcConfigModel> this._config;
	}

	public get urls(): EwcConfigUrls {
		return this.config.urls;
	}

	public get dev(): EwcConfigDev {
		return this.config.dev;
	}

	public get local(): EwcConfigDev {
		return this.config.local;
	}
}
