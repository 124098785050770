import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { FeatureTogglesService } from 'shared/app/core/feature-toggles/services/feature-toggles.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { MenuService } from '../../../menu/menu.service';
import { NavigationAndRoutingService } from '../../../navigation/services/navigation-and-routing.service';
import { LayoutService } from '../../services/layout.service';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { MenuModel } from '../../../menu/menu.model';

@UntilDestroy()
@Component({
	selector: 'ca-layout',
	templateUrl: './layout.component.html',
})
export class LayoutComponent extends BaseLayoutComponent implements OnInit, OnDestroy {
	public model: MenuModel;
	public initialized: boolean = false;

	@ViewChild('invoices') invoicesIcon: TemplateRef<any>;
	@ViewChild('partners') partnersIcon: TemplateRef<any>;

	public get isModal(): boolean {
		return false;
	}

	constructor(
		protected router: Router,
		protected menuService: MenuService,
		protected navigationAndRoutingService: NavigationAndRoutingService,
		protected layoutService: LayoutService,
		protected featureTogglesService: FeatureTogglesService
	) {
		super(router, menuService, navigationAndRoutingService, layoutService);
	}

	public ngOnInit() {
		super.ngOnInit();
		this.initMenu();
	}

	protected initMenu() {
		this.menuService.getCurrentMenu(this.isModal)
			.pipe(untilDestroyed(this))
			.subscribe(menuModel => {
				this.model = menuModel;
				this.initialized = true;
			});
	}
	getIcon(icon: string) {
		if (icon === 'partners') {
			return this.partnersIcon;
		}
		if (icon === 'invoices') {
			return this.invoicesIcon;
		}
	}
	getEnabledNavigationItems() {
		return this.model.items.filter(item => !item.featureName || this.isNavigationEnabled(item.featureName));
	}
	isNavigationEnabled(name: string): boolean {
		return this.featureTogglesService.isEnabled(name);
	}
}
