import { Injectable, ComponentRef, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { AppBarContainerComponent } from './app-bar-container.component';
import { AppBarParams } from './app-bar.component';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root',
})
export class AppBarService {
	private appBarContainerRef: ComponentRef<AppBarContainerComponent>;
	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private cookieService: CookieService,
		private appRef: ApplicationRef,
		private injector: Injector
	) {
		this.appBarContainerRef = this.componentFactoryResolver
			.resolveComponentFactory<AppBarContainerComponent>(AppBarContainerComponent)
			.create(this.injector);

		this.appRef.attachView(this.appBarContainerRef.hostView);
		const domElem = (this.appBarContainerRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
		document.body.appendChild(domElem);
	}

	public createAppBar(params: AppBarParams) {
		if (params.cookieName && this.cookieService.check(params.cookieName.toUpperCase())) {
			this.cookieService.set(params.cookieName.toUpperCase(), '1', 60);
			return;
		}

		const closeFunc: typeof params.onClose = (event) => {
			const index = this.appBarContainerRef.instance.appBars.indexOf(params);
			this.appBarContainerRef.instance.appBars.splice(index, 1);
			if (params.cookieName) {
				this.cookieService.set(params.cookieName.toUpperCase(), '1', 60);
			}
		};
		params.onClose = closeFunc;

		this.appBarContainerRef.instance.appBars.unshift(params);
	}
}
