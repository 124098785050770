<div class="bg-login-background background-round main-container flex f-column full-width full-height"
	 caContainer
	 caPopoverContainer>

	<div class="header-container container-fluid cds-grid flex f-column">
		<div class="flex f-justify-content-between f-row full-width f-align-items-center">
			<i class="sprite-comarch_large"></i>
		</div>
		<div class="logo-text collapsed-hidden">SFA-WEB</div>
	</div>

	<main class="mt-64 container-fluid cds-grid">
		<router-outlet></router-outlet>
	</main>
</div>