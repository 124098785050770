import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { AuthService } from 'shared/app/core/auth/auth.service';
import { DialogContext, DialogService } from '@cds/components-angular';
import { FeatureTogglesService } from '../feature-toggles/services/feature-toggles.service';
import { ConfigService } from '../config/config.service';

@Component({
	selector: 'ca-user-idle',
	templateUrl: 'user-idle.component.html',
})
export class UserIdleComponent implements OnInit {
	isUserIdle: boolean;

	@ViewChild('idleDialog', { static: true })
	idleDialogTemplate: TemplateRef<DialogContext<void>>;

	constructor(private authService: AuthService,
		private userIdle: UserIdleService,
		private dialogService: DialogService,
		private featureToggles: FeatureTogglesService,
		private configService: ConfigService) {}

	ngOnInit(): void {
		this.userIdle.startWatching();

		this.userIdle.onTimerStart().subscribe((count) => {
			if (this.featureToggles.isEnabled('idle.logout')) {
				if (count === 1) {
					this.dialogService.open(this.idleDialogTemplate).then(() => {
						this.authService.silentRefresh();
						this.userIdle.resetTimer();
					}, () => {
						this.logout();
					});
				}
			}
		});

		this.userIdle.onIdleStatusChanged().subscribe((idle) => {
			this.isUserIdle = idle;
			this.tryRefreshTokenIfNotIdle();
		});

		this.userIdle.onTimeout().subscribe(() => {
			if (this.featureToggles.isEnabled('idle.logout')) {
				this.logout();
			}
		});

		this.userIdle.ping$.subscribe(() => {
			this.tryRefreshTokenIfNotIdle();
		});
	}

	tryRefreshTokenIfNotIdle() {
		if (!this.isUserIdle) {
			this.authService.silentRefresh();
		}
	}

	logout() {
		const address = `${window.location.protocol}//${window.location.host}/${this.configService.config.appName}`;
		this.authService.logout(address);
	}
}
