import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
	ActivatedRoute,
	Data,
	NavigationEnd,
	NavigationStart,
	ParamMap,
	Params,
	Router
} from '@angular/router';
import { NavigationService } from 'framework/app/core/navigation/navigation.service';
import { cloneDeep } from 'lodash';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of, Subject, zip } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';
import { RouteDataModel } from '../../routing/route-data.model';
import {
	MODAL_ROUTE_OUTLET,
	MODAL_ROUTE_START_PATH,
	NAVIGATION,
	NavigationItem,
	NavigationModel
} from '../navigation.model';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class NavigationAndRoutingService implements OnDestroy {
	private _routeParams: ParamMap;
	private _routeData: RouteDataModel;
	private _routeQuery: Params;

	private _modalRouteEnabled: boolean;
	private _modalRouteParams: ParamMap;
	private _modalRouteData: RouteDataModel;
	private _modalRouteQuery: Params;
	public onRouteDataChanged: Subject<void> = new Subject();

	public get routeParams(): ParamMap {
		return this._routeParams;
	}

	public get routeData(): RouteDataModel {
		return this._routeData;
	}

	public get routeQuery(): Params {
		return this._routeQuery;
	}

	public get isModalRouteEnabled(): boolean {
		return this._modalRouteEnabled === true;
	}

	public get modalRouteParams(): ParamMap {
		return this._modalRouteParams;
	}

	public get modalRouteData(): RouteDataModel {
		return this._modalRouteData;
	}

	public get modalRouteQuery(): Params {
		return this._modalRouteQuery;
	}

	public get currentVisibleRouteParams(): ParamMap {
		return this.isModalRouteEnabled
			? this.modalRouteParams
			: this.routeParams;
	}

	public get currentVisibleRouteData(): RouteDataModel {
		return this.isModalRouteEnabled ? this.modalRouteData : this.routeData;
	}

	public get currentVisibleRouteQuery(): Params {
		return this.isModalRouteEnabled
			? this.modalRouteQuery
			: this.routeQuery;
	}

	constructor(
		@Inject(NAVIGATION) protected navigation: NavigationModel[],
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected navigationService: NavigationService
	) {
		this.initCurrentRouteData();
	}

	public ngOnDestroy() {}

	public getNavigationItemsByKey(key: string) {
		let navigationItems: NavigationItem[] = [];

		this.navigation
			.map(x => x[key])
			.filter(x => !!x)
			.forEach(x => {
				navigationItems = navigationItems.concat(x);
			});

		return cloneDeep(navigationItems);
	}

	protected initCurrentRouteData() {
		this.initRouteDataFromActivatedRoute()
			.pipe(untilDestroyed(this))
			.subscribe();

		this.router.events
			.pipe(
				filter(
					event =>
						event instanceof NavigationEnd ||
						event instanceof NavigationStart
				),
				flatMap(this.initRouteDataFromActivatedRoute),
				untilDestroyed(this)
			)
			.subscribe();
	}

	public getUrl(
		module: string,
		relativePath: string,
		isModal: boolean = false
	) {
		if (isModal) {
			return `${this.router.url}(${MODAL_ROUTE_OUTLET}:${MODAL_ROUTE_START_PATH}/${module}/${relativePath})`;
		} else {
			return `${this.router.url}/${module}/${relativePath}`;
		}
	}

	protected getRouteLeaf(route: ActivatedRoute): ActivatedRoute {
		while (route) {
			if (route.firstChild) {
				route = route.firstChild;
			} else {
				return route;
			}
		}
	}

	protected initRouteDataFromActivatedRoute = (): Observable<void> => {
		const observables = this.activatedRoute.children.map(x => x.url);
		return zip(...observables).pipe(
			flatMap(urlSegmentsList => {
				const activatedRoute = this.activatedRoute.firstChild;
				let modalChild = null;

				/// at first step get modal layout child (if exists) because it is current visible route
				/// if no modal context then only one route path is activated and everything works fine.. hopefully
				urlSegmentsList.forEach((urlSegments, index) => {
					if (
						urlSegments.length &&
						urlSegments[0].path === MODAL_ROUTE_START_PATH
					) {
						modalChild = this.activatedRoute.children[index];
						this._modalRouteEnabled = true;
					}
				});

				/// travel down to the leaf - it is current route, get its data
				const currentRouteLeaf = this.getRouteLeaf(activatedRoute);
				const dataObservables = [
					this.getDataAndParams(currentRouteLeaf),
				];

				if (modalChild) {
					this._modalRouteEnabled = true;
					const modalRouteLeaf = this.getRouteLeaf(modalChild);
					dataObservables.push(this.getDataAndParams(modalRouteLeaf));
				} else {
					this._modalRouteEnabled = false;
					this._modalRouteData = null;
					this._modalRouteParams = null;
				}

				return zip(...dataObservables).pipe(
					map(([routeData, modalRouteData]) => {
						this._routeData = routeData.data;
						this._routeParams = routeData.paramMap;
						this._routeQuery = routeData.query;

						if (modalRouteData) {
							this._modalRouteData = modalRouteData.data;
							this._modalRouteParams = modalRouteData.paramMap;
							this._modalRouteQuery = modalRouteData.query;
						}

						this.onRouteDataChanged.next();
					})
				);
			})
		);
	};

	protected getDataAndParams(
		route: ActivatedRoute
	): Observable<{ data: Data; paramMap: ParamMap; query: Params }> {
		if (route.snapshot) {
			return of({
				data: route.snapshot.data,
				paramMap: route.snapshot.paramMap,
				query: route.snapshot.queryParams,
			});
		} else {
			return zip(route.data, route.paramMap, route.queryParams).pipe(
				map(([data, paramMap, query]) => {
					return {
						data: data,
						paramMap: paramMap,
						query: query,
					};
				})
			);
		}
	}

	public closeModalAndNavigate(
		navigationCallback: () => Promise<boolean>
	): Promise<boolean> {
		return this.navigationService.close('modal').then(() => {
			return navigationCallback();
		});
	}
}
