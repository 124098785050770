import {
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Renderer2,
	SimpleChanges
} from '@angular/core';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[caModalRouterLink]',
})
export class ModalRouterLinkDirective implements OnChanges, OnInit, OnDestroy {

	private urlTree: UrlTree;
	private subscription: Subscription;

	@Input()
	activeClass: string;

	@Input('caModalRouterLink')
	commands: any;

	@Input('modalPrefix')
	prefix: string = 'modal';

	@Input()
	replaceUrl: boolean = false;

	@Input()
	exactMatch: boolean = false;

	constructor(
		private router: Router,
		private elementRef: ElementRef,
		private renderer: Renderer2) { }

	@HostListener('click')
	onClick(): void {
		this.urlTree = null;
		this.router.navigateByUrl(this.getUrlTree(), { replaceUrl: this.replaceUrl });
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('commands' in changes && this.activeClass) {
			this.updateActive();
		}
	}

	ngOnInit(): void {
		if (this.activeClass) {
			this.subscription = this.router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.urlTree = null;
					this.updateActive();
				}
			});
		}
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	private updateActive(): void {
		const isActive: boolean = this.router.isActive(this.getUrlTree(), this.exactMatch);
		if (isActive) {
			this.renderer.addClass(this.elementRef.nativeElement, this.activeClass);
		} else {
			this.renderer.removeClass(this.elementRef.nativeElement, this.activeClass);
		}
	}

	private getUrlTree(): UrlTree {
		if (this.urlTree) {
			return this.urlTree;
		} else {
			return this.urlTree = this.createUrlTree(this.commands);
		}
	}

	private createUrlTree(commands: any): UrlTree {
		if (Array.isArray(commands)) {
			commands = commands.reduce((parts: string[], part: string) => typeof part === 'object' ? parts.concat(part) : parts.concat(part.split('/')), []);
		}
		commands = [{ outlets: { [this.prefix]: commands } }];
		return this.router.createUrlTree(commands);
	}
}
