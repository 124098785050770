import { __ } from 'seed/lib/i18n';

export const identifierSchemeCodeUnspsc190501 = {
	'identifierSchemeCodeUnspsc190501.10000000': __('identifierSchemeCodeUnspsc190501.10000000'),
	'identifierSchemeCodeUnspsc190501.10100000': __('identifierSchemeCodeUnspsc190501.10100000'),
	'identifierSchemeCodeUnspsc190501.10101500': __('identifierSchemeCodeUnspsc190501.10101500'),
	'identifierSchemeCodeUnspsc190501.10101501': __('identifierSchemeCodeUnspsc190501.10101501'),
	'identifierSchemeCodeUnspsc190501.10101502': __('identifierSchemeCodeUnspsc190501.10101502'),
	'identifierSchemeCodeUnspsc190501.10101504': __('identifierSchemeCodeUnspsc190501.10101504'),
	'identifierSchemeCodeUnspsc190501.10101505': __('identifierSchemeCodeUnspsc190501.10101505'),
	'identifierSchemeCodeUnspsc190501.10101506': __('identifierSchemeCodeUnspsc190501.10101506'),
	'identifierSchemeCodeUnspsc190501.10101507': __('identifierSchemeCodeUnspsc190501.10101507'),
	'identifierSchemeCodeUnspsc190501.10101508': __('identifierSchemeCodeUnspsc190501.10101508'),
	'identifierSchemeCodeUnspsc190501.10101509': __('identifierSchemeCodeUnspsc190501.10101509'),
	'identifierSchemeCodeUnspsc190501.10101510': __('identifierSchemeCodeUnspsc190501.10101510'),
	'identifierSchemeCodeUnspsc190501.10101511': __('identifierSchemeCodeUnspsc190501.10101511'),
	'identifierSchemeCodeUnspsc190501.10101512': __('identifierSchemeCodeUnspsc190501.10101512'),
	'identifierSchemeCodeUnspsc190501.10101513': __('identifierSchemeCodeUnspsc190501.10101513'),
	'identifierSchemeCodeUnspsc190501.10101514': __('identifierSchemeCodeUnspsc190501.10101514'),
	'identifierSchemeCodeUnspsc190501.10101515': __('identifierSchemeCodeUnspsc190501.10101515'),
	'identifierSchemeCodeUnspsc190501.10101516': __('identifierSchemeCodeUnspsc190501.10101516'),
	'identifierSchemeCodeUnspsc190501.10101517': __('identifierSchemeCodeUnspsc190501.10101517'),
	'identifierSchemeCodeUnspsc190501.10101518': __('identifierSchemeCodeUnspsc190501.10101518'),
	'identifierSchemeCodeUnspsc190501.10101519': __('identifierSchemeCodeUnspsc190501.10101519'),
	'identifierSchemeCodeUnspsc190501.10101600': __('identifierSchemeCodeUnspsc190501.10101600'),
	'identifierSchemeCodeUnspsc190501.10101601': __('identifierSchemeCodeUnspsc190501.10101601'),
	'identifierSchemeCodeUnspsc190501.10101602': __('identifierSchemeCodeUnspsc190501.10101602'),
	'identifierSchemeCodeUnspsc190501.10101603': __('identifierSchemeCodeUnspsc190501.10101603'),
	'identifierSchemeCodeUnspsc190501.10101604': __('identifierSchemeCodeUnspsc190501.10101604'),
	'identifierSchemeCodeUnspsc190501.10101605': __('identifierSchemeCodeUnspsc190501.10101605'),
	'identifierSchemeCodeUnspsc190501.10101606': __('identifierSchemeCodeUnspsc190501.10101606'),
	'identifierSchemeCodeUnspsc190501.10101607': __('identifierSchemeCodeUnspsc190501.10101607'),
	'identifierSchemeCodeUnspsc190501.10101700': __('identifierSchemeCodeUnspsc190501.10101700'),
	'identifierSchemeCodeUnspsc190501.10101701': __('identifierSchemeCodeUnspsc190501.10101701'),
	'identifierSchemeCodeUnspsc190501.10101702': __('identifierSchemeCodeUnspsc190501.10101702'),
	'identifierSchemeCodeUnspsc190501.10101703': __('identifierSchemeCodeUnspsc190501.10101703'),
	'identifierSchemeCodeUnspsc190501.10101704': __('identifierSchemeCodeUnspsc190501.10101704'),
	'identifierSchemeCodeUnspsc190501.10101705': __('identifierSchemeCodeUnspsc190501.10101705'),
	'identifierSchemeCodeUnspsc190501.10101706': __('identifierSchemeCodeUnspsc190501.10101706'),
	'identifierSchemeCodeUnspsc190501.10101707': __('identifierSchemeCodeUnspsc190501.10101707'),
	'identifierSchemeCodeUnspsc190501.10101708': __('identifierSchemeCodeUnspsc190501.10101708'),
	'identifierSchemeCodeUnspsc190501.10101709': __('identifierSchemeCodeUnspsc190501.10101709'),
	'identifierSchemeCodeUnspsc190501.10101710': __('identifierSchemeCodeUnspsc190501.10101710'),
	'identifierSchemeCodeUnspsc190501.10101711': __('identifierSchemeCodeUnspsc190501.10101711'),
	'identifierSchemeCodeUnspsc190501.10101712': __('identifierSchemeCodeUnspsc190501.10101712'),
	'identifierSchemeCodeUnspsc190501.10101713': __('identifierSchemeCodeUnspsc190501.10101713'),
	'identifierSchemeCodeUnspsc190501.10101714': __('identifierSchemeCodeUnspsc190501.10101714'),
	'identifierSchemeCodeUnspsc190501.10101715': __('identifierSchemeCodeUnspsc190501.10101715'),
	'identifierSchemeCodeUnspsc190501.10101716': __('identifierSchemeCodeUnspsc190501.10101716'),
	'identifierSchemeCodeUnspsc190501.10101717': __('identifierSchemeCodeUnspsc190501.10101717'),
	'identifierSchemeCodeUnspsc190501.10101718': __('identifierSchemeCodeUnspsc190501.10101718'),
	'identifierSchemeCodeUnspsc190501.10101719': __('identifierSchemeCodeUnspsc190501.10101719'),
	'identifierSchemeCodeUnspsc190501.10101720': __('identifierSchemeCodeUnspsc190501.10101720'),
	'identifierSchemeCodeUnspsc190501.10101721': __('identifierSchemeCodeUnspsc190501.10101721'),
	'identifierSchemeCodeUnspsc190501.10101722': __('identifierSchemeCodeUnspsc190501.10101722'),
	'identifierSchemeCodeUnspsc190501.10101723': __('identifierSchemeCodeUnspsc190501.10101723'),
	'identifierSchemeCodeUnspsc190501.10101724': __('identifierSchemeCodeUnspsc190501.10101724'),
	'identifierSchemeCodeUnspsc190501.10101725': __('identifierSchemeCodeUnspsc190501.10101725'),
	'identifierSchemeCodeUnspsc190501.10101726': __('identifierSchemeCodeUnspsc190501.10101726'),
	'identifierSchemeCodeUnspsc190501.10101727': __('identifierSchemeCodeUnspsc190501.10101727'),
	'identifierSchemeCodeUnspsc190501.10101728': __('identifierSchemeCodeUnspsc190501.10101728'),
	'identifierSchemeCodeUnspsc190501.10101729': __('identifierSchemeCodeUnspsc190501.10101729'),
	'identifierSchemeCodeUnspsc190501.10101730': __('identifierSchemeCodeUnspsc190501.10101730'),
	'identifierSchemeCodeUnspsc190501.10101731': __('identifierSchemeCodeUnspsc190501.10101731'),
	'identifierSchemeCodeUnspsc190501.10101732': __('identifierSchemeCodeUnspsc190501.10101732'),
	'identifierSchemeCodeUnspsc190501.10101800': __('identifierSchemeCodeUnspsc190501.10101800'),
	'identifierSchemeCodeUnspsc190501.10101801': __('identifierSchemeCodeUnspsc190501.10101801'),
	'identifierSchemeCodeUnspsc190501.10101802': __('identifierSchemeCodeUnspsc190501.10101802'),
	'identifierSchemeCodeUnspsc190501.10101803': __('identifierSchemeCodeUnspsc190501.10101803'),
	'identifierSchemeCodeUnspsc190501.10101804': __('identifierSchemeCodeUnspsc190501.10101804'),
	'identifierSchemeCodeUnspsc190501.10101805': __('identifierSchemeCodeUnspsc190501.10101805'),
	'identifierSchemeCodeUnspsc190501.10101806': __('identifierSchemeCodeUnspsc190501.10101806'),
	'identifierSchemeCodeUnspsc190501.10101807': __('identifierSchemeCodeUnspsc190501.10101807'),
	'identifierSchemeCodeUnspsc190501.10101808': __('identifierSchemeCodeUnspsc190501.10101808'),
	'identifierSchemeCodeUnspsc190501.10101809': __('identifierSchemeCodeUnspsc190501.10101809'),
	'identifierSchemeCodeUnspsc190501.10101810': __('identifierSchemeCodeUnspsc190501.10101810'),
	'identifierSchemeCodeUnspsc190501.10101811': __('identifierSchemeCodeUnspsc190501.10101811'),
	'identifierSchemeCodeUnspsc190501.10101900': __('identifierSchemeCodeUnspsc190501.10101900'),
	'identifierSchemeCodeUnspsc190501.10101901': __('identifierSchemeCodeUnspsc190501.10101901'),
	'identifierSchemeCodeUnspsc190501.10101902': __('identifierSchemeCodeUnspsc190501.10101902'),
	'identifierSchemeCodeUnspsc190501.10101903': __('identifierSchemeCodeUnspsc190501.10101903'),
	'identifierSchemeCodeUnspsc190501.10101904': __('identifierSchemeCodeUnspsc190501.10101904'),
	'identifierSchemeCodeUnspsc190501.10101905': __('identifierSchemeCodeUnspsc190501.10101905'),
	'identifierSchemeCodeUnspsc190501.10101906': __('identifierSchemeCodeUnspsc190501.10101906'),
	'identifierSchemeCodeUnspsc190501.10101907': __('identifierSchemeCodeUnspsc190501.10101907'),
	'identifierSchemeCodeUnspsc190501.10101908': __('identifierSchemeCodeUnspsc190501.10101908'),
	'identifierSchemeCodeUnspsc190501.10101909': __('identifierSchemeCodeUnspsc190501.10101909'),
	'identifierSchemeCodeUnspsc190501.10102000': __('identifierSchemeCodeUnspsc190501.10102000'),
	'identifierSchemeCodeUnspsc190501.10102001': __('identifierSchemeCodeUnspsc190501.10102001'),
	'identifierSchemeCodeUnspsc190501.10102002': __('identifierSchemeCodeUnspsc190501.10102002'),
	'identifierSchemeCodeUnspsc190501.10102003': __('identifierSchemeCodeUnspsc190501.10102003'),
	'identifierSchemeCodeUnspsc190501.10102004': __('identifierSchemeCodeUnspsc190501.10102004'),
	'identifierSchemeCodeUnspsc190501.10102005': __('identifierSchemeCodeUnspsc190501.10102005'),
	'identifierSchemeCodeUnspsc190501.10102006': __('identifierSchemeCodeUnspsc190501.10102006'),
	'identifierSchemeCodeUnspsc190501.10102007': __('identifierSchemeCodeUnspsc190501.10102007'),
	'identifierSchemeCodeUnspsc190501.10102008': __('identifierSchemeCodeUnspsc190501.10102008'),
	'identifierSchemeCodeUnspsc190501.10102009': __('identifierSchemeCodeUnspsc190501.10102009'),
	'identifierSchemeCodeUnspsc190501.10102010': __('identifierSchemeCodeUnspsc190501.10102010'),
	'identifierSchemeCodeUnspsc190501.10102011': __('identifierSchemeCodeUnspsc190501.10102011'),
	'identifierSchemeCodeUnspsc190501.10102012': __('identifierSchemeCodeUnspsc190501.10102012'),
	'identifierSchemeCodeUnspsc190501.10102013': __('identifierSchemeCodeUnspsc190501.10102013'),
	'identifierSchemeCodeUnspsc190501.10102100': __('identifierSchemeCodeUnspsc190501.10102100'),
	'identifierSchemeCodeUnspsc190501.10102101': __('identifierSchemeCodeUnspsc190501.10102101'),
	'identifierSchemeCodeUnspsc190501.10102102': __('identifierSchemeCodeUnspsc190501.10102102'),
	'identifierSchemeCodeUnspsc190501.10102103': __('identifierSchemeCodeUnspsc190501.10102103'),
	'identifierSchemeCodeUnspsc190501.10102104': __('identifierSchemeCodeUnspsc190501.10102104'),
	'identifierSchemeCodeUnspsc190501.10102105': __('identifierSchemeCodeUnspsc190501.10102105'),
	'identifierSchemeCodeUnspsc190501.10102106': __('identifierSchemeCodeUnspsc190501.10102106'),
	'identifierSchemeCodeUnspsc190501.10102107': __('identifierSchemeCodeUnspsc190501.10102107'),
	'identifierSchemeCodeUnspsc190501.10110000': __('identifierSchemeCodeUnspsc190501.10110000'),
	'identifierSchemeCodeUnspsc190501.10111300': __('identifierSchemeCodeUnspsc190501.10111300'),
	'identifierSchemeCodeUnspsc190501.10111301': __('identifierSchemeCodeUnspsc190501.10111301'),
	'identifierSchemeCodeUnspsc190501.10111302': __('identifierSchemeCodeUnspsc190501.10111302'),
	'identifierSchemeCodeUnspsc190501.10111303': __('identifierSchemeCodeUnspsc190501.10111303'),
	'identifierSchemeCodeUnspsc190501.10111304': __('identifierSchemeCodeUnspsc190501.10111304'),
	'identifierSchemeCodeUnspsc190501.10111305': __('identifierSchemeCodeUnspsc190501.10111305'),
	'identifierSchemeCodeUnspsc190501.10111306': __('identifierSchemeCodeUnspsc190501.10111306'),
	'identifierSchemeCodeUnspsc190501.10111307': __('identifierSchemeCodeUnspsc190501.10111307'),
	'identifierSchemeCodeUnspsc190501.10111308': __('identifierSchemeCodeUnspsc190501.10111308'),
	'identifierSchemeCodeUnspsc190501.10120000': __('identifierSchemeCodeUnspsc190501.10120000'),
	'identifierSchemeCodeUnspsc190501.10121500': __('identifierSchemeCodeUnspsc190501.10121500'),
	'identifierSchemeCodeUnspsc190501.10121501': __('identifierSchemeCodeUnspsc190501.10121501'),
	'identifierSchemeCodeUnspsc190501.10121502': __('identifierSchemeCodeUnspsc190501.10121502'),
	'identifierSchemeCodeUnspsc190501.10121503': __('identifierSchemeCodeUnspsc190501.10121503'),
	'identifierSchemeCodeUnspsc190501.10121504': __('identifierSchemeCodeUnspsc190501.10121504'),
	'identifierSchemeCodeUnspsc190501.10121505': __('identifierSchemeCodeUnspsc190501.10121505'),
	'identifierSchemeCodeUnspsc190501.10121506': __('identifierSchemeCodeUnspsc190501.10121506'),
	'identifierSchemeCodeUnspsc190501.10121507': __('identifierSchemeCodeUnspsc190501.10121507'),
	'identifierSchemeCodeUnspsc190501.10121508': __('identifierSchemeCodeUnspsc190501.10121508'),
	'identifierSchemeCodeUnspsc190501.10121509': __('identifierSchemeCodeUnspsc190501.10121509'),
	'identifierSchemeCodeUnspsc190501.10121600': __('identifierSchemeCodeUnspsc190501.10121600'),
	'identifierSchemeCodeUnspsc190501.10121601': __('identifierSchemeCodeUnspsc190501.10121601'),
	'identifierSchemeCodeUnspsc190501.10121602': __('identifierSchemeCodeUnspsc190501.10121602'),
	'identifierSchemeCodeUnspsc190501.10121603': __('identifierSchemeCodeUnspsc190501.10121603'),
	'identifierSchemeCodeUnspsc190501.10121604': __('identifierSchemeCodeUnspsc190501.10121604'),
	'identifierSchemeCodeUnspsc190501.10121700': __('identifierSchemeCodeUnspsc190501.10121700'),
	'identifierSchemeCodeUnspsc190501.10121701': __('identifierSchemeCodeUnspsc190501.10121701'),
	'identifierSchemeCodeUnspsc190501.10121702': __('identifierSchemeCodeUnspsc190501.10121702'),
	'identifierSchemeCodeUnspsc190501.10121703': __('identifierSchemeCodeUnspsc190501.10121703'),
	'identifierSchemeCodeUnspsc190501.10121800': __('identifierSchemeCodeUnspsc190501.10121800'),
	'identifierSchemeCodeUnspsc190501.10121801': __('identifierSchemeCodeUnspsc190501.10121801'),
	'identifierSchemeCodeUnspsc190501.10121802': __('identifierSchemeCodeUnspsc190501.10121802'),
	'identifierSchemeCodeUnspsc190501.10121803': __('identifierSchemeCodeUnspsc190501.10121803'),
	'identifierSchemeCodeUnspsc190501.10121804': __('identifierSchemeCodeUnspsc190501.10121804'),
	'identifierSchemeCodeUnspsc190501.10121805': __('identifierSchemeCodeUnspsc190501.10121805'),
	'identifierSchemeCodeUnspsc190501.10121806': __('identifierSchemeCodeUnspsc190501.10121806'),
	'identifierSchemeCodeUnspsc190501.10121900': __('identifierSchemeCodeUnspsc190501.10121900'),
	'identifierSchemeCodeUnspsc190501.10121901': __('identifierSchemeCodeUnspsc190501.10121901'),
	'identifierSchemeCodeUnspsc190501.10122000': __('identifierSchemeCodeUnspsc190501.10122000'),
	'identifierSchemeCodeUnspsc190501.10122001': __('identifierSchemeCodeUnspsc190501.10122001'),
	'identifierSchemeCodeUnspsc190501.10122002': __('identifierSchemeCodeUnspsc190501.10122002'),
	'identifierSchemeCodeUnspsc190501.10122003': __('identifierSchemeCodeUnspsc190501.10122003'),
	'identifierSchemeCodeUnspsc190501.10122100': __('identifierSchemeCodeUnspsc190501.10122100'),
	'identifierSchemeCodeUnspsc190501.10122101': __('identifierSchemeCodeUnspsc190501.10122101'),
	'identifierSchemeCodeUnspsc190501.10122102': __('identifierSchemeCodeUnspsc190501.10122102'),
	'identifierSchemeCodeUnspsc190501.10122103': __('identifierSchemeCodeUnspsc190501.10122103'),
	'identifierSchemeCodeUnspsc190501.10122104': __('identifierSchemeCodeUnspsc190501.10122104'),
	'identifierSchemeCodeUnspsc190501.10130000': __('identifierSchemeCodeUnspsc190501.10130000'),
	'identifierSchemeCodeUnspsc190501.10131500': __('identifierSchemeCodeUnspsc190501.10131500'),
	'identifierSchemeCodeUnspsc190501.10131506': __('identifierSchemeCodeUnspsc190501.10131506'),
	'identifierSchemeCodeUnspsc190501.10131507': __('identifierSchemeCodeUnspsc190501.10131507'),
	'identifierSchemeCodeUnspsc190501.10131508': __('identifierSchemeCodeUnspsc190501.10131508'),
	'identifierSchemeCodeUnspsc190501.10131600': __('identifierSchemeCodeUnspsc190501.10131600'),
	'identifierSchemeCodeUnspsc190501.10131601': __('identifierSchemeCodeUnspsc190501.10131601'),
	'identifierSchemeCodeUnspsc190501.10131602': __('identifierSchemeCodeUnspsc190501.10131602'),
	'identifierSchemeCodeUnspsc190501.10131603': __('identifierSchemeCodeUnspsc190501.10131603'),
	'identifierSchemeCodeUnspsc190501.10131604': __('identifierSchemeCodeUnspsc190501.10131604'),
	'identifierSchemeCodeUnspsc190501.10131605': __('identifierSchemeCodeUnspsc190501.10131605'),
	'identifierSchemeCodeUnspsc190501.10131700': __('identifierSchemeCodeUnspsc190501.10131700'),
	'identifierSchemeCodeUnspsc190501.10131701': __('identifierSchemeCodeUnspsc190501.10131701'),
	'identifierSchemeCodeUnspsc190501.10131702': __('identifierSchemeCodeUnspsc190501.10131702'),
	'identifierSchemeCodeUnspsc190501.10140000': __('identifierSchemeCodeUnspsc190501.10140000'),
	'identifierSchemeCodeUnspsc190501.10141500': __('identifierSchemeCodeUnspsc190501.10141500'),
	'identifierSchemeCodeUnspsc190501.10141501': __('identifierSchemeCodeUnspsc190501.10141501'),
	'identifierSchemeCodeUnspsc190501.10141502': __('identifierSchemeCodeUnspsc190501.10141502'),
	'identifierSchemeCodeUnspsc190501.10141503': __('identifierSchemeCodeUnspsc190501.10141503'),
	'identifierSchemeCodeUnspsc190501.10141504': __('identifierSchemeCodeUnspsc190501.10141504'),
	'identifierSchemeCodeUnspsc190501.10141505': __('identifierSchemeCodeUnspsc190501.10141505'),
	'identifierSchemeCodeUnspsc190501.10141600': __('identifierSchemeCodeUnspsc190501.10141600'),
	'identifierSchemeCodeUnspsc190501.10141601': __('identifierSchemeCodeUnspsc190501.10141601'),
	'identifierSchemeCodeUnspsc190501.10141602': __('identifierSchemeCodeUnspsc190501.10141602'),
	'identifierSchemeCodeUnspsc190501.10141603': __('identifierSchemeCodeUnspsc190501.10141603'),
	'identifierSchemeCodeUnspsc190501.10141604': __('identifierSchemeCodeUnspsc190501.10141604'),
	'identifierSchemeCodeUnspsc190501.10141605': __('identifierSchemeCodeUnspsc190501.10141605'),
	'identifierSchemeCodeUnspsc190501.10141606': __('identifierSchemeCodeUnspsc190501.10141606'),
	'identifierSchemeCodeUnspsc190501.10141607': __('identifierSchemeCodeUnspsc190501.10141607'),
	'identifierSchemeCodeUnspsc190501.10141608': __('identifierSchemeCodeUnspsc190501.10141608'),
	'identifierSchemeCodeUnspsc190501.10141609': __('identifierSchemeCodeUnspsc190501.10141609'),
	'identifierSchemeCodeUnspsc190501.10141610': __('identifierSchemeCodeUnspsc190501.10141610'),
	'identifierSchemeCodeUnspsc190501.10141611': __('identifierSchemeCodeUnspsc190501.10141611'),
	'identifierSchemeCodeUnspsc190501.10141612': __('identifierSchemeCodeUnspsc190501.10141612'),
	'identifierSchemeCodeUnspsc190501.10150000': __('identifierSchemeCodeUnspsc190501.10150000'),
	'identifierSchemeCodeUnspsc190501.10151500': __('identifierSchemeCodeUnspsc190501.10151500'),
	'identifierSchemeCodeUnspsc190501.10151503': __('identifierSchemeCodeUnspsc190501.10151503'),
	'identifierSchemeCodeUnspsc190501.10151504': __('identifierSchemeCodeUnspsc190501.10151504'),
	'identifierSchemeCodeUnspsc190501.10151505': __('identifierSchemeCodeUnspsc190501.10151505'),
	'identifierSchemeCodeUnspsc190501.10151506': __('identifierSchemeCodeUnspsc190501.10151506'),
	'identifierSchemeCodeUnspsc190501.10151507': __('identifierSchemeCodeUnspsc190501.10151507'),
	'identifierSchemeCodeUnspsc190501.10151508': __('identifierSchemeCodeUnspsc190501.10151508'),
	'identifierSchemeCodeUnspsc190501.10151509': __('identifierSchemeCodeUnspsc190501.10151509'),
	'identifierSchemeCodeUnspsc190501.10151510': __('identifierSchemeCodeUnspsc190501.10151510'),
	'identifierSchemeCodeUnspsc190501.10151511': __('identifierSchemeCodeUnspsc190501.10151511'),
	'identifierSchemeCodeUnspsc190501.10151512': __('identifierSchemeCodeUnspsc190501.10151512'),
	'identifierSchemeCodeUnspsc190501.10151513': __('identifierSchemeCodeUnspsc190501.10151513'),
	'identifierSchemeCodeUnspsc190501.10151514': __('identifierSchemeCodeUnspsc190501.10151514'),
	'identifierSchemeCodeUnspsc190501.10151515': __('identifierSchemeCodeUnspsc190501.10151515'),
	'identifierSchemeCodeUnspsc190501.10151517': __('identifierSchemeCodeUnspsc190501.10151517'),
	'identifierSchemeCodeUnspsc190501.10151518': __('identifierSchemeCodeUnspsc190501.10151518'),
	'identifierSchemeCodeUnspsc190501.10151520': __('identifierSchemeCodeUnspsc190501.10151520'),
	'identifierSchemeCodeUnspsc190501.10151521': __('identifierSchemeCodeUnspsc190501.10151521'),
	'identifierSchemeCodeUnspsc190501.10151522': __('identifierSchemeCodeUnspsc190501.10151522'),
	'identifierSchemeCodeUnspsc190501.10151523': __('identifierSchemeCodeUnspsc190501.10151523'),
	'identifierSchemeCodeUnspsc190501.10151524': __('identifierSchemeCodeUnspsc190501.10151524'),
	'identifierSchemeCodeUnspsc190501.10151525': __('identifierSchemeCodeUnspsc190501.10151525'),
	'identifierSchemeCodeUnspsc190501.10151526': __('identifierSchemeCodeUnspsc190501.10151526'),
	'identifierSchemeCodeUnspsc190501.10151529': __('identifierSchemeCodeUnspsc190501.10151529'),
	'identifierSchemeCodeUnspsc190501.10151531': __('identifierSchemeCodeUnspsc190501.10151531'),
	'identifierSchemeCodeUnspsc190501.10151532': __('identifierSchemeCodeUnspsc190501.10151532'),
	'identifierSchemeCodeUnspsc190501.10151533': __('identifierSchemeCodeUnspsc190501.10151533'),
	'identifierSchemeCodeUnspsc190501.10151534': __('identifierSchemeCodeUnspsc190501.10151534'),
	'identifierSchemeCodeUnspsc190501.10151535': __('identifierSchemeCodeUnspsc190501.10151535'),
	'identifierSchemeCodeUnspsc190501.10151536': __('identifierSchemeCodeUnspsc190501.10151536'),
	'identifierSchemeCodeUnspsc190501.10151537': __('identifierSchemeCodeUnspsc190501.10151537'),
	'identifierSchemeCodeUnspsc190501.10151538': __('identifierSchemeCodeUnspsc190501.10151538'),
	'identifierSchemeCodeUnspsc190501.10151539': __('identifierSchemeCodeUnspsc190501.10151539'),
	'identifierSchemeCodeUnspsc190501.10151540': __('identifierSchemeCodeUnspsc190501.10151540'),
	'identifierSchemeCodeUnspsc190501.10151541': __('identifierSchemeCodeUnspsc190501.10151541'),
	'identifierSchemeCodeUnspsc190501.10151600': __('identifierSchemeCodeUnspsc190501.10151600'),
	'identifierSchemeCodeUnspsc190501.10151601': __('identifierSchemeCodeUnspsc190501.10151601'),
	'identifierSchemeCodeUnspsc190501.10151602': __('identifierSchemeCodeUnspsc190501.10151602'),
	'identifierSchemeCodeUnspsc190501.10151603': __('identifierSchemeCodeUnspsc190501.10151603'),
	'identifierSchemeCodeUnspsc190501.10151604': __('identifierSchemeCodeUnspsc190501.10151604'),
	'identifierSchemeCodeUnspsc190501.10151605': __('identifierSchemeCodeUnspsc190501.10151605'),
	'identifierSchemeCodeUnspsc190501.10151606': __('identifierSchemeCodeUnspsc190501.10151606'),
	'identifierSchemeCodeUnspsc190501.10151607': __('identifierSchemeCodeUnspsc190501.10151607'),
	'identifierSchemeCodeUnspsc190501.10151608': __('identifierSchemeCodeUnspsc190501.10151608'),
	'identifierSchemeCodeUnspsc190501.10151609': __('identifierSchemeCodeUnspsc190501.10151609'),
	'identifierSchemeCodeUnspsc190501.10151610': __('identifierSchemeCodeUnspsc190501.10151610'),
	'identifierSchemeCodeUnspsc190501.10151611': __('identifierSchemeCodeUnspsc190501.10151611'),
	'identifierSchemeCodeUnspsc190501.10151612': __('identifierSchemeCodeUnspsc190501.10151612'),
	'identifierSchemeCodeUnspsc190501.10151613': __('identifierSchemeCodeUnspsc190501.10151613'),
	'identifierSchemeCodeUnspsc190501.10151614': __('identifierSchemeCodeUnspsc190501.10151614'),
	'identifierSchemeCodeUnspsc190501.10151615': __('identifierSchemeCodeUnspsc190501.10151615'),
	'identifierSchemeCodeUnspsc190501.10151616': __('identifierSchemeCodeUnspsc190501.10151616'),
	'identifierSchemeCodeUnspsc190501.10151617': __('identifierSchemeCodeUnspsc190501.10151617'),
	'identifierSchemeCodeUnspsc190501.10151700': __('identifierSchemeCodeUnspsc190501.10151700'),
	'identifierSchemeCodeUnspsc190501.10151702': __('identifierSchemeCodeUnspsc190501.10151702'),
	'identifierSchemeCodeUnspsc190501.10151703': __('identifierSchemeCodeUnspsc190501.10151703'),
	'identifierSchemeCodeUnspsc190501.10151704': __('identifierSchemeCodeUnspsc190501.10151704'),
	'identifierSchemeCodeUnspsc190501.10151705': __('identifierSchemeCodeUnspsc190501.10151705'),
	'identifierSchemeCodeUnspsc190501.10151706': __('identifierSchemeCodeUnspsc190501.10151706'),
	'identifierSchemeCodeUnspsc190501.10151707': __('identifierSchemeCodeUnspsc190501.10151707'),
	'identifierSchemeCodeUnspsc190501.10151800': __('identifierSchemeCodeUnspsc190501.10151800'),
	'identifierSchemeCodeUnspsc190501.10151801': __('identifierSchemeCodeUnspsc190501.10151801'),
	'identifierSchemeCodeUnspsc190501.10151802': __('identifierSchemeCodeUnspsc190501.10151802'),
	'identifierSchemeCodeUnspsc190501.10151803': __('identifierSchemeCodeUnspsc190501.10151803'),
	'identifierSchemeCodeUnspsc190501.10151804': __('identifierSchemeCodeUnspsc190501.10151804'),
	'identifierSchemeCodeUnspsc190501.10151805': __('identifierSchemeCodeUnspsc190501.10151805'),
	'identifierSchemeCodeUnspsc190501.10151806': __('identifierSchemeCodeUnspsc190501.10151806'),
	'identifierSchemeCodeUnspsc190501.10151807': __('identifierSchemeCodeUnspsc190501.10151807'),
	'identifierSchemeCodeUnspsc190501.10151808': __('identifierSchemeCodeUnspsc190501.10151808'),
	'identifierSchemeCodeUnspsc190501.10151809': __('identifierSchemeCodeUnspsc190501.10151809'),
	'identifierSchemeCodeUnspsc190501.10151810': __('identifierSchemeCodeUnspsc190501.10151810'),
	'identifierSchemeCodeUnspsc190501.10151811': __('identifierSchemeCodeUnspsc190501.10151811'),
	'identifierSchemeCodeUnspsc190501.10151812': __('identifierSchemeCodeUnspsc190501.10151812'),
	'identifierSchemeCodeUnspsc190501.10151813': __('identifierSchemeCodeUnspsc190501.10151813'),
	'identifierSchemeCodeUnspsc190501.10151814': __('identifierSchemeCodeUnspsc190501.10151814'),
	'identifierSchemeCodeUnspsc190501.10151815': __('identifierSchemeCodeUnspsc190501.10151815'),
	'identifierSchemeCodeUnspsc190501.10151816': __('identifierSchemeCodeUnspsc190501.10151816'),
	'identifierSchemeCodeUnspsc190501.10151817': __('identifierSchemeCodeUnspsc190501.10151817'),
	'identifierSchemeCodeUnspsc190501.10151818': __('identifierSchemeCodeUnspsc190501.10151818'),
	'identifierSchemeCodeUnspsc190501.10151819': __('identifierSchemeCodeUnspsc190501.10151819'),
	'identifierSchemeCodeUnspsc190501.10151900': __('identifierSchemeCodeUnspsc190501.10151900'),
	'identifierSchemeCodeUnspsc190501.10151901': __('identifierSchemeCodeUnspsc190501.10151901'),
	'identifierSchemeCodeUnspsc190501.10151902': __('identifierSchemeCodeUnspsc190501.10151902'),
	'identifierSchemeCodeUnspsc190501.10151903': __('identifierSchemeCodeUnspsc190501.10151903'),
	'identifierSchemeCodeUnspsc190501.10151904': __('identifierSchemeCodeUnspsc190501.10151904'),
	'identifierSchemeCodeUnspsc190501.10151905': __('identifierSchemeCodeUnspsc190501.10151905'),
	'identifierSchemeCodeUnspsc190501.10151906': __('identifierSchemeCodeUnspsc190501.10151906'),
	'identifierSchemeCodeUnspsc190501.10151907': __('identifierSchemeCodeUnspsc190501.10151907'),
	'identifierSchemeCodeUnspsc190501.10151908': __('identifierSchemeCodeUnspsc190501.10151908'),
	'identifierSchemeCodeUnspsc190501.10151909': __('identifierSchemeCodeUnspsc190501.10151909'),
	'identifierSchemeCodeUnspsc190501.10151910': __('identifierSchemeCodeUnspsc190501.10151910'),
	'identifierSchemeCodeUnspsc190501.10151911': __('identifierSchemeCodeUnspsc190501.10151911'),
	'identifierSchemeCodeUnspsc190501.10151912': __('identifierSchemeCodeUnspsc190501.10151912'),
	'identifierSchemeCodeUnspsc190501.10151913': __('identifierSchemeCodeUnspsc190501.10151913'),
	'identifierSchemeCodeUnspsc190501.10151914': __('identifierSchemeCodeUnspsc190501.10151914'),
	'identifierSchemeCodeUnspsc190501.10151915': __('identifierSchemeCodeUnspsc190501.10151915'),
	'identifierSchemeCodeUnspsc190501.10151916': __('identifierSchemeCodeUnspsc190501.10151916'),
	'identifierSchemeCodeUnspsc190501.10151917': __('identifierSchemeCodeUnspsc190501.10151917'),
	'identifierSchemeCodeUnspsc190501.10151918': __('identifierSchemeCodeUnspsc190501.10151918'),
	'identifierSchemeCodeUnspsc190501.10151919': __('identifierSchemeCodeUnspsc190501.10151919'),
	'identifierSchemeCodeUnspsc190501.10151920': __('identifierSchemeCodeUnspsc190501.10151920'),
	'identifierSchemeCodeUnspsc190501.10151921': __('identifierSchemeCodeUnspsc190501.10151921'),
	'identifierSchemeCodeUnspsc190501.10151922': __('identifierSchemeCodeUnspsc190501.10151922'),
	'identifierSchemeCodeUnspsc190501.10151923': __('identifierSchemeCodeUnspsc190501.10151923'),
	'identifierSchemeCodeUnspsc190501.10151924': __('identifierSchemeCodeUnspsc190501.10151924'),
	'identifierSchemeCodeUnspsc190501.10152000': __('identifierSchemeCodeUnspsc190501.10152000'),
	'identifierSchemeCodeUnspsc190501.10152001': __('identifierSchemeCodeUnspsc190501.10152001'),
	'identifierSchemeCodeUnspsc190501.10152002': __('identifierSchemeCodeUnspsc190501.10152002'),
	'identifierSchemeCodeUnspsc190501.10152003': __('identifierSchemeCodeUnspsc190501.10152003'),
	'identifierSchemeCodeUnspsc190501.10152004': __('identifierSchemeCodeUnspsc190501.10152004'),
	'identifierSchemeCodeUnspsc190501.10152005': __('identifierSchemeCodeUnspsc190501.10152005'),
	'identifierSchemeCodeUnspsc190501.10152006': __('identifierSchemeCodeUnspsc190501.10152006'),
	'identifierSchemeCodeUnspsc190501.10152007': __('identifierSchemeCodeUnspsc190501.10152007'),
	'identifierSchemeCodeUnspsc190501.10152008': __('identifierSchemeCodeUnspsc190501.10152008'),
	'identifierSchemeCodeUnspsc190501.10152009': __('identifierSchemeCodeUnspsc190501.10152009'),
	'identifierSchemeCodeUnspsc190501.10152010': __('identifierSchemeCodeUnspsc190501.10152010'),
	'identifierSchemeCodeUnspsc190501.10152011': __('identifierSchemeCodeUnspsc190501.10152011'),
	'identifierSchemeCodeUnspsc190501.10152012': __('identifierSchemeCodeUnspsc190501.10152012'),
	'identifierSchemeCodeUnspsc190501.10152013': __('identifierSchemeCodeUnspsc190501.10152013'),
	'identifierSchemeCodeUnspsc190501.10152014': __('identifierSchemeCodeUnspsc190501.10152014'),
	'identifierSchemeCodeUnspsc190501.10152015': __('identifierSchemeCodeUnspsc190501.10152015'),
	'identifierSchemeCodeUnspsc190501.10152016': __('identifierSchemeCodeUnspsc190501.10152016'),
	'identifierSchemeCodeUnspsc190501.10152017': __('identifierSchemeCodeUnspsc190501.10152017'),
	'identifierSchemeCodeUnspsc190501.10152018': __('identifierSchemeCodeUnspsc190501.10152018'),
	'identifierSchemeCodeUnspsc190501.10152019': __('identifierSchemeCodeUnspsc190501.10152019'),
	'identifierSchemeCodeUnspsc190501.10152020': __('identifierSchemeCodeUnspsc190501.10152020'),
	'identifierSchemeCodeUnspsc190501.10152021': __('identifierSchemeCodeUnspsc190501.10152021'),
	'identifierSchemeCodeUnspsc190501.10152022': __('identifierSchemeCodeUnspsc190501.10152022'),
	'identifierSchemeCodeUnspsc190501.10152023': __('identifierSchemeCodeUnspsc190501.10152023'),
	'identifierSchemeCodeUnspsc190501.10152024': __('identifierSchemeCodeUnspsc190501.10152024'),
	'identifierSchemeCodeUnspsc190501.10152025': __('identifierSchemeCodeUnspsc190501.10152025'),
	'identifierSchemeCodeUnspsc190501.10152026': __('identifierSchemeCodeUnspsc190501.10152026'),
	'identifierSchemeCodeUnspsc190501.10152027': __('identifierSchemeCodeUnspsc190501.10152027'),
	'identifierSchemeCodeUnspsc190501.10152028': __('identifierSchemeCodeUnspsc190501.10152028'),
	'identifierSchemeCodeUnspsc190501.10152029': __('identifierSchemeCodeUnspsc190501.10152029'),
	'identifierSchemeCodeUnspsc190501.10152030': __('identifierSchemeCodeUnspsc190501.10152030'),
	'identifierSchemeCodeUnspsc190501.10152031': __('identifierSchemeCodeUnspsc190501.10152031'),
	'identifierSchemeCodeUnspsc190501.10152032': __('identifierSchemeCodeUnspsc190501.10152032'),
	'identifierSchemeCodeUnspsc190501.10152033': __('identifierSchemeCodeUnspsc190501.10152033'),
	'identifierSchemeCodeUnspsc190501.10152034': __('identifierSchemeCodeUnspsc190501.10152034'),
	'identifierSchemeCodeUnspsc190501.10152035': __('identifierSchemeCodeUnspsc190501.10152035'),
	'identifierSchemeCodeUnspsc190501.10152036': __('identifierSchemeCodeUnspsc190501.10152036'),
	'identifierSchemeCodeUnspsc190501.10152037': __('identifierSchemeCodeUnspsc190501.10152037'),
	'identifierSchemeCodeUnspsc190501.10152038': __('identifierSchemeCodeUnspsc190501.10152038'),
	'identifierSchemeCodeUnspsc190501.10152039': __('identifierSchemeCodeUnspsc190501.10152039'),
	'identifierSchemeCodeUnspsc190501.10152040': __('identifierSchemeCodeUnspsc190501.10152040'),
	'identifierSchemeCodeUnspsc190501.10152041': __('identifierSchemeCodeUnspsc190501.10152041'),
	'identifierSchemeCodeUnspsc190501.10152042': __('identifierSchemeCodeUnspsc190501.10152042'),
	'identifierSchemeCodeUnspsc190501.10152043': __('identifierSchemeCodeUnspsc190501.10152043'),
	'identifierSchemeCodeUnspsc190501.10152044': __('identifierSchemeCodeUnspsc190501.10152044'),
	'identifierSchemeCodeUnspsc190501.10152045': __('identifierSchemeCodeUnspsc190501.10152045'),
	'identifierSchemeCodeUnspsc190501.10152046': __('identifierSchemeCodeUnspsc190501.10152046'),
	'identifierSchemeCodeUnspsc190501.10152047': __('identifierSchemeCodeUnspsc190501.10152047'),
	'identifierSchemeCodeUnspsc190501.10152048': __('identifierSchemeCodeUnspsc190501.10152048'),
	'identifierSchemeCodeUnspsc190501.10152049': __('identifierSchemeCodeUnspsc190501.10152049'),
	'identifierSchemeCodeUnspsc190501.10152050': __('identifierSchemeCodeUnspsc190501.10152050'),
	'identifierSchemeCodeUnspsc190501.10152051': __('identifierSchemeCodeUnspsc190501.10152051'),
	'identifierSchemeCodeUnspsc190501.10152052': __('identifierSchemeCodeUnspsc190501.10152052'),
	'identifierSchemeCodeUnspsc190501.10152053': __('identifierSchemeCodeUnspsc190501.10152053'),
	'identifierSchemeCodeUnspsc190501.10152054': __('identifierSchemeCodeUnspsc190501.10152054'),
	'identifierSchemeCodeUnspsc190501.10152055': __('identifierSchemeCodeUnspsc190501.10152055'),
	'identifierSchemeCodeUnspsc190501.10152056': __('identifierSchemeCodeUnspsc190501.10152056'),
	'identifierSchemeCodeUnspsc190501.10152057': __('identifierSchemeCodeUnspsc190501.10152057'),
	'identifierSchemeCodeUnspsc190501.10152058': __('identifierSchemeCodeUnspsc190501.10152058'),
	'identifierSchemeCodeUnspsc190501.10152059': __('identifierSchemeCodeUnspsc190501.10152059'),
	'identifierSchemeCodeUnspsc190501.10152060': __('identifierSchemeCodeUnspsc190501.10152060'),
	'identifierSchemeCodeUnspsc190501.10152061': __('identifierSchemeCodeUnspsc190501.10152061'),
	'identifierSchemeCodeUnspsc190501.10152062': __('identifierSchemeCodeUnspsc190501.10152062'),
	'identifierSchemeCodeUnspsc190501.10152063': __('identifierSchemeCodeUnspsc190501.10152063'),
	'identifierSchemeCodeUnspsc190501.10152064': __('identifierSchemeCodeUnspsc190501.10152064'),
	'identifierSchemeCodeUnspsc190501.10152100': __('identifierSchemeCodeUnspsc190501.10152100'),
	'identifierSchemeCodeUnspsc190501.10152101': __('identifierSchemeCodeUnspsc190501.10152101'),
	'identifierSchemeCodeUnspsc190501.10152102': __('identifierSchemeCodeUnspsc190501.10152102'),
	'identifierSchemeCodeUnspsc190501.10152103': __('identifierSchemeCodeUnspsc190501.10152103'),
	'identifierSchemeCodeUnspsc190501.10152104': __('identifierSchemeCodeUnspsc190501.10152104'),
	'identifierSchemeCodeUnspsc190501.10152200': __('identifierSchemeCodeUnspsc190501.10152200'),
	'identifierSchemeCodeUnspsc190501.10152201': __('identifierSchemeCodeUnspsc190501.10152201'),
	'identifierSchemeCodeUnspsc190501.10152202': __('identifierSchemeCodeUnspsc190501.10152202'),
	'identifierSchemeCodeUnspsc190501.10152300': __('identifierSchemeCodeUnspsc190501.10152300'),
	'identifierSchemeCodeUnspsc190501.10152301': __('identifierSchemeCodeUnspsc190501.10152301'),
	'identifierSchemeCodeUnspsc190501.10152302': __('identifierSchemeCodeUnspsc190501.10152302'),
	'identifierSchemeCodeUnspsc190501.10152400': __('identifierSchemeCodeUnspsc190501.10152400'),
	'identifierSchemeCodeUnspsc190501.10152401': __('identifierSchemeCodeUnspsc190501.10152401'),
	'identifierSchemeCodeUnspsc190501.10152402': __('identifierSchemeCodeUnspsc190501.10152402'),
	'identifierSchemeCodeUnspsc190501.10152403': __('identifierSchemeCodeUnspsc190501.10152403'),
	'identifierSchemeCodeUnspsc190501.10152404': __('identifierSchemeCodeUnspsc190501.10152404'),
	'identifierSchemeCodeUnspsc190501.10152405': __('identifierSchemeCodeUnspsc190501.10152405'),
	'identifierSchemeCodeUnspsc190501.10160000': __('identifierSchemeCodeUnspsc190501.10160000'),
	'identifierSchemeCodeUnspsc190501.10161500': __('identifierSchemeCodeUnspsc190501.10161500'),
	'identifierSchemeCodeUnspsc190501.10161501': __('identifierSchemeCodeUnspsc190501.10161501'),
	'identifierSchemeCodeUnspsc190501.10161502': __('identifierSchemeCodeUnspsc190501.10161502'),
	'identifierSchemeCodeUnspsc190501.10161503': __('identifierSchemeCodeUnspsc190501.10161503'),
	'identifierSchemeCodeUnspsc190501.10161504': __('identifierSchemeCodeUnspsc190501.10161504'),
	'identifierSchemeCodeUnspsc190501.10161505': __('identifierSchemeCodeUnspsc190501.10161505'),
	'identifierSchemeCodeUnspsc190501.10161506': __('identifierSchemeCodeUnspsc190501.10161506'),
	'identifierSchemeCodeUnspsc190501.10161507': __('identifierSchemeCodeUnspsc190501.10161507'),
	'identifierSchemeCodeUnspsc190501.10161508': __('identifierSchemeCodeUnspsc190501.10161508'),
	'identifierSchemeCodeUnspsc190501.10161509': __('identifierSchemeCodeUnspsc190501.10161509'),
	'identifierSchemeCodeUnspsc190501.10161510': __('identifierSchemeCodeUnspsc190501.10161510'),
	'identifierSchemeCodeUnspsc190501.10161511': __('identifierSchemeCodeUnspsc190501.10161511'),
	'identifierSchemeCodeUnspsc190501.10161512': __('identifierSchemeCodeUnspsc190501.10161512'),
	'identifierSchemeCodeUnspsc190501.10161513': __('identifierSchemeCodeUnspsc190501.10161513'),
	'identifierSchemeCodeUnspsc190501.10161514': __('identifierSchemeCodeUnspsc190501.10161514'),
	'identifierSchemeCodeUnspsc190501.10161515': __('identifierSchemeCodeUnspsc190501.10161515'),
	'identifierSchemeCodeUnspsc190501.10161516': __('identifierSchemeCodeUnspsc190501.10161516'),
	'identifierSchemeCodeUnspsc190501.10161517': __('identifierSchemeCodeUnspsc190501.10161517'),
	'identifierSchemeCodeUnspsc190501.10161518': __('identifierSchemeCodeUnspsc190501.10161518'),
	'identifierSchemeCodeUnspsc190501.10161519': __('identifierSchemeCodeUnspsc190501.10161519'),
	'identifierSchemeCodeUnspsc190501.10161520': __('identifierSchemeCodeUnspsc190501.10161520'),
	'identifierSchemeCodeUnspsc190501.10161521': __('identifierSchemeCodeUnspsc190501.10161521'),
	'identifierSchemeCodeUnspsc190501.10161522': __('identifierSchemeCodeUnspsc190501.10161522'),
	'identifierSchemeCodeUnspsc190501.10161523': __('identifierSchemeCodeUnspsc190501.10161523'),
	'identifierSchemeCodeUnspsc190501.10161524': __('identifierSchemeCodeUnspsc190501.10161524'),
	'identifierSchemeCodeUnspsc190501.10161525': __('identifierSchemeCodeUnspsc190501.10161525'),
	'identifierSchemeCodeUnspsc190501.10161526': __('identifierSchemeCodeUnspsc190501.10161526'),
	'identifierSchemeCodeUnspsc190501.10161527': __('identifierSchemeCodeUnspsc190501.10161527'),
	'identifierSchemeCodeUnspsc190501.10161528': __('identifierSchemeCodeUnspsc190501.10161528'),
	'identifierSchemeCodeUnspsc190501.10161529': __('identifierSchemeCodeUnspsc190501.10161529'),
	'identifierSchemeCodeUnspsc190501.10161530': __('identifierSchemeCodeUnspsc190501.10161530'),
	'identifierSchemeCodeUnspsc190501.10161531': __('identifierSchemeCodeUnspsc190501.10161531'),
	'identifierSchemeCodeUnspsc190501.10161532': __('identifierSchemeCodeUnspsc190501.10161532'),
	'identifierSchemeCodeUnspsc190501.10161533': __('identifierSchemeCodeUnspsc190501.10161533'),
	'identifierSchemeCodeUnspsc190501.10161534': __('identifierSchemeCodeUnspsc190501.10161534'),
	'identifierSchemeCodeUnspsc190501.10161535': __('identifierSchemeCodeUnspsc190501.10161535'),
	'identifierSchemeCodeUnspsc190501.10161536': __('identifierSchemeCodeUnspsc190501.10161536'),
	'identifierSchemeCodeUnspsc190501.10161537': __('identifierSchemeCodeUnspsc190501.10161537'),
	'identifierSchemeCodeUnspsc190501.10161538': __('identifierSchemeCodeUnspsc190501.10161538'),
	'identifierSchemeCodeUnspsc190501.10161539': __('identifierSchemeCodeUnspsc190501.10161539'),
	'identifierSchemeCodeUnspsc190501.10161540': __('identifierSchemeCodeUnspsc190501.10161540'),
	'identifierSchemeCodeUnspsc190501.10161541': __('identifierSchemeCodeUnspsc190501.10161541'),
	'identifierSchemeCodeUnspsc190501.10161542': __('identifierSchemeCodeUnspsc190501.10161542'),
	'identifierSchemeCodeUnspsc190501.10161543': __('identifierSchemeCodeUnspsc190501.10161543'),
	'identifierSchemeCodeUnspsc190501.10161544': __('identifierSchemeCodeUnspsc190501.10161544'),
	'identifierSchemeCodeUnspsc190501.10161545': __('identifierSchemeCodeUnspsc190501.10161545'),
	'identifierSchemeCodeUnspsc190501.10161546': __('identifierSchemeCodeUnspsc190501.10161546'),
	'identifierSchemeCodeUnspsc190501.10161547': __('identifierSchemeCodeUnspsc190501.10161547'),
	'identifierSchemeCodeUnspsc190501.10161548': __('identifierSchemeCodeUnspsc190501.10161548'),
	'identifierSchemeCodeUnspsc190501.10161549': __('identifierSchemeCodeUnspsc190501.10161549'),
	'identifierSchemeCodeUnspsc190501.10161550': __('identifierSchemeCodeUnspsc190501.10161550'),
	'identifierSchemeCodeUnspsc190501.10161551': __('identifierSchemeCodeUnspsc190501.10161551'),
	'identifierSchemeCodeUnspsc190501.10161552': __('identifierSchemeCodeUnspsc190501.10161552'),
	'identifierSchemeCodeUnspsc190501.10161553': __('identifierSchemeCodeUnspsc190501.10161553'),
	'identifierSchemeCodeUnspsc190501.10161554': __('identifierSchemeCodeUnspsc190501.10161554'),
	'identifierSchemeCodeUnspsc190501.10161555': __('identifierSchemeCodeUnspsc190501.10161555'),
	'identifierSchemeCodeUnspsc190501.10161556': __('identifierSchemeCodeUnspsc190501.10161556'),
	'identifierSchemeCodeUnspsc190501.10161557': __('identifierSchemeCodeUnspsc190501.10161557'),
	'identifierSchemeCodeUnspsc190501.10161558': __('identifierSchemeCodeUnspsc190501.10161558'),
	'identifierSchemeCodeUnspsc190501.10161559': __('identifierSchemeCodeUnspsc190501.10161559'),
	'identifierSchemeCodeUnspsc190501.10161560': __('identifierSchemeCodeUnspsc190501.10161560'),
	'identifierSchemeCodeUnspsc190501.10161561': __('identifierSchemeCodeUnspsc190501.10161561'),
	'identifierSchemeCodeUnspsc190501.10161562': __('identifierSchemeCodeUnspsc190501.10161562'),
	'identifierSchemeCodeUnspsc190501.10161563': __('identifierSchemeCodeUnspsc190501.10161563'),
	'identifierSchemeCodeUnspsc190501.10161564': __('identifierSchemeCodeUnspsc190501.10161564'),
	'identifierSchemeCodeUnspsc190501.10161565': __('identifierSchemeCodeUnspsc190501.10161565'),
	'identifierSchemeCodeUnspsc190501.10161566': __('identifierSchemeCodeUnspsc190501.10161566'),
	'identifierSchemeCodeUnspsc190501.10161567': __('identifierSchemeCodeUnspsc190501.10161567'),
	'identifierSchemeCodeUnspsc190501.10161568': __('identifierSchemeCodeUnspsc190501.10161568'),
	'identifierSchemeCodeUnspsc190501.10161569': __('identifierSchemeCodeUnspsc190501.10161569'),
	'identifierSchemeCodeUnspsc190501.10161570': __('identifierSchemeCodeUnspsc190501.10161570'),
	'identifierSchemeCodeUnspsc190501.10161571': __('identifierSchemeCodeUnspsc190501.10161571'),
	'identifierSchemeCodeUnspsc190501.10161572': __('identifierSchemeCodeUnspsc190501.10161572'),
	'identifierSchemeCodeUnspsc190501.10161573': __('identifierSchemeCodeUnspsc190501.10161573'),
	'identifierSchemeCodeUnspsc190501.10161574': __('identifierSchemeCodeUnspsc190501.10161574'),
	'identifierSchemeCodeUnspsc190501.10161575': __('identifierSchemeCodeUnspsc190501.10161575'),
	'identifierSchemeCodeUnspsc190501.10161576': __('identifierSchemeCodeUnspsc190501.10161576'),
	'identifierSchemeCodeUnspsc190501.10161577': __('identifierSchemeCodeUnspsc190501.10161577'),
	'identifierSchemeCodeUnspsc190501.10161578': __('identifierSchemeCodeUnspsc190501.10161578'),
	'identifierSchemeCodeUnspsc190501.10161579': __('identifierSchemeCodeUnspsc190501.10161579'),
	'identifierSchemeCodeUnspsc190501.10161600': __('identifierSchemeCodeUnspsc190501.10161600'),
	'identifierSchemeCodeUnspsc190501.10161602': __('identifierSchemeCodeUnspsc190501.10161602'),
	'identifierSchemeCodeUnspsc190501.10161604': __('identifierSchemeCodeUnspsc190501.10161604'),
	'identifierSchemeCodeUnspsc190501.10161605': __('identifierSchemeCodeUnspsc190501.10161605'),
	'identifierSchemeCodeUnspsc190501.10161606': __('identifierSchemeCodeUnspsc190501.10161606'),
	'identifierSchemeCodeUnspsc190501.10161607': __('identifierSchemeCodeUnspsc190501.10161607'),
	'identifierSchemeCodeUnspsc190501.10161608': __('identifierSchemeCodeUnspsc190501.10161608'),
	'identifierSchemeCodeUnspsc190501.10161609': __('identifierSchemeCodeUnspsc190501.10161609'),
	'identifierSchemeCodeUnspsc190501.10161610': __('identifierSchemeCodeUnspsc190501.10161610'),
	'identifierSchemeCodeUnspsc190501.10161611': __('identifierSchemeCodeUnspsc190501.10161611'),
	'identifierSchemeCodeUnspsc190501.10161612': __('identifierSchemeCodeUnspsc190501.10161612'),
	'identifierSchemeCodeUnspsc190501.10161613': __('identifierSchemeCodeUnspsc190501.10161613'),
	'identifierSchemeCodeUnspsc190501.10161614': __('identifierSchemeCodeUnspsc190501.10161614'),
	'identifierSchemeCodeUnspsc190501.10161615': __('identifierSchemeCodeUnspsc190501.10161615'),
	'identifierSchemeCodeUnspsc190501.10161616': __('identifierSchemeCodeUnspsc190501.10161616'),
	'identifierSchemeCodeUnspsc190501.10161617': __('identifierSchemeCodeUnspsc190501.10161617'),
	'identifierSchemeCodeUnspsc190501.10161618': __('identifierSchemeCodeUnspsc190501.10161618'),
	'identifierSchemeCodeUnspsc190501.10161619': __('identifierSchemeCodeUnspsc190501.10161619'),
	'identifierSchemeCodeUnspsc190501.10161620': __('identifierSchemeCodeUnspsc190501.10161620'),
	'identifierSchemeCodeUnspsc190501.10161621': __('identifierSchemeCodeUnspsc190501.10161621'),
	'identifierSchemeCodeUnspsc190501.10161622': __('identifierSchemeCodeUnspsc190501.10161622'),
	'identifierSchemeCodeUnspsc190501.10161623': __('identifierSchemeCodeUnspsc190501.10161623'),
	'identifierSchemeCodeUnspsc190501.10161624': __('identifierSchemeCodeUnspsc190501.10161624'),
	'identifierSchemeCodeUnspsc190501.10161625': __('identifierSchemeCodeUnspsc190501.10161625'),
	'identifierSchemeCodeUnspsc190501.10161626': __('identifierSchemeCodeUnspsc190501.10161626'),
	'identifierSchemeCodeUnspsc190501.10161627': __('identifierSchemeCodeUnspsc190501.10161627'),
	'identifierSchemeCodeUnspsc190501.10161628': __('identifierSchemeCodeUnspsc190501.10161628'),
	'identifierSchemeCodeUnspsc190501.10161629': __('identifierSchemeCodeUnspsc190501.10161629'),
	'identifierSchemeCodeUnspsc190501.10161630': __('identifierSchemeCodeUnspsc190501.10161630'),
	'identifierSchemeCodeUnspsc190501.10161631': __('identifierSchemeCodeUnspsc190501.10161631'),
	'identifierSchemeCodeUnspsc190501.10161632': __('identifierSchemeCodeUnspsc190501.10161632'),
	'identifierSchemeCodeUnspsc190501.10161633': __('identifierSchemeCodeUnspsc190501.10161633'),
	'identifierSchemeCodeUnspsc190501.10161634': __('identifierSchemeCodeUnspsc190501.10161634'),
	'identifierSchemeCodeUnspsc190501.10161635': __('identifierSchemeCodeUnspsc190501.10161635'),
	'identifierSchemeCodeUnspsc190501.10161636': __('identifierSchemeCodeUnspsc190501.10161636'),
	'identifierSchemeCodeUnspsc190501.10161637': __('identifierSchemeCodeUnspsc190501.10161637'),
	'identifierSchemeCodeUnspsc190501.10161638': __('identifierSchemeCodeUnspsc190501.10161638'),
	'identifierSchemeCodeUnspsc190501.10161639': __('identifierSchemeCodeUnspsc190501.10161639'),
	'identifierSchemeCodeUnspsc190501.10161640': __('identifierSchemeCodeUnspsc190501.10161640'),
	'identifierSchemeCodeUnspsc190501.10161641': __('identifierSchemeCodeUnspsc190501.10161641'),
	'identifierSchemeCodeUnspsc190501.10161642': __('identifierSchemeCodeUnspsc190501.10161642'),
	'identifierSchemeCodeUnspsc190501.10161643': __('identifierSchemeCodeUnspsc190501.10161643'),
	'identifierSchemeCodeUnspsc190501.10161644': __('identifierSchemeCodeUnspsc190501.10161644'),
	'identifierSchemeCodeUnspsc190501.10161645': __('identifierSchemeCodeUnspsc190501.10161645'),
	'identifierSchemeCodeUnspsc190501.10161646': __('identifierSchemeCodeUnspsc190501.10161646'),
	'identifierSchemeCodeUnspsc190501.10161647': __('identifierSchemeCodeUnspsc190501.10161647'),
	'identifierSchemeCodeUnspsc190501.10161648': __('identifierSchemeCodeUnspsc190501.10161648'),
	'identifierSchemeCodeUnspsc190501.10161649': __('identifierSchemeCodeUnspsc190501.10161649'),
	'identifierSchemeCodeUnspsc190501.10161650': __('identifierSchemeCodeUnspsc190501.10161650'),
	'identifierSchemeCodeUnspsc190501.10161651': __('identifierSchemeCodeUnspsc190501.10161651'),
	'identifierSchemeCodeUnspsc190501.10161652': __('identifierSchemeCodeUnspsc190501.10161652'),
	'identifierSchemeCodeUnspsc190501.10161653': __('identifierSchemeCodeUnspsc190501.10161653'),
	'identifierSchemeCodeUnspsc190501.10161654': __('identifierSchemeCodeUnspsc190501.10161654'),
	'identifierSchemeCodeUnspsc190501.10161656': __('identifierSchemeCodeUnspsc190501.10161656'),
	'identifierSchemeCodeUnspsc190501.10161657': __('identifierSchemeCodeUnspsc190501.10161657'),
	'identifierSchemeCodeUnspsc190501.10161658': __('identifierSchemeCodeUnspsc190501.10161658'),
	'identifierSchemeCodeUnspsc190501.10161659': __('identifierSchemeCodeUnspsc190501.10161659'),
	'identifierSchemeCodeUnspsc190501.10161800': __('identifierSchemeCodeUnspsc190501.10161800'),
	'identifierSchemeCodeUnspsc190501.10161801': __('identifierSchemeCodeUnspsc190501.10161801'),
	'identifierSchemeCodeUnspsc190501.10161802': __('identifierSchemeCodeUnspsc190501.10161802'),
	'identifierSchemeCodeUnspsc190501.10161803': __('identifierSchemeCodeUnspsc190501.10161803'),
	'identifierSchemeCodeUnspsc190501.10161804': __('identifierSchemeCodeUnspsc190501.10161804'),
	'identifierSchemeCodeUnspsc190501.10161805': __('identifierSchemeCodeUnspsc190501.10161805'),
	'identifierSchemeCodeUnspsc190501.10161806': __('identifierSchemeCodeUnspsc190501.10161806'),
	'identifierSchemeCodeUnspsc190501.10161807': __('identifierSchemeCodeUnspsc190501.10161807'),
	'identifierSchemeCodeUnspsc190501.10161808': __('identifierSchemeCodeUnspsc190501.10161808'),
	'identifierSchemeCodeUnspsc190501.10161809': __('identifierSchemeCodeUnspsc190501.10161809'),
	'identifierSchemeCodeUnspsc190501.10161810': __('identifierSchemeCodeUnspsc190501.10161810'),
	'identifierSchemeCodeUnspsc190501.10161811': __('identifierSchemeCodeUnspsc190501.10161811'),
	'identifierSchemeCodeUnspsc190501.10161812': __('identifierSchemeCodeUnspsc190501.10161812'),
	'identifierSchemeCodeUnspsc190501.10161813': __('identifierSchemeCodeUnspsc190501.10161813'),
	'identifierSchemeCodeUnspsc190501.10161814': __('identifierSchemeCodeUnspsc190501.10161814'),
	'identifierSchemeCodeUnspsc190501.10161815': __('identifierSchemeCodeUnspsc190501.10161815'),
	'identifierSchemeCodeUnspsc190501.10161816': __('identifierSchemeCodeUnspsc190501.10161816'),
	'identifierSchemeCodeUnspsc190501.10161817': __('identifierSchemeCodeUnspsc190501.10161817'),
	'identifierSchemeCodeUnspsc190501.10161818': __('identifierSchemeCodeUnspsc190501.10161818'),
	'identifierSchemeCodeUnspsc190501.10161819': __('identifierSchemeCodeUnspsc190501.10161819'),
	'identifierSchemeCodeUnspsc190501.10161820': __('identifierSchemeCodeUnspsc190501.10161820'),
	'identifierSchemeCodeUnspsc190501.10161821': __('identifierSchemeCodeUnspsc190501.10161821'),
	'identifierSchemeCodeUnspsc190501.10161822': __('identifierSchemeCodeUnspsc190501.10161822'),
	'identifierSchemeCodeUnspsc190501.10161823': __('identifierSchemeCodeUnspsc190501.10161823'),
	'identifierSchemeCodeUnspsc190501.10161824': __('identifierSchemeCodeUnspsc190501.10161824'),
	'identifierSchemeCodeUnspsc190501.10161825': __('identifierSchemeCodeUnspsc190501.10161825'),
	'identifierSchemeCodeUnspsc190501.10161826': __('identifierSchemeCodeUnspsc190501.10161826'),
	'identifierSchemeCodeUnspsc190501.10161827': __('identifierSchemeCodeUnspsc190501.10161827'),
	'identifierSchemeCodeUnspsc190501.10161828': __('identifierSchemeCodeUnspsc190501.10161828'),
	'identifierSchemeCodeUnspsc190501.10161829': __('identifierSchemeCodeUnspsc190501.10161829'),
	'identifierSchemeCodeUnspsc190501.10161830': __('identifierSchemeCodeUnspsc190501.10161830'),
	'identifierSchemeCodeUnspsc190501.10161831': __('identifierSchemeCodeUnspsc190501.10161831'),
	'identifierSchemeCodeUnspsc190501.10161832': __('identifierSchemeCodeUnspsc190501.10161832'),
	'identifierSchemeCodeUnspsc190501.10161833': __('identifierSchemeCodeUnspsc190501.10161833'),
	'identifierSchemeCodeUnspsc190501.10161900': __('identifierSchemeCodeUnspsc190501.10161900'),
	'identifierSchemeCodeUnspsc190501.10161901': __('identifierSchemeCodeUnspsc190501.10161901'),
	'identifierSchemeCodeUnspsc190501.10161902': __('identifierSchemeCodeUnspsc190501.10161902'),
	'identifierSchemeCodeUnspsc190501.10161903': __('identifierSchemeCodeUnspsc190501.10161903'),
	'identifierSchemeCodeUnspsc190501.10161905': __('identifierSchemeCodeUnspsc190501.10161905'),
	'identifierSchemeCodeUnspsc190501.10161906': __('identifierSchemeCodeUnspsc190501.10161906'),
	'identifierSchemeCodeUnspsc190501.10161907': __('identifierSchemeCodeUnspsc190501.10161907'),
	'identifierSchemeCodeUnspsc190501.10161908': __('identifierSchemeCodeUnspsc190501.10161908'),
	'identifierSchemeCodeUnspsc190501.10170000': __('identifierSchemeCodeUnspsc190501.10170000'),
	'identifierSchemeCodeUnspsc190501.10171500': __('identifierSchemeCodeUnspsc190501.10171500'),
	'identifierSchemeCodeUnspsc190501.10171501': __('identifierSchemeCodeUnspsc190501.10171501'),
	'identifierSchemeCodeUnspsc190501.10171502': __('identifierSchemeCodeUnspsc190501.10171502'),
	'identifierSchemeCodeUnspsc190501.10171503': __('identifierSchemeCodeUnspsc190501.10171503'),
	'identifierSchemeCodeUnspsc190501.10171504': __('identifierSchemeCodeUnspsc190501.10171504'),
	'identifierSchemeCodeUnspsc190501.10171505': __('identifierSchemeCodeUnspsc190501.10171505'),
	'identifierSchemeCodeUnspsc190501.10171506': __('identifierSchemeCodeUnspsc190501.10171506'),
	'identifierSchemeCodeUnspsc190501.10171507': __('identifierSchemeCodeUnspsc190501.10171507'),
	'identifierSchemeCodeUnspsc190501.10171600': __('identifierSchemeCodeUnspsc190501.10171600'),
	'identifierSchemeCodeUnspsc190501.10171601': __('identifierSchemeCodeUnspsc190501.10171601'),
	'identifierSchemeCodeUnspsc190501.10171602': __('identifierSchemeCodeUnspsc190501.10171602'),
	'identifierSchemeCodeUnspsc190501.10171603': __('identifierSchemeCodeUnspsc190501.10171603'),
	'identifierSchemeCodeUnspsc190501.10171604': __('identifierSchemeCodeUnspsc190501.10171604'),
	'identifierSchemeCodeUnspsc190501.10171605': __('identifierSchemeCodeUnspsc190501.10171605'),
	'identifierSchemeCodeUnspsc190501.10171606': __('identifierSchemeCodeUnspsc190501.10171606'),
	'identifierSchemeCodeUnspsc190501.10171607': __('identifierSchemeCodeUnspsc190501.10171607'),
	'identifierSchemeCodeUnspsc190501.10171608': __('identifierSchemeCodeUnspsc190501.10171608'),
	'identifierSchemeCodeUnspsc190501.10171609': __('identifierSchemeCodeUnspsc190501.10171609'),
	'identifierSchemeCodeUnspsc190501.10171610': __('identifierSchemeCodeUnspsc190501.10171610'),
	'identifierSchemeCodeUnspsc190501.10171611': __('identifierSchemeCodeUnspsc190501.10171611'),
	'identifierSchemeCodeUnspsc190501.10171612': __('identifierSchemeCodeUnspsc190501.10171612'),
	'identifierSchemeCodeUnspsc190501.10171613': __('identifierSchemeCodeUnspsc190501.10171613'),
	'identifierSchemeCodeUnspsc190501.10171614': __('identifierSchemeCodeUnspsc190501.10171614'),
	'identifierSchemeCodeUnspsc190501.10171615': __('identifierSchemeCodeUnspsc190501.10171615'),
	'identifierSchemeCodeUnspsc190501.10171616': __('identifierSchemeCodeUnspsc190501.10171616'),
	'identifierSchemeCodeUnspsc190501.10171700': __('identifierSchemeCodeUnspsc190501.10171700'),
	'identifierSchemeCodeUnspsc190501.10171701': __('identifierSchemeCodeUnspsc190501.10171701'),
	'identifierSchemeCodeUnspsc190501.10171702': __('identifierSchemeCodeUnspsc190501.10171702'),
	'identifierSchemeCodeUnspsc190501.10171703': __('identifierSchemeCodeUnspsc190501.10171703'),
	'identifierSchemeCodeUnspsc190501.10171800': __('identifierSchemeCodeUnspsc190501.10171800'),
	'identifierSchemeCodeUnspsc190501.10171801': __('identifierSchemeCodeUnspsc190501.10171801'),
	'identifierSchemeCodeUnspsc190501.10171802': __('identifierSchemeCodeUnspsc190501.10171802'),
	'identifierSchemeCodeUnspsc190501.10190000': __('identifierSchemeCodeUnspsc190501.10190000'),
	'identifierSchemeCodeUnspsc190501.10191500': __('identifierSchemeCodeUnspsc190501.10191500'),
	'identifierSchemeCodeUnspsc190501.10191506': __('identifierSchemeCodeUnspsc190501.10191506'),
	'identifierSchemeCodeUnspsc190501.10191507': __('identifierSchemeCodeUnspsc190501.10191507'),
	'identifierSchemeCodeUnspsc190501.10191508': __('identifierSchemeCodeUnspsc190501.10191508'),
	'identifierSchemeCodeUnspsc190501.10191509': __('identifierSchemeCodeUnspsc190501.10191509'),
	'identifierSchemeCodeUnspsc190501.10191510': __('identifierSchemeCodeUnspsc190501.10191510'),
	'identifierSchemeCodeUnspsc190501.10191511': __('identifierSchemeCodeUnspsc190501.10191511'),
	'identifierSchemeCodeUnspsc190501.10191512': __('identifierSchemeCodeUnspsc190501.10191512'),
	'identifierSchemeCodeUnspsc190501.10191513': __('identifierSchemeCodeUnspsc190501.10191513'),
	'identifierSchemeCodeUnspsc190501.10191514': __('identifierSchemeCodeUnspsc190501.10191514'),
	'identifierSchemeCodeUnspsc190501.10191515': __('identifierSchemeCodeUnspsc190501.10191515'),
	'identifierSchemeCodeUnspsc190501.10191516': __('identifierSchemeCodeUnspsc190501.10191516'),
	'identifierSchemeCodeUnspsc190501.10191517': __('identifierSchemeCodeUnspsc190501.10191517'),
	'identifierSchemeCodeUnspsc190501.10191518': __('identifierSchemeCodeUnspsc190501.10191518'),
	'identifierSchemeCodeUnspsc190501.10191519': __('identifierSchemeCodeUnspsc190501.10191519'),
	'identifierSchemeCodeUnspsc190501.10191700': __('identifierSchemeCodeUnspsc190501.10191700'),
	'identifierSchemeCodeUnspsc190501.10191701': __('identifierSchemeCodeUnspsc190501.10191701'),
	'identifierSchemeCodeUnspsc190501.10191703': __('identifierSchemeCodeUnspsc190501.10191703'),
	'identifierSchemeCodeUnspsc190501.10191704': __('identifierSchemeCodeUnspsc190501.10191704'),
	'identifierSchemeCodeUnspsc190501.10191705': __('identifierSchemeCodeUnspsc190501.10191705'),
	'identifierSchemeCodeUnspsc190501.10191706': __('identifierSchemeCodeUnspsc190501.10191706'),
	'identifierSchemeCodeUnspsc190501.10191707': __('identifierSchemeCodeUnspsc190501.10191707'),
	'identifierSchemeCodeUnspsc190501.10200000': __('identifierSchemeCodeUnspsc190501.10200000'),
	'identifierSchemeCodeUnspsc190501.10201500': __('identifierSchemeCodeUnspsc190501.10201500'),
	'identifierSchemeCodeUnspsc190501.10201501': __('identifierSchemeCodeUnspsc190501.10201501'),
	'identifierSchemeCodeUnspsc190501.10201502': __('identifierSchemeCodeUnspsc190501.10201502'),
	'identifierSchemeCodeUnspsc190501.10201503': __('identifierSchemeCodeUnspsc190501.10201503'),
	'identifierSchemeCodeUnspsc190501.10201504': __('identifierSchemeCodeUnspsc190501.10201504'),
	'identifierSchemeCodeUnspsc190501.10201505': __('identifierSchemeCodeUnspsc190501.10201505'),
	'identifierSchemeCodeUnspsc190501.10201506': __('identifierSchemeCodeUnspsc190501.10201506'),
	'identifierSchemeCodeUnspsc190501.10201507': __('identifierSchemeCodeUnspsc190501.10201507'),
	'identifierSchemeCodeUnspsc190501.10201508': __('identifierSchemeCodeUnspsc190501.10201508'),
	'identifierSchemeCodeUnspsc190501.10201509': __('identifierSchemeCodeUnspsc190501.10201509'),
	'identifierSchemeCodeUnspsc190501.10201510': __('identifierSchemeCodeUnspsc190501.10201510'),
	'identifierSchemeCodeUnspsc190501.10201511': __('identifierSchemeCodeUnspsc190501.10201511'),
	'identifierSchemeCodeUnspsc190501.10201512': __('identifierSchemeCodeUnspsc190501.10201512'),
	'identifierSchemeCodeUnspsc190501.10201513': __('identifierSchemeCodeUnspsc190501.10201513'),
	'identifierSchemeCodeUnspsc190501.10201514': __('identifierSchemeCodeUnspsc190501.10201514'),
	'identifierSchemeCodeUnspsc190501.10201515': __('identifierSchemeCodeUnspsc190501.10201515'),
	'identifierSchemeCodeUnspsc190501.10201516': __('identifierSchemeCodeUnspsc190501.10201516'),
	'identifierSchemeCodeUnspsc190501.10201517': __('identifierSchemeCodeUnspsc190501.10201517'),
	'identifierSchemeCodeUnspsc190501.10201518': __('identifierSchemeCodeUnspsc190501.10201518'),
	'identifierSchemeCodeUnspsc190501.10201519': __('identifierSchemeCodeUnspsc190501.10201519'),
	'identifierSchemeCodeUnspsc190501.10201520': __('identifierSchemeCodeUnspsc190501.10201520'),
	'identifierSchemeCodeUnspsc190501.10201521': __('identifierSchemeCodeUnspsc190501.10201521'),
	'identifierSchemeCodeUnspsc190501.10201522': __('identifierSchemeCodeUnspsc190501.10201522'),
	'identifierSchemeCodeUnspsc190501.10201523': __('identifierSchemeCodeUnspsc190501.10201523'),
	'identifierSchemeCodeUnspsc190501.10201600': __('identifierSchemeCodeUnspsc190501.10201600'),
	'identifierSchemeCodeUnspsc190501.10201601': __('identifierSchemeCodeUnspsc190501.10201601'),
	'identifierSchemeCodeUnspsc190501.10201602': __('identifierSchemeCodeUnspsc190501.10201602'),
	'identifierSchemeCodeUnspsc190501.10201603': __('identifierSchemeCodeUnspsc190501.10201603'),
	'identifierSchemeCodeUnspsc190501.10201604': __('identifierSchemeCodeUnspsc190501.10201604'),
	'identifierSchemeCodeUnspsc190501.10201605': __('identifierSchemeCodeUnspsc190501.10201605'),
	'identifierSchemeCodeUnspsc190501.10201606': __('identifierSchemeCodeUnspsc190501.10201606'),
	'identifierSchemeCodeUnspsc190501.10201607': __('identifierSchemeCodeUnspsc190501.10201607'),
	'identifierSchemeCodeUnspsc190501.10201608': __('identifierSchemeCodeUnspsc190501.10201608'),
	'identifierSchemeCodeUnspsc190501.10201609': __('identifierSchemeCodeUnspsc190501.10201609'),
	'identifierSchemeCodeUnspsc190501.10201610': __('identifierSchemeCodeUnspsc190501.10201610'),
	'identifierSchemeCodeUnspsc190501.10201700': __('identifierSchemeCodeUnspsc190501.10201700'),
	'identifierSchemeCodeUnspsc190501.10201701': __('identifierSchemeCodeUnspsc190501.10201701'),
	'identifierSchemeCodeUnspsc190501.10201702': __('identifierSchemeCodeUnspsc190501.10201702'),
	'identifierSchemeCodeUnspsc190501.10201703': __('identifierSchemeCodeUnspsc190501.10201703'),
	'identifierSchemeCodeUnspsc190501.10201704': __('identifierSchemeCodeUnspsc190501.10201704'),
	'identifierSchemeCodeUnspsc190501.10201705': __('identifierSchemeCodeUnspsc190501.10201705'),
	'identifierSchemeCodeUnspsc190501.10201706': __('identifierSchemeCodeUnspsc190501.10201706'),
	'identifierSchemeCodeUnspsc190501.10201707': __('identifierSchemeCodeUnspsc190501.10201707'),
	'identifierSchemeCodeUnspsc190501.10201708': __('identifierSchemeCodeUnspsc190501.10201708'),
	'identifierSchemeCodeUnspsc190501.10201709': __('identifierSchemeCodeUnspsc190501.10201709'),
	'identifierSchemeCodeUnspsc190501.10201710': __('identifierSchemeCodeUnspsc190501.10201710'),
	'identifierSchemeCodeUnspsc190501.10201711': __('identifierSchemeCodeUnspsc190501.10201711'),
	'identifierSchemeCodeUnspsc190501.10201712': __('identifierSchemeCodeUnspsc190501.10201712'),
	'identifierSchemeCodeUnspsc190501.10201713': __('identifierSchemeCodeUnspsc190501.10201713'),
	'identifierSchemeCodeUnspsc190501.10201714': __('identifierSchemeCodeUnspsc190501.10201714'),
	'identifierSchemeCodeUnspsc190501.10201715': __('identifierSchemeCodeUnspsc190501.10201715'),
	'identifierSchemeCodeUnspsc190501.10201716': __('identifierSchemeCodeUnspsc190501.10201716'),
	'identifierSchemeCodeUnspsc190501.10201717': __('identifierSchemeCodeUnspsc190501.10201717'),
	'identifierSchemeCodeUnspsc190501.10201718': __('identifierSchemeCodeUnspsc190501.10201718'),
	'identifierSchemeCodeUnspsc190501.10201719': __('identifierSchemeCodeUnspsc190501.10201719'),
	'identifierSchemeCodeUnspsc190501.10201720': __('identifierSchemeCodeUnspsc190501.10201720'),
	'identifierSchemeCodeUnspsc190501.10201721': __('identifierSchemeCodeUnspsc190501.10201721'),
	'identifierSchemeCodeUnspsc190501.10201722': __('identifierSchemeCodeUnspsc190501.10201722'),
	'identifierSchemeCodeUnspsc190501.10201723': __('identifierSchemeCodeUnspsc190501.10201723'),
	'identifierSchemeCodeUnspsc190501.10201724': __('identifierSchemeCodeUnspsc190501.10201724'),
	'identifierSchemeCodeUnspsc190501.10201725': __('identifierSchemeCodeUnspsc190501.10201725'),
	'identifierSchemeCodeUnspsc190501.10201726': __('identifierSchemeCodeUnspsc190501.10201726'),
	'identifierSchemeCodeUnspsc190501.10201727': __('identifierSchemeCodeUnspsc190501.10201727'),
	'identifierSchemeCodeUnspsc190501.10201728': __('identifierSchemeCodeUnspsc190501.10201728'),
	'identifierSchemeCodeUnspsc190501.10201729': __('identifierSchemeCodeUnspsc190501.10201729'),
	'identifierSchemeCodeUnspsc190501.10201730': __('identifierSchemeCodeUnspsc190501.10201730'),
	'identifierSchemeCodeUnspsc190501.10201731': __('identifierSchemeCodeUnspsc190501.10201731'),
	'identifierSchemeCodeUnspsc190501.10201732': __('identifierSchemeCodeUnspsc190501.10201732'),
	'identifierSchemeCodeUnspsc190501.10201733': __('identifierSchemeCodeUnspsc190501.10201733'),
	'identifierSchemeCodeUnspsc190501.10201734': __('identifierSchemeCodeUnspsc190501.10201734'),
	'identifierSchemeCodeUnspsc190501.10201735': __('identifierSchemeCodeUnspsc190501.10201735'),
	'identifierSchemeCodeUnspsc190501.10201736': __('identifierSchemeCodeUnspsc190501.10201736'),
	'identifierSchemeCodeUnspsc190501.10201737': __('identifierSchemeCodeUnspsc190501.10201737'),
	'identifierSchemeCodeUnspsc190501.10201738': __('identifierSchemeCodeUnspsc190501.10201738'),
	'identifierSchemeCodeUnspsc190501.10201739': __('identifierSchemeCodeUnspsc190501.10201739'),
	'identifierSchemeCodeUnspsc190501.10201740': __('identifierSchemeCodeUnspsc190501.10201740'),
	'identifierSchemeCodeUnspsc190501.10201741': __('identifierSchemeCodeUnspsc190501.10201741'),
	'identifierSchemeCodeUnspsc190501.10201742': __('identifierSchemeCodeUnspsc190501.10201742'),
	'identifierSchemeCodeUnspsc190501.10201743': __('identifierSchemeCodeUnspsc190501.10201743'),
	'identifierSchemeCodeUnspsc190501.10201744': __('identifierSchemeCodeUnspsc190501.10201744'),
	'identifierSchemeCodeUnspsc190501.10201745': __('identifierSchemeCodeUnspsc190501.10201745'),
	'identifierSchemeCodeUnspsc190501.10201746': __('identifierSchemeCodeUnspsc190501.10201746'),
	'identifierSchemeCodeUnspsc190501.10201800': __('identifierSchemeCodeUnspsc190501.10201800'),
	'identifierSchemeCodeUnspsc190501.10201801': __('identifierSchemeCodeUnspsc190501.10201801'),
	'identifierSchemeCodeUnspsc190501.10201802': __('identifierSchemeCodeUnspsc190501.10201802'),
	'identifierSchemeCodeUnspsc190501.10201803': __('identifierSchemeCodeUnspsc190501.10201803'),
	'identifierSchemeCodeUnspsc190501.10201804': __('identifierSchemeCodeUnspsc190501.10201804'),
	'identifierSchemeCodeUnspsc190501.10201805': __('identifierSchemeCodeUnspsc190501.10201805'),
	'identifierSchemeCodeUnspsc190501.10201806': __('identifierSchemeCodeUnspsc190501.10201806'),
	'identifierSchemeCodeUnspsc190501.10201807': __('identifierSchemeCodeUnspsc190501.10201807'),
	'identifierSchemeCodeUnspsc190501.10201808': __('identifierSchemeCodeUnspsc190501.10201808'),
	'identifierSchemeCodeUnspsc190501.10201809': __('identifierSchemeCodeUnspsc190501.10201809'),
	'identifierSchemeCodeUnspsc190501.10201810': __('identifierSchemeCodeUnspsc190501.10201810'),
	'identifierSchemeCodeUnspsc190501.10201811': __('identifierSchemeCodeUnspsc190501.10201811'),
	'identifierSchemeCodeUnspsc190501.10201812': __('identifierSchemeCodeUnspsc190501.10201812'),
	'identifierSchemeCodeUnspsc190501.10201813': __('identifierSchemeCodeUnspsc190501.10201813'),
	'identifierSchemeCodeUnspsc190501.10201900': __('identifierSchemeCodeUnspsc190501.10201900'),
	'identifierSchemeCodeUnspsc190501.10201901': __('identifierSchemeCodeUnspsc190501.10201901'),
	'identifierSchemeCodeUnspsc190501.10201902': __('identifierSchemeCodeUnspsc190501.10201902'),
	'identifierSchemeCodeUnspsc190501.10201903': __('identifierSchemeCodeUnspsc190501.10201903'),
	'identifierSchemeCodeUnspsc190501.10201904': __('identifierSchemeCodeUnspsc190501.10201904'),
	'identifierSchemeCodeUnspsc190501.10201905': __('identifierSchemeCodeUnspsc190501.10201905'),
	'identifierSchemeCodeUnspsc190501.10201906': __('identifierSchemeCodeUnspsc190501.10201906'),
	'identifierSchemeCodeUnspsc190501.10201907': __('identifierSchemeCodeUnspsc190501.10201907'),
	'identifierSchemeCodeUnspsc190501.10201908': __('identifierSchemeCodeUnspsc190501.10201908'),
	'identifierSchemeCodeUnspsc190501.10201909': __('identifierSchemeCodeUnspsc190501.10201909'),
	'identifierSchemeCodeUnspsc190501.10201910': __('identifierSchemeCodeUnspsc190501.10201910'),
	'identifierSchemeCodeUnspsc190501.10201911': __('identifierSchemeCodeUnspsc190501.10201911'),
	'identifierSchemeCodeUnspsc190501.10201912': __('identifierSchemeCodeUnspsc190501.10201912'),
	'identifierSchemeCodeUnspsc190501.10201913': __('identifierSchemeCodeUnspsc190501.10201913'),
	'identifierSchemeCodeUnspsc190501.10201914': __('identifierSchemeCodeUnspsc190501.10201914'),
	'identifierSchemeCodeUnspsc190501.10201915': __('identifierSchemeCodeUnspsc190501.10201915'),
	'identifierSchemeCodeUnspsc190501.10201916': __('identifierSchemeCodeUnspsc190501.10201916'),
	'identifierSchemeCodeUnspsc190501.10201917': __('identifierSchemeCodeUnspsc190501.10201917'),
	'identifierSchemeCodeUnspsc190501.10201918': __('identifierSchemeCodeUnspsc190501.10201918'),
	'identifierSchemeCodeUnspsc190501.10201919': __('identifierSchemeCodeUnspsc190501.10201919'),
	'identifierSchemeCodeUnspsc190501.10201920': __('identifierSchemeCodeUnspsc190501.10201920'),
	'identifierSchemeCodeUnspsc190501.10201921': __('identifierSchemeCodeUnspsc190501.10201921'),
	'identifierSchemeCodeUnspsc190501.10201922': __('identifierSchemeCodeUnspsc190501.10201922'),
	'identifierSchemeCodeUnspsc190501.10201923': __('identifierSchemeCodeUnspsc190501.10201923'),
	'identifierSchemeCodeUnspsc190501.10201924': __('identifierSchemeCodeUnspsc190501.10201924'),
	'identifierSchemeCodeUnspsc190501.10201925': __('identifierSchemeCodeUnspsc190501.10201925'),
	'identifierSchemeCodeUnspsc190501.10201926': __('identifierSchemeCodeUnspsc190501.10201926'),
	'identifierSchemeCodeUnspsc190501.10201927': __('identifierSchemeCodeUnspsc190501.10201927'),
	'identifierSchemeCodeUnspsc190501.10201928': __('identifierSchemeCodeUnspsc190501.10201928'),
	'identifierSchemeCodeUnspsc190501.10201929': __('identifierSchemeCodeUnspsc190501.10201929'),
	'identifierSchemeCodeUnspsc190501.10201930': __('identifierSchemeCodeUnspsc190501.10201930'),
	'identifierSchemeCodeUnspsc190501.10201931': __('identifierSchemeCodeUnspsc190501.10201931'),
	'identifierSchemeCodeUnspsc190501.10201932': __('identifierSchemeCodeUnspsc190501.10201932'),
	'identifierSchemeCodeUnspsc190501.10201933': __('identifierSchemeCodeUnspsc190501.10201933'),
	'identifierSchemeCodeUnspsc190501.10201934': __('identifierSchemeCodeUnspsc190501.10201934'),
	'identifierSchemeCodeUnspsc190501.10201935': __('identifierSchemeCodeUnspsc190501.10201935'),
	'identifierSchemeCodeUnspsc190501.10201936': __('identifierSchemeCodeUnspsc190501.10201936'),
	'identifierSchemeCodeUnspsc190501.10201937': __('identifierSchemeCodeUnspsc190501.10201937'),
	'identifierSchemeCodeUnspsc190501.10201938': __('identifierSchemeCodeUnspsc190501.10201938'),
	'identifierSchemeCodeUnspsc190501.10201939': __('identifierSchemeCodeUnspsc190501.10201939'),
	'identifierSchemeCodeUnspsc190501.10201940': __('identifierSchemeCodeUnspsc190501.10201940'),
	'identifierSchemeCodeUnspsc190501.10201941': __('identifierSchemeCodeUnspsc190501.10201941'),
	'identifierSchemeCodeUnspsc190501.10201942': __('identifierSchemeCodeUnspsc190501.10201942'),
	'identifierSchemeCodeUnspsc190501.10201943': __('identifierSchemeCodeUnspsc190501.10201943'),
	'identifierSchemeCodeUnspsc190501.10201944': __('identifierSchemeCodeUnspsc190501.10201944'),
	'identifierSchemeCodeUnspsc190501.10201945': __('identifierSchemeCodeUnspsc190501.10201945'),
	'identifierSchemeCodeUnspsc190501.10201946': __('identifierSchemeCodeUnspsc190501.10201946'),
	'identifierSchemeCodeUnspsc190501.10201947': __('identifierSchemeCodeUnspsc190501.10201947'),
	'identifierSchemeCodeUnspsc190501.10201948': __('identifierSchemeCodeUnspsc190501.10201948'),
	'identifierSchemeCodeUnspsc190501.10201949': __('identifierSchemeCodeUnspsc190501.10201949'),
	'identifierSchemeCodeUnspsc190501.10201950': __('identifierSchemeCodeUnspsc190501.10201950'),
	'identifierSchemeCodeUnspsc190501.10201951': __('identifierSchemeCodeUnspsc190501.10201951'),
	'identifierSchemeCodeUnspsc190501.10201952': __('identifierSchemeCodeUnspsc190501.10201952'),
	'identifierSchemeCodeUnspsc190501.10201953': __('identifierSchemeCodeUnspsc190501.10201953'),
	'identifierSchemeCodeUnspsc190501.10201954': __('identifierSchemeCodeUnspsc190501.10201954'),
	'identifierSchemeCodeUnspsc190501.10201955': __('identifierSchemeCodeUnspsc190501.10201955'),
	'identifierSchemeCodeUnspsc190501.10201956': __('identifierSchemeCodeUnspsc190501.10201956'),
	'identifierSchemeCodeUnspsc190501.10201957': __('identifierSchemeCodeUnspsc190501.10201957'),
	'identifierSchemeCodeUnspsc190501.10201958': __('identifierSchemeCodeUnspsc190501.10201958'),
	'identifierSchemeCodeUnspsc190501.10201959': __('identifierSchemeCodeUnspsc190501.10201959'),
	'identifierSchemeCodeUnspsc190501.10201960': __('identifierSchemeCodeUnspsc190501.10201960'),
	'identifierSchemeCodeUnspsc190501.10201961': __('identifierSchemeCodeUnspsc190501.10201961'),
	'identifierSchemeCodeUnspsc190501.10201962': __('identifierSchemeCodeUnspsc190501.10201962'),
	'identifierSchemeCodeUnspsc190501.10202000': __('identifierSchemeCodeUnspsc190501.10202000'),
	'identifierSchemeCodeUnspsc190501.10202001': __('identifierSchemeCodeUnspsc190501.10202001'),
	'identifierSchemeCodeUnspsc190501.10202002': __('identifierSchemeCodeUnspsc190501.10202002'),
	'identifierSchemeCodeUnspsc190501.10202003': __('identifierSchemeCodeUnspsc190501.10202003'),
	'identifierSchemeCodeUnspsc190501.10202004': __('identifierSchemeCodeUnspsc190501.10202004'),
	'identifierSchemeCodeUnspsc190501.10202005': __('identifierSchemeCodeUnspsc190501.10202005'),
	'identifierSchemeCodeUnspsc190501.10202006': __('identifierSchemeCodeUnspsc190501.10202006'),
	'identifierSchemeCodeUnspsc190501.10202007': __('identifierSchemeCodeUnspsc190501.10202007'),
	'identifierSchemeCodeUnspsc190501.10202008': __('identifierSchemeCodeUnspsc190501.10202008'),
	'identifierSchemeCodeUnspsc190501.10202009': __('identifierSchemeCodeUnspsc190501.10202009'),
	'identifierSchemeCodeUnspsc190501.10202010': __('identifierSchemeCodeUnspsc190501.10202010'),
	'identifierSchemeCodeUnspsc190501.10202011': __('identifierSchemeCodeUnspsc190501.10202011'),
	'identifierSchemeCodeUnspsc190501.10202012': __('identifierSchemeCodeUnspsc190501.10202012'),
	'identifierSchemeCodeUnspsc190501.10202013': __('identifierSchemeCodeUnspsc190501.10202013'),
	'identifierSchemeCodeUnspsc190501.10202014': __('identifierSchemeCodeUnspsc190501.10202014'),
	'identifierSchemeCodeUnspsc190501.10202015': __('identifierSchemeCodeUnspsc190501.10202015'),
	'identifierSchemeCodeUnspsc190501.10202016': __('identifierSchemeCodeUnspsc190501.10202016'),
	'identifierSchemeCodeUnspsc190501.10202017': __('identifierSchemeCodeUnspsc190501.10202017'),
	'identifierSchemeCodeUnspsc190501.10202018': __('identifierSchemeCodeUnspsc190501.10202018'),
	'identifierSchemeCodeUnspsc190501.10202019': __('identifierSchemeCodeUnspsc190501.10202019'),
	'identifierSchemeCodeUnspsc190501.10202020': __('identifierSchemeCodeUnspsc190501.10202020'),
	'identifierSchemeCodeUnspsc190501.10202021': __('identifierSchemeCodeUnspsc190501.10202021'),
	'identifierSchemeCodeUnspsc190501.10202022': __('identifierSchemeCodeUnspsc190501.10202022'),
	'identifierSchemeCodeUnspsc190501.10202023': __('identifierSchemeCodeUnspsc190501.10202023'),
	'identifierSchemeCodeUnspsc190501.10202024': __('identifierSchemeCodeUnspsc190501.10202024'),
	'identifierSchemeCodeUnspsc190501.10202025': __('identifierSchemeCodeUnspsc190501.10202025'),
	'identifierSchemeCodeUnspsc190501.10202026': __('identifierSchemeCodeUnspsc190501.10202026'),
	'identifierSchemeCodeUnspsc190501.10202027': __('identifierSchemeCodeUnspsc190501.10202027'),
	'identifierSchemeCodeUnspsc190501.10202028': __('identifierSchemeCodeUnspsc190501.10202028'),
	'identifierSchemeCodeUnspsc190501.10202029': __('identifierSchemeCodeUnspsc190501.10202029'),
	'identifierSchemeCodeUnspsc190501.10202030': __('identifierSchemeCodeUnspsc190501.10202030'),
	'identifierSchemeCodeUnspsc190501.10202031': __('identifierSchemeCodeUnspsc190501.10202031'),
	'identifierSchemeCodeUnspsc190501.10202032': __('identifierSchemeCodeUnspsc190501.10202032'),
	'identifierSchemeCodeUnspsc190501.10202033': __('identifierSchemeCodeUnspsc190501.10202033'),
	'identifierSchemeCodeUnspsc190501.10202034': __('identifierSchemeCodeUnspsc190501.10202034'),
	'identifierSchemeCodeUnspsc190501.10202035': __('identifierSchemeCodeUnspsc190501.10202035'),
	'identifierSchemeCodeUnspsc190501.10202036': __('identifierSchemeCodeUnspsc190501.10202036'),
	'identifierSchemeCodeUnspsc190501.10202037': __('identifierSchemeCodeUnspsc190501.10202037'),
	'identifierSchemeCodeUnspsc190501.10202100': __('identifierSchemeCodeUnspsc190501.10202100'),
	'identifierSchemeCodeUnspsc190501.10202101': __('identifierSchemeCodeUnspsc190501.10202101'),
	'identifierSchemeCodeUnspsc190501.10202102': __('identifierSchemeCodeUnspsc190501.10202102'),
	'identifierSchemeCodeUnspsc190501.10202103': __('identifierSchemeCodeUnspsc190501.10202103'),
	'identifierSchemeCodeUnspsc190501.10202104': __('identifierSchemeCodeUnspsc190501.10202104'),
	'identifierSchemeCodeUnspsc190501.10202105': __('identifierSchemeCodeUnspsc190501.10202105'),
	'identifierSchemeCodeUnspsc190501.10202106': __('identifierSchemeCodeUnspsc190501.10202106'),
	'identifierSchemeCodeUnspsc190501.10202107': __('identifierSchemeCodeUnspsc190501.10202107'),
	'identifierSchemeCodeUnspsc190501.10202108': __('identifierSchemeCodeUnspsc190501.10202108'),
	'identifierSchemeCodeUnspsc190501.10202109': __('identifierSchemeCodeUnspsc190501.10202109'),
	'identifierSchemeCodeUnspsc190501.10202110': __('identifierSchemeCodeUnspsc190501.10202110'),
	'identifierSchemeCodeUnspsc190501.10202111': __('identifierSchemeCodeUnspsc190501.10202111'),
	'identifierSchemeCodeUnspsc190501.10202112': __('identifierSchemeCodeUnspsc190501.10202112'),
	'identifierSchemeCodeUnspsc190501.10202113': __('identifierSchemeCodeUnspsc190501.10202113'),
	'identifierSchemeCodeUnspsc190501.10202114': __('identifierSchemeCodeUnspsc190501.10202114'),
	'identifierSchemeCodeUnspsc190501.10202115': __('identifierSchemeCodeUnspsc190501.10202115'),
	'identifierSchemeCodeUnspsc190501.10202116': __('identifierSchemeCodeUnspsc190501.10202116'),
	'identifierSchemeCodeUnspsc190501.10202117': __('identifierSchemeCodeUnspsc190501.10202117'),
	'identifierSchemeCodeUnspsc190501.10202118': __('identifierSchemeCodeUnspsc190501.10202118'),
	'identifierSchemeCodeUnspsc190501.10202119': __('identifierSchemeCodeUnspsc190501.10202119'),
	'identifierSchemeCodeUnspsc190501.10202120': __('identifierSchemeCodeUnspsc190501.10202120'),
	'identifierSchemeCodeUnspsc190501.10202121': __('identifierSchemeCodeUnspsc190501.10202121'),
	'identifierSchemeCodeUnspsc190501.10202122': __('identifierSchemeCodeUnspsc190501.10202122'),
	'identifierSchemeCodeUnspsc190501.10202123': __('identifierSchemeCodeUnspsc190501.10202123'),
	'identifierSchemeCodeUnspsc190501.10202124': __('identifierSchemeCodeUnspsc190501.10202124'),
	'identifierSchemeCodeUnspsc190501.10202125': __('identifierSchemeCodeUnspsc190501.10202125'),
	'identifierSchemeCodeUnspsc190501.10202126': __('identifierSchemeCodeUnspsc190501.10202126'),
	'identifierSchemeCodeUnspsc190501.10202127': __('identifierSchemeCodeUnspsc190501.10202127'),
	'identifierSchemeCodeUnspsc190501.10202128': __('identifierSchemeCodeUnspsc190501.10202128'),
	'identifierSchemeCodeUnspsc190501.10202129': __('identifierSchemeCodeUnspsc190501.10202129'),
	'identifierSchemeCodeUnspsc190501.10202130': __('identifierSchemeCodeUnspsc190501.10202130'),
	'identifierSchemeCodeUnspsc190501.10202131': __('identifierSchemeCodeUnspsc190501.10202131'),
	'identifierSchemeCodeUnspsc190501.10202132': __('identifierSchemeCodeUnspsc190501.10202132'),
	'identifierSchemeCodeUnspsc190501.10202133': __('identifierSchemeCodeUnspsc190501.10202133'),
	'identifierSchemeCodeUnspsc190501.10202134': __('identifierSchemeCodeUnspsc190501.10202134'),
	'identifierSchemeCodeUnspsc190501.10202135': __('identifierSchemeCodeUnspsc190501.10202135'),
	'identifierSchemeCodeUnspsc190501.10202136': __('identifierSchemeCodeUnspsc190501.10202136'),
	'identifierSchemeCodeUnspsc190501.10202137': __('identifierSchemeCodeUnspsc190501.10202137'),
	'identifierSchemeCodeUnspsc190501.10202138': __('identifierSchemeCodeUnspsc190501.10202138'),
	'identifierSchemeCodeUnspsc190501.10202139': __('identifierSchemeCodeUnspsc190501.10202139'),
	'identifierSchemeCodeUnspsc190501.10202140': __('identifierSchemeCodeUnspsc190501.10202140'),
	'identifierSchemeCodeUnspsc190501.10202141': __('identifierSchemeCodeUnspsc190501.10202141'),
	'identifierSchemeCodeUnspsc190501.10202142': __('identifierSchemeCodeUnspsc190501.10202142'),
	'identifierSchemeCodeUnspsc190501.10202143': __('identifierSchemeCodeUnspsc190501.10202143'),
	'identifierSchemeCodeUnspsc190501.10202144': __('identifierSchemeCodeUnspsc190501.10202144'),
	'identifierSchemeCodeUnspsc190501.10202145': __('identifierSchemeCodeUnspsc190501.10202145'),
	'identifierSchemeCodeUnspsc190501.10202146': __('identifierSchemeCodeUnspsc190501.10202146'),
	'identifierSchemeCodeUnspsc190501.10202147': __('identifierSchemeCodeUnspsc190501.10202147'),
	'identifierSchemeCodeUnspsc190501.10202148': __('identifierSchemeCodeUnspsc190501.10202148'),
	'identifierSchemeCodeUnspsc190501.10202149': __('identifierSchemeCodeUnspsc190501.10202149'),
	'identifierSchemeCodeUnspsc190501.10202150': __('identifierSchemeCodeUnspsc190501.10202150'),
	'identifierSchemeCodeUnspsc190501.10202151': __('identifierSchemeCodeUnspsc190501.10202151'),
	'identifierSchemeCodeUnspsc190501.10202152': __('identifierSchemeCodeUnspsc190501.10202152'),
	'identifierSchemeCodeUnspsc190501.10202153': __('identifierSchemeCodeUnspsc190501.10202153'),
	'identifierSchemeCodeUnspsc190501.10202154': __('identifierSchemeCodeUnspsc190501.10202154'),
	'identifierSchemeCodeUnspsc190501.10202155': __('identifierSchemeCodeUnspsc190501.10202155'),
	'identifierSchemeCodeUnspsc190501.10202156': __('identifierSchemeCodeUnspsc190501.10202156'),
	'identifierSchemeCodeUnspsc190501.10202157': __('identifierSchemeCodeUnspsc190501.10202157'),
	'identifierSchemeCodeUnspsc190501.10202158': __('identifierSchemeCodeUnspsc190501.10202158'),
	'identifierSchemeCodeUnspsc190501.10202159': __('identifierSchemeCodeUnspsc190501.10202159'),
	'identifierSchemeCodeUnspsc190501.10202160': __('identifierSchemeCodeUnspsc190501.10202160'),
	'identifierSchemeCodeUnspsc190501.10202161': __('identifierSchemeCodeUnspsc190501.10202161'),
	'identifierSchemeCodeUnspsc190501.10202162': __('identifierSchemeCodeUnspsc190501.10202162'),
	'identifierSchemeCodeUnspsc190501.10202163': __('identifierSchemeCodeUnspsc190501.10202163'),
	'identifierSchemeCodeUnspsc190501.10202164': __('identifierSchemeCodeUnspsc190501.10202164'),
	'identifierSchemeCodeUnspsc190501.10202165': __('identifierSchemeCodeUnspsc190501.10202165'),
	'identifierSchemeCodeUnspsc190501.10202166': __('identifierSchemeCodeUnspsc190501.10202166'),
	'identifierSchemeCodeUnspsc190501.10202167': __('identifierSchemeCodeUnspsc190501.10202167'),
	'identifierSchemeCodeUnspsc190501.10202168': __('identifierSchemeCodeUnspsc190501.10202168'),
	'identifierSchemeCodeUnspsc190501.10202169': __('identifierSchemeCodeUnspsc190501.10202169'),
	'identifierSchemeCodeUnspsc190501.10202170': __('identifierSchemeCodeUnspsc190501.10202170'),
	'identifierSchemeCodeUnspsc190501.10202171': __('identifierSchemeCodeUnspsc190501.10202171'),
	'identifierSchemeCodeUnspsc190501.10202172': __('identifierSchemeCodeUnspsc190501.10202172'),
	'identifierSchemeCodeUnspsc190501.10202173': __('identifierSchemeCodeUnspsc190501.10202173'),
	'identifierSchemeCodeUnspsc190501.10202174': __('identifierSchemeCodeUnspsc190501.10202174'),
	'identifierSchemeCodeUnspsc190501.10202175': __('identifierSchemeCodeUnspsc190501.10202175'),
	'identifierSchemeCodeUnspsc190501.10202176': __('identifierSchemeCodeUnspsc190501.10202176'),
	'identifierSchemeCodeUnspsc190501.10202177': __('identifierSchemeCodeUnspsc190501.10202177'),
	'identifierSchemeCodeUnspsc190501.10202178': __('identifierSchemeCodeUnspsc190501.10202178'),
	'identifierSchemeCodeUnspsc190501.10202179': __('identifierSchemeCodeUnspsc190501.10202179'),
	'identifierSchemeCodeUnspsc190501.10202180': __('identifierSchemeCodeUnspsc190501.10202180'),
	'identifierSchemeCodeUnspsc190501.10202181': __('identifierSchemeCodeUnspsc190501.10202181'),
	'identifierSchemeCodeUnspsc190501.10202182': __('identifierSchemeCodeUnspsc190501.10202182'),
	'identifierSchemeCodeUnspsc190501.10202183': __('identifierSchemeCodeUnspsc190501.10202183'),
	'identifierSchemeCodeUnspsc190501.10202184': __('identifierSchemeCodeUnspsc190501.10202184'),
	'identifierSchemeCodeUnspsc190501.10202185': __('identifierSchemeCodeUnspsc190501.10202185'),
	'identifierSchemeCodeUnspsc190501.10202186': __('identifierSchemeCodeUnspsc190501.10202186'),
	'identifierSchemeCodeUnspsc190501.10202200': __('identifierSchemeCodeUnspsc190501.10202200'),
	'identifierSchemeCodeUnspsc190501.10202201': __('identifierSchemeCodeUnspsc190501.10202201'),
	'identifierSchemeCodeUnspsc190501.10202202': __('identifierSchemeCodeUnspsc190501.10202202'),
	'identifierSchemeCodeUnspsc190501.10202203': __('identifierSchemeCodeUnspsc190501.10202203'),
	'identifierSchemeCodeUnspsc190501.10202204': __('identifierSchemeCodeUnspsc190501.10202204'),
	'identifierSchemeCodeUnspsc190501.10202205': __('identifierSchemeCodeUnspsc190501.10202205'),
	'identifierSchemeCodeUnspsc190501.10202206': __('identifierSchemeCodeUnspsc190501.10202206'),
	'identifierSchemeCodeUnspsc190501.10202207': __('identifierSchemeCodeUnspsc190501.10202207'),
	'identifierSchemeCodeUnspsc190501.10202208': __('identifierSchemeCodeUnspsc190501.10202208'),
	'identifierSchemeCodeUnspsc190501.10202209': __('identifierSchemeCodeUnspsc190501.10202209'),
	'identifierSchemeCodeUnspsc190501.10202210': __('identifierSchemeCodeUnspsc190501.10202210'),
	'identifierSchemeCodeUnspsc190501.10202211': __('identifierSchemeCodeUnspsc190501.10202211'),
	'identifierSchemeCodeUnspsc190501.10202212': __('identifierSchemeCodeUnspsc190501.10202212'),
	'identifierSchemeCodeUnspsc190501.10202213': __('identifierSchemeCodeUnspsc190501.10202213'),
	'identifierSchemeCodeUnspsc190501.10202214': __('identifierSchemeCodeUnspsc190501.10202214'),
	'identifierSchemeCodeUnspsc190501.10202215': __('identifierSchemeCodeUnspsc190501.10202215'),
	'identifierSchemeCodeUnspsc190501.10202216': __('identifierSchemeCodeUnspsc190501.10202216'),
	'identifierSchemeCodeUnspsc190501.10202217': __('identifierSchemeCodeUnspsc190501.10202217'),
	'identifierSchemeCodeUnspsc190501.10202218': __('identifierSchemeCodeUnspsc190501.10202218'),
	'identifierSchemeCodeUnspsc190501.10202219': __('identifierSchemeCodeUnspsc190501.10202219'),
	'identifierSchemeCodeUnspsc190501.10202220': __('identifierSchemeCodeUnspsc190501.10202220'),
	'identifierSchemeCodeUnspsc190501.10202221': __('identifierSchemeCodeUnspsc190501.10202221'),
	'identifierSchemeCodeUnspsc190501.10202222': __('identifierSchemeCodeUnspsc190501.10202222'),
	'identifierSchemeCodeUnspsc190501.10202223': __('identifierSchemeCodeUnspsc190501.10202223'),
	'identifierSchemeCodeUnspsc190501.10202224': __('identifierSchemeCodeUnspsc190501.10202224'),
	'identifierSchemeCodeUnspsc190501.10202225': __('identifierSchemeCodeUnspsc190501.10202225'),
	'identifierSchemeCodeUnspsc190501.10202226': __('identifierSchemeCodeUnspsc190501.10202226'),
	'identifierSchemeCodeUnspsc190501.10202227': __('identifierSchemeCodeUnspsc190501.10202227'),
	'identifierSchemeCodeUnspsc190501.10202228': __('identifierSchemeCodeUnspsc190501.10202228'),
	'identifierSchemeCodeUnspsc190501.10202229': __('identifierSchemeCodeUnspsc190501.10202229'),
	'identifierSchemeCodeUnspsc190501.10202230': __('identifierSchemeCodeUnspsc190501.10202230'),
	'identifierSchemeCodeUnspsc190501.10202231': __('identifierSchemeCodeUnspsc190501.10202231'),
	'identifierSchemeCodeUnspsc190501.10202232': __('identifierSchemeCodeUnspsc190501.10202232'),
	'identifierSchemeCodeUnspsc190501.10202233': __('identifierSchemeCodeUnspsc190501.10202233'),
	'identifierSchemeCodeUnspsc190501.10202234': __('identifierSchemeCodeUnspsc190501.10202234'),
	'identifierSchemeCodeUnspsc190501.10202235': __('identifierSchemeCodeUnspsc190501.10202235'),
	'identifierSchemeCodeUnspsc190501.10202236': __('identifierSchemeCodeUnspsc190501.10202236'),
	'identifierSchemeCodeUnspsc190501.10202237': __('identifierSchemeCodeUnspsc190501.10202237'),
	'identifierSchemeCodeUnspsc190501.10202238': __('identifierSchemeCodeUnspsc190501.10202238'),
	'identifierSchemeCodeUnspsc190501.10202239': __('identifierSchemeCodeUnspsc190501.10202239'),
	'identifierSchemeCodeUnspsc190501.10202240': __('identifierSchemeCodeUnspsc190501.10202240'),
	'identifierSchemeCodeUnspsc190501.10202241': __('identifierSchemeCodeUnspsc190501.10202241'),
	'identifierSchemeCodeUnspsc190501.10202300': __('identifierSchemeCodeUnspsc190501.10202300'),
	'identifierSchemeCodeUnspsc190501.10202301': __('identifierSchemeCodeUnspsc190501.10202301'),
	'identifierSchemeCodeUnspsc190501.10202302': __('identifierSchemeCodeUnspsc190501.10202302'),
	'identifierSchemeCodeUnspsc190501.10202303': __('identifierSchemeCodeUnspsc190501.10202303'),
	'identifierSchemeCodeUnspsc190501.10202304': __('identifierSchemeCodeUnspsc190501.10202304'),
	'identifierSchemeCodeUnspsc190501.10202305': __('identifierSchemeCodeUnspsc190501.10202305'),
	'identifierSchemeCodeUnspsc190501.10202306': __('identifierSchemeCodeUnspsc190501.10202306'),
	'identifierSchemeCodeUnspsc190501.10202307': __('identifierSchemeCodeUnspsc190501.10202307'),
	'identifierSchemeCodeUnspsc190501.10202308': __('identifierSchemeCodeUnspsc190501.10202308'),
	'identifierSchemeCodeUnspsc190501.10202309': __('identifierSchemeCodeUnspsc190501.10202309'),
	'identifierSchemeCodeUnspsc190501.10202310': __('identifierSchemeCodeUnspsc190501.10202310'),
	'identifierSchemeCodeUnspsc190501.10202311': __('identifierSchemeCodeUnspsc190501.10202311'),
	'identifierSchemeCodeUnspsc190501.10202312': __('identifierSchemeCodeUnspsc190501.10202312'),
	'identifierSchemeCodeUnspsc190501.10202313': __('identifierSchemeCodeUnspsc190501.10202313'),
	'identifierSchemeCodeUnspsc190501.10202314': __('identifierSchemeCodeUnspsc190501.10202314'),
	'identifierSchemeCodeUnspsc190501.10202315': __('identifierSchemeCodeUnspsc190501.10202315'),
	'identifierSchemeCodeUnspsc190501.10202316': __('identifierSchemeCodeUnspsc190501.10202316'),
	'identifierSchemeCodeUnspsc190501.10202317': __('identifierSchemeCodeUnspsc190501.10202317'),
	'identifierSchemeCodeUnspsc190501.10202318': __('identifierSchemeCodeUnspsc190501.10202318'),
	'identifierSchemeCodeUnspsc190501.10202319': __('identifierSchemeCodeUnspsc190501.10202319'),
	'identifierSchemeCodeUnspsc190501.10202320': __('identifierSchemeCodeUnspsc190501.10202320'),
	'identifierSchemeCodeUnspsc190501.10202321': __('identifierSchemeCodeUnspsc190501.10202321'),
	'identifierSchemeCodeUnspsc190501.10202322': __('identifierSchemeCodeUnspsc190501.10202322'),
	'identifierSchemeCodeUnspsc190501.10202323': __('identifierSchemeCodeUnspsc190501.10202323'),
	'identifierSchemeCodeUnspsc190501.10202324': __('identifierSchemeCodeUnspsc190501.10202324'),
	'identifierSchemeCodeUnspsc190501.10202325': __('identifierSchemeCodeUnspsc190501.10202325'),
	'identifierSchemeCodeUnspsc190501.10202326': __('identifierSchemeCodeUnspsc190501.10202326'),
	'identifierSchemeCodeUnspsc190501.10202327': __('identifierSchemeCodeUnspsc190501.10202327'),
	'identifierSchemeCodeUnspsc190501.10202328': __('identifierSchemeCodeUnspsc190501.10202328'),
	'identifierSchemeCodeUnspsc190501.10202329': __('identifierSchemeCodeUnspsc190501.10202329'),
	'identifierSchemeCodeUnspsc190501.10202330': __('identifierSchemeCodeUnspsc190501.10202330'),
	'identifierSchemeCodeUnspsc190501.10202331': __('identifierSchemeCodeUnspsc190501.10202331'),
	'identifierSchemeCodeUnspsc190501.10202332': __('identifierSchemeCodeUnspsc190501.10202332'),
	'identifierSchemeCodeUnspsc190501.10202333': __('identifierSchemeCodeUnspsc190501.10202333'),
	'identifierSchemeCodeUnspsc190501.10202334': __('identifierSchemeCodeUnspsc190501.10202334'),
	'identifierSchemeCodeUnspsc190501.10202335': __('identifierSchemeCodeUnspsc190501.10202335'),
	'identifierSchemeCodeUnspsc190501.10202336': __('identifierSchemeCodeUnspsc190501.10202336'),
	'identifierSchemeCodeUnspsc190501.10202337': __('identifierSchemeCodeUnspsc190501.10202337'),
	'identifierSchemeCodeUnspsc190501.10202338': __('identifierSchemeCodeUnspsc190501.10202338'),
	'identifierSchemeCodeUnspsc190501.10202339': __('identifierSchemeCodeUnspsc190501.10202339'),
	'identifierSchemeCodeUnspsc190501.10202340': __('identifierSchemeCodeUnspsc190501.10202340'),
	'identifierSchemeCodeUnspsc190501.10202341': __('identifierSchemeCodeUnspsc190501.10202341'),
	'identifierSchemeCodeUnspsc190501.10202342': __('identifierSchemeCodeUnspsc190501.10202342'),
	'identifierSchemeCodeUnspsc190501.10202343': __('identifierSchemeCodeUnspsc190501.10202343'),
	'identifierSchemeCodeUnspsc190501.10202344': __('identifierSchemeCodeUnspsc190501.10202344'),
	'identifierSchemeCodeUnspsc190501.10202345': __('identifierSchemeCodeUnspsc190501.10202345'),
	'identifierSchemeCodeUnspsc190501.10202346': __('identifierSchemeCodeUnspsc190501.10202346'),
	'identifierSchemeCodeUnspsc190501.10202347': __('identifierSchemeCodeUnspsc190501.10202347'),
	'identifierSchemeCodeUnspsc190501.10202348': __('identifierSchemeCodeUnspsc190501.10202348'),
	'identifierSchemeCodeUnspsc190501.10202349': __('identifierSchemeCodeUnspsc190501.10202349'),
	'identifierSchemeCodeUnspsc190501.10202350': __('identifierSchemeCodeUnspsc190501.10202350'),
	'identifierSchemeCodeUnspsc190501.10202351': __('identifierSchemeCodeUnspsc190501.10202351'),
	'identifierSchemeCodeUnspsc190501.10202352': __('identifierSchemeCodeUnspsc190501.10202352'),
	'identifierSchemeCodeUnspsc190501.10202353': __('identifierSchemeCodeUnspsc190501.10202353'),
	'identifierSchemeCodeUnspsc190501.10202354': __('identifierSchemeCodeUnspsc190501.10202354'),
	'identifierSchemeCodeUnspsc190501.10202355': __('identifierSchemeCodeUnspsc190501.10202355'),
	'identifierSchemeCodeUnspsc190501.10202356': __('identifierSchemeCodeUnspsc190501.10202356'),
	'identifierSchemeCodeUnspsc190501.10202357': __('identifierSchemeCodeUnspsc190501.10202357'),
	'identifierSchemeCodeUnspsc190501.10202358': __('identifierSchemeCodeUnspsc190501.10202358'),
	'identifierSchemeCodeUnspsc190501.10202359': __('identifierSchemeCodeUnspsc190501.10202359'),
	'identifierSchemeCodeUnspsc190501.10202360': __('identifierSchemeCodeUnspsc190501.10202360'),
	'identifierSchemeCodeUnspsc190501.10202361': __('identifierSchemeCodeUnspsc190501.10202361'),
	'identifierSchemeCodeUnspsc190501.10202362': __('identifierSchemeCodeUnspsc190501.10202362'),
	'identifierSchemeCodeUnspsc190501.10202363': __('identifierSchemeCodeUnspsc190501.10202363'),
	'identifierSchemeCodeUnspsc190501.10202364': __('identifierSchemeCodeUnspsc190501.10202364'),
	'identifierSchemeCodeUnspsc190501.10202365': __('identifierSchemeCodeUnspsc190501.10202365'),
	'identifierSchemeCodeUnspsc190501.10202366': __('identifierSchemeCodeUnspsc190501.10202366'),
	'identifierSchemeCodeUnspsc190501.10202367': __('identifierSchemeCodeUnspsc190501.10202367'),
	'identifierSchemeCodeUnspsc190501.10202368': __('identifierSchemeCodeUnspsc190501.10202368'),
	'identifierSchemeCodeUnspsc190501.10202369': __('identifierSchemeCodeUnspsc190501.10202369'),
	'identifierSchemeCodeUnspsc190501.10202370': __('identifierSchemeCodeUnspsc190501.10202370'),
	'identifierSchemeCodeUnspsc190501.10202371': __('identifierSchemeCodeUnspsc190501.10202371'),
	'identifierSchemeCodeUnspsc190501.10202372': __('identifierSchemeCodeUnspsc190501.10202372'),
	'identifierSchemeCodeUnspsc190501.10202373': __('identifierSchemeCodeUnspsc190501.10202373'),
	'identifierSchemeCodeUnspsc190501.10202374': __('identifierSchemeCodeUnspsc190501.10202374'),
	'identifierSchemeCodeUnspsc190501.10202375': __('identifierSchemeCodeUnspsc190501.10202375'),
	'identifierSchemeCodeUnspsc190501.10202376': __('identifierSchemeCodeUnspsc190501.10202376'),
	'identifierSchemeCodeUnspsc190501.10202377': __('identifierSchemeCodeUnspsc190501.10202377'),
	'identifierSchemeCodeUnspsc190501.10202378': __('identifierSchemeCodeUnspsc190501.10202378'),
	'identifierSchemeCodeUnspsc190501.10202379': __('identifierSchemeCodeUnspsc190501.10202379'),
	'identifierSchemeCodeUnspsc190501.10202380': __('identifierSchemeCodeUnspsc190501.10202380'),
	'identifierSchemeCodeUnspsc190501.10202381': __('identifierSchemeCodeUnspsc190501.10202381'),
	'identifierSchemeCodeUnspsc190501.10202382': __('identifierSchemeCodeUnspsc190501.10202382'),
	'identifierSchemeCodeUnspsc190501.10202383': __('identifierSchemeCodeUnspsc190501.10202383'),
	'identifierSchemeCodeUnspsc190501.10202384': __('identifierSchemeCodeUnspsc190501.10202384'),
	'identifierSchemeCodeUnspsc190501.10202385': __('identifierSchemeCodeUnspsc190501.10202385'),
	'identifierSchemeCodeUnspsc190501.10202386': __('identifierSchemeCodeUnspsc190501.10202386'),
	'identifierSchemeCodeUnspsc190501.10202387': __('identifierSchemeCodeUnspsc190501.10202387'),
	'identifierSchemeCodeUnspsc190501.10202388': __('identifierSchemeCodeUnspsc190501.10202388'),
	'identifierSchemeCodeUnspsc190501.10202389': __('identifierSchemeCodeUnspsc190501.10202389'),
	'identifierSchemeCodeUnspsc190501.10202390': __('identifierSchemeCodeUnspsc190501.10202390'),
	'identifierSchemeCodeUnspsc190501.10202391': __('identifierSchemeCodeUnspsc190501.10202391'),
	'identifierSchemeCodeUnspsc190501.10202392': __('identifierSchemeCodeUnspsc190501.10202392'),
	'identifierSchemeCodeUnspsc190501.10202393': __('identifierSchemeCodeUnspsc190501.10202393'),
	'identifierSchemeCodeUnspsc190501.10202400': __('identifierSchemeCodeUnspsc190501.10202400'),
	'identifierSchemeCodeUnspsc190501.10202401': __('identifierSchemeCodeUnspsc190501.10202401'),
	'identifierSchemeCodeUnspsc190501.10202402': __('identifierSchemeCodeUnspsc190501.10202402'),
	'identifierSchemeCodeUnspsc190501.10202403': __('identifierSchemeCodeUnspsc190501.10202403'),
	'identifierSchemeCodeUnspsc190501.10202404': __('identifierSchemeCodeUnspsc190501.10202404'),
	'identifierSchemeCodeUnspsc190501.10202405': __('identifierSchemeCodeUnspsc190501.10202405'),
	'identifierSchemeCodeUnspsc190501.10202406': __('identifierSchemeCodeUnspsc190501.10202406'),
	'identifierSchemeCodeUnspsc190501.10202407': __('identifierSchemeCodeUnspsc190501.10202407'),
	'identifierSchemeCodeUnspsc190501.10202408': __('identifierSchemeCodeUnspsc190501.10202408'),
	'identifierSchemeCodeUnspsc190501.10202409': __('identifierSchemeCodeUnspsc190501.10202409'),
	'identifierSchemeCodeUnspsc190501.10202410': __('identifierSchemeCodeUnspsc190501.10202410'),
	'identifierSchemeCodeUnspsc190501.10202411': __('identifierSchemeCodeUnspsc190501.10202411'),
	'identifierSchemeCodeUnspsc190501.10202412': __('identifierSchemeCodeUnspsc190501.10202412'),
	'identifierSchemeCodeUnspsc190501.10202413': __('identifierSchemeCodeUnspsc190501.10202413'),
	'identifierSchemeCodeUnspsc190501.10202414': __('identifierSchemeCodeUnspsc190501.10202414'),
	'identifierSchemeCodeUnspsc190501.10202415': __('identifierSchemeCodeUnspsc190501.10202415'),
	'identifierSchemeCodeUnspsc190501.10202416': __('identifierSchemeCodeUnspsc190501.10202416'),
	'identifierSchemeCodeUnspsc190501.10202417': __('identifierSchemeCodeUnspsc190501.10202417'),
	'identifierSchemeCodeUnspsc190501.10202418': __('identifierSchemeCodeUnspsc190501.10202418'),
	'identifierSchemeCodeUnspsc190501.10202419': __('identifierSchemeCodeUnspsc190501.10202419'),
	'identifierSchemeCodeUnspsc190501.10202420': __('identifierSchemeCodeUnspsc190501.10202420'),
	'identifierSchemeCodeUnspsc190501.10202421': __('identifierSchemeCodeUnspsc190501.10202421'),
	'identifierSchemeCodeUnspsc190501.10202422': __('identifierSchemeCodeUnspsc190501.10202422'),
	'identifierSchemeCodeUnspsc190501.10202423': __('identifierSchemeCodeUnspsc190501.10202423'),
	'identifierSchemeCodeUnspsc190501.10202424': __('identifierSchemeCodeUnspsc190501.10202424'),
	'identifierSchemeCodeUnspsc190501.10202425': __('identifierSchemeCodeUnspsc190501.10202425'),
	'identifierSchemeCodeUnspsc190501.10202426': __('identifierSchemeCodeUnspsc190501.10202426'),
	'identifierSchemeCodeUnspsc190501.10202427': __('identifierSchemeCodeUnspsc190501.10202427'),
	'identifierSchemeCodeUnspsc190501.10202428': __('identifierSchemeCodeUnspsc190501.10202428'),
	'identifierSchemeCodeUnspsc190501.10202429': __('identifierSchemeCodeUnspsc190501.10202429'),
	'identifierSchemeCodeUnspsc190501.10202430': __('identifierSchemeCodeUnspsc190501.10202430'),
	'identifierSchemeCodeUnspsc190501.10202431': __('identifierSchemeCodeUnspsc190501.10202431'),
	'identifierSchemeCodeUnspsc190501.10202432': __('identifierSchemeCodeUnspsc190501.10202432'),
	'identifierSchemeCodeUnspsc190501.10202433': __('identifierSchemeCodeUnspsc190501.10202433'),
	'identifierSchemeCodeUnspsc190501.10202434': __('identifierSchemeCodeUnspsc190501.10202434'),
	'identifierSchemeCodeUnspsc190501.10202435': __('identifierSchemeCodeUnspsc190501.10202435'),
	'identifierSchemeCodeUnspsc190501.10202436': __('identifierSchemeCodeUnspsc190501.10202436'),
	'identifierSchemeCodeUnspsc190501.10202437': __('identifierSchemeCodeUnspsc190501.10202437'),
	'identifierSchemeCodeUnspsc190501.10202438': __('identifierSchemeCodeUnspsc190501.10202438'),
	'identifierSchemeCodeUnspsc190501.10202439': __('identifierSchemeCodeUnspsc190501.10202439'),
	'identifierSchemeCodeUnspsc190501.10202440': __('identifierSchemeCodeUnspsc190501.10202440'),
	'identifierSchemeCodeUnspsc190501.10202441': __('identifierSchemeCodeUnspsc190501.10202441'),
	'identifierSchemeCodeUnspsc190501.10202442': __('identifierSchemeCodeUnspsc190501.10202442'),
	'identifierSchemeCodeUnspsc190501.10202443': __('identifierSchemeCodeUnspsc190501.10202443'),
	'identifierSchemeCodeUnspsc190501.10202444': __('identifierSchemeCodeUnspsc190501.10202444'),
	'identifierSchemeCodeUnspsc190501.10202445': __('identifierSchemeCodeUnspsc190501.10202445'),
	'identifierSchemeCodeUnspsc190501.10202446': __('identifierSchemeCodeUnspsc190501.10202446'),
	'identifierSchemeCodeUnspsc190501.10202447': __('identifierSchemeCodeUnspsc190501.10202447'),
	'identifierSchemeCodeUnspsc190501.10202448': __('identifierSchemeCodeUnspsc190501.10202448'),
	'identifierSchemeCodeUnspsc190501.10202449': __('identifierSchemeCodeUnspsc190501.10202449'),
	'identifierSchemeCodeUnspsc190501.10202450': __('identifierSchemeCodeUnspsc190501.10202450'),
	'identifierSchemeCodeUnspsc190501.10202451': __('identifierSchemeCodeUnspsc190501.10202451'),
	'identifierSchemeCodeUnspsc190501.10202452': __('identifierSchemeCodeUnspsc190501.10202452'),
	'identifierSchemeCodeUnspsc190501.10202453': __('identifierSchemeCodeUnspsc190501.10202453'),
	'identifierSchemeCodeUnspsc190501.10202454': __('identifierSchemeCodeUnspsc190501.10202454'),
	'identifierSchemeCodeUnspsc190501.10202455': __('identifierSchemeCodeUnspsc190501.10202455'),
	'identifierSchemeCodeUnspsc190501.10202456': __('identifierSchemeCodeUnspsc190501.10202456'),
	'identifierSchemeCodeUnspsc190501.10202457': __('identifierSchemeCodeUnspsc190501.10202457'),
	'identifierSchemeCodeUnspsc190501.10202458': __('identifierSchemeCodeUnspsc190501.10202458'),
	'identifierSchemeCodeUnspsc190501.10202459': __('identifierSchemeCodeUnspsc190501.10202459'),
	'identifierSchemeCodeUnspsc190501.10202460': __('identifierSchemeCodeUnspsc190501.10202460'),
	'identifierSchemeCodeUnspsc190501.10202461': __('identifierSchemeCodeUnspsc190501.10202461'),
	'identifierSchemeCodeUnspsc190501.10202462': __('identifierSchemeCodeUnspsc190501.10202462'),
	'identifierSchemeCodeUnspsc190501.10202463': __('identifierSchemeCodeUnspsc190501.10202463'),
	'identifierSchemeCodeUnspsc190501.10202464': __('identifierSchemeCodeUnspsc190501.10202464'),
	'identifierSchemeCodeUnspsc190501.10202465': __('identifierSchemeCodeUnspsc190501.10202465'),
	'identifierSchemeCodeUnspsc190501.10202466': __('identifierSchemeCodeUnspsc190501.10202466'),
	'identifierSchemeCodeUnspsc190501.10202467': __('identifierSchemeCodeUnspsc190501.10202467'),
	'identifierSchemeCodeUnspsc190501.10202468': __('identifierSchemeCodeUnspsc190501.10202468'),
	'identifierSchemeCodeUnspsc190501.10202469': __('identifierSchemeCodeUnspsc190501.10202469'),
	'identifierSchemeCodeUnspsc190501.10202470': __('identifierSchemeCodeUnspsc190501.10202470'),
	'identifierSchemeCodeUnspsc190501.10202471': __('identifierSchemeCodeUnspsc190501.10202471'),
	'identifierSchemeCodeUnspsc190501.10202472': __('identifierSchemeCodeUnspsc190501.10202472'),
	'identifierSchemeCodeUnspsc190501.10202473': __('identifierSchemeCodeUnspsc190501.10202473'),
	'identifierSchemeCodeUnspsc190501.10202474': __('identifierSchemeCodeUnspsc190501.10202474'),
	'identifierSchemeCodeUnspsc190501.10202475': __('identifierSchemeCodeUnspsc190501.10202475'),
	'identifierSchemeCodeUnspsc190501.10202476': __('identifierSchemeCodeUnspsc190501.10202476'),
	'identifierSchemeCodeUnspsc190501.10202477': __('identifierSchemeCodeUnspsc190501.10202477'),
	'identifierSchemeCodeUnspsc190501.10202478': __('identifierSchemeCodeUnspsc190501.10202478'),
	'identifierSchemeCodeUnspsc190501.10202479': __('identifierSchemeCodeUnspsc190501.10202479'),
	'identifierSchemeCodeUnspsc190501.10202480': __('identifierSchemeCodeUnspsc190501.10202480'),
	'identifierSchemeCodeUnspsc190501.10202500': __('identifierSchemeCodeUnspsc190501.10202500'),
	'identifierSchemeCodeUnspsc190501.10202501': __('identifierSchemeCodeUnspsc190501.10202501'),
	'identifierSchemeCodeUnspsc190501.10202502': __('identifierSchemeCodeUnspsc190501.10202502'),
	'identifierSchemeCodeUnspsc190501.10202503': __('identifierSchemeCodeUnspsc190501.10202503'),
	'identifierSchemeCodeUnspsc190501.10202504': __('identifierSchemeCodeUnspsc190501.10202504'),
	'identifierSchemeCodeUnspsc190501.10202505': __('identifierSchemeCodeUnspsc190501.10202505'),
	'identifierSchemeCodeUnspsc190501.10202506': __('identifierSchemeCodeUnspsc190501.10202506'),
	'identifierSchemeCodeUnspsc190501.10202507': __('identifierSchemeCodeUnspsc190501.10202507'),
	'identifierSchemeCodeUnspsc190501.10202508': __('identifierSchemeCodeUnspsc190501.10202508'),
	'identifierSchemeCodeUnspsc190501.10202509': __('identifierSchemeCodeUnspsc190501.10202509'),
	'identifierSchemeCodeUnspsc190501.10202510': __('identifierSchemeCodeUnspsc190501.10202510'),
	'identifierSchemeCodeUnspsc190501.10202600': __('identifierSchemeCodeUnspsc190501.10202600'),
	'identifierSchemeCodeUnspsc190501.10202601': __('identifierSchemeCodeUnspsc190501.10202601'),
	'identifierSchemeCodeUnspsc190501.10202602': __('identifierSchemeCodeUnspsc190501.10202602'),
	'identifierSchemeCodeUnspsc190501.10202603': __('identifierSchemeCodeUnspsc190501.10202603'),
	'identifierSchemeCodeUnspsc190501.10202604': __('identifierSchemeCodeUnspsc190501.10202604'),
	'identifierSchemeCodeUnspsc190501.10202605': __('identifierSchemeCodeUnspsc190501.10202605'),
	'identifierSchemeCodeUnspsc190501.10202606': __('identifierSchemeCodeUnspsc190501.10202606'),
	'identifierSchemeCodeUnspsc190501.10202607': __('identifierSchemeCodeUnspsc190501.10202607'),
	'identifierSchemeCodeUnspsc190501.10202608': __('identifierSchemeCodeUnspsc190501.10202608'),
	'identifierSchemeCodeUnspsc190501.10202609': __('identifierSchemeCodeUnspsc190501.10202609'),
	'identifierSchemeCodeUnspsc190501.10202610': __('identifierSchemeCodeUnspsc190501.10202610'),
	'identifierSchemeCodeUnspsc190501.10202611': __('identifierSchemeCodeUnspsc190501.10202611'),
	'identifierSchemeCodeUnspsc190501.10202612': __('identifierSchemeCodeUnspsc190501.10202612'),
	'identifierSchemeCodeUnspsc190501.10202613': __('identifierSchemeCodeUnspsc190501.10202613'),
	'identifierSchemeCodeUnspsc190501.10202614': __('identifierSchemeCodeUnspsc190501.10202614'),
	'identifierSchemeCodeUnspsc190501.10202615': __('identifierSchemeCodeUnspsc190501.10202615'),
	'identifierSchemeCodeUnspsc190501.10202616': __('identifierSchemeCodeUnspsc190501.10202616'),
	'identifierSchemeCodeUnspsc190501.10202617': __('identifierSchemeCodeUnspsc190501.10202617'),
	'identifierSchemeCodeUnspsc190501.10202618': __('identifierSchemeCodeUnspsc190501.10202618'),
	'identifierSchemeCodeUnspsc190501.10202619': __('identifierSchemeCodeUnspsc190501.10202619'),
	'identifierSchemeCodeUnspsc190501.10202620': __('identifierSchemeCodeUnspsc190501.10202620'),
	'identifierSchemeCodeUnspsc190501.10202621': __('identifierSchemeCodeUnspsc190501.10202621'),
	'identifierSchemeCodeUnspsc190501.10202622': __('identifierSchemeCodeUnspsc190501.10202622'),
	'identifierSchemeCodeUnspsc190501.10202623': __('identifierSchemeCodeUnspsc190501.10202623'),
	'identifierSchemeCodeUnspsc190501.10202624': __('identifierSchemeCodeUnspsc190501.10202624'),
	'identifierSchemeCodeUnspsc190501.10202625': __('identifierSchemeCodeUnspsc190501.10202625'),
	'identifierSchemeCodeUnspsc190501.10202626': __('identifierSchemeCodeUnspsc190501.10202626'),
	'identifierSchemeCodeUnspsc190501.10202627': __('identifierSchemeCodeUnspsc190501.10202627'),
	'identifierSchemeCodeUnspsc190501.10202628': __('identifierSchemeCodeUnspsc190501.10202628'),
	'identifierSchemeCodeUnspsc190501.10202629': __('identifierSchemeCodeUnspsc190501.10202629'),
	'identifierSchemeCodeUnspsc190501.10202630': __('identifierSchemeCodeUnspsc190501.10202630'),
	'identifierSchemeCodeUnspsc190501.10202631': __('identifierSchemeCodeUnspsc190501.10202631'),
	'identifierSchemeCodeUnspsc190501.10202632': __('identifierSchemeCodeUnspsc190501.10202632'),
	'identifierSchemeCodeUnspsc190501.10202700': __('identifierSchemeCodeUnspsc190501.10202700'),
	'identifierSchemeCodeUnspsc190501.10202701': __('identifierSchemeCodeUnspsc190501.10202701'),
	'identifierSchemeCodeUnspsc190501.10202702': __('identifierSchemeCodeUnspsc190501.10202702'),
	'identifierSchemeCodeUnspsc190501.10202703': __('identifierSchemeCodeUnspsc190501.10202703'),
	'identifierSchemeCodeUnspsc190501.10202704': __('identifierSchemeCodeUnspsc190501.10202704'),
	'identifierSchemeCodeUnspsc190501.10202705': __('identifierSchemeCodeUnspsc190501.10202705'),
	'identifierSchemeCodeUnspsc190501.10202706': __('identifierSchemeCodeUnspsc190501.10202706'),
	'identifierSchemeCodeUnspsc190501.10202707': __('identifierSchemeCodeUnspsc190501.10202707'),
	'identifierSchemeCodeUnspsc190501.10202708': __('identifierSchemeCodeUnspsc190501.10202708'),
	'identifierSchemeCodeUnspsc190501.10202709': __('identifierSchemeCodeUnspsc190501.10202709'),
	'identifierSchemeCodeUnspsc190501.10202710': __('identifierSchemeCodeUnspsc190501.10202710'),
	'identifierSchemeCodeUnspsc190501.10202711': __('identifierSchemeCodeUnspsc190501.10202711'),
	'identifierSchemeCodeUnspsc190501.10202712': __('identifierSchemeCodeUnspsc190501.10202712'),
	'identifierSchemeCodeUnspsc190501.10202713': __('identifierSchemeCodeUnspsc190501.10202713'),
	'identifierSchemeCodeUnspsc190501.10202714': __('identifierSchemeCodeUnspsc190501.10202714'),
	'identifierSchemeCodeUnspsc190501.10202715': __('identifierSchemeCodeUnspsc190501.10202715'),
	'identifierSchemeCodeUnspsc190501.10202716': __('identifierSchemeCodeUnspsc190501.10202716'),
	'identifierSchemeCodeUnspsc190501.10202717': __('identifierSchemeCodeUnspsc190501.10202717'),
	'identifierSchemeCodeUnspsc190501.10202718': __('identifierSchemeCodeUnspsc190501.10202718'),
	'identifierSchemeCodeUnspsc190501.10202719': __('identifierSchemeCodeUnspsc190501.10202719'),
	'identifierSchemeCodeUnspsc190501.10202720': __('identifierSchemeCodeUnspsc190501.10202720'),
	'identifierSchemeCodeUnspsc190501.10202721': __('identifierSchemeCodeUnspsc190501.10202721'),
	'identifierSchemeCodeUnspsc190501.10202722': __('identifierSchemeCodeUnspsc190501.10202722'),
	'identifierSchemeCodeUnspsc190501.10202723': __('identifierSchemeCodeUnspsc190501.10202723'),
	'identifierSchemeCodeUnspsc190501.10202724': __('identifierSchemeCodeUnspsc190501.10202724'),
	'identifierSchemeCodeUnspsc190501.10202725': __('identifierSchemeCodeUnspsc190501.10202725'),
	'identifierSchemeCodeUnspsc190501.10202726': __('identifierSchemeCodeUnspsc190501.10202726'),
	'identifierSchemeCodeUnspsc190501.10202727': __('identifierSchemeCodeUnspsc190501.10202727'),
	'identifierSchemeCodeUnspsc190501.10202728': __('identifierSchemeCodeUnspsc190501.10202728'),
	'identifierSchemeCodeUnspsc190501.10202729': __('identifierSchemeCodeUnspsc190501.10202729'),
	'identifierSchemeCodeUnspsc190501.10202730': __('identifierSchemeCodeUnspsc190501.10202730'),
	'identifierSchemeCodeUnspsc190501.10202731': __('identifierSchemeCodeUnspsc190501.10202731'),
	'identifierSchemeCodeUnspsc190501.10202732': __('identifierSchemeCodeUnspsc190501.10202732'),
	'identifierSchemeCodeUnspsc190501.10202733': __('identifierSchemeCodeUnspsc190501.10202733'),
	'identifierSchemeCodeUnspsc190501.10202734': __('identifierSchemeCodeUnspsc190501.10202734'),
	'identifierSchemeCodeUnspsc190501.10202735': __('identifierSchemeCodeUnspsc190501.10202735'),
	'identifierSchemeCodeUnspsc190501.10202736': __('identifierSchemeCodeUnspsc190501.10202736'),
	'identifierSchemeCodeUnspsc190501.10202737': __('identifierSchemeCodeUnspsc190501.10202737'),
	'identifierSchemeCodeUnspsc190501.10202738': __('identifierSchemeCodeUnspsc190501.10202738'),
	'identifierSchemeCodeUnspsc190501.10202739': __('identifierSchemeCodeUnspsc190501.10202739'),
	'identifierSchemeCodeUnspsc190501.10202740': __('identifierSchemeCodeUnspsc190501.10202740'),
	'identifierSchemeCodeUnspsc190501.10202741': __('identifierSchemeCodeUnspsc190501.10202741'),
	'identifierSchemeCodeUnspsc190501.10202742': __('identifierSchemeCodeUnspsc190501.10202742'),
	'identifierSchemeCodeUnspsc190501.10202743': __('identifierSchemeCodeUnspsc190501.10202743'),
	'identifierSchemeCodeUnspsc190501.10202744': __('identifierSchemeCodeUnspsc190501.10202744'),
	'identifierSchemeCodeUnspsc190501.10202745': __('identifierSchemeCodeUnspsc190501.10202745'),
	'identifierSchemeCodeUnspsc190501.10202746': __('identifierSchemeCodeUnspsc190501.10202746'),
	'identifierSchemeCodeUnspsc190501.10202747': __('identifierSchemeCodeUnspsc190501.10202747'),
	'identifierSchemeCodeUnspsc190501.10202748': __('identifierSchemeCodeUnspsc190501.10202748'),
	'identifierSchemeCodeUnspsc190501.10202749': __('identifierSchemeCodeUnspsc190501.10202749'),
	'identifierSchemeCodeUnspsc190501.10202750': __('identifierSchemeCodeUnspsc190501.10202750'),
	'identifierSchemeCodeUnspsc190501.10202751': __('identifierSchemeCodeUnspsc190501.10202751'),
	'identifierSchemeCodeUnspsc190501.10202752': __('identifierSchemeCodeUnspsc190501.10202752'),
	'identifierSchemeCodeUnspsc190501.10202753': __('identifierSchemeCodeUnspsc190501.10202753'),
	'identifierSchemeCodeUnspsc190501.10202754': __('identifierSchemeCodeUnspsc190501.10202754'),
	'identifierSchemeCodeUnspsc190501.10202755': __('identifierSchemeCodeUnspsc190501.10202755'),
	'identifierSchemeCodeUnspsc190501.10202756': __('identifierSchemeCodeUnspsc190501.10202756'),
	'identifierSchemeCodeUnspsc190501.10202757': __('identifierSchemeCodeUnspsc190501.10202757'),
	'identifierSchemeCodeUnspsc190501.10202758': __('identifierSchemeCodeUnspsc190501.10202758'),
	'identifierSchemeCodeUnspsc190501.10202759': __('identifierSchemeCodeUnspsc190501.10202759'),
	'identifierSchemeCodeUnspsc190501.10202760': __('identifierSchemeCodeUnspsc190501.10202760'),
	'identifierSchemeCodeUnspsc190501.10202761': __('identifierSchemeCodeUnspsc190501.10202761'),
	'identifierSchemeCodeUnspsc190501.10202762': __('identifierSchemeCodeUnspsc190501.10202762'),
	'identifierSchemeCodeUnspsc190501.10202763': __('identifierSchemeCodeUnspsc190501.10202763'),
	'identifierSchemeCodeUnspsc190501.10202764': __('identifierSchemeCodeUnspsc190501.10202764'),
	'identifierSchemeCodeUnspsc190501.10202765': __('identifierSchemeCodeUnspsc190501.10202765'),
	'identifierSchemeCodeUnspsc190501.10202766': __('identifierSchemeCodeUnspsc190501.10202766'),
	'identifierSchemeCodeUnspsc190501.10202767': __('identifierSchemeCodeUnspsc190501.10202767'),
	'identifierSchemeCodeUnspsc190501.10202768': __('identifierSchemeCodeUnspsc190501.10202768'),
	'identifierSchemeCodeUnspsc190501.10202769': __('identifierSchemeCodeUnspsc190501.10202769'),
	'identifierSchemeCodeUnspsc190501.10202770': __('identifierSchemeCodeUnspsc190501.10202770'),
	'identifierSchemeCodeUnspsc190501.10202771': __('identifierSchemeCodeUnspsc190501.10202771'),
	'identifierSchemeCodeUnspsc190501.10202772': __('identifierSchemeCodeUnspsc190501.10202772'),
	'identifierSchemeCodeUnspsc190501.10202773': __('identifierSchemeCodeUnspsc190501.10202773'),
	'identifierSchemeCodeUnspsc190501.10202774': __('identifierSchemeCodeUnspsc190501.10202774'),
	'identifierSchemeCodeUnspsc190501.10202775': __('identifierSchemeCodeUnspsc190501.10202775'),
	'identifierSchemeCodeUnspsc190501.10202776': __('identifierSchemeCodeUnspsc190501.10202776'),
	'identifierSchemeCodeUnspsc190501.10202777': __('identifierSchemeCodeUnspsc190501.10202777'),
	'identifierSchemeCodeUnspsc190501.10202778': __('identifierSchemeCodeUnspsc190501.10202778'),
	'identifierSchemeCodeUnspsc190501.10202779': __('identifierSchemeCodeUnspsc190501.10202779'),
	'identifierSchemeCodeUnspsc190501.10202780': __('identifierSchemeCodeUnspsc190501.10202780'),
	'identifierSchemeCodeUnspsc190501.10202781': __('identifierSchemeCodeUnspsc190501.10202781'),
	'identifierSchemeCodeUnspsc190501.10202782': __('identifierSchemeCodeUnspsc190501.10202782'),
	'identifierSchemeCodeUnspsc190501.10202800': __('identifierSchemeCodeUnspsc190501.10202800'),
	'identifierSchemeCodeUnspsc190501.10202801': __('identifierSchemeCodeUnspsc190501.10202801'),
	'identifierSchemeCodeUnspsc190501.10202802': __('identifierSchemeCodeUnspsc190501.10202802'),
	'identifierSchemeCodeUnspsc190501.10202803': __('identifierSchemeCodeUnspsc190501.10202803'),
	'identifierSchemeCodeUnspsc190501.10202804': __('identifierSchemeCodeUnspsc190501.10202804'),
	'identifierSchemeCodeUnspsc190501.10202805': __('identifierSchemeCodeUnspsc190501.10202805'),
	'identifierSchemeCodeUnspsc190501.10202806': __('identifierSchemeCodeUnspsc190501.10202806'),
	'identifierSchemeCodeUnspsc190501.10202807': __('identifierSchemeCodeUnspsc190501.10202807'),
	'identifierSchemeCodeUnspsc190501.10202808': __('identifierSchemeCodeUnspsc190501.10202808'),
	'identifierSchemeCodeUnspsc190501.10202809': __('identifierSchemeCodeUnspsc190501.10202809'),
	'identifierSchemeCodeUnspsc190501.10202810': __('identifierSchemeCodeUnspsc190501.10202810'),
	'identifierSchemeCodeUnspsc190501.10202811': __('identifierSchemeCodeUnspsc190501.10202811'),
	'identifierSchemeCodeUnspsc190501.10202812': __('identifierSchemeCodeUnspsc190501.10202812'),
	'identifierSchemeCodeUnspsc190501.10202813': __('identifierSchemeCodeUnspsc190501.10202813'),
	'identifierSchemeCodeUnspsc190501.10202814': __('identifierSchemeCodeUnspsc190501.10202814'),
	'identifierSchemeCodeUnspsc190501.10202815': __('identifierSchemeCodeUnspsc190501.10202815'),
	'identifierSchemeCodeUnspsc190501.10202816': __('identifierSchemeCodeUnspsc190501.10202816'),
	'identifierSchemeCodeUnspsc190501.10202817': __('identifierSchemeCodeUnspsc190501.10202817'),
	'identifierSchemeCodeUnspsc190501.10202818': __('identifierSchemeCodeUnspsc190501.10202818'),
	'identifierSchemeCodeUnspsc190501.10202819': __('identifierSchemeCodeUnspsc190501.10202819'),
	'identifierSchemeCodeUnspsc190501.10202820': __('identifierSchemeCodeUnspsc190501.10202820'),
	'identifierSchemeCodeUnspsc190501.10202821': __('identifierSchemeCodeUnspsc190501.10202821'),
	'identifierSchemeCodeUnspsc190501.10202822': __('identifierSchemeCodeUnspsc190501.10202822'),
	'identifierSchemeCodeUnspsc190501.10202823': __('identifierSchemeCodeUnspsc190501.10202823'),
	'identifierSchemeCodeUnspsc190501.10202824': __('identifierSchemeCodeUnspsc190501.10202824'),
	'identifierSchemeCodeUnspsc190501.10202825': __('identifierSchemeCodeUnspsc190501.10202825'),
	'identifierSchemeCodeUnspsc190501.10202826': __('identifierSchemeCodeUnspsc190501.10202826'),
	'identifierSchemeCodeUnspsc190501.10202827': __('identifierSchemeCodeUnspsc190501.10202827'),
	'identifierSchemeCodeUnspsc190501.10202828': __('identifierSchemeCodeUnspsc190501.10202828'),
	'identifierSchemeCodeUnspsc190501.10202829': __('identifierSchemeCodeUnspsc190501.10202829'),
	'identifierSchemeCodeUnspsc190501.10202830': __('identifierSchemeCodeUnspsc190501.10202830'),
	'identifierSchemeCodeUnspsc190501.10202831': __('identifierSchemeCodeUnspsc190501.10202831'),
	'identifierSchemeCodeUnspsc190501.10202832': __('identifierSchemeCodeUnspsc190501.10202832'),
	'identifierSchemeCodeUnspsc190501.10202833': __('identifierSchemeCodeUnspsc190501.10202833'),
	'identifierSchemeCodeUnspsc190501.10202834': __('identifierSchemeCodeUnspsc190501.10202834'),
	'identifierSchemeCodeUnspsc190501.10202835': __('identifierSchemeCodeUnspsc190501.10202835'),
	'identifierSchemeCodeUnspsc190501.10202836': __('identifierSchemeCodeUnspsc190501.10202836'),
	'identifierSchemeCodeUnspsc190501.10202837': __('identifierSchemeCodeUnspsc190501.10202837'),
	'identifierSchemeCodeUnspsc190501.10202838': __('identifierSchemeCodeUnspsc190501.10202838'),
	'identifierSchemeCodeUnspsc190501.10202839': __('identifierSchemeCodeUnspsc190501.10202839'),
	'identifierSchemeCodeUnspsc190501.10202840': __('identifierSchemeCodeUnspsc190501.10202840'),
	'identifierSchemeCodeUnspsc190501.10202841': __('identifierSchemeCodeUnspsc190501.10202841'),
	'identifierSchemeCodeUnspsc190501.10202842': __('identifierSchemeCodeUnspsc190501.10202842'),
	'identifierSchemeCodeUnspsc190501.10202843': __('identifierSchemeCodeUnspsc190501.10202843'),
	'identifierSchemeCodeUnspsc190501.10202844': __('identifierSchemeCodeUnspsc190501.10202844'),
	'identifierSchemeCodeUnspsc190501.10202845': __('identifierSchemeCodeUnspsc190501.10202845'),
	'identifierSchemeCodeUnspsc190501.10202846': __('identifierSchemeCodeUnspsc190501.10202846'),
	'identifierSchemeCodeUnspsc190501.10202847': __('identifierSchemeCodeUnspsc190501.10202847'),
	'identifierSchemeCodeUnspsc190501.10202848': __('identifierSchemeCodeUnspsc190501.10202848'),
	'identifierSchemeCodeUnspsc190501.10202849': __('identifierSchemeCodeUnspsc190501.10202849'),
	'identifierSchemeCodeUnspsc190501.10202850': __('identifierSchemeCodeUnspsc190501.10202850'),
	'identifierSchemeCodeUnspsc190501.10202851': __('identifierSchemeCodeUnspsc190501.10202851'),
	'identifierSchemeCodeUnspsc190501.10202852': __('identifierSchemeCodeUnspsc190501.10202852'),
	'identifierSchemeCodeUnspsc190501.10202853': __('identifierSchemeCodeUnspsc190501.10202853'),
	'identifierSchemeCodeUnspsc190501.10202854': __('identifierSchemeCodeUnspsc190501.10202854'),
	'identifierSchemeCodeUnspsc190501.10202855': __('identifierSchemeCodeUnspsc190501.10202855'),
	'identifierSchemeCodeUnspsc190501.10202856': __('identifierSchemeCodeUnspsc190501.10202856'),
	'identifierSchemeCodeUnspsc190501.10202857': __('identifierSchemeCodeUnspsc190501.10202857'),
	'identifierSchemeCodeUnspsc190501.10202858': __('identifierSchemeCodeUnspsc190501.10202858'),
	'identifierSchemeCodeUnspsc190501.10202859': __('identifierSchemeCodeUnspsc190501.10202859'),
	'identifierSchemeCodeUnspsc190501.10202860': __('identifierSchemeCodeUnspsc190501.10202860'),
	'identifierSchemeCodeUnspsc190501.10202861': __('identifierSchemeCodeUnspsc190501.10202861'),
	'identifierSchemeCodeUnspsc190501.10202862': __('identifierSchemeCodeUnspsc190501.10202862'),
	'identifierSchemeCodeUnspsc190501.10202863': __('identifierSchemeCodeUnspsc190501.10202863'),
	'identifierSchemeCodeUnspsc190501.10202864': __('identifierSchemeCodeUnspsc190501.10202864'),
	'identifierSchemeCodeUnspsc190501.10202865': __('identifierSchemeCodeUnspsc190501.10202865'),
	'identifierSchemeCodeUnspsc190501.10202866': __('identifierSchemeCodeUnspsc190501.10202866'),
	'identifierSchemeCodeUnspsc190501.10202867': __('identifierSchemeCodeUnspsc190501.10202867'),
	'identifierSchemeCodeUnspsc190501.10202868': __('identifierSchemeCodeUnspsc190501.10202868'),
	'identifierSchemeCodeUnspsc190501.10202869': __('identifierSchemeCodeUnspsc190501.10202869'),
	'identifierSchemeCodeUnspsc190501.10202870': __('identifierSchemeCodeUnspsc190501.10202870'),
	'identifierSchemeCodeUnspsc190501.10202871': __('identifierSchemeCodeUnspsc190501.10202871'),
	'identifierSchemeCodeUnspsc190501.10202872': __('identifierSchemeCodeUnspsc190501.10202872'),
	'identifierSchemeCodeUnspsc190501.10202873': __('identifierSchemeCodeUnspsc190501.10202873'),
	'identifierSchemeCodeUnspsc190501.10202874': __('identifierSchemeCodeUnspsc190501.10202874'),
	'identifierSchemeCodeUnspsc190501.10202875': __('identifierSchemeCodeUnspsc190501.10202875'),
	'identifierSchemeCodeUnspsc190501.10202876': __('identifierSchemeCodeUnspsc190501.10202876'),
	'identifierSchemeCodeUnspsc190501.10202877': __('identifierSchemeCodeUnspsc190501.10202877'),
	'identifierSchemeCodeUnspsc190501.10202878': __('identifierSchemeCodeUnspsc190501.10202878'),
	'identifierSchemeCodeUnspsc190501.10202879': __('identifierSchemeCodeUnspsc190501.10202879'),
	'identifierSchemeCodeUnspsc190501.10202880': __('identifierSchemeCodeUnspsc190501.10202880'),
	'identifierSchemeCodeUnspsc190501.10202881': __('identifierSchemeCodeUnspsc190501.10202881'),
	'identifierSchemeCodeUnspsc190501.10202882': __('identifierSchemeCodeUnspsc190501.10202882'),
	'identifierSchemeCodeUnspsc190501.10202883': __('identifierSchemeCodeUnspsc190501.10202883'),
	'identifierSchemeCodeUnspsc190501.10202884': __('identifierSchemeCodeUnspsc190501.10202884'),
	'identifierSchemeCodeUnspsc190501.10202885': __('identifierSchemeCodeUnspsc190501.10202885'),
	'identifierSchemeCodeUnspsc190501.10202886': __('identifierSchemeCodeUnspsc190501.10202886'),
	'identifierSchemeCodeUnspsc190501.10202887': __('identifierSchemeCodeUnspsc190501.10202887'),
	'identifierSchemeCodeUnspsc190501.10202888': __('identifierSchemeCodeUnspsc190501.10202888'),
	'identifierSchemeCodeUnspsc190501.10210000': __('identifierSchemeCodeUnspsc190501.10210000'),
	'identifierSchemeCodeUnspsc190501.10211500': __('identifierSchemeCodeUnspsc190501.10211500'),
	'identifierSchemeCodeUnspsc190501.10211501': __('identifierSchemeCodeUnspsc190501.10211501'),
	'identifierSchemeCodeUnspsc190501.10211502': __('identifierSchemeCodeUnspsc190501.10211502'),
	'identifierSchemeCodeUnspsc190501.10211503': __('identifierSchemeCodeUnspsc190501.10211503'),
	'identifierSchemeCodeUnspsc190501.10211504': __('identifierSchemeCodeUnspsc190501.10211504'),
	'identifierSchemeCodeUnspsc190501.10211505': __('identifierSchemeCodeUnspsc190501.10211505'),
	'identifierSchemeCodeUnspsc190501.10211506': __('identifierSchemeCodeUnspsc190501.10211506'),
	'identifierSchemeCodeUnspsc190501.10211507': __('identifierSchemeCodeUnspsc190501.10211507'),
	'identifierSchemeCodeUnspsc190501.10211508': __('identifierSchemeCodeUnspsc190501.10211508'),
	'identifierSchemeCodeUnspsc190501.10211509': __('identifierSchemeCodeUnspsc190501.10211509'),
	'identifierSchemeCodeUnspsc190501.10211510': __('identifierSchemeCodeUnspsc190501.10211510'),
	'identifierSchemeCodeUnspsc190501.10211511': __('identifierSchemeCodeUnspsc190501.10211511'),
	'identifierSchemeCodeUnspsc190501.10211512': __('identifierSchemeCodeUnspsc190501.10211512'),
	'identifierSchemeCodeUnspsc190501.10211513': __('identifierSchemeCodeUnspsc190501.10211513'),
	'identifierSchemeCodeUnspsc190501.10211514': __('identifierSchemeCodeUnspsc190501.10211514'),
	'identifierSchemeCodeUnspsc190501.10211515': __('identifierSchemeCodeUnspsc190501.10211515'),
	'identifierSchemeCodeUnspsc190501.10211516': __('identifierSchemeCodeUnspsc190501.10211516'),
	'identifierSchemeCodeUnspsc190501.10211517': __('identifierSchemeCodeUnspsc190501.10211517'),
	'identifierSchemeCodeUnspsc190501.10211518': __('identifierSchemeCodeUnspsc190501.10211518'),
	'identifierSchemeCodeUnspsc190501.10211519': __('identifierSchemeCodeUnspsc190501.10211519'),
	'identifierSchemeCodeUnspsc190501.10211600': __('identifierSchemeCodeUnspsc190501.10211600'),
	'identifierSchemeCodeUnspsc190501.10211601': __('identifierSchemeCodeUnspsc190501.10211601'),
	'identifierSchemeCodeUnspsc190501.10211602': __('identifierSchemeCodeUnspsc190501.10211602'),
	'identifierSchemeCodeUnspsc190501.10211603': __('identifierSchemeCodeUnspsc190501.10211603'),
	'identifierSchemeCodeUnspsc190501.10211604': __('identifierSchemeCodeUnspsc190501.10211604'),
	'identifierSchemeCodeUnspsc190501.10211605': __('identifierSchemeCodeUnspsc190501.10211605'),
	'identifierSchemeCodeUnspsc190501.10211606': __('identifierSchemeCodeUnspsc190501.10211606'),
	'identifierSchemeCodeUnspsc190501.10211607': __('identifierSchemeCodeUnspsc190501.10211607'),
	'identifierSchemeCodeUnspsc190501.10211608': __('identifierSchemeCodeUnspsc190501.10211608'),
	'identifierSchemeCodeUnspsc190501.10211609': __('identifierSchemeCodeUnspsc190501.10211609'),
	'identifierSchemeCodeUnspsc190501.10211610': __('identifierSchemeCodeUnspsc190501.10211610'),
	'identifierSchemeCodeUnspsc190501.10211611': __('identifierSchemeCodeUnspsc190501.10211611'),
	'identifierSchemeCodeUnspsc190501.10211612': __('identifierSchemeCodeUnspsc190501.10211612'),
	'identifierSchemeCodeUnspsc190501.10211613': __('identifierSchemeCodeUnspsc190501.10211613'),
	'identifierSchemeCodeUnspsc190501.10211614': __('identifierSchemeCodeUnspsc190501.10211614'),
	'identifierSchemeCodeUnspsc190501.10211615': __('identifierSchemeCodeUnspsc190501.10211615'),
	'identifierSchemeCodeUnspsc190501.10211616': __('identifierSchemeCodeUnspsc190501.10211616'),
	'identifierSchemeCodeUnspsc190501.10211617': __('identifierSchemeCodeUnspsc190501.10211617'),
	'identifierSchemeCodeUnspsc190501.10211618': __('identifierSchemeCodeUnspsc190501.10211618'),
	'identifierSchemeCodeUnspsc190501.10211619': __('identifierSchemeCodeUnspsc190501.10211619'),
	'identifierSchemeCodeUnspsc190501.10211700': __('identifierSchemeCodeUnspsc190501.10211700'),
	'identifierSchemeCodeUnspsc190501.10211701': __('identifierSchemeCodeUnspsc190501.10211701'),
	'identifierSchemeCodeUnspsc190501.10211702': __('identifierSchemeCodeUnspsc190501.10211702'),
	'identifierSchemeCodeUnspsc190501.10211703': __('identifierSchemeCodeUnspsc190501.10211703'),
	'identifierSchemeCodeUnspsc190501.10211704': __('identifierSchemeCodeUnspsc190501.10211704'),
	'identifierSchemeCodeUnspsc190501.10211705': __('identifierSchemeCodeUnspsc190501.10211705'),
	'identifierSchemeCodeUnspsc190501.10211706': __('identifierSchemeCodeUnspsc190501.10211706'),
	'identifierSchemeCodeUnspsc190501.10211707': __('identifierSchemeCodeUnspsc190501.10211707'),
	'identifierSchemeCodeUnspsc190501.10211708': __('identifierSchemeCodeUnspsc190501.10211708'),
	'identifierSchemeCodeUnspsc190501.10211709': __('identifierSchemeCodeUnspsc190501.10211709'),
	'identifierSchemeCodeUnspsc190501.10211710': __('identifierSchemeCodeUnspsc190501.10211710'),
	'identifierSchemeCodeUnspsc190501.10211711': __('identifierSchemeCodeUnspsc190501.10211711'),
	'identifierSchemeCodeUnspsc190501.10211712': __('identifierSchemeCodeUnspsc190501.10211712'),
	'identifierSchemeCodeUnspsc190501.10211713': __('identifierSchemeCodeUnspsc190501.10211713'),
	'identifierSchemeCodeUnspsc190501.10211714': __('identifierSchemeCodeUnspsc190501.10211714'),
	'identifierSchemeCodeUnspsc190501.10211715': __('identifierSchemeCodeUnspsc190501.10211715'),
	'identifierSchemeCodeUnspsc190501.10211716': __('identifierSchemeCodeUnspsc190501.10211716'),
	'identifierSchemeCodeUnspsc190501.10211717': __('identifierSchemeCodeUnspsc190501.10211717'),
	'identifierSchemeCodeUnspsc190501.10211718': __('identifierSchemeCodeUnspsc190501.10211718'),
	'identifierSchemeCodeUnspsc190501.10211719': __('identifierSchemeCodeUnspsc190501.10211719'),
	'identifierSchemeCodeUnspsc190501.10211720': __('identifierSchemeCodeUnspsc190501.10211720'),
	'identifierSchemeCodeUnspsc190501.10211721': __('identifierSchemeCodeUnspsc190501.10211721'),
	'identifierSchemeCodeUnspsc190501.10211722': __('identifierSchemeCodeUnspsc190501.10211722'),
	'identifierSchemeCodeUnspsc190501.10211723': __('identifierSchemeCodeUnspsc190501.10211723'),
	'identifierSchemeCodeUnspsc190501.10211724': __('identifierSchemeCodeUnspsc190501.10211724'),
	'identifierSchemeCodeUnspsc190501.10211725': __('identifierSchemeCodeUnspsc190501.10211725'),
	'identifierSchemeCodeUnspsc190501.10211726': __('identifierSchemeCodeUnspsc190501.10211726'),
	'identifierSchemeCodeUnspsc190501.10211727': __('identifierSchemeCodeUnspsc190501.10211727'),
	'identifierSchemeCodeUnspsc190501.10211728': __('identifierSchemeCodeUnspsc190501.10211728'),
	'identifierSchemeCodeUnspsc190501.10211729': __('identifierSchemeCodeUnspsc190501.10211729'),
	'identifierSchemeCodeUnspsc190501.10211730': __('identifierSchemeCodeUnspsc190501.10211730'),
	'identifierSchemeCodeUnspsc190501.10211731': __('identifierSchemeCodeUnspsc190501.10211731'),
	'identifierSchemeCodeUnspsc190501.10211732': __('identifierSchemeCodeUnspsc190501.10211732'),
	'identifierSchemeCodeUnspsc190501.10211733': __('identifierSchemeCodeUnspsc190501.10211733'),
	'identifierSchemeCodeUnspsc190501.10211734': __('identifierSchemeCodeUnspsc190501.10211734'),
	'identifierSchemeCodeUnspsc190501.10211800': __('identifierSchemeCodeUnspsc190501.10211800'),
	'identifierSchemeCodeUnspsc190501.10211801': __('identifierSchemeCodeUnspsc190501.10211801'),
	'identifierSchemeCodeUnspsc190501.10211802': __('identifierSchemeCodeUnspsc190501.10211802'),
	'identifierSchemeCodeUnspsc190501.10211803': __('identifierSchemeCodeUnspsc190501.10211803'),
	'identifierSchemeCodeUnspsc190501.10211804': __('identifierSchemeCodeUnspsc190501.10211804'),
	'identifierSchemeCodeUnspsc190501.10211805': __('identifierSchemeCodeUnspsc190501.10211805'),
	'identifierSchemeCodeUnspsc190501.10211900': __('identifierSchemeCodeUnspsc190501.10211900'),
	'identifierSchemeCodeUnspsc190501.10211901': __('identifierSchemeCodeUnspsc190501.10211901'),
	'identifierSchemeCodeUnspsc190501.10211902': __('identifierSchemeCodeUnspsc190501.10211902'),
	'identifierSchemeCodeUnspsc190501.10211903': __('identifierSchemeCodeUnspsc190501.10211903'),
	'identifierSchemeCodeUnspsc190501.10211904': __('identifierSchemeCodeUnspsc190501.10211904'),
	'identifierSchemeCodeUnspsc190501.10211905': __('identifierSchemeCodeUnspsc190501.10211905'),
	'identifierSchemeCodeUnspsc190501.10211906': __('identifierSchemeCodeUnspsc190501.10211906'),
	'identifierSchemeCodeUnspsc190501.10211907': __('identifierSchemeCodeUnspsc190501.10211907'),
	'identifierSchemeCodeUnspsc190501.10211908': __('identifierSchemeCodeUnspsc190501.10211908'),
	'identifierSchemeCodeUnspsc190501.10211909': __('identifierSchemeCodeUnspsc190501.10211909'),
	'identifierSchemeCodeUnspsc190501.10211910': __('identifierSchemeCodeUnspsc190501.10211910'),
	'identifierSchemeCodeUnspsc190501.10212000': __('identifierSchemeCodeUnspsc190501.10212000'),
	'identifierSchemeCodeUnspsc190501.10212001': __('identifierSchemeCodeUnspsc190501.10212001'),
	'identifierSchemeCodeUnspsc190501.10212002': __('identifierSchemeCodeUnspsc190501.10212002'),
	'identifierSchemeCodeUnspsc190501.10212003': __('identifierSchemeCodeUnspsc190501.10212003'),
	'identifierSchemeCodeUnspsc190501.10212004': __('identifierSchemeCodeUnspsc190501.10212004'),
	'identifierSchemeCodeUnspsc190501.10212005': __('identifierSchemeCodeUnspsc190501.10212005'),
	'identifierSchemeCodeUnspsc190501.10212006': __('identifierSchemeCodeUnspsc190501.10212006'),
	'identifierSchemeCodeUnspsc190501.10212007': __('identifierSchemeCodeUnspsc190501.10212007'),
	'identifierSchemeCodeUnspsc190501.10212008': __('identifierSchemeCodeUnspsc190501.10212008'),
	'identifierSchemeCodeUnspsc190501.10212009': __('identifierSchemeCodeUnspsc190501.10212009'),
	'identifierSchemeCodeUnspsc190501.10212010': __('identifierSchemeCodeUnspsc190501.10212010'),
	'identifierSchemeCodeUnspsc190501.10212011': __('identifierSchemeCodeUnspsc190501.10212011'),
	'identifierSchemeCodeUnspsc190501.10212100': __('identifierSchemeCodeUnspsc190501.10212100'),
	'identifierSchemeCodeUnspsc190501.10212101': __('identifierSchemeCodeUnspsc190501.10212101'),
	'identifierSchemeCodeUnspsc190501.10212102': __('identifierSchemeCodeUnspsc190501.10212102'),
	'identifierSchemeCodeUnspsc190501.10212103': __('identifierSchemeCodeUnspsc190501.10212103'),
	'identifierSchemeCodeUnspsc190501.10212104': __('identifierSchemeCodeUnspsc190501.10212104'),
	'identifierSchemeCodeUnspsc190501.10212200': __('identifierSchemeCodeUnspsc190501.10212200'),
	'identifierSchemeCodeUnspsc190501.10212201': __('identifierSchemeCodeUnspsc190501.10212201'),
	'identifierSchemeCodeUnspsc190501.10212202': __('identifierSchemeCodeUnspsc190501.10212202'),
	'identifierSchemeCodeUnspsc190501.10212203': __('identifierSchemeCodeUnspsc190501.10212203'),
	'identifierSchemeCodeUnspsc190501.10212204': __('identifierSchemeCodeUnspsc190501.10212204'),
	'identifierSchemeCodeUnspsc190501.10212205': __('identifierSchemeCodeUnspsc190501.10212205'),
	'identifierSchemeCodeUnspsc190501.10212206': __('identifierSchemeCodeUnspsc190501.10212206'),
	'identifierSchemeCodeUnspsc190501.10212207': __('identifierSchemeCodeUnspsc190501.10212207'),
	'identifierSchemeCodeUnspsc190501.10212208': __('identifierSchemeCodeUnspsc190501.10212208'),
	'identifierSchemeCodeUnspsc190501.10212209': __('identifierSchemeCodeUnspsc190501.10212209'),
	'identifierSchemeCodeUnspsc190501.10212210': __('identifierSchemeCodeUnspsc190501.10212210'),
	'identifierSchemeCodeUnspsc190501.10212211': __('identifierSchemeCodeUnspsc190501.10212211'),
	'identifierSchemeCodeUnspsc190501.10212212': __('identifierSchemeCodeUnspsc190501.10212212'),
	'identifierSchemeCodeUnspsc190501.10212213': __('identifierSchemeCodeUnspsc190501.10212213'),
	'identifierSchemeCodeUnspsc190501.10212214': __('identifierSchemeCodeUnspsc190501.10212214'),
	'identifierSchemeCodeUnspsc190501.10212215': __('identifierSchemeCodeUnspsc190501.10212215'),
	'identifierSchemeCodeUnspsc190501.10212216': __('identifierSchemeCodeUnspsc190501.10212216'),
	'identifierSchemeCodeUnspsc190501.10212217': __('identifierSchemeCodeUnspsc190501.10212217'),
	'identifierSchemeCodeUnspsc190501.10212218': __('identifierSchemeCodeUnspsc190501.10212218'),
	'identifierSchemeCodeUnspsc190501.10212219': __('identifierSchemeCodeUnspsc190501.10212219'),
	'identifierSchemeCodeUnspsc190501.10212300': __('identifierSchemeCodeUnspsc190501.10212300'),
	'identifierSchemeCodeUnspsc190501.10212301': __('identifierSchemeCodeUnspsc190501.10212301'),
	'identifierSchemeCodeUnspsc190501.10212302': __('identifierSchemeCodeUnspsc190501.10212302'),
	'identifierSchemeCodeUnspsc190501.10212303': __('identifierSchemeCodeUnspsc190501.10212303'),
	'identifierSchemeCodeUnspsc190501.10212304': __('identifierSchemeCodeUnspsc190501.10212304'),
	'identifierSchemeCodeUnspsc190501.10212305': __('identifierSchemeCodeUnspsc190501.10212305'),
	'identifierSchemeCodeUnspsc190501.10212400': __('identifierSchemeCodeUnspsc190501.10212400'),
	'identifierSchemeCodeUnspsc190501.10212401': __('identifierSchemeCodeUnspsc190501.10212401'),
	'identifierSchemeCodeUnspsc190501.10212402': __('identifierSchemeCodeUnspsc190501.10212402'),
	'identifierSchemeCodeUnspsc190501.10212403': __('identifierSchemeCodeUnspsc190501.10212403'),
	'identifierSchemeCodeUnspsc190501.10212404': __('identifierSchemeCodeUnspsc190501.10212404'),
	'identifierSchemeCodeUnspsc190501.10212405': __('identifierSchemeCodeUnspsc190501.10212405'),
	'identifierSchemeCodeUnspsc190501.10212500': __('identifierSchemeCodeUnspsc190501.10212500'),
	'identifierSchemeCodeUnspsc190501.10212501': __('identifierSchemeCodeUnspsc190501.10212501'),
	'identifierSchemeCodeUnspsc190501.10212502': __('identifierSchemeCodeUnspsc190501.10212502'),
	'identifierSchemeCodeUnspsc190501.10212503': __('identifierSchemeCodeUnspsc190501.10212503'),
	'identifierSchemeCodeUnspsc190501.10212504': __('identifierSchemeCodeUnspsc190501.10212504'),
	'identifierSchemeCodeUnspsc190501.10212505': __('identifierSchemeCodeUnspsc190501.10212505'),
	'identifierSchemeCodeUnspsc190501.10212600': __('identifierSchemeCodeUnspsc190501.10212600'),
	'identifierSchemeCodeUnspsc190501.10212601': __('identifierSchemeCodeUnspsc190501.10212601'),
	'identifierSchemeCodeUnspsc190501.10212602': __('identifierSchemeCodeUnspsc190501.10212602'),
	'identifierSchemeCodeUnspsc190501.10212603': __('identifierSchemeCodeUnspsc190501.10212603'),
	'identifierSchemeCodeUnspsc190501.10212604': __('identifierSchemeCodeUnspsc190501.10212604'),
	'identifierSchemeCodeUnspsc190501.10212605': __('identifierSchemeCodeUnspsc190501.10212605'),
	'identifierSchemeCodeUnspsc190501.10212606': __('identifierSchemeCodeUnspsc190501.10212606'),
	'identifierSchemeCodeUnspsc190501.10212607': __('identifierSchemeCodeUnspsc190501.10212607'),
	'identifierSchemeCodeUnspsc190501.10212608': __('identifierSchemeCodeUnspsc190501.10212608'),
	'identifierSchemeCodeUnspsc190501.10212609': __('identifierSchemeCodeUnspsc190501.10212609'),
	'identifierSchemeCodeUnspsc190501.10212610': __('identifierSchemeCodeUnspsc190501.10212610'),
	'identifierSchemeCodeUnspsc190501.10212611': __('identifierSchemeCodeUnspsc190501.10212611'),
	'identifierSchemeCodeUnspsc190501.10212612': __('identifierSchemeCodeUnspsc190501.10212612'),
	'identifierSchemeCodeUnspsc190501.10212613': __('identifierSchemeCodeUnspsc190501.10212613'),
	'identifierSchemeCodeUnspsc190501.10212614': __('identifierSchemeCodeUnspsc190501.10212614'),
	'identifierSchemeCodeUnspsc190501.10212615': __('identifierSchemeCodeUnspsc190501.10212615'),
	'identifierSchemeCodeUnspsc190501.10212616': __('identifierSchemeCodeUnspsc190501.10212616'),
	'identifierSchemeCodeUnspsc190501.10212617': __('identifierSchemeCodeUnspsc190501.10212617'),
	'identifierSchemeCodeUnspsc190501.10212618': __('identifierSchemeCodeUnspsc190501.10212618'),
	'identifierSchemeCodeUnspsc190501.10212619': __('identifierSchemeCodeUnspsc190501.10212619'),
	'identifierSchemeCodeUnspsc190501.10212620': __('identifierSchemeCodeUnspsc190501.10212620'),
	'identifierSchemeCodeUnspsc190501.10212621': __('identifierSchemeCodeUnspsc190501.10212621'),
	'identifierSchemeCodeUnspsc190501.10212622': __('identifierSchemeCodeUnspsc190501.10212622'),
	'identifierSchemeCodeUnspsc190501.10212623': __('identifierSchemeCodeUnspsc190501.10212623'),
	'identifierSchemeCodeUnspsc190501.10212624': __('identifierSchemeCodeUnspsc190501.10212624'),
	'identifierSchemeCodeUnspsc190501.10212625': __('identifierSchemeCodeUnspsc190501.10212625'),
	'identifierSchemeCodeUnspsc190501.10212626': __('identifierSchemeCodeUnspsc190501.10212626'),
	'identifierSchemeCodeUnspsc190501.10212627': __('identifierSchemeCodeUnspsc190501.10212627'),
	'identifierSchemeCodeUnspsc190501.10212628': __('identifierSchemeCodeUnspsc190501.10212628'),
	'identifierSchemeCodeUnspsc190501.10212629': __('identifierSchemeCodeUnspsc190501.10212629'),
	'identifierSchemeCodeUnspsc190501.10212630': __('identifierSchemeCodeUnspsc190501.10212630'),
	'identifierSchemeCodeUnspsc190501.10212631': __('identifierSchemeCodeUnspsc190501.10212631'),
	'identifierSchemeCodeUnspsc190501.10212632': __('identifierSchemeCodeUnspsc190501.10212632'),
	'identifierSchemeCodeUnspsc190501.10212633': __('identifierSchemeCodeUnspsc190501.10212633'),
	'identifierSchemeCodeUnspsc190501.10212634': __('identifierSchemeCodeUnspsc190501.10212634'),
	'identifierSchemeCodeUnspsc190501.10212635': __('identifierSchemeCodeUnspsc190501.10212635'),
	'identifierSchemeCodeUnspsc190501.10212636': __('identifierSchemeCodeUnspsc190501.10212636'),
	'identifierSchemeCodeUnspsc190501.10212637': __('identifierSchemeCodeUnspsc190501.10212637'),
	'identifierSchemeCodeUnspsc190501.10212638': __('identifierSchemeCodeUnspsc190501.10212638'),
	'identifierSchemeCodeUnspsc190501.10212639': __('identifierSchemeCodeUnspsc190501.10212639'),
	'identifierSchemeCodeUnspsc190501.10212640': __('identifierSchemeCodeUnspsc190501.10212640'),
	'identifierSchemeCodeUnspsc190501.10212641': __('identifierSchemeCodeUnspsc190501.10212641'),
	'identifierSchemeCodeUnspsc190501.10212642': __('identifierSchemeCodeUnspsc190501.10212642'),
	'identifierSchemeCodeUnspsc190501.10212643': __('identifierSchemeCodeUnspsc190501.10212643'),
	'identifierSchemeCodeUnspsc190501.10212644': __('identifierSchemeCodeUnspsc190501.10212644'),
	'identifierSchemeCodeUnspsc190501.10212645': __('identifierSchemeCodeUnspsc190501.10212645'),
	'identifierSchemeCodeUnspsc190501.10212646': __('identifierSchemeCodeUnspsc190501.10212646'),
	'identifierSchemeCodeUnspsc190501.10212647': __('identifierSchemeCodeUnspsc190501.10212647'),
	'identifierSchemeCodeUnspsc190501.10212648': __('identifierSchemeCodeUnspsc190501.10212648'),
	'identifierSchemeCodeUnspsc190501.10212649': __('identifierSchemeCodeUnspsc190501.10212649'),
	'identifierSchemeCodeUnspsc190501.10212650': __('identifierSchemeCodeUnspsc190501.10212650'),
	'identifierSchemeCodeUnspsc190501.10212651': __('identifierSchemeCodeUnspsc190501.10212651'),
	'identifierSchemeCodeUnspsc190501.10212652': __('identifierSchemeCodeUnspsc190501.10212652'),
	'identifierSchemeCodeUnspsc190501.10212653': __('identifierSchemeCodeUnspsc190501.10212653'),
	'identifierSchemeCodeUnspsc190501.10212800': __('identifierSchemeCodeUnspsc190501.10212800'),
	'identifierSchemeCodeUnspsc190501.10212801': __('identifierSchemeCodeUnspsc190501.10212801'),
	'identifierSchemeCodeUnspsc190501.10212802': __('identifierSchemeCodeUnspsc190501.10212802'),
	'identifierSchemeCodeUnspsc190501.10212803': __('identifierSchemeCodeUnspsc190501.10212803'),
	'identifierSchemeCodeUnspsc190501.10212804': __('identifierSchemeCodeUnspsc190501.10212804'),
	'identifierSchemeCodeUnspsc190501.10212805': __('identifierSchemeCodeUnspsc190501.10212805'),
	'identifierSchemeCodeUnspsc190501.10212806': __('identifierSchemeCodeUnspsc190501.10212806'),
	'identifierSchemeCodeUnspsc190501.10212807': __('identifierSchemeCodeUnspsc190501.10212807'),
	'identifierSchemeCodeUnspsc190501.10212808': __('identifierSchemeCodeUnspsc190501.10212808'),
	'identifierSchemeCodeUnspsc190501.10212809': __('identifierSchemeCodeUnspsc190501.10212809'),
	'identifierSchemeCodeUnspsc190501.10212810': __('identifierSchemeCodeUnspsc190501.10212810'),
	'identifierSchemeCodeUnspsc190501.10212811': __('identifierSchemeCodeUnspsc190501.10212811'),
	'identifierSchemeCodeUnspsc190501.10212812': __('identifierSchemeCodeUnspsc190501.10212812'),
	'identifierSchemeCodeUnspsc190501.10212813': __('identifierSchemeCodeUnspsc190501.10212813'),
	'identifierSchemeCodeUnspsc190501.10212900': __('identifierSchemeCodeUnspsc190501.10212900'),
	'identifierSchemeCodeUnspsc190501.10212901': __('identifierSchemeCodeUnspsc190501.10212901'),
	'identifierSchemeCodeUnspsc190501.10212902': __('identifierSchemeCodeUnspsc190501.10212902'),
	'identifierSchemeCodeUnspsc190501.10212903': __('identifierSchemeCodeUnspsc190501.10212903'),
	'identifierSchemeCodeUnspsc190501.10212904': __('identifierSchemeCodeUnspsc190501.10212904'),
	'identifierSchemeCodeUnspsc190501.10212905': __('identifierSchemeCodeUnspsc190501.10212905'),
	'identifierSchemeCodeUnspsc190501.10212906': __('identifierSchemeCodeUnspsc190501.10212906'),
	'identifierSchemeCodeUnspsc190501.10213000': __('identifierSchemeCodeUnspsc190501.10213000'),
	'identifierSchemeCodeUnspsc190501.10213001': __('identifierSchemeCodeUnspsc190501.10213001'),
	'identifierSchemeCodeUnspsc190501.10213002': __('identifierSchemeCodeUnspsc190501.10213002'),
	'identifierSchemeCodeUnspsc190501.10213003': __('identifierSchemeCodeUnspsc190501.10213003'),
	'identifierSchemeCodeUnspsc190501.10213004': __('identifierSchemeCodeUnspsc190501.10213004'),
	'identifierSchemeCodeUnspsc190501.10213005': __('identifierSchemeCodeUnspsc190501.10213005'),
	'identifierSchemeCodeUnspsc190501.10213006': __('identifierSchemeCodeUnspsc190501.10213006'),
	'identifierSchemeCodeUnspsc190501.10213007': __('identifierSchemeCodeUnspsc190501.10213007'),
	'identifierSchemeCodeUnspsc190501.10213008': __('identifierSchemeCodeUnspsc190501.10213008'),
	'identifierSchemeCodeUnspsc190501.10213009': __('identifierSchemeCodeUnspsc190501.10213009'),
	'identifierSchemeCodeUnspsc190501.10213010': __('identifierSchemeCodeUnspsc190501.10213010'),
	'identifierSchemeCodeUnspsc190501.10213100': __('identifierSchemeCodeUnspsc190501.10213100'),
	'identifierSchemeCodeUnspsc190501.10213101': __('identifierSchemeCodeUnspsc190501.10213101'),
	'identifierSchemeCodeUnspsc190501.10213102': __('identifierSchemeCodeUnspsc190501.10213102'),
	'identifierSchemeCodeUnspsc190501.10213103': __('identifierSchemeCodeUnspsc190501.10213103'),
	'identifierSchemeCodeUnspsc190501.10213104': __('identifierSchemeCodeUnspsc190501.10213104'),
	'identifierSchemeCodeUnspsc190501.10213105': __('identifierSchemeCodeUnspsc190501.10213105'),
	'identifierSchemeCodeUnspsc190501.10213106': __('identifierSchemeCodeUnspsc190501.10213106'),
	'identifierSchemeCodeUnspsc190501.10213107': __('identifierSchemeCodeUnspsc190501.10213107'),
	'identifierSchemeCodeUnspsc190501.10213108': __('identifierSchemeCodeUnspsc190501.10213108'),
	'identifierSchemeCodeUnspsc190501.10213109': __('identifierSchemeCodeUnspsc190501.10213109'),
	'identifierSchemeCodeUnspsc190501.10213110': __('identifierSchemeCodeUnspsc190501.10213110'),
	'identifierSchemeCodeUnspsc190501.10213111': __('identifierSchemeCodeUnspsc190501.10213111'),
	'identifierSchemeCodeUnspsc190501.10213112': __('identifierSchemeCodeUnspsc190501.10213112'),
	'identifierSchemeCodeUnspsc190501.10213113': __('identifierSchemeCodeUnspsc190501.10213113'),
	'identifierSchemeCodeUnspsc190501.10213200': __('identifierSchemeCodeUnspsc190501.10213200'),
	'identifierSchemeCodeUnspsc190501.10213201': __('identifierSchemeCodeUnspsc190501.10213201'),
	'identifierSchemeCodeUnspsc190501.10213202': __('identifierSchemeCodeUnspsc190501.10213202'),
	'identifierSchemeCodeUnspsc190501.10213203': __('identifierSchemeCodeUnspsc190501.10213203'),
	'identifierSchemeCodeUnspsc190501.10213204': __('identifierSchemeCodeUnspsc190501.10213204'),
	'identifierSchemeCodeUnspsc190501.10213205': __('identifierSchemeCodeUnspsc190501.10213205'),
	'identifierSchemeCodeUnspsc190501.10213206': __('identifierSchemeCodeUnspsc190501.10213206'),
	'identifierSchemeCodeUnspsc190501.10213207': __('identifierSchemeCodeUnspsc190501.10213207'),
	'identifierSchemeCodeUnspsc190501.10213208': __('identifierSchemeCodeUnspsc190501.10213208'),
	'identifierSchemeCodeUnspsc190501.10213300': __('identifierSchemeCodeUnspsc190501.10213300'),
	'identifierSchemeCodeUnspsc190501.10213301': __('identifierSchemeCodeUnspsc190501.10213301'),
	'identifierSchemeCodeUnspsc190501.10213302': __('identifierSchemeCodeUnspsc190501.10213302'),
	'identifierSchemeCodeUnspsc190501.10213303': __('identifierSchemeCodeUnspsc190501.10213303'),
	'identifierSchemeCodeUnspsc190501.10213304': __('identifierSchemeCodeUnspsc190501.10213304'),
	'identifierSchemeCodeUnspsc190501.10213305': __('identifierSchemeCodeUnspsc190501.10213305'),
	'identifierSchemeCodeUnspsc190501.10213400': __('identifierSchemeCodeUnspsc190501.10213400'),
	'identifierSchemeCodeUnspsc190501.10213401': __('identifierSchemeCodeUnspsc190501.10213401'),
	'identifierSchemeCodeUnspsc190501.10213402': __('identifierSchemeCodeUnspsc190501.10213402'),
	'identifierSchemeCodeUnspsc190501.10213403': __('identifierSchemeCodeUnspsc190501.10213403'),
	'identifierSchemeCodeUnspsc190501.10213404': __('identifierSchemeCodeUnspsc190501.10213404'),
	'identifierSchemeCodeUnspsc190501.10213405': __('identifierSchemeCodeUnspsc190501.10213405'),
	'identifierSchemeCodeUnspsc190501.10213500': __('identifierSchemeCodeUnspsc190501.10213500'),
	'identifierSchemeCodeUnspsc190501.10213501': __('identifierSchemeCodeUnspsc190501.10213501'),
	'identifierSchemeCodeUnspsc190501.10213502': __('identifierSchemeCodeUnspsc190501.10213502'),
	'identifierSchemeCodeUnspsc190501.10213503': __('identifierSchemeCodeUnspsc190501.10213503'),
	'identifierSchemeCodeUnspsc190501.10213504': __('identifierSchemeCodeUnspsc190501.10213504'),
	'identifierSchemeCodeUnspsc190501.10213505': __('identifierSchemeCodeUnspsc190501.10213505'),
	'identifierSchemeCodeUnspsc190501.10213506': __('identifierSchemeCodeUnspsc190501.10213506'),
	'identifierSchemeCodeUnspsc190501.10213507': __('identifierSchemeCodeUnspsc190501.10213507'),
	'identifierSchemeCodeUnspsc190501.10213508': __('identifierSchemeCodeUnspsc190501.10213508'),
	'identifierSchemeCodeUnspsc190501.10213509': __('identifierSchemeCodeUnspsc190501.10213509'),
	'identifierSchemeCodeUnspsc190501.10213510': __('identifierSchemeCodeUnspsc190501.10213510'),
	'identifierSchemeCodeUnspsc190501.10213600': __('identifierSchemeCodeUnspsc190501.10213600'),
	'identifierSchemeCodeUnspsc190501.10213601': __('identifierSchemeCodeUnspsc190501.10213601'),
	'identifierSchemeCodeUnspsc190501.10213602': __('identifierSchemeCodeUnspsc190501.10213602'),
	'identifierSchemeCodeUnspsc190501.10213603': __('identifierSchemeCodeUnspsc190501.10213603'),
	'identifierSchemeCodeUnspsc190501.10213604': __('identifierSchemeCodeUnspsc190501.10213604'),
	'identifierSchemeCodeUnspsc190501.10213605': __('identifierSchemeCodeUnspsc190501.10213605'),
	'identifierSchemeCodeUnspsc190501.10213606': __('identifierSchemeCodeUnspsc190501.10213606'),
	'identifierSchemeCodeUnspsc190501.10213607': __('identifierSchemeCodeUnspsc190501.10213607'),
	'identifierSchemeCodeUnspsc190501.10213608': __('identifierSchemeCodeUnspsc190501.10213608'),
	'identifierSchemeCodeUnspsc190501.10213609': __('identifierSchemeCodeUnspsc190501.10213609'),
	'identifierSchemeCodeUnspsc190501.10213610': __('identifierSchemeCodeUnspsc190501.10213610'),
	'identifierSchemeCodeUnspsc190501.10213611': __('identifierSchemeCodeUnspsc190501.10213611'),
	'identifierSchemeCodeUnspsc190501.10213612': __('identifierSchemeCodeUnspsc190501.10213612'),
	'identifierSchemeCodeUnspsc190501.10213613': __('identifierSchemeCodeUnspsc190501.10213613'),
	'identifierSchemeCodeUnspsc190501.10213614': __('identifierSchemeCodeUnspsc190501.10213614'),
	'identifierSchemeCodeUnspsc190501.10213700': __('identifierSchemeCodeUnspsc190501.10213700'),
	'identifierSchemeCodeUnspsc190501.10213701': __('identifierSchemeCodeUnspsc190501.10213701'),
	'identifierSchemeCodeUnspsc190501.10213702': __('identifierSchemeCodeUnspsc190501.10213702'),
	'identifierSchemeCodeUnspsc190501.10213703': __('identifierSchemeCodeUnspsc190501.10213703'),
	'identifierSchemeCodeUnspsc190501.10213704': __('identifierSchemeCodeUnspsc190501.10213704'),
	'identifierSchemeCodeUnspsc190501.10213705': __('identifierSchemeCodeUnspsc190501.10213705'),
	'identifierSchemeCodeUnspsc190501.10213706': __('identifierSchemeCodeUnspsc190501.10213706'),
	'identifierSchemeCodeUnspsc190501.10213707': __('identifierSchemeCodeUnspsc190501.10213707'),
	'identifierSchemeCodeUnspsc190501.10213708': __('identifierSchemeCodeUnspsc190501.10213708'),
	'identifierSchemeCodeUnspsc190501.10213709': __('identifierSchemeCodeUnspsc190501.10213709'),
	'identifierSchemeCodeUnspsc190501.10213800': __('identifierSchemeCodeUnspsc190501.10213800'),
	'identifierSchemeCodeUnspsc190501.10213801': __('identifierSchemeCodeUnspsc190501.10213801'),
	'identifierSchemeCodeUnspsc190501.10213802': __('identifierSchemeCodeUnspsc190501.10213802'),
	'identifierSchemeCodeUnspsc190501.10213803': __('identifierSchemeCodeUnspsc190501.10213803'),
	'identifierSchemeCodeUnspsc190501.10213804': __('identifierSchemeCodeUnspsc190501.10213804'),
	'identifierSchemeCodeUnspsc190501.10213805': __('identifierSchemeCodeUnspsc190501.10213805'),
	'identifierSchemeCodeUnspsc190501.10213806': __('identifierSchemeCodeUnspsc190501.10213806'),
	'identifierSchemeCodeUnspsc190501.10213807': __('identifierSchemeCodeUnspsc190501.10213807'),
	'identifierSchemeCodeUnspsc190501.10213808': __('identifierSchemeCodeUnspsc190501.10213808'),
	'identifierSchemeCodeUnspsc190501.10213900': __('identifierSchemeCodeUnspsc190501.10213900'),
	'identifierSchemeCodeUnspsc190501.10213901': __('identifierSchemeCodeUnspsc190501.10213901'),
	'identifierSchemeCodeUnspsc190501.10213902': __('identifierSchemeCodeUnspsc190501.10213902'),
	'identifierSchemeCodeUnspsc190501.10213903': __('identifierSchemeCodeUnspsc190501.10213903'),
	'identifierSchemeCodeUnspsc190501.10213904': __('identifierSchemeCodeUnspsc190501.10213904'),
	'identifierSchemeCodeUnspsc190501.10213905': __('identifierSchemeCodeUnspsc190501.10213905'),
	'identifierSchemeCodeUnspsc190501.10213906': __('identifierSchemeCodeUnspsc190501.10213906'),
	'identifierSchemeCodeUnspsc190501.10213907': __('identifierSchemeCodeUnspsc190501.10213907'),
	'identifierSchemeCodeUnspsc190501.10213908': __('identifierSchemeCodeUnspsc190501.10213908'),
	'identifierSchemeCodeUnspsc190501.10213909': __('identifierSchemeCodeUnspsc190501.10213909'),
	'identifierSchemeCodeUnspsc190501.10213910': __('identifierSchemeCodeUnspsc190501.10213910'),
	'identifierSchemeCodeUnspsc190501.10213911': __('identifierSchemeCodeUnspsc190501.10213911'),
	'identifierSchemeCodeUnspsc190501.10213912': __('identifierSchemeCodeUnspsc190501.10213912'),
	'identifierSchemeCodeUnspsc190501.10213913': __('identifierSchemeCodeUnspsc190501.10213913'),
	'identifierSchemeCodeUnspsc190501.10213914': __('identifierSchemeCodeUnspsc190501.10213914'),
	'identifierSchemeCodeUnspsc190501.10213915': __('identifierSchemeCodeUnspsc190501.10213915'),
	'identifierSchemeCodeUnspsc190501.10213916': __('identifierSchemeCodeUnspsc190501.10213916'),
	'identifierSchemeCodeUnspsc190501.10213917': __('identifierSchemeCodeUnspsc190501.10213917'),
	'identifierSchemeCodeUnspsc190501.10213918': __('identifierSchemeCodeUnspsc190501.10213918'),
	'identifierSchemeCodeUnspsc190501.10213919': __('identifierSchemeCodeUnspsc190501.10213919'),
	'identifierSchemeCodeUnspsc190501.10213920': __('identifierSchemeCodeUnspsc190501.10213920'),
	'identifierSchemeCodeUnspsc190501.10213921': __('identifierSchemeCodeUnspsc190501.10213921'),
	'identifierSchemeCodeUnspsc190501.10213922': __('identifierSchemeCodeUnspsc190501.10213922'),
	'identifierSchemeCodeUnspsc190501.10213923': __('identifierSchemeCodeUnspsc190501.10213923'),
	'identifierSchemeCodeUnspsc190501.10213924': __('identifierSchemeCodeUnspsc190501.10213924'),
	'identifierSchemeCodeUnspsc190501.10213925': __('identifierSchemeCodeUnspsc190501.10213925'),
	'identifierSchemeCodeUnspsc190501.10213926': __('identifierSchemeCodeUnspsc190501.10213926'),
	'identifierSchemeCodeUnspsc190501.10213927': __('identifierSchemeCodeUnspsc190501.10213927'),
	'identifierSchemeCodeUnspsc190501.10213928': __('identifierSchemeCodeUnspsc190501.10213928'),
	'identifierSchemeCodeUnspsc190501.10213929': __('identifierSchemeCodeUnspsc190501.10213929'),
	'identifierSchemeCodeUnspsc190501.10214000': __('identifierSchemeCodeUnspsc190501.10214000'),
	'identifierSchemeCodeUnspsc190501.10214001': __('identifierSchemeCodeUnspsc190501.10214001'),
	'identifierSchemeCodeUnspsc190501.10214002': __('identifierSchemeCodeUnspsc190501.10214002'),
	'identifierSchemeCodeUnspsc190501.10214003': __('identifierSchemeCodeUnspsc190501.10214003'),
	'identifierSchemeCodeUnspsc190501.10214004': __('identifierSchemeCodeUnspsc190501.10214004'),
	'identifierSchemeCodeUnspsc190501.10214005': __('identifierSchemeCodeUnspsc190501.10214005'),
	'identifierSchemeCodeUnspsc190501.10214006': __('identifierSchemeCodeUnspsc190501.10214006'),
	'identifierSchemeCodeUnspsc190501.10214100': __('identifierSchemeCodeUnspsc190501.10214100'),
	'identifierSchemeCodeUnspsc190501.10214101': __('identifierSchemeCodeUnspsc190501.10214101'),
	'identifierSchemeCodeUnspsc190501.10214102': __('identifierSchemeCodeUnspsc190501.10214102'),
	'identifierSchemeCodeUnspsc190501.10214103': __('identifierSchemeCodeUnspsc190501.10214103'),
	'identifierSchemeCodeUnspsc190501.10214104': __('identifierSchemeCodeUnspsc190501.10214104'),
	'identifierSchemeCodeUnspsc190501.10214105': __('identifierSchemeCodeUnspsc190501.10214105'),
	'identifierSchemeCodeUnspsc190501.10214106': __('identifierSchemeCodeUnspsc190501.10214106'),
	'identifierSchemeCodeUnspsc190501.10214107': __('identifierSchemeCodeUnspsc190501.10214107'),
	'identifierSchemeCodeUnspsc190501.10214108': __('identifierSchemeCodeUnspsc190501.10214108'),
	'identifierSchemeCodeUnspsc190501.10214109': __('identifierSchemeCodeUnspsc190501.10214109'),
	'identifierSchemeCodeUnspsc190501.10214110': __('identifierSchemeCodeUnspsc190501.10214110'),
	'identifierSchemeCodeUnspsc190501.10214111': __('identifierSchemeCodeUnspsc190501.10214111'),
	'identifierSchemeCodeUnspsc190501.10214112': __('identifierSchemeCodeUnspsc190501.10214112'),
	'identifierSchemeCodeUnspsc190501.10214113': __('identifierSchemeCodeUnspsc190501.10214113'),
	'identifierSchemeCodeUnspsc190501.10214114': __('identifierSchemeCodeUnspsc190501.10214114'),
	'identifierSchemeCodeUnspsc190501.10214200': __('identifierSchemeCodeUnspsc190501.10214200'),
	'identifierSchemeCodeUnspsc190501.10214201': __('identifierSchemeCodeUnspsc190501.10214201'),
	'identifierSchemeCodeUnspsc190501.10214202': __('identifierSchemeCodeUnspsc190501.10214202'),
	'identifierSchemeCodeUnspsc190501.10214203': __('identifierSchemeCodeUnspsc190501.10214203'),
	'identifierSchemeCodeUnspsc190501.10214204': __('identifierSchemeCodeUnspsc190501.10214204'),
	'identifierSchemeCodeUnspsc190501.10214205': __('identifierSchemeCodeUnspsc190501.10214205'),
	'identifierSchemeCodeUnspsc190501.10214206': __('identifierSchemeCodeUnspsc190501.10214206'),
	'identifierSchemeCodeUnspsc190501.10214300': __('identifierSchemeCodeUnspsc190501.10214300'),
	'identifierSchemeCodeUnspsc190501.10214301': __('identifierSchemeCodeUnspsc190501.10214301'),
	'identifierSchemeCodeUnspsc190501.10214302': __('identifierSchemeCodeUnspsc190501.10214302'),
	'identifierSchemeCodeUnspsc190501.10214303': __('identifierSchemeCodeUnspsc190501.10214303'),
	'identifierSchemeCodeUnspsc190501.10214304': __('identifierSchemeCodeUnspsc190501.10214304'),
	'identifierSchemeCodeUnspsc190501.10214305': __('identifierSchemeCodeUnspsc190501.10214305'),
	'identifierSchemeCodeUnspsc190501.10214400': __('identifierSchemeCodeUnspsc190501.10214400'),
	'identifierSchemeCodeUnspsc190501.10214401': __('identifierSchemeCodeUnspsc190501.10214401'),
	'identifierSchemeCodeUnspsc190501.10214402': __('identifierSchemeCodeUnspsc190501.10214402'),
	'identifierSchemeCodeUnspsc190501.10214403': __('identifierSchemeCodeUnspsc190501.10214403'),
	'identifierSchemeCodeUnspsc190501.10214404': __('identifierSchemeCodeUnspsc190501.10214404'),
	'identifierSchemeCodeUnspsc190501.10214405': __('identifierSchemeCodeUnspsc190501.10214405'),
	'identifierSchemeCodeUnspsc190501.10214406': __('identifierSchemeCodeUnspsc190501.10214406'),
	'identifierSchemeCodeUnspsc190501.10214500': __('identifierSchemeCodeUnspsc190501.10214500'),
	'identifierSchemeCodeUnspsc190501.10214501': __('identifierSchemeCodeUnspsc190501.10214501'),
	'identifierSchemeCodeUnspsc190501.10214502': __('identifierSchemeCodeUnspsc190501.10214502'),
	'identifierSchemeCodeUnspsc190501.10214503': __('identifierSchemeCodeUnspsc190501.10214503'),
	'identifierSchemeCodeUnspsc190501.10214504': __('identifierSchemeCodeUnspsc190501.10214504'),
	'identifierSchemeCodeUnspsc190501.10214505': __('identifierSchemeCodeUnspsc190501.10214505'),
	'identifierSchemeCodeUnspsc190501.10214506': __('identifierSchemeCodeUnspsc190501.10214506'),
	'identifierSchemeCodeUnspsc190501.10214507': __('identifierSchemeCodeUnspsc190501.10214507'),
	'identifierSchemeCodeUnspsc190501.10214600': __('identifierSchemeCodeUnspsc190501.10214600'),
	'identifierSchemeCodeUnspsc190501.10214601': __('identifierSchemeCodeUnspsc190501.10214601'),
	'identifierSchemeCodeUnspsc190501.10214602': __('identifierSchemeCodeUnspsc190501.10214602'),
	'identifierSchemeCodeUnspsc190501.10214603': __('identifierSchemeCodeUnspsc190501.10214603'),
	'identifierSchemeCodeUnspsc190501.10214604': __('identifierSchemeCodeUnspsc190501.10214604'),
	'identifierSchemeCodeUnspsc190501.10214605': __('identifierSchemeCodeUnspsc190501.10214605'),
	'identifierSchemeCodeUnspsc190501.10214606': __('identifierSchemeCodeUnspsc190501.10214606'),
	'identifierSchemeCodeUnspsc190501.10214607': __('identifierSchemeCodeUnspsc190501.10214607'),
	'identifierSchemeCodeUnspsc190501.10214608': __('identifierSchemeCodeUnspsc190501.10214608'),
	'identifierSchemeCodeUnspsc190501.10214609': __('identifierSchemeCodeUnspsc190501.10214609'),
	'identifierSchemeCodeUnspsc190501.10214610': __('identifierSchemeCodeUnspsc190501.10214610'),
	'identifierSchemeCodeUnspsc190501.10214611': __('identifierSchemeCodeUnspsc190501.10214611'),
	'identifierSchemeCodeUnspsc190501.10214612': __('identifierSchemeCodeUnspsc190501.10214612'),
	'identifierSchemeCodeUnspsc190501.10214613': __('identifierSchemeCodeUnspsc190501.10214613'),
	'identifierSchemeCodeUnspsc190501.10214614': __('identifierSchemeCodeUnspsc190501.10214614'),
	'identifierSchemeCodeUnspsc190501.10214615': __('identifierSchemeCodeUnspsc190501.10214615'),
	'identifierSchemeCodeUnspsc190501.10214616': __('identifierSchemeCodeUnspsc190501.10214616'),
	'identifierSchemeCodeUnspsc190501.10214617': __('identifierSchemeCodeUnspsc190501.10214617'),
	'identifierSchemeCodeUnspsc190501.10214618': __('identifierSchemeCodeUnspsc190501.10214618'),
	'identifierSchemeCodeUnspsc190501.10214619': __('identifierSchemeCodeUnspsc190501.10214619'),
	'identifierSchemeCodeUnspsc190501.10214700': __('identifierSchemeCodeUnspsc190501.10214700'),
	'identifierSchemeCodeUnspsc190501.10214701': __('identifierSchemeCodeUnspsc190501.10214701'),
	'identifierSchemeCodeUnspsc190501.10214702': __('identifierSchemeCodeUnspsc190501.10214702'),
	'identifierSchemeCodeUnspsc190501.10214703': __('identifierSchemeCodeUnspsc190501.10214703'),
	'identifierSchemeCodeUnspsc190501.10214704': __('identifierSchemeCodeUnspsc190501.10214704'),
	'identifierSchemeCodeUnspsc190501.10214705': __('identifierSchemeCodeUnspsc190501.10214705'),
	'identifierSchemeCodeUnspsc190501.10214706': __('identifierSchemeCodeUnspsc190501.10214706'),
	'identifierSchemeCodeUnspsc190501.10214707': __('identifierSchemeCodeUnspsc190501.10214707'),
	'identifierSchemeCodeUnspsc190501.10214708': __('identifierSchemeCodeUnspsc190501.10214708'),
	'identifierSchemeCodeUnspsc190501.10214709': __('identifierSchemeCodeUnspsc190501.10214709'),
	'identifierSchemeCodeUnspsc190501.10214710': __('identifierSchemeCodeUnspsc190501.10214710'),
	'identifierSchemeCodeUnspsc190501.10214711': __('identifierSchemeCodeUnspsc190501.10214711'),
	'identifierSchemeCodeUnspsc190501.10214712': __('identifierSchemeCodeUnspsc190501.10214712'),
	'identifierSchemeCodeUnspsc190501.10214800': __('identifierSchemeCodeUnspsc190501.10214800'),
	'identifierSchemeCodeUnspsc190501.10214801': __('identifierSchemeCodeUnspsc190501.10214801'),
	'identifierSchemeCodeUnspsc190501.10214802': __('identifierSchemeCodeUnspsc190501.10214802'),
	'identifierSchemeCodeUnspsc190501.10214803': __('identifierSchemeCodeUnspsc190501.10214803'),
	'identifierSchemeCodeUnspsc190501.10214804': __('identifierSchemeCodeUnspsc190501.10214804'),
	'identifierSchemeCodeUnspsc190501.10214805': __('identifierSchemeCodeUnspsc190501.10214805'),
	'identifierSchemeCodeUnspsc190501.10214806': __('identifierSchemeCodeUnspsc190501.10214806'),
	'identifierSchemeCodeUnspsc190501.10214807': __('identifierSchemeCodeUnspsc190501.10214807'),
	'identifierSchemeCodeUnspsc190501.10214808': __('identifierSchemeCodeUnspsc190501.10214808'),
	'identifierSchemeCodeUnspsc190501.10214809': __('identifierSchemeCodeUnspsc190501.10214809'),
	'identifierSchemeCodeUnspsc190501.10214810': __('identifierSchemeCodeUnspsc190501.10214810'),
	'identifierSchemeCodeUnspsc190501.10214811': __('identifierSchemeCodeUnspsc190501.10214811'),
	'identifierSchemeCodeUnspsc190501.10214812': __('identifierSchemeCodeUnspsc190501.10214812'),
	'identifierSchemeCodeUnspsc190501.10214813': __('identifierSchemeCodeUnspsc190501.10214813'),
	'identifierSchemeCodeUnspsc190501.10214814': __('identifierSchemeCodeUnspsc190501.10214814'),
	'identifierSchemeCodeUnspsc190501.10214815': __('identifierSchemeCodeUnspsc190501.10214815'),
	'identifierSchemeCodeUnspsc190501.10214816': __('identifierSchemeCodeUnspsc190501.10214816'),
	'identifierSchemeCodeUnspsc190501.10214817': __('identifierSchemeCodeUnspsc190501.10214817'),
	'identifierSchemeCodeUnspsc190501.10214818': __('identifierSchemeCodeUnspsc190501.10214818'),
	'identifierSchemeCodeUnspsc190501.10214819': __('identifierSchemeCodeUnspsc190501.10214819'),
	'identifierSchemeCodeUnspsc190501.10214820': __('identifierSchemeCodeUnspsc190501.10214820'),
	'identifierSchemeCodeUnspsc190501.10214821': __('identifierSchemeCodeUnspsc190501.10214821'),
	'identifierSchemeCodeUnspsc190501.10214822': __('identifierSchemeCodeUnspsc190501.10214822'),
	'identifierSchemeCodeUnspsc190501.10214823': __('identifierSchemeCodeUnspsc190501.10214823'),
	'identifierSchemeCodeUnspsc190501.10214824': __('identifierSchemeCodeUnspsc190501.10214824'),
	'identifierSchemeCodeUnspsc190501.10214825': __('identifierSchemeCodeUnspsc190501.10214825'),
	'identifierSchemeCodeUnspsc190501.10214826': __('identifierSchemeCodeUnspsc190501.10214826'),
	'identifierSchemeCodeUnspsc190501.10214827': __('identifierSchemeCodeUnspsc190501.10214827'),
	'identifierSchemeCodeUnspsc190501.10214828': __('identifierSchemeCodeUnspsc190501.10214828'),
	'identifierSchemeCodeUnspsc190501.10214829': __('identifierSchemeCodeUnspsc190501.10214829'),
	'identifierSchemeCodeUnspsc190501.10214900': __('identifierSchemeCodeUnspsc190501.10214900'),
	'identifierSchemeCodeUnspsc190501.10214901': __('identifierSchemeCodeUnspsc190501.10214901'),
	'identifierSchemeCodeUnspsc190501.10214902': __('identifierSchemeCodeUnspsc190501.10214902'),
	'identifierSchemeCodeUnspsc190501.10214903': __('identifierSchemeCodeUnspsc190501.10214903'),
	'identifierSchemeCodeUnspsc190501.10214904': __('identifierSchemeCodeUnspsc190501.10214904'),
	'identifierSchemeCodeUnspsc190501.10214905': __('identifierSchemeCodeUnspsc190501.10214905'),
	'identifierSchemeCodeUnspsc190501.10214906': __('identifierSchemeCodeUnspsc190501.10214906'),
	'identifierSchemeCodeUnspsc190501.10214907': __('identifierSchemeCodeUnspsc190501.10214907'),
	'identifierSchemeCodeUnspsc190501.10214908': __('identifierSchemeCodeUnspsc190501.10214908'),
	'identifierSchemeCodeUnspsc190501.10214909': __('identifierSchemeCodeUnspsc190501.10214909'),
	'identifierSchemeCodeUnspsc190501.10214910': __('identifierSchemeCodeUnspsc190501.10214910'),
	'identifierSchemeCodeUnspsc190501.10214911': __('identifierSchemeCodeUnspsc190501.10214911'),
	'identifierSchemeCodeUnspsc190501.10214912': __('identifierSchemeCodeUnspsc190501.10214912'),
	'identifierSchemeCodeUnspsc190501.10214913': __('identifierSchemeCodeUnspsc190501.10214913'),
	'identifierSchemeCodeUnspsc190501.10214914': __('identifierSchemeCodeUnspsc190501.10214914'),
	'identifierSchemeCodeUnspsc190501.10214915': __('identifierSchemeCodeUnspsc190501.10214915'),
	'identifierSchemeCodeUnspsc190501.10214916': __('identifierSchemeCodeUnspsc190501.10214916'),
	'identifierSchemeCodeUnspsc190501.10214917': __('identifierSchemeCodeUnspsc190501.10214917'),
	'identifierSchemeCodeUnspsc190501.10214918': __('identifierSchemeCodeUnspsc190501.10214918'),
	'identifierSchemeCodeUnspsc190501.10214919': __('identifierSchemeCodeUnspsc190501.10214919'),
	'identifierSchemeCodeUnspsc190501.10215000': __('identifierSchemeCodeUnspsc190501.10215000'),
	'identifierSchemeCodeUnspsc190501.10215001': __('identifierSchemeCodeUnspsc190501.10215001'),
	'identifierSchemeCodeUnspsc190501.10215002': __('identifierSchemeCodeUnspsc190501.10215002'),
	'identifierSchemeCodeUnspsc190501.10215003': __('identifierSchemeCodeUnspsc190501.10215003'),
	'identifierSchemeCodeUnspsc190501.10215004': __('identifierSchemeCodeUnspsc190501.10215004'),
	'identifierSchemeCodeUnspsc190501.10215005': __('identifierSchemeCodeUnspsc190501.10215005'),
	'identifierSchemeCodeUnspsc190501.10215006': __('identifierSchemeCodeUnspsc190501.10215006'),
	'identifierSchemeCodeUnspsc190501.10215007': __('identifierSchemeCodeUnspsc190501.10215007'),
	'identifierSchemeCodeUnspsc190501.10215100': __('identifierSchemeCodeUnspsc190501.10215100'),
	'identifierSchemeCodeUnspsc190501.10215101': __('identifierSchemeCodeUnspsc190501.10215101'),
	'identifierSchemeCodeUnspsc190501.10215102': __('identifierSchemeCodeUnspsc190501.10215102'),
	'identifierSchemeCodeUnspsc190501.10215103': __('identifierSchemeCodeUnspsc190501.10215103'),
	'identifierSchemeCodeUnspsc190501.10215104': __('identifierSchemeCodeUnspsc190501.10215104'),
	'identifierSchemeCodeUnspsc190501.10215105': __('identifierSchemeCodeUnspsc190501.10215105'),
	'identifierSchemeCodeUnspsc190501.10215106': __('identifierSchemeCodeUnspsc190501.10215106'),
	'identifierSchemeCodeUnspsc190501.10215200': __('identifierSchemeCodeUnspsc190501.10215200'),
	'identifierSchemeCodeUnspsc190501.10215201': __('identifierSchemeCodeUnspsc190501.10215201'),
	'identifierSchemeCodeUnspsc190501.10215202': __('identifierSchemeCodeUnspsc190501.10215202'),
	'identifierSchemeCodeUnspsc190501.10215203': __('identifierSchemeCodeUnspsc190501.10215203'),
	'identifierSchemeCodeUnspsc190501.10215204': __('identifierSchemeCodeUnspsc190501.10215204'),
	'identifierSchemeCodeUnspsc190501.10215205': __('identifierSchemeCodeUnspsc190501.10215205'),
	'identifierSchemeCodeUnspsc190501.10215206': __('identifierSchemeCodeUnspsc190501.10215206'),
	'identifierSchemeCodeUnspsc190501.10215300': __('identifierSchemeCodeUnspsc190501.10215300'),
	'identifierSchemeCodeUnspsc190501.10215301': __('identifierSchemeCodeUnspsc190501.10215301'),
	'identifierSchemeCodeUnspsc190501.10215302': __('identifierSchemeCodeUnspsc190501.10215302'),
	'identifierSchemeCodeUnspsc190501.10215303': __('identifierSchemeCodeUnspsc190501.10215303'),
	'identifierSchemeCodeUnspsc190501.10215304': __('identifierSchemeCodeUnspsc190501.10215304'),
	'identifierSchemeCodeUnspsc190501.10215305': __('identifierSchemeCodeUnspsc190501.10215305'),
	'identifierSchemeCodeUnspsc190501.10215306': __('identifierSchemeCodeUnspsc190501.10215306'),
	'identifierSchemeCodeUnspsc190501.10215400': __('identifierSchemeCodeUnspsc190501.10215400'),
	'identifierSchemeCodeUnspsc190501.10215401': __('identifierSchemeCodeUnspsc190501.10215401'),
	'identifierSchemeCodeUnspsc190501.10215402': __('identifierSchemeCodeUnspsc190501.10215402'),
	'identifierSchemeCodeUnspsc190501.10215403': __('identifierSchemeCodeUnspsc190501.10215403'),
	'identifierSchemeCodeUnspsc190501.10215404': __('identifierSchemeCodeUnspsc190501.10215404'),
	'identifierSchemeCodeUnspsc190501.10215405': __('identifierSchemeCodeUnspsc190501.10215405'),
	'identifierSchemeCodeUnspsc190501.10215406': __('identifierSchemeCodeUnspsc190501.10215406'),
	'identifierSchemeCodeUnspsc190501.10215407': __('identifierSchemeCodeUnspsc190501.10215407'),
	'identifierSchemeCodeUnspsc190501.10215408': __('identifierSchemeCodeUnspsc190501.10215408'),
	'identifierSchemeCodeUnspsc190501.10215409': __('identifierSchemeCodeUnspsc190501.10215409'),
	'identifierSchemeCodeUnspsc190501.10215410': __('identifierSchemeCodeUnspsc190501.10215410'),
	'identifierSchemeCodeUnspsc190501.10215411': __('identifierSchemeCodeUnspsc190501.10215411'),
	'identifierSchemeCodeUnspsc190501.10215412': __('identifierSchemeCodeUnspsc190501.10215412'),
	'identifierSchemeCodeUnspsc190501.10215413': __('identifierSchemeCodeUnspsc190501.10215413'),
	'identifierSchemeCodeUnspsc190501.10215414': __('identifierSchemeCodeUnspsc190501.10215414'),
	'identifierSchemeCodeUnspsc190501.10215415': __('identifierSchemeCodeUnspsc190501.10215415'),
	'identifierSchemeCodeUnspsc190501.10215416': __('identifierSchemeCodeUnspsc190501.10215416'),
	'identifierSchemeCodeUnspsc190501.10215417': __('identifierSchemeCodeUnspsc190501.10215417'),
	'identifierSchemeCodeUnspsc190501.10215418': __('identifierSchemeCodeUnspsc190501.10215418'),
	'identifierSchemeCodeUnspsc190501.10215419': __('identifierSchemeCodeUnspsc190501.10215419'),
	'identifierSchemeCodeUnspsc190501.10215420': __('identifierSchemeCodeUnspsc190501.10215420'),
	'identifierSchemeCodeUnspsc190501.10215421': __('identifierSchemeCodeUnspsc190501.10215421'),
	'identifierSchemeCodeUnspsc190501.10215422': __('identifierSchemeCodeUnspsc190501.10215422'),
	'identifierSchemeCodeUnspsc190501.10215423': __('identifierSchemeCodeUnspsc190501.10215423'),
	'identifierSchemeCodeUnspsc190501.10215424': __('identifierSchemeCodeUnspsc190501.10215424'),
	'identifierSchemeCodeUnspsc190501.10215425': __('identifierSchemeCodeUnspsc190501.10215425'),
	'identifierSchemeCodeUnspsc190501.10215426': __('identifierSchemeCodeUnspsc190501.10215426'),
	'identifierSchemeCodeUnspsc190501.10215427': __('identifierSchemeCodeUnspsc190501.10215427'),
	'identifierSchemeCodeUnspsc190501.10215428': __('identifierSchemeCodeUnspsc190501.10215428'),
	'identifierSchemeCodeUnspsc190501.10215429': __('identifierSchemeCodeUnspsc190501.10215429'),
	'identifierSchemeCodeUnspsc190501.10215430': __('identifierSchemeCodeUnspsc190501.10215430'),
	'identifierSchemeCodeUnspsc190501.10215431': __('identifierSchemeCodeUnspsc190501.10215431'),
	'identifierSchemeCodeUnspsc190501.10215432': __('identifierSchemeCodeUnspsc190501.10215432'),
	'identifierSchemeCodeUnspsc190501.10215433': __('identifierSchemeCodeUnspsc190501.10215433'),
	'identifierSchemeCodeUnspsc190501.10215434': __('identifierSchemeCodeUnspsc190501.10215434'),
	'identifierSchemeCodeUnspsc190501.10215435': __('identifierSchemeCodeUnspsc190501.10215435'),
	'identifierSchemeCodeUnspsc190501.10215436': __('identifierSchemeCodeUnspsc190501.10215436'),
	'identifierSchemeCodeUnspsc190501.10215437': __('identifierSchemeCodeUnspsc190501.10215437'),
	'identifierSchemeCodeUnspsc190501.10215438': __('identifierSchemeCodeUnspsc190501.10215438'),
	'identifierSchemeCodeUnspsc190501.10215439': __('identifierSchemeCodeUnspsc190501.10215439'),
	'identifierSchemeCodeUnspsc190501.10215440': __('identifierSchemeCodeUnspsc190501.10215440'),
	'identifierSchemeCodeUnspsc190501.10215441': __('identifierSchemeCodeUnspsc190501.10215441'),
	'identifierSchemeCodeUnspsc190501.10215442': __('identifierSchemeCodeUnspsc190501.10215442'),
	'identifierSchemeCodeUnspsc190501.10215443': __('identifierSchemeCodeUnspsc190501.10215443'),
	'identifierSchemeCodeUnspsc190501.10215444': __('identifierSchemeCodeUnspsc190501.10215444'),
	'identifierSchemeCodeUnspsc190501.10215445': __('identifierSchemeCodeUnspsc190501.10215445'),
	'identifierSchemeCodeUnspsc190501.10215446': __('identifierSchemeCodeUnspsc190501.10215446'),
	'identifierSchemeCodeUnspsc190501.10215447': __('identifierSchemeCodeUnspsc190501.10215447'),
	'identifierSchemeCodeUnspsc190501.10215448': __('identifierSchemeCodeUnspsc190501.10215448'),
	'identifierSchemeCodeUnspsc190501.10215449': __('identifierSchemeCodeUnspsc190501.10215449'),
	'identifierSchemeCodeUnspsc190501.10215450': __('identifierSchemeCodeUnspsc190501.10215450'),
	'identifierSchemeCodeUnspsc190501.10215451': __('identifierSchemeCodeUnspsc190501.10215451'),
	'identifierSchemeCodeUnspsc190501.10215452': __('identifierSchemeCodeUnspsc190501.10215452'),
	'identifierSchemeCodeUnspsc190501.10215453': __('identifierSchemeCodeUnspsc190501.10215453'),
	'identifierSchemeCodeUnspsc190501.10215454': __('identifierSchemeCodeUnspsc190501.10215454'),
	'identifierSchemeCodeUnspsc190501.10215455': __('identifierSchemeCodeUnspsc190501.10215455'),
	'identifierSchemeCodeUnspsc190501.10215456': __('identifierSchemeCodeUnspsc190501.10215456'),
	'identifierSchemeCodeUnspsc190501.10215457': __('identifierSchemeCodeUnspsc190501.10215457'),
	'identifierSchemeCodeUnspsc190501.10215458': __('identifierSchemeCodeUnspsc190501.10215458'),
	'identifierSchemeCodeUnspsc190501.10215459': __('identifierSchemeCodeUnspsc190501.10215459'),
	'identifierSchemeCodeUnspsc190501.10215460': __('identifierSchemeCodeUnspsc190501.10215460'),
	'identifierSchemeCodeUnspsc190501.10215461': __('identifierSchemeCodeUnspsc190501.10215461'),
	'identifierSchemeCodeUnspsc190501.10215462': __('identifierSchemeCodeUnspsc190501.10215462'),
	'identifierSchemeCodeUnspsc190501.10215463': __('identifierSchemeCodeUnspsc190501.10215463'),
	'identifierSchemeCodeUnspsc190501.10215464': __('identifierSchemeCodeUnspsc190501.10215464'),
	'identifierSchemeCodeUnspsc190501.10215465': __('identifierSchemeCodeUnspsc190501.10215465'),
	'identifierSchemeCodeUnspsc190501.10215466': __('identifierSchemeCodeUnspsc190501.10215466'),
	'identifierSchemeCodeUnspsc190501.10215467': __('identifierSchemeCodeUnspsc190501.10215467'),
	'identifierSchemeCodeUnspsc190501.10215468': __('identifierSchemeCodeUnspsc190501.10215468'),
	'identifierSchemeCodeUnspsc190501.10215469': __('identifierSchemeCodeUnspsc190501.10215469'),
	'identifierSchemeCodeUnspsc190501.10215470': __('identifierSchemeCodeUnspsc190501.10215470'),
	'identifierSchemeCodeUnspsc190501.10215471': __('identifierSchemeCodeUnspsc190501.10215471'),
	'identifierSchemeCodeUnspsc190501.10215472': __('identifierSchemeCodeUnspsc190501.10215472'),
	'identifierSchemeCodeUnspsc190501.10215473': __('identifierSchemeCodeUnspsc190501.10215473'),
	'identifierSchemeCodeUnspsc190501.10215474': __('identifierSchemeCodeUnspsc190501.10215474'),
	'identifierSchemeCodeUnspsc190501.10215475': __('identifierSchemeCodeUnspsc190501.10215475'),
	'identifierSchemeCodeUnspsc190501.10215476': __('identifierSchemeCodeUnspsc190501.10215476'),
	'identifierSchemeCodeUnspsc190501.10215477': __('identifierSchemeCodeUnspsc190501.10215477'),
	'identifierSchemeCodeUnspsc190501.10215478': __('identifierSchemeCodeUnspsc190501.10215478'),
	'identifierSchemeCodeUnspsc190501.10215479': __('identifierSchemeCodeUnspsc190501.10215479'),
	'identifierSchemeCodeUnspsc190501.10215480': __('identifierSchemeCodeUnspsc190501.10215480'),
	'identifierSchemeCodeUnspsc190501.10215481': __('identifierSchemeCodeUnspsc190501.10215481'),
	'identifierSchemeCodeUnspsc190501.10215482': __('identifierSchemeCodeUnspsc190501.10215482'),
	'identifierSchemeCodeUnspsc190501.10215483': __('identifierSchemeCodeUnspsc190501.10215483'),
	'identifierSchemeCodeUnspsc190501.10215484': __('identifierSchemeCodeUnspsc190501.10215484'),
	'identifierSchemeCodeUnspsc190501.10215500': __('identifierSchemeCodeUnspsc190501.10215500'),
	'identifierSchemeCodeUnspsc190501.10215501': __('identifierSchemeCodeUnspsc190501.10215501'),
	'identifierSchemeCodeUnspsc190501.10215502': __('identifierSchemeCodeUnspsc190501.10215502'),
	'identifierSchemeCodeUnspsc190501.10215503': __('identifierSchemeCodeUnspsc190501.10215503'),
	'identifierSchemeCodeUnspsc190501.10215504': __('identifierSchemeCodeUnspsc190501.10215504'),
	'identifierSchemeCodeUnspsc190501.10215505': __('identifierSchemeCodeUnspsc190501.10215505'),
	'identifierSchemeCodeUnspsc190501.10215506': __('identifierSchemeCodeUnspsc190501.10215506'),
	'identifierSchemeCodeUnspsc190501.10215600': __('identifierSchemeCodeUnspsc190501.10215600'),
	'identifierSchemeCodeUnspsc190501.10215601': __('identifierSchemeCodeUnspsc190501.10215601'),
	'identifierSchemeCodeUnspsc190501.10215602': __('identifierSchemeCodeUnspsc190501.10215602'),
	'identifierSchemeCodeUnspsc190501.10215603': __('identifierSchemeCodeUnspsc190501.10215603'),
	'identifierSchemeCodeUnspsc190501.10215604': __('identifierSchemeCodeUnspsc190501.10215604'),
	'identifierSchemeCodeUnspsc190501.10215605': __('identifierSchemeCodeUnspsc190501.10215605'),
	'identifierSchemeCodeUnspsc190501.10215606': __('identifierSchemeCodeUnspsc190501.10215606'),
	'identifierSchemeCodeUnspsc190501.10215607': __('identifierSchemeCodeUnspsc190501.10215607'),
	'identifierSchemeCodeUnspsc190501.10215608': __('identifierSchemeCodeUnspsc190501.10215608'),
	'identifierSchemeCodeUnspsc190501.10215609': __('identifierSchemeCodeUnspsc190501.10215609'),
	'identifierSchemeCodeUnspsc190501.10215610': __('identifierSchemeCodeUnspsc190501.10215610'),
	'identifierSchemeCodeUnspsc190501.10215611': __('identifierSchemeCodeUnspsc190501.10215611'),
	'identifierSchemeCodeUnspsc190501.10215612': __('identifierSchemeCodeUnspsc190501.10215612'),
	'identifierSchemeCodeUnspsc190501.10215613': __('identifierSchemeCodeUnspsc190501.10215613'),
	'identifierSchemeCodeUnspsc190501.10215700': __('identifierSchemeCodeUnspsc190501.10215700'),
	'identifierSchemeCodeUnspsc190501.10215701': __('identifierSchemeCodeUnspsc190501.10215701'),
	'identifierSchemeCodeUnspsc190501.10215702': __('identifierSchemeCodeUnspsc190501.10215702'),
	'identifierSchemeCodeUnspsc190501.10215703': __('identifierSchemeCodeUnspsc190501.10215703'),
	'identifierSchemeCodeUnspsc190501.10215704': __('identifierSchemeCodeUnspsc190501.10215704'),
	'identifierSchemeCodeUnspsc190501.10215705': __('identifierSchemeCodeUnspsc190501.10215705'),
	'identifierSchemeCodeUnspsc190501.10215800': __('identifierSchemeCodeUnspsc190501.10215800'),
	'identifierSchemeCodeUnspsc190501.10215801': __('identifierSchemeCodeUnspsc190501.10215801'),
	'identifierSchemeCodeUnspsc190501.10215802': __('identifierSchemeCodeUnspsc190501.10215802'),
	'identifierSchemeCodeUnspsc190501.10215803': __('identifierSchemeCodeUnspsc190501.10215803'),
	'identifierSchemeCodeUnspsc190501.10215804': __('identifierSchemeCodeUnspsc190501.10215804'),
	'identifierSchemeCodeUnspsc190501.10215805': __('identifierSchemeCodeUnspsc190501.10215805'),
	'identifierSchemeCodeUnspsc190501.10215806': __('identifierSchemeCodeUnspsc190501.10215806'),
	'identifierSchemeCodeUnspsc190501.10215807': __('identifierSchemeCodeUnspsc190501.10215807'),
	'identifierSchemeCodeUnspsc190501.10215808': __('identifierSchemeCodeUnspsc190501.10215808'),
	'identifierSchemeCodeUnspsc190501.10216000': __('identifierSchemeCodeUnspsc190501.10216000'),
	'identifierSchemeCodeUnspsc190501.10216001': __('identifierSchemeCodeUnspsc190501.10216001'),
	'identifierSchemeCodeUnspsc190501.10216002': __('identifierSchemeCodeUnspsc190501.10216002'),
	'identifierSchemeCodeUnspsc190501.10216003': __('identifierSchemeCodeUnspsc190501.10216003'),
	'identifierSchemeCodeUnspsc190501.10216004': __('identifierSchemeCodeUnspsc190501.10216004'),
	'identifierSchemeCodeUnspsc190501.10216005': __('identifierSchemeCodeUnspsc190501.10216005'),
	'identifierSchemeCodeUnspsc190501.10216100': __('identifierSchemeCodeUnspsc190501.10216100'),
	'identifierSchemeCodeUnspsc190501.10216101': __('identifierSchemeCodeUnspsc190501.10216101'),
	'identifierSchemeCodeUnspsc190501.10216102': __('identifierSchemeCodeUnspsc190501.10216102'),
	'identifierSchemeCodeUnspsc190501.10216103': __('identifierSchemeCodeUnspsc190501.10216103'),
	'identifierSchemeCodeUnspsc190501.10216104': __('identifierSchemeCodeUnspsc190501.10216104'),
	'identifierSchemeCodeUnspsc190501.10216105': __('identifierSchemeCodeUnspsc190501.10216105'),
	'identifierSchemeCodeUnspsc190501.10216200': __('identifierSchemeCodeUnspsc190501.10216200'),
	'identifierSchemeCodeUnspsc190501.10216201': __('identifierSchemeCodeUnspsc190501.10216201'),
	'identifierSchemeCodeUnspsc190501.10216202': __('identifierSchemeCodeUnspsc190501.10216202'),
	'identifierSchemeCodeUnspsc190501.10216203': __('identifierSchemeCodeUnspsc190501.10216203'),
	'identifierSchemeCodeUnspsc190501.10216204': __('identifierSchemeCodeUnspsc190501.10216204'),
	'identifierSchemeCodeUnspsc190501.10216205': __('identifierSchemeCodeUnspsc190501.10216205'),
	'identifierSchemeCodeUnspsc190501.10216206': __('identifierSchemeCodeUnspsc190501.10216206'),
	'identifierSchemeCodeUnspsc190501.10216207': __('identifierSchemeCodeUnspsc190501.10216207'),
	'identifierSchemeCodeUnspsc190501.10216208': __('identifierSchemeCodeUnspsc190501.10216208'),
	'identifierSchemeCodeUnspsc190501.10216209': __('identifierSchemeCodeUnspsc190501.10216209'),
	'identifierSchemeCodeUnspsc190501.10216210': __('identifierSchemeCodeUnspsc190501.10216210'),
	'identifierSchemeCodeUnspsc190501.10216211': __('identifierSchemeCodeUnspsc190501.10216211'),
	'identifierSchemeCodeUnspsc190501.10216212': __('identifierSchemeCodeUnspsc190501.10216212'),
	'identifierSchemeCodeUnspsc190501.10216213': __('identifierSchemeCodeUnspsc190501.10216213'),
	'identifierSchemeCodeUnspsc190501.10216214': __('identifierSchemeCodeUnspsc190501.10216214'),
	'identifierSchemeCodeUnspsc190501.10216215': __('identifierSchemeCodeUnspsc190501.10216215'),
	'identifierSchemeCodeUnspsc190501.10216216': __('identifierSchemeCodeUnspsc190501.10216216'),
	'identifierSchemeCodeUnspsc190501.10216217': __('identifierSchemeCodeUnspsc190501.10216217'),
	'identifierSchemeCodeUnspsc190501.10216218': __('identifierSchemeCodeUnspsc190501.10216218'),
	'identifierSchemeCodeUnspsc190501.10216300': __('identifierSchemeCodeUnspsc190501.10216300'),
	'identifierSchemeCodeUnspsc190501.10216301': __('identifierSchemeCodeUnspsc190501.10216301'),
	'identifierSchemeCodeUnspsc190501.10216302': __('identifierSchemeCodeUnspsc190501.10216302'),
	'identifierSchemeCodeUnspsc190501.10216306': __('identifierSchemeCodeUnspsc190501.10216306'),
	'identifierSchemeCodeUnspsc190501.10216311': __('identifierSchemeCodeUnspsc190501.10216311'),
	'identifierSchemeCodeUnspsc190501.10216315': __('identifierSchemeCodeUnspsc190501.10216315'),
	'identifierSchemeCodeUnspsc190501.10216322': __('identifierSchemeCodeUnspsc190501.10216322'),
	'identifierSchemeCodeUnspsc190501.10216325': __('identifierSchemeCodeUnspsc190501.10216325'),
	'identifierSchemeCodeUnspsc190501.10216326': __('identifierSchemeCodeUnspsc190501.10216326'),
	'identifierSchemeCodeUnspsc190501.10216332': __('identifierSchemeCodeUnspsc190501.10216332'),
	'identifierSchemeCodeUnspsc190501.10216400': __('identifierSchemeCodeUnspsc190501.10216400'),
	'identifierSchemeCodeUnspsc190501.10216401': __('identifierSchemeCodeUnspsc190501.10216401'),
	'identifierSchemeCodeUnspsc190501.10216402': __('identifierSchemeCodeUnspsc190501.10216402'),
	'identifierSchemeCodeUnspsc190501.10216403': __('identifierSchemeCodeUnspsc190501.10216403'),
	'identifierSchemeCodeUnspsc190501.10216404': __('identifierSchemeCodeUnspsc190501.10216404'),
	'identifierSchemeCodeUnspsc190501.10216405': __('identifierSchemeCodeUnspsc190501.10216405'),
	'identifierSchemeCodeUnspsc190501.10216406': __('identifierSchemeCodeUnspsc190501.10216406'),
	'identifierSchemeCodeUnspsc190501.10216407': __('identifierSchemeCodeUnspsc190501.10216407'),
	'identifierSchemeCodeUnspsc190501.10216408': __('identifierSchemeCodeUnspsc190501.10216408'),
	'identifierSchemeCodeUnspsc190501.10216409': __('identifierSchemeCodeUnspsc190501.10216409'),
	'identifierSchemeCodeUnspsc190501.10216410': __('identifierSchemeCodeUnspsc190501.10216410'),
	'identifierSchemeCodeUnspsc190501.10216411': __('identifierSchemeCodeUnspsc190501.10216411'),
	'identifierSchemeCodeUnspsc190501.10216412': __('identifierSchemeCodeUnspsc190501.10216412'),
	'identifierSchemeCodeUnspsc190501.10216413': __('identifierSchemeCodeUnspsc190501.10216413'),
	'identifierSchemeCodeUnspsc190501.10216500': __('identifierSchemeCodeUnspsc190501.10216500'),
	'identifierSchemeCodeUnspsc190501.10216501': __('identifierSchemeCodeUnspsc190501.10216501'),
	'identifierSchemeCodeUnspsc190501.10216502': __('identifierSchemeCodeUnspsc190501.10216502'),
	'identifierSchemeCodeUnspsc190501.10216503': __('identifierSchemeCodeUnspsc190501.10216503'),
	'identifierSchemeCodeUnspsc190501.10216504': __('identifierSchemeCodeUnspsc190501.10216504'),
	'identifierSchemeCodeUnspsc190501.10216505': __('identifierSchemeCodeUnspsc190501.10216505'),
	'identifierSchemeCodeUnspsc190501.10216506': __('identifierSchemeCodeUnspsc190501.10216506'),
	'identifierSchemeCodeUnspsc190501.10216507': __('identifierSchemeCodeUnspsc190501.10216507'),
	'identifierSchemeCodeUnspsc190501.10216600': __('identifierSchemeCodeUnspsc190501.10216600'),
	'identifierSchemeCodeUnspsc190501.10216601': __('identifierSchemeCodeUnspsc190501.10216601'),
	'identifierSchemeCodeUnspsc190501.10216602': __('identifierSchemeCodeUnspsc190501.10216602'),
	'identifierSchemeCodeUnspsc190501.10216603': __('identifierSchemeCodeUnspsc190501.10216603'),
	'identifierSchemeCodeUnspsc190501.10216604': __('identifierSchemeCodeUnspsc190501.10216604'),
	'identifierSchemeCodeUnspsc190501.10216700': __('identifierSchemeCodeUnspsc190501.10216700'),
	'identifierSchemeCodeUnspsc190501.10216701': __('identifierSchemeCodeUnspsc190501.10216701'),
	'identifierSchemeCodeUnspsc190501.10216702': __('identifierSchemeCodeUnspsc190501.10216702'),
	'identifierSchemeCodeUnspsc190501.10216703': __('identifierSchemeCodeUnspsc190501.10216703'),
	'identifierSchemeCodeUnspsc190501.10216704': __('identifierSchemeCodeUnspsc190501.10216704'),
	'identifierSchemeCodeUnspsc190501.10216705': __('identifierSchemeCodeUnspsc190501.10216705'),
	'identifierSchemeCodeUnspsc190501.10216706': __('identifierSchemeCodeUnspsc190501.10216706'),
	'identifierSchemeCodeUnspsc190501.10216707': __('identifierSchemeCodeUnspsc190501.10216707'),
	'identifierSchemeCodeUnspsc190501.10216708': __('identifierSchemeCodeUnspsc190501.10216708'),
	'identifierSchemeCodeUnspsc190501.10216709': __('identifierSchemeCodeUnspsc190501.10216709'),
	'identifierSchemeCodeUnspsc190501.10216800': __('identifierSchemeCodeUnspsc190501.10216800'),
	'identifierSchemeCodeUnspsc190501.10216801': __('identifierSchemeCodeUnspsc190501.10216801'),
	'identifierSchemeCodeUnspsc190501.10216802': __('identifierSchemeCodeUnspsc190501.10216802'),
	'identifierSchemeCodeUnspsc190501.10216803': __('identifierSchemeCodeUnspsc190501.10216803'),
	'identifierSchemeCodeUnspsc190501.10216804': __('identifierSchemeCodeUnspsc190501.10216804'),
	'identifierSchemeCodeUnspsc190501.10216805': __('identifierSchemeCodeUnspsc190501.10216805'),
	'identifierSchemeCodeUnspsc190501.10216806': __('identifierSchemeCodeUnspsc190501.10216806'),
	'identifierSchemeCodeUnspsc190501.10216807': __('identifierSchemeCodeUnspsc190501.10216807'),
	'identifierSchemeCodeUnspsc190501.10216808': __('identifierSchemeCodeUnspsc190501.10216808'),
	'identifierSchemeCodeUnspsc190501.10216900': __('identifierSchemeCodeUnspsc190501.10216900'),
	'identifierSchemeCodeUnspsc190501.10216901': __('identifierSchemeCodeUnspsc190501.10216901'),
	'identifierSchemeCodeUnspsc190501.10216902': __('identifierSchemeCodeUnspsc190501.10216902'),
	'identifierSchemeCodeUnspsc190501.10216903': __('identifierSchemeCodeUnspsc190501.10216903'),
	'identifierSchemeCodeUnspsc190501.10216904': __('identifierSchemeCodeUnspsc190501.10216904'),
	'identifierSchemeCodeUnspsc190501.10216905': __('identifierSchemeCodeUnspsc190501.10216905'),
	'identifierSchemeCodeUnspsc190501.10216906': __('identifierSchemeCodeUnspsc190501.10216906'),
	'identifierSchemeCodeUnspsc190501.10216907': __('identifierSchemeCodeUnspsc190501.10216907'),
	'identifierSchemeCodeUnspsc190501.10216908': __('identifierSchemeCodeUnspsc190501.10216908'),
	'identifierSchemeCodeUnspsc190501.10216909': __('identifierSchemeCodeUnspsc190501.10216909'),
	'identifierSchemeCodeUnspsc190501.10216910': __('identifierSchemeCodeUnspsc190501.10216910'),
	'identifierSchemeCodeUnspsc190501.10217000': __('identifierSchemeCodeUnspsc190501.10217000'),
	'identifierSchemeCodeUnspsc190501.10217001': __('identifierSchemeCodeUnspsc190501.10217001'),
	'identifierSchemeCodeUnspsc190501.10217002': __('identifierSchemeCodeUnspsc190501.10217002'),
	'identifierSchemeCodeUnspsc190501.10217003': __('identifierSchemeCodeUnspsc190501.10217003'),
	'identifierSchemeCodeUnspsc190501.10217004': __('identifierSchemeCodeUnspsc190501.10217004'),
	'identifierSchemeCodeUnspsc190501.10217005': __('identifierSchemeCodeUnspsc190501.10217005'),
	'identifierSchemeCodeUnspsc190501.10217006': __('identifierSchemeCodeUnspsc190501.10217006'),
	'identifierSchemeCodeUnspsc190501.10217100': __('identifierSchemeCodeUnspsc190501.10217100'),
	'identifierSchemeCodeUnspsc190501.10217101': __('identifierSchemeCodeUnspsc190501.10217101'),
	'identifierSchemeCodeUnspsc190501.10217102': __('identifierSchemeCodeUnspsc190501.10217102'),
	'identifierSchemeCodeUnspsc190501.10217103': __('identifierSchemeCodeUnspsc190501.10217103'),
	'identifierSchemeCodeUnspsc190501.10217104': __('identifierSchemeCodeUnspsc190501.10217104'),
	'identifierSchemeCodeUnspsc190501.10217105': __('identifierSchemeCodeUnspsc190501.10217105'),
	'identifierSchemeCodeUnspsc190501.10217106': __('identifierSchemeCodeUnspsc190501.10217106'),
	'identifierSchemeCodeUnspsc190501.10217107': __('identifierSchemeCodeUnspsc190501.10217107'),
	'identifierSchemeCodeUnspsc190501.10217108': __('identifierSchemeCodeUnspsc190501.10217108'),
	'identifierSchemeCodeUnspsc190501.10217200': __('identifierSchemeCodeUnspsc190501.10217200'),
	'identifierSchemeCodeUnspsc190501.10217201': __('identifierSchemeCodeUnspsc190501.10217201'),
	'identifierSchemeCodeUnspsc190501.10217202': __('identifierSchemeCodeUnspsc190501.10217202'),
	'identifierSchemeCodeUnspsc190501.10217203': __('identifierSchemeCodeUnspsc190501.10217203'),
	'identifierSchemeCodeUnspsc190501.10217204': __('identifierSchemeCodeUnspsc190501.10217204'),
	'identifierSchemeCodeUnspsc190501.10217205': __('identifierSchemeCodeUnspsc190501.10217205'),
	'identifierSchemeCodeUnspsc190501.10217206': __('identifierSchemeCodeUnspsc190501.10217206'),
	'identifierSchemeCodeUnspsc190501.10217207': __('identifierSchemeCodeUnspsc190501.10217207'),
	'identifierSchemeCodeUnspsc190501.10217208': __('identifierSchemeCodeUnspsc190501.10217208'),
	'identifierSchemeCodeUnspsc190501.10217300': __('identifierSchemeCodeUnspsc190501.10217300'),
	'identifierSchemeCodeUnspsc190501.10217301': __('identifierSchemeCodeUnspsc190501.10217301'),
	'identifierSchemeCodeUnspsc190501.10217302': __('identifierSchemeCodeUnspsc190501.10217302'),
	'identifierSchemeCodeUnspsc190501.10217303': __('identifierSchemeCodeUnspsc190501.10217303'),
	'identifierSchemeCodeUnspsc190501.10217304': __('identifierSchemeCodeUnspsc190501.10217304'),
	'identifierSchemeCodeUnspsc190501.10217305': __('identifierSchemeCodeUnspsc190501.10217305'),
	'identifierSchemeCodeUnspsc190501.10217306': __('identifierSchemeCodeUnspsc190501.10217306'),
	'identifierSchemeCodeUnspsc190501.10217307': __('identifierSchemeCodeUnspsc190501.10217307'),
	'identifierSchemeCodeUnspsc190501.10217308': __('identifierSchemeCodeUnspsc190501.10217308'),
	'identifierSchemeCodeUnspsc190501.10217309': __('identifierSchemeCodeUnspsc190501.10217309'),
	'identifierSchemeCodeUnspsc190501.10217310': __('identifierSchemeCodeUnspsc190501.10217310'),
	'identifierSchemeCodeUnspsc190501.10217311': __('identifierSchemeCodeUnspsc190501.10217311'),
	'identifierSchemeCodeUnspsc190501.10217312': __('identifierSchemeCodeUnspsc190501.10217312'),
	'identifierSchemeCodeUnspsc190501.10217313': __('identifierSchemeCodeUnspsc190501.10217313'),
	'identifierSchemeCodeUnspsc190501.10217314': __('identifierSchemeCodeUnspsc190501.10217314'),
	'identifierSchemeCodeUnspsc190501.10217315': __('identifierSchemeCodeUnspsc190501.10217315'),
	'identifierSchemeCodeUnspsc190501.10217316': __('identifierSchemeCodeUnspsc190501.10217316'),
	'identifierSchemeCodeUnspsc190501.10217317': __('identifierSchemeCodeUnspsc190501.10217317'),
	'identifierSchemeCodeUnspsc190501.10217318': __('identifierSchemeCodeUnspsc190501.10217318'),
	'identifierSchemeCodeUnspsc190501.10217319': __('identifierSchemeCodeUnspsc190501.10217319'),
	'identifierSchemeCodeUnspsc190501.10217320': __('identifierSchemeCodeUnspsc190501.10217320'),
	'identifierSchemeCodeUnspsc190501.10217321': __('identifierSchemeCodeUnspsc190501.10217321'),
	'identifierSchemeCodeUnspsc190501.10217322': __('identifierSchemeCodeUnspsc190501.10217322'),
	'identifierSchemeCodeUnspsc190501.10217323': __('identifierSchemeCodeUnspsc190501.10217323'),
	'identifierSchemeCodeUnspsc190501.10217324': __('identifierSchemeCodeUnspsc190501.10217324'),
	'identifierSchemeCodeUnspsc190501.10217325': __('identifierSchemeCodeUnspsc190501.10217325'),
	'identifierSchemeCodeUnspsc190501.10217326': __('identifierSchemeCodeUnspsc190501.10217326'),
	'identifierSchemeCodeUnspsc190501.10217327': __('identifierSchemeCodeUnspsc190501.10217327'),
	'identifierSchemeCodeUnspsc190501.10217328': __('identifierSchemeCodeUnspsc190501.10217328'),
	'identifierSchemeCodeUnspsc190501.10217329': __('identifierSchemeCodeUnspsc190501.10217329'),
	'identifierSchemeCodeUnspsc190501.10217330': __('identifierSchemeCodeUnspsc190501.10217330'),
	'identifierSchemeCodeUnspsc190501.10217331': __('identifierSchemeCodeUnspsc190501.10217331'),
	'identifierSchemeCodeUnspsc190501.10217332': __('identifierSchemeCodeUnspsc190501.10217332'),
	'identifierSchemeCodeUnspsc190501.10217333': __('identifierSchemeCodeUnspsc190501.10217333'),
	'identifierSchemeCodeUnspsc190501.10217334': __('identifierSchemeCodeUnspsc190501.10217334'),
	'identifierSchemeCodeUnspsc190501.10217335': __('identifierSchemeCodeUnspsc190501.10217335'),
	'identifierSchemeCodeUnspsc190501.10217336': __('identifierSchemeCodeUnspsc190501.10217336'),
	'identifierSchemeCodeUnspsc190501.10217337': __('identifierSchemeCodeUnspsc190501.10217337'),
	'identifierSchemeCodeUnspsc190501.10217338': __('identifierSchemeCodeUnspsc190501.10217338'),
	'identifierSchemeCodeUnspsc190501.10217339': __('identifierSchemeCodeUnspsc190501.10217339'),
	'identifierSchemeCodeUnspsc190501.10217340': __('identifierSchemeCodeUnspsc190501.10217340'),
	'identifierSchemeCodeUnspsc190501.10217341': __('identifierSchemeCodeUnspsc190501.10217341'),
	'identifierSchemeCodeUnspsc190501.10217342': __('identifierSchemeCodeUnspsc190501.10217342'),
	'identifierSchemeCodeUnspsc190501.10217343': __('identifierSchemeCodeUnspsc190501.10217343'),
	'identifierSchemeCodeUnspsc190501.10217344': __('identifierSchemeCodeUnspsc190501.10217344'),
	'identifierSchemeCodeUnspsc190501.10217345': __('identifierSchemeCodeUnspsc190501.10217345'),
	'identifierSchemeCodeUnspsc190501.10217346': __('identifierSchemeCodeUnspsc190501.10217346'),
	'identifierSchemeCodeUnspsc190501.10217347': __('identifierSchemeCodeUnspsc190501.10217347'),
	'identifierSchemeCodeUnspsc190501.10217348': __('identifierSchemeCodeUnspsc190501.10217348'),
	'identifierSchemeCodeUnspsc190501.10217349': __('identifierSchemeCodeUnspsc190501.10217349'),
	'identifierSchemeCodeUnspsc190501.10217350': __('identifierSchemeCodeUnspsc190501.10217350'),
	'identifierSchemeCodeUnspsc190501.10217351': __('identifierSchemeCodeUnspsc190501.10217351'),
	'identifierSchemeCodeUnspsc190501.10217352': __('identifierSchemeCodeUnspsc190501.10217352'),
	'identifierSchemeCodeUnspsc190501.10217353': __('identifierSchemeCodeUnspsc190501.10217353'),
	'identifierSchemeCodeUnspsc190501.10217354': __('identifierSchemeCodeUnspsc190501.10217354'),
	'identifierSchemeCodeUnspsc190501.10217355': __('identifierSchemeCodeUnspsc190501.10217355'),
	'identifierSchemeCodeUnspsc190501.10217400': __('identifierSchemeCodeUnspsc190501.10217400'),
	'identifierSchemeCodeUnspsc190501.10217401': __('identifierSchemeCodeUnspsc190501.10217401'),
	'identifierSchemeCodeUnspsc190501.10217402': __('identifierSchemeCodeUnspsc190501.10217402'),
	'identifierSchemeCodeUnspsc190501.10217403': __('identifierSchemeCodeUnspsc190501.10217403'),
	'identifierSchemeCodeUnspsc190501.10217404': __('identifierSchemeCodeUnspsc190501.10217404'),
	'identifierSchemeCodeUnspsc190501.10217405': __('identifierSchemeCodeUnspsc190501.10217405'),
	'identifierSchemeCodeUnspsc190501.10217406': __('identifierSchemeCodeUnspsc190501.10217406'),
	'identifierSchemeCodeUnspsc190501.10217407': __('identifierSchemeCodeUnspsc190501.10217407'),
	'identifierSchemeCodeUnspsc190501.10217408': __('identifierSchemeCodeUnspsc190501.10217408'),
	'identifierSchemeCodeUnspsc190501.10217409': __('identifierSchemeCodeUnspsc190501.10217409'),
	'identifierSchemeCodeUnspsc190501.10217410': __('identifierSchemeCodeUnspsc190501.10217410'),
	'identifierSchemeCodeUnspsc190501.10217411': __('identifierSchemeCodeUnspsc190501.10217411'),
	'identifierSchemeCodeUnspsc190501.10217412': __('identifierSchemeCodeUnspsc190501.10217412'),
	'identifierSchemeCodeUnspsc190501.10217413': __('identifierSchemeCodeUnspsc190501.10217413'),
	'identifierSchemeCodeUnspsc190501.10217414': __('identifierSchemeCodeUnspsc190501.10217414'),
	'identifierSchemeCodeUnspsc190501.10217415': __('identifierSchemeCodeUnspsc190501.10217415'),
	'identifierSchemeCodeUnspsc190501.10217416': __('identifierSchemeCodeUnspsc190501.10217416'),
	'identifierSchemeCodeUnspsc190501.10217417': __('identifierSchemeCodeUnspsc190501.10217417'),
	'identifierSchemeCodeUnspsc190501.10217418': __('identifierSchemeCodeUnspsc190501.10217418'),
	'identifierSchemeCodeUnspsc190501.10217419': __('identifierSchemeCodeUnspsc190501.10217419'),
	'identifierSchemeCodeUnspsc190501.10217420': __('identifierSchemeCodeUnspsc190501.10217420'),
	'identifierSchemeCodeUnspsc190501.10217421': __('identifierSchemeCodeUnspsc190501.10217421'),
	'identifierSchemeCodeUnspsc190501.10217422': __('identifierSchemeCodeUnspsc190501.10217422'),
	'identifierSchemeCodeUnspsc190501.10217423': __('identifierSchemeCodeUnspsc190501.10217423'),
	'identifierSchemeCodeUnspsc190501.10217424': __('identifierSchemeCodeUnspsc190501.10217424'),
	'identifierSchemeCodeUnspsc190501.10217500': __('identifierSchemeCodeUnspsc190501.10217500'),
	'identifierSchemeCodeUnspsc190501.10217501': __('identifierSchemeCodeUnspsc190501.10217501'),
	'identifierSchemeCodeUnspsc190501.10217502': __('identifierSchemeCodeUnspsc190501.10217502'),
	'identifierSchemeCodeUnspsc190501.10217503': __('identifierSchemeCodeUnspsc190501.10217503'),
	'identifierSchemeCodeUnspsc190501.10217504': __('identifierSchemeCodeUnspsc190501.10217504'),
	'identifierSchemeCodeUnspsc190501.10217505': __('identifierSchemeCodeUnspsc190501.10217505'),
	'identifierSchemeCodeUnspsc190501.10217506': __('identifierSchemeCodeUnspsc190501.10217506'),
	'identifierSchemeCodeUnspsc190501.10217507': __('identifierSchemeCodeUnspsc190501.10217507'),
	'identifierSchemeCodeUnspsc190501.10217508': __('identifierSchemeCodeUnspsc190501.10217508'),
	'identifierSchemeCodeUnspsc190501.10217509': __('identifierSchemeCodeUnspsc190501.10217509'),
	'identifierSchemeCodeUnspsc190501.10217510': __('identifierSchemeCodeUnspsc190501.10217510'),
	'identifierSchemeCodeUnspsc190501.10217600': __('identifierSchemeCodeUnspsc190501.10217600'),
	'identifierSchemeCodeUnspsc190501.10217601': __('identifierSchemeCodeUnspsc190501.10217601'),
	'identifierSchemeCodeUnspsc190501.10217602': __('identifierSchemeCodeUnspsc190501.10217602'),
	'identifierSchemeCodeUnspsc190501.10217603': __('identifierSchemeCodeUnspsc190501.10217603'),
	'identifierSchemeCodeUnspsc190501.10217604': __('identifierSchemeCodeUnspsc190501.10217604'),
	'identifierSchemeCodeUnspsc190501.10217605': __('identifierSchemeCodeUnspsc190501.10217605'),
	'identifierSchemeCodeUnspsc190501.10217606': __('identifierSchemeCodeUnspsc190501.10217606'),
	'identifierSchemeCodeUnspsc190501.10217700': __('identifierSchemeCodeUnspsc190501.10217700'),
	'identifierSchemeCodeUnspsc190501.10217701': __('identifierSchemeCodeUnspsc190501.10217701'),
	'identifierSchemeCodeUnspsc190501.10217702': __('identifierSchemeCodeUnspsc190501.10217702'),
	'identifierSchemeCodeUnspsc190501.10217703': __('identifierSchemeCodeUnspsc190501.10217703'),
	'identifierSchemeCodeUnspsc190501.10217704': __('identifierSchemeCodeUnspsc190501.10217704'),
	'identifierSchemeCodeUnspsc190501.10217705': __('identifierSchemeCodeUnspsc190501.10217705'),
	'identifierSchemeCodeUnspsc190501.10217706': __('identifierSchemeCodeUnspsc190501.10217706'),
	'identifierSchemeCodeUnspsc190501.10217707': __('identifierSchemeCodeUnspsc190501.10217707'),
	'identifierSchemeCodeUnspsc190501.10217708': __('identifierSchemeCodeUnspsc190501.10217708'),
	'identifierSchemeCodeUnspsc190501.10217800': __('identifierSchemeCodeUnspsc190501.10217800'),
	'identifierSchemeCodeUnspsc190501.10217801': __('identifierSchemeCodeUnspsc190501.10217801'),
	'identifierSchemeCodeUnspsc190501.10217802': __('identifierSchemeCodeUnspsc190501.10217802'),
	'identifierSchemeCodeUnspsc190501.10217803': __('identifierSchemeCodeUnspsc190501.10217803'),
	'identifierSchemeCodeUnspsc190501.10217804': __('identifierSchemeCodeUnspsc190501.10217804'),
	'identifierSchemeCodeUnspsc190501.10217805': __('identifierSchemeCodeUnspsc190501.10217805'),
	'identifierSchemeCodeUnspsc190501.10217806': __('identifierSchemeCodeUnspsc190501.10217806'),
	'identifierSchemeCodeUnspsc190501.10217807': __('identifierSchemeCodeUnspsc190501.10217807'),
	'identifierSchemeCodeUnspsc190501.10217808': __('identifierSchemeCodeUnspsc190501.10217808'),
	'identifierSchemeCodeUnspsc190501.10217809': __('identifierSchemeCodeUnspsc190501.10217809'),
	'identifierSchemeCodeUnspsc190501.10217810': __('identifierSchemeCodeUnspsc190501.10217810'),
	'identifierSchemeCodeUnspsc190501.10217811': __('identifierSchemeCodeUnspsc190501.10217811'),
	'identifierSchemeCodeUnspsc190501.10217812': __('identifierSchemeCodeUnspsc190501.10217812'),
	'identifierSchemeCodeUnspsc190501.10217813': __('identifierSchemeCodeUnspsc190501.10217813'),
	'identifierSchemeCodeUnspsc190501.10217814': __('identifierSchemeCodeUnspsc190501.10217814'),
	'identifierSchemeCodeUnspsc190501.10217815': __('identifierSchemeCodeUnspsc190501.10217815'),
	'identifierSchemeCodeUnspsc190501.10217816': __('identifierSchemeCodeUnspsc190501.10217816'),
	'identifierSchemeCodeUnspsc190501.10217817': __('identifierSchemeCodeUnspsc190501.10217817'),
	'identifierSchemeCodeUnspsc190501.10217818': __('identifierSchemeCodeUnspsc190501.10217818'),
	'identifierSchemeCodeUnspsc190501.10217819': __('identifierSchemeCodeUnspsc190501.10217819'),
	'identifierSchemeCodeUnspsc190501.10217820': __('identifierSchemeCodeUnspsc190501.10217820'),
	'identifierSchemeCodeUnspsc190501.10217821': __('identifierSchemeCodeUnspsc190501.10217821'),
	'identifierSchemeCodeUnspsc190501.10217822': __('identifierSchemeCodeUnspsc190501.10217822'),
	'identifierSchemeCodeUnspsc190501.10217823': __('identifierSchemeCodeUnspsc190501.10217823'),
	'identifierSchemeCodeUnspsc190501.10217824': __('identifierSchemeCodeUnspsc190501.10217824'),
	'identifierSchemeCodeUnspsc190501.10217825': __('identifierSchemeCodeUnspsc190501.10217825'),
	'identifierSchemeCodeUnspsc190501.10217826': __('identifierSchemeCodeUnspsc190501.10217826'),
	'identifierSchemeCodeUnspsc190501.10217827': __('identifierSchemeCodeUnspsc190501.10217827'),
	'identifierSchemeCodeUnspsc190501.10217828': __('identifierSchemeCodeUnspsc190501.10217828'),
	'identifierSchemeCodeUnspsc190501.10217900': __('identifierSchemeCodeUnspsc190501.10217900'),
	'identifierSchemeCodeUnspsc190501.10217901': __('identifierSchemeCodeUnspsc190501.10217901'),
	'identifierSchemeCodeUnspsc190501.10217902': __('identifierSchemeCodeUnspsc190501.10217902'),
	'identifierSchemeCodeUnspsc190501.10217903': __('identifierSchemeCodeUnspsc190501.10217903'),
	'identifierSchemeCodeUnspsc190501.10217904': __('identifierSchemeCodeUnspsc190501.10217904'),
	'identifierSchemeCodeUnspsc190501.10217905': __('identifierSchemeCodeUnspsc190501.10217905'),
	'identifierSchemeCodeUnspsc190501.10217906': __('identifierSchemeCodeUnspsc190501.10217906'),
	'identifierSchemeCodeUnspsc190501.10217907': __('identifierSchemeCodeUnspsc190501.10217907'),
	'identifierSchemeCodeUnspsc190501.10217908': __('identifierSchemeCodeUnspsc190501.10217908'),
	'identifierSchemeCodeUnspsc190501.10217909': __('identifierSchemeCodeUnspsc190501.10217909'),
	'identifierSchemeCodeUnspsc190501.10217910': __('identifierSchemeCodeUnspsc190501.10217910'),
	'identifierSchemeCodeUnspsc190501.10217911': __('identifierSchemeCodeUnspsc190501.10217911'),
	'identifierSchemeCodeUnspsc190501.10217912': __('identifierSchemeCodeUnspsc190501.10217912'),
	'identifierSchemeCodeUnspsc190501.10217913': __('identifierSchemeCodeUnspsc190501.10217913'),
	'identifierSchemeCodeUnspsc190501.10217914': __('identifierSchemeCodeUnspsc190501.10217914'),
	'identifierSchemeCodeUnspsc190501.10217915': __('identifierSchemeCodeUnspsc190501.10217915'),
	'identifierSchemeCodeUnspsc190501.10217916': __('identifierSchemeCodeUnspsc190501.10217916'),
	'identifierSchemeCodeUnspsc190501.10217917': __('identifierSchemeCodeUnspsc190501.10217917'),
	'identifierSchemeCodeUnspsc190501.10217918': __('identifierSchemeCodeUnspsc190501.10217918'),
	'identifierSchemeCodeUnspsc190501.10217919': __('identifierSchemeCodeUnspsc190501.10217919'),
	'identifierSchemeCodeUnspsc190501.10217920': __('identifierSchemeCodeUnspsc190501.10217920'),
	'identifierSchemeCodeUnspsc190501.10217921': __('identifierSchemeCodeUnspsc190501.10217921'),
	'identifierSchemeCodeUnspsc190501.10217922': __('identifierSchemeCodeUnspsc190501.10217922'),
	'identifierSchemeCodeUnspsc190501.10217923': __('identifierSchemeCodeUnspsc190501.10217923'),
	'identifierSchemeCodeUnspsc190501.10217924': __('identifierSchemeCodeUnspsc190501.10217924'),
	'identifierSchemeCodeUnspsc190501.10217925': __('identifierSchemeCodeUnspsc190501.10217925'),
	'identifierSchemeCodeUnspsc190501.10217926': __('identifierSchemeCodeUnspsc190501.10217926'),
	'identifierSchemeCodeUnspsc190501.10217927': __('identifierSchemeCodeUnspsc190501.10217927'),
	'identifierSchemeCodeUnspsc190501.10217928': __('identifierSchemeCodeUnspsc190501.10217928'),
	'identifierSchemeCodeUnspsc190501.10217929': __('identifierSchemeCodeUnspsc190501.10217929'),
	'identifierSchemeCodeUnspsc190501.10217930': __('identifierSchemeCodeUnspsc190501.10217930'),
	'identifierSchemeCodeUnspsc190501.10217931': __('identifierSchemeCodeUnspsc190501.10217931'),
	'identifierSchemeCodeUnspsc190501.10217932': __('identifierSchemeCodeUnspsc190501.10217932'),
	'identifierSchemeCodeUnspsc190501.10217933': __('identifierSchemeCodeUnspsc190501.10217933'),
	'identifierSchemeCodeUnspsc190501.10217934': __('identifierSchemeCodeUnspsc190501.10217934'),
	'identifierSchemeCodeUnspsc190501.10217935': __('identifierSchemeCodeUnspsc190501.10217935'),
	'identifierSchemeCodeUnspsc190501.10217936': __('identifierSchemeCodeUnspsc190501.10217936'),
	'identifierSchemeCodeUnspsc190501.10217937': __('identifierSchemeCodeUnspsc190501.10217937'),
	'identifierSchemeCodeUnspsc190501.10217938': __('identifierSchemeCodeUnspsc190501.10217938'),
	'identifierSchemeCodeUnspsc190501.10217939': __('identifierSchemeCodeUnspsc190501.10217939'),
	'identifierSchemeCodeUnspsc190501.10217940': __('identifierSchemeCodeUnspsc190501.10217940'),
	'identifierSchemeCodeUnspsc190501.10217941': __('identifierSchemeCodeUnspsc190501.10217941'),
	'identifierSchemeCodeUnspsc190501.10217942': __('identifierSchemeCodeUnspsc190501.10217942'),
	'identifierSchemeCodeUnspsc190501.10217943': __('identifierSchemeCodeUnspsc190501.10217943'),
	'identifierSchemeCodeUnspsc190501.10217944': __('identifierSchemeCodeUnspsc190501.10217944'),
	'identifierSchemeCodeUnspsc190501.10217945': __('identifierSchemeCodeUnspsc190501.10217945'),
	'identifierSchemeCodeUnspsc190501.10217946': __('identifierSchemeCodeUnspsc190501.10217946'),
	'identifierSchemeCodeUnspsc190501.10217947': __('identifierSchemeCodeUnspsc190501.10217947'),
	'identifierSchemeCodeUnspsc190501.10217948': __('identifierSchemeCodeUnspsc190501.10217948'),
	'identifierSchemeCodeUnspsc190501.10217949': __('identifierSchemeCodeUnspsc190501.10217949'),
	'identifierSchemeCodeUnspsc190501.10217950': __('identifierSchemeCodeUnspsc190501.10217950'),
	'identifierSchemeCodeUnspsc190501.10217951': __('identifierSchemeCodeUnspsc190501.10217951'),
	'identifierSchemeCodeUnspsc190501.10217952': __('identifierSchemeCodeUnspsc190501.10217952'),
	'identifierSchemeCodeUnspsc190501.10217953': __('identifierSchemeCodeUnspsc190501.10217953'),
	'identifierSchemeCodeUnspsc190501.10217954': __('identifierSchemeCodeUnspsc190501.10217954'),
	'identifierSchemeCodeUnspsc190501.10217955': __('identifierSchemeCodeUnspsc190501.10217955'),
	'identifierSchemeCodeUnspsc190501.10217956': __('identifierSchemeCodeUnspsc190501.10217956'),
	'identifierSchemeCodeUnspsc190501.10217957': __('identifierSchemeCodeUnspsc190501.10217957'),
	'identifierSchemeCodeUnspsc190501.10217958': __('identifierSchemeCodeUnspsc190501.10217958'),
	'identifierSchemeCodeUnspsc190501.10217959': __('identifierSchemeCodeUnspsc190501.10217959'),
	'identifierSchemeCodeUnspsc190501.10217960': __('identifierSchemeCodeUnspsc190501.10217960'),
	'identifierSchemeCodeUnspsc190501.10217961': __('identifierSchemeCodeUnspsc190501.10217961'),
	'identifierSchemeCodeUnspsc190501.10217962': __('identifierSchemeCodeUnspsc190501.10217962'),
	'identifierSchemeCodeUnspsc190501.10217963': __('identifierSchemeCodeUnspsc190501.10217963'),
	'identifierSchemeCodeUnspsc190501.10217964': __('identifierSchemeCodeUnspsc190501.10217964'),
	'identifierSchemeCodeUnspsc190501.10217965': __('identifierSchemeCodeUnspsc190501.10217965'),
	'identifierSchemeCodeUnspsc190501.10217966': __('identifierSchemeCodeUnspsc190501.10217966'),
	'identifierSchemeCodeUnspsc190501.10217967': __('identifierSchemeCodeUnspsc190501.10217967'),
	'identifierSchemeCodeUnspsc190501.10217968': __('identifierSchemeCodeUnspsc190501.10217968'),
	'identifierSchemeCodeUnspsc190501.10217969': __('identifierSchemeCodeUnspsc190501.10217969'),
	'identifierSchemeCodeUnspsc190501.10217970': __('identifierSchemeCodeUnspsc190501.10217970'),
	'identifierSchemeCodeUnspsc190501.10217971': __('identifierSchemeCodeUnspsc190501.10217971'),
	'identifierSchemeCodeUnspsc190501.10217972': __('identifierSchemeCodeUnspsc190501.10217972'),
	'identifierSchemeCodeUnspsc190501.10218000': __('identifierSchemeCodeUnspsc190501.10218000'),
	'identifierSchemeCodeUnspsc190501.10218001': __('identifierSchemeCodeUnspsc190501.10218001'),
	'identifierSchemeCodeUnspsc190501.10218002': __('identifierSchemeCodeUnspsc190501.10218002'),
	'identifierSchemeCodeUnspsc190501.10218003': __('identifierSchemeCodeUnspsc190501.10218003'),
	'identifierSchemeCodeUnspsc190501.10218004': __('identifierSchemeCodeUnspsc190501.10218004'),
	'identifierSchemeCodeUnspsc190501.10218005': __('identifierSchemeCodeUnspsc190501.10218005'),
	'identifierSchemeCodeUnspsc190501.10218006': __('identifierSchemeCodeUnspsc190501.10218006'),
	'identifierSchemeCodeUnspsc190501.10218007': __('identifierSchemeCodeUnspsc190501.10218007'),
	'identifierSchemeCodeUnspsc190501.10218008': __('identifierSchemeCodeUnspsc190501.10218008'),
	'identifierSchemeCodeUnspsc190501.10218009': __('identifierSchemeCodeUnspsc190501.10218009'),
	'identifierSchemeCodeUnspsc190501.10218010': __('identifierSchemeCodeUnspsc190501.10218010'),
	'identifierSchemeCodeUnspsc190501.10218011': __('identifierSchemeCodeUnspsc190501.10218011'),
	'identifierSchemeCodeUnspsc190501.10218012': __('identifierSchemeCodeUnspsc190501.10218012'),
	'identifierSchemeCodeUnspsc190501.10218013': __('identifierSchemeCodeUnspsc190501.10218013'),
	'identifierSchemeCodeUnspsc190501.10218014': __('identifierSchemeCodeUnspsc190501.10218014'),
	'identifierSchemeCodeUnspsc190501.10218015': __('identifierSchemeCodeUnspsc190501.10218015'),
	'identifierSchemeCodeUnspsc190501.10218016': __('identifierSchemeCodeUnspsc190501.10218016'),
	'identifierSchemeCodeUnspsc190501.10218017': __('identifierSchemeCodeUnspsc190501.10218017'),
	'identifierSchemeCodeUnspsc190501.10218018': __('identifierSchemeCodeUnspsc190501.10218018'),
	'identifierSchemeCodeUnspsc190501.10218019': __('identifierSchemeCodeUnspsc190501.10218019'),
	'identifierSchemeCodeUnspsc190501.10218020': __('identifierSchemeCodeUnspsc190501.10218020'),
	'identifierSchemeCodeUnspsc190501.10218021': __('identifierSchemeCodeUnspsc190501.10218021'),
	'identifierSchemeCodeUnspsc190501.10218022': __('identifierSchemeCodeUnspsc190501.10218022'),
	'identifierSchemeCodeUnspsc190501.10218023': __('identifierSchemeCodeUnspsc190501.10218023'),
	'identifierSchemeCodeUnspsc190501.10218024': __('identifierSchemeCodeUnspsc190501.10218024'),
	'identifierSchemeCodeUnspsc190501.10218025': __('identifierSchemeCodeUnspsc190501.10218025'),
	'identifierSchemeCodeUnspsc190501.10218026': __('identifierSchemeCodeUnspsc190501.10218026'),
	'identifierSchemeCodeUnspsc190501.10218027': __('identifierSchemeCodeUnspsc190501.10218027'),
	'identifierSchemeCodeUnspsc190501.10218100': __('identifierSchemeCodeUnspsc190501.10218100'),
	'identifierSchemeCodeUnspsc190501.10218101': __('identifierSchemeCodeUnspsc190501.10218101'),
	'identifierSchemeCodeUnspsc190501.10218102': __('identifierSchemeCodeUnspsc190501.10218102'),
	'identifierSchemeCodeUnspsc190501.10218103': __('identifierSchemeCodeUnspsc190501.10218103'),
	'identifierSchemeCodeUnspsc190501.10218104': __('identifierSchemeCodeUnspsc190501.10218104'),
	'identifierSchemeCodeUnspsc190501.10218105': __('identifierSchemeCodeUnspsc190501.10218105'),
	'identifierSchemeCodeUnspsc190501.10218106': __('identifierSchemeCodeUnspsc190501.10218106'),
	'identifierSchemeCodeUnspsc190501.10218107': __('identifierSchemeCodeUnspsc190501.10218107'),
	'identifierSchemeCodeUnspsc190501.10218108': __('identifierSchemeCodeUnspsc190501.10218108'),
	'identifierSchemeCodeUnspsc190501.10218109': __('identifierSchemeCodeUnspsc190501.10218109'),
	'identifierSchemeCodeUnspsc190501.10218110': __('identifierSchemeCodeUnspsc190501.10218110'),
	'identifierSchemeCodeUnspsc190501.10218111': __('identifierSchemeCodeUnspsc190501.10218111'),
	'identifierSchemeCodeUnspsc190501.10218112': __('identifierSchemeCodeUnspsc190501.10218112'),
	'identifierSchemeCodeUnspsc190501.10218113': __('identifierSchemeCodeUnspsc190501.10218113'),
	'identifierSchemeCodeUnspsc190501.10218114': __('identifierSchemeCodeUnspsc190501.10218114'),
	'identifierSchemeCodeUnspsc190501.10218115': __('identifierSchemeCodeUnspsc190501.10218115'),
	'identifierSchemeCodeUnspsc190501.10218116': __('identifierSchemeCodeUnspsc190501.10218116'),
	'identifierSchemeCodeUnspsc190501.10218117': __('identifierSchemeCodeUnspsc190501.10218117'),
	'identifierSchemeCodeUnspsc190501.10218118': __('identifierSchemeCodeUnspsc190501.10218118'),
	'identifierSchemeCodeUnspsc190501.10218119': __('identifierSchemeCodeUnspsc190501.10218119'),
	'identifierSchemeCodeUnspsc190501.10218120': __('identifierSchemeCodeUnspsc190501.10218120'),
	'identifierSchemeCodeUnspsc190501.10218121': __('identifierSchemeCodeUnspsc190501.10218121'),
	'identifierSchemeCodeUnspsc190501.10218122': __('identifierSchemeCodeUnspsc190501.10218122'),
	'identifierSchemeCodeUnspsc190501.10218200': __('identifierSchemeCodeUnspsc190501.10218200'),
	'identifierSchemeCodeUnspsc190501.10218201': __('identifierSchemeCodeUnspsc190501.10218201'),
	'identifierSchemeCodeUnspsc190501.10218202': __('identifierSchemeCodeUnspsc190501.10218202'),
	'identifierSchemeCodeUnspsc190501.10218203': __('identifierSchemeCodeUnspsc190501.10218203'),
	'identifierSchemeCodeUnspsc190501.10218204': __('identifierSchemeCodeUnspsc190501.10218204'),
	'identifierSchemeCodeUnspsc190501.10218205': __('identifierSchemeCodeUnspsc190501.10218205'),
	'identifierSchemeCodeUnspsc190501.10218206': __('identifierSchemeCodeUnspsc190501.10218206'),
	'identifierSchemeCodeUnspsc190501.10218207': __('identifierSchemeCodeUnspsc190501.10218207'),
	'identifierSchemeCodeUnspsc190501.10218208': __('identifierSchemeCodeUnspsc190501.10218208'),
	'identifierSchemeCodeUnspsc190501.10218209': __('identifierSchemeCodeUnspsc190501.10218209'),
	'identifierSchemeCodeUnspsc190501.10218210': __('identifierSchemeCodeUnspsc190501.10218210'),
	'identifierSchemeCodeUnspsc190501.10218211': __('identifierSchemeCodeUnspsc190501.10218211'),
	'identifierSchemeCodeUnspsc190501.10218212': __('identifierSchemeCodeUnspsc190501.10218212'),
	'identifierSchemeCodeUnspsc190501.10218213': __('identifierSchemeCodeUnspsc190501.10218213'),
	'identifierSchemeCodeUnspsc190501.10218214': __('identifierSchemeCodeUnspsc190501.10218214'),
	'identifierSchemeCodeUnspsc190501.10218215': __('identifierSchemeCodeUnspsc190501.10218215'),
	'identifierSchemeCodeUnspsc190501.10218216': __('identifierSchemeCodeUnspsc190501.10218216'),
	'identifierSchemeCodeUnspsc190501.10218217': __('identifierSchemeCodeUnspsc190501.10218217'),
	'identifierSchemeCodeUnspsc190501.10218218': __('identifierSchemeCodeUnspsc190501.10218218'),
	'identifierSchemeCodeUnspsc190501.10218219': __('identifierSchemeCodeUnspsc190501.10218219'),
	'identifierSchemeCodeUnspsc190501.10218220': __('identifierSchemeCodeUnspsc190501.10218220'),
	'identifierSchemeCodeUnspsc190501.10218300': __('identifierSchemeCodeUnspsc190501.10218300'),
	'identifierSchemeCodeUnspsc190501.10218301': __('identifierSchemeCodeUnspsc190501.10218301'),
	'identifierSchemeCodeUnspsc190501.10218302': __('identifierSchemeCodeUnspsc190501.10218302'),
	'identifierSchemeCodeUnspsc190501.10218303': __('identifierSchemeCodeUnspsc190501.10218303'),
	'identifierSchemeCodeUnspsc190501.10218304': __('identifierSchemeCodeUnspsc190501.10218304'),
	'identifierSchemeCodeUnspsc190501.10218305': __('identifierSchemeCodeUnspsc190501.10218305'),
	'identifierSchemeCodeUnspsc190501.10218306': __('identifierSchemeCodeUnspsc190501.10218306'),
	'identifierSchemeCodeUnspsc190501.10218307': __('identifierSchemeCodeUnspsc190501.10218307'),
	'identifierSchemeCodeUnspsc190501.10218308': __('identifierSchemeCodeUnspsc190501.10218308'),
	'identifierSchemeCodeUnspsc190501.10218309': __('identifierSchemeCodeUnspsc190501.10218309'),
	'identifierSchemeCodeUnspsc190501.10218310': __('identifierSchemeCodeUnspsc190501.10218310'),
	'identifierSchemeCodeUnspsc190501.10218311': __('identifierSchemeCodeUnspsc190501.10218311'),
	'identifierSchemeCodeUnspsc190501.10218312': __('identifierSchemeCodeUnspsc190501.10218312'),
	'identifierSchemeCodeUnspsc190501.10218313': __('identifierSchemeCodeUnspsc190501.10218313'),
	'identifierSchemeCodeUnspsc190501.10218314': __('identifierSchemeCodeUnspsc190501.10218314'),
	'identifierSchemeCodeUnspsc190501.10218315': __('identifierSchemeCodeUnspsc190501.10218315'),
	'identifierSchemeCodeUnspsc190501.10218316': __('identifierSchemeCodeUnspsc190501.10218316'),
	'identifierSchemeCodeUnspsc190501.10218317': __('identifierSchemeCodeUnspsc190501.10218317'),
	'identifierSchemeCodeUnspsc190501.10218318': __('identifierSchemeCodeUnspsc190501.10218318'),
	'identifierSchemeCodeUnspsc190501.10218319': __('identifierSchemeCodeUnspsc190501.10218319'),
	'identifierSchemeCodeUnspsc190501.10218320': __('identifierSchemeCodeUnspsc190501.10218320'),
	'identifierSchemeCodeUnspsc190501.10218321': __('identifierSchemeCodeUnspsc190501.10218321'),
	'identifierSchemeCodeUnspsc190501.10218322': __('identifierSchemeCodeUnspsc190501.10218322'),
	'identifierSchemeCodeUnspsc190501.10218323': __('identifierSchemeCodeUnspsc190501.10218323'),
	'identifierSchemeCodeUnspsc190501.10218324': __('identifierSchemeCodeUnspsc190501.10218324'),
	'identifierSchemeCodeUnspsc190501.10218325': __('identifierSchemeCodeUnspsc190501.10218325'),
	'identifierSchemeCodeUnspsc190501.10218326': __('identifierSchemeCodeUnspsc190501.10218326'),
	'identifierSchemeCodeUnspsc190501.10218327': __('identifierSchemeCodeUnspsc190501.10218327'),
	'identifierSchemeCodeUnspsc190501.10218328': __('identifierSchemeCodeUnspsc190501.10218328'),
	'identifierSchemeCodeUnspsc190501.10218329': __('identifierSchemeCodeUnspsc190501.10218329'),
	'identifierSchemeCodeUnspsc190501.10218330': __('identifierSchemeCodeUnspsc190501.10218330'),
	'identifierSchemeCodeUnspsc190501.10218331': __('identifierSchemeCodeUnspsc190501.10218331'),
	'identifierSchemeCodeUnspsc190501.10218332': __('identifierSchemeCodeUnspsc190501.10218332'),
	'identifierSchemeCodeUnspsc190501.10218333': __('identifierSchemeCodeUnspsc190501.10218333'),
	'identifierSchemeCodeUnspsc190501.10218334': __('identifierSchemeCodeUnspsc190501.10218334'),
	'identifierSchemeCodeUnspsc190501.10218335': __('identifierSchemeCodeUnspsc190501.10218335'),
	'identifierSchemeCodeUnspsc190501.10218336': __('identifierSchemeCodeUnspsc190501.10218336'),
	'identifierSchemeCodeUnspsc190501.10218337': __('identifierSchemeCodeUnspsc190501.10218337'),
	'identifierSchemeCodeUnspsc190501.10218338': __('identifierSchemeCodeUnspsc190501.10218338'),
	'identifierSchemeCodeUnspsc190501.10218339': __('identifierSchemeCodeUnspsc190501.10218339'),
	'identifierSchemeCodeUnspsc190501.10218340': __('identifierSchemeCodeUnspsc190501.10218340'),
	'identifierSchemeCodeUnspsc190501.10218341': __('identifierSchemeCodeUnspsc190501.10218341'),
	'identifierSchemeCodeUnspsc190501.10218342': __('identifierSchemeCodeUnspsc190501.10218342'),
	'identifierSchemeCodeUnspsc190501.10218343': __('identifierSchemeCodeUnspsc190501.10218343'),
	'identifierSchemeCodeUnspsc190501.10218344': __('identifierSchemeCodeUnspsc190501.10218344'),
	'identifierSchemeCodeUnspsc190501.10218345': __('identifierSchemeCodeUnspsc190501.10218345'),
	'identifierSchemeCodeUnspsc190501.10218346': __('identifierSchemeCodeUnspsc190501.10218346'),
	'identifierSchemeCodeUnspsc190501.10218347': __('identifierSchemeCodeUnspsc190501.10218347'),
	'identifierSchemeCodeUnspsc190501.10218348': __('identifierSchemeCodeUnspsc190501.10218348'),
	'identifierSchemeCodeUnspsc190501.10218349': __('identifierSchemeCodeUnspsc190501.10218349'),
	'identifierSchemeCodeUnspsc190501.10218350': __('identifierSchemeCodeUnspsc190501.10218350'),
	'identifierSchemeCodeUnspsc190501.10220000': __('identifierSchemeCodeUnspsc190501.10220000'),
	'identifierSchemeCodeUnspsc190501.10221500': __('identifierSchemeCodeUnspsc190501.10221500'),
	'identifierSchemeCodeUnspsc190501.10221501': __('identifierSchemeCodeUnspsc190501.10221501'),
	'identifierSchemeCodeUnspsc190501.10221502': __('identifierSchemeCodeUnspsc190501.10221502'),
	'identifierSchemeCodeUnspsc190501.10221600': __('identifierSchemeCodeUnspsc190501.10221600'),
	'identifierSchemeCodeUnspsc190501.10221601': __('identifierSchemeCodeUnspsc190501.10221601'),
	'identifierSchemeCodeUnspsc190501.10221602': __('identifierSchemeCodeUnspsc190501.10221602'),
	'identifierSchemeCodeUnspsc190501.10221700': __('identifierSchemeCodeUnspsc190501.10221700'),
	'identifierSchemeCodeUnspsc190501.10221701': __('identifierSchemeCodeUnspsc190501.10221701'),
	'identifierSchemeCodeUnspsc190501.10221702': __('identifierSchemeCodeUnspsc190501.10221702'),
	'identifierSchemeCodeUnspsc190501.10221703': __('identifierSchemeCodeUnspsc190501.10221703'),
	'identifierSchemeCodeUnspsc190501.10221704': __('identifierSchemeCodeUnspsc190501.10221704'),
	'identifierSchemeCodeUnspsc190501.10221800': __('identifierSchemeCodeUnspsc190501.10221800'),
	'identifierSchemeCodeUnspsc190501.10221801': __('identifierSchemeCodeUnspsc190501.10221801'),
	'identifierSchemeCodeUnspsc190501.10221802': __('identifierSchemeCodeUnspsc190501.10221802'),
	'identifierSchemeCodeUnspsc190501.10221900': __('identifierSchemeCodeUnspsc190501.10221900'),
	'identifierSchemeCodeUnspsc190501.10221901': __('identifierSchemeCodeUnspsc190501.10221901'),
	'identifierSchemeCodeUnspsc190501.10221902': __('identifierSchemeCodeUnspsc190501.10221902'),
	'identifierSchemeCodeUnspsc190501.10222000': __('identifierSchemeCodeUnspsc190501.10222000'),
	'identifierSchemeCodeUnspsc190501.10222001': __('identifierSchemeCodeUnspsc190501.10222001'),
	'identifierSchemeCodeUnspsc190501.10222002': __('identifierSchemeCodeUnspsc190501.10222002'),
	'identifierSchemeCodeUnspsc190501.10222003': __('identifierSchemeCodeUnspsc190501.10222003'),
	'identifierSchemeCodeUnspsc190501.10222100': __('identifierSchemeCodeUnspsc190501.10222100'),
	'identifierSchemeCodeUnspsc190501.10222101': __('identifierSchemeCodeUnspsc190501.10222101'),
	'identifierSchemeCodeUnspsc190501.10222102': __('identifierSchemeCodeUnspsc190501.10222102'),
	'identifierSchemeCodeUnspsc190501.10222200': __('identifierSchemeCodeUnspsc190501.10222200'),
	'identifierSchemeCodeUnspsc190501.10222201': __('identifierSchemeCodeUnspsc190501.10222201'),
	'identifierSchemeCodeUnspsc190501.10222202': __('identifierSchemeCodeUnspsc190501.10222202'),
	'identifierSchemeCodeUnspsc190501.10222203': __('identifierSchemeCodeUnspsc190501.10222203'),
	'identifierSchemeCodeUnspsc190501.10222300': __('identifierSchemeCodeUnspsc190501.10222300'),
	'identifierSchemeCodeUnspsc190501.10222301': __('identifierSchemeCodeUnspsc190501.10222301'),
	'identifierSchemeCodeUnspsc190501.10222302': __('identifierSchemeCodeUnspsc190501.10222302'),
	'identifierSchemeCodeUnspsc190501.10222400': __('identifierSchemeCodeUnspsc190501.10222400'),
	'identifierSchemeCodeUnspsc190501.10222401': __('identifierSchemeCodeUnspsc190501.10222401'),
	'identifierSchemeCodeUnspsc190501.10222402': __('identifierSchemeCodeUnspsc190501.10222402'),
	'identifierSchemeCodeUnspsc190501.10222500': __('identifierSchemeCodeUnspsc190501.10222500'),
	'identifierSchemeCodeUnspsc190501.10222501': __('identifierSchemeCodeUnspsc190501.10222501'),
	'identifierSchemeCodeUnspsc190501.10222502': __('identifierSchemeCodeUnspsc190501.10222502'),
	'identifierSchemeCodeUnspsc190501.10222600': __('identifierSchemeCodeUnspsc190501.10222600'),
	'identifierSchemeCodeUnspsc190501.10222601': __('identifierSchemeCodeUnspsc190501.10222601'),
	'identifierSchemeCodeUnspsc190501.10222602': __('identifierSchemeCodeUnspsc190501.10222602'),
	'identifierSchemeCodeUnspsc190501.10222603': __('identifierSchemeCodeUnspsc190501.10222603'),
	'identifierSchemeCodeUnspsc190501.10222700': __('identifierSchemeCodeUnspsc190501.10222700'),
	'identifierSchemeCodeUnspsc190501.10222701': __('identifierSchemeCodeUnspsc190501.10222701'),
	'identifierSchemeCodeUnspsc190501.10222702': __('identifierSchemeCodeUnspsc190501.10222702'),
	'identifierSchemeCodeUnspsc190501.10222703': __('identifierSchemeCodeUnspsc190501.10222703'),
	'identifierSchemeCodeUnspsc190501.10222800': __('identifierSchemeCodeUnspsc190501.10222800'),
	'identifierSchemeCodeUnspsc190501.10222801': __('identifierSchemeCodeUnspsc190501.10222801'),
	'identifierSchemeCodeUnspsc190501.10222802': __('identifierSchemeCodeUnspsc190501.10222802'),
	'identifierSchemeCodeUnspsc190501.10222803': __('identifierSchemeCodeUnspsc190501.10222803'),
	'identifierSchemeCodeUnspsc190501.10222900': __('identifierSchemeCodeUnspsc190501.10222900'),
	'identifierSchemeCodeUnspsc190501.10222901': __('identifierSchemeCodeUnspsc190501.10222901'),
	'identifierSchemeCodeUnspsc190501.10222902': __('identifierSchemeCodeUnspsc190501.10222902'),
	'identifierSchemeCodeUnspsc190501.10223000': __('identifierSchemeCodeUnspsc190501.10223000'),
	'identifierSchemeCodeUnspsc190501.10223001': __('identifierSchemeCodeUnspsc190501.10223001'),
	'identifierSchemeCodeUnspsc190501.10223002': __('identifierSchemeCodeUnspsc190501.10223002'),
	'identifierSchemeCodeUnspsc190501.10223003': __('identifierSchemeCodeUnspsc190501.10223003'),
	'identifierSchemeCodeUnspsc190501.10223100': __('identifierSchemeCodeUnspsc190501.10223100'),
	'identifierSchemeCodeUnspsc190501.10223101': __('identifierSchemeCodeUnspsc190501.10223101'),
	'identifierSchemeCodeUnspsc190501.10223102': __('identifierSchemeCodeUnspsc190501.10223102'),
	'identifierSchemeCodeUnspsc190501.10223103': __('identifierSchemeCodeUnspsc190501.10223103'),
	'identifierSchemeCodeUnspsc190501.10223200': __('identifierSchemeCodeUnspsc190501.10223200'),
	'identifierSchemeCodeUnspsc190501.10223201': __('identifierSchemeCodeUnspsc190501.10223201'),
	'identifierSchemeCodeUnspsc190501.10223202': __('identifierSchemeCodeUnspsc190501.10223202'),
	'identifierSchemeCodeUnspsc190501.10223300': __('identifierSchemeCodeUnspsc190501.10223300'),
	'identifierSchemeCodeUnspsc190501.10223301': __('identifierSchemeCodeUnspsc190501.10223301'),
	'identifierSchemeCodeUnspsc190501.10223302': __('identifierSchemeCodeUnspsc190501.10223302'),
	'identifierSchemeCodeUnspsc190501.10223400': __('identifierSchemeCodeUnspsc190501.10223400'),
	'identifierSchemeCodeUnspsc190501.10223401': __('identifierSchemeCodeUnspsc190501.10223401'),
	'identifierSchemeCodeUnspsc190501.10223402': __('identifierSchemeCodeUnspsc190501.10223402'),
	'identifierSchemeCodeUnspsc190501.10223403': __('identifierSchemeCodeUnspsc190501.10223403'),
	'identifierSchemeCodeUnspsc190501.10223500': __('identifierSchemeCodeUnspsc190501.10223500'),
	'identifierSchemeCodeUnspsc190501.10223501': __('identifierSchemeCodeUnspsc190501.10223501'),
	'identifierSchemeCodeUnspsc190501.10223502': __('identifierSchemeCodeUnspsc190501.10223502'),
	'identifierSchemeCodeUnspsc190501.10223503': __('identifierSchemeCodeUnspsc190501.10223503'),
	'identifierSchemeCodeUnspsc190501.10223504': __('identifierSchemeCodeUnspsc190501.10223504'),
	'identifierSchemeCodeUnspsc190501.10223600': __('identifierSchemeCodeUnspsc190501.10223600'),
	'identifierSchemeCodeUnspsc190501.10223601': __('identifierSchemeCodeUnspsc190501.10223601'),
	'identifierSchemeCodeUnspsc190501.10223602': __('identifierSchemeCodeUnspsc190501.10223602'),
	'identifierSchemeCodeUnspsc190501.10223700': __('identifierSchemeCodeUnspsc190501.10223700'),
	'identifierSchemeCodeUnspsc190501.10223701': __('identifierSchemeCodeUnspsc190501.10223701'),
	'identifierSchemeCodeUnspsc190501.10223702': __('identifierSchemeCodeUnspsc190501.10223702'),
	'identifierSchemeCodeUnspsc190501.10223800': __('identifierSchemeCodeUnspsc190501.10223800'),
	'identifierSchemeCodeUnspsc190501.10223801': __('identifierSchemeCodeUnspsc190501.10223801'),
	'identifierSchemeCodeUnspsc190501.10223802': __('identifierSchemeCodeUnspsc190501.10223802'),
	'identifierSchemeCodeUnspsc190501.10223803': __('identifierSchemeCodeUnspsc190501.10223803'),
	'identifierSchemeCodeUnspsc190501.10223804': __('identifierSchemeCodeUnspsc190501.10223804'),
	'identifierSchemeCodeUnspsc190501.10223900': __('identifierSchemeCodeUnspsc190501.10223900'),
	'identifierSchemeCodeUnspsc190501.10223901': __('identifierSchemeCodeUnspsc190501.10223901'),
	'identifierSchemeCodeUnspsc190501.10223902': __('identifierSchemeCodeUnspsc190501.10223902'),
	'identifierSchemeCodeUnspsc190501.10224000': __('identifierSchemeCodeUnspsc190501.10224000'),
	'identifierSchemeCodeUnspsc190501.10224001': __('identifierSchemeCodeUnspsc190501.10224001'),
	'identifierSchemeCodeUnspsc190501.10224002': __('identifierSchemeCodeUnspsc190501.10224002'),
	'identifierSchemeCodeUnspsc190501.10224100': __('identifierSchemeCodeUnspsc190501.10224100'),
	'identifierSchemeCodeUnspsc190501.10224101': __('identifierSchemeCodeUnspsc190501.10224101'),
	'identifierSchemeCodeUnspsc190501.10224102': __('identifierSchemeCodeUnspsc190501.10224102'),
	'identifierSchemeCodeUnspsc190501.10224200': __('identifierSchemeCodeUnspsc190501.10224200'),
	'identifierSchemeCodeUnspsc190501.10224201': __('identifierSchemeCodeUnspsc190501.10224201'),
	'identifierSchemeCodeUnspsc190501.10224202': __('identifierSchemeCodeUnspsc190501.10224202'),
	'identifierSchemeCodeUnspsc190501.10224300': __('identifierSchemeCodeUnspsc190501.10224300'),
	'identifierSchemeCodeUnspsc190501.10224301': __('identifierSchemeCodeUnspsc190501.10224301'),
	'identifierSchemeCodeUnspsc190501.10224302': __('identifierSchemeCodeUnspsc190501.10224302'),
	'identifierSchemeCodeUnspsc190501.10224303': __('identifierSchemeCodeUnspsc190501.10224303'),
	'identifierSchemeCodeUnspsc190501.10224400': __('identifierSchemeCodeUnspsc190501.10224400'),
	'identifierSchemeCodeUnspsc190501.10224401': __('identifierSchemeCodeUnspsc190501.10224401'),
	'identifierSchemeCodeUnspsc190501.10224402': __('identifierSchemeCodeUnspsc190501.10224402'),
	'identifierSchemeCodeUnspsc190501.10224500': __('identifierSchemeCodeUnspsc190501.10224500'),
	'identifierSchemeCodeUnspsc190501.10224501': __('identifierSchemeCodeUnspsc190501.10224501'),
	'identifierSchemeCodeUnspsc190501.10224502': __('identifierSchemeCodeUnspsc190501.10224502'),
	'identifierSchemeCodeUnspsc190501.10224600': __('identifierSchemeCodeUnspsc190501.10224600'),
	'identifierSchemeCodeUnspsc190501.10224601': __('identifierSchemeCodeUnspsc190501.10224601'),
	'identifierSchemeCodeUnspsc190501.10224602': __('identifierSchemeCodeUnspsc190501.10224602'),
	'identifierSchemeCodeUnspsc190501.10224700': __('identifierSchemeCodeUnspsc190501.10224700'),
	'identifierSchemeCodeUnspsc190501.10224701': __('identifierSchemeCodeUnspsc190501.10224701'),
	'identifierSchemeCodeUnspsc190501.10224702': __('identifierSchemeCodeUnspsc190501.10224702'),
	'identifierSchemeCodeUnspsc190501.10224703': __('identifierSchemeCodeUnspsc190501.10224703'),
	'identifierSchemeCodeUnspsc190501.10224800': __('identifierSchemeCodeUnspsc190501.10224800'),
	'identifierSchemeCodeUnspsc190501.10224801': __('identifierSchemeCodeUnspsc190501.10224801'),
	'identifierSchemeCodeUnspsc190501.10224802': __('identifierSchemeCodeUnspsc190501.10224802'),
	'identifierSchemeCodeUnspsc190501.10224803': __('identifierSchemeCodeUnspsc190501.10224803'),
	'identifierSchemeCodeUnspsc190501.10224804': __('identifierSchemeCodeUnspsc190501.10224804'),
	'identifierSchemeCodeUnspsc190501.10224900': __('identifierSchemeCodeUnspsc190501.10224900'),
	'identifierSchemeCodeUnspsc190501.10224901': __('identifierSchemeCodeUnspsc190501.10224901'),
	'identifierSchemeCodeUnspsc190501.10224902': __('identifierSchemeCodeUnspsc190501.10224902'),
	'identifierSchemeCodeUnspsc190501.10225000': __('identifierSchemeCodeUnspsc190501.10225000'),
	'identifierSchemeCodeUnspsc190501.10225001': __('identifierSchemeCodeUnspsc190501.10225001'),
	'identifierSchemeCodeUnspsc190501.10225002': __('identifierSchemeCodeUnspsc190501.10225002'),
	'identifierSchemeCodeUnspsc190501.10225003': __('identifierSchemeCodeUnspsc190501.10225003'),
	'identifierSchemeCodeUnspsc190501.10225004': __('identifierSchemeCodeUnspsc190501.10225004'),
	'identifierSchemeCodeUnspsc190501.10225100': __('identifierSchemeCodeUnspsc190501.10225100'),
	'identifierSchemeCodeUnspsc190501.10225101': __('identifierSchemeCodeUnspsc190501.10225101'),
	'identifierSchemeCodeUnspsc190501.10225102': __('identifierSchemeCodeUnspsc190501.10225102'),
	'identifierSchemeCodeUnspsc190501.10225103': __('identifierSchemeCodeUnspsc190501.10225103'),
	'identifierSchemeCodeUnspsc190501.10225104': __('identifierSchemeCodeUnspsc190501.10225104'),
	'identifierSchemeCodeUnspsc190501.10225200': __('identifierSchemeCodeUnspsc190501.10225200'),
	'identifierSchemeCodeUnspsc190501.10225201': __('identifierSchemeCodeUnspsc190501.10225201'),
	'identifierSchemeCodeUnspsc190501.10225202': __('identifierSchemeCodeUnspsc190501.10225202'),
	'identifierSchemeCodeUnspsc190501.10225300': __('identifierSchemeCodeUnspsc190501.10225300'),
	'identifierSchemeCodeUnspsc190501.10225301': __('identifierSchemeCodeUnspsc190501.10225301'),
	'identifierSchemeCodeUnspsc190501.10225302': __('identifierSchemeCodeUnspsc190501.10225302'),
	'identifierSchemeCodeUnspsc190501.10225303': __('identifierSchemeCodeUnspsc190501.10225303'),
	'identifierSchemeCodeUnspsc190501.10225400': __('identifierSchemeCodeUnspsc190501.10225400'),
	'identifierSchemeCodeUnspsc190501.10225401': __('identifierSchemeCodeUnspsc190501.10225401'),
	'identifierSchemeCodeUnspsc190501.10225402': __('identifierSchemeCodeUnspsc190501.10225402'),
	'identifierSchemeCodeUnspsc190501.10225403': __('identifierSchemeCodeUnspsc190501.10225403'),
	'identifierSchemeCodeUnspsc190501.10225404': __('identifierSchemeCodeUnspsc190501.10225404'),
	'identifierSchemeCodeUnspsc190501.10225500': __('identifierSchemeCodeUnspsc190501.10225500'),
	'identifierSchemeCodeUnspsc190501.10225501': __('identifierSchemeCodeUnspsc190501.10225501'),
	'identifierSchemeCodeUnspsc190501.10225502': __('identifierSchemeCodeUnspsc190501.10225502'),
	'identifierSchemeCodeUnspsc190501.10225503': __('identifierSchemeCodeUnspsc190501.10225503'),
	'identifierSchemeCodeUnspsc190501.10225600': __('identifierSchemeCodeUnspsc190501.10225600'),
	'identifierSchemeCodeUnspsc190501.10225601': __('identifierSchemeCodeUnspsc190501.10225601'),
	'identifierSchemeCodeUnspsc190501.10225602': __('identifierSchemeCodeUnspsc190501.10225602'),
	'identifierSchemeCodeUnspsc190501.10225700': __('identifierSchemeCodeUnspsc190501.10225700'),
	'identifierSchemeCodeUnspsc190501.10225701': __('identifierSchemeCodeUnspsc190501.10225701'),
	'identifierSchemeCodeUnspsc190501.10225800': __('identifierSchemeCodeUnspsc190501.10225800'),
	'identifierSchemeCodeUnspsc190501.10225801': __('identifierSchemeCodeUnspsc190501.10225801'),
	'identifierSchemeCodeUnspsc190501.10225802': __('identifierSchemeCodeUnspsc190501.10225802'),
	'identifierSchemeCodeUnspsc190501.10225803': __('identifierSchemeCodeUnspsc190501.10225803'),
	'identifierSchemeCodeUnspsc190501.10225804': __('identifierSchemeCodeUnspsc190501.10225804'),
	'identifierSchemeCodeUnspsc190501.10225900': __('identifierSchemeCodeUnspsc190501.10225900'),
	'identifierSchemeCodeUnspsc190501.10225901': __('identifierSchemeCodeUnspsc190501.10225901'),
	'identifierSchemeCodeUnspsc190501.10225902': __('identifierSchemeCodeUnspsc190501.10225902'),
	'identifierSchemeCodeUnspsc190501.10225903': __('identifierSchemeCodeUnspsc190501.10225903'),
	'identifierSchemeCodeUnspsc190501.10225904': __('identifierSchemeCodeUnspsc190501.10225904'),
	'identifierSchemeCodeUnspsc190501.10226000': __('identifierSchemeCodeUnspsc190501.10226000'),
	'identifierSchemeCodeUnspsc190501.10226001': __('identifierSchemeCodeUnspsc190501.10226001'),
	'identifierSchemeCodeUnspsc190501.10226002': __('identifierSchemeCodeUnspsc190501.10226002'),
	'identifierSchemeCodeUnspsc190501.10226003': __('identifierSchemeCodeUnspsc190501.10226003'),
	'identifierSchemeCodeUnspsc190501.10226004': __('identifierSchemeCodeUnspsc190501.10226004'),
	'identifierSchemeCodeUnspsc190501.10226005': __('identifierSchemeCodeUnspsc190501.10226005'),
	'identifierSchemeCodeUnspsc190501.10226006': __('identifierSchemeCodeUnspsc190501.10226006'),
	'identifierSchemeCodeUnspsc190501.10226007': __('identifierSchemeCodeUnspsc190501.10226007'),
	'identifierSchemeCodeUnspsc190501.10226008': __('identifierSchemeCodeUnspsc190501.10226008'),
	'identifierSchemeCodeUnspsc190501.10226009': __('identifierSchemeCodeUnspsc190501.10226009'),
	'identifierSchemeCodeUnspsc190501.10226010': __('identifierSchemeCodeUnspsc190501.10226010'),
	'identifierSchemeCodeUnspsc190501.10226011': __('identifierSchemeCodeUnspsc190501.10226011'),
	'identifierSchemeCodeUnspsc190501.10226012': __('identifierSchemeCodeUnspsc190501.10226012'),
	'identifierSchemeCodeUnspsc190501.10226013': __('identifierSchemeCodeUnspsc190501.10226013'),
	'identifierSchemeCodeUnspsc190501.10226014': __('identifierSchemeCodeUnspsc190501.10226014'),
	'identifierSchemeCodeUnspsc190501.10226015': __('identifierSchemeCodeUnspsc190501.10226015'),
	'identifierSchemeCodeUnspsc190501.10226016': __('identifierSchemeCodeUnspsc190501.10226016'),
	'identifierSchemeCodeUnspsc190501.10226017': __('identifierSchemeCodeUnspsc190501.10226017'),
	'identifierSchemeCodeUnspsc190501.10226018': __('identifierSchemeCodeUnspsc190501.10226018'),
	'identifierSchemeCodeUnspsc190501.10226019': __('identifierSchemeCodeUnspsc190501.10226019'),
	'identifierSchemeCodeUnspsc190501.10226020': __('identifierSchemeCodeUnspsc190501.10226020'),
	'identifierSchemeCodeUnspsc190501.10226021': __('identifierSchemeCodeUnspsc190501.10226021'),
	'identifierSchemeCodeUnspsc190501.10226022': __('identifierSchemeCodeUnspsc190501.10226022'),
	'identifierSchemeCodeUnspsc190501.10226023': __('identifierSchemeCodeUnspsc190501.10226023'),
	'identifierSchemeCodeUnspsc190501.10226024': __('identifierSchemeCodeUnspsc190501.10226024'),
	'identifierSchemeCodeUnspsc190501.10226025': __('identifierSchemeCodeUnspsc190501.10226025'),
	'identifierSchemeCodeUnspsc190501.10226026': __('identifierSchemeCodeUnspsc190501.10226026'),
	'identifierSchemeCodeUnspsc190501.10226027': __('identifierSchemeCodeUnspsc190501.10226027'),
	'identifierSchemeCodeUnspsc190501.10226028': __('identifierSchemeCodeUnspsc190501.10226028'),
	'identifierSchemeCodeUnspsc190501.10226029': __('identifierSchemeCodeUnspsc190501.10226029'),
	'identifierSchemeCodeUnspsc190501.10226030': __('identifierSchemeCodeUnspsc190501.10226030'),
	'identifierSchemeCodeUnspsc190501.10226031': __('identifierSchemeCodeUnspsc190501.10226031'),
	'identifierSchemeCodeUnspsc190501.10226032': __('identifierSchemeCodeUnspsc190501.10226032'),
	'identifierSchemeCodeUnspsc190501.10226033': __('identifierSchemeCodeUnspsc190501.10226033'),
	'identifierSchemeCodeUnspsc190501.10226034': __('identifierSchemeCodeUnspsc190501.10226034'),
	'identifierSchemeCodeUnspsc190501.10226035': __('identifierSchemeCodeUnspsc190501.10226035'),
	'identifierSchemeCodeUnspsc190501.10226036': __('identifierSchemeCodeUnspsc190501.10226036'),
	'identifierSchemeCodeUnspsc190501.10226037': __('identifierSchemeCodeUnspsc190501.10226037'),
	'identifierSchemeCodeUnspsc190501.10226038': __('identifierSchemeCodeUnspsc190501.10226038'),
	'identifierSchemeCodeUnspsc190501.10226039': __('identifierSchemeCodeUnspsc190501.10226039'),
	'identifierSchemeCodeUnspsc190501.10226040': __('identifierSchemeCodeUnspsc190501.10226040'),
	'identifierSchemeCodeUnspsc190501.10226041': __('identifierSchemeCodeUnspsc190501.10226041'),
	'identifierSchemeCodeUnspsc190501.10226042': __('identifierSchemeCodeUnspsc190501.10226042'),
	'identifierSchemeCodeUnspsc190501.10226043': __('identifierSchemeCodeUnspsc190501.10226043'),
	'identifierSchemeCodeUnspsc190501.10226044': __('identifierSchemeCodeUnspsc190501.10226044'),
	'identifierSchemeCodeUnspsc190501.10226045': __('identifierSchemeCodeUnspsc190501.10226045'),
	'identifierSchemeCodeUnspsc190501.10226046': __('identifierSchemeCodeUnspsc190501.10226046'),
	'identifierSchemeCodeUnspsc190501.10226047': __('identifierSchemeCodeUnspsc190501.10226047'),
	'identifierSchemeCodeUnspsc190501.10226048': __('identifierSchemeCodeUnspsc190501.10226048'),
	'identifierSchemeCodeUnspsc190501.10226049': __('identifierSchemeCodeUnspsc190501.10226049'),
	'identifierSchemeCodeUnspsc190501.10226050': __('identifierSchemeCodeUnspsc190501.10226050'),
	'identifierSchemeCodeUnspsc190501.10226051': __('identifierSchemeCodeUnspsc190501.10226051'),
	'identifierSchemeCodeUnspsc190501.10226052': __('identifierSchemeCodeUnspsc190501.10226052'),
	'identifierSchemeCodeUnspsc190501.10226053': __('identifierSchemeCodeUnspsc190501.10226053'),
	'identifierSchemeCodeUnspsc190501.10226054': __('identifierSchemeCodeUnspsc190501.10226054'),
	'identifierSchemeCodeUnspsc190501.10226055': __('identifierSchemeCodeUnspsc190501.10226055'),
	'identifierSchemeCodeUnspsc190501.10226056': __('identifierSchemeCodeUnspsc190501.10226056'),
	'identifierSchemeCodeUnspsc190501.10226057': __('identifierSchemeCodeUnspsc190501.10226057'),
	'identifierSchemeCodeUnspsc190501.10226058': __('identifierSchemeCodeUnspsc190501.10226058'),
	'identifierSchemeCodeUnspsc190501.10226059': __('identifierSchemeCodeUnspsc190501.10226059'),
	'identifierSchemeCodeUnspsc190501.10226060': __('identifierSchemeCodeUnspsc190501.10226060'),
	'identifierSchemeCodeUnspsc190501.10226061': __('identifierSchemeCodeUnspsc190501.10226061'),
	'identifierSchemeCodeUnspsc190501.10226062': __('identifierSchemeCodeUnspsc190501.10226062'),
	'identifierSchemeCodeUnspsc190501.10226063': __('identifierSchemeCodeUnspsc190501.10226063'),
	'identifierSchemeCodeUnspsc190501.10226064': __('identifierSchemeCodeUnspsc190501.10226064'),
	'identifierSchemeCodeUnspsc190501.10226065': __('identifierSchemeCodeUnspsc190501.10226065'),
	'identifierSchemeCodeUnspsc190501.10226066': __('identifierSchemeCodeUnspsc190501.10226066'),
	'identifierSchemeCodeUnspsc190501.10226067': __('identifierSchemeCodeUnspsc190501.10226067'),
	'identifierSchemeCodeUnspsc190501.10226068': __('identifierSchemeCodeUnspsc190501.10226068'),
	'identifierSchemeCodeUnspsc190501.10226069': __('identifierSchemeCodeUnspsc190501.10226069'),
	'identifierSchemeCodeUnspsc190501.10226070': __('identifierSchemeCodeUnspsc190501.10226070'),
	'identifierSchemeCodeUnspsc190501.10226071': __('identifierSchemeCodeUnspsc190501.10226071'),
	'identifierSchemeCodeUnspsc190501.10226072': __('identifierSchemeCodeUnspsc190501.10226072'),
	'identifierSchemeCodeUnspsc190501.10226073': __('identifierSchemeCodeUnspsc190501.10226073'),
	'identifierSchemeCodeUnspsc190501.10226074': __('identifierSchemeCodeUnspsc190501.10226074'),
	'identifierSchemeCodeUnspsc190501.10226075': __('identifierSchemeCodeUnspsc190501.10226075'),
	'identifierSchemeCodeUnspsc190501.10226076': __('identifierSchemeCodeUnspsc190501.10226076'),
	'identifierSchemeCodeUnspsc190501.10226077': __('identifierSchemeCodeUnspsc190501.10226077'),
	'identifierSchemeCodeUnspsc190501.10226078': __('identifierSchemeCodeUnspsc190501.10226078'),
	'identifierSchemeCodeUnspsc190501.10226079': __('identifierSchemeCodeUnspsc190501.10226079'),
	'identifierSchemeCodeUnspsc190501.10226080': __('identifierSchemeCodeUnspsc190501.10226080'),
	'identifierSchemeCodeUnspsc190501.10226081': __('identifierSchemeCodeUnspsc190501.10226081'),
	'identifierSchemeCodeUnspsc190501.10226082': __('identifierSchemeCodeUnspsc190501.10226082'),
	'identifierSchemeCodeUnspsc190501.10226084': __('identifierSchemeCodeUnspsc190501.10226084'),
	'identifierSchemeCodeUnspsc190501.10226085': __('identifierSchemeCodeUnspsc190501.10226085'),
	'identifierSchemeCodeUnspsc190501.10226086': __('identifierSchemeCodeUnspsc190501.10226086'),
	'identifierSchemeCodeUnspsc190501.10226100': __('identifierSchemeCodeUnspsc190501.10226100'),
	'identifierSchemeCodeUnspsc190501.10226101': __('identifierSchemeCodeUnspsc190501.10226101'),
	'identifierSchemeCodeUnspsc190501.10226102': __('identifierSchemeCodeUnspsc190501.10226102'),
	'identifierSchemeCodeUnspsc190501.10226200': __('identifierSchemeCodeUnspsc190501.10226200'),
	'identifierSchemeCodeUnspsc190501.10226201': __('identifierSchemeCodeUnspsc190501.10226201'),
	'identifierSchemeCodeUnspsc190501.10226202': __('identifierSchemeCodeUnspsc190501.10226202'),
	'identifierSchemeCodeUnspsc190501.10226203': __('identifierSchemeCodeUnspsc190501.10226203'),
	'identifierSchemeCodeUnspsc190501.10226204': __('identifierSchemeCodeUnspsc190501.10226204'),
	'identifierSchemeCodeUnspsc190501.10226300': __('identifierSchemeCodeUnspsc190501.10226300'),
	'identifierSchemeCodeUnspsc190501.10226301': __('identifierSchemeCodeUnspsc190501.10226301'),
	'identifierSchemeCodeUnspsc190501.10226302': __('identifierSchemeCodeUnspsc190501.10226302'),
	'identifierSchemeCodeUnspsc190501.10226303': __('identifierSchemeCodeUnspsc190501.10226303'),
	'identifierSchemeCodeUnspsc190501.10226400': __('identifierSchemeCodeUnspsc190501.10226400'),
	'identifierSchemeCodeUnspsc190501.10226401': __('identifierSchemeCodeUnspsc190501.10226401'),
	'identifierSchemeCodeUnspsc190501.10226402': __('identifierSchemeCodeUnspsc190501.10226402'),
	'identifierSchemeCodeUnspsc190501.10226500': __('identifierSchemeCodeUnspsc190501.10226500'),
	'identifierSchemeCodeUnspsc190501.10226501': __('identifierSchemeCodeUnspsc190501.10226501'),
	'identifierSchemeCodeUnspsc190501.10226502': __('identifierSchemeCodeUnspsc190501.10226502'),
	'identifierSchemeCodeUnspsc190501.10226600': __('identifierSchemeCodeUnspsc190501.10226600'),
	'identifierSchemeCodeUnspsc190501.10226601': __('identifierSchemeCodeUnspsc190501.10226601'),
	'identifierSchemeCodeUnspsc190501.10226602': __('identifierSchemeCodeUnspsc190501.10226602'),
	'identifierSchemeCodeUnspsc190501.10226603': __('identifierSchemeCodeUnspsc190501.10226603'),
	'identifierSchemeCodeUnspsc190501.10226700': __('identifierSchemeCodeUnspsc190501.10226700'),
	'identifierSchemeCodeUnspsc190501.10226701': __('identifierSchemeCodeUnspsc190501.10226701'),
	'identifierSchemeCodeUnspsc190501.10226702': __('identifierSchemeCodeUnspsc190501.10226702'),
	'identifierSchemeCodeUnspsc190501.10226703': __('identifierSchemeCodeUnspsc190501.10226703'),
	'identifierSchemeCodeUnspsc190501.10226704': __('identifierSchemeCodeUnspsc190501.10226704'),
	'identifierSchemeCodeUnspsc190501.10230000': __('identifierSchemeCodeUnspsc190501.10230000'),
	'identifierSchemeCodeUnspsc190501.10231500': __('identifierSchemeCodeUnspsc190501.10231500'),
	'identifierSchemeCodeUnspsc190501.10231501': __('identifierSchemeCodeUnspsc190501.10231501'),
	'identifierSchemeCodeUnspsc190501.10231502': __('identifierSchemeCodeUnspsc190501.10231502'),
	'identifierSchemeCodeUnspsc190501.10231503': __('identifierSchemeCodeUnspsc190501.10231503'),
	'identifierSchemeCodeUnspsc190501.10231504': __('identifierSchemeCodeUnspsc190501.10231504'),
	'identifierSchemeCodeUnspsc190501.10231505': __('identifierSchemeCodeUnspsc190501.10231505'),
	'identifierSchemeCodeUnspsc190501.10231506': __('identifierSchemeCodeUnspsc190501.10231506'),
	'identifierSchemeCodeUnspsc190501.10231507': __('identifierSchemeCodeUnspsc190501.10231507'),
	'identifierSchemeCodeUnspsc190501.10231508': __('identifierSchemeCodeUnspsc190501.10231508'),
	'identifierSchemeCodeUnspsc190501.10231509': __('identifierSchemeCodeUnspsc190501.10231509'),
	'identifierSchemeCodeUnspsc190501.10231510': __('identifierSchemeCodeUnspsc190501.10231510'),
	'identifierSchemeCodeUnspsc190501.10231511': __('identifierSchemeCodeUnspsc190501.10231511'),
	'identifierSchemeCodeUnspsc190501.10231512': __('identifierSchemeCodeUnspsc190501.10231512'),
	'identifierSchemeCodeUnspsc190501.10231513': __('identifierSchemeCodeUnspsc190501.10231513'),
	'identifierSchemeCodeUnspsc190501.10231600': __('identifierSchemeCodeUnspsc190501.10231600'),
	'identifierSchemeCodeUnspsc190501.10231601': __('identifierSchemeCodeUnspsc190501.10231601'),
	'identifierSchemeCodeUnspsc190501.10231602': __('identifierSchemeCodeUnspsc190501.10231602'),
	'identifierSchemeCodeUnspsc190501.10231603': __('identifierSchemeCodeUnspsc190501.10231603'),
	'identifierSchemeCodeUnspsc190501.10231604': __('identifierSchemeCodeUnspsc190501.10231604'),
	'identifierSchemeCodeUnspsc190501.10231605': __('identifierSchemeCodeUnspsc190501.10231605'),
	'identifierSchemeCodeUnspsc190501.10231606': __('identifierSchemeCodeUnspsc190501.10231606'),
	'identifierSchemeCodeUnspsc190501.10231607': __('identifierSchemeCodeUnspsc190501.10231607'),
	'identifierSchemeCodeUnspsc190501.10231608': __('identifierSchemeCodeUnspsc190501.10231608'),
	'identifierSchemeCodeUnspsc190501.10231609': __('identifierSchemeCodeUnspsc190501.10231609'),
	'identifierSchemeCodeUnspsc190501.10231610': __('identifierSchemeCodeUnspsc190501.10231610'),
	'identifierSchemeCodeUnspsc190501.10231611': __('identifierSchemeCodeUnspsc190501.10231611'),
	'identifierSchemeCodeUnspsc190501.10231612': __('identifierSchemeCodeUnspsc190501.10231612'),
	'identifierSchemeCodeUnspsc190501.10231613': __('identifierSchemeCodeUnspsc190501.10231613'),
	'identifierSchemeCodeUnspsc190501.10231614': __('identifierSchemeCodeUnspsc190501.10231614'),
	'identifierSchemeCodeUnspsc190501.10231615': __('identifierSchemeCodeUnspsc190501.10231615'),
	'identifierSchemeCodeUnspsc190501.10231616': __('identifierSchemeCodeUnspsc190501.10231616'),
	'identifierSchemeCodeUnspsc190501.10231617': __('identifierSchemeCodeUnspsc190501.10231617'),
	'identifierSchemeCodeUnspsc190501.10231618': __('identifierSchemeCodeUnspsc190501.10231618'),
	'identifierSchemeCodeUnspsc190501.10231619': __('identifierSchemeCodeUnspsc190501.10231619'),
	'identifierSchemeCodeUnspsc190501.10231620': __('identifierSchemeCodeUnspsc190501.10231620'),
	'identifierSchemeCodeUnspsc190501.10231621': __('identifierSchemeCodeUnspsc190501.10231621'),
	'identifierSchemeCodeUnspsc190501.10231622': __('identifierSchemeCodeUnspsc190501.10231622'),
	'identifierSchemeCodeUnspsc190501.10231623': __('identifierSchemeCodeUnspsc190501.10231623'),
	'identifierSchemeCodeUnspsc190501.10231624': __('identifierSchemeCodeUnspsc190501.10231624'),
	'identifierSchemeCodeUnspsc190501.10231625': __('identifierSchemeCodeUnspsc190501.10231625'),
	'identifierSchemeCodeUnspsc190501.10231626': __('identifierSchemeCodeUnspsc190501.10231626'),
	'identifierSchemeCodeUnspsc190501.10231627': __('identifierSchemeCodeUnspsc190501.10231627'),
	'identifierSchemeCodeUnspsc190501.10231628': __('identifierSchemeCodeUnspsc190501.10231628'),
	'identifierSchemeCodeUnspsc190501.10231629': __('identifierSchemeCodeUnspsc190501.10231629'),
	'identifierSchemeCodeUnspsc190501.10231630': __('identifierSchemeCodeUnspsc190501.10231630'),
	'identifierSchemeCodeUnspsc190501.10231631': __('identifierSchemeCodeUnspsc190501.10231631'),
	'identifierSchemeCodeUnspsc190501.10231632': __('identifierSchemeCodeUnspsc190501.10231632'),
	'identifierSchemeCodeUnspsc190501.10231633': __('identifierSchemeCodeUnspsc190501.10231633'),
	'identifierSchemeCodeUnspsc190501.10231634': __('identifierSchemeCodeUnspsc190501.10231634'),
	'identifierSchemeCodeUnspsc190501.10231635': __('identifierSchemeCodeUnspsc190501.10231635'),
	'identifierSchemeCodeUnspsc190501.10231636': __('identifierSchemeCodeUnspsc190501.10231636'),
	'identifierSchemeCodeUnspsc190501.10231637': __('identifierSchemeCodeUnspsc190501.10231637'),
	'identifierSchemeCodeUnspsc190501.10231638': __('identifierSchemeCodeUnspsc190501.10231638'),
	'identifierSchemeCodeUnspsc190501.10231639': __('identifierSchemeCodeUnspsc190501.10231639'),
	'identifierSchemeCodeUnspsc190501.10231640': __('identifierSchemeCodeUnspsc190501.10231640'),
	'identifierSchemeCodeUnspsc190501.10231641': __('identifierSchemeCodeUnspsc190501.10231641'),
	'identifierSchemeCodeUnspsc190501.10231642': __('identifierSchemeCodeUnspsc190501.10231642'),
	'identifierSchemeCodeUnspsc190501.10231643': __('identifierSchemeCodeUnspsc190501.10231643'),
	'identifierSchemeCodeUnspsc190501.10231644': __('identifierSchemeCodeUnspsc190501.10231644'),
	'identifierSchemeCodeUnspsc190501.10231645': __('identifierSchemeCodeUnspsc190501.10231645'),
	'identifierSchemeCodeUnspsc190501.10231646': __('identifierSchemeCodeUnspsc190501.10231646'),
	'identifierSchemeCodeUnspsc190501.10231647': __('identifierSchemeCodeUnspsc190501.10231647'),
	'identifierSchemeCodeUnspsc190501.10231700': __('identifierSchemeCodeUnspsc190501.10231700'),
	'identifierSchemeCodeUnspsc190501.10231701': __('identifierSchemeCodeUnspsc190501.10231701'),
	'identifierSchemeCodeUnspsc190501.10231702': __('identifierSchemeCodeUnspsc190501.10231702'),
	'identifierSchemeCodeUnspsc190501.10231703': __('identifierSchemeCodeUnspsc190501.10231703'),
	'identifierSchemeCodeUnspsc190501.10231704': __('identifierSchemeCodeUnspsc190501.10231704'),
	'identifierSchemeCodeUnspsc190501.10231705': __('identifierSchemeCodeUnspsc190501.10231705'),
	'identifierSchemeCodeUnspsc190501.10231706': __('identifierSchemeCodeUnspsc190501.10231706'),
	'identifierSchemeCodeUnspsc190501.10231707': __('identifierSchemeCodeUnspsc190501.10231707'),
	'identifierSchemeCodeUnspsc190501.10231708': __('identifierSchemeCodeUnspsc190501.10231708'),
	'identifierSchemeCodeUnspsc190501.10231709': __('identifierSchemeCodeUnspsc190501.10231709'),
	'identifierSchemeCodeUnspsc190501.10231710': __('identifierSchemeCodeUnspsc190501.10231710'),
	'identifierSchemeCodeUnspsc190501.10231711': __('identifierSchemeCodeUnspsc190501.10231711'),
	'identifierSchemeCodeUnspsc190501.10231712': __('identifierSchemeCodeUnspsc190501.10231712'),
	'identifierSchemeCodeUnspsc190501.10231713': __('identifierSchemeCodeUnspsc190501.10231713'),
	'identifierSchemeCodeUnspsc190501.10231714': __('identifierSchemeCodeUnspsc190501.10231714'),
	'identifierSchemeCodeUnspsc190501.10231800': __('identifierSchemeCodeUnspsc190501.10231800'),
	'identifierSchemeCodeUnspsc190501.10231801': __('identifierSchemeCodeUnspsc190501.10231801'),
	'identifierSchemeCodeUnspsc190501.10231802': __('identifierSchemeCodeUnspsc190501.10231802'),
	'identifierSchemeCodeUnspsc190501.10231803': __('identifierSchemeCodeUnspsc190501.10231803'),
	'identifierSchemeCodeUnspsc190501.10231804': __('identifierSchemeCodeUnspsc190501.10231804'),
	'identifierSchemeCodeUnspsc190501.10231805': __('identifierSchemeCodeUnspsc190501.10231805'),
	'identifierSchemeCodeUnspsc190501.10231806': __('identifierSchemeCodeUnspsc190501.10231806'),
	'identifierSchemeCodeUnspsc190501.10231807': __('identifierSchemeCodeUnspsc190501.10231807'),
	'identifierSchemeCodeUnspsc190501.10231808': __('identifierSchemeCodeUnspsc190501.10231808'),
	'identifierSchemeCodeUnspsc190501.10231809': __('identifierSchemeCodeUnspsc190501.10231809'),
	'identifierSchemeCodeUnspsc190501.10231810': __('identifierSchemeCodeUnspsc190501.10231810'),
	'identifierSchemeCodeUnspsc190501.10231811': __('identifierSchemeCodeUnspsc190501.10231811'),
	'identifierSchemeCodeUnspsc190501.10231812': __('identifierSchemeCodeUnspsc190501.10231812'),
	'identifierSchemeCodeUnspsc190501.10231900': __('identifierSchemeCodeUnspsc190501.10231900'),
	'identifierSchemeCodeUnspsc190501.10231901': __('identifierSchemeCodeUnspsc190501.10231901'),
	'identifierSchemeCodeUnspsc190501.10231902': __('identifierSchemeCodeUnspsc190501.10231902'),
	'identifierSchemeCodeUnspsc190501.10231903': __('identifierSchemeCodeUnspsc190501.10231903'),
	'identifierSchemeCodeUnspsc190501.10231904': __('identifierSchemeCodeUnspsc190501.10231904'),
	'identifierSchemeCodeUnspsc190501.10231905': __('identifierSchemeCodeUnspsc190501.10231905'),
	'identifierSchemeCodeUnspsc190501.10231906': __('identifierSchemeCodeUnspsc190501.10231906'),
	'identifierSchemeCodeUnspsc190501.10231907': __('identifierSchemeCodeUnspsc190501.10231907'),
	'identifierSchemeCodeUnspsc190501.10231908': __('identifierSchemeCodeUnspsc190501.10231908'),
	'identifierSchemeCodeUnspsc190501.10231909': __('identifierSchemeCodeUnspsc190501.10231909'),
	'identifierSchemeCodeUnspsc190501.10231910': __('identifierSchemeCodeUnspsc190501.10231910'),
	'identifierSchemeCodeUnspsc190501.10231911': __('identifierSchemeCodeUnspsc190501.10231911'),
	'identifierSchemeCodeUnspsc190501.10231912': __('identifierSchemeCodeUnspsc190501.10231912'),
	'identifierSchemeCodeUnspsc190501.10231913': __('identifierSchemeCodeUnspsc190501.10231913'),
	'identifierSchemeCodeUnspsc190501.10231914': __('identifierSchemeCodeUnspsc190501.10231914'),
	'identifierSchemeCodeUnspsc190501.10231915': __('identifierSchemeCodeUnspsc190501.10231915'),
	'identifierSchemeCodeUnspsc190501.10231916': __('identifierSchemeCodeUnspsc190501.10231916'),
	'identifierSchemeCodeUnspsc190501.10231917': __('identifierSchemeCodeUnspsc190501.10231917'),
	'identifierSchemeCodeUnspsc190501.10231918': __('identifierSchemeCodeUnspsc190501.10231918'),
	'identifierSchemeCodeUnspsc190501.10231919': __('identifierSchemeCodeUnspsc190501.10231919'),
	'identifierSchemeCodeUnspsc190501.10231920': __('identifierSchemeCodeUnspsc190501.10231920'),
	'identifierSchemeCodeUnspsc190501.10231921': __('identifierSchemeCodeUnspsc190501.10231921'),
	'identifierSchemeCodeUnspsc190501.10231922': __('identifierSchemeCodeUnspsc190501.10231922'),
	'identifierSchemeCodeUnspsc190501.10231923': __('identifierSchemeCodeUnspsc190501.10231923'),
	'identifierSchemeCodeUnspsc190501.10231924': __('identifierSchemeCodeUnspsc190501.10231924'),
	'identifierSchemeCodeUnspsc190501.10231925': __('identifierSchemeCodeUnspsc190501.10231925'),
	'identifierSchemeCodeUnspsc190501.10231926': __('identifierSchemeCodeUnspsc190501.10231926'),
	'identifierSchemeCodeUnspsc190501.10232000': __('identifierSchemeCodeUnspsc190501.10232000'),
	'identifierSchemeCodeUnspsc190501.10232001': __('identifierSchemeCodeUnspsc190501.10232001'),
	'identifierSchemeCodeUnspsc190501.10232002': __('identifierSchemeCodeUnspsc190501.10232002'),
	'identifierSchemeCodeUnspsc190501.10232003': __('identifierSchemeCodeUnspsc190501.10232003'),
	'identifierSchemeCodeUnspsc190501.10232004': __('identifierSchemeCodeUnspsc190501.10232004'),
	'identifierSchemeCodeUnspsc190501.10232005': __('identifierSchemeCodeUnspsc190501.10232005'),
	'identifierSchemeCodeUnspsc190501.10232006': __('identifierSchemeCodeUnspsc190501.10232006'),
	'identifierSchemeCodeUnspsc190501.10232007': __('identifierSchemeCodeUnspsc190501.10232007'),
	'identifierSchemeCodeUnspsc190501.10232008': __('identifierSchemeCodeUnspsc190501.10232008'),
	'identifierSchemeCodeUnspsc190501.10232009': __('identifierSchemeCodeUnspsc190501.10232009'),
	'identifierSchemeCodeUnspsc190501.10232010': __('identifierSchemeCodeUnspsc190501.10232010'),
	'identifierSchemeCodeUnspsc190501.10232012': __('identifierSchemeCodeUnspsc190501.10232012'),
	'identifierSchemeCodeUnspsc190501.10232013': __('identifierSchemeCodeUnspsc190501.10232013'),
	'identifierSchemeCodeUnspsc190501.10232014': __('identifierSchemeCodeUnspsc190501.10232014'),
	'identifierSchemeCodeUnspsc190501.10232015': __('identifierSchemeCodeUnspsc190501.10232015'),
	'identifierSchemeCodeUnspsc190501.10232016': __('identifierSchemeCodeUnspsc190501.10232016'),
	'identifierSchemeCodeUnspsc190501.10232017': __('identifierSchemeCodeUnspsc190501.10232017'),
	'identifierSchemeCodeUnspsc190501.10232018': __('identifierSchemeCodeUnspsc190501.10232018'),
	'identifierSchemeCodeUnspsc190501.10232019': __('identifierSchemeCodeUnspsc190501.10232019'),
	'identifierSchemeCodeUnspsc190501.10232020': __('identifierSchemeCodeUnspsc190501.10232020'),
	'identifierSchemeCodeUnspsc190501.10232021': __('identifierSchemeCodeUnspsc190501.10232021'),
	'identifierSchemeCodeUnspsc190501.10232022': __('identifierSchemeCodeUnspsc190501.10232022'),
	'identifierSchemeCodeUnspsc190501.10232023': __('identifierSchemeCodeUnspsc190501.10232023'),
	'identifierSchemeCodeUnspsc190501.10232024': __('identifierSchemeCodeUnspsc190501.10232024'),
	'identifierSchemeCodeUnspsc190501.10232025': __('identifierSchemeCodeUnspsc190501.10232025'),
	'identifierSchemeCodeUnspsc190501.10232026': __('identifierSchemeCodeUnspsc190501.10232026'),
	'identifierSchemeCodeUnspsc190501.10232027': __('identifierSchemeCodeUnspsc190501.10232027'),
	'identifierSchemeCodeUnspsc190501.10232028': __('identifierSchemeCodeUnspsc190501.10232028'),
	'identifierSchemeCodeUnspsc190501.10232029': __('identifierSchemeCodeUnspsc190501.10232029'),
	'identifierSchemeCodeUnspsc190501.10232030': __('identifierSchemeCodeUnspsc190501.10232030'),
	'identifierSchemeCodeUnspsc190501.10232031': __('identifierSchemeCodeUnspsc190501.10232031'),
	'identifierSchemeCodeUnspsc190501.10232032': __('identifierSchemeCodeUnspsc190501.10232032'),
	'identifierSchemeCodeUnspsc190501.10232033': __('identifierSchemeCodeUnspsc190501.10232033'),
	'identifierSchemeCodeUnspsc190501.10232034': __('identifierSchemeCodeUnspsc190501.10232034'),
	'identifierSchemeCodeUnspsc190501.10232035': __('identifierSchemeCodeUnspsc190501.10232035'),
	'identifierSchemeCodeUnspsc190501.10232036': __('identifierSchemeCodeUnspsc190501.10232036'),
	'identifierSchemeCodeUnspsc190501.10232037': __('identifierSchemeCodeUnspsc190501.10232037'),
	'identifierSchemeCodeUnspsc190501.10232038': __('identifierSchemeCodeUnspsc190501.10232038'),
	'identifierSchemeCodeUnspsc190501.10232039': __('identifierSchemeCodeUnspsc190501.10232039'),
	'identifierSchemeCodeUnspsc190501.10232040': __('identifierSchemeCodeUnspsc190501.10232040'),
	'identifierSchemeCodeUnspsc190501.10232041': __('identifierSchemeCodeUnspsc190501.10232041'),
	'identifierSchemeCodeUnspsc190501.10232042': __('identifierSchemeCodeUnspsc190501.10232042'),
	'identifierSchemeCodeUnspsc190501.10232043': __('identifierSchemeCodeUnspsc190501.10232043'),
	'identifierSchemeCodeUnspsc190501.10232044': __('identifierSchemeCodeUnspsc190501.10232044'),
	'identifierSchemeCodeUnspsc190501.10232045': __('identifierSchemeCodeUnspsc190501.10232045'),
	'identifierSchemeCodeUnspsc190501.10232046': __('identifierSchemeCodeUnspsc190501.10232046'),
	'identifierSchemeCodeUnspsc190501.10232047': __('identifierSchemeCodeUnspsc190501.10232047'),
	'identifierSchemeCodeUnspsc190501.10232048': __('identifierSchemeCodeUnspsc190501.10232048'),
	'identifierSchemeCodeUnspsc190501.10232049': __('identifierSchemeCodeUnspsc190501.10232049'),
	'identifierSchemeCodeUnspsc190501.10232050': __('identifierSchemeCodeUnspsc190501.10232050'),
	'identifierSchemeCodeUnspsc190501.10232051': __('identifierSchemeCodeUnspsc190501.10232051'),
	'identifierSchemeCodeUnspsc190501.10232052': __('identifierSchemeCodeUnspsc190501.10232052'),
	'identifierSchemeCodeUnspsc190501.10232053': __('identifierSchemeCodeUnspsc190501.10232053'),
	'identifierSchemeCodeUnspsc190501.10232054': __('identifierSchemeCodeUnspsc190501.10232054'),
	'identifierSchemeCodeUnspsc190501.10232055': __('identifierSchemeCodeUnspsc190501.10232055'),
	'identifierSchemeCodeUnspsc190501.10232056': __('identifierSchemeCodeUnspsc190501.10232056'),
	'identifierSchemeCodeUnspsc190501.10232057': __('identifierSchemeCodeUnspsc190501.10232057'),
	'identifierSchemeCodeUnspsc190501.10232058': __('identifierSchemeCodeUnspsc190501.10232058'),
	'identifierSchemeCodeUnspsc190501.10232059': __('identifierSchemeCodeUnspsc190501.10232059'),
	'identifierSchemeCodeUnspsc190501.10232060': __('identifierSchemeCodeUnspsc190501.10232060'),
	'identifierSchemeCodeUnspsc190501.10232061': __('identifierSchemeCodeUnspsc190501.10232061'),
	'identifierSchemeCodeUnspsc190501.10232062': __('identifierSchemeCodeUnspsc190501.10232062'),
	'identifierSchemeCodeUnspsc190501.10232063': __('identifierSchemeCodeUnspsc190501.10232063'),
	'identifierSchemeCodeUnspsc190501.10232064': __('identifierSchemeCodeUnspsc190501.10232064'),
	'identifierSchemeCodeUnspsc190501.10232065': __('identifierSchemeCodeUnspsc190501.10232065'),
	'identifierSchemeCodeUnspsc190501.10232066': __('identifierSchemeCodeUnspsc190501.10232066'),
	'identifierSchemeCodeUnspsc190501.10232067': __('identifierSchemeCodeUnspsc190501.10232067'),
	'identifierSchemeCodeUnspsc190501.10232100': __('identifierSchemeCodeUnspsc190501.10232100'),
	'identifierSchemeCodeUnspsc190501.10232101': __('identifierSchemeCodeUnspsc190501.10232101'),
	'identifierSchemeCodeUnspsc190501.10232102': __('identifierSchemeCodeUnspsc190501.10232102'),
	'identifierSchemeCodeUnspsc190501.10232103': __('identifierSchemeCodeUnspsc190501.10232103'),
	'identifierSchemeCodeUnspsc190501.10232104': __('identifierSchemeCodeUnspsc190501.10232104'),
	'identifierSchemeCodeUnspsc190501.10232105': __('identifierSchemeCodeUnspsc190501.10232105'),
	'identifierSchemeCodeUnspsc190501.10232106': __('identifierSchemeCodeUnspsc190501.10232106'),
	'identifierSchemeCodeUnspsc190501.10232107': __('identifierSchemeCodeUnspsc190501.10232107'),
	'identifierSchemeCodeUnspsc190501.10232108': __('identifierSchemeCodeUnspsc190501.10232108'),
	'identifierSchemeCodeUnspsc190501.10232109': __('identifierSchemeCodeUnspsc190501.10232109'),
	'identifierSchemeCodeUnspsc190501.10232110': __('identifierSchemeCodeUnspsc190501.10232110'),
	'identifierSchemeCodeUnspsc190501.10232111': __('identifierSchemeCodeUnspsc190501.10232111'),
	'identifierSchemeCodeUnspsc190501.10232112': __('identifierSchemeCodeUnspsc190501.10232112'),
	'identifierSchemeCodeUnspsc190501.10232113': __('identifierSchemeCodeUnspsc190501.10232113'),
	'identifierSchemeCodeUnspsc190501.10232114': __('identifierSchemeCodeUnspsc190501.10232114'),
	'identifierSchemeCodeUnspsc190501.10232115': __('identifierSchemeCodeUnspsc190501.10232115'),
	'identifierSchemeCodeUnspsc190501.10232116': __('identifierSchemeCodeUnspsc190501.10232116'),
	'identifierSchemeCodeUnspsc190501.10232117': __('identifierSchemeCodeUnspsc190501.10232117'),
	'identifierSchemeCodeUnspsc190501.10232118': __('identifierSchemeCodeUnspsc190501.10232118'),
	'identifierSchemeCodeUnspsc190501.10232119': __('identifierSchemeCodeUnspsc190501.10232119'),
	'identifierSchemeCodeUnspsc190501.10232120': __('identifierSchemeCodeUnspsc190501.10232120'),
	'identifierSchemeCodeUnspsc190501.10232121': __('identifierSchemeCodeUnspsc190501.10232121'),
	'identifierSchemeCodeUnspsc190501.10232122': __('identifierSchemeCodeUnspsc190501.10232122'),
	'identifierSchemeCodeUnspsc190501.10232123': __('identifierSchemeCodeUnspsc190501.10232123'),
	'identifierSchemeCodeUnspsc190501.10232124': __('identifierSchemeCodeUnspsc190501.10232124'),
	'identifierSchemeCodeUnspsc190501.10232125': __('identifierSchemeCodeUnspsc190501.10232125'),
	'identifierSchemeCodeUnspsc190501.10232126': __('identifierSchemeCodeUnspsc190501.10232126'),
	'identifierSchemeCodeUnspsc190501.10232127': __('identifierSchemeCodeUnspsc190501.10232127'),
	'identifierSchemeCodeUnspsc190501.10232128': __('identifierSchemeCodeUnspsc190501.10232128'),
	'identifierSchemeCodeUnspsc190501.10232129': __('identifierSchemeCodeUnspsc190501.10232129'),
	'identifierSchemeCodeUnspsc190501.10232130': __('identifierSchemeCodeUnspsc190501.10232130'),
	'identifierSchemeCodeUnspsc190501.10232131': __('identifierSchemeCodeUnspsc190501.10232131'),
	'identifierSchemeCodeUnspsc190501.10232132': __('identifierSchemeCodeUnspsc190501.10232132'),
	'identifierSchemeCodeUnspsc190501.10240000': __('identifierSchemeCodeUnspsc190501.10240000'),
	'identifierSchemeCodeUnspsc190501.10241500': __('identifierSchemeCodeUnspsc190501.10241500'),
	'identifierSchemeCodeUnspsc190501.10241501': __('identifierSchemeCodeUnspsc190501.10241501'),
	'identifierSchemeCodeUnspsc190501.10241502': __('identifierSchemeCodeUnspsc190501.10241502'),
	'identifierSchemeCodeUnspsc190501.10241503': __('identifierSchemeCodeUnspsc190501.10241503'),
	'identifierSchemeCodeUnspsc190501.10241504': __('identifierSchemeCodeUnspsc190501.10241504'),
	'identifierSchemeCodeUnspsc190501.10241505': __('identifierSchemeCodeUnspsc190501.10241505'),
	'identifierSchemeCodeUnspsc190501.10241506': __('identifierSchemeCodeUnspsc190501.10241506'),
	'identifierSchemeCodeUnspsc190501.10241507': __('identifierSchemeCodeUnspsc190501.10241507'),
	'identifierSchemeCodeUnspsc190501.10241508': __('identifierSchemeCodeUnspsc190501.10241508'),
	'identifierSchemeCodeUnspsc190501.10241509': __('identifierSchemeCodeUnspsc190501.10241509'),
	'identifierSchemeCodeUnspsc190501.10241510': __('identifierSchemeCodeUnspsc190501.10241510'),
	'identifierSchemeCodeUnspsc190501.10241511': __('identifierSchemeCodeUnspsc190501.10241511'),
	'identifierSchemeCodeUnspsc190501.10241512': __('identifierSchemeCodeUnspsc190501.10241512'),
	'identifierSchemeCodeUnspsc190501.10241513': __('identifierSchemeCodeUnspsc190501.10241513'),
	'identifierSchemeCodeUnspsc190501.10241514': __('identifierSchemeCodeUnspsc190501.10241514'),
	'identifierSchemeCodeUnspsc190501.10241515': __('identifierSchemeCodeUnspsc190501.10241515'),
	'identifierSchemeCodeUnspsc190501.10241516': __('identifierSchemeCodeUnspsc190501.10241516'),
	'identifierSchemeCodeUnspsc190501.10241517': __('identifierSchemeCodeUnspsc190501.10241517'),
	'identifierSchemeCodeUnspsc190501.10241518': __('identifierSchemeCodeUnspsc190501.10241518'),
	'identifierSchemeCodeUnspsc190501.10241519': __('identifierSchemeCodeUnspsc190501.10241519'),
	'identifierSchemeCodeUnspsc190501.10241520': __('identifierSchemeCodeUnspsc190501.10241520'),
	'identifierSchemeCodeUnspsc190501.10241600': __('identifierSchemeCodeUnspsc190501.10241600'),
	'identifierSchemeCodeUnspsc190501.10241601': __('identifierSchemeCodeUnspsc190501.10241601'),
	'identifierSchemeCodeUnspsc190501.10241602': __('identifierSchemeCodeUnspsc190501.10241602'),
	'identifierSchemeCodeUnspsc190501.10241603': __('identifierSchemeCodeUnspsc190501.10241603'),
	'identifierSchemeCodeUnspsc190501.10241604': __('identifierSchemeCodeUnspsc190501.10241604'),
	'identifierSchemeCodeUnspsc190501.10241605': __('identifierSchemeCodeUnspsc190501.10241605'),
	'identifierSchemeCodeUnspsc190501.10241606': __('identifierSchemeCodeUnspsc190501.10241606'),
	'identifierSchemeCodeUnspsc190501.10241607': __('identifierSchemeCodeUnspsc190501.10241607'),
	'identifierSchemeCodeUnspsc190501.10241608': __('identifierSchemeCodeUnspsc190501.10241608'),
	'identifierSchemeCodeUnspsc190501.10241609': __('identifierSchemeCodeUnspsc190501.10241609'),
	'identifierSchemeCodeUnspsc190501.10241610': __('identifierSchemeCodeUnspsc190501.10241610'),
	'identifierSchemeCodeUnspsc190501.10241611': __('identifierSchemeCodeUnspsc190501.10241611'),
	'identifierSchemeCodeUnspsc190501.10241612': __('identifierSchemeCodeUnspsc190501.10241612'),
	'identifierSchemeCodeUnspsc190501.10241613': __('identifierSchemeCodeUnspsc190501.10241613'),
	'identifierSchemeCodeUnspsc190501.10241614': __('identifierSchemeCodeUnspsc190501.10241614'),
	'identifierSchemeCodeUnspsc190501.10250000': __('identifierSchemeCodeUnspsc190501.10250000'),
	'identifierSchemeCodeUnspsc190501.10251500': __('identifierSchemeCodeUnspsc190501.10251500'),
	'identifierSchemeCodeUnspsc190501.10251501': __('identifierSchemeCodeUnspsc190501.10251501'),
	'identifierSchemeCodeUnspsc190501.10251502': __('identifierSchemeCodeUnspsc190501.10251502'),
	'identifierSchemeCodeUnspsc190501.10251503': __('identifierSchemeCodeUnspsc190501.10251503'),
	'identifierSchemeCodeUnspsc190501.10251504': __('identifierSchemeCodeUnspsc190501.10251504'),
	'identifierSchemeCodeUnspsc190501.10251505': __('identifierSchemeCodeUnspsc190501.10251505'),
	'identifierSchemeCodeUnspsc190501.10251600': __('identifierSchemeCodeUnspsc190501.10251600'),
	'identifierSchemeCodeUnspsc190501.10251601': __('identifierSchemeCodeUnspsc190501.10251601'),
	'identifierSchemeCodeUnspsc190501.10251602': __('identifierSchemeCodeUnspsc190501.10251602'),
	'identifierSchemeCodeUnspsc190501.10251603': __('identifierSchemeCodeUnspsc190501.10251603'),
	'identifierSchemeCodeUnspsc190501.10251604': __('identifierSchemeCodeUnspsc190501.10251604'),
	'identifierSchemeCodeUnspsc190501.10251605': __('identifierSchemeCodeUnspsc190501.10251605'),
	'identifierSchemeCodeUnspsc190501.10251700': __('identifierSchemeCodeUnspsc190501.10251700'),
	'identifierSchemeCodeUnspsc190501.10251701': __('identifierSchemeCodeUnspsc190501.10251701'),
	'identifierSchemeCodeUnspsc190501.10251702': __('identifierSchemeCodeUnspsc190501.10251702'),
	'identifierSchemeCodeUnspsc190501.10251800': __('identifierSchemeCodeUnspsc190501.10251800'),
	'identifierSchemeCodeUnspsc190501.10251801': __('identifierSchemeCodeUnspsc190501.10251801'),
	'identifierSchemeCodeUnspsc190501.10251802': __('identifierSchemeCodeUnspsc190501.10251802'),
	'identifierSchemeCodeUnspsc190501.10251803': __('identifierSchemeCodeUnspsc190501.10251803'),
	'identifierSchemeCodeUnspsc190501.10251804': __('identifierSchemeCodeUnspsc190501.10251804'),
	'identifierSchemeCodeUnspsc190501.10251805': __('identifierSchemeCodeUnspsc190501.10251805'),
	'identifierSchemeCodeUnspsc190501.10251806': __('identifierSchemeCodeUnspsc190501.10251806'),
	'identifierSchemeCodeUnspsc190501.10251900': __('identifierSchemeCodeUnspsc190501.10251900'),
	'identifierSchemeCodeUnspsc190501.10251901': __('identifierSchemeCodeUnspsc190501.10251901'),
	'identifierSchemeCodeUnspsc190501.10251902': __('identifierSchemeCodeUnspsc190501.10251902'),
	'identifierSchemeCodeUnspsc190501.10251903': __('identifierSchemeCodeUnspsc190501.10251903'),
	'identifierSchemeCodeUnspsc190501.10251904': __('identifierSchemeCodeUnspsc190501.10251904'),
	'identifierSchemeCodeUnspsc190501.10251905': __('identifierSchemeCodeUnspsc190501.10251905'),
	'identifierSchemeCodeUnspsc190501.10252000': __('identifierSchemeCodeUnspsc190501.10252000'),
	'identifierSchemeCodeUnspsc190501.10252001': __('identifierSchemeCodeUnspsc190501.10252001'),
	'identifierSchemeCodeUnspsc190501.10252002': __('identifierSchemeCodeUnspsc190501.10252002'),
	'identifierSchemeCodeUnspsc190501.10252003': __('identifierSchemeCodeUnspsc190501.10252003'),
	'identifierSchemeCodeUnspsc190501.10252004': __('identifierSchemeCodeUnspsc190501.10252004'),
	'identifierSchemeCodeUnspsc190501.10252005': __('identifierSchemeCodeUnspsc190501.10252005'),
	'identifierSchemeCodeUnspsc190501.10252006': __('identifierSchemeCodeUnspsc190501.10252006'),
	'identifierSchemeCodeUnspsc190501.10252007': __('identifierSchemeCodeUnspsc190501.10252007'),
	'identifierSchemeCodeUnspsc190501.10252008': __('identifierSchemeCodeUnspsc190501.10252008'),
	'identifierSchemeCodeUnspsc190501.10252009': __('identifierSchemeCodeUnspsc190501.10252009'),
	'identifierSchemeCodeUnspsc190501.10252010': __('identifierSchemeCodeUnspsc190501.10252010'),
	'identifierSchemeCodeUnspsc190501.10252011': __('identifierSchemeCodeUnspsc190501.10252011'),
	'identifierSchemeCodeUnspsc190501.10252012': __('identifierSchemeCodeUnspsc190501.10252012'),
	'identifierSchemeCodeUnspsc190501.10252013': __('identifierSchemeCodeUnspsc190501.10252013'),
	'identifierSchemeCodeUnspsc190501.10252014': __('identifierSchemeCodeUnspsc190501.10252014'),
	'identifierSchemeCodeUnspsc190501.10252015': __('identifierSchemeCodeUnspsc190501.10252015'),
	'identifierSchemeCodeUnspsc190501.10252016': __('identifierSchemeCodeUnspsc190501.10252016'),
	'identifierSchemeCodeUnspsc190501.10252017': __('identifierSchemeCodeUnspsc190501.10252017'),
	'identifierSchemeCodeUnspsc190501.10252018': __('identifierSchemeCodeUnspsc190501.10252018'),
	'identifierSchemeCodeUnspsc190501.10252019': __('identifierSchemeCodeUnspsc190501.10252019'),
	'identifierSchemeCodeUnspsc190501.10252020': __('identifierSchemeCodeUnspsc190501.10252020'),
	'identifierSchemeCodeUnspsc190501.10252021': __('identifierSchemeCodeUnspsc190501.10252021'),
	'identifierSchemeCodeUnspsc190501.10252022': __('identifierSchemeCodeUnspsc190501.10252022'),
	'identifierSchemeCodeUnspsc190501.10252023': __('identifierSchemeCodeUnspsc190501.10252023'),
	'identifierSchemeCodeUnspsc190501.10252024': __('identifierSchemeCodeUnspsc190501.10252024'),
	'identifierSchemeCodeUnspsc190501.10252025': __('identifierSchemeCodeUnspsc190501.10252025'),
	'identifierSchemeCodeUnspsc190501.10252026': __('identifierSchemeCodeUnspsc190501.10252026'),
	'identifierSchemeCodeUnspsc190501.10252027': __('identifierSchemeCodeUnspsc190501.10252027'),
	'identifierSchemeCodeUnspsc190501.10252028': __('identifierSchemeCodeUnspsc190501.10252028'),
	'identifierSchemeCodeUnspsc190501.10252029': __('identifierSchemeCodeUnspsc190501.10252029'),
	'identifierSchemeCodeUnspsc190501.10252030': __('identifierSchemeCodeUnspsc190501.10252030'),
	'identifierSchemeCodeUnspsc190501.10252031': __('identifierSchemeCodeUnspsc190501.10252031'),
	'identifierSchemeCodeUnspsc190501.10252032': __('identifierSchemeCodeUnspsc190501.10252032'),
	'identifierSchemeCodeUnspsc190501.10252033': __('identifierSchemeCodeUnspsc190501.10252033'),
	'identifierSchemeCodeUnspsc190501.10252034': __('identifierSchemeCodeUnspsc190501.10252034'),
	'identifierSchemeCodeUnspsc190501.10252035': __('identifierSchemeCodeUnspsc190501.10252035'),
	'identifierSchemeCodeUnspsc190501.10252036': __('identifierSchemeCodeUnspsc190501.10252036'),
	'identifierSchemeCodeUnspsc190501.10252037': __('identifierSchemeCodeUnspsc190501.10252037'),
	'identifierSchemeCodeUnspsc190501.10252038': __('identifierSchemeCodeUnspsc190501.10252038'),
	'identifierSchemeCodeUnspsc190501.10252039': __('identifierSchemeCodeUnspsc190501.10252039'),
	'identifierSchemeCodeUnspsc190501.10252040': __('identifierSchemeCodeUnspsc190501.10252040'),
	'identifierSchemeCodeUnspsc190501.10252041': __('identifierSchemeCodeUnspsc190501.10252041'),
	'identifierSchemeCodeUnspsc190501.10252042': __('identifierSchemeCodeUnspsc190501.10252042'),
	'identifierSchemeCodeUnspsc190501.10252043': __('identifierSchemeCodeUnspsc190501.10252043'),
	'identifierSchemeCodeUnspsc190501.10252044': __('identifierSchemeCodeUnspsc190501.10252044'),
	'identifierSchemeCodeUnspsc190501.10252045': __('identifierSchemeCodeUnspsc190501.10252045'),
	'identifierSchemeCodeUnspsc190501.10252046': __('identifierSchemeCodeUnspsc190501.10252046'),
	'identifierSchemeCodeUnspsc190501.10252047': __('identifierSchemeCodeUnspsc190501.10252047'),
	'identifierSchemeCodeUnspsc190501.10252048': __('identifierSchemeCodeUnspsc190501.10252048'),
	'identifierSchemeCodeUnspsc190501.10252049': __('identifierSchemeCodeUnspsc190501.10252049'),
	'identifierSchemeCodeUnspsc190501.10252050': __('identifierSchemeCodeUnspsc190501.10252050'),
	'identifierSchemeCodeUnspsc190501.10252051': __('identifierSchemeCodeUnspsc190501.10252051'),
	'identifierSchemeCodeUnspsc190501.10252052': __('identifierSchemeCodeUnspsc190501.10252052'),
	'identifierSchemeCodeUnspsc190501.10252053': __('identifierSchemeCodeUnspsc190501.10252053'),
	'identifierSchemeCodeUnspsc190501.10252054': __('identifierSchemeCodeUnspsc190501.10252054'),
	'identifierSchemeCodeUnspsc190501.10252055': __('identifierSchemeCodeUnspsc190501.10252055'),
	'identifierSchemeCodeUnspsc190501.10252056': __('identifierSchemeCodeUnspsc190501.10252056'),
	'identifierSchemeCodeUnspsc190501.10252057': __('identifierSchemeCodeUnspsc190501.10252057'),
	'identifierSchemeCodeUnspsc190501.10252058': __('identifierSchemeCodeUnspsc190501.10252058'),
	'identifierSchemeCodeUnspsc190501.10252059': __('identifierSchemeCodeUnspsc190501.10252059'),
	'identifierSchemeCodeUnspsc190501.10252060': __('identifierSchemeCodeUnspsc190501.10252060'),
	'identifierSchemeCodeUnspsc190501.10252061': __('identifierSchemeCodeUnspsc190501.10252061'),
	'identifierSchemeCodeUnspsc190501.10252062': __('identifierSchemeCodeUnspsc190501.10252062'),
	'identifierSchemeCodeUnspsc190501.10252063': __('identifierSchemeCodeUnspsc190501.10252063'),
	'identifierSchemeCodeUnspsc190501.10252064': __('identifierSchemeCodeUnspsc190501.10252064'),
	'identifierSchemeCodeUnspsc190501.10252065': __('identifierSchemeCodeUnspsc190501.10252065'),
	'identifierSchemeCodeUnspsc190501.10252067': __('identifierSchemeCodeUnspsc190501.10252067'),
	'identifierSchemeCodeUnspsc190501.10252100': __('identifierSchemeCodeUnspsc190501.10252100'),
	'identifierSchemeCodeUnspsc190501.10252101': __('identifierSchemeCodeUnspsc190501.10252101'),
	'identifierSchemeCodeUnspsc190501.10252102': __('identifierSchemeCodeUnspsc190501.10252102'),
	'identifierSchemeCodeUnspsc190501.10252103': __('identifierSchemeCodeUnspsc190501.10252103'),
	'identifierSchemeCodeUnspsc190501.10252104': __('identifierSchemeCodeUnspsc190501.10252104'),
	'identifierSchemeCodeUnspsc190501.10252105': __('identifierSchemeCodeUnspsc190501.10252105'),
	'identifierSchemeCodeUnspsc190501.10252106': __('identifierSchemeCodeUnspsc190501.10252106'),
	'identifierSchemeCodeUnspsc190501.10252107': __('identifierSchemeCodeUnspsc190501.10252107'),
	'identifierSchemeCodeUnspsc190501.10252108': __('identifierSchemeCodeUnspsc190501.10252108'),
	'identifierSchemeCodeUnspsc190501.10252109': __('identifierSchemeCodeUnspsc190501.10252109'),
	'identifierSchemeCodeUnspsc190501.10252110': __('identifierSchemeCodeUnspsc190501.10252110'),
	'identifierSchemeCodeUnspsc190501.10252111': __('identifierSchemeCodeUnspsc190501.10252111'),
	'identifierSchemeCodeUnspsc190501.10252112': __('identifierSchemeCodeUnspsc190501.10252112'),
	'identifierSchemeCodeUnspsc190501.10252200': __('identifierSchemeCodeUnspsc190501.10252200'),
	'identifierSchemeCodeUnspsc190501.10252201': __('identifierSchemeCodeUnspsc190501.10252201'),
	'identifierSchemeCodeUnspsc190501.10252202': __('identifierSchemeCodeUnspsc190501.10252202'),
	'identifierSchemeCodeUnspsc190501.10252203': __('identifierSchemeCodeUnspsc190501.10252203'),
	'identifierSchemeCodeUnspsc190501.10252204': __('identifierSchemeCodeUnspsc190501.10252204'),
	'identifierSchemeCodeUnspsc190501.10252205': __('identifierSchemeCodeUnspsc190501.10252205'),
	'identifierSchemeCodeUnspsc190501.10252206': __('identifierSchemeCodeUnspsc190501.10252206'),
	'identifierSchemeCodeUnspsc190501.10252207': __('identifierSchemeCodeUnspsc190501.10252207'),
	'identifierSchemeCodeUnspsc190501.10252208': __('identifierSchemeCodeUnspsc190501.10252208'),
	'identifierSchemeCodeUnspsc190501.10252209': __('identifierSchemeCodeUnspsc190501.10252209'),
	'identifierSchemeCodeUnspsc190501.10252210': __('identifierSchemeCodeUnspsc190501.10252210'),
	'identifierSchemeCodeUnspsc190501.10252211': __('identifierSchemeCodeUnspsc190501.10252211'),
	'identifierSchemeCodeUnspsc190501.10252212': __('identifierSchemeCodeUnspsc190501.10252212'),
	'identifierSchemeCodeUnspsc190501.10252213': __('identifierSchemeCodeUnspsc190501.10252213'),
	'identifierSchemeCodeUnspsc190501.10252300': __('identifierSchemeCodeUnspsc190501.10252300'),
	'identifierSchemeCodeUnspsc190501.10252301': __('identifierSchemeCodeUnspsc190501.10252301'),
	'identifierSchemeCodeUnspsc190501.10252302': __('identifierSchemeCodeUnspsc190501.10252302'),
	'identifierSchemeCodeUnspsc190501.10252400': __('identifierSchemeCodeUnspsc190501.10252400'),
	'identifierSchemeCodeUnspsc190501.10252401': __('identifierSchemeCodeUnspsc190501.10252401'),
	'identifierSchemeCodeUnspsc190501.10252402': __('identifierSchemeCodeUnspsc190501.10252402'),
	'identifierSchemeCodeUnspsc190501.10252403': __('identifierSchemeCodeUnspsc190501.10252403'),
	'identifierSchemeCodeUnspsc190501.10252404': __('identifierSchemeCodeUnspsc190501.10252404'),
	'identifierSchemeCodeUnspsc190501.10252405': __('identifierSchemeCodeUnspsc190501.10252405'),
	'identifierSchemeCodeUnspsc190501.10252406': __('identifierSchemeCodeUnspsc190501.10252406'),
	'identifierSchemeCodeUnspsc190501.10300000': __('identifierSchemeCodeUnspsc190501.10300000'),
	'identifierSchemeCodeUnspsc190501.10301500': __('identifierSchemeCodeUnspsc190501.10301500'),
	'identifierSchemeCodeUnspsc190501.10301501': __('identifierSchemeCodeUnspsc190501.10301501'),
	'identifierSchemeCodeUnspsc190501.10301502': __('identifierSchemeCodeUnspsc190501.10301502'),
	'identifierSchemeCodeUnspsc190501.10301503': __('identifierSchemeCodeUnspsc190501.10301503'),
	'identifierSchemeCodeUnspsc190501.10301504': __('identifierSchemeCodeUnspsc190501.10301504'),
	'identifierSchemeCodeUnspsc190501.10301505': __('identifierSchemeCodeUnspsc190501.10301505'),
	'identifierSchemeCodeUnspsc190501.10301506': __('identifierSchemeCodeUnspsc190501.10301506'),
	'identifierSchemeCodeUnspsc190501.10301507': __('identifierSchemeCodeUnspsc190501.10301507'),
	'identifierSchemeCodeUnspsc190501.10301508': __('identifierSchemeCodeUnspsc190501.10301508'),
	'identifierSchemeCodeUnspsc190501.10301509': __('identifierSchemeCodeUnspsc190501.10301509'),
	'identifierSchemeCodeUnspsc190501.10301510': __('identifierSchemeCodeUnspsc190501.10301510'),
	'identifierSchemeCodeUnspsc190501.10301511': __('identifierSchemeCodeUnspsc190501.10301511'),
	'identifierSchemeCodeUnspsc190501.10301512': __('identifierSchemeCodeUnspsc190501.10301512'),
	'identifierSchemeCodeUnspsc190501.10301513': __('identifierSchemeCodeUnspsc190501.10301513'),
	'identifierSchemeCodeUnspsc190501.10301514': __('identifierSchemeCodeUnspsc190501.10301514'),
	'identifierSchemeCodeUnspsc190501.10301515': __('identifierSchemeCodeUnspsc190501.10301515'),
	'identifierSchemeCodeUnspsc190501.10301516': __('identifierSchemeCodeUnspsc190501.10301516'),
	'identifierSchemeCodeUnspsc190501.10301517': __('identifierSchemeCodeUnspsc190501.10301517'),
	'identifierSchemeCodeUnspsc190501.10301518': __('identifierSchemeCodeUnspsc190501.10301518'),
	'identifierSchemeCodeUnspsc190501.10301519': __('identifierSchemeCodeUnspsc190501.10301519'),
	'identifierSchemeCodeUnspsc190501.10301520': __('identifierSchemeCodeUnspsc190501.10301520'),
	'identifierSchemeCodeUnspsc190501.10301521': __('identifierSchemeCodeUnspsc190501.10301521'),
	'identifierSchemeCodeUnspsc190501.10301522': __('identifierSchemeCodeUnspsc190501.10301522'),
	'identifierSchemeCodeUnspsc190501.10301523': __('identifierSchemeCodeUnspsc190501.10301523'),
	'identifierSchemeCodeUnspsc190501.10301600': __('identifierSchemeCodeUnspsc190501.10301600'),
	'identifierSchemeCodeUnspsc190501.10301601': __('identifierSchemeCodeUnspsc190501.10301601'),
	'identifierSchemeCodeUnspsc190501.10301602': __('identifierSchemeCodeUnspsc190501.10301602'),
	'identifierSchemeCodeUnspsc190501.10301603': __('identifierSchemeCodeUnspsc190501.10301603'),
	'identifierSchemeCodeUnspsc190501.10301604': __('identifierSchemeCodeUnspsc190501.10301604'),
	'identifierSchemeCodeUnspsc190501.10301605': __('identifierSchemeCodeUnspsc190501.10301605'),
	'identifierSchemeCodeUnspsc190501.10301606': __('identifierSchemeCodeUnspsc190501.10301606'),
	'identifierSchemeCodeUnspsc190501.10301607': __('identifierSchemeCodeUnspsc190501.10301607'),
	'identifierSchemeCodeUnspsc190501.10301608': __('identifierSchemeCodeUnspsc190501.10301608'),
	'identifierSchemeCodeUnspsc190501.10301609': __('identifierSchemeCodeUnspsc190501.10301609'),
	'identifierSchemeCodeUnspsc190501.10301610': __('identifierSchemeCodeUnspsc190501.10301610'),
	'identifierSchemeCodeUnspsc190501.10301700': __('identifierSchemeCodeUnspsc190501.10301700'),
	'identifierSchemeCodeUnspsc190501.10301701': __('identifierSchemeCodeUnspsc190501.10301701'),
	'identifierSchemeCodeUnspsc190501.10301702': __('identifierSchemeCodeUnspsc190501.10301702'),
	'identifierSchemeCodeUnspsc190501.10301703': __('identifierSchemeCodeUnspsc190501.10301703'),
	'identifierSchemeCodeUnspsc190501.10301704': __('identifierSchemeCodeUnspsc190501.10301704'),
	'identifierSchemeCodeUnspsc190501.10301705': __('identifierSchemeCodeUnspsc190501.10301705'),
	'identifierSchemeCodeUnspsc190501.10301706': __('identifierSchemeCodeUnspsc190501.10301706'),
	'identifierSchemeCodeUnspsc190501.10301707': __('identifierSchemeCodeUnspsc190501.10301707'),
	'identifierSchemeCodeUnspsc190501.10301708': __('identifierSchemeCodeUnspsc190501.10301708'),
	'identifierSchemeCodeUnspsc190501.10301709': __('identifierSchemeCodeUnspsc190501.10301709'),
	'identifierSchemeCodeUnspsc190501.10301710': __('identifierSchemeCodeUnspsc190501.10301710'),
	'identifierSchemeCodeUnspsc190501.10301711': __('identifierSchemeCodeUnspsc190501.10301711'),
	'identifierSchemeCodeUnspsc190501.10301712': __('identifierSchemeCodeUnspsc190501.10301712'),
	'identifierSchemeCodeUnspsc190501.10301713': __('identifierSchemeCodeUnspsc190501.10301713'),
	'identifierSchemeCodeUnspsc190501.10301714': __('identifierSchemeCodeUnspsc190501.10301714'),
	'identifierSchemeCodeUnspsc190501.10301715': __('identifierSchemeCodeUnspsc190501.10301715'),
	'identifierSchemeCodeUnspsc190501.10301716': __('identifierSchemeCodeUnspsc190501.10301716'),
	'identifierSchemeCodeUnspsc190501.10301717': __('identifierSchemeCodeUnspsc190501.10301717'),
	'identifierSchemeCodeUnspsc190501.10301718': __('identifierSchemeCodeUnspsc190501.10301718'),
	'identifierSchemeCodeUnspsc190501.10301719': __('identifierSchemeCodeUnspsc190501.10301719'),
	'identifierSchemeCodeUnspsc190501.10301720': __('identifierSchemeCodeUnspsc190501.10301720'),
	'identifierSchemeCodeUnspsc190501.10301721': __('identifierSchemeCodeUnspsc190501.10301721'),
	'identifierSchemeCodeUnspsc190501.10301722': __('identifierSchemeCodeUnspsc190501.10301722'),
	'identifierSchemeCodeUnspsc190501.10301723': __('identifierSchemeCodeUnspsc190501.10301723'),
	'identifierSchemeCodeUnspsc190501.10301724': __('identifierSchemeCodeUnspsc190501.10301724'),
	'identifierSchemeCodeUnspsc190501.10301725': __('identifierSchemeCodeUnspsc190501.10301725'),
	'identifierSchemeCodeUnspsc190501.10301726': __('identifierSchemeCodeUnspsc190501.10301726'),
	'identifierSchemeCodeUnspsc190501.10301727': __('identifierSchemeCodeUnspsc190501.10301727'),
	'identifierSchemeCodeUnspsc190501.10301728': __('identifierSchemeCodeUnspsc190501.10301728'),
	'identifierSchemeCodeUnspsc190501.10301729': __('identifierSchemeCodeUnspsc190501.10301729'),
	'identifierSchemeCodeUnspsc190501.10301730': __('identifierSchemeCodeUnspsc190501.10301730'),
	'identifierSchemeCodeUnspsc190501.10301731': __('identifierSchemeCodeUnspsc190501.10301731'),
	'identifierSchemeCodeUnspsc190501.10301732': __('identifierSchemeCodeUnspsc190501.10301732'),
	'identifierSchemeCodeUnspsc190501.10301733': __('identifierSchemeCodeUnspsc190501.10301733'),
	'identifierSchemeCodeUnspsc190501.10301734': __('identifierSchemeCodeUnspsc190501.10301734'),
	'identifierSchemeCodeUnspsc190501.10301735': __('identifierSchemeCodeUnspsc190501.10301735'),
	'identifierSchemeCodeUnspsc190501.10301736': __('identifierSchemeCodeUnspsc190501.10301736'),
	'identifierSchemeCodeUnspsc190501.10301737': __('identifierSchemeCodeUnspsc190501.10301737'),
	'identifierSchemeCodeUnspsc190501.10301738': __('identifierSchemeCodeUnspsc190501.10301738'),
	'identifierSchemeCodeUnspsc190501.10301739': __('identifierSchemeCodeUnspsc190501.10301739'),
	'identifierSchemeCodeUnspsc190501.10301740': __('identifierSchemeCodeUnspsc190501.10301740'),
	'identifierSchemeCodeUnspsc190501.10301741': __('identifierSchemeCodeUnspsc190501.10301741'),
	'identifierSchemeCodeUnspsc190501.10301742': __('identifierSchemeCodeUnspsc190501.10301742'),
	'identifierSchemeCodeUnspsc190501.10301743': __('identifierSchemeCodeUnspsc190501.10301743'),
	'identifierSchemeCodeUnspsc190501.10301744': __('identifierSchemeCodeUnspsc190501.10301744'),
	'identifierSchemeCodeUnspsc190501.10301745': __('identifierSchemeCodeUnspsc190501.10301745'),
	'identifierSchemeCodeUnspsc190501.10301746': __('identifierSchemeCodeUnspsc190501.10301746'),
	'identifierSchemeCodeUnspsc190501.10301800': __('identifierSchemeCodeUnspsc190501.10301800'),
	'identifierSchemeCodeUnspsc190501.10301801': __('identifierSchemeCodeUnspsc190501.10301801'),
	'identifierSchemeCodeUnspsc190501.10301802': __('identifierSchemeCodeUnspsc190501.10301802'),
	'identifierSchemeCodeUnspsc190501.10301803': __('identifierSchemeCodeUnspsc190501.10301803'),
	'identifierSchemeCodeUnspsc190501.10301804': __('identifierSchemeCodeUnspsc190501.10301804'),
	'identifierSchemeCodeUnspsc190501.10301805': __('identifierSchemeCodeUnspsc190501.10301805'),
	'identifierSchemeCodeUnspsc190501.10301806': __('identifierSchemeCodeUnspsc190501.10301806'),
	'identifierSchemeCodeUnspsc190501.10301807': __('identifierSchemeCodeUnspsc190501.10301807'),
	'identifierSchemeCodeUnspsc190501.10301808': __('identifierSchemeCodeUnspsc190501.10301808'),
	'identifierSchemeCodeUnspsc190501.10301809': __('identifierSchemeCodeUnspsc190501.10301809'),
	'identifierSchemeCodeUnspsc190501.10301810': __('identifierSchemeCodeUnspsc190501.10301810'),
	'identifierSchemeCodeUnspsc190501.10301811': __('identifierSchemeCodeUnspsc190501.10301811'),
	'identifierSchemeCodeUnspsc190501.10301812': __('identifierSchemeCodeUnspsc190501.10301812'),
	'identifierSchemeCodeUnspsc190501.10301813': __('identifierSchemeCodeUnspsc190501.10301813'),
	'identifierSchemeCodeUnspsc190501.10301900': __('identifierSchemeCodeUnspsc190501.10301900'),
	'identifierSchemeCodeUnspsc190501.10301901': __('identifierSchemeCodeUnspsc190501.10301901'),
	'identifierSchemeCodeUnspsc190501.10301902': __('identifierSchemeCodeUnspsc190501.10301902'),
	'identifierSchemeCodeUnspsc190501.10301903': __('identifierSchemeCodeUnspsc190501.10301903'),
	'identifierSchemeCodeUnspsc190501.10301904': __('identifierSchemeCodeUnspsc190501.10301904'),
	'identifierSchemeCodeUnspsc190501.10301905': __('identifierSchemeCodeUnspsc190501.10301905'),
	'identifierSchemeCodeUnspsc190501.10301906': __('identifierSchemeCodeUnspsc190501.10301906'),
	'identifierSchemeCodeUnspsc190501.10301907': __('identifierSchemeCodeUnspsc190501.10301907'),
	'identifierSchemeCodeUnspsc190501.10301908': __('identifierSchemeCodeUnspsc190501.10301908'),
	'identifierSchemeCodeUnspsc190501.10301909': __('identifierSchemeCodeUnspsc190501.10301909'),
	'identifierSchemeCodeUnspsc190501.10301910': __('identifierSchemeCodeUnspsc190501.10301910'),
	'identifierSchemeCodeUnspsc190501.10301911': __('identifierSchemeCodeUnspsc190501.10301911'),
	'identifierSchemeCodeUnspsc190501.10301912': __('identifierSchemeCodeUnspsc190501.10301912'),
	'identifierSchemeCodeUnspsc190501.10301913': __('identifierSchemeCodeUnspsc190501.10301913'),
	'identifierSchemeCodeUnspsc190501.10301914': __('identifierSchemeCodeUnspsc190501.10301914'),
	'identifierSchemeCodeUnspsc190501.10301915': __('identifierSchemeCodeUnspsc190501.10301915'),
	'identifierSchemeCodeUnspsc190501.10301916': __('identifierSchemeCodeUnspsc190501.10301916'),
	'identifierSchemeCodeUnspsc190501.10301917': __('identifierSchemeCodeUnspsc190501.10301917'),
	'identifierSchemeCodeUnspsc190501.10301918': __('identifierSchemeCodeUnspsc190501.10301918'),
	'identifierSchemeCodeUnspsc190501.10301919': __('identifierSchemeCodeUnspsc190501.10301919'),
	'identifierSchemeCodeUnspsc190501.10301920': __('identifierSchemeCodeUnspsc190501.10301920'),
	'identifierSchemeCodeUnspsc190501.10301921': __('identifierSchemeCodeUnspsc190501.10301921'),
	'identifierSchemeCodeUnspsc190501.10301922': __('identifierSchemeCodeUnspsc190501.10301922'),
	'identifierSchemeCodeUnspsc190501.10301923': __('identifierSchemeCodeUnspsc190501.10301923'),
	'identifierSchemeCodeUnspsc190501.10301924': __('identifierSchemeCodeUnspsc190501.10301924'),
	'identifierSchemeCodeUnspsc190501.10301925': __('identifierSchemeCodeUnspsc190501.10301925'),
	'identifierSchemeCodeUnspsc190501.10301926': __('identifierSchemeCodeUnspsc190501.10301926'),
	'identifierSchemeCodeUnspsc190501.10301927': __('identifierSchemeCodeUnspsc190501.10301927'),
	'identifierSchemeCodeUnspsc190501.10301928': __('identifierSchemeCodeUnspsc190501.10301928'),
	'identifierSchemeCodeUnspsc190501.10301929': __('identifierSchemeCodeUnspsc190501.10301929'),
	'identifierSchemeCodeUnspsc190501.10301930': __('identifierSchemeCodeUnspsc190501.10301930'),
	'identifierSchemeCodeUnspsc190501.10301931': __('identifierSchemeCodeUnspsc190501.10301931'),
	'identifierSchemeCodeUnspsc190501.10301932': __('identifierSchemeCodeUnspsc190501.10301932'),
	'identifierSchemeCodeUnspsc190501.10301933': __('identifierSchemeCodeUnspsc190501.10301933'),
	'identifierSchemeCodeUnspsc190501.10301934': __('identifierSchemeCodeUnspsc190501.10301934'),
	'identifierSchemeCodeUnspsc190501.10301935': __('identifierSchemeCodeUnspsc190501.10301935'),
	'identifierSchemeCodeUnspsc190501.10301936': __('identifierSchemeCodeUnspsc190501.10301936'),
	'identifierSchemeCodeUnspsc190501.10301937': __('identifierSchemeCodeUnspsc190501.10301937'),
	'identifierSchemeCodeUnspsc190501.10301938': __('identifierSchemeCodeUnspsc190501.10301938'),
	'identifierSchemeCodeUnspsc190501.10301939': __('identifierSchemeCodeUnspsc190501.10301939'),
	'identifierSchemeCodeUnspsc190501.10301940': __('identifierSchemeCodeUnspsc190501.10301940'),
	'identifierSchemeCodeUnspsc190501.10301941': __('identifierSchemeCodeUnspsc190501.10301941'),
	'identifierSchemeCodeUnspsc190501.10301942': __('identifierSchemeCodeUnspsc190501.10301942'),
	'identifierSchemeCodeUnspsc190501.10301943': __('identifierSchemeCodeUnspsc190501.10301943'),
	'identifierSchemeCodeUnspsc190501.10301944': __('identifierSchemeCodeUnspsc190501.10301944'),
	'identifierSchemeCodeUnspsc190501.10301945': __('identifierSchemeCodeUnspsc190501.10301945'),
	'identifierSchemeCodeUnspsc190501.10301946': __('identifierSchemeCodeUnspsc190501.10301946'),
	'identifierSchemeCodeUnspsc190501.10301947': __('identifierSchemeCodeUnspsc190501.10301947'),
	'identifierSchemeCodeUnspsc190501.10301948': __('identifierSchemeCodeUnspsc190501.10301948'),
	'identifierSchemeCodeUnspsc190501.10301949': __('identifierSchemeCodeUnspsc190501.10301949'),
	'identifierSchemeCodeUnspsc190501.10301950': __('identifierSchemeCodeUnspsc190501.10301950'),
	'identifierSchemeCodeUnspsc190501.10301951': __('identifierSchemeCodeUnspsc190501.10301951'),
	'identifierSchemeCodeUnspsc190501.10301952': __('identifierSchemeCodeUnspsc190501.10301952'),
	'identifierSchemeCodeUnspsc190501.10301953': __('identifierSchemeCodeUnspsc190501.10301953'),
	'identifierSchemeCodeUnspsc190501.10301954': __('identifierSchemeCodeUnspsc190501.10301954'),
	'identifierSchemeCodeUnspsc190501.10301955': __('identifierSchemeCodeUnspsc190501.10301955'),
	'identifierSchemeCodeUnspsc190501.10301956': __('identifierSchemeCodeUnspsc190501.10301956'),
	'identifierSchemeCodeUnspsc190501.10301957': __('identifierSchemeCodeUnspsc190501.10301957'),
	'identifierSchemeCodeUnspsc190501.10301958': __('identifierSchemeCodeUnspsc190501.10301958'),
	'identifierSchemeCodeUnspsc190501.10301959': __('identifierSchemeCodeUnspsc190501.10301959'),
	'identifierSchemeCodeUnspsc190501.10301960': __('identifierSchemeCodeUnspsc190501.10301960'),
	'identifierSchemeCodeUnspsc190501.10301961': __('identifierSchemeCodeUnspsc190501.10301961'),
	'identifierSchemeCodeUnspsc190501.10301962': __('identifierSchemeCodeUnspsc190501.10301962'),
	'identifierSchemeCodeUnspsc190501.10302000': __('identifierSchemeCodeUnspsc190501.10302000'),
	'identifierSchemeCodeUnspsc190501.10302001': __('identifierSchemeCodeUnspsc190501.10302001'),
	'identifierSchemeCodeUnspsc190501.10302002': __('identifierSchemeCodeUnspsc190501.10302002'),
	'identifierSchemeCodeUnspsc190501.10302003': __('identifierSchemeCodeUnspsc190501.10302003'),
	'identifierSchemeCodeUnspsc190501.10302004': __('identifierSchemeCodeUnspsc190501.10302004'),
	'identifierSchemeCodeUnspsc190501.10302005': __('identifierSchemeCodeUnspsc190501.10302005'),
	'identifierSchemeCodeUnspsc190501.10302006': __('identifierSchemeCodeUnspsc190501.10302006'),
	'identifierSchemeCodeUnspsc190501.10302007': __('identifierSchemeCodeUnspsc190501.10302007'),
	'identifierSchemeCodeUnspsc190501.10302008': __('identifierSchemeCodeUnspsc190501.10302008'),
	'identifierSchemeCodeUnspsc190501.10302009': __('identifierSchemeCodeUnspsc190501.10302009'),
	'identifierSchemeCodeUnspsc190501.10302010': __('identifierSchemeCodeUnspsc190501.10302010'),
	'identifierSchemeCodeUnspsc190501.10302011': __('identifierSchemeCodeUnspsc190501.10302011'),
	'identifierSchemeCodeUnspsc190501.10302012': __('identifierSchemeCodeUnspsc190501.10302012'),
	'identifierSchemeCodeUnspsc190501.10302013': __('identifierSchemeCodeUnspsc190501.10302013'),
	'identifierSchemeCodeUnspsc190501.10302014': __('identifierSchemeCodeUnspsc190501.10302014'),
	'identifierSchemeCodeUnspsc190501.10302015': __('identifierSchemeCodeUnspsc190501.10302015'),
	'identifierSchemeCodeUnspsc190501.10302016': __('identifierSchemeCodeUnspsc190501.10302016'),
	'identifierSchemeCodeUnspsc190501.10302017': __('identifierSchemeCodeUnspsc190501.10302017'),
	'identifierSchemeCodeUnspsc190501.10302018': __('identifierSchemeCodeUnspsc190501.10302018'),
	'identifierSchemeCodeUnspsc190501.10302019': __('identifierSchemeCodeUnspsc190501.10302019'),
	'identifierSchemeCodeUnspsc190501.10302020': __('identifierSchemeCodeUnspsc190501.10302020'),
	'identifierSchemeCodeUnspsc190501.10302021': __('identifierSchemeCodeUnspsc190501.10302021'),
	'identifierSchemeCodeUnspsc190501.10302022': __('identifierSchemeCodeUnspsc190501.10302022'),
	'identifierSchemeCodeUnspsc190501.10302023': __('identifierSchemeCodeUnspsc190501.10302023'),
	'identifierSchemeCodeUnspsc190501.10302024': __('identifierSchemeCodeUnspsc190501.10302024'),
	'identifierSchemeCodeUnspsc190501.10302025': __('identifierSchemeCodeUnspsc190501.10302025'),
	'identifierSchemeCodeUnspsc190501.10302026': __('identifierSchemeCodeUnspsc190501.10302026'),
	'identifierSchemeCodeUnspsc190501.10302027': __('identifierSchemeCodeUnspsc190501.10302027'),
	'identifierSchemeCodeUnspsc190501.10302028': __('identifierSchemeCodeUnspsc190501.10302028'),
	'identifierSchemeCodeUnspsc190501.10302029': __('identifierSchemeCodeUnspsc190501.10302029'),
	'identifierSchemeCodeUnspsc190501.10302030': __('identifierSchemeCodeUnspsc190501.10302030'),
	'identifierSchemeCodeUnspsc190501.10302031': __('identifierSchemeCodeUnspsc190501.10302031'),
	'identifierSchemeCodeUnspsc190501.10302032': __('identifierSchemeCodeUnspsc190501.10302032'),
	'identifierSchemeCodeUnspsc190501.10302033': __('identifierSchemeCodeUnspsc190501.10302033'),
	'identifierSchemeCodeUnspsc190501.10302034': __('identifierSchemeCodeUnspsc190501.10302034'),
	'identifierSchemeCodeUnspsc190501.10302035': __('identifierSchemeCodeUnspsc190501.10302035'),
	'identifierSchemeCodeUnspsc190501.10302036': __('identifierSchemeCodeUnspsc190501.10302036'),
	'identifierSchemeCodeUnspsc190501.10302037': __('identifierSchemeCodeUnspsc190501.10302037'),
	'identifierSchemeCodeUnspsc190501.10302100': __('identifierSchemeCodeUnspsc190501.10302100'),
	'identifierSchemeCodeUnspsc190501.10302101': __('identifierSchemeCodeUnspsc190501.10302101'),
	'identifierSchemeCodeUnspsc190501.10302102': __('identifierSchemeCodeUnspsc190501.10302102'),
	'identifierSchemeCodeUnspsc190501.10302103': __('identifierSchemeCodeUnspsc190501.10302103'),
	'identifierSchemeCodeUnspsc190501.10302104': __('identifierSchemeCodeUnspsc190501.10302104'),
	'identifierSchemeCodeUnspsc190501.10302105': __('identifierSchemeCodeUnspsc190501.10302105'),
	'identifierSchemeCodeUnspsc190501.10302106': __('identifierSchemeCodeUnspsc190501.10302106'),
	'identifierSchemeCodeUnspsc190501.10302107': __('identifierSchemeCodeUnspsc190501.10302107'),
	'identifierSchemeCodeUnspsc190501.10302108': __('identifierSchemeCodeUnspsc190501.10302108'),
	'identifierSchemeCodeUnspsc190501.10302109': __('identifierSchemeCodeUnspsc190501.10302109'),
	'identifierSchemeCodeUnspsc190501.10302110': __('identifierSchemeCodeUnspsc190501.10302110'),
	'identifierSchemeCodeUnspsc190501.10302111': __('identifierSchemeCodeUnspsc190501.10302111'),
	'identifierSchemeCodeUnspsc190501.10302112': __('identifierSchemeCodeUnspsc190501.10302112'),
	'identifierSchemeCodeUnspsc190501.10302113': __('identifierSchemeCodeUnspsc190501.10302113'),
	'identifierSchemeCodeUnspsc190501.10302114': __('identifierSchemeCodeUnspsc190501.10302114'),
	'identifierSchemeCodeUnspsc190501.10302115': __('identifierSchemeCodeUnspsc190501.10302115'),
	'identifierSchemeCodeUnspsc190501.10302116': __('identifierSchemeCodeUnspsc190501.10302116'),
	'identifierSchemeCodeUnspsc190501.10302117': __('identifierSchemeCodeUnspsc190501.10302117'),
	'identifierSchemeCodeUnspsc190501.10302118': __('identifierSchemeCodeUnspsc190501.10302118'),
	'identifierSchemeCodeUnspsc190501.10302119': __('identifierSchemeCodeUnspsc190501.10302119'),
	'identifierSchemeCodeUnspsc190501.10302120': __('identifierSchemeCodeUnspsc190501.10302120'),
	'identifierSchemeCodeUnspsc190501.10302121': __('identifierSchemeCodeUnspsc190501.10302121'),
	'identifierSchemeCodeUnspsc190501.10302122': __('identifierSchemeCodeUnspsc190501.10302122'),
	'identifierSchemeCodeUnspsc190501.10302123': __('identifierSchemeCodeUnspsc190501.10302123'),
	'identifierSchemeCodeUnspsc190501.10302124': __('identifierSchemeCodeUnspsc190501.10302124'),
	'identifierSchemeCodeUnspsc190501.10302125': __('identifierSchemeCodeUnspsc190501.10302125'),
	'identifierSchemeCodeUnspsc190501.10302126': __('identifierSchemeCodeUnspsc190501.10302126'),
	'identifierSchemeCodeUnspsc190501.10302127': __('identifierSchemeCodeUnspsc190501.10302127'),
	'identifierSchemeCodeUnspsc190501.10302128': __('identifierSchemeCodeUnspsc190501.10302128'),
	'identifierSchemeCodeUnspsc190501.10302129': __('identifierSchemeCodeUnspsc190501.10302129'),
	'identifierSchemeCodeUnspsc190501.10302130': __('identifierSchemeCodeUnspsc190501.10302130'),
	'identifierSchemeCodeUnspsc190501.10302131': __('identifierSchemeCodeUnspsc190501.10302131'),
	'identifierSchemeCodeUnspsc190501.10302132': __('identifierSchemeCodeUnspsc190501.10302132'),
	'identifierSchemeCodeUnspsc190501.10302133': __('identifierSchemeCodeUnspsc190501.10302133'),
	'identifierSchemeCodeUnspsc190501.10302134': __('identifierSchemeCodeUnspsc190501.10302134'),
	'identifierSchemeCodeUnspsc190501.10302135': __('identifierSchemeCodeUnspsc190501.10302135'),
	'identifierSchemeCodeUnspsc190501.10302136': __('identifierSchemeCodeUnspsc190501.10302136'),
	'identifierSchemeCodeUnspsc190501.10302137': __('identifierSchemeCodeUnspsc190501.10302137'),
	'identifierSchemeCodeUnspsc190501.10302138': __('identifierSchemeCodeUnspsc190501.10302138'),
	'identifierSchemeCodeUnspsc190501.10302139': __('identifierSchemeCodeUnspsc190501.10302139'),
	'identifierSchemeCodeUnspsc190501.10302140': __('identifierSchemeCodeUnspsc190501.10302140'),
	'identifierSchemeCodeUnspsc190501.10302141': __('identifierSchemeCodeUnspsc190501.10302141'),
	'identifierSchemeCodeUnspsc190501.10302142': __('identifierSchemeCodeUnspsc190501.10302142'),
	'identifierSchemeCodeUnspsc190501.10302143': __('identifierSchemeCodeUnspsc190501.10302143'),
	'identifierSchemeCodeUnspsc190501.10302144': __('identifierSchemeCodeUnspsc190501.10302144'),
	'identifierSchemeCodeUnspsc190501.10302145': __('identifierSchemeCodeUnspsc190501.10302145'),
	'identifierSchemeCodeUnspsc190501.10302146': __('identifierSchemeCodeUnspsc190501.10302146'),
	'identifierSchemeCodeUnspsc190501.10302147': __('identifierSchemeCodeUnspsc190501.10302147'),
	'identifierSchemeCodeUnspsc190501.10302148': __('identifierSchemeCodeUnspsc190501.10302148'),
	'identifierSchemeCodeUnspsc190501.10302149': __('identifierSchemeCodeUnspsc190501.10302149'),
	'identifierSchemeCodeUnspsc190501.10302150': __('identifierSchemeCodeUnspsc190501.10302150'),
	'identifierSchemeCodeUnspsc190501.10302151': __('identifierSchemeCodeUnspsc190501.10302151'),
	'identifierSchemeCodeUnspsc190501.10302152': __('identifierSchemeCodeUnspsc190501.10302152'),
	'identifierSchemeCodeUnspsc190501.10302153': __('identifierSchemeCodeUnspsc190501.10302153'),
	'identifierSchemeCodeUnspsc190501.10302154': __('identifierSchemeCodeUnspsc190501.10302154'),
	'identifierSchemeCodeUnspsc190501.10302155': __('identifierSchemeCodeUnspsc190501.10302155'),
	'identifierSchemeCodeUnspsc190501.10302156': __('identifierSchemeCodeUnspsc190501.10302156'),
	'identifierSchemeCodeUnspsc190501.10302157': __('identifierSchemeCodeUnspsc190501.10302157'),
	'identifierSchemeCodeUnspsc190501.10302158': __('identifierSchemeCodeUnspsc190501.10302158'),
	'identifierSchemeCodeUnspsc190501.10302159': __('identifierSchemeCodeUnspsc190501.10302159'),
	'identifierSchemeCodeUnspsc190501.10302160': __('identifierSchemeCodeUnspsc190501.10302160'),
	'identifierSchemeCodeUnspsc190501.10302161': __('identifierSchemeCodeUnspsc190501.10302161'),
	'identifierSchemeCodeUnspsc190501.10302162': __('identifierSchemeCodeUnspsc190501.10302162'),
	'identifierSchemeCodeUnspsc190501.10302163': __('identifierSchemeCodeUnspsc190501.10302163'),
	'identifierSchemeCodeUnspsc190501.10302164': __('identifierSchemeCodeUnspsc190501.10302164'),
	'identifierSchemeCodeUnspsc190501.10302165': __('identifierSchemeCodeUnspsc190501.10302165'),
	'identifierSchemeCodeUnspsc190501.10302166': __('identifierSchemeCodeUnspsc190501.10302166'),
	'identifierSchemeCodeUnspsc190501.10302167': __('identifierSchemeCodeUnspsc190501.10302167'),
	'identifierSchemeCodeUnspsc190501.10302168': __('identifierSchemeCodeUnspsc190501.10302168'),
	'identifierSchemeCodeUnspsc190501.10302169': __('identifierSchemeCodeUnspsc190501.10302169'),
	'identifierSchemeCodeUnspsc190501.10302170': __('identifierSchemeCodeUnspsc190501.10302170'),
	'identifierSchemeCodeUnspsc190501.10302171': __('identifierSchemeCodeUnspsc190501.10302171'),
	'identifierSchemeCodeUnspsc190501.10302172': __('identifierSchemeCodeUnspsc190501.10302172'),
	'identifierSchemeCodeUnspsc190501.10302173': __('identifierSchemeCodeUnspsc190501.10302173'),
	'identifierSchemeCodeUnspsc190501.10302174': __('identifierSchemeCodeUnspsc190501.10302174'),
	'identifierSchemeCodeUnspsc190501.10302175': __('identifierSchemeCodeUnspsc190501.10302175'),
	'identifierSchemeCodeUnspsc190501.10302176': __('identifierSchemeCodeUnspsc190501.10302176'),
	'identifierSchemeCodeUnspsc190501.10302177': __('identifierSchemeCodeUnspsc190501.10302177'),
	'identifierSchemeCodeUnspsc190501.10302178': __('identifierSchemeCodeUnspsc190501.10302178'),
	'identifierSchemeCodeUnspsc190501.10302179': __('identifierSchemeCodeUnspsc190501.10302179'),
	'identifierSchemeCodeUnspsc190501.10302180': __('identifierSchemeCodeUnspsc190501.10302180'),
	'identifierSchemeCodeUnspsc190501.10302181': __('identifierSchemeCodeUnspsc190501.10302181'),
	'identifierSchemeCodeUnspsc190501.10302182': __('identifierSchemeCodeUnspsc190501.10302182'),
	'identifierSchemeCodeUnspsc190501.10302183': __('identifierSchemeCodeUnspsc190501.10302183'),
	'identifierSchemeCodeUnspsc190501.10302184': __('identifierSchemeCodeUnspsc190501.10302184'),
	'identifierSchemeCodeUnspsc190501.10302185': __('identifierSchemeCodeUnspsc190501.10302185'),
	'identifierSchemeCodeUnspsc190501.10302186': __('identifierSchemeCodeUnspsc190501.10302186'),
	'identifierSchemeCodeUnspsc190501.10302200': __('identifierSchemeCodeUnspsc190501.10302200'),
	'identifierSchemeCodeUnspsc190501.10302201': __('identifierSchemeCodeUnspsc190501.10302201'),
	'identifierSchemeCodeUnspsc190501.10302202': __('identifierSchemeCodeUnspsc190501.10302202'),
	'identifierSchemeCodeUnspsc190501.10302203': __('identifierSchemeCodeUnspsc190501.10302203'),
	'identifierSchemeCodeUnspsc190501.10302204': __('identifierSchemeCodeUnspsc190501.10302204'),
	'identifierSchemeCodeUnspsc190501.10302205': __('identifierSchemeCodeUnspsc190501.10302205'),
	'identifierSchemeCodeUnspsc190501.10302206': __('identifierSchemeCodeUnspsc190501.10302206'),
	'identifierSchemeCodeUnspsc190501.10302207': __('identifierSchemeCodeUnspsc190501.10302207'),
	'identifierSchemeCodeUnspsc190501.10302208': __('identifierSchemeCodeUnspsc190501.10302208'),
	'identifierSchemeCodeUnspsc190501.10302209': __('identifierSchemeCodeUnspsc190501.10302209'),
	'identifierSchemeCodeUnspsc190501.10302210': __('identifierSchemeCodeUnspsc190501.10302210'),
	'identifierSchemeCodeUnspsc190501.10302211': __('identifierSchemeCodeUnspsc190501.10302211'),
	'identifierSchemeCodeUnspsc190501.10302212': __('identifierSchemeCodeUnspsc190501.10302212'),
	'identifierSchemeCodeUnspsc190501.10302213': __('identifierSchemeCodeUnspsc190501.10302213'),
	'identifierSchemeCodeUnspsc190501.10302214': __('identifierSchemeCodeUnspsc190501.10302214'),
	'identifierSchemeCodeUnspsc190501.10302215': __('identifierSchemeCodeUnspsc190501.10302215'),
	'identifierSchemeCodeUnspsc190501.10302216': __('identifierSchemeCodeUnspsc190501.10302216'),
	'identifierSchemeCodeUnspsc190501.10302217': __('identifierSchemeCodeUnspsc190501.10302217'),
	'identifierSchemeCodeUnspsc190501.10302218': __('identifierSchemeCodeUnspsc190501.10302218'),
	'identifierSchemeCodeUnspsc190501.10302219': __('identifierSchemeCodeUnspsc190501.10302219'),
	'identifierSchemeCodeUnspsc190501.10302220': __('identifierSchemeCodeUnspsc190501.10302220'),
	'identifierSchemeCodeUnspsc190501.10302221': __('identifierSchemeCodeUnspsc190501.10302221'),
	'identifierSchemeCodeUnspsc190501.10302222': __('identifierSchemeCodeUnspsc190501.10302222'),
	'identifierSchemeCodeUnspsc190501.10302223': __('identifierSchemeCodeUnspsc190501.10302223'),
	'identifierSchemeCodeUnspsc190501.10302224': __('identifierSchemeCodeUnspsc190501.10302224'),
	'identifierSchemeCodeUnspsc190501.10302225': __('identifierSchemeCodeUnspsc190501.10302225'),
	'identifierSchemeCodeUnspsc190501.10302226': __('identifierSchemeCodeUnspsc190501.10302226'),
	'identifierSchemeCodeUnspsc190501.10302227': __('identifierSchemeCodeUnspsc190501.10302227'),
	'identifierSchemeCodeUnspsc190501.10302228': __('identifierSchemeCodeUnspsc190501.10302228'),
	'identifierSchemeCodeUnspsc190501.10302229': __('identifierSchemeCodeUnspsc190501.10302229'),
	'identifierSchemeCodeUnspsc190501.10302230': __('identifierSchemeCodeUnspsc190501.10302230'),
	'identifierSchemeCodeUnspsc190501.10302231': __('identifierSchemeCodeUnspsc190501.10302231'),
	'identifierSchemeCodeUnspsc190501.10302232': __('identifierSchemeCodeUnspsc190501.10302232'),
	'identifierSchemeCodeUnspsc190501.10302233': __('identifierSchemeCodeUnspsc190501.10302233'),
	'identifierSchemeCodeUnspsc190501.10302234': __('identifierSchemeCodeUnspsc190501.10302234'),
	'identifierSchemeCodeUnspsc190501.10302235': __('identifierSchemeCodeUnspsc190501.10302235'),
	'identifierSchemeCodeUnspsc190501.10302236': __('identifierSchemeCodeUnspsc190501.10302236'),
	'identifierSchemeCodeUnspsc190501.10302237': __('identifierSchemeCodeUnspsc190501.10302237'),
	'identifierSchemeCodeUnspsc190501.10302238': __('identifierSchemeCodeUnspsc190501.10302238'),
	'identifierSchemeCodeUnspsc190501.10302239': __('identifierSchemeCodeUnspsc190501.10302239'),
	'identifierSchemeCodeUnspsc190501.10302240': __('identifierSchemeCodeUnspsc190501.10302240'),
	'identifierSchemeCodeUnspsc190501.10302241': __('identifierSchemeCodeUnspsc190501.10302241'),
	'identifierSchemeCodeUnspsc190501.10302300': __('identifierSchemeCodeUnspsc190501.10302300'),
	'identifierSchemeCodeUnspsc190501.10302301': __('identifierSchemeCodeUnspsc190501.10302301'),
	'identifierSchemeCodeUnspsc190501.10302302': __('identifierSchemeCodeUnspsc190501.10302302'),
	'identifierSchemeCodeUnspsc190501.10302303': __('identifierSchemeCodeUnspsc190501.10302303'),
	'identifierSchemeCodeUnspsc190501.10302304': __('identifierSchemeCodeUnspsc190501.10302304'),
	'identifierSchemeCodeUnspsc190501.10302305': __('identifierSchemeCodeUnspsc190501.10302305'),
	'identifierSchemeCodeUnspsc190501.10302306': __('identifierSchemeCodeUnspsc190501.10302306'),
	'identifierSchemeCodeUnspsc190501.10302307': __('identifierSchemeCodeUnspsc190501.10302307'),
	'identifierSchemeCodeUnspsc190501.10302308': __('identifierSchemeCodeUnspsc190501.10302308'),
	'identifierSchemeCodeUnspsc190501.10302309': __('identifierSchemeCodeUnspsc190501.10302309'),
	'identifierSchemeCodeUnspsc190501.10302310': __('identifierSchemeCodeUnspsc190501.10302310'),
	'identifierSchemeCodeUnspsc190501.10302311': __('identifierSchemeCodeUnspsc190501.10302311'),
	'identifierSchemeCodeUnspsc190501.10302312': __('identifierSchemeCodeUnspsc190501.10302312'),
	'identifierSchemeCodeUnspsc190501.10302313': __('identifierSchemeCodeUnspsc190501.10302313'),
	'identifierSchemeCodeUnspsc190501.10302314': __('identifierSchemeCodeUnspsc190501.10302314'),
	'identifierSchemeCodeUnspsc190501.10302315': __('identifierSchemeCodeUnspsc190501.10302315'),
	'identifierSchemeCodeUnspsc190501.10302316': __('identifierSchemeCodeUnspsc190501.10302316'),
	'identifierSchemeCodeUnspsc190501.10302317': __('identifierSchemeCodeUnspsc190501.10302317'),
	'identifierSchemeCodeUnspsc190501.10302318': __('identifierSchemeCodeUnspsc190501.10302318'),
	'identifierSchemeCodeUnspsc190501.10302319': __('identifierSchemeCodeUnspsc190501.10302319'),
	'identifierSchemeCodeUnspsc190501.10302320': __('identifierSchemeCodeUnspsc190501.10302320'),
	'identifierSchemeCodeUnspsc190501.10302321': __('identifierSchemeCodeUnspsc190501.10302321'),
	'identifierSchemeCodeUnspsc190501.10302322': __('identifierSchemeCodeUnspsc190501.10302322'),
	'identifierSchemeCodeUnspsc190501.10302323': __('identifierSchemeCodeUnspsc190501.10302323'),
	'identifierSchemeCodeUnspsc190501.10302324': __('identifierSchemeCodeUnspsc190501.10302324'),
	'identifierSchemeCodeUnspsc190501.10302325': __('identifierSchemeCodeUnspsc190501.10302325'),
	'identifierSchemeCodeUnspsc190501.10302326': __('identifierSchemeCodeUnspsc190501.10302326'),
	'identifierSchemeCodeUnspsc190501.10302327': __('identifierSchemeCodeUnspsc190501.10302327'),
	'identifierSchemeCodeUnspsc190501.10302328': __('identifierSchemeCodeUnspsc190501.10302328'),
	'identifierSchemeCodeUnspsc190501.10302329': __('identifierSchemeCodeUnspsc190501.10302329'),
	'identifierSchemeCodeUnspsc190501.10302330': __('identifierSchemeCodeUnspsc190501.10302330'),
	'identifierSchemeCodeUnspsc190501.10302331': __('identifierSchemeCodeUnspsc190501.10302331'),
	'identifierSchemeCodeUnspsc190501.10302332': __('identifierSchemeCodeUnspsc190501.10302332'),
	'identifierSchemeCodeUnspsc190501.10302333': __('identifierSchemeCodeUnspsc190501.10302333'),
	'identifierSchemeCodeUnspsc190501.10302334': __('identifierSchemeCodeUnspsc190501.10302334'),
	'identifierSchemeCodeUnspsc190501.10302335': __('identifierSchemeCodeUnspsc190501.10302335'),
	'identifierSchemeCodeUnspsc190501.10302336': __('identifierSchemeCodeUnspsc190501.10302336'),
	'identifierSchemeCodeUnspsc190501.10302337': __('identifierSchemeCodeUnspsc190501.10302337'),
	'identifierSchemeCodeUnspsc190501.10302338': __('identifierSchemeCodeUnspsc190501.10302338'),
	'identifierSchemeCodeUnspsc190501.10302339': __('identifierSchemeCodeUnspsc190501.10302339'),
	'identifierSchemeCodeUnspsc190501.10302340': __('identifierSchemeCodeUnspsc190501.10302340'),
	'identifierSchemeCodeUnspsc190501.10302341': __('identifierSchemeCodeUnspsc190501.10302341'),
	'identifierSchemeCodeUnspsc190501.10302342': __('identifierSchemeCodeUnspsc190501.10302342'),
	'identifierSchemeCodeUnspsc190501.10302343': __('identifierSchemeCodeUnspsc190501.10302343'),
	'identifierSchemeCodeUnspsc190501.10302344': __('identifierSchemeCodeUnspsc190501.10302344'),
	'identifierSchemeCodeUnspsc190501.10302345': __('identifierSchemeCodeUnspsc190501.10302345'),
	'identifierSchemeCodeUnspsc190501.10302346': __('identifierSchemeCodeUnspsc190501.10302346'),
	'identifierSchemeCodeUnspsc190501.10302347': __('identifierSchemeCodeUnspsc190501.10302347'),
	'identifierSchemeCodeUnspsc190501.10302348': __('identifierSchemeCodeUnspsc190501.10302348'),
	'identifierSchemeCodeUnspsc190501.10302349': __('identifierSchemeCodeUnspsc190501.10302349'),
	'identifierSchemeCodeUnspsc190501.10302350': __('identifierSchemeCodeUnspsc190501.10302350'),
	'identifierSchemeCodeUnspsc190501.10302351': __('identifierSchemeCodeUnspsc190501.10302351'),
	'identifierSchemeCodeUnspsc190501.10302352': __('identifierSchemeCodeUnspsc190501.10302352'),
	'identifierSchemeCodeUnspsc190501.10302353': __('identifierSchemeCodeUnspsc190501.10302353'),
	'identifierSchemeCodeUnspsc190501.10302354': __('identifierSchemeCodeUnspsc190501.10302354'),
	'identifierSchemeCodeUnspsc190501.10302355': __('identifierSchemeCodeUnspsc190501.10302355'),
	'identifierSchemeCodeUnspsc190501.10302356': __('identifierSchemeCodeUnspsc190501.10302356'),
	'identifierSchemeCodeUnspsc190501.10302357': __('identifierSchemeCodeUnspsc190501.10302357'),
	'identifierSchemeCodeUnspsc190501.10302358': __('identifierSchemeCodeUnspsc190501.10302358'),
	'identifierSchemeCodeUnspsc190501.10302359': __('identifierSchemeCodeUnspsc190501.10302359'),
	'identifierSchemeCodeUnspsc190501.10302360': __('identifierSchemeCodeUnspsc190501.10302360'),
	'identifierSchemeCodeUnspsc190501.10302361': __('identifierSchemeCodeUnspsc190501.10302361'),
	'identifierSchemeCodeUnspsc190501.10302362': __('identifierSchemeCodeUnspsc190501.10302362'),
	'identifierSchemeCodeUnspsc190501.10302363': __('identifierSchemeCodeUnspsc190501.10302363'),
	'identifierSchemeCodeUnspsc190501.10302364': __('identifierSchemeCodeUnspsc190501.10302364'),
	'identifierSchemeCodeUnspsc190501.10302365': __('identifierSchemeCodeUnspsc190501.10302365'),
	'identifierSchemeCodeUnspsc190501.10302366': __('identifierSchemeCodeUnspsc190501.10302366'),
	'identifierSchemeCodeUnspsc190501.10302367': __('identifierSchemeCodeUnspsc190501.10302367'),
	'identifierSchemeCodeUnspsc190501.10302368': __('identifierSchemeCodeUnspsc190501.10302368'),
	'identifierSchemeCodeUnspsc190501.10302369': __('identifierSchemeCodeUnspsc190501.10302369'),
	'identifierSchemeCodeUnspsc190501.10302370': __('identifierSchemeCodeUnspsc190501.10302370'),
	'identifierSchemeCodeUnspsc190501.10302371': __('identifierSchemeCodeUnspsc190501.10302371'),
	'identifierSchemeCodeUnspsc190501.10302372': __('identifierSchemeCodeUnspsc190501.10302372'),
	'identifierSchemeCodeUnspsc190501.10302373': __('identifierSchemeCodeUnspsc190501.10302373'),
	'identifierSchemeCodeUnspsc190501.10302374': __('identifierSchemeCodeUnspsc190501.10302374'),
	'identifierSchemeCodeUnspsc190501.10302375': __('identifierSchemeCodeUnspsc190501.10302375'),
	'identifierSchemeCodeUnspsc190501.10302376': __('identifierSchemeCodeUnspsc190501.10302376'),
	'identifierSchemeCodeUnspsc190501.10302377': __('identifierSchemeCodeUnspsc190501.10302377'),
	'identifierSchemeCodeUnspsc190501.10302378': __('identifierSchemeCodeUnspsc190501.10302378'),
	'identifierSchemeCodeUnspsc190501.10302379': __('identifierSchemeCodeUnspsc190501.10302379'),
	'identifierSchemeCodeUnspsc190501.10302380': __('identifierSchemeCodeUnspsc190501.10302380'),
	'identifierSchemeCodeUnspsc190501.10302381': __('identifierSchemeCodeUnspsc190501.10302381'),
	'identifierSchemeCodeUnspsc190501.10302382': __('identifierSchemeCodeUnspsc190501.10302382'),
	'identifierSchemeCodeUnspsc190501.10302383': __('identifierSchemeCodeUnspsc190501.10302383'),
	'identifierSchemeCodeUnspsc190501.10302384': __('identifierSchemeCodeUnspsc190501.10302384'),
	'identifierSchemeCodeUnspsc190501.10302385': __('identifierSchemeCodeUnspsc190501.10302385'),
	'identifierSchemeCodeUnspsc190501.10302386': __('identifierSchemeCodeUnspsc190501.10302386'),
	'identifierSchemeCodeUnspsc190501.10302387': __('identifierSchemeCodeUnspsc190501.10302387'),
	'identifierSchemeCodeUnspsc190501.10302388': __('identifierSchemeCodeUnspsc190501.10302388'),
	'identifierSchemeCodeUnspsc190501.10302389': __('identifierSchemeCodeUnspsc190501.10302389'),
	'identifierSchemeCodeUnspsc190501.10302390': __('identifierSchemeCodeUnspsc190501.10302390'),
	'identifierSchemeCodeUnspsc190501.10302391': __('identifierSchemeCodeUnspsc190501.10302391'),
	'identifierSchemeCodeUnspsc190501.10302392': __('identifierSchemeCodeUnspsc190501.10302392'),
	'identifierSchemeCodeUnspsc190501.10302393': __('identifierSchemeCodeUnspsc190501.10302393'),
	'identifierSchemeCodeUnspsc190501.10302400': __('identifierSchemeCodeUnspsc190501.10302400'),
	'identifierSchemeCodeUnspsc190501.10302401': __('identifierSchemeCodeUnspsc190501.10302401'),
	'identifierSchemeCodeUnspsc190501.10302402': __('identifierSchemeCodeUnspsc190501.10302402'),
	'identifierSchemeCodeUnspsc190501.10302403': __('identifierSchemeCodeUnspsc190501.10302403'),
	'identifierSchemeCodeUnspsc190501.10302404': __('identifierSchemeCodeUnspsc190501.10302404'),
	'identifierSchemeCodeUnspsc190501.10302405': __('identifierSchemeCodeUnspsc190501.10302405'),
	'identifierSchemeCodeUnspsc190501.10302406': __('identifierSchemeCodeUnspsc190501.10302406'),
	'identifierSchemeCodeUnspsc190501.10302407': __('identifierSchemeCodeUnspsc190501.10302407'),
	'identifierSchemeCodeUnspsc190501.10302408': __('identifierSchemeCodeUnspsc190501.10302408'),
	'identifierSchemeCodeUnspsc190501.10302409': __('identifierSchemeCodeUnspsc190501.10302409'),
	'identifierSchemeCodeUnspsc190501.10302410': __('identifierSchemeCodeUnspsc190501.10302410'),
	'identifierSchemeCodeUnspsc190501.10302411': __('identifierSchemeCodeUnspsc190501.10302411'),
	'identifierSchemeCodeUnspsc190501.10302412': __('identifierSchemeCodeUnspsc190501.10302412'),
	'identifierSchemeCodeUnspsc190501.10302413': __('identifierSchemeCodeUnspsc190501.10302413'),
	'identifierSchemeCodeUnspsc190501.10302414': __('identifierSchemeCodeUnspsc190501.10302414'),
	'identifierSchemeCodeUnspsc190501.10302415': __('identifierSchemeCodeUnspsc190501.10302415'),
	'identifierSchemeCodeUnspsc190501.10302416': __('identifierSchemeCodeUnspsc190501.10302416'),
	'identifierSchemeCodeUnspsc190501.10302417': __('identifierSchemeCodeUnspsc190501.10302417'),
	'identifierSchemeCodeUnspsc190501.10302418': __('identifierSchemeCodeUnspsc190501.10302418'),
	'identifierSchemeCodeUnspsc190501.10302419': __('identifierSchemeCodeUnspsc190501.10302419'),
	'identifierSchemeCodeUnspsc190501.10302420': __('identifierSchemeCodeUnspsc190501.10302420'),
	'identifierSchemeCodeUnspsc190501.10302421': __('identifierSchemeCodeUnspsc190501.10302421'),
	'identifierSchemeCodeUnspsc190501.10302422': __('identifierSchemeCodeUnspsc190501.10302422'),
	'identifierSchemeCodeUnspsc190501.10302423': __('identifierSchemeCodeUnspsc190501.10302423'),
	'identifierSchemeCodeUnspsc190501.10302424': __('identifierSchemeCodeUnspsc190501.10302424'),
	'identifierSchemeCodeUnspsc190501.10302425': __('identifierSchemeCodeUnspsc190501.10302425'),
	'identifierSchemeCodeUnspsc190501.10302426': __('identifierSchemeCodeUnspsc190501.10302426'),
	'identifierSchemeCodeUnspsc190501.10302427': __('identifierSchemeCodeUnspsc190501.10302427'),
	'identifierSchemeCodeUnspsc190501.10302428': __('identifierSchemeCodeUnspsc190501.10302428'),
	'identifierSchemeCodeUnspsc190501.10302429': __('identifierSchemeCodeUnspsc190501.10302429'),
	'identifierSchemeCodeUnspsc190501.10302430': __('identifierSchemeCodeUnspsc190501.10302430'),
	'identifierSchemeCodeUnspsc190501.10302431': __('identifierSchemeCodeUnspsc190501.10302431'),
	'identifierSchemeCodeUnspsc190501.10302432': __('identifierSchemeCodeUnspsc190501.10302432'),
	'identifierSchemeCodeUnspsc190501.10302433': __('identifierSchemeCodeUnspsc190501.10302433'),
	'identifierSchemeCodeUnspsc190501.10302434': __('identifierSchemeCodeUnspsc190501.10302434'),
	'identifierSchemeCodeUnspsc190501.10302435': __('identifierSchemeCodeUnspsc190501.10302435'),
	'identifierSchemeCodeUnspsc190501.10302436': __('identifierSchemeCodeUnspsc190501.10302436'),
	'identifierSchemeCodeUnspsc190501.10302437': __('identifierSchemeCodeUnspsc190501.10302437'),
	'identifierSchemeCodeUnspsc190501.10302438': __('identifierSchemeCodeUnspsc190501.10302438'),
	'identifierSchemeCodeUnspsc190501.10302439': __('identifierSchemeCodeUnspsc190501.10302439'),
	'identifierSchemeCodeUnspsc190501.10302440': __('identifierSchemeCodeUnspsc190501.10302440'),
	'identifierSchemeCodeUnspsc190501.10302441': __('identifierSchemeCodeUnspsc190501.10302441'),
	'identifierSchemeCodeUnspsc190501.10302442': __('identifierSchemeCodeUnspsc190501.10302442'),
	'identifierSchemeCodeUnspsc190501.10302443': __('identifierSchemeCodeUnspsc190501.10302443'),
	'identifierSchemeCodeUnspsc190501.10302444': __('identifierSchemeCodeUnspsc190501.10302444'),
	'identifierSchemeCodeUnspsc190501.10302445': __('identifierSchemeCodeUnspsc190501.10302445'),
	'identifierSchemeCodeUnspsc190501.10302446': __('identifierSchemeCodeUnspsc190501.10302446'),
	'identifierSchemeCodeUnspsc190501.10302447': __('identifierSchemeCodeUnspsc190501.10302447'),
	'identifierSchemeCodeUnspsc190501.10302448': __('identifierSchemeCodeUnspsc190501.10302448'),
	'identifierSchemeCodeUnspsc190501.10302449': __('identifierSchemeCodeUnspsc190501.10302449'),
	'identifierSchemeCodeUnspsc190501.10302450': __('identifierSchemeCodeUnspsc190501.10302450'),
	'identifierSchemeCodeUnspsc190501.10302451': __('identifierSchemeCodeUnspsc190501.10302451'),
	'identifierSchemeCodeUnspsc190501.10302452': __('identifierSchemeCodeUnspsc190501.10302452'),
	'identifierSchemeCodeUnspsc190501.10302453': __('identifierSchemeCodeUnspsc190501.10302453'),
	'identifierSchemeCodeUnspsc190501.10302454': __('identifierSchemeCodeUnspsc190501.10302454'),
	'identifierSchemeCodeUnspsc190501.10302455': __('identifierSchemeCodeUnspsc190501.10302455'),
	'identifierSchemeCodeUnspsc190501.10302456': __('identifierSchemeCodeUnspsc190501.10302456'),
	'identifierSchemeCodeUnspsc190501.10302457': __('identifierSchemeCodeUnspsc190501.10302457'),
	'identifierSchemeCodeUnspsc190501.10302458': __('identifierSchemeCodeUnspsc190501.10302458'),
	'identifierSchemeCodeUnspsc190501.10302459': __('identifierSchemeCodeUnspsc190501.10302459'),
	'identifierSchemeCodeUnspsc190501.10302460': __('identifierSchemeCodeUnspsc190501.10302460'),
	'identifierSchemeCodeUnspsc190501.10302461': __('identifierSchemeCodeUnspsc190501.10302461'),
	'identifierSchemeCodeUnspsc190501.10302462': __('identifierSchemeCodeUnspsc190501.10302462'),
	'identifierSchemeCodeUnspsc190501.10302463': __('identifierSchemeCodeUnspsc190501.10302463'),
	'identifierSchemeCodeUnspsc190501.10302464': __('identifierSchemeCodeUnspsc190501.10302464'),
	'identifierSchemeCodeUnspsc190501.10302465': __('identifierSchemeCodeUnspsc190501.10302465'),
	'identifierSchemeCodeUnspsc190501.10302466': __('identifierSchemeCodeUnspsc190501.10302466'),
	'identifierSchemeCodeUnspsc190501.10302467': __('identifierSchemeCodeUnspsc190501.10302467'),
	'identifierSchemeCodeUnspsc190501.10302468': __('identifierSchemeCodeUnspsc190501.10302468'),
	'identifierSchemeCodeUnspsc190501.10302469': __('identifierSchemeCodeUnspsc190501.10302469'),
	'identifierSchemeCodeUnspsc190501.10302470': __('identifierSchemeCodeUnspsc190501.10302470'),
	'identifierSchemeCodeUnspsc190501.10302471': __('identifierSchemeCodeUnspsc190501.10302471'),
	'identifierSchemeCodeUnspsc190501.10302472': __('identifierSchemeCodeUnspsc190501.10302472'),
	'identifierSchemeCodeUnspsc190501.10302473': __('identifierSchemeCodeUnspsc190501.10302473'),
	'identifierSchemeCodeUnspsc190501.10302474': __('identifierSchemeCodeUnspsc190501.10302474'),
	'identifierSchemeCodeUnspsc190501.10302475': __('identifierSchemeCodeUnspsc190501.10302475'),
	'identifierSchemeCodeUnspsc190501.10302476': __('identifierSchemeCodeUnspsc190501.10302476'),
	'identifierSchemeCodeUnspsc190501.10302477': __('identifierSchemeCodeUnspsc190501.10302477'),
	'identifierSchemeCodeUnspsc190501.10302478': __('identifierSchemeCodeUnspsc190501.10302478'),
	'identifierSchemeCodeUnspsc190501.10302479': __('identifierSchemeCodeUnspsc190501.10302479'),
	'identifierSchemeCodeUnspsc190501.10302480': __('identifierSchemeCodeUnspsc190501.10302480'),
	'identifierSchemeCodeUnspsc190501.10302500': __('identifierSchemeCodeUnspsc190501.10302500'),
	'identifierSchemeCodeUnspsc190501.10302501': __('identifierSchemeCodeUnspsc190501.10302501'),
	'identifierSchemeCodeUnspsc190501.10302502': __('identifierSchemeCodeUnspsc190501.10302502'),
	'identifierSchemeCodeUnspsc190501.10302503': __('identifierSchemeCodeUnspsc190501.10302503'),
	'identifierSchemeCodeUnspsc190501.10302504': __('identifierSchemeCodeUnspsc190501.10302504'),
	'identifierSchemeCodeUnspsc190501.10302505': __('identifierSchemeCodeUnspsc190501.10302505'),
	'identifierSchemeCodeUnspsc190501.10302506': __('identifierSchemeCodeUnspsc190501.10302506'),
	'identifierSchemeCodeUnspsc190501.10302507': __('identifierSchemeCodeUnspsc190501.10302507'),
	'identifierSchemeCodeUnspsc190501.10302508': __('identifierSchemeCodeUnspsc190501.10302508'),
	'identifierSchemeCodeUnspsc190501.10302509': __('identifierSchemeCodeUnspsc190501.10302509'),
	'identifierSchemeCodeUnspsc190501.10302510': __('identifierSchemeCodeUnspsc190501.10302510'),
	'identifierSchemeCodeUnspsc190501.10302600': __('identifierSchemeCodeUnspsc190501.10302600'),
	'identifierSchemeCodeUnspsc190501.10302601': __('identifierSchemeCodeUnspsc190501.10302601'),
	'identifierSchemeCodeUnspsc190501.10302602': __('identifierSchemeCodeUnspsc190501.10302602'),
	'identifierSchemeCodeUnspsc190501.10302603': __('identifierSchemeCodeUnspsc190501.10302603'),
	'identifierSchemeCodeUnspsc190501.10302604': __('identifierSchemeCodeUnspsc190501.10302604'),
	'identifierSchemeCodeUnspsc190501.10302605': __('identifierSchemeCodeUnspsc190501.10302605'),
	'identifierSchemeCodeUnspsc190501.10302606': __('identifierSchemeCodeUnspsc190501.10302606'),
	'identifierSchemeCodeUnspsc190501.10302607': __('identifierSchemeCodeUnspsc190501.10302607'),
	'identifierSchemeCodeUnspsc190501.10302608': __('identifierSchemeCodeUnspsc190501.10302608'),
	'identifierSchemeCodeUnspsc190501.10302609': __('identifierSchemeCodeUnspsc190501.10302609'),
	'identifierSchemeCodeUnspsc190501.10302610': __('identifierSchemeCodeUnspsc190501.10302610'),
	'identifierSchemeCodeUnspsc190501.10302611': __('identifierSchemeCodeUnspsc190501.10302611'),
	'identifierSchemeCodeUnspsc190501.10302612': __('identifierSchemeCodeUnspsc190501.10302612'),
	'identifierSchemeCodeUnspsc190501.10302613': __('identifierSchemeCodeUnspsc190501.10302613'),
	'identifierSchemeCodeUnspsc190501.10302614': __('identifierSchemeCodeUnspsc190501.10302614'),
	'identifierSchemeCodeUnspsc190501.10302615': __('identifierSchemeCodeUnspsc190501.10302615'),
	'identifierSchemeCodeUnspsc190501.10302616': __('identifierSchemeCodeUnspsc190501.10302616'),
	'identifierSchemeCodeUnspsc190501.10302617': __('identifierSchemeCodeUnspsc190501.10302617'),
	'identifierSchemeCodeUnspsc190501.10302618': __('identifierSchemeCodeUnspsc190501.10302618'),
	'identifierSchemeCodeUnspsc190501.10302619': __('identifierSchemeCodeUnspsc190501.10302619'),
	'identifierSchemeCodeUnspsc190501.10302620': __('identifierSchemeCodeUnspsc190501.10302620'),
	'identifierSchemeCodeUnspsc190501.10302621': __('identifierSchemeCodeUnspsc190501.10302621'),
	'identifierSchemeCodeUnspsc190501.10302622': __('identifierSchemeCodeUnspsc190501.10302622'),
	'identifierSchemeCodeUnspsc190501.10302623': __('identifierSchemeCodeUnspsc190501.10302623'),
	'identifierSchemeCodeUnspsc190501.10302624': __('identifierSchemeCodeUnspsc190501.10302624'),
	'identifierSchemeCodeUnspsc190501.10302625': __('identifierSchemeCodeUnspsc190501.10302625'),
	'identifierSchemeCodeUnspsc190501.10302626': __('identifierSchemeCodeUnspsc190501.10302626'),
	'identifierSchemeCodeUnspsc190501.10302627': __('identifierSchemeCodeUnspsc190501.10302627'),
	'identifierSchemeCodeUnspsc190501.10302628': __('identifierSchemeCodeUnspsc190501.10302628'),
	'identifierSchemeCodeUnspsc190501.10302629': __('identifierSchemeCodeUnspsc190501.10302629'),
	'identifierSchemeCodeUnspsc190501.10302630': __('identifierSchemeCodeUnspsc190501.10302630'),
	'identifierSchemeCodeUnspsc190501.10302631': __('identifierSchemeCodeUnspsc190501.10302631'),
	'identifierSchemeCodeUnspsc190501.10302632': __('identifierSchemeCodeUnspsc190501.10302632'),
	'identifierSchemeCodeUnspsc190501.10302700': __('identifierSchemeCodeUnspsc190501.10302700'),
	'identifierSchemeCodeUnspsc190501.10302701': __('identifierSchemeCodeUnspsc190501.10302701'),
	'identifierSchemeCodeUnspsc190501.10302702': __('identifierSchemeCodeUnspsc190501.10302702'),
	'identifierSchemeCodeUnspsc190501.10302703': __('identifierSchemeCodeUnspsc190501.10302703'),
	'identifierSchemeCodeUnspsc190501.10302704': __('identifierSchemeCodeUnspsc190501.10302704'),
	'identifierSchemeCodeUnspsc190501.10302705': __('identifierSchemeCodeUnspsc190501.10302705'),
	'identifierSchemeCodeUnspsc190501.10302706': __('identifierSchemeCodeUnspsc190501.10302706'),
	'identifierSchemeCodeUnspsc190501.10302707': __('identifierSchemeCodeUnspsc190501.10302707'),
	'identifierSchemeCodeUnspsc190501.10302708': __('identifierSchemeCodeUnspsc190501.10302708'),
	'identifierSchemeCodeUnspsc190501.10302709': __('identifierSchemeCodeUnspsc190501.10302709'),
	'identifierSchemeCodeUnspsc190501.10302710': __('identifierSchemeCodeUnspsc190501.10302710'),
	'identifierSchemeCodeUnspsc190501.10302711': __('identifierSchemeCodeUnspsc190501.10302711'),
	'identifierSchemeCodeUnspsc190501.10302712': __('identifierSchemeCodeUnspsc190501.10302712'),
	'identifierSchemeCodeUnspsc190501.10302713': __('identifierSchemeCodeUnspsc190501.10302713'),
	'identifierSchemeCodeUnspsc190501.10302714': __('identifierSchemeCodeUnspsc190501.10302714'),
	'identifierSchemeCodeUnspsc190501.10302715': __('identifierSchemeCodeUnspsc190501.10302715'),
	'identifierSchemeCodeUnspsc190501.10302716': __('identifierSchemeCodeUnspsc190501.10302716'),
	'identifierSchemeCodeUnspsc190501.10302717': __('identifierSchemeCodeUnspsc190501.10302717'),
	'identifierSchemeCodeUnspsc190501.10302718': __('identifierSchemeCodeUnspsc190501.10302718'),
	'identifierSchemeCodeUnspsc190501.10302719': __('identifierSchemeCodeUnspsc190501.10302719'),
	'identifierSchemeCodeUnspsc190501.10302720': __('identifierSchemeCodeUnspsc190501.10302720'),
	'identifierSchemeCodeUnspsc190501.10302721': __('identifierSchemeCodeUnspsc190501.10302721'),
	'identifierSchemeCodeUnspsc190501.10302722': __('identifierSchemeCodeUnspsc190501.10302722'),
	'identifierSchemeCodeUnspsc190501.10302723': __('identifierSchemeCodeUnspsc190501.10302723'),
	'identifierSchemeCodeUnspsc190501.10302724': __('identifierSchemeCodeUnspsc190501.10302724'),
	'identifierSchemeCodeUnspsc190501.10302725': __('identifierSchemeCodeUnspsc190501.10302725'),
	'identifierSchemeCodeUnspsc190501.10302726': __('identifierSchemeCodeUnspsc190501.10302726'),
	'identifierSchemeCodeUnspsc190501.10302727': __('identifierSchemeCodeUnspsc190501.10302727'),
	'identifierSchemeCodeUnspsc190501.10302728': __('identifierSchemeCodeUnspsc190501.10302728'),
	'identifierSchemeCodeUnspsc190501.10302729': __('identifierSchemeCodeUnspsc190501.10302729'),
	'identifierSchemeCodeUnspsc190501.10302730': __('identifierSchemeCodeUnspsc190501.10302730'),
	'identifierSchemeCodeUnspsc190501.10302731': __('identifierSchemeCodeUnspsc190501.10302731'),
	'identifierSchemeCodeUnspsc190501.10302732': __('identifierSchemeCodeUnspsc190501.10302732'),
	'identifierSchemeCodeUnspsc190501.10302733': __('identifierSchemeCodeUnspsc190501.10302733'),
	'identifierSchemeCodeUnspsc190501.10302734': __('identifierSchemeCodeUnspsc190501.10302734'),
	'identifierSchemeCodeUnspsc190501.10302735': __('identifierSchemeCodeUnspsc190501.10302735'),
	'identifierSchemeCodeUnspsc190501.10302736': __('identifierSchemeCodeUnspsc190501.10302736'),
	'identifierSchemeCodeUnspsc190501.10302737': __('identifierSchemeCodeUnspsc190501.10302737'),
	'identifierSchemeCodeUnspsc190501.10302738': __('identifierSchemeCodeUnspsc190501.10302738'),
	'identifierSchemeCodeUnspsc190501.10302739': __('identifierSchemeCodeUnspsc190501.10302739'),
	'identifierSchemeCodeUnspsc190501.10302740': __('identifierSchemeCodeUnspsc190501.10302740'),
	'identifierSchemeCodeUnspsc190501.10302741': __('identifierSchemeCodeUnspsc190501.10302741'),
	'identifierSchemeCodeUnspsc190501.10302742': __('identifierSchemeCodeUnspsc190501.10302742'),
	'identifierSchemeCodeUnspsc190501.10302743': __('identifierSchemeCodeUnspsc190501.10302743'),
	'identifierSchemeCodeUnspsc190501.10302744': __('identifierSchemeCodeUnspsc190501.10302744'),
	'identifierSchemeCodeUnspsc190501.10302745': __('identifierSchemeCodeUnspsc190501.10302745'),
	'identifierSchemeCodeUnspsc190501.10302746': __('identifierSchemeCodeUnspsc190501.10302746'),
	'identifierSchemeCodeUnspsc190501.10302747': __('identifierSchemeCodeUnspsc190501.10302747'),
	'identifierSchemeCodeUnspsc190501.10302748': __('identifierSchemeCodeUnspsc190501.10302748'),
	'identifierSchemeCodeUnspsc190501.10302749': __('identifierSchemeCodeUnspsc190501.10302749'),
	'identifierSchemeCodeUnspsc190501.10302750': __('identifierSchemeCodeUnspsc190501.10302750'),
	'identifierSchemeCodeUnspsc190501.10302751': __('identifierSchemeCodeUnspsc190501.10302751'),
	'identifierSchemeCodeUnspsc190501.10302752': __('identifierSchemeCodeUnspsc190501.10302752'),
	'identifierSchemeCodeUnspsc190501.10302753': __('identifierSchemeCodeUnspsc190501.10302753'),
	'identifierSchemeCodeUnspsc190501.10302754': __('identifierSchemeCodeUnspsc190501.10302754'),
	'identifierSchemeCodeUnspsc190501.10302755': __('identifierSchemeCodeUnspsc190501.10302755'),
	'identifierSchemeCodeUnspsc190501.10302756': __('identifierSchemeCodeUnspsc190501.10302756'),
	'identifierSchemeCodeUnspsc190501.10302757': __('identifierSchemeCodeUnspsc190501.10302757'),
	'identifierSchemeCodeUnspsc190501.10302758': __('identifierSchemeCodeUnspsc190501.10302758'),
	'identifierSchemeCodeUnspsc190501.10302759': __('identifierSchemeCodeUnspsc190501.10302759'),
	'identifierSchemeCodeUnspsc190501.10302760': __('identifierSchemeCodeUnspsc190501.10302760'),
	'identifierSchemeCodeUnspsc190501.10302761': __('identifierSchemeCodeUnspsc190501.10302761'),
	'identifierSchemeCodeUnspsc190501.10302762': __('identifierSchemeCodeUnspsc190501.10302762'),
	'identifierSchemeCodeUnspsc190501.10302763': __('identifierSchemeCodeUnspsc190501.10302763'),
	'identifierSchemeCodeUnspsc190501.10302764': __('identifierSchemeCodeUnspsc190501.10302764'),
	'identifierSchemeCodeUnspsc190501.10302765': __('identifierSchemeCodeUnspsc190501.10302765'),
	'identifierSchemeCodeUnspsc190501.10302766': __('identifierSchemeCodeUnspsc190501.10302766'),
	'identifierSchemeCodeUnspsc190501.10302767': __('identifierSchemeCodeUnspsc190501.10302767'),
	'identifierSchemeCodeUnspsc190501.10302768': __('identifierSchemeCodeUnspsc190501.10302768'),
	'identifierSchemeCodeUnspsc190501.10302769': __('identifierSchemeCodeUnspsc190501.10302769'),
	'identifierSchemeCodeUnspsc190501.10302770': __('identifierSchemeCodeUnspsc190501.10302770'),
	'identifierSchemeCodeUnspsc190501.10302771': __('identifierSchemeCodeUnspsc190501.10302771'),
	'identifierSchemeCodeUnspsc190501.10302772': __('identifierSchemeCodeUnspsc190501.10302772'),
	'identifierSchemeCodeUnspsc190501.10302773': __('identifierSchemeCodeUnspsc190501.10302773'),
	'identifierSchemeCodeUnspsc190501.10302774': __('identifierSchemeCodeUnspsc190501.10302774'),
	'identifierSchemeCodeUnspsc190501.10302775': __('identifierSchemeCodeUnspsc190501.10302775'),
	'identifierSchemeCodeUnspsc190501.10302776': __('identifierSchemeCodeUnspsc190501.10302776'),
	'identifierSchemeCodeUnspsc190501.10302777': __('identifierSchemeCodeUnspsc190501.10302777'),
	'identifierSchemeCodeUnspsc190501.10302778': __('identifierSchemeCodeUnspsc190501.10302778'),
	'identifierSchemeCodeUnspsc190501.10302779': __('identifierSchemeCodeUnspsc190501.10302779'),
	'identifierSchemeCodeUnspsc190501.10302780': __('identifierSchemeCodeUnspsc190501.10302780'),
	'identifierSchemeCodeUnspsc190501.10302781': __('identifierSchemeCodeUnspsc190501.10302781'),
	'identifierSchemeCodeUnspsc190501.10302782': __('identifierSchemeCodeUnspsc190501.10302782'),
	'identifierSchemeCodeUnspsc190501.10302800': __('identifierSchemeCodeUnspsc190501.10302800'),
	'identifierSchemeCodeUnspsc190501.10302801': __('identifierSchemeCodeUnspsc190501.10302801'),
	'identifierSchemeCodeUnspsc190501.10302802': __('identifierSchemeCodeUnspsc190501.10302802'),
	'identifierSchemeCodeUnspsc190501.10302803': __('identifierSchemeCodeUnspsc190501.10302803'),
	'identifierSchemeCodeUnspsc190501.10302804': __('identifierSchemeCodeUnspsc190501.10302804'),
	'identifierSchemeCodeUnspsc190501.10302805': __('identifierSchemeCodeUnspsc190501.10302805'),
	'identifierSchemeCodeUnspsc190501.10302806': __('identifierSchemeCodeUnspsc190501.10302806'),
	'identifierSchemeCodeUnspsc190501.10302807': __('identifierSchemeCodeUnspsc190501.10302807'),
	'identifierSchemeCodeUnspsc190501.10302808': __('identifierSchemeCodeUnspsc190501.10302808'),
	'identifierSchemeCodeUnspsc190501.10302809': __('identifierSchemeCodeUnspsc190501.10302809'),
	'identifierSchemeCodeUnspsc190501.10302810': __('identifierSchemeCodeUnspsc190501.10302810'),
	'identifierSchemeCodeUnspsc190501.10302811': __('identifierSchemeCodeUnspsc190501.10302811'),
	'identifierSchemeCodeUnspsc190501.10302812': __('identifierSchemeCodeUnspsc190501.10302812'),
	'identifierSchemeCodeUnspsc190501.10302813': __('identifierSchemeCodeUnspsc190501.10302813'),
	'identifierSchemeCodeUnspsc190501.10302814': __('identifierSchemeCodeUnspsc190501.10302814'),
	'identifierSchemeCodeUnspsc190501.10302815': __('identifierSchemeCodeUnspsc190501.10302815'),
	'identifierSchemeCodeUnspsc190501.10302816': __('identifierSchemeCodeUnspsc190501.10302816'),
	'identifierSchemeCodeUnspsc190501.10302817': __('identifierSchemeCodeUnspsc190501.10302817'),
	'identifierSchemeCodeUnspsc190501.10302818': __('identifierSchemeCodeUnspsc190501.10302818'),
	'identifierSchemeCodeUnspsc190501.10302819': __('identifierSchemeCodeUnspsc190501.10302819'),
	'identifierSchemeCodeUnspsc190501.10302820': __('identifierSchemeCodeUnspsc190501.10302820'),
	'identifierSchemeCodeUnspsc190501.10302821': __('identifierSchemeCodeUnspsc190501.10302821'),
	'identifierSchemeCodeUnspsc190501.10302822': __('identifierSchemeCodeUnspsc190501.10302822'),
	'identifierSchemeCodeUnspsc190501.10302823': __('identifierSchemeCodeUnspsc190501.10302823'),
	'identifierSchemeCodeUnspsc190501.10302824': __('identifierSchemeCodeUnspsc190501.10302824'),
	'identifierSchemeCodeUnspsc190501.10302825': __('identifierSchemeCodeUnspsc190501.10302825'),
	'identifierSchemeCodeUnspsc190501.10302826': __('identifierSchemeCodeUnspsc190501.10302826'),
	'identifierSchemeCodeUnspsc190501.10302827': __('identifierSchemeCodeUnspsc190501.10302827'),
	'identifierSchemeCodeUnspsc190501.10302828': __('identifierSchemeCodeUnspsc190501.10302828'),
	'identifierSchemeCodeUnspsc190501.10302829': __('identifierSchemeCodeUnspsc190501.10302829'),
	'identifierSchemeCodeUnspsc190501.10302830': __('identifierSchemeCodeUnspsc190501.10302830'),
	'identifierSchemeCodeUnspsc190501.10302831': __('identifierSchemeCodeUnspsc190501.10302831'),
	'identifierSchemeCodeUnspsc190501.10302832': __('identifierSchemeCodeUnspsc190501.10302832'),
	'identifierSchemeCodeUnspsc190501.10302833': __('identifierSchemeCodeUnspsc190501.10302833'),
	'identifierSchemeCodeUnspsc190501.10302834': __('identifierSchemeCodeUnspsc190501.10302834'),
	'identifierSchemeCodeUnspsc190501.10302835': __('identifierSchemeCodeUnspsc190501.10302835'),
	'identifierSchemeCodeUnspsc190501.10302836': __('identifierSchemeCodeUnspsc190501.10302836'),
	'identifierSchemeCodeUnspsc190501.10302837': __('identifierSchemeCodeUnspsc190501.10302837'),
	'identifierSchemeCodeUnspsc190501.10302838': __('identifierSchemeCodeUnspsc190501.10302838'),
	'identifierSchemeCodeUnspsc190501.10302839': __('identifierSchemeCodeUnspsc190501.10302839'),
	'identifierSchemeCodeUnspsc190501.10302840': __('identifierSchemeCodeUnspsc190501.10302840'),
	'identifierSchemeCodeUnspsc190501.10302841': __('identifierSchemeCodeUnspsc190501.10302841'),
	'identifierSchemeCodeUnspsc190501.10302842': __('identifierSchemeCodeUnspsc190501.10302842'),
	'identifierSchemeCodeUnspsc190501.10302843': __('identifierSchemeCodeUnspsc190501.10302843'),
	'identifierSchemeCodeUnspsc190501.10302844': __('identifierSchemeCodeUnspsc190501.10302844'),
	'identifierSchemeCodeUnspsc190501.10302845': __('identifierSchemeCodeUnspsc190501.10302845'),
	'identifierSchemeCodeUnspsc190501.10302846': __('identifierSchemeCodeUnspsc190501.10302846'),
	'identifierSchemeCodeUnspsc190501.10302847': __('identifierSchemeCodeUnspsc190501.10302847'),
	'identifierSchemeCodeUnspsc190501.10302848': __('identifierSchemeCodeUnspsc190501.10302848'),
	'identifierSchemeCodeUnspsc190501.10302849': __('identifierSchemeCodeUnspsc190501.10302849'),
	'identifierSchemeCodeUnspsc190501.10302850': __('identifierSchemeCodeUnspsc190501.10302850'),
	'identifierSchemeCodeUnspsc190501.10302851': __('identifierSchemeCodeUnspsc190501.10302851'),
	'identifierSchemeCodeUnspsc190501.10302852': __('identifierSchemeCodeUnspsc190501.10302852'),
	'identifierSchemeCodeUnspsc190501.10302853': __('identifierSchemeCodeUnspsc190501.10302853'),
	'identifierSchemeCodeUnspsc190501.10302854': __('identifierSchemeCodeUnspsc190501.10302854'),
	'identifierSchemeCodeUnspsc190501.10302855': __('identifierSchemeCodeUnspsc190501.10302855'),
	'identifierSchemeCodeUnspsc190501.10302856': __('identifierSchemeCodeUnspsc190501.10302856'),
	'identifierSchemeCodeUnspsc190501.10302857': __('identifierSchemeCodeUnspsc190501.10302857'),
	'identifierSchemeCodeUnspsc190501.10302858': __('identifierSchemeCodeUnspsc190501.10302858'),
	'identifierSchemeCodeUnspsc190501.10302859': __('identifierSchemeCodeUnspsc190501.10302859'),
	'identifierSchemeCodeUnspsc190501.10302860': __('identifierSchemeCodeUnspsc190501.10302860'),
	'identifierSchemeCodeUnspsc190501.10302861': __('identifierSchemeCodeUnspsc190501.10302861'),
	'identifierSchemeCodeUnspsc190501.10302862': __('identifierSchemeCodeUnspsc190501.10302862'),
	'identifierSchemeCodeUnspsc190501.10302863': __('identifierSchemeCodeUnspsc190501.10302863'),
	'identifierSchemeCodeUnspsc190501.10302864': __('identifierSchemeCodeUnspsc190501.10302864'),
	'identifierSchemeCodeUnspsc190501.10302865': __('identifierSchemeCodeUnspsc190501.10302865'),
	'identifierSchemeCodeUnspsc190501.10302866': __('identifierSchemeCodeUnspsc190501.10302866'),
	'identifierSchemeCodeUnspsc190501.10302867': __('identifierSchemeCodeUnspsc190501.10302867'),
	'identifierSchemeCodeUnspsc190501.10302868': __('identifierSchemeCodeUnspsc190501.10302868'),
	'identifierSchemeCodeUnspsc190501.10302869': __('identifierSchemeCodeUnspsc190501.10302869'),
	'identifierSchemeCodeUnspsc190501.10302870': __('identifierSchemeCodeUnspsc190501.10302870'),
	'identifierSchemeCodeUnspsc190501.10302871': __('identifierSchemeCodeUnspsc190501.10302871'),
	'identifierSchemeCodeUnspsc190501.10302872': __('identifierSchemeCodeUnspsc190501.10302872'),
	'identifierSchemeCodeUnspsc190501.10302873': __('identifierSchemeCodeUnspsc190501.10302873'),
	'identifierSchemeCodeUnspsc190501.10302874': __('identifierSchemeCodeUnspsc190501.10302874'),
	'identifierSchemeCodeUnspsc190501.10302875': __('identifierSchemeCodeUnspsc190501.10302875'),
	'identifierSchemeCodeUnspsc190501.10302876': __('identifierSchemeCodeUnspsc190501.10302876'),
	'identifierSchemeCodeUnspsc190501.10302877': __('identifierSchemeCodeUnspsc190501.10302877'),
	'identifierSchemeCodeUnspsc190501.10302878': __('identifierSchemeCodeUnspsc190501.10302878'),
	'identifierSchemeCodeUnspsc190501.10302879': __('identifierSchemeCodeUnspsc190501.10302879'),
	'identifierSchemeCodeUnspsc190501.10302880': __('identifierSchemeCodeUnspsc190501.10302880'),
	'identifierSchemeCodeUnspsc190501.10302881': __('identifierSchemeCodeUnspsc190501.10302881'),
	'identifierSchemeCodeUnspsc190501.10302882': __('identifierSchemeCodeUnspsc190501.10302882'),
	'identifierSchemeCodeUnspsc190501.10302883': __('identifierSchemeCodeUnspsc190501.10302883'),
	'identifierSchemeCodeUnspsc190501.10302884': __('identifierSchemeCodeUnspsc190501.10302884'),
	'identifierSchemeCodeUnspsc190501.10302885': __('identifierSchemeCodeUnspsc190501.10302885'),
	'identifierSchemeCodeUnspsc190501.10302886': __('identifierSchemeCodeUnspsc190501.10302886'),
	'identifierSchemeCodeUnspsc190501.10302887': __('identifierSchemeCodeUnspsc190501.10302887'),
	'identifierSchemeCodeUnspsc190501.10302888': __('identifierSchemeCodeUnspsc190501.10302888'),
	'identifierSchemeCodeUnspsc190501.10310000': __('identifierSchemeCodeUnspsc190501.10310000'),
	'identifierSchemeCodeUnspsc190501.10311500': __('identifierSchemeCodeUnspsc190501.10311500'),
	'identifierSchemeCodeUnspsc190501.10311501': __('identifierSchemeCodeUnspsc190501.10311501'),
	'identifierSchemeCodeUnspsc190501.10311502': __('identifierSchemeCodeUnspsc190501.10311502'),
	'identifierSchemeCodeUnspsc190501.10311503': __('identifierSchemeCodeUnspsc190501.10311503'),
	'identifierSchemeCodeUnspsc190501.10311504': __('identifierSchemeCodeUnspsc190501.10311504'),
	'identifierSchemeCodeUnspsc190501.10311505': __('identifierSchemeCodeUnspsc190501.10311505'),
	'identifierSchemeCodeUnspsc190501.10311506': __('identifierSchemeCodeUnspsc190501.10311506'),
	'identifierSchemeCodeUnspsc190501.10311507': __('identifierSchemeCodeUnspsc190501.10311507'),
	'identifierSchemeCodeUnspsc190501.10311508': __('identifierSchemeCodeUnspsc190501.10311508'),
	'identifierSchemeCodeUnspsc190501.10311509': __('identifierSchemeCodeUnspsc190501.10311509'),
	'identifierSchemeCodeUnspsc190501.10311510': __('identifierSchemeCodeUnspsc190501.10311510'),
	'identifierSchemeCodeUnspsc190501.10311511': __('identifierSchemeCodeUnspsc190501.10311511'),
	'identifierSchemeCodeUnspsc190501.10311512': __('identifierSchemeCodeUnspsc190501.10311512'),
	'identifierSchemeCodeUnspsc190501.10311513': __('identifierSchemeCodeUnspsc190501.10311513'),
	'identifierSchemeCodeUnspsc190501.10311514': __('identifierSchemeCodeUnspsc190501.10311514'),
	'identifierSchemeCodeUnspsc190501.10311515': __('identifierSchemeCodeUnspsc190501.10311515'),
	'identifierSchemeCodeUnspsc190501.10311516': __('identifierSchemeCodeUnspsc190501.10311516'),
	'identifierSchemeCodeUnspsc190501.10311517': __('identifierSchemeCodeUnspsc190501.10311517'),
	'identifierSchemeCodeUnspsc190501.10311518': __('identifierSchemeCodeUnspsc190501.10311518'),
	'identifierSchemeCodeUnspsc190501.10311519': __('identifierSchemeCodeUnspsc190501.10311519'),
	'identifierSchemeCodeUnspsc190501.10311600': __('identifierSchemeCodeUnspsc190501.10311600'),
	'identifierSchemeCodeUnspsc190501.10311601': __('identifierSchemeCodeUnspsc190501.10311601'),
	'identifierSchemeCodeUnspsc190501.10311602': __('identifierSchemeCodeUnspsc190501.10311602'),
	'identifierSchemeCodeUnspsc190501.10311603': __('identifierSchemeCodeUnspsc190501.10311603'),
	'identifierSchemeCodeUnspsc190501.10311604': __('identifierSchemeCodeUnspsc190501.10311604'),
	'identifierSchemeCodeUnspsc190501.10311605': __('identifierSchemeCodeUnspsc190501.10311605'),
	'identifierSchemeCodeUnspsc190501.10311606': __('identifierSchemeCodeUnspsc190501.10311606'),
	'identifierSchemeCodeUnspsc190501.10311607': __('identifierSchemeCodeUnspsc190501.10311607'),
	'identifierSchemeCodeUnspsc190501.10311608': __('identifierSchemeCodeUnspsc190501.10311608'),
	'identifierSchemeCodeUnspsc190501.10311609': __('identifierSchemeCodeUnspsc190501.10311609'),
	'identifierSchemeCodeUnspsc190501.10311610': __('identifierSchemeCodeUnspsc190501.10311610'),
	'identifierSchemeCodeUnspsc190501.10311611': __('identifierSchemeCodeUnspsc190501.10311611'),
	'identifierSchemeCodeUnspsc190501.10311612': __('identifierSchemeCodeUnspsc190501.10311612'),
	'identifierSchemeCodeUnspsc190501.10311613': __('identifierSchemeCodeUnspsc190501.10311613'),
	'identifierSchemeCodeUnspsc190501.10311614': __('identifierSchemeCodeUnspsc190501.10311614'),
	'identifierSchemeCodeUnspsc190501.10311615': __('identifierSchemeCodeUnspsc190501.10311615'),
	'identifierSchemeCodeUnspsc190501.10311616': __('identifierSchemeCodeUnspsc190501.10311616'),
	'identifierSchemeCodeUnspsc190501.10311617': __('identifierSchemeCodeUnspsc190501.10311617'),
	'identifierSchemeCodeUnspsc190501.10311618': __('identifierSchemeCodeUnspsc190501.10311618'),
	'identifierSchemeCodeUnspsc190501.10311619': __('identifierSchemeCodeUnspsc190501.10311619'),
	'identifierSchemeCodeUnspsc190501.10311700': __('identifierSchemeCodeUnspsc190501.10311700'),
	'identifierSchemeCodeUnspsc190501.10311701': __('identifierSchemeCodeUnspsc190501.10311701'),
	'identifierSchemeCodeUnspsc190501.10311702': __('identifierSchemeCodeUnspsc190501.10311702'),
	'identifierSchemeCodeUnspsc190501.10311703': __('identifierSchemeCodeUnspsc190501.10311703'),
	'identifierSchemeCodeUnspsc190501.10311704': __('identifierSchemeCodeUnspsc190501.10311704'),
	'identifierSchemeCodeUnspsc190501.10311705': __('identifierSchemeCodeUnspsc190501.10311705'),
	'identifierSchemeCodeUnspsc190501.10311706': __('identifierSchemeCodeUnspsc190501.10311706'),
	'identifierSchemeCodeUnspsc190501.10311707': __('identifierSchemeCodeUnspsc190501.10311707'),
	'identifierSchemeCodeUnspsc190501.10311708': __('identifierSchemeCodeUnspsc190501.10311708'),
	'identifierSchemeCodeUnspsc190501.10311709': __('identifierSchemeCodeUnspsc190501.10311709'),
	'identifierSchemeCodeUnspsc190501.10311710': __('identifierSchemeCodeUnspsc190501.10311710'),
	'identifierSchemeCodeUnspsc190501.10311711': __('identifierSchemeCodeUnspsc190501.10311711'),
	'identifierSchemeCodeUnspsc190501.10311712': __('identifierSchemeCodeUnspsc190501.10311712'),
	'identifierSchemeCodeUnspsc190501.10311713': __('identifierSchemeCodeUnspsc190501.10311713'),
	'identifierSchemeCodeUnspsc190501.10311714': __('identifierSchemeCodeUnspsc190501.10311714'),
	'identifierSchemeCodeUnspsc190501.10311715': __('identifierSchemeCodeUnspsc190501.10311715'),
	'identifierSchemeCodeUnspsc190501.10311716': __('identifierSchemeCodeUnspsc190501.10311716'),
	'identifierSchemeCodeUnspsc190501.10311717': __('identifierSchemeCodeUnspsc190501.10311717'),
	'identifierSchemeCodeUnspsc190501.10311718': __('identifierSchemeCodeUnspsc190501.10311718'),
	'identifierSchemeCodeUnspsc190501.10311719': __('identifierSchemeCodeUnspsc190501.10311719'),
	'identifierSchemeCodeUnspsc190501.10311720': __('identifierSchemeCodeUnspsc190501.10311720'),
	'identifierSchemeCodeUnspsc190501.10311721': __('identifierSchemeCodeUnspsc190501.10311721'),
	'identifierSchemeCodeUnspsc190501.10311722': __('identifierSchemeCodeUnspsc190501.10311722'),
	'identifierSchemeCodeUnspsc190501.10311723': __('identifierSchemeCodeUnspsc190501.10311723'),
	'identifierSchemeCodeUnspsc190501.10311724': __('identifierSchemeCodeUnspsc190501.10311724'),
	'identifierSchemeCodeUnspsc190501.10311725': __('identifierSchemeCodeUnspsc190501.10311725'),
	'identifierSchemeCodeUnspsc190501.10311726': __('identifierSchemeCodeUnspsc190501.10311726'),
	'identifierSchemeCodeUnspsc190501.10311727': __('identifierSchemeCodeUnspsc190501.10311727'),
	'identifierSchemeCodeUnspsc190501.10311728': __('identifierSchemeCodeUnspsc190501.10311728'),
	'identifierSchemeCodeUnspsc190501.10311729': __('identifierSchemeCodeUnspsc190501.10311729'),
	'identifierSchemeCodeUnspsc190501.10311730': __('identifierSchemeCodeUnspsc190501.10311730'),
	'identifierSchemeCodeUnspsc190501.10311731': __('identifierSchemeCodeUnspsc190501.10311731'),
	'identifierSchemeCodeUnspsc190501.10311732': __('identifierSchemeCodeUnspsc190501.10311732'),
	'identifierSchemeCodeUnspsc190501.10311733': __('identifierSchemeCodeUnspsc190501.10311733'),
	'identifierSchemeCodeUnspsc190501.10311734': __('identifierSchemeCodeUnspsc190501.10311734'),
	'identifierSchemeCodeUnspsc190501.10311800': __('identifierSchemeCodeUnspsc190501.10311800'),
	'identifierSchemeCodeUnspsc190501.10311801': __('identifierSchemeCodeUnspsc190501.10311801'),
	'identifierSchemeCodeUnspsc190501.10311802': __('identifierSchemeCodeUnspsc190501.10311802'),
	'identifierSchemeCodeUnspsc190501.10311803': __('identifierSchemeCodeUnspsc190501.10311803'),
	'identifierSchemeCodeUnspsc190501.10311804': __('identifierSchemeCodeUnspsc190501.10311804'),
	'identifierSchemeCodeUnspsc190501.10311805': __('identifierSchemeCodeUnspsc190501.10311805'),
	'identifierSchemeCodeUnspsc190501.10311900': __('identifierSchemeCodeUnspsc190501.10311900'),
	'identifierSchemeCodeUnspsc190501.10311901': __('identifierSchemeCodeUnspsc190501.10311901'),
	'identifierSchemeCodeUnspsc190501.10311902': __('identifierSchemeCodeUnspsc190501.10311902'),
	'identifierSchemeCodeUnspsc190501.10311903': __('identifierSchemeCodeUnspsc190501.10311903'),
	'identifierSchemeCodeUnspsc190501.10311904': __('identifierSchemeCodeUnspsc190501.10311904'),
	'identifierSchemeCodeUnspsc190501.10311905': __('identifierSchemeCodeUnspsc190501.10311905'),
	'identifierSchemeCodeUnspsc190501.10311906': __('identifierSchemeCodeUnspsc190501.10311906'),
	'identifierSchemeCodeUnspsc190501.10311907': __('identifierSchemeCodeUnspsc190501.10311907'),
	'identifierSchemeCodeUnspsc190501.10311908': __('identifierSchemeCodeUnspsc190501.10311908'),
	'identifierSchemeCodeUnspsc190501.10311909': __('identifierSchemeCodeUnspsc190501.10311909'),
	'identifierSchemeCodeUnspsc190501.10311910': __('identifierSchemeCodeUnspsc190501.10311910'),
	'identifierSchemeCodeUnspsc190501.10312000': __('identifierSchemeCodeUnspsc190501.10312000'),
	'identifierSchemeCodeUnspsc190501.10312001': __('identifierSchemeCodeUnspsc190501.10312001'),
	'identifierSchemeCodeUnspsc190501.10312002': __('identifierSchemeCodeUnspsc190501.10312002'),
	'identifierSchemeCodeUnspsc190501.10312003': __('identifierSchemeCodeUnspsc190501.10312003'),
	'identifierSchemeCodeUnspsc190501.10312004': __('identifierSchemeCodeUnspsc190501.10312004'),
	'identifierSchemeCodeUnspsc190501.10312005': __('identifierSchemeCodeUnspsc190501.10312005'),
	'identifierSchemeCodeUnspsc190501.10312006': __('identifierSchemeCodeUnspsc190501.10312006'),
	'identifierSchemeCodeUnspsc190501.10312007': __('identifierSchemeCodeUnspsc190501.10312007'),
	'identifierSchemeCodeUnspsc190501.10312008': __('identifierSchemeCodeUnspsc190501.10312008'),
	'identifierSchemeCodeUnspsc190501.10312009': __('identifierSchemeCodeUnspsc190501.10312009'),
	'identifierSchemeCodeUnspsc190501.10312010': __('identifierSchemeCodeUnspsc190501.10312010'),
	'identifierSchemeCodeUnspsc190501.10312011': __('identifierSchemeCodeUnspsc190501.10312011'),
	'identifierSchemeCodeUnspsc190501.10312100': __('identifierSchemeCodeUnspsc190501.10312100'),
	'identifierSchemeCodeUnspsc190501.10312101': __('identifierSchemeCodeUnspsc190501.10312101'),
	'identifierSchemeCodeUnspsc190501.10312102': __('identifierSchemeCodeUnspsc190501.10312102'),
	'identifierSchemeCodeUnspsc190501.10312103': __('identifierSchemeCodeUnspsc190501.10312103'),
	'identifierSchemeCodeUnspsc190501.10312104': __('identifierSchemeCodeUnspsc190501.10312104'),
	'identifierSchemeCodeUnspsc190501.10312200': __('identifierSchemeCodeUnspsc190501.10312200'),
	'identifierSchemeCodeUnspsc190501.10312201': __('identifierSchemeCodeUnspsc190501.10312201'),
	'identifierSchemeCodeUnspsc190501.10312202': __('identifierSchemeCodeUnspsc190501.10312202'),
	'identifierSchemeCodeUnspsc190501.10312203': __('identifierSchemeCodeUnspsc190501.10312203'),
	'identifierSchemeCodeUnspsc190501.10312204': __('identifierSchemeCodeUnspsc190501.10312204'),
	'identifierSchemeCodeUnspsc190501.10312205': __('identifierSchemeCodeUnspsc190501.10312205'),
	'identifierSchemeCodeUnspsc190501.10312206': __('identifierSchemeCodeUnspsc190501.10312206'),
	'identifierSchemeCodeUnspsc190501.10312207': __('identifierSchemeCodeUnspsc190501.10312207'),
	'identifierSchemeCodeUnspsc190501.10312208': __('identifierSchemeCodeUnspsc190501.10312208'),
	'identifierSchemeCodeUnspsc190501.10312209': __('identifierSchemeCodeUnspsc190501.10312209'),
	'identifierSchemeCodeUnspsc190501.10312210': __('identifierSchemeCodeUnspsc190501.10312210'),
	'identifierSchemeCodeUnspsc190501.10312211': __('identifierSchemeCodeUnspsc190501.10312211'),
	'identifierSchemeCodeUnspsc190501.10312212': __('identifierSchemeCodeUnspsc190501.10312212'),
	'identifierSchemeCodeUnspsc190501.10312213': __('identifierSchemeCodeUnspsc190501.10312213'),
	'identifierSchemeCodeUnspsc190501.10312214': __('identifierSchemeCodeUnspsc190501.10312214'),
	'identifierSchemeCodeUnspsc190501.10312215': __('identifierSchemeCodeUnspsc190501.10312215'),
	'identifierSchemeCodeUnspsc190501.10312216': __('identifierSchemeCodeUnspsc190501.10312216'),
	'identifierSchemeCodeUnspsc190501.10312217': __('identifierSchemeCodeUnspsc190501.10312217'),
	'identifierSchemeCodeUnspsc190501.10312218': __('identifierSchemeCodeUnspsc190501.10312218'),
	'identifierSchemeCodeUnspsc190501.10312219': __('identifierSchemeCodeUnspsc190501.10312219'),
	'identifierSchemeCodeUnspsc190501.10312300': __('identifierSchemeCodeUnspsc190501.10312300'),
	'identifierSchemeCodeUnspsc190501.10312301': __('identifierSchemeCodeUnspsc190501.10312301'),
	'identifierSchemeCodeUnspsc190501.10312302': __('identifierSchemeCodeUnspsc190501.10312302'),
	'identifierSchemeCodeUnspsc190501.10312303': __('identifierSchemeCodeUnspsc190501.10312303'),
	'identifierSchemeCodeUnspsc190501.10312304': __('identifierSchemeCodeUnspsc190501.10312304'),
	'identifierSchemeCodeUnspsc190501.10312305': __('identifierSchemeCodeUnspsc190501.10312305'),
	'identifierSchemeCodeUnspsc190501.10312400': __('identifierSchemeCodeUnspsc190501.10312400'),
	'identifierSchemeCodeUnspsc190501.10312401': __('identifierSchemeCodeUnspsc190501.10312401'),
	'identifierSchemeCodeUnspsc190501.10312402': __('identifierSchemeCodeUnspsc190501.10312402'),
	'identifierSchemeCodeUnspsc190501.10312403': __('identifierSchemeCodeUnspsc190501.10312403'),
	'identifierSchemeCodeUnspsc190501.10312404': __('identifierSchemeCodeUnspsc190501.10312404'),
	'identifierSchemeCodeUnspsc190501.10312405': __('identifierSchemeCodeUnspsc190501.10312405'),
	'identifierSchemeCodeUnspsc190501.10312500': __('identifierSchemeCodeUnspsc190501.10312500'),
	'identifierSchemeCodeUnspsc190501.10312501': __('identifierSchemeCodeUnspsc190501.10312501'),
	'identifierSchemeCodeUnspsc190501.10312502': __('identifierSchemeCodeUnspsc190501.10312502'),
	'identifierSchemeCodeUnspsc190501.10312503': __('identifierSchemeCodeUnspsc190501.10312503'),
	'identifierSchemeCodeUnspsc190501.10312504': __('identifierSchemeCodeUnspsc190501.10312504'),
	'identifierSchemeCodeUnspsc190501.10312505': __('identifierSchemeCodeUnspsc190501.10312505'),
	'identifierSchemeCodeUnspsc190501.10312600': __('identifierSchemeCodeUnspsc190501.10312600'),
	'identifierSchemeCodeUnspsc190501.10312601': __('identifierSchemeCodeUnspsc190501.10312601'),
	'identifierSchemeCodeUnspsc190501.10312602': __('identifierSchemeCodeUnspsc190501.10312602'),
	'identifierSchemeCodeUnspsc190501.10312603': __('identifierSchemeCodeUnspsc190501.10312603'),
	'identifierSchemeCodeUnspsc190501.10312604': __('identifierSchemeCodeUnspsc190501.10312604'),
	'identifierSchemeCodeUnspsc190501.10312605': __('identifierSchemeCodeUnspsc190501.10312605'),
	'identifierSchemeCodeUnspsc190501.10312606': __('identifierSchemeCodeUnspsc190501.10312606'),
	'identifierSchemeCodeUnspsc190501.10312607': __('identifierSchemeCodeUnspsc190501.10312607'),
	'identifierSchemeCodeUnspsc190501.10312608': __('identifierSchemeCodeUnspsc190501.10312608'),
	'identifierSchemeCodeUnspsc190501.10312609': __('identifierSchemeCodeUnspsc190501.10312609'),
	'identifierSchemeCodeUnspsc190501.10312610': __('identifierSchemeCodeUnspsc190501.10312610'),
	'identifierSchemeCodeUnspsc190501.10312611': __('identifierSchemeCodeUnspsc190501.10312611'),
	'identifierSchemeCodeUnspsc190501.10312612': __('identifierSchemeCodeUnspsc190501.10312612'),
	'identifierSchemeCodeUnspsc190501.10312613': __('identifierSchemeCodeUnspsc190501.10312613'),
	'identifierSchemeCodeUnspsc190501.10312614': __('identifierSchemeCodeUnspsc190501.10312614'),
	'identifierSchemeCodeUnspsc190501.10312615': __('identifierSchemeCodeUnspsc190501.10312615'),
	'identifierSchemeCodeUnspsc190501.10312616': __('identifierSchemeCodeUnspsc190501.10312616'),
	'identifierSchemeCodeUnspsc190501.10312617': __('identifierSchemeCodeUnspsc190501.10312617'),
	'identifierSchemeCodeUnspsc190501.10312618': __('identifierSchemeCodeUnspsc190501.10312618'),
	'identifierSchemeCodeUnspsc190501.10312619': __('identifierSchemeCodeUnspsc190501.10312619'),
	'identifierSchemeCodeUnspsc190501.10312620': __('identifierSchemeCodeUnspsc190501.10312620'),
	'identifierSchemeCodeUnspsc190501.10312621': __('identifierSchemeCodeUnspsc190501.10312621'),
	'identifierSchemeCodeUnspsc190501.10312622': __('identifierSchemeCodeUnspsc190501.10312622'),
	'identifierSchemeCodeUnspsc190501.10312623': __('identifierSchemeCodeUnspsc190501.10312623'),
	'identifierSchemeCodeUnspsc190501.10312624': __('identifierSchemeCodeUnspsc190501.10312624'),
	'identifierSchemeCodeUnspsc190501.10312625': __('identifierSchemeCodeUnspsc190501.10312625'),
	'identifierSchemeCodeUnspsc190501.10312626': __('identifierSchemeCodeUnspsc190501.10312626'),
	'identifierSchemeCodeUnspsc190501.10312627': __('identifierSchemeCodeUnspsc190501.10312627'),
	'identifierSchemeCodeUnspsc190501.10312628': __('identifierSchemeCodeUnspsc190501.10312628'),
	'identifierSchemeCodeUnspsc190501.10312629': __('identifierSchemeCodeUnspsc190501.10312629'),
	'identifierSchemeCodeUnspsc190501.10312630': __('identifierSchemeCodeUnspsc190501.10312630'),
	'identifierSchemeCodeUnspsc190501.10312631': __('identifierSchemeCodeUnspsc190501.10312631'),
	'identifierSchemeCodeUnspsc190501.10312632': __('identifierSchemeCodeUnspsc190501.10312632'),
	'identifierSchemeCodeUnspsc190501.10312633': __('identifierSchemeCodeUnspsc190501.10312633'),
	'identifierSchemeCodeUnspsc190501.10312634': __('identifierSchemeCodeUnspsc190501.10312634'),
	'identifierSchemeCodeUnspsc190501.10312635': __('identifierSchemeCodeUnspsc190501.10312635'),
	'identifierSchemeCodeUnspsc190501.10312636': __('identifierSchemeCodeUnspsc190501.10312636'),
	'identifierSchemeCodeUnspsc190501.10312637': __('identifierSchemeCodeUnspsc190501.10312637'),
	'identifierSchemeCodeUnspsc190501.10312638': __('identifierSchemeCodeUnspsc190501.10312638'),
	'identifierSchemeCodeUnspsc190501.10312639': __('identifierSchemeCodeUnspsc190501.10312639'),
	'identifierSchemeCodeUnspsc190501.10312640': __('identifierSchemeCodeUnspsc190501.10312640'),
	'identifierSchemeCodeUnspsc190501.10312641': __('identifierSchemeCodeUnspsc190501.10312641'),
	'identifierSchemeCodeUnspsc190501.10312642': __('identifierSchemeCodeUnspsc190501.10312642'),
	'identifierSchemeCodeUnspsc190501.10312643': __('identifierSchemeCodeUnspsc190501.10312643'),
	'identifierSchemeCodeUnspsc190501.10312644': __('identifierSchemeCodeUnspsc190501.10312644'),
	'identifierSchemeCodeUnspsc190501.10312645': __('identifierSchemeCodeUnspsc190501.10312645'),
	'identifierSchemeCodeUnspsc190501.10312646': __('identifierSchemeCodeUnspsc190501.10312646'),
	'identifierSchemeCodeUnspsc190501.10312647': __('identifierSchemeCodeUnspsc190501.10312647'),
	'identifierSchemeCodeUnspsc190501.10312648': __('identifierSchemeCodeUnspsc190501.10312648'),
	'identifierSchemeCodeUnspsc190501.10312649': __('identifierSchemeCodeUnspsc190501.10312649'),
	'identifierSchemeCodeUnspsc190501.10312650': __('identifierSchemeCodeUnspsc190501.10312650'),
	'identifierSchemeCodeUnspsc190501.10312651': __('identifierSchemeCodeUnspsc190501.10312651'),
	'identifierSchemeCodeUnspsc190501.10312652': __('identifierSchemeCodeUnspsc190501.10312652'),
	'identifierSchemeCodeUnspsc190501.10312653': __('identifierSchemeCodeUnspsc190501.10312653'),
	'identifierSchemeCodeUnspsc190501.10312800': __('identifierSchemeCodeUnspsc190501.10312800'),
	'identifierSchemeCodeUnspsc190501.10312801': __('identifierSchemeCodeUnspsc190501.10312801'),
	'identifierSchemeCodeUnspsc190501.10312802': __('identifierSchemeCodeUnspsc190501.10312802'),
	'identifierSchemeCodeUnspsc190501.10312803': __('identifierSchemeCodeUnspsc190501.10312803'),
	'identifierSchemeCodeUnspsc190501.10312804': __('identifierSchemeCodeUnspsc190501.10312804'),
	'identifierSchemeCodeUnspsc190501.10312805': __('identifierSchemeCodeUnspsc190501.10312805'),
	'identifierSchemeCodeUnspsc190501.10312806': __('identifierSchemeCodeUnspsc190501.10312806'),
	'identifierSchemeCodeUnspsc190501.10312807': __('identifierSchemeCodeUnspsc190501.10312807'),
	'identifierSchemeCodeUnspsc190501.10312808': __('identifierSchemeCodeUnspsc190501.10312808'),
	'identifierSchemeCodeUnspsc190501.10312809': __('identifierSchemeCodeUnspsc190501.10312809'),
	'identifierSchemeCodeUnspsc190501.10312810': __('identifierSchemeCodeUnspsc190501.10312810'),
	'identifierSchemeCodeUnspsc190501.10312811': __('identifierSchemeCodeUnspsc190501.10312811'),
	'identifierSchemeCodeUnspsc190501.10312812': __('identifierSchemeCodeUnspsc190501.10312812'),
	'identifierSchemeCodeUnspsc190501.10312813': __('identifierSchemeCodeUnspsc190501.10312813'),
	'identifierSchemeCodeUnspsc190501.10312900': __('identifierSchemeCodeUnspsc190501.10312900'),
	'identifierSchemeCodeUnspsc190501.10312901': __('identifierSchemeCodeUnspsc190501.10312901'),
	'identifierSchemeCodeUnspsc190501.10312902': __('identifierSchemeCodeUnspsc190501.10312902'),
	'identifierSchemeCodeUnspsc190501.10312903': __('identifierSchemeCodeUnspsc190501.10312903'),
	'identifierSchemeCodeUnspsc190501.10312904': __('identifierSchemeCodeUnspsc190501.10312904'),
	'identifierSchemeCodeUnspsc190501.10312905': __('identifierSchemeCodeUnspsc190501.10312905'),
	'identifierSchemeCodeUnspsc190501.10312906': __('identifierSchemeCodeUnspsc190501.10312906'),
	'identifierSchemeCodeUnspsc190501.10313000': __('identifierSchemeCodeUnspsc190501.10313000'),
	'identifierSchemeCodeUnspsc190501.10313001': __('identifierSchemeCodeUnspsc190501.10313001'),
	'identifierSchemeCodeUnspsc190501.10313002': __('identifierSchemeCodeUnspsc190501.10313002'),
	'identifierSchemeCodeUnspsc190501.10313003': __('identifierSchemeCodeUnspsc190501.10313003'),
	'identifierSchemeCodeUnspsc190501.10313004': __('identifierSchemeCodeUnspsc190501.10313004'),
	'identifierSchemeCodeUnspsc190501.10313005': __('identifierSchemeCodeUnspsc190501.10313005'),
	'identifierSchemeCodeUnspsc190501.10313006': __('identifierSchemeCodeUnspsc190501.10313006'),
	'identifierSchemeCodeUnspsc190501.10313007': __('identifierSchemeCodeUnspsc190501.10313007'),
	'identifierSchemeCodeUnspsc190501.10313008': __('identifierSchemeCodeUnspsc190501.10313008'),
	'identifierSchemeCodeUnspsc190501.10313009': __('identifierSchemeCodeUnspsc190501.10313009'),
	'identifierSchemeCodeUnspsc190501.10313010': __('identifierSchemeCodeUnspsc190501.10313010'),
	'identifierSchemeCodeUnspsc190501.10313100': __('identifierSchemeCodeUnspsc190501.10313100'),
	'identifierSchemeCodeUnspsc190501.10313101': __('identifierSchemeCodeUnspsc190501.10313101'),
	'identifierSchemeCodeUnspsc190501.10313102': __('identifierSchemeCodeUnspsc190501.10313102'),
	'identifierSchemeCodeUnspsc190501.10313103': __('identifierSchemeCodeUnspsc190501.10313103'),
	'identifierSchemeCodeUnspsc190501.10313104': __('identifierSchemeCodeUnspsc190501.10313104'),
	'identifierSchemeCodeUnspsc190501.10313105': __('identifierSchemeCodeUnspsc190501.10313105'),
	'identifierSchemeCodeUnspsc190501.10313106': __('identifierSchemeCodeUnspsc190501.10313106'),
	'identifierSchemeCodeUnspsc190501.10313107': __('identifierSchemeCodeUnspsc190501.10313107'),
	'identifierSchemeCodeUnspsc190501.10313108': __('identifierSchemeCodeUnspsc190501.10313108'),
	'identifierSchemeCodeUnspsc190501.10313109': __('identifierSchemeCodeUnspsc190501.10313109'),
	'identifierSchemeCodeUnspsc190501.10313110': __('identifierSchemeCodeUnspsc190501.10313110'),
	'identifierSchemeCodeUnspsc190501.10313111': __('identifierSchemeCodeUnspsc190501.10313111'),
	'identifierSchemeCodeUnspsc190501.10313112': __('identifierSchemeCodeUnspsc190501.10313112'),
	'identifierSchemeCodeUnspsc190501.10313113': __('identifierSchemeCodeUnspsc190501.10313113'),
	'identifierSchemeCodeUnspsc190501.10313200': __('identifierSchemeCodeUnspsc190501.10313200'),
	'identifierSchemeCodeUnspsc190501.10313201': __('identifierSchemeCodeUnspsc190501.10313201'),
	'identifierSchemeCodeUnspsc190501.10313202': __('identifierSchemeCodeUnspsc190501.10313202'),
	'identifierSchemeCodeUnspsc190501.10313203': __('identifierSchemeCodeUnspsc190501.10313203'),
	'identifierSchemeCodeUnspsc190501.10313204': __('identifierSchemeCodeUnspsc190501.10313204'),
	'identifierSchemeCodeUnspsc190501.10313205': __('identifierSchemeCodeUnspsc190501.10313205'),
	'identifierSchemeCodeUnspsc190501.10313206': __('identifierSchemeCodeUnspsc190501.10313206'),
	'identifierSchemeCodeUnspsc190501.10313207': __('identifierSchemeCodeUnspsc190501.10313207'),
	'identifierSchemeCodeUnspsc190501.10313208': __('identifierSchemeCodeUnspsc190501.10313208'),
	'identifierSchemeCodeUnspsc190501.10313300': __('identifierSchemeCodeUnspsc190501.10313300'),
	'identifierSchemeCodeUnspsc190501.10313301': __('identifierSchemeCodeUnspsc190501.10313301'),
	'identifierSchemeCodeUnspsc190501.10313302': __('identifierSchemeCodeUnspsc190501.10313302'),
	'identifierSchemeCodeUnspsc190501.10313303': __('identifierSchemeCodeUnspsc190501.10313303'),
	'identifierSchemeCodeUnspsc190501.10313304': __('identifierSchemeCodeUnspsc190501.10313304'),
	'identifierSchemeCodeUnspsc190501.10313305': __('identifierSchemeCodeUnspsc190501.10313305'),
	'identifierSchemeCodeUnspsc190501.10313400': __('identifierSchemeCodeUnspsc190501.10313400'),
	'identifierSchemeCodeUnspsc190501.10313401': __('identifierSchemeCodeUnspsc190501.10313401'),
	'identifierSchemeCodeUnspsc190501.10313402': __('identifierSchemeCodeUnspsc190501.10313402'),
	'identifierSchemeCodeUnspsc190501.10313403': __('identifierSchemeCodeUnspsc190501.10313403'),
	'identifierSchemeCodeUnspsc190501.10313404': __('identifierSchemeCodeUnspsc190501.10313404'),
	'identifierSchemeCodeUnspsc190501.10313405': __('identifierSchemeCodeUnspsc190501.10313405'),
	'identifierSchemeCodeUnspsc190501.10313500': __('identifierSchemeCodeUnspsc190501.10313500'),
	'identifierSchemeCodeUnspsc190501.10313501': __('identifierSchemeCodeUnspsc190501.10313501'),
	'identifierSchemeCodeUnspsc190501.10313502': __('identifierSchemeCodeUnspsc190501.10313502'),
	'identifierSchemeCodeUnspsc190501.10313503': __('identifierSchemeCodeUnspsc190501.10313503'),
	'identifierSchemeCodeUnspsc190501.10313504': __('identifierSchemeCodeUnspsc190501.10313504'),
	'identifierSchemeCodeUnspsc190501.10313505': __('identifierSchemeCodeUnspsc190501.10313505'),
	'identifierSchemeCodeUnspsc190501.10313506': __('identifierSchemeCodeUnspsc190501.10313506'),
	'identifierSchemeCodeUnspsc190501.10313507': __('identifierSchemeCodeUnspsc190501.10313507'),
	'identifierSchemeCodeUnspsc190501.10313508': __('identifierSchemeCodeUnspsc190501.10313508'),
	'identifierSchemeCodeUnspsc190501.10313509': __('identifierSchemeCodeUnspsc190501.10313509'),
	'identifierSchemeCodeUnspsc190501.10313510': __('identifierSchemeCodeUnspsc190501.10313510'),
	'identifierSchemeCodeUnspsc190501.10313600': __('identifierSchemeCodeUnspsc190501.10313600'),
	'identifierSchemeCodeUnspsc190501.10313601': __('identifierSchemeCodeUnspsc190501.10313601'),
	'identifierSchemeCodeUnspsc190501.10313602': __('identifierSchemeCodeUnspsc190501.10313602'),
	'identifierSchemeCodeUnspsc190501.10313603': __('identifierSchemeCodeUnspsc190501.10313603'),
	'identifierSchemeCodeUnspsc190501.10313604': __('identifierSchemeCodeUnspsc190501.10313604'),
	'identifierSchemeCodeUnspsc190501.10313605': __('identifierSchemeCodeUnspsc190501.10313605'),
	'identifierSchemeCodeUnspsc190501.10313606': __('identifierSchemeCodeUnspsc190501.10313606'),
	'identifierSchemeCodeUnspsc190501.10313607': __('identifierSchemeCodeUnspsc190501.10313607'),
	'identifierSchemeCodeUnspsc190501.10313608': __('identifierSchemeCodeUnspsc190501.10313608'),
	'identifierSchemeCodeUnspsc190501.10313609': __('identifierSchemeCodeUnspsc190501.10313609'),
	'identifierSchemeCodeUnspsc190501.10313610': __('identifierSchemeCodeUnspsc190501.10313610'),
	'identifierSchemeCodeUnspsc190501.10313611': __('identifierSchemeCodeUnspsc190501.10313611'),
	'identifierSchemeCodeUnspsc190501.10313612': __('identifierSchemeCodeUnspsc190501.10313612'),
	'identifierSchemeCodeUnspsc190501.10313613': __('identifierSchemeCodeUnspsc190501.10313613'),
	'identifierSchemeCodeUnspsc190501.10313614': __('identifierSchemeCodeUnspsc190501.10313614'),
	'identifierSchemeCodeUnspsc190501.10313700': __('identifierSchemeCodeUnspsc190501.10313700'),
	'identifierSchemeCodeUnspsc190501.10313701': __('identifierSchemeCodeUnspsc190501.10313701'),
	'identifierSchemeCodeUnspsc190501.10313702': __('identifierSchemeCodeUnspsc190501.10313702'),
	'identifierSchemeCodeUnspsc190501.10313703': __('identifierSchemeCodeUnspsc190501.10313703'),
	'identifierSchemeCodeUnspsc190501.10313704': __('identifierSchemeCodeUnspsc190501.10313704'),
	'identifierSchemeCodeUnspsc190501.10313705': __('identifierSchemeCodeUnspsc190501.10313705'),
	'identifierSchemeCodeUnspsc190501.10313706': __('identifierSchemeCodeUnspsc190501.10313706'),
	'identifierSchemeCodeUnspsc190501.10313707': __('identifierSchemeCodeUnspsc190501.10313707'),
	'identifierSchemeCodeUnspsc190501.10313708': __('identifierSchemeCodeUnspsc190501.10313708'),
	'identifierSchemeCodeUnspsc190501.10313709': __('identifierSchemeCodeUnspsc190501.10313709'),
	'identifierSchemeCodeUnspsc190501.10313800': __('identifierSchemeCodeUnspsc190501.10313800'),
	'identifierSchemeCodeUnspsc190501.10313801': __('identifierSchemeCodeUnspsc190501.10313801'),
	'identifierSchemeCodeUnspsc190501.10313802': __('identifierSchemeCodeUnspsc190501.10313802'),
	'identifierSchemeCodeUnspsc190501.10313803': __('identifierSchemeCodeUnspsc190501.10313803'),
	'identifierSchemeCodeUnspsc190501.10313804': __('identifierSchemeCodeUnspsc190501.10313804'),
	'identifierSchemeCodeUnspsc190501.10313805': __('identifierSchemeCodeUnspsc190501.10313805'),
	'identifierSchemeCodeUnspsc190501.10313806': __('identifierSchemeCodeUnspsc190501.10313806'),
	'identifierSchemeCodeUnspsc190501.10313807': __('identifierSchemeCodeUnspsc190501.10313807'),
	'identifierSchemeCodeUnspsc190501.10313808': __('identifierSchemeCodeUnspsc190501.10313808'),
	'identifierSchemeCodeUnspsc190501.10313900': __('identifierSchemeCodeUnspsc190501.10313900'),
	'identifierSchemeCodeUnspsc190501.10313901': __('identifierSchemeCodeUnspsc190501.10313901'),
	'identifierSchemeCodeUnspsc190501.10313902': __('identifierSchemeCodeUnspsc190501.10313902'),
	'identifierSchemeCodeUnspsc190501.10313903': __('identifierSchemeCodeUnspsc190501.10313903'),
	'identifierSchemeCodeUnspsc190501.10313904': __('identifierSchemeCodeUnspsc190501.10313904'),
	'identifierSchemeCodeUnspsc190501.10313905': __('identifierSchemeCodeUnspsc190501.10313905'),
	'identifierSchemeCodeUnspsc190501.10313906': __('identifierSchemeCodeUnspsc190501.10313906'),
	'identifierSchemeCodeUnspsc190501.10313907': __('identifierSchemeCodeUnspsc190501.10313907'),
	'identifierSchemeCodeUnspsc190501.10313908': __('identifierSchemeCodeUnspsc190501.10313908'),
	'identifierSchemeCodeUnspsc190501.10313909': __('identifierSchemeCodeUnspsc190501.10313909'),
	'identifierSchemeCodeUnspsc190501.10313910': __('identifierSchemeCodeUnspsc190501.10313910'),
	'identifierSchemeCodeUnspsc190501.10313911': __('identifierSchemeCodeUnspsc190501.10313911'),
	'identifierSchemeCodeUnspsc190501.10313912': __('identifierSchemeCodeUnspsc190501.10313912'),
	'identifierSchemeCodeUnspsc190501.10313913': __('identifierSchemeCodeUnspsc190501.10313913'),
	'identifierSchemeCodeUnspsc190501.10313914': __('identifierSchemeCodeUnspsc190501.10313914'),
	'identifierSchemeCodeUnspsc190501.10313915': __('identifierSchemeCodeUnspsc190501.10313915'),
	'identifierSchemeCodeUnspsc190501.10313916': __('identifierSchemeCodeUnspsc190501.10313916'),
	'identifierSchemeCodeUnspsc190501.10313917': __('identifierSchemeCodeUnspsc190501.10313917'),
	'identifierSchemeCodeUnspsc190501.10313918': __('identifierSchemeCodeUnspsc190501.10313918'),
	'identifierSchemeCodeUnspsc190501.10313919': __('identifierSchemeCodeUnspsc190501.10313919'),
	'identifierSchemeCodeUnspsc190501.10313920': __('identifierSchemeCodeUnspsc190501.10313920'),
	'identifierSchemeCodeUnspsc190501.10313921': __('identifierSchemeCodeUnspsc190501.10313921'),
	'identifierSchemeCodeUnspsc190501.10313922': __('identifierSchemeCodeUnspsc190501.10313922'),
	'identifierSchemeCodeUnspsc190501.10313923': __('identifierSchemeCodeUnspsc190501.10313923'),
	'identifierSchemeCodeUnspsc190501.10313924': __('identifierSchemeCodeUnspsc190501.10313924'),
	'identifierSchemeCodeUnspsc190501.10313925': __('identifierSchemeCodeUnspsc190501.10313925'),
	'identifierSchemeCodeUnspsc190501.10313926': __('identifierSchemeCodeUnspsc190501.10313926'),
	'identifierSchemeCodeUnspsc190501.10313927': __('identifierSchemeCodeUnspsc190501.10313927'),
	'identifierSchemeCodeUnspsc190501.10313928': __('identifierSchemeCodeUnspsc190501.10313928'),
	'identifierSchemeCodeUnspsc190501.10313929': __('identifierSchemeCodeUnspsc190501.10313929'),
	'identifierSchemeCodeUnspsc190501.10314000': __('identifierSchemeCodeUnspsc190501.10314000'),
	'identifierSchemeCodeUnspsc190501.10314001': __('identifierSchemeCodeUnspsc190501.10314001'),
	'identifierSchemeCodeUnspsc190501.10314002': __('identifierSchemeCodeUnspsc190501.10314002'),
	'identifierSchemeCodeUnspsc190501.10314003': __('identifierSchemeCodeUnspsc190501.10314003'),
	'identifierSchemeCodeUnspsc190501.10314004': __('identifierSchemeCodeUnspsc190501.10314004'),
	'identifierSchemeCodeUnspsc190501.10314005': __('identifierSchemeCodeUnspsc190501.10314005'),
	'identifierSchemeCodeUnspsc190501.10314006': __('identifierSchemeCodeUnspsc190501.10314006'),
	'identifierSchemeCodeUnspsc190501.10314100': __('identifierSchemeCodeUnspsc190501.10314100'),
	'identifierSchemeCodeUnspsc190501.10314101': __('identifierSchemeCodeUnspsc190501.10314101'),
	'identifierSchemeCodeUnspsc190501.10314102': __('identifierSchemeCodeUnspsc190501.10314102'),
	'identifierSchemeCodeUnspsc190501.10314103': __('identifierSchemeCodeUnspsc190501.10314103'),
	'identifierSchemeCodeUnspsc190501.10314104': __('identifierSchemeCodeUnspsc190501.10314104'),
	'identifierSchemeCodeUnspsc190501.10314105': __('identifierSchemeCodeUnspsc190501.10314105'),
	'identifierSchemeCodeUnspsc190501.10314106': __('identifierSchemeCodeUnspsc190501.10314106'),
	'identifierSchemeCodeUnspsc190501.10314107': __('identifierSchemeCodeUnspsc190501.10314107'),
	'identifierSchemeCodeUnspsc190501.10314108': __('identifierSchemeCodeUnspsc190501.10314108'),
	'identifierSchemeCodeUnspsc190501.10314109': __('identifierSchemeCodeUnspsc190501.10314109'),
	'identifierSchemeCodeUnspsc190501.10314110': __('identifierSchemeCodeUnspsc190501.10314110'),
	'identifierSchemeCodeUnspsc190501.10314111': __('identifierSchemeCodeUnspsc190501.10314111'),
	'identifierSchemeCodeUnspsc190501.10314112': __('identifierSchemeCodeUnspsc190501.10314112'),
	'identifierSchemeCodeUnspsc190501.10314113': __('identifierSchemeCodeUnspsc190501.10314113'),
	'identifierSchemeCodeUnspsc190501.10314114': __('identifierSchemeCodeUnspsc190501.10314114'),
	'identifierSchemeCodeUnspsc190501.10314200': __('identifierSchemeCodeUnspsc190501.10314200'),
	'identifierSchemeCodeUnspsc190501.10314201': __('identifierSchemeCodeUnspsc190501.10314201'),
	'identifierSchemeCodeUnspsc190501.10314202': __('identifierSchemeCodeUnspsc190501.10314202'),
	'identifierSchemeCodeUnspsc190501.10314203': __('identifierSchemeCodeUnspsc190501.10314203'),
	'identifierSchemeCodeUnspsc190501.10314204': __('identifierSchemeCodeUnspsc190501.10314204'),
	'identifierSchemeCodeUnspsc190501.10314205': __('identifierSchemeCodeUnspsc190501.10314205'),
	'identifierSchemeCodeUnspsc190501.10314206': __('identifierSchemeCodeUnspsc190501.10314206'),
	'identifierSchemeCodeUnspsc190501.10314300': __('identifierSchemeCodeUnspsc190501.10314300'),
	'identifierSchemeCodeUnspsc190501.10314301': __('identifierSchemeCodeUnspsc190501.10314301'),
	'identifierSchemeCodeUnspsc190501.10314302': __('identifierSchemeCodeUnspsc190501.10314302'),
	'identifierSchemeCodeUnspsc190501.10314303': __('identifierSchemeCodeUnspsc190501.10314303'),
	'identifierSchemeCodeUnspsc190501.10314304': __('identifierSchemeCodeUnspsc190501.10314304'),
	'identifierSchemeCodeUnspsc190501.10314305': __('identifierSchemeCodeUnspsc190501.10314305'),
	'identifierSchemeCodeUnspsc190501.10314400': __('identifierSchemeCodeUnspsc190501.10314400'),
	'identifierSchemeCodeUnspsc190501.10314401': __('identifierSchemeCodeUnspsc190501.10314401'),
	'identifierSchemeCodeUnspsc190501.10314402': __('identifierSchemeCodeUnspsc190501.10314402'),
	'identifierSchemeCodeUnspsc190501.10314403': __('identifierSchemeCodeUnspsc190501.10314403'),
	'identifierSchemeCodeUnspsc190501.10314404': __('identifierSchemeCodeUnspsc190501.10314404'),
	'identifierSchemeCodeUnspsc190501.10314405': __('identifierSchemeCodeUnspsc190501.10314405'),
	'identifierSchemeCodeUnspsc190501.10314406': __('identifierSchemeCodeUnspsc190501.10314406'),
	'identifierSchemeCodeUnspsc190501.10314500': __('identifierSchemeCodeUnspsc190501.10314500'),
	'identifierSchemeCodeUnspsc190501.10314501': __('identifierSchemeCodeUnspsc190501.10314501'),
	'identifierSchemeCodeUnspsc190501.10314502': __('identifierSchemeCodeUnspsc190501.10314502'),
	'identifierSchemeCodeUnspsc190501.10314503': __('identifierSchemeCodeUnspsc190501.10314503'),
	'identifierSchemeCodeUnspsc190501.10314504': __('identifierSchemeCodeUnspsc190501.10314504'),
	'identifierSchemeCodeUnspsc190501.10314505': __('identifierSchemeCodeUnspsc190501.10314505'),
	'identifierSchemeCodeUnspsc190501.10314506': __('identifierSchemeCodeUnspsc190501.10314506'),
	'identifierSchemeCodeUnspsc190501.10314507': __('identifierSchemeCodeUnspsc190501.10314507'),
	'identifierSchemeCodeUnspsc190501.10314600': __('identifierSchemeCodeUnspsc190501.10314600'),
	'identifierSchemeCodeUnspsc190501.10314601': __('identifierSchemeCodeUnspsc190501.10314601'),
	'identifierSchemeCodeUnspsc190501.10314602': __('identifierSchemeCodeUnspsc190501.10314602'),
	'identifierSchemeCodeUnspsc190501.10314603': __('identifierSchemeCodeUnspsc190501.10314603'),
	'identifierSchemeCodeUnspsc190501.10314604': __('identifierSchemeCodeUnspsc190501.10314604'),
	'identifierSchemeCodeUnspsc190501.10314605': __('identifierSchemeCodeUnspsc190501.10314605'),
	'identifierSchemeCodeUnspsc190501.10314606': __('identifierSchemeCodeUnspsc190501.10314606'),
	'identifierSchemeCodeUnspsc190501.10314607': __('identifierSchemeCodeUnspsc190501.10314607'),
	'identifierSchemeCodeUnspsc190501.10314608': __('identifierSchemeCodeUnspsc190501.10314608'),
	'identifierSchemeCodeUnspsc190501.10314609': __('identifierSchemeCodeUnspsc190501.10314609'),
	'identifierSchemeCodeUnspsc190501.10314610': __('identifierSchemeCodeUnspsc190501.10314610'),
	'identifierSchemeCodeUnspsc190501.10314611': __('identifierSchemeCodeUnspsc190501.10314611'),
	'identifierSchemeCodeUnspsc190501.10314612': __('identifierSchemeCodeUnspsc190501.10314612'),
	'identifierSchemeCodeUnspsc190501.10314613': __('identifierSchemeCodeUnspsc190501.10314613'),
	'identifierSchemeCodeUnspsc190501.10314614': __('identifierSchemeCodeUnspsc190501.10314614'),
	'identifierSchemeCodeUnspsc190501.10314615': __('identifierSchemeCodeUnspsc190501.10314615'),
	'identifierSchemeCodeUnspsc190501.10314616': __('identifierSchemeCodeUnspsc190501.10314616'),
	'identifierSchemeCodeUnspsc190501.10314617': __('identifierSchemeCodeUnspsc190501.10314617'),
	'identifierSchemeCodeUnspsc190501.10314618': __('identifierSchemeCodeUnspsc190501.10314618'),
	'identifierSchemeCodeUnspsc190501.10314619': __('identifierSchemeCodeUnspsc190501.10314619'),
	'identifierSchemeCodeUnspsc190501.10314700': __('identifierSchemeCodeUnspsc190501.10314700'),
	'identifierSchemeCodeUnspsc190501.10314701': __('identifierSchemeCodeUnspsc190501.10314701'),
	'identifierSchemeCodeUnspsc190501.10314702': __('identifierSchemeCodeUnspsc190501.10314702'),
	'identifierSchemeCodeUnspsc190501.10314703': __('identifierSchemeCodeUnspsc190501.10314703'),
	'identifierSchemeCodeUnspsc190501.10314704': __('identifierSchemeCodeUnspsc190501.10314704'),
	'identifierSchemeCodeUnspsc190501.10314705': __('identifierSchemeCodeUnspsc190501.10314705'),
	'identifierSchemeCodeUnspsc190501.10314706': __('identifierSchemeCodeUnspsc190501.10314706'),
	'identifierSchemeCodeUnspsc190501.10314707': __('identifierSchemeCodeUnspsc190501.10314707'),
	'identifierSchemeCodeUnspsc190501.10314708': __('identifierSchemeCodeUnspsc190501.10314708'),
	'identifierSchemeCodeUnspsc190501.10314709': __('identifierSchemeCodeUnspsc190501.10314709'),
	'identifierSchemeCodeUnspsc190501.10314710': __('identifierSchemeCodeUnspsc190501.10314710'),
	'identifierSchemeCodeUnspsc190501.10314711': __('identifierSchemeCodeUnspsc190501.10314711'),
	'identifierSchemeCodeUnspsc190501.10314712': __('identifierSchemeCodeUnspsc190501.10314712'),
	'identifierSchemeCodeUnspsc190501.10314800': __('identifierSchemeCodeUnspsc190501.10314800'),
	'identifierSchemeCodeUnspsc190501.10314801': __('identifierSchemeCodeUnspsc190501.10314801'),
	'identifierSchemeCodeUnspsc190501.10314802': __('identifierSchemeCodeUnspsc190501.10314802'),
	'identifierSchemeCodeUnspsc190501.10314803': __('identifierSchemeCodeUnspsc190501.10314803'),
	'identifierSchemeCodeUnspsc190501.10314804': __('identifierSchemeCodeUnspsc190501.10314804'),
	'identifierSchemeCodeUnspsc190501.10314805': __('identifierSchemeCodeUnspsc190501.10314805'),
	'identifierSchemeCodeUnspsc190501.10314806': __('identifierSchemeCodeUnspsc190501.10314806'),
	'identifierSchemeCodeUnspsc190501.10314807': __('identifierSchemeCodeUnspsc190501.10314807'),
	'identifierSchemeCodeUnspsc190501.10314808': __('identifierSchemeCodeUnspsc190501.10314808'),
	'identifierSchemeCodeUnspsc190501.10314809': __('identifierSchemeCodeUnspsc190501.10314809'),
	'identifierSchemeCodeUnspsc190501.10314810': __('identifierSchemeCodeUnspsc190501.10314810'),
	'identifierSchemeCodeUnspsc190501.10314811': __('identifierSchemeCodeUnspsc190501.10314811'),
	'identifierSchemeCodeUnspsc190501.10314812': __('identifierSchemeCodeUnspsc190501.10314812'),
	'identifierSchemeCodeUnspsc190501.10314813': __('identifierSchemeCodeUnspsc190501.10314813'),
	'identifierSchemeCodeUnspsc190501.10314814': __('identifierSchemeCodeUnspsc190501.10314814'),
	'identifierSchemeCodeUnspsc190501.10314815': __('identifierSchemeCodeUnspsc190501.10314815'),
	'identifierSchemeCodeUnspsc190501.10314816': __('identifierSchemeCodeUnspsc190501.10314816'),
	'identifierSchemeCodeUnspsc190501.10314817': __('identifierSchemeCodeUnspsc190501.10314817'),
	'identifierSchemeCodeUnspsc190501.10314818': __('identifierSchemeCodeUnspsc190501.10314818'),
	'identifierSchemeCodeUnspsc190501.10314819': __('identifierSchemeCodeUnspsc190501.10314819'),
	'identifierSchemeCodeUnspsc190501.10314820': __('identifierSchemeCodeUnspsc190501.10314820'),
	'identifierSchemeCodeUnspsc190501.10314821': __('identifierSchemeCodeUnspsc190501.10314821'),
	'identifierSchemeCodeUnspsc190501.10314822': __('identifierSchemeCodeUnspsc190501.10314822'),
	'identifierSchemeCodeUnspsc190501.10314823': __('identifierSchemeCodeUnspsc190501.10314823'),
	'identifierSchemeCodeUnspsc190501.10314824': __('identifierSchemeCodeUnspsc190501.10314824'),
	'identifierSchemeCodeUnspsc190501.10314825': __('identifierSchemeCodeUnspsc190501.10314825'),
	'identifierSchemeCodeUnspsc190501.10314826': __('identifierSchemeCodeUnspsc190501.10314826'),
	'identifierSchemeCodeUnspsc190501.10314827': __('identifierSchemeCodeUnspsc190501.10314827'),
	'identifierSchemeCodeUnspsc190501.10314828': __('identifierSchemeCodeUnspsc190501.10314828'),
	'identifierSchemeCodeUnspsc190501.10314829': __('identifierSchemeCodeUnspsc190501.10314829'),
	'identifierSchemeCodeUnspsc190501.10314900': __('identifierSchemeCodeUnspsc190501.10314900'),
	'identifierSchemeCodeUnspsc190501.10314901': __('identifierSchemeCodeUnspsc190501.10314901'),
	'identifierSchemeCodeUnspsc190501.10314902': __('identifierSchemeCodeUnspsc190501.10314902'),
	'identifierSchemeCodeUnspsc190501.10314903': __('identifierSchemeCodeUnspsc190501.10314903'),
	'identifierSchemeCodeUnspsc190501.10314904': __('identifierSchemeCodeUnspsc190501.10314904'),
	'identifierSchemeCodeUnspsc190501.10314905': __('identifierSchemeCodeUnspsc190501.10314905'),
	'identifierSchemeCodeUnspsc190501.10314906': __('identifierSchemeCodeUnspsc190501.10314906'),
	'identifierSchemeCodeUnspsc190501.10314907': __('identifierSchemeCodeUnspsc190501.10314907'),
	'identifierSchemeCodeUnspsc190501.10314908': __('identifierSchemeCodeUnspsc190501.10314908'),
	'identifierSchemeCodeUnspsc190501.10314909': __('identifierSchemeCodeUnspsc190501.10314909'),
	'identifierSchemeCodeUnspsc190501.10314910': __('identifierSchemeCodeUnspsc190501.10314910'),
	'identifierSchemeCodeUnspsc190501.10314911': __('identifierSchemeCodeUnspsc190501.10314911'),
	'identifierSchemeCodeUnspsc190501.10314912': __('identifierSchemeCodeUnspsc190501.10314912'),
	'identifierSchemeCodeUnspsc190501.10314913': __('identifierSchemeCodeUnspsc190501.10314913'),
	'identifierSchemeCodeUnspsc190501.10314914': __('identifierSchemeCodeUnspsc190501.10314914'),
	'identifierSchemeCodeUnspsc190501.10314915': __('identifierSchemeCodeUnspsc190501.10314915'),
	'identifierSchemeCodeUnspsc190501.10314916': __('identifierSchemeCodeUnspsc190501.10314916'),
	'identifierSchemeCodeUnspsc190501.10314917': __('identifierSchemeCodeUnspsc190501.10314917'),
	'identifierSchemeCodeUnspsc190501.10314918': __('identifierSchemeCodeUnspsc190501.10314918'),
	'identifierSchemeCodeUnspsc190501.10314919': __('identifierSchemeCodeUnspsc190501.10314919'),
	'identifierSchemeCodeUnspsc190501.10315000': __('identifierSchemeCodeUnspsc190501.10315000'),
	'identifierSchemeCodeUnspsc190501.10315001': __('identifierSchemeCodeUnspsc190501.10315001'),
	'identifierSchemeCodeUnspsc190501.10315002': __('identifierSchemeCodeUnspsc190501.10315002'),
	'identifierSchemeCodeUnspsc190501.10315003': __('identifierSchemeCodeUnspsc190501.10315003'),
	'identifierSchemeCodeUnspsc190501.10315004': __('identifierSchemeCodeUnspsc190501.10315004'),
	'identifierSchemeCodeUnspsc190501.10315005': __('identifierSchemeCodeUnspsc190501.10315005'),
	'identifierSchemeCodeUnspsc190501.10315006': __('identifierSchemeCodeUnspsc190501.10315006'),
	'identifierSchemeCodeUnspsc190501.10315007': __('identifierSchemeCodeUnspsc190501.10315007'),
	'identifierSchemeCodeUnspsc190501.10315100': __('identifierSchemeCodeUnspsc190501.10315100'),
	'identifierSchemeCodeUnspsc190501.10315101': __('identifierSchemeCodeUnspsc190501.10315101'),
	'identifierSchemeCodeUnspsc190501.10315102': __('identifierSchemeCodeUnspsc190501.10315102'),
	'identifierSchemeCodeUnspsc190501.10315103': __('identifierSchemeCodeUnspsc190501.10315103'),
	'identifierSchemeCodeUnspsc190501.10315104': __('identifierSchemeCodeUnspsc190501.10315104'),
	'identifierSchemeCodeUnspsc190501.10315105': __('identifierSchemeCodeUnspsc190501.10315105'),
	'identifierSchemeCodeUnspsc190501.10315106': __('identifierSchemeCodeUnspsc190501.10315106'),
	'identifierSchemeCodeUnspsc190501.10315200': __('identifierSchemeCodeUnspsc190501.10315200'),
	'identifierSchemeCodeUnspsc190501.10315201': __('identifierSchemeCodeUnspsc190501.10315201'),
	'identifierSchemeCodeUnspsc190501.10315202': __('identifierSchemeCodeUnspsc190501.10315202'),
	'identifierSchemeCodeUnspsc190501.10315203': __('identifierSchemeCodeUnspsc190501.10315203'),
	'identifierSchemeCodeUnspsc190501.10315204': __('identifierSchemeCodeUnspsc190501.10315204'),
	'identifierSchemeCodeUnspsc190501.10315205': __('identifierSchemeCodeUnspsc190501.10315205'),
	'identifierSchemeCodeUnspsc190501.10315206': __('identifierSchemeCodeUnspsc190501.10315206'),
	'identifierSchemeCodeUnspsc190501.10315300': __('identifierSchemeCodeUnspsc190501.10315300'),
	'identifierSchemeCodeUnspsc190501.10315301': __('identifierSchemeCodeUnspsc190501.10315301'),
	'identifierSchemeCodeUnspsc190501.10315302': __('identifierSchemeCodeUnspsc190501.10315302'),
	'identifierSchemeCodeUnspsc190501.10315303': __('identifierSchemeCodeUnspsc190501.10315303'),
	'identifierSchemeCodeUnspsc190501.10315304': __('identifierSchemeCodeUnspsc190501.10315304'),
	'identifierSchemeCodeUnspsc190501.10315305': __('identifierSchemeCodeUnspsc190501.10315305'),
	'identifierSchemeCodeUnspsc190501.10315306': __('identifierSchemeCodeUnspsc190501.10315306'),
	'identifierSchemeCodeUnspsc190501.10315400': __('identifierSchemeCodeUnspsc190501.10315400'),
	'identifierSchemeCodeUnspsc190501.10315401': __('identifierSchemeCodeUnspsc190501.10315401'),
	'identifierSchemeCodeUnspsc190501.10315402': __('identifierSchemeCodeUnspsc190501.10315402'),
	'identifierSchemeCodeUnspsc190501.10315403': __('identifierSchemeCodeUnspsc190501.10315403'),
	'identifierSchemeCodeUnspsc190501.10315404': __('identifierSchemeCodeUnspsc190501.10315404'),
	'identifierSchemeCodeUnspsc190501.10315405': __('identifierSchemeCodeUnspsc190501.10315405'),
	'identifierSchemeCodeUnspsc190501.10315406': __('identifierSchemeCodeUnspsc190501.10315406'),
	'identifierSchemeCodeUnspsc190501.10315407': __('identifierSchemeCodeUnspsc190501.10315407'),
	'identifierSchemeCodeUnspsc190501.10315408': __('identifierSchemeCodeUnspsc190501.10315408'),
	'identifierSchemeCodeUnspsc190501.10315409': __('identifierSchemeCodeUnspsc190501.10315409'),
	'identifierSchemeCodeUnspsc190501.10315410': __('identifierSchemeCodeUnspsc190501.10315410'),
	'identifierSchemeCodeUnspsc190501.10315411': __('identifierSchemeCodeUnspsc190501.10315411'),
	'identifierSchemeCodeUnspsc190501.10315412': __('identifierSchemeCodeUnspsc190501.10315412'),
	'identifierSchemeCodeUnspsc190501.10315413': __('identifierSchemeCodeUnspsc190501.10315413'),
	'identifierSchemeCodeUnspsc190501.10315414': __('identifierSchemeCodeUnspsc190501.10315414'),
	'identifierSchemeCodeUnspsc190501.10315415': __('identifierSchemeCodeUnspsc190501.10315415'),
	'identifierSchemeCodeUnspsc190501.10315416': __('identifierSchemeCodeUnspsc190501.10315416'),
	'identifierSchemeCodeUnspsc190501.10315417': __('identifierSchemeCodeUnspsc190501.10315417'),
	'identifierSchemeCodeUnspsc190501.10315418': __('identifierSchemeCodeUnspsc190501.10315418'),
	'identifierSchemeCodeUnspsc190501.10315419': __('identifierSchemeCodeUnspsc190501.10315419'),
	'identifierSchemeCodeUnspsc190501.10315420': __('identifierSchemeCodeUnspsc190501.10315420'),
	'identifierSchemeCodeUnspsc190501.10315421': __('identifierSchemeCodeUnspsc190501.10315421'),
	'identifierSchemeCodeUnspsc190501.10315422': __('identifierSchemeCodeUnspsc190501.10315422'),
	'identifierSchemeCodeUnspsc190501.10315423': __('identifierSchemeCodeUnspsc190501.10315423'),
	'identifierSchemeCodeUnspsc190501.10315424': __('identifierSchemeCodeUnspsc190501.10315424'),
	'identifierSchemeCodeUnspsc190501.10315425': __('identifierSchemeCodeUnspsc190501.10315425'),
	'identifierSchemeCodeUnspsc190501.10315426': __('identifierSchemeCodeUnspsc190501.10315426'),
	'identifierSchemeCodeUnspsc190501.10315427': __('identifierSchemeCodeUnspsc190501.10315427'),
	'identifierSchemeCodeUnspsc190501.10315428': __('identifierSchemeCodeUnspsc190501.10315428'),
	'identifierSchemeCodeUnspsc190501.10315429': __('identifierSchemeCodeUnspsc190501.10315429'),
	'identifierSchemeCodeUnspsc190501.10315430': __('identifierSchemeCodeUnspsc190501.10315430'),
	'identifierSchemeCodeUnspsc190501.10315431': __('identifierSchemeCodeUnspsc190501.10315431'),
	'identifierSchemeCodeUnspsc190501.10315432': __('identifierSchemeCodeUnspsc190501.10315432'),
	'identifierSchemeCodeUnspsc190501.10315433': __('identifierSchemeCodeUnspsc190501.10315433'),
	'identifierSchemeCodeUnspsc190501.10315434': __('identifierSchemeCodeUnspsc190501.10315434'),
	'identifierSchemeCodeUnspsc190501.10315435': __('identifierSchemeCodeUnspsc190501.10315435'),
	'identifierSchemeCodeUnspsc190501.10315436': __('identifierSchemeCodeUnspsc190501.10315436'),
	'identifierSchemeCodeUnspsc190501.10315437': __('identifierSchemeCodeUnspsc190501.10315437'),
	'identifierSchemeCodeUnspsc190501.10315438': __('identifierSchemeCodeUnspsc190501.10315438'),
	'identifierSchemeCodeUnspsc190501.10315439': __('identifierSchemeCodeUnspsc190501.10315439'),
	'identifierSchemeCodeUnspsc190501.10315440': __('identifierSchemeCodeUnspsc190501.10315440'),
	'identifierSchemeCodeUnspsc190501.10315441': __('identifierSchemeCodeUnspsc190501.10315441'),
	'identifierSchemeCodeUnspsc190501.10315442': __('identifierSchemeCodeUnspsc190501.10315442'),
	'identifierSchemeCodeUnspsc190501.10315443': __('identifierSchemeCodeUnspsc190501.10315443'),
	'identifierSchemeCodeUnspsc190501.10315444': __('identifierSchemeCodeUnspsc190501.10315444'),
	'identifierSchemeCodeUnspsc190501.10315445': __('identifierSchemeCodeUnspsc190501.10315445'),
	'identifierSchemeCodeUnspsc190501.10315446': __('identifierSchemeCodeUnspsc190501.10315446'),
	'identifierSchemeCodeUnspsc190501.10315447': __('identifierSchemeCodeUnspsc190501.10315447'),
	'identifierSchemeCodeUnspsc190501.10315448': __('identifierSchemeCodeUnspsc190501.10315448'),
	'identifierSchemeCodeUnspsc190501.10315449': __('identifierSchemeCodeUnspsc190501.10315449'),
	'identifierSchemeCodeUnspsc190501.10315450': __('identifierSchemeCodeUnspsc190501.10315450'),
	'identifierSchemeCodeUnspsc190501.10315451': __('identifierSchemeCodeUnspsc190501.10315451'),
	'identifierSchemeCodeUnspsc190501.10315452': __('identifierSchemeCodeUnspsc190501.10315452'),
	'identifierSchemeCodeUnspsc190501.10315453': __('identifierSchemeCodeUnspsc190501.10315453'),
	'identifierSchemeCodeUnspsc190501.10315454': __('identifierSchemeCodeUnspsc190501.10315454'),
	'identifierSchemeCodeUnspsc190501.10315455': __('identifierSchemeCodeUnspsc190501.10315455'),
	'identifierSchemeCodeUnspsc190501.10315456': __('identifierSchemeCodeUnspsc190501.10315456'),
	'identifierSchemeCodeUnspsc190501.10315457': __('identifierSchemeCodeUnspsc190501.10315457'),
	'identifierSchemeCodeUnspsc190501.10315458': __('identifierSchemeCodeUnspsc190501.10315458'),
	'identifierSchemeCodeUnspsc190501.10315459': __('identifierSchemeCodeUnspsc190501.10315459'),
	'identifierSchemeCodeUnspsc190501.10315460': __('identifierSchemeCodeUnspsc190501.10315460'),
	'identifierSchemeCodeUnspsc190501.10315461': __('identifierSchemeCodeUnspsc190501.10315461'),
	'identifierSchemeCodeUnspsc190501.10315462': __('identifierSchemeCodeUnspsc190501.10315462'),
	'identifierSchemeCodeUnspsc190501.10315463': __('identifierSchemeCodeUnspsc190501.10315463'),
	'identifierSchemeCodeUnspsc190501.10315464': __('identifierSchemeCodeUnspsc190501.10315464'),
	'identifierSchemeCodeUnspsc190501.10315465': __('identifierSchemeCodeUnspsc190501.10315465'),
	'identifierSchemeCodeUnspsc190501.10315466': __('identifierSchemeCodeUnspsc190501.10315466'),
	'identifierSchemeCodeUnspsc190501.10315467': __('identifierSchemeCodeUnspsc190501.10315467'),
	'identifierSchemeCodeUnspsc190501.10315468': __('identifierSchemeCodeUnspsc190501.10315468'),
	'identifierSchemeCodeUnspsc190501.10315469': __('identifierSchemeCodeUnspsc190501.10315469'),
	'identifierSchemeCodeUnspsc190501.10315470': __('identifierSchemeCodeUnspsc190501.10315470'),
	'identifierSchemeCodeUnspsc190501.10315471': __('identifierSchemeCodeUnspsc190501.10315471'),
	'identifierSchemeCodeUnspsc190501.10315472': __('identifierSchemeCodeUnspsc190501.10315472'),
	'identifierSchemeCodeUnspsc190501.10315473': __('identifierSchemeCodeUnspsc190501.10315473'),
	'identifierSchemeCodeUnspsc190501.10315474': __('identifierSchemeCodeUnspsc190501.10315474'),
	'identifierSchemeCodeUnspsc190501.10315475': __('identifierSchemeCodeUnspsc190501.10315475'),
	'identifierSchemeCodeUnspsc190501.10315476': __('identifierSchemeCodeUnspsc190501.10315476'),
	'identifierSchemeCodeUnspsc190501.10315477': __('identifierSchemeCodeUnspsc190501.10315477'),
	'identifierSchemeCodeUnspsc190501.10315478': __('identifierSchemeCodeUnspsc190501.10315478'),
	'identifierSchemeCodeUnspsc190501.10315479': __('identifierSchemeCodeUnspsc190501.10315479'),
	'identifierSchemeCodeUnspsc190501.10315480': __('identifierSchemeCodeUnspsc190501.10315480'),
	'identifierSchemeCodeUnspsc190501.10315481': __('identifierSchemeCodeUnspsc190501.10315481'),
	'identifierSchemeCodeUnspsc190501.10315482': __('identifierSchemeCodeUnspsc190501.10315482'),
	'identifierSchemeCodeUnspsc190501.10315483': __('identifierSchemeCodeUnspsc190501.10315483'),
	'identifierSchemeCodeUnspsc190501.10315484': __('identifierSchemeCodeUnspsc190501.10315484'),
	'identifierSchemeCodeUnspsc190501.10315500': __('identifierSchemeCodeUnspsc190501.10315500'),
	'identifierSchemeCodeUnspsc190501.10315501': __('identifierSchemeCodeUnspsc190501.10315501'),
	'identifierSchemeCodeUnspsc190501.10315502': __('identifierSchemeCodeUnspsc190501.10315502'),
	'identifierSchemeCodeUnspsc190501.10315503': __('identifierSchemeCodeUnspsc190501.10315503'),
	'identifierSchemeCodeUnspsc190501.10315504': __('identifierSchemeCodeUnspsc190501.10315504'),
	'identifierSchemeCodeUnspsc190501.10315505': __('identifierSchemeCodeUnspsc190501.10315505'),
	'identifierSchemeCodeUnspsc190501.10315506': __('identifierSchemeCodeUnspsc190501.10315506'),
	'identifierSchemeCodeUnspsc190501.10315600': __('identifierSchemeCodeUnspsc190501.10315600'),
	'identifierSchemeCodeUnspsc190501.10315601': __('identifierSchemeCodeUnspsc190501.10315601'),
	'identifierSchemeCodeUnspsc190501.10315602': __('identifierSchemeCodeUnspsc190501.10315602'),
	'identifierSchemeCodeUnspsc190501.10315603': __('identifierSchemeCodeUnspsc190501.10315603'),
	'identifierSchemeCodeUnspsc190501.10315604': __('identifierSchemeCodeUnspsc190501.10315604'),
	'identifierSchemeCodeUnspsc190501.10315605': __('identifierSchemeCodeUnspsc190501.10315605'),
	'identifierSchemeCodeUnspsc190501.10315606': __('identifierSchemeCodeUnspsc190501.10315606'),
	'identifierSchemeCodeUnspsc190501.10315607': __('identifierSchemeCodeUnspsc190501.10315607'),
	'identifierSchemeCodeUnspsc190501.10315608': __('identifierSchemeCodeUnspsc190501.10315608'),
	'identifierSchemeCodeUnspsc190501.10315609': __('identifierSchemeCodeUnspsc190501.10315609'),
	'identifierSchemeCodeUnspsc190501.10315610': __('identifierSchemeCodeUnspsc190501.10315610'),
	'identifierSchemeCodeUnspsc190501.10315611': __('identifierSchemeCodeUnspsc190501.10315611'),
	'identifierSchemeCodeUnspsc190501.10315612': __('identifierSchemeCodeUnspsc190501.10315612'),
	'identifierSchemeCodeUnspsc190501.10315613': __('identifierSchemeCodeUnspsc190501.10315613'),
	'identifierSchemeCodeUnspsc190501.10315700': __('identifierSchemeCodeUnspsc190501.10315700'),
	'identifierSchemeCodeUnspsc190501.10315701': __('identifierSchemeCodeUnspsc190501.10315701'),
	'identifierSchemeCodeUnspsc190501.10315702': __('identifierSchemeCodeUnspsc190501.10315702'),
	'identifierSchemeCodeUnspsc190501.10315703': __('identifierSchemeCodeUnspsc190501.10315703'),
	'identifierSchemeCodeUnspsc190501.10315704': __('identifierSchemeCodeUnspsc190501.10315704'),
	'identifierSchemeCodeUnspsc190501.10315705': __('identifierSchemeCodeUnspsc190501.10315705'),
	'identifierSchemeCodeUnspsc190501.10315800': __('identifierSchemeCodeUnspsc190501.10315800'),
	'identifierSchemeCodeUnspsc190501.10315801': __('identifierSchemeCodeUnspsc190501.10315801'),
	'identifierSchemeCodeUnspsc190501.10315802': __('identifierSchemeCodeUnspsc190501.10315802'),
	'identifierSchemeCodeUnspsc190501.10315803': __('identifierSchemeCodeUnspsc190501.10315803'),
	'identifierSchemeCodeUnspsc190501.10315804': __('identifierSchemeCodeUnspsc190501.10315804'),
	'identifierSchemeCodeUnspsc190501.10315805': __('identifierSchemeCodeUnspsc190501.10315805'),
	'identifierSchemeCodeUnspsc190501.10315806': __('identifierSchemeCodeUnspsc190501.10315806'),
	'identifierSchemeCodeUnspsc190501.10315807': __('identifierSchemeCodeUnspsc190501.10315807'),
	'identifierSchemeCodeUnspsc190501.10315808': __('identifierSchemeCodeUnspsc190501.10315808'),
	'identifierSchemeCodeUnspsc190501.10316000': __('identifierSchemeCodeUnspsc190501.10316000'),
	'identifierSchemeCodeUnspsc190501.10316001': __('identifierSchemeCodeUnspsc190501.10316001'),
	'identifierSchemeCodeUnspsc190501.10316002': __('identifierSchemeCodeUnspsc190501.10316002'),
	'identifierSchemeCodeUnspsc190501.10316003': __('identifierSchemeCodeUnspsc190501.10316003'),
	'identifierSchemeCodeUnspsc190501.10316004': __('identifierSchemeCodeUnspsc190501.10316004'),
	'identifierSchemeCodeUnspsc190501.10316005': __('identifierSchemeCodeUnspsc190501.10316005'),
	'identifierSchemeCodeUnspsc190501.10316100': __('identifierSchemeCodeUnspsc190501.10316100'),
	'identifierSchemeCodeUnspsc190501.10316101': __('identifierSchemeCodeUnspsc190501.10316101'),
	'identifierSchemeCodeUnspsc190501.10316102': __('identifierSchemeCodeUnspsc190501.10316102'),
	'identifierSchemeCodeUnspsc190501.10316103': __('identifierSchemeCodeUnspsc190501.10316103'),
	'identifierSchemeCodeUnspsc190501.10316104': __('identifierSchemeCodeUnspsc190501.10316104'),
	'identifierSchemeCodeUnspsc190501.10316105': __('identifierSchemeCodeUnspsc190501.10316105'),
	'identifierSchemeCodeUnspsc190501.10316200': __('identifierSchemeCodeUnspsc190501.10316200'),
	'identifierSchemeCodeUnspsc190501.10316201': __('identifierSchemeCodeUnspsc190501.10316201'),
	'identifierSchemeCodeUnspsc190501.10316202': __('identifierSchemeCodeUnspsc190501.10316202'),
	'identifierSchemeCodeUnspsc190501.10316203': __('identifierSchemeCodeUnspsc190501.10316203'),
	'identifierSchemeCodeUnspsc190501.10316204': __('identifierSchemeCodeUnspsc190501.10316204'),
	'identifierSchemeCodeUnspsc190501.10316205': __('identifierSchemeCodeUnspsc190501.10316205'),
	'identifierSchemeCodeUnspsc190501.10316206': __('identifierSchemeCodeUnspsc190501.10316206'),
	'identifierSchemeCodeUnspsc190501.10316207': __('identifierSchemeCodeUnspsc190501.10316207'),
	'identifierSchemeCodeUnspsc190501.10316208': __('identifierSchemeCodeUnspsc190501.10316208'),
	'identifierSchemeCodeUnspsc190501.10316209': __('identifierSchemeCodeUnspsc190501.10316209'),
	'identifierSchemeCodeUnspsc190501.10316210': __('identifierSchemeCodeUnspsc190501.10316210'),
	'identifierSchemeCodeUnspsc190501.10316211': __('identifierSchemeCodeUnspsc190501.10316211'),
	'identifierSchemeCodeUnspsc190501.10316212': __('identifierSchemeCodeUnspsc190501.10316212'),
	'identifierSchemeCodeUnspsc190501.10316213': __('identifierSchemeCodeUnspsc190501.10316213'),
	'identifierSchemeCodeUnspsc190501.10316214': __('identifierSchemeCodeUnspsc190501.10316214'),
	'identifierSchemeCodeUnspsc190501.10316215': __('identifierSchemeCodeUnspsc190501.10316215'),
	'identifierSchemeCodeUnspsc190501.10316216': __('identifierSchemeCodeUnspsc190501.10316216'),
	'identifierSchemeCodeUnspsc190501.10316217': __('identifierSchemeCodeUnspsc190501.10316217'),
	'identifierSchemeCodeUnspsc190501.10316218': __('identifierSchemeCodeUnspsc190501.10316218'),
	'identifierSchemeCodeUnspsc190501.10316300': __('identifierSchemeCodeUnspsc190501.10316300'),
	'identifierSchemeCodeUnspsc190501.10316301': __('identifierSchemeCodeUnspsc190501.10316301'),
	'identifierSchemeCodeUnspsc190501.10316302': __('identifierSchemeCodeUnspsc190501.10316302'),
	'identifierSchemeCodeUnspsc190501.10316306': __('identifierSchemeCodeUnspsc190501.10316306'),
	'identifierSchemeCodeUnspsc190501.10316311': __('identifierSchemeCodeUnspsc190501.10316311'),
	'identifierSchemeCodeUnspsc190501.10316315': __('identifierSchemeCodeUnspsc190501.10316315'),
	'identifierSchemeCodeUnspsc190501.10316322': __('identifierSchemeCodeUnspsc190501.10316322'),
	'identifierSchemeCodeUnspsc190501.10316325': __('identifierSchemeCodeUnspsc190501.10316325'),
	'identifierSchemeCodeUnspsc190501.10316326': __('identifierSchemeCodeUnspsc190501.10316326'),
	'identifierSchemeCodeUnspsc190501.10316332': __('identifierSchemeCodeUnspsc190501.10316332'),
	'identifierSchemeCodeUnspsc190501.10316400': __('identifierSchemeCodeUnspsc190501.10316400'),
	'identifierSchemeCodeUnspsc190501.10316401': __('identifierSchemeCodeUnspsc190501.10316401'),
	'identifierSchemeCodeUnspsc190501.10316402': __('identifierSchemeCodeUnspsc190501.10316402'),
	'identifierSchemeCodeUnspsc190501.10316403': __('identifierSchemeCodeUnspsc190501.10316403'),
	'identifierSchemeCodeUnspsc190501.10316404': __('identifierSchemeCodeUnspsc190501.10316404'),
	'identifierSchemeCodeUnspsc190501.10316405': __('identifierSchemeCodeUnspsc190501.10316405'),
	'identifierSchemeCodeUnspsc190501.10316406': __('identifierSchemeCodeUnspsc190501.10316406'),
	'identifierSchemeCodeUnspsc190501.10316407': __('identifierSchemeCodeUnspsc190501.10316407'),
	'identifierSchemeCodeUnspsc190501.10316408': __('identifierSchemeCodeUnspsc190501.10316408'),
	'identifierSchemeCodeUnspsc190501.10316409': __('identifierSchemeCodeUnspsc190501.10316409'),
	'identifierSchemeCodeUnspsc190501.10316410': __('identifierSchemeCodeUnspsc190501.10316410'),
	'identifierSchemeCodeUnspsc190501.10316411': __('identifierSchemeCodeUnspsc190501.10316411'),
	'identifierSchemeCodeUnspsc190501.10316412': __('identifierSchemeCodeUnspsc190501.10316412'),
	'identifierSchemeCodeUnspsc190501.10316413': __('identifierSchemeCodeUnspsc190501.10316413'),
	'identifierSchemeCodeUnspsc190501.10316500': __('identifierSchemeCodeUnspsc190501.10316500'),
	'identifierSchemeCodeUnspsc190501.10316501': __('identifierSchemeCodeUnspsc190501.10316501'),
	'identifierSchemeCodeUnspsc190501.10316502': __('identifierSchemeCodeUnspsc190501.10316502'),
	'identifierSchemeCodeUnspsc190501.10316503': __('identifierSchemeCodeUnspsc190501.10316503'),
	'identifierSchemeCodeUnspsc190501.10316504': __('identifierSchemeCodeUnspsc190501.10316504'),
	'identifierSchemeCodeUnspsc190501.10316505': __('identifierSchemeCodeUnspsc190501.10316505'),
	'identifierSchemeCodeUnspsc190501.10316506': __('identifierSchemeCodeUnspsc190501.10316506'),
	'identifierSchemeCodeUnspsc190501.10316507': __('identifierSchemeCodeUnspsc190501.10316507'),
	'identifierSchemeCodeUnspsc190501.10316600': __('identifierSchemeCodeUnspsc190501.10316600'),
	'identifierSchemeCodeUnspsc190501.10316601': __('identifierSchemeCodeUnspsc190501.10316601'),
	'identifierSchemeCodeUnspsc190501.10316602': __('identifierSchemeCodeUnspsc190501.10316602'),
	'identifierSchemeCodeUnspsc190501.10316603': __('identifierSchemeCodeUnspsc190501.10316603'),
	'identifierSchemeCodeUnspsc190501.10316604': __('identifierSchemeCodeUnspsc190501.10316604'),
	'identifierSchemeCodeUnspsc190501.10316700': __('identifierSchemeCodeUnspsc190501.10316700'),
	'identifierSchemeCodeUnspsc190501.10316701': __('identifierSchemeCodeUnspsc190501.10316701'),
	'identifierSchemeCodeUnspsc190501.10316702': __('identifierSchemeCodeUnspsc190501.10316702'),
	'identifierSchemeCodeUnspsc190501.10316703': __('identifierSchemeCodeUnspsc190501.10316703'),
	'identifierSchemeCodeUnspsc190501.10316704': __('identifierSchemeCodeUnspsc190501.10316704'),
	'identifierSchemeCodeUnspsc190501.10316705': __('identifierSchemeCodeUnspsc190501.10316705'),
	'identifierSchemeCodeUnspsc190501.10316706': __('identifierSchemeCodeUnspsc190501.10316706'),
	'identifierSchemeCodeUnspsc190501.10316707': __('identifierSchemeCodeUnspsc190501.10316707'),
	'identifierSchemeCodeUnspsc190501.10316708': __('identifierSchemeCodeUnspsc190501.10316708'),
	'identifierSchemeCodeUnspsc190501.10316709': __('identifierSchemeCodeUnspsc190501.10316709'),
	'identifierSchemeCodeUnspsc190501.10316800': __('identifierSchemeCodeUnspsc190501.10316800'),
	'identifierSchemeCodeUnspsc190501.10316801': __('identifierSchemeCodeUnspsc190501.10316801'),
	'identifierSchemeCodeUnspsc190501.10316802': __('identifierSchemeCodeUnspsc190501.10316802'),
	'identifierSchemeCodeUnspsc190501.10316803': __('identifierSchemeCodeUnspsc190501.10316803'),
	'identifierSchemeCodeUnspsc190501.10316804': __('identifierSchemeCodeUnspsc190501.10316804'),
	'identifierSchemeCodeUnspsc190501.10316805': __('identifierSchemeCodeUnspsc190501.10316805'),
	'identifierSchemeCodeUnspsc190501.10316806': __('identifierSchemeCodeUnspsc190501.10316806'),
	'identifierSchemeCodeUnspsc190501.10316807': __('identifierSchemeCodeUnspsc190501.10316807'),
	'identifierSchemeCodeUnspsc190501.10316808': __('identifierSchemeCodeUnspsc190501.10316808'),
	'identifierSchemeCodeUnspsc190501.10316900': __('identifierSchemeCodeUnspsc190501.10316900'),
	'identifierSchemeCodeUnspsc190501.10316901': __('identifierSchemeCodeUnspsc190501.10316901'),
	'identifierSchemeCodeUnspsc190501.10316902': __('identifierSchemeCodeUnspsc190501.10316902'),
	'identifierSchemeCodeUnspsc190501.10316903': __('identifierSchemeCodeUnspsc190501.10316903'),
	'identifierSchemeCodeUnspsc190501.10316904': __('identifierSchemeCodeUnspsc190501.10316904'),
	'identifierSchemeCodeUnspsc190501.10316905': __('identifierSchemeCodeUnspsc190501.10316905'),
	'identifierSchemeCodeUnspsc190501.10316906': __('identifierSchemeCodeUnspsc190501.10316906'),
	'identifierSchemeCodeUnspsc190501.10316907': __('identifierSchemeCodeUnspsc190501.10316907'),
	'identifierSchemeCodeUnspsc190501.10316908': __('identifierSchemeCodeUnspsc190501.10316908'),
	'identifierSchemeCodeUnspsc190501.10316909': __('identifierSchemeCodeUnspsc190501.10316909'),
	'identifierSchemeCodeUnspsc190501.10316910': __('identifierSchemeCodeUnspsc190501.10316910'),
	'identifierSchemeCodeUnspsc190501.10317000': __('identifierSchemeCodeUnspsc190501.10317000'),
	'identifierSchemeCodeUnspsc190501.10317001': __('identifierSchemeCodeUnspsc190501.10317001'),
	'identifierSchemeCodeUnspsc190501.10317002': __('identifierSchemeCodeUnspsc190501.10317002'),
	'identifierSchemeCodeUnspsc190501.10317003': __('identifierSchemeCodeUnspsc190501.10317003'),
	'identifierSchemeCodeUnspsc190501.10317004': __('identifierSchemeCodeUnspsc190501.10317004'),
	'identifierSchemeCodeUnspsc190501.10317005': __('identifierSchemeCodeUnspsc190501.10317005'),
	'identifierSchemeCodeUnspsc190501.10317006': __('identifierSchemeCodeUnspsc190501.10317006'),
	'identifierSchemeCodeUnspsc190501.10317100': __('identifierSchemeCodeUnspsc190501.10317100'),
	'identifierSchemeCodeUnspsc190501.10317101': __('identifierSchemeCodeUnspsc190501.10317101'),
	'identifierSchemeCodeUnspsc190501.10317102': __('identifierSchemeCodeUnspsc190501.10317102'),
	'identifierSchemeCodeUnspsc190501.10317103': __('identifierSchemeCodeUnspsc190501.10317103'),
	'identifierSchemeCodeUnspsc190501.10317104': __('identifierSchemeCodeUnspsc190501.10317104'),
	'identifierSchemeCodeUnspsc190501.10317105': __('identifierSchemeCodeUnspsc190501.10317105'),
	'identifierSchemeCodeUnspsc190501.10317106': __('identifierSchemeCodeUnspsc190501.10317106'),
	'identifierSchemeCodeUnspsc190501.10317107': __('identifierSchemeCodeUnspsc190501.10317107'),
	'identifierSchemeCodeUnspsc190501.10317108': __('identifierSchemeCodeUnspsc190501.10317108'),
	'identifierSchemeCodeUnspsc190501.10317200': __('identifierSchemeCodeUnspsc190501.10317200'),
	'identifierSchemeCodeUnspsc190501.10317201': __('identifierSchemeCodeUnspsc190501.10317201'),
	'identifierSchemeCodeUnspsc190501.10317202': __('identifierSchemeCodeUnspsc190501.10317202'),
	'identifierSchemeCodeUnspsc190501.10317203': __('identifierSchemeCodeUnspsc190501.10317203'),
	'identifierSchemeCodeUnspsc190501.10317204': __('identifierSchemeCodeUnspsc190501.10317204'),
	'identifierSchemeCodeUnspsc190501.10317205': __('identifierSchemeCodeUnspsc190501.10317205'),
	'identifierSchemeCodeUnspsc190501.10317206': __('identifierSchemeCodeUnspsc190501.10317206'),
	'identifierSchemeCodeUnspsc190501.10317207': __('identifierSchemeCodeUnspsc190501.10317207'),
	'identifierSchemeCodeUnspsc190501.10317208': __('identifierSchemeCodeUnspsc190501.10317208'),
	'identifierSchemeCodeUnspsc190501.10317300': __('identifierSchemeCodeUnspsc190501.10317300'),
	'identifierSchemeCodeUnspsc190501.10317301': __('identifierSchemeCodeUnspsc190501.10317301'),
	'identifierSchemeCodeUnspsc190501.10317302': __('identifierSchemeCodeUnspsc190501.10317302'),
	'identifierSchemeCodeUnspsc190501.10317303': __('identifierSchemeCodeUnspsc190501.10317303'),
	'identifierSchemeCodeUnspsc190501.10317304': __('identifierSchemeCodeUnspsc190501.10317304'),
	'identifierSchemeCodeUnspsc190501.10317305': __('identifierSchemeCodeUnspsc190501.10317305'),
	'identifierSchemeCodeUnspsc190501.10317306': __('identifierSchemeCodeUnspsc190501.10317306'),
	'identifierSchemeCodeUnspsc190501.10317307': __('identifierSchemeCodeUnspsc190501.10317307'),
	'identifierSchemeCodeUnspsc190501.10317308': __('identifierSchemeCodeUnspsc190501.10317308'),
	'identifierSchemeCodeUnspsc190501.10317309': __('identifierSchemeCodeUnspsc190501.10317309'),
	'identifierSchemeCodeUnspsc190501.10317310': __('identifierSchemeCodeUnspsc190501.10317310'),
	'identifierSchemeCodeUnspsc190501.10317311': __('identifierSchemeCodeUnspsc190501.10317311'),
	'identifierSchemeCodeUnspsc190501.10317312': __('identifierSchemeCodeUnspsc190501.10317312'),
	'identifierSchemeCodeUnspsc190501.10317313': __('identifierSchemeCodeUnspsc190501.10317313'),
	'identifierSchemeCodeUnspsc190501.10317314': __('identifierSchemeCodeUnspsc190501.10317314'),
	'identifierSchemeCodeUnspsc190501.10317315': __('identifierSchemeCodeUnspsc190501.10317315'),
	'identifierSchemeCodeUnspsc190501.10317316': __('identifierSchemeCodeUnspsc190501.10317316'),
	'identifierSchemeCodeUnspsc190501.10317317': __('identifierSchemeCodeUnspsc190501.10317317'),
	'identifierSchemeCodeUnspsc190501.10317318': __('identifierSchemeCodeUnspsc190501.10317318'),
	'identifierSchemeCodeUnspsc190501.10317319': __('identifierSchemeCodeUnspsc190501.10317319'),
	'identifierSchemeCodeUnspsc190501.10317320': __('identifierSchemeCodeUnspsc190501.10317320'),
	'identifierSchemeCodeUnspsc190501.10317321': __('identifierSchemeCodeUnspsc190501.10317321'),
	'identifierSchemeCodeUnspsc190501.10317322': __('identifierSchemeCodeUnspsc190501.10317322'),
	'identifierSchemeCodeUnspsc190501.10317323': __('identifierSchemeCodeUnspsc190501.10317323'),
	'identifierSchemeCodeUnspsc190501.10317324': __('identifierSchemeCodeUnspsc190501.10317324'),
	'identifierSchemeCodeUnspsc190501.10317325': __('identifierSchemeCodeUnspsc190501.10317325'),
	'identifierSchemeCodeUnspsc190501.10317326': __('identifierSchemeCodeUnspsc190501.10317326'),
	'identifierSchemeCodeUnspsc190501.10317327': __('identifierSchemeCodeUnspsc190501.10317327'),
	'identifierSchemeCodeUnspsc190501.10317328': __('identifierSchemeCodeUnspsc190501.10317328'),
	'identifierSchemeCodeUnspsc190501.10317329': __('identifierSchemeCodeUnspsc190501.10317329'),
	'identifierSchemeCodeUnspsc190501.10317330': __('identifierSchemeCodeUnspsc190501.10317330'),
	'identifierSchemeCodeUnspsc190501.10317331': __('identifierSchemeCodeUnspsc190501.10317331'),
	'identifierSchemeCodeUnspsc190501.10317332': __('identifierSchemeCodeUnspsc190501.10317332'),
	'identifierSchemeCodeUnspsc190501.10317333': __('identifierSchemeCodeUnspsc190501.10317333'),
	'identifierSchemeCodeUnspsc190501.10317334': __('identifierSchemeCodeUnspsc190501.10317334'),
	'identifierSchemeCodeUnspsc190501.10317335': __('identifierSchemeCodeUnspsc190501.10317335'),
	'identifierSchemeCodeUnspsc190501.10317336': __('identifierSchemeCodeUnspsc190501.10317336'),
	'identifierSchemeCodeUnspsc190501.10317337': __('identifierSchemeCodeUnspsc190501.10317337'),
	'identifierSchemeCodeUnspsc190501.10317338': __('identifierSchemeCodeUnspsc190501.10317338'),
	'identifierSchemeCodeUnspsc190501.10317339': __('identifierSchemeCodeUnspsc190501.10317339'),
	'identifierSchemeCodeUnspsc190501.10317340': __('identifierSchemeCodeUnspsc190501.10317340'),
	'identifierSchemeCodeUnspsc190501.10317341': __('identifierSchemeCodeUnspsc190501.10317341'),
	'identifierSchemeCodeUnspsc190501.10317342': __('identifierSchemeCodeUnspsc190501.10317342'),
	'identifierSchemeCodeUnspsc190501.10317343': __('identifierSchemeCodeUnspsc190501.10317343'),
	'identifierSchemeCodeUnspsc190501.10317344': __('identifierSchemeCodeUnspsc190501.10317344'),
	'identifierSchemeCodeUnspsc190501.10317345': __('identifierSchemeCodeUnspsc190501.10317345'),
	'identifierSchemeCodeUnspsc190501.10317346': __('identifierSchemeCodeUnspsc190501.10317346'),
	'identifierSchemeCodeUnspsc190501.10317347': __('identifierSchemeCodeUnspsc190501.10317347'),
	'identifierSchemeCodeUnspsc190501.10317348': __('identifierSchemeCodeUnspsc190501.10317348'),
	'identifierSchemeCodeUnspsc190501.10317349': __('identifierSchemeCodeUnspsc190501.10317349'),
	'identifierSchemeCodeUnspsc190501.10317350': __('identifierSchemeCodeUnspsc190501.10317350'),
	'identifierSchemeCodeUnspsc190501.10317351': __('identifierSchemeCodeUnspsc190501.10317351'),
	'identifierSchemeCodeUnspsc190501.10317352': __('identifierSchemeCodeUnspsc190501.10317352'),
	'identifierSchemeCodeUnspsc190501.10317353': __('identifierSchemeCodeUnspsc190501.10317353'),
	'identifierSchemeCodeUnspsc190501.10317354': __('identifierSchemeCodeUnspsc190501.10317354'),
	'identifierSchemeCodeUnspsc190501.10317355': __('identifierSchemeCodeUnspsc190501.10317355'),
	'identifierSchemeCodeUnspsc190501.10317400': __('identifierSchemeCodeUnspsc190501.10317400'),
	'identifierSchemeCodeUnspsc190501.10317401': __('identifierSchemeCodeUnspsc190501.10317401'),
	'identifierSchemeCodeUnspsc190501.10317402': __('identifierSchemeCodeUnspsc190501.10317402'),
	'identifierSchemeCodeUnspsc190501.10317403': __('identifierSchemeCodeUnspsc190501.10317403'),
	'identifierSchemeCodeUnspsc190501.10317404': __('identifierSchemeCodeUnspsc190501.10317404'),
	'identifierSchemeCodeUnspsc190501.10317405': __('identifierSchemeCodeUnspsc190501.10317405'),
	'identifierSchemeCodeUnspsc190501.10317406': __('identifierSchemeCodeUnspsc190501.10317406'),
	'identifierSchemeCodeUnspsc190501.10317407': __('identifierSchemeCodeUnspsc190501.10317407'),
	'identifierSchemeCodeUnspsc190501.10317408': __('identifierSchemeCodeUnspsc190501.10317408'),
	'identifierSchemeCodeUnspsc190501.10317409': __('identifierSchemeCodeUnspsc190501.10317409'),
	'identifierSchemeCodeUnspsc190501.10317410': __('identifierSchemeCodeUnspsc190501.10317410'),
	'identifierSchemeCodeUnspsc190501.10317411': __('identifierSchemeCodeUnspsc190501.10317411'),
	'identifierSchemeCodeUnspsc190501.10317412': __('identifierSchemeCodeUnspsc190501.10317412'),
	'identifierSchemeCodeUnspsc190501.10317413': __('identifierSchemeCodeUnspsc190501.10317413'),
	'identifierSchemeCodeUnspsc190501.10317414': __('identifierSchemeCodeUnspsc190501.10317414'),
	'identifierSchemeCodeUnspsc190501.10317415': __('identifierSchemeCodeUnspsc190501.10317415'),
	'identifierSchemeCodeUnspsc190501.10317416': __('identifierSchemeCodeUnspsc190501.10317416'),
	'identifierSchemeCodeUnspsc190501.10317417': __('identifierSchemeCodeUnspsc190501.10317417'),
	'identifierSchemeCodeUnspsc190501.10317418': __('identifierSchemeCodeUnspsc190501.10317418'),
	'identifierSchemeCodeUnspsc190501.10317419': __('identifierSchemeCodeUnspsc190501.10317419'),
	'identifierSchemeCodeUnspsc190501.10317420': __('identifierSchemeCodeUnspsc190501.10317420'),
	'identifierSchemeCodeUnspsc190501.10317421': __('identifierSchemeCodeUnspsc190501.10317421'),
	'identifierSchemeCodeUnspsc190501.10317422': __('identifierSchemeCodeUnspsc190501.10317422'),
	'identifierSchemeCodeUnspsc190501.10317423': __('identifierSchemeCodeUnspsc190501.10317423'),
	'identifierSchemeCodeUnspsc190501.10317424': __('identifierSchemeCodeUnspsc190501.10317424'),
	'identifierSchemeCodeUnspsc190501.10317500': __('identifierSchemeCodeUnspsc190501.10317500'),
	'identifierSchemeCodeUnspsc190501.10317501': __('identifierSchemeCodeUnspsc190501.10317501'),
	'identifierSchemeCodeUnspsc190501.10317502': __('identifierSchemeCodeUnspsc190501.10317502'),
	'identifierSchemeCodeUnspsc190501.10317503': __('identifierSchemeCodeUnspsc190501.10317503'),
	'identifierSchemeCodeUnspsc190501.10317504': __('identifierSchemeCodeUnspsc190501.10317504'),
	'identifierSchemeCodeUnspsc190501.10317505': __('identifierSchemeCodeUnspsc190501.10317505'),
	'identifierSchemeCodeUnspsc190501.10317506': __('identifierSchemeCodeUnspsc190501.10317506'),
	'identifierSchemeCodeUnspsc190501.10317507': __('identifierSchemeCodeUnspsc190501.10317507'),
	'identifierSchemeCodeUnspsc190501.10317508': __('identifierSchemeCodeUnspsc190501.10317508'),
	'identifierSchemeCodeUnspsc190501.10317509': __('identifierSchemeCodeUnspsc190501.10317509'),
	'identifierSchemeCodeUnspsc190501.10317510': __('identifierSchemeCodeUnspsc190501.10317510'),
	'identifierSchemeCodeUnspsc190501.10317600': __('identifierSchemeCodeUnspsc190501.10317600'),
	'identifierSchemeCodeUnspsc190501.10317601': __('identifierSchemeCodeUnspsc190501.10317601'),
	'identifierSchemeCodeUnspsc190501.10317602': __('identifierSchemeCodeUnspsc190501.10317602'),
	'identifierSchemeCodeUnspsc190501.10317603': __('identifierSchemeCodeUnspsc190501.10317603'),
	'identifierSchemeCodeUnspsc190501.10317604': __('identifierSchemeCodeUnspsc190501.10317604'),
	'identifierSchemeCodeUnspsc190501.10317605': __('identifierSchemeCodeUnspsc190501.10317605'),
	'identifierSchemeCodeUnspsc190501.10317606': __('identifierSchemeCodeUnspsc190501.10317606'),
	'identifierSchemeCodeUnspsc190501.10317700': __('identifierSchemeCodeUnspsc190501.10317700'),
	'identifierSchemeCodeUnspsc190501.10317701': __('identifierSchemeCodeUnspsc190501.10317701'),
	'identifierSchemeCodeUnspsc190501.10317702': __('identifierSchemeCodeUnspsc190501.10317702'),
	'identifierSchemeCodeUnspsc190501.10317703': __('identifierSchemeCodeUnspsc190501.10317703'),
	'identifierSchemeCodeUnspsc190501.10317704': __('identifierSchemeCodeUnspsc190501.10317704'),
	'identifierSchemeCodeUnspsc190501.10317705': __('identifierSchemeCodeUnspsc190501.10317705'),
	'identifierSchemeCodeUnspsc190501.10317706': __('identifierSchemeCodeUnspsc190501.10317706'),
	'identifierSchemeCodeUnspsc190501.10317707': __('identifierSchemeCodeUnspsc190501.10317707'),
	'identifierSchemeCodeUnspsc190501.10317708': __('identifierSchemeCodeUnspsc190501.10317708'),
	'identifierSchemeCodeUnspsc190501.10317800': __('identifierSchemeCodeUnspsc190501.10317800'),
	'identifierSchemeCodeUnspsc190501.10317801': __('identifierSchemeCodeUnspsc190501.10317801'),
	'identifierSchemeCodeUnspsc190501.10317802': __('identifierSchemeCodeUnspsc190501.10317802'),
	'identifierSchemeCodeUnspsc190501.10317803': __('identifierSchemeCodeUnspsc190501.10317803'),
	'identifierSchemeCodeUnspsc190501.10317804': __('identifierSchemeCodeUnspsc190501.10317804'),
	'identifierSchemeCodeUnspsc190501.10317805': __('identifierSchemeCodeUnspsc190501.10317805'),
	'identifierSchemeCodeUnspsc190501.10317806': __('identifierSchemeCodeUnspsc190501.10317806'),
	'identifierSchemeCodeUnspsc190501.10317807': __('identifierSchemeCodeUnspsc190501.10317807'),
	'identifierSchemeCodeUnspsc190501.10317808': __('identifierSchemeCodeUnspsc190501.10317808'),
	'identifierSchemeCodeUnspsc190501.10317809': __('identifierSchemeCodeUnspsc190501.10317809'),
	'identifierSchemeCodeUnspsc190501.10317810': __('identifierSchemeCodeUnspsc190501.10317810'),
	'identifierSchemeCodeUnspsc190501.10317811': __('identifierSchemeCodeUnspsc190501.10317811'),
	'identifierSchemeCodeUnspsc190501.10317812': __('identifierSchemeCodeUnspsc190501.10317812'),
	'identifierSchemeCodeUnspsc190501.10317813': __('identifierSchemeCodeUnspsc190501.10317813'),
	'identifierSchemeCodeUnspsc190501.10317814': __('identifierSchemeCodeUnspsc190501.10317814'),
	'identifierSchemeCodeUnspsc190501.10317815': __('identifierSchemeCodeUnspsc190501.10317815'),
	'identifierSchemeCodeUnspsc190501.10317816': __('identifierSchemeCodeUnspsc190501.10317816'),
	'identifierSchemeCodeUnspsc190501.10317817': __('identifierSchemeCodeUnspsc190501.10317817'),
	'identifierSchemeCodeUnspsc190501.10317818': __('identifierSchemeCodeUnspsc190501.10317818'),
	'identifierSchemeCodeUnspsc190501.10317819': __('identifierSchemeCodeUnspsc190501.10317819'),
	'identifierSchemeCodeUnspsc190501.10317820': __('identifierSchemeCodeUnspsc190501.10317820'),
	'identifierSchemeCodeUnspsc190501.10317821': __('identifierSchemeCodeUnspsc190501.10317821'),
	'identifierSchemeCodeUnspsc190501.10317822': __('identifierSchemeCodeUnspsc190501.10317822'),
	'identifierSchemeCodeUnspsc190501.10317823': __('identifierSchemeCodeUnspsc190501.10317823'),
	'identifierSchemeCodeUnspsc190501.10317824': __('identifierSchemeCodeUnspsc190501.10317824'),
	'identifierSchemeCodeUnspsc190501.10317825': __('identifierSchemeCodeUnspsc190501.10317825'),
	'identifierSchemeCodeUnspsc190501.10317826': __('identifierSchemeCodeUnspsc190501.10317826'),
	'identifierSchemeCodeUnspsc190501.10317827': __('identifierSchemeCodeUnspsc190501.10317827'),
	'identifierSchemeCodeUnspsc190501.10317900': __('identifierSchemeCodeUnspsc190501.10317900'),
	'identifierSchemeCodeUnspsc190501.10317901': __('identifierSchemeCodeUnspsc190501.10317901'),
	'identifierSchemeCodeUnspsc190501.10317902': __('identifierSchemeCodeUnspsc190501.10317902'),
	'identifierSchemeCodeUnspsc190501.10317903': __('identifierSchemeCodeUnspsc190501.10317903'),
	'identifierSchemeCodeUnspsc190501.10317904': __('identifierSchemeCodeUnspsc190501.10317904'),
	'identifierSchemeCodeUnspsc190501.10317905': __('identifierSchemeCodeUnspsc190501.10317905'),
	'identifierSchemeCodeUnspsc190501.10317906': __('identifierSchemeCodeUnspsc190501.10317906'),
	'identifierSchemeCodeUnspsc190501.10317907': __('identifierSchemeCodeUnspsc190501.10317907'),
	'identifierSchemeCodeUnspsc190501.10317908': __('identifierSchemeCodeUnspsc190501.10317908'),
	'identifierSchemeCodeUnspsc190501.10317909': __('identifierSchemeCodeUnspsc190501.10317909'),
	'identifierSchemeCodeUnspsc190501.10317910': __('identifierSchemeCodeUnspsc190501.10317910'),
	'identifierSchemeCodeUnspsc190501.10317911': __('identifierSchemeCodeUnspsc190501.10317911'),
	'identifierSchemeCodeUnspsc190501.10317912': __('identifierSchemeCodeUnspsc190501.10317912'),
	'identifierSchemeCodeUnspsc190501.10317913': __('identifierSchemeCodeUnspsc190501.10317913'),
	'identifierSchemeCodeUnspsc190501.10317914': __('identifierSchemeCodeUnspsc190501.10317914'),
	'identifierSchemeCodeUnspsc190501.10317915': __('identifierSchemeCodeUnspsc190501.10317915'),
	'identifierSchemeCodeUnspsc190501.10317916': __('identifierSchemeCodeUnspsc190501.10317916'),
	'identifierSchemeCodeUnspsc190501.10317917': __('identifierSchemeCodeUnspsc190501.10317917'),
	'identifierSchemeCodeUnspsc190501.10317918': __('identifierSchemeCodeUnspsc190501.10317918'),
	'identifierSchemeCodeUnspsc190501.10317919': __('identifierSchemeCodeUnspsc190501.10317919'),
	'identifierSchemeCodeUnspsc190501.10317920': __('identifierSchemeCodeUnspsc190501.10317920'),
	'identifierSchemeCodeUnspsc190501.10317921': __('identifierSchemeCodeUnspsc190501.10317921'),
	'identifierSchemeCodeUnspsc190501.10317922': __('identifierSchemeCodeUnspsc190501.10317922'),
	'identifierSchemeCodeUnspsc190501.10317923': __('identifierSchemeCodeUnspsc190501.10317923'),
	'identifierSchemeCodeUnspsc190501.10317924': __('identifierSchemeCodeUnspsc190501.10317924'),
	'identifierSchemeCodeUnspsc190501.10317925': __('identifierSchemeCodeUnspsc190501.10317925'),
	'identifierSchemeCodeUnspsc190501.10317926': __('identifierSchemeCodeUnspsc190501.10317926'),
	'identifierSchemeCodeUnspsc190501.10317927': __('identifierSchemeCodeUnspsc190501.10317927'),
	'identifierSchemeCodeUnspsc190501.10317928': __('identifierSchemeCodeUnspsc190501.10317928'),
	'identifierSchemeCodeUnspsc190501.10317929': __('identifierSchemeCodeUnspsc190501.10317929'),
	'identifierSchemeCodeUnspsc190501.10317930': __('identifierSchemeCodeUnspsc190501.10317930'),
	'identifierSchemeCodeUnspsc190501.10317931': __('identifierSchemeCodeUnspsc190501.10317931'),
	'identifierSchemeCodeUnspsc190501.10317932': __('identifierSchemeCodeUnspsc190501.10317932'),
	'identifierSchemeCodeUnspsc190501.10317933': __('identifierSchemeCodeUnspsc190501.10317933'),
	'identifierSchemeCodeUnspsc190501.10317934': __('identifierSchemeCodeUnspsc190501.10317934'),
	'identifierSchemeCodeUnspsc190501.10317935': __('identifierSchemeCodeUnspsc190501.10317935'),
	'identifierSchemeCodeUnspsc190501.10317936': __('identifierSchemeCodeUnspsc190501.10317936'),
	'identifierSchemeCodeUnspsc190501.10317937': __('identifierSchemeCodeUnspsc190501.10317937'),
	'identifierSchemeCodeUnspsc190501.10317938': __('identifierSchemeCodeUnspsc190501.10317938'),
	'identifierSchemeCodeUnspsc190501.10317939': __('identifierSchemeCodeUnspsc190501.10317939'),
	'identifierSchemeCodeUnspsc190501.10317940': __('identifierSchemeCodeUnspsc190501.10317940'),
	'identifierSchemeCodeUnspsc190501.10317941': __('identifierSchemeCodeUnspsc190501.10317941'),
	'identifierSchemeCodeUnspsc190501.10317942': __('identifierSchemeCodeUnspsc190501.10317942'),
	'identifierSchemeCodeUnspsc190501.10317943': __('identifierSchemeCodeUnspsc190501.10317943'),
	'identifierSchemeCodeUnspsc190501.10317944': __('identifierSchemeCodeUnspsc190501.10317944'),
	'identifierSchemeCodeUnspsc190501.10317945': __('identifierSchemeCodeUnspsc190501.10317945'),
	'identifierSchemeCodeUnspsc190501.10317946': __('identifierSchemeCodeUnspsc190501.10317946'),
	'identifierSchemeCodeUnspsc190501.10317947': __('identifierSchemeCodeUnspsc190501.10317947'),
	'identifierSchemeCodeUnspsc190501.10317948': __('identifierSchemeCodeUnspsc190501.10317948'),
	'identifierSchemeCodeUnspsc190501.10317949': __('identifierSchemeCodeUnspsc190501.10317949'),
	'identifierSchemeCodeUnspsc190501.10317950': __('identifierSchemeCodeUnspsc190501.10317950'),
	'identifierSchemeCodeUnspsc190501.10317951': __('identifierSchemeCodeUnspsc190501.10317951'),
	'identifierSchemeCodeUnspsc190501.10317952': __('identifierSchemeCodeUnspsc190501.10317952'),
	'identifierSchemeCodeUnspsc190501.10317953': __('identifierSchemeCodeUnspsc190501.10317953'),
	'identifierSchemeCodeUnspsc190501.10317954': __('identifierSchemeCodeUnspsc190501.10317954'),
	'identifierSchemeCodeUnspsc190501.10317955': __('identifierSchemeCodeUnspsc190501.10317955'),
	'identifierSchemeCodeUnspsc190501.10317956': __('identifierSchemeCodeUnspsc190501.10317956'),
	'identifierSchemeCodeUnspsc190501.10317957': __('identifierSchemeCodeUnspsc190501.10317957'),
	'identifierSchemeCodeUnspsc190501.10317958': __('identifierSchemeCodeUnspsc190501.10317958'),
	'identifierSchemeCodeUnspsc190501.10317959': __('identifierSchemeCodeUnspsc190501.10317959'),
	'identifierSchemeCodeUnspsc190501.10317960': __('identifierSchemeCodeUnspsc190501.10317960'),
	'identifierSchemeCodeUnspsc190501.10317961': __('identifierSchemeCodeUnspsc190501.10317961'),
	'identifierSchemeCodeUnspsc190501.10317962': __('identifierSchemeCodeUnspsc190501.10317962'),
	'identifierSchemeCodeUnspsc190501.10317963': __('identifierSchemeCodeUnspsc190501.10317963'),
	'identifierSchemeCodeUnspsc190501.10317964': __('identifierSchemeCodeUnspsc190501.10317964'),
	'identifierSchemeCodeUnspsc190501.10317965': __('identifierSchemeCodeUnspsc190501.10317965'),
	'identifierSchemeCodeUnspsc190501.10317966': __('identifierSchemeCodeUnspsc190501.10317966'),
	'identifierSchemeCodeUnspsc190501.10317967': __('identifierSchemeCodeUnspsc190501.10317967'),
	'identifierSchemeCodeUnspsc190501.10317968': __('identifierSchemeCodeUnspsc190501.10317968'),
	'identifierSchemeCodeUnspsc190501.10317969': __('identifierSchemeCodeUnspsc190501.10317969'),
	'identifierSchemeCodeUnspsc190501.10317970': __('identifierSchemeCodeUnspsc190501.10317970'),
	'identifierSchemeCodeUnspsc190501.10317971': __('identifierSchemeCodeUnspsc190501.10317971'),
	'identifierSchemeCodeUnspsc190501.10317972': __('identifierSchemeCodeUnspsc190501.10317972'),
	'identifierSchemeCodeUnspsc190501.10318000': __('identifierSchemeCodeUnspsc190501.10318000'),
	'identifierSchemeCodeUnspsc190501.10318001': __('identifierSchemeCodeUnspsc190501.10318001'),
	'identifierSchemeCodeUnspsc190501.10318002': __('identifierSchemeCodeUnspsc190501.10318002'),
	'identifierSchemeCodeUnspsc190501.10318003': __('identifierSchemeCodeUnspsc190501.10318003'),
	'identifierSchemeCodeUnspsc190501.10318004': __('identifierSchemeCodeUnspsc190501.10318004'),
	'identifierSchemeCodeUnspsc190501.10318005': __('identifierSchemeCodeUnspsc190501.10318005'),
	'identifierSchemeCodeUnspsc190501.10318006': __('identifierSchemeCodeUnspsc190501.10318006'),
	'identifierSchemeCodeUnspsc190501.10318007': __('identifierSchemeCodeUnspsc190501.10318007'),
	'identifierSchemeCodeUnspsc190501.10318008': __('identifierSchemeCodeUnspsc190501.10318008'),
	'identifierSchemeCodeUnspsc190501.10318009': __('identifierSchemeCodeUnspsc190501.10318009'),
	'identifierSchemeCodeUnspsc190501.10318010': __('identifierSchemeCodeUnspsc190501.10318010'),
	'identifierSchemeCodeUnspsc190501.10318011': __('identifierSchemeCodeUnspsc190501.10318011'),
	'identifierSchemeCodeUnspsc190501.10318012': __('identifierSchemeCodeUnspsc190501.10318012'),
	'identifierSchemeCodeUnspsc190501.10318013': __('identifierSchemeCodeUnspsc190501.10318013'),
	'identifierSchemeCodeUnspsc190501.10318014': __('identifierSchemeCodeUnspsc190501.10318014'),
	'identifierSchemeCodeUnspsc190501.10318015': __('identifierSchemeCodeUnspsc190501.10318015'),
	'identifierSchemeCodeUnspsc190501.10318016': __('identifierSchemeCodeUnspsc190501.10318016'),
	'identifierSchemeCodeUnspsc190501.10318017': __('identifierSchemeCodeUnspsc190501.10318017'),
	'identifierSchemeCodeUnspsc190501.10318018': __('identifierSchemeCodeUnspsc190501.10318018'),
	'identifierSchemeCodeUnspsc190501.10318019': __('identifierSchemeCodeUnspsc190501.10318019'),
	'identifierSchemeCodeUnspsc190501.10318020': __('identifierSchemeCodeUnspsc190501.10318020'),
	'identifierSchemeCodeUnspsc190501.10318021': __('identifierSchemeCodeUnspsc190501.10318021'),
	'identifierSchemeCodeUnspsc190501.10318022': __('identifierSchemeCodeUnspsc190501.10318022'),
	'identifierSchemeCodeUnspsc190501.10318023': __('identifierSchemeCodeUnspsc190501.10318023'),
	'identifierSchemeCodeUnspsc190501.10318024': __('identifierSchemeCodeUnspsc190501.10318024'),
	'identifierSchemeCodeUnspsc190501.10318025': __('identifierSchemeCodeUnspsc190501.10318025'),
	'identifierSchemeCodeUnspsc190501.10318026': __('identifierSchemeCodeUnspsc190501.10318026'),
	'identifierSchemeCodeUnspsc190501.10318027': __('identifierSchemeCodeUnspsc190501.10318027'),
	'identifierSchemeCodeUnspsc190501.10318100': __('identifierSchemeCodeUnspsc190501.10318100'),
	'identifierSchemeCodeUnspsc190501.10318101': __('identifierSchemeCodeUnspsc190501.10318101'),
	'identifierSchemeCodeUnspsc190501.10318102': __('identifierSchemeCodeUnspsc190501.10318102'),
	'identifierSchemeCodeUnspsc190501.10318103': __('identifierSchemeCodeUnspsc190501.10318103'),
	'identifierSchemeCodeUnspsc190501.10318104': __('identifierSchemeCodeUnspsc190501.10318104'),
	'identifierSchemeCodeUnspsc190501.10318105': __('identifierSchemeCodeUnspsc190501.10318105'),
	'identifierSchemeCodeUnspsc190501.10318106': __('identifierSchemeCodeUnspsc190501.10318106'),
	'identifierSchemeCodeUnspsc190501.10318107': __('identifierSchemeCodeUnspsc190501.10318107'),
	'identifierSchemeCodeUnspsc190501.10318108': __('identifierSchemeCodeUnspsc190501.10318108'),
	'identifierSchemeCodeUnspsc190501.10318109': __('identifierSchemeCodeUnspsc190501.10318109'),
	'identifierSchemeCodeUnspsc190501.10318110': __('identifierSchemeCodeUnspsc190501.10318110'),
	'identifierSchemeCodeUnspsc190501.10318111': __('identifierSchemeCodeUnspsc190501.10318111'),
	'identifierSchemeCodeUnspsc190501.10318112': __('identifierSchemeCodeUnspsc190501.10318112'),
	'identifierSchemeCodeUnspsc190501.10318113': __('identifierSchemeCodeUnspsc190501.10318113'),
	'identifierSchemeCodeUnspsc190501.10318114': __('identifierSchemeCodeUnspsc190501.10318114'),
	'identifierSchemeCodeUnspsc190501.10318115': __('identifierSchemeCodeUnspsc190501.10318115'),
	'identifierSchemeCodeUnspsc190501.10318116': __('identifierSchemeCodeUnspsc190501.10318116'),
	'identifierSchemeCodeUnspsc190501.10318117': __('identifierSchemeCodeUnspsc190501.10318117'),
	'identifierSchemeCodeUnspsc190501.10318118': __('identifierSchemeCodeUnspsc190501.10318118'),
	'identifierSchemeCodeUnspsc190501.10318119': __('identifierSchemeCodeUnspsc190501.10318119'),
	'identifierSchemeCodeUnspsc190501.10318120': __('identifierSchemeCodeUnspsc190501.10318120'),
	'identifierSchemeCodeUnspsc190501.10318121': __('identifierSchemeCodeUnspsc190501.10318121'),
	'identifierSchemeCodeUnspsc190501.10318122': __('identifierSchemeCodeUnspsc190501.10318122'),
	'identifierSchemeCodeUnspsc190501.10318200': __('identifierSchemeCodeUnspsc190501.10318200'),
	'identifierSchemeCodeUnspsc190501.10318201': __('identifierSchemeCodeUnspsc190501.10318201'),
	'identifierSchemeCodeUnspsc190501.10318202': __('identifierSchemeCodeUnspsc190501.10318202'),
	'identifierSchemeCodeUnspsc190501.10318203': __('identifierSchemeCodeUnspsc190501.10318203'),
	'identifierSchemeCodeUnspsc190501.10318204': __('identifierSchemeCodeUnspsc190501.10318204'),
	'identifierSchemeCodeUnspsc190501.10318205': __('identifierSchemeCodeUnspsc190501.10318205'),
	'identifierSchemeCodeUnspsc190501.10318206': __('identifierSchemeCodeUnspsc190501.10318206'),
	'identifierSchemeCodeUnspsc190501.10318207': __('identifierSchemeCodeUnspsc190501.10318207'),
	'identifierSchemeCodeUnspsc190501.10318208': __('identifierSchemeCodeUnspsc190501.10318208'),
	'identifierSchemeCodeUnspsc190501.10318209': __('identifierSchemeCodeUnspsc190501.10318209'),
	'identifierSchemeCodeUnspsc190501.10318210': __('identifierSchemeCodeUnspsc190501.10318210'),
	'identifierSchemeCodeUnspsc190501.10318211': __('identifierSchemeCodeUnspsc190501.10318211'),
	'identifierSchemeCodeUnspsc190501.10318212': __('identifierSchemeCodeUnspsc190501.10318212'),
	'identifierSchemeCodeUnspsc190501.10318213': __('identifierSchemeCodeUnspsc190501.10318213'),
	'identifierSchemeCodeUnspsc190501.10318214': __('identifierSchemeCodeUnspsc190501.10318214'),
	'identifierSchemeCodeUnspsc190501.10318215': __('identifierSchemeCodeUnspsc190501.10318215'),
	'identifierSchemeCodeUnspsc190501.10318216': __('identifierSchemeCodeUnspsc190501.10318216'),
	'identifierSchemeCodeUnspsc190501.10318217': __('identifierSchemeCodeUnspsc190501.10318217'),
	'identifierSchemeCodeUnspsc190501.10318218': __('identifierSchemeCodeUnspsc190501.10318218'),
	'identifierSchemeCodeUnspsc190501.10318219': __('identifierSchemeCodeUnspsc190501.10318219'),
	'identifierSchemeCodeUnspsc190501.10318220': __('identifierSchemeCodeUnspsc190501.10318220'),
	'identifierSchemeCodeUnspsc190501.10318300': __('identifierSchemeCodeUnspsc190501.10318300'),
	'identifierSchemeCodeUnspsc190501.10318301': __('identifierSchemeCodeUnspsc190501.10318301'),
	'identifierSchemeCodeUnspsc190501.10318302': __('identifierSchemeCodeUnspsc190501.10318302'),
	'identifierSchemeCodeUnspsc190501.10318303': __('identifierSchemeCodeUnspsc190501.10318303'),
	'identifierSchemeCodeUnspsc190501.10318304': __('identifierSchemeCodeUnspsc190501.10318304'),
	'identifierSchemeCodeUnspsc190501.10318305': __('identifierSchemeCodeUnspsc190501.10318305'),
	'identifierSchemeCodeUnspsc190501.10318306': __('identifierSchemeCodeUnspsc190501.10318306'),
	'identifierSchemeCodeUnspsc190501.10318307': __('identifierSchemeCodeUnspsc190501.10318307'),
	'identifierSchemeCodeUnspsc190501.10318308': __('identifierSchemeCodeUnspsc190501.10318308'),
	'identifierSchemeCodeUnspsc190501.10318309': __('identifierSchemeCodeUnspsc190501.10318309'),
	'identifierSchemeCodeUnspsc190501.10318310': __('identifierSchemeCodeUnspsc190501.10318310'),
	'identifierSchemeCodeUnspsc190501.10318311': __('identifierSchemeCodeUnspsc190501.10318311'),
	'identifierSchemeCodeUnspsc190501.10318312': __('identifierSchemeCodeUnspsc190501.10318312'),
	'identifierSchemeCodeUnspsc190501.10318313': __('identifierSchemeCodeUnspsc190501.10318313'),
	'identifierSchemeCodeUnspsc190501.10318314': __('identifierSchemeCodeUnspsc190501.10318314'),
	'identifierSchemeCodeUnspsc190501.10318315': __('identifierSchemeCodeUnspsc190501.10318315'),
	'identifierSchemeCodeUnspsc190501.10318316': __('identifierSchemeCodeUnspsc190501.10318316'),
	'identifierSchemeCodeUnspsc190501.10318317': __('identifierSchemeCodeUnspsc190501.10318317'),
	'identifierSchemeCodeUnspsc190501.10318318': __('identifierSchemeCodeUnspsc190501.10318318'),
	'identifierSchemeCodeUnspsc190501.10318319': __('identifierSchemeCodeUnspsc190501.10318319'),
	'identifierSchemeCodeUnspsc190501.10318320': __('identifierSchemeCodeUnspsc190501.10318320'),
	'identifierSchemeCodeUnspsc190501.10318321': __('identifierSchemeCodeUnspsc190501.10318321'),
	'identifierSchemeCodeUnspsc190501.10318322': __('identifierSchemeCodeUnspsc190501.10318322'),
	'identifierSchemeCodeUnspsc190501.10318323': __('identifierSchemeCodeUnspsc190501.10318323'),
	'identifierSchemeCodeUnspsc190501.10318324': __('identifierSchemeCodeUnspsc190501.10318324'),
	'identifierSchemeCodeUnspsc190501.10318325': __('identifierSchemeCodeUnspsc190501.10318325'),
	'identifierSchemeCodeUnspsc190501.10318326': __('identifierSchemeCodeUnspsc190501.10318326'),
	'identifierSchemeCodeUnspsc190501.10318327': __('identifierSchemeCodeUnspsc190501.10318327'),
	'identifierSchemeCodeUnspsc190501.10318328': __('identifierSchemeCodeUnspsc190501.10318328'),
	'identifierSchemeCodeUnspsc190501.10318329': __('identifierSchemeCodeUnspsc190501.10318329'),
	'identifierSchemeCodeUnspsc190501.10318330': __('identifierSchemeCodeUnspsc190501.10318330'),
	'identifierSchemeCodeUnspsc190501.10318331': __('identifierSchemeCodeUnspsc190501.10318331'),
	'identifierSchemeCodeUnspsc190501.10318332': __('identifierSchemeCodeUnspsc190501.10318332'),
	'identifierSchemeCodeUnspsc190501.10318333': __('identifierSchemeCodeUnspsc190501.10318333'),
	'identifierSchemeCodeUnspsc190501.10318334': __('identifierSchemeCodeUnspsc190501.10318334'),
	'identifierSchemeCodeUnspsc190501.10318335': __('identifierSchemeCodeUnspsc190501.10318335'),
	'identifierSchemeCodeUnspsc190501.10318336': __('identifierSchemeCodeUnspsc190501.10318336'),
	'identifierSchemeCodeUnspsc190501.10318337': __('identifierSchemeCodeUnspsc190501.10318337'),
	'identifierSchemeCodeUnspsc190501.10318338': __('identifierSchemeCodeUnspsc190501.10318338'),
	'identifierSchemeCodeUnspsc190501.10318339': __('identifierSchemeCodeUnspsc190501.10318339'),
	'identifierSchemeCodeUnspsc190501.10318340': __('identifierSchemeCodeUnspsc190501.10318340'),
	'identifierSchemeCodeUnspsc190501.10318341': __('identifierSchemeCodeUnspsc190501.10318341'),
	'identifierSchemeCodeUnspsc190501.10318342': __('identifierSchemeCodeUnspsc190501.10318342'),
	'identifierSchemeCodeUnspsc190501.10318343': __('identifierSchemeCodeUnspsc190501.10318343'),
	'identifierSchemeCodeUnspsc190501.10318344': __('identifierSchemeCodeUnspsc190501.10318344'),
	'identifierSchemeCodeUnspsc190501.10318345': __('identifierSchemeCodeUnspsc190501.10318345'),
	'identifierSchemeCodeUnspsc190501.10318346': __('identifierSchemeCodeUnspsc190501.10318346'),
	'identifierSchemeCodeUnspsc190501.10318347': __('identifierSchemeCodeUnspsc190501.10318347'),
	'identifierSchemeCodeUnspsc190501.10318348': __('identifierSchemeCodeUnspsc190501.10318348'),
	'identifierSchemeCodeUnspsc190501.10318349': __('identifierSchemeCodeUnspsc190501.10318349'),
	'identifierSchemeCodeUnspsc190501.10318350': __('identifierSchemeCodeUnspsc190501.10318350'),
	'identifierSchemeCodeUnspsc190501.10320000': __('identifierSchemeCodeUnspsc190501.10320000'),
	'identifierSchemeCodeUnspsc190501.10321500': __('identifierSchemeCodeUnspsc190501.10321500'),
	'identifierSchemeCodeUnspsc190501.10321501': __('identifierSchemeCodeUnspsc190501.10321501'),
	'identifierSchemeCodeUnspsc190501.10321502': __('identifierSchemeCodeUnspsc190501.10321502'),
	'identifierSchemeCodeUnspsc190501.10321600': __('identifierSchemeCodeUnspsc190501.10321600'),
	'identifierSchemeCodeUnspsc190501.10321601': __('identifierSchemeCodeUnspsc190501.10321601'),
	'identifierSchemeCodeUnspsc190501.10321602': __('identifierSchemeCodeUnspsc190501.10321602'),
	'identifierSchemeCodeUnspsc190501.10321700': __('identifierSchemeCodeUnspsc190501.10321700'),
	'identifierSchemeCodeUnspsc190501.10321701': __('identifierSchemeCodeUnspsc190501.10321701'),
	'identifierSchemeCodeUnspsc190501.10321702': __('identifierSchemeCodeUnspsc190501.10321702'),
	'identifierSchemeCodeUnspsc190501.10321703': __('identifierSchemeCodeUnspsc190501.10321703'),
	'identifierSchemeCodeUnspsc190501.10321704': __('identifierSchemeCodeUnspsc190501.10321704'),
	'identifierSchemeCodeUnspsc190501.10321800': __('identifierSchemeCodeUnspsc190501.10321800'),
	'identifierSchemeCodeUnspsc190501.10321801': __('identifierSchemeCodeUnspsc190501.10321801'),
	'identifierSchemeCodeUnspsc190501.10321802': __('identifierSchemeCodeUnspsc190501.10321802'),
	'identifierSchemeCodeUnspsc190501.10321900': __('identifierSchemeCodeUnspsc190501.10321900'),
	'identifierSchemeCodeUnspsc190501.10321901': __('identifierSchemeCodeUnspsc190501.10321901'),
	'identifierSchemeCodeUnspsc190501.10321902': __('identifierSchemeCodeUnspsc190501.10321902'),
	'identifierSchemeCodeUnspsc190501.10322000': __('identifierSchemeCodeUnspsc190501.10322000'),
	'identifierSchemeCodeUnspsc190501.10322001': __('identifierSchemeCodeUnspsc190501.10322001'),
	'identifierSchemeCodeUnspsc190501.10322002': __('identifierSchemeCodeUnspsc190501.10322002'),
	'identifierSchemeCodeUnspsc190501.10322003': __('identifierSchemeCodeUnspsc190501.10322003'),
	'identifierSchemeCodeUnspsc190501.10322100': __('identifierSchemeCodeUnspsc190501.10322100'),
	'identifierSchemeCodeUnspsc190501.10322101': __('identifierSchemeCodeUnspsc190501.10322101'),
	'identifierSchemeCodeUnspsc190501.10322102': __('identifierSchemeCodeUnspsc190501.10322102'),
	'identifierSchemeCodeUnspsc190501.10322200': __('identifierSchemeCodeUnspsc190501.10322200'),
	'identifierSchemeCodeUnspsc190501.10322201': __('identifierSchemeCodeUnspsc190501.10322201'),
	'identifierSchemeCodeUnspsc190501.10322202': __('identifierSchemeCodeUnspsc190501.10322202'),
	'identifierSchemeCodeUnspsc190501.10322203': __('identifierSchemeCodeUnspsc190501.10322203'),
	'identifierSchemeCodeUnspsc190501.10322300': __('identifierSchemeCodeUnspsc190501.10322300'),
	'identifierSchemeCodeUnspsc190501.10322301': __('identifierSchemeCodeUnspsc190501.10322301'),
	'identifierSchemeCodeUnspsc190501.10322302': __('identifierSchemeCodeUnspsc190501.10322302'),
	'identifierSchemeCodeUnspsc190501.10322400': __('identifierSchemeCodeUnspsc190501.10322400'),
	'identifierSchemeCodeUnspsc190501.10322401': __('identifierSchemeCodeUnspsc190501.10322401'),
	'identifierSchemeCodeUnspsc190501.10322402': __('identifierSchemeCodeUnspsc190501.10322402'),
	'identifierSchemeCodeUnspsc190501.10322500': __('identifierSchemeCodeUnspsc190501.10322500'),
	'identifierSchemeCodeUnspsc190501.10322501': __('identifierSchemeCodeUnspsc190501.10322501'),
	'identifierSchemeCodeUnspsc190501.10322502': __('identifierSchemeCodeUnspsc190501.10322502'),
	'identifierSchemeCodeUnspsc190501.10322600': __('identifierSchemeCodeUnspsc190501.10322600'),
	'identifierSchemeCodeUnspsc190501.10322601': __('identifierSchemeCodeUnspsc190501.10322601'),
	'identifierSchemeCodeUnspsc190501.10322602': __('identifierSchemeCodeUnspsc190501.10322602'),
	'identifierSchemeCodeUnspsc190501.10322603': __('identifierSchemeCodeUnspsc190501.10322603'),
	'identifierSchemeCodeUnspsc190501.10322700': __('identifierSchemeCodeUnspsc190501.10322700'),
	'identifierSchemeCodeUnspsc190501.10322701': __('identifierSchemeCodeUnspsc190501.10322701'),
	'identifierSchemeCodeUnspsc190501.10322702': __('identifierSchemeCodeUnspsc190501.10322702'),
	'identifierSchemeCodeUnspsc190501.10322703': __('identifierSchemeCodeUnspsc190501.10322703'),
	'identifierSchemeCodeUnspsc190501.10322800': __('identifierSchemeCodeUnspsc190501.10322800'),
	'identifierSchemeCodeUnspsc190501.10322801': __('identifierSchemeCodeUnspsc190501.10322801'),
	'identifierSchemeCodeUnspsc190501.10322802': __('identifierSchemeCodeUnspsc190501.10322802'),
	'identifierSchemeCodeUnspsc190501.10322803': __('identifierSchemeCodeUnspsc190501.10322803'),
	'identifierSchemeCodeUnspsc190501.10322900': __('identifierSchemeCodeUnspsc190501.10322900'),
	'identifierSchemeCodeUnspsc190501.10322901': __('identifierSchemeCodeUnspsc190501.10322901'),
	'identifierSchemeCodeUnspsc190501.10322902': __('identifierSchemeCodeUnspsc190501.10322902'),
	'identifierSchemeCodeUnspsc190501.10323000': __('identifierSchemeCodeUnspsc190501.10323000'),
	'identifierSchemeCodeUnspsc190501.10323001': __('identifierSchemeCodeUnspsc190501.10323001'),
	'identifierSchemeCodeUnspsc190501.10323002': __('identifierSchemeCodeUnspsc190501.10323002'),
	'identifierSchemeCodeUnspsc190501.10323003': __('identifierSchemeCodeUnspsc190501.10323003'),
	'identifierSchemeCodeUnspsc190501.10323100': __('identifierSchemeCodeUnspsc190501.10323100'),
	'identifierSchemeCodeUnspsc190501.10323101': __('identifierSchemeCodeUnspsc190501.10323101'),
	'identifierSchemeCodeUnspsc190501.10323102': __('identifierSchemeCodeUnspsc190501.10323102'),
	'identifierSchemeCodeUnspsc190501.10323103': __('identifierSchemeCodeUnspsc190501.10323103'),
	'identifierSchemeCodeUnspsc190501.10323200': __('identifierSchemeCodeUnspsc190501.10323200'),
	'identifierSchemeCodeUnspsc190501.10323201': __('identifierSchemeCodeUnspsc190501.10323201'),
	'identifierSchemeCodeUnspsc190501.10323202': __('identifierSchemeCodeUnspsc190501.10323202'),
	'identifierSchemeCodeUnspsc190501.10323300': __('identifierSchemeCodeUnspsc190501.10323300'),
	'identifierSchemeCodeUnspsc190501.10323301': __('identifierSchemeCodeUnspsc190501.10323301'),
	'identifierSchemeCodeUnspsc190501.10323302': __('identifierSchemeCodeUnspsc190501.10323302'),
	'identifierSchemeCodeUnspsc190501.10323400': __('identifierSchemeCodeUnspsc190501.10323400'),
	'identifierSchemeCodeUnspsc190501.10323401': __('identifierSchemeCodeUnspsc190501.10323401'),
	'identifierSchemeCodeUnspsc190501.10323402': __('identifierSchemeCodeUnspsc190501.10323402'),
	'identifierSchemeCodeUnspsc190501.10323403': __('identifierSchemeCodeUnspsc190501.10323403'),
	'identifierSchemeCodeUnspsc190501.10323500': __('identifierSchemeCodeUnspsc190501.10323500'),
	'identifierSchemeCodeUnspsc190501.10323501': __('identifierSchemeCodeUnspsc190501.10323501'),
	'identifierSchemeCodeUnspsc190501.10323502': __('identifierSchemeCodeUnspsc190501.10323502'),
	'identifierSchemeCodeUnspsc190501.10323503': __('identifierSchemeCodeUnspsc190501.10323503'),
	'identifierSchemeCodeUnspsc190501.10323504': __('identifierSchemeCodeUnspsc190501.10323504'),
	'identifierSchemeCodeUnspsc190501.10323600': __('identifierSchemeCodeUnspsc190501.10323600'),
	'identifierSchemeCodeUnspsc190501.10323601': __('identifierSchemeCodeUnspsc190501.10323601'),
	'identifierSchemeCodeUnspsc190501.10323602': __('identifierSchemeCodeUnspsc190501.10323602'),
	'identifierSchemeCodeUnspsc190501.10323700': __('identifierSchemeCodeUnspsc190501.10323700'),
	'identifierSchemeCodeUnspsc190501.10323701': __('identifierSchemeCodeUnspsc190501.10323701'),
	'identifierSchemeCodeUnspsc190501.10323702': __('identifierSchemeCodeUnspsc190501.10323702'),
	'identifierSchemeCodeUnspsc190501.10323800': __('identifierSchemeCodeUnspsc190501.10323800'),
	'identifierSchemeCodeUnspsc190501.10323801': __('identifierSchemeCodeUnspsc190501.10323801'),
	'identifierSchemeCodeUnspsc190501.10323802': __('identifierSchemeCodeUnspsc190501.10323802'),
	'identifierSchemeCodeUnspsc190501.10323803': __('identifierSchemeCodeUnspsc190501.10323803'),
	'identifierSchemeCodeUnspsc190501.10323804': __('identifierSchemeCodeUnspsc190501.10323804'),
	'identifierSchemeCodeUnspsc190501.10323900': __('identifierSchemeCodeUnspsc190501.10323900'),
	'identifierSchemeCodeUnspsc190501.10323901': __('identifierSchemeCodeUnspsc190501.10323901'),
	'identifierSchemeCodeUnspsc190501.10323902': __('identifierSchemeCodeUnspsc190501.10323902'),
	'identifierSchemeCodeUnspsc190501.10324000': __('identifierSchemeCodeUnspsc190501.10324000'),
	'identifierSchemeCodeUnspsc190501.10324001': __('identifierSchemeCodeUnspsc190501.10324001'),
	'identifierSchemeCodeUnspsc190501.10324002': __('identifierSchemeCodeUnspsc190501.10324002'),
	'identifierSchemeCodeUnspsc190501.10324100': __('identifierSchemeCodeUnspsc190501.10324100'),
	'identifierSchemeCodeUnspsc190501.10324101': __('identifierSchemeCodeUnspsc190501.10324101'),
	'identifierSchemeCodeUnspsc190501.10324102': __('identifierSchemeCodeUnspsc190501.10324102'),
	'identifierSchemeCodeUnspsc190501.10324200': __('identifierSchemeCodeUnspsc190501.10324200'),
	'identifierSchemeCodeUnspsc190501.10324201': __('identifierSchemeCodeUnspsc190501.10324201'),
	'identifierSchemeCodeUnspsc190501.10324202': __('identifierSchemeCodeUnspsc190501.10324202'),
	'identifierSchemeCodeUnspsc190501.10324300': __('identifierSchemeCodeUnspsc190501.10324300'),
	'identifierSchemeCodeUnspsc190501.10324301': __('identifierSchemeCodeUnspsc190501.10324301'),
	'identifierSchemeCodeUnspsc190501.10324302': __('identifierSchemeCodeUnspsc190501.10324302'),
	'identifierSchemeCodeUnspsc190501.10324303': __('identifierSchemeCodeUnspsc190501.10324303'),
	'identifierSchemeCodeUnspsc190501.10324400': __('identifierSchemeCodeUnspsc190501.10324400'),
	'identifierSchemeCodeUnspsc190501.10324401': __('identifierSchemeCodeUnspsc190501.10324401'),
	'identifierSchemeCodeUnspsc190501.10324402': __('identifierSchemeCodeUnspsc190501.10324402'),
	'identifierSchemeCodeUnspsc190501.10324500': __('identifierSchemeCodeUnspsc190501.10324500'),
	'identifierSchemeCodeUnspsc190501.10324501': __('identifierSchemeCodeUnspsc190501.10324501'),
	'identifierSchemeCodeUnspsc190501.10324502': __('identifierSchemeCodeUnspsc190501.10324502'),
	'identifierSchemeCodeUnspsc190501.10324600': __('identifierSchemeCodeUnspsc190501.10324600'),
	'identifierSchemeCodeUnspsc190501.10324601': __('identifierSchemeCodeUnspsc190501.10324601'),
	'identifierSchemeCodeUnspsc190501.10324602': __('identifierSchemeCodeUnspsc190501.10324602'),
	'identifierSchemeCodeUnspsc190501.10324700': __('identifierSchemeCodeUnspsc190501.10324700'),
	'identifierSchemeCodeUnspsc190501.10324701': __('identifierSchemeCodeUnspsc190501.10324701'),
	'identifierSchemeCodeUnspsc190501.10324702': __('identifierSchemeCodeUnspsc190501.10324702'),
	'identifierSchemeCodeUnspsc190501.10324703': __('identifierSchemeCodeUnspsc190501.10324703'),
	'identifierSchemeCodeUnspsc190501.10324800': __('identifierSchemeCodeUnspsc190501.10324800'),
	'identifierSchemeCodeUnspsc190501.10324801': __('identifierSchemeCodeUnspsc190501.10324801'),
	'identifierSchemeCodeUnspsc190501.10324802': __('identifierSchemeCodeUnspsc190501.10324802'),
	'identifierSchemeCodeUnspsc190501.10324803': __('identifierSchemeCodeUnspsc190501.10324803'),
	'identifierSchemeCodeUnspsc190501.10324804': __('identifierSchemeCodeUnspsc190501.10324804'),
	'identifierSchemeCodeUnspsc190501.10324900': __('identifierSchemeCodeUnspsc190501.10324900'),
	'identifierSchemeCodeUnspsc190501.10324901': __('identifierSchemeCodeUnspsc190501.10324901'),
	'identifierSchemeCodeUnspsc190501.10324902': __('identifierSchemeCodeUnspsc190501.10324902'),
	'identifierSchemeCodeUnspsc190501.10325000': __('identifierSchemeCodeUnspsc190501.10325000'),
	'identifierSchemeCodeUnspsc190501.10325001': __('identifierSchemeCodeUnspsc190501.10325001'),
	'identifierSchemeCodeUnspsc190501.10325002': __('identifierSchemeCodeUnspsc190501.10325002'),
	'identifierSchemeCodeUnspsc190501.10325003': __('identifierSchemeCodeUnspsc190501.10325003'),
	'identifierSchemeCodeUnspsc190501.10325004': __('identifierSchemeCodeUnspsc190501.10325004'),
	'identifierSchemeCodeUnspsc190501.10325100': __('identifierSchemeCodeUnspsc190501.10325100'),
	'identifierSchemeCodeUnspsc190501.10325101': __('identifierSchemeCodeUnspsc190501.10325101'),
	'identifierSchemeCodeUnspsc190501.10325102': __('identifierSchemeCodeUnspsc190501.10325102'),
	'identifierSchemeCodeUnspsc190501.10325103': __('identifierSchemeCodeUnspsc190501.10325103'),
	'identifierSchemeCodeUnspsc190501.10325104': __('identifierSchemeCodeUnspsc190501.10325104'),
	'identifierSchemeCodeUnspsc190501.10325200': __('identifierSchemeCodeUnspsc190501.10325200'),
	'identifierSchemeCodeUnspsc190501.10325201': __('identifierSchemeCodeUnspsc190501.10325201'),
	'identifierSchemeCodeUnspsc190501.10325202': __('identifierSchemeCodeUnspsc190501.10325202'),
	'identifierSchemeCodeUnspsc190501.10325300': __('identifierSchemeCodeUnspsc190501.10325300'),
	'identifierSchemeCodeUnspsc190501.10325301': __('identifierSchemeCodeUnspsc190501.10325301'),
	'identifierSchemeCodeUnspsc190501.10325302': __('identifierSchemeCodeUnspsc190501.10325302'),
	'identifierSchemeCodeUnspsc190501.10325303': __('identifierSchemeCodeUnspsc190501.10325303'),
	'identifierSchemeCodeUnspsc190501.10325400': __('identifierSchemeCodeUnspsc190501.10325400'),
	'identifierSchemeCodeUnspsc190501.10325401': __('identifierSchemeCodeUnspsc190501.10325401'),
	'identifierSchemeCodeUnspsc190501.10325402': __('identifierSchemeCodeUnspsc190501.10325402'),
	'identifierSchemeCodeUnspsc190501.10325403': __('identifierSchemeCodeUnspsc190501.10325403'),
	'identifierSchemeCodeUnspsc190501.10325404': __('identifierSchemeCodeUnspsc190501.10325404'),
	'identifierSchemeCodeUnspsc190501.10325500': __('identifierSchemeCodeUnspsc190501.10325500'),
	'identifierSchemeCodeUnspsc190501.10325501': __('identifierSchemeCodeUnspsc190501.10325501'),
	'identifierSchemeCodeUnspsc190501.10325502': __('identifierSchemeCodeUnspsc190501.10325502'),
	'identifierSchemeCodeUnspsc190501.10325503': __('identifierSchemeCodeUnspsc190501.10325503'),
	'identifierSchemeCodeUnspsc190501.10325600': __('identifierSchemeCodeUnspsc190501.10325600'),
	'identifierSchemeCodeUnspsc190501.10325601': __('identifierSchemeCodeUnspsc190501.10325601'),
	'identifierSchemeCodeUnspsc190501.10325602': __('identifierSchemeCodeUnspsc190501.10325602'),
	'identifierSchemeCodeUnspsc190501.10325700': __('identifierSchemeCodeUnspsc190501.10325700'),
	'identifierSchemeCodeUnspsc190501.10325701': __('identifierSchemeCodeUnspsc190501.10325701'),
	'identifierSchemeCodeUnspsc190501.10325800': __('identifierSchemeCodeUnspsc190501.10325800'),
	'identifierSchemeCodeUnspsc190501.10325801': __('identifierSchemeCodeUnspsc190501.10325801'),
	'identifierSchemeCodeUnspsc190501.10325802': __('identifierSchemeCodeUnspsc190501.10325802'),
	'identifierSchemeCodeUnspsc190501.10325803': __('identifierSchemeCodeUnspsc190501.10325803'),
	'identifierSchemeCodeUnspsc190501.10325804': __('identifierSchemeCodeUnspsc190501.10325804'),
	'identifierSchemeCodeUnspsc190501.10325900': __('identifierSchemeCodeUnspsc190501.10325900'),
	'identifierSchemeCodeUnspsc190501.10325901': __('identifierSchemeCodeUnspsc190501.10325901'),
	'identifierSchemeCodeUnspsc190501.10325902': __('identifierSchemeCodeUnspsc190501.10325902'),
	'identifierSchemeCodeUnspsc190501.10325903': __('identifierSchemeCodeUnspsc190501.10325903'),
	'identifierSchemeCodeUnspsc190501.10325904': __('identifierSchemeCodeUnspsc190501.10325904'),
	'identifierSchemeCodeUnspsc190501.10326000': __('identifierSchemeCodeUnspsc190501.10326000'),
	'identifierSchemeCodeUnspsc190501.10326001': __('identifierSchemeCodeUnspsc190501.10326001'),
	'identifierSchemeCodeUnspsc190501.10326002': __('identifierSchemeCodeUnspsc190501.10326002'),
	'identifierSchemeCodeUnspsc190501.10326003': __('identifierSchemeCodeUnspsc190501.10326003'),
	'identifierSchemeCodeUnspsc190501.10326004': __('identifierSchemeCodeUnspsc190501.10326004'),
	'identifierSchemeCodeUnspsc190501.10326005': __('identifierSchemeCodeUnspsc190501.10326005'),
	'identifierSchemeCodeUnspsc190501.10326006': __('identifierSchemeCodeUnspsc190501.10326006'),
	'identifierSchemeCodeUnspsc190501.10326007': __('identifierSchemeCodeUnspsc190501.10326007'),
	'identifierSchemeCodeUnspsc190501.10326008': __('identifierSchemeCodeUnspsc190501.10326008'),
	'identifierSchemeCodeUnspsc190501.10326009': __('identifierSchemeCodeUnspsc190501.10326009'),
	'identifierSchemeCodeUnspsc190501.10326010': __('identifierSchemeCodeUnspsc190501.10326010'),
	'identifierSchemeCodeUnspsc190501.10326011': __('identifierSchemeCodeUnspsc190501.10326011'),
	'identifierSchemeCodeUnspsc190501.10326012': __('identifierSchemeCodeUnspsc190501.10326012'),
	'identifierSchemeCodeUnspsc190501.10326013': __('identifierSchemeCodeUnspsc190501.10326013'),
	'identifierSchemeCodeUnspsc190501.10326014': __('identifierSchemeCodeUnspsc190501.10326014'),
	'identifierSchemeCodeUnspsc190501.10326015': __('identifierSchemeCodeUnspsc190501.10326015'),
	'identifierSchemeCodeUnspsc190501.10326016': __('identifierSchemeCodeUnspsc190501.10326016'),
	'identifierSchemeCodeUnspsc190501.10326017': __('identifierSchemeCodeUnspsc190501.10326017'),
	'identifierSchemeCodeUnspsc190501.10326018': __('identifierSchemeCodeUnspsc190501.10326018'),
	'identifierSchemeCodeUnspsc190501.10326019': __('identifierSchemeCodeUnspsc190501.10326019'),
	'identifierSchemeCodeUnspsc190501.10326020': __('identifierSchemeCodeUnspsc190501.10326020'),
	'identifierSchemeCodeUnspsc190501.10326021': __('identifierSchemeCodeUnspsc190501.10326021'),
	'identifierSchemeCodeUnspsc190501.10326022': __('identifierSchemeCodeUnspsc190501.10326022'),
	'identifierSchemeCodeUnspsc190501.10326023': __('identifierSchemeCodeUnspsc190501.10326023'),
	'identifierSchemeCodeUnspsc190501.10326024': __('identifierSchemeCodeUnspsc190501.10326024'),
	'identifierSchemeCodeUnspsc190501.10326025': __('identifierSchemeCodeUnspsc190501.10326025'),
	'identifierSchemeCodeUnspsc190501.10326026': __('identifierSchemeCodeUnspsc190501.10326026'),
	'identifierSchemeCodeUnspsc190501.10326027': __('identifierSchemeCodeUnspsc190501.10326027'),
	'identifierSchemeCodeUnspsc190501.10326028': __('identifierSchemeCodeUnspsc190501.10326028'),
	'identifierSchemeCodeUnspsc190501.10326029': __('identifierSchemeCodeUnspsc190501.10326029'),
	'identifierSchemeCodeUnspsc190501.10326030': __('identifierSchemeCodeUnspsc190501.10326030'),
	'identifierSchemeCodeUnspsc190501.10326031': __('identifierSchemeCodeUnspsc190501.10326031'),
	'identifierSchemeCodeUnspsc190501.10326032': __('identifierSchemeCodeUnspsc190501.10326032'),
	'identifierSchemeCodeUnspsc190501.10326033': __('identifierSchemeCodeUnspsc190501.10326033'),
	'identifierSchemeCodeUnspsc190501.10326034': __('identifierSchemeCodeUnspsc190501.10326034'),
	'identifierSchemeCodeUnspsc190501.10326035': __('identifierSchemeCodeUnspsc190501.10326035'),
	'identifierSchemeCodeUnspsc190501.10326036': __('identifierSchemeCodeUnspsc190501.10326036'),
	'identifierSchemeCodeUnspsc190501.10326037': __('identifierSchemeCodeUnspsc190501.10326037'),
	'identifierSchemeCodeUnspsc190501.10326038': __('identifierSchemeCodeUnspsc190501.10326038'),
	'identifierSchemeCodeUnspsc190501.10326039': __('identifierSchemeCodeUnspsc190501.10326039'),
	'identifierSchemeCodeUnspsc190501.10326040': __('identifierSchemeCodeUnspsc190501.10326040'),
	'identifierSchemeCodeUnspsc190501.10326041': __('identifierSchemeCodeUnspsc190501.10326041'),
	'identifierSchemeCodeUnspsc190501.10326042': __('identifierSchemeCodeUnspsc190501.10326042'),
	'identifierSchemeCodeUnspsc190501.10326043': __('identifierSchemeCodeUnspsc190501.10326043'),
	'identifierSchemeCodeUnspsc190501.10326044': __('identifierSchemeCodeUnspsc190501.10326044'),
	'identifierSchemeCodeUnspsc190501.10326045': __('identifierSchemeCodeUnspsc190501.10326045'),
	'identifierSchemeCodeUnspsc190501.10326046': __('identifierSchemeCodeUnspsc190501.10326046'),
	'identifierSchemeCodeUnspsc190501.10326047': __('identifierSchemeCodeUnspsc190501.10326047'),
	'identifierSchemeCodeUnspsc190501.10326048': __('identifierSchemeCodeUnspsc190501.10326048'),
	'identifierSchemeCodeUnspsc190501.10326049': __('identifierSchemeCodeUnspsc190501.10326049'),
	'identifierSchemeCodeUnspsc190501.10326050': __('identifierSchemeCodeUnspsc190501.10326050'),
	'identifierSchemeCodeUnspsc190501.10326051': __('identifierSchemeCodeUnspsc190501.10326051'),
	'identifierSchemeCodeUnspsc190501.10326052': __('identifierSchemeCodeUnspsc190501.10326052'),
	'identifierSchemeCodeUnspsc190501.10326053': __('identifierSchemeCodeUnspsc190501.10326053'),
	'identifierSchemeCodeUnspsc190501.10326054': __('identifierSchemeCodeUnspsc190501.10326054'),
	'identifierSchemeCodeUnspsc190501.10326055': __('identifierSchemeCodeUnspsc190501.10326055'),
	'identifierSchemeCodeUnspsc190501.10326056': __('identifierSchemeCodeUnspsc190501.10326056'),
	'identifierSchemeCodeUnspsc190501.10326057': __('identifierSchemeCodeUnspsc190501.10326057'),
	'identifierSchemeCodeUnspsc190501.10326058': __('identifierSchemeCodeUnspsc190501.10326058'),
	'identifierSchemeCodeUnspsc190501.10326059': __('identifierSchemeCodeUnspsc190501.10326059'),
	'identifierSchemeCodeUnspsc190501.10326060': __('identifierSchemeCodeUnspsc190501.10326060'),
	'identifierSchemeCodeUnspsc190501.10326061': __('identifierSchemeCodeUnspsc190501.10326061'),
	'identifierSchemeCodeUnspsc190501.10326062': __('identifierSchemeCodeUnspsc190501.10326062'),
	'identifierSchemeCodeUnspsc190501.10326063': __('identifierSchemeCodeUnspsc190501.10326063'),
	'identifierSchemeCodeUnspsc190501.10326064': __('identifierSchemeCodeUnspsc190501.10326064'),
	'identifierSchemeCodeUnspsc190501.10326065': __('identifierSchemeCodeUnspsc190501.10326065'),
	'identifierSchemeCodeUnspsc190501.10326066': __('identifierSchemeCodeUnspsc190501.10326066'),
	'identifierSchemeCodeUnspsc190501.10326067': __('identifierSchemeCodeUnspsc190501.10326067'),
	'identifierSchemeCodeUnspsc190501.10326068': __('identifierSchemeCodeUnspsc190501.10326068'),
	'identifierSchemeCodeUnspsc190501.10326069': __('identifierSchemeCodeUnspsc190501.10326069'),
	'identifierSchemeCodeUnspsc190501.10326070': __('identifierSchemeCodeUnspsc190501.10326070'),
	'identifierSchemeCodeUnspsc190501.10326071': __('identifierSchemeCodeUnspsc190501.10326071'),
	'identifierSchemeCodeUnspsc190501.10326072': __('identifierSchemeCodeUnspsc190501.10326072'),
	'identifierSchemeCodeUnspsc190501.10326073': __('identifierSchemeCodeUnspsc190501.10326073'),
	'identifierSchemeCodeUnspsc190501.10326074': __('identifierSchemeCodeUnspsc190501.10326074'),
	'identifierSchemeCodeUnspsc190501.10326075': __('identifierSchemeCodeUnspsc190501.10326075'),
	'identifierSchemeCodeUnspsc190501.10326076': __('identifierSchemeCodeUnspsc190501.10326076'),
	'identifierSchemeCodeUnspsc190501.10326077': __('identifierSchemeCodeUnspsc190501.10326077'),
	'identifierSchemeCodeUnspsc190501.10326078': __('identifierSchemeCodeUnspsc190501.10326078'),
	'identifierSchemeCodeUnspsc190501.10326079': __('identifierSchemeCodeUnspsc190501.10326079'),
	'identifierSchemeCodeUnspsc190501.10326080': __('identifierSchemeCodeUnspsc190501.10326080'),
	'identifierSchemeCodeUnspsc190501.10326081': __('identifierSchemeCodeUnspsc190501.10326081'),
	'identifierSchemeCodeUnspsc190501.10326082': __('identifierSchemeCodeUnspsc190501.10326082'),
	'identifierSchemeCodeUnspsc190501.10326084': __('identifierSchemeCodeUnspsc190501.10326084'),
	'identifierSchemeCodeUnspsc190501.10326085': __('identifierSchemeCodeUnspsc190501.10326085'),
	'identifierSchemeCodeUnspsc190501.10326086': __('identifierSchemeCodeUnspsc190501.10326086'),
	'identifierSchemeCodeUnspsc190501.10326100': __('identifierSchemeCodeUnspsc190501.10326100'),
	'identifierSchemeCodeUnspsc190501.10326101': __('identifierSchemeCodeUnspsc190501.10326101'),
	'identifierSchemeCodeUnspsc190501.10326102': __('identifierSchemeCodeUnspsc190501.10326102'),
	'identifierSchemeCodeUnspsc190501.10326200': __('identifierSchemeCodeUnspsc190501.10326200'),
	'identifierSchemeCodeUnspsc190501.10326201': __('identifierSchemeCodeUnspsc190501.10326201'),
	'identifierSchemeCodeUnspsc190501.10326202': __('identifierSchemeCodeUnspsc190501.10326202'),
	'identifierSchemeCodeUnspsc190501.10326203': __('identifierSchemeCodeUnspsc190501.10326203'),
	'identifierSchemeCodeUnspsc190501.10326204': __('identifierSchemeCodeUnspsc190501.10326204'),
	'identifierSchemeCodeUnspsc190501.10326300': __('identifierSchemeCodeUnspsc190501.10326300'),
	'identifierSchemeCodeUnspsc190501.10326301': __('identifierSchemeCodeUnspsc190501.10326301'),
	'identifierSchemeCodeUnspsc190501.10326302': __('identifierSchemeCodeUnspsc190501.10326302'),
	'identifierSchemeCodeUnspsc190501.10326303': __('identifierSchemeCodeUnspsc190501.10326303'),
	'identifierSchemeCodeUnspsc190501.10326400': __('identifierSchemeCodeUnspsc190501.10326400'),
	'identifierSchemeCodeUnspsc190501.10326401': __('identifierSchemeCodeUnspsc190501.10326401'),
	'identifierSchemeCodeUnspsc190501.10326402': __('identifierSchemeCodeUnspsc190501.10326402'),
	'identifierSchemeCodeUnspsc190501.10326500': __('identifierSchemeCodeUnspsc190501.10326500'),
	'identifierSchemeCodeUnspsc190501.10326501': __('identifierSchemeCodeUnspsc190501.10326501'),
	'identifierSchemeCodeUnspsc190501.10326502': __('identifierSchemeCodeUnspsc190501.10326502'),
	'identifierSchemeCodeUnspsc190501.10326600': __('identifierSchemeCodeUnspsc190501.10326600'),
	'identifierSchemeCodeUnspsc190501.10326601': __('identifierSchemeCodeUnspsc190501.10326601'),
	'identifierSchemeCodeUnspsc190501.10326602': __('identifierSchemeCodeUnspsc190501.10326602'),
	'identifierSchemeCodeUnspsc190501.10326603': __('identifierSchemeCodeUnspsc190501.10326603'),
	'identifierSchemeCodeUnspsc190501.10326700': __('identifierSchemeCodeUnspsc190501.10326700'),
	'identifierSchemeCodeUnspsc190501.10326701': __('identifierSchemeCodeUnspsc190501.10326701'),
	'identifierSchemeCodeUnspsc190501.10326702': __('identifierSchemeCodeUnspsc190501.10326702'),
	'identifierSchemeCodeUnspsc190501.10326703': __('identifierSchemeCodeUnspsc190501.10326703'),
	'identifierSchemeCodeUnspsc190501.10326704': __('identifierSchemeCodeUnspsc190501.10326704'),
	'identifierSchemeCodeUnspsc190501.10330000': __('identifierSchemeCodeUnspsc190501.10330000'),
	'identifierSchemeCodeUnspsc190501.10331500': __('identifierSchemeCodeUnspsc190501.10331500'),
	'identifierSchemeCodeUnspsc190501.10331501': __('identifierSchemeCodeUnspsc190501.10331501'),
	'identifierSchemeCodeUnspsc190501.10331502': __('identifierSchemeCodeUnspsc190501.10331502'),
	'identifierSchemeCodeUnspsc190501.10331503': __('identifierSchemeCodeUnspsc190501.10331503'),
	'identifierSchemeCodeUnspsc190501.10331504': __('identifierSchemeCodeUnspsc190501.10331504'),
	'identifierSchemeCodeUnspsc190501.10331505': __('identifierSchemeCodeUnspsc190501.10331505'),
	'identifierSchemeCodeUnspsc190501.10331506': __('identifierSchemeCodeUnspsc190501.10331506'),
	'identifierSchemeCodeUnspsc190501.10331507': __('identifierSchemeCodeUnspsc190501.10331507'),
	'identifierSchemeCodeUnspsc190501.10331508': __('identifierSchemeCodeUnspsc190501.10331508'),
	'identifierSchemeCodeUnspsc190501.10331509': __('identifierSchemeCodeUnspsc190501.10331509'),
	'identifierSchemeCodeUnspsc190501.10331510': __('identifierSchemeCodeUnspsc190501.10331510'),
	'identifierSchemeCodeUnspsc190501.10331511': __('identifierSchemeCodeUnspsc190501.10331511'),
	'identifierSchemeCodeUnspsc190501.10331512': __('identifierSchemeCodeUnspsc190501.10331512'),
	'identifierSchemeCodeUnspsc190501.10331513': __('identifierSchemeCodeUnspsc190501.10331513'),
	'identifierSchemeCodeUnspsc190501.10331600': __('identifierSchemeCodeUnspsc190501.10331600'),
	'identifierSchemeCodeUnspsc190501.10331601': __('identifierSchemeCodeUnspsc190501.10331601'),
	'identifierSchemeCodeUnspsc190501.10331602': __('identifierSchemeCodeUnspsc190501.10331602'),
	'identifierSchemeCodeUnspsc190501.10331603': __('identifierSchemeCodeUnspsc190501.10331603'),
	'identifierSchemeCodeUnspsc190501.10331604': __('identifierSchemeCodeUnspsc190501.10331604'),
	'identifierSchemeCodeUnspsc190501.10331605': __('identifierSchemeCodeUnspsc190501.10331605'),
	'identifierSchemeCodeUnspsc190501.10331606': __('identifierSchemeCodeUnspsc190501.10331606'),
	'identifierSchemeCodeUnspsc190501.10331607': __('identifierSchemeCodeUnspsc190501.10331607'),
	'identifierSchemeCodeUnspsc190501.10331608': __('identifierSchemeCodeUnspsc190501.10331608'),
	'identifierSchemeCodeUnspsc190501.10331609': __('identifierSchemeCodeUnspsc190501.10331609'),
	'identifierSchemeCodeUnspsc190501.10331610': __('identifierSchemeCodeUnspsc190501.10331610'),
	'identifierSchemeCodeUnspsc190501.10331611': __('identifierSchemeCodeUnspsc190501.10331611'),
	'identifierSchemeCodeUnspsc190501.10331612': __('identifierSchemeCodeUnspsc190501.10331612'),
	'identifierSchemeCodeUnspsc190501.10331613': __('identifierSchemeCodeUnspsc190501.10331613'),
	'identifierSchemeCodeUnspsc190501.10331614': __('identifierSchemeCodeUnspsc190501.10331614'),
	'identifierSchemeCodeUnspsc190501.10331615': __('identifierSchemeCodeUnspsc190501.10331615'),
	'identifierSchemeCodeUnspsc190501.10331616': __('identifierSchemeCodeUnspsc190501.10331616'),
	'identifierSchemeCodeUnspsc190501.10331617': __('identifierSchemeCodeUnspsc190501.10331617'),
	'identifierSchemeCodeUnspsc190501.10331618': __('identifierSchemeCodeUnspsc190501.10331618'),
	'identifierSchemeCodeUnspsc190501.10331619': __('identifierSchemeCodeUnspsc190501.10331619'),
	'identifierSchemeCodeUnspsc190501.10331620': __('identifierSchemeCodeUnspsc190501.10331620'),
	'identifierSchemeCodeUnspsc190501.10331621': __('identifierSchemeCodeUnspsc190501.10331621'),
	'identifierSchemeCodeUnspsc190501.10331622': __('identifierSchemeCodeUnspsc190501.10331622'),
	'identifierSchemeCodeUnspsc190501.10331623': __('identifierSchemeCodeUnspsc190501.10331623'),
	'identifierSchemeCodeUnspsc190501.10331624': __('identifierSchemeCodeUnspsc190501.10331624'),
	'identifierSchemeCodeUnspsc190501.10331625': __('identifierSchemeCodeUnspsc190501.10331625'),
	'identifierSchemeCodeUnspsc190501.10331626': __('identifierSchemeCodeUnspsc190501.10331626'),
	'identifierSchemeCodeUnspsc190501.10331627': __('identifierSchemeCodeUnspsc190501.10331627'),
	'identifierSchemeCodeUnspsc190501.10331628': __('identifierSchemeCodeUnspsc190501.10331628'),
	'identifierSchemeCodeUnspsc190501.10331629': __('identifierSchemeCodeUnspsc190501.10331629'),
	'identifierSchemeCodeUnspsc190501.10331630': __('identifierSchemeCodeUnspsc190501.10331630'),
	'identifierSchemeCodeUnspsc190501.10331631': __('identifierSchemeCodeUnspsc190501.10331631'),
	'identifierSchemeCodeUnspsc190501.10331632': __('identifierSchemeCodeUnspsc190501.10331632'),
	'identifierSchemeCodeUnspsc190501.10331633': __('identifierSchemeCodeUnspsc190501.10331633'),
	'identifierSchemeCodeUnspsc190501.10331634': __('identifierSchemeCodeUnspsc190501.10331634'),
	'identifierSchemeCodeUnspsc190501.10331635': __('identifierSchemeCodeUnspsc190501.10331635'),
	'identifierSchemeCodeUnspsc190501.10331636': __('identifierSchemeCodeUnspsc190501.10331636'),
	'identifierSchemeCodeUnspsc190501.10331637': __('identifierSchemeCodeUnspsc190501.10331637'),
	'identifierSchemeCodeUnspsc190501.10331638': __('identifierSchemeCodeUnspsc190501.10331638'),
	'identifierSchemeCodeUnspsc190501.10331639': __('identifierSchemeCodeUnspsc190501.10331639'),
	'identifierSchemeCodeUnspsc190501.10331640': __('identifierSchemeCodeUnspsc190501.10331640'),
	'identifierSchemeCodeUnspsc190501.10331641': __('identifierSchemeCodeUnspsc190501.10331641'),
	'identifierSchemeCodeUnspsc190501.10331642': __('identifierSchemeCodeUnspsc190501.10331642'),
	'identifierSchemeCodeUnspsc190501.10331643': __('identifierSchemeCodeUnspsc190501.10331643'),
	'identifierSchemeCodeUnspsc190501.10331644': __('identifierSchemeCodeUnspsc190501.10331644'),
	'identifierSchemeCodeUnspsc190501.10331645': __('identifierSchemeCodeUnspsc190501.10331645'),
	'identifierSchemeCodeUnspsc190501.10331646': __('identifierSchemeCodeUnspsc190501.10331646'),
	'identifierSchemeCodeUnspsc190501.10331647': __('identifierSchemeCodeUnspsc190501.10331647'),
	'identifierSchemeCodeUnspsc190501.10331700': __('identifierSchemeCodeUnspsc190501.10331700'),
	'identifierSchemeCodeUnspsc190501.10331701': __('identifierSchemeCodeUnspsc190501.10331701'),
	'identifierSchemeCodeUnspsc190501.10331702': __('identifierSchemeCodeUnspsc190501.10331702'),
	'identifierSchemeCodeUnspsc190501.10331703': __('identifierSchemeCodeUnspsc190501.10331703'),
	'identifierSchemeCodeUnspsc190501.10331704': __('identifierSchemeCodeUnspsc190501.10331704'),
	'identifierSchemeCodeUnspsc190501.10331705': __('identifierSchemeCodeUnspsc190501.10331705'),
	'identifierSchemeCodeUnspsc190501.10331706': __('identifierSchemeCodeUnspsc190501.10331706'),
	'identifierSchemeCodeUnspsc190501.10331707': __('identifierSchemeCodeUnspsc190501.10331707'),
	'identifierSchemeCodeUnspsc190501.10331708': __('identifierSchemeCodeUnspsc190501.10331708'),
	'identifierSchemeCodeUnspsc190501.10331709': __('identifierSchemeCodeUnspsc190501.10331709'),
	'identifierSchemeCodeUnspsc190501.10331710': __('identifierSchemeCodeUnspsc190501.10331710'),
	'identifierSchemeCodeUnspsc190501.10331711': __('identifierSchemeCodeUnspsc190501.10331711'),
	'identifierSchemeCodeUnspsc190501.10331712': __('identifierSchemeCodeUnspsc190501.10331712'),
	'identifierSchemeCodeUnspsc190501.10331713': __('identifierSchemeCodeUnspsc190501.10331713'),
	'identifierSchemeCodeUnspsc190501.10331714': __('identifierSchemeCodeUnspsc190501.10331714'),
	'identifierSchemeCodeUnspsc190501.10331800': __('identifierSchemeCodeUnspsc190501.10331800'),
	'identifierSchemeCodeUnspsc190501.10331801': __('identifierSchemeCodeUnspsc190501.10331801'),
	'identifierSchemeCodeUnspsc190501.10331802': __('identifierSchemeCodeUnspsc190501.10331802'),
	'identifierSchemeCodeUnspsc190501.10331803': __('identifierSchemeCodeUnspsc190501.10331803'),
	'identifierSchemeCodeUnspsc190501.10331804': __('identifierSchemeCodeUnspsc190501.10331804'),
	'identifierSchemeCodeUnspsc190501.10331805': __('identifierSchemeCodeUnspsc190501.10331805'),
	'identifierSchemeCodeUnspsc190501.10331806': __('identifierSchemeCodeUnspsc190501.10331806'),
	'identifierSchemeCodeUnspsc190501.10331807': __('identifierSchemeCodeUnspsc190501.10331807'),
	'identifierSchemeCodeUnspsc190501.10331808': __('identifierSchemeCodeUnspsc190501.10331808'),
	'identifierSchemeCodeUnspsc190501.10331809': __('identifierSchemeCodeUnspsc190501.10331809'),
	'identifierSchemeCodeUnspsc190501.10331810': __('identifierSchemeCodeUnspsc190501.10331810'),
	'identifierSchemeCodeUnspsc190501.10331811': __('identifierSchemeCodeUnspsc190501.10331811'),
	'identifierSchemeCodeUnspsc190501.10331812': __('identifierSchemeCodeUnspsc190501.10331812'),
	'identifierSchemeCodeUnspsc190501.10331900': __('identifierSchemeCodeUnspsc190501.10331900'),
	'identifierSchemeCodeUnspsc190501.10331901': __('identifierSchemeCodeUnspsc190501.10331901'),
	'identifierSchemeCodeUnspsc190501.10331902': __('identifierSchemeCodeUnspsc190501.10331902'),
	'identifierSchemeCodeUnspsc190501.10331903': __('identifierSchemeCodeUnspsc190501.10331903'),
	'identifierSchemeCodeUnspsc190501.10331904': __('identifierSchemeCodeUnspsc190501.10331904'),
	'identifierSchemeCodeUnspsc190501.10331905': __('identifierSchemeCodeUnspsc190501.10331905'),
	'identifierSchemeCodeUnspsc190501.10331906': __('identifierSchemeCodeUnspsc190501.10331906'),
	'identifierSchemeCodeUnspsc190501.10331907': __('identifierSchemeCodeUnspsc190501.10331907'),
	'identifierSchemeCodeUnspsc190501.10331908': __('identifierSchemeCodeUnspsc190501.10331908'),
	'identifierSchemeCodeUnspsc190501.10331909': __('identifierSchemeCodeUnspsc190501.10331909'),
	'identifierSchemeCodeUnspsc190501.10331910': __('identifierSchemeCodeUnspsc190501.10331910'),
	'identifierSchemeCodeUnspsc190501.10331911': __('identifierSchemeCodeUnspsc190501.10331911'),
	'identifierSchemeCodeUnspsc190501.10331912': __('identifierSchemeCodeUnspsc190501.10331912'),
	'identifierSchemeCodeUnspsc190501.10331913': __('identifierSchemeCodeUnspsc190501.10331913'),
	'identifierSchemeCodeUnspsc190501.10331914': __('identifierSchemeCodeUnspsc190501.10331914'),
	'identifierSchemeCodeUnspsc190501.10331915': __('identifierSchemeCodeUnspsc190501.10331915'),
	'identifierSchemeCodeUnspsc190501.10331916': __('identifierSchemeCodeUnspsc190501.10331916'),
	'identifierSchemeCodeUnspsc190501.10331917': __('identifierSchemeCodeUnspsc190501.10331917'),
	'identifierSchemeCodeUnspsc190501.10331918': __('identifierSchemeCodeUnspsc190501.10331918'),
	'identifierSchemeCodeUnspsc190501.10331919': __('identifierSchemeCodeUnspsc190501.10331919'),
	'identifierSchemeCodeUnspsc190501.10331920': __('identifierSchemeCodeUnspsc190501.10331920'),
	'identifierSchemeCodeUnspsc190501.10331921': __('identifierSchemeCodeUnspsc190501.10331921'),
	'identifierSchemeCodeUnspsc190501.10331922': __('identifierSchemeCodeUnspsc190501.10331922'),
	'identifierSchemeCodeUnspsc190501.10331923': __('identifierSchemeCodeUnspsc190501.10331923'),
	'identifierSchemeCodeUnspsc190501.10331924': __('identifierSchemeCodeUnspsc190501.10331924'),
	'identifierSchemeCodeUnspsc190501.10331925': __('identifierSchemeCodeUnspsc190501.10331925'),
	'identifierSchemeCodeUnspsc190501.10331926': __('identifierSchemeCodeUnspsc190501.10331926'),
	'identifierSchemeCodeUnspsc190501.10332000': __('identifierSchemeCodeUnspsc190501.10332000'),
	'identifierSchemeCodeUnspsc190501.10332001': __('identifierSchemeCodeUnspsc190501.10332001'),
	'identifierSchemeCodeUnspsc190501.10332002': __('identifierSchemeCodeUnspsc190501.10332002'),
	'identifierSchemeCodeUnspsc190501.10332003': __('identifierSchemeCodeUnspsc190501.10332003'),
	'identifierSchemeCodeUnspsc190501.10332004': __('identifierSchemeCodeUnspsc190501.10332004'),
	'identifierSchemeCodeUnspsc190501.10332005': __('identifierSchemeCodeUnspsc190501.10332005'),
	'identifierSchemeCodeUnspsc190501.10332006': __('identifierSchemeCodeUnspsc190501.10332006'),
	'identifierSchemeCodeUnspsc190501.10332007': __('identifierSchemeCodeUnspsc190501.10332007'),
	'identifierSchemeCodeUnspsc190501.10332008': __('identifierSchemeCodeUnspsc190501.10332008'),
	'identifierSchemeCodeUnspsc190501.10332009': __('identifierSchemeCodeUnspsc190501.10332009'),
	'identifierSchemeCodeUnspsc190501.10332010': __('identifierSchemeCodeUnspsc190501.10332010'),
	'identifierSchemeCodeUnspsc190501.10332012': __('identifierSchemeCodeUnspsc190501.10332012'),
	'identifierSchemeCodeUnspsc190501.10332013': __('identifierSchemeCodeUnspsc190501.10332013'),
	'identifierSchemeCodeUnspsc190501.10332014': __('identifierSchemeCodeUnspsc190501.10332014'),
	'identifierSchemeCodeUnspsc190501.10332015': __('identifierSchemeCodeUnspsc190501.10332015'),
	'identifierSchemeCodeUnspsc190501.10332016': __('identifierSchemeCodeUnspsc190501.10332016'),
	'identifierSchemeCodeUnspsc190501.10332017': __('identifierSchemeCodeUnspsc190501.10332017'),
	'identifierSchemeCodeUnspsc190501.10332018': __('identifierSchemeCodeUnspsc190501.10332018'),
	'identifierSchemeCodeUnspsc190501.10332019': __('identifierSchemeCodeUnspsc190501.10332019'),
	'identifierSchemeCodeUnspsc190501.10332020': __('identifierSchemeCodeUnspsc190501.10332020'),
	'identifierSchemeCodeUnspsc190501.10332021': __('identifierSchemeCodeUnspsc190501.10332021'),
	'identifierSchemeCodeUnspsc190501.10332022': __('identifierSchemeCodeUnspsc190501.10332022'),
	'identifierSchemeCodeUnspsc190501.10332023': __('identifierSchemeCodeUnspsc190501.10332023'),
	'identifierSchemeCodeUnspsc190501.10332024': __('identifierSchemeCodeUnspsc190501.10332024'),
	'identifierSchemeCodeUnspsc190501.10332025': __('identifierSchemeCodeUnspsc190501.10332025'),
	'identifierSchemeCodeUnspsc190501.10332026': __('identifierSchemeCodeUnspsc190501.10332026'),
	'identifierSchemeCodeUnspsc190501.10332027': __('identifierSchemeCodeUnspsc190501.10332027'),
	'identifierSchemeCodeUnspsc190501.10332028': __('identifierSchemeCodeUnspsc190501.10332028'),
	'identifierSchemeCodeUnspsc190501.10332029': __('identifierSchemeCodeUnspsc190501.10332029'),
	'identifierSchemeCodeUnspsc190501.10332030': __('identifierSchemeCodeUnspsc190501.10332030'),
	'identifierSchemeCodeUnspsc190501.10332031': __('identifierSchemeCodeUnspsc190501.10332031'),
	'identifierSchemeCodeUnspsc190501.10332032': __('identifierSchemeCodeUnspsc190501.10332032'),
	'identifierSchemeCodeUnspsc190501.10332033': __('identifierSchemeCodeUnspsc190501.10332033'),
	'identifierSchemeCodeUnspsc190501.10332034': __('identifierSchemeCodeUnspsc190501.10332034'),
	'identifierSchemeCodeUnspsc190501.10332035': __('identifierSchemeCodeUnspsc190501.10332035'),
	'identifierSchemeCodeUnspsc190501.10332036': __('identifierSchemeCodeUnspsc190501.10332036'),
	'identifierSchemeCodeUnspsc190501.10332037': __('identifierSchemeCodeUnspsc190501.10332037'),
	'identifierSchemeCodeUnspsc190501.10332038': __('identifierSchemeCodeUnspsc190501.10332038'),
	'identifierSchemeCodeUnspsc190501.10332039': __('identifierSchemeCodeUnspsc190501.10332039'),
	'identifierSchemeCodeUnspsc190501.10332040': __('identifierSchemeCodeUnspsc190501.10332040'),
	'identifierSchemeCodeUnspsc190501.10332041': __('identifierSchemeCodeUnspsc190501.10332041'),
	'identifierSchemeCodeUnspsc190501.10332042': __('identifierSchemeCodeUnspsc190501.10332042'),
	'identifierSchemeCodeUnspsc190501.10332043': __('identifierSchemeCodeUnspsc190501.10332043'),
	'identifierSchemeCodeUnspsc190501.10332044': __('identifierSchemeCodeUnspsc190501.10332044'),
	'identifierSchemeCodeUnspsc190501.10332045': __('identifierSchemeCodeUnspsc190501.10332045'),
	'identifierSchemeCodeUnspsc190501.10332046': __('identifierSchemeCodeUnspsc190501.10332046'),
	'identifierSchemeCodeUnspsc190501.10332047': __('identifierSchemeCodeUnspsc190501.10332047'),
	'identifierSchemeCodeUnspsc190501.10332048': __('identifierSchemeCodeUnspsc190501.10332048'),
	'identifierSchemeCodeUnspsc190501.10332049': __('identifierSchemeCodeUnspsc190501.10332049'),
	'identifierSchemeCodeUnspsc190501.10332050': __('identifierSchemeCodeUnspsc190501.10332050'),
	'identifierSchemeCodeUnspsc190501.10332051': __('identifierSchemeCodeUnspsc190501.10332051'),
	'identifierSchemeCodeUnspsc190501.10332052': __('identifierSchemeCodeUnspsc190501.10332052'),
	'identifierSchemeCodeUnspsc190501.10332053': __('identifierSchemeCodeUnspsc190501.10332053'),
	'identifierSchemeCodeUnspsc190501.10332054': __('identifierSchemeCodeUnspsc190501.10332054'),
	'identifierSchemeCodeUnspsc190501.10332055': __('identifierSchemeCodeUnspsc190501.10332055'),
	'identifierSchemeCodeUnspsc190501.10332056': __('identifierSchemeCodeUnspsc190501.10332056'),
	'identifierSchemeCodeUnspsc190501.10332057': __('identifierSchemeCodeUnspsc190501.10332057'),
	'identifierSchemeCodeUnspsc190501.10332058': __('identifierSchemeCodeUnspsc190501.10332058'),
	'identifierSchemeCodeUnspsc190501.10332059': __('identifierSchemeCodeUnspsc190501.10332059'),
	'identifierSchemeCodeUnspsc190501.10332060': __('identifierSchemeCodeUnspsc190501.10332060'),
	'identifierSchemeCodeUnspsc190501.10332061': __('identifierSchemeCodeUnspsc190501.10332061'),
	'identifierSchemeCodeUnspsc190501.10332062': __('identifierSchemeCodeUnspsc190501.10332062'),
	'identifierSchemeCodeUnspsc190501.10332063': __('identifierSchemeCodeUnspsc190501.10332063'),
	'identifierSchemeCodeUnspsc190501.10332064': __('identifierSchemeCodeUnspsc190501.10332064'),
	'identifierSchemeCodeUnspsc190501.10332065': __('identifierSchemeCodeUnspsc190501.10332065'),
	'identifierSchemeCodeUnspsc190501.10332066': __('identifierSchemeCodeUnspsc190501.10332066'),
	'identifierSchemeCodeUnspsc190501.10332067': __('identifierSchemeCodeUnspsc190501.10332067'),
	'identifierSchemeCodeUnspsc190501.10332100': __('identifierSchemeCodeUnspsc190501.10332100'),
	'identifierSchemeCodeUnspsc190501.10332101': __('identifierSchemeCodeUnspsc190501.10332101'),
	'identifierSchemeCodeUnspsc190501.10332102': __('identifierSchemeCodeUnspsc190501.10332102'),
	'identifierSchemeCodeUnspsc190501.10332103': __('identifierSchemeCodeUnspsc190501.10332103'),
	'identifierSchemeCodeUnspsc190501.10332104': __('identifierSchemeCodeUnspsc190501.10332104'),
	'identifierSchemeCodeUnspsc190501.10332105': __('identifierSchemeCodeUnspsc190501.10332105'),
	'identifierSchemeCodeUnspsc190501.10332106': __('identifierSchemeCodeUnspsc190501.10332106'),
	'identifierSchemeCodeUnspsc190501.10332107': __('identifierSchemeCodeUnspsc190501.10332107'),
	'identifierSchemeCodeUnspsc190501.10332108': __('identifierSchemeCodeUnspsc190501.10332108'),
	'identifierSchemeCodeUnspsc190501.10332109': __('identifierSchemeCodeUnspsc190501.10332109'),
	'identifierSchemeCodeUnspsc190501.10332110': __('identifierSchemeCodeUnspsc190501.10332110'),
	'identifierSchemeCodeUnspsc190501.10332111': __('identifierSchemeCodeUnspsc190501.10332111'),
	'identifierSchemeCodeUnspsc190501.10332112': __('identifierSchemeCodeUnspsc190501.10332112'),
	'identifierSchemeCodeUnspsc190501.10332113': __('identifierSchemeCodeUnspsc190501.10332113'),
	'identifierSchemeCodeUnspsc190501.10332114': __('identifierSchemeCodeUnspsc190501.10332114'),
	'identifierSchemeCodeUnspsc190501.10332115': __('identifierSchemeCodeUnspsc190501.10332115'),
	'identifierSchemeCodeUnspsc190501.10332116': __('identifierSchemeCodeUnspsc190501.10332116'),
	'identifierSchemeCodeUnspsc190501.10332117': __('identifierSchemeCodeUnspsc190501.10332117'),
	'identifierSchemeCodeUnspsc190501.10332118': __('identifierSchemeCodeUnspsc190501.10332118'),
	'identifierSchemeCodeUnspsc190501.10332119': __('identifierSchemeCodeUnspsc190501.10332119'),
	'identifierSchemeCodeUnspsc190501.10332120': __('identifierSchemeCodeUnspsc190501.10332120'),
	'identifierSchemeCodeUnspsc190501.10332121': __('identifierSchemeCodeUnspsc190501.10332121'),
	'identifierSchemeCodeUnspsc190501.10332122': __('identifierSchemeCodeUnspsc190501.10332122'),
	'identifierSchemeCodeUnspsc190501.10332123': __('identifierSchemeCodeUnspsc190501.10332123'),
	'identifierSchemeCodeUnspsc190501.10332124': __('identifierSchemeCodeUnspsc190501.10332124'),
	'identifierSchemeCodeUnspsc190501.10332125': __('identifierSchemeCodeUnspsc190501.10332125'),
	'identifierSchemeCodeUnspsc190501.10332126': __('identifierSchemeCodeUnspsc190501.10332126'),
	'identifierSchemeCodeUnspsc190501.10332127': __('identifierSchemeCodeUnspsc190501.10332127'),
	'identifierSchemeCodeUnspsc190501.10332128': __('identifierSchemeCodeUnspsc190501.10332128'),
	'identifierSchemeCodeUnspsc190501.10332129': __('identifierSchemeCodeUnspsc190501.10332129'),
	'identifierSchemeCodeUnspsc190501.10332130': __('identifierSchemeCodeUnspsc190501.10332130'),
	'identifierSchemeCodeUnspsc190501.10332131': __('identifierSchemeCodeUnspsc190501.10332131'),
	'identifierSchemeCodeUnspsc190501.10332132': __('identifierSchemeCodeUnspsc190501.10332132'),
	'identifierSchemeCodeUnspsc190501.10340000': __('identifierSchemeCodeUnspsc190501.10340000'),
	'identifierSchemeCodeUnspsc190501.10341500': __('identifierSchemeCodeUnspsc190501.10341500'),
	'identifierSchemeCodeUnspsc190501.10341501': __('identifierSchemeCodeUnspsc190501.10341501'),
	'identifierSchemeCodeUnspsc190501.10341600': __('identifierSchemeCodeUnspsc190501.10341600'),
	'identifierSchemeCodeUnspsc190501.10341601': __('identifierSchemeCodeUnspsc190501.10341601'),
	'identifierSchemeCodeUnspsc190501.10341700': __('identifierSchemeCodeUnspsc190501.10341700'),
	'identifierSchemeCodeUnspsc190501.10341701': __('identifierSchemeCodeUnspsc190501.10341701'),
	'identifierSchemeCodeUnspsc190501.10341800': __('identifierSchemeCodeUnspsc190501.10341800'),
	'identifierSchemeCodeUnspsc190501.10341801': __('identifierSchemeCodeUnspsc190501.10341801'),
	'identifierSchemeCodeUnspsc190501.10341900': __('identifierSchemeCodeUnspsc190501.10341900'),
	'identifierSchemeCodeUnspsc190501.10341901': __('identifierSchemeCodeUnspsc190501.10341901'),
	'identifierSchemeCodeUnspsc190501.10342000': __('identifierSchemeCodeUnspsc190501.10342000'),
	'identifierSchemeCodeUnspsc190501.10342001': __('identifierSchemeCodeUnspsc190501.10342001'),
	'identifierSchemeCodeUnspsc190501.10342100': __('identifierSchemeCodeUnspsc190501.10342100'),
	'identifierSchemeCodeUnspsc190501.10342101': __('identifierSchemeCodeUnspsc190501.10342101'),
	'identifierSchemeCodeUnspsc190501.10342200': __('identifierSchemeCodeUnspsc190501.10342200'),
	'identifierSchemeCodeUnspsc190501.10342201': __('identifierSchemeCodeUnspsc190501.10342201'),
	'identifierSchemeCodeUnspsc190501.10350000': __('identifierSchemeCodeUnspsc190501.10350000'),
	'identifierSchemeCodeUnspsc190501.10351500': __('identifierSchemeCodeUnspsc190501.10351500'),
	'identifierSchemeCodeUnspsc190501.10351501': __('identifierSchemeCodeUnspsc190501.10351501'),
	'identifierSchemeCodeUnspsc190501.10351502': __('identifierSchemeCodeUnspsc190501.10351502'),
	'identifierSchemeCodeUnspsc190501.10351503': __('identifierSchemeCodeUnspsc190501.10351503'),
	'identifierSchemeCodeUnspsc190501.10351504': __('identifierSchemeCodeUnspsc190501.10351504'),
	'identifierSchemeCodeUnspsc190501.10351505': __('identifierSchemeCodeUnspsc190501.10351505'),
	'identifierSchemeCodeUnspsc190501.10351506': __('identifierSchemeCodeUnspsc190501.10351506'),
	'identifierSchemeCodeUnspsc190501.10351507': __('identifierSchemeCodeUnspsc190501.10351507'),
	'identifierSchemeCodeUnspsc190501.10351508': __('identifierSchemeCodeUnspsc190501.10351508'),
	'identifierSchemeCodeUnspsc190501.10351509': __('identifierSchemeCodeUnspsc190501.10351509'),
	'identifierSchemeCodeUnspsc190501.10351510': __('identifierSchemeCodeUnspsc190501.10351510'),
	'identifierSchemeCodeUnspsc190501.10351511': __('identifierSchemeCodeUnspsc190501.10351511'),
	'identifierSchemeCodeUnspsc190501.10351512': __('identifierSchemeCodeUnspsc190501.10351512'),
	'identifierSchemeCodeUnspsc190501.10351513': __('identifierSchemeCodeUnspsc190501.10351513'),
	'identifierSchemeCodeUnspsc190501.10351514': __('identifierSchemeCodeUnspsc190501.10351514'),
	'identifierSchemeCodeUnspsc190501.10351515': __('identifierSchemeCodeUnspsc190501.10351515'),
	'identifierSchemeCodeUnspsc190501.10351516': __('identifierSchemeCodeUnspsc190501.10351516'),
	'identifierSchemeCodeUnspsc190501.10351517': __('identifierSchemeCodeUnspsc190501.10351517'),
	'identifierSchemeCodeUnspsc190501.10351518': __('identifierSchemeCodeUnspsc190501.10351518'),
	'identifierSchemeCodeUnspsc190501.10351519': __('identifierSchemeCodeUnspsc190501.10351519'),
	'identifierSchemeCodeUnspsc190501.10351520': __('identifierSchemeCodeUnspsc190501.10351520'),
	'identifierSchemeCodeUnspsc190501.10351600': __('identifierSchemeCodeUnspsc190501.10351600'),
	'identifierSchemeCodeUnspsc190501.10351601': __('identifierSchemeCodeUnspsc190501.10351601'),
	'identifierSchemeCodeUnspsc190501.10351602': __('identifierSchemeCodeUnspsc190501.10351602'),
	'identifierSchemeCodeUnspsc190501.10351603': __('identifierSchemeCodeUnspsc190501.10351603'),
	'identifierSchemeCodeUnspsc190501.10351604': __('identifierSchemeCodeUnspsc190501.10351604'),
	'identifierSchemeCodeUnspsc190501.10351605': __('identifierSchemeCodeUnspsc190501.10351605'),
	'identifierSchemeCodeUnspsc190501.10351606': __('identifierSchemeCodeUnspsc190501.10351606'),
	'identifierSchemeCodeUnspsc190501.10351607': __('identifierSchemeCodeUnspsc190501.10351607'),
	'identifierSchemeCodeUnspsc190501.10351608': __('identifierSchemeCodeUnspsc190501.10351608'),
	'identifierSchemeCodeUnspsc190501.10351609': __('identifierSchemeCodeUnspsc190501.10351609'),
	'identifierSchemeCodeUnspsc190501.10351610': __('identifierSchemeCodeUnspsc190501.10351610'),
	'identifierSchemeCodeUnspsc190501.10351611': __('identifierSchemeCodeUnspsc190501.10351611'),
	'identifierSchemeCodeUnspsc190501.10351612': __('identifierSchemeCodeUnspsc190501.10351612'),
	'identifierSchemeCodeUnspsc190501.10351613': __('identifierSchemeCodeUnspsc190501.10351613'),
	'identifierSchemeCodeUnspsc190501.10351614': __('identifierSchemeCodeUnspsc190501.10351614'),
	'identifierSchemeCodeUnspsc190501.10360000': __('identifierSchemeCodeUnspsc190501.10360000'),
	'identifierSchemeCodeUnspsc190501.10361500': __('identifierSchemeCodeUnspsc190501.10361500'),
	'identifierSchemeCodeUnspsc190501.10361501': __('identifierSchemeCodeUnspsc190501.10361501'),
	'identifierSchemeCodeUnspsc190501.10361502': __('identifierSchemeCodeUnspsc190501.10361502'),
	'identifierSchemeCodeUnspsc190501.10361503': __('identifierSchemeCodeUnspsc190501.10361503'),
	'identifierSchemeCodeUnspsc190501.10361504': __('identifierSchemeCodeUnspsc190501.10361504'),
	'identifierSchemeCodeUnspsc190501.10361505': __('identifierSchemeCodeUnspsc190501.10361505'),
	'identifierSchemeCodeUnspsc190501.10361600': __('identifierSchemeCodeUnspsc190501.10361600'),
	'identifierSchemeCodeUnspsc190501.10361601': __('identifierSchemeCodeUnspsc190501.10361601'),
	'identifierSchemeCodeUnspsc190501.10361602': __('identifierSchemeCodeUnspsc190501.10361602'),
	'identifierSchemeCodeUnspsc190501.10361603': __('identifierSchemeCodeUnspsc190501.10361603'),
	'identifierSchemeCodeUnspsc190501.10361604': __('identifierSchemeCodeUnspsc190501.10361604'),
	'identifierSchemeCodeUnspsc190501.10361605': __('identifierSchemeCodeUnspsc190501.10361605'),
	'identifierSchemeCodeUnspsc190501.10361700': __('identifierSchemeCodeUnspsc190501.10361700'),
	'identifierSchemeCodeUnspsc190501.10361701': __('identifierSchemeCodeUnspsc190501.10361701'),
	'identifierSchemeCodeUnspsc190501.10361702': __('identifierSchemeCodeUnspsc190501.10361702'),
	'identifierSchemeCodeUnspsc190501.10361800': __('identifierSchemeCodeUnspsc190501.10361800'),
	'identifierSchemeCodeUnspsc190501.10361801': __('identifierSchemeCodeUnspsc190501.10361801'),
	'identifierSchemeCodeUnspsc190501.10361802': __('identifierSchemeCodeUnspsc190501.10361802'),
	'identifierSchemeCodeUnspsc190501.10361803': __('identifierSchemeCodeUnspsc190501.10361803'),
	'identifierSchemeCodeUnspsc190501.10361804': __('identifierSchemeCodeUnspsc190501.10361804'),
	'identifierSchemeCodeUnspsc190501.10361805': __('identifierSchemeCodeUnspsc190501.10361805'),
	'identifierSchemeCodeUnspsc190501.10361806': __('identifierSchemeCodeUnspsc190501.10361806'),
	'identifierSchemeCodeUnspsc190501.10361900': __('identifierSchemeCodeUnspsc190501.10361900'),
	'identifierSchemeCodeUnspsc190501.10361901': __('identifierSchemeCodeUnspsc190501.10361901'),
	'identifierSchemeCodeUnspsc190501.10361902': __('identifierSchemeCodeUnspsc190501.10361902'),
	'identifierSchemeCodeUnspsc190501.10361903': __('identifierSchemeCodeUnspsc190501.10361903'),
	'identifierSchemeCodeUnspsc190501.10361904': __('identifierSchemeCodeUnspsc190501.10361904'),
	'identifierSchemeCodeUnspsc190501.10361905': __('identifierSchemeCodeUnspsc190501.10361905'),
	'identifierSchemeCodeUnspsc190501.10362000': __('identifierSchemeCodeUnspsc190501.10362000'),
	'identifierSchemeCodeUnspsc190501.10362001': __('identifierSchemeCodeUnspsc190501.10362001'),
	'identifierSchemeCodeUnspsc190501.10362002': __('identifierSchemeCodeUnspsc190501.10362002'),
	'identifierSchemeCodeUnspsc190501.10362003': __('identifierSchemeCodeUnspsc190501.10362003'),
	'identifierSchemeCodeUnspsc190501.10362004': __('identifierSchemeCodeUnspsc190501.10362004'),
	'identifierSchemeCodeUnspsc190501.10362005': __('identifierSchemeCodeUnspsc190501.10362005'),
	'identifierSchemeCodeUnspsc190501.10362006': __('identifierSchemeCodeUnspsc190501.10362006'),
	'identifierSchemeCodeUnspsc190501.10362007': __('identifierSchemeCodeUnspsc190501.10362007'),
	'identifierSchemeCodeUnspsc190501.10362008': __('identifierSchemeCodeUnspsc190501.10362008'),
	'identifierSchemeCodeUnspsc190501.10362009': __('identifierSchemeCodeUnspsc190501.10362009'),
	'identifierSchemeCodeUnspsc190501.10362010': __('identifierSchemeCodeUnspsc190501.10362010'),
	'identifierSchemeCodeUnspsc190501.10362011': __('identifierSchemeCodeUnspsc190501.10362011'),
	'identifierSchemeCodeUnspsc190501.10362012': __('identifierSchemeCodeUnspsc190501.10362012'),
	'identifierSchemeCodeUnspsc190501.10362013': __('identifierSchemeCodeUnspsc190501.10362013'),
	'identifierSchemeCodeUnspsc190501.10362014': __('identifierSchemeCodeUnspsc190501.10362014'),
	'identifierSchemeCodeUnspsc190501.10362015': __('identifierSchemeCodeUnspsc190501.10362015'),
	'identifierSchemeCodeUnspsc190501.10362016': __('identifierSchemeCodeUnspsc190501.10362016'),
	'identifierSchemeCodeUnspsc190501.10362017': __('identifierSchemeCodeUnspsc190501.10362017'),
	'identifierSchemeCodeUnspsc190501.10362018': __('identifierSchemeCodeUnspsc190501.10362018'),
	'identifierSchemeCodeUnspsc190501.10362019': __('identifierSchemeCodeUnspsc190501.10362019'),
	'identifierSchemeCodeUnspsc190501.10362020': __('identifierSchemeCodeUnspsc190501.10362020'),
	'identifierSchemeCodeUnspsc190501.10362021': __('identifierSchemeCodeUnspsc190501.10362021'),
	'identifierSchemeCodeUnspsc190501.10362022': __('identifierSchemeCodeUnspsc190501.10362022'),
	'identifierSchemeCodeUnspsc190501.10362023': __('identifierSchemeCodeUnspsc190501.10362023'),
	'identifierSchemeCodeUnspsc190501.10362024': __('identifierSchemeCodeUnspsc190501.10362024'),
	'identifierSchemeCodeUnspsc190501.10362025': __('identifierSchemeCodeUnspsc190501.10362025'),
	'identifierSchemeCodeUnspsc190501.10362026': __('identifierSchemeCodeUnspsc190501.10362026'),
	'identifierSchemeCodeUnspsc190501.10362027': __('identifierSchemeCodeUnspsc190501.10362027'),
	'identifierSchemeCodeUnspsc190501.10362028': __('identifierSchemeCodeUnspsc190501.10362028'),
	'identifierSchemeCodeUnspsc190501.10362029': __('identifierSchemeCodeUnspsc190501.10362029'),
	'identifierSchemeCodeUnspsc190501.10362030': __('identifierSchemeCodeUnspsc190501.10362030'),
	'identifierSchemeCodeUnspsc190501.10362031': __('identifierSchemeCodeUnspsc190501.10362031'),
	'identifierSchemeCodeUnspsc190501.10362032': __('identifierSchemeCodeUnspsc190501.10362032'),
	'identifierSchemeCodeUnspsc190501.10362033': __('identifierSchemeCodeUnspsc190501.10362033'),
	'identifierSchemeCodeUnspsc190501.10362034': __('identifierSchemeCodeUnspsc190501.10362034'),
	'identifierSchemeCodeUnspsc190501.10362035': __('identifierSchemeCodeUnspsc190501.10362035'),
	'identifierSchemeCodeUnspsc190501.10362036': __('identifierSchemeCodeUnspsc190501.10362036'),
	'identifierSchemeCodeUnspsc190501.10362037': __('identifierSchemeCodeUnspsc190501.10362037'),
	'identifierSchemeCodeUnspsc190501.10362038': __('identifierSchemeCodeUnspsc190501.10362038'),
	'identifierSchemeCodeUnspsc190501.10362039': __('identifierSchemeCodeUnspsc190501.10362039'),
	'identifierSchemeCodeUnspsc190501.10362040': __('identifierSchemeCodeUnspsc190501.10362040'),
	'identifierSchemeCodeUnspsc190501.10362041': __('identifierSchemeCodeUnspsc190501.10362041'),
	'identifierSchemeCodeUnspsc190501.10362042': __('identifierSchemeCodeUnspsc190501.10362042'),
	'identifierSchemeCodeUnspsc190501.10362043': __('identifierSchemeCodeUnspsc190501.10362043'),
	'identifierSchemeCodeUnspsc190501.10362044': __('identifierSchemeCodeUnspsc190501.10362044'),
	'identifierSchemeCodeUnspsc190501.10362045': __('identifierSchemeCodeUnspsc190501.10362045'),
	'identifierSchemeCodeUnspsc190501.10362046': __('identifierSchemeCodeUnspsc190501.10362046'),
	'identifierSchemeCodeUnspsc190501.10362047': __('identifierSchemeCodeUnspsc190501.10362047'),
	'identifierSchemeCodeUnspsc190501.10362048': __('identifierSchemeCodeUnspsc190501.10362048'),
	'identifierSchemeCodeUnspsc190501.10362049': __('identifierSchemeCodeUnspsc190501.10362049'),
	'identifierSchemeCodeUnspsc190501.10362050': __('identifierSchemeCodeUnspsc190501.10362050'),
	'identifierSchemeCodeUnspsc190501.10362051': __('identifierSchemeCodeUnspsc190501.10362051'),
	'identifierSchemeCodeUnspsc190501.10362052': __('identifierSchemeCodeUnspsc190501.10362052'),
	'identifierSchemeCodeUnspsc190501.10362053': __('identifierSchemeCodeUnspsc190501.10362053'),
	'identifierSchemeCodeUnspsc190501.10362054': __('identifierSchemeCodeUnspsc190501.10362054'),
	'identifierSchemeCodeUnspsc190501.10362055': __('identifierSchemeCodeUnspsc190501.10362055'),
	'identifierSchemeCodeUnspsc190501.10362056': __('identifierSchemeCodeUnspsc190501.10362056'),
	'identifierSchemeCodeUnspsc190501.10362057': __('identifierSchemeCodeUnspsc190501.10362057'),
	'identifierSchemeCodeUnspsc190501.10362058': __('identifierSchemeCodeUnspsc190501.10362058'),
	'identifierSchemeCodeUnspsc190501.10362059': __('identifierSchemeCodeUnspsc190501.10362059'),
	'identifierSchemeCodeUnspsc190501.10362060': __('identifierSchemeCodeUnspsc190501.10362060'),
	'identifierSchemeCodeUnspsc190501.10362061': __('identifierSchemeCodeUnspsc190501.10362061'),
	'identifierSchemeCodeUnspsc190501.10362062': __('identifierSchemeCodeUnspsc190501.10362062'),
	'identifierSchemeCodeUnspsc190501.10362063': __('identifierSchemeCodeUnspsc190501.10362063'),
	'identifierSchemeCodeUnspsc190501.10362064': __('identifierSchemeCodeUnspsc190501.10362064'),
	'identifierSchemeCodeUnspsc190501.10362065': __('identifierSchemeCodeUnspsc190501.10362065'),
	'identifierSchemeCodeUnspsc190501.10362067': __('identifierSchemeCodeUnspsc190501.10362067'),
	'identifierSchemeCodeUnspsc190501.10362100': __('identifierSchemeCodeUnspsc190501.10362100'),
	'identifierSchemeCodeUnspsc190501.10362101': __('identifierSchemeCodeUnspsc190501.10362101'),
	'identifierSchemeCodeUnspsc190501.10362102': __('identifierSchemeCodeUnspsc190501.10362102'),
	'identifierSchemeCodeUnspsc190501.10362103': __('identifierSchemeCodeUnspsc190501.10362103'),
	'identifierSchemeCodeUnspsc190501.10362104': __('identifierSchemeCodeUnspsc190501.10362104'),
	'identifierSchemeCodeUnspsc190501.10362105': __('identifierSchemeCodeUnspsc190501.10362105'),
	'identifierSchemeCodeUnspsc190501.10362106': __('identifierSchemeCodeUnspsc190501.10362106'),
	'identifierSchemeCodeUnspsc190501.10362107': __('identifierSchemeCodeUnspsc190501.10362107'),
	'identifierSchemeCodeUnspsc190501.10362108': __('identifierSchemeCodeUnspsc190501.10362108'),
	'identifierSchemeCodeUnspsc190501.10362109': __('identifierSchemeCodeUnspsc190501.10362109'),
	'identifierSchemeCodeUnspsc190501.10362110': __('identifierSchemeCodeUnspsc190501.10362110'),
	'identifierSchemeCodeUnspsc190501.10362111': __('identifierSchemeCodeUnspsc190501.10362111'),
	'identifierSchemeCodeUnspsc190501.10362112': __('identifierSchemeCodeUnspsc190501.10362112'),
	'identifierSchemeCodeUnspsc190501.10362200': __('identifierSchemeCodeUnspsc190501.10362200'),
	'identifierSchemeCodeUnspsc190501.10362201': __('identifierSchemeCodeUnspsc190501.10362201'),
	'identifierSchemeCodeUnspsc190501.10362202': __('identifierSchemeCodeUnspsc190501.10362202'),
	'identifierSchemeCodeUnspsc190501.10362203': __('identifierSchemeCodeUnspsc190501.10362203'),
	'identifierSchemeCodeUnspsc190501.10362204': __('identifierSchemeCodeUnspsc190501.10362204'),
	'identifierSchemeCodeUnspsc190501.10362205': __('identifierSchemeCodeUnspsc190501.10362205'),
	'identifierSchemeCodeUnspsc190501.10362206': __('identifierSchemeCodeUnspsc190501.10362206'),
	'identifierSchemeCodeUnspsc190501.10362207': __('identifierSchemeCodeUnspsc190501.10362207'),
	'identifierSchemeCodeUnspsc190501.10362208': __('identifierSchemeCodeUnspsc190501.10362208'),
	'identifierSchemeCodeUnspsc190501.10362209': __('identifierSchemeCodeUnspsc190501.10362209'),
	'identifierSchemeCodeUnspsc190501.10362210': __('identifierSchemeCodeUnspsc190501.10362210'),
	'identifierSchemeCodeUnspsc190501.10362211': __('identifierSchemeCodeUnspsc190501.10362211'),
	'identifierSchemeCodeUnspsc190501.10362212': __('identifierSchemeCodeUnspsc190501.10362212'),
	'identifierSchemeCodeUnspsc190501.10362213': __('identifierSchemeCodeUnspsc190501.10362213'),
	'identifierSchemeCodeUnspsc190501.10362300': __('identifierSchemeCodeUnspsc190501.10362300'),
	'identifierSchemeCodeUnspsc190501.10362301': __('identifierSchemeCodeUnspsc190501.10362301'),
	'identifierSchemeCodeUnspsc190501.10362302': __('identifierSchemeCodeUnspsc190501.10362302'),
	'identifierSchemeCodeUnspsc190501.10362400': __('identifierSchemeCodeUnspsc190501.10362400'),
	'identifierSchemeCodeUnspsc190501.10362401': __('identifierSchemeCodeUnspsc190501.10362401'),
	'identifierSchemeCodeUnspsc190501.10362402': __('identifierSchemeCodeUnspsc190501.10362402'),
	'identifierSchemeCodeUnspsc190501.10362403': __('identifierSchemeCodeUnspsc190501.10362403'),
	'identifierSchemeCodeUnspsc190501.10362404': __('identifierSchemeCodeUnspsc190501.10362404'),
	'identifierSchemeCodeUnspsc190501.10362405': __('identifierSchemeCodeUnspsc190501.10362405'),
	'identifierSchemeCodeUnspsc190501.10362406': __('identifierSchemeCodeUnspsc190501.10362406'),
	'identifierSchemeCodeUnspsc190501.10400000': __('identifierSchemeCodeUnspsc190501.10400000'),
	'identifierSchemeCodeUnspsc190501.10401500': __('identifierSchemeCodeUnspsc190501.10401500'),
	'identifierSchemeCodeUnspsc190501.10401501': __('identifierSchemeCodeUnspsc190501.10401501'),
	'identifierSchemeCodeUnspsc190501.10401502': __('identifierSchemeCodeUnspsc190501.10401502'),
	'identifierSchemeCodeUnspsc190501.10401503': __('identifierSchemeCodeUnspsc190501.10401503'),
	'identifierSchemeCodeUnspsc190501.10401504': __('identifierSchemeCodeUnspsc190501.10401504'),
	'identifierSchemeCodeUnspsc190501.10401505': __('identifierSchemeCodeUnspsc190501.10401505'),
	'identifierSchemeCodeUnspsc190501.10401506': __('identifierSchemeCodeUnspsc190501.10401506'),
	'identifierSchemeCodeUnspsc190501.10401507': __('identifierSchemeCodeUnspsc190501.10401507'),
	'identifierSchemeCodeUnspsc190501.10401508': __('identifierSchemeCodeUnspsc190501.10401508'),
	'identifierSchemeCodeUnspsc190501.10401509': __('identifierSchemeCodeUnspsc190501.10401509'),
	'identifierSchemeCodeUnspsc190501.10401510': __('identifierSchemeCodeUnspsc190501.10401510'),
	'identifierSchemeCodeUnspsc190501.10401511': __('identifierSchemeCodeUnspsc190501.10401511'),
	'identifierSchemeCodeUnspsc190501.10401512': __('identifierSchemeCodeUnspsc190501.10401512'),
	'identifierSchemeCodeUnspsc190501.10401513': __('identifierSchemeCodeUnspsc190501.10401513'),
	'identifierSchemeCodeUnspsc190501.10401514': __('identifierSchemeCodeUnspsc190501.10401514'),
	'identifierSchemeCodeUnspsc190501.10401515': __('identifierSchemeCodeUnspsc190501.10401515'),
	'identifierSchemeCodeUnspsc190501.10401516': __('identifierSchemeCodeUnspsc190501.10401516'),
	'identifierSchemeCodeUnspsc190501.10401517': __('identifierSchemeCodeUnspsc190501.10401517'),
	'identifierSchemeCodeUnspsc190501.10401518': __('identifierSchemeCodeUnspsc190501.10401518'),
	'identifierSchemeCodeUnspsc190501.10401519': __('identifierSchemeCodeUnspsc190501.10401519'),
	'identifierSchemeCodeUnspsc190501.10401520': __('identifierSchemeCodeUnspsc190501.10401520'),
	'identifierSchemeCodeUnspsc190501.10401521': __('identifierSchemeCodeUnspsc190501.10401521'),
	'identifierSchemeCodeUnspsc190501.10401522': __('identifierSchemeCodeUnspsc190501.10401522'),
	'identifierSchemeCodeUnspsc190501.10401523': __('identifierSchemeCodeUnspsc190501.10401523'),
	'identifierSchemeCodeUnspsc190501.10401600': __('identifierSchemeCodeUnspsc190501.10401600'),
	'identifierSchemeCodeUnspsc190501.10401601': __('identifierSchemeCodeUnspsc190501.10401601'),
	'identifierSchemeCodeUnspsc190501.10401602': __('identifierSchemeCodeUnspsc190501.10401602'),
	'identifierSchemeCodeUnspsc190501.10401603': __('identifierSchemeCodeUnspsc190501.10401603'),
	'identifierSchemeCodeUnspsc190501.10401604': __('identifierSchemeCodeUnspsc190501.10401604'),
	'identifierSchemeCodeUnspsc190501.10401605': __('identifierSchemeCodeUnspsc190501.10401605'),
	'identifierSchemeCodeUnspsc190501.10401606': __('identifierSchemeCodeUnspsc190501.10401606'),
	'identifierSchemeCodeUnspsc190501.10401607': __('identifierSchemeCodeUnspsc190501.10401607'),
	'identifierSchemeCodeUnspsc190501.10401608': __('identifierSchemeCodeUnspsc190501.10401608'),
	'identifierSchemeCodeUnspsc190501.10401609': __('identifierSchemeCodeUnspsc190501.10401609'),
	'identifierSchemeCodeUnspsc190501.10401610': __('identifierSchemeCodeUnspsc190501.10401610'),
	'identifierSchemeCodeUnspsc190501.10401700': __('identifierSchemeCodeUnspsc190501.10401700'),
	'identifierSchemeCodeUnspsc190501.10401701': __('identifierSchemeCodeUnspsc190501.10401701'),
	'identifierSchemeCodeUnspsc190501.10401702': __('identifierSchemeCodeUnspsc190501.10401702'),
	'identifierSchemeCodeUnspsc190501.10401703': __('identifierSchemeCodeUnspsc190501.10401703'),
	'identifierSchemeCodeUnspsc190501.10401704': __('identifierSchemeCodeUnspsc190501.10401704'),
	'identifierSchemeCodeUnspsc190501.10401705': __('identifierSchemeCodeUnspsc190501.10401705'),
	'identifierSchemeCodeUnspsc190501.10401706': __('identifierSchemeCodeUnspsc190501.10401706'),
	'identifierSchemeCodeUnspsc190501.10401707': __('identifierSchemeCodeUnspsc190501.10401707'),
	'identifierSchemeCodeUnspsc190501.10401708': __('identifierSchemeCodeUnspsc190501.10401708'),
	'identifierSchemeCodeUnspsc190501.10401709': __('identifierSchemeCodeUnspsc190501.10401709'),
	'identifierSchemeCodeUnspsc190501.10401710': __('identifierSchemeCodeUnspsc190501.10401710'),
	'identifierSchemeCodeUnspsc190501.10401711': __('identifierSchemeCodeUnspsc190501.10401711'),
	'identifierSchemeCodeUnspsc190501.10401712': __('identifierSchemeCodeUnspsc190501.10401712'),
	'identifierSchemeCodeUnspsc190501.10401713': __('identifierSchemeCodeUnspsc190501.10401713'),
	'identifierSchemeCodeUnspsc190501.10401714': __('identifierSchemeCodeUnspsc190501.10401714'),
	'identifierSchemeCodeUnspsc190501.10401715': __('identifierSchemeCodeUnspsc190501.10401715'),
	'identifierSchemeCodeUnspsc190501.10401716': __('identifierSchemeCodeUnspsc190501.10401716'),
	'identifierSchemeCodeUnspsc190501.10401717': __('identifierSchemeCodeUnspsc190501.10401717'),
	'identifierSchemeCodeUnspsc190501.10401718': __('identifierSchemeCodeUnspsc190501.10401718'),
	'identifierSchemeCodeUnspsc190501.10401719': __('identifierSchemeCodeUnspsc190501.10401719'),
	'identifierSchemeCodeUnspsc190501.10401720': __('identifierSchemeCodeUnspsc190501.10401720'),
	'identifierSchemeCodeUnspsc190501.10401721': __('identifierSchemeCodeUnspsc190501.10401721'),
	'identifierSchemeCodeUnspsc190501.10401722': __('identifierSchemeCodeUnspsc190501.10401722'),
	'identifierSchemeCodeUnspsc190501.10401723': __('identifierSchemeCodeUnspsc190501.10401723'),
	'identifierSchemeCodeUnspsc190501.10401724': __('identifierSchemeCodeUnspsc190501.10401724'),
	'identifierSchemeCodeUnspsc190501.10401725': __('identifierSchemeCodeUnspsc190501.10401725'),
	'identifierSchemeCodeUnspsc190501.10401726': __('identifierSchemeCodeUnspsc190501.10401726'),
	'identifierSchemeCodeUnspsc190501.10401727': __('identifierSchemeCodeUnspsc190501.10401727'),
	'identifierSchemeCodeUnspsc190501.10401728': __('identifierSchemeCodeUnspsc190501.10401728'),
	'identifierSchemeCodeUnspsc190501.10401729': __('identifierSchemeCodeUnspsc190501.10401729'),
	'identifierSchemeCodeUnspsc190501.10401730': __('identifierSchemeCodeUnspsc190501.10401730'),
	'identifierSchemeCodeUnspsc190501.10401731': __('identifierSchemeCodeUnspsc190501.10401731'),
	'identifierSchemeCodeUnspsc190501.10401732': __('identifierSchemeCodeUnspsc190501.10401732'),
	'identifierSchemeCodeUnspsc190501.10401733': __('identifierSchemeCodeUnspsc190501.10401733'),
	'identifierSchemeCodeUnspsc190501.10401734': __('identifierSchemeCodeUnspsc190501.10401734'),
	'identifierSchemeCodeUnspsc190501.10401735': __('identifierSchemeCodeUnspsc190501.10401735'),
	'identifierSchemeCodeUnspsc190501.10401736': __('identifierSchemeCodeUnspsc190501.10401736'),
	'identifierSchemeCodeUnspsc190501.10401737': __('identifierSchemeCodeUnspsc190501.10401737'),
	'identifierSchemeCodeUnspsc190501.10401738': __('identifierSchemeCodeUnspsc190501.10401738'),
	'identifierSchemeCodeUnspsc190501.10401739': __('identifierSchemeCodeUnspsc190501.10401739'),
	'identifierSchemeCodeUnspsc190501.10401740': __('identifierSchemeCodeUnspsc190501.10401740'),
	'identifierSchemeCodeUnspsc190501.10401741': __('identifierSchemeCodeUnspsc190501.10401741'),
	'identifierSchemeCodeUnspsc190501.10401742': __('identifierSchemeCodeUnspsc190501.10401742'),
	'identifierSchemeCodeUnspsc190501.10401743': __('identifierSchemeCodeUnspsc190501.10401743'),
	'identifierSchemeCodeUnspsc190501.10401744': __('identifierSchemeCodeUnspsc190501.10401744'),
	'identifierSchemeCodeUnspsc190501.10401745': __('identifierSchemeCodeUnspsc190501.10401745'),
	'identifierSchemeCodeUnspsc190501.10401746': __('identifierSchemeCodeUnspsc190501.10401746'),
	'identifierSchemeCodeUnspsc190501.10401800': __('identifierSchemeCodeUnspsc190501.10401800'),
	'identifierSchemeCodeUnspsc190501.10401801': __('identifierSchemeCodeUnspsc190501.10401801'),
	'identifierSchemeCodeUnspsc190501.10401802': __('identifierSchemeCodeUnspsc190501.10401802'),
	'identifierSchemeCodeUnspsc190501.10401803': __('identifierSchemeCodeUnspsc190501.10401803'),
	'identifierSchemeCodeUnspsc190501.10401804': __('identifierSchemeCodeUnspsc190501.10401804'),
	'identifierSchemeCodeUnspsc190501.10401805': __('identifierSchemeCodeUnspsc190501.10401805'),
	'identifierSchemeCodeUnspsc190501.10401806': __('identifierSchemeCodeUnspsc190501.10401806'),
	'identifierSchemeCodeUnspsc190501.10401807': __('identifierSchemeCodeUnspsc190501.10401807'),
	'identifierSchemeCodeUnspsc190501.10401808': __('identifierSchemeCodeUnspsc190501.10401808'),
	'identifierSchemeCodeUnspsc190501.10401809': __('identifierSchemeCodeUnspsc190501.10401809'),
	'identifierSchemeCodeUnspsc190501.10401810': __('identifierSchemeCodeUnspsc190501.10401810'),
	'identifierSchemeCodeUnspsc190501.10401811': __('identifierSchemeCodeUnspsc190501.10401811'),
	'identifierSchemeCodeUnspsc190501.10401812': __('identifierSchemeCodeUnspsc190501.10401812'),
	'identifierSchemeCodeUnspsc190501.10401813': __('identifierSchemeCodeUnspsc190501.10401813'),
	'identifierSchemeCodeUnspsc190501.10401900': __('identifierSchemeCodeUnspsc190501.10401900'),
	'identifierSchemeCodeUnspsc190501.10401901': __('identifierSchemeCodeUnspsc190501.10401901'),
	'identifierSchemeCodeUnspsc190501.10401902': __('identifierSchemeCodeUnspsc190501.10401902'),
	'identifierSchemeCodeUnspsc190501.10401903': __('identifierSchemeCodeUnspsc190501.10401903'),
	'identifierSchemeCodeUnspsc190501.10401904': __('identifierSchemeCodeUnspsc190501.10401904'),
	'identifierSchemeCodeUnspsc190501.10401905': __('identifierSchemeCodeUnspsc190501.10401905'),
	'identifierSchemeCodeUnspsc190501.10401906': __('identifierSchemeCodeUnspsc190501.10401906'),
	'identifierSchemeCodeUnspsc190501.10401907': __('identifierSchemeCodeUnspsc190501.10401907'),
	'identifierSchemeCodeUnspsc190501.10401908': __('identifierSchemeCodeUnspsc190501.10401908'),
	'identifierSchemeCodeUnspsc190501.10401909': __('identifierSchemeCodeUnspsc190501.10401909'),
	'identifierSchemeCodeUnspsc190501.10401910': __('identifierSchemeCodeUnspsc190501.10401910'),
	'identifierSchemeCodeUnspsc190501.10401911': __('identifierSchemeCodeUnspsc190501.10401911'),
	'identifierSchemeCodeUnspsc190501.10401912': __('identifierSchemeCodeUnspsc190501.10401912'),
	'identifierSchemeCodeUnspsc190501.10401913': __('identifierSchemeCodeUnspsc190501.10401913'),
	'identifierSchemeCodeUnspsc190501.10401914': __('identifierSchemeCodeUnspsc190501.10401914'),
	'identifierSchemeCodeUnspsc190501.10401915': __('identifierSchemeCodeUnspsc190501.10401915'),
	'identifierSchemeCodeUnspsc190501.10401916': __('identifierSchemeCodeUnspsc190501.10401916'),
	'identifierSchemeCodeUnspsc190501.10401917': __('identifierSchemeCodeUnspsc190501.10401917'),
	'identifierSchemeCodeUnspsc190501.10401918': __('identifierSchemeCodeUnspsc190501.10401918'),
	'identifierSchemeCodeUnspsc190501.10401919': __('identifierSchemeCodeUnspsc190501.10401919'),
	'identifierSchemeCodeUnspsc190501.10401920': __('identifierSchemeCodeUnspsc190501.10401920'),
	'identifierSchemeCodeUnspsc190501.10401921': __('identifierSchemeCodeUnspsc190501.10401921'),
	'identifierSchemeCodeUnspsc190501.10401922': __('identifierSchemeCodeUnspsc190501.10401922'),
	'identifierSchemeCodeUnspsc190501.10401923': __('identifierSchemeCodeUnspsc190501.10401923'),
	'identifierSchemeCodeUnspsc190501.10401924': __('identifierSchemeCodeUnspsc190501.10401924'),
	'identifierSchemeCodeUnspsc190501.10401925': __('identifierSchemeCodeUnspsc190501.10401925'),
	'identifierSchemeCodeUnspsc190501.10401926': __('identifierSchemeCodeUnspsc190501.10401926'),
	'identifierSchemeCodeUnspsc190501.10401927': __('identifierSchemeCodeUnspsc190501.10401927'),
	'identifierSchemeCodeUnspsc190501.10401928': __('identifierSchemeCodeUnspsc190501.10401928'),
	'identifierSchemeCodeUnspsc190501.10401929': __('identifierSchemeCodeUnspsc190501.10401929'),
	'identifierSchemeCodeUnspsc190501.10401930': __('identifierSchemeCodeUnspsc190501.10401930'),
	'identifierSchemeCodeUnspsc190501.10401931': __('identifierSchemeCodeUnspsc190501.10401931'),
	'identifierSchemeCodeUnspsc190501.10401932': __('identifierSchemeCodeUnspsc190501.10401932'),
	'identifierSchemeCodeUnspsc190501.10401933': __('identifierSchemeCodeUnspsc190501.10401933'),
	'identifierSchemeCodeUnspsc190501.10401934': __('identifierSchemeCodeUnspsc190501.10401934'),
	'identifierSchemeCodeUnspsc190501.10401935': __('identifierSchemeCodeUnspsc190501.10401935'),
	'identifierSchemeCodeUnspsc190501.10401936': __('identifierSchemeCodeUnspsc190501.10401936'),
	'identifierSchemeCodeUnspsc190501.10401937': __('identifierSchemeCodeUnspsc190501.10401937'),
	'identifierSchemeCodeUnspsc190501.10401938': __('identifierSchemeCodeUnspsc190501.10401938'),
	'identifierSchemeCodeUnspsc190501.10401939': __('identifierSchemeCodeUnspsc190501.10401939'),
	'identifierSchemeCodeUnspsc190501.10401940': __('identifierSchemeCodeUnspsc190501.10401940'),
	'identifierSchemeCodeUnspsc190501.10401941': __('identifierSchemeCodeUnspsc190501.10401941'),
	'identifierSchemeCodeUnspsc190501.10401942': __('identifierSchemeCodeUnspsc190501.10401942'),
	'identifierSchemeCodeUnspsc190501.10401943': __('identifierSchemeCodeUnspsc190501.10401943'),
	'identifierSchemeCodeUnspsc190501.10401944': __('identifierSchemeCodeUnspsc190501.10401944'),
	'identifierSchemeCodeUnspsc190501.10401945': __('identifierSchemeCodeUnspsc190501.10401945'),
	'identifierSchemeCodeUnspsc190501.10401946': __('identifierSchemeCodeUnspsc190501.10401946'),
	'identifierSchemeCodeUnspsc190501.10401947': __('identifierSchemeCodeUnspsc190501.10401947'),
	'identifierSchemeCodeUnspsc190501.10401948': __('identifierSchemeCodeUnspsc190501.10401948'),
	'identifierSchemeCodeUnspsc190501.10401949': __('identifierSchemeCodeUnspsc190501.10401949'),
	'identifierSchemeCodeUnspsc190501.10401950': __('identifierSchemeCodeUnspsc190501.10401950'),
	'identifierSchemeCodeUnspsc190501.10401951': __('identifierSchemeCodeUnspsc190501.10401951'),
	'identifierSchemeCodeUnspsc190501.10401952': __('identifierSchemeCodeUnspsc190501.10401952'),
	'identifierSchemeCodeUnspsc190501.10401953': __('identifierSchemeCodeUnspsc190501.10401953'),
	'identifierSchemeCodeUnspsc190501.10401954': __('identifierSchemeCodeUnspsc190501.10401954'),
	'identifierSchemeCodeUnspsc190501.10401955': __('identifierSchemeCodeUnspsc190501.10401955'),
	'identifierSchemeCodeUnspsc190501.10401956': __('identifierSchemeCodeUnspsc190501.10401956'),
	'identifierSchemeCodeUnspsc190501.10401957': __('identifierSchemeCodeUnspsc190501.10401957'),
	'identifierSchemeCodeUnspsc190501.10401958': __('identifierSchemeCodeUnspsc190501.10401958'),
	'identifierSchemeCodeUnspsc190501.10401959': __('identifierSchemeCodeUnspsc190501.10401959'),
	'identifierSchemeCodeUnspsc190501.10401960': __('identifierSchemeCodeUnspsc190501.10401960'),
	'identifierSchemeCodeUnspsc190501.10401961': __('identifierSchemeCodeUnspsc190501.10401961'),
	'identifierSchemeCodeUnspsc190501.10401962': __('identifierSchemeCodeUnspsc190501.10401962'),
	'identifierSchemeCodeUnspsc190501.10402000': __('identifierSchemeCodeUnspsc190501.10402000'),
	'identifierSchemeCodeUnspsc190501.10402001': __('identifierSchemeCodeUnspsc190501.10402001'),
	'identifierSchemeCodeUnspsc190501.10402002': __('identifierSchemeCodeUnspsc190501.10402002'),
	'identifierSchemeCodeUnspsc190501.10402003': __('identifierSchemeCodeUnspsc190501.10402003'),
	'identifierSchemeCodeUnspsc190501.10402004': __('identifierSchemeCodeUnspsc190501.10402004'),
	'identifierSchemeCodeUnspsc190501.10402005': __('identifierSchemeCodeUnspsc190501.10402005'),
	'identifierSchemeCodeUnspsc190501.10402006': __('identifierSchemeCodeUnspsc190501.10402006'),
	'identifierSchemeCodeUnspsc190501.10402007': __('identifierSchemeCodeUnspsc190501.10402007'),
	'identifierSchemeCodeUnspsc190501.10402008': __('identifierSchemeCodeUnspsc190501.10402008'),
	'identifierSchemeCodeUnspsc190501.10402009': __('identifierSchemeCodeUnspsc190501.10402009'),
	'identifierSchemeCodeUnspsc190501.10402010': __('identifierSchemeCodeUnspsc190501.10402010'),
	'identifierSchemeCodeUnspsc190501.10402011': __('identifierSchemeCodeUnspsc190501.10402011'),
	'identifierSchemeCodeUnspsc190501.10402012': __('identifierSchemeCodeUnspsc190501.10402012'),
	'identifierSchemeCodeUnspsc190501.10402013': __('identifierSchemeCodeUnspsc190501.10402013'),
	'identifierSchemeCodeUnspsc190501.10402014': __('identifierSchemeCodeUnspsc190501.10402014'),
	'identifierSchemeCodeUnspsc190501.10402015': __('identifierSchemeCodeUnspsc190501.10402015'),
	'identifierSchemeCodeUnspsc190501.10402016': __('identifierSchemeCodeUnspsc190501.10402016'),
	'identifierSchemeCodeUnspsc190501.10402017': __('identifierSchemeCodeUnspsc190501.10402017'),
	'identifierSchemeCodeUnspsc190501.10402018': __('identifierSchemeCodeUnspsc190501.10402018'),
	'identifierSchemeCodeUnspsc190501.10402019': __('identifierSchemeCodeUnspsc190501.10402019'),
	'identifierSchemeCodeUnspsc190501.10402020': __('identifierSchemeCodeUnspsc190501.10402020'),
	'identifierSchemeCodeUnspsc190501.10402021': __('identifierSchemeCodeUnspsc190501.10402021'),
	'identifierSchemeCodeUnspsc190501.10402022': __('identifierSchemeCodeUnspsc190501.10402022'),
	'identifierSchemeCodeUnspsc190501.10402023': __('identifierSchemeCodeUnspsc190501.10402023'),
	'identifierSchemeCodeUnspsc190501.10402024': __('identifierSchemeCodeUnspsc190501.10402024'),
	'identifierSchemeCodeUnspsc190501.10402025': __('identifierSchemeCodeUnspsc190501.10402025'),
	'identifierSchemeCodeUnspsc190501.10402026': __('identifierSchemeCodeUnspsc190501.10402026'),
	'identifierSchemeCodeUnspsc190501.10402027': __('identifierSchemeCodeUnspsc190501.10402027'),
	'identifierSchemeCodeUnspsc190501.10402028': __('identifierSchemeCodeUnspsc190501.10402028'),
	'identifierSchemeCodeUnspsc190501.10402029': __('identifierSchemeCodeUnspsc190501.10402029'),
	'identifierSchemeCodeUnspsc190501.10402030': __('identifierSchemeCodeUnspsc190501.10402030'),
	'identifierSchemeCodeUnspsc190501.10402031': __('identifierSchemeCodeUnspsc190501.10402031'),
	'identifierSchemeCodeUnspsc190501.10402032': __('identifierSchemeCodeUnspsc190501.10402032'),
	'identifierSchemeCodeUnspsc190501.10402033': __('identifierSchemeCodeUnspsc190501.10402033'),
	'identifierSchemeCodeUnspsc190501.10402034': __('identifierSchemeCodeUnspsc190501.10402034'),
	'identifierSchemeCodeUnspsc190501.10402035': __('identifierSchemeCodeUnspsc190501.10402035'),
	'identifierSchemeCodeUnspsc190501.10402036': __('identifierSchemeCodeUnspsc190501.10402036'),
	'identifierSchemeCodeUnspsc190501.10402037': __('identifierSchemeCodeUnspsc190501.10402037'),
	'identifierSchemeCodeUnspsc190501.10402100': __('identifierSchemeCodeUnspsc190501.10402100'),
	'identifierSchemeCodeUnspsc190501.10402101': __('identifierSchemeCodeUnspsc190501.10402101'),
	'identifierSchemeCodeUnspsc190501.10402102': __('identifierSchemeCodeUnspsc190501.10402102'),
	'identifierSchemeCodeUnspsc190501.10402103': __('identifierSchemeCodeUnspsc190501.10402103'),
	'identifierSchemeCodeUnspsc190501.10402104': __('identifierSchemeCodeUnspsc190501.10402104'),
	'identifierSchemeCodeUnspsc190501.10402105': __('identifierSchemeCodeUnspsc190501.10402105'),
	'identifierSchemeCodeUnspsc190501.10402106': __('identifierSchemeCodeUnspsc190501.10402106'),
	'identifierSchemeCodeUnspsc190501.10402107': __('identifierSchemeCodeUnspsc190501.10402107'),
	'identifierSchemeCodeUnspsc190501.10402108': __('identifierSchemeCodeUnspsc190501.10402108'),
	'identifierSchemeCodeUnspsc190501.10402109': __('identifierSchemeCodeUnspsc190501.10402109'),
	'identifierSchemeCodeUnspsc190501.10402110': __('identifierSchemeCodeUnspsc190501.10402110'),
	'identifierSchemeCodeUnspsc190501.10402111': __('identifierSchemeCodeUnspsc190501.10402111'),
	'identifierSchemeCodeUnspsc190501.10402112': __('identifierSchemeCodeUnspsc190501.10402112'),
	'identifierSchemeCodeUnspsc190501.10402113': __('identifierSchemeCodeUnspsc190501.10402113'),
	'identifierSchemeCodeUnspsc190501.10402114': __('identifierSchemeCodeUnspsc190501.10402114'),
	'identifierSchemeCodeUnspsc190501.10402115': __('identifierSchemeCodeUnspsc190501.10402115'),
	'identifierSchemeCodeUnspsc190501.10402116': __('identifierSchemeCodeUnspsc190501.10402116'),
	'identifierSchemeCodeUnspsc190501.10402117': __('identifierSchemeCodeUnspsc190501.10402117'),
	'identifierSchemeCodeUnspsc190501.10402118': __('identifierSchemeCodeUnspsc190501.10402118'),
	'identifierSchemeCodeUnspsc190501.10402119': __('identifierSchemeCodeUnspsc190501.10402119'),
	'identifierSchemeCodeUnspsc190501.10402120': __('identifierSchemeCodeUnspsc190501.10402120'),
	'identifierSchemeCodeUnspsc190501.10402121': __('identifierSchemeCodeUnspsc190501.10402121'),
	'identifierSchemeCodeUnspsc190501.10402122': __('identifierSchemeCodeUnspsc190501.10402122'),
	'identifierSchemeCodeUnspsc190501.10402123': __('identifierSchemeCodeUnspsc190501.10402123'),
	'identifierSchemeCodeUnspsc190501.10402124': __('identifierSchemeCodeUnspsc190501.10402124'),
	'identifierSchemeCodeUnspsc190501.10402125': __('identifierSchemeCodeUnspsc190501.10402125'),
	'identifierSchemeCodeUnspsc190501.10402126': __('identifierSchemeCodeUnspsc190501.10402126'),
	'identifierSchemeCodeUnspsc190501.10402127': __('identifierSchemeCodeUnspsc190501.10402127'),
	'identifierSchemeCodeUnspsc190501.10402128': __('identifierSchemeCodeUnspsc190501.10402128'),
	'identifierSchemeCodeUnspsc190501.10402129': __('identifierSchemeCodeUnspsc190501.10402129'),
	'identifierSchemeCodeUnspsc190501.10402130': __('identifierSchemeCodeUnspsc190501.10402130'),
	'identifierSchemeCodeUnspsc190501.10402131': __('identifierSchemeCodeUnspsc190501.10402131'),
	'identifierSchemeCodeUnspsc190501.10402132': __('identifierSchemeCodeUnspsc190501.10402132'),
	'identifierSchemeCodeUnspsc190501.10402133': __('identifierSchemeCodeUnspsc190501.10402133'),
	'identifierSchemeCodeUnspsc190501.10402134': __('identifierSchemeCodeUnspsc190501.10402134'),
	'identifierSchemeCodeUnspsc190501.10402135': __('identifierSchemeCodeUnspsc190501.10402135'),
	'identifierSchemeCodeUnspsc190501.10402136': __('identifierSchemeCodeUnspsc190501.10402136'),
	'identifierSchemeCodeUnspsc190501.10402137': __('identifierSchemeCodeUnspsc190501.10402137'),
	'identifierSchemeCodeUnspsc190501.10402138': __('identifierSchemeCodeUnspsc190501.10402138'),
	'identifierSchemeCodeUnspsc190501.10402139': __('identifierSchemeCodeUnspsc190501.10402139'),
	'identifierSchemeCodeUnspsc190501.10402140': __('identifierSchemeCodeUnspsc190501.10402140'),
	'identifierSchemeCodeUnspsc190501.10402141': __('identifierSchemeCodeUnspsc190501.10402141'),
	'identifierSchemeCodeUnspsc190501.10402142': __('identifierSchemeCodeUnspsc190501.10402142'),
	'identifierSchemeCodeUnspsc190501.10402143': __('identifierSchemeCodeUnspsc190501.10402143'),
	'identifierSchemeCodeUnspsc190501.10402144': __('identifierSchemeCodeUnspsc190501.10402144'),
	'identifierSchemeCodeUnspsc190501.10402145': __('identifierSchemeCodeUnspsc190501.10402145'),
	'identifierSchemeCodeUnspsc190501.10402146': __('identifierSchemeCodeUnspsc190501.10402146'),
	'identifierSchemeCodeUnspsc190501.10402147': __('identifierSchemeCodeUnspsc190501.10402147'),
	'identifierSchemeCodeUnspsc190501.10402148': __('identifierSchemeCodeUnspsc190501.10402148'),
	'identifierSchemeCodeUnspsc190501.10402149': __('identifierSchemeCodeUnspsc190501.10402149'),
	'identifierSchemeCodeUnspsc190501.10402150': __('identifierSchemeCodeUnspsc190501.10402150'),
	'identifierSchemeCodeUnspsc190501.10402151': __('identifierSchemeCodeUnspsc190501.10402151'),
	'identifierSchemeCodeUnspsc190501.10402152': __('identifierSchemeCodeUnspsc190501.10402152'),
	'identifierSchemeCodeUnspsc190501.10402153': __('identifierSchemeCodeUnspsc190501.10402153'),
	'identifierSchemeCodeUnspsc190501.10402154': __('identifierSchemeCodeUnspsc190501.10402154'),
	'identifierSchemeCodeUnspsc190501.10402155': __('identifierSchemeCodeUnspsc190501.10402155'),
	'identifierSchemeCodeUnspsc190501.10402156': __('identifierSchemeCodeUnspsc190501.10402156'),
	'identifierSchemeCodeUnspsc190501.10402157': __('identifierSchemeCodeUnspsc190501.10402157'),
	'identifierSchemeCodeUnspsc190501.10402158': __('identifierSchemeCodeUnspsc190501.10402158'),
	'identifierSchemeCodeUnspsc190501.10402159': __('identifierSchemeCodeUnspsc190501.10402159'),
	'identifierSchemeCodeUnspsc190501.10402160': __('identifierSchemeCodeUnspsc190501.10402160'),
	'identifierSchemeCodeUnspsc190501.10402161': __('identifierSchemeCodeUnspsc190501.10402161'),
	'identifierSchemeCodeUnspsc190501.10402162': __('identifierSchemeCodeUnspsc190501.10402162'),
	'identifierSchemeCodeUnspsc190501.10402163': __('identifierSchemeCodeUnspsc190501.10402163'),
	'identifierSchemeCodeUnspsc190501.10402164': __('identifierSchemeCodeUnspsc190501.10402164'),
	'identifierSchemeCodeUnspsc190501.10402165': __('identifierSchemeCodeUnspsc190501.10402165'),
	'identifierSchemeCodeUnspsc190501.10402166': __('identifierSchemeCodeUnspsc190501.10402166'),
	'identifierSchemeCodeUnspsc190501.10402167': __('identifierSchemeCodeUnspsc190501.10402167'),
	'identifierSchemeCodeUnspsc190501.10402168': __('identifierSchemeCodeUnspsc190501.10402168'),
	'identifierSchemeCodeUnspsc190501.10402169': __('identifierSchemeCodeUnspsc190501.10402169'),
	'identifierSchemeCodeUnspsc190501.10402170': __('identifierSchemeCodeUnspsc190501.10402170'),
	'identifierSchemeCodeUnspsc190501.10402171': __('identifierSchemeCodeUnspsc190501.10402171'),
	'identifierSchemeCodeUnspsc190501.10402172': __('identifierSchemeCodeUnspsc190501.10402172'),
	'identifierSchemeCodeUnspsc190501.10402173': __('identifierSchemeCodeUnspsc190501.10402173'),
	'identifierSchemeCodeUnspsc190501.10402174': __('identifierSchemeCodeUnspsc190501.10402174'),
	'identifierSchemeCodeUnspsc190501.10402175': __('identifierSchemeCodeUnspsc190501.10402175'),
	'identifierSchemeCodeUnspsc190501.10402176': __('identifierSchemeCodeUnspsc190501.10402176'),
	'identifierSchemeCodeUnspsc190501.10402177': __('identifierSchemeCodeUnspsc190501.10402177'),
	'identifierSchemeCodeUnspsc190501.10402178': __('identifierSchemeCodeUnspsc190501.10402178'),
	'identifierSchemeCodeUnspsc190501.10402179': __('identifierSchemeCodeUnspsc190501.10402179'),
	'identifierSchemeCodeUnspsc190501.10402180': __('identifierSchemeCodeUnspsc190501.10402180'),
	'identifierSchemeCodeUnspsc190501.10402181': __('identifierSchemeCodeUnspsc190501.10402181'),
	'identifierSchemeCodeUnspsc190501.10402182': __('identifierSchemeCodeUnspsc190501.10402182'),
	'identifierSchemeCodeUnspsc190501.10402183': __('identifierSchemeCodeUnspsc190501.10402183'),
	'identifierSchemeCodeUnspsc190501.10402184': __('identifierSchemeCodeUnspsc190501.10402184'),
	'identifierSchemeCodeUnspsc190501.10402185': __('identifierSchemeCodeUnspsc190501.10402185'),
	'identifierSchemeCodeUnspsc190501.10402186': __('identifierSchemeCodeUnspsc190501.10402186'),
	'identifierSchemeCodeUnspsc190501.10402200': __('identifierSchemeCodeUnspsc190501.10402200'),
	'identifierSchemeCodeUnspsc190501.10402201': __('identifierSchemeCodeUnspsc190501.10402201'),
	'identifierSchemeCodeUnspsc190501.10402202': __('identifierSchemeCodeUnspsc190501.10402202'),
	'identifierSchemeCodeUnspsc190501.10402203': __('identifierSchemeCodeUnspsc190501.10402203'),
	'identifierSchemeCodeUnspsc190501.10402204': __('identifierSchemeCodeUnspsc190501.10402204'),
	'identifierSchemeCodeUnspsc190501.10402205': __('identifierSchemeCodeUnspsc190501.10402205'),
	'identifierSchemeCodeUnspsc190501.10402206': __('identifierSchemeCodeUnspsc190501.10402206'),
	'identifierSchemeCodeUnspsc190501.10402207': __('identifierSchemeCodeUnspsc190501.10402207'),
	'identifierSchemeCodeUnspsc190501.10402208': __('identifierSchemeCodeUnspsc190501.10402208'),
	'identifierSchemeCodeUnspsc190501.10402209': __('identifierSchemeCodeUnspsc190501.10402209'),
	'identifierSchemeCodeUnspsc190501.10402210': __('identifierSchemeCodeUnspsc190501.10402210'),
	'identifierSchemeCodeUnspsc190501.10402211': __('identifierSchemeCodeUnspsc190501.10402211'),
	'identifierSchemeCodeUnspsc190501.10402212': __('identifierSchemeCodeUnspsc190501.10402212'),
	'identifierSchemeCodeUnspsc190501.10402213': __('identifierSchemeCodeUnspsc190501.10402213'),
	'identifierSchemeCodeUnspsc190501.10402214': __('identifierSchemeCodeUnspsc190501.10402214'),
	'identifierSchemeCodeUnspsc190501.10402215': __('identifierSchemeCodeUnspsc190501.10402215'),
	'identifierSchemeCodeUnspsc190501.10402216': __('identifierSchemeCodeUnspsc190501.10402216'),
	'identifierSchemeCodeUnspsc190501.10402217': __('identifierSchemeCodeUnspsc190501.10402217'),
	'identifierSchemeCodeUnspsc190501.10402218': __('identifierSchemeCodeUnspsc190501.10402218'),
	'identifierSchemeCodeUnspsc190501.10402219': __('identifierSchemeCodeUnspsc190501.10402219'),
	'identifierSchemeCodeUnspsc190501.10402220': __('identifierSchemeCodeUnspsc190501.10402220'),
	'identifierSchemeCodeUnspsc190501.10402221': __('identifierSchemeCodeUnspsc190501.10402221'),
	'identifierSchemeCodeUnspsc190501.10402222': __('identifierSchemeCodeUnspsc190501.10402222'),
	'identifierSchemeCodeUnspsc190501.10402223': __('identifierSchemeCodeUnspsc190501.10402223'),
	'identifierSchemeCodeUnspsc190501.10402224': __('identifierSchemeCodeUnspsc190501.10402224'),
	'identifierSchemeCodeUnspsc190501.10402225': __('identifierSchemeCodeUnspsc190501.10402225'),
	'identifierSchemeCodeUnspsc190501.10402226': __('identifierSchemeCodeUnspsc190501.10402226'),
	'identifierSchemeCodeUnspsc190501.10402227': __('identifierSchemeCodeUnspsc190501.10402227'),
	'identifierSchemeCodeUnspsc190501.10402228': __('identifierSchemeCodeUnspsc190501.10402228'),
	'identifierSchemeCodeUnspsc190501.10402229': __('identifierSchemeCodeUnspsc190501.10402229'),
	'identifierSchemeCodeUnspsc190501.10402230': __('identifierSchemeCodeUnspsc190501.10402230'),
	'identifierSchemeCodeUnspsc190501.10402231': __('identifierSchemeCodeUnspsc190501.10402231'),
	'identifierSchemeCodeUnspsc190501.10402232': __('identifierSchemeCodeUnspsc190501.10402232'),
	'identifierSchemeCodeUnspsc190501.10402233': __('identifierSchemeCodeUnspsc190501.10402233'),
	'identifierSchemeCodeUnspsc190501.10402234': __('identifierSchemeCodeUnspsc190501.10402234'),
	'identifierSchemeCodeUnspsc190501.10402235': __('identifierSchemeCodeUnspsc190501.10402235'),
	'identifierSchemeCodeUnspsc190501.10402236': __('identifierSchemeCodeUnspsc190501.10402236'),
	'identifierSchemeCodeUnspsc190501.10402237': __('identifierSchemeCodeUnspsc190501.10402237'),
	'identifierSchemeCodeUnspsc190501.10402238': __('identifierSchemeCodeUnspsc190501.10402238'),
	'identifierSchemeCodeUnspsc190501.10402239': __('identifierSchemeCodeUnspsc190501.10402239'),
	'identifierSchemeCodeUnspsc190501.10402240': __('identifierSchemeCodeUnspsc190501.10402240'),
	'identifierSchemeCodeUnspsc190501.10402241': __('identifierSchemeCodeUnspsc190501.10402241'),
	'identifierSchemeCodeUnspsc190501.10402300': __('identifierSchemeCodeUnspsc190501.10402300'),
	'identifierSchemeCodeUnspsc190501.10402301': __('identifierSchemeCodeUnspsc190501.10402301'),
	'identifierSchemeCodeUnspsc190501.10402302': __('identifierSchemeCodeUnspsc190501.10402302'),
	'identifierSchemeCodeUnspsc190501.10402303': __('identifierSchemeCodeUnspsc190501.10402303'),
	'identifierSchemeCodeUnspsc190501.10402304': __('identifierSchemeCodeUnspsc190501.10402304'),
	'identifierSchemeCodeUnspsc190501.10402305': __('identifierSchemeCodeUnspsc190501.10402305'),
	'identifierSchemeCodeUnspsc190501.10402306': __('identifierSchemeCodeUnspsc190501.10402306'),
	'identifierSchemeCodeUnspsc190501.10402307': __('identifierSchemeCodeUnspsc190501.10402307'),
	'identifierSchemeCodeUnspsc190501.10402308': __('identifierSchemeCodeUnspsc190501.10402308'),
	'identifierSchemeCodeUnspsc190501.10402309': __('identifierSchemeCodeUnspsc190501.10402309'),
	'identifierSchemeCodeUnspsc190501.10402310': __('identifierSchemeCodeUnspsc190501.10402310'),
	'identifierSchemeCodeUnspsc190501.10402311': __('identifierSchemeCodeUnspsc190501.10402311'),
	'identifierSchemeCodeUnspsc190501.10402312': __('identifierSchemeCodeUnspsc190501.10402312'),
	'identifierSchemeCodeUnspsc190501.10402313': __('identifierSchemeCodeUnspsc190501.10402313'),
	'identifierSchemeCodeUnspsc190501.10402314': __('identifierSchemeCodeUnspsc190501.10402314'),
	'identifierSchemeCodeUnspsc190501.10402315': __('identifierSchemeCodeUnspsc190501.10402315'),
	'identifierSchemeCodeUnspsc190501.10402316': __('identifierSchemeCodeUnspsc190501.10402316'),
	'identifierSchemeCodeUnspsc190501.10402317': __('identifierSchemeCodeUnspsc190501.10402317'),
	'identifierSchemeCodeUnspsc190501.10402318': __('identifierSchemeCodeUnspsc190501.10402318'),
	'identifierSchemeCodeUnspsc190501.10402319': __('identifierSchemeCodeUnspsc190501.10402319'),
	'identifierSchemeCodeUnspsc190501.10402320': __('identifierSchemeCodeUnspsc190501.10402320'),
	'identifierSchemeCodeUnspsc190501.10402321': __('identifierSchemeCodeUnspsc190501.10402321'),
	'identifierSchemeCodeUnspsc190501.10402322': __('identifierSchemeCodeUnspsc190501.10402322'),
	'identifierSchemeCodeUnspsc190501.10402323': __('identifierSchemeCodeUnspsc190501.10402323'),
	'identifierSchemeCodeUnspsc190501.10402324': __('identifierSchemeCodeUnspsc190501.10402324'),
	'identifierSchemeCodeUnspsc190501.10402325': __('identifierSchemeCodeUnspsc190501.10402325'),
	'identifierSchemeCodeUnspsc190501.10402326': __('identifierSchemeCodeUnspsc190501.10402326'),
	'identifierSchemeCodeUnspsc190501.10402327': __('identifierSchemeCodeUnspsc190501.10402327'),
	'identifierSchemeCodeUnspsc190501.10402328': __('identifierSchemeCodeUnspsc190501.10402328'),
	'identifierSchemeCodeUnspsc190501.10402329': __('identifierSchemeCodeUnspsc190501.10402329'),
	'identifierSchemeCodeUnspsc190501.10402330': __('identifierSchemeCodeUnspsc190501.10402330'),
	'identifierSchemeCodeUnspsc190501.10402331': __('identifierSchemeCodeUnspsc190501.10402331'),
	'identifierSchemeCodeUnspsc190501.10402332': __('identifierSchemeCodeUnspsc190501.10402332'),
	'identifierSchemeCodeUnspsc190501.10402333': __('identifierSchemeCodeUnspsc190501.10402333'),
	'identifierSchemeCodeUnspsc190501.10402334': __('identifierSchemeCodeUnspsc190501.10402334'),
	'identifierSchemeCodeUnspsc190501.10402335': __('identifierSchemeCodeUnspsc190501.10402335'),
	'identifierSchemeCodeUnspsc190501.10402336': __('identifierSchemeCodeUnspsc190501.10402336'),
	'identifierSchemeCodeUnspsc190501.10402337': __('identifierSchemeCodeUnspsc190501.10402337'),
	'identifierSchemeCodeUnspsc190501.10402338': __('identifierSchemeCodeUnspsc190501.10402338'),
	'identifierSchemeCodeUnspsc190501.10402339': __('identifierSchemeCodeUnspsc190501.10402339'),
	'identifierSchemeCodeUnspsc190501.10402340': __('identifierSchemeCodeUnspsc190501.10402340'),
	'identifierSchemeCodeUnspsc190501.10402341': __('identifierSchemeCodeUnspsc190501.10402341'),
	'identifierSchemeCodeUnspsc190501.10402342': __('identifierSchemeCodeUnspsc190501.10402342'),
	'identifierSchemeCodeUnspsc190501.10402343': __('identifierSchemeCodeUnspsc190501.10402343'),
	'identifierSchemeCodeUnspsc190501.10402344': __('identifierSchemeCodeUnspsc190501.10402344'),
	'identifierSchemeCodeUnspsc190501.10402345': __('identifierSchemeCodeUnspsc190501.10402345'),
	'identifierSchemeCodeUnspsc190501.10402346': __('identifierSchemeCodeUnspsc190501.10402346'),
	'identifierSchemeCodeUnspsc190501.10402347': __('identifierSchemeCodeUnspsc190501.10402347'),
	'identifierSchemeCodeUnspsc190501.10402348': __('identifierSchemeCodeUnspsc190501.10402348'),
	'identifierSchemeCodeUnspsc190501.10402349': __('identifierSchemeCodeUnspsc190501.10402349'),
	'identifierSchemeCodeUnspsc190501.10402350': __('identifierSchemeCodeUnspsc190501.10402350'),
	'identifierSchemeCodeUnspsc190501.10402351': __('identifierSchemeCodeUnspsc190501.10402351'),
	'identifierSchemeCodeUnspsc190501.10402352': __('identifierSchemeCodeUnspsc190501.10402352'),
	'identifierSchemeCodeUnspsc190501.10402353': __('identifierSchemeCodeUnspsc190501.10402353'),
	'identifierSchemeCodeUnspsc190501.10402354': __('identifierSchemeCodeUnspsc190501.10402354'),
	'identifierSchemeCodeUnspsc190501.10402355': __('identifierSchemeCodeUnspsc190501.10402355'),
	'identifierSchemeCodeUnspsc190501.10402356': __('identifierSchemeCodeUnspsc190501.10402356'),
	'identifierSchemeCodeUnspsc190501.10402357': __('identifierSchemeCodeUnspsc190501.10402357'),
	'identifierSchemeCodeUnspsc190501.10402358': __('identifierSchemeCodeUnspsc190501.10402358'),
	'identifierSchemeCodeUnspsc190501.10402359': __('identifierSchemeCodeUnspsc190501.10402359'),
	'identifierSchemeCodeUnspsc190501.10402360': __('identifierSchemeCodeUnspsc190501.10402360'),
	'identifierSchemeCodeUnspsc190501.10402361': __('identifierSchemeCodeUnspsc190501.10402361'),
	'identifierSchemeCodeUnspsc190501.10402362': __('identifierSchemeCodeUnspsc190501.10402362'),
	'identifierSchemeCodeUnspsc190501.10402363': __('identifierSchemeCodeUnspsc190501.10402363'),
	'identifierSchemeCodeUnspsc190501.10402364': __('identifierSchemeCodeUnspsc190501.10402364'),
	'identifierSchemeCodeUnspsc190501.10402365': __('identifierSchemeCodeUnspsc190501.10402365'),
	'identifierSchemeCodeUnspsc190501.10402366': __('identifierSchemeCodeUnspsc190501.10402366'),
	'identifierSchemeCodeUnspsc190501.10402367': __('identifierSchemeCodeUnspsc190501.10402367'),
	'identifierSchemeCodeUnspsc190501.10402368': __('identifierSchemeCodeUnspsc190501.10402368'),
	'identifierSchemeCodeUnspsc190501.10402369': __('identifierSchemeCodeUnspsc190501.10402369'),
	'identifierSchemeCodeUnspsc190501.10402370': __('identifierSchemeCodeUnspsc190501.10402370'),
	'identifierSchemeCodeUnspsc190501.10402371': __('identifierSchemeCodeUnspsc190501.10402371'),
	'identifierSchemeCodeUnspsc190501.10402372': __('identifierSchemeCodeUnspsc190501.10402372'),
	'identifierSchemeCodeUnspsc190501.10402373': __('identifierSchemeCodeUnspsc190501.10402373'),
	'identifierSchemeCodeUnspsc190501.10402374': __('identifierSchemeCodeUnspsc190501.10402374'),
	'identifierSchemeCodeUnspsc190501.10402375': __('identifierSchemeCodeUnspsc190501.10402375'),
	'identifierSchemeCodeUnspsc190501.10402376': __('identifierSchemeCodeUnspsc190501.10402376'),
	'identifierSchemeCodeUnspsc190501.10402377': __('identifierSchemeCodeUnspsc190501.10402377'),
	'identifierSchemeCodeUnspsc190501.10402378': __('identifierSchemeCodeUnspsc190501.10402378'),
	'identifierSchemeCodeUnspsc190501.10402379': __('identifierSchemeCodeUnspsc190501.10402379'),
	'identifierSchemeCodeUnspsc190501.10402380': __('identifierSchemeCodeUnspsc190501.10402380'),
	'identifierSchemeCodeUnspsc190501.10402381': __('identifierSchemeCodeUnspsc190501.10402381'),
	'identifierSchemeCodeUnspsc190501.10402382': __('identifierSchemeCodeUnspsc190501.10402382'),
	'identifierSchemeCodeUnspsc190501.10402383': __('identifierSchemeCodeUnspsc190501.10402383'),
	'identifierSchemeCodeUnspsc190501.10402384': __('identifierSchemeCodeUnspsc190501.10402384'),
	'identifierSchemeCodeUnspsc190501.10402385': __('identifierSchemeCodeUnspsc190501.10402385'),
	'identifierSchemeCodeUnspsc190501.10402386': __('identifierSchemeCodeUnspsc190501.10402386'),
	'identifierSchemeCodeUnspsc190501.10402387': __('identifierSchemeCodeUnspsc190501.10402387'),
	'identifierSchemeCodeUnspsc190501.10402388': __('identifierSchemeCodeUnspsc190501.10402388'),
	'identifierSchemeCodeUnspsc190501.10402389': __('identifierSchemeCodeUnspsc190501.10402389'),
	'identifierSchemeCodeUnspsc190501.10402390': __('identifierSchemeCodeUnspsc190501.10402390'),
	'identifierSchemeCodeUnspsc190501.10402391': __('identifierSchemeCodeUnspsc190501.10402391'),
	'identifierSchemeCodeUnspsc190501.10402392': __('identifierSchemeCodeUnspsc190501.10402392'),
	'identifierSchemeCodeUnspsc190501.10402393': __('identifierSchemeCodeUnspsc190501.10402393'),
	'identifierSchemeCodeUnspsc190501.10402400': __('identifierSchemeCodeUnspsc190501.10402400'),
	'identifierSchemeCodeUnspsc190501.10402401': __('identifierSchemeCodeUnspsc190501.10402401'),
	'identifierSchemeCodeUnspsc190501.10402402': __('identifierSchemeCodeUnspsc190501.10402402'),
	'identifierSchemeCodeUnspsc190501.10402403': __('identifierSchemeCodeUnspsc190501.10402403'),
	'identifierSchemeCodeUnspsc190501.10402404': __('identifierSchemeCodeUnspsc190501.10402404'),
	'identifierSchemeCodeUnspsc190501.10402405': __('identifierSchemeCodeUnspsc190501.10402405'),
	'identifierSchemeCodeUnspsc190501.10402406': __('identifierSchemeCodeUnspsc190501.10402406'),
	'identifierSchemeCodeUnspsc190501.10402407': __('identifierSchemeCodeUnspsc190501.10402407'),
	'identifierSchemeCodeUnspsc190501.10402408': __('identifierSchemeCodeUnspsc190501.10402408'),
	'identifierSchemeCodeUnspsc190501.10402409': __('identifierSchemeCodeUnspsc190501.10402409'),
	'identifierSchemeCodeUnspsc190501.10402410': __('identifierSchemeCodeUnspsc190501.10402410'),
	'identifierSchemeCodeUnspsc190501.10402411': __('identifierSchemeCodeUnspsc190501.10402411'),
	'identifierSchemeCodeUnspsc190501.10402412': __('identifierSchemeCodeUnspsc190501.10402412'),
	'identifierSchemeCodeUnspsc190501.10402413': __('identifierSchemeCodeUnspsc190501.10402413'),
	'identifierSchemeCodeUnspsc190501.10402414': __('identifierSchemeCodeUnspsc190501.10402414'),
	'identifierSchemeCodeUnspsc190501.10402415': __('identifierSchemeCodeUnspsc190501.10402415'),
	'identifierSchemeCodeUnspsc190501.10402416': __('identifierSchemeCodeUnspsc190501.10402416'),
	'identifierSchemeCodeUnspsc190501.10402417': __('identifierSchemeCodeUnspsc190501.10402417'),
	'identifierSchemeCodeUnspsc190501.10402418': __('identifierSchemeCodeUnspsc190501.10402418'),
	'identifierSchemeCodeUnspsc190501.10402419': __('identifierSchemeCodeUnspsc190501.10402419'),
	'identifierSchemeCodeUnspsc190501.10402420': __('identifierSchemeCodeUnspsc190501.10402420'),
	'identifierSchemeCodeUnspsc190501.10402421': __('identifierSchemeCodeUnspsc190501.10402421'),
	'identifierSchemeCodeUnspsc190501.10402422': __('identifierSchemeCodeUnspsc190501.10402422'),
	'identifierSchemeCodeUnspsc190501.10402423': __('identifierSchemeCodeUnspsc190501.10402423'),
	'identifierSchemeCodeUnspsc190501.10402424': __('identifierSchemeCodeUnspsc190501.10402424'),
	'identifierSchemeCodeUnspsc190501.10402425': __('identifierSchemeCodeUnspsc190501.10402425'),
	'identifierSchemeCodeUnspsc190501.10402426': __('identifierSchemeCodeUnspsc190501.10402426'),
	'identifierSchemeCodeUnspsc190501.10402427': __('identifierSchemeCodeUnspsc190501.10402427'),
	'identifierSchemeCodeUnspsc190501.10402428': __('identifierSchemeCodeUnspsc190501.10402428'),
	'identifierSchemeCodeUnspsc190501.10402429': __('identifierSchemeCodeUnspsc190501.10402429'),
	'identifierSchemeCodeUnspsc190501.10402430': __('identifierSchemeCodeUnspsc190501.10402430'),
	'identifierSchemeCodeUnspsc190501.10402431': __('identifierSchemeCodeUnspsc190501.10402431'),
	'identifierSchemeCodeUnspsc190501.10402432': __('identifierSchemeCodeUnspsc190501.10402432'),
	'identifierSchemeCodeUnspsc190501.10402433': __('identifierSchemeCodeUnspsc190501.10402433'),
	'identifierSchemeCodeUnspsc190501.10402434': __('identifierSchemeCodeUnspsc190501.10402434'),
	'identifierSchemeCodeUnspsc190501.10402435': __('identifierSchemeCodeUnspsc190501.10402435'),
	'identifierSchemeCodeUnspsc190501.10402436': __('identifierSchemeCodeUnspsc190501.10402436'),
	'identifierSchemeCodeUnspsc190501.10402437': __('identifierSchemeCodeUnspsc190501.10402437'),
	'identifierSchemeCodeUnspsc190501.10402438': __('identifierSchemeCodeUnspsc190501.10402438'),
	'identifierSchemeCodeUnspsc190501.10402439': __('identifierSchemeCodeUnspsc190501.10402439'),
	'identifierSchemeCodeUnspsc190501.10402440': __('identifierSchemeCodeUnspsc190501.10402440'),
	'identifierSchemeCodeUnspsc190501.10402441': __('identifierSchemeCodeUnspsc190501.10402441'),
	'identifierSchemeCodeUnspsc190501.10402442': __('identifierSchemeCodeUnspsc190501.10402442'),
	'identifierSchemeCodeUnspsc190501.10402443': __('identifierSchemeCodeUnspsc190501.10402443'),
	'identifierSchemeCodeUnspsc190501.10402444': __('identifierSchemeCodeUnspsc190501.10402444'),
	'identifierSchemeCodeUnspsc190501.10402445': __('identifierSchemeCodeUnspsc190501.10402445'),
	'identifierSchemeCodeUnspsc190501.10402446': __('identifierSchemeCodeUnspsc190501.10402446'),
	'identifierSchemeCodeUnspsc190501.10402447': __('identifierSchemeCodeUnspsc190501.10402447'),
	'identifierSchemeCodeUnspsc190501.10402448': __('identifierSchemeCodeUnspsc190501.10402448'),
	'identifierSchemeCodeUnspsc190501.10402449': __('identifierSchemeCodeUnspsc190501.10402449'),
	'identifierSchemeCodeUnspsc190501.10402450': __('identifierSchemeCodeUnspsc190501.10402450'),
	'identifierSchemeCodeUnspsc190501.10402451': __('identifierSchemeCodeUnspsc190501.10402451'),
	'identifierSchemeCodeUnspsc190501.10402452': __('identifierSchemeCodeUnspsc190501.10402452'),
	'identifierSchemeCodeUnspsc190501.10402453': __('identifierSchemeCodeUnspsc190501.10402453'),
	'identifierSchemeCodeUnspsc190501.10402454': __('identifierSchemeCodeUnspsc190501.10402454'),
	'identifierSchemeCodeUnspsc190501.10402455': __('identifierSchemeCodeUnspsc190501.10402455'),
	'identifierSchemeCodeUnspsc190501.10402456': __('identifierSchemeCodeUnspsc190501.10402456'),
	'identifierSchemeCodeUnspsc190501.10402457': __('identifierSchemeCodeUnspsc190501.10402457'),
	'identifierSchemeCodeUnspsc190501.10402458': __('identifierSchemeCodeUnspsc190501.10402458'),
	'identifierSchemeCodeUnspsc190501.10402459': __('identifierSchemeCodeUnspsc190501.10402459'),
	'identifierSchemeCodeUnspsc190501.10402460': __('identifierSchemeCodeUnspsc190501.10402460'),
	'identifierSchemeCodeUnspsc190501.10402461': __('identifierSchemeCodeUnspsc190501.10402461'),
	'identifierSchemeCodeUnspsc190501.10402462': __('identifierSchemeCodeUnspsc190501.10402462'),
	'identifierSchemeCodeUnspsc190501.10402463': __('identifierSchemeCodeUnspsc190501.10402463'),
	'identifierSchemeCodeUnspsc190501.10402464': __('identifierSchemeCodeUnspsc190501.10402464'),
	'identifierSchemeCodeUnspsc190501.10402465': __('identifierSchemeCodeUnspsc190501.10402465'),
	'identifierSchemeCodeUnspsc190501.10402466': __('identifierSchemeCodeUnspsc190501.10402466'),
	'identifierSchemeCodeUnspsc190501.10402467': __('identifierSchemeCodeUnspsc190501.10402467'),
	'identifierSchemeCodeUnspsc190501.10402468': __('identifierSchemeCodeUnspsc190501.10402468'),
	'identifierSchemeCodeUnspsc190501.10402469': __('identifierSchemeCodeUnspsc190501.10402469'),
	'identifierSchemeCodeUnspsc190501.10402470': __('identifierSchemeCodeUnspsc190501.10402470'),
	'identifierSchemeCodeUnspsc190501.10402471': __('identifierSchemeCodeUnspsc190501.10402471'),
	'identifierSchemeCodeUnspsc190501.10402472': __('identifierSchemeCodeUnspsc190501.10402472'),
	'identifierSchemeCodeUnspsc190501.10402473': __('identifierSchemeCodeUnspsc190501.10402473'),
	'identifierSchemeCodeUnspsc190501.10402474': __('identifierSchemeCodeUnspsc190501.10402474'),
	'identifierSchemeCodeUnspsc190501.10402475': __('identifierSchemeCodeUnspsc190501.10402475'),
	'identifierSchemeCodeUnspsc190501.10402476': __('identifierSchemeCodeUnspsc190501.10402476'),
	'identifierSchemeCodeUnspsc190501.10402477': __('identifierSchemeCodeUnspsc190501.10402477'),
	'identifierSchemeCodeUnspsc190501.10402478': __('identifierSchemeCodeUnspsc190501.10402478'),
	'identifierSchemeCodeUnspsc190501.10402479': __('identifierSchemeCodeUnspsc190501.10402479'),
	'identifierSchemeCodeUnspsc190501.10402480': __('identifierSchemeCodeUnspsc190501.10402480'),
	'identifierSchemeCodeUnspsc190501.10402500': __('identifierSchemeCodeUnspsc190501.10402500'),
	'identifierSchemeCodeUnspsc190501.10402501': __('identifierSchemeCodeUnspsc190501.10402501'),
	'identifierSchemeCodeUnspsc190501.10402502': __('identifierSchemeCodeUnspsc190501.10402502'),
	'identifierSchemeCodeUnspsc190501.10402503': __('identifierSchemeCodeUnspsc190501.10402503'),
	'identifierSchemeCodeUnspsc190501.10402504': __('identifierSchemeCodeUnspsc190501.10402504'),
	'identifierSchemeCodeUnspsc190501.10402505': __('identifierSchemeCodeUnspsc190501.10402505'),
	'identifierSchemeCodeUnspsc190501.10402506': __('identifierSchemeCodeUnspsc190501.10402506'),
	'identifierSchemeCodeUnspsc190501.10402507': __('identifierSchemeCodeUnspsc190501.10402507'),
	'identifierSchemeCodeUnspsc190501.10402508': __('identifierSchemeCodeUnspsc190501.10402508'),
	'identifierSchemeCodeUnspsc190501.10402509': __('identifierSchemeCodeUnspsc190501.10402509'),
	'identifierSchemeCodeUnspsc190501.10402510': __('identifierSchemeCodeUnspsc190501.10402510'),
	'identifierSchemeCodeUnspsc190501.10402600': __('identifierSchemeCodeUnspsc190501.10402600'),
	'identifierSchemeCodeUnspsc190501.10402601': __('identifierSchemeCodeUnspsc190501.10402601'),
	'identifierSchemeCodeUnspsc190501.10402602': __('identifierSchemeCodeUnspsc190501.10402602'),
	'identifierSchemeCodeUnspsc190501.10402603': __('identifierSchemeCodeUnspsc190501.10402603'),
	'identifierSchemeCodeUnspsc190501.10402604': __('identifierSchemeCodeUnspsc190501.10402604'),
	'identifierSchemeCodeUnspsc190501.10402605': __('identifierSchemeCodeUnspsc190501.10402605'),
	'identifierSchemeCodeUnspsc190501.10402606': __('identifierSchemeCodeUnspsc190501.10402606'),
	'identifierSchemeCodeUnspsc190501.10402607': __('identifierSchemeCodeUnspsc190501.10402607'),
	'identifierSchemeCodeUnspsc190501.10402608': __('identifierSchemeCodeUnspsc190501.10402608'),
	'identifierSchemeCodeUnspsc190501.10402609': __('identifierSchemeCodeUnspsc190501.10402609'),
	'identifierSchemeCodeUnspsc190501.10402610': __('identifierSchemeCodeUnspsc190501.10402610'),
	'identifierSchemeCodeUnspsc190501.10402611': __('identifierSchemeCodeUnspsc190501.10402611'),
	'identifierSchemeCodeUnspsc190501.10402612': __('identifierSchemeCodeUnspsc190501.10402612'),
	'identifierSchemeCodeUnspsc190501.10402613': __('identifierSchemeCodeUnspsc190501.10402613'),
	'identifierSchemeCodeUnspsc190501.10402614': __('identifierSchemeCodeUnspsc190501.10402614'),
	'identifierSchemeCodeUnspsc190501.10402615': __('identifierSchemeCodeUnspsc190501.10402615'),
	'identifierSchemeCodeUnspsc190501.10402616': __('identifierSchemeCodeUnspsc190501.10402616'),
	'identifierSchemeCodeUnspsc190501.10402617': __('identifierSchemeCodeUnspsc190501.10402617'),
	'identifierSchemeCodeUnspsc190501.10402618': __('identifierSchemeCodeUnspsc190501.10402618'),
	'identifierSchemeCodeUnspsc190501.10402619': __('identifierSchemeCodeUnspsc190501.10402619'),
	'identifierSchemeCodeUnspsc190501.10402620': __('identifierSchemeCodeUnspsc190501.10402620'),
	'identifierSchemeCodeUnspsc190501.10402621': __('identifierSchemeCodeUnspsc190501.10402621'),
	'identifierSchemeCodeUnspsc190501.10402622': __('identifierSchemeCodeUnspsc190501.10402622'),
	'identifierSchemeCodeUnspsc190501.10402623': __('identifierSchemeCodeUnspsc190501.10402623'),
	'identifierSchemeCodeUnspsc190501.10402624': __('identifierSchemeCodeUnspsc190501.10402624'),
	'identifierSchemeCodeUnspsc190501.10402625': __('identifierSchemeCodeUnspsc190501.10402625'),
	'identifierSchemeCodeUnspsc190501.10402626': __('identifierSchemeCodeUnspsc190501.10402626'),
	'identifierSchemeCodeUnspsc190501.10402627': __('identifierSchemeCodeUnspsc190501.10402627'),
	'identifierSchemeCodeUnspsc190501.10402628': __('identifierSchemeCodeUnspsc190501.10402628'),
	'identifierSchemeCodeUnspsc190501.10402629': __('identifierSchemeCodeUnspsc190501.10402629'),
	'identifierSchemeCodeUnspsc190501.10402630': __('identifierSchemeCodeUnspsc190501.10402630'),
	'identifierSchemeCodeUnspsc190501.10402631': __('identifierSchemeCodeUnspsc190501.10402631'),
	'identifierSchemeCodeUnspsc190501.10402632': __('identifierSchemeCodeUnspsc190501.10402632'),
	'identifierSchemeCodeUnspsc190501.10402700': __('identifierSchemeCodeUnspsc190501.10402700'),
	'identifierSchemeCodeUnspsc190501.10402701': __('identifierSchemeCodeUnspsc190501.10402701'),
	'identifierSchemeCodeUnspsc190501.10402702': __('identifierSchemeCodeUnspsc190501.10402702'),
	'identifierSchemeCodeUnspsc190501.10402703': __('identifierSchemeCodeUnspsc190501.10402703'),
	'identifierSchemeCodeUnspsc190501.10402704': __('identifierSchemeCodeUnspsc190501.10402704'),
	'identifierSchemeCodeUnspsc190501.10402705': __('identifierSchemeCodeUnspsc190501.10402705'),
	'identifierSchemeCodeUnspsc190501.10402706': __('identifierSchemeCodeUnspsc190501.10402706'),
	'identifierSchemeCodeUnspsc190501.10402707': __('identifierSchemeCodeUnspsc190501.10402707'),
	'identifierSchemeCodeUnspsc190501.10402708': __('identifierSchemeCodeUnspsc190501.10402708'),
	'identifierSchemeCodeUnspsc190501.10402709': __('identifierSchemeCodeUnspsc190501.10402709'),
	'identifierSchemeCodeUnspsc190501.10402710': __('identifierSchemeCodeUnspsc190501.10402710'),
	'identifierSchemeCodeUnspsc190501.10402711': __('identifierSchemeCodeUnspsc190501.10402711'),
	'identifierSchemeCodeUnspsc190501.10402712': __('identifierSchemeCodeUnspsc190501.10402712'),
	'identifierSchemeCodeUnspsc190501.10402713': __('identifierSchemeCodeUnspsc190501.10402713'),
	'identifierSchemeCodeUnspsc190501.10402714': __('identifierSchemeCodeUnspsc190501.10402714'),
	'identifierSchemeCodeUnspsc190501.10402715': __('identifierSchemeCodeUnspsc190501.10402715'),
	'identifierSchemeCodeUnspsc190501.10402716': __('identifierSchemeCodeUnspsc190501.10402716'),
	'identifierSchemeCodeUnspsc190501.10402717': __('identifierSchemeCodeUnspsc190501.10402717'),
	'identifierSchemeCodeUnspsc190501.10402718': __('identifierSchemeCodeUnspsc190501.10402718'),
	'identifierSchemeCodeUnspsc190501.10402719': __('identifierSchemeCodeUnspsc190501.10402719'),
	'identifierSchemeCodeUnspsc190501.10402720': __('identifierSchemeCodeUnspsc190501.10402720'),
	'identifierSchemeCodeUnspsc190501.10402721': __('identifierSchemeCodeUnspsc190501.10402721'),
	'identifierSchemeCodeUnspsc190501.10402722': __('identifierSchemeCodeUnspsc190501.10402722'),
	'identifierSchemeCodeUnspsc190501.10402723': __('identifierSchemeCodeUnspsc190501.10402723'),
	'identifierSchemeCodeUnspsc190501.10402724': __('identifierSchemeCodeUnspsc190501.10402724'),
	'identifierSchemeCodeUnspsc190501.10402725': __('identifierSchemeCodeUnspsc190501.10402725'),
	'identifierSchemeCodeUnspsc190501.10402726': __('identifierSchemeCodeUnspsc190501.10402726'),
	'identifierSchemeCodeUnspsc190501.10402727': __('identifierSchemeCodeUnspsc190501.10402727'),
	'identifierSchemeCodeUnspsc190501.10402728': __('identifierSchemeCodeUnspsc190501.10402728'),
	'identifierSchemeCodeUnspsc190501.10402729': __('identifierSchemeCodeUnspsc190501.10402729'),
	'identifierSchemeCodeUnspsc190501.10402730': __('identifierSchemeCodeUnspsc190501.10402730'),
	'identifierSchemeCodeUnspsc190501.10402731': __('identifierSchemeCodeUnspsc190501.10402731'),
	'identifierSchemeCodeUnspsc190501.10402732': __('identifierSchemeCodeUnspsc190501.10402732'),
	'identifierSchemeCodeUnspsc190501.10402733': __('identifierSchemeCodeUnspsc190501.10402733'),
	'identifierSchemeCodeUnspsc190501.10402734': __('identifierSchemeCodeUnspsc190501.10402734'),
	'identifierSchemeCodeUnspsc190501.10402735': __('identifierSchemeCodeUnspsc190501.10402735'),
	'identifierSchemeCodeUnspsc190501.10402736': __('identifierSchemeCodeUnspsc190501.10402736'),
	'identifierSchemeCodeUnspsc190501.10402737': __('identifierSchemeCodeUnspsc190501.10402737'),
	'identifierSchemeCodeUnspsc190501.10402738': __('identifierSchemeCodeUnspsc190501.10402738'),
	'identifierSchemeCodeUnspsc190501.10402739': __('identifierSchemeCodeUnspsc190501.10402739'),
	'identifierSchemeCodeUnspsc190501.10402740': __('identifierSchemeCodeUnspsc190501.10402740'),
	'identifierSchemeCodeUnspsc190501.10402741': __('identifierSchemeCodeUnspsc190501.10402741'),
	'identifierSchemeCodeUnspsc190501.10402742': __('identifierSchemeCodeUnspsc190501.10402742'),
	'identifierSchemeCodeUnspsc190501.10402743': __('identifierSchemeCodeUnspsc190501.10402743'),
	'identifierSchemeCodeUnspsc190501.10402744': __('identifierSchemeCodeUnspsc190501.10402744'),
	'identifierSchemeCodeUnspsc190501.10402745': __('identifierSchemeCodeUnspsc190501.10402745'),
	'identifierSchemeCodeUnspsc190501.10402746': __('identifierSchemeCodeUnspsc190501.10402746'),
	'identifierSchemeCodeUnspsc190501.10402747': __('identifierSchemeCodeUnspsc190501.10402747'),
	'identifierSchemeCodeUnspsc190501.10402748': __('identifierSchemeCodeUnspsc190501.10402748'),
	'identifierSchemeCodeUnspsc190501.10402749': __('identifierSchemeCodeUnspsc190501.10402749'),
	'identifierSchemeCodeUnspsc190501.10402750': __('identifierSchemeCodeUnspsc190501.10402750'),
	'identifierSchemeCodeUnspsc190501.10402751': __('identifierSchemeCodeUnspsc190501.10402751'),
	'identifierSchemeCodeUnspsc190501.10402752': __('identifierSchemeCodeUnspsc190501.10402752'),
	'identifierSchemeCodeUnspsc190501.10402753': __('identifierSchemeCodeUnspsc190501.10402753'),
	'identifierSchemeCodeUnspsc190501.10402754': __('identifierSchemeCodeUnspsc190501.10402754'),
	'identifierSchemeCodeUnspsc190501.10402755': __('identifierSchemeCodeUnspsc190501.10402755'),
	'identifierSchemeCodeUnspsc190501.10402756': __('identifierSchemeCodeUnspsc190501.10402756'),
	'identifierSchemeCodeUnspsc190501.10402757': __('identifierSchemeCodeUnspsc190501.10402757'),
	'identifierSchemeCodeUnspsc190501.10402758': __('identifierSchemeCodeUnspsc190501.10402758'),
	'identifierSchemeCodeUnspsc190501.10402759': __('identifierSchemeCodeUnspsc190501.10402759'),
	'identifierSchemeCodeUnspsc190501.10402760': __('identifierSchemeCodeUnspsc190501.10402760'),
	'identifierSchemeCodeUnspsc190501.10402761': __('identifierSchemeCodeUnspsc190501.10402761'),
	'identifierSchemeCodeUnspsc190501.10402762': __('identifierSchemeCodeUnspsc190501.10402762'),
	'identifierSchemeCodeUnspsc190501.10402763': __('identifierSchemeCodeUnspsc190501.10402763'),
	'identifierSchemeCodeUnspsc190501.10402764': __('identifierSchemeCodeUnspsc190501.10402764'),
	'identifierSchemeCodeUnspsc190501.10402765': __('identifierSchemeCodeUnspsc190501.10402765'),
	'identifierSchemeCodeUnspsc190501.10402766': __('identifierSchemeCodeUnspsc190501.10402766'),
	'identifierSchemeCodeUnspsc190501.10402767': __('identifierSchemeCodeUnspsc190501.10402767'),
	'identifierSchemeCodeUnspsc190501.10402768': __('identifierSchemeCodeUnspsc190501.10402768'),
	'identifierSchemeCodeUnspsc190501.10402769': __('identifierSchemeCodeUnspsc190501.10402769'),
	'identifierSchemeCodeUnspsc190501.10402770': __('identifierSchemeCodeUnspsc190501.10402770'),
	'identifierSchemeCodeUnspsc190501.10402771': __('identifierSchemeCodeUnspsc190501.10402771'),
	'identifierSchemeCodeUnspsc190501.10402772': __('identifierSchemeCodeUnspsc190501.10402772'),
	'identifierSchemeCodeUnspsc190501.10402773': __('identifierSchemeCodeUnspsc190501.10402773'),
	'identifierSchemeCodeUnspsc190501.10402774': __('identifierSchemeCodeUnspsc190501.10402774'),
	'identifierSchemeCodeUnspsc190501.10402775': __('identifierSchemeCodeUnspsc190501.10402775'),
	'identifierSchemeCodeUnspsc190501.10402776': __('identifierSchemeCodeUnspsc190501.10402776'),
	'identifierSchemeCodeUnspsc190501.10402777': __('identifierSchemeCodeUnspsc190501.10402777'),
	'identifierSchemeCodeUnspsc190501.10402778': __('identifierSchemeCodeUnspsc190501.10402778'),
	'identifierSchemeCodeUnspsc190501.10402779': __('identifierSchemeCodeUnspsc190501.10402779'),
	'identifierSchemeCodeUnspsc190501.10402780': __('identifierSchemeCodeUnspsc190501.10402780'),
	'identifierSchemeCodeUnspsc190501.10402781': __('identifierSchemeCodeUnspsc190501.10402781'),
	'identifierSchemeCodeUnspsc190501.10402782': __('identifierSchemeCodeUnspsc190501.10402782'),
	'identifierSchemeCodeUnspsc190501.10402800': __('identifierSchemeCodeUnspsc190501.10402800'),
	'identifierSchemeCodeUnspsc190501.10402801': __('identifierSchemeCodeUnspsc190501.10402801'),
	'identifierSchemeCodeUnspsc190501.10402802': __('identifierSchemeCodeUnspsc190501.10402802'),
	'identifierSchemeCodeUnspsc190501.10402803': __('identifierSchemeCodeUnspsc190501.10402803'),
	'identifierSchemeCodeUnspsc190501.10402804': __('identifierSchemeCodeUnspsc190501.10402804'),
	'identifierSchemeCodeUnspsc190501.10402805': __('identifierSchemeCodeUnspsc190501.10402805'),
	'identifierSchemeCodeUnspsc190501.10402806': __('identifierSchemeCodeUnspsc190501.10402806'),
	'identifierSchemeCodeUnspsc190501.10402807': __('identifierSchemeCodeUnspsc190501.10402807'),
	'identifierSchemeCodeUnspsc190501.10402808': __('identifierSchemeCodeUnspsc190501.10402808'),
	'identifierSchemeCodeUnspsc190501.10402809': __('identifierSchemeCodeUnspsc190501.10402809'),
	'identifierSchemeCodeUnspsc190501.10402810': __('identifierSchemeCodeUnspsc190501.10402810'),
	'identifierSchemeCodeUnspsc190501.10402811': __('identifierSchemeCodeUnspsc190501.10402811'),
	'identifierSchemeCodeUnspsc190501.10402812': __('identifierSchemeCodeUnspsc190501.10402812'),
	'identifierSchemeCodeUnspsc190501.10402813': __('identifierSchemeCodeUnspsc190501.10402813'),
	'identifierSchemeCodeUnspsc190501.10402814': __('identifierSchemeCodeUnspsc190501.10402814'),
	'identifierSchemeCodeUnspsc190501.10402815': __('identifierSchemeCodeUnspsc190501.10402815'),
	'identifierSchemeCodeUnspsc190501.10402816': __('identifierSchemeCodeUnspsc190501.10402816'),
	'identifierSchemeCodeUnspsc190501.10402817': __('identifierSchemeCodeUnspsc190501.10402817'),
	'identifierSchemeCodeUnspsc190501.10402818': __('identifierSchemeCodeUnspsc190501.10402818'),
	'identifierSchemeCodeUnspsc190501.10402819': __('identifierSchemeCodeUnspsc190501.10402819'),
	'identifierSchemeCodeUnspsc190501.10402820': __('identifierSchemeCodeUnspsc190501.10402820'),
	'identifierSchemeCodeUnspsc190501.10402821': __('identifierSchemeCodeUnspsc190501.10402821'),
	'identifierSchemeCodeUnspsc190501.10402822': __('identifierSchemeCodeUnspsc190501.10402822'),
	'identifierSchemeCodeUnspsc190501.10402823': __('identifierSchemeCodeUnspsc190501.10402823'),
	'identifierSchemeCodeUnspsc190501.10402824': __('identifierSchemeCodeUnspsc190501.10402824'),
	'identifierSchemeCodeUnspsc190501.10402825': __('identifierSchemeCodeUnspsc190501.10402825'),
	'identifierSchemeCodeUnspsc190501.10402826': __('identifierSchemeCodeUnspsc190501.10402826'),
	'identifierSchemeCodeUnspsc190501.10402827': __('identifierSchemeCodeUnspsc190501.10402827'),
	'identifierSchemeCodeUnspsc190501.10402828': __('identifierSchemeCodeUnspsc190501.10402828'),
	'identifierSchemeCodeUnspsc190501.10402829': __('identifierSchemeCodeUnspsc190501.10402829'),
	'identifierSchemeCodeUnspsc190501.10402830': __('identifierSchemeCodeUnspsc190501.10402830'),
	'identifierSchemeCodeUnspsc190501.10402831': __('identifierSchemeCodeUnspsc190501.10402831'),
	'identifierSchemeCodeUnspsc190501.10402832': __('identifierSchemeCodeUnspsc190501.10402832'),
	'identifierSchemeCodeUnspsc190501.10402833': __('identifierSchemeCodeUnspsc190501.10402833'),
	'identifierSchemeCodeUnspsc190501.10402834': __('identifierSchemeCodeUnspsc190501.10402834'),
	'identifierSchemeCodeUnspsc190501.10402835': __('identifierSchemeCodeUnspsc190501.10402835'),
	'identifierSchemeCodeUnspsc190501.10402836': __('identifierSchemeCodeUnspsc190501.10402836'),
	'identifierSchemeCodeUnspsc190501.10402837': __('identifierSchemeCodeUnspsc190501.10402837'),
	'identifierSchemeCodeUnspsc190501.10402838': __('identifierSchemeCodeUnspsc190501.10402838'),
	'identifierSchemeCodeUnspsc190501.10402839': __('identifierSchemeCodeUnspsc190501.10402839'),
	'identifierSchemeCodeUnspsc190501.10402840': __('identifierSchemeCodeUnspsc190501.10402840'),
	'identifierSchemeCodeUnspsc190501.10402841': __('identifierSchemeCodeUnspsc190501.10402841'),
	'identifierSchemeCodeUnspsc190501.10402842': __('identifierSchemeCodeUnspsc190501.10402842'),
	'identifierSchemeCodeUnspsc190501.10402843': __('identifierSchemeCodeUnspsc190501.10402843'),
	'identifierSchemeCodeUnspsc190501.10402844': __('identifierSchemeCodeUnspsc190501.10402844'),
	'identifierSchemeCodeUnspsc190501.10402845': __('identifierSchemeCodeUnspsc190501.10402845'),
	'identifierSchemeCodeUnspsc190501.10402846': __('identifierSchemeCodeUnspsc190501.10402846'),
	'identifierSchemeCodeUnspsc190501.10402847': __('identifierSchemeCodeUnspsc190501.10402847'),
	'identifierSchemeCodeUnspsc190501.10402848': __('identifierSchemeCodeUnspsc190501.10402848'),
	'identifierSchemeCodeUnspsc190501.10402849': __('identifierSchemeCodeUnspsc190501.10402849'),
	'identifierSchemeCodeUnspsc190501.10402850': __('identifierSchemeCodeUnspsc190501.10402850'),
	'identifierSchemeCodeUnspsc190501.10402851': __('identifierSchemeCodeUnspsc190501.10402851'),
	'identifierSchemeCodeUnspsc190501.10402852': __('identifierSchemeCodeUnspsc190501.10402852'),
	'identifierSchemeCodeUnspsc190501.10402853': __('identifierSchemeCodeUnspsc190501.10402853'),
	'identifierSchemeCodeUnspsc190501.10402854': __('identifierSchemeCodeUnspsc190501.10402854'),
	'identifierSchemeCodeUnspsc190501.10402855': __('identifierSchemeCodeUnspsc190501.10402855'),
	'identifierSchemeCodeUnspsc190501.10402856': __('identifierSchemeCodeUnspsc190501.10402856'),
	'identifierSchemeCodeUnspsc190501.10402857': __('identifierSchemeCodeUnspsc190501.10402857'),
	'identifierSchemeCodeUnspsc190501.10402858': __('identifierSchemeCodeUnspsc190501.10402858'),
	'identifierSchemeCodeUnspsc190501.10402859': __('identifierSchemeCodeUnspsc190501.10402859'),
	'identifierSchemeCodeUnspsc190501.10402860': __('identifierSchemeCodeUnspsc190501.10402860'),
	'identifierSchemeCodeUnspsc190501.10402861': __('identifierSchemeCodeUnspsc190501.10402861'),
	'identifierSchemeCodeUnspsc190501.10402862': __('identifierSchemeCodeUnspsc190501.10402862'),
	'identifierSchemeCodeUnspsc190501.10402863': __('identifierSchemeCodeUnspsc190501.10402863'),
	'identifierSchemeCodeUnspsc190501.10402864': __('identifierSchemeCodeUnspsc190501.10402864'),
	'identifierSchemeCodeUnspsc190501.10402865': __('identifierSchemeCodeUnspsc190501.10402865'),
	'identifierSchemeCodeUnspsc190501.10402866': __('identifierSchemeCodeUnspsc190501.10402866'),
	'identifierSchemeCodeUnspsc190501.10402867': __('identifierSchemeCodeUnspsc190501.10402867'),
	'identifierSchemeCodeUnspsc190501.10402868': __('identifierSchemeCodeUnspsc190501.10402868'),
	'identifierSchemeCodeUnspsc190501.10402869': __('identifierSchemeCodeUnspsc190501.10402869'),
	'identifierSchemeCodeUnspsc190501.10402870': __('identifierSchemeCodeUnspsc190501.10402870'),
	'identifierSchemeCodeUnspsc190501.10402871': __('identifierSchemeCodeUnspsc190501.10402871'),
	'identifierSchemeCodeUnspsc190501.10402872': __('identifierSchemeCodeUnspsc190501.10402872'),
	'identifierSchemeCodeUnspsc190501.10402873': __('identifierSchemeCodeUnspsc190501.10402873'),
	'identifierSchemeCodeUnspsc190501.10402874': __('identifierSchemeCodeUnspsc190501.10402874'),
	'identifierSchemeCodeUnspsc190501.10402875': __('identifierSchemeCodeUnspsc190501.10402875'),
	'identifierSchemeCodeUnspsc190501.10402876': __('identifierSchemeCodeUnspsc190501.10402876'),
	'identifierSchemeCodeUnspsc190501.10402877': __('identifierSchemeCodeUnspsc190501.10402877'),
	'identifierSchemeCodeUnspsc190501.10402878': __('identifierSchemeCodeUnspsc190501.10402878'),
	'identifierSchemeCodeUnspsc190501.10402879': __('identifierSchemeCodeUnspsc190501.10402879'),
	'identifierSchemeCodeUnspsc190501.10402880': __('identifierSchemeCodeUnspsc190501.10402880'),
	'identifierSchemeCodeUnspsc190501.10402881': __('identifierSchemeCodeUnspsc190501.10402881'),
	'identifierSchemeCodeUnspsc190501.10402882': __('identifierSchemeCodeUnspsc190501.10402882'),
	'identifierSchemeCodeUnspsc190501.10402883': __('identifierSchemeCodeUnspsc190501.10402883'),
	'identifierSchemeCodeUnspsc190501.10402884': __('identifierSchemeCodeUnspsc190501.10402884'),
	'identifierSchemeCodeUnspsc190501.10402885': __('identifierSchemeCodeUnspsc190501.10402885'),
	'identifierSchemeCodeUnspsc190501.10402886': __('identifierSchemeCodeUnspsc190501.10402886'),
	'identifierSchemeCodeUnspsc190501.10402887': __('identifierSchemeCodeUnspsc190501.10402887'),
	'identifierSchemeCodeUnspsc190501.10402888': __('identifierSchemeCodeUnspsc190501.10402888'),
	'identifierSchemeCodeUnspsc190501.10410000': __('identifierSchemeCodeUnspsc190501.10410000'),
	'identifierSchemeCodeUnspsc190501.10411500': __('identifierSchemeCodeUnspsc190501.10411500'),
	'identifierSchemeCodeUnspsc190501.10411501': __('identifierSchemeCodeUnspsc190501.10411501'),
	'identifierSchemeCodeUnspsc190501.10411502': __('identifierSchemeCodeUnspsc190501.10411502'),
	'identifierSchemeCodeUnspsc190501.10411503': __('identifierSchemeCodeUnspsc190501.10411503'),
	'identifierSchemeCodeUnspsc190501.10411504': __('identifierSchemeCodeUnspsc190501.10411504'),
	'identifierSchemeCodeUnspsc190501.10411505': __('identifierSchemeCodeUnspsc190501.10411505'),
	'identifierSchemeCodeUnspsc190501.10411506': __('identifierSchemeCodeUnspsc190501.10411506'),
	'identifierSchemeCodeUnspsc190501.10411507': __('identifierSchemeCodeUnspsc190501.10411507'),
	'identifierSchemeCodeUnspsc190501.10411508': __('identifierSchemeCodeUnspsc190501.10411508'),
	'identifierSchemeCodeUnspsc190501.10411509': __('identifierSchemeCodeUnspsc190501.10411509'),
	'identifierSchemeCodeUnspsc190501.10411510': __('identifierSchemeCodeUnspsc190501.10411510'),
	'identifierSchemeCodeUnspsc190501.10411511': __('identifierSchemeCodeUnspsc190501.10411511'),
	'identifierSchemeCodeUnspsc190501.10411512': __('identifierSchemeCodeUnspsc190501.10411512'),
	'identifierSchemeCodeUnspsc190501.10411513': __('identifierSchemeCodeUnspsc190501.10411513'),
	'identifierSchemeCodeUnspsc190501.10411514': __('identifierSchemeCodeUnspsc190501.10411514'),
	'identifierSchemeCodeUnspsc190501.10411515': __('identifierSchemeCodeUnspsc190501.10411515'),
	'identifierSchemeCodeUnspsc190501.10411516': __('identifierSchemeCodeUnspsc190501.10411516'),
	'identifierSchemeCodeUnspsc190501.10411517': __('identifierSchemeCodeUnspsc190501.10411517'),
	'identifierSchemeCodeUnspsc190501.10411518': __('identifierSchemeCodeUnspsc190501.10411518'),
	'identifierSchemeCodeUnspsc190501.10411519': __('identifierSchemeCodeUnspsc190501.10411519'),
	'identifierSchemeCodeUnspsc190501.10411600': __('identifierSchemeCodeUnspsc190501.10411600'),
	'identifierSchemeCodeUnspsc190501.10411601': __('identifierSchemeCodeUnspsc190501.10411601'),
	'identifierSchemeCodeUnspsc190501.10411602': __('identifierSchemeCodeUnspsc190501.10411602'),
	'identifierSchemeCodeUnspsc190501.10411603': __('identifierSchemeCodeUnspsc190501.10411603'),
	'identifierSchemeCodeUnspsc190501.10411604': __('identifierSchemeCodeUnspsc190501.10411604'),
	'identifierSchemeCodeUnspsc190501.10411605': __('identifierSchemeCodeUnspsc190501.10411605'),
	'identifierSchemeCodeUnspsc190501.10411606': __('identifierSchemeCodeUnspsc190501.10411606'),
	'identifierSchemeCodeUnspsc190501.10411607': __('identifierSchemeCodeUnspsc190501.10411607'),
	'identifierSchemeCodeUnspsc190501.10411608': __('identifierSchemeCodeUnspsc190501.10411608'),
	'identifierSchemeCodeUnspsc190501.10411609': __('identifierSchemeCodeUnspsc190501.10411609'),
	'identifierSchemeCodeUnspsc190501.10411610': __('identifierSchemeCodeUnspsc190501.10411610'),
	'identifierSchemeCodeUnspsc190501.10411611': __('identifierSchemeCodeUnspsc190501.10411611'),
	'identifierSchemeCodeUnspsc190501.10411612': __('identifierSchemeCodeUnspsc190501.10411612'),
	'identifierSchemeCodeUnspsc190501.10411613': __('identifierSchemeCodeUnspsc190501.10411613'),
	'identifierSchemeCodeUnspsc190501.10411614': __('identifierSchemeCodeUnspsc190501.10411614'),
	'identifierSchemeCodeUnspsc190501.10411615': __('identifierSchemeCodeUnspsc190501.10411615'),
	'identifierSchemeCodeUnspsc190501.10411616': __('identifierSchemeCodeUnspsc190501.10411616'),
	'identifierSchemeCodeUnspsc190501.10411617': __('identifierSchemeCodeUnspsc190501.10411617'),
	'identifierSchemeCodeUnspsc190501.10411618': __('identifierSchemeCodeUnspsc190501.10411618'),
	'identifierSchemeCodeUnspsc190501.10411619': __('identifierSchemeCodeUnspsc190501.10411619'),
	'identifierSchemeCodeUnspsc190501.10411700': __('identifierSchemeCodeUnspsc190501.10411700'),
	'identifierSchemeCodeUnspsc190501.10411701': __('identifierSchemeCodeUnspsc190501.10411701'),
	'identifierSchemeCodeUnspsc190501.10411702': __('identifierSchemeCodeUnspsc190501.10411702'),
	'identifierSchemeCodeUnspsc190501.10411703': __('identifierSchemeCodeUnspsc190501.10411703'),
	'identifierSchemeCodeUnspsc190501.10411704': __('identifierSchemeCodeUnspsc190501.10411704'),
	'identifierSchemeCodeUnspsc190501.10411705': __('identifierSchemeCodeUnspsc190501.10411705'),
	'identifierSchemeCodeUnspsc190501.10411706': __('identifierSchemeCodeUnspsc190501.10411706'),
	'identifierSchemeCodeUnspsc190501.10411707': __('identifierSchemeCodeUnspsc190501.10411707'),
	'identifierSchemeCodeUnspsc190501.10411708': __('identifierSchemeCodeUnspsc190501.10411708'),
	'identifierSchemeCodeUnspsc190501.10411709': __('identifierSchemeCodeUnspsc190501.10411709'),
	'identifierSchemeCodeUnspsc190501.10411710': __('identifierSchemeCodeUnspsc190501.10411710'),
	'identifierSchemeCodeUnspsc190501.10411711': __('identifierSchemeCodeUnspsc190501.10411711'),
	'identifierSchemeCodeUnspsc190501.10411712': __('identifierSchemeCodeUnspsc190501.10411712'),
	'identifierSchemeCodeUnspsc190501.10411713': __('identifierSchemeCodeUnspsc190501.10411713'),
	'identifierSchemeCodeUnspsc190501.10411714': __('identifierSchemeCodeUnspsc190501.10411714'),
	'identifierSchemeCodeUnspsc190501.10411715': __('identifierSchemeCodeUnspsc190501.10411715'),
	'identifierSchemeCodeUnspsc190501.10411716': __('identifierSchemeCodeUnspsc190501.10411716'),
	'identifierSchemeCodeUnspsc190501.10411717': __('identifierSchemeCodeUnspsc190501.10411717'),
	'identifierSchemeCodeUnspsc190501.10411718': __('identifierSchemeCodeUnspsc190501.10411718'),
	'identifierSchemeCodeUnspsc190501.10411719': __('identifierSchemeCodeUnspsc190501.10411719'),
	'identifierSchemeCodeUnspsc190501.10411720': __('identifierSchemeCodeUnspsc190501.10411720'),
	'identifierSchemeCodeUnspsc190501.10411721': __('identifierSchemeCodeUnspsc190501.10411721'),
	'identifierSchemeCodeUnspsc190501.10411722': __('identifierSchemeCodeUnspsc190501.10411722'),
	'identifierSchemeCodeUnspsc190501.10411723': __('identifierSchemeCodeUnspsc190501.10411723'),
	'identifierSchemeCodeUnspsc190501.10411724': __('identifierSchemeCodeUnspsc190501.10411724'),
	'identifierSchemeCodeUnspsc190501.10411725': __('identifierSchemeCodeUnspsc190501.10411725'),
	'identifierSchemeCodeUnspsc190501.10411726': __('identifierSchemeCodeUnspsc190501.10411726'),
	'identifierSchemeCodeUnspsc190501.10411727': __('identifierSchemeCodeUnspsc190501.10411727'),
	'identifierSchemeCodeUnspsc190501.10411728': __('identifierSchemeCodeUnspsc190501.10411728'),
	'identifierSchemeCodeUnspsc190501.10411729': __('identifierSchemeCodeUnspsc190501.10411729'),
	'identifierSchemeCodeUnspsc190501.10411730': __('identifierSchemeCodeUnspsc190501.10411730'),
	'identifierSchemeCodeUnspsc190501.10411731': __('identifierSchemeCodeUnspsc190501.10411731'),
	'identifierSchemeCodeUnspsc190501.10411732': __('identifierSchemeCodeUnspsc190501.10411732'),
	'identifierSchemeCodeUnspsc190501.10411733': __('identifierSchemeCodeUnspsc190501.10411733'),
	'identifierSchemeCodeUnspsc190501.10411734': __('identifierSchemeCodeUnspsc190501.10411734'),
	'identifierSchemeCodeUnspsc190501.10411800': __('identifierSchemeCodeUnspsc190501.10411800'),
	'identifierSchemeCodeUnspsc190501.10411801': __('identifierSchemeCodeUnspsc190501.10411801'),
	'identifierSchemeCodeUnspsc190501.10411802': __('identifierSchemeCodeUnspsc190501.10411802'),
	'identifierSchemeCodeUnspsc190501.10411803': __('identifierSchemeCodeUnspsc190501.10411803'),
	'identifierSchemeCodeUnspsc190501.10411804': __('identifierSchemeCodeUnspsc190501.10411804'),
	'identifierSchemeCodeUnspsc190501.10411805': __('identifierSchemeCodeUnspsc190501.10411805'),
	'identifierSchemeCodeUnspsc190501.10411900': __('identifierSchemeCodeUnspsc190501.10411900'),
	'identifierSchemeCodeUnspsc190501.10411901': __('identifierSchemeCodeUnspsc190501.10411901'),
	'identifierSchemeCodeUnspsc190501.10411902': __('identifierSchemeCodeUnspsc190501.10411902'),
	'identifierSchemeCodeUnspsc190501.10411903': __('identifierSchemeCodeUnspsc190501.10411903'),
	'identifierSchemeCodeUnspsc190501.10411904': __('identifierSchemeCodeUnspsc190501.10411904'),
	'identifierSchemeCodeUnspsc190501.10411905': __('identifierSchemeCodeUnspsc190501.10411905'),
	'identifierSchemeCodeUnspsc190501.10411906': __('identifierSchemeCodeUnspsc190501.10411906'),
	'identifierSchemeCodeUnspsc190501.10411907': __('identifierSchemeCodeUnspsc190501.10411907'),
	'identifierSchemeCodeUnspsc190501.10411908': __('identifierSchemeCodeUnspsc190501.10411908'),
	'identifierSchemeCodeUnspsc190501.10411909': __('identifierSchemeCodeUnspsc190501.10411909'),
	'identifierSchemeCodeUnspsc190501.10411910': __('identifierSchemeCodeUnspsc190501.10411910'),
	'identifierSchemeCodeUnspsc190501.10412000': __('identifierSchemeCodeUnspsc190501.10412000'),
	'identifierSchemeCodeUnspsc190501.10412001': __('identifierSchemeCodeUnspsc190501.10412001'),
	'identifierSchemeCodeUnspsc190501.10412002': __('identifierSchemeCodeUnspsc190501.10412002'),
	'identifierSchemeCodeUnspsc190501.10412003': __('identifierSchemeCodeUnspsc190501.10412003'),
	'identifierSchemeCodeUnspsc190501.10412004': __('identifierSchemeCodeUnspsc190501.10412004'),
	'identifierSchemeCodeUnspsc190501.10412005': __('identifierSchemeCodeUnspsc190501.10412005'),
	'identifierSchemeCodeUnspsc190501.10412006': __('identifierSchemeCodeUnspsc190501.10412006'),
	'identifierSchemeCodeUnspsc190501.10412007': __('identifierSchemeCodeUnspsc190501.10412007'),
	'identifierSchemeCodeUnspsc190501.10412008': __('identifierSchemeCodeUnspsc190501.10412008'),
	'identifierSchemeCodeUnspsc190501.10412009': __('identifierSchemeCodeUnspsc190501.10412009'),
	'identifierSchemeCodeUnspsc190501.10412010': __('identifierSchemeCodeUnspsc190501.10412010'),
	'identifierSchemeCodeUnspsc190501.10412011': __('identifierSchemeCodeUnspsc190501.10412011'),
	'identifierSchemeCodeUnspsc190501.10412100': __('identifierSchemeCodeUnspsc190501.10412100'),
	'identifierSchemeCodeUnspsc190501.10412101': __('identifierSchemeCodeUnspsc190501.10412101'),
	'identifierSchemeCodeUnspsc190501.10412102': __('identifierSchemeCodeUnspsc190501.10412102'),
	'identifierSchemeCodeUnspsc190501.10412103': __('identifierSchemeCodeUnspsc190501.10412103'),
	'identifierSchemeCodeUnspsc190501.10412104': __('identifierSchemeCodeUnspsc190501.10412104'),
	'identifierSchemeCodeUnspsc190501.10412200': __('identifierSchemeCodeUnspsc190501.10412200'),
	'identifierSchemeCodeUnspsc190501.10412201': __('identifierSchemeCodeUnspsc190501.10412201'),
	'identifierSchemeCodeUnspsc190501.10412202': __('identifierSchemeCodeUnspsc190501.10412202'),
	'identifierSchemeCodeUnspsc190501.10412203': __('identifierSchemeCodeUnspsc190501.10412203'),
	'identifierSchemeCodeUnspsc190501.10412204': __('identifierSchemeCodeUnspsc190501.10412204'),
	'identifierSchemeCodeUnspsc190501.10412205': __('identifierSchemeCodeUnspsc190501.10412205'),
	'identifierSchemeCodeUnspsc190501.10412206': __('identifierSchemeCodeUnspsc190501.10412206'),
	'identifierSchemeCodeUnspsc190501.10412207': __('identifierSchemeCodeUnspsc190501.10412207'),
	'identifierSchemeCodeUnspsc190501.10412208': __('identifierSchemeCodeUnspsc190501.10412208'),
	'identifierSchemeCodeUnspsc190501.10412209': __('identifierSchemeCodeUnspsc190501.10412209'),
	'identifierSchemeCodeUnspsc190501.10412210': __('identifierSchemeCodeUnspsc190501.10412210'),
	'identifierSchemeCodeUnspsc190501.10412211': __('identifierSchemeCodeUnspsc190501.10412211'),
	'identifierSchemeCodeUnspsc190501.10412212': __('identifierSchemeCodeUnspsc190501.10412212'),
	'identifierSchemeCodeUnspsc190501.10412213': __('identifierSchemeCodeUnspsc190501.10412213'),
	'identifierSchemeCodeUnspsc190501.10412214': __('identifierSchemeCodeUnspsc190501.10412214'),
	'identifierSchemeCodeUnspsc190501.10412215': __('identifierSchemeCodeUnspsc190501.10412215'),
	'identifierSchemeCodeUnspsc190501.10412216': __('identifierSchemeCodeUnspsc190501.10412216'),
	'identifierSchemeCodeUnspsc190501.10412217': __('identifierSchemeCodeUnspsc190501.10412217'),
	'identifierSchemeCodeUnspsc190501.10412218': __('identifierSchemeCodeUnspsc190501.10412218'),
	'identifierSchemeCodeUnspsc190501.10412219': __('identifierSchemeCodeUnspsc190501.10412219'),
	'identifierSchemeCodeUnspsc190501.10412300': __('identifierSchemeCodeUnspsc190501.10412300'),
	'identifierSchemeCodeUnspsc190501.10412301': __('identifierSchemeCodeUnspsc190501.10412301'),
	'identifierSchemeCodeUnspsc190501.10412302': __('identifierSchemeCodeUnspsc190501.10412302'),
	'identifierSchemeCodeUnspsc190501.10412303': __('identifierSchemeCodeUnspsc190501.10412303'),
	'identifierSchemeCodeUnspsc190501.10412304': __('identifierSchemeCodeUnspsc190501.10412304'),
	'identifierSchemeCodeUnspsc190501.10412305': __('identifierSchemeCodeUnspsc190501.10412305'),
	'identifierSchemeCodeUnspsc190501.10412400': __('identifierSchemeCodeUnspsc190501.10412400'),
	'identifierSchemeCodeUnspsc190501.10412401': __('identifierSchemeCodeUnspsc190501.10412401'),
	'identifierSchemeCodeUnspsc190501.10412402': __('identifierSchemeCodeUnspsc190501.10412402'),
	'identifierSchemeCodeUnspsc190501.10412403': __('identifierSchemeCodeUnspsc190501.10412403'),
	'identifierSchemeCodeUnspsc190501.10412404': __('identifierSchemeCodeUnspsc190501.10412404'),
	'identifierSchemeCodeUnspsc190501.10412405': __('identifierSchemeCodeUnspsc190501.10412405'),
	'identifierSchemeCodeUnspsc190501.10412500': __('identifierSchemeCodeUnspsc190501.10412500'),
	'identifierSchemeCodeUnspsc190501.10412501': __('identifierSchemeCodeUnspsc190501.10412501'),
	'identifierSchemeCodeUnspsc190501.10412502': __('identifierSchemeCodeUnspsc190501.10412502'),
	'identifierSchemeCodeUnspsc190501.10412503': __('identifierSchemeCodeUnspsc190501.10412503'),
	'identifierSchemeCodeUnspsc190501.10412504': __('identifierSchemeCodeUnspsc190501.10412504'),
	'identifierSchemeCodeUnspsc190501.10412505': __('identifierSchemeCodeUnspsc190501.10412505'),
	'identifierSchemeCodeUnspsc190501.10412600': __('identifierSchemeCodeUnspsc190501.10412600'),
	'identifierSchemeCodeUnspsc190501.10412601': __('identifierSchemeCodeUnspsc190501.10412601'),
	'identifierSchemeCodeUnspsc190501.10412602': __('identifierSchemeCodeUnspsc190501.10412602'),
	'identifierSchemeCodeUnspsc190501.10412603': __('identifierSchemeCodeUnspsc190501.10412603'),
	'identifierSchemeCodeUnspsc190501.10412604': __('identifierSchemeCodeUnspsc190501.10412604'),
	'identifierSchemeCodeUnspsc190501.10412605': __('identifierSchemeCodeUnspsc190501.10412605'),
	'identifierSchemeCodeUnspsc190501.10412606': __('identifierSchemeCodeUnspsc190501.10412606'),
	'identifierSchemeCodeUnspsc190501.10412607': __('identifierSchemeCodeUnspsc190501.10412607'),
	'identifierSchemeCodeUnspsc190501.10412608': __('identifierSchemeCodeUnspsc190501.10412608'),
	'identifierSchemeCodeUnspsc190501.10412609': __('identifierSchemeCodeUnspsc190501.10412609'),
	'identifierSchemeCodeUnspsc190501.10412610': __('identifierSchemeCodeUnspsc190501.10412610'),
	'identifierSchemeCodeUnspsc190501.10412611': __('identifierSchemeCodeUnspsc190501.10412611'),
	'identifierSchemeCodeUnspsc190501.10412612': __('identifierSchemeCodeUnspsc190501.10412612'),
	'identifierSchemeCodeUnspsc190501.10412613': __('identifierSchemeCodeUnspsc190501.10412613'),
	'identifierSchemeCodeUnspsc190501.10412614': __('identifierSchemeCodeUnspsc190501.10412614'),
	'identifierSchemeCodeUnspsc190501.10412615': __('identifierSchemeCodeUnspsc190501.10412615'),
	'identifierSchemeCodeUnspsc190501.10412616': __('identifierSchemeCodeUnspsc190501.10412616'),
	'identifierSchemeCodeUnspsc190501.10412617': __('identifierSchemeCodeUnspsc190501.10412617'),
	'identifierSchemeCodeUnspsc190501.10412618': __('identifierSchemeCodeUnspsc190501.10412618'),
	'identifierSchemeCodeUnspsc190501.10412619': __('identifierSchemeCodeUnspsc190501.10412619'),
	'identifierSchemeCodeUnspsc190501.10412620': __('identifierSchemeCodeUnspsc190501.10412620'),
	'identifierSchemeCodeUnspsc190501.10412621': __('identifierSchemeCodeUnspsc190501.10412621'),
	'identifierSchemeCodeUnspsc190501.10412622': __('identifierSchemeCodeUnspsc190501.10412622'),
	'identifierSchemeCodeUnspsc190501.10412623': __('identifierSchemeCodeUnspsc190501.10412623'),
	'identifierSchemeCodeUnspsc190501.10412624': __('identifierSchemeCodeUnspsc190501.10412624'),
	'identifierSchemeCodeUnspsc190501.10412625': __('identifierSchemeCodeUnspsc190501.10412625'),
	'identifierSchemeCodeUnspsc190501.10412626': __('identifierSchemeCodeUnspsc190501.10412626'),
	'identifierSchemeCodeUnspsc190501.10412627': __('identifierSchemeCodeUnspsc190501.10412627'),
	'identifierSchemeCodeUnspsc190501.10412628': __('identifierSchemeCodeUnspsc190501.10412628'),
	'identifierSchemeCodeUnspsc190501.10412629': __('identifierSchemeCodeUnspsc190501.10412629'),
	'identifierSchemeCodeUnspsc190501.10412630': __('identifierSchemeCodeUnspsc190501.10412630'),
	'identifierSchemeCodeUnspsc190501.10412631': __('identifierSchemeCodeUnspsc190501.10412631'),
	'identifierSchemeCodeUnspsc190501.10412632': __('identifierSchemeCodeUnspsc190501.10412632'),
	'identifierSchemeCodeUnspsc190501.10412633': __('identifierSchemeCodeUnspsc190501.10412633'),
	'identifierSchemeCodeUnspsc190501.10412634': __('identifierSchemeCodeUnspsc190501.10412634'),
	'identifierSchemeCodeUnspsc190501.10412635': __('identifierSchemeCodeUnspsc190501.10412635'),
	'identifierSchemeCodeUnspsc190501.10412636': __('identifierSchemeCodeUnspsc190501.10412636'),
	'identifierSchemeCodeUnspsc190501.10412637': __('identifierSchemeCodeUnspsc190501.10412637'),
	'identifierSchemeCodeUnspsc190501.10412638': __('identifierSchemeCodeUnspsc190501.10412638'),
	'identifierSchemeCodeUnspsc190501.10412639': __('identifierSchemeCodeUnspsc190501.10412639'),
	'identifierSchemeCodeUnspsc190501.10412640': __('identifierSchemeCodeUnspsc190501.10412640'),
	'identifierSchemeCodeUnspsc190501.10412641': __('identifierSchemeCodeUnspsc190501.10412641'),
	'identifierSchemeCodeUnspsc190501.10412642': __('identifierSchemeCodeUnspsc190501.10412642'),
	'identifierSchemeCodeUnspsc190501.10412643': __('identifierSchemeCodeUnspsc190501.10412643'),
	'identifierSchemeCodeUnspsc190501.10412644': __('identifierSchemeCodeUnspsc190501.10412644'),
	'identifierSchemeCodeUnspsc190501.10412645': __('identifierSchemeCodeUnspsc190501.10412645'),
	'identifierSchemeCodeUnspsc190501.10412646': __('identifierSchemeCodeUnspsc190501.10412646'),
	'identifierSchemeCodeUnspsc190501.10412647': __('identifierSchemeCodeUnspsc190501.10412647'),
	'identifierSchemeCodeUnspsc190501.10412648': __('identifierSchemeCodeUnspsc190501.10412648'),
	'identifierSchemeCodeUnspsc190501.10412649': __('identifierSchemeCodeUnspsc190501.10412649'),
	'identifierSchemeCodeUnspsc190501.10412650': __('identifierSchemeCodeUnspsc190501.10412650'),
	'identifierSchemeCodeUnspsc190501.10412651': __('identifierSchemeCodeUnspsc190501.10412651'),
	'identifierSchemeCodeUnspsc190501.10412652': __('identifierSchemeCodeUnspsc190501.10412652'),
	'identifierSchemeCodeUnspsc190501.10412653': __('identifierSchemeCodeUnspsc190501.10412653'),
	'identifierSchemeCodeUnspsc190501.10412800': __('identifierSchemeCodeUnspsc190501.10412800'),
	'identifierSchemeCodeUnspsc190501.10412801': __('identifierSchemeCodeUnspsc190501.10412801'),
	'identifierSchemeCodeUnspsc190501.10412802': __('identifierSchemeCodeUnspsc190501.10412802'),
	'identifierSchemeCodeUnspsc190501.10412803': __('identifierSchemeCodeUnspsc190501.10412803'),
	'identifierSchemeCodeUnspsc190501.10412804': __('identifierSchemeCodeUnspsc190501.10412804'),
	'identifierSchemeCodeUnspsc190501.10412805': __('identifierSchemeCodeUnspsc190501.10412805'),
	'identifierSchemeCodeUnspsc190501.10412806': __('identifierSchemeCodeUnspsc190501.10412806'),
	'identifierSchemeCodeUnspsc190501.10412807': __('identifierSchemeCodeUnspsc190501.10412807'),
	'identifierSchemeCodeUnspsc190501.10412808': __('identifierSchemeCodeUnspsc190501.10412808'),
	'identifierSchemeCodeUnspsc190501.10412809': __('identifierSchemeCodeUnspsc190501.10412809'),
	'identifierSchemeCodeUnspsc190501.10412810': __('identifierSchemeCodeUnspsc190501.10412810'),
	'identifierSchemeCodeUnspsc190501.10412811': __('identifierSchemeCodeUnspsc190501.10412811'),
	'identifierSchemeCodeUnspsc190501.10412812': __('identifierSchemeCodeUnspsc190501.10412812'),
	'identifierSchemeCodeUnspsc190501.10412813': __('identifierSchemeCodeUnspsc190501.10412813'),
	'identifierSchemeCodeUnspsc190501.10412900': __('identifierSchemeCodeUnspsc190501.10412900'),
	'identifierSchemeCodeUnspsc190501.10412901': __('identifierSchemeCodeUnspsc190501.10412901'),
	'identifierSchemeCodeUnspsc190501.10412902': __('identifierSchemeCodeUnspsc190501.10412902'),
	'identifierSchemeCodeUnspsc190501.10412903': __('identifierSchemeCodeUnspsc190501.10412903'),
	'identifierSchemeCodeUnspsc190501.10412904': __('identifierSchemeCodeUnspsc190501.10412904'),
	'identifierSchemeCodeUnspsc190501.10412905': __('identifierSchemeCodeUnspsc190501.10412905'),
	'identifierSchemeCodeUnspsc190501.10412906': __('identifierSchemeCodeUnspsc190501.10412906'),
	'identifierSchemeCodeUnspsc190501.10413000': __('identifierSchemeCodeUnspsc190501.10413000'),
	'identifierSchemeCodeUnspsc190501.10413001': __('identifierSchemeCodeUnspsc190501.10413001'),
	'identifierSchemeCodeUnspsc190501.10413002': __('identifierSchemeCodeUnspsc190501.10413002'),
	'identifierSchemeCodeUnspsc190501.10413003': __('identifierSchemeCodeUnspsc190501.10413003'),
	'identifierSchemeCodeUnspsc190501.10413004': __('identifierSchemeCodeUnspsc190501.10413004'),
	'identifierSchemeCodeUnspsc190501.10413005': __('identifierSchemeCodeUnspsc190501.10413005'),
	'identifierSchemeCodeUnspsc190501.10413006': __('identifierSchemeCodeUnspsc190501.10413006'),
	'identifierSchemeCodeUnspsc190501.10413007': __('identifierSchemeCodeUnspsc190501.10413007'),
	'identifierSchemeCodeUnspsc190501.10413008': __('identifierSchemeCodeUnspsc190501.10413008'),
	'identifierSchemeCodeUnspsc190501.10413009': __('identifierSchemeCodeUnspsc190501.10413009'),
	'identifierSchemeCodeUnspsc190501.10413010': __('identifierSchemeCodeUnspsc190501.10413010'),
	'identifierSchemeCodeUnspsc190501.10413100': __('identifierSchemeCodeUnspsc190501.10413100'),
	'identifierSchemeCodeUnspsc190501.10413101': __('identifierSchemeCodeUnspsc190501.10413101'),
	'identifierSchemeCodeUnspsc190501.10413102': __('identifierSchemeCodeUnspsc190501.10413102'),
	'identifierSchemeCodeUnspsc190501.10413103': __('identifierSchemeCodeUnspsc190501.10413103'),
	'identifierSchemeCodeUnspsc190501.10413104': __('identifierSchemeCodeUnspsc190501.10413104'),
	'identifierSchemeCodeUnspsc190501.10413105': __('identifierSchemeCodeUnspsc190501.10413105'),
	'identifierSchemeCodeUnspsc190501.10413106': __('identifierSchemeCodeUnspsc190501.10413106'),
	'identifierSchemeCodeUnspsc190501.10413107': __('identifierSchemeCodeUnspsc190501.10413107'),
	'identifierSchemeCodeUnspsc190501.10413108': __('identifierSchemeCodeUnspsc190501.10413108'),
	'identifierSchemeCodeUnspsc190501.10413109': __('identifierSchemeCodeUnspsc190501.10413109'),
	'identifierSchemeCodeUnspsc190501.10413110': __('identifierSchemeCodeUnspsc190501.10413110'),
	'identifierSchemeCodeUnspsc190501.10413111': __('identifierSchemeCodeUnspsc190501.10413111'),
	'identifierSchemeCodeUnspsc190501.10413112': __('identifierSchemeCodeUnspsc190501.10413112'),
	'identifierSchemeCodeUnspsc190501.10413113': __('identifierSchemeCodeUnspsc190501.10413113'),
	'identifierSchemeCodeUnspsc190501.10413200': __('identifierSchemeCodeUnspsc190501.10413200'),
	'identifierSchemeCodeUnspsc190501.10413201': __('identifierSchemeCodeUnspsc190501.10413201'),
	'identifierSchemeCodeUnspsc190501.10413202': __('identifierSchemeCodeUnspsc190501.10413202'),
	'identifierSchemeCodeUnspsc190501.10413203': __('identifierSchemeCodeUnspsc190501.10413203'),
	'identifierSchemeCodeUnspsc190501.10413204': __('identifierSchemeCodeUnspsc190501.10413204'),
	'identifierSchemeCodeUnspsc190501.10413205': __('identifierSchemeCodeUnspsc190501.10413205'),
	'identifierSchemeCodeUnspsc190501.10413206': __('identifierSchemeCodeUnspsc190501.10413206'),
	'identifierSchemeCodeUnspsc190501.10413207': __('identifierSchemeCodeUnspsc190501.10413207'),
	'identifierSchemeCodeUnspsc190501.10413208': __('identifierSchemeCodeUnspsc190501.10413208'),
	'identifierSchemeCodeUnspsc190501.10413300': __('identifierSchemeCodeUnspsc190501.10413300'),
	'identifierSchemeCodeUnspsc190501.10413301': __('identifierSchemeCodeUnspsc190501.10413301'),
	'identifierSchemeCodeUnspsc190501.10413302': __('identifierSchemeCodeUnspsc190501.10413302'),
	'identifierSchemeCodeUnspsc190501.10413303': __('identifierSchemeCodeUnspsc190501.10413303'),
	'identifierSchemeCodeUnspsc190501.10413304': __('identifierSchemeCodeUnspsc190501.10413304'),
	'identifierSchemeCodeUnspsc190501.10413305': __('identifierSchemeCodeUnspsc190501.10413305'),
	'identifierSchemeCodeUnspsc190501.10413400': __('identifierSchemeCodeUnspsc190501.10413400'),
	'identifierSchemeCodeUnspsc190501.10413401': __('identifierSchemeCodeUnspsc190501.10413401'),
	'identifierSchemeCodeUnspsc190501.10413402': __('identifierSchemeCodeUnspsc190501.10413402'),
	'identifierSchemeCodeUnspsc190501.10413403': __('identifierSchemeCodeUnspsc190501.10413403'),
	'identifierSchemeCodeUnspsc190501.10413404': __('identifierSchemeCodeUnspsc190501.10413404'),
	'identifierSchemeCodeUnspsc190501.10413405': __('identifierSchemeCodeUnspsc190501.10413405'),
	'identifierSchemeCodeUnspsc190501.10413500': __('identifierSchemeCodeUnspsc190501.10413500'),
	'identifierSchemeCodeUnspsc190501.10413501': __('identifierSchemeCodeUnspsc190501.10413501'),
	'identifierSchemeCodeUnspsc190501.10413502': __('identifierSchemeCodeUnspsc190501.10413502'),
	'identifierSchemeCodeUnspsc190501.10413503': __('identifierSchemeCodeUnspsc190501.10413503'),
	'identifierSchemeCodeUnspsc190501.10413504': __('identifierSchemeCodeUnspsc190501.10413504'),
	'identifierSchemeCodeUnspsc190501.10413505': __('identifierSchemeCodeUnspsc190501.10413505'),
	'identifierSchemeCodeUnspsc190501.10413506': __('identifierSchemeCodeUnspsc190501.10413506'),
	'identifierSchemeCodeUnspsc190501.10413507': __('identifierSchemeCodeUnspsc190501.10413507'),
	'identifierSchemeCodeUnspsc190501.10413508': __('identifierSchemeCodeUnspsc190501.10413508'),
	'identifierSchemeCodeUnspsc190501.10413509': __('identifierSchemeCodeUnspsc190501.10413509'),
	'identifierSchemeCodeUnspsc190501.10413510': __('identifierSchemeCodeUnspsc190501.10413510'),
	'identifierSchemeCodeUnspsc190501.10413600': __('identifierSchemeCodeUnspsc190501.10413600'),
	'identifierSchemeCodeUnspsc190501.10413601': __('identifierSchemeCodeUnspsc190501.10413601'),
	'identifierSchemeCodeUnspsc190501.10413602': __('identifierSchemeCodeUnspsc190501.10413602'),
	'identifierSchemeCodeUnspsc190501.10413603': __('identifierSchemeCodeUnspsc190501.10413603'),
	'identifierSchemeCodeUnspsc190501.10413604': __('identifierSchemeCodeUnspsc190501.10413604'),
	'identifierSchemeCodeUnspsc190501.10413605': __('identifierSchemeCodeUnspsc190501.10413605'),
	'identifierSchemeCodeUnspsc190501.10413606': __('identifierSchemeCodeUnspsc190501.10413606'),
	'identifierSchemeCodeUnspsc190501.10413607': __('identifierSchemeCodeUnspsc190501.10413607'),
	'identifierSchemeCodeUnspsc190501.10413608': __('identifierSchemeCodeUnspsc190501.10413608'),
	'identifierSchemeCodeUnspsc190501.10413609': __('identifierSchemeCodeUnspsc190501.10413609'),
	'identifierSchemeCodeUnspsc190501.10413610': __('identifierSchemeCodeUnspsc190501.10413610'),
	'identifierSchemeCodeUnspsc190501.10413611': __('identifierSchemeCodeUnspsc190501.10413611'),
	'identifierSchemeCodeUnspsc190501.10413612': __('identifierSchemeCodeUnspsc190501.10413612'),
	'identifierSchemeCodeUnspsc190501.10413613': __('identifierSchemeCodeUnspsc190501.10413613'),
	'identifierSchemeCodeUnspsc190501.10413614': __('identifierSchemeCodeUnspsc190501.10413614'),
	'identifierSchemeCodeUnspsc190501.10413700': __('identifierSchemeCodeUnspsc190501.10413700'),
	'identifierSchemeCodeUnspsc190501.10413701': __('identifierSchemeCodeUnspsc190501.10413701'),
	'identifierSchemeCodeUnspsc190501.10413702': __('identifierSchemeCodeUnspsc190501.10413702'),
	'identifierSchemeCodeUnspsc190501.10413703': __('identifierSchemeCodeUnspsc190501.10413703'),
	'identifierSchemeCodeUnspsc190501.10413704': __('identifierSchemeCodeUnspsc190501.10413704'),
	'identifierSchemeCodeUnspsc190501.10413705': __('identifierSchemeCodeUnspsc190501.10413705'),
	'identifierSchemeCodeUnspsc190501.10413706': __('identifierSchemeCodeUnspsc190501.10413706'),
	'identifierSchemeCodeUnspsc190501.10413707': __('identifierSchemeCodeUnspsc190501.10413707'),
	'identifierSchemeCodeUnspsc190501.10413708': __('identifierSchemeCodeUnspsc190501.10413708'),
	'identifierSchemeCodeUnspsc190501.10413709': __('identifierSchemeCodeUnspsc190501.10413709'),
	'identifierSchemeCodeUnspsc190501.10413800': __('identifierSchemeCodeUnspsc190501.10413800'),
	'identifierSchemeCodeUnspsc190501.10413801': __('identifierSchemeCodeUnspsc190501.10413801'),
	'identifierSchemeCodeUnspsc190501.10413802': __('identifierSchemeCodeUnspsc190501.10413802'),
	'identifierSchemeCodeUnspsc190501.10413803': __('identifierSchemeCodeUnspsc190501.10413803'),
	'identifierSchemeCodeUnspsc190501.10413804': __('identifierSchemeCodeUnspsc190501.10413804'),
	'identifierSchemeCodeUnspsc190501.10413805': __('identifierSchemeCodeUnspsc190501.10413805'),
	'identifierSchemeCodeUnspsc190501.10413806': __('identifierSchemeCodeUnspsc190501.10413806'),
	'identifierSchemeCodeUnspsc190501.10413807': __('identifierSchemeCodeUnspsc190501.10413807'),
	'identifierSchemeCodeUnspsc190501.10413808': __('identifierSchemeCodeUnspsc190501.10413808'),
	'identifierSchemeCodeUnspsc190501.10413900': __('identifierSchemeCodeUnspsc190501.10413900'),
	'identifierSchemeCodeUnspsc190501.10413901': __('identifierSchemeCodeUnspsc190501.10413901'),
	'identifierSchemeCodeUnspsc190501.10413902': __('identifierSchemeCodeUnspsc190501.10413902'),
	'identifierSchemeCodeUnspsc190501.10413903': __('identifierSchemeCodeUnspsc190501.10413903'),
	'identifierSchemeCodeUnspsc190501.10413904': __('identifierSchemeCodeUnspsc190501.10413904'),
	'identifierSchemeCodeUnspsc190501.10413905': __('identifierSchemeCodeUnspsc190501.10413905'),
	'identifierSchemeCodeUnspsc190501.10413906': __('identifierSchemeCodeUnspsc190501.10413906'),
	'identifierSchemeCodeUnspsc190501.10413907': __('identifierSchemeCodeUnspsc190501.10413907'),
	'identifierSchemeCodeUnspsc190501.10413908': __('identifierSchemeCodeUnspsc190501.10413908'),
	'identifierSchemeCodeUnspsc190501.10413909': __('identifierSchemeCodeUnspsc190501.10413909'),
	'identifierSchemeCodeUnspsc190501.10413910': __('identifierSchemeCodeUnspsc190501.10413910'),
	'identifierSchemeCodeUnspsc190501.10413911': __('identifierSchemeCodeUnspsc190501.10413911'),
	'identifierSchemeCodeUnspsc190501.10413912': __('identifierSchemeCodeUnspsc190501.10413912'),
	'identifierSchemeCodeUnspsc190501.10413913': __('identifierSchemeCodeUnspsc190501.10413913'),
	'identifierSchemeCodeUnspsc190501.10413914': __('identifierSchemeCodeUnspsc190501.10413914'),
	'identifierSchemeCodeUnspsc190501.10413915': __('identifierSchemeCodeUnspsc190501.10413915'),
	'identifierSchemeCodeUnspsc190501.10413916': __('identifierSchemeCodeUnspsc190501.10413916'),
	'identifierSchemeCodeUnspsc190501.10413917': __('identifierSchemeCodeUnspsc190501.10413917'),
	'identifierSchemeCodeUnspsc190501.10413918': __('identifierSchemeCodeUnspsc190501.10413918'),
	'identifierSchemeCodeUnspsc190501.10413919': __('identifierSchemeCodeUnspsc190501.10413919'),
	'identifierSchemeCodeUnspsc190501.10413920': __('identifierSchemeCodeUnspsc190501.10413920'),
	'identifierSchemeCodeUnspsc190501.10413921': __('identifierSchemeCodeUnspsc190501.10413921'),
	'identifierSchemeCodeUnspsc190501.10413922': __('identifierSchemeCodeUnspsc190501.10413922'),
	'identifierSchemeCodeUnspsc190501.10413923': __('identifierSchemeCodeUnspsc190501.10413923'),
	'identifierSchemeCodeUnspsc190501.10413924': __('identifierSchemeCodeUnspsc190501.10413924'),
	'identifierSchemeCodeUnspsc190501.10413925': __('identifierSchemeCodeUnspsc190501.10413925'),
	'identifierSchemeCodeUnspsc190501.10413926': __('identifierSchemeCodeUnspsc190501.10413926'),
	'identifierSchemeCodeUnspsc190501.10413927': __('identifierSchemeCodeUnspsc190501.10413927'),
	'identifierSchemeCodeUnspsc190501.10413928': __('identifierSchemeCodeUnspsc190501.10413928'),
	'identifierSchemeCodeUnspsc190501.10413929': __('identifierSchemeCodeUnspsc190501.10413929'),
	'identifierSchemeCodeUnspsc190501.10414000': __('identifierSchemeCodeUnspsc190501.10414000'),
	'identifierSchemeCodeUnspsc190501.10414001': __('identifierSchemeCodeUnspsc190501.10414001'),
	'identifierSchemeCodeUnspsc190501.10414002': __('identifierSchemeCodeUnspsc190501.10414002'),
	'identifierSchemeCodeUnspsc190501.10414003': __('identifierSchemeCodeUnspsc190501.10414003'),
	'identifierSchemeCodeUnspsc190501.10414004': __('identifierSchemeCodeUnspsc190501.10414004'),
	'identifierSchemeCodeUnspsc190501.10414005': __('identifierSchemeCodeUnspsc190501.10414005'),
	'identifierSchemeCodeUnspsc190501.10414006': __('identifierSchemeCodeUnspsc190501.10414006'),
	'identifierSchemeCodeUnspsc190501.10414100': __('identifierSchemeCodeUnspsc190501.10414100'),
	'identifierSchemeCodeUnspsc190501.10414101': __('identifierSchemeCodeUnspsc190501.10414101'),
	'identifierSchemeCodeUnspsc190501.10414102': __('identifierSchemeCodeUnspsc190501.10414102'),
	'identifierSchemeCodeUnspsc190501.10414103': __('identifierSchemeCodeUnspsc190501.10414103'),
	'identifierSchemeCodeUnspsc190501.10414104': __('identifierSchemeCodeUnspsc190501.10414104'),
	'identifierSchemeCodeUnspsc190501.10414105': __('identifierSchemeCodeUnspsc190501.10414105'),
	'identifierSchemeCodeUnspsc190501.10414106': __('identifierSchemeCodeUnspsc190501.10414106'),
	'identifierSchemeCodeUnspsc190501.10414107': __('identifierSchemeCodeUnspsc190501.10414107'),
	'identifierSchemeCodeUnspsc190501.10414108': __('identifierSchemeCodeUnspsc190501.10414108'),
	'identifierSchemeCodeUnspsc190501.10414109': __('identifierSchemeCodeUnspsc190501.10414109'),
	'identifierSchemeCodeUnspsc190501.10414110': __('identifierSchemeCodeUnspsc190501.10414110'),
	'identifierSchemeCodeUnspsc190501.10414111': __('identifierSchemeCodeUnspsc190501.10414111'),
	'identifierSchemeCodeUnspsc190501.10414112': __('identifierSchemeCodeUnspsc190501.10414112'),
	'identifierSchemeCodeUnspsc190501.10414113': __('identifierSchemeCodeUnspsc190501.10414113'),
	'identifierSchemeCodeUnspsc190501.10414114': __('identifierSchemeCodeUnspsc190501.10414114'),
	'identifierSchemeCodeUnspsc190501.10414200': __('identifierSchemeCodeUnspsc190501.10414200'),
	'identifierSchemeCodeUnspsc190501.10414201': __('identifierSchemeCodeUnspsc190501.10414201'),
	'identifierSchemeCodeUnspsc190501.10414202': __('identifierSchemeCodeUnspsc190501.10414202'),
	'identifierSchemeCodeUnspsc190501.10414203': __('identifierSchemeCodeUnspsc190501.10414203'),
	'identifierSchemeCodeUnspsc190501.10414204': __('identifierSchemeCodeUnspsc190501.10414204'),
	'identifierSchemeCodeUnspsc190501.10414205': __('identifierSchemeCodeUnspsc190501.10414205'),
	'identifierSchemeCodeUnspsc190501.10414206': __('identifierSchemeCodeUnspsc190501.10414206'),
	'identifierSchemeCodeUnspsc190501.10414300': __('identifierSchemeCodeUnspsc190501.10414300'),
	'identifierSchemeCodeUnspsc190501.10414301': __('identifierSchemeCodeUnspsc190501.10414301'),
	'identifierSchemeCodeUnspsc190501.10414302': __('identifierSchemeCodeUnspsc190501.10414302'),
	'identifierSchemeCodeUnspsc190501.10414303': __('identifierSchemeCodeUnspsc190501.10414303'),
	'identifierSchemeCodeUnspsc190501.10414304': __('identifierSchemeCodeUnspsc190501.10414304'),
	'identifierSchemeCodeUnspsc190501.10414305': __('identifierSchemeCodeUnspsc190501.10414305'),
	'identifierSchemeCodeUnspsc190501.10414400': __('identifierSchemeCodeUnspsc190501.10414400'),
	'identifierSchemeCodeUnspsc190501.10414401': __('identifierSchemeCodeUnspsc190501.10414401'),
	'identifierSchemeCodeUnspsc190501.10414402': __('identifierSchemeCodeUnspsc190501.10414402'),
	'identifierSchemeCodeUnspsc190501.10414403': __('identifierSchemeCodeUnspsc190501.10414403'),
	'identifierSchemeCodeUnspsc190501.10414404': __('identifierSchemeCodeUnspsc190501.10414404'),
	'identifierSchemeCodeUnspsc190501.10414405': __('identifierSchemeCodeUnspsc190501.10414405'),
	'identifierSchemeCodeUnspsc190501.10414406': __('identifierSchemeCodeUnspsc190501.10414406'),
	'identifierSchemeCodeUnspsc190501.10414500': __('identifierSchemeCodeUnspsc190501.10414500'),
	'identifierSchemeCodeUnspsc190501.10414501': __('identifierSchemeCodeUnspsc190501.10414501'),
	'identifierSchemeCodeUnspsc190501.10414502': __('identifierSchemeCodeUnspsc190501.10414502'),
	'identifierSchemeCodeUnspsc190501.10414503': __('identifierSchemeCodeUnspsc190501.10414503'),
	'identifierSchemeCodeUnspsc190501.10414504': __('identifierSchemeCodeUnspsc190501.10414504'),
	'identifierSchemeCodeUnspsc190501.10414505': __('identifierSchemeCodeUnspsc190501.10414505'),
	'identifierSchemeCodeUnspsc190501.10414506': __('identifierSchemeCodeUnspsc190501.10414506'),
	'identifierSchemeCodeUnspsc190501.10414507': __('identifierSchemeCodeUnspsc190501.10414507'),
	'identifierSchemeCodeUnspsc190501.10414600': __('identifierSchemeCodeUnspsc190501.10414600'),
	'identifierSchemeCodeUnspsc190501.10414601': __('identifierSchemeCodeUnspsc190501.10414601'),
	'identifierSchemeCodeUnspsc190501.10414602': __('identifierSchemeCodeUnspsc190501.10414602'),
	'identifierSchemeCodeUnspsc190501.10414603': __('identifierSchemeCodeUnspsc190501.10414603'),
	'identifierSchemeCodeUnspsc190501.10414604': __('identifierSchemeCodeUnspsc190501.10414604'),
	'identifierSchemeCodeUnspsc190501.10414605': __('identifierSchemeCodeUnspsc190501.10414605'),
	'identifierSchemeCodeUnspsc190501.10414606': __('identifierSchemeCodeUnspsc190501.10414606'),
	'identifierSchemeCodeUnspsc190501.10414607': __('identifierSchemeCodeUnspsc190501.10414607'),
	'identifierSchemeCodeUnspsc190501.10414608': __('identifierSchemeCodeUnspsc190501.10414608'),
	'identifierSchemeCodeUnspsc190501.10414609': __('identifierSchemeCodeUnspsc190501.10414609'),
	'identifierSchemeCodeUnspsc190501.10414610': __('identifierSchemeCodeUnspsc190501.10414610'),
	'identifierSchemeCodeUnspsc190501.10414611': __('identifierSchemeCodeUnspsc190501.10414611'),
	'identifierSchemeCodeUnspsc190501.10414612': __('identifierSchemeCodeUnspsc190501.10414612'),
	'identifierSchemeCodeUnspsc190501.10414613': __('identifierSchemeCodeUnspsc190501.10414613'),
	'identifierSchemeCodeUnspsc190501.10414614': __('identifierSchemeCodeUnspsc190501.10414614'),
	'identifierSchemeCodeUnspsc190501.10414615': __('identifierSchemeCodeUnspsc190501.10414615'),
	'identifierSchemeCodeUnspsc190501.10414616': __('identifierSchemeCodeUnspsc190501.10414616'),
	'identifierSchemeCodeUnspsc190501.10414617': __('identifierSchemeCodeUnspsc190501.10414617'),
	'identifierSchemeCodeUnspsc190501.10414618': __('identifierSchemeCodeUnspsc190501.10414618'),
	'identifierSchemeCodeUnspsc190501.10414619': __('identifierSchemeCodeUnspsc190501.10414619'),
	'identifierSchemeCodeUnspsc190501.10414700': __('identifierSchemeCodeUnspsc190501.10414700'),
	'identifierSchemeCodeUnspsc190501.10414701': __('identifierSchemeCodeUnspsc190501.10414701'),
	'identifierSchemeCodeUnspsc190501.10414702': __('identifierSchemeCodeUnspsc190501.10414702'),
	'identifierSchemeCodeUnspsc190501.10414703': __('identifierSchemeCodeUnspsc190501.10414703'),
	'identifierSchemeCodeUnspsc190501.10414704': __('identifierSchemeCodeUnspsc190501.10414704'),
	'identifierSchemeCodeUnspsc190501.10414705': __('identifierSchemeCodeUnspsc190501.10414705'),
	'identifierSchemeCodeUnspsc190501.10414706': __('identifierSchemeCodeUnspsc190501.10414706'),
	'identifierSchemeCodeUnspsc190501.10414707': __('identifierSchemeCodeUnspsc190501.10414707'),
	'identifierSchemeCodeUnspsc190501.10414708': __('identifierSchemeCodeUnspsc190501.10414708'),
	'identifierSchemeCodeUnspsc190501.10414709': __('identifierSchemeCodeUnspsc190501.10414709'),
	'identifierSchemeCodeUnspsc190501.10414710': __('identifierSchemeCodeUnspsc190501.10414710'),
	'identifierSchemeCodeUnspsc190501.10414711': __('identifierSchemeCodeUnspsc190501.10414711'),
	'identifierSchemeCodeUnspsc190501.10414712': __('identifierSchemeCodeUnspsc190501.10414712'),
	'identifierSchemeCodeUnspsc190501.10414800': __('identifierSchemeCodeUnspsc190501.10414800'),
	'identifierSchemeCodeUnspsc190501.10414801': __('identifierSchemeCodeUnspsc190501.10414801'),
	'identifierSchemeCodeUnspsc190501.10414802': __('identifierSchemeCodeUnspsc190501.10414802'),
	'identifierSchemeCodeUnspsc190501.10414803': __('identifierSchemeCodeUnspsc190501.10414803'),
	'identifierSchemeCodeUnspsc190501.10414804': __('identifierSchemeCodeUnspsc190501.10414804'),
	'identifierSchemeCodeUnspsc190501.10414805': __('identifierSchemeCodeUnspsc190501.10414805'),
	'identifierSchemeCodeUnspsc190501.10414806': __('identifierSchemeCodeUnspsc190501.10414806'),
	'identifierSchemeCodeUnspsc190501.10414807': __('identifierSchemeCodeUnspsc190501.10414807'),
	'identifierSchemeCodeUnspsc190501.10414808': __('identifierSchemeCodeUnspsc190501.10414808'),
	'identifierSchemeCodeUnspsc190501.10414809': __('identifierSchemeCodeUnspsc190501.10414809'),
	'identifierSchemeCodeUnspsc190501.10414810': __('identifierSchemeCodeUnspsc190501.10414810'),
	'identifierSchemeCodeUnspsc190501.10414811': __('identifierSchemeCodeUnspsc190501.10414811'),
	'identifierSchemeCodeUnspsc190501.10414812': __('identifierSchemeCodeUnspsc190501.10414812'),
	'identifierSchemeCodeUnspsc190501.10414813': __('identifierSchemeCodeUnspsc190501.10414813'),
	'identifierSchemeCodeUnspsc190501.10414814': __('identifierSchemeCodeUnspsc190501.10414814'),
	'identifierSchemeCodeUnspsc190501.10414815': __('identifierSchemeCodeUnspsc190501.10414815'),
	'identifierSchemeCodeUnspsc190501.10414816': __('identifierSchemeCodeUnspsc190501.10414816'),
	'identifierSchemeCodeUnspsc190501.10414817': __('identifierSchemeCodeUnspsc190501.10414817'),
	'identifierSchemeCodeUnspsc190501.10414818': __('identifierSchemeCodeUnspsc190501.10414818'),
	'identifierSchemeCodeUnspsc190501.10414819': __('identifierSchemeCodeUnspsc190501.10414819'),
	'identifierSchemeCodeUnspsc190501.10414820': __('identifierSchemeCodeUnspsc190501.10414820'),
	'identifierSchemeCodeUnspsc190501.10414821': __('identifierSchemeCodeUnspsc190501.10414821'),
	'identifierSchemeCodeUnspsc190501.10414822': __('identifierSchemeCodeUnspsc190501.10414822'),
	'identifierSchemeCodeUnspsc190501.10414823': __('identifierSchemeCodeUnspsc190501.10414823'),
	'identifierSchemeCodeUnspsc190501.10414824': __('identifierSchemeCodeUnspsc190501.10414824'),
	'identifierSchemeCodeUnspsc190501.10414825': __('identifierSchemeCodeUnspsc190501.10414825'),
	'identifierSchemeCodeUnspsc190501.10414826': __('identifierSchemeCodeUnspsc190501.10414826'),
	'identifierSchemeCodeUnspsc190501.10414827': __('identifierSchemeCodeUnspsc190501.10414827'),
	'identifierSchemeCodeUnspsc190501.10414828': __('identifierSchemeCodeUnspsc190501.10414828'),
	'identifierSchemeCodeUnspsc190501.10414829': __('identifierSchemeCodeUnspsc190501.10414829'),
	'identifierSchemeCodeUnspsc190501.10414900': __('identifierSchemeCodeUnspsc190501.10414900'),
	'identifierSchemeCodeUnspsc190501.10414901': __('identifierSchemeCodeUnspsc190501.10414901'),
	'identifierSchemeCodeUnspsc190501.10414902': __('identifierSchemeCodeUnspsc190501.10414902'),
	'identifierSchemeCodeUnspsc190501.10414903': __('identifierSchemeCodeUnspsc190501.10414903'),
	'identifierSchemeCodeUnspsc190501.10414904': __('identifierSchemeCodeUnspsc190501.10414904'),
	'identifierSchemeCodeUnspsc190501.10414905': __('identifierSchemeCodeUnspsc190501.10414905'),
	'identifierSchemeCodeUnspsc190501.10414906': __('identifierSchemeCodeUnspsc190501.10414906'),
	'identifierSchemeCodeUnspsc190501.10414907': __('identifierSchemeCodeUnspsc190501.10414907'),
	'identifierSchemeCodeUnspsc190501.10414908': __('identifierSchemeCodeUnspsc190501.10414908'),
	'identifierSchemeCodeUnspsc190501.10414909': __('identifierSchemeCodeUnspsc190501.10414909'),
	'identifierSchemeCodeUnspsc190501.10414910': __('identifierSchemeCodeUnspsc190501.10414910'),
	'identifierSchemeCodeUnspsc190501.10414911': __('identifierSchemeCodeUnspsc190501.10414911'),
	'identifierSchemeCodeUnspsc190501.10414912': __('identifierSchemeCodeUnspsc190501.10414912'),
	'identifierSchemeCodeUnspsc190501.10414913': __('identifierSchemeCodeUnspsc190501.10414913'),
	'identifierSchemeCodeUnspsc190501.10414914': __('identifierSchemeCodeUnspsc190501.10414914'),
	'identifierSchemeCodeUnspsc190501.10414915': __('identifierSchemeCodeUnspsc190501.10414915'),
	'identifierSchemeCodeUnspsc190501.10414916': __('identifierSchemeCodeUnspsc190501.10414916'),
	'identifierSchemeCodeUnspsc190501.10414917': __('identifierSchemeCodeUnspsc190501.10414917'),
	'identifierSchemeCodeUnspsc190501.10414918': __('identifierSchemeCodeUnspsc190501.10414918'),
	'identifierSchemeCodeUnspsc190501.10414919': __('identifierSchemeCodeUnspsc190501.10414919'),
	'identifierSchemeCodeUnspsc190501.10415000': __('identifierSchemeCodeUnspsc190501.10415000'),
	'identifierSchemeCodeUnspsc190501.10415001': __('identifierSchemeCodeUnspsc190501.10415001'),
	'identifierSchemeCodeUnspsc190501.10415002': __('identifierSchemeCodeUnspsc190501.10415002'),
	'identifierSchemeCodeUnspsc190501.10415003': __('identifierSchemeCodeUnspsc190501.10415003'),
	'identifierSchemeCodeUnspsc190501.10415004': __('identifierSchemeCodeUnspsc190501.10415004'),
	'identifierSchemeCodeUnspsc190501.10415005': __('identifierSchemeCodeUnspsc190501.10415005'),
	'identifierSchemeCodeUnspsc190501.10415006': __('identifierSchemeCodeUnspsc190501.10415006'),
	'identifierSchemeCodeUnspsc190501.10415007': __('identifierSchemeCodeUnspsc190501.10415007'),
	'identifierSchemeCodeUnspsc190501.10415100': __('identifierSchemeCodeUnspsc190501.10415100'),
	'identifierSchemeCodeUnspsc190501.10415101': __('identifierSchemeCodeUnspsc190501.10415101'),
	'identifierSchemeCodeUnspsc190501.10415102': __('identifierSchemeCodeUnspsc190501.10415102'),
	'identifierSchemeCodeUnspsc190501.10415103': __('identifierSchemeCodeUnspsc190501.10415103'),
	'identifierSchemeCodeUnspsc190501.10415104': __('identifierSchemeCodeUnspsc190501.10415104'),
	'identifierSchemeCodeUnspsc190501.10415105': __('identifierSchemeCodeUnspsc190501.10415105'),
	'identifierSchemeCodeUnspsc190501.10415106': __('identifierSchemeCodeUnspsc190501.10415106'),
	'identifierSchemeCodeUnspsc190501.10415200': __('identifierSchemeCodeUnspsc190501.10415200'),
	'identifierSchemeCodeUnspsc190501.10415201': __('identifierSchemeCodeUnspsc190501.10415201'),
	'identifierSchemeCodeUnspsc190501.10415202': __('identifierSchemeCodeUnspsc190501.10415202'),
	'identifierSchemeCodeUnspsc190501.10415203': __('identifierSchemeCodeUnspsc190501.10415203'),
	'identifierSchemeCodeUnspsc190501.10415204': __('identifierSchemeCodeUnspsc190501.10415204'),
	'identifierSchemeCodeUnspsc190501.10415205': __('identifierSchemeCodeUnspsc190501.10415205'),
	'identifierSchemeCodeUnspsc190501.10415206': __('identifierSchemeCodeUnspsc190501.10415206'),
	'identifierSchemeCodeUnspsc190501.10415300': __('identifierSchemeCodeUnspsc190501.10415300'),
	'identifierSchemeCodeUnspsc190501.10415301': __('identifierSchemeCodeUnspsc190501.10415301'),
	'identifierSchemeCodeUnspsc190501.10415302': __('identifierSchemeCodeUnspsc190501.10415302'),
	'identifierSchemeCodeUnspsc190501.10415303': __('identifierSchemeCodeUnspsc190501.10415303'),
	'identifierSchemeCodeUnspsc190501.10415304': __('identifierSchemeCodeUnspsc190501.10415304'),
	'identifierSchemeCodeUnspsc190501.10415305': __('identifierSchemeCodeUnspsc190501.10415305'),
	'identifierSchemeCodeUnspsc190501.10415306': __('identifierSchemeCodeUnspsc190501.10415306'),
	'identifierSchemeCodeUnspsc190501.10415400': __('identifierSchemeCodeUnspsc190501.10415400'),
	'identifierSchemeCodeUnspsc190501.10415401': __('identifierSchemeCodeUnspsc190501.10415401'),
	'identifierSchemeCodeUnspsc190501.10415402': __('identifierSchemeCodeUnspsc190501.10415402'),
	'identifierSchemeCodeUnspsc190501.10415403': __('identifierSchemeCodeUnspsc190501.10415403'),
	'identifierSchemeCodeUnspsc190501.10415404': __('identifierSchemeCodeUnspsc190501.10415404'),
	'identifierSchemeCodeUnspsc190501.10415405': __('identifierSchemeCodeUnspsc190501.10415405'),
	'identifierSchemeCodeUnspsc190501.10415406': __('identifierSchemeCodeUnspsc190501.10415406'),
	'identifierSchemeCodeUnspsc190501.10415407': __('identifierSchemeCodeUnspsc190501.10415407'),
	'identifierSchemeCodeUnspsc190501.10415408': __('identifierSchemeCodeUnspsc190501.10415408'),
	'identifierSchemeCodeUnspsc190501.10415409': __('identifierSchemeCodeUnspsc190501.10415409'),
	'identifierSchemeCodeUnspsc190501.10415410': __('identifierSchemeCodeUnspsc190501.10415410'),
	'identifierSchemeCodeUnspsc190501.10415411': __('identifierSchemeCodeUnspsc190501.10415411'),
	'identifierSchemeCodeUnspsc190501.10415412': __('identifierSchemeCodeUnspsc190501.10415412'),
	'identifierSchemeCodeUnspsc190501.10415413': __('identifierSchemeCodeUnspsc190501.10415413'),
	'identifierSchemeCodeUnspsc190501.10415414': __('identifierSchemeCodeUnspsc190501.10415414'),
	'identifierSchemeCodeUnspsc190501.10415415': __('identifierSchemeCodeUnspsc190501.10415415'),
	'identifierSchemeCodeUnspsc190501.10415416': __('identifierSchemeCodeUnspsc190501.10415416'),
	'identifierSchemeCodeUnspsc190501.10415417': __('identifierSchemeCodeUnspsc190501.10415417'),
	'identifierSchemeCodeUnspsc190501.10415418': __('identifierSchemeCodeUnspsc190501.10415418'),
	'identifierSchemeCodeUnspsc190501.10415419': __('identifierSchemeCodeUnspsc190501.10415419'),
	'identifierSchemeCodeUnspsc190501.10415420': __('identifierSchemeCodeUnspsc190501.10415420'),
	'identifierSchemeCodeUnspsc190501.10415421': __('identifierSchemeCodeUnspsc190501.10415421'),
	'identifierSchemeCodeUnspsc190501.10415422': __('identifierSchemeCodeUnspsc190501.10415422'),
	'identifierSchemeCodeUnspsc190501.10415423': __('identifierSchemeCodeUnspsc190501.10415423'),
	'identifierSchemeCodeUnspsc190501.10415424': __('identifierSchemeCodeUnspsc190501.10415424'),
	'identifierSchemeCodeUnspsc190501.10415425': __('identifierSchemeCodeUnspsc190501.10415425'),
	'identifierSchemeCodeUnspsc190501.10415426': __('identifierSchemeCodeUnspsc190501.10415426'),
	'identifierSchemeCodeUnspsc190501.10415427': __('identifierSchemeCodeUnspsc190501.10415427'),
	'identifierSchemeCodeUnspsc190501.10415428': __('identifierSchemeCodeUnspsc190501.10415428'),
	'identifierSchemeCodeUnspsc190501.10415429': __('identifierSchemeCodeUnspsc190501.10415429'),
	'identifierSchemeCodeUnspsc190501.10415430': __('identifierSchemeCodeUnspsc190501.10415430'),
	'identifierSchemeCodeUnspsc190501.10415431': __('identifierSchemeCodeUnspsc190501.10415431'),
	'identifierSchemeCodeUnspsc190501.10415432': __('identifierSchemeCodeUnspsc190501.10415432'),
	'identifierSchemeCodeUnspsc190501.10415433': __('identifierSchemeCodeUnspsc190501.10415433'),
	'identifierSchemeCodeUnspsc190501.10415434': __('identifierSchemeCodeUnspsc190501.10415434'),
	'identifierSchemeCodeUnspsc190501.10415435': __('identifierSchemeCodeUnspsc190501.10415435'),
	'identifierSchemeCodeUnspsc190501.10415436': __('identifierSchemeCodeUnspsc190501.10415436'),
	'identifierSchemeCodeUnspsc190501.10415437': __('identifierSchemeCodeUnspsc190501.10415437'),
	'identifierSchemeCodeUnspsc190501.10415438': __('identifierSchemeCodeUnspsc190501.10415438'),
	'identifierSchemeCodeUnspsc190501.10415439': __('identifierSchemeCodeUnspsc190501.10415439'),
	'identifierSchemeCodeUnspsc190501.10415440': __('identifierSchemeCodeUnspsc190501.10415440'),
	'identifierSchemeCodeUnspsc190501.10415441': __('identifierSchemeCodeUnspsc190501.10415441'),
	'identifierSchemeCodeUnspsc190501.10415442': __('identifierSchemeCodeUnspsc190501.10415442'),
	'identifierSchemeCodeUnspsc190501.10415443': __('identifierSchemeCodeUnspsc190501.10415443'),
	'identifierSchemeCodeUnspsc190501.10415444': __('identifierSchemeCodeUnspsc190501.10415444'),
	'identifierSchemeCodeUnspsc190501.10415445': __('identifierSchemeCodeUnspsc190501.10415445'),
	'identifierSchemeCodeUnspsc190501.10415446': __('identifierSchemeCodeUnspsc190501.10415446'),
	'identifierSchemeCodeUnspsc190501.10415447': __('identifierSchemeCodeUnspsc190501.10415447'),
	'identifierSchemeCodeUnspsc190501.10415448': __('identifierSchemeCodeUnspsc190501.10415448'),
	'identifierSchemeCodeUnspsc190501.10415449': __('identifierSchemeCodeUnspsc190501.10415449'),
	'identifierSchemeCodeUnspsc190501.10415450': __('identifierSchemeCodeUnspsc190501.10415450'),
	'identifierSchemeCodeUnspsc190501.10415451': __('identifierSchemeCodeUnspsc190501.10415451'),
	'identifierSchemeCodeUnspsc190501.10415452': __('identifierSchemeCodeUnspsc190501.10415452'),
	'identifierSchemeCodeUnspsc190501.10415453': __('identifierSchemeCodeUnspsc190501.10415453'),
	'identifierSchemeCodeUnspsc190501.10415454': __('identifierSchemeCodeUnspsc190501.10415454'),
	'identifierSchemeCodeUnspsc190501.10415455': __('identifierSchemeCodeUnspsc190501.10415455'),
	'identifierSchemeCodeUnspsc190501.10415456': __('identifierSchemeCodeUnspsc190501.10415456'),
	'identifierSchemeCodeUnspsc190501.10415457': __('identifierSchemeCodeUnspsc190501.10415457'),
	'identifierSchemeCodeUnspsc190501.10415458': __('identifierSchemeCodeUnspsc190501.10415458'),
	'identifierSchemeCodeUnspsc190501.10415459': __('identifierSchemeCodeUnspsc190501.10415459'),
	'identifierSchemeCodeUnspsc190501.10415460': __('identifierSchemeCodeUnspsc190501.10415460'),
	'identifierSchemeCodeUnspsc190501.10415461': __('identifierSchemeCodeUnspsc190501.10415461'),
	'identifierSchemeCodeUnspsc190501.10415462': __('identifierSchemeCodeUnspsc190501.10415462'),
	'identifierSchemeCodeUnspsc190501.10415463': __('identifierSchemeCodeUnspsc190501.10415463'),
	'identifierSchemeCodeUnspsc190501.10415464': __('identifierSchemeCodeUnspsc190501.10415464'),
	'identifierSchemeCodeUnspsc190501.10415465': __('identifierSchemeCodeUnspsc190501.10415465'),
	'identifierSchemeCodeUnspsc190501.10415466': __('identifierSchemeCodeUnspsc190501.10415466'),
	'identifierSchemeCodeUnspsc190501.10415467': __('identifierSchemeCodeUnspsc190501.10415467'),
	'identifierSchemeCodeUnspsc190501.10415468': __('identifierSchemeCodeUnspsc190501.10415468'),
	'identifierSchemeCodeUnspsc190501.10415469': __('identifierSchemeCodeUnspsc190501.10415469'),
	'identifierSchemeCodeUnspsc190501.10415470': __('identifierSchemeCodeUnspsc190501.10415470'),
	'identifierSchemeCodeUnspsc190501.10415471': __('identifierSchemeCodeUnspsc190501.10415471'),
	'identifierSchemeCodeUnspsc190501.10415472': __('identifierSchemeCodeUnspsc190501.10415472'),
	'identifierSchemeCodeUnspsc190501.10415473': __('identifierSchemeCodeUnspsc190501.10415473'),
	'identifierSchemeCodeUnspsc190501.10415474': __('identifierSchemeCodeUnspsc190501.10415474'),
	'identifierSchemeCodeUnspsc190501.10415475': __('identifierSchemeCodeUnspsc190501.10415475'),
	'identifierSchemeCodeUnspsc190501.10415476': __('identifierSchemeCodeUnspsc190501.10415476'),
	'identifierSchemeCodeUnspsc190501.10415477': __('identifierSchemeCodeUnspsc190501.10415477'),
	'identifierSchemeCodeUnspsc190501.10415478': __('identifierSchemeCodeUnspsc190501.10415478'),
	'identifierSchemeCodeUnspsc190501.10415479': __('identifierSchemeCodeUnspsc190501.10415479'),
	'identifierSchemeCodeUnspsc190501.10415480': __('identifierSchemeCodeUnspsc190501.10415480'),
	'identifierSchemeCodeUnspsc190501.10415481': __('identifierSchemeCodeUnspsc190501.10415481'),
	'identifierSchemeCodeUnspsc190501.10415482': __('identifierSchemeCodeUnspsc190501.10415482'),
	'identifierSchemeCodeUnspsc190501.10415483': __('identifierSchemeCodeUnspsc190501.10415483'),
	'identifierSchemeCodeUnspsc190501.10415484': __('identifierSchemeCodeUnspsc190501.10415484'),
	'identifierSchemeCodeUnspsc190501.10415500': __('identifierSchemeCodeUnspsc190501.10415500'),
	'identifierSchemeCodeUnspsc190501.10415501': __('identifierSchemeCodeUnspsc190501.10415501'),
	'identifierSchemeCodeUnspsc190501.10415502': __('identifierSchemeCodeUnspsc190501.10415502'),
	'identifierSchemeCodeUnspsc190501.10415503': __('identifierSchemeCodeUnspsc190501.10415503'),
	'identifierSchemeCodeUnspsc190501.10415504': __('identifierSchemeCodeUnspsc190501.10415504'),
	'identifierSchemeCodeUnspsc190501.10415505': __('identifierSchemeCodeUnspsc190501.10415505'),
	'identifierSchemeCodeUnspsc190501.10415506': __('identifierSchemeCodeUnspsc190501.10415506'),
	'identifierSchemeCodeUnspsc190501.10415600': __('identifierSchemeCodeUnspsc190501.10415600'),
	'identifierSchemeCodeUnspsc190501.10415601': __('identifierSchemeCodeUnspsc190501.10415601'),
	'identifierSchemeCodeUnspsc190501.10415602': __('identifierSchemeCodeUnspsc190501.10415602'),
	'identifierSchemeCodeUnspsc190501.10415603': __('identifierSchemeCodeUnspsc190501.10415603'),
	'identifierSchemeCodeUnspsc190501.10415604': __('identifierSchemeCodeUnspsc190501.10415604'),
	'identifierSchemeCodeUnspsc190501.10415605': __('identifierSchemeCodeUnspsc190501.10415605'),
	'identifierSchemeCodeUnspsc190501.10415606': __('identifierSchemeCodeUnspsc190501.10415606'),
	'identifierSchemeCodeUnspsc190501.10415607': __('identifierSchemeCodeUnspsc190501.10415607'),
	'identifierSchemeCodeUnspsc190501.10415608': __('identifierSchemeCodeUnspsc190501.10415608'),
	'identifierSchemeCodeUnspsc190501.10415609': __('identifierSchemeCodeUnspsc190501.10415609'),
	'identifierSchemeCodeUnspsc190501.10415610': __('identifierSchemeCodeUnspsc190501.10415610'),
	'identifierSchemeCodeUnspsc190501.10415611': __('identifierSchemeCodeUnspsc190501.10415611'),
	'identifierSchemeCodeUnspsc190501.10415612': __('identifierSchemeCodeUnspsc190501.10415612'),
	'identifierSchemeCodeUnspsc190501.10415613': __('identifierSchemeCodeUnspsc190501.10415613'),
	'identifierSchemeCodeUnspsc190501.10415700': __('identifierSchemeCodeUnspsc190501.10415700'),
	'identifierSchemeCodeUnspsc190501.10415701': __('identifierSchemeCodeUnspsc190501.10415701'),
	'identifierSchemeCodeUnspsc190501.10415702': __('identifierSchemeCodeUnspsc190501.10415702'),
	'identifierSchemeCodeUnspsc190501.10415703': __('identifierSchemeCodeUnspsc190501.10415703'),
	'identifierSchemeCodeUnspsc190501.10415704': __('identifierSchemeCodeUnspsc190501.10415704'),
	'identifierSchemeCodeUnspsc190501.10415705': __('identifierSchemeCodeUnspsc190501.10415705'),
	'identifierSchemeCodeUnspsc190501.10415800': __('identifierSchemeCodeUnspsc190501.10415800'),
	'identifierSchemeCodeUnspsc190501.10415801': __('identifierSchemeCodeUnspsc190501.10415801'),
	'identifierSchemeCodeUnspsc190501.10415802': __('identifierSchemeCodeUnspsc190501.10415802'),
	'identifierSchemeCodeUnspsc190501.10415803': __('identifierSchemeCodeUnspsc190501.10415803'),
	'identifierSchemeCodeUnspsc190501.10415804': __('identifierSchemeCodeUnspsc190501.10415804'),
	'identifierSchemeCodeUnspsc190501.10415805': __('identifierSchemeCodeUnspsc190501.10415805'),
	'identifierSchemeCodeUnspsc190501.10415806': __('identifierSchemeCodeUnspsc190501.10415806'),
	'identifierSchemeCodeUnspsc190501.10415807': __('identifierSchemeCodeUnspsc190501.10415807'),
	'identifierSchemeCodeUnspsc190501.10415808': __('identifierSchemeCodeUnspsc190501.10415808'),
	'identifierSchemeCodeUnspsc190501.10416000': __('identifierSchemeCodeUnspsc190501.10416000'),
	'identifierSchemeCodeUnspsc190501.10416001': __('identifierSchemeCodeUnspsc190501.10416001'),
	'identifierSchemeCodeUnspsc190501.10416002': __('identifierSchemeCodeUnspsc190501.10416002'),
	'identifierSchemeCodeUnspsc190501.10416003': __('identifierSchemeCodeUnspsc190501.10416003'),
	'identifierSchemeCodeUnspsc190501.10416004': __('identifierSchemeCodeUnspsc190501.10416004'),
	'identifierSchemeCodeUnspsc190501.10416005': __('identifierSchemeCodeUnspsc190501.10416005'),
	'identifierSchemeCodeUnspsc190501.10416100': __('identifierSchemeCodeUnspsc190501.10416100'),
	'identifierSchemeCodeUnspsc190501.10416101': __('identifierSchemeCodeUnspsc190501.10416101'),
	'identifierSchemeCodeUnspsc190501.10416102': __('identifierSchemeCodeUnspsc190501.10416102'),
	'identifierSchemeCodeUnspsc190501.10416103': __('identifierSchemeCodeUnspsc190501.10416103'),
	'identifierSchemeCodeUnspsc190501.10416104': __('identifierSchemeCodeUnspsc190501.10416104'),
	'identifierSchemeCodeUnspsc190501.10416105': __('identifierSchemeCodeUnspsc190501.10416105'),
	'identifierSchemeCodeUnspsc190501.10416200': __('identifierSchemeCodeUnspsc190501.10416200'),
	'identifierSchemeCodeUnspsc190501.10416201': __('identifierSchemeCodeUnspsc190501.10416201'),
	'identifierSchemeCodeUnspsc190501.10416202': __('identifierSchemeCodeUnspsc190501.10416202'),
	'identifierSchemeCodeUnspsc190501.10416203': __('identifierSchemeCodeUnspsc190501.10416203'),
	'identifierSchemeCodeUnspsc190501.10416204': __('identifierSchemeCodeUnspsc190501.10416204'),
	'identifierSchemeCodeUnspsc190501.10416205': __('identifierSchemeCodeUnspsc190501.10416205'),
	'identifierSchemeCodeUnspsc190501.10416206': __('identifierSchemeCodeUnspsc190501.10416206'),
	'identifierSchemeCodeUnspsc190501.10416207': __('identifierSchemeCodeUnspsc190501.10416207'),
	'identifierSchemeCodeUnspsc190501.10416208': __('identifierSchemeCodeUnspsc190501.10416208'),
	'identifierSchemeCodeUnspsc190501.10416209': __('identifierSchemeCodeUnspsc190501.10416209'),
	'identifierSchemeCodeUnspsc190501.10416210': __('identifierSchemeCodeUnspsc190501.10416210'),
	'identifierSchemeCodeUnspsc190501.10416211': __('identifierSchemeCodeUnspsc190501.10416211'),
	'identifierSchemeCodeUnspsc190501.10416212': __('identifierSchemeCodeUnspsc190501.10416212'),
	'identifierSchemeCodeUnspsc190501.10416213': __('identifierSchemeCodeUnspsc190501.10416213'),
	'identifierSchemeCodeUnspsc190501.10416214': __('identifierSchemeCodeUnspsc190501.10416214'),
	'identifierSchemeCodeUnspsc190501.10416215': __('identifierSchemeCodeUnspsc190501.10416215'),
	'identifierSchemeCodeUnspsc190501.10416216': __('identifierSchemeCodeUnspsc190501.10416216'),
	'identifierSchemeCodeUnspsc190501.10416217': __('identifierSchemeCodeUnspsc190501.10416217'),
	'identifierSchemeCodeUnspsc190501.10416218': __('identifierSchemeCodeUnspsc190501.10416218'),
	'identifierSchemeCodeUnspsc190501.10416300': __('identifierSchemeCodeUnspsc190501.10416300'),
	'identifierSchemeCodeUnspsc190501.10416301': __('identifierSchemeCodeUnspsc190501.10416301'),
	'identifierSchemeCodeUnspsc190501.10416302': __('identifierSchemeCodeUnspsc190501.10416302'),
	'identifierSchemeCodeUnspsc190501.10416306': __('identifierSchemeCodeUnspsc190501.10416306'),
	'identifierSchemeCodeUnspsc190501.10416311': __('identifierSchemeCodeUnspsc190501.10416311'),
	'identifierSchemeCodeUnspsc190501.10416315': __('identifierSchemeCodeUnspsc190501.10416315'),
	'identifierSchemeCodeUnspsc190501.10416322': __('identifierSchemeCodeUnspsc190501.10416322'),
	'identifierSchemeCodeUnspsc190501.10416325': __('identifierSchemeCodeUnspsc190501.10416325'),
	'identifierSchemeCodeUnspsc190501.10416326': __('identifierSchemeCodeUnspsc190501.10416326'),
	'identifierSchemeCodeUnspsc190501.10416332': __('identifierSchemeCodeUnspsc190501.10416332'),
	'identifierSchemeCodeUnspsc190501.10416400': __('identifierSchemeCodeUnspsc190501.10416400'),
	'identifierSchemeCodeUnspsc190501.10416401': __('identifierSchemeCodeUnspsc190501.10416401'),
	'identifierSchemeCodeUnspsc190501.10416402': __('identifierSchemeCodeUnspsc190501.10416402'),
	'identifierSchemeCodeUnspsc190501.10416403': __('identifierSchemeCodeUnspsc190501.10416403'),
	'identifierSchemeCodeUnspsc190501.10416404': __('identifierSchemeCodeUnspsc190501.10416404'),
	'identifierSchemeCodeUnspsc190501.10416405': __('identifierSchemeCodeUnspsc190501.10416405'),
	'identifierSchemeCodeUnspsc190501.10416406': __('identifierSchemeCodeUnspsc190501.10416406'),
	'identifierSchemeCodeUnspsc190501.10416407': __('identifierSchemeCodeUnspsc190501.10416407'),
	'identifierSchemeCodeUnspsc190501.10416408': __('identifierSchemeCodeUnspsc190501.10416408'),
	'identifierSchemeCodeUnspsc190501.10416409': __('identifierSchemeCodeUnspsc190501.10416409'),
	'identifierSchemeCodeUnspsc190501.10416410': __('identifierSchemeCodeUnspsc190501.10416410'),
	'identifierSchemeCodeUnspsc190501.10416411': __('identifierSchemeCodeUnspsc190501.10416411'),
	'identifierSchemeCodeUnspsc190501.10416412': __('identifierSchemeCodeUnspsc190501.10416412'),
	'identifierSchemeCodeUnspsc190501.10416413': __('identifierSchemeCodeUnspsc190501.10416413'),
	'identifierSchemeCodeUnspsc190501.10416500': __('identifierSchemeCodeUnspsc190501.10416500'),
	'identifierSchemeCodeUnspsc190501.10416501': __('identifierSchemeCodeUnspsc190501.10416501'),
	'identifierSchemeCodeUnspsc190501.10416502': __('identifierSchemeCodeUnspsc190501.10416502'),
	'identifierSchemeCodeUnspsc190501.10416503': __('identifierSchemeCodeUnspsc190501.10416503'),
	'identifierSchemeCodeUnspsc190501.10416504': __('identifierSchemeCodeUnspsc190501.10416504'),
	'identifierSchemeCodeUnspsc190501.10416505': __('identifierSchemeCodeUnspsc190501.10416505'),
	'identifierSchemeCodeUnspsc190501.10416506': __('identifierSchemeCodeUnspsc190501.10416506'),
	'identifierSchemeCodeUnspsc190501.10416507': __('identifierSchemeCodeUnspsc190501.10416507'),
	'identifierSchemeCodeUnspsc190501.10416600': __('identifierSchemeCodeUnspsc190501.10416600'),
	'identifierSchemeCodeUnspsc190501.10416601': __('identifierSchemeCodeUnspsc190501.10416601'),
	'identifierSchemeCodeUnspsc190501.10416602': __('identifierSchemeCodeUnspsc190501.10416602'),
	'identifierSchemeCodeUnspsc190501.10416603': __('identifierSchemeCodeUnspsc190501.10416603'),
	'identifierSchemeCodeUnspsc190501.10416604': __('identifierSchemeCodeUnspsc190501.10416604'),
	'identifierSchemeCodeUnspsc190501.10416700': __('identifierSchemeCodeUnspsc190501.10416700'),
	'identifierSchemeCodeUnspsc190501.10416701': __('identifierSchemeCodeUnspsc190501.10416701'),
	'identifierSchemeCodeUnspsc190501.10416702': __('identifierSchemeCodeUnspsc190501.10416702'),
	'identifierSchemeCodeUnspsc190501.10416703': __('identifierSchemeCodeUnspsc190501.10416703'),
	'identifierSchemeCodeUnspsc190501.10416704': __('identifierSchemeCodeUnspsc190501.10416704'),
	'identifierSchemeCodeUnspsc190501.10416705': __('identifierSchemeCodeUnspsc190501.10416705'),
	'identifierSchemeCodeUnspsc190501.10416706': __('identifierSchemeCodeUnspsc190501.10416706'),
	'identifierSchemeCodeUnspsc190501.10416707': __('identifierSchemeCodeUnspsc190501.10416707'),
	'identifierSchemeCodeUnspsc190501.10416708': __('identifierSchemeCodeUnspsc190501.10416708'),
	'identifierSchemeCodeUnspsc190501.10416709': __('identifierSchemeCodeUnspsc190501.10416709'),
	'identifierSchemeCodeUnspsc190501.10416800': __('identifierSchemeCodeUnspsc190501.10416800'),
	'identifierSchemeCodeUnspsc190501.10416801': __('identifierSchemeCodeUnspsc190501.10416801'),
	'identifierSchemeCodeUnspsc190501.10416802': __('identifierSchemeCodeUnspsc190501.10416802'),
	'identifierSchemeCodeUnspsc190501.10416803': __('identifierSchemeCodeUnspsc190501.10416803'),
	'identifierSchemeCodeUnspsc190501.10416804': __('identifierSchemeCodeUnspsc190501.10416804'),
	'identifierSchemeCodeUnspsc190501.10416805': __('identifierSchemeCodeUnspsc190501.10416805'),
	'identifierSchemeCodeUnspsc190501.10416806': __('identifierSchemeCodeUnspsc190501.10416806'),
	'identifierSchemeCodeUnspsc190501.10416807': __('identifierSchemeCodeUnspsc190501.10416807'),
	'identifierSchemeCodeUnspsc190501.10416808': __('identifierSchemeCodeUnspsc190501.10416808'),
	'identifierSchemeCodeUnspsc190501.10416900': __('identifierSchemeCodeUnspsc190501.10416900'),
	'identifierSchemeCodeUnspsc190501.10416901': __('identifierSchemeCodeUnspsc190501.10416901'),
	'identifierSchemeCodeUnspsc190501.10416902': __('identifierSchemeCodeUnspsc190501.10416902'),
	'identifierSchemeCodeUnspsc190501.10416903': __('identifierSchemeCodeUnspsc190501.10416903'),
	'identifierSchemeCodeUnspsc190501.10416904': __('identifierSchemeCodeUnspsc190501.10416904'),
	'identifierSchemeCodeUnspsc190501.10416905': __('identifierSchemeCodeUnspsc190501.10416905'),
	'identifierSchemeCodeUnspsc190501.10416906': __('identifierSchemeCodeUnspsc190501.10416906'),
	'identifierSchemeCodeUnspsc190501.10416907': __('identifierSchemeCodeUnspsc190501.10416907'),
	'identifierSchemeCodeUnspsc190501.10416908': __('identifierSchemeCodeUnspsc190501.10416908'),
	'identifierSchemeCodeUnspsc190501.10416909': __('identifierSchemeCodeUnspsc190501.10416909'),
	'identifierSchemeCodeUnspsc190501.10416910': __('identifierSchemeCodeUnspsc190501.10416910'),
	'identifierSchemeCodeUnspsc190501.10417000': __('identifierSchemeCodeUnspsc190501.10417000'),
	'identifierSchemeCodeUnspsc190501.10417001': __('identifierSchemeCodeUnspsc190501.10417001'),
	'identifierSchemeCodeUnspsc190501.10417002': __('identifierSchemeCodeUnspsc190501.10417002'),
	'identifierSchemeCodeUnspsc190501.10417003': __('identifierSchemeCodeUnspsc190501.10417003'),
	'identifierSchemeCodeUnspsc190501.10417004': __('identifierSchemeCodeUnspsc190501.10417004'),
	'identifierSchemeCodeUnspsc190501.10417005': __('identifierSchemeCodeUnspsc190501.10417005'),
	'identifierSchemeCodeUnspsc190501.10417006': __('identifierSchemeCodeUnspsc190501.10417006'),
	'identifierSchemeCodeUnspsc190501.10417100': __('identifierSchemeCodeUnspsc190501.10417100'),
	'identifierSchemeCodeUnspsc190501.10417101': __('identifierSchemeCodeUnspsc190501.10417101'),
	'identifierSchemeCodeUnspsc190501.10417102': __('identifierSchemeCodeUnspsc190501.10417102'),
	'identifierSchemeCodeUnspsc190501.10417103': __('identifierSchemeCodeUnspsc190501.10417103'),
	'identifierSchemeCodeUnspsc190501.10417104': __('identifierSchemeCodeUnspsc190501.10417104'),
	'identifierSchemeCodeUnspsc190501.10417105': __('identifierSchemeCodeUnspsc190501.10417105'),
	'identifierSchemeCodeUnspsc190501.10417106': __('identifierSchemeCodeUnspsc190501.10417106'),
	'identifierSchemeCodeUnspsc190501.10417107': __('identifierSchemeCodeUnspsc190501.10417107'),
	'identifierSchemeCodeUnspsc190501.10417108': __('identifierSchemeCodeUnspsc190501.10417108'),
	'identifierSchemeCodeUnspsc190501.10417200': __('identifierSchemeCodeUnspsc190501.10417200'),
	'identifierSchemeCodeUnspsc190501.10417201': __('identifierSchemeCodeUnspsc190501.10417201'),
	'identifierSchemeCodeUnspsc190501.10417202': __('identifierSchemeCodeUnspsc190501.10417202'),
	'identifierSchemeCodeUnspsc190501.10417203': __('identifierSchemeCodeUnspsc190501.10417203'),
	'identifierSchemeCodeUnspsc190501.10417204': __('identifierSchemeCodeUnspsc190501.10417204'),
	'identifierSchemeCodeUnspsc190501.10417205': __('identifierSchemeCodeUnspsc190501.10417205'),
	'identifierSchemeCodeUnspsc190501.10417206': __('identifierSchemeCodeUnspsc190501.10417206'),
	'identifierSchemeCodeUnspsc190501.10417207': __('identifierSchemeCodeUnspsc190501.10417207'),
	'identifierSchemeCodeUnspsc190501.10417208': __('identifierSchemeCodeUnspsc190501.10417208'),
	'identifierSchemeCodeUnspsc190501.10417300': __('identifierSchemeCodeUnspsc190501.10417300'),
	'identifierSchemeCodeUnspsc190501.10417301': __('identifierSchemeCodeUnspsc190501.10417301'),
	'identifierSchemeCodeUnspsc190501.10417302': __('identifierSchemeCodeUnspsc190501.10417302'),
	'identifierSchemeCodeUnspsc190501.10417303': __('identifierSchemeCodeUnspsc190501.10417303'),
	'identifierSchemeCodeUnspsc190501.10417304': __('identifierSchemeCodeUnspsc190501.10417304'),
	'identifierSchemeCodeUnspsc190501.10417305': __('identifierSchemeCodeUnspsc190501.10417305'),
	'identifierSchemeCodeUnspsc190501.10417306': __('identifierSchemeCodeUnspsc190501.10417306'),
	'identifierSchemeCodeUnspsc190501.10417307': __('identifierSchemeCodeUnspsc190501.10417307'),
	'identifierSchemeCodeUnspsc190501.10417308': __('identifierSchemeCodeUnspsc190501.10417308'),
	'identifierSchemeCodeUnspsc190501.10417309': __('identifierSchemeCodeUnspsc190501.10417309'),
	'identifierSchemeCodeUnspsc190501.10417310': __('identifierSchemeCodeUnspsc190501.10417310'),
	'identifierSchemeCodeUnspsc190501.10417311': __('identifierSchemeCodeUnspsc190501.10417311'),
	'identifierSchemeCodeUnspsc190501.10417312': __('identifierSchemeCodeUnspsc190501.10417312'),
	'identifierSchemeCodeUnspsc190501.10417313': __('identifierSchemeCodeUnspsc190501.10417313'),
	'identifierSchemeCodeUnspsc190501.10417314': __('identifierSchemeCodeUnspsc190501.10417314'),
	'identifierSchemeCodeUnspsc190501.10417315': __('identifierSchemeCodeUnspsc190501.10417315'),
	'identifierSchemeCodeUnspsc190501.10417316': __('identifierSchemeCodeUnspsc190501.10417316'),
	'identifierSchemeCodeUnspsc190501.10417317': __('identifierSchemeCodeUnspsc190501.10417317'),
	'identifierSchemeCodeUnspsc190501.10417318': __('identifierSchemeCodeUnspsc190501.10417318'),
	'identifierSchemeCodeUnspsc190501.10417319': __('identifierSchemeCodeUnspsc190501.10417319'),
	'identifierSchemeCodeUnspsc190501.10417320': __('identifierSchemeCodeUnspsc190501.10417320'),
	'identifierSchemeCodeUnspsc190501.10417321': __('identifierSchemeCodeUnspsc190501.10417321'),
	'identifierSchemeCodeUnspsc190501.10417322': __('identifierSchemeCodeUnspsc190501.10417322'),
	'identifierSchemeCodeUnspsc190501.10417323': __('identifierSchemeCodeUnspsc190501.10417323'),
	'identifierSchemeCodeUnspsc190501.10417324': __('identifierSchemeCodeUnspsc190501.10417324'),
	'identifierSchemeCodeUnspsc190501.10417325': __('identifierSchemeCodeUnspsc190501.10417325'),
	'identifierSchemeCodeUnspsc190501.10417326': __('identifierSchemeCodeUnspsc190501.10417326'),
	'identifierSchemeCodeUnspsc190501.10417327': __('identifierSchemeCodeUnspsc190501.10417327'),
	'identifierSchemeCodeUnspsc190501.10417328': __('identifierSchemeCodeUnspsc190501.10417328'),
	'identifierSchemeCodeUnspsc190501.10417329': __('identifierSchemeCodeUnspsc190501.10417329'),
	'identifierSchemeCodeUnspsc190501.10417330': __('identifierSchemeCodeUnspsc190501.10417330'),
	'identifierSchemeCodeUnspsc190501.10417331': __('identifierSchemeCodeUnspsc190501.10417331'),
	'identifierSchemeCodeUnspsc190501.10417332': __('identifierSchemeCodeUnspsc190501.10417332'),
	'identifierSchemeCodeUnspsc190501.10417333': __('identifierSchemeCodeUnspsc190501.10417333'),
	'identifierSchemeCodeUnspsc190501.10417334': __('identifierSchemeCodeUnspsc190501.10417334'),
	'identifierSchemeCodeUnspsc190501.10417335': __('identifierSchemeCodeUnspsc190501.10417335'),
	'identifierSchemeCodeUnspsc190501.10417336': __('identifierSchemeCodeUnspsc190501.10417336'),
	'identifierSchemeCodeUnspsc190501.10417337': __('identifierSchemeCodeUnspsc190501.10417337'),
	'identifierSchemeCodeUnspsc190501.10417338': __('identifierSchemeCodeUnspsc190501.10417338'),
	'identifierSchemeCodeUnspsc190501.10417339': __('identifierSchemeCodeUnspsc190501.10417339'),
	'identifierSchemeCodeUnspsc190501.10417340': __('identifierSchemeCodeUnspsc190501.10417340'),
	'identifierSchemeCodeUnspsc190501.10417341': __('identifierSchemeCodeUnspsc190501.10417341'),
	'identifierSchemeCodeUnspsc190501.10417342': __('identifierSchemeCodeUnspsc190501.10417342'),
	'identifierSchemeCodeUnspsc190501.10417343': __('identifierSchemeCodeUnspsc190501.10417343'),
	'identifierSchemeCodeUnspsc190501.10417344': __('identifierSchemeCodeUnspsc190501.10417344'),
	'identifierSchemeCodeUnspsc190501.10417345': __('identifierSchemeCodeUnspsc190501.10417345'),
	'identifierSchemeCodeUnspsc190501.10417346': __('identifierSchemeCodeUnspsc190501.10417346'),
	'identifierSchemeCodeUnspsc190501.10417347': __('identifierSchemeCodeUnspsc190501.10417347'),
	'identifierSchemeCodeUnspsc190501.10417348': __('identifierSchemeCodeUnspsc190501.10417348'),
	'identifierSchemeCodeUnspsc190501.10417349': __('identifierSchemeCodeUnspsc190501.10417349'),
	'identifierSchemeCodeUnspsc190501.10417350': __('identifierSchemeCodeUnspsc190501.10417350'),
	'identifierSchemeCodeUnspsc190501.10417351': __('identifierSchemeCodeUnspsc190501.10417351'),
	'identifierSchemeCodeUnspsc190501.10417352': __('identifierSchemeCodeUnspsc190501.10417352'),
	'identifierSchemeCodeUnspsc190501.10417353': __('identifierSchemeCodeUnspsc190501.10417353'),
	'identifierSchemeCodeUnspsc190501.10417354': __('identifierSchemeCodeUnspsc190501.10417354'),
	'identifierSchemeCodeUnspsc190501.10417355': __('identifierSchemeCodeUnspsc190501.10417355'),
	'identifierSchemeCodeUnspsc190501.10417400': __('identifierSchemeCodeUnspsc190501.10417400'),
	'identifierSchemeCodeUnspsc190501.10417401': __('identifierSchemeCodeUnspsc190501.10417401'),
	'identifierSchemeCodeUnspsc190501.10417402': __('identifierSchemeCodeUnspsc190501.10417402'),
	'identifierSchemeCodeUnspsc190501.10417403': __('identifierSchemeCodeUnspsc190501.10417403'),
	'identifierSchemeCodeUnspsc190501.10417404': __('identifierSchemeCodeUnspsc190501.10417404'),
	'identifierSchemeCodeUnspsc190501.10417405': __('identifierSchemeCodeUnspsc190501.10417405'),
	'identifierSchemeCodeUnspsc190501.10417406': __('identifierSchemeCodeUnspsc190501.10417406'),
	'identifierSchemeCodeUnspsc190501.10417407': __('identifierSchemeCodeUnspsc190501.10417407'),
	'identifierSchemeCodeUnspsc190501.10417408': __('identifierSchemeCodeUnspsc190501.10417408'),
	'identifierSchemeCodeUnspsc190501.10417409': __('identifierSchemeCodeUnspsc190501.10417409'),
	'identifierSchemeCodeUnspsc190501.10417410': __('identifierSchemeCodeUnspsc190501.10417410'),
	'identifierSchemeCodeUnspsc190501.10417411': __('identifierSchemeCodeUnspsc190501.10417411'),
	'identifierSchemeCodeUnspsc190501.10417412': __('identifierSchemeCodeUnspsc190501.10417412'),
	'identifierSchemeCodeUnspsc190501.10417413': __('identifierSchemeCodeUnspsc190501.10417413'),
	'identifierSchemeCodeUnspsc190501.10417414': __('identifierSchemeCodeUnspsc190501.10417414'),
	'identifierSchemeCodeUnspsc190501.10417415': __('identifierSchemeCodeUnspsc190501.10417415'),
	'identifierSchemeCodeUnspsc190501.10417416': __('identifierSchemeCodeUnspsc190501.10417416'),
	'identifierSchemeCodeUnspsc190501.10417417': __('identifierSchemeCodeUnspsc190501.10417417'),
	'identifierSchemeCodeUnspsc190501.10417418': __('identifierSchemeCodeUnspsc190501.10417418'),
	'identifierSchemeCodeUnspsc190501.10417419': __('identifierSchemeCodeUnspsc190501.10417419'),
	'identifierSchemeCodeUnspsc190501.10417420': __('identifierSchemeCodeUnspsc190501.10417420'),
	'identifierSchemeCodeUnspsc190501.10417421': __('identifierSchemeCodeUnspsc190501.10417421'),
	'identifierSchemeCodeUnspsc190501.10417422': __('identifierSchemeCodeUnspsc190501.10417422'),
	'identifierSchemeCodeUnspsc190501.10417423': __('identifierSchemeCodeUnspsc190501.10417423'),
	'identifierSchemeCodeUnspsc190501.10417424': __('identifierSchemeCodeUnspsc190501.10417424'),
	'identifierSchemeCodeUnspsc190501.10417500': __('identifierSchemeCodeUnspsc190501.10417500'),
	'identifierSchemeCodeUnspsc190501.10417501': __('identifierSchemeCodeUnspsc190501.10417501'),
	'identifierSchemeCodeUnspsc190501.10417502': __('identifierSchemeCodeUnspsc190501.10417502'),
	'identifierSchemeCodeUnspsc190501.10417503': __('identifierSchemeCodeUnspsc190501.10417503'),
	'identifierSchemeCodeUnspsc190501.10417504': __('identifierSchemeCodeUnspsc190501.10417504'),
	'identifierSchemeCodeUnspsc190501.10417505': __('identifierSchemeCodeUnspsc190501.10417505'),
	'identifierSchemeCodeUnspsc190501.10417506': __('identifierSchemeCodeUnspsc190501.10417506'),
	'identifierSchemeCodeUnspsc190501.10417507': __('identifierSchemeCodeUnspsc190501.10417507'),
	'identifierSchemeCodeUnspsc190501.10417508': __('identifierSchemeCodeUnspsc190501.10417508'),
	'identifierSchemeCodeUnspsc190501.10417509': __('identifierSchemeCodeUnspsc190501.10417509'),
	'identifierSchemeCodeUnspsc190501.10417510': __('identifierSchemeCodeUnspsc190501.10417510'),
	'identifierSchemeCodeUnspsc190501.10417600': __('identifierSchemeCodeUnspsc190501.10417600'),
	'identifierSchemeCodeUnspsc190501.10417601': __('identifierSchemeCodeUnspsc190501.10417601'),
	'identifierSchemeCodeUnspsc190501.10417602': __('identifierSchemeCodeUnspsc190501.10417602'),
	'identifierSchemeCodeUnspsc190501.10417603': __('identifierSchemeCodeUnspsc190501.10417603'),
	'identifierSchemeCodeUnspsc190501.10417604': __('identifierSchemeCodeUnspsc190501.10417604'),
	'identifierSchemeCodeUnspsc190501.10417605': __('identifierSchemeCodeUnspsc190501.10417605'),
	'identifierSchemeCodeUnspsc190501.10417606': __('identifierSchemeCodeUnspsc190501.10417606'),
	'identifierSchemeCodeUnspsc190501.10417700': __('identifierSchemeCodeUnspsc190501.10417700'),
	'identifierSchemeCodeUnspsc190501.10417701': __('identifierSchemeCodeUnspsc190501.10417701'),
	'identifierSchemeCodeUnspsc190501.10417702': __('identifierSchemeCodeUnspsc190501.10417702'),
	'identifierSchemeCodeUnspsc190501.10417703': __('identifierSchemeCodeUnspsc190501.10417703'),
	'identifierSchemeCodeUnspsc190501.10417704': __('identifierSchemeCodeUnspsc190501.10417704'),
	'identifierSchemeCodeUnspsc190501.10417705': __('identifierSchemeCodeUnspsc190501.10417705'),
	'identifierSchemeCodeUnspsc190501.10417706': __('identifierSchemeCodeUnspsc190501.10417706'),
	'identifierSchemeCodeUnspsc190501.10417707': __('identifierSchemeCodeUnspsc190501.10417707'),
	'identifierSchemeCodeUnspsc190501.10417708': __('identifierSchemeCodeUnspsc190501.10417708'),
	'identifierSchemeCodeUnspsc190501.10417800': __('identifierSchemeCodeUnspsc190501.10417800'),
	'identifierSchemeCodeUnspsc190501.10417801': __('identifierSchemeCodeUnspsc190501.10417801'),
	'identifierSchemeCodeUnspsc190501.10417802': __('identifierSchemeCodeUnspsc190501.10417802'),
	'identifierSchemeCodeUnspsc190501.10417803': __('identifierSchemeCodeUnspsc190501.10417803'),
	'identifierSchemeCodeUnspsc190501.10417804': __('identifierSchemeCodeUnspsc190501.10417804'),
	'identifierSchemeCodeUnspsc190501.10417805': __('identifierSchemeCodeUnspsc190501.10417805'),
	'identifierSchemeCodeUnspsc190501.10417806': __('identifierSchemeCodeUnspsc190501.10417806'),
	'identifierSchemeCodeUnspsc190501.10417807': __('identifierSchemeCodeUnspsc190501.10417807'),
	'identifierSchemeCodeUnspsc190501.10417808': __('identifierSchemeCodeUnspsc190501.10417808'),
	'identifierSchemeCodeUnspsc190501.10417809': __('identifierSchemeCodeUnspsc190501.10417809'),
	'identifierSchemeCodeUnspsc190501.10417810': __('identifierSchemeCodeUnspsc190501.10417810'),
	'identifierSchemeCodeUnspsc190501.10417811': __('identifierSchemeCodeUnspsc190501.10417811'),
	'identifierSchemeCodeUnspsc190501.10417812': __('identifierSchemeCodeUnspsc190501.10417812'),
	'identifierSchemeCodeUnspsc190501.10417813': __('identifierSchemeCodeUnspsc190501.10417813'),
	'identifierSchemeCodeUnspsc190501.10417814': __('identifierSchemeCodeUnspsc190501.10417814'),
	'identifierSchemeCodeUnspsc190501.10417815': __('identifierSchemeCodeUnspsc190501.10417815'),
	'identifierSchemeCodeUnspsc190501.10417816': __('identifierSchemeCodeUnspsc190501.10417816'),
	'identifierSchemeCodeUnspsc190501.10417817': __('identifierSchemeCodeUnspsc190501.10417817'),
	'identifierSchemeCodeUnspsc190501.10417818': __('identifierSchemeCodeUnspsc190501.10417818'),
	'identifierSchemeCodeUnspsc190501.10417819': __('identifierSchemeCodeUnspsc190501.10417819'),
	'identifierSchemeCodeUnspsc190501.10417820': __('identifierSchemeCodeUnspsc190501.10417820'),
	'identifierSchemeCodeUnspsc190501.10417821': __('identifierSchemeCodeUnspsc190501.10417821'),
	'identifierSchemeCodeUnspsc190501.10417822': __('identifierSchemeCodeUnspsc190501.10417822'),
	'identifierSchemeCodeUnspsc190501.10417823': __('identifierSchemeCodeUnspsc190501.10417823'),
	'identifierSchemeCodeUnspsc190501.10417824': __('identifierSchemeCodeUnspsc190501.10417824'),
	'identifierSchemeCodeUnspsc190501.10417825': __('identifierSchemeCodeUnspsc190501.10417825'),
	'identifierSchemeCodeUnspsc190501.10417826': __('identifierSchemeCodeUnspsc190501.10417826'),
	'identifierSchemeCodeUnspsc190501.10417827': __('identifierSchemeCodeUnspsc190501.10417827'),
	'identifierSchemeCodeUnspsc190501.10417900': __('identifierSchemeCodeUnspsc190501.10417900'),
	'identifierSchemeCodeUnspsc190501.10417901': __('identifierSchemeCodeUnspsc190501.10417901'),
	'identifierSchemeCodeUnspsc190501.10417902': __('identifierSchemeCodeUnspsc190501.10417902'),
	'identifierSchemeCodeUnspsc190501.10417903': __('identifierSchemeCodeUnspsc190501.10417903'),
	'identifierSchemeCodeUnspsc190501.10417904': __('identifierSchemeCodeUnspsc190501.10417904'),
	'identifierSchemeCodeUnspsc190501.10417905': __('identifierSchemeCodeUnspsc190501.10417905'),
	'identifierSchemeCodeUnspsc190501.10417906': __('identifierSchemeCodeUnspsc190501.10417906'),
	'identifierSchemeCodeUnspsc190501.10417907': __('identifierSchemeCodeUnspsc190501.10417907'),
	'identifierSchemeCodeUnspsc190501.10417908': __('identifierSchemeCodeUnspsc190501.10417908'),
	'identifierSchemeCodeUnspsc190501.10417909': __('identifierSchemeCodeUnspsc190501.10417909'),
	'identifierSchemeCodeUnspsc190501.10417910': __('identifierSchemeCodeUnspsc190501.10417910'),
	'identifierSchemeCodeUnspsc190501.10417911': __('identifierSchemeCodeUnspsc190501.10417911'),
	'identifierSchemeCodeUnspsc190501.10417912': __('identifierSchemeCodeUnspsc190501.10417912'),
	'identifierSchemeCodeUnspsc190501.10417913': __('identifierSchemeCodeUnspsc190501.10417913'),
	'identifierSchemeCodeUnspsc190501.10417914': __('identifierSchemeCodeUnspsc190501.10417914'),
	'identifierSchemeCodeUnspsc190501.10417915': __('identifierSchemeCodeUnspsc190501.10417915'),
	'identifierSchemeCodeUnspsc190501.10417916': __('identifierSchemeCodeUnspsc190501.10417916'),
	'identifierSchemeCodeUnspsc190501.10417917': __('identifierSchemeCodeUnspsc190501.10417917'),
	'identifierSchemeCodeUnspsc190501.10417918': __('identifierSchemeCodeUnspsc190501.10417918'),
	'identifierSchemeCodeUnspsc190501.10417919': __('identifierSchemeCodeUnspsc190501.10417919'),
	'identifierSchemeCodeUnspsc190501.10417920': __('identifierSchemeCodeUnspsc190501.10417920'),
	'identifierSchemeCodeUnspsc190501.10417921': __('identifierSchemeCodeUnspsc190501.10417921'),
	'identifierSchemeCodeUnspsc190501.10417922': __('identifierSchemeCodeUnspsc190501.10417922'),
	'identifierSchemeCodeUnspsc190501.10417923': __('identifierSchemeCodeUnspsc190501.10417923'),
	'identifierSchemeCodeUnspsc190501.10417924': __('identifierSchemeCodeUnspsc190501.10417924'),
	'identifierSchemeCodeUnspsc190501.10417925': __('identifierSchemeCodeUnspsc190501.10417925'),
	'identifierSchemeCodeUnspsc190501.10417926': __('identifierSchemeCodeUnspsc190501.10417926'),
	'identifierSchemeCodeUnspsc190501.10417927': __('identifierSchemeCodeUnspsc190501.10417927'),
	'identifierSchemeCodeUnspsc190501.10417928': __('identifierSchemeCodeUnspsc190501.10417928'),
	'identifierSchemeCodeUnspsc190501.10417929': __('identifierSchemeCodeUnspsc190501.10417929'),
	'identifierSchemeCodeUnspsc190501.10417930': __('identifierSchemeCodeUnspsc190501.10417930'),
	'identifierSchemeCodeUnspsc190501.10417931': __('identifierSchemeCodeUnspsc190501.10417931'),
	'identifierSchemeCodeUnspsc190501.10417932': __('identifierSchemeCodeUnspsc190501.10417932'),
	'identifierSchemeCodeUnspsc190501.10417933': __('identifierSchemeCodeUnspsc190501.10417933'),
	'identifierSchemeCodeUnspsc190501.10417934': __('identifierSchemeCodeUnspsc190501.10417934'),
	'identifierSchemeCodeUnspsc190501.10417935': __('identifierSchemeCodeUnspsc190501.10417935'),
	'identifierSchemeCodeUnspsc190501.10417936': __('identifierSchemeCodeUnspsc190501.10417936'),
	'identifierSchemeCodeUnspsc190501.10417937': __('identifierSchemeCodeUnspsc190501.10417937'),
	'identifierSchemeCodeUnspsc190501.10417938': __('identifierSchemeCodeUnspsc190501.10417938'),
	'identifierSchemeCodeUnspsc190501.10417939': __('identifierSchemeCodeUnspsc190501.10417939'),
	'identifierSchemeCodeUnspsc190501.10417940': __('identifierSchemeCodeUnspsc190501.10417940'),
	'identifierSchemeCodeUnspsc190501.10417941': __('identifierSchemeCodeUnspsc190501.10417941'),
	'identifierSchemeCodeUnspsc190501.10417942': __('identifierSchemeCodeUnspsc190501.10417942'),
	'identifierSchemeCodeUnspsc190501.10417943': __('identifierSchemeCodeUnspsc190501.10417943'),
	'identifierSchemeCodeUnspsc190501.10417944': __('identifierSchemeCodeUnspsc190501.10417944'),
	'identifierSchemeCodeUnspsc190501.10417945': __('identifierSchemeCodeUnspsc190501.10417945'),
	'identifierSchemeCodeUnspsc190501.10417946': __('identifierSchemeCodeUnspsc190501.10417946'),
	'identifierSchemeCodeUnspsc190501.10417947': __('identifierSchemeCodeUnspsc190501.10417947'),
	'identifierSchemeCodeUnspsc190501.10417948': __('identifierSchemeCodeUnspsc190501.10417948'),
	'identifierSchemeCodeUnspsc190501.10417949': __('identifierSchemeCodeUnspsc190501.10417949'),
	'identifierSchemeCodeUnspsc190501.10417950': __('identifierSchemeCodeUnspsc190501.10417950'),
	'identifierSchemeCodeUnspsc190501.10417951': __('identifierSchemeCodeUnspsc190501.10417951'),
	'identifierSchemeCodeUnspsc190501.10417952': __('identifierSchemeCodeUnspsc190501.10417952'),
	'identifierSchemeCodeUnspsc190501.10417953': __('identifierSchemeCodeUnspsc190501.10417953'),
	'identifierSchemeCodeUnspsc190501.10417954': __('identifierSchemeCodeUnspsc190501.10417954'),
	'identifierSchemeCodeUnspsc190501.10417955': __('identifierSchemeCodeUnspsc190501.10417955'),
	'identifierSchemeCodeUnspsc190501.10417956': __('identifierSchemeCodeUnspsc190501.10417956'),
	'identifierSchemeCodeUnspsc190501.10417957': __('identifierSchemeCodeUnspsc190501.10417957'),
	'identifierSchemeCodeUnspsc190501.10417958': __('identifierSchemeCodeUnspsc190501.10417958'),
	'identifierSchemeCodeUnspsc190501.10417959': __('identifierSchemeCodeUnspsc190501.10417959'),
	'identifierSchemeCodeUnspsc190501.10417960': __('identifierSchemeCodeUnspsc190501.10417960'),
	'identifierSchemeCodeUnspsc190501.10417961': __('identifierSchemeCodeUnspsc190501.10417961'),
	'identifierSchemeCodeUnspsc190501.10417962': __('identifierSchemeCodeUnspsc190501.10417962'),
	'identifierSchemeCodeUnspsc190501.10417963': __('identifierSchemeCodeUnspsc190501.10417963'),
	'identifierSchemeCodeUnspsc190501.10417964': __('identifierSchemeCodeUnspsc190501.10417964'),
	'identifierSchemeCodeUnspsc190501.10417965': __('identifierSchemeCodeUnspsc190501.10417965'),
	'identifierSchemeCodeUnspsc190501.10417966': __('identifierSchemeCodeUnspsc190501.10417966'),
	'identifierSchemeCodeUnspsc190501.10417967': __('identifierSchemeCodeUnspsc190501.10417967'),
	'identifierSchemeCodeUnspsc190501.10417968': __('identifierSchemeCodeUnspsc190501.10417968'),
	'identifierSchemeCodeUnspsc190501.10417969': __('identifierSchemeCodeUnspsc190501.10417969'),
	'identifierSchemeCodeUnspsc190501.10417970': __('identifierSchemeCodeUnspsc190501.10417970'),
	'identifierSchemeCodeUnspsc190501.10417971': __('identifierSchemeCodeUnspsc190501.10417971'),
	'identifierSchemeCodeUnspsc190501.10417972': __('identifierSchemeCodeUnspsc190501.10417972'),
	'identifierSchemeCodeUnspsc190501.10418000': __('identifierSchemeCodeUnspsc190501.10418000'),
	'identifierSchemeCodeUnspsc190501.10418001': __('identifierSchemeCodeUnspsc190501.10418001'),
	'identifierSchemeCodeUnspsc190501.10418002': __('identifierSchemeCodeUnspsc190501.10418002'),
	'identifierSchemeCodeUnspsc190501.10418003': __('identifierSchemeCodeUnspsc190501.10418003'),
	'identifierSchemeCodeUnspsc190501.10418004': __('identifierSchemeCodeUnspsc190501.10418004'),
	'identifierSchemeCodeUnspsc190501.10418005': __('identifierSchemeCodeUnspsc190501.10418005'),
	'identifierSchemeCodeUnspsc190501.10418006': __('identifierSchemeCodeUnspsc190501.10418006'),
	'identifierSchemeCodeUnspsc190501.10418007': __('identifierSchemeCodeUnspsc190501.10418007'),
	'identifierSchemeCodeUnspsc190501.10418008': __('identifierSchemeCodeUnspsc190501.10418008'),
	'identifierSchemeCodeUnspsc190501.10418009': __('identifierSchemeCodeUnspsc190501.10418009'),
	'identifierSchemeCodeUnspsc190501.10418010': __('identifierSchemeCodeUnspsc190501.10418010'),
	'identifierSchemeCodeUnspsc190501.10418011': __('identifierSchemeCodeUnspsc190501.10418011'),
	'identifierSchemeCodeUnspsc190501.10418012': __('identifierSchemeCodeUnspsc190501.10418012'),
	'identifierSchemeCodeUnspsc190501.10418013': __('identifierSchemeCodeUnspsc190501.10418013'),
	'identifierSchemeCodeUnspsc190501.10418014': __('identifierSchemeCodeUnspsc190501.10418014'),
	'identifierSchemeCodeUnspsc190501.10418015': __('identifierSchemeCodeUnspsc190501.10418015'),
	'identifierSchemeCodeUnspsc190501.10418016': __('identifierSchemeCodeUnspsc190501.10418016'),
	'identifierSchemeCodeUnspsc190501.10418017': __('identifierSchemeCodeUnspsc190501.10418017'),
	'identifierSchemeCodeUnspsc190501.10418018': __('identifierSchemeCodeUnspsc190501.10418018'),
	'identifierSchemeCodeUnspsc190501.10418019': __('identifierSchemeCodeUnspsc190501.10418019'),
	'identifierSchemeCodeUnspsc190501.10418020': __('identifierSchemeCodeUnspsc190501.10418020'),
	'identifierSchemeCodeUnspsc190501.10418021': __('identifierSchemeCodeUnspsc190501.10418021'),
	'identifierSchemeCodeUnspsc190501.10418022': __('identifierSchemeCodeUnspsc190501.10418022'),
	'identifierSchemeCodeUnspsc190501.10418023': __('identifierSchemeCodeUnspsc190501.10418023'),
	'identifierSchemeCodeUnspsc190501.10418024': __('identifierSchemeCodeUnspsc190501.10418024'),
	'identifierSchemeCodeUnspsc190501.10418025': __('identifierSchemeCodeUnspsc190501.10418025'),
	'identifierSchemeCodeUnspsc190501.10418026': __('identifierSchemeCodeUnspsc190501.10418026'),
	'identifierSchemeCodeUnspsc190501.10418027': __('identifierSchemeCodeUnspsc190501.10418027'),
	'identifierSchemeCodeUnspsc190501.10418100': __('identifierSchemeCodeUnspsc190501.10418100'),
	'identifierSchemeCodeUnspsc190501.10418101': __('identifierSchemeCodeUnspsc190501.10418101'),
	'identifierSchemeCodeUnspsc190501.10418102': __('identifierSchemeCodeUnspsc190501.10418102'),
	'identifierSchemeCodeUnspsc190501.10418103': __('identifierSchemeCodeUnspsc190501.10418103'),
	'identifierSchemeCodeUnspsc190501.10418104': __('identifierSchemeCodeUnspsc190501.10418104'),
	'identifierSchemeCodeUnspsc190501.10418105': __('identifierSchemeCodeUnspsc190501.10418105'),
	'identifierSchemeCodeUnspsc190501.10418106': __('identifierSchemeCodeUnspsc190501.10418106'),
	'identifierSchemeCodeUnspsc190501.10418107': __('identifierSchemeCodeUnspsc190501.10418107'),
	'identifierSchemeCodeUnspsc190501.10418108': __('identifierSchemeCodeUnspsc190501.10418108'),
	'identifierSchemeCodeUnspsc190501.10418109': __('identifierSchemeCodeUnspsc190501.10418109'),
	'identifierSchemeCodeUnspsc190501.10418110': __('identifierSchemeCodeUnspsc190501.10418110'),
	'identifierSchemeCodeUnspsc190501.10418111': __('identifierSchemeCodeUnspsc190501.10418111'),
	'identifierSchemeCodeUnspsc190501.10418112': __('identifierSchemeCodeUnspsc190501.10418112'),
	'identifierSchemeCodeUnspsc190501.10418113': __('identifierSchemeCodeUnspsc190501.10418113'),
	'identifierSchemeCodeUnspsc190501.10418114': __('identifierSchemeCodeUnspsc190501.10418114'),
	'identifierSchemeCodeUnspsc190501.10418115': __('identifierSchemeCodeUnspsc190501.10418115'),
	'identifierSchemeCodeUnspsc190501.10418116': __('identifierSchemeCodeUnspsc190501.10418116'),
	'identifierSchemeCodeUnspsc190501.10418117': __('identifierSchemeCodeUnspsc190501.10418117'),
	'identifierSchemeCodeUnspsc190501.10418118': __('identifierSchemeCodeUnspsc190501.10418118'),
	'identifierSchemeCodeUnspsc190501.10418119': __('identifierSchemeCodeUnspsc190501.10418119'),
	'identifierSchemeCodeUnspsc190501.10418120': __('identifierSchemeCodeUnspsc190501.10418120'),
	'identifierSchemeCodeUnspsc190501.10418121': __('identifierSchemeCodeUnspsc190501.10418121'),
	'identifierSchemeCodeUnspsc190501.10418122': __('identifierSchemeCodeUnspsc190501.10418122'),
	'identifierSchemeCodeUnspsc190501.10418200': __('identifierSchemeCodeUnspsc190501.10418200'),
	'identifierSchemeCodeUnspsc190501.10418201': __('identifierSchemeCodeUnspsc190501.10418201'),
	'identifierSchemeCodeUnspsc190501.10418202': __('identifierSchemeCodeUnspsc190501.10418202'),
	'identifierSchemeCodeUnspsc190501.10418203': __('identifierSchemeCodeUnspsc190501.10418203'),
	'identifierSchemeCodeUnspsc190501.10418204': __('identifierSchemeCodeUnspsc190501.10418204'),
	'identifierSchemeCodeUnspsc190501.10418205': __('identifierSchemeCodeUnspsc190501.10418205'),
	'identifierSchemeCodeUnspsc190501.10418206': __('identifierSchemeCodeUnspsc190501.10418206'),
	'identifierSchemeCodeUnspsc190501.10418207': __('identifierSchemeCodeUnspsc190501.10418207'),
	'identifierSchemeCodeUnspsc190501.10418208': __('identifierSchemeCodeUnspsc190501.10418208'),
	'identifierSchemeCodeUnspsc190501.10418209': __('identifierSchemeCodeUnspsc190501.10418209'),
	'identifierSchemeCodeUnspsc190501.10418210': __('identifierSchemeCodeUnspsc190501.10418210'),
	'identifierSchemeCodeUnspsc190501.10418211': __('identifierSchemeCodeUnspsc190501.10418211'),
	'identifierSchemeCodeUnspsc190501.10418212': __('identifierSchemeCodeUnspsc190501.10418212'),
	'identifierSchemeCodeUnspsc190501.10418213': __('identifierSchemeCodeUnspsc190501.10418213'),
	'identifierSchemeCodeUnspsc190501.10418214': __('identifierSchemeCodeUnspsc190501.10418214'),
	'identifierSchemeCodeUnspsc190501.10418215': __('identifierSchemeCodeUnspsc190501.10418215'),
	'identifierSchemeCodeUnspsc190501.10418216': __('identifierSchemeCodeUnspsc190501.10418216'),
	'identifierSchemeCodeUnspsc190501.10418217': __('identifierSchemeCodeUnspsc190501.10418217'),
	'identifierSchemeCodeUnspsc190501.10418218': __('identifierSchemeCodeUnspsc190501.10418218'),
	'identifierSchemeCodeUnspsc190501.10418219': __('identifierSchemeCodeUnspsc190501.10418219'),
	'identifierSchemeCodeUnspsc190501.10418220': __('identifierSchemeCodeUnspsc190501.10418220'),
	'identifierSchemeCodeUnspsc190501.10418300': __('identifierSchemeCodeUnspsc190501.10418300'),
	'identifierSchemeCodeUnspsc190501.10418301': __('identifierSchemeCodeUnspsc190501.10418301'),
	'identifierSchemeCodeUnspsc190501.10418302': __('identifierSchemeCodeUnspsc190501.10418302'),
	'identifierSchemeCodeUnspsc190501.10418303': __('identifierSchemeCodeUnspsc190501.10418303'),
	'identifierSchemeCodeUnspsc190501.10418304': __('identifierSchemeCodeUnspsc190501.10418304'),
	'identifierSchemeCodeUnspsc190501.10418305': __('identifierSchemeCodeUnspsc190501.10418305'),
	'identifierSchemeCodeUnspsc190501.10418306': __('identifierSchemeCodeUnspsc190501.10418306'),
	'identifierSchemeCodeUnspsc190501.10418307': __('identifierSchemeCodeUnspsc190501.10418307'),
	'identifierSchemeCodeUnspsc190501.10418308': __('identifierSchemeCodeUnspsc190501.10418308'),
	'identifierSchemeCodeUnspsc190501.10418309': __('identifierSchemeCodeUnspsc190501.10418309'),
	'identifierSchemeCodeUnspsc190501.10418310': __('identifierSchemeCodeUnspsc190501.10418310'),
	'identifierSchemeCodeUnspsc190501.10418311': __('identifierSchemeCodeUnspsc190501.10418311'),
	'identifierSchemeCodeUnspsc190501.10418312': __('identifierSchemeCodeUnspsc190501.10418312'),
	'identifierSchemeCodeUnspsc190501.10418313': __('identifierSchemeCodeUnspsc190501.10418313'),
	'identifierSchemeCodeUnspsc190501.10418314': __('identifierSchemeCodeUnspsc190501.10418314'),
	'identifierSchemeCodeUnspsc190501.10418315': __('identifierSchemeCodeUnspsc190501.10418315'),
	'identifierSchemeCodeUnspsc190501.10418316': __('identifierSchemeCodeUnspsc190501.10418316'),
	'identifierSchemeCodeUnspsc190501.10418317': __('identifierSchemeCodeUnspsc190501.10418317'),
	'identifierSchemeCodeUnspsc190501.10418318': __('identifierSchemeCodeUnspsc190501.10418318'),
	'identifierSchemeCodeUnspsc190501.10418319': __('identifierSchemeCodeUnspsc190501.10418319'),
	'identifierSchemeCodeUnspsc190501.10418320': __('identifierSchemeCodeUnspsc190501.10418320'),
	'identifierSchemeCodeUnspsc190501.10418321': __('identifierSchemeCodeUnspsc190501.10418321'),
	'identifierSchemeCodeUnspsc190501.10418322': __('identifierSchemeCodeUnspsc190501.10418322'),
	'identifierSchemeCodeUnspsc190501.10418323': __('identifierSchemeCodeUnspsc190501.10418323'),
	'identifierSchemeCodeUnspsc190501.10418324': __('identifierSchemeCodeUnspsc190501.10418324'),
	'identifierSchemeCodeUnspsc190501.10418325': __('identifierSchemeCodeUnspsc190501.10418325'),
	'identifierSchemeCodeUnspsc190501.10418326': __('identifierSchemeCodeUnspsc190501.10418326'),
	'identifierSchemeCodeUnspsc190501.10418327': __('identifierSchemeCodeUnspsc190501.10418327'),
	'identifierSchemeCodeUnspsc190501.10418328': __('identifierSchemeCodeUnspsc190501.10418328'),
	'identifierSchemeCodeUnspsc190501.10418329': __('identifierSchemeCodeUnspsc190501.10418329'),
	'identifierSchemeCodeUnspsc190501.10418330': __('identifierSchemeCodeUnspsc190501.10418330'),
	'identifierSchemeCodeUnspsc190501.10418331': __('identifierSchemeCodeUnspsc190501.10418331'),
	'identifierSchemeCodeUnspsc190501.10418332': __('identifierSchemeCodeUnspsc190501.10418332'),
	'identifierSchemeCodeUnspsc190501.10418333': __('identifierSchemeCodeUnspsc190501.10418333'),
	'identifierSchemeCodeUnspsc190501.10418334': __('identifierSchemeCodeUnspsc190501.10418334'),
	'identifierSchemeCodeUnspsc190501.10418335': __('identifierSchemeCodeUnspsc190501.10418335'),
	'identifierSchemeCodeUnspsc190501.10418336': __('identifierSchemeCodeUnspsc190501.10418336'),
	'identifierSchemeCodeUnspsc190501.10418337': __('identifierSchemeCodeUnspsc190501.10418337'),
	'identifierSchemeCodeUnspsc190501.10418338': __('identifierSchemeCodeUnspsc190501.10418338'),
	'identifierSchemeCodeUnspsc190501.10418339': __('identifierSchemeCodeUnspsc190501.10418339'),
	'identifierSchemeCodeUnspsc190501.10418340': __('identifierSchemeCodeUnspsc190501.10418340'),
	'identifierSchemeCodeUnspsc190501.10418341': __('identifierSchemeCodeUnspsc190501.10418341'),
	'identifierSchemeCodeUnspsc190501.10418342': __('identifierSchemeCodeUnspsc190501.10418342'),
	'identifierSchemeCodeUnspsc190501.10418343': __('identifierSchemeCodeUnspsc190501.10418343'),
	'identifierSchemeCodeUnspsc190501.10418344': __('identifierSchemeCodeUnspsc190501.10418344'),
	'identifierSchemeCodeUnspsc190501.10418345': __('identifierSchemeCodeUnspsc190501.10418345'),
	'identifierSchemeCodeUnspsc190501.10418346': __('identifierSchemeCodeUnspsc190501.10418346'),
	'identifierSchemeCodeUnspsc190501.10418347': __('identifierSchemeCodeUnspsc190501.10418347'),
	'identifierSchemeCodeUnspsc190501.10418348': __('identifierSchemeCodeUnspsc190501.10418348'),
	'identifierSchemeCodeUnspsc190501.10418349': __('identifierSchemeCodeUnspsc190501.10418349'),
	'identifierSchemeCodeUnspsc190501.10418350': __('identifierSchemeCodeUnspsc190501.10418350'),
	'identifierSchemeCodeUnspsc190501.10420000': __('identifierSchemeCodeUnspsc190501.10420000'),
	'identifierSchemeCodeUnspsc190501.10421500': __('identifierSchemeCodeUnspsc190501.10421500'),
	'identifierSchemeCodeUnspsc190501.10421501': __('identifierSchemeCodeUnspsc190501.10421501'),
	'identifierSchemeCodeUnspsc190501.10421502': __('identifierSchemeCodeUnspsc190501.10421502'),
	'identifierSchemeCodeUnspsc190501.10421600': __('identifierSchemeCodeUnspsc190501.10421600'),
	'identifierSchemeCodeUnspsc190501.10421601': __('identifierSchemeCodeUnspsc190501.10421601'),
	'identifierSchemeCodeUnspsc190501.10421602': __('identifierSchemeCodeUnspsc190501.10421602'),
	'identifierSchemeCodeUnspsc190501.10421700': __('identifierSchemeCodeUnspsc190501.10421700'),
	'identifierSchemeCodeUnspsc190501.10421701': __('identifierSchemeCodeUnspsc190501.10421701'),
	'identifierSchemeCodeUnspsc190501.10421702': __('identifierSchemeCodeUnspsc190501.10421702'),
	'identifierSchemeCodeUnspsc190501.10421703': __('identifierSchemeCodeUnspsc190501.10421703'),
	'identifierSchemeCodeUnspsc190501.10421704': __('identifierSchemeCodeUnspsc190501.10421704'),
	'identifierSchemeCodeUnspsc190501.10421800': __('identifierSchemeCodeUnspsc190501.10421800'),
	'identifierSchemeCodeUnspsc190501.10421801': __('identifierSchemeCodeUnspsc190501.10421801'),
	'identifierSchemeCodeUnspsc190501.10421802': __('identifierSchemeCodeUnspsc190501.10421802'),
	'identifierSchemeCodeUnspsc190501.10421900': __('identifierSchemeCodeUnspsc190501.10421900'),
	'identifierSchemeCodeUnspsc190501.10421901': __('identifierSchemeCodeUnspsc190501.10421901'),
	'identifierSchemeCodeUnspsc190501.10421902': __('identifierSchemeCodeUnspsc190501.10421902'),
	'identifierSchemeCodeUnspsc190501.10422000': __('identifierSchemeCodeUnspsc190501.10422000'),
	'identifierSchemeCodeUnspsc190501.10422001': __('identifierSchemeCodeUnspsc190501.10422001'),
	'identifierSchemeCodeUnspsc190501.10422002': __('identifierSchemeCodeUnspsc190501.10422002'),
	'identifierSchemeCodeUnspsc190501.10422003': __('identifierSchemeCodeUnspsc190501.10422003'),
	'identifierSchemeCodeUnspsc190501.10422100': __('identifierSchemeCodeUnspsc190501.10422100'),
	'identifierSchemeCodeUnspsc190501.10422101': __('identifierSchemeCodeUnspsc190501.10422101'),
	'identifierSchemeCodeUnspsc190501.10422102': __('identifierSchemeCodeUnspsc190501.10422102'),
	'identifierSchemeCodeUnspsc190501.10422200': __('identifierSchemeCodeUnspsc190501.10422200'),
	'identifierSchemeCodeUnspsc190501.10422201': __('identifierSchemeCodeUnspsc190501.10422201'),
	'identifierSchemeCodeUnspsc190501.10422202': __('identifierSchemeCodeUnspsc190501.10422202'),
	'identifierSchemeCodeUnspsc190501.10422203': __('identifierSchemeCodeUnspsc190501.10422203'),
	'identifierSchemeCodeUnspsc190501.10422300': __('identifierSchemeCodeUnspsc190501.10422300'),
	'identifierSchemeCodeUnspsc190501.10422301': __('identifierSchemeCodeUnspsc190501.10422301'),
	'identifierSchemeCodeUnspsc190501.10422302': __('identifierSchemeCodeUnspsc190501.10422302'),
	'identifierSchemeCodeUnspsc190501.10422400': __('identifierSchemeCodeUnspsc190501.10422400'),
	'identifierSchemeCodeUnspsc190501.10422401': __('identifierSchemeCodeUnspsc190501.10422401'),
	'identifierSchemeCodeUnspsc190501.10422402': __('identifierSchemeCodeUnspsc190501.10422402'),
	'identifierSchemeCodeUnspsc190501.10422500': __('identifierSchemeCodeUnspsc190501.10422500'),
	'identifierSchemeCodeUnspsc190501.10422501': __('identifierSchemeCodeUnspsc190501.10422501'),
	'identifierSchemeCodeUnspsc190501.10422502': __('identifierSchemeCodeUnspsc190501.10422502'),
	'identifierSchemeCodeUnspsc190501.10422600': __('identifierSchemeCodeUnspsc190501.10422600'),
	'identifierSchemeCodeUnspsc190501.10422601': __('identifierSchemeCodeUnspsc190501.10422601'),
	'identifierSchemeCodeUnspsc190501.10422602': __('identifierSchemeCodeUnspsc190501.10422602'),
	'identifierSchemeCodeUnspsc190501.10422603': __('identifierSchemeCodeUnspsc190501.10422603'),
	'identifierSchemeCodeUnspsc190501.10422700': __('identifierSchemeCodeUnspsc190501.10422700'),
	'identifierSchemeCodeUnspsc190501.10422701': __('identifierSchemeCodeUnspsc190501.10422701'),
	'identifierSchemeCodeUnspsc190501.10422702': __('identifierSchemeCodeUnspsc190501.10422702'),
	'identifierSchemeCodeUnspsc190501.10422703': __('identifierSchemeCodeUnspsc190501.10422703'),
	'identifierSchemeCodeUnspsc190501.10422800': __('identifierSchemeCodeUnspsc190501.10422800'),
	'identifierSchemeCodeUnspsc190501.10422801': __('identifierSchemeCodeUnspsc190501.10422801'),
	'identifierSchemeCodeUnspsc190501.10422802': __('identifierSchemeCodeUnspsc190501.10422802'),
	'identifierSchemeCodeUnspsc190501.10422803': __('identifierSchemeCodeUnspsc190501.10422803'),
	'identifierSchemeCodeUnspsc190501.10422900': __('identifierSchemeCodeUnspsc190501.10422900'),
	'identifierSchemeCodeUnspsc190501.10422901': __('identifierSchemeCodeUnspsc190501.10422901'),
	'identifierSchemeCodeUnspsc190501.10422902': __('identifierSchemeCodeUnspsc190501.10422902'),
	'identifierSchemeCodeUnspsc190501.10423000': __('identifierSchemeCodeUnspsc190501.10423000'),
	'identifierSchemeCodeUnspsc190501.10423001': __('identifierSchemeCodeUnspsc190501.10423001'),
	'identifierSchemeCodeUnspsc190501.10423002': __('identifierSchemeCodeUnspsc190501.10423002'),
	'identifierSchemeCodeUnspsc190501.10423003': __('identifierSchemeCodeUnspsc190501.10423003'),
	'identifierSchemeCodeUnspsc190501.10423100': __('identifierSchemeCodeUnspsc190501.10423100'),
	'identifierSchemeCodeUnspsc190501.10423101': __('identifierSchemeCodeUnspsc190501.10423101'),
	'identifierSchemeCodeUnspsc190501.10423102': __('identifierSchemeCodeUnspsc190501.10423102'),
	'identifierSchemeCodeUnspsc190501.10423103': __('identifierSchemeCodeUnspsc190501.10423103'),
	'identifierSchemeCodeUnspsc190501.10423200': __('identifierSchemeCodeUnspsc190501.10423200'),
	'identifierSchemeCodeUnspsc190501.10423201': __('identifierSchemeCodeUnspsc190501.10423201'),
	'identifierSchemeCodeUnspsc190501.10423202': __('identifierSchemeCodeUnspsc190501.10423202'),
	'identifierSchemeCodeUnspsc190501.10423300': __('identifierSchemeCodeUnspsc190501.10423300'),
	'identifierSchemeCodeUnspsc190501.10423301': __('identifierSchemeCodeUnspsc190501.10423301'),
	'identifierSchemeCodeUnspsc190501.10423302': __('identifierSchemeCodeUnspsc190501.10423302'),
	'identifierSchemeCodeUnspsc190501.10423400': __('identifierSchemeCodeUnspsc190501.10423400'),
	'identifierSchemeCodeUnspsc190501.10423401': __('identifierSchemeCodeUnspsc190501.10423401'),
	'identifierSchemeCodeUnspsc190501.10423402': __('identifierSchemeCodeUnspsc190501.10423402'),
	'identifierSchemeCodeUnspsc190501.10423403': __('identifierSchemeCodeUnspsc190501.10423403'),
	'identifierSchemeCodeUnspsc190501.10423500': __('identifierSchemeCodeUnspsc190501.10423500'),
	'identifierSchemeCodeUnspsc190501.10423501': __('identifierSchemeCodeUnspsc190501.10423501'),
	'identifierSchemeCodeUnspsc190501.10423502': __('identifierSchemeCodeUnspsc190501.10423502'),
	'identifierSchemeCodeUnspsc190501.10423503': __('identifierSchemeCodeUnspsc190501.10423503'),
	'identifierSchemeCodeUnspsc190501.10423504': __('identifierSchemeCodeUnspsc190501.10423504'),
	'identifierSchemeCodeUnspsc190501.10423600': __('identifierSchemeCodeUnspsc190501.10423600'),
	'identifierSchemeCodeUnspsc190501.10423601': __('identifierSchemeCodeUnspsc190501.10423601'),
	'identifierSchemeCodeUnspsc190501.10423602': __('identifierSchemeCodeUnspsc190501.10423602'),
	'identifierSchemeCodeUnspsc190501.10423700': __('identifierSchemeCodeUnspsc190501.10423700'),
	'identifierSchemeCodeUnspsc190501.10423701': __('identifierSchemeCodeUnspsc190501.10423701'),
	'identifierSchemeCodeUnspsc190501.10423702': __('identifierSchemeCodeUnspsc190501.10423702'),
	'identifierSchemeCodeUnspsc190501.10423800': __('identifierSchemeCodeUnspsc190501.10423800'),
	'identifierSchemeCodeUnspsc190501.10423801': __('identifierSchemeCodeUnspsc190501.10423801'),
	'identifierSchemeCodeUnspsc190501.10423802': __('identifierSchemeCodeUnspsc190501.10423802'),
	'identifierSchemeCodeUnspsc190501.10423803': __('identifierSchemeCodeUnspsc190501.10423803'),
	'identifierSchemeCodeUnspsc190501.10423804': __('identifierSchemeCodeUnspsc190501.10423804'),
	'identifierSchemeCodeUnspsc190501.10423900': __('identifierSchemeCodeUnspsc190501.10423900'),
	'identifierSchemeCodeUnspsc190501.10423901': __('identifierSchemeCodeUnspsc190501.10423901'),
	'identifierSchemeCodeUnspsc190501.10423902': __('identifierSchemeCodeUnspsc190501.10423902'),
	'identifierSchemeCodeUnspsc190501.10424000': __('identifierSchemeCodeUnspsc190501.10424000'),
	'identifierSchemeCodeUnspsc190501.10424001': __('identifierSchemeCodeUnspsc190501.10424001'),
	'identifierSchemeCodeUnspsc190501.10424002': __('identifierSchemeCodeUnspsc190501.10424002'),
	'identifierSchemeCodeUnspsc190501.10424100': __('identifierSchemeCodeUnspsc190501.10424100'),
	'identifierSchemeCodeUnspsc190501.10424101': __('identifierSchemeCodeUnspsc190501.10424101'),
	'identifierSchemeCodeUnspsc190501.10424102': __('identifierSchemeCodeUnspsc190501.10424102'),
	'identifierSchemeCodeUnspsc190501.10424200': __('identifierSchemeCodeUnspsc190501.10424200'),
	'identifierSchemeCodeUnspsc190501.10424201': __('identifierSchemeCodeUnspsc190501.10424201'),
	'identifierSchemeCodeUnspsc190501.10424202': __('identifierSchemeCodeUnspsc190501.10424202'),
	'identifierSchemeCodeUnspsc190501.10424300': __('identifierSchemeCodeUnspsc190501.10424300'),
	'identifierSchemeCodeUnspsc190501.10424301': __('identifierSchemeCodeUnspsc190501.10424301'),
	'identifierSchemeCodeUnspsc190501.10424302': __('identifierSchemeCodeUnspsc190501.10424302'),
	'identifierSchemeCodeUnspsc190501.10424303': __('identifierSchemeCodeUnspsc190501.10424303'),
	'identifierSchemeCodeUnspsc190501.10424400': __('identifierSchemeCodeUnspsc190501.10424400'),
	'identifierSchemeCodeUnspsc190501.10424401': __('identifierSchemeCodeUnspsc190501.10424401'),
	'identifierSchemeCodeUnspsc190501.10424402': __('identifierSchemeCodeUnspsc190501.10424402'),
	'identifierSchemeCodeUnspsc190501.10424500': __('identifierSchemeCodeUnspsc190501.10424500'),
	'identifierSchemeCodeUnspsc190501.10424501': __('identifierSchemeCodeUnspsc190501.10424501'),
	'identifierSchemeCodeUnspsc190501.10424502': __('identifierSchemeCodeUnspsc190501.10424502'),
	'identifierSchemeCodeUnspsc190501.10424600': __('identifierSchemeCodeUnspsc190501.10424600'),
	'identifierSchemeCodeUnspsc190501.10424601': __('identifierSchemeCodeUnspsc190501.10424601'),
	'identifierSchemeCodeUnspsc190501.10424602': __('identifierSchemeCodeUnspsc190501.10424602'),
	'identifierSchemeCodeUnspsc190501.10424700': __('identifierSchemeCodeUnspsc190501.10424700'),
	'identifierSchemeCodeUnspsc190501.10424701': __('identifierSchemeCodeUnspsc190501.10424701'),
	'identifierSchemeCodeUnspsc190501.10424702': __('identifierSchemeCodeUnspsc190501.10424702'),
	'identifierSchemeCodeUnspsc190501.10424703': __('identifierSchemeCodeUnspsc190501.10424703'),
	'identifierSchemeCodeUnspsc190501.10424800': __('identifierSchemeCodeUnspsc190501.10424800'),
	'identifierSchemeCodeUnspsc190501.10424801': __('identifierSchemeCodeUnspsc190501.10424801'),
	'identifierSchemeCodeUnspsc190501.10424802': __('identifierSchemeCodeUnspsc190501.10424802'),
	'identifierSchemeCodeUnspsc190501.10424803': __('identifierSchemeCodeUnspsc190501.10424803'),
	'identifierSchemeCodeUnspsc190501.10424804': __('identifierSchemeCodeUnspsc190501.10424804'),
	'identifierSchemeCodeUnspsc190501.10424900': __('identifierSchemeCodeUnspsc190501.10424900'),
	'identifierSchemeCodeUnspsc190501.10424901': __('identifierSchemeCodeUnspsc190501.10424901'),
	'identifierSchemeCodeUnspsc190501.10424902': __('identifierSchemeCodeUnspsc190501.10424902'),
	'identifierSchemeCodeUnspsc190501.10425000': __('identifierSchemeCodeUnspsc190501.10425000'),
	'identifierSchemeCodeUnspsc190501.10425001': __('identifierSchemeCodeUnspsc190501.10425001'),
	'identifierSchemeCodeUnspsc190501.10425002': __('identifierSchemeCodeUnspsc190501.10425002'),
	'identifierSchemeCodeUnspsc190501.10425003': __('identifierSchemeCodeUnspsc190501.10425003'),
	'identifierSchemeCodeUnspsc190501.10425004': __('identifierSchemeCodeUnspsc190501.10425004'),
	'identifierSchemeCodeUnspsc190501.10425100': __('identifierSchemeCodeUnspsc190501.10425100'),
	'identifierSchemeCodeUnspsc190501.10425101': __('identifierSchemeCodeUnspsc190501.10425101'),
	'identifierSchemeCodeUnspsc190501.10425102': __('identifierSchemeCodeUnspsc190501.10425102'),
	'identifierSchemeCodeUnspsc190501.10425103': __('identifierSchemeCodeUnspsc190501.10425103'),
	'identifierSchemeCodeUnspsc190501.10425104': __('identifierSchemeCodeUnspsc190501.10425104'),
	'identifierSchemeCodeUnspsc190501.10425200': __('identifierSchemeCodeUnspsc190501.10425200'),
	'identifierSchemeCodeUnspsc190501.10425201': __('identifierSchemeCodeUnspsc190501.10425201'),
	'identifierSchemeCodeUnspsc190501.10425202': __('identifierSchemeCodeUnspsc190501.10425202'),
	'identifierSchemeCodeUnspsc190501.10425300': __('identifierSchemeCodeUnspsc190501.10425300'),
	'identifierSchemeCodeUnspsc190501.10425301': __('identifierSchemeCodeUnspsc190501.10425301'),
	'identifierSchemeCodeUnspsc190501.10425302': __('identifierSchemeCodeUnspsc190501.10425302'),
	'identifierSchemeCodeUnspsc190501.10425303': __('identifierSchemeCodeUnspsc190501.10425303'),
	'identifierSchemeCodeUnspsc190501.10425400': __('identifierSchemeCodeUnspsc190501.10425400'),
	'identifierSchemeCodeUnspsc190501.10425401': __('identifierSchemeCodeUnspsc190501.10425401'),
	'identifierSchemeCodeUnspsc190501.10425402': __('identifierSchemeCodeUnspsc190501.10425402'),
	'identifierSchemeCodeUnspsc190501.10425403': __('identifierSchemeCodeUnspsc190501.10425403'),
	'identifierSchemeCodeUnspsc190501.10425404': __('identifierSchemeCodeUnspsc190501.10425404'),
	'identifierSchemeCodeUnspsc190501.10425500': __('identifierSchemeCodeUnspsc190501.10425500'),
	'identifierSchemeCodeUnspsc190501.10425501': __('identifierSchemeCodeUnspsc190501.10425501'),
	'identifierSchemeCodeUnspsc190501.10425502': __('identifierSchemeCodeUnspsc190501.10425502'),
	'identifierSchemeCodeUnspsc190501.10425503': __('identifierSchemeCodeUnspsc190501.10425503'),
	'identifierSchemeCodeUnspsc190501.10425600': __('identifierSchemeCodeUnspsc190501.10425600'),
	'identifierSchemeCodeUnspsc190501.10425601': __('identifierSchemeCodeUnspsc190501.10425601'),
	'identifierSchemeCodeUnspsc190501.10425602': __('identifierSchemeCodeUnspsc190501.10425602'),
	'identifierSchemeCodeUnspsc190501.10425700': __('identifierSchemeCodeUnspsc190501.10425700'),
	'identifierSchemeCodeUnspsc190501.10425701': __('identifierSchemeCodeUnspsc190501.10425701'),
	'identifierSchemeCodeUnspsc190501.10425800': __('identifierSchemeCodeUnspsc190501.10425800'),
	'identifierSchemeCodeUnspsc190501.10425801': __('identifierSchemeCodeUnspsc190501.10425801'),
	'identifierSchemeCodeUnspsc190501.10425802': __('identifierSchemeCodeUnspsc190501.10425802'),
	'identifierSchemeCodeUnspsc190501.10425803': __('identifierSchemeCodeUnspsc190501.10425803'),
	'identifierSchemeCodeUnspsc190501.10425804': __('identifierSchemeCodeUnspsc190501.10425804'),
	'identifierSchemeCodeUnspsc190501.10425900': __('identifierSchemeCodeUnspsc190501.10425900'),
	'identifierSchemeCodeUnspsc190501.10425901': __('identifierSchemeCodeUnspsc190501.10425901'),
	'identifierSchemeCodeUnspsc190501.10425902': __('identifierSchemeCodeUnspsc190501.10425902'),
	'identifierSchemeCodeUnspsc190501.10425903': __('identifierSchemeCodeUnspsc190501.10425903'),
	'identifierSchemeCodeUnspsc190501.10425904': __('identifierSchemeCodeUnspsc190501.10425904'),
	'identifierSchemeCodeUnspsc190501.10426000': __('identifierSchemeCodeUnspsc190501.10426000'),
	'identifierSchemeCodeUnspsc190501.10426001': __('identifierSchemeCodeUnspsc190501.10426001'),
	'identifierSchemeCodeUnspsc190501.10426002': __('identifierSchemeCodeUnspsc190501.10426002'),
	'identifierSchemeCodeUnspsc190501.10426003': __('identifierSchemeCodeUnspsc190501.10426003'),
	'identifierSchemeCodeUnspsc190501.10426004': __('identifierSchemeCodeUnspsc190501.10426004'),
	'identifierSchemeCodeUnspsc190501.10426005': __('identifierSchemeCodeUnspsc190501.10426005'),
	'identifierSchemeCodeUnspsc190501.10426006': __('identifierSchemeCodeUnspsc190501.10426006'),
	'identifierSchemeCodeUnspsc190501.10426007': __('identifierSchemeCodeUnspsc190501.10426007'),
	'identifierSchemeCodeUnspsc190501.10426008': __('identifierSchemeCodeUnspsc190501.10426008'),
	'identifierSchemeCodeUnspsc190501.10426009': __('identifierSchemeCodeUnspsc190501.10426009'),
	'identifierSchemeCodeUnspsc190501.10426010': __('identifierSchemeCodeUnspsc190501.10426010'),
	'identifierSchemeCodeUnspsc190501.10426011': __('identifierSchemeCodeUnspsc190501.10426011'),
	'identifierSchemeCodeUnspsc190501.10426012': __('identifierSchemeCodeUnspsc190501.10426012'),
	'identifierSchemeCodeUnspsc190501.10426013': __('identifierSchemeCodeUnspsc190501.10426013'),
	'identifierSchemeCodeUnspsc190501.10426014': __('identifierSchemeCodeUnspsc190501.10426014'),
	'identifierSchemeCodeUnspsc190501.10426015': __('identifierSchemeCodeUnspsc190501.10426015'),
	'identifierSchemeCodeUnspsc190501.10426016': __('identifierSchemeCodeUnspsc190501.10426016'),
	'identifierSchemeCodeUnspsc190501.10426017': __('identifierSchemeCodeUnspsc190501.10426017'),
	'identifierSchemeCodeUnspsc190501.10426018': __('identifierSchemeCodeUnspsc190501.10426018'),
	'identifierSchemeCodeUnspsc190501.10426019': __('identifierSchemeCodeUnspsc190501.10426019'),
	'identifierSchemeCodeUnspsc190501.10426020': __('identifierSchemeCodeUnspsc190501.10426020'),
	'identifierSchemeCodeUnspsc190501.10426021': __('identifierSchemeCodeUnspsc190501.10426021'),
	'identifierSchemeCodeUnspsc190501.10426022': __('identifierSchemeCodeUnspsc190501.10426022'),
	'identifierSchemeCodeUnspsc190501.10426023': __('identifierSchemeCodeUnspsc190501.10426023'),
	'identifierSchemeCodeUnspsc190501.10426024': __('identifierSchemeCodeUnspsc190501.10426024'),
	'identifierSchemeCodeUnspsc190501.10426025': __('identifierSchemeCodeUnspsc190501.10426025'),
	'identifierSchemeCodeUnspsc190501.10426026': __('identifierSchemeCodeUnspsc190501.10426026'),
	'identifierSchemeCodeUnspsc190501.10426027': __('identifierSchemeCodeUnspsc190501.10426027'),
	'identifierSchemeCodeUnspsc190501.10426028': __('identifierSchemeCodeUnspsc190501.10426028'),
	'identifierSchemeCodeUnspsc190501.10426029': __('identifierSchemeCodeUnspsc190501.10426029'),
	'identifierSchemeCodeUnspsc190501.10426030': __('identifierSchemeCodeUnspsc190501.10426030'),
	'identifierSchemeCodeUnspsc190501.10426031': __('identifierSchemeCodeUnspsc190501.10426031'),
	'identifierSchemeCodeUnspsc190501.10426032': __('identifierSchemeCodeUnspsc190501.10426032'),
	'identifierSchemeCodeUnspsc190501.10426033': __('identifierSchemeCodeUnspsc190501.10426033'),
	'identifierSchemeCodeUnspsc190501.10426034': __('identifierSchemeCodeUnspsc190501.10426034'),
	'identifierSchemeCodeUnspsc190501.10426035': __('identifierSchemeCodeUnspsc190501.10426035'),
	'identifierSchemeCodeUnspsc190501.10426036': __('identifierSchemeCodeUnspsc190501.10426036'),
	'identifierSchemeCodeUnspsc190501.10426037': __('identifierSchemeCodeUnspsc190501.10426037'),
	'identifierSchemeCodeUnspsc190501.10426038': __('identifierSchemeCodeUnspsc190501.10426038'),
	'identifierSchemeCodeUnspsc190501.10426039': __('identifierSchemeCodeUnspsc190501.10426039'),
	'identifierSchemeCodeUnspsc190501.10426040': __('identifierSchemeCodeUnspsc190501.10426040'),
	'identifierSchemeCodeUnspsc190501.10426041': __('identifierSchemeCodeUnspsc190501.10426041'),
	'identifierSchemeCodeUnspsc190501.10426042': __('identifierSchemeCodeUnspsc190501.10426042'),
	'identifierSchemeCodeUnspsc190501.10426043': __('identifierSchemeCodeUnspsc190501.10426043'),
	'identifierSchemeCodeUnspsc190501.10426044': __('identifierSchemeCodeUnspsc190501.10426044'),
	'identifierSchemeCodeUnspsc190501.10426045': __('identifierSchemeCodeUnspsc190501.10426045'),
	'identifierSchemeCodeUnspsc190501.10426046': __('identifierSchemeCodeUnspsc190501.10426046'),
	'identifierSchemeCodeUnspsc190501.10426047': __('identifierSchemeCodeUnspsc190501.10426047'),
	'identifierSchemeCodeUnspsc190501.10426048': __('identifierSchemeCodeUnspsc190501.10426048'),
	'identifierSchemeCodeUnspsc190501.10426049': __('identifierSchemeCodeUnspsc190501.10426049'),
	'identifierSchemeCodeUnspsc190501.10426050': __('identifierSchemeCodeUnspsc190501.10426050'),
	'identifierSchemeCodeUnspsc190501.10426051': __('identifierSchemeCodeUnspsc190501.10426051'),
	'identifierSchemeCodeUnspsc190501.10426052': __('identifierSchemeCodeUnspsc190501.10426052'),
	'identifierSchemeCodeUnspsc190501.10426053': __('identifierSchemeCodeUnspsc190501.10426053'),
	'identifierSchemeCodeUnspsc190501.10426054': __('identifierSchemeCodeUnspsc190501.10426054'),
	'identifierSchemeCodeUnspsc190501.10426055': __('identifierSchemeCodeUnspsc190501.10426055'),
	'identifierSchemeCodeUnspsc190501.10426056': __('identifierSchemeCodeUnspsc190501.10426056'),
	'identifierSchemeCodeUnspsc190501.10426057': __('identifierSchemeCodeUnspsc190501.10426057'),
	'identifierSchemeCodeUnspsc190501.10426058': __('identifierSchemeCodeUnspsc190501.10426058'),
	'identifierSchemeCodeUnspsc190501.10426059': __('identifierSchemeCodeUnspsc190501.10426059'),
	'identifierSchemeCodeUnspsc190501.10426060': __('identifierSchemeCodeUnspsc190501.10426060'),
	'identifierSchemeCodeUnspsc190501.10426061': __('identifierSchemeCodeUnspsc190501.10426061'),
	'identifierSchemeCodeUnspsc190501.10426062': __('identifierSchemeCodeUnspsc190501.10426062'),
	'identifierSchemeCodeUnspsc190501.10426063': __('identifierSchemeCodeUnspsc190501.10426063'),
	'identifierSchemeCodeUnspsc190501.10426064': __('identifierSchemeCodeUnspsc190501.10426064'),
	'identifierSchemeCodeUnspsc190501.10426065': __('identifierSchemeCodeUnspsc190501.10426065'),
	'identifierSchemeCodeUnspsc190501.10426066': __('identifierSchemeCodeUnspsc190501.10426066'),
	'identifierSchemeCodeUnspsc190501.10426067': __('identifierSchemeCodeUnspsc190501.10426067'),
	'identifierSchemeCodeUnspsc190501.10426068': __('identifierSchemeCodeUnspsc190501.10426068'),
	'identifierSchemeCodeUnspsc190501.10426069': __('identifierSchemeCodeUnspsc190501.10426069'),
	'identifierSchemeCodeUnspsc190501.10426070': __('identifierSchemeCodeUnspsc190501.10426070'),
	'identifierSchemeCodeUnspsc190501.10426071': __('identifierSchemeCodeUnspsc190501.10426071'),
	'identifierSchemeCodeUnspsc190501.10426072': __('identifierSchemeCodeUnspsc190501.10426072'),
	'identifierSchemeCodeUnspsc190501.10426073': __('identifierSchemeCodeUnspsc190501.10426073'),
	'identifierSchemeCodeUnspsc190501.10426074': __('identifierSchemeCodeUnspsc190501.10426074'),
	'identifierSchemeCodeUnspsc190501.10426075': __('identifierSchemeCodeUnspsc190501.10426075'),
	'identifierSchemeCodeUnspsc190501.10426076': __('identifierSchemeCodeUnspsc190501.10426076'),
	'identifierSchemeCodeUnspsc190501.10426077': __('identifierSchemeCodeUnspsc190501.10426077'),
	'identifierSchemeCodeUnspsc190501.10426078': __('identifierSchemeCodeUnspsc190501.10426078'),
	'identifierSchemeCodeUnspsc190501.10426079': __('identifierSchemeCodeUnspsc190501.10426079'),
	'identifierSchemeCodeUnspsc190501.10426080': __('identifierSchemeCodeUnspsc190501.10426080'),
	'identifierSchemeCodeUnspsc190501.10426081': __('identifierSchemeCodeUnspsc190501.10426081'),
	'identifierSchemeCodeUnspsc190501.10426082': __('identifierSchemeCodeUnspsc190501.10426082'),
	'identifierSchemeCodeUnspsc190501.10426084': __('identifierSchemeCodeUnspsc190501.10426084'),
	'identifierSchemeCodeUnspsc190501.10426085': __('identifierSchemeCodeUnspsc190501.10426085'),
	'identifierSchemeCodeUnspsc190501.10426086': __('identifierSchemeCodeUnspsc190501.10426086'),
	'identifierSchemeCodeUnspsc190501.10426100': __('identifierSchemeCodeUnspsc190501.10426100'),
	'identifierSchemeCodeUnspsc190501.10426101': __('identifierSchemeCodeUnspsc190501.10426101'),
	'identifierSchemeCodeUnspsc190501.10426102': __('identifierSchemeCodeUnspsc190501.10426102'),
	'identifierSchemeCodeUnspsc190501.10426200': __('identifierSchemeCodeUnspsc190501.10426200'),
	'identifierSchemeCodeUnspsc190501.10426201': __('identifierSchemeCodeUnspsc190501.10426201'),
	'identifierSchemeCodeUnspsc190501.10426202': __('identifierSchemeCodeUnspsc190501.10426202'),
	'identifierSchemeCodeUnspsc190501.10426203': __('identifierSchemeCodeUnspsc190501.10426203'),
	'identifierSchemeCodeUnspsc190501.10426204': __('identifierSchemeCodeUnspsc190501.10426204'),
	'identifierSchemeCodeUnspsc190501.10426300': __('identifierSchemeCodeUnspsc190501.10426300'),
	'identifierSchemeCodeUnspsc190501.10426301': __('identifierSchemeCodeUnspsc190501.10426301'),
	'identifierSchemeCodeUnspsc190501.10426302': __('identifierSchemeCodeUnspsc190501.10426302'),
	'identifierSchemeCodeUnspsc190501.10426303': __('identifierSchemeCodeUnspsc190501.10426303'),
	'identifierSchemeCodeUnspsc190501.10426400': __('identifierSchemeCodeUnspsc190501.10426400'),
	'identifierSchemeCodeUnspsc190501.10426401': __('identifierSchemeCodeUnspsc190501.10426401'),
	'identifierSchemeCodeUnspsc190501.10426402': __('identifierSchemeCodeUnspsc190501.10426402'),
	'identifierSchemeCodeUnspsc190501.10426500': __('identifierSchemeCodeUnspsc190501.10426500'),
	'identifierSchemeCodeUnspsc190501.10426501': __('identifierSchemeCodeUnspsc190501.10426501'),
	'identifierSchemeCodeUnspsc190501.10426502': __('identifierSchemeCodeUnspsc190501.10426502'),
	'identifierSchemeCodeUnspsc190501.10426600': __('identifierSchemeCodeUnspsc190501.10426600'),
	'identifierSchemeCodeUnspsc190501.10426601': __('identifierSchemeCodeUnspsc190501.10426601'),
	'identifierSchemeCodeUnspsc190501.10426602': __('identifierSchemeCodeUnspsc190501.10426602'),
	'identifierSchemeCodeUnspsc190501.10426603': __('identifierSchemeCodeUnspsc190501.10426603'),
	'identifierSchemeCodeUnspsc190501.10426700': __('identifierSchemeCodeUnspsc190501.10426700'),
	'identifierSchemeCodeUnspsc190501.10426701': __('identifierSchemeCodeUnspsc190501.10426701'),
	'identifierSchemeCodeUnspsc190501.10426702': __('identifierSchemeCodeUnspsc190501.10426702'),
	'identifierSchemeCodeUnspsc190501.10426703': __('identifierSchemeCodeUnspsc190501.10426703'),
	'identifierSchemeCodeUnspsc190501.10426704': __('identifierSchemeCodeUnspsc190501.10426704'),
	'identifierSchemeCodeUnspsc190501.10430000': __('identifierSchemeCodeUnspsc190501.10430000'),
	'identifierSchemeCodeUnspsc190501.10431500': __('identifierSchemeCodeUnspsc190501.10431500'),
	'identifierSchemeCodeUnspsc190501.10431501': __('identifierSchemeCodeUnspsc190501.10431501'),
	'identifierSchemeCodeUnspsc190501.10431502': __('identifierSchemeCodeUnspsc190501.10431502'),
	'identifierSchemeCodeUnspsc190501.10431503': __('identifierSchemeCodeUnspsc190501.10431503'),
	'identifierSchemeCodeUnspsc190501.10431504': __('identifierSchemeCodeUnspsc190501.10431504'),
	'identifierSchemeCodeUnspsc190501.10431505': __('identifierSchemeCodeUnspsc190501.10431505'),
	'identifierSchemeCodeUnspsc190501.10431506': __('identifierSchemeCodeUnspsc190501.10431506'),
	'identifierSchemeCodeUnspsc190501.10431507': __('identifierSchemeCodeUnspsc190501.10431507'),
	'identifierSchemeCodeUnspsc190501.10431508': __('identifierSchemeCodeUnspsc190501.10431508'),
	'identifierSchemeCodeUnspsc190501.10431509': __('identifierSchemeCodeUnspsc190501.10431509'),
	'identifierSchemeCodeUnspsc190501.10431510': __('identifierSchemeCodeUnspsc190501.10431510'),
	'identifierSchemeCodeUnspsc190501.10431511': __('identifierSchemeCodeUnspsc190501.10431511'),
	'identifierSchemeCodeUnspsc190501.10431512': __('identifierSchemeCodeUnspsc190501.10431512'),
	'identifierSchemeCodeUnspsc190501.10431513': __('identifierSchemeCodeUnspsc190501.10431513'),
	'identifierSchemeCodeUnspsc190501.10431600': __('identifierSchemeCodeUnspsc190501.10431600'),
	'identifierSchemeCodeUnspsc190501.10431601': __('identifierSchemeCodeUnspsc190501.10431601'),
	'identifierSchemeCodeUnspsc190501.10431602': __('identifierSchemeCodeUnspsc190501.10431602'),
	'identifierSchemeCodeUnspsc190501.10431603': __('identifierSchemeCodeUnspsc190501.10431603'),
	'identifierSchemeCodeUnspsc190501.10431604': __('identifierSchemeCodeUnspsc190501.10431604'),
	'identifierSchemeCodeUnspsc190501.10431605': __('identifierSchemeCodeUnspsc190501.10431605'),
	'identifierSchemeCodeUnspsc190501.10431606': __('identifierSchemeCodeUnspsc190501.10431606'),
	'identifierSchemeCodeUnspsc190501.10431607': __('identifierSchemeCodeUnspsc190501.10431607'),
	'identifierSchemeCodeUnspsc190501.10431608': __('identifierSchemeCodeUnspsc190501.10431608'),
	'identifierSchemeCodeUnspsc190501.10431609': __('identifierSchemeCodeUnspsc190501.10431609'),
	'identifierSchemeCodeUnspsc190501.10431610': __('identifierSchemeCodeUnspsc190501.10431610'),
	'identifierSchemeCodeUnspsc190501.10431611': __('identifierSchemeCodeUnspsc190501.10431611'),
	'identifierSchemeCodeUnspsc190501.10431612': __('identifierSchemeCodeUnspsc190501.10431612'),
	'identifierSchemeCodeUnspsc190501.10431613': __('identifierSchemeCodeUnspsc190501.10431613'),
	'identifierSchemeCodeUnspsc190501.10431614': __('identifierSchemeCodeUnspsc190501.10431614'),
	'identifierSchemeCodeUnspsc190501.10431615': __('identifierSchemeCodeUnspsc190501.10431615'),
	'identifierSchemeCodeUnspsc190501.10431616': __('identifierSchemeCodeUnspsc190501.10431616'),
	'identifierSchemeCodeUnspsc190501.10431617': __('identifierSchemeCodeUnspsc190501.10431617'),
	'identifierSchemeCodeUnspsc190501.10431618': __('identifierSchemeCodeUnspsc190501.10431618'),
	'identifierSchemeCodeUnspsc190501.10431619': __('identifierSchemeCodeUnspsc190501.10431619'),
	'identifierSchemeCodeUnspsc190501.10431620': __('identifierSchemeCodeUnspsc190501.10431620'),
	'identifierSchemeCodeUnspsc190501.10431621': __('identifierSchemeCodeUnspsc190501.10431621'),
	'identifierSchemeCodeUnspsc190501.10431622': __('identifierSchemeCodeUnspsc190501.10431622'),
	'identifierSchemeCodeUnspsc190501.10431623': __('identifierSchemeCodeUnspsc190501.10431623'),
	'identifierSchemeCodeUnspsc190501.10431624': __('identifierSchemeCodeUnspsc190501.10431624'),
	'identifierSchemeCodeUnspsc190501.10431625': __('identifierSchemeCodeUnspsc190501.10431625'),
	'identifierSchemeCodeUnspsc190501.10431626': __('identifierSchemeCodeUnspsc190501.10431626'),
	'identifierSchemeCodeUnspsc190501.10431627': __('identifierSchemeCodeUnspsc190501.10431627'),
	'identifierSchemeCodeUnspsc190501.10431628': __('identifierSchemeCodeUnspsc190501.10431628'),
	'identifierSchemeCodeUnspsc190501.10431629': __('identifierSchemeCodeUnspsc190501.10431629'),
	'identifierSchemeCodeUnspsc190501.10431630': __('identifierSchemeCodeUnspsc190501.10431630'),
	'identifierSchemeCodeUnspsc190501.10431631': __('identifierSchemeCodeUnspsc190501.10431631'),
	'identifierSchemeCodeUnspsc190501.10431632': __('identifierSchemeCodeUnspsc190501.10431632'),
	'identifierSchemeCodeUnspsc190501.10431633': __('identifierSchemeCodeUnspsc190501.10431633'),
	'identifierSchemeCodeUnspsc190501.10431634': __('identifierSchemeCodeUnspsc190501.10431634'),
	'identifierSchemeCodeUnspsc190501.10431635': __('identifierSchemeCodeUnspsc190501.10431635'),
	'identifierSchemeCodeUnspsc190501.10431636': __('identifierSchemeCodeUnspsc190501.10431636'),
	'identifierSchemeCodeUnspsc190501.10431637': __('identifierSchemeCodeUnspsc190501.10431637'),
	'identifierSchemeCodeUnspsc190501.10431638': __('identifierSchemeCodeUnspsc190501.10431638'),
	'identifierSchemeCodeUnspsc190501.10431639': __('identifierSchemeCodeUnspsc190501.10431639'),
	'identifierSchemeCodeUnspsc190501.10431640': __('identifierSchemeCodeUnspsc190501.10431640'),
	'identifierSchemeCodeUnspsc190501.10431641': __('identifierSchemeCodeUnspsc190501.10431641'),
	'identifierSchemeCodeUnspsc190501.10431642': __('identifierSchemeCodeUnspsc190501.10431642'),
	'identifierSchemeCodeUnspsc190501.10431643': __('identifierSchemeCodeUnspsc190501.10431643'),
	'identifierSchemeCodeUnspsc190501.10431644': __('identifierSchemeCodeUnspsc190501.10431644'),
	'identifierSchemeCodeUnspsc190501.10431645': __('identifierSchemeCodeUnspsc190501.10431645'),
	'identifierSchemeCodeUnspsc190501.10431646': __('identifierSchemeCodeUnspsc190501.10431646'),
	'identifierSchemeCodeUnspsc190501.10431647': __('identifierSchemeCodeUnspsc190501.10431647'),
	'identifierSchemeCodeUnspsc190501.10431700': __('identifierSchemeCodeUnspsc190501.10431700'),
	'identifierSchemeCodeUnspsc190501.10431701': __('identifierSchemeCodeUnspsc190501.10431701'),
	'identifierSchemeCodeUnspsc190501.10431702': __('identifierSchemeCodeUnspsc190501.10431702'),
	'identifierSchemeCodeUnspsc190501.10431703': __('identifierSchemeCodeUnspsc190501.10431703'),
	'identifierSchemeCodeUnspsc190501.10431704': __('identifierSchemeCodeUnspsc190501.10431704'),
	'identifierSchemeCodeUnspsc190501.10431705': __('identifierSchemeCodeUnspsc190501.10431705'),
	'identifierSchemeCodeUnspsc190501.10431706': __('identifierSchemeCodeUnspsc190501.10431706'),
	'identifierSchemeCodeUnspsc190501.10431707': __('identifierSchemeCodeUnspsc190501.10431707'),
	'identifierSchemeCodeUnspsc190501.10431708': __('identifierSchemeCodeUnspsc190501.10431708'),
	'identifierSchemeCodeUnspsc190501.10431709': __('identifierSchemeCodeUnspsc190501.10431709'),
	'identifierSchemeCodeUnspsc190501.10431710': __('identifierSchemeCodeUnspsc190501.10431710'),
	'identifierSchemeCodeUnspsc190501.10431711': __('identifierSchemeCodeUnspsc190501.10431711'),
	'identifierSchemeCodeUnspsc190501.10431712': __('identifierSchemeCodeUnspsc190501.10431712'),
	'identifierSchemeCodeUnspsc190501.10431713': __('identifierSchemeCodeUnspsc190501.10431713'),
	'identifierSchemeCodeUnspsc190501.10431714': __('identifierSchemeCodeUnspsc190501.10431714'),
	'identifierSchemeCodeUnspsc190501.10431800': __('identifierSchemeCodeUnspsc190501.10431800'),
	'identifierSchemeCodeUnspsc190501.10431801': __('identifierSchemeCodeUnspsc190501.10431801'),
	'identifierSchemeCodeUnspsc190501.10431802': __('identifierSchemeCodeUnspsc190501.10431802'),
	'identifierSchemeCodeUnspsc190501.10431803': __('identifierSchemeCodeUnspsc190501.10431803'),
	'identifierSchemeCodeUnspsc190501.10431804': __('identifierSchemeCodeUnspsc190501.10431804'),
	'identifierSchemeCodeUnspsc190501.10431805': __('identifierSchemeCodeUnspsc190501.10431805'),
	'identifierSchemeCodeUnspsc190501.10431806': __('identifierSchemeCodeUnspsc190501.10431806'),
	'identifierSchemeCodeUnspsc190501.10431807': __('identifierSchemeCodeUnspsc190501.10431807'),
	'identifierSchemeCodeUnspsc190501.10431808': __('identifierSchemeCodeUnspsc190501.10431808'),
	'identifierSchemeCodeUnspsc190501.10431809': __('identifierSchemeCodeUnspsc190501.10431809'),
	'identifierSchemeCodeUnspsc190501.10431810': __('identifierSchemeCodeUnspsc190501.10431810'),
	'identifierSchemeCodeUnspsc190501.10431811': __('identifierSchemeCodeUnspsc190501.10431811'),
	'identifierSchemeCodeUnspsc190501.10431812': __('identifierSchemeCodeUnspsc190501.10431812'),
	'identifierSchemeCodeUnspsc190501.10431900': __('identifierSchemeCodeUnspsc190501.10431900'),
	'identifierSchemeCodeUnspsc190501.10431901': __('identifierSchemeCodeUnspsc190501.10431901'),
	'identifierSchemeCodeUnspsc190501.10431902': __('identifierSchemeCodeUnspsc190501.10431902'),
	'identifierSchemeCodeUnspsc190501.10431903': __('identifierSchemeCodeUnspsc190501.10431903'),
	'identifierSchemeCodeUnspsc190501.10431904': __('identifierSchemeCodeUnspsc190501.10431904'),
	'identifierSchemeCodeUnspsc190501.10431905': __('identifierSchemeCodeUnspsc190501.10431905'),
	'identifierSchemeCodeUnspsc190501.10431906': __('identifierSchemeCodeUnspsc190501.10431906'),
	'identifierSchemeCodeUnspsc190501.10431907': __('identifierSchemeCodeUnspsc190501.10431907'),
	'identifierSchemeCodeUnspsc190501.10431908': __('identifierSchemeCodeUnspsc190501.10431908'),
	'identifierSchemeCodeUnspsc190501.10431909': __('identifierSchemeCodeUnspsc190501.10431909'),
	'identifierSchemeCodeUnspsc190501.10431910': __('identifierSchemeCodeUnspsc190501.10431910'),
	'identifierSchemeCodeUnspsc190501.10431911': __('identifierSchemeCodeUnspsc190501.10431911'),
	'identifierSchemeCodeUnspsc190501.10431912': __('identifierSchemeCodeUnspsc190501.10431912'),
	'identifierSchemeCodeUnspsc190501.10431913': __('identifierSchemeCodeUnspsc190501.10431913'),
	'identifierSchemeCodeUnspsc190501.10431914': __('identifierSchemeCodeUnspsc190501.10431914'),
	'identifierSchemeCodeUnspsc190501.10431915': __('identifierSchemeCodeUnspsc190501.10431915'),
	'identifierSchemeCodeUnspsc190501.10431916': __('identifierSchemeCodeUnspsc190501.10431916'),
	'identifierSchemeCodeUnspsc190501.10431917': __('identifierSchemeCodeUnspsc190501.10431917'),
	'identifierSchemeCodeUnspsc190501.10431918': __('identifierSchemeCodeUnspsc190501.10431918'),
	'identifierSchemeCodeUnspsc190501.10431919': __('identifierSchemeCodeUnspsc190501.10431919'),
	'identifierSchemeCodeUnspsc190501.10431920': __('identifierSchemeCodeUnspsc190501.10431920'),
	'identifierSchemeCodeUnspsc190501.10431921': __('identifierSchemeCodeUnspsc190501.10431921'),
	'identifierSchemeCodeUnspsc190501.10431922': __('identifierSchemeCodeUnspsc190501.10431922'),
	'identifierSchemeCodeUnspsc190501.10431923': __('identifierSchemeCodeUnspsc190501.10431923'),
	'identifierSchemeCodeUnspsc190501.10431924': __('identifierSchemeCodeUnspsc190501.10431924'),
	'identifierSchemeCodeUnspsc190501.10431925': __('identifierSchemeCodeUnspsc190501.10431925'),
	'identifierSchemeCodeUnspsc190501.10431926': __('identifierSchemeCodeUnspsc190501.10431926'),
	'identifierSchemeCodeUnspsc190501.10432000': __('identifierSchemeCodeUnspsc190501.10432000'),
	'identifierSchemeCodeUnspsc190501.10432001': __('identifierSchemeCodeUnspsc190501.10432001'),
	'identifierSchemeCodeUnspsc190501.10432002': __('identifierSchemeCodeUnspsc190501.10432002'),
	'identifierSchemeCodeUnspsc190501.10432003': __('identifierSchemeCodeUnspsc190501.10432003'),
	'identifierSchemeCodeUnspsc190501.10432004': __('identifierSchemeCodeUnspsc190501.10432004'),
	'identifierSchemeCodeUnspsc190501.10432005': __('identifierSchemeCodeUnspsc190501.10432005'),
	'identifierSchemeCodeUnspsc190501.10432006': __('identifierSchemeCodeUnspsc190501.10432006'),
	'identifierSchemeCodeUnspsc190501.10432007': __('identifierSchemeCodeUnspsc190501.10432007'),
	'identifierSchemeCodeUnspsc190501.10432008': __('identifierSchemeCodeUnspsc190501.10432008'),
	'identifierSchemeCodeUnspsc190501.10432009': __('identifierSchemeCodeUnspsc190501.10432009'),
	'identifierSchemeCodeUnspsc190501.10432010': __('identifierSchemeCodeUnspsc190501.10432010'),
	'identifierSchemeCodeUnspsc190501.10432012': __('identifierSchemeCodeUnspsc190501.10432012'),
	'identifierSchemeCodeUnspsc190501.10432013': __('identifierSchemeCodeUnspsc190501.10432013'),
	'identifierSchemeCodeUnspsc190501.10432014': __('identifierSchemeCodeUnspsc190501.10432014'),
	'identifierSchemeCodeUnspsc190501.10432015': __('identifierSchemeCodeUnspsc190501.10432015'),
	'identifierSchemeCodeUnspsc190501.10432016': __('identifierSchemeCodeUnspsc190501.10432016'),
	'identifierSchemeCodeUnspsc190501.10432017': __('identifierSchemeCodeUnspsc190501.10432017'),
	'identifierSchemeCodeUnspsc190501.10432018': __('identifierSchemeCodeUnspsc190501.10432018'),
	'identifierSchemeCodeUnspsc190501.10432019': __('identifierSchemeCodeUnspsc190501.10432019'),
	'identifierSchemeCodeUnspsc190501.10432020': __('identifierSchemeCodeUnspsc190501.10432020'),
	'identifierSchemeCodeUnspsc190501.10432021': __('identifierSchemeCodeUnspsc190501.10432021'),
	'identifierSchemeCodeUnspsc190501.10432022': __('identifierSchemeCodeUnspsc190501.10432022'),
	'identifierSchemeCodeUnspsc190501.10432023': __('identifierSchemeCodeUnspsc190501.10432023'),
	'identifierSchemeCodeUnspsc190501.10432024': __('identifierSchemeCodeUnspsc190501.10432024'),
	'identifierSchemeCodeUnspsc190501.10432025': __('identifierSchemeCodeUnspsc190501.10432025'),
	'identifierSchemeCodeUnspsc190501.10432026': __('identifierSchemeCodeUnspsc190501.10432026'),
	'identifierSchemeCodeUnspsc190501.10432027': __('identifierSchemeCodeUnspsc190501.10432027'),
	'identifierSchemeCodeUnspsc190501.10432028': __('identifierSchemeCodeUnspsc190501.10432028'),
	'identifierSchemeCodeUnspsc190501.10432029': __('identifierSchemeCodeUnspsc190501.10432029'),
	'identifierSchemeCodeUnspsc190501.10432030': __('identifierSchemeCodeUnspsc190501.10432030'),
	'identifierSchemeCodeUnspsc190501.10432031': __('identifierSchemeCodeUnspsc190501.10432031'),
	'identifierSchemeCodeUnspsc190501.10432032': __('identifierSchemeCodeUnspsc190501.10432032'),
	'identifierSchemeCodeUnspsc190501.10432033': __('identifierSchemeCodeUnspsc190501.10432033'),
	'identifierSchemeCodeUnspsc190501.10432034': __('identifierSchemeCodeUnspsc190501.10432034'),
	'identifierSchemeCodeUnspsc190501.10432035': __('identifierSchemeCodeUnspsc190501.10432035'),
	'identifierSchemeCodeUnspsc190501.10432036': __('identifierSchemeCodeUnspsc190501.10432036'),
	'identifierSchemeCodeUnspsc190501.10432037': __('identifierSchemeCodeUnspsc190501.10432037'),
	'identifierSchemeCodeUnspsc190501.10432038': __('identifierSchemeCodeUnspsc190501.10432038'),
	'identifierSchemeCodeUnspsc190501.10432039': __('identifierSchemeCodeUnspsc190501.10432039'),
	'identifierSchemeCodeUnspsc190501.10432040': __('identifierSchemeCodeUnspsc190501.10432040'),
	'identifierSchemeCodeUnspsc190501.10432041': __('identifierSchemeCodeUnspsc190501.10432041'),
	'identifierSchemeCodeUnspsc190501.10432042': __('identifierSchemeCodeUnspsc190501.10432042'),
	'identifierSchemeCodeUnspsc190501.10432043': __('identifierSchemeCodeUnspsc190501.10432043'),
	'identifierSchemeCodeUnspsc190501.10432044': __('identifierSchemeCodeUnspsc190501.10432044'),
	'identifierSchemeCodeUnspsc190501.10432045': __('identifierSchemeCodeUnspsc190501.10432045'),
	'identifierSchemeCodeUnspsc190501.10432046': __('identifierSchemeCodeUnspsc190501.10432046'),
	'identifierSchemeCodeUnspsc190501.10432047': __('identifierSchemeCodeUnspsc190501.10432047'),
	'identifierSchemeCodeUnspsc190501.10432048': __('identifierSchemeCodeUnspsc190501.10432048'),
	'identifierSchemeCodeUnspsc190501.10432049': __('identifierSchemeCodeUnspsc190501.10432049'),
	'identifierSchemeCodeUnspsc190501.10432050': __('identifierSchemeCodeUnspsc190501.10432050'),
	'identifierSchemeCodeUnspsc190501.10432051': __('identifierSchemeCodeUnspsc190501.10432051'),
	'identifierSchemeCodeUnspsc190501.10432052': __('identifierSchemeCodeUnspsc190501.10432052'),
	'identifierSchemeCodeUnspsc190501.10432053': __('identifierSchemeCodeUnspsc190501.10432053'),
	'identifierSchemeCodeUnspsc190501.10432054': __('identifierSchemeCodeUnspsc190501.10432054'),
	'identifierSchemeCodeUnspsc190501.10432055': __('identifierSchemeCodeUnspsc190501.10432055'),
	'identifierSchemeCodeUnspsc190501.10432056': __('identifierSchemeCodeUnspsc190501.10432056'),
	'identifierSchemeCodeUnspsc190501.10432057': __('identifierSchemeCodeUnspsc190501.10432057'),
	'identifierSchemeCodeUnspsc190501.10432058': __('identifierSchemeCodeUnspsc190501.10432058'),
	'identifierSchemeCodeUnspsc190501.10432059': __('identifierSchemeCodeUnspsc190501.10432059'),
	'identifierSchemeCodeUnspsc190501.10432060': __('identifierSchemeCodeUnspsc190501.10432060'),
	'identifierSchemeCodeUnspsc190501.10432061': __('identifierSchemeCodeUnspsc190501.10432061'),
	'identifierSchemeCodeUnspsc190501.10432062': __('identifierSchemeCodeUnspsc190501.10432062'),
	'identifierSchemeCodeUnspsc190501.10432063': __('identifierSchemeCodeUnspsc190501.10432063'),
	'identifierSchemeCodeUnspsc190501.10432064': __('identifierSchemeCodeUnspsc190501.10432064'),
	'identifierSchemeCodeUnspsc190501.10432065': __('identifierSchemeCodeUnspsc190501.10432065'),
	'identifierSchemeCodeUnspsc190501.10432066': __('identifierSchemeCodeUnspsc190501.10432066'),
	'identifierSchemeCodeUnspsc190501.10432067': __('identifierSchemeCodeUnspsc190501.10432067'),
	'identifierSchemeCodeUnspsc190501.10432100': __('identifierSchemeCodeUnspsc190501.10432100'),
	'identifierSchemeCodeUnspsc190501.10432101': __('identifierSchemeCodeUnspsc190501.10432101'),
	'identifierSchemeCodeUnspsc190501.10432102': __('identifierSchemeCodeUnspsc190501.10432102'),
	'identifierSchemeCodeUnspsc190501.10432103': __('identifierSchemeCodeUnspsc190501.10432103'),
	'identifierSchemeCodeUnspsc190501.10432104': __('identifierSchemeCodeUnspsc190501.10432104'),
	'identifierSchemeCodeUnspsc190501.10432105': __('identifierSchemeCodeUnspsc190501.10432105'),
	'identifierSchemeCodeUnspsc190501.10432106': __('identifierSchemeCodeUnspsc190501.10432106'),
	'identifierSchemeCodeUnspsc190501.10432107': __('identifierSchemeCodeUnspsc190501.10432107'),
	'identifierSchemeCodeUnspsc190501.10432108': __('identifierSchemeCodeUnspsc190501.10432108'),
	'identifierSchemeCodeUnspsc190501.10432109': __('identifierSchemeCodeUnspsc190501.10432109'),
	'identifierSchemeCodeUnspsc190501.10432110': __('identifierSchemeCodeUnspsc190501.10432110'),
	'identifierSchemeCodeUnspsc190501.10432111': __('identifierSchemeCodeUnspsc190501.10432111'),
	'identifierSchemeCodeUnspsc190501.10432112': __('identifierSchemeCodeUnspsc190501.10432112'),
	'identifierSchemeCodeUnspsc190501.10432113': __('identifierSchemeCodeUnspsc190501.10432113'),
	'identifierSchemeCodeUnspsc190501.10432114': __('identifierSchemeCodeUnspsc190501.10432114'),
	'identifierSchemeCodeUnspsc190501.10432115': __('identifierSchemeCodeUnspsc190501.10432115'),
	'identifierSchemeCodeUnspsc190501.10432116': __('identifierSchemeCodeUnspsc190501.10432116'),
	'identifierSchemeCodeUnspsc190501.10432117': __('identifierSchemeCodeUnspsc190501.10432117'),
	'identifierSchemeCodeUnspsc190501.10432118': __('identifierSchemeCodeUnspsc190501.10432118'),
	'identifierSchemeCodeUnspsc190501.10432119': __('identifierSchemeCodeUnspsc190501.10432119'),
	'identifierSchemeCodeUnspsc190501.10432120': __('identifierSchemeCodeUnspsc190501.10432120'),
	'identifierSchemeCodeUnspsc190501.10432121': __('identifierSchemeCodeUnspsc190501.10432121'),
	'identifierSchemeCodeUnspsc190501.10432122': __('identifierSchemeCodeUnspsc190501.10432122'),
	'identifierSchemeCodeUnspsc190501.10432123': __('identifierSchemeCodeUnspsc190501.10432123'),
	'identifierSchemeCodeUnspsc190501.10432124': __('identifierSchemeCodeUnspsc190501.10432124'),
	'identifierSchemeCodeUnspsc190501.10432125': __('identifierSchemeCodeUnspsc190501.10432125'),
	'identifierSchemeCodeUnspsc190501.10432126': __('identifierSchemeCodeUnspsc190501.10432126'),
	'identifierSchemeCodeUnspsc190501.10432127': __('identifierSchemeCodeUnspsc190501.10432127'),
	'identifierSchemeCodeUnspsc190501.10432128': __('identifierSchemeCodeUnspsc190501.10432128'),
	'identifierSchemeCodeUnspsc190501.10432129': __('identifierSchemeCodeUnspsc190501.10432129'),
	'identifierSchemeCodeUnspsc190501.10432130': __('identifierSchemeCodeUnspsc190501.10432130'),
	'identifierSchemeCodeUnspsc190501.10432131': __('identifierSchemeCodeUnspsc190501.10432131'),
	'identifierSchemeCodeUnspsc190501.10432132': __('identifierSchemeCodeUnspsc190501.10432132'),
	'identifierSchemeCodeUnspsc190501.10440000': __('identifierSchemeCodeUnspsc190501.10440000'),
	'identifierSchemeCodeUnspsc190501.10441500': __('identifierSchemeCodeUnspsc190501.10441500'),
	'identifierSchemeCodeUnspsc190501.10441501': __('identifierSchemeCodeUnspsc190501.10441501'),
	'identifierSchemeCodeUnspsc190501.10441502': __('identifierSchemeCodeUnspsc190501.10441502'),
	'identifierSchemeCodeUnspsc190501.10441503': __('identifierSchemeCodeUnspsc190501.10441503'),
	'identifierSchemeCodeUnspsc190501.10441504': __('identifierSchemeCodeUnspsc190501.10441504'),
	'identifierSchemeCodeUnspsc190501.10441505': __('identifierSchemeCodeUnspsc190501.10441505'),
	'identifierSchemeCodeUnspsc190501.10441506': __('identifierSchemeCodeUnspsc190501.10441506'),
	'identifierSchemeCodeUnspsc190501.10441507': __('identifierSchemeCodeUnspsc190501.10441507'),
	'identifierSchemeCodeUnspsc190501.10441508': __('identifierSchemeCodeUnspsc190501.10441508'),
	'identifierSchemeCodeUnspsc190501.10441509': __('identifierSchemeCodeUnspsc190501.10441509'),
	'identifierSchemeCodeUnspsc190501.10441510': __('identifierSchemeCodeUnspsc190501.10441510'),
	'identifierSchemeCodeUnspsc190501.10441511': __('identifierSchemeCodeUnspsc190501.10441511'),
	'identifierSchemeCodeUnspsc190501.10441512': __('identifierSchemeCodeUnspsc190501.10441512'),
	'identifierSchemeCodeUnspsc190501.10441513': __('identifierSchemeCodeUnspsc190501.10441513'),
	'identifierSchemeCodeUnspsc190501.10441514': __('identifierSchemeCodeUnspsc190501.10441514'),
	'identifierSchemeCodeUnspsc190501.10441515': __('identifierSchemeCodeUnspsc190501.10441515'),
	'identifierSchemeCodeUnspsc190501.10441516': __('identifierSchemeCodeUnspsc190501.10441516'),
	'identifierSchemeCodeUnspsc190501.10441517': __('identifierSchemeCodeUnspsc190501.10441517'),
	'identifierSchemeCodeUnspsc190501.10441518': __('identifierSchemeCodeUnspsc190501.10441518'),
	'identifierSchemeCodeUnspsc190501.10441519': __('identifierSchemeCodeUnspsc190501.10441519'),
	'identifierSchemeCodeUnspsc190501.10441520': __('identifierSchemeCodeUnspsc190501.10441520'),
	'identifierSchemeCodeUnspsc190501.10441600': __('identifierSchemeCodeUnspsc190501.10441600'),
	'identifierSchemeCodeUnspsc190501.10441601': __('identifierSchemeCodeUnspsc190501.10441601'),
	'identifierSchemeCodeUnspsc190501.10441602': __('identifierSchemeCodeUnspsc190501.10441602'),
	'identifierSchemeCodeUnspsc190501.10441603': __('identifierSchemeCodeUnspsc190501.10441603'),
	'identifierSchemeCodeUnspsc190501.10441604': __('identifierSchemeCodeUnspsc190501.10441604'),
	'identifierSchemeCodeUnspsc190501.10441605': __('identifierSchemeCodeUnspsc190501.10441605'),
	'identifierSchemeCodeUnspsc190501.10441606': __('identifierSchemeCodeUnspsc190501.10441606'),
	'identifierSchemeCodeUnspsc190501.10441607': __('identifierSchemeCodeUnspsc190501.10441607'),
	'identifierSchemeCodeUnspsc190501.10441608': __('identifierSchemeCodeUnspsc190501.10441608'),
	'identifierSchemeCodeUnspsc190501.10441609': __('identifierSchemeCodeUnspsc190501.10441609'),
	'identifierSchemeCodeUnspsc190501.10441610': __('identifierSchemeCodeUnspsc190501.10441610'),
	'identifierSchemeCodeUnspsc190501.10441611': __('identifierSchemeCodeUnspsc190501.10441611'),
	'identifierSchemeCodeUnspsc190501.10441612': __('identifierSchemeCodeUnspsc190501.10441612'),
	'identifierSchemeCodeUnspsc190501.10441613': __('identifierSchemeCodeUnspsc190501.10441613'),
	'identifierSchemeCodeUnspsc190501.10441614': __('identifierSchemeCodeUnspsc190501.10441614'),
	'identifierSchemeCodeUnspsc190501.10450000': __('identifierSchemeCodeUnspsc190501.10450000'),
	'identifierSchemeCodeUnspsc190501.10451500': __('identifierSchemeCodeUnspsc190501.10451500'),
	'identifierSchemeCodeUnspsc190501.10451501': __('identifierSchemeCodeUnspsc190501.10451501'),
	'identifierSchemeCodeUnspsc190501.10451502': __('identifierSchemeCodeUnspsc190501.10451502'),
	'identifierSchemeCodeUnspsc190501.10451503': __('identifierSchemeCodeUnspsc190501.10451503'),
	'identifierSchemeCodeUnspsc190501.10451504': __('identifierSchemeCodeUnspsc190501.10451504'),
	'identifierSchemeCodeUnspsc190501.10451505': __('identifierSchemeCodeUnspsc190501.10451505'),
	'identifierSchemeCodeUnspsc190501.10451600': __('identifierSchemeCodeUnspsc190501.10451600'),
	'identifierSchemeCodeUnspsc190501.10451601': __('identifierSchemeCodeUnspsc190501.10451601'),
	'identifierSchemeCodeUnspsc190501.10451602': __('identifierSchemeCodeUnspsc190501.10451602'),
	'identifierSchemeCodeUnspsc190501.10451603': __('identifierSchemeCodeUnspsc190501.10451603'),
	'identifierSchemeCodeUnspsc190501.10451604': __('identifierSchemeCodeUnspsc190501.10451604'),
	'identifierSchemeCodeUnspsc190501.10451605': __('identifierSchemeCodeUnspsc190501.10451605'),
	'identifierSchemeCodeUnspsc190501.10451700': __('identifierSchemeCodeUnspsc190501.10451700'),
	'identifierSchemeCodeUnspsc190501.10451701': __('identifierSchemeCodeUnspsc190501.10451701'),
	'identifierSchemeCodeUnspsc190501.10451702': __('identifierSchemeCodeUnspsc190501.10451702'),
	'identifierSchemeCodeUnspsc190501.10451800': __('identifierSchemeCodeUnspsc190501.10451800'),
	'identifierSchemeCodeUnspsc190501.10451801': __('identifierSchemeCodeUnspsc190501.10451801'),
	'identifierSchemeCodeUnspsc190501.10451802': __('identifierSchemeCodeUnspsc190501.10451802'),
	'identifierSchemeCodeUnspsc190501.10451803': __('identifierSchemeCodeUnspsc190501.10451803'),
	'identifierSchemeCodeUnspsc190501.10451804': __('identifierSchemeCodeUnspsc190501.10451804'),
	'identifierSchemeCodeUnspsc190501.10451805': __('identifierSchemeCodeUnspsc190501.10451805'),
	'identifierSchemeCodeUnspsc190501.10451806': __('identifierSchemeCodeUnspsc190501.10451806'),
	'identifierSchemeCodeUnspsc190501.10451900': __('identifierSchemeCodeUnspsc190501.10451900'),
	'identifierSchemeCodeUnspsc190501.10451901': __('identifierSchemeCodeUnspsc190501.10451901'),
	'identifierSchemeCodeUnspsc190501.10451902': __('identifierSchemeCodeUnspsc190501.10451902'),
	'identifierSchemeCodeUnspsc190501.10451903': __('identifierSchemeCodeUnspsc190501.10451903'),
	'identifierSchemeCodeUnspsc190501.10451904': __('identifierSchemeCodeUnspsc190501.10451904'),
	'identifierSchemeCodeUnspsc190501.10451905': __('identifierSchemeCodeUnspsc190501.10451905'),
	'identifierSchemeCodeUnspsc190501.10452000': __('identifierSchemeCodeUnspsc190501.10452000'),
	'identifierSchemeCodeUnspsc190501.10452001': __('identifierSchemeCodeUnspsc190501.10452001'),
	'identifierSchemeCodeUnspsc190501.10452002': __('identifierSchemeCodeUnspsc190501.10452002'),
	'identifierSchemeCodeUnspsc190501.10452003': __('identifierSchemeCodeUnspsc190501.10452003'),
	'identifierSchemeCodeUnspsc190501.10452004': __('identifierSchemeCodeUnspsc190501.10452004'),
	'identifierSchemeCodeUnspsc190501.10452005': __('identifierSchemeCodeUnspsc190501.10452005'),
	'identifierSchemeCodeUnspsc190501.10452006': __('identifierSchemeCodeUnspsc190501.10452006'),
	'identifierSchemeCodeUnspsc190501.10452007': __('identifierSchemeCodeUnspsc190501.10452007'),
	'identifierSchemeCodeUnspsc190501.10452008': __('identifierSchemeCodeUnspsc190501.10452008'),
	'identifierSchemeCodeUnspsc190501.10452009': __('identifierSchemeCodeUnspsc190501.10452009'),
	'identifierSchemeCodeUnspsc190501.10452010': __('identifierSchemeCodeUnspsc190501.10452010'),
	'identifierSchemeCodeUnspsc190501.10452011': __('identifierSchemeCodeUnspsc190501.10452011'),
	'identifierSchemeCodeUnspsc190501.10452012': __('identifierSchemeCodeUnspsc190501.10452012'),
	'identifierSchemeCodeUnspsc190501.10452013': __('identifierSchemeCodeUnspsc190501.10452013'),
	'identifierSchemeCodeUnspsc190501.10452014': __('identifierSchemeCodeUnspsc190501.10452014'),
	'identifierSchemeCodeUnspsc190501.10452015': __('identifierSchemeCodeUnspsc190501.10452015'),
	'identifierSchemeCodeUnspsc190501.10452016': __('identifierSchemeCodeUnspsc190501.10452016'),
	'identifierSchemeCodeUnspsc190501.10452017': __('identifierSchemeCodeUnspsc190501.10452017'),
	'identifierSchemeCodeUnspsc190501.10452018': __('identifierSchemeCodeUnspsc190501.10452018'),
	'identifierSchemeCodeUnspsc190501.10452019': __('identifierSchemeCodeUnspsc190501.10452019'),
	'identifierSchemeCodeUnspsc190501.10452020': __('identifierSchemeCodeUnspsc190501.10452020'),
	'identifierSchemeCodeUnspsc190501.10452021': __('identifierSchemeCodeUnspsc190501.10452021'),
	'identifierSchemeCodeUnspsc190501.10452022': __('identifierSchemeCodeUnspsc190501.10452022'),
	'identifierSchemeCodeUnspsc190501.10452023': __('identifierSchemeCodeUnspsc190501.10452023'),
	'identifierSchemeCodeUnspsc190501.10452024': __('identifierSchemeCodeUnspsc190501.10452024'),
	'identifierSchemeCodeUnspsc190501.10452025': __('identifierSchemeCodeUnspsc190501.10452025'),
	'identifierSchemeCodeUnspsc190501.10452026': __('identifierSchemeCodeUnspsc190501.10452026'),
	'identifierSchemeCodeUnspsc190501.10452027': __('identifierSchemeCodeUnspsc190501.10452027'),
	'identifierSchemeCodeUnspsc190501.10452028': __('identifierSchemeCodeUnspsc190501.10452028'),
	'identifierSchemeCodeUnspsc190501.10452029': __('identifierSchemeCodeUnspsc190501.10452029'),
	'identifierSchemeCodeUnspsc190501.10452030': __('identifierSchemeCodeUnspsc190501.10452030'),
	'identifierSchemeCodeUnspsc190501.10452031': __('identifierSchemeCodeUnspsc190501.10452031'),
	'identifierSchemeCodeUnspsc190501.10452032': __('identifierSchemeCodeUnspsc190501.10452032'),
	'identifierSchemeCodeUnspsc190501.10452033': __('identifierSchemeCodeUnspsc190501.10452033'),
	'identifierSchemeCodeUnspsc190501.10452034': __('identifierSchemeCodeUnspsc190501.10452034'),
	'identifierSchemeCodeUnspsc190501.10452035': __('identifierSchemeCodeUnspsc190501.10452035'),
	'identifierSchemeCodeUnspsc190501.10452036': __('identifierSchemeCodeUnspsc190501.10452036'),
	'identifierSchemeCodeUnspsc190501.10452037': __('identifierSchemeCodeUnspsc190501.10452037'),
	'identifierSchemeCodeUnspsc190501.10452038': __('identifierSchemeCodeUnspsc190501.10452038'),
	'identifierSchemeCodeUnspsc190501.10452039': __('identifierSchemeCodeUnspsc190501.10452039'),
	'identifierSchemeCodeUnspsc190501.10452040': __('identifierSchemeCodeUnspsc190501.10452040'),
	'identifierSchemeCodeUnspsc190501.10452041': __('identifierSchemeCodeUnspsc190501.10452041'),
	'identifierSchemeCodeUnspsc190501.10452042': __('identifierSchemeCodeUnspsc190501.10452042'),
	'identifierSchemeCodeUnspsc190501.10452043': __('identifierSchemeCodeUnspsc190501.10452043'),
	'identifierSchemeCodeUnspsc190501.10452044': __('identifierSchemeCodeUnspsc190501.10452044'),
	'identifierSchemeCodeUnspsc190501.10452045': __('identifierSchemeCodeUnspsc190501.10452045'),
	'identifierSchemeCodeUnspsc190501.10452046': __('identifierSchemeCodeUnspsc190501.10452046'),
	'identifierSchemeCodeUnspsc190501.10452047': __('identifierSchemeCodeUnspsc190501.10452047'),
	'identifierSchemeCodeUnspsc190501.10452048': __('identifierSchemeCodeUnspsc190501.10452048'),
	'identifierSchemeCodeUnspsc190501.10452049': __('identifierSchemeCodeUnspsc190501.10452049'),
	'identifierSchemeCodeUnspsc190501.10452050': __('identifierSchemeCodeUnspsc190501.10452050'),
	'identifierSchemeCodeUnspsc190501.10452051': __('identifierSchemeCodeUnspsc190501.10452051'),
	'identifierSchemeCodeUnspsc190501.10452052': __('identifierSchemeCodeUnspsc190501.10452052'),
	'identifierSchemeCodeUnspsc190501.10452053': __('identifierSchemeCodeUnspsc190501.10452053'),
	'identifierSchemeCodeUnspsc190501.10452054': __('identifierSchemeCodeUnspsc190501.10452054'),
	'identifierSchemeCodeUnspsc190501.10452055': __('identifierSchemeCodeUnspsc190501.10452055'),
	'identifierSchemeCodeUnspsc190501.10452056': __('identifierSchemeCodeUnspsc190501.10452056'),
	'identifierSchemeCodeUnspsc190501.10452057': __('identifierSchemeCodeUnspsc190501.10452057'),
	'identifierSchemeCodeUnspsc190501.10452058': __('identifierSchemeCodeUnspsc190501.10452058'),
	'identifierSchemeCodeUnspsc190501.10452059': __('identifierSchemeCodeUnspsc190501.10452059'),
	'identifierSchemeCodeUnspsc190501.10452060': __('identifierSchemeCodeUnspsc190501.10452060'),
	'identifierSchemeCodeUnspsc190501.10452061': __('identifierSchemeCodeUnspsc190501.10452061'),
	'identifierSchemeCodeUnspsc190501.10452062': __('identifierSchemeCodeUnspsc190501.10452062'),
	'identifierSchemeCodeUnspsc190501.10452063': __('identifierSchemeCodeUnspsc190501.10452063'),
	'identifierSchemeCodeUnspsc190501.10452064': __('identifierSchemeCodeUnspsc190501.10452064'),
	'identifierSchemeCodeUnspsc190501.10452065': __('identifierSchemeCodeUnspsc190501.10452065'),
	'identifierSchemeCodeUnspsc190501.10452067': __('identifierSchemeCodeUnspsc190501.10452067'),
	'identifierSchemeCodeUnspsc190501.10452100': __('identifierSchemeCodeUnspsc190501.10452100'),
	'identifierSchemeCodeUnspsc190501.10452101': __('identifierSchemeCodeUnspsc190501.10452101'),
	'identifierSchemeCodeUnspsc190501.10452102': __('identifierSchemeCodeUnspsc190501.10452102'),
	'identifierSchemeCodeUnspsc190501.10452103': __('identifierSchemeCodeUnspsc190501.10452103'),
	'identifierSchemeCodeUnspsc190501.10452104': __('identifierSchemeCodeUnspsc190501.10452104'),
	'identifierSchemeCodeUnspsc190501.10452105': __('identifierSchemeCodeUnspsc190501.10452105'),
	'identifierSchemeCodeUnspsc190501.10452106': __('identifierSchemeCodeUnspsc190501.10452106'),
	'identifierSchemeCodeUnspsc190501.10452107': __('identifierSchemeCodeUnspsc190501.10452107'),
	'identifierSchemeCodeUnspsc190501.10452108': __('identifierSchemeCodeUnspsc190501.10452108'),
	'identifierSchemeCodeUnspsc190501.10452109': __('identifierSchemeCodeUnspsc190501.10452109'),
	'identifierSchemeCodeUnspsc190501.10452110': __('identifierSchemeCodeUnspsc190501.10452110'),
	'identifierSchemeCodeUnspsc190501.10452111': __('identifierSchemeCodeUnspsc190501.10452111'),
	'identifierSchemeCodeUnspsc190501.10452112': __('identifierSchemeCodeUnspsc190501.10452112'),
	'identifierSchemeCodeUnspsc190501.10452200': __('identifierSchemeCodeUnspsc190501.10452200'),
	'identifierSchemeCodeUnspsc190501.10452201': __('identifierSchemeCodeUnspsc190501.10452201'),
	'identifierSchemeCodeUnspsc190501.10452202': __('identifierSchemeCodeUnspsc190501.10452202'),
	'identifierSchemeCodeUnspsc190501.10452203': __('identifierSchemeCodeUnspsc190501.10452203'),
	'identifierSchemeCodeUnspsc190501.10452204': __('identifierSchemeCodeUnspsc190501.10452204'),
	'identifierSchemeCodeUnspsc190501.10452205': __('identifierSchemeCodeUnspsc190501.10452205'),
	'identifierSchemeCodeUnspsc190501.10452206': __('identifierSchemeCodeUnspsc190501.10452206'),
	'identifierSchemeCodeUnspsc190501.10452207': __('identifierSchemeCodeUnspsc190501.10452207'),
	'identifierSchemeCodeUnspsc190501.10452208': __('identifierSchemeCodeUnspsc190501.10452208'),
	'identifierSchemeCodeUnspsc190501.10452209': __('identifierSchemeCodeUnspsc190501.10452209'),
	'identifierSchemeCodeUnspsc190501.10452210': __('identifierSchemeCodeUnspsc190501.10452210'),
	'identifierSchemeCodeUnspsc190501.10452211': __('identifierSchemeCodeUnspsc190501.10452211'),
	'identifierSchemeCodeUnspsc190501.10452212': __('identifierSchemeCodeUnspsc190501.10452212'),
	'identifierSchemeCodeUnspsc190501.10452213': __('identifierSchemeCodeUnspsc190501.10452213'),
	'identifierSchemeCodeUnspsc190501.10452300': __('identifierSchemeCodeUnspsc190501.10452300'),
	'identifierSchemeCodeUnspsc190501.10452301': __('identifierSchemeCodeUnspsc190501.10452301'),
	'identifierSchemeCodeUnspsc190501.10452302': __('identifierSchemeCodeUnspsc190501.10452302'),
	'identifierSchemeCodeUnspsc190501.10452400': __('identifierSchemeCodeUnspsc190501.10452400'),
	'identifierSchemeCodeUnspsc190501.10452401': __('identifierSchemeCodeUnspsc190501.10452401'),
	'identifierSchemeCodeUnspsc190501.10452402': __('identifierSchemeCodeUnspsc190501.10452402'),
	'identifierSchemeCodeUnspsc190501.10452403': __('identifierSchemeCodeUnspsc190501.10452403'),
	'identifierSchemeCodeUnspsc190501.10452404': __('identifierSchemeCodeUnspsc190501.10452404'),
	'identifierSchemeCodeUnspsc190501.10452405': __('identifierSchemeCodeUnspsc190501.10452405'),
	'identifierSchemeCodeUnspsc190501.10452406': __('identifierSchemeCodeUnspsc190501.10452406'),
	'identifierSchemeCodeUnspsc190501.10500000': __('identifierSchemeCodeUnspsc190501.10500000'),
	'identifierSchemeCodeUnspsc190501.10501500': __('identifierSchemeCodeUnspsc190501.10501500'),
	'identifierSchemeCodeUnspsc190501.10501501': __('identifierSchemeCodeUnspsc190501.10501501'),
	'identifierSchemeCodeUnspsc190501.10501502': __('identifierSchemeCodeUnspsc190501.10501502'),
	'identifierSchemeCodeUnspsc190501.10501503': __('identifierSchemeCodeUnspsc190501.10501503'),
	'identifierSchemeCodeUnspsc190501.10501600': __('identifierSchemeCodeUnspsc190501.10501600'),
	'identifierSchemeCodeUnspsc190501.10501601': __('identifierSchemeCodeUnspsc190501.10501601'),
	'identifierSchemeCodeUnspsc190501.10501602': __('identifierSchemeCodeUnspsc190501.10501602'),
	'identifierSchemeCodeUnspsc190501.10501603': __('identifierSchemeCodeUnspsc190501.10501603'),
	'identifierSchemeCodeUnspsc190501.10501604': __('identifierSchemeCodeUnspsc190501.10501604'),
	'identifierSchemeCodeUnspsc190501.10501700': __('identifierSchemeCodeUnspsc190501.10501700'),
	'identifierSchemeCodeUnspsc190501.10501701': __('identifierSchemeCodeUnspsc190501.10501701'),
	'identifierSchemeCodeUnspsc190501.10501702': __('identifierSchemeCodeUnspsc190501.10501702'),
	'identifierSchemeCodeUnspsc190501.10501703': __('identifierSchemeCodeUnspsc190501.10501703'),
	'identifierSchemeCodeUnspsc190501.10501800': __('identifierSchemeCodeUnspsc190501.10501800'),
	'identifierSchemeCodeUnspsc190501.10501801': __('identifierSchemeCodeUnspsc190501.10501801'),
	'identifierSchemeCodeUnspsc190501.10501802': __('identifierSchemeCodeUnspsc190501.10501802'),
	'identifierSchemeCodeUnspsc190501.10501803': __('identifierSchemeCodeUnspsc190501.10501803'),
	'identifierSchemeCodeUnspsc190501.10501804': __('identifierSchemeCodeUnspsc190501.10501804'),
	'identifierSchemeCodeUnspsc190501.10501805': __('identifierSchemeCodeUnspsc190501.10501805'),
	'identifierSchemeCodeUnspsc190501.10501806': __('identifierSchemeCodeUnspsc190501.10501806'),
	'identifierSchemeCodeUnspsc190501.10501807': __('identifierSchemeCodeUnspsc190501.10501807'),
	'identifierSchemeCodeUnspsc190501.10501808': __('identifierSchemeCodeUnspsc190501.10501808'),
	'identifierSchemeCodeUnspsc190501.10501809': __('identifierSchemeCodeUnspsc190501.10501809'),
	'identifierSchemeCodeUnspsc190501.10501810': __('identifierSchemeCodeUnspsc190501.10501810'),
	'identifierSchemeCodeUnspsc190501.10501811': __('identifierSchemeCodeUnspsc190501.10501811'),
	'identifierSchemeCodeUnspsc190501.10501812': __('identifierSchemeCodeUnspsc190501.10501812'),
	'identifierSchemeCodeUnspsc190501.10501813': __('identifierSchemeCodeUnspsc190501.10501813'),
	'identifierSchemeCodeUnspsc190501.10501900': __('identifierSchemeCodeUnspsc190501.10501900'),
	'identifierSchemeCodeUnspsc190501.10501901': __('identifierSchemeCodeUnspsc190501.10501901'),
	'identifierSchemeCodeUnspsc190501.10501902': __('identifierSchemeCodeUnspsc190501.10501902'),
	'identifierSchemeCodeUnspsc190501.10501903': __('identifierSchemeCodeUnspsc190501.10501903'),
	'identifierSchemeCodeUnspsc190501.10501904': __('identifierSchemeCodeUnspsc190501.10501904'),
	'identifierSchemeCodeUnspsc190501.10501905': __('identifierSchemeCodeUnspsc190501.10501905'),
	'identifierSchemeCodeUnspsc190501.10501906': __('identifierSchemeCodeUnspsc190501.10501906'),
	'identifierSchemeCodeUnspsc190501.10501907': __('identifierSchemeCodeUnspsc190501.10501907'),
	'identifierSchemeCodeUnspsc190501.10501908': __('identifierSchemeCodeUnspsc190501.10501908'),
	'identifierSchemeCodeUnspsc190501.10502000': __('identifierSchemeCodeUnspsc190501.10502000'),
	'identifierSchemeCodeUnspsc190501.10502001': __('identifierSchemeCodeUnspsc190501.10502001'),
	'identifierSchemeCodeUnspsc190501.10502002': __('identifierSchemeCodeUnspsc190501.10502002'),
	'identifierSchemeCodeUnspsc190501.10502003': __('identifierSchemeCodeUnspsc190501.10502003'),
	'identifierSchemeCodeUnspsc190501.10502004': __('identifierSchemeCodeUnspsc190501.10502004'),
	'identifierSchemeCodeUnspsc190501.10502005': __('identifierSchemeCodeUnspsc190501.10502005'),
	'identifierSchemeCodeUnspsc190501.10502006': __('identifierSchemeCodeUnspsc190501.10502006'),
	'identifierSchemeCodeUnspsc190501.10502007': __('identifierSchemeCodeUnspsc190501.10502007'),
	'identifierSchemeCodeUnspsc190501.10502008': __('identifierSchemeCodeUnspsc190501.10502008'),
	'identifierSchemeCodeUnspsc190501.10502009': __('identifierSchemeCodeUnspsc190501.10502009'),
	'identifierSchemeCodeUnspsc190501.10502010': __('identifierSchemeCodeUnspsc190501.10502010'),
	'identifierSchemeCodeUnspsc190501.10502011': __('identifierSchemeCodeUnspsc190501.10502011'),
	'identifierSchemeCodeUnspsc190501.10502012': __('identifierSchemeCodeUnspsc190501.10502012'),
	'identifierSchemeCodeUnspsc190501.10502013': __('identifierSchemeCodeUnspsc190501.10502013'),
	'identifierSchemeCodeUnspsc190501.10502014': __('identifierSchemeCodeUnspsc190501.10502014'),
	'identifierSchemeCodeUnspsc190501.10502015': __('identifierSchemeCodeUnspsc190501.10502015'),
	'identifierSchemeCodeUnspsc190501.10502016': __('identifierSchemeCodeUnspsc190501.10502016'),
	'identifierSchemeCodeUnspsc190501.10502017': __('identifierSchemeCodeUnspsc190501.10502017'),
	'identifierSchemeCodeUnspsc190501.10502100': __('identifierSchemeCodeUnspsc190501.10502100'),
	'identifierSchemeCodeUnspsc190501.10502101': __('identifierSchemeCodeUnspsc190501.10502101'),
	'identifierSchemeCodeUnspsc190501.10502102': __('identifierSchemeCodeUnspsc190501.10502102'),
	'identifierSchemeCodeUnspsc190501.10502103': __('identifierSchemeCodeUnspsc190501.10502103'),
	'identifierSchemeCodeUnspsc190501.10502104': __('identifierSchemeCodeUnspsc190501.10502104'),
	'identifierSchemeCodeUnspsc190501.10502105': __('identifierSchemeCodeUnspsc190501.10502105'),
	'identifierSchemeCodeUnspsc190501.10502106': __('identifierSchemeCodeUnspsc190501.10502106'),
	'identifierSchemeCodeUnspsc190501.10502107': __('identifierSchemeCodeUnspsc190501.10502107'),
	'identifierSchemeCodeUnspsc190501.10502108': __('identifierSchemeCodeUnspsc190501.10502108'),
	'identifierSchemeCodeUnspsc190501.10502109': __('identifierSchemeCodeUnspsc190501.10502109'),
	'identifierSchemeCodeUnspsc190501.10502110': __('identifierSchemeCodeUnspsc190501.10502110'),
	'identifierSchemeCodeUnspsc190501.10502111': __('identifierSchemeCodeUnspsc190501.10502111'),
	'identifierSchemeCodeUnspsc190501.10502112': __('identifierSchemeCodeUnspsc190501.10502112'),
	'identifierSchemeCodeUnspsc190501.10502113': __('identifierSchemeCodeUnspsc190501.10502113'),
	'identifierSchemeCodeUnspsc190501.10502114': __('identifierSchemeCodeUnspsc190501.10502114'),
	'identifierSchemeCodeUnspsc190501.10502115': __('identifierSchemeCodeUnspsc190501.10502115'),
	'identifierSchemeCodeUnspsc190501.10502116': __('identifierSchemeCodeUnspsc190501.10502116'),
	'identifierSchemeCodeUnspsc190501.10502117': __('identifierSchemeCodeUnspsc190501.10502117'),
	'identifierSchemeCodeUnspsc190501.10502118': __('identifierSchemeCodeUnspsc190501.10502118'),
	'identifierSchemeCodeUnspsc190501.10502119': __('identifierSchemeCodeUnspsc190501.10502119'),
	'identifierSchemeCodeUnspsc190501.10502120': __('identifierSchemeCodeUnspsc190501.10502120'),
	'identifierSchemeCodeUnspsc190501.10502121': __('identifierSchemeCodeUnspsc190501.10502121'),
	'identifierSchemeCodeUnspsc190501.10502122': __('identifierSchemeCodeUnspsc190501.10502122'),
	'identifierSchemeCodeUnspsc190501.10502123': __('identifierSchemeCodeUnspsc190501.10502123'),
	'identifierSchemeCodeUnspsc190501.10502124': __('identifierSchemeCodeUnspsc190501.10502124'),
	'identifierSchemeCodeUnspsc190501.10502125': __('identifierSchemeCodeUnspsc190501.10502125'),
	'identifierSchemeCodeUnspsc190501.10502200': __('identifierSchemeCodeUnspsc190501.10502200'),
	'identifierSchemeCodeUnspsc190501.10502201': __('identifierSchemeCodeUnspsc190501.10502201'),
	'identifierSchemeCodeUnspsc190501.10502202': __('identifierSchemeCodeUnspsc190501.10502202'),
	'identifierSchemeCodeUnspsc190501.10502300': __('identifierSchemeCodeUnspsc190501.10502300'),
	'identifierSchemeCodeUnspsc190501.10502301': __('identifierSchemeCodeUnspsc190501.10502301'),
	'identifierSchemeCodeUnspsc190501.10502302': __('identifierSchemeCodeUnspsc190501.10502302'),
	'identifierSchemeCodeUnspsc190501.10502303': __('identifierSchemeCodeUnspsc190501.10502303'),
	'identifierSchemeCodeUnspsc190501.10502304': __('identifierSchemeCodeUnspsc190501.10502304'),
	'identifierSchemeCodeUnspsc190501.10502305': __('identifierSchemeCodeUnspsc190501.10502305'),
	'identifierSchemeCodeUnspsc190501.10502400': __('identifierSchemeCodeUnspsc190501.10502400'),
	'identifierSchemeCodeUnspsc190501.10502401': __('identifierSchemeCodeUnspsc190501.10502401'),
	'identifierSchemeCodeUnspsc190501.10502402': __('identifierSchemeCodeUnspsc190501.10502402'),
	'identifierSchemeCodeUnspsc190501.10502500': __('identifierSchemeCodeUnspsc190501.10502500'),
	'identifierSchemeCodeUnspsc190501.10502501': __('identifierSchemeCodeUnspsc190501.10502501'),
	'identifierSchemeCodeUnspsc190501.10502502': __('identifierSchemeCodeUnspsc190501.10502502'),
	'identifierSchemeCodeUnspsc190501.10502600': __('identifierSchemeCodeUnspsc190501.10502600'),
	'identifierSchemeCodeUnspsc190501.10502601': __('identifierSchemeCodeUnspsc190501.10502601'),
	'identifierSchemeCodeUnspsc190501.10502700': __('identifierSchemeCodeUnspsc190501.10502700'),
	'identifierSchemeCodeUnspsc190501.10502701': __('identifierSchemeCodeUnspsc190501.10502701'),
	'identifierSchemeCodeUnspsc190501.10502702': __('identifierSchemeCodeUnspsc190501.10502702'),
	'identifierSchemeCodeUnspsc190501.10502703': __('identifierSchemeCodeUnspsc190501.10502703'),
	'identifierSchemeCodeUnspsc190501.10502800': __('identifierSchemeCodeUnspsc190501.10502800'),
	'identifierSchemeCodeUnspsc190501.10502801': __('identifierSchemeCodeUnspsc190501.10502801'),
	'identifierSchemeCodeUnspsc190501.10502802': __('identifierSchemeCodeUnspsc190501.10502802'),
	'identifierSchemeCodeUnspsc190501.10502803': __('identifierSchemeCodeUnspsc190501.10502803'),
	'identifierSchemeCodeUnspsc190501.10502804': __('identifierSchemeCodeUnspsc190501.10502804'),
	'identifierSchemeCodeUnspsc190501.10502805': __('identifierSchemeCodeUnspsc190501.10502805'),
	'identifierSchemeCodeUnspsc190501.10502900': __('identifierSchemeCodeUnspsc190501.10502900'),
	'identifierSchemeCodeUnspsc190501.10502901': __('identifierSchemeCodeUnspsc190501.10502901'),
	'identifierSchemeCodeUnspsc190501.10502902': __('identifierSchemeCodeUnspsc190501.10502902'),
	'identifierSchemeCodeUnspsc190501.10502903': __('identifierSchemeCodeUnspsc190501.10502903'),
	'identifierSchemeCodeUnspsc190501.10502904': __('identifierSchemeCodeUnspsc190501.10502904'),
	'identifierSchemeCodeUnspsc190501.10502905': __('identifierSchemeCodeUnspsc190501.10502905'),
	'identifierSchemeCodeUnspsc190501.10502906': __('identifierSchemeCodeUnspsc190501.10502906'),
	'identifierSchemeCodeUnspsc190501.10502907': __('identifierSchemeCodeUnspsc190501.10502907'),
	'identifierSchemeCodeUnspsc190501.10502908': __('identifierSchemeCodeUnspsc190501.10502908'),
	'identifierSchemeCodeUnspsc190501.10502909': __('identifierSchemeCodeUnspsc190501.10502909'),
	'identifierSchemeCodeUnspsc190501.10502910': __('identifierSchemeCodeUnspsc190501.10502910'),
	'identifierSchemeCodeUnspsc190501.10502911': __('identifierSchemeCodeUnspsc190501.10502911'),
	'identifierSchemeCodeUnspsc190501.10502912': __('identifierSchemeCodeUnspsc190501.10502912'),
	'identifierSchemeCodeUnspsc190501.10502913': __('identifierSchemeCodeUnspsc190501.10502913'),
	'identifierSchemeCodeUnspsc190501.10502914': __('identifierSchemeCodeUnspsc190501.10502914'),
	'identifierSchemeCodeUnspsc190501.10502915': __('identifierSchemeCodeUnspsc190501.10502915'),
	'identifierSchemeCodeUnspsc190501.10502916': __('identifierSchemeCodeUnspsc190501.10502916'),
	'identifierSchemeCodeUnspsc190501.10502917': __('identifierSchemeCodeUnspsc190501.10502917'),
	'identifierSchemeCodeUnspsc190501.10502918': __('identifierSchemeCodeUnspsc190501.10502918'),
	'identifierSchemeCodeUnspsc190501.10502919': __('identifierSchemeCodeUnspsc190501.10502919'),
	'identifierSchemeCodeUnspsc190501.10502920': __('identifierSchemeCodeUnspsc190501.10502920'),
	'identifierSchemeCodeUnspsc190501.10502921': __('identifierSchemeCodeUnspsc190501.10502921'),
	'identifierSchemeCodeUnspsc190501.10502922': __('identifierSchemeCodeUnspsc190501.10502922'),
	'identifierSchemeCodeUnspsc190501.10502923': __('identifierSchemeCodeUnspsc190501.10502923'),
	'identifierSchemeCodeUnspsc190501.10502924': __('identifierSchemeCodeUnspsc190501.10502924'),
	'identifierSchemeCodeUnspsc190501.10502925': __('identifierSchemeCodeUnspsc190501.10502925'),
	'identifierSchemeCodeUnspsc190501.10502926': __('identifierSchemeCodeUnspsc190501.10502926'),
	'identifierSchemeCodeUnspsc190501.10502927': __('identifierSchemeCodeUnspsc190501.10502927'),
	'identifierSchemeCodeUnspsc190501.10502928': __('identifierSchemeCodeUnspsc190501.10502928'),
	'identifierSchemeCodeUnspsc190501.10502929': __('identifierSchemeCodeUnspsc190501.10502929'),
	'identifierSchemeCodeUnspsc190501.10502930': __('identifierSchemeCodeUnspsc190501.10502930'),
	'identifierSchemeCodeUnspsc190501.10502931': __('identifierSchemeCodeUnspsc190501.10502931'),
	'identifierSchemeCodeUnspsc190501.10502932': __('identifierSchemeCodeUnspsc190501.10502932'),
	'identifierSchemeCodeUnspsc190501.10502933': __('identifierSchemeCodeUnspsc190501.10502933'),
	'identifierSchemeCodeUnspsc190501.10502934': __('identifierSchemeCodeUnspsc190501.10502934'),
	'identifierSchemeCodeUnspsc190501.10502935': __('identifierSchemeCodeUnspsc190501.10502935'),
	'identifierSchemeCodeUnspsc190501.10502936': __('identifierSchemeCodeUnspsc190501.10502936'),
	'identifierSchemeCodeUnspsc190501.10502937': __('identifierSchemeCodeUnspsc190501.10502937'),
	'identifierSchemeCodeUnspsc190501.10502938': __('identifierSchemeCodeUnspsc190501.10502938'),
	'identifierSchemeCodeUnspsc190501.10502939': __('identifierSchemeCodeUnspsc190501.10502939'),
	'identifierSchemeCodeUnspsc190501.10502940': __('identifierSchemeCodeUnspsc190501.10502940'),
	'identifierSchemeCodeUnspsc190501.10502941': __('identifierSchemeCodeUnspsc190501.10502941'),
	'identifierSchemeCodeUnspsc190501.10502942': __('identifierSchemeCodeUnspsc190501.10502942'),
	'identifierSchemeCodeUnspsc190501.10502943': __('identifierSchemeCodeUnspsc190501.10502943'),
	'identifierSchemeCodeUnspsc190501.10502944': __('identifierSchemeCodeUnspsc190501.10502944'),
	'identifierSchemeCodeUnspsc190501.10502945': __('identifierSchemeCodeUnspsc190501.10502945'),
	'identifierSchemeCodeUnspsc190501.10502946': __('identifierSchemeCodeUnspsc190501.10502946'),
	'identifierSchemeCodeUnspsc190501.10502947': __('identifierSchemeCodeUnspsc190501.10502947'),
	'identifierSchemeCodeUnspsc190501.10502948': __('identifierSchemeCodeUnspsc190501.10502948'),
	'identifierSchemeCodeUnspsc190501.10502949': __('identifierSchemeCodeUnspsc190501.10502949'),
	'identifierSchemeCodeUnspsc190501.10502950': __('identifierSchemeCodeUnspsc190501.10502950'),
	'identifierSchemeCodeUnspsc190501.10502951': __('identifierSchemeCodeUnspsc190501.10502951'),
	'identifierSchemeCodeUnspsc190501.10502952': __('identifierSchemeCodeUnspsc190501.10502952'),
	'identifierSchemeCodeUnspsc190501.10502953': __('identifierSchemeCodeUnspsc190501.10502953'),
	'identifierSchemeCodeUnspsc190501.10502954': __('identifierSchemeCodeUnspsc190501.10502954'),
	'identifierSchemeCodeUnspsc190501.10502955': __('identifierSchemeCodeUnspsc190501.10502955'),
	'identifierSchemeCodeUnspsc190501.10502956': __('identifierSchemeCodeUnspsc190501.10502956'),
	'identifierSchemeCodeUnspsc190501.10502957': __('identifierSchemeCodeUnspsc190501.10502957'),
	'identifierSchemeCodeUnspsc190501.10502958': __('identifierSchemeCodeUnspsc190501.10502958'),
	'identifierSchemeCodeUnspsc190501.10502959': __('identifierSchemeCodeUnspsc190501.10502959'),
	'identifierSchemeCodeUnspsc190501.11000000': __('identifierSchemeCodeUnspsc190501.11000000'),
	'identifierSchemeCodeUnspsc190501.11100000': __('identifierSchemeCodeUnspsc190501.11100000'),
	'identifierSchemeCodeUnspsc190501.11101500': __('identifierSchemeCodeUnspsc190501.11101500'),
	'identifierSchemeCodeUnspsc190501.11101501': __('identifierSchemeCodeUnspsc190501.11101501'),
	'identifierSchemeCodeUnspsc190501.11101502': __('identifierSchemeCodeUnspsc190501.11101502'),
	'identifierSchemeCodeUnspsc190501.11101503': __('identifierSchemeCodeUnspsc190501.11101503'),
	'identifierSchemeCodeUnspsc190501.11101504': __('identifierSchemeCodeUnspsc190501.11101504'),
	'identifierSchemeCodeUnspsc190501.11101505': __('identifierSchemeCodeUnspsc190501.11101505'),
	'identifierSchemeCodeUnspsc190501.11101506': __('identifierSchemeCodeUnspsc190501.11101506'),
	'identifierSchemeCodeUnspsc190501.11101507': __('identifierSchemeCodeUnspsc190501.11101507'),
	'identifierSchemeCodeUnspsc190501.11101508': __('identifierSchemeCodeUnspsc190501.11101508'),
	'identifierSchemeCodeUnspsc190501.11101509': __('identifierSchemeCodeUnspsc190501.11101509'),
	'identifierSchemeCodeUnspsc190501.11101510': __('identifierSchemeCodeUnspsc190501.11101510'),
	'identifierSchemeCodeUnspsc190501.11101511': __('identifierSchemeCodeUnspsc190501.11101511'),
	'identifierSchemeCodeUnspsc190501.11101512': __('identifierSchemeCodeUnspsc190501.11101512'),
	'identifierSchemeCodeUnspsc190501.11101513': __('identifierSchemeCodeUnspsc190501.11101513'),
	'identifierSchemeCodeUnspsc190501.11101514': __('identifierSchemeCodeUnspsc190501.11101514'),
	'identifierSchemeCodeUnspsc190501.11101515': __('identifierSchemeCodeUnspsc190501.11101515'),
	'identifierSchemeCodeUnspsc190501.11101516': __('identifierSchemeCodeUnspsc190501.11101516'),
	'identifierSchemeCodeUnspsc190501.11101517': __('identifierSchemeCodeUnspsc190501.11101517'),
	'identifierSchemeCodeUnspsc190501.11101518': __('identifierSchemeCodeUnspsc190501.11101518'),
	'identifierSchemeCodeUnspsc190501.11101519': __('identifierSchemeCodeUnspsc190501.11101519'),
	'identifierSchemeCodeUnspsc190501.11101520': __('identifierSchemeCodeUnspsc190501.11101520'),
	'identifierSchemeCodeUnspsc190501.11101521': __('identifierSchemeCodeUnspsc190501.11101521'),
	'identifierSchemeCodeUnspsc190501.11101522': __('identifierSchemeCodeUnspsc190501.11101522'),
	'identifierSchemeCodeUnspsc190501.11101523': __('identifierSchemeCodeUnspsc190501.11101523'),
	'identifierSchemeCodeUnspsc190501.11101524': __('identifierSchemeCodeUnspsc190501.11101524'),
	'identifierSchemeCodeUnspsc190501.11101525': __('identifierSchemeCodeUnspsc190501.11101525'),
	'identifierSchemeCodeUnspsc190501.11101526': __('identifierSchemeCodeUnspsc190501.11101526'),
	'identifierSchemeCodeUnspsc190501.11101527': __('identifierSchemeCodeUnspsc190501.11101527'),
	'identifierSchemeCodeUnspsc190501.11101528': __('identifierSchemeCodeUnspsc190501.11101528'),
	'identifierSchemeCodeUnspsc190501.11101529': __('identifierSchemeCodeUnspsc190501.11101529'),
	'identifierSchemeCodeUnspsc190501.11101530': __('identifierSchemeCodeUnspsc190501.11101530'),
	'identifierSchemeCodeUnspsc190501.11101531': __('identifierSchemeCodeUnspsc190501.11101531'),
	'identifierSchemeCodeUnspsc190501.11101532': __('identifierSchemeCodeUnspsc190501.11101532'),
	'identifierSchemeCodeUnspsc190501.11101533': __('identifierSchemeCodeUnspsc190501.11101533'),
	'identifierSchemeCodeUnspsc190501.11101534': __('identifierSchemeCodeUnspsc190501.11101534'),
	'identifierSchemeCodeUnspsc190501.11101600': __('identifierSchemeCodeUnspsc190501.11101600'),
	'identifierSchemeCodeUnspsc190501.11101601': __('identifierSchemeCodeUnspsc190501.11101601'),
	'identifierSchemeCodeUnspsc190501.11101602': __('identifierSchemeCodeUnspsc190501.11101602'),
	'identifierSchemeCodeUnspsc190501.11101603': __('identifierSchemeCodeUnspsc190501.11101603'),
	'identifierSchemeCodeUnspsc190501.11101604': __('identifierSchemeCodeUnspsc190501.11101604'),
	'identifierSchemeCodeUnspsc190501.11101605': __('identifierSchemeCodeUnspsc190501.11101605'),
	'identifierSchemeCodeUnspsc190501.11101606': __('identifierSchemeCodeUnspsc190501.11101606'),
	'identifierSchemeCodeUnspsc190501.11101607': __('identifierSchemeCodeUnspsc190501.11101607'),
	'identifierSchemeCodeUnspsc190501.11101608': __('identifierSchemeCodeUnspsc190501.11101608'),
	'identifierSchemeCodeUnspsc190501.11101609': __('identifierSchemeCodeUnspsc190501.11101609'),
	'identifierSchemeCodeUnspsc190501.11101610': __('identifierSchemeCodeUnspsc190501.11101610'),
	'identifierSchemeCodeUnspsc190501.11101611': __('identifierSchemeCodeUnspsc190501.11101611'),
	'identifierSchemeCodeUnspsc190501.11101612': __('identifierSchemeCodeUnspsc190501.11101612'),
	'identifierSchemeCodeUnspsc190501.11101613': __('identifierSchemeCodeUnspsc190501.11101613'),
	'identifierSchemeCodeUnspsc190501.11101614': __('identifierSchemeCodeUnspsc190501.11101614'),
	'identifierSchemeCodeUnspsc190501.11101615': __('identifierSchemeCodeUnspsc190501.11101615'),
	'identifierSchemeCodeUnspsc190501.11101616': __('identifierSchemeCodeUnspsc190501.11101616'),
	'identifierSchemeCodeUnspsc190501.11101617': __('identifierSchemeCodeUnspsc190501.11101617'),
	'identifierSchemeCodeUnspsc190501.11101618': __('identifierSchemeCodeUnspsc190501.11101618'),
	'identifierSchemeCodeUnspsc190501.11101619': __('identifierSchemeCodeUnspsc190501.11101619'),
	'identifierSchemeCodeUnspsc190501.11101620': __('identifierSchemeCodeUnspsc190501.11101620'),
	'identifierSchemeCodeUnspsc190501.11101621': __('identifierSchemeCodeUnspsc190501.11101621'),
	'identifierSchemeCodeUnspsc190501.11101622': __('identifierSchemeCodeUnspsc190501.11101622'),
	'identifierSchemeCodeUnspsc190501.11101623': __('identifierSchemeCodeUnspsc190501.11101623'),
	'identifierSchemeCodeUnspsc190501.11101624': __('identifierSchemeCodeUnspsc190501.11101624'),
	'identifierSchemeCodeUnspsc190501.11101625': __('identifierSchemeCodeUnspsc190501.11101625'),
	'identifierSchemeCodeUnspsc190501.11101700': __('identifierSchemeCodeUnspsc190501.11101700'),
	'identifierSchemeCodeUnspsc190501.11101701': __('identifierSchemeCodeUnspsc190501.11101701'),
	'identifierSchemeCodeUnspsc190501.11101702': __('identifierSchemeCodeUnspsc190501.11101702'),
	'identifierSchemeCodeUnspsc190501.11101704': __('identifierSchemeCodeUnspsc190501.11101704'),
	'identifierSchemeCodeUnspsc190501.11101705': __('identifierSchemeCodeUnspsc190501.11101705'),
	'identifierSchemeCodeUnspsc190501.11101706': __('identifierSchemeCodeUnspsc190501.11101706'),
	'identifierSchemeCodeUnspsc190501.11101708': __('identifierSchemeCodeUnspsc190501.11101708'),
	'identifierSchemeCodeUnspsc190501.11101709': __('identifierSchemeCodeUnspsc190501.11101709'),
	'identifierSchemeCodeUnspsc190501.11101710': __('identifierSchemeCodeUnspsc190501.11101710'),
	'identifierSchemeCodeUnspsc190501.11101711': __('identifierSchemeCodeUnspsc190501.11101711'),
	'identifierSchemeCodeUnspsc190501.11101712': __('identifierSchemeCodeUnspsc190501.11101712'),
	'identifierSchemeCodeUnspsc190501.11101713': __('identifierSchemeCodeUnspsc190501.11101713'),
	'identifierSchemeCodeUnspsc190501.11101714': __('identifierSchemeCodeUnspsc190501.11101714'),
	'identifierSchemeCodeUnspsc190501.11101716': __('identifierSchemeCodeUnspsc190501.11101716'),
	'identifierSchemeCodeUnspsc190501.11101717': __('identifierSchemeCodeUnspsc190501.11101717'),
	'identifierSchemeCodeUnspsc190501.11101718': __('identifierSchemeCodeUnspsc190501.11101718'),
	'identifierSchemeCodeUnspsc190501.11101719': __('identifierSchemeCodeUnspsc190501.11101719'),
	'identifierSchemeCodeUnspsc190501.11101800': __('identifierSchemeCodeUnspsc190501.11101800'),
	'identifierSchemeCodeUnspsc190501.11101801': __('identifierSchemeCodeUnspsc190501.11101801'),
	'identifierSchemeCodeUnspsc190501.11101802': __('identifierSchemeCodeUnspsc190501.11101802'),
	'identifierSchemeCodeUnspsc190501.11101803': __('identifierSchemeCodeUnspsc190501.11101803'),
	'identifierSchemeCodeUnspsc190501.11101900': __('identifierSchemeCodeUnspsc190501.11101900'),
	'identifierSchemeCodeUnspsc190501.11101901': __('identifierSchemeCodeUnspsc190501.11101901'),
	'identifierSchemeCodeUnspsc190501.11101902': __('identifierSchemeCodeUnspsc190501.11101902'),
	'identifierSchemeCodeUnspsc190501.11101903': __('identifierSchemeCodeUnspsc190501.11101903'),
	'identifierSchemeCodeUnspsc190501.11101904': __('identifierSchemeCodeUnspsc190501.11101904'),
	'identifierSchemeCodeUnspsc190501.11101905': __('identifierSchemeCodeUnspsc190501.11101905'),
	'identifierSchemeCodeUnspsc190501.11101906': __('identifierSchemeCodeUnspsc190501.11101906'),
	'identifierSchemeCodeUnspsc190501.11101907': __('identifierSchemeCodeUnspsc190501.11101907'),
	'identifierSchemeCodeUnspsc190501.11101908': __('identifierSchemeCodeUnspsc190501.11101908'),
	'identifierSchemeCodeUnspsc190501.11101909': __('identifierSchemeCodeUnspsc190501.11101909'),
	'identifierSchemeCodeUnspsc190501.11101910': __('identifierSchemeCodeUnspsc190501.11101910'),
	'identifierSchemeCodeUnspsc190501.11101911': __('identifierSchemeCodeUnspsc190501.11101911'),
	'identifierSchemeCodeUnspsc190501.11101912': __('identifierSchemeCodeUnspsc190501.11101912'),
	'identifierSchemeCodeUnspsc190501.11101913': __('identifierSchemeCodeUnspsc190501.11101913'),
	'identifierSchemeCodeUnspsc190501.11101914': __('identifierSchemeCodeUnspsc190501.11101914'),
	'identifierSchemeCodeUnspsc190501.11101915': __('identifierSchemeCodeUnspsc190501.11101915'),
	'identifierSchemeCodeUnspsc190501.11101916': __('identifierSchemeCodeUnspsc190501.11101916'),
	'identifierSchemeCodeUnspsc190501.11101917': __('identifierSchemeCodeUnspsc190501.11101917'),
	'identifierSchemeCodeUnspsc190501.11101919': __('identifierSchemeCodeUnspsc190501.11101919'),
	'identifierSchemeCodeUnspsc190501.11101920': __('identifierSchemeCodeUnspsc190501.11101920'),
	'identifierSchemeCodeUnspsc190501.11101921': __('identifierSchemeCodeUnspsc190501.11101921'),
	'identifierSchemeCodeUnspsc190501.11101922': __('identifierSchemeCodeUnspsc190501.11101922'),
	'identifierSchemeCodeUnspsc190501.11101923': __('identifierSchemeCodeUnspsc190501.11101923'),
	'identifierSchemeCodeUnspsc190501.11101924': __('identifierSchemeCodeUnspsc190501.11101924'),
	'identifierSchemeCodeUnspsc190501.11110000': __('identifierSchemeCodeUnspsc190501.11110000'),
	'identifierSchemeCodeUnspsc190501.11111500': __('identifierSchemeCodeUnspsc190501.11111500'),
	'identifierSchemeCodeUnspsc190501.11111501': __('identifierSchemeCodeUnspsc190501.11111501'),
	'identifierSchemeCodeUnspsc190501.11111502': __('identifierSchemeCodeUnspsc190501.11111502'),
	'identifierSchemeCodeUnspsc190501.11111503': __('identifierSchemeCodeUnspsc190501.11111503'),
	'identifierSchemeCodeUnspsc190501.11111504': __('identifierSchemeCodeUnspsc190501.11111504'),
	'identifierSchemeCodeUnspsc190501.11111505': __('identifierSchemeCodeUnspsc190501.11111505'),
	'identifierSchemeCodeUnspsc190501.11111600': __('identifierSchemeCodeUnspsc190501.11111600'),
	'identifierSchemeCodeUnspsc190501.11111601': __('identifierSchemeCodeUnspsc190501.11111601'),
	'identifierSchemeCodeUnspsc190501.11111602': __('identifierSchemeCodeUnspsc190501.11111602'),
	'identifierSchemeCodeUnspsc190501.11111603': __('identifierSchemeCodeUnspsc190501.11111603'),
	'identifierSchemeCodeUnspsc190501.11111604': __('identifierSchemeCodeUnspsc190501.11111604'),
	'identifierSchemeCodeUnspsc190501.11111605': __('identifierSchemeCodeUnspsc190501.11111605'),
	'identifierSchemeCodeUnspsc190501.11111606': __('identifierSchemeCodeUnspsc190501.11111606'),
	'identifierSchemeCodeUnspsc190501.11111607': __('identifierSchemeCodeUnspsc190501.11111607'),
	'identifierSchemeCodeUnspsc190501.11111608': __('identifierSchemeCodeUnspsc190501.11111608'),
	'identifierSchemeCodeUnspsc190501.11111609': __('identifierSchemeCodeUnspsc190501.11111609'),
	'identifierSchemeCodeUnspsc190501.11111610': __('identifierSchemeCodeUnspsc190501.11111610'),
	'identifierSchemeCodeUnspsc190501.11111611': __('identifierSchemeCodeUnspsc190501.11111611'),
	'identifierSchemeCodeUnspsc190501.11111612': __('identifierSchemeCodeUnspsc190501.11111612'),
	'identifierSchemeCodeUnspsc190501.11111613': __('identifierSchemeCodeUnspsc190501.11111613'),
	'identifierSchemeCodeUnspsc190501.11111700': __('identifierSchemeCodeUnspsc190501.11111700'),
	'identifierSchemeCodeUnspsc190501.11111701': __('identifierSchemeCodeUnspsc190501.11111701'),
	'identifierSchemeCodeUnspsc190501.11111800': __('identifierSchemeCodeUnspsc190501.11111800'),
	'identifierSchemeCodeUnspsc190501.11111801': __('identifierSchemeCodeUnspsc190501.11111801'),
	'identifierSchemeCodeUnspsc190501.11111802': __('identifierSchemeCodeUnspsc190501.11111802'),
	'identifierSchemeCodeUnspsc190501.11111803': __('identifierSchemeCodeUnspsc190501.11111803'),
	'identifierSchemeCodeUnspsc190501.11111804': __('identifierSchemeCodeUnspsc190501.11111804'),
	'identifierSchemeCodeUnspsc190501.11111805': __('identifierSchemeCodeUnspsc190501.11111805'),
	'identifierSchemeCodeUnspsc190501.11111806': __('identifierSchemeCodeUnspsc190501.11111806'),
	'identifierSchemeCodeUnspsc190501.11111807': __('identifierSchemeCodeUnspsc190501.11111807'),
	'identifierSchemeCodeUnspsc190501.11111808': __('identifierSchemeCodeUnspsc190501.11111808'),
	'identifierSchemeCodeUnspsc190501.11111809': __('identifierSchemeCodeUnspsc190501.11111809'),
	'identifierSchemeCodeUnspsc190501.11111810': __('identifierSchemeCodeUnspsc190501.11111810'),
	'identifierSchemeCodeUnspsc190501.11111811': __('identifierSchemeCodeUnspsc190501.11111811'),
	'identifierSchemeCodeUnspsc190501.11111812': __('identifierSchemeCodeUnspsc190501.11111812'),
	'identifierSchemeCodeUnspsc190501.11120000': __('identifierSchemeCodeUnspsc190501.11120000'),
	'identifierSchemeCodeUnspsc190501.11121500': __('identifierSchemeCodeUnspsc190501.11121500'),
	'identifierSchemeCodeUnspsc190501.11121502': __('identifierSchemeCodeUnspsc190501.11121502'),
	'identifierSchemeCodeUnspsc190501.11121503': __('identifierSchemeCodeUnspsc190501.11121503'),
	'identifierSchemeCodeUnspsc190501.11121600': __('identifierSchemeCodeUnspsc190501.11121600'),
	'identifierSchemeCodeUnspsc190501.11121603': __('identifierSchemeCodeUnspsc190501.11121603'),
	'identifierSchemeCodeUnspsc190501.11121604': __('identifierSchemeCodeUnspsc190501.11121604'),
	'identifierSchemeCodeUnspsc190501.11121605': __('identifierSchemeCodeUnspsc190501.11121605'),
	'identifierSchemeCodeUnspsc190501.11121606': __('identifierSchemeCodeUnspsc190501.11121606'),
	'identifierSchemeCodeUnspsc190501.11121607': __('identifierSchemeCodeUnspsc190501.11121607'),
	'identifierSchemeCodeUnspsc190501.11121608': __('identifierSchemeCodeUnspsc190501.11121608'),
	'identifierSchemeCodeUnspsc190501.11121610': __('identifierSchemeCodeUnspsc190501.11121610'),
	'identifierSchemeCodeUnspsc190501.11121612': __('identifierSchemeCodeUnspsc190501.11121612'),
	'identifierSchemeCodeUnspsc190501.11121614': __('identifierSchemeCodeUnspsc190501.11121614'),
	'identifierSchemeCodeUnspsc190501.11121615': __('identifierSchemeCodeUnspsc190501.11121615'),
	'identifierSchemeCodeUnspsc190501.11121616': __('identifierSchemeCodeUnspsc190501.11121616'),
	'identifierSchemeCodeUnspsc190501.11121617': __('identifierSchemeCodeUnspsc190501.11121617'),
	'identifierSchemeCodeUnspsc190501.11121618': __('identifierSchemeCodeUnspsc190501.11121618'),
	'identifierSchemeCodeUnspsc190501.11121619': __('identifierSchemeCodeUnspsc190501.11121619'),
	'identifierSchemeCodeUnspsc190501.11121620': __('identifierSchemeCodeUnspsc190501.11121620'),
	'identifierSchemeCodeUnspsc190501.11121621': __('identifierSchemeCodeUnspsc190501.11121621'),
	'identifierSchemeCodeUnspsc190501.11121622': __('identifierSchemeCodeUnspsc190501.11121622'),
	'identifierSchemeCodeUnspsc190501.11121623': __('identifierSchemeCodeUnspsc190501.11121623'),
	'identifierSchemeCodeUnspsc190501.11121624': __('identifierSchemeCodeUnspsc190501.11121624'),
	'identifierSchemeCodeUnspsc190501.11121625': __('identifierSchemeCodeUnspsc190501.11121625'),
	'identifierSchemeCodeUnspsc190501.11121626': __('identifierSchemeCodeUnspsc190501.11121626'),
	'identifierSchemeCodeUnspsc190501.11121627': __('identifierSchemeCodeUnspsc190501.11121627'),
	'identifierSchemeCodeUnspsc190501.11121700': __('identifierSchemeCodeUnspsc190501.11121700'),
	'identifierSchemeCodeUnspsc190501.11121701': __('identifierSchemeCodeUnspsc190501.11121701'),
	'identifierSchemeCodeUnspsc190501.11121702': __('identifierSchemeCodeUnspsc190501.11121702'),
	'identifierSchemeCodeUnspsc190501.11121703': __('identifierSchemeCodeUnspsc190501.11121703'),
	'identifierSchemeCodeUnspsc190501.11121705': __('identifierSchemeCodeUnspsc190501.11121705'),
	'identifierSchemeCodeUnspsc190501.11121706': __('identifierSchemeCodeUnspsc190501.11121706'),
	'identifierSchemeCodeUnspsc190501.11121707': __('identifierSchemeCodeUnspsc190501.11121707'),
	'identifierSchemeCodeUnspsc190501.11121708': __('identifierSchemeCodeUnspsc190501.11121708'),
	'identifierSchemeCodeUnspsc190501.11121709': __('identifierSchemeCodeUnspsc190501.11121709'),
	'identifierSchemeCodeUnspsc190501.11121710': __('identifierSchemeCodeUnspsc190501.11121710'),
	'identifierSchemeCodeUnspsc190501.11121800': __('identifierSchemeCodeUnspsc190501.11121800'),
	'identifierSchemeCodeUnspsc190501.11121801': __('identifierSchemeCodeUnspsc190501.11121801'),
	'identifierSchemeCodeUnspsc190501.11121802': __('identifierSchemeCodeUnspsc190501.11121802'),
	'identifierSchemeCodeUnspsc190501.11121803': __('identifierSchemeCodeUnspsc190501.11121803'),
	'identifierSchemeCodeUnspsc190501.11121804': __('identifierSchemeCodeUnspsc190501.11121804'),
	'identifierSchemeCodeUnspsc190501.11121805': __('identifierSchemeCodeUnspsc190501.11121805'),
	'identifierSchemeCodeUnspsc190501.11121806': __('identifierSchemeCodeUnspsc190501.11121806'),
	'identifierSchemeCodeUnspsc190501.11121807': __('identifierSchemeCodeUnspsc190501.11121807'),
	'identifierSchemeCodeUnspsc190501.11121808': __('identifierSchemeCodeUnspsc190501.11121808'),
	'identifierSchemeCodeUnspsc190501.11121809': __('identifierSchemeCodeUnspsc190501.11121809'),
	'identifierSchemeCodeUnspsc190501.11121810': __('identifierSchemeCodeUnspsc190501.11121810'),
	'identifierSchemeCodeUnspsc190501.11121811': __('identifierSchemeCodeUnspsc190501.11121811'),
	'identifierSchemeCodeUnspsc190501.11121900': __('identifierSchemeCodeUnspsc190501.11121900'),
	'identifierSchemeCodeUnspsc190501.11121901': __('identifierSchemeCodeUnspsc190501.11121901'),
	'identifierSchemeCodeUnspsc190501.11122000': __('identifierSchemeCodeUnspsc190501.11122000'),
	'identifierSchemeCodeUnspsc190501.11122001': __('identifierSchemeCodeUnspsc190501.11122001'),
	'identifierSchemeCodeUnspsc190501.11122002': __('identifierSchemeCodeUnspsc190501.11122002'),
	'identifierSchemeCodeUnspsc190501.11122003': __('identifierSchemeCodeUnspsc190501.11122003'),
	'identifierSchemeCodeUnspsc190501.11122004': __('identifierSchemeCodeUnspsc190501.11122004'),
	'identifierSchemeCodeUnspsc190501.11122005': __('identifierSchemeCodeUnspsc190501.11122005'),
	'identifierSchemeCodeUnspsc190501.11122006': __('identifierSchemeCodeUnspsc190501.11122006'),
	'identifierSchemeCodeUnspsc190501.11122007': __('identifierSchemeCodeUnspsc190501.11122007'),
	'identifierSchemeCodeUnspsc190501.11130000': __('identifierSchemeCodeUnspsc190501.11130000'),
	'identifierSchemeCodeUnspsc190501.11131500': __('identifierSchemeCodeUnspsc190501.11131500'),
	'identifierSchemeCodeUnspsc190501.11131501': __('identifierSchemeCodeUnspsc190501.11131501'),
	'identifierSchemeCodeUnspsc190501.11131502': __('identifierSchemeCodeUnspsc190501.11131502'),
	'identifierSchemeCodeUnspsc190501.11131503': __('identifierSchemeCodeUnspsc190501.11131503'),
	'identifierSchemeCodeUnspsc190501.11131505': __('identifierSchemeCodeUnspsc190501.11131505'),
	'identifierSchemeCodeUnspsc190501.11131506': __('identifierSchemeCodeUnspsc190501.11131506'),
	'identifierSchemeCodeUnspsc190501.11131507': __('identifierSchemeCodeUnspsc190501.11131507'),
	'identifierSchemeCodeUnspsc190501.11131508': __('identifierSchemeCodeUnspsc190501.11131508'),
	'identifierSchemeCodeUnspsc190501.11131600': __('identifierSchemeCodeUnspsc190501.11131600'),
	'identifierSchemeCodeUnspsc190501.11131601': __('identifierSchemeCodeUnspsc190501.11131601'),
	'identifierSchemeCodeUnspsc190501.11131602': __('identifierSchemeCodeUnspsc190501.11131602'),
	'identifierSchemeCodeUnspsc190501.11131603': __('identifierSchemeCodeUnspsc190501.11131603'),
	'identifierSchemeCodeUnspsc190501.11131604': __('identifierSchemeCodeUnspsc190501.11131604'),
	'identifierSchemeCodeUnspsc190501.11131605': __('identifierSchemeCodeUnspsc190501.11131605'),
	'identifierSchemeCodeUnspsc190501.11131606': __('identifierSchemeCodeUnspsc190501.11131606'),
	'identifierSchemeCodeUnspsc190501.11131607': __('identifierSchemeCodeUnspsc190501.11131607'),
	'identifierSchemeCodeUnspsc190501.11131608': __('identifierSchemeCodeUnspsc190501.11131608'),
	'identifierSchemeCodeUnspsc190501.11131609': __('identifierSchemeCodeUnspsc190501.11131609'),
	'identifierSchemeCodeUnspsc190501.11131610': __('identifierSchemeCodeUnspsc190501.11131610'),
	'identifierSchemeCodeUnspsc190501.11140000': __('identifierSchemeCodeUnspsc190501.11140000'),
	'identifierSchemeCodeUnspsc190501.11141600': __('identifierSchemeCodeUnspsc190501.11141600'),
	'identifierSchemeCodeUnspsc190501.11141601': __('identifierSchemeCodeUnspsc190501.11141601'),
	'identifierSchemeCodeUnspsc190501.11141602': __('identifierSchemeCodeUnspsc190501.11141602'),
	'identifierSchemeCodeUnspsc190501.11141603': __('identifierSchemeCodeUnspsc190501.11141603'),
	'identifierSchemeCodeUnspsc190501.11141604': __('identifierSchemeCodeUnspsc190501.11141604'),
	'identifierSchemeCodeUnspsc190501.11141605': __('identifierSchemeCodeUnspsc190501.11141605'),
	'identifierSchemeCodeUnspsc190501.11141606': __('identifierSchemeCodeUnspsc190501.11141606'),
	'identifierSchemeCodeUnspsc190501.11141607': __('identifierSchemeCodeUnspsc190501.11141607'),
	'identifierSchemeCodeUnspsc190501.11141608': __('identifierSchemeCodeUnspsc190501.11141608'),
	'identifierSchemeCodeUnspsc190501.11141609': __('identifierSchemeCodeUnspsc190501.11141609'),
	'identifierSchemeCodeUnspsc190501.11141610': __('identifierSchemeCodeUnspsc190501.11141610'),
	'identifierSchemeCodeUnspsc190501.11141700': __('identifierSchemeCodeUnspsc190501.11141700'),
	'identifierSchemeCodeUnspsc190501.11141701': __('identifierSchemeCodeUnspsc190501.11141701'),
	'identifierSchemeCodeUnspsc190501.11141702': __('identifierSchemeCodeUnspsc190501.11141702'),
	'identifierSchemeCodeUnspsc190501.11150000': __('identifierSchemeCodeUnspsc190501.11150000'),
	'identifierSchemeCodeUnspsc190501.11151500': __('identifierSchemeCodeUnspsc190501.11151500'),
	'identifierSchemeCodeUnspsc190501.11151501': __('identifierSchemeCodeUnspsc190501.11151501'),
	'identifierSchemeCodeUnspsc190501.11151502': __('identifierSchemeCodeUnspsc190501.11151502'),
	'identifierSchemeCodeUnspsc190501.11151503': __('identifierSchemeCodeUnspsc190501.11151503'),
	'identifierSchemeCodeUnspsc190501.11151504': __('identifierSchemeCodeUnspsc190501.11151504'),
	'identifierSchemeCodeUnspsc190501.11151505': __('identifierSchemeCodeUnspsc190501.11151505'),
	'identifierSchemeCodeUnspsc190501.11151506': __('identifierSchemeCodeUnspsc190501.11151506'),
	'identifierSchemeCodeUnspsc190501.11151507': __('identifierSchemeCodeUnspsc190501.11151507'),
	'identifierSchemeCodeUnspsc190501.11151508': __('identifierSchemeCodeUnspsc190501.11151508'),
	'identifierSchemeCodeUnspsc190501.11151509': __('identifierSchemeCodeUnspsc190501.11151509'),
	'identifierSchemeCodeUnspsc190501.11151510': __('identifierSchemeCodeUnspsc190501.11151510'),
	'identifierSchemeCodeUnspsc190501.11151511': __('identifierSchemeCodeUnspsc190501.11151511'),
	'identifierSchemeCodeUnspsc190501.11151512': __('identifierSchemeCodeUnspsc190501.11151512'),
	'identifierSchemeCodeUnspsc190501.11151513': __('identifierSchemeCodeUnspsc190501.11151513'),
	'identifierSchemeCodeUnspsc190501.11151514': __('identifierSchemeCodeUnspsc190501.11151514'),
	'identifierSchemeCodeUnspsc190501.11151515': __('identifierSchemeCodeUnspsc190501.11151515'),
	'identifierSchemeCodeUnspsc190501.11151516': __('identifierSchemeCodeUnspsc190501.11151516'),
	'identifierSchemeCodeUnspsc190501.11151517': __('identifierSchemeCodeUnspsc190501.11151517'),
	'identifierSchemeCodeUnspsc190501.11151518': __('identifierSchemeCodeUnspsc190501.11151518'),
	'identifierSchemeCodeUnspsc190501.11151519': __('identifierSchemeCodeUnspsc190501.11151519'),
	'identifierSchemeCodeUnspsc190501.11151520': __('identifierSchemeCodeUnspsc190501.11151520'),
	'identifierSchemeCodeUnspsc190501.11151521': __('identifierSchemeCodeUnspsc190501.11151521'),
	'identifierSchemeCodeUnspsc190501.11151600': __('identifierSchemeCodeUnspsc190501.11151600'),
	'identifierSchemeCodeUnspsc190501.11151601': __('identifierSchemeCodeUnspsc190501.11151601'),
	'identifierSchemeCodeUnspsc190501.11151602': __('identifierSchemeCodeUnspsc190501.11151602'),
	'identifierSchemeCodeUnspsc190501.11151603': __('identifierSchemeCodeUnspsc190501.11151603'),
	'identifierSchemeCodeUnspsc190501.11151604': __('identifierSchemeCodeUnspsc190501.11151604'),
	'identifierSchemeCodeUnspsc190501.11151605': __('identifierSchemeCodeUnspsc190501.11151605'),
	'identifierSchemeCodeUnspsc190501.11151606': __('identifierSchemeCodeUnspsc190501.11151606'),
	'identifierSchemeCodeUnspsc190501.11151607': __('identifierSchemeCodeUnspsc190501.11151607'),
	'identifierSchemeCodeUnspsc190501.11151608': __('identifierSchemeCodeUnspsc190501.11151608'),
	'identifierSchemeCodeUnspsc190501.11151609': __('identifierSchemeCodeUnspsc190501.11151609'),
	'identifierSchemeCodeUnspsc190501.11151610': __('identifierSchemeCodeUnspsc190501.11151610'),
	'identifierSchemeCodeUnspsc190501.11151611': __('identifierSchemeCodeUnspsc190501.11151611'),
	'identifierSchemeCodeUnspsc190501.11151612': __('identifierSchemeCodeUnspsc190501.11151612'),
	'identifierSchemeCodeUnspsc190501.11151613': __('identifierSchemeCodeUnspsc190501.11151613'),
	'identifierSchemeCodeUnspsc190501.11151700': __('identifierSchemeCodeUnspsc190501.11151700'),
	'identifierSchemeCodeUnspsc190501.11151701': __('identifierSchemeCodeUnspsc190501.11151701'),
	'identifierSchemeCodeUnspsc190501.11151702': __('identifierSchemeCodeUnspsc190501.11151702'),
	'identifierSchemeCodeUnspsc190501.11151703': __('identifierSchemeCodeUnspsc190501.11151703'),
	'identifierSchemeCodeUnspsc190501.11151704': __('identifierSchemeCodeUnspsc190501.11151704'),
	'identifierSchemeCodeUnspsc190501.11151705': __('identifierSchemeCodeUnspsc190501.11151705'),
	'identifierSchemeCodeUnspsc190501.11151706': __('identifierSchemeCodeUnspsc190501.11151706'),
	'identifierSchemeCodeUnspsc190501.11151708': __('identifierSchemeCodeUnspsc190501.11151708'),
	'identifierSchemeCodeUnspsc190501.11151709': __('identifierSchemeCodeUnspsc190501.11151709'),
	'identifierSchemeCodeUnspsc190501.11151710': __('identifierSchemeCodeUnspsc190501.11151710'),
	'identifierSchemeCodeUnspsc190501.11151711': __('identifierSchemeCodeUnspsc190501.11151711'),
	'identifierSchemeCodeUnspsc190501.11151712': __('identifierSchemeCodeUnspsc190501.11151712'),
	'identifierSchemeCodeUnspsc190501.11151713': __('identifierSchemeCodeUnspsc190501.11151713'),
	'identifierSchemeCodeUnspsc190501.11151714': __('identifierSchemeCodeUnspsc190501.11151714'),
	'identifierSchemeCodeUnspsc190501.11151715': __('identifierSchemeCodeUnspsc190501.11151715'),
	'identifierSchemeCodeUnspsc190501.11151716': __('identifierSchemeCodeUnspsc190501.11151716'),
	'identifierSchemeCodeUnspsc190501.11151717': __('identifierSchemeCodeUnspsc190501.11151717'),
	'identifierSchemeCodeUnspsc190501.11151718': __('identifierSchemeCodeUnspsc190501.11151718'),
	'identifierSchemeCodeUnspsc190501.11160000': __('identifierSchemeCodeUnspsc190501.11160000'),
	'identifierSchemeCodeUnspsc190501.11161500': __('identifierSchemeCodeUnspsc190501.11161500'),
	'identifierSchemeCodeUnspsc190501.11161501': __('identifierSchemeCodeUnspsc190501.11161501'),
	'identifierSchemeCodeUnspsc190501.11161502': __('identifierSchemeCodeUnspsc190501.11161502'),
	'identifierSchemeCodeUnspsc190501.11161503': __('identifierSchemeCodeUnspsc190501.11161503'),
	'identifierSchemeCodeUnspsc190501.11161504': __('identifierSchemeCodeUnspsc190501.11161504'),
	'identifierSchemeCodeUnspsc190501.11161600': __('identifierSchemeCodeUnspsc190501.11161600'),
	'identifierSchemeCodeUnspsc190501.11161601': __('identifierSchemeCodeUnspsc190501.11161601'),
	'identifierSchemeCodeUnspsc190501.11161602': __('identifierSchemeCodeUnspsc190501.11161602'),
	'identifierSchemeCodeUnspsc190501.11161603': __('identifierSchemeCodeUnspsc190501.11161603'),
	'identifierSchemeCodeUnspsc190501.11161604': __('identifierSchemeCodeUnspsc190501.11161604'),
	'identifierSchemeCodeUnspsc190501.11161605': __('identifierSchemeCodeUnspsc190501.11161605'),
	'identifierSchemeCodeUnspsc190501.11161606': __('identifierSchemeCodeUnspsc190501.11161606'),
	'identifierSchemeCodeUnspsc190501.11161607': __('identifierSchemeCodeUnspsc190501.11161607'),
	'identifierSchemeCodeUnspsc190501.11161608': __('identifierSchemeCodeUnspsc190501.11161608'),
	'identifierSchemeCodeUnspsc190501.11161700': __('identifierSchemeCodeUnspsc190501.11161700'),
	'identifierSchemeCodeUnspsc190501.11161701': __('identifierSchemeCodeUnspsc190501.11161701'),
	'identifierSchemeCodeUnspsc190501.11161702': __('identifierSchemeCodeUnspsc190501.11161702'),
	'identifierSchemeCodeUnspsc190501.11161703': __('identifierSchemeCodeUnspsc190501.11161703'),
	'identifierSchemeCodeUnspsc190501.11161704': __('identifierSchemeCodeUnspsc190501.11161704'),
	'identifierSchemeCodeUnspsc190501.11161705': __('identifierSchemeCodeUnspsc190501.11161705'),
	'identifierSchemeCodeUnspsc190501.11161706': __('identifierSchemeCodeUnspsc190501.11161706'),
	'identifierSchemeCodeUnspsc190501.11161707': __('identifierSchemeCodeUnspsc190501.11161707'),
	'identifierSchemeCodeUnspsc190501.11161800': __('identifierSchemeCodeUnspsc190501.11161800'),
	'identifierSchemeCodeUnspsc190501.11161801': __('identifierSchemeCodeUnspsc190501.11161801'),
	'identifierSchemeCodeUnspsc190501.11161802': __('identifierSchemeCodeUnspsc190501.11161802'),
	'identifierSchemeCodeUnspsc190501.11161803': __('identifierSchemeCodeUnspsc190501.11161803'),
	'identifierSchemeCodeUnspsc190501.11161804': __('identifierSchemeCodeUnspsc190501.11161804'),
	'identifierSchemeCodeUnspsc190501.11161805': __('identifierSchemeCodeUnspsc190501.11161805'),
	'identifierSchemeCodeUnspsc190501.11161806': __('identifierSchemeCodeUnspsc190501.11161806'),
	'identifierSchemeCodeUnspsc190501.11161807': __('identifierSchemeCodeUnspsc190501.11161807'),
	'identifierSchemeCodeUnspsc190501.11161808': __('identifierSchemeCodeUnspsc190501.11161808'),
	'identifierSchemeCodeUnspsc190501.11162000': __('identifierSchemeCodeUnspsc190501.11162000'),
	'identifierSchemeCodeUnspsc190501.11162001': __('identifierSchemeCodeUnspsc190501.11162001'),
	'identifierSchemeCodeUnspsc190501.11162002': __('identifierSchemeCodeUnspsc190501.11162002'),
	'identifierSchemeCodeUnspsc190501.11162003': __('identifierSchemeCodeUnspsc190501.11162003'),
	'identifierSchemeCodeUnspsc190501.11162004': __('identifierSchemeCodeUnspsc190501.11162004'),
	'identifierSchemeCodeUnspsc190501.11162005': __('identifierSchemeCodeUnspsc190501.11162005'),
	'identifierSchemeCodeUnspsc190501.11162100': __('identifierSchemeCodeUnspsc190501.11162100'),
	'identifierSchemeCodeUnspsc190501.11162101': __('identifierSchemeCodeUnspsc190501.11162101'),
	'identifierSchemeCodeUnspsc190501.11162102': __('identifierSchemeCodeUnspsc190501.11162102'),
	'identifierSchemeCodeUnspsc190501.11162104': __('identifierSchemeCodeUnspsc190501.11162104'),
	'identifierSchemeCodeUnspsc190501.11162105': __('identifierSchemeCodeUnspsc190501.11162105'),
	'identifierSchemeCodeUnspsc190501.11162107': __('identifierSchemeCodeUnspsc190501.11162107'),
	'identifierSchemeCodeUnspsc190501.11162108': __('identifierSchemeCodeUnspsc190501.11162108'),
	'identifierSchemeCodeUnspsc190501.11162109': __('identifierSchemeCodeUnspsc190501.11162109'),
	'identifierSchemeCodeUnspsc190501.11162110': __('identifierSchemeCodeUnspsc190501.11162110'),
	'identifierSchemeCodeUnspsc190501.11162111': __('identifierSchemeCodeUnspsc190501.11162111'),
	'identifierSchemeCodeUnspsc190501.11162112': __('identifierSchemeCodeUnspsc190501.11162112'),
	'identifierSchemeCodeUnspsc190501.11162113': __('identifierSchemeCodeUnspsc190501.11162113'),
	'identifierSchemeCodeUnspsc190501.11162114': __('identifierSchemeCodeUnspsc190501.11162114'),
	'identifierSchemeCodeUnspsc190501.11162115': __('identifierSchemeCodeUnspsc190501.11162115'),
	'identifierSchemeCodeUnspsc190501.11162116': __('identifierSchemeCodeUnspsc190501.11162116'),
	'identifierSchemeCodeUnspsc190501.11162117': __('identifierSchemeCodeUnspsc190501.11162117'),
	'identifierSchemeCodeUnspsc190501.11162118': __('identifierSchemeCodeUnspsc190501.11162118'),
	'identifierSchemeCodeUnspsc190501.11162119': __('identifierSchemeCodeUnspsc190501.11162119'),
	'identifierSchemeCodeUnspsc190501.11162120': __('identifierSchemeCodeUnspsc190501.11162120'),
	'identifierSchemeCodeUnspsc190501.11162121': __('identifierSchemeCodeUnspsc190501.11162121'),
	'identifierSchemeCodeUnspsc190501.11162122': __('identifierSchemeCodeUnspsc190501.11162122'),
	'identifierSchemeCodeUnspsc190501.11162123': __('identifierSchemeCodeUnspsc190501.11162123'),
	'identifierSchemeCodeUnspsc190501.11162124': __('identifierSchemeCodeUnspsc190501.11162124'),
	'identifierSchemeCodeUnspsc190501.11162125': __('identifierSchemeCodeUnspsc190501.11162125'),
	'identifierSchemeCodeUnspsc190501.11162126': __('identifierSchemeCodeUnspsc190501.11162126'),
	'identifierSchemeCodeUnspsc190501.11162127': __('identifierSchemeCodeUnspsc190501.11162127'),
	'identifierSchemeCodeUnspsc190501.11162128': __('identifierSchemeCodeUnspsc190501.11162128'),
	'identifierSchemeCodeUnspsc190501.11162129': __('identifierSchemeCodeUnspsc190501.11162129'),
	'identifierSchemeCodeUnspsc190501.11162130': __('identifierSchemeCodeUnspsc190501.11162130'),
	'identifierSchemeCodeUnspsc190501.11162131': __('identifierSchemeCodeUnspsc190501.11162131'),
	'identifierSchemeCodeUnspsc190501.11162132': __('identifierSchemeCodeUnspsc190501.11162132'),
	'identifierSchemeCodeUnspsc190501.11162133': __('identifierSchemeCodeUnspsc190501.11162133'),
	'identifierSchemeCodeUnspsc190501.11162134': __('identifierSchemeCodeUnspsc190501.11162134'),
	'identifierSchemeCodeUnspsc190501.11162135': __('identifierSchemeCodeUnspsc190501.11162135'),
	'identifierSchemeCodeUnspsc190501.11162136': __('identifierSchemeCodeUnspsc190501.11162136'),
	'identifierSchemeCodeUnspsc190501.11162137': __('identifierSchemeCodeUnspsc190501.11162137'),
	'identifierSchemeCodeUnspsc190501.11162138': __('identifierSchemeCodeUnspsc190501.11162138'),
	'identifierSchemeCodeUnspsc190501.11162139': __('identifierSchemeCodeUnspsc190501.11162139'),
	'identifierSchemeCodeUnspsc190501.11162200': __('identifierSchemeCodeUnspsc190501.11162200'),
	'identifierSchemeCodeUnspsc190501.11162201': __('identifierSchemeCodeUnspsc190501.11162201'),
	'identifierSchemeCodeUnspsc190501.11162202': __('identifierSchemeCodeUnspsc190501.11162202'),
	'identifierSchemeCodeUnspsc190501.11162300': __('identifierSchemeCodeUnspsc190501.11162300'),
	'identifierSchemeCodeUnspsc190501.11162301': __('identifierSchemeCodeUnspsc190501.11162301'),
	'identifierSchemeCodeUnspsc190501.11162302': __('identifierSchemeCodeUnspsc190501.11162302'),
	'identifierSchemeCodeUnspsc190501.11162303': __('identifierSchemeCodeUnspsc190501.11162303'),
	'identifierSchemeCodeUnspsc190501.11162304': __('identifierSchemeCodeUnspsc190501.11162304'),
	'identifierSchemeCodeUnspsc190501.11162305': __('identifierSchemeCodeUnspsc190501.11162305'),
	'identifierSchemeCodeUnspsc190501.11162306': __('identifierSchemeCodeUnspsc190501.11162306'),
	'identifierSchemeCodeUnspsc190501.11162307': __('identifierSchemeCodeUnspsc190501.11162307'),
	'identifierSchemeCodeUnspsc190501.11162308': __('identifierSchemeCodeUnspsc190501.11162308'),
	'identifierSchemeCodeUnspsc190501.11162309': __('identifierSchemeCodeUnspsc190501.11162309'),
	'identifierSchemeCodeUnspsc190501.11162310': __('identifierSchemeCodeUnspsc190501.11162310'),
	'identifierSchemeCodeUnspsc190501.11162311': __('identifierSchemeCodeUnspsc190501.11162311'),
	'identifierSchemeCodeUnspsc190501.11162400': __('identifierSchemeCodeUnspsc190501.11162400'),
	'identifierSchemeCodeUnspsc190501.11162401': __('identifierSchemeCodeUnspsc190501.11162401'),
	'identifierSchemeCodeUnspsc190501.11162402': __('identifierSchemeCodeUnspsc190501.11162402'),
	'identifierSchemeCodeUnspsc190501.11170000': __('identifierSchemeCodeUnspsc190501.11170000'),
	'identifierSchemeCodeUnspsc190501.11171500': __('identifierSchemeCodeUnspsc190501.11171500'),
	'identifierSchemeCodeUnspsc190501.11171501': __('identifierSchemeCodeUnspsc190501.11171501'),
	'identifierSchemeCodeUnspsc190501.11171600': __('identifierSchemeCodeUnspsc190501.11171600'),
	'identifierSchemeCodeUnspsc190501.11171601': __('identifierSchemeCodeUnspsc190501.11171601'),
	'identifierSchemeCodeUnspsc190501.11171602': __('identifierSchemeCodeUnspsc190501.11171602'),
	'identifierSchemeCodeUnspsc190501.11171603': __('identifierSchemeCodeUnspsc190501.11171603'),
	'identifierSchemeCodeUnspsc190501.11171604': __('identifierSchemeCodeUnspsc190501.11171604'),
	'identifierSchemeCodeUnspsc190501.11171700': __('identifierSchemeCodeUnspsc190501.11171700'),
	'identifierSchemeCodeUnspsc190501.11171701': __('identifierSchemeCodeUnspsc190501.11171701'),
	'identifierSchemeCodeUnspsc190501.11171702': __('identifierSchemeCodeUnspsc190501.11171702'),
	'identifierSchemeCodeUnspsc190501.11171800': __('identifierSchemeCodeUnspsc190501.11171800'),
	'identifierSchemeCodeUnspsc190501.11171801': __('identifierSchemeCodeUnspsc190501.11171801'),
	'identifierSchemeCodeUnspsc190501.11171900': __('identifierSchemeCodeUnspsc190501.11171900'),
	'identifierSchemeCodeUnspsc190501.11171901': __('identifierSchemeCodeUnspsc190501.11171901'),
	'identifierSchemeCodeUnspsc190501.11172000': __('identifierSchemeCodeUnspsc190501.11172000'),
	'identifierSchemeCodeUnspsc190501.11172001': __('identifierSchemeCodeUnspsc190501.11172001'),
	'identifierSchemeCodeUnspsc190501.11172002': __('identifierSchemeCodeUnspsc190501.11172002'),
	'identifierSchemeCodeUnspsc190501.11172003': __('identifierSchemeCodeUnspsc190501.11172003'),
	'identifierSchemeCodeUnspsc190501.11172100': __('identifierSchemeCodeUnspsc190501.11172100'),
	'identifierSchemeCodeUnspsc190501.11172101': __('identifierSchemeCodeUnspsc190501.11172101'),
	'identifierSchemeCodeUnspsc190501.11172200': __('identifierSchemeCodeUnspsc190501.11172200'),
	'identifierSchemeCodeUnspsc190501.11172201': __('identifierSchemeCodeUnspsc190501.11172201'),
	'identifierSchemeCodeUnspsc190501.11172300': __('identifierSchemeCodeUnspsc190501.11172300'),
	'identifierSchemeCodeUnspsc190501.11172301': __('identifierSchemeCodeUnspsc190501.11172301'),
	'identifierSchemeCodeUnspsc190501.11172302': __('identifierSchemeCodeUnspsc190501.11172302'),
	'identifierSchemeCodeUnspsc190501.11172303': __('identifierSchemeCodeUnspsc190501.11172303'),
	'identifierSchemeCodeUnspsc190501.11172304': __('identifierSchemeCodeUnspsc190501.11172304'),
	'identifierSchemeCodeUnspsc190501.11180000': __('identifierSchemeCodeUnspsc190501.11180000'),
	'identifierSchemeCodeUnspsc190501.11181500': __('identifierSchemeCodeUnspsc190501.11181500'),
	'identifierSchemeCodeUnspsc190501.11181501': __('identifierSchemeCodeUnspsc190501.11181501'),
	'identifierSchemeCodeUnspsc190501.11181502': __('identifierSchemeCodeUnspsc190501.11181502'),
	'identifierSchemeCodeUnspsc190501.11181503': __('identifierSchemeCodeUnspsc190501.11181503'),
	'identifierSchemeCodeUnspsc190501.11181504': __('identifierSchemeCodeUnspsc190501.11181504'),
	'identifierSchemeCodeUnspsc190501.11181505': __('identifierSchemeCodeUnspsc190501.11181505'),
	'identifierSchemeCodeUnspsc190501.11181506': __('identifierSchemeCodeUnspsc190501.11181506'),
	'identifierSchemeCodeUnspsc190501.11181507': __('identifierSchemeCodeUnspsc190501.11181507'),
	'identifierSchemeCodeUnspsc190501.11181508': __('identifierSchemeCodeUnspsc190501.11181508'),
	'identifierSchemeCodeUnspsc190501.11181509': __('identifierSchemeCodeUnspsc190501.11181509'),
	'identifierSchemeCodeUnspsc190501.11181510': __('identifierSchemeCodeUnspsc190501.11181510'),
	'identifierSchemeCodeUnspsc190501.11181511': __('identifierSchemeCodeUnspsc190501.11181511'),
	'identifierSchemeCodeUnspsc190501.11181512': __('identifierSchemeCodeUnspsc190501.11181512'),
	'identifierSchemeCodeUnspsc190501.11190000': __('identifierSchemeCodeUnspsc190501.11190000'),
	'identifierSchemeCodeUnspsc190501.11191500': __('identifierSchemeCodeUnspsc190501.11191500'),
	'identifierSchemeCodeUnspsc190501.11191501': __('identifierSchemeCodeUnspsc190501.11191501'),
	'identifierSchemeCodeUnspsc190501.11191502': __('identifierSchemeCodeUnspsc190501.11191502'),
	'identifierSchemeCodeUnspsc190501.11191503': __('identifierSchemeCodeUnspsc190501.11191503'),
	'identifierSchemeCodeUnspsc190501.11191504': __('identifierSchemeCodeUnspsc190501.11191504'),
	'identifierSchemeCodeUnspsc190501.11191505': __('identifierSchemeCodeUnspsc190501.11191505'),
	'identifierSchemeCodeUnspsc190501.11191600': __('identifierSchemeCodeUnspsc190501.11191600'),
	'identifierSchemeCodeUnspsc190501.11191601': __('identifierSchemeCodeUnspsc190501.11191601'),
	'identifierSchemeCodeUnspsc190501.11191602': __('identifierSchemeCodeUnspsc190501.11191602'),
	'identifierSchemeCodeUnspsc190501.11191603': __('identifierSchemeCodeUnspsc190501.11191603'),
	'identifierSchemeCodeUnspsc190501.11191604': __('identifierSchemeCodeUnspsc190501.11191604'),
	'identifierSchemeCodeUnspsc190501.11191605': __('identifierSchemeCodeUnspsc190501.11191605'),
	'identifierSchemeCodeUnspsc190501.11191606': __('identifierSchemeCodeUnspsc190501.11191606'),
	'identifierSchemeCodeUnspsc190501.11191607': __('identifierSchemeCodeUnspsc190501.11191607'),
	'identifierSchemeCodeUnspsc190501.11191608': __('identifierSchemeCodeUnspsc190501.11191608'),
	'identifierSchemeCodeUnspsc190501.11191609': __('identifierSchemeCodeUnspsc190501.11191609'),
	'identifierSchemeCodeUnspsc190501.11191610': __('identifierSchemeCodeUnspsc190501.11191610'),
	'identifierSchemeCodeUnspsc190501.11191611': __('identifierSchemeCodeUnspsc190501.11191611'),
	'identifierSchemeCodeUnspsc190501.11191612': __('identifierSchemeCodeUnspsc190501.11191612'),
	'identifierSchemeCodeUnspsc190501.11191613': __('identifierSchemeCodeUnspsc190501.11191613'),
	'identifierSchemeCodeUnspsc190501.11191614': __('identifierSchemeCodeUnspsc190501.11191614'),
	'identifierSchemeCodeUnspsc190501.11191700': __('identifierSchemeCodeUnspsc190501.11191700'),
	'identifierSchemeCodeUnspsc190501.11191701': __('identifierSchemeCodeUnspsc190501.11191701'),
	'identifierSchemeCodeUnspsc190501.11191702': __('identifierSchemeCodeUnspsc190501.11191702'),
	'identifierSchemeCodeUnspsc190501.11191800': __('identifierSchemeCodeUnspsc190501.11191800'),
	'identifierSchemeCodeUnspsc190501.11191801': __('identifierSchemeCodeUnspsc190501.11191801'),
	'identifierSchemeCodeUnspsc190501.11191802': __('identifierSchemeCodeUnspsc190501.11191802'),
	'identifierSchemeCodeUnspsc190501.11191803': __('identifierSchemeCodeUnspsc190501.11191803'),
	'identifierSchemeCodeUnspsc190501.11191804': __('identifierSchemeCodeUnspsc190501.11191804'),
	'identifierSchemeCodeUnspsc190501.11191805': __('identifierSchemeCodeUnspsc190501.11191805'),
	'identifierSchemeCodeUnspsc190501.12000000': __('identifierSchemeCodeUnspsc190501.12000000'),
	'identifierSchemeCodeUnspsc190501.12130000': __('identifierSchemeCodeUnspsc190501.12130000'),
	'identifierSchemeCodeUnspsc190501.12131500': __('identifierSchemeCodeUnspsc190501.12131500'),
	'identifierSchemeCodeUnspsc190501.12131501': __('identifierSchemeCodeUnspsc190501.12131501'),
	'identifierSchemeCodeUnspsc190501.12131502': __('identifierSchemeCodeUnspsc190501.12131502'),
	'identifierSchemeCodeUnspsc190501.12131503': __('identifierSchemeCodeUnspsc190501.12131503'),
	'identifierSchemeCodeUnspsc190501.12131504': __('identifierSchemeCodeUnspsc190501.12131504'),
	'identifierSchemeCodeUnspsc190501.12131505': __('identifierSchemeCodeUnspsc190501.12131505'),
	'identifierSchemeCodeUnspsc190501.12131506': __('identifierSchemeCodeUnspsc190501.12131506'),
	'identifierSchemeCodeUnspsc190501.12131507': __('identifierSchemeCodeUnspsc190501.12131507'),
	'identifierSchemeCodeUnspsc190501.12131508': __('identifierSchemeCodeUnspsc190501.12131508'),
	'identifierSchemeCodeUnspsc190501.12131509': __('identifierSchemeCodeUnspsc190501.12131509'),
	'identifierSchemeCodeUnspsc190501.12131510': __('identifierSchemeCodeUnspsc190501.12131510'),
	'identifierSchemeCodeUnspsc190501.12131600': __('identifierSchemeCodeUnspsc190501.12131600'),
	'identifierSchemeCodeUnspsc190501.12131601': __('identifierSchemeCodeUnspsc190501.12131601'),
	'identifierSchemeCodeUnspsc190501.12131602': __('identifierSchemeCodeUnspsc190501.12131602'),
	'identifierSchemeCodeUnspsc190501.12131603': __('identifierSchemeCodeUnspsc190501.12131603'),
	'identifierSchemeCodeUnspsc190501.12131604': __('identifierSchemeCodeUnspsc190501.12131604'),
	'identifierSchemeCodeUnspsc190501.12131605': __('identifierSchemeCodeUnspsc190501.12131605'),
	'identifierSchemeCodeUnspsc190501.12131700': __('identifierSchemeCodeUnspsc190501.12131700'),
	'identifierSchemeCodeUnspsc190501.12131701': __('identifierSchemeCodeUnspsc190501.12131701'),
	'identifierSchemeCodeUnspsc190501.12131702': __('identifierSchemeCodeUnspsc190501.12131702'),
	'identifierSchemeCodeUnspsc190501.12131703': __('identifierSchemeCodeUnspsc190501.12131703'),
	'identifierSchemeCodeUnspsc190501.12131704': __('identifierSchemeCodeUnspsc190501.12131704'),
	'identifierSchemeCodeUnspsc190501.12131705': __('identifierSchemeCodeUnspsc190501.12131705'),
	'identifierSchemeCodeUnspsc190501.12131706': __('identifierSchemeCodeUnspsc190501.12131706'),
	'identifierSchemeCodeUnspsc190501.12131707': __('identifierSchemeCodeUnspsc190501.12131707'),
	'identifierSchemeCodeUnspsc190501.12131708': __('identifierSchemeCodeUnspsc190501.12131708'),
	'identifierSchemeCodeUnspsc190501.12131709': __('identifierSchemeCodeUnspsc190501.12131709'),
	'identifierSchemeCodeUnspsc190501.12131800': __('identifierSchemeCodeUnspsc190501.12131800'),
	'identifierSchemeCodeUnspsc190501.12131801': __('identifierSchemeCodeUnspsc190501.12131801'),
	'identifierSchemeCodeUnspsc190501.12131802': __('identifierSchemeCodeUnspsc190501.12131802'),
	'identifierSchemeCodeUnspsc190501.12131803': __('identifierSchemeCodeUnspsc190501.12131803'),
	'identifierSchemeCodeUnspsc190501.12131804': __('identifierSchemeCodeUnspsc190501.12131804'),
	'identifierSchemeCodeUnspsc190501.12131805': __('identifierSchemeCodeUnspsc190501.12131805'),
	'identifierSchemeCodeUnspsc190501.12131806': __('identifierSchemeCodeUnspsc190501.12131806'),
	'identifierSchemeCodeUnspsc190501.12140000': __('identifierSchemeCodeUnspsc190501.12140000'),
	'identifierSchemeCodeUnspsc190501.12141500': __('identifierSchemeCodeUnspsc190501.12141500'),
	'identifierSchemeCodeUnspsc190501.12141501': __('identifierSchemeCodeUnspsc190501.12141501'),
	'identifierSchemeCodeUnspsc190501.12141502': __('identifierSchemeCodeUnspsc190501.12141502'),
	'identifierSchemeCodeUnspsc190501.12141503': __('identifierSchemeCodeUnspsc190501.12141503'),
	'identifierSchemeCodeUnspsc190501.12141504': __('identifierSchemeCodeUnspsc190501.12141504'),
	'identifierSchemeCodeUnspsc190501.12141505': __('identifierSchemeCodeUnspsc190501.12141505'),
	'identifierSchemeCodeUnspsc190501.12141506': __('identifierSchemeCodeUnspsc190501.12141506'),
	'identifierSchemeCodeUnspsc190501.12141600': __('identifierSchemeCodeUnspsc190501.12141600'),
	'identifierSchemeCodeUnspsc190501.12141601': __('identifierSchemeCodeUnspsc190501.12141601'),
	'identifierSchemeCodeUnspsc190501.12141602': __('identifierSchemeCodeUnspsc190501.12141602'),
	'identifierSchemeCodeUnspsc190501.12141603': __('identifierSchemeCodeUnspsc190501.12141603'),
	'identifierSchemeCodeUnspsc190501.12141604': __('identifierSchemeCodeUnspsc190501.12141604'),
	'identifierSchemeCodeUnspsc190501.12141605': __('identifierSchemeCodeUnspsc190501.12141605'),
	'identifierSchemeCodeUnspsc190501.12141606': __('identifierSchemeCodeUnspsc190501.12141606'),
	'identifierSchemeCodeUnspsc190501.12141607': __('identifierSchemeCodeUnspsc190501.12141607'),
	'identifierSchemeCodeUnspsc190501.12141608': __('identifierSchemeCodeUnspsc190501.12141608'),
	'identifierSchemeCodeUnspsc190501.12141609': __('identifierSchemeCodeUnspsc190501.12141609'),
	'identifierSchemeCodeUnspsc190501.12141610': __('identifierSchemeCodeUnspsc190501.12141610'),
	'identifierSchemeCodeUnspsc190501.12141611': __('identifierSchemeCodeUnspsc190501.12141611'),
	'identifierSchemeCodeUnspsc190501.12141612': __('identifierSchemeCodeUnspsc190501.12141612'),
	'identifierSchemeCodeUnspsc190501.12141613': __('identifierSchemeCodeUnspsc190501.12141613'),
	'identifierSchemeCodeUnspsc190501.12141614': __('identifierSchemeCodeUnspsc190501.12141614'),
	'identifierSchemeCodeUnspsc190501.12141615': __('identifierSchemeCodeUnspsc190501.12141615'),
	'identifierSchemeCodeUnspsc190501.12141616': __('identifierSchemeCodeUnspsc190501.12141616'),
	'identifierSchemeCodeUnspsc190501.12141617': __('identifierSchemeCodeUnspsc190501.12141617'),
	'identifierSchemeCodeUnspsc190501.12141700': __('identifierSchemeCodeUnspsc190501.12141700'),
	'identifierSchemeCodeUnspsc190501.12141701': __('identifierSchemeCodeUnspsc190501.12141701'),
	'identifierSchemeCodeUnspsc190501.12141702': __('identifierSchemeCodeUnspsc190501.12141702'),
	'identifierSchemeCodeUnspsc190501.12141703': __('identifierSchemeCodeUnspsc190501.12141703'),
	'identifierSchemeCodeUnspsc190501.12141704': __('identifierSchemeCodeUnspsc190501.12141704'),
	'identifierSchemeCodeUnspsc190501.12141705': __('identifierSchemeCodeUnspsc190501.12141705'),
	'identifierSchemeCodeUnspsc190501.12141706': __('identifierSchemeCodeUnspsc190501.12141706'),
	'identifierSchemeCodeUnspsc190501.12141707': __('identifierSchemeCodeUnspsc190501.12141707'),
	'identifierSchemeCodeUnspsc190501.12141708': __('identifierSchemeCodeUnspsc190501.12141708'),
	'identifierSchemeCodeUnspsc190501.12141709': __('identifierSchemeCodeUnspsc190501.12141709'),
	'identifierSchemeCodeUnspsc190501.12141710': __('identifierSchemeCodeUnspsc190501.12141710'),
	'identifierSchemeCodeUnspsc190501.12141711': __('identifierSchemeCodeUnspsc190501.12141711'),
	'identifierSchemeCodeUnspsc190501.12141712': __('identifierSchemeCodeUnspsc190501.12141712'),
	'identifierSchemeCodeUnspsc190501.12141713': __('identifierSchemeCodeUnspsc190501.12141713'),
	'identifierSchemeCodeUnspsc190501.12141714': __('identifierSchemeCodeUnspsc190501.12141714'),
	'identifierSchemeCodeUnspsc190501.12141715': __('identifierSchemeCodeUnspsc190501.12141715'),
	'identifierSchemeCodeUnspsc190501.12141716': __('identifierSchemeCodeUnspsc190501.12141716'),
	'identifierSchemeCodeUnspsc190501.12141717': __('identifierSchemeCodeUnspsc190501.12141717'),
	'identifierSchemeCodeUnspsc190501.12141718': __('identifierSchemeCodeUnspsc190501.12141718'),
	'identifierSchemeCodeUnspsc190501.12141719': __('identifierSchemeCodeUnspsc190501.12141719'),
	'identifierSchemeCodeUnspsc190501.12141720': __('identifierSchemeCodeUnspsc190501.12141720'),
	'identifierSchemeCodeUnspsc190501.12141721': __('identifierSchemeCodeUnspsc190501.12141721'),
	'identifierSchemeCodeUnspsc190501.12141722': __('identifierSchemeCodeUnspsc190501.12141722'),
	'identifierSchemeCodeUnspsc190501.12141723': __('identifierSchemeCodeUnspsc190501.12141723'),
	'identifierSchemeCodeUnspsc190501.12141724': __('identifierSchemeCodeUnspsc190501.12141724'),
	'identifierSchemeCodeUnspsc190501.12141725': __('identifierSchemeCodeUnspsc190501.12141725'),
	'identifierSchemeCodeUnspsc190501.12141726': __('identifierSchemeCodeUnspsc190501.12141726'),
	'identifierSchemeCodeUnspsc190501.12141727': __('identifierSchemeCodeUnspsc190501.12141727'),
	'identifierSchemeCodeUnspsc190501.12141728': __('identifierSchemeCodeUnspsc190501.12141728'),
	'identifierSchemeCodeUnspsc190501.12141729': __('identifierSchemeCodeUnspsc190501.12141729'),
	'identifierSchemeCodeUnspsc190501.12141730': __('identifierSchemeCodeUnspsc190501.12141730'),
	'identifierSchemeCodeUnspsc190501.12141731': __('identifierSchemeCodeUnspsc190501.12141731'),
	'identifierSchemeCodeUnspsc190501.12141732': __('identifierSchemeCodeUnspsc190501.12141732'),
	'identifierSchemeCodeUnspsc190501.12141733': __('identifierSchemeCodeUnspsc190501.12141733'),
	'identifierSchemeCodeUnspsc190501.12141734': __('identifierSchemeCodeUnspsc190501.12141734'),
	'identifierSchemeCodeUnspsc190501.12141735': __('identifierSchemeCodeUnspsc190501.12141735'),
	'identifierSchemeCodeUnspsc190501.12141736': __('identifierSchemeCodeUnspsc190501.12141736'),
	'identifierSchemeCodeUnspsc190501.12141737': __('identifierSchemeCodeUnspsc190501.12141737'),
	'identifierSchemeCodeUnspsc190501.12141738': __('identifierSchemeCodeUnspsc190501.12141738'),
	'identifierSchemeCodeUnspsc190501.12141739': __('identifierSchemeCodeUnspsc190501.12141739'),
	'identifierSchemeCodeUnspsc190501.12141740': __('identifierSchemeCodeUnspsc190501.12141740'),
	'identifierSchemeCodeUnspsc190501.12141741': __('identifierSchemeCodeUnspsc190501.12141741'),
	'identifierSchemeCodeUnspsc190501.12141742': __('identifierSchemeCodeUnspsc190501.12141742'),
	'identifierSchemeCodeUnspsc190501.12141743': __('identifierSchemeCodeUnspsc190501.12141743'),
	'identifierSchemeCodeUnspsc190501.12141744': __('identifierSchemeCodeUnspsc190501.12141744'),
	'identifierSchemeCodeUnspsc190501.12141745': __('identifierSchemeCodeUnspsc190501.12141745'),
	'identifierSchemeCodeUnspsc190501.12141746': __('identifierSchemeCodeUnspsc190501.12141746'),
	'identifierSchemeCodeUnspsc190501.12141747': __('identifierSchemeCodeUnspsc190501.12141747'),
	'identifierSchemeCodeUnspsc190501.12141748': __('identifierSchemeCodeUnspsc190501.12141748'),
	'identifierSchemeCodeUnspsc190501.12141749': __('identifierSchemeCodeUnspsc190501.12141749'),
	'identifierSchemeCodeUnspsc190501.12141750': __('identifierSchemeCodeUnspsc190501.12141750'),
	'identifierSchemeCodeUnspsc190501.12141751': __('identifierSchemeCodeUnspsc190501.12141751'),
	'identifierSchemeCodeUnspsc190501.12141752': __('identifierSchemeCodeUnspsc190501.12141752'),
	'identifierSchemeCodeUnspsc190501.12141753': __('identifierSchemeCodeUnspsc190501.12141753'),
	'identifierSchemeCodeUnspsc190501.12141754': __('identifierSchemeCodeUnspsc190501.12141754'),
	'identifierSchemeCodeUnspsc190501.12141755': __('identifierSchemeCodeUnspsc190501.12141755'),
	'identifierSchemeCodeUnspsc190501.12141756': __('identifierSchemeCodeUnspsc190501.12141756'),
	'identifierSchemeCodeUnspsc190501.12141757': __('identifierSchemeCodeUnspsc190501.12141757'),
	'identifierSchemeCodeUnspsc190501.12141758': __('identifierSchemeCodeUnspsc190501.12141758'),
	'identifierSchemeCodeUnspsc190501.12141759': __('identifierSchemeCodeUnspsc190501.12141759'),
	'identifierSchemeCodeUnspsc190501.12141760': __('identifierSchemeCodeUnspsc190501.12141760'),
	'identifierSchemeCodeUnspsc190501.12141800': __('identifierSchemeCodeUnspsc190501.12141800'),
	'identifierSchemeCodeUnspsc190501.12141801': __('identifierSchemeCodeUnspsc190501.12141801'),
	'identifierSchemeCodeUnspsc190501.12141802': __('identifierSchemeCodeUnspsc190501.12141802'),
	'identifierSchemeCodeUnspsc190501.12141803': __('identifierSchemeCodeUnspsc190501.12141803'),
	'identifierSchemeCodeUnspsc190501.12141804': __('identifierSchemeCodeUnspsc190501.12141804'),
	'identifierSchemeCodeUnspsc190501.12141805': __('identifierSchemeCodeUnspsc190501.12141805'),
	'identifierSchemeCodeUnspsc190501.12141806': __('identifierSchemeCodeUnspsc190501.12141806'),
	'identifierSchemeCodeUnspsc190501.12141900': __('identifierSchemeCodeUnspsc190501.12141900'),
	'identifierSchemeCodeUnspsc190501.12141901': __('identifierSchemeCodeUnspsc190501.12141901'),
	'identifierSchemeCodeUnspsc190501.12141902': __('identifierSchemeCodeUnspsc190501.12141902'),
	'identifierSchemeCodeUnspsc190501.12141903': __('identifierSchemeCodeUnspsc190501.12141903'),
	'identifierSchemeCodeUnspsc190501.12141904': __('identifierSchemeCodeUnspsc190501.12141904'),
	'identifierSchemeCodeUnspsc190501.12141905': __('identifierSchemeCodeUnspsc190501.12141905'),
	'identifierSchemeCodeUnspsc190501.12141906': __('identifierSchemeCodeUnspsc190501.12141906'),
	'identifierSchemeCodeUnspsc190501.12141907': __('identifierSchemeCodeUnspsc190501.12141907'),
	'identifierSchemeCodeUnspsc190501.12141908': __('identifierSchemeCodeUnspsc190501.12141908'),
	'identifierSchemeCodeUnspsc190501.12141909': __('identifierSchemeCodeUnspsc190501.12141909'),
	'identifierSchemeCodeUnspsc190501.12141910': __('identifierSchemeCodeUnspsc190501.12141910'),
	'identifierSchemeCodeUnspsc190501.12141911': __('identifierSchemeCodeUnspsc190501.12141911'),
	'identifierSchemeCodeUnspsc190501.12141912': __('identifierSchemeCodeUnspsc190501.12141912'),
	'identifierSchemeCodeUnspsc190501.12141913': __('identifierSchemeCodeUnspsc190501.12141913'),
	'identifierSchemeCodeUnspsc190501.12141914': __('identifierSchemeCodeUnspsc190501.12141914'),
	'identifierSchemeCodeUnspsc190501.12141915': __('identifierSchemeCodeUnspsc190501.12141915'),
	'identifierSchemeCodeUnspsc190501.12141916': __('identifierSchemeCodeUnspsc190501.12141916'),
	'identifierSchemeCodeUnspsc190501.12142000': __('identifierSchemeCodeUnspsc190501.12142000'),
	'identifierSchemeCodeUnspsc190501.12142001': __('identifierSchemeCodeUnspsc190501.12142001'),
	'identifierSchemeCodeUnspsc190501.12142002': __('identifierSchemeCodeUnspsc190501.12142002'),
	'identifierSchemeCodeUnspsc190501.12142003': __('identifierSchemeCodeUnspsc190501.12142003'),
	'identifierSchemeCodeUnspsc190501.12142004': __('identifierSchemeCodeUnspsc190501.12142004'),
	'identifierSchemeCodeUnspsc190501.12142005': __('identifierSchemeCodeUnspsc190501.12142005'),
	'identifierSchemeCodeUnspsc190501.12142006': __('identifierSchemeCodeUnspsc190501.12142006'),
	'identifierSchemeCodeUnspsc190501.12142100': __('identifierSchemeCodeUnspsc190501.12142100'),
	'identifierSchemeCodeUnspsc190501.12142101': __('identifierSchemeCodeUnspsc190501.12142101'),
	'identifierSchemeCodeUnspsc190501.12142102': __('identifierSchemeCodeUnspsc190501.12142102'),
	'identifierSchemeCodeUnspsc190501.12142103': __('identifierSchemeCodeUnspsc190501.12142103'),
	'identifierSchemeCodeUnspsc190501.12142104': __('identifierSchemeCodeUnspsc190501.12142104'),
	'identifierSchemeCodeUnspsc190501.12142105': __('identifierSchemeCodeUnspsc190501.12142105'),
	'identifierSchemeCodeUnspsc190501.12142106': __('identifierSchemeCodeUnspsc190501.12142106'),
	'identifierSchemeCodeUnspsc190501.12142107': __('identifierSchemeCodeUnspsc190501.12142107'),
	'identifierSchemeCodeUnspsc190501.12142108': __('identifierSchemeCodeUnspsc190501.12142108'),
	'identifierSchemeCodeUnspsc190501.12142109': __('identifierSchemeCodeUnspsc190501.12142109'),
	'identifierSchemeCodeUnspsc190501.12142110': __('identifierSchemeCodeUnspsc190501.12142110'),
	'identifierSchemeCodeUnspsc190501.12142200': __('identifierSchemeCodeUnspsc190501.12142200'),
	'identifierSchemeCodeUnspsc190501.12142201': __('identifierSchemeCodeUnspsc190501.12142201'),
	'identifierSchemeCodeUnspsc190501.12142202': __('identifierSchemeCodeUnspsc190501.12142202'),
	'identifierSchemeCodeUnspsc190501.12142203': __('identifierSchemeCodeUnspsc190501.12142203'),
	'identifierSchemeCodeUnspsc190501.12142204': __('identifierSchemeCodeUnspsc190501.12142204'),
	'identifierSchemeCodeUnspsc190501.12142205': __('identifierSchemeCodeUnspsc190501.12142205'),
	'identifierSchemeCodeUnspsc190501.12142206': __('identifierSchemeCodeUnspsc190501.12142206'),
	'identifierSchemeCodeUnspsc190501.12142207': __('identifierSchemeCodeUnspsc190501.12142207'),
	'identifierSchemeCodeUnspsc190501.12142208': __('identifierSchemeCodeUnspsc190501.12142208'),
	'identifierSchemeCodeUnspsc190501.12160000': __('identifierSchemeCodeUnspsc190501.12160000'),
	'identifierSchemeCodeUnspsc190501.12161500': __('identifierSchemeCodeUnspsc190501.12161500'),
	'identifierSchemeCodeUnspsc190501.12161501': __('identifierSchemeCodeUnspsc190501.12161501'),
	'identifierSchemeCodeUnspsc190501.12161502': __('identifierSchemeCodeUnspsc190501.12161502'),
	'identifierSchemeCodeUnspsc190501.12161503': __('identifierSchemeCodeUnspsc190501.12161503'),
	'identifierSchemeCodeUnspsc190501.12161504': __('identifierSchemeCodeUnspsc190501.12161504'),
	'identifierSchemeCodeUnspsc190501.12161505': __('identifierSchemeCodeUnspsc190501.12161505'),
	'identifierSchemeCodeUnspsc190501.12161506': __('identifierSchemeCodeUnspsc190501.12161506'),
	'identifierSchemeCodeUnspsc190501.12161507': __('identifierSchemeCodeUnspsc190501.12161507'),
	'identifierSchemeCodeUnspsc190501.12161600': __('identifierSchemeCodeUnspsc190501.12161600'),
	'identifierSchemeCodeUnspsc190501.12161601': __('identifierSchemeCodeUnspsc190501.12161601'),
	'identifierSchemeCodeUnspsc190501.12161602': __('identifierSchemeCodeUnspsc190501.12161602'),
	'identifierSchemeCodeUnspsc190501.12161603': __('identifierSchemeCodeUnspsc190501.12161603'),
	'identifierSchemeCodeUnspsc190501.12161604': __('identifierSchemeCodeUnspsc190501.12161604'),
	'identifierSchemeCodeUnspsc190501.12161605': __('identifierSchemeCodeUnspsc190501.12161605'),
	'identifierSchemeCodeUnspsc190501.12161606': __('identifierSchemeCodeUnspsc190501.12161606'),
	'identifierSchemeCodeUnspsc190501.12161700': __('identifierSchemeCodeUnspsc190501.12161700'),
	'identifierSchemeCodeUnspsc190501.12161701': __('identifierSchemeCodeUnspsc190501.12161701'),
	'identifierSchemeCodeUnspsc190501.12161702': __('identifierSchemeCodeUnspsc190501.12161702'),
	'identifierSchemeCodeUnspsc190501.12161703': __('identifierSchemeCodeUnspsc190501.12161703'),
	'identifierSchemeCodeUnspsc190501.12161704': __('identifierSchemeCodeUnspsc190501.12161704'),
	'identifierSchemeCodeUnspsc190501.12161705': __('identifierSchemeCodeUnspsc190501.12161705'),
	'identifierSchemeCodeUnspsc190501.12161706': __('identifierSchemeCodeUnspsc190501.12161706'),
	'identifierSchemeCodeUnspsc190501.12161800': __('identifierSchemeCodeUnspsc190501.12161800'),
	'identifierSchemeCodeUnspsc190501.12161801': __('identifierSchemeCodeUnspsc190501.12161801'),
	'identifierSchemeCodeUnspsc190501.12161802': __('identifierSchemeCodeUnspsc190501.12161802'),
	'identifierSchemeCodeUnspsc190501.12161803': __('identifierSchemeCodeUnspsc190501.12161803'),
	'identifierSchemeCodeUnspsc190501.12161804': __('identifierSchemeCodeUnspsc190501.12161804'),
	'identifierSchemeCodeUnspsc190501.12161805': __('identifierSchemeCodeUnspsc190501.12161805'),
	'identifierSchemeCodeUnspsc190501.12161806': __('identifierSchemeCodeUnspsc190501.12161806'),
	'identifierSchemeCodeUnspsc190501.12161807': __('identifierSchemeCodeUnspsc190501.12161807'),
	'identifierSchemeCodeUnspsc190501.12161808': __('identifierSchemeCodeUnspsc190501.12161808'),
	'identifierSchemeCodeUnspsc190501.12161809': __('identifierSchemeCodeUnspsc190501.12161809'),
	'identifierSchemeCodeUnspsc190501.12161900': __('identifierSchemeCodeUnspsc190501.12161900'),
	'identifierSchemeCodeUnspsc190501.12161901': __('identifierSchemeCodeUnspsc190501.12161901'),
	'identifierSchemeCodeUnspsc190501.12161902': __('identifierSchemeCodeUnspsc190501.12161902'),
	'identifierSchemeCodeUnspsc190501.12161903': __('identifierSchemeCodeUnspsc190501.12161903'),
	'identifierSchemeCodeUnspsc190501.12161904': __('identifierSchemeCodeUnspsc190501.12161904'),
	'identifierSchemeCodeUnspsc190501.12161905': __('identifierSchemeCodeUnspsc190501.12161905'),
	'identifierSchemeCodeUnspsc190501.12161906': __('identifierSchemeCodeUnspsc190501.12161906'),
	'identifierSchemeCodeUnspsc190501.12161907': __('identifierSchemeCodeUnspsc190501.12161907'),
	'identifierSchemeCodeUnspsc190501.12161908': __('identifierSchemeCodeUnspsc190501.12161908'),
	'identifierSchemeCodeUnspsc190501.12161909': __('identifierSchemeCodeUnspsc190501.12161909'),
	'identifierSchemeCodeUnspsc190501.12161910': __('identifierSchemeCodeUnspsc190501.12161910'),
	'identifierSchemeCodeUnspsc190501.12161911': __('identifierSchemeCodeUnspsc190501.12161911'),
	'identifierSchemeCodeUnspsc190501.12161912': __('identifierSchemeCodeUnspsc190501.12161912'),
	'identifierSchemeCodeUnspsc190501.12161913': __('identifierSchemeCodeUnspsc190501.12161913'),
	'identifierSchemeCodeUnspsc190501.12162000': __('identifierSchemeCodeUnspsc190501.12162000'),
	'identifierSchemeCodeUnspsc190501.12162002': __('identifierSchemeCodeUnspsc190501.12162002'),
	'identifierSchemeCodeUnspsc190501.12162003': __('identifierSchemeCodeUnspsc190501.12162003'),
	'identifierSchemeCodeUnspsc190501.12162004': __('identifierSchemeCodeUnspsc190501.12162004'),
	'identifierSchemeCodeUnspsc190501.12162005': __('identifierSchemeCodeUnspsc190501.12162005'),
	'identifierSchemeCodeUnspsc190501.12162006': __('identifierSchemeCodeUnspsc190501.12162006'),
	'identifierSchemeCodeUnspsc190501.12162007': __('identifierSchemeCodeUnspsc190501.12162007'),
	'identifierSchemeCodeUnspsc190501.12162008': __('identifierSchemeCodeUnspsc190501.12162008'),
	'identifierSchemeCodeUnspsc190501.12162100': __('identifierSchemeCodeUnspsc190501.12162100'),
	'identifierSchemeCodeUnspsc190501.12162101': __('identifierSchemeCodeUnspsc190501.12162101'),
	'identifierSchemeCodeUnspsc190501.12162200': __('identifierSchemeCodeUnspsc190501.12162200'),
	'identifierSchemeCodeUnspsc190501.12162201': __('identifierSchemeCodeUnspsc190501.12162201'),
	'identifierSchemeCodeUnspsc190501.12162202': __('identifierSchemeCodeUnspsc190501.12162202'),
	'identifierSchemeCodeUnspsc190501.12162203': __('identifierSchemeCodeUnspsc190501.12162203'),
	'identifierSchemeCodeUnspsc190501.12162204': __('identifierSchemeCodeUnspsc190501.12162204'),
	'identifierSchemeCodeUnspsc190501.12162205': __('identifierSchemeCodeUnspsc190501.12162205'),
	'identifierSchemeCodeUnspsc190501.12162206': __('identifierSchemeCodeUnspsc190501.12162206'),
	'identifierSchemeCodeUnspsc190501.12162207': __('identifierSchemeCodeUnspsc190501.12162207'),
	'identifierSchemeCodeUnspsc190501.12162208': __('identifierSchemeCodeUnspsc190501.12162208'),
	'identifierSchemeCodeUnspsc190501.12162209': __('identifierSchemeCodeUnspsc190501.12162209'),
	'identifierSchemeCodeUnspsc190501.12162210': __('identifierSchemeCodeUnspsc190501.12162210'),
	'identifierSchemeCodeUnspsc190501.12162211': __('identifierSchemeCodeUnspsc190501.12162211'),
	'identifierSchemeCodeUnspsc190501.12162212': __('identifierSchemeCodeUnspsc190501.12162212'),
	'identifierSchemeCodeUnspsc190501.12162300': __('identifierSchemeCodeUnspsc190501.12162300'),
	'identifierSchemeCodeUnspsc190501.12162301': __('identifierSchemeCodeUnspsc190501.12162301'),
	'identifierSchemeCodeUnspsc190501.12162302': __('identifierSchemeCodeUnspsc190501.12162302'),
	'identifierSchemeCodeUnspsc190501.12162303': __('identifierSchemeCodeUnspsc190501.12162303'),
	'identifierSchemeCodeUnspsc190501.12162304': __('identifierSchemeCodeUnspsc190501.12162304'),
	'identifierSchemeCodeUnspsc190501.12162305': __('identifierSchemeCodeUnspsc190501.12162305'),
	'identifierSchemeCodeUnspsc190501.12162400': __('identifierSchemeCodeUnspsc190501.12162400'),
	'identifierSchemeCodeUnspsc190501.12162401': __('identifierSchemeCodeUnspsc190501.12162401'),
	'identifierSchemeCodeUnspsc190501.12162402': __('identifierSchemeCodeUnspsc190501.12162402'),
	'identifierSchemeCodeUnspsc190501.12162500': __('identifierSchemeCodeUnspsc190501.12162500'),
	'identifierSchemeCodeUnspsc190501.12162501': __('identifierSchemeCodeUnspsc190501.12162501'),
	'identifierSchemeCodeUnspsc190501.12162502': __('identifierSchemeCodeUnspsc190501.12162502'),
	'identifierSchemeCodeUnspsc190501.12162503': __('identifierSchemeCodeUnspsc190501.12162503'),
	'identifierSchemeCodeUnspsc190501.12162600': __('identifierSchemeCodeUnspsc190501.12162600'),
	'identifierSchemeCodeUnspsc190501.12162601': __('identifierSchemeCodeUnspsc190501.12162601'),
	'identifierSchemeCodeUnspsc190501.12162602': __('identifierSchemeCodeUnspsc190501.12162602'),
	'identifierSchemeCodeUnspsc190501.12162700': __('identifierSchemeCodeUnspsc190501.12162700'),
	'identifierSchemeCodeUnspsc190501.12162701': __('identifierSchemeCodeUnspsc190501.12162701'),
	'identifierSchemeCodeUnspsc190501.12162702': __('identifierSchemeCodeUnspsc190501.12162702'),
	'identifierSchemeCodeUnspsc190501.12162800': __('identifierSchemeCodeUnspsc190501.12162800'),
	'identifierSchemeCodeUnspsc190501.12162801': __('identifierSchemeCodeUnspsc190501.12162801'),
	'identifierSchemeCodeUnspsc190501.12162802': __('identifierSchemeCodeUnspsc190501.12162802'),
	'identifierSchemeCodeUnspsc190501.12162900': __('identifierSchemeCodeUnspsc190501.12162900'),
	'identifierSchemeCodeUnspsc190501.12162901': __('identifierSchemeCodeUnspsc190501.12162901'),
	'identifierSchemeCodeUnspsc190501.12162902': __('identifierSchemeCodeUnspsc190501.12162902'),
	'identifierSchemeCodeUnspsc190501.12162903': __('identifierSchemeCodeUnspsc190501.12162903'),
	'identifierSchemeCodeUnspsc190501.12163000': __('identifierSchemeCodeUnspsc190501.12163000'),
	'identifierSchemeCodeUnspsc190501.12163001': __('identifierSchemeCodeUnspsc190501.12163001'),
	'identifierSchemeCodeUnspsc190501.12163100': __('identifierSchemeCodeUnspsc190501.12163100'),
	'identifierSchemeCodeUnspsc190501.12163101': __('identifierSchemeCodeUnspsc190501.12163101'),
	'identifierSchemeCodeUnspsc190501.12163102': __('identifierSchemeCodeUnspsc190501.12163102'),
	'identifierSchemeCodeUnspsc190501.12163200': __('identifierSchemeCodeUnspsc190501.12163200'),
	'identifierSchemeCodeUnspsc190501.12163201': __('identifierSchemeCodeUnspsc190501.12163201'),
	'identifierSchemeCodeUnspsc190501.12163300': __('identifierSchemeCodeUnspsc190501.12163300'),
	'identifierSchemeCodeUnspsc190501.12163301': __('identifierSchemeCodeUnspsc190501.12163301'),
	'identifierSchemeCodeUnspsc190501.12163400': __('identifierSchemeCodeUnspsc190501.12163400'),
	'identifierSchemeCodeUnspsc190501.12163401': __('identifierSchemeCodeUnspsc190501.12163401'),
	'identifierSchemeCodeUnspsc190501.12163500': __('identifierSchemeCodeUnspsc190501.12163500'),
	'identifierSchemeCodeUnspsc190501.12163501': __('identifierSchemeCodeUnspsc190501.12163501'),
	'identifierSchemeCodeUnspsc190501.12163600': __('identifierSchemeCodeUnspsc190501.12163600'),
	'identifierSchemeCodeUnspsc190501.12163601': __('identifierSchemeCodeUnspsc190501.12163601'),
	'identifierSchemeCodeUnspsc190501.12163602': __('identifierSchemeCodeUnspsc190501.12163602'),
	'identifierSchemeCodeUnspsc190501.12163700': __('identifierSchemeCodeUnspsc190501.12163700'),
	'identifierSchemeCodeUnspsc190501.12163701': __('identifierSchemeCodeUnspsc190501.12163701'),
	'identifierSchemeCodeUnspsc190501.12163800': __('identifierSchemeCodeUnspsc190501.12163800'),
	'identifierSchemeCodeUnspsc190501.12163801': __('identifierSchemeCodeUnspsc190501.12163801'),
	'identifierSchemeCodeUnspsc190501.12163802': __('identifierSchemeCodeUnspsc190501.12163802'),
	'identifierSchemeCodeUnspsc190501.12163900': __('identifierSchemeCodeUnspsc190501.12163900'),
	'identifierSchemeCodeUnspsc190501.12163901': __('identifierSchemeCodeUnspsc190501.12163901'),
	'identifierSchemeCodeUnspsc190501.12163902': __('identifierSchemeCodeUnspsc190501.12163902'),
	'identifierSchemeCodeUnspsc190501.12164000': __('identifierSchemeCodeUnspsc190501.12164000'),
	'identifierSchemeCodeUnspsc190501.12164001': __('identifierSchemeCodeUnspsc190501.12164001'),
	'identifierSchemeCodeUnspsc190501.12164100': __('identifierSchemeCodeUnspsc190501.12164100'),
	'identifierSchemeCodeUnspsc190501.12164101': __('identifierSchemeCodeUnspsc190501.12164101'),
	'identifierSchemeCodeUnspsc190501.12164102': __('identifierSchemeCodeUnspsc190501.12164102'),
	'identifierSchemeCodeUnspsc190501.12164200': __('identifierSchemeCodeUnspsc190501.12164200'),
	'identifierSchemeCodeUnspsc190501.12164201': __('identifierSchemeCodeUnspsc190501.12164201'),
	'identifierSchemeCodeUnspsc190501.12164300': __('identifierSchemeCodeUnspsc190501.12164300'),
	'identifierSchemeCodeUnspsc190501.12164301': __('identifierSchemeCodeUnspsc190501.12164301'),
	'identifierSchemeCodeUnspsc190501.12164302': __('identifierSchemeCodeUnspsc190501.12164302'),
	'identifierSchemeCodeUnspsc190501.12164303': __('identifierSchemeCodeUnspsc190501.12164303'),
	'identifierSchemeCodeUnspsc190501.12164400': __('identifierSchemeCodeUnspsc190501.12164400'),
	'identifierSchemeCodeUnspsc190501.12164401': __('identifierSchemeCodeUnspsc190501.12164401'),
	'identifierSchemeCodeUnspsc190501.12164500': __('identifierSchemeCodeUnspsc190501.12164500'),
	'identifierSchemeCodeUnspsc190501.12164501': __('identifierSchemeCodeUnspsc190501.12164501'),
	'identifierSchemeCodeUnspsc190501.12164502': __('identifierSchemeCodeUnspsc190501.12164502'),
	'identifierSchemeCodeUnspsc190501.12164503': __('identifierSchemeCodeUnspsc190501.12164503'),
	'identifierSchemeCodeUnspsc190501.12164504': __('identifierSchemeCodeUnspsc190501.12164504'),
	'identifierSchemeCodeUnspsc190501.12164505': __('identifierSchemeCodeUnspsc190501.12164505'),
	'identifierSchemeCodeUnspsc190501.12164506': __('identifierSchemeCodeUnspsc190501.12164506'),
	'identifierSchemeCodeUnspsc190501.12164507': __('identifierSchemeCodeUnspsc190501.12164507'),
	'identifierSchemeCodeUnspsc190501.12164508': __('identifierSchemeCodeUnspsc190501.12164508'),
	'identifierSchemeCodeUnspsc190501.12164509': __('identifierSchemeCodeUnspsc190501.12164509'),
	'identifierSchemeCodeUnspsc190501.12164600': __('identifierSchemeCodeUnspsc190501.12164600'),
	'identifierSchemeCodeUnspsc190501.12164601': __('identifierSchemeCodeUnspsc190501.12164601'),
	'identifierSchemeCodeUnspsc190501.12164602': __('identifierSchemeCodeUnspsc190501.12164602'),
	'identifierSchemeCodeUnspsc190501.12164603': __('identifierSchemeCodeUnspsc190501.12164603'),
	'identifierSchemeCodeUnspsc190501.12164604': __('identifierSchemeCodeUnspsc190501.12164604'),
	'identifierSchemeCodeUnspsc190501.12164605': __('identifierSchemeCodeUnspsc190501.12164605'),
	'identifierSchemeCodeUnspsc190501.12164606': __('identifierSchemeCodeUnspsc190501.12164606'),
	'identifierSchemeCodeUnspsc190501.12164700': __('identifierSchemeCodeUnspsc190501.12164700'),
	'identifierSchemeCodeUnspsc190501.12164701': __('identifierSchemeCodeUnspsc190501.12164701'),
	'identifierSchemeCodeUnspsc190501.12164800': __('identifierSchemeCodeUnspsc190501.12164800'),
	'identifierSchemeCodeUnspsc190501.12164801': __('identifierSchemeCodeUnspsc190501.12164801'),
	'identifierSchemeCodeUnspsc190501.12164802': __('identifierSchemeCodeUnspsc190501.12164802'),
	'identifierSchemeCodeUnspsc190501.12164803': __('identifierSchemeCodeUnspsc190501.12164803'),
	'identifierSchemeCodeUnspsc190501.12164900': __('identifierSchemeCodeUnspsc190501.12164900'),
	'identifierSchemeCodeUnspsc190501.12164901': __('identifierSchemeCodeUnspsc190501.12164901'),
	'identifierSchemeCodeUnspsc190501.12164902': __('identifierSchemeCodeUnspsc190501.12164902'),
	'identifierSchemeCodeUnspsc190501.12164903': __('identifierSchemeCodeUnspsc190501.12164903'),
	'identifierSchemeCodeUnspsc190501.12164904': __('identifierSchemeCodeUnspsc190501.12164904'),
	'identifierSchemeCodeUnspsc190501.12164905': __('identifierSchemeCodeUnspsc190501.12164905'),
	'identifierSchemeCodeUnspsc190501.12164906': __('identifierSchemeCodeUnspsc190501.12164906'),
	'identifierSchemeCodeUnspsc190501.12165000': __('identifierSchemeCodeUnspsc190501.12165000'),
	'identifierSchemeCodeUnspsc190501.12165001': __('identifierSchemeCodeUnspsc190501.12165001'),
	'identifierSchemeCodeUnspsc190501.12165100': __('identifierSchemeCodeUnspsc190501.12165100'),
	'identifierSchemeCodeUnspsc190501.12165101': __('identifierSchemeCodeUnspsc190501.12165101'),
	'identifierSchemeCodeUnspsc190501.12165102': __('identifierSchemeCodeUnspsc190501.12165102'),
	'identifierSchemeCodeUnspsc190501.12165103': __('identifierSchemeCodeUnspsc190501.12165103'),
	'identifierSchemeCodeUnspsc190501.12165104': __('identifierSchemeCodeUnspsc190501.12165104'),
	'identifierSchemeCodeUnspsc190501.12170000': __('identifierSchemeCodeUnspsc190501.12170000'),
	'identifierSchemeCodeUnspsc190501.12171500': __('identifierSchemeCodeUnspsc190501.12171500'),
	'identifierSchemeCodeUnspsc190501.12171501': __('identifierSchemeCodeUnspsc190501.12171501'),
	'identifierSchemeCodeUnspsc190501.12171502': __('identifierSchemeCodeUnspsc190501.12171502'),
	'identifierSchemeCodeUnspsc190501.12171503': __('identifierSchemeCodeUnspsc190501.12171503'),
	'identifierSchemeCodeUnspsc190501.12171504': __('identifierSchemeCodeUnspsc190501.12171504'),
	'identifierSchemeCodeUnspsc190501.12171505': __('identifierSchemeCodeUnspsc190501.12171505'),
	'identifierSchemeCodeUnspsc190501.12171506': __('identifierSchemeCodeUnspsc190501.12171506'),
	'identifierSchemeCodeUnspsc190501.12171507': __('identifierSchemeCodeUnspsc190501.12171507'),
	'identifierSchemeCodeUnspsc190501.12171508': __('identifierSchemeCodeUnspsc190501.12171508'),
	'identifierSchemeCodeUnspsc190501.12171509': __('identifierSchemeCodeUnspsc190501.12171509'),
	'identifierSchemeCodeUnspsc190501.12171510': __('identifierSchemeCodeUnspsc190501.12171510'),
	'identifierSchemeCodeUnspsc190501.12171511': __('identifierSchemeCodeUnspsc190501.12171511'),
	'identifierSchemeCodeUnspsc190501.12171600': __('identifierSchemeCodeUnspsc190501.12171600'),
	'identifierSchemeCodeUnspsc190501.12171602': __('identifierSchemeCodeUnspsc190501.12171602'),
	'identifierSchemeCodeUnspsc190501.12171603': __('identifierSchemeCodeUnspsc190501.12171603'),
	'identifierSchemeCodeUnspsc190501.12171604': __('identifierSchemeCodeUnspsc190501.12171604'),
	'identifierSchemeCodeUnspsc190501.12171605': __('identifierSchemeCodeUnspsc190501.12171605'),
	'identifierSchemeCodeUnspsc190501.12171606': __('identifierSchemeCodeUnspsc190501.12171606'),
	'identifierSchemeCodeUnspsc190501.12171607': __('identifierSchemeCodeUnspsc190501.12171607'),
	'identifierSchemeCodeUnspsc190501.12171608': __('identifierSchemeCodeUnspsc190501.12171608'),
	'identifierSchemeCodeUnspsc190501.12171609': __('identifierSchemeCodeUnspsc190501.12171609'),
	'identifierSchemeCodeUnspsc190501.12171610': __('identifierSchemeCodeUnspsc190501.12171610'),
	'identifierSchemeCodeUnspsc190501.12171611': __('identifierSchemeCodeUnspsc190501.12171611'),
	'identifierSchemeCodeUnspsc190501.12171612': __('identifierSchemeCodeUnspsc190501.12171612'),
	'identifierSchemeCodeUnspsc190501.12171613': __('identifierSchemeCodeUnspsc190501.12171613'),
	'identifierSchemeCodeUnspsc190501.12171614': __('identifierSchemeCodeUnspsc190501.12171614'),
	'identifierSchemeCodeUnspsc190501.12171615': __('identifierSchemeCodeUnspsc190501.12171615'),
	'identifierSchemeCodeUnspsc190501.12171616': __('identifierSchemeCodeUnspsc190501.12171616'),
	'identifierSchemeCodeUnspsc190501.12171617': __('identifierSchemeCodeUnspsc190501.12171617'),
	'identifierSchemeCodeUnspsc190501.12171618': __('identifierSchemeCodeUnspsc190501.12171618'),
	'identifierSchemeCodeUnspsc190501.12171619': __('identifierSchemeCodeUnspsc190501.12171619'),
	'identifierSchemeCodeUnspsc190501.12171620': __('identifierSchemeCodeUnspsc190501.12171620'),
	'identifierSchemeCodeUnspsc190501.12171621': __('identifierSchemeCodeUnspsc190501.12171621'),
	'identifierSchemeCodeUnspsc190501.12171700': __('identifierSchemeCodeUnspsc190501.12171700'),
	'identifierSchemeCodeUnspsc190501.12171701': __('identifierSchemeCodeUnspsc190501.12171701'),
	'identifierSchemeCodeUnspsc190501.12171702': __('identifierSchemeCodeUnspsc190501.12171702'),
	'identifierSchemeCodeUnspsc190501.12171703': __('identifierSchemeCodeUnspsc190501.12171703'),
	'identifierSchemeCodeUnspsc190501.12180000': __('identifierSchemeCodeUnspsc190501.12180000'),
	'identifierSchemeCodeUnspsc190501.12181500': __('identifierSchemeCodeUnspsc190501.12181500'),
	'identifierSchemeCodeUnspsc190501.12181501': __('identifierSchemeCodeUnspsc190501.12181501'),
	'identifierSchemeCodeUnspsc190501.12181502': __('identifierSchemeCodeUnspsc190501.12181502'),
	'identifierSchemeCodeUnspsc190501.12181503': __('identifierSchemeCodeUnspsc190501.12181503'),
	'identifierSchemeCodeUnspsc190501.12181504': __('identifierSchemeCodeUnspsc190501.12181504'),
	'identifierSchemeCodeUnspsc190501.12181600': __('identifierSchemeCodeUnspsc190501.12181600'),
	'identifierSchemeCodeUnspsc190501.12181601': __('identifierSchemeCodeUnspsc190501.12181601'),
	'identifierSchemeCodeUnspsc190501.12181602': __('identifierSchemeCodeUnspsc190501.12181602'),
	'identifierSchemeCodeUnspsc190501.12190000': __('identifierSchemeCodeUnspsc190501.12190000'),
	'identifierSchemeCodeUnspsc190501.12191500': __('identifierSchemeCodeUnspsc190501.12191500'),
	'identifierSchemeCodeUnspsc190501.12191501': __('identifierSchemeCodeUnspsc190501.12191501'),
	'identifierSchemeCodeUnspsc190501.12191502': __('identifierSchemeCodeUnspsc190501.12191502'),
	'identifierSchemeCodeUnspsc190501.12191503': __('identifierSchemeCodeUnspsc190501.12191503'),
	'identifierSchemeCodeUnspsc190501.12191504': __('identifierSchemeCodeUnspsc190501.12191504'),
	'identifierSchemeCodeUnspsc190501.12191600': __('identifierSchemeCodeUnspsc190501.12191600'),
	'identifierSchemeCodeUnspsc190501.12191601': __('identifierSchemeCodeUnspsc190501.12191601'),
	'identifierSchemeCodeUnspsc190501.12191602': __('identifierSchemeCodeUnspsc190501.12191602'),
	'identifierSchemeCodeUnspsc190501.12350000': __('identifierSchemeCodeUnspsc190501.12350000'),
	'identifierSchemeCodeUnspsc190501.12352000': __('identifierSchemeCodeUnspsc190501.12352000'),
	'identifierSchemeCodeUnspsc190501.12352001': __('identifierSchemeCodeUnspsc190501.12352001'),
	'identifierSchemeCodeUnspsc190501.12352002': __('identifierSchemeCodeUnspsc190501.12352002'),
	'identifierSchemeCodeUnspsc190501.12352003': __('identifierSchemeCodeUnspsc190501.12352003'),
	'identifierSchemeCodeUnspsc190501.12352005': __('identifierSchemeCodeUnspsc190501.12352005'),
	'identifierSchemeCodeUnspsc190501.12352006': __('identifierSchemeCodeUnspsc190501.12352006'),
	'identifierSchemeCodeUnspsc190501.12352100': __('identifierSchemeCodeUnspsc190501.12352100'),
	'identifierSchemeCodeUnspsc190501.12352101': __('identifierSchemeCodeUnspsc190501.12352101'),
	'identifierSchemeCodeUnspsc190501.12352102': __('identifierSchemeCodeUnspsc190501.12352102'),
	'identifierSchemeCodeUnspsc190501.12352103': __('identifierSchemeCodeUnspsc190501.12352103'),
	'identifierSchemeCodeUnspsc190501.12352104': __('identifierSchemeCodeUnspsc190501.12352104'),
	'identifierSchemeCodeUnspsc190501.12352105': __('identifierSchemeCodeUnspsc190501.12352105'),
	'identifierSchemeCodeUnspsc190501.12352106': __('identifierSchemeCodeUnspsc190501.12352106'),
	'identifierSchemeCodeUnspsc190501.12352107': __('identifierSchemeCodeUnspsc190501.12352107'),
	'identifierSchemeCodeUnspsc190501.12352108': __('identifierSchemeCodeUnspsc190501.12352108'),
	'identifierSchemeCodeUnspsc190501.12352111': __('identifierSchemeCodeUnspsc190501.12352111'),
	'identifierSchemeCodeUnspsc190501.12352112': __('identifierSchemeCodeUnspsc190501.12352112'),
	'identifierSchemeCodeUnspsc190501.12352113': __('identifierSchemeCodeUnspsc190501.12352113'),
	'identifierSchemeCodeUnspsc190501.12352114': __('identifierSchemeCodeUnspsc190501.12352114'),
	'identifierSchemeCodeUnspsc190501.12352115': __('identifierSchemeCodeUnspsc190501.12352115'),
	'identifierSchemeCodeUnspsc190501.12352116': __('identifierSchemeCodeUnspsc190501.12352116'),
	'identifierSchemeCodeUnspsc190501.12352117': __('identifierSchemeCodeUnspsc190501.12352117'),
	'identifierSchemeCodeUnspsc190501.12352118': __('identifierSchemeCodeUnspsc190501.12352118'),
	'identifierSchemeCodeUnspsc190501.12352119': __('identifierSchemeCodeUnspsc190501.12352119'),
	'identifierSchemeCodeUnspsc190501.12352120': __('identifierSchemeCodeUnspsc190501.12352120'),
	'identifierSchemeCodeUnspsc190501.12352121': __('identifierSchemeCodeUnspsc190501.12352121'),
	'identifierSchemeCodeUnspsc190501.12352123': __('identifierSchemeCodeUnspsc190501.12352123'),
	'identifierSchemeCodeUnspsc190501.12352124': __('identifierSchemeCodeUnspsc190501.12352124'),
	'identifierSchemeCodeUnspsc190501.12352125': __('identifierSchemeCodeUnspsc190501.12352125'),
	'identifierSchemeCodeUnspsc190501.12352126': __('identifierSchemeCodeUnspsc190501.12352126'),
	'identifierSchemeCodeUnspsc190501.12352127': __('identifierSchemeCodeUnspsc190501.12352127'),
	'identifierSchemeCodeUnspsc190501.12352128': __('identifierSchemeCodeUnspsc190501.12352128'),
	'identifierSchemeCodeUnspsc190501.12352129': __('identifierSchemeCodeUnspsc190501.12352129'),
	'identifierSchemeCodeUnspsc190501.12352130': __('identifierSchemeCodeUnspsc190501.12352130'),
	'identifierSchemeCodeUnspsc190501.12352131': __('identifierSchemeCodeUnspsc190501.12352131'),
	'identifierSchemeCodeUnspsc190501.12352132': __('identifierSchemeCodeUnspsc190501.12352132'),
	'identifierSchemeCodeUnspsc190501.12352133': __('identifierSchemeCodeUnspsc190501.12352133'),
	'identifierSchemeCodeUnspsc190501.12352135': __('identifierSchemeCodeUnspsc190501.12352135'),
	'identifierSchemeCodeUnspsc190501.12352136': __('identifierSchemeCodeUnspsc190501.12352136'),
	'identifierSchemeCodeUnspsc190501.12352137': __('identifierSchemeCodeUnspsc190501.12352137'),
	'identifierSchemeCodeUnspsc190501.12352138': __('identifierSchemeCodeUnspsc190501.12352138'),
	'identifierSchemeCodeUnspsc190501.12352200': __('identifierSchemeCodeUnspsc190501.12352200'),
	'identifierSchemeCodeUnspsc190501.12352201': __('identifierSchemeCodeUnspsc190501.12352201'),
	'identifierSchemeCodeUnspsc190501.12352202': __('identifierSchemeCodeUnspsc190501.12352202'),
	'identifierSchemeCodeUnspsc190501.12352203': __('identifierSchemeCodeUnspsc190501.12352203'),
	'identifierSchemeCodeUnspsc190501.12352204': __('identifierSchemeCodeUnspsc190501.12352204'),
	'identifierSchemeCodeUnspsc190501.12352205': __('identifierSchemeCodeUnspsc190501.12352205'),
	'identifierSchemeCodeUnspsc190501.12352206': __('identifierSchemeCodeUnspsc190501.12352206'),
	'identifierSchemeCodeUnspsc190501.12352207': __('identifierSchemeCodeUnspsc190501.12352207'),
	'identifierSchemeCodeUnspsc190501.12352208': __('identifierSchemeCodeUnspsc190501.12352208'),
	'identifierSchemeCodeUnspsc190501.12352209': __('identifierSchemeCodeUnspsc190501.12352209'),
	'identifierSchemeCodeUnspsc190501.12352210': __('identifierSchemeCodeUnspsc190501.12352210'),
	'identifierSchemeCodeUnspsc190501.12352211': __('identifierSchemeCodeUnspsc190501.12352211'),
	'identifierSchemeCodeUnspsc190501.12352212': __('identifierSchemeCodeUnspsc190501.12352212'),
	'identifierSchemeCodeUnspsc190501.12352300': __('identifierSchemeCodeUnspsc190501.12352300'),
	'identifierSchemeCodeUnspsc190501.12352301': __('identifierSchemeCodeUnspsc190501.12352301'),
	'identifierSchemeCodeUnspsc190501.12352302': __('identifierSchemeCodeUnspsc190501.12352302'),
	'identifierSchemeCodeUnspsc190501.12352303': __('identifierSchemeCodeUnspsc190501.12352303'),
	'identifierSchemeCodeUnspsc190501.12352304': __('identifierSchemeCodeUnspsc190501.12352304'),
	'identifierSchemeCodeUnspsc190501.12352305': __('identifierSchemeCodeUnspsc190501.12352305'),
	'identifierSchemeCodeUnspsc190501.12352306': __('identifierSchemeCodeUnspsc190501.12352306'),
	'identifierSchemeCodeUnspsc190501.12352307': __('identifierSchemeCodeUnspsc190501.12352307'),
	'identifierSchemeCodeUnspsc190501.12352308': __('identifierSchemeCodeUnspsc190501.12352308'),
	'identifierSchemeCodeUnspsc190501.12352309': __('identifierSchemeCodeUnspsc190501.12352309'),
	'identifierSchemeCodeUnspsc190501.12352310': __('identifierSchemeCodeUnspsc190501.12352310'),
	'identifierSchemeCodeUnspsc190501.12352311': __('identifierSchemeCodeUnspsc190501.12352311'),
	'identifierSchemeCodeUnspsc190501.12352312': __('identifierSchemeCodeUnspsc190501.12352312'),
	'identifierSchemeCodeUnspsc190501.12352314': __('identifierSchemeCodeUnspsc190501.12352314'),
	'identifierSchemeCodeUnspsc190501.12352315': __('identifierSchemeCodeUnspsc190501.12352315'),
	'identifierSchemeCodeUnspsc190501.12352316': __('identifierSchemeCodeUnspsc190501.12352316'),
	'identifierSchemeCodeUnspsc190501.12352317': __('identifierSchemeCodeUnspsc190501.12352317'),
	'identifierSchemeCodeUnspsc190501.12352318': __('identifierSchemeCodeUnspsc190501.12352318'),
	'identifierSchemeCodeUnspsc190501.12352319': __('identifierSchemeCodeUnspsc190501.12352319'),
	'identifierSchemeCodeUnspsc190501.12352320': __('identifierSchemeCodeUnspsc190501.12352320'),
	'identifierSchemeCodeUnspsc190501.12352321': __('identifierSchemeCodeUnspsc190501.12352321'),
	'identifierSchemeCodeUnspsc190501.12352322': __('identifierSchemeCodeUnspsc190501.12352322'),
	'identifierSchemeCodeUnspsc190501.12352323': __('identifierSchemeCodeUnspsc190501.12352323'),
	'identifierSchemeCodeUnspsc190501.12352400': __('identifierSchemeCodeUnspsc190501.12352400'),
	'identifierSchemeCodeUnspsc190501.12352401': __('identifierSchemeCodeUnspsc190501.12352401'),
	'identifierSchemeCodeUnspsc190501.12352402': __('identifierSchemeCodeUnspsc190501.12352402'),
	'identifierSchemeCodeUnspsc190501.12352500': __('identifierSchemeCodeUnspsc190501.12352500'),
	'identifierSchemeCodeUnspsc190501.12352501': __('identifierSchemeCodeUnspsc190501.12352501'),
	'identifierSchemeCodeUnspsc190501.12352502': __('identifierSchemeCodeUnspsc190501.12352502'),
	'identifierSchemeCodeUnspsc190501.12352503': __('identifierSchemeCodeUnspsc190501.12352503'),
	'identifierSchemeCodeUnspsc190501.12352600': __('identifierSchemeCodeUnspsc190501.12352600'),
	'identifierSchemeCodeUnspsc190501.12352601': __('identifierSchemeCodeUnspsc190501.12352601'),
	'identifierSchemeCodeUnspsc190501.12352602': __('identifierSchemeCodeUnspsc190501.12352602'),
	'identifierSchemeCodeUnspsc190501.12352603': __('identifierSchemeCodeUnspsc190501.12352603'),
	'identifierSchemeCodeUnspsc190501.12352604': __('identifierSchemeCodeUnspsc190501.12352604'),
	'identifierSchemeCodeUnspsc190501.12360000': __('identifierSchemeCodeUnspsc190501.12360000'),
	'identifierSchemeCodeUnspsc190501.12361500': __('identifierSchemeCodeUnspsc190501.12361500'),
	'identifierSchemeCodeUnspsc190501.12361501': __('identifierSchemeCodeUnspsc190501.12361501'),
	'identifierSchemeCodeUnspsc190501.12361502': __('identifierSchemeCodeUnspsc190501.12361502'),
	'identifierSchemeCodeUnspsc190501.12361503': __('identifierSchemeCodeUnspsc190501.12361503'),
	'identifierSchemeCodeUnspsc190501.12361504': __('identifierSchemeCodeUnspsc190501.12361504'),
	'identifierSchemeCodeUnspsc190501.12361505': __('identifierSchemeCodeUnspsc190501.12361505'),
	'identifierSchemeCodeUnspsc190501.12361506': __('identifierSchemeCodeUnspsc190501.12361506'),
	'identifierSchemeCodeUnspsc190501.12361507': __('identifierSchemeCodeUnspsc190501.12361507'),
	'identifierSchemeCodeUnspsc190501.12361508': __('identifierSchemeCodeUnspsc190501.12361508'),
	'identifierSchemeCodeUnspsc190501.12361509': __('identifierSchemeCodeUnspsc190501.12361509'),
	'identifierSchemeCodeUnspsc190501.12361510': __('identifierSchemeCodeUnspsc190501.12361510'),
	'identifierSchemeCodeUnspsc190501.12361512': __('identifierSchemeCodeUnspsc190501.12361512'),
	'identifierSchemeCodeUnspsc190501.12361513': __('identifierSchemeCodeUnspsc190501.12361513'),
	'identifierSchemeCodeUnspsc190501.13000000': __('identifierSchemeCodeUnspsc190501.13000000'),
	'identifierSchemeCodeUnspsc190501.13100000': __('identifierSchemeCodeUnspsc190501.13100000'),
	'identifierSchemeCodeUnspsc190501.13101500': __('identifierSchemeCodeUnspsc190501.13101500'),
	'identifierSchemeCodeUnspsc190501.13101501': __('identifierSchemeCodeUnspsc190501.13101501'),
	'identifierSchemeCodeUnspsc190501.13101502': __('identifierSchemeCodeUnspsc190501.13101502'),
	'identifierSchemeCodeUnspsc190501.13101503': __('identifierSchemeCodeUnspsc190501.13101503'),
	'identifierSchemeCodeUnspsc190501.13101504': __('identifierSchemeCodeUnspsc190501.13101504'),
	'identifierSchemeCodeUnspsc190501.13101505': __('identifierSchemeCodeUnspsc190501.13101505'),
	'identifierSchemeCodeUnspsc190501.13101600': __('identifierSchemeCodeUnspsc190501.13101600'),
	'identifierSchemeCodeUnspsc190501.13101601': __('identifierSchemeCodeUnspsc190501.13101601'),
	'identifierSchemeCodeUnspsc190501.13101602': __('identifierSchemeCodeUnspsc190501.13101602'),
	'identifierSchemeCodeUnspsc190501.13101603': __('identifierSchemeCodeUnspsc190501.13101603'),
	'identifierSchemeCodeUnspsc190501.13101604': __('identifierSchemeCodeUnspsc190501.13101604'),
	'identifierSchemeCodeUnspsc190501.13101605': __('identifierSchemeCodeUnspsc190501.13101605'),
	'identifierSchemeCodeUnspsc190501.13101606': __('identifierSchemeCodeUnspsc190501.13101606'),
	'identifierSchemeCodeUnspsc190501.13101607': __('identifierSchemeCodeUnspsc190501.13101607'),
	'identifierSchemeCodeUnspsc190501.13101608': __('identifierSchemeCodeUnspsc190501.13101608'),
	'identifierSchemeCodeUnspsc190501.13101700': __('identifierSchemeCodeUnspsc190501.13101700'),
	'identifierSchemeCodeUnspsc190501.13101701': __('identifierSchemeCodeUnspsc190501.13101701'),
	'identifierSchemeCodeUnspsc190501.13101702': __('identifierSchemeCodeUnspsc190501.13101702'),
	'identifierSchemeCodeUnspsc190501.13101703': __('identifierSchemeCodeUnspsc190501.13101703'),
	'identifierSchemeCodeUnspsc190501.13101704': __('identifierSchemeCodeUnspsc190501.13101704'),
	'identifierSchemeCodeUnspsc190501.13101705': __('identifierSchemeCodeUnspsc190501.13101705'),
	'identifierSchemeCodeUnspsc190501.13101706': __('identifierSchemeCodeUnspsc190501.13101706'),
	'identifierSchemeCodeUnspsc190501.13101707': __('identifierSchemeCodeUnspsc190501.13101707'),
	'identifierSchemeCodeUnspsc190501.13101708': __('identifierSchemeCodeUnspsc190501.13101708'),
	'identifierSchemeCodeUnspsc190501.13101709': __('identifierSchemeCodeUnspsc190501.13101709'),
	'identifierSchemeCodeUnspsc190501.13101710': __('identifierSchemeCodeUnspsc190501.13101710'),
	'identifierSchemeCodeUnspsc190501.13101711': __('identifierSchemeCodeUnspsc190501.13101711'),
	'identifierSchemeCodeUnspsc190501.13101712': __('identifierSchemeCodeUnspsc190501.13101712'),
	'identifierSchemeCodeUnspsc190501.13101713': __('identifierSchemeCodeUnspsc190501.13101713'),
	'identifierSchemeCodeUnspsc190501.13101714': __('identifierSchemeCodeUnspsc190501.13101714'),
	'identifierSchemeCodeUnspsc190501.13101715': __('identifierSchemeCodeUnspsc190501.13101715'),
	'identifierSchemeCodeUnspsc190501.13101716': __('identifierSchemeCodeUnspsc190501.13101716'),
	'identifierSchemeCodeUnspsc190501.13101717': __('identifierSchemeCodeUnspsc190501.13101717'),
	'identifierSchemeCodeUnspsc190501.13101718': __('identifierSchemeCodeUnspsc190501.13101718'),
	'identifierSchemeCodeUnspsc190501.13101719': __('identifierSchemeCodeUnspsc190501.13101719'),
	'identifierSchemeCodeUnspsc190501.13101720': __('identifierSchemeCodeUnspsc190501.13101720'),
	'identifierSchemeCodeUnspsc190501.13101721': __('identifierSchemeCodeUnspsc190501.13101721'),
	'identifierSchemeCodeUnspsc190501.13101722': __('identifierSchemeCodeUnspsc190501.13101722'),
	'identifierSchemeCodeUnspsc190501.13101723': __('identifierSchemeCodeUnspsc190501.13101723'),
	'identifierSchemeCodeUnspsc190501.13101724': __('identifierSchemeCodeUnspsc190501.13101724'),
	'identifierSchemeCodeUnspsc190501.13101725': __('identifierSchemeCodeUnspsc190501.13101725'),
	'identifierSchemeCodeUnspsc190501.13101900': __('identifierSchemeCodeUnspsc190501.13101900'),
	'identifierSchemeCodeUnspsc190501.13101902': __('identifierSchemeCodeUnspsc190501.13101902'),
	'identifierSchemeCodeUnspsc190501.13101903': __('identifierSchemeCodeUnspsc190501.13101903'),
	'identifierSchemeCodeUnspsc190501.13101904': __('identifierSchemeCodeUnspsc190501.13101904'),
	'identifierSchemeCodeUnspsc190501.13101905': __('identifierSchemeCodeUnspsc190501.13101905'),
	'identifierSchemeCodeUnspsc190501.13101906': __('identifierSchemeCodeUnspsc190501.13101906'),
	'identifierSchemeCodeUnspsc190501.13102000': __('identifierSchemeCodeUnspsc190501.13102000'),
	'identifierSchemeCodeUnspsc190501.13102001': __('identifierSchemeCodeUnspsc190501.13102001'),
	'identifierSchemeCodeUnspsc190501.13102002': __('identifierSchemeCodeUnspsc190501.13102002'),
	'identifierSchemeCodeUnspsc190501.13102003': __('identifierSchemeCodeUnspsc190501.13102003'),
	'identifierSchemeCodeUnspsc190501.13102005': __('identifierSchemeCodeUnspsc190501.13102005'),
	'identifierSchemeCodeUnspsc190501.13102006': __('identifierSchemeCodeUnspsc190501.13102006'),
	'identifierSchemeCodeUnspsc190501.13102008': __('identifierSchemeCodeUnspsc190501.13102008'),
	'identifierSchemeCodeUnspsc190501.13102010': __('identifierSchemeCodeUnspsc190501.13102010'),
	'identifierSchemeCodeUnspsc190501.13102011': __('identifierSchemeCodeUnspsc190501.13102011'),
	'identifierSchemeCodeUnspsc190501.13102012': __('identifierSchemeCodeUnspsc190501.13102012'),
	'identifierSchemeCodeUnspsc190501.13102013': __('identifierSchemeCodeUnspsc190501.13102013'),
	'identifierSchemeCodeUnspsc190501.13102014': __('identifierSchemeCodeUnspsc190501.13102014'),
	'identifierSchemeCodeUnspsc190501.13102016': __('identifierSchemeCodeUnspsc190501.13102016'),
	'identifierSchemeCodeUnspsc190501.13102017': __('identifierSchemeCodeUnspsc190501.13102017'),
	'identifierSchemeCodeUnspsc190501.13102018': __('identifierSchemeCodeUnspsc190501.13102018'),
	'identifierSchemeCodeUnspsc190501.13102019': __('identifierSchemeCodeUnspsc190501.13102019'),
	'identifierSchemeCodeUnspsc190501.13102020': __('identifierSchemeCodeUnspsc190501.13102020'),
	'identifierSchemeCodeUnspsc190501.13102021': __('identifierSchemeCodeUnspsc190501.13102021'),
	'identifierSchemeCodeUnspsc190501.13102022': __('identifierSchemeCodeUnspsc190501.13102022'),
	'identifierSchemeCodeUnspsc190501.13102024': __('identifierSchemeCodeUnspsc190501.13102024'),
	'identifierSchemeCodeUnspsc190501.13102025': __('identifierSchemeCodeUnspsc190501.13102025'),
	'identifierSchemeCodeUnspsc190501.13102026': __('identifierSchemeCodeUnspsc190501.13102026'),
	'identifierSchemeCodeUnspsc190501.13102027': __('identifierSchemeCodeUnspsc190501.13102027'),
	'identifierSchemeCodeUnspsc190501.13102028': __('identifierSchemeCodeUnspsc190501.13102028'),
	'identifierSchemeCodeUnspsc190501.13102029': __('identifierSchemeCodeUnspsc190501.13102029'),
	'identifierSchemeCodeUnspsc190501.13102030': __('identifierSchemeCodeUnspsc190501.13102030'),
	'identifierSchemeCodeUnspsc190501.13102031': __('identifierSchemeCodeUnspsc190501.13102031'),
	'identifierSchemeCodeUnspsc190501.13102032': __('identifierSchemeCodeUnspsc190501.13102032'),
	'identifierSchemeCodeUnspsc190501.13110000': __('identifierSchemeCodeUnspsc190501.13110000'),
	'identifierSchemeCodeUnspsc190501.13111000': __('identifierSchemeCodeUnspsc190501.13111000'),
	'identifierSchemeCodeUnspsc190501.13111001': __('identifierSchemeCodeUnspsc190501.13111001'),
	'identifierSchemeCodeUnspsc190501.13111002': __('identifierSchemeCodeUnspsc190501.13111002'),
	'identifierSchemeCodeUnspsc190501.13111003': __('identifierSchemeCodeUnspsc190501.13111003'),
	'identifierSchemeCodeUnspsc190501.13111004': __('identifierSchemeCodeUnspsc190501.13111004'),
	'identifierSchemeCodeUnspsc190501.13111005': __('identifierSchemeCodeUnspsc190501.13111005'),
	'identifierSchemeCodeUnspsc190501.13111006': __('identifierSchemeCodeUnspsc190501.13111006'),
	'identifierSchemeCodeUnspsc190501.13111007': __('identifierSchemeCodeUnspsc190501.13111007'),
	'identifierSchemeCodeUnspsc190501.13111008': __('identifierSchemeCodeUnspsc190501.13111008'),
	'identifierSchemeCodeUnspsc190501.13111009': __('identifierSchemeCodeUnspsc190501.13111009'),
	'identifierSchemeCodeUnspsc190501.13111010': __('identifierSchemeCodeUnspsc190501.13111010'),
	'identifierSchemeCodeUnspsc190501.13111011': __('identifierSchemeCodeUnspsc190501.13111011'),
	'identifierSchemeCodeUnspsc190501.13111012': __('identifierSchemeCodeUnspsc190501.13111012'),
	'identifierSchemeCodeUnspsc190501.13111013': __('identifierSchemeCodeUnspsc190501.13111013'),
	'identifierSchemeCodeUnspsc190501.13111014': __('identifierSchemeCodeUnspsc190501.13111014'),
	'identifierSchemeCodeUnspsc190501.13111015': __('identifierSchemeCodeUnspsc190501.13111015'),
	'identifierSchemeCodeUnspsc190501.13111016': __('identifierSchemeCodeUnspsc190501.13111016'),
	'identifierSchemeCodeUnspsc190501.13111017': __('identifierSchemeCodeUnspsc190501.13111017'),
	'identifierSchemeCodeUnspsc190501.13111018': __('identifierSchemeCodeUnspsc190501.13111018'),
	'identifierSchemeCodeUnspsc190501.13111019': __('identifierSchemeCodeUnspsc190501.13111019'),
	'identifierSchemeCodeUnspsc190501.13111020': __('identifierSchemeCodeUnspsc190501.13111020'),
	'identifierSchemeCodeUnspsc190501.13111021': __('identifierSchemeCodeUnspsc190501.13111021'),
	'identifierSchemeCodeUnspsc190501.13111022': __('identifierSchemeCodeUnspsc190501.13111022'),
	'identifierSchemeCodeUnspsc190501.13111023': __('identifierSchemeCodeUnspsc190501.13111023'),
	'identifierSchemeCodeUnspsc190501.13111024': __('identifierSchemeCodeUnspsc190501.13111024'),
	'identifierSchemeCodeUnspsc190501.13111025': __('identifierSchemeCodeUnspsc190501.13111025'),
	'identifierSchemeCodeUnspsc190501.13111026': __('identifierSchemeCodeUnspsc190501.13111026'),
	'identifierSchemeCodeUnspsc190501.13111027': __('identifierSchemeCodeUnspsc190501.13111027'),
	'identifierSchemeCodeUnspsc190501.13111028': __('identifierSchemeCodeUnspsc190501.13111028'),
	'identifierSchemeCodeUnspsc190501.13111029': __('identifierSchemeCodeUnspsc190501.13111029'),
	'identifierSchemeCodeUnspsc190501.13111030': __('identifierSchemeCodeUnspsc190501.13111030'),
	'identifierSchemeCodeUnspsc190501.13111031': __('identifierSchemeCodeUnspsc190501.13111031'),
	'identifierSchemeCodeUnspsc190501.13111032': __('identifierSchemeCodeUnspsc190501.13111032'),
	'identifierSchemeCodeUnspsc190501.13111033': __('identifierSchemeCodeUnspsc190501.13111033'),
	'identifierSchemeCodeUnspsc190501.13111034': __('identifierSchemeCodeUnspsc190501.13111034'),
	'identifierSchemeCodeUnspsc190501.13111035': __('identifierSchemeCodeUnspsc190501.13111035'),
	'identifierSchemeCodeUnspsc190501.13111036': __('identifierSchemeCodeUnspsc190501.13111036'),
	'identifierSchemeCodeUnspsc190501.13111037': __('identifierSchemeCodeUnspsc190501.13111037'),
	'identifierSchemeCodeUnspsc190501.13111038': __('identifierSchemeCodeUnspsc190501.13111038'),
	'identifierSchemeCodeUnspsc190501.13111039': __('identifierSchemeCodeUnspsc190501.13111039'),
	'identifierSchemeCodeUnspsc190501.13111040': __('identifierSchemeCodeUnspsc190501.13111040'),
	'identifierSchemeCodeUnspsc190501.13111041': __('identifierSchemeCodeUnspsc190501.13111041'),
	'identifierSchemeCodeUnspsc190501.13111042': __('identifierSchemeCodeUnspsc190501.13111042'),
	'identifierSchemeCodeUnspsc190501.13111043': __('identifierSchemeCodeUnspsc190501.13111043'),
	'identifierSchemeCodeUnspsc190501.13111044': __('identifierSchemeCodeUnspsc190501.13111044'),
	'identifierSchemeCodeUnspsc190501.13111045': __('identifierSchemeCodeUnspsc190501.13111045'),
	'identifierSchemeCodeUnspsc190501.13111046': __('identifierSchemeCodeUnspsc190501.13111046'),
	'identifierSchemeCodeUnspsc190501.13111047': __('identifierSchemeCodeUnspsc190501.13111047'),
	'identifierSchemeCodeUnspsc190501.13111048': __('identifierSchemeCodeUnspsc190501.13111048'),
	'identifierSchemeCodeUnspsc190501.13111049': __('identifierSchemeCodeUnspsc190501.13111049'),
	'identifierSchemeCodeUnspsc190501.13111050': __('identifierSchemeCodeUnspsc190501.13111050'),
	'identifierSchemeCodeUnspsc190501.13111051': __('identifierSchemeCodeUnspsc190501.13111051'),
	'identifierSchemeCodeUnspsc190501.13111052': __('identifierSchemeCodeUnspsc190501.13111052'),
	'identifierSchemeCodeUnspsc190501.13111053': __('identifierSchemeCodeUnspsc190501.13111053'),
	'identifierSchemeCodeUnspsc190501.13111054': __('identifierSchemeCodeUnspsc190501.13111054'),
	'identifierSchemeCodeUnspsc190501.13111055': __('identifierSchemeCodeUnspsc190501.13111055'),
	'identifierSchemeCodeUnspsc190501.13111056': __('identifierSchemeCodeUnspsc190501.13111056'),
	'identifierSchemeCodeUnspsc190501.13111057': __('identifierSchemeCodeUnspsc190501.13111057'),
	'identifierSchemeCodeUnspsc190501.13111058': __('identifierSchemeCodeUnspsc190501.13111058'),
	'identifierSchemeCodeUnspsc190501.13111059': __('identifierSchemeCodeUnspsc190501.13111059'),
	'identifierSchemeCodeUnspsc190501.13111060': __('identifierSchemeCodeUnspsc190501.13111060'),
	'identifierSchemeCodeUnspsc190501.13111061': __('identifierSchemeCodeUnspsc190501.13111061'),
	'identifierSchemeCodeUnspsc190501.13111062': __('identifierSchemeCodeUnspsc190501.13111062'),
	'identifierSchemeCodeUnspsc190501.13111063': __('identifierSchemeCodeUnspsc190501.13111063'),
	'identifierSchemeCodeUnspsc190501.13111064': __('identifierSchemeCodeUnspsc190501.13111064'),
	'identifierSchemeCodeUnspsc190501.13111065': __('identifierSchemeCodeUnspsc190501.13111065'),
	'identifierSchemeCodeUnspsc190501.13111066': __('identifierSchemeCodeUnspsc190501.13111066'),
	'identifierSchemeCodeUnspsc190501.13111067': __('identifierSchemeCodeUnspsc190501.13111067'),
	'identifierSchemeCodeUnspsc190501.13111068': __('identifierSchemeCodeUnspsc190501.13111068'),
	'identifierSchemeCodeUnspsc190501.13111069': __('identifierSchemeCodeUnspsc190501.13111069'),
	'identifierSchemeCodeUnspsc190501.13111070': __('identifierSchemeCodeUnspsc190501.13111070'),
	'identifierSchemeCodeUnspsc190501.13111071': __('identifierSchemeCodeUnspsc190501.13111071'),
	'identifierSchemeCodeUnspsc190501.13111072': __('identifierSchemeCodeUnspsc190501.13111072'),
	'identifierSchemeCodeUnspsc190501.13111073': __('identifierSchemeCodeUnspsc190501.13111073'),
	'identifierSchemeCodeUnspsc190501.13111074': __('identifierSchemeCodeUnspsc190501.13111074'),
	'identifierSchemeCodeUnspsc190501.13111075': __('identifierSchemeCodeUnspsc190501.13111075'),
	'identifierSchemeCodeUnspsc190501.13111076': __('identifierSchemeCodeUnspsc190501.13111076'),
	'identifierSchemeCodeUnspsc190501.13111077': __('identifierSchemeCodeUnspsc190501.13111077'),
	'identifierSchemeCodeUnspsc190501.13111078': __('identifierSchemeCodeUnspsc190501.13111078'),
	'identifierSchemeCodeUnspsc190501.13111079': __('identifierSchemeCodeUnspsc190501.13111079'),
	'identifierSchemeCodeUnspsc190501.13111080': __('identifierSchemeCodeUnspsc190501.13111080'),
	'identifierSchemeCodeUnspsc190501.13111081': __('identifierSchemeCodeUnspsc190501.13111081'),
	'identifierSchemeCodeUnspsc190501.13111082': __('identifierSchemeCodeUnspsc190501.13111082'),
	'identifierSchemeCodeUnspsc190501.13111100': __('identifierSchemeCodeUnspsc190501.13111100'),
	'identifierSchemeCodeUnspsc190501.13111101': __('identifierSchemeCodeUnspsc190501.13111101'),
	'identifierSchemeCodeUnspsc190501.13111102': __('identifierSchemeCodeUnspsc190501.13111102'),
	'identifierSchemeCodeUnspsc190501.13111103': __('identifierSchemeCodeUnspsc190501.13111103'),
	'identifierSchemeCodeUnspsc190501.13111200': __('identifierSchemeCodeUnspsc190501.13111200'),
	'identifierSchemeCodeUnspsc190501.13111201': __('identifierSchemeCodeUnspsc190501.13111201'),
	'identifierSchemeCodeUnspsc190501.13111202': __('identifierSchemeCodeUnspsc190501.13111202'),
	'identifierSchemeCodeUnspsc190501.13111203': __('identifierSchemeCodeUnspsc190501.13111203'),
	'identifierSchemeCodeUnspsc190501.13111204': __('identifierSchemeCodeUnspsc190501.13111204'),
	'identifierSchemeCodeUnspsc190501.13111205': __('identifierSchemeCodeUnspsc190501.13111205'),
	'identifierSchemeCodeUnspsc190501.13111206': __('identifierSchemeCodeUnspsc190501.13111206'),
	'identifierSchemeCodeUnspsc190501.13111207': __('identifierSchemeCodeUnspsc190501.13111207'),
	'identifierSchemeCodeUnspsc190501.13111208': __('identifierSchemeCodeUnspsc190501.13111208'),
	'identifierSchemeCodeUnspsc190501.13111209': __('identifierSchemeCodeUnspsc190501.13111209'),
	'identifierSchemeCodeUnspsc190501.13111210': __('identifierSchemeCodeUnspsc190501.13111210'),
	'identifierSchemeCodeUnspsc190501.13111211': __('identifierSchemeCodeUnspsc190501.13111211'),
	'identifierSchemeCodeUnspsc190501.13111212': __('identifierSchemeCodeUnspsc190501.13111212'),
	'identifierSchemeCodeUnspsc190501.13111213': __('identifierSchemeCodeUnspsc190501.13111213'),
	'identifierSchemeCodeUnspsc190501.13111214': __('identifierSchemeCodeUnspsc190501.13111214'),
	'identifierSchemeCodeUnspsc190501.13111215': __('identifierSchemeCodeUnspsc190501.13111215'),
	'identifierSchemeCodeUnspsc190501.13111216': __('identifierSchemeCodeUnspsc190501.13111216'),
	'identifierSchemeCodeUnspsc190501.13111217': __('identifierSchemeCodeUnspsc190501.13111217'),
	'identifierSchemeCodeUnspsc190501.13111218': __('identifierSchemeCodeUnspsc190501.13111218'),
	'identifierSchemeCodeUnspsc190501.13111219': __('identifierSchemeCodeUnspsc190501.13111219'),
	'identifierSchemeCodeUnspsc190501.13111220': __('identifierSchemeCodeUnspsc190501.13111220'),
	'identifierSchemeCodeUnspsc190501.13111300': __('identifierSchemeCodeUnspsc190501.13111300'),
	'identifierSchemeCodeUnspsc190501.13111301': __('identifierSchemeCodeUnspsc190501.13111301'),
	'identifierSchemeCodeUnspsc190501.13111302': __('identifierSchemeCodeUnspsc190501.13111302'),
	'identifierSchemeCodeUnspsc190501.13111303': __('identifierSchemeCodeUnspsc190501.13111303'),
	'identifierSchemeCodeUnspsc190501.13111304': __('identifierSchemeCodeUnspsc190501.13111304'),
	'identifierSchemeCodeUnspsc190501.13111305': __('identifierSchemeCodeUnspsc190501.13111305'),
	'identifierSchemeCodeUnspsc190501.13111306': __('identifierSchemeCodeUnspsc190501.13111306'),
	'identifierSchemeCodeUnspsc190501.13111307': __('identifierSchemeCodeUnspsc190501.13111307'),
	'identifierSchemeCodeUnspsc190501.13111308': __('identifierSchemeCodeUnspsc190501.13111308'),
	'identifierSchemeCodeUnspsc190501.13111309': __('identifierSchemeCodeUnspsc190501.13111309'),
	'identifierSchemeCodeUnspsc190501.13111310': __('identifierSchemeCodeUnspsc190501.13111310'),
	'identifierSchemeCodeUnspsc190501.14000000': __('identifierSchemeCodeUnspsc190501.14000000'),
	'identifierSchemeCodeUnspsc190501.14100000': __('identifierSchemeCodeUnspsc190501.14100000'),
	'identifierSchemeCodeUnspsc190501.14101500': __('identifierSchemeCodeUnspsc190501.14101500'),
	'identifierSchemeCodeUnspsc190501.14101501': __('identifierSchemeCodeUnspsc190501.14101501'),
	'identifierSchemeCodeUnspsc190501.14110000': __('identifierSchemeCodeUnspsc190501.14110000'),
	'identifierSchemeCodeUnspsc190501.14111500': __('identifierSchemeCodeUnspsc190501.14111500'),
	'identifierSchemeCodeUnspsc190501.14111501': __('identifierSchemeCodeUnspsc190501.14111501'),
	'identifierSchemeCodeUnspsc190501.14111502': __('identifierSchemeCodeUnspsc190501.14111502'),
	'identifierSchemeCodeUnspsc190501.14111503': __('identifierSchemeCodeUnspsc190501.14111503'),
	'identifierSchemeCodeUnspsc190501.14111504': __('identifierSchemeCodeUnspsc190501.14111504'),
	'identifierSchemeCodeUnspsc190501.14111505': __('identifierSchemeCodeUnspsc190501.14111505'),
	'identifierSchemeCodeUnspsc190501.14111506': __('identifierSchemeCodeUnspsc190501.14111506'),
	'identifierSchemeCodeUnspsc190501.14111507': __('identifierSchemeCodeUnspsc190501.14111507'),
	'identifierSchemeCodeUnspsc190501.14111508': __('identifierSchemeCodeUnspsc190501.14111508'),
	'identifierSchemeCodeUnspsc190501.14111509': __('identifierSchemeCodeUnspsc190501.14111509'),
	'identifierSchemeCodeUnspsc190501.14111510': __('identifierSchemeCodeUnspsc190501.14111510'),
	'identifierSchemeCodeUnspsc190501.14111511': __('identifierSchemeCodeUnspsc190501.14111511'),
	'identifierSchemeCodeUnspsc190501.14111512': __('identifierSchemeCodeUnspsc190501.14111512'),
	'identifierSchemeCodeUnspsc190501.14111513': __('identifierSchemeCodeUnspsc190501.14111513'),
	'identifierSchemeCodeUnspsc190501.14111514': __('identifierSchemeCodeUnspsc190501.14111514'),
	'identifierSchemeCodeUnspsc190501.14111515': __('identifierSchemeCodeUnspsc190501.14111515'),
	'identifierSchemeCodeUnspsc190501.14111516': __('identifierSchemeCodeUnspsc190501.14111516'),
	'identifierSchemeCodeUnspsc190501.14111518': __('identifierSchemeCodeUnspsc190501.14111518'),
	'identifierSchemeCodeUnspsc190501.14111519': __('identifierSchemeCodeUnspsc190501.14111519'),
	'identifierSchemeCodeUnspsc190501.14111520': __('identifierSchemeCodeUnspsc190501.14111520'),
	'identifierSchemeCodeUnspsc190501.14111523': __('identifierSchemeCodeUnspsc190501.14111523'),
	'identifierSchemeCodeUnspsc190501.14111524': __('identifierSchemeCodeUnspsc190501.14111524'),
	'identifierSchemeCodeUnspsc190501.14111525': __('identifierSchemeCodeUnspsc190501.14111525'),
	'identifierSchemeCodeUnspsc190501.14111526': __('identifierSchemeCodeUnspsc190501.14111526'),
	'identifierSchemeCodeUnspsc190501.14111527': __('identifierSchemeCodeUnspsc190501.14111527'),
	'identifierSchemeCodeUnspsc190501.14111528': __('identifierSchemeCodeUnspsc190501.14111528'),
	'identifierSchemeCodeUnspsc190501.14111529': __('identifierSchemeCodeUnspsc190501.14111529'),
	'identifierSchemeCodeUnspsc190501.14111530': __('identifierSchemeCodeUnspsc190501.14111530'),
	'identifierSchemeCodeUnspsc190501.14111531': __('identifierSchemeCodeUnspsc190501.14111531'),
	'identifierSchemeCodeUnspsc190501.14111532': __('identifierSchemeCodeUnspsc190501.14111532'),
	'identifierSchemeCodeUnspsc190501.14111533': __('identifierSchemeCodeUnspsc190501.14111533'),
	'identifierSchemeCodeUnspsc190501.14111534': __('identifierSchemeCodeUnspsc190501.14111534'),
	'identifierSchemeCodeUnspsc190501.14111535': __('identifierSchemeCodeUnspsc190501.14111535'),
	'identifierSchemeCodeUnspsc190501.14111536': __('identifierSchemeCodeUnspsc190501.14111536'),
	'identifierSchemeCodeUnspsc190501.14111537': __('identifierSchemeCodeUnspsc190501.14111537'),
	'identifierSchemeCodeUnspsc190501.14111538': __('identifierSchemeCodeUnspsc190501.14111538'),
	'identifierSchemeCodeUnspsc190501.14111539': __('identifierSchemeCodeUnspsc190501.14111539'),
	'identifierSchemeCodeUnspsc190501.14111540': __('identifierSchemeCodeUnspsc190501.14111540'),
	'identifierSchemeCodeUnspsc190501.14111541': __('identifierSchemeCodeUnspsc190501.14111541'),
	'identifierSchemeCodeUnspsc190501.14111542': __('identifierSchemeCodeUnspsc190501.14111542'),
	'identifierSchemeCodeUnspsc190501.14111543': __('identifierSchemeCodeUnspsc190501.14111543'),
	'identifierSchemeCodeUnspsc190501.14111544': __('identifierSchemeCodeUnspsc190501.14111544'),
	'identifierSchemeCodeUnspsc190501.14111545': __('identifierSchemeCodeUnspsc190501.14111545'),
	'identifierSchemeCodeUnspsc190501.14111600': __('identifierSchemeCodeUnspsc190501.14111600'),
	'identifierSchemeCodeUnspsc190501.14111601': __('identifierSchemeCodeUnspsc190501.14111601'),
	'identifierSchemeCodeUnspsc190501.14111604': __('identifierSchemeCodeUnspsc190501.14111604'),
	'identifierSchemeCodeUnspsc190501.14111605': __('identifierSchemeCodeUnspsc190501.14111605'),
	'identifierSchemeCodeUnspsc190501.14111606': __('identifierSchemeCodeUnspsc190501.14111606'),
	'identifierSchemeCodeUnspsc190501.14111607': __('identifierSchemeCodeUnspsc190501.14111607'),
	'identifierSchemeCodeUnspsc190501.14111608': __('identifierSchemeCodeUnspsc190501.14111608'),
	'identifierSchemeCodeUnspsc190501.14111609': __('identifierSchemeCodeUnspsc190501.14111609'),
	'identifierSchemeCodeUnspsc190501.14111610': __('identifierSchemeCodeUnspsc190501.14111610'),
	'identifierSchemeCodeUnspsc190501.14111611': __('identifierSchemeCodeUnspsc190501.14111611'),
	'identifierSchemeCodeUnspsc190501.14111613': __('identifierSchemeCodeUnspsc190501.14111613'),
	'identifierSchemeCodeUnspsc190501.14111614': __('identifierSchemeCodeUnspsc190501.14111614'),
	'identifierSchemeCodeUnspsc190501.14111615': __('identifierSchemeCodeUnspsc190501.14111615'),
	'identifierSchemeCodeUnspsc190501.14111616': __('identifierSchemeCodeUnspsc190501.14111616'),
	'identifierSchemeCodeUnspsc190501.14111617': __('identifierSchemeCodeUnspsc190501.14111617'),
	'identifierSchemeCodeUnspsc190501.14111618': __('identifierSchemeCodeUnspsc190501.14111618'),
	'identifierSchemeCodeUnspsc190501.14111700': __('identifierSchemeCodeUnspsc190501.14111700'),
	'identifierSchemeCodeUnspsc190501.14111701': __('identifierSchemeCodeUnspsc190501.14111701'),
	'identifierSchemeCodeUnspsc190501.14111702': __('identifierSchemeCodeUnspsc190501.14111702'),
	'identifierSchemeCodeUnspsc190501.14111703': __('identifierSchemeCodeUnspsc190501.14111703'),
	'identifierSchemeCodeUnspsc190501.14111704': __('identifierSchemeCodeUnspsc190501.14111704'),
	'identifierSchemeCodeUnspsc190501.14111705': __('identifierSchemeCodeUnspsc190501.14111705'),
	'identifierSchemeCodeUnspsc190501.14111706': __('identifierSchemeCodeUnspsc190501.14111706'),
	'identifierSchemeCodeUnspsc190501.14111800': __('identifierSchemeCodeUnspsc190501.14111800'),
	'identifierSchemeCodeUnspsc190501.14111801': __('identifierSchemeCodeUnspsc190501.14111801'),
	'identifierSchemeCodeUnspsc190501.14111802': __('identifierSchemeCodeUnspsc190501.14111802'),
	'identifierSchemeCodeUnspsc190501.14111803': __('identifierSchemeCodeUnspsc190501.14111803'),
	'identifierSchemeCodeUnspsc190501.14111804': __('identifierSchemeCodeUnspsc190501.14111804'),
	'identifierSchemeCodeUnspsc190501.14111805': __('identifierSchemeCodeUnspsc190501.14111805'),
	'identifierSchemeCodeUnspsc190501.14111806': __('identifierSchemeCodeUnspsc190501.14111806'),
	'identifierSchemeCodeUnspsc190501.14111807': __('identifierSchemeCodeUnspsc190501.14111807'),
	'identifierSchemeCodeUnspsc190501.14111808': __('identifierSchemeCodeUnspsc190501.14111808'),
	'identifierSchemeCodeUnspsc190501.14111809': __('identifierSchemeCodeUnspsc190501.14111809'),
	'identifierSchemeCodeUnspsc190501.14111810': __('identifierSchemeCodeUnspsc190501.14111810'),
	'identifierSchemeCodeUnspsc190501.14111811': __('identifierSchemeCodeUnspsc190501.14111811'),
	'identifierSchemeCodeUnspsc190501.14111812': __('identifierSchemeCodeUnspsc190501.14111812'),
	'identifierSchemeCodeUnspsc190501.14111813': __('identifierSchemeCodeUnspsc190501.14111813'),
	'identifierSchemeCodeUnspsc190501.14111814': __('identifierSchemeCodeUnspsc190501.14111814'),
	'identifierSchemeCodeUnspsc190501.14111815': __('identifierSchemeCodeUnspsc190501.14111815'),
	'identifierSchemeCodeUnspsc190501.14111816': __('identifierSchemeCodeUnspsc190501.14111816'),
	'identifierSchemeCodeUnspsc190501.14111817': __('identifierSchemeCodeUnspsc190501.14111817'),
	'identifierSchemeCodeUnspsc190501.14111818': __('identifierSchemeCodeUnspsc190501.14111818'),
	'identifierSchemeCodeUnspsc190501.14111819': __('identifierSchemeCodeUnspsc190501.14111819'),
	'identifierSchemeCodeUnspsc190501.14111820': __('identifierSchemeCodeUnspsc190501.14111820'),
	'identifierSchemeCodeUnspsc190501.14111821': __('identifierSchemeCodeUnspsc190501.14111821'),
	'identifierSchemeCodeUnspsc190501.14111822': __('identifierSchemeCodeUnspsc190501.14111822'),
	'identifierSchemeCodeUnspsc190501.14111823': __('identifierSchemeCodeUnspsc190501.14111823'),
	'identifierSchemeCodeUnspsc190501.14111824': __('identifierSchemeCodeUnspsc190501.14111824'),
	'identifierSchemeCodeUnspsc190501.14111825': __('identifierSchemeCodeUnspsc190501.14111825'),
	'identifierSchemeCodeUnspsc190501.14111826': __('identifierSchemeCodeUnspsc190501.14111826'),
	'identifierSchemeCodeUnspsc190501.14111827': __('identifierSchemeCodeUnspsc190501.14111827'),
	'identifierSchemeCodeUnspsc190501.14111828': __('identifierSchemeCodeUnspsc190501.14111828'),
	'identifierSchemeCodeUnspsc190501.14111829': __('identifierSchemeCodeUnspsc190501.14111829'),
	'identifierSchemeCodeUnspsc190501.14111830': __('identifierSchemeCodeUnspsc190501.14111830'),
	'identifierSchemeCodeUnspsc190501.14111831': __('identifierSchemeCodeUnspsc190501.14111831'),
	'identifierSchemeCodeUnspsc190501.14120000': __('identifierSchemeCodeUnspsc190501.14120000'),
	'identifierSchemeCodeUnspsc190501.14121500': __('identifierSchemeCodeUnspsc190501.14121500'),
	'identifierSchemeCodeUnspsc190501.14121501': __('identifierSchemeCodeUnspsc190501.14121501'),
	'identifierSchemeCodeUnspsc190501.14121502': __('identifierSchemeCodeUnspsc190501.14121502'),
	'identifierSchemeCodeUnspsc190501.14121503': __('identifierSchemeCodeUnspsc190501.14121503'),
	'identifierSchemeCodeUnspsc190501.14121504': __('identifierSchemeCodeUnspsc190501.14121504'),
	'identifierSchemeCodeUnspsc190501.14121505': __('identifierSchemeCodeUnspsc190501.14121505'),
	'identifierSchemeCodeUnspsc190501.14121506': __('identifierSchemeCodeUnspsc190501.14121506'),
	'identifierSchemeCodeUnspsc190501.14121507': __('identifierSchemeCodeUnspsc190501.14121507'),
	'identifierSchemeCodeUnspsc190501.14121508': __('identifierSchemeCodeUnspsc190501.14121508'),
	'identifierSchemeCodeUnspsc190501.14121509': __('identifierSchemeCodeUnspsc190501.14121509'),
	'identifierSchemeCodeUnspsc190501.14121510': __('identifierSchemeCodeUnspsc190501.14121510'),
	'identifierSchemeCodeUnspsc190501.14121600': __('identifierSchemeCodeUnspsc190501.14121600'),
	'identifierSchemeCodeUnspsc190501.14121601': __('identifierSchemeCodeUnspsc190501.14121601'),
	'identifierSchemeCodeUnspsc190501.14121602': __('identifierSchemeCodeUnspsc190501.14121602'),
	'identifierSchemeCodeUnspsc190501.14121603': __('identifierSchemeCodeUnspsc190501.14121603'),
	'identifierSchemeCodeUnspsc190501.14121604': __('identifierSchemeCodeUnspsc190501.14121604'),
	'identifierSchemeCodeUnspsc190501.14121605': __('identifierSchemeCodeUnspsc190501.14121605'),
	'identifierSchemeCodeUnspsc190501.14121700': __('identifierSchemeCodeUnspsc190501.14121700'),
	'identifierSchemeCodeUnspsc190501.14121701': __('identifierSchemeCodeUnspsc190501.14121701'),
	'identifierSchemeCodeUnspsc190501.14121702': __('identifierSchemeCodeUnspsc190501.14121702'),
	'identifierSchemeCodeUnspsc190501.14121703': __('identifierSchemeCodeUnspsc190501.14121703'),
	'identifierSchemeCodeUnspsc190501.14121800': __('identifierSchemeCodeUnspsc190501.14121800'),
	'identifierSchemeCodeUnspsc190501.14121801': __('identifierSchemeCodeUnspsc190501.14121801'),
	'identifierSchemeCodeUnspsc190501.14121802': __('identifierSchemeCodeUnspsc190501.14121802'),
	'identifierSchemeCodeUnspsc190501.14121803': __('identifierSchemeCodeUnspsc190501.14121803'),
	'identifierSchemeCodeUnspsc190501.14121804': __('identifierSchemeCodeUnspsc190501.14121804'),
	'identifierSchemeCodeUnspsc190501.14121805': __('identifierSchemeCodeUnspsc190501.14121805'),
	'identifierSchemeCodeUnspsc190501.14121806': __('identifierSchemeCodeUnspsc190501.14121806'),
	'identifierSchemeCodeUnspsc190501.14121807': __('identifierSchemeCodeUnspsc190501.14121807'),
	'identifierSchemeCodeUnspsc190501.14121808': __('identifierSchemeCodeUnspsc190501.14121808'),
	'identifierSchemeCodeUnspsc190501.14121809': __('identifierSchemeCodeUnspsc190501.14121809'),
	'identifierSchemeCodeUnspsc190501.14121810': __('identifierSchemeCodeUnspsc190501.14121810'),
	'identifierSchemeCodeUnspsc190501.14121811': __('identifierSchemeCodeUnspsc190501.14121811'),
	'identifierSchemeCodeUnspsc190501.14121812': __('identifierSchemeCodeUnspsc190501.14121812'),
	'identifierSchemeCodeUnspsc190501.14121813': __('identifierSchemeCodeUnspsc190501.14121813'),
	'identifierSchemeCodeUnspsc190501.14121900': __('identifierSchemeCodeUnspsc190501.14121900'),
	'identifierSchemeCodeUnspsc190501.14121901': __('identifierSchemeCodeUnspsc190501.14121901'),
	'identifierSchemeCodeUnspsc190501.14121902': __('identifierSchemeCodeUnspsc190501.14121902'),
	'identifierSchemeCodeUnspsc190501.14121903': __('identifierSchemeCodeUnspsc190501.14121903'),
	'identifierSchemeCodeUnspsc190501.14121904': __('identifierSchemeCodeUnspsc190501.14121904'),
	'identifierSchemeCodeUnspsc190501.14121905': __('identifierSchemeCodeUnspsc190501.14121905'),
	'identifierSchemeCodeUnspsc190501.14122100': __('identifierSchemeCodeUnspsc190501.14122100'),
	'identifierSchemeCodeUnspsc190501.14122101': __('identifierSchemeCodeUnspsc190501.14122101'),
	'identifierSchemeCodeUnspsc190501.14122102': __('identifierSchemeCodeUnspsc190501.14122102'),
	'identifierSchemeCodeUnspsc190501.14122103': __('identifierSchemeCodeUnspsc190501.14122103'),
	'identifierSchemeCodeUnspsc190501.14122104': __('identifierSchemeCodeUnspsc190501.14122104'),
	'identifierSchemeCodeUnspsc190501.14122105': __('identifierSchemeCodeUnspsc190501.14122105'),
	'identifierSchemeCodeUnspsc190501.14122106': __('identifierSchemeCodeUnspsc190501.14122106'),
	'identifierSchemeCodeUnspsc190501.14122107': __('identifierSchemeCodeUnspsc190501.14122107'),
	'identifierSchemeCodeUnspsc190501.14122200': __('identifierSchemeCodeUnspsc190501.14122200'),
	'identifierSchemeCodeUnspsc190501.14122201': __('identifierSchemeCodeUnspsc190501.14122201'),
	'identifierSchemeCodeUnspsc190501.14122202': __('identifierSchemeCodeUnspsc190501.14122202'),
	'identifierSchemeCodeUnspsc190501.14122203': __('identifierSchemeCodeUnspsc190501.14122203'),
	'identifierSchemeCodeUnspsc190501.14122204': __('identifierSchemeCodeUnspsc190501.14122204'),
	'identifierSchemeCodeUnspsc190501.15000000': __('identifierSchemeCodeUnspsc190501.15000000'),
	'identifierSchemeCodeUnspsc190501.15100000': __('identifierSchemeCodeUnspsc190501.15100000'),
	'identifierSchemeCodeUnspsc190501.15101500': __('identifierSchemeCodeUnspsc190501.15101500'),
	'identifierSchemeCodeUnspsc190501.15101502': __('identifierSchemeCodeUnspsc190501.15101502'),
	'identifierSchemeCodeUnspsc190501.15101503': __('identifierSchemeCodeUnspsc190501.15101503'),
	'identifierSchemeCodeUnspsc190501.15101504': __('identifierSchemeCodeUnspsc190501.15101504'),
	'identifierSchemeCodeUnspsc190501.15101505': __('identifierSchemeCodeUnspsc190501.15101505'),
	'identifierSchemeCodeUnspsc190501.15101506': __('identifierSchemeCodeUnspsc190501.15101506'),
	'identifierSchemeCodeUnspsc190501.15101507': __('identifierSchemeCodeUnspsc190501.15101507'),
	'identifierSchemeCodeUnspsc190501.15101508': __('identifierSchemeCodeUnspsc190501.15101508'),
	'identifierSchemeCodeUnspsc190501.15101509': __('identifierSchemeCodeUnspsc190501.15101509'),
	'identifierSchemeCodeUnspsc190501.15101510': __('identifierSchemeCodeUnspsc190501.15101510'),
	'identifierSchemeCodeUnspsc190501.15101512': __('identifierSchemeCodeUnspsc190501.15101512'),
	'identifierSchemeCodeUnspsc190501.15101513': __('identifierSchemeCodeUnspsc190501.15101513'),
	'identifierSchemeCodeUnspsc190501.15101600': __('identifierSchemeCodeUnspsc190501.15101600'),
	'identifierSchemeCodeUnspsc190501.15101601': __('identifierSchemeCodeUnspsc190501.15101601'),
	'identifierSchemeCodeUnspsc190501.15101602': __('identifierSchemeCodeUnspsc190501.15101602'),
	'identifierSchemeCodeUnspsc190501.15101603': __('identifierSchemeCodeUnspsc190501.15101603'),
	'identifierSchemeCodeUnspsc190501.15101604': __('identifierSchemeCodeUnspsc190501.15101604'),
	'identifierSchemeCodeUnspsc190501.15101605': __('identifierSchemeCodeUnspsc190501.15101605'),
	'identifierSchemeCodeUnspsc190501.15101606': __('identifierSchemeCodeUnspsc190501.15101606'),
	'identifierSchemeCodeUnspsc190501.15101607': __('identifierSchemeCodeUnspsc190501.15101607'),
	'identifierSchemeCodeUnspsc190501.15101608': __('identifierSchemeCodeUnspsc190501.15101608'),
	'identifierSchemeCodeUnspsc190501.15101609': __('identifierSchemeCodeUnspsc190501.15101609'),
	'identifierSchemeCodeUnspsc190501.15101610': __('identifierSchemeCodeUnspsc190501.15101610'),
	'identifierSchemeCodeUnspsc190501.15101611': __('identifierSchemeCodeUnspsc190501.15101611'),
	'identifierSchemeCodeUnspsc190501.15101612': __('identifierSchemeCodeUnspsc190501.15101612'),
	'identifierSchemeCodeUnspsc190501.15101613': __('identifierSchemeCodeUnspsc190501.15101613'),
	'identifierSchemeCodeUnspsc190501.15101614': __('identifierSchemeCodeUnspsc190501.15101614'),
	'identifierSchemeCodeUnspsc190501.15101615': __('identifierSchemeCodeUnspsc190501.15101615'),
	'identifierSchemeCodeUnspsc190501.15101700': __('identifierSchemeCodeUnspsc190501.15101700'),
	'identifierSchemeCodeUnspsc190501.15101701': __('identifierSchemeCodeUnspsc190501.15101701'),
	'identifierSchemeCodeUnspsc190501.15101702': __('identifierSchemeCodeUnspsc190501.15101702'),
	'identifierSchemeCodeUnspsc190501.15101703': __('identifierSchemeCodeUnspsc190501.15101703'),
	'identifierSchemeCodeUnspsc190501.15101800': __('identifierSchemeCodeUnspsc190501.15101800'),
	'identifierSchemeCodeUnspsc190501.15101801': __('identifierSchemeCodeUnspsc190501.15101801'),
	'identifierSchemeCodeUnspsc190501.15101802': __('identifierSchemeCodeUnspsc190501.15101802'),
	'identifierSchemeCodeUnspsc190501.15110000': __('identifierSchemeCodeUnspsc190501.15110000'),
	'identifierSchemeCodeUnspsc190501.15111500': __('identifierSchemeCodeUnspsc190501.15111500'),
	'identifierSchemeCodeUnspsc190501.15111501': __('identifierSchemeCodeUnspsc190501.15111501'),
	'identifierSchemeCodeUnspsc190501.15111502': __('identifierSchemeCodeUnspsc190501.15111502'),
	'identifierSchemeCodeUnspsc190501.15111503': __('identifierSchemeCodeUnspsc190501.15111503'),
	'identifierSchemeCodeUnspsc190501.15111504': __('identifierSchemeCodeUnspsc190501.15111504'),
	'identifierSchemeCodeUnspsc190501.15111505': __('identifierSchemeCodeUnspsc190501.15111505'),
	'identifierSchemeCodeUnspsc190501.15111506': __('identifierSchemeCodeUnspsc190501.15111506'),
	'identifierSchemeCodeUnspsc190501.15111507': __('identifierSchemeCodeUnspsc190501.15111507'),
	'identifierSchemeCodeUnspsc190501.15111508': __('identifierSchemeCodeUnspsc190501.15111508'),
	'identifierSchemeCodeUnspsc190501.15111509': __('identifierSchemeCodeUnspsc190501.15111509'),
	'identifierSchemeCodeUnspsc190501.15111510': __('identifierSchemeCodeUnspsc190501.15111510'),
	'identifierSchemeCodeUnspsc190501.15111511': __('identifierSchemeCodeUnspsc190501.15111511'),
	'identifierSchemeCodeUnspsc190501.15111512': __('identifierSchemeCodeUnspsc190501.15111512'),
	'identifierSchemeCodeUnspsc190501.15111700': __('identifierSchemeCodeUnspsc190501.15111700'),
	'identifierSchemeCodeUnspsc190501.15111701': __('identifierSchemeCodeUnspsc190501.15111701'),
	'identifierSchemeCodeUnspsc190501.15111702': __('identifierSchemeCodeUnspsc190501.15111702'),
	'identifierSchemeCodeUnspsc190501.15111703': __('identifierSchemeCodeUnspsc190501.15111703'),
	'identifierSchemeCodeUnspsc190501.15111704': __('identifierSchemeCodeUnspsc190501.15111704'),
	'identifierSchemeCodeUnspsc190501.15111705': __('identifierSchemeCodeUnspsc190501.15111705'),
	'identifierSchemeCodeUnspsc190501.15111706': __('identifierSchemeCodeUnspsc190501.15111706'),
	'identifierSchemeCodeUnspsc190501.15111707': __('identifierSchemeCodeUnspsc190501.15111707'),
	'identifierSchemeCodeUnspsc190501.15120000': __('identifierSchemeCodeUnspsc190501.15120000'),
	'identifierSchemeCodeUnspsc190501.15121500': __('identifierSchemeCodeUnspsc190501.15121500'),
	'identifierSchemeCodeUnspsc190501.15121501': __('identifierSchemeCodeUnspsc190501.15121501'),
	'identifierSchemeCodeUnspsc190501.15121502': __('identifierSchemeCodeUnspsc190501.15121502'),
	'identifierSchemeCodeUnspsc190501.15121503': __('identifierSchemeCodeUnspsc190501.15121503'),
	'identifierSchemeCodeUnspsc190501.15121504': __('identifierSchemeCodeUnspsc190501.15121504'),
	'identifierSchemeCodeUnspsc190501.15121505': __('identifierSchemeCodeUnspsc190501.15121505'),
	'identifierSchemeCodeUnspsc190501.15121508': __('identifierSchemeCodeUnspsc190501.15121508'),
	'identifierSchemeCodeUnspsc190501.15121509': __('identifierSchemeCodeUnspsc190501.15121509'),
	'identifierSchemeCodeUnspsc190501.15121510': __('identifierSchemeCodeUnspsc190501.15121510'),
	'identifierSchemeCodeUnspsc190501.15121511': __('identifierSchemeCodeUnspsc190501.15121511'),
	'identifierSchemeCodeUnspsc190501.15121512': __('identifierSchemeCodeUnspsc190501.15121512'),
	'identifierSchemeCodeUnspsc190501.15121513': __('identifierSchemeCodeUnspsc190501.15121513'),
	'identifierSchemeCodeUnspsc190501.15121514': __('identifierSchemeCodeUnspsc190501.15121514'),
	'identifierSchemeCodeUnspsc190501.15121515': __('identifierSchemeCodeUnspsc190501.15121515'),
	'identifierSchemeCodeUnspsc190501.15121516': __('identifierSchemeCodeUnspsc190501.15121516'),
	'identifierSchemeCodeUnspsc190501.15121517': __('identifierSchemeCodeUnspsc190501.15121517'),
	'identifierSchemeCodeUnspsc190501.15121518': __('identifierSchemeCodeUnspsc190501.15121518'),
	'identifierSchemeCodeUnspsc190501.15121519': __('identifierSchemeCodeUnspsc190501.15121519'),
	'identifierSchemeCodeUnspsc190501.15121520': __('identifierSchemeCodeUnspsc190501.15121520'),
	'identifierSchemeCodeUnspsc190501.15121521': __('identifierSchemeCodeUnspsc190501.15121521'),
	'identifierSchemeCodeUnspsc190501.15121522': __('identifierSchemeCodeUnspsc190501.15121522'),
	'identifierSchemeCodeUnspsc190501.15121523': __('identifierSchemeCodeUnspsc190501.15121523'),
	'identifierSchemeCodeUnspsc190501.15121524': __('identifierSchemeCodeUnspsc190501.15121524'),
	'identifierSchemeCodeUnspsc190501.15121525': __('identifierSchemeCodeUnspsc190501.15121525'),
	'identifierSchemeCodeUnspsc190501.15121526': __('identifierSchemeCodeUnspsc190501.15121526'),
	'identifierSchemeCodeUnspsc190501.15121527': __('identifierSchemeCodeUnspsc190501.15121527'),
	'identifierSchemeCodeUnspsc190501.15121528': __('identifierSchemeCodeUnspsc190501.15121528'),
	'identifierSchemeCodeUnspsc190501.15121529': __('identifierSchemeCodeUnspsc190501.15121529'),
	'identifierSchemeCodeUnspsc190501.15121530': __('identifierSchemeCodeUnspsc190501.15121530'),
	'identifierSchemeCodeUnspsc190501.15121800': __('identifierSchemeCodeUnspsc190501.15121800'),
	'identifierSchemeCodeUnspsc190501.15121801': __('identifierSchemeCodeUnspsc190501.15121801'),
	'identifierSchemeCodeUnspsc190501.15121802': __('identifierSchemeCodeUnspsc190501.15121802'),
	'identifierSchemeCodeUnspsc190501.15121803': __('identifierSchemeCodeUnspsc190501.15121803'),
	'identifierSchemeCodeUnspsc190501.15121804': __('identifierSchemeCodeUnspsc190501.15121804'),
	'identifierSchemeCodeUnspsc190501.15121805': __('identifierSchemeCodeUnspsc190501.15121805'),
	'identifierSchemeCodeUnspsc190501.15121806': __('identifierSchemeCodeUnspsc190501.15121806'),
	'identifierSchemeCodeUnspsc190501.15121807': __('identifierSchemeCodeUnspsc190501.15121807'),
	'identifierSchemeCodeUnspsc190501.15121900': __('identifierSchemeCodeUnspsc190501.15121900'),
	'identifierSchemeCodeUnspsc190501.15121901': __('identifierSchemeCodeUnspsc190501.15121901'),
	'identifierSchemeCodeUnspsc190501.15121902': __('identifierSchemeCodeUnspsc190501.15121902'),
	'identifierSchemeCodeUnspsc190501.15121903': __('identifierSchemeCodeUnspsc190501.15121903'),
	'identifierSchemeCodeUnspsc190501.15121904': __('identifierSchemeCodeUnspsc190501.15121904'),
	'identifierSchemeCodeUnspsc190501.15121905': __('identifierSchemeCodeUnspsc190501.15121905'),
	'identifierSchemeCodeUnspsc190501.15130000': __('identifierSchemeCodeUnspsc190501.15130000'),
	'identifierSchemeCodeUnspsc190501.15131500': __('identifierSchemeCodeUnspsc190501.15131500'),
	'identifierSchemeCodeUnspsc190501.15131502': __('identifierSchemeCodeUnspsc190501.15131502'),
	'identifierSchemeCodeUnspsc190501.15131503': __('identifierSchemeCodeUnspsc190501.15131503'),
	'identifierSchemeCodeUnspsc190501.15131504': __('identifierSchemeCodeUnspsc190501.15131504'),
	'identifierSchemeCodeUnspsc190501.15131505': __('identifierSchemeCodeUnspsc190501.15131505'),
	'identifierSchemeCodeUnspsc190501.15131506': __('identifierSchemeCodeUnspsc190501.15131506'),
	'identifierSchemeCodeUnspsc190501.15131600': __('identifierSchemeCodeUnspsc190501.15131600'),
	'identifierSchemeCodeUnspsc190501.15131601': __('identifierSchemeCodeUnspsc190501.15131601'),
	'identifierSchemeCodeUnspsc190501.15131602': __('identifierSchemeCodeUnspsc190501.15131602'),
	'identifierSchemeCodeUnspsc190501.20000000': __('identifierSchemeCodeUnspsc190501.20000000'),
	'identifierSchemeCodeUnspsc190501.20100000': __('identifierSchemeCodeUnspsc190501.20100000'),
	'identifierSchemeCodeUnspsc190501.20101500': __('identifierSchemeCodeUnspsc190501.20101500'),
	'identifierSchemeCodeUnspsc190501.20101501': __('identifierSchemeCodeUnspsc190501.20101501'),
	'identifierSchemeCodeUnspsc190501.20101502': __('identifierSchemeCodeUnspsc190501.20101502'),
	'identifierSchemeCodeUnspsc190501.20101503': __('identifierSchemeCodeUnspsc190501.20101503'),
	'identifierSchemeCodeUnspsc190501.20101504': __('identifierSchemeCodeUnspsc190501.20101504'),
	'identifierSchemeCodeUnspsc190501.20101505': __('identifierSchemeCodeUnspsc190501.20101505'),
	'identifierSchemeCodeUnspsc190501.20101506': __('identifierSchemeCodeUnspsc190501.20101506'),
	'identifierSchemeCodeUnspsc190501.20101600': __('identifierSchemeCodeUnspsc190501.20101600'),
	'identifierSchemeCodeUnspsc190501.20101601': __('identifierSchemeCodeUnspsc190501.20101601'),
	'identifierSchemeCodeUnspsc190501.20101602': __('identifierSchemeCodeUnspsc190501.20101602'),
	'identifierSchemeCodeUnspsc190501.20101603': __('identifierSchemeCodeUnspsc190501.20101603'),
	'identifierSchemeCodeUnspsc190501.20101617': __('identifierSchemeCodeUnspsc190501.20101617'),
	'identifierSchemeCodeUnspsc190501.20101618': __('identifierSchemeCodeUnspsc190501.20101618'),
	'identifierSchemeCodeUnspsc190501.20101619': __('identifierSchemeCodeUnspsc190501.20101619'),
	'identifierSchemeCodeUnspsc190501.20101620': __('identifierSchemeCodeUnspsc190501.20101620'),
	'identifierSchemeCodeUnspsc190501.20101621': __('identifierSchemeCodeUnspsc190501.20101621'),
	'identifierSchemeCodeUnspsc190501.20101700': __('identifierSchemeCodeUnspsc190501.20101700'),
	'identifierSchemeCodeUnspsc190501.20101701': __('identifierSchemeCodeUnspsc190501.20101701'),
	'identifierSchemeCodeUnspsc190501.20101702': __('identifierSchemeCodeUnspsc190501.20101702'),
	'identifierSchemeCodeUnspsc190501.20101703': __('identifierSchemeCodeUnspsc190501.20101703'),
	'identifierSchemeCodeUnspsc190501.20101704': __('identifierSchemeCodeUnspsc190501.20101704'),
	'identifierSchemeCodeUnspsc190501.20101705': __('identifierSchemeCodeUnspsc190501.20101705'),
	'identifierSchemeCodeUnspsc190501.20101706': __('identifierSchemeCodeUnspsc190501.20101706'),
	'identifierSchemeCodeUnspsc190501.20101707': __('identifierSchemeCodeUnspsc190501.20101707'),
	'identifierSchemeCodeUnspsc190501.20101708': __('identifierSchemeCodeUnspsc190501.20101708'),
	'identifierSchemeCodeUnspsc190501.20101709': __('identifierSchemeCodeUnspsc190501.20101709'),
	'identifierSchemeCodeUnspsc190501.20101710': __('identifierSchemeCodeUnspsc190501.20101710'),
	'identifierSchemeCodeUnspsc190501.20101711': __('identifierSchemeCodeUnspsc190501.20101711'),
	'identifierSchemeCodeUnspsc190501.20101712': __('identifierSchemeCodeUnspsc190501.20101712'),
	'identifierSchemeCodeUnspsc190501.20101713': __('identifierSchemeCodeUnspsc190501.20101713'),
	'identifierSchemeCodeUnspsc190501.20101714': __('identifierSchemeCodeUnspsc190501.20101714'),
	'identifierSchemeCodeUnspsc190501.20101715': __('identifierSchemeCodeUnspsc190501.20101715'),
	'identifierSchemeCodeUnspsc190501.20101716': __('identifierSchemeCodeUnspsc190501.20101716'),
	'identifierSchemeCodeUnspsc190501.20101800': __('identifierSchemeCodeUnspsc190501.20101800'),
	'identifierSchemeCodeUnspsc190501.20101801': __('identifierSchemeCodeUnspsc190501.20101801'),
	'identifierSchemeCodeUnspsc190501.20101802': __('identifierSchemeCodeUnspsc190501.20101802'),
	'identifierSchemeCodeUnspsc190501.20101803': __('identifierSchemeCodeUnspsc190501.20101803'),
	'identifierSchemeCodeUnspsc190501.20101804': __('identifierSchemeCodeUnspsc190501.20101804'),
	'identifierSchemeCodeUnspsc190501.20101805': __('identifierSchemeCodeUnspsc190501.20101805'),
	'identifierSchemeCodeUnspsc190501.20101810': __('identifierSchemeCodeUnspsc190501.20101810'),
	'identifierSchemeCodeUnspsc190501.20101900': __('identifierSchemeCodeUnspsc190501.20101900'),
	'identifierSchemeCodeUnspsc190501.20101901': __('identifierSchemeCodeUnspsc190501.20101901'),
	'identifierSchemeCodeUnspsc190501.20101902': __('identifierSchemeCodeUnspsc190501.20101902'),
	'identifierSchemeCodeUnspsc190501.20101903': __('identifierSchemeCodeUnspsc190501.20101903'),
	'identifierSchemeCodeUnspsc190501.20102000': __('identifierSchemeCodeUnspsc190501.20102000'),
	'identifierSchemeCodeUnspsc190501.20102001': __('identifierSchemeCodeUnspsc190501.20102001'),
	'identifierSchemeCodeUnspsc190501.20102002': __('identifierSchemeCodeUnspsc190501.20102002'),
	'identifierSchemeCodeUnspsc190501.20102003': __('identifierSchemeCodeUnspsc190501.20102003'),
	'identifierSchemeCodeUnspsc190501.20102004': __('identifierSchemeCodeUnspsc190501.20102004'),
	'identifierSchemeCodeUnspsc190501.20102005': __('identifierSchemeCodeUnspsc190501.20102005'),
	'identifierSchemeCodeUnspsc190501.20102006': __('identifierSchemeCodeUnspsc190501.20102006'),
	'identifierSchemeCodeUnspsc190501.20102007': __('identifierSchemeCodeUnspsc190501.20102007'),
	'identifierSchemeCodeUnspsc190501.20102008': __('identifierSchemeCodeUnspsc190501.20102008'),
	'identifierSchemeCodeUnspsc190501.20102100': __('identifierSchemeCodeUnspsc190501.20102100'),
	'identifierSchemeCodeUnspsc190501.20102101': __('identifierSchemeCodeUnspsc190501.20102101'),
	'identifierSchemeCodeUnspsc190501.20102102': __('identifierSchemeCodeUnspsc190501.20102102'),
	'identifierSchemeCodeUnspsc190501.20102103': __('identifierSchemeCodeUnspsc190501.20102103'),
	'identifierSchemeCodeUnspsc190501.20102104': __('identifierSchemeCodeUnspsc190501.20102104'),
	'identifierSchemeCodeUnspsc190501.20102105': __('identifierSchemeCodeUnspsc190501.20102105'),
	'identifierSchemeCodeUnspsc190501.20102106': __('identifierSchemeCodeUnspsc190501.20102106'),
	'identifierSchemeCodeUnspsc190501.20102200': __('identifierSchemeCodeUnspsc190501.20102200'),
	'identifierSchemeCodeUnspsc190501.20102201': __('identifierSchemeCodeUnspsc190501.20102201'),
	'identifierSchemeCodeUnspsc190501.20102202': __('identifierSchemeCodeUnspsc190501.20102202'),
	'identifierSchemeCodeUnspsc190501.20102203': __('identifierSchemeCodeUnspsc190501.20102203'),
	'identifierSchemeCodeUnspsc190501.20102300': __('identifierSchemeCodeUnspsc190501.20102300'),
	'identifierSchemeCodeUnspsc190501.20102301': __('identifierSchemeCodeUnspsc190501.20102301'),
	'identifierSchemeCodeUnspsc190501.20102302': __('identifierSchemeCodeUnspsc190501.20102302'),
	'identifierSchemeCodeUnspsc190501.20102303': __('identifierSchemeCodeUnspsc190501.20102303'),
	'identifierSchemeCodeUnspsc190501.20102304': __('identifierSchemeCodeUnspsc190501.20102304'),
	'identifierSchemeCodeUnspsc190501.20102305': __('identifierSchemeCodeUnspsc190501.20102305'),
	'identifierSchemeCodeUnspsc190501.20102306': __('identifierSchemeCodeUnspsc190501.20102306'),
	'identifierSchemeCodeUnspsc190501.20102307': __('identifierSchemeCodeUnspsc190501.20102307'),
	'identifierSchemeCodeUnspsc190501.20110000': __('identifierSchemeCodeUnspsc190501.20110000'),
	'identifierSchemeCodeUnspsc190501.20111500': __('identifierSchemeCodeUnspsc190501.20111500'),
	'identifierSchemeCodeUnspsc190501.20111504': __('identifierSchemeCodeUnspsc190501.20111504'),
	'identifierSchemeCodeUnspsc190501.20111505': __('identifierSchemeCodeUnspsc190501.20111505'),
	'identifierSchemeCodeUnspsc190501.20111600': __('identifierSchemeCodeUnspsc190501.20111600'),
	'identifierSchemeCodeUnspsc190501.20111601': __('identifierSchemeCodeUnspsc190501.20111601'),
	'identifierSchemeCodeUnspsc190501.20111602': __('identifierSchemeCodeUnspsc190501.20111602'),
	'identifierSchemeCodeUnspsc190501.20111603': __('identifierSchemeCodeUnspsc190501.20111603'),
	'identifierSchemeCodeUnspsc190501.20111604': __('identifierSchemeCodeUnspsc190501.20111604'),
	'identifierSchemeCodeUnspsc190501.20111606': __('identifierSchemeCodeUnspsc190501.20111606'),
	'identifierSchemeCodeUnspsc190501.20111607': __('identifierSchemeCodeUnspsc190501.20111607'),
	'identifierSchemeCodeUnspsc190501.20111608': __('identifierSchemeCodeUnspsc190501.20111608'),
	'identifierSchemeCodeUnspsc190501.20111609': __('identifierSchemeCodeUnspsc190501.20111609'),
	'identifierSchemeCodeUnspsc190501.20111610': __('identifierSchemeCodeUnspsc190501.20111610'),
	'identifierSchemeCodeUnspsc190501.20111611': __('identifierSchemeCodeUnspsc190501.20111611'),
	'identifierSchemeCodeUnspsc190501.20111612': __('identifierSchemeCodeUnspsc190501.20111612'),
	'identifierSchemeCodeUnspsc190501.20111613': __('identifierSchemeCodeUnspsc190501.20111613'),
	'identifierSchemeCodeUnspsc190501.20111614': __('identifierSchemeCodeUnspsc190501.20111614'),
	'identifierSchemeCodeUnspsc190501.20111615': __('identifierSchemeCodeUnspsc190501.20111615'),
	'identifierSchemeCodeUnspsc190501.20111616': __('identifierSchemeCodeUnspsc190501.20111616'),
	'identifierSchemeCodeUnspsc190501.20111617': __('identifierSchemeCodeUnspsc190501.20111617'),
	'identifierSchemeCodeUnspsc190501.20111618': __('identifierSchemeCodeUnspsc190501.20111618'),
	'identifierSchemeCodeUnspsc190501.20111619': __('identifierSchemeCodeUnspsc190501.20111619'),
	'identifierSchemeCodeUnspsc190501.20111620': __('identifierSchemeCodeUnspsc190501.20111620'),
	'identifierSchemeCodeUnspsc190501.20111621': __('identifierSchemeCodeUnspsc190501.20111621'),
	'identifierSchemeCodeUnspsc190501.20111622': __('identifierSchemeCodeUnspsc190501.20111622'),
	'identifierSchemeCodeUnspsc190501.20111623': __('identifierSchemeCodeUnspsc190501.20111623'),
	'identifierSchemeCodeUnspsc190501.20111700': __('identifierSchemeCodeUnspsc190501.20111700'),
	'identifierSchemeCodeUnspsc190501.20111701': __('identifierSchemeCodeUnspsc190501.20111701'),
	'identifierSchemeCodeUnspsc190501.20111702': __('identifierSchemeCodeUnspsc190501.20111702'),
	'identifierSchemeCodeUnspsc190501.20111703': __('identifierSchemeCodeUnspsc190501.20111703'),
	'identifierSchemeCodeUnspsc190501.20111704': __('identifierSchemeCodeUnspsc190501.20111704'),
	'identifierSchemeCodeUnspsc190501.20111705': __('identifierSchemeCodeUnspsc190501.20111705'),
	'identifierSchemeCodeUnspsc190501.20111706': __('identifierSchemeCodeUnspsc190501.20111706'),
	'identifierSchemeCodeUnspsc190501.20111707': __('identifierSchemeCodeUnspsc190501.20111707'),
	'identifierSchemeCodeUnspsc190501.20111708': __('identifierSchemeCodeUnspsc190501.20111708'),
	'identifierSchemeCodeUnspsc190501.20111709': __('identifierSchemeCodeUnspsc190501.20111709'),
	'identifierSchemeCodeUnspsc190501.20111710': __('identifierSchemeCodeUnspsc190501.20111710'),
	'identifierSchemeCodeUnspsc190501.20111711': __('identifierSchemeCodeUnspsc190501.20111711'),
	'identifierSchemeCodeUnspsc190501.20111712': __('identifierSchemeCodeUnspsc190501.20111712'),
	'identifierSchemeCodeUnspsc190501.20111713': __('identifierSchemeCodeUnspsc190501.20111713'),
	'identifierSchemeCodeUnspsc190501.20111714': __('identifierSchemeCodeUnspsc190501.20111714'),
	'identifierSchemeCodeUnspsc190501.20111715': __('identifierSchemeCodeUnspsc190501.20111715'),
	'identifierSchemeCodeUnspsc190501.20120000': __('identifierSchemeCodeUnspsc190501.20120000'),
	'identifierSchemeCodeUnspsc190501.20121000': __('identifierSchemeCodeUnspsc190501.20121000'),
	'identifierSchemeCodeUnspsc190501.20121001': __('identifierSchemeCodeUnspsc190501.20121001'),
	'identifierSchemeCodeUnspsc190501.20121002': __('identifierSchemeCodeUnspsc190501.20121002'),
	'identifierSchemeCodeUnspsc190501.20121003': __('identifierSchemeCodeUnspsc190501.20121003'),
	'identifierSchemeCodeUnspsc190501.20121004': __('identifierSchemeCodeUnspsc190501.20121004'),
	'identifierSchemeCodeUnspsc190501.20121005': __('identifierSchemeCodeUnspsc190501.20121005'),
	'identifierSchemeCodeUnspsc190501.20121006': __('identifierSchemeCodeUnspsc190501.20121006'),
	'identifierSchemeCodeUnspsc190501.20121007': __('identifierSchemeCodeUnspsc190501.20121007'),
	'identifierSchemeCodeUnspsc190501.20121008': __('identifierSchemeCodeUnspsc190501.20121008'),
	'identifierSchemeCodeUnspsc190501.20121009': __('identifierSchemeCodeUnspsc190501.20121009'),
	'identifierSchemeCodeUnspsc190501.20121010': __('identifierSchemeCodeUnspsc190501.20121010'),
	'identifierSchemeCodeUnspsc190501.20121011': __('identifierSchemeCodeUnspsc190501.20121011'),
	'identifierSchemeCodeUnspsc190501.20121012': __('identifierSchemeCodeUnspsc190501.20121012'),
	'identifierSchemeCodeUnspsc190501.20121013': __('identifierSchemeCodeUnspsc190501.20121013'),
	'identifierSchemeCodeUnspsc190501.20121014': __('identifierSchemeCodeUnspsc190501.20121014'),
	'identifierSchemeCodeUnspsc190501.20121015': __('identifierSchemeCodeUnspsc190501.20121015'),
	'identifierSchemeCodeUnspsc190501.20121016': __('identifierSchemeCodeUnspsc190501.20121016'),
	'identifierSchemeCodeUnspsc190501.20121100': __('identifierSchemeCodeUnspsc190501.20121100'),
	'identifierSchemeCodeUnspsc190501.20121101': __('identifierSchemeCodeUnspsc190501.20121101'),
	'identifierSchemeCodeUnspsc190501.20121102': __('identifierSchemeCodeUnspsc190501.20121102'),
	'identifierSchemeCodeUnspsc190501.20121103': __('identifierSchemeCodeUnspsc190501.20121103'),
	'identifierSchemeCodeUnspsc190501.20121104': __('identifierSchemeCodeUnspsc190501.20121104'),
	'identifierSchemeCodeUnspsc190501.20121105': __('identifierSchemeCodeUnspsc190501.20121105'),
	'identifierSchemeCodeUnspsc190501.20121106': __('identifierSchemeCodeUnspsc190501.20121106'),
	'identifierSchemeCodeUnspsc190501.20121107': __('identifierSchemeCodeUnspsc190501.20121107'),
	'identifierSchemeCodeUnspsc190501.20121108': __('identifierSchemeCodeUnspsc190501.20121108'),
	'identifierSchemeCodeUnspsc190501.20121109': __('identifierSchemeCodeUnspsc190501.20121109'),
	'identifierSchemeCodeUnspsc190501.20121110': __('identifierSchemeCodeUnspsc190501.20121110'),
	'identifierSchemeCodeUnspsc190501.20121111': __('identifierSchemeCodeUnspsc190501.20121111'),
	'identifierSchemeCodeUnspsc190501.20121112': __('identifierSchemeCodeUnspsc190501.20121112'),
	'identifierSchemeCodeUnspsc190501.20121113': __('identifierSchemeCodeUnspsc190501.20121113'),
	'identifierSchemeCodeUnspsc190501.20121114': __('identifierSchemeCodeUnspsc190501.20121114'),
	'identifierSchemeCodeUnspsc190501.20121115': __('identifierSchemeCodeUnspsc190501.20121115'),
	'identifierSchemeCodeUnspsc190501.20121116': __('identifierSchemeCodeUnspsc190501.20121116'),
	'identifierSchemeCodeUnspsc190501.20121118': __('identifierSchemeCodeUnspsc190501.20121118'),
	'identifierSchemeCodeUnspsc190501.20121119': __('identifierSchemeCodeUnspsc190501.20121119'),
	'identifierSchemeCodeUnspsc190501.20121120': __('identifierSchemeCodeUnspsc190501.20121120'),
	'identifierSchemeCodeUnspsc190501.20121121': __('identifierSchemeCodeUnspsc190501.20121121'),
	'identifierSchemeCodeUnspsc190501.20121122': __('identifierSchemeCodeUnspsc190501.20121122'),
	'identifierSchemeCodeUnspsc190501.20121123': __('identifierSchemeCodeUnspsc190501.20121123'),
	'identifierSchemeCodeUnspsc190501.20121124': __('identifierSchemeCodeUnspsc190501.20121124'),
	'identifierSchemeCodeUnspsc190501.20121125': __('identifierSchemeCodeUnspsc190501.20121125'),
	'identifierSchemeCodeUnspsc190501.20121126': __('identifierSchemeCodeUnspsc190501.20121126'),
	'identifierSchemeCodeUnspsc190501.20121127': __('identifierSchemeCodeUnspsc190501.20121127'),
	'identifierSchemeCodeUnspsc190501.20121128': __('identifierSchemeCodeUnspsc190501.20121128'),
	'identifierSchemeCodeUnspsc190501.20121129': __('identifierSchemeCodeUnspsc190501.20121129'),
	'identifierSchemeCodeUnspsc190501.20121130': __('identifierSchemeCodeUnspsc190501.20121130'),
	'identifierSchemeCodeUnspsc190501.20121200': __('identifierSchemeCodeUnspsc190501.20121200'),
	'identifierSchemeCodeUnspsc190501.20121201': __('identifierSchemeCodeUnspsc190501.20121201'),
	'identifierSchemeCodeUnspsc190501.20121202': __('identifierSchemeCodeUnspsc190501.20121202'),
	'identifierSchemeCodeUnspsc190501.20121203': __('identifierSchemeCodeUnspsc190501.20121203'),
	'identifierSchemeCodeUnspsc190501.20121204': __('identifierSchemeCodeUnspsc190501.20121204'),
	'identifierSchemeCodeUnspsc190501.20121205': __('identifierSchemeCodeUnspsc190501.20121205'),
	'identifierSchemeCodeUnspsc190501.20121206': __('identifierSchemeCodeUnspsc190501.20121206'),
	'identifierSchemeCodeUnspsc190501.20121207': __('identifierSchemeCodeUnspsc190501.20121207'),
	'identifierSchemeCodeUnspsc190501.20121208': __('identifierSchemeCodeUnspsc190501.20121208'),
	'identifierSchemeCodeUnspsc190501.20121209': __('identifierSchemeCodeUnspsc190501.20121209'),
	'identifierSchemeCodeUnspsc190501.20121210': __('identifierSchemeCodeUnspsc190501.20121210'),
	'identifierSchemeCodeUnspsc190501.20121211': __('identifierSchemeCodeUnspsc190501.20121211'),
	'identifierSchemeCodeUnspsc190501.20121212': __('identifierSchemeCodeUnspsc190501.20121212'),
	'identifierSchemeCodeUnspsc190501.20121213': __('identifierSchemeCodeUnspsc190501.20121213'),
	'identifierSchemeCodeUnspsc190501.20121300': __('identifierSchemeCodeUnspsc190501.20121300'),
	'identifierSchemeCodeUnspsc190501.20121301': __('identifierSchemeCodeUnspsc190501.20121301'),
	'identifierSchemeCodeUnspsc190501.20121302': __('identifierSchemeCodeUnspsc190501.20121302'),
	'identifierSchemeCodeUnspsc190501.20121303': __('identifierSchemeCodeUnspsc190501.20121303'),
	'identifierSchemeCodeUnspsc190501.20121304': __('identifierSchemeCodeUnspsc190501.20121304'),
	'identifierSchemeCodeUnspsc190501.20121305': __('identifierSchemeCodeUnspsc190501.20121305'),
	'identifierSchemeCodeUnspsc190501.20121306': __('identifierSchemeCodeUnspsc190501.20121306'),
	'identifierSchemeCodeUnspsc190501.20121307': __('identifierSchemeCodeUnspsc190501.20121307'),
	'identifierSchemeCodeUnspsc190501.20121308': __('identifierSchemeCodeUnspsc190501.20121308'),
	'identifierSchemeCodeUnspsc190501.20121309': __('identifierSchemeCodeUnspsc190501.20121309'),
	'identifierSchemeCodeUnspsc190501.20121310': __('identifierSchemeCodeUnspsc190501.20121310'),
	'identifierSchemeCodeUnspsc190501.20121311': __('identifierSchemeCodeUnspsc190501.20121311'),
	'identifierSchemeCodeUnspsc190501.20121312': __('identifierSchemeCodeUnspsc190501.20121312'),
	'identifierSchemeCodeUnspsc190501.20121313': __('identifierSchemeCodeUnspsc190501.20121313'),
	'identifierSchemeCodeUnspsc190501.20121314': __('identifierSchemeCodeUnspsc190501.20121314'),
	'identifierSchemeCodeUnspsc190501.20121315': __('identifierSchemeCodeUnspsc190501.20121315'),
	'identifierSchemeCodeUnspsc190501.20121316': __('identifierSchemeCodeUnspsc190501.20121316'),
	'identifierSchemeCodeUnspsc190501.20121317': __('identifierSchemeCodeUnspsc190501.20121317'),
	'identifierSchemeCodeUnspsc190501.20121318': __('identifierSchemeCodeUnspsc190501.20121318'),
	'identifierSchemeCodeUnspsc190501.20121319': __('identifierSchemeCodeUnspsc190501.20121319'),
	'identifierSchemeCodeUnspsc190501.20121320': __('identifierSchemeCodeUnspsc190501.20121320'),
	'identifierSchemeCodeUnspsc190501.20121321': __('identifierSchemeCodeUnspsc190501.20121321'),
	'identifierSchemeCodeUnspsc190501.20121322': __('identifierSchemeCodeUnspsc190501.20121322'),
	'identifierSchemeCodeUnspsc190501.20121323': __('identifierSchemeCodeUnspsc190501.20121323'),
	'identifierSchemeCodeUnspsc190501.20121324': __('identifierSchemeCodeUnspsc190501.20121324'),
	'identifierSchemeCodeUnspsc190501.20121325': __('identifierSchemeCodeUnspsc190501.20121325'),
	'identifierSchemeCodeUnspsc190501.20121326': __('identifierSchemeCodeUnspsc190501.20121326'),
	'identifierSchemeCodeUnspsc190501.20121400': __('identifierSchemeCodeUnspsc190501.20121400'),
	'identifierSchemeCodeUnspsc190501.20121401': __('identifierSchemeCodeUnspsc190501.20121401'),
	'identifierSchemeCodeUnspsc190501.20121402': __('identifierSchemeCodeUnspsc190501.20121402'),
	'identifierSchemeCodeUnspsc190501.20121403': __('identifierSchemeCodeUnspsc190501.20121403'),
	'identifierSchemeCodeUnspsc190501.20121404': __('identifierSchemeCodeUnspsc190501.20121404'),
	'identifierSchemeCodeUnspsc190501.20121405': __('identifierSchemeCodeUnspsc190501.20121405'),
	'identifierSchemeCodeUnspsc190501.20121406': __('identifierSchemeCodeUnspsc190501.20121406'),
	'identifierSchemeCodeUnspsc190501.20121407': __('identifierSchemeCodeUnspsc190501.20121407'),
	'identifierSchemeCodeUnspsc190501.20121408': __('identifierSchemeCodeUnspsc190501.20121408'),
	'identifierSchemeCodeUnspsc190501.20121409': __('identifierSchemeCodeUnspsc190501.20121409'),
	'identifierSchemeCodeUnspsc190501.20121410': __('identifierSchemeCodeUnspsc190501.20121410'),
	'identifierSchemeCodeUnspsc190501.20121411': __('identifierSchemeCodeUnspsc190501.20121411'),
	'identifierSchemeCodeUnspsc190501.20121412': __('identifierSchemeCodeUnspsc190501.20121412'),
	'identifierSchemeCodeUnspsc190501.20121413': __('identifierSchemeCodeUnspsc190501.20121413'),
	'identifierSchemeCodeUnspsc190501.20121414': __('identifierSchemeCodeUnspsc190501.20121414'),
	'identifierSchemeCodeUnspsc190501.20121415': __('identifierSchemeCodeUnspsc190501.20121415'),
	'identifierSchemeCodeUnspsc190501.20121416': __('identifierSchemeCodeUnspsc190501.20121416'),
	'identifierSchemeCodeUnspsc190501.20121417': __('identifierSchemeCodeUnspsc190501.20121417'),
	'identifierSchemeCodeUnspsc190501.20121418': __('identifierSchemeCodeUnspsc190501.20121418'),
	'identifierSchemeCodeUnspsc190501.20121419': __('identifierSchemeCodeUnspsc190501.20121419'),
	'identifierSchemeCodeUnspsc190501.20121420': __('identifierSchemeCodeUnspsc190501.20121420'),
	'identifierSchemeCodeUnspsc190501.20121421': __('identifierSchemeCodeUnspsc190501.20121421'),
	'identifierSchemeCodeUnspsc190501.20121422': __('identifierSchemeCodeUnspsc190501.20121422'),
	'identifierSchemeCodeUnspsc190501.20121423': __('identifierSchemeCodeUnspsc190501.20121423'),
	'identifierSchemeCodeUnspsc190501.20121424': __('identifierSchemeCodeUnspsc190501.20121424'),
	'identifierSchemeCodeUnspsc190501.20121425': __('identifierSchemeCodeUnspsc190501.20121425'),
	'identifierSchemeCodeUnspsc190501.20121427': __('identifierSchemeCodeUnspsc190501.20121427'),
	'identifierSchemeCodeUnspsc190501.20121428': __('identifierSchemeCodeUnspsc190501.20121428'),
	'identifierSchemeCodeUnspsc190501.20121429': __('identifierSchemeCodeUnspsc190501.20121429'),
	'identifierSchemeCodeUnspsc190501.20121430': __('identifierSchemeCodeUnspsc190501.20121430'),
	'identifierSchemeCodeUnspsc190501.20121431': __('identifierSchemeCodeUnspsc190501.20121431'),
	'identifierSchemeCodeUnspsc190501.20121432': __('identifierSchemeCodeUnspsc190501.20121432'),
	'identifierSchemeCodeUnspsc190501.20121433': __('identifierSchemeCodeUnspsc190501.20121433'),
	'identifierSchemeCodeUnspsc190501.20121434': __('identifierSchemeCodeUnspsc190501.20121434'),
	'identifierSchemeCodeUnspsc190501.20121435': __('identifierSchemeCodeUnspsc190501.20121435'),
	'identifierSchemeCodeUnspsc190501.20121436': __('identifierSchemeCodeUnspsc190501.20121436'),
	'identifierSchemeCodeUnspsc190501.20121437': __('identifierSchemeCodeUnspsc190501.20121437'),
	'identifierSchemeCodeUnspsc190501.20121438': __('identifierSchemeCodeUnspsc190501.20121438'),
	'identifierSchemeCodeUnspsc190501.20121439': __('identifierSchemeCodeUnspsc190501.20121439'),
	'identifierSchemeCodeUnspsc190501.20121440': __('identifierSchemeCodeUnspsc190501.20121440'),
	'identifierSchemeCodeUnspsc190501.20121441': __('identifierSchemeCodeUnspsc190501.20121441'),
	'identifierSchemeCodeUnspsc190501.20121442': __('identifierSchemeCodeUnspsc190501.20121442'),
	'identifierSchemeCodeUnspsc190501.20121443': __('identifierSchemeCodeUnspsc190501.20121443'),
	'identifierSchemeCodeUnspsc190501.20121444': __('identifierSchemeCodeUnspsc190501.20121444'),
	'identifierSchemeCodeUnspsc190501.20121445': __('identifierSchemeCodeUnspsc190501.20121445'),
	'identifierSchemeCodeUnspsc190501.20121446': __('identifierSchemeCodeUnspsc190501.20121446'),
	'identifierSchemeCodeUnspsc190501.20121447': __('identifierSchemeCodeUnspsc190501.20121447'),
	'identifierSchemeCodeUnspsc190501.20121448': __('identifierSchemeCodeUnspsc190501.20121448'),
	'identifierSchemeCodeUnspsc190501.20121449': __('identifierSchemeCodeUnspsc190501.20121449'),
	'identifierSchemeCodeUnspsc190501.20121450': __('identifierSchemeCodeUnspsc190501.20121450'),
	'identifierSchemeCodeUnspsc190501.20121451': __('identifierSchemeCodeUnspsc190501.20121451'),
	'identifierSchemeCodeUnspsc190501.20121500': __('identifierSchemeCodeUnspsc190501.20121500'),
	'identifierSchemeCodeUnspsc190501.20121501': __('identifierSchemeCodeUnspsc190501.20121501'),
	'identifierSchemeCodeUnspsc190501.20121502': __('identifierSchemeCodeUnspsc190501.20121502'),
	'identifierSchemeCodeUnspsc190501.20121503': __('identifierSchemeCodeUnspsc190501.20121503'),
	'identifierSchemeCodeUnspsc190501.20121504': __('identifierSchemeCodeUnspsc190501.20121504'),
	'identifierSchemeCodeUnspsc190501.20121505': __('identifierSchemeCodeUnspsc190501.20121505'),
	'identifierSchemeCodeUnspsc190501.20121506': __('identifierSchemeCodeUnspsc190501.20121506'),
	'identifierSchemeCodeUnspsc190501.20121507': __('identifierSchemeCodeUnspsc190501.20121507'),
	'identifierSchemeCodeUnspsc190501.20121508': __('identifierSchemeCodeUnspsc190501.20121508'),
	'identifierSchemeCodeUnspsc190501.20121509': __('identifierSchemeCodeUnspsc190501.20121509'),
	'identifierSchemeCodeUnspsc190501.20121510': __('identifierSchemeCodeUnspsc190501.20121510'),
	'identifierSchemeCodeUnspsc190501.20121511': __('identifierSchemeCodeUnspsc190501.20121511'),
	'identifierSchemeCodeUnspsc190501.20121513': __('identifierSchemeCodeUnspsc190501.20121513'),
	'identifierSchemeCodeUnspsc190501.20121514': __('identifierSchemeCodeUnspsc190501.20121514'),
	'identifierSchemeCodeUnspsc190501.20121515': __('identifierSchemeCodeUnspsc190501.20121515'),
	'identifierSchemeCodeUnspsc190501.20121516': __('identifierSchemeCodeUnspsc190501.20121516'),
	'identifierSchemeCodeUnspsc190501.20121517': __('identifierSchemeCodeUnspsc190501.20121517'),
	'identifierSchemeCodeUnspsc190501.20121518': __('identifierSchemeCodeUnspsc190501.20121518'),
	'identifierSchemeCodeUnspsc190501.20121519': __('identifierSchemeCodeUnspsc190501.20121519'),
	'identifierSchemeCodeUnspsc190501.20121520': __('identifierSchemeCodeUnspsc190501.20121520'),
	'identifierSchemeCodeUnspsc190501.20121521': __('identifierSchemeCodeUnspsc190501.20121521'),
	'identifierSchemeCodeUnspsc190501.20121522': __('identifierSchemeCodeUnspsc190501.20121522'),
	'identifierSchemeCodeUnspsc190501.20121523': __('identifierSchemeCodeUnspsc190501.20121523'),
	'identifierSchemeCodeUnspsc190501.20121524': __('identifierSchemeCodeUnspsc190501.20121524'),
	'identifierSchemeCodeUnspsc190501.20121600': __('identifierSchemeCodeUnspsc190501.20121600'),
	'identifierSchemeCodeUnspsc190501.20121601': __('identifierSchemeCodeUnspsc190501.20121601'),
	'identifierSchemeCodeUnspsc190501.20121602': __('identifierSchemeCodeUnspsc190501.20121602'),
	'identifierSchemeCodeUnspsc190501.20121603': __('identifierSchemeCodeUnspsc190501.20121603'),
	'identifierSchemeCodeUnspsc190501.20121604': __('identifierSchemeCodeUnspsc190501.20121604'),
	'identifierSchemeCodeUnspsc190501.20121605': __('identifierSchemeCodeUnspsc190501.20121605'),
	'identifierSchemeCodeUnspsc190501.20121606': __('identifierSchemeCodeUnspsc190501.20121606'),
	'identifierSchemeCodeUnspsc190501.20121607': __('identifierSchemeCodeUnspsc190501.20121607'),
	'identifierSchemeCodeUnspsc190501.20121608': __('identifierSchemeCodeUnspsc190501.20121608'),
	'identifierSchemeCodeUnspsc190501.20121609': __('identifierSchemeCodeUnspsc190501.20121609'),
	'identifierSchemeCodeUnspsc190501.20121610': __('identifierSchemeCodeUnspsc190501.20121610'),
	'identifierSchemeCodeUnspsc190501.20121611': __('identifierSchemeCodeUnspsc190501.20121611'),
	'identifierSchemeCodeUnspsc190501.20121612': __('identifierSchemeCodeUnspsc190501.20121612'),
	'identifierSchemeCodeUnspsc190501.20121613': __('identifierSchemeCodeUnspsc190501.20121613'),
	'identifierSchemeCodeUnspsc190501.20121700': __('identifierSchemeCodeUnspsc190501.20121700'),
	'identifierSchemeCodeUnspsc190501.20121701': __('identifierSchemeCodeUnspsc190501.20121701'),
	'identifierSchemeCodeUnspsc190501.20121702': __('identifierSchemeCodeUnspsc190501.20121702'),
	'identifierSchemeCodeUnspsc190501.20121703': __('identifierSchemeCodeUnspsc190501.20121703'),
	'identifierSchemeCodeUnspsc190501.20121704': __('identifierSchemeCodeUnspsc190501.20121704'),
	'identifierSchemeCodeUnspsc190501.20121705': __('identifierSchemeCodeUnspsc190501.20121705'),
	'identifierSchemeCodeUnspsc190501.20121706': __('identifierSchemeCodeUnspsc190501.20121706'),
	'identifierSchemeCodeUnspsc190501.20121707': __('identifierSchemeCodeUnspsc190501.20121707'),
	'identifierSchemeCodeUnspsc190501.20121708': __('identifierSchemeCodeUnspsc190501.20121708'),
	'identifierSchemeCodeUnspsc190501.20121709': __('identifierSchemeCodeUnspsc190501.20121709'),
	'identifierSchemeCodeUnspsc190501.20121710': __('identifierSchemeCodeUnspsc190501.20121710'),
	'identifierSchemeCodeUnspsc190501.20121711': __('identifierSchemeCodeUnspsc190501.20121711'),
	'identifierSchemeCodeUnspsc190501.20121712': __('identifierSchemeCodeUnspsc190501.20121712'),
	'identifierSchemeCodeUnspsc190501.20121713': __('identifierSchemeCodeUnspsc190501.20121713'),
	'identifierSchemeCodeUnspsc190501.20121714': __('identifierSchemeCodeUnspsc190501.20121714'),
	'identifierSchemeCodeUnspsc190501.20121715': __('identifierSchemeCodeUnspsc190501.20121715'),
	'identifierSchemeCodeUnspsc190501.20121716': __('identifierSchemeCodeUnspsc190501.20121716'),
	'identifierSchemeCodeUnspsc190501.20121717': __('identifierSchemeCodeUnspsc190501.20121717'),
	'identifierSchemeCodeUnspsc190501.20121718': __('identifierSchemeCodeUnspsc190501.20121718'),
	'identifierSchemeCodeUnspsc190501.20121719': __('identifierSchemeCodeUnspsc190501.20121719'),
	'identifierSchemeCodeUnspsc190501.20121720': __('identifierSchemeCodeUnspsc190501.20121720'),
	'identifierSchemeCodeUnspsc190501.20121721': __('identifierSchemeCodeUnspsc190501.20121721'),
	'identifierSchemeCodeUnspsc190501.20121722': __('identifierSchemeCodeUnspsc190501.20121722'),
	'identifierSchemeCodeUnspsc190501.20121723': __('identifierSchemeCodeUnspsc190501.20121723'),
	'identifierSchemeCodeUnspsc190501.20121724': __('identifierSchemeCodeUnspsc190501.20121724'),
	'identifierSchemeCodeUnspsc190501.20121725': __('identifierSchemeCodeUnspsc190501.20121725'),
	'identifierSchemeCodeUnspsc190501.20121726': __('identifierSchemeCodeUnspsc190501.20121726'),
	'identifierSchemeCodeUnspsc190501.20121727': __('identifierSchemeCodeUnspsc190501.20121727'),
	'identifierSchemeCodeUnspsc190501.20121728': __('identifierSchemeCodeUnspsc190501.20121728'),
	'identifierSchemeCodeUnspsc190501.20121800': __('identifierSchemeCodeUnspsc190501.20121800'),
	'identifierSchemeCodeUnspsc190501.20121801': __('identifierSchemeCodeUnspsc190501.20121801'),
	'identifierSchemeCodeUnspsc190501.20121802': __('identifierSchemeCodeUnspsc190501.20121802'),
	'identifierSchemeCodeUnspsc190501.20121803': __('identifierSchemeCodeUnspsc190501.20121803'),
	'identifierSchemeCodeUnspsc190501.20121804': __('identifierSchemeCodeUnspsc190501.20121804'),
	'identifierSchemeCodeUnspsc190501.20121805': __('identifierSchemeCodeUnspsc190501.20121805'),
	'identifierSchemeCodeUnspsc190501.20121806': __('identifierSchemeCodeUnspsc190501.20121806'),
	'identifierSchemeCodeUnspsc190501.20121807': __('identifierSchemeCodeUnspsc190501.20121807'),
	'identifierSchemeCodeUnspsc190501.20121808': __('identifierSchemeCodeUnspsc190501.20121808'),
	'identifierSchemeCodeUnspsc190501.20121809': __('identifierSchemeCodeUnspsc190501.20121809'),
	'identifierSchemeCodeUnspsc190501.20121810': __('identifierSchemeCodeUnspsc190501.20121810'),
	'identifierSchemeCodeUnspsc190501.20121811': __('identifierSchemeCodeUnspsc190501.20121811'),
	'identifierSchemeCodeUnspsc190501.20121812': __('identifierSchemeCodeUnspsc190501.20121812'),
	'identifierSchemeCodeUnspsc190501.20121813': __('identifierSchemeCodeUnspsc190501.20121813'),
	'identifierSchemeCodeUnspsc190501.20121900': __('identifierSchemeCodeUnspsc190501.20121900'),
	'identifierSchemeCodeUnspsc190501.20121901': __('identifierSchemeCodeUnspsc190501.20121901'),
	'identifierSchemeCodeUnspsc190501.20121902': __('identifierSchemeCodeUnspsc190501.20121902'),
	'identifierSchemeCodeUnspsc190501.20121903': __('identifierSchemeCodeUnspsc190501.20121903'),
	'identifierSchemeCodeUnspsc190501.20121904': __('identifierSchemeCodeUnspsc190501.20121904'),
	'identifierSchemeCodeUnspsc190501.20121905': __('identifierSchemeCodeUnspsc190501.20121905'),
	'identifierSchemeCodeUnspsc190501.20121906': __('identifierSchemeCodeUnspsc190501.20121906'),
	'identifierSchemeCodeUnspsc190501.20121907': __('identifierSchemeCodeUnspsc190501.20121907'),
	'identifierSchemeCodeUnspsc190501.20121908': __('identifierSchemeCodeUnspsc190501.20121908'),
	'identifierSchemeCodeUnspsc190501.20121909': __('identifierSchemeCodeUnspsc190501.20121909'),
	'identifierSchemeCodeUnspsc190501.20121910': __('identifierSchemeCodeUnspsc190501.20121910'),
	'identifierSchemeCodeUnspsc190501.20121911': __('identifierSchemeCodeUnspsc190501.20121911'),
	'identifierSchemeCodeUnspsc190501.20121912': __('identifierSchemeCodeUnspsc190501.20121912'),
	'identifierSchemeCodeUnspsc190501.20121913': __('identifierSchemeCodeUnspsc190501.20121913'),
	'identifierSchemeCodeUnspsc190501.20121914': __('identifierSchemeCodeUnspsc190501.20121914'),
	'identifierSchemeCodeUnspsc190501.20121915': __('identifierSchemeCodeUnspsc190501.20121915'),
	'identifierSchemeCodeUnspsc190501.20121916': __('identifierSchemeCodeUnspsc190501.20121916'),
	'identifierSchemeCodeUnspsc190501.20121917': __('identifierSchemeCodeUnspsc190501.20121917'),
	'identifierSchemeCodeUnspsc190501.20121918': __('identifierSchemeCodeUnspsc190501.20121918'),
	'identifierSchemeCodeUnspsc190501.20121919': __('identifierSchemeCodeUnspsc190501.20121919'),
	'identifierSchemeCodeUnspsc190501.20121920': __('identifierSchemeCodeUnspsc190501.20121920'),
	'identifierSchemeCodeUnspsc190501.20121921': __('identifierSchemeCodeUnspsc190501.20121921'),
	'identifierSchemeCodeUnspsc190501.20121922': __('identifierSchemeCodeUnspsc190501.20121922'),
	'identifierSchemeCodeUnspsc190501.20121923': __('identifierSchemeCodeUnspsc190501.20121923'),
	'identifierSchemeCodeUnspsc190501.20122000': __('identifierSchemeCodeUnspsc190501.20122000'),
	'identifierSchemeCodeUnspsc190501.20122001': __('identifierSchemeCodeUnspsc190501.20122001'),
	'identifierSchemeCodeUnspsc190501.20122002': __('identifierSchemeCodeUnspsc190501.20122002'),
	'identifierSchemeCodeUnspsc190501.20122003': __('identifierSchemeCodeUnspsc190501.20122003'),
	'identifierSchemeCodeUnspsc190501.20122004': __('identifierSchemeCodeUnspsc190501.20122004'),
	'identifierSchemeCodeUnspsc190501.20122005': __('identifierSchemeCodeUnspsc190501.20122005'),
	'identifierSchemeCodeUnspsc190501.20122006': __('identifierSchemeCodeUnspsc190501.20122006'),
	'identifierSchemeCodeUnspsc190501.20122100': __('identifierSchemeCodeUnspsc190501.20122100'),
	'identifierSchemeCodeUnspsc190501.20122101': __('identifierSchemeCodeUnspsc190501.20122101'),
	'identifierSchemeCodeUnspsc190501.20122102': __('identifierSchemeCodeUnspsc190501.20122102'),
	'identifierSchemeCodeUnspsc190501.20122103': __('identifierSchemeCodeUnspsc190501.20122103'),
	'identifierSchemeCodeUnspsc190501.20122104': __('identifierSchemeCodeUnspsc190501.20122104'),
	'identifierSchemeCodeUnspsc190501.20122105': __('identifierSchemeCodeUnspsc190501.20122105'),
	'identifierSchemeCodeUnspsc190501.20122106': __('identifierSchemeCodeUnspsc190501.20122106'),
	'identifierSchemeCodeUnspsc190501.20122107': __('identifierSchemeCodeUnspsc190501.20122107'),
	'identifierSchemeCodeUnspsc190501.20122108': __('identifierSchemeCodeUnspsc190501.20122108'),
	'identifierSchemeCodeUnspsc190501.20122109': __('identifierSchemeCodeUnspsc190501.20122109'),
	'identifierSchemeCodeUnspsc190501.20122110': __('identifierSchemeCodeUnspsc190501.20122110'),
	'identifierSchemeCodeUnspsc190501.20122111': __('identifierSchemeCodeUnspsc190501.20122111'),
	'identifierSchemeCodeUnspsc190501.20122112': __('identifierSchemeCodeUnspsc190501.20122112'),
	'identifierSchemeCodeUnspsc190501.20122113': __('identifierSchemeCodeUnspsc190501.20122113'),
	'identifierSchemeCodeUnspsc190501.20122114': __('identifierSchemeCodeUnspsc190501.20122114'),
	'identifierSchemeCodeUnspsc190501.20122115': __('identifierSchemeCodeUnspsc190501.20122115'),
	'identifierSchemeCodeUnspsc190501.20122200': __('identifierSchemeCodeUnspsc190501.20122200'),
	'identifierSchemeCodeUnspsc190501.20122201': __('identifierSchemeCodeUnspsc190501.20122201'),
	'identifierSchemeCodeUnspsc190501.20122202': __('identifierSchemeCodeUnspsc190501.20122202'),
	'identifierSchemeCodeUnspsc190501.20122203': __('identifierSchemeCodeUnspsc190501.20122203'),
	'identifierSchemeCodeUnspsc190501.20122204': __('identifierSchemeCodeUnspsc190501.20122204'),
	'identifierSchemeCodeUnspsc190501.20122205': __('identifierSchemeCodeUnspsc190501.20122205'),
	'identifierSchemeCodeUnspsc190501.20122206': __('identifierSchemeCodeUnspsc190501.20122206'),
	'identifierSchemeCodeUnspsc190501.20122207': __('identifierSchemeCodeUnspsc190501.20122207'),
	'identifierSchemeCodeUnspsc190501.20122208': __('identifierSchemeCodeUnspsc190501.20122208'),
	'identifierSchemeCodeUnspsc190501.20122209': __('identifierSchemeCodeUnspsc190501.20122209'),
	'identifierSchemeCodeUnspsc190501.20122210': __('identifierSchemeCodeUnspsc190501.20122210'),
	'identifierSchemeCodeUnspsc190501.20122211': __('identifierSchemeCodeUnspsc190501.20122211'),
	'identifierSchemeCodeUnspsc190501.20122212': __('identifierSchemeCodeUnspsc190501.20122212'),
	'identifierSchemeCodeUnspsc190501.20122213': __('identifierSchemeCodeUnspsc190501.20122213'),
	'identifierSchemeCodeUnspsc190501.20122214': __('identifierSchemeCodeUnspsc190501.20122214'),
	'identifierSchemeCodeUnspsc190501.20122215': __('identifierSchemeCodeUnspsc190501.20122215'),
	'identifierSchemeCodeUnspsc190501.20122216': __('identifierSchemeCodeUnspsc190501.20122216'),
	'identifierSchemeCodeUnspsc190501.20122300': __('identifierSchemeCodeUnspsc190501.20122300'),
	'identifierSchemeCodeUnspsc190501.20122301': __('identifierSchemeCodeUnspsc190501.20122301'),
	'identifierSchemeCodeUnspsc190501.20122302': __('identifierSchemeCodeUnspsc190501.20122302'),
	'identifierSchemeCodeUnspsc190501.20122303': __('identifierSchemeCodeUnspsc190501.20122303'),
	'identifierSchemeCodeUnspsc190501.20122304': __('identifierSchemeCodeUnspsc190501.20122304'),
	'identifierSchemeCodeUnspsc190501.20122305': __('identifierSchemeCodeUnspsc190501.20122305'),
	'identifierSchemeCodeUnspsc190501.20122306': __('identifierSchemeCodeUnspsc190501.20122306'),
	'identifierSchemeCodeUnspsc190501.20122307': __('identifierSchemeCodeUnspsc190501.20122307'),
	'identifierSchemeCodeUnspsc190501.20122308': __('identifierSchemeCodeUnspsc190501.20122308'),
	'identifierSchemeCodeUnspsc190501.20122309': __('identifierSchemeCodeUnspsc190501.20122309'),
	'identifierSchemeCodeUnspsc190501.20122310': __('identifierSchemeCodeUnspsc190501.20122310'),
	'identifierSchemeCodeUnspsc190501.20122311': __('identifierSchemeCodeUnspsc190501.20122311'),
	'identifierSchemeCodeUnspsc190501.20122312': __('identifierSchemeCodeUnspsc190501.20122312'),
	'identifierSchemeCodeUnspsc190501.20122313': __('identifierSchemeCodeUnspsc190501.20122313'),
	'identifierSchemeCodeUnspsc190501.20122314': __('identifierSchemeCodeUnspsc190501.20122314'),
	'identifierSchemeCodeUnspsc190501.20122315': __('identifierSchemeCodeUnspsc190501.20122315'),
	'identifierSchemeCodeUnspsc190501.20122316': __('identifierSchemeCodeUnspsc190501.20122316'),
	'identifierSchemeCodeUnspsc190501.20122317': __('identifierSchemeCodeUnspsc190501.20122317'),
	'identifierSchemeCodeUnspsc190501.20122318': __('identifierSchemeCodeUnspsc190501.20122318'),
	'identifierSchemeCodeUnspsc190501.20122319': __('identifierSchemeCodeUnspsc190501.20122319'),
	'identifierSchemeCodeUnspsc190501.20122320': __('identifierSchemeCodeUnspsc190501.20122320'),
	'identifierSchemeCodeUnspsc190501.20122321': __('identifierSchemeCodeUnspsc190501.20122321'),
	'identifierSchemeCodeUnspsc190501.20122322': __('identifierSchemeCodeUnspsc190501.20122322'),
	'identifierSchemeCodeUnspsc190501.20122323': __('identifierSchemeCodeUnspsc190501.20122323'),
	'identifierSchemeCodeUnspsc190501.20122324': __('identifierSchemeCodeUnspsc190501.20122324'),
	'identifierSchemeCodeUnspsc190501.20122325': __('identifierSchemeCodeUnspsc190501.20122325'),
	'identifierSchemeCodeUnspsc190501.20122326': __('identifierSchemeCodeUnspsc190501.20122326'),
	'identifierSchemeCodeUnspsc190501.20122327': __('identifierSchemeCodeUnspsc190501.20122327'),
	'identifierSchemeCodeUnspsc190501.20122328': __('identifierSchemeCodeUnspsc190501.20122328'),
	'identifierSchemeCodeUnspsc190501.20122329': __('identifierSchemeCodeUnspsc190501.20122329'),
	'identifierSchemeCodeUnspsc190501.20122330': __('identifierSchemeCodeUnspsc190501.20122330'),
	'identifierSchemeCodeUnspsc190501.20122331': __('identifierSchemeCodeUnspsc190501.20122331'),
	'identifierSchemeCodeUnspsc190501.20122332': __('identifierSchemeCodeUnspsc190501.20122332'),
	'identifierSchemeCodeUnspsc190501.20122333': __('identifierSchemeCodeUnspsc190501.20122333'),
	'identifierSchemeCodeUnspsc190501.20122334': __('identifierSchemeCodeUnspsc190501.20122334'),
	'identifierSchemeCodeUnspsc190501.20122335': __('identifierSchemeCodeUnspsc190501.20122335'),
	'identifierSchemeCodeUnspsc190501.20122336': __('identifierSchemeCodeUnspsc190501.20122336'),
	'identifierSchemeCodeUnspsc190501.20122338': __('identifierSchemeCodeUnspsc190501.20122338'),
	'identifierSchemeCodeUnspsc190501.20122339': __('identifierSchemeCodeUnspsc190501.20122339'),
	'identifierSchemeCodeUnspsc190501.20122340': __('identifierSchemeCodeUnspsc190501.20122340'),
	'identifierSchemeCodeUnspsc190501.20122341': __('identifierSchemeCodeUnspsc190501.20122341'),
	'identifierSchemeCodeUnspsc190501.20122342': __('identifierSchemeCodeUnspsc190501.20122342'),
	'identifierSchemeCodeUnspsc190501.20122343': __('identifierSchemeCodeUnspsc190501.20122343'),
	'identifierSchemeCodeUnspsc190501.20122344': __('identifierSchemeCodeUnspsc190501.20122344'),
	'identifierSchemeCodeUnspsc190501.20122345': __('identifierSchemeCodeUnspsc190501.20122345'),
	'identifierSchemeCodeUnspsc190501.20122346': __('identifierSchemeCodeUnspsc190501.20122346'),
	'identifierSchemeCodeUnspsc190501.20122347': __('identifierSchemeCodeUnspsc190501.20122347'),
	'identifierSchemeCodeUnspsc190501.20122348': __('identifierSchemeCodeUnspsc190501.20122348'),
	'identifierSchemeCodeUnspsc190501.20122349': __('identifierSchemeCodeUnspsc190501.20122349'),
	'identifierSchemeCodeUnspsc190501.20122350': __('identifierSchemeCodeUnspsc190501.20122350'),
	'identifierSchemeCodeUnspsc190501.20122351': __('identifierSchemeCodeUnspsc190501.20122351'),
	'identifierSchemeCodeUnspsc190501.20122352': __('identifierSchemeCodeUnspsc190501.20122352'),
	'identifierSchemeCodeUnspsc190501.20122353': __('identifierSchemeCodeUnspsc190501.20122353'),
	'identifierSchemeCodeUnspsc190501.20122354': __('identifierSchemeCodeUnspsc190501.20122354'),
	'identifierSchemeCodeUnspsc190501.20122356': __('identifierSchemeCodeUnspsc190501.20122356'),
	'identifierSchemeCodeUnspsc190501.20122357': __('identifierSchemeCodeUnspsc190501.20122357'),
	'identifierSchemeCodeUnspsc190501.20122358': __('identifierSchemeCodeUnspsc190501.20122358'),
	'identifierSchemeCodeUnspsc190501.20122359': __('identifierSchemeCodeUnspsc190501.20122359'),
	'identifierSchemeCodeUnspsc190501.20122360': __('identifierSchemeCodeUnspsc190501.20122360'),
	'identifierSchemeCodeUnspsc190501.20122361': __('identifierSchemeCodeUnspsc190501.20122361'),
	'identifierSchemeCodeUnspsc190501.20122362': __('identifierSchemeCodeUnspsc190501.20122362'),
	'identifierSchemeCodeUnspsc190501.20122363': __('identifierSchemeCodeUnspsc190501.20122363'),
	'identifierSchemeCodeUnspsc190501.20122364': __('identifierSchemeCodeUnspsc190501.20122364'),
	'identifierSchemeCodeUnspsc190501.20122365': __('identifierSchemeCodeUnspsc190501.20122365'),
	'identifierSchemeCodeUnspsc190501.20122366': __('identifierSchemeCodeUnspsc190501.20122366'),
	'identifierSchemeCodeUnspsc190501.20122367': __('identifierSchemeCodeUnspsc190501.20122367'),
	'identifierSchemeCodeUnspsc190501.20122368': __('identifierSchemeCodeUnspsc190501.20122368'),
	'identifierSchemeCodeUnspsc190501.20122369': __('identifierSchemeCodeUnspsc190501.20122369'),
	'identifierSchemeCodeUnspsc190501.20122370': __('identifierSchemeCodeUnspsc190501.20122370'),
	'identifierSchemeCodeUnspsc190501.20122371': __('identifierSchemeCodeUnspsc190501.20122371'),
	'identifierSchemeCodeUnspsc190501.20122372': __('identifierSchemeCodeUnspsc190501.20122372'),
	'identifierSchemeCodeUnspsc190501.20122373': __('identifierSchemeCodeUnspsc190501.20122373'),
	'identifierSchemeCodeUnspsc190501.20122400': __('identifierSchemeCodeUnspsc190501.20122400'),
	'identifierSchemeCodeUnspsc190501.20122401': __('identifierSchemeCodeUnspsc190501.20122401'),
	'identifierSchemeCodeUnspsc190501.20122402': __('identifierSchemeCodeUnspsc190501.20122402'),
	'identifierSchemeCodeUnspsc190501.20122403': __('identifierSchemeCodeUnspsc190501.20122403'),
	'identifierSchemeCodeUnspsc190501.20122404': __('identifierSchemeCodeUnspsc190501.20122404'),
	'identifierSchemeCodeUnspsc190501.20122405': __('identifierSchemeCodeUnspsc190501.20122405'),
	'identifierSchemeCodeUnspsc190501.20122406': __('identifierSchemeCodeUnspsc190501.20122406'),
	'identifierSchemeCodeUnspsc190501.20122407': __('identifierSchemeCodeUnspsc190501.20122407'),
	'identifierSchemeCodeUnspsc190501.20122408': __('identifierSchemeCodeUnspsc190501.20122408'),
	'identifierSchemeCodeUnspsc190501.20122409': __('identifierSchemeCodeUnspsc190501.20122409'),
	'identifierSchemeCodeUnspsc190501.20122410': __('identifierSchemeCodeUnspsc190501.20122410'),
	'identifierSchemeCodeUnspsc190501.20122500': __('identifierSchemeCodeUnspsc190501.20122500'),
	'identifierSchemeCodeUnspsc190501.20122501': __('identifierSchemeCodeUnspsc190501.20122501'),
	'identifierSchemeCodeUnspsc190501.20122502': __('identifierSchemeCodeUnspsc190501.20122502'),
	'identifierSchemeCodeUnspsc190501.20122503': __('identifierSchemeCodeUnspsc190501.20122503'),
	'identifierSchemeCodeUnspsc190501.20122504': __('identifierSchemeCodeUnspsc190501.20122504'),
	'identifierSchemeCodeUnspsc190501.20122505': __('identifierSchemeCodeUnspsc190501.20122505'),
	'identifierSchemeCodeUnspsc190501.20122506': __('identifierSchemeCodeUnspsc190501.20122506'),
	'identifierSchemeCodeUnspsc190501.20122507': __('identifierSchemeCodeUnspsc190501.20122507'),
	'identifierSchemeCodeUnspsc190501.20122508': __('identifierSchemeCodeUnspsc190501.20122508'),
	'identifierSchemeCodeUnspsc190501.20122509': __('identifierSchemeCodeUnspsc190501.20122509'),
	'identifierSchemeCodeUnspsc190501.20122510': __('identifierSchemeCodeUnspsc190501.20122510'),
	'identifierSchemeCodeUnspsc190501.20122511': __('identifierSchemeCodeUnspsc190501.20122511'),
	'identifierSchemeCodeUnspsc190501.20122512': __('identifierSchemeCodeUnspsc190501.20122512'),
	'identifierSchemeCodeUnspsc190501.20122513': __('identifierSchemeCodeUnspsc190501.20122513'),
	'identifierSchemeCodeUnspsc190501.20122514': __('identifierSchemeCodeUnspsc190501.20122514'),
	'identifierSchemeCodeUnspsc190501.20122515': __('identifierSchemeCodeUnspsc190501.20122515'),
	'identifierSchemeCodeUnspsc190501.20122516': __('identifierSchemeCodeUnspsc190501.20122516'),
	'identifierSchemeCodeUnspsc190501.20122518': __('identifierSchemeCodeUnspsc190501.20122518'),
	'identifierSchemeCodeUnspsc190501.20122600': __('identifierSchemeCodeUnspsc190501.20122600'),
	'identifierSchemeCodeUnspsc190501.20122601': __('identifierSchemeCodeUnspsc190501.20122601'),
	'identifierSchemeCodeUnspsc190501.20122602': __('identifierSchemeCodeUnspsc190501.20122602'),
	'identifierSchemeCodeUnspsc190501.20122603': __('identifierSchemeCodeUnspsc190501.20122603'),
	'identifierSchemeCodeUnspsc190501.20122604': __('identifierSchemeCodeUnspsc190501.20122604'),
	'identifierSchemeCodeUnspsc190501.20122605': __('identifierSchemeCodeUnspsc190501.20122605'),
	'identifierSchemeCodeUnspsc190501.20122606': __('identifierSchemeCodeUnspsc190501.20122606'),
	'identifierSchemeCodeUnspsc190501.20122607': __('identifierSchemeCodeUnspsc190501.20122607'),
	'identifierSchemeCodeUnspsc190501.20122608': __('identifierSchemeCodeUnspsc190501.20122608'),
	'identifierSchemeCodeUnspsc190501.20122609': __('identifierSchemeCodeUnspsc190501.20122609'),
	'identifierSchemeCodeUnspsc190501.20122610': __('identifierSchemeCodeUnspsc190501.20122610'),
	'identifierSchemeCodeUnspsc190501.20122611': __('identifierSchemeCodeUnspsc190501.20122611'),
	'identifierSchemeCodeUnspsc190501.20122612': __('identifierSchemeCodeUnspsc190501.20122612'),
	'identifierSchemeCodeUnspsc190501.20122613': __('identifierSchemeCodeUnspsc190501.20122613'),
	'identifierSchemeCodeUnspsc190501.20122614': __('identifierSchemeCodeUnspsc190501.20122614'),
	'identifierSchemeCodeUnspsc190501.20122615': __('identifierSchemeCodeUnspsc190501.20122615'),
	'identifierSchemeCodeUnspsc190501.20122616': __('identifierSchemeCodeUnspsc190501.20122616'),
	'identifierSchemeCodeUnspsc190501.20122617': __('identifierSchemeCodeUnspsc190501.20122617'),
	'identifierSchemeCodeUnspsc190501.20122618': __('identifierSchemeCodeUnspsc190501.20122618'),
	'identifierSchemeCodeUnspsc190501.20122619': __('identifierSchemeCodeUnspsc190501.20122619'),
	'identifierSchemeCodeUnspsc190501.20122620': __('identifierSchemeCodeUnspsc190501.20122620'),
	'identifierSchemeCodeUnspsc190501.20122621': __('identifierSchemeCodeUnspsc190501.20122621'),
	'identifierSchemeCodeUnspsc190501.20122622': __('identifierSchemeCodeUnspsc190501.20122622'),
	'identifierSchemeCodeUnspsc190501.20122623': __('identifierSchemeCodeUnspsc190501.20122623'),
	'identifierSchemeCodeUnspsc190501.20122700': __('identifierSchemeCodeUnspsc190501.20122700'),
	'identifierSchemeCodeUnspsc190501.20122701': __('identifierSchemeCodeUnspsc190501.20122701'),
	'identifierSchemeCodeUnspsc190501.20122702': __('identifierSchemeCodeUnspsc190501.20122702'),
	'identifierSchemeCodeUnspsc190501.20122703': __('identifierSchemeCodeUnspsc190501.20122703'),
	'identifierSchemeCodeUnspsc190501.20122704': __('identifierSchemeCodeUnspsc190501.20122704'),
	'identifierSchemeCodeUnspsc190501.20122705': __('identifierSchemeCodeUnspsc190501.20122705'),
	'identifierSchemeCodeUnspsc190501.20122706': __('identifierSchemeCodeUnspsc190501.20122706'),
	'identifierSchemeCodeUnspsc190501.20122707': __('identifierSchemeCodeUnspsc190501.20122707'),
	'identifierSchemeCodeUnspsc190501.20122708': __('identifierSchemeCodeUnspsc190501.20122708'),
	'identifierSchemeCodeUnspsc190501.20122709': __('identifierSchemeCodeUnspsc190501.20122709'),
	'identifierSchemeCodeUnspsc190501.20122710': __('identifierSchemeCodeUnspsc190501.20122710'),
	'identifierSchemeCodeUnspsc190501.20122800': __('identifierSchemeCodeUnspsc190501.20122800'),
	'identifierSchemeCodeUnspsc190501.20122801': __('identifierSchemeCodeUnspsc190501.20122801'),
	'identifierSchemeCodeUnspsc190501.20122802': __('identifierSchemeCodeUnspsc190501.20122802'),
	'identifierSchemeCodeUnspsc190501.20122803': __('identifierSchemeCodeUnspsc190501.20122803'),
	'identifierSchemeCodeUnspsc190501.20122804': __('identifierSchemeCodeUnspsc190501.20122804'),
	'identifierSchemeCodeUnspsc190501.20122806': __('identifierSchemeCodeUnspsc190501.20122806'),
	'identifierSchemeCodeUnspsc190501.20122807': __('identifierSchemeCodeUnspsc190501.20122807'),
	'identifierSchemeCodeUnspsc190501.20122808': __('identifierSchemeCodeUnspsc190501.20122808'),
	'identifierSchemeCodeUnspsc190501.20122809': __('identifierSchemeCodeUnspsc190501.20122809'),
	'identifierSchemeCodeUnspsc190501.20122810': __('identifierSchemeCodeUnspsc190501.20122810'),
	'identifierSchemeCodeUnspsc190501.20122811': __('identifierSchemeCodeUnspsc190501.20122811'),
	'identifierSchemeCodeUnspsc190501.20122812': __('identifierSchemeCodeUnspsc190501.20122812'),
	'identifierSchemeCodeUnspsc190501.20122813': __('identifierSchemeCodeUnspsc190501.20122813'),
	'identifierSchemeCodeUnspsc190501.20122814': __('identifierSchemeCodeUnspsc190501.20122814'),
	'identifierSchemeCodeUnspsc190501.20122815': __('identifierSchemeCodeUnspsc190501.20122815'),
	'identifierSchemeCodeUnspsc190501.20122816': __('identifierSchemeCodeUnspsc190501.20122816'),
	'identifierSchemeCodeUnspsc190501.20122817': __('identifierSchemeCodeUnspsc190501.20122817'),
	'identifierSchemeCodeUnspsc190501.20122818': __('identifierSchemeCodeUnspsc190501.20122818'),
	'identifierSchemeCodeUnspsc190501.20122819': __('identifierSchemeCodeUnspsc190501.20122819'),
	'identifierSchemeCodeUnspsc190501.20122820': __('identifierSchemeCodeUnspsc190501.20122820'),
	'identifierSchemeCodeUnspsc190501.20122821': __('identifierSchemeCodeUnspsc190501.20122821'),
	'identifierSchemeCodeUnspsc190501.20122822': __('identifierSchemeCodeUnspsc190501.20122822'),
	'identifierSchemeCodeUnspsc190501.20122823': __('identifierSchemeCodeUnspsc190501.20122823'),
	'identifierSchemeCodeUnspsc190501.20122824': __('identifierSchemeCodeUnspsc190501.20122824'),
	'identifierSchemeCodeUnspsc190501.20122825': __('identifierSchemeCodeUnspsc190501.20122825'),
	'identifierSchemeCodeUnspsc190501.20122826': __('identifierSchemeCodeUnspsc190501.20122826'),
	'identifierSchemeCodeUnspsc190501.20122827': __('identifierSchemeCodeUnspsc190501.20122827'),
	'identifierSchemeCodeUnspsc190501.20122828': __('identifierSchemeCodeUnspsc190501.20122828'),
	'identifierSchemeCodeUnspsc190501.20122829': __('identifierSchemeCodeUnspsc190501.20122829'),
	'identifierSchemeCodeUnspsc190501.20122830': __('identifierSchemeCodeUnspsc190501.20122830'),
	'identifierSchemeCodeUnspsc190501.20122831': __('identifierSchemeCodeUnspsc190501.20122831'),
	'identifierSchemeCodeUnspsc190501.20122832': __('identifierSchemeCodeUnspsc190501.20122832'),
	'identifierSchemeCodeUnspsc190501.20122833': __('identifierSchemeCodeUnspsc190501.20122833'),
	'identifierSchemeCodeUnspsc190501.20122834': __('identifierSchemeCodeUnspsc190501.20122834'),
	'identifierSchemeCodeUnspsc190501.20122835': __('identifierSchemeCodeUnspsc190501.20122835'),
	'identifierSchemeCodeUnspsc190501.20122836': __('identifierSchemeCodeUnspsc190501.20122836'),
	'identifierSchemeCodeUnspsc190501.20122837': __('identifierSchemeCodeUnspsc190501.20122837'),
	'identifierSchemeCodeUnspsc190501.20122838': __('identifierSchemeCodeUnspsc190501.20122838'),
	'identifierSchemeCodeUnspsc190501.20122839': __('identifierSchemeCodeUnspsc190501.20122839'),
	'identifierSchemeCodeUnspsc190501.20122840': __('identifierSchemeCodeUnspsc190501.20122840'),
	'identifierSchemeCodeUnspsc190501.20122841': __('identifierSchemeCodeUnspsc190501.20122841'),
	'identifierSchemeCodeUnspsc190501.20122842': __('identifierSchemeCodeUnspsc190501.20122842'),
	'identifierSchemeCodeUnspsc190501.20122843': __('identifierSchemeCodeUnspsc190501.20122843'),
	'identifierSchemeCodeUnspsc190501.20122844': __('identifierSchemeCodeUnspsc190501.20122844'),
	'identifierSchemeCodeUnspsc190501.20122845': __('identifierSchemeCodeUnspsc190501.20122845'),
	'identifierSchemeCodeUnspsc190501.20122846': __('identifierSchemeCodeUnspsc190501.20122846'),
	'identifierSchemeCodeUnspsc190501.20122847': __('identifierSchemeCodeUnspsc190501.20122847'),
	'identifierSchemeCodeUnspsc190501.20122848': __('identifierSchemeCodeUnspsc190501.20122848'),
	'identifierSchemeCodeUnspsc190501.20122849': __('identifierSchemeCodeUnspsc190501.20122849'),
	'identifierSchemeCodeUnspsc190501.20122851': __('identifierSchemeCodeUnspsc190501.20122851'),
	'identifierSchemeCodeUnspsc190501.20122900': __('identifierSchemeCodeUnspsc190501.20122900'),
	'identifierSchemeCodeUnspsc190501.20122901': __('identifierSchemeCodeUnspsc190501.20122901'),
	'identifierSchemeCodeUnspsc190501.20122902': __('identifierSchemeCodeUnspsc190501.20122902'),
	'identifierSchemeCodeUnspsc190501.20122903': __('identifierSchemeCodeUnspsc190501.20122903'),
	'identifierSchemeCodeUnspsc190501.20123000': __('identifierSchemeCodeUnspsc190501.20123000'),
	'identifierSchemeCodeUnspsc190501.20123001': __('identifierSchemeCodeUnspsc190501.20123001'),
	'identifierSchemeCodeUnspsc190501.20123002': __('identifierSchemeCodeUnspsc190501.20123002'),
	'identifierSchemeCodeUnspsc190501.20123003': __('identifierSchemeCodeUnspsc190501.20123003'),
	'identifierSchemeCodeUnspsc190501.20123004': __('identifierSchemeCodeUnspsc190501.20123004'),
	'identifierSchemeCodeUnspsc190501.20123100': __('identifierSchemeCodeUnspsc190501.20123100'),
	'identifierSchemeCodeUnspsc190501.20123101': __('identifierSchemeCodeUnspsc190501.20123101'),
	'identifierSchemeCodeUnspsc190501.20123102': __('identifierSchemeCodeUnspsc190501.20123102'),
	'identifierSchemeCodeUnspsc190501.20123200': __('identifierSchemeCodeUnspsc190501.20123200'),
	'identifierSchemeCodeUnspsc190501.20123201': __('identifierSchemeCodeUnspsc190501.20123201'),
	'identifierSchemeCodeUnspsc190501.20123202': __('identifierSchemeCodeUnspsc190501.20123202'),
	'identifierSchemeCodeUnspsc190501.20123203': __('identifierSchemeCodeUnspsc190501.20123203'),
	'identifierSchemeCodeUnspsc190501.20123204': __('identifierSchemeCodeUnspsc190501.20123204'),
	'identifierSchemeCodeUnspsc190501.20123300': __('identifierSchemeCodeUnspsc190501.20123300'),
	'identifierSchemeCodeUnspsc190501.20123301': __('identifierSchemeCodeUnspsc190501.20123301'),
	'identifierSchemeCodeUnspsc190501.20123302': __('identifierSchemeCodeUnspsc190501.20123302'),
	'identifierSchemeCodeUnspsc190501.20123303': __('identifierSchemeCodeUnspsc190501.20123303'),
	'identifierSchemeCodeUnspsc190501.20123304': __('identifierSchemeCodeUnspsc190501.20123304'),
	'identifierSchemeCodeUnspsc190501.20130000': __('identifierSchemeCodeUnspsc190501.20130000'),
	'identifierSchemeCodeUnspsc190501.20131000': __('identifierSchemeCodeUnspsc190501.20131000'),
	'identifierSchemeCodeUnspsc190501.20131001': __('identifierSchemeCodeUnspsc190501.20131001'),
	'identifierSchemeCodeUnspsc190501.20131002': __('identifierSchemeCodeUnspsc190501.20131002'),
	'identifierSchemeCodeUnspsc190501.20131003': __('identifierSchemeCodeUnspsc190501.20131003'),
	'identifierSchemeCodeUnspsc190501.20131004': __('identifierSchemeCodeUnspsc190501.20131004'),
	'identifierSchemeCodeUnspsc190501.20131005': __('identifierSchemeCodeUnspsc190501.20131005'),
	'identifierSchemeCodeUnspsc190501.20131006': __('identifierSchemeCodeUnspsc190501.20131006'),
	'identifierSchemeCodeUnspsc190501.20131007': __('identifierSchemeCodeUnspsc190501.20131007'),
	'identifierSchemeCodeUnspsc190501.20131008': __('identifierSchemeCodeUnspsc190501.20131008'),
	'identifierSchemeCodeUnspsc190501.20131009': __('identifierSchemeCodeUnspsc190501.20131009'),
	'identifierSchemeCodeUnspsc190501.20131010': __('identifierSchemeCodeUnspsc190501.20131010'),
	'identifierSchemeCodeUnspsc190501.20131100': __('identifierSchemeCodeUnspsc190501.20131100'),
	'identifierSchemeCodeUnspsc190501.20131101': __('identifierSchemeCodeUnspsc190501.20131101'),
	'identifierSchemeCodeUnspsc190501.20131102': __('identifierSchemeCodeUnspsc190501.20131102'),
	'identifierSchemeCodeUnspsc190501.20131103': __('identifierSchemeCodeUnspsc190501.20131103'),
	'identifierSchemeCodeUnspsc190501.20131104': __('identifierSchemeCodeUnspsc190501.20131104'),
	'identifierSchemeCodeUnspsc190501.20131105': __('identifierSchemeCodeUnspsc190501.20131105'),
	'identifierSchemeCodeUnspsc190501.20131106': __('identifierSchemeCodeUnspsc190501.20131106'),
	'identifierSchemeCodeUnspsc190501.20131200': __('identifierSchemeCodeUnspsc190501.20131200'),
	'identifierSchemeCodeUnspsc190501.20131201': __('identifierSchemeCodeUnspsc190501.20131201'),
	'identifierSchemeCodeUnspsc190501.20131202': __('identifierSchemeCodeUnspsc190501.20131202'),
	'identifierSchemeCodeUnspsc190501.20131300': __('identifierSchemeCodeUnspsc190501.20131300'),
	'identifierSchemeCodeUnspsc190501.20131301': __('identifierSchemeCodeUnspsc190501.20131301'),
	'identifierSchemeCodeUnspsc190501.20131302': __('identifierSchemeCodeUnspsc190501.20131302'),
	'identifierSchemeCodeUnspsc190501.20131303': __('identifierSchemeCodeUnspsc190501.20131303'),
	'identifierSchemeCodeUnspsc190501.20131304': __('identifierSchemeCodeUnspsc190501.20131304'),
	'identifierSchemeCodeUnspsc190501.20131305': __('identifierSchemeCodeUnspsc190501.20131305'),
	'identifierSchemeCodeUnspsc190501.20131306': __('identifierSchemeCodeUnspsc190501.20131306'),
	'identifierSchemeCodeUnspsc190501.20131307': __('identifierSchemeCodeUnspsc190501.20131307'),
	'identifierSchemeCodeUnspsc190501.20131308': __('identifierSchemeCodeUnspsc190501.20131308'),
	'identifierSchemeCodeUnspsc190501.20140000': __('identifierSchemeCodeUnspsc190501.20140000'),
	'identifierSchemeCodeUnspsc190501.20141000': __('identifierSchemeCodeUnspsc190501.20141000'),
	'identifierSchemeCodeUnspsc190501.20141001': __('identifierSchemeCodeUnspsc190501.20141001'),
	'identifierSchemeCodeUnspsc190501.20141002': __('identifierSchemeCodeUnspsc190501.20141002'),
	'identifierSchemeCodeUnspsc190501.20141003': __('identifierSchemeCodeUnspsc190501.20141003'),
	'identifierSchemeCodeUnspsc190501.20141004': __('identifierSchemeCodeUnspsc190501.20141004'),
	'identifierSchemeCodeUnspsc190501.20141005': __('identifierSchemeCodeUnspsc190501.20141005'),
	'identifierSchemeCodeUnspsc190501.20141006': __('identifierSchemeCodeUnspsc190501.20141006'),
	'identifierSchemeCodeUnspsc190501.20141007': __('identifierSchemeCodeUnspsc190501.20141007'),
	'identifierSchemeCodeUnspsc190501.20141008': __('identifierSchemeCodeUnspsc190501.20141008'),
	'identifierSchemeCodeUnspsc190501.20141011': __('identifierSchemeCodeUnspsc190501.20141011'),
	'identifierSchemeCodeUnspsc190501.20141012': __('identifierSchemeCodeUnspsc190501.20141012'),
	'identifierSchemeCodeUnspsc190501.20141013': __('identifierSchemeCodeUnspsc190501.20141013'),
	'identifierSchemeCodeUnspsc190501.20141014': __('identifierSchemeCodeUnspsc190501.20141014'),
	'identifierSchemeCodeUnspsc190501.20141015': __('identifierSchemeCodeUnspsc190501.20141015'),
	'identifierSchemeCodeUnspsc190501.20141016': __('identifierSchemeCodeUnspsc190501.20141016'),
	'identifierSchemeCodeUnspsc190501.20141017': __('identifierSchemeCodeUnspsc190501.20141017'),
	'identifierSchemeCodeUnspsc190501.20141018': __('identifierSchemeCodeUnspsc190501.20141018'),
	'identifierSchemeCodeUnspsc190501.20141100': __('identifierSchemeCodeUnspsc190501.20141100'),
	'identifierSchemeCodeUnspsc190501.20141101': __('identifierSchemeCodeUnspsc190501.20141101'),
	'identifierSchemeCodeUnspsc190501.20141200': __('identifierSchemeCodeUnspsc190501.20141200'),
	'identifierSchemeCodeUnspsc190501.20141201': __('identifierSchemeCodeUnspsc190501.20141201'),
	'identifierSchemeCodeUnspsc190501.20141300': __('identifierSchemeCodeUnspsc190501.20141300'),
	'identifierSchemeCodeUnspsc190501.20141301': __('identifierSchemeCodeUnspsc190501.20141301'),
	'identifierSchemeCodeUnspsc190501.20141302': __('identifierSchemeCodeUnspsc190501.20141302'),
	'identifierSchemeCodeUnspsc190501.20141303': __('identifierSchemeCodeUnspsc190501.20141303'),
	'identifierSchemeCodeUnspsc190501.20141400': __('identifierSchemeCodeUnspsc190501.20141400'),
	'identifierSchemeCodeUnspsc190501.20141401': __('identifierSchemeCodeUnspsc190501.20141401'),
	'identifierSchemeCodeUnspsc190501.20141500': __('identifierSchemeCodeUnspsc190501.20141500'),
	'identifierSchemeCodeUnspsc190501.20141501': __('identifierSchemeCodeUnspsc190501.20141501'),
	'identifierSchemeCodeUnspsc190501.20141502': __('identifierSchemeCodeUnspsc190501.20141502'),
	'identifierSchemeCodeUnspsc190501.20141600': __('identifierSchemeCodeUnspsc190501.20141600'),
	'identifierSchemeCodeUnspsc190501.20141601': __('identifierSchemeCodeUnspsc190501.20141601'),
	'identifierSchemeCodeUnspsc190501.20141700': __('identifierSchemeCodeUnspsc190501.20141700'),
	'identifierSchemeCodeUnspsc190501.20141701': __('identifierSchemeCodeUnspsc190501.20141701'),
	'identifierSchemeCodeUnspsc190501.20141702': __('identifierSchemeCodeUnspsc190501.20141702'),
	'identifierSchemeCodeUnspsc190501.20141703': __('identifierSchemeCodeUnspsc190501.20141703'),
	'identifierSchemeCodeUnspsc190501.20141704': __('identifierSchemeCodeUnspsc190501.20141704'),
	'identifierSchemeCodeUnspsc190501.20141705': __('identifierSchemeCodeUnspsc190501.20141705'),
	'identifierSchemeCodeUnspsc190501.20141800': __('identifierSchemeCodeUnspsc190501.20141800'),
	'identifierSchemeCodeUnspsc190501.20141801': __('identifierSchemeCodeUnspsc190501.20141801'),
	'identifierSchemeCodeUnspsc190501.20141900': __('identifierSchemeCodeUnspsc190501.20141900'),
	'identifierSchemeCodeUnspsc190501.20141901': __('identifierSchemeCodeUnspsc190501.20141901'),
	'identifierSchemeCodeUnspsc190501.20142000': __('identifierSchemeCodeUnspsc190501.20142000'),
	'identifierSchemeCodeUnspsc190501.20142001': __('identifierSchemeCodeUnspsc190501.20142001'),
	'identifierSchemeCodeUnspsc190501.20142100': __('identifierSchemeCodeUnspsc190501.20142100'),
	'identifierSchemeCodeUnspsc190501.20142101': __('identifierSchemeCodeUnspsc190501.20142101'),
	'identifierSchemeCodeUnspsc190501.20142200': __('identifierSchemeCodeUnspsc190501.20142200'),
	'identifierSchemeCodeUnspsc190501.20142201': __('identifierSchemeCodeUnspsc190501.20142201'),
	'identifierSchemeCodeUnspsc190501.20142300': __('identifierSchemeCodeUnspsc190501.20142300'),
	'identifierSchemeCodeUnspsc190501.20142301': __('identifierSchemeCodeUnspsc190501.20142301'),
	'identifierSchemeCodeUnspsc190501.20142400': __('identifierSchemeCodeUnspsc190501.20142400'),
	'identifierSchemeCodeUnspsc190501.20142401': __('identifierSchemeCodeUnspsc190501.20142401'),
	'identifierSchemeCodeUnspsc190501.20142403': __('identifierSchemeCodeUnspsc190501.20142403'),
	'identifierSchemeCodeUnspsc190501.20142404': __('identifierSchemeCodeUnspsc190501.20142404'),
	'identifierSchemeCodeUnspsc190501.20142405': __('identifierSchemeCodeUnspsc190501.20142405'),
	'identifierSchemeCodeUnspsc190501.20142406': __('identifierSchemeCodeUnspsc190501.20142406'),
	'identifierSchemeCodeUnspsc190501.20142500': __('identifierSchemeCodeUnspsc190501.20142500'),
	'identifierSchemeCodeUnspsc190501.20142501': __('identifierSchemeCodeUnspsc190501.20142501'),
	'identifierSchemeCodeUnspsc190501.20142600': __('identifierSchemeCodeUnspsc190501.20142600'),
	'identifierSchemeCodeUnspsc190501.20142601': __('identifierSchemeCodeUnspsc190501.20142601'),
	'identifierSchemeCodeUnspsc190501.20142700': __('identifierSchemeCodeUnspsc190501.20142700'),
	'identifierSchemeCodeUnspsc190501.20142701': __('identifierSchemeCodeUnspsc190501.20142701'),
	'identifierSchemeCodeUnspsc190501.20142702': __('identifierSchemeCodeUnspsc190501.20142702'),
	'identifierSchemeCodeUnspsc190501.20142703': __('identifierSchemeCodeUnspsc190501.20142703'),
	'identifierSchemeCodeUnspsc190501.20142704': __('identifierSchemeCodeUnspsc190501.20142704'),
	'identifierSchemeCodeUnspsc190501.20142705': __('identifierSchemeCodeUnspsc190501.20142705'),
	'identifierSchemeCodeUnspsc190501.20142706': __('identifierSchemeCodeUnspsc190501.20142706'),
	'identifierSchemeCodeUnspsc190501.20142707': __('identifierSchemeCodeUnspsc190501.20142707'),
	'identifierSchemeCodeUnspsc190501.20142708': __('identifierSchemeCodeUnspsc190501.20142708'),
	'identifierSchemeCodeUnspsc190501.20142709': __('identifierSchemeCodeUnspsc190501.20142709'),
	'identifierSchemeCodeUnspsc190501.20142710': __('identifierSchemeCodeUnspsc190501.20142710'),
	'identifierSchemeCodeUnspsc190501.20142800': __('identifierSchemeCodeUnspsc190501.20142800'),
	'identifierSchemeCodeUnspsc190501.20142801': __('identifierSchemeCodeUnspsc190501.20142801'),
	'identifierSchemeCodeUnspsc190501.20142900': __('identifierSchemeCodeUnspsc190501.20142900'),
	'identifierSchemeCodeUnspsc190501.20142901': __('identifierSchemeCodeUnspsc190501.20142901'),
	'identifierSchemeCodeUnspsc190501.20142902': __('identifierSchemeCodeUnspsc190501.20142902'),
	'identifierSchemeCodeUnspsc190501.20142903': __('identifierSchemeCodeUnspsc190501.20142903'),
	'identifierSchemeCodeUnspsc190501.20142904': __('identifierSchemeCodeUnspsc190501.20142904'),
	'identifierSchemeCodeUnspsc190501.20142905': __('identifierSchemeCodeUnspsc190501.20142905'),
	'identifierSchemeCodeUnspsc190501.20143000': __('identifierSchemeCodeUnspsc190501.20143000'),
	'identifierSchemeCodeUnspsc190501.20143001': __('identifierSchemeCodeUnspsc190501.20143001'),
	'identifierSchemeCodeUnspsc190501.20143002': __('identifierSchemeCodeUnspsc190501.20143002'),
	'identifierSchemeCodeUnspsc190501.20143003': __('identifierSchemeCodeUnspsc190501.20143003'),
	'identifierSchemeCodeUnspsc190501.20143004': __('identifierSchemeCodeUnspsc190501.20143004'),
	'identifierSchemeCodeUnspsc190501.20143005': __('identifierSchemeCodeUnspsc190501.20143005'),
	'identifierSchemeCodeUnspsc190501.20143006': __('identifierSchemeCodeUnspsc190501.20143006'),
	'identifierSchemeCodeUnspsc190501.20143300': __('identifierSchemeCodeUnspsc190501.20143300'),
	'identifierSchemeCodeUnspsc190501.20143301': __('identifierSchemeCodeUnspsc190501.20143301'),
	'identifierSchemeCodeUnspsc190501.20143302': __('identifierSchemeCodeUnspsc190501.20143302'),
	'identifierSchemeCodeUnspsc190501.20143303': __('identifierSchemeCodeUnspsc190501.20143303'),
	'identifierSchemeCodeUnspsc190501.20143400': __('identifierSchemeCodeUnspsc190501.20143400'),
	'identifierSchemeCodeUnspsc190501.20143401': __('identifierSchemeCodeUnspsc190501.20143401'),
	'identifierSchemeCodeUnspsc190501.20143402': __('identifierSchemeCodeUnspsc190501.20143402'),
	'identifierSchemeCodeUnspsc190501.20143403': __('identifierSchemeCodeUnspsc190501.20143403'),
	'identifierSchemeCodeUnspsc190501.20143404': __('identifierSchemeCodeUnspsc190501.20143404'),
	'identifierSchemeCodeUnspsc190501.20143500': __('identifierSchemeCodeUnspsc190501.20143500'),
	'identifierSchemeCodeUnspsc190501.20143501': __('identifierSchemeCodeUnspsc190501.20143501'),
	'identifierSchemeCodeUnspsc190501.20143502': __('identifierSchemeCodeUnspsc190501.20143502'),
	'identifierSchemeCodeUnspsc190501.20143503': __('identifierSchemeCodeUnspsc190501.20143503'),
	'identifierSchemeCodeUnspsc190501.20143504': __('identifierSchemeCodeUnspsc190501.20143504'),
	'identifierSchemeCodeUnspsc190501.20143600': __('identifierSchemeCodeUnspsc190501.20143600'),
	'identifierSchemeCodeUnspsc190501.20143601': __('identifierSchemeCodeUnspsc190501.20143601'),
	'identifierSchemeCodeUnspsc190501.20143602': __('identifierSchemeCodeUnspsc190501.20143602'),
	'identifierSchemeCodeUnspsc190501.20143700': __('identifierSchemeCodeUnspsc190501.20143700'),
	'identifierSchemeCodeUnspsc190501.20143701': __('identifierSchemeCodeUnspsc190501.20143701'),
	'identifierSchemeCodeUnspsc190501.20143702': __('identifierSchemeCodeUnspsc190501.20143702'),
	'identifierSchemeCodeUnspsc190501.20143703': __('identifierSchemeCodeUnspsc190501.20143703'),
	'identifierSchemeCodeUnspsc190501.20143704': __('identifierSchemeCodeUnspsc190501.20143704'),
	'identifierSchemeCodeUnspsc190501.20143705': __('identifierSchemeCodeUnspsc190501.20143705'),
	'identifierSchemeCodeUnspsc190501.20143706': __('identifierSchemeCodeUnspsc190501.20143706'),
	'identifierSchemeCodeUnspsc190501.20143800': __('identifierSchemeCodeUnspsc190501.20143800'),
	'identifierSchemeCodeUnspsc190501.20143801': __('identifierSchemeCodeUnspsc190501.20143801'),
	'identifierSchemeCodeUnspsc190501.20143802': __('identifierSchemeCodeUnspsc190501.20143802'),
	'identifierSchemeCodeUnspsc190501.20143803': __('identifierSchemeCodeUnspsc190501.20143803'),
	'identifierSchemeCodeUnspsc190501.21000000': __('identifierSchemeCodeUnspsc190501.21000000'),
	'identifierSchemeCodeUnspsc190501.21100000': __('identifierSchemeCodeUnspsc190501.21100000'),
	'identifierSchemeCodeUnspsc190501.21101500': __('identifierSchemeCodeUnspsc190501.21101500'),
	'identifierSchemeCodeUnspsc190501.21101501': __('identifierSchemeCodeUnspsc190501.21101501'),
	'identifierSchemeCodeUnspsc190501.21101502': __('identifierSchemeCodeUnspsc190501.21101502'),
	'identifierSchemeCodeUnspsc190501.21101503': __('identifierSchemeCodeUnspsc190501.21101503'),
	'identifierSchemeCodeUnspsc190501.21101504': __('identifierSchemeCodeUnspsc190501.21101504'),
	'identifierSchemeCodeUnspsc190501.21101505': __('identifierSchemeCodeUnspsc190501.21101505'),
	'identifierSchemeCodeUnspsc190501.21101506': __('identifierSchemeCodeUnspsc190501.21101506'),
	'identifierSchemeCodeUnspsc190501.21101507': __('identifierSchemeCodeUnspsc190501.21101507'),
	'identifierSchemeCodeUnspsc190501.21101508': __('identifierSchemeCodeUnspsc190501.21101508'),
	'identifierSchemeCodeUnspsc190501.21101509': __('identifierSchemeCodeUnspsc190501.21101509'),
	'identifierSchemeCodeUnspsc190501.21101513': __('identifierSchemeCodeUnspsc190501.21101513'),
	'identifierSchemeCodeUnspsc190501.21101514': __('identifierSchemeCodeUnspsc190501.21101514'),
	'identifierSchemeCodeUnspsc190501.21101516': __('identifierSchemeCodeUnspsc190501.21101516'),
	'identifierSchemeCodeUnspsc190501.21101517': __('identifierSchemeCodeUnspsc190501.21101517'),
	'identifierSchemeCodeUnspsc190501.21101518': __('identifierSchemeCodeUnspsc190501.21101518'),
	'identifierSchemeCodeUnspsc190501.21101519': __('identifierSchemeCodeUnspsc190501.21101519'),
	'identifierSchemeCodeUnspsc190501.21101520': __('identifierSchemeCodeUnspsc190501.21101520'),
	'identifierSchemeCodeUnspsc190501.21101521': __('identifierSchemeCodeUnspsc190501.21101521'),
	'identifierSchemeCodeUnspsc190501.21101522': __('identifierSchemeCodeUnspsc190501.21101522'),
	'identifierSchemeCodeUnspsc190501.21101523': __('identifierSchemeCodeUnspsc190501.21101523'),
	'identifierSchemeCodeUnspsc190501.21101524': __('identifierSchemeCodeUnspsc190501.21101524'),
	'identifierSchemeCodeUnspsc190501.21101525': __('identifierSchemeCodeUnspsc190501.21101525'),
	'identifierSchemeCodeUnspsc190501.21101526': __('identifierSchemeCodeUnspsc190501.21101526'),
	'identifierSchemeCodeUnspsc190501.21101527': __('identifierSchemeCodeUnspsc190501.21101527'),
	'identifierSchemeCodeUnspsc190501.21101600': __('identifierSchemeCodeUnspsc190501.21101600'),
	'identifierSchemeCodeUnspsc190501.21101601': __('identifierSchemeCodeUnspsc190501.21101601'),
	'identifierSchemeCodeUnspsc190501.21101602': __('identifierSchemeCodeUnspsc190501.21101602'),
	'identifierSchemeCodeUnspsc190501.21101603': __('identifierSchemeCodeUnspsc190501.21101603'),
	'identifierSchemeCodeUnspsc190501.21101604': __('identifierSchemeCodeUnspsc190501.21101604'),
	'identifierSchemeCodeUnspsc190501.21101605': __('identifierSchemeCodeUnspsc190501.21101605'),
	'identifierSchemeCodeUnspsc190501.21101606': __('identifierSchemeCodeUnspsc190501.21101606'),
	'identifierSchemeCodeUnspsc190501.21101607': __('identifierSchemeCodeUnspsc190501.21101607'),
	'identifierSchemeCodeUnspsc190501.21101608': __('identifierSchemeCodeUnspsc190501.21101608'),
	'identifierSchemeCodeUnspsc190501.21101609': __('identifierSchemeCodeUnspsc190501.21101609'),
	'identifierSchemeCodeUnspsc190501.21101610': __('identifierSchemeCodeUnspsc190501.21101610'),
	'identifierSchemeCodeUnspsc190501.21101611': __('identifierSchemeCodeUnspsc190501.21101611'),
	'identifierSchemeCodeUnspsc190501.21101612': __('identifierSchemeCodeUnspsc190501.21101612'),
	'identifierSchemeCodeUnspsc190501.21101613': __('identifierSchemeCodeUnspsc190501.21101613'),
	'identifierSchemeCodeUnspsc190501.21101700': __('identifierSchemeCodeUnspsc190501.21101700'),
	'identifierSchemeCodeUnspsc190501.21101701': __('identifierSchemeCodeUnspsc190501.21101701'),
	'identifierSchemeCodeUnspsc190501.21101702': __('identifierSchemeCodeUnspsc190501.21101702'),
	'identifierSchemeCodeUnspsc190501.21101703': __('identifierSchemeCodeUnspsc190501.21101703'),
	'identifierSchemeCodeUnspsc190501.21101704': __('identifierSchemeCodeUnspsc190501.21101704'),
	'identifierSchemeCodeUnspsc190501.21101705': __('identifierSchemeCodeUnspsc190501.21101705'),
	'identifierSchemeCodeUnspsc190501.21101706': __('identifierSchemeCodeUnspsc190501.21101706'),
	'identifierSchemeCodeUnspsc190501.21101707': __('identifierSchemeCodeUnspsc190501.21101707'),
	'identifierSchemeCodeUnspsc190501.21101708': __('identifierSchemeCodeUnspsc190501.21101708'),
	'identifierSchemeCodeUnspsc190501.21101709': __('identifierSchemeCodeUnspsc190501.21101709'),
	'identifierSchemeCodeUnspsc190501.21101710': __('identifierSchemeCodeUnspsc190501.21101710'),
	'identifierSchemeCodeUnspsc190501.21101711': __('identifierSchemeCodeUnspsc190501.21101711'),
	'identifierSchemeCodeUnspsc190501.21101712': __('identifierSchemeCodeUnspsc190501.21101712'),
	'identifierSchemeCodeUnspsc190501.21101713': __('identifierSchemeCodeUnspsc190501.21101713'),
	'identifierSchemeCodeUnspsc190501.21101714': __('identifierSchemeCodeUnspsc190501.21101714'),
	'identifierSchemeCodeUnspsc190501.21101800': __('identifierSchemeCodeUnspsc190501.21101800'),
	'identifierSchemeCodeUnspsc190501.21101801': __('identifierSchemeCodeUnspsc190501.21101801'),
	'identifierSchemeCodeUnspsc190501.21101802': __('identifierSchemeCodeUnspsc190501.21101802'),
	'identifierSchemeCodeUnspsc190501.21101803': __('identifierSchemeCodeUnspsc190501.21101803'),
	'identifierSchemeCodeUnspsc190501.21101804': __('identifierSchemeCodeUnspsc190501.21101804'),
	'identifierSchemeCodeUnspsc190501.21101805': __('identifierSchemeCodeUnspsc190501.21101805'),
	'identifierSchemeCodeUnspsc190501.21101806': __('identifierSchemeCodeUnspsc190501.21101806'),
	'identifierSchemeCodeUnspsc190501.21101807': __('identifierSchemeCodeUnspsc190501.21101807'),
	'identifierSchemeCodeUnspsc190501.21101808': __('identifierSchemeCodeUnspsc190501.21101808'),
	'identifierSchemeCodeUnspsc190501.21101809': __('identifierSchemeCodeUnspsc190501.21101809'),
	'identifierSchemeCodeUnspsc190501.21101900': __('identifierSchemeCodeUnspsc190501.21101900'),
	'identifierSchemeCodeUnspsc190501.21101901': __('identifierSchemeCodeUnspsc190501.21101901'),
	'identifierSchemeCodeUnspsc190501.21101902': __('identifierSchemeCodeUnspsc190501.21101902'),
	'identifierSchemeCodeUnspsc190501.21101903': __('identifierSchemeCodeUnspsc190501.21101903'),
	'identifierSchemeCodeUnspsc190501.21101904': __('identifierSchemeCodeUnspsc190501.21101904'),
	'identifierSchemeCodeUnspsc190501.21101905': __('identifierSchemeCodeUnspsc190501.21101905'),
	'identifierSchemeCodeUnspsc190501.21101906': __('identifierSchemeCodeUnspsc190501.21101906'),
	'identifierSchemeCodeUnspsc190501.21101907': __('identifierSchemeCodeUnspsc190501.21101907'),
	'identifierSchemeCodeUnspsc190501.21101908': __('identifierSchemeCodeUnspsc190501.21101908'),
	'identifierSchemeCodeUnspsc190501.21101909': __('identifierSchemeCodeUnspsc190501.21101909'),
	'identifierSchemeCodeUnspsc190501.21101910': __('identifierSchemeCodeUnspsc190501.21101910'),
	'identifierSchemeCodeUnspsc190501.21101911': __('identifierSchemeCodeUnspsc190501.21101911'),
	'identifierSchemeCodeUnspsc190501.21101912': __('identifierSchemeCodeUnspsc190501.21101912'),
	'identifierSchemeCodeUnspsc190501.21101913': __('identifierSchemeCodeUnspsc190501.21101913'),
	'identifierSchemeCodeUnspsc190501.21101914': __('identifierSchemeCodeUnspsc190501.21101914'),
	'identifierSchemeCodeUnspsc190501.21101915': __('identifierSchemeCodeUnspsc190501.21101915'),
	'identifierSchemeCodeUnspsc190501.21101916': __('identifierSchemeCodeUnspsc190501.21101916'),
	'identifierSchemeCodeUnspsc190501.21102000': __('identifierSchemeCodeUnspsc190501.21102000'),
	'identifierSchemeCodeUnspsc190501.21102001': __('identifierSchemeCodeUnspsc190501.21102001'),
	'identifierSchemeCodeUnspsc190501.21102002': __('identifierSchemeCodeUnspsc190501.21102002'),
	'identifierSchemeCodeUnspsc190501.21102003': __('identifierSchemeCodeUnspsc190501.21102003'),
	'identifierSchemeCodeUnspsc190501.21102004': __('identifierSchemeCodeUnspsc190501.21102004'),
	'identifierSchemeCodeUnspsc190501.21102005': __('identifierSchemeCodeUnspsc190501.21102005'),
	'identifierSchemeCodeUnspsc190501.21102006': __('identifierSchemeCodeUnspsc190501.21102006'),
	'identifierSchemeCodeUnspsc190501.21102007': __('identifierSchemeCodeUnspsc190501.21102007'),
	'identifierSchemeCodeUnspsc190501.21102008': __('identifierSchemeCodeUnspsc190501.21102008'),
	'identifierSchemeCodeUnspsc190501.21102009': __('identifierSchemeCodeUnspsc190501.21102009'),
	'identifierSchemeCodeUnspsc190501.21102100': __('identifierSchemeCodeUnspsc190501.21102100'),
	'identifierSchemeCodeUnspsc190501.21102101': __('identifierSchemeCodeUnspsc190501.21102101'),
	'identifierSchemeCodeUnspsc190501.21102102': __('identifierSchemeCodeUnspsc190501.21102102'),
	'identifierSchemeCodeUnspsc190501.21102200': __('identifierSchemeCodeUnspsc190501.21102200'),
	'identifierSchemeCodeUnspsc190501.21102201': __('identifierSchemeCodeUnspsc190501.21102201'),
	'identifierSchemeCodeUnspsc190501.21102202': __('identifierSchemeCodeUnspsc190501.21102202'),
	'identifierSchemeCodeUnspsc190501.21102203': __('identifierSchemeCodeUnspsc190501.21102203'),
	'identifierSchemeCodeUnspsc190501.21102204': __('identifierSchemeCodeUnspsc190501.21102204'),
	'identifierSchemeCodeUnspsc190501.21102205': __('identifierSchemeCodeUnspsc190501.21102205'),
	'identifierSchemeCodeUnspsc190501.21102206': __('identifierSchemeCodeUnspsc190501.21102206'),
	'identifierSchemeCodeUnspsc190501.21102207': __('identifierSchemeCodeUnspsc190501.21102207'),
	'identifierSchemeCodeUnspsc190501.21102208': __('identifierSchemeCodeUnspsc190501.21102208'),
	'identifierSchemeCodeUnspsc190501.21102209': __('identifierSchemeCodeUnspsc190501.21102209'),
	'identifierSchemeCodeUnspsc190501.21102300': __('identifierSchemeCodeUnspsc190501.21102300'),
	'identifierSchemeCodeUnspsc190501.21102301': __('identifierSchemeCodeUnspsc190501.21102301'),
	'identifierSchemeCodeUnspsc190501.21102302': __('identifierSchemeCodeUnspsc190501.21102302'),
	'identifierSchemeCodeUnspsc190501.21102303': __('identifierSchemeCodeUnspsc190501.21102303'),
	'identifierSchemeCodeUnspsc190501.21102304': __('identifierSchemeCodeUnspsc190501.21102304'),
	'identifierSchemeCodeUnspsc190501.21102305': __('identifierSchemeCodeUnspsc190501.21102305'),
	'identifierSchemeCodeUnspsc190501.21102306': __('identifierSchemeCodeUnspsc190501.21102306'),
	'identifierSchemeCodeUnspsc190501.21102400': __('identifierSchemeCodeUnspsc190501.21102400'),
	'identifierSchemeCodeUnspsc190501.21102401': __('identifierSchemeCodeUnspsc190501.21102401'),
	'identifierSchemeCodeUnspsc190501.21102402': __('identifierSchemeCodeUnspsc190501.21102402'),
	'identifierSchemeCodeUnspsc190501.21102403': __('identifierSchemeCodeUnspsc190501.21102403'),
	'identifierSchemeCodeUnspsc190501.21102404': __('identifierSchemeCodeUnspsc190501.21102404'),
	'identifierSchemeCodeUnspsc190501.21102500': __('identifierSchemeCodeUnspsc190501.21102500'),
	'identifierSchemeCodeUnspsc190501.21102501': __('identifierSchemeCodeUnspsc190501.21102501'),
	'identifierSchemeCodeUnspsc190501.21102502': __('identifierSchemeCodeUnspsc190501.21102502'),
	'identifierSchemeCodeUnspsc190501.21102503': __('identifierSchemeCodeUnspsc190501.21102503'),
	'identifierSchemeCodeUnspsc190501.21102600': __('identifierSchemeCodeUnspsc190501.21102600'),
	'identifierSchemeCodeUnspsc190501.21102601': __('identifierSchemeCodeUnspsc190501.21102601'),
	'identifierSchemeCodeUnspsc190501.21110000': __('identifierSchemeCodeUnspsc190501.21110000'),
	'identifierSchemeCodeUnspsc190501.21111500': __('identifierSchemeCodeUnspsc190501.21111500'),
	'identifierSchemeCodeUnspsc190501.21111501': __('identifierSchemeCodeUnspsc190501.21111501'),
	'identifierSchemeCodeUnspsc190501.21111502': __('identifierSchemeCodeUnspsc190501.21111502'),
	'identifierSchemeCodeUnspsc190501.21111503': __('identifierSchemeCodeUnspsc190501.21111503'),
	'identifierSchemeCodeUnspsc190501.21111504': __('identifierSchemeCodeUnspsc190501.21111504'),
	'identifierSchemeCodeUnspsc190501.21111506': __('identifierSchemeCodeUnspsc190501.21111506'),
	'identifierSchemeCodeUnspsc190501.21111507': __('identifierSchemeCodeUnspsc190501.21111507'),
	'identifierSchemeCodeUnspsc190501.21111508': __('identifierSchemeCodeUnspsc190501.21111508'),
	'identifierSchemeCodeUnspsc190501.21111600': __('identifierSchemeCodeUnspsc190501.21111600'),
	'identifierSchemeCodeUnspsc190501.21111601': __('identifierSchemeCodeUnspsc190501.21111601'),
	'identifierSchemeCodeUnspsc190501.21111602': __('identifierSchemeCodeUnspsc190501.21111602'),
	'identifierSchemeCodeUnspsc190501.22000000': __('identifierSchemeCodeUnspsc190501.22000000'),
	'identifierSchemeCodeUnspsc190501.22100000': __('identifierSchemeCodeUnspsc190501.22100000'),
	'identifierSchemeCodeUnspsc190501.22101500': __('identifierSchemeCodeUnspsc190501.22101500'),
	'identifierSchemeCodeUnspsc190501.22101501': __('identifierSchemeCodeUnspsc190501.22101501'),
	'identifierSchemeCodeUnspsc190501.22101502': __('identifierSchemeCodeUnspsc190501.22101502'),
	'identifierSchemeCodeUnspsc190501.22101504': __('identifierSchemeCodeUnspsc190501.22101504'),
	'identifierSchemeCodeUnspsc190501.22101505': __('identifierSchemeCodeUnspsc190501.22101505'),
	'identifierSchemeCodeUnspsc190501.22101507': __('identifierSchemeCodeUnspsc190501.22101507'),
	'identifierSchemeCodeUnspsc190501.22101508': __('identifierSchemeCodeUnspsc190501.22101508'),
	'identifierSchemeCodeUnspsc190501.22101509': __('identifierSchemeCodeUnspsc190501.22101509'),
	'identifierSchemeCodeUnspsc190501.22101511': __('identifierSchemeCodeUnspsc190501.22101511'),
	'identifierSchemeCodeUnspsc190501.22101513': __('identifierSchemeCodeUnspsc190501.22101513'),
	'identifierSchemeCodeUnspsc190501.22101514': __('identifierSchemeCodeUnspsc190501.22101514'),
	'identifierSchemeCodeUnspsc190501.22101516': __('identifierSchemeCodeUnspsc190501.22101516'),
	'identifierSchemeCodeUnspsc190501.22101518': __('identifierSchemeCodeUnspsc190501.22101518'),
	'identifierSchemeCodeUnspsc190501.22101519': __('identifierSchemeCodeUnspsc190501.22101519'),
	'identifierSchemeCodeUnspsc190501.22101520': __('identifierSchemeCodeUnspsc190501.22101520'),
	'identifierSchemeCodeUnspsc190501.22101521': __('identifierSchemeCodeUnspsc190501.22101521'),
	'identifierSchemeCodeUnspsc190501.22101522': __('identifierSchemeCodeUnspsc190501.22101522'),
	'identifierSchemeCodeUnspsc190501.22101523': __('identifierSchemeCodeUnspsc190501.22101523'),
	'identifierSchemeCodeUnspsc190501.22101524': __('identifierSchemeCodeUnspsc190501.22101524'),
	'identifierSchemeCodeUnspsc190501.22101525': __('identifierSchemeCodeUnspsc190501.22101525'),
	'identifierSchemeCodeUnspsc190501.22101526': __('identifierSchemeCodeUnspsc190501.22101526'),
	'identifierSchemeCodeUnspsc190501.22101527': __('identifierSchemeCodeUnspsc190501.22101527'),
	'identifierSchemeCodeUnspsc190501.22101528': __('identifierSchemeCodeUnspsc190501.22101528'),
	'identifierSchemeCodeUnspsc190501.22101529': __('identifierSchemeCodeUnspsc190501.22101529'),
	'identifierSchemeCodeUnspsc190501.22101530': __('identifierSchemeCodeUnspsc190501.22101530'),
	'identifierSchemeCodeUnspsc190501.22101531': __('identifierSchemeCodeUnspsc190501.22101531'),
	'identifierSchemeCodeUnspsc190501.22101532': __('identifierSchemeCodeUnspsc190501.22101532'),
	'identifierSchemeCodeUnspsc190501.22101533': __('identifierSchemeCodeUnspsc190501.22101533'),
	'identifierSchemeCodeUnspsc190501.22101534': __('identifierSchemeCodeUnspsc190501.22101534'),
	'identifierSchemeCodeUnspsc190501.22101535': __('identifierSchemeCodeUnspsc190501.22101535'),
	'identifierSchemeCodeUnspsc190501.22101536': __('identifierSchemeCodeUnspsc190501.22101536'),
	'identifierSchemeCodeUnspsc190501.22101537': __('identifierSchemeCodeUnspsc190501.22101537'),
	'identifierSchemeCodeUnspsc190501.22101538': __('identifierSchemeCodeUnspsc190501.22101538'),
	'identifierSchemeCodeUnspsc190501.22101539': __('identifierSchemeCodeUnspsc190501.22101539'),
	'identifierSchemeCodeUnspsc190501.22101540': __('identifierSchemeCodeUnspsc190501.22101540'),
	'identifierSchemeCodeUnspsc190501.22101600': __('identifierSchemeCodeUnspsc190501.22101600'),
	'identifierSchemeCodeUnspsc190501.22101602': __('identifierSchemeCodeUnspsc190501.22101602'),
	'identifierSchemeCodeUnspsc190501.22101603': __('identifierSchemeCodeUnspsc190501.22101603'),
	'identifierSchemeCodeUnspsc190501.22101604': __('identifierSchemeCodeUnspsc190501.22101604'),
	'identifierSchemeCodeUnspsc190501.22101605': __('identifierSchemeCodeUnspsc190501.22101605'),
	'identifierSchemeCodeUnspsc190501.22101606': __('identifierSchemeCodeUnspsc190501.22101606'),
	'identifierSchemeCodeUnspsc190501.22101607': __('identifierSchemeCodeUnspsc190501.22101607'),
	'identifierSchemeCodeUnspsc190501.22101608': __('identifierSchemeCodeUnspsc190501.22101608'),
	'identifierSchemeCodeUnspsc190501.22101609': __('identifierSchemeCodeUnspsc190501.22101609'),
	'identifierSchemeCodeUnspsc190501.22101610': __('identifierSchemeCodeUnspsc190501.22101610'),
	'identifierSchemeCodeUnspsc190501.22101611': __('identifierSchemeCodeUnspsc190501.22101611'),
	'identifierSchemeCodeUnspsc190501.22101612': __('identifierSchemeCodeUnspsc190501.22101612'),
	'identifierSchemeCodeUnspsc190501.22101613': __('identifierSchemeCodeUnspsc190501.22101613'),
	'identifierSchemeCodeUnspsc190501.22101614': __('identifierSchemeCodeUnspsc190501.22101614'),
	'identifierSchemeCodeUnspsc190501.22101615': __('identifierSchemeCodeUnspsc190501.22101615'),
	'identifierSchemeCodeUnspsc190501.22101616': __('identifierSchemeCodeUnspsc190501.22101616'),
	'identifierSchemeCodeUnspsc190501.22101617': __('identifierSchemeCodeUnspsc190501.22101617'),
	'identifierSchemeCodeUnspsc190501.22101618': __('identifierSchemeCodeUnspsc190501.22101618'),
	'identifierSchemeCodeUnspsc190501.22101619': __('identifierSchemeCodeUnspsc190501.22101619'),
	'identifierSchemeCodeUnspsc190501.22101620': __('identifierSchemeCodeUnspsc190501.22101620'),
	'identifierSchemeCodeUnspsc190501.22101621': __('identifierSchemeCodeUnspsc190501.22101621'),
	'identifierSchemeCodeUnspsc190501.22101622': __('identifierSchemeCodeUnspsc190501.22101622'),
	'identifierSchemeCodeUnspsc190501.22101623': __('identifierSchemeCodeUnspsc190501.22101623'),
	'identifierSchemeCodeUnspsc190501.22101624': __('identifierSchemeCodeUnspsc190501.22101624'),
	'identifierSchemeCodeUnspsc190501.22101625': __('identifierSchemeCodeUnspsc190501.22101625'),
	'identifierSchemeCodeUnspsc190501.22101626': __('identifierSchemeCodeUnspsc190501.22101626'),
	'identifierSchemeCodeUnspsc190501.22101627': __('identifierSchemeCodeUnspsc190501.22101627'),
	'identifierSchemeCodeUnspsc190501.22101628': __('identifierSchemeCodeUnspsc190501.22101628'),
	'identifierSchemeCodeUnspsc190501.22101629': __('identifierSchemeCodeUnspsc190501.22101629'),
	'identifierSchemeCodeUnspsc190501.22101630': __('identifierSchemeCodeUnspsc190501.22101630'),
	'identifierSchemeCodeUnspsc190501.22101631': __('identifierSchemeCodeUnspsc190501.22101631'),
	'identifierSchemeCodeUnspsc190501.22101700': __('identifierSchemeCodeUnspsc190501.22101700'),
	'identifierSchemeCodeUnspsc190501.22101701': __('identifierSchemeCodeUnspsc190501.22101701'),
	'identifierSchemeCodeUnspsc190501.22101702': __('identifierSchemeCodeUnspsc190501.22101702'),
	'identifierSchemeCodeUnspsc190501.22101703': __('identifierSchemeCodeUnspsc190501.22101703'),
	'identifierSchemeCodeUnspsc190501.22101704': __('identifierSchemeCodeUnspsc190501.22101704'),
	'identifierSchemeCodeUnspsc190501.22101705': __('identifierSchemeCodeUnspsc190501.22101705'),
	'identifierSchemeCodeUnspsc190501.22101706': __('identifierSchemeCodeUnspsc190501.22101706'),
	'identifierSchemeCodeUnspsc190501.22101707': __('identifierSchemeCodeUnspsc190501.22101707'),
	'identifierSchemeCodeUnspsc190501.22101708': __('identifierSchemeCodeUnspsc190501.22101708'),
	'identifierSchemeCodeUnspsc190501.22101709': __('identifierSchemeCodeUnspsc190501.22101709'),
	'identifierSchemeCodeUnspsc190501.22101710': __('identifierSchemeCodeUnspsc190501.22101710'),
	'identifierSchemeCodeUnspsc190501.22101711': __('identifierSchemeCodeUnspsc190501.22101711'),
	'identifierSchemeCodeUnspsc190501.22101712': __('identifierSchemeCodeUnspsc190501.22101712'),
	'identifierSchemeCodeUnspsc190501.22101713': __('identifierSchemeCodeUnspsc190501.22101713'),
	'identifierSchemeCodeUnspsc190501.22101714': __('identifierSchemeCodeUnspsc190501.22101714'),
	'identifierSchemeCodeUnspsc190501.22101715': __('identifierSchemeCodeUnspsc190501.22101715'),
	'identifierSchemeCodeUnspsc190501.22101716': __('identifierSchemeCodeUnspsc190501.22101716'),
	'identifierSchemeCodeUnspsc190501.22101717': __('identifierSchemeCodeUnspsc190501.22101717'),
	'identifierSchemeCodeUnspsc190501.22101718': __('identifierSchemeCodeUnspsc190501.22101718'),
	'identifierSchemeCodeUnspsc190501.22101719': __('identifierSchemeCodeUnspsc190501.22101719'),
	'identifierSchemeCodeUnspsc190501.22101720': __('identifierSchemeCodeUnspsc190501.22101720'),
	'identifierSchemeCodeUnspsc190501.22101721': __('identifierSchemeCodeUnspsc190501.22101721'),
	'identifierSchemeCodeUnspsc190501.22101722': __('identifierSchemeCodeUnspsc190501.22101722'),
	'identifierSchemeCodeUnspsc190501.22101723': __('identifierSchemeCodeUnspsc190501.22101723'),
	'identifierSchemeCodeUnspsc190501.22101724': __('identifierSchemeCodeUnspsc190501.22101724'),
	'identifierSchemeCodeUnspsc190501.22101800': __('identifierSchemeCodeUnspsc190501.22101800'),
	'identifierSchemeCodeUnspsc190501.22101801': __('identifierSchemeCodeUnspsc190501.22101801'),
	'identifierSchemeCodeUnspsc190501.22101802': __('identifierSchemeCodeUnspsc190501.22101802'),
	'identifierSchemeCodeUnspsc190501.22101803': __('identifierSchemeCodeUnspsc190501.22101803'),
	'identifierSchemeCodeUnspsc190501.22101804': __('identifierSchemeCodeUnspsc190501.22101804'),
	'identifierSchemeCodeUnspsc190501.22101900': __('identifierSchemeCodeUnspsc190501.22101900'),
	'identifierSchemeCodeUnspsc190501.22101901': __('identifierSchemeCodeUnspsc190501.22101901'),
	'identifierSchemeCodeUnspsc190501.22101902': __('identifierSchemeCodeUnspsc190501.22101902'),
	'identifierSchemeCodeUnspsc190501.22101903': __('identifierSchemeCodeUnspsc190501.22101903'),
	'identifierSchemeCodeUnspsc190501.22101904': __('identifierSchemeCodeUnspsc190501.22101904'),
	'identifierSchemeCodeUnspsc190501.22101905': __('identifierSchemeCodeUnspsc190501.22101905'),
	'identifierSchemeCodeUnspsc190501.22101906': __('identifierSchemeCodeUnspsc190501.22101906'),
	'identifierSchemeCodeUnspsc190501.22101907': __('identifierSchemeCodeUnspsc190501.22101907'),
	'identifierSchemeCodeUnspsc190501.22101908': __('identifierSchemeCodeUnspsc190501.22101908'),
	'identifierSchemeCodeUnspsc190501.22102000': __('identifierSchemeCodeUnspsc190501.22102000'),
	'identifierSchemeCodeUnspsc190501.22102001': __('identifierSchemeCodeUnspsc190501.22102001'),
	'identifierSchemeCodeUnspsc190501.22102002': __('identifierSchemeCodeUnspsc190501.22102002'),
	'identifierSchemeCodeUnspsc190501.23000000': __('identifierSchemeCodeUnspsc190501.23000000'),
	'identifierSchemeCodeUnspsc190501.23100000': __('identifierSchemeCodeUnspsc190501.23100000'),
	'identifierSchemeCodeUnspsc190501.23101500': __('identifierSchemeCodeUnspsc190501.23101500'),
	'identifierSchemeCodeUnspsc190501.23101501': __('identifierSchemeCodeUnspsc190501.23101501'),
	'identifierSchemeCodeUnspsc190501.23101502': __('identifierSchemeCodeUnspsc190501.23101502'),
	'identifierSchemeCodeUnspsc190501.23101503': __('identifierSchemeCodeUnspsc190501.23101503'),
	'identifierSchemeCodeUnspsc190501.23101504': __('identifierSchemeCodeUnspsc190501.23101504'),
	'identifierSchemeCodeUnspsc190501.23101505': __('identifierSchemeCodeUnspsc190501.23101505'),
	'identifierSchemeCodeUnspsc190501.23101506': __('identifierSchemeCodeUnspsc190501.23101506'),
	'identifierSchemeCodeUnspsc190501.23101507': __('identifierSchemeCodeUnspsc190501.23101507'),
	'identifierSchemeCodeUnspsc190501.23101508': __('identifierSchemeCodeUnspsc190501.23101508'),
	'identifierSchemeCodeUnspsc190501.23101509': __('identifierSchemeCodeUnspsc190501.23101509'),
	'identifierSchemeCodeUnspsc190501.23101510': __('identifierSchemeCodeUnspsc190501.23101510'),
	'identifierSchemeCodeUnspsc190501.23101511': __('identifierSchemeCodeUnspsc190501.23101511'),
	'identifierSchemeCodeUnspsc190501.23101512': __('identifierSchemeCodeUnspsc190501.23101512'),
	'identifierSchemeCodeUnspsc190501.23101513': __('identifierSchemeCodeUnspsc190501.23101513'),
	'identifierSchemeCodeUnspsc190501.23101514': __('identifierSchemeCodeUnspsc190501.23101514'),
	'identifierSchemeCodeUnspsc190501.23101515': __('identifierSchemeCodeUnspsc190501.23101515'),
	'identifierSchemeCodeUnspsc190501.23101516': __('identifierSchemeCodeUnspsc190501.23101516'),
	'identifierSchemeCodeUnspsc190501.23101517': __('identifierSchemeCodeUnspsc190501.23101517'),
	'identifierSchemeCodeUnspsc190501.23101518': __('identifierSchemeCodeUnspsc190501.23101518'),
	'identifierSchemeCodeUnspsc190501.23101519': __('identifierSchemeCodeUnspsc190501.23101519'),
	'identifierSchemeCodeUnspsc190501.23101520': __('identifierSchemeCodeUnspsc190501.23101520'),
	'identifierSchemeCodeUnspsc190501.23101521': __('identifierSchemeCodeUnspsc190501.23101521'),
	'identifierSchemeCodeUnspsc190501.23101522': __('identifierSchemeCodeUnspsc190501.23101522'),
	'identifierSchemeCodeUnspsc190501.23101525': __('identifierSchemeCodeUnspsc190501.23101525'),
	'identifierSchemeCodeUnspsc190501.23101528': __('identifierSchemeCodeUnspsc190501.23101528'),
	'identifierSchemeCodeUnspsc190501.23101529': __('identifierSchemeCodeUnspsc190501.23101529'),
	'identifierSchemeCodeUnspsc190501.23101530': __('identifierSchemeCodeUnspsc190501.23101530'),
	'identifierSchemeCodeUnspsc190501.23101531': __('identifierSchemeCodeUnspsc190501.23101531'),
	'identifierSchemeCodeUnspsc190501.23101532': __('identifierSchemeCodeUnspsc190501.23101532'),
	'identifierSchemeCodeUnspsc190501.23101533': __('identifierSchemeCodeUnspsc190501.23101533'),
	'identifierSchemeCodeUnspsc190501.23101534': __('identifierSchemeCodeUnspsc190501.23101534'),
	'identifierSchemeCodeUnspsc190501.23101535': __('identifierSchemeCodeUnspsc190501.23101535'),
	'identifierSchemeCodeUnspsc190501.23101536': __('identifierSchemeCodeUnspsc190501.23101536'),
	'identifierSchemeCodeUnspsc190501.23101537': __('identifierSchemeCodeUnspsc190501.23101537'),
	'identifierSchemeCodeUnspsc190501.23101538': __('identifierSchemeCodeUnspsc190501.23101538'),
	'identifierSchemeCodeUnspsc190501.23101539': __('identifierSchemeCodeUnspsc190501.23101539'),
	'identifierSchemeCodeUnspsc190501.23101540': __('identifierSchemeCodeUnspsc190501.23101540'),
	'identifierSchemeCodeUnspsc190501.23110000': __('identifierSchemeCodeUnspsc190501.23110000'),
	'identifierSchemeCodeUnspsc190501.23111500': __('identifierSchemeCodeUnspsc190501.23111500'),
	'identifierSchemeCodeUnspsc190501.23111501': __('identifierSchemeCodeUnspsc190501.23111501'),
	'identifierSchemeCodeUnspsc190501.23111502': __('identifierSchemeCodeUnspsc190501.23111502'),
	'identifierSchemeCodeUnspsc190501.23111503': __('identifierSchemeCodeUnspsc190501.23111503'),
	'identifierSchemeCodeUnspsc190501.23111504': __('identifierSchemeCodeUnspsc190501.23111504'),
	'identifierSchemeCodeUnspsc190501.23111505': __('identifierSchemeCodeUnspsc190501.23111505'),
	'identifierSchemeCodeUnspsc190501.23111506': __('identifierSchemeCodeUnspsc190501.23111506'),
	'identifierSchemeCodeUnspsc190501.23111507': __('identifierSchemeCodeUnspsc190501.23111507'),
	'identifierSchemeCodeUnspsc190501.23111600': __('identifierSchemeCodeUnspsc190501.23111600'),
	'identifierSchemeCodeUnspsc190501.23111601': __('identifierSchemeCodeUnspsc190501.23111601'),
	'identifierSchemeCodeUnspsc190501.23111602': __('identifierSchemeCodeUnspsc190501.23111602'),
	'identifierSchemeCodeUnspsc190501.23111603': __('identifierSchemeCodeUnspsc190501.23111603'),
	'identifierSchemeCodeUnspsc190501.23111604': __('identifierSchemeCodeUnspsc190501.23111604'),
	'identifierSchemeCodeUnspsc190501.23111605': __('identifierSchemeCodeUnspsc190501.23111605'),
	'identifierSchemeCodeUnspsc190501.23111606': __('identifierSchemeCodeUnspsc190501.23111606'),
	'identifierSchemeCodeUnspsc190501.23120000': __('identifierSchemeCodeUnspsc190501.23120000'),
	'identifierSchemeCodeUnspsc190501.23121500': __('identifierSchemeCodeUnspsc190501.23121500'),
	'identifierSchemeCodeUnspsc190501.23121501': __('identifierSchemeCodeUnspsc190501.23121501'),
	'identifierSchemeCodeUnspsc190501.23121502': __('identifierSchemeCodeUnspsc190501.23121502'),
	'identifierSchemeCodeUnspsc190501.23121503': __('identifierSchemeCodeUnspsc190501.23121503'),
	'identifierSchemeCodeUnspsc190501.23121504': __('identifierSchemeCodeUnspsc190501.23121504'),
	'identifierSchemeCodeUnspsc190501.23121505': __('identifierSchemeCodeUnspsc190501.23121505'),
	'identifierSchemeCodeUnspsc190501.23121506': __('identifierSchemeCodeUnspsc190501.23121506'),
	'identifierSchemeCodeUnspsc190501.23121507': __('identifierSchemeCodeUnspsc190501.23121507'),
	'identifierSchemeCodeUnspsc190501.23121508': __('identifierSchemeCodeUnspsc190501.23121508'),
	'identifierSchemeCodeUnspsc190501.23121509': __('identifierSchemeCodeUnspsc190501.23121509'),
	'identifierSchemeCodeUnspsc190501.23121510': __('identifierSchemeCodeUnspsc190501.23121510'),
	'identifierSchemeCodeUnspsc190501.23121511': __('identifierSchemeCodeUnspsc190501.23121511'),
	'identifierSchemeCodeUnspsc190501.23121512': __('identifierSchemeCodeUnspsc190501.23121512'),
	'identifierSchemeCodeUnspsc190501.23121513': __('identifierSchemeCodeUnspsc190501.23121513'),
	'identifierSchemeCodeUnspsc190501.23121514': __('identifierSchemeCodeUnspsc190501.23121514'),
	'identifierSchemeCodeUnspsc190501.23121515': __('identifierSchemeCodeUnspsc190501.23121515'),
	'identifierSchemeCodeUnspsc190501.23121516': __('identifierSchemeCodeUnspsc190501.23121516'),
	'identifierSchemeCodeUnspsc190501.23121517': __('identifierSchemeCodeUnspsc190501.23121517'),
	'identifierSchemeCodeUnspsc190501.23121518': __('identifierSchemeCodeUnspsc190501.23121518'),
	'identifierSchemeCodeUnspsc190501.23121519': __('identifierSchemeCodeUnspsc190501.23121519'),
	'identifierSchemeCodeUnspsc190501.23121520': __('identifierSchemeCodeUnspsc190501.23121520'),
	'identifierSchemeCodeUnspsc190501.23121600': __('identifierSchemeCodeUnspsc190501.23121600'),
	'identifierSchemeCodeUnspsc190501.23121601': __('identifierSchemeCodeUnspsc190501.23121601'),
	'identifierSchemeCodeUnspsc190501.23121602': __('identifierSchemeCodeUnspsc190501.23121602'),
	'identifierSchemeCodeUnspsc190501.23121603': __('identifierSchemeCodeUnspsc190501.23121603'),
	'identifierSchemeCodeUnspsc190501.23121604': __('identifierSchemeCodeUnspsc190501.23121604'),
	'identifierSchemeCodeUnspsc190501.23121605': __('identifierSchemeCodeUnspsc190501.23121605'),
	'identifierSchemeCodeUnspsc190501.23121606': __('identifierSchemeCodeUnspsc190501.23121606'),
	'identifierSchemeCodeUnspsc190501.23121607': __('identifierSchemeCodeUnspsc190501.23121607'),
	'identifierSchemeCodeUnspsc190501.23121608': __('identifierSchemeCodeUnspsc190501.23121608'),
	'identifierSchemeCodeUnspsc190501.23121609': __('identifierSchemeCodeUnspsc190501.23121609'),
	'identifierSchemeCodeUnspsc190501.23121610': __('identifierSchemeCodeUnspsc190501.23121610'),
	'identifierSchemeCodeUnspsc190501.23121611': __('identifierSchemeCodeUnspsc190501.23121611'),
	'identifierSchemeCodeUnspsc190501.23121612': __('identifierSchemeCodeUnspsc190501.23121612'),
	'identifierSchemeCodeUnspsc190501.23121613': __('identifierSchemeCodeUnspsc190501.23121613'),
	'identifierSchemeCodeUnspsc190501.23121614': __('identifierSchemeCodeUnspsc190501.23121614'),
	'identifierSchemeCodeUnspsc190501.23121615': __('identifierSchemeCodeUnspsc190501.23121615'),
	'identifierSchemeCodeUnspsc190501.23121616': __('identifierSchemeCodeUnspsc190501.23121616'),
	'identifierSchemeCodeUnspsc190501.23121617': __('identifierSchemeCodeUnspsc190501.23121617'),
	'identifierSchemeCodeUnspsc190501.23121618': __('identifierSchemeCodeUnspsc190501.23121618'),
	'identifierSchemeCodeUnspsc190501.23121619': __('identifierSchemeCodeUnspsc190501.23121619'),
	'identifierSchemeCodeUnspsc190501.23121620': __('identifierSchemeCodeUnspsc190501.23121620'),
	'identifierSchemeCodeUnspsc190501.23121621': __('identifierSchemeCodeUnspsc190501.23121621'),
	'identifierSchemeCodeUnspsc190501.23130000': __('identifierSchemeCodeUnspsc190501.23130000'),
	'identifierSchemeCodeUnspsc190501.23131500': __('identifierSchemeCodeUnspsc190501.23131500'),
	'identifierSchemeCodeUnspsc190501.23131501': __('identifierSchemeCodeUnspsc190501.23131501'),
	'identifierSchemeCodeUnspsc190501.23131502': __('identifierSchemeCodeUnspsc190501.23131502'),
	'identifierSchemeCodeUnspsc190501.23131503': __('identifierSchemeCodeUnspsc190501.23131503'),
	'identifierSchemeCodeUnspsc190501.23131504': __('identifierSchemeCodeUnspsc190501.23131504'),
	'identifierSchemeCodeUnspsc190501.23131505': __('identifierSchemeCodeUnspsc190501.23131505'),
	'identifierSchemeCodeUnspsc190501.23131506': __('identifierSchemeCodeUnspsc190501.23131506'),
	'identifierSchemeCodeUnspsc190501.23131507': __('identifierSchemeCodeUnspsc190501.23131507'),
	'identifierSchemeCodeUnspsc190501.23131508': __('identifierSchemeCodeUnspsc190501.23131508'),
	'identifierSchemeCodeUnspsc190501.23131509': __('identifierSchemeCodeUnspsc190501.23131509'),
	'identifierSchemeCodeUnspsc190501.23131510': __('identifierSchemeCodeUnspsc190501.23131510'),
	'identifierSchemeCodeUnspsc190501.23131511': __('identifierSchemeCodeUnspsc190501.23131511'),
	'identifierSchemeCodeUnspsc190501.23131512': __('identifierSchemeCodeUnspsc190501.23131512'),
	'identifierSchemeCodeUnspsc190501.23131513': __('identifierSchemeCodeUnspsc190501.23131513'),
	'identifierSchemeCodeUnspsc190501.23131514': __('identifierSchemeCodeUnspsc190501.23131514'),
	'identifierSchemeCodeUnspsc190501.23131515': __('identifierSchemeCodeUnspsc190501.23131515'),
	'identifierSchemeCodeUnspsc190501.23131600': __('identifierSchemeCodeUnspsc190501.23131600'),
	'identifierSchemeCodeUnspsc190501.23131601': __('identifierSchemeCodeUnspsc190501.23131601'),
	'identifierSchemeCodeUnspsc190501.23131602': __('identifierSchemeCodeUnspsc190501.23131602'),
	'identifierSchemeCodeUnspsc190501.23131603': __('identifierSchemeCodeUnspsc190501.23131603'),
	'identifierSchemeCodeUnspsc190501.23131604': __('identifierSchemeCodeUnspsc190501.23131604'),
	'identifierSchemeCodeUnspsc190501.23131700': __('identifierSchemeCodeUnspsc190501.23131700'),
	'identifierSchemeCodeUnspsc190501.23131701': __('identifierSchemeCodeUnspsc190501.23131701'),
	'identifierSchemeCodeUnspsc190501.23131702': __('identifierSchemeCodeUnspsc190501.23131702'),
	'identifierSchemeCodeUnspsc190501.23131703': __('identifierSchemeCodeUnspsc190501.23131703'),
	'identifierSchemeCodeUnspsc190501.23131704': __('identifierSchemeCodeUnspsc190501.23131704'),
	'identifierSchemeCodeUnspsc190501.23140000': __('identifierSchemeCodeUnspsc190501.23140000'),
	'identifierSchemeCodeUnspsc190501.23141600': __('identifierSchemeCodeUnspsc190501.23141600'),
	'identifierSchemeCodeUnspsc190501.23141601': __('identifierSchemeCodeUnspsc190501.23141601'),
	'identifierSchemeCodeUnspsc190501.23141602': __('identifierSchemeCodeUnspsc190501.23141602'),
	'identifierSchemeCodeUnspsc190501.23141603': __('identifierSchemeCodeUnspsc190501.23141603'),
	'identifierSchemeCodeUnspsc190501.23141604': __('identifierSchemeCodeUnspsc190501.23141604'),
	'identifierSchemeCodeUnspsc190501.23141605': __('identifierSchemeCodeUnspsc190501.23141605'),
	'identifierSchemeCodeUnspsc190501.23141606': __('identifierSchemeCodeUnspsc190501.23141606'),
	'identifierSchemeCodeUnspsc190501.23141607': __('identifierSchemeCodeUnspsc190501.23141607'),
	'identifierSchemeCodeUnspsc190501.23141608': __('identifierSchemeCodeUnspsc190501.23141608'),
	'identifierSchemeCodeUnspsc190501.23141700': __('identifierSchemeCodeUnspsc190501.23141700'),
	'identifierSchemeCodeUnspsc190501.23141701': __('identifierSchemeCodeUnspsc190501.23141701'),
	'identifierSchemeCodeUnspsc190501.23141702': __('identifierSchemeCodeUnspsc190501.23141702'),
	'identifierSchemeCodeUnspsc190501.23141703': __('identifierSchemeCodeUnspsc190501.23141703'),
	'identifierSchemeCodeUnspsc190501.23141704': __('identifierSchemeCodeUnspsc190501.23141704'),
	'identifierSchemeCodeUnspsc190501.23150000': __('identifierSchemeCodeUnspsc190501.23150000'),
	'identifierSchemeCodeUnspsc190501.23151500': __('identifierSchemeCodeUnspsc190501.23151500'),
	'identifierSchemeCodeUnspsc190501.23151501': __('identifierSchemeCodeUnspsc190501.23151501'),
	'identifierSchemeCodeUnspsc190501.23151502': __('identifierSchemeCodeUnspsc190501.23151502'),
	'identifierSchemeCodeUnspsc190501.23151503': __('identifierSchemeCodeUnspsc190501.23151503'),
	'identifierSchemeCodeUnspsc190501.23151504': __('identifierSchemeCodeUnspsc190501.23151504'),
	'identifierSchemeCodeUnspsc190501.23151506': __('identifierSchemeCodeUnspsc190501.23151506'),
	'identifierSchemeCodeUnspsc190501.23151507': __('identifierSchemeCodeUnspsc190501.23151507'),
	'identifierSchemeCodeUnspsc190501.23151508': __('identifierSchemeCodeUnspsc190501.23151508'),
	'identifierSchemeCodeUnspsc190501.23151509': __('identifierSchemeCodeUnspsc190501.23151509'),
	'identifierSchemeCodeUnspsc190501.23151510': __('identifierSchemeCodeUnspsc190501.23151510'),
	'identifierSchemeCodeUnspsc190501.23151511': __('identifierSchemeCodeUnspsc190501.23151511'),
	'identifierSchemeCodeUnspsc190501.23151512': __('identifierSchemeCodeUnspsc190501.23151512'),
	'identifierSchemeCodeUnspsc190501.23151513': __('identifierSchemeCodeUnspsc190501.23151513'),
	'identifierSchemeCodeUnspsc190501.23151514': __('identifierSchemeCodeUnspsc190501.23151514'),
	'identifierSchemeCodeUnspsc190501.23151515': __('identifierSchemeCodeUnspsc190501.23151515'),
	'identifierSchemeCodeUnspsc190501.23151516': __('identifierSchemeCodeUnspsc190501.23151516'),
	'identifierSchemeCodeUnspsc190501.23151517': __('identifierSchemeCodeUnspsc190501.23151517'),
	'identifierSchemeCodeUnspsc190501.23151518': __('identifierSchemeCodeUnspsc190501.23151518'),
	'identifierSchemeCodeUnspsc190501.23151519': __('identifierSchemeCodeUnspsc190501.23151519'),
	'identifierSchemeCodeUnspsc190501.23151520': __('identifierSchemeCodeUnspsc190501.23151520'),
	'identifierSchemeCodeUnspsc190501.23151600': __('identifierSchemeCodeUnspsc190501.23151600'),
	'identifierSchemeCodeUnspsc190501.23151601': __('identifierSchemeCodeUnspsc190501.23151601'),
	'identifierSchemeCodeUnspsc190501.23151602': __('identifierSchemeCodeUnspsc190501.23151602'),
	'identifierSchemeCodeUnspsc190501.23151603': __('identifierSchemeCodeUnspsc190501.23151603'),
	'identifierSchemeCodeUnspsc190501.23151604': __('identifierSchemeCodeUnspsc190501.23151604'),
	'identifierSchemeCodeUnspsc190501.23151606': __('identifierSchemeCodeUnspsc190501.23151606'),
	'identifierSchemeCodeUnspsc190501.23151607': __('identifierSchemeCodeUnspsc190501.23151607'),
	'identifierSchemeCodeUnspsc190501.23151608': __('identifierSchemeCodeUnspsc190501.23151608'),
	'identifierSchemeCodeUnspsc190501.23151609': __('identifierSchemeCodeUnspsc190501.23151609'),
	'identifierSchemeCodeUnspsc190501.23151610': __('identifierSchemeCodeUnspsc190501.23151610'),
	'identifierSchemeCodeUnspsc190501.23151700': __('identifierSchemeCodeUnspsc190501.23151700'),
	'identifierSchemeCodeUnspsc190501.23151701': __('identifierSchemeCodeUnspsc190501.23151701'),
	'identifierSchemeCodeUnspsc190501.23151702': __('identifierSchemeCodeUnspsc190501.23151702'),
	'identifierSchemeCodeUnspsc190501.23151703': __('identifierSchemeCodeUnspsc190501.23151703'),
	'identifierSchemeCodeUnspsc190501.23151704': __('identifierSchemeCodeUnspsc190501.23151704'),
	'identifierSchemeCodeUnspsc190501.23151705': __('identifierSchemeCodeUnspsc190501.23151705'),
	'identifierSchemeCodeUnspsc190501.23151800': __('identifierSchemeCodeUnspsc190501.23151800'),
	'identifierSchemeCodeUnspsc190501.23151801': __('identifierSchemeCodeUnspsc190501.23151801'),
	'identifierSchemeCodeUnspsc190501.23151802': __('identifierSchemeCodeUnspsc190501.23151802'),
	'identifierSchemeCodeUnspsc190501.23151803': __('identifierSchemeCodeUnspsc190501.23151803'),
	'identifierSchemeCodeUnspsc190501.23151804': __('identifierSchemeCodeUnspsc190501.23151804'),
	'identifierSchemeCodeUnspsc190501.23151805': __('identifierSchemeCodeUnspsc190501.23151805'),
	'identifierSchemeCodeUnspsc190501.23151806': __('identifierSchemeCodeUnspsc190501.23151806'),
	'identifierSchemeCodeUnspsc190501.23151807': __('identifierSchemeCodeUnspsc190501.23151807'),
	'identifierSchemeCodeUnspsc190501.23151808': __('identifierSchemeCodeUnspsc190501.23151808'),
	'identifierSchemeCodeUnspsc190501.23151809': __('identifierSchemeCodeUnspsc190501.23151809'),
	'identifierSchemeCodeUnspsc190501.23151810': __('identifierSchemeCodeUnspsc190501.23151810'),
	'identifierSchemeCodeUnspsc190501.23151811': __('identifierSchemeCodeUnspsc190501.23151811'),
	'identifierSchemeCodeUnspsc190501.23151812': __('identifierSchemeCodeUnspsc190501.23151812'),
	'identifierSchemeCodeUnspsc190501.23151813': __('identifierSchemeCodeUnspsc190501.23151813'),
	'identifierSchemeCodeUnspsc190501.23151814': __('identifierSchemeCodeUnspsc190501.23151814'),
	'identifierSchemeCodeUnspsc190501.23151816': __('identifierSchemeCodeUnspsc190501.23151816'),
	'identifierSchemeCodeUnspsc190501.23151817': __('identifierSchemeCodeUnspsc190501.23151817'),
	'identifierSchemeCodeUnspsc190501.23151818': __('identifierSchemeCodeUnspsc190501.23151818'),
	'identifierSchemeCodeUnspsc190501.23151819': __('identifierSchemeCodeUnspsc190501.23151819'),
	'identifierSchemeCodeUnspsc190501.23151821': __('identifierSchemeCodeUnspsc190501.23151821'),
	'identifierSchemeCodeUnspsc190501.23151822': __('identifierSchemeCodeUnspsc190501.23151822'),
	'identifierSchemeCodeUnspsc190501.23151823': __('identifierSchemeCodeUnspsc190501.23151823'),
	'identifierSchemeCodeUnspsc190501.23151824': __('identifierSchemeCodeUnspsc190501.23151824'),
	'identifierSchemeCodeUnspsc190501.23151825': __('identifierSchemeCodeUnspsc190501.23151825'),
	'identifierSchemeCodeUnspsc190501.23151826': __('identifierSchemeCodeUnspsc190501.23151826'),
	'identifierSchemeCodeUnspsc190501.23151900': __('identifierSchemeCodeUnspsc190501.23151900'),
	'identifierSchemeCodeUnspsc190501.23151901': __('identifierSchemeCodeUnspsc190501.23151901'),
	'identifierSchemeCodeUnspsc190501.23151902': __('identifierSchemeCodeUnspsc190501.23151902'),
	'identifierSchemeCodeUnspsc190501.23151903': __('identifierSchemeCodeUnspsc190501.23151903'),
	'identifierSchemeCodeUnspsc190501.23151904': __('identifierSchemeCodeUnspsc190501.23151904'),
	'identifierSchemeCodeUnspsc190501.23151905': __('identifierSchemeCodeUnspsc190501.23151905'),
	'identifierSchemeCodeUnspsc190501.23151906': __('identifierSchemeCodeUnspsc190501.23151906'),
	'identifierSchemeCodeUnspsc190501.23151907': __('identifierSchemeCodeUnspsc190501.23151907'),
	'identifierSchemeCodeUnspsc190501.23151908': __('identifierSchemeCodeUnspsc190501.23151908'),
	'identifierSchemeCodeUnspsc190501.23151909': __('identifierSchemeCodeUnspsc190501.23151909'),
	'identifierSchemeCodeUnspsc190501.23151910': __('identifierSchemeCodeUnspsc190501.23151910'),
	'identifierSchemeCodeUnspsc190501.23151911': __('identifierSchemeCodeUnspsc190501.23151911'),
	'identifierSchemeCodeUnspsc190501.23152000': __('identifierSchemeCodeUnspsc190501.23152000'),
	'identifierSchemeCodeUnspsc190501.23152001': __('identifierSchemeCodeUnspsc190501.23152001'),
	'identifierSchemeCodeUnspsc190501.23152002': __('identifierSchemeCodeUnspsc190501.23152002'),
	'identifierSchemeCodeUnspsc190501.23152100': __('identifierSchemeCodeUnspsc190501.23152100'),
	'identifierSchemeCodeUnspsc190501.23152101': __('identifierSchemeCodeUnspsc190501.23152101'),
	'identifierSchemeCodeUnspsc190501.23152102': __('identifierSchemeCodeUnspsc190501.23152102'),
	'identifierSchemeCodeUnspsc190501.23152103': __('identifierSchemeCodeUnspsc190501.23152103'),
	'identifierSchemeCodeUnspsc190501.23152104': __('identifierSchemeCodeUnspsc190501.23152104'),
	'identifierSchemeCodeUnspsc190501.23152105': __('identifierSchemeCodeUnspsc190501.23152105'),
	'identifierSchemeCodeUnspsc190501.23152106': __('identifierSchemeCodeUnspsc190501.23152106'),
	'identifierSchemeCodeUnspsc190501.23152107': __('identifierSchemeCodeUnspsc190501.23152107'),
	'identifierSchemeCodeUnspsc190501.23152108': __('identifierSchemeCodeUnspsc190501.23152108'),
	'identifierSchemeCodeUnspsc190501.23152109': __('identifierSchemeCodeUnspsc190501.23152109'),
	'identifierSchemeCodeUnspsc190501.23152110': __('identifierSchemeCodeUnspsc190501.23152110'),
	'identifierSchemeCodeUnspsc190501.23152111': __('identifierSchemeCodeUnspsc190501.23152111'),
	'identifierSchemeCodeUnspsc190501.23152112': __('identifierSchemeCodeUnspsc190501.23152112'),
	'identifierSchemeCodeUnspsc190501.23152113': __('identifierSchemeCodeUnspsc190501.23152113'),
	'identifierSchemeCodeUnspsc190501.23152200': __('identifierSchemeCodeUnspsc190501.23152200'),
	'identifierSchemeCodeUnspsc190501.23152201': __('identifierSchemeCodeUnspsc190501.23152201'),
	'identifierSchemeCodeUnspsc190501.23152202': __('identifierSchemeCodeUnspsc190501.23152202'),
	'identifierSchemeCodeUnspsc190501.23152203': __('identifierSchemeCodeUnspsc190501.23152203'),
	'identifierSchemeCodeUnspsc190501.23152204': __('identifierSchemeCodeUnspsc190501.23152204'),
	'identifierSchemeCodeUnspsc190501.23152205': __('identifierSchemeCodeUnspsc190501.23152205'),
	'identifierSchemeCodeUnspsc190501.23152206': __('identifierSchemeCodeUnspsc190501.23152206'),
	'identifierSchemeCodeUnspsc190501.23152900': __('identifierSchemeCodeUnspsc190501.23152900'),
	'identifierSchemeCodeUnspsc190501.23152901': __('identifierSchemeCodeUnspsc190501.23152901'),
	'identifierSchemeCodeUnspsc190501.23152902': __('identifierSchemeCodeUnspsc190501.23152902'),
	'identifierSchemeCodeUnspsc190501.23152903': __('identifierSchemeCodeUnspsc190501.23152903'),
	'identifierSchemeCodeUnspsc190501.23152904': __('identifierSchemeCodeUnspsc190501.23152904'),
	'identifierSchemeCodeUnspsc190501.23152905': __('identifierSchemeCodeUnspsc190501.23152905'),
	'identifierSchemeCodeUnspsc190501.23152906': __('identifierSchemeCodeUnspsc190501.23152906'),
	'identifierSchemeCodeUnspsc190501.23152907': __('identifierSchemeCodeUnspsc190501.23152907'),
	'identifierSchemeCodeUnspsc190501.23152908': __('identifierSchemeCodeUnspsc190501.23152908'),
	'identifierSchemeCodeUnspsc190501.23152909': __('identifierSchemeCodeUnspsc190501.23152909'),
	'identifierSchemeCodeUnspsc190501.23152910': __('identifierSchemeCodeUnspsc190501.23152910'),
	'identifierSchemeCodeUnspsc190501.23152911': __('identifierSchemeCodeUnspsc190501.23152911'),
	'identifierSchemeCodeUnspsc190501.23152912': __('identifierSchemeCodeUnspsc190501.23152912'),
	'identifierSchemeCodeUnspsc190501.23152913': __('identifierSchemeCodeUnspsc190501.23152913'),
	'identifierSchemeCodeUnspsc190501.23153000': __('identifierSchemeCodeUnspsc190501.23153000'),
	'identifierSchemeCodeUnspsc190501.23153001': __('identifierSchemeCodeUnspsc190501.23153001'),
	'identifierSchemeCodeUnspsc190501.23153002': __('identifierSchemeCodeUnspsc190501.23153002'),
	'identifierSchemeCodeUnspsc190501.23153003': __('identifierSchemeCodeUnspsc190501.23153003'),
	'identifierSchemeCodeUnspsc190501.23153004': __('identifierSchemeCodeUnspsc190501.23153004'),
	'identifierSchemeCodeUnspsc190501.23153005': __('identifierSchemeCodeUnspsc190501.23153005'),
	'identifierSchemeCodeUnspsc190501.23153006': __('identifierSchemeCodeUnspsc190501.23153006'),
	'identifierSchemeCodeUnspsc190501.23153007': __('identifierSchemeCodeUnspsc190501.23153007'),
	'identifierSchemeCodeUnspsc190501.23153008': __('identifierSchemeCodeUnspsc190501.23153008'),
	'identifierSchemeCodeUnspsc190501.23153009': __('identifierSchemeCodeUnspsc190501.23153009'),
	'identifierSchemeCodeUnspsc190501.23153010': __('identifierSchemeCodeUnspsc190501.23153010'),
	'identifierSchemeCodeUnspsc190501.23153011': __('identifierSchemeCodeUnspsc190501.23153011'),
	'identifierSchemeCodeUnspsc190501.23153012': __('identifierSchemeCodeUnspsc190501.23153012'),
	'identifierSchemeCodeUnspsc190501.23153013': __('identifierSchemeCodeUnspsc190501.23153013'),
	'identifierSchemeCodeUnspsc190501.23153014': __('identifierSchemeCodeUnspsc190501.23153014'),
	'identifierSchemeCodeUnspsc190501.23153015': __('identifierSchemeCodeUnspsc190501.23153015'),
	'identifierSchemeCodeUnspsc190501.23153016': __('identifierSchemeCodeUnspsc190501.23153016'),
	'identifierSchemeCodeUnspsc190501.23153017': __('identifierSchemeCodeUnspsc190501.23153017'),
	'identifierSchemeCodeUnspsc190501.23153018': __('identifierSchemeCodeUnspsc190501.23153018'),
	'identifierSchemeCodeUnspsc190501.23153019': __('identifierSchemeCodeUnspsc190501.23153019'),
	'identifierSchemeCodeUnspsc190501.23153020': __('identifierSchemeCodeUnspsc190501.23153020'),
	'identifierSchemeCodeUnspsc190501.23153021': __('identifierSchemeCodeUnspsc190501.23153021'),
	'identifierSchemeCodeUnspsc190501.23153022': __('identifierSchemeCodeUnspsc190501.23153022'),
	'identifierSchemeCodeUnspsc190501.23153023': __('identifierSchemeCodeUnspsc190501.23153023'),
	'identifierSchemeCodeUnspsc190501.23153024': __('identifierSchemeCodeUnspsc190501.23153024'),
	'identifierSchemeCodeUnspsc190501.23153025': __('identifierSchemeCodeUnspsc190501.23153025'),
	'identifierSchemeCodeUnspsc190501.23153026': __('identifierSchemeCodeUnspsc190501.23153026'),
	'identifierSchemeCodeUnspsc190501.23153027': __('identifierSchemeCodeUnspsc190501.23153027'),
	'identifierSchemeCodeUnspsc190501.23153028': __('identifierSchemeCodeUnspsc190501.23153028'),
	'identifierSchemeCodeUnspsc190501.23153029': __('identifierSchemeCodeUnspsc190501.23153029'),
	'identifierSchemeCodeUnspsc190501.23153030': __('identifierSchemeCodeUnspsc190501.23153030'),
	'identifierSchemeCodeUnspsc190501.23153031': __('identifierSchemeCodeUnspsc190501.23153031'),
	'identifierSchemeCodeUnspsc190501.23153032': __('identifierSchemeCodeUnspsc190501.23153032'),
	'identifierSchemeCodeUnspsc190501.23153033': __('identifierSchemeCodeUnspsc190501.23153033'),
	'identifierSchemeCodeUnspsc190501.23153034': __('identifierSchemeCodeUnspsc190501.23153034'),
	'identifierSchemeCodeUnspsc190501.23153035': __('identifierSchemeCodeUnspsc190501.23153035'),
	'identifierSchemeCodeUnspsc190501.23153036': __('identifierSchemeCodeUnspsc190501.23153036'),
	'identifierSchemeCodeUnspsc190501.23153037': __('identifierSchemeCodeUnspsc190501.23153037'),
	'identifierSchemeCodeUnspsc190501.23153038': __('identifierSchemeCodeUnspsc190501.23153038'),
	'identifierSchemeCodeUnspsc190501.23153039': __('identifierSchemeCodeUnspsc190501.23153039'),
	'identifierSchemeCodeUnspsc190501.23153100': __('identifierSchemeCodeUnspsc190501.23153100'),
	'identifierSchemeCodeUnspsc190501.23153101': __('identifierSchemeCodeUnspsc190501.23153101'),
	'identifierSchemeCodeUnspsc190501.23153102': __('identifierSchemeCodeUnspsc190501.23153102'),
	'identifierSchemeCodeUnspsc190501.23153103': __('identifierSchemeCodeUnspsc190501.23153103'),
	'identifierSchemeCodeUnspsc190501.23153129': __('identifierSchemeCodeUnspsc190501.23153129'),
	'identifierSchemeCodeUnspsc190501.23153130': __('identifierSchemeCodeUnspsc190501.23153130'),
	'identifierSchemeCodeUnspsc190501.23153131': __('identifierSchemeCodeUnspsc190501.23153131'),
	'identifierSchemeCodeUnspsc190501.23153132': __('identifierSchemeCodeUnspsc190501.23153132'),
	'identifierSchemeCodeUnspsc190501.23153133': __('identifierSchemeCodeUnspsc190501.23153133'),
	'identifierSchemeCodeUnspsc190501.23153134': __('identifierSchemeCodeUnspsc190501.23153134'),
	'identifierSchemeCodeUnspsc190501.23153135': __('identifierSchemeCodeUnspsc190501.23153135'),
	'identifierSchemeCodeUnspsc190501.23153136': __('identifierSchemeCodeUnspsc190501.23153136'),
	'identifierSchemeCodeUnspsc190501.23153137': __('identifierSchemeCodeUnspsc190501.23153137'),
	'identifierSchemeCodeUnspsc190501.23153138': __('identifierSchemeCodeUnspsc190501.23153138'),
	'identifierSchemeCodeUnspsc190501.23153139': __('identifierSchemeCodeUnspsc190501.23153139'),
	'identifierSchemeCodeUnspsc190501.23153140': __('identifierSchemeCodeUnspsc190501.23153140'),
	'identifierSchemeCodeUnspsc190501.23153141': __('identifierSchemeCodeUnspsc190501.23153141'),
	'identifierSchemeCodeUnspsc190501.23153142': __('identifierSchemeCodeUnspsc190501.23153142'),
	'identifierSchemeCodeUnspsc190501.23153143': __('identifierSchemeCodeUnspsc190501.23153143'),
	'identifierSchemeCodeUnspsc190501.23153144': __('identifierSchemeCodeUnspsc190501.23153144'),
	'identifierSchemeCodeUnspsc190501.23153145': __('identifierSchemeCodeUnspsc190501.23153145'),
	'identifierSchemeCodeUnspsc190501.23153200': __('identifierSchemeCodeUnspsc190501.23153200'),
	'identifierSchemeCodeUnspsc190501.23153201': __('identifierSchemeCodeUnspsc190501.23153201'),
	'identifierSchemeCodeUnspsc190501.23153202': __('identifierSchemeCodeUnspsc190501.23153202'),
	'identifierSchemeCodeUnspsc190501.23153203': __('identifierSchemeCodeUnspsc190501.23153203'),
	'identifierSchemeCodeUnspsc190501.23153204': __('identifierSchemeCodeUnspsc190501.23153204'),
	'identifierSchemeCodeUnspsc190501.23153205': __('identifierSchemeCodeUnspsc190501.23153205'),
	'identifierSchemeCodeUnspsc190501.23153206': __('identifierSchemeCodeUnspsc190501.23153206'),
	'identifierSchemeCodeUnspsc190501.23153207': __('identifierSchemeCodeUnspsc190501.23153207'),
	'identifierSchemeCodeUnspsc190501.23153400': __('identifierSchemeCodeUnspsc190501.23153400'),
	'identifierSchemeCodeUnspsc190501.23153401': __('identifierSchemeCodeUnspsc190501.23153401'),
	'identifierSchemeCodeUnspsc190501.23153402': __('identifierSchemeCodeUnspsc190501.23153402'),
	'identifierSchemeCodeUnspsc190501.23153403': __('identifierSchemeCodeUnspsc190501.23153403'),
	'identifierSchemeCodeUnspsc190501.23153404': __('identifierSchemeCodeUnspsc190501.23153404'),
	'identifierSchemeCodeUnspsc190501.23153405': __('identifierSchemeCodeUnspsc190501.23153405'),
	'identifierSchemeCodeUnspsc190501.23153406': __('identifierSchemeCodeUnspsc190501.23153406'),
	'identifierSchemeCodeUnspsc190501.23153407': __('identifierSchemeCodeUnspsc190501.23153407'),
	'identifierSchemeCodeUnspsc190501.23153408': __('identifierSchemeCodeUnspsc190501.23153408'),
	'identifierSchemeCodeUnspsc190501.23153409': __('identifierSchemeCodeUnspsc190501.23153409'),
	'identifierSchemeCodeUnspsc190501.23153410': __('identifierSchemeCodeUnspsc190501.23153410'),
	'identifierSchemeCodeUnspsc190501.23153411': __('identifierSchemeCodeUnspsc190501.23153411'),
	'identifierSchemeCodeUnspsc190501.23153412': __('identifierSchemeCodeUnspsc190501.23153412'),
	'identifierSchemeCodeUnspsc190501.23153413': __('identifierSchemeCodeUnspsc190501.23153413'),
	'identifierSchemeCodeUnspsc190501.23153414': __('identifierSchemeCodeUnspsc190501.23153414'),
	'identifierSchemeCodeUnspsc190501.23153415': __('identifierSchemeCodeUnspsc190501.23153415'),
	'identifierSchemeCodeUnspsc190501.23153416': __('identifierSchemeCodeUnspsc190501.23153416'),
	'identifierSchemeCodeUnspsc190501.23153417': __('identifierSchemeCodeUnspsc190501.23153417'),
	'identifierSchemeCodeUnspsc190501.23153418': __('identifierSchemeCodeUnspsc190501.23153418'),
	'identifierSchemeCodeUnspsc190501.23153419': __('identifierSchemeCodeUnspsc190501.23153419'),
	'identifierSchemeCodeUnspsc190501.23153420': __('identifierSchemeCodeUnspsc190501.23153420'),
	'identifierSchemeCodeUnspsc190501.23153421': __('identifierSchemeCodeUnspsc190501.23153421'),
	'identifierSchemeCodeUnspsc190501.23153500': __('identifierSchemeCodeUnspsc190501.23153500'),
	'identifierSchemeCodeUnspsc190501.23153501': __('identifierSchemeCodeUnspsc190501.23153501'),
	'identifierSchemeCodeUnspsc190501.23153502': __('identifierSchemeCodeUnspsc190501.23153502'),
	'identifierSchemeCodeUnspsc190501.23153503': __('identifierSchemeCodeUnspsc190501.23153503'),
	'identifierSchemeCodeUnspsc190501.23153504': __('identifierSchemeCodeUnspsc190501.23153504'),
	'identifierSchemeCodeUnspsc190501.23153505': __('identifierSchemeCodeUnspsc190501.23153505'),
	'identifierSchemeCodeUnspsc190501.23153506': __('identifierSchemeCodeUnspsc190501.23153506'),
	'identifierSchemeCodeUnspsc190501.23153507': __('identifierSchemeCodeUnspsc190501.23153507'),
	'identifierSchemeCodeUnspsc190501.23153508': __('identifierSchemeCodeUnspsc190501.23153508'),
	'identifierSchemeCodeUnspsc190501.23153600': __('identifierSchemeCodeUnspsc190501.23153600'),
	'identifierSchemeCodeUnspsc190501.23153601': __('identifierSchemeCodeUnspsc190501.23153601'),
	'identifierSchemeCodeUnspsc190501.23153602': __('identifierSchemeCodeUnspsc190501.23153602'),
	'identifierSchemeCodeUnspsc190501.23153603': __('identifierSchemeCodeUnspsc190501.23153603'),
	'identifierSchemeCodeUnspsc190501.23153604': __('identifierSchemeCodeUnspsc190501.23153604'),
	'identifierSchemeCodeUnspsc190501.23153605': __('identifierSchemeCodeUnspsc190501.23153605'),
	'identifierSchemeCodeUnspsc190501.23153606': __('identifierSchemeCodeUnspsc190501.23153606'),
	'identifierSchemeCodeUnspsc190501.23153607': __('identifierSchemeCodeUnspsc190501.23153607'),
	'identifierSchemeCodeUnspsc190501.23153608': __('identifierSchemeCodeUnspsc190501.23153608'),
	'identifierSchemeCodeUnspsc190501.23153700': __('identifierSchemeCodeUnspsc190501.23153700'),
	'identifierSchemeCodeUnspsc190501.23153701': __('identifierSchemeCodeUnspsc190501.23153701'),
	'identifierSchemeCodeUnspsc190501.23153702': __('identifierSchemeCodeUnspsc190501.23153702'),
	'identifierSchemeCodeUnspsc190501.23153800': __('identifierSchemeCodeUnspsc190501.23153800'),
	'identifierSchemeCodeUnspsc190501.23153801': __('identifierSchemeCodeUnspsc190501.23153801'),
	'identifierSchemeCodeUnspsc190501.23153802': __('identifierSchemeCodeUnspsc190501.23153802'),
	'identifierSchemeCodeUnspsc190501.23160000': __('identifierSchemeCodeUnspsc190501.23160000'),
	'identifierSchemeCodeUnspsc190501.23161500': __('identifierSchemeCodeUnspsc190501.23161500'),
	'identifierSchemeCodeUnspsc190501.23161501': __('identifierSchemeCodeUnspsc190501.23161501'),
	'identifierSchemeCodeUnspsc190501.23161502': __('identifierSchemeCodeUnspsc190501.23161502'),
	'identifierSchemeCodeUnspsc190501.23161503': __('identifierSchemeCodeUnspsc190501.23161503'),
	'identifierSchemeCodeUnspsc190501.23161506': __('identifierSchemeCodeUnspsc190501.23161506'),
	'identifierSchemeCodeUnspsc190501.23161507': __('identifierSchemeCodeUnspsc190501.23161507'),
	'identifierSchemeCodeUnspsc190501.23161510': __('identifierSchemeCodeUnspsc190501.23161510'),
	'identifierSchemeCodeUnspsc190501.23161514': __('identifierSchemeCodeUnspsc190501.23161514'),
	'identifierSchemeCodeUnspsc190501.23161516': __('identifierSchemeCodeUnspsc190501.23161516'),
	'identifierSchemeCodeUnspsc190501.23161517': __('identifierSchemeCodeUnspsc190501.23161517'),
	'identifierSchemeCodeUnspsc190501.23161600': __('identifierSchemeCodeUnspsc190501.23161600'),
	'identifierSchemeCodeUnspsc190501.23161601': __('identifierSchemeCodeUnspsc190501.23161601'),
	'identifierSchemeCodeUnspsc190501.23161602': __('identifierSchemeCodeUnspsc190501.23161602'),
	'identifierSchemeCodeUnspsc190501.23161603': __('identifierSchemeCodeUnspsc190501.23161603'),
	'identifierSchemeCodeUnspsc190501.23161605': __('identifierSchemeCodeUnspsc190501.23161605'),
	'identifierSchemeCodeUnspsc190501.23161606': __('identifierSchemeCodeUnspsc190501.23161606'),
	'identifierSchemeCodeUnspsc190501.23161607': __('identifierSchemeCodeUnspsc190501.23161607'),
	'identifierSchemeCodeUnspsc190501.23161608': __('identifierSchemeCodeUnspsc190501.23161608'),
	'identifierSchemeCodeUnspsc190501.23161700': __('identifierSchemeCodeUnspsc190501.23161700'),
	'identifierSchemeCodeUnspsc190501.23161701': __('identifierSchemeCodeUnspsc190501.23161701'),
	'identifierSchemeCodeUnspsc190501.23161702': __('identifierSchemeCodeUnspsc190501.23161702'),
	'identifierSchemeCodeUnspsc190501.23180000': __('identifierSchemeCodeUnspsc190501.23180000'),
	'identifierSchemeCodeUnspsc190501.23181500': __('identifierSchemeCodeUnspsc190501.23181500'),
	'identifierSchemeCodeUnspsc190501.23181501': __('identifierSchemeCodeUnspsc190501.23181501'),
	'identifierSchemeCodeUnspsc190501.23181502': __('identifierSchemeCodeUnspsc190501.23181502'),
	'identifierSchemeCodeUnspsc190501.23181504': __('identifierSchemeCodeUnspsc190501.23181504'),
	'identifierSchemeCodeUnspsc190501.23181505': __('identifierSchemeCodeUnspsc190501.23181505'),
	'identifierSchemeCodeUnspsc190501.23181506': __('identifierSchemeCodeUnspsc190501.23181506'),
	'identifierSchemeCodeUnspsc190501.23181507': __('identifierSchemeCodeUnspsc190501.23181507'),
	'identifierSchemeCodeUnspsc190501.23181508': __('identifierSchemeCodeUnspsc190501.23181508'),
	'identifierSchemeCodeUnspsc190501.23181509': __('identifierSchemeCodeUnspsc190501.23181509'),
	'identifierSchemeCodeUnspsc190501.23181510': __('identifierSchemeCodeUnspsc190501.23181510'),
	'identifierSchemeCodeUnspsc190501.23181511': __('identifierSchemeCodeUnspsc190501.23181511'),
	'identifierSchemeCodeUnspsc190501.23181512': __('identifierSchemeCodeUnspsc190501.23181512'),
	'identifierSchemeCodeUnspsc190501.23181513': __('identifierSchemeCodeUnspsc190501.23181513'),
	'identifierSchemeCodeUnspsc190501.23181514': __('identifierSchemeCodeUnspsc190501.23181514'),
	'identifierSchemeCodeUnspsc190501.23181515': __('identifierSchemeCodeUnspsc190501.23181515'),
	'identifierSchemeCodeUnspsc190501.23181516': __('identifierSchemeCodeUnspsc190501.23181516'),
	'identifierSchemeCodeUnspsc190501.23181517': __('identifierSchemeCodeUnspsc190501.23181517'),
	'identifierSchemeCodeUnspsc190501.23181518': __('identifierSchemeCodeUnspsc190501.23181518'),
	'identifierSchemeCodeUnspsc190501.23181519': __('identifierSchemeCodeUnspsc190501.23181519'),
	'identifierSchemeCodeUnspsc190501.23181520': __('identifierSchemeCodeUnspsc190501.23181520'),
	'identifierSchemeCodeUnspsc190501.23181521': __('identifierSchemeCodeUnspsc190501.23181521'),
	'identifierSchemeCodeUnspsc190501.23181522': __('identifierSchemeCodeUnspsc190501.23181522'),
	'identifierSchemeCodeUnspsc190501.23181523': __('identifierSchemeCodeUnspsc190501.23181523'),
	'identifierSchemeCodeUnspsc190501.23181524': __('identifierSchemeCodeUnspsc190501.23181524'),
	'identifierSchemeCodeUnspsc190501.23181600': __('identifierSchemeCodeUnspsc190501.23181600'),
	'identifierSchemeCodeUnspsc190501.23181601': __('identifierSchemeCodeUnspsc190501.23181601'),
	'identifierSchemeCodeUnspsc190501.23181602': __('identifierSchemeCodeUnspsc190501.23181602'),
	'identifierSchemeCodeUnspsc190501.23181603': __('identifierSchemeCodeUnspsc190501.23181603'),
	'identifierSchemeCodeUnspsc190501.23181604': __('identifierSchemeCodeUnspsc190501.23181604'),
	'identifierSchemeCodeUnspsc190501.23181605': __('identifierSchemeCodeUnspsc190501.23181605'),
	'identifierSchemeCodeUnspsc190501.23181606': __('identifierSchemeCodeUnspsc190501.23181606'),
	'identifierSchemeCodeUnspsc190501.23181700': __('identifierSchemeCodeUnspsc190501.23181700'),
	'identifierSchemeCodeUnspsc190501.23181701': __('identifierSchemeCodeUnspsc190501.23181701'),
	'identifierSchemeCodeUnspsc190501.23181702': __('identifierSchemeCodeUnspsc190501.23181702'),
	'identifierSchemeCodeUnspsc190501.23181703': __('identifierSchemeCodeUnspsc190501.23181703'),
	'identifierSchemeCodeUnspsc190501.23181704': __('identifierSchemeCodeUnspsc190501.23181704'),
	'identifierSchemeCodeUnspsc190501.23181705': __('identifierSchemeCodeUnspsc190501.23181705'),
	'identifierSchemeCodeUnspsc190501.23181800': __('identifierSchemeCodeUnspsc190501.23181800'),
	'identifierSchemeCodeUnspsc190501.23181801': __('identifierSchemeCodeUnspsc190501.23181801'),
	'identifierSchemeCodeUnspsc190501.23181802': __('identifierSchemeCodeUnspsc190501.23181802'),
	'identifierSchemeCodeUnspsc190501.23181803': __('identifierSchemeCodeUnspsc190501.23181803'),
	'identifierSchemeCodeUnspsc190501.23181804': __('identifierSchemeCodeUnspsc190501.23181804'),
	'identifierSchemeCodeUnspsc190501.23181805': __('identifierSchemeCodeUnspsc190501.23181805'),
	'identifierSchemeCodeUnspsc190501.23181806': __('identifierSchemeCodeUnspsc190501.23181806'),
	'identifierSchemeCodeUnspsc190501.23181807': __('identifierSchemeCodeUnspsc190501.23181807'),
	'identifierSchemeCodeUnspsc190501.23181808': __('identifierSchemeCodeUnspsc190501.23181808'),
	'identifierSchemeCodeUnspsc190501.23190000': __('identifierSchemeCodeUnspsc190501.23190000'),
	'identifierSchemeCodeUnspsc190501.23191000': __('identifierSchemeCodeUnspsc190501.23191000'),
	'identifierSchemeCodeUnspsc190501.23191001': __('identifierSchemeCodeUnspsc190501.23191001'),
	'identifierSchemeCodeUnspsc190501.23191002': __('identifierSchemeCodeUnspsc190501.23191002'),
	'identifierSchemeCodeUnspsc190501.23191003': __('identifierSchemeCodeUnspsc190501.23191003'),
	'identifierSchemeCodeUnspsc190501.23191004': __('identifierSchemeCodeUnspsc190501.23191004'),
	'identifierSchemeCodeUnspsc190501.23191005': __('identifierSchemeCodeUnspsc190501.23191005'),
	'identifierSchemeCodeUnspsc190501.23191006': __('identifierSchemeCodeUnspsc190501.23191006'),
	'identifierSchemeCodeUnspsc190501.23191100': __('identifierSchemeCodeUnspsc190501.23191100'),
	'identifierSchemeCodeUnspsc190501.23191101': __('identifierSchemeCodeUnspsc190501.23191101'),
	'identifierSchemeCodeUnspsc190501.23191102': __('identifierSchemeCodeUnspsc190501.23191102'),
	'identifierSchemeCodeUnspsc190501.23191200': __('identifierSchemeCodeUnspsc190501.23191200'),
	'identifierSchemeCodeUnspsc190501.23191201': __('identifierSchemeCodeUnspsc190501.23191201'),
	'identifierSchemeCodeUnspsc190501.23191202': __('identifierSchemeCodeUnspsc190501.23191202'),
	'identifierSchemeCodeUnspsc190501.23200000': __('identifierSchemeCodeUnspsc190501.23200000'),
	'identifierSchemeCodeUnspsc190501.23201000': __('identifierSchemeCodeUnspsc190501.23201000'),
	'identifierSchemeCodeUnspsc190501.23201001': __('identifierSchemeCodeUnspsc190501.23201001'),
	'identifierSchemeCodeUnspsc190501.23201002': __('identifierSchemeCodeUnspsc190501.23201002'),
	'identifierSchemeCodeUnspsc190501.23201003': __('identifierSchemeCodeUnspsc190501.23201003'),
	'identifierSchemeCodeUnspsc190501.23201004': __('identifierSchemeCodeUnspsc190501.23201004'),
	'identifierSchemeCodeUnspsc190501.23201005': __('identifierSchemeCodeUnspsc190501.23201005'),
	'identifierSchemeCodeUnspsc190501.23201006': __('identifierSchemeCodeUnspsc190501.23201006'),
	'identifierSchemeCodeUnspsc190501.23201007': __('identifierSchemeCodeUnspsc190501.23201007'),
	'identifierSchemeCodeUnspsc190501.23201008': __('identifierSchemeCodeUnspsc190501.23201008'),
	'identifierSchemeCodeUnspsc190501.23201100': __('identifierSchemeCodeUnspsc190501.23201100'),
	'identifierSchemeCodeUnspsc190501.23201101': __('identifierSchemeCodeUnspsc190501.23201101'),
	'identifierSchemeCodeUnspsc190501.23201102': __('identifierSchemeCodeUnspsc190501.23201102'),
	'identifierSchemeCodeUnspsc190501.23201200': __('identifierSchemeCodeUnspsc190501.23201200'),
	'identifierSchemeCodeUnspsc190501.23201201': __('identifierSchemeCodeUnspsc190501.23201201'),
	'identifierSchemeCodeUnspsc190501.23201202': __('identifierSchemeCodeUnspsc190501.23201202'),
	'identifierSchemeCodeUnspsc190501.23201203': __('identifierSchemeCodeUnspsc190501.23201203'),
	'identifierSchemeCodeUnspsc190501.23201204': __('identifierSchemeCodeUnspsc190501.23201204'),
	'identifierSchemeCodeUnspsc190501.23210000': __('identifierSchemeCodeUnspsc190501.23210000'),
	'identifierSchemeCodeUnspsc190501.23211000': __('identifierSchemeCodeUnspsc190501.23211000'),
	'identifierSchemeCodeUnspsc190501.23211001': __('identifierSchemeCodeUnspsc190501.23211001'),
	'identifierSchemeCodeUnspsc190501.23211002': __('identifierSchemeCodeUnspsc190501.23211002'),
	'identifierSchemeCodeUnspsc190501.23211003': __('identifierSchemeCodeUnspsc190501.23211003'),
	'identifierSchemeCodeUnspsc190501.23211100': __('identifierSchemeCodeUnspsc190501.23211100'),
	'identifierSchemeCodeUnspsc190501.23211101': __('identifierSchemeCodeUnspsc190501.23211101'),
	'identifierSchemeCodeUnspsc190501.23211102': __('identifierSchemeCodeUnspsc190501.23211102'),
	'identifierSchemeCodeUnspsc190501.23211103': __('identifierSchemeCodeUnspsc190501.23211103'),
	'identifierSchemeCodeUnspsc190501.23211104': __('identifierSchemeCodeUnspsc190501.23211104'),
	'identifierSchemeCodeUnspsc190501.23211105': __('identifierSchemeCodeUnspsc190501.23211105'),
	'identifierSchemeCodeUnspsc190501.23211106': __('identifierSchemeCodeUnspsc190501.23211106'),
	'identifierSchemeCodeUnspsc190501.23220000': __('identifierSchemeCodeUnspsc190501.23220000'),
	'identifierSchemeCodeUnspsc190501.23221000': __('identifierSchemeCodeUnspsc190501.23221000'),
	'identifierSchemeCodeUnspsc190501.23221001': __('identifierSchemeCodeUnspsc190501.23221001'),
	'identifierSchemeCodeUnspsc190501.23221002': __('identifierSchemeCodeUnspsc190501.23221002'),
	'identifierSchemeCodeUnspsc190501.23221100': __('identifierSchemeCodeUnspsc190501.23221100'),
	'identifierSchemeCodeUnspsc190501.23221101': __('identifierSchemeCodeUnspsc190501.23221101'),
	'identifierSchemeCodeUnspsc190501.23221102': __('identifierSchemeCodeUnspsc190501.23221102'),
	'identifierSchemeCodeUnspsc190501.23221103': __('identifierSchemeCodeUnspsc190501.23221103'),
	'identifierSchemeCodeUnspsc190501.23221200': __('identifierSchemeCodeUnspsc190501.23221200'),
	'identifierSchemeCodeUnspsc190501.23221201': __('identifierSchemeCodeUnspsc190501.23221201'),
	'identifierSchemeCodeUnspsc190501.23230000': __('identifierSchemeCodeUnspsc190501.23230000'),
	'identifierSchemeCodeUnspsc190501.23231000': __('identifierSchemeCodeUnspsc190501.23231000'),
	'identifierSchemeCodeUnspsc190501.23231001': __('identifierSchemeCodeUnspsc190501.23231001'),
	'identifierSchemeCodeUnspsc190501.23231002': __('identifierSchemeCodeUnspsc190501.23231002'),
	'identifierSchemeCodeUnspsc190501.23231100': __('identifierSchemeCodeUnspsc190501.23231100'),
	'identifierSchemeCodeUnspsc190501.23231101': __('identifierSchemeCodeUnspsc190501.23231101'),
	'identifierSchemeCodeUnspsc190501.23231102': __('identifierSchemeCodeUnspsc190501.23231102'),
	'identifierSchemeCodeUnspsc190501.23231200': __('identifierSchemeCodeUnspsc190501.23231200'),
	'identifierSchemeCodeUnspsc190501.23231201': __('identifierSchemeCodeUnspsc190501.23231201'),
	'identifierSchemeCodeUnspsc190501.23231202': __('identifierSchemeCodeUnspsc190501.23231202'),
	'identifierSchemeCodeUnspsc190501.23231300': __('identifierSchemeCodeUnspsc190501.23231300'),
	'identifierSchemeCodeUnspsc190501.23231301': __('identifierSchemeCodeUnspsc190501.23231301'),
	'identifierSchemeCodeUnspsc190501.23231302': __('identifierSchemeCodeUnspsc190501.23231302'),
	'identifierSchemeCodeUnspsc190501.23231400': __('identifierSchemeCodeUnspsc190501.23231400'),
	'identifierSchemeCodeUnspsc190501.23231401': __('identifierSchemeCodeUnspsc190501.23231401'),
	'identifierSchemeCodeUnspsc190501.23231402': __('identifierSchemeCodeUnspsc190501.23231402'),
	'identifierSchemeCodeUnspsc190501.23231500': __('identifierSchemeCodeUnspsc190501.23231500'),
	'identifierSchemeCodeUnspsc190501.23231501': __('identifierSchemeCodeUnspsc190501.23231501'),
	'identifierSchemeCodeUnspsc190501.23231502': __('identifierSchemeCodeUnspsc190501.23231502'),
	'identifierSchemeCodeUnspsc190501.23231600': __('identifierSchemeCodeUnspsc190501.23231600'),
	'identifierSchemeCodeUnspsc190501.23231601': __('identifierSchemeCodeUnspsc190501.23231601'),
	'identifierSchemeCodeUnspsc190501.23231602': __('identifierSchemeCodeUnspsc190501.23231602'),
	'identifierSchemeCodeUnspsc190501.23231700': __('identifierSchemeCodeUnspsc190501.23231700'),
	'identifierSchemeCodeUnspsc190501.23231701': __('identifierSchemeCodeUnspsc190501.23231701'),
	'identifierSchemeCodeUnspsc190501.23231800': __('identifierSchemeCodeUnspsc190501.23231800'),
	'identifierSchemeCodeUnspsc190501.23231801': __('identifierSchemeCodeUnspsc190501.23231801'),
	'identifierSchemeCodeUnspsc190501.23231900': __('identifierSchemeCodeUnspsc190501.23231900'),
	'identifierSchemeCodeUnspsc190501.23231901': __('identifierSchemeCodeUnspsc190501.23231901'),
	'identifierSchemeCodeUnspsc190501.23231902': __('identifierSchemeCodeUnspsc190501.23231902'),
	'identifierSchemeCodeUnspsc190501.23231903': __('identifierSchemeCodeUnspsc190501.23231903'),
	'identifierSchemeCodeUnspsc190501.23231904': __('identifierSchemeCodeUnspsc190501.23231904'),
	'identifierSchemeCodeUnspsc190501.23232000': __('identifierSchemeCodeUnspsc190501.23232000'),
	'identifierSchemeCodeUnspsc190501.23232001': __('identifierSchemeCodeUnspsc190501.23232001'),
	'identifierSchemeCodeUnspsc190501.23232100': __('identifierSchemeCodeUnspsc190501.23232100'),
	'identifierSchemeCodeUnspsc190501.23232101': __('identifierSchemeCodeUnspsc190501.23232101'),
	'identifierSchemeCodeUnspsc190501.23232200': __('identifierSchemeCodeUnspsc190501.23232200'),
	'identifierSchemeCodeUnspsc190501.23232201': __('identifierSchemeCodeUnspsc190501.23232201'),
	'identifierSchemeCodeUnspsc190501.23240000': __('identifierSchemeCodeUnspsc190501.23240000'),
	'identifierSchemeCodeUnspsc190501.23241400': __('identifierSchemeCodeUnspsc190501.23241400'),
	'identifierSchemeCodeUnspsc190501.23241401': __('identifierSchemeCodeUnspsc190501.23241401'),
	'identifierSchemeCodeUnspsc190501.23241402': __('identifierSchemeCodeUnspsc190501.23241402'),
	'identifierSchemeCodeUnspsc190501.23241403': __('identifierSchemeCodeUnspsc190501.23241403'),
	'identifierSchemeCodeUnspsc190501.23241404': __('identifierSchemeCodeUnspsc190501.23241404'),
	'identifierSchemeCodeUnspsc190501.23241405': __('identifierSchemeCodeUnspsc190501.23241405'),
	'identifierSchemeCodeUnspsc190501.23241406': __('identifierSchemeCodeUnspsc190501.23241406'),
	'identifierSchemeCodeUnspsc190501.23241407': __('identifierSchemeCodeUnspsc190501.23241407'),
	'identifierSchemeCodeUnspsc190501.23241408': __('identifierSchemeCodeUnspsc190501.23241408'),
	'identifierSchemeCodeUnspsc190501.23241409': __('identifierSchemeCodeUnspsc190501.23241409'),
	'identifierSchemeCodeUnspsc190501.23241410': __('identifierSchemeCodeUnspsc190501.23241410'),
	'identifierSchemeCodeUnspsc190501.23241411': __('identifierSchemeCodeUnspsc190501.23241411'),
	'identifierSchemeCodeUnspsc190501.23241500': __('identifierSchemeCodeUnspsc190501.23241500'),
	'identifierSchemeCodeUnspsc190501.23241501': __('identifierSchemeCodeUnspsc190501.23241501'),
	'identifierSchemeCodeUnspsc190501.23241502': __('identifierSchemeCodeUnspsc190501.23241502'),
	'identifierSchemeCodeUnspsc190501.23241503': __('identifierSchemeCodeUnspsc190501.23241503'),
	'identifierSchemeCodeUnspsc190501.23241504': __('identifierSchemeCodeUnspsc190501.23241504'),
	'identifierSchemeCodeUnspsc190501.23241505': __('identifierSchemeCodeUnspsc190501.23241505'),
	'identifierSchemeCodeUnspsc190501.23241506': __('identifierSchemeCodeUnspsc190501.23241506'),
	'identifierSchemeCodeUnspsc190501.23241507': __('identifierSchemeCodeUnspsc190501.23241507'),
	'identifierSchemeCodeUnspsc190501.23241508': __('identifierSchemeCodeUnspsc190501.23241508'),
	'identifierSchemeCodeUnspsc190501.23241509': __('identifierSchemeCodeUnspsc190501.23241509'),
	'identifierSchemeCodeUnspsc190501.23241510': __('identifierSchemeCodeUnspsc190501.23241510'),
	'identifierSchemeCodeUnspsc190501.23241511': __('identifierSchemeCodeUnspsc190501.23241511'),
	'identifierSchemeCodeUnspsc190501.23241600': __('identifierSchemeCodeUnspsc190501.23241600'),
	'identifierSchemeCodeUnspsc190501.23241601': __('identifierSchemeCodeUnspsc190501.23241601'),
	'identifierSchemeCodeUnspsc190501.23241602': __('identifierSchemeCodeUnspsc190501.23241602'),
	'identifierSchemeCodeUnspsc190501.23241603': __('identifierSchemeCodeUnspsc190501.23241603'),
	'identifierSchemeCodeUnspsc190501.23241605': __('identifierSchemeCodeUnspsc190501.23241605'),
	'identifierSchemeCodeUnspsc190501.23241606': __('identifierSchemeCodeUnspsc190501.23241606'),
	'identifierSchemeCodeUnspsc190501.23241608': __('identifierSchemeCodeUnspsc190501.23241608'),
	'identifierSchemeCodeUnspsc190501.23241609': __('identifierSchemeCodeUnspsc190501.23241609'),
	'identifierSchemeCodeUnspsc190501.23241610': __('identifierSchemeCodeUnspsc190501.23241610'),
	'identifierSchemeCodeUnspsc190501.23241611': __('identifierSchemeCodeUnspsc190501.23241611'),
	'identifierSchemeCodeUnspsc190501.23241612': __('identifierSchemeCodeUnspsc190501.23241612'),
	'identifierSchemeCodeUnspsc190501.23241613': __('identifierSchemeCodeUnspsc190501.23241613'),
	'identifierSchemeCodeUnspsc190501.23241614': __('identifierSchemeCodeUnspsc190501.23241614'),
	'identifierSchemeCodeUnspsc190501.23241615': __('identifierSchemeCodeUnspsc190501.23241615'),
	'identifierSchemeCodeUnspsc190501.23241616': __('identifierSchemeCodeUnspsc190501.23241616'),
	'identifierSchemeCodeUnspsc190501.23241617': __('identifierSchemeCodeUnspsc190501.23241617'),
	'identifierSchemeCodeUnspsc190501.23241618': __('identifierSchemeCodeUnspsc190501.23241618'),
	'identifierSchemeCodeUnspsc190501.23241619': __('identifierSchemeCodeUnspsc190501.23241619'),
	'identifierSchemeCodeUnspsc190501.23241620': __('identifierSchemeCodeUnspsc190501.23241620'),
	'identifierSchemeCodeUnspsc190501.23241621': __('identifierSchemeCodeUnspsc190501.23241621'),
	'identifierSchemeCodeUnspsc190501.23241622': __('identifierSchemeCodeUnspsc190501.23241622'),
	'identifierSchemeCodeUnspsc190501.23241623': __('identifierSchemeCodeUnspsc190501.23241623'),
	'identifierSchemeCodeUnspsc190501.23241624': __('identifierSchemeCodeUnspsc190501.23241624'),
	'identifierSchemeCodeUnspsc190501.23241625': __('identifierSchemeCodeUnspsc190501.23241625'),
	'identifierSchemeCodeUnspsc190501.23241626': __('identifierSchemeCodeUnspsc190501.23241626'),
	'identifierSchemeCodeUnspsc190501.23241627': __('identifierSchemeCodeUnspsc190501.23241627'),
	'identifierSchemeCodeUnspsc190501.23241628': __('identifierSchemeCodeUnspsc190501.23241628'),
	'identifierSchemeCodeUnspsc190501.23241629': __('identifierSchemeCodeUnspsc190501.23241629'),
	'identifierSchemeCodeUnspsc190501.23241630': __('identifierSchemeCodeUnspsc190501.23241630'),
	'identifierSchemeCodeUnspsc190501.23241631': __('identifierSchemeCodeUnspsc190501.23241631'),
	'identifierSchemeCodeUnspsc190501.23241632': __('identifierSchemeCodeUnspsc190501.23241632'),
	'identifierSchemeCodeUnspsc190501.23241633': __('identifierSchemeCodeUnspsc190501.23241633'),
	'identifierSchemeCodeUnspsc190501.23241634': __('identifierSchemeCodeUnspsc190501.23241634'),
	'identifierSchemeCodeUnspsc190501.23241635': __('identifierSchemeCodeUnspsc190501.23241635'),
	'identifierSchemeCodeUnspsc190501.23241636': __('identifierSchemeCodeUnspsc190501.23241636'),
	'identifierSchemeCodeUnspsc190501.23241637': __('identifierSchemeCodeUnspsc190501.23241637'),
	'identifierSchemeCodeUnspsc190501.23241638': __('identifierSchemeCodeUnspsc190501.23241638'),
	'identifierSchemeCodeUnspsc190501.23241639': __('identifierSchemeCodeUnspsc190501.23241639'),
	'identifierSchemeCodeUnspsc190501.23241640': __('identifierSchemeCodeUnspsc190501.23241640'),
	'identifierSchemeCodeUnspsc190501.23241641': __('identifierSchemeCodeUnspsc190501.23241641'),
	'identifierSchemeCodeUnspsc190501.23241642': __('identifierSchemeCodeUnspsc190501.23241642'),
	'identifierSchemeCodeUnspsc190501.23241643': __('identifierSchemeCodeUnspsc190501.23241643'),
	'identifierSchemeCodeUnspsc190501.23241644': __('identifierSchemeCodeUnspsc190501.23241644'),
	'identifierSchemeCodeUnspsc190501.23241645': __('identifierSchemeCodeUnspsc190501.23241645'),
	'identifierSchemeCodeUnspsc190501.23241646': __('identifierSchemeCodeUnspsc190501.23241646'),
	'identifierSchemeCodeUnspsc190501.23241647': __('identifierSchemeCodeUnspsc190501.23241647'),
	'identifierSchemeCodeUnspsc190501.23241700': __('identifierSchemeCodeUnspsc190501.23241700'),
	'identifierSchemeCodeUnspsc190501.23241701': __('identifierSchemeCodeUnspsc190501.23241701'),
	'identifierSchemeCodeUnspsc190501.23241702': __('identifierSchemeCodeUnspsc190501.23241702'),
	'identifierSchemeCodeUnspsc190501.23241703': __('identifierSchemeCodeUnspsc190501.23241703'),
	'identifierSchemeCodeUnspsc190501.23241800': __('identifierSchemeCodeUnspsc190501.23241800'),
	'identifierSchemeCodeUnspsc190501.23241801': __('identifierSchemeCodeUnspsc190501.23241801'),
	'identifierSchemeCodeUnspsc190501.23241802': __('identifierSchemeCodeUnspsc190501.23241802'),
	'identifierSchemeCodeUnspsc190501.23241803': __('identifierSchemeCodeUnspsc190501.23241803'),
	'identifierSchemeCodeUnspsc190501.23241804': __('identifierSchemeCodeUnspsc190501.23241804'),
	'identifierSchemeCodeUnspsc190501.23241805': __('identifierSchemeCodeUnspsc190501.23241805'),
	'identifierSchemeCodeUnspsc190501.23241806': __('identifierSchemeCodeUnspsc190501.23241806'),
	'identifierSchemeCodeUnspsc190501.23241807': __('identifierSchemeCodeUnspsc190501.23241807'),
	'identifierSchemeCodeUnspsc190501.23241808': __('identifierSchemeCodeUnspsc190501.23241808'),
	'identifierSchemeCodeUnspsc190501.23241809': __('identifierSchemeCodeUnspsc190501.23241809'),
	'identifierSchemeCodeUnspsc190501.23241900': __('identifierSchemeCodeUnspsc190501.23241900'),
	'identifierSchemeCodeUnspsc190501.23241901': __('identifierSchemeCodeUnspsc190501.23241901'),
	'identifierSchemeCodeUnspsc190501.23241902': __('identifierSchemeCodeUnspsc190501.23241902'),
	'identifierSchemeCodeUnspsc190501.23241903': __('identifierSchemeCodeUnspsc190501.23241903'),
	'identifierSchemeCodeUnspsc190501.23241904': __('identifierSchemeCodeUnspsc190501.23241904'),
	'identifierSchemeCodeUnspsc190501.23241905': __('identifierSchemeCodeUnspsc190501.23241905'),
	'identifierSchemeCodeUnspsc190501.23241906': __('identifierSchemeCodeUnspsc190501.23241906'),
	'identifierSchemeCodeUnspsc190501.23242100': __('identifierSchemeCodeUnspsc190501.23242100'),
	'identifierSchemeCodeUnspsc190501.23242101': __('identifierSchemeCodeUnspsc190501.23242101'),
	'identifierSchemeCodeUnspsc190501.23242102': __('identifierSchemeCodeUnspsc190501.23242102'),
	'identifierSchemeCodeUnspsc190501.23242103': __('identifierSchemeCodeUnspsc190501.23242103'),
	'identifierSchemeCodeUnspsc190501.23242104': __('identifierSchemeCodeUnspsc190501.23242104'),
	'identifierSchemeCodeUnspsc190501.23242105': __('identifierSchemeCodeUnspsc190501.23242105'),
	'identifierSchemeCodeUnspsc190501.23242106': __('identifierSchemeCodeUnspsc190501.23242106'),
	'identifierSchemeCodeUnspsc190501.23242107': __('identifierSchemeCodeUnspsc190501.23242107'),
	'identifierSchemeCodeUnspsc190501.23242108': __('identifierSchemeCodeUnspsc190501.23242108'),
	'identifierSchemeCodeUnspsc190501.23242109': __('identifierSchemeCodeUnspsc190501.23242109'),
	'identifierSchemeCodeUnspsc190501.23242110': __('identifierSchemeCodeUnspsc190501.23242110'),
	'identifierSchemeCodeUnspsc190501.23242111': __('identifierSchemeCodeUnspsc190501.23242111'),
	'identifierSchemeCodeUnspsc190501.23242112': __('identifierSchemeCodeUnspsc190501.23242112'),
	'identifierSchemeCodeUnspsc190501.23242113': __('identifierSchemeCodeUnspsc190501.23242113'),
	'identifierSchemeCodeUnspsc190501.23242114': __('identifierSchemeCodeUnspsc190501.23242114'),
	'identifierSchemeCodeUnspsc190501.23242115': __('identifierSchemeCodeUnspsc190501.23242115'),
	'identifierSchemeCodeUnspsc190501.23242116': __('identifierSchemeCodeUnspsc190501.23242116'),
	'identifierSchemeCodeUnspsc190501.23242117': __('identifierSchemeCodeUnspsc190501.23242117'),
	'identifierSchemeCodeUnspsc190501.23242118': __('identifierSchemeCodeUnspsc190501.23242118'),
	'identifierSchemeCodeUnspsc190501.23242119': __('identifierSchemeCodeUnspsc190501.23242119'),
	'identifierSchemeCodeUnspsc190501.23242120': __('identifierSchemeCodeUnspsc190501.23242120'),
	'identifierSchemeCodeUnspsc190501.23242200': __('identifierSchemeCodeUnspsc190501.23242200'),
	'identifierSchemeCodeUnspsc190501.23242201': __('identifierSchemeCodeUnspsc190501.23242201'),
	'identifierSchemeCodeUnspsc190501.23242202': __('identifierSchemeCodeUnspsc190501.23242202'),
	'identifierSchemeCodeUnspsc190501.23242203': __('identifierSchemeCodeUnspsc190501.23242203'),
	'identifierSchemeCodeUnspsc190501.23242204': __('identifierSchemeCodeUnspsc190501.23242204'),
	'identifierSchemeCodeUnspsc190501.23242205': __('identifierSchemeCodeUnspsc190501.23242205'),
	'identifierSchemeCodeUnspsc190501.23242206': __('identifierSchemeCodeUnspsc190501.23242206'),
	'identifierSchemeCodeUnspsc190501.23242207': __('identifierSchemeCodeUnspsc190501.23242207'),
	'identifierSchemeCodeUnspsc190501.23242208': __('identifierSchemeCodeUnspsc190501.23242208'),
	'identifierSchemeCodeUnspsc190501.23242300': __('identifierSchemeCodeUnspsc190501.23242300'),
	'identifierSchemeCodeUnspsc190501.23242301': __('identifierSchemeCodeUnspsc190501.23242301'),
	'identifierSchemeCodeUnspsc190501.23242302': __('identifierSchemeCodeUnspsc190501.23242302'),
	'identifierSchemeCodeUnspsc190501.23242303': __('identifierSchemeCodeUnspsc190501.23242303'),
	'identifierSchemeCodeUnspsc190501.23242304': __('identifierSchemeCodeUnspsc190501.23242304'),
	'identifierSchemeCodeUnspsc190501.23242305': __('identifierSchemeCodeUnspsc190501.23242305'),
	'identifierSchemeCodeUnspsc190501.23242306': __('identifierSchemeCodeUnspsc190501.23242306'),
	'identifierSchemeCodeUnspsc190501.23242307': __('identifierSchemeCodeUnspsc190501.23242307'),
	'identifierSchemeCodeUnspsc190501.23242308': __('identifierSchemeCodeUnspsc190501.23242308'),
	'identifierSchemeCodeUnspsc190501.23242400': __('identifierSchemeCodeUnspsc190501.23242400'),
	'identifierSchemeCodeUnspsc190501.23242401': __('identifierSchemeCodeUnspsc190501.23242401'),
	'identifierSchemeCodeUnspsc190501.23242402': __('identifierSchemeCodeUnspsc190501.23242402'),
	'identifierSchemeCodeUnspsc190501.23242403': __('identifierSchemeCodeUnspsc190501.23242403'),
	'identifierSchemeCodeUnspsc190501.23242500': __('identifierSchemeCodeUnspsc190501.23242500'),
	'identifierSchemeCodeUnspsc190501.23242501': __('identifierSchemeCodeUnspsc190501.23242501'),
	'identifierSchemeCodeUnspsc190501.23242502': __('identifierSchemeCodeUnspsc190501.23242502'),
	'identifierSchemeCodeUnspsc190501.23242503': __('identifierSchemeCodeUnspsc190501.23242503'),
	'identifierSchemeCodeUnspsc190501.23242504': __('identifierSchemeCodeUnspsc190501.23242504'),
	'identifierSchemeCodeUnspsc190501.23242505': __('identifierSchemeCodeUnspsc190501.23242505'),
	'identifierSchemeCodeUnspsc190501.23242506': __('identifierSchemeCodeUnspsc190501.23242506'),
	'identifierSchemeCodeUnspsc190501.23242507': __('identifierSchemeCodeUnspsc190501.23242507'),
	'identifierSchemeCodeUnspsc190501.23242508': __('identifierSchemeCodeUnspsc190501.23242508'),
	'identifierSchemeCodeUnspsc190501.23242509': __('identifierSchemeCodeUnspsc190501.23242509'),
	'identifierSchemeCodeUnspsc190501.23242510': __('identifierSchemeCodeUnspsc190501.23242510'),
	'identifierSchemeCodeUnspsc190501.23242511': __('identifierSchemeCodeUnspsc190501.23242511'),
	'identifierSchemeCodeUnspsc190501.23242600': __('identifierSchemeCodeUnspsc190501.23242600'),
	'identifierSchemeCodeUnspsc190501.23242601': __('identifierSchemeCodeUnspsc190501.23242601'),
	'identifierSchemeCodeUnspsc190501.23242602': __('identifierSchemeCodeUnspsc190501.23242602'),
	'identifierSchemeCodeUnspsc190501.23242603': __('identifierSchemeCodeUnspsc190501.23242603'),
	'identifierSchemeCodeUnspsc190501.23242604': __('identifierSchemeCodeUnspsc190501.23242604'),
	'identifierSchemeCodeUnspsc190501.23242605': __('identifierSchemeCodeUnspsc190501.23242605'),
	'identifierSchemeCodeUnspsc190501.23242606': __('identifierSchemeCodeUnspsc190501.23242606'),
	'identifierSchemeCodeUnspsc190501.23242607': __('identifierSchemeCodeUnspsc190501.23242607'),
	'identifierSchemeCodeUnspsc190501.23242608': __('identifierSchemeCodeUnspsc190501.23242608'),
	'identifierSchemeCodeUnspsc190501.23242609': __('identifierSchemeCodeUnspsc190501.23242609'),
	'identifierSchemeCodeUnspsc190501.23242610': __('identifierSchemeCodeUnspsc190501.23242610'),
	'identifierSchemeCodeUnspsc190501.23242611': __('identifierSchemeCodeUnspsc190501.23242611'),
	'identifierSchemeCodeUnspsc190501.23242612': __('identifierSchemeCodeUnspsc190501.23242612'),
	'identifierSchemeCodeUnspsc190501.23242613': __('identifierSchemeCodeUnspsc190501.23242613'),
	'identifierSchemeCodeUnspsc190501.23242614': __('identifierSchemeCodeUnspsc190501.23242614'),
	'identifierSchemeCodeUnspsc190501.23242615': __('identifierSchemeCodeUnspsc190501.23242615'),
	'identifierSchemeCodeUnspsc190501.23242700': __('identifierSchemeCodeUnspsc190501.23242700'),
	'identifierSchemeCodeUnspsc190501.23242701': __('identifierSchemeCodeUnspsc190501.23242701'),
	'identifierSchemeCodeUnspsc190501.23242702': __('identifierSchemeCodeUnspsc190501.23242702'),
	'identifierSchemeCodeUnspsc190501.23250000': __('identifierSchemeCodeUnspsc190501.23250000'),
	'identifierSchemeCodeUnspsc190501.23251500': __('identifierSchemeCodeUnspsc190501.23251500'),
	'identifierSchemeCodeUnspsc190501.23251501': __('identifierSchemeCodeUnspsc190501.23251501'),
	'identifierSchemeCodeUnspsc190501.23251502': __('identifierSchemeCodeUnspsc190501.23251502'),
	'identifierSchemeCodeUnspsc190501.23251503': __('identifierSchemeCodeUnspsc190501.23251503'),
	'identifierSchemeCodeUnspsc190501.23251504': __('identifierSchemeCodeUnspsc190501.23251504'),
	'identifierSchemeCodeUnspsc190501.23251505': __('identifierSchemeCodeUnspsc190501.23251505'),
	'identifierSchemeCodeUnspsc190501.23251506': __('identifierSchemeCodeUnspsc190501.23251506'),
	'identifierSchemeCodeUnspsc190501.23251507': __('identifierSchemeCodeUnspsc190501.23251507'),
	'identifierSchemeCodeUnspsc190501.23251508': __('identifierSchemeCodeUnspsc190501.23251508'),
	'identifierSchemeCodeUnspsc190501.23251509': __('identifierSchemeCodeUnspsc190501.23251509'),
	'identifierSchemeCodeUnspsc190501.23251600': __('identifierSchemeCodeUnspsc190501.23251600'),
	'identifierSchemeCodeUnspsc190501.23251601': __('identifierSchemeCodeUnspsc190501.23251601'),
	'identifierSchemeCodeUnspsc190501.23251602': __('identifierSchemeCodeUnspsc190501.23251602'),
	'identifierSchemeCodeUnspsc190501.23251603': __('identifierSchemeCodeUnspsc190501.23251603'),
	'identifierSchemeCodeUnspsc190501.23251700': __('identifierSchemeCodeUnspsc190501.23251700'),
	'identifierSchemeCodeUnspsc190501.23251701': __('identifierSchemeCodeUnspsc190501.23251701'),
	'identifierSchemeCodeUnspsc190501.23251702': __('identifierSchemeCodeUnspsc190501.23251702'),
	'identifierSchemeCodeUnspsc190501.23251703': __('identifierSchemeCodeUnspsc190501.23251703'),
	'identifierSchemeCodeUnspsc190501.23251704': __('identifierSchemeCodeUnspsc190501.23251704'),
	'identifierSchemeCodeUnspsc190501.23251705': __('identifierSchemeCodeUnspsc190501.23251705'),
	'identifierSchemeCodeUnspsc190501.23251706': __('identifierSchemeCodeUnspsc190501.23251706'),
	'identifierSchemeCodeUnspsc190501.23251707': __('identifierSchemeCodeUnspsc190501.23251707'),
	'identifierSchemeCodeUnspsc190501.23251708': __('identifierSchemeCodeUnspsc190501.23251708'),
	'identifierSchemeCodeUnspsc190501.23251709': __('identifierSchemeCodeUnspsc190501.23251709'),
	'identifierSchemeCodeUnspsc190501.23251710': __('identifierSchemeCodeUnspsc190501.23251710'),
	'identifierSchemeCodeUnspsc190501.23251711': __('identifierSchemeCodeUnspsc190501.23251711'),
	'identifierSchemeCodeUnspsc190501.23251712': __('identifierSchemeCodeUnspsc190501.23251712'),
	'identifierSchemeCodeUnspsc190501.23251713': __('identifierSchemeCodeUnspsc190501.23251713'),
	'identifierSchemeCodeUnspsc190501.23251714': __('identifierSchemeCodeUnspsc190501.23251714'),
	'identifierSchemeCodeUnspsc190501.23251800': __('identifierSchemeCodeUnspsc190501.23251800'),
	'identifierSchemeCodeUnspsc190501.23251801': __('identifierSchemeCodeUnspsc190501.23251801'),
	'identifierSchemeCodeUnspsc190501.23251802': __('identifierSchemeCodeUnspsc190501.23251802'),
	'identifierSchemeCodeUnspsc190501.23251803': __('identifierSchemeCodeUnspsc190501.23251803'),
	'identifierSchemeCodeUnspsc190501.23251804': __('identifierSchemeCodeUnspsc190501.23251804'),
	'identifierSchemeCodeUnspsc190501.23251805': __('identifierSchemeCodeUnspsc190501.23251805'),
	'identifierSchemeCodeUnspsc190501.23251806': __('identifierSchemeCodeUnspsc190501.23251806'),
	'identifierSchemeCodeUnspsc190501.23251807': __('identifierSchemeCodeUnspsc190501.23251807'),
	'identifierSchemeCodeUnspsc190501.23251808': __('identifierSchemeCodeUnspsc190501.23251808'),
	'identifierSchemeCodeUnspsc190501.23251809': __('identifierSchemeCodeUnspsc190501.23251809'),
	'identifierSchemeCodeUnspsc190501.23251810': __('identifierSchemeCodeUnspsc190501.23251810'),
	'identifierSchemeCodeUnspsc190501.23251811': __('identifierSchemeCodeUnspsc190501.23251811'),
	'identifierSchemeCodeUnspsc190501.23251812': __('identifierSchemeCodeUnspsc190501.23251812'),
	'identifierSchemeCodeUnspsc190501.23251813': __('identifierSchemeCodeUnspsc190501.23251813'),
	'identifierSchemeCodeUnspsc190501.23251814': __('identifierSchemeCodeUnspsc190501.23251814'),
	'identifierSchemeCodeUnspsc190501.23251900': __('identifierSchemeCodeUnspsc190501.23251900'),
	'identifierSchemeCodeUnspsc190501.23251901': __('identifierSchemeCodeUnspsc190501.23251901'),
	'identifierSchemeCodeUnspsc190501.23260000': __('identifierSchemeCodeUnspsc190501.23260000'),
	'identifierSchemeCodeUnspsc190501.23261500': __('identifierSchemeCodeUnspsc190501.23261500'),
	'identifierSchemeCodeUnspsc190501.23261501': __('identifierSchemeCodeUnspsc190501.23261501'),
	'identifierSchemeCodeUnspsc190501.23261502': __('identifierSchemeCodeUnspsc190501.23261502'),
	'identifierSchemeCodeUnspsc190501.23261503': __('identifierSchemeCodeUnspsc190501.23261503'),
	'identifierSchemeCodeUnspsc190501.23261504': __('identifierSchemeCodeUnspsc190501.23261504'),
	'identifierSchemeCodeUnspsc190501.23261505': __('identifierSchemeCodeUnspsc190501.23261505'),
	'identifierSchemeCodeUnspsc190501.23261506': __('identifierSchemeCodeUnspsc190501.23261506'),
	'identifierSchemeCodeUnspsc190501.23261507': __('identifierSchemeCodeUnspsc190501.23261507'),
	'identifierSchemeCodeUnspsc190501.23270000': __('identifierSchemeCodeUnspsc190501.23270000'),
	'identifierSchemeCodeUnspsc190501.23271400': __('identifierSchemeCodeUnspsc190501.23271400'),
	'identifierSchemeCodeUnspsc190501.23271401': __('identifierSchemeCodeUnspsc190501.23271401'),
	'identifierSchemeCodeUnspsc190501.23271402': __('identifierSchemeCodeUnspsc190501.23271402'),
	'identifierSchemeCodeUnspsc190501.23271403': __('identifierSchemeCodeUnspsc190501.23271403'),
	'identifierSchemeCodeUnspsc190501.23271404': __('identifierSchemeCodeUnspsc190501.23271404'),
	'identifierSchemeCodeUnspsc190501.23271405': __('identifierSchemeCodeUnspsc190501.23271405'),
	'identifierSchemeCodeUnspsc190501.23271406': __('identifierSchemeCodeUnspsc190501.23271406'),
	'identifierSchemeCodeUnspsc190501.23271407': __('identifierSchemeCodeUnspsc190501.23271407'),
	'identifierSchemeCodeUnspsc190501.23271408': __('identifierSchemeCodeUnspsc190501.23271408'),
	'identifierSchemeCodeUnspsc190501.23271409': __('identifierSchemeCodeUnspsc190501.23271409'),
	'identifierSchemeCodeUnspsc190501.23271410': __('identifierSchemeCodeUnspsc190501.23271410'),
	'identifierSchemeCodeUnspsc190501.23271411': __('identifierSchemeCodeUnspsc190501.23271411'),
	'identifierSchemeCodeUnspsc190501.23271412': __('identifierSchemeCodeUnspsc190501.23271412'),
	'identifierSchemeCodeUnspsc190501.23271413': __('identifierSchemeCodeUnspsc190501.23271413'),
	'identifierSchemeCodeUnspsc190501.23271414': __('identifierSchemeCodeUnspsc190501.23271414'),
	'identifierSchemeCodeUnspsc190501.23271415': __('identifierSchemeCodeUnspsc190501.23271415'),
	'identifierSchemeCodeUnspsc190501.23271416': __('identifierSchemeCodeUnspsc190501.23271416'),
	'identifierSchemeCodeUnspsc190501.23271417': __('identifierSchemeCodeUnspsc190501.23271417'),
	'identifierSchemeCodeUnspsc190501.23271418': __('identifierSchemeCodeUnspsc190501.23271418'),
	'identifierSchemeCodeUnspsc190501.23271419': __('identifierSchemeCodeUnspsc190501.23271419'),
	'identifierSchemeCodeUnspsc190501.23271420': __('identifierSchemeCodeUnspsc190501.23271420'),
	'identifierSchemeCodeUnspsc190501.23271421': __('identifierSchemeCodeUnspsc190501.23271421'),
	'identifierSchemeCodeUnspsc190501.23271422': __('identifierSchemeCodeUnspsc190501.23271422'),
	'identifierSchemeCodeUnspsc190501.23271423': __('identifierSchemeCodeUnspsc190501.23271423'),
	'identifierSchemeCodeUnspsc190501.23271424': __('identifierSchemeCodeUnspsc190501.23271424'),
	'identifierSchemeCodeUnspsc190501.23271500': __('identifierSchemeCodeUnspsc190501.23271500'),
	'identifierSchemeCodeUnspsc190501.23271501': __('identifierSchemeCodeUnspsc190501.23271501'),
	'identifierSchemeCodeUnspsc190501.23271502': __('identifierSchemeCodeUnspsc190501.23271502'),
	'identifierSchemeCodeUnspsc190501.23271600': __('identifierSchemeCodeUnspsc190501.23271600'),
	'identifierSchemeCodeUnspsc190501.23271601': __('identifierSchemeCodeUnspsc190501.23271601'),
	'identifierSchemeCodeUnspsc190501.23271602': __('identifierSchemeCodeUnspsc190501.23271602'),
	'identifierSchemeCodeUnspsc190501.23271603': __('identifierSchemeCodeUnspsc190501.23271603'),
	'identifierSchemeCodeUnspsc190501.23271604': __('identifierSchemeCodeUnspsc190501.23271604'),
	'identifierSchemeCodeUnspsc190501.23271605': __('identifierSchemeCodeUnspsc190501.23271605'),
	'identifierSchemeCodeUnspsc190501.23271606': __('identifierSchemeCodeUnspsc190501.23271606'),
	'identifierSchemeCodeUnspsc190501.23271607': __('identifierSchemeCodeUnspsc190501.23271607'),
	'identifierSchemeCodeUnspsc190501.23271700': __('identifierSchemeCodeUnspsc190501.23271700'),
	'identifierSchemeCodeUnspsc190501.23271701': __('identifierSchemeCodeUnspsc190501.23271701'),
	'identifierSchemeCodeUnspsc190501.23271702': __('identifierSchemeCodeUnspsc190501.23271702'),
	'identifierSchemeCodeUnspsc190501.23271703': __('identifierSchemeCodeUnspsc190501.23271703'),
	'identifierSchemeCodeUnspsc190501.23271704': __('identifierSchemeCodeUnspsc190501.23271704'),
	'identifierSchemeCodeUnspsc190501.23271705': __('identifierSchemeCodeUnspsc190501.23271705'),
	'identifierSchemeCodeUnspsc190501.23271706': __('identifierSchemeCodeUnspsc190501.23271706'),
	'identifierSchemeCodeUnspsc190501.23271707': __('identifierSchemeCodeUnspsc190501.23271707'),
	'identifierSchemeCodeUnspsc190501.23271708': __('identifierSchemeCodeUnspsc190501.23271708'),
	'identifierSchemeCodeUnspsc190501.23271709': __('identifierSchemeCodeUnspsc190501.23271709'),
	'identifierSchemeCodeUnspsc190501.23271710': __('identifierSchemeCodeUnspsc190501.23271710'),
	'identifierSchemeCodeUnspsc190501.23271711': __('identifierSchemeCodeUnspsc190501.23271711'),
	'identifierSchemeCodeUnspsc190501.23271712': __('identifierSchemeCodeUnspsc190501.23271712'),
	'identifierSchemeCodeUnspsc190501.23271713': __('identifierSchemeCodeUnspsc190501.23271713'),
	'identifierSchemeCodeUnspsc190501.23271714': __('identifierSchemeCodeUnspsc190501.23271714'),
	'identifierSchemeCodeUnspsc190501.23271715': __('identifierSchemeCodeUnspsc190501.23271715'),
	'identifierSchemeCodeUnspsc190501.23271716': __('identifierSchemeCodeUnspsc190501.23271716'),
	'identifierSchemeCodeUnspsc190501.23271717': __('identifierSchemeCodeUnspsc190501.23271717'),
	'identifierSchemeCodeUnspsc190501.23271718': __('identifierSchemeCodeUnspsc190501.23271718'),
	'identifierSchemeCodeUnspsc190501.23271800': __('identifierSchemeCodeUnspsc190501.23271800'),
	'identifierSchemeCodeUnspsc190501.23271801': __('identifierSchemeCodeUnspsc190501.23271801'),
	'identifierSchemeCodeUnspsc190501.23271802': __('identifierSchemeCodeUnspsc190501.23271802'),
	'identifierSchemeCodeUnspsc190501.23271803': __('identifierSchemeCodeUnspsc190501.23271803'),
	'identifierSchemeCodeUnspsc190501.23271804': __('identifierSchemeCodeUnspsc190501.23271804'),
	'identifierSchemeCodeUnspsc190501.23271805': __('identifierSchemeCodeUnspsc190501.23271805'),
	'identifierSchemeCodeUnspsc190501.23271806': __('identifierSchemeCodeUnspsc190501.23271806'),
	'identifierSchemeCodeUnspsc190501.23271807': __('identifierSchemeCodeUnspsc190501.23271807'),
	'identifierSchemeCodeUnspsc190501.23271808': __('identifierSchemeCodeUnspsc190501.23271808'),
	'identifierSchemeCodeUnspsc190501.23271809': __('identifierSchemeCodeUnspsc190501.23271809'),
	'identifierSchemeCodeUnspsc190501.23271810': __('identifierSchemeCodeUnspsc190501.23271810'),
	'identifierSchemeCodeUnspsc190501.23271811': __('identifierSchemeCodeUnspsc190501.23271811'),
	'identifierSchemeCodeUnspsc190501.23271812': __('identifierSchemeCodeUnspsc190501.23271812'),
	'identifierSchemeCodeUnspsc190501.23271813': __('identifierSchemeCodeUnspsc190501.23271813'),
	'identifierSchemeCodeUnspsc190501.23271814': __('identifierSchemeCodeUnspsc190501.23271814'),
	'identifierSchemeCodeUnspsc190501.23271815': __('identifierSchemeCodeUnspsc190501.23271815'),
	'identifierSchemeCodeUnspsc190501.23271816': __('identifierSchemeCodeUnspsc190501.23271816'),
	'identifierSchemeCodeUnspsc190501.23271817': __('identifierSchemeCodeUnspsc190501.23271817'),
	'identifierSchemeCodeUnspsc190501.23271818': __('identifierSchemeCodeUnspsc190501.23271818'),
	'identifierSchemeCodeUnspsc190501.23271819': __('identifierSchemeCodeUnspsc190501.23271819'),
	'identifierSchemeCodeUnspsc190501.23271820': __('identifierSchemeCodeUnspsc190501.23271820'),
	'identifierSchemeCodeUnspsc190501.23271821': __('identifierSchemeCodeUnspsc190501.23271821'),
	'identifierSchemeCodeUnspsc190501.23280000': __('identifierSchemeCodeUnspsc190501.23280000'),
	'identifierSchemeCodeUnspsc190501.23281500': __('identifierSchemeCodeUnspsc190501.23281500'),
	'identifierSchemeCodeUnspsc190501.23281501': __('identifierSchemeCodeUnspsc190501.23281501'),
	'identifierSchemeCodeUnspsc190501.23281502': __('identifierSchemeCodeUnspsc190501.23281502'),
	'identifierSchemeCodeUnspsc190501.23281503': __('identifierSchemeCodeUnspsc190501.23281503'),
	'identifierSchemeCodeUnspsc190501.23281504': __('identifierSchemeCodeUnspsc190501.23281504'),
	'identifierSchemeCodeUnspsc190501.23281600': __('identifierSchemeCodeUnspsc190501.23281600'),
	'identifierSchemeCodeUnspsc190501.23281601': __('identifierSchemeCodeUnspsc190501.23281601'),
	'identifierSchemeCodeUnspsc190501.23281602': __('identifierSchemeCodeUnspsc190501.23281602'),
	'identifierSchemeCodeUnspsc190501.23281603': __('identifierSchemeCodeUnspsc190501.23281603'),
	'identifierSchemeCodeUnspsc190501.23281700': __('identifierSchemeCodeUnspsc190501.23281700'),
	'identifierSchemeCodeUnspsc190501.23281701': __('identifierSchemeCodeUnspsc190501.23281701'),
	'identifierSchemeCodeUnspsc190501.23281702': __('identifierSchemeCodeUnspsc190501.23281702'),
	'identifierSchemeCodeUnspsc190501.23281703': __('identifierSchemeCodeUnspsc190501.23281703'),
	'identifierSchemeCodeUnspsc190501.23281704': __('identifierSchemeCodeUnspsc190501.23281704'),
	'identifierSchemeCodeUnspsc190501.23281800': __('identifierSchemeCodeUnspsc190501.23281800'),
	'identifierSchemeCodeUnspsc190501.23281801': __('identifierSchemeCodeUnspsc190501.23281801'),
	'identifierSchemeCodeUnspsc190501.23281802': __('identifierSchemeCodeUnspsc190501.23281802'),
	'identifierSchemeCodeUnspsc190501.23281900': __('identifierSchemeCodeUnspsc190501.23281900'),
	'identifierSchemeCodeUnspsc190501.23281901': __('identifierSchemeCodeUnspsc190501.23281901'),
	'identifierSchemeCodeUnspsc190501.23281902': __('identifierSchemeCodeUnspsc190501.23281902'),
	'identifierSchemeCodeUnspsc190501.23281903': __('identifierSchemeCodeUnspsc190501.23281903'),
	'identifierSchemeCodeUnspsc190501.23281904': __('identifierSchemeCodeUnspsc190501.23281904'),
	'identifierSchemeCodeUnspsc190501.23281905': __('identifierSchemeCodeUnspsc190501.23281905'),
	'identifierSchemeCodeUnspsc190501.23290000': __('identifierSchemeCodeUnspsc190501.23290000'),
	'identifierSchemeCodeUnspsc190501.23291500': __('identifierSchemeCodeUnspsc190501.23291500'),
	'identifierSchemeCodeUnspsc190501.23291501': __('identifierSchemeCodeUnspsc190501.23291501'),
	'identifierSchemeCodeUnspsc190501.23291502': __('identifierSchemeCodeUnspsc190501.23291502'),
	'identifierSchemeCodeUnspsc190501.23291503': __('identifierSchemeCodeUnspsc190501.23291503'),
	'identifierSchemeCodeUnspsc190501.23291504': __('identifierSchemeCodeUnspsc190501.23291504'),
	'identifierSchemeCodeUnspsc190501.23291600': __('identifierSchemeCodeUnspsc190501.23291600'),
	'identifierSchemeCodeUnspsc190501.23291601': __('identifierSchemeCodeUnspsc190501.23291601'),
	'identifierSchemeCodeUnspsc190501.23291700': __('identifierSchemeCodeUnspsc190501.23291700'),
	'identifierSchemeCodeUnspsc190501.23291701': __('identifierSchemeCodeUnspsc190501.23291701'),
	'identifierSchemeCodeUnspsc190501.23291702': __('identifierSchemeCodeUnspsc190501.23291702'),
	'identifierSchemeCodeUnspsc190501.23291703': __('identifierSchemeCodeUnspsc190501.23291703'),
	'identifierSchemeCodeUnspsc190501.23291704': __('identifierSchemeCodeUnspsc190501.23291704'),
	'identifierSchemeCodeUnspsc190501.23291800': __('identifierSchemeCodeUnspsc190501.23291800'),
	'identifierSchemeCodeUnspsc190501.23291801': __('identifierSchemeCodeUnspsc190501.23291801'),
	'identifierSchemeCodeUnspsc190501.23291802': __('identifierSchemeCodeUnspsc190501.23291802'),
	'identifierSchemeCodeUnspsc190501.23291803': __('identifierSchemeCodeUnspsc190501.23291803'),
	'identifierSchemeCodeUnspsc190501.23291804': __('identifierSchemeCodeUnspsc190501.23291804'),
	'identifierSchemeCodeUnspsc190501.23291900': __('identifierSchemeCodeUnspsc190501.23291900'),
	'identifierSchemeCodeUnspsc190501.23291901': __('identifierSchemeCodeUnspsc190501.23291901'),
	'identifierSchemeCodeUnspsc190501.23291902': __('identifierSchemeCodeUnspsc190501.23291902'),
	'identifierSchemeCodeUnspsc190501.23300000': __('identifierSchemeCodeUnspsc190501.23300000'),
	'identifierSchemeCodeUnspsc190501.23301500': __('identifierSchemeCodeUnspsc190501.23301500'),
	'identifierSchemeCodeUnspsc190501.23301501': __('identifierSchemeCodeUnspsc190501.23301501'),
	'identifierSchemeCodeUnspsc190501.24000000': __('identifierSchemeCodeUnspsc190501.24000000'),
	'identifierSchemeCodeUnspsc190501.24100000': __('identifierSchemeCodeUnspsc190501.24100000'),
	'identifierSchemeCodeUnspsc190501.24101500': __('identifierSchemeCodeUnspsc190501.24101500'),
	'identifierSchemeCodeUnspsc190501.24101501': __('identifierSchemeCodeUnspsc190501.24101501'),
	'identifierSchemeCodeUnspsc190501.24101502': __('identifierSchemeCodeUnspsc190501.24101502'),
	'identifierSchemeCodeUnspsc190501.24101503': __('identifierSchemeCodeUnspsc190501.24101503'),
	'identifierSchemeCodeUnspsc190501.24101504': __('identifierSchemeCodeUnspsc190501.24101504'),
	'identifierSchemeCodeUnspsc190501.24101505': __('identifierSchemeCodeUnspsc190501.24101505'),
	'identifierSchemeCodeUnspsc190501.24101506': __('identifierSchemeCodeUnspsc190501.24101506'),
	'identifierSchemeCodeUnspsc190501.24101507': __('identifierSchemeCodeUnspsc190501.24101507'),
	'identifierSchemeCodeUnspsc190501.24101508': __('identifierSchemeCodeUnspsc190501.24101508'),
	'identifierSchemeCodeUnspsc190501.24101509': __('identifierSchemeCodeUnspsc190501.24101509'),
	'identifierSchemeCodeUnspsc190501.24101510': __('identifierSchemeCodeUnspsc190501.24101510'),
	'identifierSchemeCodeUnspsc190501.24101511': __('identifierSchemeCodeUnspsc190501.24101511'),
	'identifierSchemeCodeUnspsc190501.24101512': __('identifierSchemeCodeUnspsc190501.24101512'),
	'identifierSchemeCodeUnspsc190501.24101513': __('identifierSchemeCodeUnspsc190501.24101513'),
	'identifierSchemeCodeUnspsc190501.24101514': __('identifierSchemeCodeUnspsc190501.24101514'),
	'identifierSchemeCodeUnspsc190501.24101515': __('identifierSchemeCodeUnspsc190501.24101515'),
	'identifierSchemeCodeUnspsc190501.24101516': __('identifierSchemeCodeUnspsc190501.24101516'),
	'identifierSchemeCodeUnspsc190501.24101517': __('identifierSchemeCodeUnspsc190501.24101517'),
	'identifierSchemeCodeUnspsc190501.24101600': __('identifierSchemeCodeUnspsc190501.24101600'),
	'identifierSchemeCodeUnspsc190501.24101601': __('identifierSchemeCodeUnspsc190501.24101601'),
	'identifierSchemeCodeUnspsc190501.24101602': __('identifierSchemeCodeUnspsc190501.24101602'),
	'identifierSchemeCodeUnspsc190501.24101603': __('identifierSchemeCodeUnspsc190501.24101603'),
	'identifierSchemeCodeUnspsc190501.24101604': __('identifierSchemeCodeUnspsc190501.24101604'),
	'identifierSchemeCodeUnspsc190501.24101605': __('identifierSchemeCodeUnspsc190501.24101605'),
	'identifierSchemeCodeUnspsc190501.24101606': __('identifierSchemeCodeUnspsc190501.24101606'),
	'identifierSchemeCodeUnspsc190501.24101608': __('identifierSchemeCodeUnspsc190501.24101608'),
	'identifierSchemeCodeUnspsc190501.24101609': __('identifierSchemeCodeUnspsc190501.24101609'),
	'identifierSchemeCodeUnspsc190501.24101610': __('identifierSchemeCodeUnspsc190501.24101610'),
	'identifierSchemeCodeUnspsc190501.24101611': __('identifierSchemeCodeUnspsc190501.24101611'),
	'identifierSchemeCodeUnspsc190501.24101612': __('identifierSchemeCodeUnspsc190501.24101612'),
	'identifierSchemeCodeUnspsc190501.24101613': __('identifierSchemeCodeUnspsc190501.24101613'),
	'identifierSchemeCodeUnspsc190501.24101614': __('identifierSchemeCodeUnspsc190501.24101614'),
	'identifierSchemeCodeUnspsc190501.24101615': __('identifierSchemeCodeUnspsc190501.24101615'),
	'identifierSchemeCodeUnspsc190501.24101616': __('identifierSchemeCodeUnspsc190501.24101616'),
	'identifierSchemeCodeUnspsc190501.24101617': __('identifierSchemeCodeUnspsc190501.24101617'),
	'identifierSchemeCodeUnspsc190501.24101618': __('identifierSchemeCodeUnspsc190501.24101618'),
	'identifierSchemeCodeUnspsc190501.24101619': __('identifierSchemeCodeUnspsc190501.24101619'),
	'identifierSchemeCodeUnspsc190501.24101620': __('identifierSchemeCodeUnspsc190501.24101620'),
	'identifierSchemeCodeUnspsc190501.24101621': __('identifierSchemeCodeUnspsc190501.24101621'),
	'identifierSchemeCodeUnspsc190501.24101622': __('identifierSchemeCodeUnspsc190501.24101622'),
	'identifierSchemeCodeUnspsc190501.24101623': __('identifierSchemeCodeUnspsc190501.24101623'),
	'identifierSchemeCodeUnspsc190501.24101624': __('identifierSchemeCodeUnspsc190501.24101624'),
	'identifierSchemeCodeUnspsc190501.24101625': __('identifierSchemeCodeUnspsc190501.24101625'),
	'identifierSchemeCodeUnspsc190501.24101626': __('identifierSchemeCodeUnspsc190501.24101626'),
	'identifierSchemeCodeUnspsc190501.24101627': __('identifierSchemeCodeUnspsc190501.24101627'),
	'identifierSchemeCodeUnspsc190501.24101628': __('identifierSchemeCodeUnspsc190501.24101628'),
	'identifierSchemeCodeUnspsc190501.24101629': __('identifierSchemeCodeUnspsc190501.24101629'),
	'identifierSchemeCodeUnspsc190501.24101630': __('identifierSchemeCodeUnspsc190501.24101630'),
	'identifierSchemeCodeUnspsc190501.24101631': __('identifierSchemeCodeUnspsc190501.24101631'),
	'identifierSchemeCodeUnspsc190501.24101632': __('identifierSchemeCodeUnspsc190501.24101632'),
	'identifierSchemeCodeUnspsc190501.24101633': __('identifierSchemeCodeUnspsc190501.24101633'),
	'identifierSchemeCodeUnspsc190501.24101634': __('identifierSchemeCodeUnspsc190501.24101634'),
	'identifierSchemeCodeUnspsc190501.24101635': __('identifierSchemeCodeUnspsc190501.24101635'),
	'identifierSchemeCodeUnspsc190501.24101636': __('identifierSchemeCodeUnspsc190501.24101636'),
	'identifierSchemeCodeUnspsc190501.24101637': __('identifierSchemeCodeUnspsc190501.24101637'),
	'identifierSchemeCodeUnspsc190501.24101638': __('identifierSchemeCodeUnspsc190501.24101638'),
	'identifierSchemeCodeUnspsc190501.24101639': __('identifierSchemeCodeUnspsc190501.24101639'),
	'identifierSchemeCodeUnspsc190501.24101640': __('identifierSchemeCodeUnspsc190501.24101640'),
	'identifierSchemeCodeUnspsc190501.24101641': __('identifierSchemeCodeUnspsc190501.24101641'),
	'identifierSchemeCodeUnspsc190501.24101642': __('identifierSchemeCodeUnspsc190501.24101642'),
	'identifierSchemeCodeUnspsc190501.24101643': __('identifierSchemeCodeUnspsc190501.24101643'),
	'identifierSchemeCodeUnspsc190501.24101644': __('identifierSchemeCodeUnspsc190501.24101644'),
	'identifierSchemeCodeUnspsc190501.24101645': __('identifierSchemeCodeUnspsc190501.24101645'),
	'identifierSchemeCodeUnspsc190501.24101646': __('identifierSchemeCodeUnspsc190501.24101646'),
	'identifierSchemeCodeUnspsc190501.24101647': __('identifierSchemeCodeUnspsc190501.24101647'),
	'identifierSchemeCodeUnspsc190501.24101648': __('identifierSchemeCodeUnspsc190501.24101648'),
	'identifierSchemeCodeUnspsc190501.24101649': __('identifierSchemeCodeUnspsc190501.24101649'),
	'identifierSchemeCodeUnspsc190501.24101650': __('identifierSchemeCodeUnspsc190501.24101650'),
	'identifierSchemeCodeUnspsc190501.24101651': __('identifierSchemeCodeUnspsc190501.24101651'),
	'identifierSchemeCodeUnspsc190501.24101652': __('identifierSchemeCodeUnspsc190501.24101652'),
	'identifierSchemeCodeUnspsc190501.24101653': __('identifierSchemeCodeUnspsc190501.24101653'),
	'identifierSchemeCodeUnspsc190501.24101654': __('identifierSchemeCodeUnspsc190501.24101654'),
	'identifierSchemeCodeUnspsc190501.24101655': __('identifierSchemeCodeUnspsc190501.24101655'),
	'identifierSchemeCodeUnspsc190501.24101656': __('identifierSchemeCodeUnspsc190501.24101656'),
	'identifierSchemeCodeUnspsc190501.24101657': __('identifierSchemeCodeUnspsc190501.24101657'),
	'identifierSchemeCodeUnspsc190501.24101658': __('identifierSchemeCodeUnspsc190501.24101658'),
	'identifierSchemeCodeUnspsc190501.24101659': __('identifierSchemeCodeUnspsc190501.24101659'),
	'identifierSchemeCodeUnspsc190501.24101660': __('identifierSchemeCodeUnspsc190501.24101660'),
	'identifierSchemeCodeUnspsc190501.24101661': __('identifierSchemeCodeUnspsc190501.24101661'),
	'identifierSchemeCodeUnspsc190501.24101662': __('identifierSchemeCodeUnspsc190501.24101662'),
	'identifierSchemeCodeUnspsc190501.24101700': __('identifierSchemeCodeUnspsc190501.24101700'),
	'identifierSchemeCodeUnspsc190501.24101701': __('identifierSchemeCodeUnspsc190501.24101701'),
	'identifierSchemeCodeUnspsc190501.24101702': __('identifierSchemeCodeUnspsc190501.24101702'),
	'identifierSchemeCodeUnspsc190501.24101703': __('identifierSchemeCodeUnspsc190501.24101703'),
	'identifierSchemeCodeUnspsc190501.24101704': __('identifierSchemeCodeUnspsc190501.24101704'),
	'identifierSchemeCodeUnspsc190501.24101705': __('identifierSchemeCodeUnspsc190501.24101705'),
	'identifierSchemeCodeUnspsc190501.24101706': __('identifierSchemeCodeUnspsc190501.24101706'),
	'identifierSchemeCodeUnspsc190501.24101707': __('identifierSchemeCodeUnspsc190501.24101707'),
	'identifierSchemeCodeUnspsc190501.24101708': __('identifierSchemeCodeUnspsc190501.24101708'),
	'identifierSchemeCodeUnspsc190501.24101709': __('identifierSchemeCodeUnspsc190501.24101709'),
	'identifierSchemeCodeUnspsc190501.24101710': __('identifierSchemeCodeUnspsc190501.24101710'),
	'identifierSchemeCodeUnspsc190501.24101711': __('identifierSchemeCodeUnspsc190501.24101711'),
	'identifierSchemeCodeUnspsc190501.24101712': __('identifierSchemeCodeUnspsc190501.24101712'),
	'identifierSchemeCodeUnspsc190501.24101713': __('identifierSchemeCodeUnspsc190501.24101713'),
	'identifierSchemeCodeUnspsc190501.24101714': __('identifierSchemeCodeUnspsc190501.24101714'),
	'identifierSchemeCodeUnspsc190501.24101715': __('identifierSchemeCodeUnspsc190501.24101715'),
	'identifierSchemeCodeUnspsc190501.24101716': __('identifierSchemeCodeUnspsc190501.24101716'),
	'identifierSchemeCodeUnspsc190501.24101717': __('identifierSchemeCodeUnspsc190501.24101717'),
	'identifierSchemeCodeUnspsc190501.24101718': __('identifierSchemeCodeUnspsc190501.24101718'),
	'identifierSchemeCodeUnspsc190501.24101719': __('identifierSchemeCodeUnspsc190501.24101719'),
	'identifierSchemeCodeUnspsc190501.24101721': __('identifierSchemeCodeUnspsc190501.24101721'),
	'identifierSchemeCodeUnspsc190501.24101722': __('identifierSchemeCodeUnspsc190501.24101722'),
	'identifierSchemeCodeUnspsc190501.24101723': __('identifierSchemeCodeUnspsc190501.24101723'),
	'identifierSchemeCodeUnspsc190501.24101724': __('identifierSchemeCodeUnspsc190501.24101724'),
	'identifierSchemeCodeUnspsc190501.24101725': __('identifierSchemeCodeUnspsc190501.24101725'),
	'identifierSchemeCodeUnspsc190501.24101726': __('identifierSchemeCodeUnspsc190501.24101726'),
	'identifierSchemeCodeUnspsc190501.24101727': __('identifierSchemeCodeUnspsc190501.24101727'),
	'identifierSchemeCodeUnspsc190501.24101728': __('identifierSchemeCodeUnspsc190501.24101728'),
	'identifierSchemeCodeUnspsc190501.24101729': __('identifierSchemeCodeUnspsc190501.24101729'),
	'identifierSchemeCodeUnspsc190501.24101730': __('identifierSchemeCodeUnspsc190501.24101730'),
	'identifierSchemeCodeUnspsc190501.24101731': __('identifierSchemeCodeUnspsc190501.24101731'),
	'identifierSchemeCodeUnspsc190501.24101732': __('identifierSchemeCodeUnspsc190501.24101732'),
	'identifierSchemeCodeUnspsc190501.24101733': __('identifierSchemeCodeUnspsc190501.24101733'),
	'identifierSchemeCodeUnspsc190501.24101734': __('identifierSchemeCodeUnspsc190501.24101734'),
	'identifierSchemeCodeUnspsc190501.24101735': __('identifierSchemeCodeUnspsc190501.24101735'),
	'identifierSchemeCodeUnspsc190501.24101736': __('identifierSchemeCodeUnspsc190501.24101736'),
	'identifierSchemeCodeUnspsc190501.24101737': __('identifierSchemeCodeUnspsc190501.24101737'),
	'identifierSchemeCodeUnspsc190501.24101738': __('identifierSchemeCodeUnspsc190501.24101738'),
	'identifierSchemeCodeUnspsc190501.24101739': __('identifierSchemeCodeUnspsc190501.24101739'),
	'identifierSchemeCodeUnspsc190501.24101740': __('identifierSchemeCodeUnspsc190501.24101740'),
	'identifierSchemeCodeUnspsc190501.24101741': __('identifierSchemeCodeUnspsc190501.24101741'),
	'identifierSchemeCodeUnspsc190501.24101742': __('identifierSchemeCodeUnspsc190501.24101742'),
	'identifierSchemeCodeUnspsc190501.24101743': __('identifierSchemeCodeUnspsc190501.24101743'),
	'identifierSchemeCodeUnspsc190501.24101744': __('identifierSchemeCodeUnspsc190501.24101744'),
	'identifierSchemeCodeUnspsc190501.24101745': __('identifierSchemeCodeUnspsc190501.24101745'),
	'identifierSchemeCodeUnspsc190501.24101746': __('identifierSchemeCodeUnspsc190501.24101746'),
	'identifierSchemeCodeUnspsc190501.24101747': __('identifierSchemeCodeUnspsc190501.24101747'),
	'identifierSchemeCodeUnspsc190501.24101748': __('identifierSchemeCodeUnspsc190501.24101748'),
	'identifierSchemeCodeUnspsc190501.24101749': __('identifierSchemeCodeUnspsc190501.24101749'),
	'identifierSchemeCodeUnspsc190501.24101750': __('identifierSchemeCodeUnspsc190501.24101750'),
	'identifierSchemeCodeUnspsc190501.24101800': __('identifierSchemeCodeUnspsc190501.24101800'),
	'identifierSchemeCodeUnspsc190501.24101801': __('identifierSchemeCodeUnspsc190501.24101801'),
	'identifierSchemeCodeUnspsc190501.24101802': __('identifierSchemeCodeUnspsc190501.24101802'),
	'identifierSchemeCodeUnspsc190501.24101803': __('identifierSchemeCodeUnspsc190501.24101803'),
	'identifierSchemeCodeUnspsc190501.24101804': __('identifierSchemeCodeUnspsc190501.24101804'),
	'identifierSchemeCodeUnspsc190501.24101805': __('identifierSchemeCodeUnspsc190501.24101805'),
	'identifierSchemeCodeUnspsc190501.24101806': __('identifierSchemeCodeUnspsc190501.24101806'),
	'identifierSchemeCodeUnspsc190501.24101807': __('identifierSchemeCodeUnspsc190501.24101807'),
	'identifierSchemeCodeUnspsc190501.24101808': __('identifierSchemeCodeUnspsc190501.24101808'),
	'identifierSchemeCodeUnspsc190501.24101809': __('identifierSchemeCodeUnspsc190501.24101809'),
	'identifierSchemeCodeUnspsc190501.24101900': __('identifierSchemeCodeUnspsc190501.24101900'),
	'identifierSchemeCodeUnspsc190501.24101901': __('identifierSchemeCodeUnspsc190501.24101901'),
	'identifierSchemeCodeUnspsc190501.24101902': __('identifierSchemeCodeUnspsc190501.24101902'),
	'identifierSchemeCodeUnspsc190501.24101903': __('identifierSchemeCodeUnspsc190501.24101903'),
	'identifierSchemeCodeUnspsc190501.24101904': __('identifierSchemeCodeUnspsc190501.24101904'),
	'identifierSchemeCodeUnspsc190501.24101905': __('identifierSchemeCodeUnspsc190501.24101905'),
	'identifierSchemeCodeUnspsc190501.24101906': __('identifierSchemeCodeUnspsc190501.24101906'),
	'identifierSchemeCodeUnspsc190501.24101907': __('identifierSchemeCodeUnspsc190501.24101907'),
	'identifierSchemeCodeUnspsc190501.24101908': __('identifierSchemeCodeUnspsc190501.24101908'),
	'identifierSchemeCodeUnspsc190501.24102000': __('identifierSchemeCodeUnspsc190501.24102000'),
	'identifierSchemeCodeUnspsc190501.24102001': __('identifierSchemeCodeUnspsc190501.24102001'),
	'identifierSchemeCodeUnspsc190501.24102002': __('identifierSchemeCodeUnspsc190501.24102002'),
	'identifierSchemeCodeUnspsc190501.24102004': __('identifierSchemeCodeUnspsc190501.24102004'),
	'identifierSchemeCodeUnspsc190501.24102005': __('identifierSchemeCodeUnspsc190501.24102005'),
	'identifierSchemeCodeUnspsc190501.24102006': __('identifierSchemeCodeUnspsc190501.24102006'),
	'identifierSchemeCodeUnspsc190501.24102007': __('identifierSchemeCodeUnspsc190501.24102007'),
	'identifierSchemeCodeUnspsc190501.24102008': __('identifierSchemeCodeUnspsc190501.24102008'),
	'identifierSchemeCodeUnspsc190501.24102009': __('identifierSchemeCodeUnspsc190501.24102009'),
	'identifierSchemeCodeUnspsc190501.24102010': __('identifierSchemeCodeUnspsc190501.24102010'),
	'identifierSchemeCodeUnspsc190501.24102011': __('identifierSchemeCodeUnspsc190501.24102011'),
	'identifierSchemeCodeUnspsc190501.24102100': __('identifierSchemeCodeUnspsc190501.24102100'),
	'identifierSchemeCodeUnspsc190501.24102101': __('identifierSchemeCodeUnspsc190501.24102101'),
	'identifierSchemeCodeUnspsc190501.24102102': __('identifierSchemeCodeUnspsc190501.24102102'),
	'identifierSchemeCodeUnspsc190501.24102103': __('identifierSchemeCodeUnspsc190501.24102103'),
	'identifierSchemeCodeUnspsc190501.24102104': __('identifierSchemeCodeUnspsc190501.24102104'),
	'identifierSchemeCodeUnspsc190501.24102105': __('identifierSchemeCodeUnspsc190501.24102105'),
	'identifierSchemeCodeUnspsc190501.24102106': __('identifierSchemeCodeUnspsc190501.24102106'),
	'identifierSchemeCodeUnspsc190501.24102107': __('identifierSchemeCodeUnspsc190501.24102107'),
	'identifierSchemeCodeUnspsc190501.24102108': __('identifierSchemeCodeUnspsc190501.24102108'),
	'identifierSchemeCodeUnspsc190501.24102109': __('identifierSchemeCodeUnspsc190501.24102109'),
	'identifierSchemeCodeUnspsc190501.24102200': __('identifierSchemeCodeUnspsc190501.24102200'),
	'identifierSchemeCodeUnspsc190501.24102201': __('identifierSchemeCodeUnspsc190501.24102201'),
	'identifierSchemeCodeUnspsc190501.24102202': __('identifierSchemeCodeUnspsc190501.24102202'),
	'identifierSchemeCodeUnspsc190501.24102203': __('identifierSchemeCodeUnspsc190501.24102203'),
	'identifierSchemeCodeUnspsc190501.24102204': __('identifierSchemeCodeUnspsc190501.24102204'),
	'identifierSchemeCodeUnspsc190501.24102208': __('identifierSchemeCodeUnspsc190501.24102208'),
	'identifierSchemeCodeUnspsc190501.24102209': __('identifierSchemeCodeUnspsc190501.24102209'),
	'identifierSchemeCodeUnspsc190501.24102210': __('identifierSchemeCodeUnspsc190501.24102210'),
	'identifierSchemeCodeUnspsc190501.24102300': __('identifierSchemeCodeUnspsc190501.24102300'),
	'identifierSchemeCodeUnspsc190501.24102301': __('identifierSchemeCodeUnspsc190501.24102301'),
	'identifierSchemeCodeUnspsc190501.24102302': __('identifierSchemeCodeUnspsc190501.24102302'),
	'identifierSchemeCodeUnspsc190501.24102400': __('identifierSchemeCodeUnspsc190501.24102400'),
	'identifierSchemeCodeUnspsc190501.24102401': __('identifierSchemeCodeUnspsc190501.24102401'),
	'identifierSchemeCodeUnspsc190501.24102402': __('identifierSchemeCodeUnspsc190501.24102402'),
	'identifierSchemeCodeUnspsc190501.24110000': __('identifierSchemeCodeUnspsc190501.24110000'),
	'identifierSchemeCodeUnspsc190501.24111500': __('identifierSchemeCodeUnspsc190501.24111500'),
	'identifierSchemeCodeUnspsc190501.24111501': __('identifierSchemeCodeUnspsc190501.24111501'),
	'identifierSchemeCodeUnspsc190501.24111502': __('identifierSchemeCodeUnspsc190501.24111502'),
	'identifierSchemeCodeUnspsc190501.24111503': __('identifierSchemeCodeUnspsc190501.24111503'),
	'identifierSchemeCodeUnspsc190501.24111505': __('identifierSchemeCodeUnspsc190501.24111505'),
	'identifierSchemeCodeUnspsc190501.24111506': __('identifierSchemeCodeUnspsc190501.24111506'),
	'identifierSchemeCodeUnspsc190501.24111507': __('identifierSchemeCodeUnspsc190501.24111507'),
	'identifierSchemeCodeUnspsc190501.24111508': __('identifierSchemeCodeUnspsc190501.24111508'),
	'identifierSchemeCodeUnspsc190501.24111509': __('identifierSchemeCodeUnspsc190501.24111509'),
	'identifierSchemeCodeUnspsc190501.24111510': __('identifierSchemeCodeUnspsc190501.24111510'),
	'identifierSchemeCodeUnspsc190501.24111511': __('identifierSchemeCodeUnspsc190501.24111511'),
	'identifierSchemeCodeUnspsc190501.24111512': __('identifierSchemeCodeUnspsc190501.24111512'),
	'identifierSchemeCodeUnspsc190501.24111513': __('identifierSchemeCodeUnspsc190501.24111513'),
	'identifierSchemeCodeUnspsc190501.24111514': __('identifierSchemeCodeUnspsc190501.24111514'),
	'identifierSchemeCodeUnspsc190501.24111515': __('identifierSchemeCodeUnspsc190501.24111515'),
	'identifierSchemeCodeUnspsc190501.24111800': __('identifierSchemeCodeUnspsc190501.24111800'),
	'identifierSchemeCodeUnspsc190501.24111801': __('identifierSchemeCodeUnspsc190501.24111801'),
	'identifierSchemeCodeUnspsc190501.24111802': __('identifierSchemeCodeUnspsc190501.24111802'),
	'identifierSchemeCodeUnspsc190501.24111803': __('identifierSchemeCodeUnspsc190501.24111803'),
	'identifierSchemeCodeUnspsc190501.24111804': __('identifierSchemeCodeUnspsc190501.24111804'),
	'identifierSchemeCodeUnspsc190501.24111805': __('identifierSchemeCodeUnspsc190501.24111805'),
	'identifierSchemeCodeUnspsc190501.24111806': __('identifierSchemeCodeUnspsc190501.24111806'),
	'identifierSchemeCodeUnspsc190501.24111807': __('identifierSchemeCodeUnspsc190501.24111807'),
	'identifierSchemeCodeUnspsc190501.24111808': __('identifierSchemeCodeUnspsc190501.24111808'),
	'identifierSchemeCodeUnspsc190501.24111809': __('identifierSchemeCodeUnspsc190501.24111809'),
	'identifierSchemeCodeUnspsc190501.24111810': __('identifierSchemeCodeUnspsc190501.24111810'),
	'identifierSchemeCodeUnspsc190501.24111811': __('identifierSchemeCodeUnspsc190501.24111811'),
	'identifierSchemeCodeUnspsc190501.24111812': __('identifierSchemeCodeUnspsc190501.24111812'),
	'identifierSchemeCodeUnspsc190501.24111813': __('identifierSchemeCodeUnspsc190501.24111813'),
	'identifierSchemeCodeUnspsc190501.24111814': __('identifierSchemeCodeUnspsc190501.24111814'),
	'identifierSchemeCodeUnspsc190501.24111815': __('identifierSchemeCodeUnspsc190501.24111815'),
	'identifierSchemeCodeUnspsc190501.24111816': __('identifierSchemeCodeUnspsc190501.24111816'),
	'identifierSchemeCodeUnspsc190501.24111817': __('identifierSchemeCodeUnspsc190501.24111817'),
	'identifierSchemeCodeUnspsc190501.24111818': __('identifierSchemeCodeUnspsc190501.24111818'),
	'identifierSchemeCodeUnspsc190501.24111819': __('identifierSchemeCodeUnspsc190501.24111819'),
	'identifierSchemeCodeUnspsc190501.24112000': __('identifierSchemeCodeUnspsc190501.24112000'),
	'identifierSchemeCodeUnspsc190501.24112003': __('identifierSchemeCodeUnspsc190501.24112003'),
	'identifierSchemeCodeUnspsc190501.24112004': __('identifierSchemeCodeUnspsc190501.24112004'),
	'identifierSchemeCodeUnspsc190501.24112005': __('identifierSchemeCodeUnspsc190501.24112005'),
	'identifierSchemeCodeUnspsc190501.24112006': __('identifierSchemeCodeUnspsc190501.24112006'),
	'identifierSchemeCodeUnspsc190501.24112007': __('identifierSchemeCodeUnspsc190501.24112007'),
	'identifierSchemeCodeUnspsc190501.24112100': __('identifierSchemeCodeUnspsc190501.24112100'),
	'identifierSchemeCodeUnspsc190501.24112101': __('identifierSchemeCodeUnspsc190501.24112101'),
	'identifierSchemeCodeUnspsc190501.24112102': __('identifierSchemeCodeUnspsc190501.24112102'),
	'identifierSchemeCodeUnspsc190501.24112108': __('identifierSchemeCodeUnspsc190501.24112108'),
	'identifierSchemeCodeUnspsc190501.24112109': __('identifierSchemeCodeUnspsc190501.24112109'),
	'identifierSchemeCodeUnspsc190501.24112110': __('identifierSchemeCodeUnspsc190501.24112110'),
	'identifierSchemeCodeUnspsc190501.24112111': __('identifierSchemeCodeUnspsc190501.24112111'),
	'identifierSchemeCodeUnspsc190501.24112112': __('identifierSchemeCodeUnspsc190501.24112112'),
	'identifierSchemeCodeUnspsc190501.24112200': __('identifierSchemeCodeUnspsc190501.24112200'),
	'identifierSchemeCodeUnspsc190501.24112204': __('identifierSchemeCodeUnspsc190501.24112204'),
	'identifierSchemeCodeUnspsc190501.24112205': __('identifierSchemeCodeUnspsc190501.24112205'),
	'identifierSchemeCodeUnspsc190501.24112206': __('identifierSchemeCodeUnspsc190501.24112206'),
	'identifierSchemeCodeUnspsc190501.24112207': __('identifierSchemeCodeUnspsc190501.24112207'),
	'identifierSchemeCodeUnspsc190501.24112208': __('identifierSchemeCodeUnspsc190501.24112208'),
	'identifierSchemeCodeUnspsc190501.24112209': __('identifierSchemeCodeUnspsc190501.24112209'),
	'identifierSchemeCodeUnspsc190501.24112210': __('identifierSchemeCodeUnspsc190501.24112210'),
	'identifierSchemeCodeUnspsc190501.24112400': __('identifierSchemeCodeUnspsc190501.24112400'),
	'identifierSchemeCodeUnspsc190501.24112401': __('identifierSchemeCodeUnspsc190501.24112401'),
	'identifierSchemeCodeUnspsc190501.24112402': __('identifierSchemeCodeUnspsc190501.24112402'),
	'identifierSchemeCodeUnspsc190501.24112403': __('identifierSchemeCodeUnspsc190501.24112403'),
	'identifierSchemeCodeUnspsc190501.24112406': __('identifierSchemeCodeUnspsc190501.24112406'),
	'identifierSchemeCodeUnspsc190501.24112411': __('identifierSchemeCodeUnspsc190501.24112411'),
	'identifierSchemeCodeUnspsc190501.24112412': __('identifierSchemeCodeUnspsc190501.24112412'),
	'identifierSchemeCodeUnspsc190501.24112413': __('identifierSchemeCodeUnspsc190501.24112413'),
	'identifierSchemeCodeUnspsc190501.24112416': __('identifierSchemeCodeUnspsc190501.24112416'),
	'identifierSchemeCodeUnspsc190501.24112500': __('identifierSchemeCodeUnspsc190501.24112500'),
	'identifierSchemeCodeUnspsc190501.24112501': __('identifierSchemeCodeUnspsc190501.24112501'),
	'identifierSchemeCodeUnspsc190501.24112502': __('identifierSchemeCodeUnspsc190501.24112502'),
	'identifierSchemeCodeUnspsc190501.24112503': __('identifierSchemeCodeUnspsc190501.24112503'),
	'identifierSchemeCodeUnspsc190501.24112505': __('identifierSchemeCodeUnspsc190501.24112505'),
	'identifierSchemeCodeUnspsc190501.24112600': __('identifierSchemeCodeUnspsc190501.24112600'),
	'identifierSchemeCodeUnspsc190501.24112601': __('identifierSchemeCodeUnspsc190501.24112601'),
	'identifierSchemeCodeUnspsc190501.24112602': __('identifierSchemeCodeUnspsc190501.24112602'),
	'identifierSchemeCodeUnspsc190501.24112700': __('identifierSchemeCodeUnspsc190501.24112700'),
	'identifierSchemeCodeUnspsc190501.24112701': __('identifierSchemeCodeUnspsc190501.24112701'),
	'identifierSchemeCodeUnspsc190501.24112702': __('identifierSchemeCodeUnspsc190501.24112702'),
	'identifierSchemeCodeUnspsc190501.24112703': __('identifierSchemeCodeUnspsc190501.24112703'),
	'identifierSchemeCodeUnspsc190501.24112800': __('identifierSchemeCodeUnspsc190501.24112800'),
	'identifierSchemeCodeUnspsc190501.24112801': __('identifierSchemeCodeUnspsc190501.24112801'),
	'identifierSchemeCodeUnspsc190501.24112802': __('identifierSchemeCodeUnspsc190501.24112802'),
	'identifierSchemeCodeUnspsc190501.24112803': __('identifierSchemeCodeUnspsc190501.24112803'),
	'identifierSchemeCodeUnspsc190501.24112804': __('identifierSchemeCodeUnspsc190501.24112804'),
	'identifierSchemeCodeUnspsc190501.24112805': __('identifierSchemeCodeUnspsc190501.24112805'),
	'identifierSchemeCodeUnspsc190501.24112900': __('identifierSchemeCodeUnspsc190501.24112900'),
	'identifierSchemeCodeUnspsc190501.24112901': __('identifierSchemeCodeUnspsc190501.24112901'),
	'identifierSchemeCodeUnspsc190501.24112902': __('identifierSchemeCodeUnspsc190501.24112902'),
	'identifierSchemeCodeUnspsc190501.24113000': __('identifierSchemeCodeUnspsc190501.24113000'),
	'identifierSchemeCodeUnspsc190501.24113001': __('identifierSchemeCodeUnspsc190501.24113001'),
	'identifierSchemeCodeUnspsc190501.24113002': __('identifierSchemeCodeUnspsc190501.24113002'),
	'identifierSchemeCodeUnspsc190501.24113003': __('identifierSchemeCodeUnspsc190501.24113003'),
	'identifierSchemeCodeUnspsc190501.24113100': __('identifierSchemeCodeUnspsc190501.24113100'),
	'identifierSchemeCodeUnspsc190501.24113101': __('identifierSchemeCodeUnspsc190501.24113101'),
	'identifierSchemeCodeUnspsc190501.24113102': __('identifierSchemeCodeUnspsc190501.24113102'),
	'identifierSchemeCodeUnspsc190501.24113103': __('identifierSchemeCodeUnspsc190501.24113103'),
	'identifierSchemeCodeUnspsc190501.24113104': __('identifierSchemeCodeUnspsc190501.24113104'),
	'identifierSchemeCodeUnspsc190501.24113105': __('identifierSchemeCodeUnspsc190501.24113105'),
	'identifierSchemeCodeUnspsc190501.24113106': __('identifierSchemeCodeUnspsc190501.24113106'),
	'identifierSchemeCodeUnspsc190501.24113107': __('identifierSchemeCodeUnspsc190501.24113107'),
	'identifierSchemeCodeUnspsc190501.24113108': __('identifierSchemeCodeUnspsc190501.24113108'),
	'identifierSchemeCodeUnspsc190501.24113109': __('identifierSchemeCodeUnspsc190501.24113109'),
	'identifierSchemeCodeUnspsc190501.24120000': __('identifierSchemeCodeUnspsc190501.24120000'),
	'identifierSchemeCodeUnspsc190501.24121500': __('identifierSchemeCodeUnspsc190501.24121500'),
	'identifierSchemeCodeUnspsc190501.24121502': __('identifierSchemeCodeUnspsc190501.24121502'),
	'identifierSchemeCodeUnspsc190501.24121503': __('identifierSchemeCodeUnspsc190501.24121503'),
	'identifierSchemeCodeUnspsc190501.24121504': __('identifierSchemeCodeUnspsc190501.24121504'),
	'identifierSchemeCodeUnspsc190501.24121506': __('identifierSchemeCodeUnspsc190501.24121506'),
	'identifierSchemeCodeUnspsc190501.24121507': __('identifierSchemeCodeUnspsc190501.24121507'),
	'identifierSchemeCodeUnspsc190501.24121508': __('identifierSchemeCodeUnspsc190501.24121508'),
	'identifierSchemeCodeUnspsc190501.24121509': __('identifierSchemeCodeUnspsc190501.24121509'),
	'identifierSchemeCodeUnspsc190501.24121510': __('identifierSchemeCodeUnspsc190501.24121510'),
	'identifierSchemeCodeUnspsc190501.24121511': __('identifierSchemeCodeUnspsc190501.24121511'),
	'identifierSchemeCodeUnspsc190501.24121512': __('identifierSchemeCodeUnspsc190501.24121512'),
	'identifierSchemeCodeUnspsc190501.24121513': __('identifierSchemeCodeUnspsc190501.24121513'),
	'identifierSchemeCodeUnspsc190501.24121514': __('identifierSchemeCodeUnspsc190501.24121514'),
	'identifierSchemeCodeUnspsc190501.24121800': __('identifierSchemeCodeUnspsc190501.24121800'),
	'identifierSchemeCodeUnspsc190501.24121801': __('identifierSchemeCodeUnspsc190501.24121801'),
	'identifierSchemeCodeUnspsc190501.24121802': __('identifierSchemeCodeUnspsc190501.24121802'),
	'identifierSchemeCodeUnspsc190501.24121803': __('identifierSchemeCodeUnspsc190501.24121803'),
	'identifierSchemeCodeUnspsc190501.24121804': __('identifierSchemeCodeUnspsc190501.24121804'),
	'identifierSchemeCodeUnspsc190501.24121805': __('identifierSchemeCodeUnspsc190501.24121805'),
	'identifierSchemeCodeUnspsc190501.24121806': __('identifierSchemeCodeUnspsc190501.24121806'),
	'identifierSchemeCodeUnspsc190501.24121807': __('identifierSchemeCodeUnspsc190501.24121807'),
	'identifierSchemeCodeUnspsc190501.24121808': __('identifierSchemeCodeUnspsc190501.24121808'),
	'identifierSchemeCodeUnspsc190501.24122000': __('identifierSchemeCodeUnspsc190501.24122000'),
	'identifierSchemeCodeUnspsc190501.24122001': __('identifierSchemeCodeUnspsc190501.24122001'),
	'identifierSchemeCodeUnspsc190501.24122002': __('identifierSchemeCodeUnspsc190501.24122002'),
	'identifierSchemeCodeUnspsc190501.24122003': __('identifierSchemeCodeUnspsc190501.24122003'),
	'identifierSchemeCodeUnspsc190501.24122004': __('identifierSchemeCodeUnspsc190501.24122004'),
	'identifierSchemeCodeUnspsc190501.24122005': __('identifierSchemeCodeUnspsc190501.24122005'),
	'identifierSchemeCodeUnspsc190501.24122006': __('identifierSchemeCodeUnspsc190501.24122006'),
	'identifierSchemeCodeUnspsc190501.24130000': __('identifierSchemeCodeUnspsc190501.24130000'),
	'identifierSchemeCodeUnspsc190501.24131500': __('identifierSchemeCodeUnspsc190501.24131500'),
	'identifierSchemeCodeUnspsc190501.24131501': __('identifierSchemeCodeUnspsc190501.24131501'),
	'identifierSchemeCodeUnspsc190501.24131502': __('identifierSchemeCodeUnspsc190501.24131502'),
	'identifierSchemeCodeUnspsc190501.24131503': __('identifierSchemeCodeUnspsc190501.24131503'),
	'identifierSchemeCodeUnspsc190501.24131504': __('identifierSchemeCodeUnspsc190501.24131504'),
	'identifierSchemeCodeUnspsc190501.24131505': __('identifierSchemeCodeUnspsc190501.24131505'),
	'identifierSchemeCodeUnspsc190501.24131506': __('identifierSchemeCodeUnspsc190501.24131506'),
	'identifierSchemeCodeUnspsc190501.24131507': __('identifierSchemeCodeUnspsc190501.24131507'),
	'identifierSchemeCodeUnspsc190501.24131508': __('identifierSchemeCodeUnspsc190501.24131508'),
	'identifierSchemeCodeUnspsc190501.24131509': __('identifierSchemeCodeUnspsc190501.24131509'),
	'identifierSchemeCodeUnspsc190501.24131510': __('identifierSchemeCodeUnspsc190501.24131510'),
	'identifierSchemeCodeUnspsc190501.24131511': __('identifierSchemeCodeUnspsc190501.24131511'),
	'identifierSchemeCodeUnspsc190501.24131512': __('identifierSchemeCodeUnspsc190501.24131512'),
	'identifierSchemeCodeUnspsc190501.24131513': __('identifierSchemeCodeUnspsc190501.24131513'),
	'identifierSchemeCodeUnspsc190501.24131514': __('identifierSchemeCodeUnspsc190501.24131514'),
	'identifierSchemeCodeUnspsc190501.24131515': __('identifierSchemeCodeUnspsc190501.24131515'),
	'identifierSchemeCodeUnspsc190501.24131516': __('identifierSchemeCodeUnspsc190501.24131516'),
	'identifierSchemeCodeUnspsc190501.24131600': __('identifierSchemeCodeUnspsc190501.24131600'),
	'identifierSchemeCodeUnspsc190501.24131601': __('identifierSchemeCodeUnspsc190501.24131601'),
	'identifierSchemeCodeUnspsc190501.24131602': __('identifierSchemeCodeUnspsc190501.24131602'),
	'identifierSchemeCodeUnspsc190501.24131603': __('identifierSchemeCodeUnspsc190501.24131603'),
	'identifierSchemeCodeUnspsc190501.24131604': __('identifierSchemeCodeUnspsc190501.24131604'),
	'identifierSchemeCodeUnspsc190501.24131605': __('identifierSchemeCodeUnspsc190501.24131605'),
	'identifierSchemeCodeUnspsc190501.24131606': __('identifierSchemeCodeUnspsc190501.24131606'),
	'identifierSchemeCodeUnspsc190501.24131607': __('identifierSchemeCodeUnspsc190501.24131607'),
	'identifierSchemeCodeUnspsc190501.24131608': __('identifierSchemeCodeUnspsc190501.24131608'),
	'identifierSchemeCodeUnspsc190501.24131609': __('identifierSchemeCodeUnspsc190501.24131609'),
	'identifierSchemeCodeUnspsc190501.24131610': __('identifierSchemeCodeUnspsc190501.24131610'),
	'identifierSchemeCodeUnspsc190501.24131900': __('identifierSchemeCodeUnspsc190501.24131900'),
	'identifierSchemeCodeUnspsc190501.24131901': __('identifierSchemeCodeUnspsc190501.24131901'),
	'identifierSchemeCodeUnspsc190501.24131902': __('identifierSchemeCodeUnspsc190501.24131902'),
	'identifierSchemeCodeUnspsc190501.24131903': __('identifierSchemeCodeUnspsc190501.24131903'),
	'identifierSchemeCodeUnspsc190501.24140000': __('identifierSchemeCodeUnspsc190501.24140000'),
	'identifierSchemeCodeUnspsc190501.24141500': __('identifierSchemeCodeUnspsc190501.24141500'),
	'identifierSchemeCodeUnspsc190501.24141501': __('identifierSchemeCodeUnspsc190501.24141501'),
	'identifierSchemeCodeUnspsc190501.24141502': __('identifierSchemeCodeUnspsc190501.24141502'),
	'identifierSchemeCodeUnspsc190501.24141504': __('identifierSchemeCodeUnspsc190501.24141504'),
	'identifierSchemeCodeUnspsc190501.24141506': __('identifierSchemeCodeUnspsc190501.24141506'),
	'identifierSchemeCodeUnspsc190501.24141507': __('identifierSchemeCodeUnspsc190501.24141507'),
	'identifierSchemeCodeUnspsc190501.24141508': __('identifierSchemeCodeUnspsc190501.24141508'),
	'identifierSchemeCodeUnspsc190501.24141510': __('identifierSchemeCodeUnspsc190501.24141510'),
	'identifierSchemeCodeUnspsc190501.24141511': __('identifierSchemeCodeUnspsc190501.24141511'),
	'identifierSchemeCodeUnspsc190501.24141512': __('identifierSchemeCodeUnspsc190501.24141512'),
	'identifierSchemeCodeUnspsc190501.24141513': __('identifierSchemeCodeUnspsc190501.24141513'),
	'identifierSchemeCodeUnspsc190501.24141514': __('identifierSchemeCodeUnspsc190501.24141514'),
	'identifierSchemeCodeUnspsc190501.24141515': __('identifierSchemeCodeUnspsc190501.24141515'),
	'identifierSchemeCodeUnspsc190501.24141516': __('identifierSchemeCodeUnspsc190501.24141516'),
	'identifierSchemeCodeUnspsc190501.24141517': __('identifierSchemeCodeUnspsc190501.24141517'),
	'identifierSchemeCodeUnspsc190501.24141518': __('identifierSchemeCodeUnspsc190501.24141518'),
	'identifierSchemeCodeUnspsc190501.24141519': __('identifierSchemeCodeUnspsc190501.24141519'),
	'identifierSchemeCodeUnspsc190501.24141520': __('identifierSchemeCodeUnspsc190501.24141520'),
	'identifierSchemeCodeUnspsc190501.24141600': __('identifierSchemeCodeUnspsc190501.24141600'),
	'identifierSchemeCodeUnspsc190501.24141601': __('identifierSchemeCodeUnspsc190501.24141601'),
	'identifierSchemeCodeUnspsc190501.24141602': __('identifierSchemeCodeUnspsc190501.24141602'),
	'identifierSchemeCodeUnspsc190501.24141603': __('identifierSchemeCodeUnspsc190501.24141603'),
	'identifierSchemeCodeUnspsc190501.24141604': __('identifierSchemeCodeUnspsc190501.24141604'),
	'identifierSchemeCodeUnspsc190501.24141605': __('identifierSchemeCodeUnspsc190501.24141605'),
	'identifierSchemeCodeUnspsc190501.24141606': __('identifierSchemeCodeUnspsc190501.24141606'),
	'identifierSchemeCodeUnspsc190501.24141607': __('identifierSchemeCodeUnspsc190501.24141607'),
	'identifierSchemeCodeUnspsc190501.24141608': __('identifierSchemeCodeUnspsc190501.24141608'),
	'identifierSchemeCodeUnspsc190501.24141700': __('identifierSchemeCodeUnspsc190501.24141700'),
	'identifierSchemeCodeUnspsc190501.24141701': __('identifierSchemeCodeUnspsc190501.24141701'),
	'identifierSchemeCodeUnspsc190501.24141702': __('identifierSchemeCodeUnspsc190501.24141702'),
	'identifierSchemeCodeUnspsc190501.24141703': __('identifierSchemeCodeUnspsc190501.24141703'),
	'identifierSchemeCodeUnspsc190501.24141704': __('identifierSchemeCodeUnspsc190501.24141704'),
	'identifierSchemeCodeUnspsc190501.24141705': __('identifierSchemeCodeUnspsc190501.24141705'),
	'identifierSchemeCodeUnspsc190501.24141706': __('identifierSchemeCodeUnspsc190501.24141706'),
	'identifierSchemeCodeUnspsc190501.24141707': __('identifierSchemeCodeUnspsc190501.24141707'),
	'identifierSchemeCodeUnspsc190501.24141708': __('identifierSchemeCodeUnspsc190501.24141708'),
	'identifierSchemeCodeUnspsc190501.24141709': __('identifierSchemeCodeUnspsc190501.24141709'),
	'identifierSchemeCodeUnspsc190501.24141710': __('identifierSchemeCodeUnspsc190501.24141710'),
	'identifierSchemeCodeUnspsc190501.25000000': __('identifierSchemeCodeUnspsc190501.25000000'),
	'identifierSchemeCodeUnspsc190501.25100000': __('identifierSchemeCodeUnspsc190501.25100000'),
	'identifierSchemeCodeUnspsc190501.25101500': __('identifierSchemeCodeUnspsc190501.25101500'),
	'identifierSchemeCodeUnspsc190501.25101501': __('identifierSchemeCodeUnspsc190501.25101501'),
	'identifierSchemeCodeUnspsc190501.25101502': __('identifierSchemeCodeUnspsc190501.25101502'),
	'identifierSchemeCodeUnspsc190501.25101503': __('identifierSchemeCodeUnspsc190501.25101503'),
	'identifierSchemeCodeUnspsc190501.25101504': __('identifierSchemeCodeUnspsc190501.25101504'),
	'identifierSchemeCodeUnspsc190501.25101505': __('identifierSchemeCodeUnspsc190501.25101505'),
	'identifierSchemeCodeUnspsc190501.25101506': __('identifierSchemeCodeUnspsc190501.25101506'),
	'identifierSchemeCodeUnspsc190501.25101507': __('identifierSchemeCodeUnspsc190501.25101507'),
	'identifierSchemeCodeUnspsc190501.25101508': __('identifierSchemeCodeUnspsc190501.25101508'),
	'identifierSchemeCodeUnspsc190501.25101509': __('identifierSchemeCodeUnspsc190501.25101509'),
	'identifierSchemeCodeUnspsc190501.25101510': __('identifierSchemeCodeUnspsc190501.25101510'),
	'identifierSchemeCodeUnspsc190501.25101600': __('identifierSchemeCodeUnspsc190501.25101600'),
	'identifierSchemeCodeUnspsc190501.25101601': __('identifierSchemeCodeUnspsc190501.25101601'),
	'identifierSchemeCodeUnspsc190501.25101602': __('identifierSchemeCodeUnspsc190501.25101602'),
	'identifierSchemeCodeUnspsc190501.25101604': __('identifierSchemeCodeUnspsc190501.25101604'),
	'identifierSchemeCodeUnspsc190501.25101609': __('identifierSchemeCodeUnspsc190501.25101609'),
	'identifierSchemeCodeUnspsc190501.25101610': __('identifierSchemeCodeUnspsc190501.25101610'),
	'identifierSchemeCodeUnspsc190501.25101611': __('identifierSchemeCodeUnspsc190501.25101611'),
	'identifierSchemeCodeUnspsc190501.25101612': __('identifierSchemeCodeUnspsc190501.25101612'),
	'identifierSchemeCodeUnspsc190501.25101613': __('identifierSchemeCodeUnspsc190501.25101613'),
	'identifierSchemeCodeUnspsc190501.25101614': __('identifierSchemeCodeUnspsc190501.25101614'),
	'identifierSchemeCodeUnspsc190501.25101700': __('identifierSchemeCodeUnspsc190501.25101700'),
	'identifierSchemeCodeUnspsc190501.25101702': __('identifierSchemeCodeUnspsc190501.25101702'),
	'identifierSchemeCodeUnspsc190501.25101703': __('identifierSchemeCodeUnspsc190501.25101703'),
	'identifierSchemeCodeUnspsc190501.25101704': __('identifierSchemeCodeUnspsc190501.25101704'),
	'identifierSchemeCodeUnspsc190501.25101705': __('identifierSchemeCodeUnspsc190501.25101705'),
	'identifierSchemeCodeUnspsc190501.25101706': __('identifierSchemeCodeUnspsc190501.25101706'),
	'identifierSchemeCodeUnspsc190501.25101707': __('identifierSchemeCodeUnspsc190501.25101707'),
	'identifierSchemeCodeUnspsc190501.25101708': __('identifierSchemeCodeUnspsc190501.25101708'),
	'identifierSchemeCodeUnspsc190501.25101709': __('identifierSchemeCodeUnspsc190501.25101709'),
	'identifierSchemeCodeUnspsc190501.25101710': __('identifierSchemeCodeUnspsc190501.25101710'),
	'identifierSchemeCodeUnspsc190501.25101711': __('identifierSchemeCodeUnspsc190501.25101711'),
	'identifierSchemeCodeUnspsc190501.25101712': __('identifierSchemeCodeUnspsc190501.25101712'),
	'identifierSchemeCodeUnspsc190501.25101713': __('identifierSchemeCodeUnspsc190501.25101713'),
	'identifierSchemeCodeUnspsc190501.25101714': __('identifierSchemeCodeUnspsc190501.25101714'),
	'identifierSchemeCodeUnspsc190501.25101800': __('identifierSchemeCodeUnspsc190501.25101800'),
	'identifierSchemeCodeUnspsc190501.25101801': __('identifierSchemeCodeUnspsc190501.25101801'),
	'identifierSchemeCodeUnspsc190501.25101802': __('identifierSchemeCodeUnspsc190501.25101802'),
	'identifierSchemeCodeUnspsc190501.25101803': __('identifierSchemeCodeUnspsc190501.25101803'),
	'identifierSchemeCodeUnspsc190501.25101804': __('identifierSchemeCodeUnspsc190501.25101804'),
	'identifierSchemeCodeUnspsc190501.25101900': __('identifierSchemeCodeUnspsc190501.25101900'),
	'identifierSchemeCodeUnspsc190501.25101901': __('identifierSchemeCodeUnspsc190501.25101901'),
	'identifierSchemeCodeUnspsc190501.25101902': __('identifierSchemeCodeUnspsc190501.25101902'),
	'identifierSchemeCodeUnspsc190501.25101903': __('identifierSchemeCodeUnspsc190501.25101903'),
	'identifierSchemeCodeUnspsc190501.25101904': __('identifierSchemeCodeUnspsc190501.25101904'),
	'identifierSchemeCodeUnspsc190501.25101905': __('identifierSchemeCodeUnspsc190501.25101905'),
	'identifierSchemeCodeUnspsc190501.25101906': __('identifierSchemeCodeUnspsc190501.25101906'),
	'identifierSchemeCodeUnspsc190501.25101907': __('identifierSchemeCodeUnspsc190501.25101907'),
	'identifierSchemeCodeUnspsc190501.25101908': __('identifierSchemeCodeUnspsc190501.25101908'),
	'identifierSchemeCodeUnspsc190501.25101909': __('identifierSchemeCodeUnspsc190501.25101909'),
	'identifierSchemeCodeUnspsc190501.25101910': __('identifierSchemeCodeUnspsc190501.25101910'),
	'identifierSchemeCodeUnspsc190501.25101911': __('identifierSchemeCodeUnspsc190501.25101911'),
	'identifierSchemeCodeUnspsc190501.25101912': __('identifierSchemeCodeUnspsc190501.25101912'),
	'identifierSchemeCodeUnspsc190501.25101913': __('identifierSchemeCodeUnspsc190501.25101913'),
	'identifierSchemeCodeUnspsc190501.25101914': __('identifierSchemeCodeUnspsc190501.25101914'),
	'identifierSchemeCodeUnspsc190501.25101915': __('identifierSchemeCodeUnspsc190501.25101915'),
	'identifierSchemeCodeUnspsc190501.25101916': __('identifierSchemeCodeUnspsc190501.25101916'),
	'identifierSchemeCodeUnspsc190501.25101917': __('identifierSchemeCodeUnspsc190501.25101917'),
	'identifierSchemeCodeUnspsc190501.25101918': __('identifierSchemeCodeUnspsc190501.25101918'),
	'identifierSchemeCodeUnspsc190501.25101919': __('identifierSchemeCodeUnspsc190501.25101919'),
	'identifierSchemeCodeUnspsc190501.25101920': __('identifierSchemeCodeUnspsc190501.25101920'),
	'identifierSchemeCodeUnspsc190501.25101921': __('identifierSchemeCodeUnspsc190501.25101921'),
	'identifierSchemeCodeUnspsc190501.25101922': __('identifierSchemeCodeUnspsc190501.25101922'),
	'identifierSchemeCodeUnspsc190501.25101923': __('identifierSchemeCodeUnspsc190501.25101923'),
	'identifierSchemeCodeUnspsc190501.25101924': __('identifierSchemeCodeUnspsc190501.25101924'),
	'identifierSchemeCodeUnspsc190501.25101925': __('identifierSchemeCodeUnspsc190501.25101925'),
	'identifierSchemeCodeUnspsc190501.25101926': __('identifierSchemeCodeUnspsc190501.25101926'),
	'identifierSchemeCodeUnspsc190501.25101927': __('identifierSchemeCodeUnspsc190501.25101927'),
	'identifierSchemeCodeUnspsc190501.25101928': __('identifierSchemeCodeUnspsc190501.25101928'),
	'identifierSchemeCodeUnspsc190501.25101929': __('identifierSchemeCodeUnspsc190501.25101929'),
	'identifierSchemeCodeUnspsc190501.25101930': __('identifierSchemeCodeUnspsc190501.25101930'),
	'identifierSchemeCodeUnspsc190501.25101931': __('identifierSchemeCodeUnspsc190501.25101931'),
	'identifierSchemeCodeUnspsc190501.25101932': __('identifierSchemeCodeUnspsc190501.25101932'),
	'identifierSchemeCodeUnspsc190501.25101933': __('identifierSchemeCodeUnspsc190501.25101933'),
	'identifierSchemeCodeUnspsc190501.25101934': __('identifierSchemeCodeUnspsc190501.25101934'),
	'identifierSchemeCodeUnspsc190501.25101935': __('identifierSchemeCodeUnspsc190501.25101935'),
	'identifierSchemeCodeUnspsc190501.25101936': __('identifierSchemeCodeUnspsc190501.25101936'),
	'identifierSchemeCodeUnspsc190501.25101937': __('identifierSchemeCodeUnspsc190501.25101937'),
	'identifierSchemeCodeUnspsc190501.25101938': __('identifierSchemeCodeUnspsc190501.25101938'),
	'identifierSchemeCodeUnspsc190501.25101939': __('identifierSchemeCodeUnspsc190501.25101939'),
	'identifierSchemeCodeUnspsc190501.25101940': __('identifierSchemeCodeUnspsc190501.25101940'),
	'identifierSchemeCodeUnspsc190501.25101941': __('identifierSchemeCodeUnspsc190501.25101941'),
	'identifierSchemeCodeUnspsc190501.25102000': __('identifierSchemeCodeUnspsc190501.25102000'),
	'identifierSchemeCodeUnspsc190501.25102001': __('identifierSchemeCodeUnspsc190501.25102001'),
	'identifierSchemeCodeUnspsc190501.25102002': __('identifierSchemeCodeUnspsc190501.25102002'),
	'identifierSchemeCodeUnspsc190501.25102003': __('identifierSchemeCodeUnspsc190501.25102003'),
	'identifierSchemeCodeUnspsc190501.25102100': __('identifierSchemeCodeUnspsc190501.25102100'),
	'identifierSchemeCodeUnspsc190501.25102101': __('identifierSchemeCodeUnspsc190501.25102101'),
	'identifierSchemeCodeUnspsc190501.25102102': __('identifierSchemeCodeUnspsc190501.25102102'),
	'identifierSchemeCodeUnspsc190501.25102103': __('identifierSchemeCodeUnspsc190501.25102103'),
	'identifierSchemeCodeUnspsc190501.25102104': __('identifierSchemeCodeUnspsc190501.25102104'),
	'identifierSchemeCodeUnspsc190501.25102105': __('identifierSchemeCodeUnspsc190501.25102105'),
	'identifierSchemeCodeUnspsc190501.25102106': __('identifierSchemeCodeUnspsc190501.25102106'),
	'identifierSchemeCodeUnspsc190501.25110000': __('identifierSchemeCodeUnspsc190501.25110000'),
	'identifierSchemeCodeUnspsc190501.25111500': __('identifierSchemeCodeUnspsc190501.25111500'),
	'identifierSchemeCodeUnspsc190501.25111501': __('identifierSchemeCodeUnspsc190501.25111501'),
	'identifierSchemeCodeUnspsc190501.25111502': __('identifierSchemeCodeUnspsc190501.25111502'),
	'identifierSchemeCodeUnspsc190501.25111503': __('identifierSchemeCodeUnspsc190501.25111503'),
	'identifierSchemeCodeUnspsc190501.25111504': __('identifierSchemeCodeUnspsc190501.25111504'),
	'identifierSchemeCodeUnspsc190501.25111505': __('identifierSchemeCodeUnspsc190501.25111505'),
	'identifierSchemeCodeUnspsc190501.25111506': __('identifierSchemeCodeUnspsc190501.25111506'),
	'identifierSchemeCodeUnspsc190501.25111507': __('identifierSchemeCodeUnspsc190501.25111507'),
	'identifierSchemeCodeUnspsc190501.25111508': __('identifierSchemeCodeUnspsc190501.25111508'),
	'identifierSchemeCodeUnspsc190501.25111509': __('identifierSchemeCodeUnspsc190501.25111509'),
	'identifierSchemeCodeUnspsc190501.25111510': __('identifierSchemeCodeUnspsc190501.25111510'),
	'identifierSchemeCodeUnspsc190501.25111511': __('identifierSchemeCodeUnspsc190501.25111511'),
	'identifierSchemeCodeUnspsc190501.25111512': __('identifierSchemeCodeUnspsc190501.25111512'),
	'identifierSchemeCodeUnspsc190501.25111513': __('identifierSchemeCodeUnspsc190501.25111513'),
	'identifierSchemeCodeUnspsc190501.25111514': __('identifierSchemeCodeUnspsc190501.25111514'),
	'identifierSchemeCodeUnspsc190501.25111515': __('identifierSchemeCodeUnspsc190501.25111515'),
	'identifierSchemeCodeUnspsc190501.25111516': __('identifierSchemeCodeUnspsc190501.25111516'),
	'identifierSchemeCodeUnspsc190501.25111517': __('identifierSchemeCodeUnspsc190501.25111517'),
	'identifierSchemeCodeUnspsc190501.25111518': __('identifierSchemeCodeUnspsc190501.25111518'),
	'identifierSchemeCodeUnspsc190501.25111519': __('identifierSchemeCodeUnspsc190501.25111519'),
	'identifierSchemeCodeUnspsc190501.25111520': __('identifierSchemeCodeUnspsc190501.25111520'),
	'identifierSchemeCodeUnspsc190501.25111521': __('identifierSchemeCodeUnspsc190501.25111521'),
	'identifierSchemeCodeUnspsc190501.25111522': __('identifierSchemeCodeUnspsc190501.25111522'),
	'identifierSchemeCodeUnspsc190501.25111523': __('identifierSchemeCodeUnspsc190501.25111523'),
	'identifierSchemeCodeUnspsc190501.25111524': __('identifierSchemeCodeUnspsc190501.25111524'),
	'identifierSchemeCodeUnspsc190501.25111525': __('identifierSchemeCodeUnspsc190501.25111525'),
	'identifierSchemeCodeUnspsc190501.25111526': __('identifierSchemeCodeUnspsc190501.25111526'),
	'identifierSchemeCodeUnspsc190501.25111527': __('identifierSchemeCodeUnspsc190501.25111527'),
	'identifierSchemeCodeUnspsc190501.25111528': __('identifierSchemeCodeUnspsc190501.25111528'),
	'identifierSchemeCodeUnspsc190501.25111529': __('identifierSchemeCodeUnspsc190501.25111529'),
	'identifierSchemeCodeUnspsc190501.25111530': __('identifierSchemeCodeUnspsc190501.25111530'),
	'identifierSchemeCodeUnspsc190501.25111531': __('identifierSchemeCodeUnspsc190501.25111531'),
	'identifierSchemeCodeUnspsc190501.25111532': __('identifierSchemeCodeUnspsc190501.25111532'),
	'identifierSchemeCodeUnspsc190501.25111533': __('identifierSchemeCodeUnspsc190501.25111533'),
	'identifierSchemeCodeUnspsc190501.25111534': __('identifierSchemeCodeUnspsc190501.25111534'),
	'identifierSchemeCodeUnspsc190501.25111535': __('identifierSchemeCodeUnspsc190501.25111535'),
	'identifierSchemeCodeUnspsc190501.25111600': __('identifierSchemeCodeUnspsc190501.25111600'),
	'identifierSchemeCodeUnspsc190501.25111601': __('identifierSchemeCodeUnspsc190501.25111601'),
	'identifierSchemeCodeUnspsc190501.25111602': __('identifierSchemeCodeUnspsc190501.25111602'),
	'identifierSchemeCodeUnspsc190501.25111603': __('identifierSchemeCodeUnspsc190501.25111603'),
	'identifierSchemeCodeUnspsc190501.25111700': __('identifierSchemeCodeUnspsc190501.25111700'),
	'identifierSchemeCodeUnspsc190501.25111701': __('identifierSchemeCodeUnspsc190501.25111701'),
	'identifierSchemeCodeUnspsc190501.25111702': __('identifierSchemeCodeUnspsc190501.25111702'),
	'identifierSchemeCodeUnspsc190501.25111703': __('identifierSchemeCodeUnspsc190501.25111703'),
	'identifierSchemeCodeUnspsc190501.25111704': __('identifierSchemeCodeUnspsc190501.25111704'),
	'identifierSchemeCodeUnspsc190501.25111705': __('identifierSchemeCodeUnspsc190501.25111705'),
	'identifierSchemeCodeUnspsc190501.25111706': __('identifierSchemeCodeUnspsc190501.25111706'),
	'identifierSchemeCodeUnspsc190501.25111707': __('identifierSchemeCodeUnspsc190501.25111707'),
	'identifierSchemeCodeUnspsc190501.25111708': __('identifierSchemeCodeUnspsc190501.25111708'),
	'identifierSchemeCodeUnspsc190501.25111709': __('identifierSchemeCodeUnspsc190501.25111709'),
	'identifierSchemeCodeUnspsc190501.25111710': __('identifierSchemeCodeUnspsc190501.25111710'),
	'identifierSchemeCodeUnspsc190501.25111711': __('identifierSchemeCodeUnspsc190501.25111711'),
	'identifierSchemeCodeUnspsc190501.25111712': __('identifierSchemeCodeUnspsc190501.25111712'),
	'identifierSchemeCodeUnspsc190501.25111713': __('identifierSchemeCodeUnspsc190501.25111713'),
	'identifierSchemeCodeUnspsc190501.25111714': __('identifierSchemeCodeUnspsc190501.25111714'),
	'identifierSchemeCodeUnspsc190501.25111715': __('identifierSchemeCodeUnspsc190501.25111715'),
	'identifierSchemeCodeUnspsc190501.25111716': __('identifierSchemeCodeUnspsc190501.25111716'),
	'identifierSchemeCodeUnspsc190501.25111717': __('identifierSchemeCodeUnspsc190501.25111717'),
	'identifierSchemeCodeUnspsc190501.25111718': __('identifierSchemeCodeUnspsc190501.25111718'),
	'identifierSchemeCodeUnspsc190501.25111719': __('identifierSchemeCodeUnspsc190501.25111719'),
	'identifierSchemeCodeUnspsc190501.25111720': __('identifierSchemeCodeUnspsc190501.25111720'),
	'identifierSchemeCodeUnspsc190501.25111721': __('identifierSchemeCodeUnspsc190501.25111721'),
	'identifierSchemeCodeUnspsc190501.25111722': __('identifierSchemeCodeUnspsc190501.25111722'),
	'identifierSchemeCodeUnspsc190501.25111723': __('identifierSchemeCodeUnspsc190501.25111723'),
	'identifierSchemeCodeUnspsc190501.25111724': __('identifierSchemeCodeUnspsc190501.25111724'),
	'identifierSchemeCodeUnspsc190501.25111725': __('identifierSchemeCodeUnspsc190501.25111725'),
	'identifierSchemeCodeUnspsc190501.25111726': __('identifierSchemeCodeUnspsc190501.25111726'),
	'identifierSchemeCodeUnspsc190501.25111800': __('identifierSchemeCodeUnspsc190501.25111800'),
	'identifierSchemeCodeUnspsc190501.25111801': __('identifierSchemeCodeUnspsc190501.25111801'),
	'identifierSchemeCodeUnspsc190501.25111802': __('identifierSchemeCodeUnspsc190501.25111802'),
	'identifierSchemeCodeUnspsc190501.25111803': __('identifierSchemeCodeUnspsc190501.25111803'),
	'identifierSchemeCodeUnspsc190501.25111804': __('identifierSchemeCodeUnspsc190501.25111804'),
	'identifierSchemeCodeUnspsc190501.25111805': __('identifierSchemeCodeUnspsc190501.25111805'),
	'identifierSchemeCodeUnspsc190501.25111806': __('identifierSchemeCodeUnspsc190501.25111806'),
	'identifierSchemeCodeUnspsc190501.25111807': __('identifierSchemeCodeUnspsc190501.25111807'),
	'identifierSchemeCodeUnspsc190501.25111808': __('identifierSchemeCodeUnspsc190501.25111808'),
	'identifierSchemeCodeUnspsc190501.25111809': __('identifierSchemeCodeUnspsc190501.25111809'),
	'identifierSchemeCodeUnspsc190501.25111900': __('identifierSchemeCodeUnspsc190501.25111900'),
	'identifierSchemeCodeUnspsc190501.25111901': __('identifierSchemeCodeUnspsc190501.25111901'),
	'identifierSchemeCodeUnspsc190501.25111902': __('identifierSchemeCodeUnspsc190501.25111902'),
	'identifierSchemeCodeUnspsc190501.25111903': __('identifierSchemeCodeUnspsc190501.25111903'),
	'identifierSchemeCodeUnspsc190501.25111904': __('identifierSchemeCodeUnspsc190501.25111904'),
	'identifierSchemeCodeUnspsc190501.25111905': __('identifierSchemeCodeUnspsc190501.25111905'),
	'identifierSchemeCodeUnspsc190501.25111906': __('identifierSchemeCodeUnspsc190501.25111906'),
	'identifierSchemeCodeUnspsc190501.25111907': __('identifierSchemeCodeUnspsc190501.25111907'),
	'identifierSchemeCodeUnspsc190501.25111908': __('identifierSchemeCodeUnspsc190501.25111908'),
	'identifierSchemeCodeUnspsc190501.25111909': __('identifierSchemeCodeUnspsc190501.25111909'),
	'identifierSchemeCodeUnspsc190501.25111910': __('identifierSchemeCodeUnspsc190501.25111910'),
	'identifierSchemeCodeUnspsc190501.25111911': __('identifierSchemeCodeUnspsc190501.25111911'),
	'identifierSchemeCodeUnspsc190501.25111912': __('identifierSchemeCodeUnspsc190501.25111912'),
	'identifierSchemeCodeUnspsc190501.25111913': __('identifierSchemeCodeUnspsc190501.25111913'),
	'identifierSchemeCodeUnspsc190501.25111914': __('identifierSchemeCodeUnspsc190501.25111914'),
	'identifierSchemeCodeUnspsc190501.25111915': __('identifierSchemeCodeUnspsc190501.25111915'),
	'identifierSchemeCodeUnspsc190501.25111916': __('identifierSchemeCodeUnspsc190501.25111916'),
	'identifierSchemeCodeUnspsc190501.25111917': __('identifierSchemeCodeUnspsc190501.25111917'),
	'identifierSchemeCodeUnspsc190501.25111918': __('identifierSchemeCodeUnspsc190501.25111918'),
	'identifierSchemeCodeUnspsc190501.25111919': __('identifierSchemeCodeUnspsc190501.25111919'),
	'identifierSchemeCodeUnspsc190501.25111920': __('identifierSchemeCodeUnspsc190501.25111920'),
	'identifierSchemeCodeUnspsc190501.25111921': __('identifierSchemeCodeUnspsc190501.25111921'),
	'identifierSchemeCodeUnspsc190501.25111922': __('identifierSchemeCodeUnspsc190501.25111922'),
	'identifierSchemeCodeUnspsc190501.25111923': __('identifierSchemeCodeUnspsc190501.25111923'),
	'identifierSchemeCodeUnspsc190501.25111924': __('identifierSchemeCodeUnspsc190501.25111924'),
	'identifierSchemeCodeUnspsc190501.25111925': __('identifierSchemeCodeUnspsc190501.25111925'),
	'identifierSchemeCodeUnspsc190501.25111926': __('identifierSchemeCodeUnspsc190501.25111926'),
	'identifierSchemeCodeUnspsc190501.25111927': __('identifierSchemeCodeUnspsc190501.25111927'),
	'identifierSchemeCodeUnspsc190501.25111928': __('identifierSchemeCodeUnspsc190501.25111928'),
	'identifierSchemeCodeUnspsc190501.25111929': __('identifierSchemeCodeUnspsc190501.25111929'),
	'identifierSchemeCodeUnspsc190501.25111930': __('identifierSchemeCodeUnspsc190501.25111930'),
	'identifierSchemeCodeUnspsc190501.25111931': __('identifierSchemeCodeUnspsc190501.25111931'),
	'identifierSchemeCodeUnspsc190501.25111932': __('identifierSchemeCodeUnspsc190501.25111932'),
	'identifierSchemeCodeUnspsc190501.25111933': __('identifierSchemeCodeUnspsc190501.25111933'),
	'identifierSchemeCodeUnspsc190501.25111934': __('identifierSchemeCodeUnspsc190501.25111934'),
	'identifierSchemeCodeUnspsc190501.25111935': __('identifierSchemeCodeUnspsc190501.25111935'),
	'identifierSchemeCodeUnspsc190501.25111936': __('identifierSchemeCodeUnspsc190501.25111936'),
	'identifierSchemeCodeUnspsc190501.25111937': __('identifierSchemeCodeUnspsc190501.25111937'),
	'identifierSchemeCodeUnspsc190501.25111938': __('identifierSchemeCodeUnspsc190501.25111938'),
	'identifierSchemeCodeUnspsc190501.25111939': __('identifierSchemeCodeUnspsc190501.25111939'),
	'identifierSchemeCodeUnspsc190501.25111940': __('identifierSchemeCodeUnspsc190501.25111940'),
	'identifierSchemeCodeUnspsc190501.25120000': __('identifierSchemeCodeUnspsc190501.25120000'),
	'identifierSchemeCodeUnspsc190501.25121500': __('identifierSchemeCodeUnspsc190501.25121500'),
	'identifierSchemeCodeUnspsc190501.25121501': __('identifierSchemeCodeUnspsc190501.25121501'),
	'identifierSchemeCodeUnspsc190501.25121502': __('identifierSchemeCodeUnspsc190501.25121502'),
	'identifierSchemeCodeUnspsc190501.25121503': __('identifierSchemeCodeUnspsc190501.25121503'),
	'identifierSchemeCodeUnspsc190501.25121504': __('identifierSchemeCodeUnspsc190501.25121504'),
	'identifierSchemeCodeUnspsc190501.25121505': __('identifierSchemeCodeUnspsc190501.25121505'),
	'identifierSchemeCodeUnspsc190501.25121600': __('identifierSchemeCodeUnspsc190501.25121600'),
	'identifierSchemeCodeUnspsc190501.25121601': __('identifierSchemeCodeUnspsc190501.25121601'),
	'identifierSchemeCodeUnspsc190501.25121602': __('identifierSchemeCodeUnspsc190501.25121602'),
	'identifierSchemeCodeUnspsc190501.25121603': __('identifierSchemeCodeUnspsc190501.25121603'),
	'identifierSchemeCodeUnspsc190501.25121604': __('identifierSchemeCodeUnspsc190501.25121604'),
	'identifierSchemeCodeUnspsc190501.25121605': __('identifierSchemeCodeUnspsc190501.25121605'),
	'identifierSchemeCodeUnspsc190501.25121606': __('identifierSchemeCodeUnspsc190501.25121606'),
	'identifierSchemeCodeUnspsc190501.25121607': __('identifierSchemeCodeUnspsc190501.25121607'),
	'identifierSchemeCodeUnspsc190501.25121700': __('identifierSchemeCodeUnspsc190501.25121700'),
	'identifierSchemeCodeUnspsc190501.25121701': __('identifierSchemeCodeUnspsc190501.25121701'),
	'identifierSchemeCodeUnspsc190501.25121702': __('identifierSchemeCodeUnspsc190501.25121702'),
	'identifierSchemeCodeUnspsc190501.25121703': __('identifierSchemeCodeUnspsc190501.25121703'),
	'identifierSchemeCodeUnspsc190501.25121704': __('identifierSchemeCodeUnspsc190501.25121704'),
	'identifierSchemeCodeUnspsc190501.25121705': __('identifierSchemeCodeUnspsc190501.25121705'),
	'identifierSchemeCodeUnspsc190501.25121706': __('identifierSchemeCodeUnspsc190501.25121706'),
	'identifierSchemeCodeUnspsc190501.25121707': __('identifierSchemeCodeUnspsc190501.25121707'),
	'identifierSchemeCodeUnspsc190501.25121708': __('identifierSchemeCodeUnspsc190501.25121708'),
	'identifierSchemeCodeUnspsc190501.25121709': __('identifierSchemeCodeUnspsc190501.25121709'),
	'identifierSchemeCodeUnspsc190501.25121710': __('identifierSchemeCodeUnspsc190501.25121710'),
	'identifierSchemeCodeUnspsc190501.25121711': __('identifierSchemeCodeUnspsc190501.25121711'),
	'identifierSchemeCodeUnspsc190501.25121712': __('identifierSchemeCodeUnspsc190501.25121712'),
	'identifierSchemeCodeUnspsc190501.25121713': __('identifierSchemeCodeUnspsc190501.25121713'),
	'identifierSchemeCodeUnspsc190501.25121714': __('identifierSchemeCodeUnspsc190501.25121714'),
	'identifierSchemeCodeUnspsc190501.25121715': __('identifierSchemeCodeUnspsc190501.25121715'),
	'identifierSchemeCodeUnspsc190501.25121716': __('identifierSchemeCodeUnspsc190501.25121716'),
	'identifierSchemeCodeUnspsc190501.25121717': __('identifierSchemeCodeUnspsc190501.25121717'),
	'identifierSchemeCodeUnspsc190501.25130000': __('identifierSchemeCodeUnspsc190501.25130000'),
	'identifierSchemeCodeUnspsc190501.25131500': __('identifierSchemeCodeUnspsc190501.25131500'),
	'identifierSchemeCodeUnspsc190501.25131501': __('identifierSchemeCodeUnspsc190501.25131501'),
	'identifierSchemeCodeUnspsc190501.25131502': __('identifierSchemeCodeUnspsc190501.25131502'),
	'identifierSchemeCodeUnspsc190501.25131503': __('identifierSchemeCodeUnspsc190501.25131503'),
	'identifierSchemeCodeUnspsc190501.25131504': __('identifierSchemeCodeUnspsc190501.25131504'),
	'identifierSchemeCodeUnspsc190501.25131505': __('identifierSchemeCodeUnspsc190501.25131505'),
	'identifierSchemeCodeUnspsc190501.25131506': __('identifierSchemeCodeUnspsc190501.25131506'),
	'identifierSchemeCodeUnspsc190501.25131507': __('identifierSchemeCodeUnspsc190501.25131507'),
	'identifierSchemeCodeUnspsc190501.25131508': __('identifierSchemeCodeUnspsc190501.25131508'),
	'identifierSchemeCodeUnspsc190501.25131509': __('identifierSchemeCodeUnspsc190501.25131509'),
	'identifierSchemeCodeUnspsc190501.25131600': __('identifierSchemeCodeUnspsc190501.25131600'),
	'identifierSchemeCodeUnspsc190501.25131601': __('identifierSchemeCodeUnspsc190501.25131601'),
	'identifierSchemeCodeUnspsc190501.25131602': __('identifierSchemeCodeUnspsc190501.25131602'),
	'identifierSchemeCodeUnspsc190501.25131603': __('identifierSchemeCodeUnspsc190501.25131603'),
	'identifierSchemeCodeUnspsc190501.25131604': __('identifierSchemeCodeUnspsc190501.25131604'),
	'identifierSchemeCodeUnspsc190501.25131605': __('identifierSchemeCodeUnspsc190501.25131605'),
	'identifierSchemeCodeUnspsc190501.25131700': __('identifierSchemeCodeUnspsc190501.25131700'),
	'identifierSchemeCodeUnspsc190501.25131701': __('identifierSchemeCodeUnspsc190501.25131701'),
	'identifierSchemeCodeUnspsc190501.25131702': __('identifierSchemeCodeUnspsc190501.25131702'),
	'identifierSchemeCodeUnspsc190501.25131703': __('identifierSchemeCodeUnspsc190501.25131703'),
	'identifierSchemeCodeUnspsc190501.25131704': __('identifierSchemeCodeUnspsc190501.25131704'),
	'identifierSchemeCodeUnspsc190501.25131705': __('identifierSchemeCodeUnspsc190501.25131705'),
	'identifierSchemeCodeUnspsc190501.25131706': __('identifierSchemeCodeUnspsc190501.25131706'),
	'identifierSchemeCodeUnspsc190501.25131707': __('identifierSchemeCodeUnspsc190501.25131707'),
	'identifierSchemeCodeUnspsc190501.25131708': __('identifierSchemeCodeUnspsc190501.25131708'),
	'identifierSchemeCodeUnspsc190501.25131709': __('identifierSchemeCodeUnspsc190501.25131709'),
	'identifierSchemeCodeUnspsc190501.25131800': __('identifierSchemeCodeUnspsc190501.25131800'),
	'identifierSchemeCodeUnspsc190501.25131801': __('identifierSchemeCodeUnspsc190501.25131801'),
	'identifierSchemeCodeUnspsc190501.25131802': __('identifierSchemeCodeUnspsc190501.25131802'),
	'identifierSchemeCodeUnspsc190501.25131900': __('identifierSchemeCodeUnspsc190501.25131900'),
	'identifierSchemeCodeUnspsc190501.25131902': __('identifierSchemeCodeUnspsc190501.25131902'),
	'identifierSchemeCodeUnspsc190501.25131903': __('identifierSchemeCodeUnspsc190501.25131903'),
	'identifierSchemeCodeUnspsc190501.25131904': __('identifierSchemeCodeUnspsc190501.25131904'),
	'identifierSchemeCodeUnspsc190501.25131905': __('identifierSchemeCodeUnspsc190501.25131905'),
	'identifierSchemeCodeUnspsc190501.25131906': __('identifierSchemeCodeUnspsc190501.25131906'),
	'identifierSchemeCodeUnspsc190501.25132000': __('identifierSchemeCodeUnspsc190501.25132000'),
	'identifierSchemeCodeUnspsc190501.25132001': __('identifierSchemeCodeUnspsc190501.25132001'),
	'identifierSchemeCodeUnspsc190501.25132002': __('identifierSchemeCodeUnspsc190501.25132002'),
	'identifierSchemeCodeUnspsc190501.25132003': __('identifierSchemeCodeUnspsc190501.25132003'),
	'identifierSchemeCodeUnspsc190501.25132004': __('identifierSchemeCodeUnspsc190501.25132004'),
	'identifierSchemeCodeUnspsc190501.25132005': __('identifierSchemeCodeUnspsc190501.25132005'),
	'identifierSchemeCodeUnspsc190501.25150000': __('identifierSchemeCodeUnspsc190501.25150000'),
	'identifierSchemeCodeUnspsc190501.25151500': __('identifierSchemeCodeUnspsc190501.25151500'),
	'identifierSchemeCodeUnspsc190501.25151501': __('identifierSchemeCodeUnspsc190501.25151501'),
	'identifierSchemeCodeUnspsc190501.25151502': __('identifierSchemeCodeUnspsc190501.25151502'),
	'identifierSchemeCodeUnspsc190501.25151700': __('identifierSchemeCodeUnspsc190501.25151700'),
	'identifierSchemeCodeUnspsc190501.25151701': __('identifierSchemeCodeUnspsc190501.25151701'),
	'identifierSchemeCodeUnspsc190501.25151702': __('identifierSchemeCodeUnspsc190501.25151702'),
	'identifierSchemeCodeUnspsc190501.25151703': __('identifierSchemeCodeUnspsc190501.25151703'),
	'identifierSchemeCodeUnspsc190501.25151704': __('identifierSchemeCodeUnspsc190501.25151704'),
	'identifierSchemeCodeUnspsc190501.25151705': __('identifierSchemeCodeUnspsc190501.25151705'),
	'identifierSchemeCodeUnspsc190501.25151706': __('identifierSchemeCodeUnspsc190501.25151706'),
	'identifierSchemeCodeUnspsc190501.25151707': __('identifierSchemeCodeUnspsc190501.25151707'),
	'identifierSchemeCodeUnspsc190501.25151708': __('identifierSchemeCodeUnspsc190501.25151708'),
	'identifierSchemeCodeUnspsc190501.25151709': __('identifierSchemeCodeUnspsc190501.25151709'),
	'identifierSchemeCodeUnspsc190501.25160000': __('identifierSchemeCodeUnspsc190501.25160000'),
	'identifierSchemeCodeUnspsc190501.25161500': __('identifierSchemeCodeUnspsc190501.25161500'),
	'identifierSchemeCodeUnspsc190501.25161501': __('identifierSchemeCodeUnspsc190501.25161501'),
	'identifierSchemeCodeUnspsc190501.25161502': __('identifierSchemeCodeUnspsc190501.25161502'),
	'identifierSchemeCodeUnspsc190501.25161503': __('identifierSchemeCodeUnspsc190501.25161503'),
	'identifierSchemeCodeUnspsc190501.25161504': __('identifierSchemeCodeUnspsc190501.25161504'),
	'identifierSchemeCodeUnspsc190501.25161505': __('identifierSchemeCodeUnspsc190501.25161505'),
	'identifierSchemeCodeUnspsc190501.25161506': __('identifierSchemeCodeUnspsc190501.25161506'),
	'identifierSchemeCodeUnspsc190501.25161507': __('identifierSchemeCodeUnspsc190501.25161507'),
	'identifierSchemeCodeUnspsc190501.25161508': __('identifierSchemeCodeUnspsc190501.25161508'),
	'identifierSchemeCodeUnspsc190501.25161509': __('identifierSchemeCodeUnspsc190501.25161509'),
	'identifierSchemeCodeUnspsc190501.25161510': __('identifierSchemeCodeUnspsc190501.25161510'),
	'identifierSchemeCodeUnspsc190501.25170000': __('identifierSchemeCodeUnspsc190501.25170000'),
	'identifierSchemeCodeUnspsc190501.25171500': __('identifierSchemeCodeUnspsc190501.25171500'),
	'identifierSchemeCodeUnspsc190501.25171502': __('identifierSchemeCodeUnspsc190501.25171502'),
	'identifierSchemeCodeUnspsc190501.25171503': __('identifierSchemeCodeUnspsc190501.25171503'),
	'identifierSchemeCodeUnspsc190501.25171504': __('identifierSchemeCodeUnspsc190501.25171504'),
	'identifierSchemeCodeUnspsc190501.25171505': __('identifierSchemeCodeUnspsc190501.25171505'),
	'identifierSchemeCodeUnspsc190501.25171506': __('identifierSchemeCodeUnspsc190501.25171506'),
	'identifierSchemeCodeUnspsc190501.25171507': __('identifierSchemeCodeUnspsc190501.25171507'),
	'identifierSchemeCodeUnspsc190501.25171508': __('identifierSchemeCodeUnspsc190501.25171508'),
	'identifierSchemeCodeUnspsc190501.25171509': __('identifierSchemeCodeUnspsc190501.25171509'),
	'identifierSchemeCodeUnspsc190501.25171600': __('identifierSchemeCodeUnspsc190501.25171600'),
	'identifierSchemeCodeUnspsc190501.25171602': __('identifierSchemeCodeUnspsc190501.25171602'),
	'identifierSchemeCodeUnspsc190501.25171603': __('identifierSchemeCodeUnspsc190501.25171603'),
	'identifierSchemeCodeUnspsc190501.25171604': __('identifierSchemeCodeUnspsc190501.25171604'),
	'identifierSchemeCodeUnspsc190501.25171700': __('identifierSchemeCodeUnspsc190501.25171700'),
	'identifierSchemeCodeUnspsc190501.25171702': __('identifierSchemeCodeUnspsc190501.25171702'),
	'identifierSchemeCodeUnspsc190501.25171703': __('identifierSchemeCodeUnspsc190501.25171703'),
	'identifierSchemeCodeUnspsc190501.25171704': __('identifierSchemeCodeUnspsc190501.25171704'),
	'identifierSchemeCodeUnspsc190501.25171705': __('identifierSchemeCodeUnspsc190501.25171705'),
	'identifierSchemeCodeUnspsc190501.25171706': __('identifierSchemeCodeUnspsc190501.25171706'),
	'identifierSchemeCodeUnspsc190501.25171707': __('identifierSchemeCodeUnspsc190501.25171707'),
	'identifierSchemeCodeUnspsc190501.25171708': __('identifierSchemeCodeUnspsc190501.25171708'),
	'identifierSchemeCodeUnspsc190501.25171709': __('identifierSchemeCodeUnspsc190501.25171709'),
	'identifierSchemeCodeUnspsc190501.25171710': __('identifierSchemeCodeUnspsc190501.25171710'),
	'identifierSchemeCodeUnspsc190501.25171711': __('identifierSchemeCodeUnspsc190501.25171711'),
	'identifierSchemeCodeUnspsc190501.25171712': __('identifierSchemeCodeUnspsc190501.25171712'),
	'identifierSchemeCodeUnspsc190501.25171713': __('identifierSchemeCodeUnspsc190501.25171713'),
	'identifierSchemeCodeUnspsc190501.25171714': __('identifierSchemeCodeUnspsc190501.25171714'),
	'identifierSchemeCodeUnspsc190501.25171715': __('identifierSchemeCodeUnspsc190501.25171715'),
	'identifierSchemeCodeUnspsc190501.25171716': __('identifierSchemeCodeUnspsc190501.25171716'),
	'identifierSchemeCodeUnspsc190501.25171717': __('identifierSchemeCodeUnspsc190501.25171717'),
	'identifierSchemeCodeUnspsc190501.25171718': __('identifierSchemeCodeUnspsc190501.25171718'),
	'identifierSchemeCodeUnspsc190501.25171719': __('identifierSchemeCodeUnspsc190501.25171719'),
	'identifierSchemeCodeUnspsc190501.25171720': __('identifierSchemeCodeUnspsc190501.25171720'),
	'identifierSchemeCodeUnspsc190501.25171721': __('identifierSchemeCodeUnspsc190501.25171721'),
	'identifierSchemeCodeUnspsc190501.25171722': __('identifierSchemeCodeUnspsc190501.25171722'),
	'identifierSchemeCodeUnspsc190501.25171723': __('identifierSchemeCodeUnspsc190501.25171723'),
	'identifierSchemeCodeUnspsc190501.25171724': __('identifierSchemeCodeUnspsc190501.25171724'),
	'identifierSchemeCodeUnspsc190501.25171725': __('identifierSchemeCodeUnspsc190501.25171725'),
	'identifierSchemeCodeUnspsc190501.25171726': __('identifierSchemeCodeUnspsc190501.25171726'),
	'identifierSchemeCodeUnspsc190501.25171900': __('identifierSchemeCodeUnspsc190501.25171900'),
	'identifierSchemeCodeUnspsc190501.25171901': __('identifierSchemeCodeUnspsc190501.25171901'),
	'identifierSchemeCodeUnspsc190501.25171902': __('identifierSchemeCodeUnspsc190501.25171902'),
	'identifierSchemeCodeUnspsc190501.25171903': __('identifierSchemeCodeUnspsc190501.25171903'),
	'identifierSchemeCodeUnspsc190501.25171905': __('identifierSchemeCodeUnspsc190501.25171905'),
	'identifierSchemeCodeUnspsc190501.25171906': __('identifierSchemeCodeUnspsc190501.25171906'),
	'identifierSchemeCodeUnspsc190501.25171907': __('identifierSchemeCodeUnspsc190501.25171907'),
	'identifierSchemeCodeUnspsc190501.25171908': __('identifierSchemeCodeUnspsc190501.25171908'),
	'identifierSchemeCodeUnspsc190501.25172000': __('identifierSchemeCodeUnspsc190501.25172000'),
	'identifierSchemeCodeUnspsc190501.25172001': __('identifierSchemeCodeUnspsc190501.25172001'),
	'identifierSchemeCodeUnspsc190501.25172002': __('identifierSchemeCodeUnspsc190501.25172002'),
	'identifierSchemeCodeUnspsc190501.25172003': __('identifierSchemeCodeUnspsc190501.25172003'),
	'identifierSchemeCodeUnspsc190501.25172004': __('identifierSchemeCodeUnspsc190501.25172004'),
	'identifierSchemeCodeUnspsc190501.25172005': __('identifierSchemeCodeUnspsc190501.25172005'),
	'identifierSchemeCodeUnspsc190501.25172007': __('identifierSchemeCodeUnspsc190501.25172007'),
	'identifierSchemeCodeUnspsc190501.25172009': __('identifierSchemeCodeUnspsc190501.25172009'),
	'identifierSchemeCodeUnspsc190501.25172010': __('identifierSchemeCodeUnspsc190501.25172010'),
	'identifierSchemeCodeUnspsc190501.25172011': __('identifierSchemeCodeUnspsc190501.25172011'),
	'identifierSchemeCodeUnspsc190501.25172012': __('identifierSchemeCodeUnspsc190501.25172012'),
	'identifierSchemeCodeUnspsc190501.25172013': __('identifierSchemeCodeUnspsc190501.25172013'),
	'identifierSchemeCodeUnspsc190501.25172014': __('identifierSchemeCodeUnspsc190501.25172014'),
	'identifierSchemeCodeUnspsc190501.25172015': __('identifierSchemeCodeUnspsc190501.25172015'),
	'identifierSchemeCodeUnspsc190501.25172100': __('identifierSchemeCodeUnspsc190501.25172100'),
	'identifierSchemeCodeUnspsc190501.25172101': __('identifierSchemeCodeUnspsc190501.25172101'),
	'identifierSchemeCodeUnspsc190501.25172104': __('identifierSchemeCodeUnspsc190501.25172104'),
	'identifierSchemeCodeUnspsc190501.25172105': __('identifierSchemeCodeUnspsc190501.25172105'),
	'identifierSchemeCodeUnspsc190501.25172106': __('identifierSchemeCodeUnspsc190501.25172106'),
	'identifierSchemeCodeUnspsc190501.25172108': __('identifierSchemeCodeUnspsc190501.25172108'),
	'identifierSchemeCodeUnspsc190501.25172109': __('identifierSchemeCodeUnspsc190501.25172109'),
	'identifierSchemeCodeUnspsc190501.25172110': __('identifierSchemeCodeUnspsc190501.25172110'),
	'identifierSchemeCodeUnspsc190501.25172111': __('identifierSchemeCodeUnspsc190501.25172111'),
	'identifierSchemeCodeUnspsc190501.25172112': __('identifierSchemeCodeUnspsc190501.25172112'),
	'identifierSchemeCodeUnspsc190501.25172113': __('identifierSchemeCodeUnspsc190501.25172113'),
	'identifierSchemeCodeUnspsc190501.25172114': __('identifierSchemeCodeUnspsc190501.25172114'),
	'identifierSchemeCodeUnspsc190501.25172115': __('identifierSchemeCodeUnspsc190501.25172115'),
	'identifierSchemeCodeUnspsc190501.25172116': __('identifierSchemeCodeUnspsc190501.25172116'),
	'identifierSchemeCodeUnspsc190501.25172117': __('identifierSchemeCodeUnspsc190501.25172117'),
	'identifierSchemeCodeUnspsc190501.25172118': __('identifierSchemeCodeUnspsc190501.25172118'),
	'identifierSchemeCodeUnspsc190501.25172119': __('identifierSchemeCodeUnspsc190501.25172119'),
	'identifierSchemeCodeUnspsc190501.25172120': __('identifierSchemeCodeUnspsc190501.25172120'),
	'identifierSchemeCodeUnspsc190501.25172121': __('identifierSchemeCodeUnspsc190501.25172121'),
	'identifierSchemeCodeUnspsc190501.25172122': __('identifierSchemeCodeUnspsc190501.25172122'),
	'identifierSchemeCodeUnspsc190501.25172123': __('identifierSchemeCodeUnspsc190501.25172123'),
	'identifierSchemeCodeUnspsc190501.25172124': __('identifierSchemeCodeUnspsc190501.25172124'),
	'identifierSchemeCodeUnspsc190501.25172125': __('identifierSchemeCodeUnspsc190501.25172125'),
	'identifierSchemeCodeUnspsc190501.25172126': __('identifierSchemeCodeUnspsc190501.25172126'),
	'identifierSchemeCodeUnspsc190501.25172127': __('identifierSchemeCodeUnspsc190501.25172127'),
	'identifierSchemeCodeUnspsc190501.25172128': __('identifierSchemeCodeUnspsc190501.25172128'),
	'identifierSchemeCodeUnspsc190501.25172129': __('identifierSchemeCodeUnspsc190501.25172129'),
	'identifierSchemeCodeUnspsc190501.25172130': __('identifierSchemeCodeUnspsc190501.25172130'),
	'identifierSchemeCodeUnspsc190501.25172131': __('identifierSchemeCodeUnspsc190501.25172131'),
	'identifierSchemeCodeUnspsc190501.25172200': __('identifierSchemeCodeUnspsc190501.25172200'),
	'identifierSchemeCodeUnspsc190501.25172201': __('identifierSchemeCodeUnspsc190501.25172201'),
	'identifierSchemeCodeUnspsc190501.25172203': __('identifierSchemeCodeUnspsc190501.25172203'),
	'identifierSchemeCodeUnspsc190501.25172204': __('identifierSchemeCodeUnspsc190501.25172204'),
	'identifierSchemeCodeUnspsc190501.25172205': __('identifierSchemeCodeUnspsc190501.25172205'),
	'identifierSchemeCodeUnspsc190501.25172206': __('identifierSchemeCodeUnspsc190501.25172206'),
	'identifierSchemeCodeUnspsc190501.25172300': __('identifierSchemeCodeUnspsc190501.25172300'),
	'identifierSchemeCodeUnspsc190501.25172301': __('identifierSchemeCodeUnspsc190501.25172301'),
	'identifierSchemeCodeUnspsc190501.25172303': __('identifierSchemeCodeUnspsc190501.25172303'),
	'identifierSchemeCodeUnspsc190501.25172304': __('identifierSchemeCodeUnspsc190501.25172304'),
	'identifierSchemeCodeUnspsc190501.25172305': __('identifierSchemeCodeUnspsc190501.25172305'),
	'identifierSchemeCodeUnspsc190501.25172400': __('identifierSchemeCodeUnspsc190501.25172400'),
	'identifierSchemeCodeUnspsc190501.25172404': __('identifierSchemeCodeUnspsc190501.25172404'),
	'identifierSchemeCodeUnspsc190501.25172405': __('identifierSchemeCodeUnspsc190501.25172405'),
	'identifierSchemeCodeUnspsc190501.25172406': __('identifierSchemeCodeUnspsc190501.25172406'),
	'identifierSchemeCodeUnspsc190501.25172407': __('identifierSchemeCodeUnspsc190501.25172407'),
	'identifierSchemeCodeUnspsc190501.25172408': __('identifierSchemeCodeUnspsc190501.25172408'),
	'identifierSchemeCodeUnspsc190501.25172409': __('identifierSchemeCodeUnspsc190501.25172409'),
	'identifierSchemeCodeUnspsc190501.25172410': __('identifierSchemeCodeUnspsc190501.25172410'),
	'identifierSchemeCodeUnspsc190501.25172411': __('identifierSchemeCodeUnspsc190501.25172411'),
	'identifierSchemeCodeUnspsc190501.25172500': __('identifierSchemeCodeUnspsc190501.25172500'),
	'identifierSchemeCodeUnspsc190501.25172502': __('identifierSchemeCodeUnspsc190501.25172502'),
	'identifierSchemeCodeUnspsc190501.25172503': __('identifierSchemeCodeUnspsc190501.25172503'),
	'identifierSchemeCodeUnspsc190501.25172504': __('identifierSchemeCodeUnspsc190501.25172504'),
	'identifierSchemeCodeUnspsc190501.25172505': __('identifierSchemeCodeUnspsc190501.25172505'),
	'identifierSchemeCodeUnspsc190501.25172506': __('identifierSchemeCodeUnspsc190501.25172506'),
	'identifierSchemeCodeUnspsc190501.25172507': __('identifierSchemeCodeUnspsc190501.25172507'),
	'identifierSchemeCodeUnspsc190501.25172508': __('identifierSchemeCodeUnspsc190501.25172508'),
	'identifierSchemeCodeUnspsc190501.25172509': __('identifierSchemeCodeUnspsc190501.25172509'),
	'identifierSchemeCodeUnspsc190501.25172510': __('identifierSchemeCodeUnspsc190501.25172510'),
	'identifierSchemeCodeUnspsc190501.25172511': __('identifierSchemeCodeUnspsc190501.25172511'),
	'identifierSchemeCodeUnspsc190501.25172512': __('identifierSchemeCodeUnspsc190501.25172512'),
	'identifierSchemeCodeUnspsc190501.25172513': __('identifierSchemeCodeUnspsc190501.25172513'),
	'identifierSchemeCodeUnspsc190501.25172514': __('identifierSchemeCodeUnspsc190501.25172514'),
	'identifierSchemeCodeUnspsc190501.25172600': __('identifierSchemeCodeUnspsc190501.25172600'),
	'identifierSchemeCodeUnspsc190501.25172601': __('identifierSchemeCodeUnspsc190501.25172601'),
	'identifierSchemeCodeUnspsc190501.25172602': __('identifierSchemeCodeUnspsc190501.25172602'),
	'identifierSchemeCodeUnspsc190501.25172603': __('identifierSchemeCodeUnspsc190501.25172603'),
	'identifierSchemeCodeUnspsc190501.25172604': __('identifierSchemeCodeUnspsc190501.25172604'),
	'identifierSchemeCodeUnspsc190501.25172605': __('identifierSchemeCodeUnspsc190501.25172605'),
	'identifierSchemeCodeUnspsc190501.25172606': __('identifierSchemeCodeUnspsc190501.25172606'),
	'identifierSchemeCodeUnspsc190501.25172607': __('identifierSchemeCodeUnspsc190501.25172607'),
	'identifierSchemeCodeUnspsc190501.25172608': __('identifierSchemeCodeUnspsc190501.25172608'),
	'identifierSchemeCodeUnspsc190501.25172609': __('identifierSchemeCodeUnspsc190501.25172609'),
	'identifierSchemeCodeUnspsc190501.25172610': __('identifierSchemeCodeUnspsc190501.25172610'),
	'identifierSchemeCodeUnspsc190501.25172611': __('identifierSchemeCodeUnspsc190501.25172611'),
	'identifierSchemeCodeUnspsc190501.25172612': __('identifierSchemeCodeUnspsc190501.25172612'),
	'identifierSchemeCodeUnspsc190501.25172613': __('identifierSchemeCodeUnspsc190501.25172613'),
	'identifierSchemeCodeUnspsc190501.25172700': __('identifierSchemeCodeUnspsc190501.25172700'),
	'identifierSchemeCodeUnspsc190501.25172702': __('identifierSchemeCodeUnspsc190501.25172702'),
	'identifierSchemeCodeUnspsc190501.25172703': __('identifierSchemeCodeUnspsc190501.25172703'),
	'identifierSchemeCodeUnspsc190501.25172704': __('identifierSchemeCodeUnspsc190501.25172704'),
	'identifierSchemeCodeUnspsc190501.25172705': __('identifierSchemeCodeUnspsc190501.25172705'),
	'identifierSchemeCodeUnspsc190501.25172706': __('identifierSchemeCodeUnspsc190501.25172706'),
	'identifierSchemeCodeUnspsc190501.25172707': __('identifierSchemeCodeUnspsc190501.25172707'),
	'identifierSchemeCodeUnspsc190501.25172708': __('identifierSchemeCodeUnspsc190501.25172708'),
	'identifierSchemeCodeUnspsc190501.25172709': __('identifierSchemeCodeUnspsc190501.25172709'),
	'identifierSchemeCodeUnspsc190501.25172710': __('identifierSchemeCodeUnspsc190501.25172710'),
	'identifierSchemeCodeUnspsc190501.25172711': __('identifierSchemeCodeUnspsc190501.25172711'),
	'identifierSchemeCodeUnspsc190501.25172800': __('identifierSchemeCodeUnspsc190501.25172800'),
	'identifierSchemeCodeUnspsc190501.25172802': __('identifierSchemeCodeUnspsc190501.25172802'),
	'identifierSchemeCodeUnspsc190501.25172803': __('identifierSchemeCodeUnspsc190501.25172803'),
	'identifierSchemeCodeUnspsc190501.25172900': __('identifierSchemeCodeUnspsc190501.25172900'),
	'identifierSchemeCodeUnspsc190501.25172901': __('identifierSchemeCodeUnspsc190501.25172901'),
	'identifierSchemeCodeUnspsc190501.25172903': __('identifierSchemeCodeUnspsc190501.25172903'),
	'identifierSchemeCodeUnspsc190501.25172904': __('identifierSchemeCodeUnspsc190501.25172904'),
	'identifierSchemeCodeUnspsc190501.25172905': __('identifierSchemeCodeUnspsc190501.25172905'),
	'identifierSchemeCodeUnspsc190501.25172906': __('identifierSchemeCodeUnspsc190501.25172906'),
	'identifierSchemeCodeUnspsc190501.25172907': __('identifierSchemeCodeUnspsc190501.25172907'),
	'identifierSchemeCodeUnspsc190501.25172908': __('identifierSchemeCodeUnspsc190501.25172908'),
	'identifierSchemeCodeUnspsc190501.25172909': __('identifierSchemeCodeUnspsc190501.25172909'),
	'identifierSchemeCodeUnspsc190501.25173000': __('identifierSchemeCodeUnspsc190501.25173000'),
	'identifierSchemeCodeUnspsc190501.25173001': __('identifierSchemeCodeUnspsc190501.25173001'),
	'identifierSchemeCodeUnspsc190501.25173003': __('identifierSchemeCodeUnspsc190501.25173003'),
	'identifierSchemeCodeUnspsc190501.25173004': __('identifierSchemeCodeUnspsc190501.25173004'),
	'identifierSchemeCodeUnspsc190501.25173005': __('identifierSchemeCodeUnspsc190501.25173005'),
	'identifierSchemeCodeUnspsc190501.25173006': __('identifierSchemeCodeUnspsc190501.25173006'),
	'identifierSchemeCodeUnspsc190501.25173007': __('identifierSchemeCodeUnspsc190501.25173007'),
	'identifierSchemeCodeUnspsc190501.25173008': __('identifierSchemeCodeUnspsc190501.25173008'),
	'identifierSchemeCodeUnspsc190501.25173100': __('identifierSchemeCodeUnspsc190501.25173100'),
	'identifierSchemeCodeUnspsc190501.25173107': __('identifierSchemeCodeUnspsc190501.25173107'),
	'identifierSchemeCodeUnspsc190501.25173108': __('identifierSchemeCodeUnspsc190501.25173108'),
	'identifierSchemeCodeUnspsc190501.25173300': __('identifierSchemeCodeUnspsc190501.25173300'),
	'identifierSchemeCodeUnspsc190501.25173303': __('identifierSchemeCodeUnspsc190501.25173303'),
	'identifierSchemeCodeUnspsc190501.25173304': __('identifierSchemeCodeUnspsc190501.25173304'),
	'identifierSchemeCodeUnspsc190501.25173700': __('identifierSchemeCodeUnspsc190501.25173700'),
	'identifierSchemeCodeUnspsc190501.25173701': __('identifierSchemeCodeUnspsc190501.25173701'),
	'identifierSchemeCodeUnspsc190501.25173702': __('identifierSchemeCodeUnspsc190501.25173702'),
	'identifierSchemeCodeUnspsc190501.25173703': __('identifierSchemeCodeUnspsc190501.25173703'),
	'identifierSchemeCodeUnspsc190501.25173704': __('identifierSchemeCodeUnspsc190501.25173704'),
	'identifierSchemeCodeUnspsc190501.25173705': __('identifierSchemeCodeUnspsc190501.25173705'),
	'identifierSchemeCodeUnspsc190501.25173706': __('identifierSchemeCodeUnspsc190501.25173706'),
	'identifierSchemeCodeUnspsc190501.25173707': __('identifierSchemeCodeUnspsc190501.25173707'),
	'identifierSchemeCodeUnspsc190501.25173708': __('identifierSchemeCodeUnspsc190501.25173708'),
	'identifierSchemeCodeUnspsc190501.25173800': __('identifierSchemeCodeUnspsc190501.25173800'),
	'identifierSchemeCodeUnspsc190501.25173801': __('identifierSchemeCodeUnspsc190501.25173801'),
	'identifierSchemeCodeUnspsc190501.25173802': __('identifierSchemeCodeUnspsc190501.25173802'),
	'identifierSchemeCodeUnspsc190501.25173803': __('identifierSchemeCodeUnspsc190501.25173803'),
	'identifierSchemeCodeUnspsc190501.25173804': __('identifierSchemeCodeUnspsc190501.25173804'),
	'identifierSchemeCodeUnspsc190501.25173805': __('identifierSchemeCodeUnspsc190501.25173805'),
	'identifierSchemeCodeUnspsc190501.25173806': __('identifierSchemeCodeUnspsc190501.25173806'),
	'identifierSchemeCodeUnspsc190501.25173807': __('identifierSchemeCodeUnspsc190501.25173807'),
	'identifierSchemeCodeUnspsc190501.25173808': __('identifierSchemeCodeUnspsc190501.25173808'),
	'identifierSchemeCodeUnspsc190501.25173809': __('identifierSchemeCodeUnspsc190501.25173809'),
	'identifierSchemeCodeUnspsc190501.25173810': __('identifierSchemeCodeUnspsc190501.25173810'),
	'identifierSchemeCodeUnspsc190501.25173811': __('identifierSchemeCodeUnspsc190501.25173811'),
	'identifierSchemeCodeUnspsc190501.25173812': __('identifierSchemeCodeUnspsc190501.25173812'),
	'identifierSchemeCodeUnspsc190501.25173813': __('identifierSchemeCodeUnspsc190501.25173813'),
	'identifierSchemeCodeUnspsc190501.25173815': __('identifierSchemeCodeUnspsc190501.25173815'),
	'identifierSchemeCodeUnspsc190501.25173816': __('identifierSchemeCodeUnspsc190501.25173816'),
	'identifierSchemeCodeUnspsc190501.25173817': __('identifierSchemeCodeUnspsc190501.25173817'),
	'identifierSchemeCodeUnspsc190501.25173818': __('identifierSchemeCodeUnspsc190501.25173818'),
	'identifierSchemeCodeUnspsc190501.25173819': __('identifierSchemeCodeUnspsc190501.25173819'),
	'identifierSchemeCodeUnspsc190501.25173820': __('identifierSchemeCodeUnspsc190501.25173820'),
	'identifierSchemeCodeUnspsc190501.25173821': __('identifierSchemeCodeUnspsc190501.25173821'),
	'identifierSchemeCodeUnspsc190501.25173822': __('identifierSchemeCodeUnspsc190501.25173822'),
	'identifierSchemeCodeUnspsc190501.25173900': __('identifierSchemeCodeUnspsc190501.25173900'),
	'identifierSchemeCodeUnspsc190501.25173901': __('identifierSchemeCodeUnspsc190501.25173901'),
	'identifierSchemeCodeUnspsc190501.25173902': __('identifierSchemeCodeUnspsc190501.25173902'),
	'identifierSchemeCodeUnspsc190501.25173903': __('identifierSchemeCodeUnspsc190501.25173903'),
	'identifierSchemeCodeUnspsc190501.25173904': __('identifierSchemeCodeUnspsc190501.25173904'),
	'identifierSchemeCodeUnspsc190501.25174000': __('identifierSchemeCodeUnspsc190501.25174000'),
	'identifierSchemeCodeUnspsc190501.25174001': __('identifierSchemeCodeUnspsc190501.25174001'),
	'identifierSchemeCodeUnspsc190501.25174002': __('identifierSchemeCodeUnspsc190501.25174002'),
	'identifierSchemeCodeUnspsc190501.25174003': __('identifierSchemeCodeUnspsc190501.25174003'),
	'identifierSchemeCodeUnspsc190501.25174004': __('identifierSchemeCodeUnspsc190501.25174004'),
	'identifierSchemeCodeUnspsc190501.25174005': __('identifierSchemeCodeUnspsc190501.25174005'),
	'identifierSchemeCodeUnspsc190501.25174006': __('identifierSchemeCodeUnspsc190501.25174006'),
	'identifierSchemeCodeUnspsc190501.25174007': __('identifierSchemeCodeUnspsc190501.25174007'),
	'identifierSchemeCodeUnspsc190501.25174008': __('identifierSchemeCodeUnspsc190501.25174008'),
	'identifierSchemeCodeUnspsc190501.25174100': __('identifierSchemeCodeUnspsc190501.25174100'),
	'identifierSchemeCodeUnspsc190501.25174101': __('identifierSchemeCodeUnspsc190501.25174101'),
	'identifierSchemeCodeUnspsc190501.25174102': __('identifierSchemeCodeUnspsc190501.25174102'),
	'identifierSchemeCodeUnspsc190501.25174103': __('identifierSchemeCodeUnspsc190501.25174103'),
	'identifierSchemeCodeUnspsc190501.25174104': __('identifierSchemeCodeUnspsc190501.25174104'),
	'identifierSchemeCodeUnspsc190501.25174105': __('identifierSchemeCodeUnspsc190501.25174105'),
	'identifierSchemeCodeUnspsc190501.25174106': __('identifierSchemeCodeUnspsc190501.25174106'),
	'identifierSchemeCodeUnspsc190501.25174107': __('identifierSchemeCodeUnspsc190501.25174107'),
	'identifierSchemeCodeUnspsc190501.25174200': __('identifierSchemeCodeUnspsc190501.25174200'),
	'identifierSchemeCodeUnspsc190501.25174201': __('identifierSchemeCodeUnspsc190501.25174201'),
	'identifierSchemeCodeUnspsc190501.25174202': __('identifierSchemeCodeUnspsc190501.25174202'),
	'identifierSchemeCodeUnspsc190501.25174203': __('identifierSchemeCodeUnspsc190501.25174203'),
	'identifierSchemeCodeUnspsc190501.25174204': __('identifierSchemeCodeUnspsc190501.25174204'),
	'identifierSchemeCodeUnspsc190501.25174205': __('identifierSchemeCodeUnspsc190501.25174205'),
	'identifierSchemeCodeUnspsc190501.25174206': __('identifierSchemeCodeUnspsc190501.25174206'),
	'identifierSchemeCodeUnspsc190501.25174207': __('identifierSchemeCodeUnspsc190501.25174207'),
	'identifierSchemeCodeUnspsc190501.25174208': __('identifierSchemeCodeUnspsc190501.25174208'),
	'identifierSchemeCodeUnspsc190501.25174209': __('identifierSchemeCodeUnspsc190501.25174209'),
	'identifierSchemeCodeUnspsc190501.25174210': __('identifierSchemeCodeUnspsc190501.25174210'),
	'identifierSchemeCodeUnspsc190501.25174211': __('identifierSchemeCodeUnspsc190501.25174211'),
	'identifierSchemeCodeUnspsc190501.25174212': __('identifierSchemeCodeUnspsc190501.25174212'),
	'identifierSchemeCodeUnspsc190501.25174213': __('identifierSchemeCodeUnspsc190501.25174213'),
	'identifierSchemeCodeUnspsc190501.25174214': __('identifierSchemeCodeUnspsc190501.25174214'),
	'identifierSchemeCodeUnspsc190501.25174215': __('identifierSchemeCodeUnspsc190501.25174215'),
	'identifierSchemeCodeUnspsc190501.25174216': __('identifierSchemeCodeUnspsc190501.25174216'),
	'identifierSchemeCodeUnspsc190501.25174217': __('identifierSchemeCodeUnspsc190501.25174217'),
	'identifierSchemeCodeUnspsc190501.25174218': __('identifierSchemeCodeUnspsc190501.25174218'),
	'identifierSchemeCodeUnspsc190501.25174400': __('identifierSchemeCodeUnspsc190501.25174400'),
	'identifierSchemeCodeUnspsc190501.25174401': __('identifierSchemeCodeUnspsc190501.25174401'),
	'identifierSchemeCodeUnspsc190501.25174402': __('identifierSchemeCodeUnspsc190501.25174402'),
	'identifierSchemeCodeUnspsc190501.25174403': __('identifierSchemeCodeUnspsc190501.25174403'),
	'identifierSchemeCodeUnspsc190501.25174404': __('identifierSchemeCodeUnspsc190501.25174404'),
	'identifierSchemeCodeUnspsc190501.25174405': __('identifierSchemeCodeUnspsc190501.25174405'),
	'identifierSchemeCodeUnspsc190501.25174406': __('identifierSchemeCodeUnspsc190501.25174406'),
	'identifierSchemeCodeUnspsc190501.25174407': __('identifierSchemeCodeUnspsc190501.25174407'),
	'identifierSchemeCodeUnspsc190501.25174408': __('identifierSchemeCodeUnspsc190501.25174408'),
	'identifierSchemeCodeUnspsc190501.25174409': __('identifierSchemeCodeUnspsc190501.25174409'),
	'identifierSchemeCodeUnspsc190501.25174410': __('identifierSchemeCodeUnspsc190501.25174410'),
	'identifierSchemeCodeUnspsc190501.25174411': __('identifierSchemeCodeUnspsc190501.25174411'),
	'identifierSchemeCodeUnspsc190501.25174412': __('identifierSchemeCodeUnspsc190501.25174412'),
	'identifierSchemeCodeUnspsc190501.25174413': __('identifierSchemeCodeUnspsc190501.25174413'),
	'identifierSchemeCodeUnspsc190501.25174414': __('identifierSchemeCodeUnspsc190501.25174414'),
	'identifierSchemeCodeUnspsc190501.25174415': __('identifierSchemeCodeUnspsc190501.25174415'),
	'identifierSchemeCodeUnspsc190501.25174416': __('identifierSchemeCodeUnspsc190501.25174416'),
	'identifierSchemeCodeUnspsc190501.25174417': __('identifierSchemeCodeUnspsc190501.25174417'),
	'identifierSchemeCodeUnspsc190501.25174418': __('identifierSchemeCodeUnspsc190501.25174418'),
	'identifierSchemeCodeUnspsc190501.25174419': __('identifierSchemeCodeUnspsc190501.25174419'),
	'identifierSchemeCodeUnspsc190501.25174420': __('identifierSchemeCodeUnspsc190501.25174420'),
	'identifierSchemeCodeUnspsc190501.25174421': __('identifierSchemeCodeUnspsc190501.25174421'),
	'identifierSchemeCodeUnspsc190501.25174600': __('identifierSchemeCodeUnspsc190501.25174600'),
	'identifierSchemeCodeUnspsc190501.25174601': __('identifierSchemeCodeUnspsc190501.25174601'),
	'identifierSchemeCodeUnspsc190501.25174602': __('identifierSchemeCodeUnspsc190501.25174602'),
	'identifierSchemeCodeUnspsc190501.25174603': __('identifierSchemeCodeUnspsc190501.25174603'),
	'identifierSchemeCodeUnspsc190501.25174604': __('identifierSchemeCodeUnspsc190501.25174604'),
	'identifierSchemeCodeUnspsc190501.25174605': __('identifierSchemeCodeUnspsc190501.25174605'),
	'identifierSchemeCodeUnspsc190501.25174700': __('identifierSchemeCodeUnspsc190501.25174700'),
	'identifierSchemeCodeUnspsc190501.25174701': __('identifierSchemeCodeUnspsc190501.25174701'),
	'identifierSchemeCodeUnspsc190501.25174702': __('identifierSchemeCodeUnspsc190501.25174702'),
	'identifierSchemeCodeUnspsc190501.25174703': __('identifierSchemeCodeUnspsc190501.25174703'),
	'identifierSchemeCodeUnspsc190501.25174704': __('identifierSchemeCodeUnspsc190501.25174704'),
	'identifierSchemeCodeUnspsc190501.25174705': __('identifierSchemeCodeUnspsc190501.25174705'),
	'identifierSchemeCodeUnspsc190501.25174800': __('identifierSchemeCodeUnspsc190501.25174800'),
	'identifierSchemeCodeUnspsc190501.25174801': __('identifierSchemeCodeUnspsc190501.25174801'),
	'identifierSchemeCodeUnspsc190501.25174802': __('identifierSchemeCodeUnspsc190501.25174802'),
	'identifierSchemeCodeUnspsc190501.25174803': __('identifierSchemeCodeUnspsc190501.25174803'),
	'identifierSchemeCodeUnspsc190501.25174804': __('identifierSchemeCodeUnspsc190501.25174804'),
	'identifierSchemeCodeUnspsc190501.25174805': __('identifierSchemeCodeUnspsc190501.25174805'),
	'identifierSchemeCodeUnspsc190501.25174806': __('identifierSchemeCodeUnspsc190501.25174806'),
	'identifierSchemeCodeUnspsc190501.25174807': __('identifierSchemeCodeUnspsc190501.25174807'),
	'identifierSchemeCodeUnspsc190501.25174808': __('identifierSchemeCodeUnspsc190501.25174808'),
	'identifierSchemeCodeUnspsc190501.25174809': __('identifierSchemeCodeUnspsc190501.25174809'),
	'identifierSchemeCodeUnspsc190501.25174810': __('identifierSchemeCodeUnspsc190501.25174810'),
	'identifierSchemeCodeUnspsc190501.25174811': __('identifierSchemeCodeUnspsc190501.25174811'),
	'identifierSchemeCodeUnspsc190501.25174900': __('identifierSchemeCodeUnspsc190501.25174900'),
	'identifierSchemeCodeUnspsc190501.25174901': __('identifierSchemeCodeUnspsc190501.25174901'),
	'identifierSchemeCodeUnspsc190501.25174902': __('identifierSchemeCodeUnspsc190501.25174902'),
	'identifierSchemeCodeUnspsc190501.25174903': __('identifierSchemeCodeUnspsc190501.25174903'),
	'identifierSchemeCodeUnspsc190501.25175000': __('identifierSchemeCodeUnspsc190501.25175000'),
	'identifierSchemeCodeUnspsc190501.25175001': __('identifierSchemeCodeUnspsc190501.25175001'),
	'identifierSchemeCodeUnspsc190501.25175002': __('identifierSchemeCodeUnspsc190501.25175002'),
	'identifierSchemeCodeUnspsc190501.25175003': __('identifierSchemeCodeUnspsc190501.25175003'),
	'identifierSchemeCodeUnspsc190501.25175100': __('identifierSchemeCodeUnspsc190501.25175100'),
	'identifierSchemeCodeUnspsc190501.25175101': __('identifierSchemeCodeUnspsc190501.25175101'),
	'identifierSchemeCodeUnspsc190501.25175102': __('identifierSchemeCodeUnspsc190501.25175102'),
	'identifierSchemeCodeUnspsc190501.25175103': __('identifierSchemeCodeUnspsc190501.25175103'),
	'identifierSchemeCodeUnspsc190501.25175104': __('identifierSchemeCodeUnspsc190501.25175104'),
	'identifierSchemeCodeUnspsc190501.25175105': __('identifierSchemeCodeUnspsc190501.25175105'),
	'identifierSchemeCodeUnspsc190501.25175106': __('identifierSchemeCodeUnspsc190501.25175106'),
	'identifierSchemeCodeUnspsc190501.25175107': __('identifierSchemeCodeUnspsc190501.25175107'),
	'identifierSchemeCodeUnspsc190501.25180000': __('identifierSchemeCodeUnspsc190501.25180000'),
	'identifierSchemeCodeUnspsc190501.25181600': __('identifierSchemeCodeUnspsc190501.25181600'),
	'identifierSchemeCodeUnspsc190501.25181601': __('identifierSchemeCodeUnspsc190501.25181601'),
	'identifierSchemeCodeUnspsc190501.25181602': __('identifierSchemeCodeUnspsc190501.25181602'),
	'identifierSchemeCodeUnspsc190501.25181603': __('identifierSchemeCodeUnspsc190501.25181603'),
	'identifierSchemeCodeUnspsc190501.25181604': __('identifierSchemeCodeUnspsc190501.25181604'),
	'identifierSchemeCodeUnspsc190501.25181605': __('identifierSchemeCodeUnspsc190501.25181605'),
	'identifierSchemeCodeUnspsc190501.25181606': __('identifierSchemeCodeUnspsc190501.25181606'),
	'identifierSchemeCodeUnspsc190501.25181607': __('identifierSchemeCodeUnspsc190501.25181607'),
	'identifierSchemeCodeUnspsc190501.25181608': __('identifierSchemeCodeUnspsc190501.25181608'),
	'identifierSchemeCodeUnspsc190501.25181609': __('identifierSchemeCodeUnspsc190501.25181609'),
	'identifierSchemeCodeUnspsc190501.25181610': __('identifierSchemeCodeUnspsc190501.25181610'),
	'identifierSchemeCodeUnspsc190501.25181611': __('identifierSchemeCodeUnspsc190501.25181611'),
	'identifierSchemeCodeUnspsc190501.25181612': __('identifierSchemeCodeUnspsc190501.25181612'),
	'identifierSchemeCodeUnspsc190501.25181613': __('identifierSchemeCodeUnspsc190501.25181613'),
	'identifierSchemeCodeUnspsc190501.25181700': __('identifierSchemeCodeUnspsc190501.25181700'),
	'identifierSchemeCodeUnspsc190501.25181701': __('identifierSchemeCodeUnspsc190501.25181701'),
	'identifierSchemeCodeUnspsc190501.25181702': __('identifierSchemeCodeUnspsc190501.25181702'),
	'identifierSchemeCodeUnspsc190501.25181703': __('identifierSchemeCodeUnspsc190501.25181703'),
	'identifierSchemeCodeUnspsc190501.25181704': __('identifierSchemeCodeUnspsc190501.25181704'),
	'identifierSchemeCodeUnspsc190501.25181705': __('identifierSchemeCodeUnspsc190501.25181705'),
	'identifierSchemeCodeUnspsc190501.25181706': __('identifierSchemeCodeUnspsc190501.25181706'),
	'identifierSchemeCodeUnspsc190501.25181707': __('identifierSchemeCodeUnspsc190501.25181707'),
	'identifierSchemeCodeUnspsc190501.25181708': __('identifierSchemeCodeUnspsc190501.25181708'),
	'identifierSchemeCodeUnspsc190501.25181709': __('identifierSchemeCodeUnspsc190501.25181709'),
	'identifierSchemeCodeUnspsc190501.25181710': __('identifierSchemeCodeUnspsc190501.25181710'),
	'identifierSchemeCodeUnspsc190501.25181711': __('identifierSchemeCodeUnspsc190501.25181711'),
	'identifierSchemeCodeUnspsc190501.25181712': __('identifierSchemeCodeUnspsc190501.25181712'),
	'identifierSchemeCodeUnspsc190501.25181713': __('identifierSchemeCodeUnspsc190501.25181713'),
	'identifierSchemeCodeUnspsc190501.25181714': __('identifierSchemeCodeUnspsc190501.25181714'),
	'identifierSchemeCodeUnspsc190501.25181715': __('identifierSchemeCodeUnspsc190501.25181715'),
	'identifierSchemeCodeUnspsc190501.25181716': __('identifierSchemeCodeUnspsc190501.25181716'),
	'identifierSchemeCodeUnspsc190501.25181717': __('identifierSchemeCodeUnspsc190501.25181717'),
	'identifierSchemeCodeUnspsc190501.25181718': __('identifierSchemeCodeUnspsc190501.25181718'),
	'identifierSchemeCodeUnspsc190501.25181719': __('identifierSchemeCodeUnspsc190501.25181719'),
	'identifierSchemeCodeUnspsc190501.25181720': __('identifierSchemeCodeUnspsc190501.25181720'),
	'identifierSchemeCodeUnspsc190501.25190000': __('identifierSchemeCodeUnspsc190501.25190000'),
	'identifierSchemeCodeUnspsc190501.25191500': __('identifierSchemeCodeUnspsc190501.25191500'),
	'identifierSchemeCodeUnspsc190501.25191501': __('identifierSchemeCodeUnspsc190501.25191501'),
	'identifierSchemeCodeUnspsc190501.25191502': __('identifierSchemeCodeUnspsc190501.25191502'),
	'identifierSchemeCodeUnspsc190501.25191503': __('identifierSchemeCodeUnspsc190501.25191503'),
	'identifierSchemeCodeUnspsc190501.25191504': __('identifierSchemeCodeUnspsc190501.25191504'),
	'identifierSchemeCodeUnspsc190501.25191505': __('identifierSchemeCodeUnspsc190501.25191505'),
	'identifierSchemeCodeUnspsc190501.25191506': __('identifierSchemeCodeUnspsc190501.25191506'),
	'identifierSchemeCodeUnspsc190501.25191507': __('identifierSchemeCodeUnspsc190501.25191507'),
	'identifierSchemeCodeUnspsc190501.25191508': __('identifierSchemeCodeUnspsc190501.25191508'),
	'identifierSchemeCodeUnspsc190501.25191509': __('identifierSchemeCodeUnspsc190501.25191509'),
	'identifierSchemeCodeUnspsc190501.25191510': __('identifierSchemeCodeUnspsc190501.25191510'),
	'identifierSchemeCodeUnspsc190501.25191511': __('identifierSchemeCodeUnspsc190501.25191511'),
	'identifierSchemeCodeUnspsc190501.25191512': __('identifierSchemeCodeUnspsc190501.25191512'),
	'identifierSchemeCodeUnspsc190501.25191513': __('identifierSchemeCodeUnspsc190501.25191513'),
	'identifierSchemeCodeUnspsc190501.25191514': __('identifierSchemeCodeUnspsc190501.25191514'),
	'identifierSchemeCodeUnspsc190501.25191515': __('identifierSchemeCodeUnspsc190501.25191515'),
	'identifierSchemeCodeUnspsc190501.25191516': __('identifierSchemeCodeUnspsc190501.25191516'),
	'identifierSchemeCodeUnspsc190501.25191517': __('identifierSchemeCodeUnspsc190501.25191517'),
	'identifierSchemeCodeUnspsc190501.25191518': __('identifierSchemeCodeUnspsc190501.25191518'),
	'identifierSchemeCodeUnspsc190501.25191519': __('identifierSchemeCodeUnspsc190501.25191519'),
	'identifierSchemeCodeUnspsc190501.25191520': __('identifierSchemeCodeUnspsc190501.25191520'),
	'identifierSchemeCodeUnspsc190501.25191521': __('identifierSchemeCodeUnspsc190501.25191521'),
	'identifierSchemeCodeUnspsc190501.25191522': __('identifierSchemeCodeUnspsc190501.25191522'),
	'identifierSchemeCodeUnspsc190501.25191523': __('identifierSchemeCodeUnspsc190501.25191523'),
	'identifierSchemeCodeUnspsc190501.25191524': __('identifierSchemeCodeUnspsc190501.25191524'),
	'identifierSchemeCodeUnspsc190501.25191525': __('identifierSchemeCodeUnspsc190501.25191525'),
	'identifierSchemeCodeUnspsc190501.25191600': __('identifierSchemeCodeUnspsc190501.25191600'),
	'identifierSchemeCodeUnspsc190501.25191601': __('identifierSchemeCodeUnspsc190501.25191601'),
	'identifierSchemeCodeUnspsc190501.25191602': __('identifierSchemeCodeUnspsc190501.25191602'),
	'identifierSchemeCodeUnspsc190501.25191603': __('identifierSchemeCodeUnspsc190501.25191603'),
	'identifierSchemeCodeUnspsc190501.25191604': __('identifierSchemeCodeUnspsc190501.25191604'),
	'identifierSchemeCodeUnspsc190501.25191605': __('identifierSchemeCodeUnspsc190501.25191605'),
	'identifierSchemeCodeUnspsc190501.25191700': __('identifierSchemeCodeUnspsc190501.25191700'),
	'identifierSchemeCodeUnspsc190501.25191701': __('identifierSchemeCodeUnspsc190501.25191701'),
	'identifierSchemeCodeUnspsc190501.25191702': __('identifierSchemeCodeUnspsc190501.25191702'),
	'identifierSchemeCodeUnspsc190501.25191703': __('identifierSchemeCodeUnspsc190501.25191703'),
	'identifierSchemeCodeUnspsc190501.25191704': __('identifierSchemeCodeUnspsc190501.25191704'),
	'identifierSchemeCodeUnspsc190501.25191705': __('identifierSchemeCodeUnspsc190501.25191705'),
	'identifierSchemeCodeUnspsc190501.25191706': __('identifierSchemeCodeUnspsc190501.25191706'),
	'identifierSchemeCodeUnspsc190501.25191707': __('identifierSchemeCodeUnspsc190501.25191707'),
	'identifierSchemeCodeUnspsc190501.25191708': __('identifierSchemeCodeUnspsc190501.25191708'),
	'identifierSchemeCodeUnspsc190501.25191709': __('identifierSchemeCodeUnspsc190501.25191709'),
	'identifierSchemeCodeUnspsc190501.25191710': __('identifierSchemeCodeUnspsc190501.25191710'),
	'identifierSchemeCodeUnspsc190501.25191711': __('identifierSchemeCodeUnspsc190501.25191711'),
	'identifierSchemeCodeUnspsc190501.25191712': __('identifierSchemeCodeUnspsc190501.25191712'),
	'identifierSchemeCodeUnspsc190501.25191713': __('identifierSchemeCodeUnspsc190501.25191713'),
	'identifierSchemeCodeUnspsc190501.25191714': __('identifierSchemeCodeUnspsc190501.25191714'),
	'identifierSchemeCodeUnspsc190501.25191715': __('identifierSchemeCodeUnspsc190501.25191715'),
	'identifierSchemeCodeUnspsc190501.25191716': __('identifierSchemeCodeUnspsc190501.25191716'),
	'identifierSchemeCodeUnspsc190501.25191717': __('identifierSchemeCodeUnspsc190501.25191717'),
	'identifierSchemeCodeUnspsc190501.25191718': __('identifierSchemeCodeUnspsc190501.25191718'),
	'identifierSchemeCodeUnspsc190501.25191719': __('identifierSchemeCodeUnspsc190501.25191719'),
	'identifierSchemeCodeUnspsc190501.25191720': __('identifierSchemeCodeUnspsc190501.25191720'),
	'identifierSchemeCodeUnspsc190501.25191721': __('identifierSchemeCodeUnspsc190501.25191721'),
	'identifierSchemeCodeUnspsc190501.25191722': __('identifierSchemeCodeUnspsc190501.25191722'),
	'identifierSchemeCodeUnspsc190501.25191723': __('identifierSchemeCodeUnspsc190501.25191723'),
	'identifierSchemeCodeUnspsc190501.25191724': __('identifierSchemeCodeUnspsc190501.25191724'),
	'identifierSchemeCodeUnspsc190501.25191725': __('identifierSchemeCodeUnspsc190501.25191725'),
	'identifierSchemeCodeUnspsc190501.25191726': __('identifierSchemeCodeUnspsc190501.25191726'),
	'identifierSchemeCodeUnspsc190501.25191727': __('identifierSchemeCodeUnspsc190501.25191727'),
	'identifierSchemeCodeUnspsc190501.25191728': __('identifierSchemeCodeUnspsc190501.25191728'),
	'identifierSchemeCodeUnspsc190501.25191729': __('identifierSchemeCodeUnspsc190501.25191729'),
	'identifierSchemeCodeUnspsc190501.25191730': __('identifierSchemeCodeUnspsc190501.25191730'),
	'identifierSchemeCodeUnspsc190501.25191731': __('identifierSchemeCodeUnspsc190501.25191731'),
	'identifierSchemeCodeUnspsc190501.25191732': __('identifierSchemeCodeUnspsc190501.25191732'),
	'identifierSchemeCodeUnspsc190501.25191733': __('identifierSchemeCodeUnspsc190501.25191733'),
	'identifierSchemeCodeUnspsc190501.25191734': __('identifierSchemeCodeUnspsc190501.25191734'),
	'identifierSchemeCodeUnspsc190501.25191735': __('identifierSchemeCodeUnspsc190501.25191735'),
	'identifierSchemeCodeUnspsc190501.25191736': __('identifierSchemeCodeUnspsc190501.25191736'),
	'identifierSchemeCodeUnspsc190501.25191737': __('identifierSchemeCodeUnspsc190501.25191737'),
	'identifierSchemeCodeUnspsc190501.25191738': __('identifierSchemeCodeUnspsc190501.25191738'),
	'identifierSchemeCodeUnspsc190501.25191739': __('identifierSchemeCodeUnspsc190501.25191739'),
	'identifierSchemeCodeUnspsc190501.25191740': __('identifierSchemeCodeUnspsc190501.25191740'),
	'identifierSchemeCodeUnspsc190501.25191741': __('identifierSchemeCodeUnspsc190501.25191741'),
	'identifierSchemeCodeUnspsc190501.25191742': __('identifierSchemeCodeUnspsc190501.25191742'),
	'identifierSchemeCodeUnspsc190501.25191743': __('identifierSchemeCodeUnspsc190501.25191743'),
	'identifierSchemeCodeUnspsc190501.25191744': __('identifierSchemeCodeUnspsc190501.25191744'),
	'identifierSchemeCodeUnspsc190501.25191800': __('identifierSchemeCodeUnspsc190501.25191800'),
	'identifierSchemeCodeUnspsc190501.25191801': __('identifierSchemeCodeUnspsc190501.25191801'),
	'identifierSchemeCodeUnspsc190501.25191802': __('identifierSchemeCodeUnspsc190501.25191802'),
	'identifierSchemeCodeUnspsc190501.25191803': __('identifierSchemeCodeUnspsc190501.25191803'),
	'identifierSchemeCodeUnspsc190501.25191804': __('identifierSchemeCodeUnspsc190501.25191804'),
	'identifierSchemeCodeUnspsc190501.25191805': __('identifierSchemeCodeUnspsc190501.25191805'),
	'identifierSchemeCodeUnspsc190501.25191806': __('identifierSchemeCodeUnspsc190501.25191806'),
	'identifierSchemeCodeUnspsc190501.25191807': __('identifierSchemeCodeUnspsc190501.25191807'),
	'identifierSchemeCodeUnspsc190501.25191808': __('identifierSchemeCodeUnspsc190501.25191808'),
	'identifierSchemeCodeUnspsc190501.25191809': __('identifierSchemeCodeUnspsc190501.25191809'),
	'identifierSchemeCodeUnspsc190501.25191810': __('identifierSchemeCodeUnspsc190501.25191810'),
	'identifierSchemeCodeUnspsc190501.25191811': __('identifierSchemeCodeUnspsc190501.25191811'),
	'identifierSchemeCodeUnspsc190501.25191812': __('identifierSchemeCodeUnspsc190501.25191812'),
	'identifierSchemeCodeUnspsc190501.25191813': __('identifierSchemeCodeUnspsc190501.25191813'),
	'identifierSchemeCodeUnspsc190501.25191814': __('identifierSchemeCodeUnspsc190501.25191814'),
	'identifierSchemeCodeUnspsc190501.25191815': __('identifierSchemeCodeUnspsc190501.25191815'),
	'identifierSchemeCodeUnspsc190501.25191816': __('identifierSchemeCodeUnspsc190501.25191816'),
	'identifierSchemeCodeUnspsc190501.25191817': __('identifierSchemeCodeUnspsc190501.25191817'),
	'identifierSchemeCodeUnspsc190501.25191818': __('identifierSchemeCodeUnspsc190501.25191818'),
	'identifierSchemeCodeUnspsc190501.25191819': __('identifierSchemeCodeUnspsc190501.25191819'),
	'identifierSchemeCodeUnspsc190501.25191820': __('identifierSchemeCodeUnspsc190501.25191820'),
	'identifierSchemeCodeUnspsc190501.25191821': __('identifierSchemeCodeUnspsc190501.25191821'),
	'identifierSchemeCodeUnspsc190501.25191822': __('identifierSchemeCodeUnspsc190501.25191822'),
	'identifierSchemeCodeUnspsc190501.25191823': __('identifierSchemeCodeUnspsc190501.25191823'),
	'identifierSchemeCodeUnspsc190501.25191824': __('identifierSchemeCodeUnspsc190501.25191824'),
	'identifierSchemeCodeUnspsc190501.25191825': __('identifierSchemeCodeUnspsc190501.25191825'),
	'identifierSchemeCodeUnspsc190501.25191826': __('identifierSchemeCodeUnspsc190501.25191826'),
	'identifierSchemeCodeUnspsc190501.25191827': __('identifierSchemeCodeUnspsc190501.25191827'),
	'identifierSchemeCodeUnspsc190501.25191828': __('identifierSchemeCodeUnspsc190501.25191828'),
	'identifierSchemeCodeUnspsc190501.25191829': __('identifierSchemeCodeUnspsc190501.25191829'),
	'identifierSchemeCodeUnspsc190501.25191830': __('identifierSchemeCodeUnspsc190501.25191830'),
	'identifierSchemeCodeUnspsc190501.25191831': __('identifierSchemeCodeUnspsc190501.25191831'),
	'identifierSchemeCodeUnspsc190501.25191832': __('identifierSchemeCodeUnspsc190501.25191832'),
	'identifierSchemeCodeUnspsc190501.25191833': __('identifierSchemeCodeUnspsc190501.25191833'),
	'identifierSchemeCodeUnspsc190501.25191834': __('identifierSchemeCodeUnspsc190501.25191834'),
	'identifierSchemeCodeUnspsc190501.25191835': __('identifierSchemeCodeUnspsc190501.25191835'),
	'identifierSchemeCodeUnspsc190501.25191836': __('identifierSchemeCodeUnspsc190501.25191836'),
	'identifierSchemeCodeUnspsc190501.25191837': __('identifierSchemeCodeUnspsc190501.25191837'),
	'identifierSchemeCodeUnspsc190501.25191838': __('identifierSchemeCodeUnspsc190501.25191838'),
	'identifierSchemeCodeUnspsc190501.25191839': __('identifierSchemeCodeUnspsc190501.25191839'),
	'identifierSchemeCodeUnspsc190501.25200000': __('identifierSchemeCodeUnspsc190501.25200000'),
	'identifierSchemeCodeUnspsc190501.25201500': __('identifierSchemeCodeUnspsc190501.25201500'),
	'identifierSchemeCodeUnspsc190501.25201501': __('identifierSchemeCodeUnspsc190501.25201501'),
	'identifierSchemeCodeUnspsc190501.25201502': __('identifierSchemeCodeUnspsc190501.25201502'),
	'identifierSchemeCodeUnspsc190501.25201503': __('identifierSchemeCodeUnspsc190501.25201503'),
	'identifierSchemeCodeUnspsc190501.25201504': __('identifierSchemeCodeUnspsc190501.25201504'),
	'identifierSchemeCodeUnspsc190501.25201505': __('identifierSchemeCodeUnspsc190501.25201505'),
	'identifierSchemeCodeUnspsc190501.25201506': __('identifierSchemeCodeUnspsc190501.25201506'),
	'identifierSchemeCodeUnspsc190501.25201507': __('identifierSchemeCodeUnspsc190501.25201507'),
	'identifierSchemeCodeUnspsc190501.25201508': __('identifierSchemeCodeUnspsc190501.25201508'),
	'identifierSchemeCodeUnspsc190501.25201509': __('identifierSchemeCodeUnspsc190501.25201509'),
	'identifierSchemeCodeUnspsc190501.25201510': __('identifierSchemeCodeUnspsc190501.25201510'),
	'identifierSchemeCodeUnspsc190501.25201511': __('identifierSchemeCodeUnspsc190501.25201511'),
	'identifierSchemeCodeUnspsc190501.25201512': __('identifierSchemeCodeUnspsc190501.25201512'),
	'identifierSchemeCodeUnspsc190501.25201513': __('identifierSchemeCodeUnspsc190501.25201513'),
	'identifierSchemeCodeUnspsc190501.25201514': __('identifierSchemeCodeUnspsc190501.25201514'),
	'identifierSchemeCodeUnspsc190501.25201515': __('identifierSchemeCodeUnspsc190501.25201515'),
	'identifierSchemeCodeUnspsc190501.25201516': __('identifierSchemeCodeUnspsc190501.25201516'),
	'identifierSchemeCodeUnspsc190501.25201517': __('identifierSchemeCodeUnspsc190501.25201517'),
	'identifierSchemeCodeUnspsc190501.25201518': __('identifierSchemeCodeUnspsc190501.25201518'),
	'identifierSchemeCodeUnspsc190501.25201519': __('identifierSchemeCodeUnspsc190501.25201519'),
	'identifierSchemeCodeUnspsc190501.25201520': __('identifierSchemeCodeUnspsc190501.25201520'),
	'identifierSchemeCodeUnspsc190501.25201521': __('identifierSchemeCodeUnspsc190501.25201521'),
	'identifierSchemeCodeUnspsc190501.25201522': __('identifierSchemeCodeUnspsc190501.25201522'),
	'identifierSchemeCodeUnspsc190501.25201600': __('identifierSchemeCodeUnspsc190501.25201600'),
	'identifierSchemeCodeUnspsc190501.25201601': __('identifierSchemeCodeUnspsc190501.25201601'),
	'identifierSchemeCodeUnspsc190501.25201602': __('identifierSchemeCodeUnspsc190501.25201602'),
	'identifierSchemeCodeUnspsc190501.25201603': __('identifierSchemeCodeUnspsc190501.25201603'),
	'identifierSchemeCodeUnspsc190501.25201604': __('identifierSchemeCodeUnspsc190501.25201604'),
	'identifierSchemeCodeUnspsc190501.25201605': __('identifierSchemeCodeUnspsc190501.25201605'),
	'identifierSchemeCodeUnspsc190501.25201606': __('identifierSchemeCodeUnspsc190501.25201606'),
	'identifierSchemeCodeUnspsc190501.25201700': __('identifierSchemeCodeUnspsc190501.25201700'),
	'identifierSchemeCodeUnspsc190501.25201701': __('identifierSchemeCodeUnspsc190501.25201701'),
	'identifierSchemeCodeUnspsc190501.25201702': __('identifierSchemeCodeUnspsc190501.25201702'),
	'identifierSchemeCodeUnspsc190501.25201703': __('identifierSchemeCodeUnspsc190501.25201703'),
	'identifierSchemeCodeUnspsc190501.25201704': __('identifierSchemeCodeUnspsc190501.25201704'),
	'identifierSchemeCodeUnspsc190501.25201705': __('identifierSchemeCodeUnspsc190501.25201705'),
	'identifierSchemeCodeUnspsc190501.25201706': __('identifierSchemeCodeUnspsc190501.25201706'),
	'identifierSchemeCodeUnspsc190501.25201707': __('identifierSchemeCodeUnspsc190501.25201707'),
	'identifierSchemeCodeUnspsc190501.25201708': __('identifierSchemeCodeUnspsc190501.25201708'),
	'identifierSchemeCodeUnspsc190501.25201709': __('identifierSchemeCodeUnspsc190501.25201709'),
	'identifierSchemeCodeUnspsc190501.25201710': __('identifierSchemeCodeUnspsc190501.25201710'),
	'identifierSchemeCodeUnspsc190501.25201800': __('identifierSchemeCodeUnspsc190501.25201800'),
	'identifierSchemeCodeUnspsc190501.25201801': __('identifierSchemeCodeUnspsc190501.25201801'),
	'identifierSchemeCodeUnspsc190501.25201802': __('identifierSchemeCodeUnspsc190501.25201802'),
	'identifierSchemeCodeUnspsc190501.25201900': __('identifierSchemeCodeUnspsc190501.25201900'),
	'identifierSchemeCodeUnspsc190501.25201901': __('identifierSchemeCodeUnspsc190501.25201901'),
	'identifierSchemeCodeUnspsc190501.25201902': __('identifierSchemeCodeUnspsc190501.25201902'),
	'identifierSchemeCodeUnspsc190501.25201903': __('identifierSchemeCodeUnspsc190501.25201903'),
	'identifierSchemeCodeUnspsc190501.25201904': __('identifierSchemeCodeUnspsc190501.25201904'),
	'identifierSchemeCodeUnspsc190501.25202000': __('identifierSchemeCodeUnspsc190501.25202000'),
	'identifierSchemeCodeUnspsc190501.25202001': __('identifierSchemeCodeUnspsc190501.25202001'),
	'identifierSchemeCodeUnspsc190501.25202002': __('identifierSchemeCodeUnspsc190501.25202002'),
	'identifierSchemeCodeUnspsc190501.25202003': __('identifierSchemeCodeUnspsc190501.25202003'),
	'identifierSchemeCodeUnspsc190501.25202004': __('identifierSchemeCodeUnspsc190501.25202004'),
	'identifierSchemeCodeUnspsc190501.25202100': __('identifierSchemeCodeUnspsc190501.25202100'),
	'identifierSchemeCodeUnspsc190501.25202101': __('identifierSchemeCodeUnspsc190501.25202101'),
	'identifierSchemeCodeUnspsc190501.25202102': __('identifierSchemeCodeUnspsc190501.25202102'),
	'identifierSchemeCodeUnspsc190501.25202103': __('identifierSchemeCodeUnspsc190501.25202103'),
	'identifierSchemeCodeUnspsc190501.25202104': __('identifierSchemeCodeUnspsc190501.25202104'),
	'identifierSchemeCodeUnspsc190501.25202105': __('identifierSchemeCodeUnspsc190501.25202105'),
	'identifierSchemeCodeUnspsc190501.25202200': __('identifierSchemeCodeUnspsc190501.25202200'),
	'identifierSchemeCodeUnspsc190501.25202201': __('identifierSchemeCodeUnspsc190501.25202201'),
	'identifierSchemeCodeUnspsc190501.25202202': __('identifierSchemeCodeUnspsc190501.25202202'),
	'identifierSchemeCodeUnspsc190501.25202203': __('identifierSchemeCodeUnspsc190501.25202203'),
	'identifierSchemeCodeUnspsc190501.25202204': __('identifierSchemeCodeUnspsc190501.25202204'),
	'identifierSchemeCodeUnspsc190501.25202205': __('identifierSchemeCodeUnspsc190501.25202205'),
	'identifierSchemeCodeUnspsc190501.25202206': __('identifierSchemeCodeUnspsc190501.25202206'),
	'identifierSchemeCodeUnspsc190501.25202207': __('identifierSchemeCodeUnspsc190501.25202207'),
	'identifierSchemeCodeUnspsc190501.25202300': __('identifierSchemeCodeUnspsc190501.25202300'),
	'identifierSchemeCodeUnspsc190501.25202301': __('identifierSchemeCodeUnspsc190501.25202301'),
	'identifierSchemeCodeUnspsc190501.25202302': __('identifierSchemeCodeUnspsc190501.25202302'),
	'identifierSchemeCodeUnspsc190501.25202400': __('identifierSchemeCodeUnspsc190501.25202400'),
	'identifierSchemeCodeUnspsc190501.25202401': __('identifierSchemeCodeUnspsc190501.25202401'),
	'identifierSchemeCodeUnspsc190501.25202402': __('identifierSchemeCodeUnspsc190501.25202402'),
	'identifierSchemeCodeUnspsc190501.25202403': __('identifierSchemeCodeUnspsc190501.25202403'),
	'identifierSchemeCodeUnspsc190501.25202404': __('identifierSchemeCodeUnspsc190501.25202404'),
	'identifierSchemeCodeUnspsc190501.25202405': __('identifierSchemeCodeUnspsc190501.25202405'),
	'identifierSchemeCodeUnspsc190501.25202406': __('identifierSchemeCodeUnspsc190501.25202406'),
	'identifierSchemeCodeUnspsc190501.25202500': __('identifierSchemeCodeUnspsc190501.25202500'),
	'identifierSchemeCodeUnspsc190501.25202501': __('identifierSchemeCodeUnspsc190501.25202501'),
	'identifierSchemeCodeUnspsc190501.25202502': __('identifierSchemeCodeUnspsc190501.25202502'),
	'identifierSchemeCodeUnspsc190501.25202503': __('identifierSchemeCodeUnspsc190501.25202503'),
	'identifierSchemeCodeUnspsc190501.25202504': __('identifierSchemeCodeUnspsc190501.25202504'),
	'identifierSchemeCodeUnspsc190501.25202505': __('identifierSchemeCodeUnspsc190501.25202505'),
	'identifierSchemeCodeUnspsc190501.25202506': __('identifierSchemeCodeUnspsc190501.25202506'),
	'identifierSchemeCodeUnspsc190501.25202507': __('identifierSchemeCodeUnspsc190501.25202507'),
	'identifierSchemeCodeUnspsc190501.25202508': __('identifierSchemeCodeUnspsc190501.25202508'),
	'identifierSchemeCodeUnspsc190501.25202509': __('identifierSchemeCodeUnspsc190501.25202509'),
	'identifierSchemeCodeUnspsc190501.25202510': __('identifierSchemeCodeUnspsc190501.25202510'),
	'identifierSchemeCodeUnspsc190501.25202600': __('identifierSchemeCodeUnspsc190501.25202600'),
	'identifierSchemeCodeUnspsc190501.25202601': __('identifierSchemeCodeUnspsc190501.25202601'),
	'identifierSchemeCodeUnspsc190501.25202602': __('identifierSchemeCodeUnspsc190501.25202602'),
	'identifierSchemeCodeUnspsc190501.25202603': __('identifierSchemeCodeUnspsc190501.25202603'),
	'identifierSchemeCodeUnspsc190501.25202604': __('identifierSchemeCodeUnspsc190501.25202604'),
	'identifierSchemeCodeUnspsc190501.25202605': __('identifierSchemeCodeUnspsc190501.25202605'),
	'identifierSchemeCodeUnspsc190501.25202606': __('identifierSchemeCodeUnspsc190501.25202606'),
	'identifierSchemeCodeUnspsc190501.25202607': __('identifierSchemeCodeUnspsc190501.25202607'),
	'identifierSchemeCodeUnspsc190501.25202700': __('identifierSchemeCodeUnspsc190501.25202700'),
	'identifierSchemeCodeUnspsc190501.25202701': __('identifierSchemeCodeUnspsc190501.25202701'),
	'identifierSchemeCodeUnspsc190501.25202702': __('identifierSchemeCodeUnspsc190501.25202702'),
	'identifierSchemeCodeUnspsc190501.26000000': __('identifierSchemeCodeUnspsc190501.26000000'),
	'identifierSchemeCodeUnspsc190501.26100000': __('identifierSchemeCodeUnspsc190501.26100000'),
	'identifierSchemeCodeUnspsc190501.26101100': __('identifierSchemeCodeUnspsc190501.26101100'),
	'identifierSchemeCodeUnspsc190501.26101101': __('identifierSchemeCodeUnspsc190501.26101101'),
	'identifierSchemeCodeUnspsc190501.26101102': __('identifierSchemeCodeUnspsc190501.26101102'),
	'identifierSchemeCodeUnspsc190501.26101103': __('identifierSchemeCodeUnspsc190501.26101103'),
	'identifierSchemeCodeUnspsc190501.26101105': __('identifierSchemeCodeUnspsc190501.26101105'),
	'identifierSchemeCodeUnspsc190501.26101106': __('identifierSchemeCodeUnspsc190501.26101106'),
	'identifierSchemeCodeUnspsc190501.26101107': __('identifierSchemeCodeUnspsc190501.26101107'),
	'identifierSchemeCodeUnspsc190501.26101108': __('identifierSchemeCodeUnspsc190501.26101108'),
	'identifierSchemeCodeUnspsc190501.26101109': __('identifierSchemeCodeUnspsc190501.26101109'),
	'identifierSchemeCodeUnspsc190501.26101110': __('identifierSchemeCodeUnspsc190501.26101110'),
	'identifierSchemeCodeUnspsc190501.26101111': __('identifierSchemeCodeUnspsc190501.26101111'),
	'identifierSchemeCodeUnspsc190501.26101112': __('identifierSchemeCodeUnspsc190501.26101112'),
	'identifierSchemeCodeUnspsc190501.26101113': __('identifierSchemeCodeUnspsc190501.26101113'),
	'identifierSchemeCodeUnspsc190501.26101114': __('identifierSchemeCodeUnspsc190501.26101114'),
	'identifierSchemeCodeUnspsc190501.26101115': __('identifierSchemeCodeUnspsc190501.26101115'),
	'identifierSchemeCodeUnspsc190501.26101116': __('identifierSchemeCodeUnspsc190501.26101116'),
	'identifierSchemeCodeUnspsc190501.26101117': __('identifierSchemeCodeUnspsc190501.26101117'),
	'identifierSchemeCodeUnspsc190501.26101200': __('identifierSchemeCodeUnspsc190501.26101200'),
	'identifierSchemeCodeUnspsc190501.26101201': __('identifierSchemeCodeUnspsc190501.26101201'),
	'identifierSchemeCodeUnspsc190501.26101202': __('identifierSchemeCodeUnspsc190501.26101202'),
	'identifierSchemeCodeUnspsc190501.26101203': __('identifierSchemeCodeUnspsc190501.26101203'),
	'identifierSchemeCodeUnspsc190501.26101204': __('identifierSchemeCodeUnspsc190501.26101204'),
	'identifierSchemeCodeUnspsc190501.26101205': __('identifierSchemeCodeUnspsc190501.26101205'),
	'identifierSchemeCodeUnspsc190501.26101206': __('identifierSchemeCodeUnspsc190501.26101206'),
	'identifierSchemeCodeUnspsc190501.26101207': __('identifierSchemeCodeUnspsc190501.26101207'),
	'identifierSchemeCodeUnspsc190501.26101208': __('identifierSchemeCodeUnspsc190501.26101208'),
	'identifierSchemeCodeUnspsc190501.26101209': __('identifierSchemeCodeUnspsc190501.26101209'),
	'identifierSchemeCodeUnspsc190501.26101210': __('identifierSchemeCodeUnspsc190501.26101210'),
	'identifierSchemeCodeUnspsc190501.26101211': __('identifierSchemeCodeUnspsc190501.26101211'),
	'identifierSchemeCodeUnspsc190501.26101212': __('identifierSchemeCodeUnspsc190501.26101212'),
	'identifierSchemeCodeUnspsc190501.26101300': __('identifierSchemeCodeUnspsc190501.26101300'),
	'identifierSchemeCodeUnspsc190501.26101301': __('identifierSchemeCodeUnspsc190501.26101301'),
	'identifierSchemeCodeUnspsc190501.26101302': __('identifierSchemeCodeUnspsc190501.26101302'),
	'identifierSchemeCodeUnspsc190501.26101303': __('identifierSchemeCodeUnspsc190501.26101303'),
	'identifierSchemeCodeUnspsc190501.26101304': __('identifierSchemeCodeUnspsc190501.26101304'),
	'identifierSchemeCodeUnspsc190501.26101306': __('identifierSchemeCodeUnspsc190501.26101306'),
	'identifierSchemeCodeUnspsc190501.26101309': __('identifierSchemeCodeUnspsc190501.26101309'),
	'identifierSchemeCodeUnspsc190501.26101310': __('identifierSchemeCodeUnspsc190501.26101310'),
	'identifierSchemeCodeUnspsc190501.26101311': __('identifierSchemeCodeUnspsc190501.26101311'),
	'identifierSchemeCodeUnspsc190501.26101312': __('identifierSchemeCodeUnspsc190501.26101312'),
	'identifierSchemeCodeUnspsc190501.26101313': __('identifierSchemeCodeUnspsc190501.26101313'),
	'identifierSchemeCodeUnspsc190501.26101400': __('identifierSchemeCodeUnspsc190501.26101400'),
	'identifierSchemeCodeUnspsc190501.26101401': __('identifierSchemeCodeUnspsc190501.26101401'),
	'identifierSchemeCodeUnspsc190501.26101402': __('identifierSchemeCodeUnspsc190501.26101402'),
	'identifierSchemeCodeUnspsc190501.26101403': __('identifierSchemeCodeUnspsc190501.26101403'),
	'identifierSchemeCodeUnspsc190501.26101404': __('identifierSchemeCodeUnspsc190501.26101404'),
	'identifierSchemeCodeUnspsc190501.26101405': __('identifierSchemeCodeUnspsc190501.26101405'),
	'identifierSchemeCodeUnspsc190501.26101406': __('identifierSchemeCodeUnspsc190501.26101406'),
	'identifierSchemeCodeUnspsc190501.26101408': __('identifierSchemeCodeUnspsc190501.26101408'),
	'identifierSchemeCodeUnspsc190501.26101409': __('identifierSchemeCodeUnspsc190501.26101409'),
	'identifierSchemeCodeUnspsc190501.26101410': __('identifierSchemeCodeUnspsc190501.26101410'),
	'identifierSchemeCodeUnspsc190501.26101411': __('identifierSchemeCodeUnspsc190501.26101411'),
	'identifierSchemeCodeUnspsc190501.26101412': __('identifierSchemeCodeUnspsc190501.26101412'),
	'identifierSchemeCodeUnspsc190501.26101413': __('identifierSchemeCodeUnspsc190501.26101413'),
	'identifierSchemeCodeUnspsc190501.26101414': __('identifierSchemeCodeUnspsc190501.26101414'),
	'identifierSchemeCodeUnspsc190501.26101415': __('identifierSchemeCodeUnspsc190501.26101415'),
	'identifierSchemeCodeUnspsc190501.26101500': __('identifierSchemeCodeUnspsc190501.26101500'),
	'identifierSchemeCodeUnspsc190501.26101501': __('identifierSchemeCodeUnspsc190501.26101501'),
	'identifierSchemeCodeUnspsc190501.26101502': __('identifierSchemeCodeUnspsc190501.26101502'),
	'identifierSchemeCodeUnspsc190501.26101503': __('identifierSchemeCodeUnspsc190501.26101503'),
	'identifierSchemeCodeUnspsc190501.26101504': __('identifierSchemeCodeUnspsc190501.26101504'),
	'identifierSchemeCodeUnspsc190501.26101505': __('identifierSchemeCodeUnspsc190501.26101505'),
	'identifierSchemeCodeUnspsc190501.26101506': __('identifierSchemeCodeUnspsc190501.26101506'),
	'identifierSchemeCodeUnspsc190501.26101507': __('identifierSchemeCodeUnspsc190501.26101507'),
	'identifierSchemeCodeUnspsc190501.26101508': __('identifierSchemeCodeUnspsc190501.26101508'),
	'identifierSchemeCodeUnspsc190501.26101509': __('identifierSchemeCodeUnspsc190501.26101509'),
	'identifierSchemeCodeUnspsc190501.26101510': __('identifierSchemeCodeUnspsc190501.26101510'),
	'identifierSchemeCodeUnspsc190501.26101511': __('identifierSchemeCodeUnspsc190501.26101511'),
	'identifierSchemeCodeUnspsc190501.26101512': __('identifierSchemeCodeUnspsc190501.26101512'),
	'identifierSchemeCodeUnspsc190501.26101513': __('identifierSchemeCodeUnspsc190501.26101513'),
	'identifierSchemeCodeUnspsc190501.26101514': __('identifierSchemeCodeUnspsc190501.26101514'),
	'identifierSchemeCodeUnspsc190501.26101515': __('identifierSchemeCodeUnspsc190501.26101515'),
	'identifierSchemeCodeUnspsc190501.26101700': __('identifierSchemeCodeUnspsc190501.26101700'),
	'identifierSchemeCodeUnspsc190501.26101701': __('identifierSchemeCodeUnspsc190501.26101701'),
	'identifierSchemeCodeUnspsc190501.26101702': __('identifierSchemeCodeUnspsc190501.26101702'),
	'identifierSchemeCodeUnspsc190501.26101703': __('identifierSchemeCodeUnspsc190501.26101703'),
	'identifierSchemeCodeUnspsc190501.26101704': __('identifierSchemeCodeUnspsc190501.26101704'),
	'identifierSchemeCodeUnspsc190501.26101705': __('identifierSchemeCodeUnspsc190501.26101705'),
	'identifierSchemeCodeUnspsc190501.26101706': __('identifierSchemeCodeUnspsc190501.26101706'),
	'identifierSchemeCodeUnspsc190501.26101707': __('identifierSchemeCodeUnspsc190501.26101707'),
	'identifierSchemeCodeUnspsc190501.26101708': __('identifierSchemeCodeUnspsc190501.26101708'),
	'identifierSchemeCodeUnspsc190501.26101709': __('identifierSchemeCodeUnspsc190501.26101709'),
	'identifierSchemeCodeUnspsc190501.26101710': __('identifierSchemeCodeUnspsc190501.26101710'),
	'identifierSchemeCodeUnspsc190501.26101711': __('identifierSchemeCodeUnspsc190501.26101711'),
	'identifierSchemeCodeUnspsc190501.26101712': __('identifierSchemeCodeUnspsc190501.26101712'),
	'identifierSchemeCodeUnspsc190501.26101713': __('identifierSchemeCodeUnspsc190501.26101713'),
	'identifierSchemeCodeUnspsc190501.26101715': __('identifierSchemeCodeUnspsc190501.26101715'),
	'identifierSchemeCodeUnspsc190501.26101716': __('identifierSchemeCodeUnspsc190501.26101716'),
	'identifierSchemeCodeUnspsc190501.26101717': __('identifierSchemeCodeUnspsc190501.26101717'),
	'identifierSchemeCodeUnspsc190501.26101718': __('identifierSchemeCodeUnspsc190501.26101718'),
	'identifierSchemeCodeUnspsc190501.26101719': __('identifierSchemeCodeUnspsc190501.26101719'),
	'identifierSchemeCodeUnspsc190501.26101720': __('identifierSchemeCodeUnspsc190501.26101720'),
	'identifierSchemeCodeUnspsc190501.26101721': __('identifierSchemeCodeUnspsc190501.26101721'),
	'identifierSchemeCodeUnspsc190501.26101723': __('identifierSchemeCodeUnspsc190501.26101723'),
	'identifierSchemeCodeUnspsc190501.26101724': __('identifierSchemeCodeUnspsc190501.26101724'),
	'identifierSchemeCodeUnspsc190501.26101725': __('identifierSchemeCodeUnspsc190501.26101725'),
	'identifierSchemeCodeUnspsc190501.26101726': __('identifierSchemeCodeUnspsc190501.26101726'),
	'identifierSchemeCodeUnspsc190501.26101727': __('identifierSchemeCodeUnspsc190501.26101727'),
	'identifierSchemeCodeUnspsc190501.26101728': __('identifierSchemeCodeUnspsc190501.26101728'),
	'identifierSchemeCodeUnspsc190501.26101729': __('identifierSchemeCodeUnspsc190501.26101729'),
	'identifierSchemeCodeUnspsc190501.26101730': __('identifierSchemeCodeUnspsc190501.26101730'),
	'identifierSchemeCodeUnspsc190501.26101731': __('identifierSchemeCodeUnspsc190501.26101731'),
	'identifierSchemeCodeUnspsc190501.26101732': __('identifierSchemeCodeUnspsc190501.26101732'),
	'identifierSchemeCodeUnspsc190501.26101733': __('identifierSchemeCodeUnspsc190501.26101733'),
	'identifierSchemeCodeUnspsc190501.26101734': __('identifierSchemeCodeUnspsc190501.26101734'),
	'identifierSchemeCodeUnspsc190501.26101735': __('identifierSchemeCodeUnspsc190501.26101735'),
	'identifierSchemeCodeUnspsc190501.26101736': __('identifierSchemeCodeUnspsc190501.26101736'),
	'identifierSchemeCodeUnspsc190501.26101737': __('identifierSchemeCodeUnspsc190501.26101737'),
	'identifierSchemeCodeUnspsc190501.26101738': __('identifierSchemeCodeUnspsc190501.26101738'),
	'identifierSchemeCodeUnspsc190501.26101740': __('identifierSchemeCodeUnspsc190501.26101740'),
	'identifierSchemeCodeUnspsc190501.26101741': __('identifierSchemeCodeUnspsc190501.26101741'),
	'identifierSchemeCodeUnspsc190501.26101742': __('identifierSchemeCodeUnspsc190501.26101742'),
	'identifierSchemeCodeUnspsc190501.26101743': __('identifierSchemeCodeUnspsc190501.26101743'),
	'identifierSchemeCodeUnspsc190501.26101747': __('identifierSchemeCodeUnspsc190501.26101747'),
	'identifierSchemeCodeUnspsc190501.26101748': __('identifierSchemeCodeUnspsc190501.26101748'),
	'identifierSchemeCodeUnspsc190501.26101749': __('identifierSchemeCodeUnspsc190501.26101749'),
	'identifierSchemeCodeUnspsc190501.26101750': __('identifierSchemeCodeUnspsc190501.26101750'),
	'identifierSchemeCodeUnspsc190501.26101751': __('identifierSchemeCodeUnspsc190501.26101751'),
	'identifierSchemeCodeUnspsc190501.26101754': __('identifierSchemeCodeUnspsc190501.26101754'),
	'identifierSchemeCodeUnspsc190501.26101755': __('identifierSchemeCodeUnspsc190501.26101755'),
	'identifierSchemeCodeUnspsc190501.26101756': __('identifierSchemeCodeUnspsc190501.26101756'),
	'identifierSchemeCodeUnspsc190501.26101757': __('identifierSchemeCodeUnspsc190501.26101757'),
	'identifierSchemeCodeUnspsc190501.26101758': __('identifierSchemeCodeUnspsc190501.26101758'),
	'identifierSchemeCodeUnspsc190501.26101759': __('identifierSchemeCodeUnspsc190501.26101759'),
	'identifierSchemeCodeUnspsc190501.26101760': __('identifierSchemeCodeUnspsc190501.26101760'),
	'identifierSchemeCodeUnspsc190501.26101761': __('identifierSchemeCodeUnspsc190501.26101761'),
	'identifierSchemeCodeUnspsc190501.26101762': __('identifierSchemeCodeUnspsc190501.26101762'),
	'identifierSchemeCodeUnspsc190501.26101763': __('identifierSchemeCodeUnspsc190501.26101763'),
	'identifierSchemeCodeUnspsc190501.26101764': __('identifierSchemeCodeUnspsc190501.26101764'),
	'identifierSchemeCodeUnspsc190501.26101765': __('identifierSchemeCodeUnspsc190501.26101765'),
	'identifierSchemeCodeUnspsc190501.26101766': __('identifierSchemeCodeUnspsc190501.26101766'),
	'identifierSchemeCodeUnspsc190501.26101767': __('identifierSchemeCodeUnspsc190501.26101767'),
	'identifierSchemeCodeUnspsc190501.26101768': __('identifierSchemeCodeUnspsc190501.26101768'),
	'identifierSchemeCodeUnspsc190501.26101769': __('identifierSchemeCodeUnspsc190501.26101769'),
	'identifierSchemeCodeUnspsc190501.26101770': __('identifierSchemeCodeUnspsc190501.26101770'),
	'identifierSchemeCodeUnspsc190501.26101771': __('identifierSchemeCodeUnspsc190501.26101771'),
	'identifierSchemeCodeUnspsc190501.26101772': __('identifierSchemeCodeUnspsc190501.26101772'),
	'identifierSchemeCodeUnspsc190501.26101773': __('identifierSchemeCodeUnspsc190501.26101773'),
	'identifierSchemeCodeUnspsc190501.26101774': __('identifierSchemeCodeUnspsc190501.26101774'),
	'identifierSchemeCodeUnspsc190501.26101775': __('identifierSchemeCodeUnspsc190501.26101775'),
	'identifierSchemeCodeUnspsc190501.26101776': __('identifierSchemeCodeUnspsc190501.26101776'),
	'identifierSchemeCodeUnspsc190501.26101777': __('identifierSchemeCodeUnspsc190501.26101777'),
	'identifierSchemeCodeUnspsc190501.26101778': __('identifierSchemeCodeUnspsc190501.26101778'),
	'identifierSchemeCodeUnspsc190501.26101779': __('identifierSchemeCodeUnspsc190501.26101779'),
	'identifierSchemeCodeUnspsc190501.26101780': __('identifierSchemeCodeUnspsc190501.26101780'),
	'identifierSchemeCodeUnspsc190501.26101781': __('identifierSchemeCodeUnspsc190501.26101781'),
	'identifierSchemeCodeUnspsc190501.26101782': __('identifierSchemeCodeUnspsc190501.26101782'),
	'identifierSchemeCodeUnspsc190501.26101783': __('identifierSchemeCodeUnspsc190501.26101783'),
	'identifierSchemeCodeUnspsc190501.26101784': __('identifierSchemeCodeUnspsc190501.26101784'),
	'identifierSchemeCodeUnspsc190501.26101785': __('identifierSchemeCodeUnspsc190501.26101785'),
	'identifierSchemeCodeUnspsc190501.26101786': __('identifierSchemeCodeUnspsc190501.26101786'),
	'identifierSchemeCodeUnspsc190501.26101787': __('identifierSchemeCodeUnspsc190501.26101787'),
	'identifierSchemeCodeUnspsc190501.26101788': __('identifierSchemeCodeUnspsc190501.26101788'),
	'identifierSchemeCodeUnspsc190501.26101900': __('identifierSchemeCodeUnspsc190501.26101900'),
	'identifierSchemeCodeUnspsc190501.26101903': __('identifierSchemeCodeUnspsc190501.26101903'),
	'identifierSchemeCodeUnspsc190501.26101904': __('identifierSchemeCodeUnspsc190501.26101904'),
	'identifierSchemeCodeUnspsc190501.26101905': __('identifierSchemeCodeUnspsc190501.26101905'),
	'identifierSchemeCodeUnspsc190501.26110000': __('identifierSchemeCodeUnspsc190501.26110000'),
	'identifierSchemeCodeUnspsc190501.26111500': __('identifierSchemeCodeUnspsc190501.26111500'),
	'identifierSchemeCodeUnspsc190501.26111503': __('identifierSchemeCodeUnspsc190501.26111503'),
	'identifierSchemeCodeUnspsc190501.26111504': __('identifierSchemeCodeUnspsc190501.26111504'),
	'identifierSchemeCodeUnspsc190501.26111505': __('identifierSchemeCodeUnspsc190501.26111505'),
	'identifierSchemeCodeUnspsc190501.26111506': __('identifierSchemeCodeUnspsc190501.26111506'),
	'identifierSchemeCodeUnspsc190501.26111508': __('identifierSchemeCodeUnspsc190501.26111508'),
	'identifierSchemeCodeUnspsc190501.26111509': __('identifierSchemeCodeUnspsc190501.26111509'),
	'identifierSchemeCodeUnspsc190501.26111510': __('identifierSchemeCodeUnspsc190501.26111510'),
	'identifierSchemeCodeUnspsc190501.26111512': __('identifierSchemeCodeUnspsc190501.26111512'),
	'identifierSchemeCodeUnspsc190501.26111513': __('identifierSchemeCodeUnspsc190501.26111513'),
	'identifierSchemeCodeUnspsc190501.26111514': __('identifierSchemeCodeUnspsc190501.26111514'),
	'identifierSchemeCodeUnspsc190501.26111515': __('identifierSchemeCodeUnspsc190501.26111515'),
	'identifierSchemeCodeUnspsc190501.26111516': __('identifierSchemeCodeUnspsc190501.26111516'),
	'identifierSchemeCodeUnspsc190501.26111517': __('identifierSchemeCodeUnspsc190501.26111517'),
	'identifierSchemeCodeUnspsc190501.26111518': __('identifierSchemeCodeUnspsc190501.26111518'),
	'identifierSchemeCodeUnspsc190501.26111519': __('identifierSchemeCodeUnspsc190501.26111519'),
	'identifierSchemeCodeUnspsc190501.26111520': __('identifierSchemeCodeUnspsc190501.26111520'),
	'identifierSchemeCodeUnspsc190501.26111521': __('identifierSchemeCodeUnspsc190501.26111521'),
	'identifierSchemeCodeUnspsc190501.26111522': __('identifierSchemeCodeUnspsc190501.26111522'),
	'identifierSchemeCodeUnspsc190501.26111523': __('identifierSchemeCodeUnspsc190501.26111523'),
	'identifierSchemeCodeUnspsc190501.26111524': __('identifierSchemeCodeUnspsc190501.26111524'),
	'identifierSchemeCodeUnspsc190501.26111525': __('identifierSchemeCodeUnspsc190501.26111525'),
	'identifierSchemeCodeUnspsc190501.26111527': __('identifierSchemeCodeUnspsc190501.26111527'),
	'identifierSchemeCodeUnspsc190501.26111528': __('identifierSchemeCodeUnspsc190501.26111528'),
	'identifierSchemeCodeUnspsc190501.26111529': __('identifierSchemeCodeUnspsc190501.26111529'),
	'identifierSchemeCodeUnspsc190501.26111530': __('identifierSchemeCodeUnspsc190501.26111530'),
	'identifierSchemeCodeUnspsc190501.26111531': __('identifierSchemeCodeUnspsc190501.26111531'),
	'identifierSchemeCodeUnspsc190501.26111532': __('identifierSchemeCodeUnspsc190501.26111532'),
	'identifierSchemeCodeUnspsc190501.26111533': __('identifierSchemeCodeUnspsc190501.26111533'),
	'identifierSchemeCodeUnspsc190501.26111534': __('identifierSchemeCodeUnspsc190501.26111534'),
	'identifierSchemeCodeUnspsc190501.26111535': __('identifierSchemeCodeUnspsc190501.26111535'),
	'identifierSchemeCodeUnspsc190501.26111536': __('identifierSchemeCodeUnspsc190501.26111536'),
	'identifierSchemeCodeUnspsc190501.26111537': __('identifierSchemeCodeUnspsc190501.26111537'),
	'identifierSchemeCodeUnspsc190501.26111538': __('identifierSchemeCodeUnspsc190501.26111538'),
	'identifierSchemeCodeUnspsc190501.26111539': __('identifierSchemeCodeUnspsc190501.26111539'),
	'identifierSchemeCodeUnspsc190501.26111540': __('identifierSchemeCodeUnspsc190501.26111540'),
	'identifierSchemeCodeUnspsc190501.26111541': __('identifierSchemeCodeUnspsc190501.26111541'),
	'identifierSchemeCodeUnspsc190501.26111542': __('identifierSchemeCodeUnspsc190501.26111542'),
	'identifierSchemeCodeUnspsc190501.26111543': __('identifierSchemeCodeUnspsc190501.26111543'),
	'identifierSchemeCodeUnspsc190501.26111544': __('identifierSchemeCodeUnspsc190501.26111544'),
	'identifierSchemeCodeUnspsc190501.26111545': __('identifierSchemeCodeUnspsc190501.26111545'),
	'identifierSchemeCodeUnspsc190501.26111546': __('identifierSchemeCodeUnspsc190501.26111546'),
	'identifierSchemeCodeUnspsc190501.26111547': __('identifierSchemeCodeUnspsc190501.26111547'),
	'identifierSchemeCodeUnspsc190501.26111548': __('identifierSchemeCodeUnspsc190501.26111548'),
	'identifierSchemeCodeUnspsc190501.26111549': __('identifierSchemeCodeUnspsc190501.26111549'),
	'identifierSchemeCodeUnspsc190501.26111550': __('identifierSchemeCodeUnspsc190501.26111550'),
	'identifierSchemeCodeUnspsc190501.26111600': __('identifierSchemeCodeUnspsc190501.26111600'),
	'identifierSchemeCodeUnspsc190501.26111601': __('identifierSchemeCodeUnspsc190501.26111601'),
	'identifierSchemeCodeUnspsc190501.26111602': __('identifierSchemeCodeUnspsc190501.26111602'),
	'identifierSchemeCodeUnspsc190501.26111603': __('identifierSchemeCodeUnspsc190501.26111603'),
	'identifierSchemeCodeUnspsc190501.26111604': __('identifierSchemeCodeUnspsc190501.26111604'),
	'identifierSchemeCodeUnspsc190501.26111605': __('identifierSchemeCodeUnspsc190501.26111605'),
	'identifierSchemeCodeUnspsc190501.26111606': __('identifierSchemeCodeUnspsc190501.26111606'),
	'identifierSchemeCodeUnspsc190501.26111607': __('identifierSchemeCodeUnspsc190501.26111607'),
	'identifierSchemeCodeUnspsc190501.26111608': __('identifierSchemeCodeUnspsc190501.26111608'),
	'identifierSchemeCodeUnspsc190501.26111609': __('identifierSchemeCodeUnspsc190501.26111609'),
	'identifierSchemeCodeUnspsc190501.26111610': __('identifierSchemeCodeUnspsc190501.26111610'),
	'identifierSchemeCodeUnspsc190501.26111611': __('identifierSchemeCodeUnspsc190501.26111611'),
	'identifierSchemeCodeUnspsc190501.26111612': __('identifierSchemeCodeUnspsc190501.26111612'),
	'identifierSchemeCodeUnspsc190501.26111613': __('identifierSchemeCodeUnspsc190501.26111613'),
	'identifierSchemeCodeUnspsc190501.26111700': __('identifierSchemeCodeUnspsc190501.26111700'),
	'identifierSchemeCodeUnspsc190501.26111701': __('identifierSchemeCodeUnspsc190501.26111701'),
	'identifierSchemeCodeUnspsc190501.26111702': __('identifierSchemeCodeUnspsc190501.26111702'),
	'identifierSchemeCodeUnspsc190501.26111703': __('identifierSchemeCodeUnspsc190501.26111703'),
	'identifierSchemeCodeUnspsc190501.26111704': __('identifierSchemeCodeUnspsc190501.26111704'),
	'identifierSchemeCodeUnspsc190501.26111705': __('identifierSchemeCodeUnspsc190501.26111705'),
	'identifierSchemeCodeUnspsc190501.26111706': __('identifierSchemeCodeUnspsc190501.26111706'),
	'identifierSchemeCodeUnspsc190501.26111707': __('identifierSchemeCodeUnspsc190501.26111707'),
	'identifierSchemeCodeUnspsc190501.26111708': __('identifierSchemeCodeUnspsc190501.26111708'),
	'identifierSchemeCodeUnspsc190501.26111709': __('identifierSchemeCodeUnspsc190501.26111709'),
	'identifierSchemeCodeUnspsc190501.26111710': __('identifierSchemeCodeUnspsc190501.26111710'),
	'identifierSchemeCodeUnspsc190501.26111711': __('identifierSchemeCodeUnspsc190501.26111711'),
	'identifierSchemeCodeUnspsc190501.26111712': __('identifierSchemeCodeUnspsc190501.26111712'),
	'identifierSchemeCodeUnspsc190501.26111713': __('identifierSchemeCodeUnspsc190501.26111713'),
	'identifierSchemeCodeUnspsc190501.26111714': __('identifierSchemeCodeUnspsc190501.26111714'),
	'identifierSchemeCodeUnspsc190501.26111715': __('identifierSchemeCodeUnspsc190501.26111715'),
	'identifierSchemeCodeUnspsc190501.26111716': __('identifierSchemeCodeUnspsc190501.26111716'),
	'identifierSchemeCodeUnspsc190501.26111717': __('identifierSchemeCodeUnspsc190501.26111717'),
	'identifierSchemeCodeUnspsc190501.26111718': __('identifierSchemeCodeUnspsc190501.26111718'),
	'identifierSchemeCodeUnspsc190501.26111719': __('identifierSchemeCodeUnspsc190501.26111719'),
	'identifierSchemeCodeUnspsc190501.26111720': __('identifierSchemeCodeUnspsc190501.26111720'),
	'identifierSchemeCodeUnspsc190501.26111721': __('identifierSchemeCodeUnspsc190501.26111721'),
	'identifierSchemeCodeUnspsc190501.26111722': __('identifierSchemeCodeUnspsc190501.26111722'),
	'identifierSchemeCodeUnspsc190501.26111723': __('identifierSchemeCodeUnspsc190501.26111723'),
	'identifierSchemeCodeUnspsc190501.26111724': __('identifierSchemeCodeUnspsc190501.26111724'),
	'identifierSchemeCodeUnspsc190501.26111725': __('identifierSchemeCodeUnspsc190501.26111725'),
	'identifierSchemeCodeUnspsc190501.26111726': __('identifierSchemeCodeUnspsc190501.26111726'),
	'identifierSchemeCodeUnspsc190501.26111727': __('identifierSchemeCodeUnspsc190501.26111727'),
	'identifierSchemeCodeUnspsc190501.26111728': __('identifierSchemeCodeUnspsc190501.26111728'),
	'identifierSchemeCodeUnspsc190501.26111729': __('identifierSchemeCodeUnspsc190501.26111729'),
	'identifierSchemeCodeUnspsc190501.26111800': __('identifierSchemeCodeUnspsc190501.26111800'),
	'identifierSchemeCodeUnspsc190501.26111801': __('identifierSchemeCodeUnspsc190501.26111801'),
	'identifierSchemeCodeUnspsc190501.26111802': __('identifierSchemeCodeUnspsc190501.26111802'),
	'identifierSchemeCodeUnspsc190501.26111803': __('identifierSchemeCodeUnspsc190501.26111803'),
	'identifierSchemeCodeUnspsc190501.26111804': __('identifierSchemeCodeUnspsc190501.26111804'),
	'identifierSchemeCodeUnspsc190501.26111805': __('identifierSchemeCodeUnspsc190501.26111805'),
	'identifierSchemeCodeUnspsc190501.26111806': __('identifierSchemeCodeUnspsc190501.26111806'),
	'identifierSchemeCodeUnspsc190501.26111807': __('identifierSchemeCodeUnspsc190501.26111807'),
	'identifierSchemeCodeUnspsc190501.26111808': __('identifierSchemeCodeUnspsc190501.26111808'),
	'identifierSchemeCodeUnspsc190501.26111809': __('identifierSchemeCodeUnspsc190501.26111809'),
	'identifierSchemeCodeUnspsc190501.26111810': __('identifierSchemeCodeUnspsc190501.26111810'),
	'identifierSchemeCodeUnspsc190501.26111811': __('identifierSchemeCodeUnspsc190501.26111811'),
	'identifierSchemeCodeUnspsc190501.26111812': __('identifierSchemeCodeUnspsc190501.26111812'),
	'identifierSchemeCodeUnspsc190501.26111813': __('identifierSchemeCodeUnspsc190501.26111813'),
	'identifierSchemeCodeUnspsc190501.26111814': __('identifierSchemeCodeUnspsc190501.26111814'),
	'identifierSchemeCodeUnspsc190501.26111815': __('identifierSchemeCodeUnspsc190501.26111815'),
	'identifierSchemeCodeUnspsc190501.26111816': __('identifierSchemeCodeUnspsc190501.26111816'),
	'identifierSchemeCodeUnspsc190501.26111900': __('identifierSchemeCodeUnspsc190501.26111900'),
	'identifierSchemeCodeUnspsc190501.26111901': __('identifierSchemeCodeUnspsc190501.26111901'),
	'identifierSchemeCodeUnspsc190501.26111902': __('identifierSchemeCodeUnspsc190501.26111902'),
	'identifierSchemeCodeUnspsc190501.26111903': __('identifierSchemeCodeUnspsc190501.26111903'),
	'identifierSchemeCodeUnspsc190501.26111904': __('identifierSchemeCodeUnspsc190501.26111904'),
	'identifierSchemeCodeUnspsc190501.26111905': __('identifierSchemeCodeUnspsc190501.26111905'),
	'identifierSchemeCodeUnspsc190501.26111907': __('identifierSchemeCodeUnspsc190501.26111907'),
	'identifierSchemeCodeUnspsc190501.26111908': __('identifierSchemeCodeUnspsc190501.26111908'),
	'identifierSchemeCodeUnspsc190501.26111909': __('identifierSchemeCodeUnspsc190501.26111909'),
	'identifierSchemeCodeUnspsc190501.26111910': __('identifierSchemeCodeUnspsc190501.26111910'),
	'identifierSchemeCodeUnspsc190501.26111911': __('identifierSchemeCodeUnspsc190501.26111911'),
	'identifierSchemeCodeUnspsc190501.26111912': __('identifierSchemeCodeUnspsc190501.26111912'),
	'identifierSchemeCodeUnspsc190501.26111913': __('identifierSchemeCodeUnspsc190501.26111913'),
	'identifierSchemeCodeUnspsc190501.26111914': __('identifierSchemeCodeUnspsc190501.26111914'),
	'identifierSchemeCodeUnspsc190501.26111915': __('identifierSchemeCodeUnspsc190501.26111915'),
	'identifierSchemeCodeUnspsc190501.26111916': __('identifierSchemeCodeUnspsc190501.26111916'),
	'identifierSchemeCodeUnspsc190501.26111917': __('identifierSchemeCodeUnspsc190501.26111917'),
	'identifierSchemeCodeUnspsc190501.26112000': __('identifierSchemeCodeUnspsc190501.26112000'),
	'identifierSchemeCodeUnspsc190501.26112001': __('identifierSchemeCodeUnspsc190501.26112001'),
	'identifierSchemeCodeUnspsc190501.26112002': __('identifierSchemeCodeUnspsc190501.26112002'),
	'identifierSchemeCodeUnspsc190501.26112003': __('identifierSchemeCodeUnspsc190501.26112003'),
	'identifierSchemeCodeUnspsc190501.26112004': __('identifierSchemeCodeUnspsc190501.26112004'),
	'identifierSchemeCodeUnspsc190501.26112005': __('identifierSchemeCodeUnspsc190501.26112005'),
	'identifierSchemeCodeUnspsc190501.26112006': __('identifierSchemeCodeUnspsc190501.26112006'),
	'identifierSchemeCodeUnspsc190501.26112100': __('identifierSchemeCodeUnspsc190501.26112100'),
	'identifierSchemeCodeUnspsc190501.26112101': __('identifierSchemeCodeUnspsc190501.26112101'),
	'identifierSchemeCodeUnspsc190501.26112102': __('identifierSchemeCodeUnspsc190501.26112102'),
	'identifierSchemeCodeUnspsc190501.26112103': __('identifierSchemeCodeUnspsc190501.26112103'),
	'identifierSchemeCodeUnspsc190501.26112104': __('identifierSchemeCodeUnspsc190501.26112104'),
	'identifierSchemeCodeUnspsc190501.26112105': __('identifierSchemeCodeUnspsc190501.26112105'),
	'identifierSchemeCodeUnspsc190501.26120000': __('identifierSchemeCodeUnspsc190501.26120000'),
	'identifierSchemeCodeUnspsc190501.26121500': __('identifierSchemeCodeUnspsc190501.26121500'),
	'identifierSchemeCodeUnspsc190501.26121501': __('identifierSchemeCodeUnspsc190501.26121501'),
	'identifierSchemeCodeUnspsc190501.26121505': __('identifierSchemeCodeUnspsc190501.26121505'),
	'identifierSchemeCodeUnspsc190501.26121507': __('identifierSchemeCodeUnspsc190501.26121507'),
	'identifierSchemeCodeUnspsc190501.26121508': __('identifierSchemeCodeUnspsc190501.26121508'),
	'identifierSchemeCodeUnspsc190501.26121509': __('identifierSchemeCodeUnspsc190501.26121509'),
	'identifierSchemeCodeUnspsc190501.26121510': __('identifierSchemeCodeUnspsc190501.26121510'),
	'identifierSchemeCodeUnspsc190501.26121514': __('identifierSchemeCodeUnspsc190501.26121514'),
	'identifierSchemeCodeUnspsc190501.26121515': __('identifierSchemeCodeUnspsc190501.26121515'),
	'identifierSchemeCodeUnspsc190501.26121517': __('identifierSchemeCodeUnspsc190501.26121517'),
	'identifierSchemeCodeUnspsc190501.26121519': __('identifierSchemeCodeUnspsc190501.26121519'),
	'identifierSchemeCodeUnspsc190501.26121520': __('identifierSchemeCodeUnspsc190501.26121520'),
	'identifierSchemeCodeUnspsc190501.26121521': __('identifierSchemeCodeUnspsc190501.26121521'),
	'identifierSchemeCodeUnspsc190501.26121522': __('identifierSchemeCodeUnspsc190501.26121522'),
	'identifierSchemeCodeUnspsc190501.26121523': __('identifierSchemeCodeUnspsc190501.26121523'),
	'identifierSchemeCodeUnspsc190501.26121524': __('identifierSchemeCodeUnspsc190501.26121524'),
	'identifierSchemeCodeUnspsc190501.26121532': __('identifierSchemeCodeUnspsc190501.26121532'),
	'identifierSchemeCodeUnspsc190501.26121533': __('identifierSchemeCodeUnspsc190501.26121533'),
	'identifierSchemeCodeUnspsc190501.26121534': __('identifierSchemeCodeUnspsc190501.26121534'),
	'identifierSchemeCodeUnspsc190501.26121536': __('identifierSchemeCodeUnspsc190501.26121536'),
	'identifierSchemeCodeUnspsc190501.26121538': __('identifierSchemeCodeUnspsc190501.26121538'),
	'identifierSchemeCodeUnspsc190501.26121539': __('identifierSchemeCodeUnspsc190501.26121539'),
	'identifierSchemeCodeUnspsc190501.26121540': __('identifierSchemeCodeUnspsc190501.26121540'),
	'identifierSchemeCodeUnspsc190501.26121541': __('identifierSchemeCodeUnspsc190501.26121541'),
	'identifierSchemeCodeUnspsc190501.26121542': __('identifierSchemeCodeUnspsc190501.26121542'),
	'identifierSchemeCodeUnspsc190501.26121543': __('identifierSchemeCodeUnspsc190501.26121543'),
	'identifierSchemeCodeUnspsc190501.26121544': __('identifierSchemeCodeUnspsc190501.26121544'),
	'identifierSchemeCodeUnspsc190501.26121545': __('identifierSchemeCodeUnspsc190501.26121545'),
	'identifierSchemeCodeUnspsc190501.26121546': __('identifierSchemeCodeUnspsc190501.26121546'),
	'identifierSchemeCodeUnspsc190501.26121547': __('identifierSchemeCodeUnspsc190501.26121547'),
	'identifierSchemeCodeUnspsc190501.26121548': __('identifierSchemeCodeUnspsc190501.26121548'),
	'identifierSchemeCodeUnspsc190501.26121549': __('identifierSchemeCodeUnspsc190501.26121549'),
	'identifierSchemeCodeUnspsc190501.26121600': __('identifierSchemeCodeUnspsc190501.26121600'),
	'identifierSchemeCodeUnspsc190501.26121601': __('identifierSchemeCodeUnspsc190501.26121601'),
	'identifierSchemeCodeUnspsc190501.26121602': __('identifierSchemeCodeUnspsc190501.26121602'),
	'identifierSchemeCodeUnspsc190501.26121603': __('identifierSchemeCodeUnspsc190501.26121603'),
	'identifierSchemeCodeUnspsc190501.26121604': __('identifierSchemeCodeUnspsc190501.26121604'),
	'identifierSchemeCodeUnspsc190501.26121606': __('identifierSchemeCodeUnspsc190501.26121606'),
	'identifierSchemeCodeUnspsc190501.26121607': __('identifierSchemeCodeUnspsc190501.26121607'),
	'identifierSchemeCodeUnspsc190501.26121608': __('identifierSchemeCodeUnspsc190501.26121608'),
	'identifierSchemeCodeUnspsc190501.26121609': __('identifierSchemeCodeUnspsc190501.26121609'),
	'identifierSchemeCodeUnspsc190501.26121610': __('identifierSchemeCodeUnspsc190501.26121610'),
	'identifierSchemeCodeUnspsc190501.26121611': __('identifierSchemeCodeUnspsc190501.26121611'),
	'identifierSchemeCodeUnspsc190501.26121612': __('identifierSchemeCodeUnspsc190501.26121612'),
	'identifierSchemeCodeUnspsc190501.26121613': __('identifierSchemeCodeUnspsc190501.26121613'),
	'identifierSchemeCodeUnspsc190501.26121614': __('identifierSchemeCodeUnspsc190501.26121614'),
	'identifierSchemeCodeUnspsc190501.26121615': __('identifierSchemeCodeUnspsc190501.26121615'),
	'identifierSchemeCodeUnspsc190501.26121616': __('identifierSchemeCodeUnspsc190501.26121616'),
	'identifierSchemeCodeUnspsc190501.26121617': __('identifierSchemeCodeUnspsc190501.26121617'),
	'identifierSchemeCodeUnspsc190501.26121618': __('identifierSchemeCodeUnspsc190501.26121618'),
	'identifierSchemeCodeUnspsc190501.26121619': __('identifierSchemeCodeUnspsc190501.26121619'),
	'identifierSchemeCodeUnspsc190501.26121620': __('identifierSchemeCodeUnspsc190501.26121620'),
	'identifierSchemeCodeUnspsc190501.26121621': __('identifierSchemeCodeUnspsc190501.26121621'),
	'identifierSchemeCodeUnspsc190501.26121622': __('identifierSchemeCodeUnspsc190501.26121622'),
	'identifierSchemeCodeUnspsc190501.26121623': __('identifierSchemeCodeUnspsc190501.26121623'),
	'identifierSchemeCodeUnspsc190501.26121624': __('identifierSchemeCodeUnspsc190501.26121624'),
	'identifierSchemeCodeUnspsc190501.26121628': __('identifierSchemeCodeUnspsc190501.26121628'),
	'identifierSchemeCodeUnspsc190501.26121629': __('identifierSchemeCodeUnspsc190501.26121629'),
	'identifierSchemeCodeUnspsc190501.26121630': __('identifierSchemeCodeUnspsc190501.26121630'),
	'identifierSchemeCodeUnspsc190501.26121631': __('identifierSchemeCodeUnspsc190501.26121631'),
	'identifierSchemeCodeUnspsc190501.26121632': __('identifierSchemeCodeUnspsc190501.26121632'),
	'identifierSchemeCodeUnspsc190501.26121633': __('identifierSchemeCodeUnspsc190501.26121633'),
	'identifierSchemeCodeUnspsc190501.26121634': __('identifierSchemeCodeUnspsc190501.26121634'),
	'identifierSchemeCodeUnspsc190501.26121635': __('identifierSchemeCodeUnspsc190501.26121635'),
	'identifierSchemeCodeUnspsc190501.26121636': __('identifierSchemeCodeUnspsc190501.26121636'),
	'identifierSchemeCodeUnspsc190501.26121637': __('identifierSchemeCodeUnspsc190501.26121637'),
	'identifierSchemeCodeUnspsc190501.26121638': __('identifierSchemeCodeUnspsc190501.26121638'),
	'identifierSchemeCodeUnspsc190501.26121639': __('identifierSchemeCodeUnspsc190501.26121639'),
	'identifierSchemeCodeUnspsc190501.26121640': __('identifierSchemeCodeUnspsc190501.26121640'),
	'identifierSchemeCodeUnspsc190501.26121641': __('identifierSchemeCodeUnspsc190501.26121641'),
	'identifierSchemeCodeUnspsc190501.26121642': __('identifierSchemeCodeUnspsc190501.26121642'),
	'identifierSchemeCodeUnspsc190501.26121643': __('identifierSchemeCodeUnspsc190501.26121643'),
	'identifierSchemeCodeUnspsc190501.26121644': __('identifierSchemeCodeUnspsc190501.26121644'),
	'identifierSchemeCodeUnspsc190501.26121645': __('identifierSchemeCodeUnspsc190501.26121645'),
	'identifierSchemeCodeUnspsc190501.26121646': __('identifierSchemeCodeUnspsc190501.26121646'),
	'identifierSchemeCodeUnspsc190501.26121647': __('identifierSchemeCodeUnspsc190501.26121647'),
	'identifierSchemeCodeUnspsc190501.26121648': __('identifierSchemeCodeUnspsc190501.26121648'),
	'identifierSchemeCodeUnspsc190501.26121649': __('identifierSchemeCodeUnspsc190501.26121649'),
	'identifierSchemeCodeUnspsc190501.26121650': __('identifierSchemeCodeUnspsc190501.26121650'),
	'identifierSchemeCodeUnspsc190501.26121651': __('identifierSchemeCodeUnspsc190501.26121651'),
	'identifierSchemeCodeUnspsc190501.26121652': __('identifierSchemeCodeUnspsc190501.26121652'),
	'identifierSchemeCodeUnspsc190501.26121653': __('identifierSchemeCodeUnspsc190501.26121653'),
	'identifierSchemeCodeUnspsc190501.26121654': __('identifierSchemeCodeUnspsc190501.26121654'),
	'identifierSchemeCodeUnspsc190501.26121655': __('identifierSchemeCodeUnspsc190501.26121655'),
	'identifierSchemeCodeUnspsc190501.26121656': __('identifierSchemeCodeUnspsc190501.26121656'),
	'identifierSchemeCodeUnspsc190501.26121657': __('identifierSchemeCodeUnspsc190501.26121657'),
	'identifierSchemeCodeUnspsc190501.26121658': __('identifierSchemeCodeUnspsc190501.26121658'),
	'identifierSchemeCodeUnspsc190501.26121659': __('identifierSchemeCodeUnspsc190501.26121659'),
	'identifierSchemeCodeUnspsc190501.26121660': __('identifierSchemeCodeUnspsc190501.26121660'),
	'identifierSchemeCodeUnspsc190501.26121661': __('identifierSchemeCodeUnspsc190501.26121661'),
	'identifierSchemeCodeUnspsc190501.26121662': __('identifierSchemeCodeUnspsc190501.26121662'),
	'identifierSchemeCodeUnspsc190501.26121663': __('identifierSchemeCodeUnspsc190501.26121663'),
	'identifierSchemeCodeUnspsc190501.26121664': __('identifierSchemeCodeUnspsc190501.26121664'),
	'identifierSchemeCodeUnspsc190501.26121665': __('identifierSchemeCodeUnspsc190501.26121665'),
	'identifierSchemeCodeUnspsc190501.26121666': __('identifierSchemeCodeUnspsc190501.26121666'),
	'identifierSchemeCodeUnspsc190501.26121667': __('identifierSchemeCodeUnspsc190501.26121667'),
	'identifierSchemeCodeUnspsc190501.26121668': __('identifierSchemeCodeUnspsc190501.26121668'),
	'identifierSchemeCodeUnspsc190501.26121700': __('identifierSchemeCodeUnspsc190501.26121700'),
	'identifierSchemeCodeUnspsc190501.26121701': __('identifierSchemeCodeUnspsc190501.26121701'),
	'identifierSchemeCodeUnspsc190501.26121702': __('identifierSchemeCodeUnspsc190501.26121702'),
	'identifierSchemeCodeUnspsc190501.26121703': __('identifierSchemeCodeUnspsc190501.26121703'),
	'identifierSchemeCodeUnspsc190501.26121704': __('identifierSchemeCodeUnspsc190501.26121704'),
	'identifierSchemeCodeUnspsc190501.26121706': __('identifierSchemeCodeUnspsc190501.26121706'),
	'identifierSchemeCodeUnspsc190501.26121707': __('identifierSchemeCodeUnspsc190501.26121707'),
	'identifierSchemeCodeUnspsc190501.26121708': __('identifierSchemeCodeUnspsc190501.26121708'),
	'identifierSchemeCodeUnspsc190501.26121709': __('identifierSchemeCodeUnspsc190501.26121709'),
	'identifierSchemeCodeUnspsc190501.26121710': __('identifierSchemeCodeUnspsc190501.26121710'),
	'identifierSchemeCodeUnspsc190501.26121800': __('identifierSchemeCodeUnspsc190501.26121800'),
	'identifierSchemeCodeUnspsc190501.26121802': __('identifierSchemeCodeUnspsc190501.26121802'),
	'identifierSchemeCodeUnspsc190501.26121803': __('identifierSchemeCodeUnspsc190501.26121803'),
	'identifierSchemeCodeUnspsc190501.26121804': __('identifierSchemeCodeUnspsc190501.26121804'),
	'identifierSchemeCodeUnspsc190501.26121805': __('identifierSchemeCodeUnspsc190501.26121805'),
	'identifierSchemeCodeUnspsc190501.26121806': __('identifierSchemeCodeUnspsc190501.26121806'),
	'identifierSchemeCodeUnspsc190501.26121807': __('identifierSchemeCodeUnspsc190501.26121807'),
	'identifierSchemeCodeUnspsc190501.26121808': __('identifierSchemeCodeUnspsc190501.26121808'),
	'identifierSchemeCodeUnspsc190501.26121809': __('identifierSchemeCodeUnspsc190501.26121809'),
	'identifierSchemeCodeUnspsc190501.26121810': __('identifierSchemeCodeUnspsc190501.26121810'),
	'identifierSchemeCodeUnspsc190501.26121811': __('identifierSchemeCodeUnspsc190501.26121811'),
	'identifierSchemeCodeUnspsc190501.26121812': __('identifierSchemeCodeUnspsc190501.26121812'),
	'identifierSchemeCodeUnspsc190501.26121813': __('identifierSchemeCodeUnspsc190501.26121813'),
	'identifierSchemeCodeUnspsc190501.26121814': __('identifierSchemeCodeUnspsc190501.26121814'),
	'identifierSchemeCodeUnspsc190501.26121815': __('identifierSchemeCodeUnspsc190501.26121815'),
	'identifierSchemeCodeUnspsc190501.26121816': __('identifierSchemeCodeUnspsc190501.26121816'),
	'identifierSchemeCodeUnspsc190501.26121817': __('identifierSchemeCodeUnspsc190501.26121817'),
	'identifierSchemeCodeUnspsc190501.26121818': __('identifierSchemeCodeUnspsc190501.26121818'),
	'identifierSchemeCodeUnspsc190501.26121819': __('identifierSchemeCodeUnspsc190501.26121819'),
	'identifierSchemeCodeUnspsc190501.26121820': __('identifierSchemeCodeUnspsc190501.26121820'),
	'identifierSchemeCodeUnspsc190501.26121821': __('identifierSchemeCodeUnspsc190501.26121821'),
	'identifierSchemeCodeUnspsc190501.26121822': __('identifierSchemeCodeUnspsc190501.26121822'),
	'identifierSchemeCodeUnspsc190501.26121823': __('identifierSchemeCodeUnspsc190501.26121823'),
	'identifierSchemeCodeUnspsc190501.26121824': __('identifierSchemeCodeUnspsc190501.26121824'),
	'identifierSchemeCodeUnspsc190501.26121825': __('identifierSchemeCodeUnspsc190501.26121825'),
	'identifierSchemeCodeUnspsc190501.26121826': __('identifierSchemeCodeUnspsc190501.26121826'),
	'identifierSchemeCodeUnspsc190501.26121827': __('identifierSchemeCodeUnspsc190501.26121827'),
	'identifierSchemeCodeUnspsc190501.26121828': __('identifierSchemeCodeUnspsc190501.26121828'),
	'identifierSchemeCodeUnspsc190501.26121829': __('identifierSchemeCodeUnspsc190501.26121829'),
	'identifierSchemeCodeUnspsc190501.26121830': __('identifierSchemeCodeUnspsc190501.26121830'),
	'identifierSchemeCodeUnspsc190501.26121831': __('identifierSchemeCodeUnspsc190501.26121831'),
	'identifierSchemeCodeUnspsc190501.26121832': __('identifierSchemeCodeUnspsc190501.26121832'),
	'identifierSchemeCodeUnspsc190501.26121833': __('identifierSchemeCodeUnspsc190501.26121833'),
	'identifierSchemeCodeUnspsc190501.26121834': __('identifierSchemeCodeUnspsc190501.26121834'),
	'identifierSchemeCodeUnspsc190501.26121835': __('identifierSchemeCodeUnspsc190501.26121835'),
	'identifierSchemeCodeUnspsc190501.26121836': __('identifierSchemeCodeUnspsc190501.26121836'),
	'identifierSchemeCodeUnspsc190501.26121837': __('identifierSchemeCodeUnspsc190501.26121837'),
	'identifierSchemeCodeUnspsc190501.26121838': __('identifierSchemeCodeUnspsc190501.26121838'),
	'identifierSchemeCodeUnspsc190501.26121839': __('identifierSchemeCodeUnspsc190501.26121839'),
	'identifierSchemeCodeUnspsc190501.26121840': __('identifierSchemeCodeUnspsc190501.26121840'),
	'identifierSchemeCodeUnspsc190501.26121841': __('identifierSchemeCodeUnspsc190501.26121841'),
	'identifierSchemeCodeUnspsc190501.26121842': __('identifierSchemeCodeUnspsc190501.26121842'),
	'identifierSchemeCodeUnspsc190501.26121843': __('identifierSchemeCodeUnspsc190501.26121843'),
	'identifierSchemeCodeUnspsc190501.26121844': __('identifierSchemeCodeUnspsc190501.26121844'),
	'identifierSchemeCodeUnspsc190501.26121845': __('identifierSchemeCodeUnspsc190501.26121845'),
	'identifierSchemeCodeUnspsc190501.26121846': __('identifierSchemeCodeUnspsc190501.26121846'),
	'identifierSchemeCodeUnspsc190501.26121847': __('identifierSchemeCodeUnspsc190501.26121847'),
	'identifierSchemeCodeUnspsc190501.26121848': __('identifierSchemeCodeUnspsc190501.26121848'),
	'identifierSchemeCodeUnspsc190501.26121849': __('identifierSchemeCodeUnspsc190501.26121849'),
	'identifierSchemeCodeUnspsc190501.26121850': __('identifierSchemeCodeUnspsc190501.26121850'),
	'identifierSchemeCodeUnspsc190501.26121851': __('identifierSchemeCodeUnspsc190501.26121851'),
	'identifierSchemeCodeUnspsc190501.26121852': __('identifierSchemeCodeUnspsc190501.26121852'),
	'identifierSchemeCodeUnspsc190501.26130000': __('identifierSchemeCodeUnspsc190501.26130000'),
	'identifierSchemeCodeUnspsc190501.26131500': __('identifierSchemeCodeUnspsc190501.26131500'),
	'identifierSchemeCodeUnspsc190501.26131501': __('identifierSchemeCodeUnspsc190501.26131501'),
	'identifierSchemeCodeUnspsc190501.26131502': __('identifierSchemeCodeUnspsc190501.26131502'),
	'identifierSchemeCodeUnspsc190501.26131503': __('identifierSchemeCodeUnspsc190501.26131503'),
	'identifierSchemeCodeUnspsc190501.26131504': __('identifierSchemeCodeUnspsc190501.26131504'),
	'identifierSchemeCodeUnspsc190501.26131505': __('identifierSchemeCodeUnspsc190501.26131505'),
	'identifierSchemeCodeUnspsc190501.26131506': __('identifierSchemeCodeUnspsc190501.26131506'),
	'identifierSchemeCodeUnspsc190501.26131507': __('identifierSchemeCodeUnspsc190501.26131507'),
	'identifierSchemeCodeUnspsc190501.26131508': __('identifierSchemeCodeUnspsc190501.26131508'),
	'identifierSchemeCodeUnspsc190501.26131509': __('identifierSchemeCodeUnspsc190501.26131509'),
	'identifierSchemeCodeUnspsc190501.26131510': __('identifierSchemeCodeUnspsc190501.26131510'),
	'identifierSchemeCodeUnspsc190501.26131600': __('identifierSchemeCodeUnspsc190501.26131600'),
	'identifierSchemeCodeUnspsc190501.26131601': __('identifierSchemeCodeUnspsc190501.26131601'),
	'identifierSchemeCodeUnspsc190501.26131602': __('identifierSchemeCodeUnspsc190501.26131602'),
	'identifierSchemeCodeUnspsc190501.26131603': __('identifierSchemeCodeUnspsc190501.26131603'),
	'identifierSchemeCodeUnspsc190501.26131604': __('identifierSchemeCodeUnspsc190501.26131604'),
	'identifierSchemeCodeUnspsc190501.26131605': __('identifierSchemeCodeUnspsc190501.26131605'),
	'identifierSchemeCodeUnspsc190501.26131606': __('identifierSchemeCodeUnspsc190501.26131606'),
	'identifierSchemeCodeUnspsc190501.26131607': __('identifierSchemeCodeUnspsc190501.26131607'),
	'identifierSchemeCodeUnspsc190501.26131608': __('identifierSchemeCodeUnspsc190501.26131608'),
	'identifierSchemeCodeUnspsc190501.26131609': __('identifierSchemeCodeUnspsc190501.26131609'),
	'identifierSchemeCodeUnspsc190501.26131610': __('identifierSchemeCodeUnspsc190501.26131610'),
	'identifierSchemeCodeUnspsc190501.26131611': __('identifierSchemeCodeUnspsc190501.26131611'),
	'identifierSchemeCodeUnspsc190501.26131612': __('identifierSchemeCodeUnspsc190501.26131612'),
	'identifierSchemeCodeUnspsc190501.26131613': __('identifierSchemeCodeUnspsc190501.26131613'),
	'identifierSchemeCodeUnspsc190501.26131614': __('identifierSchemeCodeUnspsc190501.26131614'),
	'identifierSchemeCodeUnspsc190501.26131615': __('identifierSchemeCodeUnspsc190501.26131615'),
	'identifierSchemeCodeUnspsc190501.26131616': __('identifierSchemeCodeUnspsc190501.26131616'),
	'identifierSchemeCodeUnspsc190501.26131617': __('identifierSchemeCodeUnspsc190501.26131617'),
	'identifierSchemeCodeUnspsc190501.26131618': __('identifierSchemeCodeUnspsc190501.26131618'),
	'identifierSchemeCodeUnspsc190501.26131700': __('identifierSchemeCodeUnspsc190501.26131700'),
	'identifierSchemeCodeUnspsc190501.26131701': __('identifierSchemeCodeUnspsc190501.26131701'),
	'identifierSchemeCodeUnspsc190501.26131702': __('identifierSchemeCodeUnspsc190501.26131702'),
	'identifierSchemeCodeUnspsc190501.26131800': __('identifierSchemeCodeUnspsc190501.26131800'),
	'identifierSchemeCodeUnspsc190501.26131801': __('identifierSchemeCodeUnspsc190501.26131801'),
	'identifierSchemeCodeUnspsc190501.26131802': __('identifierSchemeCodeUnspsc190501.26131802'),
	'identifierSchemeCodeUnspsc190501.26131803': __('identifierSchemeCodeUnspsc190501.26131803'),
	'identifierSchemeCodeUnspsc190501.26131804': __('identifierSchemeCodeUnspsc190501.26131804'),
	'identifierSchemeCodeUnspsc190501.26131807': __('identifierSchemeCodeUnspsc190501.26131807'),
	'identifierSchemeCodeUnspsc190501.26131808': __('identifierSchemeCodeUnspsc190501.26131808'),
	'identifierSchemeCodeUnspsc190501.26131811': __('identifierSchemeCodeUnspsc190501.26131811'),
	'identifierSchemeCodeUnspsc190501.26131812': __('identifierSchemeCodeUnspsc190501.26131812'),
	'identifierSchemeCodeUnspsc190501.26131813': __('identifierSchemeCodeUnspsc190501.26131813'),
	'identifierSchemeCodeUnspsc190501.26131814': __('identifierSchemeCodeUnspsc190501.26131814'),
	'identifierSchemeCodeUnspsc190501.26131815': __('identifierSchemeCodeUnspsc190501.26131815'),
	'identifierSchemeCodeUnspsc190501.26140000': __('identifierSchemeCodeUnspsc190501.26140000'),
	'identifierSchemeCodeUnspsc190501.26141600': __('identifierSchemeCodeUnspsc190501.26141600'),
	'identifierSchemeCodeUnspsc190501.26141601': __('identifierSchemeCodeUnspsc190501.26141601'),
	'identifierSchemeCodeUnspsc190501.26141602': __('identifierSchemeCodeUnspsc190501.26141602'),
	'identifierSchemeCodeUnspsc190501.26141603': __('identifierSchemeCodeUnspsc190501.26141603'),
	'identifierSchemeCodeUnspsc190501.26141700': __('identifierSchemeCodeUnspsc190501.26141700'),
	'identifierSchemeCodeUnspsc190501.26141701': __('identifierSchemeCodeUnspsc190501.26141701'),
	'identifierSchemeCodeUnspsc190501.26141702': __('identifierSchemeCodeUnspsc190501.26141702'),
	'identifierSchemeCodeUnspsc190501.26141703': __('identifierSchemeCodeUnspsc190501.26141703'),
	'identifierSchemeCodeUnspsc190501.26141704': __('identifierSchemeCodeUnspsc190501.26141704'),
	'identifierSchemeCodeUnspsc190501.26141800': __('identifierSchemeCodeUnspsc190501.26141800'),
	'identifierSchemeCodeUnspsc190501.26141801': __('identifierSchemeCodeUnspsc190501.26141801'),
	'identifierSchemeCodeUnspsc190501.26141802': __('identifierSchemeCodeUnspsc190501.26141802'),
	'identifierSchemeCodeUnspsc190501.26141803': __('identifierSchemeCodeUnspsc190501.26141803'),
	'identifierSchemeCodeUnspsc190501.26141804': __('identifierSchemeCodeUnspsc190501.26141804'),
	'identifierSchemeCodeUnspsc190501.26141805': __('identifierSchemeCodeUnspsc190501.26141805'),
	'identifierSchemeCodeUnspsc190501.26141806': __('identifierSchemeCodeUnspsc190501.26141806'),
	'identifierSchemeCodeUnspsc190501.26141807': __('identifierSchemeCodeUnspsc190501.26141807'),
	'identifierSchemeCodeUnspsc190501.26141808': __('identifierSchemeCodeUnspsc190501.26141808'),
	'identifierSchemeCodeUnspsc190501.26141809': __('identifierSchemeCodeUnspsc190501.26141809'),
	'identifierSchemeCodeUnspsc190501.26141900': __('identifierSchemeCodeUnspsc190501.26141900'),
	'identifierSchemeCodeUnspsc190501.26141901': __('identifierSchemeCodeUnspsc190501.26141901'),
	'identifierSchemeCodeUnspsc190501.26141902': __('identifierSchemeCodeUnspsc190501.26141902'),
	'identifierSchemeCodeUnspsc190501.26141904': __('identifierSchemeCodeUnspsc190501.26141904'),
	'identifierSchemeCodeUnspsc190501.26141905': __('identifierSchemeCodeUnspsc190501.26141905'),
	'identifierSchemeCodeUnspsc190501.26141906': __('identifierSchemeCodeUnspsc190501.26141906'),
	'identifierSchemeCodeUnspsc190501.26141907': __('identifierSchemeCodeUnspsc190501.26141907'),
	'identifierSchemeCodeUnspsc190501.26141908': __('identifierSchemeCodeUnspsc190501.26141908'),
	'identifierSchemeCodeUnspsc190501.26141909': __('identifierSchemeCodeUnspsc190501.26141909'),
	'identifierSchemeCodeUnspsc190501.26141910': __('identifierSchemeCodeUnspsc190501.26141910'),
	'identifierSchemeCodeUnspsc190501.26141911': __('identifierSchemeCodeUnspsc190501.26141911'),
	'identifierSchemeCodeUnspsc190501.26142000': __('identifierSchemeCodeUnspsc190501.26142000'),
	'identifierSchemeCodeUnspsc190501.26142001': __('identifierSchemeCodeUnspsc190501.26142001'),
	'identifierSchemeCodeUnspsc190501.26142002': __('identifierSchemeCodeUnspsc190501.26142002'),
	'identifierSchemeCodeUnspsc190501.26142003': __('identifierSchemeCodeUnspsc190501.26142003'),
	'identifierSchemeCodeUnspsc190501.26142004': __('identifierSchemeCodeUnspsc190501.26142004'),
	'identifierSchemeCodeUnspsc190501.26142005': __('identifierSchemeCodeUnspsc190501.26142005'),
	'identifierSchemeCodeUnspsc190501.26142006': __('identifierSchemeCodeUnspsc190501.26142006'),
	'identifierSchemeCodeUnspsc190501.26142007': __('identifierSchemeCodeUnspsc190501.26142007'),
	'identifierSchemeCodeUnspsc190501.26142100': __('identifierSchemeCodeUnspsc190501.26142100'),
	'identifierSchemeCodeUnspsc190501.26142101': __('identifierSchemeCodeUnspsc190501.26142101'),
	'identifierSchemeCodeUnspsc190501.26142106': __('identifierSchemeCodeUnspsc190501.26142106'),
	'identifierSchemeCodeUnspsc190501.26142108': __('identifierSchemeCodeUnspsc190501.26142108'),
	'identifierSchemeCodeUnspsc190501.26142117': __('identifierSchemeCodeUnspsc190501.26142117'),
	'identifierSchemeCodeUnspsc190501.26142200': __('identifierSchemeCodeUnspsc190501.26142200'),
	'identifierSchemeCodeUnspsc190501.26142201': __('identifierSchemeCodeUnspsc190501.26142201'),
	'identifierSchemeCodeUnspsc190501.26142202': __('identifierSchemeCodeUnspsc190501.26142202'),
	'identifierSchemeCodeUnspsc190501.26142300': __('identifierSchemeCodeUnspsc190501.26142300'),
	'identifierSchemeCodeUnspsc190501.26142302': __('identifierSchemeCodeUnspsc190501.26142302'),
	'identifierSchemeCodeUnspsc190501.26142303': __('identifierSchemeCodeUnspsc190501.26142303'),
	'identifierSchemeCodeUnspsc190501.26142304': __('identifierSchemeCodeUnspsc190501.26142304'),
	'identifierSchemeCodeUnspsc190501.26142306': __('identifierSchemeCodeUnspsc190501.26142306'),
	'identifierSchemeCodeUnspsc190501.26142307': __('identifierSchemeCodeUnspsc190501.26142307'),
	'identifierSchemeCodeUnspsc190501.26142308': __('identifierSchemeCodeUnspsc190501.26142308'),
	'identifierSchemeCodeUnspsc190501.26142310': __('identifierSchemeCodeUnspsc190501.26142310'),
	'identifierSchemeCodeUnspsc190501.26142311': __('identifierSchemeCodeUnspsc190501.26142311'),
	'identifierSchemeCodeUnspsc190501.26142312': __('identifierSchemeCodeUnspsc190501.26142312'),
	'identifierSchemeCodeUnspsc190501.26142400': __('identifierSchemeCodeUnspsc190501.26142400'),
	'identifierSchemeCodeUnspsc190501.26142401': __('identifierSchemeCodeUnspsc190501.26142401'),
	'identifierSchemeCodeUnspsc190501.26142402': __('identifierSchemeCodeUnspsc190501.26142402'),
	'identifierSchemeCodeUnspsc190501.26142403': __('identifierSchemeCodeUnspsc190501.26142403'),
	'identifierSchemeCodeUnspsc190501.26142404': __('identifierSchemeCodeUnspsc190501.26142404'),
	'identifierSchemeCodeUnspsc190501.26142405': __('identifierSchemeCodeUnspsc190501.26142405'),
	'identifierSchemeCodeUnspsc190501.26142406': __('identifierSchemeCodeUnspsc190501.26142406'),
	'identifierSchemeCodeUnspsc190501.26142407': __('identifierSchemeCodeUnspsc190501.26142407'),
	'identifierSchemeCodeUnspsc190501.26142408': __('identifierSchemeCodeUnspsc190501.26142408'),
	'identifierSchemeCodeUnspsc190501.27000000': __('identifierSchemeCodeUnspsc190501.27000000'),
	'identifierSchemeCodeUnspsc190501.27110000': __('identifierSchemeCodeUnspsc190501.27110000'),
	'identifierSchemeCodeUnspsc190501.27111500': __('identifierSchemeCodeUnspsc190501.27111500'),
	'identifierSchemeCodeUnspsc190501.27111501': __('identifierSchemeCodeUnspsc190501.27111501'),
	'identifierSchemeCodeUnspsc190501.27111502': __('identifierSchemeCodeUnspsc190501.27111502'),
	'identifierSchemeCodeUnspsc190501.27111503': __('identifierSchemeCodeUnspsc190501.27111503'),
	'identifierSchemeCodeUnspsc190501.27111504': __('identifierSchemeCodeUnspsc190501.27111504'),
	'identifierSchemeCodeUnspsc190501.27111505': __('identifierSchemeCodeUnspsc190501.27111505'),
	'identifierSchemeCodeUnspsc190501.27111506': __('identifierSchemeCodeUnspsc190501.27111506'),
	'identifierSchemeCodeUnspsc190501.27111507': __('identifierSchemeCodeUnspsc190501.27111507'),
	'identifierSchemeCodeUnspsc190501.27111508': __('identifierSchemeCodeUnspsc190501.27111508'),
	'identifierSchemeCodeUnspsc190501.27111509': __('identifierSchemeCodeUnspsc190501.27111509'),
	'identifierSchemeCodeUnspsc190501.27111510': __('identifierSchemeCodeUnspsc190501.27111510'),
	'identifierSchemeCodeUnspsc190501.27111511': __('identifierSchemeCodeUnspsc190501.27111511'),
	'identifierSchemeCodeUnspsc190501.27111512': __('identifierSchemeCodeUnspsc190501.27111512'),
	'identifierSchemeCodeUnspsc190501.27111513': __('identifierSchemeCodeUnspsc190501.27111513'),
	'identifierSchemeCodeUnspsc190501.27111514': __('identifierSchemeCodeUnspsc190501.27111514'),
	'identifierSchemeCodeUnspsc190501.27111515': __('identifierSchemeCodeUnspsc190501.27111515'),
	'identifierSchemeCodeUnspsc190501.27111516': __('identifierSchemeCodeUnspsc190501.27111516'),
	'identifierSchemeCodeUnspsc190501.27111517': __('identifierSchemeCodeUnspsc190501.27111517'),
	'identifierSchemeCodeUnspsc190501.27111518': __('identifierSchemeCodeUnspsc190501.27111518'),
	'identifierSchemeCodeUnspsc190501.27111519': __('identifierSchemeCodeUnspsc190501.27111519'),
	'identifierSchemeCodeUnspsc190501.27111520': __('identifierSchemeCodeUnspsc190501.27111520'),
	'identifierSchemeCodeUnspsc190501.27111521': __('identifierSchemeCodeUnspsc190501.27111521'),
	'identifierSchemeCodeUnspsc190501.27111522': __('identifierSchemeCodeUnspsc190501.27111522'),
	'identifierSchemeCodeUnspsc190501.27111523': __('identifierSchemeCodeUnspsc190501.27111523'),
	'identifierSchemeCodeUnspsc190501.27111524': __('identifierSchemeCodeUnspsc190501.27111524'),
	'identifierSchemeCodeUnspsc190501.27111525': __('identifierSchemeCodeUnspsc190501.27111525'),
	'identifierSchemeCodeUnspsc190501.27111526': __('identifierSchemeCodeUnspsc190501.27111526'),
	'identifierSchemeCodeUnspsc190501.27111527': __('identifierSchemeCodeUnspsc190501.27111527'),
	'identifierSchemeCodeUnspsc190501.27111529': __('identifierSchemeCodeUnspsc190501.27111529'),
	'identifierSchemeCodeUnspsc190501.27111530': __('identifierSchemeCodeUnspsc190501.27111530'),
	'identifierSchemeCodeUnspsc190501.27111531': __('identifierSchemeCodeUnspsc190501.27111531'),
	'identifierSchemeCodeUnspsc190501.27111532': __('identifierSchemeCodeUnspsc190501.27111532'),
	'identifierSchemeCodeUnspsc190501.27111533': __('identifierSchemeCodeUnspsc190501.27111533'),
	'identifierSchemeCodeUnspsc190501.27111534': __('identifierSchemeCodeUnspsc190501.27111534'),
	'identifierSchemeCodeUnspsc190501.27111535': __('identifierSchemeCodeUnspsc190501.27111535'),
	'identifierSchemeCodeUnspsc190501.27111536': __('identifierSchemeCodeUnspsc190501.27111536'),
	'identifierSchemeCodeUnspsc190501.27111537': __('identifierSchemeCodeUnspsc190501.27111537'),
	'identifierSchemeCodeUnspsc190501.27111538': __('identifierSchemeCodeUnspsc190501.27111538'),
	'identifierSchemeCodeUnspsc190501.27111539': __('identifierSchemeCodeUnspsc190501.27111539'),
	'identifierSchemeCodeUnspsc190501.27111540': __('identifierSchemeCodeUnspsc190501.27111540'),
	'identifierSchemeCodeUnspsc190501.27111541': __('identifierSchemeCodeUnspsc190501.27111541'),
	'identifierSchemeCodeUnspsc190501.27111542': __('identifierSchemeCodeUnspsc190501.27111542'),
	'identifierSchemeCodeUnspsc190501.27111543': __('identifierSchemeCodeUnspsc190501.27111543'),
	'identifierSchemeCodeUnspsc190501.27111544': __('identifierSchemeCodeUnspsc190501.27111544'),
	'identifierSchemeCodeUnspsc190501.27111545': __('identifierSchemeCodeUnspsc190501.27111545'),
	'identifierSchemeCodeUnspsc190501.27111546': __('identifierSchemeCodeUnspsc190501.27111546'),
	'identifierSchemeCodeUnspsc190501.27111547': __('identifierSchemeCodeUnspsc190501.27111547'),
	'identifierSchemeCodeUnspsc190501.27111548': __('identifierSchemeCodeUnspsc190501.27111548'),
	'identifierSchemeCodeUnspsc190501.27111549': __('identifierSchemeCodeUnspsc190501.27111549'),
	'identifierSchemeCodeUnspsc190501.27111550': __('identifierSchemeCodeUnspsc190501.27111550'),
	'identifierSchemeCodeUnspsc190501.27111551': __('identifierSchemeCodeUnspsc190501.27111551'),
	'identifierSchemeCodeUnspsc190501.27111552': __('identifierSchemeCodeUnspsc190501.27111552'),
	'identifierSchemeCodeUnspsc190501.27111553': __('identifierSchemeCodeUnspsc190501.27111553'),
	'identifierSchemeCodeUnspsc190501.27111554': __('identifierSchemeCodeUnspsc190501.27111554'),
	'identifierSchemeCodeUnspsc190501.27111555': __('identifierSchemeCodeUnspsc190501.27111555'),
	'identifierSchemeCodeUnspsc190501.27111556': __('identifierSchemeCodeUnspsc190501.27111556'),
	'identifierSchemeCodeUnspsc190501.27111557': __('identifierSchemeCodeUnspsc190501.27111557'),
	'identifierSchemeCodeUnspsc190501.27111558': __('identifierSchemeCodeUnspsc190501.27111558'),
	'identifierSchemeCodeUnspsc190501.27111559': __('identifierSchemeCodeUnspsc190501.27111559'),
	'identifierSchemeCodeUnspsc190501.27111560': __('identifierSchemeCodeUnspsc190501.27111560'),
	'identifierSchemeCodeUnspsc190501.27111561': __('identifierSchemeCodeUnspsc190501.27111561'),
	'identifierSchemeCodeUnspsc190501.27111562': __('identifierSchemeCodeUnspsc190501.27111562'),
	'identifierSchemeCodeUnspsc190501.27111600': __('identifierSchemeCodeUnspsc190501.27111600'),
	'identifierSchemeCodeUnspsc190501.27111601': __('identifierSchemeCodeUnspsc190501.27111601'),
	'identifierSchemeCodeUnspsc190501.27111602': __('identifierSchemeCodeUnspsc190501.27111602'),
	'identifierSchemeCodeUnspsc190501.27111603': __('identifierSchemeCodeUnspsc190501.27111603'),
	'identifierSchemeCodeUnspsc190501.27111604': __('identifierSchemeCodeUnspsc190501.27111604'),
	'identifierSchemeCodeUnspsc190501.27111605': __('identifierSchemeCodeUnspsc190501.27111605'),
	'identifierSchemeCodeUnspsc190501.27111607': __('identifierSchemeCodeUnspsc190501.27111607'),
	'identifierSchemeCodeUnspsc190501.27111608': __('identifierSchemeCodeUnspsc190501.27111608'),
	'identifierSchemeCodeUnspsc190501.27111609': __('identifierSchemeCodeUnspsc190501.27111609'),
	'identifierSchemeCodeUnspsc190501.27111610': __('identifierSchemeCodeUnspsc190501.27111610'),
	'identifierSchemeCodeUnspsc190501.27111611': __('identifierSchemeCodeUnspsc190501.27111611'),
	'identifierSchemeCodeUnspsc190501.27111612': __('identifierSchemeCodeUnspsc190501.27111612'),
	'identifierSchemeCodeUnspsc190501.27111613': __('identifierSchemeCodeUnspsc190501.27111613'),
	'identifierSchemeCodeUnspsc190501.27111614': __('identifierSchemeCodeUnspsc190501.27111614'),
	'identifierSchemeCodeUnspsc190501.27111615': __('identifierSchemeCodeUnspsc190501.27111615'),
	'identifierSchemeCodeUnspsc190501.27111616': __('identifierSchemeCodeUnspsc190501.27111616'),
	'identifierSchemeCodeUnspsc190501.27111617': __('identifierSchemeCodeUnspsc190501.27111617'),
	'identifierSchemeCodeUnspsc190501.27111618': __('identifierSchemeCodeUnspsc190501.27111618'),
	'identifierSchemeCodeUnspsc190501.27111619': __('identifierSchemeCodeUnspsc190501.27111619'),
	'identifierSchemeCodeUnspsc190501.27111620': __('identifierSchemeCodeUnspsc190501.27111620'),
	'identifierSchemeCodeUnspsc190501.27111621': __('identifierSchemeCodeUnspsc190501.27111621'),
	'identifierSchemeCodeUnspsc190501.27111622': __('identifierSchemeCodeUnspsc190501.27111622'),
	'identifierSchemeCodeUnspsc190501.27111623': __('identifierSchemeCodeUnspsc190501.27111623'),
	'identifierSchemeCodeUnspsc190501.27111700': __('identifierSchemeCodeUnspsc190501.27111700'),
	'identifierSchemeCodeUnspsc190501.27111701': __('identifierSchemeCodeUnspsc190501.27111701'),
	'identifierSchemeCodeUnspsc190501.27111702': __('identifierSchemeCodeUnspsc190501.27111702'),
	'identifierSchemeCodeUnspsc190501.27111703': __('identifierSchemeCodeUnspsc190501.27111703'),
	'identifierSchemeCodeUnspsc190501.27111704': __('identifierSchemeCodeUnspsc190501.27111704'),
	'identifierSchemeCodeUnspsc190501.27111705': __('identifierSchemeCodeUnspsc190501.27111705'),
	'identifierSchemeCodeUnspsc190501.27111706': __('identifierSchemeCodeUnspsc190501.27111706'),
	'identifierSchemeCodeUnspsc190501.27111707': __('identifierSchemeCodeUnspsc190501.27111707'),
	'identifierSchemeCodeUnspsc190501.27111708': __('identifierSchemeCodeUnspsc190501.27111708'),
	'identifierSchemeCodeUnspsc190501.27111709': __('identifierSchemeCodeUnspsc190501.27111709'),
	'identifierSchemeCodeUnspsc190501.27111710': __('identifierSchemeCodeUnspsc190501.27111710'),
	'identifierSchemeCodeUnspsc190501.27111711': __('identifierSchemeCodeUnspsc190501.27111711'),
	'identifierSchemeCodeUnspsc190501.27111712': __('identifierSchemeCodeUnspsc190501.27111712'),
	'identifierSchemeCodeUnspsc190501.27111713': __('identifierSchemeCodeUnspsc190501.27111713'),
	'identifierSchemeCodeUnspsc190501.27111714': __('identifierSchemeCodeUnspsc190501.27111714'),
	'identifierSchemeCodeUnspsc190501.27111715': __('identifierSchemeCodeUnspsc190501.27111715'),
	'identifierSchemeCodeUnspsc190501.27111716': __('identifierSchemeCodeUnspsc190501.27111716'),
	'identifierSchemeCodeUnspsc190501.27111717': __('identifierSchemeCodeUnspsc190501.27111717'),
	'identifierSchemeCodeUnspsc190501.27111718': __('identifierSchemeCodeUnspsc190501.27111718'),
	'identifierSchemeCodeUnspsc190501.27111720': __('identifierSchemeCodeUnspsc190501.27111720'),
	'identifierSchemeCodeUnspsc190501.27111721': __('identifierSchemeCodeUnspsc190501.27111721'),
	'identifierSchemeCodeUnspsc190501.27111722': __('identifierSchemeCodeUnspsc190501.27111722'),
	'identifierSchemeCodeUnspsc190501.27111723': __('identifierSchemeCodeUnspsc190501.27111723'),
	'identifierSchemeCodeUnspsc190501.27111724': __('identifierSchemeCodeUnspsc190501.27111724'),
	'identifierSchemeCodeUnspsc190501.27111725': __('identifierSchemeCodeUnspsc190501.27111725'),
	'identifierSchemeCodeUnspsc190501.27111726': __('identifierSchemeCodeUnspsc190501.27111726'),
	'identifierSchemeCodeUnspsc190501.27111727': __('identifierSchemeCodeUnspsc190501.27111727'),
	'identifierSchemeCodeUnspsc190501.27111728': __('identifierSchemeCodeUnspsc190501.27111728'),
	'identifierSchemeCodeUnspsc190501.27111729': __('identifierSchemeCodeUnspsc190501.27111729'),
	'identifierSchemeCodeUnspsc190501.27111730': __('identifierSchemeCodeUnspsc190501.27111730'),
	'identifierSchemeCodeUnspsc190501.27111731': __('identifierSchemeCodeUnspsc190501.27111731'),
	'identifierSchemeCodeUnspsc190501.27111732': __('identifierSchemeCodeUnspsc190501.27111732'),
	'identifierSchemeCodeUnspsc190501.27111733': __('identifierSchemeCodeUnspsc190501.27111733'),
	'identifierSchemeCodeUnspsc190501.27111734': __('identifierSchemeCodeUnspsc190501.27111734'),
	'identifierSchemeCodeUnspsc190501.27111735': __('identifierSchemeCodeUnspsc190501.27111735'),
	'identifierSchemeCodeUnspsc190501.27111736': __('identifierSchemeCodeUnspsc190501.27111736'),
	'identifierSchemeCodeUnspsc190501.27111737': __('identifierSchemeCodeUnspsc190501.27111737'),
	'identifierSchemeCodeUnspsc190501.27111738': __('identifierSchemeCodeUnspsc190501.27111738'),
	'identifierSchemeCodeUnspsc190501.27111739': __('identifierSchemeCodeUnspsc190501.27111739'),
	'identifierSchemeCodeUnspsc190501.27111740': __('identifierSchemeCodeUnspsc190501.27111740'),
	'identifierSchemeCodeUnspsc190501.27111741': __('identifierSchemeCodeUnspsc190501.27111741'),
	'identifierSchemeCodeUnspsc190501.27111742': __('identifierSchemeCodeUnspsc190501.27111742'),
	'identifierSchemeCodeUnspsc190501.27111743': __('identifierSchemeCodeUnspsc190501.27111743'),
	'identifierSchemeCodeUnspsc190501.27111744': __('identifierSchemeCodeUnspsc190501.27111744'),
	'identifierSchemeCodeUnspsc190501.27111745': __('identifierSchemeCodeUnspsc190501.27111745'),
	'identifierSchemeCodeUnspsc190501.27111746': __('identifierSchemeCodeUnspsc190501.27111746'),
	'identifierSchemeCodeUnspsc190501.27111747': __('identifierSchemeCodeUnspsc190501.27111747'),
	'identifierSchemeCodeUnspsc190501.27111748': __('identifierSchemeCodeUnspsc190501.27111748'),
	'identifierSchemeCodeUnspsc190501.27111749': __('identifierSchemeCodeUnspsc190501.27111749'),
	'identifierSchemeCodeUnspsc190501.27111750': __('identifierSchemeCodeUnspsc190501.27111750'),
	'identifierSchemeCodeUnspsc190501.27111751': __('identifierSchemeCodeUnspsc190501.27111751'),
	'identifierSchemeCodeUnspsc190501.27111752': __('identifierSchemeCodeUnspsc190501.27111752'),
	'identifierSchemeCodeUnspsc190501.27111753': __('identifierSchemeCodeUnspsc190501.27111753'),
	'identifierSchemeCodeUnspsc190501.27111754': __('identifierSchemeCodeUnspsc190501.27111754'),
	'identifierSchemeCodeUnspsc190501.27111755': __('identifierSchemeCodeUnspsc190501.27111755'),
	'identifierSchemeCodeUnspsc190501.27111756': __('identifierSchemeCodeUnspsc190501.27111756'),
	'identifierSchemeCodeUnspsc190501.27111757': __('identifierSchemeCodeUnspsc190501.27111757'),
	'identifierSchemeCodeUnspsc190501.27111758': __('identifierSchemeCodeUnspsc190501.27111758'),
	'identifierSchemeCodeUnspsc190501.27111759': __('identifierSchemeCodeUnspsc190501.27111759'),
	'identifierSchemeCodeUnspsc190501.27111760': __('identifierSchemeCodeUnspsc190501.27111760'),
	'identifierSchemeCodeUnspsc190501.27111761': __('identifierSchemeCodeUnspsc190501.27111761'),
	'identifierSchemeCodeUnspsc190501.27111762': __('identifierSchemeCodeUnspsc190501.27111762'),
	'identifierSchemeCodeUnspsc190501.27111763': __('identifierSchemeCodeUnspsc190501.27111763'),
	'identifierSchemeCodeUnspsc190501.27111764': __('identifierSchemeCodeUnspsc190501.27111764'),
	'identifierSchemeCodeUnspsc190501.27111765': __('identifierSchemeCodeUnspsc190501.27111765'),
	'identifierSchemeCodeUnspsc190501.27111766': __('identifierSchemeCodeUnspsc190501.27111766'),
	'identifierSchemeCodeUnspsc190501.27111767': __('identifierSchemeCodeUnspsc190501.27111767'),
	'identifierSchemeCodeUnspsc190501.27111768': __('identifierSchemeCodeUnspsc190501.27111768'),
	'identifierSchemeCodeUnspsc190501.27111800': __('identifierSchemeCodeUnspsc190501.27111800'),
	'identifierSchemeCodeUnspsc190501.27111801': __('identifierSchemeCodeUnspsc190501.27111801'),
	'identifierSchemeCodeUnspsc190501.27111802': __('identifierSchemeCodeUnspsc190501.27111802'),
	'identifierSchemeCodeUnspsc190501.27111803': __('identifierSchemeCodeUnspsc190501.27111803'),
	'identifierSchemeCodeUnspsc190501.27111804': __('identifierSchemeCodeUnspsc190501.27111804'),
	'identifierSchemeCodeUnspsc190501.27111806': __('identifierSchemeCodeUnspsc190501.27111806'),
	'identifierSchemeCodeUnspsc190501.27111807': __('identifierSchemeCodeUnspsc190501.27111807'),
	'identifierSchemeCodeUnspsc190501.27111809': __('identifierSchemeCodeUnspsc190501.27111809'),
	'identifierSchemeCodeUnspsc190501.27111810': __('identifierSchemeCodeUnspsc190501.27111810'),
	'identifierSchemeCodeUnspsc190501.27111811': __('identifierSchemeCodeUnspsc190501.27111811'),
	'identifierSchemeCodeUnspsc190501.27111812': __('identifierSchemeCodeUnspsc190501.27111812'),
	'identifierSchemeCodeUnspsc190501.27111813': __('identifierSchemeCodeUnspsc190501.27111813'),
	'identifierSchemeCodeUnspsc190501.27111814': __('identifierSchemeCodeUnspsc190501.27111814'),
	'identifierSchemeCodeUnspsc190501.27111815': __('identifierSchemeCodeUnspsc190501.27111815'),
	'identifierSchemeCodeUnspsc190501.27111816': __('identifierSchemeCodeUnspsc190501.27111816'),
	'identifierSchemeCodeUnspsc190501.27111817': __('identifierSchemeCodeUnspsc190501.27111817'),
	'identifierSchemeCodeUnspsc190501.27111818': __('identifierSchemeCodeUnspsc190501.27111818'),
	'identifierSchemeCodeUnspsc190501.27111819': __('identifierSchemeCodeUnspsc190501.27111819'),
	'identifierSchemeCodeUnspsc190501.27111820': __('identifierSchemeCodeUnspsc190501.27111820'),
	'identifierSchemeCodeUnspsc190501.27111821': __('identifierSchemeCodeUnspsc190501.27111821'),
	'identifierSchemeCodeUnspsc190501.27111822': __('identifierSchemeCodeUnspsc190501.27111822'),
	'identifierSchemeCodeUnspsc190501.27111823': __('identifierSchemeCodeUnspsc190501.27111823'),
	'identifierSchemeCodeUnspsc190501.27111824': __('identifierSchemeCodeUnspsc190501.27111824'),
	'identifierSchemeCodeUnspsc190501.27111825': __('identifierSchemeCodeUnspsc190501.27111825'),
	'identifierSchemeCodeUnspsc190501.27111826': __('identifierSchemeCodeUnspsc190501.27111826'),
	'identifierSchemeCodeUnspsc190501.27111827': __('identifierSchemeCodeUnspsc190501.27111827'),
	'identifierSchemeCodeUnspsc190501.27111828': __('identifierSchemeCodeUnspsc190501.27111828'),
	'identifierSchemeCodeUnspsc190501.27111829': __('identifierSchemeCodeUnspsc190501.27111829'),
	'identifierSchemeCodeUnspsc190501.27111900': __('identifierSchemeCodeUnspsc190501.27111900'),
	'identifierSchemeCodeUnspsc190501.27111901': __('identifierSchemeCodeUnspsc190501.27111901'),
	'identifierSchemeCodeUnspsc190501.27111903': __('identifierSchemeCodeUnspsc190501.27111903'),
	'identifierSchemeCodeUnspsc190501.27111904': __('identifierSchemeCodeUnspsc190501.27111904'),
	'identifierSchemeCodeUnspsc190501.27111905': __('identifierSchemeCodeUnspsc190501.27111905'),
	'identifierSchemeCodeUnspsc190501.27111906': __('identifierSchemeCodeUnspsc190501.27111906'),
	'identifierSchemeCodeUnspsc190501.27111907': __('identifierSchemeCodeUnspsc190501.27111907'),
	'identifierSchemeCodeUnspsc190501.27111908': __('identifierSchemeCodeUnspsc190501.27111908'),
	'identifierSchemeCodeUnspsc190501.27111909': __('identifierSchemeCodeUnspsc190501.27111909'),
	'identifierSchemeCodeUnspsc190501.27111910': __('identifierSchemeCodeUnspsc190501.27111910'),
	'identifierSchemeCodeUnspsc190501.27111911': __('identifierSchemeCodeUnspsc190501.27111911'),
	'identifierSchemeCodeUnspsc190501.27111912': __('identifierSchemeCodeUnspsc190501.27111912'),
	'identifierSchemeCodeUnspsc190501.27111913': __('identifierSchemeCodeUnspsc190501.27111913'),
	'identifierSchemeCodeUnspsc190501.27111914': __('identifierSchemeCodeUnspsc190501.27111914'),
	'identifierSchemeCodeUnspsc190501.27111915': __('identifierSchemeCodeUnspsc190501.27111915'),
	'identifierSchemeCodeUnspsc190501.27111916': __('identifierSchemeCodeUnspsc190501.27111916'),
	'identifierSchemeCodeUnspsc190501.27111917': __('identifierSchemeCodeUnspsc190501.27111917'),
	'identifierSchemeCodeUnspsc190501.27111918': __('identifierSchemeCodeUnspsc190501.27111918'),
	'identifierSchemeCodeUnspsc190501.27111919': __('identifierSchemeCodeUnspsc190501.27111919'),
	'identifierSchemeCodeUnspsc190501.27111920': __('identifierSchemeCodeUnspsc190501.27111920'),
	'identifierSchemeCodeUnspsc190501.27111921': __('identifierSchemeCodeUnspsc190501.27111921'),
	'identifierSchemeCodeUnspsc190501.27111922': __('identifierSchemeCodeUnspsc190501.27111922'),
	'identifierSchemeCodeUnspsc190501.27111923': __('identifierSchemeCodeUnspsc190501.27111923'),
	'identifierSchemeCodeUnspsc190501.27111924': __('identifierSchemeCodeUnspsc190501.27111924'),
	'identifierSchemeCodeUnspsc190501.27111925': __('identifierSchemeCodeUnspsc190501.27111925'),
	'identifierSchemeCodeUnspsc190501.27111926': __('identifierSchemeCodeUnspsc190501.27111926'),
	'identifierSchemeCodeUnspsc190501.27111927': __('identifierSchemeCodeUnspsc190501.27111927'),
	'identifierSchemeCodeUnspsc190501.27111928': __('identifierSchemeCodeUnspsc190501.27111928'),
	'identifierSchemeCodeUnspsc190501.27111929': __('identifierSchemeCodeUnspsc190501.27111929'),
	'identifierSchemeCodeUnspsc190501.27111930': __('identifierSchemeCodeUnspsc190501.27111930'),
	'identifierSchemeCodeUnspsc190501.27111931': __('identifierSchemeCodeUnspsc190501.27111931'),
	'identifierSchemeCodeUnspsc190501.27111932': __('identifierSchemeCodeUnspsc190501.27111932'),
	'identifierSchemeCodeUnspsc190501.27111933': __('identifierSchemeCodeUnspsc190501.27111933'),
	'identifierSchemeCodeUnspsc190501.27111934': __('identifierSchemeCodeUnspsc190501.27111934'),
	'identifierSchemeCodeUnspsc190501.27111935': __('identifierSchemeCodeUnspsc190501.27111935'),
	'identifierSchemeCodeUnspsc190501.27111936': __('identifierSchemeCodeUnspsc190501.27111936'),
	'identifierSchemeCodeUnspsc190501.27111937': __('identifierSchemeCodeUnspsc190501.27111937'),
	'identifierSchemeCodeUnspsc190501.27111938': __('identifierSchemeCodeUnspsc190501.27111938'),
	'identifierSchemeCodeUnspsc190501.27111939': __('identifierSchemeCodeUnspsc190501.27111939'),
	'identifierSchemeCodeUnspsc190501.27111940': __('identifierSchemeCodeUnspsc190501.27111940'),
	'identifierSchemeCodeUnspsc190501.27111941': __('identifierSchemeCodeUnspsc190501.27111941'),
	'identifierSchemeCodeUnspsc190501.27111942': __('identifierSchemeCodeUnspsc190501.27111942'),
	'identifierSchemeCodeUnspsc190501.27111943': __('identifierSchemeCodeUnspsc190501.27111943'),
	'identifierSchemeCodeUnspsc190501.27111944': __('identifierSchemeCodeUnspsc190501.27111944'),
	'identifierSchemeCodeUnspsc190501.27111945': __('identifierSchemeCodeUnspsc190501.27111945'),
	'identifierSchemeCodeUnspsc190501.27111946': __('identifierSchemeCodeUnspsc190501.27111946'),
	'identifierSchemeCodeUnspsc190501.27111948': __('identifierSchemeCodeUnspsc190501.27111948'),
	'identifierSchemeCodeUnspsc190501.27111949': __('identifierSchemeCodeUnspsc190501.27111949'),
	'identifierSchemeCodeUnspsc190501.27111950': __('identifierSchemeCodeUnspsc190501.27111950'),
	'identifierSchemeCodeUnspsc190501.27111951': __('identifierSchemeCodeUnspsc190501.27111951'),
	'identifierSchemeCodeUnspsc190501.27111952': __('identifierSchemeCodeUnspsc190501.27111952'),
	'identifierSchemeCodeUnspsc190501.27111953': __('identifierSchemeCodeUnspsc190501.27111953'),
	'identifierSchemeCodeUnspsc190501.27111954': __('identifierSchemeCodeUnspsc190501.27111954'),
	'identifierSchemeCodeUnspsc190501.27111955': __('identifierSchemeCodeUnspsc190501.27111955'),
	'identifierSchemeCodeUnspsc190501.27111956': __('identifierSchemeCodeUnspsc190501.27111956'),
	'identifierSchemeCodeUnspsc190501.27111957': __('identifierSchemeCodeUnspsc190501.27111957'),
	'identifierSchemeCodeUnspsc190501.27111958': __('identifierSchemeCodeUnspsc190501.27111958'),
	'identifierSchemeCodeUnspsc190501.27112000': __('identifierSchemeCodeUnspsc190501.27112000'),
	'identifierSchemeCodeUnspsc190501.27112001': __('identifierSchemeCodeUnspsc190501.27112001'),
	'identifierSchemeCodeUnspsc190501.27112002': __('identifierSchemeCodeUnspsc190501.27112002'),
	'identifierSchemeCodeUnspsc190501.27112003': __('identifierSchemeCodeUnspsc190501.27112003'),
	'identifierSchemeCodeUnspsc190501.27112004': __('identifierSchemeCodeUnspsc190501.27112004'),
	'identifierSchemeCodeUnspsc190501.27112005': __('identifierSchemeCodeUnspsc190501.27112005'),
	'identifierSchemeCodeUnspsc190501.27112006': __('identifierSchemeCodeUnspsc190501.27112006'),
	'identifierSchemeCodeUnspsc190501.27112007': __('identifierSchemeCodeUnspsc190501.27112007'),
	'identifierSchemeCodeUnspsc190501.27112008': __('identifierSchemeCodeUnspsc190501.27112008'),
	'identifierSchemeCodeUnspsc190501.27112009': __('identifierSchemeCodeUnspsc190501.27112009'),
	'identifierSchemeCodeUnspsc190501.27112010': __('identifierSchemeCodeUnspsc190501.27112010'),
	'identifierSchemeCodeUnspsc190501.27112011': __('identifierSchemeCodeUnspsc190501.27112011'),
	'identifierSchemeCodeUnspsc190501.27112012': __('identifierSchemeCodeUnspsc190501.27112012'),
	'identifierSchemeCodeUnspsc190501.27112013': __('identifierSchemeCodeUnspsc190501.27112013'),
	'identifierSchemeCodeUnspsc190501.27112014': __('identifierSchemeCodeUnspsc190501.27112014'),
	'identifierSchemeCodeUnspsc190501.27112015': __('identifierSchemeCodeUnspsc190501.27112015'),
	'identifierSchemeCodeUnspsc190501.27112016': __('identifierSchemeCodeUnspsc190501.27112016'),
	'identifierSchemeCodeUnspsc190501.27112017': __('identifierSchemeCodeUnspsc190501.27112017'),
	'identifierSchemeCodeUnspsc190501.27112018': __('identifierSchemeCodeUnspsc190501.27112018'),
	'identifierSchemeCodeUnspsc190501.27112019': __('identifierSchemeCodeUnspsc190501.27112019'),
	'identifierSchemeCodeUnspsc190501.27112020': __('identifierSchemeCodeUnspsc190501.27112020'),
	'identifierSchemeCodeUnspsc190501.27112021': __('identifierSchemeCodeUnspsc190501.27112021'),
	'identifierSchemeCodeUnspsc190501.27112022': __('identifierSchemeCodeUnspsc190501.27112022'),
	'identifierSchemeCodeUnspsc190501.27112023': __('identifierSchemeCodeUnspsc190501.27112023'),
	'identifierSchemeCodeUnspsc190501.27112024': __('identifierSchemeCodeUnspsc190501.27112024'),
	'identifierSchemeCodeUnspsc190501.27112025': __('identifierSchemeCodeUnspsc190501.27112025'),
	'identifierSchemeCodeUnspsc190501.27112026': __('identifierSchemeCodeUnspsc190501.27112026'),
	'identifierSchemeCodeUnspsc190501.27112027': __('identifierSchemeCodeUnspsc190501.27112027'),
	'identifierSchemeCodeUnspsc190501.27112028': __('identifierSchemeCodeUnspsc190501.27112028'),
	'identifierSchemeCodeUnspsc190501.27112029': __('identifierSchemeCodeUnspsc190501.27112029'),
	'identifierSchemeCodeUnspsc190501.27112030': __('identifierSchemeCodeUnspsc190501.27112030'),
	'identifierSchemeCodeUnspsc190501.27112031': __('identifierSchemeCodeUnspsc190501.27112031'),
	'identifierSchemeCodeUnspsc190501.27112032': __('identifierSchemeCodeUnspsc190501.27112032'),
	'identifierSchemeCodeUnspsc190501.27112033': __('identifierSchemeCodeUnspsc190501.27112033'),
	'identifierSchemeCodeUnspsc190501.27112034': __('identifierSchemeCodeUnspsc190501.27112034'),
	'identifierSchemeCodeUnspsc190501.27112035': __('identifierSchemeCodeUnspsc190501.27112035'),
	'identifierSchemeCodeUnspsc190501.27112036': __('identifierSchemeCodeUnspsc190501.27112036'),
	'identifierSchemeCodeUnspsc190501.27112037': __('identifierSchemeCodeUnspsc190501.27112037'),
	'identifierSchemeCodeUnspsc190501.27112038': __('identifierSchemeCodeUnspsc190501.27112038'),
	'identifierSchemeCodeUnspsc190501.27112039': __('identifierSchemeCodeUnspsc190501.27112039'),
	'identifierSchemeCodeUnspsc190501.27112040': __('identifierSchemeCodeUnspsc190501.27112040'),
	'identifierSchemeCodeUnspsc190501.27112041': __('identifierSchemeCodeUnspsc190501.27112041'),
	'identifierSchemeCodeUnspsc190501.27112042': __('identifierSchemeCodeUnspsc190501.27112042'),
	'identifierSchemeCodeUnspsc190501.27112043': __('identifierSchemeCodeUnspsc190501.27112043'),
	'identifierSchemeCodeUnspsc190501.27112044': __('identifierSchemeCodeUnspsc190501.27112044'),
	'identifierSchemeCodeUnspsc190501.27112045': __('identifierSchemeCodeUnspsc190501.27112045'),
	'identifierSchemeCodeUnspsc190501.27112046': __('identifierSchemeCodeUnspsc190501.27112046'),
	'identifierSchemeCodeUnspsc190501.27112047': __('identifierSchemeCodeUnspsc190501.27112047'),
	'identifierSchemeCodeUnspsc190501.27112100': __('identifierSchemeCodeUnspsc190501.27112100'),
	'identifierSchemeCodeUnspsc190501.27112101': __('identifierSchemeCodeUnspsc190501.27112101'),
	'identifierSchemeCodeUnspsc190501.27112102': __('identifierSchemeCodeUnspsc190501.27112102'),
	'identifierSchemeCodeUnspsc190501.27112103': __('identifierSchemeCodeUnspsc190501.27112103'),
	'identifierSchemeCodeUnspsc190501.27112104': __('identifierSchemeCodeUnspsc190501.27112104'),
	'identifierSchemeCodeUnspsc190501.27112105': __('identifierSchemeCodeUnspsc190501.27112105'),
	'identifierSchemeCodeUnspsc190501.27112106': __('identifierSchemeCodeUnspsc190501.27112106'),
	'identifierSchemeCodeUnspsc190501.27112107': __('identifierSchemeCodeUnspsc190501.27112107'),
	'identifierSchemeCodeUnspsc190501.27112108': __('identifierSchemeCodeUnspsc190501.27112108'),
	'identifierSchemeCodeUnspsc190501.27112109': __('identifierSchemeCodeUnspsc190501.27112109'),
	'identifierSchemeCodeUnspsc190501.27112110': __('identifierSchemeCodeUnspsc190501.27112110'),
	'identifierSchemeCodeUnspsc190501.27112111': __('identifierSchemeCodeUnspsc190501.27112111'),
	'identifierSchemeCodeUnspsc190501.27112112': __('identifierSchemeCodeUnspsc190501.27112112'),
	'identifierSchemeCodeUnspsc190501.27112113': __('identifierSchemeCodeUnspsc190501.27112113'),
	'identifierSchemeCodeUnspsc190501.27112114': __('identifierSchemeCodeUnspsc190501.27112114'),
	'identifierSchemeCodeUnspsc190501.27112115': __('identifierSchemeCodeUnspsc190501.27112115'),
	'identifierSchemeCodeUnspsc190501.27112116': __('identifierSchemeCodeUnspsc190501.27112116'),
	'identifierSchemeCodeUnspsc190501.27112117': __('identifierSchemeCodeUnspsc190501.27112117'),
	'identifierSchemeCodeUnspsc190501.27112119': __('identifierSchemeCodeUnspsc190501.27112119'),
	'identifierSchemeCodeUnspsc190501.27112120': __('identifierSchemeCodeUnspsc190501.27112120'),
	'identifierSchemeCodeUnspsc190501.27112121': __('identifierSchemeCodeUnspsc190501.27112121'),
	'identifierSchemeCodeUnspsc190501.27112122': __('identifierSchemeCodeUnspsc190501.27112122'),
	'identifierSchemeCodeUnspsc190501.27112123': __('identifierSchemeCodeUnspsc190501.27112123'),
	'identifierSchemeCodeUnspsc190501.27112124': __('identifierSchemeCodeUnspsc190501.27112124'),
	'identifierSchemeCodeUnspsc190501.27112125': __('identifierSchemeCodeUnspsc190501.27112125'),
	'identifierSchemeCodeUnspsc190501.27112126': __('identifierSchemeCodeUnspsc190501.27112126'),
	'identifierSchemeCodeUnspsc190501.27112127': __('identifierSchemeCodeUnspsc190501.27112127'),
	'identifierSchemeCodeUnspsc190501.27112128': __('identifierSchemeCodeUnspsc190501.27112128'),
	'identifierSchemeCodeUnspsc190501.27112129': __('identifierSchemeCodeUnspsc190501.27112129'),
	'identifierSchemeCodeUnspsc190501.27112130': __('identifierSchemeCodeUnspsc190501.27112130'),
	'identifierSchemeCodeUnspsc190501.27112131': __('identifierSchemeCodeUnspsc190501.27112131'),
	'identifierSchemeCodeUnspsc190501.27112132': __('identifierSchemeCodeUnspsc190501.27112132'),
	'identifierSchemeCodeUnspsc190501.27112133': __('identifierSchemeCodeUnspsc190501.27112133'),
	'identifierSchemeCodeUnspsc190501.27112134': __('identifierSchemeCodeUnspsc190501.27112134'),
	'identifierSchemeCodeUnspsc190501.27112135': __('identifierSchemeCodeUnspsc190501.27112135'),
	'identifierSchemeCodeUnspsc190501.27112136': __('identifierSchemeCodeUnspsc190501.27112136'),
	'identifierSchemeCodeUnspsc190501.27112137': __('identifierSchemeCodeUnspsc190501.27112137'),
	'identifierSchemeCodeUnspsc190501.27112138': __('identifierSchemeCodeUnspsc190501.27112138'),
	'identifierSchemeCodeUnspsc190501.27112139': __('identifierSchemeCodeUnspsc190501.27112139'),
	'identifierSchemeCodeUnspsc190501.27112140': __('identifierSchemeCodeUnspsc190501.27112140'),
	'identifierSchemeCodeUnspsc190501.27112141': __('identifierSchemeCodeUnspsc190501.27112141'),
	'identifierSchemeCodeUnspsc190501.27112142': __('identifierSchemeCodeUnspsc190501.27112142'),
	'identifierSchemeCodeUnspsc190501.27112143': __('identifierSchemeCodeUnspsc190501.27112143'),
	'identifierSchemeCodeUnspsc190501.27112144': __('identifierSchemeCodeUnspsc190501.27112144'),
	'identifierSchemeCodeUnspsc190501.27112145': __('identifierSchemeCodeUnspsc190501.27112145'),
	'identifierSchemeCodeUnspsc190501.27112146': __('identifierSchemeCodeUnspsc190501.27112146'),
	'identifierSchemeCodeUnspsc190501.27112147': __('identifierSchemeCodeUnspsc190501.27112147'),
	'identifierSchemeCodeUnspsc190501.27112148': __('identifierSchemeCodeUnspsc190501.27112148'),
	'identifierSchemeCodeUnspsc190501.27112149': __('identifierSchemeCodeUnspsc190501.27112149'),
	'identifierSchemeCodeUnspsc190501.27112150': __('identifierSchemeCodeUnspsc190501.27112150'),
	'identifierSchemeCodeUnspsc190501.27112151': __('identifierSchemeCodeUnspsc190501.27112151'),
	'identifierSchemeCodeUnspsc190501.27112152': __('identifierSchemeCodeUnspsc190501.27112152'),
	'identifierSchemeCodeUnspsc190501.27112153': __('identifierSchemeCodeUnspsc190501.27112153'),
	'identifierSchemeCodeUnspsc190501.27112154': __('identifierSchemeCodeUnspsc190501.27112154'),
	'identifierSchemeCodeUnspsc190501.27112155': __('identifierSchemeCodeUnspsc190501.27112155'),
	'identifierSchemeCodeUnspsc190501.27112156': __('identifierSchemeCodeUnspsc190501.27112156'),
	'identifierSchemeCodeUnspsc190501.27112157': __('identifierSchemeCodeUnspsc190501.27112157'),
	'identifierSchemeCodeUnspsc190501.27112158': __('identifierSchemeCodeUnspsc190501.27112158'),
	'identifierSchemeCodeUnspsc190501.27112159': __('identifierSchemeCodeUnspsc190501.27112159'),
	'identifierSchemeCodeUnspsc190501.27112160': __('identifierSchemeCodeUnspsc190501.27112160'),
	'identifierSchemeCodeUnspsc190501.27112161': __('identifierSchemeCodeUnspsc190501.27112161'),
	'identifierSchemeCodeUnspsc190501.27112162': __('identifierSchemeCodeUnspsc190501.27112162'),
	'identifierSchemeCodeUnspsc190501.27112200': __('identifierSchemeCodeUnspsc190501.27112200'),
	'identifierSchemeCodeUnspsc190501.27112201': __('identifierSchemeCodeUnspsc190501.27112201'),
	'identifierSchemeCodeUnspsc190501.27112202': __('identifierSchemeCodeUnspsc190501.27112202'),
	'identifierSchemeCodeUnspsc190501.27112203': __('identifierSchemeCodeUnspsc190501.27112203'),
	'identifierSchemeCodeUnspsc190501.27112205': __('identifierSchemeCodeUnspsc190501.27112205'),
	'identifierSchemeCodeUnspsc190501.27112206': __('identifierSchemeCodeUnspsc190501.27112206'),
	'identifierSchemeCodeUnspsc190501.27112207': __('identifierSchemeCodeUnspsc190501.27112207'),
	'identifierSchemeCodeUnspsc190501.27112208': __('identifierSchemeCodeUnspsc190501.27112208'),
	'identifierSchemeCodeUnspsc190501.27112209': __('identifierSchemeCodeUnspsc190501.27112209'),
	'identifierSchemeCodeUnspsc190501.27112210': __('identifierSchemeCodeUnspsc190501.27112210'),
	'identifierSchemeCodeUnspsc190501.27112211': __('identifierSchemeCodeUnspsc190501.27112211'),
	'identifierSchemeCodeUnspsc190501.27112212': __('identifierSchemeCodeUnspsc190501.27112212'),
	'identifierSchemeCodeUnspsc190501.27112213': __('identifierSchemeCodeUnspsc190501.27112213'),
	'identifierSchemeCodeUnspsc190501.27112215': __('identifierSchemeCodeUnspsc190501.27112215'),
	'identifierSchemeCodeUnspsc190501.27112216': __('identifierSchemeCodeUnspsc190501.27112216'),
	'identifierSchemeCodeUnspsc190501.27112217': __('identifierSchemeCodeUnspsc190501.27112217'),
	'identifierSchemeCodeUnspsc190501.27112218': __('identifierSchemeCodeUnspsc190501.27112218'),
	'identifierSchemeCodeUnspsc190501.27112219': __('identifierSchemeCodeUnspsc190501.27112219'),
	'identifierSchemeCodeUnspsc190501.27112220': __('identifierSchemeCodeUnspsc190501.27112220'),
	'identifierSchemeCodeUnspsc190501.27112221': __('identifierSchemeCodeUnspsc190501.27112221'),
	'identifierSchemeCodeUnspsc190501.27112222': __('identifierSchemeCodeUnspsc190501.27112222'),
	'identifierSchemeCodeUnspsc190501.27112223': __('identifierSchemeCodeUnspsc190501.27112223'),
	'identifierSchemeCodeUnspsc190501.27112224': __('identifierSchemeCodeUnspsc190501.27112224'),
	'identifierSchemeCodeUnspsc190501.27112225': __('identifierSchemeCodeUnspsc190501.27112225'),
	'identifierSchemeCodeUnspsc190501.27112226': __('identifierSchemeCodeUnspsc190501.27112226'),
	'identifierSchemeCodeUnspsc190501.27112227': __('identifierSchemeCodeUnspsc190501.27112227'),
	'identifierSchemeCodeUnspsc190501.27112228': __('identifierSchemeCodeUnspsc190501.27112228'),
	'identifierSchemeCodeUnspsc190501.27112229': __('identifierSchemeCodeUnspsc190501.27112229'),
	'identifierSchemeCodeUnspsc190501.27112230': __('identifierSchemeCodeUnspsc190501.27112230'),
	'identifierSchemeCodeUnspsc190501.27112231': __('identifierSchemeCodeUnspsc190501.27112231'),
	'identifierSchemeCodeUnspsc190501.27112232': __('identifierSchemeCodeUnspsc190501.27112232'),
	'identifierSchemeCodeUnspsc190501.27112300': __('identifierSchemeCodeUnspsc190501.27112300'),
	'identifierSchemeCodeUnspsc190501.27112301': __('identifierSchemeCodeUnspsc190501.27112301'),
	'identifierSchemeCodeUnspsc190501.27112302': __('identifierSchemeCodeUnspsc190501.27112302'),
	'identifierSchemeCodeUnspsc190501.27112303': __('identifierSchemeCodeUnspsc190501.27112303'),
	'identifierSchemeCodeUnspsc190501.27112304': __('identifierSchemeCodeUnspsc190501.27112304'),
	'identifierSchemeCodeUnspsc190501.27112305': __('identifierSchemeCodeUnspsc190501.27112305'),
	'identifierSchemeCodeUnspsc190501.27112306': __('identifierSchemeCodeUnspsc190501.27112306'),
	'identifierSchemeCodeUnspsc190501.27112307': __('identifierSchemeCodeUnspsc190501.27112307'),
	'identifierSchemeCodeUnspsc190501.27112308': __('identifierSchemeCodeUnspsc190501.27112308'),
	'identifierSchemeCodeUnspsc190501.27112309': __('identifierSchemeCodeUnspsc190501.27112309'),
	'identifierSchemeCodeUnspsc190501.27112310': __('identifierSchemeCodeUnspsc190501.27112310'),
	'identifierSchemeCodeUnspsc190501.27112311': __('identifierSchemeCodeUnspsc190501.27112311'),
	'identifierSchemeCodeUnspsc190501.27112312': __('identifierSchemeCodeUnspsc190501.27112312'),
	'identifierSchemeCodeUnspsc190501.27112313': __('identifierSchemeCodeUnspsc190501.27112313'),
	'identifierSchemeCodeUnspsc190501.27112400': __('identifierSchemeCodeUnspsc190501.27112400'),
	'identifierSchemeCodeUnspsc190501.27112401': __('identifierSchemeCodeUnspsc190501.27112401'),
	'identifierSchemeCodeUnspsc190501.27112402': __('identifierSchemeCodeUnspsc190501.27112402'),
	'identifierSchemeCodeUnspsc190501.27112403': __('identifierSchemeCodeUnspsc190501.27112403'),
	'identifierSchemeCodeUnspsc190501.27112404': __('identifierSchemeCodeUnspsc190501.27112404'),
	'identifierSchemeCodeUnspsc190501.27112405': __('identifierSchemeCodeUnspsc190501.27112405'),
	'identifierSchemeCodeUnspsc190501.27112406': __('identifierSchemeCodeUnspsc190501.27112406'),
	'identifierSchemeCodeUnspsc190501.27112407': __('identifierSchemeCodeUnspsc190501.27112407'),
	'identifierSchemeCodeUnspsc190501.27112408': __('identifierSchemeCodeUnspsc190501.27112408'),
	'identifierSchemeCodeUnspsc190501.27112409': __('identifierSchemeCodeUnspsc190501.27112409'),
	'identifierSchemeCodeUnspsc190501.27112410': __('identifierSchemeCodeUnspsc190501.27112410'),
	'identifierSchemeCodeUnspsc190501.27112500': __('identifierSchemeCodeUnspsc190501.27112500'),
	'identifierSchemeCodeUnspsc190501.27112501': __('identifierSchemeCodeUnspsc190501.27112501'),
	'identifierSchemeCodeUnspsc190501.27112502': __('identifierSchemeCodeUnspsc190501.27112502'),
	'identifierSchemeCodeUnspsc190501.27112503': __('identifierSchemeCodeUnspsc190501.27112503'),
	'identifierSchemeCodeUnspsc190501.27112504': __('identifierSchemeCodeUnspsc190501.27112504'),
	'identifierSchemeCodeUnspsc190501.27112505': __('identifierSchemeCodeUnspsc190501.27112505'),
	'identifierSchemeCodeUnspsc190501.27112506': __('identifierSchemeCodeUnspsc190501.27112506'),
	'identifierSchemeCodeUnspsc190501.27112507': __('identifierSchemeCodeUnspsc190501.27112507'),
	'identifierSchemeCodeUnspsc190501.27112508': __('identifierSchemeCodeUnspsc190501.27112508'),
	'identifierSchemeCodeUnspsc190501.27112600': __('identifierSchemeCodeUnspsc190501.27112600'),
	'identifierSchemeCodeUnspsc190501.27112601': __('identifierSchemeCodeUnspsc190501.27112601'),
	'identifierSchemeCodeUnspsc190501.27112602': __('identifierSchemeCodeUnspsc190501.27112602'),
	'identifierSchemeCodeUnspsc190501.27112603': __('identifierSchemeCodeUnspsc190501.27112603'),
	'identifierSchemeCodeUnspsc190501.27112604': __('identifierSchemeCodeUnspsc190501.27112604'),
	'identifierSchemeCodeUnspsc190501.27112700': __('identifierSchemeCodeUnspsc190501.27112700'),
	'identifierSchemeCodeUnspsc190501.27112701': __('identifierSchemeCodeUnspsc190501.27112701'),
	'identifierSchemeCodeUnspsc190501.27112702': __('identifierSchemeCodeUnspsc190501.27112702'),
	'identifierSchemeCodeUnspsc190501.27112703': __('identifierSchemeCodeUnspsc190501.27112703'),
	'identifierSchemeCodeUnspsc190501.27112704': __('identifierSchemeCodeUnspsc190501.27112704'),
	'identifierSchemeCodeUnspsc190501.27112705': __('identifierSchemeCodeUnspsc190501.27112705'),
	'identifierSchemeCodeUnspsc190501.27112706': __('identifierSchemeCodeUnspsc190501.27112706'),
	'identifierSchemeCodeUnspsc190501.27112707': __('identifierSchemeCodeUnspsc190501.27112707'),
	'identifierSchemeCodeUnspsc190501.27112708': __('identifierSchemeCodeUnspsc190501.27112708'),
	'identifierSchemeCodeUnspsc190501.27112709': __('identifierSchemeCodeUnspsc190501.27112709'),
	'identifierSchemeCodeUnspsc190501.27112710': __('identifierSchemeCodeUnspsc190501.27112710'),
	'identifierSchemeCodeUnspsc190501.27112711': __('identifierSchemeCodeUnspsc190501.27112711'),
	'identifierSchemeCodeUnspsc190501.27112712': __('identifierSchemeCodeUnspsc190501.27112712'),
	'identifierSchemeCodeUnspsc190501.27112713': __('identifierSchemeCodeUnspsc190501.27112713'),
	'identifierSchemeCodeUnspsc190501.27112714': __('identifierSchemeCodeUnspsc190501.27112714'),
	'identifierSchemeCodeUnspsc190501.27112715': __('identifierSchemeCodeUnspsc190501.27112715'),
	'identifierSchemeCodeUnspsc190501.27112716': __('identifierSchemeCodeUnspsc190501.27112716'),
	'identifierSchemeCodeUnspsc190501.27112717': __('identifierSchemeCodeUnspsc190501.27112717'),
	'identifierSchemeCodeUnspsc190501.27112718': __('identifierSchemeCodeUnspsc190501.27112718'),
	'identifierSchemeCodeUnspsc190501.27112719': __('identifierSchemeCodeUnspsc190501.27112719'),
	'identifierSchemeCodeUnspsc190501.27112720': __('identifierSchemeCodeUnspsc190501.27112720'),
	'identifierSchemeCodeUnspsc190501.27112721': __('identifierSchemeCodeUnspsc190501.27112721'),
	'identifierSchemeCodeUnspsc190501.27112722': __('identifierSchemeCodeUnspsc190501.27112722'),
	'identifierSchemeCodeUnspsc190501.27112723': __('identifierSchemeCodeUnspsc190501.27112723'),
	'identifierSchemeCodeUnspsc190501.27112724': __('identifierSchemeCodeUnspsc190501.27112724'),
	'identifierSchemeCodeUnspsc190501.27112725': __('identifierSchemeCodeUnspsc190501.27112725'),
	'identifierSchemeCodeUnspsc190501.27112726': __('identifierSchemeCodeUnspsc190501.27112726'),
	'identifierSchemeCodeUnspsc190501.27112727': __('identifierSchemeCodeUnspsc190501.27112727'),
	'identifierSchemeCodeUnspsc190501.27112728': __('identifierSchemeCodeUnspsc190501.27112728'),
	'identifierSchemeCodeUnspsc190501.27112729': __('identifierSchemeCodeUnspsc190501.27112729'),
	'identifierSchemeCodeUnspsc190501.27112730': __('identifierSchemeCodeUnspsc190501.27112730'),
	'identifierSchemeCodeUnspsc190501.27112731': __('identifierSchemeCodeUnspsc190501.27112731'),
	'identifierSchemeCodeUnspsc190501.27112732': __('identifierSchemeCodeUnspsc190501.27112732'),
	'identifierSchemeCodeUnspsc190501.27112733': __('identifierSchemeCodeUnspsc190501.27112733'),
	'identifierSchemeCodeUnspsc190501.27112734': __('identifierSchemeCodeUnspsc190501.27112734'),
	'identifierSchemeCodeUnspsc190501.27112735': __('identifierSchemeCodeUnspsc190501.27112735'),
	'identifierSchemeCodeUnspsc190501.27112736': __('identifierSchemeCodeUnspsc190501.27112736'),
	'identifierSchemeCodeUnspsc190501.27112737': __('identifierSchemeCodeUnspsc190501.27112737'),
	'identifierSchemeCodeUnspsc190501.27112738': __('identifierSchemeCodeUnspsc190501.27112738'),
	'identifierSchemeCodeUnspsc190501.27112739': __('identifierSchemeCodeUnspsc190501.27112739'),
	'identifierSchemeCodeUnspsc190501.27112740': __('identifierSchemeCodeUnspsc190501.27112740'),
	'identifierSchemeCodeUnspsc190501.27112741': __('identifierSchemeCodeUnspsc190501.27112741'),
	'identifierSchemeCodeUnspsc190501.27112742': __('identifierSchemeCodeUnspsc190501.27112742'),
	'identifierSchemeCodeUnspsc190501.27112743': __('identifierSchemeCodeUnspsc190501.27112743'),
	'identifierSchemeCodeUnspsc190501.27112744': __('identifierSchemeCodeUnspsc190501.27112744'),
	'identifierSchemeCodeUnspsc190501.27112745': __('identifierSchemeCodeUnspsc190501.27112745'),
	'identifierSchemeCodeUnspsc190501.27112746': __('identifierSchemeCodeUnspsc190501.27112746'),
	'identifierSchemeCodeUnspsc190501.27112747': __('identifierSchemeCodeUnspsc190501.27112747'),
	'identifierSchemeCodeUnspsc190501.27112748': __('identifierSchemeCodeUnspsc190501.27112748'),
	'identifierSchemeCodeUnspsc190501.27112749': __('identifierSchemeCodeUnspsc190501.27112749'),
	'identifierSchemeCodeUnspsc190501.27112750': __('identifierSchemeCodeUnspsc190501.27112750'),
	'identifierSchemeCodeUnspsc190501.27112751': __('identifierSchemeCodeUnspsc190501.27112751'),
	'identifierSchemeCodeUnspsc190501.27112800': __('identifierSchemeCodeUnspsc190501.27112800'),
	'identifierSchemeCodeUnspsc190501.27112802': __('identifierSchemeCodeUnspsc190501.27112802'),
	'identifierSchemeCodeUnspsc190501.27112803': __('identifierSchemeCodeUnspsc190501.27112803'),
	'identifierSchemeCodeUnspsc190501.27112804': __('identifierSchemeCodeUnspsc190501.27112804'),
	'identifierSchemeCodeUnspsc190501.27112805': __('identifierSchemeCodeUnspsc190501.27112805'),
	'identifierSchemeCodeUnspsc190501.27112806': __('identifierSchemeCodeUnspsc190501.27112806'),
	'identifierSchemeCodeUnspsc190501.27112807': __('identifierSchemeCodeUnspsc190501.27112807'),
	'identifierSchemeCodeUnspsc190501.27112808': __('identifierSchemeCodeUnspsc190501.27112808'),
	'identifierSchemeCodeUnspsc190501.27112809': __('identifierSchemeCodeUnspsc190501.27112809'),
	'identifierSchemeCodeUnspsc190501.27112810': __('identifierSchemeCodeUnspsc190501.27112810'),
	'identifierSchemeCodeUnspsc190501.27112811': __('identifierSchemeCodeUnspsc190501.27112811'),
	'identifierSchemeCodeUnspsc190501.27112812': __('identifierSchemeCodeUnspsc190501.27112812'),
	'identifierSchemeCodeUnspsc190501.27112813': __('identifierSchemeCodeUnspsc190501.27112813'),
	'identifierSchemeCodeUnspsc190501.27112814': __('identifierSchemeCodeUnspsc190501.27112814'),
	'identifierSchemeCodeUnspsc190501.27112815': __('identifierSchemeCodeUnspsc190501.27112815'),
	'identifierSchemeCodeUnspsc190501.27112818': __('identifierSchemeCodeUnspsc190501.27112818'),
	'identifierSchemeCodeUnspsc190501.27112819': __('identifierSchemeCodeUnspsc190501.27112819'),
	'identifierSchemeCodeUnspsc190501.27112820': __('identifierSchemeCodeUnspsc190501.27112820'),
	'identifierSchemeCodeUnspsc190501.27112821': __('identifierSchemeCodeUnspsc190501.27112821'),
	'identifierSchemeCodeUnspsc190501.27112822': __('identifierSchemeCodeUnspsc190501.27112822'),
	'identifierSchemeCodeUnspsc190501.27112823': __('identifierSchemeCodeUnspsc190501.27112823'),
	'identifierSchemeCodeUnspsc190501.27112824': __('identifierSchemeCodeUnspsc190501.27112824'),
	'identifierSchemeCodeUnspsc190501.27112825': __('identifierSchemeCodeUnspsc190501.27112825'),
	'identifierSchemeCodeUnspsc190501.27112826': __('identifierSchemeCodeUnspsc190501.27112826'),
	'identifierSchemeCodeUnspsc190501.27112827': __('identifierSchemeCodeUnspsc190501.27112827'),
	'identifierSchemeCodeUnspsc190501.27112828': __('identifierSchemeCodeUnspsc190501.27112828'),
	'identifierSchemeCodeUnspsc190501.27112829': __('identifierSchemeCodeUnspsc190501.27112829'),
	'identifierSchemeCodeUnspsc190501.27112830': __('identifierSchemeCodeUnspsc190501.27112830'),
	'identifierSchemeCodeUnspsc190501.27112831': __('identifierSchemeCodeUnspsc190501.27112831'),
	'identifierSchemeCodeUnspsc190501.27112832': __('identifierSchemeCodeUnspsc190501.27112832'),
	'identifierSchemeCodeUnspsc190501.27112833': __('identifierSchemeCodeUnspsc190501.27112833'),
	'identifierSchemeCodeUnspsc190501.27112834': __('identifierSchemeCodeUnspsc190501.27112834'),
	'identifierSchemeCodeUnspsc190501.27112835': __('identifierSchemeCodeUnspsc190501.27112835'),
	'identifierSchemeCodeUnspsc190501.27112836': __('identifierSchemeCodeUnspsc190501.27112836'),
	'identifierSchemeCodeUnspsc190501.27112837': __('identifierSchemeCodeUnspsc190501.27112837'),
	'identifierSchemeCodeUnspsc190501.27112838': __('identifierSchemeCodeUnspsc190501.27112838'),
	'identifierSchemeCodeUnspsc190501.27112839': __('identifierSchemeCodeUnspsc190501.27112839'),
	'identifierSchemeCodeUnspsc190501.27112840': __('identifierSchemeCodeUnspsc190501.27112840'),
	'identifierSchemeCodeUnspsc190501.27112841': __('identifierSchemeCodeUnspsc190501.27112841'),
	'identifierSchemeCodeUnspsc190501.27112842': __('identifierSchemeCodeUnspsc190501.27112842'),
	'identifierSchemeCodeUnspsc190501.27112843': __('identifierSchemeCodeUnspsc190501.27112843'),
	'identifierSchemeCodeUnspsc190501.27112844': __('identifierSchemeCodeUnspsc190501.27112844'),
	'identifierSchemeCodeUnspsc190501.27112845': __('identifierSchemeCodeUnspsc190501.27112845'),
	'identifierSchemeCodeUnspsc190501.27112846': __('identifierSchemeCodeUnspsc190501.27112846'),
	'identifierSchemeCodeUnspsc190501.27112847': __('identifierSchemeCodeUnspsc190501.27112847'),
	'identifierSchemeCodeUnspsc190501.27112900': __('identifierSchemeCodeUnspsc190501.27112900'),
	'identifierSchemeCodeUnspsc190501.27112901': __('identifierSchemeCodeUnspsc190501.27112901'),
	'identifierSchemeCodeUnspsc190501.27112902': __('identifierSchemeCodeUnspsc190501.27112902'),
	'identifierSchemeCodeUnspsc190501.27112903': __('identifierSchemeCodeUnspsc190501.27112903'),
	'identifierSchemeCodeUnspsc190501.27112904': __('identifierSchemeCodeUnspsc190501.27112904'),
	'identifierSchemeCodeUnspsc190501.27112905': __('identifierSchemeCodeUnspsc190501.27112905'),
	'identifierSchemeCodeUnspsc190501.27112906': __('identifierSchemeCodeUnspsc190501.27112906'),
	'identifierSchemeCodeUnspsc190501.27112907': __('identifierSchemeCodeUnspsc190501.27112907'),
	'identifierSchemeCodeUnspsc190501.27112908': __('identifierSchemeCodeUnspsc190501.27112908'),
	'identifierSchemeCodeUnspsc190501.27112909': __('identifierSchemeCodeUnspsc190501.27112909'),
	'identifierSchemeCodeUnspsc190501.27112910': __('identifierSchemeCodeUnspsc190501.27112910'),
	'identifierSchemeCodeUnspsc190501.27112911': __('identifierSchemeCodeUnspsc190501.27112911'),
	'identifierSchemeCodeUnspsc190501.27112912': __('identifierSchemeCodeUnspsc190501.27112912'),
	'identifierSchemeCodeUnspsc190501.27112913': __('identifierSchemeCodeUnspsc190501.27112913'),
	'identifierSchemeCodeUnspsc190501.27112914': __('identifierSchemeCodeUnspsc190501.27112914'),
	'identifierSchemeCodeUnspsc190501.27112915': __('identifierSchemeCodeUnspsc190501.27112915'),
	'identifierSchemeCodeUnspsc190501.27112916': __('identifierSchemeCodeUnspsc190501.27112916'),
	'identifierSchemeCodeUnspsc190501.27113000': __('identifierSchemeCodeUnspsc190501.27113000'),
	'identifierSchemeCodeUnspsc190501.27113001': __('identifierSchemeCodeUnspsc190501.27113001'),
	'identifierSchemeCodeUnspsc190501.27113002': __('identifierSchemeCodeUnspsc190501.27113002'),
	'identifierSchemeCodeUnspsc190501.27113003': __('identifierSchemeCodeUnspsc190501.27113003'),
	'identifierSchemeCodeUnspsc190501.27113004': __('identifierSchemeCodeUnspsc190501.27113004'),
	'identifierSchemeCodeUnspsc190501.27113005': __('identifierSchemeCodeUnspsc190501.27113005'),
	'identifierSchemeCodeUnspsc190501.27113100': __('identifierSchemeCodeUnspsc190501.27113100'),
	'identifierSchemeCodeUnspsc190501.27113101': __('identifierSchemeCodeUnspsc190501.27113101'),
	'identifierSchemeCodeUnspsc190501.27113102': __('identifierSchemeCodeUnspsc190501.27113102'),
	'identifierSchemeCodeUnspsc190501.27113103': __('identifierSchemeCodeUnspsc190501.27113103'),
	'identifierSchemeCodeUnspsc190501.27113104': __('identifierSchemeCodeUnspsc190501.27113104'),
	'identifierSchemeCodeUnspsc190501.27113105': __('identifierSchemeCodeUnspsc190501.27113105'),
	'identifierSchemeCodeUnspsc190501.27113200': __('identifierSchemeCodeUnspsc190501.27113200'),
	'identifierSchemeCodeUnspsc190501.27113201': __('identifierSchemeCodeUnspsc190501.27113201'),
	'identifierSchemeCodeUnspsc190501.27113202': __('identifierSchemeCodeUnspsc190501.27113202'),
	'identifierSchemeCodeUnspsc190501.27113203': __('identifierSchemeCodeUnspsc190501.27113203'),
	'identifierSchemeCodeUnspsc190501.27113204': __('identifierSchemeCodeUnspsc190501.27113204'),
	'identifierSchemeCodeUnspsc190501.27113300': __('identifierSchemeCodeUnspsc190501.27113300'),
	'identifierSchemeCodeUnspsc190501.27113301': __('identifierSchemeCodeUnspsc190501.27113301'),
	'identifierSchemeCodeUnspsc190501.27120000': __('identifierSchemeCodeUnspsc190501.27120000'),
	'identifierSchemeCodeUnspsc190501.27121500': __('identifierSchemeCodeUnspsc190501.27121500'),
	'identifierSchemeCodeUnspsc190501.27121501': __('identifierSchemeCodeUnspsc190501.27121501'),
	'identifierSchemeCodeUnspsc190501.27121502': __('identifierSchemeCodeUnspsc190501.27121502'),
	'identifierSchemeCodeUnspsc190501.27121503': __('identifierSchemeCodeUnspsc190501.27121503'),
	'identifierSchemeCodeUnspsc190501.27121504': __('identifierSchemeCodeUnspsc190501.27121504'),
	'identifierSchemeCodeUnspsc190501.27121600': __('identifierSchemeCodeUnspsc190501.27121600'),
	'identifierSchemeCodeUnspsc190501.27121601': __('identifierSchemeCodeUnspsc190501.27121601'),
	'identifierSchemeCodeUnspsc190501.27121602': __('identifierSchemeCodeUnspsc190501.27121602'),
	'identifierSchemeCodeUnspsc190501.27121603': __('identifierSchemeCodeUnspsc190501.27121603'),
	'identifierSchemeCodeUnspsc190501.27121604': __('identifierSchemeCodeUnspsc190501.27121604'),
	'identifierSchemeCodeUnspsc190501.27121605': __('identifierSchemeCodeUnspsc190501.27121605'),
	'identifierSchemeCodeUnspsc190501.27121606': __('identifierSchemeCodeUnspsc190501.27121606'),
	'identifierSchemeCodeUnspsc190501.27121700': __('identifierSchemeCodeUnspsc190501.27121700'),
	'identifierSchemeCodeUnspsc190501.27121701': __('identifierSchemeCodeUnspsc190501.27121701'),
	'identifierSchemeCodeUnspsc190501.27121702': __('identifierSchemeCodeUnspsc190501.27121702'),
	'identifierSchemeCodeUnspsc190501.27121703': __('identifierSchemeCodeUnspsc190501.27121703'),
	'identifierSchemeCodeUnspsc190501.27121704': __('identifierSchemeCodeUnspsc190501.27121704'),
	'identifierSchemeCodeUnspsc190501.27121705': __('identifierSchemeCodeUnspsc190501.27121705'),
	'identifierSchemeCodeUnspsc190501.27121706': __('identifierSchemeCodeUnspsc190501.27121706'),
	'identifierSchemeCodeUnspsc190501.27121707': __('identifierSchemeCodeUnspsc190501.27121707'),
	'identifierSchemeCodeUnspsc190501.27121800': __('identifierSchemeCodeUnspsc190501.27121800'),
	'identifierSchemeCodeUnspsc190501.27121801': __('identifierSchemeCodeUnspsc190501.27121801'),
	'identifierSchemeCodeUnspsc190501.27121802': __('identifierSchemeCodeUnspsc190501.27121802'),
	'identifierSchemeCodeUnspsc190501.27121803': __('identifierSchemeCodeUnspsc190501.27121803'),
	'identifierSchemeCodeUnspsc190501.27121804': __('identifierSchemeCodeUnspsc190501.27121804'),
	'identifierSchemeCodeUnspsc190501.27121805': __('identifierSchemeCodeUnspsc190501.27121805'),
	'identifierSchemeCodeUnspsc190501.27121806': __('identifierSchemeCodeUnspsc190501.27121806'),
	'identifierSchemeCodeUnspsc190501.27121807': __('identifierSchemeCodeUnspsc190501.27121807'),
	'identifierSchemeCodeUnspsc190501.27121808': __('identifierSchemeCodeUnspsc190501.27121808'),
	'identifierSchemeCodeUnspsc190501.27121809': __('identifierSchemeCodeUnspsc190501.27121809'),
	'identifierSchemeCodeUnspsc190501.27121810': __('identifierSchemeCodeUnspsc190501.27121810'),
	'identifierSchemeCodeUnspsc190501.27121811': __('identifierSchemeCodeUnspsc190501.27121811'),
	'identifierSchemeCodeUnspsc190501.27121812': __('identifierSchemeCodeUnspsc190501.27121812'),
	'identifierSchemeCodeUnspsc190501.27121813': __('identifierSchemeCodeUnspsc190501.27121813'),
	'identifierSchemeCodeUnspsc190501.27121814': __('identifierSchemeCodeUnspsc190501.27121814'),
	'identifierSchemeCodeUnspsc190501.27121815': __('identifierSchemeCodeUnspsc190501.27121815'),
	'identifierSchemeCodeUnspsc190501.27130000': __('identifierSchemeCodeUnspsc190501.27130000'),
	'identifierSchemeCodeUnspsc190501.27131500': __('identifierSchemeCodeUnspsc190501.27131500'),
	'identifierSchemeCodeUnspsc190501.27131501': __('identifierSchemeCodeUnspsc190501.27131501'),
	'identifierSchemeCodeUnspsc190501.27131502': __('identifierSchemeCodeUnspsc190501.27131502'),
	'identifierSchemeCodeUnspsc190501.27131504': __('identifierSchemeCodeUnspsc190501.27131504'),
	'identifierSchemeCodeUnspsc190501.27131505': __('identifierSchemeCodeUnspsc190501.27131505'),
	'identifierSchemeCodeUnspsc190501.27131506': __('identifierSchemeCodeUnspsc190501.27131506'),
	'identifierSchemeCodeUnspsc190501.27131507': __('identifierSchemeCodeUnspsc190501.27131507'),
	'identifierSchemeCodeUnspsc190501.27131508': __('identifierSchemeCodeUnspsc190501.27131508'),
	'identifierSchemeCodeUnspsc190501.27131509': __('identifierSchemeCodeUnspsc190501.27131509'),
	'identifierSchemeCodeUnspsc190501.27131510': __('identifierSchemeCodeUnspsc190501.27131510'),
	'identifierSchemeCodeUnspsc190501.27131511': __('identifierSchemeCodeUnspsc190501.27131511'),
	'identifierSchemeCodeUnspsc190501.27131512': __('identifierSchemeCodeUnspsc190501.27131512'),
	'identifierSchemeCodeUnspsc190501.27131513': __('identifierSchemeCodeUnspsc190501.27131513'),
	'identifierSchemeCodeUnspsc190501.27131514': __('identifierSchemeCodeUnspsc190501.27131514'),
	'identifierSchemeCodeUnspsc190501.27131515': __('identifierSchemeCodeUnspsc190501.27131515'),
	'identifierSchemeCodeUnspsc190501.27131516': __('identifierSchemeCodeUnspsc190501.27131516'),
	'identifierSchemeCodeUnspsc190501.27131517': __('identifierSchemeCodeUnspsc190501.27131517'),
	'identifierSchemeCodeUnspsc190501.27131518': __('identifierSchemeCodeUnspsc190501.27131518'),
	'identifierSchemeCodeUnspsc190501.27131519': __('identifierSchemeCodeUnspsc190501.27131519'),
	'identifierSchemeCodeUnspsc190501.27131520': __('identifierSchemeCodeUnspsc190501.27131520'),
	'identifierSchemeCodeUnspsc190501.27131521': __('identifierSchemeCodeUnspsc190501.27131521'),
	'identifierSchemeCodeUnspsc190501.27131522': __('identifierSchemeCodeUnspsc190501.27131522'),
	'identifierSchemeCodeUnspsc190501.27131523': __('identifierSchemeCodeUnspsc190501.27131523'),
	'identifierSchemeCodeUnspsc190501.27131524': __('identifierSchemeCodeUnspsc190501.27131524'),
	'identifierSchemeCodeUnspsc190501.27131525': __('identifierSchemeCodeUnspsc190501.27131525'),
	'identifierSchemeCodeUnspsc190501.27131526': __('identifierSchemeCodeUnspsc190501.27131526'),
	'identifierSchemeCodeUnspsc190501.27131527': __('identifierSchemeCodeUnspsc190501.27131527'),
	'identifierSchemeCodeUnspsc190501.27131528': __('identifierSchemeCodeUnspsc190501.27131528'),
	'identifierSchemeCodeUnspsc190501.27131600': __('identifierSchemeCodeUnspsc190501.27131600'),
	'identifierSchemeCodeUnspsc190501.27131601': __('identifierSchemeCodeUnspsc190501.27131601'),
	'identifierSchemeCodeUnspsc190501.27131603': __('identifierSchemeCodeUnspsc190501.27131603'),
	'identifierSchemeCodeUnspsc190501.27131604': __('identifierSchemeCodeUnspsc190501.27131604'),
	'identifierSchemeCodeUnspsc190501.27131605': __('identifierSchemeCodeUnspsc190501.27131605'),
	'identifierSchemeCodeUnspsc190501.27131608': __('identifierSchemeCodeUnspsc190501.27131608'),
	'identifierSchemeCodeUnspsc190501.27131609': __('identifierSchemeCodeUnspsc190501.27131609'),
	'identifierSchemeCodeUnspsc190501.27131610': __('identifierSchemeCodeUnspsc190501.27131610'),
	'identifierSchemeCodeUnspsc190501.27131613': __('identifierSchemeCodeUnspsc190501.27131613'),
	'identifierSchemeCodeUnspsc190501.27131614': __('identifierSchemeCodeUnspsc190501.27131614'),
	'identifierSchemeCodeUnspsc190501.27131615': __('identifierSchemeCodeUnspsc190501.27131615'),
	'identifierSchemeCodeUnspsc190501.27131700': __('identifierSchemeCodeUnspsc190501.27131700'),
	'identifierSchemeCodeUnspsc190501.27131701': __('identifierSchemeCodeUnspsc190501.27131701'),
	'identifierSchemeCodeUnspsc190501.27131702': __('identifierSchemeCodeUnspsc190501.27131702'),
	'identifierSchemeCodeUnspsc190501.27131703': __('identifierSchemeCodeUnspsc190501.27131703'),
	'identifierSchemeCodeUnspsc190501.27131704': __('identifierSchemeCodeUnspsc190501.27131704'),
	'identifierSchemeCodeUnspsc190501.27131705': __('identifierSchemeCodeUnspsc190501.27131705'),
	'identifierSchemeCodeUnspsc190501.27131706': __('identifierSchemeCodeUnspsc190501.27131706'),
	'identifierSchemeCodeUnspsc190501.27131707': __('identifierSchemeCodeUnspsc190501.27131707'),
	'identifierSchemeCodeUnspsc190501.27131708': __('identifierSchemeCodeUnspsc190501.27131708'),
	'identifierSchemeCodeUnspsc190501.27131709': __('identifierSchemeCodeUnspsc190501.27131709'),
	'identifierSchemeCodeUnspsc190501.27140000': __('identifierSchemeCodeUnspsc190501.27140000'),
	'identifierSchemeCodeUnspsc190501.27141000': __('identifierSchemeCodeUnspsc190501.27141000'),
	'identifierSchemeCodeUnspsc190501.27141001': __('identifierSchemeCodeUnspsc190501.27141001'),
	'identifierSchemeCodeUnspsc190501.27141100': __('identifierSchemeCodeUnspsc190501.27141100'),
	'identifierSchemeCodeUnspsc190501.27141101': __('identifierSchemeCodeUnspsc190501.27141101'),
	'identifierSchemeCodeUnspsc190501.27150000': __('identifierSchemeCodeUnspsc190501.27150000'),
	'identifierSchemeCodeUnspsc190501.27151500': __('identifierSchemeCodeUnspsc190501.27151500'),
	'identifierSchemeCodeUnspsc190501.27151501': __('identifierSchemeCodeUnspsc190501.27151501'),
	'identifierSchemeCodeUnspsc190501.27151502': __('identifierSchemeCodeUnspsc190501.27151502'),
	'identifierSchemeCodeUnspsc190501.27151503': __('identifierSchemeCodeUnspsc190501.27151503'),
	'identifierSchemeCodeUnspsc190501.30000000': __('identifierSchemeCodeUnspsc190501.30000000'),
	'identifierSchemeCodeUnspsc190501.30100000': __('identifierSchemeCodeUnspsc190501.30100000'),
	'identifierSchemeCodeUnspsc190501.30101500': __('identifierSchemeCodeUnspsc190501.30101500'),
	'identifierSchemeCodeUnspsc190501.30101501': __('identifierSchemeCodeUnspsc190501.30101501'),
	'identifierSchemeCodeUnspsc190501.30101502': __('identifierSchemeCodeUnspsc190501.30101502'),
	'identifierSchemeCodeUnspsc190501.30101503': __('identifierSchemeCodeUnspsc190501.30101503'),
	'identifierSchemeCodeUnspsc190501.30101504': __('identifierSchemeCodeUnspsc190501.30101504'),
	'identifierSchemeCodeUnspsc190501.30101505': __('identifierSchemeCodeUnspsc190501.30101505'),
	'identifierSchemeCodeUnspsc190501.30101506': __('identifierSchemeCodeUnspsc190501.30101506'),
	'identifierSchemeCodeUnspsc190501.30101507': __('identifierSchemeCodeUnspsc190501.30101507'),
	'identifierSchemeCodeUnspsc190501.30101508': __('identifierSchemeCodeUnspsc190501.30101508'),
	'identifierSchemeCodeUnspsc190501.30101509': __('identifierSchemeCodeUnspsc190501.30101509'),
	'identifierSchemeCodeUnspsc190501.30101510': __('identifierSchemeCodeUnspsc190501.30101510'),
	'identifierSchemeCodeUnspsc190501.30101511': __('identifierSchemeCodeUnspsc190501.30101511'),
	'identifierSchemeCodeUnspsc190501.30101512': __('identifierSchemeCodeUnspsc190501.30101512'),
	'identifierSchemeCodeUnspsc190501.30101513': __('identifierSchemeCodeUnspsc190501.30101513'),
	'identifierSchemeCodeUnspsc190501.30101514': __('identifierSchemeCodeUnspsc190501.30101514'),
	'identifierSchemeCodeUnspsc190501.30101515': __('identifierSchemeCodeUnspsc190501.30101515'),
	'identifierSchemeCodeUnspsc190501.30101516': __('identifierSchemeCodeUnspsc190501.30101516'),
	'identifierSchemeCodeUnspsc190501.30101517': __('identifierSchemeCodeUnspsc190501.30101517'),
	'identifierSchemeCodeUnspsc190501.30101700': __('identifierSchemeCodeUnspsc190501.30101700'),
	'identifierSchemeCodeUnspsc190501.30101701': __('identifierSchemeCodeUnspsc190501.30101701'),
	'identifierSchemeCodeUnspsc190501.30101702': __('identifierSchemeCodeUnspsc190501.30101702'),
	'identifierSchemeCodeUnspsc190501.30101703': __('identifierSchemeCodeUnspsc190501.30101703'),
	'identifierSchemeCodeUnspsc190501.30101704': __('identifierSchemeCodeUnspsc190501.30101704'),
	'identifierSchemeCodeUnspsc190501.30101705': __('identifierSchemeCodeUnspsc190501.30101705'),
	'identifierSchemeCodeUnspsc190501.30101706': __('identifierSchemeCodeUnspsc190501.30101706'),
	'identifierSchemeCodeUnspsc190501.30101707': __('identifierSchemeCodeUnspsc190501.30101707'),
	'identifierSchemeCodeUnspsc190501.30101708': __('identifierSchemeCodeUnspsc190501.30101708'),
	'identifierSchemeCodeUnspsc190501.30101709': __('identifierSchemeCodeUnspsc190501.30101709'),
	'identifierSchemeCodeUnspsc190501.30101710': __('identifierSchemeCodeUnspsc190501.30101710'),
	'identifierSchemeCodeUnspsc190501.30101711': __('identifierSchemeCodeUnspsc190501.30101711'),
	'identifierSchemeCodeUnspsc190501.30101712': __('identifierSchemeCodeUnspsc190501.30101712'),
	'identifierSchemeCodeUnspsc190501.30101713': __('identifierSchemeCodeUnspsc190501.30101713'),
	'identifierSchemeCodeUnspsc190501.30101714': __('identifierSchemeCodeUnspsc190501.30101714'),
	'identifierSchemeCodeUnspsc190501.30101715': __('identifierSchemeCodeUnspsc190501.30101715'),
	'identifierSchemeCodeUnspsc190501.30101716': __('identifierSchemeCodeUnspsc190501.30101716'),
	'identifierSchemeCodeUnspsc190501.30101717': __('identifierSchemeCodeUnspsc190501.30101717'),
	'identifierSchemeCodeUnspsc190501.30101718': __('identifierSchemeCodeUnspsc190501.30101718'),
	'identifierSchemeCodeUnspsc190501.30101719': __('identifierSchemeCodeUnspsc190501.30101719'),
	'identifierSchemeCodeUnspsc190501.30101720': __('identifierSchemeCodeUnspsc190501.30101720'),
	'identifierSchemeCodeUnspsc190501.30101800': __('identifierSchemeCodeUnspsc190501.30101800'),
	'identifierSchemeCodeUnspsc190501.30101801': __('identifierSchemeCodeUnspsc190501.30101801'),
	'identifierSchemeCodeUnspsc190501.30101802': __('identifierSchemeCodeUnspsc190501.30101802'),
	'identifierSchemeCodeUnspsc190501.30101803': __('identifierSchemeCodeUnspsc190501.30101803'),
	'identifierSchemeCodeUnspsc190501.30101804': __('identifierSchemeCodeUnspsc190501.30101804'),
	'identifierSchemeCodeUnspsc190501.30101805': __('identifierSchemeCodeUnspsc190501.30101805'),
	'identifierSchemeCodeUnspsc190501.30101806': __('identifierSchemeCodeUnspsc190501.30101806'),
	'identifierSchemeCodeUnspsc190501.30101807': __('identifierSchemeCodeUnspsc190501.30101807'),
	'identifierSchemeCodeUnspsc190501.30101808': __('identifierSchemeCodeUnspsc190501.30101808'),
	'identifierSchemeCodeUnspsc190501.30101809': __('identifierSchemeCodeUnspsc190501.30101809'),
	'identifierSchemeCodeUnspsc190501.30101810': __('identifierSchemeCodeUnspsc190501.30101810'),
	'identifierSchemeCodeUnspsc190501.30101811': __('identifierSchemeCodeUnspsc190501.30101811'),
	'identifierSchemeCodeUnspsc190501.30101812': __('identifierSchemeCodeUnspsc190501.30101812'),
	'identifierSchemeCodeUnspsc190501.30101813': __('identifierSchemeCodeUnspsc190501.30101813'),
	'identifierSchemeCodeUnspsc190501.30101814': __('identifierSchemeCodeUnspsc190501.30101814'),
	'identifierSchemeCodeUnspsc190501.30101815': __('identifierSchemeCodeUnspsc190501.30101815'),
	'identifierSchemeCodeUnspsc190501.30101816': __('identifierSchemeCodeUnspsc190501.30101816'),
	'identifierSchemeCodeUnspsc190501.30101817': __('identifierSchemeCodeUnspsc190501.30101817'),
	'identifierSchemeCodeUnspsc190501.30101818': __('identifierSchemeCodeUnspsc190501.30101818'),
	'identifierSchemeCodeUnspsc190501.30102000': __('identifierSchemeCodeUnspsc190501.30102000'),
	'identifierSchemeCodeUnspsc190501.30102001': __('identifierSchemeCodeUnspsc190501.30102001'),
	'identifierSchemeCodeUnspsc190501.30102002': __('identifierSchemeCodeUnspsc190501.30102002'),
	'identifierSchemeCodeUnspsc190501.30102003': __('identifierSchemeCodeUnspsc190501.30102003'),
	'identifierSchemeCodeUnspsc190501.30102004': __('identifierSchemeCodeUnspsc190501.30102004'),
	'identifierSchemeCodeUnspsc190501.30102005': __('identifierSchemeCodeUnspsc190501.30102005'),
	'identifierSchemeCodeUnspsc190501.30102006': __('identifierSchemeCodeUnspsc190501.30102006'),
	'identifierSchemeCodeUnspsc190501.30102007': __('identifierSchemeCodeUnspsc190501.30102007'),
	'identifierSchemeCodeUnspsc190501.30102008': __('identifierSchemeCodeUnspsc190501.30102008'),
	'identifierSchemeCodeUnspsc190501.30102009': __('identifierSchemeCodeUnspsc190501.30102009'),
	'identifierSchemeCodeUnspsc190501.30102010': __('identifierSchemeCodeUnspsc190501.30102010'),
	'identifierSchemeCodeUnspsc190501.30102011': __('identifierSchemeCodeUnspsc190501.30102011'),
	'identifierSchemeCodeUnspsc190501.30102012': __('identifierSchemeCodeUnspsc190501.30102012'),
	'identifierSchemeCodeUnspsc190501.30102013': __('identifierSchemeCodeUnspsc190501.30102013'),
	'identifierSchemeCodeUnspsc190501.30102014': __('identifierSchemeCodeUnspsc190501.30102014'),
	'identifierSchemeCodeUnspsc190501.30102015': __('identifierSchemeCodeUnspsc190501.30102015'),
	'identifierSchemeCodeUnspsc190501.30102016': __('identifierSchemeCodeUnspsc190501.30102016'),
	'identifierSchemeCodeUnspsc190501.30102200': __('identifierSchemeCodeUnspsc190501.30102200'),
	'identifierSchemeCodeUnspsc190501.30102201': __('identifierSchemeCodeUnspsc190501.30102201'),
	'identifierSchemeCodeUnspsc190501.30102202': __('identifierSchemeCodeUnspsc190501.30102202'),
	'identifierSchemeCodeUnspsc190501.30102203': __('identifierSchemeCodeUnspsc190501.30102203'),
	'identifierSchemeCodeUnspsc190501.30102204': __('identifierSchemeCodeUnspsc190501.30102204'),
	'identifierSchemeCodeUnspsc190501.30102205': __('identifierSchemeCodeUnspsc190501.30102205'),
	'identifierSchemeCodeUnspsc190501.30102206': __('identifierSchemeCodeUnspsc190501.30102206'),
	'identifierSchemeCodeUnspsc190501.30102207': __('identifierSchemeCodeUnspsc190501.30102207'),
	'identifierSchemeCodeUnspsc190501.30102208': __('identifierSchemeCodeUnspsc190501.30102208'),
	'identifierSchemeCodeUnspsc190501.30102209': __('identifierSchemeCodeUnspsc190501.30102209'),
	'identifierSchemeCodeUnspsc190501.30102210': __('identifierSchemeCodeUnspsc190501.30102210'),
	'identifierSchemeCodeUnspsc190501.30102211': __('identifierSchemeCodeUnspsc190501.30102211'),
	'identifierSchemeCodeUnspsc190501.30102212': __('identifierSchemeCodeUnspsc190501.30102212'),
	'identifierSchemeCodeUnspsc190501.30102213': __('identifierSchemeCodeUnspsc190501.30102213'),
	'identifierSchemeCodeUnspsc190501.30102214': __('identifierSchemeCodeUnspsc190501.30102214'),
	'identifierSchemeCodeUnspsc190501.30102215': __('identifierSchemeCodeUnspsc190501.30102215'),
	'identifierSchemeCodeUnspsc190501.30102216': __('identifierSchemeCodeUnspsc190501.30102216'),
	'identifierSchemeCodeUnspsc190501.30102217': __('identifierSchemeCodeUnspsc190501.30102217'),
	'identifierSchemeCodeUnspsc190501.30102218': __('identifierSchemeCodeUnspsc190501.30102218'),
	'identifierSchemeCodeUnspsc190501.30102220': __('identifierSchemeCodeUnspsc190501.30102220'),
	'identifierSchemeCodeUnspsc190501.30102221': __('identifierSchemeCodeUnspsc190501.30102221'),
	'identifierSchemeCodeUnspsc190501.30102222': __('identifierSchemeCodeUnspsc190501.30102222'),
	'identifierSchemeCodeUnspsc190501.30102223': __('identifierSchemeCodeUnspsc190501.30102223'),
	'identifierSchemeCodeUnspsc190501.30102224': __('identifierSchemeCodeUnspsc190501.30102224'),
	'identifierSchemeCodeUnspsc190501.30102225': __('identifierSchemeCodeUnspsc190501.30102225'),
	'identifierSchemeCodeUnspsc190501.30102226': __('identifierSchemeCodeUnspsc190501.30102226'),
	'identifierSchemeCodeUnspsc190501.30102227': __('identifierSchemeCodeUnspsc190501.30102227'),
	'identifierSchemeCodeUnspsc190501.30102300': __('identifierSchemeCodeUnspsc190501.30102300'),
	'identifierSchemeCodeUnspsc190501.30102301': __('identifierSchemeCodeUnspsc190501.30102301'),
	'identifierSchemeCodeUnspsc190501.30102302': __('identifierSchemeCodeUnspsc190501.30102302'),
	'identifierSchemeCodeUnspsc190501.30102303': __('identifierSchemeCodeUnspsc190501.30102303'),
	'identifierSchemeCodeUnspsc190501.30102304': __('identifierSchemeCodeUnspsc190501.30102304'),
	'identifierSchemeCodeUnspsc190501.30102305': __('identifierSchemeCodeUnspsc190501.30102305'),
	'identifierSchemeCodeUnspsc190501.30102306': __('identifierSchemeCodeUnspsc190501.30102306'),
	'identifierSchemeCodeUnspsc190501.30102307': __('identifierSchemeCodeUnspsc190501.30102307'),
	'identifierSchemeCodeUnspsc190501.30102308': __('identifierSchemeCodeUnspsc190501.30102308'),
	'identifierSchemeCodeUnspsc190501.30102309': __('identifierSchemeCodeUnspsc190501.30102309'),
	'identifierSchemeCodeUnspsc190501.30102310': __('identifierSchemeCodeUnspsc190501.30102310'),
	'identifierSchemeCodeUnspsc190501.30102311': __('identifierSchemeCodeUnspsc190501.30102311'),
	'identifierSchemeCodeUnspsc190501.30102312': __('identifierSchemeCodeUnspsc190501.30102312'),
	'identifierSchemeCodeUnspsc190501.30102313': __('identifierSchemeCodeUnspsc190501.30102313'),
	'identifierSchemeCodeUnspsc190501.30102314': __('identifierSchemeCodeUnspsc190501.30102314'),
	'identifierSchemeCodeUnspsc190501.30102315': __('identifierSchemeCodeUnspsc190501.30102315'),
	'identifierSchemeCodeUnspsc190501.30102316': __('identifierSchemeCodeUnspsc190501.30102316'),
	'identifierSchemeCodeUnspsc190501.30102317': __('identifierSchemeCodeUnspsc190501.30102317'),
	'identifierSchemeCodeUnspsc190501.30102400': __('identifierSchemeCodeUnspsc190501.30102400'),
	'identifierSchemeCodeUnspsc190501.30102401': __('identifierSchemeCodeUnspsc190501.30102401'),
	'identifierSchemeCodeUnspsc190501.30102402': __('identifierSchemeCodeUnspsc190501.30102402'),
	'identifierSchemeCodeUnspsc190501.30102403': __('identifierSchemeCodeUnspsc190501.30102403'),
	'identifierSchemeCodeUnspsc190501.30102404': __('identifierSchemeCodeUnspsc190501.30102404'),
	'identifierSchemeCodeUnspsc190501.30102405': __('identifierSchemeCodeUnspsc190501.30102405'),
	'identifierSchemeCodeUnspsc190501.30102406': __('identifierSchemeCodeUnspsc190501.30102406'),
	'identifierSchemeCodeUnspsc190501.30102407': __('identifierSchemeCodeUnspsc190501.30102407'),
	'identifierSchemeCodeUnspsc190501.30102408': __('identifierSchemeCodeUnspsc190501.30102408'),
	'identifierSchemeCodeUnspsc190501.30102409': __('identifierSchemeCodeUnspsc190501.30102409'),
	'identifierSchemeCodeUnspsc190501.30102410': __('identifierSchemeCodeUnspsc190501.30102410'),
	'identifierSchemeCodeUnspsc190501.30102411': __('identifierSchemeCodeUnspsc190501.30102411'),
	'identifierSchemeCodeUnspsc190501.30102412': __('identifierSchemeCodeUnspsc190501.30102412'),
	'identifierSchemeCodeUnspsc190501.30102413': __('identifierSchemeCodeUnspsc190501.30102413'),
	'identifierSchemeCodeUnspsc190501.30102414': __('identifierSchemeCodeUnspsc190501.30102414'),
	'identifierSchemeCodeUnspsc190501.30102415': __('identifierSchemeCodeUnspsc190501.30102415'),
	'identifierSchemeCodeUnspsc190501.30102416': __('identifierSchemeCodeUnspsc190501.30102416'),
	'identifierSchemeCodeUnspsc190501.30102417': __('identifierSchemeCodeUnspsc190501.30102417'),
	'identifierSchemeCodeUnspsc190501.30102800': __('identifierSchemeCodeUnspsc190501.30102800'),
	'identifierSchemeCodeUnspsc190501.30102801': __('identifierSchemeCodeUnspsc190501.30102801'),
	'identifierSchemeCodeUnspsc190501.30102802': __('identifierSchemeCodeUnspsc190501.30102802'),
	'identifierSchemeCodeUnspsc190501.30102803': __('identifierSchemeCodeUnspsc190501.30102803'),
	'identifierSchemeCodeUnspsc190501.30102804': __('identifierSchemeCodeUnspsc190501.30102804'),
	'identifierSchemeCodeUnspsc190501.30102900': __('identifierSchemeCodeUnspsc190501.30102900'),
	'identifierSchemeCodeUnspsc190501.30102901': __('identifierSchemeCodeUnspsc190501.30102901'),
	'identifierSchemeCodeUnspsc190501.30102903': __('identifierSchemeCodeUnspsc190501.30102903'),
	'identifierSchemeCodeUnspsc190501.30102904': __('identifierSchemeCodeUnspsc190501.30102904'),
	'identifierSchemeCodeUnspsc190501.30102905': __('identifierSchemeCodeUnspsc190501.30102905'),
	'identifierSchemeCodeUnspsc190501.30102906': __('identifierSchemeCodeUnspsc190501.30102906'),
	'identifierSchemeCodeUnspsc190501.30102907': __('identifierSchemeCodeUnspsc190501.30102907'),
	'identifierSchemeCodeUnspsc190501.30103100': __('identifierSchemeCodeUnspsc190501.30103100'),
	'identifierSchemeCodeUnspsc190501.30103101': __('identifierSchemeCodeUnspsc190501.30103101'),
	'identifierSchemeCodeUnspsc190501.30103102': __('identifierSchemeCodeUnspsc190501.30103102'),
	'identifierSchemeCodeUnspsc190501.30103103': __('identifierSchemeCodeUnspsc190501.30103103'),
	'identifierSchemeCodeUnspsc190501.30103104': __('identifierSchemeCodeUnspsc190501.30103104'),
	'identifierSchemeCodeUnspsc190501.30103200': __('identifierSchemeCodeUnspsc190501.30103200'),
	'identifierSchemeCodeUnspsc190501.30103201': __('identifierSchemeCodeUnspsc190501.30103201'),
	'identifierSchemeCodeUnspsc190501.30103202': __('identifierSchemeCodeUnspsc190501.30103202'),
	'identifierSchemeCodeUnspsc190501.30103203': __('identifierSchemeCodeUnspsc190501.30103203'),
	'identifierSchemeCodeUnspsc190501.30103204': __('identifierSchemeCodeUnspsc190501.30103204'),
	'identifierSchemeCodeUnspsc190501.30103205': __('identifierSchemeCodeUnspsc190501.30103205'),
	'identifierSchemeCodeUnspsc190501.30103206': __('identifierSchemeCodeUnspsc190501.30103206'),
	'identifierSchemeCodeUnspsc190501.30103207': __('identifierSchemeCodeUnspsc190501.30103207'),
	'identifierSchemeCodeUnspsc190501.30103208': __('identifierSchemeCodeUnspsc190501.30103208'),
	'identifierSchemeCodeUnspsc190501.30103209': __('identifierSchemeCodeUnspsc190501.30103209'),
	'identifierSchemeCodeUnspsc190501.30103500': __('identifierSchemeCodeUnspsc190501.30103500'),
	'identifierSchemeCodeUnspsc190501.30103501': __('identifierSchemeCodeUnspsc190501.30103501'),
	'identifierSchemeCodeUnspsc190501.30103502': __('identifierSchemeCodeUnspsc190501.30103502'),
	'identifierSchemeCodeUnspsc190501.30103503': __('identifierSchemeCodeUnspsc190501.30103503'),
	'identifierSchemeCodeUnspsc190501.30103504': __('identifierSchemeCodeUnspsc190501.30103504'),
	'identifierSchemeCodeUnspsc190501.30103505': __('identifierSchemeCodeUnspsc190501.30103505'),
	'identifierSchemeCodeUnspsc190501.30103506': __('identifierSchemeCodeUnspsc190501.30103506'),
	'identifierSchemeCodeUnspsc190501.30103507': __('identifierSchemeCodeUnspsc190501.30103507'),
	'identifierSchemeCodeUnspsc190501.30103508': __('identifierSchemeCodeUnspsc190501.30103508'),
	'identifierSchemeCodeUnspsc190501.30103509': __('identifierSchemeCodeUnspsc190501.30103509'),
	'identifierSchemeCodeUnspsc190501.30103510': __('identifierSchemeCodeUnspsc190501.30103510'),
	'identifierSchemeCodeUnspsc190501.30103511': __('identifierSchemeCodeUnspsc190501.30103511'),
	'identifierSchemeCodeUnspsc190501.30103512': __('identifierSchemeCodeUnspsc190501.30103512'),
	'identifierSchemeCodeUnspsc190501.30103513': __('identifierSchemeCodeUnspsc190501.30103513'),
	'identifierSchemeCodeUnspsc190501.30103514': __('identifierSchemeCodeUnspsc190501.30103514'),
	'identifierSchemeCodeUnspsc190501.30103515': __('identifierSchemeCodeUnspsc190501.30103515'),
	'identifierSchemeCodeUnspsc190501.30103600': __('identifierSchemeCodeUnspsc190501.30103600'),
	'identifierSchemeCodeUnspsc190501.30103601': __('identifierSchemeCodeUnspsc190501.30103601'),
	'identifierSchemeCodeUnspsc190501.30103602': __('identifierSchemeCodeUnspsc190501.30103602'),
	'identifierSchemeCodeUnspsc190501.30103603': __('identifierSchemeCodeUnspsc190501.30103603'),
	'identifierSchemeCodeUnspsc190501.30103604': __('identifierSchemeCodeUnspsc190501.30103604'),
	'identifierSchemeCodeUnspsc190501.30103605': __('identifierSchemeCodeUnspsc190501.30103605'),
	'identifierSchemeCodeUnspsc190501.30103606': __('identifierSchemeCodeUnspsc190501.30103606'),
	'identifierSchemeCodeUnspsc190501.30103607': __('identifierSchemeCodeUnspsc190501.30103607'),
	'identifierSchemeCodeUnspsc190501.30103608': __('identifierSchemeCodeUnspsc190501.30103608'),
	'identifierSchemeCodeUnspsc190501.30103609': __('identifierSchemeCodeUnspsc190501.30103609'),
	'identifierSchemeCodeUnspsc190501.30103610': __('identifierSchemeCodeUnspsc190501.30103610'),
	'identifierSchemeCodeUnspsc190501.30103611': __('identifierSchemeCodeUnspsc190501.30103611'),
	'identifierSchemeCodeUnspsc190501.30103612': __('identifierSchemeCodeUnspsc190501.30103612'),
	'identifierSchemeCodeUnspsc190501.30103613': __('identifierSchemeCodeUnspsc190501.30103613'),
	'identifierSchemeCodeUnspsc190501.30103614': __('identifierSchemeCodeUnspsc190501.30103614'),
	'identifierSchemeCodeUnspsc190501.30103615': __('identifierSchemeCodeUnspsc190501.30103615'),
	'identifierSchemeCodeUnspsc190501.30103616': __('identifierSchemeCodeUnspsc190501.30103616'),
	'identifierSchemeCodeUnspsc190501.30103617': __('identifierSchemeCodeUnspsc190501.30103617'),
	'identifierSchemeCodeUnspsc190501.30103618': __('identifierSchemeCodeUnspsc190501.30103618'),
	'identifierSchemeCodeUnspsc190501.30103619': __('identifierSchemeCodeUnspsc190501.30103619'),
	'identifierSchemeCodeUnspsc190501.30103620': __('identifierSchemeCodeUnspsc190501.30103620'),
	'identifierSchemeCodeUnspsc190501.30103621': __('identifierSchemeCodeUnspsc190501.30103621'),
	'identifierSchemeCodeUnspsc190501.30103622': __('identifierSchemeCodeUnspsc190501.30103622'),
	'identifierSchemeCodeUnspsc190501.30103623': __('identifierSchemeCodeUnspsc190501.30103623'),
	'identifierSchemeCodeUnspsc190501.30103624': __('identifierSchemeCodeUnspsc190501.30103624'),
	'identifierSchemeCodeUnspsc190501.30103700': __('identifierSchemeCodeUnspsc190501.30103700'),
	'identifierSchemeCodeUnspsc190501.30103701': __('identifierSchemeCodeUnspsc190501.30103701'),
	'identifierSchemeCodeUnspsc190501.30103800': __('identifierSchemeCodeUnspsc190501.30103800'),
	'identifierSchemeCodeUnspsc190501.30103801': __('identifierSchemeCodeUnspsc190501.30103801'),
	'identifierSchemeCodeUnspsc190501.30103802': __('identifierSchemeCodeUnspsc190501.30103802'),
	'identifierSchemeCodeUnspsc190501.30103803': __('identifierSchemeCodeUnspsc190501.30103803'),
	'identifierSchemeCodeUnspsc190501.30103804': __('identifierSchemeCodeUnspsc190501.30103804'),
	'identifierSchemeCodeUnspsc190501.30103900': __('identifierSchemeCodeUnspsc190501.30103900'),
	'identifierSchemeCodeUnspsc190501.30103901': __('identifierSchemeCodeUnspsc190501.30103901'),
	'identifierSchemeCodeUnspsc190501.30104000': __('identifierSchemeCodeUnspsc190501.30104000'),
	'identifierSchemeCodeUnspsc190501.30104001': __('identifierSchemeCodeUnspsc190501.30104001'),
	'identifierSchemeCodeUnspsc190501.30104002': __('identifierSchemeCodeUnspsc190501.30104002'),
	'identifierSchemeCodeUnspsc190501.30104003': __('identifierSchemeCodeUnspsc190501.30104003'),
	'identifierSchemeCodeUnspsc190501.30110000': __('identifierSchemeCodeUnspsc190501.30110000'),
	'identifierSchemeCodeUnspsc190501.30111500': __('identifierSchemeCodeUnspsc190501.30111500'),
	'identifierSchemeCodeUnspsc190501.30111501': __('identifierSchemeCodeUnspsc190501.30111501'),
	'identifierSchemeCodeUnspsc190501.30111502': __('identifierSchemeCodeUnspsc190501.30111502'),
	'identifierSchemeCodeUnspsc190501.30111503': __('identifierSchemeCodeUnspsc190501.30111503'),
	'identifierSchemeCodeUnspsc190501.30111504': __('identifierSchemeCodeUnspsc190501.30111504'),
	'identifierSchemeCodeUnspsc190501.30111505': __('identifierSchemeCodeUnspsc190501.30111505'),
	'identifierSchemeCodeUnspsc190501.30111506': __('identifierSchemeCodeUnspsc190501.30111506'),
	'identifierSchemeCodeUnspsc190501.30111507': __('identifierSchemeCodeUnspsc190501.30111507'),
	'identifierSchemeCodeUnspsc190501.30111508': __('identifierSchemeCodeUnspsc190501.30111508'),
	'identifierSchemeCodeUnspsc190501.30111509': __('identifierSchemeCodeUnspsc190501.30111509'),
	'identifierSchemeCodeUnspsc190501.30111600': __('identifierSchemeCodeUnspsc190501.30111600'),
	'identifierSchemeCodeUnspsc190501.30111601': __('identifierSchemeCodeUnspsc190501.30111601'),
	'identifierSchemeCodeUnspsc190501.30111602': __('identifierSchemeCodeUnspsc190501.30111602'),
	'identifierSchemeCodeUnspsc190501.30111603': __('identifierSchemeCodeUnspsc190501.30111603'),
	'identifierSchemeCodeUnspsc190501.30111604': __('identifierSchemeCodeUnspsc190501.30111604'),
	'identifierSchemeCodeUnspsc190501.30111605': __('identifierSchemeCodeUnspsc190501.30111605'),
	'identifierSchemeCodeUnspsc190501.30111607': __('identifierSchemeCodeUnspsc190501.30111607'),
	'identifierSchemeCodeUnspsc190501.30111700': __('identifierSchemeCodeUnspsc190501.30111700'),
	'identifierSchemeCodeUnspsc190501.30111701': __('identifierSchemeCodeUnspsc190501.30111701'),
	'identifierSchemeCodeUnspsc190501.30111800': __('identifierSchemeCodeUnspsc190501.30111800'),
	'identifierSchemeCodeUnspsc190501.30111801': __('identifierSchemeCodeUnspsc190501.30111801'),
	'identifierSchemeCodeUnspsc190501.30111802': __('identifierSchemeCodeUnspsc190501.30111802'),
	'identifierSchemeCodeUnspsc190501.30111803': __('identifierSchemeCodeUnspsc190501.30111803'),
	'identifierSchemeCodeUnspsc190501.30111900': __('identifierSchemeCodeUnspsc190501.30111900'),
	'identifierSchemeCodeUnspsc190501.30111901': __('identifierSchemeCodeUnspsc190501.30111901'),
	'identifierSchemeCodeUnspsc190501.30111902': __('identifierSchemeCodeUnspsc190501.30111902'),
	'identifierSchemeCodeUnspsc190501.30111903': __('identifierSchemeCodeUnspsc190501.30111903'),
	'identifierSchemeCodeUnspsc190501.30120000': __('identifierSchemeCodeUnspsc190501.30120000'),
	'identifierSchemeCodeUnspsc190501.30121500': __('identifierSchemeCodeUnspsc190501.30121500'),
	'identifierSchemeCodeUnspsc190501.30121501': __('identifierSchemeCodeUnspsc190501.30121501'),
	'identifierSchemeCodeUnspsc190501.30121503': __('identifierSchemeCodeUnspsc190501.30121503'),
	'identifierSchemeCodeUnspsc190501.30121504': __('identifierSchemeCodeUnspsc190501.30121504'),
	'identifierSchemeCodeUnspsc190501.30121600': __('identifierSchemeCodeUnspsc190501.30121600'),
	'identifierSchemeCodeUnspsc190501.30121601': __('identifierSchemeCodeUnspsc190501.30121601'),
	'identifierSchemeCodeUnspsc190501.30121602': __('identifierSchemeCodeUnspsc190501.30121602'),
	'identifierSchemeCodeUnspsc190501.30121603': __('identifierSchemeCodeUnspsc190501.30121603'),
	'identifierSchemeCodeUnspsc190501.30121604': __('identifierSchemeCodeUnspsc190501.30121604'),
	'identifierSchemeCodeUnspsc190501.30121605': __('identifierSchemeCodeUnspsc190501.30121605'),
	'identifierSchemeCodeUnspsc190501.30121700': __('identifierSchemeCodeUnspsc190501.30121700'),
	'identifierSchemeCodeUnspsc190501.30121701': __('identifierSchemeCodeUnspsc190501.30121701'),
	'identifierSchemeCodeUnspsc190501.30121702': __('identifierSchemeCodeUnspsc190501.30121702'),
	'identifierSchemeCodeUnspsc190501.30121703': __('identifierSchemeCodeUnspsc190501.30121703'),
	'identifierSchemeCodeUnspsc190501.30121704': __('identifierSchemeCodeUnspsc190501.30121704'),
	'identifierSchemeCodeUnspsc190501.30121705': __('identifierSchemeCodeUnspsc190501.30121705'),
	'identifierSchemeCodeUnspsc190501.30121706': __('identifierSchemeCodeUnspsc190501.30121706'),
	'identifierSchemeCodeUnspsc190501.30121707': __('identifierSchemeCodeUnspsc190501.30121707'),
	'identifierSchemeCodeUnspsc190501.30121708': __('identifierSchemeCodeUnspsc190501.30121708'),
	'identifierSchemeCodeUnspsc190501.30121709': __('identifierSchemeCodeUnspsc190501.30121709'),
	'identifierSchemeCodeUnspsc190501.30121710': __('identifierSchemeCodeUnspsc190501.30121710'),
	'identifierSchemeCodeUnspsc190501.30121711': __('identifierSchemeCodeUnspsc190501.30121711'),
	'identifierSchemeCodeUnspsc190501.30121712': __('identifierSchemeCodeUnspsc190501.30121712'),
	'identifierSchemeCodeUnspsc190501.30121713': __('identifierSchemeCodeUnspsc190501.30121713'),
	'identifierSchemeCodeUnspsc190501.30121714': __('identifierSchemeCodeUnspsc190501.30121714'),
	'identifierSchemeCodeUnspsc190501.30121715': __('identifierSchemeCodeUnspsc190501.30121715'),
	'identifierSchemeCodeUnspsc190501.30121716': __('identifierSchemeCodeUnspsc190501.30121716'),
	'identifierSchemeCodeUnspsc190501.30121717': __('identifierSchemeCodeUnspsc190501.30121717'),
	'identifierSchemeCodeUnspsc190501.30121718': __('identifierSchemeCodeUnspsc190501.30121718'),
	'identifierSchemeCodeUnspsc190501.30121719': __('identifierSchemeCodeUnspsc190501.30121719'),
	'identifierSchemeCodeUnspsc190501.30121720': __('identifierSchemeCodeUnspsc190501.30121720'),
	'identifierSchemeCodeUnspsc190501.30121721': __('identifierSchemeCodeUnspsc190501.30121721'),
	'identifierSchemeCodeUnspsc190501.30121800': __('identifierSchemeCodeUnspsc190501.30121800'),
	'identifierSchemeCodeUnspsc190501.30121801': __('identifierSchemeCodeUnspsc190501.30121801'),
	'identifierSchemeCodeUnspsc190501.30121802': __('identifierSchemeCodeUnspsc190501.30121802'),
	'identifierSchemeCodeUnspsc190501.30121803': __('identifierSchemeCodeUnspsc190501.30121803'),
	'identifierSchemeCodeUnspsc190501.30121900': __('identifierSchemeCodeUnspsc190501.30121900'),
	'identifierSchemeCodeUnspsc190501.30121901': __('identifierSchemeCodeUnspsc190501.30121901'),
	'identifierSchemeCodeUnspsc190501.30122000': __('identifierSchemeCodeUnspsc190501.30122000'),
	'identifierSchemeCodeUnspsc190501.30122001': __('identifierSchemeCodeUnspsc190501.30122001'),
	'identifierSchemeCodeUnspsc190501.30122002': __('identifierSchemeCodeUnspsc190501.30122002'),
	'identifierSchemeCodeUnspsc190501.30122003': __('identifierSchemeCodeUnspsc190501.30122003'),
	'identifierSchemeCodeUnspsc190501.30122004': __('identifierSchemeCodeUnspsc190501.30122004'),
	'identifierSchemeCodeUnspsc190501.30130000': __('identifierSchemeCodeUnspsc190501.30130000'),
	'identifierSchemeCodeUnspsc190501.30131500': __('identifierSchemeCodeUnspsc190501.30131500'),
	'identifierSchemeCodeUnspsc190501.30131502': __('identifierSchemeCodeUnspsc190501.30131502'),
	'identifierSchemeCodeUnspsc190501.30131503': __('identifierSchemeCodeUnspsc190501.30131503'),
	'identifierSchemeCodeUnspsc190501.30131504': __('identifierSchemeCodeUnspsc190501.30131504'),
	'identifierSchemeCodeUnspsc190501.30131505': __('identifierSchemeCodeUnspsc190501.30131505'),
	'identifierSchemeCodeUnspsc190501.30131506': __('identifierSchemeCodeUnspsc190501.30131506'),
	'identifierSchemeCodeUnspsc190501.30131507': __('identifierSchemeCodeUnspsc190501.30131507'),
	'identifierSchemeCodeUnspsc190501.30131508': __('identifierSchemeCodeUnspsc190501.30131508'),
	'identifierSchemeCodeUnspsc190501.30131509': __('identifierSchemeCodeUnspsc190501.30131509'),
	'identifierSchemeCodeUnspsc190501.30131510': __('identifierSchemeCodeUnspsc190501.30131510'),
	'identifierSchemeCodeUnspsc190501.30131511': __('identifierSchemeCodeUnspsc190501.30131511'),
	'identifierSchemeCodeUnspsc190501.30131512': __('identifierSchemeCodeUnspsc190501.30131512'),
	'identifierSchemeCodeUnspsc190501.30131513': __('identifierSchemeCodeUnspsc190501.30131513'),
	'identifierSchemeCodeUnspsc190501.30131514': __('identifierSchemeCodeUnspsc190501.30131514'),
	'identifierSchemeCodeUnspsc190501.30131515': __('identifierSchemeCodeUnspsc190501.30131515'),
	'identifierSchemeCodeUnspsc190501.30131516': __('identifierSchemeCodeUnspsc190501.30131516'),
	'identifierSchemeCodeUnspsc190501.30131517': __('identifierSchemeCodeUnspsc190501.30131517'),
	'identifierSchemeCodeUnspsc190501.30131518': __('identifierSchemeCodeUnspsc190501.30131518'),
	'identifierSchemeCodeUnspsc190501.30131600': __('identifierSchemeCodeUnspsc190501.30131600'),
	'identifierSchemeCodeUnspsc190501.30131602': __('identifierSchemeCodeUnspsc190501.30131602'),
	'identifierSchemeCodeUnspsc190501.30131603': __('identifierSchemeCodeUnspsc190501.30131603'),
	'identifierSchemeCodeUnspsc190501.30131604': __('identifierSchemeCodeUnspsc190501.30131604'),
	'identifierSchemeCodeUnspsc190501.30131605': __('identifierSchemeCodeUnspsc190501.30131605'),
	'identifierSchemeCodeUnspsc190501.30131606': __('identifierSchemeCodeUnspsc190501.30131606'),
	'identifierSchemeCodeUnspsc190501.30131607': __('identifierSchemeCodeUnspsc190501.30131607'),
	'identifierSchemeCodeUnspsc190501.30131608': __('identifierSchemeCodeUnspsc190501.30131608'),
	'identifierSchemeCodeUnspsc190501.30131609': __('identifierSchemeCodeUnspsc190501.30131609'),
	'identifierSchemeCodeUnspsc190501.30131610': __('identifierSchemeCodeUnspsc190501.30131610'),
	'identifierSchemeCodeUnspsc190501.30131611': __('identifierSchemeCodeUnspsc190501.30131611'),
	'identifierSchemeCodeUnspsc190501.30131700': __('identifierSchemeCodeUnspsc190501.30131700'),
	'identifierSchemeCodeUnspsc190501.30131702': __('identifierSchemeCodeUnspsc190501.30131702'),
	'identifierSchemeCodeUnspsc190501.30131703': __('identifierSchemeCodeUnspsc190501.30131703'),
	'identifierSchemeCodeUnspsc190501.30131704': __('identifierSchemeCodeUnspsc190501.30131704'),
	'identifierSchemeCodeUnspsc190501.30131705': __('identifierSchemeCodeUnspsc190501.30131705'),
	'identifierSchemeCodeUnspsc190501.30131706': __('identifierSchemeCodeUnspsc190501.30131706'),
	'identifierSchemeCodeUnspsc190501.30140000': __('identifierSchemeCodeUnspsc190501.30140000'),
	'identifierSchemeCodeUnspsc190501.30141500': __('identifierSchemeCodeUnspsc190501.30141500'),
	'identifierSchemeCodeUnspsc190501.30141501': __('identifierSchemeCodeUnspsc190501.30141501'),
	'identifierSchemeCodeUnspsc190501.30141503': __('identifierSchemeCodeUnspsc190501.30141503'),
	'identifierSchemeCodeUnspsc190501.30141505': __('identifierSchemeCodeUnspsc190501.30141505'),
	'identifierSchemeCodeUnspsc190501.30141508': __('identifierSchemeCodeUnspsc190501.30141508'),
	'identifierSchemeCodeUnspsc190501.30141510': __('identifierSchemeCodeUnspsc190501.30141510'),
	'identifierSchemeCodeUnspsc190501.30141511': __('identifierSchemeCodeUnspsc190501.30141511'),
	'identifierSchemeCodeUnspsc190501.30141512': __('identifierSchemeCodeUnspsc190501.30141512'),
	'identifierSchemeCodeUnspsc190501.30141513': __('identifierSchemeCodeUnspsc190501.30141513'),
	'identifierSchemeCodeUnspsc190501.30141514': __('identifierSchemeCodeUnspsc190501.30141514'),
	'identifierSchemeCodeUnspsc190501.30141515': __('identifierSchemeCodeUnspsc190501.30141515'),
	'identifierSchemeCodeUnspsc190501.30141516': __('identifierSchemeCodeUnspsc190501.30141516'),
	'identifierSchemeCodeUnspsc190501.30141600': __('identifierSchemeCodeUnspsc190501.30141600'),
	'identifierSchemeCodeUnspsc190501.30141601': __('identifierSchemeCodeUnspsc190501.30141601'),
	'identifierSchemeCodeUnspsc190501.30141603': __('identifierSchemeCodeUnspsc190501.30141603'),
	'identifierSchemeCodeUnspsc190501.30141604': __('identifierSchemeCodeUnspsc190501.30141604'),
	'identifierSchemeCodeUnspsc190501.30141605': __('identifierSchemeCodeUnspsc190501.30141605'),
	'identifierSchemeCodeUnspsc190501.30141700': __('identifierSchemeCodeUnspsc190501.30141700'),
	'identifierSchemeCodeUnspsc190501.30141701': __('identifierSchemeCodeUnspsc190501.30141701'),
	'identifierSchemeCodeUnspsc190501.30141702': __('identifierSchemeCodeUnspsc190501.30141702'),
	'identifierSchemeCodeUnspsc190501.30141703': __('identifierSchemeCodeUnspsc190501.30141703'),
	'identifierSchemeCodeUnspsc190501.30150000': __('identifierSchemeCodeUnspsc190501.30150000'),
	'identifierSchemeCodeUnspsc190501.30151500': __('identifierSchemeCodeUnspsc190501.30151500'),
	'identifierSchemeCodeUnspsc190501.30151501': __('identifierSchemeCodeUnspsc190501.30151501'),
	'identifierSchemeCodeUnspsc190501.30151502': __('identifierSchemeCodeUnspsc190501.30151502'),
	'identifierSchemeCodeUnspsc190501.30151503': __('identifierSchemeCodeUnspsc190501.30151503'),
	'identifierSchemeCodeUnspsc190501.30151505': __('identifierSchemeCodeUnspsc190501.30151505'),
	'identifierSchemeCodeUnspsc190501.30151507': __('identifierSchemeCodeUnspsc190501.30151507'),
	'identifierSchemeCodeUnspsc190501.30151508': __('identifierSchemeCodeUnspsc190501.30151508'),
	'identifierSchemeCodeUnspsc190501.30151509': __('identifierSchemeCodeUnspsc190501.30151509'),
	'identifierSchemeCodeUnspsc190501.30151510': __('identifierSchemeCodeUnspsc190501.30151510'),
	'identifierSchemeCodeUnspsc190501.30151511': __('identifierSchemeCodeUnspsc190501.30151511'),
	'identifierSchemeCodeUnspsc190501.30151512': __('identifierSchemeCodeUnspsc190501.30151512'),
	'identifierSchemeCodeUnspsc190501.30151513': __('identifierSchemeCodeUnspsc190501.30151513'),
	'identifierSchemeCodeUnspsc190501.30151514': __('identifierSchemeCodeUnspsc190501.30151514'),
	'identifierSchemeCodeUnspsc190501.30151515': __('identifierSchemeCodeUnspsc190501.30151515'),
	'identifierSchemeCodeUnspsc190501.30151600': __('identifierSchemeCodeUnspsc190501.30151600'),
	'identifierSchemeCodeUnspsc190501.30151601': __('identifierSchemeCodeUnspsc190501.30151601'),
	'identifierSchemeCodeUnspsc190501.30151602': __('identifierSchemeCodeUnspsc190501.30151602'),
	'identifierSchemeCodeUnspsc190501.30151603': __('identifierSchemeCodeUnspsc190501.30151603'),
	'identifierSchemeCodeUnspsc190501.30151605': __('identifierSchemeCodeUnspsc190501.30151605'),
	'identifierSchemeCodeUnspsc190501.30151607': __('identifierSchemeCodeUnspsc190501.30151607'),
	'identifierSchemeCodeUnspsc190501.30151608': __('identifierSchemeCodeUnspsc190501.30151608'),
	'identifierSchemeCodeUnspsc190501.30151609': __('identifierSchemeCodeUnspsc190501.30151609'),
	'identifierSchemeCodeUnspsc190501.30151610': __('identifierSchemeCodeUnspsc190501.30151610'),
	'identifierSchemeCodeUnspsc190501.30151700': __('identifierSchemeCodeUnspsc190501.30151700'),
	'identifierSchemeCodeUnspsc190501.30151701': __('identifierSchemeCodeUnspsc190501.30151701'),
	'identifierSchemeCodeUnspsc190501.30151702': __('identifierSchemeCodeUnspsc190501.30151702'),
	'identifierSchemeCodeUnspsc190501.30151703': __('identifierSchemeCodeUnspsc190501.30151703'),
	'identifierSchemeCodeUnspsc190501.30151704': __('identifierSchemeCodeUnspsc190501.30151704'),
	'identifierSchemeCodeUnspsc190501.30151800': __('identifierSchemeCodeUnspsc190501.30151800'),
	'identifierSchemeCodeUnspsc190501.30151801': __('identifierSchemeCodeUnspsc190501.30151801'),
	'identifierSchemeCodeUnspsc190501.30151802': __('identifierSchemeCodeUnspsc190501.30151802'),
	'identifierSchemeCodeUnspsc190501.30151803': __('identifierSchemeCodeUnspsc190501.30151803'),
	'identifierSchemeCodeUnspsc190501.30151805': __('identifierSchemeCodeUnspsc190501.30151805'),
	'identifierSchemeCodeUnspsc190501.30151806': __('identifierSchemeCodeUnspsc190501.30151806'),
	'identifierSchemeCodeUnspsc190501.30151807': __('identifierSchemeCodeUnspsc190501.30151807'),
	'identifierSchemeCodeUnspsc190501.30151808': __('identifierSchemeCodeUnspsc190501.30151808'),
	'identifierSchemeCodeUnspsc190501.30151900': __('identifierSchemeCodeUnspsc190501.30151900'),
	'identifierSchemeCodeUnspsc190501.30151901': __('identifierSchemeCodeUnspsc190501.30151901'),
	'identifierSchemeCodeUnspsc190501.30151902': __('identifierSchemeCodeUnspsc190501.30151902'),
	'identifierSchemeCodeUnspsc190501.30152000': __('identifierSchemeCodeUnspsc190501.30152000'),
	'identifierSchemeCodeUnspsc190501.30152001': __('identifierSchemeCodeUnspsc190501.30152001'),
	'identifierSchemeCodeUnspsc190501.30152002': __('identifierSchemeCodeUnspsc190501.30152002'),
	'identifierSchemeCodeUnspsc190501.30152003': __('identifierSchemeCodeUnspsc190501.30152003'),
	'identifierSchemeCodeUnspsc190501.30152100': __('identifierSchemeCodeUnspsc190501.30152100'),
	'identifierSchemeCodeUnspsc190501.30152101': __('identifierSchemeCodeUnspsc190501.30152101'),
	'identifierSchemeCodeUnspsc190501.30160000': __('identifierSchemeCodeUnspsc190501.30160000'),
	'identifierSchemeCodeUnspsc190501.30161500': __('identifierSchemeCodeUnspsc190501.30161500'),
	'identifierSchemeCodeUnspsc190501.30161501': __('identifierSchemeCodeUnspsc190501.30161501'),
	'identifierSchemeCodeUnspsc190501.30161502': __('identifierSchemeCodeUnspsc190501.30161502'),
	'identifierSchemeCodeUnspsc190501.30161503': __('identifierSchemeCodeUnspsc190501.30161503'),
	'identifierSchemeCodeUnspsc190501.30161504': __('identifierSchemeCodeUnspsc190501.30161504'),
	'identifierSchemeCodeUnspsc190501.30161505': __('identifierSchemeCodeUnspsc190501.30161505'),
	'identifierSchemeCodeUnspsc190501.30161508': __('identifierSchemeCodeUnspsc190501.30161508'),
	'identifierSchemeCodeUnspsc190501.30161509': __('identifierSchemeCodeUnspsc190501.30161509'),
	'identifierSchemeCodeUnspsc190501.30161510': __('identifierSchemeCodeUnspsc190501.30161510'),
	'identifierSchemeCodeUnspsc190501.30161511': __('identifierSchemeCodeUnspsc190501.30161511'),
	'identifierSchemeCodeUnspsc190501.30161600': __('identifierSchemeCodeUnspsc190501.30161600'),
	'identifierSchemeCodeUnspsc190501.30161601': __('identifierSchemeCodeUnspsc190501.30161601'),
	'identifierSchemeCodeUnspsc190501.30161602': __('identifierSchemeCodeUnspsc190501.30161602'),
	'identifierSchemeCodeUnspsc190501.30161603': __('identifierSchemeCodeUnspsc190501.30161603'),
	'identifierSchemeCodeUnspsc190501.30161604': __('identifierSchemeCodeUnspsc190501.30161604'),
	'identifierSchemeCodeUnspsc190501.30161700': __('identifierSchemeCodeUnspsc190501.30161700'),
	'identifierSchemeCodeUnspsc190501.30161701': __('identifierSchemeCodeUnspsc190501.30161701'),
	'identifierSchemeCodeUnspsc190501.30161702': __('identifierSchemeCodeUnspsc190501.30161702'),
	'identifierSchemeCodeUnspsc190501.30161703': __('identifierSchemeCodeUnspsc190501.30161703'),
	'identifierSchemeCodeUnspsc190501.30161705': __('identifierSchemeCodeUnspsc190501.30161705'),
	'identifierSchemeCodeUnspsc190501.30161706': __('identifierSchemeCodeUnspsc190501.30161706'),
	'identifierSchemeCodeUnspsc190501.30161707': __('identifierSchemeCodeUnspsc190501.30161707'),
	'identifierSchemeCodeUnspsc190501.30161708': __('identifierSchemeCodeUnspsc190501.30161708'),
	'identifierSchemeCodeUnspsc190501.30161709': __('identifierSchemeCodeUnspsc190501.30161709'),
	'identifierSchemeCodeUnspsc190501.30161710': __('identifierSchemeCodeUnspsc190501.30161710'),
	'identifierSchemeCodeUnspsc190501.30161711': __('identifierSchemeCodeUnspsc190501.30161711'),
	'identifierSchemeCodeUnspsc190501.30161712': __('identifierSchemeCodeUnspsc190501.30161712'),
	'identifierSchemeCodeUnspsc190501.30161713': __('identifierSchemeCodeUnspsc190501.30161713'),
	'identifierSchemeCodeUnspsc190501.30161714': __('identifierSchemeCodeUnspsc190501.30161714'),
	'identifierSchemeCodeUnspsc190501.30161715': __('identifierSchemeCodeUnspsc190501.30161715'),
	'identifierSchemeCodeUnspsc190501.30161717': __('identifierSchemeCodeUnspsc190501.30161717'),
	'identifierSchemeCodeUnspsc190501.30161718': __('identifierSchemeCodeUnspsc190501.30161718'),
	'identifierSchemeCodeUnspsc190501.30161719': __('identifierSchemeCodeUnspsc190501.30161719'),
	'identifierSchemeCodeUnspsc190501.30161720': __('identifierSchemeCodeUnspsc190501.30161720'),
	'identifierSchemeCodeUnspsc190501.30161721': __('identifierSchemeCodeUnspsc190501.30161721'),
	'identifierSchemeCodeUnspsc190501.30161800': __('identifierSchemeCodeUnspsc190501.30161800'),
	'identifierSchemeCodeUnspsc190501.30161801': __('identifierSchemeCodeUnspsc190501.30161801'),
	'identifierSchemeCodeUnspsc190501.30161803': __('identifierSchemeCodeUnspsc190501.30161803'),
	'identifierSchemeCodeUnspsc190501.30161804': __('identifierSchemeCodeUnspsc190501.30161804'),
	'identifierSchemeCodeUnspsc190501.30161805': __('identifierSchemeCodeUnspsc190501.30161805'),
	'identifierSchemeCodeUnspsc190501.30161806': __('identifierSchemeCodeUnspsc190501.30161806'),
	'identifierSchemeCodeUnspsc190501.30161807': __('identifierSchemeCodeUnspsc190501.30161807'),
	'identifierSchemeCodeUnspsc190501.30161808': __('identifierSchemeCodeUnspsc190501.30161808'),
	'identifierSchemeCodeUnspsc190501.30161809': __('identifierSchemeCodeUnspsc190501.30161809'),
	'identifierSchemeCodeUnspsc190501.30161810': __('identifierSchemeCodeUnspsc190501.30161810'),
	'identifierSchemeCodeUnspsc190501.30161900': __('identifierSchemeCodeUnspsc190501.30161900'),
	'identifierSchemeCodeUnspsc190501.30161901': __('identifierSchemeCodeUnspsc190501.30161901'),
	'identifierSchemeCodeUnspsc190501.30161902': __('identifierSchemeCodeUnspsc190501.30161902'),
	'identifierSchemeCodeUnspsc190501.30161903': __('identifierSchemeCodeUnspsc190501.30161903'),
	'identifierSchemeCodeUnspsc190501.30161904': __('identifierSchemeCodeUnspsc190501.30161904'),
	'identifierSchemeCodeUnspsc190501.30161905': __('identifierSchemeCodeUnspsc190501.30161905'),
	'identifierSchemeCodeUnspsc190501.30161906': __('identifierSchemeCodeUnspsc190501.30161906'),
	'identifierSchemeCodeUnspsc190501.30161908': __('identifierSchemeCodeUnspsc190501.30161908'),
	'identifierSchemeCodeUnspsc190501.30162000': __('identifierSchemeCodeUnspsc190501.30162000'),
	'identifierSchemeCodeUnspsc190501.30162001': __('identifierSchemeCodeUnspsc190501.30162001'),
	'identifierSchemeCodeUnspsc190501.30162002': __('identifierSchemeCodeUnspsc190501.30162002'),
	'identifierSchemeCodeUnspsc190501.30162003': __('identifierSchemeCodeUnspsc190501.30162003'),
	'identifierSchemeCodeUnspsc190501.30162004': __('identifierSchemeCodeUnspsc190501.30162004'),
	'identifierSchemeCodeUnspsc190501.30162005': __('identifierSchemeCodeUnspsc190501.30162005'),
	'identifierSchemeCodeUnspsc190501.30162100': __('identifierSchemeCodeUnspsc190501.30162100'),
	'identifierSchemeCodeUnspsc190501.30162101': __('identifierSchemeCodeUnspsc190501.30162101'),
	'identifierSchemeCodeUnspsc190501.30162102': __('identifierSchemeCodeUnspsc190501.30162102'),
	'identifierSchemeCodeUnspsc190501.30162103': __('identifierSchemeCodeUnspsc190501.30162103'),
	'identifierSchemeCodeUnspsc190501.30162104': __('identifierSchemeCodeUnspsc190501.30162104'),
	'identifierSchemeCodeUnspsc190501.30162200': __('identifierSchemeCodeUnspsc190501.30162200'),
	'identifierSchemeCodeUnspsc190501.30162201': __('identifierSchemeCodeUnspsc190501.30162201'),
	'identifierSchemeCodeUnspsc190501.30162202': __('identifierSchemeCodeUnspsc190501.30162202'),
	'identifierSchemeCodeUnspsc190501.30162203': __('identifierSchemeCodeUnspsc190501.30162203'),
	'identifierSchemeCodeUnspsc190501.30162204': __('identifierSchemeCodeUnspsc190501.30162204'),
	'identifierSchemeCodeUnspsc190501.30162300': __('identifierSchemeCodeUnspsc190501.30162300'),
	'identifierSchemeCodeUnspsc190501.30162301': __('identifierSchemeCodeUnspsc190501.30162301'),
	'identifierSchemeCodeUnspsc190501.30162302': __('identifierSchemeCodeUnspsc190501.30162302'),
	'identifierSchemeCodeUnspsc190501.30162303': __('identifierSchemeCodeUnspsc190501.30162303'),
	'identifierSchemeCodeUnspsc190501.30162304': __('identifierSchemeCodeUnspsc190501.30162304'),
	'identifierSchemeCodeUnspsc190501.30162305': __('identifierSchemeCodeUnspsc190501.30162305'),
	'identifierSchemeCodeUnspsc190501.30162306': __('identifierSchemeCodeUnspsc190501.30162306'),
	'identifierSchemeCodeUnspsc190501.30162307': __('identifierSchemeCodeUnspsc190501.30162307'),
	'identifierSchemeCodeUnspsc190501.30162308': __('identifierSchemeCodeUnspsc190501.30162308'),
	'identifierSchemeCodeUnspsc190501.30162309': __('identifierSchemeCodeUnspsc190501.30162309'),
	'identifierSchemeCodeUnspsc190501.30162310': __('identifierSchemeCodeUnspsc190501.30162310'),
	'identifierSchemeCodeUnspsc190501.30162311': __('identifierSchemeCodeUnspsc190501.30162311'),
	'identifierSchemeCodeUnspsc190501.30162400': __('identifierSchemeCodeUnspsc190501.30162400'),
	'identifierSchemeCodeUnspsc190501.30162401': __('identifierSchemeCodeUnspsc190501.30162401'),
	'identifierSchemeCodeUnspsc190501.30162402': __('identifierSchemeCodeUnspsc190501.30162402'),
	'identifierSchemeCodeUnspsc190501.30162403': __('identifierSchemeCodeUnspsc190501.30162403'),
	'identifierSchemeCodeUnspsc190501.30162404': __('identifierSchemeCodeUnspsc190501.30162404'),
	'identifierSchemeCodeUnspsc190501.30170000': __('identifierSchemeCodeUnspsc190501.30170000'),
	'identifierSchemeCodeUnspsc190501.30171500': __('identifierSchemeCodeUnspsc190501.30171500'),
	'identifierSchemeCodeUnspsc190501.30171501': __('identifierSchemeCodeUnspsc190501.30171501'),
	'identifierSchemeCodeUnspsc190501.30171502': __('identifierSchemeCodeUnspsc190501.30171502'),
	'identifierSchemeCodeUnspsc190501.30171503': __('identifierSchemeCodeUnspsc190501.30171503'),
	'identifierSchemeCodeUnspsc190501.30171504': __('identifierSchemeCodeUnspsc190501.30171504'),
	'identifierSchemeCodeUnspsc190501.30171505': __('identifierSchemeCodeUnspsc190501.30171505'),
	'identifierSchemeCodeUnspsc190501.30171506': __('identifierSchemeCodeUnspsc190501.30171506'),
	'identifierSchemeCodeUnspsc190501.30171507': __('identifierSchemeCodeUnspsc190501.30171507'),
	'identifierSchemeCodeUnspsc190501.30171508': __('identifierSchemeCodeUnspsc190501.30171508'),
	'identifierSchemeCodeUnspsc190501.30171509': __('identifierSchemeCodeUnspsc190501.30171509'),
	'identifierSchemeCodeUnspsc190501.30171510': __('identifierSchemeCodeUnspsc190501.30171510'),
	'identifierSchemeCodeUnspsc190501.30171511': __('identifierSchemeCodeUnspsc190501.30171511'),
	'identifierSchemeCodeUnspsc190501.30171512': __('identifierSchemeCodeUnspsc190501.30171512'),
	'identifierSchemeCodeUnspsc190501.30171513': __('identifierSchemeCodeUnspsc190501.30171513'),
	'identifierSchemeCodeUnspsc190501.30171514': __('identifierSchemeCodeUnspsc190501.30171514'),
	'identifierSchemeCodeUnspsc190501.30171515': __('identifierSchemeCodeUnspsc190501.30171515'),
	'identifierSchemeCodeUnspsc190501.30171516': __('identifierSchemeCodeUnspsc190501.30171516'),
	'identifierSchemeCodeUnspsc190501.30171517': __('identifierSchemeCodeUnspsc190501.30171517'),
	'identifierSchemeCodeUnspsc190501.30171518': __('identifierSchemeCodeUnspsc190501.30171518'),
	'identifierSchemeCodeUnspsc190501.30171519': __('identifierSchemeCodeUnspsc190501.30171519'),
	'identifierSchemeCodeUnspsc190501.30171520': __('identifierSchemeCodeUnspsc190501.30171520'),
	'identifierSchemeCodeUnspsc190501.30171521': __('identifierSchemeCodeUnspsc190501.30171521'),
	'identifierSchemeCodeUnspsc190501.30171522': __('identifierSchemeCodeUnspsc190501.30171522'),
	'identifierSchemeCodeUnspsc190501.30171523': __('identifierSchemeCodeUnspsc190501.30171523'),
	'identifierSchemeCodeUnspsc190501.30171524': __('identifierSchemeCodeUnspsc190501.30171524'),
	'identifierSchemeCodeUnspsc190501.30171525': __('identifierSchemeCodeUnspsc190501.30171525'),
	'identifierSchemeCodeUnspsc190501.30171526': __('identifierSchemeCodeUnspsc190501.30171526'),
	'identifierSchemeCodeUnspsc190501.30171527': __('identifierSchemeCodeUnspsc190501.30171527'),
	'identifierSchemeCodeUnspsc190501.30171600': __('identifierSchemeCodeUnspsc190501.30171600'),
	'identifierSchemeCodeUnspsc190501.30171604': __('identifierSchemeCodeUnspsc190501.30171604'),
	'identifierSchemeCodeUnspsc190501.30171605': __('identifierSchemeCodeUnspsc190501.30171605'),
	'identifierSchemeCodeUnspsc190501.30171606': __('identifierSchemeCodeUnspsc190501.30171606'),
	'identifierSchemeCodeUnspsc190501.30171607': __('identifierSchemeCodeUnspsc190501.30171607'),
	'identifierSchemeCodeUnspsc190501.30171608': __('identifierSchemeCodeUnspsc190501.30171608'),
	'identifierSchemeCodeUnspsc190501.30171609': __('identifierSchemeCodeUnspsc190501.30171609'),
	'identifierSchemeCodeUnspsc190501.30171610': __('identifierSchemeCodeUnspsc190501.30171610'),
	'identifierSchemeCodeUnspsc190501.30171611': __('identifierSchemeCodeUnspsc190501.30171611'),
	'identifierSchemeCodeUnspsc190501.30171612': __('identifierSchemeCodeUnspsc190501.30171612'),
	'identifierSchemeCodeUnspsc190501.30171613': __('identifierSchemeCodeUnspsc190501.30171613'),
	'identifierSchemeCodeUnspsc190501.30171614': __('identifierSchemeCodeUnspsc190501.30171614'),
	'identifierSchemeCodeUnspsc190501.30171615': __('identifierSchemeCodeUnspsc190501.30171615'),
	'identifierSchemeCodeUnspsc190501.30171700': __('identifierSchemeCodeUnspsc190501.30171700'),
	'identifierSchemeCodeUnspsc190501.30171701': __('identifierSchemeCodeUnspsc190501.30171701'),
	'identifierSchemeCodeUnspsc190501.30171703': __('identifierSchemeCodeUnspsc190501.30171703'),
	'identifierSchemeCodeUnspsc190501.30171704': __('identifierSchemeCodeUnspsc190501.30171704'),
	'identifierSchemeCodeUnspsc190501.30171705': __('identifierSchemeCodeUnspsc190501.30171705'),
	'identifierSchemeCodeUnspsc190501.30171706': __('identifierSchemeCodeUnspsc190501.30171706'),
	'identifierSchemeCodeUnspsc190501.30171707': __('identifierSchemeCodeUnspsc190501.30171707'),
	'identifierSchemeCodeUnspsc190501.30171708': __('identifierSchemeCodeUnspsc190501.30171708'),
	'identifierSchemeCodeUnspsc190501.30171709': __('identifierSchemeCodeUnspsc190501.30171709'),
	'identifierSchemeCodeUnspsc190501.30171710': __('identifierSchemeCodeUnspsc190501.30171710'),
	'identifierSchemeCodeUnspsc190501.30171711': __('identifierSchemeCodeUnspsc190501.30171711'),
	'identifierSchemeCodeUnspsc190501.30171712': __('identifierSchemeCodeUnspsc190501.30171712'),
	'identifierSchemeCodeUnspsc190501.30171800': __('identifierSchemeCodeUnspsc190501.30171800'),
	'identifierSchemeCodeUnspsc190501.30171801': __('identifierSchemeCodeUnspsc190501.30171801'),
	'identifierSchemeCodeUnspsc190501.30171802': __('identifierSchemeCodeUnspsc190501.30171802'),
	'identifierSchemeCodeUnspsc190501.30171803': __('identifierSchemeCodeUnspsc190501.30171803'),
	'identifierSchemeCodeUnspsc190501.30171804': __('identifierSchemeCodeUnspsc190501.30171804'),
	'identifierSchemeCodeUnspsc190501.30171900': __('identifierSchemeCodeUnspsc190501.30171900'),
	'identifierSchemeCodeUnspsc190501.30171901': __('identifierSchemeCodeUnspsc190501.30171901'),
	'identifierSchemeCodeUnspsc190501.30171902': __('identifierSchemeCodeUnspsc190501.30171902'),
	'identifierSchemeCodeUnspsc190501.30171903': __('identifierSchemeCodeUnspsc190501.30171903'),
	'identifierSchemeCodeUnspsc190501.30171904': __('identifierSchemeCodeUnspsc190501.30171904'),
	'identifierSchemeCodeUnspsc190501.30171905': __('identifierSchemeCodeUnspsc190501.30171905'),
	'identifierSchemeCodeUnspsc190501.30171906': __('identifierSchemeCodeUnspsc190501.30171906'),
	'identifierSchemeCodeUnspsc190501.30171907': __('identifierSchemeCodeUnspsc190501.30171907'),
	'identifierSchemeCodeUnspsc190501.30171908': __('identifierSchemeCodeUnspsc190501.30171908'),
	'identifierSchemeCodeUnspsc190501.30172000': __('identifierSchemeCodeUnspsc190501.30172000'),
	'identifierSchemeCodeUnspsc190501.30172001': __('identifierSchemeCodeUnspsc190501.30172001'),
	'identifierSchemeCodeUnspsc190501.30172002': __('identifierSchemeCodeUnspsc190501.30172002'),
	'identifierSchemeCodeUnspsc190501.30172100': __('identifierSchemeCodeUnspsc190501.30172100'),
	'identifierSchemeCodeUnspsc190501.30172101': __('identifierSchemeCodeUnspsc190501.30172101'),
	'identifierSchemeCodeUnspsc190501.30172102': __('identifierSchemeCodeUnspsc190501.30172102'),
	'identifierSchemeCodeUnspsc190501.30172103': __('identifierSchemeCodeUnspsc190501.30172103'),
	'identifierSchemeCodeUnspsc190501.30172104': __('identifierSchemeCodeUnspsc190501.30172104'),
	'identifierSchemeCodeUnspsc190501.30172105': __('identifierSchemeCodeUnspsc190501.30172105'),
	'identifierSchemeCodeUnspsc190501.30172106': __('identifierSchemeCodeUnspsc190501.30172106'),
	'identifierSchemeCodeUnspsc190501.30172107': __('identifierSchemeCodeUnspsc190501.30172107'),
	'identifierSchemeCodeUnspsc190501.30172108': __('identifierSchemeCodeUnspsc190501.30172108'),
	'identifierSchemeCodeUnspsc190501.30172109': __('identifierSchemeCodeUnspsc190501.30172109'),
	'identifierSchemeCodeUnspsc190501.30172110': __('identifierSchemeCodeUnspsc190501.30172110'),
	'identifierSchemeCodeUnspsc190501.30172111': __('identifierSchemeCodeUnspsc190501.30172111'),
	'identifierSchemeCodeUnspsc190501.30172112': __('identifierSchemeCodeUnspsc190501.30172112'),
	'identifierSchemeCodeUnspsc190501.30172113': __('identifierSchemeCodeUnspsc190501.30172113'),
	'identifierSchemeCodeUnspsc190501.30180000': __('identifierSchemeCodeUnspsc190501.30180000'),
	'identifierSchemeCodeUnspsc190501.30181500': __('identifierSchemeCodeUnspsc190501.30181500'),
	'identifierSchemeCodeUnspsc190501.30181501': __('identifierSchemeCodeUnspsc190501.30181501'),
	'identifierSchemeCodeUnspsc190501.30181502': __('identifierSchemeCodeUnspsc190501.30181502'),
	'identifierSchemeCodeUnspsc190501.30181503': __('identifierSchemeCodeUnspsc190501.30181503'),
	'identifierSchemeCodeUnspsc190501.30181504': __('identifierSchemeCodeUnspsc190501.30181504'),
	'identifierSchemeCodeUnspsc190501.30181505': __('identifierSchemeCodeUnspsc190501.30181505'),
	'identifierSchemeCodeUnspsc190501.30181506': __('identifierSchemeCodeUnspsc190501.30181506'),
	'identifierSchemeCodeUnspsc190501.30181507': __('identifierSchemeCodeUnspsc190501.30181507'),
	'identifierSchemeCodeUnspsc190501.30181508': __('identifierSchemeCodeUnspsc190501.30181508'),
	'identifierSchemeCodeUnspsc190501.30181511': __('identifierSchemeCodeUnspsc190501.30181511'),
	'identifierSchemeCodeUnspsc190501.30181514': __('identifierSchemeCodeUnspsc190501.30181514'),
	'identifierSchemeCodeUnspsc190501.30181515': __('identifierSchemeCodeUnspsc190501.30181515'),
	'identifierSchemeCodeUnspsc190501.30181516': __('identifierSchemeCodeUnspsc190501.30181516'),
	'identifierSchemeCodeUnspsc190501.30181517': __('identifierSchemeCodeUnspsc190501.30181517'),
	'identifierSchemeCodeUnspsc190501.30181600': __('identifierSchemeCodeUnspsc190501.30181600'),
	'identifierSchemeCodeUnspsc190501.30181601': __('identifierSchemeCodeUnspsc190501.30181601'),
	'identifierSchemeCodeUnspsc190501.30181602': __('identifierSchemeCodeUnspsc190501.30181602'),
	'identifierSchemeCodeUnspsc190501.30181603': __('identifierSchemeCodeUnspsc190501.30181603'),
	'identifierSchemeCodeUnspsc190501.30181604': __('identifierSchemeCodeUnspsc190501.30181604'),
	'identifierSchemeCodeUnspsc190501.30181605': __('identifierSchemeCodeUnspsc190501.30181605'),
	'identifierSchemeCodeUnspsc190501.30181606': __('identifierSchemeCodeUnspsc190501.30181606'),
	'identifierSchemeCodeUnspsc190501.30181607': __('identifierSchemeCodeUnspsc190501.30181607'),
	'identifierSchemeCodeUnspsc190501.30181608': __('identifierSchemeCodeUnspsc190501.30181608'),
	'identifierSchemeCodeUnspsc190501.30181609': __('identifierSchemeCodeUnspsc190501.30181609'),
	'identifierSchemeCodeUnspsc190501.30181610': __('identifierSchemeCodeUnspsc190501.30181610'),
	'identifierSchemeCodeUnspsc190501.30181611': __('identifierSchemeCodeUnspsc190501.30181611'),
	'identifierSchemeCodeUnspsc190501.30181612': __('identifierSchemeCodeUnspsc190501.30181612'),
	'identifierSchemeCodeUnspsc190501.30181613': __('identifierSchemeCodeUnspsc190501.30181613'),
	'identifierSchemeCodeUnspsc190501.30181614': __('identifierSchemeCodeUnspsc190501.30181614'),
	'identifierSchemeCodeUnspsc190501.30181700': __('identifierSchemeCodeUnspsc190501.30181700'),
	'identifierSchemeCodeUnspsc190501.30181701': __('identifierSchemeCodeUnspsc190501.30181701'),
	'identifierSchemeCodeUnspsc190501.30181702': __('identifierSchemeCodeUnspsc190501.30181702'),
	'identifierSchemeCodeUnspsc190501.30181800': __('identifierSchemeCodeUnspsc190501.30181800'),
	'identifierSchemeCodeUnspsc190501.30181801': __('identifierSchemeCodeUnspsc190501.30181801'),
	'identifierSchemeCodeUnspsc190501.30181802': __('identifierSchemeCodeUnspsc190501.30181802'),
	'identifierSchemeCodeUnspsc190501.30181803': __('identifierSchemeCodeUnspsc190501.30181803'),
	'identifierSchemeCodeUnspsc190501.30181804': __('identifierSchemeCodeUnspsc190501.30181804'),
	'identifierSchemeCodeUnspsc190501.30181805': __('identifierSchemeCodeUnspsc190501.30181805'),
	'identifierSchemeCodeUnspsc190501.30181806': __('identifierSchemeCodeUnspsc190501.30181806'),
	'identifierSchemeCodeUnspsc190501.30181807': __('identifierSchemeCodeUnspsc190501.30181807'),
	'identifierSchemeCodeUnspsc190501.30181808': __('identifierSchemeCodeUnspsc190501.30181808'),
	'identifierSchemeCodeUnspsc190501.30181809': __('identifierSchemeCodeUnspsc190501.30181809'),
	'identifierSchemeCodeUnspsc190501.30181810': __('identifierSchemeCodeUnspsc190501.30181810'),
	'identifierSchemeCodeUnspsc190501.30181811': __('identifierSchemeCodeUnspsc190501.30181811'),
	'identifierSchemeCodeUnspsc190501.30181812': __('identifierSchemeCodeUnspsc190501.30181812'),
	'identifierSchemeCodeUnspsc190501.30190000': __('identifierSchemeCodeUnspsc190501.30190000'),
	'identifierSchemeCodeUnspsc190501.30191500': __('identifierSchemeCodeUnspsc190501.30191500'),
	'identifierSchemeCodeUnspsc190501.30191501': __('identifierSchemeCodeUnspsc190501.30191501'),
	'identifierSchemeCodeUnspsc190501.30191502': __('identifierSchemeCodeUnspsc190501.30191502'),
	'identifierSchemeCodeUnspsc190501.30191505': __('identifierSchemeCodeUnspsc190501.30191505'),
	'identifierSchemeCodeUnspsc190501.30191506': __('identifierSchemeCodeUnspsc190501.30191506'),
	'identifierSchemeCodeUnspsc190501.30191507': __('identifierSchemeCodeUnspsc190501.30191507'),
	'identifierSchemeCodeUnspsc190501.30191508': __('identifierSchemeCodeUnspsc190501.30191508'),
	'identifierSchemeCodeUnspsc190501.30191509': __('identifierSchemeCodeUnspsc190501.30191509'),
	'identifierSchemeCodeUnspsc190501.30191510': __('identifierSchemeCodeUnspsc190501.30191510'),
	'identifierSchemeCodeUnspsc190501.30191600': __('identifierSchemeCodeUnspsc190501.30191600'),
	'identifierSchemeCodeUnspsc190501.30191601': __('identifierSchemeCodeUnspsc190501.30191601'),
	'identifierSchemeCodeUnspsc190501.30191602': __('identifierSchemeCodeUnspsc190501.30191602'),
	'identifierSchemeCodeUnspsc190501.30191603': __('identifierSchemeCodeUnspsc190501.30191603'),
	'identifierSchemeCodeUnspsc190501.30191604': __('identifierSchemeCodeUnspsc190501.30191604'),
	'identifierSchemeCodeUnspsc190501.30191605': __('identifierSchemeCodeUnspsc190501.30191605'),
	'identifierSchemeCodeUnspsc190501.30191606': __('identifierSchemeCodeUnspsc190501.30191606'),
	'identifierSchemeCodeUnspsc190501.30191607': __('identifierSchemeCodeUnspsc190501.30191607'),
	'identifierSchemeCodeUnspsc190501.30191608': __('identifierSchemeCodeUnspsc190501.30191608'),
	'identifierSchemeCodeUnspsc190501.30191609': __('identifierSchemeCodeUnspsc190501.30191609'),
	'identifierSchemeCodeUnspsc190501.30191610': __('identifierSchemeCodeUnspsc190501.30191610'),
	'identifierSchemeCodeUnspsc190501.30191611': __('identifierSchemeCodeUnspsc190501.30191611'),
	'identifierSchemeCodeUnspsc190501.30191612': __('identifierSchemeCodeUnspsc190501.30191612'),
	'identifierSchemeCodeUnspsc190501.30191613': __('identifierSchemeCodeUnspsc190501.30191613'),
	'identifierSchemeCodeUnspsc190501.30191614': __('identifierSchemeCodeUnspsc190501.30191614'),
	'identifierSchemeCodeUnspsc190501.30191615': __('identifierSchemeCodeUnspsc190501.30191615'),
	'identifierSchemeCodeUnspsc190501.30191616': __('identifierSchemeCodeUnspsc190501.30191616'),
	'identifierSchemeCodeUnspsc190501.30191617': __('identifierSchemeCodeUnspsc190501.30191617'),
	'identifierSchemeCodeUnspsc190501.30191700': __('identifierSchemeCodeUnspsc190501.30191700'),
	'identifierSchemeCodeUnspsc190501.30191701': __('identifierSchemeCodeUnspsc190501.30191701'),
	'identifierSchemeCodeUnspsc190501.30191702': __('identifierSchemeCodeUnspsc190501.30191702'),
	'identifierSchemeCodeUnspsc190501.30191800': __('identifierSchemeCodeUnspsc190501.30191800'),
	'identifierSchemeCodeUnspsc190501.30191801': __('identifierSchemeCodeUnspsc190501.30191801'),
	'identifierSchemeCodeUnspsc190501.30191802': __('identifierSchemeCodeUnspsc190501.30191802'),
	'identifierSchemeCodeUnspsc190501.30191803': __('identifierSchemeCodeUnspsc190501.30191803'),
	'identifierSchemeCodeUnspsc190501.30191804': __('identifierSchemeCodeUnspsc190501.30191804'),
	'identifierSchemeCodeUnspsc190501.30191805': __('identifierSchemeCodeUnspsc190501.30191805'),
	'identifierSchemeCodeUnspsc190501.30240000': __('identifierSchemeCodeUnspsc190501.30240000'),
	'identifierSchemeCodeUnspsc190501.30241500': __('identifierSchemeCodeUnspsc190501.30241500'),
	'identifierSchemeCodeUnspsc190501.30241501': __('identifierSchemeCodeUnspsc190501.30241501'),
	'identifierSchemeCodeUnspsc190501.30241502': __('identifierSchemeCodeUnspsc190501.30241502'),
	'identifierSchemeCodeUnspsc190501.30241503': __('identifierSchemeCodeUnspsc190501.30241503'),
	'identifierSchemeCodeUnspsc190501.30241504': __('identifierSchemeCodeUnspsc190501.30241504'),
	'identifierSchemeCodeUnspsc190501.30241505': __('identifierSchemeCodeUnspsc190501.30241505'),
	'identifierSchemeCodeUnspsc190501.30241506': __('identifierSchemeCodeUnspsc190501.30241506'),
	'identifierSchemeCodeUnspsc190501.30241507': __('identifierSchemeCodeUnspsc190501.30241507'),
	'identifierSchemeCodeUnspsc190501.30241508': __('identifierSchemeCodeUnspsc190501.30241508'),
	'identifierSchemeCodeUnspsc190501.30241509': __('identifierSchemeCodeUnspsc190501.30241509'),
	'identifierSchemeCodeUnspsc190501.30241510': __('identifierSchemeCodeUnspsc190501.30241510'),
	'identifierSchemeCodeUnspsc190501.30241511': __('identifierSchemeCodeUnspsc190501.30241511'),
	'identifierSchemeCodeUnspsc190501.30241512': __('identifierSchemeCodeUnspsc190501.30241512'),
	'identifierSchemeCodeUnspsc190501.30241513': __('identifierSchemeCodeUnspsc190501.30241513'),
	'identifierSchemeCodeUnspsc190501.30241514': __('identifierSchemeCodeUnspsc190501.30241514'),
	'identifierSchemeCodeUnspsc190501.30241515': __('identifierSchemeCodeUnspsc190501.30241515'),
	'identifierSchemeCodeUnspsc190501.30241600': __('identifierSchemeCodeUnspsc190501.30241600'),
	'identifierSchemeCodeUnspsc190501.30241601': __('identifierSchemeCodeUnspsc190501.30241601'),
	'identifierSchemeCodeUnspsc190501.30241602': __('identifierSchemeCodeUnspsc190501.30241602'),
	'identifierSchemeCodeUnspsc190501.30241603': __('identifierSchemeCodeUnspsc190501.30241603'),
	'identifierSchemeCodeUnspsc190501.30241604': __('identifierSchemeCodeUnspsc190501.30241604'),
	'identifierSchemeCodeUnspsc190501.30241700': __('identifierSchemeCodeUnspsc190501.30241700'),
	'identifierSchemeCodeUnspsc190501.30241701': __('identifierSchemeCodeUnspsc190501.30241701'),
	'identifierSchemeCodeUnspsc190501.30241702': __('identifierSchemeCodeUnspsc190501.30241702'),
	'identifierSchemeCodeUnspsc190501.30250000': __('identifierSchemeCodeUnspsc190501.30250000'),
	'identifierSchemeCodeUnspsc190501.30251500': __('identifierSchemeCodeUnspsc190501.30251500'),
	'identifierSchemeCodeUnspsc190501.30251501': __('identifierSchemeCodeUnspsc190501.30251501'),
	'identifierSchemeCodeUnspsc190501.30251502': __('identifierSchemeCodeUnspsc190501.30251502'),
	'identifierSchemeCodeUnspsc190501.30251503': __('identifierSchemeCodeUnspsc190501.30251503'),
	'identifierSchemeCodeUnspsc190501.30251504': __('identifierSchemeCodeUnspsc190501.30251504'),
	'identifierSchemeCodeUnspsc190501.30251505': __('identifierSchemeCodeUnspsc190501.30251505'),
	'identifierSchemeCodeUnspsc190501.30260000': __('identifierSchemeCodeUnspsc190501.30260000'),
	'identifierSchemeCodeUnspsc190501.30261500': __('identifierSchemeCodeUnspsc190501.30261500'),
	'identifierSchemeCodeUnspsc190501.30261501': __('identifierSchemeCodeUnspsc190501.30261501'),
	'identifierSchemeCodeUnspsc190501.30261502': __('identifierSchemeCodeUnspsc190501.30261502'),
	'identifierSchemeCodeUnspsc190501.30261503': __('identifierSchemeCodeUnspsc190501.30261503'),
	'identifierSchemeCodeUnspsc190501.30261504': __('identifierSchemeCodeUnspsc190501.30261504'),
	'identifierSchemeCodeUnspsc190501.30261505': __('identifierSchemeCodeUnspsc190501.30261505'),
	'identifierSchemeCodeUnspsc190501.30261600': __('identifierSchemeCodeUnspsc190501.30261600'),
	'identifierSchemeCodeUnspsc190501.30261601': __('identifierSchemeCodeUnspsc190501.30261601'),
	'identifierSchemeCodeUnspsc190501.30261602': __('identifierSchemeCodeUnspsc190501.30261602'),
	'identifierSchemeCodeUnspsc190501.30261603': __('identifierSchemeCodeUnspsc190501.30261603'),
	'identifierSchemeCodeUnspsc190501.30261700': __('identifierSchemeCodeUnspsc190501.30261700'),
	'identifierSchemeCodeUnspsc190501.30261701': __('identifierSchemeCodeUnspsc190501.30261701'),
	'identifierSchemeCodeUnspsc190501.30261702': __('identifierSchemeCodeUnspsc190501.30261702'),
	'identifierSchemeCodeUnspsc190501.30261703': __('identifierSchemeCodeUnspsc190501.30261703'),
	'identifierSchemeCodeUnspsc190501.30261704': __('identifierSchemeCodeUnspsc190501.30261704'),
	'identifierSchemeCodeUnspsc190501.30261800': __('identifierSchemeCodeUnspsc190501.30261800'),
	'identifierSchemeCodeUnspsc190501.30261801': __('identifierSchemeCodeUnspsc190501.30261801'),
	'identifierSchemeCodeUnspsc190501.30261802': __('identifierSchemeCodeUnspsc190501.30261802'),
	'identifierSchemeCodeUnspsc190501.30261900': __('identifierSchemeCodeUnspsc190501.30261900'),
	'identifierSchemeCodeUnspsc190501.30261901': __('identifierSchemeCodeUnspsc190501.30261901'),
	'identifierSchemeCodeUnspsc190501.30261902': __('identifierSchemeCodeUnspsc190501.30261902'),
	'identifierSchemeCodeUnspsc190501.30261903': __('identifierSchemeCodeUnspsc190501.30261903'),
	'identifierSchemeCodeUnspsc190501.30261904': __('identifierSchemeCodeUnspsc190501.30261904'),
	'identifierSchemeCodeUnspsc190501.30262000': __('identifierSchemeCodeUnspsc190501.30262000'),
	'identifierSchemeCodeUnspsc190501.30262001': __('identifierSchemeCodeUnspsc190501.30262001'),
	'identifierSchemeCodeUnspsc190501.30262002': __('identifierSchemeCodeUnspsc190501.30262002'),
	'identifierSchemeCodeUnspsc190501.30262100': __('identifierSchemeCodeUnspsc190501.30262100'),
	'identifierSchemeCodeUnspsc190501.30262101': __('identifierSchemeCodeUnspsc190501.30262101'),
	'identifierSchemeCodeUnspsc190501.30262102': __('identifierSchemeCodeUnspsc190501.30262102'),
	'identifierSchemeCodeUnspsc190501.30262103': __('identifierSchemeCodeUnspsc190501.30262103'),
	'identifierSchemeCodeUnspsc190501.30262104': __('identifierSchemeCodeUnspsc190501.30262104'),
	'identifierSchemeCodeUnspsc190501.30262200': __('identifierSchemeCodeUnspsc190501.30262200'),
	'identifierSchemeCodeUnspsc190501.30262201': __('identifierSchemeCodeUnspsc190501.30262201'),
	'identifierSchemeCodeUnspsc190501.30262202': __('identifierSchemeCodeUnspsc190501.30262202'),
	'identifierSchemeCodeUnspsc190501.30262300': __('identifierSchemeCodeUnspsc190501.30262300'),
	'identifierSchemeCodeUnspsc190501.30262301': __('identifierSchemeCodeUnspsc190501.30262301'),
	'identifierSchemeCodeUnspsc190501.30262302': __('identifierSchemeCodeUnspsc190501.30262302'),
	'identifierSchemeCodeUnspsc190501.30262400': __('identifierSchemeCodeUnspsc190501.30262400'),
	'identifierSchemeCodeUnspsc190501.30262401': __('identifierSchemeCodeUnspsc190501.30262401'),
	'identifierSchemeCodeUnspsc190501.30262402': __('identifierSchemeCodeUnspsc190501.30262402'),
	'identifierSchemeCodeUnspsc190501.30262403': __('identifierSchemeCodeUnspsc190501.30262403'),
	'identifierSchemeCodeUnspsc190501.30262404': __('identifierSchemeCodeUnspsc190501.30262404'),
	'identifierSchemeCodeUnspsc190501.30262405': __('identifierSchemeCodeUnspsc190501.30262405'),
	'identifierSchemeCodeUnspsc190501.30262500': __('identifierSchemeCodeUnspsc190501.30262500'),
	'identifierSchemeCodeUnspsc190501.30262501': __('identifierSchemeCodeUnspsc190501.30262501'),
	'identifierSchemeCodeUnspsc190501.30262502': __('identifierSchemeCodeUnspsc190501.30262502'),
	'identifierSchemeCodeUnspsc190501.30262503': __('identifierSchemeCodeUnspsc190501.30262503'),
	'identifierSchemeCodeUnspsc190501.30262504': __('identifierSchemeCodeUnspsc190501.30262504'),
	'identifierSchemeCodeUnspsc190501.30262505': __('identifierSchemeCodeUnspsc190501.30262505'),
	'identifierSchemeCodeUnspsc190501.30262506': __('identifierSchemeCodeUnspsc190501.30262506'),
	'identifierSchemeCodeUnspsc190501.30262600': __('identifierSchemeCodeUnspsc190501.30262600'),
	'identifierSchemeCodeUnspsc190501.30262601': __('identifierSchemeCodeUnspsc190501.30262601'),
	'identifierSchemeCodeUnspsc190501.30262602': __('identifierSchemeCodeUnspsc190501.30262602'),
	'identifierSchemeCodeUnspsc190501.30262603': __('identifierSchemeCodeUnspsc190501.30262603'),
	'identifierSchemeCodeUnspsc190501.30262604': __('identifierSchemeCodeUnspsc190501.30262604'),
	'identifierSchemeCodeUnspsc190501.30262605': __('identifierSchemeCodeUnspsc190501.30262605'),
	'identifierSchemeCodeUnspsc190501.30262606': __('identifierSchemeCodeUnspsc190501.30262606'),
	'identifierSchemeCodeUnspsc190501.30262700': __('identifierSchemeCodeUnspsc190501.30262700'),
	'identifierSchemeCodeUnspsc190501.30262701': __('identifierSchemeCodeUnspsc190501.30262701'),
	'identifierSchemeCodeUnspsc190501.30262702': __('identifierSchemeCodeUnspsc190501.30262702'),
	'identifierSchemeCodeUnspsc190501.30262703': __('identifierSchemeCodeUnspsc190501.30262703'),
	'identifierSchemeCodeUnspsc190501.30262704': __('identifierSchemeCodeUnspsc190501.30262704'),
	'identifierSchemeCodeUnspsc190501.30262800': __('identifierSchemeCodeUnspsc190501.30262800'),
	'identifierSchemeCodeUnspsc190501.30262801': __('identifierSchemeCodeUnspsc190501.30262801'),
	'identifierSchemeCodeUnspsc190501.30262802': __('identifierSchemeCodeUnspsc190501.30262802'),
	'identifierSchemeCodeUnspsc190501.30262900': __('identifierSchemeCodeUnspsc190501.30262900'),
	'identifierSchemeCodeUnspsc190501.30262901': __('identifierSchemeCodeUnspsc190501.30262901'),
	'identifierSchemeCodeUnspsc190501.30262902': __('identifierSchemeCodeUnspsc190501.30262902'),
	'identifierSchemeCodeUnspsc190501.30262903': __('identifierSchemeCodeUnspsc190501.30262903'),
	'identifierSchemeCodeUnspsc190501.30262904': __('identifierSchemeCodeUnspsc190501.30262904'),
	'identifierSchemeCodeUnspsc190501.30263000': __('identifierSchemeCodeUnspsc190501.30263000'),
	'identifierSchemeCodeUnspsc190501.30263001': __('identifierSchemeCodeUnspsc190501.30263001'),
	'identifierSchemeCodeUnspsc190501.30263002': __('identifierSchemeCodeUnspsc190501.30263002'),
	'identifierSchemeCodeUnspsc190501.30263100': __('identifierSchemeCodeUnspsc190501.30263100'),
	'identifierSchemeCodeUnspsc190501.30263101': __('identifierSchemeCodeUnspsc190501.30263101'),
	'identifierSchemeCodeUnspsc190501.30263102': __('identifierSchemeCodeUnspsc190501.30263102'),
	'identifierSchemeCodeUnspsc190501.30263200': __('identifierSchemeCodeUnspsc190501.30263200'),
	'identifierSchemeCodeUnspsc190501.30263201': __('identifierSchemeCodeUnspsc190501.30263201'),
	'identifierSchemeCodeUnspsc190501.30263202': __('identifierSchemeCodeUnspsc190501.30263202'),
	'identifierSchemeCodeUnspsc190501.30263203': __('identifierSchemeCodeUnspsc190501.30263203'),
	'identifierSchemeCodeUnspsc190501.30263300': __('identifierSchemeCodeUnspsc190501.30263300'),
	'identifierSchemeCodeUnspsc190501.30263301': __('identifierSchemeCodeUnspsc190501.30263301'),
	'identifierSchemeCodeUnspsc190501.30263302': __('identifierSchemeCodeUnspsc190501.30263302'),
	'identifierSchemeCodeUnspsc190501.30263303': __('identifierSchemeCodeUnspsc190501.30263303'),
	'identifierSchemeCodeUnspsc190501.30263400': __('identifierSchemeCodeUnspsc190501.30263400'),
	'identifierSchemeCodeUnspsc190501.30263401': __('identifierSchemeCodeUnspsc190501.30263401'),
	'identifierSchemeCodeUnspsc190501.30263402': __('identifierSchemeCodeUnspsc190501.30263402'),
	'identifierSchemeCodeUnspsc190501.30263500': __('identifierSchemeCodeUnspsc190501.30263500'),
	'identifierSchemeCodeUnspsc190501.30263501': __('identifierSchemeCodeUnspsc190501.30263501'),
	'identifierSchemeCodeUnspsc190501.30263502': __('identifierSchemeCodeUnspsc190501.30263502'),
	'identifierSchemeCodeUnspsc190501.30263503': __('identifierSchemeCodeUnspsc190501.30263503'),
	'identifierSchemeCodeUnspsc190501.30263504': __('identifierSchemeCodeUnspsc190501.30263504'),
	'identifierSchemeCodeUnspsc190501.30263600': __('identifierSchemeCodeUnspsc190501.30263600'),
	'identifierSchemeCodeUnspsc190501.30263601': __('identifierSchemeCodeUnspsc190501.30263601'),
	'identifierSchemeCodeUnspsc190501.30263602': __('identifierSchemeCodeUnspsc190501.30263602'),
	'identifierSchemeCodeUnspsc190501.30263603': __('identifierSchemeCodeUnspsc190501.30263603'),
	'identifierSchemeCodeUnspsc190501.30263604': __('identifierSchemeCodeUnspsc190501.30263604'),
	'identifierSchemeCodeUnspsc190501.30263605': __('identifierSchemeCodeUnspsc190501.30263605'),
	'identifierSchemeCodeUnspsc190501.30263606': __('identifierSchemeCodeUnspsc190501.30263606'),
	'identifierSchemeCodeUnspsc190501.30263607': __('identifierSchemeCodeUnspsc190501.30263607'),
	'identifierSchemeCodeUnspsc190501.30263608': __('identifierSchemeCodeUnspsc190501.30263608'),
	'identifierSchemeCodeUnspsc190501.30263700': __('identifierSchemeCodeUnspsc190501.30263700'),
	'identifierSchemeCodeUnspsc190501.30263701': __('identifierSchemeCodeUnspsc190501.30263701'),
	'identifierSchemeCodeUnspsc190501.30263702': __('identifierSchemeCodeUnspsc190501.30263702'),
	'identifierSchemeCodeUnspsc190501.30263703': __('identifierSchemeCodeUnspsc190501.30263703'),
	'identifierSchemeCodeUnspsc190501.30263704': __('identifierSchemeCodeUnspsc190501.30263704'),
	'identifierSchemeCodeUnspsc190501.30263705': __('identifierSchemeCodeUnspsc190501.30263705'),
	'identifierSchemeCodeUnspsc190501.30263706': __('identifierSchemeCodeUnspsc190501.30263706'),
	'identifierSchemeCodeUnspsc190501.30263707': __('identifierSchemeCodeUnspsc190501.30263707'),
	'identifierSchemeCodeUnspsc190501.30263708': __('identifierSchemeCodeUnspsc190501.30263708'),
	'identifierSchemeCodeUnspsc190501.30263709': __('identifierSchemeCodeUnspsc190501.30263709'),
	'identifierSchemeCodeUnspsc190501.30263710': __('identifierSchemeCodeUnspsc190501.30263710'),
	'identifierSchemeCodeUnspsc190501.30263800': __('identifierSchemeCodeUnspsc190501.30263800'),
	'identifierSchemeCodeUnspsc190501.30263801': __('identifierSchemeCodeUnspsc190501.30263801'),
	'identifierSchemeCodeUnspsc190501.30263802': __('identifierSchemeCodeUnspsc190501.30263802'),
	'identifierSchemeCodeUnspsc190501.30263900': __('identifierSchemeCodeUnspsc190501.30263900'),
	'identifierSchemeCodeUnspsc190501.30263901': __('identifierSchemeCodeUnspsc190501.30263901'),
	'identifierSchemeCodeUnspsc190501.30264000': __('identifierSchemeCodeUnspsc190501.30264000'),
	'identifierSchemeCodeUnspsc190501.30264001': __('identifierSchemeCodeUnspsc190501.30264001'),
	'identifierSchemeCodeUnspsc190501.30264002': __('identifierSchemeCodeUnspsc190501.30264002'),
	'identifierSchemeCodeUnspsc190501.30264003': __('identifierSchemeCodeUnspsc190501.30264003'),
	'identifierSchemeCodeUnspsc190501.30264004': __('identifierSchemeCodeUnspsc190501.30264004'),
	'identifierSchemeCodeUnspsc190501.30264005': __('identifierSchemeCodeUnspsc190501.30264005'),
	'identifierSchemeCodeUnspsc190501.30264006': __('identifierSchemeCodeUnspsc190501.30264006'),
	'identifierSchemeCodeUnspsc190501.30264007': __('identifierSchemeCodeUnspsc190501.30264007'),
	'identifierSchemeCodeUnspsc190501.30264008': __('identifierSchemeCodeUnspsc190501.30264008'),
	'identifierSchemeCodeUnspsc190501.30264009': __('identifierSchemeCodeUnspsc190501.30264009'),
	'identifierSchemeCodeUnspsc190501.30264010': __('identifierSchemeCodeUnspsc190501.30264010'),
	'identifierSchemeCodeUnspsc190501.30264011': __('identifierSchemeCodeUnspsc190501.30264011'),
	'identifierSchemeCodeUnspsc190501.30264012': __('identifierSchemeCodeUnspsc190501.30264012'),
	'identifierSchemeCodeUnspsc190501.30264013': __('identifierSchemeCodeUnspsc190501.30264013'),
	'identifierSchemeCodeUnspsc190501.30264014': __('identifierSchemeCodeUnspsc190501.30264014'),
	'identifierSchemeCodeUnspsc190501.30264015': __('identifierSchemeCodeUnspsc190501.30264015'),
	'identifierSchemeCodeUnspsc190501.30264016': __('identifierSchemeCodeUnspsc190501.30264016'),
	'identifierSchemeCodeUnspsc190501.30264017': __('identifierSchemeCodeUnspsc190501.30264017'),
	'identifierSchemeCodeUnspsc190501.30264018': __('identifierSchemeCodeUnspsc190501.30264018'),
	'identifierSchemeCodeUnspsc190501.30264019': __('identifierSchemeCodeUnspsc190501.30264019'),
	'identifierSchemeCodeUnspsc190501.30264020': __('identifierSchemeCodeUnspsc190501.30264020'),
	'identifierSchemeCodeUnspsc190501.30264021': __('identifierSchemeCodeUnspsc190501.30264021'),
	'identifierSchemeCodeUnspsc190501.30264022': __('identifierSchemeCodeUnspsc190501.30264022'),
	'identifierSchemeCodeUnspsc190501.30264023': __('identifierSchemeCodeUnspsc190501.30264023'),
	'identifierSchemeCodeUnspsc190501.30264024': __('identifierSchemeCodeUnspsc190501.30264024'),
	'identifierSchemeCodeUnspsc190501.30264100': __('identifierSchemeCodeUnspsc190501.30264100'),
	'identifierSchemeCodeUnspsc190501.30264101': __('identifierSchemeCodeUnspsc190501.30264101'),
	'identifierSchemeCodeUnspsc190501.30264102': __('identifierSchemeCodeUnspsc190501.30264102'),
	'identifierSchemeCodeUnspsc190501.30264103': __('identifierSchemeCodeUnspsc190501.30264103'),
	'identifierSchemeCodeUnspsc190501.30264104': __('identifierSchemeCodeUnspsc190501.30264104'),
	'identifierSchemeCodeUnspsc190501.30264105': __('identifierSchemeCodeUnspsc190501.30264105'),
	'identifierSchemeCodeUnspsc190501.30264106': __('identifierSchemeCodeUnspsc190501.30264106'),
	'identifierSchemeCodeUnspsc190501.30264107': __('identifierSchemeCodeUnspsc190501.30264107'),
	'identifierSchemeCodeUnspsc190501.30264108': __('identifierSchemeCodeUnspsc190501.30264108'),
	'identifierSchemeCodeUnspsc190501.30264109': __('identifierSchemeCodeUnspsc190501.30264109'),
	'identifierSchemeCodeUnspsc190501.30264110': __('identifierSchemeCodeUnspsc190501.30264110'),
	'identifierSchemeCodeUnspsc190501.30264200': __('identifierSchemeCodeUnspsc190501.30264200'),
	'identifierSchemeCodeUnspsc190501.30264201': __('identifierSchemeCodeUnspsc190501.30264201'),
	'identifierSchemeCodeUnspsc190501.30264202': __('identifierSchemeCodeUnspsc190501.30264202'),
	'identifierSchemeCodeUnspsc190501.30264203': __('identifierSchemeCodeUnspsc190501.30264203'),
	'identifierSchemeCodeUnspsc190501.30264204': __('identifierSchemeCodeUnspsc190501.30264204'),
	'identifierSchemeCodeUnspsc190501.30264300': __('identifierSchemeCodeUnspsc190501.30264300'),
	'identifierSchemeCodeUnspsc190501.30264301': __('identifierSchemeCodeUnspsc190501.30264301'),
	'identifierSchemeCodeUnspsc190501.30264302': __('identifierSchemeCodeUnspsc190501.30264302'),
	'identifierSchemeCodeUnspsc190501.30264303': __('identifierSchemeCodeUnspsc190501.30264303'),
	'identifierSchemeCodeUnspsc190501.30264304': __('identifierSchemeCodeUnspsc190501.30264304'),
	'identifierSchemeCodeUnspsc190501.30264305': __('identifierSchemeCodeUnspsc190501.30264305'),
	'identifierSchemeCodeUnspsc190501.30264400': __('identifierSchemeCodeUnspsc190501.30264400'),
	'identifierSchemeCodeUnspsc190501.30264401': __('identifierSchemeCodeUnspsc190501.30264401'),
	'identifierSchemeCodeUnspsc190501.30264402': __('identifierSchemeCodeUnspsc190501.30264402'),
	'identifierSchemeCodeUnspsc190501.30264403': __('identifierSchemeCodeUnspsc190501.30264403'),
	'identifierSchemeCodeUnspsc190501.30264404': __('identifierSchemeCodeUnspsc190501.30264404'),
	'identifierSchemeCodeUnspsc190501.30264405': __('identifierSchemeCodeUnspsc190501.30264405'),
	'identifierSchemeCodeUnspsc190501.30264406': __('identifierSchemeCodeUnspsc190501.30264406'),
	'identifierSchemeCodeUnspsc190501.30264407': __('identifierSchemeCodeUnspsc190501.30264407'),
	'identifierSchemeCodeUnspsc190501.30264408': __('identifierSchemeCodeUnspsc190501.30264408'),
	'identifierSchemeCodeUnspsc190501.30264409': __('identifierSchemeCodeUnspsc190501.30264409'),
	'identifierSchemeCodeUnspsc190501.30264410': __('identifierSchemeCodeUnspsc190501.30264410'),
	'identifierSchemeCodeUnspsc190501.30264411': __('identifierSchemeCodeUnspsc190501.30264411'),
	'identifierSchemeCodeUnspsc190501.30264412': __('identifierSchemeCodeUnspsc190501.30264412'),
	'identifierSchemeCodeUnspsc190501.30264413': __('identifierSchemeCodeUnspsc190501.30264413'),
	'identifierSchemeCodeUnspsc190501.30264500': __('identifierSchemeCodeUnspsc190501.30264500'),
	'identifierSchemeCodeUnspsc190501.30264501': __('identifierSchemeCodeUnspsc190501.30264501'),
	'identifierSchemeCodeUnspsc190501.30264502': __('identifierSchemeCodeUnspsc190501.30264502'),
	'identifierSchemeCodeUnspsc190501.30264503': __('identifierSchemeCodeUnspsc190501.30264503'),
	'identifierSchemeCodeUnspsc190501.30264504': __('identifierSchemeCodeUnspsc190501.30264504'),
	'identifierSchemeCodeUnspsc190501.30264505': __('identifierSchemeCodeUnspsc190501.30264505'),
	'identifierSchemeCodeUnspsc190501.30264506': __('identifierSchemeCodeUnspsc190501.30264506'),
	'identifierSchemeCodeUnspsc190501.30264600': __('identifierSchemeCodeUnspsc190501.30264600'),
	'identifierSchemeCodeUnspsc190501.30264601': __('identifierSchemeCodeUnspsc190501.30264601'),
	'identifierSchemeCodeUnspsc190501.30264602': __('identifierSchemeCodeUnspsc190501.30264602'),
	'identifierSchemeCodeUnspsc190501.30264603': __('identifierSchemeCodeUnspsc190501.30264603'),
	'identifierSchemeCodeUnspsc190501.30264604': __('identifierSchemeCodeUnspsc190501.30264604'),
	'identifierSchemeCodeUnspsc190501.30264605': __('identifierSchemeCodeUnspsc190501.30264605'),
	'identifierSchemeCodeUnspsc190501.30264606': __('identifierSchemeCodeUnspsc190501.30264606'),
	'identifierSchemeCodeUnspsc190501.30264700': __('identifierSchemeCodeUnspsc190501.30264700'),
	'identifierSchemeCodeUnspsc190501.30264701': __('identifierSchemeCodeUnspsc190501.30264701'),
	'identifierSchemeCodeUnspsc190501.30264702': __('identifierSchemeCodeUnspsc190501.30264702'),
	'identifierSchemeCodeUnspsc190501.30264703': __('identifierSchemeCodeUnspsc190501.30264703'),
	'identifierSchemeCodeUnspsc190501.30264704': __('identifierSchemeCodeUnspsc190501.30264704'),
	'identifierSchemeCodeUnspsc190501.30264705': __('identifierSchemeCodeUnspsc190501.30264705'),
	'identifierSchemeCodeUnspsc190501.30264800': __('identifierSchemeCodeUnspsc190501.30264800'),
	'identifierSchemeCodeUnspsc190501.30264801': __('identifierSchemeCodeUnspsc190501.30264801'),
	'identifierSchemeCodeUnspsc190501.30264802': __('identifierSchemeCodeUnspsc190501.30264802'),
	'identifierSchemeCodeUnspsc190501.30264803': __('identifierSchemeCodeUnspsc190501.30264803'),
	'identifierSchemeCodeUnspsc190501.30264804': __('identifierSchemeCodeUnspsc190501.30264804'),
	'identifierSchemeCodeUnspsc190501.30264805': __('identifierSchemeCodeUnspsc190501.30264805'),
	'identifierSchemeCodeUnspsc190501.30264806': __('identifierSchemeCodeUnspsc190501.30264806'),
	'identifierSchemeCodeUnspsc190501.30264900': __('identifierSchemeCodeUnspsc190501.30264900'),
	'identifierSchemeCodeUnspsc190501.30264901': __('identifierSchemeCodeUnspsc190501.30264901'),
	'identifierSchemeCodeUnspsc190501.30264902': __('identifierSchemeCodeUnspsc190501.30264902'),
	'identifierSchemeCodeUnspsc190501.30264903': __('identifierSchemeCodeUnspsc190501.30264903'),
	'identifierSchemeCodeUnspsc190501.30264904': __('identifierSchemeCodeUnspsc190501.30264904'),
	'identifierSchemeCodeUnspsc190501.30264905': __('identifierSchemeCodeUnspsc190501.30264905'),
	'identifierSchemeCodeUnspsc190501.30264906': __('identifierSchemeCodeUnspsc190501.30264906'),
	'identifierSchemeCodeUnspsc190501.30264907': __('identifierSchemeCodeUnspsc190501.30264907'),
	'identifierSchemeCodeUnspsc190501.30264908': __('identifierSchemeCodeUnspsc190501.30264908'),
	'identifierSchemeCodeUnspsc190501.30264909': __('identifierSchemeCodeUnspsc190501.30264909'),
	'identifierSchemeCodeUnspsc190501.30264910': __('identifierSchemeCodeUnspsc190501.30264910'),
	'identifierSchemeCodeUnspsc190501.30264911': __('identifierSchemeCodeUnspsc190501.30264911'),
	'identifierSchemeCodeUnspsc190501.30264912': __('identifierSchemeCodeUnspsc190501.30264912'),
	'identifierSchemeCodeUnspsc190501.30265000': __('identifierSchemeCodeUnspsc190501.30265000'),
	'identifierSchemeCodeUnspsc190501.30265001': __('identifierSchemeCodeUnspsc190501.30265001'),
	'identifierSchemeCodeUnspsc190501.30265002': __('identifierSchemeCodeUnspsc190501.30265002'),
	'identifierSchemeCodeUnspsc190501.30265003': __('identifierSchemeCodeUnspsc190501.30265003'),
	'identifierSchemeCodeUnspsc190501.30265004': __('identifierSchemeCodeUnspsc190501.30265004'),
	'identifierSchemeCodeUnspsc190501.30265005': __('identifierSchemeCodeUnspsc190501.30265005'),
	'identifierSchemeCodeUnspsc190501.30265006': __('identifierSchemeCodeUnspsc190501.30265006'),
	'identifierSchemeCodeUnspsc190501.30265007': __('identifierSchemeCodeUnspsc190501.30265007'),
	'identifierSchemeCodeUnspsc190501.30265008': __('identifierSchemeCodeUnspsc190501.30265008'),
	'identifierSchemeCodeUnspsc190501.30265009': __('identifierSchemeCodeUnspsc190501.30265009'),
	'identifierSchemeCodeUnspsc190501.30265010': __('identifierSchemeCodeUnspsc190501.30265010'),
	'identifierSchemeCodeUnspsc190501.30265011': __('identifierSchemeCodeUnspsc190501.30265011'),
	'identifierSchemeCodeUnspsc190501.30265012': __('identifierSchemeCodeUnspsc190501.30265012'),
	'identifierSchemeCodeUnspsc190501.30265100': __('identifierSchemeCodeUnspsc190501.30265100'),
	'identifierSchemeCodeUnspsc190501.30265101': __('identifierSchemeCodeUnspsc190501.30265101'),
	'identifierSchemeCodeUnspsc190501.30265102': __('identifierSchemeCodeUnspsc190501.30265102'),
	'identifierSchemeCodeUnspsc190501.30265103': __('identifierSchemeCodeUnspsc190501.30265103'),
	'identifierSchemeCodeUnspsc190501.30265104': __('identifierSchemeCodeUnspsc190501.30265104'),
	'identifierSchemeCodeUnspsc190501.30265105': __('identifierSchemeCodeUnspsc190501.30265105'),
	'identifierSchemeCodeUnspsc190501.30265106': __('identifierSchemeCodeUnspsc190501.30265106'),
	'identifierSchemeCodeUnspsc190501.30265200': __('identifierSchemeCodeUnspsc190501.30265200'),
	'identifierSchemeCodeUnspsc190501.30265201': __('identifierSchemeCodeUnspsc190501.30265201'),
	'identifierSchemeCodeUnspsc190501.30265202': __('identifierSchemeCodeUnspsc190501.30265202'),
	'identifierSchemeCodeUnspsc190501.30265300': __('identifierSchemeCodeUnspsc190501.30265300'),
	'identifierSchemeCodeUnspsc190501.30265301': __('identifierSchemeCodeUnspsc190501.30265301'),
	'identifierSchemeCodeUnspsc190501.30265302': __('identifierSchemeCodeUnspsc190501.30265302'),
	'identifierSchemeCodeUnspsc190501.30265303': __('identifierSchemeCodeUnspsc190501.30265303'),
	'identifierSchemeCodeUnspsc190501.30265304': __('identifierSchemeCodeUnspsc190501.30265304'),
	'identifierSchemeCodeUnspsc190501.30265400': __('identifierSchemeCodeUnspsc190501.30265400'),
	'identifierSchemeCodeUnspsc190501.30265401': __('identifierSchemeCodeUnspsc190501.30265401'),
	'identifierSchemeCodeUnspsc190501.30265402': __('identifierSchemeCodeUnspsc190501.30265402'),
	'identifierSchemeCodeUnspsc190501.30265403': __('identifierSchemeCodeUnspsc190501.30265403'),
	'identifierSchemeCodeUnspsc190501.30265404': __('identifierSchemeCodeUnspsc190501.30265404'),
	'identifierSchemeCodeUnspsc190501.30265500': __('identifierSchemeCodeUnspsc190501.30265500'),
	'identifierSchemeCodeUnspsc190501.30265501': __('identifierSchemeCodeUnspsc190501.30265501'),
	'identifierSchemeCodeUnspsc190501.30265502': __('identifierSchemeCodeUnspsc190501.30265502'),
	'identifierSchemeCodeUnspsc190501.30265503': __('identifierSchemeCodeUnspsc190501.30265503'),
	'identifierSchemeCodeUnspsc190501.30265504': __('identifierSchemeCodeUnspsc190501.30265504'),
	'identifierSchemeCodeUnspsc190501.30265505': __('identifierSchemeCodeUnspsc190501.30265505'),
	'identifierSchemeCodeUnspsc190501.30265506': __('identifierSchemeCodeUnspsc190501.30265506'),
	'identifierSchemeCodeUnspsc190501.30265600': __('identifierSchemeCodeUnspsc190501.30265600'),
	'identifierSchemeCodeUnspsc190501.30265601': __('identifierSchemeCodeUnspsc190501.30265601'),
	'identifierSchemeCodeUnspsc190501.30265602': __('identifierSchemeCodeUnspsc190501.30265602'),
	'identifierSchemeCodeUnspsc190501.30265700': __('identifierSchemeCodeUnspsc190501.30265700'),
	'identifierSchemeCodeUnspsc190501.30265701': __('identifierSchemeCodeUnspsc190501.30265701'),
	'identifierSchemeCodeUnspsc190501.30265702': __('identifierSchemeCodeUnspsc190501.30265702'),
	'identifierSchemeCodeUnspsc190501.30265703': __('identifierSchemeCodeUnspsc190501.30265703'),
	'identifierSchemeCodeUnspsc190501.30265704': __('identifierSchemeCodeUnspsc190501.30265704'),
	'identifierSchemeCodeUnspsc190501.30265800': __('identifierSchemeCodeUnspsc190501.30265800'),
	'identifierSchemeCodeUnspsc190501.30265801': __('identifierSchemeCodeUnspsc190501.30265801'),
	'identifierSchemeCodeUnspsc190501.30265802': __('identifierSchemeCodeUnspsc190501.30265802'),
	'identifierSchemeCodeUnspsc190501.30265803': __('identifierSchemeCodeUnspsc190501.30265803'),
	'identifierSchemeCodeUnspsc190501.30265900': __('identifierSchemeCodeUnspsc190501.30265900'),
	'identifierSchemeCodeUnspsc190501.30265901': __('identifierSchemeCodeUnspsc190501.30265901'),
	'identifierSchemeCodeUnspsc190501.30265902': __('identifierSchemeCodeUnspsc190501.30265902'),
	'identifierSchemeCodeUnspsc190501.30266000': __('identifierSchemeCodeUnspsc190501.30266000'),
	'identifierSchemeCodeUnspsc190501.30266001': __('identifierSchemeCodeUnspsc190501.30266001'),
	'identifierSchemeCodeUnspsc190501.30266002': __('identifierSchemeCodeUnspsc190501.30266002'),
	'identifierSchemeCodeUnspsc190501.30266100': __('identifierSchemeCodeUnspsc190501.30266100'),
	'identifierSchemeCodeUnspsc190501.30266101': __('identifierSchemeCodeUnspsc190501.30266101'),
	'identifierSchemeCodeUnspsc190501.30266102': __('identifierSchemeCodeUnspsc190501.30266102'),
	'identifierSchemeCodeUnspsc190501.30266103': __('identifierSchemeCodeUnspsc190501.30266103'),
	'identifierSchemeCodeUnspsc190501.30266200': __('identifierSchemeCodeUnspsc190501.30266200'),
	'identifierSchemeCodeUnspsc190501.30266201': __('identifierSchemeCodeUnspsc190501.30266201'),
	'identifierSchemeCodeUnspsc190501.30266202': __('identifierSchemeCodeUnspsc190501.30266202'),
	'identifierSchemeCodeUnspsc190501.30266203': __('identifierSchemeCodeUnspsc190501.30266203'),
	'identifierSchemeCodeUnspsc190501.30266204': __('identifierSchemeCodeUnspsc190501.30266204'),
	'identifierSchemeCodeUnspsc190501.30266205': __('identifierSchemeCodeUnspsc190501.30266205'),
	'identifierSchemeCodeUnspsc190501.30266206': __('identifierSchemeCodeUnspsc190501.30266206'),
	'identifierSchemeCodeUnspsc190501.30266207': __('identifierSchemeCodeUnspsc190501.30266207'),
	'identifierSchemeCodeUnspsc190501.30266208': __('identifierSchemeCodeUnspsc190501.30266208'),
	'identifierSchemeCodeUnspsc190501.30266209': __('identifierSchemeCodeUnspsc190501.30266209'),
	'identifierSchemeCodeUnspsc190501.30266210': __('identifierSchemeCodeUnspsc190501.30266210'),
	'identifierSchemeCodeUnspsc190501.30266211': __('identifierSchemeCodeUnspsc190501.30266211'),
	'identifierSchemeCodeUnspsc190501.30266212': __('identifierSchemeCodeUnspsc190501.30266212'),
	'identifierSchemeCodeUnspsc190501.30266213': __('identifierSchemeCodeUnspsc190501.30266213'),
	'identifierSchemeCodeUnspsc190501.30266214': __('identifierSchemeCodeUnspsc190501.30266214'),
	'identifierSchemeCodeUnspsc190501.30266215': __('identifierSchemeCodeUnspsc190501.30266215'),
	'identifierSchemeCodeUnspsc190501.30266216': __('identifierSchemeCodeUnspsc190501.30266216'),
	'identifierSchemeCodeUnspsc190501.30266300': __('identifierSchemeCodeUnspsc190501.30266300'),
	'identifierSchemeCodeUnspsc190501.30266301': __('identifierSchemeCodeUnspsc190501.30266301'),
	'identifierSchemeCodeUnspsc190501.30266400': __('identifierSchemeCodeUnspsc190501.30266400'),
	'identifierSchemeCodeUnspsc190501.30266401': __('identifierSchemeCodeUnspsc190501.30266401'),
	'identifierSchemeCodeUnspsc190501.30266402': __('identifierSchemeCodeUnspsc190501.30266402'),
	'identifierSchemeCodeUnspsc190501.30266403': __('identifierSchemeCodeUnspsc190501.30266403'),
	'identifierSchemeCodeUnspsc190501.30266404': __('identifierSchemeCodeUnspsc190501.30266404'),
	'identifierSchemeCodeUnspsc190501.30266405': __('identifierSchemeCodeUnspsc190501.30266405'),
	'identifierSchemeCodeUnspsc190501.30266406': __('identifierSchemeCodeUnspsc190501.30266406'),
	'identifierSchemeCodeUnspsc190501.30266407': __('identifierSchemeCodeUnspsc190501.30266407'),
	'identifierSchemeCodeUnspsc190501.30266408': __('identifierSchemeCodeUnspsc190501.30266408'),
	'identifierSchemeCodeUnspsc190501.30266410': __('identifierSchemeCodeUnspsc190501.30266410'),
	'identifierSchemeCodeUnspsc190501.30266500': __('identifierSchemeCodeUnspsc190501.30266500'),
	'identifierSchemeCodeUnspsc190501.30266501': __('identifierSchemeCodeUnspsc190501.30266501'),
	'identifierSchemeCodeUnspsc190501.30266502': __('identifierSchemeCodeUnspsc190501.30266502'),
	'identifierSchemeCodeUnspsc190501.30266503': __('identifierSchemeCodeUnspsc190501.30266503'),
	'identifierSchemeCodeUnspsc190501.30266504': __('identifierSchemeCodeUnspsc190501.30266504'),
	'identifierSchemeCodeUnspsc190501.30266600': __('identifierSchemeCodeUnspsc190501.30266600'),
	'identifierSchemeCodeUnspsc190501.30266601': __('identifierSchemeCodeUnspsc190501.30266601'),
	'identifierSchemeCodeUnspsc190501.30266602': __('identifierSchemeCodeUnspsc190501.30266602'),
	'identifierSchemeCodeUnspsc190501.30266700': __('identifierSchemeCodeUnspsc190501.30266700'),
	'identifierSchemeCodeUnspsc190501.30266701': __('identifierSchemeCodeUnspsc190501.30266701'),
	'identifierSchemeCodeUnspsc190501.30266702': __('identifierSchemeCodeUnspsc190501.30266702'),
	'identifierSchemeCodeUnspsc190501.31000000': __('identifierSchemeCodeUnspsc190501.31000000'),
	'identifierSchemeCodeUnspsc190501.31100000': __('identifierSchemeCodeUnspsc190501.31100000'),
	'identifierSchemeCodeUnspsc190501.31101600': __('identifierSchemeCodeUnspsc190501.31101600'),
	'identifierSchemeCodeUnspsc190501.31101601': __('identifierSchemeCodeUnspsc190501.31101601'),
	'identifierSchemeCodeUnspsc190501.31101602': __('identifierSchemeCodeUnspsc190501.31101602'),
	'identifierSchemeCodeUnspsc190501.31101603': __('identifierSchemeCodeUnspsc190501.31101603'),
	'identifierSchemeCodeUnspsc190501.31101604': __('identifierSchemeCodeUnspsc190501.31101604'),
	'identifierSchemeCodeUnspsc190501.31101606': __('identifierSchemeCodeUnspsc190501.31101606'),
	'identifierSchemeCodeUnspsc190501.31101607': __('identifierSchemeCodeUnspsc190501.31101607'),
	'identifierSchemeCodeUnspsc190501.31101608': __('identifierSchemeCodeUnspsc190501.31101608'),
	'identifierSchemeCodeUnspsc190501.31101609': __('identifierSchemeCodeUnspsc190501.31101609'),
	'identifierSchemeCodeUnspsc190501.31101610': __('identifierSchemeCodeUnspsc190501.31101610'),
	'identifierSchemeCodeUnspsc190501.31101611': __('identifierSchemeCodeUnspsc190501.31101611'),
	'identifierSchemeCodeUnspsc190501.31101612': __('identifierSchemeCodeUnspsc190501.31101612'),
	'identifierSchemeCodeUnspsc190501.31101613': __('identifierSchemeCodeUnspsc190501.31101613'),
	'identifierSchemeCodeUnspsc190501.31101614': __('identifierSchemeCodeUnspsc190501.31101614'),
	'identifierSchemeCodeUnspsc190501.31101615': __('identifierSchemeCodeUnspsc190501.31101615'),
	'identifierSchemeCodeUnspsc190501.31101616': __('identifierSchemeCodeUnspsc190501.31101616'),
	'identifierSchemeCodeUnspsc190501.31101617': __('identifierSchemeCodeUnspsc190501.31101617'),
	'identifierSchemeCodeUnspsc190501.31101618': __('identifierSchemeCodeUnspsc190501.31101618'),
	'identifierSchemeCodeUnspsc190501.31101619': __('identifierSchemeCodeUnspsc190501.31101619'),
	'identifierSchemeCodeUnspsc190501.31101700': __('identifierSchemeCodeUnspsc190501.31101700'),
	'identifierSchemeCodeUnspsc190501.31101701': __('identifierSchemeCodeUnspsc190501.31101701'),
	'identifierSchemeCodeUnspsc190501.31101702': __('identifierSchemeCodeUnspsc190501.31101702'),
	'identifierSchemeCodeUnspsc190501.31101703': __('identifierSchemeCodeUnspsc190501.31101703'),
	'identifierSchemeCodeUnspsc190501.31101704': __('identifierSchemeCodeUnspsc190501.31101704'),
	'identifierSchemeCodeUnspsc190501.31101705': __('identifierSchemeCodeUnspsc190501.31101705'),
	'identifierSchemeCodeUnspsc190501.31101706': __('identifierSchemeCodeUnspsc190501.31101706'),
	'identifierSchemeCodeUnspsc190501.31101707': __('identifierSchemeCodeUnspsc190501.31101707'),
	'identifierSchemeCodeUnspsc190501.31101708': __('identifierSchemeCodeUnspsc190501.31101708'),
	'identifierSchemeCodeUnspsc190501.31101709': __('identifierSchemeCodeUnspsc190501.31101709'),
	'identifierSchemeCodeUnspsc190501.31101710': __('identifierSchemeCodeUnspsc190501.31101710'),
	'identifierSchemeCodeUnspsc190501.31101711': __('identifierSchemeCodeUnspsc190501.31101711'),
	'identifierSchemeCodeUnspsc190501.31101712': __('identifierSchemeCodeUnspsc190501.31101712'),
	'identifierSchemeCodeUnspsc190501.31101713': __('identifierSchemeCodeUnspsc190501.31101713'),
	'identifierSchemeCodeUnspsc190501.31101714': __('identifierSchemeCodeUnspsc190501.31101714'),
	'identifierSchemeCodeUnspsc190501.31101715': __('identifierSchemeCodeUnspsc190501.31101715'),
	'identifierSchemeCodeUnspsc190501.31101716': __('identifierSchemeCodeUnspsc190501.31101716'),
	'identifierSchemeCodeUnspsc190501.31101800': __('identifierSchemeCodeUnspsc190501.31101800'),
	'identifierSchemeCodeUnspsc190501.31101801': __('identifierSchemeCodeUnspsc190501.31101801'),
	'identifierSchemeCodeUnspsc190501.31101802': __('identifierSchemeCodeUnspsc190501.31101802'),
	'identifierSchemeCodeUnspsc190501.31101803': __('identifierSchemeCodeUnspsc190501.31101803'),
	'identifierSchemeCodeUnspsc190501.31101804': __('identifierSchemeCodeUnspsc190501.31101804'),
	'identifierSchemeCodeUnspsc190501.31101806': __('identifierSchemeCodeUnspsc190501.31101806'),
	'identifierSchemeCodeUnspsc190501.31101807': __('identifierSchemeCodeUnspsc190501.31101807'),
	'identifierSchemeCodeUnspsc190501.31101808': __('identifierSchemeCodeUnspsc190501.31101808'),
	'identifierSchemeCodeUnspsc190501.31101809': __('identifierSchemeCodeUnspsc190501.31101809'),
	'identifierSchemeCodeUnspsc190501.31101810': __('identifierSchemeCodeUnspsc190501.31101810'),
	'identifierSchemeCodeUnspsc190501.31101811': __('identifierSchemeCodeUnspsc190501.31101811'),
	'identifierSchemeCodeUnspsc190501.31101812': __('identifierSchemeCodeUnspsc190501.31101812'),
	'identifierSchemeCodeUnspsc190501.31101813': __('identifierSchemeCodeUnspsc190501.31101813'),
	'identifierSchemeCodeUnspsc190501.31101814': __('identifierSchemeCodeUnspsc190501.31101814'),
	'identifierSchemeCodeUnspsc190501.31101815': __('identifierSchemeCodeUnspsc190501.31101815'),
	'identifierSchemeCodeUnspsc190501.31101816': __('identifierSchemeCodeUnspsc190501.31101816'),
	'identifierSchemeCodeUnspsc190501.31101817': __('identifierSchemeCodeUnspsc190501.31101817'),
	'identifierSchemeCodeUnspsc190501.31101900': __('identifierSchemeCodeUnspsc190501.31101900'),
	'identifierSchemeCodeUnspsc190501.31101901': __('identifierSchemeCodeUnspsc190501.31101901'),
	'identifierSchemeCodeUnspsc190501.31101902': __('identifierSchemeCodeUnspsc190501.31101902'),
	'identifierSchemeCodeUnspsc190501.31101903': __('identifierSchemeCodeUnspsc190501.31101903'),
	'identifierSchemeCodeUnspsc190501.31101904': __('identifierSchemeCodeUnspsc190501.31101904'),
	'identifierSchemeCodeUnspsc190501.31101905': __('identifierSchemeCodeUnspsc190501.31101905'),
	'identifierSchemeCodeUnspsc190501.31101906': __('identifierSchemeCodeUnspsc190501.31101906'),
	'identifierSchemeCodeUnspsc190501.31101907': __('identifierSchemeCodeUnspsc190501.31101907'),
	'identifierSchemeCodeUnspsc190501.31101908': __('identifierSchemeCodeUnspsc190501.31101908'),
	'identifierSchemeCodeUnspsc190501.31101909': __('identifierSchemeCodeUnspsc190501.31101909'),
	'identifierSchemeCodeUnspsc190501.31101910': __('identifierSchemeCodeUnspsc190501.31101910'),
	'identifierSchemeCodeUnspsc190501.31101911': __('identifierSchemeCodeUnspsc190501.31101911'),
	'identifierSchemeCodeUnspsc190501.31101912': __('identifierSchemeCodeUnspsc190501.31101912'),
	'identifierSchemeCodeUnspsc190501.31101914': __('identifierSchemeCodeUnspsc190501.31101914'),
	'identifierSchemeCodeUnspsc190501.31102000': __('identifierSchemeCodeUnspsc190501.31102000'),
	'identifierSchemeCodeUnspsc190501.31102001': __('identifierSchemeCodeUnspsc190501.31102001'),
	'identifierSchemeCodeUnspsc190501.31102002': __('identifierSchemeCodeUnspsc190501.31102002'),
	'identifierSchemeCodeUnspsc190501.31102003': __('identifierSchemeCodeUnspsc190501.31102003'),
	'identifierSchemeCodeUnspsc190501.31102004': __('identifierSchemeCodeUnspsc190501.31102004'),
	'identifierSchemeCodeUnspsc190501.31102005': __('identifierSchemeCodeUnspsc190501.31102005'),
	'identifierSchemeCodeUnspsc190501.31102006': __('identifierSchemeCodeUnspsc190501.31102006'),
	'identifierSchemeCodeUnspsc190501.31102007': __('identifierSchemeCodeUnspsc190501.31102007'),
	'identifierSchemeCodeUnspsc190501.31102008': __('identifierSchemeCodeUnspsc190501.31102008'),
	'identifierSchemeCodeUnspsc190501.31102009': __('identifierSchemeCodeUnspsc190501.31102009'),
	'identifierSchemeCodeUnspsc190501.31102010': __('identifierSchemeCodeUnspsc190501.31102010'),
	'identifierSchemeCodeUnspsc190501.31102011': __('identifierSchemeCodeUnspsc190501.31102011'),
	'identifierSchemeCodeUnspsc190501.31102012': __('identifierSchemeCodeUnspsc190501.31102012'),
	'identifierSchemeCodeUnspsc190501.31102013': __('identifierSchemeCodeUnspsc190501.31102013'),
	'identifierSchemeCodeUnspsc190501.31102014': __('identifierSchemeCodeUnspsc190501.31102014'),
	'identifierSchemeCodeUnspsc190501.31102015': __('identifierSchemeCodeUnspsc190501.31102015'),
	'identifierSchemeCodeUnspsc190501.31102016': __('identifierSchemeCodeUnspsc190501.31102016'),
	'identifierSchemeCodeUnspsc190501.31102100': __('identifierSchemeCodeUnspsc190501.31102100'),
	'identifierSchemeCodeUnspsc190501.31102101': __('identifierSchemeCodeUnspsc190501.31102101'),
	'identifierSchemeCodeUnspsc190501.31102102': __('identifierSchemeCodeUnspsc190501.31102102'),
	'identifierSchemeCodeUnspsc190501.31102103': __('identifierSchemeCodeUnspsc190501.31102103'),
	'identifierSchemeCodeUnspsc190501.31102104': __('identifierSchemeCodeUnspsc190501.31102104'),
	'identifierSchemeCodeUnspsc190501.31102105': __('identifierSchemeCodeUnspsc190501.31102105'),
	'identifierSchemeCodeUnspsc190501.31102106': __('identifierSchemeCodeUnspsc190501.31102106'),
	'identifierSchemeCodeUnspsc190501.31102107': __('identifierSchemeCodeUnspsc190501.31102107'),
	'identifierSchemeCodeUnspsc190501.31102108': __('identifierSchemeCodeUnspsc190501.31102108'),
	'identifierSchemeCodeUnspsc190501.31102109': __('identifierSchemeCodeUnspsc190501.31102109'),
	'identifierSchemeCodeUnspsc190501.31102110': __('identifierSchemeCodeUnspsc190501.31102110'),
	'identifierSchemeCodeUnspsc190501.31102111': __('identifierSchemeCodeUnspsc190501.31102111'),
	'identifierSchemeCodeUnspsc190501.31102112': __('identifierSchemeCodeUnspsc190501.31102112'),
	'identifierSchemeCodeUnspsc190501.31102113': __('identifierSchemeCodeUnspsc190501.31102113'),
	'identifierSchemeCodeUnspsc190501.31102114': __('identifierSchemeCodeUnspsc190501.31102114'),
	'identifierSchemeCodeUnspsc190501.31102115': __('identifierSchemeCodeUnspsc190501.31102115'),
	'identifierSchemeCodeUnspsc190501.31102116': __('identifierSchemeCodeUnspsc190501.31102116'),
	'identifierSchemeCodeUnspsc190501.31102200': __('identifierSchemeCodeUnspsc190501.31102200'),
	'identifierSchemeCodeUnspsc190501.31102201': __('identifierSchemeCodeUnspsc190501.31102201'),
	'identifierSchemeCodeUnspsc190501.31102202': __('identifierSchemeCodeUnspsc190501.31102202'),
	'identifierSchemeCodeUnspsc190501.31102203': __('identifierSchemeCodeUnspsc190501.31102203'),
	'identifierSchemeCodeUnspsc190501.31102204': __('identifierSchemeCodeUnspsc190501.31102204'),
	'identifierSchemeCodeUnspsc190501.31102205': __('identifierSchemeCodeUnspsc190501.31102205'),
	'identifierSchemeCodeUnspsc190501.31102206': __('identifierSchemeCodeUnspsc190501.31102206'),
	'identifierSchemeCodeUnspsc190501.31102207': __('identifierSchemeCodeUnspsc190501.31102207'),
	'identifierSchemeCodeUnspsc190501.31102208': __('identifierSchemeCodeUnspsc190501.31102208'),
	'identifierSchemeCodeUnspsc190501.31102209': __('identifierSchemeCodeUnspsc190501.31102209'),
	'identifierSchemeCodeUnspsc190501.31102210': __('identifierSchemeCodeUnspsc190501.31102210'),
	'identifierSchemeCodeUnspsc190501.31102211': __('identifierSchemeCodeUnspsc190501.31102211'),
	'identifierSchemeCodeUnspsc190501.31102212': __('identifierSchemeCodeUnspsc190501.31102212'),
	'identifierSchemeCodeUnspsc190501.31102213': __('identifierSchemeCodeUnspsc190501.31102213'),
	'identifierSchemeCodeUnspsc190501.31102214': __('identifierSchemeCodeUnspsc190501.31102214'),
	'identifierSchemeCodeUnspsc190501.31102215': __('identifierSchemeCodeUnspsc190501.31102215'),
	'identifierSchemeCodeUnspsc190501.31102216': __('identifierSchemeCodeUnspsc190501.31102216'),
	'identifierSchemeCodeUnspsc190501.31102300': __('identifierSchemeCodeUnspsc190501.31102300'),
	'identifierSchemeCodeUnspsc190501.31102301': __('identifierSchemeCodeUnspsc190501.31102301'),
	'identifierSchemeCodeUnspsc190501.31102302': __('identifierSchemeCodeUnspsc190501.31102302'),
	'identifierSchemeCodeUnspsc190501.31102303': __('identifierSchemeCodeUnspsc190501.31102303'),
	'identifierSchemeCodeUnspsc190501.31102304': __('identifierSchemeCodeUnspsc190501.31102304'),
	'identifierSchemeCodeUnspsc190501.31102305': __('identifierSchemeCodeUnspsc190501.31102305'),
	'identifierSchemeCodeUnspsc190501.31102306': __('identifierSchemeCodeUnspsc190501.31102306'),
	'identifierSchemeCodeUnspsc190501.31102307': __('identifierSchemeCodeUnspsc190501.31102307'),
	'identifierSchemeCodeUnspsc190501.31102308': __('identifierSchemeCodeUnspsc190501.31102308'),
	'identifierSchemeCodeUnspsc190501.31102309': __('identifierSchemeCodeUnspsc190501.31102309'),
	'identifierSchemeCodeUnspsc190501.31102310': __('identifierSchemeCodeUnspsc190501.31102310'),
	'identifierSchemeCodeUnspsc190501.31102311': __('identifierSchemeCodeUnspsc190501.31102311'),
	'identifierSchemeCodeUnspsc190501.31102312': __('identifierSchemeCodeUnspsc190501.31102312'),
	'identifierSchemeCodeUnspsc190501.31102313': __('identifierSchemeCodeUnspsc190501.31102313'),
	'identifierSchemeCodeUnspsc190501.31102314': __('identifierSchemeCodeUnspsc190501.31102314'),
	'identifierSchemeCodeUnspsc190501.31102315': __('identifierSchemeCodeUnspsc190501.31102315'),
	'identifierSchemeCodeUnspsc190501.31102316': __('identifierSchemeCodeUnspsc190501.31102316'),
	'identifierSchemeCodeUnspsc190501.31102400': __('identifierSchemeCodeUnspsc190501.31102400'),
	'identifierSchemeCodeUnspsc190501.31102401': __('identifierSchemeCodeUnspsc190501.31102401'),
	'identifierSchemeCodeUnspsc190501.31102402': __('identifierSchemeCodeUnspsc190501.31102402'),
	'identifierSchemeCodeUnspsc190501.31102403': __('identifierSchemeCodeUnspsc190501.31102403'),
	'identifierSchemeCodeUnspsc190501.31102404': __('identifierSchemeCodeUnspsc190501.31102404'),
	'identifierSchemeCodeUnspsc190501.31102405': __('identifierSchemeCodeUnspsc190501.31102405'),
	'identifierSchemeCodeUnspsc190501.31102406': __('identifierSchemeCodeUnspsc190501.31102406'),
	'identifierSchemeCodeUnspsc190501.31102407': __('identifierSchemeCodeUnspsc190501.31102407'),
	'identifierSchemeCodeUnspsc190501.31102408': __('identifierSchemeCodeUnspsc190501.31102408'),
	'identifierSchemeCodeUnspsc190501.31102409': __('identifierSchemeCodeUnspsc190501.31102409'),
	'identifierSchemeCodeUnspsc190501.31102410': __('identifierSchemeCodeUnspsc190501.31102410'),
	'identifierSchemeCodeUnspsc190501.31102411': __('identifierSchemeCodeUnspsc190501.31102411'),
	'identifierSchemeCodeUnspsc190501.31102412': __('identifierSchemeCodeUnspsc190501.31102412'),
	'identifierSchemeCodeUnspsc190501.31102413': __('identifierSchemeCodeUnspsc190501.31102413'),
	'identifierSchemeCodeUnspsc190501.31102414': __('identifierSchemeCodeUnspsc190501.31102414'),
	'identifierSchemeCodeUnspsc190501.31102415': __('identifierSchemeCodeUnspsc190501.31102415'),
	'identifierSchemeCodeUnspsc190501.31102416': __('identifierSchemeCodeUnspsc190501.31102416'),
	'identifierSchemeCodeUnspsc190501.31102500': __('identifierSchemeCodeUnspsc190501.31102500'),
	'identifierSchemeCodeUnspsc190501.31102501': __('identifierSchemeCodeUnspsc190501.31102501'),
	'identifierSchemeCodeUnspsc190501.31102600': __('identifierSchemeCodeUnspsc190501.31102600'),
	'identifierSchemeCodeUnspsc190501.31102601': __('identifierSchemeCodeUnspsc190501.31102601'),
	'identifierSchemeCodeUnspsc190501.31102602': __('identifierSchemeCodeUnspsc190501.31102602'),
	'identifierSchemeCodeUnspsc190501.31102603': __('identifierSchemeCodeUnspsc190501.31102603'),
	'identifierSchemeCodeUnspsc190501.31102700': __('identifierSchemeCodeUnspsc190501.31102700'),
	'identifierSchemeCodeUnspsc190501.31102701': __('identifierSchemeCodeUnspsc190501.31102701'),
	'identifierSchemeCodeUnspsc190501.31102800': __('identifierSchemeCodeUnspsc190501.31102800'),
	'identifierSchemeCodeUnspsc190501.31102801': __('identifierSchemeCodeUnspsc190501.31102801'),
	'identifierSchemeCodeUnspsc190501.31102900': __('identifierSchemeCodeUnspsc190501.31102900'),
	'identifierSchemeCodeUnspsc190501.31102901': __('identifierSchemeCodeUnspsc190501.31102901'),
	'identifierSchemeCodeUnspsc190501.31110000': __('identifierSchemeCodeUnspsc190501.31110000'),
	'identifierSchemeCodeUnspsc190501.31111500': __('identifierSchemeCodeUnspsc190501.31111500'),
	'identifierSchemeCodeUnspsc190501.31111501': __('identifierSchemeCodeUnspsc190501.31111501'),
	'identifierSchemeCodeUnspsc190501.31111502': __('identifierSchemeCodeUnspsc190501.31111502'),
	'identifierSchemeCodeUnspsc190501.31111503': __('identifierSchemeCodeUnspsc190501.31111503'),
	'identifierSchemeCodeUnspsc190501.31111504': __('identifierSchemeCodeUnspsc190501.31111504'),
	'identifierSchemeCodeUnspsc190501.31111505': __('identifierSchemeCodeUnspsc190501.31111505'),
	'identifierSchemeCodeUnspsc190501.31111506': __('identifierSchemeCodeUnspsc190501.31111506'),
	'identifierSchemeCodeUnspsc190501.31111507': __('identifierSchemeCodeUnspsc190501.31111507'),
	'identifierSchemeCodeUnspsc190501.31111508': __('identifierSchemeCodeUnspsc190501.31111508'),
	'identifierSchemeCodeUnspsc190501.31111509': __('identifierSchemeCodeUnspsc190501.31111509'),
	'identifierSchemeCodeUnspsc190501.31111510': __('identifierSchemeCodeUnspsc190501.31111510'),
	'identifierSchemeCodeUnspsc190501.31111511': __('identifierSchemeCodeUnspsc190501.31111511'),
	'identifierSchemeCodeUnspsc190501.31111512': __('identifierSchemeCodeUnspsc190501.31111512'),
	'identifierSchemeCodeUnspsc190501.31111513': __('identifierSchemeCodeUnspsc190501.31111513'),
	'identifierSchemeCodeUnspsc190501.31111514': __('identifierSchemeCodeUnspsc190501.31111514'),
	'identifierSchemeCodeUnspsc190501.31111515': __('identifierSchemeCodeUnspsc190501.31111515'),
	'identifierSchemeCodeUnspsc190501.31111516': __('identifierSchemeCodeUnspsc190501.31111516'),
	'identifierSchemeCodeUnspsc190501.31111517': __('identifierSchemeCodeUnspsc190501.31111517'),
	'identifierSchemeCodeUnspsc190501.31111600': __('identifierSchemeCodeUnspsc190501.31111600'),
	'identifierSchemeCodeUnspsc190501.31111601': __('identifierSchemeCodeUnspsc190501.31111601'),
	'identifierSchemeCodeUnspsc190501.31111602': __('identifierSchemeCodeUnspsc190501.31111602'),
	'identifierSchemeCodeUnspsc190501.31111603': __('identifierSchemeCodeUnspsc190501.31111603'),
	'identifierSchemeCodeUnspsc190501.31111604': __('identifierSchemeCodeUnspsc190501.31111604'),
	'identifierSchemeCodeUnspsc190501.31111605': __('identifierSchemeCodeUnspsc190501.31111605'),
	'identifierSchemeCodeUnspsc190501.31111606': __('identifierSchemeCodeUnspsc190501.31111606'),
	'identifierSchemeCodeUnspsc190501.31111607': __('identifierSchemeCodeUnspsc190501.31111607'),
	'identifierSchemeCodeUnspsc190501.31111608': __('identifierSchemeCodeUnspsc190501.31111608'),
	'identifierSchemeCodeUnspsc190501.31111609': __('identifierSchemeCodeUnspsc190501.31111609'),
	'identifierSchemeCodeUnspsc190501.31111610': __('identifierSchemeCodeUnspsc190501.31111610'),
	'identifierSchemeCodeUnspsc190501.31111611': __('identifierSchemeCodeUnspsc190501.31111611'),
	'identifierSchemeCodeUnspsc190501.31111612': __('identifierSchemeCodeUnspsc190501.31111612'),
	'identifierSchemeCodeUnspsc190501.31111613': __('identifierSchemeCodeUnspsc190501.31111613'),
	'identifierSchemeCodeUnspsc190501.31111614': __('identifierSchemeCodeUnspsc190501.31111614'),
	'identifierSchemeCodeUnspsc190501.31111615': __('identifierSchemeCodeUnspsc190501.31111615'),
	'identifierSchemeCodeUnspsc190501.31111616': __('identifierSchemeCodeUnspsc190501.31111616'),
	'identifierSchemeCodeUnspsc190501.31111617': __('identifierSchemeCodeUnspsc190501.31111617'),
	'identifierSchemeCodeUnspsc190501.31111700': __('identifierSchemeCodeUnspsc190501.31111700'),
	'identifierSchemeCodeUnspsc190501.31111701': __('identifierSchemeCodeUnspsc190501.31111701'),
	'identifierSchemeCodeUnspsc190501.31111702': __('identifierSchemeCodeUnspsc190501.31111702'),
	'identifierSchemeCodeUnspsc190501.31111703': __('identifierSchemeCodeUnspsc190501.31111703'),
	'identifierSchemeCodeUnspsc190501.31111704': __('identifierSchemeCodeUnspsc190501.31111704'),
	'identifierSchemeCodeUnspsc190501.31111705': __('identifierSchemeCodeUnspsc190501.31111705'),
	'identifierSchemeCodeUnspsc190501.31111706': __('identifierSchemeCodeUnspsc190501.31111706'),
	'identifierSchemeCodeUnspsc190501.31111707': __('identifierSchemeCodeUnspsc190501.31111707'),
	'identifierSchemeCodeUnspsc190501.31111708': __('identifierSchemeCodeUnspsc190501.31111708'),
	'identifierSchemeCodeUnspsc190501.31111709': __('identifierSchemeCodeUnspsc190501.31111709'),
	'identifierSchemeCodeUnspsc190501.31111710': __('identifierSchemeCodeUnspsc190501.31111710'),
	'identifierSchemeCodeUnspsc190501.31111711': __('identifierSchemeCodeUnspsc190501.31111711'),
	'identifierSchemeCodeUnspsc190501.31111712': __('identifierSchemeCodeUnspsc190501.31111712'),
	'identifierSchemeCodeUnspsc190501.31111713': __('identifierSchemeCodeUnspsc190501.31111713'),
	'identifierSchemeCodeUnspsc190501.31111714': __('identifierSchemeCodeUnspsc190501.31111714'),
	'identifierSchemeCodeUnspsc190501.31111715': __('identifierSchemeCodeUnspsc190501.31111715'),
	'identifierSchemeCodeUnspsc190501.31111716': __('identifierSchemeCodeUnspsc190501.31111716'),
	'identifierSchemeCodeUnspsc190501.31111717': __('identifierSchemeCodeUnspsc190501.31111717'),
	'identifierSchemeCodeUnspsc190501.31120000': __('identifierSchemeCodeUnspsc190501.31120000'),
	'identifierSchemeCodeUnspsc190501.31121000': __('identifierSchemeCodeUnspsc190501.31121000'),
	'identifierSchemeCodeUnspsc190501.31121001': __('identifierSchemeCodeUnspsc190501.31121001'),
	'identifierSchemeCodeUnspsc190501.31121002': __('identifierSchemeCodeUnspsc190501.31121002'),
	'identifierSchemeCodeUnspsc190501.31121003': __('identifierSchemeCodeUnspsc190501.31121003'),
	'identifierSchemeCodeUnspsc190501.31121004': __('identifierSchemeCodeUnspsc190501.31121004'),
	'identifierSchemeCodeUnspsc190501.31121005': __('identifierSchemeCodeUnspsc190501.31121005'),
	'identifierSchemeCodeUnspsc190501.31121006': __('identifierSchemeCodeUnspsc190501.31121006'),
	'identifierSchemeCodeUnspsc190501.31121007': __('identifierSchemeCodeUnspsc190501.31121007'),
	'identifierSchemeCodeUnspsc190501.31121008': __('identifierSchemeCodeUnspsc190501.31121008'),
	'identifierSchemeCodeUnspsc190501.31121009': __('identifierSchemeCodeUnspsc190501.31121009'),
	'identifierSchemeCodeUnspsc190501.31121010': __('identifierSchemeCodeUnspsc190501.31121010'),
	'identifierSchemeCodeUnspsc190501.31121011': __('identifierSchemeCodeUnspsc190501.31121011'),
	'identifierSchemeCodeUnspsc190501.31121012': __('identifierSchemeCodeUnspsc190501.31121012'),
	'identifierSchemeCodeUnspsc190501.31121013': __('identifierSchemeCodeUnspsc190501.31121013'),
	'identifierSchemeCodeUnspsc190501.31121014': __('identifierSchemeCodeUnspsc190501.31121014'),
	'identifierSchemeCodeUnspsc190501.31121015': __('identifierSchemeCodeUnspsc190501.31121015'),
	'identifierSchemeCodeUnspsc190501.31121016': __('identifierSchemeCodeUnspsc190501.31121016'),
	'identifierSchemeCodeUnspsc190501.31121017': __('identifierSchemeCodeUnspsc190501.31121017'),
	'identifierSchemeCodeUnspsc190501.31121018': __('identifierSchemeCodeUnspsc190501.31121018'),
	'identifierSchemeCodeUnspsc190501.31121019': __('identifierSchemeCodeUnspsc190501.31121019'),
	'identifierSchemeCodeUnspsc190501.31121100': __('identifierSchemeCodeUnspsc190501.31121100'),
	'identifierSchemeCodeUnspsc190501.31121101': __('identifierSchemeCodeUnspsc190501.31121101'),
	'identifierSchemeCodeUnspsc190501.31121102': __('identifierSchemeCodeUnspsc190501.31121102'),
	'identifierSchemeCodeUnspsc190501.31121103': __('identifierSchemeCodeUnspsc190501.31121103'),
	'identifierSchemeCodeUnspsc190501.31121104': __('identifierSchemeCodeUnspsc190501.31121104'),
	'identifierSchemeCodeUnspsc190501.31121105': __('identifierSchemeCodeUnspsc190501.31121105'),
	'identifierSchemeCodeUnspsc190501.31121106': __('identifierSchemeCodeUnspsc190501.31121106'),
	'identifierSchemeCodeUnspsc190501.31121107': __('identifierSchemeCodeUnspsc190501.31121107'),
	'identifierSchemeCodeUnspsc190501.31121108': __('identifierSchemeCodeUnspsc190501.31121108'),
	'identifierSchemeCodeUnspsc190501.31121109': __('identifierSchemeCodeUnspsc190501.31121109'),
	'identifierSchemeCodeUnspsc190501.31121110': __('identifierSchemeCodeUnspsc190501.31121110'),
	'identifierSchemeCodeUnspsc190501.31121111': __('identifierSchemeCodeUnspsc190501.31121111'),
	'identifierSchemeCodeUnspsc190501.31121112': __('identifierSchemeCodeUnspsc190501.31121112'),
	'identifierSchemeCodeUnspsc190501.31121113': __('identifierSchemeCodeUnspsc190501.31121113'),
	'identifierSchemeCodeUnspsc190501.31121114': __('identifierSchemeCodeUnspsc190501.31121114'),
	'identifierSchemeCodeUnspsc190501.31121115': __('identifierSchemeCodeUnspsc190501.31121115'),
	'identifierSchemeCodeUnspsc190501.31121116': __('identifierSchemeCodeUnspsc190501.31121116'),
	'identifierSchemeCodeUnspsc190501.31121117': __('identifierSchemeCodeUnspsc190501.31121117'),
	'identifierSchemeCodeUnspsc190501.31121118': __('identifierSchemeCodeUnspsc190501.31121118'),
	'identifierSchemeCodeUnspsc190501.31121119': __('identifierSchemeCodeUnspsc190501.31121119'),
	'identifierSchemeCodeUnspsc190501.31121120': __('identifierSchemeCodeUnspsc190501.31121120'),
	'identifierSchemeCodeUnspsc190501.31121121': __('identifierSchemeCodeUnspsc190501.31121121'),
	'identifierSchemeCodeUnspsc190501.31121122': __('identifierSchemeCodeUnspsc190501.31121122'),
	'identifierSchemeCodeUnspsc190501.31121123': __('identifierSchemeCodeUnspsc190501.31121123'),
	'identifierSchemeCodeUnspsc190501.31121124': __('identifierSchemeCodeUnspsc190501.31121124'),
	'identifierSchemeCodeUnspsc190501.31121125': __('identifierSchemeCodeUnspsc190501.31121125'),
	'identifierSchemeCodeUnspsc190501.31121200': __('identifierSchemeCodeUnspsc190501.31121200'),
	'identifierSchemeCodeUnspsc190501.31121201': __('identifierSchemeCodeUnspsc190501.31121201'),
	'identifierSchemeCodeUnspsc190501.31121202': __('identifierSchemeCodeUnspsc190501.31121202'),
	'identifierSchemeCodeUnspsc190501.31121203': __('identifierSchemeCodeUnspsc190501.31121203'),
	'identifierSchemeCodeUnspsc190501.31121204': __('identifierSchemeCodeUnspsc190501.31121204'),
	'identifierSchemeCodeUnspsc190501.31121205': __('identifierSchemeCodeUnspsc190501.31121205'),
	'identifierSchemeCodeUnspsc190501.31121206': __('identifierSchemeCodeUnspsc190501.31121206'),
	'identifierSchemeCodeUnspsc190501.31121207': __('identifierSchemeCodeUnspsc190501.31121207'),
	'identifierSchemeCodeUnspsc190501.31121208': __('identifierSchemeCodeUnspsc190501.31121208'),
	'identifierSchemeCodeUnspsc190501.31121209': __('identifierSchemeCodeUnspsc190501.31121209'),
	'identifierSchemeCodeUnspsc190501.31121210': __('identifierSchemeCodeUnspsc190501.31121210'),
	'identifierSchemeCodeUnspsc190501.31121211': __('identifierSchemeCodeUnspsc190501.31121211'),
	'identifierSchemeCodeUnspsc190501.31121212': __('identifierSchemeCodeUnspsc190501.31121212'),
	'identifierSchemeCodeUnspsc190501.31121213': __('identifierSchemeCodeUnspsc190501.31121213'),
	'identifierSchemeCodeUnspsc190501.31121214': __('identifierSchemeCodeUnspsc190501.31121214'),
	'identifierSchemeCodeUnspsc190501.31121215': __('identifierSchemeCodeUnspsc190501.31121215'),
	'identifierSchemeCodeUnspsc190501.31121216': __('identifierSchemeCodeUnspsc190501.31121216'),
	'identifierSchemeCodeUnspsc190501.31121217': __('identifierSchemeCodeUnspsc190501.31121217'),
	'identifierSchemeCodeUnspsc190501.31121218': __('identifierSchemeCodeUnspsc190501.31121218'),
	'identifierSchemeCodeUnspsc190501.31121219': __('identifierSchemeCodeUnspsc190501.31121219'),
	'identifierSchemeCodeUnspsc190501.31121220': __('identifierSchemeCodeUnspsc190501.31121220'),
	'identifierSchemeCodeUnspsc190501.31121221': __('identifierSchemeCodeUnspsc190501.31121221'),
	'identifierSchemeCodeUnspsc190501.31121222': __('identifierSchemeCodeUnspsc190501.31121222'),
	'identifierSchemeCodeUnspsc190501.31121223': __('identifierSchemeCodeUnspsc190501.31121223'),
	'identifierSchemeCodeUnspsc190501.31121224': __('identifierSchemeCodeUnspsc190501.31121224'),
	'identifierSchemeCodeUnspsc190501.31121225': __('identifierSchemeCodeUnspsc190501.31121225'),
	'identifierSchemeCodeUnspsc190501.31121226': __('identifierSchemeCodeUnspsc190501.31121226'),
	'identifierSchemeCodeUnspsc190501.31121227': __('identifierSchemeCodeUnspsc190501.31121227'),
	'identifierSchemeCodeUnspsc190501.31121300': __('identifierSchemeCodeUnspsc190501.31121300'),
	'identifierSchemeCodeUnspsc190501.31121301': __('identifierSchemeCodeUnspsc190501.31121301'),
	'identifierSchemeCodeUnspsc190501.31121302': __('identifierSchemeCodeUnspsc190501.31121302'),
	'identifierSchemeCodeUnspsc190501.31121303': __('identifierSchemeCodeUnspsc190501.31121303'),
	'identifierSchemeCodeUnspsc190501.31121304': __('identifierSchemeCodeUnspsc190501.31121304'),
	'identifierSchemeCodeUnspsc190501.31121305': __('identifierSchemeCodeUnspsc190501.31121305'),
	'identifierSchemeCodeUnspsc190501.31121306': __('identifierSchemeCodeUnspsc190501.31121306'),
	'identifierSchemeCodeUnspsc190501.31121307': __('identifierSchemeCodeUnspsc190501.31121307'),
	'identifierSchemeCodeUnspsc190501.31121308': __('identifierSchemeCodeUnspsc190501.31121308'),
	'identifierSchemeCodeUnspsc190501.31121309': __('identifierSchemeCodeUnspsc190501.31121309'),
	'identifierSchemeCodeUnspsc190501.31121310': __('identifierSchemeCodeUnspsc190501.31121310'),
	'identifierSchemeCodeUnspsc190501.31121311': __('identifierSchemeCodeUnspsc190501.31121311'),
	'identifierSchemeCodeUnspsc190501.31121312': __('identifierSchemeCodeUnspsc190501.31121312'),
	'identifierSchemeCodeUnspsc190501.31121313': __('identifierSchemeCodeUnspsc190501.31121313'),
	'identifierSchemeCodeUnspsc190501.31121314': __('identifierSchemeCodeUnspsc190501.31121314'),
	'identifierSchemeCodeUnspsc190501.31121315': __('identifierSchemeCodeUnspsc190501.31121315'),
	'identifierSchemeCodeUnspsc190501.31121316': __('identifierSchemeCodeUnspsc190501.31121316'),
	'identifierSchemeCodeUnspsc190501.31121317': __('identifierSchemeCodeUnspsc190501.31121317'),
	'identifierSchemeCodeUnspsc190501.31121318': __('identifierSchemeCodeUnspsc190501.31121318'),
	'identifierSchemeCodeUnspsc190501.31121319': __('identifierSchemeCodeUnspsc190501.31121319'),
	'identifierSchemeCodeUnspsc190501.31121320': __('identifierSchemeCodeUnspsc190501.31121320'),
	'identifierSchemeCodeUnspsc190501.31121321': __('identifierSchemeCodeUnspsc190501.31121321'),
	'identifierSchemeCodeUnspsc190501.31121400': __('identifierSchemeCodeUnspsc190501.31121400'),
	'identifierSchemeCodeUnspsc190501.31121401': __('identifierSchemeCodeUnspsc190501.31121401'),
	'identifierSchemeCodeUnspsc190501.31121402': __('identifierSchemeCodeUnspsc190501.31121402'),
	'identifierSchemeCodeUnspsc190501.31121403': __('identifierSchemeCodeUnspsc190501.31121403'),
	'identifierSchemeCodeUnspsc190501.31121404': __('identifierSchemeCodeUnspsc190501.31121404'),
	'identifierSchemeCodeUnspsc190501.31121405': __('identifierSchemeCodeUnspsc190501.31121405'),
	'identifierSchemeCodeUnspsc190501.31121406': __('identifierSchemeCodeUnspsc190501.31121406'),
	'identifierSchemeCodeUnspsc190501.31121407': __('identifierSchemeCodeUnspsc190501.31121407'),
	'identifierSchemeCodeUnspsc190501.31121408': __('identifierSchemeCodeUnspsc190501.31121408'),
	'identifierSchemeCodeUnspsc190501.31121409': __('identifierSchemeCodeUnspsc190501.31121409'),
	'identifierSchemeCodeUnspsc190501.31121410': __('identifierSchemeCodeUnspsc190501.31121410'),
	'identifierSchemeCodeUnspsc190501.31121411': __('identifierSchemeCodeUnspsc190501.31121411'),
	'identifierSchemeCodeUnspsc190501.31121412': __('identifierSchemeCodeUnspsc190501.31121412'),
	'identifierSchemeCodeUnspsc190501.31121413': __('identifierSchemeCodeUnspsc190501.31121413'),
	'identifierSchemeCodeUnspsc190501.31121414': __('identifierSchemeCodeUnspsc190501.31121414'),
	'identifierSchemeCodeUnspsc190501.31121415': __('identifierSchemeCodeUnspsc190501.31121415'),
	'identifierSchemeCodeUnspsc190501.31121416': __('identifierSchemeCodeUnspsc190501.31121416'),
	'identifierSchemeCodeUnspsc190501.31121417': __('identifierSchemeCodeUnspsc190501.31121417'),
	'identifierSchemeCodeUnspsc190501.31121418': __('identifierSchemeCodeUnspsc190501.31121418'),
	'identifierSchemeCodeUnspsc190501.31121419': __('identifierSchemeCodeUnspsc190501.31121419'),
	'identifierSchemeCodeUnspsc190501.31121500': __('identifierSchemeCodeUnspsc190501.31121500'),
	'identifierSchemeCodeUnspsc190501.31121501': __('identifierSchemeCodeUnspsc190501.31121501'),
	'identifierSchemeCodeUnspsc190501.31121502': __('identifierSchemeCodeUnspsc190501.31121502'),
	'identifierSchemeCodeUnspsc190501.31121503': __('identifierSchemeCodeUnspsc190501.31121503'),
	'identifierSchemeCodeUnspsc190501.31121504': __('identifierSchemeCodeUnspsc190501.31121504'),
	'identifierSchemeCodeUnspsc190501.31121505': __('identifierSchemeCodeUnspsc190501.31121505'),
	'identifierSchemeCodeUnspsc190501.31121506': __('identifierSchemeCodeUnspsc190501.31121506'),
	'identifierSchemeCodeUnspsc190501.31121507': __('identifierSchemeCodeUnspsc190501.31121507'),
	'identifierSchemeCodeUnspsc190501.31121508': __('identifierSchemeCodeUnspsc190501.31121508'),
	'identifierSchemeCodeUnspsc190501.31121509': __('identifierSchemeCodeUnspsc190501.31121509'),
	'identifierSchemeCodeUnspsc190501.31121510': __('identifierSchemeCodeUnspsc190501.31121510'),
	'identifierSchemeCodeUnspsc190501.31121511': __('identifierSchemeCodeUnspsc190501.31121511'),
	'identifierSchemeCodeUnspsc190501.31121512': __('identifierSchemeCodeUnspsc190501.31121512'),
	'identifierSchemeCodeUnspsc190501.31121513': __('identifierSchemeCodeUnspsc190501.31121513'),
	'identifierSchemeCodeUnspsc190501.31121514': __('identifierSchemeCodeUnspsc190501.31121514'),
	'identifierSchemeCodeUnspsc190501.31121515': __('identifierSchemeCodeUnspsc190501.31121515'),
	'identifierSchemeCodeUnspsc190501.31121516': __('identifierSchemeCodeUnspsc190501.31121516'),
	'identifierSchemeCodeUnspsc190501.31121517': __('identifierSchemeCodeUnspsc190501.31121517'),
	'identifierSchemeCodeUnspsc190501.31121518': __('identifierSchemeCodeUnspsc190501.31121518'),
	'identifierSchemeCodeUnspsc190501.31121519': __('identifierSchemeCodeUnspsc190501.31121519'),
	'identifierSchemeCodeUnspsc190501.31121520': __('identifierSchemeCodeUnspsc190501.31121520'),
	'identifierSchemeCodeUnspsc190501.31121521': __('identifierSchemeCodeUnspsc190501.31121521'),
	'identifierSchemeCodeUnspsc190501.31121600': __('identifierSchemeCodeUnspsc190501.31121600'),
	'identifierSchemeCodeUnspsc190501.31121601': __('identifierSchemeCodeUnspsc190501.31121601'),
	'identifierSchemeCodeUnspsc190501.31121602': __('identifierSchemeCodeUnspsc190501.31121602'),
	'identifierSchemeCodeUnspsc190501.31121603': __('identifierSchemeCodeUnspsc190501.31121603'),
	'identifierSchemeCodeUnspsc190501.31121604': __('identifierSchemeCodeUnspsc190501.31121604'),
	'identifierSchemeCodeUnspsc190501.31121605': __('identifierSchemeCodeUnspsc190501.31121605'),
	'identifierSchemeCodeUnspsc190501.31121606': __('identifierSchemeCodeUnspsc190501.31121606'),
	'identifierSchemeCodeUnspsc190501.31121607': __('identifierSchemeCodeUnspsc190501.31121607'),
	'identifierSchemeCodeUnspsc190501.31121608': __('identifierSchemeCodeUnspsc190501.31121608'),
	'identifierSchemeCodeUnspsc190501.31121609': __('identifierSchemeCodeUnspsc190501.31121609'),
	'identifierSchemeCodeUnspsc190501.31121610': __('identifierSchemeCodeUnspsc190501.31121610'),
	'identifierSchemeCodeUnspsc190501.31121611': __('identifierSchemeCodeUnspsc190501.31121611'),
	'identifierSchemeCodeUnspsc190501.31121612': __('identifierSchemeCodeUnspsc190501.31121612'),
	'identifierSchemeCodeUnspsc190501.31121613': __('identifierSchemeCodeUnspsc190501.31121613'),
	'identifierSchemeCodeUnspsc190501.31121614': __('identifierSchemeCodeUnspsc190501.31121614'),
	'identifierSchemeCodeUnspsc190501.31121615': __('identifierSchemeCodeUnspsc190501.31121615'),
	'identifierSchemeCodeUnspsc190501.31121616': __('identifierSchemeCodeUnspsc190501.31121616'),
	'identifierSchemeCodeUnspsc190501.31121617': __('identifierSchemeCodeUnspsc190501.31121617'),
	'identifierSchemeCodeUnspsc190501.31121618': __('identifierSchemeCodeUnspsc190501.31121618'),
	'identifierSchemeCodeUnspsc190501.31121700': __('identifierSchemeCodeUnspsc190501.31121700'),
	'identifierSchemeCodeUnspsc190501.31121701': __('identifierSchemeCodeUnspsc190501.31121701'),
	'identifierSchemeCodeUnspsc190501.31121702': __('identifierSchemeCodeUnspsc190501.31121702'),
	'identifierSchemeCodeUnspsc190501.31121703': __('identifierSchemeCodeUnspsc190501.31121703'),
	'identifierSchemeCodeUnspsc190501.31121704': __('identifierSchemeCodeUnspsc190501.31121704'),
	'identifierSchemeCodeUnspsc190501.31121705': __('identifierSchemeCodeUnspsc190501.31121705'),
	'identifierSchemeCodeUnspsc190501.31121706': __('identifierSchemeCodeUnspsc190501.31121706'),
	'identifierSchemeCodeUnspsc190501.31121707': __('identifierSchemeCodeUnspsc190501.31121707'),
	'identifierSchemeCodeUnspsc190501.31121708': __('identifierSchemeCodeUnspsc190501.31121708'),
	'identifierSchemeCodeUnspsc190501.31121709': __('identifierSchemeCodeUnspsc190501.31121709'),
	'identifierSchemeCodeUnspsc190501.31121710': __('identifierSchemeCodeUnspsc190501.31121710'),
	'identifierSchemeCodeUnspsc190501.31121711': __('identifierSchemeCodeUnspsc190501.31121711'),
	'identifierSchemeCodeUnspsc190501.31121712': __('identifierSchemeCodeUnspsc190501.31121712'),
	'identifierSchemeCodeUnspsc190501.31121713': __('identifierSchemeCodeUnspsc190501.31121713'),
	'identifierSchemeCodeUnspsc190501.31121714': __('identifierSchemeCodeUnspsc190501.31121714'),
	'identifierSchemeCodeUnspsc190501.31121715': __('identifierSchemeCodeUnspsc190501.31121715'),
	'identifierSchemeCodeUnspsc190501.31121716': __('identifierSchemeCodeUnspsc190501.31121716'),
	'identifierSchemeCodeUnspsc190501.31121717': __('identifierSchemeCodeUnspsc190501.31121717'),
	'identifierSchemeCodeUnspsc190501.31121718': __('identifierSchemeCodeUnspsc190501.31121718'),
	'identifierSchemeCodeUnspsc190501.31121719': __('identifierSchemeCodeUnspsc190501.31121719'),
	'identifierSchemeCodeUnspsc190501.31121800': __('identifierSchemeCodeUnspsc190501.31121800'),
	'identifierSchemeCodeUnspsc190501.31121801': __('identifierSchemeCodeUnspsc190501.31121801'),
	'identifierSchemeCodeUnspsc190501.31121802': __('identifierSchemeCodeUnspsc190501.31121802'),
	'identifierSchemeCodeUnspsc190501.31121803': __('identifierSchemeCodeUnspsc190501.31121803'),
	'identifierSchemeCodeUnspsc190501.31121804': __('identifierSchemeCodeUnspsc190501.31121804'),
	'identifierSchemeCodeUnspsc190501.31121805': __('identifierSchemeCodeUnspsc190501.31121805'),
	'identifierSchemeCodeUnspsc190501.31121806': __('identifierSchemeCodeUnspsc190501.31121806'),
	'identifierSchemeCodeUnspsc190501.31121807': __('identifierSchemeCodeUnspsc190501.31121807'),
	'identifierSchemeCodeUnspsc190501.31121808': __('identifierSchemeCodeUnspsc190501.31121808'),
	'identifierSchemeCodeUnspsc190501.31121809': __('identifierSchemeCodeUnspsc190501.31121809'),
	'identifierSchemeCodeUnspsc190501.31121810': __('identifierSchemeCodeUnspsc190501.31121810'),
	'identifierSchemeCodeUnspsc190501.31121811': __('identifierSchemeCodeUnspsc190501.31121811'),
	'identifierSchemeCodeUnspsc190501.31121812': __('identifierSchemeCodeUnspsc190501.31121812'),
	'identifierSchemeCodeUnspsc190501.31121813': __('identifierSchemeCodeUnspsc190501.31121813'),
	'identifierSchemeCodeUnspsc190501.31121814': __('identifierSchemeCodeUnspsc190501.31121814'),
	'identifierSchemeCodeUnspsc190501.31121815': __('identifierSchemeCodeUnspsc190501.31121815'),
	'identifierSchemeCodeUnspsc190501.31121816': __('identifierSchemeCodeUnspsc190501.31121816'),
	'identifierSchemeCodeUnspsc190501.31121817': __('identifierSchemeCodeUnspsc190501.31121817'),
	'identifierSchemeCodeUnspsc190501.31121818': __('identifierSchemeCodeUnspsc190501.31121818'),
	'identifierSchemeCodeUnspsc190501.31121819': __('identifierSchemeCodeUnspsc190501.31121819'),
	'identifierSchemeCodeUnspsc190501.31121900': __('identifierSchemeCodeUnspsc190501.31121900'),
	'identifierSchemeCodeUnspsc190501.31121901': __('identifierSchemeCodeUnspsc190501.31121901'),
	'identifierSchemeCodeUnspsc190501.31121902': __('identifierSchemeCodeUnspsc190501.31121902'),
	'identifierSchemeCodeUnspsc190501.31121903': __('identifierSchemeCodeUnspsc190501.31121903'),
	'identifierSchemeCodeUnspsc190501.31121904': __('identifierSchemeCodeUnspsc190501.31121904'),
	'identifierSchemeCodeUnspsc190501.31121905': __('identifierSchemeCodeUnspsc190501.31121905'),
	'identifierSchemeCodeUnspsc190501.31121906': __('identifierSchemeCodeUnspsc190501.31121906'),
	'identifierSchemeCodeUnspsc190501.31121907': __('identifierSchemeCodeUnspsc190501.31121907'),
	'identifierSchemeCodeUnspsc190501.31121908': __('identifierSchemeCodeUnspsc190501.31121908'),
	'identifierSchemeCodeUnspsc190501.31121909': __('identifierSchemeCodeUnspsc190501.31121909'),
	'identifierSchemeCodeUnspsc190501.31121910': __('identifierSchemeCodeUnspsc190501.31121910'),
	'identifierSchemeCodeUnspsc190501.31121911': __('identifierSchemeCodeUnspsc190501.31121911'),
	'identifierSchemeCodeUnspsc190501.31121912': __('identifierSchemeCodeUnspsc190501.31121912'),
	'identifierSchemeCodeUnspsc190501.31121913': __('identifierSchemeCodeUnspsc190501.31121913'),
	'identifierSchemeCodeUnspsc190501.31121914': __('identifierSchemeCodeUnspsc190501.31121914'),
	'identifierSchemeCodeUnspsc190501.31121915': __('identifierSchemeCodeUnspsc190501.31121915'),
	'identifierSchemeCodeUnspsc190501.31121916': __('identifierSchemeCodeUnspsc190501.31121916'),
	'identifierSchemeCodeUnspsc190501.31121917': __('identifierSchemeCodeUnspsc190501.31121917'),
	'identifierSchemeCodeUnspsc190501.31121918': __('identifierSchemeCodeUnspsc190501.31121918'),
	'identifierSchemeCodeUnspsc190501.31121919': __('identifierSchemeCodeUnspsc190501.31121919'),
	'identifierSchemeCodeUnspsc190501.31122000': __('identifierSchemeCodeUnspsc190501.31122000'),
	'identifierSchemeCodeUnspsc190501.31122001': __('identifierSchemeCodeUnspsc190501.31122001'),
	'identifierSchemeCodeUnspsc190501.31122002': __('identifierSchemeCodeUnspsc190501.31122002'),
	'identifierSchemeCodeUnspsc190501.31122100': __('identifierSchemeCodeUnspsc190501.31122100'),
	'identifierSchemeCodeUnspsc190501.31122101': __('identifierSchemeCodeUnspsc190501.31122101'),
	'identifierSchemeCodeUnspsc190501.31122102': __('identifierSchemeCodeUnspsc190501.31122102'),
	'identifierSchemeCodeUnspsc190501.31122200': __('identifierSchemeCodeUnspsc190501.31122200'),
	'identifierSchemeCodeUnspsc190501.31122201': __('identifierSchemeCodeUnspsc190501.31122201'),
	'identifierSchemeCodeUnspsc190501.31122202': __('identifierSchemeCodeUnspsc190501.31122202'),
	'identifierSchemeCodeUnspsc190501.31130000': __('identifierSchemeCodeUnspsc190501.31130000'),
	'identifierSchemeCodeUnspsc190501.31132000': __('identifierSchemeCodeUnspsc190501.31132000'),
	'identifierSchemeCodeUnspsc190501.31132001': __('identifierSchemeCodeUnspsc190501.31132001'),
	'identifierSchemeCodeUnspsc190501.31132002': __('identifierSchemeCodeUnspsc190501.31132002'),
	'identifierSchemeCodeUnspsc190501.31132100': __('identifierSchemeCodeUnspsc190501.31132100'),
	'identifierSchemeCodeUnspsc190501.31132101': __('identifierSchemeCodeUnspsc190501.31132101'),
	'identifierSchemeCodeUnspsc190501.31132102': __('identifierSchemeCodeUnspsc190501.31132102'),
	'identifierSchemeCodeUnspsc190501.31132103': __('identifierSchemeCodeUnspsc190501.31132103'),
	'identifierSchemeCodeUnspsc190501.31132104': __('identifierSchemeCodeUnspsc190501.31132104'),
	'identifierSchemeCodeUnspsc190501.31132105': __('identifierSchemeCodeUnspsc190501.31132105'),
	'identifierSchemeCodeUnspsc190501.31132106': __('identifierSchemeCodeUnspsc190501.31132106'),
	'identifierSchemeCodeUnspsc190501.31132107': __('identifierSchemeCodeUnspsc190501.31132107'),
	'identifierSchemeCodeUnspsc190501.31132108': __('identifierSchemeCodeUnspsc190501.31132108'),
	'identifierSchemeCodeUnspsc190501.31132109': __('identifierSchemeCodeUnspsc190501.31132109'),
	'identifierSchemeCodeUnspsc190501.31132110': __('identifierSchemeCodeUnspsc190501.31132110'),
	'identifierSchemeCodeUnspsc190501.31132111': __('identifierSchemeCodeUnspsc190501.31132111'),
	'identifierSchemeCodeUnspsc190501.31132112': __('identifierSchemeCodeUnspsc190501.31132112'),
	'identifierSchemeCodeUnspsc190501.31132200': __('identifierSchemeCodeUnspsc190501.31132200'),
	'identifierSchemeCodeUnspsc190501.31132201': __('identifierSchemeCodeUnspsc190501.31132201'),
	'identifierSchemeCodeUnspsc190501.31132202': __('identifierSchemeCodeUnspsc190501.31132202'),
	'identifierSchemeCodeUnspsc190501.31132203': __('identifierSchemeCodeUnspsc190501.31132203'),
	'identifierSchemeCodeUnspsc190501.31132204': __('identifierSchemeCodeUnspsc190501.31132204'),
	'identifierSchemeCodeUnspsc190501.31132205': __('identifierSchemeCodeUnspsc190501.31132205'),
	'identifierSchemeCodeUnspsc190501.31132206': __('identifierSchemeCodeUnspsc190501.31132206'),
	'identifierSchemeCodeUnspsc190501.31132207': __('identifierSchemeCodeUnspsc190501.31132207'),
	'identifierSchemeCodeUnspsc190501.31132208': __('identifierSchemeCodeUnspsc190501.31132208'),
	'identifierSchemeCodeUnspsc190501.31132300': __('identifierSchemeCodeUnspsc190501.31132300'),
	'identifierSchemeCodeUnspsc190501.31132301': __('identifierSchemeCodeUnspsc190501.31132301'),
	'identifierSchemeCodeUnspsc190501.31132302': __('identifierSchemeCodeUnspsc190501.31132302'),
	'identifierSchemeCodeUnspsc190501.31132303': __('identifierSchemeCodeUnspsc190501.31132303'),
	'identifierSchemeCodeUnspsc190501.31132304': __('identifierSchemeCodeUnspsc190501.31132304'),
	'identifierSchemeCodeUnspsc190501.31132305': __('identifierSchemeCodeUnspsc190501.31132305'),
	'identifierSchemeCodeUnspsc190501.31132306': __('identifierSchemeCodeUnspsc190501.31132306'),
	'identifierSchemeCodeUnspsc190501.31132307': __('identifierSchemeCodeUnspsc190501.31132307'),
	'identifierSchemeCodeUnspsc190501.31132308': __('identifierSchemeCodeUnspsc190501.31132308'),
	'identifierSchemeCodeUnspsc190501.31132400': __('identifierSchemeCodeUnspsc190501.31132400'),
	'identifierSchemeCodeUnspsc190501.31132401': __('identifierSchemeCodeUnspsc190501.31132401'),
	'identifierSchemeCodeUnspsc190501.31132402': __('identifierSchemeCodeUnspsc190501.31132402'),
	'identifierSchemeCodeUnspsc190501.31132403': __('identifierSchemeCodeUnspsc190501.31132403'),
	'identifierSchemeCodeUnspsc190501.31132404': __('identifierSchemeCodeUnspsc190501.31132404'),
	'identifierSchemeCodeUnspsc190501.31132405': __('identifierSchemeCodeUnspsc190501.31132405'),
	'identifierSchemeCodeUnspsc190501.31132406': __('identifierSchemeCodeUnspsc190501.31132406'),
	'identifierSchemeCodeUnspsc190501.31132407': __('identifierSchemeCodeUnspsc190501.31132407'),
	'identifierSchemeCodeUnspsc190501.31132408': __('identifierSchemeCodeUnspsc190501.31132408'),
	'identifierSchemeCodeUnspsc190501.31132409': __('identifierSchemeCodeUnspsc190501.31132409'),
	'identifierSchemeCodeUnspsc190501.31132410': __('identifierSchemeCodeUnspsc190501.31132410'),
	'identifierSchemeCodeUnspsc190501.31132411': __('identifierSchemeCodeUnspsc190501.31132411'),
	'identifierSchemeCodeUnspsc190501.31132412': __('identifierSchemeCodeUnspsc190501.31132412'),
	'identifierSchemeCodeUnspsc190501.31132500': __('identifierSchemeCodeUnspsc190501.31132500'),
	'identifierSchemeCodeUnspsc190501.31132501': __('identifierSchemeCodeUnspsc190501.31132501'),
	'identifierSchemeCodeUnspsc190501.31132502': __('identifierSchemeCodeUnspsc190501.31132502'),
	'identifierSchemeCodeUnspsc190501.31132503': __('identifierSchemeCodeUnspsc190501.31132503'),
	'identifierSchemeCodeUnspsc190501.31132504': __('identifierSchemeCodeUnspsc190501.31132504'),
	'identifierSchemeCodeUnspsc190501.31132505': __('identifierSchemeCodeUnspsc190501.31132505'),
	'identifierSchemeCodeUnspsc190501.31132506': __('identifierSchemeCodeUnspsc190501.31132506'),
	'identifierSchemeCodeUnspsc190501.31132507': __('identifierSchemeCodeUnspsc190501.31132507'),
	'identifierSchemeCodeUnspsc190501.31132508': __('identifierSchemeCodeUnspsc190501.31132508'),
	'identifierSchemeCodeUnspsc190501.31132509': __('identifierSchemeCodeUnspsc190501.31132509'),
	'identifierSchemeCodeUnspsc190501.31132510': __('identifierSchemeCodeUnspsc190501.31132510'),
	'identifierSchemeCodeUnspsc190501.31132511': __('identifierSchemeCodeUnspsc190501.31132511'),
	'identifierSchemeCodeUnspsc190501.31132512': __('identifierSchemeCodeUnspsc190501.31132512'),
	'identifierSchemeCodeUnspsc190501.31132600': __('identifierSchemeCodeUnspsc190501.31132600'),
	'identifierSchemeCodeUnspsc190501.31132601': __('identifierSchemeCodeUnspsc190501.31132601'),
	'identifierSchemeCodeUnspsc190501.31132602': __('identifierSchemeCodeUnspsc190501.31132602'),
	'identifierSchemeCodeUnspsc190501.31132603': __('identifierSchemeCodeUnspsc190501.31132603'),
	'identifierSchemeCodeUnspsc190501.31132604': __('identifierSchemeCodeUnspsc190501.31132604'),
	'identifierSchemeCodeUnspsc190501.31132605': __('identifierSchemeCodeUnspsc190501.31132605'),
	'identifierSchemeCodeUnspsc190501.31132606': __('identifierSchemeCodeUnspsc190501.31132606'),
	'identifierSchemeCodeUnspsc190501.31132607': __('identifierSchemeCodeUnspsc190501.31132607'),
	'identifierSchemeCodeUnspsc190501.31132608': __('identifierSchemeCodeUnspsc190501.31132608'),
	'identifierSchemeCodeUnspsc190501.31132609': __('identifierSchemeCodeUnspsc190501.31132609'),
	'identifierSchemeCodeUnspsc190501.31132610': __('identifierSchemeCodeUnspsc190501.31132610'),
	'identifierSchemeCodeUnspsc190501.31132611': __('identifierSchemeCodeUnspsc190501.31132611'),
	'identifierSchemeCodeUnspsc190501.31132612': __('identifierSchemeCodeUnspsc190501.31132612'),
	'identifierSchemeCodeUnspsc190501.31132700': __('identifierSchemeCodeUnspsc190501.31132700'),
	'identifierSchemeCodeUnspsc190501.31132701': __('identifierSchemeCodeUnspsc190501.31132701'),
	'identifierSchemeCodeUnspsc190501.31132702': __('identifierSchemeCodeUnspsc190501.31132702'),
	'identifierSchemeCodeUnspsc190501.31132703': __('identifierSchemeCodeUnspsc190501.31132703'),
	'identifierSchemeCodeUnspsc190501.31132704': __('identifierSchemeCodeUnspsc190501.31132704'),
	'identifierSchemeCodeUnspsc190501.31132705': __('identifierSchemeCodeUnspsc190501.31132705'),
	'identifierSchemeCodeUnspsc190501.31132706': __('identifierSchemeCodeUnspsc190501.31132706'),
	'identifierSchemeCodeUnspsc190501.31132707': __('identifierSchemeCodeUnspsc190501.31132707'),
	'identifierSchemeCodeUnspsc190501.31132708': __('identifierSchemeCodeUnspsc190501.31132708'),
	'identifierSchemeCodeUnspsc190501.31132709': __('identifierSchemeCodeUnspsc190501.31132709'),
	'identifierSchemeCodeUnspsc190501.31132710': __('identifierSchemeCodeUnspsc190501.31132710'),
	'identifierSchemeCodeUnspsc190501.31132711': __('identifierSchemeCodeUnspsc190501.31132711'),
	'identifierSchemeCodeUnspsc190501.31132712': __('identifierSchemeCodeUnspsc190501.31132712'),
	'identifierSchemeCodeUnspsc190501.31132800': __('identifierSchemeCodeUnspsc190501.31132800'),
	'identifierSchemeCodeUnspsc190501.31132801': __('identifierSchemeCodeUnspsc190501.31132801'),
	'identifierSchemeCodeUnspsc190501.31132802': __('identifierSchemeCodeUnspsc190501.31132802'),
	'identifierSchemeCodeUnspsc190501.31132803': __('identifierSchemeCodeUnspsc190501.31132803'),
	'identifierSchemeCodeUnspsc190501.31132804': __('identifierSchemeCodeUnspsc190501.31132804'),
	'identifierSchemeCodeUnspsc190501.31132805': __('identifierSchemeCodeUnspsc190501.31132805'),
	'identifierSchemeCodeUnspsc190501.31132806': __('identifierSchemeCodeUnspsc190501.31132806'),
	'identifierSchemeCodeUnspsc190501.31132807': __('identifierSchemeCodeUnspsc190501.31132807'),
	'identifierSchemeCodeUnspsc190501.31132808': __('identifierSchemeCodeUnspsc190501.31132808'),
	'identifierSchemeCodeUnspsc190501.31132809': __('identifierSchemeCodeUnspsc190501.31132809'),
	'identifierSchemeCodeUnspsc190501.31132810': __('identifierSchemeCodeUnspsc190501.31132810'),
	'identifierSchemeCodeUnspsc190501.31132811': __('identifierSchemeCodeUnspsc190501.31132811'),
	'identifierSchemeCodeUnspsc190501.31132812': __('identifierSchemeCodeUnspsc190501.31132812'),
	'identifierSchemeCodeUnspsc190501.31132900': __('identifierSchemeCodeUnspsc190501.31132900'),
	'identifierSchemeCodeUnspsc190501.31132901': __('identifierSchemeCodeUnspsc190501.31132901'),
	'identifierSchemeCodeUnspsc190501.31132902': __('identifierSchemeCodeUnspsc190501.31132902'),
	'identifierSchemeCodeUnspsc190501.31132903': __('identifierSchemeCodeUnspsc190501.31132903'),
	'identifierSchemeCodeUnspsc190501.31132904': __('identifierSchemeCodeUnspsc190501.31132904'),
	'identifierSchemeCodeUnspsc190501.31132905': __('identifierSchemeCodeUnspsc190501.31132905'),
	'identifierSchemeCodeUnspsc190501.31132906': __('identifierSchemeCodeUnspsc190501.31132906'),
	'identifierSchemeCodeUnspsc190501.31132907': __('identifierSchemeCodeUnspsc190501.31132907'),
	'identifierSchemeCodeUnspsc190501.31132908': __('identifierSchemeCodeUnspsc190501.31132908'),
	'identifierSchemeCodeUnspsc190501.31132909': __('identifierSchemeCodeUnspsc190501.31132909'),
	'identifierSchemeCodeUnspsc190501.31132910': __('identifierSchemeCodeUnspsc190501.31132910'),
	'identifierSchemeCodeUnspsc190501.31132911': __('identifierSchemeCodeUnspsc190501.31132911'),
	'identifierSchemeCodeUnspsc190501.31132912': __('identifierSchemeCodeUnspsc190501.31132912'),
	'identifierSchemeCodeUnspsc190501.31133000': __('identifierSchemeCodeUnspsc190501.31133000'),
	'identifierSchemeCodeUnspsc190501.31133001': __('identifierSchemeCodeUnspsc190501.31133001'),
	'identifierSchemeCodeUnspsc190501.31133002': __('identifierSchemeCodeUnspsc190501.31133002'),
	'identifierSchemeCodeUnspsc190501.31133003': __('identifierSchemeCodeUnspsc190501.31133003'),
	'identifierSchemeCodeUnspsc190501.31133004': __('identifierSchemeCodeUnspsc190501.31133004'),
	'identifierSchemeCodeUnspsc190501.31133005': __('identifierSchemeCodeUnspsc190501.31133005'),
	'identifierSchemeCodeUnspsc190501.31133006': __('identifierSchemeCodeUnspsc190501.31133006'),
	'identifierSchemeCodeUnspsc190501.31133007': __('identifierSchemeCodeUnspsc190501.31133007'),
	'identifierSchemeCodeUnspsc190501.31133008': __('identifierSchemeCodeUnspsc190501.31133008'),
	'identifierSchemeCodeUnspsc190501.31133009': __('identifierSchemeCodeUnspsc190501.31133009'),
	'identifierSchemeCodeUnspsc190501.31133010': __('identifierSchemeCodeUnspsc190501.31133010'),
	'identifierSchemeCodeUnspsc190501.31133011': __('identifierSchemeCodeUnspsc190501.31133011'),
	'identifierSchemeCodeUnspsc190501.31133012': __('identifierSchemeCodeUnspsc190501.31133012'),
	'identifierSchemeCodeUnspsc190501.31133100': __('identifierSchemeCodeUnspsc190501.31133100'),
	'identifierSchemeCodeUnspsc190501.31133101': __('identifierSchemeCodeUnspsc190501.31133101'),
	'identifierSchemeCodeUnspsc190501.31133102': __('identifierSchemeCodeUnspsc190501.31133102'),
	'identifierSchemeCodeUnspsc190501.31133103': __('identifierSchemeCodeUnspsc190501.31133103'),
	'identifierSchemeCodeUnspsc190501.31133104': __('identifierSchemeCodeUnspsc190501.31133104'),
	'identifierSchemeCodeUnspsc190501.31133105': __('identifierSchemeCodeUnspsc190501.31133105'),
	'identifierSchemeCodeUnspsc190501.31133106': __('identifierSchemeCodeUnspsc190501.31133106'),
	'identifierSchemeCodeUnspsc190501.31133107': __('identifierSchemeCodeUnspsc190501.31133107'),
	'identifierSchemeCodeUnspsc190501.31133108': __('identifierSchemeCodeUnspsc190501.31133108'),
	'identifierSchemeCodeUnspsc190501.31133109': __('identifierSchemeCodeUnspsc190501.31133109'),
	'identifierSchemeCodeUnspsc190501.31133110': __('identifierSchemeCodeUnspsc190501.31133110'),
	'identifierSchemeCodeUnspsc190501.31133111': __('identifierSchemeCodeUnspsc190501.31133111'),
	'identifierSchemeCodeUnspsc190501.31133112': __('identifierSchemeCodeUnspsc190501.31133112'),
	'identifierSchemeCodeUnspsc190501.31133200': __('identifierSchemeCodeUnspsc190501.31133200'),
	'identifierSchemeCodeUnspsc190501.31133201': __('identifierSchemeCodeUnspsc190501.31133201'),
	'identifierSchemeCodeUnspsc190501.31133202': __('identifierSchemeCodeUnspsc190501.31133202'),
	'identifierSchemeCodeUnspsc190501.31133203': __('identifierSchemeCodeUnspsc190501.31133203'),
	'identifierSchemeCodeUnspsc190501.31133204': __('identifierSchemeCodeUnspsc190501.31133204'),
	'identifierSchemeCodeUnspsc190501.31133205': __('identifierSchemeCodeUnspsc190501.31133205'),
	'identifierSchemeCodeUnspsc190501.31133206': __('identifierSchemeCodeUnspsc190501.31133206'),
	'identifierSchemeCodeUnspsc190501.31133207': __('identifierSchemeCodeUnspsc190501.31133207'),
	'identifierSchemeCodeUnspsc190501.31133208': __('identifierSchemeCodeUnspsc190501.31133208'),
	'identifierSchemeCodeUnspsc190501.31133209': __('identifierSchemeCodeUnspsc190501.31133209'),
	'identifierSchemeCodeUnspsc190501.31133210': __('identifierSchemeCodeUnspsc190501.31133210'),
	'identifierSchemeCodeUnspsc190501.31133211': __('identifierSchemeCodeUnspsc190501.31133211'),
	'identifierSchemeCodeUnspsc190501.31133212': __('identifierSchemeCodeUnspsc190501.31133212'),
	'identifierSchemeCodeUnspsc190501.31133300': __('identifierSchemeCodeUnspsc190501.31133300'),
	'identifierSchemeCodeUnspsc190501.31133301': __('identifierSchemeCodeUnspsc190501.31133301'),
	'identifierSchemeCodeUnspsc190501.31133302': __('identifierSchemeCodeUnspsc190501.31133302'),
	'identifierSchemeCodeUnspsc190501.31133303': __('identifierSchemeCodeUnspsc190501.31133303'),
	'identifierSchemeCodeUnspsc190501.31133304': __('identifierSchemeCodeUnspsc190501.31133304'),
	'identifierSchemeCodeUnspsc190501.31133305': __('identifierSchemeCodeUnspsc190501.31133305'),
	'identifierSchemeCodeUnspsc190501.31133306': __('identifierSchemeCodeUnspsc190501.31133306'),
	'identifierSchemeCodeUnspsc190501.31133307': __('identifierSchemeCodeUnspsc190501.31133307'),
	'identifierSchemeCodeUnspsc190501.31133308': __('identifierSchemeCodeUnspsc190501.31133308'),
	'identifierSchemeCodeUnspsc190501.31133309': __('identifierSchemeCodeUnspsc190501.31133309'),
	'identifierSchemeCodeUnspsc190501.31133310': __('identifierSchemeCodeUnspsc190501.31133310'),
	'identifierSchemeCodeUnspsc190501.31133311': __('identifierSchemeCodeUnspsc190501.31133311'),
	'identifierSchemeCodeUnspsc190501.31133312': __('identifierSchemeCodeUnspsc190501.31133312'),
	'identifierSchemeCodeUnspsc190501.31133400': __('identifierSchemeCodeUnspsc190501.31133400'),
	'identifierSchemeCodeUnspsc190501.31133401': __('identifierSchemeCodeUnspsc190501.31133401'),
	'identifierSchemeCodeUnspsc190501.31133402': __('identifierSchemeCodeUnspsc190501.31133402'),
	'identifierSchemeCodeUnspsc190501.31133403': __('identifierSchemeCodeUnspsc190501.31133403'),
	'identifierSchemeCodeUnspsc190501.31133404': __('identifierSchemeCodeUnspsc190501.31133404'),
	'identifierSchemeCodeUnspsc190501.31133405': __('identifierSchemeCodeUnspsc190501.31133405'),
	'identifierSchemeCodeUnspsc190501.31133406': __('identifierSchemeCodeUnspsc190501.31133406'),
	'identifierSchemeCodeUnspsc190501.31133407': __('identifierSchemeCodeUnspsc190501.31133407'),
	'identifierSchemeCodeUnspsc190501.31133408': __('identifierSchemeCodeUnspsc190501.31133408'),
	'identifierSchemeCodeUnspsc190501.31133409': __('identifierSchemeCodeUnspsc190501.31133409'),
	'identifierSchemeCodeUnspsc190501.31133410': __('identifierSchemeCodeUnspsc190501.31133410'),
	'identifierSchemeCodeUnspsc190501.31133411': __('identifierSchemeCodeUnspsc190501.31133411'),
	'identifierSchemeCodeUnspsc190501.31133412': __('identifierSchemeCodeUnspsc190501.31133412'),
	'identifierSchemeCodeUnspsc190501.31133500': __('identifierSchemeCodeUnspsc190501.31133500'),
	'identifierSchemeCodeUnspsc190501.31133501': __('identifierSchemeCodeUnspsc190501.31133501'),
	'identifierSchemeCodeUnspsc190501.31133502': __('identifierSchemeCodeUnspsc190501.31133502'),
	'identifierSchemeCodeUnspsc190501.31133503': __('identifierSchemeCodeUnspsc190501.31133503'),
	'identifierSchemeCodeUnspsc190501.31133504': __('identifierSchemeCodeUnspsc190501.31133504'),
	'identifierSchemeCodeUnspsc190501.31133505': __('identifierSchemeCodeUnspsc190501.31133505'),
	'identifierSchemeCodeUnspsc190501.31133506': __('identifierSchemeCodeUnspsc190501.31133506'),
	'identifierSchemeCodeUnspsc190501.31133507': __('identifierSchemeCodeUnspsc190501.31133507'),
	'identifierSchemeCodeUnspsc190501.31133508': __('identifierSchemeCodeUnspsc190501.31133508'),
	'identifierSchemeCodeUnspsc190501.31133509': __('identifierSchemeCodeUnspsc190501.31133509'),
	'identifierSchemeCodeUnspsc190501.31133510': __('identifierSchemeCodeUnspsc190501.31133510'),
	'identifierSchemeCodeUnspsc190501.31133511': __('identifierSchemeCodeUnspsc190501.31133511'),
	'identifierSchemeCodeUnspsc190501.31133512': __('identifierSchemeCodeUnspsc190501.31133512'),
	'identifierSchemeCodeUnspsc190501.31133600': __('identifierSchemeCodeUnspsc190501.31133600'),
	'identifierSchemeCodeUnspsc190501.31133601': __('identifierSchemeCodeUnspsc190501.31133601'),
	'identifierSchemeCodeUnspsc190501.31133602': __('identifierSchemeCodeUnspsc190501.31133602'),
	'identifierSchemeCodeUnspsc190501.31133603': __('identifierSchemeCodeUnspsc190501.31133603'),
	'identifierSchemeCodeUnspsc190501.31133604': __('identifierSchemeCodeUnspsc190501.31133604'),
	'identifierSchemeCodeUnspsc190501.31133605': __('identifierSchemeCodeUnspsc190501.31133605'),
	'identifierSchemeCodeUnspsc190501.31133606': __('identifierSchemeCodeUnspsc190501.31133606'),
	'identifierSchemeCodeUnspsc190501.31133607': __('identifierSchemeCodeUnspsc190501.31133607'),
	'identifierSchemeCodeUnspsc190501.31133608': __('identifierSchemeCodeUnspsc190501.31133608'),
	'identifierSchemeCodeUnspsc190501.31133609': __('identifierSchemeCodeUnspsc190501.31133609'),
	'identifierSchemeCodeUnspsc190501.31133610': __('identifierSchemeCodeUnspsc190501.31133610'),
	'identifierSchemeCodeUnspsc190501.31133611': __('identifierSchemeCodeUnspsc190501.31133611'),
	'identifierSchemeCodeUnspsc190501.31133612': __('identifierSchemeCodeUnspsc190501.31133612'),
	'identifierSchemeCodeUnspsc190501.31133700': __('identifierSchemeCodeUnspsc190501.31133700'),
	'identifierSchemeCodeUnspsc190501.31133701': __('identifierSchemeCodeUnspsc190501.31133701'),
	'identifierSchemeCodeUnspsc190501.31133702': __('identifierSchemeCodeUnspsc190501.31133702'),
	'identifierSchemeCodeUnspsc190501.31133703': __('identifierSchemeCodeUnspsc190501.31133703'),
	'identifierSchemeCodeUnspsc190501.31133704': __('identifierSchemeCodeUnspsc190501.31133704'),
	'identifierSchemeCodeUnspsc190501.31140000': __('identifierSchemeCodeUnspsc190501.31140000'),
	'identifierSchemeCodeUnspsc190501.31141500': __('identifierSchemeCodeUnspsc190501.31141500'),
	'identifierSchemeCodeUnspsc190501.31141501': __('identifierSchemeCodeUnspsc190501.31141501'),
	'identifierSchemeCodeUnspsc190501.31141502': __('identifierSchemeCodeUnspsc190501.31141502'),
	'identifierSchemeCodeUnspsc190501.31141503': __('identifierSchemeCodeUnspsc190501.31141503'),
	'identifierSchemeCodeUnspsc190501.31141600': __('identifierSchemeCodeUnspsc190501.31141600'),
	'identifierSchemeCodeUnspsc190501.31141601': __('identifierSchemeCodeUnspsc190501.31141601'),
	'identifierSchemeCodeUnspsc190501.31141602': __('identifierSchemeCodeUnspsc190501.31141602'),
	'identifierSchemeCodeUnspsc190501.31141603': __('identifierSchemeCodeUnspsc190501.31141603'),
	'identifierSchemeCodeUnspsc190501.31141700': __('identifierSchemeCodeUnspsc190501.31141700'),
	'identifierSchemeCodeUnspsc190501.31141701': __('identifierSchemeCodeUnspsc190501.31141701'),
	'identifierSchemeCodeUnspsc190501.31141702': __('identifierSchemeCodeUnspsc190501.31141702'),
	'identifierSchemeCodeUnspsc190501.31141800': __('identifierSchemeCodeUnspsc190501.31141800'),
	'identifierSchemeCodeUnspsc190501.31141801': __('identifierSchemeCodeUnspsc190501.31141801'),
	'identifierSchemeCodeUnspsc190501.31141802': __('identifierSchemeCodeUnspsc190501.31141802'),
	'identifierSchemeCodeUnspsc190501.31141900': __('identifierSchemeCodeUnspsc190501.31141900'),
	'identifierSchemeCodeUnspsc190501.31141901': __('identifierSchemeCodeUnspsc190501.31141901'),
	'identifierSchemeCodeUnspsc190501.31142000': __('identifierSchemeCodeUnspsc190501.31142000'),
	'identifierSchemeCodeUnspsc190501.31142001': __('identifierSchemeCodeUnspsc190501.31142001'),
	'identifierSchemeCodeUnspsc190501.31142002': __('identifierSchemeCodeUnspsc190501.31142002'),
	'identifierSchemeCodeUnspsc190501.31142003': __('identifierSchemeCodeUnspsc190501.31142003'),
	'identifierSchemeCodeUnspsc190501.31142004': __('identifierSchemeCodeUnspsc190501.31142004'),
	'identifierSchemeCodeUnspsc190501.31142005': __('identifierSchemeCodeUnspsc190501.31142005'),
	'identifierSchemeCodeUnspsc190501.31142006': __('identifierSchemeCodeUnspsc190501.31142006'),
	'identifierSchemeCodeUnspsc190501.31142007': __('identifierSchemeCodeUnspsc190501.31142007'),
	'identifierSchemeCodeUnspsc190501.31142100': __('identifierSchemeCodeUnspsc190501.31142100'),
	'identifierSchemeCodeUnspsc190501.31142101': __('identifierSchemeCodeUnspsc190501.31142101'),
	'identifierSchemeCodeUnspsc190501.31142102': __('identifierSchemeCodeUnspsc190501.31142102'),
	'identifierSchemeCodeUnspsc190501.31142103': __('identifierSchemeCodeUnspsc190501.31142103'),
	'identifierSchemeCodeUnspsc190501.31142104': __('identifierSchemeCodeUnspsc190501.31142104'),
	'identifierSchemeCodeUnspsc190501.31142105': __('identifierSchemeCodeUnspsc190501.31142105'),
	'identifierSchemeCodeUnspsc190501.31142106': __('identifierSchemeCodeUnspsc190501.31142106'),
	'identifierSchemeCodeUnspsc190501.31142107': __('identifierSchemeCodeUnspsc190501.31142107'),
	'identifierSchemeCodeUnspsc190501.31142200': __('identifierSchemeCodeUnspsc190501.31142200'),
	'identifierSchemeCodeUnspsc190501.31142201': __('identifierSchemeCodeUnspsc190501.31142201'),
	'identifierSchemeCodeUnspsc190501.31142202': __('identifierSchemeCodeUnspsc190501.31142202'),
	'identifierSchemeCodeUnspsc190501.31142203': __('identifierSchemeCodeUnspsc190501.31142203'),
	'identifierSchemeCodeUnspsc190501.31142204': __('identifierSchemeCodeUnspsc190501.31142204'),
	'identifierSchemeCodeUnspsc190501.31142205': __('identifierSchemeCodeUnspsc190501.31142205'),
	'identifierSchemeCodeUnspsc190501.31142206': __('identifierSchemeCodeUnspsc190501.31142206'),
	'identifierSchemeCodeUnspsc190501.31142207': __('identifierSchemeCodeUnspsc190501.31142207'),
	'identifierSchemeCodeUnspsc190501.31142300': __('identifierSchemeCodeUnspsc190501.31142300'),
	'identifierSchemeCodeUnspsc190501.31142301': __('identifierSchemeCodeUnspsc190501.31142301'),
	'identifierSchemeCodeUnspsc190501.31142302': __('identifierSchemeCodeUnspsc190501.31142302'),
	'identifierSchemeCodeUnspsc190501.31142303': __('identifierSchemeCodeUnspsc190501.31142303'),
	'identifierSchemeCodeUnspsc190501.31142304': __('identifierSchemeCodeUnspsc190501.31142304'),
	'identifierSchemeCodeUnspsc190501.31142305': __('identifierSchemeCodeUnspsc190501.31142305'),
	'identifierSchemeCodeUnspsc190501.31142306': __('identifierSchemeCodeUnspsc190501.31142306'),
	'identifierSchemeCodeUnspsc190501.31142307': __('identifierSchemeCodeUnspsc190501.31142307'),
	'identifierSchemeCodeUnspsc190501.31142400': __('identifierSchemeCodeUnspsc190501.31142400'),
	'identifierSchemeCodeUnspsc190501.31142401': __('identifierSchemeCodeUnspsc190501.31142401'),
	'identifierSchemeCodeUnspsc190501.31142402': __('identifierSchemeCodeUnspsc190501.31142402'),
	'identifierSchemeCodeUnspsc190501.31142403': __('identifierSchemeCodeUnspsc190501.31142403'),
	'identifierSchemeCodeUnspsc190501.31142404': __('identifierSchemeCodeUnspsc190501.31142404'),
	'identifierSchemeCodeUnspsc190501.31142405': __('identifierSchemeCodeUnspsc190501.31142405'),
	'identifierSchemeCodeUnspsc190501.31142406': __('identifierSchemeCodeUnspsc190501.31142406'),
	'identifierSchemeCodeUnspsc190501.31142407': __('identifierSchemeCodeUnspsc190501.31142407'),
	'identifierSchemeCodeUnspsc190501.31142500': __('identifierSchemeCodeUnspsc190501.31142500'),
	'identifierSchemeCodeUnspsc190501.31142501': __('identifierSchemeCodeUnspsc190501.31142501'),
	'identifierSchemeCodeUnspsc190501.31142502': __('identifierSchemeCodeUnspsc190501.31142502'),
	'identifierSchemeCodeUnspsc190501.31142503': __('identifierSchemeCodeUnspsc190501.31142503'),
	'identifierSchemeCodeUnspsc190501.31142504': __('identifierSchemeCodeUnspsc190501.31142504'),
	'identifierSchemeCodeUnspsc190501.31142505': __('identifierSchemeCodeUnspsc190501.31142505'),
	'identifierSchemeCodeUnspsc190501.31142506': __('identifierSchemeCodeUnspsc190501.31142506'),
	'identifierSchemeCodeUnspsc190501.31142507': __('identifierSchemeCodeUnspsc190501.31142507'),
	'identifierSchemeCodeUnspsc190501.31142600': __('identifierSchemeCodeUnspsc190501.31142600'),
	'identifierSchemeCodeUnspsc190501.31142601': __('identifierSchemeCodeUnspsc190501.31142601'),
	'identifierSchemeCodeUnspsc190501.31142602': __('identifierSchemeCodeUnspsc190501.31142602'),
	'identifierSchemeCodeUnspsc190501.31142603': __('identifierSchemeCodeUnspsc190501.31142603'),
	'identifierSchemeCodeUnspsc190501.31142604': __('identifierSchemeCodeUnspsc190501.31142604'),
	'identifierSchemeCodeUnspsc190501.31142605': __('identifierSchemeCodeUnspsc190501.31142605'),
	'identifierSchemeCodeUnspsc190501.31142606': __('identifierSchemeCodeUnspsc190501.31142606'),
	'identifierSchemeCodeUnspsc190501.31142607': __('identifierSchemeCodeUnspsc190501.31142607'),
	'identifierSchemeCodeUnspsc190501.31142700': __('identifierSchemeCodeUnspsc190501.31142700'),
	'identifierSchemeCodeUnspsc190501.31142701': __('identifierSchemeCodeUnspsc190501.31142701'),
	'identifierSchemeCodeUnspsc190501.31142702': __('identifierSchemeCodeUnspsc190501.31142702'),
	'identifierSchemeCodeUnspsc190501.31142703': __('identifierSchemeCodeUnspsc190501.31142703'),
	'identifierSchemeCodeUnspsc190501.31142704': __('identifierSchemeCodeUnspsc190501.31142704'),
	'identifierSchemeCodeUnspsc190501.31142705': __('identifierSchemeCodeUnspsc190501.31142705'),
	'identifierSchemeCodeUnspsc190501.31142706': __('identifierSchemeCodeUnspsc190501.31142706'),
	'identifierSchemeCodeUnspsc190501.31142707': __('identifierSchemeCodeUnspsc190501.31142707'),
	'identifierSchemeCodeUnspsc190501.31142800': __('identifierSchemeCodeUnspsc190501.31142800'),
	'identifierSchemeCodeUnspsc190501.31142801': __('identifierSchemeCodeUnspsc190501.31142801'),
	'identifierSchemeCodeUnspsc190501.31142802': __('identifierSchemeCodeUnspsc190501.31142802'),
	'identifierSchemeCodeUnspsc190501.31142803': __('identifierSchemeCodeUnspsc190501.31142803'),
	'identifierSchemeCodeUnspsc190501.31142804': __('identifierSchemeCodeUnspsc190501.31142804'),
	'identifierSchemeCodeUnspsc190501.31142900': __('identifierSchemeCodeUnspsc190501.31142900'),
	'identifierSchemeCodeUnspsc190501.31142901': __('identifierSchemeCodeUnspsc190501.31142901'),
	'identifierSchemeCodeUnspsc190501.31142902': __('identifierSchemeCodeUnspsc190501.31142902'),
	'identifierSchemeCodeUnspsc190501.31142903': __('identifierSchemeCodeUnspsc190501.31142903'),
	'identifierSchemeCodeUnspsc190501.31142904': __('identifierSchemeCodeUnspsc190501.31142904'),
	'identifierSchemeCodeUnspsc190501.31143000': __('identifierSchemeCodeUnspsc190501.31143000'),
	'identifierSchemeCodeUnspsc190501.31143001': __('identifierSchemeCodeUnspsc190501.31143001'),
	'identifierSchemeCodeUnspsc190501.31143002': __('identifierSchemeCodeUnspsc190501.31143002'),
	'identifierSchemeCodeUnspsc190501.31143100': __('identifierSchemeCodeUnspsc190501.31143100'),
	'identifierSchemeCodeUnspsc190501.31143101': __('identifierSchemeCodeUnspsc190501.31143101'),
	'identifierSchemeCodeUnspsc190501.31143102': __('identifierSchemeCodeUnspsc190501.31143102'),
	'identifierSchemeCodeUnspsc190501.31143103': __('identifierSchemeCodeUnspsc190501.31143103'),
	'identifierSchemeCodeUnspsc190501.31143200': __('identifierSchemeCodeUnspsc190501.31143200'),
	'identifierSchemeCodeUnspsc190501.31143201': __('identifierSchemeCodeUnspsc190501.31143201'),
	'identifierSchemeCodeUnspsc190501.31143202': __('identifierSchemeCodeUnspsc190501.31143202'),
	'identifierSchemeCodeUnspsc190501.31143203': __('identifierSchemeCodeUnspsc190501.31143203'),
	'identifierSchemeCodeUnspsc190501.31143204': __('identifierSchemeCodeUnspsc190501.31143204'),
	'identifierSchemeCodeUnspsc190501.31143205': __('identifierSchemeCodeUnspsc190501.31143205'),
	'identifierSchemeCodeUnspsc190501.31150000': __('identifierSchemeCodeUnspsc190501.31150000'),
	'identifierSchemeCodeUnspsc190501.31151500': __('identifierSchemeCodeUnspsc190501.31151500'),
	'identifierSchemeCodeUnspsc190501.31151501': __('identifierSchemeCodeUnspsc190501.31151501'),
	'identifierSchemeCodeUnspsc190501.31151502': __('identifierSchemeCodeUnspsc190501.31151502'),
	'identifierSchemeCodeUnspsc190501.31151503': __('identifierSchemeCodeUnspsc190501.31151503'),
	'identifierSchemeCodeUnspsc190501.31151504': __('identifierSchemeCodeUnspsc190501.31151504'),
	'identifierSchemeCodeUnspsc190501.31151505': __('identifierSchemeCodeUnspsc190501.31151505'),
	'identifierSchemeCodeUnspsc190501.31151506': __('identifierSchemeCodeUnspsc190501.31151506'),
	'identifierSchemeCodeUnspsc190501.31151507': __('identifierSchemeCodeUnspsc190501.31151507'),
	'identifierSchemeCodeUnspsc190501.31151508': __('identifierSchemeCodeUnspsc190501.31151508'),
	'identifierSchemeCodeUnspsc190501.31151509': __('identifierSchemeCodeUnspsc190501.31151509'),
	'identifierSchemeCodeUnspsc190501.31151510': __('identifierSchemeCodeUnspsc190501.31151510'),
	'identifierSchemeCodeUnspsc190501.31151511': __('identifierSchemeCodeUnspsc190501.31151511'),
	'identifierSchemeCodeUnspsc190501.31151512': __('identifierSchemeCodeUnspsc190501.31151512'),
	'identifierSchemeCodeUnspsc190501.31151513': __('identifierSchemeCodeUnspsc190501.31151513'),
	'identifierSchemeCodeUnspsc190501.31151514': __('identifierSchemeCodeUnspsc190501.31151514'),
	'identifierSchemeCodeUnspsc190501.31151515': __('identifierSchemeCodeUnspsc190501.31151515'),
	'identifierSchemeCodeUnspsc190501.31151516': __('identifierSchemeCodeUnspsc190501.31151516'),
	'identifierSchemeCodeUnspsc190501.31151517': __('identifierSchemeCodeUnspsc190501.31151517'),
	'identifierSchemeCodeUnspsc190501.31151518': __('identifierSchemeCodeUnspsc190501.31151518'),
	'identifierSchemeCodeUnspsc190501.31151519': __('identifierSchemeCodeUnspsc190501.31151519'),
	'identifierSchemeCodeUnspsc190501.31151520': __('identifierSchemeCodeUnspsc190501.31151520'),
	'identifierSchemeCodeUnspsc190501.31151521': __('identifierSchemeCodeUnspsc190501.31151521'),
	'identifierSchemeCodeUnspsc190501.31151600': __('identifierSchemeCodeUnspsc190501.31151600'),
	'identifierSchemeCodeUnspsc190501.31151601': __('identifierSchemeCodeUnspsc190501.31151601'),
	'identifierSchemeCodeUnspsc190501.31151603': __('identifierSchemeCodeUnspsc190501.31151603'),
	'identifierSchemeCodeUnspsc190501.31151604': __('identifierSchemeCodeUnspsc190501.31151604'),
	'identifierSchemeCodeUnspsc190501.31151605': __('identifierSchemeCodeUnspsc190501.31151605'),
	'identifierSchemeCodeUnspsc190501.31151606': __('identifierSchemeCodeUnspsc190501.31151606'),
	'identifierSchemeCodeUnspsc190501.31151607': __('identifierSchemeCodeUnspsc190501.31151607'),
	'identifierSchemeCodeUnspsc190501.31151608': __('identifierSchemeCodeUnspsc190501.31151608'),
	'identifierSchemeCodeUnspsc190501.31151609': __('identifierSchemeCodeUnspsc190501.31151609'),
	'identifierSchemeCodeUnspsc190501.31151610': __('identifierSchemeCodeUnspsc190501.31151610'),
	'identifierSchemeCodeUnspsc190501.31151611': __('identifierSchemeCodeUnspsc190501.31151611'),
	'identifierSchemeCodeUnspsc190501.31151612': __('identifierSchemeCodeUnspsc190501.31151612'),
	'identifierSchemeCodeUnspsc190501.31151613': __('identifierSchemeCodeUnspsc190501.31151613'),
	'identifierSchemeCodeUnspsc190501.31151614': __('identifierSchemeCodeUnspsc190501.31151614'),
	'identifierSchemeCodeUnspsc190501.31151615': __('identifierSchemeCodeUnspsc190501.31151615'),
	'identifierSchemeCodeUnspsc190501.31151616': __('identifierSchemeCodeUnspsc190501.31151616'),
	'identifierSchemeCodeUnspsc190501.31151617': __('identifierSchemeCodeUnspsc190501.31151617'),
	'identifierSchemeCodeUnspsc190501.31151618': __('identifierSchemeCodeUnspsc190501.31151618'),
	'identifierSchemeCodeUnspsc190501.31151619': __('identifierSchemeCodeUnspsc190501.31151619'),
	'identifierSchemeCodeUnspsc190501.31151700': __('identifierSchemeCodeUnspsc190501.31151700'),
	'identifierSchemeCodeUnspsc190501.31151702': __('identifierSchemeCodeUnspsc190501.31151702'),
	'identifierSchemeCodeUnspsc190501.31151703': __('identifierSchemeCodeUnspsc190501.31151703'),
	'identifierSchemeCodeUnspsc190501.31151704': __('identifierSchemeCodeUnspsc190501.31151704'),
	'identifierSchemeCodeUnspsc190501.31151705': __('identifierSchemeCodeUnspsc190501.31151705'),
	'identifierSchemeCodeUnspsc190501.31151706': __('identifierSchemeCodeUnspsc190501.31151706'),
	'identifierSchemeCodeUnspsc190501.31151707': __('identifierSchemeCodeUnspsc190501.31151707'),
	'identifierSchemeCodeUnspsc190501.31151708': __('identifierSchemeCodeUnspsc190501.31151708'),
	'identifierSchemeCodeUnspsc190501.31151709': __('identifierSchemeCodeUnspsc190501.31151709'),
	'identifierSchemeCodeUnspsc190501.31151710': __('identifierSchemeCodeUnspsc190501.31151710'),
	'identifierSchemeCodeUnspsc190501.31151800': __('identifierSchemeCodeUnspsc190501.31151800'),
	'identifierSchemeCodeUnspsc190501.31151803': __('identifierSchemeCodeUnspsc190501.31151803'),
	'identifierSchemeCodeUnspsc190501.31151804': __('identifierSchemeCodeUnspsc190501.31151804'),
	'identifierSchemeCodeUnspsc190501.31151805': __('identifierSchemeCodeUnspsc190501.31151805'),
	'identifierSchemeCodeUnspsc190501.31151806': __('identifierSchemeCodeUnspsc190501.31151806'),
	'identifierSchemeCodeUnspsc190501.31151900': __('identifierSchemeCodeUnspsc190501.31151900'),
	'identifierSchemeCodeUnspsc190501.31151901': __('identifierSchemeCodeUnspsc190501.31151901'),
	'identifierSchemeCodeUnspsc190501.31151902': __('identifierSchemeCodeUnspsc190501.31151902'),
	'identifierSchemeCodeUnspsc190501.31151903': __('identifierSchemeCodeUnspsc190501.31151903'),
	'identifierSchemeCodeUnspsc190501.31151904': __('identifierSchemeCodeUnspsc190501.31151904'),
	'identifierSchemeCodeUnspsc190501.31151905': __('identifierSchemeCodeUnspsc190501.31151905'),
	'identifierSchemeCodeUnspsc190501.31151906': __('identifierSchemeCodeUnspsc190501.31151906'),
	'identifierSchemeCodeUnspsc190501.31152000': __('identifierSchemeCodeUnspsc190501.31152000'),
	'identifierSchemeCodeUnspsc190501.31152001': __('identifierSchemeCodeUnspsc190501.31152001'),
	'identifierSchemeCodeUnspsc190501.31152002': __('identifierSchemeCodeUnspsc190501.31152002'),
	'identifierSchemeCodeUnspsc190501.31152100': __('identifierSchemeCodeUnspsc190501.31152100'),
	'identifierSchemeCodeUnspsc190501.31152101': __('identifierSchemeCodeUnspsc190501.31152101'),
	'identifierSchemeCodeUnspsc190501.31152102': __('identifierSchemeCodeUnspsc190501.31152102'),
	'identifierSchemeCodeUnspsc190501.31152103': __('identifierSchemeCodeUnspsc190501.31152103'),
	'identifierSchemeCodeUnspsc190501.31152104': __('identifierSchemeCodeUnspsc190501.31152104'),
	'identifierSchemeCodeUnspsc190501.31152105': __('identifierSchemeCodeUnspsc190501.31152105'),
	'identifierSchemeCodeUnspsc190501.31152106': __('identifierSchemeCodeUnspsc190501.31152106'),
	'identifierSchemeCodeUnspsc190501.31152107': __('identifierSchemeCodeUnspsc190501.31152107'),
	'identifierSchemeCodeUnspsc190501.31152108': __('identifierSchemeCodeUnspsc190501.31152108'),
	'identifierSchemeCodeUnspsc190501.31152109': __('identifierSchemeCodeUnspsc190501.31152109'),
	'identifierSchemeCodeUnspsc190501.31152110': __('identifierSchemeCodeUnspsc190501.31152110'),
	'identifierSchemeCodeUnspsc190501.31152111': __('identifierSchemeCodeUnspsc190501.31152111'),
	'identifierSchemeCodeUnspsc190501.31152112': __('identifierSchemeCodeUnspsc190501.31152112'),
	'identifierSchemeCodeUnspsc190501.31152200': __('identifierSchemeCodeUnspsc190501.31152200'),
	'identifierSchemeCodeUnspsc190501.31152201': __('identifierSchemeCodeUnspsc190501.31152201'),
	'identifierSchemeCodeUnspsc190501.31152202': __('identifierSchemeCodeUnspsc190501.31152202'),
	'identifierSchemeCodeUnspsc190501.31152203': __('identifierSchemeCodeUnspsc190501.31152203'),
	'identifierSchemeCodeUnspsc190501.31152204': __('identifierSchemeCodeUnspsc190501.31152204'),
	'identifierSchemeCodeUnspsc190501.31152205': __('identifierSchemeCodeUnspsc190501.31152205'),
	'identifierSchemeCodeUnspsc190501.31152206': __('identifierSchemeCodeUnspsc190501.31152206'),
	'identifierSchemeCodeUnspsc190501.31152207': __('identifierSchemeCodeUnspsc190501.31152207'),
	'identifierSchemeCodeUnspsc190501.31152208': __('identifierSchemeCodeUnspsc190501.31152208'),
	'identifierSchemeCodeUnspsc190501.31152209': __('identifierSchemeCodeUnspsc190501.31152209'),
	'identifierSchemeCodeUnspsc190501.31152300': __('identifierSchemeCodeUnspsc190501.31152300'),
	'identifierSchemeCodeUnspsc190501.31152301': __('identifierSchemeCodeUnspsc190501.31152301'),
	'identifierSchemeCodeUnspsc190501.31152302': __('identifierSchemeCodeUnspsc190501.31152302'),
	'identifierSchemeCodeUnspsc190501.31152303': __('identifierSchemeCodeUnspsc190501.31152303'),
	'identifierSchemeCodeUnspsc190501.31152304': __('identifierSchemeCodeUnspsc190501.31152304'),
	'identifierSchemeCodeUnspsc190501.31152305': __('identifierSchemeCodeUnspsc190501.31152305'),
	'identifierSchemeCodeUnspsc190501.31152306': __('identifierSchemeCodeUnspsc190501.31152306'),
	'identifierSchemeCodeUnspsc190501.31152307': __('identifierSchemeCodeUnspsc190501.31152307'),
	'identifierSchemeCodeUnspsc190501.31160000': __('identifierSchemeCodeUnspsc190501.31160000'),
	'identifierSchemeCodeUnspsc190501.31161500': __('identifierSchemeCodeUnspsc190501.31161500'),
	'identifierSchemeCodeUnspsc190501.31161501': __('identifierSchemeCodeUnspsc190501.31161501'),
	'identifierSchemeCodeUnspsc190501.31161502': __('identifierSchemeCodeUnspsc190501.31161502'),
	'identifierSchemeCodeUnspsc190501.31161503': __('identifierSchemeCodeUnspsc190501.31161503'),
	'identifierSchemeCodeUnspsc190501.31161504': __('identifierSchemeCodeUnspsc190501.31161504'),
	'identifierSchemeCodeUnspsc190501.31161505': __('identifierSchemeCodeUnspsc190501.31161505'),
	'identifierSchemeCodeUnspsc190501.31161506': __('identifierSchemeCodeUnspsc190501.31161506'),
	'identifierSchemeCodeUnspsc190501.31161507': __('identifierSchemeCodeUnspsc190501.31161507'),
	'identifierSchemeCodeUnspsc190501.31161508': __('identifierSchemeCodeUnspsc190501.31161508'),
	'identifierSchemeCodeUnspsc190501.31161509': __('identifierSchemeCodeUnspsc190501.31161509'),
	'identifierSchemeCodeUnspsc190501.31161510': __('identifierSchemeCodeUnspsc190501.31161510'),
	'identifierSchemeCodeUnspsc190501.31161511': __('identifierSchemeCodeUnspsc190501.31161511'),
	'identifierSchemeCodeUnspsc190501.31161512': __('identifierSchemeCodeUnspsc190501.31161512'),
	'identifierSchemeCodeUnspsc190501.31161513': __('identifierSchemeCodeUnspsc190501.31161513'),
	'identifierSchemeCodeUnspsc190501.31161514': __('identifierSchemeCodeUnspsc190501.31161514'),
	'identifierSchemeCodeUnspsc190501.31161516': __('identifierSchemeCodeUnspsc190501.31161516'),
	'identifierSchemeCodeUnspsc190501.31161517': __('identifierSchemeCodeUnspsc190501.31161517'),
	'identifierSchemeCodeUnspsc190501.31161518': __('identifierSchemeCodeUnspsc190501.31161518'),
	'identifierSchemeCodeUnspsc190501.31161519': __('identifierSchemeCodeUnspsc190501.31161519'),
	'identifierSchemeCodeUnspsc190501.31161520': __('identifierSchemeCodeUnspsc190501.31161520'),
	'identifierSchemeCodeUnspsc190501.31161521': __('identifierSchemeCodeUnspsc190501.31161521'),
	'identifierSchemeCodeUnspsc190501.31161522': __('identifierSchemeCodeUnspsc190501.31161522'),
	'identifierSchemeCodeUnspsc190501.31161523': __('identifierSchemeCodeUnspsc190501.31161523'),
	'identifierSchemeCodeUnspsc190501.31161524': __('identifierSchemeCodeUnspsc190501.31161524'),
	'identifierSchemeCodeUnspsc190501.31161525': __('identifierSchemeCodeUnspsc190501.31161525'),
	'identifierSchemeCodeUnspsc190501.31161526': __('identifierSchemeCodeUnspsc190501.31161526'),
	'identifierSchemeCodeUnspsc190501.31161527': __('identifierSchemeCodeUnspsc190501.31161527'),
	'identifierSchemeCodeUnspsc190501.31161528': __('identifierSchemeCodeUnspsc190501.31161528'),
	'identifierSchemeCodeUnspsc190501.31161529': __('identifierSchemeCodeUnspsc190501.31161529'),
	'identifierSchemeCodeUnspsc190501.31161530': __('identifierSchemeCodeUnspsc190501.31161530'),
	'identifierSchemeCodeUnspsc190501.31161531': __('identifierSchemeCodeUnspsc190501.31161531'),
	'identifierSchemeCodeUnspsc190501.31161532': __('identifierSchemeCodeUnspsc190501.31161532'),
	'identifierSchemeCodeUnspsc190501.31161533': __('identifierSchemeCodeUnspsc190501.31161533'),
	'identifierSchemeCodeUnspsc190501.31161534': __('identifierSchemeCodeUnspsc190501.31161534'),
	'identifierSchemeCodeUnspsc190501.31161535': __('identifierSchemeCodeUnspsc190501.31161535'),
	'identifierSchemeCodeUnspsc190501.31161600': __('identifierSchemeCodeUnspsc190501.31161600'),
	'identifierSchemeCodeUnspsc190501.31161601': __('identifierSchemeCodeUnspsc190501.31161601'),
	'identifierSchemeCodeUnspsc190501.31161602': __('identifierSchemeCodeUnspsc190501.31161602'),
	'identifierSchemeCodeUnspsc190501.31161603': __('identifierSchemeCodeUnspsc190501.31161603'),
	'identifierSchemeCodeUnspsc190501.31161604': __('identifierSchemeCodeUnspsc190501.31161604'),
	'identifierSchemeCodeUnspsc190501.31161605': __('identifierSchemeCodeUnspsc190501.31161605'),
	'identifierSchemeCodeUnspsc190501.31161606': __('identifierSchemeCodeUnspsc190501.31161606'),
	'identifierSchemeCodeUnspsc190501.31161607': __('identifierSchemeCodeUnspsc190501.31161607'),
	'identifierSchemeCodeUnspsc190501.31161608': __('identifierSchemeCodeUnspsc190501.31161608'),
	'identifierSchemeCodeUnspsc190501.31161609': __('identifierSchemeCodeUnspsc190501.31161609'),
	'identifierSchemeCodeUnspsc190501.31161610': __('identifierSchemeCodeUnspsc190501.31161610'),
	'identifierSchemeCodeUnspsc190501.31161611': __('identifierSchemeCodeUnspsc190501.31161611'),
	'identifierSchemeCodeUnspsc190501.31161612': __('identifierSchemeCodeUnspsc190501.31161612'),
	'identifierSchemeCodeUnspsc190501.31161613': __('identifierSchemeCodeUnspsc190501.31161613'),
	'identifierSchemeCodeUnspsc190501.31161614': __('identifierSchemeCodeUnspsc190501.31161614'),
	'identifierSchemeCodeUnspsc190501.31161616': __('identifierSchemeCodeUnspsc190501.31161616'),
	'identifierSchemeCodeUnspsc190501.31161617': __('identifierSchemeCodeUnspsc190501.31161617'),
	'identifierSchemeCodeUnspsc190501.31161618': __('identifierSchemeCodeUnspsc190501.31161618'),
	'identifierSchemeCodeUnspsc190501.31161619': __('identifierSchemeCodeUnspsc190501.31161619'),
	'identifierSchemeCodeUnspsc190501.31161620': __('identifierSchemeCodeUnspsc190501.31161620'),
	'identifierSchemeCodeUnspsc190501.31161621': __('identifierSchemeCodeUnspsc190501.31161621'),
	'identifierSchemeCodeUnspsc190501.31161622': __('identifierSchemeCodeUnspsc190501.31161622'),
	'identifierSchemeCodeUnspsc190501.31161623': __('identifierSchemeCodeUnspsc190501.31161623'),
	'identifierSchemeCodeUnspsc190501.31161624': __('identifierSchemeCodeUnspsc190501.31161624'),
	'identifierSchemeCodeUnspsc190501.31161625': __('identifierSchemeCodeUnspsc190501.31161625'),
	'identifierSchemeCodeUnspsc190501.31161626': __('identifierSchemeCodeUnspsc190501.31161626'),
	'identifierSchemeCodeUnspsc190501.31161627': __('identifierSchemeCodeUnspsc190501.31161627'),
	'identifierSchemeCodeUnspsc190501.31161628': __('identifierSchemeCodeUnspsc190501.31161628'),
	'identifierSchemeCodeUnspsc190501.31161629': __('identifierSchemeCodeUnspsc190501.31161629'),
	'identifierSchemeCodeUnspsc190501.31161630': __('identifierSchemeCodeUnspsc190501.31161630'),
	'identifierSchemeCodeUnspsc190501.31161631': __('identifierSchemeCodeUnspsc190501.31161631'),
	'identifierSchemeCodeUnspsc190501.31161632': __('identifierSchemeCodeUnspsc190501.31161632'),
	'identifierSchemeCodeUnspsc190501.31161633': __('identifierSchemeCodeUnspsc190501.31161633'),
	'identifierSchemeCodeUnspsc190501.31161634': __('identifierSchemeCodeUnspsc190501.31161634'),
	'identifierSchemeCodeUnspsc190501.31161635': __('identifierSchemeCodeUnspsc190501.31161635'),
	'identifierSchemeCodeUnspsc190501.31161636': __('identifierSchemeCodeUnspsc190501.31161636'),
	'identifierSchemeCodeUnspsc190501.31161637': __('identifierSchemeCodeUnspsc190501.31161637'),
	'identifierSchemeCodeUnspsc190501.31161638': __('identifierSchemeCodeUnspsc190501.31161638'),
	'identifierSchemeCodeUnspsc190501.31161639': __('identifierSchemeCodeUnspsc190501.31161639'),
	'identifierSchemeCodeUnspsc190501.31161640': __('identifierSchemeCodeUnspsc190501.31161640'),
	'identifierSchemeCodeUnspsc190501.31161700': __('identifierSchemeCodeUnspsc190501.31161700'),
	'identifierSchemeCodeUnspsc190501.31161701': __('identifierSchemeCodeUnspsc190501.31161701'),
	'identifierSchemeCodeUnspsc190501.31161702': __('identifierSchemeCodeUnspsc190501.31161702'),
	'identifierSchemeCodeUnspsc190501.31161703': __('identifierSchemeCodeUnspsc190501.31161703'),
	'identifierSchemeCodeUnspsc190501.31161704': __('identifierSchemeCodeUnspsc190501.31161704'),
	'identifierSchemeCodeUnspsc190501.31161705': __('identifierSchemeCodeUnspsc190501.31161705'),
	'identifierSchemeCodeUnspsc190501.31161706': __('identifierSchemeCodeUnspsc190501.31161706'),
	'identifierSchemeCodeUnspsc190501.31161707': __('identifierSchemeCodeUnspsc190501.31161707'),
	'identifierSchemeCodeUnspsc190501.31161708': __('identifierSchemeCodeUnspsc190501.31161708'),
	'identifierSchemeCodeUnspsc190501.31161709': __('identifierSchemeCodeUnspsc190501.31161709'),
	'identifierSchemeCodeUnspsc190501.31161710': __('identifierSchemeCodeUnspsc190501.31161710'),
	'identifierSchemeCodeUnspsc190501.31161711': __('identifierSchemeCodeUnspsc190501.31161711'),
	'identifierSchemeCodeUnspsc190501.31161712': __('identifierSchemeCodeUnspsc190501.31161712'),
	'identifierSchemeCodeUnspsc190501.31161713': __('identifierSchemeCodeUnspsc190501.31161713'),
	'identifierSchemeCodeUnspsc190501.31161714': __('identifierSchemeCodeUnspsc190501.31161714'),
	'identifierSchemeCodeUnspsc190501.31161716': __('identifierSchemeCodeUnspsc190501.31161716'),
	'identifierSchemeCodeUnspsc190501.31161717': __('identifierSchemeCodeUnspsc190501.31161717'),
	'identifierSchemeCodeUnspsc190501.31161718': __('identifierSchemeCodeUnspsc190501.31161718'),
	'identifierSchemeCodeUnspsc190501.31161719': __('identifierSchemeCodeUnspsc190501.31161719'),
	'identifierSchemeCodeUnspsc190501.31161720': __('identifierSchemeCodeUnspsc190501.31161720'),
	'identifierSchemeCodeUnspsc190501.31161721': __('identifierSchemeCodeUnspsc190501.31161721'),
	'identifierSchemeCodeUnspsc190501.31161722': __('identifierSchemeCodeUnspsc190501.31161722'),
	'identifierSchemeCodeUnspsc190501.31161723': __('identifierSchemeCodeUnspsc190501.31161723'),
	'identifierSchemeCodeUnspsc190501.31161724': __('identifierSchemeCodeUnspsc190501.31161724'),
	'identifierSchemeCodeUnspsc190501.31161725': __('identifierSchemeCodeUnspsc190501.31161725'),
	'identifierSchemeCodeUnspsc190501.31161726': __('identifierSchemeCodeUnspsc190501.31161726'),
	'identifierSchemeCodeUnspsc190501.31161727': __('identifierSchemeCodeUnspsc190501.31161727'),
	'identifierSchemeCodeUnspsc190501.31161728': __('identifierSchemeCodeUnspsc190501.31161728'),
	'identifierSchemeCodeUnspsc190501.31161729': __('identifierSchemeCodeUnspsc190501.31161729'),
	'identifierSchemeCodeUnspsc190501.31161730': __('identifierSchemeCodeUnspsc190501.31161730'),
	'identifierSchemeCodeUnspsc190501.31161731': __('identifierSchemeCodeUnspsc190501.31161731'),
	'identifierSchemeCodeUnspsc190501.31161732': __('identifierSchemeCodeUnspsc190501.31161732'),
	'identifierSchemeCodeUnspsc190501.31161733': __('identifierSchemeCodeUnspsc190501.31161733'),
	'identifierSchemeCodeUnspsc190501.31161734': __('identifierSchemeCodeUnspsc190501.31161734'),
	'identifierSchemeCodeUnspsc190501.31161735': __('identifierSchemeCodeUnspsc190501.31161735'),
	'identifierSchemeCodeUnspsc190501.31161736': __('identifierSchemeCodeUnspsc190501.31161736'),
	'identifierSchemeCodeUnspsc190501.31161737': __('identifierSchemeCodeUnspsc190501.31161737'),
	'identifierSchemeCodeUnspsc190501.31161738': __('identifierSchemeCodeUnspsc190501.31161738'),
	'identifierSchemeCodeUnspsc190501.31161739': __('identifierSchemeCodeUnspsc190501.31161739'),
	'identifierSchemeCodeUnspsc190501.31161740': __('identifierSchemeCodeUnspsc190501.31161740'),
	'identifierSchemeCodeUnspsc190501.31161741': __('identifierSchemeCodeUnspsc190501.31161741'),
	'identifierSchemeCodeUnspsc190501.31161800': __('identifierSchemeCodeUnspsc190501.31161800'),
	'identifierSchemeCodeUnspsc190501.31161801': __('identifierSchemeCodeUnspsc190501.31161801'),
	'identifierSchemeCodeUnspsc190501.31161802': __('identifierSchemeCodeUnspsc190501.31161802'),
	'identifierSchemeCodeUnspsc190501.31161803': __('identifierSchemeCodeUnspsc190501.31161803'),
	'identifierSchemeCodeUnspsc190501.31161804': __('identifierSchemeCodeUnspsc190501.31161804'),
	'identifierSchemeCodeUnspsc190501.31161805': __('identifierSchemeCodeUnspsc190501.31161805'),
	'identifierSchemeCodeUnspsc190501.31161806': __('identifierSchemeCodeUnspsc190501.31161806'),
	'identifierSchemeCodeUnspsc190501.31161807': __('identifierSchemeCodeUnspsc190501.31161807'),
	'identifierSchemeCodeUnspsc190501.31161808': __('identifierSchemeCodeUnspsc190501.31161808'),
	'identifierSchemeCodeUnspsc190501.31161809': __('identifierSchemeCodeUnspsc190501.31161809'),
	'identifierSchemeCodeUnspsc190501.31161810': __('identifierSchemeCodeUnspsc190501.31161810'),
	'identifierSchemeCodeUnspsc190501.31161811': __('identifierSchemeCodeUnspsc190501.31161811'),
	'identifierSchemeCodeUnspsc190501.31161812': __('identifierSchemeCodeUnspsc190501.31161812'),
	'identifierSchemeCodeUnspsc190501.31161813': __('identifierSchemeCodeUnspsc190501.31161813'),
	'identifierSchemeCodeUnspsc190501.31161814': __('identifierSchemeCodeUnspsc190501.31161814'),
	'identifierSchemeCodeUnspsc190501.31161815': __('identifierSchemeCodeUnspsc190501.31161815'),
	'identifierSchemeCodeUnspsc190501.31161816': __('identifierSchemeCodeUnspsc190501.31161816'),
	'identifierSchemeCodeUnspsc190501.31161817': __('identifierSchemeCodeUnspsc190501.31161817'),
	'identifierSchemeCodeUnspsc190501.31161818': __('identifierSchemeCodeUnspsc190501.31161818'),
	'identifierSchemeCodeUnspsc190501.31161819': __('identifierSchemeCodeUnspsc190501.31161819'),
	'identifierSchemeCodeUnspsc190501.31161820': __('identifierSchemeCodeUnspsc190501.31161820'),
	'identifierSchemeCodeUnspsc190501.31161821': __('identifierSchemeCodeUnspsc190501.31161821'),
	'identifierSchemeCodeUnspsc190501.31161822': __('identifierSchemeCodeUnspsc190501.31161822'),
	'identifierSchemeCodeUnspsc190501.31161823': __('identifierSchemeCodeUnspsc190501.31161823'),
	'identifierSchemeCodeUnspsc190501.31161824': __('identifierSchemeCodeUnspsc190501.31161824'),
	'identifierSchemeCodeUnspsc190501.31161825': __('identifierSchemeCodeUnspsc190501.31161825'),
	'identifierSchemeCodeUnspsc190501.31161826': __('identifierSchemeCodeUnspsc190501.31161826'),
	'identifierSchemeCodeUnspsc190501.31161827': __('identifierSchemeCodeUnspsc190501.31161827'),
	'identifierSchemeCodeUnspsc190501.31161828': __('identifierSchemeCodeUnspsc190501.31161828'),
	'identifierSchemeCodeUnspsc190501.31161829': __('identifierSchemeCodeUnspsc190501.31161829'),
	'identifierSchemeCodeUnspsc190501.31161830': __('identifierSchemeCodeUnspsc190501.31161830'),
	'identifierSchemeCodeUnspsc190501.31161831': __('identifierSchemeCodeUnspsc190501.31161831'),
	'identifierSchemeCodeUnspsc190501.31161832': __('identifierSchemeCodeUnspsc190501.31161832'),
	'identifierSchemeCodeUnspsc190501.31161833': __('identifierSchemeCodeUnspsc190501.31161833'),
	'identifierSchemeCodeUnspsc190501.31161834': __('identifierSchemeCodeUnspsc190501.31161834'),
	'identifierSchemeCodeUnspsc190501.31161835': __('identifierSchemeCodeUnspsc190501.31161835'),
	'identifierSchemeCodeUnspsc190501.31161836': __('identifierSchemeCodeUnspsc190501.31161836'),
	'identifierSchemeCodeUnspsc190501.31161837': __('identifierSchemeCodeUnspsc190501.31161837'),
	'identifierSchemeCodeUnspsc190501.31161838': __('identifierSchemeCodeUnspsc190501.31161838'),
	'identifierSchemeCodeUnspsc190501.31161900': __('identifierSchemeCodeUnspsc190501.31161900'),
	'identifierSchemeCodeUnspsc190501.31161901': __('identifierSchemeCodeUnspsc190501.31161901'),
	'identifierSchemeCodeUnspsc190501.31161902': __('identifierSchemeCodeUnspsc190501.31161902'),
	'identifierSchemeCodeUnspsc190501.31161903': __('identifierSchemeCodeUnspsc190501.31161903'),
	'identifierSchemeCodeUnspsc190501.31161904': __('identifierSchemeCodeUnspsc190501.31161904'),
	'identifierSchemeCodeUnspsc190501.31161905': __('identifierSchemeCodeUnspsc190501.31161905'),
	'identifierSchemeCodeUnspsc190501.31161906': __('identifierSchemeCodeUnspsc190501.31161906'),
	'identifierSchemeCodeUnspsc190501.31161907': __('identifierSchemeCodeUnspsc190501.31161907'),
	'identifierSchemeCodeUnspsc190501.31161908': __('identifierSchemeCodeUnspsc190501.31161908'),
	'identifierSchemeCodeUnspsc190501.31161909': __('identifierSchemeCodeUnspsc190501.31161909'),
	'identifierSchemeCodeUnspsc190501.31161910': __('identifierSchemeCodeUnspsc190501.31161910'),
	'identifierSchemeCodeUnspsc190501.31161911': __('identifierSchemeCodeUnspsc190501.31161911'),
	'identifierSchemeCodeUnspsc190501.31161912': __('identifierSchemeCodeUnspsc190501.31161912'),
	'identifierSchemeCodeUnspsc190501.31162000': __('identifierSchemeCodeUnspsc190501.31162000'),
	'identifierSchemeCodeUnspsc190501.31162001': __('identifierSchemeCodeUnspsc190501.31162001'),
	'identifierSchemeCodeUnspsc190501.31162002': __('identifierSchemeCodeUnspsc190501.31162002'),
	'identifierSchemeCodeUnspsc190501.31162003': __('identifierSchemeCodeUnspsc190501.31162003'),
	'identifierSchemeCodeUnspsc190501.31162004': __('identifierSchemeCodeUnspsc190501.31162004'),
	'identifierSchemeCodeUnspsc190501.31162005': __('identifierSchemeCodeUnspsc190501.31162005'),
	'identifierSchemeCodeUnspsc190501.31162006': __('identifierSchemeCodeUnspsc190501.31162006'),
	'identifierSchemeCodeUnspsc190501.31162007': __('identifierSchemeCodeUnspsc190501.31162007'),
	'identifierSchemeCodeUnspsc190501.31162008': __('identifierSchemeCodeUnspsc190501.31162008'),
	'identifierSchemeCodeUnspsc190501.31162009': __('identifierSchemeCodeUnspsc190501.31162009'),
	'identifierSchemeCodeUnspsc190501.31162010': __('identifierSchemeCodeUnspsc190501.31162010'),
	'identifierSchemeCodeUnspsc190501.31162011': __('identifierSchemeCodeUnspsc190501.31162011'),
	'identifierSchemeCodeUnspsc190501.31162012': __('identifierSchemeCodeUnspsc190501.31162012'),
	'identifierSchemeCodeUnspsc190501.31162013': __('identifierSchemeCodeUnspsc190501.31162013'),
	'identifierSchemeCodeUnspsc190501.31162014': __('identifierSchemeCodeUnspsc190501.31162014'),
	'identifierSchemeCodeUnspsc190501.31162100': __('identifierSchemeCodeUnspsc190501.31162100'),
	'identifierSchemeCodeUnspsc190501.31162101': __('identifierSchemeCodeUnspsc190501.31162101'),
	'identifierSchemeCodeUnspsc190501.31162102': __('identifierSchemeCodeUnspsc190501.31162102'),
	'identifierSchemeCodeUnspsc190501.31162103': __('identifierSchemeCodeUnspsc190501.31162103'),
	'identifierSchemeCodeUnspsc190501.31162104': __('identifierSchemeCodeUnspsc190501.31162104'),
	'identifierSchemeCodeUnspsc190501.31162105': __('identifierSchemeCodeUnspsc190501.31162105'),
	'identifierSchemeCodeUnspsc190501.31162106': __('identifierSchemeCodeUnspsc190501.31162106'),
	'identifierSchemeCodeUnspsc190501.31162107': __('identifierSchemeCodeUnspsc190501.31162107'),
	'identifierSchemeCodeUnspsc190501.31162108': __('identifierSchemeCodeUnspsc190501.31162108'),
	'identifierSchemeCodeUnspsc190501.31162109': __('identifierSchemeCodeUnspsc190501.31162109'),
	'identifierSchemeCodeUnspsc190501.31162110': __('identifierSchemeCodeUnspsc190501.31162110'),
	'identifierSchemeCodeUnspsc190501.31162200': __('identifierSchemeCodeUnspsc190501.31162200'),
	'identifierSchemeCodeUnspsc190501.31162201': __('identifierSchemeCodeUnspsc190501.31162201'),
	'identifierSchemeCodeUnspsc190501.31162202': __('identifierSchemeCodeUnspsc190501.31162202'),
	'identifierSchemeCodeUnspsc190501.31162203': __('identifierSchemeCodeUnspsc190501.31162203'),
	'identifierSchemeCodeUnspsc190501.31162204': __('identifierSchemeCodeUnspsc190501.31162204'),
	'identifierSchemeCodeUnspsc190501.31162205': __('identifierSchemeCodeUnspsc190501.31162205'),
	'identifierSchemeCodeUnspsc190501.31162206': __('identifierSchemeCodeUnspsc190501.31162206'),
	'identifierSchemeCodeUnspsc190501.31162207': __('identifierSchemeCodeUnspsc190501.31162207'),
	'identifierSchemeCodeUnspsc190501.31162208': __('identifierSchemeCodeUnspsc190501.31162208'),
	'identifierSchemeCodeUnspsc190501.31162209': __('identifierSchemeCodeUnspsc190501.31162209'),
	'identifierSchemeCodeUnspsc190501.31162210': __('identifierSchemeCodeUnspsc190501.31162210'),
	'identifierSchemeCodeUnspsc190501.31162211': __('identifierSchemeCodeUnspsc190501.31162211'),
	'identifierSchemeCodeUnspsc190501.31162212': __('identifierSchemeCodeUnspsc190501.31162212'),
	'identifierSchemeCodeUnspsc190501.31162213': __('identifierSchemeCodeUnspsc190501.31162213'),
	'identifierSchemeCodeUnspsc190501.31162214': __('identifierSchemeCodeUnspsc190501.31162214'),
	'identifierSchemeCodeUnspsc190501.31162215': __('identifierSchemeCodeUnspsc190501.31162215'),
	'identifierSchemeCodeUnspsc190501.31162216': __('identifierSchemeCodeUnspsc190501.31162216'),
	'identifierSchemeCodeUnspsc190501.31162300': __('identifierSchemeCodeUnspsc190501.31162300'),
	'identifierSchemeCodeUnspsc190501.31162301': __('identifierSchemeCodeUnspsc190501.31162301'),
	'identifierSchemeCodeUnspsc190501.31162303': __('identifierSchemeCodeUnspsc190501.31162303'),
	'identifierSchemeCodeUnspsc190501.31162304': __('identifierSchemeCodeUnspsc190501.31162304'),
	'identifierSchemeCodeUnspsc190501.31162305': __('identifierSchemeCodeUnspsc190501.31162305'),
	'identifierSchemeCodeUnspsc190501.31162306': __('identifierSchemeCodeUnspsc190501.31162306'),
	'identifierSchemeCodeUnspsc190501.31162307': __('identifierSchemeCodeUnspsc190501.31162307'),
	'identifierSchemeCodeUnspsc190501.31162308': __('identifierSchemeCodeUnspsc190501.31162308'),
	'identifierSchemeCodeUnspsc190501.31162309': __('identifierSchemeCodeUnspsc190501.31162309'),
	'identifierSchemeCodeUnspsc190501.31162310': __('identifierSchemeCodeUnspsc190501.31162310'),
	'identifierSchemeCodeUnspsc190501.31162311': __('identifierSchemeCodeUnspsc190501.31162311'),
	'identifierSchemeCodeUnspsc190501.31162312': __('identifierSchemeCodeUnspsc190501.31162312'),
	'identifierSchemeCodeUnspsc190501.31162313': __('identifierSchemeCodeUnspsc190501.31162313'),
	'identifierSchemeCodeUnspsc190501.31162314': __('identifierSchemeCodeUnspsc190501.31162314'),
	'identifierSchemeCodeUnspsc190501.31162400': __('identifierSchemeCodeUnspsc190501.31162400'),
	'identifierSchemeCodeUnspsc190501.31162401': __('identifierSchemeCodeUnspsc190501.31162401'),
	'identifierSchemeCodeUnspsc190501.31162402': __('identifierSchemeCodeUnspsc190501.31162402'),
	'identifierSchemeCodeUnspsc190501.31162403': __('identifierSchemeCodeUnspsc190501.31162403'),
	'identifierSchemeCodeUnspsc190501.31162404': __('identifierSchemeCodeUnspsc190501.31162404'),
	'identifierSchemeCodeUnspsc190501.31162405': __('identifierSchemeCodeUnspsc190501.31162405'),
	'identifierSchemeCodeUnspsc190501.31162406': __('identifierSchemeCodeUnspsc190501.31162406'),
	'identifierSchemeCodeUnspsc190501.31162407': __('identifierSchemeCodeUnspsc190501.31162407'),
	'identifierSchemeCodeUnspsc190501.31162409': __('identifierSchemeCodeUnspsc190501.31162409'),
	'identifierSchemeCodeUnspsc190501.31162410': __('identifierSchemeCodeUnspsc190501.31162410'),
	'identifierSchemeCodeUnspsc190501.31162411': __('identifierSchemeCodeUnspsc190501.31162411'),
	'identifierSchemeCodeUnspsc190501.31162412': __('identifierSchemeCodeUnspsc190501.31162412'),
	'identifierSchemeCodeUnspsc190501.31162413': __('identifierSchemeCodeUnspsc190501.31162413'),
	'identifierSchemeCodeUnspsc190501.31162414': __('identifierSchemeCodeUnspsc190501.31162414'),
	'identifierSchemeCodeUnspsc190501.31162415': __('identifierSchemeCodeUnspsc190501.31162415'),
	'identifierSchemeCodeUnspsc190501.31162416': __('identifierSchemeCodeUnspsc190501.31162416'),
	'identifierSchemeCodeUnspsc190501.31162417': __('identifierSchemeCodeUnspsc190501.31162417'),
	'identifierSchemeCodeUnspsc190501.31162418': __('identifierSchemeCodeUnspsc190501.31162418'),
	'identifierSchemeCodeUnspsc190501.31162419': __('identifierSchemeCodeUnspsc190501.31162419'),
	'identifierSchemeCodeUnspsc190501.31162420': __('identifierSchemeCodeUnspsc190501.31162420'),
	'identifierSchemeCodeUnspsc190501.31162421': __('identifierSchemeCodeUnspsc190501.31162421'),
	'identifierSchemeCodeUnspsc190501.31162500': __('identifierSchemeCodeUnspsc190501.31162500'),
	'identifierSchemeCodeUnspsc190501.31162501': __('identifierSchemeCodeUnspsc190501.31162501'),
	'identifierSchemeCodeUnspsc190501.31162502': __('identifierSchemeCodeUnspsc190501.31162502'),
	'identifierSchemeCodeUnspsc190501.31162503': __('identifierSchemeCodeUnspsc190501.31162503'),
	'identifierSchemeCodeUnspsc190501.31162504': __('identifierSchemeCodeUnspsc190501.31162504'),
	'identifierSchemeCodeUnspsc190501.31162505': __('identifierSchemeCodeUnspsc190501.31162505'),
	'identifierSchemeCodeUnspsc190501.31162506': __('identifierSchemeCodeUnspsc190501.31162506'),
	'identifierSchemeCodeUnspsc190501.31162507': __('identifierSchemeCodeUnspsc190501.31162507'),
	'identifierSchemeCodeUnspsc190501.31162508': __('identifierSchemeCodeUnspsc190501.31162508'),
	'identifierSchemeCodeUnspsc190501.31162600': __('identifierSchemeCodeUnspsc190501.31162600'),
	'identifierSchemeCodeUnspsc190501.31162601': __('identifierSchemeCodeUnspsc190501.31162601'),
	'identifierSchemeCodeUnspsc190501.31162602': __('identifierSchemeCodeUnspsc190501.31162602'),
	'identifierSchemeCodeUnspsc190501.31162603': __('identifierSchemeCodeUnspsc190501.31162603'),
	'identifierSchemeCodeUnspsc190501.31162604': __('identifierSchemeCodeUnspsc190501.31162604'),
	'identifierSchemeCodeUnspsc190501.31162605': __('identifierSchemeCodeUnspsc190501.31162605'),
	'identifierSchemeCodeUnspsc190501.31162606': __('identifierSchemeCodeUnspsc190501.31162606'),
	'identifierSchemeCodeUnspsc190501.31162607': __('identifierSchemeCodeUnspsc190501.31162607'),
	'identifierSchemeCodeUnspsc190501.31162608': __('identifierSchemeCodeUnspsc190501.31162608'),
	'identifierSchemeCodeUnspsc190501.31162609': __('identifierSchemeCodeUnspsc190501.31162609'),
	'identifierSchemeCodeUnspsc190501.31162610': __('identifierSchemeCodeUnspsc190501.31162610'),
	'identifierSchemeCodeUnspsc190501.31162611': __('identifierSchemeCodeUnspsc190501.31162611'),
	'identifierSchemeCodeUnspsc190501.31162612': __('identifierSchemeCodeUnspsc190501.31162612'),
	'identifierSchemeCodeUnspsc190501.31162613': __('identifierSchemeCodeUnspsc190501.31162613'),
	'identifierSchemeCodeUnspsc190501.31162614': __('identifierSchemeCodeUnspsc190501.31162614'),
	'identifierSchemeCodeUnspsc190501.31162700': __('identifierSchemeCodeUnspsc190501.31162700'),
	'identifierSchemeCodeUnspsc190501.31162701': __('identifierSchemeCodeUnspsc190501.31162701'),
	'identifierSchemeCodeUnspsc190501.31162702': __('identifierSchemeCodeUnspsc190501.31162702'),
	'identifierSchemeCodeUnspsc190501.31162703': __('identifierSchemeCodeUnspsc190501.31162703'),
	'identifierSchemeCodeUnspsc190501.31162704': __('identifierSchemeCodeUnspsc190501.31162704'),
	'identifierSchemeCodeUnspsc190501.31162800': __('identifierSchemeCodeUnspsc190501.31162800'),
	'identifierSchemeCodeUnspsc190501.31162801': __('identifierSchemeCodeUnspsc190501.31162801'),
	'identifierSchemeCodeUnspsc190501.31162802': __('identifierSchemeCodeUnspsc190501.31162802'),
	'identifierSchemeCodeUnspsc190501.31162804': __('identifierSchemeCodeUnspsc190501.31162804'),
	'identifierSchemeCodeUnspsc190501.31162805': __('identifierSchemeCodeUnspsc190501.31162805'),
	'identifierSchemeCodeUnspsc190501.31162806': __('identifierSchemeCodeUnspsc190501.31162806'),
	'identifierSchemeCodeUnspsc190501.31162807': __('identifierSchemeCodeUnspsc190501.31162807'),
	'identifierSchemeCodeUnspsc190501.31162808': __('identifierSchemeCodeUnspsc190501.31162808'),
	'identifierSchemeCodeUnspsc190501.31162809': __('identifierSchemeCodeUnspsc190501.31162809'),
	'identifierSchemeCodeUnspsc190501.31162810': __('identifierSchemeCodeUnspsc190501.31162810'),
	'identifierSchemeCodeUnspsc190501.31162811': __('identifierSchemeCodeUnspsc190501.31162811'),
	'identifierSchemeCodeUnspsc190501.31162812': __('identifierSchemeCodeUnspsc190501.31162812'),
	'identifierSchemeCodeUnspsc190501.31162813': __('identifierSchemeCodeUnspsc190501.31162813'),
	'identifierSchemeCodeUnspsc190501.31162814': __('identifierSchemeCodeUnspsc190501.31162814'),
	'identifierSchemeCodeUnspsc190501.31162815': __('identifierSchemeCodeUnspsc190501.31162815'),
	'identifierSchemeCodeUnspsc190501.31162816': __('identifierSchemeCodeUnspsc190501.31162816'),
	'identifierSchemeCodeUnspsc190501.31162817': __('identifierSchemeCodeUnspsc190501.31162817'),
	'identifierSchemeCodeUnspsc190501.31162818': __('identifierSchemeCodeUnspsc190501.31162818'),
	'identifierSchemeCodeUnspsc190501.31162819': __('identifierSchemeCodeUnspsc190501.31162819'),
	'identifierSchemeCodeUnspsc190501.31162900': __('identifierSchemeCodeUnspsc190501.31162900'),
	'identifierSchemeCodeUnspsc190501.31162901': __('identifierSchemeCodeUnspsc190501.31162901'),
	'identifierSchemeCodeUnspsc190501.31162902': __('identifierSchemeCodeUnspsc190501.31162902'),
	'identifierSchemeCodeUnspsc190501.31162903': __('identifierSchemeCodeUnspsc190501.31162903'),
	'identifierSchemeCodeUnspsc190501.31162904': __('identifierSchemeCodeUnspsc190501.31162904'),
	'identifierSchemeCodeUnspsc190501.31162905': __('identifierSchemeCodeUnspsc190501.31162905'),
	'identifierSchemeCodeUnspsc190501.31162906': __('identifierSchemeCodeUnspsc190501.31162906'),
	'identifierSchemeCodeUnspsc190501.31162907': __('identifierSchemeCodeUnspsc190501.31162907'),
	'identifierSchemeCodeUnspsc190501.31162908': __('identifierSchemeCodeUnspsc190501.31162908'),
	'identifierSchemeCodeUnspsc190501.31162909': __('identifierSchemeCodeUnspsc190501.31162909'),
	'identifierSchemeCodeUnspsc190501.31162910': __('identifierSchemeCodeUnspsc190501.31162910'),
	'identifierSchemeCodeUnspsc190501.31162911': __('identifierSchemeCodeUnspsc190501.31162911'),
	'identifierSchemeCodeUnspsc190501.31162912': __('identifierSchemeCodeUnspsc190501.31162912'),
	'identifierSchemeCodeUnspsc190501.31162913': __('identifierSchemeCodeUnspsc190501.31162913'),
	'identifierSchemeCodeUnspsc190501.31162914': __('identifierSchemeCodeUnspsc190501.31162914'),
	'identifierSchemeCodeUnspsc190501.31162915': __('identifierSchemeCodeUnspsc190501.31162915'),
	'identifierSchemeCodeUnspsc190501.31162916': __('identifierSchemeCodeUnspsc190501.31162916'),
	'identifierSchemeCodeUnspsc190501.31162917': __('identifierSchemeCodeUnspsc190501.31162917'),
	'identifierSchemeCodeUnspsc190501.31162918': __('identifierSchemeCodeUnspsc190501.31162918'),
	'identifierSchemeCodeUnspsc190501.31162919': __('identifierSchemeCodeUnspsc190501.31162919'),
	'identifierSchemeCodeUnspsc190501.31162920': __('identifierSchemeCodeUnspsc190501.31162920'),
	'identifierSchemeCodeUnspsc190501.31163000': __('identifierSchemeCodeUnspsc190501.31163000'),
	'identifierSchemeCodeUnspsc190501.31163001': __('identifierSchemeCodeUnspsc190501.31163001'),
	'identifierSchemeCodeUnspsc190501.31163002': __('identifierSchemeCodeUnspsc190501.31163002'),
	'identifierSchemeCodeUnspsc190501.31163003': __('identifierSchemeCodeUnspsc190501.31163003'),
	'identifierSchemeCodeUnspsc190501.31163004': __('identifierSchemeCodeUnspsc190501.31163004'),
	'identifierSchemeCodeUnspsc190501.31163005': __('identifierSchemeCodeUnspsc190501.31163005'),
	'identifierSchemeCodeUnspsc190501.31163006': __('identifierSchemeCodeUnspsc190501.31163006'),
	'identifierSchemeCodeUnspsc190501.31163007': __('identifierSchemeCodeUnspsc190501.31163007'),
	'identifierSchemeCodeUnspsc190501.31163008': __('identifierSchemeCodeUnspsc190501.31163008'),
	'identifierSchemeCodeUnspsc190501.31163009': __('identifierSchemeCodeUnspsc190501.31163009'),
	'identifierSchemeCodeUnspsc190501.31163010': __('identifierSchemeCodeUnspsc190501.31163010'),
	'identifierSchemeCodeUnspsc190501.31163011': __('identifierSchemeCodeUnspsc190501.31163011'),
	'identifierSchemeCodeUnspsc190501.31163012': __('identifierSchemeCodeUnspsc190501.31163012'),
	'identifierSchemeCodeUnspsc190501.31163013': __('identifierSchemeCodeUnspsc190501.31163013'),
	'identifierSchemeCodeUnspsc190501.31163014': __('identifierSchemeCodeUnspsc190501.31163014'),
	'identifierSchemeCodeUnspsc190501.31163015': __('identifierSchemeCodeUnspsc190501.31163015'),
	'identifierSchemeCodeUnspsc190501.31163016': __('identifierSchemeCodeUnspsc190501.31163016'),
	'identifierSchemeCodeUnspsc190501.31163017': __('identifierSchemeCodeUnspsc190501.31163017'),
	'identifierSchemeCodeUnspsc190501.31163018': __('identifierSchemeCodeUnspsc190501.31163018'),
	'identifierSchemeCodeUnspsc190501.31163019': __('identifierSchemeCodeUnspsc190501.31163019'),
	'identifierSchemeCodeUnspsc190501.31163020': __('identifierSchemeCodeUnspsc190501.31163020'),
	'identifierSchemeCodeUnspsc190501.31163021': __('identifierSchemeCodeUnspsc190501.31163021'),
	'identifierSchemeCodeUnspsc190501.31163022': __('identifierSchemeCodeUnspsc190501.31163022'),
	'identifierSchemeCodeUnspsc190501.31163023': __('identifierSchemeCodeUnspsc190501.31163023'),
	'identifierSchemeCodeUnspsc190501.31163024': __('identifierSchemeCodeUnspsc190501.31163024'),
	'identifierSchemeCodeUnspsc190501.31163025': __('identifierSchemeCodeUnspsc190501.31163025'),
	'identifierSchemeCodeUnspsc190501.31163026': __('identifierSchemeCodeUnspsc190501.31163026'),
	'identifierSchemeCodeUnspsc190501.31163027': __('identifierSchemeCodeUnspsc190501.31163027'),
	'identifierSchemeCodeUnspsc190501.31163028': __('identifierSchemeCodeUnspsc190501.31163028'),
	'identifierSchemeCodeUnspsc190501.31163029': __('identifierSchemeCodeUnspsc190501.31163029'),
	'identifierSchemeCodeUnspsc190501.31163030': __('identifierSchemeCodeUnspsc190501.31163030'),
	'identifierSchemeCodeUnspsc190501.31163031': __('identifierSchemeCodeUnspsc190501.31163031'),
	'identifierSchemeCodeUnspsc190501.31163032': __('identifierSchemeCodeUnspsc190501.31163032'),
	'identifierSchemeCodeUnspsc190501.31163100': __('identifierSchemeCodeUnspsc190501.31163100'),
	'identifierSchemeCodeUnspsc190501.31163101': __('identifierSchemeCodeUnspsc190501.31163101'),
	'identifierSchemeCodeUnspsc190501.31163102': __('identifierSchemeCodeUnspsc190501.31163102'),
	'identifierSchemeCodeUnspsc190501.31163103': __('identifierSchemeCodeUnspsc190501.31163103'),
	'identifierSchemeCodeUnspsc190501.31163200': __('identifierSchemeCodeUnspsc190501.31163200'),
	'identifierSchemeCodeUnspsc190501.31163201': __('identifierSchemeCodeUnspsc190501.31163201'),
	'identifierSchemeCodeUnspsc190501.31163202': __('identifierSchemeCodeUnspsc190501.31163202'),
	'identifierSchemeCodeUnspsc190501.31163203': __('identifierSchemeCodeUnspsc190501.31163203'),
	'identifierSchemeCodeUnspsc190501.31163204': __('identifierSchemeCodeUnspsc190501.31163204'),
	'identifierSchemeCodeUnspsc190501.31163205': __('identifierSchemeCodeUnspsc190501.31163205'),
	'identifierSchemeCodeUnspsc190501.31163207': __('identifierSchemeCodeUnspsc190501.31163207'),
	'identifierSchemeCodeUnspsc190501.31163208': __('identifierSchemeCodeUnspsc190501.31163208'),
	'identifierSchemeCodeUnspsc190501.31163209': __('identifierSchemeCodeUnspsc190501.31163209'),
	'identifierSchemeCodeUnspsc190501.31163210': __('identifierSchemeCodeUnspsc190501.31163210'),
	'identifierSchemeCodeUnspsc190501.31163211': __('identifierSchemeCodeUnspsc190501.31163211'),
	'identifierSchemeCodeUnspsc190501.31163212': __('identifierSchemeCodeUnspsc190501.31163212'),
	'identifierSchemeCodeUnspsc190501.31163213': __('identifierSchemeCodeUnspsc190501.31163213'),
	'identifierSchemeCodeUnspsc190501.31163214': __('identifierSchemeCodeUnspsc190501.31163214'),
	'identifierSchemeCodeUnspsc190501.31163215': __('identifierSchemeCodeUnspsc190501.31163215'),
	'identifierSchemeCodeUnspsc190501.31163216': __('identifierSchemeCodeUnspsc190501.31163216'),
	'identifierSchemeCodeUnspsc190501.31163217': __('identifierSchemeCodeUnspsc190501.31163217'),
	'identifierSchemeCodeUnspsc190501.31163218': __('identifierSchemeCodeUnspsc190501.31163218'),
	'identifierSchemeCodeUnspsc190501.31163219': __('identifierSchemeCodeUnspsc190501.31163219'),
	'identifierSchemeCodeUnspsc190501.31163220': __('identifierSchemeCodeUnspsc190501.31163220'),
	'identifierSchemeCodeUnspsc190501.31163221': __('identifierSchemeCodeUnspsc190501.31163221'),
	'identifierSchemeCodeUnspsc190501.31163222': __('identifierSchemeCodeUnspsc190501.31163222'),
	'identifierSchemeCodeUnspsc190501.31163223': __('identifierSchemeCodeUnspsc190501.31163223'),
	'identifierSchemeCodeUnspsc190501.31163224': __('identifierSchemeCodeUnspsc190501.31163224'),
	'identifierSchemeCodeUnspsc190501.31163225': __('identifierSchemeCodeUnspsc190501.31163225'),
	'identifierSchemeCodeUnspsc190501.31163226': __('identifierSchemeCodeUnspsc190501.31163226'),
	'identifierSchemeCodeUnspsc190501.31163227': __('identifierSchemeCodeUnspsc190501.31163227'),
	'identifierSchemeCodeUnspsc190501.31163228': __('identifierSchemeCodeUnspsc190501.31163228'),
	'identifierSchemeCodeUnspsc190501.31163229': __('identifierSchemeCodeUnspsc190501.31163229'),
	'identifierSchemeCodeUnspsc190501.31163230': __('identifierSchemeCodeUnspsc190501.31163230'),
	'identifierSchemeCodeUnspsc190501.31163231': __('identifierSchemeCodeUnspsc190501.31163231'),
	'identifierSchemeCodeUnspsc190501.31163300': __('identifierSchemeCodeUnspsc190501.31163300'),
	'identifierSchemeCodeUnspsc190501.31163301': __('identifierSchemeCodeUnspsc190501.31163301'),
	'identifierSchemeCodeUnspsc190501.31163302': __('identifierSchemeCodeUnspsc190501.31163302'),
	'identifierSchemeCodeUnspsc190501.31163303': __('identifierSchemeCodeUnspsc190501.31163303'),
	'identifierSchemeCodeUnspsc190501.31163304': __('identifierSchemeCodeUnspsc190501.31163304'),
	'identifierSchemeCodeUnspsc190501.31163400': __('identifierSchemeCodeUnspsc190501.31163400'),
	'identifierSchemeCodeUnspsc190501.31163401': __('identifierSchemeCodeUnspsc190501.31163401'),
	'identifierSchemeCodeUnspsc190501.31170000': __('identifierSchemeCodeUnspsc190501.31170000'),
	'identifierSchemeCodeUnspsc190501.31171500': __('identifierSchemeCodeUnspsc190501.31171500'),
	'identifierSchemeCodeUnspsc190501.31171501': __('identifierSchemeCodeUnspsc190501.31171501'),
	'identifierSchemeCodeUnspsc190501.31171502': __('identifierSchemeCodeUnspsc190501.31171502'),
	'identifierSchemeCodeUnspsc190501.31171503': __('identifierSchemeCodeUnspsc190501.31171503'),
	'identifierSchemeCodeUnspsc190501.31171504': __('identifierSchemeCodeUnspsc190501.31171504'),
	'identifierSchemeCodeUnspsc190501.31171505': __('identifierSchemeCodeUnspsc190501.31171505'),
	'identifierSchemeCodeUnspsc190501.31171506': __('identifierSchemeCodeUnspsc190501.31171506'),
	'identifierSchemeCodeUnspsc190501.31171507': __('identifierSchemeCodeUnspsc190501.31171507'),
	'identifierSchemeCodeUnspsc190501.31171508': __('identifierSchemeCodeUnspsc190501.31171508'),
	'identifierSchemeCodeUnspsc190501.31171509': __('identifierSchemeCodeUnspsc190501.31171509'),
	'identifierSchemeCodeUnspsc190501.31171510': __('identifierSchemeCodeUnspsc190501.31171510'),
	'identifierSchemeCodeUnspsc190501.31171511': __('identifierSchemeCodeUnspsc190501.31171511'),
	'identifierSchemeCodeUnspsc190501.31171512': __('identifierSchemeCodeUnspsc190501.31171512'),
	'identifierSchemeCodeUnspsc190501.31171513': __('identifierSchemeCodeUnspsc190501.31171513'),
	'identifierSchemeCodeUnspsc190501.31171515': __('identifierSchemeCodeUnspsc190501.31171515'),
	'identifierSchemeCodeUnspsc190501.31171516': __('identifierSchemeCodeUnspsc190501.31171516'),
	'identifierSchemeCodeUnspsc190501.31171518': __('identifierSchemeCodeUnspsc190501.31171518'),
	'identifierSchemeCodeUnspsc190501.31171519': __('identifierSchemeCodeUnspsc190501.31171519'),
	'identifierSchemeCodeUnspsc190501.31171520': __('identifierSchemeCodeUnspsc190501.31171520'),
	'identifierSchemeCodeUnspsc190501.31171522': __('identifierSchemeCodeUnspsc190501.31171522'),
	'identifierSchemeCodeUnspsc190501.31171523': __('identifierSchemeCodeUnspsc190501.31171523'),
	'identifierSchemeCodeUnspsc190501.31171524': __('identifierSchemeCodeUnspsc190501.31171524'),
	'identifierSchemeCodeUnspsc190501.31171525': __('identifierSchemeCodeUnspsc190501.31171525'),
	'identifierSchemeCodeUnspsc190501.31171526': __('identifierSchemeCodeUnspsc190501.31171526'),
	'identifierSchemeCodeUnspsc190501.31171527': __('identifierSchemeCodeUnspsc190501.31171527'),
	'identifierSchemeCodeUnspsc190501.31171528': __('identifierSchemeCodeUnspsc190501.31171528'),
	'identifierSchemeCodeUnspsc190501.31171529': __('identifierSchemeCodeUnspsc190501.31171529'),
	'identifierSchemeCodeUnspsc190501.31171530': __('identifierSchemeCodeUnspsc190501.31171530'),
	'identifierSchemeCodeUnspsc190501.31171531': __('identifierSchemeCodeUnspsc190501.31171531'),
	'identifierSchemeCodeUnspsc190501.31171532': __('identifierSchemeCodeUnspsc190501.31171532'),
	'identifierSchemeCodeUnspsc190501.31171533': __('identifierSchemeCodeUnspsc190501.31171533'),
	'identifierSchemeCodeUnspsc190501.31171534': __('identifierSchemeCodeUnspsc190501.31171534'),
	'identifierSchemeCodeUnspsc190501.31171535': __('identifierSchemeCodeUnspsc190501.31171535'),
	'identifierSchemeCodeUnspsc190501.31171536': __('identifierSchemeCodeUnspsc190501.31171536'),
	'identifierSchemeCodeUnspsc190501.31171537': __('identifierSchemeCodeUnspsc190501.31171537'),
	'identifierSchemeCodeUnspsc190501.31171538': __('identifierSchemeCodeUnspsc190501.31171538'),
	'identifierSchemeCodeUnspsc190501.31171539': __('identifierSchemeCodeUnspsc190501.31171539'),
	'identifierSchemeCodeUnspsc190501.31171540': __('identifierSchemeCodeUnspsc190501.31171540'),
	'identifierSchemeCodeUnspsc190501.31171541': __('identifierSchemeCodeUnspsc190501.31171541'),
	'identifierSchemeCodeUnspsc190501.31171542': __('identifierSchemeCodeUnspsc190501.31171542'),
	'identifierSchemeCodeUnspsc190501.31171543': __('identifierSchemeCodeUnspsc190501.31171543'),
	'identifierSchemeCodeUnspsc190501.31171544': __('identifierSchemeCodeUnspsc190501.31171544'),
	'identifierSchemeCodeUnspsc190501.31171545': __('identifierSchemeCodeUnspsc190501.31171545'),
	'identifierSchemeCodeUnspsc190501.31171546': __('identifierSchemeCodeUnspsc190501.31171546'),
	'identifierSchemeCodeUnspsc190501.31171547': __('identifierSchemeCodeUnspsc190501.31171547'),
	'identifierSchemeCodeUnspsc190501.31171548': __('identifierSchemeCodeUnspsc190501.31171548'),
	'identifierSchemeCodeUnspsc190501.31171549': __('identifierSchemeCodeUnspsc190501.31171549'),
	'identifierSchemeCodeUnspsc190501.31171550': __('identifierSchemeCodeUnspsc190501.31171550'),
	'identifierSchemeCodeUnspsc190501.31171551': __('identifierSchemeCodeUnspsc190501.31171551'),
	'identifierSchemeCodeUnspsc190501.31171552': __('identifierSchemeCodeUnspsc190501.31171552'),
	'identifierSchemeCodeUnspsc190501.31171553': __('identifierSchemeCodeUnspsc190501.31171553'),
	'identifierSchemeCodeUnspsc190501.31171554': __('identifierSchemeCodeUnspsc190501.31171554'),
	'identifierSchemeCodeUnspsc190501.31171555': __('identifierSchemeCodeUnspsc190501.31171555'),
	'identifierSchemeCodeUnspsc190501.31171556': __('identifierSchemeCodeUnspsc190501.31171556'),
	'identifierSchemeCodeUnspsc190501.31171557': __('identifierSchemeCodeUnspsc190501.31171557'),
	'identifierSchemeCodeUnspsc190501.31171558': __('identifierSchemeCodeUnspsc190501.31171558'),
	'identifierSchemeCodeUnspsc190501.31171559': __('identifierSchemeCodeUnspsc190501.31171559'),
	'identifierSchemeCodeUnspsc190501.31171560': __('identifierSchemeCodeUnspsc190501.31171560'),
	'identifierSchemeCodeUnspsc190501.31171561': __('identifierSchemeCodeUnspsc190501.31171561'),
	'identifierSchemeCodeUnspsc190501.31171562': __('identifierSchemeCodeUnspsc190501.31171562'),
	'identifierSchemeCodeUnspsc190501.31171563': __('identifierSchemeCodeUnspsc190501.31171563'),
	'identifierSchemeCodeUnspsc190501.31171564': __('identifierSchemeCodeUnspsc190501.31171564'),
	'identifierSchemeCodeUnspsc190501.31171600': __('identifierSchemeCodeUnspsc190501.31171600'),
	'identifierSchemeCodeUnspsc190501.31171603': __('identifierSchemeCodeUnspsc190501.31171603'),
	'identifierSchemeCodeUnspsc190501.31171604': __('identifierSchemeCodeUnspsc190501.31171604'),
	'identifierSchemeCodeUnspsc190501.31171605': __('identifierSchemeCodeUnspsc190501.31171605'),
	'identifierSchemeCodeUnspsc190501.31171606': __('identifierSchemeCodeUnspsc190501.31171606'),
	'identifierSchemeCodeUnspsc190501.31171607': __('identifierSchemeCodeUnspsc190501.31171607'),
	'identifierSchemeCodeUnspsc190501.31171608': __('identifierSchemeCodeUnspsc190501.31171608'),
	'identifierSchemeCodeUnspsc190501.31171609': __('identifierSchemeCodeUnspsc190501.31171609'),
	'identifierSchemeCodeUnspsc190501.31171610': __('identifierSchemeCodeUnspsc190501.31171610'),
	'identifierSchemeCodeUnspsc190501.31171700': __('identifierSchemeCodeUnspsc190501.31171700'),
	'identifierSchemeCodeUnspsc190501.31171704': __('identifierSchemeCodeUnspsc190501.31171704'),
	'identifierSchemeCodeUnspsc190501.31171706': __('identifierSchemeCodeUnspsc190501.31171706'),
	'identifierSchemeCodeUnspsc190501.31171707': __('identifierSchemeCodeUnspsc190501.31171707'),
	'identifierSchemeCodeUnspsc190501.31171708': __('identifierSchemeCodeUnspsc190501.31171708'),
	'identifierSchemeCodeUnspsc190501.31171709': __('identifierSchemeCodeUnspsc190501.31171709'),
	'identifierSchemeCodeUnspsc190501.31171710': __('identifierSchemeCodeUnspsc190501.31171710'),
	'identifierSchemeCodeUnspsc190501.31171711': __('identifierSchemeCodeUnspsc190501.31171711'),
	'identifierSchemeCodeUnspsc190501.31171712': __('identifierSchemeCodeUnspsc190501.31171712'),
	'identifierSchemeCodeUnspsc190501.31171713': __('identifierSchemeCodeUnspsc190501.31171713'),
	'identifierSchemeCodeUnspsc190501.31171714': __('identifierSchemeCodeUnspsc190501.31171714'),
	'identifierSchemeCodeUnspsc190501.31171715': __('identifierSchemeCodeUnspsc190501.31171715'),
	'identifierSchemeCodeUnspsc190501.31171716': __('identifierSchemeCodeUnspsc190501.31171716'),
	'identifierSchemeCodeUnspsc190501.31171800': __('identifierSchemeCodeUnspsc190501.31171800'),
	'identifierSchemeCodeUnspsc190501.31171801': __('identifierSchemeCodeUnspsc190501.31171801'),
	'identifierSchemeCodeUnspsc190501.31171802': __('identifierSchemeCodeUnspsc190501.31171802'),
	'identifierSchemeCodeUnspsc190501.31171803': __('identifierSchemeCodeUnspsc190501.31171803'),
	'identifierSchemeCodeUnspsc190501.31171804': __('identifierSchemeCodeUnspsc190501.31171804'),
	'identifierSchemeCodeUnspsc190501.31171805': __('identifierSchemeCodeUnspsc190501.31171805'),
	'identifierSchemeCodeUnspsc190501.31171806': __('identifierSchemeCodeUnspsc190501.31171806'),
	'identifierSchemeCodeUnspsc190501.31171900': __('identifierSchemeCodeUnspsc190501.31171900'),
	'identifierSchemeCodeUnspsc190501.31171901': __('identifierSchemeCodeUnspsc190501.31171901'),
	'identifierSchemeCodeUnspsc190501.31171902': __('identifierSchemeCodeUnspsc190501.31171902'),
	'identifierSchemeCodeUnspsc190501.31171903': __('identifierSchemeCodeUnspsc190501.31171903'),
	'identifierSchemeCodeUnspsc190501.31171904': __('identifierSchemeCodeUnspsc190501.31171904'),
	'identifierSchemeCodeUnspsc190501.31171905': __('identifierSchemeCodeUnspsc190501.31171905'),
	'identifierSchemeCodeUnspsc190501.31180000': __('identifierSchemeCodeUnspsc190501.31180000'),
	'identifierSchemeCodeUnspsc190501.31181700': __('identifierSchemeCodeUnspsc190501.31181700'),
	'identifierSchemeCodeUnspsc190501.31181701': __('identifierSchemeCodeUnspsc190501.31181701'),
	'identifierSchemeCodeUnspsc190501.31181702': __('identifierSchemeCodeUnspsc190501.31181702'),
	'identifierSchemeCodeUnspsc190501.31181703': __('identifierSchemeCodeUnspsc190501.31181703'),
	'identifierSchemeCodeUnspsc190501.31181704': __('identifierSchemeCodeUnspsc190501.31181704'),
	'identifierSchemeCodeUnspsc190501.31181705': __('identifierSchemeCodeUnspsc190501.31181705'),
	'identifierSchemeCodeUnspsc190501.31181800': __('identifierSchemeCodeUnspsc190501.31181800'),
	'identifierSchemeCodeUnspsc190501.31181801': __('identifierSchemeCodeUnspsc190501.31181801'),
	'identifierSchemeCodeUnspsc190501.31181802': __('identifierSchemeCodeUnspsc190501.31181802'),
	'identifierSchemeCodeUnspsc190501.31190000': __('identifierSchemeCodeUnspsc190501.31190000'),
	'identifierSchemeCodeUnspsc190501.31191500': __('identifierSchemeCodeUnspsc190501.31191500'),
	'identifierSchemeCodeUnspsc190501.31191501': __('identifierSchemeCodeUnspsc190501.31191501'),
	'identifierSchemeCodeUnspsc190501.31191502': __('identifierSchemeCodeUnspsc190501.31191502'),
	'identifierSchemeCodeUnspsc190501.31191504': __('identifierSchemeCodeUnspsc190501.31191504'),
	'identifierSchemeCodeUnspsc190501.31191505': __('identifierSchemeCodeUnspsc190501.31191505'),
	'identifierSchemeCodeUnspsc190501.31191506': __('identifierSchemeCodeUnspsc190501.31191506'),
	'identifierSchemeCodeUnspsc190501.31191507': __('identifierSchemeCodeUnspsc190501.31191507'),
	'identifierSchemeCodeUnspsc190501.31191508': __('identifierSchemeCodeUnspsc190501.31191508'),
	'identifierSchemeCodeUnspsc190501.31191509': __('identifierSchemeCodeUnspsc190501.31191509'),
	'identifierSchemeCodeUnspsc190501.31191510': __('identifierSchemeCodeUnspsc190501.31191510'),
	'identifierSchemeCodeUnspsc190501.31191511': __('identifierSchemeCodeUnspsc190501.31191511'),
	'identifierSchemeCodeUnspsc190501.31191512': __('identifierSchemeCodeUnspsc190501.31191512'),
	'identifierSchemeCodeUnspsc190501.31191513': __('identifierSchemeCodeUnspsc190501.31191513'),
	'identifierSchemeCodeUnspsc190501.31191514': __('identifierSchemeCodeUnspsc190501.31191514'),
	'identifierSchemeCodeUnspsc190501.31191515': __('identifierSchemeCodeUnspsc190501.31191515'),
	'identifierSchemeCodeUnspsc190501.31191516': __('identifierSchemeCodeUnspsc190501.31191516'),
	'identifierSchemeCodeUnspsc190501.31191517': __('identifierSchemeCodeUnspsc190501.31191517'),
	'identifierSchemeCodeUnspsc190501.31191518': __('identifierSchemeCodeUnspsc190501.31191518'),
	'identifierSchemeCodeUnspsc190501.31191519': __('identifierSchemeCodeUnspsc190501.31191519'),
	'identifierSchemeCodeUnspsc190501.31191520': __('identifierSchemeCodeUnspsc190501.31191520'),
	'identifierSchemeCodeUnspsc190501.31191521': __('identifierSchemeCodeUnspsc190501.31191521'),
	'identifierSchemeCodeUnspsc190501.31191600': __('identifierSchemeCodeUnspsc190501.31191600'),
	'identifierSchemeCodeUnspsc190501.31191601': __('identifierSchemeCodeUnspsc190501.31191601'),
	'identifierSchemeCodeUnspsc190501.31191602': __('identifierSchemeCodeUnspsc190501.31191602'),
	'identifierSchemeCodeUnspsc190501.31191603': __('identifierSchemeCodeUnspsc190501.31191603'),
	'identifierSchemeCodeUnspsc190501.31200000': __('identifierSchemeCodeUnspsc190501.31200000'),
	'identifierSchemeCodeUnspsc190501.31201500': __('identifierSchemeCodeUnspsc190501.31201500'),
	'identifierSchemeCodeUnspsc190501.31201501': __('identifierSchemeCodeUnspsc190501.31201501'),
	'identifierSchemeCodeUnspsc190501.31201502': __('identifierSchemeCodeUnspsc190501.31201502'),
	'identifierSchemeCodeUnspsc190501.31201503': __('identifierSchemeCodeUnspsc190501.31201503'),
	'identifierSchemeCodeUnspsc190501.31201504': __('identifierSchemeCodeUnspsc190501.31201504'),
	'identifierSchemeCodeUnspsc190501.31201505': __('identifierSchemeCodeUnspsc190501.31201505'),
	'identifierSchemeCodeUnspsc190501.31201506': __('identifierSchemeCodeUnspsc190501.31201506'),
	'identifierSchemeCodeUnspsc190501.31201507': __('identifierSchemeCodeUnspsc190501.31201507'),
	'identifierSchemeCodeUnspsc190501.31201508': __('identifierSchemeCodeUnspsc190501.31201508'),
	'identifierSchemeCodeUnspsc190501.31201509': __('identifierSchemeCodeUnspsc190501.31201509'),
	'identifierSchemeCodeUnspsc190501.31201510': __('identifierSchemeCodeUnspsc190501.31201510'),
	'identifierSchemeCodeUnspsc190501.31201511': __('identifierSchemeCodeUnspsc190501.31201511'),
	'identifierSchemeCodeUnspsc190501.31201512': __('identifierSchemeCodeUnspsc190501.31201512'),
	'identifierSchemeCodeUnspsc190501.31201513': __('identifierSchemeCodeUnspsc190501.31201513'),
	'identifierSchemeCodeUnspsc190501.31201514': __('identifierSchemeCodeUnspsc190501.31201514'),
	'identifierSchemeCodeUnspsc190501.31201515': __('identifierSchemeCodeUnspsc190501.31201515'),
	'identifierSchemeCodeUnspsc190501.31201516': __('identifierSchemeCodeUnspsc190501.31201516'),
	'identifierSchemeCodeUnspsc190501.31201517': __('identifierSchemeCodeUnspsc190501.31201517'),
	'identifierSchemeCodeUnspsc190501.31201518': __('identifierSchemeCodeUnspsc190501.31201518'),
	'identifierSchemeCodeUnspsc190501.31201519': __('identifierSchemeCodeUnspsc190501.31201519'),
	'identifierSchemeCodeUnspsc190501.31201520': __('identifierSchemeCodeUnspsc190501.31201520'),
	'identifierSchemeCodeUnspsc190501.31201521': __('identifierSchemeCodeUnspsc190501.31201521'),
	'identifierSchemeCodeUnspsc190501.31201522': __('identifierSchemeCodeUnspsc190501.31201522'),
	'identifierSchemeCodeUnspsc190501.31201523': __('identifierSchemeCodeUnspsc190501.31201523'),
	'identifierSchemeCodeUnspsc190501.31201524': __('identifierSchemeCodeUnspsc190501.31201524'),
	'identifierSchemeCodeUnspsc190501.31201525': __('identifierSchemeCodeUnspsc190501.31201525'),
	'identifierSchemeCodeUnspsc190501.31201526': __('identifierSchemeCodeUnspsc190501.31201526'),
	'identifierSchemeCodeUnspsc190501.31201527': __('identifierSchemeCodeUnspsc190501.31201527'),
	'identifierSchemeCodeUnspsc190501.31201528': __('identifierSchemeCodeUnspsc190501.31201528'),
	'identifierSchemeCodeUnspsc190501.31201529': __('identifierSchemeCodeUnspsc190501.31201529'),
	'identifierSchemeCodeUnspsc190501.31201530': __('identifierSchemeCodeUnspsc190501.31201530'),
	'identifierSchemeCodeUnspsc190501.31201531': __('identifierSchemeCodeUnspsc190501.31201531'),
	'identifierSchemeCodeUnspsc190501.31201532': __('identifierSchemeCodeUnspsc190501.31201532'),
	'identifierSchemeCodeUnspsc190501.31201533': __('identifierSchemeCodeUnspsc190501.31201533'),
	'identifierSchemeCodeUnspsc190501.31201534': __('identifierSchemeCodeUnspsc190501.31201534'),
	'identifierSchemeCodeUnspsc190501.31201535': __('identifierSchemeCodeUnspsc190501.31201535'),
	'identifierSchemeCodeUnspsc190501.31201536': __('identifierSchemeCodeUnspsc190501.31201536'),
	'identifierSchemeCodeUnspsc190501.31201537': __('identifierSchemeCodeUnspsc190501.31201537'),
	'identifierSchemeCodeUnspsc190501.31201538': __('identifierSchemeCodeUnspsc190501.31201538'),
	'identifierSchemeCodeUnspsc190501.31201600': __('identifierSchemeCodeUnspsc190501.31201600'),
	'identifierSchemeCodeUnspsc190501.31201601': __('identifierSchemeCodeUnspsc190501.31201601'),
	'identifierSchemeCodeUnspsc190501.31201602': __('identifierSchemeCodeUnspsc190501.31201602'),
	'identifierSchemeCodeUnspsc190501.31201603': __('identifierSchemeCodeUnspsc190501.31201603'),
	'identifierSchemeCodeUnspsc190501.31201604': __('identifierSchemeCodeUnspsc190501.31201604'),
	'identifierSchemeCodeUnspsc190501.31201605': __('identifierSchemeCodeUnspsc190501.31201605'),
	'identifierSchemeCodeUnspsc190501.31201606': __('identifierSchemeCodeUnspsc190501.31201606'),
	'identifierSchemeCodeUnspsc190501.31201607': __('identifierSchemeCodeUnspsc190501.31201607'),
	'identifierSchemeCodeUnspsc190501.31201608': __('identifierSchemeCodeUnspsc190501.31201608'),
	'identifierSchemeCodeUnspsc190501.31201609': __('identifierSchemeCodeUnspsc190501.31201609'),
	'identifierSchemeCodeUnspsc190501.31201610': __('identifierSchemeCodeUnspsc190501.31201610'),
	'identifierSchemeCodeUnspsc190501.31201611': __('identifierSchemeCodeUnspsc190501.31201611'),
	'identifierSchemeCodeUnspsc190501.31201613': __('identifierSchemeCodeUnspsc190501.31201613'),
	'identifierSchemeCodeUnspsc190501.31201615': __('identifierSchemeCodeUnspsc190501.31201615'),
	'identifierSchemeCodeUnspsc190501.31201616': __('identifierSchemeCodeUnspsc190501.31201616'),
	'identifierSchemeCodeUnspsc190501.31201617': __('identifierSchemeCodeUnspsc190501.31201617'),
	'identifierSchemeCodeUnspsc190501.31201618': __('identifierSchemeCodeUnspsc190501.31201618'),
	'identifierSchemeCodeUnspsc190501.31201619': __('identifierSchemeCodeUnspsc190501.31201619'),
	'identifierSchemeCodeUnspsc190501.31201620': __('identifierSchemeCodeUnspsc190501.31201620'),
	'identifierSchemeCodeUnspsc190501.31201621': __('identifierSchemeCodeUnspsc190501.31201621'),
	'identifierSchemeCodeUnspsc190501.31201622': __('identifierSchemeCodeUnspsc190501.31201622'),
	'identifierSchemeCodeUnspsc190501.31201623': __('identifierSchemeCodeUnspsc190501.31201623'),
	'identifierSchemeCodeUnspsc190501.31201624': __('identifierSchemeCodeUnspsc190501.31201624'),
	'identifierSchemeCodeUnspsc190501.31201625': __('identifierSchemeCodeUnspsc190501.31201625'),
	'identifierSchemeCodeUnspsc190501.31201626': __('identifierSchemeCodeUnspsc190501.31201626'),
	'identifierSchemeCodeUnspsc190501.31201627': __('identifierSchemeCodeUnspsc190501.31201627'),
	'identifierSchemeCodeUnspsc190501.31201632': __('identifierSchemeCodeUnspsc190501.31201632'),
	'identifierSchemeCodeUnspsc190501.31201633': __('identifierSchemeCodeUnspsc190501.31201633'),
	'identifierSchemeCodeUnspsc190501.31201634': __('identifierSchemeCodeUnspsc190501.31201634'),
	'identifierSchemeCodeUnspsc190501.31201635': __('identifierSchemeCodeUnspsc190501.31201635'),
	'identifierSchemeCodeUnspsc190501.31201636': __('identifierSchemeCodeUnspsc190501.31201636'),
	'identifierSchemeCodeUnspsc190501.31201637': __('identifierSchemeCodeUnspsc190501.31201637'),
	'identifierSchemeCodeUnspsc190501.31201700': __('identifierSchemeCodeUnspsc190501.31201700'),
	'identifierSchemeCodeUnspsc190501.31201701': __('identifierSchemeCodeUnspsc190501.31201701'),
	'identifierSchemeCodeUnspsc190501.31201702': __('identifierSchemeCodeUnspsc190501.31201702'),
	'identifierSchemeCodeUnspsc190501.31201703': __('identifierSchemeCodeUnspsc190501.31201703'),
	'identifierSchemeCodeUnspsc190501.31201704': __('identifierSchemeCodeUnspsc190501.31201704'),
	'identifierSchemeCodeUnspsc190501.31201705': __('identifierSchemeCodeUnspsc190501.31201705'),
	'identifierSchemeCodeUnspsc190501.31201706': __('identifierSchemeCodeUnspsc190501.31201706'),
	'identifierSchemeCodeUnspsc190501.31201707': __('identifierSchemeCodeUnspsc190501.31201707'),
	'identifierSchemeCodeUnspsc190501.31201708': __('identifierSchemeCodeUnspsc190501.31201708'),
	'identifierSchemeCodeUnspsc190501.31201709': __('identifierSchemeCodeUnspsc190501.31201709'),
	'identifierSchemeCodeUnspsc190501.31201710': __('identifierSchemeCodeUnspsc190501.31201710'),
	'identifierSchemeCodeUnspsc190501.31201711': __('identifierSchemeCodeUnspsc190501.31201711'),
	'identifierSchemeCodeUnspsc190501.31201712': __('identifierSchemeCodeUnspsc190501.31201712'),
	'identifierSchemeCodeUnspsc190501.31201713': __('identifierSchemeCodeUnspsc190501.31201713'),
	'identifierSchemeCodeUnspsc190501.31210000': __('identifierSchemeCodeUnspsc190501.31210000'),
	'identifierSchemeCodeUnspsc190501.31211500': __('identifierSchemeCodeUnspsc190501.31211500'),
	'identifierSchemeCodeUnspsc190501.31211501': __('identifierSchemeCodeUnspsc190501.31211501'),
	'identifierSchemeCodeUnspsc190501.31211502': __('identifierSchemeCodeUnspsc190501.31211502'),
	'identifierSchemeCodeUnspsc190501.31211503': __('identifierSchemeCodeUnspsc190501.31211503'),
	'identifierSchemeCodeUnspsc190501.31211504': __('identifierSchemeCodeUnspsc190501.31211504'),
	'identifierSchemeCodeUnspsc190501.31211505': __('identifierSchemeCodeUnspsc190501.31211505'),
	'identifierSchemeCodeUnspsc190501.31211506': __('identifierSchemeCodeUnspsc190501.31211506'),
	'identifierSchemeCodeUnspsc190501.31211507': __('identifierSchemeCodeUnspsc190501.31211507'),
	'identifierSchemeCodeUnspsc190501.31211508': __('identifierSchemeCodeUnspsc190501.31211508'),
	'identifierSchemeCodeUnspsc190501.31211509': __('identifierSchemeCodeUnspsc190501.31211509'),
	'identifierSchemeCodeUnspsc190501.31211510': __('identifierSchemeCodeUnspsc190501.31211510'),
	'identifierSchemeCodeUnspsc190501.31211511': __('identifierSchemeCodeUnspsc190501.31211511'),
	'identifierSchemeCodeUnspsc190501.31211512': __('identifierSchemeCodeUnspsc190501.31211512'),
	'identifierSchemeCodeUnspsc190501.31211513': __('identifierSchemeCodeUnspsc190501.31211513'),
	'identifierSchemeCodeUnspsc190501.31211514': __('identifierSchemeCodeUnspsc190501.31211514'),
	'identifierSchemeCodeUnspsc190501.31211515': __('identifierSchemeCodeUnspsc190501.31211515'),
	'identifierSchemeCodeUnspsc190501.31211516': __('identifierSchemeCodeUnspsc190501.31211516'),
	'identifierSchemeCodeUnspsc190501.31211517': __('identifierSchemeCodeUnspsc190501.31211517'),
	'identifierSchemeCodeUnspsc190501.31211518': __('identifierSchemeCodeUnspsc190501.31211518'),
	'identifierSchemeCodeUnspsc190501.31211519': __('identifierSchemeCodeUnspsc190501.31211519'),
	'identifierSchemeCodeUnspsc190501.31211520': __('identifierSchemeCodeUnspsc190501.31211520'),
	'identifierSchemeCodeUnspsc190501.31211521': __('identifierSchemeCodeUnspsc190501.31211521'),
	'identifierSchemeCodeUnspsc190501.31211522': __('identifierSchemeCodeUnspsc190501.31211522'),
	'identifierSchemeCodeUnspsc190501.31211523': __('identifierSchemeCodeUnspsc190501.31211523'),
	'identifierSchemeCodeUnspsc190501.31211600': __('identifierSchemeCodeUnspsc190501.31211600'),
	'identifierSchemeCodeUnspsc190501.31211601': __('identifierSchemeCodeUnspsc190501.31211601'),
	'identifierSchemeCodeUnspsc190501.31211602': __('identifierSchemeCodeUnspsc190501.31211602'),
	'identifierSchemeCodeUnspsc190501.31211603': __('identifierSchemeCodeUnspsc190501.31211603'),
	'identifierSchemeCodeUnspsc190501.31211604': __('identifierSchemeCodeUnspsc190501.31211604'),
	'identifierSchemeCodeUnspsc190501.31211605': __('identifierSchemeCodeUnspsc190501.31211605'),
	'identifierSchemeCodeUnspsc190501.31211606': __('identifierSchemeCodeUnspsc190501.31211606'),
	'identifierSchemeCodeUnspsc190501.31211607': __('identifierSchemeCodeUnspsc190501.31211607'),
	'identifierSchemeCodeUnspsc190501.31211700': __('identifierSchemeCodeUnspsc190501.31211700'),
	'identifierSchemeCodeUnspsc190501.31211701': __('identifierSchemeCodeUnspsc190501.31211701'),
	'identifierSchemeCodeUnspsc190501.31211702': __('identifierSchemeCodeUnspsc190501.31211702'),
	'identifierSchemeCodeUnspsc190501.31211703': __('identifierSchemeCodeUnspsc190501.31211703'),
	'identifierSchemeCodeUnspsc190501.31211704': __('identifierSchemeCodeUnspsc190501.31211704'),
	'identifierSchemeCodeUnspsc190501.31211705': __('identifierSchemeCodeUnspsc190501.31211705'),
	'identifierSchemeCodeUnspsc190501.31211706': __('identifierSchemeCodeUnspsc190501.31211706'),
	'identifierSchemeCodeUnspsc190501.31211707': __('identifierSchemeCodeUnspsc190501.31211707'),
	'identifierSchemeCodeUnspsc190501.31211708': __('identifierSchemeCodeUnspsc190501.31211708'),
	'identifierSchemeCodeUnspsc190501.31211709': __('identifierSchemeCodeUnspsc190501.31211709'),
	'identifierSchemeCodeUnspsc190501.31211800': __('identifierSchemeCodeUnspsc190501.31211800'),
	'identifierSchemeCodeUnspsc190501.31211801': __('identifierSchemeCodeUnspsc190501.31211801'),
	'identifierSchemeCodeUnspsc190501.31211802': __('identifierSchemeCodeUnspsc190501.31211802'),
	'identifierSchemeCodeUnspsc190501.31211803': __('identifierSchemeCodeUnspsc190501.31211803'),
	'identifierSchemeCodeUnspsc190501.31211900': __('identifierSchemeCodeUnspsc190501.31211900'),
	'identifierSchemeCodeUnspsc190501.31211901': __('identifierSchemeCodeUnspsc190501.31211901'),
	'identifierSchemeCodeUnspsc190501.31211902': __('identifierSchemeCodeUnspsc190501.31211902'),
	'identifierSchemeCodeUnspsc190501.31211903': __('identifierSchemeCodeUnspsc190501.31211903'),
	'identifierSchemeCodeUnspsc190501.31211904': __('identifierSchemeCodeUnspsc190501.31211904'),
	'identifierSchemeCodeUnspsc190501.31211905': __('identifierSchemeCodeUnspsc190501.31211905'),
	'identifierSchemeCodeUnspsc190501.31211906': __('identifierSchemeCodeUnspsc190501.31211906'),
	'identifierSchemeCodeUnspsc190501.31211908': __('identifierSchemeCodeUnspsc190501.31211908'),
	'identifierSchemeCodeUnspsc190501.31211909': __('identifierSchemeCodeUnspsc190501.31211909'),
	'identifierSchemeCodeUnspsc190501.31211910': __('identifierSchemeCodeUnspsc190501.31211910'),
	'identifierSchemeCodeUnspsc190501.31211912': __('identifierSchemeCodeUnspsc190501.31211912'),
	'identifierSchemeCodeUnspsc190501.31211913': __('identifierSchemeCodeUnspsc190501.31211913'),
	'identifierSchemeCodeUnspsc190501.31211914': __('identifierSchemeCodeUnspsc190501.31211914'),
	'identifierSchemeCodeUnspsc190501.31211915': __('identifierSchemeCodeUnspsc190501.31211915'),
	'identifierSchemeCodeUnspsc190501.31211916': __('identifierSchemeCodeUnspsc190501.31211916'),
	'identifierSchemeCodeUnspsc190501.31211917': __('identifierSchemeCodeUnspsc190501.31211917'),
	'identifierSchemeCodeUnspsc190501.31211918': __('identifierSchemeCodeUnspsc190501.31211918'),
	'identifierSchemeCodeUnspsc190501.31220000': __('identifierSchemeCodeUnspsc190501.31220000'),
	'identifierSchemeCodeUnspsc190501.31221600': __('identifierSchemeCodeUnspsc190501.31221600'),
	'identifierSchemeCodeUnspsc190501.31221601': __('identifierSchemeCodeUnspsc190501.31221601'),
	'identifierSchemeCodeUnspsc190501.31221602': __('identifierSchemeCodeUnspsc190501.31221602'),
	'identifierSchemeCodeUnspsc190501.31221603': __('identifierSchemeCodeUnspsc190501.31221603'),
	'identifierSchemeCodeUnspsc190501.31230000': __('identifierSchemeCodeUnspsc190501.31230000'),
	'identifierSchemeCodeUnspsc190501.31231100': __('identifierSchemeCodeUnspsc190501.31231100'),
	'identifierSchemeCodeUnspsc190501.31231101': __('identifierSchemeCodeUnspsc190501.31231101'),
	'identifierSchemeCodeUnspsc190501.31231102': __('identifierSchemeCodeUnspsc190501.31231102'),
	'identifierSchemeCodeUnspsc190501.31231103': __('identifierSchemeCodeUnspsc190501.31231103'),
	'identifierSchemeCodeUnspsc190501.31231104': __('identifierSchemeCodeUnspsc190501.31231104'),
	'identifierSchemeCodeUnspsc190501.31231105': __('identifierSchemeCodeUnspsc190501.31231105'),
	'identifierSchemeCodeUnspsc190501.31231106': __('identifierSchemeCodeUnspsc190501.31231106'),
	'identifierSchemeCodeUnspsc190501.31231107': __('identifierSchemeCodeUnspsc190501.31231107'),
	'identifierSchemeCodeUnspsc190501.31231108': __('identifierSchemeCodeUnspsc190501.31231108'),
	'identifierSchemeCodeUnspsc190501.31231109': __('identifierSchemeCodeUnspsc190501.31231109'),
	'identifierSchemeCodeUnspsc190501.31231110': __('identifierSchemeCodeUnspsc190501.31231110'),
	'identifierSchemeCodeUnspsc190501.31231111': __('identifierSchemeCodeUnspsc190501.31231111'),
	'identifierSchemeCodeUnspsc190501.31231112': __('identifierSchemeCodeUnspsc190501.31231112'),
	'identifierSchemeCodeUnspsc190501.31231113': __('identifierSchemeCodeUnspsc190501.31231113'),
	'identifierSchemeCodeUnspsc190501.31231114': __('identifierSchemeCodeUnspsc190501.31231114'),
	'identifierSchemeCodeUnspsc190501.31231115': __('identifierSchemeCodeUnspsc190501.31231115'),
	'identifierSchemeCodeUnspsc190501.31231116': __('identifierSchemeCodeUnspsc190501.31231116'),
	'identifierSchemeCodeUnspsc190501.31231117': __('identifierSchemeCodeUnspsc190501.31231117'),
	'identifierSchemeCodeUnspsc190501.31231118': __('identifierSchemeCodeUnspsc190501.31231118'),
	'identifierSchemeCodeUnspsc190501.31231119': __('identifierSchemeCodeUnspsc190501.31231119'),
	'identifierSchemeCodeUnspsc190501.31231120': __('identifierSchemeCodeUnspsc190501.31231120'),
	'identifierSchemeCodeUnspsc190501.31231200': __('identifierSchemeCodeUnspsc190501.31231200'),
	'identifierSchemeCodeUnspsc190501.31231201': __('identifierSchemeCodeUnspsc190501.31231201'),
	'identifierSchemeCodeUnspsc190501.31231202': __('identifierSchemeCodeUnspsc190501.31231202'),
	'identifierSchemeCodeUnspsc190501.31231203': __('identifierSchemeCodeUnspsc190501.31231203'),
	'identifierSchemeCodeUnspsc190501.31231204': __('identifierSchemeCodeUnspsc190501.31231204'),
	'identifierSchemeCodeUnspsc190501.31231205': __('identifierSchemeCodeUnspsc190501.31231205'),
	'identifierSchemeCodeUnspsc190501.31231206': __('identifierSchemeCodeUnspsc190501.31231206'),
	'identifierSchemeCodeUnspsc190501.31231207': __('identifierSchemeCodeUnspsc190501.31231207'),
	'identifierSchemeCodeUnspsc190501.31231208': __('identifierSchemeCodeUnspsc190501.31231208'),
	'identifierSchemeCodeUnspsc190501.31231209': __('identifierSchemeCodeUnspsc190501.31231209'),
	'identifierSchemeCodeUnspsc190501.31231210': __('identifierSchemeCodeUnspsc190501.31231210'),
	'identifierSchemeCodeUnspsc190501.31231211': __('identifierSchemeCodeUnspsc190501.31231211'),
	'identifierSchemeCodeUnspsc190501.31231212': __('identifierSchemeCodeUnspsc190501.31231212'),
	'identifierSchemeCodeUnspsc190501.31231213': __('identifierSchemeCodeUnspsc190501.31231213'),
	'identifierSchemeCodeUnspsc190501.31231214': __('identifierSchemeCodeUnspsc190501.31231214'),
	'identifierSchemeCodeUnspsc190501.31231215': __('identifierSchemeCodeUnspsc190501.31231215'),
	'identifierSchemeCodeUnspsc190501.31231216': __('identifierSchemeCodeUnspsc190501.31231216'),
	'identifierSchemeCodeUnspsc190501.31231217': __('identifierSchemeCodeUnspsc190501.31231217'),
	'identifierSchemeCodeUnspsc190501.31231218': __('identifierSchemeCodeUnspsc190501.31231218'),
	'identifierSchemeCodeUnspsc190501.31231219': __('identifierSchemeCodeUnspsc190501.31231219'),
	'identifierSchemeCodeUnspsc190501.31231400': __('identifierSchemeCodeUnspsc190501.31231400'),
	'identifierSchemeCodeUnspsc190501.31231401': __('identifierSchemeCodeUnspsc190501.31231401'),
	'identifierSchemeCodeUnspsc190501.31231402': __('identifierSchemeCodeUnspsc190501.31231402'),
	'identifierSchemeCodeUnspsc190501.31231403': __('identifierSchemeCodeUnspsc190501.31231403'),
	'identifierSchemeCodeUnspsc190501.31231404': __('identifierSchemeCodeUnspsc190501.31231404'),
	'identifierSchemeCodeUnspsc190501.31231405': __('identifierSchemeCodeUnspsc190501.31231405'),
	'identifierSchemeCodeUnspsc190501.31240000': __('identifierSchemeCodeUnspsc190501.31240000'),
	'identifierSchemeCodeUnspsc190501.31241500': __('identifierSchemeCodeUnspsc190501.31241500'),
	'identifierSchemeCodeUnspsc190501.31241501': __('identifierSchemeCodeUnspsc190501.31241501'),
	'identifierSchemeCodeUnspsc190501.31241502': __('identifierSchemeCodeUnspsc190501.31241502'),
	'identifierSchemeCodeUnspsc190501.31241600': __('identifierSchemeCodeUnspsc190501.31241600'),
	'identifierSchemeCodeUnspsc190501.31241601': __('identifierSchemeCodeUnspsc190501.31241601'),
	'identifierSchemeCodeUnspsc190501.31241602': __('identifierSchemeCodeUnspsc190501.31241602'),
	'identifierSchemeCodeUnspsc190501.31241603': __('identifierSchemeCodeUnspsc190501.31241603'),
	'identifierSchemeCodeUnspsc190501.31241604': __('identifierSchemeCodeUnspsc190501.31241604'),
	'identifierSchemeCodeUnspsc190501.31241605': __('identifierSchemeCodeUnspsc190501.31241605'),
	'identifierSchemeCodeUnspsc190501.31241606': __('identifierSchemeCodeUnspsc190501.31241606'),
	'identifierSchemeCodeUnspsc190501.31241607': __('identifierSchemeCodeUnspsc190501.31241607'),
	'identifierSchemeCodeUnspsc190501.31241608': __('identifierSchemeCodeUnspsc190501.31241608'),
	'identifierSchemeCodeUnspsc190501.31241609': __('identifierSchemeCodeUnspsc190501.31241609'),
	'identifierSchemeCodeUnspsc190501.31241610': __('identifierSchemeCodeUnspsc190501.31241610'),
	'identifierSchemeCodeUnspsc190501.31241700': __('identifierSchemeCodeUnspsc190501.31241700'),
	'identifierSchemeCodeUnspsc190501.31241701': __('identifierSchemeCodeUnspsc190501.31241701'),
	'identifierSchemeCodeUnspsc190501.31241702': __('identifierSchemeCodeUnspsc190501.31241702'),
	'identifierSchemeCodeUnspsc190501.31241703': __('identifierSchemeCodeUnspsc190501.31241703'),
	'identifierSchemeCodeUnspsc190501.31241704': __('identifierSchemeCodeUnspsc190501.31241704'),
	'identifierSchemeCodeUnspsc190501.31241705': __('identifierSchemeCodeUnspsc190501.31241705'),
	'identifierSchemeCodeUnspsc190501.31241706': __('identifierSchemeCodeUnspsc190501.31241706'),
	'identifierSchemeCodeUnspsc190501.31241800': __('identifierSchemeCodeUnspsc190501.31241800'),
	'identifierSchemeCodeUnspsc190501.31241801': __('identifierSchemeCodeUnspsc190501.31241801'),
	'identifierSchemeCodeUnspsc190501.31241802': __('identifierSchemeCodeUnspsc190501.31241802'),
	'identifierSchemeCodeUnspsc190501.31241803': __('identifierSchemeCodeUnspsc190501.31241803'),
	'identifierSchemeCodeUnspsc190501.31241804': __('identifierSchemeCodeUnspsc190501.31241804'),
	'identifierSchemeCodeUnspsc190501.31241805': __('identifierSchemeCodeUnspsc190501.31241805'),
	'identifierSchemeCodeUnspsc190501.31241806': __('identifierSchemeCodeUnspsc190501.31241806'),
	'identifierSchemeCodeUnspsc190501.31241807': __('identifierSchemeCodeUnspsc190501.31241807'),
	'identifierSchemeCodeUnspsc190501.31241808': __('identifierSchemeCodeUnspsc190501.31241808'),
	'identifierSchemeCodeUnspsc190501.31241809': __('identifierSchemeCodeUnspsc190501.31241809'),
	'identifierSchemeCodeUnspsc190501.31241810': __('identifierSchemeCodeUnspsc190501.31241810'),
	'identifierSchemeCodeUnspsc190501.31241811': __('identifierSchemeCodeUnspsc190501.31241811'),
	'identifierSchemeCodeUnspsc190501.31241812': __('identifierSchemeCodeUnspsc190501.31241812'),
	'identifierSchemeCodeUnspsc190501.31241813': __('identifierSchemeCodeUnspsc190501.31241813'),
	'identifierSchemeCodeUnspsc190501.31241814': __('identifierSchemeCodeUnspsc190501.31241814'),
	'identifierSchemeCodeUnspsc190501.31241815': __('identifierSchemeCodeUnspsc190501.31241815'),
	'identifierSchemeCodeUnspsc190501.31241900': __('identifierSchemeCodeUnspsc190501.31241900'),
	'identifierSchemeCodeUnspsc190501.31241901': __('identifierSchemeCodeUnspsc190501.31241901'),
	'identifierSchemeCodeUnspsc190501.31241902': __('identifierSchemeCodeUnspsc190501.31241902'),
	'identifierSchemeCodeUnspsc190501.31241903': __('identifierSchemeCodeUnspsc190501.31241903'),
	'identifierSchemeCodeUnspsc190501.31241904': __('identifierSchemeCodeUnspsc190501.31241904'),
	'identifierSchemeCodeUnspsc190501.31241905': __('identifierSchemeCodeUnspsc190501.31241905'),
	'identifierSchemeCodeUnspsc190501.31241906': __('identifierSchemeCodeUnspsc190501.31241906'),
	'identifierSchemeCodeUnspsc190501.31241907': __('identifierSchemeCodeUnspsc190501.31241907'),
	'identifierSchemeCodeUnspsc190501.31241908': __('identifierSchemeCodeUnspsc190501.31241908'),
	'identifierSchemeCodeUnspsc190501.31242000': __('identifierSchemeCodeUnspsc190501.31242000'),
	'identifierSchemeCodeUnspsc190501.31242001': __('identifierSchemeCodeUnspsc190501.31242001'),
	'identifierSchemeCodeUnspsc190501.31242002': __('identifierSchemeCodeUnspsc190501.31242002'),
	'identifierSchemeCodeUnspsc190501.31242003': __('identifierSchemeCodeUnspsc190501.31242003'),
	'identifierSchemeCodeUnspsc190501.31242100': __('identifierSchemeCodeUnspsc190501.31242100'),
	'identifierSchemeCodeUnspsc190501.31242101': __('identifierSchemeCodeUnspsc190501.31242101'),
	'identifierSchemeCodeUnspsc190501.31242103': __('identifierSchemeCodeUnspsc190501.31242103'),
	'identifierSchemeCodeUnspsc190501.31242104': __('identifierSchemeCodeUnspsc190501.31242104'),
	'identifierSchemeCodeUnspsc190501.31242105': __('identifierSchemeCodeUnspsc190501.31242105'),
	'identifierSchemeCodeUnspsc190501.31242106': __('identifierSchemeCodeUnspsc190501.31242106'),
	'identifierSchemeCodeUnspsc190501.31242107': __('identifierSchemeCodeUnspsc190501.31242107'),
	'identifierSchemeCodeUnspsc190501.31242200': __('identifierSchemeCodeUnspsc190501.31242200'),
	'identifierSchemeCodeUnspsc190501.31242201': __('identifierSchemeCodeUnspsc190501.31242201'),
	'identifierSchemeCodeUnspsc190501.31242202': __('identifierSchemeCodeUnspsc190501.31242202'),
	'identifierSchemeCodeUnspsc190501.31242203': __('identifierSchemeCodeUnspsc190501.31242203'),
	'identifierSchemeCodeUnspsc190501.31242204': __('identifierSchemeCodeUnspsc190501.31242204'),
	'identifierSchemeCodeUnspsc190501.31242205': __('identifierSchemeCodeUnspsc190501.31242205'),
	'identifierSchemeCodeUnspsc190501.31242206': __('identifierSchemeCodeUnspsc190501.31242206'),
	'identifierSchemeCodeUnspsc190501.31242207': __('identifierSchemeCodeUnspsc190501.31242207'),
	'identifierSchemeCodeUnspsc190501.31242208': __('identifierSchemeCodeUnspsc190501.31242208'),
	'identifierSchemeCodeUnspsc190501.31250000': __('identifierSchemeCodeUnspsc190501.31250000'),
	'identifierSchemeCodeUnspsc190501.31251500': __('identifierSchemeCodeUnspsc190501.31251500'),
	'identifierSchemeCodeUnspsc190501.31251501': __('identifierSchemeCodeUnspsc190501.31251501'),
	'identifierSchemeCodeUnspsc190501.31251502': __('identifierSchemeCodeUnspsc190501.31251502'),
	'identifierSchemeCodeUnspsc190501.31251503': __('identifierSchemeCodeUnspsc190501.31251503'),
	'identifierSchemeCodeUnspsc190501.31251504': __('identifierSchemeCodeUnspsc190501.31251504'),
	'identifierSchemeCodeUnspsc190501.31251505': __('identifierSchemeCodeUnspsc190501.31251505'),
	'identifierSchemeCodeUnspsc190501.31251506': __('identifierSchemeCodeUnspsc190501.31251506'),
	'identifierSchemeCodeUnspsc190501.31251507': __('identifierSchemeCodeUnspsc190501.31251507'),
	'identifierSchemeCodeUnspsc190501.31251508': __('identifierSchemeCodeUnspsc190501.31251508'),
	'identifierSchemeCodeUnspsc190501.31251509': __('identifierSchemeCodeUnspsc190501.31251509'),
	'identifierSchemeCodeUnspsc190501.31251510': __('identifierSchemeCodeUnspsc190501.31251510'),
	'identifierSchemeCodeUnspsc190501.31251511': __('identifierSchemeCodeUnspsc190501.31251511'),
	'identifierSchemeCodeUnspsc190501.31251512': __('identifierSchemeCodeUnspsc190501.31251512'),
	'identifierSchemeCodeUnspsc190501.31251513': __('identifierSchemeCodeUnspsc190501.31251513'),
	'identifierSchemeCodeUnspsc190501.31251514': __('identifierSchemeCodeUnspsc190501.31251514'),
	'identifierSchemeCodeUnspsc190501.31251515': __('identifierSchemeCodeUnspsc190501.31251515'),
	'identifierSchemeCodeUnspsc190501.31251516': __('identifierSchemeCodeUnspsc190501.31251516'),
	'identifierSchemeCodeUnspsc190501.31251600': __('identifierSchemeCodeUnspsc190501.31251600'),
	'identifierSchemeCodeUnspsc190501.31251601': __('identifierSchemeCodeUnspsc190501.31251601'),
	'identifierSchemeCodeUnspsc190501.31260000': __('identifierSchemeCodeUnspsc190501.31260000'),
	'identifierSchemeCodeUnspsc190501.31261500': __('identifierSchemeCodeUnspsc190501.31261500'),
	'identifierSchemeCodeUnspsc190501.31261501': __('identifierSchemeCodeUnspsc190501.31261501'),
	'identifierSchemeCodeUnspsc190501.31261502': __('identifierSchemeCodeUnspsc190501.31261502'),
	'identifierSchemeCodeUnspsc190501.31261503': __('identifierSchemeCodeUnspsc190501.31261503'),
	'identifierSchemeCodeUnspsc190501.31261504': __('identifierSchemeCodeUnspsc190501.31261504'),
	'identifierSchemeCodeUnspsc190501.31261505': __('identifierSchemeCodeUnspsc190501.31261505'),
	'identifierSchemeCodeUnspsc190501.31261600': __('identifierSchemeCodeUnspsc190501.31261600'),
	'identifierSchemeCodeUnspsc190501.31261601': __('identifierSchemeCodeUnspsc190501.31261601'),
	'identifierSchemeCodeUnspsc190501.31261602': __('identifierSchemeCodeUnspsc190501.31261602'),
	'identifierSchemeCodeUnspsc190501.31261603': __('identifierSchemeCodeUnspsc190501.31261603'),
	'identifierSchemeCodeUnspsc190501.31261700': __('identifierSchemeCodeUnspsc190501.31261700'),
	'identifierSchemeCodeUnspsc190501.31261701': __('identifierSchemeCodeUnspsc190501.31261701'),
	'identifierSchemeCodeUnspsc190501.31261702': __('identifierSchemeCodeUnspsc190501.31261702'),
	'identifierSchemeCodeUnspsc190501.31261703': __('identifierSchemeCodeUnspsc190501.31261703'),
	'identifierSchemeCodeUnspsc190501.31261704': __('identifierSchemeCodeUnspsc190501.31261704'),
	'identifierSchemeCodeUnspsc190501.31270000': __('identifierSchemeCodeUnspsc190501.31270000'),
	'identifierSchemeCodeUnspsc190501.31271600': __('identifierSchemeCodeUnspsc190501.31271600'),
	'identifierSchemeCodeUnspsc190501.31271601': __('identifierSchemeCodeUnspsc190501.31271601'),
	'identifierSchemeCodeUnspsc190501.31271602': __('identifierSchemeCodeUnspsc190501.31271602'),
	'identifierSchemeCodeUnspsc190501.31280000': __('identifierSchemeCodeUnspsc190501.31280000'),
	'identifierSchemeCodeUnspsc190501.31281500': __('identifierSchemeCodeUnspsc190501.31281500'),
	'identifierSchemeCodeUnspsc190501.31281502': __('identifierSchemeCodeUnspsc190501.31281502'),
	'identifierSchemeCodeUnspsc190501.31281503': __('identifierSchemeCodeUnspsc190501.31281503'),
	'identifierSchemeCodeUnspsc190501.31281504': __('identifierSchemeCodeUnspsc190501.31281504'),
	'identifierSchemeCodeUnspsc190501.31281505': __('identifierSchemeCodeUnspsc190501.31281505'),
	'identifierSchemeCodeUnspsc190501.31281506': __('identifierSchemeCodeUnspsc190501.31281506'),
	'identifierSchemeCodeUnspsc190501.31281507': __('identifierSchemeCodeUnspsc190501.31281507'),
	'identifierSchemeCodeUnspsc190501.31281508': __('identifierSchemeCodeUnspsc190501.31281508'),
	'identifierSchemeCodeUnspsc190501.31281509': __('identifierSchemeCodeUnspsc190501.31281509'),
	'identifierSchemeCodeUnspsc190501.31281510': __('identifierSchemeCodeUnspsc190501.31281510'),
	'identifierSchemeCodeUnspsc190501.31281511': __('identifierSchemeCodeUnspsc190501.31281511'),
	'identifierSchemeCodeUnspsc190501.31281512': __('identifierSchemeCodeUnspsc190501.31281512'),
	'identifierSchemeCodeUnspsc190501.31281513': __('identifierSchemeCodeUnspsc190501.31281513'),
	'identifierSchemeCodeUnspsc190501.31281514': __('identifierSchemeCodeUnspsc190501.31281514'),
	'identifierSchemeCodeUnspsc190501.31281515': __('identifierSchemeCodeUnspsc190501.31281515'),
	'identifierSchemeCodeUnspsc190501.31281516': __('identifierSchemeCodeUnspsc190501.31281516'),
	'identifierSchemeCodeUnspsc190501.31281517': __('identifierSchemeCodeUnspsc190501.31281517'),
	'identifierSchemeCodeUnspsc190501.31281518': __('identifierSchemeCodeUnspsc190501.31281518'),
	'identifierSchemeCodeUnspsc190501.31281519': __('identifierSchemeCodeUnspsc190501.31281519'),
	'identifierSchemeCodeUnspsc190501.31281520': __('identifierSchemeCodeUnspsc190501.31281520'),
	'identifierSchemeCodeUnspsc190501.31281521': __('identifierSchemeCodeUnspsc190501.31281521'),
	'identifierSchemeCodeUnspsc190501.31281522': __('identifierSchemeCodeUnspsc190501.31281522'),
	'identifierSchemeCodeUnspsc190501.31281523': __('identifierSchemeCodeUnspsc190501.31281523'),
	'identifierSchemeCodeUnspsc190501.31281524': __('identifierSchemeCodeUnspsc190501.31281524'),
	'identifierSchemeCodeUnspsc190501.31281525': __('identifierSchemeCodeUnspsc190501.31281525'),
	'identifierSchemeCodeUnspsc190501.31281526': __('identifierSchemeCodeUnspsc190501.31281526'),
	'identifierSchemeCodeUnspsc190501.31281527': __('identifierSchemeCodeUnspsc190501.31281527'),
	'identifierSchemeCodeUnspsc190501.31281528': __('identifierSchemeCodeUnspsc190501.31281528'),
	'identifierSchemeCodeUnspsc190501.31281529': __('identifierSchemeCodeUnspsc190501.31281529'),
	'identifierSchemeCodeUnspsc190501.31281530': __('identifierSchemeCodeUnspsc190501.31281530'),
	'identifierSchemeCodeUnspsc190501.31281531': __('identifierSchemeCodeUnspsc190501.31281531'),
	'identifierSchemeCodeUnspsc190501.31281532': __('identifierSchemeCodeUnspsc190501.31281532'),
	'identifierSchemeCodeUnspsc190501.31281533': __('identifierSchemeCodeUnspsc190501.31281533'),
	'identifierSchemeCodeUnspsc190501.31281534': __('identifierSchemeCodeUnspsc190501.31281534'),
	'identifierSchemeCodeUnspsc190501.31281535': __('identifierSchemeCodeUnspsc190501.31281535'),
	'identifierSchemeCodeUnspsc190501.31281536': __('identifierSchemeCodeUnspsc190501.31281536'),
	'identifierSchemeCodeUnspsc190501.31281537': __('identifierSchemeCodeUnspsc190501.31281537'),
	'identifierSchemeCodeUnspsc190501.31281538': __('identifierSchemeCodeUnspsc190501.31281538'),
	'identifierSchemeCodeUnspsc190501.31281700': __('identifierSchemeCodeUnspsc190501.31281700'),
	'identifierSchemeCodeUnspsc190501.31281701': __('identifierSchemeCodeUnspsc190501.31281701'),
	'identifierSchemeCodeUnspsc190501.31281800': __('identifierSchemeCodeUnspsc190501.31281800'),
	'identifierSchemeCodeUnspsc190501.31281801': __('identifierSchemeCodeUnspsc190501.31281801'),
	'identifierSchemeCodeUnspsc190501.31281802': __('identifierSchemeCodeUnspsc190501.31281802'),
	'identifierSchemeCodeUnspsc190501.31281803': __('identifierSchemeCodeUnspsc190501.31281803'),
	'identifierSchemeCodeUnspsc190501.31281804': __('identifierSchemeCodeUnspsc190501.31281804'),
	'identifierSchemeCodeUnspsc190501.31281805': __('identifierSchemeCodeUnspsc190501.31281805'),
	'identifierSchemeCodeUnspsc190501.31281806': __('identifierSchemeCodeUnspsc190501.31281806'),
	'identifierSchemeCodeUnspsc190501.31281807': __('identifierSchemeCodeUnspsc190501.31281807'),
	'identifierSchemeCodeUnspsc190501.31281808': __('identifierSchemeCodeUnspsc190501.31281808'),
	'identifierSchemeCodeUnspsc190501.31281809': __('identifierSchemeCodeUnspsc190501.31281809'),
	'identifierSchemeCodeUnspsc190501.31281810': __('identifierSchemeCodeUnspsc190501.31281810'),
	'identifierSchemeCodeUnspsc190501.31281811': __('identifierSchemeCodeUnspsc190501.31281811'),
	'identifierSchemeCodeUnspsc190501.31281812': __('identifierSchemeCodeUnspsc190501.31281812'),
	'identifierSchemeCodeUnspsc190501.31281813': __('identifierSchemeCodeUnspsc190501.31281813'),
	'identifierSchemeCodeUnspsc190501.31281814': __('identifierSchemeCodeUnspsc190501.31281814'),
	'identifierSchemeCodeUnspsc190501.31281815': __('identifierSchemeCodeUnspsc190501.31281815'),
	'identifierSchemeCodeUnspsc190501.31281816': __('identifierSchemeCodeUnspsc190501.31281816'),
	'identifierSchemeCodeUnspsc190501.31281817': __('identifierSchemeCodeUnspsc190501.31281817'),
	'identifierSchemeCodeUnspsc190501.31281818': __('identifierSchemeCodeUnspsc190501.31281818'),
	'identifierSchemeCodeUnspsc190501.31281819': __('identifierSchemeCodeUnspsc190501.31281819'),
	'identifierSchemeCodeUnspsc190501.31281900': __('identifierSchemeCodeUnspsc190501.31281900'),
	'identifierSchemeCodeUnspsc190501.31281901': __('identifierSchemeCodeUnspsc190501.31281901'),
	'identifierSchemeCodeUnspsc190501.31281902': __('identifierSchemeCodeUnspsc190501.31281902'),
	'identifierSchemeCodeUnspsc190501.31281903': __('identifierSchemeCodeUnspsc190501.31281903'),
	'identifierSchemeCodeUnspsc190501.31281904': __('identifierSchemeCodeUnspsc190501.31281904'),
	'identifierSchemeCodeUnspsc190501.31281905': __('identifierSchemeCodeUnspsc190501.31281905'),
	'identifierSchemeCodeUnspsc190501.31281906': __('identifierSchemeCodeUnspsc190501.31281906'),
	'identifierSchemeCodeUnspsc190501.31281907': __('identifierSchemeCodeUnspsc190501.31281907'),
	'identifierSchemeCodeUnspsc190501.31281908': __('identifierSchemeCodeUnspsc190501.31281908'),
	'identifierSchemeCodeUnspsc190501.31281909': __('identifierSchemeCodeUnspsc190501.31281909'),
	'identifierSchemeCodeUnspsc190501.31281910': __('identifierSchemeCodeUnspsc190501.31281910'),
	'identifierSchemeCodeUnspsc190501.31281911': __('identifierSchemeCodeUnspsc190501.31281911'),
	'identifierSchemeCodeUnspsc190501.31281912': __('identifierSchemeCodeUnspsc190501.31281912'),
	'identifierSchemeCodeUnspsc190501.31281913': __('identifierSchemeCodeUnspsc190501.31281913'),
	'identifierSchemeCodeUnspsc190501.31281914': __('identifierSchemeCodeUnspsc190501.31281914'),
	'identifierSchemeCodeUnspsc190501.31281915': __('identifierSchemeCodeUnspsc190501.31281915'),
	'identifierSchemeCodeUnspsc190501.31281916': __('identifierSchemeCodeUnspsc190501.31281916'),
	'identifierSchemeCodeUnspsc190501.31281917': __('identifierSchemeCodeUnspsc190501.31281917'),
	'identifierSchemeCodeUnspsc190501.31281918': __('identifierSchemeCodeUnspsc190501.31281918'),
	'identifierSchemeCodeUnspsc190501.31281919': __('identifierSchemeCodeUnspsc190501.31281919'),
	'identifierSchemeCodeUnspsc190501.31282000': __('identifierSchemeCodeUnspsc190501.31282000'),
	'identifierSchemeCodeUnspsc190501.31282001': __('identifierSchemeCodeUnspsc190501.31282001'),
	'identifierSchemeCodeUnspsc190501.31282002': __('identifierSchemeCodeUnspsc190501.31282002'),
	'identifierSchemeCodeUnspsc190501.31282003': __('identifierSchemeCodeUnspsc190501.31282003'),
	'identifierSchemeCodeUnspsc190501.31282004': __('identifierSchemeCodeUnspsc190501.31282004'),
	'identifierSchemeCodeUnspsc190501.31282005': __('identifierSchemeCodeUnspsc190501.31282005'),
	'identifierSchemeCodeUnspsc190501.31282006': __('identifierSchemeCodeUnspsc190501.31282006'),
	'identifierSchemeCodeUnspsc190501.31282007': __('identifierSchemeCodeUnspsc190501.31282007'),
	'identifierSchemeCodeUnspsc190501.31282008': __('identifierSchemeCodeUnspsc190501.31282008'),
	'identifierSchemeCodeUnspsc190501.31282009': __('identifierSchemeCodeUnspsc190501.31282009'),
	'identifierSchemeCodeUnspsc190501.31282010': __('identifierSchemeCodeUnspsc190501.31282010'),
	'identifierSchemeCodeUnspsc190501.31282011': __('identifierSchemeCodeUnspsc190501.31282011'),
	'identifierSchemeCodeUnspsc190501.31282012': __('identifierSchemeCodeUnspsc190501.31282012'),
	'identifierSchemeCodeUnspsc190501.31282013': __('identifierSchemeCodeUnspsc190501.31282013'),
	'identifierSchemeCodeUnspsc190501.31282014': __('identifierSchemeCodeUnspsc190501.31282014'),
	'identifierSchemeCodeUnspsc190501.31282015': __('identifierSchemeCodeUnspsc190501.31282015'),
	'identifierSchemeCodeUnspsc190501.31282016': __('identifierSchemeCodeUnspsc190501.31282016'),
	'identifierSchemeCodeUnspsc190501.31282017': __('identifierSchemeCodeUnspsc190501.31282017'),
	'identifierSchemeCodeUnspsc190501.31282018': __('identifierSchemeCodeUnspsc190501.31282018'),
	'identifierSchemeCodeUnspsc190501.31282019': __('identifierSchemeCodeUnspsc190501.31282019'),
	'identifierSchemeCodeUnspsc190501.31282100': __('identifierSchemeCodeUnspsc190501.31282100'),
	'identifierSchemeCodeUnspsc190501.31282101': __('identifierSchemeCodeUnspsc190501.31282101'),
	'identifierSchemeCodeUnspsc190501.31282102': __('identifierSchemeCodeUnspsc190501.31282102'),
	'identifierSchemeCodeUnspsc190501.31282103': __('identifierSchemeCodeUnspsc190501.31282103'),
	'identifierSchemeCodeUnspsc190501.31282104': __('identifierSchemeCodeUnspsc190501.31282104'),
	'identifierSchemeCodeUnspsc190501.31282105': __('identifierSchemeCodeUnspsc190501.31282105'),
	'identifierSchemeCodeUnspsc190501.31282106': __('identifierSchemeCodeUnspsc190501.31282106'),
	'identifierSchemeCodeUnspsc190501.31282107': __('identifierSchemeCodeUnspsc190501.31282107'),
	'identifierSchemeCodeUnspsc190501.31282108': __('identifierSchemeCodeUnspsc190501.31282108'),
	'identifierSchemeCodeUnspsc190501.31282109': __('identifierSchemeCodeUnspsc190501.31282109'),
	'identifierSchemeCodeUnspsc190501.31282110': __('identifierSchemeCodeUnspsc190501.31282110'),
	'identifierSchemeCodeUnspsc190501.31282111': __('identifierSchemeCodeUnspsc190501.31282111'),
	'identifierSchemeCodeUnspsc190501.31282112': __('identifierSchemeCodeUnspsc190501.31282112'),
	'identifierSchemeCodeUnspsc190501.31282113': __('identifierSchemeCodeUnspsc190501.31282113'),
	'identifierSchemeCodeUnspsc190501.31282114': __('identifierSchemeCodeUnspsc190501.31282114'),
	'identifierSchemeCodeUnspsc190501.31282115': __('identifierSchemeCodeUnspsc190501.31282115'),
	'identifierSchemeCodeUnspsc190501.31282116': __('identifierSchemeCodeUnspsc190501.31282116'),
	'identifierSchemeCodeUnspsc190501.31282117': __('identifierSchemeCodeUnspsc190501.31282117'),
	'identifierSchemeCodeUnspsc190501.31282118': __('identifierSchemeCodeUnspsc190501.31282118'),
	'identifierSchemeCodeUnspsc190501.31282119': __('identifierSchemeCodeUnspsc190501.31282119'),
	'identifierSchemeCodeUnspsc190501.31282200': __('identifierSchemeCodeUnspsc190501.31282200'),
	'identifierSchemeCodeUnspsc190501.31282201': __('identifierSchemeCodeUnspsc190501.31282201'),
	'identifierSchemeCodeUnspsc190501.31282202': __('identifierSchemeCodeUnspsc190501.31282202'),
	'identifierSchemeCodeUnspsc190501.31282203': __('identifierSchemeCodeUnspsc190501.31282203'),
	'identifierSchemeCodeUnspsc190501.31282204': __('identifierSchemeCodeUnspsc190501.31282204'),
	'identifierSchemeCodeUnspsc190501.31282205': __('identifierSchemeCodeUnspsc190501.31282205'),
	'identifierSchemeCodeUnspsc190501.31282206': __('identifierSchemeCodeUnspsc190501.31282206'),
	'identifierSchemeCodeUnspsc190501.31282207': __('identifierSchemeCodeUnspsc190501.31282207'),
	'identifierSchemeCodeUnspsc190501.31282208': __('identifierSchemeCodeUnspsc190501.31282208'),
	'identifierSchemeCodeUnspsc190501.31282209': __('identifierSchemeCodeUnspsc190501.31282209'),
	'identifierSchemeCodeUnspsc190501.31282210': __('identifierSchemeCodeUnspsc190501.31282210'),
	'identifierSchemeCodeUnspsc190501.31282211': __('identifierSchemeCodeUnspsc190501.31282211'),
	'identifierSchemeCodeUnspsc190501.31282212': __('identifierSchemeCodeUnspsc190501.31282212'),
	'identifierSchemeCodeUnspsc190501.31282213': __('identifierSchemeCodeUnspsc190501.31282213'),
	'identifierSchemeCodeUnspsc190501.31282214': __('identifierSchemeCodeUnspsc190501.31282214'),
	'identifierSchemeCodeUnspsc190501.31282215': __('identifierSchemeCodeUnspsc190501.31282215'),
	'identifierSchemeCodeUnspsc190501.31282216': __('identifierSchemeCodeUnspsc190501.31282216'),
	'identifierSchemeCodeUnspsc190501.31282217': __('identifierSchemeCodeUnspsc190501.31282217'),
	'identifierSchemeCodeUnspsc190501.31282218': __('identifierSchemeCodeUnspsc190501.31282218'),
	'identifierSchemeCodeUnspsc190501.31282219': __('identifierSchemeCodeUnspsc190501.31282219'),
	'identifierSchemeCodeUnspsc190501.31282300': __('identifierSchemeCodeUnspsc190501.31282300'),
	'identifierSchemeCodeUnspsc190501.31282301': __('identifierSchemeCodeUnspsc190501.31282301'),
	'identifierSchemeCodeUnspsc190501.31282302': __('identifierSchemeCodeUnspsc190501.31282302'),
	'identifierSchemeCodeUnspsc190501.31282303': __('identifierSchemeCodeUnspsc190501.31282303'),
	'identifierSchemeCodeUnspsc190501.31282304': __('identifierSchemeCodeUnspsc190501.31282304'),
	'identifierSchemeCodeUnspsc190501.31282305': __('identifierSchemeCodeUnspsc190501.31282305'),
	'identifierSchemeCodeUnspsc190501.31282306': __('identifierSchemeCodeUnspsc190501.31282306'),
	'identifierSchemeCodeUnspsc190501.31282307': __('identifierSchemeCodeUnspsc190501.31282307'),
	'identifierSchemeCodeUnspsc190501.31282308': __('identifierSchemeCodeUnspsc190501.31282308'),
	'identifierSchemeCodeUnspsc190501.31282309': __('identifierSchemeCodeUnspsc190501.31282309'),
	'identifierSchemeCodeUnspsc190501.31282310': __('identifierSchemeCodeUnspsc190501.31282310'),
	'identifierSchemeCodeUnspsc190501.31282311': __('identifierSchemeCodeUnspsc190501.31282311'),
	'identifierSchemeCodeUnspsc190501.31282312': __('identifierSchemeCodeUnspsc190501.31282312'),
	'identifierSchemeCodeUnspsc190501.31282313': __('identifierSchemeCodeUnspsc190501.31282313'),
	'identifierSchemeCodeUnspsc190501.31282314': __('identifierSchemeCodeUnspsc190501.31282314'),
	'identifierSchemeCodeUnspsc190501.31282315': __('identifierSchemeCodeUnspsc190501.31282315'),
	'identifierSchemeCodeUnspsc190501.31282316': __('identifierSchemeCodeUnspsc190501.31282316'),
	'identifierSchemeCodeUnspsc190501.31282317': __('identifierSchemeCodeUnspsc190501.31282317'),
	'identifierSchemeCodeUnspsc190501.31282318': __('identifierSchemeCodeUnspsc190501.31282318'),
	'identifierSchemeCodeUnspsc190501.31282319': __('identifierSchemeCodeUnspsc190501.31282319'),
	'identifierSchemeCodeUnspsc190501.31282400': __('identifierSchemeCodeUnspsc190501.31282400'),
	'identifierSchemeCodeUnspsc190501.31282401': __('identifierSchemeCodeUnspsc190501.31282401'),
	'identifierSchemeCodeUnspsc190501.31282402': __('identifierSchemeCodeUnspsc190501.31282402'),
	'identifierSchemeCodeUnspsc190501.31282403': __('identifierSchemeCodeUnspsc190501.31282403'),
	'identifierSchemeCodeUnspsc190501.31282404': __('identifierSchemeCodeUnspsc190501.31282404'),
	'identifierSchemeCodeUnspsc190501.31282405': __('identifierSchemeCodeUnspsc190501.31282405'),
	'identifierSchemeCodeUnspsc190501.31282406': __('identifierSchemeCodeUnspsc190501.31282406'),
	'identifierSchemeCodeUnspsc190501.31282407': __('identifierSchemeCodeUnspsc190501.31282407'),
	'identifierSchemeCodeUnspsc190501.31282408': __('identifierSchemeCodeUnspsc190501.31282408'),
	'identifierSchemeCodeUnspsc190501.31282409': __('identifierSchemeCodeUnspsc190501.31282409'),
	'identifierSchemeCodeUnspsc190501.31282410': __('identifierSchemeCodeUnspsc190501.31282410'),
	'identifierSchemeCodeUnspsc190501.31282411': __('identifierSchemeCodeUnspsc190501.31282411'),
	'identifierSchemeCodeUnspsc190501.31282412': __('identifierSchemeCodeUnspsc190501.31282412'),
	'identifierSchemeCodeUnspsc190501.31282413': __('identifierSchemeCodeUnspsc190501.31282413'),
	'identifierSchemeCodeUnspsc190501.31282414': __('identifierSchemeCodeUnspsc190501.31282414'),
	'identifierSchemeCodeUnspsc190501.31282415': __('identifierSchemeCodeUnspsc190501.31282415'),
	'identifierSchemeCodeUnspsc190501.31282416': __('identifierSchemeCodeUnspsc190501.31282416'),
	'identifierSchemeCodeUnspsc190501.31282417': __('identifierSchemeCodeUnspsc190501.31282417'),
	'identifierSchemeCodeUnspsc190501.31282418': __('identifierSchemeCodeUnspsc190501.31282418'),
	'identifierSchemeCodeUnspsc190501.31282419': __('identifierSchemeCodeUnspsc190501.31282419'),
	'identifierSchemeCodeUnspsc190501.31290000': __('identifierSchemeCodeUnspsc190501.31290000'),
	'identifierSchemeCodeUnspsc190501.31291100': __('identifierSchemeCodeUnspsc190501.31291100'),
	'identifierSchemeCodeUnspsc190501.31291101': __('identifierSchemeCodeUnspsc190501.31291101'),
	'identifierSchemeCodeUnspsc190501.31291102': __('identifierSchemeCodeUnspsc190501.31291102'),
	'identifierSchemeCodeUnspsc190501.31291103': __('identifierSchemeCodeUnspsc190501.31291103'),
	'identifierSchemeCodeUnspsc190501.31291104': __('identifierSchemeCodeUnspsc190501.31291104'),
	'identifierSchemeCodeUnspsc190501.31291105': __('identifierSchemeCodeUnspsc190501.31291105'),
	'identifierSchemeCodeUnspsc190501.31291106': __('identifierSchemeCodeUnspsc190501.31291106'),
	'identifierSchemeCodeUnspsc190501.31291107': __('identifierSchemeCodeUnspsc190501.31291107'),
	'identifierSchemeCodeUnspsc190501.31291108': __('identifierSchemeCodeUnspsc190501.31291108'),
	'identifierSchemeCodeUnspsc190501.31291109': __('identifierSchemeCodeUnspsc190501.31291109'),
	'identifierSchemeCodeUnspsc190501.31291110': __('identifierSchemeCodeUnspsc190501.31291110'),
	'identifierSchemeCodeUnspsc190501.31291111': __('identifierSchemeCodeUnspsc190501.31291111'),
	'identifierSchemeCodeUnspsc190501.31291112': __('identifierSchemeCodeUnspsc190501.31291112'),
	'identifierSchemeCodeUnspsc190501.31291113': __('identifierSchemeCodeUnspsc190501.31291113'),
	'identifierSchemeCodeUnspsc190501.31291114': __('identifierSchemeCodeUnspsc190501.31291114'),
	'identifierSchemeCodeUnspsc190501.31291115': __('identifierSchemeCodeUnspsc190501.31291115'),
	'identifierSchemeCodeUnspsc190501.31291116': __('identifierSchemeCodeUnspsc190501.31291116'),
	'identifierSchemeCodeUnspsc190501.31291117': __('identifierSchemeCodeUnspsc190501.31291117'),
	'identifierSchemeCodeUnspsc190501.31291118': __('identifierSchemeCodeUnspsc190501.31291118'),
	'identifierSchemeCodeUnspsc190501.31291119': __('identifierSchemeCodeUnspsc190501.31291119'),
	'identifierSchemeCodeUnspsc190501.31291120': __('identifierSchemeCodeUnspsc190501.31291120'),
	'identifierSchemeCodeUnspsc190501.31291200': __('identifierSchemeCodeUnspsc190501.31291200'),
	'identifierSchemeCodeUnspsc190501.31291201': __('identifierSchemeCodeUnspsc190501.31291201'),
	'identifierSchemeCodeUnspsc190501.31291202': __('identifierSchemeCodeUnspsc190501.31291202'),
	'identifierSchemeCodeUnspsc190501.31291203': __('identifierSchemeCodeUnspsc190501.31291203'),
	'identifierSchemeCodeUnspsc190501.31291204': __('identifierSchemeCodeUnspsc190501.31291204'),
	'identifierSchemeCodeUnspsc190501.31291205': __('identifierSchemeCodeUnspsc190501.31291205'),
	'identifierSchemeCodeUnspsc190501.31291206': __('identifierSchemeCodeUnspsc190501.31291206'),
	'identifierSchemeCodeUnspsc190501.31291207': __('identifierSchemeCodeUnspsc190501.31291207'),
	'identifierSchemeCodeUnspsc190501.31291208': __('identifierSchemeCodeUnspsc190501.31291208'),
	'identifierSchemeCodeUnspsc190501.31291209': __('identifierSchemeCodeUnspsc190501.31291209'),
	'identifierSchemeCodeUnspsc190501.31291210': __('identifierSchemeCodeUnspsc190501.31291210'),
	'identifierSchemeCodeUnspsc190501.31291211': __('identifierSchemeCodeUnspsc190501.31291211'),
	'identifierSchemeCodeUnspsc190501.31291212': __('identifierSchemeCodeUnspsc190501.31291212'),
	'identifierSchemeCodeUnspsc190501.31291213': __('identifierSchemeCodeUnspsc190501.31291213'),
	'identifierSchemeCodeUnspsc190501.31291214': __('identifierSchemeCodeUnspsc190501.31291214'),
	'identifierSchemeCodeUnspsc190501.31291215': __('identifierSchemeCodeUnspsc190501.31291215'),
	'identifierSchemeCodeUnspsc190501.31291216': __('identifierSchemeCodeUnspsc190501.31291216'),
	'identifierSchemeCodeUnspsc190501.31291217': __('identifierSchemeCodeUnspsc190501.31291217'),
	'identifierSchemeCodeUnspsc190501.31291218': __('identifierSchemeCodeUnspsc190501.31291218'),
	'identifierSchemeCodeUnspsc190501.31291219': __('identifierSchemeCodeUnspsc190501.31291219'),
	'identifierSchemeCodeUnspsc190501.31291220': __('identifierSchemeCodeUnspsc190501.31291220'),
	'identifierSchemeCodeUnspsc190501.31291300': __('identifierSchemeCodeUnspsc190501.31291300'),
	'identifierSchemeCodeUnspsc190501.31291301': __('identifierSchemeCodeUnspsc190501.31291301'),
	'identifierSchemeCodeUnspsc190501.31291302': __('identifierSchemeCodeUnspsc190501.31291302'),
	'identifierSchemeCodeUnspsc190501.31291303': __('identifierSchemeCodeUnspsc190501.31291303'),
	'identifierSchemeCodeUnspsc190501.31291304': __('identifierSchemeCodeUnspsc190501.31291304'),
	'identifierSchemeCodeUnspsc190501.31291305': __('identifierSchemeCodeUnspsc190501.31291305'),
	'identifierSchemeCodeUnspsc190501.31291306': __('identifierSchemeCodeUnspsc190501.31291306'),
	'identifierSchemeCodeUnspsc190501.31291307': __('identifierSchemeCodeUnspsc190501.31291307'),
	'identifierSchemeCodeUnspsc190501.31291308': __('identifierSchemeCodeUnspsc190501.31291308'),
	'identifierSchemeCodeUnspsc190501.31291309': __('identifierSchemeCodeUnspsc190501.31291309'),
	'identifierSchemeCodeUnspsc190501.31291310': __('identifierSchemeCodeUnspsc190501.31291310'),
	'identifierSchemeCodeUnspsc190501.31291311': __('identifierSchemeCodeUnspsc190501.31291311'),
	'identifierSchemeCodeUnspsc190501.31291312': __('identifierSchemeCodeUnspsc190501.31291312'),
	'identifierSchemeCodeUnspsc190501.31291313': __('identifierSchemeCodeUnspsc190501.31291313'),
	'identifierSchemeCodeUnspsc190501.31291314': __('identifierSchemeCodeUnspsc190501.31291314'),
	'identifierSchemeCodeUnspsc190501.31291315': __('identifierSchemeCodeUnspsc190501.31291315'),
	'identifierSchemeCodeUnspsc190501.31291316': __('identifierSchemeCodeUnspsc190501.31291316'),
	'identifierSchemeCodeUnspsc190501.31291317': __('identifierSchemeCodeUnspsc190501.31291317'),
	'identifierSchemeCodeUnspsc190501.31291318': __('identifierSchemeCodeUnspsc190501.31291318'),
	'identifierSchemeCodeUnspsc190501.31291319': __('identifierSchemeCodeUnspsc190501.31291319'),
	'identifierSchemeCodeUnspsc190501.31291320': __('identifierSchemeCodeUnspsc190501.31291320'),
	'identifierSchemeCodeUnspsc190501.31291400': __('identifierSchemeCodeUnspsc190501.31291400'),
	'identifierSchemeCodeUnspsc190501.31291401': __('identifierSchemeCodeUnspsc190501.31291401'),
	'identifierSchemeCodeUnspsc190501.31291402': __('identifierSchemeCodeUnspsc190501.31291402'),
	'identifierSchemeCodeUnspsc190501.31291403': __('identifierSchemeCodeUnspsc190501.31291403'),
	'identifierSchemeCodeUnspsc190501.31291404': __('identifierSchemeCodeUnspsc190501.31291404'),
	'identifierSchemeCodeUnspsc190501.31291405': __('identifierSchemeCodeUnspsc190501.31291405'),
	'identifierSchemeCodeUnspsc190501.31291406': __('identifierSchemeCodeUnspsc190501.31291406'),
	'identifierSchemeCodeUnspsc190501.31291407': __('identifierSchemeCodeUnspsc190501.31291407'),
	'identifierSchemeCodeUnspsc190501.31291408': __('identifierSchemeCodeUnspsc190501.31291408'),
	'identifierSchemeCodeUnspsc190501.31291409': __('identifierSchemeCodeUnspsc190501.31291409'),
	'identifierSchemeCodeUnspsc190501.31291410': __('identifierSchemeCodeUnspsc190501.31291410'),
	'identifierSchemeCodeUnspsc190501.31291411': __('identifierSchemeCodeUnspsc190501.31291411'),
	'identifierSchemeCodeUnspsc190501.31291412': __('identifierSchemeCodeUnspsc190501.31291412'),
	'identifierSchemeCodeUnspsc190501.31291413': __('identifierSchemeCodeUnspsc190501.31291413'),
	'identifierSchemeCodeUnspsc190501.31291414': __('identifierSchemeCodeUnspsc190501.31291414'),
	'identifierSchemeCodeUnspsc190501.31291415': __('identifierSchemeCodeUnspsc190501.31291415'),
	'identifierSchemeCodeUnspsc190501.31291416': __('identifierSchemeCodeUnspsc190501.31291416'),
	'identifierSchemeCodeUnspsc190501.31291417': __('identifierSchemeCodeUnspsc190501.31291417'),
	'identifierSchemeCodeUnspsc190501.31291418': __('identifierSchemeCodeUnspsc190501.31291418'),
	'identifierSchemeCodeUnspsc190501.31291419': __('identifierSchemeCodeUnspsc190501.31291419'),
	'identifierSchemeCodeUnspsc190501.31291420': __('identifierSchemeCodeUnspsc190501.31291420'),
	'identifierSchemeCodeUnspsc190501.31300000': __('identifierSchemeCodeUnspsc190501.31300000'),
	'identifierSchemeCodeUnspsc190501.31301100': __('identifierSchemeCodeUnspsc190501.31301100'),
	'identifierSchemeCodeUnspsc190501.31301101': __('identifierSchemeCodeUnspsc190501.31301101'),
	'identifierSchemeCodeUnspsc190501.31301102': __('identifierSchemeCodeUnspsc190501.31301102'),
	'identifierSchemeCodeUnspsc190501.31301103': __('identifierSchemeCodeUnspsc190501.31301103'),
	'identifierSchemeCodeUnspsc190501.31301104': __('identifierSchemeCodeUnspsc190501.31301104'),
	'identifierSchemeCodeUnspsc190501.31301105': __('identifierSchemeCodeUnspsc190501.31301105'),
	'identifierSchemeCodeUnspsc190501.31301106': __('identifierSchemeCodeUnspsc190501.31301106'),
	'identifierSchemeCodeUnspsc190501.31301107': __('identifierSchemeCodeUnspsc190501.31301107'),
	'identifierSchemeCodeUnspsc190501.31301108': __('identifierSchemeCodeUnspsc190501.31301108'),
	'identifierSchemeCodeUnspsc190501.31301109': __('identifierSchemeCodeUnspsc190501.31301109'),
	'identifierSchemeCodeUnspsc190501.31301110': __('identifierSchemeCodeUnspsc190501.31301110'),
	'identifierSchemeCodeUnspsc190501.31301111': __('identifierSchemeCodeUnspsc190501.31301111'),
	'identifierSchemeCodeUnspsc190501.31301112': __('identifierSchemeCodeUnspsc190501.31301112'),
	'identifierSchemeCodeUnspsc190501.31301113': __('identifierSchemeCodeUnspsc190501.31301113'),
	'identifierSchemeCodeUnspsc190501.31301114': __('identifierSchemeCodeUnspsc190501.31301114'),
	'identifierSchemeCodeUnspsc190501.31301115': __('identifierSchemeCodeUnspsc190501.31301115'),
	'identifierSchemeCodeUnspsc190501.31301116': __('identifierSchemeCodeUnspsc190501.31301116'),
	'identifierSchemeCodeUnspsc190501.31301117': __('identifierSchemeCodeUnspsc190501.31301117'),
	'identifierSchemeCodeUnspsc190501.31301118': __('identifierSchemeCodeUnspsc190501.31301118'),
	'identifierSchemeCodeUnspsc190501.31301119': __('identifierSchemeCodeUnspsc190501.31301119'),
	'identifierSchemeCodeUnspsc190501.31301200': __('identifierSchemeCodeUnspsc190501.31301200'),
	'identifierSchemeCodeUnspsc190501.31301201': __('identifierSchemeCodeUnspsc190501.31301201'),
	'identifierSchemeCodeUnspsc190501.31301202': __('identifierSchemeCodeUnspsc190501.31301202'),
	'identifierSchemeCodeUnspsc190501.31301203': __('identifierSchemeCodeUnspsc190501.31301203'),
	'identifierSchemeCodeUnspsc190501.31301204': __('identifierSchemeCodeUnspsc190501.31301204'),
	'identifierSchemeCodeUnspsc190501.31301205': __('identifierSchemeCodeUnspsc190501.31301205'),
	'identifierSchemeCodeUnspsc190501.31301206': __('identifierSchemeCodeUnspsc190501.31301206'),
	'identifierSchemeCodeUnspsc190501.31301207': __('identifierSchemeCodeUnspsc190501.31301207'),
	'identifierSchemeCodeUnspsc190501.31301208': __('identifierSchemeCodeUnspsc190501.31301208'),
	'identifierSchemeCodeUnspsc190501.31301209': __('identifierSchemeCodeUnspsc190501.31301209'),
	'identifierSchemeCodeUnspsc190501.31301210': __('identifierSchemeCodeUnspsc190501.31301210'),
	'identifierSchemeCodeUnspsc190501.31301211': __('identifierSchemeCodeUnspsc190501.31301211'),
	'identifierSchemeCodeUnspsc190501.31301212': __('identifierSchemeCodeUnspsc190501.31301212'),
	'identifierSchemeCodeUnspsc190501.31301213': __('identifierSchemeCodeUnspsc190501.31301213'),
	'identifierSchemeCodeUnspsc190501.31301214': __('identifierSchemeCodeUnspsc190501.31301214'),
	'identifierSchemeCodeUnspsc190501.31301215': __('identifierSchemeCodeUnspsc190501.31301215'),
	'identifierSchemeCodeUnspsc190501.31301216': __('identifierSchemeCodeUnspsc190501.31301216'),
	'identifierSchemeCodeUnspsc190501.31301217': __('identifierSchemeCodeUnspsc190501.31301217'),
	'identifierSchemeCodeUnspsc190501.31301218': __('identifierSchemeCodeUnspsc190501.31301218'),
	'identifierSchemeCodeUnspsc190501.31301219': __('identifierSchemeCodeUnspsc190501.31301219'),
	'identifierSchemeCodeUnspsc190501.31301300': __('identifierSchemeCodeUnspsc190501.31301300'),
	'identifierSchemeCodeUnspsc190501.31301301': __('identifierSchemeCodeUnspsc190501.31301301'),
	'identifierSchemeCodeUnspsc190501.31301302': __('identifierSchemeCodeUnspsc190501.31301302'),
	'identifierSchemeCodeUnspsc190501.31301303': __('identifierSchemeCodeUnspsc190501.31301303'),
	'identifierSchemeCodeUnspsc190501.31301304': __('identifierSchemeCodeUnspsc190501.31301304'),
	'identifierSchemeCodeUnspsc190501.31301305': __('identifierSchemeCodeUnspsc190501.31301305'),
	'identifierSchemeCodeUnspsc190501.31301306': __('identifierSchemeCodeUnspsc190501.31301306'),
	'identifierSchemeCodeUnspsc190501.31301307': __('identifierSchemeCodeUnspsc190501.31301307'),
	'identifierSchemeCodeUnspsc190501.31301308': __('identifierSchemeCodeUnspsc190501.31301308'),
	'identifierSchemeCodeUnspsc190501.31301309': __('identifierSchemeCodeUnspsc190501.31301309'),
	'identifierSchemeCodeUnspsc190501.31301310': __('identifierSchemeCodeUnspsc190501.31301310'),
	'identifierSchemeCodeUnspsc190501.31301311': __('identifierSchemeCodeUnspsc190501.31301311'),
	'identifierSchemeCodeUnspsc190501.31301312': __('identifierSchemeCodeUnspsc190501.31301312'),
	'identifierSchemeCodeUnspsc190501.31301313': __('identifierSchemeCodeUnspsc190501.31301313'),
	'identifierSchemeCodeUnspsc190501.31301314': __('identifierSchemeCodeUnspsc190501.31301314'),
	'identifierSchemeCodeUnspsc190501.31301315': __('identifierSchemeCodeUnspsc190501.31301315'),
	'identifierSchemeCodeUnspsc190501.31301316': __('identifierSchemeCodeUnspsc190501.31301316'),
	'identifierSchemeCodeUnspsc190501.31301317': __('identifierSchemeCodeUnspsc190501.31301317'),
	'identifierSchemeCodeUnspsc190501.31301318': __('identifierSchemeCodeUnspsc190501.31301318'),
	'identifierSchemeCodeUnspsc190501.31301319': __('identifierSchemeCodeUnspsc190501.31301319'),
	'identifierSchemeCodeUnspsc190501.31301400': __('identifierSchemeCodeUnspsc190501.31301400'),
	'identifierSchemeCodeUnspsc190501.31301401': __('identifierSchemeCodeUnspsc190501.31301401'),
	'identifierSchemeCodeUnspsc190501.31301402': __('identifierSchemeCodeUnspsc190501.31301402'),
	'identifierSchemeCodeUnspsc190501.31301403': __('identifierSchemeCodeUnspsc190501.31301403'),
	'identifierSchemeCodeUnspsc190501.31301404': __('identifierSchemeCodeUnspsc190501.31301404'),
	'identifierSchemeCodeUnspsc190501.31301405': __('identifierSchemeCodeUnspsc190501.31301405'),
	'identifierSchemeCodeUnspsc190501.31301406': __('identifierSchemeCodeUnspsc190501.31301406'),
	'identifierSchemeCodeUnspsc190501.31301407': __('identifierSchemeCodeUnspsc190501.31301407'),
	'identifierSchemeCodeUnspsc190501.31301408': __('identifierSchemeCodeUnspsc190501.31301408'),
	'identifierSchemeCodeUnspsc190501.31301409': __('identifierSchemeCodeUnspsc190501.31301409'),
	'identifierSchemeCodeUnspsc190501.31301410': __('identifierSchemeCodeUnspsc190501.31301410'),
	'identifierSchemeCodeUnspsc190501.31301411': __('identifierSchemeCodeUnspsc190501.31301411'),
	'identifierSchemeCodeUnspsc190501.31301412': __('identifierSchemeCodeUnspsc190501.31301412'),
	'identifierSchemeCodeUnspsc190501.31301413': __('identifierSchemeCodeUnspsc190501.31301413'),
	'identifierSchemeCodeUnspsc190501.31301414': __('identifierSchemeCodeUnspsc190501.31301414'),
	'identifierSchemeCodeUnspsc190501.31301415': __('identifierSchemeCodeUnspsc190501.31301415'),
	'identifierSchemeCodeUnspsc190501.31301416': __('identifierSchemeCodeUnspsc190501.31301416'),
	'identifierSchemeCodeUnspsc190501.31301417': __('identifierSchemeCodeUnspsc190501.31301417'),
	'identifierSchemeCodeUnspsc190501.31301418': __('identifierSchemeCodeUnspsc190501.31301418'),
	'identifierSchemeCodeUnspsc190501.31301419': __('identifierSchemeCodeUnspsc190501.31301419'),
	'identifierSchemeCodeUnspsc190501.31301500': __('identifierSchemeCodeUnspsc190501.31301500'),
	'identifierSchemeCodeUnspsc190501.31301501': __('identifierSchemeCodeUnspsc190501.31301501'),
	'identifierSchemeCodeUnspsc190501.31301502': __('identifierSchemeCodeUnspsc190501.31301502'),
	'identifierSchemeCodeUnspsc190501.31301503': __('identifierSchemeCodeUnspsc190501.31301503'),
	'identifierSchemeCodeUnspsc190501.31301504': __('identifierSchemeCodeUnspsc190501.31301504'),
	'identifierSchemeCodeUnspsc190501.31301505': __('identifierSchemeCodeUnspsc190501.31301505'),
	'identifierSchemeCodeUnspsc190501.31301506': __('identifierSchemeCodeUnspsc190501.31301506'),
	'identifierSchemeCodeUnspsc190501.31301507': __('identifierSchemeCodeUnspsc190501.31301507'),
	'identifierSchemeCodeUnspsc190501.31301508': __('identifierSchemeCodeUnspsc190501.31301508'),
	'identifierSchemeCodeUnspsc190501.31301509': __('identifierSchemeCodeUnspsc190501.31301509'),
	'identifierSchemeCodeUnspsc190501.31301510': __('identifierSchemeCodeUnspsc190501.31301510'),
	'identifierSchemeCodeUnspsc190501.31301511': __('identifierSchemeCodeUnspsc190501.31301511'),
	'identifierSchemeCodeUnspsc190501.31301512': __('identifierSchemeCodeUnspsc190501.31301512'),
	'identifierSchemeCodeUnspsc190501.31301513': __('identifierSchemeCodeUnspsc190501.31301513'),
	'identifierSchemeCodeUnspsc190501.31301514': __('identifierSchemeCodeUnspsc190501.31301514'),
	'identifierSchemeCodeUnspsc190501.31301515': __('identifierSchemeCodeUnspsc190501.31301515'),
	'identifierSchemeCodeUnspsc190501.31301516': __('identifierSchemeCodeUnspsc190501.31301516'),
	'identifierSchemeCodeUnspsc190501.31301517': __('identifierSchemeCodeUnspsc190501.31301517'),
	'identifierSchemeCodeUnspsc190501.31301518': __('identifierSchemeCodeUnspsc190501.31301518'),
	'identifierSchemeCodeUnspsc190501.31301519': __('identifierSchemeCodeUnspsc190501.31301519'),
	'identifierSchemeCodeUnspsc190501.31310000': __('identifierSchemeCodeUnspsc190501.31310000'),
	'identifierSchemeCodeUnspsc190501.31311100': __('identifierSchemeCodeUnspsc190501.31311100'),
	'identifierSchemeCodeUnspsc190501.31311101': __('identifierSchemeCodeUnspsc190501.31311101'),
	'identifierSchemeCodeUnspsc190501.31311102': __('identifierSchemeCodeUnspsc190501.31311102'),
	'identifierSchemeCodeUnspsc190501.31311103': __('identifierSchemeCodeUnspsc190501.31311103'),
	'identifierSchemeCodeUnspsc190501.31311104': __('identifierSchemeCodeUnspsc190501.31311104'),
	'identifierSchemeCodeUnspsc190501.31311105': __('identifierSchemeCodeUnspsc190501.31311105'),
	'identifierSchemeCodeUnspsc190501.31311106': __('identifierSchemeCodeUnspsc190501.31311106'),
	'identifierSchemeCodeUnspsc190501.31311109': __('identifierSchemeCodeUnspsc190501.31311109'),
	'identifierSchemeCodeUnspsc190501.31311110': __('identifierSchemeCodeUnspsc190501.31311110'),
	'identifierSchemeCodeUnspsc190501.31311111': __('identifierSchemeCodeUnspsc190501.31311111'),
	'identifierSchemeCodeUnspsc190501.31311112': __('identifierSchemeCodeUnspsc190501.31311112'),
	'identifierSchemeCodeUnspsc190501.31311113': __('identifierSchemeCodeUnspsc190501.31311113'),
	'identifierSchemeCodeUnspsc190501.31311200': __('identifierSchemeCodeUnspsc190501.31311200'),
	'identifierSchemeCodeUnspsc190501.31311201': __('identifierSchemeCodeUnspsc190501.31311201'),
	'identifierSchemeCodeUnspsc190501.31311202': __('identifierSchemeCodeUnspsc190501.31311202'),
	'identifierSchemeCodeUnspsc190501.31311203': __('identifierSchemeCodeUnspsc190501.31311203'),
	'identifierSchemeCodeUnspsc190501.31311204': __('identifierSchemeCodeUnspsc190501.31311204'),
	'identifierSchemeCodeUnspsc190501.31311205': __('identifierSchemeCodeUnspsc190501.31311205'),
	'identifierSchemeCodeUnspsc190501.31311206': __('identifierSchemeCodeUnspsc190501.31311206'),
	'identifierSchemeCodeUnspsc190501.31311209': __('identifierSchemeCodeUnspsc190501.31311209'),
	'identifierSchemeCodeUnspsc190501.31311210': __('identifierSchemeCodeUnspsc190501.31311210'),
	'identifierSchemeCodeUnspsc190501.31311211': __('identifierSchemeCodeUnspsc190501.31311211'),
	'identifierSchemeCodeUnspsc190501.31311212': __('identifierSchemeCodeUnspsc190501.31311212'),
	'identifierSchemeCodeUnspsc190501.31311213': __('identifierSchemeCodeUnspsc190501.31311213'),
	'identifierSchemeCodeUnspsc190501.31311300': __('identifierSchemeCodeUnspsc190501.31311300'),
	'identifierSchemeCodeUnspsc190501.31311301': __('identifierSchemeCodeUnspsc190501.31311301'),
	'identifierSchemeCodeUnspsc190501.31311302': __('identifierSchemeCodeUnspsc190501.31311302'),
	'identifierSchemeCodeUnspsc190501.31311303': __('identifierSchemeCodeUnspsc190501.31311303'),
	'identifierSchemeCodeUnspsc190501.31311304': __('identifierSchemeCodeUnspsc190501.31311304'),
	'identifierSchemeCodeUnspsc190501.31311305': __('identifierSchemeCodeUnspsc190501.31311305'),
	'identifierSchemeCodeUnspsc190501.31311306': __('identifierSchemeCodeUnspsc190501.31311306'),
	'identifierSchemeCodeUnspsc190501.31311309': __('identifierSchemeCodeUnspsc190501.31311309'),
	'identifierSchemeCodeUnspsc190501.31311310': __('identifierSchemeCodeUnspsc190501.31311310'),
	'identifierSchemeCodeUnspsc190501.31311311': __('identifierSchemeCodeUnspsc190501.31311311'),
	'identifierSchemeCodeUnspsc190501.31311312': __('identifierSchemeCodeUnspsc190501.31311312'),
	'identifierSchemeCodeUnspsc190501.31311313': __('identifierSchemeCodeUnspsc190501.31311313'),
	'identifierSchemeCodeUnspsc190501.31311400': __('identifierSchemeCodeUnspsc190501.31311400'),
	'identifierSchemeCodeUnspsc190501.31311401': __('identifierSchemeCodeUnspsc190501.31311401'),
	'identifierSchemeCodeUnspsc190501.31311402': __('identifierSchemeCodeUnspsc190501.31311402'),
	'identifierSchemeCodeUnspsc190501.31311403': __('identifierSchemeCodeUnspsc190501.31311403'),
	'identifierSchemeCodeUnspsc190501.31311404': __('identifierSchemeCodeUnspsc190501.31311404'),
	'identifierSchemeCodeUnspsc190501.31311405': __('identifierSchemeCodeUnspsc190501.31311405'),
	'identifierSchemeCodeUnspsc190501.31311406': __('identifierSchemeCodeUnspsc190501.31311406'),
	'identifierSchemeCodeUnspsc190501.31311409': __('identifierSchemeCodeUnspsc190501.31311409'),
	'identifierSchemeCodeUnspsc190501.31311410': __('identifierSchemeCodeUnspsc190501.31311410'),
	'identifierSchemeCodeUnspsc190501.31311411': __('identifierSchemeCodeUnspsc190501.31311411'),
	'identifierSchemeCodeUnspsc190501.31311412': __('identifierSchemeCodeUnspsc190501.31311412'),
	'identifierSchemeCodeUnspsc190501.31311413': __('identifierSchemeCodeUnspsc190501.31311413'),
	'identifierSchemeCodeUnspsc190501.31311500': __('identifierSchemeCodeUnspsc190501.31311500'),
	'identifierSchemeCodeUnspsc190501.31311501': __('identifierSchemeCodeUnspsc190501.31311501'),
	'identifierSchemeCodeUnspsc190501.31311502': __('identifierSchemeCodeUnspsc190501.31311502'),
	'identifierSchemeCodeUnspsc190501.31311503': __('identifierSchemeCodeUnspsc190501.31311503'),
	'identifierSchemeCodeUnspsc190501.31311504': __('identifierSchemeCodeUnspsc190501.31311504'),
	'identifierSchemeCodeUnspsc190501.31311505': __('identifierSchemeCodeUnspsc190501.31311505'),
	'identifierSchemeCodeUnspsc190501.31311506': __('identifierSchemeCodeUnspsc190501.31311506'),
	'identifierSchemeCodeUnspsc190501.31311509': __('identifierSchemeCodeUnspsc190501.31311509'),
	'identifierSchemeCodeUnspsc190501.31311510': __('identifierSchemeCodeUnspsc190501.31311510'),
	'identifierSchemeCodeUnspsc190501.31311511': __('identifierSchemeCodeUnspsc190501.31311511'),
	'identifierSchemeCodeUnspsc190501.31311512': __('identifierSchemeCodeUnspsc190501.31311512'),
	'identifierSchemeCodeUnspsc190501.31311513': __('identifierSchemeCodeUnspsc190501.31311513'),
	'identifierSchemeCodeUnspsc190501.31311600': __('identifierSchemeCodeUnspsc190501.31311600'),
	'identifierSchemeCodeUnspsc190501.31311601': __('identifierSchemeCodeUnspsc190501.31311601'),
	'identifierSchemeCodeUnspsc190501.31311602': __('identifierSchemeCodeUnspsc190501.31311602'),
	'identifierSchemeCodeUnspsc190501.31311603': __('identifierSchemeCodeUnspsc190501.31311603'),
	'identifierSchemeCodeUnspsc190501.31311604': __('identifierSchemeCodeUnspsc190501.31311604'),
	'identifierSchemeCodeUnspsc190501.31311605': __('identifierSchemeCodeUnspsc190501.31311605'),
	'identifierSchemeCodeUnspsc190501.31311606': __('identifierSchemeCodeUnspsc190501.31311606'),
	'identifierSchemeCodeUnspsc190501.31311609': __('identifierSchemeCodeUnspsc190501.31311609'),
	'identifierSchemeCodeUnspsc190501.31311610': __('identifierSchemeCodeUnspsc190501.31311610'),
	'identifierSchemeCodeUnspsc190501.31311611': __('identifierSchemeCodeUnspsc190501.31311611'),
	'identifierSchemeCodeUnspsc190501.31311612': __('identifierSchemeCodeUnspsc190501.31311612'),
	'identifierSchemeCodeUnspsc190501.31311613': __('identifierSchemeCodeUnspsc190501.31311613'),
	'identifierSchemeCodeUnspsc190501.31311700': __('identifierSchemeCodeUnspsc190501.31311700'),
	'identifierSchemeCodeUnspsc190501.31311701': __('identifierSchemeCodeUnspsc190501.31311701'),
	'identifierSchemeCodeUnspsc190501.31311702': __('identifierSchemeCodeUnspsc190501.31311702'),
	'identifierSchemeCodeUnspsc190501.31311703': __('identifierSchemeCodeUnspsc190501.31311703'),
	'identifierSchemeCodeUnspsc190501.31311704': __('identifierSchemeCodeUnspsc190501.31311704'),
	'identifierSchemeCodeUnspsc190501.31311705': __('identifierSchemeCodeUnspsc190501.31311705'),
	'identifierSchemeCodeUnspsc190501.31311706': __('identifierSchemeCodeUnspsc190501.31311706'),
	'identifierSchemeCodeUnspsc190501.31311709': __('identifierSchemeCodeUnspsc190501.31311709'),
	'identifierSchemeCodeUnspsc190501.31311710': __('identifierSchemeCodeUnspsc190501.31311710'),
	'identifierSchemeCodeUnspsc190501.31311711': __('identifierSchemeCodeUnspsc190501.31311711'),
	'identifierSchemeCodeUnspsc190501.31311712': __('identifierSchemeCodeUnspsc190501.31311712'),
	'identifierSchemeCodeUnspsc190501.31311713': __('identifierSchemeCodeUnspsc190501.31311713'),
	'identifierSchemeCodeUnspsc190501.31320000': __('identifierSchemeCodeUnspsc190501.31320000'),
	'identifierSchemeCodeUnspsc190501.31321100': __('identifierSchemeCodeUnspsc190501.31321100'),
	'identifierSchemeCodeUnspsc190501.31321101': __('identifierSchemeCodeUnspsc190501.31321101'),
	'identifierSchemeCodeUnspsc190501.31321102': __('identifierSchemeCodeUnspsc190501.31321102'),
	'identifierSchemeCodeUnspsc190501.31321103': __('identifierSchemeCodeUnspsc190501.31321103'),
	'identifierSchemeCodeUnspsc190501.31321104': __('identifierSchemeCodeUnspsc190501.31321104'),
	'identifierSchemeCodeUnspsc190501.31321105': __('identifierSchemeCodeUnspsc190501.31321105'),
	'identifierSchemeCodeUnspsc190501.31321106': __('identifierSchemeCodeUnspsc190501.31321106'),
	'identifierSchemeCodeUnspsc190501.31321109': __('identifierSchemeCodeUnspsc190501.31321109'),
	'identifierSchemeCodeUnspsc190501.31321110': __('identifierSchemeCodeUnspsc190501.31321110'),
	'identifierSchemeCodeUnspsc190501.31321111': __('identifierSchemeCodeUnspsc190501.31321111'),
	'identifierSchemeCodeUnspsc190501.31321112': __('identifierSchemeCodeUnspsc190501.31321112'),
	'identifierSchemeCodeUnspsc190501.31321113': __('identifierSchemeCodeUnspsc190501.31321113'),
	'identifierSchemeCodeUnspsc190501.31321200': __('identifierSchemeCodeUnspsc190501.31321200'),
	'identifierSchemeCodeUnspsc190501.31321201': __('identifierSchemeCodeUnspsc190501.31321201'),
	'identifierSchemeCodeUnspsc190501.31321202': __('identifierSchemeCodeUnspsc190501.31321202'),
	'identifierSchemeCodeUnspsc190501.31321203': __('identifierSchemeCodeUnspsc190501.31321203'),
	'identifierSchemeCodeUnspsc190501.31321204': __('identifierSchemeCodeUnspsc190501.31321204'),
	'identifierSchemeCodeUnspsc190501.31321205': __('identifierSchemeCodeUnspsc190501.31321205'),
	'identifierSchemeCodeUnspsc190501.31321206': __('identifierSchemeCodeUnspsc190501.31321206'),
	'identifierSchemeCodeUnspsc190501.31321209': __('identifierSchemeCodeUnspsc190501.31321209'),
	'identifierSchemeCodeUnspsc190501.31321210': __('identifierSchemeCodeUnspsc190501.31321210'),
	'identifierSchemeCodeUnspsc190501.31321211': __('identifierSchemeCodeUnspsc190501.31321211'),
	'identifierSchemeCodeUnspsc190501.31321212': __('identifierSchemeCodeUnspsc190501.31321212'),
	'identifierSchemeCodeUnspsc190501.31321213': __('identifierSchemeCodeUnspsc190501.31321213'),
	'identifierSchemeCodeUnspsc190501.31321300': __('identifierSchemeCodeUnspsc190501.31321300'),
	'identifierSchemeCodeUnspsc190501.31321301': __('identifierSchemeCodeUnspsc190501.31321301'),
	'identifierSchemeCodeUnspsc190501.31321302': __('identifierSchemeCodeUnspsc190501.31321302'),
	'identifierSchemeCodeUnspsc190501.31321303': __('identifierSchemeCodeUnspsc190501.31321303'),
	'identifierSchemeCodeUnspsc190501.31321304': __('identifierSchemeCodeUnspsc190501.31321304'),
	'identifierSchemeCodeUnspsc190501.31321305': __('identifierSchemeCodeUnspsc190501.31321305'),
	'identifierSchemeCodeUnspsc190501.31321306': __('identifierSchemeCodeUnspsc190501.31321306'),
	'identifierSchemeCodeUnspsc190501.31321309': __('identifierSchemeCodeUnspsc190501.31321309'),
	'identifierSchemeCodeUnspsc190501.31321310': __('identifierSchemeCodeUnspsc190501.31321310'),
	'identifierSchemeCodeUnspsc190501.31321311': __('identifierSchemeCodeUnspsc190501.31321311'),
	'identifierSchemeCodeUnspsc190501.31321312': __('identifierSchemeCodeUnspsc190501.31321312'),
	'identifierSchemeCodeUnspsc190501.31321313': __('identifierSchemeCodeUnspsc190501.31321313'),
	'identifierSchemeCodeUnspsc190501.31321400': __('identifierSchemeCodeUnspsc190501.31321400'),
	'identifierSchemeCodeUnspsc190501.31321401': __('identifierSchemeCodeUnspsc190501.31321401'),
	'identifierSchemeCodeUnspsc190501.31321402': __('identifierSchemeCodeUnspsc190501.31321402'),
	'identifierSchemeCodeUnspsc190501.31321403': __('identifierSchemeCodeUnspsc190501.31321403'),
	'identifierSchemeCodeUnspsc190501.31321404': __('identifierSchemeCodeUnspsc190501.31321404'),
	'identifierSchemeCodeUnspsc190501.31321405': __('identifierSchemeCodeUnspsc190501.31321405'),
	'identifierSchemeCodeUnspsc190501.31321406': __('identifierSchemeCodeUnspsc190501.31321406'),
	'identifierSchemeCodeUnspsc190501.31321409': __('identifierSchemeCodeUnspsc190501.31321409'),
	'identifierSchemeCodeUnspsc190501.31321410': __('identifierSchemeCodeUnspsc190501.31321410'),
	'identifierSchemeCodeUnspsc190501.31321411': __('identifierSchemeCodeUnspsc190501.31321411'),
	'identifierSchemeCodeUnspsc190501.31321412': __('identifierSchemeCodeUnspsc190501.31321412'),
	'identifierSchemeCodeUnspsc190501.31321413': __('identifierSchemeCodeUnspsc190501.31321413'),
	'identifierSchemeCodeUnspsc190501.31321500': __('identifierSchemeCodeUnspsc190501.31321500'),
	'identifierSchemeCodeUnspsc190501.31321501': __('identifierSchemeCodeUnspsc190501.31321501'),
	'identifierSchemeCodeUnspsc190501.31321502': __('identifierSchemeCodeUnspsc190501.31321502'),
	'identifierSchemeCodeUnspsc190501.31321503': __('identifierSchemeCodeUnspsc190501.31321503'),
	'identifierSchemeCodeUnspsc190501.31321504': __('identifierSchemeCodeUnspsc190501.31321504'),
	'identifierSchemeCodeUnspsc190501.31321505': __('identifierSchemeCodeUnspsc190501.31321505'),
	'identifierSchemeCodeUnspsc190501.31321506': __('identifierSchemeCodeUnspsc190501.31321506'),
	'identifierSchemeCodeUnspsc190501.31321509': __('identifierSchemeCodeUnspsc190501.31321509'),
	'identifierSchemeCodeUnspsc190501.31321510': __('identifierSchemeCodeUnspsc190501.31321510'),
	'identifierSchemeCodeUnspsc190501.31321511': __('identifierSchemeCodeUnspsc190501.31321511'),
	'identifierSchemeCodeUnspsc190501.31321512': __('identifierSchemeCodeUnspsc190501.31321512'),
	'identifierSchemeCodeUnspsc190501.31321513': __('identifierSchemeCodeUnspsc190501.31321513'),
	'identifierSchemeCodeUnspsc190501.31321600': __('identifierSchemeCodeUnspsc190501.31321600'),
	'identifierSchemeCodeUnspsc190501.31321601': __('identifierSchemeCodeUnspsc190501.31321601'),
	'identifierSchemeCodeUnspsc190501.31321602': __('identifierSchemeCodeUnspsc190501.31321602'),
	'identifierSchemeCodeUnspsc190501.31321603': __('identifierSchemeCodeUnspsc190501.31321603'),
	'identifierSchemeCodeUnspsc190501.31321604': __('identifierSchemeCodeUnspsc190501.31321604'),
	'identifierSchemeCodeUnspsc190501.31321605': __('identifierSchemeCodeUnspsc190501.31321605'),
	'identifierSchemeCodeUnspsc190501.31321606': __('identifierSchemeCodeUnspsc190501.31321606'),
	'identifierSchemeCodeUnspsc190501.31321609': __('identifierSchemeCodeUnspsc190501.31321609'),
	'identifierSchemeCodeUnspsc190501.31321610': __('identifierSchemeCodeUnspsc190501.31321610'),
	'identifierSchemeCodeUnspsc190501.31321611': __('identifierSchemeCodeUnspsc190501.31321611'),
	'identifierSchemeCodeUnspsc190501.31321612': __('identifierSchemeCodeUnspsc190501.31321612'),
	'identifierSchemeCodeUnspsc190501.31321613': __('identifierSchemeCodeUnspsc190501.31321613'),
	'identifierSchemeCodeUnspsc190501.31321700': __('identifierSchemeCodeUnspsc190501.31321700'),
	'identifierSchemeCodeUnspsc190501.31321701': __('identifierSchemeCodeUnspsc190501.31321701'),
	'identifierSchemeCodeUnspsc190501.31321702': __('identifierSchemeCodeUnspsc190501.31321702'),
	'identifierSchemeCodeUnspsc190501.31321703': __('identifierSchemeCodeUnspsc190501.31321703'),
	'identifierSchemeCodeUnspsc190501.31321704': __('identifierSchemeCodeUnspsc190501.31321704'),
	'identifierSchemeCodeUnspsc190501.31321705': __('identifierSchemeCodeUnspsc190501.31321705'),
	'identifierSchemeCodeUnspsc190501.31321706': __('identifierSchemeCodeUnspsc190501.31321706'),
	'identifierSchemeCodeUnspsc190501.31321709': __('identifierSchemeCodeUnspsc190501.31321709'),
	'identifierSchemeCodeUnspsc190501.31321710': __('identifierSchemeCodeUnspsc190501.31321710'),
	'identifierSchemeCodeUnspsc190501.31321711': __('identifierSchemeCodeUnspsc190501.31321711'),
	'identifierSchemeCodeUnspsc190501.31321712': __('identifierSchemeCodeUnspsc190501.31321712'),
	'identifierSchemeCodeUnspsc190501.31321713': __('identifierSchemeCodeUnspsc190501.31321713'),
	'identifierSchemeCodeUnspsc190501.31330000': __('identifierSchemeCodeUnspsc190501.31330000'),
	'identifierSchemeCodeUnspsc190501.31331100': __('identifierSchemeCodeUnspsc190501.31331100'),
	'identifierSchemeCodeUnspsc190501.31331101': __('identifierSchemeCodeUnspsc190501.31331101'),
	'identifierSchemeCodeUnspsc190501.31331102': __('identifierSchemeCodeUnspsc190501.31331102'),
	'identifierSchemeCodeUnspsc190501.31331103': __('identifierSchemeCodeUnspsc190501.31331103'),
	'identifierSchemeCodeUnspsc190501.31331104': __('identifierSchemeCodeUnspsc190501.31331104'),
	'identifierSchemeCodeUnspsc190501.31331105': __('identifierSchemeCodeUnspsc190501.31331105'),
	'identifierSchemeCodeUnspsc190501.31331106': __('identifierSchemeCodeUnspsc190501.31331106'),
	'identifierSchemeCodeUnspsc190501.31331109': __('identifierSchemeCodeUnspsc190501.31331109'),
	'identifierSchemeCodeUnspsc190501.31331110': __('identifierSchemeCodeUnspsc190501.31331110'),
	'identifierSchemeCodeUnspsc190501.31331111': __('identifierSchemeCodeUnspsc190501.31331111'),
	'identifierSchemeCodeUnspsc190501.31331112': __('identifierSchemeCodeUnspsc190501.31331112'),
	'identifierSchemeCodeUnspsc190501.31331113': __('identifierSchemeCodeUnspsc190501.31331113'),
	'identifierSchemeCodeUnspsc190501.31331200': __('identifierSchemeCodeUnspsc190501.31331200'),
	'identifierSchemeCodeUnspsc190501.31331201': __('identifierSchemeCodeUnspsc190501.31331201'),
	'identifierSchemeCodeUnspsc190501.31331202': __('identifierSchemeCodeUnspsc190501.31331202'),
	'identifierSchemeCodeUnspsc190501.31331203': __('identifierSchemeCodeUnspsc190501.31331203'),
	'identifierSchemeCodeUnspsc190501.31331204': __('identifierSchemeCodeUnspsc190501.31331204'),
	'identifierSchemeCodeUnspsc190501.31331205': __('identifierSchemeCodeUnspsc190501.31331205'),
	'identifierSchemeCodeUnspsc190501.31331206': __('identifierSchemeCodeUnspsc190501.31331206'),
	'identifierSchemeCodeUnspsc190501.31331209': __('identifierSchemeCodeUnspsc190501.31331209'),
	'identifierSchemeCodeUnspsc190501.31331210': __('identifierSchemeCodeUnspsc190501.31331210'),
	'identifierSchemeCodeUnspsc190501.31331211': __('identifierSchemeCodeUnspsc190501.31331211'),
	'identifierSchemeCodeUnspsc190501.31331212': __('identifierSchemeCodeUnspsc190501.31331212'),
	'identifierSchemeCodeUnspsc190501.31331213': __('identifierSchemeCodeUnspsc190501.31331213'),
	'identifierSchemeCodeUnspsc190501.31331300': __('identifierSchemeCodeUnspsc190501.31331300'),
	'identifierSchemeCodeUnspsc190501.31331301': __('identifierSchemeCodeUnspsc190501.31331301'),
	'identifierSchemeCodeUnspsc190501.31331302': __('identifierSchemeCodeUnspsc190501.31331302'),
	'identifierSchemeCodeUnspsc190501.31331303': __('identifierSchemeCodeUnspsc190501.31331303'),
	'identifierSchemeCodeUnspsc190501.31331304': __('identifierSchemeCodeUnspsc190501.31331304'),
	'identifierSchemeCodeUnspsc190501.31331305': __('identifierSchemeCodeUnspsc190501.31331305'),
	'identifierSchemeCodeUnspsc190501.31331306': __('identifierSchemeCodeUnspsc190501.31331306'),
	'identifierSchemeCodeUnspsc190501.31331309': __('identifierSchemeCodeUnspsc190501.31331309'),
	'identifierSchemeCodeUnspsc190501.31331310': __('identifierSchemeCodeUnspsc190501.31331310'),
	'identifierSchemeCodeUnspsc190501.31331311': __('identifierSchemeCodeUnspsc190501.31331311'),
	'identifierSchemeCodeUnspsc190501.31331312': __('identifierSchemeCodeUnspsc190501.31331312'),
	'identifierSchemeCodeUnspsc190501.31331313': __('identifierSchemeCodeUnspsc190501.31331313'),
	'identifierSchemeCodeUnspsc190501.31331400': __('identifierSchemeCodeUnspsc190501.31331400'),
	'identifierSchemeCodeUnspsc190501.31331401': __('identifierSchemeCodeUnspsc190501.31331401'),
	'identifierSchemeCodeUnspsc190501.31331402': __('identifierSchemeCodeUnspsc190501.31331402'),
	'identifierSchemeCodeUnspsc190501.31331403': __('identifierSchemeCodeUnspsc190501.31331403'),
	'identifierSchemeCodeUnspsc190501.31331404': __('identifierSchemeCodeUnspsc190501.31331404'),
	'identifierSchemeCodeUnspsc190501.31331405': __('identifierSchemeCodeUnspsc190501.31331405'),
	'identifierSchemeCodeUnspsc190501.31331406': __('identifierSchemeCodeUnspsc190501.31331406'),
	'identifierSchemeCodeUnspsc190501.31331409': __('identifierSchemeCodeUnspsc190501.31331409'),
	'identifierSchemeCodeUnspsc190501.31331410': __('identifierSchemeCodeUnspsc190501.31331410'),
	'identifierSchemeCodeUnspsc190501.31331411': __('identifierSchemeCodeUnspsc190501.31331411'),
	'identifierSchemeCodeUnspsc190501.31331412': __('identifierSchemeCodeUnspsc190501.31331412'),
	'identifierSchemeCodeUnspsc190501.31331413': __('identifierSchemeCodeUnspsc190501.31331413'),
	'identifierSchemeCodeUnspsc190501.31331500': __('identifierSchemeCodeUnspsc190501.31331500'),
	'identifierSchemeCodeUnspsc190501.31331501': __('identifierSchemeCodeUnspsc190501.31331501'),
	'identifierSchemeCodeUnspsc190501.31331502': __('identifierSchemeCodeUnspsc190501.31331502'),
	'identifierSchemeCodeUnspsc190501.31331503': __('identifierSchemeCodeUnspsc190501.31331503'),
	'identifierSchemeCodeUnspsc190501.31331504': __('identifierSchemeCodeUnspsc190501.31331504'),
	'identifierSchemeCodeUnspsc190501.31331505': __('identifierSchemeCodeUnspsc190501.31331505'),
	'identifierSchemeCodeUnspsc190501.31331506': __('identifierSchemeCodeUnspsc190501.31331506'),
	'identifierSchemeCodeUnspsc190501.31331509': __('identifierSchemeCodeUnspsc190501.31331509'),
	'identifierSchemeCodeUnspsc190501.31331510': __('identifierSchemeCodeUnspsc190501.31331510'),
	'identifierSchemeCodeUnspsc190501.31331511': __('identifierSchemeCodeUnspsc190501.31331511'),
	'identifierSchemeCodeUnspsc190501.31331512': __('identifierSchemeCodeUnspsc190501.31331512'),
	'identifierSchemeCodeUnspsc190501.31331513': __('identifierSchemeCodeUnspsc190501.31331513'),
	'identifierSchemeCodeUnspsc190501.31331600': __('identifierSchemeCodeUnspsc190501.31331600'),
	'identifierSchemeCodeUnspsc190501.31331601': __('identifierSchemeCodeUnspsc190501.31331601'),
	'identifierSchemeCodeUnspsc190501.31331602': __('identifierSchemeCodeUnspsc190501.31331602'),
	'identifierSchemeCodeUnspsc190501.31331603': __('identifierSchemeCodeUnspsc190501.31331603'),
	'identifierSchemeCodeUnspsc190501.31331604': __('identifierSchemeCodeUnspsc190501.31331604'),
	'identifierSchemeCodeUnspsc190501.31331605': __('identifierSchemeCodeUnspsc190501.31331605'),
	'identifierSchemeCodeUnspsc190501.31331606': __('identifierSchemeCodeUnspsc190501.31331606'),
	'identifierSchemeCodeUnspsc190501.31331609': __('identifierSchemeCodeUnspsc190501.31331609'),
	'identifierSchemeCodeUnspsc190501.31331610': __('identifierSchemeCodeUnspsc190501.31331610'),
	'identifierSchemeCodeUnspsc190501.31331611': __('identifierSchemeCodeUnspsc190501.31331611'),
	'identifierSchemeCodeUnspsc190501.31331612': __('identifierSchemeCodeUnspsc190501.31331612'),
	'identifierSchemeCodeUnspsc190501.31331613': __('identifierSchemeCodeUnspsc190501.31331613'),
	'identifierSchemeCodeUnspsc190501.31331700': __('identifierSchemeCodeUnspsc190501.31331700'),
	'identifierSchemeCodeUnspsc190501.31331701': __('identifierSchemeCodeUnspsc190501.31331701'),
	'identifierSchemeCodeUnspsc190501.31331702': __('identifierSchemeCodeUnspsc190501.31331702'),
	'identifierSchemeCodeUnspsc190501.31331703': __('identifierSchemeCodeUnspsc190501.31331703'),
	'identifierSchemeCodeUnspsc190501.31331704': __('identifierSchemeCodeUnspsc190501.31331704'),
	'identifierSchemeCodeUnspsc190501.31331705': __('identifierSchemeCodeUnspsc190501.31331705'),
	'identifierSchemeCodeUnspsc190501.31331706': __('identifierSchemeCodeUnspsc190501.31331706'),
	'identifierSchemeCodeUnspsc190501.31331709': __('identifierSchemeCodeUnspsc190501.31331709'),
	'identifierSchemeCodeUnspsc190501.31331710': __('identifierSchemeCodeUnspsc190501.31331710'),
	'identifierSchemeCodeUnspsc190501.31331711': __('identifierSchemeCodeUnspsc190501.31331711'),
	'identifierSchemeCodeUnspsc190501.31331712': __('identifierSchemeCodeUnspsc190501.31331712'),
	'identifierSchemeCodeUnspsc190501.31331713': __('identifierSchemeCodeUnspsc190501.31331713'),
	'identifierSchemeCodeUnspsc190501.31340000': __('identifierSchemeCodeUnspsc190501.31340000'),
	'identifierSchemeCodeUnspsc190501.31341100': __('identifierSchemeCodeUnspsc190501.31341100'),
	'identifierSchemeCodeUnspsc190501.31341101': __('identifierSchemeCodeUnspsc190501.31341101'),
	'identifierSchemeCodeUnspsc190501.31341102': __('identifierSchemeCodeUnspsc190501.31341102'),
	'identifierSchemeCodeUnspsc190501.31341103': __('identifierSchemeCodeUnspsc190501.31341103'),
	'identifierSchemeCodeUnspsc190501.31341104': __('identifierSchemeCodeUnspsc190501.31341104'),
	'identifierSchemeCodeUnspsc190501.31341105': __('identifierSchemeCodeUnspsc190501.31341105'),
	'identifierSchemeCodeUnspsc190501.31341106': __('identifierSchemeCodeUnspsc190501.31341106'),
	'identifierSchemeCodeUnspsc190501.31341109': __('identifierSchemeCodeUnspsc190501.31341109'),
	'identifierSchemeCodeUnspsc190501.31341110': __('identifierSchemeCodeUnspsc190501.31341110'),
	'identifierSchemeCodeUnspsc190501.31341111': __('identifierSchemeCodeUnspsc190501.31341111'),
	'identifierSchemeCodeUnspsc190501.31341112': __('identifierSchemeCodeUnspsc190501.31341112'),
	'identifierSchemeCodeUnspsc190501.31341113': __('identifierSchemeCodeUnspsc190501.31341113'),
	'identifierSchemeCodeUnspsc190501.31341200': __('identifierSchemeCodeUnspsc190501.31341200'),
	'identifierSchemeCodeUnspsc190501.31341201': __('identifierSchemeCodeUnspsc190501.31341201'),
	'identifierSchemeCodeUnspsc190501.31341202': __('identifierSchemeCodeUnspsc190501.31341202'),
	'identifierSchemeCodeUnspsc190501.31341203': __('identifierSchemeCodeUnspsc190501.31341203'),
	'identifierSchemeCodeUnspsc190501.31341204': __('identifierSchemeCodeUnspsc190501.31341204'),
	'identifierSchemeCodeUnspsc190501.31341205': __('identifierSchemeCodeUnspsc190501.31341205'),
	'identifierSchemeCodeUnspsc190501.31341206': __('identifierSchemeCodeUnspsc190501.31341206'),
	'identifierSchemeCodeUnspsc190501.31341209': __('identifierSchemeCodeUnspsc190501.31341209'),
	'identifierSchemeCodeUnspsc190501.31341210': __('identifierSchemeCodeUnspsc190501.31341210'),
	'identifierSchemeCodeUnspsc190501.31341211': __('identifierSchemeCodeUnspsc190501.31341211'),
	'identifierSchemeCodeUnspsc190501.31341212': __('identifierSchemeCodeUnspsc190501.31341212'),
	'identifierSchemeCodeUnspsc190501.31341213': __('identifierSchemeCodeUnspsc190501.31341213'),
	'identifierSchemeCodeUnspsc190501.31341300': __('identifierSchemeCodeUnspsc190501.31341300'),
	'identifierSchemeCodeUnspsc190501.31341301': __('identifierSchemeCodeUnspsc190501.31341301'),
	'identifierSchemeCodeUnspsc190501.31341302': __('identifierSchemeCodeUnspsc190501.31341302'),
	'identifierSchemeCodeUnspsc190501.31341303': __('identifierSchemeCodeUnspsc190501.31341303'),
	'identifierSchemeCodeUnspsc190501.31341304': __('identifierSchemeCodeUnspsc190501.31341304'),
	'identifierSchemeCodeUnspsc190501.31341305': __('identifierSchemeCodeUnspsc190501.31341305'),
	'identifierSchemeCodeUnspsc190501.31341306': __('identifierSchemeCodeUnspsc190501.31341306'),
	'identifierSchemeCodeUnspsc190501.31341309': __('identifierSchemeCodeUnspsc190501.31341309'),
	'identifierSchemeCodeUnspsc190501.31341310': __('identifierSchemeCodeUnspsc190501.31341310'),
	'identifierSchemeCodeUnspsc190501.31341311': __('identifierSchemeCodeUnspsc190501.31341311'),
	'identifierSchemeCodeUnspsc190501.31341312': __('identifierSchemeCodeUnspsc190501.31341312'),
	'identifierSchemeCodeUnspsc190501.31341313': __('identifierSchemeCodeUnspsc190501.31341313'),
	'identifierSchemeCodeUnspsc190501.31341400': __('identifierSchemeCodeUnspsc190501.31341400'),
	'identifierSchemeCodeUnspsc190501.31341401': __('identifierSchemeCodeUnspsc190501.31341401'),
	'identifierSchemeCodeUnspsc190501.31341402': __('identifierSchemeCodeUnspsc190501.31341402'),
	'identifierSchemeCodeUnspsc190501.31341403': __('identifierSchemeCodeUnspsc190501.31341403'),
	'identifierSchemeCodeUnspsc190501.31341404': __('identifierSchemeCodeUnspsc190501.31341404'),
	'identifierSchemeCodeUnspsc190501.31341405': __('identifierSchemeCodeUnspsc190501.31341405'),
	'identifierSchemeCodeUnspsc190501.31341406': __('identifierSchemeCodeUnspsc190501.31341406'),
	'identifierSchemeCodeUnspsc190501.31341409': __('identifierSchemeCodeUnspsc190501.31341409'),
	'identifierSchemeCodeUnspsc190501.31341410': __('identifierSchemeCodeUnspsc190501.31341410'),
	'identifierSchemeCodeUnspsc190501.31341411': __('identifierSchemeCodeUnspsc190501.31341411'),
	'identifierSchemeCodeUnspsc190501.31341412': __('identifierSchemeCodeUnspsc190501.31341412'),
	'identifierSchemeCodeUnspsc190501.31341413': __('identifierSchemeCodeUnspsc190501.31341413'),
	'identifierSchemeCodeUnspsc190501.31341500': __('identifierSchemeCodeUnspsc190501.31341500'),
	'identifierSchemeCodeUnspsc190501.31341501': __('identifierSchemeCodeUnspsc190501.31341501'),
	'identifierSchemeCodeUnspsc190501.31341502': __('identifierSchemeCodeUnspsc190501.31341502'),
	'identifierSchemeCodeUnspsc190501.31341503': __('identifierSchemeCodeUnspsc190501.31341503'),
	'identifierSchemeCodeUnspsc190501.31341504': __('identifierSchemeCodeUnspsc190501.31341504'),
	'identifierSchemeCodeUnspsc190501.31341505': __('identifierSchemeCodeUnspsc190501.31341505'),
	'identifierSchemeCodeUnspsc190501.31341506': __('identifierSchemeCodeUnspsc190501.31341506'),
	'identifierSchemeCodeUnspsc190501.31341509': __('identifierSchemeCodeUnspsc190501.31341509'),
	'identifierSchemeCodeUnspsc190501.31341510': __('identifierSchemeCodeUnspsc190501.31341510'),
	'identifierSchemeCodeUnspsc190501.31341511': __('identifierSchemeCodeUnspsc190501.31341511'),
	'identifierSchemeCodeUnspsc190501.31341512': __('identifierSchemeCodeUnspsc190501.31341512'),
	'identifierSchemeCodeUnspsc190501.31341513': __('identifierSchemeCodeUnspsc190501.31341513'),
	'identifierSchemeCodeUnspsc190501.31341600': __('identifierSchemeCodeUnspsc190501.31341600'),
	'identifierSchemeCodeUnspsc190501.31341601': __('identifierSchemeCodeUnspsc190501.31341601'),
	'identifierSchemeCodeUnspsc190501.31341602': __('identifierSchemeCodeUnspsc190501.31341602'),
	'identifierSchemeCodeUnspsc190501.31341603': __('identifierSchemeCodeUnspsc190501.31341603'),
	'identifierSchemeCodeUnspsc190501.31341604': __('identifierSchemeCodeUnspsc190501.31341604'),
	'identifierSchemeCodeUnspsc190501.31341605': __('identifierSchemeCodeUnspsc190501.31341605'),
	'identifierSchemeCodeUnspsc190501.31341606': __('identifierSchemeCodeUnspsc190501.31341606'),
	'identifierSchemeCodeUnspsc190501.31341609': __('identifierSchemeCodeUnspsc190501.31341609'),
	'identifierSchemeCodeUnspsc190501.31341610': __('identifierSchemeCodeUnspsc190501.31341610'),
	'identifierSchemeCodeUnspsc190501.31341611': __('identifierSchemeCodeUnspsc190501.31341611'),
	'identifierSchemeCodeUnspsc190501.31341612': __('identifierSchemeCodeUnspsc190501.31341612'),
	'identifierSchemeCodeUnspsc190501.31341613': __('identifierSchemeCodeUnspsc190501.31341613'),
	'identifierSchemeCodeUnspsc190501.31341700': __('identifierSchemeCodeUnspsc190501.31341700'),
	'identifierSchemeCodeUnspsc190501.31341701': __('identifierSchemeCodeUnspsc190501.31341701'),
	'identifierSchemeCodeUnspsc190501.31341702': __('identifierSchemeCodeUnspsc190501.31341702'),
	'identifierSchemeCodeUnspsc190501.31341703': __('identifierSchemeCodeUnspsc190501.31341703'),
	'identifierSchemeCodeUnspsc190501.31341704': __('identifierSchemeCodeUnspsc190501.31341704'),
	'identifierSchemeCodeUnspsc190501.31341705': __('identifierSchemeCodeUnspsc190501.31341705'),
	'identifierSchemeCodeUnspsc190501.31341706': __('identifierSchemeCodeUnspsc190501.31341706'),
	'identifierSchemeCodeUnspsc190501.31341709': __('identifierSchemeCodeUnspsc190501.31341709'),
	'identifierSchemeCodeUnspsc190501.31341710': __('identifierSchemeCodeUnspsc190501.31341710'),
	'identifierSchemeCodeUnspsc190501.31341711': __('identifierSchemeCodeUnspsc190501.31341711'),
	'identifierSchemeCodeUnspsc190501.31341712': __('identifierSchemeCodeUnspsc190501.31341712'),
	'identifierSchemeCodeUnspsc190501.31341713': __('identifierSchemeCodeUnspsc190501.31341713'),
	'identifierSchemeCodeUnspsc190501.31350000': __('identifierSchemeCodeUnspsc190501.31350000'),
	'identifierSchemeCodeUnspsc190501.31351100': __('identifierSchemeCodeUnspsc190501.31351100'),
	'identifierSchemeCodeUnspsc190501.31351101': __('identifierSchemeCodeUnspsc190501.31351101'),
	'identifierSchemeCodeUnspsc190501.31351102': __('identifierSchemeCodeUnspsc190501.31351102'),
	'identifierSchemeCodeUnspsc190501.31351103': __('identifierSchemeCodeUnspsc190501.31351103'),
	'identifierSchemeCodeUnspsc190501.31351104': __('identifierSchemeCodeUnspsc190501.31351104'),
	'identifierSchemeCodeUnspsc190501.31351105': __('identifierSchemeCodeUnspsc190501.31351105'),
	'identifierSchemeCodeUnspsc190501.31351106': __('identifierSchemeCodeUnspsc190501.31351106'),
	'identifierSchemeCodeUnspsc190501.31351109': __('identifierSchemeCodeUnspsc190501.31351109'),
	'identifierSchemeCodeUnspsc190501.31351110': __('identifierSchemeCodeUnspsc190501.31351110'),
	'identifierSchemeCodeUnspsc190501.31351111': __('identifierSchemeCodeUnspsc190501.31351111'),
	'identifierSchemeCodeUnspsc190501.31351112': __('identifierSchemeCodeUnspsc190501.31351112'),
	'identifierSchemeCodeUnspsc190501.31351113': __('identifierSchemeCodeUnspsc190501.31351113'),
	'identifierSchemeCodeUnspsc190501.31351200': __('identifierSchemeCodeUnspsc190501.31351200'),
	'identifierSchemeCodeUnspsc190501.31351201': __('identifierSchemeCodeUnspsc190501.31351201'),
	'identifierSchemeCodeUnspsc190501.31351202': __('identifierSchemeCodeUnspsc190501.31351202'),
	'identifierSchemeCodeUnspsc190501.31351203': __('identifierSchemeCodeUnspsc190501.31351203'),
	'identifierSchemeCodeUnspsc190501.31351204': __('identifierSchemeCodeUnspsc190501.31351204'),
	'identifierSchemeCodeUnspsc190501.31351205': __('identifierSchemeCodeUnspsc190501.31351205'),
	'identifierSchemeCodeUnspsc190501.31351206': __('identifierSchemeCodeUnspsc190501.31351206'),
	'identifierSchemeCodeUnspsc190501.31351209': __('identifierSchemeCodeUnspsc190501.31351209'),
	'identifierSchemeCodeUnspsc190501.31351210': __('identifierSchemeCodeUnspsc190501.31351210'),
	'identifierSchemeCodeUnspsc190501.31351211': __('identifierSchemeCodeUnspsc190501.31351211'),
	'identifierSchemeCodeUnspsc190501.31351212': __('identifierSchemeCodeUnspsc190501.31351212'),
	'identifierSchemeCodeUnspsc190501.31351213': __('identifierSchemeCodeUnspsc190501.31351213'),
	'identifierSchemeCodeUnspsc190501.31351300': __('identifierSchemeCodeUnspsc190501.31351300'),
	'identifierSchemeCodeUnspsc190501.31351301': __('identifierSchemeCodeUnspsc190501.31351301'),
	'identifierSchemeCodeUnspsc190501.31351302': __('identifierSchemeCodeUnspsc190501.31351302'),
	'identifierSchemeCodeUnspsc190501.31351303': __('identifierSchemeCodeUnspsc190501.31351303'),
	'identifierSchemeCodeUnspsc190501.31351304': __('identifierSchemeCodeUnspsc190501.31351304'),
	'identifierSchemeCodeUnspsc190501.31351305': __('identifierSchemeCodeUnspsc190501.31351305'),
	'identifierSchemeCodeUnspsc190501.31351306': __('identifierSchemeCodeUnspsc190501.31351306'),
	'identifierSchemeCodeUnspsc190501.31351309': __('identifierSchemeCodeUnspsc190501.31351309'),
	'identifierSchemeCodeUnspsc190501.31351310': __('identifierSchemeCodeUnspsc190501.31351310'),
	'identifierSchemeCodeUnspsc190501.31351311': __('identifierSchemeCodeUnspsc190501.31351311'),
	'identifierSchemeCodeUnspsc190501.31351312': __('identifierSchemeCodeUnspsc190501.31351312'),
	'identifierSchemeCodeUnspsc190501.31351313': __('identifierSchemeCodeUnspsc190501.31351313'),
	'identifierSchemeCodeUnspsc190501.31351400': __('identifierSchemeCodeUnspsc190501.31351400'),
	'identifierSchemeCodeUnspsc190501.31351401': __('identifierSchemeCodeUnspsc190501.31351401'),
	'identifierSchemeCodeUnspsc190501.31351402': __('identifierSchemeCodeUnspsc190501.31351402'),
	'identifierSchemeCodeUnspsc190501.31351403': __('identifierSchemeCodeUnspsc190501.31351403'),
	'identifierSchemeCodeUnspsc190501.31351404': __('identifierSchemeCodeUnspsc190501.31351404'),
	'identifierSchemeCodeUnspsc190501.31351405': __('identifierSchemeCodeUnspsc190501.31351405'),
	'identifierSchemeCodeUnspsc190501.31351406': __('identifierSchemeCodeUnspsc190501.31351406'),
	'identifierSchemeCodeUnspsc190501.31351409': __('identifierSchemeCodeUnspsc190501.31351409'),
	'identifierSchemeCodeUnspsc190501.31351410': __('identifierSchemeCodeUnspsc190501.31351410'),
	'identifierSchemeCodeUnspsc190501.31351411': __('identifierSchemeCodeUnspsc190501.31351411'),
	'identifierSchemeCodeUnspsc190501.31351412': __('identifierSchemeCodeUnspsc190501.31351412'),
	'identifierSchemeCodeUnspsc190501.31351413': __('identifierSchemeCodeUnspsc190501.31351413'),
	'identifierSchemeCodeUnspsc190501.31351500': __('identifierSchemeCodeUnspsc190501.31351500'),
	'identifierSchemeCodeUnspsc190501.31351501': __('identifierSchemeCodeUnspsc190501.31351501'),
	'identifierSchemeCodeUnspsc190501.31351502': __('identifierSchemeCodeUnspsc190501.31351502'),
	'identifierSchemeCodeUnspsc190501.31351503': __('identifierSchemeCodeUnspsc190501.31351503'),
	'identifierSchemeCodeUnspsc190501.31351504': __('identifierSchemeCodeUnspsc190501.31351504'),
	'identifierSchemeCodeUnspsc190501.31351505': __('identifierSchemeCodeUnspsc190501.31351505'),
	'identifierSchemeCodeUnspsc190501.31351506': __('identifierSchemeCodeUnspsc190501.31351506'),
	'identifierSchemeCodeUnspsc190501.31351509': __('identifierSchemeCodeUnspsc190501.31351509'),
	'identifierSchemeCodeUnspsc190501.31351510': __('identifierSchemeCodeUnspsc190501.31351510'),
	'identifierSchemeCodeUnspsc190501.31351511': __('identifierSchemeCodeUnspsc190501.31351511'),
	'identifierSchemeCodeUnspsc190501.31351512': __('identifierSchemeCodeUnspsc190501.31351512'),
	'identifierSchemeCodeUnspsc190501.31351513': __('identifierSchemeCodeUnspsc190501.31351513'),
	'identifierSchemeCodeUnspsc190501.31351600': __('identifierSchemeCodeUnspsc190501.31351600'),
	'identifierSchemeCodeUnspsc190501.31351601': __('identifierSchemeCodeUnspsc190501.31351601'),
	'identifierSchemeCodeUnspsc190501.31351602': __('identifierSchemeCodeUnspsc190501.31351602'),
	'identifierSchemeCodeUnspsc190501.31351603': __('identifierSchemeCodeUnspsc190501.31351603'),
	'identifierSchemeCodeUnspsc190501.31351604': __('identifierSchemeCodeUnspsc190501.31351604'),
	'identifierSchemeCodeUnspsc190501.31351605': __('identifierSchemeCodeUnspsc190501.31351605'),
	'identifierSchemeCodeUnspsc190501.31351606': __('identifierSchemeCodeUnspsc190501.31351606'),
	'identifierSchemeCodeUnspsc190501.31351609': __('identifierSchemeCodeUnspsc190501.31351609'),
	'identifierSchemeCodeUnspsc190501.31351610': __('identifierSchemeCodeUnspsc190501.31351610'),
	'identifierSchemeCodeUnspsc190501.31351611': __('identifierSchemeCodeUnspsc190501.31351611'),
	'identifierSchemeCodeUnspsc190501.31351612': __('identifierSchemeCodeUnspsc190501.31351612'),
	'identifierSchemeCodeUnspsc190501.31351613': __('identifierSchemeCodeUnspsc190501.31351613'),
	'identifierSchemeCodeUnspsc190501.31351700': __('identifierSchemeCodeUnspsc190501.31351700'),
	'identifierSchemeCodeUnspsc190501.31351701': __('identifierSchemeCodeUnspsc190501.31351701'),
	'identifierSchemeCodeUnspsc190501.31351702': __('identifierSchemeCodeUnspsc190501.31351702'),
	'identifierSchemeCodeUnspsc190501.31351703': __('identifierSchemeCodeUnspsc190501.31351703'),
	'identifierSchemeCodeUnspsc190501.31351704': __('identifierSchemeCodeUnspsc190501.31351704'),
	'identifierSchemeCodeUnspsc190501.31351705': __('identifierSchemeCodeUnspsc190501.31351705'),
	'identifierSchemeCodeUnspsc190501.31351706': __('identifierSchemeCodeUnspsc190501.31351706'),
	'identifierSchemeCodeUnspsc190501.31351709': __('identifierSchemeCodeUnspsc190501.31351709'),
	'identifierSchemeCodeUnspsc190501.31351710': __('identifierSchemeCodeUnspsc190501.31351710'),
	'identifierSchemeCodeUnspsc190501.31351711': __('identifierSchemeCodeUnspsc190501.31351711'),
	'identifierSchemeCodeUnspsc190501.31351712': __('identifierSchemeCodeUnspsc190501.31351712'),
	'identifierSchemeCodeUnspsc190501.31351713': __('identifierSchemeCodeUnspsc190501.31351713'),
	'identifierSchemeCodeUnspsc190501.31360000': __('identifierSchemeCodeUnspsc190501.31360000'),
	'identifierSchemeCodeUnspsc190501.31361100': __('identifierSchemeCodeUnspsc190501.31361100'),
	'identifierSchemeCodeUnspsc190501.31361101': __('identifierSchemeCodeUnspsc190501.31361101'),
	'identifierSchemeCodeUnspsc190501.31361102': __('identifierSchemeCodeUnspsc190501.31361102'),
	'identifierSchemeCodeUnspsc190501.31361103': __('identifierSchemeCodeUnspsc190501.31361103'),
	'identifierSchemeCodeUnspsc190501.31361104': __('identifierSchemeCodeUnspsc190501.31361104'),
	'identifierSchemeCodeUnspsc190501.31361105': __('identifierSchemeCodeUnspsc190501.31361105'),
	'identifierSchemeCodeUnspsc190501.31361106': __('identifierSchemeCodeUnspsc190501.31361106'),
	'identifierSchemeCodeUnspsc190501.31361109': __('identifierSchemeCodeUnspsc190501.31361109'),
	'identifierSchemeCodeUnspsc190501.31361110': __('identifierSchemeCodeUnspsc190501.31361110'),
	'identifierSchemeCodeUnspsc190501.31361111': __('identifierSchemeCodeUnspsc190501.31361111'),
	'identifierSchemeCodeUnspsc190501.31361112': __('identifierSchemeCodeUnspsc190501.31361112'),
	'identifierSchemeCodeUnspsc190501.31361113': __('identifierSchemeCodeUnspsc190501.31361113'),
	'identifierSchemeCodeUnspsc190501.31361200': __('identifierSchemeCodeUnspsc190501.31361200'),
	'identifierSchemeCodeUnspsc190501.31361201': __('identifierSchemeCodeUnspsc190501.31361201'),
	'identifierSchemeCodeUnspsc190501.31361202': __('identifierSchemeCodeUnspsc190501.31361202'),
	'identifierSchemeCodeUnspsc190501.31361203': __('identifierSchemeCodeUnspsc190501.31361203'),
	'identifierSchemeCodeUnspsc190501.31361204': __('identifierSchemeCodeUnspsc190501.31361204'),
	'identifierSchemeCodeUnspsc190501.31361205': __('identifierSchemeCodeUnspsc190501.31361205'),
	'identifierSchemeCodeUnspsc190501.31361206': __('identifierSchemeCodeUnspsc190501.31361206'),
	'identifierSchemeCodeUnspsc190501.31361209': __('identifierSchemeCodeUnspsc190501.31361209'),
	'identifierSchemeCodeUnspsc190501.31361210': __('identifierSchemeCodeUnspsc190501.31361210'),
	'identifierSchemeCodeUnspsc190501.31361211': __('identifierSchemeCodeUnspsc190501.31361211'),
	'identifierSchemeCodeUnspsc190501.31361212': __('identifierSchemeCodeUnspsc190501.31361212'),
	'identifierSchemeCodeUnspsc190501.31361213': __('identifierSchemeCodeUnspsc190501.31361213'),
	'identifierSchemeCodeUnspsc190501.31361300': __('identifierSchemeCodeUnspsc190501.31361300'),
	'identifierSchemeCodeUnspsc190501.31361301': __('identifierSchemeCodeUnspsc190501.31361301'),
	'identifierSchemeCodeUnspsc190501.31361302': __('identifierSchemeCodeUnspsc190501.31361302'),
	'identifierSchemeCodeUnspsc190501.31361303': __('identifierSchemeCodeUnspsc190501.31361303'),
	'identifierSchemeCodeUnspsc190501.31361304': __('identifierSchemeCodeUnspsc190501.31361304'),
	'identifierSchemeCodeUnspsc190501.31361305': __('identifierSchemeCodeUnspsc190501.31361305'),
	'identifierSchemeCodeUnspsc190501.31361306': __('identifierSchemeCodeUnspsc190501.31361306'),
	'identifierSchemeCodeUnspsc190501.31361309': __('identifierSchemeCodeUnspsc190501.31361309'),
	'identifierSchemeCodeUnspsc190501.31361310': __('identifierSchemeCodeUnspsc190501.31361310'),
	'identifierSchemeCodeUnspsc190501.31361311': __('identifierSchemeCodeUnspsc190501.31361311'),
	'identifierSchemeCodeUnspsc190501.31361312': __('identifierSchemeCodeUnspsc190501.31361312'),
	'identifierSchemeCodeUnspsc190501.31361313': __('identifierSchemeCodeUnspsc190501.31361313'),
	'identifierSchemeCodeUnspsc190501.31361400': __('identifierSchemeCodeUnspsc190501.31361400'),
	'identifierSchemeCodeUnspsc190501.31361401': __('identifierSchemeCodeUnspsc190501.31361401'),
	'identifierSchemeCodeUnspsc190501.31361402': __('identifierSchemeCodeUnspsc190501.31361402'),
	'identifierSchemeCodeUnspsc190501.31361403': __('identifierSchemeCodeUnspsc190501.31361403'),
	'identifierSchemeCodeUnspsc190501.31361404': __('identifierSchemeCodeUnspsc190501.31361404'),
	'identifierSchemeCodeUnspsc190501.31361405': __('identifierSchemeCodeUnspsc190501.31361405'),
	'identifierSchemeCodeUnspsc190501.31361406': __('identifierSchemeCodeUnspsc190501.31361406'),
	'identifierSchemeCodeUnspsc190501.31361409': __('identifierSchemeCodeUnspsc190501.31361409'),
	'identifierSchemeCodeUnspsc190501.31361410': __('identifierSchemeCodeUnspsc190501.31361410'),
	'identifierSchemeCodeUnspsc190501.31361411': __('identifierSchemeCodeUnspsc190501.31361411'),
	'identifierSchemeCodeUnspsc190501.31361412': __('identifierSchemeCodeUnspsc190501.31361412'),
	'identifierSchemeCodeUnspsc190501.31361413': __('identifierSchemeCodeUnspsc190501.31361413'),
	'identifierSchemeCodeUnspsc190501.31361500': __('identifierSchemeCodeUnspsc190501.31361500'),
	'identifierSchemeCodeUnspsc190501.31361501': __('identifierSchemeCodeUnspsc190501.31361501'),
	'identifierSchemeCodeUnspsc190501.31361502': __('identifierSchemeCodeUnspsc190501.31361502'),
	'identifierSchemeCodeUnspsc190501.31361503': __('identifierSchemeCodeUnspsc190501.31361503'),
	'identifierSchemeCodeUnspsc190501.31361504': __('identifierSchemeCodeUnspsc190501.31361504'),
	'identifierSchemeCodeUnspsc190501.31361505': __('identifierSchemeCodeUnspsc190501.31361505'),
	'identifierSchemeCodeUnspsc190501.31361506': __('identifierSchemeCodeUnspsc190501.31361506'),
	'identifierSchemeCodeUnspsc190501.31361509': __('identifierSchemeCodeUnspsc190501.31361509'),
	'identifierSchemeCodeUnspsc190501.31361510': __('identifierSchemeCodeUnspsc190501.31361510'),
	'identifierSchemeCodeUnspsc190501.31361511': __('identifierSchemeCodeUnspsc190501.31361511'),
	'identifierSchemeCodeUnspsc190501.31361512': __('identifierSchemeCodeUnspsc190501.31361512'),
	'identifierSchemeCodeUnspsc190501.31361513': __('identifierSchemeCodeUnspsc190501.31361513'),
	'identifierSchemeCodeUnspsc190501.31361600': __('identifierSchemeCodeUnspsc190501.31361600'),
	'identifierSchemeCodeUnspsc190501.31361601': __('identifierSchemeCodeUnspsc190501.31361601'),
	'identifierSchemeCodeUnspsc190501.31361602': __('identifierSchemeCodeUnspsc190501.31361602'),
	'identifierSchemeCodeUnspsc190501.31361603': __('identifierSchemeCodeUnspsc190501.31361603'),
	'identifierSchemeCodeUnspsc190501.31361604': __('identifierSchemeCodeUnspsc190501.31361604'),
	'identifierSchemeCodeUnspsc190501.31361605': __('identifierSchemeCodeUnspsc190501.31361605'),
	'identifierSchemeCodeUnspsc190501.31361606': __('identifierSchemeCodeUnspsc190501.31361606'),
	'identifierSchemeCodeUnspsc190501.31361609': __('identifierSchemeCodeUnspsc190501.31361609'),
	'identifierSchemeCodeUnspsc190501.31361610': __('identifierSchemeCodeUnspsc190501.31361610'),
	'identifierSchemeCodeUnspsc190501.31361611': __('identifierSchemeCodeUnspsc190501.31361611'),
	'identifierSchemeCodeUnspsc190501.31361612': __('identifierSchemeCodeUnspsc190501.31361612'),
	'identifierSchemeCodeUnspsc190501.31361613': __('identifierSchemeCodeUnspsc190501.31361613'),
	'identifierSchemeCodeUnspsc190501.31361700': __('identifierSchemeCodeUnspsc190501.31361700'),
	'identifierSchemeCodeUnspsc190501.31361701': __('identifierSchemeCodeUnspsc190501.31361701'),
	'identifierSchemeCodeUnspsc190501.31361702': __('identifierSchemeCodeUnspsc190501.31361702'),
	'identifierSchemeCodeUnspsc190501.31361703': __('identifierSchemeCodeUnspsc190501.31361703'),
	'identifierSchemeCodeUnspsc190501.31361704': __('identifierSchemeCodeUnspsc190501.31361704'),
	'identifierSchemeCodeUnspsc190501.31361705': __('identifierSchemeCodeUnspsc190501.31361705'),
	'identifierSchemeCodeUnspsc190501.31361706': __('identifierSchemeCodeUnspsc190501.31361706'),
	'identifierSchemeCodeUnspsc190501.31361709': __('identifierSchemeCodeUnspsc190501.31361709'),
	'identifierSchemeCodeUnspsc190501.31361710': __('identifierSchemeCodeUnspsc190501.31361710'),
	'identifierSchemeCodeUnspsc190501.31361711': __('identifierSchemeCodeUnspsc190501.31361711'),
	'identifierSchemeCodeUnspsc190501.31361712': __('identifierSchemeCodeUnspsc190501.31361712'),
	'identifierSchemeCodeUnspsc190501.31361713': __('identifierSchemeCodeUnspsc190501.31361713'),
	'identifierSchemeCodeUnspsc190501.31370000': __('identifierSchemeCodeUnspsc190501.31370000'),
	'identifierSchemeCodeUnspsc190501.31371000': __('identifierSchemeCodeUnspsc190501.31371000'),
	'identifierSchemeCodeUnspsc190501.31371001': __('identifierSchemeCodeUnspsc190501.31371001'),
	'identifierSchemeCodeUnspsc190501.31371002': __('identifierSchemeCodeUnspsc190501.31371002'),
	'identifierSchemeCodeUnspsc190501.31371003': __('identifierSchemeCodeUnspsc190501.31371003'),
	'identifierSchemeCodeUnspsc190501.31371100': __('identifierSchemeCodeUnspsc190501.31371100'),
	'identifierSchemeCodeUnspsc190501.31371101': __('identifierSchemeCodeUnspsc190501.31371101'),
	'identifierSchemeCodeUnspsc190501.31371102': __('identifierSchemeCodeUnspsc190501.31371102'),
	'identifierSchemeCodeUnspsc190501.31371103': __('identifierSchemeCodeUnspsc190501.31371103'),
	'identifierSchemeCodeUnspsc190501.31371104': __('identifierSchemeCodeUnspsc190501.31371104'),
	'identifierSchemeCodeUnspsc190501.31371105': __('identifierSchemeCodeUnspsc190501.31371105'),
	'identifierSchemeCodeUnspsc190501.31371106': __('identifierSchemeCodeUnspsc190501.31371106'),
	'identifierSchemeCodeUnspsc190501.31371107': __('identifierSchemeCodeUnspsc190501.31371107'),
	'identifierSchemeCodeUnspsc190501.31371108': __('identifierSchemeCodeUnspsc190501.31371108'),
	'identifierSchemeCodeUnspsc190501.31371200': __('identifierSchemeCodeUnspsc190501.31371200'),
	'identifierSchemeCodeUnspsc190501.31371201': __('identifierSchemeCodeUnspsc190501.31371201'),
	'identifierSchemeCodeUnspsc190501.31371202': __('identifierSchemeCodeUnspsc190501.31371202'),
	'identifierSchemeCodeUnspsc190501.31371203': __('identifierSchemeCodeUnspsc190501.31371203'),
	'identifierSchemeCodeUnspsc190501.31371204': __('identifierSchemeCodeUnspsc190501.31371204'),
	'identifierSchemeCodeUnspsc190501.31371205': __('identifierSchemeCodeUnspsc190501.31371205'),
	'identifierSchemeCodeUnspsc190501.31371206': __('identifierSchemeCodeUnspsc190501.31371206'),
	'identifierSchemeCodeUnspsc190501.31371207': __('identifierSchemeCodeUnspsc190501.31371207'),
	'identifierSchemeCodeUnspsc190501.31371208': __('identifierSchemeCodeUnspsc190501.31371208'),
	'identifierSchemeCodeUnspsc190501.31371209': __('identifierSchemeCodeUnspsc190501.31371209'),
	'identifierSchemeCodeUnspsc190501.31371210': __('identifierSchemeCodeUnspsc190501.31371210'),
	'identifierSchemeCodeUnspsc190501.31371211': __('identifierSchemeCodeUnspsc190501.31371211'),
	'identifierSchemeCodeUnspsc190501.31371212': __('identifierSchemeCodeUnspsc190501.31371212'),
	'identifierSchemeCodeUnspsc190501.31371300': __('identifierSchemeCodeUnspsc190501.31371300'),
	'identifierSchemeCodeUnspsc190501.31371301': __('identifierSchemeCodeUnspsc190501.31371301'),
	'identifierSchemeCodeUnspsc190501.31371302': __('identifierSchemeCodeUnspsc190501.31371302'),
	'identifierSchemeCodeUnspsc190501.31371400': __('identifierSchemeCodeUnspsc190501.31371400'),
	'identifierSchemeCodeUnspsc190501.31371401': __('identifierSchemeCodeUnspsc190501.31371401'),
	'identifierSchemeCodeUnspsc190501.31380000': __('identifierSchemeCodeUnspsc190501.31380000'),
	'identifierSchemeCodeUnspsc190501.31381100': __('identifierSchemeCodeUnspsc190501.31381100'),
	'identifierSchemeCodeUnspsc190501.31381101': __('identifierSchemeCodeUnspsc190501.31381101'),
	'identifierSchemeCodeUnspsc190501.31381102': __('identifierSchemeCodeUnspsc190501.31381102'),
	'identifierSchemeCodeUnspsc190501.31381103': __('identifierSchemeCodeUnspsc190501.31381103'),
	'identifierSchemeCodeUnspsc190501.31381104': __('identifierSchemeCodeUnspsc190501.31381104'),
	'identifierSchemeCodeUnspsc190501.31381105': __('identifierSchemeCodeUnspsc190501.31381105'),
	'identifierSchemeCodeUnspsc190501.31381106': __('identifierSchemeCodeUnspsc190501.31381106'),
	'identifierSchemeCodeUnspsc190501.31381107': __('identifierSchemeCodeUnspsc190501.31381107'),
	'identifierSchemeCodeUnspsc190501.31381108': __('identifierSchemeCodeUnspsc190501.31381108'),
	'identifierSchemeCodeUnspsc190501.31381109': __('identifierSchemeCodeUnspsc190501.31381109'),
	'identifierSchemeCodeUnspsc190501.31381110': __('identifierSchemeCodeUnspsc190501.31381110'),
	'identifierSchemeCodeUnspsc190501.31381111': __('identifierSchemeCodeUnspsc190501.31381111'),
	'identifierSchemeCodeUnspsc190501.31381112': __('identifierSchemeCodeUnspsc190501.31381112'),
	'identifierSchemeCodeUnspsc190501.31381113': __('identifierSchemeCodeUnspsc190501.31381113'),
	'identifierSchemeCodeUnspsc190501.31381114': __('identifierSchemeCodeUnspsc190501.31381114'),
	'identifierSchemeCodeUnspsc190501.31381115': __('identifierSchemeCodeUnspsc190501.31381115'),
	'identifierSchemeCodeUnspsc190501.31381116': __('identifierSchemeCodeUnspsc190501.31381116'),
	'identifierSchemeCodeUnspsc190501.31381117': __('identifierSchemeCodeUnspsc190501.31381117'),
	'identifierSchemeCodeUnspsc190501.31381118': __('identifierSchemeCodeUnspsc190501.31381118'),
	'identifierSchemeCodeUnspsc190501.31381119': __('identifierSchemeCodeUnspsc190501.31381119'),
	'identifierSchemeCodeUnspsc190501.31381120': __('identifierSchemeCodeUnspsc190501.31381120'),
	'identifierSchemeCodeUnspsc190501.31381121': __('identifierSchemeCodeUnspsc190501.31381121'),
	'identifierSchemeCodeUnspsc190501.31381122': __('identifierSchemeCodeUnspsc190501.31381122'),
	'identifierSchemeCodeUnspsc190501.31381123': __('identifierSchemeCodeUnspsc190501.31381123'),
	'identifierSchemeCodeUnspsc190501.31381124': __('identifierSchemeCodeUnspsc190501.31381124'),
	'identifierSchemeCodeUnspsc190501.31381125': __('identifierSchemeCodeUnspsc190501.31381125'),
	'identifierSchemeCodeUnspsc190501.31381126': __('identifierSchemeCodeUnspsc190501.31381126'),
	'identifierSchemeCodeUnspsc190501.31381127': __('identifierSchemeCodeUnspsc190501.31381127'),
	'identifierSchemeCodeUnspsc190501.31381128': __('identifierSchemeCodeUnspsc190501.31381128'),
	'identifierSchemeCodeUnspsc190501.31381129': __('identifierSchemeCodeUnspsc190501.31381129'),
	'identifierSchemeCodeUnspsc190501.31381130': __('identifierSchemeCodeUnspsc190501.31381130'),
	'identifierSchemeCodeUnspsc190501.31381131': __('identifierSchemeCodeUnspsc190501.31381131'),
	'identifierSchemeCodeUnspsc190501.31381132': __('identifierSchemeCodeUnspsc190501.31381132'),
	'identifierSchemeCodeUnspsc190501.31381133': __('identifierSchemeCodeUnspsc190501.31381133'),
	'identifierSchemeCodeUnspsc190501.31381134': __('identifierSchemeCodeUnspsc190501.31381134'),
	'identifierSchemeCodeUnspsc190501.31381135': __('identifierSchemeCodeUnspsc190501.31381135'),
	'identifierSchemeCodeUnspsc190501.31381136': __('identifierSchemeCodeUnspsc190501.31381136'),
	'identifierSchemeCodeUnspsc190501.31381137': __('identifierSchemeCodeUnspsc190501.31381137'),
	'identifierSchemeCodeUnspsc190501.31381138': __('identifierSchemeCodeUnspsc190501.31381138'),
	'identifierSchemeCodeUnspsc190501.31381139': __('identifierSchemeCodeUnspsc190501.31381139'),
	'identifierSchemeCodeUnspsc190501.31381140': __('identifierSchemeCodeUnspsc190501.31381140'),
	'identifierSchemeCodeUnspsc190501.31381141': __('identifierSchemeCodeUnspsc190501.31381141'),
	'identifierSchemeCodeUnspsc190501.31381142': __('identifierSchemeCodeUnspsc190501.31381142'),
	'identifierSchemeCodeUnspsc190501.31381143': __('identifierSchemeCodeUnspsc190501.31381143'),
	'identifierSchemeCodeUnspsc190501.31381144': __('identifierSchemeCodeUnspsc190501.31381144'),
	'identifierSchemeCodeUnspsc190501.31381145': __('identifierSchemeCodeUnspsc190501.31381145'),
	'identifierSchemeCodeUnspsc190501.31381146': __('identifierSchemeCodeUnspsc190501.31381146'),
	'identifierSchemeCodeUnspsc190501.31381147': __('identifierSchemeCodeUnspsc190501.31381147'),
	'identifierSchemeCodeUnspsc190501.31381148': __('identifierSchemeCodeUnspsc190501.31381148'),
	'identifierSchemeCodeUnspsc190501.31381149': __('identifierSchemeCodeUnspsc190501.31381149'),
	'identifierSchemeCodeUnspsc190501.31381150': __('identifierSchemeCodeUnspsc190501.31381150'),
	'identifierSchemeCodeUnspsc190501.31381151': __('identifierSchemeCodeUnspsc190501.31381151'),
	'identifierSchemeCodeUnspsc190501.31381152': __('identifierSchemeCodeUnspsc190501.31381152'),
	'identifierSchemeCodeUnspsc190501.31381153': __('identifierSchemeCodeUnspsc190501.31381153'),
	'identifierSchemeCodeUnspsc190501.31381154': __('identifierSchemeCodeUnspsc190501.31381154'),
	'identifierSchemeCodeUnspsc190501.31381155': __('identifierSchemeCodeUnspsc190501.31381155'),
	'identifierSchemeCodeUnspsc190501.31381200': __('identifierSchemeCodeUnspsc190501.31381200'),
	'identifierSchemeCodeUnspsc190501.31381201': __('identifierSchemeCodeUnspsc190501.31381201'),
	'identifierSchemeCodeUnspsc190501.31381202': __('identifierSchemeCodeUnspsc190501.31381202'),
	'identifierSchemeCodeUnspsc190501.31381203': __('identifierSchemeCodeUnspsc190501.31381203'),
	'identifierSchemeCodeUnspsc190501.31381204': __('identifierSchemeCodeUnspsc190501.31381204'),
	'identifierSchemeCodeUnspsc190501.31381205': __('identifierSchemeCodeUnspsc190501.31381205'),
	'identifierSchemeCodeUnspsc190501.31381206': __('identifierSchemeCodeUnspsc190501.31381206'),
	'identifierSchemeCodeUnspsc190501.31381207': __('identifierSchemeCodeUnspsc190501.31381207'),
	'identifierSchemeCodeUnspsc190501.31381208': __('identifierSchemeCodeUnspsc190501.31381208'),
	'identifierSchemeCodeUnspsc190501.31381209': __('identifierSchemeCodeUnspsc190501.31381209'),
	'identifierSchemeCodeUnspsc190501.31381210': __('identifierSchemeCodeUnspsc190501.31381210'),
	'identifierSchemeCodeUnspsc190501.31381211': __('identifierSchemeCodeUnspsc190501.31381211'),
	'identifierSchemeCodeUnspsc190501.31381212': __('identifierSchemeCodeUnspsc190501.31381212'),
	'identifierSchemeCodeUnspsc190501.31381213': __('identifierSchemeCodeUnspsc190501.31381213'),
	'identifierSchemeCodeUnspsc190501.31381214': __('identifierSchemeCodeUnspsc190501.31381214'),
	'identifierSchemeCodeUnspsc190501.31381215': __('identifierSchemeCodeUnspsc190501.31381215'),
	'identifierSchemeCodeUnspsc190501.31381216': __('identifierSchemeCodeUnspsc190501.31381216'),
	'identifierSchemeCodeUnspsc190501.31381217': __('identifierSchemeCodeUnspsc190501.31381217'),
	'identifierSchemeCodeUnspsc190501.31381218': __('identifierSchemeCodeUnspsc190501.31381218'),
	'identifierSchemeCodeUnspsc190501.31381219': __('identifierSchemeCodeUnspsc190501.31381219'),
	'identifierSchemeCodeUnspsc190501.31381220': __('identifierSchemeCodeUnspsc190501.31381220'),
	'identifierSchemeCodeUnspsc190501.31381221': __('identifierSchemeCodeUnspsc190501.31381221'),
	'identifierSchemeCodeUnspsc190501.31381222': __('identifierSchemeCodeUnspsc190501.31381222'),
	'identifierSchemeCodeUnspsc190501.31381223': __('identifierSchemeCodeUnspsc190501.31381223'),
	'identifierSchemeCodeUnspsc190501.31381224': __('identifierSchemeCodeUnspsc190501.31381224'),
	'identifierSchemeCodeUnspsc190501.31381225': __('identifierSchemeCodeUnspsc190501.31381225'),
	'identifierSchemeCodeUnspsc190501.31381226': __('identifierSchemeCodeUnspsc190501.31381226'),
	'identifierSchemeCodeUnspsc190501.31381227': __('identifierSchemeCodeUnspsc190501.31381227'),
	'identifierSchemeCodeUnspsc190501.31381228': __('identifierSchemeCodeUnspsc190501.31381228'),
	'identifierSchemeCodeUnspsc190501.31381229': __('identifierSchemeCodeUnspsc190501.31381229'),
	'identifierSchemeCodeUnspsc190501.31381230': __('identifierSchemeCodeUnspsc190501.31381230'),
	'identifierSchemeCodeUnspsc190501.31381231': __('identifierSchemeCodeUnspsc190501.31381231'),
	'identifierSchemeCodeUnspsc190501.31381232': __('identifierSchemeCodeUnspsc190501.31381232'),
	'identifierSchemeCodeUnspsc190501.31381233': __('identifierSchemeCodeUnspsc190501.31381233'),
	'identifierSchemeCodeUnspsc190501.31381234': __('identifierSchemeCodeUnspsc190501.31381234'),
	'identifierSchemeCodeUnspsc190501.31381235': __('identifierSchemeCodeUnspsc190501.31381235'),
	'identifierSchemeCodeUnspsc190501.31381236': __('identifierSchemeCodeUnspsc190501.31381236'),
	'identifierSchemeCodeUnspsc190501.31381237': __('identifierSchemeCodeUnspsc190501.31381237'),
	'identifierSchemeCodeUnspsc190501.31381238': __('identifierSchemeCodeUnspsc190501.31381238'),
	'identifierSchemeCodeUnspsc190501.31381239': __('identifierSchemeCodeUnspsc190501.31381239'),
	'identifierSchemeCodeUnspsc190501.31381240': __('identifierSchemeCodeUnspsc190501.31381240'),
	'identifierSchemeCodeUnspsc190501.31381241': __('identifierSchemeCodeUnspsc190501.31381241'),
	'identifierSchemeCodeUnspsc190501.31381242': __('identifierSchemeCodeUnspsc190501.31381242'),
	'identifierSchemeCodeUnspsc190501.31381243': __('identifierSchemeCodeUnspsc190501.31381243'),
	'identifierSchemeCodeUnspsc190501.31381244': __('identifierSchemeCodeUnspsc190501.31381244'),
	'identifierSchemeCodeUnspsc190501.31381245': __('identifierSchemeCodeUnspsc190501.31381245'),
	'identifierSchemeCodeUnspsc190501.31381246': __('identifierSchemeCodeUnspsc190501.31381246'),
	'identifierSchemeCodeUnspsc190501.31381247': __('identifierSchemeCodeUnspsc190501.31381247'),
	'identifierSchemeCodeUnspsc190501.31381248': __('identifierSchemeCodeUnspsc190501.31381248'),
	'identifierSchemeCodeUnspsc190501.31381249': __('identifierSchemeCodeUnspsc190501.31381249'),
	'identifierSchemeCodeUnspsc190501.31381250': __('identifierSchemeCodeUnspsc190501.31381250'),
	'identifierSchemeCodeUnspsc190501.31381251': __('identifierSchemeCodeUnspsc190501.31381251'),
	'identifierSchemeCodeUnspsc190501.31381252': __('identifierSchemeCodeUnspsc190501.31381252'),
	'identifierSchemeCodeUnspsc190501.31381253': __('identifierSchemeCodeUnspsc190501.31381253'),
	'identifierSchemeCodeUnspsc190501.31381254': __('identifierSchemeCodeUnspsc190501.31381254'),
	'identifierSchemeCodeUnspsc190501.31381255': __('identifierSchemeCodeUnspsc190501.31381255'),
	'identifierSchemeCodeUnspsc190501.31381300': __('identifierSchemeCodeUnspsc190501.31381300'),
	'identifierSchemeCodeUnspsc190501.31381301': __('identifierSchemeCodeUnspsc190501.31381301'),
	'identifierSchemeCodeUnspsc190501.31381302': __('identifierSchemeCodeUnspsc190501.31381302'),
	'identifierSchemeCodeUnspsc190501.31381303': __('identifierSchemeCodeUnspsc190501.31381303'),
	'identifierSchemeCodeUnspsc190501.31381304': __('identifierSchemeCodeUnspsc190501.31381304'),
	'identifierSchemeCodeUnspsc190501.31381305': __('identifierSchemeCodeUnspsc190501.31381305'),
	'identifierSchemeCodeUnspsc190501.31381306': __('identifierSchemeCodeUnspsc190501.31381306'),
	'identifierSchemeCodeUnspsc190501.31381307': __('identifierSchemeCodeUnspsc190501.31381307'),
	'identifierSchemeCodeUnspsc190501.31381308': __('identifierSchemeCodeUnspsc190501.31381308'),
	'identifierSchemeCodeUnspsc190501.31381309': __('identifierSchemeCodeUnspsc190501.31381309'),
	'identifierSchemeCodeUnspsc190501.31381310': __('identifierSchemeCodeUnspsc190501.31381310'),
	'identifierSchemeCodeUnspsc190501.31381311': __('identifierSchemeCodeUnspsc190501.31381311'),
	'identifierSchemeCodeUnspsc190501.31381312': __('identifierSchemeCodeUnspsc190501.31381312'),
	'identifierSchemeCodeUnspsc190501.31381313': __('identifierSchemeCodeUnspsc190501.31381313'),
	'identifierSchemeCodeUnspsc190501.31381314': __('identifierSchemeCodeUnspsc190501.31381314'),
	'identifierSchemeCodeUnspsc190501.31381315': __('identifierSchemeCodeUnspsc190501.31381315'),
	'identifierSchemeCodeUnspsc190501.31381316': __('identifierSchemeCodeUnspsc190501.31381316'),
	'identifierSchemeCodeUnspsc190501.31381317': __('identifierSchemeCodeUnspsc190501.31381317'),
	'identifierSchemeCodeUnspsc190501.31381318': __('identifierSchemeCodeUnspsc190501.31381318'),
	'identifierSchemeCodeUnspsc190501.31381319': __('identifierSchemeCodeUnspsc190501.31381319'),
	'identifierSchemeCodeUnspsc190501.31381320': __('identifierSchemeCodeUnspsc190501.31381320'),
	'identifierSchemeCodeUnspsc190501.31381321': __('identifierSchemeCodeUnspsc190501.31381321'),
	'identifierSchemeCodeUnspsc190501.31381322': __('identifierSchemeCodeUnspsc190501.31381322'),
	'identifierSchemeCodeUnspsc190501.31381323': __('identifierSchemeCodeUnspsc190501.31381323'),
	'identifierSchemeCodeUnspsc190501.31381324': __('identifierSchemeCodeUnspsc190501.31381324'),
	'identifierSchemeCodeUnspsc190501.31381325': __('identifierSchemeCodeUnspsc190501.31381325'),
	'identifierSchemeCodeUnspsc190501.31381326': __('identifierSchemeCodeUnspsc190501.31381326'),
	'identifierSchemeCodeUnspsc190501.31381327': __('identifierSchemeCodeUnspsc190501.31381327'),
	'identifierSchemeCodeUnspsc190501.31381328': __('identifierSchemeCodeUnspsc190501.31381328'),
	'identifierSchemeCodeUnspsc190501.31381329': __('identifierSchemeCodeUnspsc190501.31381329'),
	'identifierSchemeCodeUnspsc190501.31381330': __('identifierSchemeCodeUnspsc190501.31381330'),
	'identifierSchemeCodeUnspsc190501.31381331': __('identifierSchemeCodeUnspsc190501.31381331'),
	'identifierSchemeCodeUnspsc190501.31381332': __('identifierSchemeCodeUnspsc190501.31381332'),
	'identifierSchemeCodeUnspsc190501.31381333': __('identifierSchemeCodeUnspsc190501.31381333'),
	'identifierSchemeCodeUnspsc190501.31381334': __('identifierSchemeCodeUnspsc190501.31381334'),
	'identifierSchemeCodeUnspsc190501.31381335': __('identifierSchemeCodeUnspsc190501.31381335'),
	'identifierSchemeCodeUnspsc190501.31381336': __('identifierSchemeCodeUnspsc190501.31381336'),
	'identifierSchemeCodeUnspsc190501.31381337': __('identifierSchemeCodeUnspsc190501.31381337'),
	'identifierSchemeCodeUnspsc190501.31381338': __('identifierSchemeCodeUnspsc190501.31381338'),
	'identifierSchemeCodeUnspsc190501.31381339': __('identifierSchemeCodeUnspsc190501.31381339'),
	'identifierSchemeCodeUnspsc190501.31381340': __('identifierSchemeCodeUnspsc190501.31381340'),
	'identifierSchemeCodeUnspsc190501.31381341': __('identifierSchemeCodeUnspsc190501.31381341'),
	'identifierSchemeCodeUnspsc190501.31381342': __('identifierSchemeCodeUnspsc190501.31381342'),
	'identifierSchemeCodeUnspsc190501.31381343': __('identifierSchemeCodeUnspsc190501.31381343'),
	'identifierSchemeCodeUnspsc190501.31381344': __('identifierSchemeCodeUnspsc190501.31381344'),
	'identifierSchemeCodeUnspsc190501.31381345': __('identifierSchemeCodeUnspsc190501.31381345'),
	'identifierSchemeCodeUnspsc190501.31381346': __('identifierSchemeCodeUnspsc190501.31381346'),
	'identifierSchemeCodeUnspsc190501.31381347': __('identifierSchemeCodeUnspsc190501.31381347'),
	'identifierSchemeCodeUnspsc190501.31381348': __('identifierSchemeCodeUnspsc190501.31381348'),
	'identifierSchemeCodeUnspsc190501.31381349': __('identifierSchemeCodeUnspsc190501.31381349'),
	'identifierSchemeCodeUnspsc190501.31381350': __('identifierSchemeCodeUnspsc190501.31381350'),
	'identifierSchemeCodeUnspsc190501.31381351': __('identifierSchemeCodeUnspsc190501.31381351'),
	'identifierSchemeCodeUnspsc190501.31381352': __('identifierSchemeCodeUnspsc190501.31381352'),
	'identifierSchemeCodeUnspsc190501.31381353': __('identifierSchemeCodeUnspsc190501.31381353'),
	'identifierSchemeCodeUnspsc190501.31381354': __('identifierSchemeCodeUnspsc190501.31381354'),
	'identifierSchemeCodeUnspsc190501.31381355': __('identifierSchemeCodeUnspsc190501.31381355'),
	'identifierSchemeCodeUnspsc190501.31381400': __('identifierSchemeCodeUnspsc190501.31381400'),
	'identifierSchemeCodeUnspsc190501.31381401': __('identifierSchemeCodeUnspsc190501.31381401'),
	'identifierSchemeCodeUnspsc190501.31381402': __('identifierSchemeCodeUnspsc190501.31381402'),
	'identifierSchemeCodeUnspsc190501.31381403': __('identifierSchemeCodeUnspsc190501.31381403'),
	'identifierSchemeCodeUnspsc190501.31381404': __('identifierSchemeCodeUnspsc190501.31381404'),
	'identifierSchemeCodeUnspsc190501.31381405': __('identifierSchemeCodeUnspsc190501.31381405'),
	'identifierSchemeCodeUnspsc190501.31381406': __('identifierSchemeCodeUnspsc190501.31381406'),
	'identifierSchemeCodeUnspsc190501.31381407': __('identifierSchemeCodeUnspsc190501.31381407'),
	'identifierSchemeCodeUnspsc190501.31381408': __('identifierSchemeCodeUnspsc190501.31381408'),
	'identifierSchemeCodeUnspsc190501.31381409': __('identifierSchemeCodeUnspsc190501.31381409'),
	'identifierSchemeCodeUnspsc190501.31381410': __('identifierSchemeCodeUnspsc190501.31381410'),
	'identifierSchemeCodeUnspsc190501.31381411': __('identifierSchemeCodeUnspsc190501.31381411'),
	'identifierSchemeCodeUnspsc190501.31381412': __('identifierSchemeCodeUnspsc190501.31381412'),
	'identifierSchemeCodeUnspsc190501.31381413': __('identifierSchemeCodeUnspsc190501.31381413'),
	'identifierSchemeCodeUnspsc190501.31381414': __('identifierSchemeCodeUnspsc190501.31381414'),
	'identifierSchemeCodeUnspsc190501.31381415': __('identifierSchemeCodeUnspsc190501.31381415'),
	'identifierSchemeCodeUnspsc190501.31381416': __('identifierSchemeCodeUnspsc190501.31381416'),
	'identifierSchemeCodeUnspsc190501.31381417': __('identifierSchemeCodeUnspsc190501.31381417'),
	'identifierSchemeCodeUnspsc190501.31381418': __('identifierSchemeCodeUnspsc190501.31381418'),
	'identifierSchemeCodeUnspsc190501.31381419': __('identifierSchemeCodeUnspsc190501.31381419'),
	'identifierSchemeCodeUnspsc190501.31381420': __('identifierSchemeCodeUnspsc190501.31381420'),
	'identifierSchemeCodeUnspsc190501.31381421': __('identifierSchemeCodeUnspsc190501.31381421'),
	'identifierSchemeCodeUnspsc190501.31381422': __('identifierSchemeCodeUnspsc190501.31381422'),
	'identifierSchemeCodeUnspsc190501.31381423': __('identifierSchemeCodeUnspsc190501.31381423'),
	'identifierSchemeCodeUnspsc190501.31381424': __('identifierSchemeCodeUnspsc190501.31381424'),
	'identifierSchemeCodeUnspsc190501.31381425': __('identifierSchemeCodeUnspsc190501.31381425'),
	'identifierSchemeCodeUnspsc190501.31381426': __('identifierSchemeCodeUnspsc190501.31381426'),
	'identifierSchemeCodeUnspsc190501.31381427': __('identifierSchemeCodeUnspsc190501.31381427'),
	'identifierSchemeCodeUnspsc190501.31381428': __('identifierSchemeCodeUnspsc190501.31381428'),
	'identifierSchemeCodeUnspsc190501.31381429': __('identifierSchemeCodeUnspsc190501.31381429'),
	'identifierSchemeCodeUnspsc190501.31381430': __('identifierSchemeCodeUnspsc190501.31381430'),
	'identifierSchemeCodeUnspsc190501.31381431': __('identifierSchemeCodeUnspsc190501.31381431'),
	'identifierSchemeCodeUnspsc190501.31381432': __('identifierSchemeCodeUnspsc190501.31381432'),
	'identifierSchemeCodeUnspsc190501.31381433': __('identifierSchemeCodeUnspsc190501.31381433'),
	'identifierSchemeCodeUnspsc190501.31381434': __('identifierSchemeCodeUnspsc190501.31381434'),
	'identifierSchemeCodeUnspsc190501.31381435': __('identifierSchemeCodeUnspsc190501.31381435'),
	'identifierSchemeCodeUnspsc190501.31381436': __('identifierSchemeCodeUnspsc190501.31381436'),
	'identifierSchemeCodeUnspsc190501.31381437': __('identifierSchemeCodeUnspsc190501.31381437'),
	'identifierSchemeCodeUnspsc190501.31381438': __('identifierSchemeCodeUnspsc190501.31381438'),
	'identifierSchemeCodeUnspsc190501.31381439': __('identifierSchemeCodeUnspsc190501.31381439'),
	'identifierSchemeCodeUnspsc190501.31381440': __('identifierSchemeCodeUnspsc190501.31381440'),
	'identifierSchemeCodeUnspsc190501.31381441': __('identifierSchemeCodeUnspsc190501.31381441'),
	'identifierSchemeCodeUnspsc190501.31381442': __('identifierSchemeCodeUnspsc190501.31381442'),
	'identifierSchemeCodeUnspsc190501.31381443': __('identifierSchemeCodeUnspsc190501.31381443'),
	'identifierSchemeCodeUnspsc190501.31381444': __('identifierSchemeCodeUnspsc190501.31381444'),
	'identifierSchemeCodeUnspsc190501.31381445': __('identifierSchemeCodeUnspsc190501.31381445'),
	'identifierSchemeCodeUnspsc190501.31381446': __('identifierSchemeCodeUnspsc190501.31381446'),
	'identifierSchemeCodeUnspsc190501.31381447': __('identifierSchemeCodeUnspsc190501.31381447'),
	'identifierSchemeCodeUnspsc190501.31381448': __('identifierSchemeCodeUnspsc190501.31381448'),
	'identifierSchemeCodeUnspsc190501.31381449': __('identifierSchemeCodeUnspsc190501.31381449'),
	'identifierSchemeCodeUnspsc190501.31381450': __('identifierSchemeCodeUnspsc190501.31381450'),
	'identifierSchemeCodeUnspsc190501.31381451': __('identifierSchemeCodeUnspsc190501.31381451'),
	'identifierSchemeCodeUnspsc190501.31381452': __('identifierSchemeCodeUnspsc190501.31381452'),
	'identifierSchemeCodeUnspsc190501.31381453': __('identifierSchemeCodeUnspsc190501.31381453'),
	'identifierSchemeCodeUnspsc190501.31381454': __('identifierSchemeCodeUnspsc190501.31381454'),
	'identifierSchemeCodeUnspsc190501.31381455': __('identifierSchemeCodeUnspsc190501.31381455'),
	'identifierSchemeCodeUnspsc190501.31381500': __('identifierSchemeCodeUnspsc190501.31381500'),
	'identifierSchemeCodeUnspsc190501.31381501': __('identifierSchemeCodeUnspsc190501.31381501'),
	'identifierSchemeCodeUnspsc190501.31381502': __('identifierSchemeCodeUnspsc190501.31381502'),
	'identifierSchemeCodeUnspsc190501.31381503': __('identifierSchemeCodeUnspsc190501.31381503'),
	'identifierSchemeCodeUnspsc190501.31381504': __('identifierSchemeCodeUnspsc190501.31381504'),
	'identifierSchemeCodeUnspsc190501.31381505': __('identifierSchemeCodeUnspsc190501.31381505'),
	'identifierSchemeCodeUnspsc190501.31381506': __('identifierSchemeCodeUnspsc190501.31381506'),
	'identifierSchemeCodeUnspsc190501.31381507': __('identifierSchemeCodeUnspsc190501.31381507'),
	'identifierSchemeCodeUnspsc190501.31381508': __('identifierSchemeCodeUnspsc190501.31381508'),
	'identifierSchemeCodeUnspsc190501.31381509': __('identifierSchemeCodeUnspsc190501.31381509'),
	'identifierSchemeCodeUnspsc190501.31381510': __('identifierSchemeCodeUnspsc190501.31381510'),
	'identifierSchemeCodeUnspsc190501.31381511': __('identifierSchemeCodeUnspsc190501.31381511'),
	'identifierSchemeCodeUnspsc190501.31381512': __('identifierSchemeCodeUnspsc190501.31381512'),
	'identifierSchemeCodeUnspsc190501.31381513': __('identifierSchemeCodeUnspsc190501.31381513'),
	'identifierSchemeCodeUnspsc190501.31381514': __('identifierSchemeCodeUnspsc190501.31381514'),
	'identifierSchemeCodeUnspsc190501.31381515': __('identifierSchemeCodeUnspsc190501.31381515'),
	'identifierSchemeCodeUnspsc190501.31381516': __('identifierSchemeCodeUnspsc190501.31381516'),
	'identifierSchemeCodeUnspsc190501.31381517': __('identifierSchemeCodeUnspsc190501.31381517'),
	'identifierSchemeCodeUnspsc190501.31381518': __('identifierSchemeCodeUnspsc190501.31381518'),
	'identifierSchemeCodeUnspsc190501.31381519': __('identifierSchemeCodeUnspsc190501.31381519'),
	'identifierSchemeCodeUnspsc190501.31381520': __('identifierSchemeCodeUnspsc190501.31381520'),
	'identifierSchemeCodeUnspsc190501.31381521': __('identifierSchemeCodeUnspsc190501.31381521'),
	'identifierSchemeCodeUnspsc190501.31381522': __('identifierSchemeCodeUnspsc190501.31381522'),
	'identifierSchemeCodeUnspsc190501.31381523': __('identifierSchemeCodeUnspsc190501.31381523'),
	'identifierSchemeCodeUnspsc190501.31381524': __('identifierSchemeCodeUnspsc190501.31381524'),
	'identifierSchemeCodeUnspsc190501.31381525': __('identifierSchemeCodeUnspsc190501.31381525'),
	'identifierSchemeCodeUnspsc190501.31381526': __('identifierSchemeCodeUnspsc190501.31381526'),
	'identifierSchemeCodeUnspsc190501.31381527': __('identifierSchemeCodeUnspsc190501.31381527'),
	'identifierSchemeCodeUnspsc190501.31381528': __('identifierSchemeCodeUnspsc190501.31381528'),
	'identifierSchemeCodeUnspsc190501.31381529': __('identifierSchemeCodeUnspsc190501.31381529'),
	'identifierSchemeCodeUnspsc190501.31381530': __('identifierSchemeCodeUnspsc190501.31381530'),
	'identifierSchemeCodeUnspsc190501.31381531': __('identifierSchemeCodeUnspsc190501.31381531'),
	'identifierSchemeCodeUnspsc190501.31381532': __('identifierSchemeCodeUnspsc190501.31381532'),
	'identifierSchemeCodeUnspsc190501.31381533': __('identifierSchemeCodeUnspsc190501.31381533'),
	'identifierSchemeCodeUnspsc190501.31381534': __('identifierSchemeCodeUnspsc190501.31381534'),
	'identifierSchemeCodeUnspsc190501.31381535': __('identifierSchemeCodeUnspsc190501.31381535'),
	'identifierSchemeCodeUnspsc190501.31381536': __('identifierSchemeCodeUnspsc190501.31381536'),
	'identifierSchemeCodeUnspsc190501.31381537': __('identifierSchemeCodeUnspsc190501.31381537'),
	'identifierSchemeCodeUnspsc190501.31381538': __('identifierSchemeCodeUnspsc190501.31381538'),
	'identifierSchemeCodeUnspsc190501.31381539': __('identifierSchemeCodeUnspsc190501.31381539'),
	'identifierSchemeCodeUnspsc190501.31381540': __('identifierSchemeCodeUnspsc190501.31381540'),
	'identifierSchemeCodeUnspsc190501.31381541': __('identifierSchemeCodeUnspsc190501.31381541'),
	'identifierSchemeCodeUnspsc190501.31381542': __('identifierSchemeCodeUnspsc190501.31381542'),
	'identifierSchemeCodeUnspsc190501.31381543': __('identifierSchemeCodeUnspsc190501.31381543'),
	'identifierSchemeCodeUnspsc190501.31381544': __('identifierSchemeCodeUnspsc190501.31381544'),
	'identifierSchemeCodeUnspsc190501.31381545': __('identifierSchemeCodeUnspsc190501.31381545'),
	'identifierSchemeCodeUnspsc190501.31381546': __('identifierSchemeCodeUnspsc190501.31381546'),
	'identifierSchemeCodeUnspsc190501.31381547': __('identifierSchemeCodeUnspsc190501.31381547'),
	'identifierSchemeCodeUnspsc190501.31381548': __('identifierSchemeCodeUnspsc190501.31381548'),
	'identifierSchemeCodeUnspsc190501.31381549': __('identifierSchemeCodeUnspsc190501.31381549'),
	'identifierSchemeCodeUnspsc190501.31381550': __('identifierSchemeCodeUnspsc190501.31381550'),
	'identifierSchemeCodeUnspsc190501.31381551': __('identifierSchemeCodeUnspsc190501.31381551'),
	'identifierSchemeCodeUnspsc190501.31381552': __('identifierSchemeCodeUnspsc190501.31381552'),
	'identifierSchemeCodeUnspsc190501.31381553': __('identifierSchemeCodeUnspsc190501.31381553'),
	'identifierSchemeCodeUnspsc190501.31381554': __('identifierSchemeCodeUnspsc190501.31381554'),
	'identifierSchemeCodeUnspsc190501.31381555': __('identifierSchemeCodeUnspsc190501.31381555'),
	'identifierSchemeCodeUnspsc190501.31390000': __('identifierSchemeCodeUnspsc190501.31390000'),
	'identifierSchemeCodeUnspsc190501.31391500': __('identifierSchemeCodeUnspsc190501.31391500'),
	'identifierSchemeCodeUnspsc190501.31391501': __('identifierSchemeCodeUnspsc190501.31391501'),
	'identifierSchemeCodeUnspsc190501.31391502': __('identifierSchemeCodeUnspsc190501.31391502'),
	'identifierSchemeCodeUnspsc190501.31391503': __('identifierSchemeCodeUnspsc190501.31391503'),
	'identifierSchemeCodeUnspsc190501.31391504': __('identifierSchemeCodeUnspsc190501.31391504'),
	'identifierSchemeCodeUnspsc190501.31391505': __('identifierSchemeCodeUnspsc190501.31391505'),
	'identifierSchemeCodeUnspsc190501.31391506': __('identifierSchemeCodeUnspsc190501.31391506'),
	'identifierSchemeCodeUnspsc190501.31391600': __('identifierSchemeCodeUnspsc190501.31391600'),
	'identifierSchemeCodeUnspsc190501.31391601': __('identifierSchemeCodeUnspsc190501.31391601'),
	'identifierSchemeCodeUnspsc190501.31391602': __('identifierSchemeCodeUnspsc190501.31391602'),
	'identifierSchemeCodeUnspsc190501.31391603': __('identifierSchemeCodeUnspsc190501.31391603'),
	'identifierSchemeCodeUnspsc190501.31391604': __('identifierSchemeCodeUnspsc190501.31391604'),
	'identifierSchemeCodeUnspsc190501.31391605': __('identifierSchemeCodeUnspsc190501.31391605'),
	'identifierSchemeCodeUnspsc190501.31391606': __('identifierSchemeCodeUnspsc190501.31391606'),
	'identifierSchemeCodeUnspsc190501.31391700': __('identifierSchemeCodeUnspsc190501.31391700'),
	'identifierSchemeCodeUnspsc190501.31391701': __('identifierSchemeCodeUnspsc190501.31391701'),
	'identifierSchemeCodeUnspsc190501.31391702': __('identifierSchemeCodeUnspsc190501.31391702'),
	'identifierSchemeCodeUnspsc190501.31391703': __('identifierSchemeCodeUnspsc190501.31391703'),
	'identifierSchemeCodeUnspsc190501.31391704': __('identifierSchemeCodeUnspsc190501.31391704'),
	'identifierSchemeCodeUnspsc190501.31391705': __('identifierSchemeCodeUnspsc190501.31391705'),
	'identifierSchemeCodeUnspsc190501.31391706': __('identifierSchemeCodeUnspsc190501.31391706'),
	'identifierSchemeCodeUnspsc190501.31400000': __('identifierSchemeCodeUnspsc190501.31400000'),
	'identifierSchemeCodeUnspsc190501.31401500': __('identifierSchemeCodeUnspsc190501.31401500'),
	'identifierSchemeCodeUnspsc190501.31401501': __('identifierSchemeCodeUnspsc190501.31401501'),
	'identifierSchemeCodeUnspsc190501.31401502': __('identifierSchemeCodeUnspsc190501.31401502'),
	'identifierSchemeCodeUnspsc190501.31401503': __('identifierSchemeCodeUnspsc190501.31401503'),
	'identifierSchemeCodeUnspsc190501.31401504': __('identifierSchemeCodeUnspsc190501.31401504'),
	'identifierSchemeCodeUnspsc190501.31401505': __('identifierSchemeCodeUnspsc190501.31401505'),
	'identifierSchemeCodeUnspsc190501.31401506': __('identifierSchemeCodeUnspsc190501.31401506'),
	'identifierSchemeCodeUnspsc190501.31401507': __('identifierSchemeCodeUnspsc190501.31401507'),
	'identifierSchemeCodeUnspsc190501.31401600': __('identifierSchemeCodeUnspsc190501.31401600'),
	'identifierSchemeCodeUnspsc190501.31401601': __('identifierSchemeCodeUnspsc190501.31401601'),
	'identifierSchemeCodeUnspsc190501.31401602': __('identifierSchemeCodeUnspsc190501.31401602'),
	'identifierSchemeCodeUnspsc190501.31401603': __('identifierSchemeCodeUnspsc190501.31401603'),
	'identifierSchemeCodeUnspsc190501.31401604': __('identifierSchemeCodeUnspsc190501.31401604'),
	'identifierSchemeCodeUnspsc190501.31401605': __('identifierSchemeCodeUnspsc190501.31401605'),
	'identifierSchemeCodeUnspsc190501.31401606': __('identifierSchemeCodeUnspsc190501.31401606'),
	'identifierSchemeCodeUnspsc190501.31401607': __('identifierSchemeCodeUnspsc190501.31401607'),
	'identifierSchemeCodeUnspsc190501.31401608': __('identifierSchemeCodeUnspsc190501.31401608'),
	'identifierSchemeCodeUnspsc190501.31401609': __('identifierSchemeCodeUnspsc190501.31401609'),
	'identifierSchemeCodeUnspsc190501.31401610': __('identifierSchemeCodeUnspsc190501.31401610'),
	'identifierSchemeCodeUnspsc190501.31401700': __('identifierSchemeCodeUnspsc190501.31401700'),
	'identifierSchemeCodeUnspsc190501.31401701': __('identifierSchemeCodeUnspsc190501.31401701'),
	'identifierSchemeCodeUnspsc190501.31401702': __('identifierSchemeCodeUnspsc190501.31401702'),
	'identifierSchemeCodeUnspsc190501.31401703': __('identifierSchemeCodeUnspsc190501.31401703'),
	'identifierSchemeCodeUnspsc190501.31401704': __('identifierSchemeCodeUnspsc190501.31401704'),
	'identifierSchemeCodeUnspsc190501.31401705': __('identifierSchemeCodeUnspsc190501.31401705'),
	'identifierSchemeCodeUnspsc190501.31401706': __('identifierSchemeCodeUnspsc190501.31401706'),
	'identifierSchemeCodeUnspsc190501.31401707': __('identifierSchemeCodeUnspsc190501.31401707'),
	'identifierSchemeCodeUnspsc190501.31401708': __('identifierSchemeCodeUnspsc190501.31401708'),
	'identifierSchemeCodeUnspsc190501.31401800': __('identifierSchemeCodeUnspsc190501.31401800'),
	'identifierSchemeCodeUnspsc190501.31401801': __('identifierSchemeCodeUnspsc190501.31401801'),
	'identifierSchemeCodeUnspsc190501.31401802': __('identifierSchemeCodeUnspsc190501.31401802'),
	'identifierSchemeCodeUnspsc190501.31401803': __('identifierSchemeCodeUnspsc190501.31401803'),
	'identifierSchemeCodeUnspsc190501.31401804': __('identifierSchemeCodeUnspsc190501.31401804'),
	'identifierSchemeCodeUnspsc190501.31401805': __('identifierSchemeCodeUnspsc190501.31401805'),
	'identifierSchemeCodeUnspsc190501.31401806': __('identifierSchemeCodeUnspsc190501.31401806'),
	'identifierSchemeCodeUnspsc190501.31401807': __('identifierSchemeCodeUnspsc190501.31401807'),
	'identifierSchemeCodeUnspsc190501.31401900': __('identifierSchemeCodeUnspsc190501.31401900'),
	'identifierSchemeCodeUnspsc190501.31401901': __('identifierSchemeCodeUnspsc190501.31401901'),
	'identifierSchemeCodeUnspsc190501.31401902': __('identifierSchemeCodeUnspsc190501.31401902'),
	'identifierSchemeCodeUnspsc190501.31401903': __('identifierSchemeCodeUnspsc190501.31401903'),
	'identifierSchemeCodeUnspsc190501.31401904': __('identifierSchemeCodeUnspsc190501.31401904'),
	'identifierSchemeCodeUnspsc190501.31410000': __('identifierSchemeCodeUnspsc190501.31410000'),
	'identifierSchemeCodeUnspsc190501.31411500': __('identifierSchemeCodeUnspsc190501.31411500'),
	'identifierSchemeCodeUnspsc190501.31411501': __('identifierSchemeCodeUnspsc190501.31411501'),
	'identifierSchemeCodeUnspsc190501.31411502': __('identifierSchemeCodeUnspsc190501.31411502'),
	'identifierSchemeCodeUnspsc190501.31411503': __('identifierSchemeCodeUnspsc190501.31411503'),
	'identifierSchemeCodeUnspsc190501.31411504': __('identifierSchemeCodeUnspsc190501.31411504'),
	'identifierSchemeCodeUnspsc190501.31411505': __('identifierSchemeCodeUnspsc190501.31411505'),
	'identifierSchemeCodeUnspsc190501.31411506': __('identifierSchemeCodeUnspsc190501.31411506'),
	'identifierSchemeCodeUnspsc190501.31411600': __('identifierSchemeCodeUnspsc190501.31411600'),
	'identifierSchemeCodeUnspsc190501.31411601': __('identifierSchemeCodeUnspsc190501.31411601'),
	'identifierSchemeCodeUnspsc190501.31411602': __('identifierSchemeCodeUnspsc190501.31411602'),
	'identifierSchemeCodeUnspsc190501.31411603': __('identifierSchemeCodeUnspsc190501.31411603'),
	'identifierSchemeCodeUnspsc190501.31411604': __('identifierSchemeCodeUnspsc190501.31411604'),
	'identifierSchemeCodeUnspsc190501.31411605': __('identifierSchemeCodeUnspsc190501.31411605'),
	'identifierSchemeCodeUnspsc190501.31411700': __('identifierSchemeCodeUnspsc190501.31411700'),
	'identifierSchemeCodeUnspsc190501.31411701': __('identifierSchemeCodeUnspsc190501.31411701'),
	'identifierSchemeCodeUnspsc190501.31411702': __('identifierSchemeCodeUnspsc190501.31411702'),
	'identifierSchemeCodeUnspsc190501.31411703': __('identifierSchemeCodeUnspsc190501.31411703'),
	'identifierSchemeCodeUnspsc190501.31411704': __('identifierSchemeCodeUnspsc190501.31411704'),
	'identifierSchemeCodeUnspsc190501.31411705': __('identifierSchemeCodeUnspsc190501.31411705'),
	'identifierSchemeCodeUnspsc190501.31411800': __('identifierSchemeCodeUnspsc190501.31411800'),
	'identifierSchemeCodeUnspsc190501.31411801': __('identifierSchemeCodeUnspsc190501.31411801'),
	'identifierSchemeCodeUnspsc190501.31411802': __('identifierSchemeCodeUnspsc190501.31411802'),
	'identifierSchemeCodeUnspsc190501.31411803': __('identifierSchemeCodeUnspsc190501.31411803'),
	'identifierSchemeCodeUnspsc190501.31411804': __('identifierSchemeCodeUnspsc190501.31411804'),
	'identifierSchemeCodeUnspsc190501.31411805': __('identifierSchemeCodeUnspsc190501.31411805'),
	'identifierSchemeCodeUnspsc190501.31411900': __('identifierSchemeCodeUnspsc190501.31411900'),
	'identifierSchemeCodeUnspsc190501.31411901': __('identifierSchemeCodeUnspsc190501.31411901'),
	'identifierSchemeCodeUnspsc190501.31411902': __('identifierSchemeCodeUnspsc190501.31411902'),
	'identifierSchemeCodeUnspsc190501.31411903': __('identifierSchemeCodeUnspsc190501.31411903'),
	'identifierSchemeCodeUnspsc190501.31411904': __('identifierSchemeCodeUnspsc190501.31411904'),
	'identifierSchemeCodeUnspsc190501.31411905': __('identifierSchemeCodeUnspsc190501.31411905'),
	'identifierSchemeCodeUnspsc190501.31420000': __('identifierSchemeCodeUnspsc190501.31420000'),
	'identifierSchemeCodeUnspsc190501.31421500': __('identifierSchemeCodeUnspsc190501.31421500'),
	'identifierSchemeCodeUnspsc190501.31421501': __('identifierSchemeCodeUnspsc190501.31421501'),
	'identifierSchemeCodeUnspsc190501.31421502': __('identifierSchemeCodeUnspsc190501.31421502'),
	'identifierSchemeCodeUnspsc190501.31421503': __('identifierSchemeCodeUnspsc190501.31421503'),
	'identifierSchemeCodeUnspsc190501.31421504': __('identifierSchemeCodeUnspsc190501.31421504'),
	'identifierSchemeCodeUnspsc190501.31421505': __('identifierSchemeCodeUnspsc190501.31421505'),
	'identifierSchemeCodeUnspsc190501.31421506': __('identifierSchemeCodeUnspsc190501.31421506'),
	'identifierSchemeCodeUnspsc190501.31421507': __('identifierSchemeCodeUnspsc190501.31421507'),
	'identifierSchemeCodeUnspsc190501.31421508': __('identifierSchemeCodeUnspsc190501.31421508'),
	'identifierSchemeCodeUnspsc190501.31421509': __('identifierSchemeCodeUnspsc190501.31421509'),
	'identifierSchemeCodeUnspsc190501.31421510': __('identifierSchemeCodeUnspsc190501.31421510'),
	'identifierSchemeCodeUnspsc190501.31421511': __('identifierSchemeCodeUnspsc190501.31421511'),
	'identifierSchemeCodeUnspsc190501.31421512': __('identifierSchemeCodeUnspsc190501.31421512'),
	'identifierSchemeCodeUnspsc190501.31421513': __('identifierSchemeCodeUnspsc190501.31421513'),
	'identifierSchemeCodeUnspsc190501.31421514': __('identifierSchemeCodeUnspsc190501.31421514'),
	'identifierSchemeCodeUnspsc190501.31421515': __('identifierSchemeCodeUnspsc190501.31421515'),
	'identifierSchemeCodeUnspsc190501.31421516': __('identifierSchemeCodeUnspsc190501.31421516'),
	'identifierSchemeCodeUnspsc190501.31421517': __('identifierSchemeCodeUnspsc190501.31421517'),
	'identifierSchemeCodeUnspsc190501.31421518': __('identifierSchemeCodeUnspsc190501.31421518'),
	'identifierSchemeCodeUnspsc190501.31421519': __('identifierSchemeCodeUnspsc190501.31421519'),
	'identifierSchemeCodeUnspsc190501.31421520': __('identifierSchemeCodeUnspsc190501.31421520'),
	'identifierSchemeCodeUnspsc190501.31421521': __('identifierSchemeCodeUnspsc190501.31421521'),
	'identifierSchemeCodeUnspsc190501.31421522': __('identifierSchemeCodeUnspsc190501.31421522'),
	'identifierSchemeCodeUnspsc190501.32000000': __('identifierSchemeCodeUnspsc190501.32000000'),
	'identifierSchemeCodeUnspsc190501.32100000': __('identifierSchemeCodeUnspsc190501.32100000'),
	'identifierSchemeCodeUnspsc190501.32101500': __('identifierSchemeCodeUnspsc190501.32101500'),
	'identifierSchemeCodeUnspsc190501.32101502': __('identifierSchemeCodeUnspsc190501.32101502'),
	'identifierSchemeCodeUnspsc190501.32101503': __('identifierSchemeCodeUnspsc190501.32101503'),
	'identifierSchemeCodeUnspsc190501.32101504': __('identifierSchemeCodeUnspsc190501.32101504'),
	'identifierSchemeCodeUnspsc190501.32101505': __('identifierSchemeCodeUnspsc190501.32101505'),
	'identifierSchemeCodeUnspsc190501.32101506': __('identifierSchemeCodeUnspsc190501.32101506'),
	'identifierSchemeCodeUnspsc190501.32101507': __('identifierSchemeCodeUnspsc190501.32101507'),
	'identifierSchemeCodeUnspsc190501.32101508': __('identifierSchemeCodeUnspsc190501.32101508'),
	'identifierSchemeCodeUnspsc190501.32101509': __('identifierSchemeCodeUnspsc190501.32101509'),
	'identifierSchemeCodeUnspsc190501.32101510': __('identifierSchemeCodeUnspsc190501.32101510'),
	'identifierSchemeCodeUnspsc190501.32101512': __('identifierSchemeCodeUnspsc190501.32101512'),
	'identifierSchemeCodeUnspsc190501.32101513': __('identifierSchemeCodeUnspsc190501.32101513'),
	'identifierSchemeCodeUnspsc190501.32101514': __('identifierSchemeCodeUnspsc190501.32101514'),
	'identifierSchemeCodeUnspsc190501.32101515': __('identifierSchemeCodeUnspsc190501.32101515'),
	'identifierSchemeCodeUnspsc190501.32101516': __('identifierSchemeCodeUnspsc190501.32101516'),
	'identifierSchemeCodeUnspsc190501.32101517': __('identifierSchemeCodeUnspsc190501.32101517'),
	'identifierSchemeCodeUnspsc190501.32101518': __('identifierSchemeCodeUnspsc190501.32101518'),
	'identifierSchemeCodeUnspsc190501.32101519': __('identifierSchemeCodeUnspsc190501.32101519'),
	'identifierSchemeCodeUnspsc190501.32101520': __('identifierSchemeCodeUnspsc190501.32101520'),
	'identifierSchemeCodeUnspsc190501.32101521': __('identifierSchemeCodeUnspsc190501.32101521'),
	'identifierSchemeCodeUnspsc190501.32101522': __('identifierSchemeCodeUnspsc190501.32101522'),
	'identifierSchemeCodeUnspsc190501.32101523': __('identifierSchemeCodeUnspsc190501.32101523'),
	'identifierSchemeCodeUnspsc190501.32101524': __('identifierSchemeCodeUnspsc190501.32101524'),
	'identifierSchemeCodeUnspsc190501.32101525': __('identifierSchemeCodeUnspsc190501.32101525'),
	'identifierSchemeCodeUnspsc190501.32101526': __('identifierSchemeCodeUnspsc190501.32101526'),
	'identifierSchemeCodeUnspsc190501.32101527': __('identifierSchemeCodeUnspsc190501.32101527'),
	'identifierSchemeCodeUnspsc190501.32101528': __('identifierSchemeCodeUnspsc190501.32101528'),
	'identifierSchemeCodeUnspsc190501.32101529': __('identifierSchemeCodeUnspsc190501.32101529'),
	'identifierSchemeCodeUnspsc190501.32101530': __('identifierSchemeCodeUnspsc190501.32101530'),
	'identifierSchemeCodeUnspsc190501.32101531': __('identifierSchemeCodeUnspsc190501.32101531'),
	'identifierSchemeCodeUnspsc190501.32101532': __('identifierSchemeCodeUnspsc190501.32101532'),
	'identifierSchemeCodeUnspsc190501.32101533': __('identifierSchemeCodeUnspsc190501.32101533'),
	'identifierSchemeCodeUnspsc190501.32101534': __('identifierSchemeCodeUnspsc190501.32101534'),
	'identifierSchemeCodeUnspsc190501.32101535': __('identifierSchemeCodeUnspsc190501.32101535'),
	'identifierSchemeCodeUnspsc190501.32101536': __('identifierSchemeCodeUnspsc190501.32101536'),
	'identifierSchemeCodeUnspsc190501.32101537': __('identifierSchemeCodeUnspsc190501.32101537'),
	'identifierSchemeCodeUnspsc190501.32101538': __('identifierSchemeCodeUnspsc190501.32101538'),
	'identifierSchemeCodeUnspsc190501.32101539': __('identifierSchemeCodeUnspsc190501.32101539'),
	'identifierSchemeCodeUnspsc190501.32101540': __('identifierSchemeCodeUnspsc190501.32101540'),
	'identifierSchemeCodeUnspsc190501.32101541': __('identifierSchemeCodeUnspsc190501.32101541'),
	'identifierSchemeCodeUnspsc190501.32101542': __('identifierSchemeCodeUnspsc190501.32101542'),
	'identifierSchemeCodeUnspsc190501.32101543': __('identifierSchemeCodeUnspsc190501.32101543'),
	'identifierSchemeCodeUnspsc190501.32101544': __('identifierSchemeCodeUnspsc190501.32101544'),
	'identifierSchemeCodeUnspsc190501.32101545': __('identifierSchemeCodeUnspsc190501.32101545'),
	'identifierSchemeCodeUnspsc190501.32101546': __('identifierSchemeCodeUnspsc190501.32101546'),
	'identifierSchemeCodeUnspsc190501.32101547': __('identifierSchemeCodeUnspsc190501.32101547'),
	'identifierSchemeCodeUnspsc190501.32101548': __('identifierSchemeCodeUnspsc190501.32101548'),
	'identifierSchemeCodeUnspsc190501.32101600': __('identifierSchemeCodeUnspsc190501.32101600'),
	'identifierSchemeCodeUnspsc190501.32101601': __('identifierSchemeCodeUnspsc190501.32101601'),
	'identifierSchemeCodeUnspsc190501.32101602': __('identifierSchemeCodeUnspsc190501.32101602'),
	'identifierSchemeCodeUnspsc190501.32101603': __('identifierSchemeCodeUnspsc190501.32101603'),
	'identifierSchemeCodeUnspsc190501.32101604': __('identifierSchemeCodeUnspsc190501.32101604'),
	'identifierSchemeCodeUnspsc190501.32101605': __('identifierSchemeCodeUnspsc190501.32101605'),
	'identifierSchemeCodeUnspsc190501.32101606': __('identifierSchemeCodeUnspsc190501.32101606'),
	'identifierSchemeCodeUnspsc190501.32101607': __('identifierSchemeCodeUnspsc190501.32101607'),
	'identifierSchemeCodeUnspsc190501.32101608': __('identifierSchemeCodeUnspsc190501.32101608'),
	'identifierSchemeCodeUnspsc190501.32101609': __('identifierSchemeCodeUnspsc190501.32101609'),
	'identifierSchemeCodeUnspsc190501.32101611': __('identifierSchemeCodeUnspsc190501.32101611'),
	'identifierSchemeCodeUnspsc190501.32101612': __('identifierSchemeCodeUnspsc190501.32101612'),
	'identifierSchemeCodeUnspsc190501.32101613': __('identifierSchemeCodeUnspsc190501.32101613'),
	'identifierSchemeCodeUnspsc190501.32101614': __('identifierSchemeCodeUnspsc190501.32101614'),
	'identifierSchemeCodeUnspsc190501.32101615': __('identifierSchemeCodeUnspsc190501.32101615'),
	'identifierSchemeCodeUnspsc190501.32101616': __('identifierSchemeCodeUnspsc190501.32101616'),
	'identifierSchemeCodeUnspsc190501.32101617': __('identifierSchemeCodeUnspsc190501.32101617'),
	'identifierSchemeCodeUnspsc190501.32101618': __('identifierSchemeCodeUnspsc190501.32101618'),
	'identifierSchemeCodeUnspsc190501.32101619': __('identifierSchemeCodeUnspsc190501.32101619'),
	'identifierSchemeCodeUnspsc190501.32101620': __('identifierSchemeCodeUnspsc190501.32101620'),
	'identifierSchemeCodeUnspsc190501.32101621': __('identifierSchemeCodeUnspsc190501.32101621'),
	'identifierSchemeCodeUnspsc190501.32101622': __('identifierSchemeCodeUnspsc190501.32101622'),
	'identifierSchemeCodeUnspsc190501.32101623': __('identifierSchemeCodeUnspsc190501.32101623'),
	'identifierSchemeCodeUnspsc190501.32101624': __('identifierSchemeCodeUnspsc190501.32101624'),
	'identifierSchemeCodeUnspsc190501.32101625': __('identifierSchemeCodeUnspsc190501.32101625'),
	'identifierSchemeCodeUnspsc190501.32101626': __('identifierSchemeCodeUnspsc190501.32101626'),
	'identifierSchemeCodeUnspsc190501.32101627': __('identifierSchemeCodeUnspsc190501.32101627'),
	'identifierSchemeCodeUnspsc190501.32101628': __('identifierSchemeCodeUnspsc190501.32101628'),
	'identifierSchemeCodeUnspsc190501.32101629': __('identifierSchemeCodeUnspsc190501.32101629'),
	'identifierSchemeCodeUnspsc190501.32101630': __('identifierSchemeCodeUnspsc190501.32101630'),
	'identifierSchemeCodeUnspsc190501.32101631': __('identifierSchemeCodeUnspsc190501.32101631'),
	'identifierSchemeCodeUnspsc190501.32101632': __('identifierSchemeCodeUnspsc190501.32101632'),
	'identifierSchemeCodeUnspsc190501.32101633': __('identifierSchemeCodeUnspsc190501.32101633'),
	'identifierSchemeCodeUnspsc190501.32101634': __('identifierSchemeCodeUnspsc190501.32101634'),
	'identifierSchemeCodeUnspsc190501.32101635': __('identifierSchemeCodeUnspsc190501.32101635'),
	'identifierSchemeCodeUnspsc190501.32101636': __('identifierSchemeCodeUnspsc190501.32101636'),
	'identifierSchemeCodeUnspsc190501.32101637': __('identifierSchemeCodeUnspsc190501.32101637'),
	'identifierSchemeCodeUnspsc190501.32101638': __('identifierSchemeCodeUnspsc190501.32101638'),
	'identifierSchemeCodeUnspsc190501.32101639': __('identifierSchemeCodeUnspsc190501.32101639'),
	'identifierSchemeCodeUnspsc190501.32101640': __('identifierSchemeCodeUnspsc190501.32101640'),
	'identifierSchemeCodeUnspsc190501.32101641': __('identifierSchemeCodeUnspsc190501.32101641'),
	'identifierSchemeCodeUnspsc190501.32101642': __('identifierSchemeCodeUnspsc190501.32101642'),
	'identifierSchemeCodeUnspsc190501.32101643': __('identifierSchemeCodeUnspsc190501.32101643'),
	'identifierSchemeCodeUnspsc190501.32101644': __('identifierSchemeCodeUnspsc190501.32101644'),
	'identifierSchemeCodeUnspsc190501.32101645': __('identifierSchemeCodeUnspsc190501.32101645'),
	'identifierSchemeCodeUnspsc190501.32101646': __('identifierSchemeCodeUnspsc190501.32101646'),
	'identifierSchemeCodeUnspsc190501.32101647': __('identifierSchemeCodeUnspsc190501.32101647'),
	'identifierSchemeCodeUnspsc190501.32101648': __('identifierSchemeCodeUnspsc190501.32101648'),
	'identifierSchemeCodeUnspsc190501.32101649': __('identifierSchemeCodeUnspsc190501.32101649'),
	'identifierSchemeCodeUnspsc190501.32101650': __('identifierSchemeCodeUnspsc190501.32101650'),
	'identifierSchemeCodeUnspsc190501.32101651': __('identifierSchemeCodeUnspsc190501.32101651'),
	'identifierSchemeCodeUnspsc190501.32101652': __('identifierSchemeCodeUnspsc190501.32101652'),
	'identifierSchemeCodeUnspsc190501.32101653': __('identifierSchemeCodeUnspsc190501.32101653'),
	'identifierSchemeCodeUnspsc190501.32101654': __('identifierSchemeCodeUnspsc190501.32101654'),
	'identifierSchemeCodeUnspsc190501.32101655': __('identifierSchemeCodeUnspsc190501.32101655'),
	'identifierSchemeCodeUnspsc190501.32101656': __('identifierSchemeCodeUnspsc190501.32101656'),
	'identifierSchemeCodeUnspsc190501.32101657': __('identifierSchemeCodeUnspsc190501.32101657'),
	'identifierSchemeCodeUnspsc190501.32101658': __('identifierSchemeCodeUnspsc190501.32101658'),
	'identifierSchemeCodeUnspsc190501.32101659': __('identifierSchemeCodeUnspsc190501.32101659'),
	'identifierSchemeCodeUnspsc190501.32101660': __('identifierSchemeCodeUnspsc190501.32101660'),
	'identifierSchemeCodeUnspsc190501.32101661': __('identifierSchemeCodeUnspsc190501.32101661'),
	'identifierSchemeCodeUnspsc190501.32101662': __('identifierSchemeCodeUnspsc190501.32101662'),
	'identifierSchemeCodeUnspsc190501.32101663': __('identifierSchemeCodeUnspsc190501.32101663'),
	'identifierSchemeCodeUnspsc190501.32101664': __('identifierSchemeCodeUnspsc190501.32101664'),
	'identifierSchemeCodeUnspsc190501.32101665': __('identifierSchemeCodeUnspsc190501.32101665'),
	'identifierSchemeCodeUnspsc190501.32101666': __('identifierSchemeCodeUnspsc190501.32101666'),
	'identifierSchemeCodeUnspsc190501.32101667': __('identifierSchemeCodeUnspsc190501.32101667'),
	'identifierSchemeCodeUnspsc190501.32101668': __('identifierSchemeCodeUnspsc190501.32101668'),
	'identifierSchemeCodeUnspsc190501.32101669': __('identifierSchemeCodeUnspsc190501.32101669'),
	'identifierSchemeCodeUnspsc190501.32101670': __('identifierSchemeCodeUnspsc190501.32101670'),
	'identifierSchemeCodeUnspsc190501.32101671': __('identifierSchemeCodeUnspsc190501.32101671'),
	'identifierSchemeCodeUnspsc190501.32101672': __('identifierSchemeCodeUnspsc190501.32101672'),
	'identifierSchemeCodeUnspsc190501.32110000': __('identifierSchemeCodeUnspsc190501.32110000'),
	'identifierSchemeCodeUnspsc190501.32111500': __('identifierSchemeCodeUnspsc190501.32111500'),
	'identifierSchemeCodeUnspsc190501.32111501': __('identifierSchemeCodeUnspsc190501.32111501'),
	'identifierSchemeCodeUnspsc190501.32111502': __('identifierSchemeCodeUnspsc190501.32111502'),
	'identifierSchemeCodeUnspsc190501.32111503': __('identifierSchemeCodeUnspsc190501.32111503'),
	'identifierSchemeCodeUnspsc190501.32111504': __('identifierSchemeCodeUnspsc190501.32111504'),
	'identifierSchemeCodeUnspsc190501.32111505': __('identifierSchemeCodeUnspsc190501.32111505'),
	'identifierSchemeCodeUnspsc190501.32111506': __('identifierSchemeCodeUnspsc190501.32111506'),
	'identifierSchemeCodeUnspsc190501.32111507': __('identifierSchemeCodeUnspsc190501.32111507'),
	'identifierSchemeCodeUnspsc190501.32111508': __('identifierSchemeCodeUnspsc190501.32111508'),
	'identifierSchemeCodeUnspsc190501.32111509': __('identifierSchemeCodeUnspsc190501.32111509'),
	'identifierSchemeCodeUnspsc190501.32111510': __('identifierSchemeCodeUnspsc190501.32111510'),
	'identifierSchemeCodeUnspsc190501.32111511': __('identifierSchemeCodeUnspsc190501.32111511'),
	'identifierSchemeCodeUnspsc190501.32111512': __('identifierSchemeCodeUnspsc190501.32111512'),
	'identifierSchemeCodeUnspsc190501.32111513': __('identifierSchemeCodeUnspsc190501.32111513'),
	'identifierSchemeCodeUnspsc190501.32111514': __('identifierSchemeCodeUnspsc190501.32111514'),
	'identifierSchemeCodeUnspsc190501.32111515': __('identifierSchemeCodeUnspsc190501.32111515'),
	'identifierSchemeCodeUnspsc190501.32111600': __('identifierSchemeCodeUnspsc190501.32111600'),
	'identifierSchemeCodeUnspsc190501.32111601': __('identifierSchemeCodeUnspsc190501.32111601'),
	'identifierSchemeCodeUnspsc190501.32111602': __('identifierSchemeCodeUnspsc190501.32111602'),
	'identifierSchemeCodeUnspsc190501.32111603': __('identifierSchemeCodeUnspsc190501.32111603'),
	'identifierSchemeCodeUnspsc190501.32111604': __('identifierSchemeCodeUnspsc190501.32111604'),
	'identifierSchemeCodeUnspsc190501.32111607': __('identifierSchemeCodeUnspsc190501.32111607'),
	'identifierSchemeCodeUnspsc190501.32111608': __('identifierSchemeCodeUnspsc190501.32111608'),
	'identifierSchemeCodeUnspsc190501.32111609': __('identifierSchemeCodeUnspsc190501.32111609'),
	'identifierSchemeCodeUnspsc190501.32111610': __('identifierSchemeCodeUnspsc190501.32111610'),
	'identifierSchemeCodeUnspsc190501.32111611': __('identifierSchemeCodeUnspsc190501.32111611'),
	'identifierSchemeCodeUnspsc190501.32111612': __('identifierSchemeCodeUnspsc190501.32111612'),
	'identifierSchemeCodeUnspsc190501.32111613': __('identifierSchemeCodeUnspsc190501.32111613'),
	'identifierSchemeCodeUnspsc190501.32111614': __('identifierSchemeCodeUnspsc190501.32111614'),
	'identifierSchemeCodeUnspsc190501.32111615': __('identifierSchemeCodeUnspsc190501.32111615'),
	'identifierSchemeCodeUnspsc190501.32111616': __('identifierSchemeCodeUnspsc190501.32111616'),
	'identifierSchemeCodeUnspsc190501.32111700': __('identifierSchemeCodeUnspsc190501.32111700'),
	'identifierSchemeCodeUnspsc190501.32111701': __('identifierSchemeCodeUnspsc190501.32111701'),
	'identifierSchemeCodeUnspsc190501.32111702': __('identifierSchemeCodeUnspsc190501.32111702'),
	'identifierSchemeCodeUnspsc190501.32111703': __('identifierSchemeCodeUnspsc190501.32111703'),
	'identifierSchemeCodeUnspsc190501.32111704': __('identifierSchemeCodeUnspsc190501.32111704'),
	'identifierSchemeCodeUnspsc190501.32111705': __('identifierSchemeCodeUnspsc190501.32111705'),
	'identifierSchemeCodeUnspsc190501.32111706': __('identifierSchemeCodeUnspsc190501.32111706'),
	'identifierSchemeCodeUnspsc190501.32111707': __('identifierSchemeCodeUnspsc190501.32111707'),
	'identifierSchemeCodeUnspsc190501.32111708': __('identifierSchemeCodeUnspsc190501.32111708'),
	'identifierSchemeCodeUnspsc190501.32111709': __('identifierSchemeCodeUnspsc190501.32111709'),
	'identifierSchemeCodeUnspsc190501.32120000': __('identifierSchemeCodeUnspsc190501.32120000'),
	'identifierSchemeCodeUnspsc190501.32121500': __('identifierSchemeCodeUnspsc190501.32121500'),
	'identifierSchemeCodeUnspsc190501.32121501': __('identifierSchemeCodeUnspsc190501.32121501'),
	'identifierSchemeCodeUnspsc190501.32121502': __('identifierSchemeCodeUnspsc190501.32121502'),
	'identifierSchemeCodeUnspsc190501.32121503': __('identifierSchemeCodeUnspsc190501.32121503'),
	'identifierSchemeCodeUnspsc190501.32121504': __('identifierSchemeCodeUnspsc190501.32121504'),
	'identifierSchemeCodeUnspsc190501.32121505': __('identifierSchemeCodeUnspsc190501.32121505'),
	'identifierSchemeCodeUnspsc190501.32121506': __('identifierSchemeCodeUnspsc190501.32121506'),
	'identifierSchemeCodeUnspsc190501.32121507': __('identifierSchemeCodeUnspsc190501.32121507'),
	'identifierSchemeCodeUnspsc190501.32121508': __('identifierSchemeCodeUnspsc190501.32121508'),
	'identifierSchemeCodeUnspsc190501.32121509': __('identifierSchemeCodeUnspsc190501.32121509'),
	'identifierSchemeCodeUnspsc190501.32121510': __('identifierSchemeCodeUnspsc190501.32121510'),
	'identifierSchemeCodeUnspsc190501.32121511': __('identifierSchemeCodeUnspsc190501.32121511'),
	'identifierSchemeCodeUnspsc190501.32121512': __('identifierSchemeCodeUnspsc190501.32121512'),
	'identifierSchemeCodeUnspsc190501.32121513': __('identifierSchemeCodeUnspsc190501.32121513'),
	'identifierSchemeCodeUnspsc190501.32121514': __('identifierSchemeCodeUnspsc190501.32121514'),
	'identifierSchemeCodeUnspsc190501.32121515': __('identifierSchemeCodeUnspsc190501.32121515'),
	'identifierSchemeCodeUnspsc190501.32121600': __('identifierSchemeCodeUnspsc190501.32121600'),
	'identifierSchemeCodeUnspsc190501.32121602': __('identifierSchemeCodeUnspsc190501.32121602'),
	'identifierSchemeCodeUnspsc190501.32121603': __('identifierSchemeCodeUnspsc190501.32121603'),
	'identifierSchemeCodeUnspsc190501.32121607': __('identifierSchemeCodeUnspsc190501.32121607'),
	'identifierSchemeCodeUnspsc190501.32121609': __('identifierSchemeCodeUnspsc190501.32121609'),
	'identifierSchemeCodeUnspsc190501.32121610': __('identifierSchemeCodeUnspsc190501.32121610'),
	'identifierSchemeCodeUnspsc190501.32121611': __('identifierSchemeCodeUnspsc190501.32121611'),
	'identifierSchemeCodeUnspsc190501.32121612': __('identifierSchemeCodeUnspsc190501.32121612'),
	'identifierSchemeCodeUnspsc190501.32121613': __('identifierSchemeCodeUnspsc190501.32121613'),
	'identifierSchemeCodeUnspsc190501.32121614': __('identifierSchemeCodeUnspsc190501.32121614'),
	'identifierSchemeCodeUnspsc190501.32121615': __('identifierSchemeCodeUnspsc190501.32121615'),
	'identifierSchemeCodeUnspsc190501.32121616': __('identifierSchemeCodeUnspsc190501.32121616'),
	'identifierSchemeCodeUnspsc190501.32121617': __('identifierSchemeCodeUnspsc190501.32121617'),
	'identifierSchemeCodeUnspsc190501.32121618': __('identifierSchemeCodeUnspsc190501.32121618'),
	'identifierSchemeCodeUnspsc190501.32121700': __('identifierSchemeCodeUnspsc190501.32121700'),
	'identifierSchemeCodeUnspsc190501.32121701': __('identifierSchemeCodeUnspsc190501.32121701'),
	'identifierSchemeCodeUnspsc190501.32121702': __('identifierSchemeCodeUnspsc190501.32121702'),
	'identifierSchemeCodeUnspsc190501.32121703': __('identifierSchemeCodeUnspsc190501.32121703'),
	'identifierSchemeCodeUnspsc190501.32121704': __('identifierSchemeCodeUnspsc190501.32121704'),
	'identifierSchemeCodeUnspsc190501.32121705': __('identifierSchemeCodeUnspsc190501.32121705'),
	'identifierSchemeCodeUnspsc190501.32121706': __('identifierSchemeCodeUnspsc190501.32121706'),
	'identifierSchemeCodeUnspsc190501.32121707': __('identifierSchemeCodeUnspsc190501.32121707'),
	'identifierSchemeCodeUnspsc190501.32121708': __('identifierSchemeCodeUnspsc190501.32121708'),
	'identifierSchemeCodeUnspsc190501.32121709': __('identifierSchemeCodeUnspsc190501.32121709'),
	'identifierSchemeCodeUnspsc190501.32121710': __('identifierSchemeCodeUnspsc190501.32121710'),
	'identifierSchemeCodeUnspsc190501.32121711': __('identifierSchemeCodeUnspsc190501.32121711'),
	'identifierSchemeCodeUnspsc190501.32121712': __('identifierSchemeCodeUnspsc190501.32121712'),
	'identifierSchemeCodeUnspsc190501.32121800': __('identifierSchemeCodeUnspsc190501.32121800'),
	'identifierSchemeCodeUnspsc190501.32121801': __('identifierSchemeCodeUnspsc190501.32121801'),
	'identifierSchemeCodeUnspsc190501.32121802': __('identifierSchemeCodeUnspsc190501.32121802'),
	'identifierSchemeCodeUnspsc190501.32121803': __('identifierSchemeCodeUnspsc190501.32121803'),
	'identifierSchemeCodeUnspsc190501.32121804': __('identifierSchemeCodeUnspsc190501.32121804'),
	'identifierSchemeCodeUnspsc190501.32121900': __('identifierSchemeCodeUnspsc190501.32121900'),
	'identifierSchemeCodeUnspsc190501.32121901': __('identifierSchemeCodeUnspsc190501.32121901'),
	'identifierSchemeCodeUnspsc190501.32121902': __('identifierSchemeCodeUnspsc190501.32121902'),
	'identifierSchemeCodeUnspsc190501.32130000': __('identifierSchemeCodeUnspsc190501.32130000'),
	'identifierSchemeCodeUnspsc190501.32131000': __('identifierSchemeCodeUnspsc190501.32131000'),
	'identifierSchemeCodeUnspsc190501.32131001': __('identifierSchemeCodeUnspsc190501.32131001'),
	'identifierSchemeCodeUnspsc190501.32131002': __('identifierSchemeCodeUnspsc190501.32131002'),
	'identifierSchemeCodeUnspsc190501.32131003': __('identifierSchemeCodeUnspsc190501.32131003'),
	'identifierSchemeCodeUnspsc190501.32131005': __('identifierSchemeCodeUnspsc190501.32131005'),
	'identifierSchemeCodeUnspsc190501.32131006': __('identifierSchemeCodeUnspsc190501.32131006'),
	'identifierSchemeCodeUnspsc190501.32131007': __('identifierSchemeCodeUnspsc190501.32131007'),
	'identifierSchemeCodeUnspsc190501.32131008': __('identifierSchemeCodeUnspsc190501.32131008'),
	'identifierSchemeCodeUnspsc190501.32131009': __('identifierSchemeCodeUnspsc190501.32131009'),
	'identifierSchemeCodeUnspsc190501.32131010': __('identifierSchemeCodeUnspsc190501.32131010'),
	'identifierSchemeCodeUnspsc190501.32131011': __('identifierSchemeCodeUnspsc190501.32131011'),
	'identifierSchemeCodeUnspsc190501.32131012': __('identifierSchemeCodeUnspsc190501.32131012'),
	'identifierSchemeCodeUnspsc190501.32131013': __('identifierSchemeCodeUnspsc190501.32131013'),
	'identifierSchemeCodeUnspsc190501.32131014': __('identifierSchemeCodeUnspsc190501.32131014'),
	'identifierSchemeCodeUnspsc190501.32131015': __('identifierSchemeCodeUnspsc190501.32131015'),
	'identifierSchemeCodeUnspsc190501.32131016': __('identifierSchemeCodeUnspsc190501.32131016'),
	'identifierSchemeCodeUnspsc190501.32131017': __('identifierSchemeCodeUnspsc190501.32131017'),
	'identifierSchemeCodeUnspsc190501.32131018': __('identifierSchemeCodeUnspsc190501.32131018'),
	'identifierSchemeCodeUnspsc190501.32131019': __('identifierSchemeCodeUnspsc190501.32131019'),
	'identifierSchemeCodeUnspsc190501.32131020': __('identifierSchemeCodeUnspsc190501.32131020'),
	'identifierSchemeCodeUnspsc190501.32131021': __('identifierSchemeCodeUnspsc190501.32131021'),
	'identifierSchemeCodeUnspsc190501.32131022': __('identifierSchemeCodeUnspsc190501.32131022'),
	'identifierSchemeCodeUnspsc190501.32131023': __('identifierSchemeCodeUnspsc190501.32131023'),
	'identifierSchemeCodeUnspsc190501.32140000': __('identifierSchemeCodeUnspsc190501.32140000'),
	'identifierSchemeCodeUnspsc190501.32141000': __('identifierSchemeCodeUnspsc190501.32141000'),
	'identifierSchemeCodeUnspsc190501.32141001': __('identifierSchemeCodeUnspsc190501.32141001'),
	'identifierSchemeCodeUnspsc190501.32141002': __('identifierSchemeCodeUnspsc190501.32141002'),
	'identifierSchemeCodeUnspsc190501.32141003': __('identifierSchemeCodeUnspsc190501.32141003'),
	'identifierSchemeCodeUnspsc190501.32141004': __('identifierSchemeCodeUnspsc190501.32141004'),
	'identifierSchemeCodeUnspsc190501.32141005': __('identifierSchemeCodeUnspsc190501.32141005'),
	'identifierSchemeCodeUnspsc190501.32141006': __('identifierSchemeCodeUnspsc190501.32141006'),
	'identifierSchemeCodeUnspsc190501.32141007': __('identifierSchemeCodeUnspsc190501.32141007'),
	'identifierSchemeCodeUnspsc190501.32141008': __('identifierSchemeCodeUnspsc190501.32141008'),
	'identifierSchemeCodeUnspsc190501.32141009': __('identifierSchemeCodeUnspsc190501.32141009'),
	'identifierSchemeCodeUnspsc190501.32141010': __('identifierSchemeCodeUnspsc190501.32141010'),
	'identifierSchemeCodeUnspsc190501.32141011': __('identifierSchemeCodeUnspsc190501.32141011'),
	'identifierSchemeCodeUnspsc190501.32141012': __('identifierSchemeCodeUnspsc190501.32141012'),
	'identifierSchemeCodeUnspsc190501.32141013': __('identifierSchemeCodeUnspsc190501.32141013'),
	'identifierSchemeCodeUnspsc190501.32141014': __('identifierSchemeCodeUnspsc190501.32141014'),
	'identifierSchemeCodeUnspsc190501.32141015': __('identifierSchemeCodeUnspsc190501.32141015'),
	'identifierSchemeCodeUnspsc190501.32141016': __('identifierSchemeCodeUnspsc190501.32141016'),
	'identifierSchemeCodeUnspsc190501.32141017': __('identifierSchemeCodeUnspsc190501.32141017'),
	'identifierSchemeCodeUnspsc190501.32141018': __('identifierSchemeCodeUnspsc190501.32141018'),
	'identifierSchemeCodeUnspsc190501.32141019': __('identifierSchemeCodeUnspsc190501.32141019'),
	'identifierSchemeCodeUnspsc190501.32141020': __('identifierSchemeCodeUnspsc190501.32141020'),
	'identifierSchemeCodeUnspsc190501.32141021': __('identifierSchemeCodeUnspsc190501.32141021'),
	'identifierSchemeCodeUnspsc190501.32141022': __('identifierSchemeCodeUnspsc190501.32141022'),
	'identifierSchemeCodeUnspsc190501.32141100': __('identifierSchemeCodeUnspsc190501.32141100'),
	'identifierSchemeCodeUnspsc190501.32141101': __('identifierSchemeCodeUnspsc190501.32141101'),
	'identifierSchemeCodeUnspsc190501.32141102': __('identifierSchemeCodeUnspsc190501.32141102'),
	'identifierSchemeCodeUnspsc190501.32141103': __('identifierSchemeCodeUnspsc190501.32141103'),
	'identifierSchemeCodeUnspsc190501.32141104': __('identifierSchemeCodeUnspsc190501.32141104'),
	'identifierSchemeCodeUnspsc190501.32141105': __('identifierSchemeCodeUnspsc190501.32141105'),
	'identifierSchemeCodeUnspsc190501.32141106': __('identifierSchemeCodeUnspsc190501.32141106'),
	'identifierSchemeCodeUnspsc190501.32141107': __('identifierSchemeCodeUnspsc190501.32141107'),
	'identifierSchemeCodeUnspsc190501.32141108': __('identifierSchemeCodeUnspsc190501.32141108'),
	'identifierSchemeCodeUnspsc190501.32141109': __('identifierSchemeCodeUnspsc190501.32141109'),
	'identifierSchemeCodeUnspsc190501.32141110': __('identifierSchemeCodeUnspsc190501.32141110'),
	'identifierSchemeCodeUnspsc190501.32150000': __('identifierSchemeCodeUnspsc190501.32150000'),
	'identifierSchemeCodeUnspsc190501.32151500': __('identifierSchemeCodeUnspsc190501.32151500'),
	'identifierSchemeCodeUnspsc190501.32151501': __('identifierSchemeCodeUnspsc190501.32151501'),
	'identifierSchemeCodeUnspsc190501.32151502': __('identifierSchemeCodeUnspsc190501.32151502'),
	'identifierSchemeCodeUnspsc190501.32151503': __('identifierSchemeCodeUnspsc190501.32151503'),
	'identifierSchemeCodeUnspsc190501.32151504': __('identifierSchemeCodeUnspsc190501.32151504'),
	'identifierSchemeCodeUnspsc190501.32151600': __('identifierSchemeCodeUnspsc190501.32151600'),
	'identifierSchemeCodeUnspsc190501.32151601': __('identifierSchemeCodeUnspsc190501.32151601'),
	'identifierSchemeCodeUnspsc190501.32151602': __('identifierSchemeCodeUnspsc190501.32151602'),
	'identifierSchemeCodeUnspsc190501.32151603': __('identifierSchemeCodeUnspsc190501.32151603'),
	'identifierSchemeCodeUnspsc190501.32151700': __('identifierSchemeCodeUnspsc190501.32151700'),
	'identifierSchemeCodeUnspsc190501.32151701': __('identifierSchemeCodeUnspsc190501.32151701'),
	'identifierSchemeCodeUnspsc190501.32151702': __('identifierSchemeCodeUnspsc190501.32151702'),
	'identifierSchemeCodeUnspsc190501.32151703': __('identifierSchemeCodeUnspsc190501.32151703'),
	'identifierSchemeCodeUnspsc190501.32151704': __('identifierSchemeCodeUnspsc190501.32151704'),
	'identifierSchemeCodeUnspsc190501.32151705': __('identifierSchemeCodeUnspsc190501.32151705'),
	'identifierSchemeCodeUnspsc190501.32151706': __('identifierSchemeCodeUnspsc190501.32151706'),
	'identifierSchemeCodeUnspsc190501.32151707': __('identifierSchemeCodeUnspsc190501.32151707'),
	'identifierSchemeCodeUnspsc190501.32151708': __('identifierSchemeCodeUnspsc190501.32151708'),
	'identifierSchemeCodeUnspsc190501.32151709': __('identifierSchemeCodeUnspsc190501.32151709'),
	'identifierSchemeCodeUnspsc190501.32151800': __('identifierSchemeCodeUnspsc190501.32151800'),
	'identifierSchemeCodeUnspsc190501.32151801': __('identifierSchemeCodeUnspsc190501.32151801'),
	'identifierSchemeCodeUnspsc190501.32151802': __('identifierSchemeCodeUnspsc190501.32151802'),
	'identifierSchemeCodeUnspsc190501.32151803': __('identifierSchemeCodeUnspsc190501.32151803'),
	'identifierSchemeCodeUnspsc190501.32151804': __('identifierSchemeCodeUnspsc190501.32151804'),
	'identifierSchemeCodeUnspsc190501.32151805': __('identifierSchemeCodeUnspsc190501.32151805'),
	'identifierSchemeCodeUnspsc190501.32151900': __('identifierSchemeCodeUnspsc190501.32151900'),
	'identifierSchemeCodeUnspsc190501.32151901': __('identifierSchemeCodeUnspsc190501.32151901'),
	'identifierSchemeCodeUnspsc190501.32151902': __('identifierSchemeCodeUnspsc190501.32151902'),
	'identifierSchemeCodeUnspsc190501.32151903': __('identifierSchemeCodeUnspsc190501.32151903'),
	'identifierSchemeCodeUnspsc190501.32151904': __('identifierSchemeCodeUnspsc190501.32151904'),
	'identifierSchemeCodeUnspsc190501.32151905': __('identifierSchemeCodeUnspsc190501.32151905'),
	'identifierSchemeCodeUnspsc190501.32151906': __('identifierSchemeCodeUnspsc190501.32151906'),
	'identifierSchemeCodeUnspsc190501.32151907': __('identifierSchemeCodeUnspsc190501.32151907'),
	'identifierSchemeCodeUnspsc190501.32151908': __('identifierSchemeCodeUnspsc190501.32151908'),
	'identifierSchemeCodeUnspsc190501.32151909': __('identifierSchemeCodeUnspsc190501.32151909'),
	'identifierSchemeCodeUnspsc190501.32151910': __('identifierSchemeCodeUnspsc190501.32151910'),
	'identifierSchemeCodeUnspsc190501.32152000': __('identifierSchemeCodeUnspsc190501.32152000'),
	'identifierSchemeCodeUnspsc190501.32152001': __('identifierSchemeCodeUnspsc190501.32152001'),
	'identifierSchemeCodeUnspsc190501.32152002': __('identifierSchemeCodeUnspsc190501.32152002'),
	'identifierSchemeCodeUnspsc190501.32152003': __('identifierSchemeCodeUnspsc190501.32152003'),
	'identifierSchemeCodeUnspsc190501.39000000': __('identifierSchemeCodeUnspsc190501.39000000'),
	'identifierSchemeCodeUnspsc190501.39100000': __('identifierSchemeCodeUnspsc190501.39100000'),
	'identifierSchemeCodeUnspsc190501.39101600': __('identifierSchemeCodeUnspsc190501.39101600'),
	'identifierSchemeCodeUnspsc190501.39101601': __('identifierSchemeCodeUnspsc190501.39101601'),
	'identifierSchemeCodeUnspsc190501.39101602': __('identifierSchemeCodeUnspsc190501.39101602'),
	'identifierSchemeCodeUnspsc190501.39101603': __('identifierSchemeCodeUnspsc190501.39101603'),
	'identifierSchemeCodeUnspsc190501.39101605': __('identifierSchemeCodeUnspsc190501.39101605'),
	'identifierSchemeCodeUnspsc190501.39101608': __('identifierSchemeCodeUnspsc190501.39101608'),
	'identifierSchemeCodeUnspsc190501.39101609': __('identifierSchemeCodeUnspsc190501.39101609'),
	'identifierSchemeCodeUnspsc190501.39101612': __('identifierSchemeCodeUnspsc190501.39101612'),
	'identifierSchemeCodeUnspsc190501.39101613': __('identifierSchemeCodeUnspsc190501.39101613'),
	'identifierSchemeCodeUnspsc190501.39101614': __('identifierSchemeCodeUnspsc190501.39101614'),
	'identifierSchemeCodeUnspsc190501.39101615': __('identifierSchemeCodeUnspsc190501.39101615'),
	'identifierSchemeCodeUnspsc190501.39101616': __('identifierSchemeCodeUnspsc190501.39101616'),
	'identifierSchemeCodeUnspsc190501.39101617': __('identifierSchemeCodeUnspsc190501.39101617'),
	'identifierSchemeCodeUnspsc190501.39101618': __('identifierSchemeCodeUnspsc190501.39101618'),
	'identifierSchemeCodeUnspsc190501.39101619': __('identifierSchemeCodeUnspsc190501.39101619'),
	'identifierSchemeCodeUnspsc190501.39101620': __('identifierSchemeCodeUnspsc190501.39101620'),
	'identifierSchemeCodeUnspsc190501.39101621': __('identifierSchemeCodeUnspsc190501.39101621'),
	'identifierSchemeCodeUnspsc190501.39101622': __('identifierSchemeCodeUnspsc190501.39101622'),
	'identifierSchemeCodeUnspsc190501.39101623': __('identifierSchemeCodeUnspsc190501.39101623'),
	'identifierSchemeCodeUnspsc190501.39101624': __('identifierSchemeCodeUnspsc190501.39101624'),
	'identifierSchemeCodeUnspsc190501.39101625': __('identifierSchemeCodeUnspsc190501.39101625'),
	'identifierSchemeCodeUnspsc190501.39101626': __('identifierSchemeCodeUnspsc190501.39101626'),
	'identifierSchemeCodeUnspsc190501.39101627': __('identifierSchemeCodeUnspsc190501.39101627'),
	'identifierSchemeCodeUnspsc190501.39101628': __('identifierSchemeCodeUnspsc190501.39101628'),
	'identifierSchemeCodeUnspsc190501.39101800': __('identifierSchemeCodeUnspsc190501.39101800'),
	'identifierSchemeCodeUnspsc190501.39101801': __('identifierSchemeCodeUnspsc190501.39101801'),
	'identifierSchemeCodeUnspsc190501.39101802': __('identifierSchemeCodeUnspsc190501.39101802'),
	'identifierSchemeCodeUnspsc190501.39101803': __('identifierSchemeCodeUnspsc190501.39101803'),
	'identifierSchemeCodeUnspsc190501.39101804': __('identifierSchemeCodeUnspsc190501.39101804'),
	'identifierSchemeCodeUnspsc190501.39101805': __('identifierSchemeCodeUnspsc190501.39101805'),
	'identifierSchemeCodeUnspsc190501.39101806': __('identifierSchemeCodeUnspsc190501.39101806'),
	'identifierSchemeCodeUnspsc190501.39101807': __('identifierSchemeCodeUnspsc190501.39101807'),
	'identifierSchemeCodeUnspsc190501.39101900': __('identifierSchemeCodeUnspsc190501.39101900'),
	'identifierSchemeCodeUnspsc190501.39101901': __('identifierSchemeCodeUnspsc190501.39101901'),
	'identifierSchemeCodeUnspsc190501.39101902': __('identifierSchemeCodeUnspsc190501.39101902'),
	'identifierSchemeCodeUnspsc190501.39101903': __('identifierSchemeCodeUnspsc190501.39101903'),
	'identifierSchemeCodeUnspsc190501.39101904': __('identifierSchemeCodeUnspsc190501.39101904'),
	'identifierSchemeCodeUnspsc190501.39101905': __('identifierSchemeCodeUnspsc190501.39101905'),
	'identifierSchemeCodeUnspsc190501.39101906': __('identifierSchemeCodeUnspsc190501.39101906'),
	'identifierSchemeCodeUnspsc190501.39101907': __('identifierSchemeCodeUnspsc190501.39101907'),
	'identifierSchemeCodeUnspsc190501.39110000': __('identifierSchemeCodeUnspsc190501.39110000'),
	'identifierSchemeCodeUnspsc190501.39111500': __('identifierSchemeCodeUnspsc190501.39111500'),
	'identifierSchemeCodeUnspsc190501.39111501': __('identifierSchemeCodeUnspsc190501.39111501'),
	'identifierSchemeCodeUnspsc190501.39111503': __('identifierSchemeCodeUnspsc190501.39111503'),
	'identifierSchemeCodeUnspsc190501.39111504': __('identifierSchemeCodeUnspsc190501.39111504'),
	'identifierSchemeCodeUnspsc190501.39111505': __('identifierSchemeCodeUnspsc190501.39111505'),
	'identifierSchemeCodeUnspsc190501.39111506': __('identifierSchemeCodeUnspsc190501.39111506'),
	'identifierSchemeCodeUnspsc190501.39111507': __('identifierSchemeCodeUnspsc190501.39111507'),
	'identifierSchemeCodeUnspsc190501.39111508': __('identifierSchemeCodeUnspsc190501.39111508'),
	'identifierSchemeCodeUnspsc190501.39111509': __('identifierSchemeCodeUnspsc190501.39111509'),
	'identifierSchemeCodeUnspsc190501.39111510': __('identifierSchemeCodeUnspsc190501.39111510'),
	'identifierSchemeCodeUnspsc190501.39111512': __('identifierSchemeCodeUnspsc190501.39111512'),
	'identifierSchemeCodeUnspsc190501.39111515': __('identifierSchemeCodeUnspsc190501.39111515'),
	'identifierSchemeCodeUnspsc190501.39111520': __('identifierSchemeCodeUnspsc190501.39111520'),
	'identifierSchemeCodeUnspsc190501.39111521': __('identifierSchemeCodeUnspsc190501.39111521'),
	'identifierSchemeCodeUnspsc190501.39111522': __('identifierSchemeCodeUnspsc190501.39111522'),
	'identifierSchemeCodeUnspsc190501.39111524': __('identifierSchemeCodeUnspsc190501.39111524'),
	'identifierSchemeCodeUnspsc190501.39111525': __('identifierSchemeCodeUnspsc190501.39111525'),
	'identifierSchemeCodeUnspsc190501.39111527': __('identifierSchemeCodeUnspsc190501.39111527'),
	'identifierSchemeCodeUnspsc190501.39111528': __('identifierSchemeCodeUnspsc190501.39111528'),
	'identifierSchemeCodeUnspsc190501.39111529': __('identifierSchemeCodeUnspsc190501.39111529'),
	'identifierSchemeCodeUnspsc190501.39111530': __('identifierSchemeCodeUnspsc190501.39111530'),
	'identifierSchemeCodeUnspsc190501.39111531': __('identifierSchemeCodeUnspsc190501.39111531'),
	'identifierSchemeCodeUnspsc190501.39111532': __('identifierSchemeCodeUnspsc190501.39111532'),
	'identifierSchemeCodeUnspsc190501.39111533': __('identifierSchemeCodeUnspsc190501.39111533'),
	'identifierSchemeCodeUnspsc190501.39111534': __('identifierSchemeCodeUnspsc190501.39111534'),
	'identifierSchemeCodeUnspsc190501.39111535': __('identifierSchemeCodeUnspsc190501.39111535'),
	'identifierSchemeCodeUnspsc190501.39111536': __('identifierSchemeCodeUnspsc190501.39111536'),
	'identifierSchemeCodeUnspsc190501.39111537': __('identifierSchemeCodeUnspsc190501.39111537'),
	'identifierSchemeCodeUnspsc190501.39111538': __('identifierSchemeCodeUnspsc190501.39111538'),
	'identifierSchemeCodeUnspsc190501.39111539': __('identifierSchemeCodeUnspsc190501.39111539'),
	'identifierSchemeCodeUnspsc190501.39111540': __('identifierSchemeCodeUnspsc190501.39111540'),
	'identifierSchemeCodeUnspsc190501.39111541': __('identifierSchemeCodeUnspsc190501.39111541'),
	'identifierSchemeCodeUnspsc190501.39111542': __('identifierSchemeCodeUnspsc190501.39111542'),
	'identifierSchemeCodeUnspsc190501.39111543': __('identifierSchemeCodeUnspsc190501.39111543'),
	'identifierSchemeCodeUnspsc190501.39111544': __('identifierSchemeCodeUnspsc190501.39111544'),
	'identifierSchemeCodeUnspsc190501.39111545': __('identifierSchemeCodeUnspsc190501.39111545'),
	'identifierSchemeCodeUnspsc190501.39111546': __('identifierSchemeCodeUnspsc190501.39111546'),
	'identifierSchemeCodeUnspsc190501.39111547': __('identifierSchemeCodeUnspsc190501.39111547'),
	'identifierSchemeCodeUnspsc190501.39111600': __('identifierSchemeCodeUnspsc190501.39111600'),
	'identifierSchemeCodeUnspsc190501.39111603': __('identifierSchemeCodeUnspsc190501.39111603'),
	'identifierSchemeCodeUnspsc190501.39111605': __('identifierSchemeCodeUnspsc190501.39111605'),
	'identifierSchemeCodeUnspsc190501.39111606': __('identifierSchemeCodeUnspsc190501.39111606'),
	'identifierSchemeCodeUnspsc190501.39111608': __('identifierSchemeCodeUnspsc190501.39111608'),
	'identifierSchemeCodeUnspsc190501.39111609': __('identifierSchemeCodeUnspsc190501.39111609'),
	'identifierSchemeCodeUnspsc190501.39111610': __('identifierSchemeCodeUnspsc190501.39111610'),
	'identifierSchemeCodeUnspsc190501.39111611': __('identifierSchemeCodeUnspsc190501.39111611'),
	'identifierSchemeCodeUnspsc190501.39111612': __('identifierSchemeCodeUnspsc190501.39111612'),
	'identifierSchemeCodeUnspsc190501.39111613': __('identifierSchemeCodeUnspsc190501.39111613'),
	'identifierSchemeCodeUnspsc190501.39111614': __('identifierSchemeCodeUnspsc190501.39111614'),
	'identifierSchemeCodeUnspsc190501.39111615': __('identifierSchemeCodeUnspsc190501.39111615'),
	'identifierSchemeCodeUnspsc190501.39111616': __('identifierSchemeCodeUnspsc190501.39111616'),
	'identifierSchemeCodeUnspsc190501.39111617': __('identifierSchemeCodeUnspsc190501.39111617'),
	'identifierSchemeCodeUnspsc190501.39111618': __('identifierSchemeCodeUnspsc190501.39111618'),
	'identifierSchemeCodeUnspsc190501.39111619': __('identifierSchemeCodeUnspsc190501.39111619'),
	'identifierSchemeCodeUnspsc190501.39111620': __('identifierSchemeCodeUnspsc190501.39111620'),
	'identifierSchemeCodeUnspsc190501.39111700': __('identifierSchemeCodeUnspsc190501.39111700'),
	'identifierSchemeCodeUnspsc190501.39111703': __('identifierSchemeCodeUnspsc190501.39111703'),
	'identifierSchemeCodeUnspsc190501.39111705': __('identifierSchemeCodeUnspsc190501.39111705'),
	'identifierSchemeCodeUnspsc190501.39111706': __('identifierSchemeCodeUnspsc190501.39111706'),
	'identifierSchemeCodeUnspsc190501.39111707': __('identifierSchemeCodeUnspsc190501.39111707'),
	'identifierSchemeCodeUnspsc190501.39111708': __('identifierSchemeCodeUnspsc190501.39111708'),
	'identifierSchemeCodeUnspsc190501.39111709': __('identifierSchemeCodeUnspsc190501.39111709'),
	'identifierSchemeCodeUnspsc190501.39111710': __('identifierSchemeCodeUnspsc190501.39111710'),
	'identifierSchemeCodeUnspsc190501.39111711': __('identifierSchemeCodeUnspsc190501.39111711'),
	'identifierSchemeCodeUnspsc190501.39111712': __('identifierSchemeCodeUnspsc190501.39111712'),
	'identifierSchemeCodeUnspsc190501.39111713': __('identifierSchemeCodeUnspsc190501.39111713'),
	'identifierSchemeCodeUnspsc190501.39111714': __('identifierSchemeCodeUnspsc190501.39111714'),
	'identifierSchemeCodeUnspsc190501.39111800': __('identifierSchemeCodeUnspsc190501.39111800'),
	'identifierSchemeCodeUnspsc190501.39111801': __('identifierSchemeCodeUnspsc190501.39111801'),
	'identifierSchemeCodeUnspsc190501.39111802': __('identifierSchemeCodeUnspsc190501.39111802'),
	'identifierSchemeCodeUnspsc190501.39111803': __('identifierSchemeCodeUnspsc190501.39111803'),
	'identifierSchemeCodeUnspsc190501.39111806': __('identifierSchemeCodeUnspsc190501.39111806'),
	'identifierSchemeCodeUnspsc190501.39111808': __('identifierSchemeCodeUnspsc190501.39111808'),
	'identifierSchemeCodeUnspsc190501.39111809': __('identifierSchemeCodeUnspsc190501.39111809'),
	'identifierSchemeCodeUnspsc190501.39111810': __('identifierSchemeCodeUnspsc190501.39111810'),
	'identifierSchemeCodeUnspsc190501.39111811': __('identifierSchemeCodeUnspsc190501.39111811'),
	'identifierSchemeCodeUnspsc190501.39111812': __('identifierSchemeCodeUnspsc190501.39111812'),
	'identifierSchemeCodeUnspsc190501.39111813': __('identifierSchemeCodeUnspsc190501.39111813'),
	'identifierSchemeCodeUnspsc190501.39111814': __('identifierSchemeCodeUnspsc190501.39111814'),
	'identifierSchemeCodeUnspsc190501.39111815': __('identifierSchemeCodeUnspsc190501.39111815'),
	'identifierSchemeCodeUnspsc190501.39111816': __('identifierSchemeCodeUnspsc190501.39111816'),
	'identifierSchemeCodeUnspsc190501.39111817': __('identifierSchemeCodeUnspsc190501.39111817'),
	'identifierSchemeCodeUnspsc190501.39111818': __('identifierSchemeCodeUnspsc190501.39111818'),
	'identifierSchemeCodeUnspsc190501.39111819': __('identifierSchemeCodeUnspsc190501.39111819'),
	'identifierSchemeCodeUnspsc190501.39111820': __('identifierSchemeCodeUnspsc190501.39111820'),
	'identifierSchemeCodeUnspsc190501.39111821': __('identifierSchemeCodeUnspsc190501.39111821'),
	'identifierSchemeCodeUnspsc190501.39111822': __('identifierSchemeCodeUnspsc190501.39111822'),
	'identifierSchemeCodeUnspsc190501.39111823': __('identifierSchemeCodeUnspsc190501.39111823'),
	'identifierSchemeCodeUnspsc190501.39111824': __('identifierSchemeCodeUnspsc190501.39111824'),
	'identifierSchemeCodeUnspsc190501.39111825': __('identifierSchemeCodeUnspsc190501.39111825'),
	'identifierSchemeCodeUnspsc190501.39111826': __('identifierSchemeCodeUnspsc190501.39111826'),
	'identifierSchemeCodeUnspsc190501.39111827': __('identifierSchemeCodeUnspsc190501.39111827'),
	'identifierSchemeCodeUnspsc190501.39111828': __('identifierSchemeCodeUnspsc190501.39111828'),
	'identifierSchemeCodeUnspsc190501.39111829': __('identifierSchemeCodeUnspsc190501.39111829'),
	'identifierSchemeCodeUnspsc190501.39111830': __('identifierSchemeCodeUnspsc190501.39111830'),
	'identifierSchemeCodeUnspsc190501.39111831': __('identifierSchemeCodeUnspsc190501.39111831'),
	'identifierSchemeCodeUnspsc190501.39111900': __('identifierSchemeCodeUnspsc190501.39111900'),
	'identifierSchemeCodeUnspsc190501.39111903': __('identifierSchemeCodeUnspsc190501.39111903'),
	'identifierSchemeCodeUnspsc190501.39111904': __('identifierSchemeCodeUnspsc190501.39111904'),
	'identifierSchemeCodeUnspsc190501.39111905': __('identifierSchemeCodeUnspsc190501.39111905'),
	'identifierSchemeCodeUnspsc190501.39111906': __('identifierSchemeCodeUnspsc190501.39111906'),
	'identifierSchemeCodeUnspsc190501.39111907': __('identifierSchemeCodeUnspsc190501.39111907'),
	'identifierSchemeCodeUnspsc190501.39111908': __('identifierSchemeCodeUnspsc190501.39111908'),
	'identifierSchemeCodeUnspsc190501.39111909': __('identifierSchemeCodeUnspsc190501.39111909'),
	'identifierSchemeCodeUnspsc190501.39111910': __('identifierSchemeCodeUnspsc190501.39111910'),
	'identifierSchemeCodeUnspsc190501.39111911': __('identifierSchemeCodeUnspsc190501.39111911'),
	'identifierSchemeCodeUnspsc190501.39112000': __('identifierSchemeCodeUnspsc190501.39112000'),
	'identifierSchemeCodeUnspsc190501.39112001': __('identifierSchemeCodeUnspsc190501.39112001'),
	'identifierSchemeCodeUnspsc190501.39112002': __('identifierSchemeCodeUnspsc190501.39112002'),
	'identifierSchemeCodeUnspsc190501.39112003': __('identifierSchemeCodeUnspsc190501.39112003'),
	'identifierSchemeCodeUnspsc190501.39112004': __('identifierSchemeCodeUnspsc190501.39112004'),
	'identifierSchemeCodeUnspsc190501.39112005': __('identifierSchemeCodeUnspsc190501.39112005'),
	'identifierSchemeCodeUnspsc190501.39112006': __('identifierSchemeCodeUnspsc190501.39112006'),
	'identifierSchemeCodeUnspsc190501.39112007': __('identifierSchemeCodeUnspsc190501.39112007'),
	'identifierSchemeCodeUnspsc190501.39112008': __('identifierSchemeCodeUnspsc190501.39112008'),
	'identifierSchemeCodeUnspsc190501.39112009': __('identifierSchemeCodeUnspsc190501.39112009'),
	'identifierSchemeCodeUnspsc190501.39112010': __('identifierSchemeCodeUnspsc190501.39112010'),
	'identifierSchemeCodeUnspsc190501.39112011': __('identifierSchemeCodeUnspsc190501.39112011'),
	'identifierSchemeCodeUnspsc190501.39112012': __('identifierSchemeCodeUnspsc190501.39112012'),
	'identifierSchemeCodeUnspsc190501.39112013': __('identifierSchemeCodeUnspsc190501.39112013'),
	'identifierSchemeCodeUnspsc190501.39112100': __('identifierSchemeCodeUnspsc190501.39112100'),
	'identifierSchemeCodeUnspsc190501.39112101': __('identifierSchemeCodeUnspsc190501.39112101'),
	'identifierSchemeCodeUnspsc190501.39112102': __('identifierSchemeCodeUnspsc190501.39112102'),
	'identifierSchemeCodeUnspsc190501.39112103': __('identifierSchemeCodeUnspsc190501.39112103'),
	'identifierSchemeCodeUnspsc190501.39112200': __('identifierSchemeCodeUnspsc190501.39112200'),
	'identifierSchemeCodeUnspsc190501.39112201': __('identifierSchemeCodeUnspsc190501.39112201'),
	'identifierSchemeCodeUnspsc190501.39112202': __('identifierSchemeCodeUnspsc190501.39112202'),
	'identifierSchemeCodeUnspsc190501.39112203': __('identifierSchemeCodeUnspsc190501.39112203'),
	'identifierSchemeCodeUnspsc190501.39112204': __('identifierSchemeCodeUnspsc190501.39112204'),
	'identifierSchemeCodeUnspsc190501.39112205': __('identifierSchemeCodeUnspsc190501.39112205'),
	'identifierSchemeCodeUnspsc190501.39112206': __('identifierSchemeCodeUnspsc190501.39112206'),
	'identifierSchemeCodeUnspsc190501.39112300': __('identifierSchemeCodeUnspsc190501.39112300'),
	'identifierSchemeCodeUnspsc190501.39112301': __('identifierSchemeCodeUnspsc190501.39112301'),
	'identifierSchemeCodeUnspsc190501.39112302': __('identifierSchemeCodeUnspsc190501.39112302'),
	'identifierSchemeCodeUnspsc190501.39112303': __('identifierSchemeCodeUnspsc190501.39112303'),
	'identifierSchemeCodeUnspsc190501.39112304': __('identifierSchemeCodeUnspsc190501.39112304'),
	'identifierSchemeCodeUnspsc190501.39112305': __('identifierSchemeCodeUnspsc190501.39112305'),
	'identifierSchemeCodeUnspsc190501.39112306': __('identifierSchemeCodeUnspsc190501.39112306'),
	'identifierSchemeCodeUnspsc190501.39112307': __('identifierSchemeCodeUnspsc190501.39112307'),
	'identifierSchemeCodeUnspsc190501.39112308': __('identifierSchemeCodeUnspsc190501.39112308'),
	'identifierSchemeCodeUnspsc190501.39112309': __('identifierSchemeCodeUnspsc190501.39112309'),
	'identifierSchemeCodeUnspsc190501.39112400': __('identifierSchemeCodeUnspsc190501.39112400'),
	'identifierSchemeCodeUnspsc190501.39112401': __('identifierSchemeCodeUnspsc190501.39112401'),
	'identifierSchemeCodeUnspsc190501.39112402': __('identifierSchemeCodeUnspsc190501.39112402'),
	'identifierSchemeCodeUnspsc190501.39112403': __('identifierSchemeCodeUnspsc190501.39112403'),
	'identifierSchemeCodeUnspsc190501.39112500': __('identifierSchemeCodeUnspsc190501.39112500'),
	'identifierSchemeCodeUnspsc190501.39112501': __('identifierSchemeCodeUnspsc190501.39112501'),
	'identifierSchemeCodeUnspsc190501.39112502': __('identifierSchemeCodeUnspsc190501.39112502'),
	'identifierSchemeCodeUnspsc190501.39112503': __('identifierSchemeCodeUnspsc190501.39112503'),
	'identifierSchemeCodeUnspsc190501.39112504': __('identifierSchemeCodeUnspsc190501.39112504'),
	'identifierSchemeCodeUnspsc190501.39112505': __('identifierSchemeCodeUnspsc190501.39112505'),
	'identifierSchemeCodeUnspsc190501.39112506': __('identifierSchemeCodeUnspsc190501.39112506'),
	'identifierSchemeCodeUnspsc190501.39112507': __('identifierSchemeCodeUnspsc190501.39112507'),
	'identifierSchemeCodeUnspsc190501.39112508': __('identifierSchemeCodeUnspsc190501.39112508'),
	'identifierSchemeCodeUnspsc190501.39112600': __('identifierSchemeCodeUnspsc190501.39112600'),
	'identifierSchemeCodeUnspsc190501.39112601': __('identifierSchemeCodeUnspsc190501.39112601'),
	'identifierSchemeCodeUnspsc190501.39112602': __('identifierSchemeCodeUnspsc190501.39112602'),
	'identifierSchemeCodeUnspsc190501.39112603': __('identifierSchemeCodeUnspsc190501.39112603'),
	'identifierSchemeCodeUnspsc190501.39112604': __('identifierSchemeCodeUnspsc190501.39112604'),
	'identifierSchemeCodeUnspsc190501.39120000': __('identifierSchemeCodeUnspsc190501.39120000'),
	'identifierSchemeCodeUnspsc190501.39121000': __('identifierSchemeCodeUnspsc190501.39121000'),
	'identifierSchemeCodeUnspsc190501.39121001': __('identifierSchemeCodeUnspsc190501.39121001'),
	'identifierSchemeCodeUnspsc190501.39121002': __('identifierSchemeCodeUnspsc190501.39121002'),
	'identifierSchemeCodeUnspsc190501.39121003': __('identifierSchemeCodeUnspsc190501.39121003'),
	'identifierSchemeCodeUnspsc190501.39121004': __('identifierSchemeCodeUnspsc190501.39121004'),
	'identifierSchemeCodeUnspsc190501.39121006': __('identifierSchemeCodeUnspsc190501.39121006'),
	'identifierSchemeCodeUnspsc190501.39121007': __('identifierSchemeCodeUnspsc190501.39121007'),
	'identifierSchemeCodeUnspsc190501.39121008': __('identifierSchemeCodeUnspsc190501.39121008'),
	'identifierSchemeCodeUnspsc190501.39121009': __('identifierSchemeCodeUnspsc190501.39121009'),
	'identifierSchemeCodeUnspsc190501.39121010': __('identifierSchemeCodeUnspsc190501.39121010'),
	'identifierSchemeCodeUnspsc190501.39121011': __('identifierSchemeCodeUnspsc190501.39121011'),
	'identifierSchemeCodeUnspsc190501.39121012': __('identifierSchemeCodeUnspsc190501.39121012'),
	'identifierSchemeCodeUnspsc190501.39121013': __('identifierSchemeCodeUnspsc190501.39121013'),
	'identifierSchemeCodeUnspsc190501.39121014': __('identifierSchemeCodeUnspsc190501.39121014'),
	'identifierSchemeCodeUnspsc190501.39121015': __('identifierSchemeCodeUnspsc190501.39121015'),
	'identifierSchemeCodeUnspsc190501.39121016': __('identifierSchemeCodeUnspsc190501.39121016'),
	'identifierSchemeCodeUnspsc190501.39121017': __('identifierSchemeCodeUnspsc190501.39121017'),
	'identifierSchemeCodeUnspsc190501.39121018': __('identifierSchemeCodeUnspsc190501.39121018'),
	'identifierSchemeCodeUnspsc190501.39121019': __('identifierSchemeCodeUnspsc190501.39121019'),
	'identifierSchemeCodeUnspsc190501.39121020': __('identifierSchemeCodeUnspsc190501.39121020'),
	'identifierSchemeCodeUnspsc190501.39121021': __('identifierSchemeCodeUnspsc190501.39121021'),
	'identifierSchemeCodeUnspsc190501.39121022': __('identifierSchemeCodeUnspsc190501.39121022'),
	'identifierSchemeCodeUnspsc190501.39121023': __('identifierSchemeCodeUnspsc190501.39121023'),
	'identifierSchemeCodeUnspsc190501.39121024': __('identifierSchemeCodeUnspsc190501.39121024'),
	'identifierSchemeCodeUnspsc190501.39121025': __('identifierSchemeCodeUnspsc190501.39121025'),
	'identifierSchemeCodeUnspsc190501.39121026': __('identifierSchemeCodeUnspsc190501.39121026'),
	'identifierSchemeCodeUnspsc190501.39121027': __('identifierSchemeCodeUnspsc190501.39121027'),
	'identifierSchemeCodeUnspsc190501.39121028': __('identifierSchemeCodeUnspsc190501.39121028'),
	'identifierSchemeCodeUnspsc190501.39121029': __('identifierSchemeCodeUnspsc190501.39121029'),
	'identifierSchemeCodeUnspsc190501.39121030': __('identifierSchemeCodeUnspsc190501.39121030'),
	'identifierSchemeCodeUnspsc190501.39121031': __('identifierSchemeCodeUnspsc190501.39121031'),
	'identifierSchemeCodeUnspsc190501.39121032': __('identifierSchemeCodeUnspsc190501.39121032'),
	'identifierSchemeCodeUnspsc190501.39121033': __('identifierSchemeCodeUnspsc190501.39121033'),
	'identifierSchemeCodeUnspsc190501.39121034': __('identifierSchemeCodeUnspsc190501.39121034'),
	'identifierSchemeCodeUnspsc190501.39121035': __('identifierSchemeCodeUnspsc190501.39121035'),
	'identifierSchemeCodeUnspsc190501.39121036': __('identifierSchemeCodeUnspsc190501.39121036'),
	'identifierSchemeCodeUnspsc190501.39121037': __('identifierSchemeCodeUnspsc190501.39121037'),
	'identifierSchemeCodeUnspsc190501.39121038': __('identifierSchemeCodeUnspsc190501.39121038'),
	'identifierSchemeCodeUnspsc190501.39121039': __('identifierSchemeCodeUnspsc190501.39121039'),
	'identifierSchemeCodeUnspsc190501.39121040': __('identifierSchemeCodeUnspsc190501.39121040'),
	'identifierSchemeCodeUnspsc190501.39121041': __('identifierSchemeCodeUnspsc190501.39121041'),
	'identifierSchemeCodeUnspsc190501.39121042': __('identifierSchemeCodeUnspsc190501.39121042'),
	'identifierSchemeCodeUnspsc190501.39121043': __('identifierSchemeCodeUnspsc190501.39121043'),
	'identifierSchemeCodeUnspsc190501.39121044': __('identifierSchemeCodeUnspsc190501.39121044'),
	'identifierSchemeCodeUnspsc190501.39121045': __('identifierSchemeCodeUnspsc190501.39121045'),
	'identifierSchemeCodeUnspsc190501.39121046': __('identifierSchemeCodeUnspsc190501.39121046'),
	'identifierSchemeCodeUnspsc190501.39121047': __('identifierSchemeCodeUnspsc190501.39121047'),
	'identifierSchemeCodeUnspsc190501.39121100': __('identifierSchemeCodeUnspsc190501.39121100'),
	'identifierSchemeCodeUnspsc190501.39121101': __('identifierSchemeCodeUnspsc190501.39121101'),
	'identifierSchemeCodeUnspsc190501.39121102': __('identifierSchemeCodeUnspsc190501.39121102'),
	'identifierSchemeCodeUnspsc190501.39121103': __('identifierSchemeCodeUnspsc190501.39121103'),
	'identifierSchemeCodeUnspsc190501.39121104': __('identifierSchemeCodeUnspsc190501.39121104'),
	'identifierSchemeCodeUnspsc190501.39121105': __('identifierSchemeCodeUnspsc190501.39121105'),
	'identifierSchemeCodeUnspsc190501.39121106': __('identifierSchemeCodeUnspsc190501.39121106'),
	'identifierSchemeCodeUnspsc190501.39121107': __('identifierSchemeCodeUnspsc190501.39121107'),
	'identifierSchemeCodeUnspsc190501.39121108': __('identifierSchemeCodeUnspsc190501.39121108'),
	'identifierSchemeCodeUnspsc190501.39121109': __('identifierSchemeCodeUnspsc190501.39121109'),
	'identifierSchemeCodeUnspsc190501.39121110': __('identifierSchemeCodeUnspsc190501.39121110'),
	'identifierSchemeCodeUnspsc190501.39121111': __('identifierSchemeCodeUnspsc190501.39121111'),
	'identifierSchemeCodeUnspsc190501.39121112': __('identifierSchemeCodeUnspsc190501.39121112'),
	'identifierSchemeCodeUnspsc190501.39121113': __('identifierSchemeCodeUnspsc190501.39121113'),
	'identifierSchemeCodeUnspsc190501.39121114': __('identifierSchemeCodeUnspsc190501.39121114'),
	'identifierSchemeCodeUnspsc190501.39121115': __('identifierSchemeCodeUnspsc190501.39121115'),
	'identifierSchemeCodeUnspsc190501.39121116': __('identifierSchemeCodeUnspsc190501.39121116'),
	'identifierSchemeCodeUnspsc190501.39121117': __('identifierSchemeCodeUnspsc190501.39121117'),
	'identifierSchemeCodeUnspsc190501.39121300': __('identifierSchemeCodeUnspsc190501.39121300'),
	'identifierSchemeCodeUnspsc190501.39121301': __('identifierSchemeCodeUnspsc190501.39121301'),
	'identifierSchemeCodeUnspsc190501.39121302': __('identifierSchemeCodeUnspsc190501.39121302'),
	'identifierSchemeCodeUnspsc190501.39121303': __('identifierSchemeCodeUnspsc190501.39121303'),
	'identifierSchemeCodeUnspsc190501.39121304': __('identifierSchemeCodeUnspsc190501.39121304'),
	'identifierSchemeCodeUnspsc190501.39121305': __('identifierSchemeCodeUnspsc190501.39121305'),
	'identifierSchemeCodeUnspsc190501.39121306': __('identifierSchemeCodeUnspsc190501.39121306'),
	'identifierSchemeCodeUnspsc190501.39121307': __('identifierSchemeCodeUnspsc190501.39121307'),
	'identifierSchemeCodeUnspsc190501.39121308': __('identifierSchemeCodeUnspsc190501.39121308'),
	'identifierSchemeCodeUnspsc190501.39121309': __('identifierSchemeCodeUnspsc190501.39121309'),
	'identifierSchemeCodeUnspsc190501.39121310': __('identifierSchemeCodeUnspsc190501.39121310'),
	'identifierSchemeCodeUnspsc190501.39121311': __('identifierSchemeCodeUnspsc190501.39121311'),
	'identifierSchemeCodeUnspsc190501.39121312': __('identifierSchemeCodeUnspsc190501.39121312'),
	'identifierSchemeCodeUnspsc190501.39121313': __('identifierSchemeCodeUnspsc190501.39121313'),
	'identifierSchemeCodeUnspsc190501.39121314': __('identifierSchemeCodeUnspsc190501.39121314'),
	'identifierSchemeCodeUnspsc190501.39121315': __('identifierSchemeCodeUnspsc190501.39121315'),
	'identifierSchemeCodeUnspsc190501.39121316': __('identifierSchemeCodeUnspsc190501.39121316'),
	'identifierSchemeCodeUnspsc190501.39121317': __('identifierSchemeCodeUnspsc190501.39121317'),
	'identifierSchemeCodeUnspsc190501.39121318': __('identifierSchemeCodeUnspsc190501.39121318'),
	'identifierSchemeCodeUnspsc190501.39121319': __('identifierSchemeCodeUnspsc190501.39121319'),
	'identifierSchemeCodeUnspsc190501.39121320': __('identifierSchemeCodeUnspsc190501.39121320'),
	'identifierSchemeCodeUnspsc190501.39121321': __('identifierSchemeCodeUnspsc190501.39121321'),
	'identifierSchemeCodeUnspsc190501.39121322': __('identifierSchemeCodeUnspsc190501.39121322'),
	'identifierSchemeCodeUnspsc190501.39121323': __('identifierSchemeCodeUnspsc190501.39121323'),
	'identifierSchemeCodeUnspsc190501.39121324': __('identifierSchemeCodeUnspsc190501.39121324'),
	'identifierSchemeCodeUnspsc190501.39121325': __('identifierSchemeCodeUnspsc190501.39121325'),
	'identifierSchemeCodeUnspsc190501.39121326': __('identifierSchemeCodeUnspsc190501.39121326'),
	'identifierSchemeCodeUnspsc190501.39121327': __('identifierSchemeCodeUnspsc190501.39121327'),
	'identifierSchemeCodeUnspsc190501.39121328': __('identifierSchemeCodeUnspsc190501.39121328'),
	'identifierSchemeCodeUnspsc190501.39121329': __('identifierSchemeCodeUnspsc190501.39121329'),
	'identifierSchemeCodeUnspsc190501.39121330': __('identifierSchemeCodeUnspsc190501.39121330'),
	'identifierSchemeCodeUnspsc190501.39121331': __('identifierSchemeCodeUnspsc190501.39121331'),
	'identifierSchemeCodeUnspsc190501.39121332': __('identifierSchemeCodeUnspsc190501.39121332'),
	'identifierSchemeCodeUnspsc190501.39121333': __('identifierSchemeCodeUnspsc190501.39121333'),
	'identifierSchemeCodeUnspsc190501.39121334': __('identifierSchemeCodeUnspsc190501.39121334'),
	'identifierSchemeCodeUnspsc190501.39121335': __('identifierSchemeCodeUnspsc190501.39121335'),
	'identifierSchemeCodeUnspsc190501.39121336': __('identifierSchemeCodeUnspsc190501.39121336'),
	'identifierSchemeCodeUnspsc190501.39121337': __('identifierSchemeCodeUnspsc190501.39121337'),
	'identifierSchemeCodeUnspsc190501.39121400': __('identifierSchemeCodeUnspsc190501.39121400'),
	'identifierSchemeCodeUnspsc190501.39121402': __('identifierSchemeCodeUnspsc190501.39121402'),
	'identifierSchemeCodeUnspsc190501.39121403': __('identifierSchemeCodeUnspsc190501.39121403'),
	'identifierSchemeCodeUnspsc190501.39121404': __('identifierSchemeCodeUnspsc190501.39121404'),
	'identifierSchemeCodeUnspsc190501.39121405': __('identifierSchemeCodeUnspsc190501.39121405'),
	'identifierSchemeCodeUnspsc190501.39121406': __('identifierSchemeCodeUnspsc190501.39121406'),
	'identifierSchemeCodeUnspsc190501.39121407': __('identifierSchemeCodeUnspsc190501.39121407'),
	'identifierSchemeCodeUnspsc190501.39121408': __('identifierSchemeCodeUnspsc190501.39121408'),
	'identifierSchemeCodeUnspsc190501.39121409': __('identifierSchemeCodeUnspsc190501.39121409'),
	'identifierSchemeCodeUnspsc190501.39121410': __('identifierSchemeCodeUnspsc190501.39121410'),
	'identifierSchemeCodeUnspsc190501.39121412': __('identifierSchemeCodeUnspsc190501.39121412'),
	'identifierSchemeCodeUnspsc190501.39121413': __('identifierSchemeCodeUnspsc190501.39121413'),
	'identifierSchemeCodeUnspsc190501.39121414': __('identifierSchemeCodeUnspsc190501.39121414'),
	'identifierSchemeCodeUnspsc190501.39121415': __('identifierSchemeCodeUnspsc190501.39121415'),
	'identifierSchemeCodeUnspsc190501.39121416': __('identifierSchemeCodeUnspsc190501.39121416'),
	'identifierSchemeCodeUnspsc190501.39121419': __('identifierSchemeCodeUnspsc190501.39121419'),
	'identifierSchemeCodeUnspsc190501.39121420': __('identifierSchemeCodeUnspsc190501.39121420'),
	'identifierSchemeCodeUnspsc190501.39121421': __('identifierSchemeCodeUnspsc190501.39121421'),
	'identifierSchemeCodeUnspsc190501.39121423': __('identifierSchemeCodeUnspsc190501.39121423'),
	'identifierSchemeCodeUnspsc190501.39121424': __('identifierSchemeCodeUnspsc190501.39121424'),
	'identifierSchemeCodeUnspsc190501.39121425': __('identifierSchemeCodeUnspsc190501.39121425'),
	'identifierSchemeCodeUnspsc190501.39121426': __('identifierSchemeCodeUnspsc190501.39121426'),
	'identifierSchemeCodeUnspsc190501.39121427': __('identifierSchemeCodeUnspsc190501.39121427'),
	'identifierSchemeCodeUnspsc190501.39121428': __('identifierSchemeCodeUnspsc190501.39121428'),
	'identifierSchemeCodeUnspsc190501.39121431': __('identifierSchemeCodeUnspsc190501.39121431'),
	'identifierSchemeCodeUnspsc190501.39121432': __('identifierSchemeCodeUnspsc190501.39121432'),
	'identifierSchemeCodeUnspsc190501.39121433': __('identifierSchemeCodeUnspsc190501.39121433'),
	'identifierSchemeCodeUnspsc190501.39121434': __('identifierSchemeCodeUnspsc190501.39121434'),
	'identifierSchemeCodeUnspsc190501.39121435': __('identifierSchemeCodeUnspsc190501.39121435'),
	'identifierSchemeCodeUnspsc190501.39121436': __('identifierSchemeCodeUnspsc190501.39121436'),
	'identifierSchemeCodeUnspsc190501.39121437': __('identifierSchemeCodeUnspsc190501.39121437'),
	'identifierSchemeCodeUnspsc190501.39121438': __('identifierSchemeCodeUnspsc190501.39121438'),
	'identifierSchemeCodeUnspsc190501.39121440': __('identifierSchemeCodeUnspsc190501.39121440'),
	'identifierSchemeCodeUnspsc190501.39121441': __('identifierSchemeCodeUnspsc190501.39121441'),
	'identifierSchemeCodeUnspsc190501.39121442': __('identifierSchemeCodeUnspsc190501.39121442'),
	'identifierSchemeCodeUnspsc190501.39121443': __('identifierSchemeCodeUnspsc190501.39121443'),
	'identifierSchemeCodeUnspsc190501.39121444': __('identifierSchemeCodeUnspsc190501.39121444'),
	'identifierSchemeCodeUnspsc190501.39121445': __('identifierSchemeCodeUnspsc190501.39121445'),
	'identifierSchemeCodeUnspsc190501.39121446': __('identifierSchemeCodeUnspsc190501.39121446'),
	'identifierSchemeCodeUnspsc190501.39121447': __('identifierSchemeCodeUnspsc190501.39121447'),
	'identifierSchemeCodeUnspsc190501.39121448': __('identifierSchemeCodeUnspsc190501.39121448'),
	'identifierSchemeCodeUnspsc190501.39121449': __('identifierSchemeCodeUnspsc190501.39121449'),
	'identifierSchemeCodeUnspsc190501.39121450': __('identifierSchemeCodeUnspsc190501.39121450'),
	'identifierSchemeCodeUnspsc190501.39121451': __('identifierSchemeCodeUnspsc190501.39121451'),
	'identifierSchemeCodeUnspsc190501.39121452': __('identifierSchemeCodeUnspsc190501.39121452'),
	'identifierSchemeCodeUnspsc190501.39121453': __('identifierSchemeCodeUnspsc190501.39121453'),
	'identifierSchemeCodeUnspsc190501.39121454': __('identifierSchemeCodeUnspsc190501.39121454'),
	'identifierSchemeCodeUnspsc190501.39121455': __('identifierSchemeCodeUnspsc190501.39121455'),
	'identifierSchemeCodeUnspsc190501.39121456': __('identifierSchemeCodeUnspsc190501.39121456'),
	'identifierSchemeCodeUnspsc190501.39121457': __('identifierSchemeCodeUnspsc190501.39121457'),
	'identifierSchemeCodeUnspsc190501.39121458': __('identifierSchemeCodeUnspsc190501.39121458'),
	'identifierSchemeCodeUnspsc190501.39121459': __('identifierSchemeCodeUnspsc190501.39121459'),
	'identifierSchemeCodeUnspsc190501.39121460': __('identifierSchemeCodeUnspsc190501.39121460'),
	'identifierSchemeCodeUnspsc190501.39121461': __('identifierSchemeCodeUnspsc190501.39121461'),
	'identifierSchemeCodeUnspsc190501.39121462': __('identifierSchemeCodeUnspsc190501.39121462'),
	'identifierSchemeCodeUnspsc190501.39121463': __('identifierSchemeCodeUnspsc190501.39121463'),
	'identifierSchemeCodeUnspsc190501.39121464': __('identifierSchemeCodeUnspsc190501.39121464'),
	'identifierSchemeCodeUnspsc190501.39121465': __('identifierSchemeCodeUnspsc190501.39121465'),
	'identifierSchemeCodeUnspsc190501.39121466': __('identifierSchemeCodeUnspsc190501.39121466'),
	'identifierSchemeCodeUnspsc190501.39121467': __('identifierSchemeCodeUnspsc190501.39121467'),
	'identifierSchemeCodeUnspsc190501.39121468': __('identifierSchemeCodeUnspsc190501.39121468'),
	'identifierSchemeCodeUnspsc190501.39121469': __('identifierSchemeCodeUnspsc190501.39121469'),
	'identifierSchemeCodeUnspsc190501.39121500': __('identifierSchemeCodeUnspsc190501.39121500'),
	'identifierSchemeCodeUnspsc190501.39121521': __('identifierSchemeCodeUnspsc190501.39121521'),
	'identifierSchemeCodeUnspsc190501.39121522': __('identifierSchemeCodeUnspsc190501.39121522'),
	'identifierSchemeCodeUnspsc190501.39121523': __('identifierSchemeCodeUnspsc190501.39121523'),
	'identifierSchemeCodeUnspsc190501.39121524': __('identifierSchemeCodeUnspsc190501.39121524'),
	'identifierSchemeCodeUnspsc190501.39121527': __('identifierSchemeCodeUnspsc190501.39121527'),
	'identifierSchemeCodeUnspsc190501.39121528': __('identifierSchemeCodeUnspsc190501.39121528'),
	'identifierSchemeCodeUnspsc190501.39121529': __('identifierSchemeCodeUnspsc190501.39121529'),
	'identifierSchemeCodeUnspsc190501.39121534': __('identifierSchemeCodeUnspsc190501.39121534'),
	'identifierSchemeCodeUnspsc190501.39121544': __('identifierSchemeCodeUnspsc190501.39121544'),
	'identifierSchemeCodeUnspsc190501.39121545': __('identifierSchemeCodeUnspsc190501.39121545'),
	'identifierSchemeCodeUnspsc190501.39121551': __('identifierSchemeCodeUnspsc190501.39121551'),
	'identifierSchemeCodeUnspsc190501.39121552': __('identifierSchemeCodeUnspsc190501.39121552'),
	'identifierSchemeCodeUnspsc190501.39121555': __('identifierSchemeCodeUnspsc190501.39121555'),
	'identifierSchemeCodeUnspsc190501.39121561': __('identifierSchemeCodeUnspsc190501.39121561'),
	'identifierSchemeCodeUnspsc190501.39121565': __('identifierSchemeCodeUnspsc190501.39121565'),
	'identifierSchemeCodeUnspsc190501.39121568': __('identifierSchemeCodeUnspsc190501.39121568'),
	'identifierSchemeCodeUnspsc190501.39121569': __('identifierSchemeCodeUnspsc190501.39121569'),
	'identifierSchemeCodeUnspsc190501.39121570': __('identifierSchemeCodeUnspsc190501.39121570'),
	'identifierSchemeCodeUnspsc190501.39121600': __('identifierSchemeCodeUnspsc190501.39121600'),
	'identifierSchemeCodeUnspsc190501.39121601': __('identifierSchemeCodeUnspsc190501.39121601'),
	'identifierSchemeCodeUnspsc190501.39121602': __('identifierSchemeCodeUnspsc190501.39121602'),
	'identifierSchemeCodeUnspsc190501.39121603': __('identifierSchemeCodeUnspsc190501.39121603'),
	'identifierSchemeCodeUnspsc190501.39121604': __('identifierSchemeCodeUnspsc190501.39121604'),
	'identifierSchemeCodeUnspsc190501.39121605': __('identifierSchemeCodeUnspsc190501.39121605'),
	'identifierSchemeCodeUnspsc190501.39121606': __('identifierSchemeCodeUnspsc190501.39121606'),
	'identifierSchemeCodeUnspsc190501.39121607': __('identifierSchemeCodeUnspsc190501.39121607'),
	'identifierSchemeCodeUnspsc190501.39121609': __('identifierSchemeCodeUnspsc190501.39121609'),
	'identifierSchemeCodeUnspsc190501.39121610': __('identifierSchemeCodeUnspsc190501.39121610'),
	'identifierSchemeCodeUnspsc190501.39121611': __('identifierSchemeCodeUnspsc190501.39121611'),
	'identifierSchemeCodeUnspsc190501.39121612': __('identifierSchemeCodeUnspsc190501.39121612'),
	'identifierSchemeCodeUnspsc190501.39121613': __('identifierSchemeCodeUnspsc190501.39121613'),
	'identifierSchemeCodeUnspsc190501.39121614': __('identifierSchemeCodeUnspsc190501.39121614'),
	'identifierSchemeCodeUnspsc190501.39121615': __('identifierSchemeCodeUnspsc190501.39121615'),
	'identifierSchemeCodeUnspsc190501.39121616': __('identifierSchemeCodeUnspsc190501.39121616'),
	'identifierSchemeCodeUnspsc190501.39121617': __('identifierSchemeCodeUnspsc190501.39121617'),
	'identifierSchemeCodeUnspsc190501.39121618': __('identifierSchemeCodeUnspsc190501.39121618'),
	'identifierSchemeCodeUnspsc190501.39121619': __('identifierSchemeCodeUnspsc190501.39121619'),
	'identifierSchemeCodeUnspsc190501.39121620': __('identifierSchemeCodeUnspsc190501.39121620'),
	'identifierSchemeCodeUnspsc190501.39121621': __('identifierSchemeCodeUnspsc190501.39121621'),
	'identifierSchemeCodeUnspsc190501.39121622': __('identifierSchemeCodeUnspsc190501.39121622'),
	'identifierSchemeCodeUnspsc190501.39121623': __('identifierSchemeCodeUnspsc190501.39121623'),
	'identifierSchemeCodeUnspsc190501.39121624': __('identifierSchemeCodeUnspsc190501.39121624'),
	'identifierSchemeCodeUnspsc190501.39121625': __('identifierSchemeCodeUnspsc190501.39121625'),
	'identifierSchemeCodeUnspsc190501.39121626': __('identifierSchemeCodeUnspsc190501.39121626'),
	'identifierSchemeCodeUnspsc190501.39121627': __('identifierSchemeCodeUnspsc190501.39121627'),
	'identifierSchemeCodeUnspsc190501.39121628': __('identifierSchemeCodeUnspsc190501.39121628'),
	'identifierSchemeCodeUnspsc190501.39121629': __('identifierSchemeCodeUnspsc190501.39121629'),
	'identifierSchemeCodeUnspsc190501.39121630': __('identifierSchemeCodeUnspsc190501.39121630'),
	'identifierSchemeCodeUnspsc190501.39121631': __('identifierSchemeCodeUnspsc190501.39121631'),
	'identifierSchemeCodeUnspsc190501.39121632': __('identifierSchemeCodeUnspsc190501.39121632'),
	'identifierSchemeCodeUnspsc190501.39121633': __('identifierSchemeCodeUnspsc190501.39121633'),
	'identifierSchemeCodeUnspsc190501.39121634': __('identifierSchemeCodeUnspsc190501.39121634'),
	'identifierSchemeCodeUnspsc190501.39121635': __('identifierSchemeCodeUnspsc190501.39121635'),
	'identifierSchemeCodeUnspsc190501.39121636': __('identifierSchemeCodeUnspsc190501.39121636'),
	'identifierSchemeCodeUnspsc190501.39121637': __('identifierSchemeCodeUnspsc190501.39121637'),
	'identifierSchemeCodeUnspsc190501.39121638': __('identifierSchemeCodeUnspsc190501.39121638'),
	'identifierSchemeCodeUnspsc190501.39121639': __('identifierSchemeCodeUnspsc190501.39121639'),
	'identifierSchemeCodeUnspsc190501.39121640': __('identifierSchemeCodeUnspsc190501.39121640'),
	'identifierSchemeCodeUnspsc190501.39121641': __('identifierSchemeCodeUnspsc190501.39121641'),
	'identifierSchemeCodeUnspsc190501.39121642': __('identifierSchemeCodeUnspsc190501.39121642'),
	'identifierSchemeCodeUnspsc190501.39121643': __('identifierSchemeCodeUnspsc190501.39121643'),
	'identifierSchemeCodeUnspsc190501.39121644': __('identifierSchemeCodeUnspsc190501.39121644'),
	'identifierSchemeCodeUnspsc190501.39121645': __('identifierSchemeCodeUnspsc190501.39121645'),
	'identifierSchemeCodeUnspsc190501.39121646': __('identifierSchemeCodeUnspsc190501.39121646'),
	'identifierSchemeCodeUnspsc190501.39121647': __('identifierSchemeCodeUnspsc190501.39121647'),
	'identifierSchemeCodeUnspsc190501.39121648': __('identifierSchemeCodeUnspsc190501.39121648'),
	'identifierSchemeCodeUnspsc190501.39121700': __('identifierSchemeCodeUnspsc190501.39121700'),
	'identifierSchemeCodeUnspsc190501.39121701': __('identifierSchemeCodeUnspsc190501.39121701'),
	'identifierSchemeCodeUnspsc190501.39121702': __('identifierSchemeCodeUnspsc190501.39121702'),
	'identifierSchemeCodeUnspsc190501.39121703': __('identifierSchemeCodeUnspsc190501.39121703'),
	'identifierSchemeCodeUnspsc190501.39121704': __('identifierSchemeCodeUnspsc190501.39121704'),
	'identifierSchemeCodeUnspsc190501.39121705': __('identifierSchemeCodeUnspsc190501.39121705'),
	'identifierSchemeCodeUnspsc190501.39121706': __('identifierSchemeCodeUnspsc190501.39121706'),
	'identifierSchemeCodeUnspsc190501.39121707': __('identifierSchemeCodeUnspsc190501.39121707'),
	'identifierSchemeCodeUnspsc190501.39121708': __('identifierSchemeCodeUnspsc190501.39121708'),
	'identifierSchemeCodeUnspsc190501.39121709': __('identifierSchemeCodeUnspsc190501.39121709'),
	'identifierSchemeCodeUnspsc190501.39121710': __('identifierSchemeCodeUnspsc190501.39121710'),
	'identifierSchemeCodeUnspsc190501.39121717': __('identifierSchemeCodeUnspsc190501.39121717'),
	'identifierSchemeCodeUnspsc190501.39121718': __('identifierSchemeCodeUnspsc190501.39121718'),
	'identifierSchemeCodeUnspsc190501.39121719': __('identifierSchemeCodeUnspsc190501.39121719'),
	'identifierSchemeCodeUnspsc190501.39121720': __('identifierSchemeCodeUnspsc190501.39121720'),
	'identifierSchemeCodeUnspsc190501.39121721': __('identifierSchemeCodeUnspsc190501.39121721'),
	'identifierSchemeCodeUnspsc190501.39121722': __('identifierSchemeCodeUnspsc190501.39121722'),
	'identifierSchemeCodeUnspsc190501.39121723': __('identifierSchemeCodeUnspsc190501.39121723'),
	'identifierSchemeCodeUnspsc190501.39121724': __('identifierSchemeCodeUnspsc190501.39121724'),
	'identifierSchemeCodeUnspsc190501.39121725': __('identifierSchemeCodeUnspsc190501.39121725'),
	'identifierSchemeCodeUnspsc190501.39121726': __('identifierSchemeCodeUnspsc190501.39121726'),
	'identifierSchemeCodeUnspsc190501.39121727': __('identifierSchemeCodeUnspsc190501.39121727'),
	'identifierSchemeCodeUnspsc190501.39121728': __('identifierSchemeCodeUnspsc190501.39121728'),
	'identifierSchemeCodeUnspsc190501.39121729': __('identifierSchemeCodeUnspsc190501.39121729'),
	'identifierSchemeCodeUnspsc190501.39121730': __('identifierSchemeCodeUnspsc190501.39121730'),
	'identifierSchemeCodeUnspsc190501.39121731': __('identifierSchemeCodeUnspsc190501.39121731'),
	'identifierSchemeCodeUnspsc190501.39121800': __('identifierSchemeCodeUnspsc190501.39121800'),
	'identifierSchemeCodeUnspsc190501.39121801': __('identifierSchemeCodeUnspsc190501.39121801'),
	'identifierSchemeCodeUnspsc190501.39121802': __('identifierSchemeCodeUnspsc190501.39121802'),
	'identifierSchemeCodeUnspsc190501.39121803': __('identifierSchemeCodeUnspsc190501.39121803'),
	'identifierSchemeCodeUnspsc190501.39121900': __('identifierSchemeCodeUnspsc190501.39121900'),
	'identifierSchemeCodeUnspsc190501.39121901': __('identifierSchemeCodeUnspsc190501.39121901'),
	'identifierSchemeCodeUnspsc190501.39121902': __('identifierSchemeCodeUnspsc190501.39121902'),
	'identifierSchemeCodeUnspsc190501.39121903': __('identifierSchemeCodeUnspsc190501.39121903'),
	'identifierSchemeCodeUnspsc190501.39121904': __('identifierSchemeCodeUnspsc190501.39121904'),
	'identifierSchemeCodeUnspsc190501.39121905': __('identifierSchemeCodeUnspsc190501.39121905'),
	'identifierSchemeCodeUnspsc190501.39121906': __('identifierSchemeCodeUnspsc190501.39121906'),
	'identifierSchemeCodeUnspsc190501.39121907': __('identifierSchemeCodeUnspsc190501.39121907'),
	'identifierSchemeCodeUnspsc190501.39121908': __('identifierSchemeCodeUnspsc190501.39121908'),
	'identifierSchemeCodeUnspsc190501.39121909': __('identifierSchemeCodeUnspsc190501.39121909'),
	'identifierSchemeCodeUnspsc190501.39121910': __('identifierSchemeCodeUnspsc190501.39121910'),
	'identifierSchemeCodeUnspsc190501.39121911': __('identifierSchemeCodeUnspsc190501.39121911'),
	'identifierSchemeCodeUnspsc190501.39121912': __('identifierSchemeCodeUnspsc190501.39121912'),
	'identifierSchemeCodeUnspsc190501.39122000': __('identifierSchemeCodeUnspsc190501.39122000'),
	'identifierSchemeCodeUnspsc190501.39122001': __('identifierSchemeCodeUnspsc190501.39122001'),
	'identifierSchemeCodeUnspsc190501.39122002': __('identifierSchemeCodeUnspsc190501.39122002'),
	'identifierSchemeCodeUnspsc190501.39122003': __('identifierSchemeCodeUnspsc190501.39122003'),
	'identifierSchemeCodeUnspsc190501.39122004': __('identifierSchemeCodeUnspsc190501.39122004'),
	'identifierSchemeCodeUnspsc190501.39122005': __('identifierSchemeCodeUnspsc190501.39122005'),
	'identifierSchemeCodeUnspsc190501.39122100': __('identifierSchemeCodeUnspsc190501.39122100'),
	'identifierSchemeCodeUnspsc190501.39122101': __('identifierSchemeCodeUnspsc190501.39122101'),
	'identifierSchemeCodeUnspsc190501.39122102': __('identifierSchemeCodeUnspsc190501.39122102'),
	'identifierSchemeCodeUnspsc190501.39122103': __('identifierSchemeCodeUnspsc190501.39122103'),
	'identifierSchemeCodeUnspsc190501.39122104': __('identifierSchemeCodeUnspsc190501.39122104'),
	'identifierSchemeCodeUnspsc190501.39122105': __('identifierSchemeCodeUnspsc190501.39122105'),
	'identifierSchemeCodeUnspsc190501.39122106': __('identifierSchemeCodeUnspsc190501.39122106'),
	'identifierSchemeCodeUnspsc190501.39122107': __('identifierSchemeCodeUnspsc190501.39122107'),
	'identifierSchemeCodeUnspsc190501.39122108': __('identifierSchemeCodeUnspsc190501.39122108'),
	'identifierSchemeCodeUnspsc190501.39122109': __('identifierSchemeCodeUnspsc190501.39122109'),
	'identifierSchemeCodeUnspsc190501.39122110': __('identifierSchemeCodeUnspsc190501.39122110'),
	'identifierSchemeCodeUnspsc190501.39122111': __('identifierSchemeCodeUnspsc190501.39122111'),
	'identifierSchemeCodeUnspsc190501.39122112': __('identifierSchemeCodeUnspsc190501.39122112'),
	'identifierSchemeCodeUnspsc190501.39122113': __('identifierSchemeCodeUnspsc190501.39122113'),
	'identifierSchemeCodeUnspsc190501.39122114': __('identifierSchemeCodeUnspsc190501.39122114'),
	'identifierSchemeCodeUnspsc190501.39122115': __('identifierSchemeCodeUnspsc190501.39122115'),
	'identifierSchemeCodeUnspsc190501.39122116': __('identifierSchemeCodeUnspsc190501.39122116'),
	'identifierSchemeCodeUnspsc190501.39122117': __('identifierSchemeCodeUnspsc190501.39122117'),
	'identifierSchemeCodeUnspsc190501.39122118': __('identifierSchemeCodeUnspsc190501.39122118'),
	'identifierSchemeCodeUnspsc190501.39122119': __('identifierSchemeCodeUnspsc190501.39122119'),
	'identifierSchemeCodeUnspsc190501.39122120': __('identifierSchemeCodeUnspsc190501.39122120'),
	'identifierSchemeCodeUnspsc190501.39122200': __('identifierSchemeCodeUnspsc190501.39122200'),
	'identifierSchemeCodeUnspsc190501.39122201': __('identifierSchemeCodeUnspsc190501.39122201'),
	'identifierSchemeCodeUnspsc190501.39122202': __('identifierSchemeCodeUnspsc190501.39122202'),
	'identifierSchemeCodeUnspsc190501.39122203': __('identifierSchemeCodeUnspsc190501.39122203'),
	'identifierSchemeCodeUnspsc190501.39122204': __('identifierSchemeCodeUnspsc190501.39122204'),
	'identifierSchemeCodeUnspsc190501.39122205': __('identifierSchemeCodeUnspsc190501.39122205'),
	'identifierSchemeCodeUnspsc190501.39122206': __('identifierSchemeCodeUnspsc190501.39122206'),
	'identifierSchemeCodeUnspsc190501.39122207': __('identifierSchemeCodeUnspsc190501.39122207'),
	'identifierSchemeCodeUnspsc190501.39122208': __('identifierSchemeCodeUnspsc190501.39122208'),
	'identifierSchemeCodeUnspsc190501.39122209': __('identifierSchemeCodeUnspsc190501.39122209'),
	'identifierSchemeCodeUnspsc190501.39122210': __('identifierSchemeCodeUnspsc190501.39122210'),
	'identifierSchemeCodeUnspsc190501.39122211': __('identifierSchemeCodeUnspsc190501.39122211'),
	'identifierSchemeCodeUnspsc190501.39122212': __('identifierSchemeCodeUnspsc190501.39122212'),
	'identifierSchemeCodeUnspsc190501.39122213': __('identifierSchemeCodeUnspsc190501.39122213'),
	'identifierSchemeCodeUnspsc190501.39122214': __('identifierSchemeCodeUnspsc190501.39122214'),
	'identifierSchemeCodeUnspsc190501.39122215': __('identifierSchemeCodeUnspsc190501.39122215'),
	'identifierSchemeCodeUnspsc190501.39122216': __('identifierSchemeCodeUnspsc190501.39122216'),
	'identifierSchemeCodeUnspsc190501.39122217': __('identifierSchemeCodeUnspsc190501.39122217'),
	'identifierSchemeCodeUnspsc190501.39122218': __('identifierSchemeCodeUnspsc190501.39122218'),
	'identifierSchemeCodeUnspsc190501.39122219': __('identifierSchemeCodeUnspsc190501.39122219'),
	'identifierSchemeCodeUnspsc190501.39122220': __('identifierSchemeCodeUnspsc190501.39122220'),
	'identifierSchemeCodeUnspsc190501.39122221': __('identifierSchemeCodeUnspsc190501.39122221'),
	'identifierSchemeCodeUnspsc190501.39122222': __('identifierSchemeCodeUnspsc190501.39122222'),
	'identifierSchemeCodeUnspsc190501.39122223': __('identifierSchemeCodeUnspsc190501.39122223'),
	'identifierSchemeCodeUnspsc190501.39122224': __('identifierSchemeCodeUnspsc190501.39122224'),
	'identifierSchemeCodeUnspsc190501.39122225': __('identifierSchemeCodeUnspsc190501.39122225'),
	'identifierSchemeCodeUnspsc190501.39122226': __('identifierSchemeCodeUnspsc190501.39122226'),
	'identifierSchemeCodeUnspsc190501.39122227': __('identifierSchemeCodeUnspsc190501.39122227'),
	'identifierSchemeCodeUnspsc190501.39122228': __('identifierSchemeCodeUnspsc190501.39122228'),
	'identifierSchemeCodeUnspsc190501.39122229': __('identifierSchemeCodeUnspsc190501.39122229'),
	'identifierSchemeCodeUnspsc190501.39122230': __('identifierSchemeCodeUnspsc190501.39122230'),
	'identifierSchemeCodeUnspsc190501.39122231': __('identifierSchemeCodeUnspsc190501.39122231'),
	'identifierSchemeCodeUnspsc190501.39122232': __('identifierSchemeCodeUnspsc190501.39122232'),
	'identifierSchemeCodeUnspsc190501.39122233': __('identifierSchemeCodeUnspsc190501.39122233'),
	'identifierSchemeCodeUnspsc190501.39122234': __('identifierSchemeCodeUnspsc190501.39122234'),
	'identifierSchemeCodeUnspsc190501.39122235': __('identifierSchemeCodeUnspsc190501.39122235'),
	'identifierSchemeCodeUnspsc190501.39122236': __('identifierSchemeCodeUnspsc190501.39122236'),
	'identifierSchemeCodeUnspsc190501.39122237': __('identifierSchemeCodeUnspsc190501.39122237'),
	'identifierSchemeCodeUnspsc190501.39122238': __('identifierSchemeCodeUnspsc190501.39122238'),
	'identifierSchemeCodeUnspsc190501.39122239': __('identifierSchemeCodeUnspsc190501.39122239'),
	'identifierSchemeCodeUnspsc190501.39122240': __('identifierSchemeCodeUnspsc190501.39122240'),
	'identifierSchemeCodeUnspsc190501.39122241': __('identifierSchemeCodeUnspsc190501.39122241'),
	'identifierSchemeCodeUnspsc190501.39122242': __('identifierSchemeCodeUnspsc190501.39122242'),
	'identifierSchemeCodeUnspsc190501.39122243': __('identifierSchemeCodeUnspsc190501.39122243'),
	'identifierSchemeCodeUnspsc190501.39122244': __('identifierSchemeCodeUnspsc190501.39122244'),
	'identifierSchemeCodeUnspsc190501.39122245': __('identifierSchemeCodeUnspsc190501.39122245'),
	'identifierSchemeCodeUnspsc190501.39122246': __('identifierSchemeCodeUnspsc190501.39122246'),
	'identifierSchemeCodeUnspsc190501.39122247': __('identifierSchemeCodeUnspsc190501.39122247'),
	'identifierSchemeCodeUnspsc190501.39122248': __('identifierSchemeCodeUnspsc190501.39122248'),
	'identifierSchemeCodeUnspsc190501.39122249': __('identifierSchemeCodeUnspsc190501.39122249'),
	'identifierSchemeCodeUnspsc190501.39122250': __('identifierSchemeCodeUnspsc190501.39122250'),
	'identifierSchemeCodeUnspsc190501.39122251': __('identifierSchemeCodeUnspsc190501.39122251'),
	'identifierSchemeCodeUnspsc190501.39122300': __('identifierSchemeCodeUnspsc190501.39122300'),
	'identifierSchemeCodeUnspsc190501.39122301': __('identifierSchemeCodeUnspsc190501.39122301'),
	'identifierSchemeCodeUnspsc190501.39122302': __('identifierSchemeCodeUnspsc190501.39122302'),
	'identifierSchemeCodeUnspsc190501.39122303': __('identifierSchemeCodeUnspsc190501.39122303'),
	'identifierSchemeCodeUnspsc190501.39122304': __('identifierSchemeCodeUnspsc190501.39122304'),
	'identifierSchemeCodeUnspsc190501.39122305': __('identifierSchemeCodeUnspsc190501.39122305'),
	'identifierSchemeCodeUnspsc190501.39122306': __('identifierSchemeCodeUnspsc190501.39122306'),
	'identifierSchemeCodeUnspsc190501.39122307': __('identifierSchemeCodeUnspsc190501.39122307'),
	'identifierSchemeCodeUnspsc190501.39122308': __('identifierSchemeCodeUnspsc190501.39122308'),
	'identifierSchemeCodeUnspsc190501.39122309': __('identifierSchemeCodeUnspsc190501.39122309'),
	'identifierSchemeCodeUnspsc190501.39122310': __('identifierSchemeCodeUnspsc190501.39122310'),
	'identifierSchemeCodeUnspsc190501.39122311': __('identifierSchemeCodeUnspsc190501.39122311'),
	'identifierSchemeCodeUnspsc190501.39122312': __('identifierSchemeCodeUnspsc190501.39122312'),
	'identifierSchemeCodeUnspsc190501.39122313': __('identifierSchemeCodeUnspsc190501.39122313'),
	'identifierSchemeCodeUnspsc190501.39122314': __('identifierSchemeCodeUnspsc190501.39122314'),
	'identifierSchemeCodeUnspsc190501.39122315': __('identifierSchemeCodeUnspsc190501.39122315'),
	'identifierSchemeCodeUnspsc190501.39122316': __('identifierSchemeCodeUnspsc190501.39122316'),
	'identifierSchemeCodeUnspsc190501.39122317': __('identifierSchemeCodeUnspsc190501.39122317'),
	'identifierSchemeCodeUnspsc190501.39122318': __('identifierSchemeCodeUnspsc190501.39122318'),
	'identifierSchemeCodeUnspsc190501.39122319': __('identifierSchemeCodeUnspsc190501.39122319'),
	'identifierSchemeCodeUnspsc190501.39122320': __('identifierSchemeCodeUnspsc190501.39122320'),
	'identifierSchemeCodeUnspsc190501.39122321': __('identifierSchemeCodeUnspsc190501.39122321'),
	'identifierSchemeCodeUnspsc190501.39122322': __('identifierSchemeCodeUnspsc190501.39122322'),
	'identifierSchemeCodeUnspsc190501.39122323': __('identifierSchemeCodeUnspsc190501.39122323'),
	'identifierSchemeCodeUnspsc190501.39122324': __('identifierSchemeCodeUnspsc190501.39122324'),
	'identifierSchemeCodeUnspsc190501.39122325': __('identifierSchemeCodeUnspsc190501.39122325'),
	'identifierSchemeCodeUnspsc190501.39122326': __('identifierSchemeCodeUnspsc190501.39122326'),
	'identifierSchemeCodeUnspsc190501.39122327': __('identifierSchemeCodeUnspsc190501.39122327'),
	'identifierSchemeCodeUnspsc190501.39122328': __('identifierSchemeCodeUnspsc190501.39122328'),
	'identifierSchemeCodeUnspsc190501.39122329': __('identifierSchemeCodeUnspsc190501.39122329'),
	'identifierSchemeCodeUnspsc190501.39122330': __('identifierSchemeCodeUnspsc190501.39122330'),
	'identifierSchemeCodeUnspsc190501.39122331': __('identifierSchemeCodeUnspsc190501.39122331'),
	'identifierSchemeCodeUnspsc190501.39122332': __('identifierSchemeCodeUnspsc190501.39122332'),
	'identifierSchemeCodeUnspsc190501.39122333': __('identifierSchemeCodeUnspsc190501.39122333'),
	'identifierSchemeCodeUnspsc190501.39122334': __('identifierSchemeCodeUnspsc190501.39122334'),
	'identifierSchemeCodeUnspsc190501.39122335': __('identifierSchemeCodeUnspsc190501.39122335'),
	'identifierSchemeCodeUnspsc190501.39122336': __('identifierSchemeCodeUnspsc190501.39122336'),
	'identifierSchemeCodeUnspsc190501.39122337': __('identifierSchemeCodeUnspsc190501.39122337'),
	'identifierSchemeCodeUnspsc190501.39130000': __('identifierSchemeCodeUnspsc190501.39130000'),
	'identifierSchemeCodeUnspsc190501.39131500': __('identifierSchemeCodeUnspsc190501.39131500'),
	'identifierSchemeCodeUnspsc190501.39131501': __('identifierSchemeCodeUnspsc190501.39131501'),
	'identifierSchemeCodeUnspsc190501.39131502': __('identifierSchemeCodeUnspsc190501.39131502'),
	'identifierSchemeCodeUnspsc190501.39131503': __('identifierSchemeCodeUnspsc190501.39131503'),
	'identifierSchemeCodeUnspsc190501.39131504': __('identifierSchemeCodeUnspsc190501.39131504'),
	'identifierSchemeCodeUnspsc190501.39131505': __('identifierSchemeCodeUnspsc190501.39131505'),
	'identifierSchemeCodeUnspsc190501.39131506': __('identifierSchemeCodeUnspsc190501.39131506'),
	'identifierSchemeCodeUnspsc190501.39131507': __('identifierSchemeCodeUnspsc190501.39131507'),
	'identifierSchemeCodeUnspsc190501.39131508': __('identifierSchemeCodeUnspsc190501.39131508'),
	'identifierSchemeCodeUnspsc190501.39131509': __('identifierSchemeCodeUnspsc190501.39131509'),
	'identifierSchemeCodeUnspsc190501.39131600': __('identifierSchemeCodeUnspsc190501.39131600'),
	'identifierSchemeCodeUnspsc190501.39131601': __('identifierSchemeCodeUnspsc190501.39131601'),
	'identifierSchemeCodeUnspsc190501.39131602': __('identifierSchemeCodeUnspsc190501.39131602'),
	'identifierSchemeCodeUnspsc190501.39131603': __('identifierSchemeCodeUnspsc190501.39131603'),
	'identifierSchemeCodeUnspsc190501.39131604': __('identifierSchemeCodeUnspsc190501.39131604'),
	'identifierSchemeCodeUnspsc190501.39131605': __('identifierSchemeCodeUnspsc190501.39131605'),
	'identifierSchemeCodeUnspsc190501.39131606': __('identifierSchemeCodeUnspsc190501.39131606'),
	'identifierSchemeCodeUnspsc190501.39131607': __('identifierSchemeCodeUnspsc190501.39131607'),
	'identifierSchemeCodeUnspsc190501.39131608': __('identifierSchemeCodeUnspsc190501.39131608'),
	'identifierSchemeCodeUnspsc190501.39131609': __('identifierSchemeCodeUnspsc190501.39131609'),
	'identifierSchemeCodeUnspsc190501.39131700': __('identifierSchemeCodeUnspsc190501.39131700'),
	'identifierSchemeCodeUnspsc190501.39131701': __('identifierSchemeCodeUnspsc190501.39131701'),
	'identifierSchemeCodeUnspsc190501.39131702': __('identifierSchemeCodeUnspsc190501.39131702'),
	'identifierSchemeCodeUnspsc190501.39131703': __('identifierSchemeCodeUnspsc190501.39131703'),
	'identifierSchemeCodeUnspsc190501.39131704': __('identifierSchemeCodeUnspsc190501.39131704'),
	'identifierSchemeCodeUnspsc190501.39131705': __('identifierSchemeCodeUnspsc190501.39131705'),
	'identifierSchemeCodeUnspsc190501.39131706': __('identifierSchemeCodeUnspsc190501.39131706'),
	'identifierSchemeCodeUnspsc190501.39131707': __('identifierSchemeCodeUnspsc190501.39131707'),
	'identifierSchemeCodeUnspsc190501.39131708': __('identifierSchemeCodeUnspsc190501.39131708'),
	'identifierSchemeCodeUnspsc190501.39131709': __('identifierSchemeCodeUnspsc190501.39131709'),
	'identifierSchemeCodeUnspsc190501.39131710': __('identifierSchemeCodeUnspsc190501.39131710'),
	'identifierSchemeCodeUnspsc190501.39131711': __('identifierSchemeCodeUnspsc190501.39131711'),
	'identifierSchemeCodeUnspsc190501.39131712': __('identifierSchemeCodeUnspsc190501.39131712'),
	'identifierSchemeCodeUnspsc190501.39131713': __('identifierSchemeCodeUnspsc190501.39131713'),
	'identifierSchemeCodeUnspsc190501.39131714': __('identifierSchemeCodeUnspsc190501.39131714'),
	'identifierSchemeCodeUnspsc190501.39131715': __('identifierSchemeCodeUnspsc190501.39131715'),
	'identifierSchemeCodeUnspsc190501.39131716': __('identifierSchemeCodeUnspsc190501.39131716'),
	'identifierSchemeCodeUnspsc190501.39131717': __('identifierSchemeCodeUnspsc190501.39131717'),
	'identifierSchemeCodeUnspsc190501.39131718': __('identifierSchemeCodeUnspsc190501.39131718'),
	'identifierSchemeCodeUnspsc190501.39131719': __('identifierSchemeCodeUnspsc190501.39131719'),
	'identifierSchemeCodeUnspsc190501.39131720': __('identifierSchemeCodeUnspsc190501.39131720'),
	'identifierSchemeCodeUnspsc190501.40000000': __('identifierSchemeCodeUnspsc190501.40000000'),
	'identifierSchemeCodeUnspsc190501.40100000': __('identifierSchemeCodeUnspsc190501.40100000'),
	'identifierSchemeCodeUnspsc190501.40101500': __('identifierSchemeCodeUnspsc190501.40101500'),
	'identifierSchemeCodeUnspsc190501.40101501': __('identifierSchemeCodeUnspsc190501.40101501'),
	'identifierSchemeCodeUnspsc190501.40101502': __('identifierSchemeCodeUnspsc190501.40101502'),
	'identifierSchemeCodeUnspsc190501.40101503': __('identifierSchemeCodeUnspsc190501.40101503'),
	'identifierSchemeCodeUnspsc190501.40101504': __('identifierSchemeCodeUnspsc190501.40101504'),
	'identifierSchemeCodeUnspsc190501.40101505': __('identifierSchemeCodeUnspsc190501.40101505'),
	'identifierSchemeCodeUnspsc190501.40101506': __('identifierSchemeCodeUnspsc190501.40101506'),
	'identifierSchemeCodeUnspsc190501.40101507': __('identifierSchemeCodeUnspsc190501.40101507'),
	'identifierSchemeCodeUnspsc190501.40101508': __('identifierSchemeCodeUnspsc190501.40101508'),
	'identifierSchemeCodeUnspsc190501.40101509': __('identifierSchemeCodeUnspsc190501.40101509'),
	'identifierSchemeCodeUnspsc190501.40101510': __('identifierSchemeCodeUnspsc190501.40101510'),
	'identifierSchemeCodeUnspsc190501.40101511': __('identifierSchemeCodeUnspsc190501.40101511'),
	'identifierSchemeCodeUnspsc190501.40101512': __('identifierSchemeCodeUnspsc190501.40101512'),
	'identifierSchemeCodeUnspsc190501.40101600': __('identifierSchemeCodeUnspsc190501.40101600'),
	'identifierSchemeCodeUnspsc190501.40101601': __('identifierSchemeCodeUnspsc190501.40101601'),
	'identifierSchemeCodeUnspsc190501.40101602': __('identifierSchemeCodeUnspsc190501.40101602'),
	'identifierSchemeCodeUnspsc190501.40101603': __('identifierSchemeCodeUnspsc190501.40101603'),
	'identifierSchemeCodeUnspsc190501.40101604': __('identifierSchemeCodeUnspsc190501.40101604'),
	'identifierSchemeCodeUnspsc190501.40101605': __('identifierSchemeCodeUnspsc190501.40101605'),
	'identifierSchemeCodeUnspsc190501.40101606': __('identifierSchemeCodeUnspsc190501.40101606'),
	'identifierSchemeCodeUnspsc190501.40101607': __('identifierSchemeCodeUnspsc190501.40101607'),
	'identifierSchemeCodeUnspsc190501.40101608': __('identifierSchemeCodeUnspsc190501.40101608'),
	'identifierSchemeCodeUnspsc190501.40101609': __('identifierSchemeCodeUnspsc190501.40101609'),
	'identifierSchemeCodeUnspsc190501.40101610': __('identifierSchemeCodeUnspsc190501.40101610'),
	'identifierSchemeCodeUnspsc190501.40101700': __('identifierSchemeCodeUnspsc190501.40101700'),
	'identifierSchemeCodeUnspsc190501.40101701': __('identifierSchemeCodeUnspsc190501.40101701'),
	'identifierSchemeCodeUnspsc190501.40101702': __('identifierSchemeCodeUnspsc190501.40101702'),
	'identifierSchemeCodeUnspsc190501.40101703': __('identifierSchemeCodeUnspsc190501.40101703'),
	'identifierSchemeCodeUnspsc190501.40101704': __('identifierSchemeCodeUnspsc190501.40101704'),
	'identifierSchemeCodeUnspsc190501.40101705': __('identifierSchemeCodeUnspsc190501.40101705'),
	'identifierSchemeCodeUnspsc190501.40101706': __('identifierSchemeCodeUnspsc190501.40101706'),
	'identifierSchemeCodeUnspsc190501.40101707': __('identifierSchemeCodeUnspsc190501.40101707'),
	'identifierSchemeCodeUnspsc190501.40101708': __('identifierSchemeCodeUnspsc190501.40101708'),
	'identifierSchemeCodeUnspsc190501.40101709': __('identifierSchemeCodeUnspsc190501.40101709'),
	'identifierSchemeCodeUnspsc190501.40101710': __('identifierSchemeCodeUnspsc190501.40101710'),
	'identifierSchemeCodeUnspsc190501.40101711': __('identifierSchemeCodeUnspsc190501.40101711'),
	'identifierSchemeCodeUnspsc190501.40101712': __('identifierSchemeCodeUnspsc190501.40101712'),
	'identifierSchemeCodeUnspsc190501.40101713': __('identifierSchemeCodeUnspsc190501.40101713'),
	'identifierSchemeCodeUnspsc190501.40101714': __('identifierSchemeCodeUnspsc190501.40101714'),
	'identifierSchemeCodeUnspsc190501.40101715': __('identifierSchemeCodeUnspsc190501.40101715'),
	'identifierSchemeCodeUnspsc190501.40101716': __('identifierSchemeCodeUnspsc190501.40101716'),
	'identifierSchemeCodeUnspsc190501.40101717': __('identifierSchemeCodeUnspsc190501.40101717'),
	'identifierSchemeCodeUnspsc190501.40101718': __('identifierSchemeCodeUnspsc190501.40101718'),
	'identifierSchemeCodeUnspsc190501.40101719': __('identifierSchemeCodeUnspsc190501.40101719'),
	'identifierSchemeCodeUnspsc190501.40101720': __('identifierSchemeCodeUnspsc190501.40101720'),
	'identifierSchemeCodeUnspsc190501.40101721': __('identifierSchemeCodeUnspsc190501.40101721'),
	'identifierSchemeCodeUnspsc190501.40101722': __('identifierSchemeCodeUnspsc190501.40101722'),
	'identifierSchemeCodeUnspsc190501.40101723': __('identifierSchemeCodeUnspsc190501.40101723'),
	'identifierSchemeCodeUnspsc190501.40101800': __('identifierSchemeCodeUnspsc190501.40101800'),
	'identifierSchemeCodeUnspsc190501.40101801': __('identifierSchemeCodeUnspsc190501.40101801'),
	'identifierSchemeCodeUnspsc190501.40101802': __('identifierSchemeCodeUnspsc190501.40101802'),
	'identifierSchemeCodeUnspsc190501.40101805': __('identifierSchemeCodeUnspsc190501.40101805'),
	'identifierSchemeCodeUnspsc190501.40101806': __('identifierSchemeCodeUnspsc190501.40101806'),
	'identifierSchemeCodeUnspsc190501.40101807': __('identifierSchemeCodeUnspsc190501.40101807'),
	'identifierSchemeCodeUnspsc190501.40101808': __('identifierSchemeCodeUnspsc190501.40101808'),
	'identifierSchemeCodeUnspsc190501.40101809': __('identifierSchemeCodeUnspsc190501.40101809'),
	'identifierSchemeCodeUnspsc190501.40101810': __('identifierSchemeCodeUnspsc190501.40101810'),
	'identifierSchemeCodeUnspsc190501.40101811': __('identifierSchemeCodeUnspsc190501.40101811'),
	'identifierSchemeCodeUnspsc190501.40101812': __('identifierSchemeCodeUnspsc190501.40101812'),
	'identifierSchemeCodeUnspsc190501.40101813': __('identifierSchemeCodeUnspsc190501.40101813'),
	'identifierSchemeCodeUnspsc190501.40101814': __('identifierSchemeCodeUnspsc190501.40101814'),
	'identifierSchemeCodeUnspsc190501.40101815': __('identifierSchemeCodeUnspsc190501.40101815'),
	'identifierSchemeCodeUnspsc190501.40101816': __('identifierSchemeCodeUnspsc190501.40101816'),
	'identifierSchemeCodeUnspsc190501.40101817': __('identifierSchemeCodeUnspsc190501.40101817'),
	'identifierSchemeCodeUnspsc190501.40101818': __('identifierSchemeCodeUnspsc190501.40101818'),
	'identifierSchemeCodeUnspsc190501.40101819': __('identifierSchemeCodeUnspsc190501.40101819'),
	'identifierSchemeCodeUnspsc190501.40101820': __('identifierSchemeCodeUnspsc190501.40101820'),
	'identifierSchemeCodeUnspsc190501.40101821': __('identifierSchemeCodeUnspsc190501.40101821'),
	'identifierSchemeCodeUnspsc190501.40101822': __('identifierSchemeCodeUnspsc190501.40101822'),
	'identifierSchemeCodeUnspsc190501.40101823': __('identifierSchemeCodeUnspsc190501.40101823'),
	'identifierSchemeCodeUnspsc190501.40101824': __('identifierSchemeCodeUnspsc190501.40101824'),
	'identifierSchemeCodeUnspsc190501.40101825': __('identifierSchemeCodeUnspsc190501.40101825'),
	'identifierSchemeCodeUnspsc190501.40101826': __('identifierSchemeCodeUnspsc190501.40101826'),
	'identifierSchemeCodeUnspsc190501.40101827': __('identifierSchemeCodeUnspsc190501.40101827'),
	'identifierSchemeCodeUnspsc190501.40101828': __('identifierSchemeCodeUnspsc190501.40101828'),
	'identifierSchemeCodeUnspsc190501.40101829': __('identifierSchemeCodeUnspsc190501.40101829'),
	'identifierSchemeCodeUnspsc190501.40101830': __('identifierSchemeCodeUnspsc190501.40101830'),
	'identifierSchemeCodeUnspsc190501.40101831': __('identifierSchemeCodeUnspsc190501.40101831'),
	'identifierSchemeCodeUnspsc190501.40101832': __('identifierSchemeCodeUnspsc190501.40101832'),
	'identifierSchemeCodeUnspsc190501.40101833': __('identifierSchemeCodeUnspsc190501.40101833'),
	'identifierSchemeCodeUnspsc190501.40101834': __('identifierSchemeCodeUnspsc190501.40101834'),
	'identifierSchemeCodeUnspsc190501.40101835': __('identifierSchemeCodeUnspsc190501.40101835'),
	'identifierSchemeCodeUnspsc190501.40101836': __('identifierSchemeCodeUnspsc190501.40101836'),
	'identifierSchemeCodeUnspsc190501.40101837': __('identifierSchemeCodeUnspsc190501.40101837'),
	'identifierSchemeCodeUnspsc190501.40101838': __('identifierSchemeCodeUnspsc190501.40101838'),
	'identifierSchemeCodeUnspsc190501.40101839': __('identifierSchemeCodeUnspsc190501.40101839'),
	'identifierSchemeCodeUnspsc190501.40101840': __('identifierSchemeCodeUnspsc190501.40101840'),
	'identifierSchemeCodeUnspsc190501.40101841': __('identifierSchemeCodeUnspsc190501.40101841'),
	'identifierSchemeCodeUnspsc190501.40101842': __('identifierSchemeCodeUnspsc190501.40101842'),
	'identifierSchemeCodeUnspsc190501.40101843': __('identifierSchemeCodeUnspsc190501.40101843'),
	'identifierSchemeCodeUnspsc190501.40101844': __('identifierSchemeCodeUnspsc190501.40101844'),
	'identifierSchemeCodeUnspsc190501.40101845': __('identifierSchemeCodeUnspsc190501.40101845'),
	'identifierSchemeCodeUnspsc190501.40101846': __('identifierSchemeCodeUnspsc190501.40101846'),
	'identifierSchemeCodeUnspsc190501.40101847': __('identifierSchemeCodeUnspsc190501.40101847'),
	'identifierSchemeCodeUnspsc190501.40101848': __('identifierSchemeCodeUnspsc190501.40101848'),
	'identifierSchemeCodeUnspsc190501.40101849': __('identifierSchemeCodeUnspsc190501.40101849'),
	'identifierSchemeCodeUnspsc190501.40101850': __('identifierSchemeCodeUnspsc190501.40101850'),
	'identifierSchemeCodeUnspsc190501.40101851': __('identifierSchemeCodeUnspsc190501.40101851'),
	'identifierSchemeCodeUnspsc190501.40101852': __('identifierSchemeCodeUnspsc190501.40101852'),
	'identifierSchemeCodeUnspsc190501.40101900': __('identifierSchemeCodeUnspsc190501.40101900'),
	'identifierSchemeCodeUnspsc190501.40101901': __('identifierSchemeCodeUnspsc190501.40101901'),
	'identifierSchemeCodeUnspsc190501.40101902': __('identifierSchemeCodeUnspsc190501.40101902'),
	'identifierSchemeCodeUnspsc190501.40101903': __('identifierSchemeCodeUnspsc190501.40101903'),
	'identifierSchemeCodeUnspsc190501.40102000': __('identifierSchemeCodeUnspsc190501.40102000'),
	'identifierSchemeCodeUnspsc190501.40102001': __('identifierSchemeCodeUnspsc190501.40102001'),
	'identifierSchemeCodeUnspsc190501.40102002': __('identifierSchemeCodeUnspsc190501.40102002'),
	'identifierSchemeCodeUnspsc190501.40102003': __('identifierSchemeCodeUnspsc190501.40102003'),
	'identifierSchemeCodeUnspsc190501.40102004': __('identifierSchemeCodeUnspsc190501.40102004'),
	'identifierSchemeCodeUnspsc190501.40102005': __('identifierSchemeCodeUnspsc190501.40102005'),
	'identifierSchemeCodeUnspsc190501.40102006': __('identifierSchemeCodeUnspsc190501.40102006'),
	'identifierSchemeCodeUnspsc190501.40102007': __('identifierSchemeCodeUnspsc190501.40102007'),
	'identifierSchemeCodeUnspsc190501.40102100': __('identifierSchemeCodeUnspsc190501.40102100'),
	'identifierSchemeCodeUnspsc190501.40102101': __('identifierSchemeCodeUnspsc190501.40102101'),
	'identifierSchemeCodeUnspsc190501.40102102': __('identifierSchemeCodeUnspsc190501.40102102'),
	'identifierSchemeCodeUnspsc190501.40102103': __('identifierSchemeCodeUnspsc190501.40102103'),
	'identifierSchemeCodeUnspsc190501.40102104': __('identifierSchemeCodeUnspsc190501.40102104'),
	'identifierSchemeCodeUnspsc190501.40102105': __('identifierSchemeCodeUnspsc190501.40102105'),
	'identifierSchemeCodeUnspsc190501.40102106': __('identifierSchemeCodeUnspsc190501.40102106'),
	'identifierSchemeCodeUnspsc190501.40102107': __('identifierSchemeCodeUnspsc190501.40102107'),
	'identifierSchemeCodeUnspsc190501.40140000': __('identifierSchemeCodeUnspsc190501.40140000'),
	'identifierSchemeCodeUnspsc190501.40141600': __('identifierSchemeCodeUnspsc190501.40141600'),
	'identifierSchemeCodeUnspsc190501.40141602': __('identifierSchemeCodeUnspsc190501.40141602'),
	'identifierSchemeCodeUnspsc190501.40141603': __('identifierSchemeCodeUnspsc190501.40141603'),
	'identifierSchemeCodeUnspsc190501.40141604': __('identifierSchemeCodeUnspsc190501.40141604'),
	'identifierSchemeCodeUnspsc190501.40141605': __('identifierSchemeCodeUnspsc190501.40141605'),
	'identifierSchemeCodeUnspsc190501.40141606': __('identifierSchemeCodeUnspsc190501.40141606'),
	'identifierSchemeCodeUnspsc190501.40141607': __('identifierSchemeCodeUnspsc190501.40141607'),
	'identifierSchemeCodeUnspsc190501.40141608': __('identifierSchemeCodeUnspsc190501.40141608'),
	'identifierSchemeCodeUnspsc190501.40141609': __('identifierSchemeCodeUnspsc190501.40141609'),
	'identifierSchemeCodeUnspsc190501.40141610': __('identifierSchemeCodeUnspsc190501.40141610'),
	'identifierSchemeCodeUnspsc190501.40141611': __('identifierSchemeCodeUnspsc190501.40141611'),
	'identifierSchemeCodeUnspsc190501.40141612': __('identifierSchemeCodeUnspsc190501.40141612'),
	'identifierSchemeCodeUnspsc190501.40141613': __('identifierSchemeCodeUnspsc190501.40141613'),
	'identifierSchemeCodeUnspsc190501.40141615': __('identifierSchemeCodeUnspsc190501.40141615'),
	'identifierSchemeCodeUnspsc190501.40141616': __('identifierSchemeCodeUnspsc190501.40141616'),
	'identifierSchemeCodeUnspsc190501.40141617': __('identifierSchemeCodeUnspsc190501.40141617'),
	'identifierSchemeCodeUnspsc190501.40141618': __('identifierSchemeCodeUnspsc190501.40141618'),
	'identifierSchemeCodeUnspsc190501.40141619': __('identifierSchemeCodeUnspsc190501.40141619'),
	'identifierSchemeCodeUnspsc190501.40141620': __('identifierSchemeCodeUnspsc190501.40141620'),
	'identifierSchemeCodeUnspsc190501.40141621': __('identifierSchemeCodeUnspsc190501.40141621'),
	'identifierSchemeCodeUnspsc190501.40141622': __('identifierSchemeCodeUnspsc190501.40141622'),
	'identifierSchemeCodeUnspsc190501.40141623': __('identifierSchemeCodeUnspsc190501.40141623'),
	'identifierSchemeCodeUnspsc190501.40141624': __('identifierSchemeCodeUnspsc190501.40141624'),
	'identifierSchemeCodeUnspsc190501.40141625': __('identifierSchemeCodeUnspsc190501.40141625'),
	'identifierSchemeCodeUnspsc190501.40141626': __('identifierSchemeCodeUnspsc190501.40141626'),
	'identifierSchemeCodeUnspsc190501.40141627': __('identifierSchemeCodeUnspsc190501.40141627'),
	'identifierSchemeCodeUnspsc190501.40141628': __('identifierSchemeCodeUnspsc190501.40141628'),
	'identifierSchemeCodeUnspsc190501.40141629': __('identifierSchemeCodeUnspsc190501.40141629'),
	'identifierSchemeCodeUnspsc190501.40141630': __('identifierSchemeCodeUnspsc190501.40141630'),
	'identifierSchemeCodeUnspsc190501.40141631': __('identifierSchemeCodeUnspsc190501.40141631'),
	'identifierSchemeCodeUnspsc190501.40141632': __('identifierSchemeCodeUnspsc190501.40141632'),
	'identifierSchemeCodeUnspsc190501.40141633': __('identifierSchemeCodeUnspsc190501.40141633'),
	'identifierSchemeCodeUnspsc190501.40141634': __('identifierSchemeCodeUnspsc190501.40141634'),
	'identifierSchemeCodeUnspsc190501.40141635': __('identifierSchemeCodeUnspsc190501.40141635'),
	'identifierSchemeCodeUnspsc190501.40141636': __('identifierSchemeCodeUnspsc190501.40141636'),
	'identifierSchemeCodeUnspsc190501.40141637': __('identifierSchemeCodeUnspsc190501.40141637'),
	'identifierSchemeCodeUnspsc190501.40141638': __('identifierSchemeCodeUnspsc190501.40141638'),
	'identifierSchemeCodeUnspsc190501.40141639': __('identifierSchemeCodeUnspsc190501.40141639'),
	'identifierSchemeCodeUnspsc190501.40141640': __('identifierSchemeCodeUnspsc190501.40141640'),
	'identifierSchemeCodeUnspsc190501.40141641': __('identifierSchemeCodeUnspsc190501.40141641'),
	'identifierSchemeCodeUnspsc190501.40141642': __('identifierSchemeCodeUnspsc190501.40141642'),
	'identifierSchemeCodeUnspsc190501.40141643': __('identifierSchemeCodeUnspsc190501.40141643'),
	'identifierSchemeCodeUnspsc190501.40141644': __('identifierSchemeCodeUnspsc190501.40141644'),
	'identifierSchemeCodeUnspsc190501.40141645': __('identifierSchemeCodeUnspsc190501.40141645'),
	'identifierSchemeCodeUnspsc190501.40141646': __('identifierSchemeCodeUnspsc190501.40141646'),
	'identifierSchemeCodeUnspsc190501.40141647': __('identifierSchemeCodeUnspsc190501.40141647'),
	'identifierSchemeCodeUnspsc190501.40141648': __('identifierSchemeCodeUnspsc190501.40141648'),
	'identifierSchemeCodeUnspsc190501.40141649': __('identifierSchemeCodeUnspsc190501.40141649'),
	'identifierSchemeCodeUnspsc190501.40141650': __('identifierSchemeCodeUnspsc190501.40141650'),
	'identifierSchemeCodeUnspsc190501.40141651': __('identifierSchemeCodeUnspsc190501.40141651'),
	'identifierSchemeCodeUnspsc190501.40141652': __('identifierSchemeCodeUnspsc190501.40141652'),
	'identifierSchemeCodeUnspsc190501.40141653': __('identifierSchemeCodeUnspsc190501.40141653'),
	'identifierSchemeCodeUnspsc190501.40141654': __('identifierSchemeCodeUnspsc190501.40141654'),
	'identifierSchemeCodeUnspsc190501.40141655': __('identifierSchemeCodeUnspsc190501.40141655'),
	'identifierSchemeCodeUnspsc190501.40141656': __('identifierSchemeCodeUnspsc190501.40141656'),
	'identifierSchemeCodeUnspsc190501.40141657': __('identifierSchemeCodeUnspsc190501.40141657'),
	'identifierSchemeCodeUnspsc190501.40141658': __('identifierSchemeCodeUnspsc190501.40141658'),
	'identifierSchemeCodeUnspsc190501.40141659': __('identifierSchemeCodeUnspsc190501.40141659'),
	'identifierSchemeCodeUnspsc190501.40141700': __('identifierSchemeCodeUnspsc190501.40141700'),
	'identifierSchemeCodeUnspsc190501.40141719': __('identifierSchemeCodeUnspsc190501.40141719'),
	'identifierSchemeCodeUnspsc190501.40141720': __('identifierSchemeCodeUnspsc190501.40141720'),
	'identifierSchemeCodeUnspsc190501.40141725': __('identifierSchemeCodeUnspsc190501.40141725'),
	'identifierSchemeCodeUnspsc190501.40141726': __('identifierSchemeCodeUnspsc190501.40141726'),
	'identifierSchemeCodeUnspsc190501.40141727': __('identifierSchemeCodeUnspsc190501.40141727'),
	'identifierSchemeCodeUnspsc190501.40141731': __('identifierSchemeCodeUnspsc190501.40141731'),
	'identifierSchemeCodeUnspsc190501.40141732': __('identifierSchemeCodeUnspsc190501.40141732'),
	'identifierSchemeCodeUnspsc190501.40141734': __('identifierSchemeCodeUnspsc190501.40141734'),
	'identifierSchemeCodeUnspsc190501.40141735': __('identifierSchemeCodeUnspsc190501.40141735'),
	'identifierSchemeCodeUnspsc190501.40141736': __('identifierSchemeCodeUnspsc190501.40141736'),
	'identifierSchemeCodeUnspsc190501.40141737': __('identifierSchemeCodeUnspsc190501.40141737'),
	'identifierSchemeCodeUnspsc190501.40141738': __('identifierSchemeCodeUnspsc190501.40141738'),
	'identifierSchemeCodeUnspsc190501.40141739': __('identifierSchemeCodeUnspsc190501.40141739'),
	'identifierSchemeCodeUnspsc190501.40141740': __('identifierSchemeCodeUnspsc190501.40141740'),
	'identifierSchemeCodeUnspsc190501.40141741': __('identifierSchemeCodeUnspsc190501.40141741'),
	'identifierSchemeCodeUnspsc190501.40141742': __('identifierSchemeCodeUnspsc190501.40141742'),
	'identifierSchemeCodeUnspsc190501.40141743': __('identifierSchemeCodeUnspsc190501.40141743'),
	'identifierSchemeCodeUnspsc190501.40141744': __('identifierSchemeCodeUnspsc190501.40141744'),
	'identifierSchemeCodeUnspsc190501.40141745': __('identifierSchemeCodeUnspsc190501.40141745'),
	'identifierSchemeCodeUnspsc190501.40141746': __('identifierSchemeCodeUnspsc190501.40141746'),
	'identifierSchemeCodeUnspsc190501.40141747': __('identifierSchemeCodeUnspsc190501.40141747'),
	'identifierSchemeCodeUnspsc190501.40141749': __('identifierSchemeCodeUnspsc190501.40141749'),
	'identifierSchemeCodeUnspsc190501.40141750': __('identifierSchemeCodeUnspsc190501.40141750'),
	'identifierSchemeCodeUnspsc190501.40141751': __('identifierSchemeCodeUnspsc190501.40141751'),
	'identifierSchemeCodeUnspsc190501.40141755': __('identifierSchemeCodeUnspsc190501.40141755'),
	'identifierSchemeCodeUnspsc190501.40141756': __('identifierSchemeCodeUnspsc190501.40141756'),
	'identifierSchemeCodeUnspsc190501.40141757': __('identifierSchemeCodeUnspsc190501.40141757'),
	'identifierSchemeCodeUnspsc190501.40141758': __('identifierSchemeCodeUnspsc190501.40141758'),
	'identifierSchemeCodeUnspsc190501.40141759': __('identifierSchemeCodeUnspsc190501.40141759'),
	'identifierSchemeCodeUnspsc190501.40141760': __('identifierSchemeCodeUnspsc190501.40141760'),
	'identifierSchemeCodeUnspsc190501.40141761': __('identifierSchemeCodeUnspsc190501.40141761'),
	'identifierSchemeCodeUnspsc190501.40141762': __('identifierSchemeCodeUnspsc190501.40141762'),
	'identifierSchemeCodeUnspsc190501.40141763': __('identifierSchemeCodeUnspsc190501.40141763'),
	'identifierSchemeCodeUnspsc190501.40141764': __('identifierSchemeCodeUnspsc190501.40141764'),
	'identifierSchemeCodeUnspsc190501.40141765': __('identifierSchemeCodeUnspsc190501.40141765'),
	'identifierSchemeCodeUnspsc190501.40141766': __('identifierSchemeCodeUnspsc190501.40141766'),
	'identifierSchemeCodeUnspsc190501.40141767': __('identifierSchemeCodeUnspsc190501.40141767'),
	'identifierSchemeCodeUnspsc190501.40141768': __('identifierSchemeCodeUnspsc190501.40141768'),
	'identifierSchemeCodeUnspsc190501.40141769': __('identifierSchemeCodeUnspsc190501.40141769'),
	'identifierSchemeCodeUnspsc190501.40141770': __('identifierSchemeCodeUnspsc190501.40141770'),
	'identifierSchemeCodeUnspsc190501.40141900': __('identifierSchemeCodeUnspsc190501.40141900'),
	'identifierSchemeCodeUnspsc190501.40141901': __('identifierSchemeCodeUnspsc190501.40141901'),
	'identifierSchemeCodeUnspsc190501.40141902': __('identifierSchemeCodeUnspsc190501.40141902'),
	'identifierSchemeCodeUnspsc190501.40141903': __('identifierSchemeCodeUnspsc190501.40141903'),
	'identifierSchemeCodeUnspsc190501.40141904': __('identifierSchemeCodeUnspsc190501.40141904'),
	'identifierSchemeCodeUnspsc190501.40141905': __('identifierSchemeCodeUnspsc190501.40141905'),
	'identifierSchemeCodeUnspsc190501.40141906': __('identifierSchemeCodeUnspsc190501.40141906'),
	'identifierSchemeCodeUnspsc190501.40141907': __('identifierSchemeCodeUnspsc190501.40141907'),
	'identifierSchemeCodeUnspsc190501.40141908': __('identifierSchemeCodeUnspsc190501.40141908'),
	'identifierSchemeCodeUnspsc190501.40141909': __('identifierSchemeCodeUnspsc190501.40141909'),
	'identifierSchemeCodeUnspsc190501.40141910': __('identifierSchemeCodeUnspsc190501.40141910'),
	'identifierSchemeCodeUnspsc190501.40141911': __('identifierSchemeCodeUnspsc190501.40141911'),
	'identifierSchemeCodeUnspsc190501.40141912': __('identifierSchemeCodeUnspsc190501.40141912'),
	'identifierSchemeCodeUnspsc190501.40141913': __('identifierSchemeCodeUnspsc190501.40141913'),
	'identifierSchemeCodeUnspsc190501.40141914': __('identifierSchemeCodeUnspsc190501.40141914'),
	'identifierSchemeCodeUnspsc190501.40141915': __('identifierSchemeCodeUnspsc190501.40141915'),
	'identifierSchemeCodeUnspsc190501.40141916': __('identifierSchemeCodeUnspsc190501.40141916'),
	'identifierSchemeCodeUnspsc190501.40141917': __('identifierSchemeCodeUnspsc190501.40141917'),
	'identifierSchemeCodeUnspsc190501.40141918': __('identifierSchemeCodeUnspsc190501.40141918'),
	'identifierSchemeCodeUnspsc190501.40141919': __('identifierSchemeCodeUnspsc190501.40141919'),
	'identifierSchemeCodeUnspsc190501.40141920': __('identifierSchemeCodeUnspsc190501.40141920'),
	'identifierSchemeCodeUnspsc190501.40141921': __('identifierSchemeCodeUnspsc190501.40141921'),
	'identifierSchemeCodeUnspsc190501.40141922': __('identifierSchemeCodeUnspsc190501.40141922'),
	'identifierSchemeCodeUnspsc190501.40141923': __('identifierSchemeCodeUnspsc190501.40141923'),
	'identifierSchemeCodeUnspsc190501.40141924': __('identifierSchemeCodeUnspsc190501.40141924'),
	'identifierSchemeCodeUnspsc190501.40142000': __('identifierSchemeCodeUnspsc190501.40142000'),
	'identifierSchemeCodeUnspsc190501.40142001': __('identifierSchemeCodeUnspsc190501.40142001'),
	'identifierSchemeCodeUnspsc190501.40142002': __('identifierSchemeCodeUnspsc190501.40142002'),
	'identifierSchemeCodeUnspsc190501.40142003': __('identifierSchemeCodeUnspsc190501.40142003'),
	'identifierSchemeCodeUnspsc190501.40142004': __('identifierSchemeCodeUnspsc190501.40142004'),
	'identifierSchemeCodeUnspsc190501.40142005': __('identifierSchemeCodeUnspsc190501.40142005'),
	'identifierSchemeCodeUnspsc190501.40142006': __('identifierSchemeCodeUnspsc190501.40142006'),
	'identifierSchemeCodeUnspsc190501.40142007': __('identifierSchemeCodeUnspsc190501.40142007'),
	'identifierSchemeCodeUnspsc190501.40142008': __('identifierSchemeCodeUnspsc190501.40142008'),
	'identifierSchemeCodeUnspsc190501.40142009': __('identifierSchemeCodeUnspsc190501.40142009'),
	'identifierSchemeCodeUnspsc190501.40142010': __('identifierSchemeCodeUnspsc190501.40142010'),
	'identifierSchemeCodeUnspsc190501.40142011': __('identifierSchemeCodeUnspsc190501.40142011'),
	'identifierSchemeCodeUnspsc190501.40142012': __('identifierSchemeCodeUnspsc190501.40142012'),
	'identifierSchemeCodeUnspsc190501.40142013': __('identifierSchemeCodeUnspsc190501.40142013'),
	'identifierSchemeCodeUnspsc190501.40142014': __('identifierSchemeCodeUnspsc190501.40142014'),
	'identifierSchemeCodeUnspsc190501.40142015': __('identifierSchemeCodeUnspsc190501.40142015'),
	'identifierSchemeCodeUnspsc190501.40142016': __('identifierSchemeCodeUnspsc190501.40142016'),
	'identifierSchemeCodeUnspsc190501.40142017': __('identifierSchemeCodeUnspsc190501.40142017'),
	'identifierSchemeCodeUnspsc190501.40142018': __('identifierSchemeCodeUnspsc190501.40142018'),
	'identifierSchemeCodeUnspsc190501.40142019': __('identifierSchemeCodeUnspsc190501.40142019'),
	'identifierSchemeCodeUnspsc190501.40142020': __('identifierSchemeCodeUnspsc190501.40142020'),
	'identifierSchemeCodeUnspsc190501.40142021': __('identifierSchemeCodeUnspsc190501.40142021'),
	'identifierSchemeCodeUnspsc190501.40142022': __('identifierSchemeCodeUnspsc190501.40142022'),
	'identifierSchemeCodeUnspsc190501.40142200': __('identifierSchemeCodeUnspsc190501.40142200'),
	'identifierSchemeCodeUnspsc190501.40142201': __('identifierSchemeCodeUnspsc190501.40142201'),
	'identifierSchemeCodeUnspsc190501.40142202': __('identifierSchemeCodeUnspsc190501.40142202'),
	'identifierSchemeCodeUnspsc190501.40142203': __('identifierSchemeCodeUnspsc190501.40142203'),
	'identifierSchemeCodeUnspsc190501.40142204': __('identifierSchemeCodeUnspsc190501.40142204'),
	'identifierSchemeCodeUnspsc190501.40142205': __('identifierSchemeCodeUnspsc190501.40142205'),
	'identifierSchemeCodeUnspsc190501.40142206': __('identifierSchemeCodeUnspsc190501.40142206'),
	'identifierSchemeCodeUnspsc190501.40142207': __('identifierSchemeCodeUnspsc190501.40142207'),
	'identifierSchemeCodeUnspsc190501.40142208': __('identifierSchemeCodeUnspsc190501.40142208'),
	'identifierSchemeCodeUnspsc190501.40142500': __('identifierSchemeCodeUnspsc190501.40142500'),
	'identifierSchemeCodeUnspsc190501.40142501': __('identifierSchemeCodeUnspsc190501.40142501'),
	'identifierSchemeCodeUnspsc190501.40142502': __('identifierSchemeCodeUnspsc190501.40142502'),
	'identifierSchemeCodeUnspsc190501.40142503': __('identifierSchemeCodeUnspsc190501.40142503'),
	'identifierSchemeCodeUnspsc190501.40142504': __('identifierSchemeCodeUnspsc190501.40142504'),
	'identifierSchemeCodeUnspsc190501.40142505': __('identifierSchemeCodeUnspsc190501.40142505'),
	'identifierSchemeCodeUnspsc190501.40142506': __('identifierSchemeCodeUnspsc190501.40142506'),
	'identifierSchemeCodeUnspsc190501.40142507': __('identifierSchemeCodeUnspsc190501.40142507'),
	'identifierSchemeCodeUnspsc190501.40142508': __('identifierSchemeCodeUnspsc190501.40142508'),
	'identifierSchemeCodeUnspsc190501.40142509': __('identifierSchemeCodeUnspsc190501.40142509'),
	'identifierSchemeCodeUnspsc190501.40142510': __('identifierSchemeCodeUnspsc190501.40142510'),
	'identifierSchemeCodeUnspsc190501.40142511': __('identifierSchemeCodeUnspsc190501.40142511'),
	'identifierSchemeCodeUnspsc190501.40142512': __('identifierSchemeCodeUnspsc190501.40142512'),
	'identifierSchemeCodeUnspsc190501.40142513': __('identifierSchemeCodeUnspsc190501.40142513'),
	'identifierSchemeCodeUnspsc190501.40142514': __('identifierSchemeCodeUnspsc190501.40142514'),
	'identifierSchemeCodeUnspsc190501.40142515': __('identifierSchemeCodeUnspsc190501.40142515'),
	'identifierSchemeCodeUnspsc190501.40150000': __('identifierSchemeCodeUnspsc190501.40150000'),
	'identifierSchemeCodeUnspsc190501.40151500': __('identifierSchemeCodeUnspsc190501.40151500'),
	'identifierSchemeCodeUnspsc190501.40151501': __('identifierSchemeCodeUnspsc190501.40151501'),
	'identifierSchemeCodeUnspsc190501.40151502': __('identifierSchemeCodeUnspsc190501.40151502'),
	'identifierSchemeCodeUnspsc190501.40151503': __('identifierSchemeCodeUnspsc190501.40151503'),
	'identifierSchemeCodeUnspsc190501.40151504': __('identifierSchemeCodeUnspsc190501.40151504'),
	'identifierSchemeCodeUnspsc190501.40151505': __('identifierSchemeCodeUnspsc190501.40151505'),
	'identifierSchemeCodeUnspsc190501.40151506': __('identifierSchemeCodeUnspsc190501.40151506'),
	'identifierSchemeCodeUnspsc190501.40151507': __('identifierSchemeCodeUnspsc190501.40151507'),
	'identifierSchemeCodeUnspsc190501.40151508': __('identifierSchemeCodeUnspsc190501.40151508'),
	'identifierSchemeCodeUnspsc190501.40151509': __('identifierSchemeCodeUnspsc190501.40151509'),
	'identifierSchemeCodeUnspsc190501.40151510': __('identifierSchemeCodeUnspsc190501.40151510'),
	'identifierSchemeCodeUnspsc190501.40151511': __('identifierSchemeCodeUnspsc190501.40151511'),
	'identifierSchemeCodeUnspsc190501.40151512': __('identifierSchemeCodeUnspsc190501.40151512'),
	'identifierSchemeCodeUnspsc190501.40151513': __('identifierSchemeCodeUnspsc190501.40151513'),
	'identifierSchemeCodeUnspsc190501.40151514': __('identifierSchemeCodeUnspsc190501.40151514'),
	'identifierSchemeCodeUnspsc190501.40151515': __('identifierSchemeCodeUnspsc190501.40151515'),
	'identifierSchemeCodeUnspsc190501.40151516': __('identifierSchemeCodeUnspsc190501.40151516'),
	'identifierSchemeCodeUnspsc190501.40151517': __('identifierSchemeCodeUnspsc190501.40151517'),
	'identifierSchemeCodeUnspsc190501.40151518': __('identifierSchemeCodeUnspsc190501.40151518'),
	'identifierSchemeCodeUnspsc190501.40151519': __('identifierSchemeCodeUnspsc190501.40151519'),
	'identifierSchemeCodeUnspsc190501.40151520': __('identifierSchemeCodeUnspsc190501.40151520'),
	'identifierSchemeCodeUnspsc190501.40151521': __('identifierSchemeCodeUnspsc190501.40151521'),
	'identifierSchemeCodeUnspsc190501.40151522': __('identifierSchemeCodeUnspsc190501.40151522'),
	'identifierSchemeCodeUnspsc190501.40151523': __('identifierSchemeCodeUnspsc190501.40151523'),
	'identifierSchemeCodeUnspsc190501.40151524': __('identifierSchemeCodeUnspsc190501.40151524'),
	'identifierSchemeCodeUnspsc190501.40151525': __('identifierSchemeCodeUnspsc190501.40151525'),
	'identifierSchemeCodeUnspsc190501.40151526': __('identifierSchemeCodeUnspsc190501.40151526'),
	'identifierSchemeCodeUnspsc190501.40151527': __('identifierSchemeCodeUnspsc190501.40151527'),
	'identifierSchemeCodeUnspsc190501.40151528': __('identifierSchemeCodeUnspsc190501.40151528'),
	'identifierSchemeCodeUnspsc190501.40151529': __('identifierSchemeCodeUnspsc190501.40151529'),
	'identifierSchemeCodeUnspsc190501.40151530': __('identifierSchemeCodeUnspsc190501.40151530'),
	'identifierSchemeCodeUnspsc190501.40151531': __('identifierSchemeCodeUnspsc190501.40151531'),
	'identifierSchemeCodeUnspsc190501.40151532': __('identifierSchemeCodeUnspsc190501.40151532'),
	'identifierSchemeCodeUnspsc190501.40151533': __('identifierSchemeCodeUnspsc190501.40151533'),
	'identifierSchemeCodeUnspsc190501.40151534': __('identifierSchemeCodeUnspsc190501.40151534'),
	'identifierSchemeCodeUnspsc190501.40151546': __('identifierSchemeCodeUnspsc190501.40151546'),
	'identifierSchemeCodeUnspsc190501.40151547': __('identifierSchemeCodeUnspsc190501.40151547'),
	'identifierSchemeCodeUnspsc190501.40151548': __('identifierSchemeCodeUnspsc190501.40151548'),
	'identifierSchemeCodeUnspsc190501.40151549': __('identifierSchemeCodeUnspsc190501.40151549'),
	'identifierSchemeCodeUnspsc190501.40151550': __('identifierSchemeCodeUnspsc190501.40151550'),
	'identifierSchemeCodeUnspsc190501.40151551': __('identifierSchemeCodeUnspsc190501.40151551'),
	'identifierSchemeCodeUnspsc190501.40151552': __('identifierSchemeCodeUnspsc190501.40151552'),
	'identifierSchemeCodeUnspsc190501.40151553': __('identifierSchemeCodeUnspsc190501.40151553'),
	'identifierSchemeCodeUnspsc190501.40151554': __('identifierSchemeCodeUnspsc190501.40151554'),
	'identifierSchemeCodeUnspsc190501.40151555': __('identifierSchemeCodeUnspsc190501.40151555'),
	'identifierSchemeCodeUnspsc190501.40151556': __('identifierSchemeCodeUnspsc190501.40151556'),
	'identifierSchemeCodeUnspsc190501.40151557': __('identifierSchemeCodeUnspsc190501.40151557'),
	'identifierSchemeCodeUnspsc190501.40151558': __('identifierSchemeCodeUnspsc190501.40151558'),
	'identifierSchemeCodeUnspsc190501.40151559': __('identifierSchemeCodeUnspsc190501.40151559'),
	'identifierSchemeCodeUnspsc190501.40151560': __('identifierSchemeCodeUnspsc190501.40151560'),
	'identifierSchemeCodeUnspsc190501.40151561': __('identifierSchemeCodeUnspsc190501.40151561'),
	'identifierSchemeCodeUnspsc190501.40151562': __('identifierSchemeCodeUnspsc190501.40151562'),
	'identifierSchemeCodeUnspsc190501.40151563': __('identifierSchemeCodeUnspsc190501.40151563'),
	'identifierSchemeCodeUnspsc190501.40151564': __('identifierSchemeCodeUnspsc190501.40151564'),
	'identifierSchemeCodeUnspsc190501.40151565': __('identifierSchemeCodeUnspsc190501.40151565'),
	'identifierSchemeCodeUnspsc190501.40151566': __('identifierSchemeCodeUnspsc190501.40151566'),
	'identifierSchemeCodeUnspsc190501.40151567': __('identifierSchemeCodeUnspsc190501.40151567'),
	'identifierSchemeCodeUnspsc190501.40151568': __('identifierSchemeCodeUnspsc190501.40151568'),
	'identifierSchemeCodeUnspsc190501.40151569': __('identifierSchemeCodeUnspsc190501.40151569'),
	'identifierSchemeCodeUnspsc190501.40151570': __('identifierSchemeCodeUnspsc190501.40151570'),
	'identifierSchemeCodeUnspsc190501.40151571': __('identifierSchemeCodeUnspsc190501.40151571'),
	'identifierSchemeCodeUnspsc190501.40151572': __('identifierSchemeCodeUnspsc190501.40151572'),
	'identifierSchemeCodeUnspsc190501.40151573': __('identifierSchemeCodeUnspsc190501.40151573'),
	'identifierSchemeCodeUnspsc190501.40151574': __('identifierSchemeCodeUnspsc190501.40151574'),
	'identifierSchemeCodeUnspsc190501.40151575': __('identifierSchemeCodeUnspsc190501.40151575'),
	'identifierSchemeCodeUnspsc190501.40151576': __('identifierSchemeCodeUnspsc190501.40151576'),
	'identifierSchemeCodeUnspsc190501.40151577': __('identifierSchemeCodeUnspsc190501.40151577'),
	'identifierSchemeCodeUnspsc190501.40151578': __('identifierSchemeCodeUnspsc190501.40151578'),
	'identifierSchemeCodeUnspsc190501.40151579': __('identifierSchemeCodeUnspsc190501.40151579'),
	'identifierSchemeCodeUnspsc190501.40151580': __('identifierSchemeCodeUnspsc190501.40151580'),
	'identifierSchemeCodeUnspsc190501.40151581': __('identifierSchemeCodeUnspsc190501.40151581'),
	'identifierSchemeCodeUnspsc190501.40151582': __('identifierSchemeCodeUnspsc190501.40151582'),
	'identifierSchemeCodeUnspsc190501.40151600': __('identifierSchemeCodeUnspsc190501.40151600'),
	'identifierSchemeCodeUnspsc190501.40151601': __('identifierSchemeCodeUnspsc190501.40151601'),
	'identifierSchemeCodeUnspsc190501.40151602': __('identifierSchemeCodeUnspsc190501.40151602'),
	'identifierSchemeCodeUnspsc190501.40151603': __('identifierSchemeCodeUnspsc190501.40151603'),
	'identifierSchemeCodeUnspsc190501.40151604': __('identifierSchemeCodeUnspsc190501.40151604'),
	'identifierSchemeCodeUnspsc190501.40151605': __('identifierSchemeCodeUnspsc190501.40151605'),
	'identifierSchemeCodeUnspsc190501.40151606': __('identifierSchemeCodeUnspsc190501.40151606'),
	'identifierSchemeCodeUnspsc190501.40151607': __('identifierSchemeCodeUnspsc190501.40151607'),
	'identifierSchemeCodeUnspsc190501.40151608': __('identifierSchemeCodeUnspsc190501.40151608'),
	'identifierSchemeCodeUnspsc190501.40151609': __('identifierSchemeCodeUnspsc190501.40151609'),
	'identifierSchemeCodeUnspsc190501.40151611': __('identifierSchemeCodeUnspsc190501.40151611'),
	'identifierSchemeCodeUnspsc190501.40151612': __('identifierSchemeCodeUnspsc190501.40151612'),
	'identifierSchemeCodeUnspsc190501.40151613': __('identifierSchemeCodeUnspsc190501.40151613'),
	'identifierSchemeCodeUnspsc190501.40151614': __('identifierSchemeCodeUnspsc190501.40151614'),
	'identifierSchemeCodeUnspsc190501.40151615': __('identifierSchemeCodeUnspsc190501.40151615'),
	'identifierSchemeCodeUnspsc190501.40151616': __('identifierSchemeCodeUnspsc190501.40151616'),
	'identifierSchemeCodeUnspsc190501.40151700': __('identifierSchemeCodeUnspsc190501.40151700'),
	'identifierSchemeCodeUnspsc190501.40151701': __('identifierSchemeCodeUnspsc190501.40151701'),
	'identifierSchemeCodeUnspsc190501.40151712': __('identifierSchemeCodeUnspsc190501.40151712'),
	'identifierSchemeCodeUnspsc190501.40151713': __('identifierSchemeCodeUnspsc190501.40151713'),
	'identifierSchemeCodeUnspsc190501.40151714': __('identifierSchemeCodeUnspsc190501.40151714'),
	'identifierSchemeCodeUnspsc190501.40151715': __('identifierSchemeCodeUnspsc190501.40151715'),
	'identifierSchemeCodeUnspsc190501.40151716': __('identifierSchemeCodeUnspsc190501.40151716'),
	'identifierSchemeCodeUnspsc190501.40151717': __('identifierSchemeCodeUnspsc190501.40151717'),
	'identifierSchemeCodeUnspsc190501.40151718': __('identifierSchemeCodeUnspsc190501.40151718'),
	'identifierSchemeCodeUnspsc190501.40151719': __('identifierSchemeCodeUnspsc190501.40151719'),
	'identifierSchemeCodeUnspsc190501.40151720': __('identifierSchemeCodeUnspsc190501.40151720'),
	'identifierSchemeCodeUnspsc190501.40151721': __('identifierSchemeCodeUnspsc190501.40151721'),
	'identifierSchemeCodeUnspsc190501.40151722': __('identifierSchemeCodeUnspsc190501.40151722'),
	'identifierSchemeCodeUnspsc190501.40151723': __('identifierSchemeCodeUnspsc190501.40151723'),
	'identifierSchemeCodeUnspsc190501.40151724': __('identifierSchemeCodeUnspsc190501.40151724'),
	'identifierSchemeCodeUnspsc190501.40151725': __('identifierSchemeCodeUnspsc190501.40151725'),
	'identifierSchemeCodeUnspsc190501.40151726': __('identifierSchemeCodeUnspsc190501.40151726'),
	'identifierSchemeCodeUnspsc190501.40151727': __('identifierSchemeCodeUnspsc190501.40151727'),
	'identifierSchemeCodeUnspsc190501.40151728': __('identifierSchemeCodeUnspsc190501.40151728'),
	'identifierSchemeCodeUnspsc190501.40151729': __('identifierSchemeCodeUnspsc190501.40151729'),
	'identifierSchemeCodeUnspsc190501.40151730': __('identifierSchemeCodeUnspsc190501.40151730'),
	'identifierSchemeCodeUnspsc190501.40151731': __('identifierSchemeCodeUnspsc190501.40151731'),
	'identifierSchemeCodeUnspsc190501.40151732': __('identifierSchemeCodeUnspsc190501.40151732'),
	'identifierSchemeCodeUnspsc190501.40151733': __('identifierSchemeCodeUnspsc190501.40151733'),
	'identifierSchemeCodeUnspsc190501.40151734': __('identifierSchemeCodeUnspsc190501.40151734'),
	'identifierSchemeCodeUnspsc190501.40151735': __('identifierSchemeCodeUnspsc190501.40151735'),
	'identifierSchemeCodeUnspsc190501.40151736': __('identifierSchemeCodeUnspsc190501.40151736'),
	'identifierSchemeCodeUnspsc190501.40151737': __('identifierSchemeCodeUnspsc190501.40151737'),
	'identifierSchemeCodeUnspsc190501.40151738': __('identifierSchemeCodeUnspsc190501.40151738'),
	'identifierSchemeCodeUnspsc190501.40151739': __('identifierSchemeCodeUnspsc190501.40151739'),
	'identifierSchemeCodeUnspsc190501.40151800': __('identifierSchemeCodeUnspsc190501.40151800'),
	'identifierSchemeCodeUnspsc190501.40151801': __('identifierSchemeCodeUnspsc190501.40151801'),
	'identifierSchemeCodeUnspsc190501.40151802': __('identifierSchemeCodeUnspsc190501.40151802'),
	'identifierSchemeCodeUnspsc190501.40151803': __('identifierSchemeCodeUnspsc190501.40151803'),
	'identifierSchemeCodeUnspsc190501.40151804': __('identifierSchemeCodeUnspsc190501.40151804'),
	'identifierSchemeCodeUnspsc190501.40160000': __('identifierSchemeCodeUnspsc190501.40160000'),
	'identifierSchemeCodeUnspsc190501.40161500': __('identifierSchemeCodeUnspsc190501.40161500'),
	'identifierSchemeCodeUnspsc190501.40161501': __('identifierSchemeCodeUnspsc190501.40161501'),
	'identifierSchemeCodeUnspsc190501.40161502': __('identifierSchemeCodeUnspsc190501.40161502'),
	'identifierSchemeCodeUnspsc190501.40161503': __('identifierSchemeCodeUnspsc190501.40161503'),
	'identifierSchemeCodeUnspsc190501.40161504': __('identifierSchemeCodeUnspsc190501.40161504'),
	'identifierSchemeCodeUnspsc190501.40161505': __('identifierSchemeCodeUnspsc190501.40161505'),
	'identifierSchemeCodeUnspsc190501.40161506': __('identifierSchemeCodeUnspsc190501.40161506'),
	'identifierSchemeCodeUnspsc190501.40161507': __('identifierSchemeCodeUnspsc190501.40161507'),
	'identifierSchemeCodeUnspsc190501.40161508': __('identifierSchemeCodeUnspsc190501.40161508'),
	'identifierSchemeCodeUnspsc190501.40161509': __('identifierSchemeCodeUnspsc190501.40161509'),
	'identifierSchemeCodeUnspsc190501.40161511': __('identifierSchemeCodeUnspsc190501.40161511'),
	'identifierSchemeCodeUnspsc190501.40161512': __('identifierSchemeCodeUnspsc190501.40161512'),
	'identifierSchemeCodeUnspsc190501.40161513': __('identifierSchemeCodeUnspsc190501.40161513'),
	'identifierSchemeCodeUnspsc190501.40161514': __('identifierSchemeCodeUnspsc190501.40161514'),
	'identifierSchemeCodeUnspsc190501.40161515': __('identifierSchemeCodeUnspsc190501.40161515'),
	'identifierSchemeCodeUnspsc190501.40161516': __('identifierSchemeCodeUnspsc190501.40161516'),
	'identifierSchemeCodeUnspsc190501.40161517': __('identifierSchemeCodeUnspsc190501.40161517'),
	'identifierSchemeCodeUnspsc190501.40161518': __('identifierSchemeCodeUnspsc190501.40161518'),
	'identifierSchemeCodeUnspsc190501.40161519': __('identifierSchemeCodeUnspsc190501.40161519'),
	'identifierSchemeCodeUnspsc190501.40161520': __('identifierSchemeCodeUnspsc190501.40161520'),
	'identifierSchemeCodeUnspsc190501.40161521': __('identifierSchemeCodeUnspsc190501.40161521'),
	'identifierSchemeCodeUnspsc190501.40161522': __('identifierSchemeCodeUnspsc190501.40161522'),
	'identifierSchemeCodeUnspsc190501.40161524': __('identifierSchemeCodeUnspsc190501.40161524'),
	'identifierSchemeCodeUnspsc190501.40161525': __('identifierSchemeCodeUnspsc190501.40161525'),
	'identifierSchemeCodeUnspsc190501.40161526': __('identifierSchemeCodeUnspsc190501.40161526'),
	'identifierSchemeCodeUnspsc190501.40161527': __('identifierSchemeCodeUnspsc190501.40161527'),
	'identifierSchemeCodeUnspsc190501.40161528': __('identifierSchemeCodeUnspsc190501.40161528'),
	'identifierSchemeCodeUnspsc190501.40161529': __('identifierSchemeCodeUnspsc190501.40161529'),
	'identifierSchemeCodeUnspsc190501.40161530': __('identifierSchemeCodeUnspsc190501.40161530'),
	'identifierSchemeCodeUnspsc190501.40161531': __('identifierSchemeCodeUnspsc190501.40161531'),
	'identifierSchemeCodeUnspsc190501.40161532': __('identifierSchemeCodeUnspsc190501.40161532'),
	'identifierSchemeCodeUnspsc190501.40161533': __('identifierSchemeCodeUnspsc190501.40161533'),
	'identifierSchemeCodeUnspsc190501.40161600': __('identifierSchemeCodeUnspsc190501.40161600'),
	'identifierSchemeCodeUnspsc190501.40161601': __('identifierSchemeCodeUnspsc190501.40161601'),
	'identifierSchemeCodeUnspsc190501.40161602': __('identifierSchemeCodeUnspsc190501.40161602'),
	'identifierSchemeCodeUnspsc190501.40161603': __('identifierSchemeCodeUnspsc190501.40161603'),
	'identifierSchemeCodeUnspsc190501.40161604': __('identifierSchemeCodeUnspsc190501.40161604'),
	'identifierSchemeCodeUnspsc190501.40161605': __('identifierSchemeCodeUnspsc190501.40161605'),
	'identifierSchemeCodeUnspsc190501.40161606': __('identifierSchemeCodeUnspsc190501.40161606'),
	'identifierSchemeCodeUnspsc190501.40161607': __('identifierSchemeCodeUnspsc190501.40161607'),
	'identifierSchemeCodeUnspsc190501.40161608': __('identifierSchemeCodeUnspsc190501.40161608'),
	'identifierSchemeCodeUnspsc190501.40161609': __('identifierSchemeCodeUnspsc190501.40161609'),
	'identifierSchemeCodeUnspsc190501.40161700': __('identifierSchemeCodeUnspsc190501.40161700'),
	'identifierSchemeCodeUnspsc190501.40161701': __('identifierSchemeCodeUnspsc190501.40161701'),
	'identifierSchemeCodeUnspsc190501.40161702': __('identifierSchemeCodeUnspsc190501.40161702'),
	'identifierSchemeCodeUnspsc190501.40161703': __('identifierSchemeCodeUnspsc190501.40161703'),
	'identifierSchemeCodeUnspsc190501.40161704': __('identifierSchemeCodeUnspsc190501.40161704'),
	'identifierSchemeCodeUnspsc190501.40161705': __('identifierSchemeCodeUnspsc190501.40161705'),
	'identifierSchemeCodeUnspsc190501.40161800': __('identifierSchemeCodeUnspsc190501.40161800'),
	'identifierSchemeCodeUnspsc190501.40161801': __('identifierSchemeCodeUnspsc190501.40161801'),
	'identifierSchemeCodeUnspsc190501.40161802': __('identifierSchemeCodeUnspsc190501.40161802'),
	'identifierSchemeCodeUnspsc190501.40161803': __('identifierSchemeCodeUnspsc190501.40161803'),
	'identifierSchemeCodeUnspsc190501.40161804': __('identifierSchemeCodeUnspsc190501.40161804'),
	'identifierSchemeCodeUnspsc190501.40161805': __('identifierSchemeCodeUnspsc190501.40161805'),
	'identifierSchemeCodeUnspsc190501.40161806': __('identifierSchemeCodeUnspsc190501.40161806'),
	'identifierSchemeCodeUnspsc190501.40161807': __('identifierSchemeCodeUnspsc190501.40161807'),
	'identifierSchemeCodeUnspsc190501.40161808': __('identifierSchemeCodeUnspsc190501.40161808'),
	'identifierSchemeCodeUnspsc190501.40161809': __('identifierSchemeCodeUnspsc190501.40161809'),
	'identifierSchemeCodeUnspsc190501.40170000': __('identifierSchemeCodeUnspsc190501.40170000'),
	'identifierSchemeCodeUnspsc190501.40171500': __('identifierSchemeCodeUnspsc190501.40171500'),
	'identifierSchemeCodeUnspsc190501.40171501': __('identifierSchemeCodeUnspsc190501.40171501'),
	'identifierSchemeCodeUnspsc190501.40171502': __('identifierSchemeCodeUnspsc190501.40171502'),
	'identifierSchemeCodeUnspsc190501.40171503': __('identifierSchemeCodeUnspsc190501.40171503'),
	'identifierSchemeCodeUnspsc190501.40171504': __('identifierSchemeCodeUnspsc190501.40171504'),
	'identifierSchemeCodeUnspsc190501.40171505': __('identifierSchemeCodeUnspsc190501.40171505'),
	'identifierSchemeCodeUnspsc190501.40171506': __('identifierSchemeCodeUnspsc190501.40171506'),
	'identifierSchemeCodeUnspsc190501.40171507': __('identifierSchemeCodeUnspsc190501.40171507'),
	'identifierSchemeCodeUnspsc190501.40171508': __('identifierSchemeCodeUnspsc190501.40171508'),
	'identifierSchemeCodeUnspsc190501.40171509': __('identifierSchemeCodeUnspsc190501.40171509'),
	'identifierSchemeCodeUnspsc190501.40171510': __('identifierSchemeCodeUnspsc190501.40171510'),
	'identifierSchemeCodeUnspsc190501.40171511': __('identifierSchemeCodeUnspsc190501.40171511'),
	'identifierSchemeCodeUnspsc190501.40171512': __('identifierSchemeCodeUnspsc190501.40171512'),
	'identifierSchemeCodeUnspsc190501.40171513': __('identifierSchemeCodeUnspsc190501.40171513'),
	'identifierSchemeCodeUnspsc190501.40171514': __('identifierSchemeCodeUnspsc190501.40171514'),
	'identifierSchemeCodeUnspsc190501.40171515': __('identifierSchemeCodeUnspsc190501.40171515'),
	'identifierSchemeCodeUnspsc190501.40171516': __('identifierSchemeCodeUnspsc190501.40171516'),
	'identifierSchemeCodeUnspsc190501.40171517': __('identifierSchemeCodeUnspsc190501.40171517'),
	'identifierSchemeCodeUnspsc190501.40171518': __('identifierSchemeCodeUnspsc190501.40171518'),
	'identifierSchemeCodeUnspsc190501.40171519': __('identifierSchemeCodeUnspsc190501.40171519'),
	'identifierSchemeCodeUnspsc190501.40171520': __('identifierSchemeCodeUnspsc190501.40171520'),
	'identifierSchemeCodeUnspsc190501.40171521': __('identifierSchemeCodeUnspsc190501.40171521'),
	'identifierSchemeCodeUnspsc190501.40171522': __('identifierSchemeCodeUnspsc190501.40171522'),
	'identifierSchemeCodeUnspsc190501.40171523': __('identifierSchemeCodeUnspsc190501.40171523'),
	'identifierSchemeCodeUnspsc190501.40171524': __('identifierSchemeCodeUnspsc190501.40171524'),
	'identifierSchemeCodeUnspsc190501.40171525': __('identifierSchemeCodeUnspsc190501.40171525'),
	'identifierSchemeCodeUnspsc190501.40171526': __('identifierSchemeCodeUnspsc190501.40171526'),
	'identifierSchemeCodeUnspsc190501.40171527': __('identifierSchemeCodeUnspsc190501.40171527'),
	'identifierSchemeCodeUnspsc190501.40171600': __('identifierSchemeCodeUnspsc190501.40171600'),
	'identifierSchemeCodeUnspsc190501.40171601': __('identifierSchemeCodeUnspsc190501.40171601'),
	'identifierSchemeCodeUnspsc190501.40171602': __('identifierSchemeCodeUnspsc190501.40171602'),
	'identifierSchemeCodeUnspsc190501.40171603': __('identifierSchemeCodeUnspsc190501.40171603'),
	'identifierSchemeCodeUnspsc190501.40171604': __('identifierSchemeCodeUnspsc190501.40171604'),
	'identifierSchemeCodeUnspsc190501.40171605': __('identifierSchemeCodeUnspsc190501.40171605'),
	'identifierSchemeCodeUnspsc190501.40171606': __('identifierSchemeCodeUnspsc190501.40171606'),
	'identifierSchemeCodeUnspsc190501.40171607': __('identifierSchemeCodeUnspsc190501.40171607'),
	'identifierSchemeCodeUnspsc190501.40171608': __('identifierSchemeCodeUnspsc190501.40171608'),
	'identifierSchemeCodeUnspsc190501.40171609': __('identifierSchemeCodeUnspsc190501.40171609'),
	'identifierSchemeCodeUnspsc190501.40171610': __('identifierSchemeCodeUnspsc190501.40171610'),
	'identifierSchemeCodeUnspsc190501.40171611': __('identifierSchemeCodeUnspsc190501.40171611'),
	'identifierSchemeCodeUnspsc190501.40171612': __('identifierSchemeCodeUnspsc190501.40171612'),
	'identifierSchemeCodeUnspsc190501.40171613': __('identifierSchemeCodeUnspsc190501.40171613'),
	'identifierSchemeCodeUnspsc190501.40171614': __('identifierSchemeCodeUnspsc190501.40171614'),
	'identifierSchemeCodeUnspsc190501.40171615': __('identifierSchemeCodeUnspsc190501.40171615'),
	'identifierSchemeCodeUnspsc190501.40171616': __('identifierSchemeCodeUnspsc190501.40171616'),
	'identifierSchemeCodeUnspsc190501.40171617': __('identifierSchemeCodeUnspsc190501.40171617'),
	'identifierSchemeCodeUnspsc190501.40171618': __('identifierSchemeCodeUnspsc190501.40171618'),
	'identifierSchemeCodeUnspsc190501.40171619': __('identifierSchemeCodeUnspsc190501.40171619'),
	'identifierSchemeCodeUnspsc190501.40171620': __('identifierSchemeCodeUnspsc190501.40171620'),
	'identifierSchemeCodeUnspsc190501.40171621': __('identifierSchemeCodeUnspsc190501.40171621'),
	'identifierSchemeCodeUnspsc190501.40171622': __('identifierSchemeCodeUnspsc190501.40171622'),
	'identifierSchemeCodeUnspsc190501.40171623': __('identifierSchemeCodeUnspsc190501.40171623'),
	'identifierSchemeCodeUnspsc190501.40171624': __('identifierSchemeCodeUnspsc190501.40171624'),
	'identifierSchemeCodeUnspsc190501.40171625': __('identifierSchemeCodeUnspsc190501.40171625'),
	'identifierSchemeCodeUnspsc190501.40171626': __('identifierSchemeCodeUnspsc190501.40171626'),
	'identifierSchemeCodeUnspsc190501.40171700': __('identifierSchemeCodeUnspsc190501.40171700'),
	'identifierSchemeCodeUnspsc190501.40171701': __('identifierSchemeCodeUnspsc190501.40171701'),
	'identifierSchemeCodeUnspsc190501.40171702': __('identifierSchemeCodeUnspsc190501.40171702'),
	'identifierSchemeCodeUnspsc190501.40171703': __('identifierSchemeCodeUnspsc190501.40171703'),
	'identifierSchemeCodeUnspsc190501.40171704': __('identifierSchemeCodeUnspsc190501.40171704'),
	'identifierSchemeCodeUnspsc190501.40171705': __('identifierSchemeCodeUnspsc190501.40171705'),
	'identifierSchemeCodeUnspsc190501.40171706': __('identifierSchemeCodeUnspsc190501.40171706'),
	'identifierSchemeCodeUnspsc190501.40171707': __('identifierSchemeCodeUnspsc190501.40171707'),
	'identifierSchemeCodeUnspsc190501.40171708': __('identifierSchemeCodeUnspsc190501.40171708'),
	'identifierSchemeCodeUnspsc190501.40171709': __('identifierSchemeCodeUnspsc190501.40171709'),
	'identifierSchemeCodeUnspsc190501.40171710': __('identifierSchemeCodeUnspsc190501.40171710'),
	'identifierSchemeCodeUnspsc190501.40171711': __('identifierSchemeCodeUnspsc190501.40171711'),
	'identifierSchemeCodeUnspsc190501.40171800': __('identifierSchemeCodeUnspsc190501.40171800'),
	'identifierSchemeCodeUnspsc190501.40171801': __('identifierSchemeCodeUnspsc190501.40171801'),
	'identifierSchemeCodeUnspsc190501.40171802': __('identifierSchemeCodeUnspsc190501.40171802'),
	'identifierSchemeCodeUnspsc190501.40171803': __('identifierSchemeCodeUnspsc190501.40171803'),
	'identifierSchemeCodeUnspsc190501.40171900': __('identifierSchemeCodeUnspsc190501.40171900'),
	'identifierSchemeCodeUnspsc190501.40171901': __('identifierSchemeCodeUnspsc190501.40171901'),
	'identifierSchemeCodeUnspsc190501.40171902': __('identifierSchemeCodeUnspsc190501.40171902'),
	'identifierSchemeCodeUnspsc190501.40171903': __('identifierSchemeCodeUnspsc190501.40171903'),
	'identifierSchemeCodeUnspsc190501.40171904': __('identifierSchemeCodeUnspsc190501.40171904'),
	'identifierSchemeCodeUnspsc190501.40171905': __('identifierSchemeCodeUnspsc190501.40171905'),
	'identifierSchemeCodeUnspsc190501.40171906': __('identifierSchemeCodeUnspsc190501.40171906'),
	'identifierSchemeCodeUnspsc190501.40171907': __('identifierSchemeCodeUnspsc190501.40171907'),
	'identifierSchemeCodeUnspsc190501.40172000': __('identifierSchemeCodeUnspsc190501.40172000'),
	'identifierSchemeCodeUnspsc190501.40172001': __('identifierSchemeCodeUnspsc190501.40172001'),
	'identifierSchemeCodeUnspsc190501.40172002': __('identifierSchemeCodeUnspsc190501.40172002'),
	'identifierSchemeCodeUnspsc190501.40172003': __('identifierSchemeCodeUnspsc190501.40172003'),
	'identifierSchemeCodeUnspsc190501.40172004': __('identifierSchemeCodeUnspsc190501.40172004'),
	'identifierSchemeCodeUnspsc190501.40172005': __('identifierSchemeCodeUnspsc190501.40172005'),
	'identifierSchemeCodeUnspsc190501.40172006': __('identifierSchemeCodeUnspsc190501.40172006'),
	'identifierSchemeCodeUnspsc190501.40172007': __('identifierSchemeCodeUnspsc190501.40172007'),
	'identifierSchemeCodeUnspsc190501.40172008': __('identifierSchemeCodeUnspsc190501.40172008'),
	'identifierSchemeCodeUnspsc190501.40172100': __('identifierSchemeCodeUnspsc190501.40172100'),
	'identifierSchemeCodeUnspsc190501.40172101': __('identifierSchemeCodeUnspsc190501.40172101'),
	'identifierSchemeCodeUnspsc190501.40172102': __('identifierSchemeCodeUnspsc190501.40172102'),
	'identifierSchemeCodeUnspsc190501.40172103': __('identifierSchemeCodeUnspsc190501.40172103'),
	'identifierSchemeCodeUnspsc190501.40172104': __('identifierSchemeCodeUnspsc190501.40172104'),
	'identifierSchemeCodeUnspsc190501.40172200': __('identifierSchemeCodeUnspsc190501.40172200'),
	'identifierSchemeCodeUnspsc190501.40172201': __('identifierSchemeCodeUnspsc190501.40172201'),
	'identifierSchemeCodeUnspsc190501.40172202': __('identifierSchemeCodeUnspsc190501.40172202'),
	'identifierSchemeCodeUnspsc190501.40172203': __('identifierSchemeCodeUnspsc190501.40172203'),
	'identifierSchemeCodeUnspsc190501.40172204': __('identifierSchemeCodeUnspsc190501.40172204'),
	'identifierSchemeCodeUnspsc190501.40172205': __('identifierSchemeCodeUnspsc190501.40172205'),
	'identifierSchemeCodeUnspsc190501.40172206': __('identifierSchemeCodeUnspsc190501.40172206'),
	'identifierSchemeCodeUnspsc190501.40172207': __('identifierSchemeCodeUnspsc190501.40172207'),
	'identifierSchemeCodeUnspsc190501.40172300': __('identifierSchemeCodeUnspsc190501.40172300'),
	'identifierSchemeCodeUnspsc190501.40172301': __('identifierSchemeCodeUnspsc190501.40172301'),
	'identifierSchemeCodeUnspsc190501.40172302': __('identifierSchemeCodeUnspsc190501.40172302'),
	'identifierSchemeCodeUnspsc190501.40172303': __('identifierSchemeCodeUnspsc190501.40172303'),
	'identifierSchemeCodeUnspsc190501.40172304': __('identifierSchemeCodeUnspsc190501.40172304'),
	'identifierSchemeCodeUnspsc190501.40172305': __('identifierSchemeCodeUnspsc190501.40172305'),
	'identifierSchemeCodeUnspsc190501.40172306': __('identifierSchemeCodeUnspsc190501.40172306'),
	'identifierSchemeCodeUnspsc190501.40172307': __('identifierSchemeCodeUnspsc190501.40172307'),
	'identifierSchemeCodeUnspsc190501.40172308': __('identifierSchemeCodeUnspsc190501.40172308'),
	'identifierSchemeCodeUnspsc190501.40172309': __('identifierSchemeCodeUnspsc190501.40172309'),
	'identifierSchemeCodeUnspsc190501.40172310': __('identifierSchemeCodeUnspsc190501.40172310'),
	'identifierSchemeCodeUnspsc190501.40172311': __('identifierSchemeCodeUnspsc190501.40172311'),
	'identifierSchemeCodeUnspsc190501.40172312': __('identifierSchemeCodeUnspsc190501.40172312'),
	'identifierSchemeCodeUnspsc190501.40172313': __('identifierSchemeCodeUnspsc190501.40172313'),
	'identifierSchemeCodeUnspsc190501.40172314': __('identifierSchemeCodeUnspsc190501.40172314'),
	'identifierSchemeCodeUnspsc190501.40172315': __('identifierSchemeCodeUnspsc190501.40172315'),
	'identifierSchemeCodeUnspsc190501.40172400': __('identifierSchemeCodeUnspsc190501.40172400'),
	'identifierSchemeCodeUnspsc190501.40172401': __('identifierSchemeCodeUnspsc190501.40172401'),
	'identifierSchemeCodeUnspsc190501.40172402': __('identifierSchemeCodeUnspsc190501.40172402'),
	'identifierSchemeCodeUnspsc190501.40172403': __('identifierSchemeCodeUnspsc190501.40172403'),
	'identifierSchemeCodeUnspsc190501.40172404': __('identifierSchemeCodeUnspsc190501.40172404'),
	'identifierSchemeCodeUnspsc190501.40172405': __('identifierSchemeCodeUnspsc190501.40172405'),
	'identifierSchemeCodeUnspsc190501.40172406': __('identifierSchemeCodeUnspsc190501.40172406'),
	'identifierSchemeCodeUnspsc190501.40172407': __('identifierSchemeCodeUnspsc190501.40172407'),
	'identifierSchemeCodeUnspsc190501.40172408': __('identifierSchemeCodeUnspsc190501.40172408'),
	'identifierSchemeCodeUnspsc190501.40172409': __('identifierSchemeCodeUnspsc190501.40172409'),
	'identifierSchemeCodeUnspsc190501.40172410': __('identifierSchemeCodeUnspsc190501.40172410'),
	'identifierSchemeCodeUnspsc190501.40172411': __('identifierSchemeCodeUnspsc190501.40172411'),
	'identifierSchemeCodeUnspsc190501.40172412': __('identifierSchemeCodeUnspsc190501.40172412'),
	'identifierSchemeCodeUnspsc190501.40172413': __('identifierSchemeCodeUnspsc190501.40172413'),
	'identifierSchemeCodeUnspsc190501.40172414': __('identifierSchemeCodeUnspsc190501.40172414'),
	'identifierSchemeCodeUnspsc190501.40172500': __('identifierSchemeCodeUnspsc190501.40172500'),
	'identifierSchemeCodeUnspsc190501.40172501': __('identifierSchemeCodeUnspsc190501.40172501'),
	'identifierSchemeCodeUnspsc190501.40172502': __('identifierSchemeCodeUnspsc190501.40172502'),
	'identifierSchemeCodeUnspsc190501.40172503': __('identifierSchemeCodeUnspsc190501.40172503'),
	'identifierSchemeCodeUnspsc190501.40172504': __('identifierSchemeCodeUnspsc190501.40172504'),
	'identifierSchemeCodeUnspsc190501.40172505': __('identifierSchemeCodeUnspsc190501.40172505'),
	'identifierSchemeCodeUnspsc190501.40172506': __('identifierSchemeCodeUnspsc190501.40172506'),
	'identifierSchemeCodeUnspsc190501.40172507': __('identifierSchemeCodeUnspsc190501.40172507'),
	'identifierSchemeCodeUnspsc190501.40172508': __('identifierSchemeCodeUnspsc190501.40172508'),
	'identifierSchemeCodeUnspsc190501.40172509': __('identifierSchemeCodeUnspsc190501.40172509'),
	'identifierSchemeCodeUnspsc190501.40172510': __('identifierSchemeCodeUnspsc190501.40172510'),
	'identifierSchemeCodeUnspsc190501.40172511': __('identifierSchemeCodeUnspsc190501.40172511'),
	'identifierSchemeCodeUnspsc190501.40172512': __('identifierSchemeCodeUnspsc190501.40172512'),
	'identifierSchemeCodeUnspsc190501.40172513': __('identifierSchemeCodeUnspsc190501.40172513'),
	'identifierSchemeCodeUnspsc190501.40172514': __('identifierSchemeCodeUnspsc190501.40172514'),
	'identifierSchemeCodeUnspsc190501.40172515': __('identifierSchemeCodeUnspsc190501.40172515'),
	'identifierSchemeCodeUnspsc190501.40172516': __('identifierSchemeCodeUnspsc190501.40172516'),
	'identifierSchemeCodeUnspsc190501.40172517': __('identifierSchemeCodeUnspsc190501.40172517'),
	'identifierSchemeCodeUnspsc190501.40172518': __('identifierSchemeCodeUnspsc190501.40172518'),
	'identifierSchemeCodeUnspsc190501.40172519': __('identifierSchemeCodeUnspsc190501.40172519'),
	'identifierSchemeCodeUnspsc190501.40172520': __('identifierSchemeCodeUnspsc190501.40172520'),
	'identifierSchemeCodeUnspsc190501.40172521': __('identifierSchemeCodeUnspsc190501.40172521'),
	'identifierSchemeCodeUnspsc190501.40172522': __('identifierSchemeCodeUnspsc190501.40172522'),
	'identifierSchemeCodeUnspsc190501.40172523': __('identifierSchemeCodeUnspsc190501.40172523'),
	'identifierSchemeCodeUnspsc190501.40172600': __('identifierSchemeCodeUnspsc190501.40172600'),
	'identifierSchemeCodeUnspsc190501.40172601': __('identifierSchemeCodeUnspsc190501.40172601'),
	'identifierSchemeCodeUnspsc190501.40172602': __('identifierSchemeCodeUnspsc190501.40172602'),
	'identifierSchemeCodeUnspsc190501.40172603': __('identifierSchemeCodeUnspsc190501.40172603'),
	'identifierSchemeCodeUnspsc190501.40172604': __('identifierSchemeCodeUnspsc190501.40172604'),
	'identifierSchemeCodeUnspsc190501.40172605': __('identifierSchemeCodeUnspsc190501.40172605'),
	'identifierSchemeCodeUnspsc190501.40172606': __('identifierSchemeCodeUnspsc190501.40172606'),
	'identifierSchemeCodeUnspsc190501.40172607': __('identifierSchemeCodeUnspsc190501.40172607'),
	'identifierSchemeCodeUnspsc190501.40172608': __('identifierSchemeCodeUnspsc190501.40172608'),
	'identifierSchemeCodeUnspsc190501.40172609': __('identifierSchemeCodeUnspsc190501.40172609'),
	'identifierSchemeCodeUnspsc190501.40172610': __('identifierSchemeCodeUnspsc190501.40172610'),
	'identifierSchemeCodeUnspsc190501.40172611': __('identifierSchemeCodeUnspsc190501.40172611'),
	'identifierSchemeCodeUnspsc190501.40172612': __('identifierSchemeCodeUnspsc190501.40172612'),
	'identifierSchemeCodeUnspsc190501.40172613': __('identifierSchemeCodeUnspsc190501.40172613'),
	'identifierSchemeCodeUnspsc190501.40172700': __('identifierSchemeCodeUnspsc190501.40172700'),
	'identifierSchemeCodeUnspsc190501.40172701': __('identifierSchemeCodeUnspsc190501.40172701'),
	'identifierSchemeCodeUnspsc190501.40172702': __('identifierSchemeCodeUnspsc190501.40172702'),
	'identifierSchemeCodeUnspsc190501.40172703': __('identifierSchemeCodeUnspsc190501.40172703'),
	'identifierSchemeCodeUnspsc190501.40172704': __('identifierSchemeCodeUnspsc190501.40172704'),
	'identifierSchemeCodeUnspsc190501.40172705': __('identifierSchemeCodeUnspsc190501.40172705'),
	'identifierSchemeCodeUnspsc190501.40172706': __('identifierSchemeCodeUnspsc190501.40172706'),
	'identifierSchemeCodeUnspsc190501.40172707': __('identifierSchemeCodeUnspsc190501.40172707'),
	'identifierSchemeCodeUnspsc190501.40172708': __('identifierSchemeCodeUnspsc190501.40172708'),
	'identifierSchemeCodeUnspsc190501.40172709': __('identifierSchemeCodeUnspsc190501.40172709'),
	'identifierSchemeCodeUnspsc190501.40172710': __('identifierSchemeCodeUnspsc190501.40172710'),
	'identifierSchemeCodeUnspsc190501.40172711': __('identifierSchemeCodeUnspsc190501.40172711'),
	'identifierSchemeCodeUnspsc190501.40172712': __('identifierSchemeCodeUnspsc190501.40172712'),
	'identifierSchemeCodeUnspsc190501.40172713': __('identifierSchemeCodeUnspsc190501.40172713'),
	'identifierSchemeCodeUnspsc190501.40172800': __('identifierSchemeCodeUnspsc190501.40172800'),
	'identifierSchemeCodeUnspsc190501.40172801': __('identifierSchemeCodeUnspsc190501.40172801'),
	'identifierSchemeCodeUnspsc190501.40172802': __('identifierSchemeCodeUnspsc190501.40172802'),
	'identifierSchemeCodeUnspsc190501.40172803': __('identifierSchemeCodeUnspsc190501.40172803'),
	'identifierSchemeCodeUnspsc190501.40172804': __('identifierSchemeCodeUnspsc190501.40172804'),
	'identifierSchemeCodeUnspsc190501.40172805': __('identifierSchemeCodeUnspsc190501.40172805'),
	'identifierSchemeCodeUnspsc190501.40172806': __('identifierSchemeCodeUnspsc190501.40172806'),
	'identifierSchemeCodeUnspsc190501.40172807': __('identifierSchemeCodeUnspsc190501.40172807'),
	'identifierSchemeCodeUnspsc190501.40172808': __('identifierSchemeCodeUnspsc190501.40172808'),
	'identifierSchemeCodeUnspsc190501.40172809': __('identifierSchemeCodeUnspsc190501.40172809'),
	'identifierSchemeCodeUnspsc190501.40172810': __('identifierSchemeCodeUnspsc190501.40172810'),
	'identifierSchemeCodeUnspsc190501.40172811': __('identifierSchemeCodeUnspsc190501.40172811'),
	'identifierSchemeCodeUnspsc190501.40172812': __('identifierSchemeCodeUnspsc190501.40172812'),
	'identifierSchemeCodeUnspsc190501.40172813': __('identifierSchemeCodeUnspsc190501.40172813'),
	'identifierSchemeCodeUnspsc190501.40172814': __('identifierSchemeCodeUnspsc190501.40172814'),
	'identifierSchemeCodeUnspsc190501.40172815': __('identifierSchemeCodeUnspsc190501.40172815'),
	'identifierSchemeCodeUnspsc190501.40172900': __('identifierSchemeCodeUnspsc190501.40172900'),
	'identifierSchemeCodeUnspsc190501.40172901': __('identifierSchemeCodeUnspsc190501.40172901'),
	'identifierSchemeCodeUnspsc190501.40172902': __('identifierSchemeCodeUnspsc190501.40172902'),
	'identifierSchemeCodeUnspsc190501.40172903': __('identifierSchemeCodeUnspsc190501.40172903'),
	'identifierSchemeCodeUnspsc190501.40172904': __('identifierSchemeCodeUnspsc190501.40172904'),
	'identifierSchemeCodeUnspsc190501.40172905': __('identifierSchemeCodeUnspsc190501.40172905'),
	'identifierSchemeCodeUnspsc190501.40172906': __('identifierSchemeCodeUnspsc190501.40172906'),
	'identifierSchemeCodeUnspsc190501.40172907': __('identifierSchemeCodeUnspsc190501.40172907'),
	'identifierSchemeCodeUnspsc190501.40172908': __('identifierSchemeCodeUnspsc190501.40172908'),
	'identifierSchemeCodeUnspsc190501.40172909': __('identifierSchemeCodeUnspsc190501.40172909'),
	'identifierSchemeCodeUnspsc190501.40172910': __('identifierSchemeCodeUnspsc190501.40172910'),
	'identifierSchemeCodeUnspsc190501.40172911': __('identifierSchemeCodeUnspsc190501.40172911'),
	'identifierSchemeCodeUnspsc190501.40173000': __('identifierSchemeCodeUnspsc190501.40173000'),
	'identifierSchemeCodeUnspsc190501.40173001': __('identifierSchemeCodeUnspsc190501.40173001'),
	'identifierSchemeCodeUnspsc190501.40173002': __('identifierSchemeCodeUnspsc190501.40173002'),
	'identifierSchemeCodeUnspsc190501.40173003': __('identifierSchemeCodeUnspsc190501.40173003'),
	'identifierSchemeCodeUnspsc190501.40173004': __('identifierSchemeCodeUnspsc190501.40173004'),
	'identifierSchemeCodeUnspsc190501.40173005': __('identifierSchemeCodeUnspsc190501.40173005'),
	'identifierSchemeCodeUnspsc190501.40173006': __('identifierSchemeCodeUnspsc190501.40173006'),
	'identifierSchemeCodeUnspsc190501.40173007': __('identifierSchemeCodeUnspsc190501.40173007'),
	'identifierSchemeCodeUnspsc190501.40173008': __('identifierSchemeCodeUnspsc190501.40173008'),
	'identifierSchemeCodeUnspsc190501.40173009': __('identifierSchemeCodeUnspsc190501.40173009'),
	'identifierSchemeCodeUnspsc190501.40173100': __('identifierSchemeCodeUnspsc190501.40173100'),
	'identifierSchemeCodeUnspsc190501.40173101': __('identifierSchemeCodeUnspsc190501.40173101'),
	'identifierSchemeCodeUnspsc190501.40173102': __('identifierSchemeCodeUnspsc190501.40173102'),
	'identifierSchemeCodeUnspsc190501.40173103': __('identifierSchemeCodeUnspsc190501.40173103'),
	'identifierSchemeCodeUnspsc190501.40173104': __('identifierSchemeCodeUnspsc190501.40173104'),
	'identifierSchemeCodeUnspsc190501.40173200': __('identifierSchemeCodeUnspsc190501.40173200'),
	'identifierSchemeCodeUnspsc190501.40173201': __('identifierSchemeCodeUnspsc190501.40173201'),
	'identifierSchemeCodeUnspsc190501.40173202': __('identifierSchemeCodeUnspsc190501.40173202'),
	'identifierSchemeCodeUnspsc190501.40173203': __('identifierSchemeCodeUnspsc190501.40173203'),
	'identifierSchemeCodeUnspsc190501.40173300': __('identifierSchemeCodeUnspsc190501.40173300'),
	'identifierSchemeCodeUnspsc190501.40173301': __('identifierSchemeCodeUnspsc190501.40173301'),
	'identifierSchemeCodeUnspsc190501.40173302': __('identifierSchemeCodeUnspsc190501.40173302'),
	'identifierSchemeCodeUnspsc190501.40173303': __('identifierSchemeCodeUnspsc190501.40173303'),
	'identifierSchemeCodeUnspsc190501.40173304': __('identifierSchemeCodeUnspsc190501.40173304'),
	'identifierSchemeCodeUnspsc190501.40173305': __('identifierSchemeCodeUnspsc190501.40173305'),
	'identifierSchemeCodeUnspsc190501.40173306': __('identifierSchemeCodeUnspsc190501.40173306'),
	'identifierSchemeCodeUnspsc190501.40173307': __('identifierSchemeCodeUnspsc190501.40173307'),
	'identifierSchemeCodeUnspsc190501.40173308': __('identifierSchemeCodeUnspsc190501.40173308'),
	'identifierSchemeCodeUnspsc190501.40173309': __('identifierSchemeCodeUnspsc190501.40173309'),
	'identifierSchemeCodeUnspsc190501.40173310': __('identifierSchemeCodeUnspsc190501.40173310'),
	'identifierSchemeCodeUnspsc190501.40173311': __('identifierSchemeCodeUnspsc190501.40173311'),
	'identifierSchemeCodeUnspsc190501.40173400': __('identifierSchemeCodeUnspsc190501.40173400'),
	'identifierSchemeCodeUnspsc190501.40173401': __('identifierSchemeCodeUnspsc190501.40173401'),
	'identifierSchemeCodeUnspsc190501.40173402': __('identifierSchemeCodeUnspsc190501.40173402'),
	'identifierSchemeCodeUnspsc190501.40173403': __('identifierSchemeCodeUnspsc190501.40173403'),
	'identifierSchemeCodeUnspsc190501.40173404': __('identifierSchemeCodeUnspsc190501.40173404'),
	'identifierSchemeCodeUnspsc190501.40173405': __('identifierSchemeCodeUnspsc190501.40173405'),
	'identifierSchemeCodeUnspsc190501.40173406': __('identifierSchemeCodeUnspsc190501.40173406'),
	'identifierSchemeCodeUnspsc190501.40173407': __('identifierSchemeCodeUnspsc190501.40173407'),
	'identifierSchemeCodeUnspsc190501.40173408': __('identifierSchemeCodeUnspsc190501.40173408'),
	'identifierSchemeCodeUnspsc190501.40173409': __('identifierSchemeCodeUnspsc190501.40173409'),
	'identifierSchemeCodeUnspsc190501.40173500': __('identifierSchemeCodeUnspsc190501.40173500'),
	'identifierSchemeCodeUnspsc190501.40173501': __('identifierSchemeCodeUnspsc190501.40173501'),
	'identifierSchemeCodeUnspsc190501.40173502': __('identifierSchemeCodeUnspsc190501.40173502'),
	'identifierSchemeCodeUnspsc190501.40173503': __('identifierSchemeCodeUnspsc190501.40173503'),
	'identifierSchemeCodeUnspsc190501.40173504': __('identifierSchemeCodeUnspsc190501.40173504'),
	'identifierSchemeCodeUnspsc190501.40173505': __('identifierSchemeCodeUnspsc190501.40173505'),
	'identifierSchemeCodeUnspsc190501.40173506': __('identifierSchemeCodeUnspsc190501.40173506'),
	'identifierSchemeCodeUnspsc190501.40173507': __('identifierSchemeCodeUnspsc190501.40173507'),
	'identifierSchemeCodeUnspsc190501.40173508': __('identifierSchemeCodeUnspsc190501.40173508'),
	'identifierSchemeCodeUnspsc190501.40173509': __('identifierSchemeCodeUnspsc190501.40173509'),
	'identifierSchemeCodeUnspsc190501.40173510': __('identifierSchemeCodeUnspsc190501.40173510'),
	'identifierSchemeCodeUnspsc190501.40173511': __('identifierSchemeCodeUnspsc190501.40173511'),
	'identifierSchemeCodeUnspsc190501.40173512': __('identifierSchemeCodeUnspsc190501.40173512'),
	'identifierSchemeCodeUnspsc190501.40173513': __('identifierSchemeCodeUnspsc190501.40173513'),
	'identifierSchemeCodeUnspsc190501.40173514': __('identifierSchemeCodeUnspsc190501.40173514'),
	'identifierSchemeCodeUnspsc190501.40173515': __('identifierSchemeCodeUnspsc190501.40173515'),
	'identifierSchemeCodeUnspsc190501.40173600': __('identifierSchemeCodeUnspsc190501.40173600'),
	'identifierSchemeCodeUnspsc190501.40173601': __('identifierSchemeCodeUnspsc190501.40173601'),
	'identifierSchemeCodeUnspsc190501.40173602': __('identifierSchemeCodeUnspsc190501.40173602'),
	'identifierSchemeCodeUnspsc190501.40173603': __('identifierSchemeCodeUnspsc190501.40173603'),
	'identifierSchemeCodeUnspsc190501.40173604': __('identifierSchemeCodeUnspsc190501.40173604'),
	'identifierSchemeCodeUnspsc190501.40173605': __('identifierSchemeCodeUnspsc190501.40173605'),
	'identifierSchemeCodeUnspsc190501.40173606': __('identifierSchemeCodeUnspsc190501.40173606'),
	'identifierSchemeCodeUnspsc190501.40173607': __('identifierSchemeCodeUnspsc190501.40173607'),
	'identifierSchemeCodeUnspsc190501.40173608': __('identifierSchemeCodeUnspsc190501.40173608'),
	'identifierSchemeCodeUnspsc190501.40173609': __('identifierSchemeCodeUnspsc190501.40173609'),
	'identifierSchemeCodeUnspsc190501.40173610': __('identifierSchemeCodeUnspsc190501.40173610'),
	'identifierSchemeCodeUnspsc190501.40173611': __('identifierSchemeCodeUnspsc190501.40173611'),
	'identifierSchemeCodeUnspsc190501.40173612': __('identifierSchemeCodeUnspsc190501.40173612'),
	'identifierSchemeCodeUnspsc190501.40173613': __('identifierSchemeCodeUnspsc190501.40173613'),
	'identifierSchemeCodeUnspsc190501.40173614': __('identifierSchemeCodeUnspsc190501.40173614'),
	'identifierSchemeCodeUnspsc190501.40173615': __('identifierSchemeCodeUnspsc190501.40173615'),
	'identifierSchemeCodeUnspsc190501.40173700': __('identifierSchemeCodeUnspsc190501.40173700'),
	'identifierSchemeCodeUnspsc190501.40173701': __('identifierSchemeCodeUnspsc190501.40173701'),
	'identifierSchemeCodeUnspsc190501.40173702': __('identifierSchemeCodeUnspsc190501.40173702'),
	'identifierSchemeCodeUnspsc190501.40173703': __('identifierSchemeCodeUnspsc190501.40173703'),
	'identifierSchemeCodeUnspsc190501.40173704': __('identifierSchemeCodeUnspsc190501.40173704'),
	'identifierSchemeCodeUnspsc190501.40173705': __('identifierSchemeCodeUnspsc190501.40173705'),
	'identifierSchemeCodeUnspsc190501.40173706': __('identifierSchemeCodeUnspsc190501.40173706'),
	'identifierSchemeCodeUnspsc190501.40173707': __('identifierSchemeCodeUnspsc190501.40173707'),
	'identifierSchemeCodeUnspsc190501.40173708': __('identifierSchemeCodeUnspsc190501.40173708'),
	'identifierSchemeCodeUnspsc190501.40173709': __('identifierSchemeCodeUnspsc190501.40173709'),
	'identifierSchemeCodeUnspsc190501.40173710': __('identifierSchemeCodeUnspsc190501.40173710'),
	'identifierSchemeCodeUnspsc190501.40173711': __('identifierSchemeCodeUnspsc190501.40173711'),
	'identifierSchemeCodeUnspsc190501.40173800': __('identifierSchemeCodeUnspsc190501.40173800'),
	'identifierSchemeCodeUnspsc190501.40173801': __('identifierSchemeCodeUnspsc190501.40173801'),
	'identifierSchemeCodeUnspsc190501.40173802': __('identifierSchemeCodeUnspsc190501.40173802'),
	'identifierSchemeCodeUnspsc190501.40173803': __('identifierSchemeCodeUnspsc190501.40173803'),
	'identifierSchemeCodeUnspsc190501.40173804': __('identifierSchemeCodeUnspsc190501.40173804'),
	'identifierSchemeCodeUnspsc190501.40173805': __('identifierSchemeCodeUnspsc190501.40173805'),
	'identifierSchemeCodeUnspsc190501.40173806': __('identifierSchemeCodeUnspsc190501.40173806'),
	'identifierSchemeCodeUnspsc190501.40173807': __('identifierSchemeCodeUnspsc190501.40173807'),
	'identifierSchemeCodeUnspsc190501.40173808': __('identifierSchemeCodeUnspsc190501.40173808'),
	'identifierSchemeCodeUnspsc190501.40173900': __('identifierSchemeCodeUnspsc190501.40173900'),
	'identifierSchemeCodeUnspsc190501.40173901': __('identifierSchemeCodeUnspsc190501.40173901'),
	'identifierSchemeCodeUnspsc190501.40173902': __('identifierSchemeCodeUnspsc190501.40173902'),
	'identifierSchemeCodeUnspsc190501.40173903': __('identifierSchemeCodeUnspsc190501.40173903'),
	'identifierSchemeCodeUnspsc190501.40173904': __('identifierSchemeCodeUnspsc190501.40173904'),
	'identifierSchemeCodeUnspsc190501.40173905': __('identifierSchemeCodeUnspsc190501.40173905'),
	'identifierSchemeCodeUnspsc190501.40173906': __('identifierSchemeCodeUnspsc190501.40173906'),
	'identifierSchemeCodeUnspsc190501.40173907': __('identifierSchemeCodeUnspsc190501.40173907'),
	'identifierSchemeCodeUnspsc190501.40173908': __('identifierSchemeCodeUnspsc190501.40173908'),
	'identifierSchemeCodeUnspsc190501.40173909': __('identifierSchemeCodeUnspsc190501.40173909'),
	'identifierSchemeCodeUnspsc190501.40173910': __('identifierSchemeCodeUnspsc190501.40173910'),
	'identifierSchemeCodeUnspsc190501.40174000': __('identifierSchemeCodeUnspsc190501.40174000'),
	'identifierSchemeCodeUnspsc190501.40174001': __('identifierSchemeCodeUnspsc190501.40174001'),
	'identifierSchemeCodeUnspsc190501.40174002': __('identifierSchemeCodeUnspsc190501.40174002'),
	'identifierSchemeCodeUnspsc190501.40174003': __('identifierSchemeCodeUnspsc190501.40174003'),
	'identifierSchemeCodeUnspsc190501.40174004': __('identifierSchemeCodeUnspsc190501.40174004'),
	'identifierSchemeCodeUnspsc190501.40174005': __('identifierSchemeCodeUnspsc190501.40174005'),
	'identifierSchemeCodeUnspsc190501.40174006': __('identifierSchemeCodeUnspsc190501.40174006'),
	'identifierSchemeCodeUnspsc190501.40174007': __('identifierSchemeCodeUnspsc190501.40174007'),
	'identifierSchemeCodeUnspsc190501.40174008': __('identifierSchemeCodeUnspsc190501.40174008'),
	'identifierSchemeCodeUnspsc190501.40174100': __('identifierSchemeCodeUnspsc190501.40174100'),
	'identifierSchemeCodeUnspsc190501.40174101': __('identifierSchemeCodeUnspsc190501.40174101'),
	'identifierSchemeCodeUnspsc190501.40174102': __('identifierSchemeCodeUnspsc190501.40174102'),
	'identifierSchemeCodeUnspsc190501.40174103': __('identifierSchemeCodeUnspsc190501.40174103'),
	'identifierSchemeCodeUnspsc190501.40174104': __('identifierSchemeCodeUnspsc190501.40174104'),
	'identifierSchemeCodeUnspsc190501.40174105': __('identifierSchemeCodeUnspsc190501.40174105'),
	'identifierSchemeCodeUnspsc190501.40174106': __('identifierSchemeCodeUnspsc190501.40174106'),
	'identifierSchemeCodeUnspsc190501.40174107': __('identifierSchemeCodeUnspsc190501.40174107'),
	'identifierSchemeCodeUnspsc190501.40174108': __('identifierSchemeCodeUnspsc190501.40174108'),
	'identifierSchemeCodeUnspsc190501.40174200': __('identifierSchemeCodeUnspsc190501.40174200'),
	'identifierSchemeCodeUnspsc190501.40174201': __('identifierSchemeCodeUnspsc190501.40174201'),
	'identifierSchemeCodeUnspsc190501.40174202': __('identifierSchemeCodeUnspsc190501.40174202'),
	'identifierSchemeCodeUnspsc190501.40174203': __('identifierSchemeCodeUnspsc190501.40174203'),
	'identifierSchemeCodeUnspsc190501.40174300': __('identifierSchemeCodeUnspsc190501.40174300'),
	'identifierSchemeCodeUnspsc190501.40174301': __('identifierSchemeCodeUnspsc190501.40174301'),
	'identifierSchemeCodeUnspsc190501.40174302': __('identifierSchemeCodeUnspsc190501.40174302'),
	'identifierSchemeCodeUnspsc190501.40174303': __('identifierSchemeCodeUnspsc190501.40174303'),
	'identifierSchemeCodeUnspsc190501.40174304': __('identifierSchemeCodeUnspsc190501.40174304'),
	'identifierSchemeCodeUnspsc190501.40174305': __('identifierSchemeCodeUnspsc190501.40174305'),
	'identifierSchemeCodeUnspsc190501.40174306': __('identifierSchemeCodeUnspsc190501.40174306'),
	'identifierSchemeCodeUnspsc190501.40174307': __('identifierSchemeCodeUnspsc190501.40174307'),
	'identifierSchemeCodeUnspsc190501.40174308': __('identifierSchemeCodeUnspsc190501.40174308'),
	'identifierSchemeCodeUnspsc190501.40174309': __('identifierSchemeCodeUnspsc190501.40174309'),
	'identifierSchemeCodeUnspsc190501.40174310': __('identifierSchemeCodeUnspsc190501.40174310'),
	'identifierSchemeCodeUnspsc190501.40174400': __('identifierSchemeCodeUnspsc190501.40174400'),
	'identifierSchemeCodeUnspsc190501.40174401': __('identifierSchemeCodeUnspsc190501.40174401'),
	'identifierSchemeCodeUnspsc190501.40174402': __('identifierSchemeCodeUnspsc190501.40174402'),
	'identifierSchemeCodeUnspsc190501.40174403': __('identifierSchemeCodeUnspsc190501.40174403'),
	'identifierSchemeCodeUnspsc190501.40174500': __('identifierSchemeCodeUnspsc190501.40174500'),
	'identifierSchemeCodeUnspsc190501.40174501': __('identifierSchemeCodeUnspsc190501.40174501'),
	'identifierSchemeCodeUnspsc190501.40174502': __('identifierSchemeCodeUnspsc190501.40174502'),
	'identifierSchemeCodeUnspsc190501.40174503': __('identifierSchemeCodeUnspsc190501.40174503'),
	'identifierSchemeCodeUnspsc190501.40174504': __('identifierSchemeCodeUnspsc190501.40174504'),
	'identifierSchemeCodeUnspsc190501.40174505': __('identifierSchemeCodeUnspsc190501.40174505'),
	'identifierSchemeCodeUnspsc190501.40174506': __('identifierSchemeCodeUnspsc190501.40174506'),
	'identifierSchemeCodeUnspsc190501.40174600': __('identifierSchemeCodeUnspsc190501.40174600'),
	'identifierSchemeCodeUnspsc190501.40174601': __('identifierSchemeCodeUnspsc190501.40174601'),
	'identifierSchemeCodeUnspsc190501.40174602': __('identifierSchemeCodeUnspsc190501.40174602'),
	'identifierSchemeCodeUnspsc190501.40174603': __('identifierSchemeCodeUnspsc190501.40174603'),
	'identifierSchemeCodeUnspsc190501.40174604': __('identifierSchemeCodeUnspsc190501.40174604'),
	'identifierSchemeCodeUnspsc190501.40174605': __('identifierSchemeCodeUnspsc190501.40174605'),
	'identifierSchemeCodeUnspsc190501.40174606': __('identifierSchemeCodeUnspsc190501.40174606'),
	'identifierSchemeCodeUnspsc190501.40174607': __('identifierSchemeCodeUnspsc190501.40174607'),
	'identifierSchemeCodeUnspsc190501.40174608': __('identifierSchemeCodeUnspsc190501.40174608'),
	'identifierSchemeCodeUnspsc190501.40174609': __('identifierSchemeCodeUnspsc190501.40174609'),
	'identifierSchemeCodeUnspsc190501.40174610': __('identifierSchemeCodeUnspsc190501.40174610'),
	'identifierSchemeCodeUnspsc190501.40174611': __('identifierSchemeCodeUnspsc190501.40174611'),
	'identifierSchemeCodeUnspsc190501.40174612': __('identifierSchemeCodeUnspsc190501.40174612'),
	'identifierSchemeCodeUnspsc190501.40174613': __('identifierSchemeCodeUnspsc190501.40174613'),
	'identifierSchemeCodeUnspsc190501.40174700': __('identifierSchemeCodeUnspsc190501.40174700'),
	'identifierSchemeCodeUnspsc190501.40174701': __('identifierSchemeCodeUnspsc190501.40174701'),
	'identifierSchemeCodeUnspsc190501.40174702': __('identifierSchemeCodeUnspsc190501.40174702'),
	'identifierSchemeCodeUnspsc190501.40174703': __('identifierSchemeCodeUnspsc190501.40174703'),
	'identifierSchemeCodeUnspsc190501.40174704': __('identifierSchemeCodeUnspsc190501.40174704'),
	'identifierSchemeCodeUnspsc190501.40174705': __('identifierSchemeCodeUnspsc190501.40174705'),
	'identifierSchemeCodeUnspsc190501.40174706': __('identifierSchemeCodeUnspsc190501.40174706'),
	'identifierSchemeCodeUnspsc190501.40174707': __('identifierSchemeCodeUnspsc190501.40174707'),
	'identifierSchemeCodeUnspsc190501.40174708': __('identifierSchemeCodeUnspsc190501.40174708'),
	'identifierSchemeCodeUnspsc190501.40174709': __('identifierSchemeCodeUnspsc190501.40174709'),
	'identifierSchemeCodeUnspsc190501.40174710': __('identifierSchemeCodeUnspsc190501.40174710'),
	'identifierSchemeCodeUnspsc190501.40174711': __('identifierSchemeCodeUnspsc190501.40174711'),
	'identifierSchemeCodeUnspsc190501.40174800': __('identifierSchemeCodeUnspsc190501.40174800'),
	'identifierSchemeCodeUnspsc190501.40174801': __('identifierSchemeCodeUnspsc190501.40174801'),
	'identifierSchemeCodeUnspsc190501.40174802': __('identifierSchemeCodeUnspsc190501.40174802'),
	'identifierSchemeCodeUnspsc190501.40174803': __('identifierSchemeCodeUnspsc190501.40174803'),
	'identifierSchemeCodeUnspsc190501.40174804': __('identifierSchemeCodeUnspsc190501.40174804'),
	'identifierSchemeCodeUnspsc190501.40174805': __('identifierSchemeCodeUnspsc190501.40174805'),
	'identifierSchemeCodeUnspsc190501.40174806': __('identifierSchemeCodeUnspsc190501.40174806'),
	'identifierSchemeCodeUnspsc190501.40174807': __('identifierSchemeCodeUnspsc190501.40174807'),
	'identifierSchemeCodeUnspsc190501.40174808': __('identifierSchemeCodeUnspsc190501.40174808'),
	'identifierSchemeCodeUnspsc190501.40174900': __('identifierSchemeCodeUnspsc190501.40174900'),
	'identifierSchemeCodeUnspsc190501.40174901': __('identifierSchemeCodeUnspsc190501.40174901'),
	'identifierSchemeCodeUnspsc190501.40174902': __('identifierSchemeCodeUnspsc190501.40174902'),
	'identifierSchemeCodeUnspsc190501.40174903': __('identifierSchemeCodeUnspsc190501.40174903'),
	'identifierSchemeCodeUnspsc190501.40174904': __('identifierSchemeCodeUnspsc190501.40174904'),
	'identifierSchemeCodeUnspsc190501.40174905': __('identifierSchemeCodeUnspsc190501.40174905'),
	'identifierSchemeCodeUnspsc190501.40174906': __('identifierSchemeCodeUnspsc190501.40174906'),
	'identifierSchemeCodeUnspsc190501.40174907': __('identifierSchemeCodeUnspsc190501.40174907'),
	'identifierSchemeCodeUnspsc190501.40174908': __('identifierSchemeCodeUnspsc190501.40174908'),
	'identifierSchemeCodeUnspsc190501.40174909': __('identifierSchemeCodeUnspsc190501.40174909'),
	'identifierSchemeCodeUnspsc190501.40174910': __('identifierSchemeCodeUnspsc190501.40174910'),
	'identifierSchemeCodeUnspsc190501.40174911': __('identifierSchemeCodeUnspsc190501.40174911'),
	'identifierSchemeCodeUnspsc190501.40174912': __('identifierSchemeCodeUnspsc190501.40174912'),
	'identifierSchemeCodeUnspsc190501.40175000': __('identifierSchemeCodeUnspsc190501.40175000'),
	'identifierSchemeCodeUnspsc190501.40175001': __('identifierSchemeCodeUnspsc190501.40175001'),
	'identifierSchemeCodeUnspsc190501.40175002': __('identifierSchemeCodeUnspsc190501.40175002'),
	'identifierSchemeCodeUnspsc190501.40175003': __('identifierSchemeCodeUnspsc190501.40175003'),
	'identifierSchemeCodeUnspsc190501.40175100': __('identifierSchemeCodeUnspsc190501.40175100'),
	'identifierSchemeCodeUnspsc190501.40175101': __('identifierSchemeCodeUnspsc190501.40175101'),
	'identifierSchemeCodeUnspsc190501.40175102': __('identifierSchemeCodeUnspsc190501.40175102'),
	'identifierSchemeCodeUnspsc190501.40175103': __('identifierSchemeCodeUnspsc190501.40175103'),
	'identifierSchemeCodeUnspsc190501.40175200': __('identifierSchemeCodeUnspsc190501.40175200'),
	'identifierSchemeCodeUnspsc190501.40175201': __('identifierSchemeCodeUnspsc190501.40175201'),
	'identifierSchemeCodeUnspsc190501.40175202': __('identifierSchemeCodeUnspsc190501.40175202'),
	'identifierSchemeCodeUnspsc190501.40175203': __('identifierSchemeCodeUnspsc190501.40175203'),
	'identifierSchemeCodeUnspsc190501.40175204': __('identifierSchemeCodeUnspsc190501.40175204'),
	'identifierSchemeCodeUnspsc190501.40175205': __('identifierSchemeCodeUnspsc190501.40175205'),
	'identifierSchemeCodeUnspsc190501.40175206': __('identifierSchemeCodeUnspsc190501.40175206'),
	'identifierSchemeCodeUnspsc190501.40175207': __('identifierSchemeCodeUnspsc190501.40175207'),
	'identifierSchemeCodeUnspsc190501.40175208': __('identifierSchemeCodeUnspsc190501.40175208'),
	'identifierSchemeCodeUnspsc190501.40175209': __('identifierSchemeCodeUnspsc190501.40175209'),
	'identifierSchemeCodeUnspsc190501.40175210': __('identifierSchemeCodeUnspsc190501.40175210'),
	'identifierSchemeCodeUnspsc190501.40175211': __('identifierSchemeCodeUnspsc190501.40175211'),
	'identifierSchemeCodeUnspsc190501.40175212': __('identifierSchemeCodeUnspsc190501.40175212'),
	'identifierSchemeCodeUnspsc190501.40175300': __('identifierSchemeCodeUnspsc190501.40175300'),
	'identifierSchemeCodeUnspsc190501.40175301': __('identifierSchemeCodeUnspsc190501.40175301'),
	'identifierSchemeCodeUnspsc190501.40175302': __('identifierSchemeCodeUnspsc190501.40175302'),
	'identifierSchemeCodeUnspsc190501.40175303': __('identifierSchemeCodeUnspsc190501.40175303'),
	'identifierSchemeCodeUnspsc190501.40175304': __('identifierSchemeCodeUnspsc190501.40175304'),
	'identifierSchemeCodeUnspsc190501.40175305': __('identifierSchemeCodeUnspsc190501.40175305'),
	'identifierSchemeCodeUnspsc190501.40175306': __('identifierSchemeCodeUnspsc190501.40175306'),
	'identifierSchemeCodeUnspsc190501.40175307': __('identifierSchemeCodeUnspsc190501.40175307'),
	'identifierSchemeCodeUnspsc190501.40175308': __('identifierSchemeCodeUnspsc190501.40175308'),
	'identifierSchemeCodeUnspsc190501.40175309': __('identifierSchemeCodeUnspsc190501.40175309'),
	'identifierSchemeCodeUnspsc190501.40180000': __('identifierSchemeCodeUnspsc190501.40180000'),
	'identifierSchemeCodeUnspsc190501.40181500': __('identifierSchemeCodeUnspsc190501.40181500'),
	'identifierSchemeCodeUnspsc190501.40181501': __('identifierSchemeCodeUnspsc190501.40181501'),
	'identifierSchemeCodeUnspsc190501.40181502': __('identifierSchemeCodeUnspsc190501.40181502'),
	'identifierSchemeCodeUnspsc190501.40181503': __('identifierSchemeCodeUnspsc190501.40181503'),
	'identifierSchemeCodeUnspsc190501.40181504': __('identifierSchemeCodeUnspsc190501.40181504'),
	'identifierSchemeCodeUnspsc190501.40181505': __('identifierSchemeCodeUnspsc190501.40181505'),
	'identifierSchemeCodeUnspsc190501.40181506': __('identifierSchemeCodeUnspsc190501.40181506'),
	'identifierSchemeCodeUnspsc190501.40181600': __('identifierSchemeCodeUnspsc190501.40181600'),
	'identifierSchemeCodeUnspsc190501.40181601': __('identifierSchemeCodeUnspsc190501.40181601'),
	'identifierSchemeCodeUnspsc190501.40181602': __('identifierSchemeCodeUnspsc190501.40181602'),
	'identifierSchemeCodeUnspsc190501.40181603': __('identifierSchemeCodeUnspsc190501.40181603'),
	'identifierSchemeCodeUnspsc190501.40181604': __('identifierSchemeCodeUnspsc190501.40181604'),
	'identifierSchemeCodeUnspsc190501.40181605': __('identifierSchemeCodeUnspsc190501.40181605'),
	'identifierSchemeCodeUnspsc190501.40181606': __('identifierSchemeCodeUnspsc190501.40181606'),
	'identifierSchemeCodeUnspsc190501.40181700': __('identifierSchemeCodeUnspsc190501.40181700'),
	'identifierSchemeCodeUnspsc190501.40181701': __('identifierSchemeCodeUnspsc190501.40181701'),
	'identifierSchemeCodeUnspsc190501.40181702': __('identifierSchemeCodeUnspsc190501.40181702'),
	'identifierSchemeCodeUnspsc190501.40181703': __('identifierSchemeCodeUnspsc190501.40181703'),
	'identifierSchemeCodeUnspsc190501.40181704': __('identifierSchemeCodeUnspsc190501.40181704'),
	'identifierSchemeCodeUnspsc190501.40181705': __('identifierSchemeCodeUnspsc190501.40181705'),
	'identifierSchemeCodeUnspsc190501.40181706': __('identifierSchemeCodeUnspsc190501.40181706'),
	'identifierSchemeCodeUnspsc190501.40181800': __('identifierSchemeCodeUnspsc190501.40181800'),
	'identifierSchemeCodeUnspsc190501.40181801': __('identifierSchemeCodeUnspsc190501.40181801'),
	'identifierSchemeCodeUnspsc190501.40181802': __('identifierSchemeCodeUnspsc190501.40181802'),
	'identifierSchemeCodeUnspsc190501.40181803': __('identifierSchemeCodeUnspsc190501.40181803'),
	'identifierSchemeCodeUnspsc190501.40181804': __('identifierSchemeCodeUnspsc190501.40181804'),
	'identifierSchemeCodeUnspsc190501.40181805': __('identifierSchemeCodeUnspsc190501.40181805'),
	'identifierSchemeCodeUnspsc190501.40181806': __('identifierSchemeCodeUnspsc190501.40181806'),
	'identifierSchemeCodeUnspsc190501.40181900': __('identifierSchemeCodeUnspsc190501.40181900'),
	'identifierSchemeCodeUnspsc190501.40181901': __('identifierSchemeCodeUnspsc190501.40181901'),
	'identifierSchemeCodeUnspsc190501.40181902': __('identifierSchemeCodeUnspsc190501.40181902'),
	'identifierSchemeCodeUnspsc190501.40181903': __('identifierSchemeCodeUnspsc190501.40181903'),
	'identifierSchemeCodeUnspsc190501.40181904': __('identifierSchemeCodeUnspsc190501.40181904'),
	'identifierSchemeCodeUnspsc190501.40181905': __('identifierSchemeCodeUnspsc190501.40181905'),
	'identifierSchemeCodeUnspsc190501.40181906': __('identifierSchemeCodeUnspsc190501.40181906'),
	'identifierSchemeCodeUnspsc190501.40182000': __('identifierSchemeCodeUnspsc190501.40182000'),
	'identifierSchemeCodeUnspsc190501.40182001': __('identifierSchemeCodeUnspsc190501.40182001'),
	'identifierSchemeCodeUnspsc190501.40182002': __('identifierSchemeCodeUnspsc190501.40182002'),
	'identifierSchemeCodeUnspsc190501.40182003': __('identifierSchemeCodeUnspsc190501.40182003'),
	'identifierSchemeCodeUnspsc190501.40182004': __('identifierSchemeCodeUnspsc190501.40182004'),
	'identifierSchemeCodeUnspsc190501.40182005': __('identifierSchemeCodeUnspsc190501.40182005'),
	'identifierSchemeCodeUnspsc190501.40182006': __('identifierSchemeCodeUnspsc190501.40182006'),
	'identifierSchemeCodeUnspsc190501.40182100': __('identifierSchemeCodeUnspsc190501.40182100'),
	'identifierSchemeCodeUnspsc190501.40182101': __('identifierSchemeCodeUnspsc190501.40182101'),
	'identifierSchemeCodeUnspsc190501.40182102': __('identifierSchemeCodeUnspsc190501.40182102'),
	'identifierSchemeCodeUnspsc190501.40182103': __('identifierSchemeCodeUnspsc190501.40182103'),
	'identifierSchemeCodeUnspsc190501.40182104': __('identifierSchemeCodeUnspsc190501.40182104'),
	'identifierSchemeCodeUnspsc190501.40182105': __('identifierSchemeCodeUnspsc190501.40182105'),
	'identifierSchemeCodeUnspsc190501.40182106': __('identifierSchemeCodeUnspsc190501.40182106'),
	'identifierSchemeCodeUnspsc190501.40182200': __('identifierSchemeCodeUnspsc190501.40182200'),
	'identifierSchemeCodeUnspsc190501.40182201': __('identifierSchemeCodeUnspsc190501.40182201'),
	'identifierSchemeCodeUnspsc190501.40182202': __('identifierSchemeCodeUnspsc190501.40182202'),
	'identifierSchemeCodeUnspsc190501.40182203': __('identifierSchemeCodeUnspsc190501.40182203'),
	'identifierSchemeCodeUnspsc190501.40182204': __('identifierSchemeCodeUnspsc190501.40182204'),
	'identifierSchemeCodeUnspsc190501.40182205': __('identifierSchemeCodeUnspsc190501.40182205'),
	'identifierSchemeCodeUnspsc190501.40182206': __('identifierSchemeCodeUnspsc190501.40182206'),
	'identifierSchemeCodeUnspsc190501.40182300': __('identifierSchemeCodeUnspsc190501.40182300'),
	'identifierSchemeCodeUnspsc190501.40182301': __('identifierSchemeCodeUnspsc190501.40182301'),
	'identifierSchemeCodeUnspsc190501.40182302': __('identifierSchemeCodeUnspsc190501.40182302'),
	'identifierSchemeCodeUnspsc190501.40182303': __('identifierSchemeCodeUnspsc190501.40182303'),
	'identifierSchemeCodeUnspsc190501.40182304': __('identifierSchemeCodeUnspsc190501.40182304'),
	'identifierSchemeCodeUnspsc190501.40182305': __('identifierSchemeCodeUnspsc190501.40182305'),
	'identifierSchemeCodeUnspsc190501.40182306': __('identifierSchemeCodeUnspsc190501.40182306'),
	'identifierSchemeCodeUnspsc190501.40182400': __('identifierSchemeCodeUnspsc190501.40182400'),
	'identifierSchemeCodeUnspsc190501.40182401': __('identifierSchemeCodeUnspsc190501.40182401'),
	'identifierSchemeCodeUnspsc190501.40182402': __('identifierSchemeCodeUnspsc190501.40182402'),
	'identifierSchemeCodeUnspsc190501.40182403': __('identifierSchemeCodeUnspsc190501.40182403'),
	'identifierSchemeCodeUnspsc190501.40182404': __('identifierSchemeCodeUnspsc190501.40182404'),
	'identifierSchemeCodeUnspsc190501.40182405': __('identifierSchemeCodeUnspsc190501.40182405'),
	'identifierSchemeCodeUnspsc190501.40182406': __('identifierSchemeCodeUnspsc190501.40182406'),
	'identifierSchemeCodeUnspsc190501.40182500': __('identifierSchemeCodeUnspsc190501.40182500'),
	'identifierSchemeCodeUnspsc190501.40182501': __('identifierSchemeCodeUnspsc190501.40182501'),
	'identifierSchemeCodeUnspsc190501.40182502': __('identifierSchemeCodeUnspsc190501.40182502'),
	'identifierSchemeCodeUnspsc190501.40182503': __('identifierSchemeCodeUnspsc190501.40182503'),
	'identifierSchemeCodeUnspsc190501.40182504': __('identifierSchemeCodeUnspsc190501.40182504'),
	'identifierSchemeCodeUnspsc190501.40182505': __('identifierSchemeCodeUnspsc190501.40182505'),
	'identifierSchemeCodeUnspsc190501.40182506': __('identifierSchemeCodeUnspsc190501.40182506'),
	'identifierSchemeCodeUnspsc190501.40182600': __('identifierSchemeCodeUnspsc190501.40182600'),
	'identifierSchemeCodeUnspsc190501.40182601': __('identifierSchemeCodeUnspsc190501.40182601'),
	'identifierSchemeCodeUnspsc190501.40182602': __('identifierSchemeCodeUnspsc190501.40182602'),
	'identifierSchemeCodeUnspsc190501.40182603': __('identifierSchemeCodeUnspsc190501.40182603'),
	'identifierSchemeCodeUnspsc190501.40182604': __('identifierSchemeCodeUnspsc190501.40182604'),
	'identifierSchemeCodeUnspsc190501.40182605': __('identifierSchemeCodeUnspsc190501.40182605'),
	'identifierSchemeCodeUnspsc190501.40182606': __('identifierSchemeCodeUnspsc190501.40182606'),
	'identifierSchemeCodeUnspsc190501.40182700': __('identifierSchemeCodeUnspsc190501.40182700'),
	'identifierSchemeCodeUnspsc190501.40182701': __('identifierSchemeCodeUnspsc190501.40182701'),
	'identifierSchemeCodeUnspsc190501.40182702': __('identifierSchemeCodeUnspsc190501.40182702'),
	'identifierSchemeCodeUnspsc190501.40182703': __('identifierSchemeCodeUnspsc190501.40182703'),
	'identifierSchemeCodeUnspsc190501.40182704': __('identifierSchemeCodeUnspsc190501.40182704'),
	'identifierSchemeCodeUnspsc190501.40182705': __('identifierSchemeCodeUnspsc190501.40182705'),
	'identifierSchemeCodeUnspsc190501.40182706': __('identifierSchemeCodeUnspsc190501.40182706'),
	'identifierSchemeCodeUnspsc190501.40182707': __('identifierSchemeCodeUnspsc190501.40182707'),
	'identifierSchemeCodeUnspsc190501.40182800': __('identifierSchemeCodeUnspsc190501.40182800'),
	'identifierSchemeCodeUnspsc190501.40182801': __('identifierSchemeCodeUnspsc190501.40182801'),
	'identifierSchemeCodeUnspsc190501.40182802': __('identifierSchemeCodeUnspsc190501.40182802'),
	'identifierSchemeCodeUnspsc190501.40182803': __('identifierSchemeCodeUnspsc190501.40182803'),
	'identifierSchemeCodeUnspsc190501.40182804': __('identifierSchemeCodeUnspsc190501.40182804'),
	'identifierSchemeCodeUnspsc190501.40182805': __('identifierSchemeCodeUnspsc190501.40182805'),
	'identifierSchemeCodeUnspsc190501.40182806': __('identifierSchemeCodeUnspsc190501.40182806'),
	'identifierSchemeCodeUnspsc190501.40182900': __('identifierSchemeCodeUnspsc190501.40182900'),
	'identifierSchemeCodeUnspsc190501.40182901': __('identifierSchemeCodeUnspsc190501.40182901'),
	'identifierSchemeCodeUnspsc190501.40182902': __('identifierSchemeCodeUnspsc190501.40182902'),
	'identifierSchemeCodeUnspsc190501.40182903': __('identifierSchemeCodeUnspsc190501.40182903'),
	'identifierSchemeCodeUnspsc190501.40182904': __('identifierSchemeCodeUnspsc190501.40182904'),
	'identifierSchemeCodeUnspsc190501.40182905': __('identifierSchemeCodeUnspsc190501.40182905'),
	'identifierSchemeCodeUnspsc190501.40182906': __('identifierSchemeCodeUnspsc190501.40182906'),
	'identifierSchemeCodeUnspsc190501.40183000': __('identifierSchemeCodeUnspsc190501.40183000'),
	'identifierSchemeCodeUnspsc190501.40183001': __('identifierSchemeCodeUnspsc190501.40183001'),
	'identifierSchemeCodeUnspsc190501.40183002': __('identifierSchemeCodeUnspsc190501.40183002'),
	'identifierSchemeCodeUnspsc190501.40183003': __('identifierSchemeCodeUnspsc190501.40183003'),
	'identifierSchemeCodeUnspsc190501.40183004': __('identifierSchemeCodeUnspsc190501.40183004'),
	'identifierSchemeCodeUnspsc190501.40183005': __('identifierSchemeCodeUnspsc190501.40183005'),
	'identifierSchemeCodeUnspsc190501.40183006': __('identifierSchemeCodeUnspsc190501.40183006'),
	'identifierSchemeCodeUnspsc190501.40183007': __('identifierSchemeCodeUnspsc190501.40183007'),
	'identifierSchemeCodeUnspsc190501.40183008': __('identifierSchemeCodeUnspsc190501.40183008'),
	'identifierSchemeCodeUnspsc190501.40183009': __('identifierSchemeCodeUnspsc190501.40183009'),
	'identifierSchemeCodeUnspsc190501.40183010': __('identifierSchemeCodeUnspsc190501.40183010'),
	'identifierSchemeCodeUnspsc190501.40183100': __('identifierSchemeCodeUnspsc190501.40183100'),
	'identifierSchemeCodeUnspsc190501.40183101': __('identifierSchemeCodeUnspsc190501.40183101'),
	'identifierSchemeCodeUnspsc190501.40183102': __('identifierSchemeCodeUnspsc190501.40183102'),
	'identifierSchemeCodeUnspsc190501.40183103': __('identifierSchemeCodeUnspsc190501.40183103'),
	'identifierSchemeCodeUnspsc190501.40183104': __('identifierSchemeCodeUnspsc190501.40183104'),
	'identifierSchemeCodeUnspsc190501.40183105': __('identifierSchemeCodeUnspsc190501.40183105'),
	'identifierSchemeCodeUnspsc190501.40183106': __('identifierSchemeCodeUnspsc190501.40183106'),
	'identifierSchemeCodeUnspsc190501.40183107': __('identifierSchemeCodeUnspsc190501.40183107'),
	'identifierSchemeCodeUnspsc190501.40183108': __('identifierSchemeCodeUnspsc190501.40183108'),
	'identifierSchemeCodeUnspsc190501.40183109': __('identifierSchemeCodeUnspsc190501.40183109'),
	'identifierSchemeCodeUnspsc190501.40183110': __('identifierSchemeCodeUnspsc190501.40183110'),
	'identifierSchemeCodeUnspsc190501.40183111': __('identifierSchemeCodeUnspsc190501.40183111'),
	'identifierSchemeCodeUnspsc190501.40183112': __('identifierSchemeCodeUnspsc190501.40183112'),
	'identifierSchemeCodeUnspsc190501.40183200': __('identifierSchemeCodeUnspsc190501.40183200'),
	'identifierSchemeCodeUnspsc190501.40183201': __('identifierSchemeCodeUnspsc190501.40183201'),
	'identifierSchemeCodeUnspsc190501.40183202': __('identifierSchemeCodeUnspsc190501.40183202'),
	'identifierSchemeCodeUnspsc190501.40183203': __('identifierSchemeCodeUnspsc190501.40183203'),
	'identifierSchemeCodeUnspsc190501.40183204': __('identifierSchemeCodeUnspsc190501.40183204'),
	'identifierSchemeCodeUnspsc190501.40183205': __('identifierSchemeCodeUnspsc190501.40183205'),
	'identifierSchemeCodeUnspsc190501.40183206': __('identifierSchemeCodeUnspsc190501.40183206'),
	'identifierSchemeCodeUnspsc190501.41000000': __('identifierSchemeCodeUnspsc190501.41000000'),
	'identifierSchemeCodeUnspsc190501.41100000': __('identifierSchemeCodeUnspsc190501.41100000'),
	'identifierSchemeCodeUnspsc190501.41101500': __('identifierSchemeCodeUnspsc190501.41101500'),
	'identifierSchemeCodeUnspsc190501.41101502': __('identifierSchemeCodeUnspsc190501.41101502'),
	'identifierSchemeCodeUnspsc190501.41101503': __('identifierSchemeCodeUnspsc190501.41101503'),
	'identifierSchemeCodeUnspsc190501.41101504': __('identifierSchemeCodeUnspsc190501.41101504'),
	'identifierSchemeCodeUnspsc190501.41101505': __('identifierSchemeCodeUnspsc190501.41101505'),
	'identifierSchemeCodeUnspsc190501.41101515': __('identifierSchemeCodeUnspsc190501.41101515'),
	'identifierSchemeCodeUnspsc190501.41101516': __('identifierSchemeCodeUnspsc190501.41101516'),
	'identifierSchemeCodeUnspsc190501.41101518': __('identifierSchemeCodeUnspsc190501.41101518'),
	'identifierSchemeCodeUnspsc190501.41101519': __('identifierSchemeCodeUnspsc190501.41101519'),
	'identifierSchemeCodeUnspsc190501.41101520': __('identifierSchemeCodeUnspsc190501.41101520'),
	'identifierSchemeCodeUnspsc190501.41101700': __('identifierSchemeCodeUnspsc190501.41101700'),
	'identifierSchemeCodeUnspsc190501.41101701': __('identifierSchemeCodeUnspsc190501.41101701'),
	'identifierSchemeCodeUnspsc190501.41101702': __('identifierSchemeCodeUnspsc190501.41101702'),
	'identifierSchemeCodeUnspsc190501.41101703': __('identifierSchemeCodeUnspsc190501.41101703'),
	'identifierSchemeCodeUnspsc190501.41101705': __('identifierSchemeCodeUnspsc190501.41101705'),
	'identifierSchemeCodeUnspsc190501.41101706': __('identifierSchemeCodeUnspsc190501.41101706'),
	'identifierSchemeCodeUnspsc190501.41101707': __('identifierSchemeCodeUnspsc190501.41101707'),
	'identifierSchemeCodeUnspsc190501.41101708': __('identifierSchemeCodeUnspsc190501.41101708'),
	'identifierSchemeCodeUnspsc190501.41101709': __('identifierSchemeCodeUnspsc190501.41101709'),
	'identifierSchemeCodeUnspsc190501.41101710': __('identifierSchemeCodeUnspsc190501.41101710'),
	'identifierSchemeCodeUnspsc190501.41101800': __('identifierSchemeCodeUnspsc190501.41101800'),
	'identifierSchemeCodeUnspsc190501.41101801': __('identifierSchemeCodeUnspsc190501.41101801'),
	'identifierSchemeCodeUnspsc190501.41101802': __('identifierSchemeCodeUnspsc190501.41101802'),
	'identifierSchemeCodeUnspsc190501.41101803': __('identifierSchemeCodeUnspsc190501.41101803'),
	'identifierSchemeCodeUnspsc190501.41101804': __('identifierSchemeCodeUnspsc190501.41101804'),
	'identifierSchemeCodeUnspsc190501.41101805': __('identifierSchemeCodeUnspsc190501.41101805'),
	'identifierSchemeCodeUnspsc190501.41101806': __('identifierSchemeCodeUnspsc190501.41101806'),
	'identifierSchemeCodeUnspsc190501.41101807': __('identifierSchemeCodeUnspsc190501.41101807'),
	'identifierSchemeCodeUnspsc190501.41101808': __('identifierSchemeCodeUnspsc190501.41101808'),
	'identifierSchemeCodeUnspsc190501.41101809': __('identifierSchemeCodeUnspsc190501.41101809'),
	'identifierSchemeCodeUnspsc190501.41101810': __('identifierSchemeCodeUnspsc190501.41101810'),
	'identifierSchemeCodeUnspsc190501.41101811': __('identifierSchemeCodeUnspsc190501.41101811'),
	'identifierSchemeCodeUnspsc190501.41101812': __('identifierSchemeCodeUnspsc190501.41101812'),
	'identifierSchemeCodeUnspsc190501.41101813': __('identifierSchemeCodeUnspsc190501.41101813'),
	'identifierSchemeCodeUnspsc190501.41101900': __('identifierSchemeCodeUnspsc190501.41101900'),
	'identifierSchemeCodeUnspsc190501.41101901': __('identifierSchemeCodeUnspsc190501.41101901'),
	'identifierSchemeCodeUnspsc190501.41101902': __('identifierSchemeCodeUnspsc190501.41101902'),
	'identifierSchemeCodeUnspsc190501.41101903': __('identifierSchemeCodeUnspsc190501.41101903'),
	'identifierSchemeCodeUnspsc190501.41102400': __('identifierSchemeCodeUnspsc190501.41102400'),
	'identifierSchemeCodeUnspsc190501.41102401': __('identifierSchemeCodeUnspsc190501.41102401'),
	'identifierSchemeCodeUnspsc190501.41102402': __('identifierSchemeCodeUnspsc190501.41102402'),
	'identifierSchemeCodeUnspsc190501.41102403': __('identifierSchemeCodeUnspsc190501.41102403'),
	'identifierSchemeCodeUnspsc190501.41102404': __('identifierSchemeCodeUnspsc190501.41102404'),
	'identifierSchemeCodeUnspsc190501.41102405': __('identifierSchemeCodeUnspsc190501.41102405'),
	'identifierSchemeCodeUnspsc190501.41102406': __('identifierSchemeCodeUnspsc190501.41102406'),
	'identifierSchemeCodeUnspsc190501.41102407': __('identifierSchemeCodeUnspsc190501.41102407'),
	'identifierSchemeCodeUnspsc190501.41102410': __('identifierSchemeCodeUnspsc190501.41102410'),
	'identifierSchemeCodeUnspsc190501.41102412': __('identifierSchemeCodeUnspsc190501.41102412'),
	'identifierSchemeCodeUnspsc190501.41102421': __('identifierSchemeCodeUnspsc190501.41102421'),
	'identifierSchemeCodeUnspsc190501.41102422': __('identifierSchemeCodeUnspsc190501.41102422'),
	'identifierSchemeCodeUnspsc190501.41102423': __('identifierSchemeCodeUnspsc190501.41102423'),
	'identifierSchemeCodeUnspsc190501.41102424': __('identifierSchemeCodeUnspsc190501.41102424'),
	'identifierSchemeCodeUnspsc190501.41102425': __('identifierSchemeCodeUnspsc190501.41102425'),
	'identifierSchemeCodeUnspsc190501.41102426': __('identifierSchemeCodeUnspsc190501.41102426'),
	'identifierSchemeCodeUnspsc190501.41102427': __('identifierSchemeCodeUnspsc190501.41102427'),
	'identifierSchemeCodeUnspsc190501.41102428': __('identifierSchemeCodeUnspsc190501.41102428'),
	'identifierSchemeCodeUnspsc190501.41102429': __('identifierSchemeCodeUnspsc190501.41102429'),
	'identifierSchemeCodeUnspsc190501.41102430': __('identifierSchemeCodeUnspsc190501.41102430'),
	'identifierSchemeCodeUnspsc190501.41102500': __('identifierSchemeCodeUnspsc190501.41102500'),
	'identifierSchemeCodeUnspsc190501.41102501': __('identifierSchemeCodeUnspsc190501.41102501'),
	'identifierSchemeCodeUnspsc190501.41102502': __('identifierSchemeCodeUnspsc190501.41102502'),
	'identifierSchemeCodeUnspsc190501.41102503': __('identifierSchemeCodeUnspsc190501.41102503'),
	'identifierSchemeCodeUnspsc190501.41102504': __('identifierSchemeCodeUnspsc190501.41102504'),
	'identifierSchemeCodeUnspsc190501.41102505': __('identifierSchemeCodeUnspsc190501.41102505'),
	'identifierSchemeCodeUnspsc190501.41102506': __('identifierSchemeCodeUnspsc190501.41102506'),
	'identifierSchemeCodeUnspsc190501.41102507': __('identifierSchemeCodeUnspsc190501.41102507'),
	'identifierSchemeCodeUnspsc190501.41102508': __('identifierSchemeCodeUnspsc190501.41102508'),
	'identifierSchemeCodeUnspsc190501.41102509': __('identifierSchemeCodeUnspsc190501.41102509'),
	'identifierSchemeCodeUnspsc190501.41102510': __('identifierSchemeCodeUnspsc190501.41102510'),
	'identifierSchemeCodeUnspsc190501.41102511': __('identifierSchemeCodeUnspsc190501.41102511'),
	'identifierSchemeCodeUnspsc190501.41102512': __('identifierSchemeCodeUnspsc190501.41102512'),
	'identifierSchemeCodeUnspsc190501.41102513': __('identifierSchemeCodeUnspsc190501.41102513'),
	'identifierSchemeCodeUnspsc190501.41102600': __('identifierSchemeCodeUnspsc190501.41102600'),
	'identifierSchemeCodeUnspsc190501.41102601': __('identifierSchemeCodeUnspsc190501.41102601'),
	'identifierSchemeCodeUnspsc190501.41102602': __('identifierSchemeCodeUnspsc190501.41102602'),
	'identifierSchemeCodeUnspsc190501.41102603': __('identifierSchemeCodeUnspsc190501.41102603'),
	'identifierSchemeCodeUnspsc190501.41102604': __('identifierSchemeCodeUnspsc190501.41102604'),
	'identifierSchemeCodeUnspsc190501.41102605': __('identifierSchemeCodeUnspsc190501.41102605'),
	'identifierSchemeCodeUnspsc190501.41102606': __('identifierSchemeCodeUnspsc190501.41102606'),
	'identifierSchemeCodeUnspsc190501.41102607': __('identifierSchemeCodeUnspsc190501.41102607'),
	'identifierSchemeCodeUnspsc190501.41102608': __('identifierSchemeCodeUnspsc190501.41102608'),
	'identifierSchemeCodeUnspsc190501.41102609': __('identifierSchemeCodeUnspsc190501.41102609'),
	'identifierSchemeCodeUnspsc190501.41102610': __('identifierSchemeCodeUnspsc190501.41102610'),
	'identifierSchemeCodeUnspsc190501.41102611': __('identifierSchemeCodeUnspsc190501.41102611'),
	'identifierSchemeCodeUnspsc190501.41102612': __('identifierSchemeCodeUnspsc190501.41102612'),
	'identifierSchemeCodeUnspsc190501.41102613': __('identifierSchemeCodeUnspsc190501.41102613'),
	'identifierSchemeCodeUnspsc190501.41102614': __('identifierSchemeCodeUnspsc190501.41102614'),
	'identifierSchemeCodeUnspsc190501.41102700': __('identifierSchemeCodeUnspsc190501.41102700'),
	'identifierSchemeCodeUnspsc190501.41102701': __('identifierSchemeCodeUnspsc190501.41102701'),
	'identifierSchemeCodeUnspsc190501.41102702': __('identifierSchemeCodeUnspsc190501.41102702'),
	'identifierSchemeCodeUnspsc190501.41102703': __('identifierSchemeCodeUnspsc190501.41102703'),
	'identifierSchemeCodeUnspsc190501.41102704': __('identifierSchemeCodeUnspsc190501.41102704'),
	'identifierSchemeCodeUnspsc190501.41102705': __('identifierSchemeCodeUnspsc190501.41102705'),
	'identifierSchemeCodeUnspsc190501.41102706': __('identifierSchemeCodeUnspsc190501.41102706'),
	'identifierSchemeCodeUnspsc190501.41102900': __('identifierSchemeCodeUnspsc190501.41102900'),
	'identifierSchemeCodeUnspsc190501.41102901': __('identifierSchemeCodeUnspsc190501.41102901'),
	'identifierSchemeCodeUnspsc190501.41102902': __('identifierSchemeCodeUnspsc190501.41102902'),
	'identifierSchemeCodeUnspsc190501.41102903': __('identifierSchemeCodeUnspsc190501.41102903'),
	'identifierSchemeCodeUnspsc190501.41102904': __('identifierSchemeCodeUnspsc190501.41102904'),
	'identifierSchemeCodeUnspsc190501.41102905': __('identifierSchemeCodeUnspsc190501.41102905'),
	'identifierSchemeCodeUnspsc190501.41102909': __('identifierSchemeCodeUnspsc190501.41102909'),
	'identifierSchemeCodeUnspsc190501.41102910': __('identifierSchemeCodeUnspsc190501.41102910'),
	'identifierSchemeCodeUnspsc190501.41102911': __('identifierSchemeCodeUnspsc190501.41102911'),
	'identifierSchemeCodeUnspsc190501.41102912': __('identifierSchemeCodeUnspsc190501.41102912'),
	'identifierSchemeCodeUnspsc190501.41102913': __('identifierSchemeCodeUnspsc190501.41102913'),
	'identifierSchemeCodeUnspsc190501.41102914': __('identifierSchemeCodeUnspsc190501.41102914'),
	'identifierSchemeCodeUnspsc190501.41102915': __('identifierSchemeCodeUnspsc190501.41102915'),
	'identifierSchemeCodeUnspsc190501.41102916': __('identifierSchemeCodeUnspsc190501.41102916'),
	'identifierSchemeCodeUnspsc190501.41102917': __('identifierSchemeCodeUnspsc190501.41102917'),
	'identifierSchemeCodeUnspsc190501.41102918': __('identifierSchemeCodeUnspsc190501.41102918'),
	'identifierSchemeCodeUnspsc190501.41102919': __('identifierSchemeCodeUnspsc190501.41102919'),
	'identifierSchemeCodeUnspsc190501.41102920': __('identifierSchemeCodeUnspsc190501.41102920'),
	'identifierSchemeCodeUnspsc190501.41102921': __('identifierSchemeCodeUnspsc190501.41102921'),
	'identifierSchemeCodeUnspsc190501.41102922': __('identifierSchemeCodeUnspsc190501.41102922'),
	'identifierSchemeCodeUnspsc190501.41102923': __('identifierSchemeCodeUnspsc190501.41102923'),
	'identifierSchemeCodeUnspsc190501.41102924': __('identifierSchemeCodeUnspsc190501.41102924'),
	'identifierSchemeCodeUnspsc190501.41102925': __('identifierSchemeCodeUnspsc190501.41102925'),
	'identifierSchemeCodeUnspsc190501.41102926': __('identifierSchemeCodeUnspsc190501.41102926'),
	'identifierSchemeCodeUnspsc190501.41102927': __('identifierSchemeCodeUnspsc190501.41102927'),
	'identifierSchemeCodeUnspsc190501.41102928': __('identifierSchemeCodeUnspsc190501.41102928'),
	'identifierSchemeCodeUnspsc190501.41102929': __('identifierSchemeCodeUnspsc190501.41102929'),
	'identifierSchemeCodeUnspsc190501.41102930': __('identifierSchemeCodeUnspsc190501.41102930'),
	'identifierSchemeCodeUnspsc190501.41102931': __('identifierSchemeCodeUnspsc190501.41102931'),
	'identifierSchemeCodeUnspsc190501.41103000': __('identifierSchemeCodeUnspsc190501.41103000'),
	'identifierSchemeCodeUnspsc190501.41103001': __('identifierSchemeCodeUnspsc190501.41103001'),
	'identifierSchemeCodeUnspsc190501.41103003': __('identifierSchemeCodeUnspsc190501.41103003'),
	'identifierSchemeCodeUnspsc190501.41103004': __('identifierSchemeCodeUnspsc190501.41103004'),
	'identifierSchemeCodeUnspsc190501.41103005': __('identifierSchemeCodeUnspsc190501.41103005'),
	'identifierSchemeCodeUnspsc190501.41103006': __('identifierSchemeCodeUnspsc190501.41103006'),
	'identifierSchemeCodeUnspsc190501.41103007': __('identifierSchemeCodeUnspsc190501.41103007'),
	'identifierSchemeCodeUnspsc190501.41103008': __('identifierSchemeCodeUnspsc190501.41103008'),
	'identifierSchemeCodeUnspsc190501.41103010': __('identifierSchemeCodeUnspsc190501.41103010'),
	'identifierSchemeCodeUnspsc190501.41103011': __('identifierSchemeCodeUnspsc190501.41103011'),
	'identifierSchemeCodeUnspsc190501.41103012': __('identifierSchemeCodeUnspsc190501.41103012'),
	'identifierSchemeCodeUnspsc190501.41103013': __('identifierSchemeCodeUnspsc190501.41103013'),
	'identifierSchemeCodeUnspsc190501.41103014': __('identifierSchemeCodeUnspsc190501.41103014'),
	'identifierSchemeCodeUnspsc190501.41103015': __('identifierSchemeCodeUnspsc190501.41103015'),
	'identifierSchemeCodeUnspsc190501.41103017': __('identifierSchemeCodeUnspsc190501.41103017'),
	'identifierSchemeCodeUnspsc190501.41103019': __('identifierSchemeCodeUnspsc190501.41103019'),
	'identifierSchemeCodeUnspsc190501.41103020': __('identifierSchemeCodeUnspsc190501.41103020'),
	'identifierSchemeCodeUnspsc190501.41103021': __('identifierSchemeCodeUnspsc190501.41103021'),
	'identifierSchemeCodeUnspsc190501.41103022': __('identifierSchemeCodeUnspsc190501.41103022'),
	'identifierSchemeCodeUnspsc190501.41103023': __('identifierSchemeCodeUnspsc190501.41103023'),
	'identifierSchemeCodeUnspsc190501.41103024': __('identifierSchemeCodeUnspsc190501.41103024'),
	'identifierSchemeCodeUnspsc190501.41103025': __('identifierSchemeCodeUnspsc190501.41103025'),
	'identifierSchemeCodeUnspsc190501.41103026': __('identifierSchemeCodeUnspsc190501.41103026'),
	'identifierSchemeCodeUnspsc190501.41103027': __('identifierSchemeCodeUnspsc190501.41103027'),
	'identifierSchemeCodeUnspsc190501.41103028': __('identifierSchemeCodeUnspsc190501.41103028'),
	'identifierSchemeCodeUnspsc190501.41103029': __('identifierSchemeCodeUnspsc190501.41103029'),
	'identifierSchemeCodeUnspsc190501.41103030': __('identifierSchemeCodeUnspsc190501.41103030'),
	'identifierSchemeCodeUnspsc190501.41103031': __('identifierSchemeCodeUnspsc190501.41103031'),
	'identifierSchemeCodeUnspsc190501.41103032': __('identifierSchemeCodeUnspsc190501.41103032'),
	'identifierSchemeCodeUnspsc190501.41103033': __('identifierSchemeCodeUnspsc190501.41103033'),
	'identifierSchemeCodeUnspsc190501.41103034': __('identifierSchemeCodeUnspsc190501.41103034'),
	'identifierSchemeCodeUnspsc190501.41103035': __('identifierSchemeCodeUnspsc190501.41103035'),
	'identifierSchemeCodeUnspsc190501.41103036': __('identifierSchemeCodeUnspsc190501.41103036'),
	'identifierSchemeCodeUnspsc190501.41103037': __('identifierSchemeCodeUnspsc190501.41103037'),
	'identifierSchemeCodeUnspsc190501.41103200': __('identifierSchemeCodeUnspsc190501.41103200'),
	'identifierSchemeCodeUnspsc190501.41103201': __('identifierSchemeCodeUnspsc190501.41103201'),
	'identifierSchemeCodeUnspsc190501.41103202': __('identifierSchemeCodeUnspsc190501.41103202'),
	'identifierSchemeCodeUnspsc190501.41103203': __('identifierSchemeCodeUnspsc190501.41103203'),
	'identifierSchemeCodeUnspsc190501.41103205': __('identifierSchemeCodeUnspsc190501.41103205'),
	'identifierSchemeCodeUnspsc190501.41103206': __('identifierSchemeCodeUnspsc190501.41103206'),
	'identifierSchemeCodeUnspsc190501.41103207': __('identifierSchemeCodeUnspsc190501.41103207'),
	'identifierSchemeCodeUnspsc190501.41103208': __('identifierSchemeCodeUnspsc190501.41103208'),
	'identifierSchemeCodeUnspsc190501.41103209': __('identifierSchemeCodeUnspsc190501.41103209'),
	'identifierSchemeCodeUnspsc190501.41103210': __('identifierSchemeCodeUnspsc190501.41103210'),
	'identifierSchemeCodeUnspsc190501.41103211': __('identifierSchemeCodeUnspsc190501.41103211'),
	'identifierSchemeCodeUnspsc190501.41103212': __('identifierSchemeCodeUnspsc190501.41103212'),
	'identifierSchemeCodeUnspsc190501.41103213': __('identifierSchemeCodeUnspsc190501.41103213'),
	'identifierSchemeCodeUnspsc190501.41103300': __('identifierSchemeCodeUnspsc190501.41103300'),
	'identifierSchemeCodeUnspsc190501.41103301': __('identifierSchemeCodeUnspsc190501.41103301'),
	'identifierSchemeCodeUnspsc190501.41103302': __('identifierSchemeCodeUnspsc190501.41103302'),
	'identifierSchemeCodeUnspsc190501.41103303': __('identifierSchemeCodeUnspsc190501.41103303'),
	'identifierSchemeCodeUnspsc190501.41103305': __('identifierSchemeCodeUnspsc190501.41103305'),
	'identifierSchemeCodeUnspsc190501.41103306': __('identifierSchemeCodeUnspsc190501.41103306'),
	'identifierSchemeCodeUnspsc190501.41103307': __('identifierSchemeCodeUnspsc190501.41103307'),
	'identifierSchemeCodeUnspsc190501.41103308': __('identifierSchemeCodeUnspsc190501.41103308'),
	'identifierSchemeCodeUnspsc190501.41103309': __('identifierSchemeCodeUnspsc190501.41103309'),
	'identifierSchemeCodeUnspsc190501.41103310': __('identifierSchemeCodeUnspsc190501.41103310'),
	'identifierSchemeCodeUnspsc190501.41103311': __('identifierSchemeCodeUnspsc190501.41103311'),
	'identifierSchemeCodeUnspsc190501.41103312': __('identifierSchemeCodeUnspsc190501.41103312'),
	'identifierSchemeCodeUnspsc190501.41103313': __('identifierSchemeCodeUnspsc190501.41103313'),
	'identifierSchemeCodeUnspsc190501.41103314': __('identifierSchemeCodeUnspsc190501.41103314'),
	'identifierSchemeCodeUnspsc190501.41103315': __('identifierSchemeCodeUnspsc190501.41103315'),
	'identifierSchemeCodeUnspsc190501.41103316': __('identifierSchemeCodeUnspsc190501.41103316'),
	'identifierSchemeCodeUnspsc190501.41103317': __('identifierSchemeCodeUnspsc190501.41103317'),
	'identifierSchemeCodeUnspsc190501.41103318': __('identifierSchemeCodeUnspsc190501.41103318'),
	'identifierSchemeCodeUnspsc190501.41103319': __('identifierSchemeCodeUnspsc190501.41103319'),
	'identifierSchemeCodeUnspsc190501.41103320': __('identifierSchemeCodeUnspsc190501.41103320'),
	'identifierSchemeCodeUnspsc190501.41103321': __('identifierSchemeCodeUnspsc190501.41103321'),
	'identifierSchemeCodeUnspsc190501.41103322': __('identifierSchemeCodeUnspsc190501.41103322'),
	'identifierSchemeCodeUnspsc190501.41103323': __('identifierSchemeCodeUnspsc190501.41103323'),
	'identifierSchemeCodeUnspsc190501.41103324': __('identifierSchemeCodeUnspsc190501.41103324'),
	'identifierSchemeCodeUnspsc190501.41103325': __('identifierSchemeCodeUnspsc190501.41103325'),
	'identifierSchemeCodeUnspsc190501.41103326': __('identifierSchemeCodeUnspsc190501.41103326'),
	'identifierSchemeCodeUnspsc190501.41103327': __('identifierSchemeCodeUnspsc190501.41103327'),
	'identifierSchemeCodeUnspsc190501.41103328': __('identifierSchemeCodeUnspsc190501.41103328'),
	'identifierSchemeCodeUnspsc190501.41103400': __('identifierSchemeCodeUnspsc190501.41103400'),
	'identifierSchemeCodeUnspsc190501.41103401': __('identifierSchemeCodeUnspsc190501.41103401'),
	'identifierSchemeCodeUnspsc190501.41103403': __('identifierSchemeCodeUnspsc190501.41103403'),
	'identifierSchemeCodeUnspsc190501.41103406': __('identifierSchemeCodeUnspsc190501.41103406'),
	'identifierSchemeCodeUnspsc190501.41103407': __('identifierSchemeCodeUnspsc190501.41103407'),
	'identifierSchemeCodeUnspsc190501.41103408': __('identifierSchemeCodeUnspsc190501.41103408'),
	'identifierSchemeCodeUnspsc190501.41103409': __('identifierSchemeCodeUnspsc190501.41103409'),
	'identifierSchemeCodeUnspsc190501.41103410': __('identifierSchemeCodeUnspsc190501.41103410'),
	'identifierSchemeCodeUnspsc190501.41103411': __('identifierSchemeCodeUnspsc190501.41103411'),
	'identifierSchemeCodeUnspsc190501.41103412': __('identifierSchemeCodeUnspsc190501.41103412'),
	'identifierSchemeCodeUnspsc190501.41103413': __('identifierSchemeCodeUnspsc190501.41103413'),
	'identifierSchemeCodeUnspsc190501.41103414': __('identifierSchemeCodeUnspsc190501.41103414'),
	'identifierSchemeCodeUnspsc190501.41103415': __('identifierSchemeCodeUnspsc190501.41103415'),
	'identifierSchemeCodeUnspsc190501.41103416': __('identifierSchemeCodeUnspsc190501.41103416'),
	'identifierSchemeCodeUnspsc190501.41103417': __('identifierSchemeCodeUnspsc190501.41103417'),
	'identifierSchemeCodeUnspsc190501.41103418': __('identifierSchemeCodeUnspsc190501.41103418'),
	'identifierSchemeCodeUnspsc190501.41103419': __('identifierSchemeCodeUnspsc190501.41103419'),
	'identifierSchemeCodeUnspsc190501.41103420': __('identifierSchemeCodeUnspsc190501.41103420'),
	'identifierSchemeCodeUnspsc190501.41103421': __('identifierSchemeCodeUnspsc190501.41103421'),
	'identifierSchemeCodeUnspsc190501.41103500': __('identifierSchemeCodeUnspsc190501.41103500'),
	'identifierSchemeCodeUnspsc190501.41103501': __('identifierSchemeCodeUnspsc190501.41103501'),
	'identifierSchemeCodeUnspsc190501.41103502': __('identifierSchemeCodeUnspsc190501.41103502'),
	'identifierSchemeCodeUnspsc190501.41103504': __('identifierSchemeCodeUnspsc190501.41103504'),
	'identifierSchemeCodeUnspsc190501.41103506': __('identifierSchemeCodeUnspsc190501.41103506'),
	'identifierSchemeCodeUnspsc190501.41103507': __('identifierSchemeCodeUnspsc190501.41103507'),
	'identifierSchemeCodeUnspsc190501.41103508': __('identifierSchemeCodeUnspsc190501.41103508'),
	'identifierSchemeCodeUnspsc190501.41103509': __('identifierSchemeCodeUnspsc190501.41103509'),
	'identifierSchemeCodeUnspsc190501.41103510': __('identifierSchemeCodeUnspsc190501.41103510'),
	'identifierSchemeCodeUnspsc190501.41103511': __('identifierSchemeCodeUnspsc190501.41103511'),
	'identifierSchemeCodeUnspsc190501.41103512': __('identifierSchemeCodeUnspsc190501.41103512'),
	'identifierSchemeCodeUnspsc190501.41103513': __('identifierSchemeCodeUnspsc190501.41103513'),
	'identifierSchemeCodeUnspsc190501.41103514': __('identifierSchemeCodeUnspsc190501.41103514'),
	'identifierSchemeCodeUnspsc190501.41103515': __('identifierSchemeCodeUnspsc190501.41103515'),
	'identifierSchemeCodeUnspsc190501.41103516': __('identifierSchemeCodeUnspsc190501.41103516'),
	'identifierSchemeCodeUnspsc190501.41103517': __('identifierSchemeCodeUnspsc190501.41103517'),
	'identifierSchemeCodeUnspsc190501.41103700': __('identifierSchemeCodeUnspsc190501.41103700'),
	'identifierSchemeCodeUnspsc190501.41103701': __('identifierSchemeCodeUnspsc190501.41103701'),
	'identifierSchemeCodeUnspsc190501.41103702': __('identifierSchemeCodeUnspsc190501.41103702'),
	'identifierSchemeCodeUnspsc190501.41103703': __('identifierSchemeCodeUnspsc190501.41103703'),
	'identifierSchemeCodeUnspsc190501.41103704': __('identifierSchemeCodeUnspsc190501.41103704'),
	'identifierSchemeCodeUnspsc190501.41103705': __('identifierSchemeCodeUnspsc190501.41103705'),
	'identifierSchemeCodeUnspsc190501.41103706': __('identifierSchemeCodeUnspsc190501.41103706'),
	'identifierSchemeCodeUnspsc190501.41103707': __('identifierSchemeCodeUnspsc190501.41103707'),
	'identifierSchemeCodeUnspsc190501.41103708': __('identifierSchemeCodeUnspsc190501.41103708'),
	'identifierSchemeCodeUnspsc190501.41103709': __('identifierSchemeCodeUnspsc190501.41103709'),
	'identifierSchemeCodeUnspsc190501.41103710': __('identifierSchemeCodeUnspsc190501.41103710'),
	'identifierSchemeCodeUnspsc190501.41103711': __('identifierSchemeCodeUnspsc190501.41103711'),
	'identifierSchemeCodeUnspsc190501.41103712': __('identifierSchemeCodeUnspsc190501.41103712'),
	'identifierSchemeCodeUnspsc190501.41103713': __('identifierSchemeCodeUnspsc190501.41103713'),
	'identifierSchemeCodeUnspsc190501.41103714': __('identifierSchemeCodeUnspsc190501.41103714'),
	'identifierSchemeCodeUnspsc190501.41103715': __('identifierSchemeCodeUnspsc190501.41103715'),
	'identifierSchemeCodeUnspsc190501.41103716': __('identifierSchemeCodeUnspsc190501.41103716'),
	'identifierSchemeCodeUnspsc190501.41103717': __('identifierSchemeCodeUnspsc190501.41103717'),
	'identifierSchemeCodeUnspsc190501.41103718': __('identifierSchemeCodeUnspsc190501.41103718'),
	'identifierSchemeCodeUnspsc190501.41103800': __('identifierSchemeCodeUnspsc190501.41103800'),
	'identifierSchemeCodeUnspsc190501.41103801': __('identifierSchemeCodeUnspsc190501.41103801'),
	'identifierSchemeCodeUnspsc190501.41103802': __('identifierSchemeCodeUnspsc190501.41103802'),
	'identifierSchemeCodeUnspsc190501.41103803': __('identifierSchemeCodeUnspsc190501.41103803'),
	'identifierSchemeCodeUnspsc190501.41103804': __('identifierSchemeCodeUnspsc190501.41103804'),
	'identifierSchemeCodeUnspsc190501.41103805': __('identifierSchemeCodeUnspsc190501.41103805'),
	'identifierSchemeCodeUnspsc190501.41103806': __('identifierSchemeCodeUnspsc190501.41103806'),
	'identifierSchemeCodeUnspsc190501.41103807': __('identifierSchemeCodeUnspsc190501.41103807'),
	'identifierSchemeCodeUnspsc190501.41103808': __('identifierSchemeCodeUnspsc190501.41103808'),
	'identifierSchemeCodeUnspsc190501.41103809': __('identifierSchemeCodeUnspsc190501.41103809'),
	'identifierSchemeCodeUnspsc190501.41103810': __('identifierSchemeCodeUnspsc190501.41103810'),
	'identifierSchemeCodeUnspsc190501.41103811': __('identifierSchemeCodeUnspsc190501.41103811'),
	'identifierSchemeCodeUnspsc190501.41103812': __('identifierSchemeCodeUnspsc190501.41103812'),
	'identifierSchemeCodeUnspsc190501.41103813': __('identifierSchemeCodeUnspsc190501.41103813'),
	'identifierSchemeCodeUnspsc190501.41103814': __('identifierSchemeCodeUnspsc190501.41103814'),
	'identifierSchemeCodeUnspsc190501.41103815': __('identifierSchemeCodeUnspsc190501.41103815'),
	'identifierSchemeCodeUnspsc190501.41103816': __('identifierSchemeCodeUnspsc190501.41103816'),
	'identifierSchemeCodeUnspsc190501.41103817': __('identifierSchemeCodeUnspsc190501.41103817'),
	'identifierSchemeCodeUnspsc190501.41103818': __('identifierSchemeCodeUnspsc190501.41103818'),
	'identifierSchemeCodeUnspsc190501.41103819': __('identifierSchemeCodeUnspsc190501.41103819'),
	'identifierSchemeCodeUnspsc190501.41103900': __('identifierSchemeCodeUnspsc190501.41103900'),
	'identifierSchemeCodeUnspsc190501.41103901': __('identifierSchemeCodeUnspsc190501.41103901'),
	'identifierSchemeCodeUnspsc190501.41103902': __('identifierSchemeCodeUnspsc190501.41103902'),
	'identifierSchemeCodeUnspsc190501.41103903': __('identifierSchemeCodeUnspsc190501.41103903'),
	'identifierSchemeCodeUnspsc190501.41103904': __('identifierSchemeCodeUnspsc190501.41103904'),
	'identifierSchemeCodeUnspsc190501.41103905': __('identifierSchemeCodeUnspsc190501.41103905'),
	'identifierSchemeCodeUnspsc190501.41103906': __('identifierSchemeCodeUnspsc190501.41103906'),
	'identifierSchemeCodeUnspsc190501.41103907': __('identifierSchemeCodeUnspsc190501.41103907'),
	'identifierSchemeCodeUnspsc190501.41103908': __('identifierSchemeCodeUnspsc190501.41103908'),
	'identifierSchemeCodeUnspsc190501.41103909': __('identifierSchemeCodeUnspsc190501.41103909'),
	'identifierSchemeCodeUnspsc190501.41103910': __('identifierSchemeCodeUnspsc190501.41103910'),
	'identifierSchemeCodeUnspsc190501.41103911': __('identifierSchemeCodeUnspsc190501.41103911'),
	'identifierSchemeCodeUnspsc190501.41103912': __('identifierSchemeCodeUnspsc190501.41103912'),
	'identifierSchemeCodeUnspsc190501.41103913': __('identifierSchemeCodeUnspsc190501.41103913'),
	'identifierSchemeCodeUnspsc190501.41103914': __('identifierSchemeCodeUnspsc190501.41103914'),
	'identifierSchemeCodeUnspsc190501.41103915': __('identifierSchemeCodeUnspsc190501.41103915'),
	'identifierSchemeCodeUnspsc190501.41103916': __('identifierSchemeCodeUnspsc190501.41103916'),
	'identifierSchemeCodeUnspsc190501.41103917': __('identifierSchemeCodeUnspsc190501.41103917'),
	'identifierSchemeCodeUnspsc190501.41104000': __('identifierSchemeCodeUnspsc190501.41104000'),
	'identifierSchemeCodeUnspsc190501.41104001': __('identifierSchemeCodeUnspsc190501.41104001'),
	'identifierSchemeCodeUnspsc190501.41104002': __('identifierSchemeCodeUnspsc190501.41104002'),
	'identifierSchemeCodeUnspsc190501.41104003': __('identifierSchemeCodeUnspsc190501.41104003'),
	'identifierSchemeCodeUnspsc190501.41104004': __('identifierSchemeCodeUnspsc190501.41104004'),
	'identifierSchemeCodeUnspsc190501.41104005': __('identifierSchemeCodeUnspsc190501.41104005'),
	'identifierSchemeCodeUnspsc190501.41104006': __('identifierSchemeCodeUnspsc190501.41104006'),
	'identifierSchemeCodeUnspsc190501.41104007': __('identifierSchemeCodeUnspsc190501.41104007'),
	'identifierSchemeCodeUnspsc190501.41104008': __('identifierSchemeCodeUnspsc190501.41104008'),
	'identifierSchemeCodeUnspsc190501.41104009': __('identifierSchemeCodeUnspsc190501.41104009'),
	'identifierSchemeCodeUnspsc190501.41104010': __('identifierSchemeCodeUnspsc190501.41104010'),
	'identifierSchemeCodeUnspsc190501.41104011': __('identifierSchemeCodeUnspsc190501.41104011'),
	'identifierSchemeCodeUnspsc190501.41104012': __('identifierSchemeCodeUnspsc190501.41104012'),
	'identifierSchemeCodeUnspsc190501.41104013': __('identifierSchemeCodeUnspsc190501.41104013'),
	'identifierSchemeCodeUnspsc190501.41104014': __('identifierSchemeCodeUnspsc190501.41104014'),
	'identifierSchemeCodeUnspsc190501.41104015': __('identifierSchemeCodeUnspsc190501.41104015'),
	'identifierSchemeCodeUnspsc190501.41104016': __('identifierSchemeCodeUnspsc190501.41104016'),
	'identifierSchemeCodeUnspsc190501.41104017': __('identifierSchemeCodeUnspsc190501.41104017'),
	'identifierSchemeCodeUnspsc190501.41104018': __('identifierSchemeCodeUnspsc190501.41104018'),
	'identifierSchemeCodeUnspsc190501.41104019': __('identifierSchemeCodeUnspsc190501.41104019'),
	'identifierSchemeCodeUnspsc190501.41104020': __('identifierSchemeCodeUnspsc190501.41104020'),
	'identifierSchemeCodeUnspsc190501.41104021': __('identifierSchemeCodeUnspsc190501.41104021'),
	'identifierSchemeCodeUnspsc190501.41104022': __('identifierSchemeCodeUnspsc190501.41104022'),
	'identifierSchemeCodeUnspsc190501.41104023': __('identifierSchemeCodeUnspsc190501.41104023'),
	'identifierSchemeCodeUnspsc190501.41104100': __('identifierSchemeCodeUnspsc190501.41104100'),
	'identifierSchemeCodeUnspsc190501.41104101': __('identifierSchemeCodeUnspsc190501.41104101'),
	'identifierSchemeCodeUnspsc190501.41104102': __('identifierSchemeCodeUnspsc190501.41104102'),
	'identifierSchemeCodeUnspsc190501.41104103': __('identifierSchemeCodeUnspsc190501.41104103'),
	'identifierSchemeCodeUnspsc190501.41104104': __('identifierSchemeCodeUnspsc190501.41104104'),
	'identifierSchemeCodeUnspsc190501.41104105': __('identifierSchemeCodeUnspsc190501.41104105'),
	'identifierSchemeCodeUnspsc190501.41104106': __('identifierSchemeCodeUnspsc190501.41104106'),
	'identifierSchemeCodeUnspsc190501.41104107': __('identifierSchemeCodeUnspsc190501.41104107'),
	'identifierSchemeCodeUnspsc190501.41104108': __('identifierSchemeCodeUnspsc190501.41104108'),
	'identifierSchemeCodeUnspsc190501.41104109': __('identifierSchemeCodeUnspsc190501.41104109'),
	'identifierSchemeCodeUnspsc190501.41104110': __('identifierSchemeCodeUnspsc190501.41104110'),
	'identifierSchemeCodeUnspsc190501.41104111': __('identifierSchemeCodeUnspsc190501.41104111'),
	'identifierSchemeCodeUnspsc190501.41104112': __('identifierSchemeCodeUnspsc190501.41104112'),
	'identifierSchemeCodeUnspsc190501.41104114': __('identifierSchemeCodeUnspsc190501.41104114'),
	'identifierSchemeCodeUnspsc190501.41104115': __('identifierSchemeCodeUnspsc190501.41104115'),
	'identifierSchemeCodeUnspsc190501.41104116': __('identifierSchemeCodeUnspsc190501.41104116'),
	'identifierSchemeCodeUnspsc190501.41104117': __('identifierSchemeCodeUnspsc190501.41104117'),
	'identifierSchemeCodeUnspsc190501.41104118': __('identifierSchemeCodeUnspsc190501.41104118'),
	'identifierSchemeCodeUnspsc190501.41104119': __('identifierSchemeCodeUnspsc190501.41104119'),
	'identifierSchemeCodeUnspsc190501.41104120': __('identifierSchemeCodeUnspsc190501.41104120'),
	'identifierSchemeCodeUnspsc190501.41104121': __('identifierSchemeCodeUnspsc190501.41104121'),
	'identifierSchemeCodeUnspsc190501.41104122': __('identifierSchemeCodeUnspsc190501.41104122'),
	'identifierSchemeCodeUnspsc190501.41104123': __('identifierSchemeCodeUnspsc190501.41104123'),
	'identifierSchemeCodeUnspsc190501.41104124': __('identifierSchemeCodeUnspsc190501.41104124'),
	'identifierSchemeCodeUnspsc190501.41104125': __('identifierSchemeCodeUnspsc190501.41104125'),
	'identifierSchemeCodeUnspsc190501.41104126': __('identifierSchemeCodeUnspsc190501.41104126'),
	'identifierSchemeCodeUnspsc190501.41104127': __('identifierSchemeCodeUnspsc190501.41104127'),
	'identifierSchemeCodeUnspsc190501.41104128': __('identifierSchemeCodeUnspsc190501.41104128'),
	'identifierSchemeCodeUnspsc190501.41104129': __('identifierSchemeCodeUnspsc190501.41104129'),
	'identifierSchemeCodeUnspsc190501.41104130': __('identifierSchemeCodeUnspsc190501.41104130'),
	'identifierSchemeCodeUnspsc190501.41104131': __('identifierSchemeCodeUnspsc190501.41104131'),
	'identifierSchemeCodeUnspsc190501.41104132': __('identifierSchemeCodeUnspsc190501.41104132'),
	'identifierSchemeCodeUnspsc190501.41104133': __('identifierSchemeCodeUnspsc190501.41104133'),
	'identifierSchemeCodeUnspsc190501.41104134': __('identifierSchemeCodeUnspsc190501.41104134'),
	'identifierSchemeCodeUnspsc190501.41104135': __('identifierSchemeCodeUnspsc190501.41104135'),
	'identifierSchemeCodeUnspsc190501.41104136': __('identifierSchemeCodeUnspsc190501.41104136'),
	'identifierSchemeCodeUnspsc190501.41104137': __('identifierSchemeCodeUnspsc190501.41104137'),
	'identifierSchemeCodeUnspsc190501.41104138': __('identifierSchemeCodeUnspsc190501.41104138'),
	'identifierSchemeCodeUnspsc190501.41104200': __('identifierSchemeCodeUnspsc190501.41104200'),
	'identifierSchemeCodeUnspsc190501.41104201': __('identifierSchemeCodeUnspsc190501.41104201'),
	'identifierSchemeCodeUnspsc190501.41104202': __('identifierSchemeCodeUnspsc190501.41104202'),
	'identifierSchemeCodeUnspsc190501.41104203': __('identifierSchemeCodeUnspsc190501.41104203'),
	'identifierSchemeCodeUnspsc190501.41104204': __('identifierSchemeCodeUnspsc190501.41104204'),
	'identifierSchemeCodeUnspsc190501.41104205': __('identifierSchemeCodeUnspsc190501.41104205'),
	'identifierSchemeCodeUnspsc190501.41104206': __('identifierSchemeCodeUnspsc190501.41104206'),
	'identifierSchemeCodeUnspsc190501.41104207': __('identifierSchemeCodeUnspsc190501.41104207'),
	'identifierSchemeCodeUnspsc190501.41104208': __('identifierSchemeCodeUnspsc190501.41104208'),
	'identifierSchemeCodeUnspsc190501.41104209': __('identifierSchemeCodeUnspsc190501.41104209'),
	'identifierSchemeCodeUnspsc190501.41104210': __('identifierSchemeCodeUnspsc190501.41104210'),
	'identifierSchemeCodeUnspsc190501.41104211': __('identifierSchemeCodeUnspsc190501.41104211'),
	'identifierSchemeCodeUnspsc190501.41104212': __('identifierSchemeCodeUnspsc190501.41104212'),
	'identifierSchemeCodeUnspsc190501.41104213': __('identifierSchemeCodeUnspsc190501.41104213'),
	'identifierSchemeCodeUnspsc190501.41104214': __('identifierSchemeCodeUnspsc190501.41104214'),
	'identifierSchemeCodeUnspsc190501.41104300': __('identifierSchemeCodeUnspsc190501.41104300'),
	'identifierSchemeCodeUnspsc190501.41104301': __('identifierSchemeCodeUnspsc190501.41104301'),
	'identifierSchemeCodeUnspsc190501.41104302': __('identifierSchemeCodeUnspsc190501.41104302'),
	'identifierSchemeCodeUnspsc190501.41104303': __('identifierSchemeCodeUnspsc190501.41104303'),
	'identifierSchemeCodeUnspsc190501.41104304': __('identifierSchemeCodeUnspsc190501.41104304'),
	'identifierSchemeCodeUnspsc190501.41104305': __('identifierSchemeCodeUnspsc190501.41104305'),
	'identifierSchemeCodeUnspsc190501.41104306': __('identifierSchemeCodeUnspsc190501.41104306'),
	'identifierSchemeCodeUnspsc190501.41104307': __('identifierSchemeCodeUnspsc190501.41104307'),
	'identifierSchemeCodeUnspsc190501.41104308': __('identifierSchemeCodeUnspsc190501.41104308'),
	'identifierSchemeCodeUnspsc190501.41104309': __('identifierSchemeCodeUnspsc190501.41104309'),
	'identifierSchemeCodeUnspsc190501.41104400': __('identifierSchemeCodeUnspsc190501.41104400'),
	'identifierSchemeCodeUnspsc190501.41104401': __('identifierSchemeCodeUnspsc190501.41104401'),
	'identifierSchemeCodeUnspsc190501.41104402': __('identifierSchemeCodeUnspsc190501.41104402'),
	'identifierSchemeCodeUnspsc190501.41104403': __('identifierSchemeCodeUnspsc190501.41104403'),
	'identifierSchemeCodeUnspsc190501.41104404': __('identifierSchemeCodeUnspsc190501.41104404'),
	'identifierSchemeCodeUnspsc190501.41104405': __('identifierSchemeCodeUnspsc190501.41104405'),
	'identifierSchemeCodeUnspsc190501.41104406': __('identifierSchemeCodeUnspsc190501.41104406'),
	'identifierSchemeCodeUnspsc190501.41104407': __('identifierSchemeCodeUnspsc190501.41104407'),
	'identifierSchemeCodeUnspsc190501.41104408': __('identifierSchemeCodeUnspsc190501.41104408'),
	'identifierSchemeCodeUnspsc190501.41104409': __('identifierSchemeCodeUnspsc190501.41104409'),
	'identifierSchemeCodeUnspsc190501.41104410': __('identifierSchemeCodeUnspsc190501.41104410'),
	'identifierSchemeCodeUnspsc190501.41104411': __('identifierSchemeCodeUnspsc190501.41104411'),
	'identifierSchemeCodeUnspsc190501.41104412': __('identifierSchemeCodeUnspsc190501.41104412'),
	'identifierSchemeCodeUnspsc190501.41104413': __('identifierSchemeCodeUnspsc190501.41104413'),
	'identifierSchemeCodeUnspsc190501.41104414': __('identifierSchemeCodeUnspsc190501.41104414'),
	'identifierSchemeCodeUnspsc190501.41104415': __('identifierSchemeCodeUnspsc190501.41104415'),
	'identifierSchemeCodeUnspsc190501.41104416': __('identifierSchemeCodeUnspsc190501.41104416'),
	'identifierSchemeCodeUnspsc190501.41104417': __('identifierSchemeCodeUnspsc190501.41104417'),
	'identifierSchemeCodeUnspsc190501.41104418': __('identifierSchemeCodeUnspsc190501.41104418'),
	'identifierSchemeCodeUnspsc190501.41104419': __('identifierSchemeCodeUnspsc190501.41104419'),
	'identifierSchemeCodeUnspsc190501.41104420': __('identifierSchemeCodeUnspsc190501.41104420'),
	'identifierSchemeCodeUnspsc190501.41104421': __('identifierSchemeCodeUnspsc190501.41104421'),
	'identifierSchemeCodeUnspsc190501.41104422': __('identifierSchemeCodeUnspsc190501.41104422'),
	'identifierSchemeCodeUnspsc190501.41104423': __('identifierSchemeCodeUnspsc190501.41104423'),
	'identifierSchemeCodeUnspsc190501.41104424': __('identifierSchemeCodeUnspsc190501.41104424'),
	'identifierSchemeCodeUnspsc190501.41104425': __('identifierSchemeCodeUnspsc190501.41104425'),
	'identifierSchemeCodeUnspsc190501.41104426': __('identifierSchemeCodeUnspsc190501.41104426'),
	'identifierSchemeCodeUnspsc190501.41104427': __('identifierSchemeCodeUnspsc190501.41104427'),
	'identifierSchemeCodeUnspsc190501.41104500': __('identifierSchemeCodeUnspsc190501.41104500'),
	'identifierSchemeCodeUnspsc190501.41104501': __('identifierSchemeCodeUnspsc190501.41104501'),
	'identifierSchemeCodeUnspsc190501.41104502': __('identifierSchemeCodeUnspsc190501.41104502'),
	'identifierSchemeCodeUnspsc190501.41104503': __('identifierSchemeCodeUnspsc190501.41104503'),
	'identifierSchemeCodeUnspsc190501.41104504': __('identifierSchemeCodeUnspsc190501.41104504'),
	'identifierSchemeCodeUnspsc190501.41104505': __('identifierSchemeCodeUnspsc190501.41104505'),
	'identifierSchemeCodeUnspsc190501.41104506': __('identifierSchemeCodeUnspsc190501.41104506'),
	'identifierSchemeCodeUnspsc190501.41104507': __('identifierSchemeCodeUnspsc190501.41104507'),
	'identifierSchemeCodeUnspsc190501.41104508': __('identifierSchemeCodeUnspsc190501.41104508'),
	'identifierSchemeCodeUnspsc190501.41104509': __('identifierSchemeCodeUnspsc190501.41104509'),
	'identifierSchemeCodeUnspsc190501.41104510': __('identifierSchemeCodeUnspsc190501.41104510'),
	'identifierSchemeCodeUnspsc190501.41104511': __('identifierSchemeCodeUnspsc190501.41104511'),
	'identifierSchemeCodeUnspsc190501.41104512': __('identifierSchemeCodeUnspsc190501.41104512'),
	'identifierSchemeCodeUnspsc190501.41104513': __('identifierSchemeCodeUnspsc190501.41104513'),
	'identifierSchemeCodeUnspsc190501.41104600': __('identifierSchemeCodeUnspsc190501.41104600'),
	'identifierSchemeCodeUnspsc190501.41104601': __('identifierSchemeCodeUnspsc190501.41104601'),
	'identifierSchemeCodeUnspsc190501.41104602': __('identifierSchemeCodeUnspsc190501.41104602'),
	'identifierSchemeCodeUnspsc190501.41104603': __('identifierSchemeCodeUnspsc190501.41104603'),
	'identifierSchemeCodeUnspsc190501.41104604': __('identifierSchemeCodeUnspsc190501.41104604'),
	'identifierSchemeCodeUnspsc190501.41104605': __('identifierSchemeCodeUnspsc190501.41104605'),
	'identifierSchemeCodeUnspsc190501.41104606': __('identifierSchemeCodeUnspsc190501.41104606'),
	'identifierSchemeCodeUnspsc190501.41104607': __('identifierSchemeCodeUnspsc190501.41104607'),
	'identifierSchemeCodeUnspsc190501.41104608': __('identifierSchemeCodeUnspsc190501.41104608'),
	'identifierSchemeCodeUnspsc190501.41104609': __('identifierSchemeCodeUnspsc190501.41104609'),
	'identifierSchemeCodeUnspsc190501.41104610': __('identifierSchemeCodeUnspsc190501.41104610'),
	'identifierSchemeCodeUnspsc190501.41104611': __('identifierSchemeCodeUnspsc190501.41104611'),
	'identifierSchemeCodeUnspsc190501.41104612': __('identifierSchemeCodeUnspsc190501.41104612'),
	'identifierSchemeCodeUnspsc190501.41104613': __('identifierSchemeCodeUnspsc190501.41104613'),
	'identifierSchemeCodeUnspsc190501.41104700': __('identifierSchemeCodeUnspsc190501.41104700'),
	'identifierSchemeCodeUnspsc190501.41104701': __('identifierSchemeCodeUnspsc190501.41104701'),
	'identifierSchemeCodeUnspsc190501.41104702': __('identifierSchemeCodeUnspsc190501.41104702'),
	'identifierSchemeCodeUnspsc190501.41104703': __('identifierSchemeCodeUnspsc190501.41104703'),
	'identifierSchemeCodeUnspsc190501.41104704': __('identifierSchemeCodeUnspsc190501.41104704'),
	'identifierSchemeCodeUnspsc190501.41104800': __('identifierSchemeCodeUnspsc190501.41104800'),
	'identifierSchemeCodeUnspsc190501.41104801': __('identifierSchemeCodeUnspsc190501.41104801'),
	'identifierSchemeCodeUnspsc190501.41104802': __('identifierSchemeCodeUnspsc190501.41104802'),
	'identifierSchemeCodeUnspsc190501.41104803': __('identifierSchemeCodeUnspsc190501.41104803'),
	'identifierSchemeCodeUnspsc190501.41104804': __('identifierSchemeCodeUnspsc190501.41104804'),
	'identifierSchemeCodeUnspsc190501.41104805': __('identifierSchemeCodeUnspsc190501.41104805'),
	'identifierSchemeCodeUnspsc190501.41104806': __('identifierSchemeCodeUnspsc190501.41104806'),
	'identifierSchemeCodeUnspsc190501.41104807': __('identifierSchemeCodeUnspsc190501.41104807'),
	'identifierSchemeCodeUnspsc190501.41104808': __('identifierSchemeCodeUnspsc190501.41104808'),
	'identifierSchemeCodeUnspsc190501.41104809': __('identifierSchemeCodeUnspsc190501.41104809'),
	'identifierSchemeCodeUnspsc190501.41104810': __('identifierSchemeCodeUnspsc190501.41104810'),
	'identifierSchemeCodeUnspsc190501.41104811': __('identifierSchemeCodeUnspsc190501.41104811'),
	'identifierSchemeCodeUnspsc190501.41104812': __('identifierSchemeCodeUnspsc190501.41104812'),
	'identifierSchemeCodeUnspsc190501.41104813': __('identifierSchemeCodeUnspsc190501.41104813'),
	'identifierSchemeCodeUnspsc190501.41104814': __('identifierSchemeCodeUnspsc190501.41104814'),
	'identifierSchemeCodeUnspsc190501.41104815': __('identifierSchemeCodeUnspsc190501.41104815'),
	'identifierSchemeCodeUnspsc190501.41104816': __('identifierSchemeCodeUnspsc190501.41104816'),
	'identifierSchemeCodeUnspsc190501.41104817': __('identifierSchemeCodeUnspsc190501.41104817'),
	'identifierSchemeCodeUnspsc190501.41104818': __('identifierSchemeCodeUnspsc190501.41104818'),
	'identifierSchemeCodeUnspsc190501.41104819': __('identifierSchemeCodeUnspsc190501.41104819'),
	'identifierSchemeCodeUnspsc190501.41104820': __('identifierSchemeCodeUnspsc190501.41104820'),
	'identifierSchemeCodeUnspsc190501.41104821': __('identifierSchemeCodeUnspsc190501.41104821'),
	'identifierSchemeCodeUnspsc190501.41104900': __('identifierSchemeCodeUnspsc190501.41104900'),
	'identifierSchemeCodeUnspsc190501.41104901': __('identifierSchemeCodeUnspsc190501.41104901'),
	'identifierSchemeCodeUnspsc190501.41104902': __('identifierSchemeCodeUnspsc190501.41104902'),
	'identifierSchemeCodeUnspsc190501.41104903': __('identifierSchemeCodeUnspsc190501.41104903'),
	'identifierSchemeCodeUnspsc190501.41104904': __('identifierSchemeCodeUnspsc190501.41104904'),
	'identifierSchemeCodeUnspsc190501.41104905': __('identifierSchemeCodeUnspsc190501.41104905'),
	'identifierSchemeCodeUnspsc190501.41104906': __('identifierSchemeCodeUnspsc190501.41104906'),
	'identifierSchemeCodeUnspsc190501.41104907': __('identifierSchemeCodeUnspsc190501.41104907'),
	'identifierSchemeCodeUnspsc190501.41104908': __('identifierSchemeCodeUnspsc190501.41104908'),
	'identifierSchemeCodeUnspsc190501.41104909': __('identifierSchemeCodeUnspsc190501.41104909'),
	'identifierSchemeCodeUnspsc190501.41104910': __('identifierSchemeCodeUnspsc190501.41104910'),
	'identifierSchemeCodeUnspsc190501.41104911': __('identifierSchemeCodeUnspsc190501.41104911'),
	'identifierSchemeCodeUnspsc190501.41104912': __('identifierSchemeCodeUnspsc190501.41104912'),
	'identifierSchemeCodeUnspsc190501.41104913': __('identifierSchemeCodeUnspsc190501.41104913'),
	'identifierSchemeCodeUnspsc190501.41104914': __('identifierSchemeCodeUnspsc190501.41104914'),
	'identifierSchemeCodeUnspsc190501.41104915': __('identifierSchemeCodeUnspsc190501.41104915'),
	'identifierSchemeCodeUnspsc190501.41104916': __('identifierSchemeCodeUnspsc190501.41104916'),
	'identifierSchemeCodeUnspsc190501.41104917': __('identifierSchemeCodeUnspsc190501.41104917'),
	'identifierSchemeCodeUnspsc190501.41104918': __('identifierSchemeCodeUnspsc190501.41104918'),
	'identifierSchemeCodeUnspsc190501.41104919': __('identifierSchemeCodeUnspsc190501.41104919'),
	'identifierSchemeCodeUnspsc190501.41104920': __('identifierSchemeCodeUnspsc190501.41104920'),
	'identifierSchemeCodeUnspsc190501.41104921': __('identifierSchemeCodeUnspsc190501.41104921'),
	'identifierSchemeCodeUnspsc190501.41104922': __('identifierSchemeCodeUnspsc190501.41104922'),
	'identifierSchemeCodeUnspsc190501.41104923': __('identifierSchemeCodeUnspsc190501.41104923'),
	'identifierSchemeCodeUnspsc190501.41104924': __('identifierSchemeCodeUnspsc190501.41104924'),
	'identifierSchemeCodeUnspsc190501.41104925': __('identifierSchemeCodeUnspsc190501.41104925'),
	'identifierSchemeCodeUnspsc190501.41104926': __('identifierSchemeCodeUnspsc190501.41104926'),
	'identifierSchemeCodeUnspsc190501.41104927': __('identifierSchemeCodeUnspsc190501.41104927'),
	'identifierSchemeCodeUnspsc190501.41104928': __('identifierSchemeCodeUnspsc190501.41104928'),
	'identifierSchemeCodeUnspsc190501.41104929': __('identifierSchemeCodeUnspsc190501.41104929'),
	'identifierSchemeCodeUnspsc190501.41104930': __('identifierSchemeCodeUnspsc190501.41104930'),
	'identifierSchemeCodeUnspsc190501.41104931': __('identifierSchemeCodeUnspsc190501.41104931'),
	'identifierSchemeCodeUnspsc190501.41104932': __('identifierSchemeCodeUnspsc190501.41104932'),
	'identifierSchemeCodeUnspsc190501.41105000': __('identifierSchemeCodeUnspsc190501.41105000'),
	'identifierSchemeCodeUnspsc190501.41105001': __('identifierSchemeCodeUnspsc190501.41105001'),
	'identifierSchemeCodeUnspsc190501.41105002': __('identifierSchemeCodeUnspsc190501.41105002'),
	'identifierSchemeCodeUnspsc190501.41105003': __('identifierSchemeCodeUnspsc190501.41105003'),
	'identifierSchemeCodeUnspsc190501.41105100': __('identifierSchemeCodeUnspsc190501.41105100'),
	'identifierSchemeCodeUnspsc190501.41105101': __('identifierSchemeCodeUnspsc190501.41105101'),
	'identifierSchemeCodeUnspsc190501.41105102': __('identifierSchemeCodeUnspsc190501.41105102'),
	'identifierSchemeCodeUnspsc190501.41105103': __('identifierSchemeCodeUnspsc190501.41105103'),
	'identifierSchemeCodeUnspsc190501.41105104': __('identifierSchemeCodeUnspsc190501.41105104'),
	'identifierSchemeCodeUnspsc190501.41105105': __('identifierSchemeCodeUnspsc190501.41105105'),
	'identifierSchemeCodeUnspsc190501.41105106': __('identifierSchemeCodeUnspsc190501.41105106'),
	'identifierSchemeCodeUnspsc190501.41105107': __('identifierSchemeCodeUnspsc190501.41105107'),
	'identifierSchemeCodeUnspsc190501.41105108': __('identifierSchemeCodeUnspsc190501.41105108'),
	'identifierSchemeCodeUnspsc190501.41105109': __('identifierSchemeCodeUnspsc190501.41105109'),
	'identifierSchemeCodeUnspsc190501.41105200': __('identifierSchemeCodeUnspsc190501.41105200'),
	'identifierSchemeCodeUnspsc190501.41105201': __('identifierSchemeCodeUnspsc190501.41105201'),
	'identifierSchemeCodeUnspsc190501.41105202': __('identifierSchemeCodeUnspsc190501.41105202'),
	'identifierSchemeCodeUnspsc190501.41105203': __('identifierSchemeCodeUnspsc190501.41105203'),
	'identifierSchemeCodeUnspsc190501.41105204': __('identifierSchemeCodeUnspsc190501.41105204'),
	'identifierSchemeCodeUnspsc190501.41105205': __('identifierSchemeCodeUnspsc190501.41105205'),
	'identifierSchemeCodeUnspsc190501.41105206': __('identifierSchemeCodeUnspsc190501.41105206'),
	'identifierSchemeCodeUnspsc190501.41105207': __('identifierSchemeCodeUnspsc190501.41105207'),
	'identifierSchemeCodeUnspsc190501.41105208': __('identifierSchemeCodeUnspsc190501.41105208'),
	'identifierSchemeCodeUnspsc190501.41105209': __('identifierSchemeCodeUnspsc190501.41105209'),
	'identifierSchemeCodeUnspsc190501.41105300': __('identifierSchemeCodeUnspsc190501.41105300'),
	'identifierSchemeCodeUnspsc190501.41105301': __('identifierSchemeCodeUnspsc190501.41105301'),
	'identifierSchemeCodeUnspsc190501.41105302': __('identifierSchemeCodeUnspsc190501.41105302'),
	'identifierSchemeCodeUnspsc190501.41105303': __('identifierSchemeCodeUnspsc190501.41105303'),
	'identifierSchemeCodeUnspsc190501.41105304': __('identifierSchemeCodeUnspsc190501.41105304'),
	'identifierSchemeCodeUnspsc190501.41105305': __('identifierSchemeCodeUnspsc190501.41105305'),
	'identifierSchemeCodeUnspsc190501.41105307': __('identifierSchemeCodeUnspsc190501.41105307'),
	'identifierSchemeCodeUnspsc190501.41105308': __('identifierSchemeCodeUnspsc190501.41105308'),
	'identifierSchemeCodeUnspsc190501.41105309': __('identifierSchemeCodeUnspsc190501.41105309'),
	'identifierSchemeCodeUnspsc190501.41105310': __('identifierSchemeCodeUnspsc190501.41105310'),
	'identifierSchemeCodeUnspsc190501.41105311': __('identifierSchemeCodeUnspsc190501.41105311'),
	'identifierSchemeCodeUnspsc190501.41105312': __('identifierSchemeCodeUnspsc190501.41105312'),
	'identifierSchemeCodeUnspsc190501.41105313': __('identifierSchemeCodeUnspsc190501.41105313'),
	'identifierSchemeCodeUnspsc190501.41105314': __('identifierSchemeCodeUnspsc190501.41105314'),
	'identifierSchemeCodeUnspsc190501.41105315': __('identifierSchemeCodeUnspsc190501.41105315'),
	'identifierSchemeCodeUnspsc190501.41105316': __('identifierSchemeCodeUnspsc190501.41105316'),
	'identifierSchemeCodeUnspsc190501.41105317': __('identifierSchemeCodeUnspsc190501.41105317'),
	'identifierSchemeCodeUnspsc190501.41105318': __('identifierSchemeCodeUnspsc190501.41105318'),
	'identifierSchemeCodeUnspsc190501.41105319': __('identifierSchemeCodeUnspsc190501.41105319'),
	'identifierSchemeCodeUnspsc190501.41105320': __('identifierSchemeCodeUnspsc190501.41105320'),
	'identifierSchemeCodeUnspsc190501.41105321': __('identifierSchemeCodeUnspsc190501.41105321'),
	'identifierSchemeCodeUnspsc190501.41105322': __('identifierSchemeCodeUnspsc190501.41105322'),
	'identifierSchemeCodeUnspsc190501.41105323': __('identifierSchemeCodeUnspsc190501.41105323'),
	'identifierSchemeCodeUnspsc190501.41105324': __('identifierSchemeCodeUnspsc190501.41105324'),
	'identifierSchemeCodeUnspsc190501.41105325': __('identifierSchemeCodeUnspsc190501.41105325'),
	'identifierSchemeCodeUnspsc190501.41105326': __('identifierSchemeCodeUnspsc190501.41105326'),
	'identifierSchemeCodeUnspsc190501.41105327': __('identifierSchemeCodeUnspsc190501.41105327'),
	'identifierSchemeCodeUnspsc190501.41105328': __('identifierSchemeCodeUnspsc190501.41105328'),
	'identifierSchemeCodeUnspsc190501.41105329': __('identifierSchemeCodeUnspsc190501.41105329'),
	'identifierSchemeCodeUnspsc190501.41105330': __('identifierSchemeCodeUnspsc190501.41105330'),
	'identifierSchemeCodeUnspsc190501.41105331': __('identifierSchemeCodeUnspsc190501.41105331'),
	'identifierSchemeCodeUnspsc190501.41105332': __('identifierSchemeCodeUnspsc190501.41105332'),
	'identifierSchemeCodeUnspsc190501.41105333': __('identifierSchemeCodeUnspsc190501.41105333'),
	'identifierSchemeCodeUnspsc190501.41105334': __('identifierSchemeCodeUnspsc190501.41105334'),
	'identifierSchemeCodeUnspsc190501.41105335': __('identifierSchemeCodeUnspsc190501.41105335'),
	'identifierSchemeCodeUnspsc190501.41105336': __('identifierSchemeCodeUnspsc190501.41105336'),
	'identifierSchemeCodeUnspsc190501.41105337': __('identifierSchemeCodeUnspsc190501.41105337'),
	'identifierSchemeCodeUnspsc190501.41105338': __('identifierSchemeCodeUnspsc190501.41105338'),
	'identifierSchemeCodeUnspsc190501.41105339': __('identifierSchemeCodeUnspsc190501.41105339'),
	'identifierSchemeCodeUnspsc190501.41105340': __('identifierSchemeCodeUnspsc190501.41105340'),
	'identifierSchemeCodeUnspsc190501.41105341': __('identifierSchemeCodeUnspsc190501.41105341'),
	'identifierSchemeCodeUnspsc190501.41105342': __('identifierSchemeCodeUnspsc190501.41105342'),
	'identifierSchemeCodeUnspsc190501.41105343': __('identifierSchemeCodeUnspsc190501.41105343'),
	'identifierSchemeCodeUnspsc190501.41105344': __('identifierSchemeCodeUnspsc190501.41105344'),
	'identifierSchemeCodeUnspsc190501.41105345': __('identifierSchemeCodeUnspsc190501.41105345'),
	'identifierSchemeCodeUnspsc190501.41105500': __('identifierSchemeCodeUnspsc190501.41105500'),
	'identifierSchemeCodeUnspsc190501.41105501': __('identifierSchemeCodeUnspsc190501.41105501'),
	'identifierSchemeCodeUnspsc190501.41105502': __('identifierSchemeCodeUnspsc190501.41105502'),
	'identifierSchemeCodeUnspsc190501.41105503': __('identifierSchemeCodeUnspsc190501.41105503'),
	'identifierSchemeCodeUnspsc190501.41105504': __('identifierSchemeCodeUnspsc190501.41105504'),
	'identifierSchemeCodeUnspsc190501.41105505': __('identifierSchemeCodeUnspsc190501.41105505'),
	'identifierSchemeCodeUnspsc190501.41105506': __('identifierSchemeCodeUnspsc190501.41105506'),
	'identifierSchemeCodeUnspsc190501.41105507': __('identifierSchemeCodeUnspsc190501.41105507'),
	'identifierSchemeCodeUnspsc190501.41105508': __('identifierSchemeCodeUnspsc190501.41105508'),
	'identifierSchemeCodeUnspsc190501.41105509': __('identifierSchemeCodeUnspsc190501.41105509'),
	'identifierSchemeCodeUnspsc190501.41105510': __('identifierSchemeCodeUnspsc190501.41105510'),
	'identifierSchemeCodeUnspsc190501.41105511': __('identifierSchemeCodeUnspsc190501.41105511'),
	'identifierSchemeCodeUnspsc190501.41105512': __('identifierSchemeCodeUnspsc190501.41105512'),
	'identifierSchemeCodeUnspsc190501.41105513': __('identifierSchemeCodeUnspsc190501.41105513'),
	'identifierSchemeCodeUnspsc190501.41105514': __('identifierSchemeCodeUnspsc190501.41105514'),
	'identifierSchemeCodeUnspsc190501.41105515': __('identifierSchemeCodeUnspsc190501.41105515'),
	'identifierSchemeCodeUnspsc190501.41105516': __('identifierSchemeCodeUnspsc190501.41105516'),
	'identifierSchemeCodeUnspsc190501.41105517': __('identifierSchemeCodeUnspsc190501.41105517'),
	'identifierSchemeCodeUnspsc190501.41105518': __('identifierSchemeCodeUnspsc190501.41105518'),
	'identifierSchemeCodeUnspsc190501.41105519': __('identifierSchemeCodeUnspsc190501.41105519'),
	'identifierSchemeCodeUnspsc190501.41105520': __('identifierSchemeCodeUnspsc190501.41105520'),
	'identifierSchemeCodeUnspsc190501.41105521': __('identifierSchemeCodeUnspsc190501.41105521'),
	'identifierSchemeCodeUnspsc190501.41105600': __('identifierSchemeCodeUnspsc190501.41105600'),
	'identifierSchemeCodeUnspsc190501.41105601': __('identifierSchemeCodeUnspsc190501.41105601'),
	'identifierSchemeCodeUnspsc190501.41105700': __('identifierSchemeCodeUnspsc190501.41105700'),
	'identifierSchemeCodeUnspsc190501.41105701': __('identifierSchemeCodeUnspsc190501.41105701'),
	'identifierSchemeCodeUnspsc190501.41105800': __('identifierSchemeCodeUnspsc190501.41105800'),
	'identifierSchemeCodeUnspsc190501.41105801': __('identifierSchemeCodeUnspsc190501.41105801'),
	'identifierSchemeCodeUnspsc190501.41105802': __('identifierSchemeCodeUnspsc190501.41105802'),
	'identifierSchemeCodeUnspsc190501.41105803': __('identifierSchemeCodeUnspsc190501.41105803'),
	'identifierSchemeCodeUnspsc190501.41105804': __('identifierSchemeCodeUnspsc190501.41105804'),
	'identifierSchemeCodeUnspsc190501.41105900': __('identifierSchemeCodeUnspsc190501.41105900'),
	'identifierSchemeCodeUnspsc190501.41105901': __('identifierSchemeCodeUnspsc190501.41105901'),
	'identifierSchemeCodeUnspsc190501.41105902': __('identifierSchemeCodeUnspsc190501.41105902'),
	'identifierSchemeCodeUnspsc190501.41105903': __('identifierSchemeCodeUnspsc190501.41105903'),
	'identifierSchemeCodeUnspsc190501.41105904': __('identifierSchemeCodeUnspsc190501.41105904'),
	'identifierSchemeCodeUnspsc190501.41105905': __('identifierSchemeCodeUnspsc190501.41105905'),
	'identifierSchemeCodeUnspsc190501.41105906': __('identifierSchemeCodeUnspsc190501.41105906'),
	'identifierSchemeCodeUnspsc190501.41105907': __('identifierSchemeCodeUnspsc190501.41105907'),
	'identifierSchemeCodeUnspsc190501.41105908': __('identifierSchemeCodeUnspsc190501.41105908'),
	'identifierSchemeCodeUnspsc190501.41105909': __('identifierSchemeCodeUnspsc190501.41105909'),
	'identifierSchemeCodeUnspsc190501.41106000': __('identifierSchemeCodeUnspsc190501.41106000'),
	'identifierSchemeCodeUnspsc190501.41106001': __('identifierSchemeCodeUnspsc190501.41106001'),
	'identifierSchemeCodeUnspsc190501.41106002': __('identifierSchemeCodeUnspsc190501.41106002'),
	'identifierSchemeCodeUnspsc190501.41106003': __('identifierSchemeCodeUnspsc190501.41106003'),
	'identifierSchemeCodeUnspsc190501.41106004': __('identifierSchemeCodeUnspsc190501.41106004'),
	'identifierSchemeCodeUnspsc190501.41106005': __('identifierSchemeCodeUnspsc190501.41106005'),
	'identifierSchemeCodeUnspsc190501.41106006': __('identifierSchemeCodeUnspsc190501.41106006'),
	'identifierSchemeCodeUnspsc190501.41106100': __('identifierSchemeCodeUnspsc190501.41106100'),
	'identifierSchemeCodeUnspsc190501.41106101': __('identifierSchemeCodeUnspsc190501.41106101'),
	'identifierSchemeCodeUnspsc190501.41106102': __('identifierSchemeCodeUnspsc190501.41106102'),
	'identifierSchemeCodeUnspsc190501.41106103': __('identifierSchemeCodeUnspsc190501.41106103'),
	'identifierSchemeCodeUnspsc190501.41106104': __('identifierSchemeCodeUnspsc190501.41106104'),
	'identifierSchemeCodeUnspsc190501.41106200': __('identifierSchemeCodeUnspsc190501.41106200'),
	'identifierSchemeCodeUnspsc190501.41106201': __('identifierSchemeCodeUnspsc190501.41106201'),
	'identifierSchemeCodeUnspsc190501.41106202': __('identifierSchemeCodeUnspsc190501.41106202'),
	'identifierSchemeCodeUnspsc190501.41106203': __('identifierSchemeCodeUnspsc190501.41106203'),
	'identifierSchemeCodeUnspsc190501.41106204': __('identifierSchemeCodeUnspsc190501.41106204'),
	'identifierSchemeCodeUnspsc190501.41106205': __('identifierSchemeCodeUnspsc190501.41106205'),
	'identifierSchemeCodeUnspsc190501.41106206': __('identifierSchemeCodeUnspsc190501.41106206'),
	'identifierSchemeCodeUnspsc190501.41106207': __('identifierSchemeCodeUnspsc190501.41106207'),
	'identifierSchemeCodeUnspsc190501.41106208': __('identifierSchemeCodeUnspsc190501.41106208'),
	'identifierSchemeCodeUnspsc190501.41106209': __('identifierSchemeCodeUnspsc190501.41106209'),
	'identifierSchemeCodeUnspsc190501.41106210': __('identifierSchemeCodeUnspsc190501.41106210'),
	'identifierSchemeCodeUnspsc190501.41106211': __('identifierSchemeCodeUnspsc190501.41106211'),
	'identifierSchemeCodeUnspsc190501.41106212': __('identifierSchemeCodeUnspsc190501.41106212'),
	'identifierSchemeCodeUnspsc190501.41106213': __('identifierSchemeCodeUnspsc190501.41106213'),
	'identifierSchemeCodeUnspsc190501.41106214': __('identifierSchemeCodeUnspsc190501.41106214'),
	'identifierSchemeCodeUnspsc190501.41106215': __('identifierSchemeCodeUnspsc190501.41106215'),
	'identifierSchemeCodeUnspsc190501.41106216': __('identifierSchemeCodeUnspsc190501.41106216'),
	'identifierSchemeCodeUnspsc190501.41106217': __('identifierSchemeCodeUnspsc190501.41106217'),
	'identifierSchemeCodeUnspsc190501.41106218': __('identifierSchemeCodeUnspsc190501.41106218'),
	'identifierSchemeCodeUnspsc190501.41106219': __('identifierSchemeCodeUnspsc190501.41106219'),
	'identifierSchemeCodeUnspsc190501.41106220': __('identifierSchemeCodeUnspsc190501.41106220'),
	'identifierSchemeCodeUnspsc190501.41106221': __('identifierSchemeCodeUnspsc190501.41106221'),
	'identifierSchemeCodeUnspsc190501.41106222': __('identifierSchemeCodeUnspsc190501.41106222'),
	'identifierSchemeCodeUnspsc190501.41106223': __('identifierSchemeCodeUnspsc190501.41106223'),
	'identifierSchemeCodeUnspsc190501.41106224': __('identifierSchemeCodeUnspsc190501.41106224'),
	'identifierSchemeCodeUnspsc190501.41106225': __('identifierSchemeCodeUnspsc190501.41106225'),
	'identifierSchemeCodeUnspsc190501.41106226': __('identifierSchemeCodeUnspsc190501.41106226'),
	'identifierSchemeCodeUnspsc190501.41106227': __('identifierSchemeCodeUnspsc190501.41106227'),
	'identifierSchemeCodeUnspsc190501.41106228': __('identifierSchemeCodeUnspsc190501.41106228'),
	'identifierSchemeCodeUnspsc190501.41106229': __('identifierSchemeCodeUnspsc190501.41106229'),
	'identifierSchemeCodeUnspsc190501.41106230': __('identifierSchemeCodeUnspsc190501.41106230'),
	'identifierSchemeCodeUnspsc190501.41106231': __('identifierSchemeCodeUnspsc190501.41106231'),
	'identifierSchemeCodeUnspsc190501.41106232': __('identifierSchemeCodeUnspsc190501.41106232'),
	'identifierSchemeCodeUnspsc190501.41106233': __('identifierSchemeCodeUnspsc190501.41106233'),
	'identifierSchemeCodeUnspsc190501.41106300': __('identifierSchemeCodeUnspsc190501.41106300'),
	'identifierSchemeCodeUnspsc190501.41106301': __('identifierSchemeCodeUnspsc190501.41106301'),
	'identifierSchemeCodeUnspsc190501.41106302': __('identifierSchemeCodeUnspsc190501.41106302'),
	'identifierSchemeCodeUnspsc190501.41106303': __('identifierSchemeCodeUnspsc190501.41106303'),
	'identifierSchemeCodeUnspsc190501.41106304': __('identifierSchemeCodeUnspsc190501.41106304'),
	'identifierSchemeCodeUnspsc190501.41106305': __('identifierSchemeCodeUnspsc190501.41106305'),
	'identifierSchemeCodeUnspsc190501.41106306': __('identifierSchemeCodeUnspsc190501.41106306'),
	'identifierSchemeCodeUnspsc190501.41106307': __('identifierSchemeCodeUnspsc190501.41106307'),
	'identifierSchemeCodeUnspsc190501.41106308': __('identifierSchemeCodeUnspsc190501.41106308'),
	'identifierSchemeCodeUnspsc190501.41106309': __('identifierSchemeCodeUnspsc190501.41106309'),
	'identifierSchemeCodeUnspsc190501.41106310': __('identifierSchemeCodeUnspsc190501.41106310'),
	'identifierSchemeCodeUnspsc190501.41106311': __('identifierSchemeCodeUnspsc190501.41106311'),
	'identifierSchemeCodeUnspsc190501.41106312': __('identifierSchemeCodeUnspsc190501.41106312'),
	'identifierSchemeCodeUnspsc190501.41106313': __('identifierSchemeCodeUnspsc190501.41106313'),
	'identifierSchemeCodeUnspsc190501.41106314': __('identifierSchemeCodeUnspsc190501.41106314'),
	'identifierSchemeCodeUnspsc190501.41106400': __('identifierSchemeCodeUnspsc190501.41106400'),
	'identifierSchemeCodeUnspsc190501.41106401': __('identifierSchemeCodeUnspsc190501.41106401'),
	'identifierSchemeCodeUnspsc190501.41106402': __('identifierSchemeCodeUnspsc190501.41106402'),
	'identifierSchemeCodeUnspsc190501.41106403': __('identifierSchemeCodeUnspsc190501.41106403'),
	'identifierSchemeCodeUnspsc190501.41106500': __('identifierSchemeCodeUnspsc190501.41106500'),
	'identifierSchemeCodeUnspsc190501.41106501': __('identifierSchemeCodeUnspsc190501.41106501'),
	'identifierSchemeCodeUnspsc190501.41106502': __('identifierSchemeCodeUnspsc190501.41106502'),
	'identifierSchemeCodeUnspsc190501.41106503': __('identifierSchemeCodeUnspsc190501.41106503'),
	'identifierSchemeCodeUnspsc190501.41106504': __('identifierSchemeCodeUnspsc190501.41106504'),
	'identifierSchemeCodeUnspsc190501.41106505': __('identifierSchemeCodeUnspsc190501.41106505'),
	'identifierSchemeCodeUnspsc190501.41106506': __('identifierSchemeCodeUnspsc190501.41106506'),
	'identifierSchemeCodeUnspsc190501.41106507': __('identifierSchemeCodeUnspsc190501.41106507'),
	'identifierSchemeCodeUnspsc190501.41106508': __('identifierSchemeCodeUnspsc190501.41106508'),
	'identifierSchemeCodeUnspsc190501.41106509': __('identifierSchemeCodeUnspsc190501.41106509'),
	'identifierSchemeCodeUnspsc190501.41106510': __('identifierSchemeCodeUnspsc190501.41106510'),
	'identifierSchemeCodeUnspsc190501.41106511': __('identifierSchemeCodeUnspsc190501.41106511'),
	'identifierSchemeCodeUnspsc190501.41106512': __('identifierSchemeCodeUnspsc190501.41106512'),
	'identifierSchemeCodeUnspsc190501.41106513': __('identifierSchemeCodeUnspsc190501.41106513'),
	'identifierSchemeCodeUnspsc190501.41106514': __('identifierSchemeCodeUnspsc190501.41106514'),
	'identifierSchemeCodeUnspsc190501.41106515': __('identifierSchemeCodeUnspsc190501.41106515'),
	'identifierSchemeCodeUnspsc190501.41106516': __('identifierSchemeCodeUnspsc190501.41106516'),
	'identifierSchemeCodeUnspsc190501.41106600': __('identifierSchemeCodeUnspsc190501.41106600'),
	'identifierSchemeCodeUnspsc190501.41106601': __('identifierSchemeCodeUnspsc190501.41106601'),
	'identifierSchemeCodeUnspsc190501.41106602': __('identifierSchemeCodeUnspsc190501.41106602'),
	'identifierSchemeCodeUnspsc190501.41106603': __('identifierSchemeCodeUnspsc190501.41106603'),
	'identifierSchemeCodeUnspsc190501.41106604': __('identifierSchemeCodeUnspsc190501.41106604'),
	'identifierSchemeCodeUnspsc190501.41106605': __('identifierSchemeCodeUnspsc190501.41106605'),
	'identifierSchemeCodeUnspsc190501.41106606': __('identifierSchemeCodeUnspsc190501.41106606'),
	'identifierSchemeCodeUnspsc190501.41106607': __('identifierSchemeCodeUnspsc190501.41106607'),
	'identifierSchemeCodeUnspsc190501.41106608': __('identifierSchemeCodeUnspsc190501.41106608'),
	'identifierSchemeCodeUnspsc190501.41106609': __('identifierSchemeCodeUnspsc190501.41106609'),
	'identifierSchemeCodeUnspsc190501.41106610': __('identifierSchemeCodeUnspsc190501.41106610'),
	'identifierSchemeCodeUnspsc190501.41106611': __('identifierSchemeCodeUnspsc190501.41106611'),
	'identifierSchemeCodeUnspsc190501.41106612': __('identifierSchemeCodeUnspsc190501.41106612'),
	'identifierSchemeCodeUnspsc190501.41106613': __('identifierSchemeCodeUnspsc190501.41106613'),
	'identifierSchemeCodeUnspsc190501.41106614': __('identifierSchemeCodeUnspsc190501.41106614'),
	'identifierSchemeCodeUnspsc190501.41106615': __('identifierSchemeCodeUnspsc190501.41106615'),
	'identifierSchemeCodeUnspsc190501.41106616': __('identifierSchemeCodeUnspsc190501.41106616'),
	'identifierSchemeCodeUnspsc190501.41106617': __('identifierSchemeCodeUnspsc190501.41106617'),
	'identifierSchemeCodeUnspsc190501.41106618': __('identifierSchemeCodeUnspsc190501.41106618'),
	'identifierSchemeCodeUnspsc190501.41106619': __('identifierSchemeCodeUnspsc190501.41106619'),
	'identifierSchemeCodeUnspsc190501.41106620': __('identifierSchemeCodeUnspsc190501.41106620'),
	'identifierSchemeCodeUnspsc190501.41106621': __('identifierSchemeCodeUnspsc190501.41106621'),
	'identifierSchemeCodeUnspsc190501.41106622': __('identifierSchemeCodeUnspsc190501.41106622'),
	'identifierSchemeCodeUnspsc190501.41106700': __('identifierSchemeCodeUnspsc190501.41106700'),
	'identifierSchemeCodeUnspsc190501.41106701': __('identifierSchemeCodeUnspsc190501.41106701'),
	'identifierSchemeCodeUnspsc190501.41106702': __('identifierSchemeCodeUnspsc190501.41106702'),
	'identifierSchemeCodeUnspsc190501.41106703': __('identifierSchemeCodeUnspsc190501.41106703'),
	'identifierSchemeCodeUnspsc190501.41106704': __('identifierSchemeCodeUnspsc190501.41106704'),
	'identifierSchemeCodeUnspsc190501.41106705': __('identifierSchemeCodeUnspsc190501.41106705'),
	'identifierSchemeCodeUnspsc190501.41106706': __('identifierSchemeCodeUnspsc190501.41106706'),
	'identifierSchemeCodeUnspsc190501.41106707': __('identifierSchemeCodeUnspsc190501.41106707'),
	'identifierSchemeCodeUnspsc190501.41106708': __('identifierSchemeCodeUnspsc190501.41106708'),
	'identifierSchemeCodeUnspsc190501.41110000': __('identifierSchemeCodeUnspsc190501.41110000'),
	'identifierSchemeCodeUnspsc190501.41111500': __('identifierSchemeCodeUnspsc190501.41111500'),
	'identifierSchemeCodeUnspsc190501.41111501': __('identifierSchemeCodeUnspsc190501.41111501'),
	'identifierSchemeCodeUnspsc190501.41111502': __('identifierSchemeCodeUnspsc190501.41111502'),
	'identifierSchemeCodeUnspsc190501.41111503': __('identifierSchemeCodeUnspsc190501.41111503'),
	'identifierSchemeCodeUnspsc190501.41111504': __('identifierSchemeCodeUnspsc190501.41111504'),
	'identifierSchemeCodeUnspsc190501.41111505': __('identifierSchemeCodeUnspsc190501.41111505'),
	'identifierSchemeCodeUnspsc190501.41111506': __('identifierSchemeCodeUnspsc190501.41111506'),
	'identifierSchemeCodeUnspsc190501.41111507': __('identifierSchemeCodeUnspsc190501.41111507'),
	'identifierSchemeCodeUnspsc190501.41111508': __('identifierSchemeCodeUnspsc190501.41111508'),
	'identifierSchemeCodeUnspsc190501.41111509': __('identifierSchemeCodeUnspsc190501.41111509'),
	'identifierSchemeCodeUnspsc190501.41111510': __('identifierSchemeCodeUnspsc190501.41111510'),
	'identifierSchemeCodeUnspsc190501.41111511': __('identifierSchemeCodeUnspsc190501.41111511'),
	'identifierSchemeCodeUnspsc190501.41111512': __('identifierSchemeCodeUnspsc190501.41111512'),
	'identifierSchemeCodeUnspsc190501.41111513': __('identifierSchemeCodeUnspsc190501.41111513'),
	'identifierSchemeCodeUnspsc190501.41111515': __('identifierSchemeCodeUnspsc190501.41111515'),
	'identifierSchemeCodeUnspsc190501.41111516': __('identifierSchemeCodeUnspsc190501.41111516'),
	'identifierSchemeCodeUnspsc190501.41111517': __('identifierSchemeCodeUnspsc190501.41111517'),
	'identifierSchemeCodeUnspsc190501.41111518': __('identifierSchemeCodeUnspsc190501.41111518'),
	'identifierSchemeCodeUnspsc190501.41111519': __('identifierSchemeCodeUnspsc190501.41111519'),
	'identifierSchemeCodeUnspsc190501.41111520': __('identifierSchemeCodeUnspsc190501.41111520'),
	'identifierSchemeCodeUnspsc190501.41111521': __('identifierSchemeCodeUnspsc190501.41111521'),
	'identifierSchemeCodeUnspsc190501.41111522': __('identifierSchemeCodeUnspsc190501.41111522'),
	'identifierSchemeCodeUnspsc190501.41111523': __('identifierSchemeCodeUnspsc190501.41111523'),
	'identifierSchemeCodeUnspsc190501.41111524': __('identifierSchemeCodeUnspsc190501.41111524'),
	'identifierSchemeCodeUnspsc190501.41111525': __('identifierSchemeCodeUnspsc190501.41111525'),
	'identifierSchemeCodeUnspsc190501.41111526': __('identifierSchemeCodeUnspsc190501.41111526'),
	'identifierSchemeCodeUnspsc190501.41111527': __('identifierSchemeCodeUnspsc190501.41111527'),
	'identifierSchemeCodeUnspsc190501.41111600': __('identifierSchemeCodeUnspsc190501.41111600'),
	'identifierSchemeCodeUnspsc190501.41111601': __('identifierSchemeCodeUnspsc190501.41111601'),
	'identifierSchemeCodeUnspsc190501.41111602': __('identifierSchemeCodeUnspsc190501.41111602'),
	'identifierSchemeCodeUnspsc190501.41111603': __('identifierSchemeCodeUnspsc190501.41111603'),
	'identifierSchemeCodeUnspsc190501.41111604': __('identifierSchemeCodeUnspsc190501.41111604'),
	'identifierSchemeCodeUnspsc190501.41111605': __('identifierSchemeCodeUnspsc190501.41111605'),
	'identifierSchemeCodeUnspsc190501.41111606': __('identifierSchemeCodeUnspsc190501.41111606'),
	'identifierSchemeCodeUnspsc190501.41111607': __('identifierSchemeCodeUnspsc190501.41111607'),
	'identifierSchemeCodeUnspsc190501.41111613': __('identifierSchemeCodeUnspsc190501.41111613'),
	'identifierSchemeCodeUnspsc190501.41111614': __('identifierSchemeCodeUnspsc190501.41111614'),
	'identifierSchemeCodeUnspsc190501.41111615': __('identifierSchemeCodeUnspsc190501.41111615'),
	'identifierSchemeCodeUnspsc190501.41111616': __('identifierSchemeCodeUnspsc190501.41111616'),
	'identifierSchemeCodeUnspsc190501.41111617': __('identifierSchemeCodeUnspsc190501.41111617'),
	'identifierSchemeCodeUnspsc190501.41111618': __('identifierSchemeCodeUnspsc190501.41111618'),
	'identifierSchemeCodeUnspsc190501.41111619': __('identifierSchemeCodeUnspsc190501.41111619'),
	'identifierSchemeCodeUnspsc190501.41111620': __('identifierSchemeCodeUnspsc190501.41111620'),
	'identifierSchemeCodeUnspsc190501.41111621': __('identifierSchemeCodeUnspsc190501.41111621'),
	'identifierSchemeCodeUnspsc190501.41111622': __('identifierSchemeCodeUnspsc190501.41111622'),
	'identifierSchemeCodeUnspsc190501.41111623': __('identifierSchemeCodeUnspsc190501.41111623'),
	'identifierSchemeCodeUnspsc190501.41111624': __('identifierSchemeCodeUnspsc190501.41111624'),
	'identifierSchemeCodeUnspsc190501.41111625': __('identifierSchemeCodeUnspsc190501.41111625'),
	'identifierSchemeCodeUnspsc190501.41111626': __('identifierSchemeCodeUnspsc190501.41111626'),
	'identifierSchemeCodeUnspsc190501.41111627': __('identifierSchemeCodeUnspsc190501.41111627'),
	'identifierSchemeCodeUnspsc190501.41111628': __('identifierSchemeCodeUnspsc190501.41111628'),
	'identifierSchemeCodeUnspsc190501.41111629': __('identifierSchemeCodeUnspsc190501.41111629'),
	'identifierSchemeCodeUnspsc190501.41111630': __('identifierSchemeCodeUnspsc190501.41111630'),
	'identifierSchemeCodeUnspsc190501.41111631': __('identifierSchemeCodeUnspsc190501.41111631'),
	'identifierSchemeCodeUnspsc190501.41111632': __('identifierSchemeCodeUnspsc190501.41111632'),
	'identifierSchemeCodeUnspsc190501.41111633': __('identifierSchemeCodeUnspsc190501.41111633'),
	'identifierSchemeCodeUnspsc190501.41111634': __('identifierSchemeCodeUnspsc190501.41111634'),
	'identifierSchemeCodeUnspsc190501.41111635': __('identifierSchemeCodeUnspsc190501.41111635'),
	'identifierSchemeCodeUnspsc190501.41111636': __('identifierSchemeCodeUnspsc190501.41111636'),
	'identifierSchemeCodeUnspsc190501.41111637': __('identifierSchemeCodeUnspsc190501.41111637'),
	'identifierSchemeCodeUnspsc190501.41111638': __('identifierSchemeCodeUnspsc190501.41111638'),
	'identifierSchemeCodeUnspsc190501.41111639': __('identifierSchemeCodeUnspsc190501.41111639'),
	'identifierSchemeCodeUnspsc190501.41111640': __('identifierSchemeCodeUnspsc190501.41111640'),
	'identifierSchemeCodeUnspsc190501.41111641': __('identifierSchemeCodeUnspsc190501.41111641'),
	'identifierSchemeCodeUnspsc190501.41111642': __('identifierSchemeCodeUnspsc190501.41111642'),
	'identifierSchemeCodeUnspsc190501.41111643': __('identifierSchemeCodeUnspsc190501.41111643'),
	'identifierSchemeCodeUnspsc190501.41111644': __('identifierSchemeCodeUnspsc190501.41111644'),
	'identifierSchemeCodeUnspsc190501.41111645': __('identifierSchemeCodeUnspsc190501.41111645'),
	'identifierSchemeCodeUnspsc190501.41111646': __('identifierSchemeCodeUnspsc190501.41111646'),
	'identifierSchemeCodeUnspsc190501.41111647': __('identifierSchemeCodeUnspsc190501.41111647'),
	'identifierSchemeCodeUnspsc190501.41111648': __('identifierSchemeCodeUnspsc190501.41111648'),
	'identifierSchemeCodeUnspsc190501.41111649': __('identifierSchemeCodeUnspsc190501.41111649'),
	'identifierSchemeCodeUnspsc190501.41111650': __('identifierSchemeCodeUnspsc190501.41111650'),
	'identifierSchemeCodeUnspsc190501.41111651': __('identifierSchemeCodeUnspsc190501.41111651'),
	'identifierSchemeCodeUnspsc190501.41111652': __('identifierSchemeCodeUnspsc190501.41111652'),
	'identifierSchemeCodeUnspsc190501.41111700': __('identifierSchemeCodeUnspsc190501.41111700'),
	'identifierSchemeCodeUnspsc190501.41111701': __('identifierSchemeCodeUnspsc190501.41111701'),
	'identifierSchemeCodeUnspsc190501.41111702': __('identifierSchemeCodeUnspsc190501.41111702'),
	'identifierSchemeCodeUnspsc190501.41111703': __('identifierSchemeCodeUnspsc190501.41111703'),
	'identifierSchemeCodeUnspsc190501.41111704': __('identifierSchemeCodeUnspsc190501.41111704'),
	'identifierSchemeCodeUnspsc190501.41111705': __('identifierSchemeCodeUnspsc190501.41111705'),
	'identifierSchemeCodeUnspsc190501.41111706': __('identifierSchemeCodeUnspsc190501.41111706'),
	'identifierSchemeCodeUnspsc190501.41111707': __('identifierSchemeCodeUnspsc190501.41111707'),
	'identifierSchemeCodeUnspsc190501.41111708': __('identifierSchemeCodeUnspsc190501.41111708'),
	'identifierSchemeCodeUnspsc190501.41111709': __('identifierSchemeCodeUnspsc190501.41111709'),
	'identifierSchemeCodeUnspsc190501.41111710': __('identifierSchemeCodeUnspsc190501.41111710'),
	'identifierSchemeCodeUnspsc190501.41111711': __('identifierSchemeCodeUnspsc190501.41111711'),
	'identifierSchemeCodeUnspsc190501.41111712': __('identifierSchemeCodeUnspsc190501.41111712'),
	'identifierSchemeCodeUnspsc190501.41111713': __('identifierSchemeCodeUnspsc190501.41111713'),
	'identifierSchemeCodeUnspsc190501.41111714': __('identifierSchemeCodeUnspsc190501.41111714'),
	'identifierSchemeCodeUnspsc190501.41111715': __('identifierSchemeCodeUnspsc190501.41111715'),
	'identifierSchemeCodeUnspsc190501.41111716': __('identifierSchemeCodeUnspsc190501.41111716'),
	'identifierSchemeCodeUnspsc190501.41111717': __('identifierSchemeCodeUnspsc190501.41111717'),
	'identifierSchemeCodeUnspsc190501.41111718': __('identifierSchemeCodeUnspsc190501.41111718'),
	'identifierSchemeCodeUnspsc190501.41111719': __('identifierSchemeCodeUnspsc190501.41111719'),
	'identifierSchemeCodeUnspsc190501.41111720': __('identifierSchemeCodeUnspsc190501.41111720'),
	'identifierSchemeCodeUnspsc190501.41111721': __('identifierSchemeCodeUnspsc190501.41111721'),
	'identifierSchemeCodeUnspsc190501.41111722': __('identifierSchemeCodeUnspsc190501.41111722'),
	'identifierSchemeCodeUnspsc190501.41111723': __('identifierSchemeCodeUnspsc190501.41111723'),
	'identifierSchemeCodeUnspsc190501.41111724': __('identifierSchemeCodeUnspsc190501.41111724'),
	'identifierSchemeCodeUnspsc190501.41111725': __('identifierSchemeCodeUnspsc190501.41111725'),
	'identifierSchemeCodeUnspsc190501.41111726': __('identifierSchemeCodeUnspsc190501.41111726'),
	'identifierSchemeCodeUnspsc190501.41111727': __('identifierSchemeCodeUnspsc190501.41111727'),
	'identifierSchemeCodeUnspsc190501.41111728': __('identifierSchemeCodeUnspsc190501.41111728'),
	'identifierSchemeCodeUnspsc190501.41111729': __('identifierSchemeCodeUnspsc190501.41111729'),
	'identifierSchemeCodeUnspsc190501.41111730': __('identifierSchemeCodeUnspsc190501.41111730'),
	'identifierSchemeCodeUnspsc190501.41111731': __('identifierSchemeCodeUnspsc190501.41111731'),
	'identifierSchemeCodeUnspsc190501.41111733': __('identifierSchemeCodeUnspsc190501.41111733'),
	'identifierSchemeCodeUnspsc190501.41111734': __('identifierSchemeCodeUnspsc190501.41111734'),
	'identifierSchemeCodeUnspsc190501.41111735': __('identifierSchemeCodeUnspsc190501.41111735'),
	'identifierSchemeCodeUnspsc190501.41111736': __('identifierSchemeCodeUnspsc190501.41111736'),
	'identifierSchemeCodeUnspsc190501.41111737': __('identifierSchemeCodeUnspsc190501.41111737'),
	'identifierSchemeCodeUnspsc190501.41111738': __('identifierSchemeCodeUnspsc190501.41111738'),
	'identifierSchemeCodeUnspsc190501.41111739': __('identifierSchemeCodeUnspsc190501.41111739'),
	'identifierSchemeCodeUnspsc190501.41111740': __('identifierSchemeCodeUnspsc190501.41111740'),
	'identifierSchemeCodeUnspsc190501.41111741': __('identifierSchemeCodeUnspsc190501.41111741'),
	'identifierSchemeCodeUnspsc190501.41111742': __('identifierSchemeCodeUnspsc190501.41111742'),
	'identifierSchemeCodeUnspsc190501.41111743': __('identifierSchemeCodeUnspsc190501.41111743'),
	'identifierSchemeCodeUnspsc190501.41111744': __('identifierSchemeCodeUnspsc190501.41111744'),
	'identifierSchemeCodeUnspsc190501.41111745': __('identifierSchemeCodeUnspsc190501.41111745'),
	'identifierSchemeCodeUnspsc190501.41111746': __('identifierSchemeCodeUnspsc190501.41111746'),
	'identifierSchemeCodeUnspsc190501.41111747': __('identifierSchemeCodeUnspsc190501.41111747'),
	'identifierSchemeCodeUnspsc190501.41111748': __('identifierSchemeCodeUnspsc190501.41111748'),
	'identifierSchemeCodeUnspsc190501.41111749': __('identifierSchemeCodeUnspsc190501.41111749'),
	'identifierSchemeCodeUnspsc190501.41111750': __('identifierSchemeCodeUnspsc190501.41111750'),
	'identifierSchemeCodeUnspsc190501.41111751': __('identifierSchemeCodeUnspsc190501.41111751'),
	'identifierSchemeCodeUnspsc190501.41111752': __('identifierSchemeCodeUnspsc190501.41111752'),
	'identifierSchemeCodeUnspsc190501.41111753': __('identifierSchemeCodeUnspsc190501.41111753'),
	'identifierSchemeCodeUnspsc190501.41111754': __('identifierSchemeCodeUnspsc190501.41111754'),
	'identifierSchemeCodeUnspsc190501.41111755': __('identifierSchemeCodeUnspsc190501.41111755'),
	'identifierSchemeCodeUnspsc190501.41111756': __('identifierSchemeCodeUnspsc190501.41111756'),
	'identifierSchemeCodeUnspsc190501.41111757': __('identifierSchemeCodeUnspsc190501.41111757'),
	'identifierSchemeCodeUnspsc190501.41111758': __('identifierSchemeCodeUnspsc190501.41111758'),
	'identifierSchemeCodeUnspsc190501.41111759': __('identifierSchemeCodeUnspsc190501.41111759'),
	'identifierSchemeCodeUnspsc190501.41111760': __('identifierSchemeCodeUnspsc190501.41111760'),
	'identifierSchemeCodeUnspsc190501.41111761': __('identifierSchemeCodeUnspsc190501.41111761'),
	'identifierSchemeCodeUnspsc190501.41111762': __('identifierSchemeCodeUnspsc190501.41111762'),
	'identifierSchemeCodeUnspsc190501.41111763': __('identifierSchemeCodeUnspsc190501.41111763'),
	'identifierSchemeCodeUnspsc190501.41111764': __('identifierSchemeCodeUnspsc190501.41111764'),
	'identifierSchemeCodeUnspsc190501.41111765': __('identifierSchemeCodeUnspsc190501.41111765'),
	'identifierSchemeCodeUnspsc190501.41111766': __('identifierSchemeCodeUnspsc190501.41111766'),
	'identifierSchemeCodeUnspsc190501.41111767': __('identifierSchemeCodeUnspsc190501.41111767'),
	'identifierSchemeCodeUnspsc190501.41111768': __('identifierSchemeCodeUnspsc190501.41111768'),
	'identifierSchemeCodeUnspsc190501.41111769': __('identifierSchemeCodeUnspsc190501.41111769'),
	'identifierSchemeCodeUnspsc190501.41111770': __('identifierSchemeCodeUnspsc190501.41111770'),
	'identifierSchemeCodeUnspsc190501.41111800': __('identifierSchemeCodeUnspsc190501.41111800'),
	'identifierSchemeCodeUnspsc190501.41111801': __('identifierSchemeCodeUnspsc190501.41111801'),
	'identifierSchemeCodeUnspsc190501.41111802': __('identifierSchemeCodeUnspsc190501.41111802'),
	'identifierSchemeCodeUnspsc190501.41111803': __('identifierSchemeCodeUnspsc190501.41111803'),
	'identifierSchemeCodeUnspsc190501.41111804': __('identifierSchemeCodeUnspsc190501.41111804'),
	'identifierSchemeCodeUnspsc190501.41111805': __('identifierSchemeCodeUnspsc190501.41111805'),
	'identifierSchemeCodeUnspsc190501.41111806': __('identifierSchemeCodeUnspsc190501.41111806'),
	'identifierSchemeCodeUnspsc190501.41111807': __('identifierSchemeCodeUnspsc190501.41111807'),
	'identifierSchemeCodeUnspsc190501.41111808': __('identifierSchemeCodeUnspsc190501.41111808'),
	'identifierSchemeCodeUnspsc190501.41111809': __('identifierSchemeCodeUnspsc190501.41111809'),
	'identifierSchemeCodeUnspsc190501.41111810': __('identifierSchemeCodeUnspsc190501.41111810'),
	'identifierSchemeCodeUnspsc190501.41111811': __('identifierSchemeCodeUnspsc190501.41111811'),
	'identifierSchemeCodeUnspsc190501.41111812': __('identifierSchemeCodeUnspsc190501.41111812'),
	'identifierSchemeCodeUnspsc190501.41111813': __('identifierSchemeCodeUnspsc190501.41111813'),
	'identifierSchemeCodeUnspsc190501.41111814': __('identifierSchemeCodeUnspsc190501.41111814'),
	'identifierSchemeCodeUnspsc190501.41111815': __('identifierSchemeCodeUnspsc190501.41111815'),
	'identifierSchemeCodeUnspsc190501.41111816': __('identifierSchemeCodeUnspsc190501.41111816'),
	'identifierSchemeCodeUnspsc190501.41111817': __('identifierSchemeCodeUnspsc190501.41111817'),
	'identifierSchemeCodeUnspsc190501.41111818': __('identifierSchemeCodeUnspsc190501.41111818'),
	'identifierSchemeCodeUnspsc190501.41111819': __('identifierSchemeCodeUnspsc190501.41111819'),
	'identifierSchemeCodeUnspsc190501.41111820': __('identifierSchemeCodeUnspsc190501.41111820'),
	'identifierSchemeCodeUnspsc190501.41111900': __('identifierSchemeCodeUnspsc190501.41111900'),
	'identifierSchemeCodeUnspsc190501.41111901': __('identifierSchemeCodeUnspsc190501.41111901'),
	'identifierSchemeCodeUnspsc190501.41111902': __('identifierSchemeCodeUnspsc190501.41111902'),
	'identifierSchemeCodeUnspsc190501.41111903': __('identifierSchemeCodeUnspsc190501.41111903'),
	'identifierSchemeCodeUnspsc190501.41111904': __('identifierSchemeCodeUnspsc190501.41111904'),
	'identifierSchemeCodeUnspsc190501.41111905': __('identifierSchemeCodeUnspsc190501.41111905'),
	'identifierSchemeCodeUnspsc190501.41111906': __('identifierSchemeCodeUnspsc190501.41111906'),
	'identifierSchemeCodeUnspsc190501.41111907': __('identifierSchemeCodeUnspsc190501.41111907'),
	'identifierSchemeCodeUnspsc190501.41111908': __('identifierSchemeCodeUnspsc190501.41111908'),
	'identifierSchemeCodeUnspsc190501.41111909': __('identifierSchemeCodeUnspsc190501.41111909'),
	'identifierSchemeCodeUnspsc190501.41111910': __('identifierSchemeCodeUnspsc190501.41111910'),
	'identifierSchemeCodeUnspsc190501.41111911': __('identifierSchemeCodeUnspsc190501.41111911'),
	'identifierSchemeCodeUnspsc190501.41111912': __('identifierSchemeCodeUnspsc190501.41111912'),
	'identifierSchemeCodeUnspsc190501.41111913': __('identifierSchemeCodeUnspsc190501.41111913'),
	'identifierSchemeCodeUnspsc190501.41111914': __('identifierSchemeCodeUnspsc190501.41111914'),
	'identifierSchemeCodeUnspsc190501.41111915': __('identifierSchemeCodeUnspsc190501.41111915'),
	'identifierSchemeCodeUnspsc190501.41111916': __('identifierSchemeCodeUnspsc190501.41111916'),
	'identifierSchemeCodeUnspsc190501.41111917': __('identifierSchemeCodeUnspsc190501.41111917'),
	'identifierSchemeCodeUnspsc190501.41111918': __('identifierSchemeCodeUnspsc190501.41111918'),
	'identifierSchemeCodeUnspsc190501.41111919': __('identifierSchemeCodeUnspsc190501.41111919'),
	'identifierSchemeCodeUnspsc190501.41111920': __('identifierSchemeCodeUnspsc190501.41111920'),
	'identifierSchemeCodeUnspsc190501.41111921': __('identifierSchemeCodeUnspsc190501.41111921'),
	'identifierSchemeCodeUnspsc190501.41111922': __('identifierSchemeCodeUnspsc190501.41111922'),
	'identifierSchemeCodeUnspsc190501.41111923': __('identifierSchemeCodeUnspsc190501.41111923'),
	'identifierSchemeCodeUnspsc190501.41111924': __('identifierSchemeCodeUnspsc190501.41111924'),
	'identifierSchemeCodeUnspsc190501.41111926': __('identifierSchemeCodeUnspsc190501.41111926'),
	'identifierSchemeCodeUnspsc190501.41111927': __('identifierSchemeCodeUnspsc190501.41111927'),
	'identifierSchemeCodeUnspsc190501.41111928': __('identifierSchemeCodeUnspsc190501.41111928'),
	'identifierSchemeCodeUnspsc190501.41111929': __('identifierSchemeCodeUnspsc190501.41111929'),
	'identifierSchemeCodeUnspsc190501.41111930': __('identifierSchemeCodeUnspsc190501.41111930'),
	'identifierSchemeCodeUnspsc190501.41111931': __('identifierSchemeCodeUnspsc190501.41111931'),
	'identifierSchemeCodeUnspsc190501.41111932': __('identifierSchemeCodeUnspsc190501.41111932'),
	'identifierSchemeCodeUnspsc190501.41111933': __('identifierSchemeCodeUnspsc190501.41111933'),
	'identifierSchemeCodeUnspsc190501.41111934': __('identifierSchemeCodeUnspsc190501.41111934'),
	'identifierSchemeCodeUnspsc190501.41111935': __('identifierSchemeCodeUnspsc190501.41111935'),
	'identifierSchemeCodeUnspsc190501.41111936': __('identifierSchemeCodeUnspsc190501.41111936'),
	'identifierSchemeCodeUnspsc190501.41111937': __('identifierSchemeCodeUnspsc190501.41111937'),
	'identifierSchemeCodeUnspsc190501.41111938': __('identifierSchemeCodeUnspsc190501.41111938'),
	'identifierSchemeCodeUnspsc190501.41111939': __('identifierSchemeCodeUnspsc190501.41111939'),
	'identifierSchemeCodeUnspsc190501.41111940': __('identifierSchemeCodeUnspsc190501.41111940'),
	'identifierSchemeCodeUnspsc190501.41111941': __('identifierSchemeCodeUnspsc190501.41111941'),
	'identifierSchemeCodeUnspsc190501.41111942': __('identifierSchemeCodeUnspsc190501.41111942'),
	'identifierSchemeCodeUnspsc190501.41111943': __('identifierSchemeCodeUnspsc190501.41111943'),
	'identifierSchemeCodeUnspsc190501.41111944': __('identifierSchemeCodeUnspsc190501.41111944'),
	'identifierSchemeCodeUnspsc190501.41111945': __('identifierSchemeCodeUnspsc190501.41111945'),
	'identifierSchemeCodeUnspsc190501.41111946': __('identifierSchemeCodeUnspsc190501.41111946'),
	'identifierSchemeCodeUnspsc190501.41111947': __('identifierSchemeCodeUnspsc190501.41111947'),
	'identifierSchemeCodeUnspsc190501.41111948': __('identifierSchemeCodeUnspsc190501.41111948'),
	'identifierSchemeCodeUnspsc190501.41111949': __('identifierSchemeCodeUnspsc190501.41111949'),
	'identifierSchemeCodeUnspsc190501.41111950': __('identifierSchemeCodeUnspsc190501.41111950'),
	'identifierSchemeCodeUnspsc190501.41111951': __('identifierSchemeCodeUnspsc190501.41111951'),
	'identifierSchemeCodeUnspsc190501.41111952': __('identifierSchemeCodeUnspsc190501.41111952'),
	'identifierSchemeCodeUnspsc190501.41111953': __('identifierSchemeCodeUnspsc190501.41111953'),
	'identifierSchemeCodeUnspsc190501.41111954': __('identifierSchemeCodeUnspsc190501.41111954'),
	'identifierSchemeCodeUnspsc190501.41111955': __('identifierSchemeCodeUnspsc190501.41111955'),
	'identifierSchemeCodeUnspsc190501.41111956': __('identifierSchemeCodeUnspsc190501.41111956'),
	'identifierSchemeCodeUnspsc190501.41111957': __('identifierSchemeCodeUnspsc190501.41111957'),
	'identifierSchemeCodeUnspsc190501.41111958': __('identifierSchemeCodeUnspsc190501.41111958'),
	'identifierSchemeCodeUnspsc190501.41111959': __('identifierSchemeCodeUnspsc190501.41111959'),
	'identifierSchemeCodeUnspsc190501.41111960': __('identifierSchemeCodeUnspsc190501.41111960'),
	'identifierSchemeCodeUnspsc190501.41111961': __('identifierSchemeCodeUnspsc190501.41111961'),
	'identifierSchemeCodeUnspsc190501.41111962': __('identifierSchemeCodeUnspsc190501.41111962'),
	'identifierSchemeCodeUnspsc190501.41111963': __('identifierSchemeCodeUnspsc190501.41111963'),
	'identifierSchemeCodeUnspsc190501.41111964': __('identifierSchemeCodeUnspsc190501.41111964'),
	'identifierSchemeCodeUnspsc190501.41111965': __('identifierSchemeCodeUnspsc190501.41111965'),
	'identifierSchemeCodeUnspsc190501.41111966': __('identifierSchemeCodeUnspsc190501.41111966'),
	'identifierSchemeCodeUnspsc190501.41111967': __('identifierSchemeCodeUnspsc190501.41111967'),
	'identifierSchemeCodeUnspsc190501.41111968': __('identifierSchemeCodeUnspsc190501.41111968'),
	'identifierSchemeCodeUnspsc190501.41111969': __('identifierSchemeCodeUnspsc190501.41111969'),
	'identifierSchemeCodeUnspsc190501.41111970': __('identifierSchemeCodeUnspsc190501.41111970'),
	'identifierSchemeCodeUnspsc190501.41111971': __('identifierSchemeCodeUnspsc190501.41111971'),
	'identifierSchemeCodeUnspsc190501.41111972': __('identifierSchemeCodeUnspsc190501.41111972'),
	'identifierSchemeCodeUnspsc190501.41111973': __('identifierSchemeCodeUnspsc190501.41111973'),
	'identifierSchemeCodeUnspsc190501.41111974': __('identifierSchemeCodeUnspsc190501.41111974'),
	'identifierSchemeCodeUnspsc190501.41111975': __('identifierSchemeCodeUnspsc190501.41111975'),
	'identifierSchemeCodeUnspsc190501.41111976': __('identifierSchemeCodeUnspsc190501.41111976'),
	'identifierSchemeCodeUnspsc190501.41111977': __('identifierSchemeCodeUnspsc190501.41111977'),
	'identifierSchemeCodeUnspsc190501.41111978': __('identifierSchemeCodeUnspsc190501.41111978'),
	'identifierSchemeCodeUnspsc190501.41111979': __('identifierSchemeCodeUnspsc190501.41111979'),
	'identifierSchemeCodeUnspsc190501.41111980': __('identifierSchemeCodeUnspsc190501.41111980'),
	'identifierSchemeCodeUnspsc190501.41111981': __('identifierSchemeCodeUnspsc190501.41111981'),
	'identifierSchemeCodeUnspsc190501.41112100': __('identifierSchemeCodeUnspsc190501.41112100'),
	'identifierSchemeCodeUnspsc190501.41112101': __('identifierSchemeCodeUnspsc190501.41112101'),
	'identifierSchemeCodeUnspsc190501.41112103': __('identifierSchemeCodeUnspsc190501.41112103'),
	'identifierSchemeCodeUnspsc190501.41112104': __('identifierSchemeCodeUnspsc190501.41112104'),
	'identifierSchemeCodeUnspsc190501.41112105': __('identifierSchemeCodeUnspsc190501.41112105'),
	'identifierSchemeCodeUnspsc190501.41112106': __('identifierSchemeCodeUnspsc190501.41112106'),
	'identifierSchemeCodeUnspsc190501.41112107': __('identifierSchemeCodeUnspsc190501.41112107'),
	'identifierSchemeCodeUnspsc190501.41112108': __('identifierSchemeCodeUnspsc190501.41112108'),
	'identifierSchemeCodeUnspsc190501.41112109': __('identifierSchemeCodeUnspsc190501.41112109'),
	'identifierSchemeCodeUnspsc190501.41112110': __('identifierSchemeCodeUnspsc190501.41112110'),
	'identifierSchemeCodeUnspsc190501.41112111': __('identifierSchemeCodeUnspsc190501.41112111'),
	'identifierSchemeCodeUnspsc190501.41112112': __('identifierSchemeCodeUnspsc190501.41112112'),
	'identifierSchemeCodeUnspsc190501.41112113': __('identifierSchemeCodeUnspsc190501.41112113'),
	'identifierSchemeCodeUnspsc190501.41112114': __('identifierSchemeCodeUnspsc190501.41112114'),
	'identifierSchemeCodeUnspsc190501.41112200': __('identifierSchemeCodeUnspsc190501.41112200'),
	'identifierSchemeCodeUnspsc190501.41112201': __('identifierSchemeCodeUnspsc190501.41112201'),
	'identifierSchemeCodeUnspsc190501.41112202': __('identifierSchemeCodeUnspsc190501.41112202'),
	'identifierSchemeCodeUnspsc190501.41112203': __('identifierSchemeCodeUnspsc190501.41112203'),
	'identifierSchemeCodeUnspsc190501.41112204': __('identifierSchemeCodeUnspsc190501.41112204'),
	'identifierSchemeCodeUnspsc190501.41112205': __('identifierSchemeCodeUnspsc190501.41112205'),
	'identifierSchemeCodeUnspsc190501.41112206': __('identifierSchemeCodeUnspsc190501.41112206'),
	'identifierSchemeCodeUnspsc190501.41112207': __('identifierSchemeCodeUnspsc190501.41112207'),
	'identifierSchemeCodeUnspsc190501.41112209': __('identifierSchemeCodeUnspsc190501.41112209'),
	'identifierSchemeCodeUnspsc190501.41112210': __('identifierSchemeCodeUnspsc190501.41112210'),
	'identifierSchemeCodeUnspsc190501.41112211': __('identifierSchemeCodeUnspsc190501.41112211'),
	'identifierSchemeCodeUnspsc190501.41112212': __('identifierSchemeCodeUnspsc190501.41112212'),
	'identifierSchemeCodeUnspsc190501.41112213': __('identifierSchemeCodeUnspsc190501.41112213'),
	'identifierSchemeCodeUnspsc190501.41112214': __('identifierSchemeCodeUnspsc190501.41112214'),
	'identifierSchemeCodeUnspsc190501.41112215': __('identifierSchemeCodeUnspsc190501.41112215'),
	'identifierSchemeCodeUnspsc190501.41112216': __('identifierSchemeCodeUnspsc190501.41112216'),
	'identifierSchemeCodeUnspsc190501.41112217': __('identifierSchemeCodeUnspsc190501.41112217'),
	'identifierSchemeCodeUnspsc190501.41112219': __('identifierSchemeCodeUnspsc190501.41112219'),
	'identifierSchemeCodeUnspsc190501.41112220': __('identifierSchemeCodeUnspsc190501.41112220'),
	'identifierSchemeCodeUnspsc190501.41112221': __('identifierSchemeCodeUnspsc190501.41112221'),
	'identifierSchemeCodeUnspsc190501.41112222': __('identifierSchemeCodeUnspsc190501.41112222'),
	'identifierSchemeCodeUnspsc190501.41112223': __('identifierSchemeCodeUnspsc190501.41112223'),
	'identifierSchemeCodeUnspsc190501.41112224': __('identifierSchemeCodeUnspsc190501.41112224'),
	'identifierSchemeCodeUnspsc190501.41112225': __('identifierSchemeCodeUnspsc190501.41112225'),
	'identifierSchemeCodeUnspsc190501.41112226': __('identifierSchemeCodeUnspsc190501.41112226'),
	'identifierSchemeCodeUnspsc190501.41112227': __('identifierSchemeCodeUnspsc190501.41112227'),
	'identifierSchemeCodeUnspsc190501.41112228': __('identifierSchemeCodeUnspsc190501.41112228'),
	'identifierSchemeCodeUnspsc190501.41112229': __('identifierSchemeCodeUnspsc190501.41112229'),
	'identifierSchemeCodeUnspsc190501.41112230': __('identifierSchemeCodeUnspsc190501.41112230'),
	'identifierSchemeCodeUnspsc190501.41112231': __('identifierSchemeCodeUnspsc190501.41112231'),
	'identifierSchemeCodeUnspsc190501.41112232': __('identifierSchemeCodeUnspsc190501.41112232'),
	'identifierSchemeCodeUnspsc190501.41112233': __('identifierSchemeCodeUnspsc190501.41112233'),
	'identifierSchemeCodeUnspsc190501.41112234': __('identifierSchemeCodeUnspsc190501.41112234'),
	'identifierSchemeCodeUnspsc190501.41112235': __('identifierSchemeCodeUnspsc190501.41112235'),
	'identifierSchemeCodeUnspsc190501.41112236': __('identifierSchemeCodeUnspsc190501.41112236'),
	'identifierSchemeCodeUnspsc190501.41112237': __('identifierSchemeCodeUnspsc190501.41112237'),
	'identifierSchemeCodeUnspsc190501.41112238': __('identifierSchemeCodeUnspsc190501.41112238'),
	'identifierSchemeCodeUnspsc190501.41112239': __('identifierSchemeCodeUnspsc190501.41112239'),
	'identifierSchemeCodeUnspsc190501.41112240': __('identifierSchemeCodeUnspsc190501.41112240'),
	'identifierSchemeCodeUnspsc190501.41112241': __('identifierSchemeCodeUnspsc190501.41112241'),
	'identifierSchemeCodeUnspsc190501.41112242': __('identifierSchemeCodeUnspsc190501.41112242'),
	'identifierSchemeCodeUnspsc190501.41112243': __('identifierSchemeCodeUnspsc190501.41112243'),
	'identifierSchemeCodeUnspsc190501.41112244': __('identifierSchemeCodeUnspsc190501.41112244'),
	'identifierSchemeCodeUnspsc190501.41112300': __('identifierSchemeCodeUnspsc190501.41112300'),
	'identifierSchemeCodeUnspsc190501.41112301': __('identifierSchemeCodeUnspsc190501.41112301'),
	'identifierSchemeCodeUnspsc190501.41112302': __('identifierSchemeCodeUnspsc190501.41112302'),
	'identifierSchemeCodeUnspsc190501.41112303': __('identifierSchemeCodeUnspsc190501.41112303'),
	'identifierSchemeCodeUnspsc190501.41112304': __('identifierSchemeCodeUnspsc190501.41112304'),
	'identifierSchemeCodeUnspsc190501.41112305': __('identifierSchemeCodeUnspsc190501.41112305'),
	'identifierSchemeCodeUnspsc190501.41112306': __('identifierSchemeCodeUnspsc190501.41112306'),
	'identifierSchemeCodeUnspsc190501.41112307': __('identifierSchemeCodeUnspsc190501.41112307'),
	'identifierSchemeCodeUnspsc190501.41112400': __('identifierSchemeCodeUnspsc190501.41112400'),
	'identifierSchemeCodeUnspsc190501.41112401': __('identifierSchemeCodeUnspsc190501.41112401'),
	'identifierSchemeCodeUnspsc190501.41112402': __('identifierSchemeCodeUnspsc190501.41112402'),
	'identifierSchemeCodeUnspsc190501.41112403': __('identifierSchemeCodeUnspsc190501.41112403'),
	'identifierSchemeCodeUnspsc190501.41112404': __('identifierSchemeCodeUnspsc190501.41112404'),
	'identifierSchemeCodeUnspsc190501.41112405': __('identifierSchemeCodeUnspsc190501.41112405'),
	'identifierSchemeCodeUnspsc190501.41112406': __('identifierSchemeCodeUnspsc190501.41112406'),
	'identifierSchemeCodeUnspsc190501.41112407': __('identifierSchemeCodeUnspsc190501.41112407'),
	'identifierSchemeCodeUnspsc190501.41112408': __('identifierSchemeCodeUnspsc190501.41112408'),
	'identifierSchemeCodeUnspsc190501.41112409': __('identifierSchemeCodeUnspsc190501.41112409'),
	'identifierSchemeCodeUnspsc190501.41112410': __('identifierSchemeCodeUnspsc190501.41112410'),
	'identifierSchemeCodeUnspsc190501.41112411': __('identifierSchemeCodeUnspsc190501.41112411'),
	'identifierSchemeCodeUnspsc190501.41112412': __('identifierSchemeCodeUnspsc190501.41112412'),
	'identifierSchemeCodeUnspsc190501.41112413': __('identifierSchemeCodeUnspsc190501.41112413'),
	'identifierSchemeCodeUnspsc190501.41112414': __('identifierSchemeCodeUnspsc190501.41112414'),
	'identifierSchemeCodeUnspsc190501.41112415': __('identifierSchemeCodeUnspsc190501.41112415'),
	'identifierSchemeCodeUnspsc190501.41112416': __('identifierSchemeCodeUnspsc190501.41112416'),
	'identifierSchemeCodeUnspsc190501.41112417': __('identifierSchemeCodeUnspsc190501.41112417'),
	'identifierSchemeCodeUnspsc190501.41112418': __('identifierSchemeCodeUnspsc190501.41112418'),
	'identifierSchemeCodeUnspsc190501.41112419': __('identifierSchemeCodeUnspsc190501.41112419'),
	'identifierSchemeCodeUnspsc190501.41112420': __('identifierSchemeCodeUnspsc190501.41112420'),
	'identifierSchemeCodeUnspsc190501.41112421': __('identifierSchemeCodeUnspsc190501.41112421'),
	'identifierSchemeCodeUnspsc190501.41112422': __('identifierSchemeCodeUnspsc190501.41112422'),
	'identifierSchemeCodeUnspsc190501.41112423': __('identifierSchemeCodeUnspsc190501.41112423'),
	'identifierSchemeCodeUnspsc190501.41112500': __('identifierSchemeCodeUnspsc190501.41112500'),
	'identifierSchemeCodeUnspsc190501.41112501': __('identifierSchemeCodeUnspsc190501.41112501'),
	'identifierSchemeCodeUnspsc190501.41112502': __('identifierSchemeCodeUnspsc190501.41112502'),
	'identifierSchemeCodeUnspsc190501.41112503': __('identifierSchemeCodeUnspsc190501.41112503'),
	'identifierSchemeCodeUnspsc190501.41112504': __('identifierSchemeCodeUnspsc190501.41112504'),
	'identifierSchemeCodeUnspsc190501.41112505': __('identifierSchemeCodeUnspsc190501.41112505'),
	'identifierSchemeCodeUnspsc190501.41112506': __('identifierSchemeCodeUnspsc190501.41112506'),
	'identifierSchemeCodeUnspsc190501.41112508': __('identifierSchemeCodeUnspsc190501.41112508'),
	'identifierSchemeCodeUnspsc190501.41112509': __('identifierSchemeCodeUnspsc190501.41112509'),
	'identifierSchemeCodeUnspsc190501.41112510': __('identifierSchemeCodeUnspsc190501.41112510'),
	'identifierSchemeCodeUnspsc190501.41112511': __('identifierSchemeCodeUnspsc190501.41112511'),
	'identifierSchemeCodeUnspsc190501.41112512': __('identifierSchemeCodeUnspsc190501.41112512'),
	'identifierSchemeCodeUnspsc190501.41112513': __('identifierSchemeCodeUnspsc190501.41112513'),
	'identifierSchemeCodeUnspsc190501.41112514': __('identifierSchemeCodeUnspsc190501.41112514'),
	'identifierSchemeCodeUnspsc190501.41112516': __('identifierSchemeCodeUnspsc190501.41112516'),
	'identifierSchemeCodeUnspsc190501.41112517': __('identifierSchemeCodeUnspsc190501.41112517'),
	'identifierSchemeCodeUnspsc190501.41112518': __('identifierSchemeCodeUnspsc190501.41112518'),
	'identifierSchemeCodeUnspsc190501.41112519': __('identifierSchemeCodeUnspsc190501.41112519'),
	'identifierSchemeCodeUnspsc190501.41112520': __('identifierSchemeCodeUnspsc190501.41112520'),
	'identifierSchemeCodeUnspsc190501.41112521': __('identifierSchemeCodeUnspsc190501.41112521'),
	'identifierSchemeCodeUnspsc190501.41112522': __('identifierSchemeCodeUnspsc190501.41112522'),
	'identifierSchemeCodeUnspsc190501.41112600': __('identifierSchemeCodeUnspsc190501.41112600'),
	'identifierSchemeCodeUnspsc190501.41112601': __('identifierSchemeCodeUnspsc190501.41112601'),
	'identifierSchemeCodeUnspsc190501.41112602': __('identifierSchemeCodeUnspsc190501.41112602'),
	'identifierSchemeCodeUnspsc190501.41112700': __('identifierSchemeCodeUnspsc190501.41112700'),
	'identifierSchemeCodeUnspsc190501.41112701': __('identifierSchemeCodeUnspsc190501.41112701'),
	'identifierSchemeCodeUnspsc190501.41112702': __('identifierSchemeCodeUnspsc190501.41112702'),
	'identifierSchemeCodeUnspsc190501.41112704': __('identifierSchemeCodeUnspsc190501.41112704'),
	'identifierSchemeCodeUnspsc190501.41112800': __('identifierSchemeCodeUnspsc190501.41112800'),
	'identifierSchemeCodeUnspsc190501.41112801': __('identifierSchemeCodeUnspsc190501.41112801'),
	'identifierSchemeCodeUnspsc190501.41112802': __('identifierSchemeCodeUnspsc190501.41112802'),
	'identifierSchemeCodeUnspsc190501.41112803': __('identifierSchemeCodeUnspsc190501.41112803'),
	'identifierSchemeCodeUnspsc190501.41112804': __('identifierSchemeCodeUnspsc190501.41112804'),
	'identifierSchemeCodeUnspsc190501.41112805': __('identifierSchemeCodeUnspsc190501.41112805'),
	'identifierSchemeCodeUnspsc190501.41112806': __('identifierSchemeCodeUnspsc190501.41112806'),
	'identifierSchemeCodeUnspsc190501.41112807': __('identifierSchemeCodeUnspsc190501.41112807'),
	'identifierSchemeCodeUnspsc190501.41112808': __('identifierSchemeCodeUnspsc190501.41112808'),
	'identifierSchemeCodeUnspsc190501.41112809': __('identifierSchemeCodeUnspsc190501.41112809'),
	'identifierSchemeCodeUnspsc190501.41112900': __('identifierSchemeCodeUnspsc190501.41112900'),
	'identifierSchemeCodeUnspsc190501.41112901': __('identifierSchemeCodeUnspsc190501.41112901'),
	'identifierSchemeCodeUnspsc190501.41112902': __('identifierSchemeCodeUnspsc190501.41112902'),
	'identifierSchemeCodeUnspsc190501.41112903': __('identifierSchemeCodeUnspsc190501.41112903'),
	'identifierSchemeCodeUnspsc190501.41112904': __('identifierSchemeCodeUnspsc190501.41112904'),
	'identifierSchemeCodeUnspsc190501.41112905': __('identifierSchemeCodeUnspsc190501.41112905'),
	'identifierSchemeCodeUnspsc190501.41112906': __('identifierSchemeCodeUnspsc190501.41112906'),
	'identifierSchemeCodeUnspsc190501.41112907': __('identifierSchemeCodeUnspsc190501.41112907'),
	'identifierSchemeCodeUnspsc190501.41112908': __('identifierSchemeCodeUnspsc190501.41112908'),
	'identifierSchemeCodeUnspsc190501.41113000': __('identifierSchemeCodeUnspsc190501.41113000'),
	'identifierSchemeCodeUnspsc190501.41113001': __('identifierSchemeCodeUnspsc190501.41113001'),
	'identifierSchemeCodeUnspsc190501.41113002': __('identifierSchemeCodeUnspsc190501.41113002'),
	'identifierSchemeCodeUnspsc190501.41113003': __('identifierSchemeCodeUnspsc190501.41113003'),
	'identifierSchemeCodeUnspsc190501.41113004': __('identifierSchemeCodeUnspsc190501.41113004'),
	'identifierSchemeCodeUnspsc190501.41113005': __('identifierSchemeCodeUnspsc190501.41113005'),
	'identifierSchemeCodeUnspsc190501.41113006': __('identifierSchemeCodeUnspsc190501.41113006'),
	'identifierSchemeCodeUnspsc190501.41113007': __('identifierSchemeCodeUnspsc190501.41113007'),
	'identifierSchemeCodeUnspsc190501.41113008': __('identifierSchemeCodeUnspsc190501.41113008'),
	'identifierSchemeCodeUnspsc190501.41113009': __('identifierSchemeCodeUnspsc190501.41113009'),
	'identifierSchemeCodeUnspsc190501.41113010': __('identifierSchemeCodeUnspsc190501.41113010'),
	'identifierSchemeCodeUnspsc190501.41113023': __('identifierSchemeCodeUnspsc190501.41113023'),
	'identifierSchemeCodeUnspsc190501.41113024': __('identifierSchemeCodeUnspsc190501.41113024'),
	'identifierSchemeCodeUnspsc190501.41113025': __('identifierSchemeCodeUnspsc190501.41113025'),
	'identifierSchemeCodeUnspsc190501.41113026': __('identifierSchemeCodeUnspsc190501.41113026'),
	'identifierSchemeCodeUnspsc190501.41113027': __('identifierSchemeCodeUnspsc190501.41113027'),
	'identifierSchemeCodeUnspsc190501.41113029': __('identifierSchemeCodeUnspsc190501.41113029'),
	'identifierSchemeCodeUnspsc190501.41113030': __('identifierSchemeCodeUnspsc190501.41113030'),
	'identifierSchemeCodeUnspsc190501.41113031': __('identifierSchemeCodeUnspsc190501.41113031'),
	'identifierSchemeCodeUnspsc190501.41113033': __('identifierSchemeCodeUnspsc190501.41113033'),
	'identifierSchemeCodeUnspsc190501.41113034': __('identifierSchemeCodeUnspsc190501.41113034'),
	'identifierSchemeCodeUnspsc190501.41113035': __('identifierSchemeCodeUnspsc190501.41113035'),
	'identifierSchemeCodeUnspsc190501.41113036': __('identifierSchemeCodeUnspsc190501.41113036'),
	'identifierSchemeCodeUnspsc190501.41113037': __('identifierSchemeCodeUnspsc190501.41113037'),
	'identifierSchemeCodeUnspsc190501.41113038': __('identifierSchemeCodeUnspsc190501.41113038'),
	'identifierSchemeCodeUnspsc190501.41113039': __('identifierSchemeCodeUnspsc190501.41113039'),
	'identifierSchemeCodeUnspsc190501.41113040': __('identifierSchemeCodeUnspsc190501.41113040'),
	'identifierSchemeCodeUnspsc190501.41113041': __('identifierSchemeCodeUnspsc190501.41113041'),
	'identifierSchemeCodeUnspsc190501.41113042': __('identifierSchemeCodeUnspsc190501.41113042'),
	'identifierSchemeCodeUnspsc190501.41113043': __('identifierSchemeCodeUnspsc190501.41113043'),
	'identifierSchemeCodeUnspsc190501.41113044': __('identifierSchemeCodeUnspsc190501.41113044'),
	'identifierSchemeCodeUnspsc190501.41113045': __('identifierSchemeCodeUnspsc190501.41113045'),
	'identifierSchemeCodeUnspsc190501.41113046': __('identifierSchemeCodeUnspsc190501.41113046'),
	'identifierSchemeCodeUnspsc190501.41113047': __('identifierSchemeCodeUnspsc190501.41113047'),
	'identifierSchemeCodeUnspsc190501.41113048': __('identifierSchemeCodeUnspsc190501.41113048'),
	'identifierSchemeCodeUnspsc190501.41113049': __('identifierSchemeCodeUnspsc190501.41113049'),
	'identifierSchemeCodeUnspsc190501.41113050': __('identifierSchemeCodeUnspsc190501.41113050'),
	'identifierSchemeCodeUnspsc190501.41113051': __('identifierSchemeCodeUnspsc190501.41113051'),
	'identifierSchemeCodeUnspsc190501.41113052': __('identifierSchemeCodeUnspsc190501.41113052'),
	'identifierSchemeCodeUnspsc190501.41113053': __('identifierSchemeCodeUnspsc190501.41113053'),
	'identifierSchemeCodeUnspsc190501.41113054': __('identifierSchemeCodeUnspsc190501.41113054'),
	'identifierSchemeCodeUnspsc190501.41113055': __('identifierSchemeCodeUnspsc190501.41113055'),
	'identifierSchemeCodeUnspsc190501.41113056': __('identifierSchemeCodeUnspsc190501.41113056'),
	'identifierSchemeCodeUnspsc190501.41113057': __('identifierSchemeCodeUnspsc190501.41113057'),
	'identifierSchemeCodeUnspsc190501.41113100': __('identifierSchemeCodeUnspsc190501.41113100'),
	'identifierSchemeCodeUnspsc190501.41113101': __('identifierSchemeCodeUnspsc190501.41113101'),
	'identifierSchemeCodeUnspsc190501.41113102': __('identifierSchemeCodeUnspsc190501.41113102'),
	'identifierSchemeCodeUnspsc190501.41113103': __('identifierSchemeCodeUnspsc190501.41113103'),
	'identifierSchemeCodeUnspsc190501.41113104': __('identifierSchemeCodeUnspsc190501.41113104'),
	'identifierSchemeCodeUnspsc190501.41113105': __('identifierSchemeCodeUnspsc190501.41113105'),
	'identifierSchemeCodeUnspsc190501.41113106': __('identifierSchemeCodeUnspsc190501.41113106'),
	'identifierSchemeCodeUnspsc190501.41113107': __('identifierSchemeCodeUnspsc190501.41113107'),
	'identifierSchemeCodeUnspsc190501.41113108': __('identifierSchemeCodeUnspsc190501.41113108'),
	'identifierSchemeCodeUnspsc190501.41113109': __('identifierSchemeCodeUnspsc190501.41113109'),
	'identifierSchemeCodeUnspsc190501.41113110': __('identifierSchemeCodeUnspsc190501.41113110'),
	'identifierSchemeCodeUnspsc190501.41113111': __('identifierSchemeCodeUnspsc190501.41113111'),
	'identifierSchemeCodeUnspsc190501.41113112': __('identifierSchemeCodeUnspsc190501.41113112'),
	'identifierSchemeCodeUnspsc190501.41113113': __('identifierSchemeCodeUnspsc190501.41113113'),
	'identifierSchemeCodeUnspsc190501.41113114': __('identifierSchemeCodeUnspsc190501.41113114'),
	'identifierSchemeCodeUnspsc190501.41113115': __('identifierSchemeCodeUnspsc190501.41113115'),
	'identifierSchemeCodeUnspsc190501.41113116': __('identifierSchemeCodeUnspsc190501.41113116'),
	'identifierSchemeCodeUnspsc190501.41113117': __('identifierSchemeCodeUnspsc190501.41113117'),
	'identifierSchemeCodeUnspsc190501.41113118': __('identifierSchemeCodeUnspsc190501.41113118'),
	'identifierSchemeCodeUnspsc190501.41113119': __('identifierSchemeCodeUnspsc190501.41113119'),
	'identifierSchemeCodeUnspsc190501.41113120': __('identifierSchemeCodeUnspsc190501.41113120'),
	'identifierSchemeCodeUnspsc190501.41113121': __('identifierSchemeCodeUnspsc190501.41113121'),
	'identifierSchemeCodeUnspsc190501.41113122': __('identifierSchemeCodeUnspsc190501.41113122'),
	'identifierSchemeCodeUnspsc190501.41113123': __('identifierSchemeCodeUnspsc190501.41113123'),
	'identifierSchemeCodeUnspsc190501.41113124': __('identifierSchemeCodeUnspsc190501.41113124'),
	'identifierSchemeCodeUnspsc190501.41113300': __('identifierSchemeCodeUnspsc190501.41113300'),
	'identifierSchemeCodeUnspsc190501.41113301': __('identifierSchemeCodeUnspsc190501.41113301'),
	'identifierSchemeCodeUnspsc190501.41113302': __('identifierSchemeCodeUnspsc190501.41113302'),
	'identifierSchemeCodeUnspsc190501.41113304': __('identifierSchemeCodeUnspsc190501.41113304'),
	'identifierSchemeCodeUnspsc190501.41113305': __('identifierSchemeCodeUnspsc190501.41113305'),
	'identifierSchemeCodeUnspsc190501.41113306': __('identifierSchemeCodeUnspsc190501.41113306'),
	'identifierSchemeCodeUnspsc190501.41113308': __('identifierSchemeCodeUnspsc190501.41113308'),
	'identifierSchemeCodeUnspsc190501.41113309': __('identifierSchemeCodeUnspsc190501.41113309'),
	'identifierSchemeCodeUnspsc190501.41113310': __('identifierSchemeCodeUnspsc190501.41113310'),
	'identifierSchemeCodeUnspsc190501.41113311': __('identifierSchemeCodeUnspsc190501.41113311'),
	'identifierSchemeCodeUnspsc190501.41113312': __('identifierSchemeCodeUnspsc190501.41113312'),
	'identifierSchemeCodeUnspsc190501.41113313': __('identifierSchemeCodeUnspsc190501.41113313'),
	'identifierSchemeCodeUnspsc190501.41113314': __('identifierSchemeCodeUnspsc190501.41113314'),
	'identifierSchemeCodeUnspsc190501.41113315': __('identifierSchemeCodeUnspsc190501.41113315'),
	'identifierSchemeCodeUnspsc190501.41113316': __('identifierSchemeCodeUnspsc190501.41113316'),
	'identifierSchemeCodeUnspsc190501.41113318': __('identifierSchemeCodeUnspsc190501.41113318'),
	'identifierSchemeCodeUnspsc190501.41113319': __('identifierSchemeCodeUnspsc190501.41113319'),
	'identifierSchemeCodeUnspsc190501.41113320': __('identifierSchemeCodeUnspsc190501.41113320'),
	'identifierSchemeCodeUnspsc190501.41113321': __('identifierSchemeCodeUnspsc190501.41113321'),
	'identifierSchemeCodeUnspsc190501.41113322': __('identifierSchemeCodeUnspsc190501.41113322'),
	'identifierSchemeCodeUnspsc190501.41113323': __('identifierSchemeCodeUnspsc190501.41113323'),
	'identifierSchemeCodeUnspsc190501.41113324': __('identifierSchemeCodeUnspsc190501.41113324'),
	'identifierSchemeCodeUnspsc190501.41113325': __('identifierSchemeCodeUnspsc190501.41113325'),
	'identifierSchemeCodeUnspsc190501.41113326': __('identifierSchemeCodeUnspsc190501.41113326'),
	'identifierSchemeCodeUnspsc190501.41113327': __('identifierSchemeCodeUnspsc190501.41113327'),
	'identifierSchemeCodeUnspsc190501.41113328': __('identifierSchemeCodeUnspsc190501.41113328'),
	'identifierSchemeCodeUnspsc190501.41113329': __('identifierSchemeCodeUnspsc190501.41113329'),
	'identifierSchemeCodeUnspsc190501.41113330': __('identifierSchemeCodeUnspsc190501.41113330'),
	'identifierSchemeCodeUnspsc190501.41113331': __('identifierSchemeCodeUnspsc190501.41113331'),
	'identifierSchemeCodeUnspsc190501.41113332': __('identifierSchemeCodeUnspsc190501.41113332'),
	'identifierSchemeCodeUnspsc190501.41113333': __('identifierSchemeCodeUnspsc190501.41113333'),
	'identifierSchemeCodeUnspsc190501.41113334': __('identifierSchemeCodeUnspsc190501.41113334'),
	'identifierSchemeCodeUnspsc190501.41113335': __('identifierSchemeCodeUnspsc190501.41113335'),
	'identifierSchemeCodeUnspsc190501.41113336': __('identifierSchemeCodeUnspsc190501.41113336'),
	'identifierSchemeCodeUnspsc190501.41113337': __('identifierSchemeCodeUnspsc190501.41113337'),
	'identifierSchemeCodeUnspsc190501.41113338': __('identifierSchemeCodeUnspsc190501.41113338'),
	'identifierSchemeCodeUnspsc190501.41113339': __('identifierSchemeCodeUnspsc190501.41113339'),
	'identifierSchemeCodeUnspsc190501.41113400': __('identifierSchemeCodeUnspsc190501.41113400'),
	'identifierSchemeCodeUnspsc190501.41113401': __('identifierSchemeCodeUnspsc190501.41113401'),
	'identifierSchemeCodeUnspsc190501.41113402': __('identifierSchemeCodeUnspsc190501.41113402'),
	'identifierSchemeCodeUnspsc190501.41113403': __('identifierSchemeCodeUnspsc190501.41113403'),
	'identifierSchemeCodeUnspsc190501.41113404': __('identifierSchemeCodeUnspsc190501.41113404'),
	'identifierSchemeCodeUnspsc190501.41113405': __('identifierSchemeCodeUnspsc190501.41113405'),
	'identifierSchemeCodeUnspsc190501.41113406': __('identifierSchemeCodeUnspsc190501.41113406'),
	'identifierSchemeCodeUnspsc190501.41113407': __('identifierSchemeCodeUnspsc190501.41113407'),
	'identifierSchemeCodeUnspsc190501.41113600': __('identifierSchemeCodeUnspsc190501.41113600'),
	'identifierSchemeCodeUnspsc190501.41113601': __('identifierSchemeCodeUnspsc190501.41113601'),
	'identifierSchemeCodeUnspsc190501.41113602': __('identifierSchemeCodeUnspsc190501.41113602'),
	'identifierSchemeCodeUnspsc190501.41113603': __('identifierSchemeCodeUnspsc190501.41113603'),
	'identifierSchemeCodeUnspsc190501.41113604': __('identifierSchemeCodeUnspsc190501.41113604'),
	'identifierSchemeCodeUnspsc190501.41113605': __('identifierSchemeCodeUnspsc190501.41113605'),
	'identifierSchemeCodeUnspsc190501.41113606': __('identifierSchemeCodeUnspsc190501.41113606'),
	'identifierSchemeCodeUnspsc190501.41113607': __('identifierSchemeCodeUnspsc190501.41113607'),
	'identifierSchemeCodeUnspsc190501.41113608': __('identifierSchemeCodeUnspsc190501.41113608'),
	'identifierSchemeCodeUnspsc190501.41113611': __('identifierSchemeCodeUnspsc190501.41113611'),
	'identifierSchemeCodeUnspsc190501.41113612': __('identifierSchemeCodeUnspsc190501.41113612'),
	'identifierSchemeCodeUnspsc190501.41113613': __('identifierSchemeCodeUnspsc190501.41113613'),
	'identifierSchemeCodeUnspsc190501.41113614': __('identifierSchemeCodeUnspsc190501.41113614'),
	'identifierSchemeCodeUnspsc190501.41113615': __('identifierSchemeCodeUnspsc190501.41113615'),
	'identifierSchemeCodeUnspsc190501.41113616': __('identifierSchemeCodeUnspsc190501.41113616'),
	'identifierSchemeCodeUnspsc190501.41113617': __('identifierSchemeCodeUnspsc190501.41113617'),
	'identifierSchemeCodeUnspsc190501.41113618': __('identifierSchemeCodeUnspsc190501.41113618'),
	'identifierSchemeCodeUnspsc190501.41113619': __('identifierSchemeCodeUnspsc190501.41113619'),
	'identifierSchemeCodeUnspsc190501.41113620': __('identifierSchemeCodeUnspsc190501.41113620'),
	'identifierSchemeCodeUnspsc190501.41113621': __('identifierSchemeCodeUnspsc190501.41113621'),
	'identifierSchemeCodeUnspsc190501.41113622': __('identifierSchemeCodeUnspsc190501.41113622'),
	'identifierSchemeCodeUnspsc190501.41113623': __('identifierSchemeCodeUnspsc190501.41113623'),
	'identifierSchemeCodeUnspsc190501.41113624': __('identifierSchemeCodeUnspsc190501.41113624'),
	'identifierSchemeCodeUnspsc190501.41113625': __('identifierSchemeCodeUnspsc190501.41113625'),
	'identifierSchemeCodeUnspsc190501.41113626': __('identifierSchemeCodeUnspsc190501.41113626'),
	'identifierSchemeCodeUnspsc190501.41113627': __('identifierSchemeCodeUnspsc190501.41113627'),
	'identifierSchemeCodeUnspsc190501.41113628': __('identifierSchemeCodeUnspsc190501.41113628'),
	'identifierSchemeCodeUnspsc190501.41113629': __('identifierSchemeCodeUnspsc190501.41113629'),
	'identifierSchemeCodeUnspsc190501.41113630': __('identifierSchemeCodeUnspsc190501.41113630'),
	'identifierSchemeCodeUnspsc190501.41113631': __('identifierSchemeCodeUnspsc190501.41113631'),
	'identifierSchemeCodeUnspsc190501.41113632': __('identifierSchemeCodeUnspsc190501.41113632'),
	'identifierSchemeCodeUnspsc190501.41113633': __('identifierSchemeCodeUnspsc190501.41113633'),
	'identifierSchemeCodeUnspsc190501.41113634': __('identifierSchemeCodeUnspsc190501.41113634'),
	'identifierSchemeCodeUnspsc190501.41113635': __('identifierSchemeCodeUnspsc190501.41113635'),
	'identifierSchemeCodeUnspsc190501.41113636': __('identifierSchemeCodeUnspsc190501.41113636'),
	'identifierSchemeCodeUnspsc190501.41113637': __('identifierSchemeCodeUnspsc190501.41113637'),
	'identifierSchemeCodeUnspsc190501.41113638': __('identifierSchemeCodeUnspsc190501.41113638'),
	'identifierSchemeCodeUnspsc190501.41113639': __('identifierSchemeCodeUnspsc190501.41113639'),
	'identifierSchemeCodeUnspsc190501.41113640': __('identifierSchemeCodeUnspsc190501.41113640'),
	'identifierSchemeCodeUnspsc190501.41113641': __('identifierSchemeCodeUnspsc190501.41113641'),
	'identifierSchemeCodeUnspsc190501.41113642': __('identifierSchemeCodeUnspsc190501.41113642'),
	'identifierSchemeCodeUnspsc190501.41113643': __('identifierSchemeCodeUnspsc190501.41113643'),
	'identifierSchemeCodeUnspsc190501.41113644': __('identifierSchemeCodeUnspsc190501.41113644'),
	'identifierSchemeCodeUnspsc190501.41113645': __('identifierSchemeCodeUnspsc190501.41113645'),
	'identifierSchemeCodeUnspsc190501.41113646': __('identifierSchemeCodeUnspsc190501.41113646'),
	'identifierSchemeCodeUnspsc190501.41113647': __('identifierSchemeCodeUnspsc190501.41113647'),
	'identifierSchemeCodeUnspsc190501.41113649': __('identifierSchemeCodeUnspsc190501.41113649'),
	'identifierSchemeCodeUnspsc190501.41113650': __('identifierSchemeCodeUnspsc190501.41113650'),
	'identifierSchemeCodeUnspsc190501.41113651': __('identifierSchemeCodeUnspsc190501.41113651'),
	'identifierSchemeCodeUnspsc190501.41113652': __('identifierSchemeCodeUnspsc190501.41113652'),
	'identifierSchemeCodeUnspsc190501.41113653': __('identifierSchemeCodeUnspsc190501.41113653'),
	'identifierSchemeCodeUnspsc190501.41113654': __('identifierSchemeCodeUnspsc190501.41113654'),
	'identifierSchemeCodeUnspsc190501.41113655': __('identifierSchemeCodeUnspsc190501.41113655'),
	'identifierSchemeCodeUnspsc190501.41113656': __('identifierSchemeCodeUnspsc190501.41113656'),
	'identifierSchemeCodeUnspsc190501.41113657': __('identifierSchemeCodeUnspsc190501.41113657'),
	'identifierSchemeCodeUnspsc190501.41113658': __('identifierSchemeCodeUnspsc190501.41113658'),
	'identifierSchemeCodeUnspsc190501.41113659': __('identifierSchemeCodeUnspsc190501.41113659'),
	'identifierSchemeCodeUnspsc190501.41113660': __('identifierSchemeCodeUnspsc190501.41113660'),
	'identifierSchemeCodeUnspsc190501.41113661': __('identifierSchemeCodeUnspsc190501.41113661'),
	'identifierSchemeCodeUnspsc190501.41113662': __('identifierSchemeCodeUnspsc190501.41113662'),
	'identifierSchemeCodeUnspsc190501.41113663': __('identifierSchemeCodeUnspsc190501.41113663'),
	'identifierSchemeCodeUnspsc190501.41113664': __('identifierSchemeCodeUnspsc190501.41113664'),
	'identifierSchemeCodeUnspsc190501.41113665': __('identifierSchemeCodeUnspsc190501.41113665'),
	'identifierSchemeCodeUnspsc190501.41113666': __('identifierSchemeCodeUnspsc190501.41113666'),
	'identifierSchemeCodeUnspsc190501.41113667': __('identifierSchemeCodeUnspsc190501.41113667'),
	'identifierSchemeCodeUnspsc190501.41113668': __('identifierSchemeCodeUnspsc190501.41113668'),
	'identifierSchemeCodeUnspsc190501.41113669': __('identifierSchemeCodeUnspsc190501.41113669'),
	'identifierSchemeCodeUnspsc190501.41113670': __('identifierSchemeCodeUnspsc190501.41113670'),
	'identifierSchemeCodeUnspsc190501.41113671': __('identifierSchemeCodeUnspsc190501.41113671'),
	'identifierSchemeCodeUnspsc190501.41113672': __('identifierSchemeCodeUnspsc190501.41113672'),
	'identifierSchemeCodeUnspsc190501.41113673': __('identifierSchemeCodeUnspsc190501.41113673'),
	'identifierSchemeCodeUnspsc190501.41113674': __('identifierSchemeCodeUnspsc190501.41113674'),
	'identifierSchemeCodeUnspsc190501.41113675': __('identifierSchemeCodeUnspsc190501.41113675'),
	'identifierSchemeCodeUnspsc190501.41113676': __('identifierSchemeCodeUnspsc190501.41113676'),
	'identifierSchemeCodeUnspsc190501.41113677': __('identifierSchemeCodeUnspsc190501.41113677'),
	'identifierSchemeCodeUnspsc190501.41113678': __('identifierSchemeCodeUnspsc190501.41113678'),
	'identifierSchemeCodeUnspsc190501.41113679': __('identifierSchemeCodeUnspsc190501.41113679'),
	'identifierSchemeCodeUnspsc190501.41113680': __('identifierSchemeCodeUnspsc190501.41113680'),
	'identifierSchemeCodeUnspsc190501.41113681': __('identifierSchemeCodeUnspsc190501.41113681'),
	'identifierSchemeCodeUnspsc190501.41113682': __('identifierSchemeCodeUnspsc190501.41113682'),
	'identifierSchemeCodeUnspsc190501.41113683': __('identifierSchemeCodeUnspsc190501.41113683'),
	'identifierSchemeCodeUnspsc190501.41113684': __('identifierSchemeCodeUnspsc190501.41113684'),
	'identifierSchemeCodeUnspsc190501.41113685': __('identifierSchemeCodeUnspsc190501.41113685'),
	'identifierSchemeCodeUnspsc190501.41113686': __('identifierSchemeCodeUnspsc190501.41113686'),
	'identifierSchemeCodeUnspsc190501.41113687': __('identifierSchemeCodeUnspsc190501.41113687'),
	'identifierSchemeCodeUnspsc190501.41113688': __('identifierSchemeCodeUnspsc190501.41113688'),
	'identifierSchemeCodeUnspsc190501.41113689': __('identifierSchemeCodeUnspsc190501.41113689'),
	'identifierSchemeCodeUnspsc190501.41113700': __('identifierSchemeCodeUnspsc190501.41113700'),
	'identifierSchemeCodeUnspsc190501.41113701': __('identifierSchemeCodeUnspsc190501.41113701'),
	'identifierSchemeCodeUnspsc190501.41113702': __('identifierSchemeCodeUnspsc190501.41113702'),
	'identifierSchemeCodeUnspsc190501.41113703': __('identifierSchemeCodeUnspsc190501.41113703'),
	'identifierSchemeCodeUnspsc190501.41113704': __('identifierSchemeCodeUnspsc190501.41113704'),
	'identifierSchemeCodeUnspsc190501.41113705': __('identifierSchemeCodeUnspsc190501.41113705'),
	'identifierSchemeCodeUnspsc190501.41113706': __('identifierSchemeCodeUnspsc190501.41113706'),
	'identifierSchemeCodeUnspsc190501.41113707': __('identifierSchemeCodeUnspsc190501.41113707'),
	'identifierSchemeCodeUnspsc190501.41113708': __('identifierSchemeCodeUnspsc190501.41113708'),
	'identifierSchemeCodeUnspsc190501.41113709': __('identifierSchemeCodeUnspsc190501.41113709'),
	'identifierSchemeCodeUnspsc190501.41113710': __('identifierSchemeCodeUnspsc190501.41113710'),
	'identifierSchemeCodeUnspsc190501.41113711': __('identifierSchemeCodeUnspsc190501.41113711'),
	'identifierSchemeCodeUnspsc190501.41113712': __('identifierSchemeCodeUnspsc190501.41113712'),
	'identifierSchemeCodeUnspsc190501.41113713': __('identifierSchemeCodeUnspsc190501.41113713'),
	'identifierSchemeCodeUnspsc190501.41113714': __('identifierSchemeCodeUnspsc190501.41113714'),
	'identifierSchemeCodeUnspsc190501.41113715': __('identifierSchemeCodeUnspsc190501.41113715'),
	'identifierSchemeCodeUnspsc190501.41113716': __('identifierSchemeCodeUnspsc190501.41113716'),
	'identifierSchemeCodeUnspsc190501.41113717': __('identifierSchemeCodeUnspsc190501.41113717'),
	'identifierSchemeCodeUnspsc190501.41113718': __('identifierSchemeCodeUnspsc190501.41113718'),
	'identifierSchemeCodeUnspsc190501.41113719': __('identifierSchemeCodeUnspsc190501.41113719'),
	'identifierSchemeCodeUnspsc190501.41113720': __('identifierSchemeCodeUnspsc190501.41113720'),
	'identifierSchemeCodeUnspsc190501.41113721': __('identifierSchemeCodeUnspsc190501.41113721'),
	'identifierSchemeCodeUnspsc190501.41113722': __('identifierSchemeCodeUnspsc190501.41113722'),
	'identifierSchemeCodeUnspsc190501.41113723': __('identifierSchemeCodeUnspsc190501.41113723'),
	'identifierSchemeCodeUnspsc190501.41113724': __('identifierSchemeCodeUnspsc190501.41113724'),
	'identifierSchemeCodeUnspsc190501.41113725': __('identifierSchemeCodeUnspsc190501.41113725'),
	'identifierSchemeCodeUnspsc190501.41113726': __('identifierSchemeCodeUnspsc190501.41113726'),
	'identifierSchemeCodeUnspsc190501.41113727': __('identifierSchemeCodeUnspsc190501.41113727'),
	'identifierSchemeCodeUnspsc190501.41113728': __('identifierSchemeCodeUnspsc190501.41113728'),
	'identifierSchemeCodeUnspsc190501.41113729': __('identifierSchemeCodeUnspsc190501.41113729'),
	'identifierSchemeCodeUnspsc190501.41113730': __('identifierSchemeCodeUnspsc190501.41113730'),
	'identifierSchemeCodeUnspsc190501.41113731': __('identifierSchemeCodeUnspsc190501.41113731'),
	'identifierSchemeCodeUnspsc190501.41113732': __('identifierSchemeCodeUnspsc190501.41113732'),
	'identifierSchemeCodeUnspsc190501.41113733': __('identifierSchemeCodeUnspsc190501.41113733'),
	'identifierSchemeCodeUnspsc190501.41113734': __('identifierSchemeCodeUnspsc190501.41113734'),
	'identifierSchemeCodeUnspsc190501.41113735': __('identifierSchemeCodeUnspsc190501.41113735'),
	'identifierSchemeCodeUnspsc190501.41113736': __('identifierSchemeCodeUnspsc190501.41113736'),
	'identifierSchemeCodeUnspsc190501.41113737': __('identifierSchemeCodeUnspsc190501.41113737'),
	'identifierSchemeCodeUnspsc190501.41113738': __('identifierSchemeCodeUnspsc190501.41113738'),
	'identifierSchemeCodeUnspsc190501.41113739': __('identifierSchemeCodeUnspsc190501.41113739'),
	'identifierSchemeCodeUnspsc190501.41113740': __('identifierSchemeCodeUnspsc190501.41113740'),
	'identifierSchemeCodeUnspsc190501.41113800': __('identifierSchemeCodeUnspsc190501.41113800'),
	'identifierSchemeCodeUnspsc190501.41113801': __('identifierSchemeCodeUnspsc190501.41113801'),
	'identifierSchemeCodeUnspsc190501.41113802': __('identifierSchemeCodeUnspsc190501.41113802'),
	'identifierSchemeCodeUnspsc190501.41113803': __('identifierSchemeCodeUnspsc190501.41113803'),
	'identifierSchemeCodeUnspsc190501.41113804': __('identifierSchemeCodeUnspsc190501.41113804'),
	'identifierSchemeCodeUnspsc190501.41113805': __('identifierSchemeCodeUnspsc190501.41113805'),
	'identifierSchemeCodeUnspsc190501.41113806': __('identifierSchemeCodeUnspsc190501.41113806'),
	'identifierSchemeCodeUnspsc190501.41113807': __('identifierSchemeCodeUnspsc190501.41113807'),
	'identifierSchemeCodeUnspsc190501.41113808': __('identifierSchemeCodeUnspsc190501.41113808'),
	'identifierSchemeCodeUnspsc190501.41113809': __('identifierSchemeCodeUnspsc190501.41113809'),
	'identifierSchemeCodeUnspsc190501.41113810': __('identifierSchemeCodeUnspsc190501.41113810'),
	'identifierSchemeCodeUnspsc190501.41113811': __('identifierSchemeCodeUnspsc190501.41113811'),
	'identifierSchemeCodeUnspsc190501.41113812': __('identifierSchemeCodeUnspsc190501.41113812'),
	'identifierSchemeCodeUnspsc190501.41113813': __('identifierSchemeCodeUnspsc190501.41113813'),
	'identifierSchemeCodeUnspsc190501.41113814': __('identifierSchemeCodeUnspsc190501.41113814'),
	'identifierSchemeCodeUnspsc190501.41113815': __('identifierSchemeCodeUnspsc190501.41113815'),
	'identifierSchemeCodeUnspsc190501.41113816': __('identifierSchemeCodeUnspsc190501.41113816'),
	'identifierSchemeCodeUnspsc190501.41113817': __('identifierSchemeCodeUnspsc190501.41113817'),
	'identifierSchemeCodeUnspsc190501.41113818': __('identifierSchemeCodeUnspsc190501.41113818'),
	'identifierSchemeCodeUnspsc190501.41113819': __('identifierSchemeCodeUnspsc190501.41113819'),
	'identifierSchemeCodeUnspsc190501.41113820': __('identifierSchemeCodeUnspsc190501.41113820'),
	'identifierSchemeCodeUnspsc190501.41113821': __('identifierSchemeCodeUnspsc190501.41113821'),
	'identifierSchemeCodeUnspsc190501.41113822': __('identifierSchemeCodeUnspsc190501.41113822'),
	'identifierSchemeCodeUnspsc190501.41113823': __('identifierSchemeCodeUnspsc190501.41113823'),
	'identifierSchemeCodeUnspsc190501.41113824': __('identifierSchemeCodeUnspsc190501.41113824'),
	'identifierSchemeCodeUnspsc190501.41113825': __('identifierSchemeCodeUnspsc190501.41113825'),
	'identifierSchemeCodeUnspsc190501.41113826': __('identifierSchemeCodeUnspsc190501.41113826'),
	'identifierSchemeCodeUnspsc190501.41113827': __('identifierSchemeCodeUnspsc190501.41113827'),
	'identifierSchemeCodeUnspsc190501.41113828': __('identifierSchemeCodeUnspsc190501.41113828'),
	'identifierSchemeCodeUnspsc190501.41113829': __('identifierSchemeCodeUnspsc190501.41113829'),
	'identifierSchemeCodeUnspsc190501.41113830': __('identifierSchemeCodeUnspsc190501.41113830'),
	'identifierSchemeCodeUnspsc190501.41113831': __('identifierSchemeCodeUnspsc190501.41113831'),
	'identifierSchemeCodeUnspsc190501.41113900': __('identifierSchemeCodeUnspsc190501.41113900'),
	'identifierSchemeCodeUnspsc190501.41113901': __('identifierSchemeCodeUnspsc190501.41113901'),
	'identifierSchemeCodeUnspsc190501.41113902': __('identifierSchemeCodeUnspsc190501.41113902'),
	'identifierSchemeCodeUnspsc190501.41113903': __('identifierSchemeCodeUnspsc190501.41113903'),
	'identifierSchemeCodeUnspsc190501.41113904': __('identifierSchemeCodeUnspsc190501.41113904'),
	'identifierSchemeCodeUnspsc190501.41113905': __('identifierSchemeCodeUnspsc190501.41113905'),
	'identifierSchemeCodeUnspsc190501.41113906': __('identifierSchemeCodeUnspsc190501.41113906'),
	'identifierSchemeCodeUnspsc190501.41113907': __('identifierSchemeCodeUnspsc190501.41113907'),
	'identifierSchemeCodeUnspsc190501.41113908': __('identifierSchemeCodeUnspsc190501.41113908'),
	'identifierSchemeCodeUnspsc190501.41113909': __('identifierSchemeCodeUnspsc190501.41113909'),
	'identifierSchemeCodeUnspsc190501.41113910': __('identifierSchemeCodeUnspsc190501.41113910'),
	'identifierSchemeCodeUnspsc190501.41114000': __('identifierSchemeCodeUnspsc190501.41114000'),
	'identifierSchemeCodeUnspsc190501.41114001': __('identifierSchemeCodeUnspsc190501.41114001'),
	'identifierSchemeCodeUnspsc190501.41114100': __('identifierSchemeCodeUnspsc190501.41114100'),
	'identifierSchemeCodeUnspsc190501.41114102': __('identifierSchemeCodeUnspsc190501.41114102'),
	'identifierSchemeCodeUnspsc190501.41114103': __('identifierSchemeCodeUnspsc190501.41114103'),
	'identifierSchemeCodeUnspsc190501.41114104': __('identifierSchemeCodeUnspsc190501.41114104'),
	'identifierSchemeCodeUnspsc190501.41114105': __('identifierSchemeCodeUnspsc190501.41114105'),
	'identifierSchemeCodeUnspsc190501.41114106': __('identifierSchemeCodeUnspsc190501.41114106'),
	'identifierSchemeCodeUnspsc190501.41114107': __('identifierSchemeCodeUnspsc190501.41114107'),
	'identifierSchemeCodeUnspsc190501.41114108': __('identifierSchemeCodeUnspsc190501.41114108'),
	'identifierSchemeCodeUnspsc190501.41114200': __('identifierSchemeCodeUnspsc190501.41114200'),
	'identifierSchemeCodeUnspsc190501.41114201': __('identifierSchemeCodeUnspsc190501.41114201'),
	'identifierSchemeCodeUnspsc190501.41114202': __('identifierSchemeCodeUnspsc190501.41114202'),
	'identifierSchemeCodeUnspsc190501.41114203': __('identifierSchemeCodeUnspsc190501.41114203'),
	'identifierSchemeCodeUnspsc190501.41114204': __('identifierSchemeCodeUnspsc190501.41114204'),
	'identifierSchemeCodeUnspsc190501.41114205': __('identifierSchemeCodeUnspsc190501.41114205'),
	'identifierSchemeCodeUnspsc190501.41114206': __('identifierSchemeCodeUnspsc190501.41114206'),
	'identifierSchemeCodeUnspsc190501.41114207': __('identifierSchemeCodeUnspsc190501.41114207'),
	'identifierSchemeCodeUnspsc190501.41114208': __('identifierSchemeCodeUnspsc190501.41114208'),
	'identifierSchemeCodeUnspsc190501.41114209': __('identifierSchemeCodeUnspsc190501.41114209'),
	'identifierSchemeCodeUnspsc190501.41114210': __('identifierSchemeCodeUnspsc190501.41114210'),
	'identifierSchemeCodeUnspsc190501.41114211': __('identifierSchemeCodeUnspsc190501.41114211'),
	'identifierSchemeCodeUnspsc190501.41114212': __('identifierSchemeCodeUnspsc190501.41114212'),
	'identifierSchemeCodeUnspsc190501.41114213': __('identifierSchemeCodeUnspsc190501.41114213'),
	'identifierSchemeCodeUnspsc190501.41114214': __('identifierSchemeCodeUnspsc190501.41114214'),
	'identifierSchemeCodeUnspsc190501.41114215': __('identifierSchemeCodeUnspsc190501.41114215'),
	'identifierSchemeCodeUnspsc190501.41114216': __('identifierSchemeCodeUnspsc190501.41114216'),
	'identifierSchemeCodeUnspsc190501.41114217': __('identifierSchemeCodeUnspsc190501.41114217'),
	'identifierSchemeCodeUnspsc190501.41114218': __('identifierSchemeCodeUnspsc190501.41114218'),
	'identifierSchemeCodeUnspsc190501.41114219': __('identifierSchemeCodeUnspsc190501.41114219'),
	'identifierSchemeCodeUnspsc190501.41114220': __('identifierSchemeCodeUnspsc190501.41114220'),
	'identifierSchemeCodeUnspsc190501.41114221': __('identifierSchemeCodeUnspsc190501.41114221'),
	'identifierSchemeCodeUnspsc190501.41114300': __('identifierSchemeCodeUnspsc190501.41114300'),
	'identifierSchemeCodeUnspsc190501.41114301': __('identifierSchemeCodeUnspsc190501.41114301'),
	'identifierSchemeCodeUnspsc190501.41114302': __('identifierSchemeCodeUnspsc190501.41114302'),
	'identifierSchemeCodeUnspsc190501.41114303': __('identifierSchemeCodeUnspsc190501.41114303'),
	'identifierSchemeCodeUnspsc190501.41114400': __('identifierSchemeCodeUnspsc190501.41114400'),
	'identifierSchemeCodeUnspsc190501.41114401': __('identifierSchemeCodeUnspsc190501.41114401'),
	'identifierSchemeCodeUnspsc190501.41114402': __('identifierSchemeCodeUnspsc190501.41114402'),
	'identifierSchemeCodeUnspsc190501.41114403': __('identifierSchemeCodeUnspsc190501.41114403'),
	'identifierSchemeCodeUnspsc190501.41114404': __('identifierSchemeCodeUnspsc190501.41114404'),
	'identifierSchemeCodeUnspsc190501.41114405': __('identifierSchemeCodeUnspsc190501.41114405'),
	'identifierSchemeCodeUnspsc190501.41114406': __('identifierSchemeCodeUnspsc190501.41114406'),
	'identifierSchemeCodeUnspsc190501.41114407': __('identifierSchemeCodeUnspsc190501.41114407'),
	'identifierSchemeCodeUnspsc190501.41114408': __('identifierSchemeCodeUnspsc190501.41114408'),
	'identifierSchemeCodeUnspsc190501.41114409': __('identifierSchemeCodeUnspsc190501.41114409'),
	'identifierSchemeCodeUnspsc190501.41114410': __('identifierSchemeCodeUnspsc190501.41114410'),
	'identifierSchemeCodeUnspsc190501.41114411': __('identifierSchemeCodeUnspsc190501.41114411'),
	'identifierSchemeCodeUnspsc190501.41114412': __('identifierSchemeCodeUnspsc190501.41114412'),
	'identifierSchemeCodeUnspsc190501.41114413': __('identifierSchemeCodeUnspsc190501.41114413'),
	'identifierSchemeCodeUnspsc190501.41114414': __('identifierSchemeCodeUnspsc190501.41114414'),
	'identifierSchemeCodeUnspsc190501.41114415': __('identifierSchemeCodeUnspsc190501.41114415'),
	'identifierSchemeCodeUnspsc190501.41114416': __('identifierSchemeCodeUnspsc190501.41114416'),
	'identifierSchemeCodeUnspsc190501.41114417': __('identifierSchemeCodeUnspsc190501.41114417'),
	'identifierSchemeCodeUnspsc190501.41114418': __('identifierSchemeCodeUnspsc190501.41114418'),
	'identifierSchemeCodeUnspsc190501.41114419': __('identifierSchemeCodeUnspsc190501.41114419'),
	'identifierSchemeCodeUnspsc190501.41114420': __('identifierSchemeCodeUnspsc190501.41114420'),
	'identifierSchemeCodeUnspsc190501.41114421': __('identifierSchemeCodeUnspsc190501.41114421'),
	'identifierSchemeCodeUnspsc190501.41114422': __('identifierSchemeCodeUnspsc190501.41114422'),
	'identifierSchemeCodeUnspsc190501.41114423': __('identifierSchemeCodeUnspsc190501.41114423'),
	'identifierSchemeCodeUnspsc190501.41114424': __('identifierSchemeCodeUnspsc190501.41114424'),
	'identifierSchemeCodeUnspsc190501.41114425': __('identifierSchemeCodeUnspsc190501.41114425'),
	'identifierSchemeCodeUnspsc190501.41114426': __('identifierSchemeCodeUnspsc190501.41114426'),
	'identifierSchemeCodeUnspsc190501.41114427': __('identifierSchemeCodeUnspsc190501.41114427'),
	'identifierSchemeCodeUnspsc190501.41114500': __('identifierSchemeCodeUnspsc190501.41114500'),
	'identifierSchemeCodeUnspsc190501.41114501': __('identifierSchemeCodeUnspsc190501.41114501'),
	'identifierSchemeCodeUnspsc190501.41114502': __('identifierSchemeCodeUnspsc190501.41114502'),
	'identifierSchemeCodeUnspsc190501.41114503': __('identifierSchemeCodeUnspsc190501.41114503'),
	'identifierSchemeCodeUnspsc190501.41114504': __('identifierSchemeCodeUnspsc190501.41114504'),
	'identifierSchemeCodeUnspsc190501.41114505': __('identifierSchemeCodeUnspsc190501.41114505'),
	'identifierSchemeCodeUnspsc190501.41114506': __('identifierSchemeCodeUnspsc190501.41114506'),
	'identifierSchemeCodeUnspsc190501.41114507': __('identifierSchemeCodeUnspsc190501.41114507'),
	'identifierSchemeCodeUnspsc190501.41114508': __('identifierSchemeCodeUnspsc190501.41114508'),
	'identifierSchemeCodeUnspsc190501.41114509': __('identifierSchemeCodeUnspsc190501.41114509'),
	'identifierSchemeCodeUnspsc190501.41114510': __('identifierSchemeCodeUnspsc190501.41114510'),
	'identifierSchemeCodeUnspsc190501.41114511': __('identifierSchemeCodeUnspsc190501.41114511'),
	'identifierSchemeCodeUnspsc190501.41114512': __('identifierSchemeCodeUnspsc190501.41114512'),
	'identifierSchemeCodeUnspsc190501.41114513': __('identifierSchemeCodeUnspsc190501.41114513'),
	'identifierSchemeCodeUnspsc190501.41114514': __('identifierSchemeCodeUnspsc190501.41114514'),
	'identifierSchemeCodeUnspsc190501.41114515': __('identifierSchemeCodeUnspsc190501.41114515'),
	'identifierSchemeCodeUnspsc190501.41114516': __('identifierSchemeCodeUnspsc190501.41114516'),
	'identifierSchemeCodeUnspsc190501.41114517': __('identifierSchemeCodeUnspsc190501.41114517'),
	'identifierSchemeCodeUnspsc190501.41114518': __('identifierSchemeCodeUnspsc190501.41114518'),
	'identifierSchemeCodeUnspsc190501.41114519': __('identifierSchemeCodeUnspsc190501.41114519'),
	'identifierSchemeCodeUnspsc190501.41114520': __('identifierSchemeCodeUnspsc190501.41114520'),
	'identifierSchemeCodeUnspsc190501.41114521': __('identifierSchemeCodeUnspsc190501.41114521'),
	'identifierSchemeCodeUnspsc190501.41114522': __('identifierSchemeCodeUnspsc190501.41114522'),
	'identifierSchemeCodeUnspsc190501.41114523': __('identifierSchemeCodeUnspsc190501.41114523'),
	'identifierSchemeCodeUnspsc190501.41114524': __('identifierSchemeCodeUnspsc190501.41114524'),
	'identifierSchemeCodeUnspsc190501.41114525': __('identifierSchemeCodeUnspsc190501.41114525'),
	'identifierSchemeCodeUnspsc190501.41114526': __('identifierSchemeCodeUnspsc190501.41114526'),
	'identifierSchemeCodeUnspsc190501.41114527': __('identifierSchemeCodeUnspsc190501.41114527'),
	'identifierSchemeCodeUnspsc190501.41114528': __('identifierSchemeCodeUnspsc190501.41114528'),
	'identifierSchemeCodeUnspsc190501.41114529': __('identifierSchemeCodeUnspsc190501.41114529'),
	'identifierSchemeCodeUnspsc190501.41114530': __('identifierSchemeCodeUnspsc190501.41114530'),
	'identifierSchemeCodeUnspsc190501.41114600': __('identifierSchemeCodeUnspsc190501.41114600'),
	'identifierSchemeCodeUnspsc190501.41114601': __('identifierSchemeCodeUnspsc190501.41114601'),
	'identifierSchemeCodeUnspsc190501.41114602': __('identifierSchemeCodeUnspsc190501.41114602'),
	'identifierSchemeCodeUnspsc190501.41114603': __('identifierSchemeCodeUnspsc190501.41114603'),
	'identifierSchemeCodeUnspsc190501.41114604': __('identifierSchemeCodeUnspsc190501.41114604'),
	'identifierSchemeCodeUnspsc190501.41114605': __('identifierSchemeCodeUnspsc190501.41114605'),
	'identifierSchemeCodeUnspsc190501.41114606': __('identifierSchemeCodeUnspsc190501.41114606'),
	'identifierSchemeCodeUnspsc190501.41114607': __('identifierSchemeCodeUnspsc190501.41114607'),
	'identifierSchemeCodeUnspsc190501.41114608': __('identifierSchemeCodeUnspsc190501.41114608'),
	'identifierSchemeCodeUnspsc190501.41114609': __('identifierSchemeCodeUnspsc190501.41114609'),
	'identifierSchemeCodeUnspsc190501.41114610': __('identifierSchemeCodeUnspsc190501.41114610'),
	'identifierSchemeCodeUnspsc190501.41114611': __('identifierSchemeCodeUnspsc190501.41114611'),
	'identifierSchemeCodeUnspsc190501.41114612': __('identifierSchemeCodeUnspsc190501.41114612'),
	'identifierSchemeCodeUnspsc190501.41114613': __('identifierSchemeCodeUnspsc190501.41114613'),
	'identifierSchemeCodeUnspsc190501.41114614': __('identifierSchemeCodeUnspsc190501.41114614'),
	'identifierSchemeCodeUnspsc190501.41114615': __('identifierSchemeCodeUnspsc190501.41114615'),
	'identifierSchemeCodeUnspsc190501.41114616': __('identifierSchemeCodeUnspsc190501.41114616'),
	'identifierSchemeCodeUnspsc190501.41114617': __('identifierSchemeCodeUnspsc190501.41114617'),
	'identifierSchemeCodeUnspsc190501.41114618': __('identifierSchemeCodeUnspsc190501.41114618'),
	'identifierSchemeCodeUnspsc190501.41114619': __('identifierSchemeCodeUnspsc190501.41114619'),
	'identifierSchemeCodeUnspsc190501.41114620': __('identifierSchemeCodeUnspsc190501.41114620'),
	'identifierSchemeCodeUnspsc190501.41114621': __('identifierSchemeCodeUnspsc190501.41114621'),
	'identifierSchemeCodeUnspsc190501.41114622': __('identifierSchemeCodeUnspsc190501.41114622'),
	'identifierSchemeCodeUnspsc190501.41114623': __('identifierSchemeCodeUnspsc190501.41114623'),
	'identifierSchemeCodeUnspsc190501.41114624': __('identifierSchemeCodeUnspsc190501.41114624'),
	'identifierSchemeCodeUnspsc190501.41114625': __('identifierSchemeCodeUnspsc190501.41114625'),
	'identifierSchemeCodeUnspsc190501.41114626': __('identifierSchemeCodeUnspsc190501.41114626'),
	'identifierSchemeCodeUnspsc190501.41114627': __('identifierSchemeCodeUnspsc190501.41114627'),
	'identifierSchemeCodeUnspsc190501.41114628': __('identifierSchemeCodeUnspsc190501.41114628'),
	'identifierSchemeCodeUnspsc190501.41114629': __('identifierSchemeCodeUnspsc190501.41114629'),
	'identifierSchemeCodeUnspsc190501.41114630': __('identifierSchemeCodeUnspsc190501.41114630'),
	'identifierSchemeCodeUnspsc190501.41114631': __('identifierSchemeCodeUnspsc190501.41114631'),
	'identifierSchemeCodeUnspsc190501.41114632': __('identifierSchemeCodeUnspsc190501.41114632'),
	'identifierSchemeCodeUnspsc190501.41114633': __('identifierSchemeCodeUnspsc190501.41114633'),
	'identifierSchemeCodeUnspsc190501.41114634': __('identifierSchemeCodeUnspsc190501.41114634'),
	'identifierSchemeCodeUnspsc190501.41114635': __('identifierSchemeCodeUnspsc190501.41114635'),
	'identifierSchemeCodeUnspsc190501.41114636': __('identifierSchemeCodeUnspsc190501.41114636'),
	'identifierSchemeCodeUnspsc190501.41114637': __('identifierSchemeCodeUnspsc190501.41114637'),
	'identifierSchemeCodeUnspsc190501.41114638': __('identifierSchemeCodeUnspsc190501.41114638'),
	'identifierSchemeCodeUnspsc190501.41114639': __('identifierSchemeCodeUnspsc190501.41114639'),
	'identifierSchemeCodeUnspsc190501.41114640': __('identifierSchemeCodeUnspsc190501.41114640'),
	'identifierSchemeCodeUnspsc190501.41114641': __('identifierSchemeCodeUnspsc190501.41114641'),
	'identifierSchemeCodeUnspsc190501.41114642': __('identifierSchemeCodeUnspsc190501.41114642'),
	'identifierSchemeCodeUnspsc190501.41114643': __('identifierSchemeCodeUnspsc190501.41114643'),
	'identifierSchemeCodeUnspsc190501.41114644': __('identifierSchemeCodeUnspsc190501.41114644'),
	'identifierSchemeCodeUnspsc190501.41114645': __('identifierSchemeCodeUnspsc190501.41114645'),
	'identifierSchemeCodeUnspsc190501.41114646': __('identifierSchemeCodeUnspsc190501.41114646'),
	'identifierSchemeCodeUnspsc190501.41114647': __('identifierSchemeCodeUnspsc190501.41114647'),
	'identifierSchemeCodeUnspsc190501.41114648': __('identifierSchemeCodeUnspsc190501.41114648'),
	'identifierSchemeCodeUnspsc190501.41114649': __('identifierSchemeCodeUnspsc190501.41114649'),
	'identifierSchemeCodeUnspsc190501.41114650': __('identifierSchemeCodeUnspsc190501.41114650'),
	'identifierSchemeCodeUnspsc190501.41114700': __('identifierSchemeCodeUnspsc190501.41114700'),
	'identifierSchemeCodeUnspsc190501.41114701': __('identifierSchemeCodeUnspsc190501.41114701'),
	'identifierSchemeCodeUnspsc190501.41114702': __('identifierSchemeCodeUnspsc190501.41114702'),
	'identifierSchemeCodeUnspsc190501.41114703': __('identifierSchemeCodeUnspsc190501.41114703'),
	'identifierSchemeCodeUnspsc190501.41114704': __('identifierSchemeCodeUnspsc190501.41114704'),
	'identifierSchemeCodeUnspsc190501.41114705': __('identifierSchemeCodeUnspsc190501.41114705'),
	'identifierSchemeCodeUnspsc190501.41114706': __('identifierSchemeCodeUnspsc190501.41114706'),
	'identifierSchemeCodeUnspsc190501.41114707': __('identifierSchemeCodeUnspsc190501.41114707'),
	'identifierSchemeCodeUnspsc190501.41114708': __('identifierSchemeCodeUnspsc190501.41114708'),
	'identifierSchemeCodeUnspsc190501.41114709': __('identifierSchemeCodeUnspsc190501.41114709'),
	'identifierSchemeCodeUnspsc190501.41114710': __('identifierSchemeCodeUnspsc190501.41114710'),
	'identifierSchemeCodeUnspsc190501.41114711': __('identifierSchemeCodeUnspsc190501.41114711'),
	'identifierSchemeCodeUnspsc190501.41114712': __('identifierSchemeCodeUnspsc190501.41114712'),
	'identifierSchemeCodeUnspsc190501.41114713': __('identifierSchemeCodeUnspsc190501.41114713'),
	'identifierSchemeCodeUnspsc190501.41114714': __('identifierSchemeCodeUnspsc190501.41114714'),
	'identifierSchemeCodeUnspsc190501.41114715': __('identifierSchemeCodeUnspsc190501.41114715'),
	'identifierSchemeCodeUnspsc190501.41114716': __('identifierSchemeCodeUnspsc190501.41114716'),
	'identifierSchemeCodeUnspsc190501.41114717': __('identifierSchemeCodeUnspsc190501.41114717'),
	'identifierSchemeCodeUnspsc190501.41114718': __('identifierSchemeCodeUnspsc190501.41114718'),
	'identifierSchemeCodeUnspsc190501.41114719': __('identifierSchemeCodeUnspsc190501.41114719'),
	'identifierSchemeCodeUnspsc190501.41114720': __('identifierSchemeCodeUnspsc190501.41114720'),
	'identifierSchemeCodeUnspsc190501.41114721': __('identifierSchemeCodeUnspsc190501.41114721'),
	'identifierSchemeCodeUnspsc190501.41114722': __('identifierSchemeCodeUnspsc190501.41114722'),
	'identifierSchemeCodeUnspsc190501.41114723': __('identifierSchemeCodeUnspsc190501.41114723'),
	'identifierSchemeCodeUnspsc190501.41114800': __('identifierSchemeCodeUnspsc190501.41114800'),
	'identifierSchemeCodeUnspsc190501.41114801': __('identifierSchemeCodeUnspsc190501.41114801'),
	'identifierSchemeCodeUnspsc190501.41114802': __('identifierSchemeCodeUnspsc190501.41114802'),
	'identifierSchemeCodeUnspsc190501.41114803': __('identifierSchemeCodeUnspsc190501.41114803'),
	'identifierSchemeCodeUnspsc190501.41115100': __('identifierSchemeCodeUnspsc190501.41115100'),
	'identifierSchemeCodeUnspsc190501.41115101': __('identifierSchemeCodeUnspsc190501.41115101'),
	'identifierSchemeCodeUnspsc190501.41115102': __('identifierSchemeCodeUnspsc190501.41115102'),
	'identifierSchemeCodeUnspsc190501.41115200': __('identifierSchemeCodeUnspsc190501.41115200'),
	'identifierSchemeCodeUnspsc190501.41115201': __('identifierSchemeCodeUnspsc190501.41115201'),
	'identifierSchemeCodeUnspsc190501.41115202': __('identifierSchemeCodeUnspsc190501.41115202'),
	'identifierSchemeCodeUnspsc190501.41115203': __('identifierSchemeCodeUnspsc190501.41115203'),
	'identifierSchemeCodeUnspsc190501.41115300': __('identifierSchemeCodeUnspsc190501.41115300'),
	'identifierSchemeCodeUnspsc190501.41115301': __('identifierSchemeCodeUnspsc190501.41115301'),
	'identifierSchemeCodeUnspsc190501.41115302': __('identifierSchemeCodeUnspsc190501.41115302'),
	'identifierSchemeCodeUnspsc190501.41115303': __('identifierSchemeCodeUnspsc190501.41115303'),
	'identifierSchemeCodeUnspsc190501.41115304': __('identifierSchemeCodeUnspsc190501.41115304'),
	'identifierSchemeCodeUnspsc190501.41115305': __('identifierSchemeCodeUnspsc190501.41115305'),
	'identifierSchemeCodeUnspsc190501.41115306': __('identifierSchemeCodeUnspsc190501.41115306'),
	'identifierSchemeCodeUnspsc190501.41115307': __('identifierSchemeCodeUnspsc190501.41115307'),
	'identifierSchemeCodeUnspsc190501.41115308': __('identifierSchemeCodeUnspsc190501.41115308'),
	'identifierSchemeCodeUnspsc190501.41115309': __('identifierSchemeCodeUnspsc190501.41115309'),
	'identifierSchemeCodeUnspsc190501.41115310': __('identifierSchemeCodeUnspsc190501.41115310'),
	'identifierSchemeCodeUnspsc190501.41115311': __('identifierSchemeCodeUnspsc190501.41115311'),
	'identifierSchemeCodeUnspsc190501.41115312': __('identifierSchemeCodeUnspsc190501.41115312'),
	'identifierSchemeCodeUnspsc190501.41115313': __('identifierSchemeCodeUnspsc190501.41115313'),
	'identifierSchemeCodeUnspsc190501.41115314': __('identifierSchemeCodeUnspsc190501.41115314'),
	'identifierSchemeCodeUnspsc190501.41115315': __('identifierSchemeCodeUnspsc190501.41115315'),
	'identifierSchemeCodeUnspsc190501.41115316': __('identifierSchemeCodeUnspsc190501.41115316'),
	'identifierSchemeCodeUnspsc190501.41115317': __('identifierSchemeCodeUnspsc190501.41115317'),
	'identifierSchemeCodeUnspsc190501.41115318': __('identifierSchemeCodeUnspsc190501.41115318'),
	'identifierSchemeCodeUnspsc190501.41115319': __('identifierSchemeCodeUnspsc190501.41115319'),
	'identifierSchemeCodeUnspsc190501.41115320': __('identifierSchemeCodeUnspsc190501.41115320'),
	'identifierSchemeCodeUnspsc190501.41115321': __('identifierSchemeCodeUnspsc190501.41115321'),
	'identifierSchemeCodeUnspsc190501.41115322': __('identifierSchemeCodeUnspsc190501.41115322'),
	'identifierSchemeCodeUnspsc190501.41115323': __('identifierSchemeCodeUnspsc190501.41115323'),
	'identifierSchemeCodeUnspsc190501.41115324': __('identifierSchemeCodeUnspsc190501.41115324'),
	'identifierSchemeCodeUnspsc190501.41115325': __('identifierSchemeCodeUnspsc190501.41115325'),
	'identifierSchemeCodeUnspsc190501.41115326': __('identifierSchemeCodeUnspsc190501.41115326'),
	'identifierSchemeCodeUnspsc190501.41115327': __('identifierSchemeCodeUnspsc190501.41115327'),
	'identifierSchemeCodeUnspsc190501.41115328': __('identifierSchemeCodeUnspsc190501.41115328'),
	'identifierSchemeCodeUnspsc190501.41115329': __('identifierSchemeCodeUnspsc190501.41115329'),
	'identifierSchemeCodeUnspsc190501.41115330': __('identifierSchemeCodeUnspsc190501.41115330'),
	'identifierSchemeCodeUnspsc190501.41115331': __('identifierSchemeCodeUnspsc190501.41115331'),
	'identifierSchemeCodeUnspsc190501.41115332': __('identifierSchemeCodeUnspsc190501.41115332'),
	'identifierSchemeCodeUnspsc190501.41115333': __('identifierSchemeCodeUnspsc190501.41115333'),
	'identifierSchemeCodeUnspsc190501.41115334': __('identifierSchemeCodeUnspsc190501.41115334'),
	'identifierSchemeCodeUnspsc190501.41115335': __('identifierSchemeCodeUnspsc190501.41115335'),
	'identifierSchemeCodeUnspsc190501.41115336': __('identifierSchemeCodeUnspsc190501.41115336'),
	'identifierSchemeCodeUnspsc190501.41115337': __('identifierSchemeCodeUnspsc190501.41115337'),
	'identifierSchemeCodeUnspsc190501.41115338': __('identifierSchemeCodeUnspsc190501.41115338'),
	'identifierSchemeCodeUnspsc190501.41115339': __('identifierSchemeCodeUnspsc190501.41115339'),
	'identifierSchemeCodeUnspsc190501.41115340': __('identifierSchemeCodeUnspsc190501.41115340'),
	'identifierSchemeCodeUnspsc190501.41115400': __('identifierSchemeCodeUnspsc190501.41115400'),
	'identifierSchemeCodeUnspsc190501.41115401': __('identifierSchemeCodeUnspsc190501.41115401'),
	'identifierSchemeCodeUnspsc190501.41115402': __('identifierSchemeCodeUnspsc190501.41115402'),
	'identifierSchemeCodeUnspsc190501.41115403': __('identifierSchemeCodeUnspsc190501.41115403'),
	'identifierSchemeCodeUnspsc190501.41115404': __('identifierSchemeCodeUnspsc190501.41115404'),
	'identifierSchemeCodeUnspsc190501.41115405': __('identifierSchemeCodeUnspsc190501.41115405'),
	'identifierSchemeCodeUnspsc190501.41115406': __('identifierSchemeCodeUnspsc190501.41115406'),
	'identifierSchemeCodeUnspsc190501.41115407': __('identifierSchemeCodeUnspsc190501.41115407'),
	'identifierSchemeCodeUnspsc190501.41115408': __('identifierSchemeCodeUnspsc190501.41115408'),
	'identifierSchemeCodeUnspsc190501.41115409': __('identifierSchemeCodeUnspsc190501.41115409'),
	'identifierSchemeCodeUnspsc190501.41115411': __('identifierSchemeCodeUnspsc190501.41115411'),
	'identifierSchemeCodeUnspsc190501.41115412': __('identifierSchemeCodeUnspsc190501.41115412'),
	'identifierSchemeCodeUnspsc190501.41115413': __('identifierSchemeCodeUnspsc190501.41115413'),
	'identifierSchemeCodeUnspsc190501.41115414': __('identifierSchemeCodeUnspsc190501.41115414'),
	'identifierSchemeCodeUnspsc190501.41115415': __('identifierSchemeCodeUnspsc190501.41115415'),
	'identifierSchemeCodeUnspsc190501.41115416': __('identifierSchemeCodeUnspsc190501.41115416'),
	'identifierSchemeCodeUnspsc190501.41115417': __('identifierSchemeCodeUnspsc190501.41115417'),
	'identifierSchemeCodeUnspsc190501.41115418': __('identifierSchemeCodeUnspsc190501.41115418'),
	'identifierSchemeCodeUnspsc190501.41115500': __('identifierSchemeCodeUnspsc190501.41115500'),
	'identifierSchemeCodeUnspsc190501.41115501': __('identifierSchemeCodeUnspsc190501.41115501'),
	'identifierSchemeCodeUnspsc190501.41115502': __('identifierSchemeCodeUnspsc190501.41115502'),
	'identifierSchemeCodeUnspsc190501.41115503': __('identifierSchemeCodeUnspsc190501.41115503'),
	'identifierSchemeCodeUnspsc190501.41115504': __('identifierSchemeCodeUnspsc190501.41115504'),
	'identifierSchemeCodeUnspsc190501.41115505': __('identifierSchemeCodeUnspsc190501.41115505'),
	'identifierSchemeCodeUnspsc190501.41115506': __('identifierSchemeCodeUnspsc190501.41115506'),
	'identifierSchemeCodeUnspsc190501.41115507': __('identifierSchemeCodeUnspsc190501.41115507'),
	'identifierSchemeCodeUnspsc190501.41115508': __('identifierSchemeCodeUnspsc190501.41115508'),
	'identifierSchemeCodeUnspsc190501.41115509': __('identifierSchemeCodeUnspsc190501.41115509'),
	'identifierSchemeCodeUnspsc190501.41115510': __('identifierSchemeCodeUnspsc190501.41115510'),
	'identifierSchemeCodeUnspsc190501.41115511': __('identifierSchemeCodeUnspsc190501.41115511'),
	'identifierSchemeCodeUnspsc190501.41115512': __('identifierSchemeCodeUnspsc190501.41115512'),
	'identifierSchemeCodeUnspsc190501.41115513': __('identifierSchemeCodeUnspsc190501.41115513'),
	'identifierSchemeCodeUnspsc190501.41115514': __('identifierSchemeCodeUnspsc190501.41115514'),
	'identifierSchemeCodeUnspsc190501.41115515': __('identifierSchemeCodeUnspsc190501.41115515'),
	'identifierSchemeCodeUnspsc190501.41115600': __('identifierSchemeCodeUnspsc190501.41115600'),
	'identifierSchemeCodeUnspsc190501.41115601': __('identifierSchemeCodeUnspsc190501.41115601'),
	'identifierSchemeCodeUnspsc190501.41115602': __('identifierSchemeCodeUnspsc190501.41115602'),
	'identifierSchemeCodeUnspsc190501.41115603': __('identifierSchemeCodeUnspsc190501.41115603'),
	'identifierSchemeCodeUnspsc190501.41115604': __('identifierSchemeCodeUnspsc190501.41115604'),
	'identifierSchemeCodeUnspsc190501.41115606': __('identifierSchemeCodeUnspsc190501.41115606'),
	'identifierSchemeCodeUnspsc190501.41115607': __('identifierSchemeCodeUnspsc190501.41115607'),
	'identifierSchemeCodeUnspsc190501.41115608': __('identifierSchemeCodeUnspsc190501.41115608'),
	'identifierSchemeCodeUnspsc190501.41115609': __('identifierSchemeCodeUnspsc190501.41115609'),
	'identifierSchemeCodeUnspsc190501.41115610': __('identifierSchemeCodeUnspsc190501.41115610'),
	'identifierSchemeCodeUnspsc190501.41115611': __('identifierSchemeCodeUnspsc190501.41115611'),
	'identifierSchemeCodeUnspsc190501.41115612': __('identifierSchemeCodeUnspsc190501.41115612'),
	'identifierSchemeCodeUnspsc190501.41115613': __('identifierSchemeCodeUnspsc190501.41115613'),
	'identifierSchemeCodeUnspsc190501.41115614': __('identifierSchemeCodeUnspsc190501.41115614'),
	'identifierSchemeCodeUnspsc190501.41115615': __('identifierSchemeCodeUnspsc190501.41115615'),
	'identifierSchemeCodeUnspsc190501.41115700': __('identifierSchemeCodeUnspsc190501.41115700'),
	'identifierSchemeCodeUnspsc190501.41115701': __('identifierSchemeCodeUnspsc190501.41115701'),
	'identifierSchemeCodeUnspsc190501.41115702': __('identifierSchemeCodeUnspsc190501.41115702'),
	'identifierSchemeCodeUnspsc190501.41115703': __('identifierSchemeCodeUnspsc190501.41115703'),
	'identifierSchemeCodeUnspsc190501.41115704': __('identifierSchemeCodeUnspsc190501.41115704'),
	'identifierSchemeCodeUnspsc190501.41115705': __('identifierSchemeCodeUnspsc190501.41115705'),
	'identifierSchemeCodeUnspsc190501.41115706': __('identifierSchemeCodeUnspsc190501.41115706'),
	'identifierSchemeCodeUnspsc190501.41115707': __('identifierSchemeCodeUnspsc190501.41115707'),
	'identifierSchemeCodeUnspsc190501.41115708': __('identifierSchemeCodeUnspsc190501.41115708'),
	'identifierSchemeCodeUnspsc190501.41115709': __('identifierSchemeCodeUnspsc190501.41115709'),
	'identifierSchemeCodeUnspsc190501.41115710': __('identifierSchemeCodeUnspsc190501.41115710'),
	'identifierSchemeCodeUnspsc190501.41115711': __('identifierSchemeCodeUnspsc190501.41115711'),
	'identifierSchemeCodeUnspsc190501.41115712': __('identifierSchemeCodeUnspsc190501.41115712'),
	'identifierSchemeCodeUnspsc190501.41115713': __('identifierSchemeCodeUnspsc190501.41115713'),
	'identifierSchemeCodeUnspsc190501.41115714': __('identifierSchemeCodeUnspsc190501.41115714'),
	'identifierSchemeCodeUnspsc190501.41115715': __('identifierSchemeCodeUnspsc190501.41115715'),
	'identifierSchemeCodeUnspsc190501.41115716': __('identifierSchemeCodeUnspsc190501.41115716'),
	'identifierSchemeCodeUnspsc190501.41115717': __('identifierSchemeCodeUnspsc190501.41115717'),
	'identifierSchemeCodeUnspsc190501.41115718': __('identifierSchemeCodeUnspsc190501.41115718'),
	'identifierSchemeCodeUnspsc190501.41115719': __('identifierSchemeCodeUnspsc190501.41115719'),
	'identifierSchemeCodeUnspsc190501.41115720': __('identifierSchemeCodeUnspsc190501.41115720'),
	'identifierSchemeCodeUnspsc190501.41115721': __('identifierSchemeCodeUnspsc190501.41115721'),
	'identifierSchemeCodeUnspsc190501.41115722': __('identifierSchemeCodeUnspsc190501.41115722'),
	'identifierSchemeCodeUnspsc190501.41115723': __('identifierSchemeCodeUnspsc190501.41115723'),
	'identifierSchemeCodeUnspsc190501.41115724': __('identifierSchemeCodeUnspsc190501.41115724'),
	'identifierSchemeCodeUnspsc190501.41115725': __('identifierSchemeCodeUnspsc190501.41115725'),
	'identifierSchemeCodeUnspsc190501.41115726': __('identifierSchemeCodeUnspsc190501.41115726'),
	'identifierSchemeCodeUnspsc190501.41115727': __('identifierSchemeCodeUnspsc190501.41115727'),
	'identifierSchemeCodeUnspsc190501.41115728': __('identifierSchemeCodeUnspsc190501.41115728'),
	'identifierSchemeCodeUnspsc190501.41115729': __('identifierSchemeCodeUnspsc190501.41115729'),
	'identifierSchemeCodeUnspsc190501.41115730': __('identifierSchemeCodeUnspsc190501.41115730'),
	'identifierSchemeCodeUnspsc190501.41115731': __('identifierSchemeCodeUnspsc190501.41115731'),
	'identifierSchemeCodeUnspsc190501.41115732': __('identifierSchemeCodeUnspsc190501.41115732'),
	'identifierSchemeCodeUnspsc190501.41115733': __('identifierSchemeCodeUnspsc190501.41115733'),
	'identifierSchemeCodeUnspsc190501.41115800': __('identifierSchemeCodeUnspsc190501.41115800'),
	'identifierSchemeCodeUnspsc190501.41115801': __('identifierSchemeCodeUnspsc190501.41115801'),
	'identifierSchemeCodeUnspsc190501.41115802': __('identifierSchemeCodeUnspsc190501.41115802'),
	'identifierSchemeCodeUnspsc190501.41115803': __('identifierSchemeCodeUnspsc190501.41115803'),
	'identifierSchemeCodeUnspsc190501.41115804': __('identifierSchemeCodeUnspsc190501.41115804'),
	'identifierSchemeCodeUnspsc190501.41115805': __('identifierSchemeCodeUnspsc190501.41115805'),
	'identifierSchemeCodeUnspsc190501.41115806': __('identifierSchemeCodeUnspsc190501.41115806'),
	'identifierSchemeCodeUnspsc190501.41115807': __('identifierSchemeCodeUnspsc190501.41115807'),
	'identifierSchemeCodeUnspsc190501.41115808': __('identifierSchemeCodeUnspsc190501.41115808'),
	'identifierSchemeCodeUnspsc190501.41115809': __('identifierSchemeCodeUnspsc190501.41115809'),
	'identifierSchemeCodeUnspsc190501.41115810': __('identifierSchemeCodeUnspsc190501.41115810'),
	'identifierSchemeCodeUnspsc190501.41115811': __('identifierSchemeCodeUnspsc190501.41115811'),
	'identifierSchemeCodeUnspsc190501.41115812': __('identifierSchemeCodeUnspsc190501.41115812'),
	'identifierSchemeCodeUnspsc190501.41115813': __('identifierSchemeCodeUnspsc190501.41115813'),
	'identifierSchemeCodeUnspsc190501.41115814': __('identifierSchemeCodeUnspsc190501.41115814'),
	'identifierSchemeCodeUnspsc190501.41115815': __('identifierSchemeCodeUnspsc190501.41115815'),
	'identifierSchemeCodeUnspsc190501.41115816': __('identifierSchemeCodeUnspsc190501.41115816'),
	'identifierSchemeCodeUnspsc190501.41115817': __('identifierSchemeCodeUnspsc190501.41115817'),
	'identifierSchemeCodeUnspsc190501.41115818': __('identifierSchemeCodeUnspsc190501.41115818'),
	'identifierSchemeCodeUnspsc190501.41115819': __('identifierSchemeCodeUnspsc190501.41115819'),
	'identifierSchemeCodeUnspsc190501.41115820': __('identifierSchemeCodeUnspsc190501.41115820'),
	'identifierSchemeCodeUnspsc190501.41115821': __('identifierSchemeCodeUnspsc190501.41115821'),
	'identifierSchemeCodeUnspsc190501.41115822': __('identifierSchemeCodeUnspsc190501.41115822'),
	'identifierSchemeCodeUnspsc190501.41115823': __('identifierSchemeCodeUnspsc190501.41115823'),
	'identifierSchemeCodeUnspsc190501.41115824': __('identifierSchemeCodeUnspsc190501.41115824'),
	'identifierSchemeCodeUnspsc190501.41115825': __('identifierSchemeCodeUnspsc190501.41115825'),
	'identifierSchemeCodeUnspsc190501.41115826': __('identifierSchemeCodeUnspsc190501.41115826'),
	'identifierSchemeCodeUnspsc190501.41115827': __('identifierSchemeCodeUnspsc190501.41115827'),
	'identifierSchemeCodeUnspsc190501.41115828': __('identifierSchemeCodeUnspsc190501.41115828'),
	'identifierSchemeCodeUnspsc190501.41115829': __('identifierSchemeCodeUnspsc190501.41115829'),
	'identifierSchemeCodeUnspsc190501.41115830': __('identifierSchemeCodeUnspsc190501.41115830'),
	'identifierSchemeCodeUnspsc190501.41115831': __('identifierSchemeCodeUnspsc190501.41115831'),
	'identifierSchemeCodeUnspsc190501.41115832': __('identifierSchemeCodeUnspsc190501.41115832'),
	'identifierSchemeCodeUnspsc190501.41115833': __('identifierSchemeCodeUnspsc190501.41115833'),
	'identifierSchemeCodeUnspsc190501.41115834': __('identifierSchemeCodeUnspsc190501.41115834'),
	'identifierSchemeCodeUnspsc190501.41115835': __('identifierSchemeCodeUnspsc190501.41115835'),
	'identifierSchemeCodeUnspsc190501.41115836': __('identifierSchemeCodeUnspsc190501.41115836'),
	'identifierSchemeCodeUnspsc190501.41115837': __('identifierSchemeCodeUnspsc190501.41115837'),
	'identifierSchemeCodeUnspsc190501.41115838': __('identifierSchemeCodeUnspsc190501.41115838'),
	'identifierSchemeCodeUnspsc190501.41115839': __('identifierSchemeCodeUnspsc190501.41115839'),
	'identifierSchemeCodeUnspsc190501.41115840': __('identifierSchemeCodeUnspsc190501.41115840'),
	'identifierSchemeCodeUnspsc190501.41115841': __('identifierSchemeCodeUnspsc190501.41115841'),
	'identifierSchemeCodeUnspsc190501.41115842': __('identifierSchemeCodeUnspsc190501.41115842'),
	'identifierSchemeCodeUnspsc190501.41115843': __('identifierSchemeCodeUnspsc190501.41115843'),
	'identifierSchemeCodeUnspsc190501.41115844': __('identifierSchemeCodeUnspsc190501.41115844'),
	'identifierSchemeCodeUnspsc190501.41115845': __('identifierSchemeCodeUnspsc190501.41115845'),
	'identifierSchemeCodeUnspsc190501.41115846': __('identifierSchemeCodeUnspsc190501.41115846'),
	'identifierSchemeCodeUnspsc190501.41115847': __('identifierSchemeCodeUnspsc190501.41115847'),
	'identifierSchemeCodeUnspsc190501.41115848': __('identifierSchemeCodeUnspsc190501.41115848'),
	'identifierSchemeCodeUnspsc190501.41115849': __('identifierSchemeCodeUnspsc190501.41115849'),
	'identifierSchemeCodeUnspsc190501.41115850': __('identifierSchemeCodeUnspsc190501.41115850'),
	'identifierSchemeCodeUnspsc190501.41115851': __('identifierSchemeCodeUnspsc190501.41115851'),
	'identifierSchemeCodeUnspsc190501.41115852': __('identifierSchemeCodeUnspsc190501.41115852'),
	'identifierSchemeCodeUnspsc190501.41115853': __('identifierSchemeCodeUnspsc190501.41115853'),
	'identifierSchemeCodeUnspsc190501.41115854': __('identifierSchemeCodeUnspsc190501.41115854'),
	'identifierSchemeCodeUnspsc190501.41115855': __('identifierSchemeCodeUnspsc190501.41115855'),
	'identifierSchemeCodeUnspsc190501.41115856': __('identifierSchemeCodeUnspsc190501.41115856'),
	'identifierSchemeCodeUnspsc190501.41115857': __('identifierSchemeCodeUnspsc190501.41115857'),
	'identifierSchemeCodeUnspsc190501.41115858': __('identifierSchemeCodeUnspsc190501.41115858'),
	'identifierSchemeCodeUnspsc190501.41115859': __('identifierSchemeCodeUnspsc190501.41115859'),
	'identifierSchemeCodeUnspsc190501.41115860': __('identifierSchemeCodeUnspsc190501.41115860'),
	'identifierSchemeCodeUnspsc190501.41115861': __('identifierSchemeCodeUnspsc190501.41115861'),
	'identifierSchemeCodeUnspsc190501.41115862': __('identifierSchemeCodeUnspsc190501.41115862'),
	'identifierSchemeCodeUnspsc190501.41115863': __('identifierSchemeCodeUnspsc190501.41115863'),
	'identifierSchemeCodeUnspsc190501.41115864': __('identifierSchemeCodeUnspsc190501.41115864'),
	'identifierSchemeCodeUnspsc190501.41115865': __('identifierSchemeCodeUnspsc190501.41115865'),
	'identifierSchemeCodeUnspsc190501.41115866': __('identifierSchemeCodeUnspsc190501.41115866'),
	'identifierSchemeCodeUnspsc190501.41115867': __('identifierSchemeCodeUnspsc190501.41115867'),
	'identifierSchemeCodeUnspsc190501.41115868': __('identifierSchemeCodeUnspsc190501.41115868'),
	'identifierSchemeCodeUnspsc190501.41116000': __('identifierSchemeCodeUnspsc190501.41116000'),
	'identifierSchemeCodeUnspsc190501.41116001': __('identifierSchemeCodeUnspsc190501.41116001'),
	'identifierSchemeCodeUnspsc190501.41116002': __('identifierSchemeCodeUnspsc190501.41116002'),
	'identifierSchemeCodeUnspsc190501.41116003': __('identifierSchemeCodeUnspsc190501.41116003'),
	'identifierSchemeCodeUnspsc190501.41116004': __('identifierSchemeCodeUnspsc190501.41116004'),
	'identifierSchemeCodeUnspsc190501.41116005': __('identifierSchemeCodeUnspsc190501.41116005'),
	'identifierSchemeCodeUnspsc190501.41116006': __('identifierSchemeCodeUnspsc190501.41116006'),
	'identifierSchemeCodeUnspsc190501.41116007': __('identifierSchemeCodeUnspsc190501.41116007'),
	'identifierSchemeCodeUnspsc190501.41116008': __('identifierSchemeCodeUnspsc190501.41116008'),
	'identifierSchemeCodeUnspsc190501.41116009': __('identifierSchemeCodeUnspsc190501.41116009'),
	'identifierSchemeCodeUnspsc190501.41116010': __('identifierSchemeCodeUnspsc190501.41116010'),
	'identifierSchemeCodeUnspsc190501.41116011': __('identifierSchemeCodeUnspsc190501.41116011'),
	'identifierSchemeCodeUnspsc190501.41116012': __('identifierSchemeCodeUnspsc190501.41116012'),
	'identifierSchemeCodeUnspsc190501.41116013': __('identifierSchemeCodeUnspsc190501.41116013'),
	'identifierSchemeCodeUnspsc190501.41116014': __('identifierSchemeCodeUnspsc190501.41116014'),
	'identifierSchemeCodeUnspsc190501.41116015': __('identifierSchemeCodeUnspsc190501.41116015'),
	'identifierSchemeCodeUnspsc190501.41116016': __('identifierSchemeCodeUnspsc190501.41116016'),
	'identifierSchemeCodeUnspsc190501.41116017': __('identifierSchemeCodeUnspsc190501.41116017'),
	'identifierSchemeCodeUnspsc190501.41116018': __('identifierSchemeCodeUnspsc190501.41116018'),
	'identifierSchemeCodeUnspsc190501.41116019': __('identifierSchemeCodeUnspsc190501.41116019'),
	'identifierSchemeCodeUnspsc190501.41116020': __('identifierSchemeCodeUnspsc190501.41116020'),
	'identifierSchemeCodeUnspsc190501.41116021': __('identifierSchemeCodeUnspsc190501.41116021'),
	'identifierSchemeCodeUnspsc190501.41116022': __('identifierSchemeCodeUnspsc190501.41116022'),
	'identifierSchemeCodeUnspsc190501.41116023': __('identifierSchemeCodeUnspsc190501.41116023'),
	'identifierSchemeCodeUnspsc190501.41116024': __('identifierSchemeCodeUnspsc190501.41116024'),
	'identifierSchemeCodeUnspsc190501.41116025': __('identifierSchemeCodeUnspsc190501.41116025'),
	'identifierSchemeCodeUnspsc190501.41116026': __('identifierSchemeCodeUnspsc190501.41116026'),
	'identifierSchemeCodeUnspsc190501.41116027': __('identifierSchemeCodeUnspsc190501.41116027'),
	'identifierSchemeCodeUnspsc190501.41116100': __('identifierSchemeCodeUnspsc190501.41116100'),
	'identifierSchemeCodeUnspsc190501.41116101': __('identifierSchemeCodeUnspsc190501.41116101'),
	'identifierSchemeCodeUnspsc190501.41116102': __('identifierSchemeCodeUnspsc190501.41116102'),
	'identifierSchemeCodeUnspsc190501.41116103': __('identifierSchemeCodeUnspsc190501.41116103'),
	'identifierSchemeCodeUnspsc190501.41116104': __('identifierSchemeCodeUnspsc190501.41116104'),
	'identifierSchemeCodeUnspsc190501.41116105': __('identifierSchemeCodeUnspsc190501.41116105'),
	'identifierSchemeCodeUnspsc190501.41116106': __('identifierSchemeCodeUnspsc190501.41116106'),
	'identifierSchemeCodeUnspsc190501.41116107': __('identifierSchemeCodeUnspsc190501.41116107'),
	'identifierSchemeCodeUnspsc190501.41116108': __('identifierSchemeCodeUnspsc190501.41116108'),
	'identifierSchemeCodeUnspsc190501.41116109': __('identifierSchemeCodeUnspsc190501.41116109'),
	'identifierSchemeCodeUnspsc190501.41116110': __('identifierSchemeCodeUnspsc190501.41116110'),
	'identifierSchemeCodeUnspsc190501.41116111': __('identifierSchemeCodeUnspsc190501.41116111'),
	'identifierSchemeCodeUnspsc190501.41116112': __('identifierSchemeCodeUnspsc190501.41116112'),
	'identifierSchemeCodeUnspsc190501.41116113': __('identifierSchemeCodeUnspsc190501.41116113'),
	'identifierSchemeCodeUnspsc190501.41116116': __('identifierSchemeCodeUnspsc190501.41116116'),
	'identifierSchemeCodeUnspsc190501.41116117': __('identifierSchemeCodeUnspsc190501.41116117'),
	'identifierSchemeCodeUnspsc190501.41116118': __('identifierSchemeCodeUnspsc190501.41116118'),
	'identifierSchemeCodeUnspsc190501.41116119': __('identifierSchemeCodeUnspsc190501.41116119'),
	'identifierSchemeCodeUnspsc190501.41116120': __('identifierSchemeCodeUnspsc190501.41116120'),
	'identifierSchemeCodeUnspsc190501.41116121': __('identifierSchemeCodeUnspsc190501.41116121'),
	'identifierSchemeCodeUnspsc190501.41116122': __('identifierSchemeCodeUnspsc190501.41116122'),
	'identifierSchemeCodeUnspsc190501.41116123': __('identifierSchemeCodeUnspsc190501.41116123'),
	'identifierSchemeCodeUnspsc190501.41116124': __('identifierSchemeCodeUnspsc190501.41116124'),
	'identifierSchemeCodeUnspsc190501.41116125': __('identifierSchemeCodeUnspsc190501.41116125'),
	'identifierSchemeCodeUnspsc190501.41116126': __('identifierSchemeCodeUnspsc190501.41116126'),
	'identifierSchemeCodeUnspsc190501.41116127': __('identifierSchemeCodeUnspsc190501.41116127'),
	'identifierSchemeCodeUnspsc190501.41116128': __('identifierSchemeCodeUnspsc190501.41116128'),
	'identifierSchemeCodeUnspsc190501.41116129': __('identifierSchemeCodeUnspsc190501.41116129'),
	'identifierSchemeCodeUnspsc190501.41116130': __('identifierSchemeCodeUnspsc190501.41116130'),
	'identifierSchemeCodeUnspsc190501.41116131': __('identifierSchemeCodeUnspsc190501.41116131'),
	'identifierSchemeCodeUnspsc190501.41116132': __('identifierSchemeCodeUnspsc190501.41116132'),
	'identifierSchemeCodeUnspsc190501.41116133': __('identifierSchemeCodeUnspsc190501.41116133'),
	'identifierSchemeCodeUnspsc190501.41116134': __('identifierSchemeCodeUnspsc190501.41116134'),
	'identifierSchemeCodeUnspsc190501.41116135': __('identifierSchemeCodeUnspsc190501.41116135'),
	'identifierSchemeCodeUnspsc190501.41116136': __('identifierSchemeCodeUnspsc190501.41116136'),
	'identifierSchemeCodeUnspsc190501.41116137': __('identifierSchemeCodeUnspsc190501.41116137'),
	'identifierSchemeCodeUnspsc190501.41116138': __('identifierSchemeCodeUnspsc190501.41116138'),
	'identifierSchemeCodeUnspsc190501.41116139': __('identifierSchemeCodeUnspsc190501.41116139'),
	'identifierSchemeCodeUnspsc190501.41116140': __('identifierSchemeCodeUnspsc190501.41116140'),
	'identifierSchemeCodeUnspsc190501.41116141': __('identifierSchemeCodeUnspsc190501.41116141'),
	'identifierSchemeCodeUnspsc190501.41116142': __('identifierSchemeCodeUnspsc190501.41116142'),
	'identifierSchemeCodeUnspsc190501.41116143': __('identifierSchemeCodeUnspsc190501.41116143'),
	'identifierSchemeCodeUnspsc190501.41116144': __('identifierSchemeCodeUnspsc190501.41116144'),
	'identifierSchemeCodeUnspsc190501.41116145': __('identifierSchemeCodeUnspsc190501.41116145'),
	'identifierSchemeCodeUnspsc190501.41116146': __('identifierSchemeCodeUnspsc190501.41116146'),
	'identifierSchemeCodeUnspsc190501.41116147': __('identifierSchemeCodeUnspsc190501.41116147'),
	'identifierSchemeCodeUnspsc190501.41116148': __('identifierSchemeCodeUnspsc190501.41116148'),
	'identifierSchemeCodeUnspsc190501.41116149': __('identifierSchemeCodeUnspsc190501.41116149'),
	'identifierSchemeCodeUnspsc190501.41116150': __('identifierSchemeCodeUnspsc190501.41116150'),
	'identifierSchemeCodeUnspsc190501.41116151': __('identifierSchemeCodeUnspsc190501.41116151'),
	'identifierSchemeCodeUnspsc190501.41116152': __('identifierSchemeCodeUnspsc190501.41116152'),
	'identifierSchemeCodeUnspsc190501.41116153': __('identifierSchemeCodeUnspsc190501.41116153'),
	'identifierSchemeCodeUnspsc190501.41116154': __('identifierSchemeCodeUnspsc190501.41116154'),
	'identifierSchemeCodeUnspsc190501.41116155': __('identifierSchemeCodeUnspsc190501.41116155'),
	'identifierSchemeCodeUnspsc190501.41116156': __('identifierSchemeCodeUnspsc190501.41116156'),
	'identifierSchemeCodeUnspsc190501.41116157': __('identifierSchemeCodeUnspsc190501.41116157'),
	'identifierSchemeCodeUnspsc190501.41116158': __('identifierSchemeCodeUnspsc190501.41116158'),
	'identifierSchemeCodeUnspsc190501.41116159': __('identifierSchemeCodeUnspsc190501.41116159'),
	'identifierSchemeCodeUnspsc190501.41116160': __('identifierSchemeCodeUnspsc190501.41116160'),
	'identifierSchemeCodeUnspsc190501.41116161': __('identifierSchemeCodeUnspsc190501.41116161'),
	'identifierSchemeCodeUnspsc190501.41116162': __('identifierSchemeCodeUnspsc190501.41116162'),
	'identifierSchemeCodeUnspsc190501.41116163': __('identifierSchemeCodeUnspsc190501.41116163'),
	'identifierSchemeCodeUnspsc190501.41116164': __('identifierSchemeCodeUnspsc190501.41116164'),
	'identifierSchemeCodeUnspsc190501.41116165': __('identifierSchemeCodeUnspsc190501.41116165'),
	'identifierSchemeCodeUnspsc190501.41116166': __('identifierSchemeCodeUnspsc190501.41116166'),
	'identifierSchemeCodeUnspsc190501.41116167': __('identifierSchemeCodeUnspsc190501.41116167'),
	'identifierSchemeCodeUnspsc190501.41116168': __('identifierSchemeCodeUnspsc190501.41116168'),
	'identifierSchemeCodeUnspsc190501.41116200': __('identifierSchemeCodeUnspsc190501.41116200'),
	'identifierSchemeCodeUnspsc190501.41116201': __('identifierSchemeCodeUnspsc190501.41116201'),
	'identifierSchemeCodeUnspsc190501.41116202': __('identifierSchemeCodeUnspsc190501.41116202'),
	'identifierSchemeCodeUnspsc190501.41116203': __('identifierSchemeCodeUnspsc190501.41116203'),
	'identifierSchemeCodeUnspsc190501.41116205': __('identifierSchemeCodeUnspsc190501.41116205'),
	'identifierSchemeCodeUnspsc190501.41116206': __('identifierSchemeCodeUnspsc190501.41116206'),
	'identifierSchemeCodeUnspsc190501.41116207': __('identifierSchemeCodeUnspsc190501.41116207'),
	'identifierSchemeCodeUnspsc190501.41116208': __('identifierSchemeCodeUnspsc190501.41116208'),
	'identifierSchemeCodeUnspsc190501.41116209': __('identifierSchemeCodeUnspsc190501.41116209'),
	'identifierSchemeCodeUnspsc190501.41116210': __('identifierSchemeCodeUnspsc190501.41116210'),
	'identifierSchemeCodeUnspsc190501.41116211': __('identifierSchemeCodeUnspsc190501.41116211'),
	'identifierSchemeCodeUnspsc190501.41116212': __('identifierSchemeCodeUnspsc190501.41116212'),
	'identifierSchemeCodeUnspsc190501.41116213': __('identifierSchemeCodeUnspsc190501.41116213'),
	'identifierSchemeCodeUnspsc190501.41116214': __('identifierSchemeCodeUnspsc190501.41116214'),
	'identifierSchemeCodeUnspsc190501.41116215': __('identifierSchemeCodeUnspsc190501.41116215'),
	'identifierSchemeCodeUnspsc190501.41116216': __('identifierSchemeCodeUnspsc190501.41116216'),
	'identifierSchemeCodeUnspsc190501.41116217': __('identifierSchemeCodeUnspsc190501.41116217'),
	'identifierSchemeCodeUnspsc190501.41116218': __('identifierSchemeCodeUnspsc190501.41116218'),
	'identifierSchemeCodeUnspsc190501.41116219': __('identifierSchemeCodeUnspsc190501.41116219'),
	'identifierSchemeCodeUnspsc190501.41116220': __('identifierSchemeCodeUnspsc190501.41116220'),
	'identifierSchemeCodeUnspsc190501.41116221': __('identifierSchemeCodeUnspsc190501.41116221'),
	'identifierSchemeCodeUnspsc190501.41116222': __('identifierSchemeCodeUnspsc190501.41116222'),
	'identifierSchemeCodeUnspsc190501.41116223': __('identifierSchemeCodeUnspsc190501.41116223'),
	'identifierSchemeCodeUnspsc190501.41116224': __('identifierSchemeCodeUnspsc190501.41116224'),
	'identifierSchemeCodeUnspsc190501.41116300': __('identifierSchemeCodeUnspsc190501.41116300'),
	'identifierSchemeCodeUnspsc190501.41116301': __('identifierSchemeCodeUnspsc190501.41116301'),
	'identifierSchemeCodeUnspsc190501.41116302': __('identifierSchemeCodeUnspsc190501.41116302'),
	'identifierSchemeCodeUnspsc190501.41116303': __('identifierSchemeCodeUnspsc190501.41116303'),
	'identifierSchemeCodeUnspsc190501.41116304': __('identifierSchemeCodeUnspsc190501.41116304'),
	'identifierSchemeCodeUnspsc190501.41116400': __('identifierSchemeCodeUnspsc190501.41116400'),
	'identifierSchemeCodeUnspsc190501.41116401': __('identifierSchemeCodeUnspsc190501.41116401'),
	'identifierSchemeCodeUnspsc190501.41116500': __('identifierSchemeCodeUnspsc190501.41116500'),
	'identifierSchemeCodeUnspsc190501.41116501': __('identifierSchemeCodeUnspsc190501.41116501'),
	'identifierSchemeCodeUnspsc190501.41116502': __('identifierSchemeCodeUnspsc190501.41116502'),
	'identifierSchemeCodeUnspsc190501.41120000': __('identifierSchemeCodeUnspsc190501.41120000'),
	'identifierSchemeCodeUnspsc190501.41121500': __('identifierSchemeCodeUnspsc190501.41121500'),
	'identifierSchemeCodeUnspsc190501.41121501': __('identifierSchemeCodeUnspsc190501.41121501'),
	'identifierSchemeCodeUnspsc190501.41121502': __('identifierSchemeCodeUnspsc190501.41121502'),
	'identifierSchemeCodeUnspsc190501.41121503': __('identifierSchemeCodeUnspsc190501.41121503'),
	'identifierSchemeCodeUnspsc190501.41121504': __('identifierSchemeCodeUnspsc190501.41121504'),
	'identifierSchemeCodeUnspsc190501.41121505': __('identifierSchemeCodeUnspsc190501.41121505'),
	'identifierSchemeCodeUnspsc190501.41121506': __('identifierSchemeCodeUnspsc190501.41121506'),
	'identifierSchemeCodeUnspsc190501.41121507': __('identifierSchemeCodeUnspsc190501.41121507'),
	'identifierSchemeCodeUnspsc190501.41121508': __('identifierSchemeCodeUnspsc190501.41121508'),
	'identifierSchemeCodeUnspsc190501.41121509': __('identifierSchemeCodeUnspsc190501.41121509'),
	'identifierSchemeCodeUnspsc190501.41121510': __('identifierSchemeCodeUnspsc190501.41121510'),
	'identifierSchemeCodeUnspsc190501.41121511': __('identifierSchemeCodeUnspsc190501.41121511'),
	'identifierSchemeCodeUnspsc190501.41121513': __('identifierSchemeCodeUnspsc190501.41121513'),
	'identifierSchemeCodeUnspsc190501.41121514': __('identifierSchemeCodeUnspsc190501.41121514'),
	'identifierSchemeCodeUnspsc190501.41121515': __('identifierSchemeCodeUnspsc190501.41121515'),
	'identifierSchemeCodeUnspsc190501.41121516': __('identifierSchemeCodeUnspsc190501.41121516'),
	'identifierSchemeCodeUnspsc190501.41121517': __('identifierSchemeCodeUnspsc190501.41121517'),
	'identifierSchemeCodeUnspsc190501.41121518': __('identifierSchemeCodeUnspsc190501.41121518'),
	'identifierSchemeCodeUnspsc190501.41121519': __('identifierSchemeCodeUnspsc190501.41121519'),
	'identifierSchemeCodeUnspsc190501.41121520': __('identifierSchemeCodeUnspsc190501.41121520'),
	'identifierSchemeCodeUnspsc190501.41121521': __('identifierSchemeCodeUnspsc190501.41121521'),
	'identifierSchemeCodeUnspsc190501.41121522': __('identifierSchemeCodeUnspsc190501.41121522'),
	'identifierSchemeCodeUnspsc190501.41121600': __('identifierSchemeCodeUnspsc190501.41121600'),
	'identifierSchemeCodeUnspsc190501.41121601': __('identifierSchemeCodeUnspsc190501.41121601'),
	'identifierSchemeCodeUnspsc190501.41121602': __('identifierSchemeCodeUnspsc190501.41121602'),
	'identifierSchemeCodeUnspsc190501.41121603': __('identifierSchemeCodeUnspsc190501.41121603'),
	'identifierSchemeCodeUnspsc190501.41121604': __('identifierSchemeCodeUnspsc190501.41121604'),
	'identifierSchemeCodeUnspsc190501.41121605': __('identifierSchemeCodeUnspsc190501.41121605'),
	'identifierSchemeCodeUnspsc190501.41121606': __('identifierSchemeCodeUnspsc190501.41121606'),
	'identifierSchemeCodeUnspsc190501.41121607': __('identifierSchemeCodeUnspsc190501.41121607'),
	'identifierSchemeCodeUnspsc190501.41121608': __('identifierSchemeCodeUnspsc190501.41121608'),
	'identifierSchemeCodeUnspsc190501.41121609': __('identifierSchemeCodeUnspsc190501.41121609'),
	'identifierSchemeCodeUnspsc190501.41121610': __('identifierSchemeCodeUnspsc190501.41121610'),
	'identifierSchemeCodeUnspsc190501.41121700': __('identifierSchemeCodeUnspsc190501.41121700'),
	'identifierSchemeCodeUnspsc190501.41121701': __('identifierSchemeCodeUnspsc190501.41121701'),
	'identifierSchemeCodeUnspsc190501.41121702': __('identifierSchemeCodeUnspsc190501.41121702'),
	'identifierSchemeCodeUnspsc190501.41121703': __('identifierSchemeCodeUnspsc190501.41121703'),
	'identifierSchemeCodeUnspsc190501.41121704': __('identifierSchemeCodeUnspsc190501.41121704'),
	'identifierSchemeCodeUnspsc190501.41121705': __('identifierSchemeCodeUnspsc190501.41121705'),
	'identifierSchemeCodeUnspsc190501.41121706': __('identifierSchemeCodeUnspsc190501.41121706'),
	'identifierSchemeCodeUnspsc190501.41121707': __('identifierSchemeCodeUnspsc190501.41121707'),
	'identifierSchemeCodeUnspsc190501.41121708': __('identifierSchemeCodeUnspsc190501.41121708'),
	'identifierSchemeCodeUnspsc190501.41121709': __('identifierSchemeCodeUnspsc190501.41121709'),
	'identifierSchemeCodeUnspsc190501.41121710': __('identifierSchemeCodeUnspsc190501.41121710'),
	'identifierSchemeCodeUnspsc190501.41121711': __('identifierSchemeCodeUnspsc190501.41121711'),
	'identifierSchemeCodeUnspsc190501.41121712': __('identifierSchemeCodeUnspsc190501.41121712'),
	'identifierSchemeCodeUnspsc190501.41121713': __('identifierSchemeCodeUnspsc190501.41121713'),
	'identifierSchemeCodeUnspsc190501.41121714': __('identifierSchemeCodeUnspsc190501.41121714'),
	'identifierSchemeCodeUnspsc190501.41121715': __('identifierSchemeCodeUnspsc190501.41121715'),
	'identifierSchemeCodeUnspsc190501.41121800': __('identifierSchemeCodeUnspsc190501.41121800'),
	'identifierSchemeCodeUnspsc190501.41121801': __('identifierSchemeCodeUnspsc190501.41121801'),
	'identifierSchemeCodeUnspsc190501.41121802': __('identifierSchemeCodeUnspsc190501.41121802'),
	'identifierSchemeCodeUnspsc190501.41121803': __('identifierSchemeCodeUnspsc190501.41121803'),
	'identifierSchemeCodeUnspsc190501.41121804': __('identifierSchemeCodeUnspsc190501.41121804'),
	'identifierSchemeCodeUnspsc190501.41121805': __('identifierSchemeCodeUnspsc190501.41121805'),
	'identifierSchemeCodeUnspsc190501.41121806': __('identifierSchemeCodeUnspsc190501.41121806'),
	'identifierSchemeCodeUnspsc190501.41121807': __('identifierSchemeCodeUnspsc190501.41121807'),
	'identifierSchemeCodeUnspsc190501.41121808': __('identifierSchemeCodeUnspsc190501.41121808'),
	'identifierSchemeCodeUnspsc190501.41121809': __('identifierSchemeCodeUnspsc190501.41121809'),
	'identifierSchemeCodeUnspsc190501.41121810': __('identifierSchemeCodeUnspsc190501.41121810'),
	'identifierSchemeCodeUnspsc190501.41121811': __('identifierSchemeCodeUnspsc190501.41121811'),
	'identifierSchemeCodeUnspsc190501.41121812': __('identifierSchemeCodeUnspsc190501.41121812'),
	'identifierSchemeCodeUnspsc190501.41121813': __('identifierSchemeCodeUnspsc190501.41121813'),
	'identifierSchemeCodeUnspsc190501.41121814': __('identifierSchemeCodeUnspsc190501.41121814'),
	'identifierSchemeCodeUnspsc190501.41121815': __('identifierSchemeCodeUnspsc190501.41121815'),
	'identifierSchemeCodeUnspsc190501.41121816': __('identifierSchemeCodeUnspsc190501.41121816'),
	'identifierSchemeCodeUnspsc190501.41121817': __('identifierSchemeCodeUnspsc190501.41121817'),
	'identifierSchemeCodeUnspsc190501.41121818': __('identifierSchemeCodeUnspsc190501.41121818'),
	'identifierSchemeCodeUnspsc190501.41121819': __('identifierSchemeCodeUnspsc190501.41121819'),
	'identifierSchemeCodeUnspsc190501.41121820': __('identifierSchemeCodeUnspsc190501.41121820'),
	'identifierSchemeCodeUnspsc190501.41121821': __('identifierSchemeCodeUnspsc190501.41121821'),
	'identifierSchemeCodeUnspsc190501.41122000': __('identifierSchemeCodeUnspsc190501.41122000'),
	'identifierSchemeCodeUnspsc190501.41122001': __('identifierSchemeCodeUnspsc190501.41122001'),
	'identifierSchemeCodeUnspsc190501.41122002': __('identifierSchemeCodeUnspsc190501.41122002'),
	'identifierSchemeCodeUnspsc190501.41122003': __('identifierSchemeCodeUnspsc190501.41122003'),
	'identifierSchemeCodeUnspsc190501.41122004': __('identifierSchemeCodeUnspsc190501.41122004'),
	'identifierSchemeCodeUnspsc190501.41122100': __('identifierSchemeCodeUnspsc190501.41122100'),
	'identifierSchemeCodeUnspsc190501.41122101': __('identifierSchemeCodeUnspsc190501.41122101'),
	'identifierSchemeCodeUnspsc190501.41122102': __('identifierSchemeCodeUnspsc190501.41122102'),
	'identifierSchemeCodeUnspsc190501.41122103': __('identifierSchemeCodeUnspsc190501.41122103'),
	'identifierSchemeCodeUnspsc190501.41122104': __('identifierSchemeCodeUnspsc190501.41122104'),
	'identifierSchemeCodeUnspsc190501.41122105': __('identifierSchemeCodeUnspsc190501.41122105'),
	'identifierSchemeCodeUnspsc190501.41122106': __('identifierSchemeCodeUnspsc190501.41122106'),
	'identifierSchemeCodeUnspsc190501.41122107': __('identifierSchemeCodeUnspsc190501.41122107'),
	'identifierSchemeCodeUnspsc190501.41122108': __('identifierSchemeCodeUnspsc190501.41122108'),
	'identifierSchemeCodeUnspsc190501.41122109': __('identifierSchemeCodeUnspsc190501.41122109'),
	'identifierSchemeCodeUnspsc190501.41122110': __('identifierSchemeCodeUnspsc190501.41122110'),
	'identifierSchemeCodeUnspsc190501.41122111': __('identifierSchemeCodeUnspsc190501.41122111'),
	'identifierSchemeCodeUnspsc190501.41122112': __('identifierSchemeCodeUnspsc190501.41122112'),
	'identifierSchemeCodeUnspsc190501.41122113': __('identifierSchemeCodeUnspsc190501.41122113'),
	'identifierSchemeCodeUnspsc190501.41122114': __('identifierSchemeCodeUnspsc190501.41122114'),
	'identifierSchemeCodeUnspsc190501.41122200': __('identifierSchemeCodeUnspsc190501.41122200'),
	'identifierSchemeCodeUnspsc190501.41122201': __('identifierSchemeCodeUnspsc190501.41122201'),
	'identifierSchemeCodeUnspsc190501.41122202': __('identifierSchemeCodeUnspsc190501.41122202'),
	'identifierSchemeCodeUnspsc190501.41122203': __('identifierSchemeCodeUnspsc190501.41122203'),
	'identifierSchemeCodeUnspsc190501.41122300': __('identifierSchemeCodeUnspsc190501.41122300'),
	'identifierSchemeCodeUnspsc190501.41122301': __('identifierSchemeCodeUnspsc190501.41122301'),
	'identifierSchemeCodeUnspsc190501.41122400': __('identifierSchemeCodeUnspsc190501.41122400'),
	'identifierSchemeCodeUnspsc190501.41122401': __('identifierSchemeCodeUnspsc190501.41122401'),
	'identifierSchemeCodeUnspsc190501.41122402': __('identifierSchemeCodeUnspsc190501.41122402'),
	'identifierSchemeCodeUnspsc190501.41122403': __('identifierSchemeCodeUnspsc190501.41122403'),
	'identifierSchemeCodeUnspsc190501.41122404': __('identifierSchemeCodeUnspsc190501.41122404'),
	'identifierSchemeCodeUnspsc190501.41122405': __('identifierSchemeCodeUnspsc190501.41122405'),
	'identifierSchemeCodeUnspsc190501.41122406': __('identifierSchemeCodeUnspsc190501.41122406'),
	'identifierSchemeCodeUnspsc190501.41122407': __('identifierSchemeCodeUnspsc190501.41122407'),
	'identifierSchemeCodeUnspsc190501.41122408': __('identifierSchemeCodeUnspsc190501.41122408'),
	'identifierSchemeCodeUnspsc190501.41122409': __('identifierSchemeCodeUnspsc190501.41122409'),
	'identifierSchemeCodeUnspsc190501.41122410': __('identifierSchemeCodeUnspsc190501.41122410'),
	'identifierSchemeCodeUnspsc190501.41122411': __('identifierSchemeCodeUnspsc190501.41122411'),
	'identifierSchemeCodeUnspsc190501.41122412': __('identifierSchemeCodeUnspsc190501.41122412'),
	'identifierSchemeCodeUnspsc190501.41122413': __('identifierSchemeCodeUnspsc190501.41122413'),
	'identifierSchemeCodeUnspsc190501.41122414': __('identifierSchemeCodeUnspsc190501.41122414'),
	'identifierSchemeCodeUnspsc190501.41122415': __('identifierSchemeCodeUnspsc190501.41122415'),
	'identifierSchemeCodeUnspsc190501.41122416': __('identifierSchemeCodeUnspsc190501.41122416'),
	'identifierSchemeCodeUnspsc190501.41122417': __('identifierSchemeCodeUnspsc190501.41122417'),
	'identifierSchemeCodeUnspsc190501.41122418': __('identifierSchemeCodeUnspsc190501.41122418'),
	'identifierSchemeCodeUnspsc190501.41122419': __('identifierSchemeCodeUnspsc190501.41122419'),
	'identifierSchemeCodeUnspsc190501.41122420': __('identifierSchemeCodeUnspsc190501.41122420'),
	'identifierSchemeCodeUnspsc190501.41122421': __('identifierSchemeCodeUnspsc190501.41122421'),
	'identifierSchemeCodeUnspsc190501.41122500': __('identifierSchemeCodeUnspsc190501.41122500'),
	'identifierSchemeCodeUnspsc190501.41122501': __('identifierSchemeCodeUnspsc190501.41122501'),
	'identifierSchemeCodeUnspsc190501.41122502': __('identifierSchemeCodeUnspsc190501.41122502'),
	'identifierSchemeCodeUnspsc190501.41122503': __('identifierSchemeCodeUnspsc190501.41122503'),
	'identifierSchemeCodeUnspsc190501.41122600': __('identifierSchemeCodeUnspsc190501.41122600'),
	'identifierSchemeCodeUnspsc190501.41122601': __('identifierSchemeCodeUnspsc190501.41122601'),
	'identifierSchemeCodeUnspsc190501.41122602': __('identifierSchemeCodeUnspsc190501.41122602'),
	'identifierSchemeCodeUnspsc190501.41122603': __('identifierSchemeCodeUnspsc190501.41122603'),
	'identifierSchemeCodeUnspsc190501.41122604': __('identifierSchemeCodeUnspsc190501.41122604'),
	'identifierSchemeCodeUnspsc190501.41122605': __('identifierSchemeCodeUnspsc190501.41122605'),
	'identifierSchemeCodeUnspsc190501.41122606': __('identifierSchemeCodeUnspsc190501.41122606'),
	'identifierSchemeCodeUnspsc190501.41122607': __('identifierSchemeCodeUnspsc190501.41122607'),
	'identifierSchemeCodeUnspsc190501.41122608': __('identifierSchemeCodeUnspsc190501.41122608'),
	'identifierSchemeCodeUnspsc190501.41122700': __('identifierSchemeCodeUnspsc190501.41122700'),
	'identifierSchemeCodeUnspsc190501.41122701': __('identifierSchemeCodeUnspsc190501.41122701'),
	'identifierSchemeCodeUnspsc190501.41122702': __('identifierSchemeCodeUnspsc190501.41122702'),
	'identifierSchemeCodeUnspsc190501.41122703': __('identifierSchemeCodeUnspsc190501.41122703'),
	'identifierSchemeCodeUnspsc190501.41122704': __('identifierSchemeCodeUnspsc190501.41122704'),
	'identifierSchemeCodeUnspsc190501.41122800': __('identifierSchemeCodeUnspsc190501.41122800'),
	'identifierSchemeCodeUnspsc190501.41122801': __('identifierSchemeCodeUnspsc190501.41122801'),
	'identifierSchemeCodeUnspsc190501.41122802': __('identifierSchemeCodeUnspsc190501.41122802'),
	'identifierSchemeCodeUnspsc190501.41122803': __('identifierSchemeCodeUnspsc190501.41122803'),
	'identifierSchemeCodeUnspsc190501.41122804': __('identifierSchemeCodeUnspsc190501.41122804'),
	'identifierSchemeCodeUnspsc190501.41122805': __('identifierSchemeCodeUnspsc190501.41122805'),
	'identifierSchemeCodeUnspsc190501.41122806': __('identifierSchemeCodeUnspsc190501.41122806'),
	'identifierSchemeCodeUnspsc190501.41122807': __('identifierSchemeCodeUnspsc190501.41122807'),
	'identifierSchemeCodeUnspsc190501.41122808': __('identifierSchemeCodeUnspsc190501.41122808'),
	'identifierSchemeCodeUnspsc190501.41122809': __('identifierSchemeCodeUnspsc190501.41122809'),
	'identifierSchemeCodeUnspsc190501.41122810': __('identifierSchemeCodeUnspsc190501.41122810'),
	'identifierSchemeCodeUnspsc190501.41122811': __('identifierSchemeCodeUnspsc190501.41122811'),
	'identifierSchemeCodeUnspsc190501.41123000': __('identifierSchemeCodeUnspsc190501.41123000'),
	'identifierSchemeCodeUnspsc190501.41123001': __('identifierSchemeCodeUnspsc190501.41123001'),
	'identifierSchemeCodeUnspsc190501.41123002': __('identifierSchemeCodeUnspsc190501.41123002'),
	'identifierSchemeCodeUnspsc190501.41123003': __('identifierSchemeCodeUnspsc190501.41123003'),
	'identifierSchemeCodeUnspsc190501.41123004': __('identifierSchemeCodeUnspsc190501.41123004'),
	'identifierSchemeCodeUnspsc190501.41123005': __('identifierSchemeCodeUnspsc190501.41123005'),
	'identifierSchemeCodeUnspsc190501.41123006': __('identifierSchemeCodeUnspsc190501.41123006'),
	'identifierSchemeCodeUnspsc190501.41123100': __('identifierSchemeCodeUnspsc190501.41123100'),
	'identifierSchemeCodeUnspsc190501.41123101': __('identifierSchemeCodeUnspsc190501.41123101'),
	'identifierSchemeCodeUnspsc190501.41123102': __('identifierSchemeCodeUnspsc190501.41123102'),
	'identifierSchemeCodeUnspsc190501.41123103': __('identifierSchemeCodeUnspsc190501.41123103'),
	'identifierSchemeCodeUnspsc190501.41123104': __('identifierSchemeCodeUnspsc190501.41123104'),
	'identifierSchemeCodeUnspsc190501.41123105': __('identifierSchemeCodeUnspsc190501.41123105'),
	'identifierSchemeCodeUnspsc190501.41123106': __('identifierSchemeCodeUnspsc190501.41123106'),
	'identifierSchemeCodeUnspsc190501.41123200': __('identifierSchemeCodeUnspsc190501.41123200'),
	'identifierSchemeCodeUnspsc190501.41123201': __('identifierSchemeCodeUnspsc190501.41123201'),
	'identifierSchemeCodeUnspsc190501.41123202': __('identifierSchemeCodeUnspsc190501.41123202'),
	'identifierSchemeCodeUnspsc190501.41123203': __('identifierSchemeCodeUnspsc190501.41123203'),
	'identifierSchemeCodeUnspsc190501.41123204': __('identifierSchemeCodeUnspsc190501.41123204'),
	'identifierSchemeCodeUnspsc190501.41123300': __('identifierSchemeCodeUnspsc190501.41123300'),
	'identifierSchemeCodeUnspsc190501.41123302': __('identifierSchemeCodeUnspsc190501.41123302'),
	'identifierSchemeCodeUnspsc190501.41123303': __('identifierSchemeCodeUnspsc190501.41123303'),
	'identifierSchemeCodeUnspsc190501.41123304': __('identifierSchemeCodeUnspsc190501.41123304'),
	'identifierSchemeCodeUnspsc190501.41123305': __('identifierSchemeCodeUnspsc190501.41123305'),
	'identifierSchemeCodeUnspsc190501.41123306': __('identifierSchemeCodeUnspsc190501.41123306'),
	'identifierSchemeCodeUnspsc190501.41123307': __('identifierSchemeCodeUnspsc190501.41123307'),
	'identifierSchemeCodeUnspsc190501.41123308': __('identifierSchemeCodeUnspsc190501.41123308'),
	'identifierSchemeCodeUnspsc190501.41123309': __('identifierSchemeCodeUnspsc190501.41123309'),
	'identifierSchemeCodeUnspsc190501.41123310': __('identifierSchemeCodeUnspsc190501.41123310'),
	'identifierSchemeCodeUnspsc190501.41123311': __('identifierSchemeCodeUnspsc190501.41123311'),
	'identifierSchemeCodeUnspsc190501.41123312': __('identifierSchemeCodeUnspsc190501.41123312'),
	'identifierSchemeCodeUnspsc190501.41123313': __('identifierSchemeCodeUnspsc190501.41123313'),
	'identifierSchemeCodeUnspsc190501.41123314': __('identifierSchemeCodeUnspsc190501.41123314'),
	'identifierSchemeCodeUnspsc190501.41123400': __('identifierSchemeCodeUnspsc190501.41123400'),
	'identifierSchemeCodeUnspsc190501.41123401': __('identifierSchemeCodeUnspsc190501.41123401'),
	'identifierSchemeCodeUnspsc190501.41123402': __('identifierSchemeCodeUnspsc190501.41123402'),
	'identifierSchemeCodeUnspsc190501.41123403': __('identifierSchemeCodeUnspsc190501.41123403'),
	'identifierSchemeCodeUnspsc190501.42000000': __('identifierSchemeCodeUnspsc190501.42000000'),
	'identifierSchemeCodeUnspsc190501.42120000': __('identifierSchemeCodeUnspsc190501.42120000'),
	'identifierSchemeCodeUnspsc190501.42121500': __('identifierSchemeCodeUnspsc190501.42121500'),
	'identifierSchemeCodeUnspsc190501.42121501': __('identifierSchemeCodeUnspsc190501.42121501'),
	'identifierSchemeCodeUnspsc190501.42121502': __('identifierSchemeCodeUnspsc190501.42121502'),
	'identifierSchemeCodeUnspsc190501.42121503': __('identifierSchemeCodeUnspsc190501.42121503'),
	'identifierSchemeCodeUnspsc190501.42121504': __('identifierSchemeCodeUnspsc190501.42121504'),
	'identifierSchemeCodeUnspsc190501.42121505': __('identifierSchemeCodeUnspsc190501.42121505'),
	'identifierSchemeCodeUnspsc190501.42121506': __('identifierSchemeCodeUnspsc190501.42121506'),
	'identifierSchemeCodeUnspsc190501.42121507': __('identifierSchemeCodeUnspsc190501.42121507'),
	'identifierSchemeCodeUnspsc190501.42121508': __('identifierSchemeCodeUnspsc190501.42121508'),
	'identifierSchemeCodeUnspsc190501.42121509': __('identifierSchemeCodeUnspsc190501.42121509'),
	'identifierSchemeCodeUnspsc190501.42121511': __('identifierSchemeCodeUnspsc190501.42121511'),
	'identifierSchemeCodeUnspsc190501.42121512': __('identifierSchemeCodeUnspsc190501.42121512'),
	'identifierSchemeCodeUnspsc190501.42121513': __('identifierSchemeCodeUnspsc190501.42121513'),
	'identifierSchemeCodeUnspsc190501.42121514': __('identifierSchemeCodeUnspsc190501.42121514'),
	'identifierSchemeCodeUnspsc190501.42121515': __('identifierSchemeCodeUnspsc190501.42121515'),
	'identifierSchemeCodeUnspsc190501.42121516': __('identifierSchemeCodeUnspsc190501.42121516'),
	'identifierSchemeCodeUnspsc190501.42121517': __('identifierSchemeCodeUnspsc190501.42121517'),
	'identifierSchemeCodeUnspsc190501.42121518': __('identifierSchemeCodeUnspsc190501.42121518'),
	'identifierSchemeCodeUnspsc190501.42121519': __('identifierSchemeCodeUnspsc190501.42121519'),
	'identifierSchemeCodeUnspsc190501.42121520': __('identifierSchemeCodeUnspsc190501.42121520'),
	'identifierSchemeCodeUnspsc190501.42121600': __('identifierSchemeCodeUnspsc190501.42121600'),
	'identifierSchemeCodeUnspsc190501.42121601': __('identifierSchemeCodeUnspsc190501.42121601'),
	'identifierSchemeCodeUnspsc190501.42121602': __('identifierSchemeCodeUnspsc190501.42121602'),
	'identifierSchemeCodeUnspsc190501.42121603': __('identifierSchemeCodeUnspsc190501.42121603'),
	'identifierSchemeCodeUnspsc190501.42121604': __('identifierSchemeCodeUnspsc190501.42121604'),
	'identifierSchemeCodeUnspsc190501.42121605': __('identifierSchemeCodeUnspsc190501.42121605'),
	'identifierSchemeCodeUnspsc190501.42121606': __('identifierSchemeCodeUnspsc190501.42121606'),
	'identifierSchemeCodeUnspsc190501.42121607': __('identifierSchemeCodeUnspsc190501.42121607'),
	'identifierSchemeCodeUnspsc190501.42121608': __('identifierSchemeCodeUnspsc190501.42121608'),
	'identifierSchemeCodeUnspsc190501.42121700': __('identifierSchemeCodeUnspsc190501.42121700'),
	'identifierSchemeCodeUnspsc190501.42121701': __('identifierSchemeCodeUnspsc190501.42121701'),
	'identifierSchemeCodeUnspsc190501.42121702': __('identifierSchemeCodeUnspsc190501.42121702'),
	'identifierSchemeCodeUnspsc190501.42121800': __('identifierSchemeCodeUnspsc190501.42121800'),
	'identifierSchemeCodeUnspsc190501.42121801': __('identifierSchemeCodeUnspsc190501.42121801'),
	'identifierSchemeCodeUnspsc190501.42121802': __('identifierSchemeCodeUnspsc190501.42121802'),
	'identifierSchemeCodeUnspsc190501.42121803': __('identifierSchemeCodeUnspsc190501.42121803'),
	'identifierSchemeCodeUnspsc190501.42121804': __('identifierSchemeCodeUnspsc190501.42121804'),
	'identifierSchemeCodeUnspsc190501.42130000': __('identifierSchemeCodeUnspsc190501.42130000'),
	'identifierSchemeCodeUnspsc190501.42131500': __('identifierSchemeCodeUnspsc190501.42131500'),
	'identifierSchemeCodeUnspsc190501.42131501': __('identifierSchemeCodeUnspsc190501.42131501'),
	'identifierSchemeCodeUnspsc190501.42131502': __('identifierSchemeCodeUnspsc190501.42131502'),
	'identifierSchemeCodeUnspsc190501.42131503': __('identifierSchemeCodeUnspsc190501.42131503'),
	'identifierSchemeCodeUnspsc190501.42131504': __('identifierSchemeCodeUnspsc190501.42131504'),
	'identifierSchemeCodeUnspsc190501.42131505': __('identifierSchemeCodeUnspsc190501.42131505'),
	'identifierSchemeCodeUnspsc190501.42131506': __('identifierSchemeCodeUnspsc190501.42131506'),
	'identifierSchemeCodeUnspsc190501.42131507': __('identifierSchemeCodeUnspsc190501.42131507'),
	'identifierSchemeCodeUnspsc190501.42131508': __('identifierSchemeCodeUnspsc190501.42131508'),
	'identifierSchemeCodeUnspsc190501.42131509': __('identifierSchemeCodeUnspsc190501.42131509'),
	'identifierSchemeCodeUnspsc190501.42131510': __('identifierSchemeCodeUnspsc190501.42131510'),
	'identifierSchemeCodeUnspsc190501.42131511': __('identifierSchemeCodeUnspsc190501.42131511'),
	'identifierSchemeCodeUnspsc190501.42131512': __('identifierSchemeCodeUnspsc190501.42131512'),
	'identifierSchemeCodeUnspsc190501.42131513': __('identifierSchemeCodeUnspsc190501.42131513'),
	'identifierSchemeCodeUnspsc190501.42131514': __('identifierSchemeCodeUnspsc190501.42131514'),
	'identifierSchemeCodeUnspsc190501.42131600': __('identifierSchemeCodeUnspsc190501.42131600'),
	'identifierSchemeCodeUnspsc190501.42131601': __('identifierSchemeCodeUnspsc190501.42131601'),
	'identifierSchemeCodeUnspsc190501.42131602': __('identifierSchemeCodeUnspsc190501.42131602'),
	'identifierSchemeCodeUnspsc190501.42131603': __('identifierSchemeCodeUnspsc190501.42131603'),
	'identifierSchemeCodeUnspsc190501.42131604': __('identifierSchemeCodeUnspsc190501.42131604'),
	'identifierSchemeCodeUnspsc190501.42131607': __('identifierSchemeCodeUnspsc190501.42131607'),
	'identifierSchemeCodeUnspsc190501.42131608': __('identifierSchemeCodeUnspsc190501.42131608'),
	'identifierSchemeCodeUnspsc190501.42131609': __('identifierSchemeCodeUnspsc190501.42131609'),
	'identifierSchemeCodeUnspsc190501.42131610': __('identifierSchemeCodeUnspsc190501.42131610'),
	'identifierSchemeCodeUnspsc190501.42131611': __('identifierSchemeCodeUnspsc190501.42131611'),
	'identifierSchemeCodeUnspsc190501.42131612': __('identifierSchemeCodeUnspsc190501.42131612'),
	'identifierSchemeCodeUnspsc190501.42131613': __('identifierSchemeCodeUnspsc190501.42131613'),
	'identifierSchemeCodeUnspsc190501.42131614': __('identifierSchemeCodeUnspsc190501.42131614'),
	'identifierSchemeCodeUnspsc190501.42131615': __('identifierSchemeCodeUnspsc190501.42131615'),
	'identifierSchemeCodeUnspsc190501.42131700': __('identifierSchemeCodeUnspsc190501.42131700'),
	'identifierSchemeCodeUnspsc190501.42131701': __('identifierSchemeCodeUnspsc190501.42131701'),
	'identifierSchemeCodeUnspsc190501.42131702': __('identifierSchemeCodeUnspsc190501.42131702'),
	'identifierSchemeCodeUnspsc190501.42131704': __('identifierSchemeCodeUnspsc190501.42131704'),
	'identifierSchemeCodeUnspsc190501.42131706': __('identifierSchemeCodeUnspsc190501.42131706'),
	'identifierSchemeCodeUnspsc190501.42131707': __('identifierSchemeCodeUnspsc190501.42131707'),
	'identifierSchemeCodeUnspsc190501.42131708': __('identifierSchemeCodeUnspsc190501.42131708'),
	'identifierSchemeCodeUnspsc190501.42131709': __('identifierSchemeCodeUnspsc190501.42131709'),
	'identifierSchemeCodeUnspsc190501.42131710': __('identifierSchemeCodeUnspsc190501.42131710'),
	'identifierSchemeCodeUnspsc190501.42131711': __('identifierSchemeCodeUnspsc190501.42131711'),
	'identifierSchemeCodeUnspsc190501.42131712': __('identifierSchemeCodeUnspsc190501.42131712'),
	'identifierSchemeCodeUnspsc190501.42131713': __('identifierSchemeCodeUnspsc190501.42131713'),
	'identifierSchemeCodeUnspsc190501.42131714': __('identifierSchemeCodeUnspsc190501.42131714'),
	'identifierSchemeCodeUnspsc190501.42131715': __('identifierSchemeCodeUnspsc190501.42131715'),
	'identifierSchemeCodeUnspsc190501.42131716': __('identifierSchemeCodeUnspsc190501.42131716'),
	'identifierSchemeCodeUnspsc190501.42131717': __('identifierSchemeCodeUnspsc190501.42131717'),
	'identifierSchemeCodeUnspsc190501.42131718': __('identifierSchemeCodeUnspsc190501.42131718'),
	'identifierSchemeCodeUnspsc190501.42131719': __('identifierSchemeCodeUnspsc190501.42131719'),
	'identifierSchemeCodeUnspsc190501.42131720': __('identifierSchemeCodeUnspsc190501.42131720'),
	'identifierSchemeCodeUnspsc190501.42131721': __('identifierSchemeCodeUnspsc190501.42131721'),
	'identifierSchemeCodeUnspsc190501.42131722': __('identifierSchemeCodeUnspsc190501.42131722'),
	'identifierSchemeCodeUnspsc190501.42132100': __('identifierSchemeCodeUnspsc190501.42132100'),
	'identifierSchemeCodeUnspsc190501.42132101': __('identifierSchemeCodeUnspsc190501.42132101'),
	'identifierSchemeCodeUnspsc190501.42132102': __('identifierSchemeCodeUnspsc190501.42132102'),
	'identifierSchemeCodeUnspsc190501.42132103': __('identifierSchemeCodeUnspsc190501.42132103'),
	'identifierSchemeCodeUnspsc190501.42132104': __('identifierSchemeCodeUnspsc190501.42132104'),
	'identifierSchemeCodeUnspsc190501.42132105': __('identifierSchemeCodeUnspsc190501.42132105'),
	'identifierSchemeCodeUnspsc190501.42132106': __('identifierSchemeCodeUnspsc190501.42132106'),
	'identifierSchemeCodeUnspsc190501.42132107': __('identifierSchemeCodeUnspsc190501.42132107'),
	'identifierSchemeCodeUnspsc190501.42132108': __('identifierSchemeCodeUnspsc190501.42132108'),
	'identifierSchemeCodeUnspsc190501.42132109': __('identifierSchemeCodeUnspsc190501.42132109'),
	'identifierSchemeCodeUnspsc190501.42132110': __('identifierSchemeCodeUnspsc190501.42132110'),
	'identifierSchemeCodeUnspsc190501.42132111': __('identifierSchemeCodeUnspsc190501.42132111'),
	'identifierSchemeCodeUnspsc190501.42132112': __('identifierSchemeCodeUnspsc190501.42132112'),
	'identifierSchemeCodeUnspsc190501.42132113': __('identifierSchemeCodeUnspsc190501.42132113'),
	'identifierSchemeCodeUnspsc190501.42132114': __('identifierSchemeCodeUnspsc190501.42132114'),
	'identifierSchemeCodeUnspsc190501.42132115': __('identifierSchemeCodeUnspsc190501.42132115'),
	'identifierSchemeCodeUnspsc190501.42132116': __('identifierSchemeCodeUnspsc190501.42132116'),
	'identifierSchemeCodeUnspsc190501.42132117': __('identifierSchemeCodeUnspsc190501.42132117'),
	'identifierSchemeCodeUnspsc190501.42132200': __('identifierSchemeCodeUnspsc190501.42132200'),
	'identifierSchemeCodeUnspsc190501.42132201': __('identifierSchemeCodeUnspsc190501.42132201'),
	'identifierSchemeCodeUnspsc190501.42132202': __('identifierSchemeCodeUnspsc190501.42132202'),
	'identifierSchemeCodeUnspsc190501.42132203': __('identifierSchemeCodeUnspsc190501.42132203'),
	'identifierSchemeCodeUnspsc190501.42132204': __('identifierSchemeCodeUnspsc190501.42132204'),
	'identifierSchemeCodeUnspsc190501.42132205': __('identifierSchemeCodeUnspsc190501.42132205'),
	'identifierSchemeCodeUnspsc190501.42132206': __('identifierSchemeCodeUnspsc190501.42132206'),
	'identifierSchemeCodeUnspsc190501.42140000': __('identifierSchemeCodeUnspsc190501.42140000'),
	'identifierSchemeCodeUnspsc190501.42141500': __('identifierSchemeCodeUnspsc190501.42141500'),
	'identifierSchemeCodeUnspsc190501.42141501': __('identifierSchemeCodeUnspsc190501.42141501'),
	'identifierSchemeCodeUnspsc190501.42141502': __('identifierSchemeCodeUnspsc190501.42141502'),
	'identifierSchemeCodeUnspsc190501.42141503': __('identifierSchemeCodeUnspsc190501.42141503'),
	'identifierSchemeCodeUnspsc190501.42141504': __('identifierSchemeCodeUnspsc190501.42141504'),
	'identifierSchemeCodeUnspsc190501.42141505': __('identifierSchemeCodeUnspsc190501.42141505'),
	'identifierSchemeCodeUnspsc190501.42141506': __('identifierSchemeCodeUnspsc190501.42141506'),
	'identifierSchemeCodeUnspsc190501.42141600': __('identifierSchemeCodeUnspsc190501.42141600'),
	'identifierSchemeCodeUnspsc190501.42141601': __('identifierSchemeCodeUnspsc190501.42141601'),
	'identifierSchemeCodeUnspsc190501.42141602': __('identifierSchemeCodeUnspsc190501.42141602'),
	'identifierSchemeCodeUnspsc190501.42141603': __('identifierSchemeCodeUnspsc190501.42141603'),
	'identifierSchemeCodeUnspsc190501.42141605': __('identifierSchemeCodeUnspsc190501.42141605'),
	'identifierSchemeCodeUnspsc190501.42141606': __('identifierSchemeCodeUnspsc190501.42141606'),
	'identifierSchemeCodeUnspsc190501.42141607': __('identifierSchemeCodeUnspsc190501.42141607'),
	'identifierSchemeCodeUnspsc190501.42141608': __('identifierSchemeCodeUnspsc190501.42141608'),
	'identifierSchemeCodeUnspsc190501.42141609': __('identifierSchemeCodeUnspsc190501.42141609'),
	'identifierSchemeCodeUnspsc190501.42141610': __('identifierSchemeCodeUnspsc190501.42141610'),
	'identifierSchemeCodeUnspsc190501.42141611': __('identifierSchemeCodeUnspsc190501.42141611'),
	'identifierSchemeCodeUnspsc190501.42141612': __('identifierSchemeCodeUnspsc190501.42141612'),
	'identifierSchemeCodeUnspsc190501.42141700': __('identifierSchemeCodeUnspsc190501.42141700'),
	'identifierSchemeCodeUnspsc190501.42141701': __('identifierSchemeCodeUnspsc190501.42141701'),
	'identifierSchemeCodeUnspsc190501.42141702': __('identifierSchemeCodeUnspsc190501.42141702'),
	'identifierSchemeCodeUnspsc190501.42141703': __('identifierSchemeCodeUnspsc190501.42141703'),
	'identifierSchemeCodeUnspsc190501.42141704': __('identifierSchemeCodeUnspsc190501.42141704'),
	'identifierSchemeCodeUnspsc190501.42141705': __('identifierSchemeCodeUnspsc190501.42141705'),
	'identifierSchemeCodeUnspsc190501.42141706': __('identifierSchemeCodeUnspsc190501.42141706'),
	'identifierSchemeCodeUnspsc190501.42141800': __('identifierSchemeCodeUnspsc190501.42141800'),
	'identifierSchemeCodeUnspsc190501.42141801': __('identifierSchemeCodeUnspsc190501.42141801'),
	'identifierSchemeCodeUnspsc190501.42141802': __('identifierSchemeCodeUnspsc190501.42141802'),
	'identifierSchemeCodeUnspsc190501.42141803': __('identifierSchemeCodeUnspsc190501.42141803'),
	'identifierSchemeCodeUnspsc190501.42141804': __('identifierSchemeCodeUnspsc190501.42141804'),
	'identifierSchemeCodeUnspsc190501.42141805': __('identifierSchemeCodeUnspsc190501.42141805'),
	'identifierSchemeCodeUnspsc190501.42141806': __('identifierSchemeCodeUnspsc190501.42141806'),
	'identifierSchemeCodeUnspsc190501.42141807': __('identifierSchemeCodeUnspsc190501.42141807'),
	'identifierSchemeCodeUnspsc190501.42141808': __('identifierSchemeCodeUnspsc190501.42141808'),
	'identifierSchemeCodeUnspsc190501.42141809': __('identifierSchemeCodeUnspsc190501.42141809'),
	'identifierSchemeCodeUnspsc190501.42141810': __('identifierSchemeCodeUnspsc190501.42141810'),
	'identifierSchemeCodeUnspsc190501.42141811': __('identifierSchemeCodeUnspsc190501.42141811'),
	'identifierSchemeCodeUnspsc190501.42141812': __('identifierSchemeCodeUnspsc190501.42141812'),
	'identifierSchemeCodeUnspsc190501.42141813': __('identifierSchemeCodeUnspsc190501.42141813'),
	'identifierSchemeCodeUnspsc190501.42141814': __('identifierSchemeCodeUnspsc190501.42141814'),
	'identifierSchemeCodeUnspsc190501.42141815': __('identifierSchemeCodeUnspsc190501.42141815'),
	'identifierSchemeCodeUnspsc190501.42141816': __('identifierSchemeCodeUnspsc190501.42141816'),
	'identifierSchemeCodeUnspsc190501.42141817': __('identifierSchemeCodeUnspsc190501.42141817'),
	'identifierSchemeCodeUnspsc190501.42141818': __('identifierSchemeCodeUnspsc190501.42141818'),
	'identifierSchemeCodeUnspsc190501.42141819': __('identifierSchemeCodeUnspsc190501.42141819'),
	'identifierSchemeCodeUnspsc190501.42141820': __('identifierSchemeCodeUnspsc190501.42141820'),
	'identifierSchemeCodeUnspsc190501.42141821': __('identifierSchemeCodeUnspsc190501.42141821'),
	'identifierSchemeCodeUnspsc190501.42141822': __('identifierSchemeCodeUnspsc190501.42141822'),
	'identifierSchemeCodeUnspsc190501.42141823': __('identifierSchemeCodeUnspsc190501.42141823'),
	'identifierSchemeCodeUnspsc190501.42141824': __('identifierSchemeCodeUnspsc190501.42141824'),
	'identifierSchemeCodeUnspsc190501.42141825': __('identifierSchemeCodeUnspsc190501.42141825'),
	'identifierSchemeCodeUnspsc190501.42141900': __('identifierSchemeCodeUnspsc190501.42141900'),
	'identifierSchemeCodeUnspsc190501.42141903': __('identifierSchemeCodeUnspsc190501.42141903'),
	'identifierSchemeCodeUnspsc190501.42141904': __('identifierSchemeCodeUnspsc190501.42141904'),
	'identifierSchemeCodeUnspsc190501.42141905': __('identifierSchemeCodeUnspsc190501.42141905'),
	'identifierSchemeCodeUnspsc190501.42141906': __('identifierSchemeCodeUnspsc190501.42141906'),
	'identifierSchemeCodeUnspsc190501.42141907': __('identifierSchemeCodeUnspsc190501.42141907'),
	'identifierSchemeCodeUnspsc190501.42142100': __('identifierSchemeCodeUnspsc190501.42142100'),
	'identifierSchemeCodeUnspsc190501.42142101': __('identifierSchemeCodeUnspsc190501.42142101'),
	'identifierSchemeCodeUnspsc190501.42142102': __('identifierSchemeCodeUnspsc190501.42142102'),
	'identifierSchemeCodeUnspsc190501.42142103': __('identifierSchemeCodeUnspsc190501.42142103'),
	'identifierSchemeCodeUnspsc190501.42142104': __('identifierSchemeCodeUnspsc190501.42142104'),
	'identifierSchemeCodeUnspsc190501.42142105': __('identifierSchemeCodeUnspsc190501.42142105'),
	'identifierSchemeCodeUnspsc190501.42142106': __('identifierSchemeCodeUnspsc190501.42142106'),
	'identifierSchemeCodeUnspsc190501.42142107': __('identifierSchemeCodeUnspsc190501.42142107'),
	'identifierSchemeCodeUnspsc190501.42142108': __('identifierSchemeCodeUnspsc190501.42142108'),
	'identifierSchemeCodeUnspsc190501.42142109': __('identifierSchemeCodeUnspsc190501.42142109'),
	'identifierSchemeCodeUnspsc190501.42142110': __('identifierSchemeCodeUnspsc190501.42142110'),
	'identifierSchemeCodeUnspsc190501.42142111': __('identifierSchemeCodeUnspsc190501.42142111'),
	'identifierSchemeCodeUnspsc190501.42142112': __('identifierSchemeCodeUnspsc190501.42142112'),
	'identifierSchemeCodeUnspsc190501.42142113': __('identifierSchemeCodeUnspsc190501.42142113'),
	'identifierSchemeCodeUnspsc190501.42142114': __('identifierSchemeCodeUnspsc190501.42142114'),
	'identifierSchemeCodeUnspsc190501.42142119': __('identifierSchemeCodeUnspsc190501.42142119'),
	'identifierSchemeCodeUnspsc190501.42142120': __('identifierSchemeCodeUnspsc190501.42142120'),
	'identifierSchemeCodeUnspsc190501.42142121': __('identifierSchemeCodeUnspsc190501.42142121'),
	'identifierSchemeCodeUnspsc190501.42142122': __('identifierSchemeCodeUnspsc190501.42142122'),
	'identifierSchemeCodeUnspsc190501.42142123': __('identifierSchemeCodeUnspsc190501.42142123'),
	'identifierSchemeCodeUnspsc190501.42142124': __('identifierSchemeCodeUnspsc190501.42142124'),
	'identifierSchemeCodeUnspsc190501.42142125': __('identifierSchemeCodeUnspsc190501.42142125'),
	'identifierSchemeCodeUnspsc190501.42142126': __('identifierSchemeCodeUnspsc190501.42142126'),
	'identifierSchemeCodeUnspsc190501.42142127': __('identifierSchemeCodeUnspsc190501.42142127'),
	'identifierSchemeCodeUnspsc190501.42142128': __('identifierSchemeCodeUnspsc190501.42142128'),
	'identifierSchemeCodeUnspsc190501.42142129': __('identifierSchemeCodeUnspsc190501.42142129'),
	'identifierSchemeCodeUnspsc190501.42142130': __('identifierSchemeCodeUnspsc190501.42142130'),
	'identifierSchemeCodeUnspsc190501.42142131': __('identifierSchemeCodeUnspsc190501.42142131'),
	'identifierSchemeCodeUnspsc190501.42142200': __('identifierSchemeCodeUnspsc190501.42142200'),
	'identifierSchemeCodeUnspsc190501.42142201': __('identifierSchemeCodeUnspsc190501.42142201'),
	'identifierSchemeCodeUnspsc190501.42142202': __('identifierSchemeCodeUnspsc190501.42142202'),
	'identifierSchemeCodeUnspsc190501.42142203': __('identifierSchemeCodeUnspsc190501.42142203'),
	'identifierSchemeCodeUnspsc190501.42142204': __('identifierSchemeCodeUnspsc190501.42142204'),
	'identifierSchemeCodeUnspsc190501.42142205': __('identifierSchemeCodeUnspsc190501.42142205'),
	'identifierSchemeCodeUnspsc190501.42142206': __('identifierSchemeCodeUnspsc190501.42142206'),
	'identifierSchemeCodeUnspsc190501.42142300': __('identifierSchemeCodeUnspsc190501.42142300'),
	'identifierSchemeCodeUnspsc190501.42142301': __('identifierSchemeCodeUnspsc190501.42142301'),
	'identifierSchemeCodeUnspsc190501.42142302': __('identifierSchemeCodeUnspsc190501.42142302'),
	'identifierSchemeCodeUnspsc190501.42142303': __('identifierSchemeCodeUnspsc190501.42142303'),
	'identifierSchemeCodeUnspsc190501.42142304': __('identifierSchemeCodeUnspsc190501.42142304'),
	'identifierSchemeCodeUnspsc190501.42142305': __('identifierSchemeCodeUnspsc190501.42142305'),
	'identifierSchemeCodeUnspsc190501.42142306': __('identifierSchemeCodeUnspsc190501.42142306'),
	'identifierSchemeCodeUnspsc190501.42142307': __('identifierSchemeCodeUnspsc190501.42142307'),
	'identifierSchemeCodeUnspsc190501.42142500': __('identifierSchemeCodeUnspsc190501.42142500'),
	'identifierSchemeCodeUnspsc190501.42142501': __('identifierSchemeCodeUnspsc190501.42142501'),
	'identifierSchemeCodeUnspsc190501.42142502': __('identifierSchemeCodeUnspsc190501.42142502'),
	'identifierSchemeCodeUnspsc190501.42142503': __('identifierSchemeCodeUnspsc190501.42142503'),
	'identifierSchemeCodeUnspsc190501.42142504': __('identifierSchemeCodeUnspsc190501.42142504'),
	'identifierSchemeCodeUnspsc190501.42142506': __('identifierSchemeCodeUnspsc190501.42142506'),
	'identifierSchemeCodeUnspsc190501.42142509': __('identifierSchemeCodeUnspsc190501.42142509'),
	'identifierSchemeCodeUnspsc190501.42142510': __('identifierSchemeCodeUnspsc190501.42142510'),
	'identifierSchemeCodeUnspsc190501.42142511': __('identifierSchemeCodeUnspsc190501.42142511'),
	'identifierSchemeCodeUnspsc190501.42142512': __('identifierSchemeCodeUnspsc190501.42142512'),
	'identifierSchemeCodeUnspsc190501.42142513': __('identifierSchemeCodeUnspsc190501.42142513'),
	'identifierSchemeCodeUnspsc190501.42142514': __('identifierSchemeCodeUnspsc190501.42142514'),
	'identifierSchemeCodeUnspsc190501.42142516': __('identifierSchemeCodeUnspsc190501.42142516'),
	'identifierSchemeCodeUnspsc190501.42142518': __('identifierSchemeCodeUnspsc190501.42142518'),
	'identifierSchemeCodeUnspsc190501.42142519': __('identifierSchemeCodeUnspsc190501.42142519'),
	'identifierSchemeCodeUnspsc190501.42142520': __('identifierSchemeCodeUnspsc190501.42142520'),
	'identifierSchemeCodeUnspsc190501.42142521': __('identifierSchemeCodeUnspsc190501.42142521'),
	'identifierSchemeCodeUnspsc190501.42142523': __('identifierSchemeCodeUnspsc190501.42142523'),
	'identifierSchemeCodeUnspsc190501.42142525': __('identifierSchemeCodeUnspsc190501.42142525'),
	'identifierSchemeCodeUnspsc190501.42142527': __('identifierSchemeCodeUnspsc190501.42142527'),
	'identifierSchemeCodeUnspsc190501.42142528': __('identifierSchemeCodeUnspsc190501.42142528'),
	'identifierSchemeCodeUnspsc190501.42142529': __('identifierSchemeCodeUnspsc190501.42142529'),
	'identifierSchemeCodeUnspsc190501.42142530': __('identifierSchemeCodeUnspsc190501.42142530'),
	'identifierSchemeCodeUnspsc190501.42142531': __('identifierSchemeCodeUnspsc190501.42142531'),
	'identifierSchemeCodeUnspsc190501.42142532': __('identifierSchemeCodeUnspsc190501.42142532'),
	'identifierSchemeCodeUnspsc190501.42142533': __('identifierSchemeCodeUnspsc190501.42142533'),
	'identifierSchemeCodeUnspsc190501.42142534': __('identifierSchemeCodeUnspsc190501.42142534'),
	'identifierSchemeCodeUnspsc190501.42142535': __('identifierSchemeCodeUnspsc190501.42142535'),
	'identifierSchemeCodeUnspsc190501.42142536': __('identifierSchemeCodeUnspsc190501.42142536'),
	'identifierSchemeCodeUnspsc190501.42142537': __('identifierSchemeCodeUnspsc190501.42142537'),
	'identifierSchemeCodeUnspsc190501.42142538': __('identifierSchemeCodeUnspsc190501.42142538'),
	'identifierSchemeCodeUnspsc190501.42142539': __('identifierSchemeCodeUnspsc190501.42142539'),
	'identifierSchemeCodeUnspsc190501.42142540': __('identifierSchemeCodeUnspsc190501.42142540'),
	'identifierSchemeCodeUnspsc190501.42142541': __('identifierSchemeCodeUnspsc190501.42142541'),
	'identifierSchemeCodeUnspsc190501.42142542': __('identifierSchemeCodeUnspsc190501.42142542'),
	'identifierSchemeCodeUnspsc190501.42142543': __('identifierSchemeCodeUnspsc190501.42142543'),
	'identifierSchemeCodeUnspsc190501.42142544': __('identifierSchemeCodeUnspsc190501.42142544'),
	'identifierSchemeCodeUnspsc190501.42142545': __('identifierSchemeCodeUnspsc190501.42142545'),
	'identifierSchemeCodeUnspsc190501.42142600': __('identifierSchemeCodeUnspsc190501.42142600'),
	'identifierSchemeCodeUnspsc190501.42142601': __('identifierSchemeCodeUnspsc190501.42142601'),
	'identifierSchemeCodeUnspsc190501.42142602': __('identifierSchemeCodeUnspsc190501.42142602'),
	'identifierSchemeCodeUnspsc190501.42142603': __('identifierSchemeCodeUnspsc190501.42142603'),
	'identifierSchemeCodeUnspsc190501.42142604': __('identifierSchemeCodeUnspsc190501.42142604'),
	'identifierSchemeCodeUnspsc190501.42142605': __('identifierSchemeCodeUnspsc190501.42142605'),
	'identifierSchemeCodeUnspsc190501.42142606': __('identifierSchemeCodeUnspsc190501.42142606'),
	'identifierSchemeCodeUnspsc190501.42142607': __('identifierSchemeCodeUnspsc190501.42142607'),
	'identifierSchemeCodeUnspsc190501.42142608': __('identifierSchemeCodeUnspsc190501.42142608'),
	'identifierSchemeCodeUnspsc190501.42142609': __('identifierSchemeCodeUnspsc190501.42142609'),
	'identifierSchemeCodeUnspsc190501.42142610': __('identifierSchemeCodeUnspsc190501.42142610'),
	'identifierSchemeCodeUnspsc190501.42142611': __('identifierSchemeCodeUnspsc190501.42142611'),
	'identifierSchemeCodeUnspsc190501.42142612': __('identifierSchemeCodeUnspsc190501.42142612'),
	'identifierSchemeCodeUnspsc190501.42142613': __('identifierSchemeCodeUnspsc190501.42142613'),
	'identifierSchemeCodeUnspsc190501.42142614': __('identifierSchemeCodeUnspsc190501.42142614'),
	'identifierSchemeCodeUnspsc190501.42142615': __('identifierSchemeCodeUnspsc190501.42142615'),
	'identifierSchemeCodeUnspsc190501.42142616': __('identifierSchemeCodeUnspsc190501.42142616'),
	'identifierSchemeCodeUnspsc190501.42142617': __('identifierSchemeCodeUnspsc190501.42142617'),
	'identifierSchemeCodeUnspsc190501.42142618': __('identifierSchemeCodeUnspsc190501.42142618'),
	'identifierSchemeCodeUnspsc190501.42142619': __('identifierSchemeCodeUnspsc190501.42142619'),
	'identifierSchemeCodeUnspsc190501.42142620': __('identifierSchemeCodeUnspsc190501.42142620'),
	'identifierSchemeCodeUnspsc190501.42142621': __('identifierSchemeCodeUnspsc190501.42142621'),
	'identifierSchemeCodeUnspsc190501.42142622': __('identifierSchemeCodeUnspsc190501.42142622'),
	'identifierSchemeCodeUnspsc190501.42142623': __('identifierSchemeCodeUnspsc190501.42142623'),
	'identifierSchemeCodeUnspsc190501.42142624': __('identifierSchemeCodeUnspsc190501.42142624'),
	'identifierSchemeCodeUnspsc190501.42142625': __('identifierSchemeCodeUnspsc190501.42142625'),
	'identifierSchemeCodeUnspsc190501.42142626': __('identifierSchemeCodeUnspsc190501.42142626'),
	'identifierSchemeCodeUnspsc190501.42142700': __('identifierSchemeCodeUnspsc190501.42142700'),
	'identifierSchemeCodeUnspsc190501.42142701': __('identifierSchemeCodeUnspsc190501.42142701'),
	'identifierSchemeCodeUnspsc190501.42142702': __('identifierSchemeCodeUnspsc190501.42142702'),
	'identifierSchemeCodeUnspsc190501.42142703': __('identifierSchemeCodeUnspsc190501.42142703'),
	'identifierSchemeCodeUnspsc190501.42142704': __('identifierSchemeCodeUnspsc190501.42142704'),
	'identifierSchemeCodeUnspsc190501.42142705': __('identifierSchemeCodeUnspsc190501.42142705'),
	'identifierSchemeCodeUnspsc190501.42142706': __('identifierSchemeCodeUnspsc190501.42142706'),
	'identifierSchemeCodeUnspsc190501.42142707': __('identifierSchemeCodeUnspsc190501.42142707'),
	'identifierSchemeCodeUnspsc190501.42142708': __('identifierSchemeCodeUnspsc190501.42142708'),
	'identifierSchemeCodeUnspsc190501.42142709': __('identifierSchemeCodeUnspsc190501.42142709'),
	'identifierSchemeCodeUnspsc190501.42142710': __('identifierSchemeCodeUnspsc190501.42142710'),
	'identifierSchemeCodeUnspsc190501.42142712': __('identifierSchemeCodeUnspsc190501.42142712'),
	'identifierSchemeCodeUnspsc190501.42142713': __('identifierSchemeCodeUnspsc190501.42142713'),
	'identifierSchemeCodeUnspsc190501.42142714': __('identifierSchemeCodeUnspsc190501.42142714'),
	'identifierSchemeCodeUnspsc190501.42142715': __('identifierSchemeCodeUnspsc190501.42142715'),
	'identifierSchemeCodeUnspsc190501.42142716': __('identifierSchemeCodeUnspsc190501.42142716'),
	'identifierSchemeCodeUnspsc190501.42142717': __('identifierSchemeCodeUnspsc190501.42142717'),
	'identifierSchemeCodeUnspsc190501.42142718': __('identifierSchemeCodeUnspsc190501.42142718'),
	'identifierSchemeCodeUnspsc190501.42142719': __('identifierSchemeCodeUnspsc190501.42142719'),
	'identifierSchemeCodeUnspsc190501.42142720': __('identifierSchemeCodeUnspsc190501.42142720'),
	'identifierSchemeCodeUnspsc190501.42142721': __('identifierSchemeCodeUnspsc190501.42142721'),
	'identifierSchemeCodeUnspsc190501.42142722': __('identifierSchemeCodeUnspsc190501.42142722'),
	'identifierSchemeCodeUnspsc190501.42142723': __('identifierSchemeCodeUnspsc190501.42142723'),
	'identifierSchemeCodeUnspsc190501.42142724': __('identifierSchemeCodeUnspsc190501.42142724'),
	'identifierSchemeCodeUnspsc190501.42142725': __('identifierSchemeCodeUnspsc190501.42142725'),
	'identifierSchemeCodeUnspsc190501.42142726': __('identifierSchemeCodeUnspsc190501.42142726'),
	'identifierSchemeCodeUnspsc190501.42142727': __('identifierSchemeCodeUnspsc190501.42142727'),
	'identifierSchemeCodeUnspsc190501.42142728': __('identifierSchemeCodeUnspsc190501.42142728'),
	'identifierSchemeCodeUnspsc190501.42142729': __('identifierSchemeCodeUnspsc190501.42142729'),
	'identifierSchemeCodeUnspsc190501.42142730': __('identifierSchemeCodeUnspsc190501.42142730'),
	'identifierSchemeCodeUnspsc190501.42142731': __('identifierSchemeCodeUnspsc190501.42142731'),
	'identifierSchemeCodeUnspsc190501.42142732': __('identifierSchemeCodeUnspsc190501.42142732'),
	'identifierSchemeCodeUnspsc190501.42142733': __('identifierSchemeCodeUnspsc190501.42142733'),
	'identifierSchemeCodeUnspsc190501.42142734': __('identifierSchemeCodeUnspsc190501.42142734'),
	'identifierSchemeCodeUnspsc190501.42142735': __('identifierSchemeCodeUnspsc190501.42142735'),
	'identifierSchemeCodeUnspsc190501.42142736': __('identifierSchemeCodeUnspsc190501.42142736'),
	'identifierSchemeCodeUnspsc190501.42142800': __('identifierSchemeCodeUnspsc190501.42142800'),
	'identifierSchemeCodeUnspsc190501.42142801': __('identifierSchemeCodeUnspsc190501.42142801'),
	'identifierSchemeCodeUnspsc190501.42142802': __('identifierSchemeCodeUnspsc190501.42142802'),
	'identifierSchemeCodeUnspsc190501.42142900': __('identifierSchemeCodeUnspsc190501.42142900'),
	'identifierSchemeCodeUnspsc190501.42142901': __('identifierSchemeCodeUnspsc190501.42142901'),
	'identifierSchemeCodeUnspsc190501.42142902': __('identifierSchemeCodeUnspsc190501.42142902'),
	'identifierSchemeCodeUnspsc190501.42142903': __('identifierSchemeCodeUnspsc190501.42142903'),
	'identifierSchemeCodeUnspsc190501.42142904': __('identifierSchemeCodeUnspsc190501.42142904'),
	'identifierSchemeCodeUnspsc190501.42142905': __('identifierSchemeCodeUnspsc190501.42142905'),
	'identifierSchemeCodeUnspsc190501.42142906': __('identifierSchemeCodeUnspsc190501.42142906'),
	'identifierSchemeCodeUnspsc190501.42142907': __('identifierSchemeCodeUnspsc190501.42142907'),
	'identifierSchemeCodeUnspsc190501.42142908': __('identifierSchemeCodeUnspsc190501.42142908'),
	'identifierSchemeCodeUnspsc190501.42142909': __('identifierSchemeCodeUnspsc190501.42142909'),
	'identifierSchemeCodeUnspsc190501.42142910': __('identifierSchemeCodeUnspsc190501.42142910'),
	'identifierSchemeCodeUnspsc190501.42142911': __('identifierSchemeCodeUnspsc190501.42142911'),
	'identifierSchemeCodeUnspsc190501.42142912': __('identifierSchemeCodeUnspsc190501.42142912'),
	'identifierSchemeCodeUnspsc190501.42142913': __('identifierSchemeCodeUnspsc190501.42142913'),
	'identifierSchemeCodeUnspsc190501.42142914': __('identifierSchemeCodeUnspsc190501.42142914'),
	'identifierSchemeCodeUnspsc190501.42142915': __('identifierSchemeCodeUnspsc190501.42142915'),
	'identifierSchemeCodeUnspsc190501.42142916': __('identifierSchemeCodeUnspsc190501.42142916'),
	'identifierSchemeCodeUnspsc190501.42142917': __('identifierSchemeCodeUnspsc190501.42142917'),
	'identifierSchemeCodeUnspsc190501.42142918': __('identifierSchemeCodeUnspsc190501.42142918'),
	'identifierSchemeCodeUnspsc190501.42142919': __('identifierSchemeCodeUnspsc190501.42142919'),
	'identifierSchemeCodeUnspsc190501.42142920': __('identifierSchemeCodeUnspsc190501.42142920'),
	'identifierSchemeCodeUnspsc190501.42142921': __('identifierSchemeCodeUnspsc190501.42142921'),
	'identifierSchemeCodeUnspsc190501.42142922': __('identifierSchemeCodeUnspsc190501.42142922'),
	'identifierSchemeCodeUnspsc190501.42142923': __('identifierSchemeCodeUnspsc190501.42142923'),
	'identifierSchemeCodeUnspsc190501.42142924': __('identifierSchemeCodeUnspsc190501.42142924'),
	'identifierSchemeCodeUnspsc190501.42142925': __('identifierSchemeCodeUnspsc190501.42142925'),
	'identifierSchemeCodeUnspsc190501.42142926': __('identifierSchemeCodeUnspsc190501.42142926'),
	'identifierSchemeCodeUnspsc190501.42142927': __('identifierSchemeCodeUnspsc190501.42142927'),
	'identifierSchemeCodeUnspsc190501.42142928': __('identifierSchemeCodeUnspsc190501.42142928'),
	'identifierSchemeCodeUnspsc190501.42142929': __('identifierSchemeCodeUnspsc190501.42142929'),
	'identifierSchemeCodeUnspsc190501.42142930': __('identifierSchemeCodeUnspsc190501.42142930'),
	'identifierSchemeCodeUnspsc190501.42142931': __('identifierSchemeCodeUnspsc190501.42142931'),
	'identifierSchemeCodeUnspsc190501.42142932': __('identifierSchemeCodeUnspsc190501.42142932'),
	'identifierSchemeCodeUnspsc190501.42143100': __('identifierSchemeCodeUnspsc190501.42143100'),
	'identifierSchemeCodeUnspsc190501.42143101': __('identifierSchemeCodeUnspsc190501.42143101'),
	'identifierSchemeCodeUnspsc190501.42143102': __('identifierSchemeCodeUnspsc190501.42143102'),
	'identifierSchemeCodeUnspsc190501.42143103': __('identifierSchemeCodeUnspsc190501.42143103'),
	'identifierSchemeCodeUnspsc190501.42143104': __('identifierSchemeCodeUnspsc190501.42143104'),
	'identifierSchemeCodeUnspsc190501.42143105': __('identifierSchemeCodeUnspsc190501.42143105'),
	'identifierSchemeCodeUnspsc190501.42143106': __('identifierSchemeCodeUnspsc190501.42143106'),
	'identifierSchemeCodeUnspsc190501.42143107': __('identifierSchemeCodeUnspsc190501.42143107'),
	'identifierSchemeCodeUnspsc190501.42143108': __('identifierSchemeCodeUnspsc190501.42143108'),
	'identifierSchemeCodeUnspsc190501.42143109': __('identifierSchemeCodeUnspsc190501.42143109'),
	'identifierSchemeCodeUnspsc190501.42143110': __('identifierSchemeCodeUnspsc190501.42143110'),
	'identifierSchemeCodeUnspsc190501.42143111': __('identifierSchemeCodeUnspsc190501.42143111'),
	'identifierSchemeCodeUnspsc190501.42143112': __('identifierSchemeCodeUnspsc190501.42143112'),
	'identifierSchemeCodeUnspsc190501.42143113': __('identifierSchemeCodeUnspsc190501.42143113'),
	'identifierSchemeCodeUnspsc190501.42143114': __('identifierSchemeCodeUnspsc190501.42143114'),
	'identifierSchemeCodeUnspsc190501.42143115': __('identifierSchemeCodeUnspsc190501.42143115'),
	'identifierSchemeCodeUnspsc190501.42143116': __('identifierSchemeCodeUnspsc190501.42143116'),
	'identifierSchemeCodeUnspsc190501.42143117': __('identifierSchemeCodeUnspsc190501.42143117'),
	'identifierSchemeCodeUnspsc190501.42143118': __('identifierSchemeCodeUnspsc190501.42143118'),
	'identifierSchemeCodeUnspsc190501.42143119': __('identifierSchemeCodeUnspsc190501.42143119'),
	'identifierSchemeCodeUnspsc190501.42143120': __('identifierSchemeCodeUnspsc190501.42143120'),
	'identifierSchemeCodeUnspsc190501.42143121': __('identifierSchemeCodeUnspsc190501.42143121'),
	'identifierSchemeCodeUnspsc190501.42143122': __('identifierSchemeCodeUnspsc190501.42143122'),
	'identifierSchemeCodeUnspsc190501.42143123': __('identifierSchemeCodeUnspsc190501.42143123'),
	'identifierSchemeCodeUnspsc190501.42143124': __('identifierSchemeCodeUnspsc190501.42143124'),
	'identifierSchemeCodeUnspsc190501.42143125': __('identifierSchemeCodeUnspsc190501.42143125'),
	'identifierSchemeCodeUnspsc190501.42143126': __('identifierSchemeCodeUnspsc190501.42143126'),
	'identifierSchemeCodeUnspsc190501.42143127': __('identifierSchemeCodeUnspsc190501.42143127'),
	'identifierSchemeCodeUnspsc190501.42143128': __('identifierSchemeCodeUnspsc190501.42143128'),
	'identifierSchemeCodeUnspsc190501.42143129': __('identifierSchemeCodeUnspsc190501.42143129'),
	'identifierSchemeCodeUnspsc190501.42143130': __('identifierSchemeCodeUnspsc190501.42143130'),
	'identifierSchemeCodeUnspsc190501.42143131': __('identifierSchemeCodeUnspsc190501.42143131'),
	'identifierSchemeCodeUnspsc190501.42143132': __('identifierSchemeCodeUnspsc190501.42143132'),
	'identifierSchemeCodeUnspsc190501.42143133': __('identifierSchemeCodeUnspsc190501.42143133'),
	'identifierSchemeCodeUnspsc190501.42143134': __('identifierSchemeCodeUnspsc190501.42143134'),
	'identifierSchemeCodeUnspsc190501.42143135': __('identifierSchemeCodeUnspsc190501.42143135'),
	'identifierSchemeCodeUnspsc190501.42143200': __('identifierSchemeCodeUnspsc190501.42143200'),
	'identifierSchemeCodeUnspsc190501.42143201': __('identifierSchemeCodeUnspsc190501.42143201'),
	'identifierSchemeCodeUnspsc190501.42143203': __('identifierSchemeCodeUnspsc190501.42143203'),
	'identifierSchemeCodeUnspsc190501.42143204': __('identifierSchemeCodeUnspsc190501.42143204'),
	'identifierSchemeCodeUnspsc190501.42143205': __('identifierSchemeCodeUnspsc190501.42143205'),
	'identifierSchemeCodeUnspsc190501.42143206': __('identifierSchemeCodeUnspsc190501.42143206'),
	'identifierSchemeCodeUnspsc190501.42143207': __('identifierSchemeCodeUnspsc190501.42143207'),
	'identifierSchemeCodeUnspsc190501.42143208': __('identifierSchemeCodeUnspsc190501.42143208'),
	'identifierSchemeCodeUnspsc190501.42143209': __('identifierSchemeCodeUnspsc190501.42143209'),
	'identifierSchemeCodeUnspsc190501.42143210': __('identifierSchemeCodeUnspsc190501.42143210'),
	'identifierSchemeCodeUnspsc190501.42143211': __('identifierSchemeCodeUnspsc190501.42143211'),
	'identifierSchemeCodeUnspsc190501.42143212': __('identifierSchemeCodeUnspsc190501.42143212'),
	'identifierSchemeCodeUnspsc190501.42143213': __('identifierSchemeCodeUnspsc190501.42143213'),
	'identifierSchemeCodeUnspsc190501.42143300': __('identifierSchemeCodeUnspsc190501.42143300'),
	'identifierSchemeCodeUnspsc190501.42143301': __('identifierSchemeCodeUnspsc190501.42143301'),
	'identifierSchemeCodeUnspsc190501.42143302': __('identifierSchemeCodeUnspsc190501.42143302'),
	'identifierSchemeCodeUnspsc190501.42143303': __('identifierSchemeCodeUnspsc190501.42143303'),
	'identifierSchemeCodeUnspsc190501.42143304': __('identifierSchemeCodeUnspsc190501.42143304'),
	'identifierSchemeCodeUnspsc190501.42143305': __('identifierSchemeCodeUnspsc190501.42143305'),
	'identifierSchemeCodeUnspsc190501.42143306': __('identifierSchemeCodeUnspsc190501.42143306'),
	'identifierSchemeCodeUnspsc190501.42143307': __('identifierSchemeCodeUnspsc190501.42143307'),
	'identifierSchemeCodeUnspsc190501.42143308': __('identifierSchemeCodeUnspsc190501.42143308'),
	'identifierSchemeCodeUnspsc190501.42143309': __('identifierSchemeCodeUnspsc190501.42143309'),
	'identifierSchemeCodeUnspsc190501.42143310': __('identifierSchemeCodeUnspsc190501.42143310'),
	'identifierSchemeCodeUnspsc190501.42143311': __('identifierSchemeCodeUnspsc190501.42143311'),
	'identifierSchemeCodeUnspsc190501.42143400': __('identifierSchemeCodeUnspsc190501.42143400'),
	'identifierSchemeCodeUnspsc190501.42143401': __('identifierSchemeCodeUnspsc190501.42143401'),
	'identifierSchemeCodeUnspsc190501.42143402': __('identifierSchemeCodeUnspsc190501.42143402'),
	'identifierSchemeCodeUnspsc190501.42143403': __('identifierSchemeCodeUnspsc190501.42143403'),
	'identifierSchemeCodeUnspsc190501.42143404': __('identifierSchemeCodeUnspsc190501.42143404'),
	'identifierSchemeCodeUnspsc190501.42143500': __('identifierSchemeCodeUnspsc190501.42143500'),
	'identifierSchemeCodeUnspsc190501.42143501': __('identifierSchemeCodeUnspsc190501.42143501'),
	'identifierSchemeCodeUnspsc190501.42143502': __('identifierSchemeCodeUnspsc190501.42143502'),
	'identifierSchemeCodeUnspsc190501.42143503': __('identifierSchemeCodeUnspsc190501.42143503'),
	'identifierSchemeCodeUnspsc190501.42143504': __('identifierSchemeCodeUnspsc190501.42143504'),
	'identifierSchemeCodeUnspsc190501.42143505': __('identifierSchemeCodeUnspsc190501.42143505'),
	'identifierSchemeCodeUnspsc190501.42143506': __('identifierSchemeCodeUnspsc190501.42143506'),
	'identifierSchemeCodeUnspsc190501.42143507': __('identifierSchemeCodeUnspsc190501.42143507'),
	'identifierSchemeCodeUnspsc190501.42143508': __('identifierSchemeCodeUnspsc190501.42143508'),
	'identifierSchemeCodeUnspsc190501.42143509': __('identifierSchemeCodeUnspsc190501.42143509'),
	'identifierSchemeCodeUnspsc190501.42143510': __('identifierSchemeCodeUnspsc190501.42143510'),
	'identifierSchemeCodeUnspsc190501.42143511': __('identifierSchemeCodeUnspsc190501.42143511'),
	'identifierSchemeCodeUnspsc190501.42143512': __('identifierSchemeCodeUnspsc190501.42143512'),
	'identifierSchemeCodeUnspsc190501.42143513': __('identifierSchemeCodeUnspsc190501.42143513'),
	'identifierSchemeCodeUnspsc190501.42143514': __('identifierSchemeCodeUnspsc190501.42143514'),
	'identifierSchemeCodeUnspsc190501.42143515': __('identifierSchemeCodeUnspsc190501.42143515'),
	'identifierSchemeCodeUnspsc190501.42143516': __('identifierSchemeCodeUnspsc190501.42143516'),
	'identifierSchemeCodeUnspsc190501.42143517': __('identifierSchemeCodeUnspsc190501.42143517'),
	'identifierSchemeCodeUnspsc190501.42143518': __('identifierSchemeCodeUnspsc190501.42143518'),
	'identifierSchemeCodeUnspsc190501.42143519': __('identifierSchemeCodeUnspsc190501.42143519'),
	'identifierSchemeCodeUnspsc190501.42143520': __('identifierSchemeCodeUnspsc190501.42143520'),
	'identifierSchemeCodeUnspsc190501.42143521': __('identifierSchemeCodeUnspsc190501.42143521'),
	'identifierSchemeCodeUnspsc190501.42143522': __('identifierSchemeCodeUnspsc190501.42143522'),
	'identifierSchemeCodeUnspsc190501.42143523': __('identifierSchemeCodeUnspsc190501.42143523'),
	'identifierSchemeCodeUnspsc190501.42143524': __('identifierSchemeCodeUnspsc190501.42143524'),
	'identifierSchemeCodeUnspsc190501.42143525': __('identifierSchemeCodeUnspsc190501.42143525'),
	'identifierSchemeCodeUnspsc190501.42143526': __('identifierSchemeCodeUnspsc190501.42143526'),
	'identifierSchemeCodeUnspsc190501.42143527': __('identifierSchemeCodeUnspsc190501.42143527'),
	'identifierSchemeCodeUnspsc190501.42143528': __('identifierSchemeCodeUnspsc190501.42143528'),
	'identifierSchemeCodeUnspsc190501.42143529': __('identifierSchemeCodeUnspsc190501.42143529'),
	'identifierSchemeCodeUnspsc190501.42143530': __('identifierSchemeCodeUnspsc190501.42143530'),
	'identifierSchemeCodeUnspsc190501.42143531': __('identifierSchemeCodeUnspsc190501.42143531'),
	'identifierSchemeCodeUnspsc190501.42143532': __('identifierSchemeCodeUnspsc190501.42143532'),
	'identifierSchemeCodeUnspsc190501.42143533': __('identifierSchemeCodeUnspsc190501.42143533'),
	'identifierSchemeCodeUnspsc190501.42143600': __('identifierSchemeCodeUnspsc190501.42143600'),
	'identifierSchemeCodeUnspsc190501.42143601': __('identifierSchemeCodeUnspsc190501.42143601'),
	'identifierSchemeCodeUnspsc190501.42143602': __('identifierSchemeCodeUnspsc190501.42143602'),
	'identifierSchemeCodeUnspsc190501.42143603': __('identifierSchemeCodeUnspsc190501.42143603'),
	'identifierSchemeCodeUnspsc190501.42143604': __('identifierSchemeCodeUnspsc190501.42143604'),
	'identifierSchemeCodeUnspsc190501.42143605': __('identifierSchemeCodeUnspsc190501.42143605'),
	'identifierSchemeCodeUnspsc190501.42143606': __('identifierSchemeCodeUnspsc190501.42143606'),
	'identifierSchemeCodeUnspsc190501.42143607': __('identifierSchemeCodeUnspsc190501.42143607'),
	'identifierSchemeCodeUnspsc190501.42143608': __('identifierSchemeCodeUnspsc190501.42143608'),
	'identifierSchemeCodeUnspsc190501.42143609': __('identifierSchemeCodeUnspsc190501.42143609'),
	'identifierSchemeCodeUnspsc190501.42143610': __('identifierSchemeCodeUnspsc190501.42143610'),
	'identifierSchemeCodeUnspsc190501.42143611': __('identifierSchemeCodeUnspsc190501.42143611'),
	'identifierSchemeCodeUnspsc190501.42143612': __('identifierSchemeCodeUnspsc190501.42143612'),
	'identifierSchemeCodeUnspsc190501.42143700': __('identifierSchemeCodeUnspsc190501.42143700'),
	'identifierSchemeCodeUnspsc190501.42143701': __('identifierSchemeCodeUnspsc190501.42143701'),
	'identifierSchemeCodeUnspsc190501.42143702': __('identifierSchemeCodeUnspsc190501.42143702'),
	'identifierSchemeCodeUnspsc190501.42143703': __('identifierSchemeCodeUnspsc190501.42143703'),
	'identifierSchemeCodeUnspsc190501.42143704': __('identifierSchemeCodeUnspsc190501.42143704'),
	'identifierSchemeCodeUnspsc190501.42143705': __('identifierSchemeCodeUnspsc190501.42143705'),
	'identifierSchemeCodeUnspsc190501.42143706': __('identifierSchemeCodeUnspsc190501.42143706'),
	'identifierSchemeCodeUnspsc190501.42143707': __('identifierSchemeCodeUnspsc190501.42143707'),
	'identifierSchemeCodeUnspsc190501.42143708': __('identifierSchemeCodeUnspsc190501.42143708'),
	'identifierSchemeCodeUnspsc190501.42143709': __('identifierSchemeCodeUnspsc190501.42143709'),
	'identifierSchemeCodeUnspsc190501.42143710': __('identifierSchemeCodeUnspsc190501.42143710'),
	'identifierSchemeCodeUnspsc190501.42143711': __('identifierSchemeCodeUnspsc190501.42143711'),
	'identifierSchemeCodeUnspsc190501.42143712': __('identifierSchemeCodeUnspsc190501.42143712'),
	'identifierSchemeCodeUnspsc190501.42143713': __('identifierSchemeCodeUnspsc190501.42143713'),
	'identifierSchemeCodeUnspsc190501.42143714': __('identifierSchemeCodeUnspsc190501.42143714'),
	'identifierSchemeCodeUnspsc190501.42143800': __('identifierSchemeCodeUnspsc190501.42143800'),
	'identifierSchemeCodeUnspsc190501.42143801': __('identifierSchemeCodeUnspsc190501.42143801'),
	'identifierSchemeCodeUnspsc190501.42143802': __('identifierSchemeCodeUnspsc190501.42143802'),
	'identifierSchemeCodeUnspsc190501.42143803': __('identifierSchemeCodeUnspsc190501.42143803'),
	'identifierSchemeCodeUnspsc190501.42143804': __('identifierSchemeCodeUnspsc190501.42143804'),
	'identifierSchemeCodeUnspsc190501.42143805': __('identifierSchemeCodeUnspsc190501.42143805'),
	'identifierSchemeCodeUnspsc190501.42143806': __('identifierSchemeCodeUnspsc190501.42143806'),
	'identifierSchemeCodeUnspsc190501.42143807': __('identifierSchemeCodeUnspsc190501.42143807'),
	'identifierSchemeCodeUnspsc190501.42143808': __('identifierSchemeCodeUnspsc190501.42143808'),
	'identifierSchemeCodeUnspsc190501.42143809': __('identifierSchemeCodeUnspsc190501.42143809'),
	'identifierSchemeCodeUnspsc190501.42143810': __('identifierSchemeCodeUnspsc190501.42143810'),
	'identifierSchemeCodeUnspsc190501.42143900': __('identifierSchemeCodeUnspsc190501.42143900'),
	'identifierSchemeCodeUnspsc190501.42143901': __('identifierSchemeCodeUnspsc190501.42143901'),
	'identifierSchemeCodeUnspsc190501.42143902': __('identifierSchemeCodeUnspsc190501.42143902'),
	'identifierSchemeCodeUnspsc190501.42143903': __('identifierSchemeCodeUnspsc190501.42143903'),
	'identifierSchemeCodeUnspsc190501.42143904': __('identifierSchemeCodeUnspsc190501.42143904'),
	'identifierSchemeCodeUnspsc190501.42143905': __('identifierSchemeCodeUnspsc190501.42143905'),
	'identifierSchemeCodeUnspsc190501.42144000': __('identifierSchemeCodeUnspsc190501.42144000'),
	'identifierSchemeCodeUnspsc190501.42144001': __('identifierSchemeCodeUnspsc190501.42144001'),
	'identifierSchemeCodeUnspsc190501.42144002': __('identifierSchemeCodeUnspsc190501.42144002'),
	'identifierSchemeCodeUnspsc190501.42144003': __('identifierSchemeCodeUnspsc190501.42144003'),
	'identifierSchemeCodeUnspsc190501.42144004': __('identifierSchemeCodeUnspsc190501.42144004'),
	'identifierSchemeCodeUnspsc190501.42144005': __('identifierSchemeCodeUnspsc190501.42144005'),
	'identifierSchemeCodeUnspsc190501.42144006': __('identifierSchemeCodeUnspsc190501.42144006'),
	'identifierSchemeCodeUnspsc190501.42144100': __('identifierSchemeCodeUnspsc190501.42144100'),
	'identifierSchemeCodeUnspsc190501.42144101': __('identifierSchemeCodeUnspsc190501.42144101'),
	'identifierSchemeCodeUnspsc190501.42144102': __('identifierSchemeCodeUnspsc190501.42144102'),
	'identifierSchemeCodeUnspsc190501.42144103': __('identifierSchemeCodeUnspsc190501.42144103'),
	'identifierSchemeCodeUnspsc190501.42144104': __('identifierSchemeCodeUnspsc190501.42144104'),
	'identifierSchemeCodeUnspsc190501.42144200': __('identifierSchemeCodeUnspsc190501.42144200'),
	'identifierSchemeCodeUnspsc190501.42144201': __('identifierSchemeCodeUnspsc190501.42144201'),
	'identifierSchemeCodeUnspsc190501.42144202': __('identifierSchemeCodeUnspsc190501.42144202'),
	'identifierSchemeCodeUnspsc190501.42144203': __('identifierSchemeCodeUnspsc190501.42144203'),
	'identifierSchemeCodeUnspsc190501.42144204': __('identifierSchemeCodeUnspsc190501.42144204'),
	'identifierSchemeCodeUnspsc190501.42144205': __('identifierSchemeCodeUnspsc190501.42144205'),
	'identifierSchemeCodeUnspsc190501.42144206': __('identifierSchemeCodeUnspsc190501.42144206'),
	'identifierSchemeCodeUnspsc190501.42144207': __('identifierSchemeCodeUnspsc190501.42144207'),
	'identifierSchemeCodeUnspsc190501.42144208': __('identifierSchemeCodeUnspsc190501.42144208'),
	'identifierSchemeCodeUnspsc190501.42144209': __('identifierSchemeCodeUnspsc190501.42144209'),
	'identifierSchemeCodeUnspsc190501.42144300': __('identifierSchemeCodeUnspsc190501.42144300'),
	'identifierSchemeCodeUnspsc190501.42144301': __('identifierSchemeCodeUnspsc190501.42144301'),
	'identifierSchemeCodeUnspsc190501.42144302': __('identifierSchemeCodeUnspsc190501.42144302'),
	'identifierSchemeCodeUnspsc190501.42144400': __('identifierSchemeCodeUnspsc190501.42144400'),
	'identifierSchemeCodeUnspsc190501.42144401': __('identifierSchemeCodeUnspsc190501.42144401'),
	'identifierSchemeCodeUnspsc190501.42144402': __('identifierSchemeCodeUnspsc190501.42144402'),
	'identifierSchemeCodeUnspsc190501.42144403': __('identifierSchemeCodeUnspsc190501.42144403'),
	'identifierSchemeCodeUnspsc190501.42144404': __('identifierSchemeCodeUnspsc190501.42144404'),
	'identifierSchemeCodeUnspsc190501.42144405': __('identifierSchemeCodeUnspsc190501.42144405'),
	'identifierSchemeCodeUnspsc190501.42144406': __('identifierSchemeCodeUnspsc190501.42144406'),
	'identifierSchemeCodeUnspsc190501.42144407': __('identifierSchemeCodeUnspsc190501.42144407'),
	'identifierSchemeCodeUnspsc190501.42144408': __('identifierSchemeCodeUnspsc190501.42144408'),
	'identifierSchemeCodeUnspsc190501.42144409': __('identifierSchemeCodeUnspsc190501.42144409'),
	'identifierSchemeCodeUnspsc190501.42144500': __('identifierSchemeCodeUnspsc190501.42144500'),
	'identifierSchemeCodeUnspsc190501.42144501': __('identifierSchemeCodeUnspsc190501.42144501'),
	'identifierSchemeCodeUnspsc190501.42144502': __('identifierSchemeCodeUnspsc190501.42144502'),
	'identifierSchemeCodeUnspsc190501.42144503': __('identifierSchemeCodeUnspsc190501.42144503'),
	'identifierSchemeCodeUnspsc190501.42144504': __('identifierSchemeCodeUnspsc190501.42144504'),
	'identifierSchemeCodeUnspsc190501.42144505': __('identifierSchemeCodeUnspsc190501.42144505'),
	'identifierSchemeCodeUnspsc190501.42144506': __('identifierSchemeCodeUnspsc190501.42144506'),
	'identifierSchemeCodeUnspsc190501.42144507': __('identifierSchemeCodeUnspsc190501.42144507'),
	'identifierSchemeCodeUnspsc190501.42150000': __('identifierSchemeCodeUnspsc190501.42150000'),
	'identifierSchemeCodeUnspsc190501.42151500': __('identifierSchemeCodeUnspsc190501.42151500'),
	'identifierSchemeCodeUnspsc190501.42151501': __('identifierSchemeCodeUnspsc190501.42151501'),
	'identifierSchemeCodeUnspsc190501.42151502': __('identifierSchemeCodeUnspsc190501.42151502'),
	'identifierSchemeCodeUnspsc190501.42151503': __('identifierSchemeCodeUnspsc190501.42151503'),
	'identifierSchemeCodeUnspsc190501.42151504': __('identifierSchemeCodeUnspsc190501.42151504'),
	'identifierSchemeCodeUnspsc190501.42151505': __('identifierSchemeCodeUnspsc190501.42151505'),
	'identifierSchemeCodeUnspsc190501.42151506': __('identifierSchemeCodeUnspsc190501.42151506'),
	'identifierSchemeCodeUnspsc190501.42151507': __('identifierSchemeCodeUnspsc190501.42151507'),
	'identifierSchemeCodeUnspsc190501.42151508': __('identifierSchemeCodeUnspsc190501.42151508'),
	'identifierSchemeCodeUnspsc190501.42151509': __('identifierSchemeCodeUnspsc190501.42151509'),
	'identifierSchemeCodeUnspsc190501.42151510': __('identifierSchemeCodeUnspsc190501.42151510'),
	'identifierSchemeCodeUnspsc190501.42151511': __('identifierSchemeCodeUnspsc190501.42151511'),
	'identifierSchemeCodeUnspsc190501.42151512': __('identifierSchemeCodeUnspsc190501.42151512'),
	'identifierSchemeCodeUnspsc190501.42151600': __('identifierSchemeCodeUnspsc190501.42151600'),
	'identifierSchemeCodeUnspsc190501.42151603': __('identifierSchemeCodeUnspsc190501.42151603'),
	'identifierSchemeCodeUnspsc190501.42151604': __('identifierSchemeCodeUnspsc190501.42151604'),
	'identifierSchemeCodeUnspsc190501.42151605': __('identifierSchemeCodeUnspsc190501.42151605'),
	'identifierSchemeCodeUnspsc190501.42151606': __('identifierSchemeCodeUnspsc190501.42151606'),
	'identifierSchemeCodeUnspsc190501.42151610': __('identifierSchemeCodeUnspsc190501.42151610'),
	'identifierSchemeCodeUnspsc190501.42151611': __('identifierSchemeCodeUnspsc190501.42151611'),
	'identifierSchemeCodeUnspsc190501.42151612': __('identifierSchemeCodeUnspsc190501.42151612'),
	'identifierSchemeCodeUnspsc190501.42151613': __('identifierSchemeCodeUnspsc190501.42151613'),
	'identifierSchemeCodeUnspsc190501.42151614': __('identifierSchemeCodeUnspsc190501.42151614'),
	'identifierSchemeCodeUnspsc190501.42151615': __('identifierSchemeCodeUnspsc190501.42151615'),
	'identifierSchemeCodeUnspsc190501.42151616': __('identifierSchemeCodeUnspsc190501.42151616'),
	'identifierSchemeCodeUnspsc190501.42151617': __('identifierSchemeCodeUnspsc190501.42151617'),
	'identifierSchemeCodeUnspsc190501.42151618': __('identifierSchemeCodeUnspsc190501.42151618'),
	'identifierSchemeCodeUnspsc190501.42151619': __('identifierSchemeCodeUnspsc190501.42151619'),
	'identifierSchemeCodeUnspsc190501.42151620': __('identifierSchemeCodeUnspsc190501.42151620'),
	'identifierSchemeCodeUnspsc190501.42151621': __('identifierSchemeCodeUnspsc190501.42151621'),
	'identifierSchemeCodeUnspsc190501.42151623': __('identifierSchemeCodeUnspsc190501.42151623'),
	'identifierSchemeCodeUnspsc190501.42151624': __('identifierSchemeCodeUnspsc190501.42151624'),
	'identifierSchemeCodeUnspsc190501.42151625': __('identifierSchemeCodeUnspsc190501.42151625'),
	'identifierSchemeCodeUnspsc190501.42151627': __('identifierSchemeCodeUnspsc190501.42151627'),
	'identifierSchemeCodeUnspsc190501.42151628': __('identifierSchemeCodeUnspsc190501.42151628'),
	'identifierSchemeCodeUnspsc190501.42151629': __('identifierSchemeCodeUnspsc190501.42151629'),
	'identifierSchemeCodeUnspsc190501.42151630': __('identifierSchemeCodeUnspsc190501.42151630'),
	'identifierSchemeCodeUnspsc190501.42151631': __('identifierSchemeCodeUnspsc190501.42151631'),
	'identifierSchemeCodeUnspsc190501.42151632': __('identifierSchemeCodeUnspsc190501.42151632'),
	'identifierSchemeCodeUnspsc190501.42151633': __('identifierSchemeCodeUnspsc190501.42151633'),
	'identifierSchemeCodeUnspsc190501.42151634': __('identifierSchemeCodeUnspsc190501.42151634'),
	'identifierSchemeCodeUnspsc190501.42151635': __('identifierSchemeCodeUnspsc190501.42151635'),
	'identifierSchemeCodeUnspsc190501.42151636': __('identifierSchemeCodeUnspsc190501.42151636'),
	'identifierSchemeCodeUnspsc190501.42151637': __('identifierSchemeCodeUnspsc190501.42151637'),
	'identifierSchemeCodeUnspsc190501.42151638': __('identifierSchemeCodeUnspsc190501.42151638'),
	'identifierSchemeCodeUnspsc190501.42151639': __('identifierSchemeCodeUnspsc190501.42151639'),
	'identifierSchemeCodeUnspsc190501.42151640': __('identifierSchemeCodeUnspsc190501.42151640'),
	'identifierSchemeCodeUnspsc190501.42151641': __('identifierSchemeCodeUnspsc190501.42151641'),
	'identifierSchemeCodeUnspsc190501.42151642': __('identifierSchemeCodeUnspsc190501.42151642'),
	'identifierSchemeCodeUnspsc190501.42151643': __('identifierSchemeCodeUnspsc190501.42151643'),
	'identifierSchemeCodeUnspsc190501.42151644': __('identifierSchemeCodeUnspsc190501.42151644'),
	'identifierSchemeCodeUnspsc190501.42151646': __('identifierSchemeCodeUnspsc190501.42151646'),
	'identifierSchemeCodeUnspsc190501.42151647': __('identifierSchemeCodeUnspsc190501.42151647'),
	'identifierSchemeCodeUnspsc190501.42151648': __('identifierSchemeCodeUnspsc190501.42151648'),
	'identifierSchemeCodeUnspsc190501.42151650': __('identifierSchemeCodeUnspsc190501.42151650'),
	'identifierSchemeCodeUnspsc190501.42151651': __('identifierSchemeCodeUnspsc190501.42151651'),
	'identifierSchemeCodeUnspsc190501.42151652': __('identifierSchemeCodeUnspsc190501.42151652'),
	'identifierSchemeCodeUnspsc190501.42151653': __('identifierSchemeCodeUnspsc190501.42151653'),
	'identifierSchemeCodeUnspsc190501.42151654': __('identifierSchemeCodeUnspsc190501.42151654'),
	'identifierSchemeCodeUnspsc190501.42151655': __('identifierSchemeCodeUnspsc190501.42151655'),
	'identifierSchemeCodeUnspsc190501.42151656': __('identifierSchemeCodeUnspsc190501.42151656'),
	'identifierSchemeCodeUnspsc190501.42151658': __('identifierSchemeCodeUnspsc190501.42151658'),
	'identifierSchemeCodeUnspsc190501.42151660': __('identifierSchemeCodeUnspsc190501.42151660'),
	'identifierSchemeCodeUnspsc190501.42151661': __('identifierSchemeCodeUnspsc190501.42151661'),
	'identifierSchemeCodeUnspsc190501.42151665': __('identifierSchemeCodeUnspsc190501.42151665'),
	'identifierSchemeCodeUnspsc190501.42151669': __('identifierSchemeCodeUnspsc190501.42151669'),
	'identifierSchemeCodeUnspsc190501.42151670': __('identifierSchemeCodeUnspsc190501.42151670'),
	'identifierSchemeCodeUnspsc190501.42151672': __('identifierSchemeCodeUnspsc190501.42151672'),
	'identifierSchemeCodeUnspsc190501.42151673': __('identifierSchemeCodeUnspsc190501.42151673'),
	'identifierSchemeCodeUnspsc190501.42151676': __('identifierSchemeCodeUnspsc190501.42151676'),
	'identifierSchemeCodeUnspsc190501.42151682': __('identifierSchemeCodeUnspsc190501.42151682'),
	'identifierSchemeCodeUnspsc190501.42151683': __('identifierSchemeCodeUnspsc190501.42151683'),
	'identifierSchemeCodeUnspsc190501.42151684': __('identifierSchemeCodeUnspsc190501.42151684'),
	'identifierSchemeCodeUnspsc190501.42151685': __('identifierSchemeCodeUnspsc190501.42151685'),
	'identifierSchemeCodeUnspsc190501.42151686': __('identifierSchemeCodeUnspsc190501.42151686'),
	'identifierSchemeCodeUnspsc190501.42151687': __('identifierSchemeCodeUnspsc190501.42151687'),
	'identifierSchemeCodeUnspsc190501.42151688': __('identifierSchemeCodeUnspsc190501.42151688'),
	'identifierSchemeCodeUnspsc190501.42151689': __('identifierSchemeCodeUnspsc190501.42151689'),
	'identifierSchemeCodeUnspsc190501.42151690': __('identifierSchemeCodeUnspsc190501.42151690'),
	'identifierSchemeCodeUnspsc190501.42151691': __('identifierSchemeCodeUnspsc190501.42151691'),
	'identifierSchemeCodeUnspsc190501.42151692': __('identifierSchemeCodeUnspsc190501.42151692'),
	'identifierSchemeCodeUnspsc190501.42151693': __('identifierSchemeCodeUnspsc190501.42151693'),
	'identifierSchemeCodeUnspsc190501.42151694': __('identifierSchemeCodeUnspsc190501.42151694'),
	'identifierSchemeCodeUnspsc190501.42151695': __('identifierSchemeCodeUnspsc190501.42151695'),
	'identifierSchemeCodeUnspsc190501.42151696': __('identifierSchemeCodeUnspsc190501.42151696'),
	'identifierSchemeCodeUnspsc190501.42151697': __('identifierSchemeCodeUnspsc190501.42151697'),
	'identifierSchemeCodeUnspsc190501.42151700': __('identifierSchemeCodeUnspsc190501.42151700'),
	'identifierSchemeCodeUnspsc190501.42151701': __('identifierSchemeCodeUnspsc190501.42151701'),
	'identifierSchemeCodeUnspsc190501.42151702': __('identifierSchemeCodeUnspsc190501.42151702'),
	'identifierSchemeCodeUnspsc190501.42151703': __('identifierSchemeCodeUnspsc190501.42151703'),
	'identifierSchemeCodeUnspsc190501.42151704': __('identifierSchemeCodeUnspsc190501.42151704'),
	'identifierSchemeCodeUnspsc190501.42151705': __('identifierSchemeCodeUnspsc190501.42151705'),
	'identifierSchemeCodeUnspsc190501.42151706': __('identifierSchemeCodeUnspsc190501.42151706'),
	'identifierSchemeCodeUnspsc190501.42151707': __('identifierSchemeCodeUnspsc190501.42151707'),
	'identifierSchemeCodeUnspsc190501.42151708': __('identifierSchemeCodeUnspsc190501.42151708'),
	'identifierSchemeCodeUnspsc190501.42151709': __('identifierSchemeCodeUnspsc190501.42151709'),
	'identifierSchemeCodeUnspsc190501.42151800': __('identifierSchemeCodeUnspsc190501.42151800'),
	'identifierSchemeCodeUnspsc190501.42151802': __('identifierSchemeCodeUnspsc190501.42151802'),
	'identifierSchemeCodeUnspsc190501.42151803': __('identifierSchemeCodeUnspsc190501.42151803'),
	'identifierSchemeCodeUnspsc190501.42151804': __('identifierSchemeCodeUnspsc190501.42151804'),
	'identifierSchemeCodeUnspsc190501.42151805': __('identifierSchemeCodeUnspsc190501.42151805'),
	'identifierSchemeCodeUnspsc190501.42151806': __('identifierSchemeCodeUnspsc190501.42151806'),
	'identifierSchemeCodeUnspsc190501.42151807': __('identifierSchemeCodeUnspsc190501.42151807'),
	'identifierSchemeCodeUnspsc190501.42151808': __('identifierSchemeCodeUnspsc190501.42151808'),
	'identifierSchemeCodeUnspsc190501.42151809': __('identifierSchemeCodeUnspsc190501.42151809'),
	'identifierSchemeCodeUnspsc190501.42151810': __('identifierSchemeCodeUnspsc190501.42151810'),
	'identifierSchemeCodeUnspsc190501.42151811': __('identifierSchemeCodeUnspsc190501.42151811'),
	'identifierSchemeCodeUnspsc190501.42151812': __('identifierSchemeCodeUnspsc190501.42151812'),
	'identifierSchemeCodeUnspsc190501.42151813': __('identifierSchemeCodeUnspsc190501.42151813'),
	'identifierSchemeCodeUnspsc190501.42151814': __('identifierSchemeCodeUnspsc190501.42151814'),
	'identifierSchemeCodeUnspsc190501.42151815': __('identifierSchemeCodeUnspsc190501.42151815'),
	'identifierSchemeCodeUnspsc190501.42151817': __('identifierSchemeCodeUnspsc190501.42151817'),
	'identifierSchemeCodeUnspsc190501.42151818': __('identifierSchemeCodeUnspsc190501.42151818'),
	'identifierSchemeCodeUnspsc190501.42151900': __('identifierSchemeCodeUnspsc190501.42151900'),
	'identifierSchemeCodeUnspsc190501.42151901': __('identifierSchemeCodeUnspsc190501.42151901'),
	'identifierSchemeCodeUnspsc190501.42151902': __('identifierSchemeCodeUnspsc190501.42151902'),
	'identifierSchemeCodeUnspsc190501.42151903': __('identifierSchemeCodeUnspsc190501.42151903'),
	'identifierSchemeCodeUnspsc190501.42151904': __('identifierSchemeCodeUnspsc190501.42151904'),
	'identifierSchemeCodeUnspsc190501.42151905': __('identifierSchemeCodeUnspsc190501.42151905'),
	'identifierSchemeCodeUnspsc190501.42151906': __('identifierSchemeCodeUnspsc190501.42151906'),
	'identifierSchemeCodeUnspsc190501.42151907': __('identifierSchemeCodeUnspsc190501.42151907'),
	'identifierSchemeCodeUnspsc190501.42151908': __('identifierSchemeCodeUnspsc190501.42151908'),
	'identifierSchemeCodeUnspsc190501.42151909': __('identifierSchemeCodeUnspsc190501.42151909'),
	'identifierSchemeCodeUnspsc190501.42151910': __('identifierSchemeCodeUnspsc190501.42151910'),
	'identifierSchemeCodeUnspsc190501.42151911': __('identifierSchemeCodeUnspsc190501.42151911'),
	'identifierSchemeCodeUnspsc190501.42151912': __('identifierSchemeCodeUnspsc190501.42151912'),
	'identifierSchemeCodeUnspsc190501.42151913': __('identifierSchemeCodeUnspsc190501.42151913'),
	'identifierSchemeCodeUnspsc190501.42151914': __('identifierSchemeCodeUnspsc190501.42151914'),
	'identifierSchemeCodeUnspsc190501.42152000': __('identifierSchemeCodeUnspsc190501.42152000'),
	'identifierSchemeCodeUnspsc190501.42152001': __('identifierSchemeCodeUnspsc190501.42152001'),
	'identifierSchemeCodeUnspsc190501.42152002': __('identifierSchemeCodeUnspsc190501.42152002'),
	'identifierSchemeCodeUnspsc190501.42152003': __('identifierSchemeCodeUnspsc190501.42152003'),
	'identifierSchemeCodeUnspsc190501.42152004': __('identifierSchemeCodeUnspsc190501.42152004'),
	'identifierSchemeCodeUnspsc190501.42152005': __('identifierSchemeCodeUnspsc190501.42152005'),
	'identifierSchemeCodeUnspsc190501.42152006': __('identifierSchemeCodeUnspsc190501.42152006'),
	'identifierSchemeCodeUnspsc190501.42152007': __('identifierSchemeCodeUnspsc190501.42152007'),
	'identifierSchemeCodeUnspsc190501.42152008': __('identifierSchemeCodeUnspsc190501.42152008'),
	'identifierSchemeCodeUnspsc190501.42152009': __('identifierSchemeCodeUnspsc190501.42152009'),
	'identifierSchemeCodeUnspsc190501.42152010': __('identifierSchemeCodeUnspsc190501.42152010'),
	'identifierSchemeCodeUnspsc190501.42152011': __('identifierSchemeCodeUnspsc190501.42152011'),
	'identifierSchemeCodeUnspsc190501.42152012': __('identifierSchemeCodeUnspsc190501.42152012'),
	'identifierSchemeCodeUnspsc190501.42152013': __('identifierSchemeCodeUnspsc190501.42152013'),
	'identifierSchemeCodeUnspsc190501.42152014': __('identifierSchemeCodeUnspsc190501.42152014'),
	'identifierSchemeCodeUnspsc190501.42152015': __('identifierSchemeCodeUnspsc190501.42152015'),
	'identifierSchemeCodeUnspsc190501.42152016': __('identifierSchemeCodeUnspsc190501.42152016'),
	'identifierSchemeCodeUnspsc190501.42152017': __('identifierSchemeCodeUnspsc190501.42152017'),
	'identifierSchemeCodeUnspsc190501.42152018': __('identifierSchemeCodeUnspsc190501.42152018'),
	'identifierSchemeCodeUnspsc190501.42152100': __('identifierSchemeCodeUnspsc190501.42152100'),
	'identifierSchemeCodeUnspsc190501.42152101': __('identifierSchemeCodeUnspsc190501.42152101'),
	'identifierSchemeCodeUnspsc190501.42152102': __('identifierSchemeCodeUnspsc190501.42152102'),
	'identifierSchemeCodeUnspsc190501.42152103': __('identifierSchemeCodeUnspsc190501.42152103'),
	'identifierSchemeCodeUnspsc190501.42152104': __('identifierSchemeCodeUnspsc190501.42152104'),
	'identifierSchemeCodeUnspsc190501.42152105': __('identifierSchemeCodeUnspsc190501.42152105'),
	'identifierSchemeCodeUnspsc190501.42152106': __('identifierSchemeCodeUnspsc190501.42152106'),
	'identifierSchemeCodeUnspsc190501.42152107': __('identifierSchemeCodeUnspsc190501.42152107'),
	'identifierSchemeCodeUnspsc190501.42152108': __('identifierSchemeCodeUnspsc190501.42152108'),
	'identifierSchemeCodeUnspsc190501.42152109': __('identifierSchemeCodeUnspsc190501.42152109'),
	'identifierSchemeCodeUnspsc190501.42152110': __('identifierSchemeCodeUnspsc190501.42152110'),
	'identifierSchemeCodeUnspsc190501.42152111': __('identifierSchemeCodeUnspsc190501.42152111'),
	'identifierSchemeCodeUnspsc190501.42152112': __('identifierSchemeCodeUnspsc190501.42152112'),
	'identifierSchemeCodeUnspsc190501.42152113': __('identifierSchemeCodeUnspsc190501.42152113'),
	'identifierSchemeCodeUnspsc190501.42152114': __('identifierSchemeCodeUnspsc190501.42152114'),
	'identifierSchemeCodeUnspsc190501.42152115': __('identifierSchemeCodeUnspsc190501.42152115'),
	'identifierSchemeCodeUnspsc190501.42152116': __('identifierSchemeCodeUnspsc190501.42152116'),
	'identifierSchemeCodeUnspsc190501.42152117': __('identifierSchemeCodeUnspsc190501.42152117'),
	'identifierSchemeCodeUnspsc190501.42152118': __('identifierSchemeCodeUnspsc190501.42152118'),
	'identifierSchemeCodeUnspsc190501.42152119': __('identifierSchemeCodeUnspsc190501.42152119'),
	'identifierSchemeCodeUnspsc190501.42152120': __('identifierSchemeCodeUnspsc190501.42152120'),
	'identifierSchemeCodeUnspsc190501.42152200': __('identifierSchemeCodeUnspsc190501.42152200'),
	'identifierSchemeCodeUnspsc190501.42152201': __('identifierSchemeCodeUnspsc190501.42152201'),
	'identifierSchemeCodeUnspsc190501.42152202': __('identifierSchemeCodeUnspsc190501.42152202'),
	'identifierSchemeCodeUnspsc190501.42152203': __('identifierSchemeCodeUnspsc190501.42152203'),
	'identifierSchemeCodeUnspsc190501.42152204': __('identifierSchemeCodeUnspsc190501.42152204'),
	'identifierSchemeCodeUnspsc190501.42152205': __('identifierSchemeCodeUnspsc190501.42152205'),
	'identifierSchemeCodeUnspsc190501.42152206': __('identifierSchemeCodeUnspsc190501.42152206'),
	'identifierSchemeCodeUnspsc190501.42152207': __('identifierSchemeCodeUnspsc190501.42152207'),
	'identifierSchemeCodeUnspsc190501.42152208': __('identifierSchemeCodeUnspsc190501.42152208'),
	'identifierSchemeCodeUnspsc190501.42152209': __('identifierSchemeCodeUnspsc190501.42152209'),
	'identifierSchemeCodeUnspsc190501.42152210': __('identifierSchemeCodeUnspsc190501.42152210'),
	'identifierSchemeCodeUnspsc190501.42152211': __('identifierSchemeCodeUnspsc190501.42152211'),
	'identifierSchemeCodeUnspsc190501.42152212': __('identifierSchemeCodeUnspsc190501.42152212'),
	'identifierSchemeCodeUnspsc190501.42152213': __('identifierSchemeCodeUnspsc190501.42152213'),
	'identifierSchemeCodeUnspsc190501.42152214': __('identifierSchemeCodeUnspsc190501.42152214'),
	'identifierSchemeCodeUnspsc190501.42152215': __('identifierSchemeCodeUnspsc190501.42152215'),
	'identifierSchemeCodeUnspsc190501.42152216': __('identifierSchemeCodeUnspsc190501.42152216'),
	'identifierSchemeCodeUnspsc190501.42152217': __('identifierSchemeCodeUnspsc190501.42152217'),
	'identifierSchemeCodeUnspsc190501.42152218': __('identifierSchemeCodeUnspsc190501.42152218'),
	'identifierSchemeCodeUnspsc190501.42152219': __('identifierSchemeCodeUnspsc190501.42152219'),
	'identifierSchemeCodeUnspsc190501.42152220': __('identifierSchemeCodeUnspsc190501.42152220'),
	'identifierSchemeCodeUnspsc190501.42152221': __('identifierSchemeCodeUnspsc190501.42152221'),
	'identifierSchemeCodeUnspsc190501.42152222': __('identifierSchemeCodeUnspsc190501.42152222'),
	'identifierSchemeCodeUnspsc190501.42152223': __('identifierSchemeCodeUnspsc190501.42152223'),
	'identifierSchemeCodeUnspsc190501.42152224': __('identifierSchemeCodeUnspsc190501.42152224'),
	'identifierSchemeCodeUnspsc190501.42152225': __('identifierSchemeCodeUnspsc190501.42152225'),
	'identifierSchemeCodeUnspsc190501.42152226': __('identifierSchemeCodeUnspsc190501.42152226'),
	'identifierSchemeCodeUnspsc190501.42152227': __('identifierSchemeCodeUnspsc190501.42152227'),
	'identifierSchemeCodeUnspsc190501.42152228': __('identifierSchemeCodeUnspsc190501.42152228'),
	'identifierSchemeCodeUnspsc190501.42152229': __('identifierSchemeCodeUnspsc190501.42152229'),
	'identifierSchemeCodeUnspsc190501.42152230': __('identifierSchemeCodeUnspsc190501.42152230'),
	'identifierSchemeCodeUnspsc190501.42152231': __('identifierSchemeCodeUnspsc190501.42152231'),
	'identifierSchemeCodeUnspsc190501.42152232': __('identifierSchemeCodeUnspsc190501.42152232'),
	'identifierSchemeCodeUnspsc190501.42152233': __('identifierSchemeCodeUnspsc190501.42152233'),
	'identifierSchemeCodeUnspsc190501.42152234': __('identifierSchemeCodeUnspsc190501.42152234'),
	'identifierSchemeCodeUnspsc190501.42152235': __('identifierSchemeCodeUnspsc190501.42152235'),
	'identifierSchemeCodeUnspsc190501.42152236': __('identifierSchemeCodeUnspsc190501.42152236'),
	'identifierSchemeCodeUnspsc190501.42152237': __('identifierSchemeCodeUnspsc190501.42152237'),
	'identifierSchemeCodeUnspsc190501.42152238': __('identifierSchemeCodeUnspsc190501.42152238'),
	'identifierSchemeCodeUnspsc190501.42152239': __('identifierSchemeCodeUnspsc190501.42152239'),
	'identifierSchemeCodeUnspsc190501.42152240': __('identifierSchemeCodeUnspsc190501.42152240'),
	'identifierSchemeCodeUnspsc190501.42152241': __('identifierSchemeCodeUnspsc190501.42152241'),
	'identifierSchemeCodeUnspsc190501.42152242': __('identifierSchemeCodeUnspsc190501.42152242'),
	'identifierSchemeCodeUnspsc190501.42152300': __('identifierSchemeCodeUnspsc190501.42152300'),
	'identifierSchemeCodeUnspsc190501.42152301': __('identifierSchemeCodeUnspsc190501.42152301'),
	'identifierSchemeCodeUnspsc190501.42152302': __('identifierSchemeCodeUnspsc190501.42152302'),
	'identifierSchemeCodeUnspsc190501.42152303': __('identifierSchemeCodeUnspsc190501.42152303'),
	'identifierSchemeCodeUnspsc190501.42152304': __('identifierSchemeCodeUnspsc190501.42152304'),
	'identifierSchemeCodeUnspsc190501.42152308': __('identifierSchemeCodeUnspsc190501.42152308'),
	'identifierSchemeCodeUnspsc190501.42152309': __('identifierSchemeCodeUnspsc190501.42152309'),
	'identifierSchemeCodeUnspsc190501.42152310': __('identifierSchemeCodeUnspsc190501.42152310'),
	'identifierSchemeCodeUnspsc190501.42152311': __('identifierSchemeCodeUnspsc190501.42152311'),
	'identifierSchemeCodeUnspsc190501.42152400': __('identifierSchemeCodeUnspsc190501.42152400'),
	'identifierSchemeCodeUnspsc190501.42152402': __('identifierSchemeCodeUnspsc190501.42152402'),
	'identifierSchemeCodeUnspsc190501.42152405': __('identifierSchemeCodeUnspsc190501.42152405'),
	'identifierSchemeCodeUnspsc190501.42152412': __('identifierSchemeCodeUnspsc190501.42152412'),
	'identifierSchemeCodeUnspsc190501.42152415': __('identifierSchemeCodeUnspsc190501.42152415'),
	'identifierSchemeCodeUnspsc190501.42152418': __('identifierSchemeCodeUnspsc190501.42152418'),
	'identifierSchemeCodeUnspsc190501.42152423': __('identifierSchemeCodeUnspsc190501.42152423'),
	'identifierSchemeCodeUnspsc190501.42152424': __('identifierSchemeCodeUnspsc190501.42152424'),
	'identifierSchemeCodeUnspsc190501.42152425': __('identifierSchemeCodeUnspsc190501.42152425'),
	'identifierSchemeCodeUnspsc190501.42152434': __('identifierSchemeCodeUnspsc190501.42152434'),
	'identifierSchemeCodeUnspsc190501.42152435': __('identifierSchemeCodeUnspsc190501.42152435'),
	'identifierSchemeCodeUnspsc190501.42152438': __('identifierSchemeCodeUnspsc190501.42152438'),
	'identifierSchemeCodeUnspsc190501.42152444': __('identifierSchemeCodeUnspsc190501.42152444'),
	'identifierSchemeCodeUnspsc190501.42152445': __('identifierSchemeCodeUnspsc190501.42152445'),
	'identifierSchemeCodeUnspsc190501.42152446': __('identifierSchemeCodeUnspsc190501.42152446'),
	'identifierSchemeCodeUnspsc190501.42152447': __('identifierSchemeCodeUnspsc190501.42152447'),
	'identifierSchemeCodeUnspsc190501.42152449': __('identifierSchemeCodeUnspsc190501.42152449'),
	'identifierSchemeCodeUnspsc190501.42152451': __('identifierSchemeCodeUnspsc190501.42152451'),
	'identifierSchemeCodeUnspsc190501.42152452': __('identifierSchemeCodeUnspsc190501.42152452'),
	'identifierSchemeCodeUnspsc190501.42152453': __('identifierSchemeCodeUnspsc190501.42152453'),
	'identifierSchemeCodeUnspsc190501.42152454': __('identifierSchemeCodeUnspsc190501.42152454'),
	'identifierSchemeCodeUnspsc190501.42152456': __('identifierSchemeCodeUnspsc190501.42152456'),
	'identifierSchemeCodeUnspsc190501.42152457': __('identifierSchemeCodeUnspsc190501.42152457'),
	'identifierSchemeCodeUnspsc190501.42152458': __('identifierSchemeCodeUnspsc190501.42152458'),
	'identifierSchemeCodeUnspsc190501.42152460': __('identifierSchemeCodeUnspsc190501.42152460'),
	'identifierSchemeCodeUnspsc190501.42152461': __('identifierSchemeCodeUnspsc190501.42152461'),
	'identifierSchemeCodeUnspsc190501.42152462': __('identifierSchemeCodeUnspsc190501.42152462'),
	'identifierSchemeCodeUnspsc190501.42152463': __('identifierSchemeCodeUnspsc190501.42152463'),
	'identifierSchemeCodeUnspsc190501.42152464': __('identifierSchemeCodeUnspsc190501.42152464'),
	'identifierSchemeCodeUnspsc190501.42152465': __('identifierSchemeCodeUnspsc190501.42152465'),
	'identifierSchemeCodeUnspsc190501.42152466': __('identifierSchemeCodeUnspsc190501.42152466'),
	'identifierSchemeCodeUnspsc190501.42152468': __('identifierSchemeCodeUnspsc190501.42152468'),
	'identifierSchemeCodeUnspsc190501.42152470': __('identifierSchemeCodeUnspsc190501.42152470'),
	'identifierSchemeCodeUnspsc190501.42152471': __('identifierSchemeCodeUnspsc190501.42152471'),
	'identifierSchemeCodeUnspsc190501.42152472': __('identifierSchemeCodeUnspsc190501.42152472'),
	'identifierSchemeCodeUnspsc190501.42152473': __('identifierSchemeCodeUnspsc190501.42152473'),
	'identifierSchemeCodeUnspsc190501.42152474': __('identifierSchemeCodeUnspsc190501.42152474'),
	'identifierSchemeCodeUnspsc190501.42152475': __('identifierSchemeCodeUnspsc190501.42152475'),
	'identifierSchemeCodeUnspsc190501.42152476': __('identifierSchemeCodeUnspsc190501.42152476'),
	'identifierSchemeCodeUnspsc190501.42152477': __('identifierSchemeCodeUnspsc190501.42152477'),
	'identifierSchemeCodeUnspsc190501.42152478': __('identifierSchemeCodeUnspsc190501.42152478'),
	'identifierSchemeCodeUnspsc190501.42152479': __('identifierSchemeCodeUnspsc190501.42152479'),
	'identifierSchemeCodeUnspsc190501.42152480': __('identifierSchemeCodeUnspsc190501.42152480'),
	'identifierSchemeCodeUnspsc190501.42152481': __('identifierSchemeCodeUnspsc190501.42152481'),
	'identifierSchemeCodeUnspsc190501.42152482': __('identifierSchemeCodeUnspsc190501.42152482'),
	'identifierSchemeCodeUnspsc190501.42152483': __('identifierSchemeCodeUnspsc190501.42152483'),
	'identifierSchemeCodeUnspsc190501.42152484': __('identifierSchemeCodeUnspsc190501.42152484'),
	'identifierSchemeCodeUnspsc190501.42152485': __('identifierSchemeCodeUnspsc190501.42152485'),
	'identifierSchemeCodeUnspsc190501.42152500': __('identifierSchemeCodeUnspsc190501.42152500'),
	'identifierSchemeCodeUnspsc190501.42152501': __('identifierSchemeCodeUnspsc190501.42152501'),
	'identifierSchemeCodeUnspsc190501.42152502': __('identifierSchemeCodeUnspsc190501.42152502'),
	'identifierSchemeCodeUnspsc190501.42152503': __('identifierSchemeCodeUnspsc190501.42152503'),
	'identifierSchemeCodeUnspsc190501.42152504': __('identifierSchemeCodeUnspsc190501.42152504'),
	'identifierSchemeCodeUnspsc190501.42152505': __('identifierSchemeCodeUnspsc190501.42152505'),
	'identifierSchemeCodeUnspsc190501.42152506': __('identifierSchemeCodeUnspsc190501.42152506'),
	'identifierSchemeCodeUnspsc190501.42152507': __('identifierSchemeCodeUnspsc190501.42152507'),
	'identifierSchemeCodeUnspsc190501.42152508': __('identifierSchemeCodeUnspsc190501.42152508'),
	'identifierSchemeCodeUnspsc190501.42152509': __('identifierSchemeCodeUnspsc190501.42152509'),
	'identifierSchemeCodeUnspsc190501.42152511': __('identifierSchemeCodeUnspsc190501.42152511'),
	'identifierSchemeCodeUnspsc190501.42152512': __('identifierSchemeCodeUnspsc190501.42152512'),
	'identifierSchemeCodeUnspsc190501.42152513': __('identifierSchemeCodeUnspsc190501.42152513'),
	'identifierSchemeCodeUnspsc190501.42152516': __('identifierSchemeCodeUnspsc190501.42152516'),
	'identifierSchemeCodeUnspsc190501.42152517': __('identifierSchemeCodeUnspsc190501.42152517'),
	'identifierSchemeCodeUnspsc190501.42152518': __('identifierSchemeCodeUnspsc190501.42152518'),
	'identifierSchemeCodeUnspsc190501.42152519': __('identifierSchemeCodeUnspsc190501.42152519'),
	'identifierSchemeCodeUnspsc190501.42152520': __('identifierSchemeCodeUnspsc190501.42152520'),
	'identifierSchemeCodeUnspsc190501.42152521': __('identifierSchemeCodeUnspsc190501.42152521'),
	'identifierSchemeCodeUnspsc190501.42152522': __('identifierSchemeCodeUnspsc190501.42152522'),
	'identifierSchemeCodeUnspsc190501.42152600': __('identifierSchemeCodeUnspsc190501.42152600'),
	'identifierSchemeCodeUnspsc190501.42152601': __('identifierSchemeCodeUnspsc190501.42152601'),
	'identifierSchemeCodeUnspsc190501.42152602': __('identifierSchemeCodeUnspsc190501.42152602'),
	'identifierSchemeCodeUnspsc190501.42152603': __('identifierSchemeCodeUnspsc190501.42152603'),
	'identifierSchemeCodeUnspsc190501.42152604': __('identifierSchemeCodeUnspsc190501.42152604'),
	'identifierSchemeCodeUnspsc190501.42152605': __('identifierSchemeCodeUnspsc190501.42152605'),
	'identifierSchemeCodeUnspsc190501.42152606': __('identifierSchemeCodeUnspsc190501.42152606'),
	'identifierSchemeCodeUnspsc190501.42152607': __('identifierSchemeCodeUnspsc190501.42152607'),
	'identifierSchemeCodeUnspsc190501.42152608': __('identifierSchemeCodeUnspsc190501.42152608'),
	'identifierSchemeCodeUnspsc190501.42152609': __('identifierSchemeCodeUnspsc190501.42152609'),
	'identifierSchemeCodeUnspsc190501.42152610': __('identifierSchemeCodeUnspsc190501.42152610'),
	'identifierSchemeCodeUnspsc190501.42152700': __('identifierSchemeCodeUnspsc190501.42152700'),
	'identifierSchemeCodeUnspsc190501.42152701': __('identifierSchemeCodeUnspsc190501.42152701'),
	'identifierSchemeCodeUnspsc190501.42152704': __('identifierSchemeCodeUnspsc190501.42152704'),
	'identifierSchemeCodeUnspsc190501.42152705': __('identifierSchemeCodeUnspsc190501.42152705'),
	'identifierSchemeCodeUnspsc190501.42152706': __('identifierSchemeCodeUnspsc190501.42152706'),
	'identifierSchemeCodeUnspsc190501.42152707': __('identifierSchemeCodeUnspsc190501.42152707'),
	'identifierSchemeCodeUnspsc190501.42152708': __('identifierSchemeCodeUnspsc190501.42152708'),
	'identifierSchemeCodeUnspsc190501.42152709': __('identifierSchemeCodeUnspsc190501.42152709'),
	'identifierSchemeCodeUnspsc190501.42152710': __('identifierSchemeCodeUnspsc190501.42152710'),
	'identifierSchemeCodeUnspsc190501.42152711': __('identifierSchemeCodeUnspsc190501.42152711'),
	'identifierSchemeCodeUnspsc190501.42152712': __('identifierSchemeCodeUnspsc190501.42152712'),
	'identifierSchemeCodeUnspsc190501.42152713': __('identifierSchemeCodeUnspsc190501.42152713'),
	'identifierSchemeCodeUnspsc190501.42152714': __('identifierSchemeCodeUnspsc190501.42152714'),
	'identifierSchemeCodeUnspsc190501.42152715': __('identifierSchemeCodeUnspsc190501.42152715'),
	'identifierSchemeCodeUnspsc190501.42152716': __('identifierSchemeCodeUnspsc190501.42152716'),
	'identifierSchemeCodeUnspsc190501.42152717': __('identifierSchemeCodeUnspsc190501.42152717'),
	'identifierSchemeCodeUnspsc190501.42152718': __('identifierSchemeCodeUnspsc190501.42152718'),
	'identifierSchemeCodeUnspsc190501.42152719': __('identifierSchemeCodeUnspsc190501.42152719'),
	'identifierSchemeCodeUnspsc190501.42152720': __('identifierSchemeCodeUnspsc190501.42152720'),
	'identifierSchemeCodeUnspsc190501.42152721': __('identifierSchemeCodeUnspsc190501.42152721'),
	'identifierSchemeCodeUnspsc190501.42152722': __('identifierSchemeCodeUnspsc190501.42152722'),
	'identifierSchemeCodeUnspsc190501.42152723': __('identifierSchemeCodeUnspsc190501.42152723'),
	'identifierSchemeCodeUnspsc190501.42152800': __('identifierSchemeCodeUnspsc190501.42152800'),
	'identifierSchemeCodeUnspsc190501.42152801': __('identifierSchemeCodeUnspsc190501.42152801'),
	'identifierSchemeCodeUnspsc190501.42152802': __('identifierSchemeCodeUnspsc190501.42152802'),
	'identifierSchemeCodeUnspsc190501.42152803': __('identifierSchemeCodeUnspsc190501.42152803'),
	'identifierSchemeCodeUnspsc190501.42152804': __('identifierSchemeCodeUnspsc190501.42152804'),
	'identifierSchemeCodeUnspsc190501.42152805': __('identifierSchemeCodeUnspsc190501.42152805'),
	'identifierSchemeCodeUnspsc190501.42152806': __('identifierSchemeCodeUnspsc190501.42152806'),
	'identifierSchemeCodeUnspsc190501.42152807': __('identifierSchemeCodeUnspsc190501.42152807'),
	'identifierSchemeCodeUnspsc190501.42152808': __('identifierSchemeCodeUnspsc190501.42152808'),
	'identifierSchemeCodeUnspsc190501.42152809': __('identifierSchemeCodeUnspsc190501.42152809'),
	'identifierSchemeCodeUnspsc190501.42152810': __('identifierSchemeCodeUnspsc190501.42152810'),
	'identifierSchemeCodeUnspsc190501.42152811': __('identifierSchemeCodeUnspsc190501.42152811'),
	'identifierSchemeCodeUnspsc190501.42152900': __('identifierSchemeCodeUnspsc190501.42152900'),
	'identifierSchemeCodeUnspsc190501.42152901': __('identifierSchemeCodeUnspsc190501.42152901'),
	'identifierSchemeCodeUnspsc190501.42152902': __('identifierSchemeCodeUnspsc190501.42152902'),
	'identifierSchemeCodeUnspsc190501.42152903': __('identifierSchemeCodeUnspsc190501.42152903'),
	'identifierSchemeCodeUnspsc190501.42152904': __('identifierSchemeCodeUnspsc190501.42152904'),
	'identifierSchemeCodeUnspsc190501.42152905': __('identifierSchemeCodeUnspsc190501.42152905'),
	'identifierSchemeCodeUnspsc190501.42152906': __('identifierSchemeCodeUnspsc190501.42152906'),
	'identifierSchemeCodeUnspsc190501.42152907': __('identifierSchemeCodeUnspsc190501.42152907'),
	'identifierSchemeCodeUnspsc190501.42152908': __('identifierSchemeCodeUnspsc190501.42152908'),
	'identifierSchemeCodeUnspsc190501.42152909': __('identifierSchemeCodeUnspsc190501.42152909'),
	'identifierSchemeCodeUnspsc190501.42152910': __('identifierSchemeCodeUnspsc190501.42152910'),
	'identifierSchemeCodeUnspsc190501.42152911': __('identifierSchemeCodeUnspsc190501.42152911'),
	'identifierSchemeCodeUnspsc190501.42152912': __('identifierSchemeCodeUnspsc190501.42152912'),
	'identifierSchemeCodeUnspsc190501.42152913': __('identifierSchemeCodeUnspsc190501.42152913'),
	'identifierSchemeCodeUnspsc190501.42152914': __('identifierSchemeCodeUnspsc190501.42152914'),
	'identifierSchemeCodeUnspsc190501.42152915': __('identifierSchemeCodeUnspsc190501.42152915'),
	'identifierSchemeCodeUnspsc190501.42152916': __('identifierSchemeCodeUnspsc190501.42152916'),
	'identifierSchemeCodeUnspsc190501.42152917': __('identifierSchemeCodeUnspsc190501.42152917'),
	'identifierSchemeCodeUnspsc190501.42152918': __('identifierSchemeCodeUnspsc190501.42152918'),
	'identifierSchemeCodeUnspsc190501.42152919': __('identifierSchemeCodeUnspsc190501.42152919'),
	'identifierSchemeCodeUnspsc190501.42152920': __('identifierSchemeCodeUnspsc190501.42152920'),
	'identifierSchemeCodeUnspsc190501.42152921': __('identifierSchemeCodeUnspsc190501.42152921'),
	'identifierSchemeCodeUnspsc190501.42152922': __('identifierSchemeCodeUnspsc190501.42152922'),
	'identifierSchemeCodeUnspsc190501.42152923': __('identifierSchemeCodeUnspsc190501.42152923'),
	'identifierSchemeCodeUnspsc190501.42152924': __('identifierSchemeCodeUnspsc190501.42152924'),
	'identifierSchemeCodeUnspsc190501.42152925': __('identifierSchemeCodeUnspsc190501.42152925'),
	'identifierSchemeCodeUnspsc190501.42152926': __('identifierSchemeCodeUnspsc190501.42152926'),
	'identifierSchemeCodeUnspsc190501.42152927': __('identifierSchemeCodeUnspsc190501.42152927'),
	'identifierSchemeCodeUnspsc190501.42152928': __('identifierSchemeCodeUnspsc190501.42152928'),
	'identifierSchemeCodeUnspsc190501.42152930': __('identifierSchemeCodeUnspsc190501.42152930'),
	'identifierSchemeCodeUnspsc190501.42152931': __('identifierSchemeCodeUnspsc190501.42152931'),
	'identifierSchemeCodeUnspsc190501.42152932': __('identifierSchemeCodeUnspsc190501.42152932'),
	'identifierSchemeCodeUnspsc190501.42152933': __('identifierSchemeCodeUnspsc190501.42152933'),
	'identifierSchemeCodeUnspsc190501.42153000': __('identifierSchemeCodeUnspsc190501.42153000'),
	'identifierSchemeCodeUnspsc190501.42153001': __('identifierSchemeCodeUnspsc190501.42153001'),
	'identifierSchemeCodeUnspsc190501.42153002': __('identifierSchemeCodeUnspsc190501.42153002'),
	'identifierSchemeCodeUnspsc190501.42153003': __('identifierSchemeCodeUnspsc190501.42153003'),
	'identifierSchemeCodeUnspsc190501.42153004': __('identifierSchemeCodeUnspsc190501.42153004'),
	'identifierSchemeCodeUnspsc190501.42153005': __('identifierSchemeCodeUnspsc190501.42153005'),
	'identifierSchemeCodeUnspsc190501.42153006': __('identifierSchemeCodeUnspsc190501.42153006'),
	'identifierSchemeCodeUnspsc190501.42153007': __('identifierSchemeCodeUnspsc190501.42153007'),
	'identifierSchemeCodeUnspsc190501.42153008': __('identifierSchemeCodeUnspsc190501.42153008'),
	'identifierSchemeCodeUnspsc190501.42153009': __('identifierSchemeCodeUnspsc190501.42153009'),
	'identifierSchemeCodeUnspsc190501.42153010': __('identifierSchemeCodeUnspsc190501.42153010'),
	'identifierSchemeCodeUnspsc190501.42153011': __('identifierSchemeCodeUnspsc190501.42153011'),
	'identifierSchemeCodeUnspsc190501.42160000': __('identifierSchemeCodeUnspsc190501.42160000'),
	'identifierSchemeCodeUnspsc190501.42161500': __('identifierSchemeCodeUnspsc190501.42161500'),
	'identifierSchemeCodeUnspsc190501.42161501': __('identifierSchemeCodeUnspsc190501.42161501'),
	'identifierSchemeCodeUnspsc190501.42161502': __('identifierSchemeCodeUnspsc190501.42161502'),
	'identifierSchemeCodeUnspsc190501.42161503': __('identifierSchemeCodeUnspsc190501.42161503'),
	'identifierSchemeCodeUnspsc190501.42161504': __('identifierSchemeCodeUnspsc190501.42161504'),
	'identifierSchemeCodeUnspsc190501.42161505': __('identifierSchemeCodeUnspsc190501.42161505'),
	'identifierSchemeCodeUnspsc190501.42161506': __('identifierSchemeCodeUnspsc190501.42161506'),
	'identifierSchemeCodeUnspsc190501.42161507': __('identifierSchemeCodeUnspsc190501.42161507'),
	'identifierSchemeCodeUnspsc190501.42161508': __('identifierSchemeCodeUnspsc190501.42161508'),
	'identifierSchemeCodeUnspsc190501.42161509': __('identifierSchemeCodeUnspsc190501.42161509'),
	'identifierSchemeCodeUnspsc190501.42161510': __('identifierSchemeCodeUnspsc190501.42161510'),
	'identifierSchemeCodeUnspsc190501.42161511': __('identifierSchemeCodeUnspsc190501.42161511'),
	'identifierSchemeCodeUnspsc190501.42161512': __('identifierSchemeCodeUnspsc190501.42161512'),
	'identifierSchemeCodeUnspsc190501.42161513': __('identifierSchemeCodeUnspsc190501.42161513'),
	'identifierSchemeCodeUnspsc190501.42161514': __('identifierSchemeCodeUnspsc190501.42161514'),
	'identifierSchemeCodeUnspsc190501.42161515': __('identifierSchemeCodeUnspsc190501.42161515'),
	'identifierSchemeCodeUnspsc190501.42161516': __('identifierSchemeCodeUnspsc190501.42161516'),
	'identifierSchemeCodeUnspsc190501.42161517': __('identifierSchemeCodeUnspsc190501.42161517'),
	'identifierSchemeCodeUnspsc190501.42161518': __('identifierSchemeCodeUnspsc190501.42161518'),
	'identifierSchemeCodeUnspsc190501.42161519': __('identifierSchemeCodeUnspsc190501.42161519'),
	'identifierSchemeCodeUnspsc190501.42161520': __('identifierSchemeCodeUnspsc190501.42161520'),
	'identifierSchemeCodeUnspsc190501.42161600': __('identifierSchemeCodeUnspsc190501.42161600'),
	'identifierSchemeCodeUnspsc190501.42161601': __('identifierSchemeCodeUnspsc190501.42161601'),
	'identifierSchemeCodeUnspsc190501.42161602': __('identifierSchemeCodeUnspsc190501.42161602'),
	'identifierSchemeCodeUnspsc190501.42161603': __('identifierSchemeCodeUnspsc190501.42161603'),
	'identifierSchemeCodeUnspsc190501.42161604': __('identifierSchemeCodeUnspsc190501.42161604'),
	'identifierSchemeCodeUnspsc190501.42161605': __('identifierSchemeCodeUnspsc190501.42161605'),
	'identifierSchemeCodeUnspsc190501.42161606': __('identifierSchemeCodeUnspsc190501.42161606'),
	'identifierSchemeCodeUnspsc190501.42161607': __('identifierSchemeCodeUnspsc190501.42161607'),
	'identifierSchemeCodeUnspsc190501.42161608': __('identifierSchemeCodeUnspsc190501.42161608'),
	'identifierSchemeCodeUnspsc190501.42161609': __('identifierSchemeCodeUnspsc190501.42161609'),
	'identifierSchemeCodeUnspsc190501.42161610': __('identifierSchemeCodeUnspsc190501.42161610'),
	'identifierSchemeCodeUnspsc190501.42161611': __('identifierSchemeCodeUnspsc190501.42161611'),
	'identifierSchemeCodeUnspsc190501.42161612': __('identifierSchemeCodeUnspsc190501.42161612'),
	'identifierSchemeCodeUnspsc190501.42161613': __('identifierSchemeCodeUnspsc190501.42161613'),
	'identifierSchemeCodeUnspsc190501.42161614': __('identifierSchemeCodeUnspsc190501.42161614'),
	'identifierSchemeCodeUnspsc190501.42161615': __('identifierSchemeCodeUnspsc190501.42161615'),
	'identifierSchemeCodeUnspsc190501.42161616': __('identifierSchemeCodeUnspsc190501.42161616'),
	'identifierSchemeCodeUnspsc190501.42161617': __('identifierSchemeCodeUnspsc190501.42161617'),
	'identifierSchemeCodeUnspsc190501.42161618': __('identifierSchemeCodeUnspsc190501.42161618'),
	'identifierSchemeCodeUnspsc190501.42161619': __('identifierSchemeCodeUnspsc190501.42161619'),
	'identifierSchemeCodeUnspsc190501.42161620': __('identifierSchemeCodeUnspsc190501.42161620'),
	'identifierSchemeCodeUnspsc190501.42161621': __('identifierSchemeCodeUnspsc190501.42161621'),
	'identifierSchemeCodeUnspsc190501.42161622': __('identifierSchemeCodeUnspsc190501.42161622'),
	'identifierSchemeCodeUnspsc190501.42161623': __('identifierSchemeCodeUnspsc190501.42161623'),
	'identifierSchemeCodeUnspsc190501.42161624': __('identifierSchemeCodeUnspsc190501.42161624'),
	'identifierSchemeCodeUnspsc190501.42161625': __('identifierSchemeCodeUnspsc190501.42161625'),
	'identifierSchemeCodeUnspsc190501.42161627': __('identifierSchemeCodeUnspsc190501.42161627'),
	'identifierSchemeCodeUnspsc190501.42161628': __('identifierSchemeCodeUnspsc190501.42161628'),
	'identifierSchemeCodeUnspsc190501.42161629': __('identifierSchemeCodeUnspsc190501.42161629'),
	'identifierSchemeCodeUnspsc190501.42161630': __('identifierSchemeCodeUnspsc190501.42161630'),
	'identifierSchemeCodeUnspsc190501.42161631': __('identifierSchemeCodeUnspsc190501.42161631'),
	'identifierSchemeCodeUnspsc190501.42161632': __('identifierSchemeCodeUnspsc190501.42161632'),
	'identifierSchemeCodeUnspsc190501.42161633': __('identifierSchemeCodeUnspsc190501.42161633'),
	'identifierSchemeCodeUnspsc190501.42161634': __('identifierSchemeCodeUnspsc190501.42161634'),
	'identifierSchemeCodeUnspsc190501.42161635': __('identifierSchemeCodeUnspsc190501.42161635'),
	'identifierSchemeCodeUnspsc190501.42161636': __('identifierSchemeCodeUnspsc190501.42161636'),
	'identifierSchemeCodeUnspsc190501.42161637': __('identifierSchemeCodeUnspsc190501.42161637'),
	'identifierSchemeCodeUnspsc190501.42161638': __('identifierSchemeCodeUnspsc190501.42161638'),
	'identifierSchemeCodeUnspsc190501.42161639': __('identifierSchemeCodeUnspsc190501.42161639'),
	'identifierSchemeCodeUnspsc190501.42161640': __('identifierSchemeCodeUnspsc190501.42161640'),
	'identifierSchemeCodeUnspsc190501.42161641': __('identifierSchemeCodeUnspsc190501.42161641'),
	'identifierSchemeCodeUnspsc190501.42161642': __('identifierSchemeCodeUnspsc190501.42161642'),
	'identifierSchemeCodeUnspsc190501.42161643': __('identifierSchemeCodeUnspsc190501.42161643'),
	'identifierSchemeCodeUnspsc190501.42161644': __('identifierSchemeCodeUnspsc190501.42161644'),
	'identifierSchemeCodeUnspsc190501.42161645': __('identifierSchemeCodeUnspsc190501.42161645'),
	'identifierSchemeCodeUnspsc190501.42161646': __('identifierSchemeCodeUnspsc190501.42161646'),
	'identifierSchemeCodeUnspsc190501.42161647': __('identifierSchemeCodeUnspsc190501.42161647'),
	'identifierSchemeCodeUnspsc190501.42161648': __('identifierSchemeCodeUnspsc190501.42161648'),
	'identifierSchemeCodeUnspsc190501.42161649': __('identifierSchemeCodeUnspsc190501.42161649'),
	'identifierSchemeCodeUnspsc190501.42161650': __('identifierSchemeCodeUnspsc190501.42161650'),
	'identifierSchemeCodeUnspsc190501.42161651': __('identifierSchemeCodeUnspsc190501.42161651'),
	'identifierSchemeCodeUnspsc190501.42161652': __('identifierSchemeCodeUnspsc190501.42161652'),
	'identifierSchemeCodeUnspsc190501.42161653': __('identifierSchemeCodeUnspsc190501.42161653'),
	'identifierSchemeCodeUnspsc190501.42161654': __('identifierSchemeCodeUnspsc190501.42161654'),
	'identifierSchemeCodeUnspsc190501.42161655': __('identifierSchemeCodeUnspsc190501.42161655'),
	'identifierSchemeCodeUnspsc190501.42161656': __('identifierSchemeCodeUnspsc190501.42161656'),
	'identifierSchemeCodeUnspsc190501.42161657': __('identifierSchemeCodeUnspsc190501.42161657'),
	'identifierSchemeCodeUnspsc190501.42161658': __('identifierSchemeCodeUnspsc190501.42161658'),
	'identifierSchemeCodeUnspsc190501.42161659': __('identifierSchemeCodeUnspsc190501.42161659'),
	'identifierSchemeCodeUnspsc190501.42161660': __('identifierSchemeCodeUnspsc190501.42161660'),
	'identifierSchemeCodeUnspsc190501.42161700': __('identifierSchemeCodeUnspsc190501.42161700'),
	'identifierSchemeCodeUnspsc190501.42161701': __('identifierSchemeCodeUnspsc190501.42161701'),
	'identifierSchemeCodeUnspsc190501.42161702': __('identifierSchemeCodeUnspsc190501.42161702'),
	'identifierSchemeCodeUnspsc190501.42161703': __('identifierSchemeCodeUnspsc190501.42161703'),
	'identifierSchemeCodeUnspsc190501.42161704': __('identifierSchemeCodeUnspsc190501.42161704'),
	'identifierSchemeCodeUnspsc190501.42161800': __('identifierSchemeCodeUnspsc190501.42161800'),
	'identifierSchemeCodeUnspsc190501.42161801': __('identifierSchemeCodeUnspsc190501.42161801'),
	'identifierSchemeCodeUnspsc190501.42161802': __('identifierSchemeCodeUnspsc190501.42161802'),
	'identifierSchemeCodeUnspsc190501.42161803': __('identifierSchemeCodeUnspsc190501.42161803'),
	'identifierSchemeCodeUnspsc190501.42161804': __('identifierSchemeCodeUnspsc190501.42161804'),
	'identifierSchemeCodeUnspsc190501.42161805': __('identifierSchemeCodeUnspsc190501.42161805'),
	'identifierSchemeCodeUnspsc190501.42161806': __('identifierSchemeCodeUnspsc190501.42161806'),
	'identifierSchemeCodeUnspsc190501.42161900': __('identifierSchemeCodeUnspsc190501.42161900'),
	'identifierSchemeCodeUnspsc190501.42161901': __('identifierSchemeCodeUnspsc190501.42161901'),
	'identifierSchemeCodeUnspsc190501.42161902': __('identifierSchemeCodeUnspsc190501.42161902'),
	'identifierSchemeCodeUnspsc190501.42161903': __('identifierSchemeCodeUnspsc190501.42161903'),
	'identifierSchemeCodeUnspsc190501.42161904': __('identifierSchemeCodeUnspsc190501.42161904'),
	'identifierSchemeCodeUnspsc190501.42161905': __('identifierSchemeCodeUnspsc190501.42161905'),
	'identifierSchemeCodeUnspsc190501.42161906': __('identifierSchemeCodeUnspsc190501.42161906'),
	'identifierSchemeCodeUnspsc190501.42161907': __('identifierSchemeCodeUnspsc190501.42161907'),
	'identifierSchemeCodeUnspsc190501.42161908': __('identifierSchemeCodeUnspsc190501.42161908'),
	'identifierSchemeCodeUnspsc190501.42161909': __('identifierSchemeCodeUnspsc190501.42161909'),
	'identifierSchemeCodeUnspsc190501.42161910': __('identifierSchemeCodeUnspsc190501.42161910'),
	'identifierSchemeCodeUnspsc190501.42161911': __('identifierSchemeCodeUnspsc190501.42161911'),
	'identifierSchemeCodeUnspsc190501.42161912': __('identifierSchemeCodeUnspsc190501.42161912'),
	'identifierSchemeCodeUnspsc190501.42161913': __('identifierSchemeCodeUnspsc190501.42161913'),
	'identifierSchemeCodeUnspsc190501.42161914': __('identifierSchemeCodeUnspsc190501.42161914'),
	'identifierSchemeCodeUnspsc190501.42161915': __('identifierSchemeCodeUnspsc190501.42161915'),
	'identifierSchemeCodeUnspsc190501.42161916': __('identifierSchemeCodeUnspsc190501.42161916'),
	'identifierSchemeCodeUnspsc190501.42170000': __('identifierSchemeCodeUnspsc190501.42170000'),
	'identifierSchemeCodeUnspsc190501.42171500': __('identifierSchemeCodeUnspsc190501.42171500'),
	'identifierSchemeCodeUnspsc190501.42171501': __('identifierSchemeCodeUnspsc190501.42171501'),
	'identifierSchemeCodeUnspsc190501.42171502': __('identifierSchemeCodeUnspsc190501.42171502'),
	'identifierSchemeCodeUnspsc190501.42171503': __('identifierSchemeCodeUnspsc190501.42171503'),
	'identifierSchemeCodeUnspsc190501.42171504': __('identifierSchemeCodeUnspsc190501.42171504'),
	'identifierSchemeCodeUnspsc190501.42171505': __('identifierSchemeCodeUnspsc190501.42171505'),
	'identifierSchemeCodeUnspsc190501.42171600': __('identifierSchemeCodeUnspsc190501.42171600'),
	'identifierSchemeCodeUnspsc190501.42171601': __('identifierSchemeCodeUnspsc190501.42171601'),
	'identifierSchemeCodeUnspsc190501.42171602': __('identifierSchemeCodeUnspsc190501.42171602'),
	'identifierSchemeCodeUnspsc190501.42171603': __('identifierSchemeCodeUnspsc190501.42171603'),
	'identifierSchemeCodeUnspsc190501.42171604': __('identifierSchemeCodeUnspsc190501.42171604'),
	'identifierSchemeCodeUnspsc190501.42171605': __('identifierSchemeCodeUnspsc190501.42171605'),
	'identifierSchemeCodeUnspsc190501.42171606': __('identifierSchemeCodeUnspsc190501.42171606'),
	'identifierSchemeCodeUnspsc190501.42171607': __('identifierSchemeCodeUnspsc190501.42171607'),
	'identifierSchemeCodeUnspsc190501.42171608': __('identifierSchemeCodeUnspsc190501.42171608'),
	'identifierSchemeCodeUnspsc190501.42171609': __('identifierSchemeCodeUnspsc190501.42171609'),
	'identifierSchemeCodeUnspsc190501.42171610': __('identifierSchemeCodeUnspsc190501.42171610'),
	'identifierSchemeCodeUnspsc190501.42171611': __('identifierSchemeCodeUnspsc190501.42171611'),
	'identifierSchemeCodeUnspsc190501.42171612': __('identifierSchemeCodeUnspsc190501.42171612'),
	'identifierSchemeCodeUnspsc190501.42171613': __('identifierSchemeCodeUnspsc190501.42171613'),
	'identifierSchemeCodeUnspsc190501.42171614': __('identifierSchemeCodeUnspsc190501.42171614'),
	'identifierSchemeCodeUnspsc190501.42171615': __('identifierSchemeCodeUnspsc190501.42171615'),
	'identifierSchemeCodeUnspsc190501.42171616': __('identifierSchemeCodeUnspsc190501.42171616'),
	'identifierSchemeCodeUnspsc190501.42171617': __('identifierSchemeCodeUnspsc190501.42171617'),
	'identifierSchemeCodeUnspsc190501.42171618': __('identifierSchemeCodeUnspsc190501.42171618'),
	'identifierSchemeCodeUnspsc190501.42171619': __('identifierSchemeCodeUnspsc190501.42171619'),
	'identifierSchemeCodeUnspsc190501.42171700': __('identifierSchemeCodeUnspsc190501.42171700'),
	'identifierSchemeCodeUnspsc190501.42171701': __('identifierSchemeCodeUnspsc190501.42171701'),
	'identifierSchemeCodeUnspsc190501.42171702': __('identifierSchemeCodeUnspsc190501.42171702'),
	'identifierSchemeCodeUnspsc190501.42171703': __('identifierSchemeCodeUnspsc190501.42171703'),
	'identifierSchemeCodeUnspsc190501.42171704': __('identifierSchemeCodeUnspsc190501.42171704'),
	'identifierSchemeCodeUnspsc190501.42171800': __('identifierSchemeCodeUnspsc190501.42171800'),
	'identifierSchemeCodeUnspsc190501.42171801': __('identifierSchemeCodeUnspsc190501.42171801'),
	'identifierSchemeCodeUnspsc190501.42171805': __('identifierSchemeCodeUnspsc190501.42171805'),
	'identifierSchemeCodeUnspsc190501.42171807': __('identifierSchemeCodeUnspsc190501.42171807'),
	'identifierSchemeCodeUnspsc190501.42171808': __('identifierSchemeCodeUnspsc190501.42171808'),
	'identifierSchemeCodeUnspsc190501.42171809': __('identifierSchemeCodeUnspsc190501.42171809'),
	'identifierSchemeCodeUnspsc190501.42171810': __('identifierSchemeCodeUnspsc190501.42171810'),
	'identifierSchemeCodeUnspsc190501.42171900': __('identifierSchemeCodeUnspsc190501.42171900'),
	'identifierSchemeCodeUnspsc190501.42171921': __('identifierSchemeCodeUnspsc190501.42171921'),
	'identifierSchemeCodeUnspsc190501.42171922': __('identifierSchemeCodeUnspsc190501.42171922'),
	'identifierSchemeCodeUnspsc190501.42171923': __('identifierSchemeCodeUnspsc190501.42171923'),
	'identifierSchemeCodeUnspsc190501.42171924': __('identifierSchemeCodeUnspsc190501.42171924'),
	'identifierSchemeCodeUnspsc190501.42172000': __('identifierSchemeCodeUnspsc190501.42172000'),
	'identifierSchemeCodeUnspsc190501.42172001': __('identifierSchemeCodeUnspsc190501.42172001'),
	'identifierSchemeCodeUnspsc190501.42172002': __('identifierSchemeCodeUnspsc190501.42172002'),
	'identifierSchemeCodeUnspsc190501.42172003': __('identifierSchemeCodeUnspsc190501.42172003'),
	'identifierSchemeCodeUnspsc190501.42172004': __('identifierSchemeCodeUnspsc190501.42172004'),
	'identifierSchemeCodeUnspsc190501.42172005': __('identifierSchemeCodeUnspsc190501.42172005'),
	'identifierSchemeCodeUnspsc190501.42172006': __('identifierSchemeCodeUnspsc190501.42172006'),
	'identifierSchemeCodeUnspsc190501.42172008': __('identifierSchemeCodeUnspsc190501.42172008'),
	'identifierSchemeCodeUnspsc190501.42172009': __('identifierSchemeCodeUnspsc190501.42172009'),
	'identifierSchemeCodeUnspsc190501.42172010': __('identifierSchemeCodeUnspsc190501.42172010'),
	'identifierSchemeCodeUnspsc190501.42172011': __('identifierSchemeCodeUnspsc190501.42172011'),
	'identifierSchemeCodeUnspsc190501.42172014': __('identifierSchemeCodeUnspsc190501.42172014'),
	'identifierSchemeCodeUnspsc190501.42172015': __('identifierSchemeCodeUnspsc190501.42172015'),
	'identifierSchemeCodeUnspsc190501.42172016': __('identifierSchemeCodeUnspsc190501.42172016'),
	'identifierSchemeCodeUnspsc190501.42172017': __('identifierSchemeCodeUnspsc190501.42172017'),
	'identifierSchemeCodeUnspsc190501.42172100': __('identifierSchemeCodeUnspsc190501.42172100'),
	'identifierSchemeCodeUnspsc190501.42172101': __('identifierSchemeCodeUnspsc190501.42172101'),
	'identifierSchemeCodeUnspsc190501.42172103': __('identifierSchemeCodeUnspsc190501.42172103'),
	'identifierSchemeCodeUnspsc190501.42172104': __('identifierSchemeCodeUnspsc190501.42172104'),
	'identifierSchemeCodeUnspsc190501.42172105': __('identifierSchemeCodeUnspsc190501.42172105'),
	'identifierSchemeCodeUnspsc190501.42172106': __('identifierSchemeCodeUnspsc190501.42172106'),
	'identifierSchemeCodeUnspsc190501.42172107': __('identifierSchemeCodeUnspsc190501.42172107'),
	'identifierSchemeCodeUnspsc190501.42180000': __('identifierSchemeCodeUnspsc190501.42180000'),
	'identifierSchemeCodeUnspsc190501.42181500': __('identifierSchemeCodeUnspsc190501.42181500'),
	'identifierSchemeCodeUnspsc190501.42181501': __('identifierSchemeCodeUnspsc190501.42181501'),
	'identifierSchemeCodeUnspsc190501.42181502': __('identifierSchemeCodeUnspsc190501.42181502'),
	'identifierSchemeCodeUnspsc190501.42181503': __('identifierSchemeCodeUnspsc190501.42181503'),
	'identifierSchemeCodeUnspsc190501.42181504': __('identifierSchemeCodeUnspsc190501.42181504'),
	'identifierSchemeCodeUnspsc190501.42181505': __('identifierSchemeCodeUnspsc190501.42181505'),
	'identifierSchemeCodeUnspsc190501.42181506': __('identifierSchemeCodeUnspsc190501.42181506'),
	'identifierSchemeCodeUnspsc190501.42181507': __('identifierSchemeCodeUnspsc190501.42181507'),
	'identifierSchemeCodeUnspsc190501.42181508': __('identifierSchemeCodeUnspsc190501.42181508'),
	'identifierSchemeCodeUnspsc190501.42181509': __('identifierSchemeCodeUnspsc190501.42181509'),
	'identifierSchemeCodeUnspsc190501.42181510': __('identifierSchemeCodeUnspsc190501.42181510'),
	'identifierSchemeCodeUnspsc190501.42181511': __('identifierSchemeCodeUnspsc190501.42181511'),
	'identifierSchemeCodeUnspsc190501.42181512': __('identifierSchemeCodeUnspsc190501.42181512'),
	'identifierSchemeCodeUnspsc190501.42181513': __('identifierSchemeCodeUnspsc190501.42181513'),
	'identifierSchemeCodeUnspsc190501.42181514': __('identifierSchemeCodeUnspsc190501.42181514'),
	'identifierSchemeCodeUnspsc190501.42181515': __('identifierSchemeCodeUnspsc190501.42181515'),
	'identifierSchemeCodeUnspsc190501.42181516': __('identifierSchemeCodeUnspsc190501.42181516'),
	'identifierSchemeCodeUnspsc190501.42181520': __('identifierSchemeCodeUnspsc190501.42181520'),
	'identifierSchemeCodeUnspsc190501.42181521': __('identifierSchemeCodeUnspsc190501.42181521'),
	'identifierSchemeCodeUnspsc190501.42181522': __('identifierSchemeCodeUnspsc190501.42181522'),
	'identifierSchemeCodeUnspsc190501.42181523': __('identifierSchemeCodeUnspsc190501.42181523'),
	'identifierSchemeCodeUnspsc190501.42181524': __('identifierSchemeCodeUnspsc190501.42181524'),
	'identifierSchemeCodeUnspsc190501.42181525': __('identifierSchemeCodeUnspsc190501.42181525'),
	'identifierSchemeCodeUnspsc190501.42181527': __('identifierSchemeCodeUnspsc190501.42181527'),
	'identifierSchemeCodeUnspsc190501.42181528': __('identifierSchemeCodeUnspsc190501.42181528'),
	'identifierSchemeCodeUnspsc190501.42181529': __('identifierSchemeCodeUnspsc190501.42181529'),
	'identifierSchemeCodeUnspsc190501.42181530': __('identifierSchemeCodeUnspsc190501.42181530'),
	'identifierSchemeCodeUnspsc190501.42181533': __('identifierSchemeCodeUnspsc190501.42181533'),
	'identifierSchemeCodeUnspsc190501.42181534': __('identifierSchemeCodeUnspsc190501.42181534'),
	'identifierSchemeCodeUnspsc190501.42181535': __('identifierSchemeCodeUnspsc190501.42181535'),
	'identifierSchemeCodeUnspsc190501.42181539': __('identifierSchemeCodeUnspsc190501.42181539'),
	'identifierSchemeCodeUnspsc190501.42181540': __('identifierSchemeCodeUnspsc190501.42181540'),
	'identifierSchemeCodeUnspsc190501.42181541': __('identifierSchemeCodeUnspsc190501.42181541'),
	'identifierSchemeCodeUnspsc190501.42181542': __('identifierSchemeCodeUnspsc190501.42181542'),
	'identifierSchemeCodeUnspsc190501.42181543': __('identifierSchemeCodeUnspsc190501.42181543'),
	'identifierSchemeCodeUnspsc190501.42181544': __('identifierSchemeCodeUnspsc190501.42181544'),
	'identifierSchemeCodeUnspsc190501.42181545': __('identifierSchemeCodeUnspsc190501.42181545'),
	'identifierSchemeCodeUnspsc190501.42181600': __('identifierSchemeCodeUnspsc190501.42181600'),
	'identifierSchemeCodeUnspsc190501.42181601': __('identifierSchemeCodeUnspsc190501.42181601'),
	'identifierSchemeCodeUnspsc190501.42181602': __('identifierSchemeCodeUnspsc190501.42181602'),
	'identifierSchemeCodeUnspsc190501.42181603': __('identifierSchemeCodeUnspsc190501.42181603'),
	'identifierSchemeCodeUnspsc190501.42181604': __('identifierSchemeCodeUnspsc190501.42181604'),
	'identifierSchemeCodeUnspsc190501.42181605': __('identifierSchemeCodeUnspsc190501.42181605'),
	'identifierSchemeCodeUnspsc190501.42181606': __('identifierSchemeCodeUnspsc190501.42181606'),
	'identifierSchemeCodeUnspsc190501.42181607': __('identifierSchemeCodeUnspsc190501.42181607'),
	'identifierSchemeCodeUnspsc190501.42181608': __('identifierSchemeCodeUnspsc190501.42181608'),
	'identifierSchemeCodeUnspsc190501.42181609': __('identifierSchemeCodeUnspsc190501.42181609'),
	'identifierSchemeCodeUnspsc190501.42181610': __('identifierSchemeCodeUnspsc190501.42181610'),
	'identifierSchemeCodeUnspsc190501.42181611': __('identifierSchemeCodeUnspsc190501.42181611'),
	'identifierSchemeCodeUnspsc190501.42181700': __('identifierSchemeCodeUnspsc190501.42181700'),
	'identifierSchemeCodeUnspsc190501.42181701': __('identifierSchemeCodeUnspsc190501.42181701'),
	'identifierSchemeCodeUnspsc190501.42181702': __('identifierSchemeCodeUnspsc190501.42181702'),
	'identifierSchemeCodeUnspsc190501.42181703': __('identifierSchemeCodeUnspsc190501.42181703'),
	'identifierSchemeCodeUnspsc190501.42181704': __('identifierSchemeCodeUnspsc190501.42181704'),
	'identifierSchemeCodeUnspsc190501.42181705': __('identifierSchemeCodeUnspsc190501.42181705'),
	'identifierSchemeCodeUnspsc190501.42181706': __('identifierSchemeCodeUnspsc190501.42181706'),
	'identifierSchemeCodeUnspsc190501.42181707': __('identifierSchemeCodeUnspsc190501.42181707'),
	'identifierSchemeCodeUnspsc190501.42181708': __('identifierSchemeCodeUnspsc190501.42181708'),
	'identifierSchemeCodeUnspsc190501.42181710': __('identifierSchemeCodeUnspsc190501.42181710'),
	'identifierSchemeCodeUnspsc190501.42181711': __('identifierSchemeCodeUnspsc190501.42181711'),
	'identifierSchemeCodeUnspsc190501.42181712': __('identifierSchemeCodeUnspsc190501.42181712'),
	'identifierSchemeCodeUnspsc190501.42181713': __('identifierSchemeCodeUnspsc190501.42181713'),
	'identifierSchemeCodeUnspsc190501.42181715': __('identifierSchemeCodeUnspsc190501.42181715'),
	'identifierSchemeCodeUnspsc190501.42181716': __('identifierSchemeCodeUnspsc190501.42181716'),
	'identifierSchemeCodeUnspsc190501.42181717': __('identifierSchemeCodeUnspsc190501.42181717'),
	'identifierSchemeCodeUnspsc190501.42181718': __('identifierSchemeCodeUnspsc190501.42181718'),
	'identifierSchemeCodeUnspsc190501.42181719': __('identifierSchemeCodeUnspsc190501.42181719'),
	'identifierSchemeCodeUnspsc190501.42181720': __('identifierSchemeCodeUnspsc190501.42181720'),
	'identifierSchemeCodeUnspsc190501.42181721': __('identifierSchemeCodeUnspsc190501.42181721'),
	'identifierSchemeCodeUnspsc190501.42181722': __('identifierSchemeCodeUnspsc190501.42181722'),
	'identifierSchemeCodeUnspsc190501.42181723': __('identifierSchemeCodeUnspsc190501.42181723'),
	'identifierSchemeCodeUnspsc190501.42181724': __('identifierSchemeCodeUnspsc190501.42181724'),
	'identifierSchemeCodeUnspsc190501.42181800': __('identifierSchemeCodeUnspsc190501.42181800'),
	'identifierSchemeCodeUnspsc190501.42181801': __('identifierSchemeCodeUnspsc190501.42181801'),
	'identifierSchemeCodeUnspsc190501.42181802': __('identifierSchemeCodeUnspsc190501.42181802'),
	'identifierSchemeCodeUnspsc190501.42181803': __('identifierSchemeCodeUnspsc190501.42181803'),
	'identifierSchemeCodeUnspsc190501.42181804': __('identifierSchemeCodeUnspsc190501.42181804'),
	'identifierSchemeCodeUnspsc190501.42181805': __('identifierSchemeCodeUnspsc190501.42181805'),
	'identifierSchemeCodeUnspsc190501.42181900': __('identifierSchemeCodeUnspsc190501.42181900'),
	'identifierSchemeCodeUnspsc190501.42181902': __('identifierSchemeCodeUnspsc190501.42181902'),
	'identifierSchemeCodeUnspsc190501.42181903': __('identifierSchemeCodeUnspsc190501.42181903'),
	'identifierSchemeCodeUnspsc190501.42181904': __('identifierSchemeCodeUnspsc190501.42181904'),
	'identifierSchemeCodeUnspsc190501.42181905': __('identifierSchemeCodeUnspsc190501.42181905'),
	'identifierSchemeCodeUnspsc190501.42181907': __('identifierSchemeCodeUnspsc190501.42181907'),
	'identifierSchemeCodeUnspsc190501.42181908': __('identifierSchemeCodeUnspsc190501.42181908'),
	'identifierSchemeCodeUnspsc190501.42181909': __('identifierSchemeCodeUnspsc190501.42181909'),
	'identifierSchemeCodeUnspsc190501.42181913': __('identifierSchemeCodeUnspsc190501.42181913'),
	'identifierSchemeCodeUnspsc190501.42181914': __('identifierSchemeCodeUnspsc190501.42181914'),
	'identifierSchemeCodeUnspsc190501.42181915': __('identifierSchemeCodeUnspsc190501.42181915'),
	'identifierSchemeCodeUnspsc190501.42181916': __('identifierSchemeCodeUnspsc190501.42181916'),
	'identifierSchemeCodeUnspsc190501.42181917': __('identifierSchemeCodeUnspsc190501.42181917'),
	'identifierSchemeCodeUnspsc190501.42181918': __('identifierSchemeCodeUnspsc190501.42181918'),
	'identifierSchemeCodeUnspsc190501.42181919': __('identifierSchemeCodeUnspsc190501.42181919'),
	'identifierSchemeCodeUnspsc190501.42181920': __('identifierSchemeCodeUnspsc190501.42181920'),
	'identifierSchemeCodeUnspsc190501.42181921': __('identifierSchemeCodeUnspsc190501.42181921'),
	'identifierSchemeCodeUnspsc190501.42181922': __('identifierSchemeCodeUnspsc190501.42181922'),
	'identifierSchemeCodeUnspsc190501.42181923': __('identifierSchemeCodeUnspsc190501.42181923'),
	'identifierSchemeCodeUnspsc190501.42181924': __('identifierSchemeCodeUnspsc190501.42181924'),
	'identifierSchemeCodeUnspsc190501.42182000': __('identifierSchemeCodeUnspsc190501.42182000'),
	'identifierSchemeCodeUnspsc190501.42182001': __('identifierSchemeCodeUnspsc190501.42182001'),
	'identifierSchemeCodeUnspsc190501.42182003': __('identifierSchemeCodeUnspsc190501.42182003'),
	'identifierSchemeCodeUnspsc190501.42182004': __('identifierSchemeCodeUnspsc190501.42182004'),
	'identifierSchemeCodeUnspsc190501.42182005': __('identifierSchemeCodeUnspsc190501.42182005'),
	'identifierSchemeCodeUnspsc190501.42182006': __('identifierSchemeCodeUnspsc190501.42182006'),
	'identifierSchemeCodeUnspsc190501.42182007': __('identifierSchemeCodeUnspsc190501.42182007'),
	'identifierSchemeCodeUnspsc190501.42182008': __('identifierSchemeCodeUnspsc190501.42182008'),
	'identifierSchemeCodeUnspsc190501.42182009': __('identifierSchemeCodeUnspsc190501.42182009'),
	'identifierSchemeCodeUnspsc190501.42182010': __('identifierSchemeCodeUnspsc190501.42182010'),
	'identifierSchemeCodeUnspsc190501.42182011': __('identifierSchemeCodeUnspsc190501.42182011'),
	'identifierSchemeCodeUnspsc190501.42182012': __('identifierSchemeCodeUnspsc190501.42182012'),
	'identifierSchemeCodeUnspsc190501.42182013': __('identifierSchemeCodeUnspsc190501.42182013'),
	'identifierSchemeCodeUnspsc190501.42182015': __('identifierSchemeCodeUnspsc190501.42182015'),
	'identifierSchemeCodeUnspsc190501.42182017': __('identifierSchemeCodeUnspsc190501.42182017'),
	'identifierSchemeCodeUnspsc190501.42182021': __('identifierSchemeCodeUnspsc190501.42182021'),
	'identifierSchemeCodeUnspsc190501.42182022': __('identifierSchemeCodeUnspsc190501.42182022'),
	'identifierSchemeCodeUnspsc190501.42182023': __('identifierSchemeCodeUnspsc190501.42182023'),
	'identifierSchemeCodeUnspsc190501.42182024': __('identifierSchemeCodeUnspsc190501.42182024'),
	'identifierSchemeCodeUnspsc190501.42182025': __('identifierSchemeCodeUnspsc190501.42182025'),
	'identifierSchemeCodeUnspsc190501.42182100': __('identifierSchemeCodeUnspsc190501.42182100'),
	'identifierSchemeCodeUnspsc190501.42182101': __('identifierSchemeCodeUnspsc190501.42182101'),
	'identifierSchemeCodeUnspsc190501.42182102': __('identifierSchemeCodeUnspsc190501.42182102'),
	'identifierSchemeCodeUnspsc190501.42182103': __('identifierSchemeCodeUnspsc190501.42182103'),
	'identifierSchemeCodeUnspsc190501.42182104': __('identifierSchemeCodeUnspsc190501.42182104'),
	'identifierSchemeCodeUnspsc190501.42182105': __('identifierSchemeCodeUnspsc190501.42182105'),
	'identifierSchemeCodeUnspsc190501.42182106': __('identifierSchemeCodeUnspsc190501.42182106'),
	'identifierSchemeCodeUnspsc190501.42182107': __('identifierSchemeCodeUnspsc190501.42182107'),
	'identifierSchemeCodeUnspsc190501.42182109': __('identifierSchemeCodeUnspsc190501.42182109'),
	'identifierSchemeCodeUnspsc190501.42182110': __('identifierSchemeCodeUnspsc190501.42182110'),
	'identifierSchemeCodeUnspsc190501.42182111': __('identifierSchemeCodeUnspsc190501.42182111'),
	'identifierSchemeCodeUnspsc190501.42182112': __('identifierSchemeCodeUnspsc190501.42182112'),
	'identifierSchemeCodeUnspsc190501.42182113': __('identifierSchemeCodeUnspsc190501.42182113'),
	'identifierSchemeCodeUnspsc190501.42182114': __('identifierSchemeCodeUnspsc190501.42182114'),
	'identifierSchemeCodeUnspsc190501.42182200': __('identifierSchemeCodeUnspsc190501.42182200'),
	'identifierSchemeCodeUnspsc190501.42182201': __('identifierSchemeCodeUnspsc190501.42182201'),
	'identifierSchemeCodeUnspsc190501.42182202': __('identifierSchemeCodeUnspsc190501.42182202'),
	'identifierSchemeCodeUnspsc190501.42182203': __('identifierSchemeCodeUnspsc190501.42182203'),
	'identifierSchemeCodeUnspsc190501.42182204': __('identifierSchemeCodeUnspsc190501.42182204'),
	'identifierSchemeCodeUnspsc190501.42182205': __('identifierSchemeCodeUnspsc190501.42182205'),
	'identifierSchemeCodeUnspsc190501.42182206': __('identifierSchemeCodeUnspsc190501.42182206'),
	'identifierSchemeCodeUnspsc190501.42182207': __('identifierSchemeCodeUnspsc190501.42182207'),
	'identifierSchemeCodeUnspsc190501.42182208': __('identifierSchemeCodeUnspsc190501.42182208'),
	'identifierSchemeCodeUnspsc190501.42182209': __('identifierSchemeCodeUnspsc190501.42182209'),
	'identifierSchemeCodeUnspsc190501.42182210': __('identifierSchemeCodeUnspsc190501.42182210'),
	'identifierSchemeCodeUnspsc190501.42182211': __('identifierSchemeCodeUnspsc190501.42182211'),
	'identifierSchemeCodeUnspsc190501.42182213': __('identifierSchemeCodeUnspsc190501.42182213'),
	'identifierSchemeCodeUnspsc190501.42182300': __('identifierSchemeCodeUnspsc190501.42182300'),
	'identifierSchemeCodeUnspsc190501.42182301': __('identifierSchemeCodeUnspsc190501.42182301'),
	'identifierSchemeCodeUnspsc190501.42182302': __('identifierSchemeCodeUnspsc190501.42182302'),
	'identifierSchemeCodeUnspsc190501.42182303': __('identifierSchemeCodeUnspsc190501.42182303'),
	'identifierSchemeCodeUnspsc190501.42182304': __('identifierSchemeCodeUnspsc190501.42182304'),
	'identifierSchemeCodeUnspsc190501.42182306': __('identifierSchemeCodeUnspsc190501.42182306'),
	'identifierSchemeCodeUnspsc190501.42182307': __('identifierSchemeCodeUnspsc190501.42182307'),
	'identifierSchemeCodeUnspsc190501.42182308': __('identifierSchemeCodeUnspsc190501.42182308'),
	'identifierSchemeCodeUnspsc190501.42182311': __('identifierSchemeCodeUnspsc190501.42182311'),
	'identifierSchemeCodeUnspsc190501.42182313': __('identifierSchemeCodeUnspsc190501.42182313'),
	'identifierSchemeCodeUnspsc190501.42182314': __('identifierSchemeCodeUnspsc190501.42182314'),
	'identifierSchemeCodeUnspsc190501.42182315': __('identifierSchemeCodeUnspsc190501.42182315'),
	'identifierSchemeCodeUnspsc190501.42182316': __('identifierSchemeCodeUnspsc190501.42182316'),
	'identifierSchemeCodeUnspsc190501.42182317': __('identifierSchemeCodeUnspsc190501.42182317'),
	'identifierSchemeCodeUnspsc190501.42182318': __('identifierSchemeCodeUnspsc190501.42182318'),
	'identifierSchemeCodeUnspsc190501.42182319': __('identifierSchemeCodeUnspsc190501.42182319'),
	'identifierSchemeCodeUnspsc190501.42182320': __('identifierSchemeCodeUnspsc190501.42182320'),
	'identifierSchemeCodeUnspsc190501.42182321': __('identifierSchemeCodeUnspsc190501.42182321'),
	'identifierSchemeCodeUnspsc190501.42182322': __('identifierSchemeCodeUnspsc190501.42182322'),
	'identifierSchemeCodeUnspsc190501.42182323': __('identifierSchemeCodeUnspsc190501.42182323'),
	'identifierSchemeCodeUnspsc190501.42182324': __('identifierSchemeCodeUnspsc190501.42182324'),
	'identifierSchemeCodeUnspsc190501.42182325': __('identifierSchemeCodeUnspsc190501.42182325'),
	'identifierSchemeCodeUnspsc190501.42182326': __('identifierSchemeCodeUnspsc190501.42182326'),
	'identifierSchemeCodeUnspsc190501.42182327': __('identifierSchemeCodeUnspsc190501.42182327'),
	'identifierSchemeCodeUnspsc190501.42182400': __('identifierSchemeCodeUnspsc190501.42182400'),
	'identifierSchemeCodeUnspsc190501.42182401': __('identifierSchemeCodeUnspsc190501.42182401'),
	'identifierSchemeCodeUnspsc190501.42182402': __('identifierSchemeCodeUnspsc190501.42182402'),
	'identifierSchemeCodeUnspsc190501.42182403': __('identifierSchemeCodeUnspsc190501.42182403'),
	'identifierSchemeCodeUnspsc190501.42182404': __('identifierSchemeCodeUnspsc190501.42182404'),
	'identifierSchemeCodeUnspsc190501.42182405': __('identifierSchemeCodeUnspsc190501.42182405'),
	'identifierSchemeCodeUnspsc190501.42182406': __('identifierSchemeCodeUnspsc190501.42182406'),
	'identifierSchemeCodeUnspsc190501.42182407': __('identifierSchemeCodeUnspsc190501.42182407'),
	'identifierSchemeCodeUnspsc190501.42182408': __('identifierSchemeCodeUnspsc190501.42182408'),
	'identifierSchemeCodeUnspsc190501.42182409': __('identifierSchemeCodeUnspsc190501.42182409'),
	'identifierSchemeCodeUnspsc190501.42182410': __('identifierSchemeCodeUnspsc190501.42182410'),
	'identifierSchemeCodeUnspsc190501.42182411': __('identifierSchemeCodeUnspsc190501.42182411'),
	'identifierSchemeCodeUnspsc190501.42182412': __('identifierSchemeCodeUnspsc190501.42182412'),
	'identifierSchemeCodeUnspsc190501.42182413': __('identifierSchemeCodeUnspsc190501.42182413'),
	'identifierSchemeCodeUnspsc190501.42182414': __('identifierSchemeCodeUnspsc190501.42182414'),
	'identifierSchemeCodeUnspsc190501.42182415': __('identifierSchemeCodeUnspsc190501.42182415'),
	'identifierSchemeCodeUnspsc190501.42182416': __('identifierSchemeCodeUnspsc190501.42182416'),
	'identifierSchemeCodeUnspsc190501.42182417': __('identifierSchemeCodeUnspsc190501.42182417'),
	'identifierSchemeCodeUnspsc190501.42182418': __('identifierSchemeCodeUnspsc190501.42182418'),
	'identifierSchemeCodeUnspsc190501.42182419': __('identifierSchemeCodeUnspsc190501.42182419'),
	'identifierSchemeCodeUnspsc190501.42182420': __('identifierSchemeCodeUnspsc190501.42182420'),
	'identifierSchemeCodeUnspsc190501.42182421': __('identifierSchemeCodeUnspsc190501.42182421'),
	'identifierSchemeCodeUnspsc190501.42182422': __('identifierSchemeCodeUnspsc190501.42182422'),
	'identifierSchemeCodeUnspsc190501.42182423': __('identifierSchemeCodeUnspsc190501.42182423'),
	'identifierSchemeCodeUnspsc190501.42182424': __('identifierSchemeCodeUnspsc190501.42182424'),
	'identifierSchemeCodeUnspsc190501.42182425': __('identifierSchemeCodeUnspsc190501.42182425'),
	'identifierSchemeCodeUnspsc190501.42182426': __('identifierSchemeCodeUnspsc190501.42182426'),
	'identifierSchemeCodeUnspsc190501.42182427': __('identifierSchemeCodeUnspsc190501.42182427'),
	'identifierSchemeCodeUnspsc190501.42182500': __('identifierSchemeCodeUnspsc190501.42182500'),
	'identifierSchemeCodeUnspsc190501.42182501': __('identifierSchemeCodeUnspsc190501.42182501'),
	'identifierSchemeCodeUnspsc190501.42182502': __('identifierSchemeCodeUnspsc190501.42182502'),
	'identifierSchemeCodeUnspsc190501.42182503': __('identifierSchemeCodeUnspsc190501.42182503'),
	'identifierSchemeCodeUnspsc190501.42182600': __('identifierSchemeCodeUnspsc190501.42182600'),
	'identifierSchemeCodeUnspsc190501.42182601': __('identifierSchemeCodeUnspsc190501.42182601'),
	'identifierSchemeCodeUnspsc190501.42182602': __('identifierSchemeCodeUnspsc190501.42182602'),
	'identifierSchemeCodeUnspsc190501.42182603': __('identifierSchemeCodeUnspsc190501.42182603'),
	'identifierSchemeCodeUnspsc190501.42182604': __('identifierSchemeCodeUnspsc190501.42182604'),
	'identifierSchemeCodeUnspsc190501.42182605': __('identifierSchemeCodeUnspsc190501.42182605'),
	'identifierSchemeCodeUnspsc190501.42182606': __('identifierSchemeCodeUnspsc190501.42182606'),
	'identifierSchemeCodeUnspsc190501.42182700': __('identifierSchemeCodeUnspsc190501.42182700'),
	'identifierSchemeCodeUnspsc190501.42182701': __('identifierSchemeCodeUnspsc190501.42182701'),
	'identifierSchemeCodeUnspsc190501.42182702': __('identifierSchemeCodeUnspsc190501.42182702'),
	'identifierSchemeCodeUnspsc190501.42182703': __('identifierSchemeCodeUnspsc190501.42182703'),
	'identifierSchemeCodeUnspsc190501.42182704': __('identifierSchemeCodeUnspsc190501.42182704'),
	'identifierSchemeCodeUnspsc190501.42182705': __('identifierSchemeCodeUnspsc190501.42182705'),
	'identifierSchemeCodeUnspsc190501.42182706': __('identifierSchemeCodeUnspsc190501.42182706'),
	'identifierSchemeCodeUnspsc190501.42182707': __('identifierSchemeCodeUnspsc190501.42182707'),
	'identifierSchemeCodeUnspsc190501.42182800': __('identifierSchemeCodeUnspsc190501.42182800'),
	'identifierSchemeCodeUnspsc190501.42182801': __('identifierSchemeCodeUnspsc190501.42182801'),
	'identifierSchemeCodeUnspsc190501.42182802': __('identifierSchemeCodeUnspsc190501.42182802'),
	'identifierSchemeCodeUnspsc190501.42182803': __('identifierSchemeCodeUnspsc190501.42182803'),
	'identifierSchemeCodeUnspsc190501.42182804': __('identifierSchemeCodeUnspsc190501.42182804'),
	'identifierSchemeCodeUnspsc190501.42182805': __('identifierSchemeCodeUnspsc190501.42182805'),
	'identifierSchemeCodeUnspsc190501.42182806': __('identifierSchemeCodeUnspsc190501.42182806'),
	'identifierSchemeCodeUnspsc190501.42182807': __('identifierSchemeCodeUnspsc190501.42182807'),
	'identifierSchemeCodeUnspsc190501.42182808': __('identifierSchemeCodeUnspsc190501.42182808'),
	'identifierSchemeCodeUnspsc190501.42183000': __('identifierSchemeCodeUnspsc190501.42183000'),
	'identifierSchemeCodeUnspsc190501.42183001': __('identifierSchemeCodeUnspsc190501.42183001'),
	'identifierSchemeCodeUnspsc190501.42183002': __('identifierSchemeCodeUnspsc190501.42183002'),
	'identifierSchemeCodeUnspsc190501.42183003': __('identifierSchemeCodeUnspsc190501.42183003'),
	'identifierSchemeCodeUnspsc190501.42183005': __('identifierSchemeCodeUnspsc190501.42183005'),
	'identifierSchemeCodeUnspsc190501.42183006': __('identifierSchemeCodeUnspsc190501.42183006'),
	'identifierSchemeCodeUnspsc190501.42183009': __('identifierSchemeCodeUnspsc190501.42183009'),
	'identifierSchemeCodeUnspsc190501.42183010': __('identifierSchemeCodeUnspsc190501.42183010'),
	'identifierSchemeCodeUnspsc190501.42183011': __('identifierSchemeCodeUnspsc190501.42183011'),
	'identifierSchemeCodeUnspsc190501.42183012': __('identifierSchemeCodeUnspsc190501.42183012'),
	'identifierSchemeCodeUnspsc190501.42183013': __('identifierSchemeCodeUnspsc190501.42183013'),
	'identifierSchemeCodeUnspsc190501.42183015': __('identifierSchemeCodeUnspsc190501.42183015'),
	'identifierSchemeCodeUnspsc190501.42183016': __('identifierSchemeCodeUnspsc190501.42183016'),
	'identifierSchemeCodeUnspsc190501.42183017': __('identifierSchemeCodeUnspsc190501.42183017'),
	'identifierSchemeCodeUnspsc190501.42183018': __('identifierSchemeCodeUnspsc190501.42183018'),
	'identifierSchemeCodeUnspsc190501.42183019': __('identifierSchemeCodeUnspsc190501.42183019'),
	'identifierSchemeCodeUnspsc190501.42183020': __('identifierSchemeCodeUnspsc190501.42183020'),
	'identifierSchemeCodeUnspsc190501.42183021': __('identifierSchemeCodeUnspsc190501.42183021'),
	'identifierSchemeCodeUnspsc190501.42183022': __('identifierSchemeCodeUnspsc190501.42183022'),
	'identifierSchemeCodeUnspsc190501.42183023': __('identifierSchemeCodeUnspsc190501.42183023'),
	'identifierSchemeCodeUnspsc190501.42183024': __('identifierSchemeCodeUnspsc190501.42183024'),
	'identifierSchemeCodeUnspsc190501.42183026': __('identifierSchemeCodeUnspsc190501.42183026'),
	'identifierSchemeCodeUnspsc190501.42183027': __('identifierSchemeCodeUnspsc190501.42183027'),
	'identifierSchemeCodeUnspsc190501.42183029': __('identifierSchemeCodeUnspsc190501.42183029'),
	'identifierSchemeCodeUnspsc190501.42183030': __('identifierSchemeCodeUnspsc190501.42183030'),
	'identifierSchemeCodeUnspsc190501.42183031': __('identifierSchemeCodeUnspsc190501.42183031'),
	'identifierSchemeCodeUnspsc190501.42183033': __('identifierSchemeCodeUnspsc190501.42183033'),
	'identifierSchemeCodeUnspsc190501.42183034': __('identifierSchemeCodeUnspsc190501.42183034'),
	'identifierSchemeCodeUnspsc190501.42183035': __('identifierSchemeCodeUnspsc190501.42183035'),
	'identifierSchemeCodeUnspsc190501.42183036': __('identifierSchemeCodeUnspsc190501.42183036'),
	'identifierSchemeCodeUnspsc190501.42183037': __('identifierSchemeCodeUnspsc190501.42183037'),
	'identifierSchemeCodeUnspsc190501.42183038': __('identifierSchemeCodeUnspsc190501.42183038'),
	'identifierSchemeCodeUnspsc190501.42183039': __('identifierSchemeCodeUnspsc190501.42183039'),
	'identifierSchemeCodeUnspsc190501.42183040': __('identifierSchemeCodeUnspsc190501.42183040'),
	'identifierSchemeCodeUnspsc190501.42183041': __('identifierSchemeCodeUnspsc190501.42183041'),
	'identifierSchemeCodeUnspsc190501.42183042': __('identifierSchemeCodeUnspsc190501.42183042'),
	'identifierSchemeCodeUnspsc190501.42183044': __('identifierSchemeCodeUnspsc190501.42183044'),
	'identifierSchemeCodeUnspsc190501.42183045': __('identifierSchemeCodeUnspsc190501.42183045'),
	'identifierSchemeCodeUnspsc190501.42183046': __('identifierSchemeCodeUnspsc190501.42183046'),
	'identifierSchemeCodeUnspsc190501.42183047': __('identifierSchemeCodeUnspsc190501.42183047'),
	'identifierSchemeCodeUnspsc190501.42183048': __('identifierSchemeCodeUnspsc190501.42183048'),
	'identifierSchemeCodeUnspsc190501.42183050': __('identifierSchemeCodeUnspsc190501.42183050'),
	'identifierSchemeCodeUnspsc190501.42183051': __('identifierSchemeCodeUnspsc190501.42183051'),
	'identifierSchemeCodeUnspsc190501.42183052': __('identifierSchemeCodeUnspsc190501.42183052'),
	'identifierSchemeCodeUnspsc190501.42183053': __('identifierSchemeCodeUnspsc190501.42183053'),
	'identifierSchemeCodeUnspsc190501.42183054': __('identifierSchemeCodeUnspsc190501.42183054'),
	'identifierSchemeCodeUnspsc190501.42183055': __('identifierSchemeCodeUnspsc190501.42183055'),
	'identifierSchemeCodeUnspsc190501.42183057': __('identifierSchemeCodeUnspsc190501.42183057'),
	'identifierSchemeCodeUnspsc190501.42183058': __('identifierSchemeCodeUnspsc190501.42183058'),
	'identifierSchemeCodeUnspsc190501.42183059': __('identifierSchemeCodeUnspsc190501.42183059'),
	'identifierSchemeCodeUnspsc190501.42183060': __('identifierSchemeCodeUnspsc190501.42183060'),
	'identifierSchemeCodeUnspsc190501.42183061': __('identifierSchemeCodeUnspsc190501.42183061'),
	'identifierSchemeCodeUnspsc190501.42183063': __('identifierSchemeCodeUnspsc190501.42183063'),
	'identifierSchemeCodeUnspsc190501.42183065': __('identifierSchemeCodeUnspsc190501.42183065'),
	'identifierSchemeCodeUnspsc190501.42183067': __('identifierSchemeCodeUnspsc190501.42183067'),
	'identifierSchemeCodeUnspsc190501.42183068': __('identifierSchemeCodeUnspsc190501.42183068'),
	'identifierSchemeCodeUnspsc190501.42183069': __('identifierSchemeCodeUnspsc190501.42183069'),
	'identifierSchemeCodeUnspsc190501.42183070': __('identifierSchemeCodeUnspsc190501.42183070'),
	'identifierSchemeCodeUnspsc190501.42183071': __('identifierSchemeCodeUnspsc190501.42183071'),
	'identifierSchemeCodeUnspsc190501.42183072': __('identifierSchemeCodeUnspsc190501.42183072'),
	'identifierSchemeCodeUnspsc190501.42183073': __('identifierSchemeCodeUnspsc190501.42183073'),
	'identifierSchemeCodeUnspsc190501.42183074': __('identifierSchemeCodeUnspsc190501.42183074'),
	'identifierSchemeCodeUnspsc190501.42183075': __('identifierSchemeCodeUnspsc190501.42183075'),
	'identifierSchemeCodeUnspsc190501.42183076': __('identifierSchemeCodeUnspsc190501.42183076'),
	'identifierSchemeCodeUnspsc190501.42183077': __('identifierSchemeCodeUnspsc190501.42183077'),
	'identifierSchemeCodeUnspsc190501.42183078': __('identifierSchemeCodeUnspsc190501.42183078'),
	'identifierSchemeCodeUnspsc190501.42183079': __('identifierSchemeCodeUnspsc190501.42183079'),
	'identifierSchemeCodeUnspsc190501.42183080': __('identifierSchemeCodeUnspsc190501.42183080'),
	'identifierSchemeCodeUnspsc190501.42183081': __('identifierSchemeCodeUnspsc190501.42183081'),
	'identifierSchemeCodeUnspsc190501.42183082': __('identifierSchemeCodeUnspsc190501.42183082'),
	'identifierSchemeCodeUnspsc190501.42183083': __('identifierSchemeCodeUnspsc190501.42183083'),
	'identifierSchemeCodeUnspsc190501.42183084': __('identifierSchemeCodeUnspsc190501.42183084'),
	'identifierSchemeCodeUnspsc190501.42183085': __('identifierSchemeCodeUnspsc190501.42183085'),
	'identifierSchemeCodeUnspsc190501.42183086': __('identifierSchemeCodeUnspsc190501.42183086'),
	'identifierSchemeCodeUnspsc190501.42183087': __('identifierSchemeCodeUnspsc190501.42183087'),
	'identifierSchemeCodeUnspsc190501.42183088': __('identifierSchemeCodeUnspsc190501.42183088'),
	'identifierSchemeCodeUnspsc190501.42183089': __('identifierSchemeCodeUnspsc190501.42183089'),
	'identifierSchemeCodeUnspsc190501.42183090': __('identifierSchemeCodeUnspsc190501.42183090'),
	'identifierSchemeCodeUnspsc190501.42183091': __('identifierSchemeCodeUnspsc190501.42183091'),
	'identifierSchemeCodeUnspsc190501.42183200': __('identifierSchemeCodeUnspsc190501.42183200'),
	'identifierSchemeCodeUnspsc190501.42183201': __('identifierSchemeCodeUnspsc190501.42183201'),
	'identifierSchemeCodeUnspsc190501.42183202': __('identifierSchemeCodeUnspsc190501.42183202'),
	'identifierSchemeCodeUnspsc190501.42183400': __('identifierSchemeCodeUnspsc190501.42183400'),
	'identifierSchemeCodeUnspsc190501.42183401': __('identifierSchemeCodeUnspsc190501.42183401'),
	'identifierSchemeCodeUnspsc190501.42183402': __('identifierSchemeCodeUnspsc190501.42183402'),
	'identifierSchemeCodeUnspsc190501.42183403': __('identifierSchemeCodeUnspsc190501.42183403'),
	'identifierSchemeCodeUnspsc190501.42183404': __('identifierSchemeCodeUnspsc190501.42183404'),
	'identifierSchemeCodeUnspsc190501.42183500': __('identifierSchemeCodeUnspsc190501.42183500'),
	'identifierSchemeCodeUnspsc190501.42183501': __('identifierSchemeCodeUnspsc190501.42183501'),
	'identifierSchemeCodeUnspsc190501.42183502': __('identifierSchemeCodeUnspsc190501.42183502'),
	'identifierSchemeCodeUnspsc190501.42183503': __('identifierSchemeCodeUnspsc190501.42183503'),
	'identifierSchemeCodeUnspsc190501.42183504': __('identifierSchemeCodeUnspsc190501.42183504'),
	'identifierSchemeCodeUnspsc190501.42183505': __('identifierSchemeCodeUnspsc190501.42183505'),
	'identifierSchemeCodeUnspsc190501.42183506': __('identifierSchemeCodeUnspsc190501.42183506'),
	'identifierSchemeCodeUnspsc190501.42183507': __('identifierSchemeCodeUnspsc190501.42183507'),
	'identifierSchemeCodeUnspsc190501.42183508': __('identifierSchemeCodeUnspsc190501.42183508'),
	'identifierSchemeCodeUnspsc190501.42183509': __('identifierSchemeCodeUnspsc190501.42183509'),
	'identifierSchemeCodeUnspsc190501.42183510': __('identifierSchemeCodeUnspsc190501.42183510'),
	'identifierSchemeCodeUnspsc190501.42190000': __('identifierSchemeCodeUnspsc190501.42190000'),
	'identifierSchemeCodeUnspsc190501.42191500': __('identifierSchemeCodeUnspsc190501.42191500'),
	'identifierSchemeCodeUnspsc190501.42191501': __('identifierSchemeCodeUnspsc190501.42191501'),
	'identifierSchemeCodeUnspsc190501.42191502': __('identifierSchemeCodeUnspsc190501.42191502'),
	'identifierSchemeCodeUnspsc190501.42191600': __('identifierSchemeCodeUnspsc190501.42191600'),
	'identifierSchemeCodeUnspsc190501.42191601': __('identifierSchemeCodeUnspsc190501.42191601'),
	'identifierSchemeCodeUnspsc190501.42191602': __('identifierSchemeCodeUnspsc190501.42191602'),
	'identifierSchemeCodeUnspsc190501.42191603': __('identifierSchemeCodeUnspsc190501.42191603'),
	'identifierSchemeCodeUnspsc190501.42191604': __('identifierSchemeCodeUnspsc190501.42191604'),
	'identifierSchemeCodeUnspsc190501.42191605': __('identifierSchemeCodeUnspsc190501.42191605'),
	'identifierSchemeCodeUnspsc190501.42191606': __('identifierSchemeCodeUnspsc190501.42191606'),
	'identifierSchemeCodeUnspsc190501.42191607': __('identifierSchemeCodeUnspsc190501.42191607'),
	'identifierSchemeCodeUnspsc190501.42191608': __('identifierSchemeCodeUnspsc190501.42191608'),
	'identifierSchemeCodeUnspsc190501.42191609': __('identifierSchemeCodeUnspsc190501.42191609'),
	'identifierSchemeCodeUnspsc190501.42191610': __('identifierSchemeCodeUnspsc190501.42191610'),
	'identifierSchemeCodeUnspsc190501.42191611': __('identifierSchemeCodeUnspsc190501.42191611'),
	'identifierSchemeCodeUnspsc190501.42191612': __('identifierSchemeCodeUnspsc190501.42191612'),
	'identifierSchemeCodeUnspsc190501.42191613': __('identifierSchemeCodeUnspsc190501.42191613'),
	'identifierSchemeCodeUnspsc190501.42191614': __('identifierSchemeCodeUnspsc190501.42191614'),
	'identifierSchemeCodeUnspsc190501.42191615': __('identifierSchemeCodeUnspsc190501.42191615'),
	'identifierSchemeCodeUnspsc190501.42191616': __('identifierSchemeCodeUnspsc190501.42191616'),
	'identifierSchemeCodeUnspsc190501.42191617': __('identifierSchemeCodeUnspsc190501.42191617'),
	'identifierSchemeCodeUnspsc190501.42191700': __('identifierSchemeCodeUnspsc190501.42191700'),
	'identifierSchemeCodeUnspsc190501.42191701': __('identifierSchemeCodeUnspsc190501.42191701'),
	'identifierSchemeCodeUnspsc190501.42191702': __('identifierSchemeCodeUnspsc190501.42191702'),
	'identifierSchemeCodeUnspsc190501.42191703': __('identifierSchemeCodeUnspsc190501.42191703'),
	'identifierSchemeCodeUnspsc190501.42191704': __('identifierSchemeCodeUnspsc190501.42191704'),
	'identifierSchemeCodeUnspsc190501.42191705': __('identifierSchemeCodeUnspsc190501.42191705'),
	'identifierSchemeCodeUnspsc190501.42191706': __('identifierSchemeCodeUnspsc190501.42191706'),
	'identifierSchemeCodeUnspsc190501.42191707': __('identifierSchemeCodeUnspsc190501.42191707'),
	'identifierSchemeCodeUnspsc190501.42191708': __('identifierSchemeCodeUnspsc190501.42191708'),
	'identifierSchemeCodeUnspsc190501.42191709': __('identifierSchemeCodeUnspsc190501.42191709'),
	'identifierSchemeCodeUnspsc190501.42191710': __('identifierSchemeCodeUnspsc190501.42191710'),
	'identifierSchemeCodeUnspsc190501.42191711': __('identifierSchemeCodeUnspsc190501.42191711'),
	'identifierSchemeCodeUnspsc190501.42191712': __('identifierSchemeCodeUnspsc190501.42191712'),
	'identifierSchemeCodeUnspsc190501.42191713': __('identifierSchemeCodeUnspsc190501.42191713'),
	'identifierSchemeCodeUnspsc190501.42191800': __('identifierSchemeCodeUnspsc190501.42191800'),
	'identifierSchemeCodeUnspsc190501.42191801': __('identifierSchemeCodeUnspsc190501.42191801'),
	'identifierSchemeCodeUnspsc190501.42191802': __('identifierSchemeCodeUnspsc190501.42191802'),
	'identifierSchemeCodeUnspsc190501.42191803': __('identifierSchemeCodeUnspsc190501.42191803'),
	'identifierSchemeCodeUnspsc190501.42191804': __('identifierSchemeCodeUnspsc190501.42191804'),
	'identifierSchemeCodeUnspsc190501.42191805': __('identifierSchemeCodeUnspsc190501.42191805'),
	'identifierSchemeCodeUnspsc190501.42191806': __('identifierSchemeCodeUnspsc190501.42191806'),
	'identifierSchemeCodeUnspsc190501.42191807': __('identifierSchemeCodeUnspsc190501.42191807'),
	'identifierSchemeCodeUnspsc190501.42191808': __('identifierSchemeCodeUnspsc190501.42191808'),
	'identifierSchemeCodeUnspsc190501.42191810': __('identifierSchemeCodeUnspsc190501.42191810'),
	'identifierSchemeCodeUnspsc190501.42191811': __('identifierSchemeCodeUnspsc190501.42191811'),
	'identifierSchemeCodeUnspsc190501.42191812': __('identifierSchemeCodeUnspsc190501.42191812'),
	'identifierSchemeCodeUnspsc190501.42191813': __('identifierSchemeCodeUnspsc190501.42191813'),
	'identifierSchemeCodeUnspsc190501.42191814': __('identifierSchemeCodeUnspsc190501.42191814'),
	'identifierSchemeCodeUnspsc190501.42191815': __('identifierSchemeCodeUnspsc190501.42191815'),
	'identifierSchemeCodeUnspsc190501.42191816': __('identifierSchemeCodeUnspsc190501.42191816'),
	'identifierSchemeCodeUnspsc190501.42191817': __('identifierSchemeCodeUnspsc190501.42191817'),
	'identifierSchemeCodeUnspsc190501.42191818': __('identifierSchemeCodeUnspsc190501.42191818'),
	'identifierSchemeCodeUnspsc190501.42191819': __('identifierSchemeCodeUnspsc190501.42191819'),
	'identifierSchemeCodeUnspsc190501.42191820': __('identifierSchemeCodeUnspsc190501.42191820'),
	'identifierSchemeCodeUnspsc190501.42191900': __('identifierSchemeCodeUnspsc190501.42191900'),
	'identifierSchemeCodeUnspsc190501.42191901': __('identifierSchemeCodeUnspsc190501.42191901'),
	'identifierSchemeCodeUnspsc190501.42191902': __('identifierSchemeCodeUnspsc190501.42191902'),
	'identifierSchemeCodeUnspsc190501.42191903': __('identifierSchemeCodeUnspsc190501.42191903'),
	'identifierSchemeCodeUnspsc190501.42191904': __('identifierSchemeCodeUnspsc190501.42191904'),
	'identifierSchemeCodeUnspsc190501.42191905': __('identifierSchemeCodeUnspsc190501.42191905'),
	'identifierSchemeCodeUnspsc190501.42191906': __('identifierSchemeCodeUnspsc190501.42191906'),
	'identifierSchemeCodeUnspsc190501.42191907': __('identifierSchemeCodeUnspsc190501.42191907'),
	'identifierSchemeCodeUnspsc190501.42191908': __('identifierSchemeCodeUnspsc190501.42191908'),
	'identifierSchemeCodeUnspsc190501.42191909': __('identifierSchemeCodeUnspsc190501.42191909'),
	'identifierSchemeCodeUnspsc190501.42191910': __('identifierSchemeCodeUnspsc190501.42191910'),
	'identifierSchemeCodeUnspsc190501.42191911': __('identifierSchemeCodeUnspsc190501.42191911'),
	'identifierSchemeCodeUnspsc190501.42191912': __('identifierSchemeCodeUnspsc190501.42191912'),
	'identifierSchemeCodeUnspsc190501.42191913': __('identifierSchemeCodeUnspsc190501.42191913'),
	'identifierSchemeCodeUnspsc190501.42191914': __('identifierSchemeCodeUnspsc190501.42191914'),
	'identifierSchemeCodeUnspsc190501.42192000': __('identifierSchemeCodeUnspsc190501.42192000'),
	'identifierSchemeCodeUnspsc190501.42192001': __('identifierSchemeCodeUnspsc190501.42192001'),
	'identifierSchemeCodeUnspsc190501.42192002': __('identifierSchemeCodeUnspsc190501.42192002'),
	'identifierSchemeCodeUnspsc190501.42192003': __('identifierSchemeCodeUnspsc190501.42192003'),
	'identifierSchemeCodeUnspsc190501.42192004': __('identifierSchemeCodeUnspsc190501.42192004'),
	'identifierSchemeCodeUnspsc190501.42192005': __('identifierSchemeCodeUnspsc190501.42192005'),
	'identifierSchemeCodeUnspsc190501.42192006': __('identifierSchemeCodeUnspsc190501.42192006'),
	'identifierSchemeCodeUnspsc190501.42192100': __('identifierSchemeCodeUnspsc190501.42192100'),
	'identifierSchemeCodeUnspsc190501.42192101': __('identifierSchemeCodeUnspsc190501.42192101'),
	'identifierSchemeCodeUnspsc190501.42192102': __('identifierSchemeCodeUnspsc190501.42192102'),
	'identifierSchemeCodeUnspsc190501.42192103': __('identifierSchemeCodeUnspsc190501.42192103'),
	'identifierSchemeCodeUnspsc190501.42192104': __('identifierSchemeCodeUnspsc190501.42192104'),
	'identifierSchemeCodeUnspsc190501.42192106': __('identifierSchemeCodeUnspsc190501.42192106'),
	'identifierSchemeCodeUnspsc190501.42192107': __('identifierSchemeCodeUnspsc190501.42192107'),
	'identifierSchemeCodeUnspsc190501.42192108': __('identifierSchemeCodeUnspsc190501.42192108'),
	'identifierSchemeCodeUnspsc190501.42192109': __('identifierSchemeCodeUnspsc190501.42192109'),
	'identifierSchemeCodeUnspsc190501.42192110': __('identifierSchemeCodeUnspsc190501.42192110'),
	'identifierSchemeCodeUnspsc190501.42192111': __('identifierSchemeCodeUnspsc190501.42192111'),
	'identifierSchemeCodeUnspsc190501.42192112': __('identifierSchemeCodeUnspsc190501.42192112'),
	'identifierSchemeCodeUnspsc190501.42192200': __('identifierSchemeCodeUnspsc190501.42192200'),
	'identifierSchemeCodeUnspsc190501.42192201': __('identifierSchemeCodeUnspsc190501.42192201'),
	'identifierSchemeCodeUnspsc190501.42192202': __('identifierSchemeCodeUnspsc190501.42192202'),
	'identifierSchemeCodeUnspsc190501.42192203': __('identifierSchemeCodeUnspsc190501.42192203'),
	'identifierSchemeCodeUnspsc190501.42192204': __('identifierSchemeCodeUnspsc190501.42192204'),
	'identifierSchemeCodeUnspsc190501.42192205': __('identifierSchemeCodeUnspsc190501.42192205'),
	'identifierSchemeCodeUnspsc190501.42192206': __('identifierSchemeCodeUnspsc190501.42192206'),
	'identifierSchemeCodeUnspsc190501.42192207': __('identifierSchemeCodeUnspsc190501.42192207'),
	'identifierSchemeCodeUnspsc190501.42192208': __('identifierSchemeCodeUnspsc190501.42192208'),
	'identifierSchemeCodeUnspsc190501.42192209': __('identifierSchemeCodeUnspsc190501.42192209'),
	'identifierSchemeCodeUnspsc190501.42192210': __('identifierSchemeCodeUnspsc190501.42192210'),
	'identifierSchemeCodeUnspsc190501.42192211': __('identifierSchemeCodeUnspsc190501.42192211'),
	'identifierSchemeCodeUnspsc190501.42192212': __('identifierSchemeCodeUnspsc190501.42192212'),
	'identifierSchemeCodeUnspsc190501.42192214': __('identifierSchemeCodeUnspsc190501.42192214'),
	'identifierSchemeCodeUnspsc190501.42192215': __('identifierSchemeCodeUnspsc190501.42192215'),
	'identifierSchemeCodeUnspsc190501.42192216': __('identifierSchemeCodeUnspsc190501.42192216'),
	'identifierSchemeCodeUnspsc190501.42192217': __('identifierSchemeCodeUnspsc190501.42192217'),
	'identifierSchemeCodeUnspsc190501.42192218': __('identifierSchemeCodeUnspsc190501.42192218'),
	'identifierSchemeCodeUnspsc190501.42192219': __('identifierSchemeCodeUnspsc190501.42192219'),
	'identifierSchemeCodeUnspsc190501.42192220': __('identifierSchemeCodeUnspsc190501.42192220'),
	'identifierSchemeCodeUnspsc190501.42192221': __('identifierSchemeCodeUnspsc190501.42192221'),
	'identifierSchemeCodeUnspsc190501.42192300': __('identifierSchemeCodeUnspsc190501.42192300'),
	'identifierSchemeCodeUnspsc190501.42192301': __('identifierSchemeCodeUnspsc190501.42192301'),
	'identifierSchemeCodeUnspsc190501.42192302': __('identifierSchemeCodeUnspsc190501.42192302'),
	'identifierSchemeCodeUnspsc190501.42192303': __('identifierSchemeCodeUnspsc190501.42192303'),
	'identifierSchemeCodeUnspsc190501.42192304': __('identifierSchemeCodeUnspsc190501.42192304'),
	'identifierSchemeCodeUnspsc190501.42192305': __('identifierSchemeCodeUnspsc190501.42192305'),
	'identifierSchemeCodeUnspsc190501.42192306': __('identifierSchemeCodeUnspsc190501.42192306'),
	'identifierSchemeCodeUnspsc190501.42192307': __('identifierSchemeCodeUnspsc190501.42192307'),
	'identifierSchemeCodeUnspsc190501.42192308': __('identifierSchemeCodeUnspsc190501.42192308'),
	'identifierSchemeCodeUnspsc190501.42192400': __('identifierSchemeCodeUnspsc190501.42192400'),
	'identifierSchemeCodeUnspsc190501.42192401': __('identifierSchemeCodeUnspsc190501.42192401'),
	'identifierSchemeCodeUnspsc190501.42192402': __('identifierSchemeCodeUnspsc190501.42192402'),
	'identifierSchemeCodeUnspsc190501.42192403': __('identifierSchemeCodeUnspsc190501.42192403'),
	'identifierSchemeCodeUnspsc190501.42192404': __('identifierSchemeCodeUnspsc190501.42192404'),
	'identifierSchemeCodeUnspsc190501.42192405': __('identifierSchemeCodeUnspsc190501.42192405'),
	'identifierSchemeCodeUnspsc190501.42192406': __('identifierSchemeCodeUnspsc190501.42192406'),
	'identifierSchemeCodeUnspsc190501.42192407': __('identifierSchemeCodeUnspsc190501.42192407'),
	'identifierSchemeCodeUnspsc190501.42192408': __('identifierSchemeCodeUnspsc190501.42192408'),
	'identifierSchemeCodeUnspsc190501.42192409': __('identifierSchemeCodeUnspsc190501.42192409'),
	'identifierSchemeCodeUnspsc190501.42192410': __('identifierSchemeCodeUnspsc190501.42192410'),
	'identifierSchemeCodeUnspsc190501.42192411': __('identifierSchemeCodeUnspsc190501.42192411'),
	'identifierSchemeCodeUnspsc190501.42192412': __('identifierSchemeCodeUnspsc190501.42192412'),
	'identifierSchemeCodeUnspsc190501.42192413': __('identifierSchemeCodeUnspsc190501.42192413'),
	'identifierSchemeCodeUnspsc190501.42192414': __('identifierSchemeCodeUnspsc190501.42192414'),
	'identifierSchemeCodeUnspsc190501.42192415': __('identifierSchemeCodeUnspsc190501.42192415'),
	'identifierSchemeCodeUnspsc190501.42192416': __('identifierSchemeCodeUnspsc190501.42192416'),
	'identifierSchemeCodeUnspsc190501.42192417': __('identifierSchemeCodeUnspsc190501.42192417'),
	'identifierSchemeCodeUnspsc190501.42192418': __('identifierSchemeCodeUnspsc190501.42192418'),
	'identifierSchemeCodeUnspsc190501.42192419': __('identifierSchemeCodeUnspsc190501.42192419'),
	'identifierSchemeCodeUnspsc190501.42192420': __('identifierSchemeCodeUnspsc190501.42192420'),
	'identifierSchemeCodeUnspsc190501.42192421': __('identifierSchemeCodeUnspsc190501.42192421'),
	'identifierSchemeCodeUnspsc190501.42192422': __('identifierSchemeCodeUnspsc190501.42192422'),
	'identifierSchemeCodeUnspsc190501.42192423': __('identifierSchemeCodeUnspsc190501.42192423'),
	'identifierSchemeCodeUnspsc190501.42192424': __('identifierSchemeCodeUnspsc190501.42192424'),
	'identifierSchemeCodeUnspsc190501.42192425': __('identifierSchemeCodeUnspsc190501.42192425'),
	'identifierSchemeCodeUnspsc190501.42192426': __('identifierSchemeCodeUnspsc190501.42192426'),
	'identifierSchemeCodeUnspsc190501.42192427': __('identifierSchemeCodeUnspsc190501.42192427'),
	'identifierSchemeCodeUnspsc190501.42192428': __('identifierSchemeCodeUnspsc190501.42192428'),
	'identifierSchemeCodeUnspsc190501.42192429': __('identifierSchemeCodeUnspsc190501.42192429'),
	'identifierSchemeCodeUnspsc190501.42192430': __('identifierSchemeCodeUnspsc190501.42192430'),
	'identifierSchemeCodeUnspsc190501.42192431': __('identifierSchemeCodeUnspsc190501.42192431'),
	'identifierSchemeCodeUnspsc190501.42192500': __('identifierSchemeCodeUnspsc190501.42192500'),
	'identifierSchemeCodeUnspsc190501.42192501': __('identifierSchemeCodeUnspsc190501.42192501'),
	'identifierSchemeCodeUnspsc190501.42192502': __('identifierSchemeCodeUnspsc190501.42192502'),
	'identifierSchemeCodeUnspsc190501.42192600': __('identifierSchemeCodeUnspsc190501.42192600'),
	'identifierSchemeCodeUnspsc190501.42192601': __('identifierSchemeCodeUnspsc190501.42192601'),
	'identifierSchemeCodeUnspsc190501.42192602': __('identifierSchemeCodeUnspsc190501.42192602'),
	'identifierSchemeCodeUnspsc190501.42192603': __('identifierSchemeCodeUnspsc190501.42192603'),
	'identifierSchemeCodeUnspsc190501.42192604': __('identifierSchemeCodeUnspsc190501.42192604'),
	'identifierSchemeCodeUnspsc190501.42192606': __('identifierSchemeCodeUnspsc190501.42192606'),
	'identifierSchemeCodeUnspsc190501.42192607': __('identifierSchemeCodeUnspsc190501.42192607'),
	'identifierSchemeCodeUnspsc190501.42192608': __('identifierSchemeCodeUnspsc190501.42192608'),
	'identifierSchemeCodeUnspsc190501.42192609': __('identifierSchemeCodeUnspsc190501.42192609'),
	'identifierSchemeCodeUnspsc190501.42192610': __('identifierSchemeCodeUnspsc190501.42192610'),
	'identifierSchemeCodeUnspsc190501.42192611': __('identifierSchemeCodeUnspsc190501.42192611'),
	'identifierSchemeCodeUnspsc190501.42192612': __('identifierSchemeCodeUnspsc190501.42192612'),
	'identifierSchemeCodeUnspsc190501.42192613': __('identifierSchemeCodeUnspsc190501.42192613'),
	'identifierSchemeCodeUnspsc190501.42192614': __('identifierSchemeCodeUnspsc190501.42192614'),
	'identifierSchemeCodeUnspsc190501.42192615': __('identifierSchemeCodeUnspsc190501.42192615'),
	'identifierSchemeCodeUnspsc190501.42192616': __('identifierSchemeCodeUnspsc190501.42192616'),
	'identifierSchemeCodeUnspsc190501.42192617': __('identifierSchemeCodeUnspsc190501.42192617'),
	'identifierSchemeCodeUnspsc190501.42192618': __('identifierSchemeCodeUnspsc190501.42192618'),
	'identifierSchemeCodeUnspsc190501.42192700': __('identifierSchemeCodeUnspsc190501.42192700'),
	'identifierSchemeCodeUnspsc190501.42192701': __('identifierSchemeCodeUnspsc190501.42192701'),
	'identifierSchemeCodeUnspsc190501.42192702': __('identifierSchemeCodeUnspsc190501.42192702'),
	'identifierSchemeCodeUnspsc190501.42192703': __('identifierSchemeCodeUnspsc190501.42192703'),
	'identifierSchemeCodeUnspsc190501.42192704': __('identifierSchemeCodeUnspsc190501.42192704'),
	'identifierSchemeCodeUnspsc190501.42192705': __('identifierSchemeCodeUnspsc190501.42192705'),
	'identifierSchemeCodeUnspsc190501.42192706': __('identifierSchemeCodeUnspsc190501.42192706'),
	'identifierSchemeCodeUnspsc190501.42192707': __('identifierSchemeCodeUnspsc190501.42192707'),
	'identifierSchemeCodeUnspsc190501.42200000': __('identifierSchemeCodeUnspsc190501.42200000'),
	'identifierSchemeCodeUnspsc190501.42201500': __('identifierSchemeCodeUnspsc190501.42201500'),
	'identifierSchemeCodeUnspsc190501.42201501': __('identifierSchemeCodeUnspsc190501.42201501'),
	'identifierSchemeCodeUnspsc190501.42201502': __('identifierSchemeCodeUnspsc190501.42201502'),
	'identifierSchemeCodeUnspsc190501.42201503': __('identifierSchemeCodeUnspsc190501.42201503'),
	'identifierSchemeCodeUnspsc190501.42201504': __('identifierSchemeCodeUnspsc190501.42201504'),
	'identifierSchemeCodeUnspsc190501.42201505': __('identifierSchemeCodeUnspsc190501.42201505'),
	'identifierSchemeCodeUnspsc190501.42201507': __('identifierSchemeCodeUnspsc190501.42201507'),
	'identifierSchemeCodeUnspsc190501.42201508': __('identifierSchemeCodeUnspsc190501.42201508'),
	'identifierSchemeCodeUnspsc190501.42201509': __('identifierSchemeCodeUnspsc190501.42201509'),
	'identifierSchemeCodeUnspsc190501.42201510': __('identifierSchemeCodeUnspsc190501.42201510'),
	'identifierSchemeCodeUnspsc190501.42201511': __('identifierSchemeCodeUnspsc190501.42201511'),
	'identifierSchemeCodeUnspsc190501.42201512': __('identifierSchemeCodeUnspsc190501.42201512'),
	'identifierSchemeCodeUnspsc190501.42201513': __('identifierSchemeCodeUnspsc190501.42201513'),
	'identifierSchemeCodeUnspsc190501.42201514': __('identifierSchemeCodeUnspsc190501.42201514'),
	'identifierSchemeCodeUnspsc190501.42201515': __('identifierSchemeCodeUnspsc190501.42201515'),
	'identifierSchemeCodeUnspsc190501.42201600': __('identifierSchemeCodeUnspsc190501.42201600'),
	'identifierSchemeCodeUnspsc190501.42201601': __('identifierSchemeCodeUnspsc190501.42201601'),
	'identifierSchemeCodeUnspsc190501.42201602': __('identifierSchemeCodeUnspsc190501.42201602'),
	'identifierSchemeCodeUnspsc190501.42201603': __('identifierSchemeCodeUnspsc190501.42201603'),
	'identifierSchemeCodeUnspsc190501.42201604': __('identifierSchemeCodeUnspsc190501.42201604'),
	'identifierSchemeCodeUnspsc190501.42201605': __('identifierSchemeCodeUnspsc190501.42201605'),
	'identifierSchemeCodeUnspsc190501.42201607': __('identifierSchemeCodeUnspsc190501.42201607'),
	'identifierSchemeCodeUnspsc190501.42201608': __('identifierSchemeCodeUnspsc190501.42201608'),
	'identifierSchemeCodeUnspsc190501.42201609': __('identifierSchemeCodeUnspsc190501.42201609'),
	'identifierSchemeCodeUnspsc190501.42201610': __('identifierSchemeCodeUnspsc190501.42201610'),
	'identifierSchemeCodeUnspsc190501.42201611': __('identifierSchemeCodeUnspsc190501.42201611'),
	'identifierSchemeCodeUnspsc190501.42201612': __('identifierSchemeCodeUnspsc190501.42201612'),
	'identifierSchemeCodeUnspsc190501.42201613': __('identifierSchemeCodeUnspsc190501.42201613'),
	'identifierSchemeCodeUnspsc190501.42201700': __('identifierSchemeCodeUnspsc190501.42201700'),
	'identifierSchemeCodeUnspsc190501.42201704': __('identifierSchemeCodeUnspsc190501.42201704'),
	'identifierSchemeCodeUnspsc190501.42201705': __('identifierSchemeCodeUnspsc190501.42201705'),
	'identifierSchemeCodeUnspsc190501.42201706': __('identifierSchemeCodeUnspsc190501.42201706'),
	'identifierSchemeCodeUnspsc190501.42201707': __('identifierSchemeCodeUnspsc190501.42201707'),
	'identifierSchemeCodeUnspsc190501.42201708': __('identifierSchemeCodeUnspsc190501.42201708'),
	'identifierSchemeCodeUnspsc190501.42201709': __('identifierSchemeCodeUnspsc190501.42201709'),
	'identifierSchemeCodeUnspsc190501.42201710': __('identifierSchemeCodeUnspsc190501.42201710'),
	'identifierSchemeCodeUnspsc190501.42201711': __('identifierSchemeCodeUnspsc190501.42201711'),
	'identifierSchemeCodeUnspsc190501.42201712': __('identifierSchemeCodeUnspsc190501.42201712'),
	'identifierSchemeCodeUnspsc190501.42201713': __('identifierSchemeCodeUnspsc190501.42201713'),
	'identifierSchemeCodeUnspsc190501.42201718': __('identifierSchemeCodeUnspsc190501.42201718'),
	'identifierSchemeCodeUnspsc190501.42201720': __('identifierSchemeCodeUnspsc190501.42201720'),
	'identifierSchemeCodeUnspsc190501.42201721': __('identifierSchemeCodeUnspsc190501.42201721'),
	'identifierSchemeCodeUnspsc190501.42201722': __('identifierSchemeCodeUnspsc190501.42201722'),
	'identifierSchemeCodeUnspsc190501.42201723': __('identifierSchemeCodeUnspsc190501.42201723'),
	'identifierSchemeCodeUnspsc190501.42201724': __('identifierSchemeCodeUnspsc190501.42201724'),
	'identifierSchemeCodeUnspsc190501.42201725': __('identifierSchemeCodeUnspsc190501.42201725'),
	'identifierSchemeCodeUnspsc190501.42201726': __('identifierSchemeCodeUnspsc190501.42201726'),
	'identifierSchemeCodeUnspsc190501.42201727': __('identifierSchemeCodeUnspsc190501.42201727'),
	'identifierSchemeCodeUnspsc190501.42201800': __('identifierSchemeCodeUnspsc190501.42201800'),
	'identifierSchemeCodeUnspsc190501.42201803': __('identifierSchemeCodeUnspsc190501.42201803'),
	'identifierSchemeCodeUnspsc190501.42201804': __('identifierSchemeCodeUnspsc190501.42201804'),
	'identifierSchemeCodeUnspsc190501.42201805': __('identifierSchemeCodeUnspsc190501.42201805'),
	'identifierSchemeCodeUnspsc190501.42201806': __('identifierSchemeCodeUnspsc190501.42201806'),
	'identifierSchemeCodeUnspsc190501.42201807': __('identifierSchemeCodeUnspsc190501.42201807'),
	'identifierSchemeCodeUnspsc190501.42201808': __('identifierSchemeCodeUnspsc190501.42201808'),
	'identifierSchemeCodeUnspsc190501.42201809': __('identifierSchemeCodeUnspsc190501.42201809'),
	'identifierSchemeCodeUnspsc190501.42201810': __('identifierSchemeCodeUnspsc190501.42201810'),
	'identifierSchemeCodeUnspsc190501.42201811': __('identifierSchemeCodeUnspsc190501.42201811'),
	'identifierSchemeCodeUnspsc190501.42201812': __('identifierSchemeCodeUnspsc190501.42201812'),
	'identifierSchemeCodeUnspsc190501.42201813': __('identifierSchemeCodeUnspsc190501.42201813'),
	'identifierSchemeCodeUnspsc190501.42201814': __('identifierSchemeCodeUnspsc190501.42201814'),
	'identifierSchemeCodeUnspsc190501.42201815': __('identifierSchemeCodeUnspsc190501.42201815'),
	'identifierSchemeCodeUnspsc190501.42201816': __('identifierSchemeCodeUnspsc190501.42201816'),
	'identifierSchemeCodeUnspsc190501.42201817': __('identifierSchemeCodeUnspsc190501.42201817'),
	'identifierSchemeCodeUnspsc190501.42201818': __('identifierSchemeCodeUnspsc190501.42201818'),
	'identifierSchemeCodeUnspsc190501.42201819': __('identifierSchemeCodeUnspsc190501.42201819'),
	'identifierSchemeCodeUnspsc190501.42201820': __('identifierSchemeCodeUnspsc190501.42201820'),
	'identifierSchemeCodeUnspsc190501.42201821': __('identifierSchemeCodeUnspsc190501.42201821'),
	'identifierSchemeCodeUnspsc190501.42201822': __('identifierSchemeCodeUnspsc190501.42201822'),
	'identifierSchemeCodeUnspsc190501.42201823': __('identifierSchemeCodeUnspsc190501.42201823'),
	'identifierSchemeCodeUnspsc190501.42201824': __('identifierSchemeCodeUnspsc190501.42201824'),
	'identifierSchemeCodeUnspsc190501.42201825': __('identifierSchemeCodeUnspsc190501.42201825'),
	'identifierSchemeCodeUnspsc190501.42201826': __('identifierSchemeCodeUnspsc190501.42201826'),
	'identifierSchemeCodeUnspsc190501.42201827': __('identifierSchemeCodeUnspsc190501.42201827'),
	'identifierSchemeCodeUnspsc190501.42201828': __('identifierSchemeCodeUnspsc190501.42201828'),
	'identifierSchemeCodeUnspsc190501.42201829': __('identifierSchemeCodeUnspsc190501.42201829'),
	'identifierSchemeCodeUnspsc190501.42201830': __('identifierSchemeCodeUnspsc190501.42201830'),
	'identifierSchemeCodeUnspsc190501.42201831': __('identifierSchemeCodeUnspsc190501.42201831'),
	'identifierSchemeCodeUnspsc190501.42201832': __('identifierSchemeCodeUnspsc190501.42201832'),
	'identifierSchemeCodeUnspsc190501.42201833': __('identifierSchemeCodeUnspsc190501.42201833'),
	'identifierSchemeCodeUnspsc190501.42201834': __('identifierSchemeCodeUnspsc190501.42201834'),
	'identifierSchemeCodeUnspsc190501.42201835': __('identifierSchemeCodeUnspsc190501.42201835'),
	'identifierSchemeCodeUnspsc190501.42201836': __('identifierSchemeCodeUnspsc190501.42201836'),
	'identifierSchemeCodeUnspsc190501.42201837': __('identifierSchemeCodeUnspsc190501.42201837'),
	'identifierSchemeCodeUnspsc190501.42201838': __('identifierSchemeCodeUnspsc190501.42201838'),
	'identifierSchemeCodeUnspsc190501.42201839': __('identifierSchemeCodeUnspsc190501.42201839'),
	'identifierSchemeCodeUnspsc190501.42201840': __('identifierSchemeCodeUnspsc190501.42201840'),
	'identifierSchemeCodeUnspsc190501.42201841': __('identifierSchemeCodeUnspsc190501.42201841'),
	'identifierSchemeCodeUnspsc190501.42201843': __('identifierSchemeCodeUnspsc190501.42201843'),
	'identifierSchemeCodeUnspsc190501.42201844': __('identifierSchemeCodeUnspsc190501.42201844'),
	'identifierSchemeCodeUnspsc190501.42201845': __('identifierSchemeCodeUnspsc190501.42201845'),
	'identifierSchemeCodeUnspsc190501.42201846': __('identifierSchemeCodeUnspsc190501.42201846'),
	'identifierSchemeCodeUnspsc190501.42201847': __('identifierSchemeCodeUnspsc190501.42201847'),
	'identifierSchemeCodeUnspsc190501.42201848': __('identifierSchemeCodeUnspsc190501.42201848'),
	'identifierSchemeCodeUnspsc190501.42201849': __('identifierSchemeCodeUnspsc190501.42201849'),
	'identifierSchemeCodeUnspsc190501.42201850': __('identifierSchemeCodeUnspsc190501.42201850'),
	'identifierSchemeCodeUnspsc190501.42201851': __('identifierSchemeCodeUnspsc190501.42201851'),
	'identifierSchemeCodeUnspsc190501.42201852': __('identifierSchemeCodeUnspsc190501.42201852'),
	'identifierSchemeCodeUnspsc190501.42201853': __('identifierSchemeCodeUnspsc190501.42201853'),
	'identifierSchemeCodeUnspsc190501.42201854': __('identifierSchemeCodeUnspsc190501.42201854'),
	'identifierSchemeCodeUnspsc190501.42201855': __('identifierSchemeCodeUnspsc190501.42201855'),
	'identifierSchemeCodeUnspsc190501.42201856': __('identifierSchemeCodeUnspsc190501.42201856'),
	'identifierSchemeCodeUnspsc190501.42201857': __('identifierSchemeCodeUnspsc190501.42201857'),
	'identifierSchemeCodeUnspsc190501.42201858': __('identifierSchemeCodeUnspsc190501.42201858'),
	'identifierSchemeCodeUnspsc190501.42201859': __('identifierSchemeCodeUnspsc190501.42201859'),
	'identifierSchemeCodeUnspsc190501.42201860': __('identifierSchemeCodeUnspsc190501.42201860'),
	'identifierSchemeCodeUnspsc190501.42201861': __('identifierSchemeCodeUnspsc190501.42201861'),
	'identifierSchemeCodeUnspsc190501.42201862': __('identifierSchemeCodeUnspsc190501.42201862'),
	'identifierSchemeCodeUnspsc190501.42201863': __('identifierSchemeCodeUnspsc190501.42201863'),
	'identifierSchemeCodeUnspsc190501.42201864': __('identifierSchemeCodeUnspsc190501.42201864'),
	'identifierSchemeCodeUnspsc190501.42201865': __('identifierSchemeCodeUnspsc190501.42201865'),
	'identifierSchemeCodeUnspsc190501.42201900': __('identifierSchemeCodeUnspsc190501.42201900'),
	'identifierSchemeCodeUnspsc190501.42201901': __('identifierSchemeCodeUnspsc190501.42201901'),
	'identifierSchemeCodeUnspsc190501.42201902': __('identifierSchemeCodeUnspsc190501.42201902'),
	'identifierSchemeCodeUnspsc190501.42201903': __('identifierSchemeCodeUnspsc190501.42201903'),
	'identifierSchemeCodeUnspsc190501.42201904': __('identifierSchemeCodeUnspsc190501.42201904'),
	'identifierSchemeCodeUnspsc190501.42201905': __('identifierSchemeCodeUnspsc190501.42201905'),
	'identifierSchemeCodeUnspsc190501.42201906': __('identifierSchemeCodeUnspsc190501.42201906'),
	'identifierSchemeCodeUnspsc190501.42201907': __('identifierSchemeCodeUnspsc190501.42201907'),
	'identifierSchemeCodeUnspsc190501.42201908': __('identifierSchemeCodeUnspsc190501.42201908'),
	'identifierSchemeCodeUnspsc190501.42202000': __('identifierSchemeCodeUnspsc190501.42202000'),
	'identifierSchemeCodeUnspsc190501.42202001': __('identifierSchemeCodeUnspsc190501.42202001'),
	'identifierSchemeCodeUnspsc190501.42202002': __('identifierSchemeCodeUnspsc190501.42202002'),
	'identifierSchemeCodeUnspsc190501.42202003': __('identifierSchemeCodeUnspsc190501.42202003'),
	'identifierSchemeCodeUnspsc190501.42202004': __('identifierSchemeCodeUnspsc190501.42202004'),
	'identifierSchemeCodeUnspsc190501.42202005': __('identifierSchemeCodeUnspsc190501.42202005'),
	'identifierSchemeCodeUnspsc190501.42202006': __('identifierSchemeCodeUnspsc190501.42202006'),
	'identifierSchemeCodeUnspsc190501.42202007': __('identifierSchemeCodeUnspsc190501.42202007'),
	'identifierSchemeCodeUnspsc190501.42202008': __('identifierSchemeCodeUnspsc190501.42202008'),
	'identifierSchemeCodeUnspsc190501.42202100': __('identifierSchemeCodeUnspsc190501.42202100'),
	'identifierSchemeCodeUnspsc190501.42202101': __('identifierSchemeCodeUnspsc190501.42202101'),
	'identifierSchemeCodeUnspsc190501.42202102': __('identifierSchemeCodeUnspsc190501.42202102'),
	'identifierSchemeCodeUnspsc190501.42202103': __('identifierSchemeCodeUnspsc190501.42202103'),
	'identifierSchemeCodeUnspsc190501.42202104': __('identifierSchemeCodeUnspsc190501.42202104'),
	'identifierSchemeCodeUnspsc190501.42202105': __('identifierSchemeCodeUnspsc190501.42202105'),
	'identifierSchemeCodeUnspsc190501.42202106': __('identifierSchemeCodeUnspsc190501.42202106'),
	'identifierSchemeCodeUnspsc190501.42202107': __('identifierSchemeCodeUnspsc190501.42202107'),
	'identifierSchemeCodeUnspsc190501.42202108': __('identifierSchemeCodeUnspsc190501.42202108'),
	'identifierSchemeCodeUnspsc190501.42202200': __('identifierSchemeCodeUnspsc190501.42202200'),
	'identifierSchemeCodeUnspsc190501.42202201': __('identifierSchemeCodeUnspsc190501.42202201'),
	'identifierSchemeCodeUnspsc190501.42202202': __('identifierSchemeCodeUnspsc190501.42202202'),
	'identifierSchemeCodeUnspsc190501.42202203': __('identifierSchemeCodeUnspsc190501.42202203'),
	'identifierSchemeCodeUnspsc190501.42202204': __('identifierSchemeCodeUnspsc190501.42202204'),
	'identifierSchemeCodeUnspsc190501.42202205': __('identifierSchemeCodeUnspsc190501.42202205'),
	'identifierSchemeCodeUnspsc190501.42202300': __('identifierSchemeCodeUnspsc190501.42202300'),
	'identifierSchemeCodeUnspsc190501.42202301': __('identifierSchemeCodeUnspsc190501.42202301'),
	'identifierSchemeCodeUnspsc190501.42202302': __('identifierSchemeCodeUnspsc190501.42202302'),
	'identifierSchemeCodeUnspsc190501.42202303': __('identifierSchemeCodeUnspsc190501.42202303'),
	'identifierSchemeCodeUnspsc190501.42202304': __('identifierSchemeCodeUnspsc190501.42202304'),
	'identifierSchemeCodeUnspsc190501.42202400': __('identifierSchemeCodeUnspsc190501.42202400'),
	'identifierSchemeCodeUnspsc190501.42202401': __('identifierSchemeCodeUnspsc190501.42202401'),
	'identifierSchemeCodeUnspsc190501.42202402': __('identifierSchemeCodeUnspsc190501.42202402'),
	'identifierSchemeCodeUnspsc190501.42202500': __('identifierSchemeCodeUnspsc190501.42202500'),
	'identifierSchemeCodeUnspsc190501.42202501': __('identifierSchemeCodeUnspsc190501.42202501'),
	'identifierSchemeCodeUnspsc190501.42202502': __('identifierSchemeCodeUnspsc190501.42202502'),
	'identifierSchemeCodeUnspsc190501.42202600': __('identifierSchemeCodeUnspsc190501.42202600'),
	'identifierSchemeCodeUnspsc190501.42202601': __('identifierSchemeCodeUnspsc190501.42202601'),
	'identifierSchemeCodeUnspsc190501.42202602': __('identifierSchemeCodeUnspsc190501.42202602'),
	'identifierSchemeCodeUnspsc190501.42202603': __('identifierSchemeCodeUnspsc190501.42202603'),
	'identifierSchemeCodeUnspsc190501.42202604': __('identifierSchemeCodeUnspsc190501.42202604'),
	'identifierSchemeCodeUnspsc190501.42202700': __('identifierSchemeCodeUnspsc190501.42202700'),
	'identifierSchemeCodeUnspsc190501.42202701': __('identifierSchemeCodeUnspsc190501.42202701'),
	'identifierSchemeCodeUnspsc190501.42202702': __('identifierSchemeCodeUnspsc190501.42202702'),
	'identifierSchemeCodeUnspsc190501.42202705': __('identifierSchemeCodeUnspsc190501.42202705'),
	'identifierSchemeCodeUnspsc190501.42202706': __('identifierSchemeCodeUnspsc190501.42202706'),
	'identifierSchemeCodeUnspsc190501.42202707': __('identifierSchemeCodeUnspsc190501.42202707'),
	'identifierSchemeCodeUnspsc190501.42202900': __('identifierSchemeCodeUnspsc190501.42202900'),
	'identifierSchemeCodeUnspsc190501.42202901': __('identifierSchemeCodeUnspsc190501.42202901'),
	'identifierSchemeCodeUnspsc190501.42202902': __('identifierSchemeCodeUnspsc190501.42202902'),
	'identifierSchemeCodeUnspsc190501.42203000': __('identifierSchemeCodeUnspsc190501.42203000'),
	'identifierSchemeCodeUnspsc190501.42203001': __('identifierSchemeCodeUnspsc190501.42203001'),
	'identifierSchemeCodeUnspsc190501.42203002': __('identifierSchemeCodeUnspsc190501.42203002'),
	'identifierSchemeCodeUnspsc190501.42203003': __('identifierSchemeCodeUnspsc190501.42203003'),
	'identifierSchemeCodeUnspsc190501.42203200': __('identifierSchemeCodeUnspsc190501.42203200'),
	'identifierSchemeCodeUnspsc190501.42203201': __('identifierSchemeCodeUnspsc190501.42203201'),
	'identifierSchemeCodeUnspsc190501.42203202': __('identifierSchemeCodeUnspsc190501.42203202'),
	'identifierSchemeCodeUnspsc190501.42203203': __('identifierSchemeCodeUnspsc190501.42203203'),
	'identifierSchemeCodeUnspsc190501.42203400': __('identifierSchemeCodeUnspsc190501.42203400'),
	'identifierSchemeCodeUnspsc190501.42203402': __('identifierSchemeCodeUnspsc190501.42203402'),
	'identifierSchemeCodeUnspsc190501.42203403': __('identifierSchemeCodeUnspsc190501.42203403'),
	'identifierSchemeCodeUnspsc190501.42203404': __('identifierSchemeCodeUnspsc190501.42203404'),
	'identifierSchemeCodeUnspsc190501.42203405': __('identifierSchemeCodeUnspsc190501.42203405'),
	'identifierSchemeCodeUnspsc190501.42203406': __('identifierSchemeCodeUnspsc190501.42203406'),
	'identifierSchemeCodeUnspsc190501.42203407': __('identifierSchemeCodeUnspsc190501.42203407'),
	'identifierSchemeCodeUnspsc190501.42203408': __('identifierSchemeCodeUnspsc190501.42203408'),
	'identifierSchemeCodeUnspsc190501.42203409': __('identifierSchemeCodeUnspsc190501.42203409'),
	'identifierSchemeCodeUnspsc190501.42203410': __('identifierSchemeCodeUnspsc190501.42203410'),
	'identifierSchemeCodeUnspsc190501.42203413': __('identifierSchemeCodeUnspsc190501.42203413'),
	'identifierSchemeCodeUnspsc190501.42203414': __('identifierSchemeCodeUnspsc190501.42203414'),
	'identifierSchemeCodeUnspsc190501.42203415': __('identifierSchemeCodeUnspsc190501.42203415'),
	'identifierSchemeCodeUnspsc190501.42203416': __('identifierSchemeCodeUnspsc190501.42203416'),
	'identifierSchemeCodeUnspsc190501.42203417': __('identifierSchemeCodeUnspsc190501.42203417'),
	'identifierSchemeCodeUnspsc190501.42203418': __('identifierSchemeCodeUnspsc190501.42203418'),
	'identifierSchemeCodeUnspsc190501.42203420': __('identifierSchemeCodeUnspsc190501.42203420'),
	'identifierSchemeCodeUnspsc190501.42203421': __('identifierSchemeCodeUnspsc190501.42203421'),
	'identifierSchemeCodeUnspsc190501.42203422': __('identifierSchemeCodeUnspsc190501.42203422'),
	'identifierSchemeCodeUnspsc190501.42203423': __('identifierSchemeCodeUnspsc190501.42203423'),
	'identifierSchemeCodeUnspsc190501.42203424': __('identifierSchemeCodeUnspsc190501.42203424'),
	'identifierSchemeCodeUnspsc190501.42203425': __('identifierSchemeCodeUnspsc190501.42203425'),
	'identifierSchemeCodeUnspsc190501.42203426': __('identifierSchemeCodeUnspsc190501.42203426'),
	'identifierSchemeCodeUnspsc190501.42203427': __('identifierSchemeCodeUnspsc190501.42203427'),
	'identifierSchemeCodeUnspsc190501.42203428': __('identifierSchemeCodeUnspsc190501.42203428'),
	'identifierSchemeCodeUnspsc190501.42203429': __('identifierSchemeCodeUnspsc190501.42203429'),
	'identifierSchemeCodeUnspsc190501.42203430': __('identifierSchemeCodeUnspsc190501.42203430'),
	'identifierSchemeCodeUnspsc190501.42203431': __('identifierSchemeCodeUnspsc190501.42203431'),
	'identifierSchemeCodeUnspsc190501.42203432': __('identifierSchemeCodeUnspsc190501.42203432'),
	'identifierSchemeCodeUnspsc190501.42203433': __('identifierSchemeCodeUnspsc190501.42203433'),
	'identifierSchemeCodeUnspsc190501.42203434': __('identifierSchemeCodeUnspsc190501.42203434'),
	'identifierSchemeCodeUnspsc190501.42203435': __('identifierSchemeCodeUnspsc190501.42203435'),
	'identifierSchemeCodeUnspsc190501.42203436': __('identifierSchemeCodeUnspsc190501.42203436'),
	'identifierSchemeCodeUnspsc190501.42203437': __('identifierSchemeCodeUnspsc190501.42203437'),
	'identifierSchemeCodeUnspsc190501.42203438': __('identifierSchemeCodeUnspsc190501.42203438'),
	'identifierSchemeCodeUnspsc190501.42203439': __('identifierSchemeCodeUnspsc190501.42203439'),
	'identifierSchemeCodeUnspsc190501.42203440': __('identifierSchemeCodeUnspsc190501.42203440'),
	'identifierSchemeCodeUnspsc190501.42203441': __('identifierSchemeCodeUnspsc190501.42203441'),
	'identifierSchemeCodeUnspsc190501.42203442': __('identifierSchemeCodeUnspsc190501.42203442'),
	'identifierSchemeCodeUnspsc190501.42203443': __('identifierSchemeCodeUnspsc190501.42203443'),
	'identifierSchemeCodeUnspsc190501.42203444': __('identifierSchemeCodeUnspsc190501.42203444'),
	'identifierSchemeCodeUnspsc190501.42203445': __('identifierSchemeCodeUnspsc190501.42203445'),
	'identifierSchemeCodeUnspsc190501.42203446': __('identifierSchemeCodeUnspsc190501.42203446'),
	'identifierSchemeCodeUnspsc190501.42203447': __('identifierSchemeCodeUnspsc190501.42203447'),
	'identifierSchemeCodeUnspsc190501.42203500': __('identifierSchemeCodeUnspsc190501.42203500'),
	'identifierSchemeCodeUnspsc190501.42203501': __('identifierSchemeCodeUnspsc190501.42203501'),
	'identifierSchemeCodeUnspsc190501.42203502': __('identifierSchemeCodeUnspsc190501.42203502'),
	'identifierSchemeCodeUnspsc190501.42203503': __('identifierSchemeCodeUnspsc190501.42203503'),
	'identifierSchemeCodeUnspsc190501.42203504': __('identifierSchemeCodeUnspsc190501.42203504'),
	'identifierSchemeCodeUnspsc190501.42203505': __('identifierSchemeCodeUnspsc190501.42203505'),
	'identifierSchemeCodeUnspsc190501.42203506': __('identifierSchemeCodeUnspsc190501.42203506'),
	'identifierSchemeCodeUnspsc190501.42203507': __('identifierSchemeCodeUnspsc190501.42203507'),
	'identifierSchemeCodeUnspsc190501.42203508': __('identifierSchemeCodeUnspsc190501.42203508'),
	'identifierSchemeCodeUnspsc190501.42203509': __('identifierSchemeCodeUnspsc190501.42203509'),
	'identifierSchemeCodeUnspsc190501.42203510': __('identifierSchemeCodeUnspsc190501.42203510'),
	'identifierSchemeCodeUnspsc190501.42203600': __('identifierSchemeCodeUnspsc190501.42203600'),
	'identifierSchemeCodeUnspsc190501.42203601': __('identifierSchemeCodeUnspsc190501.42203601'),
	'identifierSchemeCodeUnspsc190501.42203602': __('identifierSchemeCodeUnspsc190501.42203602'),
	'identifierSchemeCodeUnspsc190501.42203603': __('identifierSchemeCodeUnspsc190501.42203603'),
	'identifierSchemeCodeUnspsc190501.42203605': __('identifierSchemeCodeUnspsc190501.42203605'),
	'identifierSchemeCodeUnspsc190501.42203606': __('identifierSchemeCodeUnspsc190501.42203606'),
	'identifierSchemeCodeUnspsc190501.42203607': __('identifierSchemeCodeUnspsc190501.42203607'),
	'identifierSchemeCodeUnspsc190501.42203608': __('identifierSchemeCodeUnspsc190501.42203608'),
	'identifierSchemeCodeUnspsc190501.42203609': __('identifierSchemeCodeUnspsc190501.42203609'),
	'identifierSchemeCodeUnspsc190501.42203610': __('identifierSchemeCodeUnspsc190501.42203610'),
	'identifierSchemeCodeUnspsc190501.42203611': __('identifierSchemeCodeUnspsc190501.42203611'),
	'identifierSchemeCodeUnspsc190501.42203612': __('identifierSchemeCodeUnspsc190501.42203612'),
	'identifierSchemeCodeUnspsc190501.42203613': __('identifierSchemeCodeUnspsc190501.42203613'),
	'identifierSchemeCodeUnspsc190501.42203700': __('identifierSchemeCodeUnspsc190501.42203700'),
	'identifierSchemeCodeUnspsc190501.42203701': __('identifierSchemeCodeUnspsc190501.42203701'),
	'identifierSchemeCodeUnspsc190501.42203702': __('identifierSchemeCodeUnspsc190501.42203702'),
	'identifierSchemeCodeUnspsc190501.42203703': __('identifierSchemeCodeUnspsc190501.42203703'),
	'identifierSchemeCodeUnspsc190501.42203704': __('identifierSchemeCodeUnspsc190501.42203704'),
	'identifierSchemeCodeUnspsc190501.42203705': __('identifierSchemeCodeUnspsc190501.42203705'),
	'identifierSchemeCodeUnspsc190501.42203706': __('identifierSchemeCodeUnspsc190501.42203706'),
	'identifierSchemeCodeUnspsc190501.42203707': __('identifierSchemeCodeUnspsc190501.42203707'),
	'identifierSchemeCodeUnspsc190501.42203708': __('identifierSchemeCodeUnspsc190501.42203708'),
	'identifierSchemeCodeUnspsc190501.42203709': __('identifierSchemeCodeUnspsc190501.42203709'),
	'identifierSchemeCodeUnspsc190501.42203710': __('identifierSchemeCodeUnspsc190501.42203710'),
	'identifierSchemeCodeUnspsc190501.42203711': __('identifierSchemeCodeUnspsc190501.42203711'),
	'identifierSchemeCodeUnspsc190501.42203712': __('identifierSchemeCodeUnspsc190501.42203712'),
	'identifierSchemeCodeUnspsc190501.42203800': __('identifierSchemeCodeUnspsc190501.42203800'),
	'identifierSchemeCodeUnspsc190501.42203801': __('identifierSchemeCodeUnspsc190501.42203801'),
	'identifierSchemeCodeUnspsc190501.42203802': __('identifierSchemeCodeUnspsc190501.42203802'),
	'identifierSchemeCodeUnspsc190501.42203803': __('identifierSchemeCodeUnspsc190501.42203803'),
	'identifierSchemeCodeUnspsc190501.42203804': __('identifierSchemeCodeUnspsc190501.42203804'),
	'identifierSchemeCodeUnspsc190501.42203805': __('identifierSchemeCodeUnspsc190501.42203805'),
	'identifierSchemeCodeUnspsc190501.42203806': __('identifierSchemeCodeUnspsc190501.42203806'),
	'identifierSchemeCodeUnspsc190501.42203900': __('identifierSchemeCodeUnspsc190501.42203900'),
	'identifierSchemeCodeUnspsc190501.42203901': __('identifierSchemeCodeUnspsc190501.42203901'),
	'identifierSchemeCodeUnspsc190501.42203902': __('identifierSchemeCodeUnspsc190501.42203902'),
	'identifierSchemeCodeUnspsc190501.42203903': __('identifierSchemeCodeUnspsc190501.42203903'),
	'identifierSchemeCodeUnspsc190501.42203904': __('identifierSchemeCodeUnspsc190501.42203904'),
	'identifierSchemeCodeUnspsc190501.42203905': __('identifierSchemeCodeUnspsc190501.42203905'),
	'identifierSchemeCodeUnspsc190501.42203906': __('identifierSchemeCodeUnspsc190501.42203906'),
	'identifierSchemeCodeUnspsc190501.42203907': __('identifierSchemeCodeUnspsc190501.42203907'),
	'identifierSchemeCodeUnspsc190501.42204000': __('identifierSchemeCodeUnspsc190501.42204000'),
	'identifierSchemeCodeUnspsc190501.42204001': __('identifierSchemeCodeUnspsc190501.42204001'),
	'identifierSchemeCodeUnspsc190501.42204002': __('identifierSchemeCodeUnspsc190501.42204002'),
	'identifierSchemeCodeUnspsc190501.42204003': __('identifierSchemeCodeUnspsc190501.42204003'),
	'identifierSchemeCodeUnspsc190501.42204004': __('identifierSchemeCodeUnspsc190501.42204004'),
	'identifierSchemeCodeUnspsc190501.42204005': __('identifierSchemeCodeUnspsc190501.42204005'),
	'identifierSchemeCodeUnspsc190501.42204006': __('identifierSchemeCodeUnspsc190501.42204006'),
	'identifierSchemeCodeUnspsc190501.42204007': __('identifierSchemeCodeUnspsc190501.42204007'),
	'identifierSchemeCodeUnspsc190501.42204008': __('identifierSchemeCodeUnspsc190501.42204008'),
	'identifierSchemeCodeUnspsc190501.42204009': __('identifierSchemeCodeUnspsc190501.42204009'),
	'identifierSchemeCodeUnspsc190501.42204010': __('identifierSchemeCodeUnspsc190501.42204010'),
	'identifierSchemeCodeUnspsc190501.42204011': __('identifierSchemeCodeUnspsc190501.42204011'),
	'identifierSchemeCodeUnspsc190501.42204012': __('identifierSchemeCodeUnspsc190501.42204012'),
	'identifierSchemeCodeUnspsc190501.42204013': __('identifierSchemeCodeUnspsc190501.42204013'),
	'identifierSchemeCodeUnspsc190501.42204014': __('identifierSchemeCodeUnspsc190501.42204014'),
	'identifierSchemeCodeUnspsc190501.42204015': __('identifierSchemeCodeUnspsc190501.42204015'),
	'identifierSchemeCodeUnspsc190501.42204016': __('identifierSchemeCodeUnspsc190501.42204016'),
	'identifierSchemeCodeUnspsc190501.42204017': __('identifierSchemeCodeUnspsc190501.42204017'),
	'identifierSchemeCodeUnspsc190501.42204018': __('identifierSchemeCodeUnspsc190501.42204018'),
	'identifierSchemeCodeUnspsc190501.42204019': __('identifierSchemeCodeUnspsc190501.42204019'),
	'identifierSchemeCodeUnspsc190501.42204100': __('identifierSchemeCodeUnspsc190501.42204100'),
	'identifierSchemeCodeUnspsc190501.42204101': __('identifierSchemeCodeUnspsc190501.42204101'),
	'identifierSchemeCodeUnspsc190501.42204200': __('identifierSchemeCodeUnspsc190501.42204200'),
	'identifierSchemeCodeUnspsc190501.42204201': __('identifierSchemeCodeUnspsc190501.42204201'),
	'identifierSchemeCodeUnspsc190501.42204202': __('identifierSchemeCodeUnspsc190501.42204202'),
	'identifierSchemeCodeUnspsc190501.42204203': __('identifierSchemeCodeUnspsc190501.42204203'),
	'identifierSchemeCodeUnspsc190501.42204300': __('identifierSchemeCodeUnspsc190501.42204300'),
	'identifierSchemeCodeUnspsc190501.42204301': __('identifierSchemeCodeUnspsc190501.42204301'),
	'identifierSchemeCodeUnspsc190501.42204302': __('identifierSchemeCodeUnspsc190501.42204302'),
	'identifierSchemeCodeUnspsc190501.42204303': __('identifierSchemeCodeUnspsc190501.42204303'),
	'identifierSchemeCodeUnspsc190501.42204304': __('identifierSchemeCodeUnspsc190501.42204304'),
	'identifierSchemeCodeUnspsc190501.42204305': __('identifierSchemeCodeUnspsc190501.42204305'),
	'identifierSchemeCodeUnspsc190501.42210000': __('identifierSchemeCodeUnspsc190501.42210000'),
	'identifierSchemeCodeUnspsc190501.42211500': __('identifierSchemeCodeUnspsc190501.42211500'),
	'identifierSchemeCodeUnspsc190501.42211501': __('identifierSchemeCodeUnspsc190501.42211501'),
	'identifierSchemeCodeUnspsc190501.42211502': __('identifierSchemeCodeUnspsc190501.42211502'),
	'identifierSchemeCodeUnspsc190501.42211503': __('identifierSchemeCodeUnspsc190501.42211503'),
	'identifierSchemeCodeUnspsc190501.42211504': __('identifierSchemeCodeUnspsc190501.42211504'),
	'identifierSchemeCodeUnspsc190501.42211505': __('identifierSchemeCodeUnspsc190501.42211505'),
	'identifierSchemeCodeUnspsc190501.42211506': __('identifierSchemeCodeUnspsc190501.42211506'),
	'identifierSchemeCodeUnspsc190501.42211507': __('identifierSchemeCodeUnspsc190501.42211507'),
	'identifierSchemeCodeUnspsc190501.42211508': __('identifierSchemeCodeUnspsc190501.42211508'),
	'identifierSchemeCodeUnspsc190501.42211509': __('identifierSchemeCodeUnspsc190501.42211509'),
	'identifierSchemeCodeUnspsc190501.42211510': __('identifierSchemeCodeUnspsc190501.42211510'),
	'identifierSchemeCodeUnspsc190501.42211511': __('identifierSchemeCodeUnspsc190501.42211511'),
	'identifierSchemeCodeUnspsc190501.42211512': __('identifierSchemeCodeUnspsc190501.42211512'),
	'identifierSchemeCodeUnspsc190501.42211513': __('identifierSchemeCodeUnspsc190501.42211513'),
	'identifierSchemeCodeUnspsc190501.42211514': __('identifierSchemeCodeUnspsc190501.42211514'),
	'identifierSchemeCodeUnspsc190501.42211515': __('identifierSchemeCodeUnspsc190501.42211515'),
	'identifierSchemeCodeUnspsc190501.42211600': __('identifierSchemeCodeUnspsc190501.42211600'),
	'identifierSchemeCodeUnspsc190501.42211601': __('identifierSchemeCodeUnspsc190501.42211601'),
	'identifierSchemeCodeUnspsc190501.42211602': __('identifierSchemeCodeUnspsc190501.42211602'),
	'identifierSchemeCodeUnspsc190501.42211603': __('identifierSchemeCodeUnspsc190501.42211603'),
	'identifierSchemeCodeUnspsc190501.42211604': __('identifierSchemeCodeUnspsc190501.42211604'),
	'identifierSchemeCodeUnspsc190501.42211605': __('identifierSchemeCodeUnspsc190501.42211605'),
	'identifierSchemeCodeUnspsc190501.42211606': __('identifierSchemeCodeUnspsc190501.42211606'),
	'identifierSchemeCodeUnspsc190501.42211607': __('identifierSchemeCodeUnspsc190501.42211607'),
	'identifierSchemeCodeUnspsc190501.42211608': __('identifierSchemeCodeUnspsc190501.42211608'),
	'identifierSchemeCodeUnspsc190501.42211610': __('identifierSchemeCodeUnspsc190501.42211610'),
	'identifierSchemeCodeUnspsc190501.42211611': __('identifierSchemeCodeUnspsc190501.42211611'),
	'identifierSchemeCodeUnspsc190501.42211612': __('identifierSchemeCodeUnspsc190501.42211612'),
	'identifierSchemeCodeUnspsc190501.42211613': __('identifierSchemeCodeUnspsc190501.42211613'),
	'identifierSchemeCodeUnspsc190501.42211614': __('identifierSchemeCodeUnspsc190501.42211614'),
	'identifierSchemeCodeUnspsc190501.42211615': __('identifierSchemeCodeUnspsc190501.42211615'),
	'identifierSchemeCodeUnspsc190501.42211616': __('identifierSchemeCodeUnspsc190501.42211616'),
	'identifierSchemeCodeUnspsc190501.42211617': __('identifierSchemeCodeUnspsc190501.42211617'),
	'identifierSchemeCodeUnspsc190501.42211618': __('identifierSchemeCodeUnspsc190501.42211618'),
	'identifierSchemeCodeUnspsc190501.42211619': __('identifierSchemeCodeUnspsc190501.42211619'),
	'identifierSchemeCodeUnspsc190501.42211620': __('identifierSchemeCodeUnspsc190501.42211620'),
	'identifierSchemeCodeUnspsc190501.42211621': __('identifierSchemeCodeUnspsc190501.42211621'),
	'identifierSchemeCodeUnspsc190501.42211622': __('identifierSchemeCodeUnspsc190501.42211622'),
	'identifierSchemeCodeUnspsc190501.42211700': __('identifierSchemeCodeUnspsc190501.42211700'),
	'identifierSchemeCodeUnspsc190501.42211701': __('identifierSchemeCodeUnspsc190501.42211701'),
	'identifierSchemeCodeUnspsc190501.42211702': __('identifierSchemeCodeUnspsc190501.42211702'),
	'identifierSchemeCodeUnspsc190501.42211703': __('identifierSchemeCodeUnspsc190501.42211703'),
	'identifierSchemeCodeUnspsc190501.42211704': __('identifierSchemeCodeUnspsc190501.42211704'),
	'identifierSchemeCodeUnspsc190501.42211705': __('identifierSchemeCodeUnspsc190501.42211705'),
	'identifierSchemeCodeUnspsc190501.42211706': __('identifierSchemeCodeUnspsc190501.42211706'),
	'identifierSchemeCodeUnspsc190501.42211707': __('identifierSchemeCodeUnspsc190501.42211707'),
	'identifierSchemeCodeUnspsc190501.42211708': __('identifierSchemeCodeUnspsc190501.42211708'),
	'identifierSchemeCodeUnspsc190501.42211709': __('identifierSchemeCodeUnspsc190501.42211709'),
	'identifierSchemeCodeUnspsc190501.42211710': __('identifierSchemeCodeUnspsc190501.42211710'),
	'identifierSchemeCodeUnspsc190501.42211711': __('identifierSchemeCodeUnspsc190501.42211711'),
	'identifierSchemeCodeUnspsc190501.42211712': __('identifierSchemeCodeUnspsc190501.42211712'),
	'identifierSchemeCodeUnspsc190501.42211713': __('identifierSchemeCodeUnspsc190501.42211713'),
	'identifierSchemeCodeUnspsc190501.42211714': __('identifierSchemeCodeUnspsc190501.42211714'),
	'identifierSchemeCodeUnspsc190501.42211715': __('identifierSchemeCodeUnspsc190501.42211715'),
	'identifierSchemeCodeUnspsc190501.42211800': __('identifierSchemeCodeUnspsc190501.42211800'),
	'identifierSchemeCodeUnspsc190501.42211801': __('identifierSchemeCodeUnspsc190501.42211801'),
	'identifierSchemeCodeUnspsc190501.42211802': __('identifierSchemeCodeUnspsc190501.42211802'),
	'identifierSchemeCodeUnspsc190501.42211803': __('identifierSchemeCodeUnspsc190501.42211803'),
	'identifierSchemeCodeUnspsc190501.42211804': __('identifierSchemeCodeUnspsc190501.42211804'),
	'identifierSchemeCodeUnspsc190501.42211805': __('identifierSchemeCodeUnspsc190501.42211805'),
	'identifierSchemeCodeUnspsc190501.42211806': __('identifierSchemeCodeUnspsc190501.42211806'),
	'identifierSchemeCodeUnspsc190501.42211807': __('identifierSchemeCodeUnspsc190501.42211807'),
	'identifierSchemeCodeUnspsc190501.42211808': __('identifierSchemeCodeUnspsc190501.42211808'),
	'identifierSchemeCodeUnspsc190501.42211809': __('identifierSchemeCodeUnspsc190501.42211809'),
	'identifierSchemeCodeUnspsc190501.42211810': __('identifierSchemeCodeUnspsc190501.42211810'),
	'identifierSchemeCodeUnspsc190501.42211811': __('identifierSchemeCodeUnspsc190501.42211811'),
	'identifierSchemeCodeUnspsc190501.42211812': __('identifierSchemeCodeUnspsc190501.42211812'),
	'identifierSchemeCodeUnspsc190501.42211813': __('identifierSchemeCodeUnspsc190501.42211813'),
	'identifierSchemeCodeUnspsc190501.42211900': __('identifierSchemeCodeUnspsc190501.42211900'),
	'identifierSchemeCodeUnspsc190501.42211901': __('identifierSchemeCodeUnspsc190501.42211901'),
	'identifierSchemeCodeUnspsc190501.42211902': __('identifierSchemeCodeUnspsc190501.42211902'),
	'identifierSchemeCodeUnspsc190501.42211903': __('identifierSchemeCodeUnspsc190501.42211903'),
	'identifierSchemeCodeUnspsc190501.42211904': __('identifierSchemeCodeUnspsc190501.42211904'),
	'identifierSchemeCodeUnspsc190501.42211905': __('identifierSchemeCodeUnspsc190501.42211905'),
	'identifierSchemeCodeUnspsc190501.42211906': __('identifierSchemeCodeUnspsc190501.42211906'),
	'identifierSchemeCodeUnspsc190501.42211907': __('identifierSchemeCodeUnspsc190501.42211907'),
	'identifierSchemeCodeUnspsc190501.42211908': __('identifierSchemeCodeUnspsc190501.42211908'),
	'identifierSchemeCodeUnspsc190501.42211909': __('identifierSchemeCodeUnspsc190501.42211909'),
	'identifierSchemeCodeUnspsc190501.42211910': __('identifierSchemeCodeUnspsc190501.42211910'),
	'identifierSchemeCodeUnspsc190501.42211911': __('identifierSchemeCodeUnspsc190501.42211911'),
	'identifierSchemeCodeUnspsc190501.42211912': __('identifierSchemeCodeUnspsc190501.42211912'),
	'identifierSchemeCodeUnspsc190501.42211913': __('identifierSchemeCodeUnspsc190501.42211913'),
	'identifierSchemeCodeUnspsc190501.42211914': __('identifierSchemeCodeUnspsc190501.42211914'),
	'identifierSchemeCodeUnspsc190501.42211915': __('identifierSchemeCodeUnspsc190501.42211915'),
	'identifierSchemeCodeUnspsc190501.42211916': __('identifierSchemeCodeUnspsc190501.42211916'),
	'identifierSchemeCodeUnspsc190501.42211917': __('identifierSchemeCodeUnspsc190501.42211917'),
	'identifierSchemeCodeUnspsc190501.42211918': __('identifierSchemeCodeUnspsc190501.42211918'),
	'identifierSchemeCodeUnspsc190501.42211919': __('identifierSchemeCodeUnspsc190501.42211919'),
	'identifierSchemeCodeUnspsc190501.42212000': __('identifierSchemeCodeUnspsc190501.42212000'),
	'identifierSchemeCodeUnspsc190501.42212001': __('identifierSchemeCodeUnspsc190501.42212001'),
	'identifierSchemeCodeUnspsc190501.42212002': __('identifierSchemeCodeUnspsc190501.42212002'),
	'identifierSchemeCodeUnspsc190501.42212003': __('identifierSchemeCodeUnspsc190501.42212003'),
	'identifierSchemeCodeUnspsc190501.42212004': __('identifierSchemeCodeUnspsc190501.42212004'),
	'identifierSchemeCodeUnspsc190501.42212005': __('identifierSchemeCodeUnspsc190501.42212005'),
	'identifierSchemeCodeUnspsc190501.42212006': __('identifierSchemeCodeUnspsc190501.42212006'),
	'identifierSchemeCodeUnspsc190501.42212007': __('identifierSchemeCodeUnspsc190501.42212007'),
	'identifierSchemeCodeUnspsc190501.42212100': __('identifierSchemeCodeUnspsc190501.42212100'),
	'identifierSchemeCodeUnspsc190501.42212101': __('identifierSchemeCodeUnspsc190501.42212101'),
	'identifierSchemeCodeUnspsc190501.42212102': __('identifierSchemeCodeUnspsc190501.42212102'),
	'identifierSchemeCodeUnspsc190501.42212103': __('identifierSchemeCodeUnspsc190501.42212103'),
	'identifierSchemeCodeUnspsc190501.42212104': __('identifierSchemeCodeUnspsc190501.42212104'),
	'identifierSchemeCodeUnspsc190501.42212105': __('identifierSchemeCodeUnspsc190501.42212105'),
	'identifierSchemeCodeUnspsc190501.42212106': __('identifierSchemeCodeUnspsc190501.42212106'),
	'identifierSchemeCodeUnspsc190501.42212107': __('identifierSchemeCodeUnspsc190501.42212107'),
	'identifierSchemeCodeUnspsc190501.42212108': __('identifierSchemeCodeUnspsc190501.42212108'),
	'identifierSchemeCodeUnspsc190501.42212109': __('identifierSchemeCodeUnspsc190501.42212109'),
	'identifierSchemeCodeUnspsc190501.42212110': __('identifierSchemeCodeUnspsc190501.42212110'),
	'identifierSchemeCodeUnspsc190501.42212111': __('identifierSchemeCodeUnspsc190501.42212111'),
	'identifierSchemeCodeUnspsc190501.42212112': __('identifierSchemeCodeUnspsc190501.42212112'),
	'identifierSchemeCodeUnspsc190501.42212113': __('identifierSchemeCodeUnspsc190501.42212113'),
	'identifierSchemeCodeUnspsc190501.42212200': __('identifierSchemeCodeUnspsc190501.42212200'),
	'identifierSchemeCodeUnspsc190501.42212201': __('identifierSchemeCodeUnspsc190501.42212201'),
	'identifierSchemeCodeUnspsc190501.42212202': __('identifierSchemeCodeUnspsc190501.42212202'),
	'identifierSchemeCodeUnspsc190501.42212203': __('identifierSchemeCodeUnspsc190501.42212203'),
	'identifierSchemeCodeUnspsc190501.42212204': __('identifierSchemeCodeUnspsc190501.42212204'),
	'identifierSchemeCodeUnspsc190501.42212300': __('identifierSchemeCodeUnspsc190501.42212300'),
	'identifierSchemeCodeUnspsc190501.42212301': __('identifierSchemeCodeUnspsc190501.42212301'),
	'identifierSchemeCodeUnspsc190501.42212302': __('identifierSchemeCodeUnspsc190501.42212302'),
	'identifierSchemeCodeUnspsc190501.42212303': __('identifierSchemeCodeUnspsc190501.42212303'),
	'identifierSchemeCodeUnspsc190501.42212304': __('identifierSchemeCodeUnspsc190501.42212304'),
	'identifierSchemeCodeUnspsc190501.42212305': __('identifierSchemeCodeUnspsc190501.42212305'),
	'identifierSchemeCodeUnspsc190501.42212306': __('identifierSchemeCodeUnspsc190501.42212306'),
	'identifierSchemeCodeUnspsc190501.42220000': __('identifierSchemeCodeUnspsc190501.42220000'),
	'identifierSchemeCodeUnspsc190501.42221500': __('identifierSchemeCodeUnspsc190501.42221500'),
	'identifierSchemeCodeUnspsc190501.42221501': __('identifierSchemeCodeUnspsc190501.42221501'),
	'identifierSchemeCodeUnspsc190501.42221502': __('identifierSchemeCodeUnspsc190501.42221502'),
	'identifierSchemeCodeUnspsc190501.42221503': __('identifierSchemeCodeUnspsc190501.42221503'),
	'identifierSchemeCodeUnspsc190501.42221504': __('identifierSchemeCodeUnspsc190501.42221504'),
	'identifierSchemeCodeUnspsc190501.42221506': __('identifierSchemeCodeUnspsc190501.42221506'),
	'identifierSchemeCodeUnspsc190501.42221507': __('identifierSchemeCodeUnspsc190501.42221507'),
	'identifierSchemeCodeUnspsc190501.42221508': __('identifierSchemeCodeUnspsc190501.42221508'),
	'identifierSchemeCodeUnspsc190501.42221509': __('identifierSchemeCodeUnspsc190501.42221509'),
	'identifierSchemeCodeUnspsc190501.42221512': __('identifierSchemeCodeUnspsc190501.42221512'),
	'identifierSchemeCodeUnspsc190501.42221514': __('identifierSchemeCodeUnspsc190501.42221514'),
	'identifierSchemeCodeUnspsc190501.42221515': __('identifierSchemeCodeUnspsc190501.42221515'),
	'identifierSchemeCodeUnspsc190501.42221516': __('identifierSchemeCodeUnspsc190501.42221516'),
	'identifierSchemeCodeUnspsc190501.42221519': __('identifierSchemeCodeUnspsc190501.42221519'),
	'identifierSchemeCodeUnspsc190501.42221520': __('identifierSchemeCodeUnspsc190501.42221520'),
	'identifierSchemeCodeUnspsc190501.42221521': __('identifierSchemeCodeUnspsc190501.42221521'),
	'identifierSchemeCodeUnspsc190501.42221522': __('identifierSchemeCodeUnspsc190501.42221522'),
	'identifierSchemeCodeUnspsc190501.42221523': __('identifierSchemeCodeUnspsc190501.42221523'),
	'identifierSchemeCodeUnspsc190501.42221524': __('identifierSchemeCodeUnspsc190501.42221524'),
	'identifierSchemeCodeUnspsc190501.42221525': __('identifierSchemeCodeUnspsc190501.42221525'),
	'identifierSchemeCodeUnspsc190501.42221600': __('identifierSchemeCodeUnspsc190501.42221600'),
	'identifierSchemeCodeUnspsc190501.42221601': __('identifierSchemeCodeUnspsc190501.42221601'),
	'identifierSchemeCodeUnspsc190501.42221602': __('identifierSchemeCodeUnspsc190501.42221602'),
	'identifierSchemeCodeUnspsc190501.42221603': __('identifierSchemeCodeUnspsc190501.42221603'),
	'identifierSchemeCodeUnspsc190501.42221604': __('identifierSchemeCodeUnspsc190501.42221604'),
	'identifierSchemeCodeUnspsc190501.42221605': __('identifierSchemeCodeUnspsc190501.42221605'),
	'identifierSchemeCodeUnspsc190501.42221606': __('identifierSchemeCodeUnspsc190501.42221606'),
	'identifierSchemeCodeUnspsc190501.42221607': __('identifierSchemeCodeUnspsc190501.42221607'),
	'identifierSchemeCodeUnspsc190501.42221608': __('identifierSchemeCodeUnspsc190501.42221608'),
	'identifierSchemeCodeUnspsc190501.42221609': __('identifierSchemeCodeUnspsc190501.42221609'),
	'identifierSchemeCodeUnspsc190501.42221610': __('identifierSchemeCodeUnspsc190501.42221610'),
	'identifierSchemeCodeUnspsc190501.42221611': __('identifierSchemeCodeUnspsc190501.42221611'),
	'identifierSchemeCodeUnspsc190501.42221612': __('identifierSchemeCodeUnspsc190501.42221612'),
	'identifierSchemeCodeUnspsc190501.42221613': __('identifierSchemeCodeUnspsc190501.42221613'),
	'identifierSchemeCodeUnspsc190501.42221614': __('identifierSchemeCodeUnspsc190501.42221614'),
	'identifierSchemeCodeUnspsc190501.42221615': __('identifierSchemeCodeUnspsc190501.42221615'),
	'identifierSchemeCodeUnspsc190501.42221616': __('identifierSchemeCodeUnspsc190501.42221616'),
	'identifierSchemeCodeUnspsc190501.42221617': __('identifierSchemeCodeUnspsc190501.42221617'),
	'identifierSchemeCodeUnspsc190501.42221618': __('identifierSchemeCodeUnspsc190501.42221618'),
	'identifierSchemeCodeUnspsc190501.42221619': __('identifierSchemeCodeUnspsc190501.42221619'),
	'identifierSchemeCodeUnspsc190501.42221700': __('identifierSchemeCodeUnspsc190501.42221700'),
	'identifierSchemeCodeUnspsc190501.42221701': __('identifierSchemeCodeUnspsc190501.42221701'),
	'identifierSchemeCodeUnspsc190501.42221703': __('identifierSchemeCodeUnspsc190501.42221703'),
	'identifierSchemeCodeUnspsc190501.42221704': __('identifierSchemeCodeUnspsc190501.42221704'),
	'identifierSchemeCodeUnspsc190501.42221705': __('identifierSchemeCodeUnspsc190501.42221705'),
	'identifierSchemeCodeUnspsc190501.42221706': __('identifierSchemeCodeUnspsc190501.42221706'),
	'identifierSchemeCodeUnspsc190501.42221707': __('identifierSchemeCodeUnspsc190501.42221707'),
	'identifierSchemeCodeUnspsc190501.42221708': __('identifierSchemeCodeUnspsc190501.42221708'),
	'identifierSchemeCodeUnspsc190501.42221800': __('identifierSchemeCodeUnspsc190501.42221800'),
	'identifierSchemeCodeUnspsc190501.42221801': __('identifierSchemeCodeUnspsc190501.42221801'),
	'identifierSchemeCodeUnspsc190501.42221802': __('identifierSchemeCodeUnspsc190501.42221802'),
	'identifierSchemeCodeUnspsc190501.42221803': __('identifierSchemeCodeUnspsc190501.42221803'),
	'identifierSchemeCodeUnspsc190501.42221900': __('identifierSchemeCodeUnspsc190501.42221900'),
	'identifierSchemeCodeUnspsc190501.42221901': __('identifierSchemeCodeUnspsc190501.42221901'),
	'identifierSchemeCodeUnspsc190501.42221902': __('identifierSchemeCodeUnspsc190501.42221902'),
	'identifierSchemeCodeUnspsc190501.42221903': __('identifierSchemeCodeUnspsc190501.42221903'),
	'identifierSchemeCodeUnspsc190501.42221904': __('identifierSchemeCodeUnspsc190501.42221904'),
	'identifierSchemeCodeUnspsc190501.42221905': __('identifierSchemeCodeUnspsc190501.42221905'),
	'identifierSchemeCodeUnspsc190501.42221906': __('identifierSchemeCodeUnspsc190501.42221906'),
	'identifierSchemeCodeUnspsc190501.42221907': __('identifierSchemeCodeUnspsc190501.42221907'),
	'identifierSchemeCodeUnspsc190501.42221908': __('identifierSchemeCodeUnspsc190501.42221908'),
	'identifierSchemeCodeUnspsc190501.42221909': __('identifierSchemeCodeUnspsc190501.42221909'),
	'identifierSchemeCodeUnspsc190501.42221910': __('identifierSchemeCodeUnspsc190501.42221910'),
	'identifierSchemeCodeUnspsc190501.42221911': __('identifierSchemeCodeUnspsc190501.42221911'),
	'identifierSchemeCodeUnspsc190501.42222000': __('identifierSchemeCodeUnspsc190501.42222000'),
	'identifierSchemeCodeUnspsc190501.42222001': __('identifierSchemeCodeUnspsc190501.42222001'),
	'identifierSchemeCodeUnspsc190501.42222002': __('identifierSchemeCodeUnspsc190501.42222002'),
	'identifierSchemeCodeUnspsc190501.42222003': __('identifierSchemeCodeUnspsc190501.42222003'),
	'identifierSchemeCodeUnspsc190501.42222004': __('identifierSchemeCodeUnspsc190501.42222004'),
	'identifierSchemeCodeUnspsc190501.42222005': __('identifierSchemeCodeUnspsc190501.42222005'),
	'identifierSchemeCodeUnspsc190501.42222006': __('identifierSchemeCodeUnspsc190501.42222006'),
	'identifierSchemeCodeUnspsc190501.42222007': __('identifierSchemeCodeUnspsc190501.42222007'),
	'identifierSchemeCodeUnspsc190501.42222008': __('identifierSchemeCodeUnspsc190501.42222008'),
	'identifierSchemeCodeUnspsc190501.42222009': __('identifierSchemeCodeUnspsc190501.42222009'),
	'identifierSchemeCodeUnspsc190501.42222010': __('identifierSchemeCodeUnspsc190501.42222010'),
	'identifierSchemeCodeUnspsc190501.42222011': __('identifierSchemeCodeUnspsc190501.42222011'),
	'identifierSchemeCodeUnspsc190501.42222012': __('identifierSchemeCodeUnspsc190501.42222012'),
	'identifierSchemeCodeUnspsc190501.42222100': __('identifierSchemeCodeUnspsc190501.42222100'),
	'identifierSchemeCodeUnspsc190501.42222101': __('identifierSchemeCodeUnspsc190501.42222101'),
	'identifierSchemeCodeUnspsc190501.42222102': __('identifierSchemeCodeUnspsc190501.42222102'),
	'identifierSchemeCodeUnspsc190501.42222103': __('identifierSchemeCodeUnspsc190501.42222103'),
	'identifierSchemeCodeUnspsc190501.42222104': __('identifierSchemeCodeUnspsc190501.42222104'),
	'identifierSchemeCodeUnspsc190501.42222105': __('identifierSchemeCodeUnspsc190501.42222105'),
	'identifierSchemeCodeUnspsc190501.42222200': __('identifierSchemeCodeUnspsc190501.42222200'),
	'identifierSchemeCodeUnspsc190501.42222201': __('identifierSchemeCodeUnspsc190501.42222201'),
	'identifierSchemeCodeUnspsc190501.42222202': __('identifierSchemeCodeUnspsc190501.42222202'),
	'identifierSchemeCodeUnspsc190501.42222300': __('identifierSchemeCodeUnspsc190501.42222300'),
	'identifierSchemeCodeUnspsc190501.42222301': __('identifierSchemeCodeUnspsc190501.42222301'),
	'identifierSchemeCodeUnspsc190501.42222302': __('identifierSchemeCodeUnspsc190501.42222302'),
	'identifierSchemeCodeUnspsc190501.42222303': __('identifierSchemeCodeUnspsc190501.42222303'),
	'identifierSchemeCodeUnspsc190501.42222304': __('identifierSchemeCodeUnspsc190501.42222304'),
	'identifierSchemeCodeUnspsc190501.42222305': __('identifierSchemeCodeUnspsc190501.42222305'),
	'identifierSchemeCodeUnspsc190501.42222307': __('identifierSchemeCodeUnspsc190501.42222307'),
	'identifierSchemeCodeUnspsc190501.42222310': __('identifierSchemeCodeUnspsc190501.42222310'),
	'identifierSchemeCodeUnspsc190501.42222311': __('identifierSchemeCodeUnspsc190501.42222311'),
	'identifierSchemeCodeUnspsc190501.42222312': __('identifierSchemeCodeUnspsc190501.42222312'),
	'identifierSchemeCodeUnspsc190501.42222313': __('identifierSchemeCodeUnspsc190501.42222313'),
	'identifierSchemeCodeUnspsc190501.42230000': __('identifierSchemeCodeUnspsc190501.42230000'),
	'identifierSchemeCodeUnspsc190501.42231500': __('identifierSchemeCodeUnspsc190501.42231500'),
	'identifierSchemeCodeUnspsc190501.42231501': __('identifierSchemeCodeUnspsc190501.42231501'),
	'identifierSchemeCodeUnspsc190501.42231502': __('identifierSchemeCodeUnspsc190501.42231502'),
	'identifierSchemeCodeUnspsc190501.42231503': __('identifierSchemeCodeUnspsc190501.42231503'),
	'identifierSchemeCodeUnspsc190501.42231504': __('identifierSchemeCodeUnspsc190501.42231504'),
	'identifierSchemeCodeUnspsc190501.42231505': __('identifierSchemeCodeUnspsc190501.42231505'),
	'identifierSchemeCodeUnspsc190501.42231506': __('identifierSchemeCodeUnspsc190501.42231506'),
	'identifierSchemeCodeUnspsc190501.42231507': __('identifierSchemeCodeUnspsc190501.42231507'),
	'identifierSchemeCodeUnspsc190501.42231508': __('identifierSchemeCodeUnspsc190501.42231508'),
	'identifierSchemeCodeUnspsc190501.42231509': __('identifierSchemeCodeUnspsc190501.42231509'),
	'identifierSchemeCodeUnspsc190501.42231510': __('identifierSchemeCodeUnspsc190501.42231510'),
	'identifierSchemeCodeUnspsc190501.42231600': __('identifierSchemeCodeUnspsc190501.42231600'),
	'identifierSchemeCodeUnspsc190501.42231601': __('identifierSchemeCodeUnspsc190501.42231601'),
	'identifierSchemeCodeUnspsc190501.42231602': __('identifierSchemeCodeUnspsc190501.42231602'),
	'identifierSchemeCodeUnspsc190501.42231603': __('identifierSchemeCodeUnspsc190501.42231603'),
	'identifierSchemeCodeUnspsc190501.42231604': __('identifierSchemeCodeUnspsc190501.42231604'),
	'identifierSchemeCodeUnspsc190501.42231605': __('identifierSchemeCodeUnspsc190501.42231605'),
	'identifierSchemeCodeUnspsc190501.42231606': __('identifierSchemeCodeUnspsc190501.42231606'),
	'identifierSchemeCodeUnspsc190501.42231608': __('identifierSchemeCodeUnspsc190501.42231608'),
	'identifierSchemeCodeUnspsc190501.42231609': __('identifierSchemeCodeUnspsc190501.42231609'),
	'identifierSchemeCodeUnspsc190501.42231610': __('identifierSchemeCodeUnspsc190501.42231610'),
	'identifierSchemeCodeUnspsc190501.42231611': __('identifierSchemeCodeUnspsc190501.42231611'),
	'identifierSchemeCodeUnspsc190501.42231612': __('identifierSchemeCodeUnspsc190501.42231612'),
	'identifierSchemeCodeUnspsc190501.42231613': __('identifierSchemeCodeUnspsc190501.42231613'),
	'identifierSchemeCodeUnspsc190501.42231614': __('identifierSchemeCodeUnspsc190501.42231614'),
	'identifierSchemeCodeUnspsc190501.42231615': __('identifierSchemeCodeUnspsc190501.42231615'),
	'identifierSchemeCodeUnspsc190501.42231616': __('identifierSchemeCodeUnspsc190501.42231616'),
	'identifierSchemeCodeUnspsc190501.42231617': __('identifierSchemeCodeUnspsc190501.42231617'),
	'identifierSchemeCodeUnspsc190501.42231618': __('identifierSchemeCodeUnspsc190501.42231618'),
	'identifierSchemeCodeUnspsc190501.42231700': __('identifierSchemeCodeUnspsc190501.42231700'),
	'identifierSchemeCodeUnspsc190501.42231701': __('identifierSchemeCodeUnspsc190501.42231701'),
	'identifierSchemeCodeUnspsc190501.42231702': __('identifierSchemeCodeUnspsc190501.42231702'),
	'identifierSchemeCodeUnspsc190501.42231703': __('identifierSchemeCodeUnspsc190501.42231703'),
	'identifierSchemeCodeUnspsc190501.42231705': __('identifierSchemeCodeUnspsc190501.42231705'),
	'identifierSchemeCodeUnspsc190501.42231800': __('identifierSchemeCodeUnspsc190501.42231800'),
	'identifierSchemeCodeUnspsc190501.42231801': __('identifierSchemeCodeUnspsc190501.42231801'),
	'identifierSchemeCodeUnspsc190501.42231802': __('identifierSchemeCodeUnspsc190501.42231802'),
	'identifierSchemeCodeUnspsc190501.42231803': __('identifierSchemeCodeUnspsc190501.42231803'),
	'identifierSchemeCodeUnspsc190501.42231804': __('identifierSchemeCodeUnspsc190501.42231804'),
	'identifierSchemeCodeUnspsc190501.42231806': __('identifierSchemeCodeUnspsc190501.42231806'),
	'identifierSchemeCodeUnspsc190501.42231807': __('identifierSchemeCodeUnspsc190501.42231807'),
	'identifierSchemeCodeUnspsc190501.42231808': __('identifierSchemeCodeUnspsc190501.42231808'),
	'identifierSchemeCodeUnspsc190501.42231809': __('identifierSchemeCodeUnspsc190501.42231809'),
	'identifierSchemeCodeUnspsc190501.42231900': __('identifierSchemeCodeUnspsc190501.42231900'),
	'identifierSchemeCodeUnspsc190501.42231901': __('identifierSchemeCodeUnspsc190501.42231901'),
	'identifierSchemeCodeUnspsc190501.42231902': __('identifierSchemeCodeUnspsc190501.42231902'),
	'identifierSchemeCodeUnspsc190501.42231903': __('identifierSchemeCodeUnspsc190501.42231903'),
	'identifierSchemeCodeUnspsc190501.42231904': __('identifierSchemeCodeUnspsc190501.42231904'),
	'identifierSchemeCodeUnspsc190501.42231905': __('identifierSchemeCodeUnspsc190501.42231905'),
	'identifierSchemeCodeUnspsc190501.42231906': __('identifierSchemeCodeUnspsc190501.42231906'),
	'identifierSchemeCodeUnspsc190501.42232000': __('identifierSchemeCodeUnspsc190501.42232000'),
	'identifierSchemeCodeUnspsc190501.42232001': __('identifierSchemeCodeUnspsc190501.42232001'),
	'identifierSchemeCodeUnspsc190501.42232002': __('identifierSchemeCodeUnspsc190501.42232002'),
	'identifierSchemeCodeUnspsc190501.42232003': __('identifierSchemeCodeUnspsc190501.42232003'),
	'identifierSchemeCodeUnspsc190501.42232004': __('identifierSchemeCodeUnspsc190501.42232004'),
	'identifierSchemeCodeUnspsc190501.42232005': __('identifierSchemeCodeUnspsc190501.42232005'),
	'identifierSchemeCodeUnspsc190501.42232006': __('identifierSchemeCodeUnspsc190501.42232006'),
	'identifierSchemeCodeUnspsc190501.42240000': __('identifierSchemeCodeUnspsc190501.42240000'),
	'identifierSchemeCodeUnspsc190501.42241500': __('identifierSchemeCodeUnspsc190501.42241500'),
	'identifierSchemeCodeUnspsc190501.42241501': __('identifierSchemeCodeUnspsc190501.42241501'),
	'identifierSchemeCodeUnspsc190501.42241502': __('identifierSchemeCodeUnspsc190501.42241502'),
	'identifierSchemeCodeUnspsc190501.42241503': __('identifierSchemeCodeUnspsc190501.42241503'),
	'identifierSchemeCodeUnspsc190501.42241504': __('identifierSchemeCodeUnspsc190501.42241504'),
	'identifierSchemeCodeUnspsc190501.42241505': __('identifierSchemeCodeUnspsc190501.42241505'),
	'identifierSchemeCodeUnspsc190501.42241506': __('identifierSchemeCodeUnspsc190501.42241506'),
	'identifierSchemeCodeUnspsc190501.42241507': __('identifierSchemeCodeUnspsc190501.42241507'),
	'identifierSchemeCodeUnspsc190501.42241509': __('identifierSchemeCodeUnspsc190501.42241509'),
	'identifierSchemeCodeUnspsc190501.42241510': __('identifierSchemeCodeUnspsc190501.42241510'),
	'identifierSchemeCodeUnspsc190501.42241511': __('identifierSchemeCodeUnspsc190501.42241511'),
	'identifierSchemeCodeUnspsc190501.42241512': __('identifierSchemeCodeUnspsc190501.42241512'),
	'identifierSchemeCodeUnspsc190501.42241513': __('identifierSchemeCodeUnspsc190501.42241513'),
	'identifierSchemeCodeUnspsc190501.42241514': __('identifierSchemeCodeUnspsc190501.42241514'),
	'identifierSchemeCodeUnspsc190501.42241516': __('identifierSchemeCodeUnspsc190501.42241516'),
	'identifierSchemeCodeUnspsc190501.42241517': __('identifierSchemeCodeUnspsc190501.42241517'),
	'identifierSchemeCodeUnspsc190501.42241518': __('identifierSchemeCodeUnspsc190501.42241518'),
	'identifierSchemeCodeUnspsc190501.42241519': __('identifierSchemeCodeUnspsc190501.42241519'),
	'identifierSchemeCodeUnspsc190501.42241520': __('identifierSchemeCodeUnspsc190501.42241520'),
	'identifierSchemeCodeUnspsc190501.42241600': __('identifierSchemeCodeUnspsc190501.42241600'),
	'identifierSchemeCodeUnspsc190501.42241602': __('identifierSchemeCodeUnspsc190501.42241602'),
	'identifierSchemeCodeUnspsc190501.42241603': __('identifierSchemeCodeUnspsc190501.42241603'),
	'identifierSchemeCodeUnspsc190501.42241604': __('identifierSchemeCodeUnspsc190501.42241604'),
	'identifierSchemeCodeUnspsc190501.42241606': __('identifierSchemeCodeUnspsc190501.42241606'),
	'identifierSchemeCodeUnspsc190501.42241700': __('identifierSchemeCodeUnspsc190501.42241700'),
	'identifierSchemeCodeUnspsc190501.42241701': __('identifierSchemeCodeUnspsc190501.42241701'),
	'identifierSchemeCodeUnspsc190501.42241702': __('identifierSchemeCodeUnspsc190501.42241702'),
	'identifierSchemeCodeUnspsc190501.42241703': __('identifierSchemeCodeUnspsc190501.42241703'),
	'identifierSchemeCodeUnspsc190501.42241705': __('identifierSchemeCodeUnspsc190501.42241705'),
	'identifierSchemeCodeUnspsc190501.42241706': __('identifierSchemeCodeUnspsc190501.42241706'),
	'identifierSchemeCodeUnspsc190501.42241707': __('identifierSchemeCodeUnspsc190501.42241707'),
	'identifierSchemeCodeUnspsc190501.42241708': __('identifierSchemeCodeUnspsc190501.42241708'),
	'identifierSchemeCodeUnspsc190501.42241709': __('identifierSchemeCodeUnspsc190501.42241709'),
	'identifierSchemeCodeUnspsc190501.42241710': __('identifierSchemeCodeUnspsc190501.42241710'),
	'identifierSchemeCodeUnspsc190501.42241711': __('identifierSchemeCodeUnspsc190501.42241711'),
	'identifierSchemeCodeUnspsc190501.42241712': __('identifierSchemeCodeUnspsc190501.42241712'),
	'identifierSchemeCodeUnspsc190501.42241713': __('identifierSchemeCodeUnspsc190501.42241713'),
	'identifierSchemeCodeUnspsc190501.42241714': __('identifierSchemeCodeUnspsc190501.42241714'),
	'identifierSchemeCodeUnspsc190501.42241800': __('identifierSchemeCodeUnspsc190501.42241800'),
	'identifierSchemeCodeUnspsc190501.42241801': __('identifierSchemeCodeUnspsc190501.42241801'),
	'identifierSchemeCodeUnspsc190501.42241802': __('identifierSchemeCodeUnspsc190501.42241802'),
	'identifierSchemeCodeUnspsc190501.42241803': __('identifierSchemeCodeUnspsc190501.42241803'),
	'identifierSchemeCodeUnspsc190501.42241804': __('identifierSchemeCodeUnspsc190501.42241804'),
	'identifierSchemeCodeUnspsc190501.42241805': __('identifierSchemeCodeUnspsc190501.42241805'),
	'identifierSchemeCodeUnspsc190501.42241806': __('identifierSchemeCodeUnspsc190501.42241806'),
	'identifierSchemeCodeUnspsc190501.42241807': __('identifierSchemeCodeUnspsc190501.42241807'),
	'identifierSchemeCodeUnspsc190501.42241808': __('identifierSchemeCodeUnspsc190501.42241808'),
	'identifierSchemeCodeUnspsc190501.42241809': __('identifierSchemeCodeUnspsc190501.42241809'),
	'identifierSchemeCodeUnspsc190501.42241811': __('identifierSchemeCodeUnspsc190501.42241811'),
	'identifierSchemeCodeUnspsc190501.42241900': __('identifierSchemeCodeUnspsc190501.42241900'),
	'identifierSchemeCodeUnspsc190501.42241901': __('identifierSchemeCodeUnspsc190501.42241901'),
	'identifierSchemeCodeUnspsc190501.42241902': __('identifierSchemeCodeUnspsc190501.42241902'),
	'identifierSchemeCodeUnspsc190501.42242000': __('identifierSchemeCodeUnspsc190501.42242000'),
	'identifierSchemeCodeUnspsc190501.42242001': __('identifierSchemeCodeUnspsc190501.42242001'),
	'identifierSchemeCodeUnspsc190501.42242002': __('identifierSchemeCodeUnspsc190501.42242002'),
	'identifierSchemeCodeUnspsc190501.42242003': __('identifierSchemeCodeUnspsc190501.42242003'),
	'identifierSchemeCodeUnspsc190501.42242004': __('identifierSchemeCodeUnspsc190501.42242004'),
	'identifierSchemeCodeUnspsc190501.42242005': __('identifierSchemeCodeUnspsc190501.42242005'),
	'identifierSchemeCodeUnspsc190501.42242006': __('identifierSchemeCodeUnspsc190501.42242006'),
	'identifierSchemeCodeUnspsc190501.42242100': __('identifierSchemeCodeUnspsc190501.42242100'),
	'identifierSchemeCodeUnspsc190501.42242101': __('identifierSchemeCodeUnspsc190501.42242101'),
	'identifierSchemeCodeUnspsc190501.42242102': __('identifierSchemeCodeUnspsc190501.42242102'),
	'identifierSchemeCodeUnspsc190501.42242103': __('identifierSchemeCodeUnspsc190501.42242103'),
	'identifierSchemeCodeUnspsc190501.42242104': __('identifierSchemeCodeUnspsc190501.42242104'),
	'identifierSchemeCodeUnspsc190501.42242106': __('identifierSchemeCodeUnspsc190501.42242106'),
	'identifierSchemeCodeUnspsc190501.42242107': __('identifierSchemeCodeUnspsc190501.42242107'),
	'identifierSchemeCodeUnspsc190501.42242108': __('identifierSchemeCodeUnspsc190501.42242108'),
	'identifierSchemeCodeUnspsc190501.42242109': __('identifierSchemeCodeUnspsc190501.42242109'),
	'identifierSchemeCodeUnspsc190501.42250000': __('identifierSchemeCodeUnspsc190501.42250000'),
	'identifierSchemeCodeUnspsc190501.42251500': __('identifierSchemeCodeUnspsc190501.42251500'),
	'identifierSchemeCodeUnspsc190501.42251501': __('identifierSchemeCodeUnspsc190501.42251501'),
	'identifierSchemeCodeUnspsc190501.42251502': __('identifierSchemeCodeUnspsc190501.42251502'),
	'identifierSchemeCodeUnspsc190501.42251503': __('identifierSchemeCodeUnspsc190501.42251503'),
	'identifierSchemeCodeUnspsc190501.42251504': __('identifierSchemeCodeUnspsc190501.42251504'),
	'identifierSchemeCodeUnspsc190501.42251505': __('identifierSchemeCodeUnspsc190501.42251505'),
	'identifierSchemeCodeUnspsc190501.42251506': __('identifierSchemeCodeUnspsc190501.42251506'),
	'identifierSchemeCodeUnspsc190501.42251600': __('identifierSchemeCodeUnspsc190501.42251600'),
	'identifierSchemeCodeUnspsc190501.42251601': __('identifierSchemeCodeUnspsc190501.42251601'),
	'identifierSchemeCodeUnspsc190501.42251602': __('identifierSchemeCodeUnspsc190501.42251602'),
	'identifierSchemeCodeUnspsc190501.42251603': __('identifierSchemeCodeUnspsc190501.42251603'),
	'identifierSchemeCodeUnspsc190501.42251604': __('identifierSchemeCodeUnspsc190501.42251604'),
	'identifierSchemeCodeUnspsc190501.42251605': __('identifierSchemeCodeUnspsc190501.42251605'),
	'identifierSchemeCodeUnspsc190501.42251606': __('identifierSchemeCodeUnspsc190501.42251606'),
	'identifierSchemeCodeUnspsc190501.42251607': __('identifierSchemeCodeUnspsc190501.42251607'),
	'identifierSchemeCodeUnspsc190501.42251608': __('identifierSchemeCodeUnspsc190501.42251608'),
	'identifierSchemeCodeUnspsc190501.42251609': __('identifierSchemeCodeUnspsc190501.42251609'),
	'identifierSchemeCodeUnspsc190501.42251610': __('identifierSchemeCodeUnspsc190501.42251610'),
	'identifierSchemeCodeUnspsc190501.42251611': __('identifierSchemeCodeUnspsc190501.42251611'),
	'identifierSchemeCodeUnspsc190501.42251612': __('identifierSchemeCodeUnspsc190501.42251612'),
	'identifierSchemeCodeUnspsc190501.42251613': __('identifierSchemeCodeUnspsc190501.42251613'),
	'identifierSchemeCodeUnspsc190501.42251614': __('identifierSchemeCodeUnspsc190501.42251614'),
	'identifierSchemeCodeUnspsc190501.42251615': __('identifierSchemeCodeUnspsc190501.42251615'),
	'identifierSchemeCodeUnspsc190501.42251616': __('identifierSchemeCodeUnspsc190501.42251616'),
	'identifierSchemeCodeUnspsc190501.42251617': __('identifierSchemeCodeUnspsc190501.42251617'),
	'identifierSchemeCodeUnspsc190501.42251618': __('identifierSchemeCodeUnspsc190501.42251618'),
	'identifierSchemeCodeUnspsc190501.42251619': __('identifierSchemeCodeUnspsc190501.42251619'),
	'identifierSchemeCodeUnspsc190501.42251620': __('identifierSchemeCodeUnspsc190501.42251620'),
	'identifierSchemeCodeUnspsc190501.42251621': __('identifierSchemeCodeUnspsc190501.42251621'),
	'identifierSchemeCodeUnspsc190501.42251622': __('identifierSchemeCodeUnspsc190501.42251622'),
	'identifierSchemeCodeUnspsc190501.42251623': __('identifierSchemeCodeUnspsc190501.42251623'),
	'identifierSchemeCodeUnspsc190501.42251624': __('identifierSchemeCodeUnspsc190501.42251624'),
	'identifierSchemeCodeUnspsc190501.42251625': __('identifierSchemeCodeUnspsc190501.42251625'),
	'identifierSchemeCodeUnspsc190501.42251626': __('identifierSchemeCodeUnspsc190501.42251626'),
	'identifierSchemeCodeUnspsc190501.42251627': __('identifierSchemeCodeUnspsc190501.42251627'),
	'identifierSchemeCodeUnspsc190501.42251628': __('identifierSchemeCodeUnspsc190501.42251628'),
	'identifierSchemeCodeUnspsc190501.42251629': __('identifierSchemeCodeUnspsc190501.42251629'),
	'identifierSchemeCodeUnspsc190501.42251630': __('identifierSchemeCodeUnspsc190501.42251630'),
	'identifierSchemeCodeUnspsc190501.42251631': __('identifierSchemeCodeUnspsc190501.42251631'),
	'identifierSchemeCodeUnspsc190501.42251632': __('identifierSchemeCodeUnspsc190501.42251632'),
	'identifierSchemeCodeUnspsc190501.42251700': __('identifierSchemeCodeUnspsc190501.42251700'),
	'identifierSchemeCodeUnspsc190501.42251701': __('identifierSchemeCodeUnspsc190501.42251701'),
	'identifierSchemeCodeUnspsc190501.42251702': __('identifierSchemeCodeUnspsc190501.42251702'),
	'identifierSchemeCodeUnspsc190501.42251703': __('identifierSchemeCodeUnspsc190501.42251703'),
	'identifierSchemeCodeUnspsc190501.42251704': __('identifierSchemeCodeUnspsc190501.42251704'),
	'identifierSchemeCodeUnspsc190501.42251705': __('identifierSchemeCodeUnspsc190501.42251705'),
	'identifierSchemeCodeUnspsc190501.42251706': __('identifierSchemeCodeUnspsc190501.42251706'),
	'identifierSchemeCodeUnspsc190501.42251707': __('identifierSchemeCodeUnspsc190501.42251707'),
	'identifierSchemeCodeUnspsc190501.42251800': __('identifierSchemeCodeUnspsc190501.42251800'),
	'identifierSchemeCodeUnspsc190501.42251801': __('identifierSchemeCodeUnspsc190501.42251801'),
	'identifierSchemeCodeUnspsc190501.42251802': __('identifierSchemeCodeUnspsc190501.42251802'),
	'identifierSchemeCodeUnspsc190501.42251803': __('identifierSchemeCodeUnspsc190501.42251803'),
	'identifierSchemeCodeUnspsc190501.42251804': __('identifierSchemeCodeUnspsc190501.42251804'),
	'identifierSchemeCodeUnspsc190501.42251805': __('identifierSchemeCodeUnspsc190501.42251805'),
	'identifierSchemeCodeUnspsc190501.42251806': __('identifierSchemeCodeUnspsc190501.42251806'),
	'identifierSchemeCodeUnspsc190501.42251807': __('identifierSchemeCodeUnspsc190501.42251807'),
	'identifierSchemeCodeUnspsc190501.42251808': __('identifierSchemeCodeUnspsc190501.42251808'),
	'identifierSchemeCodeUnspsc190501.42251809': __('identifierSchemeCodeUnspsc190501.42251809'),
	'identifierSchemeCodeUnspsc190501.42251810': __('identifierSchemeCodeUnspsc190501.42251810'),
	'identifierSchemeCodeUnspsc190501.42260000': __('identifierSchemeCodeUnspsc190501.42260000'),
	'identifierSchemeCodeUnspsc190501.42261500': __('identifierSchemeCodeUnspsc190501.42261500'),
	'identifierSchemeCodeUnspsc190501.42261501': __('identifierSchemeCodeUnspsc190501.42261501'),
	'identifierSchemeCodeUnspsc190501.42261502': __('identifierSchemeCodeUnspsc190501.42261502'),
	'identifierSchemeCodeUnspsc190501.42261503': __('identifierSchemeCodeUnspsc190501.42261503'),
	'identifierSchemeCodeUnspsc190501.42261504': __('identifierSchemeCodeUnspsc190501.42261504'),
	'identifierSchemeCodeUnspsc190501.42261505': __('identifierSchemeCodeUnspsc190501.42261505'),
	'identifierSchemeCodeUnspsc190501.42261506': __('identifierSchemeCodeUnspsc190501.42261506'),
	'identifierSchemeCodeUnspsc190501.42261507': __('identifierSchemeCodeUnspsc190501.42261507'),
	'identifierSchemeCodeUnspsc190501.42261508': __('identifierSchemeCodeUnspsc190501.42261508'),
	'identifierSchemeCodeUnspsc190501.42261509': __('identifierSchemeCodeUnspsc190501.42261509'),
	'identifierSchemeCodeUnspsc190501.42261510': __('identifierSchemeCodeUnspsc190501.42261510'),
	'identifierSchemeCodeUnspsc190501.42261511': __('identifierSchemeCodeUnspsc190501.42261511'),
	'identifierSchemeCodeUnspsc190501.42261512': __('identifierSchemeCodeUnspsc190501.42261512'),
	'identifierSchemeCodeUnspsc190501.42261513': __('identifierSchemeCodeUnspsc190501.42261513'),
	'identifierSchemeCodeUnspsc190501.42261514': __('identifierSchemeCodeUnspsc190501.42261514'),
	'identifierSchemeCodeUnspsc190501.42261516': __('identifierSchemeCodeUnspsc190501.42261516'),
	'identifierSchemeCodeUnspsc190501.42261600': __('identifierSchemeCodeUnspsc190501.42261600'),
	'identifierSchemeCodeUnspsc190501.42261601': __('identifierSchemeCodeUnspsc190501.42261601'),
	'identifierSchemeCodeUnspsc190501.42261602': __('identifierSchemeCodeUnspsc190501.42261602'),
	'identifierSchemeCodeUnspsc190501.42261604': __('identifierSchemeCodeUnspsc190501.42261604'),
	'identifierSchemeCodeUnspsc190501.42261605': __('identifierSchemeCodeUnspsc190501.42261605'),
	'identifierSchemeCodeUnspsc190501.42261606': __('identifierSchemeCodeUnspsc190501.42261606'),
	'identifierSchemeCodeUnspsc190501.42261607': __('identifierSchemeCodeUnspsc190501.42261607'),
	'identifierSchemeCodeUnspsc190501.42261608': __('identifierSchemeCodeUnspsc190501.42261608'),
	'identifierSchemeCodeUnspsc190501.42261609': __('identifierSchemeCodeUnspsc190501.42261609'),
	'identifierSchemeCodeUnspsc190501.42261610': __('identifierSchemeCodeUnspsc190501.42261610'),
	'identifierSchemeCodeUnspsc190501.42261611': __('identifierSchemeCodeUnspsc190501.42261611'),
	'identifierSchemeCodeUnspsc190501.42261612': __('identifierSchemeCodeUnspsc190501.42261612'),
	'identifierSchemeCodeUnspsc190501.42261613': __('identifierSchemeCodeUnspsc190501.42261613'),
	'identifierSchemeCodeUnspsc190501.42261700': __('identifierSchemeCodeUnspsc190501.42261700'),
	'identifierSchemeCodeUnspsc190501.42261701': __('identifierSchemeCodeUnspsc190501.42261701'),
	'identifierSchemeCodeUnspsc190501.42261702': __('identifierSchemeCodeUnspsc190501.42261702'),
	'identifierSchemeCodeUnspsc190501.42261703': __('identifierSchemeCodeUnspsc190501.42261703'),
	'identifierSchemeCodeUnspsc190501.42261704': __('identifierSchemeCodeUnspsc190501.42261704'),
	'identifierSchemeCodeUnspsc190501.42261705': __('identifierSchemeCodeUnspsc190501.42261705'),
	'identifierSchemeCodeUnspsc190501.42261706': __('identifierSchemeCodeUnspsc190501.42261706'),
	'identifierSchemeCodeUnspsc190501.42261707': __('identifierSchemeCodeUnspsc190501.42261707'),
	'identifierSchemeCodeUnspsc190501.42261708': __('identifierSchemeCodeUnspsc190501.42261708'),
	'identifierSchemeCodeUnspsc190501.42261800': __('identifierSchemeCodeUnspsc190501.42261800'),
	'identifierSchemeCodeUnspsc190501.42261801': __('identifierSchemeCodeUnspsc190501.42261801'),
	'identifierSchemeCodeUnspsc190501.42261802': __('identifierSchemeCodeUnspsc190501.42261802'),
	'identifierSchemeCodeUnspsc190501.42261803': __('identifierSchemeCodeUnspsc190501.42261803'),
	'identifierSchemeCodeUnspsc190501.42261804': __('identifierSchemeCodeUnspsc190501.42261804'),
	'identifierSchemeCodeUnspsc190501.42261805': __('identifierSchemeCodeUnspsc190501.42261805'),
	'identifierSchemeCodeUnspsc190501.42261806': __('identifierSchemeCodeUnspsc190501.42261806'),
	'identifierSchemeCodeUnspsc190501.42261807': __('identifierSchemeCodeUnspsc190501.42261807'),
	'identifierSchemeCodeUnspsc190501.42261808': __('identifierSchemeCodeUnspsc190501.42261808'),
	'identifierSchemeCodeUnspsc190501.42261809': __('identifierSchemeCodeUnspsc190501.42261809'),
	'identifierSchemeCodeUnspsc190501.42261810': __('identifierSchemeCodeUnspsc190501.42261810'),
	'identifierSchemeCodeUnspsc190501.42261900': __('identifierSchemeCodeUnspsc190501.42261900'),
	'identifierSchemeCodeUnspsc190501.42261901': __('identifierSchemeCodeUnspsc190501.42261901'),
	'identifierSchemeCodeUnspsc190501.42261902': __('identifierSchemeCodeUnspsc190501.42261902'),
	'identifierSchemeCodeUnspsc190501.42261903': __('identifierSchemeCodeUnspsc190501.42261903'),
	'identifierSchemeCodeUnspsc190501.42261904': __('identifierSchemeCodeUnspsc190501.42261904'),
	'identifierSchemeCodeUnspsc190501.42262000': __('identifierSchemeCodeUnspsc190501.42262000'),
	'identifierSchemeCodeUnspsc190501.42262001': __('identifierSchemeCodeUnspsc190501.42262001'),
	'identifierSchemeCodeUnspsc190501.42262002': __('identifierSchemeCodeUnspsc190501.42262002'),
	'identifierSchemeCodeUnspsc190501.42262003': __('identifierSchemeCodeUnspsc190501.42262003'),
	'identifierSchemeCodeUnspsc190501.42262004': __('identifierSchemeCodeUnspsc190501.42262004'),
	'identifierSchemeCodeUnspsc190501.42262005': __('identifierSchemeCodeUnspsc190501.42262005'),
	'identifierSchemeCodeUnspsc190501.42262006': __('identifierSchemeCodeUnspsc190501.42262006'),
	'identifierSchemeCodeUnspsc190501.42262007': __('identifierSchemeCodeUnspsc190501.42262007'),
	'identifierSchemeCodeUnspsc190501.42262008': __('identifierSchemeCodeUnspsc190501.42262008'),
	'identifierSchemeCodeUnspsc190501.42262009': __('identifierSchemeCodeUnspsc190501.42262009'),
	'identifierSchemeCodeUnspsc190501.42262100': __('identifierSchemeCodeUnspsc190501.42262100'),
	'identifierSchemeCodeUnspsc190501.42262101': __('identifierSchemeCodeUnspsc190501.42262101'),
	'identifierSchemeCodeUnspsc190501.42262102': __('identifierSchemeCodeUnspsc190501.42262102'),
	'identifierSchemeCodeUnspsc190501.42262103': __('identifierSchemeCodeUnspsc190501.42262103'),
	'identifierSchemeCodeUnspsc190501.42262104': __('identifierSchemeCodeUnspsc190501.42262104'),
	'identifierSchemeCodeUnspsc190501.42262105': __('identifierSchemeCodeUnspsc190501.42262105'),
	'identifierSchemeCodeUnspsc190501.42270000': __('identifierSchemeCodeUnspsc190501.42270000'),
	'identifierSchemeCodeUnspsc190501.42271500': __('identifierSchemeCodeUnspsc190501.42271500'),
	'identifierSchemeCodeUnspsc190501.42271501': __('identifierSchemeCodeUnspsc190501.42271501'),
	'identifierSchemeCodeUnspsc190501.42271502': __('identifierSchemeCodeUnspsc190501.42271502'),
	'identifierSchemeCodeUnspsc190501.42271503': __('identifierSchemeCodeUnspsc190501.42271503'),
	'identifierSchemeCodeUnspsc190501.42271504': __('identifierSchemeCodeUnspsc190501.42271504'),
	'identifierSchemeCodeUnspsc190501.42271505': __('identifierSchemeCodeUnspsc190501.42271505'),
	'identifierSchemeCodeUnspsc190501.42271507': __('identifierSchemeCodeUnspsc190501.42271507'),
	'identifierSchemeCodeUnspsc190501.42271508': __('identifierSchemeCodeUnspsc190501.42271508'),
	'identifierSchemeCodeUnspsc190501.42271509': __('identifierSchemeCodeUnspsc190501.42271509'),
	'identifierSchemeCodeUnspsc190501.42271510': __('identifierSchemeCodeUnspsc190501.42271510'),
	'identifierSchemeCodeUnspsc190501.42271511': __('identifierSchemeCodeUnspsc190501.42271511'),
	'identifierSchemeCodeUnspsc190501.42271512': __('identifierSchemeCodeUnspsc190501.42271512'),
	'identifierSchemeCodeUnspsc190501.42271513': __('identifierSchemeCodeUnspsc190501.42271513'),
	'identifierSchemeCodeUnspsc190501.42271514': __('identifierSchemeCodeUnspsc190501.42271514'),
	'identifierSchemeCodeUnspsc190501.42271515': __('identifierSchemeCodeUnspsc190501.42271515'),
	'identifierSchemeCodeUnspsc190501.42271600': __('identifierSchemeCodeUnspsc190501.42271600'),
	'identifierSchemeCodeUnspsc190501.42271602': __('identifierSchemeCodeUnspsc190501.42271602'),
	'identifierSchemeCodeUnspsc190501.42271603': __('identifierSchemeCodeUnspsc190501.42271603'),
	'identifierSchemeCodeUnspsc190501.42271604': __('identifierSchemeCodeUnspsc190501.42271604'),
	'identifierSchemeCodeUnspsc190501.42271605': __('identifierSchemeCodeUnspsc190501.42271605'),
	'identifierSchemeCodeUnspsc190501.42271606': __('identifierSchemeCodeUnspsc190501.42271606'),
	'identifierSchemeCodeUnspsc190501.42271607': __('identifierSchemeCodeUnspsc190501.42271607'),
	'identifierSchemeCodeUnspsc190501.42271608': __('identifierSchemeCodeUnspsc190501.42271608'),
	'identifierSchemeCodeUnspsc190501.42271609': __('identifierSchemeCodeUnspsc190501.42271609'),
	'identifierSchemeCodeUnspsc190501.42271610': __('identifierSchemeCodeUnspsc190501.42271610'),
	'identifierSchemeCodeUnspsc190501.42271611': __('identifierSchemeCodeUnspsc190501.42271611'),
	'identifierSchemeCodeUnspsc190501.42271612': __('identifierSchemeCodeUnspsc190501.42271612'),
	'identifierSchemeCodeUnspsc190501.42271613': __('identifierSchemeCodeUnspsc190501.42271613'),
	'identifierSchemeCodeUnspsc190501.42271614': __('identifierSchemeCodeUnspsc190501.42271614'),
	'identifierSchemeCodeUnspsc190501.42271615': __('identifierSchemeCodeUnspsc190501.42271615'),
	'identifierSchemeCodeUnspsc190501.42271616': __('identifierSchemeCodeUnspsc190501.42271616'),
	'identifierSchemeCodeUnspsc190501.42271617': __('identifierSchemeCodeUnspsc190501.42271617'),
	'identifierSchemeCodeUnspsc190501.42271618': __('identifierSchemeCodeUnspsc190501.42271618'),
	'identifierSchemeCodeUnspsc190501.42271619': __('identifierSchemeCodeUnspsc190501.42271619'),
	'identifierSchemeCodeUnspsc190501.42271620': __('identifierSchemeCodeUnspsc190501.42271620'),
	'identifierSchemeCodeUnspsc190501.42271621': __('identifierSchemeCodeUnspsc190501.42271621'),
	'identifierSchemeCodeUnspsc190501.42271622': __('identifierSchemeCodeUnspsc190501.42271622'),
	'identifierSchemeCodeUnspsc190501.42271623': __('identifierSchemeCodeUnspsc190501.42271623'),
	'identifierSchemeCodeUnspsc190501.42271624': __('identifierSchemeCodeUnspsc190501.42271624'),
	'identifierSchemeCodeUnspsc190501.42271625': __('identifierSchemeCodeUnspsc190501.42271625'),
	'identifierSchemeCodeUnspsc190501.42271626': __('identifierSchemeCodeUnspsc190501.42271626'),
	'identifierSchemeCodeUnspsc190501.42271627': __('identifierSchemeCodeUnspsc190501.42271627'),
	'identifierSchemeCodeUnspsc190501.42271628': __('identifierSchemeCodeUnspsc190501.42271628'),
	'identifierSchemeCodeUnspsc190501.42271629': __('identifierSchemeCodeUnspsc190501.42271629'),
	'identifierSchemeCodeUnspsc190501.42271630': __('identifierSchemeCodeUnspsc190501.42271630'),
	'identifierSchemeCodeUnspsc190501.42271631': __('identifierSchemeCodeUnspsc190501.42271631'),
	'identifierSchemeCodeUnspsc190501.42271632': __('identifierSchemeCodeUnspsc190501.42271632'),
	'identifierSchemeCodeUnspsc190501.42271633': __('identifierSchemeCodeUnspsc190501.42271633'),
	'identifierSchemeCodeUnspsc190501.42271634': __('identifierSchemeCodeUnspsc190501.42271634'),
	'identifierSchemeCodeUnspsc190501.42271635': __('identifierSchemeCodeUnspsc190501.42271635'),
	'identifierSchemeCodeUnspsc190501.42271636': __('identifierSchemeCodeUnspsc190501.42271636'),
	'identifierSchemeCodeUnspsc190501.42271637': __('identifierSchemeCodeUnspsc190501.42271637'),
	'identifierSchemeCodeUnspsc190501.42271638': __('identifierSchemeCodeUnspsc190501.42271638'),
	'identifierSchemeCodeUnspsc190501.42271639': __('identifierSchemeCodeUnspsc190501.42271639'),
	'identifierSchemeCodeUnspsc190501.42271640': __('identifierSchemeCodeUnspsc190501.42271640'),
	'identifierSchemeCodeUnspsc190501.42271641': __('identifierSchemeCodeUnspsc190501.42271641'),
	'identifierSchemeCodeUnspsc190501.42271642': __('identifierSchemeCodeUnspsc190501.42271642'),
	'identifierSchemeCodeUnspsc190501.42271643': __('identifierSchemeCodeUnspsc190501.42271643'),
	'identifierSchemeCodeUnspsc190501.42271644': __('identifierSchemeCodeUnspsc190501.42271644'),
	'identifierSchemeCodeUnspsc190501.42271645': __('identifierSchemeCodeUnspsc190501.42271645'),
	'identifierSchemeCodeUnspsc190501.42271646': __('identifierSchemeCodeUnspsc190501.42271646'),
	'identifierSchemeCodeUnspsc190501.42271647': __('identifierSchemeCodeUnspsc190501.42271647'),
	'identifierSchemeCodeUnspsc190501.42271648': __('identifierSchemeCodeUnspsc190501.42271648'),
	'identifierSchemeCodeUnspsc190501.42271700': __('identifierSchemeCodeUnspsc190501.42271700'),
	'identifierSchemeCodeUnspsc190501.42271701': __('identifierSchemeCodeUnspsc190501.42271701'),
	'identifierSchemeCodeUnspsc190501.42271702': __('identifierSchemeCodeUnspsc190501.42271702'),
	'identifierSchemeCodeUnspsc190501.42271703': __('identifierSchemeCodeUnspsc190501.42271703'),
	'identifierSchemeCodeUnspsc190501.42271704': __('identifierSchemeCodeUnspsc190501.42271704'),
	'identifierSchemeCodeUnspsc190501.42271705': __('identifierSchemeCodeUnspsc190501.42271705'),
	'identifierSchemeCodeUnspsc190501.42271706': __('identifierSchemeCodeUnspsc190501.42271706'),
	'identifierSchemeCodeUnspsc190501.42271707': __('identifierSchemeCodeUnspsc190501.42271707'),
	'identifierSchemeCodeUnspsc190501.42271708': __('identifierSchemeCodeUnspsc190501.42271708'),
	'identifierSchemeCodeUnspsc190501.42271709': __('identifierSchemeCodeUnspsc190501.42271709'),
	'identifierSchemeCodeUnspsc190501.42271710': __('identifierSchemeCodeUnspsc190501.42271710'),
	'identifierSchemeCodeUnspsc190501.42271711': __('identifierSchemeCodeUnspsc190501.42271711'),
	'identifierSchemeCodeUnspsc190501.42271712': __('identifierSchemeCodeUnspsc190501.42271712'),
	'identifierSchemeCodeUnspsc190501.42271713': __('identifierSchemeCodeUnspsc190501.42271713'),
	'identifierSchemeCodeUnspsc190501.42271714': __('identifierSchemeCodeUnspsc190501.42271714'),
	'identifierSchemeCodeUnspsc190501.42271715': __('identifierSchemeCodeUnspsc190501.42271715'),
	'identifierSchemeCodeUnspsc190501.42271716': __('identifierSchemeCodeUnspsc190501.42271716'),
	'identifierSchemeCodeUnspsc190501.42271717': __('identifierSchemeCodeUnspsc190501.42271717'),
	'identifierSchemeCodeUnspsc190501.42271718': __('identifierSchemeCodeUnspsc190501.42271718'),
	'identifierSchemeCodeUnspsc190501.42271719': __('identifierSchemeCodeUnspsc190501.42271719'),
	'identifierSchemeCodeUnspsc190501.42271720': __('identifierSchemeCodeUnspsc190501.42271720'),
	'identifierSchemeCodeUnspsc190501.42271721': __('identifierSchemeCodeUnspsc190501.42271721'),
	'identifierSchemeCodeUnspsc190501.42271722': __('identifierSchemeCodeUnspsc190501.42271722'),
	'identifierSchemeCodeUnspsc190501.42271723': __('identifierSchemeCodeUnspsc190501.42271723'),
	'identifierSchemeCodeUnspsc190501.42271724': __('identifierSchemeCodeUnspsc190501.42271724'),
	'identifierSchemeCodeUnspsc190501.42271725': __('identifierSchemeCodeUnspsc190501.42271725'),
	'identifierSchemeCodeUnspsc190501.42271726': __('identifierSchemeCodeUnspsc190501.42271726'),
	'identifierSchemeCodeUnspsc190501.42271727': __('identifierSchemeCodeUnspsc190501.42271727'),
	'identifierSchemeCodeUnspsc190501.42271800': __('identifierSchemeCodeUnspsc190501.42271800'),
	'identifierSchemeCodeUnspsc190501.42271801': __('identifierSchemeCodeUnspsc190501.42271801'),
	'identifierSchemeCodeUnspsc190501.42271802': __('identifierSchemeCodeUnspsc190501.42271802'),
	'identifierSchemeCodeUnspsc190501.42271803': __('identifierSchemeCodeUnspsc190501.42271803'),
	'identifierSchemeCodeUnspsc190501.42271804': __('identifierSchemeCodeUnspsc190501.42271804'),
	'identifierSchemeCodeUnspsc190501.42271805': __('identifierSchemeCodeUnspsc190501.42271805'),
	'identifierSchemeCodeUnspsc190501.42271806': __('identifierSchemeCodeUnspsc190501.42271806'),
	'identifierSchemeCodeUnspsc190501.42271807': __('identifierSchemeCodeUnspsc190501.42271807'),
	'identifierSchemeCodeUnspsc190501.42271900': __('identifierSchemeCodeUnspsc190501.42271900'),
	'identifierSchemeCodeUnspsc190501.42271902': __('identifierSchemeCodeUnspsc190501.42271902'),
	'identifierSchemeCodeUnspsc190501.42271903': __('identifierSchemeCodeUnspsc190501.42271903'),
	'identifierSchemeCodeUnspsc190501.42271904': __('identifierSchemeCodeUnspsc190501.42271904'),
	'identifierSchemeCodeUnspsc190501.42271905': __('identifierSchemeCodeUnspsc190501.42271905'),
	'identifierSchemeCodeUnspsc190501.42271906': __('identifierSchemeCodeUnspsc190501.42271906'),
	'identifierSchemeCodeUnspsc190501.42271907': __('identifierSchemeCodeUnspsc190501.42271907'),
	'identifierSchemeCodeUnspsc190501.42271908': __('identifierSchemeCodeUnspsc190501.42271908'),
	'identifierSchemeCodeUnspsc190501.42271909': __('identifierSchemeCodeUnspsc190501.42271909'),
	'identifierSchemeCodeUnspsc190501.42271910': __('identifierSchemeCodeUnspsc190501.42271910'),
	'identifierSchemeCodeUnspsc190501.42271911': __('identifierSchemeCodeUnspsc190501.42271911'),
	'identifierSchemeCodeUnspsc190501.42271912': __('identifierSchemeCodeUnspsc190501.42271912'),
	'identifierSchemeCodeUnspsc190501.42271913': __('identifierSchemeCodeUnspsc190501.42271913'),
	'identifierSchemeCodeUnspsc190501.42271914': __('identifierSchemeCodeUnspsc190501.42271914'),
	'identifierSchemeCodeUnspsc190501.42271915': __('identifierSchemeCodeUnspsc190501.42271915'),
	'identifierSchemeCodeUnspsc190501.42271916': __('identifierSchemeCodeUnspsc190501.42271916'),
	'identifierSchemeCodeUnspsc190501.42271917': __('identifierSchemeCodeUnspsc190501.42271917'),
	'identifierSchemeCodeUnspsc190501.42271918': __('identifierSchemeCodeUnspsc190501.42271918'),
	'identifierSchemeCodeUnspsc190501.42271919': __('identifierSchemeCodeUnspsc190501.42271919'),
	'identifierSchemeCodeUnspsc190501.42271920': __('identifierSchemeCodeUnspsc190501.42271920'),
	'identifierSchemeCodeUnspsc190501.42271921': __('identifierSchemeCodeUnspsc190501.42271921'),
	'identifierSchemeCodeUnspsc190501.42271922': __('identifierSchemeCodeUnspsc190501.42271922'),
	'identifierSchemeCodeUnspsc190501.42271923': __('identifierSchemeCodeUnspsc190501.42271923'),
	'identifierSchemeCodeUnspsc190501.42271924': __('identifierSchemeCodeUnspsc190501.42271924'),
	'identifierSchemeCodeUnspsc190501.42271925': __('identifierSchemeCodeUnspsc190501.42271925'),
	'identifierSchemeCodeUnspsc190501.42271926': __('identifierSchemeCodeUnspsc190501.42271926'),
	'identifierSchemeCodeUnspsc190501.42271927': __('identifierSchemeCodeUnspsc190501.42271927'),
	'identifierSchemeCodeUnspsc190501.42271928': __('identifierSchemeCodeUnspsc190501.42271928'),
	'identifierSchemeCodeUnspsc190501.42271929': __('identifierSchemeCodeUnspsc190501.42271929'),
	'identifierSchemeCodeUnspsc190501.42271930': __('identifierSchemeCodeUnspsc190501.42271930'),
	'identifierSchemeCodeUnspsc190501.42271931': __('identifierSchemeCodeUnspsc190501.42271931'),
	'identifierSchemeCodeUnspsc190501.42271932': __('identifierSchemeCodeUnspsc190501.42271932'),
	'identifierSchemeCodeUnspsc190501.42271933': __('identifierSchemeCodeUnspsc190501.42271933'),
	'identifierSchemeCodeUnspsc190501.42271934': __('identifierSchemeCodeUnspsc190501.42271934'),
	'identifierSchemeCodeUnspsc190501.42271935': __('identifierSchemeCodeUnspsc190501.42271935'),
	'identifierSchemeCodeUnspsc190501.42271936': __('identifierSchemeCodeUnspsc190501.42271936'),
	'identifierSchemeCodeUnspsc190501.42272000': __('identifierSchemeCodeUnspsc190501.42272000'),
	'identifierSchemeCodeUnspsc190501.42272001': __('identifierSchemeCodeUnspsc190501.42272001'),
	'identifierSchemeCodeUnspsc190501.42272003': __('identifierSchemeCodeUnspsc190501.42272003'),
	'identifierSchemeCodeUnspsc190501.42272004': __('identifierSchemeCodeUnspsc190501.42272004'),
	'identifierSchemeCodeUnspsc190501.42272005': __('identifierSchemeCodeUnspsc190501.42272005'),
	'identifierSchemeCodeUnspsc190501.42272006': __('identifierSchemeCodeUnspsc190501.42272006'),
	'identifierSchemeCodeUnspsc190501.42272007': __('identifierSchemeCodeUnspsc190501.42272007'),
	'identifierSchemeCodeUnspsc190501.42272008': __('identifierSchemeCodeUnspsc190501.42272008'),
	'identifierSchemeCodeUnspsc190501.42272009': __('identifierSchemeCodeUnspsc190501.42272009'),
	'identifierSchemeCodeUnspsc190501.42272017': __('identifierSchemeCodeUnspsc190501.42272017'),
	'identifierSchemeCodeUnspsc190501.42272018': __('identifierSchemeCodeUnspsc190501.42272018'),
	'identifierSchemeCodeUnspsc190501.42272019': __('identifierSchemeCodeUnspsc190501.42272019'),
	'identifierSchemeCodeUnspsc190501.42272100': __('identifierSchemeCodeUnspsc190501.42272100'),
	'identifierSchemeCodeUnspsc190501.42272101': __('identifierSchemeCodeUnspsc190501.42272101'),
	'identifierSchemeCodeUnspsc190501.42272102': __('identifierSchemeCodeUnspsc190501.42272102'),
	'identifierSchemeCodeUnspsc190501.42272103': __('identifierSchemeCodeUnspsc190501.42272103'),
	'identifierSchemeCodeUnspsc190501.42272200': __('identifierSchemeCodeUnspsc190501.42272200'),
	'identifierSchemeCodeUnspsc190501.42272201': __('identifierSchemeCodeUnspsc190501.42272201'),
	'identifierSchemeCodeUnspsc190501.42272202': __('identifierSchemeCodeUnspsc190501.42272202'),
	'identifierSchemeCodeUnspsc190501.42272203': __('identifierSchemeCodeUnspsc190501.42272203'),
	'identifierSchemeCodeUnspsc190501.42272204': __('identifierSchemeCodeUnspsc190501.42272204'),
	'identifierSchemeCodeUnspsc190501.42272205': __('identifierSchemeCodeUnspsc190501.42272205'),
	'identifierSchemeCodeUnspsc190501.42272207': __('identifierSchemeCodeUnspsc190501.42272207'),
	'identifierSchemeCodeUnspsc190501.42272208': __('identifierSchemeCodeUnspsc190501.42272208'),
	'identifierSchemeCodeUnspsc190501.42272209': __('identifierSchemeCodeUnspsc190501.42272209'),
	'identifierSchemeCodeUnspsc190501.42272211': __('identifierSchemeCodeUnspsc190501.42272211'),
	'identifierSchemeCodeUnspsc190501.42272212': __('identifierSchemeCodeUnspsc190501.42272212'),
	'identifierSchemeCodeUnspsc190501.42272213': __('identifierSchemeCodeUnspsc190501.42272213'),
	'identifierSchemeCodeUnspsc190501.42272215': __('identifierSchemeCodeUnspsc190501.42272215'),
	'identifierSchemeCodeUnspsc190501.42272216': __('identifierSchemeCodeUnspsc190501.42272216'),
	'identifierSchemeCodeUnspsc190501.42272217': __('identifierSchemeCodeUnspsc190501.42272217'),
	'identifierSchemeCodeUnspsc190501.42272218': __('identifierSchemeCodeUnspsc190501.42272218'),
	'identifierSchemeCodeUnspsc190501.42272219': __('identifierSchemeCodeUnspsc190501.42272219'),
	'identifierSchemeCodeUnspsc190501.42272220': __('identifierSchemeCodeUnspsc190501.42272220'),
	'identifierSchemeCodeUnspsc190501.42272222': __('identifierSchemeCodeUnspsc190501.42272222'),
	'identifierSchemeCodeUnspsc190501.42272223': __('identifierSchemeCodeUnspsc190501.42272223'),
	'identifierSchemeCodeUnspsc190501.42272224': __('identifierSchemeCodeUnspsc190501.42272224'),
	'identifierSchemeCodeUnspsc190501.42272225': __('identifierSchemeCodeUnspsc190501.42272225'),
	'identifierSchemeCodeUnspsc190501.42272226': __('identifierSchemeCodeUnspsc190501.42272226'),
	'identifierSchemeCodeUnspsc190501.42272227': __('identifierSchemeCodeUnspsc190501.42272227'),
	'identifierSchemeCodeUnspsc190501.42272228': __('identifierSchemeCodeUnspsc190501.42272228'),
	'identifierSchemeCodeUnspsc190501.42272229': __('identifierSchemeCodeUnspsc190501.42272229'),
	'identifierSchemeCodeUnspsc190501.42272230': __('identifierSchemeCodeUnspsc190501.42272230'),
	'identifierSchemeCodeUnspsc190501.42272300': __('identifierSchemeCodeUnspsc190501.42272300'),
	'identifierSchemeCodeUnspsc190501.42272301': __('identifierSchemeCodeUnspsc190501.42272301'),
	'identifierSchemeCodeUnspsc190501.42272302': __('identifierSchemeCodeUnspsc190501.42272302'),
	'identifierSchemeCodeUnspsc190501.42272303': __('identifierSchemeCodeUnspsc190501.42272303'),
	'identifierSchemeCodeUnspsc190501.42272304': __('identifierSchemeCodeUnspsc190501.42272304'),
	'identifierSchemeCodeUnspsc190501.42272305': __('identifierSchemeCodeUnspsc190501.42272305'),
	'identifierSchemeCodeUnspsc190501.42272306': __('identifierSchemeCodeUnspsc190501.42272306'),
	'identifierSchemeCodeUnspsc190501.42272307': __('identifierSchemeCodeUnspsc190501.42272307'),
	'identifierSchemeCodeUnspsc190501.42272308': __('identifierSchemeCodeUnspsc190501.42272308'),
	'identifierSchemeCodeUnspsc190501.42272309': __('identifierSchemeCodeUnspsc190501.42272309'),
	'identifierSchemeCodeUnspsc190501.42272310': __('identifierSchemeCodeUnspsc190501.42272310'),
	'identifierSchemeCodeUnspsc190501.42272500': __('identifierSchemeCodeUnspsc190501.42272500'),
	'identifierSchemeCodeUnspsc190501.42272501': __('identifierSchemeCodeUnspsc190501.42272501'),
	'identifierSchemeCodeUnspsc190501.42272502': __('identifierSchemeCodeUnspsc190501.42272502'),
	'identifierSchemeCodeUnspsc190501.42272503': __('identifierSchemeCodeUnspsc190501.42272503'),
	'identifierSchemeCodeUnspsc190501.42272505': __('identifierSchemeCodeUnspsc190501.42272505'),
	'identifierSchemeCodeUnspsc190501.42272506': __('identifierSchemeCodeUnspsc190501.42272506'),
	'identifierSchemeCodeUnspsc190501.42272507': __('identifierSchemeCodeUnspsc190501.42272507'),
	'identifierSchemeCodeUnspsc190501.42272509': __('identifierSchemeCodeUnspsc190501.42272509'),
	'identifierSchemeCodeUnspsc190501.42272510': __('identifierSchemeCodeUnspsc190501.42272510'),
	'identifierSchemeCodeUnspsc190501.42272511': __('identifierSchemeCodeUnspsc190501.42272511'),
	'identifierSchemeCodeUnspsc190501.42272512': __('identifierSchemeCodeUnspsc190501.42272512'),
	'identifierSchemeCodeUnspsc190501.42272513': __('identifierSchemeCodeUnspsc190501.42272513'),
	'identifierSchemeCodeUnspsc190501.42272514': __('identifierSchemeCodeUnspsc190501.42272514'),
	'identifierSchemeCodeUnspsc190501.42272515': __('identifierSchemeCodeUnspsc190501.42272515'),
	'identifierSchemeCodeUnspsc190501.42272516': __('identifierSchemeCodeUnspsc190501.42272516'),
	'identifierSchemeCodeUnspsc190501.42272517': __('identifierSchemeCodeUnspsc190501.42272517'),
	'identifierSchemeCodeUnspsc190501.42272518': __('identifierSchemeCodeUnspsc190501.42272518'),
	'identifierSchemeCodeUnspsc190501.42272519': __('identifierSchemeCodeUnspsc190501.42272519'),
	'identifierSchemeCodeUnspsc190501.42272520': __('identifierSchemeCodeUnspsc190501.42272520'),
	'identifierSchemeCodeUnspsc190501.42272521': __('identifierSchemeCodeUnspsc190501.42272521'),
	'identifierSchemeCodeUnspsc190501.42272522': __('identifierSchemeCodeUnspsc190501.42272522'),
	'identifierSchemeCodeUnspsc190501.42272523': __('identifierSchemeCodeUnspsc190501.42272523'),
	'identifierSchemeCodeUnspsc190501.42272524': __('identifierSchemeCodeUnspsc190501.42272524'),
	'identifierSchemeCodeUnspsc190501.42272525': __('identifierSchemeCodeUnspsc190501.42272525'),
	'identifierSchemeCodeUnspsc190501.42272526': __('identifierSchemeCodeUnspsc190501.42272526'),
	'identifierSchemeCodeUnspsc190501.42272527': __('identifierSchemeCodeUnspsc190501.42272527'),
	'identifierSchemeCodeUnspsc190501.42272528': __('identifierSchemeCodeUnspsc190501.42272528'),
	'identifierSchemeCodeUnspsc190501.42272529': __('identifierSchemeCodeUnspsc190501.42272529'),
	'identifierSchemeCodeUnspsc190501.42272530': __('identifierSchemeCodeUnspsc190501.42272530'),
	'identifierSchemeCodeUnspsc190501.42272531': __('identifierSchemeCodeUnspsc190501.42272531'),
	'identifierSchemeCodeUnspsc190501.42272532': __('identifierSchemeCodeUnspsc190501.42272532'),
	'identifierSchemeCodeUnspsc190501.42272533': __('identifierSchemeCodeUnspsc190501.42272533'),
	'identifierSchemeCodeUnspsc190501.42272534': __('identifierSchemeCodeUnspsc190501.42272534'),
	'identifierSchemeCodeUnspsc190501.42272535': __('identifierSchemeCodeUnspsc190501.42272535'),
	'identifierSchemeCodeUnspsc190501.42272536': __('identifierSchemeCodeUnspsc190501.42272536'),
	'identifierSchemeCodeUnspsc190501.42280000': __('identifierSchemeCodeUnspsc190501.42280000'),
	'identifierSchemeCodeUnspsc190501.42281500': __('identifierSchemeCodeUnspsc190501.42281500'),
	'identifierSchemeCodeUnspsc190501.42281501': __('identifierSchemeCodeUnspsc190501.42281501'),
	'identifierSchemeCodeUnspsc190501.42281502': __('identifierSchemeCodeUnspsc190501.42281502'),
	'identifierSchemeCodeUnspsc190501.42281503': __('identifierSchemeCodeUnspsc190501.42281503'),
	'identifierSchemeCodeUnspsc190501.42281504': __('identifierSchemeCodeUnspsc190501.42281504'),
	'identifierSchemeCodeUnspsc190501.42281505': __('identifierSchemeCodeUnspsc190501.42281505'),
	'identifierSchemeCodeUnspsc190501.42281507': __('identifierSchemeCodeUnspsc190501.42281507'),
	'identifierSchemeCodeUnspsc190501.42281508': __('identifierSchemeCodeUnspsc190501.42281508'),
	'identifierSchemeCodeUnspsc190501.42281509': __('identifierSchemeCodeUnspsc190501.42281509'),
	'identifierSchemeCodeUnspsc190501.42281510': __('identifierSchemeCodeUnspsc190501.42281510'),
	'identifierSchemeCodeUnspsc190501.42281511': __('identifierSchemeCodeUnspsc190501.42281511'),
	'identifierSchemeCodeUnspsc190501.42281512': __('identifierSchemeCodeUnspsc190501.42281512'),
	'identifierSchemeCodeUnspsc190501.42281513': __('identifierSchemeCodeUnspsc190501.42281513'),
	'identifierSchemeCodeUnspsc190501.42281514': __('identifierSchemeCodeUnspsc190501.42281514'),
	'identifierSchemeCodeUnspsc190501.42281515': __('identifierSchemeCodeUnspsc190501.42281515'),
	'identifierSchemeCodeUnspsc190501.42281517': __('identifierSchemeCodeUnspsc190501.42281517'),
	'identifierSchemeCodeUnspsc190501.42281518': __('identifierSchemeCodeUnspsc190501.42281518'),
	'identifierSchemeCodeUnspsc190501.42281519': __('identifierSchemeCodeUnspsc190501.42281519'),
	'identifierSchemeCodeUnspsc190501.42281521': __('identifierSchemeCodeUnspsc190501.42281521'),
	'identifierSchemeCodeUnspsc190501.42281522': __('identifierSchemeCodeUnspsc190501.42281522'),
	'identifierSchemeCodeUnspsc190501.42281523': __('identifierSchemeCodeUnspsc190501.42281523'),
	'identifierSchemeCodeUnspsc190501.42281524': __('identifierSchemeCodeUnspsc190501.42281524'),
	'identifierSchemeCodeUnspsc190501.42281525': __('identifierSchemeCodeUnspsc190501.42281525'),
	'identifierSchemeCodeUnspsc190501.42281526': __('identifierSchemeCodeUnspsc190501.42281526'),
	'identifierSchemeCodeUnspsc190501.42281527': __('identifierSchemeCodeUnspsc190501.42281527'),
	'identifierSchemeCodeUnspsc190501.42281528': __('identifierSchemeCodeUnspsc190501.42281528'),
	'identifierSchemeCodeUnspsc190501.42281529': __('identifierSchemeCodeUnspsc190501.42281529'),
	'identifierSchemeCodeUnspsc190501.42281530': __('identifierSchemeCodeUnspsc190501.42281530'),
	'identifierSchemeCodeUnspsc190501.42281531': __('identifierSchemeCodeUnspsc190501.42281531'),
	'identifierSchemeCodeUnspsc190501.42281532': __('identifierSchemeCodeUnspsc190501.42281532'),
	'identifierSchemeCodeUnspsc190501.42281533': __('identifierSchemeCodeUnspsc190501.42281533'),
	'identifierSchemeCodeUnspsc190501.42281534': __('identifierSchemeCodeUnspsc190501.42281534'),
	'identifierSchemeCodeUnspsc190501.42281536': __('identifierSchemeCodeUnspsc190501.42281536'),
	'identifierSchemeCodeUnspsc190501.42281537': __('identifierSchemeCodeUnspsc190501.42281537'),
	'identifierSchemeCodeUnspsc190501.42281600': __('identifierSchemeCodeUnspsc190501.42281600'),
	'identifierSchemeCodeUnspsc190501.42281603': __('identifierSchemeCodeUnspsc190501.42281603'),
	'identifierSchemeCodeUnspsc190501.42281604': __('identifierSchemeCodeUnspsc190501.42281604'),
	'identifierSchemeCodeUnspsc190501.42281605': __('identifierSchemeCodeUnspsc190501.42281605'),
	'identifierSchemeCodeUnspsc190501.42281606': __('identifierSchemeCodeUnspsc190501.42281606'),
	'identifierSchemeCodeUnspsc190501.42281607': __('identifierSchemeCodeUnspsc190501.42281607'),
	'identifierSchemeCodeUnspsc190501.42281700': __('identifierSchemeCodeUnspsc190501.42281700'),
	'identifierSchemeCodeUnspsc190501.42281701': __('identifierSchemeCodeUnspsc190501.42281701'),
	'identifierSchemeCodeUnspsc190501.42281703': __('identifierSchemeCodeUnspsc190501.42281703'),
	'identifierSchemeCodeUnspsc190501.42281704': __('identifierSchemeCodeUnspsc190501.42281704'),
	'identifierSchemeCodeUnspsc190501.42281706': __('identifierSchemeCodeUnspsc190501.42281706'),
	'identifierSchemeCodeUnspsc190501.42281707': __('identifierSchemeCodeUnspsc190501.42281707'),
	'identifierSchemeCodeUnspsc190501.42281708': __('identifierSchemeCodeUnspsc190501.42281708'),
	'identifierSchemeCodeUnspsc190501.42281709': __('identifierSchemeCodeUnspsc190501.42281709'),
	'identifierSchemeCodeUnspsc190501.42281710': __('identifierSchemeCodeUnspsc190501.42281710'),
	'identifierSchemeCodeUnspsc190501.42281711': __('identifierSchemeCodeUnspsc190501.42281711'),
	'identifierSchemeCodeUnspsc190501.42281713': __('identifierSchemeCodeUnspsc190501.42281713'),
	'identifierSchemeCodeUnspsc190501.42281714': __('identifierSchemeCodeUnspsc190501.42281714'),
	'identifierSchemeCodeUnspsc190501.42281800': __('identifierSchemeCodeUnspsc190501.42281800'),
	'identifierSchemeCodeUnspsc190501.42281801': __('identifierSchemeCodeUnspsc190501.42281801'),
	'identifierSchemeCodeUnspsc190501.42281802': __('identifierSchemeCodeUnspsc190501.42281802'),
	'identifierSchemeCodeUnspsc190501.42281803': __('identifierSchemeCodeUnspsc190501.42281803'),
	'identifierSchemeCodeUnspsc190501.42281804': __('identifierSchemeCodeUnspsc190501.42281804'),
	'identifierSchemeCodeUnspsc190501.42281805': __('identifierSchemeCodeUnspsc190501.42281805'),
	'identifierSchemeCodeUnspsc190501.42281806': __('identifierSchemeCodeUnspsc190501.42281806'),
	'identifierSchemeCodeUnspsc190501.42281807': __('identifierSchemeCodeUnspsc190501.42281807'),
	'identifierSchemeCodeUnspsc190501.42281808': __('identifierSchemeCodeUnspsc190501.42281808'),
	'identifierSchemeCodeUnspsc190501.42281809': __('identifierSchemeCodeUnspsc190501.42281809'),
	'identifierSchemeCodeUnspsc190501.42281810': __('identifierSchemeCodeUnspsc190501.42281810'),
	'identifierSchemeCodeUnspsc190501.42281811': __('identifierSchemeCodeUnspsc190501.42281811'),
	'identifierSchemeCodeUnspsc190501.42281812': __('identifierSchemeCodeUnspsc190501.42281812'),
	'identifierSchemeCodeUnspsc190501.42281813': __('identifierSchemeCodeUnspsc190501.42281813'),
	'identifierSchemeCodeUnspsc190501.42281900': __('identifierSchemeCodeUnspsc190501.42281900'),
	'identifierSchemeCodeUnspsc190501.42281901': __('identifierSchemeCodeUnspsc190501.42281901'),
	'identifierSchemeCodeUnspsc190501.42281902': __('identifierSchemeCodeUnspsc190501.42281902'),
	'identifierSchemeCodeUnspsc190501.42281903': __('identifierSchemeCodeUnspsc190501.42281903'),
	'identifierSchemeCodeUnspsc190501.42281904': __('identifierSchemeCodeUnspsc190501.42281904'),
	'identifierSchemeCodeUnspsc190501.42281905': __('identifierSchemeCodeUnspsc190501.42281905'),
	'identifierSchemeCodeUnspsc190501.42281906': __('identifierSchemeCodeUnspsc190501.42281906'),
	'identifierSchemeCodeUnspsc190501.42281907': __('identifierSchemeCodeUnspsc190501.42281907'),
	'identifierSchemeCodeUnspsc190501.42281908': __('identifierSchemeCodeUnspsc190501.42281908'),
	'identifierSchemeCodeUnspsc190501.42281909': __('identifierSchemeCodeUnspsc190501.42281909'),
	'identifierSchemeCodeUnspsc190501.42281912': __('identifierSchemeCodeUnspsc190501.42281912'),
	'identifierSchemeCodeUnspsc190501.42281913': __('identifierSchemeCodeUnspsc190501.42281913'),
	'identifierSchemeCodeUnspsc190501.42281914': __('identifierSchemeCodeUnspsc190501.42281914'),
	'identifierSchemeCodeUnspsc190501.42281915': __('identifierSchemeCodeUnspsc190501.42281915'),
	'identifierSchemeCodeUnspsc190501.42281916': __('identifierSchemeCodeUnspsc190501.42281916'),
	'identifierSchemeCodeUnspsc190501.42281917': __('identifierSchemeCodeUnspsc190501.42281917'),
	'identifierSchemeCodeUnspsc190501.42282000': __('identifierSchemeCodeUnspsc190501.42282000'),
	'identifierSchemeCodeUnspsc190501.42282001': __('identifierSchemeCodeUnspsc190501.42282001'),
	'identifierSchemeCodeUnspsc190501.42282002': __('identifierSchemeCodeUnspsc190501.42282002'),
	'identifierSchemeCodeUnspsc190501.42282003': __('identifierSchemeCodeUnspsc190501.42282003'),
	'identifierSchemeCodeUnspsc190501.42282004': __('identifierSchemeCodeUnspsc190501.42282004'),
	'identifierSchemeCodeUnspsc190501.42282005': __('identifierSchemeCodeUnspsc190501.42282005'),
	'identifierSchemeCodeUnspsc190501.42282006': __('identifierSchemeCodeUnspsc190501.42282006'),
	'identifierSchemeCodeUnspsc190501.42282007': __('identifierSchemeCodeUnspsc190501.42282007'),
	'identifierSchemeCodeUnspsc190501.42282008': __('identifierSchemeCodeUnspsc190501.42282008'),
	'identifierSchemeCodeUnspsc190501.42282009': __('identifierSchemeCodeUnspsc190501.42282009'),
	'identifierSchemeCodeUnspsc190501.42282010': __('identifierSchemeCodeUnspsc190501.42282010'),
	'identifierSchemeCodeUnspsc190501.42282011': __('identifierSchemeCodeUnspsc190501.42282011'),
	'identifierSchemeCodeUnspsc190501.42282012': __('identifierSchemeCodeUnspsc190501.42282012'),
	'identifierSchemeCodeUnspsc190501.42282013': __('identifierSchemeCodeUnspsc190501.42282013'),
	'identifierSchemeCodeUnspsc190501.42282014': __('identifierSchemeCodeUnspsc190501.42282014'),
	'identifierSchemeCodeUnspsc190501.42282015': __('identifierSchemeCodeUnspsc190501.42282015'),
	'identifierSchemeCodeUnspsc190501.42282016': __('identifierSchemeCodeUnspsc190501.42282016'),
	'identifierSchemeCodeUnspsc190501.42282017': __('identifierSchemeCodeUnspsc190501.42282017'),
	'identifierSchemeCodeUnspsc190501.42282018': __('identifierSchemeCodeUnspsc190501.42282018'),
	'identifierSchemeCodeUnspsc190501.42282019': __('identifierSchemeCodeUnspsc190501.42282019'),
	'identifierSchemeCodeUnspsc190501.42282020': __('identifierSchemeCodeUnspsc190501.42282020'),
	'identifierSchemeCodeUnspsc190501.42290000': __('identifierSchemeCodeUnspsc190501.42290000'),
	'identifierSchemeCodeUnspsc190501.42291500': __('identifierSchemeCodeUnspsc190501.42291500'),
	'identifierSchemeCodeUnspsc190501.42291501': __('identifierSchemeCodeUnspsc190501.42291501'),
	'identifierSchemeCodeUnspsc190501.42291502': __('identifierSchemeCodeUnspsc190501.42291502'),
	'identifierSchemeCodeUnspsc190501.42291503': __('identifierSchemeCodeUnspsc190501.42291503'),
	'identifierSchemeCodeUnspsc190501.42291600': __('identifierSchemeCodeUnspsc190501.42291600'),
	'identifierSchemeCodeUnspsc190501.42291602': __('identifierSchemeCodeUnspsc190501.42291602'),
	'identifierSchemeCodeUnspsc190501.42291603': __('identifierSchemeCodeUnspsc190501.42291603'),
	'identifierSchemeCodeUnspsc190501.42291604': __('identifierSchemeCodeUnspsc190501.42291604'),
	'identifierSchemeCodeUnspsc190501.42291605': __('identifierSchemeCodeUnspsc190501.42291605'),
	'identifierSchemeCodeUnspsc190501.42291606': __('identifierSchemeCodeUnspsc190501.42291606'),
	'identifierSchemeCodeUnspsc190501.42291607': __('identifierSchemeCodeUnspsc190501.42291607'),
	'identifierSchemeCodeUnspsc190501.42291608': __('identifierSchemeCodeUnspsc190501.42291608'),
	'identifierSchemeCodeUnspsc190501.42291609': __('identifierSchemeCodeUnspsc190501.42291609'),
	'identifierSchemeCodeUnspsc190501.42291610': __('identifierSchemeCodeUnspsc190501.42291610'),
	'identifierSchemeCodeUnspsc190501.42291611': __('identifierSchemeCodeUnspsc190501.42291611'),
	'identifierSchemeCodeUnspsc190501.42291612': __('identifierSchemeCodeUnspsc190501.42291612'),
	'identifierSchemeCodeUnspsc190501.42291613': __('identifierSchemeCodeUnspsc190501.42291613'),
	'identifierSchemeCodeUnspsc190501.42291614': __('identifierSchemeCodeUnspsc190501.42291614'),
	'identifierSchemeCodeUnspsc190501.42291615': __('identifierSchemeCodeUnspsc190501.42291615'),
	'identifierSchemeCodeUnspsc190501.42291616': __('identifierSchemeCodeUnspsc190501.42291616'),
	'identifierSchemeCodeUnspsc190501.42291617': __('identifierSchemeCodeUnspsc190501.42291617'),
	'identifierSchemeCodeUnspsc190501.42291619': __('identifierSchemeCodeUnspsc190501.42291619'),
	'identifierSchemeCodeUnspsc190501.42291621': __('identifierSchemeCodeUnspsc190501.42291621'),
	'identifierSchemeCodeUnspsc190501.42291627': __('identifierSchemeCodeUnspsc190501.42291627'),
	'identifierSchemeCodeUnspsc190501.42291628': __('identifierSchemeCodeUnspsc190501.42291628'),
	'identifierSchemeCodeUnspsc190501.42291629': __('identifierSchemeCodeUnspsc190501.42291629'),
	'identifierSchemeCodeUnspsc190501.42291630': __('identifierSchemeCodeUnspsc190501.42291630'),
	'identifierSchemeCodeUnspsc190501.42291631': __('identifierSchemeCodeUnspsc190501.42291631'),
	'identifierSchemeCodeUnspsc190501.42291632': __('identifierSchemeCodeUnspsc190501.42291632'),
	'identifierSchemeCodeUnspsc190501.42291633': __('identifierSchemeCodeUnspsc190501.42291633'),
	'identifierSchemeCodeUnspsc190501.42291700': __('identifierSchemeCodeUnspsc190501.42291700'),
	'identifierSchemeCodeUnspsc190501.42291701': __('identifierSchemeCodeUnspsc190501.42291701'),
	'identifierSchemeCodeUnspsc190501.42291702': __('identifierSchemeCodeUnspsc190501.42291702'),
	'identifierSchemeCodeUnspsc190501.42291703': __('identifierSchemeCodeUnspsc190501.42291703'),
	'identifierSchemeCodeUnspsc190501.42291708': __('identifierSchemeCodeUnspsc190501.42291708'),
	'identifierSchemeCodeUnspsc190501.42291709': __('identifierSchemeCodeUnspsc190501.42291709'),
	'identifierSchemeCodeUnspsc190501.42291711': __('identifierSchemeCodeUnspsc190501.42291711'),
	'identifierSchemeCodeUnspsc190501.42291712': __('identifierSchemeCodeUnspsc190501.42291712'),
	'identifierSchemeCodeUnspsc190501.42291713': __('identifierSchemeCodeUnspsc190501.42291713'),
	'identifierSchemeCodeUnspsc190501.42291714': __('identifierSchemeCodeUnspsc190501.42291714'),
	'identifierSchemeCodeUnspsc190501.42291715': __('identifierSchemeCodeUnspsc190501.42291715'),
	'identifierSchemeCodeUnspsc190501.42291716': __('identifierSchemeCodeUnspsc190501.42291716'),
	'identifierSchemeCodeUnspsc190501.42291800': __('identifierSchemeCodeUnspsc190501.42291800'),
	'identifierSchemeCodeUnspsc190501.42291801': __('identifierSchemeCodeUnspsc190501.42291801'),
	'identifierSchemeCodeUnspsc190501.42291802': __('identifierSchemeCodeUnspsc190501.42291802'),
	'identifierSchemeCodeUnspsc190501.42291804': __('identifierSchemeCodeUnspsc190501.42291804'),
	'identifierSchemeCodeUnspsc190501.42291805': __('identifierSchemeCodeUnspsc190501.42291805'),
	'identifierSchemeCodeUnspsc190501.42291806': __('identifierSchemeCodeUnspsc190501.42291806'),
	'identifierSchemeCodeUnspsc190501.42291807': __('identifierSchemeCodeUnspsc190501.42291807'),
	'identifierSchemeCodeUnspsc190501.42291808': __('identifierSchemeCodeUnspsc190501.42291808'),
	'identifierSchemeCodeUnspsc190501.42291900': __('identifierSchemeCodeUnspsc190501.42291900'),
	'identifierSchemeCodeUnspsc190501.42291901': __('identifierSchemeCodeUnspsc190501.42291901'),
	'identifierSchemeCodeUnspsc190501.42291902': __('identifierSchemeCodeUnspsc190501.42291902'),
	'identifierSchemeCodeUnspsc190501.42292000': __('identifierSchemeCodeUnspsc190501.42292000'),
	'identifierSchemeCodeUnspsc190501.42292001': __('identifierSchemeCodeUnspsc190501.42292001'),
	'identifierSchemeCodeUnspsc190501.42292002': __('identifierSchemeCodeUnspsc190501.42292002'),
	'identifierSchemeCodeUnspsc190501.42292003': __('identifierSchemeCodeUnspsc190501.42292003'),
	'identifierSchemeCodeUnspsc190501.42292100': __('identifierSchemeCodeUnspsc190501.42292100'),
	'identifierSchemeCodeUnspsc190501.42292101': __('identifierSchemeCodeUnspsc190501.42292101'),
	'identifierSchemeCodeUnspsc190501.42292102': __('identifierSchemeCodeUnspsc190501.42292102'),
	'identifierSchemeCodeUnspsc190501.42292104': __('identifierSchemeCodeUnspsc190501.42292104'),
	'identifierSchemeCodeUnspsc190501.42292105': __('identifierSchemeCodeUnspsc190501.42292105'),
	'identifierSchemeCodeUnspsc190501.42292106': __('identifierSchemeCodeUnspsc190501.42292106'),
	'identifierSchemeCodeUnspsc190501.42292300': __('identifierSchemeCodeUnspsc190501.42292300'),
	'identifierSchemeCodeUnspsc190501.42292301': __('identifierSchemeCodeUnspsc190501.42292301'),
	'identifierSchemeCodeUnspsc190501.42292302': __('identifierSchemeCodeUnspsc190501.42292302'),
	'identifierSchemeCodeUnspsc190501.42292303': __('identifierSchemeCodeUnspsc190501.42292303'),
	'identifierSchemeCodeUnspsc190501.42292304': __('identifierSchemeCodeUnspsc190501.42292304'),
	'identifierSchemeCodeUnspsc190501.42292305': __('identifierSchemeCodeUnspsc190501.42292305'),
	'identifierSchemeCodeUnspsc190501.42292306': __('identifierSchemeCodeUnspsc190501.42292306'),
	'identifierSchemeCodeUnspsc190501.42292307': __('identifierSchemeCodeUnspsc190501.42292307'),
	'identifierSchemeCodeUnspsc190501.42292308': __('identifierSchemeCodeUnspsc190501.42292308'),
	'identifierSchemeCodeUnspsc190501.42292400': __('identifierSchemeCodeUnspsc190501.42292400'),
	'identifierSchemeCodeUnspsc190501.42292401': __('identifierSchemeCodeUnspsc190501.42292401'),
	'identifierSchemeCodeUnspsc190501.42292402': __('identifierSchemeCodeUnspsc190501.42292402'),
	'identifierSchemeCodeUnspsc190501.42292403': __('identifierSchemeCodeUnspsc190501.42292403'),
	'identifierSchemeCodeUnspsc190501.42292404': __('identifierSchemeCodeUnspsc190501.42292404'),
	'identifierSchemeCodeUnspsc190501.42292405': __('identifierSchemeCodeUnspsc190501.42292405'),
	'identifierSchemeCodeUnspsc190501.42292500': __('identifierSchemeCodeUnspsc190501.42292500'),
	'identifierSchemeCodeUnspsc190501.42292501': __('identifierSchemeCodeUnspsc190501.42292501'),
	'identifierSchemeCodeUnspsc190501.42292502': __('identifierSchemeCodeUnspsc190501.42292502'),
	'identifierSchemeCodeUnspsc190501.42292503': __('identifierSchemeCodeUnspsc190501.42292503'),
	'identifierSchemeCodeUnspsc190501.42292504': __('identifierSchemeCodeUnspsc190501.42292504'),
	'identifierSchemeCodeUnspsc190501.42292505': __('identifierSchemeCodeUnspsc190501.42292505'),
	'identifierSchemeCodeUnspsc190501.42292506': __('identifierSchemeCodeUnspsc190501.42292506'),
	'identifierSchemeCodeUnspsc190501.42292507': __('identifierSchemeCodeUnspsc190501.42292507'),
	'identifierSchemeCodeUnspsc190501.42292600': __('identifierSchemeCodeUnspsc190501.42292600'),
	'identifierSchemeCodeUnspsc190501.42292601': __('identifierSchemeCodeUnspsc190501.42292601'),
	'identifierSchemeCodeUnspsc190501.42292602': __('identifierSchemeCodeUnspsc190501.42292602'),
	'identifierSchemeCodeUnspsc190501.42292603': __('identifierSchemeCodeUnspsc190501.42292603'),
	'identifierSchemeCodeUnspsc190501.42292604': __('identifierSchemeCodeUnspsc190501.42292604'),
	'identifierSchemeCodeUnspsc190501.42292700': __('identifierSchemeCodeUnspsc190501.42292700'),
	'identifierSchemeCodeUnspsc190501.42292701': __('identifierSchemeCodeUnspsc190501.42292701'),
	'identifierSchemeCodeUnspsc190501.42292702': __('identifierSchemeCodeUnspsc190501.42292702'),
	'identifierSchemeCodeUnspsc190501.42292703': __('identifierSchemeCodeUnspsc190501.42292703'),
	'identifierSchemeCodeUnspsc190501.42292704': __('identifierSchemeCodeUnspsc190501.42292704'),
	'identifierSchemeCodeUnspsc190501.42292800': __('identifierSchemeCodeUnspsc190501.42292800'),
	'identifierSchemeCodeUnspsc190501.42292801': __('identifierSchemeCodeUnspsc190501.42292801'),
	'identifierSchemeCodeUnspsc190501.42292802': __('identifierSchemeCodeUnspsc190501.42292802'),
	'identifierSchemeCodeUnspsc190501.42292803': __('identifierSchemeCodeUnspsc190501.42292803'),
	'identifierSchemeCodeUnspsc190501.42292900': __('identifierSchemeCodeUnspsc190501.42292900'),
	'identifierSchemeCodeUnspsc190501.42292901': __('identifierSchemeCodeUnspsc190501.42292901'),
	'identifierSchemeCodeUnspsc190501.42292903': __('identifierSchemeCodeUnspsc190501.42292903'),
	'identifierSchemeCodeUnspsc190501.42292904': __('identifierSchemeCodeUnspsc190501.42292904'),
	'identifierSchemeCodeUnspsc190501.42292907': __('identifierSchemeCodeUnspsc190501.42292907'),
	'identifierSchemeCodeUnspsc190501.42292908': __('identifierSchemeCodeUnspsc190501.42292908'),
	'identifierSchemeCodeUnspsc190501.42292909': __('identifierSchemeCodeUnspsc190501.42292909'),
	'identifierSchemeCodeUnspsc190501.42292910': __('identifierSchemeCodeUnspsc190501.42292910'),
	'identifierSchemeCodeUnspsc190501.42292911': __('identifierSchemeCodeUnspsc190501.42292911'),
	'identifierSchemeCodeUnspsc190501.42292912': __('identifierSchemeCodeUnspsc190501.42292912'),
	'identifierSchemeCodeUnspsc190501.42292913': __('identifierSchemeCodeUnspsc190501.42292913'),
	'identifierSchemeCodeUnspsc190501.42292914': __('identifierSchemeCodeUnspsc190501.42292914'),
	'identifierSchemeCodeUnspsc190501.42293000': __('identifierSchemeCodeUnspsc190501.42293000'),
	'identifierSchemeCodeUnspsc190501.42293001': __('identifierSchemeCodeUnspsc190501.42293001'),
	'identifierSchemeCodeUnspsc190501.42293002': __('identifierSchemeCodeUnspsc190501.42293002'),
	'identifierSchemeCodeUnspsc190501.42293003': __('identifierSchemeCodeUnspsc190501.42293003'),
	'identifierSchemeCodeUnspsc190501.42293004': __('identifierSchemeCodeUnspsc190501.42293004'),
	'identifierSchemeCodeUnspsc190501.42293005': __('identifierSchemeCodeUnspsc190501.42293005'),
	'identifierSchemeCodeUnspsc190501.42293006': __('identifierSchemeCodeUnspsc190501.42293006'),
	'identifierSchemeCodeUnspsc190501.42293007': __('identifierSchemeCodeUnspsc190501.42293007'),
	'identifierSchemeCodeUnspsc190501.42293008': __('identifierSchemeCodeUnspsc190501.42293008'),
	'identifierSchemeCodeUnspsc190501.42293100': __('identifierSchemeCodeUnspsc190501.42293100'),
	'identifierSchemeCodeUnspsc190501.42293102': __('identifierSchemeCodeUnspsc190501.42293102'),
	'identifierSchemeCodeUnspsc190501.42293104': __('identifierSchemeCodeUnspsc190501.42293104'),
	'identifierSchemeCodeUnspsc190501.42293106': __('identifierSchemeCodeUnspsc190501.42293106'),
	'identifierSchemeCodeUnspsc190501.42293107': __('identifierSchemeCodeUnspsc190501.42293107'),
	'identifierSchemeCodeUnspsc190501.42293108': __('identifierSchemeCodeUnspsc190501.42293108'),
	'identifierSchemeCodeUnspsc190501.42293109': __('identifierSchemeCodeUnspsc190501.42293109'),
	'identifierSchemeCodeUnspsc190501.42293133': __('identifierSchemeCodeUnspsc190501.42293133'),
	'identifierSchemeCodeUnspsc190501.42293139': __('identifierSchemeCodeUnspsc190501.42293139'),
	'identifierSchemeCodeUnspsc190501.42293140': __('identifierSchemeCodeUnspsc190501.42293140'),
	'identifierSchemeCodeUnspsc190501.42293300': __('identifierSchemeCodeUnspsc190501.42293300'),
	'identifierSchemeCodeUnspsc190501.42293301': __('identifierSchemeCodeUnspsc190501.42293301'),
	'identifierSchemeCodeUnspsc190501.42293302': __('identifierSchemeCodeUnspsc190501.42293302'),
	'identifierSchemeCodeUnspsc190501.42293303': __('identifierSchemeCodeUnspsc190501.42293303'),
	'identifierSchemeCodeUnspsc190501.42293304': __('identifierSchemeCodeUnspsc190501.42293304'),
	'identifierSchemeCodeUnspsc190501.42293305': __('identifierSchemeCodeUnspsc190501.42293305'),
	'identifierSchemeCodeUnspsc190501.42293306': __('identifierSchemeCodeUnspsc190501.42293306'),
	'identifierSchemeCodeUnspsc190501.42293307': __('identifierSchemeCodeUnspsc190501.42293307'),
	'identifierSchemeCodeUnspsc190501.42293308': __('identifierSchemeCodeUnspsc190501.42293308'),
	'identifierSchemeCodeUnspsc190501.42293309': __('identifierSchemeCodeUnspsc190501.42293309'),
	'identifierSchemeCodeUnspsc190501.42293400': __('identifierSchemeCodeUnspsc190501.42293400'),
	'identifierSchemeCodeUnspsc190501.42293401': __('identifierSchemeCodeUnspsc190501.42293401'),
	'identifierSchemeCodeUnspsc190501.42293404': __('identifierSchemeCodeUnspsc190501.42293404'),
	'identifierSchemeCodeUnspsc190501.42293405': __('identifierSchemeCodeUnspsc190501.42293405'),
	'identifierSchemeCodeUnspsc190501.42293406': __('identifierSchemeCodeUnspsc190501.42293406'),
	'identifierSchemeCodeUnspsc190501.42293407': __('identifierSchemeCodeUnspsc190501.42293407'),
	'identifierSchemeCodeUnspsc190501.42293408': __('identifierSchemeCodeUnspsc190501.42293408'),
	'identifierSchemeCodeUnspsc190501.42293409': __('identifierSchemeCodeUnspsc190501.42293409'),
	'identifierSchemeCodeUnspsc190501.42293410': __('identifierSchemeCodeUnspsc190501.42293410'),
	'identifierSchemeCodeUnspsc190501.42293411': __('identifierSchemeCodeUnspsc190501.42293411'),
	'identifierSchemeCodeUnspsc190501.42293412': __('identifierSchemeCodeUnspsc190501.42293412'),
	'identifierSchemeCodeUnspsc190501.42293500': __('identifierSchemeCodeUnspsc190501.42293500'),
	'identifierSchemeCodeUnspsc190501.42293501': __('identifierSchemeCodeUnspsc190501.42293501'),
	'identifierSchemeCodeUnspsc190501.42293503': __('identifierSchemeCodeUnspsc190501.42293503'),
	'identifierSchemeCodeUnspsc190501.42293504': __('identifierSchemeCodeUnspsc190501.42293504'),
	'identifierSchemeCodeUnspsc190501.42293505': __('identifierSchemeCodeUnspsc190501.42293505'),
	'identifierSchemeCodeUnspsc190501.42293506': __('identifierSchemeCodeUnspsc190501.42293506'),
	'identifierSchemeCodeUnspsc190501.42293507': __('identifierSchemeCodeUnspsc190501.42293507'),
	'identifierSchemeCodeUnspsc190501.42293509': __('identifierSchemeCodeUnspsc190501.42293509'),
	'identifierSchemeCodeUnspsc190501.42293510': __('identifierSchemeCodeUnspsc190501.42293510'),
	'identifierSchemeCodeUnspsc190501.42293511': __('identifierSchemeCodeUnspsc190501.42293511'),
	'identifierSchemeCodeUnspsc190501.42293512': __('identifierSchemeCodeUnspsc190501.42293512'),
	'identifierSchemeCodeUnspsc190501.42293513': __('identifierSchemeCodeUnspsc190501.42293513'),
	'identifierSchemeCodeUnspsc190501.42293514': __('identifierSchemeCodeUnspsc190501.42293514'),
	'identifierSchemeCodeUnspsc190501.42293515': __('identifierSchemeCodeUnspsc190501.42293515'),
	'identifierSchemeCodeUnspsc190501.42293516': __('identifierSchemeCodeUnspsc190501.42293516'),
	'identifierSchemeCodeUnspsc190501.42293517': __('identifierSchemeCodeUnspsc190501.42293517'),
	'identifierSchemeCodeUnspsc190501.42293518': __('identifierSchemeCodeUnspsc190501.42293518'),
	'identifierSchemeCodeUnspsc190501.42293519': __('identifierSchemeCodeUnspsc190501.42293519'),
	'identifierSchemeCodeUnspsc190501.42293520': __('identifierSchemeCodeUnspsc190501.42293520'),
	'identifierSchemeCodeUnspsc190501.42293521': __('identifierSchemeCodeUnspsc190501.42293521'),
	'identifierSchemeCodeUnspsc190501.42293522': __('identifierSchemeCodeUnspsc190501.42293522'),
	'identifierSchemeCodeUnspsc190501.42293523': __('identifierSchemeCodeUnspsc190501.42293523'),
	'identifierSchemeCodeUnspsc190501.42293524': __('identifierSchemeCodeUnspsc190501.42293524'),
	'identifierSchemeCodeUnspsc190501.42293525': __('identifierSchemeCodeUnspsc190501.42293525'),
	'identifierSchemeCodeUnspsc190501.42293526': __('identifierSchemeCodeUnspsc190501.42293526'),
	'identifierSchemeCodeUnspsc190501.42293527': __('identifierSchemeCodeUnspsc190501.42293527'),
	'identifierSchemeCodeUnspsc190501.42293600': __('identifierSchemeCodeUnspsc190501.42293600'),
	'identifierSchemeCodeUnspsc190501.42293601': __('identifierSchemeCodeUnspsc190501.42293601'),
	'identifierSchemeCodeUnspsc190501.42293603': __('identifierSchemeCodeUnspsc190501.42293603'),
	'identifierSchemeCodeUnspsc190501.42293604': __('identifierSchemeCodeUnspsc190501.42293604'),
	'identifierSchemeCodeUnspsc190501.42293605': __('identifierSchemeCodeUnspsc190501.42293605'),
	'identifierSchemeCodeUnspsc190501.42293700': __('identifierSchemeCodeUnspsc190501.42293700'),
	'identifierSchemeCodeUnspsc190501.42293701': __('identifierSchemeCodeUnspsc190501.42293701'),
	'identifierSchemeCodeUnspsc190501.42293702': __('identifierSchemeCodeUnspsc190501.42293702'),
	'identifierSchemeCodeUnspsc190501.42293703': __('identifierSchemeCodeUnspsc190501.42293703'),
	'identifierSchemeCodeUnspsc190501.42293800': __('identifierSchemeCodeUnspsc190501.42293800'),
	'identifierSchemeCodeUnspsc190501.42293801': __('identifierSchemeCodeUnspsc190501.42293801'),
	'identifierSchemeCodeUnspsc190501.42293802': __('identifierSchemeCodeUnspsc190501.42293802'),
	'identifierSchemeCodeUnspsc190501.42293803': __('identifierSchemeCodeUnspsc190501.42293803'),
	'identifierSchemeCodeUnspsc190501.42293804': __('identifierSchemeCodeUnspsc190501.42293804'),
	'identifierSchemeCodeUnspsc190501.42293805': __('identifierSchemeCodeUnspsc190501.42293805'),
	'identifierSchemeCodeUnspsc190501.42293806': __('identifierSchemeCodeUnspsc190501.42293806'),
	'identifierSchemeCodeUnspsc190501.42293807': __('identifierSchemeCodeUnspsc190501.42293807'),
	'identifierSchemeCodeUnspsc190501.42293900': __('identifierSchemeCodeUnspsc190501.42293900'),
	'identifierSchemeCodeUnspsc190501.42293901': __('identifierSchemeCodeUnspsc190501.42293901'),
	'identifierSchemeCodeUnspsc190501.42293902': __('identifierSchemeCodeUnspsc190501.42293902'),
	'identifierSchemeCodeUnspsc190501.42294000': __('identifierSchemeCodeUnspsc190501.42294000'),
	'identifierSchemeCodeUnspsc190501.42294001': __('identifierSchemeCodeUnspsc190501.42294001'),
	'identifierSchemeCodeUnspsc190501.42294002': __('identifierSchemeCodeUnspsc190501.42294002'),
	'identifierSchemeCodeUnspsc190501.42294003': __('identifierSchemeCodeUnspsc190501.42294003'),
	'identifierSchemeCodeUnspsc190501.42294100': __('identifierSchemeCodeUnspsc190501.42294100'),
	'identifierSchemeCodeUnspsc190501.42294101': __('identifierSchemeCodeUnspsc190501.42294101'),
	'identifierSchemeCodeUnspsc190501.42294102': __('identifierSchemeCodeUnspsc190501.42294102'),
	'identifierSchemeCodeUnspsc190501.42294103': __('identifierSchemeCodeUnspsc190501.42294103'),
	'identifierSchemeCodeUnspsc190501.42294200': __('identifierSchemeCodeUnspsc190501.42294200'),
	'identifierSchemeCodeUnspsc190501.42294201': __('identifierSchemeCodeUnspsc190501.42294201'),
	'identifierSchemeCodeUnspsc190501.42294202': __('identifierSchemeCodeUnspsc190501.42294202'),
	'identifierSchemeCodeUnspsc190501.42294203': __('identifierSchemeCodeUnspsc190501.42294203'),
	'identifierSchemeCodeUnspsc190501.42294204': __('identifierSchemeCodeUnspsc190501.42294204'),
	'identifierSchemeCodeUnspsc190501.42294205': __('identifierSchemeCodeUnspsc190501.42294205'),
	'identifierSchemeCodeUnspsc190501.42294206': __('identifierSchemeCodeUnspsc190501.42294206'),
	'identifierSchemeCodeUnspsc190501.42294207': __('identifierSchemeCodeUnspsc190501.42294207'),
	'identifierSchemeCodeUnspsc190501.42294210': __('identifierSchemeCodeUnspsc190501.42294210'),
	'identifierSchemeCodeUnspsc190501.42294211': __('identifierSchemeCodeUnspsc190501.42294211'),
	'identifierSchemeCodeUnspsc190501.42294212': __('identifierSchemeCodeUnspsc190501.42294212'),
	'identifierSchemeCodeUnspsc190501.42294213': __('identifierSchemeCodeUnspsc190501.42294213'),
	'identifierSchemeCodeUnspsc190501.42294214': __('identifierSchemeCodeUnspsc190501.42294214'),
	'identifierSchemeCodeUnspsc190501.42294215': __('identifierSchemeCodeUnspsc190501.42294215'),
	'identifierSchemeCodeUnspsc190501.42294216': __('identifierSchemeCodeUnspsc190501.42294216'),
	'identifierSchemeCodeUnspsc190501.42294217': __('identifierSchemeCodeUnspsc190501.42294217'),
	'identifierSchemeCodeUnspsc190501.42294218': __('identifierSchemeCodeUnspsc190501.42294218'),
	'identifierSchemeCodeUnspsc190501.42294219': __('identifierSchemeCodeUnspsc190501.42294219'),
	'identifierSchemeCodeUnspsc190501.42294221': __('identifierSchemeCodeUnspsc190501.42294221'),
	'identifierSchemeCodeUnspsc190501.42294222': __('identifierSchemeCodeUnspsc190501.42294222'),
	'identifierSchemeCodeUnspsc190501.42294300': __('identifierSchemeCodeUnspsc190501.42294300'),
	'identifierSchemeCodeUnspsc190501.42294301': __('identifierSchemeCodeUnspsc190501.42294301'),
	'identifierSchemeCodeUnspsc190501.42294302': __('identifierSchemeCodeUnspsc190501.42294302'),
	'identifierSchemeCodeUnspsc190501.42294303': __('identifierSchemeCodeUnspsc190501.42294303'),
	'identifierSchemeCodeUnspsc190501.42294304': __('identifierSchemeCodeUnspsc190501.42294304'),
	'identifierSchemeCodeUnspsc190501.42294305': __('identifierSchemeCodeUnspsc190501.42294305'),
	'identifierSchemeCodeUnspsc190501.42294306': __('identifierSchemeCodeUnspsc190501.42294306'),
	'identifierSchemeCodeUnspsc190501.42294307': __('identifierSchemeCodeUnspsc190501.42294307'),
	'identifierSchemeCodeUnspsc190501.42294308': __('identifierSchemeCodeUnspsc190501.42294308'),
	'identifierSchemeCodeUnspsc190501.42294400': __('identifierSchemeCodeUnspsc190501.42294400'),
	'identifierSchemeCodeUnspsc190501.42294401': __('identifierSchemeCodeUnspsc190501.42294401'),
	'identifierSchemeCodeUnspsc190501.42294402': __('identifierSchemeCodeUnspsc190501.42294402'),
	'identifierSchemeCodeUnspsc190501.42294500': __('identifierSchemeCodeUnspsc190501.42294500'),
	'identifierSchemeCodeUnspsc190501.42294502': __('identifierSchemeCodeUnspsc190501.42294502'),
	'identifierSchemeCodeUnspsc190501.42294503': __('identifierSchemeCodeUnspsc190501.42294503'),
	'identifierSchemeCodeUnspsc190501.42294504': __('identifierSchemeCodeUnspsc190501.42294504'),
	'identifierSchemeCodeUnspsc190501.42294505': __('identifierSchemeCodeUnspsc190501.42294505'),
	'identifierSchemeCodeUnspsc190501.42294506': __('identifierSchemeCodeUnspsc190501.42294506'),
	'identifierSchemeCodeUnspsc190501.42294507': __('identifierSchemeCodeUnspsc190501.42294507'),
	'identifierSchemeCodeUnspsc190501.42294508': __('identifierSchemeCodeUnspsc190501.42294508'),
	'identifierSchemeCodeUnspsc190501.42294509': __('identifierSchemeCodeUnspsc190501.42294509'),
	'identifierSchemeCodeUnspsc190501.42294510': __('identifierSchemeCodeUnspsc190501.42294510'),
	'identifierSchemeCodeUnspsc190501.42294511': __('identifierSchemeCodeUnspsc190501.42294511'),
	'identifierSchemeCodeUnspsc190501.42294512': __('identifierSchemeCodeUnspsc190501.42294512'),
	'identifierSchemeCodeUnspsc190501.42294513': __('identifierSchemeCodeUnspsc190501.42294513'),
	'identifierSchemeCodeUnspsc190501.42294514': __('identifierSchemeCodeUnspsc190501.42294514'),
	'identifierSchemeCodeUnspsc190501.42294515': __('identifierSchemeCodeUnspsc190501.42294515'),
	'identifierSchemeCodeUnspsc190501.42294516': __('identifierSchemeCodeUnspsc190501.42294516'),
	'identifierSchemeCodeUnspsc190501.42294517': __('identifierSchemeCodeUnspsc190501.42294517'),
	'identifierSchemeCodeUnspsc190501.42294518': __('identifierSchemeCodeUnspsc190501.42294518'),
	'identifierSchemeCodeUnspsc190501.42294519': __('identifierSchemeCodeUnspsc190501.42294519'),
	'identifierSchemeCodeUnspsc190501.42294520': __('identifierSchemeCodeUnspsc190501.42294520'),
	'identifierSchemeCodeUnspsc190501.42294521': __('identifierSchemeCodeUnspsc190501.42294521'),
	'identifierSchemeCodeUnspsc190501.42294522': __('identifierSchemeCodeUnspsc190501.42294522'),
	'identifierSchemeCodeUnspsc190501.42294523': __('identifierSchemeCodeUnspsc190501.42294523'),
	'identifierSchemeCodeUnspsc190501.42294524': __('identifierSchemeCodeUnspsc190501.42294524'),
	'identifierSchemeCodeUnspsc190501.42294527': __('identifierSchemeCodeUnspsc190501.42294527'),
	'identifierSchemeCodeUnspsc190501.42294528': __('identifierSchemeCodeUnspsc190501.42294528'),
	'identifierSchemeCodeUnspsc190501.42294530': __('identifierSchemeCodeUnspsc190501.42294530'),
	'identifierSchemeCodeUnspsc190501.42294531': __('identifierSchemeCodeUnspsc190501.42294531'),
	'identifierSchemeCodeUnspsc190501.42294532': __('identifierSchemeCodeUnspsc190501.42294532'),
	'identifierSchemeCodeUnspsc190501.42294533': __('identifierSchemeCodeUnspsc190501.42294533'),
	'identifierSchemeCodeUnspsc190501.42294534': __('identifierSchemeCodeUnspsc190501.42294534'),
	'identifierSchemeCodeUnspsc190501.42294535': __('identifierSchemeCodeUnspsc190501.42294535'),
	'identifierSchemeCodeUnspsc190501.42294536': __('identifierSchemeCodeUnspsc190501.42294536'),
	'identifierSchemeCodeUnspsc190501.42294537': __('identifierSchemeCodeUnspsc190501.42294537'),
	'identifierSchemeCodeUnspsc190501.42294538': __('identifierSchemeCodeUnspsc190501.42294538'),
	'identifierSchemeCodeUnspsc190501.42294539': __('identifierSchemeCodeUnspsc190501.42294539'),
	'identifierSchemeCodeUnspsc190501.42294540': __('identifierSchemeCodeUnspsc190501.42294540'),
	'identifierSchemeCodeUnspsc190501.42294541': __('identifierSchemeCodeUnspsc190501.42294541'),
	'identifierSchemeCodeUnspsc190501.42294542': __('identifierSchemeCodeUnspsc190501.42294542'),
	'identifierSchemeCodeUnspsc190501.42294543': __('identifierSchemeCodeUnspsc190501.42294543'),
	'identifierSchemeCodeUnspsc190501.42294544': __('identifierSchemeCodeUnspsc190501.42294544'),
	'identifierSchemeCodeUnspsc190501.42294545': __('identifierSchemeCodeUnspsc190501.42294545'),
	'identifierSchemeCodeUnspsc190501.42294600': __('identifierSchemeCodeUnspsc190501.42294600'),
	'identifierSchemeCodeUnspsc190501.42294601': __('identifierSchemeCodeUnspsc190501.42294601'),
	'identifierSchemeCodeUnspsc190501.42294602': __('identifierSchemeCodeUnspsc190501.42294602'),
	'identifierSchemeCodeUnspsc190501.42294603': __('identifierSchemeCodeUnspsc190501.42294603'),
	'identifierSchemeCodeUnspsc190501.42294604': __('identifierSchemeCodeUnspsc190501.42294604'),
	'identifierSchemeCodeUnspsc190501.42294605': __('identifierSchemeCodeUnspsc190501.42294605'),
	'identifierSchemeCodeUnspsc190501.42294606': __('identifierSchemeCodeUnspsc190501.42294606'),
	'identifierSchemeCodeUnspsc190501.42294607': __('identifierSchemeCodeUnspsc190501.42294607'),
	'identifierSchemeCodeUnspsc190501.42294608': __('identifierSchemeCodeUnspsc190501.42294608'),
	'identifierSchemeCodeUnspsc190501.42294609': __('identifierSchemeCodeUnspsc190501.42294609'),
	'identifierSchemeCodeUnspsc190501.42294610': __('identifierSchemeCodeUnspsc190501.42294610'),
	'identifierSchemeCodeUnspsc190501.42294611': __('identifierSchemeCodeUnspsc190501.42294611'),
	'identifierSchemeCodeUnspsc190501.42294700': __('identifierSchemeCodeUnspsc190501.42294700'),
	'identifierSchemeCodeUnspsc190501.42294701': __('identifierSchemeCodeUnspsc190501.42294701'),
	'identifierSchemeCodeUnspsc190501.42294702': __('identifierSchemeCodeUnspsc190501.42294702'),
	'identifierSchemeCodeUnspsc190501.42294703': __('identifierSchemeCodeUnspsc190501.42294703'),
	'identifierSchemeCodeUnspsc190501.42294704': __('identifierSchemeCodeUnspsc190501.42294704'),
	'identifierSchemeCodeUnspsc190501.42294705': __('identifierSchemeCodeUnspsc190501.42294705'),
	'identifierSchemeCodeUnspsc190501.42294706': __('identifierSchemeCodeUnspsc190501.42294706'),
	'identifierSchemeCodeUnspsc190501.42294707': __('identifierSchemeCodeUnspsc190501.42294707'),
	'identifierSchemeCodeUnspsc190501.42294708': __('identifierSchemeCodeUnspsc190501.42294708'),
	'identifierSchemeCodeUnspsc190501.42294709': __('identifierSchemeCodeUnspsc190501.42294709'),
	'identifierSchemeCodeUnspsc190501.42294710': __('identifierSchemeCodeUnspsc190501.42294710'),
	'identifierSchemeCodeUnspsc190501.42294711': __('identifierSchemeCodeUnspsc190501.42294711'),
	'identifierSchemeCodeUnspsc190501.42294712': __('identifierSchemeCodeUnspsc190501.42294712'),
	'identifierSchemeCodeUnspsc190501.42294713': __('identifierSchemeCodeUnspsc190501.42294713'),
	'identifierSchemeCodeUnspsc190501.42294714': __('identifierSchemeCodeUnspsc190501.42294714'),
	'identifierSchemeCodeUnspsc190501.42294715': __('identifierSchemeCodeUnspsc190501.42294715'),
	'identifierSchemeCodeUnspsc190501.42294717': __('identifierSchemeCodeUnspsc190501.42294717'),
	'identifierSchemeCodeUnspsc190501.42294718': __('identifierSchemeCodeUnspsc190501.42294718'),
	'identifierSchemeCodeUnspsc190501.42294720': __('identifierSchemeCodeUnspsc190501.42294720'),
	'identifierSchemeCodeUnspsc190501.42294721': __('identifierSchemeCodeUnspsc190501.42294721'),
	'identifierSchemeCodeUnspsc190501.42294722': __('identifierSchemeCodeUnspsc190501.42294722'),
	'identifierSchemeCodeUnspsc190501.42294723': __('identifierSchemeCodeUnspsc190501.42294723'),
	'identifierSchemeCodeUnspsc190501.42294724': __('identifierSchemeCodeUnspsc190501.42294724'),
	'identifierSchemeCodeUnspsc190501.42294725': __('identifierSchemeCodeUnspsc190501.42294725'),
	'identifierSchemeCodeUnspsc190501.42294726': __('identifierSchemeCodeUnspsc190501.42294726'),
	'identifierSchemeCodeUnspsc190501.42294727': __('identifierSchemeCodeUnspsc190501.42294727'),
	'identifierSchemeCodeUnspsc190501.42294728': __('identifierSchemeCodeUnspsc190501.42294728'),
	'identifierSchemeCodeUnspsc190501.42294729': __('identifierSchemeCodeUnspsc190501.42294729'),
	'identifierSchemeCodeUnspsc190501.42294730': __('identifierSchemeCodeUnspsc190501.42294730'),
	'identifierSchemeCodeUnspsc190501.42294731': __('identifierSchemeCodeUnspsc190501.42294731'),
	'identifierSchemeCodeUnspsc190501.42294732': __('identifierSchemeCodeUnspsc190501.42294732'),
	'identifierSchemeCodeUnspsc190501.42294733': __('identifierSchemeCodeUnspsc190501.42294733'),
	'identifierSchemeCodeUnspsc190501.42294734': __('identifierSchemeCodeUnspsc190501.42294734'),
	'identifierSchemeCodeUnspsc190501.42294735': __('identifierSchemeCodeUnspsc190501.42294735'),
	'identifierSchemeCodeUnspsc190501.42294736': __('identifierSchemeCodeUnspsc190501.42294736'),
	'identifierSchemeCodeUnspsc190501.42294737': __('identifierSchemeCodeUnspsc190501.42294737'),
	'identifierSchemeCodeUnspsc190501.42294738': __('identifierSchemeCodeUnspsc190501.42294738'),
	'identifierSchemeCodeUnspsc190501.42294739': __('identifierSchemeCodeUnspsc190501.42294739'),
	'identifierSchemeCodeUnspsc190501.42294740': __('identifierSchemeCodeUnspsc190501.42294740'),
	'identifierSchemeCodeUnspsc190501.42294900': __('identifierSchemeCodeUnspsc190501.42294900'),
	'identifierSchemeCodeUnspsc190501.42294902': __('identifierSchemeCodeUnspsc190501.42294902'),
	'identifierSchemeCodeUnspsc190501.42294903': __('identifierSchemeCodeUnspsc190501.42294903'),
	'identifierSchemeCodeUnspsc190501.42294904': __('identifierSchemeCodeUnspsc190501.42294904'),
	'identifierSchemeCodeUnspsc190501.42294905': __('identifierSchemeCodeUnspsc190501.42294905'),
	'identifierSchemeCodeUnspsc190501.42294906': __('identifierSchemeCodeUnspsc190501.42294906'),
	'identifierSchemeCodeUnspsc190501.42294907': __('identifierSchemeCodeUnspsc190501.42294907'),
	'identifierSchemeCodeUnspsc190501.42294908': __('identifierSchemeCodeUnspsc190501.42294908'),
	'identifierSchemeCodeUnspsc190501.42294909': __('identifierSchemeCodeUnspsc190501.42294909'),
	'identifierSchemeCodeUnspsc190501.42294910': __('identifierSchemeCodeUnspsc190501.42294910'),
	'identifierSchemeCodeUnspsc190501.42294913': __('identifierSchemeCodeUnspsc190501.42294913'),
	'identifierSchemeCodeUnspsc190501.42294914': __('identifierSchemeCodeUnspsc190501.42294914'),
	'identifierSchemeCodeUnspsc190501.42294915': __('identifierSchemeCodeUnspsc190501.42294915'),
	'identifierSchemeCodeUnspsc190501.42294917': __('identifierSchemeCodeUnspsc190501.42294917'),
	'identifierSchemeCodeUnspsc190501.42294919': __('identifierSchemeCodeUnspsc190501.42294919'),
	'identifierSchemeCodeUnspsc190501.42294920': __('identifierSchemeCodeUnspsc190501.42294920'),
	'identifierSchemeCodeUnspsc190501.42294921': __('identifierSchemeCodeUnspsc190501.42294921'),
	'identifierSchemeCodeUnspsc190501.42294922': __('identifierSchemeCodeUnspsc190501.42294922'),
	'identifierSchemeCodeUnspsc190501.42294923': __('identifierSchemeCodeUnspsc190501.42294923'),
	'identifierSchemeCodeUnspsc190501.42294924': __('identifierSchemeCodeUnspsc190501.42294924'),
	'identifierSchemeCodeUnspsc190501.42294925': __('identifierSchemeCodeUnspsc190501.42294925'),
	'identifierSchemeCodeUnspsc190501.42294926': __('identifierSchemeCodeUnspsc190501.42294926'),
	'identifierSchemeCodeUnspsc190501.42294927': __('identifierSchemeCodeUnspsc190501.42294927'),
	'identifierSchemeCodeUnspsc190501.42294928': __('identifierSchemeCodeUnspsc190501.42294928'),
	'identifierSchemeCodeUnspsc190501.42294929': __('identifierSchemeCodeUnspsc190501.42294929'),
	'identifierSchemeCodeUnspsc190501.42294930': __('identifierSchemeCodeUnspsc190501.42294930'),
	'identifierSchemeCodeUnspsc190501.42294931': __('identifierSchemeCodeUnspsc190501.42294931'),
	'identifierSchemeCodeUnspsc190501.42294933': __('identifierSchemeCodeUnspsc190501.42294933'),
	'identifierSchemeCodeUnspsc190501.42294934': __('identifierSchemeCodeUnspsc190501.42294934'),
	'identifierSchemeCodeUnspsc190501.42294936': __('identifierSchemeCodeUnspsc190501.42294936'),
	'identifierSchemeCodeUnspsc190501.42294937': __('identifierSchemeCodeUnspsc190501.42294937'),
	'identifierSchemeCodeUnspsc190501.42294938': __('identifierSchemeCodeUnspsc190501.42294938'),
	'identifierSchemeCodeUnspsc190501.42294939': __('identifierSchemeCodeUnspsc190501.42294939'),
	'identifierSchemeCodeUnspsc190501.42294941': __('identifierSchemeCodeUnspsc190501.42294941'),
	'identifierSchemeCodeUnspsc190501.42294942': __('identifierSchemeCodeUnspsc190501.42294942'),
	'identifierSchemeCodeUnspsc190501.42294943': __('identifierSchemeCodeUnspsc190501.42294943'),
	'identifierSchemeCodeUnspsc190501.42294944': __('identifierSchemeCodeUnspsc190501.42294944'),
	'identifierSchemeCodeUnspsc190501.42294946': __('identifierSchemeCodeUnspsc190501.42294946'),
	'identifierSchemeCodeUnspsc190501.42294948': __('identifierSchemeCodeUnspsc190501.42294948'),
	'identifierSchemeCodeUnspsc190501.42294949': __('identifierSchemeCodeUnspsc190501.42294949'),
	'identifierSchemeCodeUnspsc190501.42294950': __('identifierSchemeCodeUnspsc190501.42294950'),
	'identifierSchemeCodeUnspsc190501.42294951': __('identifierSchemeCodeUnspsc190501.42294951'),
	'identifierSchemeCodeUnspsc190501.42294952': __('identifierSchemeCodeUnspsc190501.42294952'),
	'identifierSchemeCodeUnspsc190501.42294953': __('identifierSchemeCodeUnspsc190501.42294953'),
	'identifierSchemeCodeUnspsc190501.42294954': __('identifierSchemeCodeUnspsc190501.42294954'),
	'identifierSchemeCodeUnspsc190501.42294955': __('identifierSchemeCodeUnspsc190501.42294955'),
	'identifierSchemeCodeUnspsc190501.42294956': __('identifierSchemeCodeUnspsc190501.42294956'),
	'identifierSchemeCodeUnspsc190501.42294957': __('identifierSchemeCodeUnspsc190501.42294957'),
	'identifierSchemeCodeUnspsc190501.42294958': __('identifierSchemeCodeUnspsc190501.42294958'),
	'identifierSchemeCodeUnspsc190501.42294959': __('identifierSchemeCodeUnspsc190501.42294959'),
	'identifierSchemeCodeUnspsc190501.42294960': __('identifierSchemeCodeUnspsc190501.42294960'),
	'identifierSchemeCodeUnspsc190501.42294961': __('identifierSchemeCodeUnspsc190501.42294961'),
	'identifierSchemeCodeUnspsc190501.42294962': __('identifierSchemeCodeUnspsc190501.42294962'),
	'identifierSchemeCodeUnspsc190501.42294963': __('identifierSchemeCodeUnspsc190501.42294963'),
	'identifierSchemeCodeUnspsc190501.42294964': __('identifierSchemeCodeUnspsc190501.42294964'),
	'identifierSchemeCodeUnspsc190501.42294965': __('identifierSchemeCodeUnspsc190501.42294965'),
	'identifierSchemeCodeUnspsc190501.42294966': __('identifierSchemeCodeUnspsc190501.42294966'),
	'identifierSchemeCodeUnspsc190501.42294967': __('identifierSchemeCodeUnspsc190501.42294967'),
	'identifierSchemeCodeUnspsc190501.42294968': __('identifierSchemeCodeUnspsc190501.42294968'),
	'identifierSchemeCodeUnspsc190501.42294969': __('identifierSchemeCodeUnspsc190501.42294969'),
	'identifierSchemeCodeUnspsc190501.42294970': __('identifierSchemeCodeUnspsc190501.42294970'),
	'identifierSchemeCodeUnspsc190501.42294971': __('identifierSchemeCodeUnspsc190501.42294971'),
	'identifierSchemeCodeUnspsc190501.42294972': __('identifierSchemeCodeUnspsc190501.42294972'),
	'identifierSchemeCodeUnspsc190501.42294978': __('identifierSchemeCodeUnspsc190501.42294978'),
	'identifierSchemeCodeUnspsc190501.42294979': __('identifierSchemeCodeUnspsc190501.42294979'),
	'identifierSchemeCodeUnspsc190501.42294980': __('identifierSchemeCodeUnspsc190501.42294980'),
	'identifierSchemeCodeUnspsc190501.42294981': __('identifierSchemeCodeUnspsc190501.42294981'),
	'identifierSchemeCodeUnspsc190501.42294982': __('identifierSchemeCodeUnspsc190501.42294982'),
	'identifierSchemeCodeUnspsc190501.42294983': __('identifierSchemeCodeUnspsc190501.42294983'),
	'identifierSchemeCodeUnspsc190501.42294984': __('identifierSchemeCodeUnspsc190501.42294984'),
	'identifierSchemeCodeUnspsc190501.42294985': __('identifierSchemeCodeUnspsc190501.42294985'),
	'identifierSchemeCodeUnspsc190501.42294986': __('identifierSchemeCodeUnspsc190501.42294986'),
	'identifierSchemeCodeUnspsc190501.42294987': __('identifierSchemeCodeUnspsc190501.42294987'),
	'identifierSchemeCodeUnspsc190501.42294988': __('identifierSchemeCodeUnspsc190501.42294988'),
	'identifierSchemeCodeUnspsc190501.42294989': __('identifierSchemeCodeUnspsc190501.42294989'),
	'identifierSchemeCodeUnspsc190501.42294990': __('identifierSchemeCodeUnspsc190501.42294990'),
	'identifierSchemeCodeUnspsc190501.42294991': __('identifierSchemeCodeUnspsc190501.42294991'),
	'identifierSchemeCodeUnspsc190501.42295000': __('identifierSchemeCodeUnspsc190501.42295000'),
	'identifierSchemeCodeUnspsc190501.42295001': __('identifierSchemeCodeUnspsc190501.42295001'),
	'identifierSchemeCodeUnspsc190501.42295002': __('identifierSchemeCodeUnspsc190501.42295002'),
	'identifierSchemeCodeUnspsc190501.42295003': __('identifierSchemeCodeUnspsc190501.42295003'),
	'identifierSchemeCodeUnspsc190501.42295004': __('identifierSchemeCodeUnspsc190501.42295004'),
	'identifierSchemeCodeUnspsc190501.42295005': __('identifierSchemeCodeUnspsc190501.42295005'),
	'identifierSchemeCodeUnspsc190501.42295006': __('identifierSchemeCodeUnspsc190501.42295006'),
	'identifierSchemeCodeUnspsc190501.42295013': __('identifierSchemeCodeUnspsc190501.42295013'),
	'identifierSchemeCodeUnspsc190501.42295014': __('identifierSchemeCodeUnspsc190501.42295014'),
	'identifierSchemeCodeUnspsc190501.42295015': __('identifierSchemeCodeUnspsc190501.42295015'),
	'identifierSchemeCodeUnspsc190501.42295016': __('identifierSchemeCodeUnspsc190501.42295016'),
	'identifierSchemeCodeUnspsc190501.42295017': __('identifierSchemeCodeUnspsc190501.42295017'),
	'identifierSchemeCodeUnspsc190501.42295018': __('identifierSchemeCodeUnspsc190501.42295018'),
	'identifierSchemeCodeUnspsc190501.42295019': __('identifierSchemeCodeUnspsc190501.42295019'),
	'identifierSchemeCodeUnspsc190501.42295020': __('identifierSchemeCodeUnspsc190501.42295020'),
	'identifierSchemeCodeUnspsc190501.42295021': __('identifierSchemeCodeUnspsc190501.42295021'),
	'identifierSchemeCodeUnspsc190501.42295100': __('identifierSchemeCodeUnspsc190501.42295100'),
	'identifierSchemeCodeUnspsc190501.42295101': __('identifierSchemeCodeUnspsc190501.42295101'),
	'identifierSchemeCodeUnspsc190501.42295102': __('identifierSchemeCodeUnspsc190501.42295102'),
	'identifierSchemeCodeUnspsc190501.42295103': __('identifierSchemeCodeUnspsc190501.42295103'),
	'identifierSchemeCodeUnspsc190501.42295104': __('identifierSchemeCodeUnspsc190501.42295104'),
	'identifierSchemeCodeUnspsc190501.42295105': __('identifierSchemeCodeUnspsc190501.42295105'),
	'identifierSchemeCodeUnspsc190501.42295106': __('identifierSchemeCodeUnspsc190501.42295106'),
	'identifierSchemeCodeUnspsc190501.42295107': __('identifierSchemeCodeUnspsc190501.42295107'),
	'identifierSchemeCodeUnspsc190501.42295108': __('identifierSchemeCodeUnspsc190501.42295108'),
	'identifierSchemeCodeUnspsc190501.42295109': __('identifierSchemeCodeUnspsc190501.42295109'),
	'identifierSchemeCodeUnspsc190501.42295111': __('identifierSchemeCodeUnspsc190501.42295111'),
	'identifierSchemeCodeUnspsc190501.42295112': __('identifierSchemeCodeUnspsc190501.42295112'),
	'identifierSchemeCodeUnspsc190501.42295114': __('identifierSchemeCodeUnspsc190501.42295114'),
	'identifierSchemeCodeUnspsc190501.42295116': __('identifierSchemeCodeUnspsc190501.42295116'),
	'identifierSchemeCodeUnspsc190501.42295119': __('identifierSchemeCodeUnspsc190501.42295119'),
	'identifierSchemeCodeUnspsc190501.42295120': __('identifierSchemeCodeUnspsc190501.42295120'),
	'identifierSchemeCodeUnspsc190501.42295121': __('identifierSchemeCodeUnspsc190501.42295121'),
	'identifierSchemeCodeUnspsc190501.42295122': __('identifierSchemeCodeUnspsc190501.42295122'),
	'identifierSchemeCodeUnspsc190501.42295123': __('identifierSchemeCodeUnspsc190501.42295123'),
	'identifierSchemeCodeUnspsc190501.42295124': __('identifierSchemeCodeUnspsc190501.42295124'),
	'identifierSchemeCodeUnspsc190501.42295125': __('identifierSchemeCodeUnspsc190501.42295125'),
	'identifierSchemeCodeUnspsc190501.42295129': __('identifierSchemeCodeUnspsc190501.42295129'),
	'identifierSchemeCodeUnspsc190501.42295131': __('identifierSchemeCodeUnspsc190501.42295131'),
	'identifierSchemeCodeUnspsc190501.42295134': __('identifierSchemeCodeUnspsc190501.42295134'),
	'identifierSchemeCodeUnspsc190501.42295137': __('identifierSchemeCodeUnspsc190501.42295137'),
	'identifierSchemeCodeUnspsc190501.42295141': __('identifierSchemeCodeUnspsc190501.42295141'),
	'identifierSchemeCodeUnspsc190501.42295142': __('identifierSchemeCodeUnspsc190501.42295142'),
	'identifierSchemeCodeUnspsc190501.42295143': __('identifierSchemeCodeUnspsc190501.42295143'),
	'identifierSchemeCodeUnspsc190501.42295144': __('identifierSchemeCodeUnspsc190501.42295144'),
	'identifierSchemeCodeUnspsc190501.42295148': __('identifierSchemeCodeUnspsc190501.42295148'),
	'identifierSchemeCodeUnspsc190501.42295149': __('identifierSchemeCodeUnspsc190501.42295149'),
	'identifierSchemeCodeUnspsc190501.42295150': __('identifierSchemeCodeUnspsc190501.42295150'),
	'identifierSchemeCodeUnspsc190501.42295151': __('identifierSchemeCodeUnspsc190501.42295151'),
	'identifierSchemeCodeUnspsc190501.42295152': __('identifierSchemeCodeUnspsc190501.42295152'),
	'identifierSchemeCodeUnspsc190501.42295153': __('identifierSchemeCodeUnspsc190501.42295153'),
	'identifierSchemeCodeUnspsc190501.42295154': __('identifierSchemeCodeUnspsc190501.42295154'),
	'identifierSchemeCodeUnspsc190501.42295155': __('identifierSchemeCodeUnspsc190501.42295155'),
	'identifierSchemeCodeUnspsc190501.42295156': __('identifierSchemeCodeUnspsc190501.42295156'),
	'identifierSchemeCodeUnspsc190501.42295157': __('identifierSchemeCodeUnspsc190501.42295157'),
	'identifierSchemeCodeUnspsc190501.42295158': __('identifierSchemeCodeUnspsc190501.42295158'),
	'identifierSchemeCodeUnspsc190501.42295159': __('identifierSchemeCodeUnspsc190501.42295159'),
	'identifierSchemeCodeUnspsc190501.42295160': __('identifierSchemeCodeUnspsc190501.42295160'),
	'identifierSchemeCodeUnspsc190501.42295161': __('identifierSchemeCodeUnspsc190501.42295161'),
	'identifierSchemeCodeUnspsc190501.42295162': __('identifierSchemeCodeUnspsc190501.42295162'),
	'identifierSchemeCodeUnspsc190501.42295163': __('identifierSchemeCodeUnspsc190501.42295163'),
	'identifierSchemeCodeUnspsc190501.42295164': __('identifierSchemeCodeUnspsc190501.42295164'),
	'identifierSchemeCodeUnspsc190501.42295165': __('identifierSchemeCodeUnspsc190501.42295165'),
	'identifierSchemeCodeUnspsc190501.42295166': __('identifierSchemeCodeUnspsc190501.42295166'),
	'identifierSchemeCodeUnspsc190501.42295167': __('identifierSchemeCodeUnspsc190501.42295167'),
	'identifierSchemeCodeUnspsc190501.42295168': __('identifierSchemeCodeUnspsc190501.42295168'),
	'identifierSchemeCodeUnspsc190501.42295169': __('identifierSchemeCodeUnspsc190501.42295169'),
	'identifierSchemeCodeUnspsc190501.42295170': __('identifierSchemeCodeUnspsc190501.42295170'),
	'identifierSchemeCodeUnspsc190501.42295171': __('identifierSchemeCodeUnspsc190501.42295171'),
	'identifierSchemeCodeUnspsc190501.42295172': __('identifierSchemeCodeUnspsc190501.42295172'),
	'identifierSchemeCodeUnspsc190501.42295173': __('identifierSchemeCodeUnspsc190501.42295173'),
	'identifierSchemeCodeUnspsc190501.42295174': __('identifierSchemeCodeUnspsc190501.42295174'),
	'identifierSchemeCodeUnspsc190501.42295200': __('identifierSchemeCodeUnspsc190501.42295200'),
	'identifierSchemeCodeUnspsc190501.42295201': __('identifierSchemeCodeUnspsc190501.42295201'),
	'identifierSchemeCodeUnspsc190501.42295202': __('identifierSchemeCodeUnspsc190501.42295202'),
	'identifierSchemeCodeUnspsc190501.42295203': __('identifierSchemeCodeUnspsc190501.42295203'),
	'identifierSchemeCodeUnspsc190501.42295204': __('identifierSchemeCodeUnspsc190501.42295204'),
	'identifierSchemeCodeUnspsc190501.42295205': __('identifierSchemeCodeUnspsc190501.42295205'),
	'identifierSchemeCodeUnspsc190501.42295207': __('identifierSchemeCodeUnspsc190501.42295207'),
	'identifierSchemeCodeUnspsc190501.42295208': __('identifierSchemeCodeUnspsc190501.42295208'),
	'identifierSchemeCodeUnspsc190501.42295209': __('identifierSchemeCodeUnspsc190501.42295209'),
	'identifierSchemeCodeUnspsc190501.42295210': __('identifierSchemeCodeUnspsc190501.42295210'),
	'identifierSchemeCodeUnspsc190501.42295211': __('identifierSchemeCodeUnspsc190501.42295211'),
	'identifierSchemeCodeUnspsc190501.42295212': __('identifierSchemeCodeUnspsc190501.42295212'),
	'identifierSchemeCodeUnspsc190501.42295213': __('identifierSchemeCodeUnspsc190501.42295213'),
	'identifierSchemeCodeUnspsc190501.42295214': __('identifierSchemeCodeUnspsc190501.42295214'),
	'identifierSchemeCodeUnspsc190501.42295215': __('identifierSchemeCodeUnspsc190501.42295215'),
	'identifierSchemeCodeUnspsc190501.42295216': __('identifierSchemeCodeUnspsc190501.42295216'),
	'identifierSchemeCodeUnspsc190501.42295300': __('identifierSchemeCodeUnspsc190501.42295300'),
	'identifierSchemeCodeUnspsc190501.42295301': __('identifierSchemeCodeUnspsc190501.42295301'),
	'identifierSchemeCodeUnspsc190501.42295302': __('identifierSchemeCodeUnspsc190501.42295302'),
	'identifierSchemeCodeUnspsc190501.42295304': __('identifierSchemeCodeUnspsc190501.42295304'),
	'identifierSchemeCodeUnspsc190501.42295305': __('identifierSchemeCodeUnspsc190501.42295305'),
	'identifierSchemeCodeUnspsc190501.42295306': __('identifierSchemeCodeUnspsc190501.42295306'),
	'identifierSchemeCodeUnspsc190501.42295308': __('identifierSchemeCodeUnspsc190501.42295308'),
	'identifierSchemeCodeUnspsc190501.42295309': __('identifierSchemeCodeUnspsc190501.42295309'),
	'identifierSchemeCodeUnspsc190501.42295310': __('identifierSchemeCodeUnspsc190501.42295310'),
	'identifierSchemeCodeUnspsc190501.42295311': __('identifierSchemeCodeUnspsc190501.42295311'),
	'identifierSchemeCodeUnspsc190501.42295312': __('identifierSchemeCodeUnspsc190501.42295312'),
	'identifierSchemeCodeUnspsc190501.42295313': __('identifierSchemeCodeUnspsc190501.42295313'),
	'identifierSchemeCodeUnspsc190501.42295400': __('identifierSchemeCodeUnspsc190501.42295400'),
	'identifierSchemeCodeUnspsc190501.42295401': __('identifierSchemeCodeUnspsc190501.42295401'),
	'identifierSchemeCodeUnspsc190501.42295402': __('identifierSchemeCodeUnspsc190501.42295402'),
	'identifierSchemeCodeUnspsc190501.42295406': __('identifierSchemeCodeUnspsc190501.42295406'),
	'identifierSchemeCodeUnspsc190501.42295408': __('identifierSchemeCodeUnspsc190501.42295408'),
	'identifierSchemeCodeUnspsc190501.42295409': __('identifierSchemeCodeUnspsc190501.42295409'),
	'identifierSchemeCodeUnspsc190501.42295410': __('identifierSchemeCodeUnspsc190501.42295410'),
	'identifierSchemeCodeUnspsc190501.42295411': __('identifierSchemeCodeUnspsc190501.42295411'),
	'identifierSchemeCodeUnspsc190501.42295413': __('identifierSchemeCodeUnspsc190501.42295413'),
	'identifierSchemeCodeUnspsc190501.42295414': __('identifierSchemeCodeUnspsc190501.42295414'),
	'identifierSchemeCodeUnspsc190501.42295415': __('identifierSchemeCodeUnspsc190501.42295415'),
	'identifierSchemeCodeUnspsc190501.42295417': __('identifierSchemeCodeUnspsc190501.42295417'),
	'identifierSchemeCodeUnspsc190501.42295418': __('identifierSchemeCodeUnspsc190501.42295418'),
	'identifierSchemeCodeUnspsc190501.42295419': __('identifierSchemeCodeUnspsc190501.42295419'),
	'identifierSchemeCodeUnspsc190501.42295421': __('identifierSchemeCodeUnspsc190501.42295421'),
	'identifierSchemeCodeUnspsc190501.42295422': __('identifierSchemeCodeUnspsc190501.42295422'),
	'identifierSchemeCodeUnspsc190501.42295423': __('identifierSchemeCodeUnspsc190501.42295423'),
	'identifierSchemeCodeUnspsc190501.42295424': __('identifierSchemeCodeUnspsc190501.42295424'),
	'identifierSchemeCodeUnspsc190501.42295426': __('identifierSchemeCodeUnspsc190501.42295426'),
	'identifierSchemeCodeUnspsc190501.42295427': __('identifierSchemeCodeUnspsc190501.42295427'),
	'identifierSchemeCodeUnspsc190501.42295435': __('identifierSchemeCodeUnspsc190501.42295435'),
	'identifierSchemeCodeUnspsc190501.42295440': __('identifierSchemeCodeUnspsc190501.42295440'),
	'identifierSchemeCodeUnspsc190501.42295446': __('identifierSchemeCodeUnspsc190501.42295446'),
	'identifierSchemeCodeUnspsc190501.42295448': __('identifierSchemeCodeUnspsc190501.42295448'),
	'identifierSchemeCodeUnspsc190501.42295451': __('identifierSchemeCodeUnspsc190501.42295451'),
	'identifierSchemeCodeUnspsc190501.42295452': __('identifierSchemeCodeUnspsc190501.42295452'),
	'identifierSchemeCodeUnspsc190501.42295453': __('identifierSchemeCodeUnspsc190501.42295453'),
	'identifierSchemeCodeUnspsc190501.42295454': __('identifierSchemeCodeUnspsc190501.42295454'),
	'identifierSchemeCodeUnspsc190501.42295456': __('identifierSchemeCodeUnspsc190501.42295456'),
	'identifierSchemeCodeUnspsc190501.42295458': __('identifierSchemeCodeUnspsc190501.42295458'),
	'identifierSchemeCodeUnspsc190501.42295459': __('identifierSchemeCodeUnspsc190501.42295459'),
	'identifierSchemeCodeUnspsc190501.42295460': __('identifierSchemeCodeUnspsc190501.42295460'),
	'identifierSchemeCodeUnspsc190501.42295461': __('identifierSchemeCodeUnspsc190501.42295461'),
	'identifierSchemeCodeUnspsc190501.42295462': __('identifierSchemeCodeUnspsc190501.42295462'),
	'identifierSchemeCodeUnspsc190501.42295464': __('identifierSchemeCodeUnspsc190501.42295464'),
	'identifierSchemeCodeUnspsc190501.42295465': __('identifierSchemeCodeUnspsc190501.42295465'),
	'identifierSchemeCodeUnspsc190501.42295470': __('identifierSchemeCodeUnspsc190501.42295470'),
	'identifierSchemeCodeUnspsc190501.42295471': __('identifierSchemeCodeUnspsc190501.42295471'),
	'identifierSchemeCodeUnspsc190501.42295472': __('identifierSchemeCodeUnspsc190501.42295472'),
	'identifierSchemeCodeUnspsc190501.42295473': __('identifierSchemeCodeUnspsc190501.42295473'),
	'identifierSchemeCodeUnspsc190501.42295474': __('identifierSchemeCodeUnspsc190501.42295474'),
	'identifierSchemeCodeUnspsc190501.42295475': __('identifierSchemeCodeUnspsc190501.42295475'),
	'identifierSchemeCodeUnspsc190501.42295476': __('identifierSchemeCodeUnspsc190501.42295476'),
	'identifierSchemeCodeUnspsc190501.42295477': __('identifierSchemeCodeUnspsc190501.42295477'),
	'identifierSchemeCodeUnspsc190501.42295478': __('identifierSchemeCodeUnspsc190501.42295478'),
	'identifierSchemeCodeUnspsc190501.42295479': __('identifierSchemeCodeUnspsc190501.42295479'),
	'identifierSchemeCodeUnspsc190501.42295480': __('identifierSchemeCodeUnspsc190501.42295480'),
	'identifierSchemeCodeUnspsc190501.42295500': __('identifierSchemeCodeUnspsc190501.42295500'),
	'identifierSchemeCodeUnspsc190501.42295502': __('identifierSchemeCodeUnspsc190501.42295502'),
	'identifierSchemeCodeUnspsc190501.42295503': __('identifierSchemeCodeUnspsc190501.42295503'),
	'identifierSchemeCodeUnspsc190501.42295505': __('identifierSchemeCodeUnspsc190501.42295505'),
	'identifierSchemeCodeUnspsc190501.42295506': __('identifierSchemeCodeUnspsc190501.42295506'),
	'identifierSchemeCodeUnspsc190501.42295509': __('identifierSchemeCodeUnspsc190501.42295509'),
	'identifierSchemeCodeUnspsc190501.42295510': __('identifierSchemeCodeUnspsc190501.42295510'),
	'identifierSchemeCodeUnspsc190501.42295511': __('identifierSchemeCodeUnspsc190501.42295511'),
	'identifierSchemeCodeUnspsc190501.42295512': __('identifierSchemeCodeUnspsc190501.42295512'),
	'identifierSchemeCodeUnspsc190501.42295513': __('identifierSchemeCodeUnspsc190501.42295513'),
	'identifierSchemeCodeUnspsc190501.42295514': __('identifierSchemeCodeUnspsc190501.42295514'),
	'identifierSchemeCodeUnspsc190501.42295515': __('identifierSchemeCodeUnspsc190501.42295515'),
	'identifierSchemeCodeUnspsc190501.42295516': __('identifierSchemeCodeUnspsc190501.42295516'),
	'identifierSchemeCodeUnspsc190501.42295517': __('identifierSchemeCodeUnspsc190501.42295517'),
	'identifierSchemeCodeUnspsc190501.42295518': __('identifierSchemeCodeUnspsc190501.42295518'),
	'identifierSchemeCodeUnspsc190501.42295519': __('identifierSchemeCodeUnspsc190501.42295519'),
	'identifierSchemeCodeUnspsc190501.42295520': __('identifierSchemeCodeUnspsc190501.42295520'),
	'identifierSchemeCodeUnspsc190501.42295521': __('identifierSchemeCodeUnspsc190501.42295521'),
	'identifierSchemeCodeUnspsc190501.42295522': __('identifierSchemeCodeUnspsc190501.42295522'),
	'identifierSchemeCodeUnspsc190501.42295523': __('identifierSchemeCodeUnspsc190501.42295523'),
	'identifierSchemeCodeUnspsc190501.42295524': __('identifierSchemeCodeUnspsc190501.42295524'),
	'identifierSchemeCodeUnspsc190501.42295525': __('identifierSchemeCodeUnspsc190501.42295525'),
	'identifierSchemeCodeUnspsc190501.42295526': __('identifierSchemeCodeUnspsc190501.42295526'),
	'identifierSchemeCodeUnspsc190501.42295527': __('identifierSchemeCodeUnspsc190501.42295527'),
	'identifierSchemeCodeUnspsc190501.42295528': __('identifierSchemeCodeUnspsc190501.42295528'),
	'identifierSchemeCodeUnspsc190501.42295529': __('identifierSchemeCodeUnspsc190501.42295529'),
	'identifierSchemeCodeUnspsc190501.42295530': __('identifierSchemeCodeUnspsc190501.42295530'),
	'identifierSchemeCodeUnspsc190501.42295531': __('identifierSchemeCodeUnspsc190501.42295531'),
	'identifierSchemeCodeUnspsc190501.42295532': __('identifierSchemeCodeUnspsc190501.42295532'),
	'identifierSchemeCodeUnspsc190501.42295533': __('identifierSchemeCodeUnspsc190501.42295533'),
	'identifierSchemeCodeUnspsc190501.42295534': __('identifierSchemeCodeUnspsc190501.42295534'),
	'identifierSchemeCodeUnspsc190501.42295535': __('identifierSchemeCodeUnspsc190501.42295535'),
	'identifierSchemeCodeUnspsc190501.42295536': __('identifierSchemeCodeUnspsc190501.42295536'),
	'identifierSchemeCodeUnspsc190501.42295537': __('identifierSchemeCodeUnspsc190501.42295537'),
	'identifierSchemeCodeUnspsc190501.42295538': __('identifierSchemeCodeUnspsc190501.42295538'),
	'identifierSchemeCodeUnspsc190501.42295539': __('identifierSchemeCodeUnspsc190501.42295539'),
	'identifierSchemeCodeUnspsc190501.42295540': __('identifierSchemeCodeUnspsc190501.42295540'),
	'identifierSchemeCodeUnspsc190501.42295541': __('identifierSchemeCodeUnspsc190501.42295541'),
	'identifierSchemeCodeUnspsc190501.42295542': __('identifierSchemeCodeUnspsc190501.42295542'),
	'identifierSchemeCodeUnspsc190501.42295543': __('identifierSchemeCodeUnspsc190501.42295543'),
	'identifierSchemeCodeUnspsc190501.42295544': __('identifierSchemeCodeUnspsc190501.42295544'),
	'identifierSchemeCodeUnspsc190501.42295545': __('identifierSchemeCodeUnspsc190501.42295545'),
	'identifierSchemeCodeUnspsc190501.42295600': __('identifierSchemeCodeUnspsc190501.42295600'),
	'identifierSchemeCodeUnspsc190501.42295601': __('identifierSchemeCodeUnspsc190501.42295601'),
	'identifierSchemeCodeUnspsc190501.42295602': __('identifierSchemeCodeUnspsc190501.42295602'),
	'identifierSchemeCodeUnspsc190501.42295603': __('identifierSchemeCodeUnspsc190501.42295603'),
	'identifierSchemeCodeUnspsc190501.42295800': __('identifierSchemeCodeUnspsc190501.42295800'),
	'identifierSchemeCodeUnspsc190501.42295801': __('identifierSchemeCodeUnspsc190501.42295801'),
	'identifierSchemeCodeUnspsc190501.42295802': __('identifierSchemeCodeUnspsc190501.42295802'),
	'identifierSchemeCodeUnspsc190501.42295803': __('identifierSchemeCodeUnspsc190501.42295803'),
	'identifierSchemeCodeUnspsc190501.42295804': __('identifierSchemeCodeUnspsc190501.42295804'),
	'identifierSchemeCodeUnspsc190501.42295805': __('identifierSchemeCodeUnspsc190501.42295805'),
	'identifierSchemeCodeUnspsc190501.42295806': __('identifierSchemeCodeUnspsc190501.42295806'),
	'identifierSchemeCodeUnspsc190501.42295807': __('identifierSchemeCodeUnspsc190501.42295807'),
	'identifierSchemeCodeUnspsc190501.42295808': __('identifierSchemeCodeUnspsc190501.42295808'),
	'identifierSchemeCodeUnspsc190501.42295809': __('identifierSchemeCodeUnspsc190501.42295809'),
	'identifierSchemeCodeUnspsc190501.42295810': __('identifierSchemeCodeUnspsc190501.42295810'),
	'identifierSchemeCodeUnspsc190501.42295900': __('identifierSchemeCodeUnspsc190501.42295900'),
	'identifierSchemeCodeUnspsc190501.42295901': __('identifierSchemeCodeUnspsc190501.42295901'),
	'identifierSchemeCodeUnspsc190501.42295902': __('identifierSchemeCodeUnspsc190501.42295902'),
	'identifierSchemeCodeUnspsc190501.42295903': __('identifierSchemeCodeUnspsc190501.42295903'),
	'identifierSchemeCodeUnspsc190501.42295904': __('identifierSchemeCodeUnspsc190501.42295904'),
	'identifierSchemeCodeUnspsc190501.42295905': __('identifierSchemeCodeUnspsc190501.42295905'),
	'identifierSchemeCodeUnspsc190501.42295906': __('identifierSchemeCodeUnspsc190501.42295906'),
	'identifierSchemeCodeUnspsc190501.42295907': __('identifierSchemeCodeUnspsc190501.42295907'),
	'identifierSchemeCodeUnspsc190501.42295908': __('identifierSchemeCodeUnspsc190501.42295908'),
	'identifierSchemeCodeUnspsc190501.42295909': __('identifierSchemeCodeUnspsc190501.42295909'),
	'identifierSchemeCodeUnspsc190501.42295910': __('identifierSchemeCodeUnspsc190501.42295910'),
	'identifierSchemeCodeUnspsc190501.42295911': __('identifierSchemeCodeUnspsc190501.42295911'),
	'identifierSchemeCodeUnspsc190501.42295912': __('identifierSchemeCodeUnspsc190501.42295912'),
	'identifierSchemeCodeUnspsc190501.42296000': __('identifierSchemeCodeUnspsc190501.42296000'),
	'identifierSchemeCodeUnspsc190501.42296001': __('identifierSchemeCodeUnspsc190501.42296001'),
	'identifierSchemeCodeUnspsc190501.42296002': __('identifierSchemeCodeUnspsc190501.42296002'),
	'identifierSchemeCodeUnspsc190501.42296003': __('identifierSchemeCodeUnspsc190501.42296003'),
	'identifierSchemeCodeUnspsc190501.42296004': __('identifierSchemeCodeUnspsc190501.42296004'),
	'identifierSchemeCodeUnspsc190501.42296005': __('identifierSchemeCodeUnspsc190501.42296005'),
	'identifierSchemeCodeUnspsc190501.42296006': __('identifierSchemeCodeUnspsc190501.42296006'),
	'identifierSchemeCodeUnspsc190501.42296007': __('identifierSchemeCodeUnspsc190501.42296007'),
	'identifierSchemeCodeUnspsc190501.42296008': __('identifierSchemeCodeUnspsc190501.42296008'),
	'identifierSchemeCodeUnspsc190501.42296009': __('identifierSchemeCodeUnspsc190501.42296009'),
	'identifierSchemeCodeUnspsc190501.42296010': __('identifierSchemeCodeUnspsc190501.42296010'),
	'identifierSchemeCodeUnspsc190501.42296100': __('identifierSchemeCodeUnspsc190501.42296100'),
	'identifierSchemeCodeUnspsc190501.42296101': __('identifierSchemeCodeUnspsc190501.42296101'),
	'identifierSchemeCodeUnspsc190501.42296102': __('identifierSchemeCodeUnspsc190501.42296102'),
	'identifierSchemeCodeUnspsc190501.42296103': __('identifierSchemeCodeUnspsc190501.42296103'),
	'identifierSchemeCodeUnspsc190501.42296104': __('identifierSchemeCodeUnspsc190501.42296104'),
	'identifierSchemeCodeUnspsc190501.42296105': __('identifierSchemeCodeUnspsc190501.42296105'),
	'identifierSchemeCodeUnspsc190501.42296106': __('identifierSchemeCodeUnspsc190501.42296106'),
	'identifierSchemeCodeUnspsc190501.42296107': __('identifierSchemeCodeUnspsc190501.42296107'),
	'identifierSchemeCodeUnspsc190501.42296108': __('identifierSchemeCodeUnspsc190501.42296108'),
	'identifierSchemeCodeUnspsc190501.42296109': __('identifierSchemeCodeUnspsc190501.42296109'),
	'identifierSchemeCodeUnspsc190501.42296110': __('identifierSchemeCodeUnspsc190501.42296110'),
	'identifierSchemeCodeUnspsc190501.42296111': __('identifierSchemeCodeUnspsc190501.42296111'),
	'identifierSchemeCodeUnspsc190501.42296200': __('identifierSchemeCodeUnspsc190501.42296200'),
	'identifierSchemeCodeUnspsc190501.42296201': __('identifierSchemeCodeUnspsc190501.42296201'),
	'identifierSchemeCodeUnspsc190501.42296202': __('identifierSchemeCodeUnspsc190501.42296202'),
	'identifierSchemeCodeUnspsc190501.42296203': __('identifierSchemeCodeUnspsc190501.42296203'),
	'identifierSchemeCodeUnspsc190501.42296204': __('identifierSchemeCodeUnspsc190501.42296204'),
	'identifierSchemeCodeUnspsc190501.42296205': __('identifierSchemeCodeUnspsc190501.42296205'),
	'identifierSchemeCodeUnspsc190501.42296206': __('identifierSchemeCodeUnspsc190501.42296206'),
	'identifierSchemeCodeUnspsc190501.42296207': __('identifierSchemeCodeUnspsc190501.42296207'),
	'identifierSchemeCodeUnspsc190501.42296208': __('identifierSchemeCodeUnspsc190501.42296208'),
	'identifierSchemeCodeUnspsc190501.42296209': __('identifierSchemeCodeUnspsc190501.42296209'),
	'identifierSchemeCodeUnspsc190501.42296210': __('identifierSchemeCodeUnspsc190501.42296210'),
	'identifierSchemeCodeUnspsc190501.42296211': __('identifierSchemeCodeUnspsc190501.42296211'),
	'identifierSchemeCodeUnspsc190501.42296212': __('identifierSchemeCodeUnspsc190501.42296212'),
	'identifierSchemeCodeUnspsc190501.42296213': __('identifierSchemeCodeUnspsc190501.42296213'),
	'identifierSchemeCodeUnspsc190501.42296214': __('identifierSchemeCodeUnspsc190501.42296214'),
	'identifierSchemeCodeUnspsc190501.42296215': __('identifierSchemeCodeUnspsc190501.42296215'),
	'identifierSchemeCodeUnspsc190501.42296216': __('identifierSchemeCodeUnspsc190501.42296216'),
	'identifierSchemeCodeUnspsc190501.42296217': __('identifierSchemeCodeUnspsc190501.42296217'),
	'identifierSchemeCodeUnspsc190501.42296218': __('identifierSchemeCodeUnspsc190501.42296218'),
	'identifierSchemeCodeUnspsc190501.42296219': __('identifierSchemeCodeUnspsc190501.42296219'),
	'identifierSchemeCodeUnspsc190501.42296300': __('identifierSchemeCodeUnspsc190501.42296300'),
	'identifierSchemeCodeUnspsc190501.42296301': __('identifierSchemeCodeUnspsc190501.42296301'),
	'identifierSchemeCodeUnspsc190501.42296302': __('identifierSchemeCodeUnspsc190501.42296302'),
	'identifierSchemeCodeUnspsc190501.42296303': __('identifierSchemeCodeUnspsc190501.42296303'),
	'identifierSchemeCodeUnspsc190501.42296304': __('identifierSchemeCodeUnspsc190501.42296304'),
	'identifierSchemeCodeUnspsc190501.42296305': __('identifierSchemeCodeUnspsc190501.42296305'),
	'identifierSchemeCodeUnspsc190501.42296306': __('identifierSchemeCodeUnspsc190501.42296306'),
	'identifierSchemeCodeUnspsc190501.42296307': __('identifierSchemeCodeUnspsc190501.42296307'),
	'identifierSchemeCodeUnspsc190501.42296308': __('identifierSchemeCodeUnspsc190501.42296308'),
	'identifierSchemeCodeUnspsc190501.42296309': __('identifierSchemeCodeUnspsc190501.42296309'),
	'identifierSchemeCodeUnspsc190501.42296310': __('identifierSchemeCodeUnspsc190501.42296310'),
	'identifierSchemeCodeUnspsc190501.42296311': __('identifierSchemeCodeUnspsc190501.42296311'),
	'identifierSchemeCodeUnspsc190501.42296312': __('identifierSchemeCodeUnspsc190501.42296312'),
	'identifierSchemeCodeUnspsc190501.42296313': __('identifierSchemeCodeUnspsc190501.42296313'),
	'identifierSchemeCodeUnspsc190501.42296314': __('identifierSchemeCodeUnspsc190501.42296314'),
	'identifierSchemeCodeUnspsc190501.42296315': __('identifierSchemeCodeUnspsc190501.42296315'),
	'identifierSchemeCodeUnspsc190501.42296316': __('identifierSchemeCodeUnspsc190501.42296316'),
	'identifierSchemeCodeUnspsc190501.42296317': __('identifierSchemeCodeUnspsc190501.42296317'),
	'identifierSchemeCodeUnspsc190501.42296318': __('identifierSchemeCodeUnspsc190501.42296318'),
	'identifierSchemeCodeUnspsc190501.42296319': __('identifierSchemeCodeUnspsc190501.42296319'),
	'identifierSchemeCodeUnspsc190501.42296320': __('identifierSchemeCodeUnspsc190501.42296320'),
	'identifierSchemeCodeUnspsc190501.42296321': __('identifierSchemeCodeUnspsc190501.42296321'),
	'identifierSchemeCodeUnspsc190501.42296322': __('identifierSchemeCodeUnspsc190501.42296322'),
	'identifierSchemeCodeUnspsc190501.42296323': __('identifierSchemeCodeUnspsc190501.42296323'),
	'identifierSchemeCodeUnspsc190501.42296324': __('identifierSchemeCodeUnspsc190501.42296324'),
	'identifierSchemeCodeUnspsc190501.42296325': __('identifierSchemeCodeUnspsc190501.42296325'),
	'identifierSchemeCodeUnspsc190501.42296326': __('identifierSchemeCodeUnspsc190501.42296326'),
	'identifierSchemeCodeUnspsc190501.42296327': __('identifierSchemeCodeUnspsc190501.42296327'),
	'identifierSchemeCodeUnspsc190501.42296328': __('identifierSchemeCodeUnspsc190501.42296328'),
	'identifierSchemeCodeUnspsc190501.42296329': __('identifierSchemeCodeUnspsc190501.42296329'),
	'identifierSchemeCodeUnspsc190501.42296330': __('identifierSchemeCodeUnspsc190501.42296330'),
	'identifierSchemeCodeUnspsc190501.42296331': __('identifierSchemeCodeUnspsc190501.42296331'),
	'identifierSchemeCodeUnspsc190501.42296332': __('identifierSchemeCodeUnspsc190501.42296332'),
	'identifierSchemeCodeUnspsc190501.42296333': __('identifierSchemeCodeUnspsc190501.42296333'),
	'identifierSchemeCodeUnspsc190501.42296334': __('identifierSchemeCodeUnspsc190501.42296334'),
	'identifierSchemeCodeUnspsc190501.42296335': __('identifierSchemeCodeUnspsc190501.42296335'),
	'identifierSchemeCodeUnspsc190501.42296336': __('identifierSchemeCodeUnspsc190501.42296336'),
	'identifierSchemeCodeUnspsc190501.42296337': __('identifierSchemeCodeUnspsc190501.42296337'),
	'identifierSchemeCodeUnspsc190501.42296338': __('identifierSchemeCodeUnspsc190501.42296338'),
	'identifierSchemeCodeUnspsc190501.42296400': __('identifierSchemeCodeUnspsc190501.42296400'),
	'identifierSchemeCodeUnspsc190501.42296401': __('identifierSchemeCodeUnspsc190501.42296401'),
	'identifierSchemeCodeUnspsc190501.42296402': __('identifierSchemeCodeUnspsc190501.42296402'),
	'identifierSchemeCodeUnspsc190501.42296403': __('identifierSchemeCodeUnspsc190501.42296403'),
	'identifierSchemeCodeUnspsc190501.42296404': __('identifierSchemeCodeUnspsc190501.42296404'),
	'identifierSchemeCodeUnspsc190501.42296405': __('identifierSchemeCodeUnspsc190501.42296405'),
	'identifierSchemeCodeUnspsc190501.42296406': __('identifierSchemeCodeUnspsc190501.42296406'),
	'identifierSchemeCodeUnspsc190501.42296407': __('identifierSchemeCodeUnspsc190501.42296407'),
	'identifierSchemeCodeUnspsc190501.42296408': __('identifierSchemeCodeUnspsc190501.42296408'),
	'identifierSchemeCodeUnspsc190501.42296409': __('identifierSchemeCodeUnspsc190501.42296409'),
	'identifierSchemeCodeUnspsc190501.42296410': __('identifierSchemeCodeUnspsc190501.42296410'),
	'identifierSchemeCodeUnspsc190501.42296411': __('identifierSchemeCodeUnspsc190501.42296411'),
	'identifierSchemeCodeUnspsc190501.42296412': __('identifierSchemeCodeUnspsc190501.42296412'),
	'identifierSchemeCodeUnspsc190501.42296413': __('identifierSchemeCodeUnspsc190501.42296413'),
	'identifierSchemeCodeUnspsc190501.42296414': __('identifierSchemeCodeUnspsc190501.42296414'),
	'identifierSchemeCodeUnspsc190501.42296415': __('identifierSchemeCodeUnspsc190501.42296415'),
	'identifierSchemeCodeUnspsc190501.42296500': __('identifierSchemeCodeUnspsc190501.42296500'),
	'identifierSchemeCodeUnspsc190501.42296501': __('identifierSchemeCodeUnspsc190501.42296501'),
	'identifierSchemeCodeUnspsc190501.42296502': __('identifierSchemeCodeUnspsc190501.42296502'),
	'identifierSchemeCodeUnspsc190501.42296503': __('identifierSchemeCodeUnspsc190501.42296503'),
	'identifierSchemeCodeUnspsc190501.42296504': __('identifierSchemeCodeUnspsc190501.42296504'),
	'identifierSchemeCodeUnspsc190501.42296505': __('identifierSchemeCodeUnspsc190501.42296505'),
	'identifierSchemeCodeUnspsc190501.42296506': __('identifierSchemeCodeUnspsc190501.42296506'),
	'identifierSchemeCodeUnspsc190501.42296507': __('identifierSchemeCodeUnspsc190501.42296507'),
	'identifierSchemeCodeUnspsc190501.42296508': __('identifierSchemeCodeUnspsc190501.42296508'),
	'identifierSchemeCodeUnspsc190501.42296600': __('identifierSchemeCodeUnspsc190501.42296600'),
	'identifierSchemeCodeUnspsc190501.42296601': __('identifierSchemeCodeUnspsc190501.42296601'),
	'identifierSchemeCodeUnspsc190501.42296602': __('identifierSchemeCodeUnspsc190501.42296602'),
	'identifierSchemeCodeUnspsc190501.42296603': __('identifierSchemeCodeUnspsc190501.42296603'),
	'identifierSchemeCodeUnspsc190501.42296604': __('identifierSchemeCodeUnspsc190501.42296604'),
	'identifierSchemeCodeUnspsc190501.42296605': __('identifierSchemeCodeUnspsc190501.42296605'),
	'identifierSchemeCodeUnspsc190501.42296606': __('identifierSchemeCodeUnspsc190501.42296606'),
	'identifierSchemeCodeUnspsc190501.42296607': __('identifierSchemeCodeUnspsc190501.42296607'),
	'identifierSchemeCodeUnspsc190501.42296608': __('identifierSchemeCodeUnspsc190501.42296608'),
	'identifierSchemeCodeUnspsc190501.42296609': __('identifierSchemeCodeUnspsc190501.42296609'),
	'identifierSchemeCodeUnspsc190501.42296610': __('identifierSchemeCodeUnspsc190501.42296610'),
	'identifierSchemeCodeUnspsc190501.42296611': __('identifierSchemeCodeUnspsc190501.42296611'),
	'identifierSchemeCodeUnspsc190501.42296612': __('identifierSchemeCodeUnspsc190501.42296612'),
	'identifierSchemeCodeUnspsc190501.42296613': __('identifierSchemeCodeUnspsc190501.42296613'),
	'identifierSchemeCodeUnspsc190501.42296614': __('identifierSchemeCodeUnspsc190501.42296614'),
	'identifierSchemeCodeUnspsc190501.42296615': __('identifierSchemeCodeUnspsc190501.42296615'),
	'identifierSchemeCodeUnspsc190501.42296700': __('identifierSchemeCodeUnspsc190501.42296700'),
	'identifierSchemeCodeUnspsc190501.42296701': __('identifierSchemeCodeUnspsc190501.42296701'),
	'identifierSchemeCodeUnspsc190501.42296702': __('identifierSchemeCodeUnspsc190501.42296702'),
	'identifierSchemeCodeUnspsc190501.42296703': __('identifierSchemeCodeUnspsc190501.42296703'),
	'identifierSchemeCodeUnspsc190501.42296704': __('identifierSchemeCodeUnspsc190501.42296704'),
	'identifierSchemeCodeUnspsc190501.42296705': __('identifierSchemeCodeUnspsc190501.42296705'),
	'identifierSchemeCodeUnspsc190501.42296706': __('identifierSchemeCodeUnspsc190501.42296706'),
	'identifierSchemeCodeUnspsc190501.42296707': __('identifierSchemeCodeUnspsc190501.42296707'),
	'identifierSchemeCodeUnspsc190501.42296708': __('identifierSchemeCodeUnspsc190501.42296708'),
	'identifierSchemeCodeUnspsc190501.42296800': __('identifierSchemeCodeUnspsc190501.42296800'),
	'identifierSchemeCodeUnspsc190501.42296801': __('identifierSchemeCodeUnspsc190501.42296801'),
	'identifierSchemeCodeUnspsc190501.42296802': __('identifierSchemeCodeUnspsc190501.42296802'),
	'identifierSchemeCodeUnspsc190501.42296803': __('identifierSchemeCodeUnspsc190501.42296803'),
	'identifierSchemeCodeUnspsc190501.42296804': __('identifierSchemeCodeUnspsc190501.42296804'),
	'identifierSchemeCodeUnspsc190501.42296805': __('identifierSchemeCodeUnspsc190501.42296805'),
	'identifierSchemeCodeUnspsc190501.42296806': __('identifierSchemeCodeUnspsc190501.42296806'),
	'identifierSchemeCodeUnspsc190501.42296807': __('identifierSchemeCodeUnspsc190501.42296807'),
	'identifierSchemeCodeUnspsc190501.42296808': __('identifierSchemeCodeUnspsc190501.42296808'),
	'identifierSchemeCodeUnspsc190501.42296809': __('identifierSchemeCodeUnspsc190501.42296809'),
	'identifierSchemeCodeUnspsc190501.42296810': __('identifierSchemeCodeUnspsc190501.42296810'),
	'identifierSchemeCodeUnspsc190501.42296811': __('identifierSchemeCodeUnspsc190501.42296811'),
	'identifierSchemeCodeUnspsc190501.42296812': __('identifierSchemeCodeUnspsc190501.42296812'),
	'identifierSchemeCodeUnspsc190501.42296813': __('identifierSchemeCodeUnspsc190501.42296813'),
	'identifierSchemeCodeUnspsc190501.42296814': __('identifierSchemeCodeUnspsc190501.42296814'),
	'identifierSchemeCodeUnspsc190501.42296815': __('identifierSchemeCodeUnspsc190501.42296815'),
	'identifierSchemeCodeUnspsc190501.42296816': __('identifierSchemeCodeUnspsc190501.42296816'),
	'identifierSchemeCodeUnspsc190501.42296817': __('identifierSchemeCodeUnspsc190501.42296817'),
	'identifierSchemeCodeUnspsc190501.42296818': __('identifierSchemeCodeUnspsc190501.42296818'),
	'identifierSchemeCodeUnspsc190501.42300000': __('identifierSchemeCodeUnspsc190501.42300000'),
	'identifierSchemeCodeUnspsc190501.42301500': __('identifierSchemeCodeUnspsc190501.42301500'),
	'identifierSchemeCodeUnspsc190501.42301501': __('identifierSchemeCodeUnspsc190501.42301501'),
	'identifierSchemeCodeUnspsc190501.42301502': __('identifierSchemeCodeUnspsc190501.42301502'),
	'identifierSchemeCodeUnspsc190501.42301503': __('identifierSchemeCodeUnspsc190501.42301503'),
	'identifierSchemeCodeUnspsc190501.42301504': __('identifierSchemeCodeUnspsc190501.42301504'),
	'identifierSchemeCodeUnspsc190501.42301505': __('identifierSchemeCodeUnspsc190501.42301505'),
	'identifierSchemeCodeUnspsc190501.42301506': __('identifierSchemeCodeUnspsc190501.42301506'),
	'identifierSchemeCodeUnspsc190501.42301507': __('identifierSchemeCodeUnspsc190501.42301507'),
	'identifierSchemeCodeUnspsc190501.42301508': __('identifierSchemeCodeUnspsc190501.42301508'),
	'identifierSchemeCodeUnspsc190501.42301509': __('identifierSchemeCodeUnspsc190501.42301509'),
	'identifierSchemeCodeUnspsc190501.42301510': __('identifierSchemeCodeUnspsc190501.42301510'),
	'identifierSchemeCodeUnspsc190501.42301511': __('identifierSchemeCodeUnspsc190501.42301511'),
	'identifierSchemeCodeUnspsc190501.42310000': __('identifierSchemeCodeUnspsc190501.42310000'),
	'identifierSchemeCodeUnspsc190501.42311500': __('identifierSchemeCodeUnspsc190501.42311500'),
	'identifierSchemeCodeUnspsc190501.42311501': __('identifierSchemeCodeUnspsc190501.42311501'),
	'identifierSchemeCodeUnspsc190501.42311502': __('identifierSchemeCodeUnspsc190501.42311502'),
	'identifierSchemeCodeUnspsc190501.42311503': __('identifierSchemeCodeUnspsc190501.42311503'),
	'identifierSchemeCodeUnspsc190501.42311506': __('identifierSchemeCodeUnspsc190501.42311506'),
	'identifierSchemeCodeUnspsc190501.42311510': __('identifierSchemeCodeUnspsc190501.42311510'),
	'identifierSchemeCodeUnspsc190501.42311512': __('identifierSchemeCodeUnspsc190501.42311512'),
	'identifierSchemeCodeUnspsc190501.42311513': __('identifierSchemeCodeUnspsc190501.42311513'),
	'identifierSchemeCodeUnspsc190501.42311515': __('identifierSchemeCodeUnspsc190501.42311515'),
	'identifierSchemeCodeUnspsc190501.42311517': __('identifierSchemeCodeUnspsc190501.42311517'),
	'identifierSchemeCodeUnspsc190501.42311518': __('identifierSchemeCodeUnspsc190501.42311518'),
	'identifierSchemeCodeUnspsc190501.42311519': __('identifierSchemeCodeUnspsc190501.42311519'),
	'identifierSchemeCodeUnspsc190501.42311520': __('identifierSchemeCodeUnspsc190501.42311520'),
	'identifierSchemeCodeUnspsc190501.42311523': __('identifierSchemeCodeUnspsc190501.42311523'),
	'identifierSchemeCodeUnspsc190501.42311524': __('identifierSchemeCodeUnspsc190501.42311524'),
	'identifierSchemeCodeUnspsc190501.42311527': __('identifierSchemeCodeUnspsc190501.42311527'),
	'identifierSchemeCodeUnspsc190501.42311528': __('identifierSchemeCodeUnspsc190501.42311528'),
	'identifierSchemeCodeUnspsc190501.42311531': __('identifierSchemeCodeUnspsc190501.42311531'),
	'identifierSchemeCodeUnspsc190501.42311537': __('identifierSchemeCodeUnspsc190501.42311537'),
	'identifierSchemeCodeUnspsc190501.42311539': __('identifierSchemeCodeUnspsc190501.42311539'),
	'identifierSchemeCodeUnspsc190501.42311540': __('identifierSchemeCodeUnspsc190501.42311540'),
	'identifierSchemeCodeUnspsc190501.42311541': __('identifierSchemeCodeUnspsc190501.42311541'),
	'identifierSchemeCodeUnspsc190501.42311543': __('identifierSchemeCodeUnspsc190501.42311543'),
	'identifierSchemeCodeUnspsc190501.42311544': __('identifierSchemeCodeUnspsc190501.42311544'),
	'identifierSchemeCodeUnspsc190501.42311545': __('identifierSchemeCodeUnspsc190501.42311545'),
	'identifierSchemeCodeUnspsc190501.42311546': __('identifierSchemeCodeUnspsc190501.42311546'),
	'identifierSchemeCodeUnspsc190501.42311547': __('identifierSchemeCodeUnspsc190501.42311547'),
	'identifierSchemeCodeUnspsc190501.42311548': __('identifierSchemeCodeUnspsc190501.42311548'),
	'identifierSchemeCodeUnspsc190501.42311549': __('identifierSchemeCodeUnspsc190501.42311549'),
	'identifierSchemeCodeUnspsc190501.42311550': __('identifierSchemeCodeUnspsc190501.42311550'),
	'identifierSchemeCodeUnspsc190501.42311551': __('identifierSchemeCodeUnspsc190501.42311551'),
	'identifierSchemeCodeUnspsc190501.42311552': __('identifierSchemeCodeUnspsc190501.42311552'),
	'identifierSchemeCodeUnspsc190501.42311600': __('identifierSchemeCodeUnspsc190501.42311600'),
	'identifierSchemeCodeUnspsc190501.42311606': __('identifierSchemeCodeUnspsc190501.42311606'),
	'identifierSchemeCodeUnspsc190501.42311607': __('identifierSchemeCodeUnspsc190501.42311607'),
	'identifierSchemeCodeUnspsc190501.42312000': __('identifierSchemeCodeUnspsc190501.42312000'),
	'identifierSchemeCodeUnspsc190501.42312002': __('identifierSchemeCodeUnspsc190501.42312002'),
	'identifierSchemeCodeUnspsc190501.42312004': __('identifierSchemeCodeUnspsc190501.42312004'),
	'identifierSchemeCodeUnspsc190501.42312005': __('identifierSchemeCodeUnspsc190501.42312005'),
	'identifierSchemeCodeUnspsc190501.42312006': __('identifierSchemeCodeUnspsc190501.42312006'),
	'identifierSchemeCodeUnspsc190501.42312007': __('identifierSchemeCodeUnspsc190501.42312007'),
	'identifierSchemeCodeUnspsc190501.42312009': __('identifierSchemeCodeUnspsc190501.42312009'),
	'identifierSchemeCodeUnspsc190501.42312010': __('identifierSchemeCodeUnspsc190501.42312010'),
	'identifierSchemeCodeUnspsc190501.42312011': __('identifierSchemeCodeUnspsc190501.42312011'),
	'identifierSchemeCodeUnspsc190501.42312012': __('identifierSchemeCodeUnspsc190501.42312012'),
	'identifierSchemeCodeUnspsc190501.42312013': __('identifierSchemeCodeUnspsc190501.42312013'),
	'identifierSchemeCodeUnspsc190501.42312014': __('identifierSchemeCodeUnspsc190501.42312014'),
	'identifierSchemeCodeUnspsc190501.42312015': __('identifierSchemeCodeUnspsc190501.42312015'),
	'identifierSchemeCodeUnspsc190501.42312100': __('identifierSchemeCodeUnspsc190501.42312100'),
	'identifierSchemeCodeUnspsc190501.42312101': __('identifierSchemeCodeUnspsc190501.42312101'),
	'identifierSchemeCodeUnspsc190501.42312102': __('identifierSchemeCodeUnspsc190501.42312102'),
	'identifierSchemeCodeUnspsc190501.42312103': __('identifierSchemeCodeUnspsc190501.42312103'),
	'identifierSchemeCodeUnspsc190501.42312104': __('identifierSchemeCodeUnspsc190501.42312104'),
	'identifierSchemeCodeUnspsc190501.42312105': __('identifierSchemeCodeUnspsc190501.42312105'),
	'identifierSchemeCodeUnspsc190501.42312106': __('identifierSchemeCodeUnspsc190501.42312106'),
	'identifierSchemeCodeUnspsc190501.42312107': __('identifierSchemeCodeUnspsc190501.42312107'),
	'identifierSchemeCodeUnspsc190501.42312108': __('identifierSchemeCodeUnspsc190501.42312108'),
	'identifierSchemeCodeUnspsc190501.42312109': __('identifierSchemeCodeUnspsc190501.42312109'),
	'identifierSchemeCodeUnspsc190501.42312110': __('identifierSchemeCodeUnspsc190501.42312110'),
	'identifierSchemeCodeUnspsc190501.42312111': __('identifierSchemeCodeUnspsc190501.42312111'),
	'identifierSchemeCodeUnspsc190501.42312112': __('identifierSchemeCodeUnspsc190501.42312112'),
	'identifierSchemeCodeUnspsc190501.42312113': __('identifierSchemeCodeUnspsc190501.42312113'),
	'identifierSchemeCodeUnspsc190501.42312115': __('identifierSchemeCodeUnspsc190501.42312115'),
	'identifierSchemeCodeUnspsc190501.42312116': __('identifierSchemeCodeUnspsc190501.42312116'),
	'identifierSchemeCodeUnspsc190501.42312117': __('identifierSchemeCodeUnspsc190501.42312117'),
	'identifierSchemeCodeUnspsc190501.42312118': __('identifierSchemeCodeUnspsc190501.42312118'),
	'identifierSchemeCodeUnspsc190501.42312119': __('identifierSchemeCodeUnspsc190501.42312119'),
	'identifierSchemeCodeUnspsc190501.42312120': __('identifierSchemeCodeUnspsc190501.42312120'),
	'identifierSchemeCodeUnspsc190501.42312200': __('identifierSchemeCodeUnspsc190501.42312200'),
	'identifierSchemeCodeUnspsc190501.42312201': __('identifierSchemeCodeUnspsc190501.42312201'),
	'identifierSchemeCodeUnspsc190501.42312202': __('identifierSchemeCodeUnspsc190501.42312202'),
	'identifierSchemeCodeUnspsc190501.42312203': __('identifierSchemeCodeUnspsc190501.42312203'),
	'identifierSchemeCodeUnspsc190501.42312204': __('identifierSchemeCodeUnspsc190501.42312204'),
	'identifierSchemeCodeUnspsc190501.42312206': __('identifierSchemeCodeUnspsc190501.42312206'),
	'identifierSchemeCodeUnspsc190501.42312207': __('identifierSchemeCodeUnspsc190501.42312207'),
	'identifierSchemeCodeUnspsc190501.42312208': __('identifierSchemeCodeUnspsc190501.42312208'),
	'identifierSchemeCodeUnspsc190501.42312209': __('identifierSchemeCodeUnspsc190501.42312209'),
	'identifierSchemeCodeUnspsc190501.42312210': __('identifierSchemeCodeUnspsc190501.42312210'),
	'identifierSchemeCodeUnspsc190501.42312211': __('identifierSchemeCodeUnspsc190501.42312211'),
	'identifierSchemeCodeUnspsc190501.42312300': __('identifierSchemeCodeUnspsc190501.42312300'),
	'identifierSchemeCodeUnspsc190501.42312301': __('identifierSchemeCodeUnspsc190501.42312301'),
	'identifierSchemeCodeUnspsc190501.42312302': __('identifierSchemeCodeUnspsc190501.42312302'),
	'identifierSchemeCodeUnspsc190501.42312303': __('identifierSchemeCodeUnspsc190501.42312303'),
	'identifierSchemeCodeUnspsc190501.42312304': __('identifierSchemeCodeUnspsc190501.42312304'),
	'identifierSchemeCodeUnspsc190501.42312305': __('identifierSchemeCodeUnspsc190501.42312305'),
	'identifierSchemeCodeUnspsc190501.42312306': __('identifierSchemeCodeUnspsc190501.42312306'),
	'identifierSchemeCodeUnspsc190501.42312307': __('identifierSchemeCodeUnspsc190501.42312307'),
	'identifierSchemeCodeUnspsc190501.42312309': __('identifierSchemeCodeUnspsc190501.42312309'),
	'identifierSchemeCodeUnspsc190501.42312310': __('identifierSchemeCodeUnspsc190501.42312310'),
	'identifierSchemeCodeUnspsc190501.42312311': __('identifierSchemeCodeUnspsc190501.42312311'),
	'identifierSchemeCodeUnspsc190501.42312312': __('identifierSchemeCodeUnspsc190501.42312312'),
	'identifierSchemeCodeUnspsc190501.42312313': __('identifierSchemeCodeUnspsc190501.42312313'),
	'identifierSchemeCodeUnspsc190501.42312314': __('identifierSchemeCodeUnspsc190501.42312314'),
	'identifierSchemeCodeUnspsc190501.42312315': __('identifierSchemeCodeUnspsc190501.42312315'),
	'identifierSchemeCodeUnspsc190501.42312400': __('identifierSchemeCodeUnspsc190501.42312400'),
	'identifierSchemeCodeUnspsc190501.42312401': __('identifierSchemeCodeUnspsc190501.42312401'),
	'identifierSchemeCodeUnspsc190501.42312402': __('identifierSchemeCodeUnspsc190501.42312402'),
	'identifierSchemeCodeUnspsc190501.42312403': __('identifierSchemeCodeUnspsc190501.42312403'),
	'identifierSchemeCodeUnspsc190501.42312500': __('identifierSchemeCodeUnspsc190501.42312500'),
	'identifierSchemeCodeUnspsc190501.42312501': __('identifierSchemeCodeUnspsc190501.42312501'),
	'identifierSchemeCodeUnspsc190501.42312502': __('identifierSchemeCodeUnspsc190501.42312502'),
	'identifierSchemeCodeUnspsc190501.42312503': __('identifierSchemeCodeUnspsc190501.42312503'),
	'identifierSchemeCodeUnspsc190501.42312504': __('identifierSchemeCodeUnspsc190501.42312504'),
	'identifierSchemeCodeUnspsc190501.42312505': __('identifierSchemeCodeUnspsc190501.42312505'),
	'identifierSchemeCodeUnspsc190501.42312600': __('identifierSchemeCodeUnspsc190501.42312600'),
	'identifierSchemeCodeUnspsc190501.42312601': __('identifierSchemeCodeUnspsc190501.42312601'),
	'identifierSchemeCodeUnspsc190501.42312602': __('identifierSchemeCodeUnspsc190501.42312602'),
	'identifierSchemeCodeUnspsc190501.42312603': __('identifierSchemeCodeUnspsc190501.42312603'),
	'identifierSchemeCodeUnspsc190501.42312604': __('identifierSchemeCodeUnspsc190501.42312604'),
	'identifierSchemeCodeUnspsc190501.42320000': __('identifierSchemeCodeUnspsc190501.42320000'),
	'identifierSchemeCodeUnspsc190501.42321500': __('identifierSchemeCodeUnspsc190501.42321500'),
	'identifierSchemeCodeUnspsc190501.42321501': __('identifierSchemeCodeUnspsc190501.42321501'),
	'identifierSchemeCodeUnspsc190501.42321504': __('identifierSchemeCodeUnspsc190501.42321504'),
	'identifierSchemeCodeUnspsc190501.42321505': __('identifierSchemeCodeUnspsc190501.42321505'),
	'identifierSchemeCodeUnspsc190501.42321506': __('identifierSchemeCodeUnspsc190501.42321506'),
	'identifierSchemeCodeUnspsc190501.42321507': __('identifierSchemeCodeUnspsc190501.42321507'),
	'identifierSchemeCodeUnspsc190501.42321508': __('identifierSchemeCodeUnspsc190501.42321508'),
	'identifierSchemeCodeUnspsc190501.42321509': __('identifierSchemeCodeUnspsc190501.42321509'),
	'identifierSchemeCodeUnspsc190501.42321510': __('identifierSchemeCodeUnspsc190501.42321510'),
	'identifierSchemeCodeUnspsc190501.42321512': __('identifierSchemeCodeUnspsc190501.42321512'),
	'identifierSchemeCodeUnspsc190501.42321513': __('identifierSchemeCodeUnspsc190501.42321513'),
	'identifierSchemeCodeUnspsc190501.42321514': __('identifierSchemeCodeUnspsc190501.42321514'),
	'identifierSchemeCodeUnspsc190501.42321515': __('identifierSchemeCodeUnspsc190501.42321515'),
	'identifierSchemeCodeUnspsc190501.42321516': __('identifierSchemeCodeUnspsc190501.42321516'),
	'identifierSchemeCodeUnspsc190501.42321517': __('identifierSchemeCodeUnspsc190501.42321517'),
	'identifierSchemeCodeUnspsc190501.42321518': __('identifierSchemeCodeUnspsc190501.42321518'),
	'identifierSchemeCodeUnspsc190501.42321600': __('identifierSchemeCodeUnspsc190501.42321600'),
	'identifierSchemeCodeUnspsc190501.42321601': __('identifierSchemeCodeUnspsc190501.42321601'),
	'identifierSchemeCodeUnspsc190501.42321602': __('identifierSchemeCodeUnspsc190501.42321602'),
	'identifierSchemeCodeUnspsc190501.42321603': __('identifierSchemeCodeUnspsc190501.42321603'),
	'identifierSchemeCodeUnspsc190501.42321604': __('identifierSchemeCodeUnspsc190501.42321604'),
	'identifierSchemeCodeUnspsc190501.42321605': __('identifierSchemeCodeUnspsc190501.42321605'),
	'identifierSchemeCodeUnspsc190501.42321606': __('identifierSchemeCodeUnspsc190501.42321606'),
	'identifierSchemeCodeUnspsc190501.42321607': __('identifierSchemeCodeUnspsc190501.42321607'),
	'identifierSchemeCodeUnspsc190501.42321609': __('identifierSchemeCodeUnspsc190501.42321609'),
	'identifierSchemeCodeUnspsc190501.42321610': __('identifierSchemeCodeUnspsc190501.42321610'),
	'identifierSchemeCodeUnspsc190501.42321611': __('identifierSchemeCodeUnspsc190501.42321611'),
	'identifierSchemeCodeUnspsc190501.42321612': __('identifierSchemeCodeUnspsc190501.42321612'),
	'identifierSchemeCodeUnspsc190501.42321613': __('identifierSchemeCodeUnspsc190501.42321613'),
	'identifierSchemeCodeUnspsc190501.42321614': __('identifierSchemeCodeUnspsc190501.42321614'),
	'identifierSchemeCodeUnspsc190501.42321615': __('identifierSchemeCodeUnspsc190501.42321615'),
	'identifierSchemeCodeUnspsc190501.42321616': __('identifierSchemeCodeUnspsc190501.42321616'),
	'identifierSchemeCodeUnspsc190501.42321617': __('identifierSchemeCodeUnspsc190501.42321617'),
	'identifierSchemeCodeUnspsc190501.42321618': __('identifierSchemeCodeUnspsc190501.42321618'),
	'identifierSchemeCodeUnspsc190501.42321619': __('identifierSchemeCodeUnspsc190501.42321619'),
	'identifierSchemeCodeUnspsc190501.42321620': __('identifierSchemeCodeUnspsc190501.42321620'),
	'identifierSchemeCodeUnspsc190501.42321621': __('identifierSchemeCodeUnspsc190501.42321621'),
	'identifierSchemeCodeUnspsc190501.42321622': __('identifierSchemeCodeUnspsc190501.42321622'),
	'identifierSchemeCodeUnspsc190501.42321700': __('identifierSchemeCodeUnspsc190501.42321700'),
	'identifierSchemeCodeUnspsc190501.42321701': __('identifierSchemeCodeUnspsc190501.42321701'),
	'identifierSchemeCodeUnspsc190501.42321702': __('identifierSchemeCodeUnspsc190501.42321702'),
	'identifierSchemeCodeUnspsc190501.42321703': __('identifierSchemeCodeUnspsc190501.42321703'),
	'identifierSchemeCodeUnspsc190501.42321704': __('identifierSchemeCodeUnspsc190501.42321704'),
	'identifierSchemeCodeUnspsc190501.42321705': __('identifierSchemeCodeUnspsc190501.42321705'),
	'identifierSchemeCodeUnspsc190501.42321706': __('identifierSchemeCodeUnspsc190501.42321706'),
	'identifierSchemeCodeUnspsc190501.42321707': __('identifierSchemeCodeUnspsc190501.42321707'),
	'identifierSchemeCodeUnspsc190501.42321708': __('identifierSchemeCodeUnspsc190501.42321708'),
	'identifierSchemeCodeUnspsc190501.42321709': __('identifierSchemeCodeUnspsc190501.42321709'),
	'identifierSchemeCodeUnspsc190501.42321710': __('identifierSchemeCodeUnspsc190501.42321710'),
	'identifierSchemeCodeUnspsc190501.42321711': __('identifierSchemeCodeUnspsc190501.42321711'),
	'identifierSchemeCodeUnspsc190501.42321712': __('identifierSchemeCodeUnspsc190501.42321712'),
	'identifierSchemeCodeUnspsc190501.42321713': __('identifierSchemeCodeUnspsc190501.42321713'),
	'identifierSchemeCodeUnspsc190501.42321714': __('identifierSchemeCodeUnspsc190501.42321714'),
	'identifierSchemeCodeUnspsc190501.42321715': __('identifierSchemeCodeUnspsc190501.42321715'),
	'identifierSchemeCodeUnspsc190501.42321716': __('identifierSchemeCodeUnspsc190501.42321716'),
	'identifierSchemeCodeUnspsc190501.42321718': __('identifierSchemeCodeUnspsc190501.42321718'),
	'identifierSchemeCodeUnspsc190501.42321719': __('identifierSchemeCodeUnspsc190501.42321719'),
	'identifierSchemeCodeUnspsc190501.42321720': __('identifierSchemeCodeUnspsc190501.42321720'),
	'identifierSchemeCodeUnspsc190501.42321721': __('identifierSchemeCodeUnspsc190501.42321721'),
	'identifierSchemeCodeUnspsc190501.42321722': __('identifierSchemeCodeUnspsc190501.42321722'),
	'identifierSchemeCodeUnspsc190501.42321723': __('identifierSchemeCodeUnspsc190501.42321723'),
	'identifierSchemeCodeUnspsc190501.42321724': __('identifierSchemeCodeUnspsc190501.42321724'),
	'identifierSchemeCodeUnspsc190501.42321800': __('identifierSchemeCodeUnspsc190501.42321800'),
	'identifierSchemeCodeUnspsc190501.42321801': __('identifierSchemeCodeUnspsc190501.42321801'),
	'identifierSchemeCodeUnspsc190501.42321802': __('identifierSchemeCodeUnspsc190501.42321802'),
	'identifierSchemeCodeUnspsc190501.42321804': __('identifierSchemeCodeUnspsc190501.42321804'),
	'identifierSchemeCodeUnspsc190501.42321805': __('identifierSchemeCodeUnspsc190501.42321805'),
	'identifierSchemeCodeUnspsc190501.42321806': __('identifierSchemeCodeUnspsc190501.42321806'),
	'identifierSchemeCodeUnspsc190501.42321808': __('identifierSchemeCodeUnspsc190501.42321808'),
	'identifierSchemeCodeUnspsc190501.42321809': __('identifierSchemeCodeUnspsc190501.42321809'),
	'identifierSchemeCodeUnspsc190501.42321810': __('identifierSchemeCodeUnspsc190501.42321810'),
	'identifierSchemeCodeUnspsc190501.42321811': __('identifierSchemeCodeUnspsc190501.42321811'),
	'identifierSchemeCodeUnspsc190501.42321812': __('identifierSchemeCodeUnspsc190501.42321812'),
	'identifierSchemeCodeUnspsc190501.42321813': __('identifierSchemeCodeUnspsc190501.42321813'),
	'identifierSchemeCodeUnspsc190501.42321814': __('identifierSchemeCodeUnspsc190501.42321814'),
	'identifierSchemeCodeUnspsc190501.42321815': __('identifierSchemeCodeUnspsc190501.42321815'),
	'identifierSchemeCodeUnspsc190501.42321816': __('identifierSchemeCodeUnspsc190501.42321816'),
	'identifierSchemeCodeUnspsc190501.42321900': __('identifierSchemeCodeUnspsc190501.42321900'),
	'identifierSchemeCodeUnspsc190501.42321902': __('identifierSchemeCodeUnspsc190501.42321902'),
	'identifierSchemeCodeUnspsc190501.42321903': __('identifierSchemeCodeUnspsc190501.42321903'),
	'identifierSchemeCodeUnspsc190501.42321904': __('identifierSchemeCodeUnspsc190501.42321904'),
	'identifierSchemeCodeUnspsc190501.42321905': __('identifierSchemeCodeUnspsc190501.42321905'),
	'identifierSchemeCodeUnspsc190501.42321906': __('identifierSchemeCodeUnspsc190501.42321906'),
	'identifierSchemeCodeUnspsc190501.42321907': __('identifierSchemeCodeUnspsc190501.42321907'),
	'identifierSchemeCodeUnspsc190501.42321908': __('identifierSchemeCodeUnspsc190501.42321908'),
	'identifierSchemeCodeUnspsc190501.42321909': __('identifierSchemeCodeUnspsc190501.42321909'),
	'identifierSchemeCodeUnspsc190501.42321910': __('identifierSchemeCodeUnspsc190501.42321910'),
	'identifierSchemeCodeUnspsc190501.42321911': __('identifierSchemeCodeUnspsc190501.42321911'),
	'identifierSchemeCodeUnspsc190501.42321912': __('identifierSchemeCodeUnspsc190501.42321912'),
	'identifierSchemeCodeUnspsc190501.42321913': __('identifierSchemeCodeUnspsc190501.42321913'),
	'identifierSchemeCodeUnspsc190501.42322000': __('identifierSchemeCodeUnspsc190501.42322000'),
	'identifierSchemeCodeUnspsc190501.42322001': __('identifierSchemeCodeUnspsc190501.42322001'),
	'identifierSchemeCodeUnspsc190501.42322002': __('identifierSchemeCodeUnspsc190501.42322002'),
	'identifierSchemeCodeUnspsc190501.42322003': __('identifierSchemeCodeUnspsc190501.42322003'),
	'identifierSchemeCodeUnspsc190501.42322004': __('identifierSchemeCodeUnspsc190501.42322004'),
	'identifierSchemeCodeUnspsc190501.42322005': __('identifierSchemeCodeUnspsc190501.42322005'),
	'identifierSchemeCodeUnspsc190501.42322006': __('identifierSchemeCodeUnspsc190501.42322006'),
	'identifierSchemeCodeUnspsc190501.42322007': __('identifierSchemeCodeUnspsc190501.42322007'),
	'identifierSchemeCodeUnspsc190501.42322008': __('identifierSchemeCodeUnspsc190501.42322008'),
	'identifierSchemeCodeUnspsc190501.42322009': __('identifierSchemeCodeUnspsc190501.42322009'),
	'identifierSchemeCodeUnspsc190501.42322100': __('identifierSchemeCodeUnspsc190501.42322100'),
	'identifierSchemeCodeUnspsc190501.42322101': __('identifierSchemeCodeUnspsc190501.42322101'),
	'identifierSchemeCodeUnspsc190501.42322102': __('identifierSchemeCodeUnspsc190501.42322102'),
	'identifierSchemeCodeUnspsc190501.42322103': __('identifierSchemeCodeUnspsc190501.42322103'),
	'identifierSchemeCodeUnspsc190501.42322104': __('identifierSchemeCodeUnspsc190501.42322104'),
	'identifierSchemeCodeUnspsc190501.42322105': __('identifierSchemeCodeUnspsc190501.42322105'),
	'identifierSchemeCodeUnspsc190501.42322106': __('identifierSchemeCodeUnspsc190501.42322106'),
	'identifierSchemeCodeUnspsc190501.42322200': __('identifierSchemeCodeUnspsc190501.42322200'),
	'identifierSchemeCodeUnspsc190501.42322201': __('identifierSchemeCodeUnspsc190501.42322201'),
	'identifierSchemeCodeUnspsc190501.42322202': __('identifierSchemeCodeUnspsc190501.42322202'),
	'identifierSchemeCodeUnspsc190501.42322203': __('identifierSchemeCodeUnspsc190501.42322203'),
	'identifierSchemeCodeUnspsc190501.42322204': __('identifierSchemeCodeUnspsc190501.42322204'),
	'identifierSchemeCodeUnspsc190501.42322205': __('identifierSchemeCodeUnspsc190501.42322205'),
	'identifierSchemeCodeUnspsc190501.42322206': __('identifierSchemeCodeUnspsc190501.42322206'),
	'identifierSchemeCodeUnspsc190501.42322300': __('identifierSchemeCodeUnspsc190501.42322300'),
	'identifierSchemeCodeUnspsc190501.42322301': __('identifierSchemeCodeUnspsc190501.42322301'),
	'identifierSchemeCodeUnspsc190501.42322302': __('identifierSchemeCodeUnspsc190501.42322302'),
	'identifierSchemeCodeUnspsc190501.42322400': __('identifierSchemeCodeUnspsc190501.42322400'),
	'identifierSchemeCodeUnspsc190501.42322401': __('identifierSchemeCodeUnspsc190501.42322401'),
	'identifierSchemeCodeUnspsc190501.42322402': __('identifierSchemeCodeUnspsc190501.42322402'),
	'identifierSchemeCodeUnspsc190501.42322403': __('identifierSchemeCodeUnspsc190501.42322403'),
	'identifierSchemeCodeUnspsc190501.42322500': __('identifierSchemeCodeUnspsc190501.42322500'),
	'identifierSchemeCodeUnspsc190501.42322501': __('identifierSchemeCodeUnspsc190501.42322501'),
	'identifierSchemeCodeUnspsc190501.42322502': __('identifierSchemeCodeUnspsc190501.42322502'),
	'identifierSchemeCodeUnspsc190501.42322503': __('identifierSchemeCodeUnspsc190501.42322503'),
	'identifierSchemeCodeUnspsc190501.42322504': __('identifierSchemeCodeUnspsc190501.42322504'),
	'identifierSchemeCodeUnspsc190501.43000000': __('identifierSchemeCodeUnspsc190501.43000000'),
	'identifierSchemeCodeUnspsc190501.43190000': __('identifierSchemeCodeUnspsc190501.43190000'),
	'identifierSchemeCodeUnspsc190501.43191500': __('identifierSchemeCodeUnspsc190501.43191500'),
	'identifierSchemeCodeUnspsc190501.43191501': __('identifierSchemeCodeUnspsc190501.43191501'),
	'identifierSchemeCodeUnspsc190501.43191502': __('identifierSchemeCodeUnspsc190501.43191502'),
	'identifierSchemeCodeUnspsc190501.43191503': __('identifierSchemeCodeUnspsc190501.43191503'),
	'identifierSchemeCodeUnspsc190501.43191504': __('identifierSchemeCodeUnspsc190501.43191504'),
	'identifierSchemeCodeUnspsc190501.43191505': __('identifierSchemeCodeUnspsc190501.43191505'),
	'identifierSchemeCodeUnspsc190501.43191507': __('identifierSchemeCodeUnspsc190501.43191507'),
	'identifierSchemeCodeUnspsc190501.43191508': __('identifierSchemeCodeUnspsc190501.43191508'),
	'identifierSchemeCodeUnspsc190501.43191509': __('identifierSchemeCodeUnspsc190501.43191509'),
	'identifierSchemeCodeUnspsc190501.43191510': __('identifierSchemeCodeUnspsc190501.43191510'),
	'identifierSchemeCodeUnspsc190501.43191511': __('identifierSchemeCodeUnspsc190501.43191511'),
	'identifierSchemeCodeUnspsc190501.43191512': __('identifierSchemeCodeUnspsc190501.43191512'),
	'identifierSchemeCodeUnspsc190501.43191513': __('identifierSchemeCodeUnspsc190501.43191513'),
	'identifierSchemeCodeUnspsc190501.43191514': __('identifierSchemeCodeUnspsc190501.43191514'),
	'identifierSchemeCodeUnspsc190501.43191515': __('identifierSchemeCodeUnspsc190501.43191515'),
	'identifierSchemeCodeUnspsc190501.43191516': __('identifierSchemeCodeUnspsc190501.43191516'),
	'identifierSchemeCodeUnspsc190501.43191600': __('identifierSchemeCodeUnspsc190501.43191600'),
	'identifierSchemeCodeUnspsc190501.43191601': __('identifierSchemeCodeUnspsc190501.43191601'),
	'identifierSchemeCodeUnspsc190501.43191602': __('identifierSchemeCodeUnspsc190501.43191602'),
	'identifierSchemeCodeUnspsc190501.43191603': __('identifierSchemeCodeUnspsc190501.43191603'),
	'identifierSchemeCodeUnspsc190501.43191604': __('identifierSchemeCodeUnspsc190501.43191604'),
	'identifierSchemeCodeUnspsc190501.43191605': __('identifierSchemeCodeUnspsc190501.43191605'),
	'identifierSchemeCodeUnspsc190501.43191606': __('identifierSchemeCodeUnspsc190501.43191606'),
	'identifierSchemeCodeUnspsc190501.43191607': __('identifierSchemeCodeUnspsc190501.43191607'),
	'identifierSchemeCodeUnspsc190501.43191608': __('identifierSchemeCodeUnspsc190501.43191608'),
	'identifierSchemeCodeUnspsc190501.43191609': __('identifierSchemeCodeUnspsc190501.43191609'),
	'identifierSchemeCodeUnspsc190501.43191610': __('identifierSchemeCodeUnspsc190501.43191610'),
	'identifierSchemeCodeUnspsc190501.43191611': __('identifierSchemeCodeUnspsc190501.43191611'),
	'identifierSchemeCodeUnspsc190501.43191612': __('identifierSchemeCodeUnspsc190501.43191612'),
	'identifierSchemeCodeUnspsc190501.43191614': __('identifierSchemeCodeUnspsc190501.43191614'),
	'identifierSchemeCodeUnspsc190501.43191615': __('identifierSchemeCodeUnspsc190501.43191615'),
	'identifierSchemeCodeUnspsc190501.43191616': __('identifierSchemeCodeUnspsc190501.43191616'),
	'identifierSchemeCodeUnspsc190501.43191618': __('identifierSchemeCodeUnspsc190501.43191618'),
	'identifierSchemeCodeUnspsc190501.43191619': __('identifierSchemeCodeUnspsc190501.43191619'),
	'identifierSchemeCodeUnspsc190501.43191621': __('identifierSchemeCodeUnspsc190501.43191621'),
	'identifierSchemeCodeUnspsc190501.43191622': __('identifierSchemeCodeUnspsc190501.43191622'),
	'identifierSchemeCodeUnspsc190501.43191623': __('identifierSchemeCodeUnspsc190501.43191623'),
	'identifierSchemeCodeUnspsc190501.43191624': __('identifierSchemeCodeUnspsc190501.43191624'),
	'identifierSchemeCodeUnspsc190501.43191625': __('identifierSchemeCodeUnspsc190501.43191625'),
	'identifierSchemeCodeUnspsc190501.43191626': __('identifierSchemeCodeUnspsc190501.43191626'),
	'identifierSchemeCodeUnspsc190501.43191627': __('identifierSchemeCodeUnspsc190501.43191627'),
	'identifierSchemeCodeUnspsc190501.43191628': __('identifierSchemeCodeUnspsc190501.43191628'),
	'identifierSchemeCodeUnspsc190501.43191629': __('identifierSchemeCodeUnspsc190501.43191629'),
	'identifierSchemeCodeUnspsc190501.43191630': __('identifierSchemeCodeUnspsc190501.43191630'),
	'identifierSchemeCodeUnspsc190501.43191631': __('identifierSchemeCodeUnspsc190501.43191631'),
	'identifierSchemeCodeUnspsc190501.43191632': __('identifierSchemeCodeUnspsc190501.43191632'),
	'identifierSchemeCodeUnspsc190501.43191633': __('identifierSchemeCodeUnspsc190501.43191633'),
	'identifierSchemeCodeUnspsc190501.43191634': __('identifierSchemeCodeUnspsc190501.43191634'),
	'identifierSchemeCodeUnspsc190501.43200000': __('identifierSchemeCodeUnspsc190501.43200000'),
	'identifierSchemeCodeUnspsc190501.43201400': __('identifierSchemeCodeUnspsc190501.43201400'),
	'identifierSchemeCodeUnspsc190501.43201401': __('identifierSchemeCodeUnspsc190501.43201401'),
	'identifierSchemeCodeUnspsc190501.43201402': __('identifierSchemeCodeUnspsc190501.43201402'),
	'identifierSchemeCodeUnspsc190501.43201403': __('identifierSchemeCodeUnspsc190501.43201403'),
	'identifierSchemeCodeUnspsc190501.43201404': __('identifierSchemeCodeUnspsc190501.43201404'),
	'identifierSchemeCodeUnspsc190501.43201405': __('identifierSchemeCodeUnspsc190501.43201405'),
	'identifierSchemeCodeUnspsc190501.43201406': __('identifierSchemeCodeUnspsc190501.43201406'),
	'identifierSchemeCodeUnspsc190501.43201407': __('identifierSchemeCodeUnspsc190501.43201407'),
	'identifierSchemeCodeUnspsc190501.43201409': __('identifierSchemeCodeUnspsc190501.43201409'),
	'identifierSchemeCodeUnspsc190501.43201410': __('identifierSchemeCodeUnspsc190501.43201410'),
	'identifierSchemeCodeUnspsc190501.43201411': __('identifierSchemeCodeUnspsc190501.43201411'),
	'identifierSchemeCodeUnspsc190501.43201412': __('identifierSchemeCodeUnspsc190501.43201412'),
	'identifierSchemeCodeUnspsc190501.43201413': __('identifierSchemeCodeUnspsc190501.43201413'),
	'identifierSchemeCodeUnspsc190501.43201414': __('identifierSchemeCodeUnspsc190501.43201414'),
	'identifierSchemeCodeUnspsc190501.43201415': __('identifierSchemeCodeUnspsc190501.43201415'),
	'identifierSchemeCodeUnspsc190501.43201416': __('identifierSchemeCodeUnspsc190501.43201416'),
	'identifierSchemeCodeUnspsc190501.43201417': __('identifierSchemeCodeUnspsc190501.43201417'),
	'identifierSchemeCodeUnspsc190501.43201500': __('identifierSchemeCodeUnspsc190501.43201500'),
	'identifierSchemeCodeUnspsc190501.43201501': __('identifierSchemeCodeUnspsc190501.43201501'),
	'identifierSchemeCodeUnspsc190501.43201502': __('identifierSchemeCodeUnspsc190501.43201502'),
	'identifierSchemeCodeUnspsc190501.43201503': __('identifierSchemeCodeUnspsc190501.43201503'),
	'identifierSchemeCodeUnspsc190501.43201507': __('identifierSchemeCodeUnspsc190501.43201507'),
	'identifierSchemeCodeUnspsc190501.43201508': __('identifierSchemeCodeUnspsc190501.43201508'),
	'identifierSchemeCodeUnspsc190501.43201509': __('identifierSchemeCodeUnspsc190501.43201509'),
	'identifierSchemeCodeUnspsc190501.43201513': __('identifierSchemeCodeUnspsc190501.43201513'),
	'identifierSchemeCodeUnspsc190501.43201522': __('identifierSchemeCodeUnspsc190501.43201522'),
	'identifierSchemeCodeUnspsc190501.43201531': __('identifierSchemeCodeUnspsc190501.43201531'),
	'identifierSchemeCodeUnspsc190501.43201533': __('identifierSchemeCodeUnspsc190501.43201533'),
	'identifierSchemeCodeUnspsc190501.43201534': __('identifierSchemeCodeUnspsc190501.43201534'),
	'identifierSchemeCodeUnspsc190501.43201535': __('identifierSchemeCodeUnspsc190501.43201535'),
	'identifierSchemeCodeUnspsc190501.43201537': __('identifierSchemeCodeUnspsc190501.43201537'),
	'identifierSchemeCodeUnspsc190501.43201538': __('identifierSchemeCodeUnspsc190501.43201538'),
	'identifierSchemeCodeUnspsc190501.43201539': __('identifierSchemeCodeUnspsc190501.43201539'),
	'identifierSchemeCodeUnspsc190501.43201540': __('identifierSchemeCodeUnspsc190501.43201540'),
	'identifierSchemeCodeUnspsc190501.43201541': __('identifierSchemeCodeUnspsc190501.43201541'),
	'identifierSchemeCodeUnspsc190501.43201542': __('identifierSchemeCodeUnspsc190501.43201542'),
	'identifierSchemeCodeUnspsc190501.43201543': __('identifierSchemeCodeUnspsc190501.43201543'),
	'identifierSchemeCodeUnspsc190501.43201544': __('identifierSchemeCodeUnspsc190501.43201544'),
	'identifierSchemeCodeUnspsc190501.43201545': __('identifierSchemeCodeUnspsc190501.43201545'),
	'identifierSchemeCodeUnspsc190501.43201546': __('identifierSchemeCodeUnspsc190501.43201546'),
	'identifierSchemeCodeUnspsc190501.43201547': __('identifierSchemeCodeUnspsc190501.43201547'),
	'identifierSchemeCodeUnspsc190501.43201549': __('identifierSchemeCodeUnspsc190501.43201549'),
	'identifierSchemeCodeUnspsc190501.43201550': __('identifierSchemeCodeUnspsc190501.43201550'),
	'identifierSchemeCodeUnspsc190501.43201552': __('identifierSchemeCodeUnspsc190501.43201552'),
	'identifierSchemeCodeUnspsc190501.43201553': __('identifierSchemeCodeUnspsc190501.43201553'),
	'identifierSchemeCodeUnspsc190501.43201554': __('identifierSchemeCodeUnspsc190501.43201554'),
	'identifierSchemeCodeUnspsc190501.43201555': __('identifierSchemeCodeUnspsc190501.43201555'),
	'identifierSchemeCodeUnspsc190501.43201556': __('identifierSchemeCodeUnspsc190501.43201556'),
	'identifierSchemeCodeUnspsc190501.43201557': __('identifierSchemeCodeUnspsc190501.43201557'),
	'identifierSchemeCodeUnspsc190501.43201558': __('identifierSchemeCodeUnspsc190501.43201558'),
	'identifierSchemeCodeUnspsc190501.43201559': __('identifierSchemeCodeUnspsc190501.43201559'),
	'identifierSchemeCodeUnspsc190501.43201560': __('identifierSchemeCodeUnspsc190501.43201560'),
	'identifierSchemeCodeUnspsc190501.43201600': __('identifierSchemeCodeUnspsc190501.43201600'),
	'identifierSchemeCodeUnspsc190501.43201601': __('identifierSchemeCodeUnspsc190501.43201601'),
	'identifierSchemeCodeUnspsc190501.43201602': __('identifierSchemeCodeUnspsc190501.43201602'),
	'identifierSchemeCodeUnspsc190501.43201603': __('identifierSchemeCodeUnspsc190501.43201603'),
	'identifierSchemeCodeUnspsc190501.43201604': __('identifierSchemeCodeUnspsc190501.43201604'),
	'identifierSchemeCodeUnspsc190501.43201605': __('identifierSchemeCodeUnspsc190501.43201605'),
	'identifierSchemeCodeUnspsc190501.43201608': __('identifierSchemeCodeUnspsc190501.43201608'),
	'identifierSchemeCodeUnspsc190501.43201609': __('identifierSchemeCodeUnspsc190501.43201609'),
	'identifierSchemeCodeUnspsc190501.43201610': __('identifierSchemeCodeUnspsc190501.43201610'),
	'identifierSchemeCodeUnspsc190501.43201611': __('identifierSchemeCodeUnspsc190501.43201611'),
	'identifierSchemeCodeUnspsc190501.43201612': __('identifierSchemeCodeUnspsc190501.43201612'),
	'identifierSchemeCodeUnspsc190501.43201614': __('identifierSchemeCodeUnspsc190501.43201614'),
	'identifierSchemeCodeUnspsc190501.43201615': __('identifierSchemeCodeUnspsc190501.43201615'),
	'identifierSchemeCodeUnspsc190501.43201616': __('identifierSchemeCodeUnspsc190501.43201616'),
	'identifierSchemeCodeUnspsc190501.43201617': __('identifierSchemeCodeUnspsc190501.43201617'),
	'identifierSchemeCodeUnspsc190501.43201618': __('identifierSchemeCodeUnspsc190501.43201618'),
	'identifierSchemeCodeUnspsc190501.43201619': __('identifierSchemeCodeUnspsc190501.43201619'),
	'identifierSchemeCodeUnspsc190501.43201800': __('identifierSchemeCodeUnspsc190501.43201800'),
	'identifierSchemeCodeUnspsc190501.43201801': __('identifierSchemeCodeUnspsc190501.43201801'),
	'identifierSchemeCodeUnspsc190501.43201802': __('identifierSchemeCodeUnspsc190501.43201802'),
	'identifierSchemeCodeUnspsc190501.43201803': __('identifierSchemeCodeUnspsc190501.43201803'),
	'identifierSchemeCodeUnspsc190501.43201806': __('identifierSchemeCodeUnspsc190501.43201806'),
	'identifierSchemeCodeUnspsc190501.43201807': __('identifierSchemeCodeUnspsc190501.43201807'),
	'identifierSchemeCodeUnspsc190501.43201808': __('identifierSchemeCodeUnspsc190501.43201808'),
	'identifierSchemeCodeUnspsc190501.43201809': __('identifierSchemeCodeUnspsc190501.43201809'),
	'identifierSchemeCodeUnspsc190501.43201810': __('identifierSchemeCodeUnspsc190501.43201810'),
	'identifierSchemeCodeUnspsc190501.43201811': __('identifierSchemeCodeUnspsc190501.43201811'),
	'identifierSchemeCodeUnspsc190501.43201812': __('identifierSchemeCodeUnspsc190501.43201812'),
	'identifierSchemeCodeUnspsc190501.43201813': __('identifierSchemeCodeUnspsc190501.43201813'),
	'identifierSchemeCodeUnspsc190501.43201814': __('identifierSchemeCodeUnspsc190501.43201814'),
	'identifierSchemeCodeUnspsc190501.43201815': __('identifierSchemeCodeUnspsc190501.43201815'),
	'identifierSchemeCodeUnspsc190501.43201816': __('identifierSchemeCodeUnspsc190501.43201816'),
	'identifierSchemeCodeUnspsc190501.43201817': __('identifierSchemeCodeUnspsc190501.43201817'),
	'identifierSchemeCodeUnspsc190501.43201818': __('identifierSchemeCodeUnspsc190501.43201818'),
	'identifierSchemeCodeUnspsc190501.43201819': __('identifierSchemeCodeUnspsc190501.43201819'),
	'identifierSchemeCodeUnspsc190501.43201820': __('identifierSchemeCodeUnspsc190501.43201820'),
	'identifierSchemeCodeUnspsc190501.43201821': __('identifierSchemeCodeUnspsc190501.43201821'),
	'identifierSchemeCodeUnspsc190501.43201822': __('identifierSchemeCodeUnspsc190501.43201822'),
	'identifierSchemeCodeUnspsc190501.43201823': __('identifierSchemeCodeUnspsc190501.43201823'),
	'identifierSchemeCodeUnspsc190501.43201825': __('identifierSchemeCodeUnspsc190501.43201825'),
	'identifierSchemeCodeUnspsc190501.43201826': __('identifierSchemeCodeUnspsc190501.43201826'),
	'identifierSchemeCodeUnspsc190501.43201827': __('identifierSchemeCodeUnspsc190501.43201827'),
	'identifierSchemeCodeUnspsc190501.43201828': __('identifierSchemeCodeUnspsc190501.43201828'),
	'identifierSchemeCodeUnspsc190501.43201829': __('identifierSchemeCodeUnspsc190501.43201829'),
	'identifierSchemeCodeUnspsc190501.43201830': __('identifierSchemeCodeUnspsc190501.43201830'),
	'identifierSchemeCodeUnspsc190501.43201831': __('identifierSchemeCodeUnspsc190501.43201831'),
	'identifierSchemeCodeUnspsc190501.43201832': __('identifierSchemeCodeUnspsc190501.43201832'),
	'identifierSchemeCodeUnspsc190501.43201833': __('identifierSchemeCodeUnspsc190501.43201833'),
	'identifierSchemeCodeUnspsc190501.43201834': __('identifierSchemeCodeUnspsc190501.43201834'),
	'identifierSchemeCodeUnspsc190501.43201835': __('identifierSchemeCodeUnspsc190501.43201835'),
	'identifierSchemeCodeUnspsc190501.43201900': __('identifierSchemeCodeUnspsc190501.43201900'),
	'identifierSchemeCodeUnspsc190501.43201902': __('identifierSchemeCodeUnspsc190501.43201902'),
	'identifierSchemeCodeUnspsc190501.43201903': __('identifierSchemeCodeUnspsc190501.43201903'),
	'identifierSchemeCodeUnspsc190501.43201904': __('identifierSchemeCodeUnspsc190501.43201904'),
	'identifierSchemeCodeUnspsc190501.43201905': __('identifierSchemeCodeUnspsc190501.43201905'),
	'identifierSchemeCodeUnspsc190501.43202000': __('identifierSchemeCodeUnspsc190501.43202000'),
	'identifierSchemeCodeUnspsc190501.43202001': __('identifierSchemeCodeUnspsc190501.43202001'),
	'identifierSchemeCodeUnspsc190501.43202002': __('identifierSchemeCodeUnspsc190501.43202002'),
	'identifierSchemeCodeUnspsc190501.43202003': __('identifierSchemeCodeUnspsc190501.43202003'),
	'identifierSchemeCodeUnspsc190501.43202004': __('identifierSchemeCodeUnspsc190501.43202004'),
	'identifierSchemeCodeUnspsc190501.43202005': __('identifierSchemeCodeUnspsc190501.43202005'),
	'identifierSchemeCodeUnspsc190501.43202006': __('identifierSchemeCodeUnspsc190501.43202006'),
	'identifierSchemeCodeUnspsc190501.43202007': __('identifierSchemeCodeUnspsc190501.43202007'),
	'identifierSchemeCodeUnspsc190501.43202008': __('identifierSchemeCodeUnspsc190501.43202008'),
	'identifierSchemeCodeUnspsc190501.43202009': __('identifierSchemeCodeUnspsc190501.43202009'),
	'identifierSchemeCodeUnspsc190501.43202010': __('identifierSchemeCodeUnspsc190501.43202010'),
	'identifierSchemeCodeUnspsc190501.43202100': __('identifierSchemeCodeUnspsc190501.43202100'),
	'identifierSchemeCodeUnspsc190501.43202101': __('identifierSchemeCodeUnspsc190501.43202101'),
	'identifierSchemeCodeUnspsc190501.43202102': __('identifierSchemeCodeUnspsc190501.43202102'),
	'identifierSchemeCodeUnspsc190501.43202103': __('identifierSchemeCodeUnspsc190501.43202103'),
	'identifierSchemeCodeUnspsc190501.43202104': __('identifierSchemeCodeUnspsc190501.43202104'),
	'identifierSchemeCodeUnspsc190501.43202105': __('identifierSchemeCodeUnspsc190501.43202105'),
	'identifierSchemeCodeUnspsc190501.43202106': __('identifierSchemeCodeUnspsc190501.43202106'),
	'identifierSchemeCodeUnspsc190501.43202107': __('identifierSchemeCodeUnspsc190501.43202107'),
	'identifierSchemeCodeUnspsc190501.43202108': __('identifierSchemeCodeUnspsc190501.43202108'),
	'identifierSchemeCodeUnspsc190501.43202200': __('identifierSchemeCodeUnspsc190501.43202200'),
	'identifierSchemeCodeUnspsc190501.43202201': __('identifierSchemeCodeUnspsc190501.43202201'),
	'identifierSchemeCodeUnspsc190501.43202202': __('identifierSchemeCodeUnspsc190501.43202202'),
	'identifierSchemeCodeUnspsc190501.43202204': __('identifierSchemeCodeUnspsc190501.43202204'),
	'identifierSchemeCodeUnspsc190501.43202205': __('identifierSchemeCodeUnspsc190501.43202205'),
	'identifierSchemeCodeUnspsc190501.43202206': __('identifierSchemeCodeUnspsc190501.43202206'),
	'identifierSchemeCodeUnspsc190501.43202207': __('identifierSchemeCodeUnspsc190501.43202207'),
	'identifierSchemeCodeUnspsc190501.43202208': __('identifierSchemeCodeUnspsc190501.43202208'),
	'identifierSchemeCodeUnspsc190501.43202209': __('identifierSchemeCodeUnspsc190501.43202209'),
	'identifierSchemeCodeUnspsc190501.43202210': __('identifierSchemeCodeUnspsc190501.43202210'),
	'identifierSchemeCodeUnspsc190501.43202211': __('identifierSchemeCodeUnspsc190501.43202211'),
	'identifierSchemeCodeUnspsc190501.43202212': __('identifierSchemeCodeUnspsc190501.43202212'),
	'identifierSchemeCodeUnspsc190501.43202213': __('identifierSchemeCodeUnspsc190501.43202213'),
	'identifierSchemeCodeUnspsc190501.43202214': __('identifierSchemeCodeUnspsc190501.43202214'),
	'identifierSchemeCodeUnspsc190501.43202215': __('identifierSchemeCodeUnspsc190501.43202215'),
	'identifierSchemeCodeUnspsc190501.43202216': __('identifierSchemeCodeUnspsc190501.43202216'),
	'identifierSchemeCodeUnspsc190501.43202217': __('identifierSchemeCodeUnspsc190501.43202217'),
	'identifierSchemeCodeUnspsc190501.43202218': __('identifierSchemeCodeUnspsc190501.43202218'),
	'identifierSchemeCodeUnspsc190501.43202219': __('identifierSchemeCodeUnspsc190501.43202219'),
	'identifierSchemeCodeUnspsc190501.43202220': __('identifierSchemeCodeUnspsc190501.43202220'),
	'identifierSchemeCodeUnspsc190501.43202221': __('identifierSchemeCodeUnspsc190501.43202221'),
	'identifierSchemeCodeUnspsc190501.43202222': __('identifierSchemeCodeUnspsc190501.43202222'),
	'identifierSchemeCodeUnspsc190501.43210000': __('identifierSchemeCodeUnspsc190501.43210000'),
	'identifierSchemeCodeUnspsc190501.43211500': __('identifierSchemeCodeUnspsc190501.43211500'),
	'identifierSchemeCodeUnspsc190501.43211501': __('identifierSchemeCodeUnspsc190501.43211501'),
	'identifierSchemeCodeUnspsc190501.43211502': __('identifierSchemeCodeUnspsc190501.43211502'),
	'identifierSchemeCodeUnspsc190501.43211503': __('identifierSchemeCodeUnspsc190501.43211503'),
	'identifierSchemeCodeUnspsc190501.43211504': __('identifierSchemeCodeUnspsc190501.43211504'),
	'identifierSchemeCodeUnspsc190501.43211505': __('identifierSchemeCodeUnspsc190501.43211505'),
	'identifierSchemeCodeUnspsc190501.43211506': __('identifierSchemeCodeUnspsc190501.43211506'),
	'identifierSchemeCodeUnspsc190501.43211507': __('identifierSchemeCodeUnspsc190501.43211507'),
	'identifierSchemeCodeUnspsc190501.43211508': __('identifierSchemeCodeUnspsc190501.43211508'),
	'identifierSchemeCodeUnspsc190501.43211509': __('identifierSchemeCodeUnspsc190501.43211509'),
	'identifierSchemeCodeUnspsc190501.43211510': __('identifierSchemeCodeUnspsc190501.43211510'),
	'identifierSchemeCodeUnspsc190501.43211511': __('identifierSchemeCodeUnspsc190501.43211511'),
	'identifierSchemeCodeUnspsc190501.43211512': __('identifierSchemeCodeUnspsc190501.43211512'),
	'identifierSchemeCodeUnspsc190501.43211513': __('identifierSchemeCodeUnspsc190501.43211513'),
	'identifierSchemeCodeUnspsc190501.43211514': __('identifierSchemeCodeUnspsc190501.43211514'),
	'identifierSchemeCodeUnspsc190501.43211515': __('identifierSchemeCodeUnspsc190501.43211515'),
	'identifierSchemeCodeUnspsc190501.43211516': __('identifierSchemeCodeUnspsc190501.43211516'),
	'identifierSchemeCodeUnspsc190501.43211517': __('identifierSchemeCodeUnspsc190501.43211517'),
	'identifierSchemeCodeUnspsc190501.43211518': __('identifierSchemeCodeUnspsc190501.43211518'),
	'identifierSchemeCodeUnspsc190501.43211519': __('identifierSchemeCodeUnspsc190501.43211519'),
	'identifierSchemeCodeUnspsc190501.43211520': __('identifierSchemeCodeUnspsc190501.43211520'),
	'identifierSchemeCodeUnspsc190501.43211521': __('identifierSchemeCodeUnspsc190501.43211521'),
	'identifierSchemeCodeUnspsc190501.43211600': __('identifierSchemeCodeUnspsc190501.43211600'),
	'identifierSchemeCodeUnspsc190501.43211601': __('identifierSchemeCodeUnspsc190501.43211601'),
	'identifierSchemeCodeUnspsc190501.43211602': __('identifierSchemeCodeUnspsc190501.43211602'),
	'identifierSchemeCodeUnspsc190501.43211603': __('identifierSchemeCodeUnspsc190501.43211603'),
	'identifierSchemeCodeUnspsc190501.43211604': __('identifierSchemeCodeUnspsc190501.43211604'),
	'identifierSchemeCodeUnspsc190501.43211605': __('identifierSchemeCodeUnspsc190501.43211605'),
	'identifierSchemeCodeUnspsc190501.43211606': __('identifierSchemeCodeUnspsc190501.43211606'),
	'identifierSchemeCodeUnspsc190501.43211607': __('identifierSchemeCodeUnspsc190501.43211607'),
	'identifierSchemeCodeUnspsc190501.43211608': __('identifierSchemeCodeUnspsc190501.43211608'),
	'identifierSchemeCodeUnspsc190501.43211609': __('identifierSchemeCodeUnspsc190501.43211609'),
	'identifierSchemeCodeUnspsc190501.43211610': __('identifierSchemeCodeUnspsc190501.43211610'),
	'identifierSchemeCodeUnspsc190501.43211611': __('identifierSchemeCodeUnspsc190501.43211611'),
	'identifierSchemeCodeUnspsc190501.43211612': __('identifierSchemeCodeUnspsc190501.43211612'),
	'identifierSchemeCodeUnspsc190501.43211613': __('identifierSchemeCodeUnspsc190501.43211613'),
	'identifierSchemeCodeUnspsc190501.43211614': __('identifierSchemeCodeUnspsc190501.43211614'),
	'identifierSchemeCodeUnspsc190501.43211615': __('identifierSchemeCodeUnspsc190501.43211615'),
	'identifierSchemeCodeUnspsc190501.43211616': __('identifierSchemeCodeUnspsc190501.43211616'),
	'identifierSchemeCodeUnspsc190501.43211617': __('identifierSchemeCodeUnspsc190501.43211617'),
	'identifierSchemeCodeUnspsc190501.43211618': __('identifierSchemeCodeUnspsc190501.43211618'),
	'identifierSchemeCodeUnspsc190501.43211619': __('identifierSchemeCodeUnspsc190501.43211619'),
	'identifierSchemeCodeUnspsc190501.43211700': __('identifierSchemeCodeUnspsc190501.43211700'),
	'identifierSchemeCodeUnspsc190501.43211701': __('identifierSchemeCodeUnspsc190501.43211701'),
	'identifierSchemeCodeUnspsc190501.43211702': __('identifierSchemeCodeUnspsc190501.43211702'),
	'identifierSchemeCodeUnspsc190501.43211704': __('identifierSchemeCodeUnspsc190501.43211704'),
	'identifierSchemeCodeUnspsc190501.43211705': __('identifierSchemeCodeUnspsc190501.43211705'),
	'identifierSchemeCodeUnspsc190501.43211706': __('identifierSchemeCodeUnspsc190501.43211706'),
	'identifierSchemeCodeUnspsc190501.43211707': __('identifierSchemeCodeUnspsc190501.43211707'),
	'identifierSchemeCodeUnspsc190501.43211708': __('identifierSchemeCodeUnspsc190501.43211708'),
	'identifierSchemeCodeUnspsc190501.43211709': __('identifierSchemeCodeUnspsc190501.43211709'),
	'identifierSchemeCodeUnspsc190501.43211710': __('identifierSchemeCodeUnspsc190501.43211710'),
	'identifierSchemeCodeUnspsc190501.43211711': __('identifierSchemeCodeUnspsc190501.43211711'),
	'identifierSchemeCodeUnspsc190501.43211712': __('identifierSchemeCodeUnspsc190501.43211712'),
	'identifierSchemeCodeUnspsc190501.43211713': __('identifierSchemeCodeUnspsc190501.43211713'),
	'identifierSchemeCodeUnspsc190501.43211714': __('identifierSchemeCodeUnspsc190501.43211714'),
	'identifierSchemeCodeUnspsc190501.43211715': __('identifierSchemeCodeUnspsc190501.43211715'),
	'identifierSchemeCodeUnspsc190501.43211717': __('identifierSchemeCodeUnspsc190501.43211717'),
	'identifierSchemeCodeUnspsc190501.43211718': __('identifierSchemeCodeUnspsc190501.43211718'),
	'identifierSchemeCodeUnspsc190501.43211719': __('identifierSchemeCodeUnspsc190501.43211719'),
	'identifierSchemeCodeUnspsc190501.43211720': __('identifierSchemeCodeUnspsc190501.43211720'),
	'identifierSchemeCodeUnspsc190501.43211721': __('identifierSchemeCodeUnspsc190501.43211721'),
	'identifierSchemeCodeUnspsc190501.43211722': __('identifierSchemeCodeUnspsc190501.43211722'),
	'identifierSchemeCodeUnspsc190501.43211723': __('identifierSchemeCodeUnspsc190501.43211723'),
	'identifierSchemeCodeUnspsc190501.43211724': __('identifierSchemeCodeUnspsc190501.43211724'),
	'identifierSchemeCodeUnspsc190501.43211725': __('identifierSchemeCodeUnspsc190501.43211725'),
	'identifierSchemeCodeUnspsc190501.43211726': __('identifierSchemeCodeUnspsc190501.43211726'),
	'identifierSchemeCodeUnspsc190501.43211727': __('identifierSchemeCodeUnspsc190501.43211727'),
	'identifierSchemeCodeUnspsc190501.43211728': __('identifierSchemeCodeUnspsc190501.43211728'),
	'identifierSchemeCodeUnspsc190501.43211729': __('identifierSchemeCodeUnspsc190501.43211729'),
	'identifierSchemeCodeUnspsc190501.43211730': __('identifierSchemeCodeUnspsc190501.43211730'),
	'identifierSchemeCodeUnspsc190501.43211731': __('identifierSchemeCodeUnspsc190501.43211731'),
	'identifierSchemeCodeUnspsc190501.43211732': __('identifierSchemeCodeUnspsc190501.43211732'),
	'identifierSchemeCodeUnspsc190501.43211800': __('identifierSchemeCodeUnspsc190501.43211800'),
	'identifierSchemeCodeUnspsc190501.43211801': __('identifierSchemeCodeUnspsc190501.43211801'),
	'identifierSchemeCodeUnspsc190501.43211802': __('identifierSchemeCodeUnspsc190501.43211802'),
	'identifierSchemeCodeUnspsc190501.43211803': __('identifierSchemeCodeUnspsc190501.43211803'),
	'identifierSchemeCodeUnspsc190501.43211804': __('identifierSchemeCodeUnspsc190501.43211804'),
	'identifierSchemeCodeUnspsc190501.43211805': __('identifierSchemeCodeUnspsc190501.43211805'),
	'identifierSchemeCodeUnspsc190501.43211806': __('identifierSchemeCodeUnspsc190501.43211806'),
	'identifierSchemeCodeUnspsc190501.43211807': __('identifierSchemeCodeUnspsc190501.43211807'),
	'identifierSchemeCodeUnspsc190501.43211900': __('identifierSchemeCodeUnspsc190501.43211900'),
	'identifierSchemeCodeUnspsc190501.43211901': __('identifierSchemeCodeUnspsc190501.43211901'),
	'identifierSchemeCodeUnspsc190501.43211902': __('identifierSchemeCodeUnspsc190501.43211902'),
	'identifierSchemeCodeUnspsc190501.43211903': __('identifierSchemeCodeUnspsc190501.43211903'),
	'identifierSchemeCodeUnspsc190501.43211904': __('identifierSchemeCodeUnspsc190501.43211904'),
	'identifierSchemeCodeUnspsc190501.43211905': __('identifierSchemeCodeUnspsc190501.43211905'),
	'identifierSchemeCodeUnspsc190501.43211906': __('identifierSchemeCodeUnspsc190501.43211906'),
	'identifierSchemeCodeUnspsc190501.43211907': __('identifierSchemeCodeUnspsc190501.43211907'),
	'identifierSchemeCodeUnspsc190501.43211908': __('identifierSchemeCodeUnspsc190501.43211908'),
	'identifierSchemeCodeUnspsc190501.43211909': __('identifierSchemeCodeUnspsc190501.43211909'),
	'identifierSchemeCodeUnspsc190501.43211910': __('identifierSchemeCodeUnspsc190501.43211910'),
	'identifierSchemeCodeUnspsc190501.43211911': __('identifierSchemeCodeUnspsc190501.43211911'),
	'identifierSchemeCodeUnspsc190501.43211912': __('identifierSchemeCodeUnspsc190501.43211912'),
	'identifierSchemeCodeUnspsc190501.43211913': __('identifierSchemeCodeUnspsc190501.43211913'),
	'identifierSchemeCodeUnspsc190501.43211914': __('identifierSchemeCodeUnspsc190501.43211914'),
	'identifierSchemeCodeUnspsc190501.43212000': __('identifierSchemeCodeUnspsc190501.43212000'),
	'identifierSchemeCodeUnspsc190501.43212001': __('identifierSchemeCodeUnspsc190501.43212001'),
	'identifierSchemeCodeUnspsc190501.43212002': __('identifierSchemeCodeUnspsc190501.43212002'),
	'identifierSchemeCodeUnspsc190501.43212003': __('identifierSchemeCodeUnspsc190501.43212003'),
	'identifierSchemeCodeUnspsc190501.43212100': __('identifierSchemeCodeUnspsc190501.43212100'),
	'identifierSchemeCodeUnspsc190501.43212101': __('identifierSchemeCodeUnspsc190501.43212101'),
	'identifierSchemeCodeUnspsc190501.43212102': __('identifierSchemeCodeUnspsc190501.43212102'),
	'identifierSchemeCodeUnspsc190501.43212103': __('identifierSchemeCodeUnspsc190501.43212103'),
	'identifierSchemeCodeUnspsc190501.43212104': __('identifierSchemeCodeUnspsc190501.43212104'),
	'identifierSchemeCodeUnspsc190501.43212105': __('identifierSchemeCodeUnspsc190501.43212105'),
	'identifierSchemeCodeUnspsc190501.43212106': __('identifierSchemeCodeUnspsc190501.43212106'),
	'identifierSchemeCodeUnspsc190501.43212107': __('identifierSchemeCodeUnspsc190501.43212107'),
	'identifierSchemeCodeUnspsc190501.43212108': __('identifierSchemeCodeUnspsc190501.43212108'),
	'identifierSchemeCodeUnspsc190501.43212109': __('identifierSchemeCodeUnspsc190501.43212109'),
	'identifierSchemeCodeUnspsc190501.43212110': __('identifierSchemeCodeUnspsc190501.43212110'),
	'identifierSchemeCodeUnspsc190501.43212111': __('identifierSchemeCodeUnspsc190501.43212111'),
	'identifierSchemeCodeUnspsc190501.43212112': __('identifierSchemeCodeUnspsc190501.43212112'),
	'identifierSchemeCodeUnspsc190501.43212113': __('identifierSchemeCodeUnspsc190501.43212113'),
	'identifierSchemeCodeUnspsc190501.43212114': __('identifierSchemeCodeUnspsc190501.43212114'),
	'identifierSchemeCodeUnspsc190501.43212115': __('identifierSchemeCodeUnspsc190501.43212115'),
	'identifierSchemeCodeUnspsc190501.43212116': __('identifierSchemeCodeUnspsc190501.43212116'),
	'identifierSchemeCodeUnspsc190501.43212117': __('identifierSchemeCodeUnspsc190501.43212117'),
	'identifierSchemeCodeUnspsc190501.43212200': __('identifierSchemeCodeUnspsc190501.43212200'),
	'identifierSchemeCodeUnspsc190501.43212201': __('identifierSchemeCodeUnspsc190501.43212201'),
	'identifierSchemeCodeUnspsc190501.43220000': __('identifierSchemeCodeUnspsc190501.43220000'),
	'identifierSchemeCodeUnspsc190501.43221500': __('identifierSchemeCodeUnspsc190501.43221500'),
	'identifierSchemeCodeUnspsc190501.43221501': __('identifierSchemeCodeUnspsc190501.43221501'),
	'identifierSchemeCodeUnspsc190501.43221502': __('identifierSchemeCodeUnspsc190501.43221502'),
	'identifierSchemeCodeUnspsc190501.43221503': __('identifierSchemeCodeUnspsc190501.43221503'),
	'identifierSchemeCodeUnspsc190501.43221504': __('identifierSchemeCodeUnspsc190501.43221504'),
	'identifierSchemeCodeUnspsc190501.43221505': __('identifierSchemeCodeUnspsc190501.43221505'),
	'identifierSchemeCodeUnspsc190501.43221506': __('identifierSchemeCodeUnspsc190501.43221506'),
	'identifierSchemeCodeUnspsc190501.43221507': __('identifierSchemeCodeUnspsc190501.43221507'),
	'identifierSchemeCodeUnspsc190501.43221508': __('identifierSchemeCodeUnspsc190501.43221508'),
	'identifierSchemeCodeUnspsc190501.43221509': __('identifierSchemeCodeUnspsc190501.43221509'),
	'identifierSchemeCodeUnspsc190501.43221510': __('identifierSchemeCodeUnspsc190501.43221510'),
	'identifierSchemeCodeUnspsc190501.43221513': __('identifierSchemeCodeUnspsc190501.43221513'),
	'identifierSchemeCodeUnspsc190501.43221514': __('identifierSchemeCodeUnspsc190501.43221514'),
	'identifierSchemeCodeUnspsc190501.43221515': __('identifierSchemeCodeUnspsc190501.43221515'),
	'identifierSchemeCodeUnspsc190501.43221516': __('identifierSchemeCodeUnspsc190501.43221516'),
	'identifierSchemeCodeUnspsc190501.43221517': __('identifierSchemeCodeUnspsc190501.43221517'),
	'identifierSchemeCodeUnspsc190501.43221518': __('identifierSchemeCodeUnspsc190501.43221518'),
	'identifierSchemeCodeUnspsc190501.43221519': __('identifierSchemeCodeUnspsc190501.43221519'),
	'identifierSchemeCodeUnspsc190501.43221520': __('identifierSchemeCodeUnspsc190501.43221520'),
	'identifierSchemeCodeUnspsc190501.43221521': __('identifierSchemeCodeUnspsc190501.43221521'),
	'identifierSchemeCodeUnspsc190501.43221522': __('identifierSchemeCodeUnspsc190501.43221522'),
	'identifierSchemeCodeUnspsc190501.43221523': __('identifierSchemeCodeUnspsc190501.43221523'),
	'identifierSchemeCodeUnspsc190501.43221524': __('identifierSchemeCodeUnspsc190501.43221524'),
	'identifierSchemeCodeUnspsc190501.43221525': __('identifierSchemeCodeUnspsc190501.43221525'),
	'identifierSchemeCodeUnspsc190501.43221526': __('identifierSchemeCodeUnspsc190501.43221526'),
	'identifierSchemeCodeUnspsc190501.43221527': __('identifierSchemeCodeUnspsc190501.43221527'),
	'identifierSchemeCodeUnspsc190501.43221528': __('identifierSchemeCodeUnspsc190501.43221528'),
	'identifierSchemeCodeUnspsc190501.43221529': __('identifierSchemeCodeUnspsc190501.43221529'),
	'identifierSchemeCodeUnspsc190501.43221530': __('identifierSchemeCodeUnspsc190501.43221530'),
	'identifierSchemeCodeUnspsc190501.43221600': __('identifierSchemeCodeUnspsc190501.43221600'),
	'identifierSchemeCodeUnspsc190501.43221601': __('identifierSchemeCodeUnspsc190501.43221601'),
	'identifierSchemeCodeUnspsc190501.43221602': __('identifierSchemeCodeUnspsc190501.43221602'),
	'identifierSchemeCodeUnspsc190501.43221603': __('identifierSchemeCodeUnspsc190501.43221603'),
	'identifierSchemeCodeUnspsc190501.43221604': __('identifierSchemeCodeUnspsc190501.43221604'),
	'identifierSchemeCodeUnspsc190501.43221700': __('identifierSchemeCodeUnspsc190501.43221700'),
	'identifierSchemeCodeUnspsc190501.43221701': __('identifierSchemeCodeUnspsc190501.43221701'),
	'identifierSchemeCodeUnspsc190501.43221702': __('identifierSchemeCodeUnspsc190501.43221702'),
	'identifierSchemeCodeUnspsc190501.43221703': __('identifierSchemeCodeUnspsc190501.43221703'),
	'identifierSchemeCodeUnspsc190501.43221704': __('identifierSchemeCodeUnspsc190501.43221704'),
	'identifierSchemeCodeUnspsc190501.43221705': __('identifierSchemeCodeUnspsc190501.43221705'),
	'identifierSchemeCodeUnspsc190501.43221706': __('identifierSchemeCodeUnspsc190501.43221706'),
	'identifierSchemeCodeUnspsc190501.43221707': __('identifierSchemeCodeUnspsc190501.43221707'),
	'identifierSchemeCodeUnspsc190501.43221708': __('identifierSchemeCodeUnspsc190501.43221708'),
	'identifierSchemeCodeUnspsc190501.43221709': __('identifierSchemeCodeUnspsc190501.43221709'),
	'identifierSchemeCodeUnspsc190501.43221710': __('identifierSchemeCodeUnspsc190501.43221710'),
	'identifierSchemeCodeUnspsc190501.43221711': __('identifierSchemeCodeUnspsc190501.43221711'),
	'identifierSchemeCodeUnspsc190501.43221712': __('identifierSchemeCodeUnspsc190501.43221712'),
	'identifierSchemeCodeUnspsc190501.43221713': __('identifierSchemeCodeUnspsc190501.43221713'),
	'identifierSchemeCodeUnspsc190501.43221714': __('identifierSchemeCodeUnspsc190501.43221714'),
	'identifierSchemeCodeUnspsc190501.43221715': __('identifierSchemeCodeUnspsc190501.43221715'),
	'identifierSchemeCodeUnspsc190501.43221716': __('identifierSchemeCodeUnspsc190501.43221716'),
	'identifierSchemeCodeUnspsc190501.43221717': __('identifierSchemeCodeUnspsc190501.43221717'),
	'identifierSchemeCodeUnspsc190501.43221718': __('identifierSchemeCodeUnspsc190501.43221718'),
	'identifierSchemeCodeUnspsc190501.43221719': __('identifierSchemeCodeUnspsc190501.43221719'),
	'identifierSchemeCodeUnspsc190501.43221720': __('identifierSchemeCodeUnspsc190501.43221720'),
	'identifierSchemeCodeUnspsc190501.43221721': __('identifierSchemeCodeUnspsc190501.43221721'),
	'identifierSchemeCodeUnspsc190501.43221722': __('identifierSchemeCodeUnspsc190501.43221722'),
	'identifierSchemeCodeUnspsc190501.43221723': __('identifierSchemeCodeUnspsc190501.43221723'),
	'identifierSchemeCodeUnspsc190501.43221724': __('identifierSchemeCodeUnspsc190501.43221724'),
	'identifierSchemeCodeUnspsc190501.43221725': __('identifierSchemeCodeUnspsc190501.43221725'),
	'identifierSchemeCodeUnspsc190501.43221726': __('identifierSchemeCodeUnspsc190501.43221726'),
	'identifierSchemeCodeUnspsc190501.43221727': __('identifierSchemeCodeUnspsc190501.43221727'),
	'identifierSchemeCodeUnspsc190501.43221728': __('identifierSchemeCodeUnspsc190501.43221728'),
	'identifierSchemeCodeUnspsc190501.43221729': __('identifierSchemeCodeUnspsc190501.43221729'),
	'identifierSchemeCodeUnspsc190501.43221730': __('identifierSchemeCodeUnspsc190501.43221730'),
	'identifierSchemeCodeUnspsc190501.43221731': __('identifierSchemeCodeUnspsc190501.43221731'),
	'identifierSchemeCodeUnspsc190501.43221732': __('identifierSchemeCodeUnspsc190501.43221732'),
	'identifierSchemeCodeUnspsc190501.43221733': __('identifierSchemeCodeUnspsc190501.43221733'),
	'identifierSchemeCodeUnspsc190501.43221800': __('identifierSchemeCodeUnspsc190501.43221800'),
	'identifierSchemeCodeUnspsc190501.43221801': __('identifierSchemeCodeUnspsc190501.43221801'),
	'identifierSchemeCodeUnspsc190501.43221802': __('identifierSchemeCodeUnspsc190501.43221802'),
	'identifierSchemeCodeUnspsc190501.43221803': __('identifierSchemeCodeUnspsc190501.43221803'),
	'identifierSchemeCodeUnspsc190501.43221804': __('identifierSchemeCodeUnspsc190501.43221804'),
	'identifierSchemeCodeUnspsc190501.43221805': __('identifierSchemeCodeUnspsc190501.43221805'),
	'identifierSchemeCodeUnspsc190501.43221806': __('identifierSchemeCodeUnspsc190501.43221806'),
	'identifierSchemeCodeUnspsc190501.43221807': __('identifierSchemeCodeUnspsc190501.43221807'),
	'identifierSchemeCodeUnspsc190501.43221808': __('identifierSchemeCodeUnspsc190501.43221808'),
	'identifierSchemeCodeUnspsc190501.43221809': __('identifierSchemeCodeUnspsc190501.43221809'),
	'identifierSchemeCodeUnspsc190501.43221810': __('identifierSchemeCodeUnspsc190501.43221810'),
	'identifierSchemeCodeUnspsc190501.43221811': __('identifierSchemeCodeUnspsc190501.43221811'),
	'identifierSchemeCodeUnspsc190501.43222500': __('identifierSchemeCodeUnspsc190501.43222500'),
	'identifierSchemeCodeUnspsc190501.43222501': __('identifierSchemeCodeUnspsc190501.43222501'),
	'identifierSchemeCodeUnspsc190501.43222502': __('identifierSchemeCodeUnspsc190501.43222502'),
	'identifierSchemeCodeUnspsc190501.43222503': __('identifierSchemeCodeUnspsc190501.43222503'),
	'identifierSchemeCodeUnspsc190501.43222504': __('identifierSchemeCodeUnspsc190501.43222504'),
	'identifierSchemeCodeUnspsc190501.43222600': __('identifierSchemeCodeUnspsc190501.43222600'),
	'identifierSchemeCodeUnspsc190501.43222602': __('identifierSchemeCodeUnspsc190501.43222602'),
	'identifierSchemeCodeUnspsc190501.43222604': __('identifierSchemeCodeUnspsc190501.43222604'),
	'identifierSchemeCodeUnspsc190501.43222605': __('identifierSchemeCodeUnspsc190501.43222605'),
	'identifierSchemeCodeUnspsc190501.43222606': __('identifierSchemeCodeUnspsc190501.43222606'),
	'identifierSchemeCodeUnspsc190501.43222607': __('identifierSchemeCodeUnspsc190501.43222607'),
	'identifierSchemeCodeUnspsc190501.43222608': __('identifierSchemeCodeUnspsc190501.43222608'),
	'identifierSchemeCodeUnspsc190501.43222609': __('identifierSchemeCodeUnspsc190501.43222609'),
	'identifierSchemeCodeUnspsc190501.43222610': __('identifierSchemeCodeUnspsc190501.43222610'),
	'identifierSchemeCodeUnspsc190501.43222611': __('identifierSchemeCodeUnspsc190501.43222611'),
	'identifierSchemeCodeUnspsc190501.43222612': __('identifierSchemeCodeUnspsc190501.43222612'),
	'identifierSchemeCodeUnspsc190501.43222615': __('identifierSchemeCodeUnspsc190501.43222615'),
	'identifierSchemeCodeUnspsc190501.43222619': __('identifierSchemeCodeUnspsc190501.43222619'),
	'identifierSchemeCodeUnspsc190501.43222620': __('identifierSchemeCodeUnspsc190501.43222620'),
	'identifierSchemeCodeUnspsc190501.43222621': __('identifierSchemeCodeUnspsc190501.43222621'),
	'identifierSchemeCodeUnspsc190501.43222622': __('identifierSchemeCodeUnspsc190501.43222622'),
	'identifierSchemeCodeUnspsc190501.43222623': __('identifierSchemeCodeUnspsc190501.43222623'),
	'identifierSchemeCodeUnspsc190501.43222624': __('identifierSchemeCodeUnspsc190501.43222624'),
	'identifierSchemeCodeUnspsc190501.43222625': __('identifierSchemeCodeUnspsc190501.43222625'),
	'identifierSchemeCodeUnspsc190501.43222626': __('identifierSchemeCodeUnspsc190501.43222626'),
	'identifierSchemeCodeUnspsc190501.43222627': __('identifierSchemeCodeUnspsc190501.43222627'),
	'identifierSchemeCodeUnspsc190501.43222628': __('identifierSchemeCodeUnspsc190501.43222628'),
	'identifierSchemeCodeUnspsc190501.43222629': __('identifierSchemeCodeUnspsc190501.43222629'),
	'identifierSchemeCodeUnspsc190501.43222630': __('identifierSchemeCodeUnspsc190501.43222630'),
	'identifierSchemeCodeUnspsc190501.43222631': __('identifierSchemeCodeUnspsc190501.43222631'),
	'identifierSchemeCodeUnspsc190501.43222632': __('identifierSchemeCodeUnspsc190501.43222632'),
	'identifierSchemeCodeUnspsc190501.43222633': __('identifierSchemeCodeUnspsc190501.43222633'),
	'identifierSchemeCodeUnspsc190501.43222634': __('identifierSchemeCodeUnspsc190501.43222634'),
	'identifierSchemeCodeUnspsc190501.43222635': __('identifierSchemeCodeUnspsc190501.43222635'),
	'identifierSchemeCodeUnspsc190501.43222636': __('identifierSchemeCodeUnspsc190501.43222636'),
	'identifierSchemeCodeUnspsc190501.43222637': __('identifierSchemeCodeUnspsc190501.43222637'),
	'identifierSchemeCodeUnspsc190501.43222638': __('identifierSchemeCodeUnspsc190501.43222638'),
	'identifierSchemeCodeUnspsc190501.43222639': __('identifierSchemeCodeUnspsc190501.43222639'),
	'identifierSchemeCodeUnspsc190501.43222640': __('identifierSchemeCodeUnspsc190501.43222640'),
	'identifierSchemeCodeUnspsc190501.43222641': __('identifierSchemeCodeUnspsc190501.43222641'),
	'identifierSchemeCodeUnspsc190501.43222642': __('identifierSchemeCodeUnspsc190501.43222642'),
	'identifierSchemeCodeUnspsc190501.43222643': __('identifierSchemeCodeUnspsc190501.43222643'),
	'identifierSchemeCodeUnspsc190501.43222644': __('identifierSchemeCodeUnspsc190501.43222644'),
	'identifierSchemeCodeUnspsc190501.43222700': __('identifierSchemeCodeUnspsc190501.43222700'),
	'identifierSchemeCodeUnspsc190501.43222701': __('identifierSchemeCodeUnspsc190501.43222701'),
	'identifierSchemeCodeUnspsc190501.43222702': __('identifierSchemeCodeUnspsc190501.43222702'),
	'identifierSchemeCodeUnspsc190501.43222703': __('identifierSchemeCodeUnspsc190501.43222703'),
	'identifierSchemeCodeUnspsc190501.43222704': __('identifierSchemeCodeUnspsc190501.43222704'),
	'identifierSchemeCodeUnspsc190501.43222800': __('identifierSchemeCodeUnspsc190501.43222800'),
	'identifierSchemeCodeUnspsc190501.43222801': __('identifierSchemeCodeUnspsc190501.43222801'),
	'identifierSchemeCodeUnspsc190501.43222802': __('identifierSchemeCodeUnspsc190501.43222802'),
	'identifierSchemeCodeUnspsc190501.43222803': __('identifierSchemeCodeUnspsc190501.43222803'),
	'identifierSchemeCodeUnspsc190501.43222805': __('identifierSchemeCodeUnspsc190501.43222805'),
	'identifierSchemeCodeUnspsc190501.43222806': __('identifierSchemeCodeUnspsc190501.43222806'),
	'identifierSchemeCodeUnspsc190501.43222811': __('identifierSchemeCodeUnspsc190501.43222811'),
	'identifierSchemeCodeUnspsc190501.43222813': __('identifierSchemeCodeUnspsc190501.43222813'),
	'identifierSchemeCodeUnspsc190501.43222814': __('identifierSchemeCodeUnspsc190501.43222814'),
	'identifierSchemeCodeUnspsc190501.43222815': __('identifierSchemeCodeUnspsc190501.43222815'),
	'identifierSchemeCodeUnspsc190501.43222816': __('identifierSchemeCodeUnspsc190501.43222816'),
	'identifierSchemeCodeUnspsc190501.43222817': __('identifierSchemeCodeUnspsc190501.43222817'),
	'identifierSchemeCodeUnspsc190501.43222818': __('identifierSchemeCodeUnspsc190501.43222818'),
	'identifierSchemeCodeUnspsc190501.43222819': __('identifierSchemeCodeUnspsc190501.43222819'),
	'identifierSchemeCodeUnspsc190501.43222820': __('identifierSchemeCodeUnspsc190501.43222820'),
	'identifierSchemeCodeUnspsc190501.43222822': __('identifierSchemeCodeUnspsc190501.43222822'),
	'identifierSchemeCodeUnspsc190501.43222823': __('identifierSchemeCodeUnspsc190501.43222823'),
	'identifierSchemeCodeUnspsc190501.43222824': __('identifierSchemeCodeUnspsc190501.43222824'),
	'identifierSchemeCodeUnspsc190501.43222825': __('identifierSchemeCodeUnspsc190501.43222825'),
	'identifierSchemeCodeUnspsc190501.43222826': __('identifierSchemeCodeUnspsc190501.43222826'),
	'identifierSchemeCodeUnspsc190501.43222827': __('identifierSchemeCodeUnspsc190501.43222827'),
	'identifierSchemeCodeUnspsc190501.43222900': __('identifierSchemeCodeUnspsc190501.43222900'),
	'identifierSchemeCodeUnspsc190501.43222901': __('identifierSchemeCodeUnspsc190501.43222901'),
	'identifierSchemeCodeUnspsc190501.43222902': __('identifierSchemeCodeUnspsc190501.43222902'),
	'identifierSchemeCodeUnspsc190501.43222903': __('identifierSchemeCodeUnspsc190501.43222903'),
	'identifierSchemeCodeUnspsc190501.43223000': __('identifierSchemeCodeUnspsc190501.43223000'),
	'identifierSchemeCodeUnspsc190501.43223001': __('identifierSchemeCodeUnspsc190501.43223001'),
	'identifierSchemeCodeUnspsc190501.43223100': __('identifierSchemeCodeUnspsc190501.43223100'),
	'identifierSchemeCodeUnspsc190501.43223101': __('identifierSchemeCodeUnspsc190501.43223101'),
	'identifierSchemeCodeUnspsc190501.43223102': __('identifierSchemeCodeUnspsc190501.43223102'),
	'identifierSchemeCodeUnspsc190501.43223103': __('identifierSchemeCodeUnspsc190501.43223103'),
	'identifierSchemeCodeUnspsc190501.43223104': __('identifierSchemeCodeUnspsc190501.43223104'),
	'identifierSchemeCodeUnspsc190501.43223105': __('identifierSchemeCodeUnspsc190501.43223105'),
	'identifierSchemeCodeUnspsc190501.43223106': __('identifierSchemeCodeUnspsc190501.43223106'),
	'identifierSchemeCodeUnspsc190501.43223107': __('identifierSchemeCodeUnspsc190501.43223107'),
	'identifierSchemeCodeUnspsc190501.43223108': __('identifierSchemeCodeUnspsc190501.43223108'),
	'identifierSchemeCodeUnspsc190501.43223109': __('identifierSchemeCodeUnspsc190501.43223109'),
	'identifierSchemeCodeUnspsc190501.43223110': __('identifierSchemeCodeUnspsc190501.43223110'),
	'identifierSchemeCodeUnspsc190501.43223111': __('identifierSchemeCodeUnspsc190501.43223111'),
	'identifierSchemeCodeUnspsc190501.43223112': __('identifierSchemeCodeUnspsc190501.43223112'),
	'identifierSchemeCodeUnspsc190501.43223113': __('identifierSchemeCodeUnspsc190501.43223113'),
	'identifierSchemeCodeUnspsc190501.43223200': __('identifierSchemeCodeUnspsc190501.43223200'),
	'identifierSchemeCodeUnspsc190501.43223201': __('identifierSchemeCodeUnspsc190501.43223201'),
	'identifierSchemeCodeUnspsc190501.43223202': __('identifierSchemeCodeUnspsc190501.43223202'),
	'identifierSchemeCodeUnspsc190501.43223203': __('identifierSchemeCodeUnspsc190501.43223203'),
	'identifierSchemeCodeUnspsc190501.43223204': __('identifierSchemeCodeUnspsc190501.43223204'),
	'identifierSchemeCodeUnspsc190501.43223205': __('identifierSchemeCodeUnspsc190501.43223205'),
	'identifierSchemeCodeUnspsc190501.43223206': __('identifierSchemeCodeUnspsc190501.43223206'),
	'identifierSchemeCodeUnspsc190501.43223207': __('identifierSchemeCodeUnspsc190501.43223207'),
	'identifierSchemeCodeUnspsc190501.43223208': __('identifierSchemeCodeUnspsc190501.43223208'),
	'identifierSchemeCodeUnspsc190501.43223209': __('identifierSchemeCodeUnspsc190501.43223209'),
	'identifierSchemeCodeUnspsc190501.43223210': __('identifierSchemeCodeUnspsc190501.43223210'),
	'identifierSchemeCodeUnspsc190501.43223211': __('identifierSchemeCodeUnspsc190501.43223211'),
	'identifierSchemeCodeUnspsc190501.43223212': __('identifierSchemeCodeUnspsc190501.43223212'),
	'identifierSchemeCodeUnspsc190501.43223300': __('identifierSchemeCodeUnspsc190501.43223300'),
	'identifierSchemeCodeUnspsc190501.43223301': __('identifierSchemeCodeUnspsc190501.43223301'),
	'identifierSchemeCodeUnspsc190501.43223302': __('identifierSchemeCodeUnspsc190501.43223302'),
	'identifierSchemeCodeUnspsc190501.43223303': __('identifierSchemeCodeUnspsc190501.43223303'),
	'identifierSchemeCodeUnspsc190501.43223305': __('identifierSchemeCodeUnspsc190501.43223305'),
	'identifierSchemeCodeUnspsc190501.43223306': __('identifierSchemeCodeUnspsc190501.43223306'),
	'identifierSchemeCodeUnspsc190501.43223307': __('identifierSchemeCodeUnspsc190501.43223307'),
	'identifierSchemeCodeUnspsc190501.43223308': __('identifierSchemeCodeUnspsc190501.43223308'),
	'identifierSchemeCodeUnspsc190501.43223309': __('identifierSchemeCodeUnspsc190501.43223309'),
	'identifierSchemeCodeUnspsc190501.43223310': __('identifierSchemeCodeUnspsc190501.43223310'),
	'identifierSchemeCodeUnspsc190501.43223311': __('identifierSchemeCodeUnspsc190501.43223311'),
	'identifierSchemeCodeUnspsc190501.43223312': __('identifierSchemeCodeUnspsc190501.43223312'),
	'identifierSchemeCodeUnspsc190501.43223313': __('identifierSchemeCodeUnspsc190501.43223313'),
	'identifierSchemeCodeUnspsc190501.43223314': __('identifierSchemeCodeUnspsc190501.43223314'),
	'identifierSchemeCodeUnspsc190501.43223315': __('identifierSchemeCodeUnspsc190501.43223315'),
	'identifierSchemeCodeUnspsc190501.43223316': __('identifierSchemeCodeUnspsc190501.43223316'),
	'identifierSchemeCodeUnspsc190501.43223317': __('identifierSchemeCodeUnspsc190501.43223317'),
	'identifierSchemeCodeUnspsc190501.43223318': __('identifierSchemeCodeUnspsc190501.43223318'),
	'identifierSchemeCodeUnspsc190501.43223319': __('identifierSchemeCodeUnspsc190501.43223319'),
	'identifierSchemeCodeUnspsc190501.43223320': __('identifierSchemeCodeUnspsc190501.43223320'),
	'identifierSchemeCodeUnspsc190501.43223321': __('identifierSchemeCodeUnspsc190501.43223321'),
	'identifierSchemeCodeUnspsc190501.43223322': __('identifierSchemeCodeUnspsc190501.43223322'),
	'identifierSchemeCodeUnspsc190501.43223323': __('identifierSchemeCodeUnspsc190501.43223323'),
	'identifierSchemeCodeUnspsc190501.43223324': __('identifierSchemeCodeUnspsc190501.43223324'),
	'identifierSchemeCodeUnspsc190501.43223325': __('identifierSchemeCodeUnspsc190501.43223325'),
	'identifierSchemeCodeUnspsc190501.43223326': __('identifierSchemeCodeUnspsc190501.43223326'),
	'identifierSchemeCodeUnspsc190501.43223327': __('identifierSchemeCodeUnspsc190501.43223327'),
	'identifierSchemeCodeUnspsc190501.43223328': __('identifierSchemeCodeUnspsc190501.43223328'),
	'identifierSchemeCodeUnspsc190501.43223329': __('identifierSchemeCodeUnspsc190501.43223329'),
	'identifierSchemeCodeUnspsc190501.43223330': __('identifierSchemeCodeUnspsc190501.43223330'),
	'identifierSchemeCodeUnspsc190501.43223331': __('identifierSchemeCodeUnspsc190501.43223331'),
	'identifierSchemeCodeUnspsc190501.43223332': __('identifierSchemeCodeUnspsc190501.43223332'),
	'identifierSchemeCodeUnspsc190501.43223333': __('identifierSchemeCodeUnspsc190501.43223333'),
	'identifierSchemeCodeUnspsc190501.43223334': __('identifierSchemeCodeUnspsc190501.43223334'),
	'identifierSchemeCodeUnspsc190501.43223335': __('identifierSchemeCodeUnspsc190501.43223335'),
	'identifierSchemeCodeUnspsc190501.43223336': __('identifierSchemeCodeUnspsc190501.43223336'),
	'identifierSchemeCodeUnspsc190501.43223337': __('identifierSchemeCodeUnspsc190501.43223337'),
	'identifierSchemeCodeUnspsc190501.43223338': __('identifierSchemeCodeUnspsc190501.43223338'),
	'identifierSchemeCodeUnspsc190501.43223339': __('identifierSchemeCodeUnspsc190501.43223339'),
	'identifierSchemeCodeUnspsc190501.43223340': __('identifierSchemeCodeUnspsc190501.43223340'),
	'identifierSchemeCodeUnspsc190501.43223341': __('identifierSchemeCodeUnspsc190501.43223341'),
	'identifierSchemeCodeUnspsc190501.43223342': __('identifierSchemeCodeUnspsc190501.43223342'),
	'identifierSchemeCodeUnspsc190501.43223343': __('identifierSchemeCodeUnspsc190501.43223343'),
	'identifierSchemeCodeUnspsc190501.43230000': __('identifierSchemeCodeUnspsc190501.43230000'),
	'identifierSchemeCodeUnspsc190501.43231500': __('identifierSchemeCodeUnspsc190501.43231500'),
	'identifierSchemeCodeUnspsc190501.43231501': __('identifierSchemeCodeUnspsc190501.43231501'),
	'identifierSchemeCodeUnspsc190501.43231503': __('identifierSchemeCodeUnspsc190501.43231503'),
	'identifierSchemeCodeUnspsc190501.43231505': __('identifierSchemeCodeUnspsc190501.43231505'),
	'identifierSchemeCodeUnspsc190501.43231506': __('identifierSchemeCodeUnspsc190501.43231506'),
	'identifierSchemeCodeUnspsc190501.43231507': __('identifierSchemeCodeUnspsc190501.43231507'),
	'identifierSchemeCodeUnspsc190501.43231508': __('identifierSchemeCodeUnspsc190501.43231508'),
	'identifierSchemeCodeUnspsc190501.43231509': __('identifierSchemeCodeUnspsc190501.43231509'),
	'identifierSchemeCodeUnspsc190501.43231510': __('identifierSchemeCodeUnspsc190501.43231510'),
	'identifierSchemeCodeUnspsc190501.43231511': __('identifierSchemeCodeUnspsc190501.43231511'),
	'identifierSchemeCodeUnspsc190501.43231512': __('identifierSchemeCodeUnspsc190501.43231512'),
	'identifierSchemeCodeUnspsc190501.43231513': __('identifierSchemeCodeUnspsc190501.43231513'),
	'identifierSchemeCodeUnspsc190501.43231514': __('identifierSchemeCodeUnspsc190501.43231514'),
	'identifierSchemeCodeUnspsc190501.43231515': __('identifierSchemeCodeUnspsc190501.43231515'),
	'identifierSchemeCodeUnspsc190501.43231516': __('identifierSchemeCodeUnspsc190501.43231516'),
	'identifierSchemeCodeUnspsc190501.43231517': __('identifierSchemeCodeUnspsc190501.43231517'),
	'identifierSchemeCodeUnspsc190501.43231600': __('identifierSchemeCodeUnspsc190501.43231600'),
	'identifierSchemeCodeUnspsc190501.43231601': __('identifierSchemeCodeUnspsc190501.43231601'),
	'identifierSchemeCodeUnspsc190501.43231602': __('identifierSchemeCodeUnspsc190501.43231602'),
	'identifierSchemeCodeUnspsc190501.43231603': __('identifierSchemeCodeUnspsc190501.43231603'),
	'identifierSchemeCodeUnspsc190501.43231604': __('identifierSchemeCodeUnspsc190501.43231604'),
	'identifierSchemeCodeUnspsc190501.43231605': __('identifierSchemeCodeUnspsc190501.43231605'),
	'identifierSchemeCodeUnspsc190501.43232000': __('identifierSchemeCodeUnspsc190501.43232000'),
	'identifierSchemeCodeUnspsc190501.43232001': __('identifierSchemeCodeUnspsc190501.43232001'),
	'identifierSchemeCodeUnspsc190501.43232002': __('identifierSchemeCodeUnspsc190501.43232002'),
	'identifierSchemeCodeUnspsc190501.43232003': __('identifierSchemeCodeUnspsc190501.43232003'),
	'identifierSchemeCodeUnspsc190501.43232004': __('identifierSchemeCodeUnspsc190501.43232004'),
	'identifierSchemeCodeUnspsc190501.43232005': __('identifierSchemeCodeUnspsc190501.43232005'),
	'identifierSchemeCodeUnspsc190501.43232100': __('identifierSchemeCodeUnspsc190501.43232100'),
	'identifierSchemeCodeUnspsc190501.43232101': __('identifierSchemeCodeUnspsc190501.43232101'),
	'identifierSchemeCodeUnspsc190501.43232102': __('identifierSchemeCodeUnspsc190501.43232102'),
	'identifierSchemeCodeUnspsc190501.43232103': __('identifierSchemeCodeUnspsc190501.43232103'),
	'identifierSchemeCodeUnspsc190501.43232104': __('identifierSchemeCodeUnspsc190501.43232104'),
	'identifierSchemeCodeUnspsc190501.43232105': __('identifierSchemeCodeUnspsc190501.43232105'),
	'identifierSchemeCodeUnspsc190501.43232106': __('identifierSchemeCodeUnspsc190501.43232106'),
	'identifierSchemeCodeUnspsc190501.43232107': __('identifierSchemeCodeUnspsc190501.43232107'),
	'identifierSchemeCodeUnspsc190501.43232108': __('identifierSchemeCodeUnspsc190501.43232108'),
	'identifierSchemeCodeUnspsc190501.43232110': __('identifierSchemeCodeUnspsc190501.43232110'),
	'identifierSchemeCodeUnspsc190501.43232111': __('identifierSchemeCodeUnspsc190501.43232111'),
	'identifierSchemeCodeUnspsc190501.43232112': __('identifierSchemeCodeUnspsc190501.43232112'),
	'identifierSchemeCodeUnspsc190501.43232200': __('identifierSchemeCodeUnspsc190501.43232200'),
	'identifierSchemeCodeUnspsc190501.43232201': __('identifierSchemeCodeUnspsc190501.43232201'),
	'identifierSchemeCodeUnspsc190501.43232202': __('identifierSchemeCodeUnspsc190501.43232202'),
	'identifierSchemeCodeUnspsc190501.43232203': __('identifierSchemeCodeUnspsc190501.43232203'),
	'identifierSchemeCodeUnspsc190501.43232204': __('identifierSchemeCodeUnspsc190501.43232204'),
	'identifierSchemeCodeUnspsc190501.43232205': __('identifierSchemeCodeUnspsc190501.43232205'),
	'identifierSchemeCodeUnspsc190501.43232300': __('identifierSchemeCodeUnspsc190501.43232300'),
	'identifierSchemeCodeUnspsc190501.43232301': __('identifierSchemeCodeUnspsc190501.43232301'),
	'identifierSchemeCodeUnspsc190501.43232302': __('identifierSchemeCodeUnspsc190501.43232302'),
	'identifierSchemeCodeUnspsc190501.43232303': __('identifierSchemeCodeUnspsc190501.43232303'),
	'identifierSchemeCodeUnspsc190501.43232304': __('identifierSchemeCodeUnspsc190501.43232304'),
	'identifierSchemeCodeUnspsc190501.43232305': __('identifierSchemeCodeUnspsc190501.43232305'),
	'identifierSchemeCodeUnspsc190501.43232306': __('identifierSchemeCodeUnspsc190501.43232306'),
	'identifierSchemeCodeUnspsc190501.43232307': __('identifierSchemeCodeUnspsc190501.43232307'),
	'identifierSchemeCodeUnspsc190501.43232309': __('identifierSchemeCodeUnspsc190501.43232309'),
	'identifierSchemeCodeUnspsc190501.43232310': __('identifierSchemeCodeUnspsc190501.43232310'),
	'identifierSchemeCodeUnspsc190501.43232311': __('identifierSchemeCodeUnspsc190501.43232311'),
	'identifierSchemeCodeUnspsc190501.43232312': __('identifierSchemeCodeUnspsc190501.43232312'),
	'identifierSchemeCodeUnspsc190501.43232313': __('identifierSchemeCodeUnspsc190501.43232313'),
	'identifierSchemeCodeUnspsc190501.43232314': __('identifierSchemeCodeUnspsc190501.43232314'),
	'identifierSchemeCodeUnspsc190501.43232400': __('identifierSchemeCodeUnspsc190501.43232400'),
	'identifierSchemeCodeUnspsc190501.43232401': __('identifierSchemeCodeUnspsc190501.43232401'),
	'identifierSchemeCodeUnspsc190501.43232402': __('identifierSchemeCodeUnspsc190501.43232402'),
	'identifierSchemeCodeUnspsc190501.43232403': __('identifierSchemeCodeUnspsc190501.43232403'),
	'identifierSchemeCodeUnspsc190501.43232404': __('identifierSchemeCodeUnspsc190501.43232404'),
	'identifierSchemeCodeUnspsc190501.43232405': __('identifierSchemeCodeUnspsc190501.43232405'),
	'identifierSchemeCodeUnspsc190501.43232406': __('identifierSchemeCodeUnspsc190501.43232406'),
	'identifierSchemeCodeUnspsc190501.43232407': __('identifierSchemeCodeUnspsc190501.43232407'),
	'identifierSchemeCodeUnspsc190501.43232408': __('identifierSchemeCodeUnspsc190501.43232408'),
	'identifierSchemeCodeUnspsc190501.43232409': __('identifierSchemeCodeUnspsc190501.43232409'),
	'identifierSchemeCodeUnspsc190501.43232500': __('identifierSchemeCodeUnspsc190501.43232500'),
	'identifierSchemeCodeUnspsc190501.43232501': __('identifierSchemeCodeUnspsc190501.43232501'),
	'identifierSchemeCodeUnspsc190501.43232502': __('identifierSchemeCodeUnspsc190501.43232502'),
	'identifierSchemeCodeUnspsc190501.43232503': __('identifierSchemeCodeUnspsc190501.43232503'),
	'identifierSchemeCodeUnspsc190501.43232504': __('identifierSchemeCodeUnspsc190501.43232504'),
	'identifierSchemeCodeUnspsc190501.43232505': __('identifierSchemeCodeUnspsc190501.43232505'),
	'identifierSchemeCodeUnspsc190501.43232506': __('identifierSchemeCodeUnspsc190501.43232506'),
	'identifierSchemeCodeUnspsc190501.43232507': __('identifierSchemeCodeUnspsc190501.43232507'),
	'identifierSchemeCodeUnspsc190501.43232508': __('identifierSchemeCodeUnspsc190501.43232508'),
	'identifierSchemeCodeUnspsc190501.43232509': __('identifierSchemeCodeUnspsc190501.43232509'),
	'identifierSchemeCodeUnspsc190501.43232510': __('identifierSchemeCodeUnspsc190501.43232510'),
	'identifierSchemeCodeUnspsc190501.43232600': __('identifierSchemeCodeUnspsc190501.43232600'),
	'identifierSchemeCodeUnspsc190501.43232601': __('identifierSchemeCodeUnspsc190501.43232601'),
	'identifierSchemeCodeUnspsc190501.43232602': __('identifierSchemeCodeUnspsc190501.43232602'),
	'identifierSchemeCodeUnspsc190501.43232603': __('identifierSchemeCodeUnspsc190501.43232603'),
	'identifierSchemeCodeUnspsc190501.43232604': __('identifierSchemeCodeUnspsc190501.43232604'),
	'identifierSchemeCodeUnspsc190501.43232605': __('identifierSchemeCodeUnspsc190501.43232605'),
	'identifierSchemeCodeUnspsc190501.43232606': __('identifierSchemeCodeUnspsc190501.43232606'),
	'identifierSchemeCodeUnspsc190501.43232607': __('identifierSchemeCodeUnspsc190501.43232607'),
	'identifierSchemeCodeUnspsc190501.43232608': __('identifierSchemeCodeUnspsc190501.43232608'),
	'identifierSchemeCodeUnspsc190501.43232609': __('identifierSchemeCodeUnspsc190501.43232609'),
	'identifierSchemeCodeUnspsc190501.43232610': __('identifierSchemeCodeUnspsc190501.43232610'),
	'identifierSchemeCodeUnspsc190501.43232611': __('identifierSchemeCodeUnspsc190501.43232611'),
	'identifierSchemeCodeUnspsc190501.43232612': __('identifierSchemeCodeUnspsc190501.43232612'),
	'identifierSchemeCodeUnspsc190501.43232613': __('identifierSchemeCodeUnspsc190501.43232613'),
	'identifierSchemeCodeUnspsc190501.43232614': __('identifierSchemeCodeUnspsc190501.43232614'),
	'identifierSchemeCodeUnspsc190501.43232615': __('identifierSchemeCodeUnspsc190501.43232615'),
	'identifierSchemeCodeUnspsc190501.43232616': __('identifierSchemeCodeUnspsc190501.43232616'),
	'identifierSchemeCodeUnspsc190501.43232617': __('identifierSchemeCodeUnspsc190501.43232617'),
	'identifierSchemeCodeUnspsc190501.43232618': __('identifierSchemeCodeUnspsc190501.43232618'),
	'identifierSchemeCodeUnspsc190501.43232619': __('identifierSchemeCodeUnspsc190501.43232619'),
	'identifierSchemeCodeUnspsc190501.43232700': __('identifierSchemeCodeUnspsc190501.43232700'),
	'identifierSchemeCodeUnspsc190501.43232701': __('identifierSchemeCodeUnspsc190501.43232701'),
	'identifierSchemeCodeUnspsc190501.43232702': __('identifierSchemeCodeUnspsc190501.43232702'),
	'identifierSchemeCodeUnspsc190501.43232703': __('identifierSchemeCodeUnspsc190501.43232703'),
	'identifierSchemeCodeUnspsc190501.43232704': __('identifierSchemeCodeUnspsc190501.43232704'),
	'identifierSchemeCodeUnspsc190501.43232705': __('identifierSchemeCodeUnspsc190501.43232705'),
	'identifierSchemeCodeUnspsc190501.43232800': __('identifierSchemeCodeUnspsc190501.43232800'),
	'identifierSchemeCodeUnspsc190501.43232801': __('identifierSchemeCodeUnspsc190501.43232801'),
	'identifierSchemeCodeUnspsc190501.43232802': __('identifierSchemeCodeUnspsc190501.43232802'),
	'identifierSchemeCodeUnspsc190501.43232803': __('identifierSchemeCodeUnspsc190501.43232803'),
	'identifierSchemeCodeUnspsc190501.43232804': __('identifierSchemeCodeUnspsc190501.43232804'),
	'identifierSchemeCodeUnspsc190501.43232805': __('identifierSchemeCodeUnspsc190501.43232805'),
	'identifierSchemeCodeUnspsc190501.43232900': __('identifierSchemeCodeUnspsc190501.43232900'),
	'identifierSchemeCodeUnspsc190501.43232901': __('identifierSchemeCodeUnspsc190501.43232901'),
	'identifierSchemeCodeUnspsc190501.43232902': __('identifierSchemeCodeUnspsc190501.43232902'),
	'identifierSchemeCodeUnspsc190501.43232903': __('identifierSchemeCodeUnspsc190501.43232903'),
	'identifierSchemeCodeUnspsc190501.43232904': __('identifierSchemeCodeUnspsc190501.43232904'),
	'identifierSchemeCodeUnspsc190501.43232905': __('identifierSchemeCodeUnspsc190501.43232905'),
	'identifierSchemeCodeUnspsc190501.43232906': __('identifierSchemeCodeUnspsc190501.43232906'),
	'identifierSchemeCodeUnspsc190501.43232907': __('identifierSchemeCodeUnspsc190501.43232907'),
	'identifierSchemeCodeUnspsc190501.43232908': __('identifierSchemeCodeUnspsc190501.43232908'),
	'identifierSchemeCodeUnspsc190501.43232909': __('identifierSchemeCodeUnspsc190501.43232909'),
	'identifierSchemeCodeUnspsc190501.43232910': __('identifierSchemeCodeUnspsc190501.43232910'),
	'identifierSchemeCodeUnspsc190501.43232911': __('identifierSchemeCodeUnspsc190501.43232911'),
	'identifierSchemeCodeUnspsc190501.43232912': __('identifierSchemeCodeUnspsc190501.43232912'),
	'identifierSchemeCodeUnspsc190501.43232913': __('identifierSchemeCodeUnspsc190501.43232913'),
	'identifierSchemeCodeUnspsc190501.43232914': __('identifierSchemeCodeUnspsc190501.43232914'),
	'identifierSchemeCodeUnspsc190501.43232915': __('identifierSchemeCodeUnspsc190501.43232915'),
	'identifierSchemeCodeUnspsc190501.43232916': __('identifierSchemeCodeUnspsc190501.43232916'),
	'identifierSchemeCodeUnspsc190501.43233000': __('identifierSchemeCodeUnspsc190501.43233000'),
	'identifierSchemeCodeUnspsc190501.43233001': __('identifierSchemeCodeUnspsc190501.43233001'),
	'identifierSchemeCodeUnspsc190501.43233002': __('identifierSchemeCodeUnspsc190501.43233002'),
	'identifierSchemeCodeUnspsc190501.43233004': __('identifierSchemeCodeUnspsc190501.43233004'),
	'identifierSchemeCodeUnspsc190501.43233005': __('identifierSchemeCodeUnspsc190501.43233005'),
	'identifierSchemeCodeUnspsc190501.43233006': __('identifierSchemeCodeUnspsc190501.43233006'),
	'identifierSchemeCodeUnspsc190501.43233007': __('identifierSchemeCodeUnspsc190501.43233007'),
	'identifierSchemeCodeUnspsc190501.43233200': __('identifierSchemeCodeUnspsc190501.43233200'),
	'identifierSchemeCodeUnspsc190501.43233201': __('identifierSchemeCodeUnspsc190501.43233201'),
	'identifierSchemeCodeUnspsc190501.43233203': __('identifierSchemeCodeUnspsc190501.43233203'),
	'identifierSchemeCodeUnspsc190501.43233204': __('identifierSchemeCodeUnspsc190501.43233204'),
	'identifierSchemeCodeUnspsc190501.43233205': __('identifierSchemeCodeUnspsc190501.43233205'),
	'identifierSchemeCodeUnspsc190501.43233400': __('identifierSchemeCodeUnspsc190501.43233400'),
	'identifierSchemeCodeUnspsc190501.43233401': __('identifierSchemeCodeUnspsc190501.43233401'),
	'identifierSchemeCodeUnspsc190501.43233402': __('identifierSchemeCodeUnspsc190501.43233402'),
	'identifierSchemeCodeUnspsc190501.43233403': __('identifierSchemeCodeUnspsc190501.43233403'),
	'identifierSchemeCodeUnspsc190501.43233404': __('identifierSchemeCodeUnspsc190501.43233404'),
	'identifierSchemeCodeUnspsc190501.43233405': __('identifierSchemeCodeUnspsc190501.43233405'),
	'identifierSchemeCodeUnspsc190501.43233406': __('identifierSchemeCodeUnspsc190501.43233406'),
	'identifierSchemeCodeUnspsc190501.43233407': __('identifierSchemeCodeUnspsc190501.43233407'),
	'identifierSchemeCodeUnspsc190501.43233410': __('identifierSchemeCodeUnspsc190501.43233410'),
	'identifierSchemeCodeUnspsc190501.43233411': __('identifierSchemeCodeUnspsc190501.43233411'),
	'identifierSchemeCodeUnspsc190501.43233413': __('identifierSchemeCodeUnspsc190501.43233413'),
	'identifierSchemeCodeUnspsc190501.43233414': __('identifierSchemeCodeUnspsc190501.43233414'),
	'identifierSchemeCodeUnspsc190501.43233415': __('identifierSchemeCodeUnspsc190501.43233415'),
	'identifierSchemeCodeUnspsc190501.43233416': __('identifierSchemeCodeUnspsc190501.43233416'),
	'identifierSchemeCodeUnspsc190501.43233417': __('identifierSchemeCodeUnspsc190501.43233417'),
	'identifierSchemeCodeUnspsc190501.43233418': __('identifierSchemeCodeUnspsc190501.43233418'),
	'identifierSchemeCodeUnspsc190501.43233419': __('identifierSchemeCodeUnspsc190501.43233419'),
	'identifierSchemeCodeUnspsc190501.43233420': __('identifierSchemeCodeUnspsc190501.43233420'),
	'identifierSchemeCodeUnspsc190501.43233421': __('identifierSchemeCodeUnspsc190501.43233421'),
	'identifierSchemeCodeUnspsc190501.43233500': __('identifierSchemeCodeUnspsc190501.43233500'),
	'identifierSchemeCodeUnspsc190501.43233501': __('identifierSchemeCodeUnspsc190501.43233501'),
	'identifierSchemeCodeUnspsc190501.43233502': __('identifierSchemeCodeUnspsc190501.43233502'),
	'identifierSchemeCodeUnspsc190501.43233503': __('identifierSchemeCodeUnspsc190501.43233503'),
	'identifierSchemeCodeUnspsc190501.43233504': __('identifierSchemeCodeUnspsc190501.43233504'),
	'identifierSchemeCodeUnspsc190501.43233505': __('identifierSchemeCodeUnspsc190501.43233505'),
	'identifierSchemeCodeUnspsc190501.43233506': __('identifierSchemeCodeUnspsc190501.43233506'),
	'identifierSchemeCodeUnspsc190501.43233507': __('identifierSchemeCodeUnspsc190501.43233507'),
	'identifierSchemeCodeUnspsc190501.43233508': __('identifierSchemeCodeUnspsc190501.43233508'),
	'identifierSchemeCodeUnspsc190501.43233509': __('identifierSchemeCodeUnspsc190501.43233509'),
	'identifierSchemeCodeUnspsc190501.43233510': __('identifierSchemeCodeUnspsc190501.43233510'),
	'identifierSchemeCodeUnspsc190501.43233511': __('identifierSchemeCodeUnspsc190501.43233511'),
	'identifierSchemeCodeUnspsc190501.43233512': __('identifierSchemeCodeUnspsc190501.43233512'),
	'identifierSchemeCodeUnspsc190501.43233600': __('identifierSchemeCodeUnspsc190501.43233600'),
	'identifierSchemeCodeUnspsc190501.43233601': __('identifierSchemeCodeUnspsc190501.43233601'),
	'identifierSchemeCodeUnspsc190501.43233602': __('identifierSchemeCodeUnspsc190501.43233602'),
	'identifierSchemeCodeUnspsc190501.43233603': __('identifierSchemeCodeUnspsc190501.43233603'),
	'identifierSchemeCodeUnspsc190501.43233700': __('identifierSchemeCodeUnspsc190501.43233700'),
	'identifierSchemeCodeUnspsc190501.43233701': __('identifierSchemeCodeUnspsc190501.43233701'),
	'identifierSchemeCodeUnspsc190501.44000000': __('identifierSchemeCodeUnspsc190501.44000000'),
	'identifierSchemeCodeUnspsc190501.44100000': __('identifierSchemeCodeUnspsc190501.44100000'),
	'identifierSchemeCodeUnspsc190501.44101500': __('identifierSchemeCodeUnspsc190501.44101500'),
	'identifierSchemeCodeUnspsc190501.44101501': __('identifierSchemeCodeUnspsc190501.44101501'),
	'identifierSchemeCodeUnspsc190501.44101503': __('identifierSchemeCodeUnspsc190501.44101503'),
	'identifierSchemeCodeUnspsc190501.44101504': __('identifierSchemeCodeUnspsc190501.44101504'),
	'identifierSchemeCodeUnspsc190501.44101505': __('identifierSchemeCodeUnspsc190501.44101505'),
	'identifierSchemeCodeUnspsc190501.44101506': __('identifierSchemeCodeUnspsc190501.44101506'),
	'identifierSchemeCodeUnspsc190501.44101507': __('identifierSchemeCodeUnspsc190501.44101507'),
	'identifierSchemeCodeUnspsc190501.44101508': __('identifierSchemeCodeUnspsc190501.44101508'),
	'identifierSchemeCodeUnspsc190501.44101509': __('identifierSchemeCodeUnspsc190501.44101509'),
	'identifierSchemeCodeUnspsc190501.44101510': __('identifierSchemeCodeUnspsc190501.44101510'),
	'identifierSchemeCodeUnspsc190501.44101511': __('identifierSchemeCodeUnspsc190501.44101511'),
	'identifierSchemeCodeUnspsc190501.44101600': __('identifierSchemeCodeUnspsc190501.44101600'),
	'identifierSchemeCodeUnspsc190501.44101601': __('identifierSchemeCodeUnspsc190501.44101601'),
	'identifierSchemeCodeUnspsc190501.44101602': __('identifierSchemeCodeUnspsc190501.44101602'),
	'identifierSchemeCodeUnspsc190501.44101603': __('identifierSchemeCodeUnspsc190501.44101603'),
	'identifierSchemeCodeUnspsc190501.44101604': __('identifierSchemeCodeUnspsc190501.44101604'),
	'identifierSchemeCodeUnspsc190501.44101605': __('identifierSchemeCodeUnspsc190501.44101605'),
	'identifierSchemeCodeUnspsc190501.44101606': __('identifierSchemeCodeUnspsc190501.44101606'),
	'identifierSchemeCodeUnspsc190501.44101607': __('identifierSchemeCodeUnspsc190501.44101607'),
	'identifierSchemeCodeUnspsc190501.44101608': __('identifierSchemeCodeUnspsc190501.44101608'),
	'identifierSchemeCodeUnspsc190501.44101700': __('identifierSchemeCodeUnspsc190501.44101700'),
	'identifierSchemeCodeUnspsc190501.44101701': __('identifierSchemeCodeUnspsc190501.44101701'),
	'identifierSchemeCodeUnspsc190501.44101702': __('identifierSchemeCodeUnspsc190501.44101702'),
	'identifierSchemeCodeUnspsc190501.44101703': __('identifierSchemeCodeUnspsc190501.44101703'),
	'identifierSchemeCodeUnspsc190501.44101704': __('identifierSchemeCodeUnspsc190501.44101704'),
	'identifierSchemeCodeUnspsc190501.44101705': __('identifierSchemeCodeUnspsc190501.44101705'),
	'identifierSchemeCodeUnspsc190501.44101706': __('identifierSchemeCodeUnspsc190501.44101706'),
	'identifierSchemeCodeUnspsc190501.44101707': __('identifierSchemeCodeUnspsc190501.44101707'),
	'identifierSchemeCodeUnspsc190501.44101708': __('identifierSchemeCodeUnspsc190501.44101708'),
	'identifierSchemeCodeUnspsc190501.44101709': __('identifierSchemeCodeUnspsc190501.44101709'),
	'identifierSchemeCodeUnspsc190501.44101710': __('identifierSchemeCodeUnspsc190501.44101710'),
	'identifierSchemeCodeUnspsc190501.44101711': __('identifierSchemeCodeUnspsc190501.44101711'),
	'identifierSchemeCodeUnspsc190501.44101712': __('identifierSchemeCodeUnspsc190501.44101712'),
	'identifierSchemeCodeUnspsc190501.44101713': __('identifierSchemeCodeUnspsc190501.44101713'),
	'identifierSchemeCodeUnspsc190501.44101714': __('identifierSchemeCodeUnspsc190501.44101714'),
	'identifierSchemeCodeUnspsc190501.44101715': __('identifierSchemeCodeUnspsc190501.44101715'),
	'identifierSchemeCodeUnspsc190501.44101716': __('identifierSchemeCodeUnspsc190501.44101716'),
	'identifierSchemeCodeUnspsc190501.44101718': __('identifierSchemeCodeUnspsc190501.44101718'),
	'identifierSchemeCodeUnspsc190501.44101719': __('identifierSchemeCodeUnspsc190501.44101719'),
	'identifierSchemeCodeUnspsc190501.44101720': __('identifierSchemeCodeUnspsc190501.44101720'),
	'identifierSchemeCodeUnspsc190501.44101721': __('identifierSchemeCodeUnspsc190501.44101721'),
	'identifierSchemeCodeUnspsc190501.44101722': __('identifierSchemeCodeUnspsc190501.44101722'),
	'identifierSchemeCodeUnspsc190501.44101723': __('identifierSchemeCodeUnspsc190501.44101723'),
	'identifierSchemeCodeUnspsc190501.44101724': __('identifierSchemeCodeUnspsc190501.44101724'),
	'identifierSchemeCodeUnspsc190501.44101725': __('identifierSchemeCodeUnspsc190501.44101725'),
	'identifierSchemeCodeUnspsc190501.44101726': __('identifierSchemeCodeUnspsc190501.44101726'),
	'identifierSchemeCodeUnspsc190501.44101727': __('identifierSchemeCodeUnspsc190501.44101727'),
	'identifierSchemeCodeUnspsc190501.44101728': __('identifierSchemeCodeUnspsc190501.44101728'),
	'identifierSchemeCodeUnspsc190501.44101729': __('identifierSchemeCodeUnspsc190501.44101729'),
	'identifierSchemeCodeUnspsc190501.44101730': __('identifierSchemeCodeUnspsc190501.44101730'),
	'identifierSchemeCodeUnspsc190501.44101800': __('identifierSchemeCodeUnspsc190501.44101800'),
	'identifierSchemeCodeUnspsc190501.44101802': __('identifierSchemeCodeUnspsc190501.44101802'),
	'identifierSchemeCodeUnspsc190501.44101803': __('identifierSchemeCodeUnspsc190501.44101803'),
	'identifierSchemeCodeUnspsc190501.44101804': __('identifierSchemeCodeUnspsc190501.44101804'),
	'identifierSchemeCodeUnspsc190501.44101805': __('identifierSchemeCodeUnspsc190501.44101805'),
	'identifierSchemeCodeUnspsc190501.44101806': __('identifierSchemeCodeUnspsc190501.44101806'),
	'identifierSchemeCodeUnspsc190501.44101807': __('identifierSchemeCodeUnspsc190501.44101807'),
	'identifierSchemeCodeUnspsc190501.44101808': __('identifierSchemeCodeUnspsc190501.44101808'),
	'identifierSchemeCodeUnspsc190501.44101809': __('identifierSchemeCodeUnspsc190501.44101809'),
	'identifierSchemeCodeUnspsc190501.44101810': __('identifierSchemeCodeUnspsc190501.44101810'),
	'identifierSchemeCodeUnspsc190501.44101900': __('identifierSchemeCodeUnspsc190501.44101900'),
	'identifierSchemeCodeUnspsc190501.44101901': __('identifierSchemeCodeUnspsc190501.44101901'),
	'identifierSchemeCodeUnspsc190501.44101902': __('identifierSchemeCodeUnspsc190501.44101902'),
	'identifierSchemeCodeUnspsc190501.44101903': __('identifierSchemeCodeUnspsc190501.44101903'),
	'identifierSchemeCodeUnspsc190501.44101904': __('identifierSchemeCodeUnspsc190501.44101904'),
	'identifierSchemeCodeUnspsc190501.44102000': __('identifierSchemeCodeUnspsc190501.44102000'),
	'identifierSchemeCodeUnspsc190501.44102001': __('identifierSchemeCodeUnspsc190501.44102001'),
	'identifierSchemeCodeUnspsc190501.44102002': __('identifierSchemeCodeUnspsc190501.44102002'),
	'identifierSchemeCodeUnspsc190501.44102003': __('identifierSchemeCodeUnspsc190501.44102003'),
	'identifierSchemeCodeUnspsc190501.44102004': __('identifierSchemeCodeUnspsc190501.44102004'),
	'identifierSchemeCodeUnspsc190501.44102100': __('identifierSchemeCodeUnspsc190501.44102100'),
	'identifierSchemeCodeUnspsc190501.44102101': __('identifierSchemeCodeUnspsc190501.44102101'),
	'identifierSchemeCodeUnspsc190501.44102102': __('identifierSchemeCodeUnspsc190501.44102102'),
	'identifierSchemeCodeUnspsc190501.44102103': __('identifierSchemeCodeUnspsc190501.44102103'),
	'identifierSchemeCodeUnspsc190501.44102104': __('identifierSchemeCodeUnspsc190501.44102104'),
	'identifierSchemeCodeUnspsc190501.44102105': __('identifierSchemeCodeUnspsc190501.44102105'),
	'identifierSchemeCodeUnspsc190501.44102106': __('identifierSchemeCodeUnspsc190501.44102106'),
	'identifierSchemeCodeUnspsc190501.44102107': __('identifierSchemeCodeUnspsc190501.44102107'),
	'identifierSchemeCodeUnspsc190501.44102108': __('identifierSchemeCodeUnspsc190501.44102108'),
	'identifierSchemeCodeUnspsc190501.44102109': __('identifierSchemeCodeUnspsc190501.44102109'),
	'identifierSchemeCodeUnspsc190501.44102200': __('identifierSchemeCodeUnspsc190501.44102200'),
	'identifierSchemeCodeUnspsc190501.44102201': __('identifierSchemeCodeUnspsc190501.44102201'),
	'identifierSchemeCodeUnspsc190501.44102202': __('identifierSchemeCodeUnspsc190501.44102202'),
	'identifierSchemeCodeUnspsc190501.44102203': __('identifierSchemeCodeUnspsc190501.44102203'),
	'identifierSchemeCodeUnspsc190501.44102300': __('identifierSchemeCodeUnspsc190501.44102300'),
	'identifierSchemeCodeUnspsc190501.44102301': __('identifierSchemeCodeUnspsc190501.44102301'),
	'identifierSchemeCodeUnspsc190501.44102302': __('identifierSchemeCodeUnspsc190501.44102302'),
	'identifierSchemeCodeUnspsc190501.44102303': __('identifierSchemeCodeUnspsc190501.44102303'),
	'identifierSchemeCodeUnspsc190501.44102304': __('identifierSchemeCodeUnspsc190501.44102304'),
	'identifierSchemeCodeUnspsc190501.44102305': __('identifierSchemeCodeUnspsc190501.44102305'),
	'identifierSchemeCodeUnspsc190501.44102306': __('identifierSchemeCodeUnspsc190501.44102306'),
	'identifierSchemeCodeUnspsc190501.44102307': __('identifierSchemeCodeUnspsc190501.44102307'),
	'identifierSchemeCodeUnspsc190501.44102400': __('identifierSchemeCodeUnspsc190501.44102400'),
	'identifierSchemeCodeUnspsc190501.44102402': __('identifierSchemeCodeUnspsc190501.44102402'),
	'identifierSchemeCodeUnspsc190501.44102403': __('identifierSchemeCodeUnspsc190501.44102403'),
	'identifierSchemeCodeUnspsc190501.44102404': __('identifierSchemeCodeUnspsc190501.44102404'),
	'identifierSchemeCodeUnspsc190501.44102405': __('identifierSchemeCodeUnspsc190501.44102405'),
	'identifierSchemeCodeUnspsc190501.44102406': __('identifierSchemeCodeUnspsc190501.44102406'),
	'identifierSchemeCodeUnspsc190501.44102407': __('identifierSchemeCodeUnspsc190501.44102407'),
	'identifierSchemeCodeUnspsc190501.44102408': __('identifierSchemeCodeUnspsc190501.44102408'),
	'identifierSchemeCodeUnspsc190501.44102409': __('identifierSchemeCodeUnspsc190501.44102409'),
	'identifierSchemeCodeUnspsc190501.44102411': __('identifierSchemeCodeUnspsc190501.44102411'),
	'identifierSchemeCodeUnspsc190501.44102412': __('identifierSchemeCodeUnspsc190501.44102412'),
	'identifierSchemeCodeUnspsc190501.44102413': __('identifierSchemeCodeUnspsc190501.44102413'),
	'identifierSchemeCodeUnspsc190501.44102414': __('identifierSchemeCodeUnspsc190501.44102414'),
	'identifierSchemeCodeUnspsc190501.44102500': __('identifierSchemeCodeUnspsc190501.44102500'),
	'identifierSchemeCodeUnspsc190501.44102501': __('identifierSchemeCodeUnspsc190501.44102501'),
	'identifierSchemeCodeUnspsc190501.44102502': __('identifierSchemeCodeUnspsc190501.44102502'),
	'identifierSchemeCodeUnspsc190501.44102503': __('identifierSchemeCodeUnspsc190501.44102503'),
	'identifierSchemeCodeUnspsc190501.44102504': __('identifierSchemeCodeUnspsc190501.44102504'),
	'identifierSchemeCodeUnspsc190501.44102600': __('identifierSchemeCodeUnspsc190501.44102600'),
	'identifierSchemeCodeUnspsc190501.44102602': __('identifierSchemeCodeUnspsc190501.44102602'),
	'identifierSchemeCodeUnspsc190501.44102603': __('identifierSchemeCodeUnspsc190501.44102603'),
	'identifierSchemeCodeUnspsc190501.44102604': __('identifierSchemeCodeUnspsc190501.44102604'),
	'identifierSchemeCodeUnspsc190501.44102605': __('identifierSchemeCodeUnspsc190501.44102605'),
	'identifierSchemeCodeUnspsc190501.44102606': __('identifierSchemeCodeUnspsc190501.44102606'),
	'identifierSchemeCodeUnspsc190501.44102607': __('identifierSchemeCodeUnspsc190501.44102607'),
	'identifierSchemeCodeUnspsc190501.44102608': __('identifierSchemeCodeUnspsc190501.44102608'),
	'identifierSchemeCodeUnspsc190501.44102609': __('identifierSchemeCodeUnspsc190501.44102609'),
	'identifierSchemeCodeUnspsc190501.44102610': __('identifierSchemeCodeUnspsc190501.44102610'),
	'identifierSchemeCodeUnspsc190501.44102800': __('identifierSchemeCodeUnspsc190501.44102800'),
	'identifierSchemeCodeUnspsc190501.44102801': __('identifierSchemeCodeUnspsc190501.44102801'),
	'identifierSchemeCodeUnspsc190501.44102802': __('identifierSchemeCodeUnspsc190501.44102802'),
	'identifierSchemeCodeUnspsc190501.44102803': __('identifierSchemeCodeUnspsc190501.44102803'),
	'identifierSchemeCodeUnspsc190501.44102804': __('identifierSchemeCodeUnspsc190501.44102804'),
	'identifierSchemeCodeUnspsc190501.44102805': __('identifierSchemeCodeUnspsc190501.44102805'),
	'identifierSchemeCodeUnspsc190501.44102806': __('identifierSchemeCodeUnspsc190501.44102806'),
	'identifierSchemeCodeUnspsc190501.44102900': __('identifierSchemeCodeUnspsc190501.44102900'),
	'identifierSchemeCodeUnspsc190501.44102901': __('identifierSchemeCodeUnspsc190501.44102901'),
	'identifierSchemeCodeUnspsc190501.44102902': __('identifierSchemeCodeUnspsc190501.44102902'),
	'identifierSchemeCodeUnspsc190501.44102903': __('identifierSchemeCodeUnspsc190501.44102903'),
	'identifierSchemeCodeUnspsc190501.44102904': __('identifierSchemeCodeUnspsc190501.44102904'),
	'identifierSchemeCodeUnspsc190501.44102905': __('identifierSchemeCodeUnspsc190501.44102905'),
	'identifierSchemeCodeUnspsc190501.44102906': __('identifierSchemeCodeUnspsc190501.44102906'),
	'identifierSchemeCodeUnspsc190501.44102907': __('identifierSchemeCodeUnspsc190501.44102907'),
	'identifierSchemeCodeUnspsc190501.44102908': __('identifierSchemeCodeUnspsc190501.44102908'),
	'identifierSchemeCodeUnspsc190501.44102909': __('identifierSchemeCodeUnspsc190501.44102909'),
	'identifierSchemeCodeUnspsc190501.44102910': __('identifierSchemeCodeUnspsc190501.44102910'),
	'identifierSchemeCodeUnspsc190501.44102911': __('identifierSchemeCodeUnspsc190501.44102911'),
	'identifierSchemeCodeUnspsc190501.44102912': __('identifierSchemeCodeUnspsc190501.44102912'),
	'identifierSchemeCodeUnspsc190501.44102913': __('identifierSchemeCodeUnspsc190501.44102913'),
	'identifierSchemeCodeUnspsc190501.44103000': __('identifierSchemeCodeUnspsc190501.44103000'),
	'identifierSchemeCodeUnspsc190501.44103001': __('identifierSchemeCodeUnspsc190501.44103001'),
	'identifierSchemeCodeUnspsc190501.44103002': __('identifierSchemeCodeUnspsc190501.44103002'),
	'identifierSchemeCodeUnspsc190501.44103003': __('identifierSchemeCodeUnspsc190501.44103003'),
	'identifierSchemeCodeUnspsc190501.44103004': __('identifierSchemeCodeUnspsc190501.44103004'),
	'identifierSchemeCodeUnspsc190501.44103005': __('identifierSchemeCodeUnspsc190501.44103005'),
	'identifierSchemeCodeUnspsc190501.44103100': __('identifierSchemeCodeUnspsc190501.44103100'),
	'identifierSchemeCodeUnspsc190501.44103101': __('identifierSchemeCodeUnspsc190501.44103101'),
	'identifierSchemeCodeUnspsc190501.44103103': __('identifierSchemeCodeUnspsc190501.44103103'),
	'identifierSchemeCodeUnspsc190501.44103104': __('identifierSchemeCodeUnspsc190501.44103104'),
	'identifierSchemeCodeUnspsc190501.44103105': __('identifierSchemeCodeUnspsc190501.44103105'),
	'identifierSchemeCodeUnspsc190501.44103106': __('identifierSchemeCodeUnspsc190501.44103106'),
	'identifierSchemeCodeUnspsc190501.44103107': __('identifierSchemeCodeUnspsc190501.44103107'),
	'identifierSchemeCodeUnspsc190501.44103108': __('identifierSchemeCodeUnspsc190501.44103108'),
	'identifierSchemeCodeUnspsc190501.44103109': __('identifierSchemeCodeUnspsc190501.44103109'),
	'identifierSchemeCodeUnspsc190501.44103110': __('identifierSchemeCodeUnspsc190501.44103110'),
	'identifierSchemeCodeUnspsc190501.44103111': __('identifierSchemeCodeUnspsc190501.44103111'),
	'identifierSchemeCodeUnspsc190501.44103112': __('identifierSchemeCodeUnspsc190501.44103112'),
	'identifierSchemeCodeUnspsc190501.44103113': __('identifierSchemeCodeUnspsc190501.44103113'),
	'identifierSchemeCodeUnspsc190501.44103114': __('identifierSchemeCodeUnspsc190501.44103114'),
	'identifierSchemeCodeUnspsc190501.44103116': __('identifierSchemeCodeUnspsc190501.44103116'),
	'identifierSchemeCodeUnspsc190501.44103117': __('identifierSchemeCodeUnspsc190501.44103117'),
	'identifierSchemeCodeUnspsc190501.44103118': __('identifierSchemeCodeUnspsc190501.44103118'),
	'identifierSchemeCodeUnspsc190501.44103119': __('identifierSchemeCodeUnspsc190501.44103119'),
	'identifierSchemeCodeUnspsc190501.44103120': __('identifierSchemeCodeUnspsc190501.44103120'),
	'identifierSchemeCodeUnspsc190501.44103121': __('identifierSchemeCodeUnspsc190501.44103121'),
	'identifierSchemeCodeUnspsc190501.44103122': __('identifierSchemeCodeUnspsc190501.44103122'),
	'identifierSchemeCodeUnspsc190501.44103123': __('identifierSchemeCodeUnspsc190501.44103123'),
	'identifierSchemeCodeUnspsc190501.44103124': __('identifierSchemeCodeUnspsc190501.44103124'),
	'identifierSchemeCodeUnspsc190501.44103125': __('identifierSchemeCodeUnspsc190501.44103125'),
	'identifierSchemeCodeUnspsc190501.44103126': __('identifierSchemeCodeUnspsc190501.44103126'),
	'identifierSchemeCodeUnspsc190501.44103127': __('identifierSchemeCodeUnspsc190501.44103127'),
	'identifierSchemeCodeUnspsc190501.44103200': __('identifierSchemeCodeUnspsc190501.44103200'),
	'identifierSchemeCodeUnspsc190501.44103201': __('identifierSchemeCodeUnspsc190501.44103201'),
	'identifierSchemeCodeUnspsc190501.44103202': __('identifierSchemeCodeUnspsc190501.44103202'),
	'identifierSchemeCodeUnspsc190501.44103203': __('identifierSchemeCodeUnspsc190501.44103203'),
	'identifierSchemeCodeUnspsc190501.44103204': __('identifierSchemeCodeUnspsc190501.44103204'),
	'identifierSchemeCodeUnspsc190501.44103205': __('identifierSchemeCodeUnspsc190501.44103205'),
	'identifierSchemeCodeUnspsc190501.44103206': __('identifierSchemeCodeUnspsc190501.44103206'),
	'identifierSchemeCodeUnspsc190501.44103207': __('identifierSchemeCodeUnspsc190501.44103207'),
	'identifierSchemeCodeUnspsc190501.44103500': __('identifierSchemeCodeUnspsc190501.44103500'),
	'identifierSchemeCodeUnspsc190501.44103502': __('identifierSchemeCodeUnspsc190501.44103502'),
	'identifierSchemeCodeUnspsc190501.44103503': __('identifierSchemeCodeUnspsc190501.44103503'),
	'identifierSchemeCodeUnspsc190501.44103504': __('identifierSchemeCodeUnspsc190501.44103504'),
	'identifierSchemeCodeUnspsc190501.44103505': __('identifierSchemeCodeUnspsc190501.44103505'),
	'identifierSchemeCodeUnspsc190501.44103506': __('identifierSchemeCodeUnspsc190501.44103506'),
	'identifierSchemeCodeUnspsc190501.44103507': __('identifierSchemeCodeUnspsc190501.44103507'),
	'identifierSchemeCodeUnspsc190501.44103508': __('identifierSchemeCodeUnspsc190501.44103508'),
	'identifierSchemeCodeUnspsc190501.44103600': __('identifierSchemeCodeUnspsc190501.44103600'),
	'identifierSchemeCodeUnspsc190501.44103601': __('identifierSchemeCodeUnspsc190501.44103601'),
	'identifierSchemeCodeUnspsc190501.44110000': __('identifierSchemeCodeUnspsc190501.44110000'),
	'identifierSchemeCodeUnspsc190501.44111500': __('identifierSchemeCodeUnspsc190501.44111500'),
	'identifierSchemeCodeUnspsc190501.44111501': __('identifierSchemeCodeUnspsc190501.44111501'),
	'identifierSchemeCodeUnspsc190501.44111502': __('identifierSchemeCodeUnspsc190501.44111502'),
	'identifierSchemeCodeUnspsc190501.44111503': __('identifierSchemeCodeUnspsc190501.44111503'),
	'identifierSchemeCodeUnspsc190501.44111506': __('identifierSchemeCodeUnspsc190501.44111506'),
	'identifierSchemeCodeUnspsc190501.44111507': __('identifierSchemeCodeUnspsc190501.44111507'),
	'identifierSchemeCodeUnspsc190501.44111509': __('identifierSchemeCodeUnspsc190501.44111509'),
	'identifierSchemeCodeUnspsc190501.44111510': __('identifierSchemeCodeUnspsc190501.44111510'),
	'identifierSchemeCodeUnspsc190501.44111511': __('identifierSchemeCodeUnspsc190501.44111511'),
	'identifierSchemeCodeUnspsc190501.44111512': __('identifierSchemeCodeUnspsc190501.44111512'),
	'identifierSchemeCodeUnspsc190501.44111513': __('identifierSchemeCodeUnspsc190501.44111513'),
	'identifierSchemeCodeUnspsc190501.44111514': __('identifierSchemeCodeUnspsc190501.44111514'),
	'identifierSchemeCodeUnspsc190501.44111515': __('identifierSchemeCodeUnspsc190501.44111515'),
	'identifierSchemeCodeUnspsc190501.44111516': __('identifierSchemeCodeUnspsc190501.44111516'),
	'identifierSchemeCodeUnspsc190501.44111517': __('identifierSchemeCodeUnspsc190501.44111517'),
	'identifierSchemeCodeUnspsc190501.44111518': __('identifierSchemeCodeUnspsc190501.44111518'),
	'identifierSchemeCodeUnspsc190501.44111519': __('identifierSchemeCodeUnspsc190501.44111519'),
	'identifierSchemeCodeUnspsc190501.44111520': __('identifierSchemeCodeUnspsc190501.44111520'),
	'identifierSchemeCodeUnspsc190501.44111521': __('identifierSchemeCodeUnspsc190501.44111521'),
	'identifierSchemeCodeUnspsc190501.44111522': __('identifierSchemeCodeUnspsc190501.44111522'),
	'identifierSchemeCodeUnspsc190501.44111523': __('identifierSchemeCodeUnspsc190501.44111523'),
	'identifierSchemeCodeUnspsc190501.44111600': __('identifierSchemeCodeUnspsc190501.44111600'),
	'identifierSchemeCodeUnspsc190501.44111601': __('identifierSchemeCodeUnspsc190501.44111601'),
	'identifierSchemeCodeUnspsc190501.44111603': __('identifierSchemeCodeUnspsc190501.44111603'),
	'identifierSchemeCodeUnspsc190501.44111604': __('identifierSchemeCodeUnspsc190501.44111604'),
	'identifierSchemeCodeUnspsc190501.44111605': __('identifierSchemeCodeUnspsc190501.44111605'),
	'identifierSchemeCodeUnspsc190501.44111606': __('identifierSchemeCodeUnspsc190501.44111606'),
	'identifierSchemeCodeUnspsc190501.44111607': __('identifierSchemeCodeUnspsc190501.44111607'),
	'identifierSchemeCodeUnspsc190501.44111608': __('identifierSchemeCodeUnspsc190501.44111608'),
	'identifierSchemeCodeUnspsc190501.44111609': __('identifierSchemeCodeUnspsc190501.44111609'),
	'identifierSchemeCodeUnspsc190501.44111610': __('identifierSchemeCodeUnspsc190501.44111610'),
	'identifierSchemeCodeUnspsc190501.44111611': __('identifierSchemeCodeUnspsc190501.44111611'),
	'identifierSchemeCodeUnspsc190501.44111612': __('identifierSchemeCodeUnspsc190501.44111612'),
	'identifierSchemeCodeUnspsc190501.44111613': __('identifierSchemeCodeUnspsc190501.44111613'),
	'identifierSchemeCodeUnspsc190501.44111614': __('identifierSchemeCodeUnspsc190501.44111614'),
	'identifierSchemeCodeUnspsc190501.44111615': __('identifierSchemeCodeUnspsc190501.44111615'),
	'identifierSchemeCodeUnspsc190501.44111616': __('identifierSchemeCodeUnspsc190501.44111616'),
	'identifierSchemeCodeUnspsc190501.44111617': __('identifierSchemeCodeUnspsc190501.44111617'),
	'identifierSchemeCodeUnspsc190501.44111618': __('identifierSchemeCodeUnspsc190501.44111618'),
	'identifierSchemeCodeUnspsc190501.44111800': __('identifierSchemeCodeUnspsc190501.44111800'),
	'identifierSchemeCodeUnspsc190501.44111801': __('identifierSchemeCodeUnspsc190501.44111801'),
	'identifierSchemeCodeUnspsc190501.44111802': __('identifierSchemeCodeUnspsc190501.44111802'),
	'identifierSchemeCodeUnspsc190501.44111803': __('identifierSchemeCodeUnspsc190501.44111803'),
	'identifierSchemeCodeUnspsc190501.44111804': __('identifierSchemeCodeUnspsc190501.44111804'),
	'identifierSchemeCodeUnspsc190501.44111805': __('identifierSchemeCodeUnspsc190501.44111805'),
	'identifierSchemeCodeUnspsc190501.44111806': __('identifierSchemeCodeUnspsc190501.44111806'),
	'identifierSchemeCodeUnspsc190501.44111807': __('identifierSchemeCodeUnspsc190501.44111807'),
	'identifierSchemeCodeUnspsc190501.44111808': __('identifierSchemeCodeUnspsc190501.44111808'),
	'identifierSchemeCodeUnspsc190501.44111809': __('identifierSchemeCodeUnspsc190501.44111809'),
	'identifierSchemeCodeUnspsc190501.44111810': __('identifierSchemeCodeUnspsc190501.44111810'),
	'identifierSchemeCodeUnspsc190501.44111812': __('identifierSchemeCodeUnspsc190501.44111812'),
	'identifierSchemeCodeUnspsc190501.44111813': __('identifierSchemeCodeUnspsc190501.44111813'),
	'identifierSchemeCodeUnspsc190501.44111814': __('identifierSchemeCodeUnspsc190501.44111814'),
	'identifierSchemeCodeUnspsc190501.44111815': __('identifierSchemeCodeUnspsc190501.44111815'),
	'identifierSchemeCodeUnspsc190501.44111816': __('identifierSchemeCodeUnspsc190501.44111816'),
	'identifierSchemeCodeUnspsc190501.44111817': __('identifierSchemeCodeUnspsc190501.44111817'),
	'identifierSchemeCodeUnspsc190501.44111818': __('identifierSchemeCodeUnspsc190501.44111818'),
	'identifierSchemeCodeUnspsc190501.44111900': __('identifierSchemeCodeUnspsc190501.44111900'),
	'identifierSchemeCodeUnspsc190501.44111901': __('identifierSchemeCodeUnspsc190501.44111901'),
	'identifierSchemeCodeUnspsc190501.44111902': __('identifierSchemeCodeUnspsc190501.44111902'),
	'identifierSchemeCodeUnspsc190501.44111903': __('identifierSchemeCodeUnspsc190501.44111903'),
	'identifierSchemeCodeUnspsc190501.44111904': __('identifierSchemeCodeUnspsc190501.44111904'),
	'identifierSchemeCodeUnspsc190501.44111905': __('identifierSchemeCodeUnspsc190501.44111905'),
	'identifierSchemeCodeUnspsc190501.44111906': __('identifierSchemeCodeUnspsc190501.44111906'),
	'identifierSchemeCodeUnspsc190501.44111907': __('identifierSchemeCodeUnspsc190501.44111907'),
	'identifierSchemeCodeUnspsc190501.44111908': __('identifierSchemeCodeUnspsc190501.44111908'),
	'identifierSchemeCodeUnspsc190501.44111909': __('identifierSchemeCodeUnspsc190501.44111909'),
	'identifierSchemeCodeUnspsc190501.44111910': __('identifierSchemeCodeUnspsc190501.44111910'),
	'identifierSchemeCodeUnspsc190501.44111911': __('identifierSchemeCodeUnspsc190501.44111911'),
	'identifierSchemeCodeUnspsc190501.44111912': __('identifierSchemeCodeUnspsc190501.44111912'),
	'identifierSchemeCodeUnspsc190501.44111913': __('identifierSchemeCodeUnspsc190501.44111913'),
	'identifierSchemeCodeUnspsc190501.44111914': __('identifierSchemeCodeUnspsc190501.44111914'),
	'identifierSchemeCodeUnspsc190501.44112000': __('identifierSchemeCodeUnspsc190501.44112000'),
	'identifierSchemeCodeUnspsc190501.44112001': __('identifierSchemeCodeUnspsc190501.44112001'),
	'identifierSchemeCodeUnspsc190501.44112002': __('identifierSchemeCodeUnspsc190501.44112002'),
	'identifierSchemeCodeUnspsc190501.44112004': __('identifierSchemeCodeUnspsc190501.44112004'),
	'identifierSchemeCodeUnspsc190501.44112005': __('identifierSchemeCodeUnspsc190501.44112005'),
	'identifierSchemeCodeUnspsc190501.44112006': __('identifierSchemeCodeUnspsc190501.44112006'),
	'identifierSchemeCodeUnspsc190501.44112007': __('identifierSchemeCodeUnspsc190501.44112007'),
	'identifierSchemeCodeUnspsc190501.44112008': __('identifierSchemeCodeUnspsc190501.44112008'),
	'identifierSchemeCodeUnspsc190501.44120000': __('identifierSchemeCodeUnspsc190501.44120000'),
	'identifierSchemeCodeUnspsc190501.44121500': __('identifierSchemeCodeUnspsc190501.44121500'),
	'identifierSchemeCodeUnspsc190501.44121501': __('identifierSchemeCodeUnspsc190501.44121501'),
	'identifierSchemeCodeUnspsc190501.44121503': __('identifierSchemeCodeUnspsc190501.44121503'),
	'identifierSchemeCodeUnspsc190501.44121504': __('identifierSchemeCodeUnspsc190501.44121504'),
	'identifierSchemeCodeUnspsc190501.44121505': __('identifierSchemeCodeUnspsc190501.44121505'),
	'identifierSchemeCodeUnspsc190501.44121506': __('identifierSchemeCodeUnspsc190501.44121506'),
	'identifierSchemeCodeUnspsc190501.44121507': __('identifierSchemeCodeUnspsc190501.44121507'),
	'identifierSchemeCodeUnspsc190501.44121508': __('identifierSchemeCodeUnspsc190501.44121508'),
	'identifierSchemeCodeUnspsc190501.44121509': __('identifierSchemeCodeUnspsc190501.44121509'),
	'identifierSchemeCodeUnspsc190501.44121510': __('identifierSchemeCodeUnspsc190501.44121510'),
	'identifierSchemeCodeUnspsc190501.44121511': __('identifierSchemeCodeUnspsc190501.44121511'),
	'identifierSchemeCodeUnspsc190501.44121512': __('identifierSchemeCodeUnspsc190501.44121512'),
	'identifierSchemeCodeUnspsc190501.44121513': __('identifierSchemeCodeUnspsc190501.44121513'),
	'identifierSchemeCodeUnspsc190501.44121600': __('identifierSchemeCodeUnspsc190501.44121600'),
	'identifierSchemeCodeUnspsc190501.44121604': __('identifierSchemeCodeUnspsc190501.44121604'),
	'identifierSchemeCodeUnspsc190501.44121605': __('identifierSchemeCodeUnspsc190501.44121605'),
	'identifierSchemeCodeUnspsc190501.44121611': __('identifierSchemeCodeUnspsc190501.44121611'),
	'identifierSchemeCodeUnspsc190501.44121612': __('identifierSchemeCodeUnspsc190501.44121612'),
	'identifierSchemeCodeUnspsc190501.44121613': __('identifierSchemeCodeUnspsc190501.44121613'),
	'identifierSchemeCodeUnspsc190501.44121614': __('identifierSchemeCodeUnspsc190501.44121614'),
	'identifierSchemeCodeUnspsc190501.44121615': __('identifierSchemeCodeUnspsc190501.44121615'),
	'identifierSchemeCodeUnspsc190501.44121617': __('identifierSchemeCodeUnspsc190501.44121617'),
	'identifierSchemeCodeUnspsc190501.44121618': __('identifierSchemeCodeUnspsc190501.44121618'),
	'identifierSchemeCodeUnspsc190501.44121619': __('identifierSchemeCodeUnspsc190501.44121619'),
	'identifierSchemeCodeUnspsc190501.44121620': __('identifierSchemeCodeUnspsc190501.44121620'),
	'identifierSchemeCodeUnspsc190501.44121621': __('identifierSchemeCodeUnspsc190501.44121621'),
	'identifierSchemeCodeUnspsc190501.44121622': __('identifierSchemeCodeUnspsc190501.44121622'),
	'identifierSchemeCodeUnspsc190501.44121623': __('identifierSchemeCodeUnspsc190501.44121623'),
	'identifierSchemeCodeUnspsc190501.44121624': __('identifierSchemeCodeUnspsc190501.44121624'),
	'identifierSchemeCodeUnspsc190501.44121625': __('identifierSchemeCodeUnspsc190501.44121625'),
	'identifierSchemeCodeUnspsc190501.44121626': __('identifierSchemeCodeUnspsc190501.44121626'),
	'identifierSchemeCodeUnspsc190501.44121627': __('identifierSchemeCodeUnspsc190501.44121627'),
	'identifierSchemeCodeUnspsc190501.44121628': __('identifierSchemeCodeUnspsc190501.44121628'),
	'identifierSchemeCodeUnspsc190501.44121630': __('identifierSchemeCodeUnspsc190501.44121630'),
	'identifierSchemeCodeUnspsc190501.44121631': __('identifierSchemeCodeUnspsc190501.44121631'),
	'identifierSchemeCodeUnspsc190501.44121632': __('identifierSchemeCodeUnspsc190501.44121632'),
	'identifierSchemeCodeUnspsc190501.44121633': __('identifierSchemeCodeUnspsc190501.44121633'),
	'identifierSchemeCodeUnspsc190501.44121634': __('identifierSchemeCodeUnspsc190501.44121634'),
	'identifierSchemeCodeUnspsc190501.44121635': __('identifierSchemeCodeUnspsc190501.44121635'),
	'identifierSchemeCodeUnspsc190501.44121636': __('identifierSchemeCodeUnspsc190501.44121636'),
	'identifierSchemeCodeUnspsc190501.44121700': __('identifierSchemeCodeUnspsc190501.44121700'),
	'identifierSchemeCodeUnspsc190501.44121701': __('identifierSchemeCodeUnspsc190501.44121701'),
	'identifierSchemeCodeUnspsc190501.44121702': __('identifierSchemeCodeUnspsc190501.44121702'),
	'identifierSchemeCodeUnspsc190501.44121703': __('identifierSchemeCodeUnspsc190501.44121703'),
	'identifierSchemeCodeUnspsc190501.44121704': __('identifierSchemeCodeUnspsc190501.44121704'),
	'identifierSchemeCodeUnspsc190501.44121705': __('identifierSchemeCodeUnspsc190501.44121705'),
	'identifierSchemeCodeUnspsc190501.44121706': __('identifierSchemeCodeUnspsc190501.44121706'),
	'identifierSchemeCodeUnspsc190501.44121707': __('identifierSchemeCodeUnspsc190501.44121707'),
	'identifierSchemeCodeUnspsc190501.44121708': __('identifierSchemeCodeUnspsc190501.44121708'),
	'identifierSchemeCodeUnspsc190501.44121709': __('identifierSchemeCodeUnspsc190501.44121709'),
	'identifierSchemeCodeUnspsc190501.44121710': __('identifierSchemeCodeUnspsc190501.44121710'),
	'identifierSchemeCodeUnspsc190501.44121711': __('identifierSchemeCodeUnspsc190501.44121711'),
	'identifierSchemeCodeUnspsc190501.44121712': __('identifierSchemeCodeUnspsc190501.44121712'),
	'identifierSchemeCodeUnspsc190501.44121713': __('identifierSchemeCodeUnspsc190501.44121713'),
	'identifierSchemeCodeUnspsc190501.44121714': __('identifierSchemeCodeUnspsc190501.44121714'),
	'identifierSchemeCodeUnspsc190501.44121715': __('identifierSchemeCodeUnspsc190501.44121715'),
	'identifierSchemeCodeUnspsc190501.44121716': __('identifierSchemeCodeUnspsc190501.44121716'),
	'identifierSchemeCodeUnspsc190501.44121717': __('identifierSchemeCodeUnspsc190501.44121717'),
	'identifierSchemeCodeUnspsc190501.44121718': __('identifierSchemeCodeUnspsc190501.44121718'),
	'identifierSchemeCodeUnspsc190501.44121719': __('identifierSchemeCodeUnspsc190501.44121719'),
	'identifierSchemeCodeUnspsc190501.44121720': __('identifierSchemeCodeUnspsc190501.44121720'),
	'identifierSchemeCodeUnspsc190501.44121721': __('identifierSchemeCodeUnspsc190501.44121721'),
	'identifierSchemeCodeUnspsc190501.44121800': __('identifierSchemeCodeUnspsc190501.44121800'),
	'identifierSchemeCodeUnspsc190501.44121801': __('identifierSchemeCodeUnspsc190501.44121801'),
	'identifierSchemeCodeUnspsc190501.44121802': __('identifierSchemeCodeUnspsc190501.44121802'),
	'identifierSchemeCodeUnspsc190501.44121804': __('identifierSchemeCodeUnspsc190501.44121804'),
	'identifierSchemeCodeUnspsc190501.44121805': __('identifierSchemeCodeUnspsc190501.44121805'),
	'identifierSchemeCodeUnspsc190501.44121806': __('identifierSchemeCodeUnspsc190501.44121806'),
	'identifierSchemeCodeUnspsc190501.44121807': __('identifierSchemeCodeUnspsc190501.44121807'),
	'identifierSchemeCodeUnspsc190501.44121808': __('identifierSchemeCodeUnspsc190501.44121808'),
	'identifierSchemeCodeUnspsc190501.44121809': __('identifierSchemeCodeUnspsc190501.44121809'),
	'identifierSchemeCodeUnspsc190501.44121900': __('identifierSchemeCodeUnspsc190501.44121900'),
	'identifierSchemeCodeUnspsc190501.44121902': __('identifierSchemeCodeUnspsc190501.44121902'),
	'identifierSchemeCodeUnspsc190501.44121904': __('identifierSchemeCodeUnspsc190501.44121904'),
	'identifierSchemeCodeUnspsc190501.44121905': __('identifierSchemeCodeUnspsc190501.44121905'),
	'identifierSchemeCodeUnspsc190501.44121906': __('identifierSchemeCodeUnspsc190501.44121906'),
	'identifierSchemeCodeUnspsc190501.44121907': __('identifierSchemeCodeUnspsc190501.44121907'),
	'identifierSchemeCodeUnspsc190501.44121908': __('identifierSchemeCodeUnspsc190501.44121908'),
	'identifierSchemeCodeUnspsc190501.44122000': __('identifierSchemeCodeUnspsc190501.44122000'),
	'identifierSchemeCodeUnspsc190501.44122001': __('identifierSchemeCodeUnspsc190501.44122001'),
	'identifierSchemeCodeUnspsc190501.44122002': __('identifierSchemeCodeUnspsc190501.44122002'),
	'identifierSchemeCodeUnspsc190501.44122003': __('identifierSchemeCodeUnspsc190501.44122003'),
	'identifierSchemeCodeUnspsc190501.44122005': __('identifierSchemeCodeUnspsc190501.44122005'),
	'identifierSchemeCodeUnspsc190501.44122008': __('identifierSchemeCodeUnspsc190501.44122008'),
	'identifierSchemeCodeUnspsc190501.44122009': __('identifierSchemeCodeUnspsc190501.44122009'),
	'identifierSchemeCodeUnspsc190501.44122010': __('identifierSchemeCodeUnspsc190501.44122010'),
	'identifierSchemeCodeUnspsc190501.44122011': __('identifierSchemeCodeUnspsc190501.44122011'),
	'identifierSchemeCodeUnspsc190501.44122012': __('identifierSchemeCodeUnspsc190501.44122012'),
	'identifierSchemeCodeUnspsc190501.44122013': __('identifierSchemeCodeUnspsc190501.44122013'),
	'identifierSchemeCodeUnspsc190501.44122014': __('identifierSchemeCodeUnspsc190501.44122014'),
	'identifierSchemeCodeUnspsc190501.44122015': __('identifierSchemeCodeUnspsc190501.44122015'),
	'identifierSchemeCodeUnspsc190501.44122016': __('identifierSchemeCodeUnspsc190501.44122016'),
	'identifierSchemeCodeUnspsc190501.44122017': __('identifierSchemeCodeUnspsc190501.44122017'),
	'identifierSchemeCodeUnspsc190501.44122018': __('identifierSchemeCodeUnspsc190501.44122018'),
	'identifierSchemeCodeUnspsc190501.44122019': __('identifierSchemeCodeUnspsc190501.44122019'),
	'identifierSchemeCodeUnspsc190501.44122020': __('identifierSchemeCodeUnspsc190501.44122020'),
	'identifierSchemeCodeUnspsc190501.44122021': __('identifierSchemeCodeUnspsc190501.44122021'),
	'identifierSchemeCodeUnspsc190501.44122022': __('identifierSchemeCodeUnspsc190501.44122022'),
	'identifierSchemeCodeUnspsc190501.44122023': __('identifierSchemeCodeUnspsc190501.44122023'),
	'identifierSchemeCodeUnspsc190501.44122024': __('identifierSchemeCodeUnspsc190501.44122024'),
	'identifierSchemeCodeUnspsc190501.44122025': __('identifierSchemeCodeUnspsc190501.44122025'),
	'identifierSchemeCodeUnspsc190501.44122026': __('identifierSchemeCodeUnspsc190501.44122026'),
	'identifierSchemeCodeUnspsc190501.44122027': __('identifierSchemeCodeUnspsc190501.44122027'),
	'identifierSchemeCodeUnspsc190501.44122028': __('identifierSchemeCodeUnspsc190501.44122028'),
	'identifierSchemeCodeUnspsc190501.44122029': __('identifierSchemeCodeUnspsc190501.44122029'),
	'identifierSchemeCodeUnspsc190501.44122030': __('identifierSchemeCodeUnspsc190501.44122030'),
	'identifierSchemeCodeUnspsc190501.44122031': __('identifierSchemeCodeUnspsc190501.44122031'),
	'identifierSchemeCodeUnspsc190501.44122032': __('identifierSchemeCodeUnspsc190501.44122032'),
	'identifierSchemeCodeUnspsc190501.44122033': __('identifierSchemeCodeUnspsc190501.44122033'),
	'identifierSchemeCodeUnspsc190501.44122034': __('identifierSchemeCodeUnspsc190501.44122034'),
	'identifierSchemeCodeUnspsc190501.44122035': __('identifierSchemeCodeUnspsc190501.44122035'),
	'identifierSchemeCodeUnspsc190501.44122036': __('identifierSchemeCodeUnspsc190501.44122036'),
	'identifierSchemeCodeUnspsc190501.44122037': __('identifierSchemeCodeUnspsc190501.44122037'),
	'identifierSchemeCodeUnspsc190501.44122100': __('identifierSchemeCodeUnspsc190501.44122100'),
	'identifierSchemeCodeUnspsc190501.44122101': __('identifierSchemeCodeUnspsc190501.44122101'),
	'identifierSchemeCodeUnspsc190501.44122103': __('identifierSchemeCodeUnspsc190501.44122103'),
	'identifierSchemeCodeUnspsc190501.44122104': __('identifierSchemeCodeUnspsc190501.44122104'),
	'identifierSchemeCodeUnspsc190501.44122105': __('identifierSchemeCodeUnspsc190501.44122105'),
	'identifierSchemeCodeUnspsc190501.44122106': __('identifierSchemeCodeUnspsc190501.44122106'),
	'identifierSchemeCodeUnspsc190501.44122107': __('identifierSchemeCodeUnspsc190501.44122107'),
	'identifierSchemeCodeUnspsc190501.44122109': __('identifierSchemeCodeUnspsc190501.44122109'),
	'identifierSchemeCodeUnspsc190501.44122110': __('identifierSchemeCodeUnspsc190501.44122110'),
	'identifierSchemeCodeUnspsc190501.44122111': __('identifierSchemeCodeUnspsc190501.44122111'),
	'identifierSchemeCodeUnspsc190501.44122112': __('identifierSchemeCodeUnspsc190501.44122112'),
	'identifierSchemeCodeUnspsc190501.44122113': __('identifierSchemeCodeUnspsc190501.44122113'),
	'identifierSchemeCodeUnspsc190501.44122114': __('identifierSchemeCodeUnspsc190501.44122114'),
	'identifierSchemeCodeUnspsc190501.44122115': __('identifierSchemeCodeUnspsc190501.44122115'),
	'identifierSchemeCodeUnspsc190501.44122116': __('identifierSchemeCodeUnspsc190501.44122116'),
	'identifierSchemeCodeUnspsc190501.44122117': __('identifierSchemeCodeUnspsc190501.44122117'),
	'identifierSchemeCodeUnspsc190501.44122118': __('identifierSchemeCodeUnspsc190501.44122118'),
	'identifierSchemeCodeUnspsc190501.44122119': __('identifierSchemeCodeUnspsc190501.44122119'),
	'identifierSchemeCodeUnspsc190501.44122120': __('identifierSchemeCodeUnspsc190501.44122120'),
	'identifierSchemeCodeUnspsc190501.44122121': __('identifierSchemeCodeUnspsc190501.44122121'),
	'identifierSchemeCodeUnspsc190501.44122122': __('identifierSchemeCodeUnspsc190501.44122122'),
	'identifierSchemeCodeUnspsc190501.45000000': __('identifierSchemeCodeUnspsc190501.45000000'),
	'identifierSchemeCodeUnspsc190501.45100000': __('identifierSchemeCodeUnspsc190501.45100000'),
	'identifierSchemeCodeUnspsc190501.45101500': __('identifierSchemeCodeUnspsc190501.45101500'),
	'identifierSchemeCodeUnspsc190501.45101501': __('identifierSchemeCodeUnspsc190501.45101501'),
	'identifierSchemeCodeUnspsc190501.45101502': __('identifierSchemeCodeUnspsc190501.45101502'),
	'identifierSchemeCodeUnspsc190501.45101503': __('identifierSchemeCodeUnspsc190501.45101503'),
	'identifierSchemeCodeUnspsc190501.45101504': __('identifierSchemeCodeUnspsc190501.45101504'),
	'identifierSchemeCodeUnspsc190501.45101505': __('identifierSchemeCodeUnspsc190501.45101505'),
	'identifierSchemeCodeUnspsc190501.45101506': __('identifierSchemeCodeUnspsc190501.45101506'),
	'identifierSchemeCodeUnspsc190501.45101507': __('identifierSchemeCodeUnspsc190501.45101507'),
	'identifierSchemeCodeUnspsc190501.45101508': __('identifierSchemeCodeUnspsc190501.45101508'),
	'identifierSchemeCodeUnspsc190501.45101509': __('identifierSchemeCodeUnspsc190501.45101509'),
	'identifierSchemeCodeUnspsc190501.45101510': __('identifierSchemeCodeUnspsc190501.45101510'),
	'identifierSchemeCodeUnspsc190501.45101511': __('identifierSchemeCodeUnspsc190501.45101511'),
	'identifierSchemeCodeUnspsc190501.45101512': __('identifierSchemeCodeUnspsc190501.45101512'),
	'identifierSchemeCodeUnspsc190501.45101513': __('identifierSchemeCodeUnspsc190501.45101513'),
	'identifierSchemeCodeUnspsc190501.45101514': __('identifierSchemeCodeUnspsc190501.45101514'),
	'identifierSchemeCodeUnspsc190501.45101515': __('identifierSchemeCodeUnspsc190501.45101515'),
	'identifierSchemeCodeUnspsc190501.45101516': __('identifierSchemeCodeUnspsc190501.45101516'),
	'identifierSchemeCodeUnspsc190501.45101517': __('identifierSchemeCodeUnspsc190501.45101517'),
	'identifierSchemeCodeUnspsc190501.45101518': __('identifierSchemeCodeUnspsc190501.45101518'),
	'identifierSchemeCodeUnspsc190501.45101519': __('identifierSchemeCodeUnspsc190501.45101519'),
	'identifierSchemeCodeUnspsc190501.45101520': __('identifierSchemeCodeUnspsc190501.45101520'),
	'identifierSchemeCodeUnspsc190501.45101600': __('identifierSchemeCodeUnspsc190501.45101600'),
	'identifierSchemeCodeUnspsc190501.45101602': __('identifierSchemeCodeUnspsc190501.45101602'),
	'identifierSchemeCodeUnspsc190501.45101603': __('identifierSchemeCodeUnspsc190501.45101603'),
	'identifierSchemeCodeUnspsc190501.45101604': __('identifierSchemeCodeUnspsc190501.45101604'),
	'identifierSchemeCodeUnspsc190501.45101606': __('identifierSchemeCodeUnspsc190501.45101606'),
	'identifierSchemeCodeUnspsc190501.45101607': __('identifierSchemeCodeUnspsc190501.45101607'),
	'identifierSchemeCodeUnspsc190501.45101608': __('identifierSchemeCodeUnspsc190501.45101608'),
	'identifierSchemeCodeUnspsc190501.45101609': __('identifierSchemeCodeUnspsc190501.45101609'),
	'identifierSchemeCodeUnspsc190501.45101610': __('identifierSchemeCodeUnspsc190501.45101610'),
	'identifierSchemeCodeUnspsc190501.45101611': __('identifierSchemeCodeUnspsc190501.45101611'),
	'identifierSchemeCodeUnspsc190501.45101612': __('identifierSchemeCodeUnspsc190501.45101612'),
	'identifierSchemeCodeUnspsc190501.45101613': __('identifierSchemeCodeUnspsc190501.45101613'),
	'identifierSchemeCodeUnspsc190501.45101614': __('identifierSchemeCodeUnspsc190501.45101614'),
	'identifierSchemeCodeUnspsc190501.45101615': __('identifierSchemeCodeUnspsc190501.45101615'),
	'identifierSchemeCodeUnspsc190501.45101616': __('identifierSchemeCodeUnspsc190501.45101616'),
	'identifierSchemeCodeUnspsc190501.45101700': __('identifierSchemeCodeUnspsc190501.45101700'),
	'identifierSchemeCodeUnspsc190501.45101701': __('identifierSchemeCodeUnspsc190501.45101701'),
	'identifierSchemeCodeUnspsc190501.45101702': __('identifierSchemeCodeUnspsc190501.45101702'),
	'identifierSchemeCodeUnspsc190501.45101703': __('identifierSchemeCodeUnspsc190501.45101703'),
	'identifierSchemeCodeUnspsc190501.45101704': __('identifierSchemeCodeUnspsc190501.45101704'),
	'identifierSchemeCodeUnspsc190501.45101705': __('identifierSchemeCodeUnspsc190501.45101705'),
	'identifierSchemeCodeUnspsc190501.45101706': __('identifierSchemeCodeUnspsc190501.45101706'),
	'identifierSchemeCodeUnspsc190501.45101707': __('identifierSchemeCodeUnspsc190501.45101707'),
	'identifierSchemeCodeUnspsc190501.45101708': __('identifierSchemeCodeUnspsc190501.45101708'),
	'identifierSchemeCodeUnspsc190501.45101709': __('identifierSchemeCodeUnspsc190501.45101709'),
	'identifierSchemeCodeUnspsc190501.45101710': __('identifierSchemeCodeUnspsc190501.45101710'),
	'identifierSchemeCodeUnspsc190501.45101711': __('identifierSchemeCodeUnspsc190501.45101711'),
	'identifierSchemeCodeUnspsc190501.45101800': __('identifierSchemeCodeUnspsc190501.45101800'),
	'identifierSchemeCodeUnspsc190501.45101801': __('identifierSchemeCodeUnspsc190501.45101801'),
	'identifierSchemeCodeUnspsc190501.45101802': __('identifierSchemeCodeUnspsc190501.45101802'),
	'identifierSchemeCodeUnspsc190501.45101803': __('identifierSchemeCodeUnspsc190501.45101803'),
	'identifierSchemeCodeUnspsc190501.45101804': __('identifierSchemeCodeUnspsc190501.45101804'),
	'identifierSchemeCodeUnspsc190501.45101805': __('identifierSchemeCodeUnspsc190501.45101805'),
	'identifierSchemeCodeUnspsc190501.45101806': __('identifierSchemeCodeUnspsc190501.45101806'),
	'identifierSchemeCodeUnspsc190501.45101807': __('identifierSchemeCodeUnspsc190501.45101807'),
	'identifierSchemeCodeUnspsc190501.45101808': __('identifierSchemeCodeUnspsc190501.45101808'),
	'identifierSchemeCodeUnspsc190501.45101900': __('identifierSchemeCodeUnspsc190501.45101900'),
	'identifierSchemeCodeUnspsc190501.45101901': __('identifierSchemeCodeUnspsc190501.45101901'),
	'identifierSchemeCodeUnspsc190501.45101902': __('identifierSchemeCodeUnspsc190501.45101902'),
	'identifierSchemeCodeUnspsc190501.45101903': __('identifierSchemeCodeUnspsc190501.45101903'),
	'identifierSchemeCodeUnspsc190501.45101904': __('identifierSchemeCodeUnspsc190501.45101904'),
	'identifierSchemeCodeUnspsc190501.45101905': __('identifierSchemeCodeUnspsc190501.45101905'),
	'identifierSchemeCodeUnspsc190501.45102000': __('identifierSchemeCodeUnspsc190501.45102000'),
	'identifierSchemeCodeUnspsc190501.45102001': __('identifierSchemeCodeUnspsc190501.45102001'),
	'identifierSchemeCodeUnspsc190501.45102002': __('identifierSchemeCodeUnspsc190501.45102002'),
	'identifierSchemeCodeUnspsc190501.45102003': __('identifierSchemeCodeUnspsc190501.45102003'),
	'identifierSchemeCodeUnspsc190501.45102004': __('identifierSchemeCodeUnspsc190501.45102004'),
	'identifierSchemeCodeUnspsc190501.45102005': __('identifierSchemeCodeUnspsc190501.45102005'),
	'identifierSchemeCodeUnspsc190501.45110000': __('identifierSchemeCodeUnspsc190501.45110000'),
	'identifierSchemeCodeUnspsc190501.45111500': __('identifierSchemeCodeUnspsc190501.45111500'),
	'identifierSchemeCodeUnspsc190501.45111501': __('identifierSchemeCodeUnspsc190501.45111501'),
	'identifierSchemeCodeUnspsc190501.45111502': __('identifierSchemeCodeUnspsc190501.45111502'),
	'identifierSchemeCodeUnspsc190501.45111503': __('identifierSchemeCodeUnspsc190501.45111503'),
	'identifierSchemeCodeUnspsc190501.45111504': __('identifierSchemeCodeUnspsc190501.45111504'),
	'identifierSchemeCodeUnspsc190501.45111600': __('identifierSchemeCodeUnspsc190501.45111600'),
	'identifierSchemeCodeUnspsc190501.45111601': __('identifierSchemeCodeUnspsc190501.45111601'),
	'identifierSchemeCodeUnspsc190501.45111602': __('identifierSchemeCodeUnspsc190501.45111602'),
	'identifierSchemeCodeUnspsc190501.45111603': __('identifierSchemeCodeUnspsc190501.45111603'),
	'identifierSchemeCodeUnspsc190501.45111604': __('identifierSchemeCodeUnspsc190501.45111604'),
	'identifierSchemeCodeUnspsc190501.45111605': __('identifierSchemeCodeUnspsc190501.45111605'),
	'identifierSchemeCodeUnspsc190501.45111606': __('identifierSchemeCodeUnspsc190501.45111606'),
	'identifierSchemeCodeUnspsc190501.45111607': __('identifierSchemeCodeUnspsc190501.45111607'),
	'identifierSchemeCodeUnspsc190501.45111608': __('identifierSchemeCodeUnspsc190501.45111608'),
	'identifierSchemeCodeUnspsc190501.45111609': __('identifierSchemeCodeUnspsc190501.45111609'),
	'identifierSchemeCodeUnspsc190501.45111610': __('identifierSchemeCodeUnspsc190501.45111610'),
	'identifierSchemeCodeUnspsc190501.45111612': __('identifierSchemeCodeUnspsc190501.45111612'),
	'identifierSchemeCodeUnspsc190501.45111613': __('identifierSchemeCodeUnspsc190501.45111613'),
	'identifierSchemeCodeUnspsc190501.45111614': __('identifierSchemeCodeUnspsc190501.45111614'),
	'identifierSchemeCodeUnspsc190501.45111615': __('identifierSchemeCodeUnspsc190501.45111615'),
	'identifierSchemeCodeUnspsc190501.45111616': __('identifierSchemeCodeUnspsc190501.45111616'),
	'identifierSchemeCodeUnspsc190501.45111617': __('identifierSchemeCodeUnspsc190501.45111617'),
	'identifierSchemeCodeUnspsc190501.45111618': __('identifierSchemeCodeUnspsc190501.45111618'),
	'identifierSchemeCodeUnspsc190501.45111619': __('identifierSchemeCodeUnspsc190501.45111619'),
	'identifierSchemeCodeUnspsc190501.45111620': __('identifierSchemeCodeUnspsc190501.45111620'),
	'identifierSchemeCodeUnspsc190501.45111700': __('identifierSchemeCodeUnspsc190501.45111700'),
	'identifierSchemeCodeUnspsc190501.45111701': __('identifierSchemeCodeUnspsc190501.45111701'),
	'identifierSchemeCodeUnspsc190501.45111702': __('identifierSchemeCodeUnspsc190501.45111702'),
	'identifierSchemeCodeUnspsc190501.45111703': __('identifierSchemeCodeUnspsc190501.45111703'),
	'identifierSchemeCodeUnspsc190501.45111704': __('identifierSchemeCodeUnspsc190501.45111704'),
	'identifierSchemeCodeUnspsc190501.45111705': __('identifierSchemeCodeUnspsc190501.45111705'),
	'identifierSchemeCodeUnspsc190501.45111706': __('identifierSchemeCodeUnspsc190501.45111706'),
	'identifierSchemeCodeUnspsc190501.45111707': __('identifierSchemeCodeUnspsc190501.45111707'),
	'identifierSchemeCodeUnspsc190501.45111708': __('identifierSchemeCodeUnspsc190501.45111708'),
	'identifierSchemeCodeUnspsc190501.45111709': __('identifierSchemeCodeUnspsc190501.45111709'),
	'identifierSchemeCodeUnspsc190501.45111710': __('identifierSchemeCodeUnspsc190501.45111710'),
	'identifierSchemeCodeUnspsc190501.45111711': __('identifierSchemeCodeUnspsc190501.45111711'),
	'identifierSchemeCodeUnspsc190501.45111712': __('identifierSchemeCodeUnspsc190501.45111712'),
	'identifierSchemeCodeUnspsc190501.45111713': __('identifierSchemeCodeUnspsc190501.45111713'),
	'identifierSchemeCodeUnspsc190501.45111714': __('identifierSchemeCodeUnspsc190501.45111714'),
	'identifierSchemeCodeUnspsc190501.45111715': __('identifierSchemeCodeUnspsc190501.45111715'),
	'identifierSchemeCodeUnspsc190501.45111716': __('identifierSchemeCodeUnspsc190501.45111716'),
	'identifierSchemeCodeUnspsc190501.45111717': __('identifierSchemeCodeUnspsc190501.45111717'),
	'identifierSchemeCodeUnspsc190501.45111718': __('identifierSchemeCodeUnspsc190501.45111718'),
	'identifierSchemeCodeUnspsc190501.45111719': __('identifierSchemeCodeUnspsc190501.45111719'),
	'identifierSchemeCodeUnspsc190501.45111720': __('identifierSchemeCodeUnspsc190501.45111720'),
	'identifierSchemeCodeUnspsc190501.45111721': __('identifierSchemeCodeUnspsc190501.45111721'),
	'identifierSchemeCodeUnspsc190501.45111722': __('identifierSchemeCodeUnspsc190501.45111722'),
	'identifierSchemeCodeUnspsc190501.45111723': __('identifierSchemeCodeUnspsc190501.45111723'),
	'identifierSchemeCodeUnspsc190501.45111724': __('identifierSchemeCodeUnspsc190501.45111724'),
	'identifierSchemeCodeUnspsc190501.45111725': __('identifierSchemeCodeUnspsc190501.45111725'),
	'identifierSchemeCodeUnspsc190501.45111800': __('identifierSchemeCodeUnspsc190501.45111800'),
	'identifierSchemeCodeUnspsc190501.45111801': __('identifierSchemeCodeUnspsc190501.45111801'),
	'identifierSchemeCodeUnspsc190501.45111802': __('identifierSchemeCodeUnspsc190501.45111802'),
	'identifierSchemeCodeUnspsc190501.45111803': __('identifierSchemeCodeUnspsc190501.45111803'),
	'identifierSchemeCodeUnspsc190501.45111804': __('identifierSchemeCodeUnspsc190501.45111804'),
	'identifierSchemeCodeUnspsc190501.45111805': __('identifierSchemeCodeUnspsc190501.45111805'),
	'identifierSchemeCodeUnspsc190501.45111806': __('identifierSchemeCodeUnspsc190501.45111806'),
	'identifierSchemeCodeUnspsc190501.45111807': __('identifierSchemeCodeUnspsc190501.45111807'),
	'identifierSchemeCodeUnspsc190501.45111808': __('identifierSchemeCodeUnspsc190501.45111808'),
	'identifierSchemeCodeUnspsc190501.45111809': __('identifierSchemeCodeUnspsc190501.45111809'),
	'identifierSchemeCodeUnspsc190501.45111810': __('identifierSchemeCodeUnspsc190501.45111810'),
	'identifierSchemeCodeUnspsc190501.45111811': __('identifierSchemeCodeUnspsc190501.45111811'),
	'identifierSchemeCodeUnspsc190501.45111812': __('identifierSchemeCodeUnspsc190501.45111812'),
	'identifierSchemeCodeUnspsc190501.45111813': __('identifierSchemeCodeUnspsc190501.45111813'),
	'identifierSchemeCodeUnspsc190501.45111814': __('identifierSchemeCodeUnspsc190501.45111814'),
	'identifierSchemeCodeUnspsc190501.45111815': __('identifierSchemeCodeUnspsc190501.45111815'),
	'identifierSchemeCodeUnspsc190501.45111816': __('identifierSchemeCodeUnspsc190501.45111816'),
	'identifierSchemeCodeUnspsc190501.45111817': __('identifierSchemeCodeUnspsc190501.45111817'),
	'identifierSchemeCodeUnspsc190501.45111818': __('identifierSchemeCodeUnspsc190501.45111818'),
	'identifierSchemeCodeUnspsc190501.45111819': __('identifierSchemeCodeUnspsc190501.45111819'),
	'identifierSchemeCodeUnspsc190501.45111820': __('identifierSchemeCodeUnspsc190501.45111820'),
	'identifierSchemeCodeUnspsc190501.45111821': __('identifierSchemeCodeUnspsc190501.45111821'),
	'identifierSchemeCodeUnspsc190501.45111822': __('identifierSchemeCodeUnspsc190501.45111822'),
	'identifierSchemeCodeUnspsc190501.45111823': __('identifierSchemeCodeUnspsc190501.45111823'),
	'identifierSchemeCodeUnspsc190501.45111824': __('identifierSchemeCodeUnspsc190501.45111824'),
	'identifierSchemeCodeUnspsc190501.45111825': __('identifierSchemeCodeUnspsc190501.45111825'),
	'identifierSchemeCodeUnspsc190501.45111826': __('identifierSchemeCodeUnspsc190501.45111826'),
	'identifierSchemeCodeUnspsc190501.45111827': __('identifierSchemeCodeUnspsc190501.45111827'),
	'identifierSchemeCodeUnspsc190501.45111828': __('identifierSchemeCodeUnspsc190501.45111828'),
	'identifierSchemeCodeUnspsc190501.45111829': __('identifierSchemeCodeUnspsc190501.45111829'),
	'identifierSchemeCodeUnspsc190501.45111900': __('identifierSchemeCodeUnspsc190501.45111900'),
	'identifierSchemeCodeUnspsc190501.45111901': __('identifierSchemeCodeUnspsc190501.45111901'),
	'identifierSchemeCodeUnspsc190501.45111902': __('identifierSchemeCodeUnspsc190501.45111902'),
	'identifierSchemeCodeUnspsc190501.45112000': __('identifierSchemeCodeUnspsc190501.45112000'),
	'identifierSchemeCodeUnspsc190501.45112001': __('identifierSchemeCodeUnspsc190501.45112001'),
	'identifierSchemeCodeUnspsc190501.45112002': __('identifierSchemeCodeUnspsc190501.45112002'),
	'identifierSchemeCodeUnspsc190501.45112003': __('identifierSchemeCodeUnspsc190501.45112003'),
	'identifierSchemeCodeUnspsc190501.45112004': __('identifierSchemeCodeUnspsc190501.45112004'),
	'identifierSchemeCodeUnspsc190501.45120000': __('identifierSchemeCodeUnspsc190501.45120000'),
	'identifierSchemeCodeUnspsc190501.45121500': __('identifierSchemeCodeUnspsc190501.45121500'),
	'identifierSchemeCodeUnspsc190501.45121501': __('identifierSchemeCodeUnspsc190501.45121501'),
	'identifierSchemeCodeUnspsc190501.45121502': __('identifierSchemeCodeUnspsc190501.45121502'),
	'identifierSchemeCodeUnspsc190501.45121503': __('identifierSchemeCodeUnspsc190501.45121503'),
	'identifierSchemeCodeUnspsc190501.45121504': __('identifierSchemeCodeUnspsc190501.45121504'),
	'identifierSchemeCodeUnspsc190501.45121505': __('identifierSchemeCodeUnspsc190501.45121505'),
	'identifierSchemeCodeUnspsc190501.45121506': __('identifierSchemeCodeUnspsc190501.45121506'),
	'identifierSchemeCodeUnspsc190501.45121510': __('identifierSchemeCodeUnspsc190501.45121510'),
	'identifierSchemeCodeUnspsc190501.45121511': __('identifierSchemeCodeUnspsc190501.45121511'),
	'identifierSchemeCodeUnspsc190501.45121512': __('identifierSchemeCodeUnspsc190501.45121512'),
	'identifierSchemeCodeUnspsc190501.45121513': __('identifierSchemeCodeUnspsc190501.45121513'),
	'identifierSchemeCodeUnspsc190501.45121514': __('identifierSchemeCodeUnspsc190501.45121514'),
	'identifierSchemeCodeUnspsc190501.45121515': __('identifierSchemeCodeUnspsc190501.45121515'),
	'identifierSchemeCodeUnspsc190501.45121516': __('identifierSchemeCodeUnspsc190501.45121516'),
	'identifierSchemeCodeUnspsc190501.45121517': __('identifierSchemeCodeUnspsc190501.45121517'),
	'identifierSchemeCodeUnspsc190501.45121518': __('identifierSchemeCodeUnspsc190501.45121518'),
	'identifierSchemeCodeUnspsc190501.45121519': __('identifierSchemeCodeUnspsc190501.45121519'),
	'identifierSchemeCodeUnspsc190501.45121520': __('identifierSchemeCodeUnspsc190501.45121520'),
	'identifierSchemeCodeUnspsc190501.45121521': __('identifierSchemeCodeUnspsc190501.45121521'),
	'identifierSchemeCodeUnspsc190501.45121522': __('identifierSchemeCodeUnspsc190501.45121522'),
	'identifierSchemeCodeUnspsc190501.45121523': __('identifierSchemeCodeUnspsc190501.45121523'),
	'identifierSchemeCodeUnspsc190501.45121600': __('identifierSchemeCodeUnspsc190501.45121600'),
	'identifierSchemeCodeUnspsc190501.45121601': __('identifierSchemeCodeUnspsc190501.45121601'),
	'identifierSchemeCodeUnspsc190501.45121602': __('identifierSchemeCodeUnspsc190501.45121602'),
	'identifierSchemeCodeUnspsc190501.45121603': __('identifierSchemeCodeUnspsc190501.45121603'),
	'identifierSchemeCodeUnspsc190501.45121604': __('identifierSchemeCodeUnspsc190501.45121604'),
	'identifierSchemeCodeUnspsc190501.45121605': __('identifierSchemeCodeUnspsc190501.45121605'),
	'identifierSchemeCodeUnspsc190501.45121606': __('identifierSchemeCodeUnspsc190501.45121606'),
	'identifierSchemeCodeUnspsc190501.45121607': __('identifierSchemeCodeUnspsc190501.45121607'),
	'identifierSchemeCodeUnspsc190501.45121608': __('identifierSchemeCodeUnspsc190501.45121608'),
	'identifierSchemeCodeUnspsc190501.45121609': __('identifierSchemeCodeUnspsc190501.45121609'),
	'identifierSchemeCodeUnspsc190501.45121610': __('identifierSchemeCodeUnspsc190501.45121610'),
	'identifierSchemeCodeUnspsc190501.45121611': __('identifierSchemeCodeUnspsc190501.45121611'),
	'identifierSchemeCodeUnspsc190501.45121612': __('identifierSchemeCodeUnspsc190501.45121612'),
	'identifierSchemeCodeUnspsc190501.45121613': __('identifierSchemeCodeUnspsc190501.45121613'),
	'identifierSchemeCodeUnspsc190501.45121614': __('identifierSchemeCodeUnspsc190501.45121614'),
	'identifierSchemeCodeUnspsc190501.45121615': __('identifierSchemeCodeUnspsc190501.45121615'),
	'identifierSchemeCodeUnspsc190501.45121616': __('identifierSchemeCodeUnspsc190501.45121616'),
	'identifierSchemeCodeUnspsc190501.45121617': __('identifierSchemeCodeUnspsc190501.45121617'),
	'identifierSchemeCodeUnspsc190501.45121618': __('identifierSchemeCodeUnspsc190501.45121618'),
	'identifierSchemeCodeUnspsc190501.45121619': __('identifierSchemeCodeUnspsc190501.45121619'),
	'identifierSchemeCodeUnspsc190501.45121620': __('identifierSchemeCodeUnspsc190501.45121620'),
	'identifierSchemeCodeUnspsc190501.45121621': __('identifierSchemeCodeUnspsc190501.45121621'),
	'identifierSchemeCodeUnspsc190501.45121622': __('identifierSchemeCodeUnspsc190501.45121622'),
	'identifierSchemeCodeUnspsc190501.45121623': __('identifierSchemeCodeUnspsc190501.45121623'),
	'identifierSchemeCodeUnspsc190501.45121624': __('identifierSchemeCodeUnspsc190501.45121624'),
	'identifierSchemeCodeUnspsc190501.45121625': __('identifierSchemeCodeUnspsc190501.45121625'),
	'identifierSchemeCodeUnspsc190501.45121626': __('identifierSchemeCodeUnspsc190501.45121626'),
	'identifierSchemeCodeUnspsc190501.45121627': __('identifierSchemeCodeUnspsc190501.45121627'),
	'identifierSchemeCodeUnspsc190501.45121628': __('identifierSchemeCodeUnspsc190501.45121628'),
	'identifierSchemeCodeUnspsc190501.45121629': __('identifierSchemeCodeUnspsc190501.45121629'),
	'identifierSchemeCodeUnspsc190501.45121630': __('identifierSchemeCodeUnspsc190501.45121630'),
	'identifierSchemeCodeUnspsc190501.45121631': __('identifierSchemeCodeUnspsc190501.45121631'),
	'identifierSchemeCodeUnspsc190501.45121632': __('identifierSchemeCodeUnspsc190501.45121632'),
	'identifierSchemeCodeUnspsc190501.45121700': __('identifierSchemeCodeUnspsc190501.45121700'),
	'identifierSchemeCodeUnspsc190501.45121701': __('identifierSchemeCodeUnspsc190501.45121701'),
	'identifierSchemeCodeUnspsc190501.45121702': __('identifierSchemeCodeUnspsc190501.45121702'),
	'identifierSchemeCodeUnspsc190501.45121703': __('identifierSchemeCodeUnspsc190501.45121703'),
	'identifierSchemeCodeUnspsc190501.45121704': __('identifierSchemeCodeUnspsc190501.45121704'),
	'identifierSchemeCodeUnspsc190501.45121705': __('identifierSchemeCodeUnspsc190501.45121705'),
	'identifierSchemeCodeUnspsc190501.45121706': __('identifierSchemeCodeUnspsc190501.45121706'),
	'identifierSchemeCodeUnspsc190501.45121707': __('identifierSchemeCodeUnspsc190501.45121707'),
	'identifierSchemeCodeUnspsc190501.45121708': __('identifierSchemeCodeUnspsc190501.45121708'),
	'identifierSchemeCodeUnspsc190501.45121709': __('identifierSchemeCodeUnspsc190501.45121709'),
	'identifierSchemeCodeUnspsc190501.45121710': __('identifierSchemeCodeUnspsc190501.45121710'),
	'identifierSchemeCodeUnspsc190501.45121711': __('identifierSchemeCodeUnspsc190501.45121711'),
	'identifierSchemeCodeUnspsc190501.45121712': __('identifierSchemeCodeUnspsc190501.45121712'),
	'identifierSchemeCodeUnspsc190501.45121713': __('identifierSchemeCodeUnspsc190501.45121713'),
	'identifierSchemeCodeUnspsc190501.45121714': __('identifierSchemeCodeUnspsc190501.45121714'),
	'identifierSchemeCodeUnspsc190501.45121715': __('identifierSchemeCodeUnspsc190501.45121715'),
	'identifierSchemeCodeUnspsc190501.45121716': __('identifierSchemeCodeUnspsc190501.45121716'),
	'identifierSchemeCodeUnspsc190501.45121717': __('identifierSchemeCodeUnspsc190501.45121717'),
	'identifierSchemeCodeUnspsc190501.45121718': __('identifierSchemeCodeUnspsc190501.45121718'),
	'identifierSchemeCodeUnspsc190501.45121719': __('identifierSchemeCodeUnspsc190501.45121719'),
	'identifierSchemeCodeUnspsc190501.45121720': __('identifierSchemeCodeUnspsc190501.45121720'),
	'identifierSchemeCodeUnspsc190501.45121721': __('identifierSchemeCodeUnspsc190501.45121721'),
	'identifierSchemeCodeUnspsc190501.45121800': __('identifierSchemeCodeUnspsc190501.45121800'),
	'identifierSchemeCodeUnspsc190501.45121801': __('identifierSchemeCodeUnspsc190501.45121801'),
	'identifierSchemeCodeUnspsc190501.45121802': __('identifierSchemeCodeUnspsc190501.45121802'),
	'identifierSchemeCodeUnspsc190501.45121803': __('identifierSchemeCodeUnspsc190501.45121803'),
	'identifierSchemeCodeUnspsc190501.45121804': __('identifierSchemeCodeUnspsc190501.45121804'),
	'identifierSchemeCodeUnspsc190501.45121805': __('identifierSchemeCodeUnspsc190501.45121805'),
	'identifierSchemeCodeUnspsc190501.45121806': __('identifierSchemeCodeUnspsc190501.45121806'),
	'identifierSchemeCodeUnspsc190501.45121807': __('identifierSchemeCodeUnspsc190501.45121807'),
	'identifierSchemeCodeUnspsc190501.45121808': __('identifierSchemeCodeUnspsc190501.45121808'),
	'identifierSchemeCodeUnspsc190501.45121809': __('identifierSchemeCodeUnspsc190501.45121809'),
	'identifierSchemeCodeUnspsc190501.45121810': __('identifierSchemeCodeUnspsc190501.45121810'),
	'identifierSchemeCodeUnspsc190501.45130000': __('identifierSchemeCodeUnspsc190501.45130000'),
	'identifierSchemeCodeUnspsc190501.45131500': __('identifierSchemeCodeUnspsc190501.45131500'),
	'identifierSchemeCodeUnspsc190501.45131501': __('identifierSchemeCodeUnspsc190501.45131501'),
	'identifierSchemeCodeUnspsc190501.45131502': __('identifierSchemeCodeUnspsc190501.45131502'),
	'identifierSchemeCodeUnspsc190501.45131503': __('identifierSchemeCodeUnspsc190501.45131503'),
	'identifierSchemeCodeUnspsc190501.45131505': __('identifierSchemeCodeUnspsc190501.45131505'),
	'identifierSchemeCodeUnspsc190501.45131506': __('identifierSchemeCodeUnspsc190501.45131506'),
	'identifierSchemeCodeUnspsc190501.45131507': __('identifierSchemeCodeUnspsc190501.45131507'),
	'identifierSchemeCodeUnspsc190501.45131508': __('identifierSchemeCodeUnspsc190501.45131508'),
	'identifierSchemeCodeUnspsc190501.45131509': __('identifierSchemeCodeUnspsc190501.45131509'),
	'identifierSchemeCodeUnspsc190501.45131510': __('identifierSchemeCodeUnspsc190501.45131510'),
	'identifierSchemeCodeUnspsc190501.45131600': __('identifierSchemeCodeUnspsc190501.45131600'),
	'identifierSchemeCodeUnspsc190501.45131601': __('identifierSchemeCodeUnspsc190501.45131601'),
	'identifierSchemeCodeUnspsc190501.45131604': __('identifierSchemeCodeUnspsc190501.45131604'),
	'identifierSchemeCodeUnspsc190501.45131700': __('identifierSchemeCodeUnspsc190501.45131700'),
	'identifierSchemeCodeUnspsc190501.45131701': __('identifierSchemeCodeUnspsc190501.45131701'),
	'identifierSchemeCodeUnspsc190501.45140000': __('identifierSchemeCodeUnspsc190501.45140000'),
	'identifierSchemeCodeUnspsc190501.45141500': __('identifierSchemeCodeUnspsc190501.45141500'),
	'identifierSchemeCodeUnspsc190501.45141501': __('identifierSchemeCodeUnspsc190501.45141501'),
	'identifierSchemeCodeUnspsc190501.45141502': __('identifierSchemeCodeUnspsc190501.45141502'),
	'identifierSchemeCodeUnspsc190501.45141503': __('identifierSchemeCodeUnspsc190501.45141503'),
	'identifierSchemeCodeUnspsc190501.45141504': __('identifierSchemeCodeUnspsc190501.45141504'),
	'identifierSchemeCodeUnspsc190501.45141600': __('identifierSchemeCodeUnspsc190501.45141600'),
	'identifierSchemeCodeUnspsc190501.45141601': __('identifierSchemeCodeUnspsc190501.45141601'),
	'identifierSchemeCodeUnspsc190501.45141602': __('identifierSchemeCodeUnspsc190501.45141602'),
	'identifierSchemeCodeUnspsc190501.45141603': __('identifierSchemeCodeUnspsc190501.45141603'),
	'identifierSchemeCodeUnspsc190501.45141604': __('identifierSchemeCodeUnspsc190501.45141604'),
	'identifierSchemeCodeUnspsc190501.45141605': __('identifierSchemeCodeUnspsc190501.45141605'),
	'identifierSchemeCodeUnspsc190501.45141606': __('identifierSchemeCodeUnspsc190501.45141606'),
	'identifierSchemeCodeUnspsc190501.45141607': __('identifierSchemeCodeUnspsc190501.45141607'),
	'identifierSchemeCodeUnspsc190501.45141608': __('identifierSchemeCodeUnspsc190501.45141608'),
	'identifierSchemeCodeUnspsc190501.45141609': __('identifierSchemeCodeUnspsc190501.45141609'),
	'identifierSchemeCodeUnspsc190501.45141610': __('identifierSchemeCodeUnspsc190501.45141610'),
	'identifierSchemeCodeUnspsc190501.45141611': __('identifierSchemeCodeUnspsc190501.45141611'),
	'identifierSchemeCodeUnspsc190501.45141612': __('identifierSchemeCodeUnspsc190501.45141612'),
	'identifierSchemeCodeUnspsc190501.46000000': __('identifierSchemeCodeUnspsc190501.46000000'),
	'identifierSchemeCodeUnspsc190501.46100000': __('identifierSchemeCodeUnspsc190501.46100000'),
	'identifierSchemeCodeUnspsc190501.46101500': __('identifierSchemeCodeUnspsc190501.46101500'),
	'identifierSchemeCodeUnspsc190501.46101501': __('identifierSchemeCodeUnspsc190501.46101501'),
	'identifierSchemeCodeUnspsc190501.46101502': __('identifierSchemeCodeUnspsc190501.46101502'),
	'identifierSchemeCodeUnspsc190501.46101503': __('identifierSchemeCodeUnspsc190501.46101503'),
	'identifierSchemeCodeUnspsc190501.46101504': __('identifierSchemeCodeUnspsc190501.46101504'),
	'identifierSchemeCodeUnspsc190501.46101505': __('identifierSchemeCodeUnspsc190501.46101505'),
	'identifierSchemeCodeUnspsc190501.46101506': __('identifierSchemeCodeUnspsc190501.46101506'),
	'identifierSchemeCodeUnspsc190501.46101600': __('identifierSchemeCodeUnspsc190501.46101600'),
	'identifierSchemeCodeUnspsc190501.46101601': __('identifierSchemeCodeUnspsc190501.46101601'),
	'identifierSchemeCodeUnspsc190501.46101700': __('identifierSchemeCodeUnspsc190501.46101700'),
	'identifierSchemeCodeUnspsc190501.46101701': __('identifierSchemeCodeUnspsc190501.46101701'),
	'identifierSchemeCodeUnspsc190501.46101702': __('identifierSchemeCodeUnspsc190501.46101702'),
	'identifierSchemeCodeUnspsc190501.46101703': __('identifierSchemeCodeUnspsc190501.46101703'),
	'identifierSchemeCodeUnspsc190501.46101800': __('identifierSchemeCodeUnspsc190501.46101800'),
	'identifierSchemeCodeUnspsc190501.46101801': __('identifierSchemeCodeUnspsc190501.46101801'),
	'identifierSchemeCodeUnspsc190501.46101802': __('identifierSchemeCodeUnspsc190501.46101802'),
	'identifierSchemeCodeUnspsc190501.46101900': __('identifierSchemeCodeUnspsc190501.46101900'),
	'identifierSchemeCodeUnspsc190501.46101901': __('identifierSchemeCodeUnspsc190501.46101901'),
	'identifierSchemeCodeUnspsc190501.46101902': __('identifierSchemeCodeUnspsc190501.46101902'),
	'identifierSchemeCodeUnspsc190501.46101903': __('identifierSchemeCodeUnspsc190501.46101903'),
	'identifierSchemeCodeUnspsc190501.46110000': __('identifierSchemeCodeUnspsc190501.46110000'),
	'identifierSchemeCodeUnspsc190501.46111500': __('identifierSchemeCodeUnspsc190501.46111500'),
	'identifierSchemeCodeUnspsc190501.46111501': __('identifierSchemeCodeUnspsc190501.46111501'),
	'identifierSchemeCodeUnspsc190501.46111502': __('identifierSchemeCodeUnspsc190501.46111502'),
	'identifierSchemeCodeUnspsc190501.46111503': __('identifierSchemeCodeUnspsc190501.46111503'),
	'identifierSchemeCodeUnspsc190501.46111504': __('identifierSchemeCodeUnspsc190501.46111504'),
	'identifierSchemeCodeUnspsc190501.46111505': __('identifierSchemeCodeUnspsc190501.46111505'),
	'identifierSchemeCodeUnspsc190501.46111506': __('identifierSchemeCodeUnspsc190501.46111506'),
	'identifierSchemeCodeUnspsc190501.46111600': __('identifierSchemeCodeUnspsc190501.46111600'),
	'identifierSchemeCodeUnspsc190501.46111601': __('identifierSchemeCodeUnspsc190501.46111601'),
	'identifierSchemeCodeUnspsc190501.46111602': __('identifierSchemeCodeUnspsc190501.46111602'),
	'identifierSchemeCodeUnspsc190501.46111700': __('identifierSchemeCodeUnspsc190501.46111700'),
	'identifierSchemeCodeUnspsc190501.46111701': __('identifierSchemeCodeUnspsc190501.46111701'),
	'identifierSchemeCodeUnspsc190501.46111702': __('identifierSchemeCodeUnspsc190501.46111702'),
	'identifierSchemeCodeUnspsc190501.46111703': __('identifierSchemeCodeUnspsc190501.46111703'),
	'identifierSchemeCodeUnspsc190501.46111704': __('identifierSchemeCodeUnspsc190501.46111704'),
	'identifierSchemeCodeUnspsc190501.46111705': __('identifierSchemeCodeUnspsc190501.46111705'),
	'identifierSchemeCodeUnspsc190501.46111800': __('identifierSchemeCodeUnspsc190501.46111800'),
	'identifierSchemeCodeUnspsc190501.46111801': __('identifierSchemeCodeUnspsc190501.46111801'),
	'identifierSchemeCodeUnspsc190501.46120000': __('identifierSchemeCodeUnspsc190501.46120000'),
	'identifierSchemeCodeUnspsc190501.46121500': __('identifierSchemeCodeUnspsc190501.46121500'),
	'identifierSchemeCodeUnspsc190501.46121501': __('identifierSchemeCodeUnspsc190501.46121501'),
	'identifierSchemeCodeUnspsc190501.46121502': __('identifierSchemeCodeUnspsc190501.46121502'),
	'identifierSchemeCodeUnspsc190501.46121503': __('identifierSchemeCodeUnspsc190501.46121503'),
	'identifierSchemeCodeUnspsc190501.46121504': __('identifierSchemeCodeUnspsc190501.46121504'),
	'identifierSchemeCodeUnspsc190501.46121505': __('identifierSchemeCodeUnspsc190501.46121505'),
	'identifierSchemeCodeUnspsc190501.46121506': __('identifierSchemeCodeUnspsc190501.46121506'),
	'identifierSchemeCodeUnspsc190501.46121507': __('identifierSchemeCodeUnspsc190501.46121507'),
	'identifierSchemeCodeUnspsc190501.46121508': __('identifierSchemeCodeUnspsc190501.46121508'),
	'identifierSchemeCodeUnspsc190501.46121509': __('identifierSchemeCodeUnspsc190501.46121509'),
	'identifierSchemeCodeUnspsc190501.46121510': __('identifierSchemeCodeUnspsc190501.46121510'),
	'identifierSchemeCodeUnspsc190501.46121511': __('identifierSchemeCodeUnspsc190501.46121511'),
	'identifierSchemeCodeUnspsc190501.46121512': __('identifierSchemeCodeUnspsc190501.46121512'),
	'identifierSchemeCodeUnspsc190501.46121600': __('identifierSchemeCodeUnspsc190501.46121600'),
	'identifierSchemeCodeUnspsc190501.46121601': __('identifierSchemeCodeUnspsc190501.46121601'),
	'identifierSchemeCodeUnspsc190501.46121602': __('identifierSchemeCodeUnspsc190501.46121602'),
	'identifierSchemeCodeUnspsc190501.46121603': __('identifierSchemeCodeUnspsc190501.46121603'),
	'identifierSchemeCodeUnspsc190501.46121604': __('identifierSchemeCodeUnspsc190501.46121604'),
	'identifierSchemeCodeUnspsc190501.46121605': __('identifierSchemeCodeUnspsc190501.46121605'),
	'identifierSchemeCodeUnspsc190501.46130000': __('identifierSchemeCodeUnspsc190501.46130000'),
	'identifierSchemeCodeUnspsc190501.46131500': __('identifierSchemeCodeUnspsc190501.46131500'),
	'identifierSchemeCodeUnspsc190501.46131501': __('identifierSchemeCodeUnspsc190501.46131501'),
	'identifierSchemeCodeUnspsc190501.46131502': __('identifierSchemeCodeUnspsc190501.46131502'),
	'identifierSchemeCodeUnspsc190501.46131503': __('identifierSchemeCodeUnspsc190501.46131503'),
	'identifierSchemeCodeUnspsc190501.46131504': __('identifierSchemeCodeUnspsc190501.46131504'),
	'identifierSchemeCodeUnspsc190501.46131505': __('identifierSchemeCodeUnspsc190501.46131505'),
	'identifierSchemeCodeUnspsc190501.46131600': __('identifierSchemeCodeUnspsc190501.46131600'),
	'identifierSchemeCodeUnspsc190501.46131601': __('identifierSchemeCodeUnspsc190501.46131601'),
	'identifierSchemeCodeUnspsc190501.46131602': __('identifierSchemeCodeUnspsc190501.46131602'),
	'identifierSchemeCodeUnspsc190501.46131603': __('identifierSchemeCodeUnspsc190501.46131603'),
	'identifierSchemeCodeUnspsc190501.46131604': __('identifierSchemeCodeUnspsc190501.46131604'),
	'identifierSchemeCodeUnspsc190501.46140000': __('identifierSchemeCodeUnspsc190501.46140000'),
	'identifierSchemeCodeUnspsc190501.46141500': __('identifierSchemeCodeUnspsc190501.46141500'),
	'identifierSchemeCodeUnspsc190501.46141501': __('identifierSchemeCodeUnspsc190501.46141501'),
	'identifierSchemeCodeUnspsc190501.46141502': __('identifierSchemeCodeUnspsc190501.46141502'),
	'identifierSchemeCodeUnspsc190501.46150000': __('identifierSchemeCodeUnspsc190501.46150000'),
	'identifierSchemeCodeUnspsc190501.46151500': __('identifierSchemeCodeUnspsc190501.46151500'),
	'identifierSchemeCodeUnspsc190501.46151501': __('identifierSchemeCodeUnspsc190501.46151501'),
	'identifierSchemeCodeUnspsc190501.46151502': __('identifierSchemeCodeUnspsc190501.46151502'),
	'identifierSchemeCodeUnspsc190501.46151503': __('identifierSchemeCodeUnspsc190501.46151503'),
	'identifierSchemeCodeUnspsc190501.46151504': __('identifierSchemeCodeUnspsc190501.46151504'),
	'identifierSchemeCodeUnspsc190501.46151505': __('identifierSchemeCodeUnspsc190501.46151505'),
	'identifierSchemeCodeUnspsc190501.46151506': __('identifierSchemeCodeUnspsc190501.46151506'),
	'identifierSchemeCodeUnspsc190501.46151507': __('identifierSchemeCodeUnspsc190501.46151507'),
	'identifierSchemeCodeUnspsc190501.46151508': __('identifierSchemeCodeUnspsc190501.46151508'),
	'identifierSchemeCodeUnspsc190501.46151600': __('identifierSchemeCodeUnspsc190501.46151600'),
	'identifierSchemeCodeUnspsc190501.46151601': __('identifierSchemeCodeUnspsc190501.46151601'),
	'identifierSchemeCodeUnspsc190501.46151602': __('identifierSchemeCodeUnspsc190501.46151602'),
	'identifierSchemeCodeUnspsc190501.46151604': __('identifierSchemeCodeUnspsc190501.46151604'),
	'identifierSchemeCodeUnspsc190501.46151605': __('identifierSchemeCodeUnspsc190501.46151605'),
	'identifierSchemeCodeUnspsc190501.46151606': __('identifierSchemeCodeUnspsc190501.46151606'),
	'identifierSchemeCodeUnspsc190501.46151607': __('identifierSchemeCodeUnspsc190501.46151607'),
	'identifierSchemeCodeUnspsc190501.46151608': __('identifierSchemeCodeUnspsc190501.46151608'),
	'identifierSchemeCodeUnspsc190501.46151609': __('identifierSchemeCodeUnspsc190501.46151609'),
	'identifierSchemeCodeUnspsc190501.46151700': __('identifierSchemeCodeUnspsc190501.46151700'),
	'identifierSchemeCodeUnspsc190501.46151702': __('identifierSchemeCodeUnspsc190501.46151702'),
	'identifierSchemeCodeUnspsc190501.46151703': __('identifierSchemeCodeUnspsc190501.46151703'),
	'identifierSchemeCodeUnspsc190501.46151704': __('identifierSchemeCodeUnspsc190501.46151704'),
	'identifierSchemeCodeUnspsc190501.46151705': __('identifierSchemeCodeUnspsc190501.46151705'),
	'identifierSchemeCodeUnspsc190501.46151706': __('identifierSchemeCodeUnspsc190501.46151706'),
	'identifierSchemeCodeUnspsc190501.46151707': __('identifierSchemeCodeUnspsc190501.46151707'),
	'identifierSchemeCodeUnspsc190501.46151708': __('identifierSchemeCodeUnspsc190501.46151708'),
	'identifierSchemeCodeUnspsc190501.46151709': __('identifierSchemeCodeUnspsc190501.46151709'),
	'identifierSchemeCodeUnspsc190501.46151710': __('identifierSchemeCodeUnspsc190501.46151710'),
	'identifierSchemeCodeUnspsc190501.46151711': __('identifierSchemeCodeUnspsc190501.46151711'),
	'identifierSchemeCodeUnspsc190501.46151712': __('identifierSchemeCodeUnspsc190501.46151712'),
	'identifierSchemeCodeUnspsc190501.46151713': __('identifierSchemeCodeUnspsc190501.46151713'),
	'identifierSchemeCodeUnspsc190501.46151714': __('identifierSchemeCodeUnspsc190501.46151714'),
	'identifierSchemeCodeUnspsc190501.46151715': __('identifierSchemeCodeUnspsc190501.46151715'),
	'identifierSchemeCodeUnspsc190501.46151716': __('identifierSchemeCodeUnspsc190501.46151716'),
	'identifierSchemeCodeUnspsc190501.46151800': __('identifierSchemeCodeUnspsc190501.46151800'),
	'identifierSchemeCodeUnspsc190501.46151801': __('identifierSchemeCodeUnspsc190501.46151801'),
	'identifierSchemeCodeUnspsc190501.46151802': __('identifierSchemeCodeUnspsc190501.46151802'),
	'identifierSchemeCodeUnspsc190501.46151900': __('identifierSchemeCodeUnspsc190501.46151900'),
	'identifierSchemeCodeUnspsc190501.46151901': __('identifierSchemeCodeUnspsc190501.46151901'),
	'identifierSchemeCodeUnspsc190501.46160000': __('identifierSchemeCodeUnspsc190501.46160000'),
	'identifierSchemeCodeUnspsc190501.46161500': __('identifierSchemeCodeUnspsc190501.46161500'),
	'identifierSchemeCodeUnspsc190501.46161501': __('identifierSchemeCodeUnspsc190501.46161501'),
	'identifierSchemeCodeUnspsc190501.46161502': __('identifierSchemeCodeUnspsc190501.46161502'),
	'identifierSchemeCodeUnspsc190501.46161503': __('identifierSchemeCodeUnspsc190501.46161503'),
	'identifierSchemeCodeUnspsc190501.46161504': __('identifierSchemeCodeUnspsc190501.46161504'),
	'identifierSchemeCodeUnspsc190501.46161505': __('identifierSchemeCodeUnspsc190501.46161505'),
	'identifierSchemeCodeUnspsc190501.46161506': __('identifierSchemeCodeUnspsc190501.46161506'),
	'identifierSchemeCodeUnspsc190501.46161507': __('identifierSchemeCodeUnspsc190501.46161507'),
	'identifierSchemeCodeUnspsc190501.46161508': __('identifierSchemeCodeUnspsc190501.46161508'),
	'identifierSchemeCodeUnspsc190501.46161509': __('identifierSchemeCodeUnspsc190501.46161509'),
	'identifierSchemeCodeUnspsc190501.46161510': __('identifierSchemeCodeUnspsc190501.46161510'),
	'identifierSchemeCodeUnspsc190501.46161511': __('identifierSchemeCodeUnspsc190501.46161511'),
	'identifierSchemeCodeUnspsc190501.46161512': __('identifierSchemeCodeUnspsc190501.46161512'),
	'identifierSchemeCodeUnspsc190501.46161513': __('identifierSchemeCodeUnspsc190501.46161513'),
	'identifierSchemeCodeUnspsc190501.46161514': __('identifierSchemeCodeUnspsc190501.46161514'),
	'identifierSchemeCodeUnspsc190501.46161515': __('identifierSchemeCodeUnspsc190501.46161515'),
	'identifierSchemeCodeUnspsc190501.46161516': __('identifierSchemeCodeUnspsc190501.46161516'),
	'identifierSchemeCodeUnspsc190501.46161517': __('identifierSchemeCodeUnspsc190501.46161517'),
	'identifierSchemeCodeUnspsc190501.46161518': __('identifierSchemeCodeUnspsc190501.46161518'),
	'identifierSchemeCodeUnspsc190501.46161519': __('identifierSchemeCodeUnspsc190501.46161519'),
	'identifierSchemeCodeUnspsc190501.46161520': __('identifierSchemeCodeUnspsc190501.46161520'),
	'identifierSchemeCodeUnspsc190501.46161521': __('identifierSchemeCodeUnspsc190501.46161521'),
	'identifierSchemeCodeUnspsc190501.46161522': __('identifierSchemeCodeUnspsc190501.46161522'),
	'identifierSchemeCodeUnspsc190501.46161523': __('identifierSchemeCodeUnspsc190501.46161523'),
	'identifierSchemeCodeUnspsc190501.46161524': __('identifierSchemeCodeUnspsc190501.46161524'),
	'identifierSchemeCodeUnspsc190501.46161525': __('identifierSchemeCodeUnspsc190501.46161525'),
	'identifierSchemeCodeUnspsc190501.46161526': __('identifierSchemeCodeUnspsc190501.46161526'),
	'identifierSchemeCodeUnspsc190501.46161527': __('identifierSchemeCodeUnspsc190501.46161527'),
	'identifierSchemeCodeUnspsc190501.46161528': __('identifierSchemeCodeUnspsc190501.46161528'),
	'identifierSchemeCodeUnspsc190501.46161529': __('identifierSchemeCodeUnspsc190501.46161529'),
	'identifierSchemeCodeUnspsc190501.46161530': __('identifierSchemeCodeUnspsc190501.46161530'),
	'identifierSchemeCodeUnspsc190501.46161531': __('identifierSchemeCodeUnspsc190501.46161531'),
	'identifierSchemeCodeUnspsc190501.46161532': __('identifierSchemeCodeUnspsc190501.46161532'),
	'identifierSchemeCodeUnspsc190501.46161533': __('identifierSchemeCodeUnspsc190501.46161533'),
	'identifierSchemeCodeUnspsc190501.46161534': __('identifierSchemeCodeUnspsc190501.46161534'),
	'identifierSchemeCodeUnspsc190501.46161600': __('identifierSchemeCodeUnspsc190501.46161600'),
	'identifierSchemeCodeUnspsc190501.46161601': __('identifierSchemeCodeUnspsc190501.46161601'),
	'identifierSchemeCodeUnspsc190501.46161602': __('identifierSchemeCodeUnspsc190501.46161602'),
	'identifierSchemeCodeUnspsc190501.46161603': __('identifierSchemeCodeUnspsc190501.46161603'),
	'identifierSchemeCodeUnspsc190501.46161604': __('identifierSchemeCodeUnspsc190501.46161604'),
	'identifierSchemeCodeUnspsc190501.46161605': __('identifierSchemeCodeUnspsc190501.46161605'),
	'identifierSchemeCodeUnspsc190501.46161700': __('identifierSchemeCodeUnspsc190501.46161700'),
	'identifierSchemeCodeUnspsc190501.46161701': __('identifierSchemeCodeUnspsc190501.46161701'),
	'identifierSchemeCodeUnspsc190501.46161702': __('identifierSchemeCodeUnspsc190501.46161702'),
	'identifierSchemeCodeUnspsc190501.46161703': __('identifierSchemeCodeUnspsc190501.46161703'),
	'identifierSchemeCodeUnspsc190501.46161704': __('identifierSchemeCodeUnspsc190501.46161704'),
	'identifierSchemeCodeUnspsc190501.46161705': __('identifierSchemeCodeUnspsc190501.46161705'),
	'identifierSchemeCodeUnspsc190501.46161706': __('identifierSchemeCodeUnspsc190501.46161706'),
	'identifierSchemeCodeUnspsc190501.46161707': __('identifierSchemeCodeUnspsc190501.46161707'),
	'identifierSchemeCodeUnspsc190501.46161708': __('identifierSchemeCodeUnspsc190501.46161708'),
	'identifierSchemeCodeUnspsc190501.46161710': __('identifierSchemeCodeUnspsc190501.46161710'),
	'identifierSchemeCodeUnspsc190501.46161711': __('identifierSchemeCodeUnspsc190501.46161711'),
	'identifierSchemeCodeUnspsc190501.46161712': __('identifierSchemeCodeUnspsc190501.46161712'),
	'identifierSchemeCodeUnspsc190501.46161713': __('identifierSchemeCodeUnspsc190501.46161713'),
	'identifierSchemeCodeUnspsc190501.46161714': __('identifierSchemeCodeUnspsc190501.46161714'),
	'identifierSchemeCodeUnspsc190501.46161715': __('identifierSchemeCodeUnspsc190501.46161715'),
	'identifierSchemeCodeUnspsc190501.46170000': __('identifierSchemeCodeUnspsc190501.46170000'),
	'identifierSchemeCodeUnspsc190501.46171500': __('identifierSchemeCodeUnspsc190501.46171500'),
	'identifierSchemeCodeUnspsc190501.46171501': __('identifierSchemeCodeUnspsc190501.46171501'),
	'identifierSchemeCodeUnspsc190501.46171502': __('identifierSchemeCodeUnspsc190501.46171502'),
	'identifierSchemeCodeUnspsc190501.46171503': __('identifierSchemeCodeUnspsc190501.46171503'),
	'identifierSchemeCodeUnspsc190501.46171504': __('identifierSchemeCodeUnspsc190501.46171504'),
	'identifierSchemeCodeUnspsc190501.46171505': __('identifierSchemeCodeUnspsc190501.46171505'),
	'identifierSchemeCodeUnspsc190501.46171506': __('identifierSchemeCodeUnspsc190501.46171506'),
	'identifierSchemeCodeUnspsc190501.46171507': __('identifierSchemeCodeUnspsc190501.46171507'),
	'identifierSchemeCodeUnspsc190501.46171508': __('identifierSchemeCodeUnspsc190501.46171508'),
	'identifierSchemeCodeUnspsc190501.46171509': __('identifierSchemeCodeUnspsc190501.46171509'),
	'identifierSchemeCodeUnspsc190501.46171510': __('identifierSchemeCodeUnspsc190501.46171510'),
	'identifierSchemeCodeUnspsc190501.46171511': __('identifierSchemeCodeUnspsc190501.46171511'),
	'identifierSchemeCodeUnspsc190501.46171512': __('identifierSchemeCodeUnspsc190501.46171512'),
	'identifierSchemeCodeUnspsc190501.46171513': __('identifierSchemeCodeUnspsc190501.46171513'),
	'identifierSchemeCodeUnspsc190501.46171514': __('identifierSchemeCodeUnspsc190501.46171514'),
	'identifierSchemeCodeUnspsc190501.46171515': __('identifierSchemeCodeUnspsc190501.46171515'),
	'identifierSchemeCodeUnspsc190501.46171516': __('identifierSchemeCodeUnspsc190501.46171516'),
	'identifierSchemeCodeUnspsc190501.46171517': __('identifierSchemeCodeUnspsc190501.46171517'),
	'identifierSchemeCodeUnspsc190501.46171518': __('identifierSchemeCodeUnspsc190501.46171518'),
	'identifierSchemeCodeUnspsc190501.46171519': __('identifierSchemeCodeUnspsc190501.46171519'),
	'identifierSchemeCodeUnspsc190501.46171520': __('identifierSchemeCodeUnspsc190501.46171520'),
	'identifierSchemeCodeUnspsc190501.46171521': __('identifierSchemeCodeUnspsc190501.46171521'),
	'identifierSchemeCodeUnspsc190501.46171522': __('identifierSchemeCodeUnspsc190501.46171522'),
	'identifierSchemeCodeUnspsc190501.46171523': __('identifierSchemeCodeUnspsc190501.46171523'),
	'identifierSchemeCodeUnspsc190501.46171524': __('identifierSchemeCodeUnspsc190501.46171524'),
	'identifierSchemeCodeUnspsc190501.46171525': __('identifierSchemeCodeUnspsc190501.46171525'),
	'identifierSchemeCodeUnspsc190501.46171600': __('identifierSchemeCodeUnspsc190501.46171600'),
	'identifierSchemeCodeUnspsc190501.46171602': __('identifierSchemeCodeUnspsc190501.46171602'),
	'identifierSchemeCodeUnspsc190501.46171603': __('identifierSchemeCodeUnspsc190501.46171603'),
	'identifierSchemeCodeUnspsc190501.46171604': __('identifierSchemeCodeUnspsc190501.46171604'),
	'identifierSchemeCodeUnspsc190501.46171605': __('identifierSchemeCodeUnspsc190501.46171605'),
	'identifierSchemeCodeUnspsc190501.46171606': __('identifierSchemeCodeUnspsc190501.46171606'),
	'identifierSchemeCodeUnspsc190501.46171607': __('identifierSchemeCodeUnspsc190501.46171607'),
	'identifierSchemeCodeUnspsc190501.46171608': __('identifierSchemeCodeUnspsc190501.46171608'),
	'identifierSchemeCodeUnspsc190501.46171609': __('identifierSchemeCodeUnspsc190501.46171609'),
	'identifierSchemeCodeUnspsc190501.46171610': __('identifierSchemeCodeUnspsc190501.46171610'),
	'identifierSchemeCodeUnspsc190501.46171611': __('identifierSchemeCodeUnspsc190501.46171611'),
	'identifierSchemeCodeUnspsc190501.46171612': __('identifierSchemeCodeUnspsc190501.46171612'),
	'identifierSchemeCodeUnspsc190501.46171613': __('identifierSchemeCodeUnspsc190501.46171613'),
	'identifierSchemeCodeUnspsc190501.46171615': __('identifierSchemeCodeUnspsc190501.46171615'),
	'identifierSchemeCodeUnspsc190501.46171616': __('identifierSchemeCodeUnspsc190501.46171616'),
	'identifierSchemeCodeUnspsc190501.46171617': __('identifierSchemeCodeUnspsc190501.46171617'),
	'identifierSchemeCodeUnspsc190501.46171618': __('identifierSchemeCodeUnspsc190501.46171618'),
	'identifierSchemeCodeUnspsc190501.46171619': __('identifierSchemeCodeUnspsc190501.46171619'),
	'identifierSchemeCodeUnspsc190501.46171620': __('identifierSchemeCodeUnspsc190501.46171620'),
	'identifierSchemeCodeUnspsc190501.46171621': __('identifierSchemeCodeUnspsc190501.46171621'),
	'identifierSchemeCodeUnspsc190501.46171622': __('identifierSchemeCodeUnspsc190501.46171622'),
	'identifierSchemeCodeUnspsc190501.46171623': __('identifierSchemeCodeUnspsc190501.46171623'),
	'identifierSchemeCodeUnspsc190501.46171624': __('identifierSchemeCodeUnspsc190501.46171624'),
	'identifierSchemeCodeUnspsc190501.46171625': __('identifierSchemeCodeUnspsc190501.46171625'),
	'identifierSchemeCodeUnspsc190501.46171626': __('identifierSchemeCodeUnspsc190501.46171626'),
	'identifierSchemeCodeUnspsc190501.46171627': __('identifierSchemeCodeUnspsc190501.46171627'),
	'identifierSchemeCodeUnspsc190501.46171628': __('identifierSchemeCodeUnspsc190501.46171628'),
	'identifierSchemeCodeUnspsc190501.46171629': __('identifierSchemeCodeUnspsc190501.46171629'),
	'identifierSchemeCodeUnspsc190501.46171630': __('identifierSchemeCodeUnspsc190501.46171630'),
	'identifierSchemeCodeUnspsc190501.46171631': __('identifierSchemeCodeUnspsc190501.46171631'),
	'identifierSchemeCodeUnspsc190501.46171632': __('identifierSchemeCodeUnspsc190501.46171632'),
	'identifierSchemeCodeUnspsc190501.46171633': __('identifierSchemeCodeUnspsc190501.46171633'),
	'identifierSchemeCodeUnspsc190501.46171634': __('identifierSchemeCodeUnspsc190501.46171634'),
	'identifierSchemeCodeUnspsc190501.46171635': __('identifierSchemeCodeUnspsc190501.46171635'),
	'identifierSchemeCodeUnspsc190501.46171636': __('identifierSchemeCodeUnspsc190501.46171636'),
	'identifierSchemeCodeUnspsc190501.46171637': __('identifierSchemeCodeUnspsc190501.46171637'),
	'identifierSchemeCodeUnspsc190501.46171638': __('identifierSchemeCodeUnspsc190501.46171638'),
	'identifierSchemeCodeUnspsc190501.46171639': __('identifierSchemeCodeUnspsc190501.46171639'),
	'identifierSchemeCodeUnspsc190501.46171640': __('identifierSchemeCodeUnspsc190501.46171640'),
	'identifierSchemeCodeUnspsc190501.46171641': __('identifierSchemeCodeUnspsc190501.46171641'),
	'identifierSchemeCodeUnspsc190501.46171700': __('identifierSchemeCodeUnspsc190501.46171700'),
	'identifierSchemeCodeUnspsc190501.46171701': __('identifierSchemeCodeUnspsc190501.46171701'),
	'identifierSchemeCodeUnspsc190501.46180000': __('identifierSchemeCodeUnspsc190501.46180000'),
	'identifierSchemeCodeUnspsc190501.46181500': __('identifierSchemeCodeUnspsc190501.46181500'),
	'identifierSchemeCodeUnspsc190501.46181501': __('identifierSchemeCodeUnspsc190501.46181501'),
	'identifierSchemeCodeUnspsc190501.46181502': __('identifierSchemeCodeUnspsc190501.46181502'),
	'identifierSchemeCodeUnspsc190501.46181503': __('identifierSchemeCodeUnspsc190501.46181503'),
	'identifierSchemeCodeUnspsc190501.46181504': __('identifierSchemeCodeUnspsc190501.46181504'),
	'identifierSchemeCodeUnspsc190501.46181505': __('identifierSchemeCodeUnspsc190501.46181505'),
	'identifierSchemeCodeUnspsc190501.46181506': __('identifierSchemeCodeUnspsc190501.46181506'),
	'identifierSchemeCodeUnspsc190501.46181507': __('identifierSchemeCodeUnspsc190501.46181507'),
	'identifierSchemeCodeUnspsc190501.46181508': __('identifierSchemeCodeUnspsc190501.46181508'),
	'identifierSchemeCodeUnspsc190501.46181509': __('identifierSchemeCodeUnspsc190501.46181509'),
	'identifierSchemeCodeUnspsc190501.46181512': __('identifierSchemeCodeUnspsc190501.46181512'),
	'identifierSchemeCodeUnspsc190501.46181514': __('identifierSchemeCodeUnspsc190501.46181514'),
	'identifierSchemeCodeUnspsc190501.46181516': __('identifierSchemeCodeUnspsc190501.46181516'),
	'identifierSchemeCodeUnspsc190501.46181517': __('identifierSchemeCodeUnspsc190501.46181517'),
	'identifierSchemeCodeUnspsc190501.46181518': __('identifierSchemeCodeUnspsc190501.46181518'),
	'identifierSchemeCodeUnspsc190501.46181520': __('identifierSchemeCodeUnspsc190501.46181520'),
	'identifierSchemeCodeUnspsc190501.46181522': __('identifierSchemeCodeUnspsc190501.46181522'),
	'identifierSchemeCodeUnspsc190501.46181526': __('identifierSchemeCodeUnspsc190501.46181526'),
	'identifierSchemeCodeUnspsc190501.46181527': __('identifierSchemeCodeUnspsc190501.46181527'),
	'identifierSchemeCodeUnspsc190501.46181528': __('identifierSchemeCodeUnspsc190501.46181528'),
	'identifierSchemeCodeUnspsc190501.46181529': __('identifierSchemeCodeUnspsc190501.46181529'),
	'identifierSchemeCodeUnspsc190501.46181530': __('identifierSchemeCodeUnspsc190501.46181530'),
	'identifierSchemeCodeUnspsc190501.46181531': __('identifierSchemeCodeUnspsc190501.46181531'),
	'identifierSchemeCodeUnspsc190501.46181532': __('identifierSchemeCodeUnspsc190501.46181532'),
	'identifierSchemeCodeUnspsc190501.46181533': __('identifierSchemeCodeUnspsc190501.46181533'),
	'identifierSchemeCodeUnspsc190501.46181534': __('identifierSchemeCodeUnspsc190501.46181534'),
	'identifierSchemeCodeUnspsc190501.46181535': __('identifierSchemeCodeUnspsc190501.46181535'),
	'identifierSchemeCodeUnspsc190501.46181536': __('identifierSchemeCodeUnspsc190501.46181536'),
	'identifierSchemeCodeUnspsc190501.46181537': __('identifierSchemeCodeUnspsc190501.46181537'),
	'identifierSchemeCodeUnspsc190501.46181538': __('identifierSchemeCodeUnspsc190501.46181538'),
	'identifierSchemeCodeUnspsc190501.46181539': __('identifierSchemeCodeUnspsc190501.46181539'),
	'identifierSchemeCodeUnspsc190501.46181540': __('identifierSchemeCodeUnspsc190501.46181540'),
	'identifierSchemeCodeUnspsc190501.46181541': __('identifierSchemeCodeUnspsc190501.46181541'),
	'identifierSchemeCodeUnspsc190501.46181542': __('identifierSchemeCodeUnspsc190501.46181542'),
	'identifierSchemeCodeUnspsc190501.46181543': __('identifierSchemeCodeUnspsc190501.46181543'),
	'identifierSchemeCodeUnspsc190501.46181544': __('identifierSchemeCodeUnspsc190501.46181544'),
	'identifierSchemeCodeUnspsc190501.46181545': __('identifierSchemeCodeUnspsc190501.46181545'),
	'identifierSchemeCodeUnspsc190501.46181546': __('identifierSchemeCodeUnspsc190501.46181546'),
	'identifierSchemeCodeUnspsc190501.46181547': __('identifierSchemeCodeUnspsc190501.46181547'),
	'identifierSchemeCodeUnspsc190501.46181548': __('identifierSchemeCodeUnspsc190501.46181548'),
	'identifierSchemeCodeUnspsc190501.46181549': __('identifierSchemeCodeUnspsc190501.46181549'),
	'identifierSchemeCodeUnspsc190501.46181550': __('identifierSchemeCodeUnspsc190501.46181550'),
	'identifierSchemeCodeUnspsc190501.46181551': __('identifierSchemeCodeUnspsc190501.46181551'),
	'identifierSchemeCodeUnspsc190501.46181552': __('identifierSchemeCodeUnspsc190501.46181552'),
	'identifierSchemeCodeUnspsc190501.46181553': __('identifierSchemeCodeUnspsc190501.46181553'),
	'identifierSchemeCodeUnspsc190501.46181600': __('identifierSchemeCodeUnspsc190501.46181600'),
	'identifierSchemeCodeUnspsc190501.46181601': __('identifierSchemeCodeUnspsc190501.46181601'),
	'identifierSchemeCodeUnspsc190501.46181602': __('identifierSchemeCodeUnspsc190501.46181602'),
	'identifierSchemeCodeUnspsc190501.46181603': __('identifierSchemeCodeUnspsc190501.46181603'),
	'identifierSchemeCodeUnspsc190501.46181604': __('identifierSchemeCodeUnspsc190501.46181604'),
	'identifierSchemeCodeUnspsc190501.46181605': __('identifierSchemeCodeUnspsc190501.46181605'),
	'identifierSchemeCodeUnspsc190501.46181606': __('identifierSchemeCodeUnspsc190501.46181606'),
	'identifierSchemeCodeUnspsc190501.46181607': __('identifierSchemeCodeUnspsc190501.46181607'),
	'identifierSchemeCodeUnspsc190501.46181608': __('identifierSchemeCodeUnspsc190501.46181608'),
	'identifierSchemeCodeUnspsc190501.46181609': __('identifierSchemeCodeUnspsc190501.46181609'),
	'identifierSchemeCodeUnspsc190501.46181610': __('identifierSchemeCodeUnspsc190501.46181610'),
	'identifierSchemeCodeUnspsc190501.46181611': __('identifierSchemeCodeUnspsc190501.46181611'),
	'identifierSchemeCodeUnspsc190501.46181612': __('identifierSchemeCodeUnspsc190501.46181612'),
	'identifierSchemeCodeUnspsc190501.46181613': __('identifierSchemeCodeUnspsc190501.46181613'),
	'identifierSchemeCodeUnspsc190501.46181700': __('identifierSchemeCodeUnspsc190501.46181700'),
	'identifierSchemeCodeUnspsc190501.46181701': __('identifierSchemeCodeUnspsc190501.46181701'),
	'identifierSchemeCodeUnspsc190501.46181702': __('identifierSchemeCodeUnspsc190501.46181702'),
	'identifierSchemeCodeUnspsc190501.46181703': __('identifierSchemeCodeUnspsc190501.46181703'),
	'identifierSchemeCodeUnspsc190501.46181704': __('identifierSchemeCodeUnspsc190501.46181704'),
	'identifierSchemeCodeUnspsc190501.46181705': __('identifierSchemeCodeUnspsc190501.46181705'),
	'identifierSchemeCodeUnspsc190501.46181706': __('identifierSchemeCodeUnspsc190501.46181706'),
	'identifierSchemeCodeUnspsc190501.46181707': __('identifierSchemeCodeUnspsc190501.46181707'),
	'identifierSchemeCodeUnspsc190501.46181708': __('identifierSchemeCodeUnspsc190501.46181708'),
	'identifierSchemeCodeUnspsc190501.46181709': __('identifierSchemeCodeUnspsc190501.46181709'),
	'identifierSchemeCodeUnspsc190501.46181710': __('identifierSchemeCodeUnspsc190501.46181710'),
	'identifierSchemeCodeUnspsc190501.46181711': __('identifierSchemeCodeUnspsc190501.46181711'),
	'identifierSchemeCodeUnspsc190501.46181800': __('identifierSchemeCodeUnspsc190501.46181800'),
	'identifierSchemeCodeUnspsc190501.46181801': __('identifierSchemeCodeUnspsc190501.46181801'),
	'identifierSchemeCodeUnspsc190501.46181802': __('identifierSchemeCodeUnspsc190501.46181802'),
	'identifierSchemeCodeUnspsc190501.46181803': __('identifierSchemeCodeUnspsc190501.46181803'),
	'identifierSchemeCodeUnspsc190501.46181804': __('identifierSchemeCodeUnspsc190501.46181804'),
	'identifierSchemeCodeUnspsc190501.46181805': __('identifierSchemeCodeUnspsc190501.46181805'),
	'identifierSchemeCodeUnspsc190501.46181806': __('identifierSchemeCodeUnspsc190501.46181806'),
	'identifierSchemeCodeUnspsc190501.46181808': __('identifierSchemeCodeUnspsc190501.46181808'),
	'identifierSchemeCodeUnspsc190501.46181809': __('identifierSchemeCodeUnspsc190501.46181809'),
	'identifierSchemeCodeUnspsc190501.46181810': __('identifierSchemeCodeUnspsc190501.46181810'),
	'identifierSchemeCodeUnspsc190501.46181811': __('identifierSchemeCodeUnspsc190501.46181811'),
	'identifierSchemeCodeUnspsc190501.46181900': __('identifierSchemeCodeUnspsc190501.46181900'),
	'identifierSchemeCodeUnspsc190501.46181902': __('identifierSchemeCodeUnspsc190501.46181902'),
	'identifierSchemeCodeUnspsc190501.46181903': __('identifierSchemeCodeUnspsc190501.46181903'),
	'identifierSchemeCodeUnspsc190501.46181904': __('identifierSchemeCodeUnspsc190501.46181904'),
	'identifierSchemeCodeUnspsc190501.46182000': __('identifierSchemeCodeUnspsc190501.46182000'),
	'identifierSchemeCodeUnspsc190501.46182001': __('identifierSchemeCodeUnspsc190501.46182001'),
	'identifierSchemeCodeUnspsc190501.46182002': __('identifierSchemeCodeUnspsc190501.46182002'),
	'identifierSchemeCodeUnspsc190501.46182003': __('identifierSchemeCodeUnspsc190501.46182003'),
	'identifierSchemeCodeUnspsc190501.46182004': __('identifierSchemeCodeUnspsc190501.46182004'),
	'identifierSchemeCodeUnspsc190501.46182005': __('identifierSchemeCodeUnspsc190501.46182005'),
	'identifierSchemeCodeUnspsc190501.46182006': __('identifierSchemeCodeUnspsc190501.46182006'),
	'identifierSchemeCodeUnspsc190501.46182007': __('identifierSchemeCodeUnspsc190501.46182007'),
	'identifierSchemeCodeUnspsc190501.46182100': __('identifierSchemeCodeUnspsc190501.46182100'),
	'identifierSchemeCodeUnspsc190501.46182101': __('identifierSchemeCodeUnspsc190501.46182101'),
	'identifierSchemeCodeUnspsc190501.46182102': __('identifierSchemeCodeUnspsc190501.46182102'),
	'identifierSchemeCodeUnspsc190501.46182103': __('identifierSchemeCodeUnspsc190501.46182103'),
	'identifierSchemeCodeUnspsc190501.46182104': __('identifierSchemeCodeUnspsc190501.46182104'),
	'identifierSchemeCodeUnspsc190501.46182105': __('identifierSchemeCodeUnspsc190501.46182105'),
	'identifierSchemeCodeUnspsc190501.46182106': __('identifierSchemeCodeUnspsc190501.46182106'),
	'identifierSchemeCodeUnspsc190501.46182107': __('identifierSchemeCodeUnspsc190501.46182107'),
	'identifierSchemeCodeUnspsc190501.46182108': __('identifierSchemeCodeUnspsc190501.46182108'),
	'identifierSchemeCodeUnspsc190501.46182200': __('identifierSchemeCodeUnspsc190501.46182200'),
	'identifierSchemeCodeUnspsc190501.46182201': __('identifierSchemeCodeUnspsc190501.46182201'),
	'identifierSchemeCodeUnspsc190501.46182202': __('identifierSchemeCodeUnspsc190501.46182202'),
	'identifierSchemeCodeUnspsc190501.46182203': __('identifierSchemeCodeUnspsc190501.46182203'),
	'identifierSchemeCodeUnspsc190501.46182204': __('identifierSchemeCodeUnspsc190501.46182204'),
	'identifierSchemeCodeUnspsc190501.46182205': __('identifierSchemeCodeUnspsc190501.46182205'),
	'identifierSchemeCodeUnspsc190501.46182206': __('identifierSchemeCodeUnspsc190501.46182206'),
	'identifierSchemeCodeUnspsc190501.46182207': __('identifierSchemeCodeUnspsc190501.46182207'),
	'identifierSchemeCodeUnspsc190501.46182208': __('identifierSchemeCodeUnspsc190501.46182208'),
	'identifierSchemeCodeUnspsc190501.46182209': __('identifierSchemeCodeUnspsc190501.46182209'),
	'identifierSchemeCodeUnspsc190501.46182210': __('identifierSchemeCodeUnspsc190501.46182210'),
	'identifierSchemeCodeUnspsc190501.46182211': __('identifierSchemeCodeUnspsc190501.46182211'),
	'identifierSchemeCodeUnspsc190501.46182212': __('identifierSchemeCodeUnspsc190501.46182212'),
	'identifierSchemeCodeUnspsc190501.46182213': __('identifierSchemeCodeUnspsc190501.46182213'),
	'identifierSchemeCodeUnspsc190501.46182300': __('identifierSchemeCodeUnspsc190501.46182300'),
	'identifierSchemeCodeUnspsc190501.46182301': __('identifierSchemeCodeUnspsc190501.46182301'),
	'identifierSchemeCodeUnspsc190501.46182302': __('identifierSchemeCodeUnspsc190501.46182302'),
	'identifierSchemeCodeUnspsc190501.46182303': __('identifierSchemeCodeUnspsc190501.46182303'),
	'identifierSchemeCodeUnspsc190501.46182304': __('identifierSchemeCodeUnspsc190501.46182304'),
	'identifierSchemeCodeUnspsc190501.46182305': __('identifierSchemeCodeUnspsc190501.46182305'),
	'identifierSchemeCodeUnspsc190501.46182306': __('identifierSchemeCodeUnspsc190501.46182306'),
	'identifierSchemeCodeUnspsc190501.46182307': __('identifierSchemeCodeUnspsc190501.46182307'),
	'identifierSchemeCodeUnspsc190501.46182308': __('identifierSchemeCodeUnspsc190501.46182308'),
	'identifierSchemeCodeUnspsc190501.46182309': __('identifierSchemeCodeUnspsc190501.46182309'),
	'identifierSchemeCodeUnspsc190501.46182310': __('identifierSchemeCodeUnspsc190501.46182310'),
	'identifierSchemeCodeUnspsc190501.46182311': __('identifierSchemeCodeUnspsc190501.46182311'),
	'identifierSchemeCodeUnspsc190501.46182312': __('identifierSchemeCodeUnspsc190501.46182312'),
	'identifierSchemeCodeUnspsc190501.46182313': __('identifierSchemeCodeUnspsc190501.46182313'),
	'identifierSchemeCodeUnspsc190501.46182314': __('identifierSchemeCodeUnspsc190501.46182314'),
	'identifierSchemeCodeUnspsc190501.46182315': __('identifierSchemeCodeUnspsc190501.46182315'),
	'identifierSchemeCodeUnspsc190501.46182400': __('identifierSchemeCodeUnspsc190501.46182400'),
	'identifierSchemeCodeUnspsc190501.46182401': __('identifierSchemeCodeUnspsc190501.46182401'),
	'identifierSchemeCodeUnspsc190501.46182402': __('identifierSchemeCodeUnspsc190501.46182402'),
	'identifierSchemeCodeUnspsc190501.46182403': __('identifierSchemeCodeUnspsc190501.46182403'),
	'identifierSchemeCodeUnspsc190501.46182404': __('identifierSchemeCodeUnspsc190501.46182404'),
	'identifierSchemeCodeUnspsc190501.46182405': __('identifierSchemeCodeUnspsc190501.46182405'),
	'identifierSchemeCodeUnspsc190501.46182406': __('identifierSchemeCodeUnspsc190501.46182406'),
	'identifierSchemeCodeUnspsc190501.46182407': __('identifierSchemeCodeUnspsc190501.46182407'),
	'identifierSchemeCodeUnspsc190501.46182500': __('identifierSchemeCodeUnspsc190501.46182500'),
	'identifierSchemeCodeUnspsc190501.46182501': __('identifierSchemeCodeUnspsc190501.46182501'),
	'identifierSchemeCodeUnspsc190501.46182502': __('identifierSchemeCodeUnspsc190501.46182502'),
	'identifierSchemeCodeUnspsc190501.46182503': __('identifierSchemeCodeUnspsc190501.46182503'),
	'identifierSchemeCodeUnspsc190501.46182504': __('identifierSchemeCodeUnspsc190501.46182504'),
	'identifierSchemeCodeUnspsc190501.46182505': __('identifierSchemeCodeUnspsc190501.46182505'),
	'identifierSchemeCodeUnspsc190501.46182506': __('identifierSchemeCodeUnspsc190501.46182506'),
	'identifierSchemeCodeUnspsc190501.46182507': __('identifierSchemeCodeUnspsc190501.46182507'),
	'identifierSchemeCodeUnspsc190501.46190000': __('identifierSchemeCodeUnspsc190501.46190000'),
	'identifierSchemeCodeUnspsc190501.46191500': __('identifierSchemeCodeUnspsc190501.46191500'),
	'identifierSchemeCodeUnspsc190501.46191501': __('identifierSchemeCodeUnspsc190501.46191501'),
	'identifierSchemeCodeUnspsc190501.46191502': __('identifierSchemeCodeUnspsc190501.46191502'),
	'identifierSchemeCodeUnspsc190501.46191503': __('identifierSchemeCodeUnspsc190501.46191503'),
	'identifierSchemeCodeUnspsc190501.46191504': __('identifierSchemeCodeUnspsc190501.46191504'),
	'identifierSchemeCodeUnspsc190501.46191505': __('identifierSchemeCodeUnspsc190501.46191505'),
	'identifierSchemeCodeUnspsc190501.46191506': __('identifierSchemeCodeUnspsc190501.46191506'),
	'identifierSchemeCodeUnspsc190501.46191507': __('identifierSchemeCodeUnspsc190501.46191507'),
	'identifierSchemeCodeUnspsc190501.46191508': __('identifierSchemeCodeUnspsc190501.46191508'),
	'identifierSchemeCodeUnspsc190501.46191509': __('identifierSchemeCodeUnspsc190501.46191509'),
	'identifierSchemeCodeUnspsc190501.46191510': __('identifierSchemeCodeUnspsc190501.46191510'),
	'identifierSchemeCodeUnspsc190501.46191511': __('identifierSchemeCodeUnspsc190501.46191511'),
	'identifierSchemeCodeUnspsc190501.46191600': __('identifierSchemeCodeUnspsc190501.46191600'),
	'identifierSchemeCodeUnspsc190501.46191601': __('identifierSchemeCodeUnspsc190501.46191601'),
	'identifierSchemeCodeUnspsc190501.46191602': __('identifierSchemeCodeUnspsc190501.46191602'),
	'identifierSchemeCodeUnspsc190501.46191603': __('identifierSchemeCodeUnspsc190501.46191603'),
	'identifierSchemeCodeUnspsc190501.46191604': __('identifierSchemeCodeUnspsc190501.46191604'),
	'identifierSchemeCodeUnspsc190501.46191605': __('identifierSchemeCodeUnspsc190501.46191605'),
	'identifierSchemeCodeUnspsc190501.46191606': __('identifierSchemeCodeUnspsc190501.46191606'),
	'identifierSchemeCodeUnspsc190501.46191607': __('identifierSchemeCodeUnspsc190501.46191607'),
	'identifierSchemeCodeUnspsc190501.46191608': __('identifierSchemeCodeUnspsc190501.46191608'),
	'identifierSchemeCodeUnspsc190501.46191609': __('identifierSchemeCodeUnspsc190501.46191609'),
	'identifierSchemeCodeUnspsc190501.46191610': __('identifierSchemeCodeUnspsc190501.46191610'),
	'identifierSchemeCodeUnspsc190501.46191611': __('identifierSchemeCodeUnspsc190501.46191611'),
	'identifierSchemeCodeUnspsc190501.46191612': __('identifierSchemeCodeUnspsc190501.46191612'),
	'identifierSchemeCodeUnspsc190501.46191613': __('identifierSchemeCodeUnspsc190501.46191613'),
	'identifierSchemeCodeUnspsc190501.46191614': __('identifierSchemeCodeUnspsc190501.46191614'),
	'identifierSchemeCodeUnspsc190501.46191615': __('identifierSchemeCodeUnspsc190501.46191615'),
	'identifierSchemeCodeUnspsc190501.46191616': __('identifierSchemeCodeUnspsc190501.46191616'),
	'identifierSchemeCodeUnspsc190501.46191617': __('identifierSchemeCodeUnspsc190501.46191617'),
	'identifierSchemeCodeUnspsc190501.46191618': __('identifierSchemeCodeUnspsc190501.46191618'),
	'identifierSchemeCodeUnspsc190501.46191619': __('identifierSchemeCodeUnspsc190501.46191619'),
	'identifierSchemeCodeUnspsc190501.46191620': __('identifierSchemeCodeUnspsc190501.46191620'),
	'identifierSchemeCodeUnspsc190501.46191621': __('identifierSchemeCodeUnspsc190501.46191621'),
	'identifierSchemeCodeUnspsc190501.46200000': __('identifierSchemeCodeUnspsc190501.46200000'),
	'identifierSchemeCodeUnspsc190501.46201000': __('identifierSchemeCodeUnspsc190501.46201000'),
	'identifierSchemeCodeUnspsc190501.46201001': __('identifierSchemeCodeUnspsc190501.46201001'),
	'identifierSchemeCodeUnspsc190501.46201002': __('identifierSchemeCodeUnspsc190501.46201002'),
	'identifierSchemeCodeUnspsc190501.46201100': __('identifierSchemeCodeUnspsc190501.46201100'),
	'identifierSchemeCodeUnspsc190501.46201101': __('identifierSchemeCodeUnspsc190501.46201101'),
	'identifierSchemeCodeUnspsc190501.46201102': __('identifierSchemeCodeUnspsc190501.46201102'),
	'identifierSchemeCodeUnspsc190501.46210000': __('identifierSchemeCodeUnspsc190501.46210000'),
	'identifierSchemeCodeUnspsc190501.46211500': __('identifierSchemeCodeUnspsc190501.46211500'),
	'identifierSchemeCodeUnspsc190501.46211501': __('identifierSchemeCodeUnspsc190501.46211501'),
	'identifierSchemeCodeUnspsc190501.46211502': __('identifierSchemeCodeUnspsc190501.46211502'),
	'identifierSchemeCodeUnspsc190501.46211503': __('identifierSchemeCodeUnspsc190501.46211503'),
	'identifierSchemeCodeUnspsc190501.46211504': __('identifierSchemeCodeUnspsc190501.46211504'),
	'identifierSchemeCodeUnspsc190501.46211600': __('identifierSchemeCodeUnspsc190501.46211600'),
	'identifierSchemeCodeUnspsc190501.46211601': __('identifierSchemeCodeUnspsc190501.46211601'),
	'identifierSchemeCodeUnspsc190501.46211602': __('identifierSchemeCodeUnspsc190501.46211602'),
	'identifierSchemeCodeUnspsc190501.46211603': __('identifierSchemeCodeUnspsc190501.46211603'),
	'identifierSchemeCodeUnspsc190501.46211604': __('identifierSchemeCodeUnspsc190501.46211604'),
	'identifierSchemeCodeUnspsc190501.46220000': __('identifierSchemeCodeUnspsc190501.46220000'),
	'identifierSchemeCodeUnspsc190501.46221500': __('identifierSchemeCodeUnspsc190501.46221500'),
	'identifierSchemeCodeUnspsc190501.46221501': __('identifierSchemeCodeUnspsc190501.46221501'),
	'identifierSchemeCodeUnspsc190501.46221502': __('identifierSchemeCodeUnspsc190501.46221502'),
	'identifierSchemeCodeUnspsc190501.46221503': __('identifierSchemeCodeUnspsc190501.46221503'),
	'identifierSchemeCodeUnspsc190501.46221504': __('identifierSchemeCodeUnspsc190501.46221504'),
	'identifierSchemeCodeUnspsc190501.46221505': __('identifierSchemeCodeUnspsc190501.46221505'),
	'identifierSchemeCodeUnspsc190501.46221506': __('identifierSchemeCodeUnspsc190501.46221506'),
	'identifierSchemeCodeUnspsc190501.46221507': __('identifierSchemeCodeUnspsc190501.46221507'),
	'identifierSchemeCodeUnspsc190501.46221508': __('identifierSchemeCodeUnspsc190501.46221508'),
	'identifierSchemeCodeUnspsc190501.46221509': __('identifierSchemeCodeUnspsc190501.46221509'),
	'identifierSchemeCodeUnspsc190501.46221510': __('identifierSchemeCodeUnspsc190501.46221510'),
	'identifierSchemeCodeUnspsc190501.46221511': __('identifierSchemeCodeUnspsc190501.46221511'),
	'identifierSchemeCodeUnspsc190501.46221512': __('identifierSchemeCodeUnspsc190501.46221512'),
	'identifierSchemeCodeUnspsc190501.46221513': __('identifierSchemeCodeUnspsc190501.46221513'),
	'identifierSchemeCodeUnspsc190501.47000000': __('identifierSchemeCodeUnspsc190501.47000000'),
	'identifierSchemeCodeUnspsc190501.47100000': __('identifierSchemeCodeUnspsc190501.47100000'),
	'identifierSchemeCodeUnspsc190501.47101500': __('identifierSchemeCodeUnspsc190501.47101500'),
	'identifierSchemeCodeUnspsc190501.47101501': __('identifierSchemeCodeUnspsc190501.47101501'),
	'identifierSchemeCodeUnspsc190501.47101502': __('identifierSchemeCodeUnspsc190501.47101502'),
	'identifierSchemeCodeUnspsc190501.47101503': __('identifierSchemeCodeUnspsc190501.47101503'),
	'identifierSchemeCodeUnspsc190501.47101504': __('identifierSchemeCodeUnspsc190501.47101504'),
	'identifierSchemeCodeUnspsc190501.47101505': __('identifierSchemeCodeUnspsc190501.47101505'),
	'identifierSchemeCodeUnspsc190501.47101506': __('identifierSchemeCodeUnspsc190501.47101506'),
	'identifierSchemeCodeUnspsc190501.47101507': __('identifierSchemeCodeUnspsc190501.47101507'),
	'identifierSchemeCodeUnspsc190501.47101508': __('identifierSchemeCodeUnspsc190501.47101508'),
	'identifierSchemeCodeUnspsc190501.47101509': __('identifierSchemeCodeUnspsc190501.47101509'),
	'identifierSchemeCodeUnspsc190501.47101510': __('identifierSchemeCodeUnspsc190501.47101510'),
	'identifierSchemeCodeUnspsc190501.47101511': __('identifierSchemeCodeUnspsc190501.47101511'),
	'identifierSchemeCodeUnspsc190501.47101512': __('identifierSchemeCodeUnspsc190501.47101512'),
	'identifierSchemeCodeUnspsc190501.47101513': __('identifierSchemeCodeUnspsc190501.47101513'),
	'identifierSchemeCodeUnspsc190501.47101514': __('identifierSchemeCodeUnspsc190501.47101514'),
	'identifierSchemeCodeUnspsc190501.47101516': __('identifierSchemeCodeUnspsc190501.47101516'),
	'identifierSchemeCodeUnspsc190501.47101517': __('identifierSchemeCodeUnspsc190501.47101517'),
	'identifierSchemeCodeUnspsc190501.47101518': __('identifierSchemeCodeUnspsc190501.47101518'),
	'identifierSchemeCodeUnspsc190501.47101519': __('identifierSchemeCodeUnspsc190501.47101519'),
	'identifierSchemeCodeUnspsc190501.47101521': __('identifierSchemeCodeUnspsc190501.47101521'),
	'identifierSchemeCodeUnspsc190501.47101522': __('identifierSchemeCodeUnspsc190501.47101522'),
	'identifierSchemeCodeUnspsc190501.47101523': __('identifierSchemeCodeUnspsc190501.47101523'),
	'identifierSchemeCodeUnspsc190501.47101524': __('identifierSchemeCodeUnspsc190501.47101524'),
	'identifierSchemeCodeUnspsc190501.47101525': __('identifierSchemeCodeUnspsc190501.47101525'),
	'identifierSchemeCodeUnspsc190501.47101526': __('identifierSchemeCodeUnspsc190501.47101526'),
	'identifierSchemeCodeUnspsc190501.47101527': __('identifierSchemeCodeUnspsc190501.47101527'),
	'identifierSchemeCodeUnspsc190501.47101528': __('identifierSchemeCodeUnspsc190501.47101528'),
	'identifierSchemeCodeUnspsc190501.47101529': __('identifierSchemeCodeUnspsc190501.47101529'),
	'identifierSchemeCodeUnspsc190501.47101530': __('identifierSchemeCodeUnspsc190501.47101530'),
	'identifierSchemeCodeUnspsc190501.47101531': __('identifierSchemeCodeUnspsc190501.47101531'),
	'identifierSchemeCodeUnspsc190501.47101532': __('identifierSchemeCodeUnspsc190501.47101532'),
	'identifierSchemeCodeUnspsc190501.47101533': __('identifierSchemeCodeUnspsc190501.47101533'),
	'identifierSchemeCodeUnspsc190501.47101534': __('identifierSchemeCodeUnspsc190501.47101534'),
	'identifierSchemeCodeUnspsc190501.47101535': __('identifierSchemeCodeUnspsc190501.47101535'),
	'identifierSchemeCodeUnspsc190501.47101536': __('identifierSchemeCodeUnspsc190501.47101536'),
	'identifierSchemeCodeUnspsc190501.47101537': __('identifierSchemeCodeUnspsc190501.47101537'),
	'identifierSchemeCodeUnspsc190501.47101538': __('identifierSchemeCodeUnspsc190501.47101538'),
	'identifierSchemeCodeUnspsc190501.47101539': __('identifierSchemeCodeUnspsc190501.47101539'),
	'identifierSchemeCodeUnspsc190501.47101540': __('identifierSchemeCodeUnspsc190501.47101540'),
	'identifierSchemeCodeUnspsc190501.47101541': __('identifierSchemeCodeUnspsc190501.47101541'),
	'identifierSchemeCodeUnspsc190501.47101542': __('identifierSchemeCodeUnspsc190501.47101542'),
	'identifierSchemeCodeUnspsc190501.47101543': __('identifierSchemeCodeUnspsc190501.47101543'),
	'identifierSchemeCodeUnspsc190501.47101544': __('identifierSchemeCodeUnspsc190501.47101544'),
	'identifierSchemeCodeUnspsc190501.47101545': __('identifierSchemeCodeUnspsc190501.47101545'),
	'identifierSchemeCodeUnspsc190501.47101546': __('identifierSchemeCodeUnspsc190501.47101546'),
	'identifierSchemeCodeUnspsc190501.47101547': __('identifierSchemeCodeUnspsc190501.47101547'),
	'identifierSchemeCodeUnspsc190501.47101548': __('identifierSchemeCodeUnspsc190501.47101548'),
	'identifierSchemeCodeUnspsc190501.47101549': __('identifierSchemeCodeUnspsc190501.47101549'),
	'identifierSchemeCodeUnspsc190501.47101550': __('identifierSchemeCodeUnspsc190501.47101550'),
	'identifierSchemeCodeUnspsc190501.47101551': __('identifierSchemeCodeUnspsc190501.47101551'),
	'identifierSchemeCodeUnspsc190501.47101552': __('identifierSchemeCodeUnspsc190501.47101552'),
	'identifierSchemeCodeUnspsc190501.47101553': __('identifierSchemeCodeUnspsc190501.47101553'),
	'identifierSchemeCodeUnspsc190501.47101554': __('identifierSchemeCodeUnspsc190501.47101554'),
	'identifierSchemeCodeUnspsc190501.47101555': __('identifierSchemeCodeUnspsc190501.47101555'),
	'identifierSchemeCodeUnspsc190501.47101556': __('identifierSchemeCodeUnspsc190501.47101556'),
	'identifierSchemeCodeUnspsc190501.47101557': __('identifierSchemeCodeUnspsc190501.47101557'),
	'identifierSchemeCodeUnspsc190501.47101558': __('identifierSchemeCodeUnspsc190501.47101558'),
	'identifierSchemeCodeUnspsc190501.47101559': __('identifierSchemeCodeUnspsc190501.47101559'),
	'identifierSchemeCodeUnspsc190501.47101560': __('identifierSchemeCodeUnspsc190501.47101560'),
	'identifierSchemeCodeUnspsc190501.47101561': __('identifierSchemeCodeUnspsc190501.47101561'),
	'identifierSchemeCodeUnspsc190501.47101562': __('identifierSchemeCodeUnspsc190501.47101562'),
	'identifierSchemeCodeUnspsc190501.47101563': __('identifierSchemeCodeUnspsc190501.47101563'),
	'identifierSchemeCodeUnspsc190501.47101564': __('identifierSchemeCodeUnspsc190501.47101564'),
	'identifierSchemeCodeUnspsc190501.47101565': __('identifierSchemeCodeUnspsc190501.47101565'),
	'identifierSchemeCodeUnspsc190501.47101566': __('identifierSchemeCodeUnspsc190501.47101566'),
	'identifierSchemeCodeUnspsc190501.47101567': __('identifierSchemeCodeUnspsc190501.47101567'),
	'identifierSchemeCodeUnspsc190501.47101568': __('identifierSchemeCodeUnspsc190501.47101568'),
	'identifierSchemeCodeUnspsc190501.47101569': __('identifierSchemeCodeUnspsc190501.47101569'),
	'identifierSchemeCodeUnspsc190501.47101570': __('identifierSchemeCodeUnspsc190501.47101570'),
	'identifierSchemeCodeUnspsc190501.47101571': __('identifierSchemeCodeUnspsc190501.47101571'),
	'identifierSchemeCodeUnspsc190501.47101572': __('identifierSchemeCodeUnspsc190501.47101572'),
	'identifierSchemeCodeUnspsc190501.47101573': __('identifierSchemeCodeUnspsc190501.47101573'),
	'identifierSchemeCodeUnspsc190501.47101574': __('identifierSchemeCodeUnspsc190501.47101574'),
	'identifierSchemeCodeUnspsc190501.47101600': __('identifierSchemeCodeUnspsc190501.47101600'),
	'identifierSchemeCodeUnspsc190501.47101601': __('identifierSchemeCodeUnspsc190501.47101601'),
	'identifierSchemeCodeUnspsc190501.47101602': __('identifierSchemeCodeUnspsc190501.47101602'),
	'identifierSchemeCodeUnspsc190501.47101603': __('identifierSchemeCodeUnspsc190501.47101603'),
	'identifierSchemeCodeUnspsc190501.47101604': __('identifierSchemeCodeUnspsc190501.47101604'),
	'identifierSchemeCodeUnspsc190501.47101605': __('identifierSchemeCodeUnspsc190501.47101605'),
	'identifierSchemeCodeUnspsc190501.47101606': __('identifierSchemeCodeUnspsc190501.47101606'),
	'identifierSchemeCodeUnspsc190501.47101607': __('identifierSchemeCodeUnspsc190501.47101607'),
	'identifierSchemeCodeUnspsc190501.47101608': __('identifierSchemeCodeUnspsc190501.47101608'),
	'identifierSchemeCodeUnspsc190501.47101609': __('identifierSchemeCodeUnspsc190501.47101609'),
	'identifierSchemeCodeUnspsc190501.47101610': __('identifierSchemeCodeUnspsc190501.47101610'),
	'identifierSchemeCodeUnspsc190501.47101611': __('identifierSchemeCodeUnspsc190501.47101611'),
	'identifierSchemeCodeUnspsc190501.47101612': __('identifierSchemeCodeUnspsc190501.47101612'),
	'identifierSchemeCodeUnspsc190501.47101613': __('identifierSchemeCodeUnspsc190501.47101613'),
	'identifierSchemeCodeUnspsc190501.47101614': __('identifierSchemeCodeUnspsc190501.47101614'),
	'identifierSchemeCodeUnspsc190501.47101615': __('identifierSchemeCodeUnspsc190501.47101615'),
	'identifierSchemeCodeUnspsc190501.47110000': __('identifierSchemeCodeUnspsc190501.47110000'),
	'identifierSchemeCodeUnspsc190501.47111500': __('identifierSchemeCodeUnspsc190501.47111500'),
	'identifierSchemeCodeUnspsc190501.47111501': __('identifierSchemeCodeUnspsc190501.47111501'),
	'identifierSchemeCodeUnspsc190501.47111502': __('identifierSchemeCodeUnspsc190501.47111502'),
	'identifierSchemeCodeUnspsc190501.47111503': __('identifierSchemeCodeUnspsc190501.47111503'),
	'identifierSchemeCodeUnspsc190501.47111505': __('identifierSchemeCodeUnspsc190501.47111505'),
	'identifierSchemeCodeUnspsc190501.47111506': __('identifierSchemeCodeUnspsc190501.47111506'),
	'identifierSchemeCodeUnspsc190501.47111507': __('identifierSchemeCodeUnspsc190501.47111507'),
	'identifierSchemeCodeUnspsc190501.47111508': __('identifierSchemeCodeUnspsc190501.47111508'),
	'identifierSchemeCodeUnspsc190501.47111509': __('identifierSchemeCodeUnspsc190501.47111509'),
	'identifierSchemeCodeUnspsc190501.47111510': __('identifierSchemeCodeUnspsc190501.47111510'),
	'identifierSchemeCodeUnspsc190501.47111511': __('identifierSchemeCodeUnspsc190501.47111511'),
	'identifierSchemeCodeUnspsc190501.47111600': __('identifierSchemeCodeUnspsc190501.47111600'),
	'identifierSchemeCodeUnspsc190501.47111601': __('identifierSchemeCodeUnspsc190501.47111601'),
	'identifierSchemeCodeUnspsc190501.47111602': __('identifierSchemeCodeUnspsc190501.47111602'),
	'identifierSchemeCodeUnspsc190501.47111603': __('identifierSchemeCodeUnspsc190501.47111603'),
	'identifierSchemeCodeUnspsc190501.47111700': __('identifierSchemeCodeUnspsc190501.47111700'),
	'identifierSchemeCodeUnspsc190501.47111701': __('identifierSchemeCodeUnspsc190501.47111701'),
	'identifierSchemeCodeUnspsc190501.47120000': __('identifierSchemeCodeUnspsc190501.47120000'),
	'identifierSchemeCodeUnspsc190501.47121500': __('identifierSchemeCodeUnspsc190501.47121500'),
	'identifierSchemeCodeUnspsc190501.47121501': __('identifierSchemeCodeUnspsc190501.47121501'),
	'identifierSchemeCodeUnspsc190501.47121502': __('identifierSchemeCodeUnspsc190501.47121502'),
	'identifierSchemeCodeUnspsc190501.47121600': __('identifierSchemeCodeUnspsc190501.47121600'),
	'identifierSchemeCodeUnspsc190501.47121602': __('identifierSchemeCodeUnspsc190501.47121602'),
	'identifierSchemeCodeUnspsc190501.47121603': __('identifierSchemeCodeUnspsc190501.47121603'),
	'identifierSchemeCodeUnspsc190501.47121604': __('identifierSchemeCodeUnspsc190501.47121604'),
	'identifierSchemeCodeUnspsc190501.47121605': __('identifierSchemeCodeUnspsc190501.47121605'),
	'identifierSchemeCodeUnspsc190501.47121606': __('identifierSchemeCodeUnspsc190501.47121606'),
	'identifierSchemeCodeUnspsc190501.47121607': __('identifierSchemeCodeUnspsc190501.47121607'),
	'identifierSchemeCodeUnspsc190501.47121608': __('identifierSchemeCodeUnspsc190501.47121608'),
	'identifierSchemeCodeUnspsc190501.47121609': __('identifierSchemeCodeUnspsc190501.47121609'),
	'identifierSchemeCodeUnspsc190501.47121610': __('identifierSchemeCodeUnspsc190501.47121610'),
	'identifierSchemeCodeUnspsc190501.47121611': __('identifierSchemeCodeUnspsc190501.47121611'),
	'identifierSchemeCodeUnspsc190501.47121612': __('identifierSchemeCodeUnspsc190501.47121612'),
	'identifierSchemeCodeUnspsc190501.47121613': __('identifierSchemeCodeUnspsc190501.47121613'),
	'identifierSchemeCodeUnspsc190501.47121700': __('identifierSchemeCodeUnspsc190501.47121700'),
	'identifierSchemeCodeUnspsc190501.47121701': __('identifierSchemeCodeUnspsc190501.47121701'),
	'identifierSchemeCodeUnspsc190501.47121702': __('identifierSchemeCodeUnspsc190501.47121702'),
	'identifierSchemeCodeUnspsc190501.47121703': __('identifierSchemeCodeUnspsc190501.47121703'),
	'identifierSchemeCodeUnspsc190501.47121704': __('identifierSchemeCodeUnspsc190501.47121704'),
	'identifierSchemeCodeUnspsc190501.47121705': __('identifierSchemeCodeUnspsc190501.47121705'),
	'identifierSchemeCodeUnspsc190501.47121706': __('identifierSchemeCodeUnspsc190501.47121706'),
	'identifierSchemeCodeUnspsc190501.47121707': __('identifierSchemeCodeUnspsc190501.47121707'),
	'identifierSchemeCodeUnspsc190501.47121708': __('identifierSchemeCodeUnspsc190501.47121708'),
	'identifierSchemeCodeUnspsc190501.47121709': __('identifierSchemeCodeUnspsc190501.47121709'),
	'identifierSchemeCodeUnspsc190501.47121710': __('identifierSchemeCodeUnspsc190501.47121710'),
	'identifierSchemeCodeUnspsc190501.47121800': __('identifierSchemeCodeUnspsc190501.47121800'),
	'identifierSchemeCodeUnspsc190501.47121801': __('identifierSchemeCodeUnspsc190501.47121801'),
	'identifierSchemeCodeUnspsc190501.47121802': __('identifierSchemeCodeUnspsc190501.47121802'),
	'identifierSchemeCodeUnspsc190501.47121803': __('identifierSchemeCodeUnspsc190501.47121803'),
	'identifierSchemeCodeUnspsc190501.47121804': __('identifierSchemeCodeUnspsc190501.47121804'),
	'identifierSchemeCodeUnspsc190501.47121805': __('identifierSchemeCodeUnspsc190501.47121805'),
	'identifierSchemeCodeUnspsc190501.47121806': __('identifierSchemeCodeUnspsc190501.47121806'),
	'identifierSchemeCodeUnspsc190501.47121807': __('identifierSchemeCodeUnspsc190501.47121807'),
	'identifierSchemeCodeUnspsc190501.47121808': __('identifierSchemeCodeUnspsc190501.47121808'),
	'identifierSchemeCodeUnspsc190501.47121809': __('identifierSchemeCodeUnspsc190501.47121809'),
	'identifierSchemeCodeUnspsc190501.47121810': __('identifierSchemeCodeUnspsc190501.47121810'),
	'identifierSchemeCodeUnspsc190501.47121811': __('identifierSchemeCodeUnspsc190501.47121811'),
	'identifierSchemeCodeUnspsc190501.47121812': __('identifierSchemeCodeUnspsc190501.47121812'),
	'identifierSchemeCodeUnspsc190501.47121813': __('identifierSchemeCodeUnspsc190501.47121813'),
	'identifierSchemeCodeUnspsc190501.47121814': __('identifierSchemeCodeUnspsc190501.47121814'),
	'identifierSchemeCodeUnspsc190501.47121815': __('identifierSchemeCodeUnspsc190501.47121815'),
	'identifierSchemeCodeUnspsc190501.47121816': __('identifierSchemeCodeUnspsc190501.47121816'),
	'identifierSchemeCodeUnspsc190501.47121900': __('identifierSchemeCodeUnspsc190501.47121900'),
	'identifierSchemeCodeUnspsc190501.47121901': __('identifierSchemeCodeUnspsc190501.47121901'),
	'identifierSchemeCodeUnspsc190501.47121902': __('identifierSchemeCodeUnspsc190501.47121902'),
	'identifierSchemeCodeUnspsc190501.47121903': __('identifierSchemeCodeUnspsc190501.47121903'),
	'identifierSchemeCodeUnspsc190501.47121904': __('identifierSchemeCodeUnspsc190501.47121904'),
	'identifierSchemeCodeUnspsc190501.47130000': __('identifierSchemeCodeUnspsc190501.47130000'),
	'identifierSchemeCodeUnspsc190501.47131500': __('identifierSchemeCodeUnspsc190501.47131500'),
	'identifierSchemeCodeUnspsc190501.47131501': __('identifierSchemeCodeUnspsc190501.47131501'),
	'identifierSchemeCodeUnspsc190501.47131502': __('identifierSchemeCodeUnspsc190501.47131502'),
	'identifierSchemeCodeUnspsc190501.47131503': __('identifierSchemeCodeUnspsc190501.47131503'),
	'identifierSchemeCodeUnspsc190501.47131600': __('identifierSchemeCodeUnspsc190501.47131600'),
	'identifierSchemeCodeUnspsc190501.47131601': __('identifierSchemeCodeUnspsc190501.47131601'),
	'identifierSchemeCodeUnspsc190501.47131602': __('identifierSchemeCodeUnspsc190501.47131602'),
	'identifierSchemeCodeUnspsc190501.47131603': __('identifierSchemeCodeUnspsc190501.47131603'),
	'identifierSchemeCodeUnspsc190501.47131604': __('identifierSchemeCodeUnspsc190501.47131604'),
	'identifierSchemeCodeUnspsc190501.47131605': __('identifierSchemeCodeUnspsc190501.47131605'),
	'identifierSchemeCodeUnspsc190501.47131608': __('identifierSchemeCodeUnspsc190501.47131608'),
	'identifierSchemeCodeUnspsc190501.47131609': __('identifierSchemeCodeUnspsc190501.47131609'),
	'identifierSchemeCodeUnspsc190501.47131610': __('identifierSchemeCodeUnspsc190501.47131610'),
	'identifierSchemeCodeUnspsc190501.47131611': __('identifierSchemeCodeUnspsc190501.47131611'),
	'identifierSchemeCodeUnspsc190501.47131612': __('identifierSchemeCodeUnspsc190501.47131612'),
	'identifierSchemeCodeUnspsc190501.47131613': __('identifierSchemeCodeUnspsc190501.47131613'),
	'identifierSchemeCodeUnspsc190501.47131614': __('identifierSchemeCodeUnspsc190501.47131614'),
	'identifierSchemeCodeUnspsc190501.47131615': __('identifierSchemeCodeUnspsc190501.47131615'),
	'identifierSchemeCodeUnspsc190501.47131616': __('identifierSchemeCodeUnspsc190501.47131616'),
	'identifierSchemeCodeUnspsc190501.47131617': __('identifierSchemeCodeUnspsc190501.47131617'),
	'identifierSchemeCodeUnspsc190501.47131618': __('identifierSchemeCodeUnspsc190501.47131618'),
	'identifierSchemeCodeUnspsc190501.47131619': __('identifierSchemeCodeUnspsc190501.47131619'),
	'identifierSchemeCodeUnspsc190501.47131700': __('identifierSchemeCodeUnspsc190501.47131700'),
	'identifierSchemeCodeUnspsc190501.47131701': __('identifierSchemeCodeUnspsc190501.47131701'),
	'identifierSchemeCodeUnspsc190501.47131702': __('identifierSchemeCodeUnspsc190501.47131702'),
	'identifierSchemeCodeUnspsc190501.47131703': __('identifierSchemeCodeUnspsc190501.47131703'),
	'identifierSchemeCodeUnspsc190501.47131704': __('identifierSchemeCodeUnspsc190501.47131704'),
	'identifierSchemeCodeUnspsc190501.47131705': __('identifierSchemeCodeUnspsc190501.47131705'),
	'identifierSchemeCodeUnspsc190501.47131706': __('identifierSchemeCodeUnspsc190501.47131706'),
	'identifierSchemeCodeUnspsc190501.47131707': __('identifierSchemeCodeUnspsc190501.47131707'),
	'identifierSchemeCodeUnspsc190501.47131709': __('identifierSchemeCodeUnspsc190501.47131709'),
	'identifierSchemeCodeUnspsc190501.47131710': __('identifierSchemeCodeUnspsc190501.47131710'),
	'identifierSchemeCodeUnspsc190501.47131711': __('identifierSchemeCodeUnspsc190501.47131711'),
	'identifierSchemeCodeUnspsc190501.47131800': __('identifierSchemeCodeUnspsc190501.47131800'),
	'identifierSchemeCodeUnspsc190501.47131801': __('identifierSchemeCodeUnspsc190501.47131801'),
	'identifierSchemeCodeUnspsc190501.47131802': __('identifierSchemeCodeUnspsc190501.47131802'),
	'identifierSchemeCodeUnspsc190501.47131803': __('identifierSchemeCodeUnspsc190501.47131803'),
	'identifierSchemeCodeUnspsc190501.47131804': __('identifierSchemeCodeUnspsc190501.47131804'),
	'identifierSchemeCodeUnspsc190501.47131805': __('identifierSchemeCodeUnspsc190501.47131805'),
	'identifierSchemeCodeUnspsc190501.47131806': __('identifierSchemeCodeUnspsc190501.47131806'),
	'identifierSchemeCodeUnspsc190501.47131807': __('identifierSchemeCodeUnspsc190501.47131807'),
	'identifierSchemeCodeUnspsc190501.47131808': __('identifierSchemeCodeUnspsc190501.47131808'),
	'identifierSchemeCodeUnspsc190501.47131809': __('identifierSchemeCodeUnspsc190501.47131809'),
	'identifierSchemeCodeUnspsc190501.47131810': __('identifierSchemeCodeUnspsc190501.47131810'),
	'identifierSchemeCodeUnspsc190501.47131811': __('identifierSchemeCodeUnspsc190501.47131811'),
	'identifierSchemeCodeUnspsc190501.47131812': __('identifierSchemeCodeUnspsc190501.47131812'),
	'identifierSchemeCodeUnspsc190501.47131813': __('identifierSchemeCodeUnspsc190501.47131813'),
	'identifierSchemeCodeUnspsc190501.47131814': __('identifierSchemeCodeUnspsc190501.47131814'),
	'identifierSchemeCodeUnspsc190501.47131815': __('identifierSchemeCodeUnspsc190501.47131815'),
	'identifierSchemeCodeUnspsc190501.47131816': __('identifierSchemeCodeUnspsc190501.47131816'),
	'identifierSchemeCodeUnspsc190501.47131817': __('identifierSchemeCodeUnspsc190501.47131817'),
	'identifierSchemeCodeUnspsc190501.47131818': __('identifierSchemeCodeUnspsc190501.47131818'),
	'identifierSchemeCodeUnspsc190501.47131819': __('identifierSchemeCodeUnspsc190501.47131819'),
	'identifierSchemeCodeUnspsc190501.47131820': __('identifierSchemeCodeUnspsc190501.47131820'),
	'identifierSchemeCodeUnspsc190501.47131821': __('identifierSchemeCodeUnspsc190501.47131821'),
	'identifierSchemeCodeUnspsc190501.47131822': __('identifierSchemeCodeUnspsc190501.47131822'),
	'identifierSchemeCodeUnspsc190501.47131823': __('identifierSchemeCodeUnspsc190501.47131823'),
	'identifierSchemeCodeUnspsc190501.47131824': __('identifierSchemeCodeUnspsc190501.47131824'),
	'identifierSchemeCodeUnspsc190501.47131825': __('identifierSchemeCodeUnspsc190501.47131825'),
	'identifierSchemeCodeUnspsc190501.47131826': __('identifierSchemeCodeUnspsc190501.47131826'),
	'identifierSchemeCodeUnspsc190501.47131827': __('identifierSchemeCodeUnspsc190501.47131827'),
	'identifierSchemeCodeUnspsc190501.47131828': __('identifierSchemeCodeUnspsc190501.47131828'),
	'identifierSchemeCodeUnspsc190501.47131829': __('identifierSchemeCodeUnspsc190501.47131829'),
	'identifierSchemeCodeUnspsc190501.47131830': __('identifierSchemeCodeUnspsc190501.47131830'),
	'identifierSchemeCodeUnspsc190501.47131831': __('identifierSchemeCodeUnspsc190501.47131831'),
	'identifierSchemeCodeUnspsc190501.47131832': __('identifierSchemeCodeUnspsc190501.47131832'),
	'identifierSchemeCodeUnspsc190501.47131833': __('identifierSchemeCodeUnspsc190501.47131833'),
	'identifierSchemeCodeUnspsc190501.47131834': __('identifierSchemeCodeUnspsc190501.47131834'),
	'identifierSchemeCodeUnspsc190501.47131835': __('identifierSchemeCodeUnspsc190501.47131835'),
	'identifierSchemeCodeUnspsc190501.47131836': __('identifierSchemeCodeUnspsc190501.47131836'),
	'identifierSchemeCodeUnspsc190501.47131900': __('identifierSchemeCodeUnspsc190501.47131900'),
	'identifierSchemeCodeUnspsc190501.47131901': __('identifierSchemeCodeUnspsc190501.47131901'),
	'identifierSchemeCodeUnspsc190501.47131902': __('identifierSchemeCodeUnspsc190501.47131902'),
	'identifierSchemeCodeUnspsc190501.47131903': __('identifierSchemeCodeUnspsc190501.47131903'),
	'identifierSchemeCodeUnspsc190501.47131904': __('identifierSchemeCodeUnspsc190501.47131904'),
	'identifierSchemeCodeUnspsc190501.47131905': __('identifierSchemeCodeUnspsc190501.47131905'),
	'identifierSchemeCodeUnspsc190501.47131906': __('identifierSchemeCodeUnspsc190501.47131906'),
	'identifierSchemeCodeUnspsc190501.47131907': __('identifierSchemeCodeUnspsc190501.47131907'),
	'identifierSchemeCodeUnspsc190501.47131908': __('identifierSchemeCodeUnspsc190501.47131908'),
	'identifierSchemeCodeUnspsc190501.47131909': __('identifierSchemeCodeUnspsc190501.47131909'),
	'identifierSchemeCodeUnspsc190501.47131910': __('identifierSchemeCodeUnspsc190501.47131910'),
	'identifierSchemeCodeUnspsc190501.47132100': __('identifierSchemeCodeUnspsc190501.47132100'),
	'identifierSchemeCodeUnspsc190501.47132101': __('identifierSchemeCodeUnspsc190501.47132101'),
	'identifierSchemeCodeUnspsc190501.47132102': __('identifierSchemeCodeUnspsc190501.47132102'),
	'identifierSchemeCodeUnspsc190501.47140000': __('identifierSchemeCodeUnspsc190501.47140000'),
	'identifierSchemeCodeUnspsc190501.47141500': __('identifierSchemeCodeUnspsc190501.47141500'),
	'identifierSchemeCodeUnspsc190501.47141501': __('identifierSchemeCodeUnspsc190501.47141501'),
	'identifierSchemeCodeUnspsc190501.47141502': __('identifierSchemeCodeUnspsc190501.47141502'),
	'identifierSchemeCodeUnspsc190501.48000000': __('identifierSchemeCodeUnspsc190501.48000000'),
	'identifierSchemeCodeUnspsc190501.48100000': __('identifierSchemeCodeUnspsc190501.48100000'),
	'identifierSchemeCodeUnspsc190501.48101500': __('identifierSchemeCodeUnspsc190501.48101500'),
	'identifierSchemeCodeUnspsc190501.48101501': __('identifierSchemeCodeUnspsc190501.48101501'),
	'identifierSchemeCodeUnspsc190501.48101502': __('identifierSchemeCodeUnspsc190501.48101502'),
	'identifierSchemeCodeUnspsc190501.48101503': __('identifierSchemeCodeUnspsc190501.48101503'),
	'identifierSchemeCodeUnspsc190501.48101504': __('identifierSchemeCodeUnspsc190501.48101504'),
	'identifierSchemeCodeUnspsc190501.48101505': __('identifierSchemeCodeUnspsc190501.48101505'),
	'identifierSchemeCodeUnspsc190501.48101506': __('identifierSchemeCodeUnspsc190501.48101506'),
	'identifierSchemeCodeUnspsc190501.48101507': __('identifierSchemeCodeUnspsc190501.48101507'),
	'identifierSchemeCodeUnspsc190501.48101508': __('identifierSchemeCodeUnspsc190501.48101508'),
	'identifierSchemeCodeUnspsc190501.48101509': __('identifierSchemeCodeUnspsc190501.48101509'),
	'identifierSchemeCodeUnspsc190501.48101510': __('identifierSchemeCodeUnspsc190501.48101510'),
	'identifierSchemeCodeUnspsc190501.48101511': __('identifierSchemeCodeUnspsc190501.48101511'),
	'identifierSchemeCodeUnspsc190501.48101512': __('identifierSchemeCodeUnspsc190501.48101512'),
	'identifierSchemeCodeUnspsc190501.48101513': __('identifierSchemeCodeUnspsc190501.48101513'),
	'identifierSchemeCodeUnspsc190501.48101514': __('identifierSchemeCodeUnspsc190501.48101514'),
	'identifierSchemeCodeUnspsc190501.48101515': __('identifierSchemeCodeUnspsc190501.48101515'),
	'identifierSchemeCodeUnspsc190501.48101516': __('identifierSchemeCodeUnspsc190501.48101516'),
	'identifierSchemeCodeUnspsc190501.48101517': __('identifierSchemeCodeUnspsc190501.48101517'),
	'identifierSchemeCodeUnspsc190501.48101518': __('identifierSchemeCodeUnspsc190501.48101518'),
	'identifierSchemeCodeUnspsc190501.48101519': __('identifierSchemeCodeUnspsc190501.48101519'),
	'identifierSchemeCodeUnspsc190501.48101520': __('identifierSchemeCodeUnspsc190501.48101520'),
	'identifierSchemeCodeUnspsc190501.48101521': __('identifierSchemeCodeUnspsc190501.48101521'),
	'identifierSchemeCodeUnspsc190501.48101522': __('identifierSchemeCodeUnspsc190501.48101522'),
	'identifierSchemeCodeUnspsc190501.48101523': __('identifierSchemeCodeUnspsc190501.48101523'),
	'identifierSchemeCodeUnspsc190501.48101524': __('identifierSchemeCodeUnspsc190501.48101524'),
	'identifierSchemeCodeUnspsc190501.48101525': __('identifierSchemeCodeUnspsc190501.48101525'),
	'identifierSchemeCodeUnspsc190501.48101526': __('identifierSchemeCodeUnspsc190501.48101526'),
	'identifierSchemeCodeUnspsc190501.48101527': __('identifierSchemeCodeUnspsc190501.48101527'),
	'identifierSchemeCodeUnspsc190501.48101528': __('identifierSchemeCodeUnspsc190501.48101528'),
	'identifierSchemeCodeUnspsc190501.48101529': __('identifierSchemeCodeUnspsc190501.48101529'),
	'identifierSchemeCodeUnspsc190501.48101530': __('identifierSchemeCodeUnspsc190501.48101530'),
	'identifierSchemeCodeUnspsc190501.48101531': __('identifierSchemeCodeUnspsc190501.48101531'),
	'identifierSchemeCodeUnspsc190501.48101532': __('identifierSchemeCodeUnspsc190501.48101532'),
	'identifierSchemeCodeUnspsc190501.48101533': __('identifierSchemeCodeUnspsc190501.48101533'),
	'identifierSchemeCodeUnspsc190501.48101534': __('identifierSchemeCodeUnspsc190501.48101534'),
	'identifierSchemeCodeUnspsc190501.48101535': __('identifierSchemeCodeUnspsc190501.48101535'),
	'identifierSchemeCodeUnspsc190501.48101536': __('identifierSchemeCodeUnspsc190501.48101536'),
	'identifierSchemeCodeUnspsc190501.48101537': __('identifierSchemeCodeUnspsc190501.48101537'),
	'identifierSchemeCodeUnspsc190501.48101538': __('identifierSchemeCodeUnspsc190501.48101538'),
	'identifierSchemeCodeUnspsc190501.48101539': __('identifierSchemeCodeUnspsc190501.48101539'),
	'identifierSchemeCodeUnspsc190501.48101540': __('identifierSchemeCodeUnspsc190501.48101540'),
	'identifierSchemeCodeUnspsc190501.48101541': __('identifierSchemeCodeUnspsc190501.48101541'),
	'identifierSchemeCodeUnspsc190501.48101542': __('identifierSchemeCodeUnspsc190501.48101542'),
	'identifierSchemeCodeUnspsc190501.48101543': __('identifierSchemeCodeUnspsc190501.48101543'),
	'identifierSchemeCodeUnspsc190501.48101544': __('identifierSchemeCodeUnspsc190501.48101544'),
	'identifierSchemeCodeUnspsc190501.48101545': __('identifierSchemeCodeUnspsc190501.48101545'),
	'identifierSchemeCodeUnspsc190501.48101546': __('identifierSchemeCodeUnspsc190501.48101546'),
	'identifierSchemeCodeUnspsc190501.48101600': __('identifierSchemeCodeUnspsc190501.48101600'),
	'identifierSchemeCodeUnspsc190501.48101601': __('identifierSchemeCodeUnspsc190501.48101601'),
	'identifierSchemeCodeUnspsc190501.48101602': __('identifierSchemeCodeUnspsc190501.48101602'),
	'identifierSchemeCodeUnspsc190501.48101603': __('identifierSchemeCodeUnspsc190501.48101603'),
	'identifierSchemeCodeUnspsc190501.48101604': __('identifierSchemeCodeUnspsc190501.48101604'),
	'identifierSchemeCodeUnspsc190501.48101605': __('identifierSchemeCodeUnspsc190501.48101605'),
	'identifierSchemeCodeUnspsc190501.48101606': __('identifierSchemeCodeUnspsc190501.48101606'),
	'identifierSchemeCodeUnspsc190501.48101607': __('identifierSchemeCodeUnspsc190501.48101607'),
	'identifierSchemeCodeUnspsc190501.48101608': __('identifierSchemeCodeUnspsc190501.48101608'),
	'identifierSchemeCodeUnspsc190501.48101609': __('identifierSchemeCodeUnspsc190501.48101609'),
	'identifierSchemeCodeUnspsc190501.48101610': __('identifierSchemeCodeUnspsc190501.48101610'),
	'identifierSchemeCodeUnspsc190501.48101611': __('identifierSchemeCodeUnspsc190501.48101611'),
	'identifierSchemeCodeUnspsc190501.48101612': __('identifierSchemeCodeUnspsc190501.48101612'),
	'identifierSchemeCodeUnspsc190501.48101613': __('identifierSchemeCodeUnspsc190501.48101613'),
	'identifierSchemeCodeUnspsc190501.48101614': __('identifierSchemeCodeUnspsc190501.48101614'),
	'identifierSchemeCodeUnspsc190501.48101615': __('identifierSchemeCodeUnspsc190501.48101615'),
	'identifierSchemeCodeUnspsc190501.48101616': __('identifierSchemeCodeUnspsc190501.48101616'),
	'identifierSchemeCodeUnspsc190501.48101617': __('identifierSchemeCodeUnspsc190501.48101617'),
	'identifierSchemeCodeUnspsc190501.48101618': __('identifierSchemeCodeUnspsc190501.48101618'),
	'identifierSchemeCodeUnspsc190501.48101619': __('identifierSchemeCodeUnspsc190501.48101619'),
	'identifierSchemeCodeUnspsc190501.48101700': __('identifierSchemeCodeUnspsc190501.48101700'),
	'identifierSchemeCodeUnspsc190501.48101701': __('identifierSchemeCodeUnspsc190501.48101701'),
	'identifierSchemeCodeUnspsc190501.48101702': __('identifierSchemeCodeUnspsc190501.48101702'),
	'identifierSchemeCodeUnspsc190501.48101703': __('identifierSchemeCodeUnspsc190501.48101703'),
	'identifierSchemeCodeUnspsc190501.48101704': __('identifierSchemeCodeUnspsc190501.48101704'),
	'identifierSchemeCodeUnspsc190501.48101705': __('identifierSchemeCodeUnspsc190501.48101705'),
	'identifierSchemeCodeUnspsc190501.48101706': __('identifierSchemeCodeUnspsc190501.48101706'),
	'identifierSchemeCodeUnspsc190501.48101707': __('identifierSchemeCodeUnspsc190501.48101707'),
	'identifierSchemeCodeUnspsc190501.48101708': __('identifierSchemeCodeUnspsc190501.48101708'),
	'identifierSchemeCodeUnspsc190501.48101709': __('identifierSchemeCodeUnspsc190501.48101709'),
	'identifierSchemeCodeUnspsc190501.48101710': __('identifierSchemeCodeUnspsc190501.48101710'),
	'identifierSchemeCodeUnspsc190501.48101711': __('identifierSchemeCodeUnspsc190501.48101711'),
	'identifierSchemeCodeUnspsc190501.48101712': __('identifierSchemeCodeUnspsc190501.48101712'),
	'identifierSchemeCodeUnspsc190501.48101713': __('identifierSchemeCodeUnspsc190501.48101713'),
	'identifierSchemeCodeUnspsc190501.48101714': __('identifierSchemeCodeUnspsc190501.48101714'),
	'identifierSchemeCodeUnspsc190501.48101715': __('identifierSchemeCodeUnspsc190501.48101715'),
	'identifierSchemeCodeUnspsc190501.48101716': __('identifierSchemeCodeUnspsc190501.48101716'),
	'identifierSchemeCodeUnspsc190501.48101800': __('identifierSchemeCodeUnspsc190501.48101800'),
	'identifierSchemeCodeUnspsc190501.48101801': __('identifierSchemeCodeUnspsc190501.48101801'),
	'identifierSchemeCodeUnspsc190501.48101802': __('identifierSchemeCodeUnspsc190501.48101802'),
	'identifierSchemeCodeUnspsc190501.48101803': __('identifierSchemeCodeUnspsc190501.48101803'),
	'identifierSchemeCodeUnspsc190501.48101804': __('identifierSchemeCodeUnspsc190501.48101804'),
	'identifierSchemeCodeUnspsc190501.48101805': __('identifierSchemeCodeUnspsc190501.48101805'),
	'identifierSchemeCodeUnspsc190501.48101806': __('identifierSchemeCodeUnspsc190501.48101806'),
	'identifierSchemeCodeUnspsc190501.48101807': __('identifierSchemeCodeUnspsc190501.48101807'),
	'identifierSchemeCodeUnspsc190501.48101808': __('identifierSchemeCodeUnspsc190501.48101808'),
	'identifierSchemeCodeUnspsc190501.48101809': __('identifierSchemeCodeUnspsc190501.48101809'),
	'identifierSchemeCodeUnspsc190501.48101810': __('identifierSchemeCodeUnspsc190501.48101810'),
	'identifierSchemeCodeUnspsc190501.48101811': __('identifierSchemeCodeUnspsc190501.48101811'),
	'identifierSchemeCodeUnspsc190501.48101812': __('identifierSchemeCodeUnspsc190501.48101812'),
	'identifierSchemeCodeUnspsc190501.48101813': __('identifierSchemeCodeUnspsc190501.48101813'),
	'identifierSchemeCodeUnspsc190501.48101814': __('identifierSchemeCodeUnspsc190501.48101814'),
	'identifierSchemeCodeUnspsc190501.48101815': __('identifierSchemeCodeUnspsc190501.48101815'),
	'identifierSchemeCodeUnspsc190501.48101816': __('identifierSchemeCodeUnspsc190501.48101816'),
	'identifierSchemeCodeUnspsc190501.48101817': __('identifierSchemeCodeUnspsc190501.48101817'),
	'identifierSchemeCodeUnspsc190501.48101818': __('identifierSchemeCodeUnspsc190501.48101818'),
	'identifierSchemeCodeUnspsc190501.48101819': __('identifierSchemeCodeUnspsc190501.48101819'),
	'identifierSchemeCodeUnspsc190501.48101820': __('identifierSchemeCodeUnspsc190501.48101820'),
	'identifierSchemeCodeUnspsc190501.48101821': __('identifierSchemeCodeUnspsc190501.48101821'),
	'identifierSchemeCodeUnspsc190501.48101900': __('identifierSchemeCodeUnspsc190501.48101900'),
	'identifierSchemeCodeUnspsc190501.48101901': __('identifierSchemeCodeUnspsc190501.48101901'),
	'identifierSchemeCodeUnspsc190501.48101902': __('identifierSchemeCodeUnspsc190501.48101902'),
	'identifierSchemeCodeUnspsc190501.48101903': __('identifierSchemeCodeUnspsc190501.48101903'),
	'identifierSchemeCodeUnspsc190501.48101904': __('identifierSchemeCodeUnspsc190501.48101904'),
	'identifierSchemeCodeUnspsc190501.48101905': __('identifierSchemeCodeUnspsc190501.48101905'),
	'identifierSchemeCodeUnspsc190501.48101906': __('identifierSchemeCodeUnspsc190501.48101906'),
	'identifierSchemeCodeUnspsc190501.48101907': __('identifierSchemeCodeUnspsc190501.48101907'),
	'identifierSchemeCodeUnspsc190501.48101908': __('identifierSchemeCodeUnspsc190501.48101908'),
	'identifierSchemeCodeUnspsc190501.48101909': __('identifierSchemeCodeUnspsc190501.48101909'),
	'identifierSchemeCodeUnspsc190501.48101910': __('identifierSchemeCodeUnspsc190501.48101910'),
	'identifierSchemeCodeUnspsc190501.48101911': __('identifierSchemeCodeUnspsc190501.48101911'),
	'identifierSchemeCodeUnspsc190501.48101912': __('identifierSchemeCodeUnspsc190501.48101912'),
	'identifierSchemeCodeUnspsc190501.48101913': __('identifierSchemeCodeUnspsc190501.48101913'),
	'identifierSchemeCodeUnspsc190501.48101914': __('identifierSchemeCodeUnspsc190501.48101914'),
	'identifierSchemeCodeUnspsc190501.48101915': __('identifierSchemeCodeUnspsc190501.48101915'),
	'identifierSchemeCodeUnspsc190501.48101916': __('identifierSchemeCodeUnspsc190501.48101916'),
	'identifierSchemeCodeUnspsc190501.48101917': __('identifierSchemeCodeUnspsc190501.48101917'),
	'identifierSchemeCodeUnspsc190501.48101918': __('identifierSchemeCodeUnspsc190501.48101918'),
	'identifierSchemeCodeUnspsc190501.48101919': __('identifierSchemeCodeUnspsc190501.48101919'),
	'identifierSchemeCodeUnspsc190501.48101920': __('identifierSchemeCodeUnspsc190501.48101920'),
	'identifierSchemeCodeUnspsc190501.48102000': __('identifierSchemeCodeUnspsc190501.48102000'),
	'identifierSchemeCodeUnspsc190501.48102001': __('identifierSchemeCodeUnspsc190501.48102001'),
	'identifierSchemeCodeUnspsc190501.48102002': __('identifierSchemeCodeUnspsc190501.48102002'),
	'identifierSchemeCodeUnspsc190501.48102003': __('identifierSchemeCodeUnspsc190501.48102003'),
	'identifierSchemeCodeUnspsc190501.48102004': __('identifierSchemeCodeUnspsc190501.48102004'),
	'identifierSchemeCodeUnspsc190501.48102005': __('identifierSchemeCodeUnspsc190501.48102005'),
	'identifierSchemeCodeUnspsc190501.48102006': __('identifierSchemeCodeUnspsc190501.48102006'),
	'identifierSchemeCodeUnspsc190501.48102007': __('identifierSchemeCodeUnspsc190501.48102007'),
	'identifierSchemeCodeUnspsc190501.48102008': __('identifierSchemeCodeUnspsc190501.48102008'),
	'identifierSchemeCodeUnspsc190501.48102009': __('identifierSchemeCodeUnspsc190501.48102009'),
	'identifierSchemeCodeUnspsc190501.48102010': __('identifierSchemeCodeUnspsc190501.48102010'),
	'identifierSchemeCodeUnspsc190501.48102011': __('identifierSchemeCodeUnspsc190501.48102011'),
	'identifierSchemeCodeUnspsc190501.48102012': __('identifierSchemeCodeUnspsc190501.48102012'),
	'identifierSchemeCodeUnspsc190501.48102100': __('identifierSchemeCodeUnspsc190501.48102100'),
	'identifierSchemeCodeUnspsc190501.48102101': __('identifierSchemeCodeUnspsc190501.48102101'),
	'identifierSchemeCodeUnspsc190501.48102102': __('identifierSchemeCodeUnspsc190501.48102102'),
	'identifierSchemeCodeUnspsc190501.48102103': __('identifierSchemeCodeUnspsc190501.48102103'),
	'identifierSchemeCodeUnspsc190501.48102104': __('identifierSchemeCodeUnspsc190501.48102104'),
	'identifierSchemeCodeUnspsc190501.48102105': __('identifierSchemeCodeUnspsc190501.48102105'),
	'identifierSchemeCodeUnspsc190501.48102106': __('identifierSchemeCodeUnspsc190501.48102106'),
	'identifierSchemeCodeUnspsc190501.48102107': __('identifierSchemeCodeUnspsc190501.48102107'),
	'identifierSchemeCodeUnspsc190501.48102108': __('identifierSchemeCodeUnspsc190501.48102108'),
	'identifierSchemeCodeUnspsc190501.48102109': __('identifierSchemeCodeUnspsc190501.48102109'),
	'identifierSchemeCodeUnspsc190501.48102110': __('identifierSchemeCodeUnspsc190501.48102110'),
	'identifierSchemeCodeUnspsc190501.48110000': __('identifierSchemeCodeUnspsc190501.48110000'),
	'identifierSchemeCodeUnspsc190501.48111000': __('identifierSchemeCodeUnspsc190501.48111000'),
	'identifierSchemeCodeUnspsc190501.48111001': __('identifierSchemeCodeUnspsc190501.48111001'),
	'identifierSchemeCodeUnspsc190501.48111002': __('identifierSchemeCodeUnspsc190501.48111002'),
	'identifierSchemeCodeUnspsc190501.48111100': __('identifierSchemeCodeUnspsc190501.48111100'),
	'identifierSchemeCodeUnspsc190501.48111101': __('identifierSchemeCodeUnspsc190501.48111101'),
	'identifierSchemeCodeUnspsc190501.48111102': __('identifierSchemeCodeUnspsc190501.48111102'),
	'identifierSchemeCodeUnspsc190501.48111103': __('identifierSchemeCodeUnspsc190501.48111103'),
	'identifierSchemeCodeUnspsc190501.48111104': __('identifierSchemeCodeUnspsc190501.48111104'),
	'identifierSchemeCodeUnspsc190501.48111105': __('identifierSchemeCodeUnspsc190501.48111105'),
	'identifierSchemeCodeUnspsc190501.48111106': __('identifierSchemeCodeUnspsc190501.48111106'),
	'identifierSchemeCodeUnspsc190501.48111107': __('identifierSchemeCodeUnspsc190501.48111107'),
	'identifierSchemeCodeUnspsc190501.48111108': __('identifierSchemeCodeUnspsc190501.48111108'),
	'identifierSchemeCodeUnspsc190501.48111109': __('identifierSchemeCodeUnspsc190501.48111109'),
	'identifierSchemeCodeUnspsc190501.48111200': __('identifierSchemeCodeUnspsc190501.48111200'),
	'identifierSchemeCodeUnspsc190501.48111201': __('identifierSchemeCodeUnspsc190501.48111201'),
	'identifierSchemeCodeUnspsc190501.48111202': __('identifierSchemeCodeUnspsc190501.48111202'),
	'identifierSchemeCodeUnspsc190501.48111300': __('identifierSchemeCodeUnspsc190501.48111300'),
	'identifierSchemeCodeUnspsc190501.48111301': __('identifierSchemeCodeUnspsc190501.48111301'),
	'identifierSchemeCodeUnspsc190501.48111302': __('identifierSchemeCodeUnspsc190501.48111302'),
	'identifierSchemeCodeUnspsc190501.48111303': __('identifierSchemeCodeUnspsc190501.48111303'),
	'identifierSchemeCodeUnspsc190501.48111304': __('identifierSchemeCodeUnspsc190501.48111304'),
	'identifierSchemeCodeUnspsc190501.48111305': __('identifierSchemeCodeUnspsc190501.48111305'),
	'identifierSchemeCodeUnspsc190501.48111306': __('identifierSchemeCodeUnspsc190501.48111306'),
	'identifierSchemeCodeUnspsc190501.48111400': __('identifierSchemeCodeUnspsc190501.48111400'),
	'identifierSchemeCodeUnspsc190501.48111401': __('identifierSchemeCodeUnspsc190501.48111401'),
	'identifierSchemeCodeUnspsc190501.48111402': __('identifierSchemeCodeUnspsc190501.48111402'),
	'identifierSchemeCodeUnspsc190501.48111403': __('identifierSchemeCodeUnspsc190501.48111403'),
	'identifierSchemeCodeUnspsc190501.48111404': __('identifierSchemeCodeUnspsc190501.48111404'),
	'identifierSchemeCodeUnspsc190501.48111405': __('identifierSchemeCodeUnspsc190501.48111405'),
	'identifierSchemeCodeUnspsc190501.48111406': __('identifierSchemeCodeUnspsc190501.48111406'),
	'identifierSchemeCodeUnspsc190501.48120000': __('identifierSchemeCodeUnspsc190501.48120000'),
	'identifierSchemeCodeUnspsc190501.48121100': __('identifierSchemeCodeUnspsc190501.48121100'),
	'identifierSchemeCodeUnspsc190501.48121101': __('identifierSchemeCodeUnspsc190501.48121101'),
	'identifierSchemeCodeUnspsc190501.48121102': __('identifierSchemeCodeUnspsc190501.48121102'),
	'identifierSchemeCodeUnspsc190501.48121200': __('identifierSchemeCodeUnspsc190501.48121200'),
	'identifierSchemeCodeUnspsc190501.48121201': __('identifierSchemeCodeUnspsc190501.48121201'),
	'identifierSchemeCodeUnspsc190501.48121202': __('identifierSchemeCodeUnspsc190501.48121202'),
	'identifierSchemeCodeUnspsc190501.48121300': __('identifierSchemeCodeUnspsc190501.48121300'),
	'identifierSchemeCodeUnspsc190501.48121301': __('identifierSchemeCodeUnspsc190501.48121301'),
	'identifierSchemeCodeUnspsc190501.48121302': __('identifierSchemeCodeUnspsc190501.48121302'),
	'identifierSchemeCodeUnspsc190501.48130000': __('identifierSchemeCodeUnspsc190501.48130000'),
	'identifierSchemeCodeUnspsc190501.48131500': __('identifierSchemeCodeUnspsc190501.48131500'),
	'identifierSchemeCodeUnspsc190501.48131501': __('identifierSchemeCodeUnspsc190501.48131501'),
	'identifierSchemeCodeUnspsc190501.48131502': __('identifierSchemeCodeUnspsc190501.48131502'),
	'identifierSchemeCodeUnspsc190501.48131503': __('identifierSchemeCodeUnspsc190501.48131503'),
	'identifierSchemeCodeUnspsc190501.48131504': __('identifierSchemeCodeUnspsc190501.48131504'),
	'identifierSchemeCodeUnspsc190501.48131505': __('identifierSchemeCodeUnspsc190501.48131505'),
	'identifierSchemeCodeUnspsc190501.49000000': __('identifierSchemeCodeUnspsc190501.49000000'),
	'identifierSchemeCodeUnspsc190501.49100000': __('identifierSchemeCodeUnspsc190501.49100000'),
	'identifierSchemeCodeUnspsc190501.49101600': __('identifierSchemeCodeUnspsc190501.49101600'),
	'identifierSchemeCodeUnspsc190501.49101601': __('identifierSchemeCodeUnspsc190501.49101601'),
	'identifierSchemeCodeUnspsc190501.49101602': __('identifierSchemeCodeUnspsc190501.49101602'),
	'identifierSchemeCodeUnspsc190501.49101603': __('identifierSchemeCodeUnspsc190501.49101603'),
	'identifierSchemeCodeUnspsc190501.49101604': __('identifierSchemeCodeUnspsc190501.49101604'),
	'identifierSchemeCodeUnspsc190501.49101605': __('identifierSchemeCodeUnspsc190501.49101605'),
	'identifierSchemeCodeUnspsc190501.49101606': __('identifierSchemeCodeUnspsc190501.49101606'),
	'identifierSchemeCodeUnspsc190501.49101607': __('identifierSchemeCodeUnspsc190501.49101607'),
	'identifierSchemeCodeUnspsc190501.49101608': __('identifierSchemeCodeUnspsc190501.49101608'),
	'identifierSchemeCodeUnspsc190501.49101609': __('identifierSchemeCodeUnspsc190501.49101609'),
	'identifierSchemeCodeUnspsc190501.49101611': __('identifierSchemeCodeUnspsc190501.49101611'),
	'identifierSchemeCodeUnspsc190501.49101612': __('identifierSchemeCodeUnspsc190501.49101612'),
	'identifierSchemeCodeUnspsc190501.49101613': __('identifierSchemeCodeUnspsc190501.49101613'),
	'identifierSchemeCodeUnspsc190501.49101614': __('identifierSchemeCodeUnspsc190501.49101614'),
	'identifierSchemeCodeUnspsc190501.49101700': __('identifierSchemeCodeUnspsc190501.49101700'),
	'identifierSchemeCodeUnspsc190501.49101701': __('identifierSchemeCodeUnspsc190501.49101701'),
	'identifierSchemeCodeUnspsc190501.49101702': __('identifierSchemeCodeUnspsc190501.49101702'),
	'identifierSchemeCodeUnspsc190501.49101704': __('identifierSchemeCodeUnspsc190501.49101704'),
	'identifierSchemeCodeUnspsc190501.49101705': __('identifierSchemeCodeUnspsc190501.49101705'),
	'identifierSchemeCodeUnspsc190501.49101706': __('identifierSchemeCodeUnspsc190501.49101706'),
	'identifierSchemeCodeUnspsc190501.49101707': __('identifierSchemeCodeUnspsc190501.49101707'),
	'identifierSchemeCodeUnspsc190501.49101708': __('identifierSchemeCodeUnspsc190501.49101708'),
	'identifierSchemeCodeUnspsc190501.49101709': __('identifierSchemeCodeUnspsc190501.49101709'),
	'identifierSchemeCodeUnspsc190501.49120000': __('identifierSchemeCodeUnspsc190501.49120000'),
	'identifierSchemeCodeUnspsc190501.49121500': __('identifierSchemeCodeUnspsc190501.49121500'),
	'identifierSchemeCodeUnspsc190501.49121502': __('identifierSchemeCodeUnspsc190501.49121502'),
	'identifierSchemeCodeUnspsc190501.49121503': __('identifierSchemeCodeUnspsc190501.49121503'),
	'identifierSchemeCodeUnspsc190501.49121504': __('identifierSchemeCodeUnspsc190501.49121504'),
	'identifierSchemeCodeUnspsc190501.49121505': __('identifierSchemeCodeUnspsc190501.49121505'),
	'identifierSchemeCodeUnspsc190501.49121506': __('identifierSchemeCodeUnspsc190501.49121506'),
	'identifierSchemeCodeUnspsc190501.49121507': __('identifierSchemeCodeUnspsc190501.49121507'),
	'identifierSchemeCodeUnspsc190501.49121508': __('identifierSchemeCodeUnspsc190501.49121508'),
	'identifierSchemeCodeUnspsc190501.49121509': __('identifierSchemeCodeUnspsc190501.49121509'),
	'identifierSchemeCodeUnspsc190501.49121510': __('identifierSchemeCodeUnspsc190501.49121510'),
	'identifierSchemeCodeUnspsc190501.49121511': __('identifierSchemeCodeUnspsc190501.49121511'),
	'identifierSchemeCodeUnspsc190501.49121512': __('identifierSchemeCodeUnspsc190501.49121512'),
	'identifierSchemeCodeUnspsc190501.49121513': __('identifierSchemeCodeUnspsc190501.49121513'),
	'identifierSchemeCodeUnspsc190501.49121514': __('identifierSchemeCodeUnspsc190501.49121514'),
	'identifierSchemeCodeUnspsc190501.49121600': __('identifierSchemeCodeUnspsc190501.49121600'),
	'identifierSchemeCodeUnspsc190501.49121601': __('identifierSchemeCodeUnspsc190501.49121601'),
	'identifierSchemeCodeUnspsc190501.49121602': __('identifierSchemeCodeUnspsc190501.49121602'),
	'identifierSchemeCodeUnspsc190501.49121603': __('identifierSchemeCodeUnspsc190501.49121603'),
	'identifierSchemeCodeUnspsc190501.49130000': __('identifierSchemeCodeUnspsc190501.49130000'),
	'identifierSchemeCodeUnspsc190501.49131500': __('identifierSchemeCodeUnspsc190501.49131500'),
	'identifierSchemeCodeUnspsc190501.49131501': __('identifierSchemeCodeUnspsc190501.49131501'),
	'identifierSchemeCodeUnspsc190501.49131502': __('identifierSchemeCodeUnspsc190501.49131502'),
	'identifierSchemeCodeUnspsc190501.49131503': __('identifierSchemeCodeUnspsc190501.49131503'),
	'identifierSchemeCodeUnspsc190501.49131504': __('identifierSchemeCodeUnspsc190501.49131504'),
	'identifierSchemeCodeUnspsc190501.49131505': __('identifierSchemeCodeUnspsc190501.49131505'),
	'identifierSchemeCodeUnspsc190501.49131506': __('identifierSchemeCodeUnspsc190501.49131506'),
	'identifierSchemeCodeUnspsc190501.49131600': __('identifierSchemeCodeUnspsc190501.49131600'),
	'identifierSchemeCodeUnspsc190501.49131601': __('identifierSchemeCodeUnspsc190501.49131601'),
	'identifierSchemeCodeUnspsc190501.49131602': __('identifierSchemeCodeUnspsc190501.49131602'),
	'identifierSchemeCodeUnspsc190501.49131603': __('identifierSchemeCodeUnspsc190501.49131603'),
	'identifierSchemeCodeUnspsc190501.49131604': __('identifierSchemeCodeUnspsc190501.49131604'),
	'identifierSchemeCodeUnspsc190501.49131605': __('identifierSchemeCodeUnspsc190501.49131605'),
	'identifierSchemeCodeUnspsc190501.49131606': __('identifierSchemeCodeUnspsc190501.49131606'),
	'identifierSchemeCodeUnspsc190501.49131607': __('identifierSchemeCodeUnspsc190501.49131607'),
	'identifierSchemeCodeUnspsc190501.49140000': __('identifierSchemeCodeUnspsc190501.49140000'),
	'identifierSchemeCodeUnspsc190501.49141500': __('identifierSchemeCodeUnspsc190501.49141500'),
	'identifierSchemeCodeUnspsc190501.49141501': __('identifierSchemeCodeUnspsc190501.49141501'),
	'identifierSchemeCodeUnspsc190501.49141502': __('identifierSchemeCodeUnspsc190501.49141502'),
	'identifierSchemeCodeUnspsc190501.49141503': __('identifierSchemeCodeUnspsc190501.49141503'),
	'identifierSchemeCodeUnspsc190501.49141504': __('identifierSchemeCodeUnspsc190501.49141504'),
	'identifierSchemeCodeUnspsc190501.49141505': __('identifierSchemeCodeUnspsc190501.49141505'),
	'identifierSchemeCodeUnspsc190501.49141506': __('identifierSchemeCodeUnspsc190501.49141506'),
	'identifierSchemeCodeUnspsc190501.49141507': __('identifierSchemeCodeUnspsc190501.49141507'),
	'identifierSchemeCodeUnspsc190501.49141508': __('identifierSchemeCodeUnspsc190501.49141508'),
	'identifierSchemeCodeUnspsc190501.49141509': __('identifierSchemeCodeUnspsc190501.49141509'),
	'identifierSchemeCodeUnspsc190501.49141600': __('identifierSchemeCodeUnspsc190501.49141600'),
	'identifierSchemeCodeUnspsc190501.49141602': __('identifierSchemeCodeUnspsc190501.49141602'),
	'identifierSchemeCodeUnspsc190501.49141603': __('identifierSchemeCodeUnspsc190501.49141603'),
	'identifierSchemeCodeUnspsc190501.49141604': __('identifierSchemeCodeUnspsc190501.49141604'),
	'identifierSchemeCodeUnspsc190501.49141605': __('identifierSchemeCodeUnspsc190501.49141605'),
	'identifierSchemeCodeUnspsc190501.49141606': __('identifierSchemeCodeUnspsc190501.49141606'),
	'identifierSchemeCodeUnspsc190501.49141607': __('identifierSchemeCodeUnspsc190501.49141607'),
	'identifierSchemeCodeUnspsc190501.49141608': __('identifierSchemeCodeUnspsc190501.49141608'),
	'identifierSchemeCodeUnspsc190501.49150000': __('identifierSchemeCodeUnspsc190501.49150000'),
	'identifierSchemeCodeUnspsc190501.49151500': __('identifierSchemeCodeUnspsc190501.49151500'),
	'identifierSchemeCodeUnspsc190501.49151501': __('identifierSchemeCodeUnspsc190501.49151501'),
	'identifierSchemeCodeUnspsc190501.49151502': __('identifierSchemeCodeUnspsc190501.49151502'),
	'identifierSchemeCodeUnspsc190501.49151503': __('identifierSchemeCodeUnspsc190501.49151503'),
	'identifierSchemeCodeUnspsc190501.49151504': __('identifierSchemeCodeUnspsc190501.49151504'),
	'identifierSchemeCodeUnspsc190501.49151505': __('identifierSchemeCodeUnspsc190501.49151505'),
	'identifierSchemeCodeUnspsc190501.49151506': __('identifierSchemeCodeUnspsc190501.49151506'),
	'identifierSchemeCodeUnspsc190501.49151600': __('identifierSchemeCodeUnspsc190501.49151600'),
	'identifierSchemeCodeUnspsc190501.49151601': __('identifierSchemeCodeUnspsc190501.49151601'),
	'identifierSchemeCodeUnspsc190501.49151602': __('identifierSchemeCodeUnspsc190501.49151602'),
	'identifierSchemeCodeUnspsc190501.49151603': __('identifierSchemeCodeUnspsc190501.49151603'),
	'identifierSchemeCodeUnspsc190501.49160000': __('identifierSchemeCodeUnspsc190501.49160000'),
	'identifierSchemeCodeUnspsc190501.49161500': __('identifierSchemeCodeUnspsc190501.49161500'),
	'identifierSchemeCodeUnspsc190501.49161501': __('identifierSchemeCodeUnspsc190501.49161501'),
	'identifierSchemeCodeUnspsc190501.49161502': __('identifierSchemeCodeUnspsc190501.49161502'),
	'identifierSchemeCodeUnspsc190501.49161503': __('identifierSchemeCodeUnspsc190501.49161503'),
	'identifierSchemeCodeUnspsc190501.49161504': __('identifierSchemeCodeUnspsc190501.49161504'),
	'identifierSchemeCodeUnspsc190501.49161505': __('identifierSchemeCodeUnspsc190501.49161505'),
	'identifierSchemeCodeUnspsc190501.49161506': __('identifierSchemeCodeUnspsc190501.49161506'),
	'identifierSchemeCodeUnspsc190501.49161507': __('identifierSchemeCodeUnspsc190501.49161507'),
	'identifierSchemeCodeUnspsc190501.49161508': __('identifierSchemeCodeUnspsc190501.49161508'),
	'identifierSchemeCodeUnspsc190501.49161509': __('identifierSchemeCodeUnspsc190501.49161509'),
	'identifierSchemeCodeUnspsc190501.49161510': __('identifierSchemeCodeUnspsc190501.49161510'),
	'identifierSchemeCodeUnspsc190501.49161511': __('identifierSchemeCodeUnspsc190501.49161511'),
	'identifierSchemeCodeUnspsc190501.49161512': __('identifierSchemeCodeUnspsc190501.49161512'),
	'identifierSchemeCodeUnspsc190501.49161513': __('identifierSchemeCodeUnspsc190501.49161513'),
	'identifierSchemeCodeUnspsc190501.49161514': __('identifierSchemeCodeUnspsc190501.49161514'),
	'identifierSchemeCodeUnspsc190501.49161515': __('identifierSchemeCodeUnspsc190501.49161515'),
	'identifierSchemeCodeUnspsc190501.49161516': __('identifierSchemeCodeUnspsc190501.49161516'),
	'identifierSchemeCodeUnspsc190501.49161517': __('identifierSchemeCodeUnspsc190501.49161517'),
	'identifierSchemeCodeUnspsc190501.49161518': __('identifierSchemeCodeUnspsc190501.49161518'),
	'identifierSchemeCodeUnspsc190501.49161519': __('identifierSchemeCodeUnspsc190501.49161519'),
	'identifierSchemeCodeUnspsc190501.49161520': __('identifierSchemeCodeUnspsc190501.49161520'),
	'identifierSchemeCodeUnspsc190501.49161521': __('identifierSchemeCodeUnspsc190501.49161521'),
	'identifierSchemeCodeUnspsc190501.49161522': __('identifierSchemeCodeUnspsc190501.49161522'),
	'identifierSchemeCodeUnspsc190501.49161523': __('identifierSchemeCodeUnspsc190501.49161523'),
	'identifierSchemeCodeUnspsc190501.49161524': __('identifierSchemeCodeUnspsc190501.49161524'),
	'identifierSchemeCodeUnspsc190501.49161525': __('identifierSchemeCodeUnspsc190501.49161525'),
	'identifierSchemeCodeUnspsc190501.49161526': __('identifierSchemeCodeUnspsc190501.49161526'),
	'identifierSchemeCodeUnspsc190501.49161527': __('identifierSchemeCodeUnspsc190501.49161527'),
	'identifierSchemeCodeUnspsc190501.49161600': __('identifierSchemeCodeUnspsc190501.49161600'),
	'identifierSchemeCodeUnspsc190501.49161601': __('identifierSchemeCodeUnspsc190501.49161601'),
	'identifierSchemeCodeUnspsc190501.49161602': __('identifierSchemeCodeUnspsc190501.49161602'),
	'identifierSchemeCodeUnspsc190501.49161603': __('identifierSchemeCodeUnspsc190501.49161603'),
	'identifierSchemeCodeUnspsc190501.49161604': __('identifierSchemeCodeUnspsc190501.49161604'),
	'identifierSchemeCodeUnspsc190501.49161605': __('identifierSchemeCodeUnspsc190501.49161605'),
	'identifierSchemeCodeUnspsc190501.49161606': __('identifierSchemeCodeUnspsc190501.49161606'),
	'identifierSchemeCodeUnspsc190501.49161607': __('identifierSchemeCodeUnspsc190501.49161607'),
	'identifierSchemeCodeUnspsc190501.49161608': __('identifierSchemeCodeUnspsc190501.49161608'),
	'identifierSchemeCodeUnspsc190501.49161609': __('identifierSchemeCodeUnspsc190501.49161609'),
	'identifierSchemeCodeUnspsc190501.49161610': __('identifierSchemeCodeUnspsc190501.49161610'),
	'identifierSchemeCodeUnspsc190501.49161611': __('identifierSchemeCodeUnspsc190501.49161611'),
	'identifierSchemeCodeUnspsc190501.49161612': __('identifierSchemeCodeUnspsc190501.49161612'),
	'identifierSchemeCodeUnspsc190501.49161613': __('identifierSchemeCodeUnspsc190501.49161613'),
	'identifierSchemeCodeUnspsc190501.49161614': __('identifierSchemeCodeUnspsc190501.49161614'),
	'identifierSchemeCodeUnspsc190501.49161615': __('identifierSchemeCodeUnspsc190501.49161615'),
	'identifierSchemeCodeUnspsc190501.49161616': __('identifierSchemeCodeUnspsc190501.49161616'),
	'identifierSchemeCodeUnspsc190501.49161617': __('identifierSchemeCodeUnspsc190501.49161617'),
	'identifierSchemeCodeUnspsc190501.49161618': __('identifierSchemeCodeUnspsc190501.49161618'),
	'identifierSchemeCodeUnspsc190501.49161619': __('identifierSchemeCodeUnspsc190501.49161619'),
	'identifierSchemeCodeUnspsc190501.49161620': __('identifierSchemeCodeUnspsc190501.49161620'),
	'identifierSchemeCodeUnspsc190501.49161621': __('identifierSchemeCodeUnspsc190501.49161621'),
	'identifierSchemeCodeUnspsc190501.49161700': __('identifierSchemeCodeUnspsc190501.49161700'),
	'identifierSchemeCodeUnspsc190501.49161701': __('identifierSchemeCodeUnspsc190501.49161701'),
	'identifierSchemeCodeUnspsc190501.49161702': __('identifierSchemeCodeUnspsc190501.49161702'),
	'identifierSchemeCodeUnspsc190501.49161703': __('identifierSchemeCodeUnspsc190501.49161703'),
	'identifierSchemeCodeUnspsc190501.49161704': __('identifierSchemeCodeUnspsc190501.49161704'),
	'identifierSchemeCodeUnspsc190501.49161705': __('identifierSchemeCodeUnspsc190501.49161705'),
	'identifierSchemeCodeUnspsc190501.49161706': __('identifierSchemeCodeUnspsc190501.49161706'),
	'identifierSchemeCodeUnspsc190501.49161707': __('identifierSchemeCodeUnspsc190501.49161707'),
	'identifierSchemeCodeUnspsc190501.49161708': __('identifierSchemeCodeUnspsc190501.49161708'),
	'identifierSchemeCodeUnspsc190501.49161709': __('identifierSchemeCodeUnspsc190501.49161709'),
	'identifierSchemeCodeUnspsc190501.49161710': __('identifierSchemeCodeUnspsc190501.49161710'),
	'identifierSchemeCodeUnspsc190501.49161711': __('identifierSchemeCodeUnspsc190501.49161711'),
	'identifierSchemeCodeUnspsc190501.49170000': __('identifierSchemeCodeUnspsc190501.49170000'),
	'identifierSchemeCodeUnspsc190501.49171500': __('identifierSchemeCodeUnspsc190501.49171500'),
	'identifierSchemeCodeUnspsc190501.49171501': __('identifierSchemeCodeUnspsc190501.49171501'),
	'identifierSchemeCodeUnspsc190501.49171502': __('identifierSchemeCodeUnspsc190501.49171502'),
	'identifierSchemeCodeUnspsc190501.49171503': __('identifierSchemeCodeUnspsc190501.49171503'),
	'identifierSchemeCodeUnspsc190501.49171504': __('identifierSchemeCodeUnspsc190501.49171504'),
	'identifierSchemeCodeUnspsc190501.49171505': __('identifierSchemeCodeUnspsc190501.49171505'),
	'identifierSchemeCodeUnspsc190501.49171506': __('identifierSchemeCodeUnspsc190501.49171506'),
	'identifierSchemeCodeUnspsc190501.49171507': __('identifierSchemeCodeUnspsc190501.49171507'),
	'identifierSchemeCodeUnspsc190501.49171508': __('identifierSchemeCodeUnspsc190501.49171508'),
	'identifierSchemeCodeUnspsc190501.49171509': __('identifierSchemeCodeUnspsc190501.49171509'),
	'identifierSchemeCodeUnspsc190501.49171600': __('identifierSchemeCodeUnspsc190501.49171600'),
	'identifierSchemeCodeUnspsc190501.49171601': __('identifierSchemeCodeUnspsc190501.49171601'),
	'identifierSchemeCodeUnspsc190501.49171602': __('identifierSchemeCodeUnspsc190501.49171602'),
	'identifierSchemeCodeUnspsc190501.49171603': __('identifierSchemeCodeUnspsc190501.49171603'),
	'identifierSchemeCodeUnspsc190501.49171604': __('identifierSchemeCodeUnspsc190501.49171604'),
	'identifierSchemeCodeUnspsc190501.49180000': __('identifierSchemeCodeUnspsc190501.49180000'),
	'identifierSchemeCodeUnspsc190501.49181500': __('identifierSchemeCodeUnspsc190501.49181500'),
	'identifierSchemeCodeUnspsc190501.49181501': __('identifierSchemeCodeUnspsc190501.49181501'),
	'identifierSchemeCodeUnspsc190501.49181502': __('identifierSchemeCodeUnspsc190501.49181502'),
	'identifierSchemeCodeUnspsc190501.49181503': __('identifierSchemeCodeUnspsc190501.49181503'),
	'identifierSchemeCodeUnspsc190501.49181504': __('identifierSchemeCodeUnspsc190501.49181504'),
	'identifierSchemeCodeUnspsc190501.49181505': __('identifierSchemeCodeUnspsc190501.49181505'),
	'identifierSchemeCodeUnspsc190501.49181506': __('identifierSchemeCodeUnspsc190501.49181506'),
	'identifierSchemeCodeUnspsc190501.49181507': __('identifierSchemeCodeUnspsc190501.49181507'),
	'identifierSchemeCodeUnspsc190501.49181508': __('identifierSchemeCodeUnspsc190501.49181508'),
	'identifierSchemeCodeUnspsc190501.49181509': __('identifierSchemeCodeUnspsc190501.49181509'),
	'identifierSchemeCodeUnspsc190501.49181510': __('identifierSchemeCodeUnspsc190501.49181510'),
	'identifierSchemeCodeUnspsc190501.49181511': __('identifierSchemeCodeUnspsc190501.49181511'),
	'identifierSchemeCodeUnspsc190501.49181512': __('identifierSchemeCodeUnspsc190501.49181512'),
	'identifierSchemeCodeUnspsc190501.49181513': __('identifierSchemeCodeUnspsc190501.49181513'),
	'identifierSchemeCodeUnspsc190501.49181514': __('identifierSchemeCodeUnspsc190501.49181514'),
	'identifierSchemeCodeUnspsc190501.49181515': __('identifierSchemeCodeUnspsc190501.49181515'),
	'identifierSchemeCodeUnspsc190501.49181600': __('identifierSchemeCodeUnspsc190501.49181600'),
	'identifierSchemeCodeUnspsc190501.49181601': __('identifierSchemeCodeUnspsc190501.49181601'),
	'identifierSchemeCodeUnspsc190501.49181602': __('identifierSchemeCodeUnspsc190501.49181602'),
	'identifierSchemeCodeUnspsc190501.49181603': __('identifierSchemeCodeUnspsc190501.49181603'),
	'identifierSchemeCodeUnspsc190501.49181604': __('identifierSchemeCodeUnspsc190501.49181604'),
	'identifierSchemeCodeUnspsc190501.49181605': __('identifierSchemeCodeUnspsc190501.49181605'),
	'identifierSchemeCodeUnspsc190501.49181606': __('identifierSchemeCodeUnspsc190501.49181606'),
	'identifierSchemeCodeUnspsc190501.49181607': __('identifierSchemeCodeUnspsc190501.49181607'),
	'identifierSchemeCodeUnspsc190501.49181608': __('identifierSchemeCodeUnspsc190501.49181608'),
	'identifierSchemeCodeUnspsc190501.49181609': __('identifierSchemeCodeUnspsc190501.49181609'),
	'identifierSchemeCodeUnspsc190501.49181610': __('identifierSchemeCodeUnspsc190501.49181610'),
	'identifierSchemeCodeUnspsc190501.49181611': __('identifierSchemeCodeUnspsc190501.49181611'),
	'identifierSchemeCodeUnspsc190501.49181612': __('identifierSchemeCodeUnspsc190501.49181612'),
	'identifierSchemeCodeUnspsc190501.49181613': __('identifierSchemeCodeUnspsc190501.49181613'),
	'identifierSchemeCodeUnspsc190501.49181614': __('identifierSchemeCodeUnspsc190501.49181614'),
	'identifierSchemeCodeUnspsc190501.49200000': __('identifierSchemeCodeUnspsc190501.49200000'),
	'identifierSchemeCodeUnspsc190501.49201500': __('identifierSchemeCodeUnspsc190501.49201500'),
	'identifierSchemeCodeUnspsc190501.49201501': __('identifierSchemeCodeUnspsc190501.49201501'),
	'identifierSchemeCodeUnspsc190501.49201502': __('identifierSchemeCodeUnspsc190501.49201502'),
	'identifierSchemeCodeUnspsc190501.49201503': __('identifierSchemeCodeUnspsc190501.49201503'),
	'identifierSchemeCodeUnspsc190501.49201504': __('identifierSchemeCodeUnspsc190501.49201504'),
	'identifierSchemeCodeUnspsc190501.49201512': __('identifierSchemeCodeUnspsc190501.49201512'),
	'identifierSchemeCodeUnspsc190501.49201513': __('identifierSchemeCodeUnspsc190501.49201513'),
	'identifierSchemeCodeUnspsc190501.49201514': __('identifierSchemeCodeUnspsc190501.49201514'),
	'identifierSchemeCodeUnspsc190501.49201515': __('identifierSchemeCodeUnspsc190501.49201515'),
	'identifierSchemeCodeUnspsc190501.49201516': __('identifierSchemeCodeUnspsc190501.49201516'),
	'identifierSchemeCodeUnspsc190501.49201517': __('identifierSchemeCodeUnspsc190501.49201517'),
	'identifierSchemeCodeUnspsc190501.49201518': __('identifierSchemeCodeUnspsc190501.49201518'),
	'identifierSchemeCodeUnspsc190501.49201519': __('identifierSchemeCodeUnspsc190501.49201519'),
	'identifierSchemeCodeUnspsc190501.49201520': __('identifierSchemeCodeUnspsc190501.49201520'),
	'identifierSchemeCodeUnspsc190501.49201600': __('identifierSchemeCodeUnspsc190501.49201600'),
	'identifierSchemeCodeUnspsc190501.49201601': __('identifierSchemeCodeUnspsc190501.49201601'),
	'identifierSchemeCodeUnspsc190501.49201602': __('identifierSchemeCodeUnspsc190501.49201602'),
	'identifierSchemeCodeUnspsc190501.49201603': __('identifierSchemeCodeUnspsc190501.49201603'),
	'identifierSchemeCodeUnspsc190501.49201604': __('identifierSchemeCodeUnspsc190501.49201604'),
	'identifierSchemeCodeUnspsc190501.49201605': __('identifierSchemeCodeUnspsc190501.49201605'),
	'identifierSchemeCodeUnspsc190501.49201606': __('identifierSchemeCodeUnspsc190501.49201606'),
	'identifierSchemeCodeUnspsc190501.49201607': __('identifierSchemeCodeUnspsc190501.49201607'),
	'identifierSchemeCodeUnspsc190501.49201608': __('identifierSchemeCodeUnspsc190501.49201608'),
	'identifierSchemeCodeUnspsc190501.49201609': __('identifierSchemeCodeUnspsc190501.49201609'),
	'identifierSchemeCodeUnspsc190501.49201610': __('identifierSchemeCodeUnspsc190501.49201610'),
	'identifierSchemeCodeUnspsc190501.49201611': __('identifierSchemeCodeUnspsc190501.49201611'),
	'identifierSchemeCodeUnspsc190501.49201612': __('identifierSchemeCodeUnspsc190501.49201612'),
	'identifierSchemeCodeUnspsc190501.49210000': __('identifierSchemeCodeUnspsc190501.49210000'),
	'identifierSchemeCodeUnspsc190501.49211600': __('identifierSchemeCodeUnspsc190501.49211600'),
	'identifierSchemeCodeUnspsc190501.49211601': __('identifierSchemeCodeUnspsc190501.49211601'),
	'identifierSchemeCodeUnspsc190501.49211602': __('identifierSchemeCodeUnspsc190501.49211602'),
	'identifierSchemeCodeUnspsc190501.49211603': __('identifierSchemeCodeUnspsc190501.49211603'),
	'identifierSchemeCodeUnspsc190501.49211604': __('identifierSchemeCodeUnspsc190501.49211604'),
	'identifierSchemeCodeUnspsc190501.49211605': __('identifierSchemeCodeUnspsc190501.49211605'),
	'identifierSchemeCodeUnspsc190501.49211606': __('identifierSchemeCodeUnspsc190501.49211606'),
	'identifierSchemeCodeUnspsc190501.49211607': __('identifierSchemeCodeUnspsc190501.49211607'),
	'identifierSchemeCodeUnspsc190501.49211608': __('identifierSchemeCodeUnspsc190501.49211608'),
	'identifierSchemeCodeUnspsc190501.49211609': __('identifierSchemeCodeUnspsc190501.49211609'),
	'identifierSchemeCodeUnspsc190501.49211610': __('identifierSchemeCodeUnspsc190501.49211610'),
	'identifierSchemeCodeUnspsc190501.49211700': __('identifierSchemeCodeUnspsc190501.49211700'),
	'identifierSchemeCodeUnspsc190501.49211701': __('identifierSchemeCodeUnspsc190501.49211701'),
	'identifierSchemeCodeUnspsc190501.49211702': __('identifierSchemeCodeUnspsc190501.49211702'),
	'identifierSchemeCodeUnspsc190501.49211703': __('identifierSchemeCodeUnspsc190501.49211703'),
	'identifierSchemeCodeUnspsc190501.49211800': __('identifierSchemeCodeUnspsc190501.49211800'),
	'identifierSchemeCodeUnspsc190501.49211801': __('identifierSchemeCodeUnspsc190501.49211801'),
	'identifierSchemeCodeUnspsc190501.49211802': __('identifierSchemeCodeUnspsc190501.49211802'),
	'identifierSchemeCodeUnspsc190501.49211803': __('identifierSchemeCodeUnspsc190501.49211803'),
	'identifierSchemeCodeUnspsc190501.49211804': __('identifierSchemeCodeUnspsc190501.49211804'),
	'identifierSchemeCodeUnspsc190501.49211805': __('identifierSchemeCodeUnspsc190501.49211805'),
	'identifierSchemeCodeUnspsc190501.49211806': __('identifierSchemeCodeUnspsc190501.49211806'),
	'identifierSchemeCodeUnspsc190501.49211807': __('identifierSchemeCodeUnspsc190501.49211807'),
	'identifierSchemeCodeUnspsc190501.49211808': __('identifierSchemeCodeUnspsc190501.49211808'),
	'identifierSchemeCodeUnspsc190501.49211809': __('identifierSchemeCodeUnspsc190501.49211809'),
	'identifierSchemeCodeUnspsc190501.49211810': __('identifierSchemeCodeUnspsc190501.49211810'),
	'identifierSchemeCodeUnspsc190501.49211811': __('identifierSchemeCodeUnspsc190501.49211811'),
	'identifierSchemeCodeUnspsc190501.49211812': __('identifierSchemeCodeUnspsc190501.49211812'),
	'identifierSchemeCodeUnspsc190501.49211813': __('identifierSchemeCodeUnspsc190501.49211813'),
	'identifierSchemeCodeUnspsc190501.49211814': __('identifierSchemeCodeUnspsc190501.49211814'),
	'identifierSchemeCodeUnspsc190501.49211815': __('identifierSchemeCodeUnspsc190501.49211815'),
	'identifierSchemeCodeUnspsc190501.49211816': __('identifierSchemeCodeUnspsc190501.49211816'),
	'identifierSchemeCodeUnspsc190501.49211817': __('identifierSchemeCodeUnspsc190501.49211817'),
	'identifierSchemeCodeUnspsc190501.49211818': __('identifierSchemeCodeUnspsc190501.49211818'),
	'identifierSchemeCodeUnspsc190501.49211819': __('identifierSchemeCodeUnspsc190501.49211819'),
	'identifierSchemeCodeUnspsc190501.49211820': __('identifierSchemeCodeUnspsc190501.49211820'),
	'identifierSchemeCodeUnspsc190501.49211821': __('identifierSchemeCodeUnspsc190501.49211821'),
	'identifierSchemeCodeUnspsc190501.49211822': __('identifierSchemeCodeUnspsc190501.49211822'),
	'identifierSchemeCodeUnspsc190501.49211823': __('identifierSchemeCodeUnspsc190501.49211823'),
	'identifierSchemeCodeUnspsc190501.49211824': __('identifierSchemeCodeUnspsc190501.49211824'),
	'identifierSchemeCodeUnspsc190501.49211825': __('identifierSchemeCodeUnspsc190501.49211825'),
	'identifierSchemeCodeUnspsc190501.49211826': __('identifierSchemeCodeUnspsc190501.49211826'),
	'identifierSchemeCodeUnspsc190501.49211827': __('identifierSchemeCodeUnspsc190501.49211827'),
	'identifierSchemeCodeUnspsc190501.49211828': __('identifierSchemeCodeUnspsc190501.49211828'),
	'identifierSchemeCodeUnspsc190501.49211829': __('identifierSchemeCodeUnspsc190501.49211829'),
	'identifierSchemeCodeUnspsc190501.49211830': __('identifierSchemeCodeUnspsc190501.49211830'),
	'identifierSchemeCodeUnspsc190501.49211831': __('identifierSchemeCodeUnspsc190501.49211831'),
	'identifierSchemeCodeUnspsc190501.49211832': __('identifierSchemeCodeUnspsc190501.49211832'),
	'identifierSchemeCodeUnspsc190501.49211833': __('identifierSchemeCodeUnspsc190501.49211833'),
	'identifierSchemeCodeUnspsc190501.49211834': __('identifierSchemeCodeUnspsc190501.49211834'),
	'identifierSchemeCodeUnspsc190501.49220000': __('identifierSchemeCodeUnspsc190501.49220000'),
	'identifierSchemeCodeUnspsc190501.49221500': __('identifierSchemeCodeUnspsc190501.49221500'),
	'identifierSchemeCodeUnspsc190501.49221501': __('identifierSchemeCodeUnspsc190501.49221501'),
	'identifierSchemeCodeUnspsc190501.49221502': __('identifierSchemeCodeUnspsc190501.49221502'),
	'identifierSchemeCodeUnspsc190501.49221503': __('identifierSchemeCodeUnspsc190501.49221503'),
	'identifierSchemeCodeUnspsc190501.49221504': __('identifierSchemeCodeUnspsc190501.49221504'),
	'identifierSchemeCodeUnspsc190501.49221505': __('identifierSchemeCodeUnspsc190501.49221505'),
	'identifierSchemeCodeUnspsc190501.49221506': __('identifierSchemeCodeUnspsc190501.49221506'),
	'identifierSchemeCodeUnspsc190501.49221507': __('identifierSchemeCodeUnspsc190501.49221507'),
	'identifierSchemeCodeUnspsc190501.49221508': __('identifierSchemeCodeUnspsc190501.49221508'),
	'identifierSchemeCodeUnspsc190501.49221509': __('identifierSchemeCodeUnspsc190501.49221509'),
	'identifierSchemeCodeUnspsc190501.49221510': __('identifierSchemeCodeUnspsc190501.49221510'),
	'identifierSchemeCodeUnspsc190501.49221511': __('identifierSchemeCodeUnspsc190501.49221511'),
	'identifierSchemeCodeUnspsc190501.49221512': __('identifierSchemeCodeUnspsc190501.49221512'),
	'identifierSchemeCodeUnspsc190501.49221513': __('identifierSchemeCodeUnspsc190501.49221513'),
	'identifierSchemeCodeUnspsc190501.49221514': __('identifierSchemeCodeUnspsc190501.49221514'),
	'identifierSchemeCodeUnspsc190501.49221515': __('identifierSchemeCodeUnspsc190501.49221515'),
	'identifierSchemeCodeUnspsc190501.49221516': __('identifierSchemeCodeUnspsc190501.49221516'),
	'identifierSchemeCodeUnspsc190501.49221517': __('identifierSchemeCodeUnspsc190501.49221517'),
	'identifierSchemeCodeUnspsc190501.49221518': __('identifierSchemeCodeUnspsc190501.49221518'),
	'identifierSchemeCodeUnspsc190501.49221519': __('identifierSchemeCodeUnspsc190501.49221519'),
	'identifierSchemeCodeUnspsc190501.49221520': __('identifierSchemeCodeUnspsc190501.49221520'),
	'identifierSchemeCodeUnspsc190501.49221521': __('identifierSchemeCodeUnspsc190501.49221521'),
	'identifierSchemeCodeUnspsc190501.49221522': __('identifierSchemeCodeUnspsc190501.49221522'),
	'identifierSchemeCodeUnspsc190501.49221523': __('identifierSchemeCodeUnspsc190501.49221523'),
	'identifierSchemeCodeUnspsc190501.49221524': __('identifierSchemeCodeUnspsc190501.49221524'),
	'identifierSchemeCodeUnspsc190501.49221525': __('identifierSchemeCodeUnspsc190501.49221525'),
	'identifierSchemeCodeUnspsc190501.49221526': __('identifierSchemeCodeUnspsc190501.49221526'),
	'identifierSchemeCodeUnspsc190501.49221527': __('identifierSchemeCodeUnspsc190501.49221527'),
	'identifierSchemeCodeUnspsc190501.49221528': __('identifierSchemeCodeUnspsc190501.49221528'),
	'identifierSchemeCodeUnspsc190501.49221529': __('identifierSchemeCodeUnspsc190501.49221529'),
	'identifierSchemeCodeUnspsc190501.49221530': __('identifierSchemeCodeUnspsc190501.49221530'),
	'identifierSchemeCodeUnspsc190501.49221531': __('identifierSchemeCodeUnspsc190501.49221531'),
	'identifierSchemeCodeUnspsc190501.49221532': __('identifierSchemeCodeUnspsc190501.49221532'),
	'identifierSchemeCodeUnspsc190501.49221533': __('identifierSchemeCodeUnspsc190501.49221533'),
	'identifierSchemeCodeUnspsc190501.49240000': __('identifierSchemeCodeUnspsc190501.49240000'),
	'identifierSchemeCodeUnspsc190501.49241500': __('identifierSchemeCodeUnspsc190501.49241500'),
	'identifierSchemeCodeUnspsc190501.49241501': __('identifierSchemeCodeUnspsc190501.49241501'),
	'identifierSchemeCodeUnspsc190501.49241502': __('identifierSchemeCodeUnspsc190501.49241502'),
	'identifierSchemeCodeUnspsc190501.49241503': __('identifierSchemeCodeUnspsc190501.49241503'),
	'identifierSchemeCodeUnspsc190501.49241504': __('identifierSchemeCodeUnspsc190501.49241504'),
	'identifierSchemeCodeUnspsc190501.49241505': __('identifierSchemeCodeUnspsc190501.49241505'),
	'identifierSchemeCodeUnspsc190501.49241506': __('identifierSchemeCodeUnspsc190501.49241506'),
	'identifierSchemeCodeUnspsc190501.49241507': __('identifierSchemeCodeUnspsc190501.49241507'),
	'identifierSchemeCodeUnspsc190501.49241508': __('identifierSchemeCodeUnspsc190501.49241508'),
	'identifierSchemeCodeUnspsc190501.49241509': __('identifierSchemeCodeUnspsc190501.49241509'),
	'identifierSchemeCodeUnspsc190501.49241510': __('identifierSchemeCodeUnspsc190501.49241510'),
	'identifierSchemeCodeUnspsc190501.49241511': __('identifierSchemeCodeUnspsc190501.49241511'),
	'identifierSchemeCodeUnspsc190501.49241512': __('identifierSchemeCodeUnspsc190501.49241512'),
	'identifierSchemeCodeUnspsc190501.49241600': __('identifierSchemeCodeUnspsc190501.49241600'),
	'identifierSchemeCodeUnspsc190501.49241601': __('identifierSchemeCodeUnspsc190501.49241601'),
	'identifierSchemeCodeUnspsc190501.49241602': __('identifierSchemeCodeUnspsc190501.49241602'),
	'identifierSchemeCodeUnspsc190501.49241603': __('identifierSchemeCodeUnspsc190501.49241603'),
	'identifierSchemeCodeUnspsc190501.49241604': __('identifierSchemeCodeUnspsc190501.49241604'),
	'identifierSchemeCodeUnspsc190501.49241700': __('identifierSchemeCodeUnspsc190501.49241700'),
	'identifierSchemeCodeUnspsc190501.49241701': __('identifierSchemeCodeUnspsc190501.49241701'),
	'identifierSchemeCodeUnspsc190501.49241702': __('identifierSchemeCodeUnspsc190501.49241702'),
	'identifierSchemeCodeUnspsc190501.49241703': __('identifierSchemeCodeUnspsc190501.49241703'),
	'identifierSchemeCodeUnspsc190501.49241704': __('identifierSchemeCodeUnspsc190501.49241704'),
	'identifierSchemeCodeUnspsc190501.49241705': __('identifierSchemeCodeUnspsc190501.49241705'),
	'identifierSchemeCodeUnspsc190501.49241706': __('identifierSchemeCodeUnspsc190501.49241706'),
	'identifierSchemeCodeUnspsc190501.49241707': __('identifierSchemeCodeUnspsc190501.49241707'),
	'identifierSchemeCodeUnspsc190501.49241708': __('identifierSchemeCodeUnspsc190501.49241708'),
	'identifierSchemeCodeUnspsc190501.49241709': __('identifierSchemeCodeUnspsc190501.49241709'),
	'identifierSchemeCodeUnspsc190501.49241710': __('identifierSchemeCodeUnspsc190501.49241710'),
	'identifierSchemeCodeUnspsc190501.49241711': __('identifierSchemeCodeUnspsc190501.49241711'),
	'identifierSchemeCodeUnspsc190501.49241712': __('identifierSchemeCodeUnspsc190501.49241712'),
	'identifierSchemeCodeUnspsc190501.49241800': __('identifierSchemeCodeUnspsc190501.49241800'),
	'identifierSchemeCodeUnspsc190501.49241801': __('identifierSchemeCodeUnspsc190501.49241801'),
	'identifierSchemeCodeUnspsc190501.50000000': __('identifierSchemeCodeUnspsc190501.50000000'),
	'identifierSchemeCodeUnspsc190501.50100000': __('identifierSchemeCodeUnspsc190501.50100000'),
	'identifierSchemeCodeUnspsc190501.50101700': __('identifierSchemeCodeUnspsc190501.50101700'),
	'identifierSchemeCodeUnspsc190501.50101716': __('identifierSchemeCodeUnspsc190501.50101716'),
	'identifierSchemeCodeUnspsc190501.50101717': __('identifierSchemeCodeUnspsc190501.50101717'),
	'identifierSchemeCodeUnspsc190501.50102500': __('identifierSchemeCodeUnspsc190501.50102500'),
	'identifierSchemeCodeUnspsc190501.50102501': __('identifierSchemeCodeUnspsc190501.50102501'),
	'identifierSchemeCodeUnspsc190501.50102502': __('identifierSchemeCodeUnspsc190501.50102502'),
	'identifierSchemeCodeUnspsc190501.50102503': __('identifierSchemeCodeUnspsc190501.50102503'),
	'identifierSchemeCodeUnspsc190501.50102504': __('identifierSchemeCodeUnspsc190501.50102504'),
	'identifierSchemeCodeUnspsc190501.50102505': __('identifierSchemeCodeUnspsc190501.50102505'),
	'identifierSchemeCodeUnspsc190501.50102506': __('identifierSchemeCodeUnspsc190501.50102506'),
	'identifierSchemeCodeUnspsc190501.50102507': __('identifierSchemeCodeUnspsc190501.50102507'),
	'identifierSchemeCodeUnspsc190501.50102508': __('identifierSchemeCodeUnspsc190501.50102508'),
	'identifierSchemeCodeUnspsc190501.50102509': __('identifierSchemeCodeUnspsc190501.50102509'),
	'identifierSchemeCodeUnspsc190501.50102510': __('identifierSchemeCodeUnspsc190501.50102510'),
	'identifierSchemeCodeUnspsc190501.50102511': __('identifierSchemeCodeUnspsc190501.50102511'),
	'identifierSchemeCodeUnspsc190501.50102512': __('identifierSchemeCodeUnspsc190501.50102512'),
	'identifierSchemeCodeUnspsc190501.50102513': __('identifierSchemeCodeUnspsc190501.50102513'),
	'identifierSchemeCodeUnspsc190501.50102514': __('identifierSchemeCodeUnspsc190501.50102514'),
	'identifierSchemeCodeUnspsc190501.50102515': __('identifierSchemeCodeUnspsc190501.50102515'),
	'identifierSchemeCodeUnspsc190501.50102516': __('identifierSchemeCodeUnspsc190501.50102516'),
	'identifierSchemeCodeUnspsc190501.50110000': __('identifierSchemeCodeUnspsc190501.50110000'),
	'identifierSchemeCodeUnspsc190501.50111500': __('identifierSchemeCodeUnspsc190501.50111500'),
	'identifierSchemeCodeUnspsc190501.50111513': __('identifierSchemeCodeUnspsc190501.50111513'),
	'identifierSchemeCodeUnspsc190501.50111514': __('identifierSchemeCodeUnspsc190501.50111514'),
	'identifierSchemeCodeUnspsc190501.50111515': __('identifierSchemeCodeUnspsc190501.50111515'),
	'identifierSchemeCodeUnspsc190501.50111516': __('identifierSchemeCodeUnspsc190501.50111516'),
	'identifierSchemeCodeUnspsc190501.50111517': __('identifierSchemeCodeUnspsc190501.50111517'),
	'identifierSchemeCodeUnspsc190501.50111518': __('identifierSchemeCodeUnspsc190501.50111518'),
	'identifierSchemeCodeUnspsc190501.50111519': __('identifierSchemeCodeUnspsc190501.50111519'),
	'identifierSchemeCodeUnspsc190501.50111520': __('identifierSchemeCodeUnspsc190501.50111520'),
	'identifierSchemeCodeUnspsc190501.50111521': __('identifierSchemeCodeUnspsc190501.50111521'),
	'identifierSchemeCodeUnspsc190501.50111522': __('identifierSchemeCodeUnspsc190501.50111522'),
	'identifierSchemeCodeUnspsc190501.50111523': __('identifierSchemeCodeUnspsc190501.50111523'),
	'identifierSchemeCodeUnspsc190501.50111524': __('identifierSchemeCodeUnspsc190501.50111524'),
	'identifierSchemeCodeUnspsc190501.50111525': __('identifierSchemeCodeUnspsc190501.50111525'),
	'identifierSchemeCodeUnspsc190501.50111526': __('identifierSchemeCodeUnspsc190501.50111526'),
	'identifierSchemeCodeUnspsc190501.50111527': __('identifierSchemeCodeUnspsc190501.50111527'),
	'identifierSchemeCodeUnspsc190501.50111528': __('identifierSchemeCodeUnspsc190501.50111528'),
	'identifierSchemeCodeUnspsc190501.50111529': __('identifierSchemeCodeUnspsc190501.50111529'),
	'identifierSchemeCodeUnspsc190501.50111530': __('identifierSchemeCodeUnspsc190501.50111530'),
	'identifierSchemeCodeUnspsc190501.50111531': __('identifierSchemeCodeUnspsc190501.50111531'),
	'identifierSchemeCodeUnspsc190501.50111532': __('identifierSchemeCodeUnspsc190501.50111532'),
	'identifierSchemeCodeUnspsc190501.50111533': __('identifierSchemeCodeUnspsc190501.50111533'),
	'identifierSchemeCodeUnspsc190501.50111534': __('identifierSchemeCodeUnspsc190501.50111534'),
	'identifierSchemeCodeUnspsc190501.50111535': __('identifierSchemeCodeUnspsc190501.50111535'),
	'identifierSchemeCodeUnspsc190501.50111536': __('identifierSchemeCodeUnspsc190501.50111536'),
	'identifierSchemeCodeUnspsc190501.50111537': __('identifierSchemeCodeUnspsc190501.50111537'),
	'identifierSchemeCodeUnspsc190501.50111538': __('identifierSchemeCodeUnspsc190501.50111538'),
	'identifierSchemeCodeUnspsc190501.50111539': __('identifierSchemeCodeUnspsc190501.50111539'),
	'identifierSchemeCodeUnspsc190501.50111540': __('identifierSchemeCodeUnspsc190501.50111540'),
	'identifierSchemeCodeUnspsc190501.50111541': __('identifierSchemeCodeUnspsc190501.50111541'),
	'identifierSchemeCodeUnspsc190501.50111542': __('identifierSchemeCodeUnspsc190501.50111542'),
	'identifierSchemeCodeUnspsc190501.50111543': __('identifierSchemeCodeUnspsc190501.50111543'),
	'identifierSchemeCodeUnspsc190501.50111544': __('identifierSchemeCodeUnspsc190501.50111544'),
	'identifierSchemeCodeUnspsc190501.50111545': __('identifierSchemeCodeUnspsc190501.50111545'),
	'identifierSchemeCodeUnspsc190501.50111546': __('identifierSchemeCodeUnspsc190501.50111546'),
	'identifierSchemeCodeUnspsc190501.50111547': __('identifierSchemeCodeUnspsc190501.50111547'),
	'identifierSchemeCodeUnspsc190501.50111548': __('identifierSchemeCodeUnspsc190501.50111548'),
	'identifierSchemeCodeUnspsc190501.50111549': __('identifierSchemeCodeUnspsc190501.50111549'),
	'identifierSchemeCodeUnspsc190501.50111550': __('identifierSchemeCodeUnspsc190501.50111550'),
	'identifierSchemeCodeUnspsc190501.50111551': __('identifierSchemeCodeUnspsc190501.50111551'),
	'identifierSchemeCodeUnspsc190501.50111552': __('identifierSchemeCodeUnspsc190501.50111552'),
	'identifierSchemeCodeUnspsc190501.50111553': __('identifierSchemeCodeUnspsc190501.50111553'),
	'identifierSchemeCodeUnspsc190501.50111554': __('identifierSchemeCodeUnspsc190501.50111554'),
	'identifierSchemeCodeUnspsc190501.50111555': __('identifierSchemeCodeUnspsc190501.50111555'),
	'identifierSchemeCodeUnspsc190501.50111556': __('identifierSchemeCodeUnspsc190501.50111556'),
	'identifierSchemeCodeUnspsc190501.50111557': __('identifierSchemeCodeUnspsc190501.50111557'),
	'identifierSchemeCodeUnspsc190501.50111558': __('identifierSchemeCodeUnspsc190501.50111558'),
	'identifierSchemeCodeUnspsc190501.50111559': __('identifierSchemeCodeUnspsc190501.50111559'),
	'identifierSchemeCodeUnspsc190501.50111560': __('identifierSchemeCodeUnspsc190501.50111560'),
	'identifierSchemeCodeUnspsc190501.50111561': __('identifierSchemeCodeUnspsc190501.50111561'),
	'identifierSchemeCodeUnspsc190501.50111562': __('identifierSchemeCodeUnspsc190501.50111562'),
	'identifierSchemeCodeUnspsc190501.50112000': __('identifierSchemeCodeUnspsc190501.50112000'),
	'identifierSchemeCodeUnspsc190501.50112004': __('identifierSchemeCodeUnspsc190501.50112004'),
	'identifierSchemeCodeUnspsc190501.50112005': __('identifierSchemeCodeUnspsc190501.50112005'),
	'identifierSchemeCodeUnspsc190501.50112006': __('identifierSchemeCodeUnspsc190501.50112006'),
	'identifierSchemeCodeUnspsc190501.50112007': __('identifierSchemeCodeUnspsc190501.50112007'),
	'identifierSchemeCodeUnspsc190501.50112008': __('identifierSchemeCodeUnspsc190501.50112008'),
	'identifierSchemeCodeUnspsc190501.50112009': __('identifierSchemeCodeUnspsc190501.50112009'),
	'identifierSchemeCodeUnspsc190501.50112010': __('identifierSchemeCodeUnspsc190501.50112010'),
	'identifierSchemeCodeUnspsc190501.50112011': __('identifierSchemeCodeUnspsc190501.50112011'),
	'identifierSchemeCodeUnspsc190501.50112012': __('identifierSchemeCodeUnspsc190501.50112012'),
	'identifierSchemeCodeUnspsc190501.50112013': __('identifierSchemeCodeUnspsc190501.50112013'),
	'identifierSchemeCodeUnspsc190501.50112014': __('identifierSchemeCodeUnspsc190501.50112014'),
	'identifierSchemeCodeUnspsc190501.50112015': __('identifierSchemeCodeUnspsc190501.50112015'),
	'identifierSchemeCodeUnspsc190501.50112016': __('identifierSchemeCodeUnspsc190501.50112016'),
	'identifierSchemeCodeUnspsc190501.50112017': __('identifierSchemeCodeUnspsc190501.50112017'),
	'identifierSchemeCodeUnspsc190501.50112018': __('identifierSchemeCodeUnspsc190501.50112018'),
	'identifierSchemeCodeUnspsc190501.50112019': __('identifierSchemeCodeUnspsc190501.50112019'),
	'identifierSchemeCodeUnspsc190501.50112020': __('identifierSchemeCodeUnspsc190501.50112020'),
	'identifierSchemeCodeUnspsc190501.50112021': __('identifierSchemeCodeUnspsc190501.50112021'),
	'identifierSchemeCodeUnspsc190501.50112022': __('identifierSchemeCodeUnspsc190501.50112022'),
	'identifierSchemeCodeUnspsc190501.50112023': __('identifierSchemeCodeUnspsc190501.50112023'),
	'identifierSchemeCodeUnspsc190501.50112024': __('identifierSchemeCodeUnspsc190501.50112024'),
	'identifierSchemeCodeUnspsc190501.50112025': __('identifierSchemeCodeUnspsc190501.50112025'),
	'identifierSchemeCodeUnspsc190501.50112026': __('identifierSchemeCodeUnspsc190501.50112026'),
	'identifierSchemeCodeUnspsc190501.50112027': __('identifierSchemeCodeUnspsc190501.50112027'),
	'identifierSchemeCodeUnspsc190501.50112028': __('identifierSchemeCodeUnspsc190501.50112028'),
	'identifierSchemeCodeUnspsc190501.50112029': __('identifierSchemeCodeUnspsc190501.50112029'),
	'identifierSchemeCodeUnspsc190501.50112030': __('identifierSchemeCodeUnspsc190501.50112030'),
	'identifierSchemeCodeUnspsc190501.50112031': __('identifierSchemeCodeUnspsc190501.50112031'),
	'identifierSchemeCodeUnspsc190501.50112032': __('identifierSchemeCodeUnspsc190501.50112032'),
	'identifierSchemeCodeUnspsc190501.50112033': __('identifierSchemeCodeUnspsc190501.50112033'),
	'identifierSchemeCodeUnspsc190501.50112034': __('identifierSchemeCodeUnspsc190501.50112034'),
	'identifierSchemeCodeUnspsc190501.50112035': __('identifierSchemeCodeUnspsc190501.50112035'),
	'identifierSchemeCodeUnspsc190501.50112036': __('identifierSchemeCodeUnspsc190501.50112036'),
	'identifierSchemeCodeUnspsc190501.50112037': __('identifierSchemeCodeUnspsc190501.50112037'),
	'identifierSchemeCodeUnspsc190501.50112038': __('identifierSchemeCodeUnspsc190501.50112038'),
	'identifierSchemeCodeUnspsc190501.50112039': __('identifierSchemeCodeUnspsc190501.50112039'),
	'identifierSchemeCodeUnspsc190501.50112040': __('identifierSchemeCodeUnspsc190501.50112040'),
	'identifierSchemeCodeUnspsc190501.50112041': __('identifierSchemeCodeUnspsc190501.50112041'),
	'identifierSchemeCodeUnspsc190501.50112042': __('identifierSchemeCodeUnspsc190501.50112042'),
	'identifierSchemeCodeUnspsc190501.50112043': __('identifierSchemeCodeUnspsc190501.50112043'),
	'identifierSchemeCodeUnspsc190501.50112044': __('identifierSchemeCodeUnspsc190501.50112044'),
	'identifierSchemeCodeUnspsc190501.50112045': __('identifierSchemeCodeUnspsc190501.50112045'),
	'identifierSchemeCodeUnspsc190501.50112046': __('identifierSchemeCodeUnspsc190501.50112046'),
	'identifierSchemeCodeUnspsc190501.50112047': __('identifierSchemeCodeUnspsc190501.50112047'),
	'identifierSchemeCodeUnspsc190501.50112048': __('identifierSchemeCodeUnspsc190501.50112048'),
	'identifierSchemeCodeUnspsc190501.50112049': __('identifierSchemeCodeUnspsc190501.50112049'),
	'identifierSchemeCodeUnspsc190501.50112050': __('identifierSchemeCodeUnspsc190501.50112050'),
	'identifierSchemeCodeUnspsc190501.50112051': __('identifierSchemeCodeUnspsc190501.50112051'),
	'identifierSchemeCodeUnspsc190501.50112052': __('identifierSchemeCodeUnspsc190501.50112052'),
	'identifierSchemeCodeUnspsc190501.50112053': __('identifierSchemeCodeUnspsc190501.50112053'),
	'identifierSchemeCodeUnspsc190501.50120000': __('identifierSchemeCodeUnspsc190501.50120000'),
	'identifierSchemeCodeUnspsc190501.50121500': __('identifierSchemeCodeUnspsc190501.50121500'),
	'identifierSchemeCodeUnspsc190501.50121537': __('identifierSchemeCodeUnspsc190501.50121537'),
	'identifierSchemeCodeUnspsc190501.50121538': __('identifierSchemeCodeUnspsc190501.50121538'),
	'identifierSchemeCodeUnspsc190501.50121539': __('identifierSchemeCodeUnspsc190501.50121539'),
	'identifierSchemeCodeUnspsc190501.50121540': __('identifierSchemeCodeUnspsc190501.50121540'),
	'identifierSchemeCodeUnspsc190501.50121600': __('identifierSchemeCodeUnspsc190501.50121600'),
	'identifierSchemeCodeUnspsc190501.50121611': __('identifierSchemeCodeUnspsc190501.50121611'),
	'identifierSchemeCodeUnspsc190501.50121612': __('identifierSchemeCodeUnspsc190501.50121612'),
	'identifierSchemeCodeUnspsc190501.50121613': __('identifierSchemeCodeUnspsc190501.50121613'),
	'identifierSchemeCodeUnspsc190501.50121700': __('identifierSchemeCodeUnspsc190501.50121700'),
	'identifierSchemeCodeUnspsc190501.50121705': __('identifierSchemeCodeUnspsc190501.50121705'),
	'identifierSchemeCodeUnspsc190501.50121706': __('identifierSchemeCodeUnspsc190501.50121706'),
	'identifierSchemeCodeUnspsc190501.50121707': __('identifierSchemeCodeUnspsc190501.50121707'),
	'identifierSchemeCodeUnspsc190501.50121800': __('identifierSchemeCodeUnspsc190501.50121800'),
	'identifierSchemeCodeUnspsc190501.50121802': __('identifierSchemeCodeUnspsc190501.50121802'),
	'identifierSchemeCodeUnspsc190501.50121803': __('identifierSchemeCodeUnspsc190501.50121803'),
	'identifierSchemeCodeUnspsc190501.50121804': __('identifierSchemeCodeUnspsc190501.50121804'),
	'identifierSchemeCodeUnspsc190501.50121900': __('identifierSchemeCodeUnspsc190501.50121900'),
	'identifierSchemeCodeUnspsc190501.50121901': __('identifierSchemeCodeUnspsc190501.50121901'),
	'identifierSchemeCodeUnspsc190501.50121902': __('identifierSchemeCodeUnspsc190501.50121902'),
	'identifierSchemeCodeUnspsc190501.50121903': __('identifierSchemeCodeUnspsc190501.50121903'),
	'identifierSchemeCodeUnspsc190501.50130000': __('identifierSchemeCodeUnspsc190501.50130000'),
	'identifierSchemeCodeUnspsc190501.50131600': __('identifierSchemeCodeUnspsc190501.50131600'),
	'identifierSchemeCodeUnspsc190501.50131609': __('identifierSchemeCodeUnspsc190501.50131609'),
	'identifierSchemeCodeUnspsc190501.50131612': __('identifierSchemeCodeUnspsc190501.50131612'),
	'identifierSchemeCodeUnspsc190501.50131613': __('identifierSchemeCodeUnspsc190501.50131613'),
	'identifierSchemeCodeUnspsc190501.50131614': __('identifierSchemeCodeUnspsc190501.50131614'),
	'identifierSchemeCodeUnspsc190501.50131615': __('identifierSchemeCodeUnspsc190501.50131615'),
	'identifierSchemeCodeUnspsc190501.50131616': __('identifierSchemeCodeUnspsc190501.50131616'),
	'identifierSchemeCodeUnspsc190501.50131617': __('identifierSchemeCodeUnspsc190501.50131617'),
	'identifierSchemeCodeUnspsc190501.50131618': __('identifierSchemeCodeUnspsc190501.50131618'),
	'identifierSchemeCodeUnspsc190501.50131619': __('identifierSchemeCodeUnspsc190501.50131619'),
	'identifierSchemeCodeUnspsc190501.50131620': __('identifierSchemeCodeUnspsc190501.50131620'),
	'identifierSchemeCodeUnspsc190501.50131621': __('identifierSchemeCodeUnspsc190501.50131621'),
	'identifierSchemeCodeUnspsc190501.50131622': __('identifierSchemeCodeUnspsc190501.50131622'),
	'identifierSchemeCodeUnspsc190501.50131623': __('identifierSchemeCodeUnspsc190501.50131623'),
	'identifierSchemeCodeUnspsc190501.50131624': __('identifierSchemeCodeUnspsc190501.50131624'),
	'identifierSchemeCodeUnspsc190501.50131625': __('identifierSchemeCodeUnspsc190501.50131625'),
	'identifierSchemeCodeUnspsc190501.50131626': __('identifierSchemeCodeUnspsc190501.50131626'),
	'identifierSchemeCodeUnspsc190501.50131627': __('identifierSchemeCodeUnspsc190501.50131627'),
	'identifierSchemeCodeUnspsc190501.50131628': __('identifierSchemeCodeUnspsc190501.50131628'),
	'identifierSchemeCodeUnspsc190501.50131629': __('identifierSchemeCodeUnspsc190501.50131629'),
	'identifierSchemeCodeUnspsc190501.50131630': __('identifierSchemeCodeUnspsc190501.50131630'),
	'identifierSchemeCodeUnspsc190501.50131631': __('identifierSchemeCodeUnspsc190501.50131631'),
	'identifierSchemeCodeUnspsc190501.50131632': __('identifierSchemeCodeUnspsc190501.50131632'),
	'identifierSchemeCodeUnspsc190501.50131633': __('identifierSchemeCodeUnspsc190501.50131633'),
	'identifierSchemeCodeUnspsc190501.50131634': __('identifierSchemeCodeUnspsc190501.50131634'),
	'identifierSchemeCodeUnspsc190501.50131635': __('identifierSchemeCodeUnspsc190501.50131635'),
	'identifierSchemeCodeUnspsc190501.50131700': __('identifierSchemeCodeUnspsc190501.50131700'),
	'identifierSchemeCodeUnspsc190501.50131701': __('identifierSchemeCodeUnspsc190501.50131701'),
	'identifierSchemeCodeUnspsc190501.50131702': __('identifierSchemeCodeUnspsc190501.50131702'),
	'identifierSchemeCodeUnspsc190501.50131703': __('identifierSchemeCodeUnspsc190501.50131703'),
	'identifierSchemeCodeUnspsc190501.50131704': __('identifierSchemeCodeUnspsc190501.50131704'),
	'identifierSchemeCodeUnspsc190501.50131705': __('identifierSchemeCodeUnspsc190501.50131705'),
	'identifierSchemeCodeUnspsc190501.50131706': __('identifierSchemeCodeUnspsc190501.50131706'),
	'identifierSchemeCodeUnspsc190501.50131707': __('identifierSchemeCodeUnspsc190501.50131707'),
	'identifierSchemeCodeUnspsc190501.50131800': __('identifierSchemeCodeUnspsc190501.50131800'),
	'identifierSchemeCodeUnspsc190501.50131801': __('identifierSchemeCodeUnspsc190501.50131801'),
	'identifierSchemeCodeUnspsc190501.50131802': __('identifierSchemeCodeUnspsc190501.50131802'),
	'identifierSchemeCodeUnspsc190501.50131803': __('identifierSchemeCodeUnspsc190501.50131803'),
	'identifierSchemeCodeUnspsc190501.50150000': __('identifierSchemeCodeUnspsc190501.50150000'),
	'identifierSchemeCodeUnspsc190501.50151500': __('identifierSchemeCodeUnspsc190501.50151500'),
	'identifierSchemeCodeUnspsc190501.50151513': __('identifierSchemeCodeUnspsc190501.50151513'),
	'identifierSchemeCodeUnspsc190501.50151514': __('identifierSchemeCodeUnspsc190501.50151514'),
	'identifierSchemeCodeUnspsc190501.50151515': __('identifierSchemeCodeUnspsc190501.50151515'),
	'identifierSchemeCodeUnspsc190501.50151516': __('identifierSchemeCodeUnspsc190501.50151516'),
	'identifierSchemeCodeUnspsc190501.50151600': __('identifierSchemeCodeUnspsc190501.50151600'),
	'identifierSchemeCodeUnspsc190501.50151604': __('identifierSchemeCodeUnspsc190501.50151604'),
	'identifierSchemeCodeUnspsc190501.50151605': __('identifierSchemeCodeUnspsc190501.50151605'),
	'identifierSchemeCodeUnspsc190501.50151700': __('identifierSchemeCodeUnspsc190501.50151700'),
	'identifierSchemeCodeUnspsc190501.50151701': __('identifierSchemeCodeUnspsc190501.50151701'),
	'identifierSchemeCodeUnspsc190501.50160000': __('identifierSchemeCodeUnspsc190501.50160000'),
	'identifierSchemeCodeUnspsc190501.50161500': __('identifierSchemeCodeUnspsc190501.50161500'),
	'identifierSchemeCodeUnspsc190501.50161509': __('identifierSchemeCodeUnspsc190501.50161509'),
	'identifierSchemeCodeUnspsc190501.50161510': __('identifierSchemeCodeUnspsc190501.50161510'),
	'identifierSchemeCodeUnspsc190501.50161511': __('identifierSchemeCodeUnspsc190501.50161511'),
	'identifierSchemeCodeUnspsc190501.50161512': __('identifierSchemeCodeUnspsc190501.50161512'),
	'identifierSchemeCodeUnspsc190501.50161800': __('identifierSchemeCodeUnspsc190501.50161800'),
	'identifierSchemeCodeUnspsc190501.50161813': __('identifierSchemeCodeUnspsc190501.50161813'),
	'identifierSchemeCodeUnspsc190501.50161814': __('identifierSchemeCodeUnspsc190501.50161814'),
	'identifierSchemeCodeUnspsc190501.50161815': __('identifierSchemeCodeUnspsc190501.50161815'),
	'identifierSchemeCodeUnspsc190501.50161900': __('identifierSchemeCodeUnspsc190501.50161900'),
	'identifierSchemeCodeUnspsc190501.50161901': __('identifierSchemeCodeUnspsc190501.50161901'),
	'identifierSchemeCodeUnspsc190501.50161902': __('identifierSchemeCodeUnspsc190501.50161902'),
	'identifierSchemeCodeUnspsc190501.50170000': __('identifierSchemeCodeUnspsc190501.50170000'),
	'identifierSchemeCodeUnspsc190501.50171500': __('identifierSchemeCodeUnspsc190501.50171500'),
	'identifierSchemeCodeUnspsc190501.50171548': __('identifierSchemeCodeUnspsc190501.50171548'),
	'identifierSchemeCodeUnspsc190501.50171550': __('identifierSchemeCodeUnspsc190501.50171550'),
	'identifierSchemeCodeUnspsc190501.50171551': __('identifierSchemeCodeUnspsc190501.50171551'),
	'identifierSchemeCodeUnspsc190501.50171552': __('identifierSchemeCodeUnspsc190501.50171552'),
	'identifierSchemeCodeUnspsc190501.50171553': __('identifierSchemeCodeUnspsc190501.50171553'),
	'identifierSchemeCodeUnspsc190501.50171554': __('identifierSchemeCodeUnspsc190501.50171554'),
	'identifierSchemeCodeUnspsc190501.50171555': __('identifierSchemeCodeUnspsc190501.50171555'),
	'identifierSchemeCodeUnspsc190501.50171556': __('identifierSchemeCodeUnspsc190501.50171556'),
	'identifierSchemeCodeUnspsc190501.50171557': __('identifierSchemeCodeUnspsc190501.50171557'),
	'identifierSchemeCodeUnspsc190501.50171558': __('identifierSchemeCodeUnspsc190501.50171558'),
	'identifierSchemeCodeUnspsc190501.50171559': __('identifierSchemeCodeUnspsc190501.50171559'),
	'identifierSchemeCodeUnspsc190501.50171560': __('identifierSchemeCodeUnspsc190501.50171560'),
	'identifierSchemeCodeUnspsc190501.50171700': __('identifierSchemeCodeUnspsc190501.50171700'),
	'identifierSchemeCodeUnspsc190501.50171707': __('identifierSchemeCodeUnspsc190501.50171707'),
	'identifierSchemeCodeUnspsc190501.50171708': __('identifierSchemeCodeUnspsc190501.50171708'),
	'identifierSchemeCodeUnspsc190501.50171800': __('identifierSchemeCodeUnspsc190501.50171800'),
	'identifierSchemeCodeUnspsc190501.50171830': __('identifierSchemeCodeUnspsc190501.50171830'),
	'identifierSchemeCodeUnspsc190501.50171831': __('identifierSchemeCodeUnspsc190501.50171831'),
	'identifierSchemeCodeUnspsc190501.50171832': __('identifierSchemeCodeUnspsc190501.50171832'),
	'identifierSchemeCodeUnspsc190501.50171833': __('identifierSchemeCodeUnspsc190501.50171833'),
	'identifierSchemeCodeUnspsc190501.50171834': __('identifierSchemeCodeUnspsc190501.50171834'),
	'identifierSchemeCodeUnspsc190501.50171900': __('identifierSchemeCodeUnspsc190501.50171900'),
	'identifierSchemeCodeUnspsc190501.50171901': __('identifierSchemeCodeUnspsc190501.50171901'),
	'identifierSchemeCodeUnspsc190501.50171902': __('identifierSchemeCodeUnspsc190501.50171902'),
	'identifierSchemeCodeUnspsc190501.50171904': __('identifierSchemeCodeUnspsc190501.50171904'),
	'identifierSchemeCodeUnspsc190501.50172000': __('identifierSchemeCodeUnspsc190501.50172000'),
	'identifierSchemeCodeUnspsc190501.50172001': __('identifierSchemeCodeUnspsc190501.50172001'),
	'identifierSchemeCodeUnspsc190501.50172002': __('identifierSchemeCodeUnspsc190501.50172002'),
	'identifierSchemeCodeUnspsc190501.50172003': __('identifierSchemeCodeUnspsc190501.50172003'),
	'identifierSchemeCodeUnspsc190501.50172004': __('identifierSchemeCodeUnspsc190501.50172004'),
	'identifierSchemeCodeUnspsc190501.50172005': __('identifierSchemeCodeUnspsc190501.50172005'),
	'identifierSchemeCodeUnspsc190501.50172006': __('identifierSchemeCodeUnspsc190501.50172006'),
	'identifierSchemeCodeUnspsc190501.50180000': __('identifierSchemeCodeUnspsc190501.50180000'),
	'identifierSchemeCodeUnspsc190501.50181700': __('identifierSchemeCodeUnspsc190501.50181700'),
	'identifierSchemeCodeUnspsc190501.50181708': __('identifierSchemeCodeUnspsc190501.50181708'),
	'identifierSchemeCodeUnspsc190501.50181709': __('identifierSchemeCodeUnspsc190501.50181709'),
	'identifierSchemeCodeUnspsc190501.50181710': __('identifierSchemeCodeUnspsc190501.50181710'),
	'identifierSchemeCodeUnspsc190501.50181711': __('identifierSchemeCodeUnspsc190501.50181711'),
	'identifierSchemeCodeUnspsc190501.50181712': __('identifierSchemeCodeUnspsc190501.50181712'),
	'identifierSchemeCodeUnspsc190501.50181900': __('identifierSchemeCodeUnspsc190501.50181900'),
	'identifierSchemeCodeUnspsc190501.50181901': __('identifierSchemeCodeUnspsc190501.50181901'),
	'identifierSchemeCodeUnspsc190501.50181902': __('identifierSchemeCodeUnspsc190501.50181902'),
	'identifierSchemeCodeUnspsc190501.50181903': __('identifierSchemeCodeUnspsc190501.50181903'),
	'identifierSchemeCodeUnspsc190501.50181904': __('identifierSchemeCodeUnspsc190501.50181904'),
	'identifierSchemeCodeUnspsc190501.50181905': __('identifierSchemeCodeUnspsc190501.50181905'),
	'identifierSchemeCodeUnspsc190501.50181906': __('identifierSchemeCodeUnspsc190501.50181906'),
	'identifierSchemeCodeUnspsc190501.50181907': __('identifierSchemeCodeUnspsc190501.50181907'),
	'identifierSchemeCodeUnspsc190501.50181908': __('identifierSchemeCodeUnspsc190501.50181908'),
	'identifierSchemeCodeUnspsc190501.50181909': __('identifierSchemeCodeUnspsc190501.50181909'),
	'identifierSchemeCodeUnspsc190501.50182000': __('identifierSchemeCodeUnspsc190501.50182000'),
	'identifierSchemeCodeUnspsc190501.50182001': __('identifierSchemeCodeUnspsc190501.50182001'),
	'identifierSchemeCodeUnspsc190501.50182002': __('identifierSchemeCodeUnspsc190501.50182002'),
	'identifierSchemeCodeUnspsc190501.50182003': __('identifierSchemeCodeUnspsc190501.50182003'),
	'identifierSchemeCodeUnspsc190501.50182004': __('identifierSchemeCodeUnspsc190501.50182004'),
	'identifierSchemeCodeUnspsc190501.50182005': __('identifierSchemeCodeUnspsc190501.50182005'),
	'identifierSchemeCodeUnspsc190501.50190000': __('identifierSchemeCodeUnspsc190501.50190000'),
	'identifierSchemeCodeUnspsc190501.50191500': __('identifierSchemeCodeUnspsc190501.50191500'),
	'identifierSchemeCodeUnspsc190501.50191505': __('identifierSchemeCodeUnspsc190501.50191505'),
	'identifierSchemeCodeUnspsc190501.50191506': __('identifierSchemeCodeUnspsc190501.50191506'),
	'identifierSchemeCodeUnspsc190501.50191507': __('identifierSchemeCodeUnspsc190501.50191507'),
	'identifierSchemeCodeUnspsc190501.50192100': __('identifierSchemeCodeUnspsc190501.50192100'),
	'identifierSchemeCodeUnspsc190501.50192109': __('identifierSchemeCodeUnspsc190501.50192109'),
	'identifierSchemeCodeUnspsc190501.50192110': __('identifierSchemeCodeUnspsc190501.50192110'),
	'identifierSchemeCodeUnspsc190501.50192111': __('identifierSchemeCodeUnspsc190501.50192111'),
	'identifierSchemeCodeUnspsc190501.50192112': __('identifierSchemeCodeUnspsc190501.50192112'),
	'identifierSchemeCodeUnspsc190501.50192113': __('identifierSchemeCodeUnspsc190501.50192113'),
	'identifierSchemeCodeUnspsc190501.50192300': __('identifierSchemeCodeUnspsc190501.50192300'),
	'identifierSchemeCodeUnspsc190501.50192301': __('identifierSchemeCodeUnspsc190501.50192301'),
	'identifierSchemeCodeUnspsc190501.50192302': __('identifierSchemeCodeUnspsc190501.50192302'),
	'identifierSchemeCodeUnspsc190501.50192303': __('identifierSchemeCodeUnspsc190501.50192303'),
	'identifierSchemeCodeUnspsc190501.50192304': __('identifierSchemeCodeUnspsc190501.50192304'),
	'identifierSchemeCodeUnspsc190501.50192400': __('identifierSchemeCodeUnspsc190501.50192400'),
	'identifierSchemeCodeUnspsc190501.50192401': __('identifierSchemeCodeUnspsc190501.50192401'),
	'identifierSchemeCodeUnspsc190501.50192402': __('identifierSchemeCodeUnspsc190501.50192402'),
	'identifierSchemeCodeUnspsc190501.50192403': __('identifierSchemeCodeUnspsc190501.50192403'),
	'identifierSchemeCodeUnspsc190501.50192404': __('identifierSchemeCodeUnspsc190501.50192404'),
	'identifierSchemeCodeUnspsc190501.50192405': __('identifierSchemeCodeUnspsc190501.50192405'),
	'identifierSchemeCodeUnspsc190501.50192406': __('identifierSchemeCodeUnspsc190501.50192406'),
	'identifierSchemeCodeUnspsc190501.50192500': __('identifierSchemeCodeUnspsc190501.50192500'),
	'identifierSchemeCodeUnspsc190501.50192501': __('identifierSchemeCodeUnspsc190501.50192501'),
	'identifierSchemeCodeUnspsc190501.50192502': __('identifierSchemeCodeUnspsc190501.50192502'),
	'identifierSchemeCodeUnspsc190501.50192503': __('identifierSchemeCodeUnspsc190501.50192503'),
	'identifierSchemeCodeUnspsc190501.50192504': __('identifierSchemeCodeUnspsc190501.50192504'),
	'identifierSchemeCodeUnspsc190501.50192600': __('identifierSchemeCodeUnspsc190501.50192600'),
	'identifierSchemeCodeUnspsc190501.50192601': __('identifierSchemeCodeUnspsc190501.50192601'),
	'identifierSchemeCodeUnspsc190501.50192602': __('identifierSchemeCodeUnspsc190501.50192602'),
	'identifierSchemeCodeUnspsc190501.50192603': __('identifierSchemeCodeUnspsc190501.50192603'),
	'identifierSchemeCodeUnspsc190501.50192700': __('identifierSchemeCodeUnspsc190501.50192700'),
	'identifierSchemeCodeUnspsc190501.50192701': __('identifierSchemeCodeUnspsc190501.50192701'),
	'identifierSchemeCodeUnspsc190501.50192702': __('identifierSchemeCodeUnspsc190501.50192702'),
	'identifierSchemeCodeUnspsc190501.50192703': __('identifierSchemeCodeUnspsc190501.50192703'),
	'identifierSchemeCodeUnspsc190501.50192800': __('identifierSchemeCodeUnspsc190501.50192800'),
	'identifierSchemeCodeUnspsc190501.50192801': __('identifierSchemeCodeUnspsc190501.50192801'),
	'identifierSchemeCodeUnspsc190501.50192802': __('identifierSchemeCodeUnspsc190501.50192802'),
	'identifierSchemeCodeUnspsc190501.50192803': __('identifierSchemeCodeUnspsc190501.50192803'),
	'identifierSchemeCodeUnspsc190501.50192900': __('identifierSchemeCodeUnspsc190501.50192900'),
	'identifierSchemeCodeUnspsc190501.50192901': __('identifierSchemeCodeUnspsc190501.50192901'),
	'identifierSchemeCodeUnspsc190501.50192902': __('identifierSchemeCodeUnspsc190501.50192902'),
	'identifierSchemeCodeUnspsc190501.50193000': __('identifierSchemeCodeUnspsc190501.50193000'),
	'identifierSchemeCodeUnspsc190501.50193001': __('identifierSchemeCodeUnspsc190501.50193001'),
	'identifierSchemeCodeUnspsc190501.50193002': __('identifierSchemeCodeUnspsc190501.50193002'),
	'identifierSchemeCodeUnspsc190501.50193100': __('identifierSchemeCodeUnspsc190501.50193100'),
	'identifierSchemeCodeUnspsc190501.50193101': __('identifierSchemeCodeUnspsc190501.50193101'),
	'identifierSchemeCodeUnspsc190501.50193102': __('identifierSchemeCodeUnspsc190501.50193102'),
	'identifierSchemeCodeUnspsc190501.50193103': __('identifierSchemeCodeUnspsc190501.50193103'),
	'identifierSchemeCodeUnspsc190501.50193104': __('identifierSchemeCodeUnspsc190501.50193104'),
	'identifierSchemeCodeUnspsc190501.50193105': __('identifierSchemeCodeUnspsc190501.50193105'),
	'identifierSchemeCodeUnspsc190501.50193106': __('identifierSchemeCodeUnspsc190501.50193106'),
	'identifierSchemeCodeUnspsc190501.50193107': __('identifierSchemeCodeUnspsc190501.50193107'),
	'identifierSchemeCodeUnspsc190501.50193108': __('identifierSchemeCodeUnspsc190501.50193108'),
	'identifierSchemeCodeUnspsc190501.50193200': __('identifierSchemeCodeUnspsc190501.50193200'),
	'identifierSchemeCodeUnspsc190501.50193201': __('identifierSchemeCodeUnspsc190501.50193201'),
	'identifierSchemeCodeUnspsc190501.50193202': __('identifierSchemeCodeUnspsc190501.50193202'),
	'identifierSchemeCodeUnspsc190501.50193203': __('identifierSchemeCodeUnspsc190501.50193203'),
	'identifierSchemeCodeUnspsc190501.50193300': __('identifierSchemeCodeUnspsc190501.50193300'),
	'identifierSchemeCodeUnspsc190501.50193301': __('identifierSchemeCodeUnspsc190501.50193301'),
	'identifierSchemeCodeUnspsc190501.50193302': __('identifierSchemeCodeUnspsc190501.50193302'),
	'identifierSchemeCodeUnspsc190501.50200000': __('identifierSchemeCodeUnspsc190501.50200000'),
	'identifierSchemeCodeUnspsc190501.50201700': __('identifierSchemeCodeUnspsc190501.50201700'),
	'identifierSchemeCodeUnspsc190501.50201706': __('identifierSchemeCodeUnspsc190501.50201706'),
	'identifierSchemeCodeUnspsc190501.50201707': __('identifierSchemeCodeUnspsc190501.50201707'),
	'identifierSchemeCodeUnspsc190501.50201708': __('identifierSchemeCodeUnspsc190501.50201708'),
	'identifierSchemeCodeUnspsc190501.50201709': __('identifierSchemeCodeUnspsc190501.50201709'),
	'identifierSchemeCodeUnspsc190501.50201710': __('identifierSchemeCodeUnspsc190501.50201710'),
	'identifierSchemeCodeUnspsc190501.50201711': __('identifierSchemeCodeUnspsc190501.50201711'),
	'identifierSchemeCodeUnspsc190501.50201712': __('identifierSchemeCodeUnspsc190501.50201712'),
	'identifierSchemeCodeUnspsc190501.50201713': __('identifierSchemeCodeUnspsc190501.50201713'),
	'identifierSchemeCodeUnspsc190501.50201714': __('identifierSchemeCodeUnspsc190501.50201714'),
	'identifierSchemeCodeUnspsc190501.50201715': __('identifierSchemeCodeUnspsc190501.50201715'),
	'identifierSchemeCodeUnspsc190501.50201716': __('identifierSchemeCodeUnspsc190501.50201716'),
	'identifierSchemeCodeUnspsc190501.50201717': __('identifierSchemeCodeUnspsc190501.50201717'),
	'identifierSchemeCodeUnspsc190501.50202200': __('identifierSchemeCodeUnspsc190501.50202200'),
	'identifierSchemeCodeUnspsc190501.50202201': __('identifierSchemeCodeUnspsc190501.50202201'),
	'identifierSchemeCodeUnspsc190501.50202202': __('identifierSchemeCodeUnspsc190501.50202202'),
	'identifierSchemeCodeUnspsc190501.50202203': __('identifierSchemeCodeUnspsc190501.50202203'),
	'identifierSchemeCodeUnspsc190501.50202204': __('identifierSchemeCodeUnspsc190501.50202204'),
	'identifierSchemeCodeUnspsc190501.50202205': __('identifierSchemeCodeUnspsc190501.50202205'),
	'identifierSchemeCodeUnspsc190501.50202206': __('identifierSchemeCodeUnspsc190501.50202206'),
	'identifierSchemeCodeUnspsc190501.50202207': __('identifierSchemeCodeUnspsc190501.50202207'),
	'identifierSchemeCodeUnspsc190501.50202208': __('identifierSchemeCodeUnspsc190501.50202208'),
	'identifierSchemeCodeUnspsc190501.50202209': __('identifierSchemeCodeUnspsc190501.50202209'),
	'identifierSchemeCodeUnspsc190501.50202210': __('identifierSchemeCodeUnspsc190501.50202210'),
	'identifierSchemeCodeUnspsc190501.50202300': __('identifierSchemeCodeUnspsc190501.50202300'),
	'identifierSchemeCodeUnspsc190501.50202301': __('identifierSchemeCodeUnspsc190501.50202301'),
	'identifierSchemeCodeUnspsc190501.50202302': __('identifierSchemeCodeUnspsc190501.50202302'),
	'identifierSchemeCodeUnspsc190501.50202303': __('identifierSchemeCodeUnspsc190501.50202303'),
	'identifierSchemeCodeUnspsc190501.50202304': __('identifierSchemeCodeUnspsc190501.50202304'),
	'identifierSchemeCodeUnspsc190501.50202305': __('identifierSchemeCodeUnspsc190501.50202305'),
	'identifierSchemeCodeUnspsc190501.50202306': __('identifierSchemeCodeUnspsc190501.50202306'),
	'identifierSchemeCodeUnspsc190501.50202307': __('identifierSchemeCodeUnspsc190501.50202307'),
	'identifierSchemeCodeUnspsc190501.50202308': __('identifierSchemeCodeUnspsc190501.50202308'),
	'identifierSchemeCodeUnspsc190501.50202309': __('identifierSchemeCodeUnspsc190501.50202309'),
	'identifierSchemeCodeUnspsc190501.50202310': __('identifierSchemeCodeUnspsc190501.50202310'),
	'identifierSchemeCodeUnspsc190501.50202311': __('identifierSchemeCodeUnspsc190501.50202311'),
	'identifierSchemeCodeUnspsc190501.50202400': __('identifierSchemeCodeUnspsc190501.50202400'),
	'identifierSchemeCodeUnspsc190501.50202401': __('identifierSchemeCodeUnspsc190501.50202401'),
	'identifierSchemeCodeUnspsc190501.50202403': __('identifierSchemeCodeUnspsc190501.50202403'),
	'identifierSchemeCodeUnspsc190501.50202404': __('identifierSchemeCodeUnspsc190501.50202404'),
	'identifierSchemeCodeUnspsc190501.50202405': __('identifierSchemeCodeUnspsc190501.50202405'),
	'identifierSchemeCodeUnspsc190501.50202406': __('identifierSchemeCodeUnspsc190501.50202406'),
	'identifierSchemeCodeUnspsc190501.50202407': __('identifierSchemeCodeUnspsc190501.50202407'),
	'identifierSchemeCodeUnspsc190501.50202408': __('identifierSchemeCodeUnspsc190501.50202408'),
	'identifierSchemeCodeUnspsc190501.50202409': __('identifierSchemeCodeUnspsc190501.50202409'),
	'identifierSchemeCodeUnspsc190501.50202410': __('identifierSchemeCodeUnspsc190501.50202410'),
	'identifierSchemeCodeUnspsc190501.50202411': __('identifierSchemeCodeUnspsc190501.50202411'),
	'identifierSchemeCodeUnspsc190501.50202412': __('identifierSchemeCodeUnspsc190501.50202412'),
	'identifierSchemeCodeUnspsc190501.50202413': __('identifierSchemeCodeUnspsc190501.50202413'),
	'identifierSchemeCodeUnspsc190501.50202414': __('identifierSchemeCodeUnspsc190501.50202414'),
	'identifierSchemeCodeUnspsc190501.50202415': __('identifierSchemeCodeUnspsc190501.50202415'),
	'identifierSchemeCodeUnspsc190501.50202416': __('identifierSchemeCodeUnspsc190501.50202416'),
	'identifierSchemeCodeUnspsc190501.50202418': __('identifierSchemeCodeUnspsc190501.50202418'),
	'identifierSchemeCodeUnspsc190501.50202419': __('identifierSchemeCodeUnspsc190501.50202419'),
	'identifierSchemeCodeUnspsc190501.50202420': __('identifierSchemeCodeUnspsc190501.50202420'),
	'identifierSchemeCodeUnspsc190501.50202421': __('identifierSchemeCodeUnspsc190501.50202421'),
	'identifierSchemeCodeUnspsc190501.50202422': __('identifierSchemeCodeUnspsc190501.50202422'),
	'identifierSchemeCodeUnspsc190501.50202423': __('identifierSchemeCodeUnspsc190501.50202423'),
	'identifierSchemeCodeUnspsc190501.50202424': __('identifierSchemeCodeUnspsc190501.50202424'),
	'identifierSchemeCodeUnspsc190501.50202425': __('identifierSchemeCodeUnspsc190501.50202425'),
	'identifierSchemeCodeUnspsc190501.50202426': __('identifierSchemeCodeUnspsc190501.50202426'),
	'identifierSchemeCodeUnspsc190501.50202427': __('identifierSchemeCodeUnspsc190501.50202427'),
	'identifierSchemeCodeUnspsc190501.50202428': __('identifierSchemeCodeUnspsc190501.50202428'),
	'identifierSchemeCodeUnspsc190501.50202429': __('identifierSchemeCodeUnspsc190501.50202429'),
	'identifierSchemeCodeUnspsc190501.50202430': __('identifierSchemeCodeUnspsc190501.50202430'),
	'identifierSchemeCodeUnspsc190501.50202500': __('identifierSchemeCodeUnspsc190501.50202500'),
	'identifierSchemeCodeUnspsc190501.50202501': __('identifierSchemeCodeUnspsc190501.50202501'),
	'identifierSchemeCodeUnspsc190501.50202502': __('identifierSchemeCodeUnspsc190501.50202502'),
	'identifierSchemeCodeUnspsc190501.50202503': __('identifierSchemeCodeUnspsc190501.50202503'),
	'identifierSchemeCodeUnspsc190501.50202504': __('identifierSchemeCodeUnspsc190501.50202504'),
	'identifierSchemeCodeUnspsc190501.50202505': __('identifierSchemeCodeUnspsc190501.50202505'),
	'identifierSchemeCodeUnspsc190501.50202506': __('identifierSchemeCodeUnspsc190501.50202506'),
	'identifierSchemeCodeUnspsc190501.50202507': __('identifierSchemeCodeUnspsc190501.50202507'),
	'identifierSchemeCodeUnspsc190501.50202508': __('identifierSchemeCodeUnspsc190501.50202508'),
	'identifierSchemeCodeUnspsc190501.50202509': __('identifierSchemeCodeUnspsc190501.50202509'),
	'identifierSchemeCodeUnspsc190501.50202510': __('identifierSchemeCodeUnspsc190501.50202510'),
	'identifierSchemeCodeUnspsc190501.50202511': __('identifierSchemeCodeUnspsc190501.50202511'),
	'identifierSchemeCodeUnspsc190501.50202512': __('identifierSchemeCodeUnspsc190501.50202512'),
	'identifierSchemeCodeUnspsc190501.50202513': __('identifierSchemeCodeUnspsc190501.50202513'),
	'identifierSchemeCodeUnspsc190501.50202600': __('identifierSchemeCodeUnspsc190501.50202600'),
	'identifierSchemeCodeUnspsc190501.50202601': __('identifierSchemeCodeUnspsc190501.50202601'),
	'identifierSchemeCodeUnspsc190501.50202602': __('identifierSchemeCodeUnspsc190501.50202602'),
	'identifierSchemeCodeUnspsc190501.50202700': __('identifierSchemeCodeUnspsc190501.50202700'),
	'identifierSchemeCodeUnspsc190501.50202701': __('identifierSchemeCodeUnspsc190501.50202701'),
	'identifierSchemeCodeUnspsc190501.50202702': __('identifierSchemeCodeUnspsc190501.50202702'),
	'identifierSchemeCodeUnspsc190501.50202703': __('identifierSchemeCodeUnspsc190501.50202703'),
	'identifierSchemeCodeUnspsc190501.50202704': __('identifierSchemeCodeUnspsc190501.50202704'),
	'identifierSchemeCodeUnspsc190501.50202705': __('identifierSchemeCodeUnspsc190501.50202705'),
	'identifierSchemeCodeUnspsc190501.50202706': __('identifierSchemeCodeUnspsc190501.50202706'),
	'identifierSchemeCodeUnspsc190501.50202800': __('identifierSchemeCodeUnspsc190501.50202800'),
	'identifierSchemeCodeUnspsc190501.50202801': __('identifierSchemeCodeUnspsc190501.50202801'),
	'identifierSchemeCodeUnspsc190501.50202802': __('identifierSchemeCodeUnspsc190501.50202802'),
	'identifierSchemeCodeUnspsc190501.50202803': __('identifierSchemeCodeUnspsc190501.50202803'),
	'identifierSchemeCodeUnspsc190501.50202804': __('identifierSchemeCodeUnspsc190501.50202804'),
	'identifierSchemeCodeUnspsc190501.50202805': __('identifierSchemeCodeUnspsc190501.50202805'),
	'identifierSchemeCodeUnspsc190501.50202806': __('identifierSchemeCodeUnspsc190501.50202806'),
	'identifierSchemeCodeUnspsc190501.50202807': __('identifierSchemeCodeUnspsc190501.50202807'),
	'identifierSchemeCodeUnspsc190501.50203000': __('identifierSchemeCodeUnspsc190501.50203000'),
	'identifierSchemeCodeUnspsc190501.50203001': __('identifierSchemeCodeUnspsc190501.50203001'),
	'identifierSchemeCodeUnspsc190501.50203002': __('identifierSchemeCodeUnspsc190501.50203002'),
	'identifierSchemeCodeUnspsc190501.50203003': __('identifierSchemeCodeUnspsc190501.50203003'),
	'identifierSchemeCodeUnspsc190501.50203100': __('identifierSchemeCodeUnspsc190501.50203100'),
	'identifierSchemeCodeUnspsc190501.50203101': __('identifierSchemeCodeUnspsc190501.50203101'),
	'identifierSchemeCodeUnspsc190501.50203200': __('identifierSchemeCodeUnspsc190501.50203200'),
	'identifierSchemeCodeUnspsc190501.50203201': __('identifierSchemeCodeUnspsc190501.50203201'),
	'identifierSchemeCodeUnspsc190501.50203202': __('identifierSchemeCodeUnspsc190501.50203202'),
	'identifierSchemeCodeUnspsc190501.50203203': __('identifierSchemeCodeUnspsc190501.50203203'),
	'identifierSchemeCodeUnspsc190501.50203204': __('identifierSchemeCodeUnspsc190501.50203204'),
	'identifierSchemeCodeUnspsc190501.50203205': __('identifierSchemeCodeUnspsc190501.50203205'),
	'identifierSchemeCodeUnspsc190501.50210000': __('identifierSchemeCodeUnspsc190501.50210000'),
	'identifierSchemeCodeUnspsc190501.50211500': __('identifierSchemeCodeUnspsc190501.50211500'),
	'identifierSchemeCodeUnspsc190501.50211502': __('identifierSchemeCodeUnspsc190501.50211502'),
	'identifierSchemeCodeUnspsc190501.50211503': __('identifierSchemeCodeUnspsc190501.50211503'),
	'identifierSchemeCodeUnspsc190501.50211504': __('identifierSchemeCodeUnspsc190501.50211504'),
	'identifierSchemeCodeUnspsc190501.50211505': __('identifierSchemeCodeUnspsc190501.50211505'),
	'identifierSchemeCodeUnspsc190501.50211506': __('identifierSchemeCodeUnspsc190501.50211506'),
	'identifierSchemeCodeUnspsc190501.50211507': __('identifierSchemeCodeUnspsc190501.50211507'),
	'identifierSchemeCodeUnspsc190501.50211600': __('identifierSchemeCodeUnspsc190501.50211600'),
	'identifierSchemeCodeUnspsc190501.50211607': __('identifierSchemeCodeUnspsc190501.50211607'),
	'identifierSchemeCodeUnspsc190501.50211608': __('identifierSchemeCodeUnspsc190501.50211608'),
	'identifierSchemeCodeUnspsc190501.50211609': __('identifierSchemeCodeUnspsc190501.50211609'),
	'identifierSchemeCodeUnspsc190501.50211610': __('identifierSchemeCodeUnspsc190501.50211610'),
	'identifierSchemeCodeUnspsc190501.50211611': __('identifierSchemeCodeUnspsc190501.50211611'),
	'identifierSchemeCodeUnspsc190501.50211612': __('identifierSchemeCodeUnspsc190501.50211612'),
	'identifierSchemeCodeUnspsc190501.50220000': __('identifierSchemeCodeUnspsc190501.50220000'),
	'identifierSchemeCodeUnspsc190501.50221000': __('identifierSchemeCodeUnspsc190501.50221000'),
	'identifierSchemeCodeUnspsc190501.50221001': __('identifierSchemeCodeUnspsc190501.50221001'),
	'identifierSchemeCodeUnspsc190501.50221002': __('identifierSchemeCodeUnspsc190501.50221002'),
	'identifierSchemeCodeUnspsc190501.50221100': __('identifierSchemeCodeUnspsc190501.50221100'),
	'identifierSchemeCodeUnspsc190501.50221101': __('identifierSchemeCodeUnspsc190501.50221101'),
	'identifierSchemeCodeUnspsc190501.50221102': __('identifierSchemeCodeUnspsc190501.50221102'),
	'identifierSchemeCodeUnspsc190501.50221103': __('identifierSchemeCodeUnspsc190501.50221103'),
	'identifierSchemeCodeUnspsc190501.50221104': __('identifierSchemeCodeUnspsc190501.50221104'),
	'identifierSchemeCodeUnspsc190501.50221105': __('identifierSchemeCodeUnspsc190501.50221105'),
	'identifierSchemeCodeUnspsc190501.50221106': __('identifierSchemeCodeUnspsc190501.50221106'),
	'identifierSchemeCodeUnspsc190501.50221107': __('identifierSchemeCodeUnspsc190501.50221107'),
	'identifierSchemeCodeUnspsc190501.50221108': __('identifierSchemeCodeUnspsc190501.50221108'),
	'identifierSchemeCodeUnspsc190501.50221109': __('identifierSchemeCodeUnspsc190501.50221109'),
	'identifierSchemeCodeUnspsc190501.50221110': __('identifierSchemeCodeUnspsc190501.50221110'),
	'identifierSchemeCodeUnspsc190501.50221200': __('identifierSchemeCodeUnspsc190501.50221200'),
	'identifierSchemeCodeUnspsc190501.50221201': __('identifierSchemeCodeUnspsc190501.50221201'),
	'identifierSchemeCodeUnspsc190501.50221202': __('identifierSchemeCodeUnspsc190501.50221202'),
	'identifierSchemeCodeUnspsc190501.50221300': __('identifierSchemeCodeUnspsc190501.50221300'),
	'identifierSchemeCodeUnspsc190501.50221301': __('identifierSchemeCodeUnspsc190501.50221301'),
	'identifierSchemeCodeUnspsc190501.50221302': __('identifierSchemeCodeUnspsc190501.50221302'),
	'identifierSchemeCodeUnspsc190501.50221303': __('identifierSchemeCodeUnspsc190501.50221303'),
	'identifierSchemeCodeUnspsc190501.50221304': __('identifierSchemeCodeUnspsc190501.50221304'),
	'identifierSchemeCodeUnspsc190501.50300000': __('identifierSchemeCodeUnspsc190501.50300000'),
	'identifierSchemeCodeUnspsc190501.50301500': __('identifierSchemeCodeUnspsc190501.50301500'),
	'identifierSchemeCodeUnspsc190501.50301501': __('identifierSchemeCodeUnspsc190501.50301501'),
	'identifierSchemeCodeUnspsc190501.50301502': __('identifierSchemeCodeUnspsc190501.50301502'),
	'identifierSchemeCodeUnspsc190501.50301503': __('identifierSchemeCodeUnspsc190501.50301503'),
	'identifierSchemeCodeUnspsc190501.50301504': __('identifierSchemeCodeUnspsc190501.50301504'),
	'identifierSchemeCodeUnspsc190501.50301505': __('identifierSchemeCodeUnspsc190501.50301505'),
	'identifierSchemeCodeUnspsc190501.50301506': __('identifierSchemeCodeUnspsc190501.50301506'),
	'identifierSchemeCodeUnspsc190501.50301507': __('identifierSchemeCodeUnspsc190501.50301507'),
	'identifierSchemeCodeUnspsc190501.50301508': __('identifierSchemeCodeUnspsc190501.50301508'),
	'identifierSchemeCodeUnspsc190501.50301509': __('identifierSchemeCodeUnspsc190501.50301509'),
	'identifierSchemeCodeUnspsc190501.50301510': __('identifierSchemeCodeUnspsc190501.50301510'),
	'identifierSchemeCodeUnspsc190501.50301511': __('identifierSchemeCodeUnspsc190501.50301511'),
	'identifierSchemeCodeUnspsc190501.50301512': __('identifierSchemeCodeUnspsc190501.50301512'),
	'identifierSchemeCodeUnspsc190501.50301513': __('identifierSchemeCodeUnspsc190501.50301513'),
	'identifierSchemeCodeUnspsc190501.50301514': __('identifierSchemeCodeUnspsc190501.50301514'),
	'identifierSchemeCodeUnspsc190501.50301515': __('identifierSchemeCodeUnspsc190501.50301515'),
	'identifierSchemeCodeUnspsc190501.50301516': __('identifierSchemeCodeUnspsc190501.50301516'),
	'identifierSchemeCodeUnspsc190501.50301517': __('identifierSchemeCodeUnspsc190501.50301517'),
	'identifierSchemeCodeUnspsc190501.50301518': __('identifierSchemeCodeUnspsc190501.50301518'),
	'identifierSchemeCodeUnspsc190501.50301519': __('identifierSchemeCodeUnspsc190501.50301519'),
	'identifierSchemeCodeUnspsc190501.50301520': __('identifierSchemeCodeUnspsc190501.50301520'),
	'identifierSchemeCodeUnspsc190501.50301521': __('identifierSchemeCodeUnspsc190501.50301521'),
	'identifierSchemeCodeUnspsc190501.50301522': __('identifierSchemeCodeUnspsc190501.50301522'),
	'identifierSchemeCodeUnspsc190501.50301523': __('identifierSchemeCodeUnspsc190501.50301523'),
	'identifierSchemeCodeUnspsc190501.50301524': __('identifierSchemeCodeUnspsc190501.50301524'),
	'identifierSchemeCodeUnspsc190501.50301525': __('identifierSchemeCodeUnspsc190501.50301525'),
	'identifierSchemeCodeUnspsc190501.50301526': __('identifierSchemeCodeUnspsc190501.50301526'),
	'identifierSchemeCodeUnspsc190501.50301527': __('identifierSchemeCodeUnspsc190501.50301527'),
	'identifierSchemeCodeUnspsc190501.50301528': __('identifierSchemeCodeUnspsc190501.50301528'),
	'identifierSchemeCodeUnspsc190501.50301529': __('identifierSchemeCodeUnspsc190501.50301529'),
	'identifierSchemeCodeUnspsc190501.50301530': __('identifierSchemeCodeUnspsc190501.50301530'),
	'identifierSchemeCodeUnspsc190501.50301531': __('identifierSchemeCodeUnspsc190501.50301531'),
	'identifierSchemeCodeUnspsc190501.50301532': __('identifierSchemeCodeUnspsc190501.50301532'),
	'identifierSchemeCodeUnspsc190501.50301533': __('identifierSchemeCodeUnspsc190501.50301533'),
	'identifierSchemeCodeUnspsc190501.50301534': __('identifierSchemeCodeUnspsc190501.50301534'),
	'identifierSchemeCodeUnspsc190501.50301535': __('identifierSchemeCodeUnspsc190501.50301535'),
	'identifierSchemeCodeUnspsc190501.50301536': __('identifierSchemeCodeUnspsc190501.50301536'),
	'identifierSchemeCodeUnspsc190501.50301537': __('identifierSchemeCodeUnspsc190501.50301537'),
	'identifierSchemeCodeUnspsc190501.50301538': __('identifierSchemeCodeUnspsc190501.50301538'),
	'identifierSchemeCodeUnspsc190501.50301539': __('identifierSchemeCodeUnspsc190501.50301539'),
	'identifierSchemeCodeUnspsc190501.50301540': __('identifierSchemeCodeUnspsc190501.50301540'),
	'identifierSchemeCodeUnspsc190501.50301541': __('identifierSchemeCodeUnspsc190501.50301541'),
	'identifierSchemeCodeUnspsc190501.50301542': __('identifierSchemeCodeUnspsc190501.50301542'),
	'identifierSchemeCodeUnspsc190501.50301543': __('identifierSchemeCodeUnspsc190501.50301543'),
	'identifierSchemeCodeUnspsc190501.50301544': __('identifierSchemeCodeUnspsc190501.50301544'),
	'identifierSchemeCodeUnspsc190501.50301545': __('identifierSchemeCodeUnspsc190501.50301545'),
	'identifierSchemeCodeUnspsc190501.50301546': __('identifierSchemeCodeUnspsc190501.50301546'),
	'identifierSchemeCodeUnspsc190501.50301547': __('identifierSchemeCodeUnspsc190501.50301547'),
	'identifierSchemeCodeUnspsc190501.50301548': __('identifierSchemeCodeUnspsc190501.50301548'),
	'identifierSchemeCodeUnspsc190501.50301549': __('identifierSchemeCodeUnspsc190501.50301549'),
	'identifierSchemeCodeUnspsc190501.50301550': __('identifierSchemeCodeUnspsc190501.50301550'),
	'identifierSchemeCodeUnspsc190501.50301551': __('identifierSchemeCodeUnspsc190501.50301551'),
	'identifierSchemeCodeUnspsc190501.50301552': __('identifierSchemeCodeUnspsc190501.50301552'),
	'identifierSchemeCodeUnspsc190501.50301553': __('identifierSchemeCodeUnspsc190501.50301553'),
	'identifierSchemeCodeUnspsc190501.50301554': __('identifierSchemeCodeUnspsc190501.50301554'),
	'identifierSchemeCodeUnspsc190501.50301555': __('identifierSchemeCodeUnspsc190501.50301555'),
	'identifierSchemeCodeUnspsc190501.50301556': __('identifierSchemeCodeUnspsc190501.50301556'),
	'identifierSchemeCodeUnspsc190501.50301557': __('identifierSchemeCodeUnspsc190501.50301557'),
	'identifierSchemeCodeUnspsc190501.50301558': __('identifierSchemeCodeUnspsc190501.50301558'),
	'identifierSchemeCodeUnspsc190501.50301559': __('identifierSchemeCodeUnspsc190501.50301559'),
	'identifierSchemeCodeUnspsc190501.50301560': __('identifierSchemeCodeUnspsc190501.50301560'),
	'identifierSchemeCodeUnspsc190501.50301561': __('identifierSchemeCodeUnspsc190501.50301561'),
	'identifierSchemeCodeUnspsc190501.50301562': __('identifierSchemeCodeUnspsc190501.50301562'),
	'identifierSchemeCodeUnspsc190501.50301563': __('identifierSchemeCodeUnspsc190501.50301563'),
	'identifierSchemeCodeUnspsc190501.50301564': __('identifierSchemeCodeUnspsc190501.50301564'),
	'identifierSchemeCodeUnspsc190501.50301565': __('identifierSchemeCodeUnspsc190501.50301565'),
	'identifierSchemeCodeUnspsc190501.50301566': __('identifierSchemeCodeUnspsc190501.50301566'),
	'identifierSchemeCodeUnspsc190501.50301567': __('identifierSchemeCodeUnspsc190501.50301567'),
	'identifierSchemeCodeUnspsc190501.50301568': __('identifierSchemeCodeUnspsc190501.50301568'),
	'identifierSchemeCodeUnspsc190501.50301569': __('identifierSchemeCodeUnspsc190501.50301569'),
	'identifierSchemeCodeUnspsc190501.50301570': __('identifierSchemeCodeUnspsc190501.50301570'),
	'identifierSchemeCodeUnspsc190501.50301571': __('identifierSchemeCodeUnspsc190501.50301571'),
	'identifierSchemeCodeUnspsc190501.50301572': __('identifierSchemeCodeUnspsc190501.50301572'),
	'identifierSchemeCodeUnspsc190501.50301573': __('identifierSchemeCodeUnspsc190501.50301573'),
	'identifierSchemeCodeUnspsc190501.50301574': __('identifierSchemeCodeUnspsc190501.50301574'),
	'identifierSchemeCodeUnspsc190501.50301575': __('identifierSchemeCodeUnspsc190501.50301575'),
	'identifierSchemeCodeUnspsc190501.50301576': __('identifierSchemeCodeUnspsc190501.50301576'),
	'identifierSchemeCodeUnspsc190501.50301577': __('identifierSchemeCodeUnspsc190501.50301577'),
	'identifierSchemeCodeUnspsc190501.50301578': __('identifierSchemeCodeUnspsc190501.50301578'),
	'identifierSchemeCodeUnspsc190501.50301579': __('identifierSchemeCodeUnspsc190501.50301579'),
	'identifierSchemeCodeUnspsc190501.50301580': __('identifierSchemeCodeUnspsc190501.50301580'),
	'identifierSchemeCodeUnspsc190501.50301581': __('identifierSchemeCodeUnspsc190501.50301581'),
	'identifierSchemeCodeUnspsc190501.50301582': __('identifierSchemeCodeUnspsc190501.50301582'),
	'identifierSchemeCodeUnspsc190501.50301583': __('identifierSchemeCodeUnspsc190501.50301583'),
	'identifierSchemeCodeUnspsc190501.50301584': __('identifierSchemeCodeUnspsc190501.50301584'),
	'identifierSchemeCodeUnspsc190501.50301585': __('identifierSchemeCodeUnspsc190501.50301585'),
	'identifierSchemeCodeUnspsc190501.50301586': __('identifierSchemeCodeUnspsc190501.50301586'),
	'identifierSchemeCodeUnspsc190501.50301587': __('identifierSchemeCodeUnspsc190501.50301587'),
	'identifierSchemeCodeUnspsc190501.50301588': __('identifierSchemeCodeUnspsc190501.50301588'),
	'identifierSchemeCodeUnspsc190501.50301589': __('identifierSchemeCodeUnspsc190501.50301589'),
	'identifierSchemeCodeUnspsc190501.50301590': __('identifierSchemeCodeUnspsc190501.50301590'),
	'identifierSchemeCodeUnspsc190501.50301591': __('identifierSchemeCodeUnspsc190501.50301591'),
	'identifierSchemeCodeUnspsc190501.50301592': __('identifierSchemeCodeUnspsc190501.50301592'),
	'identifierSchemeCodeUnspsc190501.50301593': __('identifierSchemeCodeUnspsc190501.50301593'),
	'identifierSchemeCodeUnspsc190501.50301594': __('identifierSchemeCodeUnspsc190501.50301594'),
	'identifierSchemeCodeUnspsc190501.50301595': __('identifierSchemeCodeUnspsc190501.50301595'),
	'identifierSchemeCodeUnspsc190501.50301596': __('identifierSchemeCodeUnspsc190501.50301596'),
	'identifierSchemeCodeUnspsc190501.50301597': __('identifierSchemeCodeUnspsc190501.50301597'),
	'identifierSchemeCodeUnspsc190501.50301598': __('identifierSchemeCodeUnspsc190501.50301598'),
	'identifierSchemeCodeUnspsc190501.50301599': __('identifierSchemeCodeUnspsc190501.50301599'),
	'identifierSchemeCodeUnspsc190501.50301600': __('identifierSchemeCodeUnspsc190501.50301600'),
	'identifierSchemeCodeUnspsc190501.50301601': __('identifierSchemeCodeUnspsc190501.50301601'),
	'identifierSchemeCodeUnspsc190501.50301602': __('identifierSchemeCodeUnspsc190501.50301602'),
	'identifierSchemeCodeUnspsc190501.50301603': __('identifierSchemeCodeUnspsc190501.50301603'),
	'identifierSchemeCodeUnspsc190501.50301604': __('identifierSchemeCodeUnspsc190501.50301604'),
	'identifierSchemeCodeUnspsc190501.50301605': __('identifierSchemeCodeUnspsc190501.50301605'),
	'identifierSchemeCodeUnspsc190501.50301606': __('identifierSchemeCodeUnspsc190501.50301606'),
	'identifierSchemeCodeUnspsc190501.50301607': __('identifierSchemeCodeUnspsc190501.50301607'),
	'identifierSchemeCodeUnspsc190501.50301608': __('identifierSchemeCodeUnspsc190501.50301608'),
	'identifierSchemeCodeUnspsc190501.50301609': __('identifierSchemeCodeUnspsc190501.50301609'),
	'identifierSchemeCodeUnspsc190501.50301610': __('identifierSchemeCodeUnspsc190501.50301610'),
	'identifierSchemeCodeUnspsc190501.50301611': __('identifierSchemeCodeUnspsc190501.50301611'),
	'identifierSchemeCodeUnspsc190501.50301612': __('identifierSchemeCodeUnspsc190501.50301612'),
	'identifierSchemeCodeUnspsc190501.50301613': __('identifierSchemeCodeUnspsc190501.50301613'),
	'identifierSchemeCodeUnspsc190501.50301614': __('identifierSchemeCodeUnspsc190501.50301614'),
	'identifierSchemeCodeUnspsc190501.50301615': __('identifierSchemeCodeUnspsc190501.50301615'),
	'identifierSchemeCodeUnspsc190501.50301616': __('identifierSchemeCodeUnspsc190501.50301616'),
	'identifierSchemeCodeUnspsc190501.50301617': __('identifierSchemeCodeUnspsc190501.50301617'),
	'identifierSchemeCodeUnspsc190501.50301618': __('identifierSchemeCodeUnspsc190501.50301618'),
	'identifierSchemeCodeUnspsc190501.50301619': __('identifierSchemeCodeUnspsc190501.50301619'),
	'identifierSchemeCodeUnspsc190501.50301620': __('identifierSchemeCodeUnspsc190501.50301620'),
	'identifierSchemeCodeUnspsc190501.50301621': __('identifierSchemeCodeUnspsc190501.50301621'),
	'identifierSchemeCodeUnspsc190501.50301622': __('identifierSchemeCodeUnspsc190501.50301622'),
	'identifierSchemeCodeUnspsc190501.50301623': __('identifierSchemeCodeUnspsc190501.50301623'),
	'identifierSchemeCodeUnspsc190501.50301624': __('identifierSchemeCodeUnspsc190501.50301624'),
	'identifierSchemeCodeUnspsc190501.50301625': __('identifierSchemeCodeUnspsc190501.50301625'),
	'identifierSchemeCodeUnspsc190501.50301626': __('identifierSchemeCodeUnspsc190501.50301626'),
	'identifierSchemeCodeUnspsc190501.50301627': __('identifierSchemeCodeUnspsc190501.50301627'),
	'identifierSchemeCodeUnspsc190501.50301628': __('identifierSchemeCodeUnspsc190501.50301628'),
	'identifierSchemeCodeUnspsc190501.50301629': __('identifierSchemeCodeUnspsc190501.50301629'),
	'identifierSchemeCodeUnspsc190501.50301630': __('identifierSchemeCodeUnspsc190501.50301630'),
	'identifierSchemeCodeUnspsc190501.50301631': __('identifierSchemeCodeUnspsc190501.50301631'),
	'identifierSchemeCodeUnspsc190501.50301632': __('identifierSchemeCodeUnspsc190501.50301632'),
	'identifierSchemeCodeUnspsc190501.50301633': __('identifierSchemeCodeUnspsc190501.50301633'),
	'identifierSchemeCodeUnspsc190501.50301634': __('identifierSchemeCodeUnspsc190501.50301634'),
	'identifierSchemeCodeUnspsc190501.50301635': __('identifierSchemeCodeUnspsc190501.50301635'),
	'identifierSchemeCodeUnspsc190501.50301636': __('identifierSchemeCodeUnspsc190501.50301636'),
	'identifierSchemeCodeUnspsc190501.50301637': __('identifierSchemeCodeUnspsc190501.50301637'),
	'identifierSchemeCodeUnspsc190501.50301638': __('identifierSchemeCodeUnspsc190501.50301638'),
	'identifierSchemeCodeUnspsc190501.50301639': __('identifierSchemeCodeUnspsc190501.50301639'),
	'identifierSchemeCodeUnspsc190501.50301640': __('identifierSchemeCodeUnspsc190501.50301640'),
	'identifierSchemeCodeUnspsc190501.50301641': __('identifierSchemeCodeUnspsc190501.50301641'),
	'identifierSchemeCodeUnspsc190501.50301642': __('identifierSchemeCodeUnspsc190501.50301642'),
	'identifierSchemeCodeUnspsc190501.50301643': __('identifierSchemeCodeUnspsc190501.50301643'),
	'identifierSchemeCodeUnspsc190501.50301644': __('identifierSchemeCodeUnspsc190501.50301644'),
	'identifierSchemeCodeUnspsc190501.50301645': __('identifierSchemeCodeUnspsc190501.50301645'),
	'identifierSchemeCodeUnspsc190501.50301646': __('identifierSchemeCodeUnspsc190501.50301646'),
	'identifierSchemeCodeUnspsc190501.50301647': __('identifierSchemeCodeUnspsc190501.50301647'),
	'identifierSchemeCodeUnspsc190501.50301648': __('identifierSchemeCodeUnspsc190501.50301648'),
	'identifierSchemeCodeUnspsc190501.50301649': __('identifierSchemeCodeUnspsc190501.50301649'),
	'identifierSchemeCodeUnspsc190501.50301650': __('identifierSchemeCodeUnspsc190501.50301650'),
	'identifierSchemeCodeUnspsc190501.50301651': __('identifierSchemeCodeUnspsc190501.50301651'),
	'identifierSchemeCodeUnspsc190501.50301652': __('identifierSchemeCodeUnspsc190501.50301652'),
	'identifierSchemeCodeUnspsc190501.50301653': __('identifierSchemeCodeUnspsc190501.50301653'),
	'identifierSchemeCodeUnspsc190501.50301654': __('identifierSchemeCodeUnspsc190501.50301654'),
	'identifierSchemeCodeUnspsc190501.50301655': __('identifierSchemeCodeUnspsc190501.50301655'),
	'identifierSchemeCodeUnspsc190501.50301700': __('identifierSchemeCodeUnspsc190501.50301700'),
	'identifierSchemeCodeUnspsc190501.50301701': __('identifierSchemeCodeUnspsc190501.50301701'),
	'identifierSchemeCodeUnspsc190501.50301702': __('identifierSchemeCodeUnspsc190501.50301702'),
	'identifierSchemeCodeUnspsc190501.50301703': __('identifierSchemeCodeUnspsc190501.50301703'),
	'identifierSchemeCodeUnspsc190501.50301704': __('identifierSchemeCodeUnspsc190501.50301704'),
	'identifierSchemeCodeUnspsc190501.50301705': __('identifierSchemeCodeUnspsc190501.50301705'),
	'identifierSchemeCodeUnspsc190501.50301706': __('identifierSchemeCodeUnspsc190501.50301706'),
	'identifierSchemeCodeUnspsc190501.50301707': __('identifierSchemeCodeUnspsc190501.50301707'),
	'identifierSchemeCodeUnspsc190501.50301708': __('identifierSchemeCodeUnspsc190501.50301708'),
	'identifierSchemeCodeUnspsc190501.50301709': __('identifierSchemeCodeUnspsc190501.50301709'),
	'identifierSchemeCodeUnspsc190501.50301710': __('identifierSchemeCodeUnspsc190501.50301710'),
	'identifierSchemeCodeUnspsc190501.50301711': __('identifierSchemeCodeUnspsc190501.50301711'),
	'identifierSchemeCodeUnspsc190501.50301712': __('identifierSchemeCodeUnspsc190501.50301712'),
	'identifierSchemeCodeUnspsc190501.50301713': __('identifierSchemeCodeUnspsc190501.50301713'),
	'identifierSchemeCodeUnspsc190501.50301714': __('identifierSchemeCodeUnspsc190501.50301714'),
	'identifierSchemeCodeUnspsc190501.50301715': __('identifierSchemeCodeUnspsc190501.50301715'),
	'identifierSchemeCodeUnspsc190501.50301716': __('identifierSchemeCodeUnspsc190501.50301716'),
	'identifierSchemeCodeUnspsc190501.50301717': __('identifierSchemeCodeUnspsc190501.50301717'),
	'identifierSchemeCodeUnspsc190501.50301718': __('identifierSchemeCodeUnspsc190501.50301718'),
	'identifierSchemeCodeUnspsc190501.50301719': __('identifierSchemeCodeUnspsc190501.50301719'),
	'identifierSchemeCodeUnspsc190501.50301800': __('identifierSchemeCodeUnspsc190501.50301800'),
	'identifierSchemeCodeUnspsc190501.50301801': __('identifierSchemeCodeUnspsc190501.50301801'),
	'identifierSchemeCodeUnspsc190501.50301802': __('identifierSchemeCodeUnspsc190501.50301802'),
	'identifierSchemeCodeUnspsc190501.50301803': __('identifierSchemeCodeUnspsc190501.50301803'),
	'identifierSchemeCodeUnspsc190501.50301804': __('identifierSchemeCodeUnspsc190501.50301804'),
	'identifierSchemeCodeUnspsc190501.50301805': __('identifierSchemeCodeUnspsc190501.50301805'),
	'identifierSchemeCodeUnspsc190501.50301806': __('identifierSchemeCodeUnspsc190501.50301806'),
	'identifierSchemeCodeUnspsc190501.50301807': __('identifierSchemeCodeUnspsc190501.50301807'),
	'identifierSchemeCodeUnspsc190501.50301808': __('identifierSchemeCodeUnspsc190501.50301808'),
	'identifierSchemeCodeUnspsc190501.50301809': __('identifierSchemeCodeUnspsc190501.50301809'),
	'identifierSchemeCodeUnspsc190501.50301810': __('identifierSchemeCodeUnspsc190501.50301810'),
	'identifierSchemeCodeUnspsc190501.50301811': __('identifierSchemeCodeUnspsc190501.50301811'),
	'identifierSchemeCodeUnspsc190501.50301812': __('identifierSchemeCodeUnspsc190501.50301812'),
	'identifierSchemeCodeUnspsc190501.50301813': __('identifierSchemeCodeUnspsc190501.50301813'),
	'identifierSchemeCodeUnspsc190501.50301814': __('identifierSchemeCodeUnspsc190501.50301814'),
	'identifierSchemeCodeUnspsc190501.50301900': __('identifierSchemeCodeUnspsc190501.50301900'),
	'identifierSchemeCodeUnspsc190501.50301901': __('identifierSchemeCodeUnspsc190501.50301901'),
	'identifierSchemeCodeUnspsc190501.50301902': __('identifierSchemeCodeUnspsc190501.50301902'),
	'identifierSchemeCodeUnspsc190501.50301903': __('identifierSchemeCodeUnspsc190501.50301903'),
	'identifierSchemeCodeUnspsc190501.50302000': __('identifierSchemeCodeUnspsc190501.50302000'),
	'identifierSchemeCodeUnspsc190501.50302001': __('identifierSchemeCodeUnspsc190501.50302001'),
	'identifierSchemeCodeUnspsc190501.50302002': __('identifierSchemeCodeUnspsc190501.50302002'),
	'identifierSchemeCodeUnspsc190501.50302003': __('identifierSchemeCodeUnspsc190501.50302003'),
	'identifierSchemeCodeUnspsc190501.50302004': __('identifierSchemeCodeUnspsc190501.50302004'),
	'identifierSchemeCodeUnspsc190501.50302005': __('identifierSchemeCodeUnspsc190501.50302005'),
	'identifierSchemeCodeUnspsc190501.50302006': __('identifierSchemeCodeUnspsc190501.50302006'),
	'identifierSchemeCodeUnspsc190501.50302007': __('identifierSchemeCodeUnspsc190501.50302007'),
	'identifierSchemeCodeUnspsc190501.50302008': __('identifierSchemeCodeUnspsc190501.50302008'),
	'identifierSchemeCodeUnspsc190501.50302009': __('identifierSchemeCodeUnspsc190501.50302009'),
	'identifierSchemeCodeUnspsc190501.50302010': __('identifierSchemeCodeUnspsc190501.50302010'),
	'identifierSchemeCodeUnspsc190501.50302011': __('identifierSchemeCodeUnspsc190501.50302011'),
	'identifierSchemeCodeUnspsc190501.50302012': __('identifierSchemeCodeUnspsc190501.50302012'),
	'identifierSchemeCodeUnspsc190501.50302013': __('identifierSchemeCodeUnspsc190501.50302013'),
	'identifierSchemeCodeUnspsc190501.50302014': __('identifierSchemeCodeUnspsc190501.50302014'),
	'identifierSchemeCodeUnspsc190501.50302015': __('identifierSchemeCodeUnspsc190501.50302015'),
	'identifierSchemeCodeUnspsc190501.50302100': __('identifierSchemeCodeUnspsc190501.50302100'),
	'identifierSchemeCodeUnspsc190501.50302101': __('identifierSchemeCodeUnspsc190501.50302101'),
	'identifierSchemeCodeUnspsc190501.50302102': __('identifierSchemeCodeUnspsc190501.50302102'),
	'identifierSchemeCodeUnspsc190501.50302103': __('identifierSchemeCodeUnspsc190501.50302103'),
	'identifierSchemeCodeUnspsc190501.50302104': __('identifierSchemeCodeUnspsc190501.50302104'),
	'identifierSchemeCodeUnspsc190501.50302200': __('identifierSchemeCodeUnspsc190501.50302200'),
	'identifierSchemeCodeUnspsc190501.50302201': __('identifierSchemeCodeUnspsc190501.50302201'),
	'identifierSchemeCodeUnspsc190501.50302202': __('identifierSchemeCodeUnspsc190501.50302202'),
	'identifierSchemeCodeUnspsc190501.50302203': __('identifierSchemeCodeUnspsc190501.50302203'),
	'identifierSchemeCodeUnspsc190501.50302204': __('identifierSchemeCodeUnspsc190501.50302204'),
	'identifierSchemeCodeUnspsc190501.50302205': __('identifierSchemeCodeUnspsc190501.50302205'),
	'identifierSchemeCodeUnspsc190501.50302206': __('identifierSchemeCodeUnspsc190501.50302206'),
	'identifierSchemeCodeUnspsc190501.50302207': __('identifierSchemeCodeUnspsc190501.50302207'),
	'identifierSchemeCodeUnspsc190501.50302208': __('identifierSchemeCodeUnspsc190501.50302208'),
	'identifierSchemeCodeUnspsc190501.50302209': __('identifierSchemeCodeUnspsc190501.50302209'),
	'identifierSchemeCodeUnspsc190501.50302210': __('identifierSchemeCodeUnspsc190501.50302210'),
	'identifierSchemeCodeUnspsc190501.50302211': __('identifierSchemeCodeUnspsc190501.50302211'),
	'identifierSchemeCodeUnspsc190501.50302212': __('identifierSchemeCodeUnspsc190501.50302212'),
	'identifierSchemeCodeUnspsc190501.50302213': __('identifierSchemeCodeUnspsc190501.50302213'),
	'identifierSchemeCodeUnspsc190501.50302214': __('identifierSchemeCodeUnspsc190501.50302214'),
	'identifierSchemeCodeUnspsc190501.50302215': __('identifierSchemeCodeUnspsc190501.50302215'),
	'identifierSchemeCodeUnspsc190501.50302300': __('identifierSchemeCodeUnspsc190501.50302300'),
	'identifierSchemeCodeUnspsc190501.50302301': __('identifierSchemeCodeUnspsc190501.50302301'),
	'identifierSchemeCodeUnspsc190501.50302302': __('identifierSchemeCodeUnspsc190501.50302302'),
	'identifierSchemeCodeUnspsc190501.50302303': __('identifierSchemeCodeUnspsc190501.50302303'),
	'identifierSchemeCodeUnspsc190501.50302304': __('identifierSchemeCodeUnspsc190501.50302304'),
	'identifierSchemeCodeUnspsc190501.50302400': __('identifierSchemeCodeUnspsc190501.50302400'),
	'identifierSchemeCodeUnspsc190501.50302401': __('identifierSchemeCodeUnspsc190501.50302401'),
	'identifierSchemeCodeUnspsc190501.50302402': __('identifierSchemeCodeUnspsc190501.50302402'),
	'identifierSchemeCodeUnspsc190501.50302403': __('identifierSchemeCodeUnspsc190501.50302403'),
	'identifierSchemeCodeUnspsc190501.50302404': __('identifierSchemeCodeUnspsc190501.50302404'),
	'identifierSchemeCodeUnspsc190501.50302405': __('identifierSchemeCodeUnspsc190501.50302405'),
	'identifierSchemeCodeUnspsc190501.50302406': __('identifierSchemeCodeUnspsc190501.50302406'),
	'identifierSchemeCodeUnspsc190501.50302407': __('identifierSchemeCodeUnspsc190501.50302407'),
	'identifierSchemeCodeUnspsc190501.50302408': __('identifierSchemeCodeUnspsc190501.50302408'),
	'identifierSchemeCodeUnspsc190501.50302409': __('identifierSchemeCodeUnspsc190501.50302409'),
	'identifierSchemeCodeUnspsc190501.50302500': __('identifierSchemeCodeUnspsc190501.50302500'),
	'identifierSchemeCodeUnspsc190501.50302501': __('identifierSchemeCodeUnspsc190501.50302501'),
	'identifierSchemeCodeUnspsc190501.50302502': __('identifierSchemeCodeUnspsc190501.50302502'),
	'identifierSchemeCodeUnspsc190501.50302503': __('identifierSchemeCodeUnspsc190501.50302503'),
	'identifierSchemeCodeUnspsc190501.50302504': __('identifierSchemeCodeUnspsc190501.50302504'),
	'identifierSchemeCodeUnspsc190501.50302505': __('identifierSchemeCodeUnspsc190501.50302505'),
	'identifierSchemeCodeUnspsc190501.50302506': __('identifierSchemeCodeUnspsc190501.50302506'),
	'identifierSchemeCodeUnspsc190501.50302507': __('identifierSchemeCodeUnspsc190501.50302507'),
	'identifierSchemeCodeUnspsc190501.50302508': __('identifierSchemeCodeUnspsc190501.50302508'),
	'identifierSchemeCodeUnspsc190501.50302509': __('identifierSchemeCodeUnspsc190501.50302509'),
	'identifierSchemeCodeUnspsc190501.50302510': __('identifierSchemeCodeUnspsc190501.50302510'),
	'identifierSchemeCodeUnspsc190501.50302511': __('identifierSchemeCodeUnspsc190501.50302511'),
	'identifierSchemeCodeUnspsc190501.50302512': __('identifierSchemeCodeUnspsc190501.50302512'),
	'identifierSchemeCodeUnspsc190501.50302513': __('identifierSchemeCodeUnspsc190501.50302513'),
	'identifierSchemeCodeUnspsc190501.50302514': __('identifierSchemeCodeUnspsc190501.50302514'),
	'identifierSchemeCodeUnspsc190501.50302515': __('identifierSchemeCodeUnspsc190501.50302515'),
	'identifierSchemeCodeUnspsc190501.50302516': __('identifierSchemeCodeUnspsc190501.50302516'),
	'identifierSchemeCodeUnspsc190501.50302517': __('identifierSchemeCodeUnspsc190501.50302517'),
	'identifierSchemeCodeUnspsc190501.50302518': __('identifierSchemeCodeUnspsc190501.50302518'),
	'identifierSchemeCodeUnspsc190501.50302519': __('identifierSchemeCodeUnspsc190501.50302519'),
	'identifierSchemeCodeUnspsc190501.50302520': __('identifierSchemeCodeUnspsc190501.50302520'),
	'identifierSchemeCodeUnspsc190501.50302521': __('identifierSchemeCodeUnspsc190501.50302521'),
	'identifierSchemeCodeUnspsc190501.50302522': __('identifierSchemeCodeUnspsc190501.50302522'),
	'identifierSchemeCodeUnspsc190501.50302523': __('identifierSchemeCodeUnspsc190501.50302523'),
	'identifierSchemeCodeUnspsc190501.50302524': __('identifierSchemeCodeUnspsc190501.50302524'),
	'identifierSchemeCodeUnspsc190501.50302525': __('identifierSchemeCodeUnspsc190501.50302525'),
	'identifierSchemeCodeUnspsc190501.50302527': __('identifierSchemeCodeUnspsc190501.50302527'),
	'identifierSchemeCodeUnspsc190501.50302528': __('identifierSchemeCodeUnspsc190501.50302528'),
	'identifierSchemeCodeUnspsc190501.50302529': __('identifierSchemeCodeUnspsc190501.50302529'),
	'identifierSchemeCodeUnspsc190501.50302530': __('identifierSchemeCodeUnspsc190501.50302530'),
	'identifierSchemeCodeUnspsc190501.50302531': __('identifierSchemeCodeUnspsc190501.50302531'),
	'identifierSchemeCodeUnspsc190501.50302532': __('identifierSchemeCodeUnspsc190501.50302532'),
	'identifierSchemeCodeUnspsc190501.50302533': __('identifierSchemeCodeUnspsc190501.50302533'),
	'identifierSchemeCodeUnspsc190501.50302600': __('identifierSchemeCodeUnspsc190501.50302600'),
	'identifierSchemeCodeUnspsc190501.50302601': __('identifierSchemeCodeUnspsc190501.50302601'),
	'identifierSchemeCodeUnspsc190501.50302602': __('identifierSchemeCodeUnspsc190501.50302602'),
	'identifierSchemeCodeUnspsc190501.50302603': __('identifierSchemeCodeUnspsc190501.50302603'),
	'identifierSchemeCodeUnspsc190501.50302604': __('identifierSchemeCodeUnspsc190501.50302604'),
	'identifierSchemeCodeUnspsc190501.50302605': __('identifierSchemeCodeUnspsc190501.50302605'),
	'identifierSchemeCodeUnspsc190501.50302606': __('identifierSchemeCodeUnspsc190501.50302606'),
	'identifierSchemeCodeUnspsc190501.50302607': __('identifierSchemeCodeUnspsc190501.50302607'),
	'identifierSchemeCodeUnspsc190501.50302700': __('identifierSchemeCodeUnspsc190501.50302700'),
	'identifierSchemeCodeUnspsc190501.50302701': __('identifierSchemeCodeUnspsc190501.50302701'),
	'identifierSchemeCodeUnspsc190501.50302702': __('identifierSchemeCodeUnspsc190501.50302702'),
	'identifierSchemeCodeUnspsc190501.50302703': __('identifierSchemeCodeUnspsc190501.50302703'),
	'identifierSchemeCodeUnspsc190501.50302704': __('identifierSchemeCodeUnspsc190501.50302704'),
	'identifierSchemeCodeUnspsc190501.50302705': __('identifierSchemeCodeUnspsc190501.50302705'),
	'identifierSchemeCodeUnspsc190501.50302706': __('identifierSchemeCodeUnspsc190501.50302706'),
	'identifierSchemeCodeUnspsc190501.50302707': __('identifierSchemeCodeUnspsc190501.50302707'),
	'identifierSchemeCodeUnspsc190501.50302708': __('identifierSchemeCodeUnspsc190501.50302708'),
	'identifierSchemeCodeUnspsc190501.50302709': __('identifierSchemeCodeUnspsc190501.50302709'),
	'identifierSchemeCodeUnspsc190501.50302710': __('identifierSchemeCodeUnspsc190501.50302710'),
	'identifierSchemeCodeUnspsc190501.50302800': __('identifierSchemeCodeUnspsc190501.50302800'),
	'identifierSchemeCodeUnspsc190501.50302801': __('identifierSchemeCodeUnspsc190501.50302801'),
	'identifierSchemeCodeUnspsc190501.50302802': __('identifierSchemeCodeUnspsc190501.50302802'),
	'identifierSchemeCodeUnspsc190501.50302803': __('identifierSchemeCodeUnspsc190501.50302803'),
	'identifierSchemeCodeUnspsc190501.50302804': __('identifierSchemeCodeUnspsc190501.50302804'),
	'identifierSchemeCodeUnspsc190501.50302805': __('identifierSchemeCodeUnspsc190501.50302805'),
	'identifierSchemeCodeUnspsc190501.50302806': __('identifierSchemeCodeUnspsc190501.50302806'),
	'identifierSchemeCodeUnspsc190501.50302900': __('identifierSchemeCodeUnspsc190501.50302900'),
	'identifierSchemeCodeUnspsc190501.50302901': __('identifierSchemeCodeUnspsc190501.50302901'),
	'identifierSchemeCodeUnspsc190501.50302902': __('identifierSchemeCodeUnspsc190501.50302902'),
	'identifierSchemeCodeUnspsc190501.50302903': __('identifierSchemeCodeUnspsc190501.50302903'),
	'identifierSchemeCodeUnspsc190501.50302904': __('identifierSchemeCodeUnspsc190501.50302904'),
	'identifierSchemeCodeUnspsc190501.50302905': __('identifierSchemeCodeUnspsc190501.50302905'),
	'identifierSchemeCodeUnspsc190501.50302906': __('identifierSchemeCodeUnspsc190501.50302906'),
	'identifierSchemeCodeUnspsc190501.50302907': __('identifierSchemeCodeUnspsc190501.50302907'),
	'identifierSchemeCodeUnspsc190501.50302908': __('identifierSchemeCodeUnspsc190501.50302908'),
	'identifierSchemeCodeUnspsc190501.50302909': __('identifierSchemeCodeUnspsc190501.50302909'),
	'identifierSchemeCodeUnspsc190501.50302910': __('identifierSchemeCodeUnspsc190501.50302910'),
	'identifierSchemeCodeUnspsc190501.50302911': __('identifierSchemeCodeUnspsc190501.50302911'),
	'identifierSchemeCodeUnspsc190501.50302912': __('identifierSchemeCodeUnspsc190501.50302912'),
	'identifierSchemeCodeUnspsc190501.50302913': __('identifierSchemeCodeUnspsc190501.50302913'),
	'identifierSchemeCodeUnspsc190501.50302914': __('identifierSchemeCodeUnspsc190501.50302914'),
	'identifierSchemeCodeUnspsc190501.50302915': __('identifierSchemeCodeUnspsc190501.50302915'),
	'identifierSchemeCodeUnspsc190501.50302916': __('identifierSchemeCodeUnspsc190501.50302916'),
	'identifierSchemeCodeUnspsc190501.50303000': __('identifierSchemeCodeUnspsc190501.50303000'),
	'identifierSchemeCodeUnspsc190501.50303001': __('identifierSchemeCodeUnspsc190501.50303001'),
	'identifierSchemeCodeUnspsc190501.50303002': __('identifierSchemeCodeUnspsc190501.50303002'),
	'identifierSchemeCodeUnspsc190501.50303100': __('identifierSchemeCodeUnspsc190501.50303100'),
	'identifierSchemeCodeUnspsc190501.50303101': __('identifierSchemeCodeUnspsc190501.50303101'),
	'identifierSchemeCodeUnspsc190501.50303102': __('identifierSchemeCodeUnspsc190501.50303102'),
	'identifierSchemeCodeUnspsc190501.50303103': __('identifierSchemeCodeUnspsc190501.50303103'),
	'identifierSchemeCodeUnspsc190501.50303104': __('identifierSchemeCodeUnspsc190501.50303104'),
	'identifierSchemeCodeUnspsc190501.50303105': __('identifierSchemeCodeUnspsc190501.50303105'),
	'identifierSchemeCodeUnspsc190501.50303106': __('identifierSchemeCodeUnspsc190501.50303106'),
	'identifierSchemeCodeUnspsc190501.50303107': __('identifierSchemeCodeUnspsc190501.50303107'),
	'identifierSchemeCodeUnspsc190501.50303108': __('identifierSchemeCodeUnspsc190501.50303108'),
	'identifierSchemeCodeUnspsc190501.50303109': __('identifierSchemeCodeUnspsc190501.50303109'),
	'identifierSchemeCodeUnspsc190501.50303110': __('identifierSchemeCodeUnspsc190501.50303110'),
	'identifierSchemeCodeUnspsc190501.50303111': __('identifierSchemeCodeUnspsc190501.50303111'),
	'identifierSchemeCodeUnspsc190501.50303200': __('identifierSchemeCodeUnspsc190501.50303200'),
	'identifierSchemeCodeUnspsc190501.50303201': __('identifierSchemeCodeUnspsc190501.50303201'),
	'identifierSchemeCodeUnspsc190501.50303202': __('identifierSchemeCodeUnspsc190501.50303202'),
	'identifierSchemeCodeUnspsc190501.50303203': __('identifierSchemeCodeUnspsc190501.50303203'),
	'identifierSchemeCodeUnspsc190501.50303204': __('identifierSchemeCodeUnspsc190501.50303204'),
	'identifierSchemeCodeUnspsc190501.50303205': __('identifierSchemeCodeUnspsc190501.50303205'),
	'identifierSchemeCodeUnspsc190501.50303206': __('identifierSchemeCodeUnspsc190501.50303206'),
	'identifierSchemeCodeUnspsc190501.50303207': __('identifierSchemeCodeUnspsc190501.50303207'),
	'identifierSchemeCodeUnspsc190501.50303300': __('identifierSchemeCodeUnspsc190501.50303300'),
	'identifierSchemeCodeUnspsc190501.50303301': __('identifierSchemeCodeUnspsc190501.50303301'),
	'identifierSchemeCodeUnspsc190501.50303302': __('identifierSchemeCodeUnspsc190501.50303302'),
	'identifierSchemeCodeUnspsc190501.50303303': __('identifierSchemeCodeUnspsc190501.50303303'),
	'identifierSchemeCodeUnspsc190501.50303304': __('identifierSchemeCodeUnspsc190501.50303304'),
	'identifierSchemeCodeUnspsc190501.50303305': __('identifierSchemeCodeUnspsc190501.50303305'),
	'identifierSchemeCodeUnspsc190501.50303306': __('identifierSchemeCodeUnspsc190501.50303306'),
	'identifierSchemeCodeUnspsc190501.50303307': __('identifierSchemeCodeUnspsc190501.50303307'),
	'identifierSchemeCodeUnspsc190501.50303308': __('identifierSchemeCodeUnspsc190501.50303308'),
	'identifierSchemeCodeUnspsc190501.50303309': __('identifierSchemeCodeUnspsc190501.50303309'),
	'identifierSchemeCodeUnspsc190501.50303400': __('identifierSchemeCodeUnspsc190501.50303400'),
	'identifierSchemeCodeUnspsc190501.50303401': __('identifierSchemeCodeUnspsc190501.50303401'),
	'identifierSchemeCodeUnspsc190501.50303402': __('identifierSchemeCodeUnspsc190501.50303402'),
	'identifierSchemeCodeUnspsc190501.50303403': __('identifierSchemeCodeUnspsc190501.50303403'),
	'identifierSchemeCodeUnspsc190501.50303404': __('identifierSchemeCodeUnspsc190501.50303404'),
	'identifierSchemeCodeUnspsc190501.50303405': __('identifierSchemeCodeUnspsc190501.50303405'),
	'identifierSchemeCodeUnspsc190501.50303406': __('identifierSchemeCodeUnspsc190501.50303406'),
	'identifierSchemeCodeUnspsc190501.50303407': __('identifierSchemeCodeUnspsc190501.50303407'),
	'identifierSchemeCodeUnspsc190501.50303408': __('identifierSchemeCodeUnspsc190501.50303408'),
	'identifierSchemeCodeUnspsc190501.50303409': __('identifierSchemeCodeUnspsc190501.50303409'),
	'identifierSchemeCodeUnspsc190501.50303410': __('identifierSchemeCodeUnspsc190501.50303410'),
	'identifierSchemeCodeUnspsc190501.50303411': __('identifierSchemeCodeUnspsc190501.50303411'),
	'identifierSchemeCodeUnspsc190501.50303412': __('identifierSchemeCodeUnspsc190501.50303412'),
	'identifierSchemeCodeUnspsc190501.50303413': __('identifierSchemeCodeUnspsc190501.50303413'),
	'identifierSchemeCodeUnspsc190501.50303414': __('identifierSchemeCodeUnspsc190501.50303414'),
	'identifierSchemeCodeUnspsc190501.50303415': __('identifierSchemeCodeUnspsc190501.50303415'),
	'identifierSchemeCodeUnspsc190501.50303416': __('identifierSchemeCodeUnspsc190501.50303416'),
	'identifierSchemeCodeUnspsc190501.50303417': __('identifierSchemeCodeUnspsc190501.50303417'),
	'identifierSchemeCodeUnspsc190501.50303418': __('identifierSchemeCodeUnspsc190501.50303418'),
	'identifierSchemeCodeUnspsc190501.50303419': __('identifierSchemeCodeUnspsc190501.50303419'),
	'identifierSchemeCodeUnspsc190501.50303420': __('identifierSchemeCodeUnspsc190501.50303420'),
	'identifierSchemeCodeUnspsc190501.50303421': __('identifierSchemeCodeUnspsc190501.50303421'),
	'identifierSchemeCodeUnspsc190501.50303422': __('identifierSchemeCodeUnspsc190501.50303422'),
	'identifierSchemeCodeUnspsc190501.50303423': __('identifierSchemeCodeUnspsc190501.50303423'),
	'identifierSchemeCodeUnspsc190501.50303424': __('identifierSchemeCodeUnspsc190501.50303424'),
	'identifierSchemeCodeUnspsc190501.50303425': __('identifierSchemeCodeUnspsc190501.50303425'),
	'identifierSchemeCodeUnspsc190501.50303426': __('identifierSchemeCodeUnspsc190501.50303426'),
	'identifierSchemeCodeUnspsc190501.50303427': __('identifierSchemeCodeUnspsc190501.50303427'),
	'identifierSchemeCodeUnspsc190501.50303428': __('identifierSchemeCodeUnspsc190501.50303428'),
	'identifierSchemeCodeUnspsc190501.50303429': __('identifierSchemeCodeUnspsc190501.50303429'),
	'identifierSchemeCodeUnspsc190501.50303430': __('identifierSchemeCodeUnspsc190501.50303430'),
	'identifierSchemeCodeUnspsc190501.50303431': __('identifierSchemeCodeUnspsc190501.50303431'),
	'identifierSchemeCodeUnspsc190501.50303432': __('identifierSchemeCodeUnspsc190501.50303432'),
	'identifierSchemeCodeUnspsc190501.50303433': __('identifierSchemeCodeUnspsc190501.50303433'),
	'identifierSchemeCodeUnspsc190501.50303434': __('identifierSchemeCodeUnspsc190501.50303434'),
	'identifierSchemeCodeUnspsc190501.50303435': __('identifierSchemeCodeUnspsc190501.50303435'),
	'identifierSchemeCodeUnspsc190501.50303436': __('identifierSchemeCodeUnspsc190501.50303436'),
	'identifierSchemeCodeUnspsc190501.50303437': __('identifierSchemeCodeUnspsc190501.50303437'),
	'identifierSchemeCodeUnspsc190501.50303438': __('identifierSchemeCodeUnspsc190501.50303438'),
	'identifierSchemeCodeUnspsc190501.50303439': __('identifierSchemeCodeUnspsc190501.50303439'),
	'identifierSchemeCodeUnspsc190501.50303440': __('identifierSchemeCodeUnspsc190501.50303440'),
	'identifierSchemeCodeUnspsc190501.50303441': __('identifierSchemeCodeUnspsc190501.50303441'),
	'identifierSchemeCodeUnspsc190501.50303442': __('identifierSchemeCodeUnspsc190501.50303442'),
	'identifierSchemeCodeUnspsc190501.50303443': __('identifierSchemeCodeUnspsc190501.50303443'),
	'identifierSchemeCodeUnspsc190501.50303444': __('identifierSchemeCodeUnspsc190501.50303444'),
	'identifierSchemeCodeUnspsc190501.50303445': __('identifierSchemeCodeUnspsc190501.50303445'),
	'identifierSchemeCodeUnspsc190501.50303446': __('identifierSchemeCodeUnspsc190501.50303446'),
	'identifierSchemeCodeUnspsc190501.50303447': __('identifierSchemeCodeUnspsc190501.50303447'),
	'identifierSchemeCodeUnspsc190501.50303448': __('identifierSchemeCodeUnspsc190501.50303448'),
	'identifierSchemeCodeUnspsc190501.50303449': __('identifierSchemeCodeUnspsc190501.50303449'),
	'identifierSchemeCodeUnspsc190501.50303450': __('identifierSchemeCodeUnspsc190501.50303450'),
	'identifierSchemeCodeUnspsc190501.50303451': __('identifierSchemeCodeUnspsc190501.50303451'),
	'identifierSchemeCodeUnspsc190501.50303452': __('identifierSchemeCodeUnspsc190501.50303452'),
	'identifierSchemeCodeUnspsc190501.50303453': __('identifierSchemeCodeUnspsc190501.50303453'),
	'identifierSchemeCodeUnspsc190501.50303454': __('identifierSchemeCodeUnspsc190501.50303454'),
	'identifierSchemeCodeUnspsc190501.50303455': __('identifierSchemeCodeUnspsc190501.50303455'),
	'identifierSchemeCodeUnspsc190501.50303456': __('identifierSchemeCodeUnspsc190501.50303456'),
	'identifierSchemeCodeUnspsc190501.50303457': __('identifierSchemeCodeUnspsc190501.50303457'),
	'identifierSchemeCodeUnspsc190501.50303458': __('identifierSchemeCodeUnspsc190501.50303458'),
	'identifierSchemeCodeUnspsc190501.50303459': __('identifierSchemeCodeUnspsc190501.50303459'),
	'identifierSchemeCodeUnspsc190501.50303460': __('identifierSchemeCodeUnspsc190501.50303460'),
	'identifierSchemeCodeUnspsc190501.50303461': __('identifierSchemeCodeUnspsc190501.50303461'),
	'identifierSchemeCodeUnspsc190501.50303462': __('identifierSchemeCodeUnspsc190501.50303462'),
	'identifierSchemeCodeUnspsc190501.50303463': __('identifierSchemeCodeUnspsc190501.50303463'),
	'identifierSchemeCodeUnspsc190501.50303464': __('identifierSchemeCodeUnspsc190501.50303464'),
	'identifierSchemeCodeUnspsc190501.50303465': __('identifierSchemeCodeUnspsc190501.50303465'),
	'identifierSchemeCodeUnspsc190501.50303466': __('identifierSchemeCodeUnspsc190501.50303466'),
	'identifierSchemeCodeUnspsc190501.50303467': __('identifierSchemeCodeUnspsc190501.50303467'),
	'identifierSchemeCodeUnspsc190501.50303468': __('identifierSchemeCodeUnspsc190501.50303468'),
	'identifierSchemeCodeUnspsc190501.50303469': __('identifierSchemeCodeUnspsc190501.50303469'),
	'identifierSchemeCodeUnspsc190501.50303470': __('identifierSchemeCodeUnspsc190501.50303470'),
	'identifierSchemeCodeUnspsc190501.50303471': __('identifierSchemeCodeUnspsc190501.50303471'),
	'identifierSchemeCodeUnspsc190501.50303472': __('identifierSchemeCodeUnspsc190501.50303472'),
	'identifierSchemeCodeUnspsc190501.50303473': __('identifierSchemeCodeUnspsc190501.50303473'),
	'identifierSchemeCodeUnspsc190501.50303474': __('identifierSchemeCodeUnspsc190501.50303474'),
	'identifierSchemeCodeUnspsc190501.50303475': __('identifierSchemeCodeUnspsc190501.50303475'),
	'identifierSchemeCodeUnspsc190501.50303476': __('identifierSchemeCodeUnspsc190501.50303476'),
	'identifierSchemeCodeUnspsc190501.50303477': __('identifierSchemeCodeUnspsc190501.50303477'),
	'identifierSchemeCodeUnspsc190501.50303478': __('identifierSchemeCodeUnspsc190501.50303478'),
	'identifierSchemeCodeUnspsc190501.50303479': __('identifierSchemeCodeUnspsc190501.50303479'),
	'identifierSchemeCodeUnspsc190501.50303480': __('identifierSchemeCodeUnspsc190501.50303480'),
	'identifierSchemeCodeUnspsc190501.50303481': __('identifierSchemeCodeUnspsc190501.50303481'),
	'identifierSchemeCodeUnspsc190501.50303482': __('identifierSchemeCodeUnspsc190501.50303482'),
	'identifierSchemeCodeUnspsc190501.50303483': __('identifierSchemeCodeUnspsc190501.50303483'),
	'identifierSchemeCodeUnspsc190501.50303484': __('identifierSchemeCodeUnspsc190501.50303484'),
	'identifierSchemeCodeUnspsc190501.50303485': __('identifierSchemeCodeUnspsc190501.50303485'),
	'identifierSchemeCodeUnspsc190501.50303486': __('identifierSchemeCodeUnspsc190501.50303486'),
	'identifierSchemeCodeUnspsc190501.50303487': __('identifierSchemeCodeUnspsc190501.50303487'),
	'identifierSchemeCodeUnspsc190501.50303488': __('identifierSchemeCodeUnspsc190501.50303488'),
	'identifierSchemeCodeUnspsc190501.50303489': __('identifierSchemeCodeUnspsc190501.50303489'),
	'identifierSchemeCodeUnspsc190501.50303490': __('identifierSchemeCodeUnspsc190501.50303490'),
	'identifierSchemeCodeUnspsc190501.50303491': __('identifierSchemeCodeUnspsc190501.50303491'),
	'identifierSchemeCodeUnspsc190501.50303492': __('identifierSchemeCodeUnspsc190501.50303492'),
	'identifierSchemeCodeUnspsc190501.50303493': __('identifierSchemeCodeUnspsc190501.50303493'),
	'identifierSchemeCodeUnspsc190501.50303494': __('identifierSchemeCodeUnspsc190501.50303494'),
	'identifierSchemeCodeUnspsc190501.50303495': __('identifierSchemeCodeUnspsc190501.50303495'),
	'identifierSchemeCodeUnspsc190501.50303496': __('identifierSchemeCodeUnspsc190501.50303496'),
	'identifierSchemeCodeUnspsc190501.50303497': __('identifierSchemeCodeUnspsc190501.50303497'),
	'identifierSchemeCodeUnspsc190501.50303498': __('identifierSchemeCodeUnspsc190501.50303498'),
	'identifierSchemeCodeUnspsc190501.50303499': __('identifierSchemeCodeUnspsc190501.50303499'),
	'identifierSchemeCodeUnspsc190501.50303500': __('identifierSchemeCodeUnspsc190501.50303500'),
	'identifierSchemeCodeUnspsc190501.50303501': __('identifierSchemeCodeUnspsc190501.50303501'),
	'identifierSchemeCodeUnspsc190501.50303502': __('identifierSchemeCodeUnspsc190501.50303502'),
	'identifierSchemeCodeUnspsc190501.50303503': __('identifierSchemeCodeUnspsc190501.50303503'),
	'identifierSchemeCodeUnspsc190501.50303504': __('identifierSchemeCodeUnspsc190501.50303504'),
	'identifierSchemeCodeUnspsc190501.50303505': __('identifierSchemeCodeUnspsc190501.50303505'),
	'identifierSchemeCodeUnspsc190501.50303506': __('identifierSchemeCodeUnspsc190501.50303506'),
	'identifierSchemeCodeUnspsc190501.50303600': __('identifierSchemeCodeUnspsc190501.50303600'),
	'identifierSchemeCodeUnspsc190501.50303601': __('identifierSchemeCodeUnspsc190501.50303601'),
	'identifierSchemeCodeUnspsc190501.50303602': __('identifierSchemeCodeUnspsc190501.50303602'),
	'identifierSchemeCodeUnspsc190501.50303603': __('identifierSchemeCodeUnspsc190501.50303603'),
	'identifierSchemeCodeUnspsc190501.50303604': __('identifierSchemeCodeUnspsc190501.50303604'),
	'identifierSchemeCodeUnspsc190501.50303605': __('identifierSchemeCodeUnspsc190501.50303605'),
	'identifierSchemeCodeUnspsc190501.50303606': __('identifierSchemeCodeUnspsc190501.50303606'),
	'identifierSchemeCodeUnspsc190501.50303607': __('identifierSchemeCodeUnspsc190501.50303607'),
	'identifierSchemeCodeUnspsc190501.50303608': __('identifierSchemeCodeUnspsc190501.50303608'),
	'identifierSchemeCodeUnspsc190501.50303609': __('identifierSchemeCodeUnspsc190501.50303609'),
	'identifierSchemeCodeUnspsc190501.50303610': __('identifierSchemeCodeUnspsc190501.50303610'),
	'identifierSchemeCodeUnspsc190501.50303611': __('identifierSchemeCodeUnspsc190501.50303611'),
	'identifierSchemeCodeUnspsc190501.50303612': __('identifierSchemeCodeUnspsc190501.50303612'),
	'identifierSchemeCodeUnspsc190501.50303613': __('identifierSchemeCodeUnspsc190501.50303613'),
	'identifierSchemeCodeUnspsc190501.50303614': __('identifierSchemeCodeUnspsc190501.50303614'),
	'identifierSchemeCodeUnspsc190501.50303615': __('identifierSchemeCodeUnspsc190501.50303615'),
	'identifierSchemeCodeUnspsc190501.50303616': __('identifierSchemeCodeUnspsc190501.50303616'),
	'identifierSchemeCodeUnspsc190501.50303617': __('identifierSchemeCodeUnspsc190501.50303617'),
	'identifierSchemeCodeUnspsc190501.50303618': __('identifierSchemeCodeUnspsc190501.50303618'),
	'identifierSchemeCodeUnspsc190501.50303619': __('identifierSchemeCodeUnspsc190501.50303619'),
	'identifierSchemeCodeUnspsc190501.50303620': __('identifierSchemeCodeUnspsc190501.50303620'),
	'identifierSchemeCodeUnspsc190501.50303621': __('identifierSchemeCodeUnspsc190501.50303621'),
	'identifierSchemeCodeUnspsc190501.50303622': __('identifierSchemeCodeUnspsc190501.50303622'),
	'identifierSchemeCodeUnspsc190501.50303623': __('identifierSchemeCodeUnspsc190501.50303623'),
	'identifierSchemeCodeUnspsc190501.50303624': __('identifierSchemeCodeUnspsc190501.50303624'),
	'identifierSchemeCodeUnspsc190501.50303625': __('identifierSchemeCodeUnspsc190501.50303625'),
	'identifierSchemeCodeUnspsc190501.50303626': __('identifierSchemeCodeUnspsc190501.50303626'),
	'identifierSchemeCodeUnspsc190501.50303627': __('identifierSchemeCodeUnspsc190501.50303627'),
	'identifierSchemeCodeUnspsc190501.50303628': __('identifierSchemeCodeUnspsc190501.50303628'),
	'identifierSchemeCodeUnspsc190501.50303629': __('identifierSchemeCodeUnspsc190501.50303629'),
	'identifierSchemeCodeUnspsc190501.50303630': __('identifierSchemeCodeUnspsc190501.50303630'),
	'identifierSchemeCodeUnspsc190501.50303631': __('identifierSchemeCodeUnspsc190501.50303631'),
	'identifierSchemeCodeUnspsc190501.50303632': __('identifierSchemeCodeUnspsc190501.50303632'),
	'identifierSchemeCodeUnspsc190501.50303633': __('identifierSchemeCodeUnspsc190501.50303633'),
	'identifierSchemeCodeUnspsc190501.50303634': __('identifierSchemeCodeUnspsc190501.50303634'),
	'identifierSchemeCodeUnspsc190501.50303635': __('identifierSchemeCodeUnspsc190501.50303635'),
	'identifierSchemeCodeUnspsc190501.50303636': __('identifierSchemeCodeUnspsc190501.50303636'),
	'identifierSchemeCodeUnspsc190501.50303637': __('identifierSchemeCodeUnspsc190501.50303637'),
	'identifierSchemeCodeUnspsc190501.50303638': __('identifierSchemeCodeUnspsc190501.50303638'),
	'identifierSchemeCodeUnspsc190501.50303639': __('identifierSchemeCodeUnspsc190501.50303639'),
	'identifierSchemeCodeUnspsc190501.50303640': __('identifierSchemeCodeUnspsc190501.50303640'),
	'identifierSchemeCodeUnspsc190501.50303641': __('identifierSchemeCodeUnspsc190501.50303641'),
	'identifierSchemeCodeUnspsc190501.50303642': __('identifierSchemeCodeUnspsc190501.50303642'),
	'identifierSchemeCodeUnspsc190501.50303643': __('identifierSchemeCodeUnspsc190501.50303643'),
	'identifierSchemeCodeUnspsc190501.50303644': __('identifierSchemeCodeUnspsc190501.50303644'),
	'identifierSchemeCodeUnspsc190501.50303645': __('identifierSchemeCodeUnspsc190501.50303645'),
	'identifierSchemeCodeUnspsc190501.50303646': __('identifierSchemeCodeUnspsc190501.50303646'),
	'identifierSchemeCodeUnspsc190501.50303647': __('identifierSchemeCodeUnspsc190501.50303647'),
	'identifierSchemeCodeUnspsc190501.50303648': __('identifierSchemeCodeUnspsc190501.50303648'),
	'identifierSchemeCodeUnspsc190501.50303649': __('identifierSchemeCodeUnspsc190501.50303649'),
	'identifierSchemeCodeUnspsc190501.50303650': __('identifierSchemeCodeUnspsc190501.50303650'),
	'identifierSchemeCodeUnspsc190501.50303651': __('identifierSchemeCodeUnspsc190501.50303651'),
	'identifierSchemeCodeUnspsc190501.50303652': __('identifierSchemeCodeUnspsc190501.50303652'),
	'identifierSchemeCodeUnspsc190501.50303653': __('identifierSchemeCodeUnspsc190501.50303653'),
	'identifierSchemeCodeUnspsc190501.50303654': __('identifierSchemeCodeUnspsc190501.50303654'),
	'identifierSchemeCodeUnspsc190501.50303655': __('identifierSchemeCodeUnspsc190501.50303655'),
	'identifierSchemeCodeUnspsc190501.50303656': __('identifierSchemeCodeUnspsc190501.50303656'),
	'identifierSchemeCodeUnspsc190501.50303657': __('identifierSchemeCodeUnspsc190501.50303657'),
	'identifierSchemeCodeUnspsc190501.50303658': __('identifierSchemeCodeUnspsc190501.50303658'),
	'identifierSchemeCodeUnspsc190501.50303659': __('identifierSchemeCodeUnspsc190501.50303659'),
	'identifierSchemeCodeUnspsc190501.50303660': __('identifierSchemeCodeUnspsc190501.50303660'),
	'identifierSchemeCodeUnspsc190501.50303661': __('identifierSchemeCodeUnspsc190501.50303661'),
	'identifierSchemeCodeUnspsc190501.50303662': __('identifierSchemeCodeUnspsc190501.50303662'),
	'identifierSchemeCodeUnspsc190501.50303663': __('identifierSchemeCodeUnspsc190501.50303663'),
	'identifierSchemeCodeUnspsc190501.50303664': __('identifierSchemeCodeUnspsc190501.50303664'),
	'identifierSchemeCodeUnspsc190501.50303665': __('identifierSchemeCodeUnspsc190501.50303665'),
	'identifierSchemeCodeUnspsc190501.50303700': __('identifierSchemeCodeUnspsc190501.50303700'),
	'identifierSchemeCodeUnspsc190501.50303701': __('identifierSchemeCodeUnspsc190501.50303701'),
	'identifierSchemeCodeUnspsc190501.50303702': __('identifierSchemeCodeUnspsc190501.50303702'),
	'identifierSchemeCodeUnspsc190501.50303703': __('identifierSchemeCodeUnspsc190501.50303703'),
	'identifierSchemeCodeUnspsc190501.50303704': __('identifierSchemeCodeUnspsc190501.50303704'),
	'identifierSchemeCodeUnspsc190501.50303800': __('identifierSchemeCodeUnspsc190501.50303800'),
	'identifierSchemeCodeUnspsc190501.50303801': __('identifierSchemeCodeUnspsc190501.50303801'),
	'identifierSchemeCodeUnspsc190501.50303802': __('identifierSchemeCodeUnspsc190501.50303802'),
	'identifierSchemeCodeUnspsc190501.50303803': __('identifierSchemeCodeUnspsc190501.50303803'),
	'identifierSchemeCodeUnspsc190501.50303804': __('identifierSchemeCodeUnspsc190501.50303804'),
	'identifierSchemeCodeUnspsc190501.50303805': __('identifierSchemeCodeUnspsc190501.50303805'),
	'identifierSchemeCodeUnspsc190501.50303900': __('identifierSchemeCodeUnspsc190501.50303900'),
	'identifierSchemeCodeUnspsc190501.50303901': __('identifierSchemeCodeUnspsc190501.50303901'),
	'identifierSchemeCodeUnspsc190501.50303902': __('identifierSchemeCodeUnspsc190501.50303902'),
	'identifierSchemeCodeUnspsc190501.50303903': __('identifierSchemeCodeUnspsc190501.50303903'),
	'identifierSchemeCodeUnspsc190501.50303904': __('identifierSchemeCodeUnspsc190501.50303904'),
	'identifierSchemeCodeUnspsc190501.50303905': __('identifierSchemeCodeUnspsc190501.50303905'),
	'identifierSchemeCodeUnspsc190501.50303906': __('identifierSchemeCodeUnspsc190501.50303906'),
	'identifierSchemeCodeUnspsc190501.50304000': __('identifierSchemeCodeUnspsc190501.50304000'),
	'identifierSchemeCodeUnspsc190501.50304001': __('identifierSchemeCodeUnspsc190501.50304001'),
	'identifierSchemeCodeUnspsc190501.50304002': __('identifierSchemeCodeUnspsc190501.50304002'),
	'identifierSchemeCodeUnspsc190501.50304003': __('identifierSchemeCodeUnspsc190501.50304003'),
	'identifierSchemeCodeUnspsc190501.50304004': __('identifierSchemeCodeUnspsc190501.50304004'),
	'identifierSchemeCodeUnspsc190501.50304005': __('identifierSchemeCodeUnspsc190501.50304005'),
	'identifierSchemeCodeUnspsc190501.50304006': __('identifierSchemeCodeUnspsc190501.50304006'),
	'identifierSchemeCodeUnspsc190501.50304100': __('identifierSchemeCodeUnspsc190501.50304100'),
	'identifierSchemeCodeUnspsc190501.50304101': __('identifierSchemeCodeUnspsc190501.50304101'),
	'identifierSchemeCodeUnspsc190501.50304102': __('identifierSchemeCodeUnspsc190501.50304102'),
	'identifierSchemeCodeUnspsc190501.50304103': __('identifierSchemeCodeUnspsc190501.50304103'),
	'identifierSchemeCodeUnspsc190501.50304104': __('identifierSchemeCodeUnspsc190501.50304104'),
	'identifierSchemeCodeUnspsc190501.50304105': __('identifierSchemeCodeUnspsc190501.50304105'),
	'identifierSchemeCodeUnspsc190501.50304106': __('identifierSchemeCodeUnspsc190501.50304106'),
	'identifierSchemeCodeUnspsc190501.50304107': __('identifierSchemeCodeUnspsc190501.50304107'),
	'identifierSchemeCodeUnspsc190501.50304108': __('identifierSchemeCodeUnspsc190501.50304108'),
	'identifierSchemeCodeUnspsc190501.50304200': __('identifierSchemeCodeUnspsc190501.50304200'),
	'identifierSchemeCodeUnspsc190501.50304201': __('identifierSchemeCodeUnspsc190501.50304201'),
	'identifierSchemeCodeUnspsc190501.50304202': __('identifierSchemeCodeUnspsc190501.50304202'),
	'identifierSchemeCodeUnspsc190501.50304203': __('identifierSchemeCodeUnspsc190501.50304203'),
	'identifierSchemeCodeUnspsc190501.50304204': __('identifierSchemeCodeUnspsc190501.50304204'),
	'identifierSchemeCodeUnspsc190501.50304205': __('identifierSchemeCodeUnspsc190501.50304205'),
	'identifierSchemeCodeUnspsc190501.50304206': __('identifierSchemeCodeUnspsc190501.50304206'),
	'identifierSchemeCodeUnspsc190501.50304207': __('identifierSchemeCodeUnspsc190501.50304207'),
	'identifierSchemeCodeUnspsc190501.50304208': __('identifierSchemeCodeUnspsc190501.50304208'),
	'identifierSchemeCodeUnspsc190501.50304300': __('identifierSchemeCodeUnspsc190501.50304300'),
	'identifierSchemeCodeUnspsc190501.50304301': __('identifierSchemeCodeUnspsc190501.50304301'),
	'identifierSchemeCodeUnspsc190501.50304302': __('identifierSchemeCodeUnspsc190501.50304302'),
	'identifierSchemeCodeUnspsc190501.50304303': __('identifierSchemeCodeUnspsc190501.50304303'),
	'identifierSchemeCodeUnspsc190501.50304304': __('identifierSchemeCodeUnspsc190501.50304304'),
	'identifierSchemeCodeUnspsc190501.50304305': __('identifierSchemeCodeUnspsc190501.50304305'),
	'identifierSchemeCodeUnspsc190501.50304306': __('identifierSchemeCodeUnspsc190501.50304306'),
	'identifierSchemeCodeUnspsc190501.50304307': __('identifierSchemeCodeUnspsc190501.50304307'),
	'identifierSchemeCodeUnspsc190501.50304308': __('identifierSchemeCodeUnspsc190501.50304308'),
	'identifierSchemeCodeUnspsc190501.50304309': __('identifierSchemeCodeUnspsc190501.50304309'),
	'identifierSchemeCodeUnspsc190501.50304310': __('identifierSchemeCodeUnspsc190501.50304310'),
	'identifierSchemeCodeUnspsc190501.50304311': __('identifierSchemeCodeUnspsc190501.50304311'),
	'identifierSchemeCodeUnspsc190501.50304312': __('identifierSchemeCodeUnspsc190501.50304312'),
	'identifierSchemeCodeUnspsc190501.50304313': __('identifierSchemeCodeUnspsc190501.50304313'),
	'identifierSchemeCodeUnspsc190501.50304400': __('identifierSchemeCodeUnspsc190501.50304400'),
	'identifierSchemeCodeUnspsc190501.50304401': __('identifierSchemeCodeUnspsc190501.50304401'),
	'identifierSchemeCodeUnspsc190501.50304402': __('identifierSchemeCodeUnspsc190501.50304402'),
	'identifierSchemeCodeUnspsc190501.50304403': __('identifierSchemeCodeUnspsc190501.50304403'),
	'identifierSchemeCodeUnspsc190501.50304404': __('identifierSchemeCodeUnspsc190501.50304404'),
	'identifierSchemeCodeUnspsc190501.50304405': __('identifierSchemeCodeUnspsc190501.50304405'),
	'identifierSchemeCodeUnspsc190501.50304406': __('identifierSchemeCodeUnspsc190501.50304406'),
	'identifierSchemeCodeUnspsc190501.50304407': __('identifierSchemeCodeUnspsc190501.50304407'),
	'identifierSchemeCodeUnspsc190501.50304408': __('identifierSchemeCodeUnspsc190501.50304408'),
	'identifierSchemeCodeUnspsc190501.50304409': __('identifierSchemeCodeUnspsc190501.50304409'),
	'identifierSchemeCodeUnspsc190501.50304410': __('identifierSchemeCodeUnspsc190501.50304410'),
	'identifierSchemeCodeUnspsc190501.50304411': __('identifierSchemeCodeUnspsc190501.50304411'),
	'identifierSchemeCodeUnspsc190501.50304412': __('identifierSchemeCodeUnspsc190501.50304412'),
	'identifierSchemeCodeUnspsc190501.50304413': __('identifierSchemeCodeUnspsc190501.50304413'),
	'identifierSchemeCodeUnspsc190501.50304414': __('identifierSchemeCodeUnspsc190501.50304414'),
	'identifierSchemeCodeUnspsc190501.50304415': __('identifierSchemeCodeUnspsc190501.50304415'),
	'identifierSchemeCodeUnspsc190501.50304416': __('identifierSchemeCodeUnspsc190501.50304416'),
	'identifierSchemeCodeUnspsc190501.50304417': __('identifierSchemeCodeUnspsc190501.50304417'),
	'identifierSchemeCodeUnspsc190501.50304418': __('identifierSchemeCodeUnspsc190501.50304418'),
	'identifierSchemeCodeUnspsc190501.50304419': __('identifierSchemeCodeUnspsc190501.50304419'),
	'identifierSchemeCodeUnspsc190501.50304420': __('identifierSchemeCodeUnspsc190501.50304420'),
	'identifierSchemeCodeUnspsc190501.50304421': __('identifierSchemeCodeUnspsc190501.50304421'),
	'identifierSchemeCodeUnspsc190501.50304422': __('identifierSchemeCodeUnspsc190501.50304422'),
	'identifierSchemeCodeUnspsc190501.50304423': __('identifierSchemeCodeUnspsc190501.50304423'),
	'identifierSchemeCodeUnspsc190501.50304424': __('identifierSchemeCodeUnspsc190501.50304424'),
	'identifierSchemeCodeUnspsc190501.50304425': __('identifierSchemeCodeUnspsc190501.50304425'),
	'identifierSchemeCodeUnspsc190501.50304426': __('identifierSchemeCodeUnspsc190501.50304426'),
	'identifierSchemeCodeUnspsc190501.50304427': __('identifierSchemeCodeUnspsc190501.50304427'),
	'identifierSchemeCodeUnspsc190501.50304428': __('identifierSchemeCodeUnspsc190501.50304428'),
	'identifierSchemeCodeUnspsc190501.50304429': __('identifierSchemeCodeUnspsc190501.50304429'),
	'identifierSchemeCodeUnspsc190501.50304430': __('identifierSchemeCodeUnspsc190501.50304430'),
	'identifierSchemeCodeUnspsc190501.50304431': __('identifierSchemeCodeUnspsc190501.50304431'),
	'identifierSchemeCodeUnspsc190501.50304432': __('identifierSchemeCodeUnspsc190501.50304432'),
	'identifierSchemeCodeUnspsc190501.50304433': __('identifierSchemeCodeUnspsc190501.50304433'),
	'identifierSchemeCodeUnspsc190501.50304434': __('identifierSchemeCodeUnspsc190501.50304434'),
	'identifierSchemeCodeUnspsc190501.50304435': __('identifierSchemeCodeUnspsc190501.50304435'),
	'identifierSchemeCodeUnspsc190501.50304436': __('identifierSchemeCodeUnspsc190501.50304436'),
	'identifierSchemeCodeUnspsc190501.50304437': __('identifierSchemeCodeUnspsc190501.50304437'),
	'identifierSchemeCodeUnspsc190501.50304438': __('identifierSchemeCodeUnspsc190501.50304438'),
	'identifierSchemeCodeUnspsc190501.50304439': __('identifierSchemeCodeUnspsc190501.50304439'),
	'identifierSchemeCodeUnspsc190501.50304440': __('identifierSchemeCodeUnspsc190501.50304440'),
	'identifierSchemeCodeUnspsc190501.50304441': __('identifierSchemeCodeUnspsc190501.50304441'),
	'identifierSchemeCodeUnspsc190501.50304442': __('identifierSchemeCodeUnspsc190501.50304442'),
	'identifierSchemeCodeUnspsc190501.50304443': __('identifierSchemeCodeUnspsc190501.50304443'),
	'identifierSchemeCodeUnspsc190501.50304444': __('identifierSchemeCodeUnspsc190501.50304444'),
	'identifierSchemeCodeUnspsc190501.50304445': __('identifierSchemeCodeUnspsc190501.50304445'),
	'identifierSchemeCodeUnspsc190501.50304446': __('identifierSchemeCodeUnspsc190501.50304446'),
	'identifierSchemeCodeUnspsc190501.50304500': __('identifierSchemeCodeUnspsc190501.50304500'),
	'identifierSchemeCodeUnspsc190501.50304501': __('identifierSchemeCodeUnspsc190501.50304501'),
	'identifierSchemeCodeUnspsc190501.50304502': __('identifierSchemeCodeUnspsc190501.50304502'),
	'identifierSchemeCodeUnspsc190501.50304503': __('identifierSchemeCodeUnspsc190501.50304503'),
	'identifierSchemeCodeUnspsc190501.50304504': __('identifierSchemeCodeUnspsc190501.50304504'),
	'identifierSchemeCodeUnspsc190501.50304505': __('identifierSchemeCodeUnspsc190501.50304505'),
	'identifierSchemeCodeUnspsc190501.50304506': __('identifierSchemeCodeUnspsc190501.50304506'),
	'identifierSchemeCodeUnspsc190501.50304507': __('identifierSchemeCodeUnspsc190501.50304507'),
	'identifierSchemeCodeUnspsc190501.50304508': __('identifierSchemeCodeUnspsc190501.50304508'),
	'identifierSchemeCodeUnspsc190501.50304509': __('identifierSchemeCodeUnspsc190501.50304509'),
	'identifierSchemeCodeUnspsc190501.50304510': __('identifierSchemeCodeUnspsc190501.50304510'),
	'identifierSchemeCodeUnspsc190501.50304511': __('identifierSchemeCodeUnspsc190501.50304511'),
	'identifierSchemeCodeUnspsc190501.50304512': __('identifierSchemeCodeUnspsc190501.50304512'),
	'identifierSchemeCodeUnspsc190501.50304513': __('identifierSchemeCodeUnspsc190501.50304513'),
	'identifierSchemeCodeUnspsc190501.50304514': __('identifierSchemeCodeUnspsc190501.50304514'),
	'identifierSchemeCodeUnspsc190501.50304515': __('identifierSchemeCodeUnspsc190501.50304515'),
	'identifierSchemeCodeUnspsc190501.50304516': __('identifierSchemeCodeUnspsc190501.50304516'),
	'identifierSchemeCodeUnspsc190501.50304517': __('identifierSchemeCodeUnspsc190501.50304517'),
	'identifierSchemeCodeUnspsc190501.50304518': __('identifierSchemeCodeUnspsc190501.50304518'),
	'identifierSchemeCodeUnspsc190501.50304519': __('identifierSchemeCodeUnspsc190501.50304519'),
	'identifierSchemeCodeUnspsc190501.50304520': __('identifierSchemeCodeUnspsc190501.50304520'),
	'identifierSchemeCodeUnspsc190501.50304521': __('identifierSchemeCodeUnspsc190501.50304521'),
	'identifierSchemeCodeUnspsc190501.50304522': __('identifierSchemeCodeUnspsc190501.50304522'),
	'identifierSchemeCodeUnspsc190501.50304523': __('identifierSchemeCodeUnspsc190501.50304523'),
	'identifierSchemeCodeUnspsc190501.50304524': __('identifierSchemeCodeUnspsc190501.50304524'),
	'identifierSchemeCodeUnspsc190501.50304525': __('identifierSchemeCodeUnspsc190501.50304525'),
	'identifierSchemeCodeUnspsc190501.50304600': __('identifierSchemeCodeUnspsc190501.50304600'),
	'identifierSchemeCodeUnspsc190501.50304601': __('identifierSchemeCodeUnspsc190501.50304601'),
	'identifierSchemeCodeUnspsc190501.50304602': __('identifierSchemeCodeUnspsc190501.50304602'),
	'identifierSchemeCodeUnspsc190501.50304603': __('identifierSchemeCodeUnspsc190501.50304603'),
	'identifierSchemeCodeUnspsc190501.50304604': __('identifierSchemeCodeUnspsc190501.50304604'),
	'identifierSchemeCodeUnspsc190501.50304605': __('identifierSchemeCodeUnspsc190501.50304605'),
	'identifierSchemeCodeUnspsc190501.50304606': __('identifierSchemeCodeUnspsc190501.50304606'),
	'identifierSchemeCodeUnspsc190501.50304607': __('identifierSchemeCodeUnspsc190501.50304607'),
	'identifierSchemeCodeUnspsc190501.50304608': __('identifierSchemeCodeUnspsc190501.50304608'),
	'identifierSchemeCodeUnspsc190501.50304609': __('identifierSchemeCodeUnspsc190501.50304609'),
	'identifierSchemeCodeUnspsc190501.50304610': __('identifierSchemeCodeUnspsc190501.50304610'),
	'identifierSchemeCodeUnspsc190501.50304611': __('identifierSchemeCodeUnspsc190501.50304611'),
	'identifierSchemeCodeUnspsc190501.50304612': __('identifierSchemeCodeUnspsc190501.50304612'),
	'identifierSchemeCodeUnspsc190501.50304613': __('identifierSchemeCodeUnspsc190501.50304613'),
	'identifierSchemeCodeUnspsc190501.50304614': __('identifierSchemeCodeUnspsc190501.50304614'),
	'identifierSchemeCodeUnspsc190501.50304615': __('identifierSchemeCodeUnspsc190501.50304615'),
	'identifierSchemeCodeUnspsc190501.50304616': __('identifierSchemeCodeUnspsc190501.50304616'),
	'identifierSchemeCodeUnspsc190501.50304617': __('identifierSchemeCodeUnspsc190501.50304617'),
	'identifierSchemeCodeUnspsc190501.50304618': __('identifierSchemeCodeUnspsc190501.50304618'),
	'identifierSchemeCodeUnspsc190501.50304619': __('identifierSchemeCodeUnspsc190501.50304619'),
	'identifierSchemeCodeUnspsc190501.50304620': __('identifierSchemeCodeUnspsc190501.50304620'),
	'identifierSchemeCodeUnspsc190501.50304621': __('identifierSchemeCodeUnspsc190501.50304621'),
	'identifierSchemeCodeUnspsc190501.50304622': __('identifierSchemeCodeUnspsc190501.50304622'),
	'identifierSchemeCodeUnspsc190501.50304623': __('identifierSchemeCodeUnspsc190501.50304623'),
	'identifierSchemeCodeUnspsc190501.50304624': __('identifierSchemeCodeUnspsc190501.50304624'),
	'identifierSchemeCodeUnspsc190501.50304625': __('identifierSchemeCodeUnspsc190501.50304625'),
	'identifierSchemeCodeUnspsc190501.50304626': __('identifierSchemeCodeUnspsc190501.50304626'),
	'identifierSchemeCodeUnspsc190501.50304627': __('identifierSchemeCodeUnspsc190501.50304627'),
	'identifierSchemeCodeUnspsc190501.50304628': __('identifierSchemeCodeUnspsc190501.50304628'),
	'identifierSchemeCodeUnspsc190501.50304629': __('identifierSchemeCodeUnspsc190501.50304629'),
	'identifierSchemeCodeUnspsc190501.50304630': __('identifierSchemeCodeUnspsc190501.50304630'),
	'identifierSchemeCodeUnspsc190501.50304631': __('identifierSchemeCodeUnspsc190501.50304631'),
	'identifierSchemeCodeUnspsc190501.50304632': __('identifierSchemeCodeUnspsc190501.50304632'),
	'identifierSchemeCodeUnspsc190501.50304633': __('identifierSchemeCodeUnspsc190501.50304633'),
	'identifierSchemeCodeUnspsc190501.50304634': __('identifierSchemeCodeUnspsc190501.50304634'),
	'identifierSchemeCodeUnspsc190501.50304635': __('identifierSchemeCodeUnspsc190501.50304635'),
	'identifierSchemeCodeUnspsc190501.50304636': __('identifierSchemeCodeUnspsc190501.50304636'),
	'identifierSchemeCodeUnspsc190501.50304637': __('identifierSchemeCodeUnspsc190501.50304637'),
	'identifierSchemeCodeUnspsc190501.50304638': __('identifierSchemeCodeUnspsc190501.50304638'),
	'identifierSchemeCodeUnspsc190501.50304639': __('identifierSchemeCodeUnspsc190501.50304639'),
	'identifierSchemeCodeUnspsc190501.50304640': __('identifierSchemeCodeUnspsc190501.50304640'),
	'identifierSchemeCodeUnspsc190501.50304641': __('identifierSchemeCodeUnspsc190501.50304641'),
	'identifierSchemeCodeUnspsc190501.50304642': __('identifierSchemeCodeUnspsc190501.50304642'),
	'identifierSchemeCodeUnspsc190501.50304643': __('identifierSchemeCodeUnspsc190501.50304643'),
	'identifierSchemeCodeUnspsc190501.50304644': __('identifierSchemeCodeUnspsc190501.50304644'),
	'identifierSchemeCodeUnspsc190501.50304645': __('identifierSchemeCodeUnspsc190501.50304645'),
	'identifierSchemeCodeUnspsc190501.50304646': __('identifierSchemeCodeUnspsc190501.50304646'),
	'identifierSchemeCodeUnspsc190501.50304647': __('identifierSchemeCodeUnspsc190501.50304647'),
	'identifierSchemeCodeUnspsc190501.50304648': __('identifierSchemeCodeUnspsc190501.50304648'),
	'identifierSchemeCodeUnspsc190501.50304649': __('identifierSchemeCodeUnspsc190501.50304649'),
	'identifierSchemeCodeUnspsc190501.50304650': __('identifierSchemeCodeUnspsc190501.50304650'),
	'identifierSchemeCodeUnspsc190501.50304651': __('identifierSchemeCodeUnspsc190501.50304651'),
	'identifierSchemeCodeUnspsc190501.50304652': __('identifierSchemeCodeUnspsc190501.50304652'),
	'identifierSchemeCodeUnspsc190501.50304653': __('identifierSchemeCodeUnspsc190501.50304653'),
	'identifierSchemeCodeUnspsc190501.50304654': __('identifierSchemeCodeUnspsc190501.50304654'),
	'identifierSchemeCodeUnspsc190501.50304655': __('identifierSchemeCodeUnspsc190501.50304655'),
	'identifierSchemeCodeUnspsc190501.50304656': __('identifierSchemeCodeUnspsc190501.50304656'),
	'identifierSchemeCodeUnspsc190501.50304657': __('identifierSchemeCodeUnspsc190501.50304657'),
	'identifierSchemeCodeUnspsc190501.50304658': __('identifierSchemeCodeUnspsc190501.50304658'),
	'identifierSchemeCodeUnspsc190501.50304659': __('identifierSchemeCodeUnspsc190501.50304659'),
	'identifierSchemeCodeUnspsc190501.50304660': __('identifierSchemeCodeUnspsc190501.50304660'),
	'identifierSchemeCodeUnspsc190501.50304661': __('identifierSchemeCodeUnspsc190501.50304661'),
	'identifierSchemeCodeUnspsc190501.50304700': __('identifierSchemeCodeUnspsc190501.50304700'),
	'identifierSchemeCodeUnspsc190501.50304701': __('identifierSchemeCodeUnspsc190501.50304701'),
	'identifierSchemeCodeUnspsc190501.50304702': __('identifierSchemeCodeUnspsc190501.50304702'),
	'identifierSchemeCodeUnspsc190501.50304800': __('identifierSchemeCodeUnspsc190501.50304800'),
	'identifierSchemeCodeUnspsc190501.50304801': __('identifierSchemeCodeUnspsc190501.50304801'),
	'identifierSchemeCodeUnspsc190501.50304802': __('identifierSchemeCodeUnspsc190501.50304802'),
	'identifierSchemeCodeUnspsc190501.50304900': __('identifierSchemeCodeUnspsc190501.50304900'),
	'identifierSchemeCodeUnspsc190501.50304901': __('identifierSchemeCodeUnspsc190501.50304901'),
	'identifierSchemeCodeUnspsc190501.50304902': __('identifierSchemeCodeUnspsc190501.50304902'),
	'identifierSchemeCodeUnspsc190501.50304903': __('identifierSchemeCodeUnspsc190501.50304903'),
	'identifierSchemeCodeUnspsc190501.50304904': __('identifierSchemeCodeUnspsc190501.50304904'),
	'identifierSchemeCodeUnspsc190501.50304905': __('identifierSchemeCodeUnspsc190501.50304905'),
	'identifierSchemeCodeUnspsc190501.50304906': __('identifierSchemeCodeUnspsc190501.50304906'),
	'identifierSchemeCodeUnspsc190501.50304907': __('identifierSchemeCodeUnspsc190501.50304907'),
	'identifierSchemeCodeUnspsc190501.50304908': __('identifierSchemeCodeUnspsc190501.50304908'),
	'identifierSchemeCodeUnspsc190501.50304909': __('identifierSchemeCodeUnspsc190501.50304909'),
	'identifierSchemeCodeUnspsc190501.50304910': __('identifierSchemeCodeUnspsc190501.50304910'),
	'identifierSchemeCodeUnspsc190501.50304911': __('identifierSchemeCodeUnspsc190501.50304911'),
	'identifierSchemeCodeUnspsc190501.50304912': __('identifierSchemeCodeUnspsc190501.50304912'),
	'identifierSchemeCodeUnspsc190501.50304913': __('identifierSchemeCodeUnspsc190501.50304913'),
	'identifierSchemeCodeUnspsc190501.50304914': __('identifierSchemeCodeUnspsc190501.50304914'),
	'identifierSchemeCodeUnspsc190501.50304915': __('identifierSchemeCodeUnspsc190501.50304915'),
	'identifierSchemeCodeUnspsc190501.50304916': __('identifierSchemeCodeUnspsc190501.50304916'),
	'identifierSchemeCodeUnspsc190501.50304917': __('identifierSchemeCodeUnspsc190501.50304917'),
	'identifierSchemeCodeUnspsc190501.50304918': __('identifierSchemeCodeUnspsc190501.50304918'),
	'identifierSchemeCodeUnspsc190501.50304919': __('identifierSchemeCodeUnspsc190501.50304919'),
	'identifierSchemeCodeUnspsc190501.50304920': __('identifierSchemeCodeUnspsc190501.50304920'),
	'identifierSchemeCodeUnspsc190501.50304921': __('identifierSchemeCodeUnspsc190501.50304921'),
	'identifierSchemeCodeUnspsc190501.50304922': __('identifierSchemeCodeUnspsc190501.50304922'),
	'identifierSchemeCodeUnspsc190501.50304923': __('identifierSchemeCodeUnspsc190501.50304923'),
	'identifierSchemeCodeUnspsc190501.50304924': __('identifierSchemeCodeUnspsc190501.50304924'),
	'identifierSchemeCodeUnspsc190501.50304925': __('identifierSchemeCodeUnspsc190501.50304925'),
	'identifierSchemeCodeUnspsc190501.50304926': __('identifierSchemeCodeUnspsc190501.50304926'),
	'identifierSchemeCodeUnspsc190501.50304927': __('identifierSchemeCodeUnspsc190501.50304927'),
	'identifierSchemeCodeUnspsc190501.50304928': __('identifierSchemeCodeUnspsc190501.50304928'),
	'identifierSchemeCodeUnspsc190501.50304929': __('identifierSchemeCodeUnspsc190501.50304929'),
	'identifierSchemeCodeUnspsc190501.50304930': __('identifierSchemeCodeUnspsc190501.50304930'),
	'identifierSchemeCodeUnspsc190501.50304931': __('identifierSchemeCodeUnspsc190501.50304931'),
	'identifierSchemeCodeUnspsc190501.50304932': __('identifierSchemeCodeUnspsc190501.50304932'),
	'identifierSchemeCodeUnspsc190501.50304933': __('identifierSchemeCodeUnspsc190501.50304933'),
	'identifierSchemeCodeUnspsc190501.50304934': __('identifierSchemeCodeUnspsc190501.50304934'),
	'identifierSchemeCodeUnspsc190501.50304935': __('identifierSchemeCodeUnspsc190501.50304935'),
	'identifierSchemeCodeUnspsc190501.50304936': __('identifierSchemeCodeUnspsc190501.50304936'),
	'identifierSchemeCodeUnspsc190501.50304937': __('identifierSchemeCodeUnspsc190501.50304937'),
	'identifierSchemeCodeUnspsc190501.50304938': __('identifierSchemeCodeUnspsc190501.50304938'),
	'identifierSchemeCodeUnspsc190501.50304939': __('identifierSchemeCodeUnspsc190501.50304939'),
	'identifierSchemeCodeUnspsc190501.50304940': __('identifierSchemeCodeUnspsc190501.50304940'),
	'identifierSchemeCodeUnspsc190501.50304941': __('identifierSchemeCodeUnspsc190501.50304941'),
	'identifierSchemeCodeUnspsc190501.50304942': __('identifierSchemeCodeUnspsc190501.50304942'),
	'identifierSchemeCodeUnspsc190501.50304943': __('identifierSchemeCodeUnspsc190501.50304943'),
	'identifierSchemeCodeUnspsc190501.50304944': __('identifierSchemeCodeUnspsc190501.50304944'),
	'identifierSchemeCodeUnspsc190501.50304945': __('identifierSchemeCodeUnspsc190501.50304945'),
	'identifierSchemeCodeUnspsc190501.50304946': __('identifierSchemeCodeUnspsc190501.50304946'),
	'identifierSchemeCodeUnspsc190501.50304947': __('identifierSchemeCodeUnspsc190501.50304947'),
	'identifierSchemeCodeUnspsc190501.50304948': __('identifierSchemeCodeUnspsc190501.50304948'),
	'identifierSchemeCodeUnspsc190501.50304949': __('identifierSchemeCodeUnspsc190501.50304949'),
	'identifierSchemeCodeUnspsc190501.50304950': __('identifierSchemeCodeUnspsc190501.50304950'),
	'identifierSchemeCodeUnspsc190501.50304951': __('identifierSchemeCodeUnspsc190501.50304951'),
	'identifierSchemeCodeUnspsc190501.50304952': __('identifierSchemeCodeUnspsc190501.50304952'),
	'identifierSchemeCodeUnspsc190501.50304953': __('identifierSchemeCodeUnspsc190501.50304953'),
	'identifierSchemeCodeUnspsc190501.50304954': __('identifierSchemeCodeUnspsc190501.50304954'),
	'identifierSchemeCodeUnspsc190501.50304955': __('identifierSchemeCodeUnspsc190501.50304955'),
	'identifierSchemeCodeUnspsc190501.50304956': __('identifierSchemeCodeUnspsc190501.50304956'),
	'identifierSchemeCodeUnspsc190501.50304957': __('identifierSchemeCodeUnspsc190501.50304957'),
	'identifierSchemeCodeUnspsc190501.50305000': __('identifierSchemeCodeUnspsc190501.50305000'),
	'identifierSchemeCodeUnspsc190501.50305001': __('identifierSchemeCodeUnspsc190501.50305001'),
	'identifierSchemeCodeUnspsc190501.50305002': __('identifierSchemeCodeUnspsc190501.50305002'),
	'identifierSchemeCodeUnspsc190501.50305003': __('identifierSchemeCodeUnspsc190501.50305003'),
	'identifierSchemeCodeUnspsc190501.50305004': __('identifierSchemeCodeUnspsc190501.50305004'),
	'identifierSchemeCodeUnspsc190501.50305005': __('identifierSchemeCodeUnspsc190501.50305005'),
	'identifierSchemeCodeUnspsc190501.50305006': __('identifierSchemeCodeUnspsc190501.50305006'),
	'identifierSchemeCodeUnspsc190501.50305007': __('identifierSchemeCodeUnspsc190501.50305007'),
	'identifierSchemeCodeUnspsc190501.50305008': __('identifierSchemeCodeUnspsc190501.50305008'),
	'identifierSchemeCodeUnspsc190501.50305009': __('identifierSchemeCodeUnspsc190501.50305009'),
	'identifierSchemeCodeUnspsc190501.50305010': __('identifierSchemeCodeUnspsc190501.50305010'),
	'identifierSchemeCodeUnspsc190501.50305011': __('identifierSchemeCodeUnspsc190501.50305011'),
	'identifierSchemeCodeUnspsc190501.50305012': __('identifierSchemeCodeUnspsc190501.50305012'),
	'identifierSchemeCodeUnspsc190501.50305013': __('identifierSchemeCodeUnspsc190501.50305013'),
	'identifierSchemeCodeUnspsc190501.50305014': __('identifierSchemeCodeUnspsc190501.50305014'),
	'identifierSchemeCodeUnspsc190501.50305015': __('identifierSchemeCodeUnspsc190501.50305015'),
	'identifierSchemeCodeUnspsc190501.50305016': __('identifierSchemeCodeUnspsc190501.50305016'),
	'identifierSchemeCodeUnspsc190501.50305017': __('identifierSchemeCodeUnspsc190501.50305017'),
	'identifierSchemeCodeUnspsc190501.50305018': __('identifierSchemeCodeUnspsc190501.50305018'),
	'identifierSchemeCodeUnspsc190501.50305019': __('identifierSchemeCodeUnspsc190501.50305019'),
	'identifierSchemeCodeUnspsc190501.50305020': __('identifierSchemeCodeUnspsc190501.50305020'),
	'identifierSchemeCodeUnspsc190501.50305021': __('identifierSchemeCodeUnspsc190501.50305021'),
	'identifierSchemeCodeUnspsc190501.50305022': __('identifierSchemeCodeUnspsc190501.50305022'),
	'identifierSchemeCodeUnspsc190501.50305023': __('identifierSchemeCodeUnspsc190501.50305023'),
	'identifierSchemeCodeUnspsc190501.50305024': __('identifierSchemeCodeUnspsc190501.50305024'),
	'identifierSchemeCodeUnspsc190501.50305025': __('identifierSchemeCodeUnspsc190501.50305025'),
	'identifierSchemeCodeUnspsc190501.50305026': __('identifierSchemeCodeUnspsc190501.50305026'),
	'identifierSchemeCodeUnspsc190501.50305027': __('identifierSchemeCodeUnspsc190501.50305027'),
	'identifierSchemeCodeUnspsc190501.50305028': __('identifierSchemeCodeUnspsc190501.50305028'),
	'identifierSchemeCodeUnspsc190501.50305029': __('identifierSchemeCodeUnspsc190501.50305029'),
	'identifierSchemeCodeUnspsc190501.50305030': __('identifierSchemeCodeUnspsc190501.50305030'),
	'identifierSchemeCodeUnspsc190501.50305031': __('identifierSchemeCodeUnspsc190501.50305031'),
	'identifierSchemeCodeUnspsc190501.50305032': __('identifierSchemeCodeUnspsc190501.50305032'),
	'identifierSchemeCodeUnspsc190501.50305033': __('identifierSchemeCodeUnspsc190501.50305033'),
	'identifierSchemeCodeUnspsc190501.50305034': __('identifierSchemeCodeUnspsc190501.50305034'),
	'identifierSchemeCodeUnspsc190501.50305035': __('identifierSchemeCodeUnspsc190501.50305035'),
	'identifierSchemeCodeUnspsc190501.50305036': __('identifierSchemeCodeUnspsc190501.50305036'),
	'identifierSchemeCodeUnspsc190501.50305037': __('identifierSchemeCodeUnspsc190501.50305037'),
	'identifierSchemeCodeUnspsc190501.50305038': __('identifierSchemeCodeUnspsc190501.50305038'),
	'identifierSchemeCodeUnspsc190501.50305039': __('identifierSchemeCodeUnspsc190501.50305039'),
	'identifierSchemeCodeUnspsc190501.50305040': __('identifierSchemeCodeUnspsc190501.50305040'),
	'identifierSchemeCodeUnspsc190501.50305041': __('identifierSchemeCodeUnspsc190501.50305041'),
	'identifierSchemeCodeUnspsc190501.50305042': __('identifierSchemeCodeUnspsc190501.50305042'),
	'identifierSchemeCodeUnspsc190501.50305043': __('identifierSchemeCodeUnspsc190501.50305043'),
	'identifierSchemeCodeUnspsc190501.50305044': __('identifierSchemeCodeUnspsc190501.50305044'),
	'identifierSchemeCodeUnspsc190501.50305100': __('identifierSchemeCodeUnspsc190501.50305100'),
	'identifierSchemeCodeUnspsc190501.50305101': __('identifierSchemeCodeUnspsc190501.50305101'),
	'identifierSchemeCodeUnspsc190501.50305102': __('identifierSchemeCodeUnspsc190501.50305102'),
	'identifierSchemeCodeUnspsc190501.50305103': __('identifierSchemeCodeUnspsc190501.50305103'),
	'identifierSchemeCodeUnspsc190501.50305104': __('identifierSchemeCodeUnspsc190501.50305104'),
	'identifierSchemeCodeUnspsc190501.50305105': __('identifierSchemeCodeUnspsc190501.50305105'),
	'identifierSchemeCodeUnspsc190501.50305106': __('identifierSchemeCodeUnspsc190501.50305106'),
	'identifierSchemeCodeUnspsc190501.50305107': __('identifierSchemeCodeUnspsc190501.50305107'),
	'identifierSchemeCodeUnspsc190501.50305200': __('identifierSchemeCodeUnspsc190501.50305200'),
	'identifierSchemeCodeUnspsc190501.50305201': __('identifierSchemeCodeUnspsc190501.50305201'),
	'identifierSchemeCodeUnspsc190501.50305202': __('identifierSchemeCodeUnspsc190501.50305202'),
	'identifierSchemeCodeUnspsc190501.50305203': __('identifierSchemeCodeUnspsc190501.50305203'),
	'identifierSchemeCodeUnspsc190501.50305204': __('identifierSchemeCodeUnspsc190501.50305204'),
	'identifierSchemeCodeUnspsc190501.50305205': __('identifierSchemeCodeUnspsc190501.50305205'),
	'identifierSchemeCodeUnspsc190501.50305206': __('identifierSchemeCodeUnspsc190501.50305206'),
	'identifierSchemeCodeUnspsc190501.50305207': __('identifierSchemeCodeUnspsc190501.50305207'),
	'identifierSchemeCodeUnspsc190501.50305208': __('identifierSchemeCodeUnspsc190501.50305208'),
	'identifierSchemeCodeUnspsc190501.50305209': __('identifierSchemeCodeUnspsc190501.50305209'),
	'identifierSchemeCodeUnspsc190501.50305210': __('identifierSchemeCodeUnspsc190501.50305210'),
	'identifierSchemeCodeUnspsc190501.50305300': __('identifierSchemeCodeUnspsc190501.50305300'),
	'identifierSchemeCodeUnspsc190501.50305301': __('identifierSchemeCodeUnspsc190501.50305301'),
	'identifierSchemeCodeUnspsc190501.50305302': __('identifierSchemeCodeUnspsc190501.50305302'),
	'identifierSchemeCodeUnspsc190501.50305303': __('identifierSchemeCodeUnspsc190501.50305303'),
	'identifierSchemeCodeUnspsc190501.50305304': __('identifierSchemeCodeUnspsc190501.50305304'),
	'identifierSchemeCodeUnspsc190501.50305305': __('identifierSchemeCodeUnspsc190501.50305305'),
	'identifierSchemeCodeUnspsc190501.50305306': __('identifierSchemeCodeUnspsc190501.50305306'),
	'identifierSchemeCodeUnspsc190501.50305307': __('identifierSchemeCodeUnspsc190501.50305307'),
	'identifierSchemeCodeUnspsc190501.50305308': __('identifierSchemeCodeUnspsc190501.50305308'),
	'identifierSchemeCodeUnspsc190501.50305309': __('identifierSchemeCodeUnspsc190501.50305309'),
	'identifierSchemeCodeUnspsc190501.50305310': __('identifierSchemeCodeUnspsc190501.50305310'),
	'identifierSchemeCodeUnspsc190501.50305311': __('identifierSchemeCodeUnspsc190501.50305311'),
	'identifierSchemeCodeUnspsc190501.50305312': __('identifierSchemeCodeUnspsc190501.50305312'),
	'identifierSchemeCodeUnspsc190501.50305313': __('identifierSchemeCodeUnspsc190501.50305313'),
	'identifierSchemeCodeUnspsc190501.50305314': __('identifierSchemeCodeUnspsc190501.50305314'),
	'identifierSchemeCodeUnspsc190501.50305315': __('identifierSchemeCodeUnspsc190501.50305315'),
	'identifierSchemeCodeUnspsc190501.50305316': __('identifierSchemeCodeUnspsc190501.50305316'),
	'identifierSchemeCodeUnspsc190501.50305317': __('identifierSchemeCodeUnspsc190501.50305317'),
	'identifierSchemeCodeUnspsc190501.50305318': __('identifierSchemeCodeUnspsc190501.50305318'),
	'identifierSchemeCodeUnspsc190501.50305319': __('identifierSchemeCodeUnspsc190501.50305319'),
	'identifierSchemeCodeUnspsc190501.50305320': __('identifierSchemeCodeUnspsc190501.50305320'),
	'identifierSchemeCodeUnspsc190501.50305321': __('identifierSchemeCodeUnspsc190501.50305321'),
	'identifierSchemeCodeUnspsc190501.50305322': __('identifierSchemeCodeUnspsc190501.50305322'),
	'identifierSchemeCodeUnspsc190501.50305323': __('identifierSchemeCodeUnspsc190501.50305323'),
	'identifierSchemeCodeUnspsc190501.50305324': __('identifierSchemeCodeUnspsc190501.50305324'),
	'identifierSchemeCodeUnspsc190501.50305325': __('identifierSchemeCodeUnspsc190501.50305325'),
	'identifierSchemeCodeUnspsc190501.50305326': __('identifierSchemeCodeUnspsc190501.50305326'),
	'identifierSchemeCodeUnspsc190501.50305327': __('identifierSchemeCodeUnspsc190501.50305327'),
	'identifierSchemeCodeUnspsc190501.50305328': __('identifierSchemeCodeUnspsc190501.50305328'),
	'identifierSchemeCodeUnspsc190501.50305329': __('identifierSchemeCodeUnspsc190501.50305329'),
	'identifierSchemeCodeUnspsc190501.50305330': __('identifierSchemeCodeUnspsc190501.50305330'),
	'identifierSchemeCodeUnspsc190501.50305331': __('identifierSchemeCodeUnspsc190501.50305331'),
	'identifierSchemeCodeUnspsc190501.50305332': __('identifierSchemeCodeUnspsc190501.50305332'),
	'identifierSchemeCodeUnspsc190501.50305333': __('identifierSchemeCodeUnspsc190501.50305333'),
	'identifierSchemeCodeUnspsc190501.50305334': __('identifierSchemeCodeUnspsc190501.50305334'),
	'identifierSchemeCodeUnspsc190501.50305335': __('identifierSchemeCodeUnspsc190501.50305335'),
	'identifierSchemeCodeUnspsc190501.50305336': __('identifierSchemeCodeUnspsc190501.50305336'),
	'identifierSchemeCodeUnspsc190501.50305337': __('identifierSchemeCodeUnspsc190501.50305337'),
	'identifierSchemeCodeUnspsc190501.50305338': __('identifierSchemeCodeUnspsc190501.50305338'),
	'identifierSchemeCodeUnspsc190501.50305339': __('identifierSchemeCodeUnspsc190501.50305339'),
	'identifierSchemeCodeUnspsc190501.50305340': __('identifierSchemeCodeUnspsc190501.50305340'),
	'identifierSchemeCodeUnspsc190501.50305341': __('identifierSchemeCodeUnspsc190501.50305341'),
	'identifierSchemeCodeUnspsc190501.50305342': __('identifierSchemeCodeUnspsc190501.50305342'),
	'identifierSchemeCodeUnspsc190501.50305343': __('identifierSchemeCodeUnspsc190501.50305343'),
	'identifierSchemeCodeUnspsc190501.50305344': __('identifierSchemeCodeUnspsc190501.50305344'),
	'identifierSchemeCodeUnspsc190501.50305345': __('identifierSchemeCodeUnspsc190501.50305345'),
	'identifierSchemeCodeUnspsc190501.50305346': __('identifierSchemeCodeUnspsc190501.50305346'),
	'identifierSchemeCodeUnspsc190501.50305347': __('identifierSchemeCodeUnspsc190501.50305347'),
	'identifierSchemeCodeUnspsc190501.50305348': __('identifierSchemeCodeUnspsc190501.50305348'),
	'identifierSchemeCodeUnspsc190501.50305349': __('identifierSchemeCodeUnspsc190501.50305349'),
	'identifierSchemeCodeUnspsc190501.50305350': __('identifierSchemeCodeUnspsc190501.50305350'),
	'identifierSchemeCodeUnspsc190501.50305351': __('identifierSchemeCodeUnspsc190501.50305351'),
	'identifierSchemeCodeUnspsc190501.50305352': __('identifierSchemeCodeUnspsc190501.50305352'),
	'identifierSchemeCodeUnspsc190501.50305353': __('identifierSchemeCodeUnspsc190501.50305353'),
	'identifierSchemeCodeUnspsc190501.50305354': __('identifierSchemeCodeUnspsc190501.50305354'),
	'identifierSchemeCodeUnspsc190501.50305355': __('identifierSchemeCodeUnspsc190501.50305355'),
	'identifierSchemeCodeUnspsc190501.50305356': __('identifierSchemeCodeUnspsc190501.50305356'),
	'identifierSchemeCodeUnspsc190501.50305357': __('identifierSchemeCodeUnspsc190501.50305357'),
	'identifierSchemeCodeUnspsc190501.50305358': __('identifierSchemeCodeUnspsc190501.50305358'),
	'identifierSchemeCodeUnspsc190501.50305359': __('identifierSchemeCodeUnspsc190501.50305359'),
	'identifierSchemeCodeUnspsc190501.50305360': __('identifierSchemeCodeUnspsc190501.50305360'),
	'identifierSchemeCodeUnspsc190501.50305361': __('identifierSchemeCodeUnspsc190501.50305361'),
	'identifierSchemeCodeUnspsc190501.50305362': __('identifierSchemeCodeUnspsc190501.50305362'),
	'identifierSchemeCodeUnspsc190501.50305363': __('identifierSchemeCodeUnspsc190501.50305363'),
	'identifierSchemeCodeUnspsc190501.50305364': __('identifierSchemeCodeUnspsc190501.50305364'),
	'identifierSchemeCodeUnspsc190501.50305365': __('identifierSchemeCodeUnspsc190501.50305365'),
	'identifierSchemeCodeUnspsc190501.50305366': __('identifierSchemeCodeUnspsc190501.50305366'),
	'identifierSchemeCodeUnspsc190501.50305367': __('identifierSchemeCodeUnspsc190501.50305367'),
	'identifierSchemeCodeUnspsc190501.50305368': __('identifierSchemeCodeUnspsc190501.50305368'),
	'identifierSchemeCodeUnspsc190501.50305369': __('identifierSchemeCodeUnspsc190501.50305369'),
	'identifierSchemeCodeUnspsc190501.50305370': __('identifierSchemeCodeUnspsc190501.50305370'),
	'identifierSchemeCodeUnspsc190501.50305371': __('identifierSchemeCodeUnspsc190501.50305371'),
	'identifierSchemeCodeUnspsc190501.50305400': __('identifierSchemeCodeUnspsc190501.50305400'),
	'identifierSchemeCodeUnspsc190501.50305401': __('identifierSchemeCodeUnspsc190501.50305401'),
	'identifierSchemeCodeUnspsc190501.50305402': __('identifierSchemeCodeUnspsc190501.50305402'),
	'identifierSchemeCodeUnspsc190501.50305403': __('identifierSchemeCodeUnspsc190501.50305403'),
	'identifierSchemeCodeUnspsc190501.50305404': __('identifierSchemeCodeUnspsc190501.50305404'),
	'identifierSchemeCodeUnspsc190501.50305405': __('identifierSchemeCodeUnspsc190501.50305405'),
	'identifierSchemeCodeUnspsc190501.50305406': __('identifierSchemeCodeUnspsc190501.50305406'),
	'identifierSchemeCodeUnspsc190501.50305407': __('identifierSchemeCodeUnspsc190501.50305407'),
	'identifierSchemeCodeUnspsc190501.50305408': __('identifierSchemeCodeUnspsc190501.50305408'),
	'identifierSchemeCodeUnspsc190501.50305409': __('identifierSchemeCodeUnspsc190501.50305409'),
	'identifierSchemeCodeUnspsc190501.50305410': __('identifierSchemeCodeUnspsc190501.50305410'),
	'identifierSchemeCodeUnspsc190501.50305411': __('identifierSchemeCodeUnspsc190501.50305411'),
	'identifierSchemeCodeUnspsc190501.50305412': __('identifierSchemeCodeUnspsc190501.50305412'),
	'identifierSchemeCodeUnspsc190501.50305413': __('identifierSchemeCodeUnspsc190501.50305413'),
	'identifierSchemeCodeUnspsc190501.50305414': __('identifierSchemeCodeUnspsc190501.50305414'),
	'identifierSchemeCodeUnspsc190501.50305415': __('identifierSchemeCodeUnspsc190501.50305415'),
	'identifierSchemeCodeUnspsc190501.50305416': __('identifierSchemeCodeUnspsc190501.50305416'),
	'identifierSchemeCodeUnspsc190501.50305417': __('identifierSchemeCodeUnspsc190501.50305417'),
	'identifierSchemeCodeUnspsc190501.50305418': __('identifierSchemeCodeUnspsc190501.50305418'),
	'identifierSchemeCodeUnspsc190501.50305419': __('identifierSchemeCodeUnspsc190501.50305419'),
	'identifierSchemeCodeUnspsc190501.50305420': __('identifierSchemeCodeUnspsc190501.50305420'),
	'identifierSchemeCodeUnspsc190501.50305421': __('identifierSchemeCodeUnspsc190501.50305421'),
	'identifierSchemeCodeUnspsc190501.50305422': __('identifierSchemeCodeUnspsc190501.50305422'),
	'identifierSchemeCodeUnspsc190501.50305423': __('identifierSchemeCodeUnspsc190501.50305423'),
	'identifierSchemeCodeUnspsc190501.50305424': __('identifierSchemeCodeUnspsc190501.50305424'),
	'identifierSchemeCodeUnspsc190501.50305425': __('identifierSchemeCodeUnspsc190501.50305425'),
	'identifierSchemeCodeUnspsc190501.50305426': __('identifierSchemeCodeUnspsc190501.50305426'),
	'identifierSchemeCodeUnspsc190501.50305427': __('identifierSchemeCodeUnspsc190501.50305427'),
	'identifierSchemeCodeUnspsc190501.50305428': __('identifierSchemeCodeUnspsc190501.50305428'),
	'identifierSchemeCodeUnspsc190501.50305429': __('identifierSchemeCodeUnspsc190501.50305429'),
	'identifierSchemeCodeUnspsc190501.50305430': __('identifierSchemeCodeUnspsc190501.50305430'),
	'identifierSchemeCodeUnspsc190501.50305431': __('identifierSchemeCodeUnspsc190501.50305431'),
	'identifierSchemeCodeUnspsc190501.50305432': __('identifierSchemeCodeUnspsc190501.50305432'),
	'identifierSchemeCodeUnspsc190501.50305433': __('identifierSchemeCodeUnspsc190501.50305433'),
	'identifierSchemeCodeUnspsc190501.50305434': __('identifierSchemeCodeUnspsc190501.50305434'),
	'identifierSchemeCodeUnspsc190501.50305435': __('identifierSchemeCodeUnspsc190501.50305435'),
	'identifierSchemeCodeUnspsc190501.50305436': __('identifierSchemeCodeUnspsc190501.50305436'),
	'identifierSchemeCodeUnspsc190501.50305437': __('identifierSchemeCodeUnspsc190501.50305437'),
	'identifierSchemeCodeUnspsc190501.50305438': __('identifierSchemeCodeUnspsc190501.50305438'),
	'identifierSchemeCodeUnspsc190501.50305439': __('identifierSchemeCodeUnspsc190501.50305439'),
	'identifierSchemeCodeUnspsc190501.50305440': __('identifierSchemeCodeUnspsc190501.50305440'),
	'identifierSchemeCodeUnspsc190501.50305441': __('identifierSchemeCodeUnspsc190501.50305441'),
	'identifierSchemeCodeUnspsc190501.50305442': __('identifierSchemeCodeUnspsc190501.50305442'),
	'identifierSchemeCodeUnspsc190501.50305443': __('identifierSchemeCodeUnspsc190501.50305443'),
	'identifierSchemeCodeUnspsc190501.50305444': __('identifierSchemeCodeUnspsc190501.50305444'),
	'identifierSchemeCodeUnspsc190501.50305445': __('identifierSchemeCodeUnspsc190501.50305445'),
	'identifierSchemeCodeUnspsc190501.50305446': __('identifierSchemeCodeUnspsc190501.50305446'),
	'identifierSchemeCodeUnspsc190501.50305447': __('identifierSchemeCodeUnspsc190501.50305447'),
	'identifierSchemeCodeUnspsc190501.50305448': __('identifierSchemeCodeUnspsc190501.50305448'),
	'identifierSchemeCodeUnspsc190501.50305449': __('identifierSchemeCodeUnspsc190501.50305449'),
	'identifierSchemeCodeUnspsc190501.50305450': __('identifierSchemeCodeUnspsc190501.50305450'),
	'identifierSchemeCodeUnspsc190501.50305451': __('identifierSchemeCodeUnspsc190501.50305451'),
	'identifierSchemeCodeUnspsc190501.50305452': __('identifierSchemeCodeUnspsc190501.50305452'),
	'identifierSchemeCodeUnspsc190501.50305453': __('identifierSchemeCodeUnspsc190501.50305453'),
	'identifierSchemeCodeUnspsc190501.50305454': __('identifierSchemeCodeUnspsc190501.50305454'),
	'identifierSchemeCodeUnspsc190501.50305455': __('identifierSchemeCodeUnspsc190501.50305455'),
	'identifierSchemeCodeUnspsc190501.50305456': __('identifierSchemeCodeUnspsc190501.50305456'),
	'identifierSchemeCodeUnspsc190501.50305457': __('identifierSchemeCodeUnspsc190501.50305457'),
	'identifierSchemeCodeUnspsc190501.50305458': __('identifierSchemeCodeUnspsc190501.50305458'),
	'identifierSchemeCodeUnspsc190501.50305459': __('identifierSchemeCodeUnspsc190501.50305459'),
	'identifierSchemeCodeUnspsc190501.50305460': __('identifierSchemeCodeUnspsc190501.50305460'),
	'identifierSchemeCodeUnspsc190501.50305500': __('identifierSchemeCodeUnspsc190501.50305500'),
	'identifierSchemeCodeUnspsc190501.50305501': __('identifierSchemeCodeUnspsc190501.50305501'),
	'identifierSchemeCodeUnspsc190501.50305502': __('identifierSchemeCodeUnspsc190501.50305502'),
	'identifierSchemeCodeUnspsc190501.50305503': __('identifierSchemeCodeUnspsc190501.50305503'),
	'identifierSchemeCodeUnspsc190501.50305504': __('identifierSchemeCodeUnspsc190501.50305504'),
	'identifierSchemeCodeUnspsc190501.50305505': __('identifierSchemeCodeUnspsc190501.50305505'),
	'identifierSchemeCodeUnspsc190501.50305506': __('identifierSchemeCodeUnspsc190501.50305506'),
	'identifierSchemeCodeUnspsc190501.50305507': __('identifierSchemeCodeUnspsc190501.50305507'),
	'identifierSchemeCodeUnspsc190501.50305508': __('identifierSchemeCodeUnspsc190501.50305508'),
	'identifierSchemeCodeUnspsc190501.50305509': __('identifierSchemeCodeUnspsc190501.50305509'),
	'identifierSchemeCodeUnspsc190501.50305510': __('identifierSchemeCodeUnspsc190501.50305510'),
	'identifierSchemeCodeUnspsc190501.50305511': __('identifierSchemeCodeUnspsc190501.50305511'),
	'identifierSchemeCodeUnspsc190501.50305512': __('identifierSchemeCodeUnspsc190501.50305512'),
	'identifierSchemeCodeUnspsc190501.50305600': __('identifierSchemeCodeUnspsc190501.50305600'),
	'identifierSchemeCodeUnspsc190501.50305601': __('identifierSchemeCodeUnspsc190501.50305601'),
	'identifierSchemeCodeUnspsc190501.50305602': __('identifierSchemeCodeUnspsc190501.50305602'),
	'identifierSchemeCodeUnspsc190501.50305603': __('identifierSchemeCodeUnspsc190501.50305603'),
	'identifierSchemeCodeUnspsc190501.50305604': __('identifierSchemeCodeUnspsc190501.50305604'),
	'identifierSchemeCodeUnspsc190501.50305605': __('identifierSchemeCodeUnspsc190501.50305605'),
	'identifierSchemeCodeUnspsc190501.50305606': __('identifierSchemeCodeUnspsc190501.50305606'),
	'identifierSchemeCodeUnspsc190501.50305607': __('identifierSchemeCodeUnspsc190501.50305607'),
	'identifierSchemeCodeUnspsc190501.50305608': __('identifierSchemeCodeUnspsc190501.50305608'),
	'identifierSchemeCodeUnspsc190501.50305609': __('identifierSchemeCodeUnspsc190501.50305609'),
	'identifierSchemeCodeUnspsc190501.50305610': __('identifierSchemeCodeUnspsc190501.50305610'),
	'identifierSchemeCodeUnspsc190501.50305700': __('identifierSchemeCodeUnspsc190501.50305700'),
	'identifierSchemeCodeUnspsc190501.50305701': __('identifierSchemeCodeUnspsc190501.50305701'),
	'identifierSchemeCodeUnspsc190501.50305702': __('identifierSchemeCodeUnspsc190501.50305702'),
	'identifierSchemeCodeUnspsc190501.50305703': __('identifierSchemeCodeUnspsc190501.50305703'),
	'identifierSchemeCodeUnspsc190501.50305704': __('identifierSchemeCodeUnspsc190501.50305704'),
	'identifierSchemeCodeUnspsc190501.50305705': __('identifierSchemeCodeUnspsc190501.50305705'),
	'identifierSchemeCodeUnspsc190501.50305706': __('identifierSchemeCodeUnspsc190501.50305706'),
	'identifierSchemeCodeUnspsc190501.50305707': __('identifierSchemeCodeUnspsc190501.50305707'),
	'identifierSchemeCodeUnspsc190501.50305708': __('identifierSchemeCodeUnspsc190501.50305708'),
	'identifierSchemeCodeUnspsc190501.50305709': __('identifierSchemeCodeUnspsc190501.50305709'),
	'identifierSchemeCodeUnspsc190501.50305710': __('identifierSchemeCodeUnspsc190501.50305710'),
	'identifierSchemeCodeUnspsc190501.50305711': __('identifierSchemeCodeUnspsc190501.50305711'),
	'identifierSchemeCodeUnspsc190501.50305712': __('identifierSchemeCodeUnspsc190501.50305712'),
	'identifierSchemeCodeUnspsc190501.50305713': __('identifierSchemeCodeUnspsc190501.50305713'),
	'identifierSchemeCodeUnspsc190501.50305714': __('identifierSchemeCodeUnspsc190501.50305714'),
	'identifierSchemeCodeUnspsc190501.50305715': __('identifierSchemeCodeUnspsc190501.50305715'),
	'identifierSchemeCodeUnspsc190501.50305716': __('identifierSchemeCodeUnspsc190501.50305716'),
	'identifierSchemeCodeUnspsc190501.50305717': __('identifierSchemeCodeUnspsc190501.50305717'),
	'identifierSchemeCodeUnspsc190501.50305718': __('identifierSchemeCodeUnspsc190501.50305718'),
	'identifierSchemeCodeUnspsc190501.50305719': __('identifierSchemeCodeUnspsc190501.50305719'),
	'identifierSchemeCodeUnspsc190501.50305720': __('identifierSchemeCodeUnspsc190501.50305720'),
	'identifierSchemeCodeUnspsc190501.50305721': __('identifierSchemeCodeUnspsc190501.50305721'),
	'identifierSchemeCodeUnspsc190501.50305722': __('identifierSchemeCodeUnspsc190501.50305722'),
	'identifierSchemeCodeUnspsc190501.50305723': __('identifierSchemeCodeUnspsc190501.50305723'),
	'identifierSchemeCodeUnspsc190501.50305800': __('identifierSchemeCodeUnspsc190501.50305800'),
	'identifierSchemeCodeUnspsc190501.50305801': __('identifierSchemeCodeUnspsc190501.50305801'),
	'identifierSchemeCodeUnspsc190501.50305802': __('identifierSchemeCodeUnspsc190501.50305802'),
	'identifierSchemeCodeUnspsc190501.50305803': __('identifierSchemeCodeUnspsc190501.50305803'),
	'identifierSchemeCodeUnspsc190501.50305804': __('identifierSchemeCodeUnspsc190501.50305804'),
	'identifierSchemeCodeUnspsc190501.50305805': __('identifierSchemeCodeUnspsc190501.50305805'),
	'identifierSchemeCodeUnspsc190501.50305806': __('identifierSchemeCodeUnspsc190501.50305806'),
	'identifierSchemeCodeUnspsc190501.50305807': __('identifierSchemeCodeUnspsc190501.50305807'),
	'identifierSchemeCodeUnspsc190501.50305808': __('identifierSchemeCodeUnspsc190501.50305808'),
	'identifierSchemeCodeUnspsc190501.50305809': __('identifierSchemeCodeUnspsc190501.50305809'),
	'identifierSchemeCodeUnspsc190501.50305810': __('identifierSchemeCodeUnspsc190501.50305810'),
	'identifierSchemeCodeUnspsc190501.50305811': __('identifierSchemeCodeUnspsc190501.50305811'),
	'identifierSchemeCodeUnspsc190501.50305812': __('identifierSchemeCodeUnspsc190501.50305812'),
	'identifierSchemeCodeUnspsc190501.50305813': __('identifierSchemeCodeUnspsc190501.50305813'),
	'identifierSchemeCodeUnspsc190501.50305814': __('identifierSchemeCodeUnspsc190501.50305814'),
	'identifierSchemeCodeUnspsc190501.50305815': __('identifierSchemeCodeUnspsc190501.50305815'),
	'identifierSchemeCodeUnspsc190501.50305816': __('identifierSchemeCodeUnspsc190501.50305816'),
	'identifierSchemeCodeUnspsc190501.50305817': __('identifierSchemeCodeUnspsc190501.50305817'),
	'identifierSchemeCodeUnspsc190501.50305818': __('identifierSchemeCodeUnspsc190501.50305818'),
	'identifierSchemeCodeUnspsc190501.50305819': __('identifierSchemeCodeUnspsc190501.50305819'),
	'identifierSchemeCodeUnspsc190501.50305820': __('identifierSchemeCodeUnspsc190501.50305820'),
	'identifierSchemeCodeUnspsc190501.50305821': __('identifierSchemeCodeUnspsc190501.50305821'),
	'identifierSchemeCodeUnspsc190501.50305822': __('identifierSchemeCodeUnspsc190501.50305822'),
	'identifierSchemeCodeUnspsc190501.50305823': __('identifierSchemeCodeUnspsc190501.50305823'),
	'identifierSchemeCodeUnspsc190501.50305824': __('identifierSchemeCodeUnspsc190501.50305824'),
	'identifierSchemeCodeUnspsc190501.50305825': __('identifierSchemeCodeUnspsc190501.50305825'),
	'identifierSchemeCodeUnspsc190501.50305826': __('identifierSchemeCodeUnspsc190501.50305826'),
	'identifierSchemeCodeUnspsc190501.50305827': __('identifierSchemeCodeUnspsc190501.50305827'),
	'identifierSchemeCodeUnspsc190501.50305828': __('identifierSchemeCodeUnspsc190501.50305828'),
	'identifierSchemeCodeUnspsc190501.50305829': __('identifierSchemeCodeUnspsc190501.50305829'),
	'identifierSchemeCodeUnspsc190501.50305830': __('identifierSchemeCodeUnspsc190501.50305830'),
	'identifierSchemeCodeUnspsc190501.50305831': __('identifierSchemeCodeUnspsc190501.50305831'),
	'identifierSchemeCodeUnspsc190501.50305832': __('identifierSchemeCodeUnspsc190501.50305832'),
	'identifierSchemeCodeUnspsc190501.50305833': __('identifierSchemeCodeUnspsc190501.50305833'),
	'identifierSchemeCodeUnspsc190501.50305834': __('identifierSchemeCodeUnspsc190501.50305834'),
	'identifierSchemeCodeUnspsc190501.50305835': __('identifierSchemeCodeUnspsc190501.50305835'),
	'identifierSchemeCodeUnspsc190501.50305836': __('identifierSchemeCodeUnspsc190501.50305836'),
	'identifierSchemeCodeUnspsc190501.50305837': __('identifierSchemeCodeUnspsc190501.50305837'),
	'identifierSchemeCodeUnspsc190501.50305838': __('identifierSchemeCodeUnspsc190501.50305838'),
	'identifierSchemeCodeUnspsc190501.50305839': __('identifierSchemeCodeUnspsc190501.50305839'),
	'identifierSchemeCodeUnspsc190501.50305840': __('identifierSchemeCodeUnspsc190501.50305840'),
	'identifierSchemeCodeUnspsc190501.50305841': __('identifierSchemeCodeUnspsc190501.50305841'),
	'identifierSchemeCodeUnspsc190501.50305842': __('identifierSchemeCodeUnspsc190501.50305842'),
	'identifierSchemeCodeUnspsc190501.50305843': __('identifierSchemeCodeUnspsc190501.50305843'),
	'identifierSchemeCodeUnspsc190501.50305844': __('identifierSchemeCodeUnspsc190501.50305844'),
	'identifierSchemeCodeUnspsc190501.50305845': __('identifierSchemeCodeUnspsc190501.50305845'),
	'identifierSchemeCodeUnspsc190501.50305846': __('identifierSchemeCodeUnspsc190501.50305846'),
	'identifierSchemeCodeUnspsc190501.50305847': __('identifierSchemeCodeUnspsc190501.50305847'),
	'identifierSchemeCodeUnspsc190501.50305848': __('identifierSchemeCodeUnspsc190501.50305848'),
	'identifierSchemeCodeUnspsc190501.50305849': __('identifierSchemeCodeUnspsc190501.50305849'),
	'identifierSchemeCodeUnspsc190501.50305850': __('identifierSchemeCodeUnspsc190501.50305850'),
	'identifierSchemeCodeUnspsc190501.50305851': __('identifierSchemeCodeUnspsc190501.50305851'),
	'identifierSchemeCodeUnspsc190501.50305852': __('identifierSchemeCodeUnspsc190501.50305852'),
	'identifierSchemeCodeUnspsc190501.50305853': __('identifierSchemeCodeUnspsc190501.50305853'),
	'identifierSchemeCodeUnspsc190501.50305854': __('identifierSchemeCodeUnspsc190501.50305854'),
	'identifierSchemeCodeUnspsc190501.50305855': __('identifierSchemeCodeUnspsc190501.50305855'),
	'identifierSchemeCodeUnspsc190501.50305856': __('identifierSchemeCodeUnspsc190501.50305856'),
	'identifierSchemeCodeUnspsc190501.50305857': __('identifierSchemeCodeUnspsc190501.50305857'),
	'identifierSchemeCodeUnspsc190501.50305858': __('identifierSchemeCodeUnspsc190501.50305858'),
	'identifierSchemeCodeUnspsc190501.50305859': __('identifierSchemeCodeUnspsc190501.50305859'),
	'identifierSchemeCodeUnspsc190501.50305860': __('identifierSchemeCodeUnspsc190501.50305860'),
	'identifierSchemeCodeUnspsc190501.50305861': __('identifierSchemeCodeUnspsc190501.50305861'),
	'identifierSchemeCodeUnspsc190501.50305862': __('identifierSchemeCodeUnspsc190501.50305862'),
	'identifierSchemeCodeUnspsc190501.50305863': __('identifierSchemeCodeUnspsc190501.50305863'),
	'identifierSchemeCodeUnspsc190501.50305864': __('identifierSchemeCodeUnspsc190501.50305864'),
	'identifierSchemeCodeUnspsc190501.50305865': __('identifierSchemeCodeUnspsc190501.50305865'),
	'identifierSchemeCodeUnspsc190501.50305866': __('identifierSchemeCodeUnspsc190501.50305866'),
	'identifierSchemeCodeUnspsc190501.50305867': __('identifierSchemeCodeUnspsc190501.50305867'),
	'identifierSchemeCodeUnspsc190501.50305868': __('identifierSchemeCodeUnspsc190501.50305868'),
	'identifierSchemeCodeUnspsc190501.50305869': __('identifierSchemeCodeUnspsc190501.50305869'),
	'identifierSchemeCodeUnspsc190501.50305870': __('identifierSchemeCodeUnspsc190501.50305870'),
	'identifierSchemeCodeUnspsc190501.50305871': __('identifierSchemeCodeUnspsc190501.50305871'),
	'identifierSchemeCodeUnspsc190501.50305872': __('identifierSchemeCodeUnspsc190501.50305872'),
	'identifierSchemeCodeUnspsc190501.50305873': __('identifierSchemeCodeUnspsc190501.50305873'),
	'identifierSchemeCodeUnspsc190501.50305874': __('identifierSchemeCodeUnspsc190501.50305874'),
	'identifierSchemeCodeUnspsc190501.50305875': __('identifierSchemeCodeUnspsc190501.50305875'),
	'identifierSchemeCodeUnspsc190501.50305876': __('identifierSchemeCodeUnspsc190501.50305876'),
	'identifierSchemeCodeUnspsc190501.50305877': __('identifierSchemeCodeUnspsc190501.50305877'),
	'identifierSchemeCodeUnspsc190501.50305878': __('identifierSchemeCodeUnspsc190501.50305878'),
	'identifierSchemeCodeUnspsc190501.50305900': __('identifierSchemeCodeUnspsc190501.50305900'),
	'identifierSchemeCodeUnspsc190501.50305901': __('identifierSchemeCodeUnspsc190501.50305901'),
	'identifierSchemeCodeUnspsc190501.50305902': __('identifierSchemeCodeUnspsc190501.50305902'),
	'identifierSchemeCodeUnspsc190501.50305903': __('identifierSchemeCodeUnspsc190501.50305903'),
	'identifierSchemeCodeUnspsc190501.50305904': __('identifierSchemeCodeUnspsc190501.50305904'),
	'identifierSchemeCodeUnspsc190501.50305905': __('identifierSchemeCodeUnspsc190501.50305905'),
	'identifierSchemeCodeUnspsc190501.50305906': __('identifierSchemeCodeUnspsc190501.50305906'),
	'identifierSchemeCodeUnspsc190501.50305907': __('identifierSchemeCodeUnspsc190501.50305907'),
	'identifierSchemeCodeUnspsc190501.50305908': __('identifierSchemeCodeUnspsc190501.50305908'),
	'identifierSchemeCodeUnspsc190501.50306000': __('identifierSchemeCodeUnspsc190501.50306000'),
	'identifierSchemeCodeUnspsc190501.50306001': __('identifierSchemeCodeUnspsc190501.50306001'),
	'identifierSchemeCodeUnspsc190501.50306002': __('identifierSchemeCodeUnspsc190501.50306002'),
	'identifierSchemeCodeUnspsc190501.50306003': __('identifierSchemeCodeUnspsc190501.50306003'),
	'identifierSchemeCodeUnspsc190501.50306004': __('identifierSchemeCodeUnspsc190501.50306004'),
	'identifierSchemeCodeUnspsc190501.50306005': __('identifierSchemeCodeUnspsc190501.50306005'),
	'identifierSchemeCodeUnspsc190501.50306006': __('identifierSchemeCodeUnspsc190501.50306006'),
	'identifierSchemeCodeUnspsc190501.50306007': __('identifierSchemeCodeUnspsc190501.50306007'),
	'identifierSchemeCodeUnspsc190501.50306008': __('identifierSchemeCodeUnspsc190501.50306008'),
	'identifierSchemeCodeUnspsc190501.50306100': __('identifierSchemeCodeUnspsc190501.50306100'),
	'identifierSchemeCodeUnspsc190501.50306101': __('identifierSchemeCodeUnspsc190501.50306101'),
	'identifierSchemeCodeUnspsc190501.50306102': __('identifierSchemeCodeUnspsc190501.50306102'),
	'identifierSchemeCodeUnspsc190501.50306103': __('identifierSchemeCodeUnspsc190501.50306103'),
	'identifierSchemeCodeUnspsc190501.50306200': __('identifierSchemeCodeUnspsc190501.50306200'),
	'identifierSchemeCodeUnspsc190501.50306201': __('identifierSchemeCodeUnspsc190501.50306201'),
	'identifierSchemeCodeUnspsc190501.50306202': __('identifierSchemeCodeUnspsc190501.50306202'),
	'identifierSchemeCodeUnspsc190501.50306203': __('identifierSchemeCodeUnspsc190501.50306203'),
	'identifierSchemeCodeUnspsc190501.50306204': __('identifierSchemeCodeUnspsc190501.50306204'),
	'identifierSchemeCodeUnspsc190501.50306205': __('identifierSchemeCodeUnspsc190501.50306205'),
	'identifierSchemeCodeUnspsc190501.50306206': __('identifierSchemeCodeUnspsc190501.50306206'),
	'identifierSchemeCodeUnspsc190501.50306207': __('identifierSchemeCodeUnspsc190501.50306207'),
	'identifierSchemeCodeUnspsc190501.50306208': __('identifierSchemeCodeUnspsc190501.50306208'),
	'identifierSchemeCodeUnspsc190501.50306209': __('identifierSchemeCodeUnspsc190501.50306209'),
	'identifierSchemeCodeUnspsc190501.50306210': __('identifierSchemeCodeUnspsc190501.50306210'),
	'identifierSchemeCodeUnspsc190501.50306211': __('identifierSchemeCodeUnspsc190501.50306211'),
	'identifierSchemeCodeUnspsc190501.50306212': __('identifierSchemeCodeUnspsc190501.50306212'),
	'identifierSchemeCodeUnspsc190501.50306213': __('identifierSchemeCodeUnspsc190501.50306213'),
	'identifierSchemeCodeUnspsc190501.50306214': __('identifierSchemeCodeUnspsc190501.50306214'),
	'identifierSchemeCodeUnspsc190501.50306215': __('identifierSchemeCodeUnspsc190501.50306215'),
	'identifierSchemeCodeUnspsc190501.50306216': __('identifierSchemeCodeUnspsc190501.50306216'),
	'identifierSchemeCodeUnspsc190501.50306217': __('identifierSchemeCodeUnspsc190501.50306217'),
	'identifierSchemeCodeUnspsc190501.50306218': __('identifierSchemeCodeUnspsc190501.50306218'),
	'identifierSchemeCodeUnspsc190501.50306219': __('identifierSchemeCodeUnspsc190501.50306219'),
	'identifierSchemeCodeUnspsc190501.50306220': __('identifierSchemeCodeUnspsc190501.50306220'),
	'identifierSchemeCodeUnspsc190501.50306221': __('identifierSchemeCodeUnspsc190501.50306221'),
	'identifierSchemeCodeUnspsc190501.50306222': __('identifierSchemeCodeUnspsc190501.50306222'),
	'identifierSchemeCodeUnspsc190501.50306223': __('identifierSchemeCodeUnspsc190501.50306223'),
	'identifierSchemeCodeUnspsc190501.50306224': __('identifierSchemeCodeUnspsc190501.50306224'),
	'identifierSchemeCodeUnspsc190501.50306225': __('identifierSchemeCodeUnspsc190501.50306225'),
	'identifierSchemeCodeUnspsc190501.50306226': __('identifierSchemeCodeUnspsc190501.50306226'),
	'identifierSchemeCodeUnspsc190501.50306227': __('identifierSchemeCodeUnspsc190501.50306227'),
	'identifierSchemeCodeUnspsc190501.50306228': __('identifierSchemeCodeUnspsc190501.50306228'),
	'identifierSchemeCodeUnspsc190501.50306229': __('identifierSchemeCodeUnspsc190501.50306229'),
	'identifierSchemeCodeUnspsc190501.50306300': __('identifierSchemeCodeUnspsc190501.50306300'),
	'identifierSchemeCodeUnspsc190501.50306301': __('identifierSchemeCodeUnspsc190501.50306301'),
	'identifierSchemeCodeUnspsc190501.50306302': __('identifierSchemeCodeUnspsc190501.50306302'),
	'identifierSchemeCodeUnspsc190501.50306303': __('identifierSchemeCodeUnspsc190501.50306303'),
	'identifierSchemeCodeUnspsc190501.50306304': __('identifierSchemeCodeUnspsc190501.50306304'),
	'identifierSchemeCodeUnspsc190501.50306305': __('identifierSchemeCodeUnspsc190501.50306305'),
	'identifierSchemeCodeUnspsc190501.50306306': __('identifierSchemeCodeUnspsc190501.50306306'),
	'identifierSchemeCodeUnspsc190501.50306307': __('identifierSchemeCodeUnspsc190501.50306307'),
	'identifierSchemeCodeUnspsc190501.50306308': __('identifierSchemeCodeUnspsc190501.50306308'),
	'identifierSchemeCodeUnspsc190501.50306309': __('identifierSchemeCodeUnspsc190501.50306309'),
	'identifierSchemeCodeUnspsc190501.50306310': __('identifierSchemeCodeUnspsc190501.50306310'),
	'identifierSchemeCodeUnspsc190501.50306400': __('identifierSchemeCodeUnspsc190501.50306400'),
	'identifierSchemeCodeUnspsc190501.50306401': __('identifierSchemeCodeUnspsc190501.50306401'),
	'identifierSchemeCodeUnspsc190501.50306402': __('identifierSchemeCodeUnspsc190501.50306402'),
	'identifierSchemeCodeUnspsc190501.50306403': __('identifierSchemeCodeUnspsc190501.50306403'),
	'identifierSchemeCodeUnspsc190501.50306404': __('identifierSchemeCodeUnspsc190501.50306404'),
	'identifierSchemeCodeUnspsc190501.50306500': __('identifierSchemeCodeUnspsc190501.50306500'),
	'identifierSchemeCodeUnspsc190501.50306501': __('identifierSchemeCodeUnspsc190501.50306501'),
	'identifierSchemeCodeUnspsc190501.50306502': __('identifierSchemeCodeUnspsc190501.50306502'),
	'identifierSchemeCodeUnspsc190501.50306600': __('identifierSchemeCodeUnspsc190501.50306600'),
	'identifierSchemeCodeUnspsc190501.50306601': __('identifierSchemeCodeUnspsc190501.50306601'),
	'identifierSchemeCodeUnspsc190501.50306602': __('identifierSchemeCodeUnspsc190501.50306602'),
	'identifierSchemeCodeUnspsc190501.50306603': __('identifierSchemeCodeUnspsc190501.50306603'),
	'identifierSchemeCodeUnspsc190501.50306604': __('identifierSchemeCodeUnspsc190501.50306604'),
	'identifierSchemeCodeUnspsc190501.50306700': __('identifierSchemeCodeUnspsc190501.50306700'),
	'identifierSchemeCodeUnspsc190501.50306701': __('identifierSchemeCodeUnspsc190501.50306701'),
	'identifierSchemeCodeUnspsc190501.50306702': __('identifierSchemeCodeUnspsc190501.50306702'),
	'identifierSchemeCodeUnspsc190501.50306703': __('identifierSchemeCodeUnspsc190501.50306703'),
	'identifierSchemeCodeUnspsc190501.50306800': __('identifierSchemeCodeUnspsc190501.50306800'),
	'identifierSchemeCodeUnspsc190501.50306801': __('identifierSchemeCodeUnspsc190501.50306801'),
	'identifierSchemeCodeUnspsc190501.50306802': __('identifierSchemeCodeUnspsc190501.50306802'),
	'identifierSchemeCodeUnspsc190501.50306803': __('identifierSchemeCodeUnspsc190501.50306803'),
	'identifierSchemeCodeUnspsc190501.50306900': __('identifierSchemeCodeUnspsc190501.50306900'),
	'identifierSchemeCodeUnspsc190501.50306901': __('identifierSchemeCodeUnspsc190501.50306901'),
	'identifierSchemeCodeUnspsc190501.50306902': __('identifierSchemeCodeUnspsc190501.50306902'),
	'identifierSchemeCodeUnspsc190501.50306903': __('identifierSchemeCodeUnspsc190501.50306903'),
	'identifierSchemeCodeUnspsc190501.50306904': __('identifierSchemeCodeUnspsc190501.50306904'),
	'identifierSchemeCodeUnspsc190501.50306905': __('identifierSchemeCodeUnspsc190501.50306905'),
	'identifierSchemeCodeUnspsc190501.50306906': __('identifierSchemeCodeUnspsc190501.50306906'),
	'identifierSchemeCodeUnspsc190501.50307000': __('identifierSchemeCodeUnspsc190501.50307000'),
	'identifierSchemeCodeUnspsc190501.50307001': __('identifierSchemeCodeUnspsc190501.50307001'),
	'identifierSchemeCodeUnspsc190501.50307002': __('identifierSchemeCodeUnspsc190501.50307002'),
	'identifierSchemeCodeUnspsc190501.50307003': __('identifierSchemeCodeUnspsc190501.50307003'),
	'identifierSchemeCodeUnspsc190501.50307004': __('identifierSchemeCodeUnspsc190501.50307004'),
	'identifierSchemeCodeUnspsc190501.50307005': __('identifierSchemeCodeUnspsc190501.50307005'),
	'identifierSchemeCodeUnspsc190501.50307006': __('identifierSchemeCodeUnspsc190501.50307006'),
	'identifierSchemeCodeUnspsc190501.50307007': __('identifierSchemeCodeUnspsc190501.50307007'),
	'identifierSchemeCodeUnspsc190501.50307008': __('identifierSchemeCodeUnspsc190501.50307008'),
	'identifierSchemeCodeUnspsc190501.50307009': __('identifierSchemeCodeUnspsc190501.50307009'),
	'identifierSchemeCodeUnspsc190501.50307010': __('identifierSchemeCodeUnspsc190501.50307010'),
	'identifierSchemeCodeUnspsc190501.50307011': __('identifierSchemeCodeUnspsc190501.50307011'),
	'identifierSchemeCodeUnspsc190501.50307012': __('identifierSchemeCodeUnspsc190501.50307012'),
	'identifierSchemeCodeUnspsc190501.50307013': __('identifierSchemeCodeUnspsc190501.50307013'),
	'identifierSchemeCodeUnspsc190501.50307014': __('identifierSchemeCodeUnspsc190501.50307014'),
	'identifierSchemeCodeUnspsc190501.50307015': __('identifierSchemeCodeUnspsc190501.50307015'),
	'identifierSchemeCodeUnspsc190501.50307016': __('identifierSchemeCodeUnspsc190501.50307016'),
	'identifierSchemeCodeUnspsc190501.50307017': __('identifierSchemeCodeUnspsc190501.50307017'),
	'identifierSchemeCodeUnspsc190501.50307018': __('identifierSchemeCodeUnspsc190501.50307018'),
	'identifierSchemeCodeUnspsc190501.50307019': __('identifierSchemeCodeUnspsc190501.50307019'),
	'identifierSchemeCodeUnspsc190501.50307020': __('identifierSchemeCodeUnspsc190501.50307020'),
	'identifierSchemeCodeUnspsc190501.50307021': __('identifierSchemeCodeUnspsc190501.50307021'),
	'identifierSchemeCodeUnspsc190501.50307022': __('identifierSchemeCodeUnspsc190501.50307022'),
	'identifierSchemeCodeUnspsc190501.50307023': __('identifierSchemeCodeUnspsc190501.50307023'),
	'identifierSchemeCodeUnspsc190501.50307024': __('identifierSchemeCodeUnspsc190501.50307024'),
	'identifierSchemeCodeUnspsc190501.50307025': __('identifierSchemeCodeUnspsc190501.50307025'),
	'identifierSchemeCodeUnspsc190501.50307026': __('identifierSchemeCodeUnspsc190501.50307026'),
	'identifierSchemeCodeUnspsc190501.50307027': __('identifierSchemeCodeUnspsc190501.50307027'),
	'identifierSchemeCodeUnspsc190501.50307028': __('identifierSchemeCodeUnspsc190501.50307028'),
	'identifierSchemeCodeUnspsc190501.50307029': __('identifierSchemeCodeUnspsc190501.50307029'),
	'identifierSchemeCodeUnspsc190501.50307030': __('identifierSchemeCodeUnspsc190501.50307030'),
	'identifierSchemeCodeUnspsc190501.50307031': __('identifierSchemeCodeUnspsc190501.50307031'),
	'identifierSchemeCodeUnspsc190501.50307032': __('identifierSchemeCodeUnspsc190501.50307032'),
	'identifierSchemeCodeUnspsc190501.50307033': __('identifierSchemeCodeUnspsc190501.50307033'),
	'identifierSchemeCodeUnspsc190501.50307034': __('identifierSchemeCodeUnspsc190501.50307034'),
	'identifierSchemeCodeUnspsc190501.50307035': __('identifierSchemeCodeUnspsc190501.50307035'),
	'identifierSchemeCodeUnspsc190501.50307036': __('identifierSchemeCodeUnspsc190501.50307036'),
	'identifierSchemeCodeUnspsc190501.50307037': __('identifierSchemeCodeUnspsc190501.50307037'),
	'identifierSchemeCodeUnspsc190501.50307038': __('identifierSchemeCodeUnspsc190501.50307038'),
	'identifierSchemeCodeUnspsc190501.50307039': __('identifierSchemeCodeUnspsc190501.50307039'),
	'identifierSchemeCodeUnspsc190501.50307040': __('identifierSchemeCodeUnspsc190501.50307040'),
	'identifierSchemeCodeUnspsc190501.50307041': __('identifierSchemeCodeUnspsc190501.50307041'),
	'identifierSchemeCodeUnspsc190501.50307042': __('identifierSchemeCodeUnspsc190501.50307042'),
	'identifierSchemeCodeUnspsc190501.50307043': __('identifierSchemeCodeUnspsc190501.50307043'),
	'identifierSchemeCodeUnspsc190501.50307044': __('identifierSchemeCodeUnspsc190501.50307044'),
	'identifierSchemeCodeUnspsc190501.50307045': __('identifierSchemeCodeUnspsc190501.50307045'),
	'identifierSchemeCodeUnspsc190501.50307046': __('identifierSchemeCodeUnspsc190501.50307046'),
	'identifierSchemeCodeUnspsc190501.50307047': __('identifierSchemeCodeUnspsc190501.50307047'),
	'identifierSchemeCodeUnspsc190501.50307048': __('identifierSchemeCodeUnspsc190501.50307048'),
	'identifierSchemeCodeUnspsc190501.50307049': __('identifierSchemeCodeUnspsc190501.50307049'),
	'identifierSchemeCodeUnspsc190501.50307050': __('identifierSchemeCodeUnspsc190501.50307050'),
	'identifierSchemeCodeUnspsc190501.50307100': __('identifierSchemeCodeUnspsc190501.50307100'),
	'identifierSchemeCodeUnspsc190501.50307101': __('identifierSchemeCodeUnspsc190501.50307101'),
	'identifierSchemeCodeUnspsc190501.50307102': __('identifierSchemeCodeUnspsc190501.50307102'),
	'identifierSchemeCodeUnspsc190501.50307103': __('identifierSchemeCodeUnspsc190501.50307103'),
	'identifierSchemeCodeUnspsc190501.50307104': __('identifierSchemeCodeUnspsc190501.50307104'),
	'identifierSchemeCodeUnspsc190501.50307200': __('identifierSchemeCodeUnspsc190501.50307200'),
	'identifierSchemeCodeUnspsc190501.50307201': __('identifierSchemeCodeUnspsc190501.50307201'),
	'identifierSchemeCodeUnspsc190501.50307202': __('identifierSchemeCodeUnspsc190501.50307202'),
	'identifierSchemeCodeUnspsc190501.50307203': __('identifierSchemeCodeUnspsc190501.50307203'),
	'identifierSchemeCodeUnspsc190501.50307204': __('identifierSchemeCodeUnspsc190501.50307204'),
	'identifierSchemeCodeUnspsc190501.50307205': __('identifierSchemeCodeUnspsc190501.50307205'),
	'identifierSchemeCodeUnspsc190501.50307206': __('identifierSchemeCodeUnspsc190501.50307206'),
	'identifierSchemeCodeUnspsc190501.50307207': __('identifierSchemeCodeUnspsc190501.50307207'),
	'identifierSchemeCodeUnspsc190501.50307208': __('identifierSchemeCodeUnspsc190501.50307208'),
	'identifierSchemeCodeUnspsc190501.50307209': __('identifierSchemeCodeUnspsc190501.50307209'),
	'identifierSchemeCodeUnspsc190501.50307210': __('identifierSchemeCodeUnspsc190501.50307210'),
	'identifierSchemeCodeUnspsc190501.50307211': __('identifierSchemeCodeUnspsc190501.50307211'),
	'identifierSchemeCodeUnspsc190501.50307212': __('identifierSchemeCodeUnspsc190501.50307212'),
	'identifierSchemeCodeUnspsc190501.50307213': __('identifierSchemeCodeUnspsc190501.50307213'),
	'identifierSchemeCodeUnspsc190501.50307214': __('identifierSchemeCodeUnspsc190501.50307214'),
	'identifierSchemeCodeUnspsc190501.50307215': __('identifierSchemeCodeUnspsc190501.50307215'),
	'identifierSchemeCodeUnspsc190501.50307216': __('identifierSchemeCodeUnspsc190501.50307216'),
	'identifierSchemeCodeUnspsc190501.50307217': __('identifierSchemeCodeUnspsc190501.50307217'),
	'identifierSchemeCodeUnspsc190501.50307218': __('identifierSchemeCodeUnspsc190501.50307218'),
	'identifierSchemeCodeUnspsc190501.50307219': __('identifierSchemeCodeUnspsc190501.50307219'),
	'identifierSchemeCodeUnspsc190501.50307220': __('identifierSchemeCodeUnspsc190501.50307220'),
	'identifierSchemeCodeUnspsc190501.50307221': __('identifierSchemeCodeUnspsc190501.50307221'),
	'identifierSchemeCodeUnspsc190501.50307222': __('identifierSchemeCodeUnspsc190501.50307222'),
	'identifierSchemeCodeUnspsc190501.50307223': __('identifierSchemeCodeUnspsc190501.50307223'),
	'identifierSchemeCodeUnspsc190501.50307224': __('identifierSchemeCodeUnspsc190501.50307224'),
	'identifierSchemeCodeUnspsc190501.50307225': __('identifierSchemeCodeUnspsc190501.50307225'),
	'identifierSchemeCodeUnspsc190501.50307226': __('identifierSchemeCodeUnspsc190501.50307226'),
	'identifierSchemeCodeUnspsc190501.50307227': __('identifierSchemeCodeUnspsc190501.50307227'),
	'identifierSchemeCodeUnspsc190501.50307228': __('identifierSchemeCodeUnspsc190501.50307228'),
	'identifierSchemeCodeUnspsc190501.50307229': __('identifierSchemeCodeUnspsc190501.50307229'),
	'identifierSchemeCodeUnspsc190501.50307230': __('identifierSchemeCodeUnspsc190501.50307230'),
	'identifierSchemeCodeUnspsc190501.50307500': __('identifierSchemeCodeUnspsc190501.50307500'),
	'identifierSchemeCodeUnspsc190501.50307501': __('identifierSchemeCodeUnspsc190501.50307501'),
	'identifierSchemeCodeUnspsc190501.50307502': __('identifierSchemeCodeUnspsc190501.50307502'),
	'identifierSchemeCodeUnspsc190501.50307503': __('identifierSchemeCodeUnspsc190501.50307503'),
	'identifierSchemeCodeUnspsc190501.50310000': __('identifierSchemeCodeUnspsc190501.50310000'),
	'identifierSchemeCodeUnspsc190501.50311500': __('identifierSchemeCodeUnspsc190501.50311500'),
	'identifierSchemeCodeUnspsc190501.50311501': __('identifierSchemeCodeUnspsc190501.50311501'),
	'identifierSchemeCodeUnspsc190501.50311502': __('identifierSchemeCodeUnspsc190501.50311502'),
	'identifierSchemeCodeUnspsc190501.50311503': __('identifierSchemeCodeUnspsc190501.50311503'),
	'identifierSchemeCodeUnspsc190501.50311504': __('identifierSchemeCodeUnspsc190501.50311504'),
	'identifierSchemeCodeUnspsc190501.50311505': __('identifierSchemeCodeUnspsc190501.50311505'),
	'identifierSchemeCodeUnspsc190501.50311506': __('identifierSchemeCodeUnspsc190501.50311506'),
	'identifierSchemeCodeUnspsc190501.50311507': __('identifierSchemeCodeUnspsc190501.50311507'),
	'identifierSchemeCodeUnspsc190501.50311508': __('identifierSchemeCodeUnspsc190501.50311508'),
	'identifierSchemeCodeUnspsc190501.50311509': __('identifierSchemeCodeUnspsc190501.50311509'),
	'identifierSchemeCodeUnspsc190501.50311510': __('identifierSchemeCodeUnspsc190501.50311510'),
	'identifierSchemeCodeUnspsc190501.50311511': __('identifierSchemeCodeUnspsc190501.50311511'),
	'identifierSchemeCodeUnspsc190501.50311512': __('identifierSchemeCodeUnspsc190501.50311512'),
	'identifierSchemeCodeUnspsc190501.50311513': __('identifierSchemeCodeUnspsc190501.50311513'),
	'identifierSchemeCodeUnspsc190501.50311514': __('identifierSchemeCodeUnspsc190501.50311514'),
	'identifierSchemeCodeUnspsc190501.50311515': __('identifierSchemeCodeUnspsc190501.50311515'),
	'identifierSchemeCodeUnspsc190501.50311516': __('identifierSchemeCodeUnspsc190501.50311516'),
	'identifierSchemeCodeUnspsc190501.50311517': __('identifierSchemeCodeUnspsc190501.50311517'),
	'identifierSchemeCodeUnspsc190501.50311518': __('identifierSchemeCodeUnspsc190501.50311518'),
	'identifierSchemeCodeUnspsc190501.50311519': __('identifierSchemeCodeUnspsc190501.50311519'),
	'identifierSchemeCodeUnspsc190501.50311520': __('identifierSchemeCodeUnspsc190501.50311520'),
	'identifierSchemeCodeUnspsc190501.50311521': __('identifierSchemeCodeUnspsc190501.50311521'),
	'identifierSchemeCodeUnspsc190501.50311522': __('identifierSchemeCodeUnspsc190501.50311522'),
	'identifierSchemeCodeUnspsc190501.50311523': __('identifierSchemeCodeUnspsc190501.50311523'),
	'identifierSchemeCodeUnspsc190501.50311524': __('identifierSchemeCodeUnspsc190501.50311524'),
	'identifierSchemeCodeUnspsc190501.50311525': __('identifierSchemeCodeUnspsc190501.50311525'),
	'identifierSchemeCodeUnspsc190501.50311526': __('identifierSchemeCodeUnspsc190501.50311526'),
	'identifierSchemeCodeUnspsc190501.50311527': __('identifierSchemeCodeUnspsc190501.50311527'),
	'identifierSchemeCodeUnspsc190501.50311528': __('identifierSchemeCodeUnspsc190501.50311528'),
	'identifierSchemeCodeUnspsc190501.50311529': __('identifierSchemeCodeUnspsc190501.50311529'),
	'identifierSchemeCodeUnspsc190501.50311530': __('identifierSchemeCodeUnspsc190501.50311530'),
	'identifierSchemeCodeUnspsc190501.50311531': __('identifierSchemeCodeUnspsc190501.50311531'),
	'identifierSchemeCodeUnspsc190501.50311532': __('identifierSchemeCodeUnspsc190501.50311532'),
	'identifierSchemeCodeUnspsc190501.50311533': __('identifierSchemeCodeUnspsc190501.50311533'),
	'identifierSchemeCodeUnspsc190501.50311534': __('identifierSchemeCodeUnspsc190501.50311534'),
	'identifierSchemeCodeUnspsc190501.50311535': __('identifierSchemeCodeUnspsc190501.50311535'),
	'identifierSchemeCodeUnspsc190501.50311536': __('identifierSchemeCodeUnspsc190501.50311536'),
	'identifierSchemeCodeUnspsc190501.50311537': __('identifierSchemeCodeUnspsc190501.50311537'),
	'identifierSchemeCodeUnspsc190501.50311538': __('identifierSchemeCodeUnspsc190501.50311538'),
	'identifierSchemeCodeUnspsc190501.50311539': __('identifierSchemeCodeUnspsc190501.50311539'),
	'identifierSchemeCodeUnspsc190501.50311540': __('identifierSchemeCodeUnspsc190501.50311540'),
	'identifierSchemeCodeUnspsc190501.50311541': __('identifierSchemeCodeUnspsc190501.50311541'),
	'identifierSchemeCodeUnspsc190501.50311542': __('identifierSchemeCodeUnspsc190501.50311542'),
	'identifierSchemeCodeUnspsc190501.50311543': __('identifierSchemeCodeUnspsc190501.50311543'),
	'identifierSchemeCodeUnspsc190501.50311544': __('identifierSchemeCodeUnspsc190501.50311544'),
	'identifierSchemeCodeUnspsc190501.50311545': __('identifierSchemeCodeUnspsc190501.50311545'),
	'identifierSchemeCodeUnspsc190501.50311546': __('identifierSchemeCodeUnspsc190501.50311546'),
	'identifierSchemeCodeUnspsc190501.50311547': __('identifierSchemeCodeUnspsc190501.50311547'),
	'identifierSchemeCodeUnspsc190501.50311548': __('identifierSchemeCodeUnspsc190501.50311548'),
	'identifierSchemeCodeUnspsc190501.50311549': __('identifierSchemeCodeUnspsc190501.50311549'),
	'identifierSchemeCodeUnspsc190501.50311550': __('identifierSchemeCodeUnspsc190501.50311550'),
	'identifierSchemeCodeUnspsc190501.50311551': __('identifierSchemeCodeUnspsc190501.50311551'),
	'identifierSchemeCodeUnspsc190501.50311552': __('identifierSchemeCodeUnspsc190501.50311552'),
	'identifierSchemeCodeUnspsc190501.50311553': __('identifierSchemeCodeUnspsc190501.50311553'),
	'identifierSchemeCodeUnspsc190501.50311554': __('identifierSchemeCodeUnspsc190501.50311554'),
	'identifierSchemeCodeUnspsc190501.50311555': __('identifierSchemeCodeUnspsc190501.50311555'),
	'identifierSchemeCodeUnspsc190501.50311556': __('identifierSchemeCodeUnspsc190501.50311556'),
	'identifierSchemeCodeUnspsc190501.50311557': __('identifierSchemeCodeUnspsc190501.50311557'),
	'identifierSchemeCodeUnspsc190501.50311558': __('identifierSchemeCodeUnspsc190501.50311558'),
	'identifierSchemeCodeUnspsc190501.50311559': __('identifierSchemeCodeUnspsc190501.50311559'),
	'identifierSchemeCodeUnspsc190501.50311560': __('identifierSchemeCodeUnspsc190501.50311560'),
	'identifierSchemeCodeUnspsc190501.50311561': __('identifierSchemeCodeUnspsc190501.50311561'),
	'identifierSchemeCodeUnspsc190501.50311562': __('identifierSchemeCodeUnspsc190501.50311562'),
	'identifierSchemeCodeUnspsc190501.50311563': __('identifierSchemeCodeUnspsc190501.50311563'),
	'identifierSchemeCodeUnspsc190501.50311564': __('identifierSchemeCodeUnspsc190501.50311564'),
	'identifierSchemeCodeUnspsc190501.50311565': __('identifierSchemeCodeUnspsc190501.50311565'),
	'identifierSchemeCodeUnspsc190501.50311566': __('identifierSchemeCodeUnspsc190501.50311566'),
	'identifierSchemeCodeUnspsc190501.50311567': __('identifierSchemeCodeUnspsc190501.50311567'),
	'identifierSchemeCodeUnspsc190501.50311568': __('identifierSchemeCodeUnspsc190501.50311568'),
	'identifierSchemeCodeUnspsc190501.50311569': __('identifierSchemeCodeUnspsc190501.50311569'),
	'identifierSchemeCodeUnspsc190501.50311570': __('identifierSchemeCodeUnspsc190501.50311570'),
	'identifierSchemeCodeUnspsc190501.50311571': __('identifierSchemeCodeUnspsc190501.50311571'),
	'identifierSchemeCodeUnspsc190501.50311572': __('identifierSchemeCodeUnspsc190501.50311572'),
	'identifierSchemeCodeUnspsc190501.50311573': __('identifierSchemeCodeUnspsc190501.50311573'),
	'identifierSchemeCodeUnspsc190501.50311574': __('identifierSchemeCodeUnspsc190501.50311574'),
	'identifierSchemeCodeUnspsc190501.50311575': __('identifierSchemeCodeUnspsc190501.50311575'),
	'identifierSchemeCodeUnspsc190501.50311576': __('identifierSchemeCodeUnspsc190501.50311576'),
	'identifierSchemeCodeUnspsc190501.50311577': __('identifierSchemeCodeUnspsc190501.50311577'),
	'identifierSchemeCodeUnspsc190501.50311578': __('identifierSchemeCodeUnspsc190501.50311578'),
	'identifierSchemeCodeUnspsc190501.50311579': __('identifierSchemeCodeUnspsc190501.50311579'),
	'identifierSchemeCodeUnspsc190501.50311580': __('identifierSchemeCodeUnspsc190501.50311580'),
	'identifierSchemeCodeUnspsc190501.50311581': __('identifierSchemeCodeUnspsc190501.50311581'),
	'identifierSchemeCodeUnspsc190501.50311582': __('identifierSchemeCodeUnspsc190501.50311582'),
	'identifierSchemeCodeUnspsc190501.50311583': __('identifierSchemeCodeUnspsc190501.50311583'),
	'identifierSchemeCodeUnspsc190501.50311584': __('identifierSchemeCodeUnspsc190501.50311584'),
	'identifierSchemeCodeUnspsc190501.50311585': __('identifierSchemeCodeUnspsc190501.50311585'),
	'identifierSchemeCodeUnspsc190501.50311586': __('identifierSchemeCodeUnspsc190501.50311586'),
	'identifierSchemeCodeUnspsc190501.50311587': __('identifierSchemeCodeUnspsc190501.50311587'),
	'identifierSchemeCodeUnspsc190501.50311588': __('identifierSchemeCodeUnspsc190501.50311588'),
	'identifierSchemeCodeUnspsc190501.50311589': __('identifierSchemeCodeUnspsc190501.50311589'),
	'identifierSchemeCodeUnspsc190501.50311590': __('identifierSchemeCodeUnspsc190501.50311590'),
	'identifierSchemeCodeUnspsc190501.50311591': __('identifierSchemeCodeUnspsc190501.50311591'),
	'identifierSchemeCodeUnspsc190501.50311592': __('identifierSchemeCodeUnspsc190501.50311592'),
	'identifierSchemeCodeUnspsc190501.50311593': __('identifierSchemeCodeUnspsc190501.50311593'),
	'identifierSchemeCodeUnspsc190501.50311594': __('identifierSchemeCodeUnspsc190501.50311594'),
	'identifierSchemeCodeUnspsc190501.50311595': __('identifierSchemeCodeUnspsc190501.50311595'),
	'identifierSchemeCodeUnspsc190501.50311596': __('identifierSchemeCodeUnspsc190501.50311596'),
	'identifierSchemeCodeUnspsc190501.50311600': __('identifierSchemeCodeUnspsc190501.50311600'),
	'identifierSchemeCodeUnspsc190501.50311601': __('identifierSchemeCodeUnspsc190501.50311601'),
	'identifierSchemeCodeUnspsc190501.50311602': __('identifierSchemeCodeUnspsc190501.50311602'),
	'identifierSchemeCodeUnspsc190501.50311603': __('identifierSchemeCodeUnspsc190501.50311603'),
	'identifierSchemeCodeUnspsc190501.50311604': __('identifierSchemeCodeUnspsc190501.50311604'),
	'identifierSchemeCodeUnspsc190501.50311605': __('identifierSchemeCodeUnspsc190501.50311605'),
	'identifierSchemeCodeUnspsc190501.50311606': __('identifierSchemeCodeUnspsc190501.50311606'),
	'identifierSchemeCodeUnspsc190501.50311607': __('identifierSchemeCodeUnspsc190501.50311607'),
	'identifierSchemeCodeUnspsc190501.50311608': __('identifierSchemeCodeUnspsc190501.50311608'),
	'identifierSchemeCodeUnspsc190501.50311609': __('identifierSchemeCodeUnspsc190501.50311609'),
	'identifierSchemeCodeUnspsc190501.50311610': __('identifierSchemeCodeUnspsc190501.50311610'),
	'identifierSchemeCodeUnspsc190501.50311611': __('identifierSchemeCodeUnspsc190501.50311611'),
	'identifierSchemeCodeUnspsc190501.50311612': __('identifierSchemeCodeUnspsc190501.50311612'),
	'identifierSchemeCodeUnspsc190501.50311613': __('identifierSchemeCodeUnspsc190501.50311613'),
	'identifierSchemeCodeUnspsc190501.50311614': __('identifierSchemeCodeUnspsc190501.50311614'),
	'identifierSchemeCodeUnspsc190501.50311615': __('identifierSchemeCodeUnspsc190501.50311615'),
	'identifierSchemeCodeUnspsc190501.50311616': __('identifierSchemeCodeUnspsc190501.50311616'),
	'identifierSchemeCodeUnspsc190501.50311617': __('identifierSchemeCodeUnspsc190501.50311617'),
	'identifierSchemeCodeUnspsc190501.50311618': __('identifierSchemeCodeUnspsc190501.50311618'),
	'identifierSchemeCodeUnspsc190501.50311619': __('identifierSchemeCodeUnspsc190501.50311619'),
	'identifierSchemeCodeUnspsc190501.50311620': __('identifierSchemeCodeUnspsc190501.50311620'),
	'identifierSchemeCodeUnspsc190501.50311621': __('identifierSchemeCodeUnspsc190501.50311621'),
	'identifierSchemeCodeUnspsc190501.50311622': __('identifierSchemeCodeUnspsc190501.50311622'),
	'identifierSchemeCodeUnspsc190501.50311623': __('identifierSchemeCodeUnspsc190501.50311623'),
	'identifierSchemeCodeUnspsc190501.50311624': __('identifierSchemeCodeUnspsc190501.50311624'),
	'identifierSchemeCodeUnspsc190501.50311625': __('identifierSchemeCodeUnspsc190501.50311625'),
	'identifierSchemeCodeUnspsc190501.50311626': __('identifierSchemeCodeUnspsc190501.50311626'),
	'identifierSchemeCodeUnspsc190501.50311627': __('identifierSchemeCodeUnspsc190501.50311627'),
	'identifierSchemeCodeUnspsc190501.50311628': __('identifierSchemeCodeUnspsc190501.50311628'),
	'identifierSchemeCodeUnspsc190501.50311629': __('identifierSchemeCodeUnspsc190501.50311629'),
	'identifierSchemeCodeUnspsc190501.50311630': __('identifierSchemeCodeUnspsc190501.50311630'),
	'identifierSchemeCodeUnspsc190501.50311631': __('identifierSchemeCodeUnspsc190501.50311631'),
	'identifierSchemeCodeUnspsc190501.50311632': __('identifierSchemeCodeUnspsc190501.50311632'),
	'identifierSchemeCodeUnspsc190501.50311633': __('identifierSchemeCodeUnspsc190501.50311633'),
	'identifierSchemeCodeUnspsc190501.50311634': __('identifierSchemeCodeUnspsc190501.50311634'),
	'identifierSchemeCodeUnspsc190501.50311635': __('identifierSchemeCodeUnspsc190501.50311635'),
	'identifierSchemeCodeUnspsc190501.50311636': __('identifierSchemeCodeUnspsc190501.50311636'),
	'identifierSchemeCodeUnspsc190501.50311637': __('identifierSchemeCodeUnspsc190501.50311637'),
	'identifierSchemeCodeUnspsc190501.50311638': __('identifierSchemeCodeUnspsc190501.50311638'),
	'identifierSchemeCodeUnspsc190501.50311639': __('identifierSchemeCodeUnspsc190501.50311639'),
	'identifierSchemeCodeUnspsc190501.50311640': __('identifierSchemeCodeUnspsc190501.50311640'),
	'identifierSchemeCodeUnspsc190501.50311641': __('identifierSchemeCodeUnspsc190501.50311641'),
	'identifierSchemeCodeUnspsc190501.50311642': __('identifierSchemeCodeUnspsc190501.50311642'),
	'identifierSchemeCodeUnspsc190501.50311643': __('identifierSchemeCodeUnspsc190501.50311643'),
	'identifierSchemeCodeUnspsc190501.50311644': __('identifierSchemeCodeUnspsc190501.50311644'),
	'identifierSchemeCodeUnspsc190501.50311645': __('identifierSchemeCodeUnspsc190501.50311645'),
	'identifierSchemeCodeUnspsc190501.50311646': __('identifierSchemeCodeUnspsc190501.50311646'),
	'identifierSchemeCodeUnspsc190501.50311647': __('identifierSchemeCodeUnspsc190501.50311647'),
	'identifierSchemeCodeUnspsc190501.50311648': __('identifierSchemeCodeUnspsc190501.50311648'),
	'identifierSchemeCodeUnspsc190501.50311649': __('identifierSchemeCodeUnspsc190501.50311649'),
	'identifierSchemeCodeUnspsc190501.50311650': __('identifierSchemeCodeUnspsc190501.50311650'),
	'identifierSchemeCodeUnspsc190501.50311651': __('identifierSchemeCodeUnspsc190501.50311651'),
	'identifierSchemeCodeUnspsc190501.50311652': __('identifierSchemeCodeUnspsc190501.50311652'),
	'identifierSchemeCodeUnspsc190501.50311653': __('identifierSchemeCodeUnspsc190501.50311653'),
	'identifierSchemeCodeUnspsc190501.50311654': __('identifierSchemeCodeUnspsc190501.50311654'),
	'identifierSchemeCodeUnspsc190501.50311655': __('identifierSchemeCodeUnspsc190501.50311655'),
	'identifierSchemeCodeUnspsc190501.50311700': __('identifierSchemeCodeUnspsc190501.50311700'),
	'identifierSchemeCodeUnspsc190501.50311701': __('identifierSchemeCodeUnspsc190501.50311701'),
	'identifierSchemeCodeUnspsc190501.50311702': __('identifierSchemeCodeUnspsc190501.50311702'),
	'identifierSchemeCodeUnspsc190501.50311703': __('identifierSchemeCodeUnspsc190501.50311703'),
	'identifierSchemeCodeUnspsc190501.50311704': __('identifierSchemeCodeUnspsc190501.50311704'),
	'identifierSchemeCodeUnspsc190501.50311705': __('identifierSchemeCodeUnspsc190501.50311705'),
	'identifierSchemeCodeUnspsc190501.50311706': __('identifierSchemeCodeUnspsc190501.50311706'),
	'identifierSchemeCodeUnspsc190501.50311707': __('identifierSchemeCodeUnspsc190501.50311707'),
	'identifierSchemeCodeUnspsc190501.50311708': __('identifierSchemeCodeUnspsc190501.50311708'),
	'identifierSchemeCodeUnspsc190501.50311709': __('identifierSchemeCodeUnspsc190501.50311709'),
	'identifierSchemeCodeUnspsc190501.50311710': __('identifierSchemeCodeUnspsc190501.50311710'),
	'identifierSchemeCodeUnspsc190501.50311711': __('identifierSchemeCodeUnspsc190501.50311711'),
	'identifierSchemeCodeUnspsc190501.50311712': __('identifierSchemeCodeUnspsc190501.50311712'),
	'identifierSchemeCodeUnspsc190501.50311713': __('identifierSchemeCodeUnspsc190501.50311713'),
	'identifierSchemeCodeUnspsc190501.50311714': __('identifierSchemeCodeUnspsc190501.50311714'),
	'identifierSchemeCodeUnspsc190501.50311715': __('identifierSchemeCodeUnspsc190501.50311715'),
	'identifierSchemeCodeUnspsc190501.50311800': __('identifierSchemeCodeUnspsc190501.50311800'),
	'identifierSchemeCodeUnspsc190501.50311801': __('identifierSchemeCodeUnspsc190501.50311801'),
	'identifierSchemeCodeUnspsc190501.50311802': __('identifierSchemeCodeUnspsc190501.50311802'),
	'identifierSchemeCodeUnspsc190501.50311803': __('identifierSchemeCodeUnspsc190501.50311803'),
	'identifierSchemeCodeUnspsc190501.50311804': __('identifierSchemeCodeUnspsc190501.50311804'),
	'identifierSchemeCodeUnspsc190501.50311805': __('identifierSchemeCodeUnspsc190501.50311805'),
	'identifierSchemeCodeUnspsc190501.50311806': __('identifierSchemeCodeUnspsc190501.50311806'),
	'identifierSchemeCodeUnspsc190501.50311807': __('identifierSchemeCodeUnspsc190501.50311807'),
	'identifierSchemeCodeUnspsc190501.50311808': __('identifierSchemeCodeUnspsc190501.50311808'),
	'identifierSchemeCodeUnspsc190501.50311809': __('identifierSchemeCodeUnspsc190501.50311809'),
	'identifierSchemeCodeUnspsc190501.50311810': __('identifierSchemeCodeUnspsc190501.50311810'),
	'identifierSchemeCodeUnspsc190501.50311811': __('identifierSchemeCodeUnspsc190501.50311811'),
	'identifierSchemeCodeUnspsc190501.50311812': __('identifierSchemeCodeUnspsc190501.50311812'),
	'identifierSchemeCodeUnspsc190501.50311813': __('identifierSchemeCodeUnspsc190501.50311813'),
	'identifierSchemeCodeUnspsc190501.50311814': __('identifierSchemeCodeUnspsc190501.50311814'),
	'identifierSchemeCodeUnspsc190501.50311900': __('identifierSchemeCodeUnspsc190501.50311900'),
	'identifierSchemeCodeUnspsc190501.50311901': __('identifierSchemeCodeUnspsc190501.50311901'),
	'identifierSchemeCodeUnspsc190501.50311902': __('identifierSchemeCodeUnspsc190501.50311902'),
	'identifierSchemeCodeUnspsc190501.50311903': __('identifierSchemeCodeUnspsc190501.50311903'),
	'identifierSchemeCodeUnspsc190501.50312000': __('identifierSchemeCodeUnspsc190501.50312000'),
	'identifierSchemeCodeUnspsc190501.50312001': __('identifierSchemeCodeUnspsc190501.50312001'),
	'identifierSchemeCodeUnspsc190501.50312002': __('identifierSchemeCodeUnspsc190501.50312002'),
	'identifierSchemeCodeUnspsc190501.50312003': __('identifierSchemeCodeUnspsc190501.50312003'),
	'identifierSchemeCodeUnspsc190501.50312004': __('identifierSchemeCodeUnspsc190501.50312004'),
	'identifierSchemeCodeUnspsc190501.50312005': __('identifierSchemeCodeUnspsc190501.50312005'),
	'identifierSchemeCodeUnspsc190501.50312006': __('identifierSchemeCodeUnspsc190501.50312006'),
	'identifierSchemeCodeUnspsc190501.50312007': __('identifierSchemeCodeUnspsc190501.50312007'),
	'identifierSchemeCodeUnspsc190501.50312008': __('identifierSchemeCodeUnspsc190501.50312008'),
	'identifierSchemeCodeUnspsc190501.50312009': __('identifierSchemeCodeUnspsc190501.50312009'),
	'identifierSchemeCodeUnspsc190501.50312010': __('identifierSchemeCodeUnspsc190501.50312010'),
	'identifierSchemeCodeUnspsc190501.50312011': __('identifierSchemeCodeUnspsc190501.50312011'),
	'identifierSchemeCodeUnspsc190501.50312012': __('identifierSchemeCodeUnspsc190501.50312012'),
	'identifierSchemeCodeUnspsc190501.50312013': __('identifierSchemeCodeUnspsc190501.50312013'),
	'identifierSchemeCodeUnspsc190501.50312014': __('identifierSchemeCodeUnspsc190501.50312014'),
	'identifierSchemeCodeUnspsc190501.50312015': __('identifierSchemeCodeUnspsc190501.50312015'),
	'identifierSchemeCodeUnspsc190501.50312100': __('identifierSchemeCodeUnspsc190501.50312100'),
	'identifierSchemeCodeUnspsc190501.50312101': __('identifierSchemeCodeUnspsc190501.50312101'),
	'identifierSchemeCodeUnspsc190501.50312102': __('identifierSchemeCodeUnspsc190501.50312102'),
	'identifierSchemeCodeUnspsc190501.50312103': __('identifierSchemeCodeUnspsc190501.50312103'),
	'identifierSchemeCodeUnspsc190501.50312104': __('identifierSchemeCodeUnspsc190501.50312104'),
	'identifierSchemeCodeUnspsc190501.50312200': __('identifierSchemeCodeUnspsc190501.50312200'),
	'identifierSchemeCodeUnspsc190501.50312201': __('identifierSchemeCodeUnspsc190501.50312201'),
	'identifierSchemeCodeUnspsc190501.50312202': __('identifierSchemeCodeUnspsc190501.50312202'),
	'identifierSchemeCodeUnspsc190501.50312203': __('identifierSchemeCodeUnspsc190501.50312203'),
	'identifierSchemeCodeUnspsc190501.50312204': __('identifierSchemeCodeUnspsc190501.50312204'),
	'identifierSchemeCodeUnspsc190501.50312205': __('identifierSchemeCodeUnspsc190501.50312205'),
	'identifierSchemeCodeUnspsc190501.50312206': __('identifierSchemeCodeUnspsc190501.50312206'),
	'identifierSchemeCodeUnspsc190501.50312207': __('identifierSchemeCodeUnspsc190501.50312207'),
	'identifierSchemeCodeUnspsc190501.50312208': __('identifierSchemeCodeUnspsc190501.50312208'),
	'identifierSchemeCodeUnspsc190501.50312209': __('identifierSchemeCodeUnspsc190501.50312209'),
	'identifierSchemeCodeUnspsc190501.50312210': __('identifierSchemeCodeUnspsc190501.50312210'),
	'identifierSchemeCodeUnspsc190501.50312211': __('identifierSchemeCodeUnspsc190501.50312211'),
	'identifierSchemeCodeUnspsc190501.50312212': __('identifierSchemeCodeUnspsc190501.50312212'),
	'identifierSchemeCodeUnspsc190501.50312213': __('identifierSchemeCodeUnspsc190501.50312213'),
	'identifierSchemeCodeUnspsc190501.50312214': __('identifierSchemeCodeUnspsc190501.50312214'),
	'identifierSchemeCodeUnspsc190501.50312215': __('identifierSchemeCodeUnspsc190501.50312215'),
	'identifierSchemeCodeUnspsc190501.50312300': __('identifierSchemeCodeUnspsc190501.50312300'),
	'identifierSchemeCodeUnspsc190501.50312301': __('identifierSchemeCodeUnspsc190501.50312301'),
	'identifierSchemeCodeUnspsc190501.50312302': __('identifierSchemeCodeUnspsc190501.50312302'),
	'identifierSchemeCodeUnspsc190501.50312303': __('identifierSchemeCodeUnspsc190501.50312303'),
	'identifierSchemeCodeUnspsc190501.50312304': __('identifierSchemeCodeUnspsc190501.50312304'),
	'identifierSchemeCodeUnspsc190501.50312400': __('identifierSchemeCodeUnspsc190501.50312400'),
	'identifierSchemeCodeUnspsc190501.50312401': __('identifierSchemeCodeUnspsc190501.50312401'),
	'identifierSchemeCodeUnspsc190501.50312402': __('identifierSchemeCodeUnspsc190501.50312402'),
	'identifierSchemeCodeUnspsc190501.50312403': __('identifierSchemeCodeUnspsc190501.50312403'),
	'identifierSchemeCodeUnspsc190501.50312404': __('identifierSchemeCodeUnspsc190501.50312404'),
	'identifierSchemeCodeUnspsc190501.50312405': __('identifierSchemeCodeUnspsc190501.50312405'),
	'identifierSchemeCodeUnspsc190501.50312406': __('identifierSchemeCodeUnspsc190501.50312406'),
	'identifierSchemeCodeUnspsc190501.50312407': __('identifierSchemeCodeUnspsc190501.50312407'),
	'identifierSchemeCodeUnspsc190501.50312408': __('identifierSchemeCodeUnspsc190501.50312408'),
	'identifierSchemeCodeUnspsc190501.50312409': __('identifierSchemeCodeUnspsc190501.50312409'),
	'identifierSchemeCodeUnspsc190501.50312500': __('identifierSchemeCodeUnspsc190501.50312500'),
	'identifierSchemeCodeUnspsc190501.50312501': __('identifierSchemeCodeUnspsc190501.50312501'),
	'identifierSchemeCodeUnspsc190501.50312502': __('identifierSchemeCodeUnspsc190501.50312502'),
	'identifierSchemeCodeUnspsc190501.50312503': __('identifierSchemeCodeUnspsc190501.50312503'),
	'identifierSchemeCodeUnspsc190501.50312504': __('identifierSchemeCodeUnspsc190501.50312504'),
	'identifierSchemeCodeUnspsc190501.50312505': __('identifierSchemeCodeUnspsc190501.50312505'),
	'identifierSchemeCodeUnspsc190501.50312506': __('identifierSchemeCodeUnspsc190501.50312506'),
	'identifierSchemeCodeUnspsc190501.50312507': __('identifierSchemeCodeUnspsc190501.50312507'),
	'identifierSchemeCodeUnspsc190501.50312508': __('identifierSchemeCodeUnspsc190501.50312508'),
	'identifierSchemeCodeUnspsc190501.50312509': __('identifierSchemeCodeUnspsc190501.50312509'),
	'identifierSchemeCodeUnspsc190501.50312510': __('identifierSchemeCodeUnspsc190501.50312510'),
	'identifierSchemeCodeUnspsc190501.50312511': __('identifierSchemeCodeUnspsc190501.50312511'),
	'identifierSchemeCodeUnspsc190501.50312512': __('identifierSchemeCodeUnspsc190501.50312512'),
	'identifierSchemeCodeUnspsc190501.50312513': __('identifierSchemeCodeUnspsc190501.50312513'),
	'identifierSchemeCodeUnspsc190501.50312514': __('identifierSchemeCodeUnspsc190501.50312514'),
	'identifierSchemeCodeUnspsc190501.50312515': __('identifierSchemeCodeUnspsc190501.50312515'),
	'identifierSchemeCodeUnspsc190501.50312516': __('identifierSchemeCodeUnspsc190501.50312516'),
	'identifierSchemeCodeUnspsc190501.50312517': __('identifierSchemeCodeUnspsc190501.50312517'),
	'identifierSchemeCodeUnspsc190501.50312518': __('identifierSchemeCodeUnspsc190501.50312518'),
	'identifierSchemeCodeUnspsc190501.50312519': __('identifierSchemeCodeUnspsc190501.50312519'),
	'identifierSchemeCodeUnspsc190501.50312520': __('identifierSchemeCodeUnspsc190501.50312520'),
	'identifierSchemeCodeUnspsc190501.50312521': __('identifierSchemeCodeUnspsc190501.50312521'),
	'identifierSchemeCodeUnspsc190501.50312522': __('identifierSchemeCodeUnspsc190501.50312522'),
	'identifierSchemeCodeUnspsc190501.50312523': __('identifierSchemeCodeUnspsc190501.50312523'),
	'identifierSchemeCodeUnspsc190501.50312524': __('identifierSchemeCodeUnspsc190501.50312524'),
	'identifierSchemeCodeUnspsc190501.50312525': __('identifierSchemeCodeUnspsc190501.50312525'),
	'identifierSchemeCodeUnspsc190501.50312527': __('identifierSchemeCodeUnspsc190501.50312527'),
	'identifierSchemeCodeUnspsc190501.50312528': __('identifierSchemeCodeUnspsc190501.50312528'),
	'identifierSchemeCodeUnspsc190501.50312529': __('identifierSchemeCodeUnspsc190501.50312529'),
	'identifierSchemeCodeUnspsc190501.50312530': __('identifierSchemeCodeUnspsc190501.50312530'),
	'identifierSchemeCodeUnspsc190501.50312531': __('identifierSchemeCodeUnspsc190501.50312531'),
	'identifierSchemeCodeUnspsc190501.50312532': __('identifierSchemeCodeUnspsc190501.50312532'),
	'identifierSchemeCodeUnspsc190501.50312600': __('identifierSchemeCodeUnspsc190501.50312600'),
	'identifierSchemeCodeUnspsc190501.50312601': __('identifierSchemeCodeUnspsc190501.50312601'),
	'identifierSchemeCodeUnspsc190501.50312602': __('identifierSchemeCodeUnspsc190501.50312602'),
	'identifierSchemeCodeUnspsc190501.50312603': __('identifierSchemeCodeUnspsc190501.50312603'),
	'identifierSchemeCodeUnspsc190501.50312604': __('identifierSchemeCodeUnspsc190501.50312604'),
	'identifierSchemeCodeUnspsc190501.50312605': __('identifierSchemeCodeUnspsc190501.50312605'),
	'identifierSchemeCodeUnspsc190501.50312606': __('identifierSchemeCodeUnspsc190501.50312606'),
	'identifierSchemeCodeUnspsc190501.50312607': __('identifierSchemeCodeUnspsc190501.50312607'),
	'identifierSchemeCodeUnspsc190501.50312700': __('identifierSchemeCodeUnspsc190501.50312700'),
	'identifierSchemeCodeUnspsc190501.50312701': __('identifierSchemeCodeUnspsc190501.50312701'),
	'identifierSchemeCodeUnspsc190501.50312702': __('identifierSchemeCodeUnspsc190501.50312702'),
	'identifierSchemeCodeUnspsc190501.50312703': __('identifierSchemeCodeUnspsc190501.50312703'),
	'identifierSchemeCodeUnspsc190501.50312704': __('identifierSchemeCodeUnspsc190501.50312704'),
	'identifierSchemeCodeUnspsc190501.50312705': __('identifierSchemeCodeUnspsc190501.50312705'),
	'identifierSchemeCodeUnspsc190501.50312706': __('identifierSchemeCodeUnspsc190501.50312706'),
	'identifierSchemeCodeUnspsc190501.50312707': __('identifierSchemeCodeUnspsc190501.50312707'),
	'identifierSchemeCodeUnspsc190501.50312708': __('identifierSchemeCodeUnspsc190501.50312708'),
	'identifierSchemeCodeUnspsc190501.50312709': __('identifierSchemeCodeUnspsc190501.50312709'),
	'identifierSchemeCodeUnspsc190501.50312710': __('identifierSchemeCodeUnspsc190501.50312710'),
	'identifierSchemeCodeUnspsc190501.50312800': __('identifierSchemeCodeUnspsc190501.50312800'),
	'identifierSchemeCodeUnspsc190501.50312801': __('identifierSchemeCodeUnspsc190501.50312801'),
	'identifierSchemeCodeUnspsc190501.50312802': __('identifierSchemeCodeUnspsc190501.50312802'),
	'identifierSchemeCodeUnspsc190501.50312803': __('identifierSchemeCodeUnspsc190501.50312803'),
	'identifierSchemeCodeUnspsc190501.50312804': __('identifierSchemeCodeUnspsc190501.50312804'),
	'identifierSchemeCodeUnspsc190501.50312805': __('identifierSchemeCodeUnspsc190501.50312805'),
	'identifierSchemeCodeUnspsc190501.50312806': __('identifierSchemeCodeUnspsc190501.50312806'),
	'identifierSchemeCodeUnspsc190501.50312900': __('identifierSchemeCodeUnspsc190501.50312900'),
	'identifierSchemeCodeUnspsc190501.50312901': __('identifierSchemeCodeUnspsc190501.50312901'),
	'identifierSchemeCodeUnspsc190501.50312902': __('identifierSchemeCodeUnspsc190501.50312902'),
	'identifierSchemeCodeUnspsc190501.50312903': __('identifierSchemeCodeUnspsc190501.50312903'),
	'identifierSchemeCodeUnspsc190501.50312904': __('identifierSchemeCodeUnspsc190501.50312904'),
	'identifierSchemeCodeUnspsc190501.50312905': __('identifierSchemeCodeUnspsc190501.50312905'),
	'identifierSchemeCodeUnspsc190501.50312906': __('identifierSchemeCodeUnspsc190501.50312906'),
	'identifierSchemeCodeUnspsc190501.50312907': __('identifierSchemeCodeUnspsc190501.50312907'),
	'identifierSchemeCodeUnspsc190501.50312908': __('identifierSchemeCodeUnspsc190501.50312908'),
	'identifierSchemeCodeUnspsc190501.50312909': __('identifierSchemeCodeUnspsc190501.50312909'),
	'identifierSchemeCodeUnspsc190501.50312910': __('identifierSchemeCodeUnspsc190501.50312910'),
	'identifierSchemeCodeUnspsc190501.50312911': __('identifierSchemeCodeUnspsc190501.50312911'),
	'identifierSchemeCodeUnspsc190501.50312912': __('identifierSchemeCodeUnspsc190501.50312912'),
	'identifierSchemeCodeUnspsc190501.50312913': __('identifierSchemeCodeUnspsc190501.50312913'),
	'identifierSchemeCodeUnspsc190501.50312914': __('identifierSchemeCodeUnspsc190501.50312914'),
	'identifierSchemeCodeUnspsc190501.50312915': __('identifierSchemeCodeUnspsc190501.50312915'),
	'identifierSchemeCodeUnspsc190501.50312916': __('identifierSchemeCodeUnspsc190501.50312916'),
	'identifierSchemeCodeUnspsc190501.50313000': __('identifierSchemeCodeUnspsc190501.50313000'),
	'identifierSchemeCodeUnspsc190501.50313001': __('identifierSchemeCodeUnspsc190501.50313001'),
	'identifierSchemeCodeUnspsc190501.50313002': __('identifierSchemeCodeUnspsc190501.50313002'),
	'identifierSchemeCodeUnspsc190501.50313100': __('identifierSchemeCodeUnspsc190501.50313100'),
	'identifierSchemeCodeUnspsc190501.50313101': __('identifierSchemeCodeUnspsc190501.50313101'),
	'identifierSchemeCodeUnspsc190501.50313102': __('identifierSchemeCodeUnspsc190501.50313102'),
	'identifierSchemeCodeUnspsc190501.50313103': __('identifierSchemeCodeUnspsc190501.50313103'),
	'identifierSchemeCodeUnspsc190501.50313104': __('identifierSchemeCodeUnspsc190501.50313104'),
	'identifierSchemeCodeUnspsc190501.50313105': __('identifierSchemeCodeUnspsc190501.50313105'),
	'identifierSchemeCodeUnspsc190501.50313106': __('identifierSchemeCodeUnspsc190501.50313106'),
	'identifierSchemeCodeUnspsc190501.50313107': __('identifierSchemeCodeUnspsc190501.50313107'),
	'identifierSchemeCodeUnspsc190501.50313108': __('identifierSchemeCodeUnspsc190501.50313108'),
	'identifierSchemeCodeUnspsc190501.50313109': __('identifierSchemeCodeUnspsc190501.50313109'),
	'identifierSchemeCodeUnspsc190501.50313110': __('identifierSchemeCodeUnspsc190501.50313110'),
	'identifierSchemeCodeUnspsc190501.50313111': __('identifierSchemeCodeUnspsc190501.50313111'),
	'identifierSchemeCodeUnspsc190501.50313200': __('identifierSchemeCodeUnspsc190501.50313200'),
	'identifierSchemeCodeUnspsc190501.50313201': __('identifierSchemeCodeUnspsc190501.50313201'),
	'identifierSchemeCodeUnspsc190501.50313202': __('identifierSchemeCodeUnspsc190501.50313202'),
	'identifierSchemeCodeUnspsc190501.50313203': __('identifierSchemeCodeUnspsc190501.50313203'),
	'identifierSchemeCodeUnspsc190501.50313204': __('identifierSchemeCodeUnspsc190501.50313204'),
	'identifierSchemeCodeUnspsc190501.50313205': __('identifierSchemeCodeUnspsc190501.50313205'),
	'identifierSchemeCodeUnspsc190501.50313206': __('identifierSchemeCodeUnspsc190501.50313206'),
	'identifierSchemeCodeUnspsc190501.50313207': __('identifierSchemeCodeUnspsc190501.50313207'),
	'identifierSchemeCodeUnspsc190501.50313300': __('identifierSchemeCodeUnspsc190501.50313300'),
	'identifierSchemeCodeUnspsc190501.50313301': __('identifierSchemeCodeUnspsc190501.50313301'),
	'identifierSchemeCodeUnspsc190501.50313302': __('identifierSchemeCodeUnspsc190501.50313302'),
	'identifierSchemeCodeUnspsc190501.50313303': __('identifierSchemeCodeUnspsc190501.50313303'),
	'identifierSchemeCodeUnspsc190501.50313304': __('identifierSchemeCodeUnspsc190501.50313304'),
	'identifierSchemeCodeUnspsc190501.50313305': __('identifierSchemeCodeUnspsc190501.50313305'),
	'identifierSchemeCodeUnspsc190501.50313306': __('identifierSchemeCodeUnspsc190501.50313306'),
	'identifierSchemeCodeUnspsc190501.50313307': __('identifierSchemeCodeUnspsc190501.50313307'),
	'identifierSchemeCodeUnspsc190501.50313308': __('identifierSchemeCodeUnspsc190501.50313308'),
	'identifierSchemeCodeUnspsc190501.50313309': __('identifierSchemeCodeUnspsc190501.50313309'),
	'identifierSchemeCodeUnspsc190501.50313400': __('identifierSchemeCodeUnspsc190501.50313400'),
	'identifierSchemeCodeUnspsc190501.50313401': __('identifierSchemeCodeUnspsc190501.50313401'),
	'identifierSchemeCodeUnspsc190501.50313402': __('identifierSchemeCodeUnspsc190501.50313402'),
	'identifierSchemeCodeUnspsc190501.50313403': __('identifierSchemeCodeUnspsc190501.50313403'),
	'identifierSchemeCodeUnspsc190501.50313404': __('identifierSchemeCodeUnspsc190501.50313404'),
	'identifierSchemeCodeUnspsc190501.50313405': __('identifierSchemeCodeUnspsc190501.50313405'),
	'identifierSchemeCodeUnspsc190501.50313406': __('identifierSchemeCodeUnspsc190501.50313406'),
	'identifierSchemeCodeUnspsc190501.50313407': __('identifierSchemeCodeUnspsc190501.50313407'),
	'identifierSchemeCodeUnspsc190501.50313408': __('identifierSchemeCodeUnspsc190501.50313408'),
	'identifierSchemeCodeUnspsc190501.50313409': __('identifierSchemeCodeUnspsc190501.50313409'),
	'identifierSchemeCodeUnspsc190501.50313410': __('identifierSchemeCodeUnspsc190501.50313410'),
	'identifierSchemeCodeUnspsc190501.50313411': __('identifierSchemeCodeUnspsc190501.50313411'),
	'identifierSchemeCodeUnspsc190501.50313412': __('identifierSchemeCodeUnspsc190501.50313412'),
	'identifierSchemeCodeUnspsc190501.50313413': __('identifierSchemeCodeUnspsc190501.50313413'),
	'identifierSchemeCodeUnspsc190501.50313414': __('identifierSchemeCodeUnspsc190501.50313414'),
	'identifierSchemeCodeUnspsc190501.50313415': __('identifierSchemeCodeUnspsc190501.50313415'),
	'identifierSchemeCodeUnspsc190501.50313416': __('identifierSchemeCodeUnspsc190501.50313416'),
	'identifierSchemeCodeUnspsc190501.50313417': __('identifierSchemeCodeUnspsc190501.50313417'),
	'identifierSchemeCodeUnspsc190501.50313418': __('identifierSchemeCodeUnspsc190501.50313418'),
	'identifierSchemeCodeUnspsc190501.50313419': __('identifierSchemeCodeUnspsc190501.50313419'),
	'identifierSchemeCodeUnspsc190501.50313420': __('identifierSchemeCodeUnspsc190501.50313420'),
	'identifierSchemeCodeUnspsc190501.50313421': __('identifierSchemeCodeUnspsc190501.50313421'),
	'identifierSchemeCodeUnspsc190501.50313422': __('identifierSchemeCodeUnspsc190501.50313422'),
	'identifierSchemeCodeUnspsc190501.50313423': __('identifierSchemeCodeUnspsc190501.50313423'),
	'identifierSchemeCodeUnspsc190501.50313424': __('identifierSchemeCodeUnspsc190501.50313424'),
	'identifierSchemeCodeUnspsc190501.50313425': __('identifierSchemeCodeUnspsc190501.50313425'),
	'identifierSchemeCodeUnspsc190501.50313426': __('identifierSchemeCodeUnspsc190501.50313426'),
	'identifierSchemeCodeUnspsc190501.50313427': __('identifierSchemeCodeUnspsc190501.50313427'),
	'identifierSchemeCodeUnspsc190501.50313428': __('identifierSchemeCodeUnspsc190501.50313428'),
	'identifierSchemeCodeUnspsc190501.50313429': __('identifierSchemeCodeUnspsc190501.50313429'),
	'identifierSchemeCodeUnspsc190501.50313430': __('identifierSchemeCodeUnspsc190501.50313430'),
	'identifierSchemeCodeUnspsc190501.50313431': __('identifierSchemeCodeUnspsc190501.50313431'),
	'identifierSchemeCodeUnspsc190501.50313432': __('identifierSchemeCodeUnspsc190501.50313432'),
	'identifierSchemeCodeUnspsc190501.50313433': __('identifierSchemeCodeUnspsc190501.50313433'),
	'identifierSchemeCodeUnspsc190501.50313434': __('identifierSchemeCodeUnspsc190501.50313434'),
	'identifierSchemeCodeUnspsc190501.50313435': __('identifierSchemeCodeUnspsc190501.50313435'),
	'identifierSchemeCodeUnspsc190501.50313436': __('identifierSchemeCodeUnspsc190501.50313436'),
	'identifierSchemeCodeUnspsc190501.50313437': __('identifierSchemeCodeUnspsc190501.50313437'),
	'identifierSchemeCodeUnspsc190501.50313438': __('identifierSchemeCodeUnspsc190501.50313438'),
	'identifierSchemeCodeUnspsc190501.50313439': __('identifierSchemeCodeUnspsc190501.50313439'),
	'identifierSchemeCodeUnspsc190501.50313440': __('identifierSchemeCodeUnspsc190501.50313440'),
	'identifierSchemeCodeUnspsc190501.50313441': __('identifierSchemeCodeUnspsc190501.50313441'),
	'identifierSchemeCodeUnspsc190501.50313442': __('identifierSchemeCodeUnspsc190501.50313442'),
	'identifierSchemeCodeUnspsc190501.50313443': __('identifierSchemeCodeUnspsc190501.50313443'),
	'identifierSchemeCodeUnspsc190501.50313444': __('identifierSchemeCodeUnspsc190501.50313444'),
	'identifierSchemeCodeUnspsc190501.50313445': __('identifierSchemeCodeUnspsc190501.50313445'),
	'identifierSchemeCodeUnspsc190501.50313446': __('identifierSchemeCodeUnspsc190501.50313446'),
	'identifierSchemeCodeUnspsc190501.50313447': __('identifierSchemeCodeUnspsc190501.50313447'),
	'identifierSchemeCodeUnspsc190501.50313448': __('identifierSchemeCodeUnspsc190501.50313448'),
	'identifierSchemeCodeUnspsc190501.50313449': __('identifierSchemeCodeUnspsc190501.50313449'),
	'identifierSchemeCodeUnspsc190501.50313450': __('identifierSchemeCodeUnspsc190501.50313450'),
	'identifierSchemeCodeUnspsc190501.50313451': __('identifierSchemeCodeUnspsc190501.50313451'),
	'identifierSchemeCodeUnspsc190501.50313452': __('identifierSchemeCodeUnspsc190501.50313452'),
	'identifierSchemeCodeUnspsc190501.50313453': __('identifierSchemeCodeUnspsc190501.50313453'),
	'identifierSchemeCodeUnspsc190501.50313454': __('identifierSchemeCodeUnspsc190501.50313454'),
	'identifierSchemeCodeUnspsc190501.50313455': __('identifierSchemeCodeUnspsc190501.50313455'),
	'identifierSchemeCodeUnspsc190501.50313456': __('identifierSchemeCodeUnspsc190501.50313456'),
	'identifierSchemeCodeUnspsc190501.50313457': __('identifierSchemeCodeUnspsc190501.50313457'),
	'identifierSchemeCodeUnspsc190501.50313458': __('identifierSchemeCodeUnspsc190501.50313458'),
	'identifierSchemeCodeUnspsc190501.50313459': __('identifierSchemeCodeUnspsc190501.50313459'),
	'identifierSchemeCodeUnspsc190501.50313460': __('identifierSchemeCodeUnspsc190501.50313460'),
	'identifierSchemeCodeUnspsc190501.50313461': __('identifierSchemeCodeUnspsc190501.50313461'),
	'identifierSchemeCodeUnspsc190501.50313462': __('identifierSchemeCodeUnspsc190501.50313462'),
	'identifierSchemeCodeUnspsc190501.50313463': __('identifierSchemeCodeUnspsc190501.50313463'),
	'identifierSchemeCodeUnspsc190501.50313464': __('identifierSchemeCodeUnspsc190501.50313464'),
	'identifierSchemeCodeUnspsc190501.50313465': __('identifierSchemeCodeUnspsc190501.50313465'),
	'identifierSchemeCodeUnspsc190501.50313466': __('identifierSchemeCodeUnspsc190501.50313466'),
	'identifierSchemeCodeUnspsc190501.50313467': __('identifierSchemeCodeUnspsc190501.50313467'),
	'identifierSchemeCodeUnspsc190501.50313468': __('identifierSchemeCodeUnspsc190501.50313468'),
	'identifierSchemeCodeUnspsc190501.50313469': __('identifierSchemeCodeUnspsc190501.50313469'),
	'identifierSchemeCodeUnspsc190501.50313470': __('identifierSchemeCodeUnspsc190501.50313470'),
	'identifierSchemeCodeUnspsc190501.50313471': __('identifierSchemeCodeUnspsc190501.50313471'),
	'identifierSchemeCodeUnspsc190501.50313472': __('identifierSchemeCodeUnspsc190501.50313472'),
	'identifierSchemeCodeUnspsc190501.50313473': __('identifierSchemeCodeUnspsc190501.50313473'),
	'identifierSchemeCodeUnspsc190501.50313474': __('identifierSchemeCodeUnspsc190501.50313474'),
	'identifierSchemeCodeUnspsc190501.50313475': __('identifierSchemeCodeUnspsc190501.50313475'),
	'identifierSchemeCodeUnspsc190501.50313476': __('identifierSchemeCodeUnspsc190501.50313476'),
	'identifierSchemeCodeUnspsc190501.50313477': __('identifierSchemeCodeUnspsc190501.50313477'),
	'identifierSchemeCodeUnspsc190501.50313478': __('identifierSchemeCodeUnspsc190501.50313478'),
	'identifierSchemeCodeUnspsc190501.50313479': __('identifierSchemeCodeUnspsc190501.50313479'),
	'identifierSchemeCodeUnspsc190501.50313480': __('identifierSchemeCodeUnspsc190501.50313480'),
	'identifierSchemeCodeUnspsc190501.50313481': __('identifierSchemeCodeUnspsc190501.50313481'),
	'identifierSchemeCodeUnspsc190501.50313482': __('identifierSchemeCodeUnspsc190501.50313482'),
	'identifierSchemeCodeUnspsc190501.50313483': __('identifierSchemeCodeUnspsc190501.50313483'),
	'identifierSchemeCodeUnspsc190501.50313484': __('identifierSchemeCodeUnspsc190501.50313484'),
	'identifierSchemeCodeUnspsc190501.50313485': __('identifierSchemeCodeUnspsc190501.50313485'),
	'identifierSchemeCodeUnspsc190501.50313486': __('identifierSchemeCodeUnspsc190501.50313486'),
	'identifierSchemeCodeUnspsc190501.50313487': __('identifierSchemeCodeUnspsc190501.50313487'),
	'identifierSchemeCodeUnspsc190501.50313488': __('identifierSchemeCodeUnspsc190501.50313488'),
	'identifierSchemeCodeUnspsc190501.50313489': __('identifierSchemeCodeUnspsc190501.50313489'),
	'identifierSchemeCodeUnspsc190501.50313490': __('identifierSchemeCodeUnspsc190501.50313490'),
	'identifierSchemeCodeUnspsc190501.50313491': __('identifierSchemeCodeUnspsc190501.50313491'),
	'identifierSchemeCodeUnspsc190501.50313492': __('identifierSchemeCodeUnspsc190501.50313492'),
	'identifierSchemeCodeUnspsc190501.50313493': __('identifierSchemeCodeUnspsc190501.50313493'),
	'identifierSchemeCodeUnspsc190501.50313494': __('identifierSchemeCodeUnspsc190501.50313494'),
	'identifierSchemeCodeUnspsc190501.50313495': __('identifierSchemeCodeUnspsc190501.50313495'),
	'identifierSchemeCodeUnspsc190501.50313496': __('identifierSchemeCodeUnspsc190501.50313496'),
	'identifierSchemeCodeUnspsc190501.50313500': __('identifierSchemeCodeUnspsc190501.50313500'),
	'identifierSchemeCodeUnspsc190501.50313501': __('identifierSchemeCodeUnspsc190501.50313501'),
	'identifierSchemeCodeUnspsc190501.50313502': __('identifierSchemeCodeUnspsc190501.50313502'),
	'identifierSchemeCodeUnspsc190501.50313503': __('identifierSchemeCodeUnspsc190501.50313503'),
	'identifierSchemeCodeUnspsc190501.50313504': __('identifierSchemeCodeUnspsc190501.50313504'),
	'identifierSchemeCodeUnspsc190501.50313505': __('identifierSchemeCodeUnspsc190501.50313505'),
	'identifierSchemeCodeUnspsc190501.50313506': __('identifierSchemeCodeUnspsc190501.50313506'),
	'identifierSchemeCodeUnspsc190501.50313600': __('identifierSchemeCodeUnspsc190501.50313600'),
	'identifierSchemeCodeUnspsc190501.50313601': __('identifierSchemeCodeUnspsc190501.50313601'),
	'identifierSchemeCodeUnspsc190501.50313602': __('identifierSchemeCodeUnspsc190501.50313602'),
	'identifierSchemeCodeUnspsc190501.50313603': __('identifierSchemeCodeUnspsc190501.50313603'),
	'identifierSchemeCodeUnspsc190501.50313604': __('identifierSchemeCodeUnspsc190501.50313604'),
	'identifierSchemeCodeUnspsc190501.50313605': __('identifierSchemeCodeUnspsc190501.50313605'),
	'identifierSchemeCodeUnspsc190501.50313606': __('identifierSchemeCodeUnspsc190501.50313606'),
	'identifierSchemeCodeUnspsc190501.50313607': __('identifierSchemeCodeUnspsc190501.50313607'),
	'identifierSchemeCodeUnspsc190501.50313608': __('identifierSchemeCodeUnspsc190501.50313608'),
	'identifierSchemeCodeUnspsc190501.50313609': __('identifierSchemeCodeUnspsc190501.50313609'),
	'identifierSchemeCodeUnspsc190501.50313610': __('identifierSchemeCodeUnspsc190501.50313610'),
	'identifierSchemeCodeUnspsc190501.50313611': __('identifierSchemeCodeUnspsc190501.50313611'),
	'identifierSchemeCodeUnspsc190501.50313612': __('identifierSchemeCodeUnspsc190501.50313612'),
	'identifierSchemeCodeUnspsc190501.50313613': __('identifierSchemeCodeUnspsc190501.50313613'),
	'identifierSchemeCodeUnspsc190501.50313614': __('identifierSchemeCodeUnspsc190501.50313614'),
	'identifierSchemeCodeUnspsc190501.50313615': __('identifierSchemeCodeUnspsc190501.50313615'),
	'identifierSchemeCodeUnspsc190501.50313616': __('identifierSchemeCodeUnspsc190501.50313616'),
	'identifierSchemeCodeUnspsc190501.50313617': __('identifierSchemeCodeUnspsc190501.50313617'),
	'identifierSchemeCodeUnspsc190501.50313618': __('identifierSchemeCodeUnspsc190501.50313618'),
	'identifierSchemeCodeUnspsc190501.50313619': __('identifierSchemeCodeUnspsc190501.50313619'),
	'identifierSchemeCodeUnspsc190501.50313620': __('identifierSchemeCodeUnspsc190501.50313620'),
	'identifierSchemeCodeUnspsc190501.50313621': __('identifierSchemeCodeUnspsc190501.50313621'),
	'identifierSchemeCodeUnspsc190501.50313622': __('identifierSchemeCodeUnspsc190501.50313622'),
	'identifierSchemeCodeUnspsc190501.50313623': __('identifierSchemeCodeUnspsc190501.50313623'),
	'identifierSchemeCodeUnspsc190501.50313624': __('identifierSchemeCodeUnspsc190501.50313624'),
	'identifierSchemeCodeUnspsc190501.50313625': __('identifierSchemeCodeUnspsc190501.50313625'),
	'identifierSchemeCodeUnspsc190501.50313626': __('identifierSchemeCodeUnspsc190501.50313626'),
	'identifierSchemeCodeUnspsc190501.50313627': __('identifierSchemeCodeUnspsc190501.50313627'),
	'identifierSchemeCodeUnspsc190501.50313628': __('identifierSchemeCodeUnspsc190501.50313628'),
	'identifierSchemeCodeUnspsc190501.50313629': __('identifierSchemeCodeUnspsc190501.50313629'),
	'identifierSchemeCodeUnspsc190501.50313630': __('identifierSchemeCodeUnspsc190501.50313630'),
	'identifierSchemeCodeUnspsc190501.50313631': __('identifierSchemeCodeUnspsc190501.50313631'),
	'identifierSchemeCodeUnspsc190501.50313632': __('identifierSchemeCodeUnspsc190501.50313632'),
	'identifierSchemeCodeUnspsc190501.50313633': __('identifierSchemeCodeUnspsc190501.50313633'),
	'identifierSchemeCodeUnspsc190501.50313634': __('identifierSchemeCodeUnspsc190501.50313634'),
	'identifierSchemeCodeUnspsc190501.50313635': __('identifierSchemeCodeUnspsc190501.50313635'),
	'identifierSchemeCodeUnspsc190501.50313636': __('identifierSchemeCodeUnspsc190501.50313636'),
	'identifierSchemeCodeUnspsc190501.50313637': __('identifierSchemeCodeUnspsc190501.50313637'),
	'identifierSchemeCodeUnspsc190501.50313638': __('identifierSchemeCodeUnspsc190501.50313638'),
	'identifierSchemeCodeUnspsc190501.50313639': __('identifierSchemeCodeUnspsc190501.50313639'),
	'identifierSchemeCodeUnspsc190501.50313640': __('identifierSchemeCodeUnspsc190501.50313640'),
	'identifierSchemeCodeUnspsc190501.50313641': __('identifierSchemeCodeUnspsc190501.50313641'),
	'identifierSchemeCodeUnspsc190501.50313642': __('identifierSchemeCodeUnspsc190501.50313642'),
	'identifierSchemeCodeUnspsc190501.50313643': __('identifierSchemeCodeUnspsc190501.50313643'),
	'identifierSchemeCodeUnspsc190501.50313644': __('identifierSchemeCodeUnspsc190501.50313644'),
	'identifierSchemeCodeUnspsc190501.50313645': __('identifierSchemeCodeUnspsc190501.50313645'),
	'identifierSchemeCodeUnspsc190501.50313646': __('identifierSchemeCodeUnspsc190501.50313646'),
	'identifierSchemeCodeUnspsc190501.50313647': __('identifierSchemeCodeUnspsc190501.50313647'),
	'identifierSchemeCodeUnspsc190501.50313648': __('identifierSchemeCodeUnspsc190501.50313648'),
	'identifierSchemeCodeUnspsc190501.50313649': __('identifierSchemeCodeUnspsc190501.50313649'),
	'identifierSchemeCodeUnspsc190501.50313650': __('identifierSchemeCodeUnspsc190501.50313650'),
	'identifierSchemeCodeUnspsc190501.50313651': __('identifierSchemeCodeUnspsc190501.50313651'),
	'identifierSchemeCodeUnspsc190501.50313652': __('identifierSchemeCodeUnspsc190501.50313652'),
	'identifierSchemeCodeUnspsc190501.50313653': __('identifierSchemeCodeUnspsc190501.50313653'),
	'identifierSchemeCodeUnspsc190501.50313654': __('identifierSchemeCodeUnspsc190501.50313654'),
	'identifierSchemeCodeUnspsc190501.50313655': __('identifierSchemeCodeUnspsc190501.50313655'),
	'identifierSchemeCodeUnspsc190501.50313656': __('identifierSchemeCodeUnspsc190501.50313656'),
	'identifierSchemeCodeUnspsc190501.50313657': __('identifierSchemeCodeUnspsc190501.50313657'),
	'identifierSchemeCodeUnspsc190501.50313658': __('identifierSchemeCodeUnspsc190501.50313658'),
	'identifierSchemeCodeUnspsc190501.50313659': __('identifierSchemeCodeUnspsc190501.50313659'),
	'identifierSchemeCodeUnspsc190501.50313660': __('identifierSchemeCodeUnspsc190501.50313660'),
	'identifierSchemeCodeUnspsc190501.50313661': __('identifierSchemeCodeUnspsc190501.50313661'),
	'identifierSchemeCodeUnspsc190501.50313662': __('identifierSchemeCodeUnspsc190501.50313662'),
	'identifierSchemeCodeUnspsc190501.50313663': __('identifierSchemeCodeUnspsc190501.50313663'),
	'identifierSchemeCodeUnspsc190501.50313664': __('identifierSchemeCodeUnspsc190501.50313664'),
	'identifierSchemeCodeUnspsc190501.50313665': __('identifierSchemeCodeUnspsc190501.50313665'),
	'identifierSchemeCodeUnspsc190501.50313700': __('identifierSchemeCodeUnspsc190501.50313700'),
	'identifierSchemeCodeUnspsc190501.50313701': __('identifierSchemeCodeUnspsc190501.50313701'),
	'identifierSchemeCodeUnspsc190501.50313702': __('identifierSchemeCodeUnspsc190501.50313702'),
	'identifierSchemeCodeUnspsc190501.50313703': __('identifierSchemeCodeUnspsc190501.50313703'),
	'identifierSchemeCodeUnspsc190501.50313704': __('identifierSchemeCodeUnspsc190501.50313704'),
	'identifierSchemeCodeUnspsc190501.50313800': __('identifierSchemeCodeUnspsc190501.50313800'),
	'identifierSchemeCodeUnspsc190501.50313801': __('identifierSchemeCodeUnspsc190501.50313801'),
	'identifierSchemeCodeUnspsc190501.50313802': __('identifierSchemeCodeUnspsc190501.50313802'),
	'identifierSchemeCodeUnspsc190501.50313803': __('identifierSchemeCodeUnspsc190501.50313803'),
	'identifierSchemeCodeUnspsc190501.50313804': __('identifierSchemeCodeUnspsc190501.50313804'),
	'identifierSchemeCodeUnspsc190501.50313805': __('identifierSchemeCodeUnspsc190501.50313805'),
	'identifierSchemeCodeUnspsc190501.50313900': __('identifierSchemeCodeUnspsc190501.50313900'),
	'identifierSchemeCodeUnspsc190501.50313901': __('identifierSchemeCodeUnspsc190501.50313901'),
	'identifierSchemeCodeUnspsc190501.50313902': __('identifierSchemeCodeUnspsc190501.50313902'),
	'identifierSchemeCodeUnspsc190501.50313903': __('identifierSchemeCodeUnspsc190501.50313903'),
	'identifierSchemeCodeUnspsc190501.50313904': __('identifierSchemeCodeUnspsc190501.50313904'),
	'identifierSchemeCodeUnspsc190501.50313905': __('identifierSchemeCodeUnspsc190501.50313905'),
	'identifierSchemeCodeUnspsc190501.50313906': __('identifierSchemeCodeUnspsc190501.50313906'),
	'identifierSchemeCodeUnspsc190501.50314000': __('identifierSchemeCodeUnspsc190501.50314000'),
	'identifierSchemeCodeUnspsc190501.50314001': __('identifierSchemeCodeUnspsc190501.50314001'),
	'identifierSchemeCodeUnspsc190501.50314002': __('identifierSchemeCodeUnspsc190501.50314002'),
	'identifierSchemeCodeUnspsc190501.50314003': __('identifierSchemeCodeUnspsc190501.50314003'),
	'identifierSchemeCodeUnspsc190501.50314004': __('identifierSchemeCodeUnspsc190501.50314004'),
	'identifierSchemeCodeUnspsc190501.50314005': __('identifierSchemeCodeUnspsc190501.50314005'),
	'identifierSchemeCodeUnspsc190501.50314006': __('identifierSchemeCodeUnspsc190501.50314006'),
	'identifierSchemeCodeUnspsc190501.50314100': __('identifierSchemeCodeUnspsc190501.50314100'),
	'identifierSchemeCodeUnspsc190501.50314101': __('identifierSchemeCodeUnspsc190501.50314101'),
	'identifierSchemeCodeUnspsc190501.50314102': __('identifierSchemeCodeUnspsc190501.50314102'),
	'identifierSchemeCodeUnspsc190501.50314103': __('identifierSchemeCodeUnspsc190501.50314103'),
	'identifierSchemeCodeUnspsc190501.50314104': __('identifierSchemeCodeUnspsc190501.50314104'),
	'identifierSchemeCodeUnspsc190501.50314105': __('identifierSchemeCodeUnspsc190501.50314105'),
	'identifierSchemeCodeUnspsc190501.50314106': __('identifierSchemeCodeUnspsc190501.50314106'),
	'identifierSchemeCodeUnspsc190501.50314107': __('identifierSchemeCodeUnspsc190501.50314107'),
	'identifierSchemeCodeUnspsc190501.50314108': __('identifierSchemeCodeUnspsc190501.50314108'),
	'identifierSchemeCodeUnspsc190501.50314200': __('identifierSchemeCodeUnspsc190501.50314200'),
	'identifierSchemeCodeUnspsc190501.50314201': __('identifierSchemeCodeUnspsc190501.50314201'),
	'identifierSchemeCodeUnspsc190501.50314202': __('identifierSchemeCodeUnspsc190501.50314202'),
	'identifierSchemeCodeUnspsc190501.50314203': __('identifierSchemeCodeUnspsc190501.50314203'),
	'identifierSchemeCodeUnspsc190501.50314204': __('identifierSchemeCodeUnspsc190501.50314204'),
	'identifierSchemeCodeUnspsc190501.50314205': __('identifierSchemeCodeUnspsc190501.50314205'),
	'identifierSchemeCodeUnspsc190501.50314206': __('identifierSchemeCodeUnspsc190501.50314206'),
	'identifierSchemeCodeUnspsc190501.50314207': __('identifierSchemeCodeUnspsc190501.50314207'),
	'identifierSchemeCodeUnspsc190501.50314208': __('identifierSchemeCodeUnspsc190501.50314208'),
	'identifierSchemeCodeUnspsc190501.50314300': __('identifierSchemeCodeUnspsc190501.50314300'),
	'identifierSchemeCodeUnspsc190501.50314301': __('identifierSchemeCodeUnspsc190501.50314301'),
	'identifierSchemeCodeUnspsc190501.50314302': __('identifierSchemeCodeUnspsc190501.50314302'),
	'identifierSchemeCodeUnspsc190501.50314303': __('identifierSchemeCodeUnspsc190501.50314303'),
	'identifierSchemeCodeUnspsc190501.50314304': __('identifierSchemeCodeUnspsc190501.50314304'),
	'identifierSchemeCodeUnspsc190501.50314305': __('identifierSchemeCodeUnspsc190501.50314305'),
	'identifierSchemeCodeUnspsc190501.50314306': __('identifierSchemeCodeUnspsc190501.50314306'),
	'identifierSchemeCodeUnspsc190501.50314307': __('identifierSchemeCodeUnspsc190501.50314307'),
	'identifierSchemeCodeUnspsc190501.50314308': __('identifierSchemeCodeUnspsc190501.50314308'),
	'identifierSchemeCodeUnspsc190501.50314309': __('identifierSchemeCodeUnspsc190501.50314309'),
	'identifierSchemeCodeUnspsc190501.50314310': __('identifierSchemeCodeUnspsc190501.50314310'),
	'identifierSchemeCodeUnspsc190501.50314311': __('identifierSchemeCodeUnspsc190501.50314311'),
	'identifierSchemeCodeUnspsc190501.50314312': __('identifierSchemeCodeUnspsc190501.50314312'),
	'identifierSchemeCodeUnspsc190501.50314313': __('identifierSchemeCodeUnspsc190501.50314313'),
	'identifierSchemeCodeUnspsc190501.50314400': __('identifierSchemeCodeUnspsc190501.50314400'),
	'identifierSchemeCodeUnspsc190501.50314401': __('identifierSchemeCodeUnspsc190501.50314401'),
	'identifierSchemeCodeUnspsc190501.50314402': __('identifierSchemeCodeUnspsc190501.50314402'),
	'identifierSchemeCodeUnspsc190501.50314403': __('identifierSchemeCodeUnspsc190501.50314403'),
	'identifierSchemeCodeUnspsc190501.50314404': __('identifierSchemeCodeUnspsc190501.50314404'),
	'identifierSchemeCodeUnspsc190501.50314405': __('identifierSchemeCodeUnspsc190501.50314405'),
	'identifierSchemeCodeUnspsc190501.50314406': __('identifierSchemeCodeUnspsc190501.50314406'),
	'identifierSchemeCodeUnspsc190501.50314407': __('identifierSchemeCodeUnspsc190501.50314407'),
	'identifierSchemeCodeUnspsc190501.50314408': __('identifierSchemeCodeUnspsc190501.50314408'),
	'identifierSchemeCodeUnspsc190501.50314409': __('identifierSchemeCodeUnspsc190501.50314409'),
	'identifierSchemeCodeUnspsc190501.50314410': __('identifierSchemeCodeUnspsc190501.50314410'),
	'identifierSchemeCodeUnspsc190501.50314411': __('identifierSchemeCodeUnspsc190501.50314411'),
	'identifierSchemeCodeUnspsc190501.50314412': __('identifierSchemeCodeUnspsc190501.50314412'),
	'identifierSchemeCodeUnspsc190501.50314413': __('identifierSchemeCodeUnspsc190501.50314413'),
	'identifierSchemeCodeUnspsc190501.50314414': __('identifierSchemeCodeUnspsc190501.50314414'),
	'identifierSchemeCodeUnspsc190501.50314415': __('identifierSchemeCodeUnspsc190501.50314415'),
	'identifierSchemeCodeUnspsc190501.50314416': __('identifierSchemeCodeUnspsc190501.50314416'),
	'identifierSchemeCodeUnspsc190501.50314417': __('identifierSchemeCodeUnspsc190501.50314417'),
	'identifierSchemeCodeUnspsc190501.50314418': __('identifierSchemeCodeUnspsc190501.50314418'),
	'identifierSchemeCodeUnspsc190501.50314419': __('identifierSchemeCodeUnspsc190501.50314419'),
	'identifierSchemeCodeUnspsc190501.50314420': __('identifierSchemeCodeUnspsc190501.50314420'),
	'identifierSchemeCodeUnspsc190501.50314421': __('identifierSchemeCodeUnspsc190501.50314421'),
	'identifierSchemeCodeUnspsc190501.50314422': __('identifierSchemeCodeUnspsc190501.50314422'),
	'identifierSchemeCodeUnspsc190501.50314423': __('identifierSchemeCodeUnspsc190501.50314423'),
	'identifierSchemeCodeUnspsc190501.50314424': __('identifierSchemeCodeUnspsc190501.50314424'),
	'identifierSchemeCodeUnspsc190501.50314425': __('identifierSchemeCodeUnspsc190501.50314425'),
	'identifierSchemeCodeUnspsc190501.50314426': __('identifierSchemeCodeUnspsc190501.50314426'),
	'identifierSchemeCodeUnspsc190501.50314427': __('identifierSchemeCodeUnspsc190501.50314427'),
	'identifierSchemeCodeUnspsc190501.50314428': __('identifierSchemeCodeUnspsc190501.50314428'),
	'identifierSchemeCodeUnspsc190501.50314429': __('identifierSchemeCodeUnspsc190501.50314429'),
	'identifierSchemeCodeUnspsc190501.50314430': __('identifierSchemeCodeUnspsc190501.50314430'),
	'identifierSchemeCodeUnspsc190501.50314431': __('identifierSchemeCodeUnspsc190501.50314431'),
	'identifierSchemeCodeUnspsc190501.50314432': __('identifierSchemeCodeUnspsc190501.50314432'),
	'identifierSchemeCodeUnspsc190501.50314433': __('identifierSchemeCodeUnspsc190501.50314433'),
	'identifierSchemeCodeUnspsc190501.50314434': __('identifierSchemeCodeUnspsc190501.50314434'),
	'identifierSchemeCodeUnspsc190501.50314435': __('identifierSchemeCodeUnspsc190501.50314435'),
	'identifierSchemeCodeUnspsc190501.50314436': __('identifierSchemeCodeUnspsc190501.50314436'),
	'identifierSchemeCodeUnspsc190501.50314437': __('identifierSchemeCodeUnspsc190501.50314437'),
	'identifierSchemeCodeUnspsc190501.50314438': __('identifierSchemeCodeUnspsc190501.50314438'),
	'identifierSchemeCodeUnspsc190501.50314439': __('identifierSchemeCodeUnspsc190501.50314439'),
	'identifierSchemeCodeUnspsc190501.50314440': __('identifierSchemeCodeUnspsc190501.50314440'),
	'identifierSchemeCodeUnspsc190501.50314441': __('identifierSchemeCodeUnspsc190501.50314441'),
	'identifierSchemeCodeUnspsc190501.50314442': __('identifierSchemeCodeUnspsc190501.50314442'),
	'identifierSchemeCodeUnspsc190501.50314443': __('identifierSchemeCodeUnspsc190501.50314443'),
	'identifierSchemeCodeUnspsc190501.50314444': __('identifierSchemeCodeUnspsc190501.50314444'),
	'identifierSchemeCodeUnspsc190501.50314445': __('identifierSchemeCodeUnspsc190501.50314445'),
	'identifierSchemeCodeUnspsc190501.50314446': __('identifierSchemeCodeUnspsc190501.50314446'),
	'identifierSchemeCodeUnspsc190501.50314500': __('identifierSchemeCodeUnspsc190501.50314500'),
	'identifierSchemeCodeUnspsc190501.50314501': __('identifierSchemeCodeUnspsc190501.50314501'),
	'identifierSchemeCodeUnspsc190501.50314502': __('identifierSchemeCodeUnspsc190501.50314502'),
	'identifierSchemeCodeUnspsc190501.50314503': __('identifierSchemeCodeUnspsc190501.50314503'),
	'identifierSchemeCodeUnspsc190501.50314504': __('identifierSchemeCodeUnspsc190501.50314504'),
	'identifierSchemeCodeUnspsc190501.50314505': __('identifierSchemeCodeUnspsc190501.50314505'),
	'identifierSchemeCodeUnspsc190501.50314506': __('identifierSchemeCodeUnspsc190501.50314506'),
	'identifierSchemeCodeUnspsc190501.50314507': __('identifierSchemeCodeUnspsc190501.50314507'),
	'identifierSchemeCodeUnspsc190501.50314508': __('identifierSchemeCodeUnspsc190501.50314508'),
	'identifierSchemeCodeUnspsc190501.50314509': __('identifierSchemeCodeUnspsc190501.50314509'),
	'identifierSchemeCodeUnspsc190501.50314510': __('identifierSchemeCodeUnspsc190501.50314510'),
	'identifierSchemeCodeUnspsc190501.50314511': __('identifierSchemeCodeUnspsc190501.50314511'),
	'identifierSchemeCodeUnspsc190501.50314512': __('identifierSchemeCodeUnspsc190501.50314512'),
	'identifierSchemeCodeUnspsc190501.50314513': __('identifierSchemeCodeUnspsc190501.50314513'),
	'identifierSchemeCodeUnspsc190501.50314514': __('identifierSchemeCodeUnspsc190501.50314514'),
	'identifierSchemeCodeUnspsc190501.50314515': __('identifierSchemeCodeUnspsc190501.50314515'),
	'identifierSchemeCodeUnspsc190501.50314516': __('identifierSchemeCodeUnspsc190501.50314516'),
	'identifierSchemeCodeUnspsc190501.50314517': __('identifierSchemeCodeUnspsc190501.50314517'),
	'identifierSchemeCodeUnspsc190501.50314518': __('identifierSchemeCodeUnspsc190501.50314518'),
	'identifierSchemeCodeUnspsc190501.50314519': __('identifierSchemeCodeUnspsc190501.50314519'),
	'identifierSchemeCodeUnspsc190501.50314520': __('identifierSchemeCodeUnspsc190501.50314520'),
	'identifierSchemeCodeUnspsc190501.50314521': __('identifierSchemeCodeUnspsc190501.50314521'),
	'identifierSchemeCodeUnspsc190501.50314522': __('identifierSchemeCodeUnspsc190501.50314522'),
	'identifierSchemeCodeUnspsc190501.50314523': __('identifierSchemeCodeUnspsc190501.50314523'),
	'identifierSchemeCodeUnspsc190501.50314524': __('identifierSchemeCodeUnspsc190501.50314524'),
	'identifierSchemeCodeUnspsc190501.50314525': __('identifierSchemeCodeUnspsc190501.50314525'),
	'identifierSchemeCodeUnspsc190501.50314600': __('identifierSchemeCodeUnspsc190501.50314600'),
	'identifierSchemeCodeUnspsc190501.50314601': __('identifierSchemeCodeUnspsc190501.50314601'),
	'identifierSchemeCodeUnspsc190501.50314602': __('identifierSchemeCodeUnspsc190501.50314602'),
	'identifierSchemeCodeUnspsc190501.50314603': __('identifierSchemeCodeUnspsc190501.50314603'),
	'identifierSchemeCodeUnspsc190501.50314604': __('identifierSchemeCodeUnspsc190501.50314604'),
	'identifierSchemeCodeUnspsc190501.50314605': __('identifierSchemeCodeUnspsc190501.50314605'),
	'identifierSchemeCodeUnspsc190501.50314606': __('identifierSchemeCodeUnspsc190501.50314606'),
	'identifierSchemeCodeUnspsc190501.50314607': __('identifierSchemeCodeUnspsc190501.50314607'),
	'identifierSchemeCodeUnspsc190501.50314608': __('identifierSchemeCodeUnspsc190501.50314608'),
	'identifierSchemeCodeUnspsc190501.50314609': __('identifierSchemeCodeUnspsc190501.50314609'),
	'identifierSchemeCodeUnspsc190501.50314610': __('identifierSchemeCodeUnspsc190501.50314610'),
	'identifierSchemeCodeUnspsc190501.50314611': __('identifierSchemeCodeUnspsc190501.50314611'),
	'identifierSchemeCodeUnspsc190501.50314612': __('identifierSchemeCodeUnspsc190501.50314612'),
	'identifierSchemeCodeUnspsc190501.50314613': __('identifierSchemeCodeUnspsc190501.50314613'),
	'identifierSchemeCodeUnspsc190501.50314614': __('identifierSchemeCodeUnspsc190501.50314614'),
	'identifierSchemeCodeUnspsc190501.50314615': __('identifierSchemeCodeUnspsc190501.50314615'),
	'identifierSchemeCodeUnspsc190501.50314616': __('identifierSchemeCodeUnspsc190501.50314616'),
	'identifierSchemeCodeUnspsc190501.50314617': __('identifierSchemeCodeUnspsc190501.50314617'),
	'identifierSchemeCodeUnspsc190501.50314618': __('identifierSchemeCodeUnspsc190501.50314618'),
	'identifierSchemeCodeUnspsc190501.50314619': __('identifierSchemeCodeUnspsc190501.50314619'),
	'identifierSchemeCodeUnspsc190501.50314620': __('identifierSchemeCodeUnspsc190501.50314620'),
	'identifierSchemeCodeUnspsc190501.50314621': __('identifierSchemeCodeUnspsc190501.50314621'),
	'identifierSchemeCodeUnspsc190501.50314622': __('identifierSchemeCodeUnspsc190501.50314622'),
	'identifierSchemeCodeUnspsc190501.50314623': __('identifierSchemeCodeUnspsc190501.50314623'),
	'identifierSchemeCodeUnspsc190501.50314624': __('identifierSchemeCodeUnspsc190501.50314624'),
	'identifierSchemeCodeUnspsc190501.50314625': __('identifierSchemeCodeUnspsc190501.50314625'),
	'identifierSchemeCodeUnspsc190501.50314626': __('identifierSchemeCodeUnspsc190501.50314626'),
	'identifierSchemeCodeUnspsc190501.50314627': __('identifierSchemeCodeUnspsc190501.50314627'),
	'identifierSchemeCodeUnspsc190501.50314628': __('identifierSchemeCodeUnspsc190501.50314628'),
	'identifierSchemeCodeUnspsc190501.50314629': __('identifierSchemeCodeUnspsc190501.50314629'),
	'identifierSchemeCodeUnspsc190501.50314630': __('identifierSchemeCodeUnspsc190501.50314630'),
	'identifierSchemeCodeUnspsc190501.50314631': __('identifierSchemeCodeUnspsc190501.50314631'),
	'identifierSchemeCodeUnspsc190501.50314632': __('identifierSchemeCodeUnspsc190501.50314632'),
	'identifierSchemeCodeUnspsc190501.50314633': __('identifierSchemeCodeUnspsc190501.50314633'),
	'identifierSchemeCodeUnspsc190501.50314634': __('identifierSchemeCodeUnspsc190501.50314634'),
	'identifierSchemeCodeUnspsc190501.50314635': __('identifierSchemeCodeUnspsc190501.50314635'),
	'identifierSchemeCodeUnspsc190501.50314636': __('identifierSchemeCodeUnspsc190501.50314636'),
	'identifierSchemeCodeUnspsc190501.50314637': __('identifierSchemeCodeUnspsc190501.50314637'),
	'identifierSchemeCodeUnspsc190501.50314638': __('identifierSchemeCodeUnspsc190501.50314638'),
	'identifierSchemeCodeUnspsc190501.50314639': __('identifierSchemeCodeUnspsc190501.50314639'),
	'identifierSchemeCodeUnspsc190501.50314640': __('identifierSchemeCodeUnspsc190501.50314640'),
	'identifierSchemeCodeUnspsc190501.50314641': __('identifierSchemeCodeUnspsc190501.50314641'),
	'identifierSchemeCodeUnspsc190501.50314642': __('identifierSchemeCodeUnspsc190501.50314642'),
	'identifierSchemeCodeUnspsc190501.50314643': __('identifierSchemeCodeUnspsc190501.50314643'),
	'identifierSchemeCodeUnspsc190501.50314644': __('identifierSchemeCodeUnspsc190501.50314644'),
	'identifierSchemeCodeUnspsc190501.50314645': __('identifierSchemeCodeUnspsc190501.50314645'),
	'identifierSchemeCodeUnspsc190501.50314646': __('identifierSchemeCodeUnspsc190501.50314646'),
	'identifierSchemeCodeUnspsc190501.50314647': __('identifierSchemeCodeUnspsc190501.50314647'),
	'identifierSchemeCodeUnspsc190501.50314648': __('identifierSchemeCodeUnspsc190501.50314648'),
	'identifierSchemeCodeUnspsc190501.50314649': __('identifierSchemeCodeUnspsc190501.50314649'),
	'identifierSchemeCodeUnspsc190501.50314650': __('identifierSchemeCodeUnspsc190501.50314650'),
	'identifierSchemeCodeUnspsc190501.50314651': __('identifierSchemeCodeUnspsc190501.50314651'),
	'identifierSchemeCodeUnspsc190501.50314652': __('identifierSchemeCodeUnspsc190501.50314652'),
	'identifierSchemeCodeUnspsc190501.50314653': __('identifierSchemeCodeUnspsc190501.50314653'),
	'identifierSchemeCodeUnspsc190501.50314654': __('identifierSchemeCodeUnspsc190501.50314654'),
	'identifierSchemeCodeUnspsc190501.50314655': __('identifierSchemeCodeUnspsc190501.50314655'),
	'identifierSchemeCodeUnspsc190501.50314656': __('identifierSchemeCodeUnspsc190501.50314656'),
	'identifierSchemeCodeUnspsc190501.50314657': __('identifierSchemeCodeUnspsc190501.50314657'),
	'identifierSchemeCodeUnspsc190501.50314658': __('identifierSchemeCodeUnspsc190501.50314658'),
	'identifierSchemeCodeUnspsc190501.50314659': __('identifierSchemeCodeUnspsc190501.50314659'),
	'identifierSchemeCodeUnspsc190501.50314660': __('identifierSchemeCodeUnspsc190501.50314660'),
	'identifierSchemeCodeUnspsc190501.50314661': __('identifierSchemeCodeUnspsc190501.50314661'),
	'identifierSchemeCodeUnspsc190501.50314700': __('identifierSchemeCodeUnspsc190501.50314700'),
	'identifierSchemeCodeUnspsc190501.50314701': __('identifierSchemeCodeUnspsc190501.50314701'),
	'identifierSchemeCodeUnspsc190501.50314702': __('identifierSchemeCodeUnspsc190501.50314702'),
	'identifierSchemeCodeUnspsc190501.50314800': __('identifierSchemeCodeUnspsc190501.50314800'),
	'identifierSchemeCodeUnspsc190501.50314801': __('identifierSchemeCodeUnspsc190501.50314801'),
	'identifierSchemeCodeUnspsc190501.50314802': __('identifierSchemeCodeUnspsc190501.50314802'),
	'identifierSchemeCodeUnspsc190501.50314900': __('identifierSchemeCodeUnspsc190501.50314900'),
	'identifierSchemeCodeUnspsc190501.50314901': __('identifierSchemeCodeUnspsc190501.50314901'),
	'identifierSchemeCodeUnspsc190501.50314902': __('identifierSchemeCodeUnspsc190501.50314902'),
	'identifierSchemeCodeUnspsc190501.50314903': __('identifierSchemeCodeUnspsc190501.50314903'),
	'identifierSchemeCodeUnspsc190501.50314904': __('identifierSchemeCodeUnspsc190501.50314904'),
	'identifierSchemeCodeUnspsc190501.50314905': __('identifierSchemeCodeUnspsc190501.50314905'),
	'identifierSchemeCodeUnspsc190501.50314906': __('identifierSchemeCodeUnspsc190501.50314906'),
	'identifierSchemeCodeUnspsc190501.50314907': __('identifierSchemeCodeUnspsc190501.50314907'),
	'identifierSchemeCodeUnspsc190501.50314908': __('identifierSchemeCodeUnspsc190501.50314908'),
	'identifierSchemeCodeUnspsc190501.50314909': __('identifierSchemeCodeUnspsc190501.50314909'),
	'identifierSchemeCodeUnspsc190501.50314910': __('identifierSchemeCodeUnspsc190501.50314910'),
	'identifierSchemeCodeUnspsc190501.50314911': __('identifierSchemeCodeUnspsc190501.50314911'),
	'identifierSchemeCodeUnspsc190501.50314912': __('identifierSchemeCodeUnspsc190501.50314912'),
	'identifierSchemeCodeUnspsc190501.50314913': __('identifierSchemeCodeUnspsc190501.50314913'),
	'identifierSchemeCodeUnspsc190501.50314914': __('identifierSchemeCodeUnspsc190501.50314914'),
	'identifierSchemeCodeUnspsc190501.50314915': __('identifierSchemeCodeUnspsc190501.50314915'),
	'identifierSchemeCodeUnspsc190501.50314916': __('identifierSchemeCodeUnspsc190501.50314916'),
	'identifierSchemeCodeUnspsc190501.50314917': __('identifierSchemeCodeUnspsc190501.50314917'),
	'identifierSchemeCodeUnspsc190501.50314918': __('identifierSchemeCodeUnspsc190501.50314918'),
	'identifierSchemeCodeUnspsc190501.50314919': __('identifierSchemeCodeUnspsc190501.50314919'),
	'identifierSchemeCodeUnspsc190501.50314920': __('identifierSchemeCodeUnspsc190501.50314920'),
	'identifierSchemeCodeUnspsc190501.50314921': __('identifierSchemeCodeUnspsc190501.50314921'),
	'identifierSchemeCodeUnspsc190501.50314922': __('identifierSchemeCodeUnspsc190501.50314922'),
	'identifierSchemeCodeUnspsc190501.50314923': __('identifierSchemeCodeUnspsc190501.50314923'),
	'identifierSchemeCodeUnspsc190501.50314924': __('identifierSchemeCodeUnspsc190501.50314924'),
	'identifierSchemeCodeUnspsc190501.50314925': __('identifierSchemeCodeUnspsc190501.50314925'),
	'identifierSchemeCodeUnspsc190501.50314926': __('identifierSchemeCodeUnspsc190501.50314926'),
	'identifierSchemeCodeUnspsc190501.50314927': __('identifierSchemeCodeUnspsc190501.50314927'),
	'identifierSchemeCodeUnspsc190501.50314928': __('identifierSchemeCodeUnspsc190501.50314928'),
	'identifierSchemeCodeUnspsc190501.50314929': __('identifierSchemeCodeUnspsc190501.50314929'),
	'identifierSchemeCodeUnspsc190501.50314930': __('identifierSchemeCodeUnspsc190501.50314930'),
	'identifierSchemeCodeUnspsc190501.50314931': __('identifierSchemeCodeUnspsc190501.50314931'),
	'identifierSchemeCodeUnspsc190501.50314932': __('identifierSchemeCodeUnspsc190501.50314932'),
	'identifierSchemeCodeUnspsc190501.50314933': __('identifierSchemeCodeUnspsc190501.50314933'),
	'identifierSchemeCodeUnspsc190501.50314934': __('identifierSchemeCodeUnspsc190501.50314934'),
	'identifierSchemeCodeUnspsc190501.50314935': __('identifierSchemeCodeUnspsc190501.50314935'),
	'identifierSchemeCodeUnspsc190501.50314936': __('identifierSchemeCodeUnspsc190501.50314936'),
	'identifierSchemeCodeUnspsc190501.50314937': __('identifierSchemeCodeUnspsc190501.50314937'),
	'identifierSchemeCodeUnspsc190501.50314938': __('identifierSchemeCodeUnspsc190501.50314938'),
	'identifierSchemeCodeUnspsc190501.50314939': __('identifierSchemeCodeUnspsc190501.50314939'),
	'identifierSchemeCodeUnspsc190501.50314940': __('identifierSchemeCodeUnspsc190501.50314940'),
	'identifierSchemeCodeUnspsc190501.50314941': __('identifierSchemeCodeUnspsc190501.50314941'),
	'identifierSchemeCodeUnspsc190501.50314942': __('identifierSchemeCodeUnspsc190501.50314942'),
	'identifierSchemeCodeUnspsc190501.50314943': __('identifierSchemeCodeUnspsc190501.50314943'),
	'identifierSchemeCodeUnspsc190501.50314944': __('identifierSchemeCodeUnspsc190501.50314944'),
	'identifierSchemeCodeUnspsc190501.50314945': __('identifierSchemeCodeUnspsc190501.50314945'),
	'identifierSchemeCodeUnspsc190501.50314946': __('identifierSchemeCodeUnspsc190501.50314946'),
	'identifierSchemeCodeUnspsc190501.50314947': __('identifierSchemeCodeUnspsc190501.50314947'),
	'identifierSchemeCodeUnspsc190501.50314948': __('identifierSchemeCodeUnspsc190501.50314948'),
	'identifierSchemeCodeUnspsc190501.50314949': __('identifierSchemeCodeUnspsc190501.50314949'),
	'identifierSchemeCodeUnspsc190501.50314950': __('identifierSchemeCodeUnspsc190501.50314950'),
	'identifierSchemeCodeUnspsc190501.50314951': __('identifierSchemeCodeUnspsc190501.50314951'),
	'identifierSchemeCodeUnspsc190501.50314952': __('identifierSchemeCodeUnspsc190501.50314952'),
	'identifierSchemeCodeUnspsc190501.50314953': __('identifierSchemeCodeUnspsc190501.50314953'),
	'identifierSchemeCodeUnspsc190501.50314954': __('identifierSchemeCodeUnspsc190501.50314954'),
	'identifierSchemeCodeUnspsc190501.50314955': __('identifierSchemeCodeUnspsc190501.50314955'),
	'identifierSchemeCodeUnspsc190501.50314956': __('identifierSchemeCodeUnspsc190501.50314956'),
	'identifierSchemeCodeUnspsc190501.50314957': __('identifierSchemeCodeUnspsc190501.50314957'),
	'identifierSchemeCodeUnspsc190501.50315000': __('identifierSchemeCodeUnspsc190501.50315000'),
	'identifierSchemeCodeUnspsc190501.50315001': __('identifierSchemeCodeUnspsc190501.50315001'),
	'identifierSchemeCodeUnspsc190501.50315002': __('identifierSchemeCodeUnspsc190501.50315002'),
	'identifierSchemeCodeUnspsc190501.50315003': __('identifierSchemeCodeUnspsc190501.50315003'),
	'identifierSchemeCodeUnspsc190501.50315004': __('identifierSchemeCodeUnspsc190501.50315004'),
	'identifierSchemeCodeUnspsc190501.50315005': __('identifierSchemeCodeUnspsc190501.50315005'),
	'identifierSchemeCodeUnspsc190501.50315006': __('identifierSchemeCodeUnspsc190501.50315006'),
	'identifierSchemeCodeUnspsc190501.50315007': __('identifierSchemeCodeUnspsc190501.50315007'),
	'identifierSchemeCodeUnspsc190501.50315008': __('identifierSchemeCodeUnspsc190501.50315008'),
	'identifierSchemeCodeUnspsc190501.50315009': __('identifierSchemeCodeUnspsc190501.50315009'),
	'identifierSchemeCodeUnspsc190501.50315010': __('identifierSchemeCodeUnspsc190501.50315010'),
	'identifierSchemeCodeUnspsc190501.50315011': __('identifierSchemeCodeUnspsc190501.50315011'),
	'identifierSchemeCodeUnspsc190501.50315012': __('identifierSchemeCodeUnspsc190501.50315012'),
	'identifierSchemeCodeUnspsc190501.50315013': __('identifierSchemeCodeUnspsc190501.50315013'),
	'identifierSchemeCodeUnspsc190501.50315014': __('identifierSchemeCodeUnspsc190501.50315014'),
	'identifierSchemeCodeUnspsc190501.50315015': __('identifierSchemeCodeUnspsc190501.50315015'),
	'identifierSchemeCodeUnspsc190501.50315016': __('identifierSchemeCodeUnspsc190501.50315016'),
	'identifierSchemeCodeUnspsc190501.50315017': __('identifierSchemeCodeUnspsc190501.50315017'),
	'identifierSchemeCodeUnspsc190501.50315018': __('identifierSchemeCodeUnspsc190501.50315018'),
	'identifierSchemeCodeUnspsc190501.50315019': __('identifierSchemeCodeUnspsc190501.50315019'),
	'identifierSchemeCodeUnspsc190501.50315020': __('identifierSchemeCodeUnspsc190501.50315020'),
	'identifierSchemeCodeUnspsc190501.50315021': __('identifierSchemeCodeUnspsc190501.50315021'),
	'identifierSchemeCodeUnspsc190501.50315022': __('identifierSchemeCodeUnspsc190501.50315022'),
	'identifierSchemeCodeUnspsc190501.50315023': __('identifierSchemeCodeUnspsc190501.50315023'),
	'identifierSchemeCodeUnspsc190501.50315024': __('identifierSchemeCodeUnspsc190501.50315024'),
	'identifierSchemeCodeUnspsc190501.50315025': __('identifierSchemeCodeUnspsc190501.50315025'),
	'identifierSchemeCodeUnspsc190501.50315026': __('identifierSchemeCodeUnspsc190501.50315026'),
	'identifierSchemeCodeUnspsc190501.50315027': __('identifierSchemeCodeUnspsc190501.50315027'),
	'identifierSchemeCodeUnspsc190501.50315028': __('identifierSchemeCodeUnspsc190501.50315028'),
	'identifierSchemeCodeUnspsc190501.50315029': __('identifierSchemeCodeUnspsc190501.50315029'),
	'identifierSchemeCodeUnspsc190501.50315030': __('identifierSchemeCodeUnspsc190501.50315030'),
	'identifierSchemeCodeUnspsc190501.50315031': __('identifierSchemeCodeUnspsc190501.50315031'),
	'identifierSchemeCodeUnspsc190501.50315032': __('identifierSchemeCodeUnspsc190501.50315032'),
	'identifierSchemeCodeUnspsc190501.50315033': __('identifierSchemeCodeUnspsc190501.50315033'),
	'identifierSchemeCodeUnspsc190501.50315034': __('identifierSchemeCodeUnspsc190501.50315034'),
	'identifierSchemeCodeUnspsc190501.50315035': __('identifierSchemeCodeUnspsc190501.50315035'),
	'identifierSchemeCodeUnspsc190501.50315036': __('identifierSchemeCodeUnspsc190501.50315036'),
	'identifierSchemeCodeUnspsc190501.50315037': __('identifierSchemeCodeUnspsc190501.50315037'),
	'identifierSchemeCodeUnspsc190501.50315038': __('identifierSchemeCodeUnspsc190501.50315038'),
	'identifierSchemeCodeUnspsc190501.50315039': __('identifierSchemeCodeUnspsc190501.50315039'),
	'identifierSchemeCodeUnspsc190501.50315040': __('identifierSchemeCodeUnspsc190501.50315040'),
	'identifierSchemeCodeUnspsc190501.50315041': __('identifierSchemeCodeUnspsc190501.50315041'),
	'identifierSchemeCodeUnspsc190501.50315042': __('identifierSchemeCodeUnspsc190501.50315042'),
	'identifierSchemeCodeUnspsc190501.50315043': __('identifierSchemeCodeUnspsc190501.50315043'),
	'identifierSchemeCodeUnspsc190501.50315044': __('identifierSchemeCodeUnspsc190501.50315044'),
	'identifierSchemeCodeUnspsc190501.50315100': __('identifierSchemeCodeUnspsc190501.50315100'),
	'identifierSchemeCodeUnspsc190501.50315101': __('identifierSchemeCodeUnspsc190501.50315101'),
	'identifierSchemeCodeUnspsc190501.50315102': __('identifierSchemeCodeUnspsc190501.50315102'),
	'identifierSchemeCodeUnspsc190501.50315103': __('identifierSchemeCodeUnspsc190501.50315103'),
	'identifierSchemeCodeUnspsc190501.50315104': __('identifierSchemeCodeUnspsc190501.50315104'),
	'identifierSchemeCodeUnspsc190501.50315105': __('identifierSchemeCodeUnspsc190501.50315105'),
	'identifierSchemeCodeUnspsc190501.50315106': __('identifierSchemeCodeUnspsc190501.50315106'),
	'identifierSchemeCodeUnspsc190501.50315200': __('identifierSchemeCodeUnspsc190501.50315200'),
	'identifierSchemeCodeUnspsc190501.50315201': __('identifierSchemeCodeUnspsc190501.50315201'),
	'identifierSchemeCodeUnspsc190501.50315202': __('identifierSchemeCodeUnspsc190501.50315202'),
	'identifierSchemeCodeUnspsc190501.50315203': __('identifierSchemeCodeUnspsc190501.50315203'),
	'identifierSchemeCodeUnspsc190501.50315204': __('identifierSchemeCodeUnspsc190501.50315204'),
	'identifierSchemeCodeUnspsc190501.50315205': __('identifierSchemeCodeUnspsc190501.50315205'),
	'identifierSchemeCodeUnspsc190501.50315206': __('identifierSchemeCodeUnspsc190501.50315206'),
	'identifierSchemeCodeUnspsc190501.50315207': __('identifierSchemeCodeUnspsc190501.50315207'),
	'identifierSchemeCodeUnspsc190501.50315208': __('identifierSchemeCodeUnspsc190501.50315208'),
	'identifierSchemeCodeUnspsc190501.50315209': __('identifierSchemeCodeUnspsc190501.50315209'),
	'identifierSchemeCodeUnspsc190501.50315210': __('identifierSchemeCodeUnspsc190501.50315210'),
	'identifierSchemeCodeUnspsc190501.50315300': __('identifierSchemeCodeUnspsc190501.50315300'),
	'identifierSchemeCodeUnspsc190501.50315301': __('identifierSchemeCodeUnspsc190501.50315301'),
	'identifierSchemeCodeUnspsc190501.50315302': __('identifierSchemeCodeUnspsc190501.50315302'),
	'identifierSchemeCodeUnspsc190501.50315303': __('identifierSchemeCodeUnspsc190501.50315303'),
	'identifierSchemeCodeUnspsc190501.50315304': __('identifierSchemeCodeUnspsc190501.50315304'),
	'identifierSchemeCodeUnspsc190501.50315305': __('identifierSchemeCodeUnspsc190501.50315305'),
	'identifierSchemeCodeUnspsc190501.50315306': __('identifierSchemeCodeUnspsc190501.50315306'),
	'identifierSchemeCodeUnspsc190501.50315307': __('identifierSchemeCodeUnspsc190501.50315307'),
	'identifierSchemeCodeUnspsc190501.50315308': __('identifierSchemeCodeUnspsc190501.50315308'),
	'identifierSchemeCodeUnspsc190501.50315309': __('identifierSchemeCodeUnspsc190501.50315309'),
	'identifierSchemeCodeUnspsc190501.50315310': __('identifierSchemeCodeUnspsc190501.50315310'),
	'identifierSchemeCodeUnspsc190501.50315311': __('identifierSchemeCodeUnspsc190501.50315311'),
	'identifierSchemeCodeUnspsc190501.50315312': __('identifierSchemeCodeUnspsc190501.50315312'),
	'identifierSchemeCodeUnspsc190501.50315313': __('identifierSchemeCodeUnspsc190501.50315313'),
	'identifierSchemeCodeUnspsc190501.50315314': __('identifierSchemeCodeUnspsc190501.50315314'),
	'identifierSchemeCodeUnspsc190501.50315315': __('identifierSchemeCodeUnspsc190501.50315315'),
	'identifierSchemeCodeUnspsc190501.50315316': __('identifierSchemeCodeUnspsc190501.50315316'),
	'identifierSchemeCodeUnspsc190501.50315317': __('identifierSchemeCodeUnspsc190501.50315317'),
	'identifierSchemeCodeUnspsc190501.50315318': __('identifierSchemeCodeUnspsc190501.50315318'),
	'identifierSchemeCodeUnspsc190501.50315319': __('identifierSchemeCodeUnspsc190501.50315319'),
	'identifierSchemeCodeUnspsc190501.50315320': __('identifierSchemeCodeUnspsc190501.50315320'),
	'identifierSchemeCodeUnspsc190501.50315321': __('identifierSchemeCodeUnspsc190501.50315321'),
	'identifierSchemeCodeUnspsc190501.50315322': __('identifierSchemeCodeUnspsc190501.50315322'),
	'identifierSchemeCodeUnspsc190501.50315323': __('identifierSchemeCodeUnspsc190501.50315323'),
	'identifierSchemeCodeUnspsc190501.50315324': __('identifierSchemeCodeUnspsc190501.50315324'),
	'identifierSchemeCodeUnspsc190501.50315325': __('identifierSchemeCodeUnspsc190501.50315325'),
	'identifierSchemeCodeUnspsc190501.50315326': __('identifierSchemeCodeUnspsc190501.50315326'),
	'identifierSchemeCodeUnspsc190501.50315327': __('identifierSchemeCodeUnspsc190501.50315327'),
	'identifierSchemeCodeUnspsc190501.50315328': __('identifierSchemeCodeUnspsc190501.50315328'),
	'identifierSchemeCodeUnspsc190501.50315329': __('identifierSchemeCodeUnspsc190501.50315329'),
	'identifierSchemeCodeUnspsc190501.50315330': __('identifierSchemeCodeUnspsc190501.50315330'),
	'identifierSchemeCodeUnspsc190501.50315331': __('identifierSchemeCodeUnspsc190501.50315331'),
	'identifierSchemeCodeUnspsc190501.50315332': __('identifierSchemeCodeUnspsc190501.50315332'),
	'identifierSchemeCodeUnspsc190501.50315333': __('identifierSchemeCodeUnspsc190501.50315333'),
	'identifierSchemeCodeUnspsc190501.50315334': __('identifierSchemeCodeUnspsc190501.50315334'),
	'identifierSchemeCodeUnspsc190501.50315335': __('identifierSchemeCodeUnspsc190501.50315335'),
	'identifierSchemeCodeUnspsc190501.50315336': __('identifierSchemeCodeUnspsc190501.50315336'),
	'identifierSchemeCodeUnspsc190501.50315337': __('identifierSchemeCodeUnspsc190501.50315337'),
	'identifierSchemeCodeUnspsc190501.50315338': __('identifierSchemeCodeUnspsc190501.50315338'),
	'identifierSchemeCodeUnspsc190501.50315339': __('identifierSchemeCodeUnspsc190501.50315339'),
	'identifierSchemeCodeUnspsc190501.50315340': __('identifierSchemeCodeUnspsc190501.50315340'),
	'identifierSchemeCodeUnspsc190501.50315341': __('identifierSchemeCodeUnspsc190501.50315341'),
	'identifierSchemeCodeUnspsc190501.50315342': __('identifierSchemeCodeUnspsc190501.50315342'),
	'identifierSchemeCodeUnspsc190501.50315343': __('identifierSchemeCodeUnspsc190501.50315343'),
	'identifierSchemeCodeUnspsc190501.50315344': __('identifierSchemeCodeUnspsc190501.50315344'),
	'identifierSchemeCodeUnspsc190501.50315345': __('identifierSchemeCodeUnspsc190501.50315345'),
	'identifierSchemeCodeUnspsc190501.50315346': __('identifierSchemeCodeUnspsc190501.50315346'),
	'identifierSchemeCodeUnspsc190501.50315347': __('identifierSchemeCodeUnspsc190501.50315347'),
	'identifierSchemeCodeUnspsc190501.50315348': __('identifierSchemeCodeUnspsc190501.50315348'),
	'identifierSchemeCodeUnspsc190501.50315349': __('identifierSchemeCodeUnspsc190501.50315349'),
	'identifierSchemeCodeUnspsc190501.50315350': __('identifierSchemeCodeUnspsc190501.50315350'),
	'identifierSchemeCodeUnspsc190501.50315351': __('identifierSchemeCodeUnspsc190501.50315351'),
	'identifierSchemeCodeUnspsc190501.50315352': __('identifierSchemeCodeUnspsc190501.50315352'),
	'identifierSchemeCodeUnspsc190501.50315353': __('identifierSchemeCodeUnspsc190501.50315353'),
	'identifierSchemeCodeUnspsc190501.50315354': __('identifierSchemeCodeUnspsc190501.50315354'),
	'identifierSchemeCodeUnspsc190501.50315355': __('identifierSchemeCodeUnspsc190501.50315355'),
	'identifierSchemeCodeUnspsc190501.50315356': __('identifierSchemeCodeUnspsc190501.50315356'),
	'identifierSchemeCodeUnspsc190501.50315357': __('identifierSchemeCodeUnspsc190501.50315357'),
	'identifierSchemeCodeUnspsc190501.50315358': __('identifierSchemeCodeUnspsc190501.50315358'),
	'identifierSchemeCodeUnspsc190501.50315359': __('identifierSchemeCodeUnspsc190501.50315359'),
	'identifierSchemeCodeUnspsc190501.50315360': __('identifierSchemeCodeUnspsc190501.50315360'),
	'identifierSchemeCodeUnspsc190501.50315361': __('identifierSchemeCodeUnspsc190501.50315361'),
	'identifierSchemeCodeUnspsc190501.50315362': __('identifierSchemeCodeUnspsc190501.50315362'),
	'identifierSchemeCodeUnspsc190501.50315363': __('identifierSchemeCodeUnspsc190501.50315363'),
	'identifierSchemeCodeUnspsc190501.50315364': __('identifierSchemeCodeUnspsc190501.50315364'),
	'identifierSchemeCodeUnspsc190501.50315365': __('identifierSchemeCodeUnspsc190501.50315365'),
	'identifierSchemeCodeUnspsc190501.50315366': __('identifierSchemeCodeUnspsc190501.50315366'),
	'identifierSchemeCodeUnspsc190501.50315367': __('identifierSchemeCodeUnspsc190501.50315367'),
	'identifierSchemeCodeUnspsc190501.50315368': __('identifierSchemeCodeUnspsc190501.50315368'),
	'identifierSchemeCodeUnspsc190501.50315369': __('identifierSchemeCodeUnspsc190501.50315369'),
	'identifierSchemeCodeUnspsc190501.50315370': __('identifierSchemeCodeUnspsc190501.50315370'),
	'identifierSchemeCodeUnspsc190501.50315371': __('identifierSchemeCodeUnspsc190501.50315371'),
	'identifierSchemeCodeUnspsc190501.50315400': __('identifierSchemeCodeUnspsc190501.50315400'),
	'identifierSchemeCodeUnspsc190501.50315401': __('identifierSchemeCodeUnspsc190501.50315401'),
	'identifierSchemeCodeUnspsc190501.50315402': __('identifierSchemeCodeUnspsc190501.50315402'),
	'identifierSchemeCodeUnspsc190501.50315403': __('identifierSchemeCodeUnspsc190501.50315403'),
	'identifierSchemeCodeUnspsc190501.50315404': __('identifierSchemeCodeUnspsc190501.50315404'),
	'identifierSchemeCodeUnspsc190501.50315405': __('identifierSchemeCodeUnspsc190501.50315405'),
	'identifierSchemeCodeUnspsc190501.50315406': __('identifierSchemeCodeUnspsc190501.50315406'),
	'identifierSchemeCodeUnspsc190501.50315407': __('identifierSchemeCodeUnspsc190501.50315407'),
	'identifierSchemeCodeUnspsc190501.50315408': __('identifierSchemeCodeUnspsc190501.50315408'),
	'identifierSchemeCodeUnspsc190501.50315409': __('identifierSchemeCodeUnspsc190501.50315409'),
	'identifierSchemeCodeUnspsc190501.50315410': __('identifierSchemeCodeUnspsc190501.50315410'),
	'identifierSchemeCodeUnspsc190501.50315411': __('identifierSchemeCodeUnspsc190501.50315411'),
	'identifierSchemeCodeUnspsc190501.50315412': __('identifierSchemeCodeUnspsc190501.50315412'),
	'identifierSchemeCodeUnspsc190501.50315413': __('identifierSchemeCodeUnspsc190501.50315413'),
	'identifierSchemeCodeUnspsc190501.50315414': __('identifierSchemeCodeUnspsc190501.50315414'),
	'identifierSchemeCodeUnspsc190501.50315415': __('identifierSchemeCodeUnspsc190501.50315415'),
	'identifierSchemeCodeUnspsc190501.50315416': __('identifierSchemeCodeUnspsc190501.50315416'),
	'identifierSchemeCodeUnspsc190501.50315417': __('identifierSchemeCodeUnspsc190501.50315417'),
	'identifierSchemeCodeUnspsc190501.50315418': __('identifierSchemeCodeUnspsc190501.50315418'),
	'identifierSchemeCodeUnspsc190501.50315419': __('identifierSchemeCodeUnspsc190501.50315419'),
	'identifierSchemeCodeUnspsc190501.50315420': __('identifierSchemeCodeUnspsc190501.50315420'),
	'identifierSchemeCodeUnspsc190501.50315421': __('identifierSchemeCodeUnspsc190501.50315421'),
	'identifierSchemeCodeUnspsc190501.50315422': __('identifierSchemeCodeUnspsc190501.50315422'),
	'identifierSchemeCodeUnspsc190501.50315423': __('identifierSchemeCodeUnspsc190501.50315423'),
	'identifierSchemeCodeUnspsc190501.50315424': __('identifierSchemeCodeUnspsc190501.50315424'),
	'identifierSchemeCodeUnspsc190501.50315425': __('identifierSchemeCodeUnspsc190501.50315425'),
	'identifierSchemeCodeUnspsc190501.50315426': __('identifierSchemeCodeUnspsc190501.50315426'),
	'identifierSchemeCodeUnspsc190501.50315427': __('identifierSchemeCodeUnspsc190501.50315427'),
	'identifierSchemeCodeUnspsc190501.50315428': __('identifierSchemeCodeUnspsc190501.50315428'),
	'identifierSchemeCodeUnspsc190501.50315429': __('identifierSchemeCodeUnspsc190501.50315429'),
	'identifierSchemeCodeUnspsc190501.50315430': __('identifierSchemeCodeUnspsc190501.50315430'),
	'identifierSchemeCodeUnspsc190501.50315431': __('identifierSchemeCodeUnspsc190501.50315431'),
	'identifierSchemeCodeUnspsc190501.50315432': __('identifierSchemeCodeUnspsc190501.50315432'),
	'identifierSchemeCodeUnspsc190501.50315433': __('identifierSchemeCodeUnspsc190501.50315433'),
	'identifierSchemeCodeUnspsc190501.50315434': __('identifierSchemeCodeUnspsc190501.50315434'),
	'identifierSchemeCodeUnspsc190501.50315435': __('identifierSchemeCodeUnspsc190501.50315435'),
	'identifierSchemeCodeUnspsc190501.50315436': __('identifierSchemeCodeUnspsc190501.50315436'),
	'identifierSchemeCodeUnspsc190501.50315437': __('identifierSchemeCodeUnspsc190501.50315437'),
	'identifierSchemeCodeUnspsc190501.50315438': __('identifierSchemeCodeUnspsc190501.50315438'),
	'identifierSchemeCodeUnspsc190501.50315439': __('identifierSchemeCodeUnspsc190501.50315439'),
	'identifierSchemeCodeUnspsc190501.50315440': __('identifierSchemeCodeUnspsc190501.50315440'),
	'identifierSchemeCodeUnspsc190501.50315441': __('identifierSchemeCodeUnspsc190501.50315441'),
	'identifierSchemeCodeUnspsc190501.50315442': __('identifierSchemeCodeUnspsc190501.50315442'),
	'identifierSchemeCodeUnspsc190501.50315443': __('identifierSchemeCodeUnspsc190501.50315443'),
	'identifierSchemeCodeUnspsc190501.50315444': __('identifierSchemeCodeUnspsc190501.50315444'),
	'identifierSchemeCodeUnspsc190501.50315445': __('identifierSchemeCodeUnspsc190501.50315445'),
	'identifierSchemeCodeUnspsc190501.50315446': __('identifierSchemeCodeUnspsc190501.50315446'),
	'identifierSchemeCodeUnspsc190501.50315447': __('identifierSchemeCodeUnspsc190501.50315447'),
	'identifierSchemeCodeUnspsc190501.50315448': __('identifierSchemeCodeUnspsc190501.50315448'),
	'identifierSchemeCodeUnspsc190501.50315449': __('identifierSchemeCodeUnspsc190501.50315449'),
	'identifierSchemeCodeUnspsc190501.50315450': __('identifierSchemeCodeUnspsc190501.50315450'),
	'identifierSchemeCodeUnspsc190501.50315451': __('identifierSchemeCodeUnspsc190501.50315451'),
	'identifierSchemeCodeUnspsc190501.50315452': __('identifierSchemeCodeUnspsc190501.50315452'),
	'identifierSchemeCodeUnspsc190501.50315453': __('identifierSchemeCodeUnspsc190501.50315453'),
	'identifierSchemeCodeUnspsc190501.50315454': __('identifierSchemeCodeUnspsc190501.50315454'),
	'identifierSchemeCodeUnspsc190501.50315455': __('identifierSchemeCodeUnspsc190501.50315455'),
	'identifierSchemeCodeUnspsc190501.50315456': __('identifierSchemeCodeUnspsc190501.50315456'),
	'identifierSchemeCodeUnspsc190501.50315457': __('identifierSchemeCodeUnspsc190501.50315457'),
	'identifierSchemeCodeUnspsc190501.50315458': __('identifierSchemeCodeUnspsc190501.50315458'),
	'identifierSchemeCodeUnspsc190501.50315459': __('identifierSchemeCodeUnspsc190501.50315459'),
	'identifierSchemeCodeUnspsc190501.50315460': __('identifierSchemeCodeUnspsc190501.50315460'),
	'identifierSchemeCodeUnspsc190501.50315500': __('identifierSchemeCodeUnspsc190501.50315500'),
	'identifierSchemeCodeUnspsc190501.50315501': __('identifierSchemeCodeUnspsc190501.50315501'),
	'identifierSchemeCodeUnspsc190501.50315502': __('identifierSchemeCodeUnspsc190501.50315502'),
	'identifierSchemeCodeUnspsc190501.50315503': __('identifierSchemeCodeUnspsc190501.50315503'),
	'identifierSchemeCodeUnspsc190501.50315504': __('identifierSchemeCodeUnspsc190501.50315504'),
	'identifierSchemeCodeUnspsc190501.50315505': __('identifierSchemeCodeUnspsc190501.50315505'),
	'identifierSchemeCodeUnspsc190501.50315506': __('identifierSchemeCodeUnspsc190501.50315506'),
	'identifierSchemeCodeUnspsc190501.50315507': __('identifierSchemeCodeUnspsc190501.50315507'),
	'identifierSchemeCodeUnspsc190501.50315508': __('identifierSchemeCodeUnspsc190501.50315508'),
	'identifierSchemeCodeUnspsc190501.50315509': __('identifierSchemeCodeUnspsc190501.50315509'),
	'identifierSchemeCodeUnspsc190501.50315510': __('identifierSchemeCodeUnspsc190501.50315510'),
	'identifierSchemeCodeUnspsc190501.50315511': __('identifierSchemeCodeUnspsc190501.50315511'),
	'identifierSchemeCodeUnspsc190501.50315512': __('identifierSchemeCodeUnspsc190501.50315512'),
	'identifierSchemeCodeUnspsc190501.50315600': __('identifierSchemeCodeUnspsc190501.50315600'),
	'identifierSchemeCodeUnspsc190501.50315601': __('identifierSchemeCodeUnspsc190501.50315601'),
	'identifierSchemeCodeUnspsc190501.50315602': __('identifierSchemeCodeUnspsc190501.50315602'),
	'identifierSchemeCodeUnspsc190501.50315603': __('identifierSchemeCodeUnspsc190501.50315603'),
	'identifierSchemeCodeUnspsc190501.50315604': __('identifierSchemeCodeUnspsc190501.50315604'),
	'identifierSchemeCodeUnspsc190501.50315605': __('identifierSchemeCodeUnspsc190501.50315605'),
	'identifierSchemeCodeUnspsc190501.50315606': __('identifierSchemeCodeUnspsc190501.50315606'),
	'identifierSchemeCodeUnspsc190501.50315607': __('identifierSchemeCodeUnspsc190501.50315607'),
	'identifierSchemeCodeUnspsc190501.50315608': __('identifierSchemeCodeUnspsc190501.50315608'),
	'identifierSchemeCodeUnspsc190501.50315609': __('identifierSchemeCodeUnspsc190501.50315609'),
	'identifierSchemeCodeUnspsc190501.50315610': __('identifierSchemeCodeUnspsc190501.50315610'),
	'identifierSchemeCodeUnspsc190501.50315700': __('identifierSchemeCodeUnspsc190501.50315700'),
	'identifierSchemeCodeUnspsc190501.50315701': __('identifierSchemeCodeUnspsc190501.50315701'),
	'identifierSchemeCodeUnspsc190501.50315702': __('identifierSchemeCodeUnspsc190501.50315702'),
	'identifierSchemeCodeUnspsc190501.50315703': __('identifierSchemeCodeUnspsc190501.50315703'),
	'identifierSchemeCodeUnspsc190501.50315704': __('identifierSchemeCodeUnspsc190501.50315704'),
	'identifierSchemeCodeUnspsc190501.50315705': __('identifierSchemeCodeUnspsc190501.50315705'),
	'identifierSchemeCodeUnspsc190501.50315706': __('identifierSchemeCodeUnspsc190501.50315706'),
	'identifierSchemeCodeUnspsc190501.50315707': __('identifierSchemeCodeUnspsc190501.50315707'),
	'identifierSchemeCodeUnspsc190501.50315708': __('identifierSchemeCodeUnspsc190501.50315708'),
	'identifierSchemeCodeUnspsc190501.50315709': __('identifierSchemeCodeUnspsc190501.50315709'),
	'identifierSchemeCodeUnspsc190501.50315710': __('identifierSchemeCodeUnspsc190501.50315710'),
	'identifierSchemeCodeUnspsc190501.50315711': __('identifierSchemeCodeUnspsc190501.50315711'),
	'identifierSchemeCodeUnspsc190501.50315712': __('identifierSchemeCodeUnspsc190501.50315712'),
	'identifierSchemeCodeUnspsc190501.50315713': __('identifierSchemeCodeUnspsc190501.50315713'),
	'identifierSchemeCodeUnspsc190501.50315714': __('identifierSchemeCodeUnspsc190501.50315714'),
	'identifierSchemeCodeUnspsc190501.50315715': __('identifierSchemeCodeUnspsc190501.50315715'),
	'identifierSchemeCodeUnspsc190501.50315716': __('identifierSchemeCodeUnspsc190501.50315716'),
	'identifierSchemeCodeUnspsc190501.50315717': __('identifierSchemeCodeUnspsc190501.50315717'),
	'identifierSchemeCodeUnspsc190501.50315718': __('identifierSchemeCodeUnspsc190501.50315718'),
	'identifierSchemeCodeUnspsc190501.50315719': __('identifierSchemeCodeUnspsc190501.50315719'),
	'identifierSchemeCodeUnspsc190501.50315720': __('identifierSchemeCodeUnspsc190501.50315720'),
	'identifierSchemeCodeUnspsc190501.50315721': __('identifierSchemeCodeUnspsc190501.50315721'),
	'identifierSchemeCodeUnspsc190501.50315722': __('identifierSchemeCodeUnspsc190501.50315722'),
	'identifierSchemeCodeUnspsc190501.50315723': __('identifierSchemeCodeUnspsc190501.50315723'),
	'identifierSchemeCodeUnspsc190501.50315800': __('identifierSchemeCodeUnspsc190501.50315800'),
	'identifierSchemeCodeUnspsc190501.50315801': __('identifierSchemeCodeUnspsc190501.50315801'),
	'identifierSchemeCodeUnspsc190501.50315802': __('identifierSchemeCodeUnspsc190501.50315802'),
	'identifierSchemeCodeUnspsc190501.50315803': __('identifierSchemeCodeUnspsc190501.50315803'),
	'identifierSchemeCodeUnspsc190501.50315804': __('identifierSchemeCodeUnspsc190501.50315804'),
	'identifierSchemeCodeUnspsc190501.50315805': __('identifierSchemeCodeUnspsc190501.50315805'),
	'identifierSchemeCodeUnspsc190501.50315806': __('identifierSchemeCodeUnspsc190501.50315806'),
	'identifierSchemeCodeUnspsc190501.50315807': __('identifierSchemeCodeUnspsc190501.50315807'),
	'identifierSchemeCodeUnspsc190501.50315808': __('identifierSchemeCodeUnspsc190501.50315808'),
	'identifierSchemeCodeUnspsc190501.50315809': __('identifierSchemeCodeUnspsc190501.50315809'),
	'identifierSchemeCodeUnspsc190501.50315810': __('identifierSchemeCodeUnspsc190501.50315810'),
	'identifierSchemeCodeUnspsc190501.50315811': __('identifierSchemeCodeUnspsc190501.50315811'),
	'identifierSchemeCodeUnspsc190501.50315812': __('identifierSchemeCodeUnspsc190501.50315812'),
	'identifierSchemeCodeUnspsc190501.50315813': __('identifierSchemeCodeUnspsc190501.50315813'),
	'identifierSchemeCodeUnspsc190501.50315814': __('identifierSchemeCodeUnspsc190501.50315814'),
	'identifierSchemeCodeUnspsc190501.50315815': __('identifierSchemeCodeUnspsc190501.50315815'),
	'identifierSchemeCodeUnspsc190501.50315816': __('identifierSchemeCodeUnspsc190501.50315816'),
	'identifierSchemeCodeUnspsc190501.50315817': __('identifierSchemeCodeUnspsc190501.50315817'),
	'identifierSchemeCodeUnspsc190501.50315818': __('identifierSchemeCodeUnspsc190501.50315818'),
	'identifierSchemeCodeUnspsc190501.50315819': __('identifierSchemeCodeUnspsc190501.50315819'),
	'identifierSchemeCodeUnspsc190501.50315820': __('identifierSchemeCodeUnspsc190501.50315820'),
	'identifierSchemeCodeUnspsc190501.50315821': __('identifierSchemeCodeUnspsc190501.50315821'),
	'identifierSchemeCodeUnspsc190501.50315822': __('identifierSchemeCodeUnspsc190501.50315822'),
	'identifierSchemeCodeUnspsc190501.50315823': __('identifierSchemeCodeUnspsc190501.50315823'),
	'identifierSchemeCodeUnspsc190501.50315824': __('identifierSchemeCodeUnspsc190501.50315824'),
	'identifierSchemeCodeUnspsc190501.50315825': __('identifierSchemeCodeUnspsc190501.50315825'),
	'identifierSchemeCodeUnspsc190501.50315826': __('identifierSchemeCodeUnspsc190501.50315826'),
	'identifierSchemeCodeUnspsc190501.50315827': __('identifierSchemeCodeUnspsc190501.50315827'),
	'identifierSchemeCodeUnspsc190501.50315828': __('identifierSchemeCodeUnspsc190501.50315828'),
	'identifierSchemeCodeUnspsc190501.50315829': __('identifierSchemeCodeUnspsc190501.50315829'),
	'identifierSchemeCodeUnspsc190501.50315830': __('identifierSchemeCodeUnspsc190501.50315830'),
	'identifierSchemeCodeUnspsc190501.50315831': __('identifierSchemeCodeUnspsc190501.50315831'),
	'identifierSchemeCodeUnspsc190501.50315832': __('identifierSchemeCodeUnspsc190501.50315832'),
	'identifierSchemeCodeUnspsc190501.50315833': __('identifierSchemeCodeUnspsc190501.50315833'),
	'identifierSchemeCodeUnspsc190501.50315834': __('identifierSchemeCodeUnspsc190501.50315834'),
	'identifierSchemeCodeUnspsc190501.50315835': __('identifierSchemeCodeUnspsc190501.50315835'),
	'identifierSchemeCodeUnspsc190501.50315836': __('identifierSchemeCodeUnspsc190501.50315836'),
	'identifierSchemeCodeUnspsc190501.50315837': __('identifierSchemeCodeUnspsc190501.50315837'),
	'identifierSchemeCodeUnspsc190501.50315838': __('identifierSchemeCodeUnspsc190501.50315838'),
	'identifierSchemeCodeUnspsc190501.50315839': __('identifierSchemeCodeUnspsc190501.50315839'),
	'identifierSchemeCodeUnspsc190501.50315840': __('identifierSchemeCodeUnspsc190501.50315840'),
	'identifierSchemeCodeUnspsc190501.50315841': __('identifierSchemeCodeUnspsc190501.50315841'),
	'identifierSchemeCodeUnspsc190501.50315842': __('identifierSchemeCodeUnspsc190501.50315842'),
	'identifierSchemeCodeUnspsc190501.50315843': __('identifierSchemeCodeUnspsc190501.50315843'),
	'identifierSchemeCodeUnspsc190501.50315844': __('identifierSchemeCodeUnspsc190501.50315844'),
	'identifierSchemeCodeUnspsc190501.50315845': __('identifierSchemeCodeUnspsc190501.50315845'),
	'identifierSchemeCodeUnspsc190501.50315846': __('identifierSchemeCodeUnspsc190501.50315846'),
	'identifierSchemeCodeUnspsc190501.50315847': __('identifierSchemeCodeUnspsc190501.50315847'),
	'identifierSchemeCodeUnspsc190501.50315848': __('identifierSchemeCodeUnspsc190501.50315848'),
	'identifierSchemeCodeUnspsc190501.50315849': __('identifierSchemeCodeUnspsc190501.50315849'),
	'identifierSchemeCodeUnspsc190501.50315850': __('identifierSchemeCodeUnspsc190501.50315850'),
	'identifierSchemeCodeUnspsc190501.50315851': __('identifierSchemeCodeUnspsc190501.50315851'),
	'identifierSchemeCodeUnspsc190501.50315852': __('identifierSchemeCodeUnspsc190501.50315852'),
	'identifierSchemeCodeUnspsc190501.50315853': __('identifierSchemeCodeUnspsc190501.50315853'),
	'identifierSchemeCodeUnspsc190501.50315854': __('identifierSchemeCodeUnspsc190501.50315854'),
	'identifierSchemeCodeUnspsc190501.50315855': __('identifierSchemeCodeUnspsc190501.50315855'),
	'identifierSchemeCodeUnspsc190501.50315856': __('identifierSchemeCodeUnspsc190501.50315856'),
	'identifierSchemeCodeUnspsc190501.50315857': __('identifierSchemeCodeUnspsc190501.50315857'),
	'identifierSchemeCodeUnspsc190501.50315858': __('identifierSchemeCodeUnspsc190501.50315858'),
	'identifierSchemeCodeUnspsc190501.50315859': __('identifierSchemeCodeUnspsc190501.50315859'),
	'identifierSchemeCodeUnspsc190501.50315860': __('identifierSchemeCodeUnspsc190501.50315860'),
	'identifierSchemeCodeUnspsc190501.50315861': __('identifierSchemeCodeUnspsc190501.50315861'),
	'identifierSchemeCodeUnspsc190501.50315862': __('identifierSchemeCodeUnspsc190501.50315862'),
	'identifierSchemeCodeUnspsc190501.50315863': __('identifierSchemeCodeUnspsc190501.50315863'),
	'identifierSchemeCodeUnspsc190501.50315864': __('identifierSchemeCodeUnspsc190501.50315864'),
	'identifierSchemeCodeUnspsc190501.50315865': __('identifierSchemeCodeUnspsc190501.50315865'),
	'identifierSchemeCodeUnspsc190501.50315866': __('identifierSchemeCodeUnspsc190501.50315866'),
	'identifierSchemeCodeUnspsc190501.50315867': __('identifierSchemeCodeUnspsc190501.50315867'),
	'identifierSchemeCodeUnspsc190501.50315868': __('identifierSchemeCodeUnspsc190501.50315868'),
	'identifierSchemeCodeUnspsc190501.50315869': __('identifierSchemeCodeUnspsc190501.50315869'),
	'identifierSchemeCodeUnspsc190501.50315870': __('identifierSchemeCodeUnspsc190501.50315870'),
	'identifierSchemeCodeUnspsc190501.50315871': __('identifierSchemeCodeUnspsc190501.50315871'),
	'identifierSchemeCodeUnspsc190501.50315872': __('identifierSchemeCodeUnspsc190501.50315872'),
	'identifierSchemeCodeUnspsc190501.50315873': __('identifierSchemeCodeUnspsc190501.50315873'),
	'identifierSchemeCodeUnspsc190501.50315874': __('identifierSchemeCodeUnspsc190501.50315874'),
	'identifierSchemeCodeUnspsc190501.50315875': __('identifierSchemeCodeUnspsc190501.50315875'),
	'identifierSchemeCodeUnspsc190501.50315876': __('identifierSchemeCodeUnspsc190501.50315876'),
	'identifierSchemeCodeUnspsc190501.50315877': __('identifierSchemeCodeUnspsc190501.50315877'),
	'identifierSchemeCodeUnspsc190501.50315900': __('identifierSchemeCodeUnspsc190501.50315900'),
	'identifierSchemeCodeUnspsc190501.50315901': __('identifierSchemeCodeUnspsc190501.50315901'),
	'identifierSchemeCodeUnspsc190501.50315902': __('identifierSchemeCodeUnspsc190501.50315902'),
	'identifierSchemeCodeUnspsc190501.50315903': __('identifierSchemeCodeUnspsc190501.50315903'),
	'identifierSchemeCodeUnspsc190501.50315904': __('identifierSchemeCodeUnspsc190501.50315904'),
	'identifierSchemeCodeUnspsc190501.50315905': __('identifierSchemeCodeUnspsc190501.50315905'),
	'identifierSchemeCodeUnspsc190501.50315906': __('identifierSchemeCodeUnspsc190501.50315906'),
	'identifierSchemeCodeUnspsc190501.50315907': __('identifierSchemeCodeUnspsc190501.50315907'),
	'identifierSchemeCodeUnspsc190501.50315908': __('identifierSchemeCodeUnspsc190501.50315908'),
	'identifierSchemeCodeUnspsc190501.50316000': __('identifierSchemeCodeUnspsc190501.50316000'),
	'identifierSchemeCodeUnspsc190501.50316001': __('identifierSchemeCodeUnspsc190501.50316001'),
	'identifierSchemeCodeUnspsc190501.50316002': __('identifierSchemeCodeUnspsc190501.50316002'),
	'identifierSchemeCodeUnspsc190501.50316003': __('identifierSchemeCodeUnspsc190501.50316003'),
	'identifierSchemeCodeUnspsc190501.50316004': __('identifierSchemeCodeUnspsc190501.50316004'),
	'identifierSchemeCodeUnspsc190501.50316005': __('identifierSchemeCodeUnspsc190501.50316005'),
	'identifierSchemeCodeUnspsc190501.50316006': __('identifierSchemeCodeUnspsc190501.50316006'),
	'identifierSchemeCodeUnspsc190501.50316007': __('identifierSchemeCodeUnspsc190501.50316007'),
	'identifierSchemeCodeUnspsc190501.50316008': __('identifierSchemeCodeUnspsc190501.50316008'),
	'identifierSchemeCodeUnspsc190501.50316100': __('identifierSchemeCodeUnspsc190501.50316100'),
	'identifierSchemeCodeUnspsc190501.50316101': __('identifierSchemeCodeUnspsc190501.50316101'),
	'identifierSchemeCodeUnspsc190501.50316102': __('identifierSchemeCodeUnspsc190501.50316102'),
	'identifierSchemeCodeUnspsc190501.50316103': __('identifierSchemeCodeUnspsc190501.50316103'),
	'identifierSchemeCodeUnspsc190501.50316200': __('identifierSchemeCodeUnspsc190501.50316200'),
	'identifierSchemeCodeUnspsc190501.50316201': __('identifierSchemeCodeUnspsc190501.50316201'),
	'identifierSchemeCodeUnspsc190501.50316202': __('identifierSchemeCodeUnspsc190501.50316202'),
	'identifierSchemeCodeUnspsc190501.50316203': __('identifierSchemeCodeUnspsc190501.50316203'),
	'identifierSchemeCodeUnspsc190501.50316204': __('identifierSchemeCodeUnspsc190501.50316204'),
	'identifierSchemeCodeUnspsc190501.50316205': __('identifierSchemeCodeUnspsc190501.50316205'),
	'identifierSchemeCodeUnspsc190501.50316206': __('identifierSchemeCodeUnspsc190501.50316206'),
	'identifierSchemeCodeUnspsc190501.50316207': __('identifierSchemeCodeUnspsc190501.50316207'),
	'identifierSchemeCodeUnspsc190501.50316208': __('identifierSchemeCodeUnspsc190501.50316208'),
	'identifierSchemeCodeUnspsc190501.50316209': __('identifierSchemeCodeUnspsc190501.50316209'),
	'identifierSchemeCodeUnspsc190501.50316210': __('identifierSchemeCodeUnspsc190501.50316210'),
	'identifierSchemeCodeUnspsc190501.50316211': __('identifierSchemeCodeUnspsc190501.50316211'),
	'identifierSchemeCodeUnspsc190501.50316212': __('identifierSchemeCodeUnspsc190501.50316212'),
	'identifierSchemeCodeUnspsc190501.50316213': __('identifierSchemeCodeUnspsc190501.50316213'),
	'identifierSchemeCodeUnspsc190501.50316214': __('identifierSchemeCodeUnspsc190501.50316214'),
	'identifierSchemeCodeUnspsc190501.50316215': __('identifierSchemeCodeUnspsc190501.50316215'),
	'identifierSchemeCodeUnspsc190501.50316216': __('identifierSchemeCodeUnspsc190501.50316216'),
	'identifierSchemeCodeUnspsc190501.50316217': __('identifierSchemeCodeUnspsc190501.50316217'),
	'identifierSchemeCodeUnspsc190501.50316218': __('identifierSchemeCodeUnspsc190501.50316218'),
	'identifierSchemeCodeUnspsc190501.50316219': __('identifierSchemeCodeUnspsc190501.50316219'),
	'identifierSchemeCodeUnspsc190501.50316220': __('identifierSchemeCodeUnspsc190501.50316220'),
	'identifierSchemeCodeUnspsc190501.50316221': __('identifierSchemeCodeUnspsc190501.50316221'),
	'identifierSchemeCodeUnspsc190501.50316222': __('identifierSchemeCodeUnspsc190501.50316222'),
	'identifierSchemeCodeUnspsc190501.50316223': __('identifierSchemeCodeUnspsc190501.50316223'),
	'identifierSchemeCodeUnspsc190501.50316224': __('identifierSchemeCodeUnspsc190501.50316224'),
	'identifierSchemeCodeUnspsc190501.50316225': __('identifierSchemeCodeUnspsc190501.50316225'),
	'identifierSchemeCodeUnspsc190501.50316226': __('identifierSchemeCodeUnspsc190501.50316226'),
	'identifierSchemeCodeUnspsc190501.50316227': __('identifierSchemeCodeUnspsc190501.50316227'),
	'identifierSchemeCodeUnspsc190501.50316228': __('identifierSchemeCodeUnspsc190501.50316228'),
	'identifierSchemeCodeUnspsc190501.50316229': __('identifierSchemeCodeUnspsc190501.50316229'),
	'identifierSchemeCodeUnspsc190501.50316300': __('identifierSchemeCodeUnspsc190501.50316300'),
	'identifierSchemeCodeUnspsc190501.50316301': __('identifierSchemeCodeUnspsc190501.50316301'),
	'identifierSchemeCodeUnspsc190501.50316302': __('identifierSchemeCodeUnspsc190501.50316302'),
	'identifierSchemeCodeUnspsc190501.50316303': __('identifierSchemeCodeUnspsc190501.50316303'),
	'identifierSchemeCodeUnspsc190501.50316304': __('identifierSchemeCodeUnspsc190501.50316304'),
	'identifierSchemeCodeUnspsc190501.50316305': __('identifierSchemeCodeUnspsc190501.50316305'),
	'identifierSchemeCodeUnspsc190501.50316306': __('identifierSchemeCodeUnspsc190501.50316306'),
	'identifierSchemeCodeUnspsc190501.50316307': __('identifierSchemeCodeUnspsc190501.50316307'),
	'identifierSchemeCodeUnspsc190501.50316308': __('identifierSchemeCodeUnspsc190501.50316308'),
	'identifierSchemeCodeUnspsc190501.50316309': __('identifierSchemeCodeUnspsc190501.50316309'),
	'identifierSchemeCodeUnspsc190501.50316310': __('identifierSchemeCodeUnspsc190501.50316310'),
	'identifierSchemeCodeUnspsc190501.50316400': __('identifierSchemeCodeUnspsc190501.50316400'),
	'identifierSchemeCodeUnspsc190501.50316401': __('identifierSchemeCodeUnspsc190501.50316401'),
	'identifierSchemeCodeUnspsc190501.50316402': __('identifierSchemeCodeUnspsc190501.50316402'),
	'identifierSchemeCodeUnspsc190501.50316403': __('identifierSchemeCodeUnspsc190501.50316403'),
	'identifierSchemeCodeUnspsc190501.50316404': __('identifierSchemeCodeUnspsc190501.50316404'),
	'identifierSchemeCodeUnspsc190501.50316500': __('identifierSchemeCodeUnspsc190501.50316500'),
	'identifierSchemeCodeUnspsc190501.50316501': __('identifierSchemeCodeUnspsc190501.50316501'),
	'identifierSchemeCodeUnspsc190501.50316502': __('identifierSchemeCodeUnspsc190501.50316502'),
	'identifierSchemeCodeUnspsc190501.50316600': __('identifierSchemeCodeUnspsc190501.50316600'),
	'identifierSchemeCodeUnspsc190501.50316601': __('identifierSchemeCodeUnspsc190501.50316601'),
	'identifierSchemeCodeUnspsc190501.50316602': __('identifierSchemeCodeUnspsc190501.50316602'),
	'identifierSchemeCodeUnspsc190501.50316603': __('identifierSchemeCodeUnspsc190501.50316603'),
	'identifierSchemeCodeUnspsc190501.50316604': __('identifierSchemeCodeUnspsc190501.50316604'),
	'identifierSchemeCodeUnspsc190501.50316700': __('identifierSchemeCodeUnspsc190501.50316700'),
	'identifierSchemeCodeUnspsc190501.50316701': __('identifierSchemeCodeUnspsc190501.50316701'),
	'identifierSchemeCodeUnspsc190501.50316702': __('identifierSchemeCodeUnspsc190501.50316702'),
	'identifierSchemeCodeUnspsc190501.50316703': __('identifierSchemeCodeUnspsc190501.50316703'),
	'identifierSchemeCodeUnspsc190501.50316800': __('identifierSchemeCodeUnspsc190501.50316800'),
	'identifierSchemeCodeUnspsc190501.50316801': __('identifierSchemeCodeUnspsc190501.50316801'),
	'identifierSchemeCodeUnspsc190501.50316802': __('identifierSchemeCodeUnspsc190501.50316802'),
	'identifierSchemeCodeUnspsc190501.50316803': __('identifierSchemeCodeUnspsc190501.50316803'),
	'identifierSchemeCodeUnspsc190501.50316900': __('identifierSchemeCodeUnspsc190501.50316900'),
	'identifierSchemeCodeUnspsc190501.50316901': __('identifierSchemeCodeUnspsc190501.50316901'),
	'identifierSchemeCodeUnspsc190501.50316902': __('identifierSchemeCodeUnspsc190501.50316902'),
	'identifierSchemeCodeUnspsc190501.50316903': __('identifierSchemeCodeUnspsc190501.50316903'),
	'identifierSchemeCodeUnspsc190501.50316904': __('identifierSchemeCodeUnspsc190501.50316904'),
	'identifierSchemeCodeUnspsc190501.50316905': __('identifierSchemeCodeUnspsc190501.50316905'),
	'identifierSchemeCodeUnspsc190501.50316906': __('identifierSchemeCodeUnspsc190501.50316906'),
	'identifierSchemeCodeUnspsc190501.50317000': __('identifierSchemeCodeUnspsc190501.50317000'),
	'identifierSchemeCodeUnspsc190501.50317001': __('identifierSchemeCodeUnspsc190501.50317001'),
	'identifierSchemeCodeUnspsc190501.50317002': __('identifierSchemeCodeUnspsc190501.50317002'),
	'identifierSchemeCodeUnspsc190501.50317003': __('identifierSchemeCodeUnspsc190501.50317003'),
	'identifierSchemeCodeUnspsc190501.50317004': __('identifierSchemeCodeUnspsc190501.50317004'),
	'identifierSchemeCodeUnspsc190501.50317005': __('identifierSchemeCodeUnspsc190501.50317005'),
	'identifierSchemeCodeUnspsc190501.50317006': __('identifierSchemeCodeUnspsc190501.50317006'),
	'identifierSchemeCodeUnspsc190501.50317007': __('identifierSchemeCodeUnspsc190501.50317007'),
	'identifierSchemeCodeUnspsc190501.50317008': __('identifierSchemeCodeUnspsc190501.50317008'),
	'identifierSchemeCodeUnspsc190501.50317009': __('identifierSchemeCodeUnspsc190501.50317009'),
	'identifierSchemeCodeUnspsc190501.50317010': __('identifierSchemeCodeUnspsc190501.50317010'),
	'identifierSchemeCodeUnspsc190501.50317011': __('identifierSchemeCodeUnspsc190501.50317011'),
	'identifierSchemeCodeUnspsc190501.50317012': __('identifierSchemeCodeUnspsc190501.50317012'),
	'identifierSchemeCodeUnspsc190501.50317013': __('identifierSchemeCodeUnspsc190501.50317013'),
	'identifierSchemeCodeUnspsc190501.50317014': __('identifierSchemeCodeUnspsc190501.50317014'),
	'identifierSchemeCodeUnspsc190501.50317015': __('identifierSchemeCodeUnspsc190501.50317015'),
	'identifierSchemeCodeUnspsc190501.50317016': __('identifierSchemeCodeUnspsc190501.50317016'),
	'identifierSchemeCodeUnspsc190501.50317017': __('identifierSchemeCodeUnspsc190501.50317017'),
	'identifierSchemeCodeUnspsc190501.50317018': __('identifierSchemeCodeUnspsc190501.50317018'),
	'identifierSchemeCodeUnspsc190501.50317019': __('identifierSchemeCodeUnspsc190501.50317019'),
	'identifierSchemeCodeUnspsc190501.50317020': __('identifierSchemeCodeUnspsc190501.50317020'),
	'identifierSchemeCodeUnspsc190501.50317021': __('identifierSchemeCodeUnspsc190501.50317021'),
	'identifierSchemeCodeUnspsc190501.50317022': __('identifierSchemeCodeUnspsc190501.50317022'),
	'identifierSchemeCodeUnspsc190501.50317023': __('identifierSchemeCodeUnspsc190501.50317023'),
	'identifierSchemeCodeUnspsc190501.50317024': __('identifierSchemeCodeUnspsc190501.50317024'),
	'identifierSchemeCodeUnspsc190501.50317025': __('identifierSchemeCodeUnspsc190501.50317025'),
	'identifierSchemeCodeUnspsc190501.50317026': __('identifierSchemeCodeUnspsc190501.50317026'),
	'identifierSchemeCodeUnspsc190501.50317027': __('identifierSchemeCodeUnspsc190501.50317027'),
	'identifierSchemeCodeUnspsc190501.50317028': __('identifierSchemeCodeUnspsc190501.50317028'),
	'identifierSchemeCodeUnspsc190501.50317029': __('identifierSchemeCodeUnspsc190501.50317029'),
	'identifierSchemeCodeUnspsc190501.50317030': __('identifierSchemeCodeUnspsc190501.50317030'),
	'identifierSchemeCodeUnspsc190501.50317031': __('identifierSchemeCodeUnspsc190501.50317031'),
	'identifierSchemeCodeUnspsc190501.50317032': __('identifierSchemeCodeUnspsc190501.50317032'),
	'identifierSchemeCodeUnspsc190501.50317033': __('identifierSchemeCodeUnspsc190501.50317033'),
	'identifierSchemeCodeUnspsc190501.50317034': __('identifierSchemeCodeUnspsc190501.50317034'),
	'identifierSchemeCodeUnspsc190501.50317035': __('identifierSchemeCodeUnspsc190501.50317035'),
	'identifierSchemeCodeUnspsc190501.50317036': __('identifierSchemeCodeUnspsc190501.50317036'),
	'identifierSchemeCodeUnspsc190501.50317037': __('identifierSchemeCodeUnspsc190501.50317037'),
	'identifierSchemeCodeUnspsc190501.50317100': __('identifierSchemeCodeUnspsc190501.50317100'),
	'identifierSchemeCodeUnspsc190501.50317101': __('identifierSchemeCodeUnspsc190501.50317101'),
	'identifierSchemeCodeUnspsc190501.50317102': __('identifierSchemeCodeUnspsc190501.50317102'),
	'identifierSchemeCodeUnspsc190501.50317103': __('identifierSchemeCodeUnspsc190501.50317103'),
	'identifierSchemeCodeUnspsc190501.50317104': __('identifierSchemeCodeUnspsc190501.50317104'),
	'identifierSchemeCodeUnspsc190501.50317200': __('identifierSchemeCodeUnspsc190501.50317200'),
	'identifierSchemeCodeUnspsc190501.50317201': __('identifierSchemeCodeUnspsc190501.50317201'),
	'identifierSchemeCodeUnspsc190501.50317202': __('identifierSchemeCodeUnspsc190501.50317202'),
	'identifierSchemeCodeUnspsc190501.50317203': __('identifierSchemeCodeUnspsc190501.50317203'),
	'identifierSchemeCodeUnspsc190501.50317204': __('identifierSchemeCodeUnspsc190501.50317204'),
	'identifierSchemeCodeUnspsc190501.50317205': __('identifierSchemeCodeUnspsc190501.50317205'),
	'identifierSchemeCodeUnspsc190501.50317206': __('identifierSchemeCodeUnspsc190501.50317206'),
	'identifierSchemeCodeUnspsc190501.50317207': __('identifierSchemeCodeUnspsc190501.50317207'),
	'identifierSchemeCodeUnspsc190501.50317208': __('identifierSchemeCodeUnspsc190501.50317208'),
	'identifierSchemeCodeUnspsc190501.50317209': __('identifierSchemeCodeUnspsc190501.50317209'),
	'identifierSchemeCodeUnspsc190501.50317210': __('identifierSchemeCodeUnspsc190501.50317210'),
	'identifierSchemeCodeUnspsc190501.50317211': __('identifierSchemeCodeUnspsc190501.50317211'),
	'identifierSchemeCodeUnspsc190501.50317212': __('identifierSchemeCodeUnspsc190501.50317212'),
	'identifierSchemeCodeUnspsc190501.50317213': __('identifierSchemeCodeUnspsc190501.50317213'),
	'identifierSchemeCodeUnspsc190501.50317214': __('identifierSchemeCodeUnspsc190501.50317214'),
	'identifierSchemeCodeUnspsc190501.50317215': __('identifierSchemeCodeUnspsc190501.50317215'),
	'identifierSchemeCodeUnspsc190501.50317216': __('identifierSchemeCodeUnspsc190501.50317216'),
	'identifierSchemeCodeUnspsc190501.50317217': __('identifierSchemeCodeUnspsc190501.50317217'),
	'identifierSchemeCodeUnspsc190501.50317218': __('identifierSchemeCodeUnspsc190501.50317218'),
	'identifierSchemeCodeUnspsc190501.50317219': __('identifierSchemeCodeUnspsc190501.50317219'),
	'identifierSchemeCodeUnspsc190501.50317220': __('identifierSchemeCodeUnspsc190501.50317220'),
	'identifierSchemeCodeUnspsc190501.50317221': __('identifierSchemeCodeUnspsc190501.50317221'),
	'identifierSchemeCodeUnspsc190501.50317222': __('identifierSchemeCodeUnspsc190501.50317222'),
	'identifierSchemeCodeUnspsc190501.50317223': __('identifierSchemeCodeUnspsc190501.50317223'),
	'identifierSchemeCodeUnspsc190501.50317224': __('identifierSchemeCodeUnspsc190501.50317224'),
	'identifierSchemeCodeUnspsc190501.50317225': __('identifierSchemeCodeUnspsc190501.50317225'),
	'identifierSchemeCodeUnspsc190501.50317226': __('identifierSchemeCodeUnspsc190501.50317226'),
	'identifierSchemeCodeUnspsc190501.50317227': __('identifierSchemeCodeUnspsc190501.50317227'),
	'identifierSchemeCodeUnspsc190501.50317228': __('identifierSchemeCodeUnspsc190501.50317228'),
	'identifierSchemeCodeUnspsc190501.50317229': __('identifierSchemeCodeUnspsc190501.50317229'),
	'identifierSchemeCodeUnspsc190501.50317230': __('identifierSchemeCodeUnspsc190501.50317230'),
	'identifierSchemeCodeUnspsc190501.50320000': __('identifierSchemeCodeUnspsc190501.50320000'),
	'identifierSchemeCodeUnspsc190501.50321500': __('identifierSchemeCodeUnspsc190501.50321500'),
	'identifierSchemeCodeUnspsc190501.50321501': __('identifierSchemeCodeUnspsc190501.50321501'),
	'identifierSchemeCodeUnspsc190501.50321502': __('identifierSchemeCodeUnspsc190501.50321502'),
	'identifierSchemeCodeUnspsc190501.50321503': __('identifierSchemeCodeUnspsc190501.50321503'),
	'identifierSchemeCodeUnspsc190501.50321504': __('identifierSchemeCodeUnspsc190501.50321504'),
	'identifierSchemeCodeUnspsc190501.50321505': __('identifierSchemeCodeUnspsc190501.50321505'),
	'identifierSchemeCodeUnspsc190501.50321506': __('identifierSchemeCodeUnspsc190501.50321506'),
	'identifierSchemeCodeUnspsc190501.50321507': __('identifierSchemeCodeUnspsc190501.50321507'),
	'identifierSchemeCodeUnspsc190501.50321508': __('identifierSchemeCodeUnspsc190501.50321508'),
	'identifierSchemeCodeUnspsc190501.50321509': __('identifierSchemeCodeUnspsc190501.50321509'),
	'identifierSchemeCodeUnspsc190501.50321510': __('identifierSchemeCodeUnspsc190501.50321510'),
	'identifierSchemeCodeUnspsc190501.50321511': __('identifierSchemeCodeUnspsc190501.50321511'),
	'identifierSchemeCodeUnspsc190501.50321512': __('identifierSchemeCodeUnspsc190501.50321512'),
	'identifierSchemeCodeUnspsc190501.50321513': __('identifierSchemeCodeUnspsc190501.50321513'),
	'identifierSchemeCodeUnspsc190501.50321514': __('identifierSchemeCodeUnspsc190501.50321514'),
	'identifierSchemeCodeUnspsc190501.50321515': __('identifierSchemeCodeUnspsc190501.50321515'),
	'identifierSchemeCodeUnspsc190501.50321516': __('identifierSchemeCodeUnspsc190501.50321516'),
	'identifierSchemeCodeUnspsc190501.50321517': __('identifierSchemeCodeUnspsc190501.50321517'),
	'identifierSchemeCodeUnspsc190501.50321518': __('identifierSchemeCodeUnspsc190501.50321518'),
	'identifierSchemeCodeUnspsc190501.50321519': __('identifierSchemeCodeUnspsc190501.50321519'),
	'identifierSchemeCodeUnspsc190501.50321520': __('identifierSchemeCodeUnspsc190501.50321520'),
	'identifierSchemeCodeUnspsc190501.50321521': __('identifierSchemeCodeUnspsc190501.50321521'),
	'identifierSchemeCodeUnspsc190501.50321522': __('identifierSchemeCodeUnspsc190501.50321522'),
	'identifierSchemeCodeUnspsc190501.50321523': __('identifierSchemeCodeUnspsc190501.50321523'),
	'identifierSchemeCodeUnspsc190501.50321524': __('identifierSchemeCodeUnspsc190501.50321524'),
	'identifierSchemeCodeUnspsc190501.50321525': __('identifierSchemeCodeUnspsc190501.50321525'),
	'identifierSchemeCodeUnspsc190501.50321526': __('identifierSchemeCodeUnspsc190501.50321526'),
	'identifierSchemeCodeUnspsc190501.50321527': __('identifierSchemeCodeUnspsc190501.50321527'),
	'identifierSchemeCodeUnspsc190501.50321528': __('identifierSchemeCodeUnspsc190501.50321528'),
	'identifierSchemeCodeUnspsc190501.50321529': __('identifierSchemeCodeUnspsc190501.50321529'),
	'identifierSchemeCodeUnspsc190501.50321530': __('identifierSchemeCodeUnspsc190501.50321530'),
	'identifierSchemeCodeUnspsc190501.50321531': __('identifierSchemeCodeUnspsc190501.50321531'),
	'identifierSchemeCodeUnspsc190501.50321532': __('identifierSchemeCodeUnspsc190501.50321532'),
	'identifierSchemeCodeUnspsc190501.50321533': __('identifierSchemeCodeUnspsc190501.50321533'),
	'identifierSchemeCodeUnspsc190501.50321534': __('identifierSchemeCodeUnspsc190501.50321534'),
	'identifierSchemeCodeUnspsc190501.50321535': __('identifierSchemeCodeUnspsc190501.50321535'),
	'identifierSchemeCodeUnspsc190501.50321536': __('identifierSchemeCodeUnspsc190501.50321536'),
	'identifierSchemeCodeUnspsc190501.50321537': __('identifierSchemeCodeUnspsc190501.50321537'),
	'identifierSchemeCodeUnspsc190501.50321538': __('identifierSchemeCodeUnspsc190501.50321538'),
	'identifierSchemeCodeUnspsc190501.50321539': __('identifierSchemeCodeUnspsc190501.50321539'),
	'identifierSchemeCodeUnspsc190501.50321540': __('identifierSchemeCodeUnspsc190501.50321540'),
	'identifierSchemeCodeUnspsc190501.50321541': __('identifierSchemeCodeUnspsc190501.50321541'),
	'identifierSchemeCodeUnspsc190501.50321542': __('identifierSchemeCodeUnspsc190501.50321542'),
	'identifierSchemeCodeUnspsc190501.50321543': __('identifierSchemeCodeUnspsc190501.50321543'),
	'identifierSchemeCodeUnspsc190501.50321544': __('identifierSchemeCodeUnspsc190501.50321544'),
	'identifierSchemeCodeUnspsc190501.50321545': __('identifierSchemeCodeUnspsc190501.50321545'),
	'identifierSchemeCodeUnspsc190501.50321546': __('identifierSchemeCodeUnspsc190501.50321546'),
	'identifierSchemeCodeUnspsc190501.50321547': __('identifierSchemeCodeUnspsc190501.50321547'),
	'identifierSchemeCodeUnspsc190501.50321548': __('identifierSchemeCodeUnspsc190501.50321548'),
	'identifierSchemeCodeUnspsc190501.50321549': __('identifierSchemeCodeUnspsc190501.50321549'),
	'identifierSchemeCodeUnspsc190501.50321550': __('identifierSchemeCodeUnspsc190501.50321550'),
	'identifierSchemeCodeUnspsc190501.50321551': __('identifierSchemeCodeUnspsc190501.50321551'),
	'identifierSchemeCodeUnspsc190501.50321552': __('identifierSchemeCodeUnspsc190501.50321552'),
	'identifierSchemeCodeUnspsc190501.50321553': __('identifierSchemeCodeUnspsc190501.50321553'),
	'identifierSchemeCodeUnspsc190501.50321554': __('identifierSchemeCodeUnspsc190501.50321554'),
	'identifierSchemeCodeUnspsc190501.50321555': __('identifierSchemeCodeUnspsc190501.50321555'),
	'identifierSchemeCodeUnspsc190501.50321556': __('identifierSchemeCodeUnspsc190501.50321556'),
	'identifierSchemeCodeUnspsc190501.50321557': __('identifierSchemeCodeUnspsc190501.50321557'),
	'identifierSchemeCodeUnspsc190501.50321558': __('identifierSchemeCodeUnspsc190501.50321558'),
	'identifierSchemeCodeUnspsc190501.50321559': __('identifierSchemeCodeUnspsc190501.50321559'),
	'identifierSchemeCodeUnspsc190501.50321560': __('identifierSchemeCodeUnspsc190501.50321560'),
	'identifierSchemeCodeUnspsc190501.50321561': __('identifierSchemeCodeUnspsc190501.50321561'),
	'identifierSchemeCodeUnspsc190501.50321562': __('identifierSchemeCodeUnspsc190501.50321562'),
	'identifierSchemeCodeUnspsc190501.50321563': __('identifierSchemeCodeUnspsc190501.50321563'),
	'identifierSchemeCodeUnspsc190501.50321564': __('identifierSchemeCodeUnspsc190501.50321564'),
	'identifierSchemeCodeUnspsc190501.50321565': __('identifierSchemeCodeUnspsc190501.50321565'),
	'identifierSchemeCodeUnspsc190501.50321566': __('identifierSchemeCodeUnspsc190501.50321566'),
	'identifierSchemeCodeUnspsc190501.50321567': __('identifierSchemeCodeUnspsc190501.50321567'),
	'identifierSchemeCodeUnspsc190501.50321568': __('identifierSchemeCodeUnspsc190501.50321568'),
	'identifierSchemeCodeUnspsc190501.50321569': __('identifierSchemeCodeUnspsc190501.50321569'),
	'identifierSchemeCodeUnspsc190501.50321570': __('identifierSchemeCodeUnspsc190501.50321570'),
	'identifierSchemeCodeUnspsc190501.50321571': __('identifierSchemeCodeUnspsc190501.50321571'),
	'identifierSchemeCodeUnspsc190501.50321572': __('identifierSchemeCodeUnspsc190501.50321572'),
	'identifierSchemeCodeUnspsc190501.50321573': __('identifierSchemeCodeUnspsc190501.50321573'),
	'identifierSchemeCodeUnspsc190501.50321574': __('identifierSchemeCodeUnspsc190501.50321574'),
	'identifierSchemeCodeUnspsc190501.50321575': __('identifierSchemeCodeUnspsc190501.50321575'),
	'identifierSchemeCodeUnspsc190501.50321576': __('identifierSchemeCodeUnspsc190501.50321576'),
	'identifierSchemeCodeUnspsc190501.50321577': __('identifierSchemeCodeUnspsc190501.50321577'),
	'identifierSchemeCodeUnspsc190501.50321578': __('identifierSchemeCodeUnspsc190501.50321578'),
	'identifierSchemeCodeUnspsc190501.50321579': __('identifierSchemeCodeUnspsc190501.50321579'),
	'identifierSchemeCodeUnspsc190501.50321580': __('identifierSchemeCodeUnspsc190501.50321580'),
	'identifierSchemeCodeUnspsc190501.50321581': __('identifierSchemeCodeUnspsc190501.50321581'),
	'identifierSchemeCodeUnspsc190501.50321582': __('identifierSchemeCodeUnspsc190501.50321582'),
	'identifierSchemeCodeUnspsc190501.50321583': __('identifierSchemeCodeUnspsc190501.50321583'),
	'identifierSchemeCodeUnspsc190501.50321584': __('identifierSchemeCodeUnspsc190501.50321584'),
	'identifierSchemeCodeUnspsc190501.50321585': __('identifierSchemeCodeUnspsc190501.50321585'),
	'identifierSchemeCodeUnspsc190501.50321586': __('identifierSchemeCodeUnspsc190501.50321586'),
	'identifierSchemeCodeUnspsc190501.50321587': __('identifierSchemeCodeUnspsc190501.50321587'),
	'identifierSchemeCodeUnspsc190501.50321588': __('identifierSchemeCodeUnspsc190501.50321588'),
	'identifierSchemeCodeUnspsc190501.50321589': __('identifierSchemeCodeUnspsc190501.50321589'),
	'identifierSchemeCodeUnspsc190501.50321590': __('identifierSchemeCodeUnspsc190501.50321590'),
	'identifierSchemeCodeUnspsc190501.50321591': __('identifierSchemeCodeUnspsc190501.50321591'),
	'identifierSchemeCodeUnspsc190501.50321592': __('identifierSchemeCodeUnspsc190501.50321592'),
	'identifierSchemeCodeUnspsc190501.50321593': __('identifierSchemeCodeUnspsc190501.50321593'),
	'identifierSchemeCodeUnspsc190501.50321594': __('identifierSchemeCodeUnspsc190501.50321594'),
	'identifierSchemeCodeUnspsc190501.50321595': __('identifierSchemeCodeUnspsc190501.50321595'),
	'identifierSchemeCodeUnspsc190501.50321596': __('identifierSchemeCodeUnspsc190501.50321596'),
	'identifierSchemeCodeUnspsc190501.50321600': __('identifierSchemeCodeUnspsc190501.50321600'),
	'identifierSchemeCodeUnspsc190501.50321601': __('identifierSchemeCodeUnspsc190501.50321601'),
	'identifierSchemeCodeUnspsc190501.50321602': __('identifierSchemeCodeUnspsc190501.50321602'),
	'identifierSchemeCodeUnspsc190501.50321603': __('identifierSchemeCodeUnspsc190501.50321603'),
	'identifierSchemeCodeUnspsc190501.50321604': __('identifierSchemeCodeUnspsc190501.50321604'),
	'identifierSchemeCodeUnspsc190501.50321605': __('identifierSchemeCodeUnspsc190501.50321605'),
	'identifierSchemeCodeUnspsc190501.50321606': __('identifierSchemeCodeUnspsc190501.50321606'),
	'identifierSchemeCodeUnspsc190501.50321607': __('identifierSchemeCodeUnspsc190501.50321607'),
	'identifierSchemeCodeUnspsc190501.50321608': __('identifierSchemeCodeUnspsc190501.50321608'),
	'identifierSchemeCodeUnspsc190501.50321609': __('identifierSchemeCodeUnspsc190501.50321609'),
	'identifierSchemeCodeUnspsc190501.50321610': __('identifierSchemeCodeUnspsc190501.50321610'),
	'identifierSchemeCodeUnspsc190501.50321611': __('identifierSchemeCodeUnspsc190501.50321611'),
	'identifierSchemeCodeUnspsc190501.50321612': __('identifierSchemeCodeUnspsc190501.50321612'),
	'identifierSchemeCodeUnspsc190501.50321613': __('identifierSchemeCodeUnspsc190501.50321613'),
	'identifierSchemeCodeUnspsc190501.50321614': __('identifierSchemeCodeUnspsc190501.50321614'),
	'identifierSchemeCodeUnspsc190501.50321615': __('identifierSchemeCodeUnspsc190501.50321615'),
	'identifierSchemeCodeUnspsc190501.50321616': __('identifierSchemeCodeUnspsc190501.50321616'),
	'identifierSchemeCodeUnspsc190501.50321617': __('identifierSchemeCodeUnspsc190501.50321617'),
	'identifierSchemeCodeUnspsc190501.50321618': __('identifierSchemeCodeUnspsc190501.50321618'),
	'identifierSchemeCodeUnspsc190501.50321619': __('identifierSchemeCodeUnspsc190501.50321619'),
	'identifierSchemeCodeUnspsc190501.50321620': __('identifierSchemeCodeUnspsc190501.50321620'),
	'identifierSchemeCodeUnspsc190501.50321621': __('identifierSchemeCodeUnspsc190501.50321621'),
	'identifierSchemeCodeUnspsc190501.50321622': __('identifierSchemeCodeUnspsc190501.50321622'),
	'identifierSchemeCodeUnspsc190501.50321623': __('identifierSchemeCodeUnspsc190501.50321623'),
	'identifierSchemeCodeUnspsc190501.50321624': __('identifierSchemeCodeUnspsc190501.50321624'),
	'identifierSchemeCodeUnspsc190501.50321625': __('identifierSchemeCodeUnspsc190501.50321625'),
	'identifierSchemeCodeUnspsc190501.50321626': __('identifierSchemeCodeUnspsc190501.50321626'),
	'identifierSchemeCodeUnspsc190501.50321627': __('identifierSchemeCodeUnspsc190501.50321627'),
	'identifierSchemeCodeUnspsc190501.50321628': __('identifierSchemeCodeUnspsc190501.50321628'),
	'identifierSchemeCodeUnspsc190501.50321629': __('identifierSchemeCodeUnspsc190501.50321629'),
	'identifierSchemeCodeUnspsc190501.50321630': __('identifierSchemeCodeUnspsc190501.50321630'),
	'identifierSchemeCodeUnspsc190501.50321631': __('identifierSchemeCodeUnspsc190501.50321631'),
	'identifierSchemeCodeUnspsc190501.50321632': __('identifierSchemeCodeUnspsc190501.50321632'),
	'identifierSchemeCodeUnspsc190501.50321633': __('identifierSchemeCodeUnspsc190501.50321633'),
	'identifierSchemeCodeUnspsc190501.50321634': __('identifierSchemeCodeUnspsc190501.50321634'),
	'identifierSchemeCodeUnspsc190501.50321635': __('identifierSchemeCodeUnspsc190501.50321635'),
	'identifierSchemeCodeUnspsc190501.50321636': __('identifierSchemeCodeUnspsc190501.50321636'),
	'identifierSchemeCodeUnspsc190501.50321637': __('identifierSchemeCodeUnspsc190501.50321637'),
	'identifierSchemeCodeUnspsc190501.50321638': __('identifierSchemeCodeUnspsc190501.50321638'),
	'identifierSchemeCodeUnspsc190501.50321639': __('identifierSchemeCodeUnspsc190501.50321639'),
	'identifierSchemeCodeUnspsc190501.50321640': __('identifierSchemeCodeUnspsc190501.50321640'),
	'identifierSchemeCodeUnspsc190501.50321641': __('identifierSchemeCodeUnspsc190501.50321641'),
	'identifierSchemeCodeUnspsc190501.50321642': __('identifierSchemeCodeUnspsc190501.50321642'),
	'identifierSchemeCodeUnspsc190501.50321643': __('identifierSchemeCodeUnspsc190501.50321643'),
	'identifierSchemeCodeUnspsc190501.50321644': __('identifierSchemeCodeUnspsc190501.50321644'),
	'identifierSchemeCodeUnspsc190501.50321645': __('identifierSchemeCodeUnspsc190501.50321645'),
	'identifierSchemeCodeUnspsc190501.50321646': __('identifierSchemeCodeUnspsc190501.50321646'),
	'identifierSchemeCodeUnspsc190501.50321647': __('identifierSchemeCodeUnspsc190501.50321647'),
	'identifierSchemeCodeUnspsc190501.50321648': __('identifierSchemeCodeUnspsc190501.50321648'),
	'identifierSchemeCodeUnspsc190501.50321649': __('identifierSchemeCodeUnspsc190501.50321649'),
	'identifierSchemeCodeUnspsc190501.50321650': __('identifierSchemeCodeUnspsc190501.50321650'),
	'identifierSchemeCodeUnspsc190501.50321651': __('identifierSchemeCodeUnspsc190501.50321651'),
	'identifierSchemeCodeUnspsc190501.50321652': __('identifierSchemeCodeUnspsc190501.50321652'),
	'identifierSchemeCodeUnspsc190501.50321653': __('identifierSchemeCodeUnspsc190501.50321653'),
	'identifierSchemeCodeUnspsc190501.50321654': __('identifierSchemeCodeUnspsc190501.50321654'),
	'identifierSchemeCodeUnspsc190501.50321655': __('identifierSchemeCodeUnspsc190501.50321655'),
	'identifierSchemeCodeUnspsc190501.50321700': __('identifierSchemeCodeUnspsc190501.50321700'),
	'identifierSchemeCodeUnspsc190501.50321701': __('identifierSchemeCodeUnspsc190501.50321701'),
	'identifierSchemeCodeUnspsc190501.50321702': __('identifierSchemeCodeUnspsc190501.50321702'),
	'identifierSchemeCodeUnspsc190501.50321703': __('identifierSchemeCodeUnspsc190501.50321703'),
	'identifierSchemeCodeUnspsc190501.50321704': __('identifierSchemeCodeUnspsc190501.50321704'),
	'identifierSchemeCodeUnspsc190501.50321705': __('identifierSchemeCodeUnspsc190501.50321705'),
	'identifierSchemeCodeUnspsc190501.50321706': __('identifierSchemeCodeUnspsc190501.50321706'),
	'identifierSchemeCodeUnspsc190501.50321707': __('identifierSchemeCodeUnspsc190501.50321707'),
	'identifierSchemeCodeUnspsc190501.50321708': __('identifierSchemeCodeUnspsc190501.50321708'),
	'identifierSchemeCodeUnspsc190501.50321709': __('identifierSchemeCodeUnspsc190501.50321709'),
	'identifierSchemeCodeUnspsc190501.50321710': __('identifierSchemeCodeUnspsc190501.50321710'),
	'identifierSchemeCodeUnspsc190501.50321711': __('identifierSchemeCodeUnspsc190501.50321711'),
	'identifierSchemeCodeUnspsc190501.50321712': __('identifierSchemeCodeUnspsc190501.50321712'),
	'identifierSchemeCodeUnspsc190501.50321713': __('identifierSchemeCodeUnspsc190501.50321713'),
	'identifierSchemeCodeUnspsc190501.50321714': __('identifierSchemeCodeUnspsc190501.50321714'),
	'identifierSchemeCodeUnspsc190501.50321715': __('identifierSchemeCodeUnspsc190501.50321715'),
	'identifierSchemeCodeUnspsc190501.50321800': __('identifierSchemeCodeUnspsc190501.50321800'),
	'identifierSchemeCodeUnspsc190501.50321801': __('identifierSchemeCodeUnspsc190501.50321801'),
	'identifierSchemeCodeUnspsc190501.50321802': __('identifierSchemeCodeUnspsc190501.50321802'),
	'identifierSchemeCodeUnspsc190501.50321803': __('identifierSchemeCodeUnspsc190501.50321803'),
	'identifierSchemeCodeUnspsc190501.50321804': __('identifierSchemeCodeUnspsc190501.50321804'),
	'identifierSchemeCodeUnspsc190501.50321805': __('identifierSchemeCodeUnspsc190501.50321805'),
	'identifierSchemeCodeUnspsc190501.50321806': __('identifierSchemeCodeUnspsc190501.50321806'),
	'identifierSchemeCodeUnspsc190501.50321807': __('identifierSchemeCodeUnspsc190501.50321807'),
	'identifierSchemeCodeUnspsc190501.50321808': __('identifierSchemeCodeUnspsc190501.50321808'),
	'identifierSchemeCodeUnspsc190501.50321809': __('identifierSchemeCodeUnspsc190501.50321809'),
	'identifierSchemeCodeUnspsc190501.50321810': __('identifierSchemeCodeUnspsc190501.50321810'),
	'identifierSchemeCodeUnspsc190501.50321811': __('identifierSchemeCodeUnspsc190501.50321811'),
	'identifierSchemeCodeUnspsc190501.50321812': __('identifierSchemeCodeUnspsc190501.50321812'),
	'identifierSchemeCodeUnspsc190501.50321813': __('identifierSchemeCodeUnspsc190501.50321813'),
	'identifierSchemeCodeUnspsc190501.50321814': __('identifierSchemeCodeUnspsc190501.50321814'),
	'identifierSchemeCodeUnspsc190501.50321900': __('identifierSchemeCodeUnspsc190501.50321900'),
	'identifierSchemeCodeUnspsc190501.50321901': __('identifierSchemeCodeUnspsc190501.50321901'),
	'identifierSchemeCodeUnspsc190501.50321902': __('identifierSchemeCodeUnspsc190501.50321902'),
	'identifierSchemeCodeUnspsc190501.50321903': __('identifierSchemeCodeUnspsc190501.50321903'),
	'identifierSchemeCodeUnspsc190501.50322000': __('identifierSchemeCodeUnspsc190501.50322000'),
	'identifierSchemeCodeUnspsc190501.50322001': __('identifierSchemeCodeUnspsc190501.50322001'),
	'identifierSchemeCodeUnspsc190501.50322002': __('identifierSchemeCodeUnspsc190501.50322002'),
	'identifierSchemeCodeUnspsc190501.50322003': __('identifierSchemeCodeUnspsc190501.50322003'),
	'identifierSchemeCodeUnspsc190501.50322004': __('identifierSchemeCodeUnspsc190501.50322004'),
	'identifierSchemeCodeUnspsc190501.50322005': __('identifierSchemeCodeUnspsc190501.50322005'),
	'identifierSchemeCodeUnspsc190501.50322006': __('identifierSchemeCodeUnspsc190501.50322006'),
	'identifierSchemeCodeUnspsc190501.50322007': __('identifierSchemeCodeUnspsc190501.50322007'),
	'identifierSchemeCodeUnspsc190501.50322008': __('identifierSchemeCodeUnspsc190501.50322008'),
	'identifierSchemeCodeUnspsc190501.50322009': __('identifierSchemeCodeUnspsc190501.50322009'),
	'identifierSchemeCodeUnspsc190501.50322010': __('identifierSchemeCodeUnspsc190501.50322010'),
	'identifierSchemeCodeUnspsc190501.50322011': __('identifierSchemeCodeUnspsc190501.50322011'),
	'identifierSchemeCodeUnspsc190501.50322012': __('identifierSchemeCodeUnspsc190501.50322012'),
	'identifierSchemeCodeUnspsc190501.50322013': __('identifierSchemeCodeUnspsc190501.50322013'),
	'identifierSchemeCodeUnspsc190501.50322014': __('identifierSchemeCodeUnspsc190501.50322014'),
	'identifierSchemeCodeUnspsc190501.50322015': __('identifierSchemeCodeUnspsc190501.50322015'),
	'identifierSchemeCodeUnspsc190501.50322100': __('identifierSchemeCodeUnspsc190501.50322100'),
	'identifierSchemeCodeUnspsc190501.50322101': __('identifierSchemeCodeUnspsc190501.50322101'),
	'identifierSchemeCodeUnspsc190501.50322102': __('identifierSchemeCodeUnspsc190501.50322102'),
	'identifierSchemeCodeUnspsc190501.50322103': __('identifierSchemeCodeUnspsc190501.50322103'),
	'identifierSchemeCodeUnspsc190501.50322104': __('identifierSchemeCodeUnspsc190501.50322104'),
	'identifierSchemeCodeUnspsc190501.50322200': __('identifierSchemeCodeUnspsc190501.50322200'),
	'identifierSchemeCodeUnspsc190501.50322201': __('identifierSchemeCodeUnspsc190501.50322201'),
	'identifierSchemeCodeUnspsc190501.50322202': __('identifierSchemeCodeUnspsc190501.50322202'),
	'identifierSchemeCodeUnspsc190501.50322203': __('identifierSchemeCodeUnspsc190501.50322203'),
	'identifierSchemeCodeUnspsc190501.50322204': __('identifierSchemeCodeUnspsc190501.50322204'),
	'identifierSchemeCodeUnspsc190501.50322205': __('identifierSchemeCodeUnspsc190501.50322205'),
	'identifierSchemeCodeUnspsc190501.50322206': __('identifierSchemeCodeUnspsc190501.50322206'),
	'identifierSchemeCodeUnspsc190501.50322207': __('identifierSchemeCodeUnspsc190501.50322207'),
	'identifierSchemeCodeUnspsc190501.50322208': __('identifierSchemeCodeUnspsc190501.50322208'),
	'identifierSchemeCodeUnspsc190501.50322209': __('identifierSchemeCodeUnspsc190501.50322209'),
	'identifierSchemeCodeUnspsc190501.50322210': __('identifierSchemeCodeUnspsc190501.50322210'),
	'identifierSchemeCodeUnspsc190501.50322211': __('identifierSchemeCodeUnspsc190501.50322211'),
	'identifierSchemeCodeUnspsc190501.50322212': __('identifierSchemeCodeUnspsc190501.50322212'),
	'identifierSchemeCodeUnspsc190501.50322213': __('identifierSchemeCodeUnspsc190501.50322213'),
	'identifierSchemeCodeUnspsc190501.50322214': __('identifierSchemeCodeUnspsc190501.50322214'),
	'identifierSchemeCodeUnspsc190501.50322215': __('identifierSchemeCodeUnspsc190501.50322215'),
	'identifierSchemeCodeUnspsc190501.50322300': __('identifierSchemeCodeUnspsc190501.50322300'),
	'identifierSchemeCodeUnspsc190501.50322301': __('identifierSchemeCodeUnspsc190501.50322301'),
	'identifierSchemeCodeUnspsc190501.50322302': __('identifierSchemeCodeUnspsc190501.50322302'),
	'identifierSchemeCodeUnspsc190501.50322303': __('identifierSchemeCodeUnspsc190501.50322303'),
	'identifierSchemeCodeUnspsc190501.50322304': __('identifierSchemeCodeUnspsc190501.50322304'),
	'identifierSchemeCodeUnspsc190501.50322400': __('identifierSchemeCodeUnspsc190501.50322400'),
	'identifierSchemeCodeUnspsc190501.50322401': __('identifierSchemeCodeUnspsc190501.50322401'),
	'identifierSchemeCodeUnspsc190501.50322402': __('identifierSchemeCodeUnspsc190501.50322402'),
	'identifierSchemeCodeUnspsc190501.50322403': __('identifierSchemeCodeUnspsc190501.50322403'),
	'identifierSchemeCodeUnspsc190501.50322404': __('identifierSchemeCodeUnspsc190501.50322404'),
	'identifierSchemeCodeUnspsc190501.50322405': __('identifierSchemeCodeUnspsc190501.50322405'),
	'identifierSchemeCodeUnspsc190501.50322406': __('identifierSchemeCodeUnspsc190501.50322406'),
	'identifierSchemeCodeUnspsc190501.50322407': __('identifierSchemeCodeUnspsc190501.50322407'),
	'identifierSchemeCodeUnspsc190501.50322408': __('identifierSchemeCodeUnspsc190501.50322408'),
	'identifierSchemeCodeUnspsc190501.50322409': __('identifierSchemeCodeUnspsc190501.50322409'),
	'identifierSchemeCodeUnspsc190501.50322500': __('identifierSchemeCodeUnspsc190501.50322500'),
	'identifierSchemeCodeUnspsc190501.50322501': __('identifierSchemeCodeUnspsc190501.50322501'),
	'identifierSchemeCodeUnspsc190501.50322502': __('identifierSchemeCodeUnspsc190501.50322502'),
	'identifierSchemeCodeUnspsc190501.50322503': __('identifierSchemeCodeUnspsc190501.50322503'),
	'identifierSchemeCodeUnspsc190501.50322504': __('identifierSchemeCodeUnspsc190501.50322504'),
	'identifierSchemeCodeUnspsc190501.50322505': __('identifierSchemeCodeUnspsc190501.50322505'),
	'identifierSchemeCodeUnspsc190501.50322506': __('identifierSchemeCodeUnspsc190501.50322506'),
	'identifierSchemeCodeUnspsc190501.50322507': __('identifierSchemeCodeUnspsc190501.50322507'),
	'identifierSchemeCodeUnspsc190501.50322508': __('identifierSchemeCodeUnspsc190501.50322508'),
	'identifierSchemeCodeUnspsc190501.50322509': __('identifierSchemeCodeUnspsc190501.50322509'),
	'identifierSchemeCodeUnspsc190501.50322510': __('identifierSchemeCodeUnspsc190501.50322510'),
	'identifierSchemeCodeUnspsc190501.50322511': __('identifierSchemeCodeUnspsc190501.50322511'),
	'identifierSchemeCodeUnspsc190501.50322512': __('identifierSchemeCodeUnspsc190501.50322512'),
	'identifierSchemeCodeUnspsc190501.50322513': __('identifierSchemeCodeUnspsc190501.50322513'),
	'identifierSchemeCodeUnspsc190501.50322514': __('identifierSchemeCodeUnspsc190501.50322514'),
	'identifierSchemeCodeUnspsc190501.50322515': __('identifierSchemeCodeUnspsc190501.50322515'),
	'identifierSchemeCodeUnspsc190501.50322516': __('identifierSchemeCodeUnspsc190501.50322516'),
	'identifierSchemeCodeUnspsc190501.50322517': __('identifierSchemeCodeUnspsc190501.50322517'),
	'identifierSchemeCodeUnspsc190501.50322518': __('identifierSchemeCodeUnspsc190501.50322518'),
	'identifierSchemeCodeUnspsc190501.50322519': __('identifierSchemeCodeUnspsc190501.50322519'),
	'identifierSchemeCodeUnspsc190501.50322520': __('identifierSchemeCodeUnspsc190501.50322520'),
	'identifierSchemeCodeUnspsc190501.50322521': __('identifierSchemeCodeUnspsc190501.50322521'),
	'identifierSchemeCodeUnspsc190501.50322522': __('identifierSchemeCodeUnspsc190501.50322522'),
	'identifierSchemeCodeUnspsc190501.50322523': __('identifierSchemeCodeUnspsc190501.50322523'),
	'identifierSchemeCodeUnspsc190501.50322524': __('identifierSchemeCodeUnspsc190501.50322524'),
	'identifierSchemeCodeUnspsc190501.50322525': __('identifierSchemeCodeUnspsc190501.50322525'),
	'identifierSchemeCodeUnspsc190501.50322527': __('identifierSchemeCodeUnspsc190501.50322527'),
	'identifierSchemeCodeUnspsc190501.50322528': __('identifierSchemeCodeUnspsc190501.50322528'),
	'identifierSchemeCodeUnspsc190501.50322529': __('identifierSchemeCodeUnspsc190501.50322529'),
	'identifierSchemeCodeUnspsc190501.50322530': __('identifierSchemeCodeUnspsc190501.50322530'),
	'identifierSchemeCodeUnspsc190501.50322531': __('identifierSchemeCodeUnspsc190501.50322531'),
	'identifierSchemeCodeUnspsc190501.50322532': __('identifierSchemeCodeUnspsc190501.50322532'),
	'identifierSchemeCodeUnspsc190501.50322600': __('identifierSchemeCodeUnspsc190501.50322600'),
	'identifierSchemeCodeUnspsc190501.50322601': __('identifierSchemeCodeUnspsc190501.50322601'),
	'identifierSchemeCodeUnspsc190501.50322602': __('identifierSchemeCodeUnspsc190501.50322602'),
	'identifierSchemeCodeUnspsc190501.50322603': __('identifierSchemeCodeUnspsc190501.50322603'),
	'identifierSchemeCodeUnspsc190501.50322604': __('identifierSchemeCodeUnspsc190501.50322604'),
	'identifierSchemeCodeUnspsc190501.50322605': __('identifierSchemeCodeUnspsc190501.50322605'),
	'identifierSchemeCodeUnspsc190501.50322606': __('identifierSchemeCodeUnspsc190501.50322606'),
	'identifierSchemeCodeUnspsc190501.50322607': __('identifierSchemeCodeUnspsc190501.50322607'),
	'identifierSchemeCodeUnspsc190501.50322700': __('identifierSchemeCodeUnspsc190501.50322700'),
	'identifierSchemeCodeUnspsc190501.50322701': __('identifierSchemeCodeUnspsc190501.50322701'),
	'identifierSchemeCodeUnspsc190501.50322702': __('identifierSchemeCodeUnspsc190501.50322702'),
	'identifierSchemeCodeUnspsc190501.50322703': __('identifierSchemeCodeUnspsc190501.50322703'),
	'identifierSchemeCodeUnspsc190501.50322704': __('identifierSchemeCodeUnspsc190501.50322704'),
	'identifierSchemeCodeUnspsc190501.50322705': __('identifierSchemeCodeUnspsc190501.50322705'),
	'identifierSchemeCodeUnspsc190501.50322706': __('identifierSchemeCodeUnspsc190501.50322706'),
	'identifierSchemeCodeUnspsc190501.50322707': __('identifierSchemeCodeUnspsc190501.50322707'),
	'identifierSchemeCodeUnspsc190501.50322708': __('identifierSchemeCodeUnspsc190501.50322708'),
	'identifierSchemeCodeUnspsc190501.50322709': __('identifierSchemeCodeUnspsc190501.50322709'),
	'identifierSchemeCodeUnspsc190501.50322710': __('identifierSchemeCodeUnspsc190501.50322710'),
	'identifierSchemeCodeUnspsc190501.50322800': __('identifierSchemeCodeUnspsc190501.50322800'),
	'identifierSchemeCodeUnspsc190501.50322801': __('identifierSchemeCodeUnspsc190501.50322801'),
	'identifierSchemeCodeUnspsc190501.50322802': __('identifierSchemeCodeUnspsc190501.50322802'),
	'identifierSchemeCodeUnspsc190501.50322803': __('identifierSchemeCodeUnspsc190501.50322803'),
	'identifierSchemeCodeUnspsc190501.50322804': __('identifierSchemeCodeUnspsc190501.50322804'),
	'identifierSchemeCodeUnspsc190501.50322805': __('identifierSchemeCodeUnspsc190501.50322805'),
	'identifierSchemeCodeUnspsc190501.50322806': __('identifierSchemeCodeUnspsc190501.50322806'),
	'identifierSchemeCodeUnspsc190501.50322900': __('identifierSchemeCodeUnspsc190501.50322900'),
	'identifierSchemeCodeUnspsc190501.50322901': __('identifierSchemeCodeUnspsc190501.50322901'),
	'identifierSchemeCodeUnspsc190501.50322902': __('identifierSchemeCodeUnspsc190501.50322902'),
	'identifierSchemeCodeUnspsc190501.50322903': __('identifierSchemeCodeUnspsc190501.50322903'),
	'identifierSchemeCodeUnspsc190501.50322904': __('identifierSchemeCodeUnspsc190501.50322904'),
	'identifierSchemeCodeUnspsc190501.50322905': __('identifierSchemeCodeUnspsc190501.50322905'),
	'identifierSchemeCodeUnspsc190501.50322906': __('identifierSchemeCodeUnspsc190501.50322906'),
	'identifierSchemeCodeUnspsc190501.50322907': __('identifierSchemeCodeUnspsc190501.50322907'),
	'identifierSchemeCodeUnspsc190501.50322908': __('identifierSchemeCodeUnspsc190501.50322908'),
	'identifierSchemeCodeUnspsc190501.50322909': __('identifierSchemeCodeUnspsc190501.50322909'),
	'identifierSchemeCodeUnspsc190501.50322910': __('identifierSchemeCodeUnspsc190501.50322910'),
	'identifierSchemeCodeUnspsc190501.50322911': __('identifierSchemeCodeUnspsc190501.50322911'),
	'identifierSchemeCodeUnspsc190501.50322912': __('identifierSchemeCodeUnspsc190501.50322912'),
	'identifierSchemeCodeUnspsc190501.50322913': __('identifierSchemeCodeUnspsc190501.50322913'),
	'identifierSchemeCodeUnspsc190501.50322914': __('identifierSchemeCodeUnspsc190501.50322914'),
	'identifierSchemeCodeUnspsc190501.50322915': __('identifierSchemeCodeUnspsc190501.50322915'),
	'identifierSchemeCodeUnspsc190501.50322916': __('identifierSchemeCodeUnspsc190501.50322916'),
	'identifierSchemeCodeUnspsc190501.50323000': __('identifierSchemeCodeUnspsc190501.50323000'),
	'identifierSchemeCodeUnspsc190501.50323001': __('identifierSchemeCodeUnspsc190501.50323001'),
	'identifierSchemeCodeUnspsc190501.50323002': __('identifierSchemeCodeUnspsc190501.50323002'),
	'identifierSchemeCodeUnspsc190501.50323100': __('identifierSchemeCodeUnspsc190501.50323100'),
	'identifierSchemeCodeUnspsc190501.50323101': __('identifierSchemeCodeUnspsc190501.50323101'),
	'identifierSchemeCodeUnspsc190501.50323102': __('identifierSchemeCodeUnspsc190501.50323102'),
	'identifierSchemeCodeUnspsc190501.50323103': __('identifierSchemeCodeUnspsc190501.50323103'),
	'identifierSchemeCodeUnspsc190501.50323104': __('identifierSchemeCodeUnspsc190501.50323104'),
	'identifierSchemeCodeUnspsc190501.50323105': __('identifierSchemeCodeUnspsc190501.50323105'),
	'identifierSchemeCodeUnspsc190501.50323106': __('identifierSchemeCodeUnspsc190501.50323106'),
	'identifierSchemeCodeUnspsc190501.50323107': __('identifierSchemeCodeUnspsc190501.50323107'),
	'identifierSchemeCodeUnspsc190501.50323108': __('identifierSchemeCodeUnspsc190501.50323108'),
	'identifierSchemeCodeUnspsc190501.50323109': __('identifierSchemeCodeUnspsc190501.50323109'),
	'identifierSchemeCodeUnspsc190501.50323110': __('identifierSchemeCodeUnspsc190501.50323110'),
	'identifierSchemeCodeUnspsc190501.50323111': __('identifierSchemeCodeUnspsc190501.50323111'),
	'identifierSchemeCodeUnspsc190501.50323200': __('identifierSchemeCodeUnspsc190501.50323200'),
	'identifierSchemeCodeUnspsc190501.50323201': __('identifierSchemeCodeUnspsc190501.50323201'),
	'identifierSchemeCodeUnspsc190501.50323202': __('identifierSchemeCodeUnspsc190501.50323202'),
	'identifierSchemeCodeUnspsc190501.50323203': __('identifierSchemeCodeUnspsc190501.50323203'),
	'identifierSchemeCodeUnspsc190501.50323204': __('identifierSchemeCodeUnspsc190501.50323204'),
	'identifierSchemeCodeUnspsc190501.50323205': __('identifierSchemeCodeUnspsc190501.50323205'),
	'identifierSchemeCodeUnspsc190501.50323206': __('identifierSchemeCodeUnspsc190501.50323206'),
	'identifierSchemeCodeUnspsc190501.50323207': __('identifierSchemeCodeUnspsc190501.50323207'),
	'identifierSchemeCodeUnspsc190501.50323300': __('identifierSchemeCodeUnspsc190501.50323300'),
	'identifierSchemeCodeUnspsc190501.50323301': __('identifierSchemeCodeUnspsc190501.50323301'),
	'identifierSchemeCodeUnspsc190501.50323302': __('identifierSchemeCodeUnspsc190501.50323302'),
	'identifierSchemeCodeUnspsc190501.50323303': __('identifierSchemeCodeUnspsc190501.50323303'),
	'identifierSchemeCodeUnspsc190501.50323304': __('identifierSchemeCodeUnspsc190501.50323304'),
	'identifierSchemeCodeUnspsc190501.50323305': __('identifierSchemeCodeUnspsc190501.50323305'),
	'identifierSchemeCodeUnspsc190501.50323306': __('identifierSchemeCodeUnspsc190501.50323306'),
	'identifierSchemeCodeUnspsc190501.50323307': __('identifierSchemeCodeUnspsc190501.50323307'),
	'identifierSchemeCodeUnspsc190501.50323308': __('identifierSchemeCodeUnspsc190501.50323308'),
	'identifierSchemeCodeUnspsc190501.50323309': __('identifierSchemeCodeUnspsc190501.50323309'),
	'identifierSchemeCodeUnspsc190501.50323400': __('identifierSchemeCodeUnspsc190501.50323400'),
	'identifierSchemeCodeUnspsc190501.50323401': __('identifierSchemeCodeUnspsc190501.50323401'),
	'identifierSchemeCodeUnspsc190501.50323402': __('identifierSchemeCodeUnspsc190501.50323402'),
	'identifierSchemeCodeUnspsc190501.50323403': __('identifierSchemeCodeUnspsc190501.50323403'),
	'identifierSchemeCodeUnspsc190501.50323404': __('identifierSchemeCodeUnspsc190501.50323404'),
	'identifierSchemeCodeUnspsc190501.50323405': __('identifierSchemeCodeUnspsc190501.50323405'),
	'identifierSchemeCodeUnspsc190501.50323406': __('identifierSchemeCodeUnspsc190501.50323406'),
	'identifierSchemeCodeUnspsc190501.50323407': __('identifierSchemeCodeUnspsc190501.50323407'),
	'identifierSchemeCodeUnspsc190501.50323408': __('identifierSchemeCodeUnspsc190501.50323408'),
	'identifierSchemeCodeUnspsc190501.50323409': __('identifierSchemeCodeUnspsc190501.50323409'),
	'identifierSchemeCodeUnspsc190501.50323410': __('identifierSchemeCodeUnspsc190501.50323410'),
	'identifierSchemeCodeUnspsc190501.50323411': __('identifierSchemeCodeUnspsc190501.50323411'),
	'identifierSchemeCodeUnspsc190501.50323412': __('identifierSchemeCodeUnspsc190501.50323412'),
	'identifierSchemeCodeUnspsc190501.50323413': __('identifierSchemeCodeUnspsc190501.50323413'),
	'identifierSchemeCodeUnspsc190501.50323414': __('identifierSchemeCodeUnspsc190501.50323414'),
	'identifierSchemeCodeUnspsc190501.50323415': __('identifierSchemeCodeUnspsc190501.50323415'),
	'identifierSchemeCodeUnspsc190501.50323416': __('identifierSchemeCodeUnspsc190501.50323416'),
	'identifierSchemeCodeUnspsc190501.50323417': __('identifierSchemeCodeUnspsc190501.50323417'),
	'identifierSchemeCodeUnspsc190501.50323418': __('identifierSchemeCodeUnspsc190501.50323418'),
	'identifierSchemeCodeUnspsc190501.50323419': __('identifierSchemeCodeUnspsc190501.50323419'),
	'identifierSchemeCodeUnspsc190501.50323420': __('identifierSchemeCodeUnspsc190501.50323420'),
	'identifierSchemeCodeUnspsc190501.50323421': __('identifierSchemeCodeUnspsc190501.50323421'),
	'identifierSchemeCodeUnspsc190501.50323422': __('identifierSchemeCodeUnspsc190501.50323422'),
	'identifierSchemeCodeUnspsc190501.50323423': __('identifierSchemeCodeUnspsc190501.50323423'),
	'identifierSchemeCodeUnspsc190501.50323424': __('identifierSchemeCodeUnspsc190501.50323424'),
	'identifierSchemeCodeUnspsc190501.50323425': __('identifierSchemeCodeUnspsc190501.50323425'),
	'identifierSchemeCodeUnspsc190501.50323426': __('identifierSchemeCodeUnspsc190501.50323426'),
	'identifierSchemeCodeUnspsc190501.50323427': __('identifierSchemeCodeUnspsc190501.50323427'),
	'identifierSchemeCodeUnspsc190501.50323428': __('identifierSchemeCodeUnspsc190501.50323428'),
	'identifierSchemeCodeUnspsc190501.50323429': __('identifierSchemeCodeUnspsc190501.50323429'),
	'identifierSchemeCodeUnspsc190501.50323430': __('identifierSchemeCodeUnspsc190501.50323430'),
	'identifierSchemeCodeUnspsc190501.50323431': __('identifierSchemeCodeUnspsc190501.50323431'),
	'identifierSchemeCodeUnspsc190501.50323432': __('identifierSchemeCodeUnspsc190501.50323432'),
	'identifierSchemeCodeUnspsc190501.50323433': __('identifierSchemeCodeUnspsc190501.50323433'),
	'identifierSchemeCodeUnspsc190501.50323434': __('identifierSchemeCodeUnspsc190501.50323434'),
	'identifierSchemeCodeUnspsc190501.50323435': __('identifierSchemeCodeUnspsc190501.50323435'),
	'identifierSchemeCodeUnspsc190501.50323436': __('identifierSchemeCodeUnspsc190501.50323436'),
	'identifierSchemeCodeUnspsc190501.50323437': __('identifierSchemeCodeUnspsc190501.50323437'),
	'identifierSchemeCodeUnspsc190501.50323438': __('identifierSchemeCodeUnspsc190501.50323438'),
	'identifierSchemeCodeUnspsc190501.50323439': __('identifierSchemeCodeUnspsc190501.50323439'),
	'identifierSchemeCodeUnspsc190501.50323440': __('identifierSchemeCodeUnspsc190501.50323440'),
	'identifierSchemeCodeUnspsc190501.50323441': __('identifierSchemeCodeUnspsc190501.50323441'),
	'identifierSchemeCodeUnspsc190501.50323442': __('identifierSchemeCodeUnspsc190501.50323442'),
	'identifierSchemeCodeUnspsc190501.50323443': __('identifierSchemeCodeUnspsc190501.50323443'),
	'identifierSchemeCodeUnspsc190501.50323444': __('identifierSchemeCodeUnspsc190501.50323444'),
	'identifierSchemeCodeUnspsc190501.50323445': __('identifierSchemeCodeUnspsc190501.50323445'),
	'identifierSchemeCodeUnspsc190501.50323446': __('identifierSchemeCodeUnspsc190501.50323446'),
	'identifierSchemeCodeUnspsc190501.50323447': __('identifierSchemeCodeUnspsc190501.50323447'),
	'identifierSchemeCodeUnspsc190501.50323448': __('identifierSchemeCodeUnspsc190501.50323448'),
	'identifierSchemeCodeUnspsc190501.50323449': __('identifierSchemeCodeUnspsc190501.50323449'),
	'identifierSchemeCodeUnspsc190501.50323450': __('identifierSchemeCodeUnspsc190501.50323450'),
	'identifierSchemeCodeUnspsc190501.50323451': __('identifierSchemeCodeUnspsc190501.50323451'),
	'identifierSchemeCodeUnspsc190501.50323452': __('identifierSchemeCodeUnspsc190501.50323452'),
	'identifierSchemeCodeUnspsc190501.50323453': __('identifierSchemeCodeUnspsc190501.50323453'),
	'identifierSchemeCodeUnspsc190501.50323454': __('identifierSchemeCodeUnspsc190501.50323454'),
	'identifierSchemeCodeUnspsc190501.50323455': __('identifierSchemeCodeUnspsc190501.50323455'),
	'identifierSchemeCodeUnspsc190501.50323456': __('identifierSchemeCodeUnspsc190501.50323456'),
	'identifierSchemeCodeUnspsc190501.50323457': __('identifierSchemeCodeUnspsc190501.50323457'),
	'identifierSchemeCodeUnspsc190501.50323458': __('identifierSchemeCodeUnspsc190501.50323458'),
	'identifierSchemeCodeUnspsc190501.50323459': __('identifierSchemeCodeUnspsc190501.50323459'),
	'identifierSchemeCodeUnspsc190501.50323460': __('identifierSchemeCodeUnspsc190501.50323460'),
	'identifierSchemeCodeUnspsc190501.50323461': __('identifierSchemeCodeUnspsc190501.50323461'),
	'identifierSchemeCodeUnspsc190501.50323462': __('identifierSchemeCodeUnspsc190501.50323462'),
	'identifierSchemeCodeUnspsc190501.50323463': __('identifierSchemeCodeUnspsc190501.50323463'),
	'identifierSchemeCodeUnspsc190501.50323464': __('identifierSchemeCodeUnspsc190501.50323464'),
	'identifierSchemeCodeUnspsc190501.50323465': __('identifierSchemeCodeUnspsc190501.50323465'),
	'identifierSchemeCodeUnspsc190501.50323466': __('identifierSchemeCodeUnspsc190501.50323466'),
	'identifierSchemeCodeUnspsc190501.50323467': __('identifierSchemeCodeUnspsc190501.50323467'),
	'identifierSchemeCodeUnspsc190501.50323468': __('identifierSchemeCodeUnspsc190501.50323468'),
	'identifierSchemeCodeUnspsc190501.50323469': __('identifierSchemeCodeUnspsc190501.50323469'),
	'identifierSchemeCodeUnspsc190501.50323470': __('identifierSchemeCodeUnspsc190501.50323470'),
	'identifierSchemeCodeUnspsc190501.50323471': __('identifierSchemeCodeUnspsc190501.50323471'),
	'identifierSchemeCodeUnspsc190501.50323472': __('identifierSchemeCodeUnspsc190501.50323472'),
	'identifierSchemeCodeUnspsc190501.50323473': __('identifierSchemeCodeUnspsc190501.50323473'),
	'identifierSchemeCodeUnspsc190501.50323474': __('identifierSchemeCodeUnspsc190501.50323474'),
	'identifierSchemeCodeUnspsc190501.50323475': __('identifierSchemeCodeUnspsc190501.50323475'),
	'identifierSchemeCodeUnspsc190501.50323476': __('identifierSchemeCodeUnspsc190501.50323476'),
	'identifierSchemeCodeUnspsc190501.50323477': __('identifierSchemeCodeUnspsc190501.50323477'),
	'identifierSchemeCodeUnspsc190501.50323478': __('identifierSchemeCodeUnspsc190501.50323478'),
	'identifierSchemeCodeUnspsc190501.50323479': __('identifierSchemeCodeUnspsc190501.50323479'),
	'identifierSchemeCodeUnspsc190501.50323480': __('identifierSchemeCodeUnspsc190501.50323480'),
	'identifierSchemeCodeUnspsc190501.50323481': __('identifierSchemeCodeUnspsc190501.50323481'),
	'identifierSchemeCodeUnspsc190501.50323482': __('identifierSchemeCodeUnspsc190501.50323482'),
	'identifierSchemeCodeUnspsc190501.50323483': __('identifierSchemeCodeUnspsc190501.50323483'),
	'identifierSchemeCodeUnspsc190501.50323484': __('identifierSchemeCodeUnspsc190501.50323484'),
	'identifierSchemeCodeUnspsc190501.50323485': __('identifierSchemeCodeUnspsc190501.50323485'),
	'identifierSchemeCodeUnspsc190501.50323486': __('identifierSchemeCodeUnspsc190501.50323486'),
	'identifierSchemeCodeUnspsc190501.50323487': __('identifierSchemeCodeUnspsc190501.50323487'),
	'identifierSchemeCodeUnspsc190501.50323488': __('identifierSchemeCodeUnspsc190501.50323488'),
	'identifierSchemeCodeUnspsc190501.50323489': __('identifierSchemeCodeUnspsc190501.50323489'),
	'identifierSchemeCodeUnspsc190501.50323490': __('identifierSchemeCodeUnspsc190501.50323490'),
	'identifierSchemeCodeUnspsc190501.50323491': __('identifierSchemeCodeUnspsc190501.50323491'),
	'identifierSchemeCodeUnspsc190501.50323492': __('identifierSchemeCodeUnspsc190501.50323492'),
	'identifierSchemeCodeUnspsc190501.50323493': __('identifierSchemeCodeUnspsc190501.50323493'),
	'identifierSchemeCodeUnspsc190501.50323494': __('identifierSchemeCodeUnspsc190501.50323494'),
	'identifierSchemeCodeUnspsc190501.50323495': __('identifierSchemeCodeUnspsc190501.50323495'),
	'identifierSchemeCodeUnspsc190501.50323496': __('identifierSchemeCodeUnspsc190501.50323496'),
	'identifierSchemeCodeUnspsc190501.50323500': __('identifierSchemeCodeUnspsc190501.50323500'),
	'identifierSchemeCodeUnspsc190501.50323501': __('identifierSchemeCodeUnspsc190501.50323501'),
	'identifierSchemeCodeUnspsc190501.50323502': __('identifierSchemeCodeUnspsc190501.50323502'),
	'identifierSchemeCodeUnspsc190501.50323503': __('identifierSchemeCodeUnspsc190501.50323503'),
	'identifierSchemeCodeUnspsc190501.50323504': __('identifierSchemeCodeUnspsc190501.50323504'),
	'identifierSchemeCodeUnspsc190501.50323505': __('identifierSchemeCodeUnspsc190501.50323505'),
	'identifierSchemeCodeUnspsc190501.50323506': __('identifierSchemeCodeUnspsc190501.50323506'),
	'identifierSchemeCodeUnspsc190501.50323600': __('identifierSchemeCodeUnspsc190501.50323600'),
	'identifierSchemeCodeUnspsc190501.50323601': __('identifierSchemeCodeUnspsc190501.50323601'),
	'identifierSchemeCodeUnspsc190501.50323602': __('identifierSchemeCodeUnspsc190501.50323602'),
	'identifierSchemeCodeUnspsc190501.50323603': __('identifierSchemeCodeUnspsc190501.50323603'),
	'identifierSchemeCodeUnspsc190501.50323604': __('identifierSchemeCodeUnspsc190501.50323604'),
	'identifierSchemeCodeUnspsc190501.50323605': __('identifierSchemeCodeUnspsc190501.50323605'),
	'identifierSchemeCodeUnspsc190501.50323606': __('identifierSchemeCodeUnspsc190501.50323606'),
	'identifierSchemeCodeUnspsc190501.50323607': __('identifierSchemeCodeUnspsc190501.50323607'),
	'identifierSchemeCodeUnspsc190501.50323608': __('identifierSchemeCodeUnspsc190501.50323608'),
	'identifierSchemeCodeUnspsc190501.50323609': __('identifierSchemeCodeUnspsc190501.50323609'),
	'identifierSchemeCodeUnspsc190501.50323610': __('identifierSchemeCodeUnspsc190501.50323610'),
	'identifierSchemeCodeUnspsc190501.50323611': __('identifierSchemeCodeUnspsc190501.50323611'),
	'identifierSchemeCodeUnspsc190501.50323612': __('identifierSchemeCodeUnspsc190501.50323612'),
	'identifierSchemeCodeUnspsc190501.50323613': __('identifierSchemeCodeUnspsc190501.50323613'),
	'identifierSchemeCodeUnspsc190501.50323614': __('identifierSchemeCodeUnspsc190501.50323614'),
	'identifierSchemeCodeUnspsc190501.50323615': __('identifierSchemeCodeUnspsc190501.50323615'),
	'identifierSchemeCodeUnspsc190501.50323616': __('identifierSchemeCodeUnspsc190501.50323616'),
	'identifierSchemeCodeUnspsc190501.50323617': __('identifierSchemeCodeUnspsc190501.50323617'),
	'identifierSchemeCodeUnspsc190501.50323618': __('identifierSchemeCodeUnspsc190501.50323618'),
	'identifierSchemeCodeUnspsc190501.50323619': __('identifierSchemeCodeUnspsc190501.50323619'),
	'identifierSchemeCodeUnspsc190501.50323620': __('identifierSchemeCodeUnspsc190501.50323620'),
	'identifierSchemeCodeUnspsc190501.50323621': __('identifierSchemeCodeUnspsc190501.50323621'),
	'identifierSchemeCodeUnspsc190501.50323622': __('identifierSchemeCodeUnspsc190501.50323622'),
	'identifierSchemeCodeUnspsc190501.50323623': __('identifierSchemeCodeUnspsc190501.50323623'),
	'identifierSchemeCodeUnspsc190501.50323624': __('identifierSchemeCodeUnspsc190501.50323624'),
	'identifierSchemeCodeUnspsc190501.50323625': __('identifierSchemeCodeUnspsc190501.50323625'),
	'identifierSchemeCodeUnspsc190501.50323626': __('identifierSchemeCodeUnspsc190501.50323626'),
	'identifierSchemeCodeUnspsc190501.50323627': __('identifierSchemeCodeUnspsc190501.50323627'),
	'identifierSchemeCodeUnspsc190501.50323628': __('identifierSchemeCodeUnspsc190501.50323628'),
	'identifierSchemeCodeUnspsc190501.50323629': __('identifierSchemeCodeUnspsc190501.50323629'),
	'identifierSchemeCodeUnspsc190501.50323630': __('identifierSchemeCodeUnspsc190501.50323630'),
	'identifierSchemeCodeUnspsc190501.50323631': __('identifierSchemeCodeUnspsc190501.50323631'),
	'identifierSchemeCodeUnspsc190501.50323632': __('identifierSchemeCodeUnspsc190501.50323632'),
	'identifierSchemeCodeUnspsc190501.50323633': __('identifierSchemeCodeUnspsc190501.50323633'),
	'identifierSchemeCodeUnspsc190501.50323634': __('identifierSchemeCodeUnspsc190501.50323634'),
	'identifierSchemeCodeUnspsc190501.50323635': __('identifierSchemeCodeUnspsc190501.50323635'),
	'identifierSchemeCodeUnspsc190501.50323636': __('identifierSchemeCodeUnspsc190501.50323636'),
	'identifierSchemeCodeUnspsc190501.50323637': __('identifierSchemeCodeUnspsc190501.50323637'),
	'identifierSchemeCodeUnspsc190501.50323638': __('identifierSchemeCodeUnspsc190501.50323638'),
	'identifierSchemeCodeUnspsc190501.50323639': __('identifierSchemeCodeUnspsc190501.50323639'),
	'identifierSchemeCodeUnspsc190501.50323640': __('identifierSchemeCodeUnspsc190501.50323640'),
	'identifierSchemeCodeUnspsc190501.50323641': __('identifierSchemeCodeUnspsc190501.50323641'),
	'identifierSchemeCodeUnspsc190501.50323642': __('identifierSchemeCodeUnspsc190501.50323642'),
	'identifierSchemeCodeUnspsc190501.50323643': __('identifierSchemeCodeUnspsc190501.50323643'),
	'identifierSchemeCodeUnspsc190501.50323644': __('identifierSchemeCodeUnspsc190501.50323644'),
	'identifierSchemeCodeUnspsc190501.50323645': __('identifierSchemeCodeUnspsc190501.50323645'),
	'identifierSchemeCodeUnspsc190501.50323646': __('identifierSchemeCodeUnspsc190501.50323646'),
	'identifierSchemeCodeUnspsc190501.50323647': __('identifierSchemeCodeUnspsc190501.50323647'),
	'identifierSchemeCodeUnspsc190501.50323648': __('identifierSchemeCodeUnspsc190501.50323648'),
	'identifierSchemeCodeUnspsc190501.50323649': __('identifierSchemeCodeUnspsc190501.50323649'),
	'identifierSchemeCodeUnspsc190501.50323650': __('identifierSchemeCodeUnspsc190501.50323650'),
	'identifierSchemeCodeUnspsc190501.50323651': __('identifierSchemeCodeUnspsc190501.50323651'),
	'identifierSchemeCodeUnspsc190501.50323652': __('identifierSchemeCodeUnspsc190501.50323652'),
	'identifierSchemeCodeUnspsc190501.50323653': __('identifierSchemeCodeUnspsc190501.50323653'),
	'identifierSchemeCodeUnspsc190501.50323654': __('identifierSchemeCodeUnspsc190501.50323654'),
	'identifierSchemeCodeUnspsc190501.50323655': __('identifierSchemeCodeUnspsc190501.50323655'),
	'identifierSchemeCodeUnspsc190501.50323656': __('identifierSchemeCodeUnspsc190501.50323656'),
	'identifierSchemeCodeUnspsc190501.50323657': __('identifierSchemeCodeUnspsc190501.50323657'),
	'identifierSchemeCodeUnspsc190501.50323658': __('identifierSchemeCodeUnspsc190501.50323658'),
	'identifierSchemeCodeUnspsc190501.50323659': __('identifierSchemeCodeUnspsc190501.50323659'),
	'identifierSchemeCodeUnspsc190501.50323660': __('identifierSchemeCodeUnspsc190501.50323660'),
	'identifierSchemeCodeUnspsc190501.50323661': __('identifierSchemeCodeUnspsc190501.50323661'),
	'identifierSchemeCodeUnspsc190501.50323662': __('identifierSchemeCodeUnspsc190501.50323662'),
	'identifierSchemeCodeUnspsc190501.50323663': __('identifierSchemeCodeUnspsc190501.50323663'),
	'identifierSchemeCodeUnspsc190501.50323664': __('identifierSchemeCodeUnspsc190501.50323664'),
	'identifierSchemeCodeUnspsc190501.50323665': __('identifierSchemeCodeUnspsc190501.50323665'),
	'identifierSchemeCodeUnspsc190501.50323700': __('identifierSchemeCodeUnspsc190501.50323700'),
	'identifierSchemeCodeUnspsc190501.50323701': __('identifierSchemeCodeUnspsc190501.50323701'),
	'identifierSchemeCodeUnspsc190501.50323702': __('identifierSchemeCodeUnspsc190501.50323702'),
	'identifierSchemeCodeUnspsc190501.50323703': __('identifierSchemeCodeUnspsc190501.50323703'),
	'identifierSchemeCodeUnspsc190501.50323704': __('identifierSchemeCodeUnspsc190501.50323704'),
	'identifierSchemeCodeUnspsc190501.50323800': __('identifierSchemeCodeUnspsc190501.50323800'),
	'identifierSchemeCodeUnspsc190501.50323801': __('identifierSchemeCodeUnspsc190501.50323801'),
	'identifierSchemeCodeUnspsc190501.50323802': __('identifierSchemeCodeUnspsc190501.50323802'),
	'identifierSchemeCodeUnspsc190501.50323803': __('identifierSchemeCodeUnspsc190501.50323803'),
	'identifierSchemeCodeUnspsc190501.50323804': __('identifierSchemeCodeUnspsc190501.50323804'),
	'identifierSchemeCodeUnspsc190501.50323805': __('identifierSchemeCodeUnspsc190501.50323805'),
	'identifierSchemeCodeUnspsc190501.50323900': __('identifierSchemeCodeUnspsc190501.50323900'),
	'identifierSchemeCodeUnspsc190501.50323901': __('identifierSchemeCodeUnspsc190501.50323901'),
	'identifierSchemeCodeUnspsc190501.50323902': __('identifierSchemeCodeUnspsc190501.50323902'),
	'identifierSchemeCodeUnspsc190501.50323903': __('identifierSchemeCodeUnspsc190501.50323903'),
	'identifierSchemeCodeUnspsc190501.50323904': __('identifierSchemeCodeUnspsc190501.50323904'),
	'identifierSchemeCodeUnspsc190501.50323905': __('identifierSchemeCodeUnspsc190501.50323905'),
	'identifierSchemeCodeUnspsc190501.50323906': __('identifierSchemeCodeUnspsc190501.50323906'),
	'identifierSchemeCodeUnspsc190501.50324000': __('identifierSchemeCodeUnspsc190501.50324000'),
	'identifierSchemeCodeUnspsc190501.50324001': __('identifierSchemeCodeUnspsc190501.50324001'),
	'identifierSchemeCodeUnspsc190501.50324002': __('identifierSchemeCodeUnspsc190501.50324002'),
	'identifierSchemeCodeUnspsc190501.50324003': __('identifierSchemeCodeUnspsc190501.50324003'),
	'identifierSchemeCodeUnspsc190501.50324004': __('identifierSchemeCodeUnspsc190501.50324004'),
	'identifierSchemeCodeUnspsc190501.50324005': __('identifierSchemeCodeUnspsc190501.50324005'),
	'identifierSchemeCodeUnspsc190501.50324006': __('identifierSchemeCodeUnspsc190501.50324006'),
	'identifierSchemeCodeUnspsc190501.50324100': __('identifierSchemeCodeUnspsc190501.50324100'),
	'identifierSchemeCodeUnspsc190501.50324101': __('identifierSchemeCodeUnspsc190501.50324101'),
	'identifierSchemeCodeUnspsc190501.50324102': __('identifierSchemeCodeUnspsc190501.50324102'),
	'identifierSchemeCodeUnspsc190501.50324103': __('identifierSchemeCodeUnspsc190501.50324103'),
	'identifierSchemeCodeUnspsc190501.50324104': __('identifierSchemeCodeUnspsc190501.50324104'),
	'identifierSchemeCodeUnspsc190501.50324105': __('identifierSchemeCodeUnspsc190501.50324105'),
	'identifierSchemeCodeUnspsc190501.50324106': __('identifierSchemeCodeUnspsc190501.50324106'),
	'identifierSchemeCodeUnspsc190501.50324107': __('identifierSchemeCodeUnspsc190501.50324107'),
	'identifierSchemeCodeUnspsc190501.50324108': __('identifierSchemeCodeUnspsc190501.50324108'),
	'identifierSchemeCodeUnspsc190501.50324200': __('identifierSchemeCodeUnspsc190501.50324200'),
	'identifierSchemeCodeUnspsc190501.50324201': __('identifierSchemeCodeUnspsc190501.50324201'),
	'identifierSchemeCodeUnspsc190501.50324202': __('identifierSchemeCodeUnspsc190501.50324202'),
	'identifierSchemeCodeUnspsc190501.50324203': __('identifierSchemeCodeUnspsc190501.50324203'),
	'identifierSchemeCodeUnspsc190501.50324204': __('identifierSchemeCodeUnspsc190501.50324204'),
	'identifierSchemeCodeUnspsc190501.50324205': __('identifierSchemeCodeUnspsc190501.50324205'),
	'identifierSchemeCodeUnspsc190501.50324206': __('identifierSchemeCodeUnspsc190501.50324206'),
	'identifierSchemeCodeUnspsc190501.50324207': __('identifierSchemeCodeUnspsc190501.50324207'),
	'identifierSchemeCodeUnspsc190501.50324208': __('identifierSchemeCodeUnspsc190501.50324208'),
	'identifierSchemeCodeUnspsc190501.50324300': __('identifierSchemeCodeUnspsc190501.50324300'),
	'identifierSchemeCodeUnspsc190501.50324301': __('identifierSchemeCodeUnspsc190501.50324301'),
	'identifierSchemeCodeUnspsc190501.50324302': __('identifierSchemeCodeUnspsc190501.50324302'),
	'identifierSchemeCodeUnspsc190501.50324303': __('identifierSchemeCodeUnspsc190501.50324303'),
	'identifierSchemeCodeUnspsc190501.50324304': __('identifierSchemeCodeUnspsc190501.50324304'),
	'identifierSchemeCodeUnspsc190501.50324305': __('identifierSchemeCodeUnspsc190501.50324305'),
	'identifierSchemeCodeUnspsc190501.50324306': __('identifierSchemeCodeUnspsc190501.50324306'),
	'identifierSchemeCodeUnspsc190501.50324307': __('identifierSchemeCodeUnspsc190501.50324307'),
	'identifierSchemeCodeUnspsc190501.50324308': __('identifierSchemeCodeUnspsc190501.50324308'),
	'identifierSchemeCodeUnspsc190501.50324309': __('identifierSchemeCodeUnspsc190501.50324309'),
	'identifierSchemeCodeUnspsc190501.50324310': __('identifierSchemeCodeUnspsc190501.50324310'),
	'identifierSchemeCodeUnspsc190501.50324311': __('identifierSchemeCodeUnspsc190501.50324311'),
	'identifierSchemeCodeUnspsc190501.50324312': __('identifierSchemeCodeUnspsc190501.50324312'),
	'identifierSchemeCodeUnspsc190501.50324313': __('identifierSchemeCodeUnspsc190501.50324313'),
	'identifierSchemeCodeUnspsc190501.50324400': __('identifierSchemeCodeUnspsc190501.50324400'),
	'identifierSchemeCodeUnspsc190501.50324401': __('identifierSchemeCodeUnspsc190501.50324401'),
	'identifierSchemeCodeUnspsc190501.50324402': __('identifierSchemeCodeUnspsc190501.50324402'),
	'identifierSchemeCodeUnspsc190501.50324403': __('identifierSchemeCodeUnspsc190501.50324403'),
	'identifierSchemeCodeUnspsc190501.50324404': __('identifierSchemeCodeUnspsc190501.50324404'),
	'identifierSchemeCodeUnspsc190501.50324405': __('identifierSchemeCodeUnspsc190501.50324405'),
	'identifierSchemeCodeUnspsc190501.50324406': __('identifierSchemeCodeUnspsc190501.50324406'),
	'identifierSchemeCodeUnspsc190501.50324407': __('identifierSchemeCodeUnspsc190501.50324407'),
	'identifierSchemeCodeUnspsc190501.50324408': __('identifierSchemeCodeUnspsc190501.50324408'),
	'identifierSchemeCodeUnspsc190501.50324409': __('identifierSchemeCodeUnspsc190501.50324409'),
	'identifierSchemeCodeUnspsc190501.50324410': __('identifierSchemeCodeUnspsc190501.50324410'),
	'identifierSchemeCodeUnspsc190501.50324411': __('identifierSchemeCodeUnspsc190501.50324411'),
	'identifierSchemeCodeUnspsc190501.50324412': __('identifierSchemeCodeUnspsc190501.50324412'),
	'identifierSchemeCodeUnspsc190501.50324413': __('identifierSchemeCodeUnspsc190501.50324413'),
	'identifierSchemeCodeUnspsc190501.50324414': __('identifierSchemeCodeUnspsc190501.50324414'),
	'identifierSchemeCodeUnspsc190501.50324415': __('identifierSchemeCodeUnspsc190501.50324415'),
	'identifierSchemeCodeUnspsc190501.50324416': __('identifierSchemeCodeUnspsc190501.50324416'),
	'identifierSchemeCodeUnspsc190501.50324417': __('identifierSchemeCodeUnspsc190501.50324417'),
	'identifierSchemeCodeUnspsc190501.50324418': __('identifierSchemeCodeUnspsc190501.50324418'),
	'identifierSchemeCodeUnspsc190501.50324419': __('identifierSchemeCodeUnspsc190501.50324419'),
	'identifierSchemeCodeUnspsc190501.50324420': __('identifierSchemeCodeUnspsc190501.50324420'),
	'identifierSchemeCodeUnspsc190501.50324421': __('identifierSchemeCodeUnspsc190501.50324421'),
	'identifierSchemeCodeUnspsc190501.50324422': __('identifierSchemeCodeUnspsc190501.50324422'),
	'identifierSchemeCodeUnspsc190501.50324423': __('identifierSchemeCodeUnspsc190501.50324423'),
	'identifierSchemeCodeUnspsc190501.50324424': __('identifierSchemeCodeUnspsc190501.50324424'),
	'identifierSchemeCodeUnspsc190501.50324425': __('identifierSchemeCodeUnspsc190501.50324425'),
	'identifierSchemeCodeUnspsc190501.50324426': __('identifierSchemeCodeUnspsc190501.50324426'),
	'identifierSchemeCodeUnspsc190501.50324427': __('identifierSchemeCodeUnspsc190501.50324427'),
	'identifierSchemeCodeUnspsc190501.50324428': __('identifierSchemeCodeUnspsc190501.50324428'),
	'identifierSchemeCodeUnspsc190501.50324429': __('identifierSchemeCodeUnspsc190501.50324429'),
	'identifierSchemeCodeUnspsc190501.50324430': __('identifierSchemeCodeUnspsc190501.50324430'),
	'identifierSchemeCodeUnspsc190501.50324431': __('identifierSchemeCodeUnspsc190501.50324431'),
	'identifierSchemeCodeUnspsc190501.50324432': __('identifierSchemeCodeUnspsc190501.50324432'),
	'identifierSchemeCodeUnspsc190501.50324433': __('identifierSchemeCodeUnspsc190501.50324433'),
	'identifierSchemeCodeUnspsc190501.50324434': __('identifierSchemeCodeUnspsc190501.50324434'),
	'identifierSchemeCodeUnspsc190501.50324435': __('identifierSchemeCodeUnspsc190501.50324435'),
	'identifierSchemeCodeUnspsc190501.50324436': __('identifierSchemeCodeUnspsc190501.50324436'),
	'identifierSchemeCodeUnspsc190501.50324437': __('identifierSchemeCodeUnspsc190501.50324437'),
	'identifierSchemeCodeUnspsc190501.50324438': __('identifierSchemeCodeUnspsc190501.50324438'),
	'identifierSchemeCodeUnspsc190501.50324439': __('identifierSchemeCodeUnspsc190501.50324439'),
	'identifierSchemeCodeUnspsc190501.50324440': __('identifierSchemeCodeUnspsc190501.50324440'),
	'identifierSchemeCodeUnspsc190501.50324441': __('identifierSchemeCodeUnspsc190501.50324441'),
	'identifierSchemeCodeUnspsc190501.50324442': __('identifierSchemeCodeUnspsc190501.50324442'),
	'identifierSchemeCodeUnspsc190501.50324443': __('identifierSchemeCodeUnspsc190501.50324443'),
	'identifierSchemeCodeUnspsc190501.50324444': __('identifierSchemeCodeUnspsc190501.50324444'),
	'identifierSchemeCodeUnspsc190501.50324445': __('identifierSchemeCodeUnspsc190501.50324445'),
	'identifierSchemeCodeUnspsc190501.50324446': __('identifierSchemeCodeUnspsc190501.50324446'),
	'identifierSchemeCodeUnspsc190501.50324500': __('identifierSchemeCodeUnspsc190501.50324500'),
	'identifierSchemeCodeUnspsc190501.50324501': __('identifierSchemeCodeUnspsc190501.50324501'),
	'identifierSchemeCodeUnspsc190501.50324502': __('identifierSchemeCodeUnspsc190501.50324502'),
	'identifierSchemeCodeUnspsc190501.50324503': __('identifierSchemeCodeUnspsc190501.50324503'),
	'identifierSchemeCodeUnspsc190501.50324504': __('identifierSchemeCodeUnspsc190501.50324504'),
	'identifierSchemeCodeUnspsc190501.50324505': __('identifierSchemeCodeUnspsc190501.50324505'),
	'identifierSchemeCodeUnspsc190501.50324506': __('identifierSchemeCodeUnspsc190501.50324506'),
	'identifierSchemeCodeUnspsc190501.50324507': __('identifierSchemeCodeUnspsc190501.50324507'),
	'identifierSchemeCodeUnspsc190501.50324508': __('identifierSchemeCodeUnspsc190501.50324508'),
	'identifierSchemeCodeUnspsc190501.50324509': __('identifierSchemeCodeUnspsc190501.50324509'),
	'identifierSchemeCodeUnspsc190501.50324510': __('identifierSchemeCodeUnspsc190501.50324510'),
	'identifierSchemeCodeUnspsc190501.50324511': __('identifierSchemeCodeUnspsc190501.50324511'),
	'identifierSchemeCodeUnspsc190501.50324512': __('identifierSchemeCodeUnspsc190501.50324512'),
	'identifierSchemeCodeUnspsc190501.50324513': __('identifierSchemeCodeUnspsc190501.50324513'),
	'identifierSchemeCodeUnspsc190501.50324514': __('identifierSchemeCodeUnspsc190501.50324514'),
	'identifierSchemeCodeUnspsc190501.50324515': __('identifierSchemeCodeUnspsc190501.50324515'),
	'identifierSchemeCodeUnspsc190501.50324516': __('identifierSchemeCodeUnspsc190501.50324516'),
	'identifierSchemeCodeUnspsc190501.50324517': __('identifierSchemeCodeUnspsc190501.50324517'),
	'identifierSchemeCodeUnspsc190501.50324518': __('identifierSchemeCodeUnspsc190501.50324518'),
	'identifierSchemeCodeUnspsc190501.50324519': __('identifierSchemeCodeUnspsc190501.50324519'),
	'identifierSchemeCodeUnspsc190501.50324520': __('identifierSchemeCodeUnspsc190501.50324520'),
	'identifierSchemeCodeUnspsc190501.50324521': __('identifierSchemeCodeUnspsc190501.50324521'),
	'identifierSchemeCodeUnspsc190501.50324522': __('identifierSchemeCodeUnspsc190501.50324522'),
	'identifierSchemeCodeUnspsc190501.50324523': __('identifierSchemeCodeUnspsc190501.50324523'),
	'identifierSchemeCodeUnspsc190501.50324524': __('identifierSchemeCodeUnspsc190501.50324524'),
	'identifierSchemeCodeUnspsc190501.50324525': __('identifierSchemeCodeUnspsc190501.50324525'),
	'identifierSchemeCodeUnspsc190501.50324600': __('identifierSchemeCodeUnspsc190501.50324600'),
	'identifierSchemeCodeUnspsc190501.50324601': __('identifierSchemeCodeUnspsc190501.50324601'),
	'identifierSchemeCodeUnspsc190501.50324602': __('identifierSchemeCodeUnspsc190501.50324602'),
	'identifierSchemeCodeUnspsc190501.50324603': __('identifierSchemeCodeUnspsc190501.50324603'),
	'identifierSchemeCodeUnspsc190501.50324604': __('identifierSchemeCodeUnspsc190501.50324604'),
	'identifierSchemeCodeUnspsc190501.50324605': __('identifierSchemeCodeUnspsc190501.50324605'),
	'identifierSchemeCodeUnspsc190501.50324606': __('identifierSchemeCodeUnspsc190501.50324606'),
	'identifierSchemeCodeUnspsc190501.50324607': __('identifierSchemeCodeUnspsc190501.50324607'),
	'identifierSchemeCodeUnspsc190501.50324608': __('identifierSchemeCodeUnspsc190501.50324608'),
	'identifierSchemeCodeUnspsc190501.50324609': __('identifierSchemeCodeUnspsc190501.50324609'),
	'identifierSchemeCodeUnspsc190501.50324610': __('identifierSchemeCodeUnspsc190501.50324610'),
	'identifierSchemeCodeUnspsc190501.50324611': __('identifierSchemeCodeUnspsc190501.50324611'),
	'identifierSchemeCodeUnspsc190501.50324612': __('identifierSchemeCodeUnspsc190501.50324612'),
	'identifierSchemeCodeUnspsc190501.50324613': __('identifierSchemeCodeUnspsc190501.50324613'),
	'identifierSchemeCodeUnspsc190501.50324614': __('identifierSchemeCodeUnspsc190501.50324614'),
	'identifierSchemeCodeUnspsc190501.50324615': __('identifierSchemeCodeUnspsc190501.50324615'),
	'identifierSchemeCodeUnspsc190501.50324616': __('identifierSchemeCodeUnspsc190501.50324616'),
	'identifierSchemeCodeUnspsc190501.50324617': __('identifierSchemeCodeUnspsc190501.50324617'),
	'identifierSchemeCodeUnspsc190501.50324618': __('identifierSchemeCodeUnspsc190501.50324618'),
	'identifierSchemeCodeUnspsc190501.50324619': __('identifierSchemeCodeUnspsc190501.50324619'),
	'identifierSchemeCodeUnspsc190501.50324620': __('identifierSchemeCodeUnspsc190501.50324620'),
	'identifierSchemeCodeUnspsc190501.50324621': __('identifierSchemeCodeUnspsc190501.50324621'),
	'identifierSchemeCodeUnspsc190501.50324622': __('identifierSchemeCodeUnspsc190501.50324622'),
	'identifierSchemeCodeUnspsc190501.50324623': __('identifierSchemeCodeUnspsc190501.50324623'),
	'identifierSchemeCodeUnspsc190501.50324624': __('identifierSchemeCodeUnspsc190501.50324624'),
	'identifierSchemeCodeUnspsc190501.50324625': __('identifierSchemeCodeUnspsc190501.50324625'),
	'identifierSchemeCodeUnspsc190501.50324626': __('identifierSchemeCodeUnspsc190501.50324626'),
	'identifierSchemeCodeUnspsc190501.50324627': __('identifierSchemeCodeUnspsc190501.50324627'),
	'identifierSchemeCodeUnspsc190501.50324628': __('identifierSchemeCodeUnspsc190501.50324628'),
	'identifierSchemeCodeUnspsc190501.50324629': __('identifierSchemeCodeUnspsc190501.50324629'),
	'identifierSchemeCodeUnspsc190501.50324630': __('identifierSchemeCodeUnspsc190501.50324630'),
	'identifierSchemeCodeUnspsc190501.50324631': __('identifierSchemeCodeUnspsc190501.50324631'),
	'identifierSchemeCodeUnspsc190501.50324632': __('identifierSchemeCodeUnspsc190501.50324632'),
	'identifierSchemeCodeUnspsc190501.50324633': __('identifierSchemeCodeUnspsc190501.50324633'),
	'identifierSchemeCodeUnspsc190501.50324634': __('identifierSchemeCodeUnspsc190501.50324634'),
	'identifierSchemeCodeUnspsc190501.50324635': __('identifierSchemeCodeUnspsc190501.50324635'),
	'identifierSchemeCodeUnspsc190501.50324636': __('identifierSchemeCodeUnspsc190501.50324636'),
	'identifierSchemeCodeUnspsc190501.50324637': __('identifierSchemeCodeUnspsc190501.50324637'),
	'identifierSchemeCodeUnspsc190501.50324638': __('identifierSchemeCodeUnspsc190501.50324638'),
	'identifierSchemeCodeUnspsc190501.50324639': __('identifierSchemeCodeUnspsc190501.50324639'),
	'identifierSchemeCodeUnspsc190501.50324640': __('identifierSchemeCodeUnspsc190501.50324640'),
	'identifierSchemeCodeUnspsc190501.50324641': __('identifierSchemeCodeUnspsc190501.50324641'),
	'identifierSchemeCodeUnspsc190501.50324642': __('identifierSchemeCodeUnspsc190501.50324642'),
	'identifierSchemeCodeUnspsc190501.50324643': __('identifierSchemeCodeUnspsc190501.50324643'),
	'identifierSchemeCodeUnspsc190501.50324644': __('identifierSchemeCodeUnspsc190501.50324644'),
	'identifierSchemeCodeUnspsc190501.50324645': __('identifierSchemeCodeUnspsc190501.50324645'),
	'identifierSchemeCodeUnspsc190501.50324646': __('identifierSchemeCodeUnspsc190501.50324646'),
	'identifierSchemeCodeUnspsc190501.50324647': __('identifierSchemeCodeUnspsc190501.50324647'),
	'identifierSchemeCodeUnspsc190501.50324648': __('identifierSchemeCodeUnspsc190501.50324648'),
	'identifierSchemeCodeUnspsc190501.50324649': __('identifierSchemeCodeUnspsc190501.50324649'),
	'identifierSchemeCodeUnspsc190501.50324650': __('identifierSchemeCodeUnspsc190501.50324650'),
	'identifierSchemeCodeUnspsc190501.50324651': __('identifierSchemeCodeUnspsc190501.50324651'),
	'identifierSchemeCodeUnspsc190501.50324652': __('identifierSchemeCodeUnspsc190501.50324652'),
	'identifierSchemeCodeUnspsc190501.50324653': __('identifierSchemeCodeUnspsc190501.50324653'),
	'identifierSchemeCodeUnspsc190501.50324654': __('identifierSchemeCodeUnspsc190501.50324654'),
	'identifierSchemeCodeUnspsc190501.50324655': __('identifierSchemeCodeUnspsc190501.50324655'),
	'identifierSchemeCodeUnspsc190501.50324656': __('identifierSchemeCodeUnspsc190501.50324656'),
	'identifierSchemeCodeUnspsc190501.50324657': __('identifierSchemeCodeUnspsc190501.50324657'),
	'identifierSchemeCodeUnspsc190501.50324658': __('identifierSchemeCodeUnspsc190501.50324658'),
	'identifierSchemeCodeUnspsc190501.50324659': __('identifierSchemeCodeUnspsc190501.50324659'),
	'identifierSchemeCodeUnspsc190501.50324660': __('identifierSchemeCodeUnspsc190501.50324660'),
	'identifierSchemeCodeUnspsc190501.50324661': __('identifierSchemeCodeUnspsc190501.50324661'),
	'identifierSchemeCodeUnspsc190501.50324700': __('identifierSchemeCodeUnspsc190501.50324700'),
	'identifierSchemeCodeUnspsc190501.50324701': __('identifierSchemeCodeUnspsc190501.50324701'),
	'identifierSchemeCodeUnspsc190501.50324702': __('identifierSchemeCodeUnspsc190501.50324702'),
	'identifierSchemeCodeUnspsc190501.50324800': __('identifierSchemeCodeUnspsc190501.50324800'),
	'identifierSchemeCodeUnspsc190501.50324801': __('identifierSchemeCodeUnspsc190501.50324801'),
	'identifierSchemeCodeUnspsc190501.50324802': __('identifierSchemeCodeUnspsc190501.50324802'),
	'identifierSchemeCodeUnspsc190501.50324900': __('identifierSchemeCodeUnspsc190501.50324900'),
	'identifierSchemeCodeUnspsc190501.50324901': __('identifierSchemeCodeUnspsc190501.50324901'),
	'identifierSchemeCodeUnspsc190501.50324902': __('identifierSchemeCodeUnspsc190501.50324902'),
	'identifierSchemeCodeUnspsc190501.50324903': __('identifierSchemeCodeUnspsc190501.50324903'),
	'identifierSchemeCodeUnspsc190501.50324904': __('identifierSchemeCodeUnspsc190501.50324904'),
	'identifierSchemeCodeUnspsc190501.50324905': __('identifierSchemeCodeUnspsc190501.50324905'),
	'identifierSchemeCodeUnspsc190501.50324906': __('identifierSchemeCodeUnspsc190501.50324906'),
	'identifierSchemeCodeUnspsc190501.50324907': __('identifierSchemeCodeUnspsc190501.50324907'),
	'identifierSchemeCodeUnspsc190501.50324908': __('identifierSchemeCodeUnspsc190501.50324908'),
	'identifierSchemeCodeUnspsc190501.50324909': __('identifierSchemeCodeUnspsc190501.50324909'),
	'identifierSchemeCodeUnspsc190501.50324910': __('identifierSchemeCodeUnspsc190501.50324910'),
	'identifierSchemeCodeUnspsc190501.50324911': __('identifierSchemeCodeUnspsc190501.50324911'),
	'identifierSchemeCodeUnspsc190501.50324912': __('identifierSchemeCodeUnspsc190501.50324912'),
	'identifierSchemeCodeUnspsc190501.50324913': __('identifierSchemeCodeUnspsc190501.50324913'),
	'identifierSchemeCodeUnspsc190501.50324914': __('identifierSchemeCodeUnspsc190501.50324914'),
	'identifierSchemeCodeUnspsc190501.50324915': __('identifierSchemeCodeUnspsc190501.50324915'),
	'identifierSchemeCodeUnspsc190501.50324916': __('identifierSchemeCodeUnspsc190501.50324916'),
	'identifierSchemeCodeUnspsc190501.50324917': __('identifierSchemeCodeUnspsc190501.50324917'),
	'identifierSchemeCodeUnspsc190501.50324918': __('identifierSchemeCodeUnspsc190501.50324918'),
	'identifierSchemeCodeUnspsc190501.50324919': __('identifierSchemeCodeUnspsc190501.50324919'),
	'identifierSchemeCodeUnspsc190501.50324920': __('identifierSchemeCodeUnspsc190501.50324920'),
	'identifierSchemeCodeUnspsc190501.50324921': __('identifierSchemeCodeUnspsc190501.50324921'),
	'identifierSchemeCodeUnspsc190501.50324922': __('identifierSchemeCodeUnspsc190501.50324922'),
	'identifierSchemeCodeUnspsc190501.50324923': __('identifierSchemeCodeUnspsc190501.50324923'),
	'identifierSchemeCodeUnspsc190501.50324924': __('identifierSchemeCodeUnspsc190501.50324924'),
	'identifierSchemeCodeUnspsc190501.50324925': __('identifierSchemeCodeUnspsc190501.50324925'),
	'identifierSchemeCodeUnspsc190501.50324926': __('identifierSchemeCodeUnspsc190501.50324926'),
	'identifierSchemeCodeUnspsc190501.50324927': __('identifierSchemeCodeUnspsc190501.50324927'),
	'identifierSchemeCodeUnspsc190501.50324928': __('identifierSchemeCodeUnspsc190501.50324928'),
	'identifierSchemeCodeUnspsc190501.50324929': __('identifierSchemeCodeUnspsc190501.50324929'),
	'identifierSchemeCodeUnspsc190501.50324930': __('identifierSchemeCodeUnspsc190501.50324930'),
	'identifierSchemeCodeUnspsc190501.50324931': __('identifierSchemeCodeUnspsc190501.50324931'),
	'identifierSchemeCodeUnspsc190501.50324932': __('identifierSchemeCodeUnspsc190501.50324932'),
	'identifierSchemeCodeUnspsc190501.50324933': __('identifierSchemeCodeUnspsc190501.50324933'),
	'identifierSchemeCodeUnspsc190501.50324934': __('identifierSchemeCodeUnspsc190501.50324934'),
	'identifierSchemeCodeUnspsc190501.50324935': __('identifierSchemeCodeUnspsc190501.50324935'),
	'identifierSchemeCodeUnspsc190501.50324936': __('identifierSchemeCodeUnspsc190501.50324936'),
	'identifierSchemeCodeUnspsc190501.50324937': __('identifierSchemeCodeUnspsc190501.50324937'),
	'identifierSchemeCodeUnspsc190501.50324938': __('identifierSchemeCodeUnspsc190501.50324938'),
	'identifierSchemeCodeUnspsc190501.50324939': __('identifierSchemeCodeUnspsc190501.50324939'),
	'identifierSchemeCodeUnspsc190501.50324940': __('identifierSchemeCodeUnspsc190501.50324940'),
	'identifierSchemeCodeUnspsc190501.50324941': __('identifierSchemeCodeUnspsc190501.50324941'),
	'identifierSchemeCodeUnspsc190501.50324942': __('identifierSchemeCodeUnspsc190501.50324942'),
	'identifierSchemeCodeUnspsc190501.50324943': __('identifierSchemeCodeUnspsc190501.50324943'),
	'identifierSchemeCodeUnspsc190501.50324944': __('identifierSchemeCodeUnspsc190501.50324944'),
	'identifierSchemeCodeUnspsc190501.50324945': __('identifierSchemeCodeUnspsc190501.50324945'),
	'identifierSchemeCodeUnspsc190501.50324946': __('identifierSchemeCodeUnspsc190501.50324946'),
	'identifierSchemeCodeUnspsc190501.50324947': __('identifierSchemeCodeUnspsc190501.50324947'),
	'identifierSchemeCodeUnspsc190501.50324948': __('identifierSchemeCodeUnspsc190501.50324948'),
	'identifierSchemeCodeUnspsc190501.50324949': __('identifierSchemeCodeUnspsc190501.50324949'),
	'identifierSchemeCodeUnspsc190501.50324950': __('identifierSchemeCodeUnspsc190501.50324950'),
	'identifierSchemeCodeUnspsc190501.50324951': __('identifierSchemeCodeUnspsc190501.50324951'),
	'identifierSchemeCodeUnspsc190501.50324952': __('identifierSchemeCodeUnspsc190501.50324952'),
	'identifierSchemeCodeUnspsc190501.50324953': __('identifierSchemeCodeUnspsc190501.50324953'),
	'identifierSchemeCodeUnspsc190501.50324954': __('identifierSchemeCodeUnspsc190501.50324954'),
	'identifierSchemeCodeUnspsc190501.50324955': __('identifierSchemeCodeUnspsc190501.50324955'),
	'identifierSchemeCodeUnspsc190501.50324956': __('identifierSchemeCodeUnspsc190501.50324956'),
	'identifierSchemeCodeUnspsc190501.50324957': __('identifierSchemeCodeUnspsc190501.50324957'),
	'identifierSchemeCodeUnspsc190501.50325000': __('identifierSchemeCodeUnspsc190501.50325000'),
	'identifierSchemeCodeUnspsc190501.50325001': __('identifierSchemeCodeUnspsc190501.50325001'),
	'identifierSchemeCodeUnspsc190501.50325002': __('identifierSchemeCodeUnspsc190501.50325002'),
	'identifierSchemeCodeUnspsc190501.50325003': __('identifierSchemeCodeUnspsc190501.50325003'),
	'identifierSchemeCodeUnspsc190501.50325004': __('identifierSchemeCodeUnspsc190501.50325004'),
	'identifierSchemeCodeUnspsc190501.50325005': __('identifierSchemeCodeUnspsc190501.50325005'),
	'identifierSchemeCodeUnspsc190501.50325006': __('identifierSchemeCodeUnspsc190501.50325006'),
	'identifierSchemeCodeUnspsc190501.50325007': __('identifierSchemeCodeUnspsc190501.50325007'),
	'identifierSchemeCodeUnspsc190501.50325008': __('identifierSchemeCodeUnspsc190501.50325008'),
	'identifierSchemeCodeUnspsc190501.50325009': __('identifierSchemeCodeUnspsc190501.50325009'),
	'identifierSchemeCodeUnspsc190501.50325010': __('identifierSchemeCodeUnspsc190501.50325010'),
	'identifierSchemeCodeUnspsc190501.50325011': __('identifierSchemeCodeUnspsc190501.50325011'),
	'identifierSchemeCodeUnspsc190501.50325012': __('identifierSchemeCodeUnspsc190501.50325012'),
	'identifierSchemeCodeUnspsc190501.50325013': __('identifierSchemeCodeUnspsc190501.50325013'),
	'identifierSchemeCodeUnspsc190501.50325014': __('identifierSchemeCodeUnspsc190501.50325014'),
	'identifierSchemeCodeUnspsc190501.50325015': __('identifierSchemeCodeUnspsc190501.50325015'),
	'identifierSchemeCodeUnspsc190501.50325016': __('identifierSchemeCodeUnspsc190501.50325016'),
	'identifierSchemeCodeUnspsc190501.50325017': __('identifierSchemeCodeUnspsc190501.50325017'),
	'identifierSchemeCodeUnspsc190501.50325018': __('identifierSchemeCodeUnspsc190501.50325018'),
	'identifierSchemeCodeUnspsc190501.50325019': __('identifierSchemeCodeUnspsc190501.50325019'),
	'identifierSchemeCodeUnspsc190501.50325020': __('identifierSchemeCodeUnspsc190501.50325020'),
	'identifierSchemeCodeUnspsc190501.50325021': __('identifierSchemeCodeUnspsc190501.50325021'),
	'identifierSchemeCodeUnspsc190501.50325022': __('identifierSchemeCodeUnspsc190501.50325022'),
	'identifierSchemeCodeUnspsc190501.50325023': __('identifierSchemeCodeUnspsc190501.50325023'),
	'identifierSchemeCodeUnspsc190501.50325024': __('identifierSchemeCodeUnspsc190501.50325024'),
	'identifierSchemeCodeUnspsc190501.50325025': __('identifierSchemeCodeUnspsc190501.50325025'),
	'identifierSchemeCodeUnspsc190501.50325026': __('identifierSchemeCodeUnspsc190501.50325026'),
	'identifierSchemeCodeUnspsc190501.50325027': __('identifierSchemeCodeUnspsc190501.50325027'),
	'identifierSchemeCodeUnspsc190501.50325028': __('identifierSchemeCodeUnspsc190501.50325028'),
	'identifierSchemeCodeUnspsc190501.50325029': __('identifierSchemeCodeUnspsc190501.50325029'),
	'identifierSchemeCodeUnspsc190501.50325030': __('identifierSchemeCodeUnspsc190501.50325030'),
	'identifierSchemeCodeUnspsc190501.50325031': __('identifierSchemeCodeUnspsc190501.50325031'),
	'identifierSchemeCodeUnspsc190501.50325032': __('identifierSchemeCodeUnspsc190501.50325032'),
	'identifierSchemeCodeUnspsc190501.50325033': __('identifierSchemeCodeUnspsc190501.50325033'),
	'identifierSchemeCodeUnspsc190501.50325034': __('identifierSchemeCodeUnspsc190501.50325034'),
	'identifierSchemeCodeUnspsc190501.50325035': __('identifierSchemeCodeUnspsc190501.50325035'),
	'identifierSchemeCodeUnspsc190501.50325036': __('identifierSchemeCodeUnspsc190501.50325036'),
	'identifierSchemeCodeUnspsc190501.50325037': __('identifierSchemeCodeUnspsc190501.50325037'),
	'identifierSchemeCodeUnspsc190501.50325038': __('identifierSchemeCodeUnspsc190501.50325038'),
	'identifierSchemeCodeUnspsc190501.50325039': __('identifierSchemeCodeUnspsc190501.50325039'),
	'identifierSchemeCodeUnspsc190501.50325040': __('identifierSchemeCodeUnspsc190501.50325040'),
	'identifierSchemeCodeUnspsc190501.50325041': __('identifierSchemeCodeUnspsc190501.50325041'),
	'identifierSchemeCodeUnspsc190501.50325042': __('identifierSchemeCodeUnspsc190501.50325042'),
	'identifierSchemeCodeUnspsc190501.50325043': __('identifierSchemeCodeUnspsc190501.50325043'),
	'identifierSchemeCodeUnspsc190501.50325044': __('identifierSchemeCodeUnspsc190501.50325044'),
	'identifierSchemeCodeUnspsc190501.50325100': __('identifierSchemeCodeUnspsc190501.50325100'),
	'identifierSchemeCodeUnspsc190501.50325101': __('identifierSchemeCodeUnspsc190501.50325101'),
	'identifierSchemeCodeUnspsc190501.50325102': __('identifierSchemeCodeUnspsc190501.50325102'),
	'identifierSchemeCodeUnspsc190501.50325103': __('identifierSchemeCodeUnspsc190501.50325103'),
	'identifierSchemeCodeUnspsc190501.50325104': __('identifierSchemeCodeUnspsc190501.50325104'),
	'identifierSchemeCodeUnspsc190501.50325105': __('identifierSchemeCodeUnspsc190501.50325105'),
	'identifierSchemeCodeUnspsc190501.50325106': __('identifierSchemeCodeUnspsc190501.50325106'),
	'identifierSchemeCodeUnspsc190501.50325200': __('identifierSchemeCodeUnspsc190501.50325200'),
	'identifierSchemeCodeUnspsc190501.50325201': __('identifierSchemeCodeUnspsc190501.50325201'),
	'identifierSchemeCodeUnspsc190501.50325202': __('identifierSchemeCodeUnspsc190501.50325202'),
	'identifierSchemeCodeUnspsc190501.50325203': __('identifierSchemeCodeUnspsc190501.50325203'),
	'identifierSchemeCodeUnspsc190501.50325204': __('identifierSchemeCodeUnspsc190501.50325204'),
	'identifierSchemeCodeUnspsc190501.50325205': __('identifierSchemeCodeUnspsc190501.50325205'),
	'identifierSchemeCodeUnspsc190501.50325206': __('identifierSchemeCodeUnspsc190501.50325206'),
	'identifierSchemeCodeUnspsc190501.50325207': __('identifierSchemeCodeUnspsc190501.50325207'),
	'identifierSchemeCodeUnspsc190501.50325208': __('identifierSchemeCodeUnspsc190501.50325208'),
	'identifierSchemeCodeUnspsc190501.50325209': __('identifierSchemeCodeUnspsc190501.50325209'),
	'identifierSchemeCodeUnspsc190501.50325210': __('identifierSchemeCodeUnspsc190501.50325210'),
	'identifierSchemeCodeUnspsc190501.50325300': __('identifierSchemeCodeUnspsc190501.50325300'),
	'identifierSchemeCodeUnspsc190501.50325301': __('identifierSchemeCodeUnspsc190501.50325301'),
	'identifierSchemeCodeUnspsc190501.50325302': __('identifierSchemeCodeUnspsc190501.50325302'),
	'identifierSchemeCodeUnspsc190501.50325303': __('identifierSchemeCodeUnspsc190501.50325303'),
	'identifierSchemeCodeUnspsc190501.50325304': __('identifierSchemeCodeUnspsc190501.50325304'),
	'identifierSchemeCodeUnspsc190501.50325305': __('identifierSchemeCodeUnspsc190501.50325305'),
	'identifierSchemeCodeUnspsc190501.50325306': __('identifierSchemeCodeUnspsc190501.50325306'),
	'identifierSchemeCodeUnspsc190501.50325307': __('identifierSchemeCodeUnspsc190501.50325307'),
	'identifierSchemeCodeUnspsc190501.50325308': __('identifierSchemeCodeUnspsc190501.50325308'),
	'identifierSchemeCodeUnspsc190501.50325309': __('identifierSchemeCodeUnspsc190501.50325309'),
	'identifierSchemeCodeUnspsc190501.50325310': __('identifierSchemeCodeUnspsc190501.50325310'),
	'identifierSchemeCodeUnspsc190501.50325311': __('identifierSchemeCodeUnspsc190501.50325311'),
	'identifierSchemeCodeUnspsc190501.50325312': __('identifierSchemeCodeUnspsc190501.50325312'),
	'identifierSchemeCodeUnspsc190501.50325313': __('identifierSchemeCodeUnspsc190501.50325313'),
	'identifierSchemeCodeUnspsc190501.50325314': __('identifierSchemeCodeUnspsc190501.50325314'),
	'identifierSchemeCodeUnspsc190501.50325315': __('identifierSchemeCodeUnspsc190501.50325315'),
	'identifierSchemeCodeUnspsc190501.50325316': __('identifierSchemeCodeUnspsc190501.50325316'),
	'identifierSchemeCodeUnspsc190501.50325317': __('identifierSchemeCodeUnspsc190501.50325317'),
	'identifierSchemeCodeUnspsc190501.50325318': __('identifierSchemeCodeUnspsc190501.50325318'),
	'identifierSchemeCodeUnspsc190501.50325319': __('identifierSchemeCodeUnspsc190501.50325319'),
	'identifierSchemeCodeUnspsc190501.50325320': __('identifierSchemeCodeUnspsc190501.50325320'),
	'identifierSchemeCodeUnspsc190501.50325321': __('identifierSchemeCodeUnspsc190501.50325321'),
	'identifierSchemeCodeUnspsc190501.50325322': __('identifierSchemeCodeUnspsc190501.50325322'),
	'identifierSchemeCodeUnspsc190501.50325323': __('identifierSchemeCodeUnspsc190501.50325323'),
	'identifierSchemeCodeUnspsc190501.50325324': __('identifierSchemeCodeUnspsc190501.50325324'),
	'identifierSchemeCodeUnspsc190501.50325325': __('identifierSchemeCodeUnspsc190501.50325325'),
	'identifierSchemeCodeUnspsc190501.50325326': __('identifierSchemeCodeUnspsc190501.50325326'),
	'identifierSchemeCodeUnspsc190501.50325327': __('identifierSchemeCodeUnspsc190501.50325327'),
	'identifierSchemeCodeUnspsc190501.50325328': __('identifierSchemeCodeUnspsc190501.50325328'),
	'identifierSchemeCodeUnspsc190501.50325329': __('identifierSchemeCodeUnspsc190501.50325329'),
	'identifierSchemeCodeUnspsc190501.50325330': __('identifierSchemeCodeUnspsc190501.50325330'),
	'identifierSchemeCodeUnspsc190501.50325331': __('identifierSchemeCodeUnspsc190501.50325331'),
	'identifierSchemeCodeUnspsc190501.50325332': __('identifierSchemeCodeUnspsc190501.50325332'),
	'identifierSchemeCodeUnspsc190501.50325333': __('identifierSchemeCodeUnspsc190501.50325333'),
	'identifierSchemeCodeUnspsc190501.50325334': __('identifierSchemeCodeUnspsc190501.50325334'),
	'identifierSchemeCodeUnspsc190501.50325335': __('identifierSchemeCodeUnspsc190501.50325335'),
	'identifierSchemeCodeUnspsc190501.50325336': __('identifierSchemeCodeUnspsc190501.50325336'),
	'identifierSchemeCodeUnspsc190501.50325337': __('identifierSchemeCodeUnspsc190501.50325337'),
	'identifierSchemeCodeUnspsc190501.50325338': __('identifierSchemeCodeUnspsc190501.50325338'),
	'identifierSchemeCodeUnspsc190501.50325339': __('identifierSchemeCodeUnspsc190501.50325339'),
	'identifierSchemeCodeUnspsc190501.50325340': __('identifierSchemeCodeUnspsc190501.50325340'),
	'identifierSchemeCodeUnspsc190501.50325341': __('identifierSchemeCodeUnspsc190501.50325341'),
	'identifierSchemeCodeUnspsc190501.50325342': __('identifierSchemeCodeUnspsc190501.50325342'),
	'identifierSchemeCodeUnspsc190501.50325343': __('identifierSchemeCodeUnspsc190501.50325343'),
	'identifierSchemeCodeUnspsc190501.50325344': __('identifierSchemeCodeUnspsc190501.50325344'),
	'identifierSchemeCodeUnspsc190501.50325345': __('identifierSchemeCodeUnspsc190501.50325345'),
	'identifierSchemeCodeUnspsc190501.50325346': __('identifierSchemeCodeUnspsc190501.50325346'),
	'identifierSchemeCodeUnspsc190501.50325347': __('identifierSchemeCodeUnspsc190501.50325347'),
	'identifierSchemeCodeUnspsc190501.50325348': __('identifierSchemeCodeUnspsc190501.50325348'),
	'identifierSchemeCodeUnspsc190501.50325349': __('identifierSchemeCodeUnspsc190501.50325349'),
	'identifierSchemeCodeUnspsc190501.50325350': __('identifierSchemeCodeUnspsc190501.50325350'),
	'identifierSchemeCodeUnspsc190501.50325351': __('identifierSchemeCodeUnspsc190501.50325351'),
	'identifierSchemeCodeUnspsc190501.50325352': __('identifierSchemeCodeUnspsc190501.50325352'),
	'identifierSchemeCodeUnspsc190501.50325353': __('identifierSchemeCodeUnspsc190501.50325353'),
	'identifierSchemeCodeUnspsc190501.50325354': __('identifierSchemeCodeUnspsc190501.50325354'),
	'identifierSchemeCodeUnspsc190501.50325355': __('identifierSchemeCodeUnspsc190501.50325355'),
	'identifierSchemeCodeUnspsc190501.50325356': __('identifierSchemeCodeUnspsc190501.50325356'),
	'identifierSchemeCodeUnspsc190501.50325357': __('identifierSchemeCodeUnspsc190501.50325357'),
	'identifierSchemeCodeUnspsc190501.50325358': __('identifierSchemeCodeUnspsc190501.50325358'),
	'identifierSchemeCodeUnspsc190501.50325359': __('identifierSchemeCodeUnspsc190501.50325359'),
	'identifierSchemeCodeUnspsc190501.50325360': __('identifierSchemeCodeUnspsc190501.50325360'),
	'identifierSchemeCodeUnspsc190501.50325361': __('identifierSchemeCodeUnspsc190501.50325361'),
	'identifierSchemeCodeUnspsc190501.50325362': __('identifierSchemeCodeUnspsc190501.50325362'),
	'identifierSchemeCodeUnspsc190501.50325363': __('identifierSchemeCodeUnspsc190501.50325363'),
	'identifierSchemeCodeUnspsc190501.50325364': __('identifierSchemeCodeUnspsc190501.50325364'),
	'identifierSchemeCodeUnspsc190501.50325365': __('identifierSchemeCodeUnspsc190501.50325365'),
	'identifierSchemeCodeUnspsc190501.50325366': __('identifierSchemeCodeUnspsc190501.50325366'),
	'identifierSchemeCodeUnspsc190501.50325367': __('identifierSchemeCodeUnspsc190501.50325367'),
	'identifierSchemeCodeUnspsc190501.50325368': __('identifierSchemeCodeUnspsc190501.50325368'),
	'identifierSchemeCodeUnspsc190501.50325369': __('identifierSchemeCodeUnspsc190501.50325369'),
	'identifierSchemeCodeUnspsc190501.50325370': __('identifierSchemeCodeUnspsc190501.50325370'),
	'identifierSchemeCodeUnspsc190501.50325371': __('identifierSchemeCodeUnspsc190501.50325371'),
	'identifierSchemeCodeUnspsc190501.50325400': __('identifierSchemeCodeUnspsc190501.50325400'),
	'identifierSchemeCodeUnspsc190501.50325401': __('identifierSchemeCodeUnspsc190501.50325401'),
	'identifierSchemeCodeUnspsc190501.50325402': __('identifierSchemeCodeUnspsc190501.50325402'),
	'identifierSchemeCodeUnspsc190501.50325403': __('identifierSchemeCodeUnspsc190501.50325403'),
	'identifierSchemeCodeUnspsc190501.50325404': __('identifierSchemeCodeUnspsc190501.50325404'),
	'identifierSchemeCodeUnspsc190501.50325405': __('identifierSchemeCodeUnspsc190501.50325405'),
	'identifierSchemeCodeUnspsc190501.50325406': __('identifierSchemeCodeUnspsc190501.50325406'),
	'identifierSchemeCodeUnspsc190501.50325407': __('identifierSchemeCodeUnspsc190501.50325407'),
	'identifierSchemeCodeUnspsc190501.50325408': __('identifierSchemeCodeUnspsc190501.50325408'),
	'identifierSchemeCodeUnspsc190501.50325409': __('identifierSchemeCodeUnspsc190501.50325409'),
	'identifierSchemeCodeUnspsc190501.50325410': __('identifierSchemeCodeUnspsc190501.50325410'),
	'identifierSchemeCodeUnspsc190501.50325411': __('identifierSchemeCodeUnspsc190501.50325411'),
	'identifierSchemeCodeUnspsc190501.50325412': __('identifierSchemeCodeUnspsc190501.50325412'),
	'identifierSchemeCodeUnspsc190501.50325413': __('identifierSchemeCodeUnspsc190501.50325413'),
	'identifierSchemeCodeUnspsc190501.50325414': __('identifierSchemeCodeUnspsc190501.50325414'),
	'identifierSchemeCodeUnspsc190501.50325415': __('identifierSchemeCodeUnspsc190501.50325415'),
	'identifierSchemeCodeUnspsc190501.50325416': __('identifierSchemeCodeUnspsc190501.50325416'),
	'identifierSchemeCodeUnspsc190501.50325417': __('identifierSchemeCodeUnspsc190501.50325417'),
	'identifierSchemeCodeUnspsc190501.50325418': __('identifierSchemeCodeUnspsc190501.50325418'),
	'identifierSchemeCodeUnspsc190501.50325419': __('identifierSchemeCodeUnspsc190501.50325419'),
	'identifierSchemeCodeUnspsc190501.50325420': __('identifierSchemeCodeUnspsc190501.50325420'),
	'identifierSchemeCodeUnspsc190501.50325421': __('identifierSchemeCodeUnspsc190501.50325421'),
	'identifierSchemeCodeUnspsc190501.50325422': __('identifierSchemeCodeUnspsc190501.50325422'),
	'identifierSchemeCodeUnspsc190501.50325423': __('identifierSchemeCodeUnspsc190501.50325423'),
	'identifierSchemeCodeUnspsc190501.50325424': __('identifierSchemeCodeUnspsc190501.50325424'),
	'identifierSchemeCodeUnspsc190501.50325425': __('identifierSchemeCodeUnspsc190501.50325425'),
	'identifierSchemeCodeUnspsc190501.50325426': __('identifierSchemeCodeUnspsc190501.50325426'),
	'identifierSchemeCodeUnspsc190501.50325427': __('identifierSchemeCodeUnspsc190501.50325427'),
	'identifierSchemeCodeUnspsc190501.50325428': __('identifierSchemeCodeUnspsc190501.50325428'),
	'identifierSchemeCodeUnspsc190501.50325429': __('identifierSchemeCodeUnspsc190501.50325429'),
	'identifierSchemeCodeUnspsc190501.50325430': __('identifierSchemeCodeUnspsc190501.50325430'),
	'identifierSchemeCodeUnspsc190501.50325431': __('identifierSchemeCodeUnspsc190501.50325431'),
	'identifierSchemeCodeUnspsc190501.50325432': __('identifierSchemeCodeUnspsc190501.50325432'),
	'identifierSchemeCodeUnspsc190501.50325433': __('identifierSchemeCodeUnspsc190501.50325433'),
	'identifierSchemeCodeUnspsc190501.50325434': __('identifierSchemeCodeUnspsc190501.50325434'),
	'identifierSchemeCodeUnspsc190501.50325435': __('identifierSchemeCodeUnspsc190501.50325435'),
	'identifierSchemeCodeUnspsc190501.50325436': __('identifierSchemeCodeUnspsc190501.50325436'),
	'identifierSchemeCodeUnspsc190501.50325437': __('identifierSchemeCodeUnspsc190501.50325437'),
	'identifierSchemeCodeUnspsc190501.50325438': __('identifierSchemeCodeUnspsc190501.50325438'),
	'identifierSchemeCodeUnspsc190501.50325439': __('identifierSchemeCodeUnspsc190501.50325439'),
	'identifierSchemeCodeUnspsc190501.50325440': __('identifierSchemeCodeUnspsc190501.50325440'),
	'identifierSchemeCodeUnspsc190501.50325441': __('identifierSchemeCodeUnspsc190501.50325441'),
	'identifierSchemeCodeUnspsc190501.50325442': __('identifierSchemeCodeUnspsc190501.50325442'),
	'identifierSchemeCodeUnspsc190501.50325443': __('identifierSchemeCodeUnspsc190501.50325443'),
	'identifierSchemeCodeUnspsc190501.50325444': __('identifierSchemeCodeUnspsc190501.50325444'),
	'identifierSchemeCodeUnspsc190501.50325445': __('identifierSchemeCodeUnspsc190501.50325445'),
	'identifierSchemeCodeUnspsc190501.50325446': __('identifierSchemeCodeUnspsc190501.50325446'),
	'identifierSchemeCodeUnspsc190501.50325447': __('identifierSchemeCodeUnspsc190501.50325447'),
	'identifierSchemeCodeUnspsc190501.50325448': __('identifierSchemeCodeUnspsc190501.50325448'),
	'identifierSchemeCodeUnspsc190501.50325449': __('identifierSchemeCodeUnspsc190501.50325449'),
	'identifierSchemeCodeUnspsc190501.50325450': __('identifierSchemeCodeUnspsc190501.50325450'),
	'identifierSchemeCodeUnspsc190501.50325451': __('identifierSchemeCodeUnspsc190501.50325451'),
	'identifierSchemeCodeUnspsc190501.50325452': __('identifierSchemeCodeUnspsc190501.50325452'),
	'identifierSchemeCodeUnspsc190501.50325453': __('identifierSchemeCodeUnspsc190501.50325453'),
	'identifierSchemeCodeUnspsc190501.50325454': __('identifierSchemeCodeUnspsc190501.50325454'),
	'identifierSchemeCodeUnspsc190501.50325455': __('identifierSchemeCodeUnspsc190501.50325455'),
	'identifierSchemeCodeUnspsc190501.50325456': __('identifierSchemeCodeUnspsc190501.50325456'),
	'identifierSchemeCodeUnspsc190501.50325457': __('identifierSchemeCodeUnspsc190501.50325457'),
	'identifierSchemeCodeUnspsc190501.50325458': __('identifierSchemeCodeUnspsc190501.50325458'),
	'identifierSchemeCodeUnspsc190501.50325459': __('identifierSchemeCodeUnspsc190501.50325459'),
	'identifierSchemeCodeUnspsc190501.50325460': __('identifierSchemeCodeUnspsc190501.50325460'),
	'identifierSchemeCodeUnspsc190501.50325500': __('identifierSchemeCodeUnspsc190501.50325500'),
	'identifierSchemeCodeUnspsc190501.50325501': __('identifierSchemeCodeUnspsc190501.50325501'),
	'identifierSchemeCodeUnspsc190501.50325502': __('identifierSchemeCodeUnspsc190501.50325502'),
	'identifierSchemeCodeUnspsc190501.50325503': __('identifierSchemeCodeUnspsc190501.50325503'),
	'identifierSchemeCodeUnspsc190501.50325504': __('identifierSchemeCodeUnspsc190501.50325504'),
	'identifierSchemeCodeUnspsc190501.50325505': __('identifierSchemeCodeUnspsc190501.50325505'),
	'identifierSchemeCodeUnspsc190501.50325506': __('identifierSchemeCodeUnspsc190501.50325506'),
	'identifierSchemeCodeUnspsc190501.50325507': __('identifierSchemeCodeUnspsc190501.50325507'),
	'identifierSchemeCodeUnspsc190501.50325508': __('identifierSchemeCodeUnspsc190501.50325508'),
	'identifierSchemeCodeUnspsc190501.50325509': __('identifierSchemeCodeUnspsc190501.50325509'),
	'identifierSchemeCodeUnspsc190501.50325510': __('identifierSchemeCodeUnspsc190501.50325510'),
	'identifierSchemeCodeUnspsc190501.50325511': __('identifierSchemeCodeUnspsc190501.50325511'),
	'identifierSchemeCodeUnspsc190501.50325512': __('identifierSchemeCodeUnspsc190501.50325512'),
	'identifierSchemeCodeUnspsc190501.50325600': __('identifierSchemeCodeUnspsc190501.50325600'),
	'identifierSchemeCodeUnspsc190501.50325601': __('identifierSchemeCodeUnspsc190501.50325601'),
	'identifierSchemeCodeUnspsc190501.50325602': __('identifierSchemeCodeUnspsc190501.50325602'),
	'identifierSchemeCodeUnspsc190501.50325603': __('identifierSchemeCodeUnspsc190501.50325603'),
	'identifierSchemeCodeUnspsc190501.50325604': __('identifierSchemeCodeUnspsc190501.50325604'),
	'identifierSchemeCodeUnspsc190501.50325605': __('identifierSchemeCodeUnspsc190501.50325605'),
	'identifierSchemeCodeUnspsc190501.50325606': __('identifierSchemeCodeUnspsc190501.50325606'),
	'identifierSchemeCodeUnspsc190501.50325607': __('identifierSchemeCodeUnspsc190501.50325607'),
	'identifierSchemeCodeUnspsc190501.50325608': __('identifierSchemeCodeUnspsc190501.50325608'),
	'identifierSchemeCodeUnspsc190501.50325609': __('identifierSchemeCodeUnspsc190501.50325609'),
	'identifierSchemeCodeUnspsc190501.50325610': __('identifierSchemeCodeUnspsc190501.50325610'),
	'identifierSchemeCodeUnspsc190501.50325700': __('identifierSchemeCodeUnspsc190501.50325700'),
	'identifierSchemeCodeUnspsc190501.50325701': __('identifierSchemeCodeUnspsc190501.50325701'),
	'identifierSchemeCodeUnspsc190501.50325702': __('identifierSchemeCodeUnspsc190501.50325702'),
	'identifierSchemeCodeUnspsc190501.50325703': __('identifierSchemeCodeUnspsc190501.50325703'),
	'identifierSchemeCodeUnspsc190501.50325704': __('identifierSchemeCodeUnspsc190501.50325704'),
	'identifierSchemeCodeUnspsc190501.50325705': __('identifierSchemeCodeUnspsc190501.50325705'),
	'identifierSchemeCodeUnspsc190501.50325706': __('identifierSchemeCodeUnspsc190501.50325706'),
	'identifierSchemeCodeUnspsc190501.50325707': __('identifierSchemeCodeUnspsc190501.50325707'),
	'identifierSchemeCodeUnspsc190501.50325708': __('identifierSchemeCodeUnspsc190501.50325708'),
	'identifierSchemeCodeUnspsc190501.50325709': __('identifierSchemeCodeUnspsc190501.50325709'),
	'identifierSchemeCodeUnspsc190501.50325710': __('identifierSchemeCodeUnspsc190501.50325710'),
	'identifierSchemeCodeUnspsc190501.50325711': __('identifierSchemeCodeUnspsc190501.50325711'),
	'identifierSchemeCodeUnspsc190501.50325712': __('identifierSchemeCodeUnspsc190501.50325712'),
	'identifierSchemeCodeUnspsc190501.50325713': __('identifierSchemeCodeUnspsc190501.50325713'),
	'identifierSchemeCodeUnspsc190501.50325714': __('identifierSchemeCodeUnspsc190501.50325714'),
	'identifierSchemeCodeUnspsc190501.50325715': __('identifierSchemeCodeUnspsc190501.50325715'),
	'identifierSchemeCodeUnspsc190501.50325716': __('identifierSchemeCodeUnspsc190501.50325716'),
	'identifierSchemeCodeUnspsc190501.50325717': __('identifierSchemeCodeUnspsc190501.50325717'),
	'identifierSchemeCodeUnspsc190501.50325718': __('identifierSchemeCodeUnspsc190501.50325718'),
	'identifierSchemeCodeUnspsc190501.50325719': __('identifierSchemeCodeUnspsc190501.50325719'),
	'identifierSchemeCodeUnspsc190501.50325720': __('identifierSchemeCodeUnspsc190501.50325720'),
	'identifierSchemeCodeUnspsc190501.50325721': __('identifierSchemeCodeUnspsc190501.50325721'),
	'identifierSchemeCodeUnspsc190501.50325722': __('identifierSchemeCodeUnspsc190501.50325722'),
	'identifierSchemeCodeUnspsc190501.50325723': __('identifierSchemeCodeUnspsc190501.50325723'),
	'identifierSchemeCodeUnspsc190501.50325800': __('identifierSchemeCodeUnspsc190501.50325800'),
	'identifierSchemeCodeUnspsc190501.50325801': __('identifierSchemeCodeUnspsc190501.50325801'),
	'identifierSchemeCodeUnspsc190501.50325802': __('identifierSchemeCodeUnspsc190501.50325802'),
	'identifierSchemeCodeUnspsc190501.50325803': __('identifierSchemeCodeUnspsc190501.50325803'),
	'identifierSchemeCodeUnspsc190501.50325804': __('identifierSchemeCodeUnspsc190501.50325804'),
	'identifierSchemeCodeUnspsc190501.50325805': __('identifierSchemeCodeUnspsc190501.50325805'),
	'identifierSchemeCodeUnspsc190501.50325806': __('identifierSchemeCodeUnspsc190501.50325806'),
	'identifierSchemeCodeUnspsc190501.50325807': __('identifierSchemeCodeUnspsc190501.50325807'),
	'identifierSchemeCodeUnspsc190501.50325808': __('identifierSchemeCodeUnspsc190501.50325808'),
	'identifierSchemeCodeUnspsc190501.50325809': __('identifierSchemeCodeUnspsc190501.50325809'),
	'identifierSchemeCodeUnspsc190501.50325810': __('identifierSchemeCodeUnspsc190501.50325810'),
	'identifierSchemeCodeUnspsc190501.50325811': __('identifierSchemeCodeUnspsc190501.50325811'),
	'identifierSchemeCodeUnspsc190501.50325812': __('identifierSchemeCodeUnspsc190501.50325812'),
	'identifierSchemeCodeUnspsc190501.50325813': __('identifierSchemeCodeUnspsc190501.50325813'),
	'identifierSchemeCodeUnspsc190501.50325814': __('identifierSchemeCodeUnspsc190501.50325814'),
	'identifierSchemeCodeUnspsc190501.50325815': __('identifierSchemeCodeUnspsc190501.50325815'),
	'identifierSchemeCodeUnspsc190501.50325816': __('identifierSchemeCodeUnspsc190501.50325816'),
	'identifierSchemeCodeUnspsc190501.50325817': __('identifierSchemeCodeUnspsc190501.50325817'),
	'identifierSchemeCodeUnspsc190501.50325818': __('identifierSchemeCodeUnspsc190501.50325818'),
	'identifierSchemeCodeUnspsc190501.50325819': __('identifierSchemeCodeUnspsc190501.50325819'),
	'identifierSchemeCodeUnspsc190501.50325820': __('identifierSchemeCodeUnspsc190501.50325820'),
	'identifierSchemeCodeUnspsc190501.50325821': __('identifierSchemeCodeUnspsc190501.50325821'),
	'identifierSchemeCodeUnspsc190501.50325822': __('identifierSchemeCodeUnspsc190501.50325822'),
	'identifierSchemeCodeUnspsc190501.50325823': __('identifierSchemeCodeUnspsc190501.50325823'),
	'identifierSchemeCodeUnspsc190501.50325824': __('identifierSchemeCodeUnspsc190501.50325824'),
	'identifierSchemeCodeUnspsc190501.50325825': __('identifierSchemeCodeUnspsc190501.50325825'),
	'identifierSchemeCodeUnspsc190501.50325826': __('identifierSchemeCodeUnspsc190501.50325826'),
	'identifierSchemeCodeUnspsc190501.50325827': __('identifierSchemeCodeUnspsc190501.50325827'),
	'identifierSchemeCodeUnspsc190501.50325828': __('identifierSchemeCodeUnspsc190501.50325828'),
	'identifierSchemeCodeUnspsc190501.50325829': __('identifierSchemeCodeUnspsc190501.50325829'),
	'identifierSchemeCodeUnspsc190501.50325830': __('identifierSchemeCodeUnspsc190501.50325830'),
	'identifierSchemeCodeUnspsc190501.50325831': __('identifierSchemeCodeUnspsc190501.50325831'),
	'identifierSchemeCodeUnspsc190501.50325832': __('identifierSchemeCodeUnspsc190501.50325832'),
	'identifierSchemeCodeUnspsc190501.50325833': __('identifierSchemeCodeUnspsc190501.50325833'),
	'identifierSchemeCodeUnspsc190501.50325834': __('identifierSchemeCodeUnspsc190501.50325834'),
	'identifierSchemeCodeUnspsc190501.50325835': __('identifierSchemeCodeUnspsc190501.50325835'),
	'identifierSchemeCodeUnspsc190501.50325836': __('identifierSchemeCodeUnspsc190501.50325836'),
	'identifierSchemeCodeUnspsc190501.50325837': __('identifierSchemeCodeUnspsc190501.50325837'),
	'identifierSchemeCodeUnspsc190501.50325838': __('identifierSchemeCodeUnspsc190501.50325838'),
	'identifierSchemeCodeUnspsc190501.50325839': __('identifierSchemeCodeUnspsc190501.50325839'),
	'identifierSchemeCodeUnspsc190501.50325840': __('identifierSchemeCodeUnspsc190501.50325840'),
	'identifierSchemeCodeUnspsc190501.50325841': __('identifierSchemeCodeUnspsc190501.50325841'),
	'identifierSchemeCodeUnspsc190501.50325842': __('identifierSchemeCodeUnspsc190501.50325842'),
	'identifierSchemeCodeUnspsc190501.50325843': __('identifierSchemeCodeUnspsc190501.50325843'),
	'identifierSchemeCodeUnspsc190501.50325844': __('identifierSchemeCodeUnspsc190501.50325844'),
	'identifierSchemeCodeUnspsc190501.50325845': __('identifierSchemeCodeUnspsc190501.50325845'),
	'identifierSchemeCodeUnspsc190501.50325846': __('identifierSchemeCodeUnspsc190501.50325846'),
	'identifierSchemeCodeUnspsc190501.50325847': __('identifierSchemeCodeUnspsc190501.50325847'),
	'identifierSchemeCodeUnspsc190501.50325848': __('identifierSchemeCodeUnspsc190501.50325848'),
	'identifierSchemeCodeUnspsc190501.50325849': __('identifierSchemeCodeUnspsc190501.50325849'),
	'identifierSchemeCodeUnspsc190501.50325850': __('identifierSchemeCodeUnspsc190501.50325850'),
	'identifierSchemeCodeUnspsc190501.50325851': __('identifierSchemeCodeUnspsc190501.50325851'),
	'identifierSchemeCodeUnspsc190501.50325852': __('identifierSchemeCodeUnspsc190501.50325852'),
	'identifierSchemeCodeUnspsc190501.50325853': __('identifierSchemeCodeUnspsc190501.50325853'),
	'identifierSchemeCodeUnspsc190501.50325854': __('identifierSchemeCodeUnspsc190501.50325854'),
	'identifierSchemeCodeUnspsc190501.50325855': __('identifierSchemeCodeUnspsc190501.50325855'),
	'identifierSchemeCodeUnspsc190501.50325856': __('identifierSchemeCodeUnspsc190501.50325856'),
	'identifierSchemeCodeUnspsc190501.50325857': __('identifierSchemeCodeUnspsc190501.50325857'),
	'identifierSchemeCodeUnspsc190501.50325858': __('identifierSchemeCodeUnspsc190501.50325858'),
	'identifierSchemeCodeUnspsc190501.50325859': __('identifierSchemeCodeUnspsc190501.50325859'),
	'identifierSchemeCodeUnspsc190501.50325860': __('identifierSchemeCodeUnspsc190501.50325860'),
	'identifierSchemeCodeUnspsc190501.50325861': __('identifierSchemeCodeUnspsc190501.50325861'),
	'identifierSchemeCodeUnspsc190501.50325862': __('identifierSchemeCodeUnspsc190501.50325862'),
	'identifierSchemeCodeUnspsc190501.50325863': __('identifierSchemeCodeUnspsc190501.50325863'),
	'identifierSchemeCodeUnspsc190501.50325864': __('identifierSchemeCodeUnspsc190501.50325864'),
	'identifierSchemeCodeUnspsc190501.50325865': __('identifierSchemeCodeUnspsc190501.50325865'),
	'identifierSchemeCodeUnspsc190501.50325866': __('identifierSchemeCodeUnspsc190501.50325866'),
	'identifierSchemeCodeUnspsc190501.50325867': __('identifierSchemeCodeUnspsc190501.50325867'),
	'identifierSchemeCodeUnspsc190501.50325868': __('identifierSchemeCodeUnspsc190501.50325868'),
	'identifierSchemeCodeUnspsc190501.50325869': __('identifierSchemeCodeUnspsc190501.50325869'),
	'identifierSchemeCodeUnspsc190501.50325870': __('identifierSchemeCodeUnspsc190501.50325870'),
	'identifierSchemeCodeUnspsc190501.50325871': __('identifierSchemeCodeUnspsc190501.50325871'),
	'identifierSchemeCodeUnspsc190501.50325872': __('identifierSchemeCodeUnspsc190501.50325872'),
	'identifierSchemeCodeUnspsc190501.50325873': __('identifierSchemeCodeUnspsc190501.50325873'),
	'identifierSchemeCodeUnspsc190501.50325874': __('identifierSchemeCodeUnspsc190501.50325874'),
	'identifierSchemeCodeUnspsc190501.50325875': __('identifierSchemeCodeUnspsc190501.50325875'),
	'identifierSchemeCodeUnspsc190501.50325876': __('identifierSchemeCodeUnspsc190501.50325876'),
	'identifierSchemeCodeUnspsc190501.50325877': __('identifierSchemeCodeUnspsc190501.50325877'),
	'identifierSchemeCodeUnspsc190501.50325900': __('identifierSchemeCodeUnspsc190501.50325900'),
	'identifierSchemeCodeUnspsc190501.50325901': __('identifierSchemeCodeUnspsc190501.50325901'),
	'identifierSchemeCodeUnspsc190501.50325902': __('identifierSchemeCodeUnspsc190501.50325902'),
	'identifierSchemeCodeUnspsc190501.50325903': __('identifierSchemeCodeUnspsc190501.50325903'),
	'identifierSchemeCodeUnspsc190501.50325904': __('identifierSchemeCodeUnspsc190501.50325904'),
	'identifierSchemeCodeUnspsc190501.50325905': __('identifierSchemeCodeUnspsc190501.50325905'),
	'identifierSchemeCodeUnspsc190501.50325906': __('identifierSchemeCodeUnspsc190501.50325906'),
	'identifierSchemeCodeUnspsc190501.50325907': __('identifierSchemeCodeUnspsc190501.50325907'),
	'identifierSchemeCodeUnspsc190501.50325908': __('identifierSchemeCodeUnspsc190501.50325908'),
	'identifierSchemeCodeUnspsc190501.50326000': __('identifierSchemeCodeUnspsc190501.50326000'),
	'identifierSchemeCodeUnspsc190501.50326001': __('identifierSchemeCodeUnspsc190501.50326001'),
	'identifierSchemeCodeUnspsc190501.50326002': __('identifierSchemeCodeUnspsc190501.50326002'),
	'identifierSchemeCodeUnspsc190501.50326003': __('identifierSchemeCodeUnspsc190501.50326003'),
	'identifierSchemeCodeUnspsc190501.50326004': __('identifierSchemeCodeUnspsc190501.50326004'),
	'identifierSchemeCodeUnspsc190501.50326005': __('identifierSchemeCodeUnspsc190501.50326005'),
	'identifierSchemeCodeUnspsc190501.50326006': __('identifierSchemeCodeUnspsc190501.50326006'),
	'identifierSchemeCodeUnspsc190501.50326007': __('identifierSchemeCodeUnspsc190501.50326007'),
	'identifierSchemeCodeUnspsc190501.50326008': __('identifierSchemeCodeUnspsc190501.50326008'),
	'identifierSchemeCodeUnspsc190501.50326100': __('identifierSchemeCodeUnspsc190501.50326100'),
	'identifierSchemeCodeUnspsc190501.50326101': __('identifierSchemeCodeUnspsc190501.50326101'),
	'identifierSchemeCodeUnspsc190501.50326102': __('identifierSchemeCodeUnspsc190501.50326102'),
	'identifierSchemeCodeUnspsc190501.50326103': __('identifierSchemeCodeUnspsc190501.50326103'),
	'identifierSchemeCodeUnspsc190501.50326200': __('identifierSchemeCodeUnspsc190501.50326200'),
	'identifierSchemeCodeUnspsc190501.50326201': __('identifierSchemeCodeUnspsc190501.50326201'),
	'identifierSchemeCodeUnspsc190501.50326202': __('identifierSchemeCodeUnspsc190501.50326202'),
	'identifierSchemeCodeUnspsc190501.50326203': __('identifierSchemeCodeUnspsc190501.50326203'),
	'identifierSchemeCodeUnspsc190501.50326204': __('identifierSchemeCodeUnspsc190501.50326204'),
	'identifierSchemeCodeUnspsc190501.50326205': __('identifierSchemeCodeUnspsc190501.50326205'),
	'identifierSchemeCodeUnspsc190501.50326206': __('identifierSchemeCodeUnspsc190501.50326206'),
	'identifierSchemeCodeUnspsc190501.50326207': __('identifierSchemeCodeUnspsc190501.50326207'),
	'identifierSchemeCodeUnspsc190501.50326208': __('identifierSchemeCodeUnspsc190501.50326208'),
	'identifierSchemeCodeUnspsc190501.50326209': __('identifierSchemeCodeUnspsc190501.50326209'),
	'identifierSchemeCodeUnspsc190501.50326210': __('identifierSchemeCodeUnspsc190501.50326210'),
	'identifierSchemeCodeUnspsc190501.50326211': __('identifierSchemeCodeUnspsc190501.50326211'),
	'identifierSchemeCodeUnspsc190501.50326212': __('identifierSchemeCodeUnspsc190501.50326212'),
	'identifierSchemeCodeUnspsc190501.50326213': __('identifierSchemeCodeUnspsc190501.50326213'),
	'identifierSchemeCodeUnspsc190501.50326214': __('identifierSchemeCodeUnspsc190501.50326214'),
	'identifierSchemeCodeUnspsc190501.50326215': __('identifierSchemeCodeUnspsc190501.50326215'),
	'identifierSchemeCodeUnspsc190501.50326216': __('identifierSchemeCodeUnspsc190501.50326216'),
	'identifierSchemeCodeUnspsc190501.50326217': __('identifierSchemeCodeUnspsc190501.50326217'),
	'identifierSchemeCodeUnspsc190501.50326218': __('identifierSchemeCodeUnspsc190501.50326218'),
	'identifierSchemeCodeUnspsc190501.50326219': __('identifierSchemeCodeUnspsc190501.50326219'),
	'identifierSchemeCodeUnspsc190501.50326220': __('identifierSchemeCodeUnspsc190501.50326220'),
	'identifierSchemeCodeUnspsc190501.50326221': __('identifierSchemeCodeUnspsc190501.50326221'),
	'identifierSchemeCodeUnspsc190501.50326222': __('identifierSchemeCodeUnspsc190501.50326222'),
	'identifierSchemeCodeUnspsc190501.50326223': __('identifierSchemeCodeUnspsc190501.50326223'),
	'identifierSchemeCodeUnspsc190501.50326224': __('identifierSchemeCodeUnspsc190501.50326224'),
	'identifierSchemeCodeUnspsc190501.50326225': __('identifierSchemeCodeUnspsc190501.50326225'),
	'identifierSchemeCodeUnspsc190501.50326226': __('identifierSchemeCodeUnspsc190501.50326226'),
	'identifierSchemeCodeUnspsc190501.50326227': __('identifierSchemeCodeUnspsc190501.50326227'),
	'identifierSchemeCodeUnspsc190501.50326228': __('identifierSchemeCodeUnspsc190501.50326228'),
	'identifierSchemeCodeUnspsc190501.50326229': __('identifierSchemeCodeUnspsc190501.50326229'),
	'identifierSchemeCodeUnspsc190501.50326300': __('identifierSchemeCodeUnspsc190501.50326300'),
	'identifierSchemeCodeUnspsc190501.50326301': __('identifierSchemeCodeUnspsc190501.50326301'),
	'identifierSchemeCodeUnspsc190501.50326302': __('identifierSchemeCodeUnspsc190501.50326302'),
	'identifierSchemeCodeUnspsc190501.50326303': __('identifierSchemeCodeUnspsc190501.50326303'),
	'identifierSchemeCodeUnspsc190501.50326304': __('identifierSchemeCodeUnspsc190501.50326304'),
	'identifierSchemeCodeUnspsc190501.50326305': __('identifierSchemeCodeUnspsc190501.50326305'),
	'identifierSchemeCodeUnspsc190501.50326306': __('identifierSchemeCodeUnspsc190501.50326306'),
	'identifierSchemeCodeUnspsc190501.50326307': __('identifierSchemeCodeUnspsc190501.50326307'),
	'identifierSchemeCodeUnspsc190501.50326308': __('identifierSchemeCodeUnspsc190501.50326308'),
	'identifierSchemeCodeUnspsc190501.50326309': __('identifierSchemeCodeUnspsc190501.50326309'),
	'identifierSchemeCodeUnspsc190501.50326310': __('identifierSchemeCodeUnspsc190501.50326310'),
	'identifierSchemeCodeUnspsc190501.50326400': __('identifierSchemeCodeUnspsc190501.50326400'),
	'identifierSchemeCodeUnspsc190501.50326401': __('identifierSchemeCodeUnspsc190501.50326401'),
	'identifierSchemeCodeUnspsc190501.50326402': __('identifierSchemeCodeUnspsc190501.50326402'),
	'identifierSchemeCodeUnspsc190501.50326403': __('identifierSchemeCodeUnspsc190501.50326403'),
	'identifierSchemeCodeUnspsc190501.50326404': __('identifierSchemeCodeUnspsc190501.50326404'),
	'identifierSchemeCodeUnspsc190501.50326500': __('identifierSchemeCodeUnspsc190501.50326500'),
	'identifierSchemeCodeUnspsc190501.50326501': __('identifierSchemeCodeUnspsc190501.50326501'),
	'identifierSchemeCodeUnspsc190501.50326502': __('identifierSchemeCodeUnspsc190501.50326502'),
	'identifierSchemeCodeUnspsc190501.50326600': __('identifierSchemeCodeUnspsc190501.50326600'),
	'identifierSchemeCodeUnspsc190501.50326601': __('identifierSchemeCodeUnspsc190501.50326601'),
	'identifierSchemeCodeUnspsc190501.50326602': __('identifierSchemeCodeUnspsc190501.50326602'),
	'identifierSchemeCodeUnspsc190501.50326603': __('identifierSchemeCodeUnspsc190501.50326603'),
	'identifierSchemeCodeUnspsc190501.50326604': __('identifierSchemeCodeUnspsc190501.50326604'),
	'identifierSchemeCodeUnspsc190501.50326700': __('identifierSchemeCodeUnspsc190501.50326700'),
	'identifierSchemeCodeUnspsc190501.50326701': __('identifierSchemeCodeUnspsc190501.50326701'),
	'identifierSchemeCodeUnspsc190501.50326702': __('identifierSchemeCodeUnspsc190501.50326702'),
	'identifierSchemeCodeUnspsc190501.50326703': __('identifierSchemeCodeUnspsc190501.50326703'),
	'identifierSchemeCodeUnspsc190501.50326800': __('identifierSchemeCodeUnspsc190501.50326800'),
	'identifierSchemeCodeUnspsc190501.50326801': __('identifierSchemeCodeUnspsc190501.50326801'),
	'identifierSchemeCodeUnspsc190501.50326802': __('identifierSchemeCodeUnspsc190501.50326802'),
	'identifierSchemeCodeUnspsc190501.50326803': __('identifierSchemeCodeUnspsc190501.50326803'),
	'identifierSchemeCodeUnspsc190501.50326900': __('identifierSchemeCodeUnspsc190501.50326900'),
	'identifierSchemeCodeUnspsc190501.50326901': __('identifierSchemeCodeUnspsc190501.50326901'),
	'identifierSchemeCodeUnspsc190501.50326902': __('identifierSchemeCodeUnspsc190501.50326902'),
	'identifierSchemeCodeUnspsc190501.50326903': __('identifierSchemeCodeUnspsc190501.50326903'),
	'identifierSchemeCodeUnspsc190501.50326904': __('identifierSchemeCodeUnspsc190501.50326904'),
	'identifierSchemeCodeUnspsc190501.50326905': __('identifierSchemeCodeUnspsc190501.50326905'),
	'identifierSchemeCodeUnspsc190501.50326906': __('identifierSchemeCodeUnspsc190501.50326906'),
	'identifierSchemeCodeUnspsc190501.50327000': __('identifierSchemeCodeUnspsc190501.50327000'),
	'identifierSchemeCodeUnspsc190501.50327001': __('identifierSchemeCodeUnspsc190501.50327001'),
	'identifierSchemeCodeUnspsc190501.50327002': __('identifierSchemeCodeUnspsc190501.50327002'),
	'identifierSchemeCodeUnspsc190501.50327003': __('identifierSchemeCodeUnspsc190501.50327003'),
	'identifierSchemeCodeUnspsc190501.50327004': __('identifierSchemeCodeUnspsc190501.50327004'),
	'identifierSchemeCodeUnspsc190501.50327005': __('identifierSchemeCodeUnspsc190501.50327005'),
	'identifierSchemeCodeUnspsc190501.50327006': __('identifierSchemeCodeUnspsc190501.50327006'),
	'identifierSchemeCodeUnspsc190501.50327007': __('identifierSchemeCodeUnspsc190501.50327007'),
	'identifierSchemeCodeUnspsc190501.50327008': __('identifierSchemeCodeUnspsc190501.50327008'),
	'identifierSchemeCodeUnspsc190501.50327009': __('identifierSchemeCodeUnspsc190501.50327009'),
	'identifierSchemeCodeUnspsc190501.50327010': __('identifierSchemeCodeUnspsc190501.50327010'),
	'identifierSchemeCodeUnspsc190501.50327011': __('identifierSchemeCodeUnspsc190501.50327011'),
	'identifierSchemeCodeUnspsc190501.50327012': __('identifierSchemeCodeUnspsc190501.50327012'),
	'identifierSchemeCodeUnspsc190501.50327013': __('identifierSchemeCodeUnspsc190501.50327013'),
	'identifierSchemeCodeUnspsc190501.50327014': __('identifierSchemeCodeUnspsc190501.50327014'),
	'identifierSchemeCodeUnspsc190501.50327015': __('identifierSchemeCodeUnspsc190501.50327015'),
	'identifierSchemeCodeUnspsc190501.50327016': __('identifierSchemeCodeUnspsc190501.50327016'),
	'identifierSchemeCodeUnspsc190501.50327017': __('identifierSchemeCodeUnspsc190501.50327017'),
	'identifierSchemeCodeUnspsc190501.50327018': __('identifierSchemeCodeUnspsc190501.50327018'),
	'identifierSchemeCodeUnspsc190501.50327019': __('identifierSchemeCodeUnspsc190501.50327019'),
	'identifierSchemeCodeUnspsc190501.50327020': __('identifierSchemeCodeUnspsc190501.50327020'),
	'identifierSchemeCodeUnspsc190501.50327021': __('identifierSchemeCodeUnspsc190501.50327021'),
	'identifierSchemeCodeUnspsc190501.50327022': __('identifierSchemeCodeUnspsc190501.50327022'),
	'identifierSchemeCodeUnspsc190501.50327023': __('identifierSchemeCodeUnspsc190501.50327023'),
	'identifierSchemeCodeUnspsc190501.50327024': __('identifierSchemeCodeUnspsc190501.50327024'),
	'identifierSchemeCodeUnspsc190501.50327025': __('identifierSchemeCodeUnspsc190501.50327025'),
	'identifierSchemeCodeUnspsc190501.50327026': __('identifierSchemeCodeUnspsc190501.50327026'),
	'identifierSchemeCodeUnspsc190501.50327027': __('identifierSchemeCodeUnspsc190501.50327027'),
	'identifierSchemeCodeUnspsc190501.50327028': __('identifierSchemeCodeUnspsc190501.50327028'),
	'identifierSchemeCodeUnspsc190501.50327029': __('identifierSchemeCodeUnspsc190501.50327029'),
	'identifierSchemeCodeUnspsc190501.50327030': __('identifierSchemeCodeUnspsc190501.50327030'),
	'identifierSchemeCodeUnspsc190501.50327031': __('identifierSchemeCodeUnspsc190501.50327031'),
	'identifierSchemeCodeUnspsc190501.50327032': __('identifierSchemeCodeUnspsc190501.50327032'),
	'identifierSchemeCodeUnspsc190501.50327033': __('identifierSchemeCodeUnspsc190501.50327033'),
	'identifierSchemeCodeUnspsc190501.50327034': __('identifierSchemeCodeUnspsc190501.50327034'),
	'identifierSchemeCodeUnspsc190501.50327035': __('identifierSchemeCodeUnspsc190501.50327035'),
	'identifierSchemeCodeUnspsc190501.50327036': __('identifierSchemeCodeUnspsc190501.50327036'),
	'identifierSchemeCodeUnspsc190501.50327037': __('identifierSchemeCodeUnspsc190501.50327037'),
	'identifierSchemeCodeUnspsc190501.50327100': __('identifierSchemeCodeUnspsc190501.50327100'),
	'identifierSchemeCodeUnspsc190501.50327101': __('identifierSchemeCodeUnspsc190501.50327101'),
	'identifierSchemeCodeUnspsc190501.50327102': __('identifierSchemeCodeUnspsc190501.50327102'),
	'identifierSchemeCodeUnspsc190501.50327103': __('identifierSchemeCodeUnspsc190501.50327103'),
	'identifierSchemeCodeUnspsc190501.50327104': __('identifierSchemeCodeUnspsc190501.50327104'),
	'identifierSchemeCodeUnspsc190501.50327200': __('identifierSchemeCodeUnspsc190501.50327200'),
	'identifierSchemeCodeUnspsc190501.50327201': __('identifierSchemeCodeUnspsc190501.50327201'),
	'identifierSchemeCodeUnspsc190501.50327202': __('identifierSchemeCodeUnspsc190501.50327202'),
	'identifierSchemeCodeUnspsc190501.50327203': __('identifierSchemeCodeUnspsc190501.50327203'),
	'identifierSchemeCodeUnspsc190501.50327204': __('identifierSchemeCodeUnspsc190501.50327204'),
	'identifierSchemeCodeUnspsc190501.50327205': __('identifierSchemeCodeUnspsc190501.50327205'),
	'identifierSchemeCodeUnspsc190501.50327206': __('identifierSchemeCodeUnspsc190501.50327206'),
	'identifierSchemeCodeUnspsc190501.50327207': __('identifierSchemeCodeUnspsc190501.50327207'),
	'identifierSchemeCodeUnspsc190501.50327208': __('identifierSchemeCodeUnspsc190501.50327208'),
	'identifierSchemeCodeUnspsc190501.50327209': __('identifierSchemeCodeUnspsc190501.50327209'),
	'identifierSchemeCodeUnspsc190501.50327210': __('identifierSchemeCodeUnspsc190501.50327210'),
	'identifierSchemeCodeUnspsc190501.50327211': __('identifierSchemeCodeUnspsc190501.50327211'),
	'identifierSchemeCodeUnspsc190501.50327212': __('identifierSchemeCodeUnspsc190501.50327212'),
	'identifierSchemeCodeUnspsc190501.50327213': __('identifierSchemeCodeUnspsc190501.50327213'),
	'identifierSchemeCodeUnspsc190501.50327214': __('identifierSchemeCodeUnspsc190501.50327214'),
	'identifierSchemeCodeUnspsc190501.50327215': __('identifierSchemeCodeUnspsc190501.50327215'),
	'identifierSchemeCodeUnspsc190501.50327216': __('identifierSchemeCodeUnspsc190501.50327216'),
	'identifierSchemeCodeUnspsc190501.50327217': __('identifierSchemeCodeUnspsc190501.50327217'),
	'identifierSchemeCodeUnspsc190501.50327218': __('identifierSchemeCodeUnspsc190501.50327218'),
	'identifierSchemeCodeUnspsc190501.50327219': __('identifierSchemeCodeUnspsc190501.50327219'),
	'identifierSchemeCodeUnspsc190501.50327220': __('identifierSchemeCodeUnspsc190501.50327220'),
	'identifierSchemeCodeUnspsc190501.50327221': __('identifierSchemeCodeUnspsc190501.50327221'),
	'identifierSchemeCodeUnspsc190501.50327222': __('identifierSchemeCodeUnspsc190501.50327222'),
	'identifierSchemeCodeUnspsc190501.50327223': __('identifierSchemeCodeUnspsc190501.50327223'),
	'identifierSchemeCodeUnspsc190501.50327224': __('identifierSchemeCodeUnspsc190501.50327224'),
	'identifierSchemeCodeUnspsc190501.50327225': __('identifierSchemeCodeUnspsc190501.50327225'),
	'identifierSchemeCodeUnspsc190501.50327226': __('identifierSchemeCodeUnspsc190501.50327226'),
	'identifierSchemeCodeUnspsc190501.50327227': __('identifierSchemeCodeUnspsc190501.50327227'),
	'identifierSchemeCodeUnspsc190501.50327228': __('identifierSchemeCodeUnspsc190501.50327228'),
	'identifierSchemeCodeUnspsc190501.50327229': __('identifierSchemeCodeUnspsc190501.50327229'),
	'identifierSchemeCodeUnspsc190501.50327230': __('identifierSchemeCodeUnspsc190501.50327230'),
	'identifierSchemeCodeUnspsc190501.50330000': __('identifierSchemeCodeUnspsc190501.50330000'),
	'identifierSchemeCodeUnspsc190501.50331500': __('identifierSchemeCodeUnspsc190501.50331500'),
	'identifierSchemeCodeUnspsc190501.50331501': __('identifierSchemeCodeUnspsc190501.50331501'),
	'identifierSchemeCodeUnspsc190501.50331502': __('identifierSchemeCodeUnspsc190501.50331502'),
	'identifierSchemeCodeUnspsc190501.50331503': __('identifierSchemeCodeUnspsc190501.50331503'),
	'identifierSchemeCodeUnspsc190501.50331504': __('identifierSchemeCodeUnspsc190501.50331504'),
	'identifierSchemeCodeUnspsc190501.50331505': __('identifierSchemeCodeUnspsc190501.50331505'),
	'identifierSchemeCodeUnspsc190501.50331506': __('identifierSchemeCodeUnspsc190501.50331506'),
	'identifierSchemeCodeUnspsc190501.50331507': __('identifierSchemeCodeUnspsc190501.50331507'),
	'identifierSchemeCodeUnspsc190501.50331508': __('identifierSchemeCodeUnspsc190501.50331508'),
	'identifierSchemeCodeUnspsc190501.50331509': __('identifierSchemeCodeUnspsc190501.50331509'),
	'identifierSchemeCodeUnspsc190501.50331510': __('identifierSchemeCodeUnspsc190501.50331510'),
	'identifierSchemeCodeUnspsc190501.50331511': __('identifierSchemeCodeUnspsc190501.50331511'),
	'identifierSchemeCodeUnspsc190501.50331512': __('identifierSchemeCodeUnspsc190501.50331512'),
	'identifierSchemeCodeUnspsc190501.50331513': __('identifierSchemeCodeUnspsc190501.50331513'),
	'identifierSchemeCodeUnspsc190501.50331514': __('identifierSchemeCodeUnspsc190501.50331514'),
	'identifierSchemeCodeUnspsc190501.50331515': __('identifierSchemeCodeUnspsc190501.50331515'),
	'identifierSchemeCodeUnspsc190501.50331516': __('identifierSchemeCodeUnspsc190501.50331516'),
	'identifierSchemeCodeUnspsc190501.50331517': __('identifierSchemeCodeUnspsc190501.50331517'),
	'identifierSchemeCodeUnspsc190501.50331518': __('identifierSchemeCodeUnspsc190501.50331518'),
	'identifierSchemeCodeUnspsc190501.50331519': __('identifierSchemeCodeUnspsc190501.50331519'),
	'identifierSchemeCodeUnspsc190501.50331520': __('identifierSchemeCodeUnspsc190501.50331520'),
	'identifierSchemeCodeUnspsc190501.50331521': __('identifierSchemeCodeUnspsc190501.50331521'),
	'identifierSchemeCodeUnspsc190501.50331522': __('identifierSchemeCodeUnspsc190501.50331522'),
	'identifierSchemeCodeUnspsc190501.50331523': __('identifierSchemeCodeUnspsc190501.50331523'),
	'identifierSchemeCodeUnspsc190501.50331524': __('identifierSchemeCodeUnspsc190501.50331524'),
	'identifierSchemeCodeUnspsc190501.50331525': __('identifierSchemeCodeUnspsc190501.50331525'),
	'identifierSchemeCodeUnspsc190501.50331526': __('identifierSchemeCodeUnspsc190501.50331526'),
	'identifierSchemeCodeUnspsc190501.50331527': __('identifierSchemeCodeUnspsc190501.50331527'),
	'identifierSchemeCodeUnspsc190501.50331528': __('identifierSchemeCodeUnspsc190501.50331528'),
	'identifierSchemeCodeUnspsc190501.50331529': __('identifierSchemeCodeUnspsc190501.50331529'),
	'identifierSchemeCodeUnspsc190501.50331530': __('identifierSchemeCodeUnspsc190501.50331530'),
	'identifierSchemeCodeUnspsc190501.50331531': __('identifierSchemeCodeUnspsc190501.50331531'),
	'identifierSchemeCodeUnspsc190501.50331532': __('identifierSchemeCodeUnspsc190501.50331532'),
	'identifierSchemeCodeUnspsc190501.50331533': __('identifierSchemeCodeUnspsc190501.50331533'),
	'identifierSchemeCodeUnspsc190501.50331534': __('identifierSchemeCodeUnspsc190501.50331534'),
	'identifierSchemeCodeUnspsc190501.50331535': __('identifierSchemeCodeUnspsc190501.50331535'),
	'identifierSchemeCodeUnspsc190501.50331536': __('identifierSchemeCodeUnspsc190501.50331536'),
	'identifierSchemeCodeUnspsc190501.50331537': __('identifierSchemeCodeUnspsc190501.50331537'),
	'identifierSchemeCodeUnspsc190501.50331538': __('identifierSchemeCodeUnspsc190501.50331538'),
	'identifierSchemeCodeUnspsc190501.50331539': __('identifierSchemeCodeUnspsc190501.50331539'),
	'identifierSchemeCodeUnspsc190501.50331540': __('identifierSchemeCodeUnspsc190501.50331540'),
	'identifierSchemeCodeUnspsc190501.50331541': __('identifierSchemeCodeUnspsc190501.50331541'),
	'identifierSchemeCodeUnspsc190501.50331542': __('identifierSchemeCodeUnspsc190501.50331542'),
	'identifierSchemeCodeUnspsc190501.50331543': __('identifierSchemeCodeUnspsc190501.50331543'),
	'identifierSchemeCodeUnspsc190501.50331544': __('identifierSchemeCodeUnspsc190501.50331544'),
	'identifierSchemeCodeUnspsc190501.50331545': __('identifierSchemeCodeUnspsc190501.50331545'),
	'identifierSchemeCodeUnspsc190501.50331546': __('identifierSchemeCodeUnspsc190501.50331546'),
	'identifierSchemeCodeUnspsc190501.50331547': __('identifierSchemeCodeUnspsc190501.50331547'),
	'identifierSchemeCodeUnspsc190501.50331548': __('identifierSchemeCodeUnspsc190501.50331548'),
	'identifierSchemeCodeUnspsc190501.50331549': __('identifierSchemeCodeUnspsc190501.50331549'),
	'identifierSchemeCodeUnspsc190501.50331550': __('identifierSchemeCodeUnspsc190501.50331550'),
	'identifierSchemeCodeUnspsc190501.50331551': __('identifierSchemeCodeUnspsc190501.50331551'),
	'identifierSchemeCodeUnspsc190501.50331552': __('identifierSchemeCodeUnspsc190501.50331552'),
	'identifierSchemeCodeUnspsc190501.50331553': __('identifierSchemeCodeUnspsc190501.50331553'),
	'identifierSchemeCodeUnspsc190501.50331554': __('identifierSchemeCodeUnspsc190501.50331554'),
	'identifierSchemeCodeUnspsc190501.50331555': __('identifierSchemeCodeUnspsc190501.50331555'),
	'identifierSchemeCodeUnspsc190501.50331556': __('identifierSchemeCodeUnspsc190501.50331556'),
	'identifierSchemeCodeUnspsc190501.50331557': __('identifierSchemeCodeUnspsc190501.50331557'),
	'identifierSchemeCodeUnspsc190501.50331558': __('identifierSchemeCodeUnspsc190501.50331558'),
	'identifierSchemeCodeUnspsc190501.50331559': __('identifierSchemeCodeUnspsc190501.50331559'),
	'identifierSchemeCodeUnspsc190501.50331560': __('identifierSchemeCodeUnspsc190501.50331560'),
	'identifierSchemeCodeUnspsc190501.50331561': __('identifierSchemeCodeUnspsc190501.50331561'),
	'identifierSchemeCodeUnspsc190501.50331562': __('identifierSchemeCodeUnspsc190501.50331562'),
	'identifierSchemeCodeUnspsc190501.50331563': __('identifierSchemeCodeUnspsc190501.50331563'),
	'identifierSchemeCodeUnspsc190501.50331564': __('identifierSchemeCodeUnspsc190501.50331564'),
	'identifierSchemeCodeUnspsc190501.50331565': __('identifierSchemeCodeUnspsc190501.50331565'),
	'identifierSchemeCodeUnspsc190501.50331566': __('identifierSchemeCodeUnspsc190501.50331566'),
	'identifierSchemeCodeUnspsc190501.50331567': __('identifierSchemeCodeUnspsc190501.50331567'),
	'identifierSchemeCodeUnspsc190501.50331568': __('identifierSchemeCodeUnspsc190501.50331568'),
	'identifierSchemeCodeUnspsc190501.50331569': __('identifierSchemeCodeUnspsc190501.50331569'),
	'identifierSchemeCodeUnspsc190501.50331570': __('identifierSchemeCodeUnspsc190501.50331570'),
	'identifierSchemeCodeUnspsc190501.50331571': __('identifierSchemeCodeUnspsc190501.50331571'),
	'identifierSchemeCodeUnspsc190501.50331572': __('identifierSchemeCodeUnspsc190501.50331572'),
	'identifierSchemeCodeUnspsc190501.50331573': __('identifierSchemeCodeUnspsc190501.50331573'),
	'identifierSchemeCodeUnspsc190501.50331574': __('identifierSchemeCodeUnspsc190501.50331574'),
	'identifierSchemeCodeUnspsc190501.50331575': __('identifierSchemeCodeUnspsc190501.50331575'),
	'identifierSchemeCodeUnspsc190501.50331576': __('identifierSchemeCodeUnspsc190501.50331576'),
	'identifierSchemeCodeUnspsc190501.50331577': __('identifierSchemeCodeUnspsc190501.50331577'),
	'identifierSchemeCodeUnspsc190501.50331578': __('identifierSchemeCodeUnspsc190501.50331578'),
	'identifierSchemeCodeUnspsc190501.50331579': __('identifierSchemeCodeUnspsc190501.50331579'),
	'identifierSchemeCodeUnspsc190501.50331580': __('identifierSchemeCodeUnspsc190501.50331580'),
	'identifierSchemeCodeUnspsc190501.50331581': __('identifierSchemeCodeUnspsc190501.50331581'),
	'identifierSchemeCodeUnspsc190501.50331582': __('identifierSchemeCodeUnspsc190501.50331582'),
	'identifierSchemeCodeUnspsc190501.50331583': __('identifierSchemeCodeUnspsc190501.50331583'),
	'identifierSchemeCodeUnspsc190501.50331584': __('identifierSchemeCodeUnspsc190501.50331584'),
	'identifierSchemeCodeUnspsc190501.50331585': __('identifierSchemeCodeUnspsc190501.50331585'),
	'identifierSchemeCodeUnspsc190501.50331586': __('identifierSchemeCodeUnspsc190501.50331586'),
	'identifierSchemeCodeUnspsc190501.50331587': __('identifierSchemeCodeUnspsc190501.50331587'),
	'identifierSchemeCodeUnspsc190501.50331588': __('identifierSchemeCodeUnspsc190501.50331588'),
	'identifierSchemeCodeUnspsc190501.50331589': __('identifierSchemeCodeUnspsc190501.50331589'),
	'identifierSchemeCodeUnspsc190501.50331590': __('identifierSchemeCodeUnspsc190501.50331590'),
	'identifierSchemeCodeUnspsc190501.50331591': __('identifierSchemeCodeUnspsc190501.50331591'),
	'identifierSchemeCodeUnspsc190501.50331592': __('identifierSchemeCodeUnspsc190501.50331592'),
	'identifierSchemeCodeUnspsc190501.50331593': __('identifierSchemeCodeUnspsc190501.50331593'),
	'identifierSchemeCodeUnspsc190501.50331594': __('identifierSchemeCodeUnspsc190501.50331594'),
	'identifierSchemeCodeUnspsc190501.50331595': __('identifierSchemeCodeUnspsc190501.50331595'),
	'identifierSchemeCodeUnspsc190501.50331596': __('identifierSchemeCodeUnspsc190501.50331596'),
	'identifierSchemeCodeUnspsc190501.50331600': __('identifierSchemeCodeUnspsc190501.50331600'),
	'identifierSchemeCodeUnspsc190501.50331601': __('identifierSchemeCodeUnspsc190501.50331601'),
	'identifierSchemeCodeUnspsc190501.50331602': __('identifierSchemeCodeUnspsc190501.50331602'),
	'identifierSchemeCodeUnspsc190501.50331603': __('identifierSchemeCodeUnspsc190501.50331603'),
	'identifierSchemeCodeUnspsc190501.50331604': __('identifierSchemeCodeUnspsc190501.50331604'),
	'identifierSchemeCodeUnspsc190501.50331605': __('identifierSchemeCodeUnspsc190501.50331605'),
	'identifierSchemeCodeUnspsc190501.50331606': __('identifierSchemeCodeUnspsc190501.50331606'),
	'identifierSchemeCodeUnspsc190501.50331607': __('identifierSchemeCodeUnspsc190501.50331607'),
	'identifierSchemeCodeUnspsc190501.50331608': __('identifierSchemeCodeUnspsc190501.50331608'),
	'identifierSchemeCodeUnspsc190501.50331609': __('identifierSchemeCodeUnspsc190501.50331609'),
	'identifierSchemeCodeUnspsc190501.50331610': __('identifierSchemeCodeUnspsc190501.50331610'),
	'identifierSchemeCodeUnspsc190501.50331611': __('identifierSchemeCodeUnspsc190501.50331611'),
	'identifierSchemeCodeUnspsc190501.50331612': __('identifierSchemeCodeUnspsc190501.50331612'),
	'identifierSchemeCodeUnspsc190501.50331613': __('identifierSchemeCodeUnspsc190501.50331613'),
	'identifierSchemeCodeUnspsc190501.50331614': __('identifierSchemeCodeUnspsc190501.50331614'),
	'identifierSchemeCodeUnspsc190501.50331615': __('identifierSchemeCodeUnspsc190501.50331615'),
	'identifierSchemeCodeUnspsc190501.50331616': __('identifierSchemeCodeUnspsc190501.50331616'),
	'identifierSchemeCodeUnspsc190501.50331617': __('identifierSchemeCodeUnspsc190501.50331617'),
	'identifierSchemeCodeUnspsc190501.50331618': __('identifierSchemeCodeUnspsc190501.50331618'),
	'identifierSchemeCodeUnspsc190501.50331619': __('identifierSchemeCodeUnspsc190501.50331619'),
	'identifierSchemeCodeUnspsc190501.50331620': __('identifierSchemeCodeUnspsc190501.50331620'),
	'identifierSchemeCodeUnspsc190501.50331621': __('identifierSchemeCodeUnspsc190501.50331621'),
	'identifierSchemeCodeUnspsc190501.50331622': __('identifierSchemeCodeUnspsc190501.50331622'),
	'identifierSchemeCodeUnspsc190501.50331623': __('identifierSchemeCodeUnspsc190501.50331623'),
	'identifierSchemeCodeUnspsc190501.50331624': __('identifierSchemeCodeUnspsc190501.50331624'),
	'identifierSchemeCodeUnspsc190501.50331625': __('identifierSchemeCodeUnspsc190501.50331625'),
	'identifierSchemeCodeUnspsc190501.50331626': __('identifierSchemeCodeUnspsc190501.50331626'),
	'identifierSchemeCodeUnspsc190501.50331627': __('identifierSchemeCodeUnspsc190501.50331627'),
	'identifierSchemeCodeUnspsc190501.50331628': __('identifierSchemeCodeUnspsc190501.50331628'),
	'identifierSchemeCodeUnspsc190501.50331629': __('identifierSchemeCodeUnspsc190501.50331629'),
	'identifierSchemeCodeUnspsc190501.50331630': __('identifierSchemeCodeUnspsc190501.50331630'),
	'identifierSchemeCodeUnspsc190501.50331631': __('identifierSchemeCodeUnspsc190501.50331631'),
	'identifierSchemeCodeUnspsc190501.50331632': __('identifierSchemeCodeUnspsc190501.50331632'),
	'identifierSchemeCodeUnspsc190501.50331633': __('identifierSchemeCodeUnspsc190501.50331633'),
	'identifierSchemeCodeUnspsc190501.50331634': __('identifierSchemeCodeUnspsc190501.50331634'),
	'identifierSchemeCodeUnspsc190501.50331635': __('identifierSchemeCodeUnspsc190501.50331635'),
	'identifierSchemeCodeUnspsc190501.50331636': __('identifierSchemeCodeUnspsc190501.50331636'),
	'identifierSchemeCodeUnspsc190501.50331637': __('identifierSchemeCodeUnspsc190501.50331637'),
	'identifierSchemeCodeUnspsc190501.50331638': __('identifierSchemeCodeUnspsc190501.50331638'),
	'identifierSchemeCodeUnspsc190501.50331639': __('identifierSchemeCodeUnspsc190501.50331639'),
	'identifierSchemeCodeUnspsc190501.50331640': __('identifierSchemeCodeUnspsc190501.50331640'),
	'identifierSchemeCodeUnspsc190501.50331641': __('identifierSchemeCodeUnspsc190501.50331641'),
	'identifierSchemeCodeUnspsc190501.50331642': __('identifierSchemeCodeUnspsc190501.50331642'),
	'identifierSchemeCodeUnspsc190501.50331643': __('identifierSchemeCodeUnspsc190501.50331643'),
	'identifierSchemeCodeUnspsc190501.50331644': __('identifierSchemeCodeUnspsc190501.50331644'),
	'identifierSchemeCodeUnspsc190501.50331645': __('identifierSchemeCodeUnspsc190501.50331645'),
	'identifierSchemeCodeUnspsc190501.50331646': __('identifierSchemeCodeUnspsc190501.50331646'),
	'identifierSchemeCodeUnspsc190501.50331647': __('identifierSchemeCodeUnspsc190501.50331647'),
	'identifierSchemeCodeUnspsc190501.50331648': __('identifierSchemeCodeUnspsc190501.50331648'),
	'identifierSchemeCodeUnspsc190501.50331649': __('identifierSchemeCodeUnspsc190501.50331649'),
	'identifierSchemeCodeUnspsc190501.50331650': __('identifierSchemeCodeUnspsc190501.50331650'),
	'identifierSchemeCodeUnspsc190501.50331651': __('identifierSchemeCodeUnspsc190501.50331651'),
	'identifierSchemeCodeUnspsc190501.50331652': __('identifierSchemeCodeUnspsc190501.50331652'),
	'identifierSchemeCodeUnspsc190501.50331653': __('identifierSchemeCodeUnspsc190501.50331653'),
	'identifierSchemeCodeUnspsc190501.50331654': __('identifierSchemeCodeUnspsc190501.50331654'),
	'identifierSchemeCodeUnspsc190501.50331655': __('identifierSchemeCodeUnspsc190501.50331655'),
	'identifierSchemeCodeUnspsc190501.50331700': __('identifierSchemeCodeUnspsc190501.50331700'),
	'identifierSchemeCodeUnspsc190501.50331701': __('identifierSchemeCodeUnspsc190501.50331701'),
	'identifierSchemeCodeUnspsc190501.50331702': __('identifierSchemeCodeUnspsc190501.50331702'),
	'identifierSchemeCodeUnspsc190501.50331703': __('identifierSchemeCodeUnspsc190501.50331703'),
	'identifierSchemeCodeUnspsc190501.50331704': __('identifierSchemeCodeUnspsc190501.50331704'),
	'identifierSchemeCodeUnspsc190501.50331705': __('identifierSchemeCodeUnspsc190501.50331705'),
	'identifierSchemeCodeUnspsc190501.50331706': __('identifierSchemeCodeUnspsc190501.50331706'),
	'identifierSchemeCodeUnspsc190501.50331707': __('identifierSchemeCodeUnspsc190501.50331707'),
	'identifierSchemeCodeUnspsc190501.50331708': __('identifierSchemeCodeUnspsc190501.50331708'),
	'identifierSchemeCodeUnspsc190501.50331709': __('identifierSchemeCodeUnspsc190501.50331709'),
	'identifierSchemeCodeUnspsc190501.50331710': __('identifierSchemeCodeUnspsc190501.50331710'),
	'identifierSchemeCodeUnspsc190501.50331711': __('identifierSchemeCodeUnspsc190501.50331711'),
	'identifierSchemeCodeUnspsc190501.50331712': __('identifierSchemeCodeUnspsc190501.50331712'),
	'identifierSchemeCodeUnspsc190501.50331713': __('identifierSchemeCodeUnspsc190501.50331713'),
	'identifierSchemeCodeUnspsc190501.50331714': __('identifierSchemeCodeUnspsc190501.50331714'),
	'identifierSchemeCodeUnspsc190501.50331715': __('identifierSchemeCodeUnspsc190501.50331715'),
	'identifierSchemeCodeUnspsc190501.50331800': __('identifierSchemeCodeUnspsc190501.50331800'),
	'identifierSchemeCodeUnspsc190501.50331801': __('identifierSchemeCodeUnspsc190501.50331801'),
	'identifierSchemeCodeUnspsc190501.50331802': __('identifierSchemeCodeUnspsc190501.50331802'),
	'identifierSchemeCodeUnspsc190501.50331803': __('identifierSchemeCodeUnspsc190501.50331803'),
	'identifierSchemeCodeUnspsc190501.50331804': __('identifierSchemeCodeUnspsc190501.50331804'),
	'identifierSchemeCodeUnspsc190501.50331805': __('identifierSchemeCodeUnspsc190501.50331805'),
	'identifierSchemeCodeUnspsc190501.50331806': __('identifierSchemeCodeUnspsc190501.50331806'),
	'identifierSchemeCodeUnspsc190501.50331807': __('identifierSchemeCodeUnspsc190501.50331807'),
	'identifierSchemeCodeUnspsc190501.50331808': __('identifierSchemeCodeUnspsc190501.50331808'),
	'identifierSchemeCodeUnspsc190501.50331809': __('identifierSchemeCodeUnspsc190501.50331809'),
	'identifierSchemeCodeUnspsc190501.50331810': __('identifierSchemeCodeUnspsc190501.50331810'),
	'identifierSchemeCodeUnspsc190501.50331811': __('identifierSchemeCodeUnspsc190501.50331811'),
	'identifierSchemeCodeUnspsc190501.50331812': __('identifierSchemeCodeUnspsc190501.50331812'),
	'identifierSchemeCodeUnspsc190501.50331813': __('identifierSchemeCodeUnspsc190501.50331813'),
	'identifierSchemeCodeUnspsc190501.50331814': __('identifierSchemeCodeUnspsc190501.50331814'),
	'identifierSchemeCodeUnspsc190501.50331900': __('identifierSchemeCodeUnspsc190501.50331900'),
	'identifierSchemeCodeUnspsc190501.50331901': __('identifierSchemeCodeUnspsc190501.50331901'),
	'identifierSchemeCodeUnspsc190501.50331902': __('identifierSchemeCodeUnspsc190501.50331902'),
	'identifierSchemeCodeUnspsc190501.50331903': __('identifierSchemeCodeUnspsc190501.50331903'),
	'identifierSchemeCodeUnspsc190501.50332000': __('identifierSchemeCodeUnspsc190501.50332000'),
	'identifierSchemeCodeUnspsc190501.50332001': __('identifierSchemeCodeUnspsc190501.50332001'),
	'identifierSchemeCodeUnspsc190501.50332002': __('identifierSchemeCodeUnspsc190501.50332002'),
	'identifierSchemeCodeUnspsc190501.50332003': __('identifierSchemeCodeUnspsc190501.50332003'),
	'identifierSchemeCodeUnspsc190501.50332004': __('identifierSchemeCodeUnspsc190501.50332004'),
	'identifierSchemeCodeUnspsc190501.50332005': __('identifierSchemeCodeUnspsc190501.50332005'),
	'identifierSchemeCodeUnspsc190501.50332006': __('identifierSchemeCodeUnspsc190501.50332006'),
	'identifierSchemeCodeUnspsc190501.50332007': __('identifierSchemeCodeUnspsc190501.50332007'),
	'identifierSchemeCodeUnspsc190501.50332008': __('identifierSchemeCodeUnspsc190501.50332008'),
	'identifierSchemeCodeUnspsc190501.50332009': __('identifierSchemeCodeUnspsc190501.50332009'),
	'identifierSchemeCodeUnspsc190501.50332010': __('identifierSchemeCodeUnspsc190501.50332010'),
	'identifierSchemeCodeUnspsc190501.50332011': __('identifierSchemeCodeUnspsc190501.50332011'),
	'identifierSchemeCodeUnspsc190501.50332012': __('identifierSchemeCodeUnspsc190501.50332012'),
	'identifierSchemeCodeUnspsc190501.50332013': __('identifierSchemeCodeUnspsc190501.50332013'),
	'identifierSchemeCodeUnspsc190501.50332014': __('identifierSchemeCodeUnspsc190501.50332014'),
	'identifierSchemeCodeUnspsc190501.50332015': __('identifierSchemeCodeUnspsc190501.50332015'),
	'identifierSchemeCodeUnspsc190501.50332100': __('identifierSchemeCodeUnspsc190501.50332100'),
	'identifierSchemeCodeUnspsc190501.50332101': __('identifierSchemeCodeUnspsc190501.50332101'),
	'identifierSchemeCodeUnspsc190501.50332102': __('identifierSchemeCodeUnspsc190501.50332102'),
	'identifierSchemeCodeUnspsc190501.50332103': __('identifierSchemeCodeUnspsc190501.50332103'),
	'identifierSchemeCodeUnspsc190501.50332104': __('identifierSchemeCodeUnspsc190501.50332104'),
	'identifierSchemeCodeUnspsc190501.50332200': __('identifierSchemeCodeUnspsc190501.50332200'),
	'identifierSchemeCodeUnspsc190501.50332201': __('identifierSchemeCodeUnspsc190501.50332201'),
	'identifierSchemeCodeUnspsc190501.50332202': __('identifierSchemeCodeUnspsc190501.50332202'),
	'identifierSchemeCodeUnspsc190501.50332203': __('identifierSchemeCodeUnspsc190501.50332203'),
	'identifierSchemeCodeUnspsc190501.50332204': __('identifierSchemeCodeUnspsc190501.50332204'),
	'identifierSchemeCodeUnspsc190501.50332205': __('identifierSchemeCodeUnspsc190501.50332205'),
	'identifierSchemeCodeUnspsc190501.50332206': __('identifierSchemeCodeUnspsc190501.50332206'),
	'identifierSchemeCodeUnspsc190501.50332207': __('identifierSchemeCodeUnspsc190501.50332207'),
	'identifierSchemeCodeUnspsc190501.50332208': __('identifierSchemeCodeUnspsc190501.50332208'),
	'identifierSchemeCodeUnspsc190501.50332209': __('identifierSchemeCodeUnspsc190501.50332209'),
	'identifierSchemeCodeUnspsc190501.50332210': __('identifierSchemeCodeUnspsc190501.50332210'),
	'identifierSchemeCodeUnspsc190501.50332211': __('identifierSchemeCodeUnspsc190501.50332211'),
	'identifierSchemeCodeUnspsc190501.50332212': __('identifierSchemeCodeUnspsc190501.50332212'),
	'identifierSchemeCodeUnspsc190501.50332213': __('identifierSchemeCodeUnspsc190501.50332213'),
	'identifierSchemeCodeUnspsc190501.50332214': __('identifierSchemeCodeUnspsc190501.50332214'),
	'identifierSchemeCodeUnspsc190501.50332215': __('identifierSchemeCodeUnspsc190501.50332215'),
	'identifierSchemeCodeUnspsc190501.50332300': __('identifierSchemeCodeUnspsc190501.50332300'),
	'identifierSchemeCodeUnspsc190501.50332301': __('identifierSchemeCodeUnspsc190501.50332301'),
	'identifierSchemeCodeUnspsc190501.50332302': __('identifierSchemeCodeUnspsc190501.50332302'),
	'identifierSchemeCodeUnspsc190501.50332303': __('identifierSchemeCodeUnspsc190501.50332303'),
	'identifierSchemeCodeUnspsc190501.50332304': __('identifierSchemeCodeUnspsc190501.50332304'),
	'identifierSchemeCodeUnspsc190501.50332400': __('identifierSchemeCodeUnspsc190501.50332400'),
	'identifierSchemeCodeUnspsc190501.50332401': __('identifierSchemeCodeUnspsc190501.50332401'),
	'identifierSchemeCodeUnspsc190501.50332402': __('identifierSchemeCodeUnspsc190501.50332402'),
	'identifierSchemeCodeUnspsc190501.50332403': __('identifierSchemeCodeUnspsc190501.50332403'),
	'identifierSchemeCodeUnspsc190501.50332404': __('identifierSchemeCodeUnspsc190501.50332404'),
	'identifierSchemeCodeUnspsc190501.50332405': __('identifierSchemeCodeUnspsc190501.50332405'),
	'identifierSchemeCodeUnspsc190501.50332406': __('identifierSchemeCodeUnspsc190501.50332406'),
	'identifierSchemeCodeUnspsc190501.50332407': __('identifierSchemeCodeUnspsc190501.50332407'),
	'identifierSchemeCodeUnspsc190501.50332408': __('identifierSchemeCodeUnspsc190501.50332408'),
	'identifierSchemeCodeUnspsc190501.50332409': __('identifierSchemeCodeUnspsc190501.50332409'),
	'identifierSchemeCodeUnspsc190501.50332500': __('identifierSchemeCodeUnspsc190501.50332500'),
	'identifierSchemeCodeUnspsc190501.50332501': __('identifierSchemeCodeUnspsc190501.50332501'),
	'identifierSchemeCodeUnspsc190501.50332502': __('identifierSchemeCodeUnspsc190501.50332502'),
	'identifierSchemeCodeUnspsc190501.50332503': __('identifierSchemeCodeUnspsc190501.50332503'),
	'identifierSchemeCodeUnspsc190501.50332504': __('identifierSchemeCodeUnspsc190501.50332504'),
	'identifierSchemeCodeUnspsc190501.50332505': __('identifierSchemeCodeUnspsc190501.50332505'),
	'identifierSchemeCodeUnspsc190501.50332506': __('identifierSchemeCodeUnspsc190501.50332506'),
	'identifierSchemeCodeUnspsc190501.50332507': __('identifierSchemeCodeUnspsc190501.50332507'),
	'identifierSchemeCodeUnspsc190501.50332508': __('identifierSchemeCodeUnspsc190501.50332508'),
	'identifierSchemeCodeUnspsc190501.50332509': __('identifierSchemeCodeUnspsc190501.50332509'),
	'identifierSchemeCodeUnspsc190501.50332510': __('identifierSchemeCodeUnspsc190501.50332510'),
	'identifierSchemeCodeUnspsc190501.50332511': __('identifierSchemeCodeUnspsc190501.50332511'),
	'identifierSchemeCodeUnspsc190501.50332512': __('identifierSchemeCodeUnspsc190501.50332512'),
	'identifierSchemeCodeUnspsc190501.50332513': __('identifierSchemeCodeUnspsc190501.50332513'),
	'identifierSchemeCodeUnspsc190501.50332514': __('identifierSchemeCodeUnspsc190501.50332514'),
	'identifierSchemeCodeUnspsc190501.50332515': __('identifierSchemeCodeUnspsc190501.50332515'),
	'identifierSchemeCodeUnspsc190501.50332516': __('identifierSchemeCodeUnspsc190501.50332516'),
	'identifierSchemeCodeUnspsc190501.50332517': __('identifierSchemeCodeUnspsc190501.50332517'),
	'identifierSchemeCodeUnspsc190501.50332518': __('identifierSchemeCodeUnspsc190501.50332518'),
	'identifierSchemeCodeUnspsc190501.50332519': __('identifierSchemeCodeUnspsc190501.50332519'),
	'identifierSchemeCodeUnspsc190501.50332520': __('identifierSchemeCodeUnspsc190501.50332520'),
	'identifierSchemeCodeUnspsc190501.50332521': __('identifierSchemeCodeUnspsc190501.50332521'),
	'identifierSchemeCodeUnspsc190501.50332522': __('identifierSchemeCodeUnspsc190501.50332522'),
	'identifierSchemeCodeUnspsc190501.50332523': __('identifierSchemeCodeUnspsc190501.50332523'),
	'identifierSchemeCodeUnspsc190501.50332524': __('identifierSchemeCodeUnspsc190501.50332524'),
	'identifierSchemeCodeUnspsc190501.50332525': __('identifierSchemeCodeUnspsc190501.50332525'),
	'identifierSchemeCodeUnspsc190501.50332527': __('identifierSchemeCodeUnspsc190501.50332527'),
	'identifierSchemeCodeUnspsc190501.50332528': __('identifierSchemeCodeUnspsc190501.50332528'),
	'identifierSchemeCodeUnspsc190501.50332529': __('identifierSchemeCodeUnspsc190501.50332529'),
	'identifierSchemeCodeUnspsc190501.50332530': __('identifierSchemeCodeUnspsc190501.50332530'),
	'identifierSchemeCodeUnspsc190501.50332531': __('identifierSchemeCodeUnspsc190501.50332531'),
	'identifierSchemeCodeUnspsc190501.50332532': __('identifierSchemeCodeUnspsc190501.50332532'),
	'identifierSchemeCodeUnspsc190501.50332600': __('identifierSchemeCodeUnspsc190501.50332600'),
	'identifierSchemeCodeUnspsc190501.50332601': __('identifierSchemeCodeUnspsc190501.50332601'),
	'identifierSchemeCodeUnspsc190501.50332602': __('identifierSchemeCodeUnspsc190501.50332602'),
	'identifierSchemeCodeUnspsc190501.50332603': __('identifierSchemeCodeUnspsc190501.50332603'),
	'identifierSchemeCodeUnspsc190501.50332604': __('identifierSchemeCodeUnspsc190501.50332604'),
	'identifierSchemeCodeUnspsc190501.50332605': __('identifierSchemeCodeUnspsc190501.50332605'),
	'identifierSchemeCodeUnspsc190501.50332606': __('identifierSchemeCodeUnspsc190501.50332606'),
	'identifierSchemeCodeUnspsc190501.50332607': __('identifierSchemeCodeUnspsc190501.50332607'),
	'identifierSchemeCodeUnspsc190501.50332700': __('identifierSchemeCodeUnspsc190501.50332700'),
	'identifierSchemeCodeUnspsc190501.50332701': __('identifierSchemeCodeUnspsc190501.50332701'),
	'identifierSchemeCodeUnspsc190501.50332702': __('identifierSchemeCodeUnspsc190501.50332702'),
	'identifierSchemeCodeUnspsc190501.50332703': __('identifierSchemeCodeUnspsc190501.50332703'),
	'identifierSchemeCodeUnspsc190501.50332704': __('identifierSchemeCodeUnspsc190501.50332704'),
	'identifierSchemeCodeUnspsc190501.50332705': __('identifierSchemeCodeUnspsc190501.50332705'),
	'identifierSchemeCodeUnspsc190501.50332706': __('identifierSchemeCodeUnspsc190501.50332706'),
	'identifierSchemeCodeUnspsc190501.50332707': __('identifierSchemeCodeUnspsc190501.50332707'),
	'identifierSchemeCodeUnspsc190501.50332708': __('identifierSchemeCodeUnspsc190501.50332708'),
	'identifierSchemeCodeUnspsc190501.50332709': __('identifierSchemeCodeUnspsc190501.50332709'),
	'identifierSchemeCodeUnspsc190501.50332710': __('identifierSchemeCodeUnspsc190501.50332710'),
	'identifierSchemeCodeUnspsc190501.50332800': __('identifierSchemeCodeUnspsc190501.50332800'),
	'identifierSchemeCodeUnspsc190501.50332801': __('identifierSchemeCodeUnspsc190501.50332801'),
	'identifierSchemeCodeUnspsc190501.50332802': __('identifierSchemeCodeUnspsc190501.50332802'),
	'identifierSchemeCodeUnspsc190501.50332803': __('identifierSchemeCodeUnspsc190501.50332803'),
	'identifierSchemeCodeUnspsc190501.50332804': __('identifierSchemeCodeUnspsc190501.50332804'),
	'identifierSchemeCodeUnspsc190501.50332805': __('identifierSchemeCodeUnspsc190501.50332805'),
	'identifierSchemeCodeUnspsc190501.50332806': __('identifierSchemeCodeUnspsc190501.50332806'),
	'identifierSchemeCodeUnspsc190501.50332900': __('identifierSchemeCodeUnspsc190501.50332900'),
	'identifierSchemeCodeUnspsc190501.50332901': __('identifierSchemeCodeUnspsc190501.50332901'),
	'identifierSchemeCodeUnspsc190501.50332902': __('identifierSchemeCodeUnspsc190501.50332902'),
	'identifierSchemeCodeUnspsc190501.50332903': __('identifierSchemeCodeUnspsc190501.50332903'),
	'identifierSchemeCodeUnspsc190501.50332904': __('identifierSchemeCodeUnspsc190501.50332904'),
	'identifierSchemeCodeUnspsc190501.50332905': __('identifierSchemeCodeUnspsc190501.50332905'),
	'identifierSchemeCodeUnspsc190501.50332906': __('identifierSchemeCodeUnspsc190501.50332906'),
	'identifierSchemeCodeUnspsc190501.50332907': __('identifierSchemeCodeUnspsc190501.50332907'),
	'identifierSchemeCodeUnspsc190501.50332908': __('identifierSchemeCodeUnspsc190501.50332908'),
	'identifierSchemeCodeUnspsc190501.50332909': __('identifierSchemeCodeUnspsc190501.50332909'),
	'identifierSchemeCodeUnspsc190501.50332910': __('identifierSchemeCodeUnspsc190501.50332910'),
	'identifierSchemeCodeUnspsc190501.50332911': __('identifierSchemeCodeUnspsc190501.50332911'),
	'identifierSchemeCodeUnspsc190501.50332912': __('identifierSchemeCodeUnspsc190501.50332912'),
	'identifierSchemeCodeUnspsc190501.50332913': __('identifierSchemeCodeUnspsc190501.50332913'),
	'identifierSchemeCodeUnspsc190501.50332914': __('identifierSchemeCodeUnspsc190501.50332914'),
	'identifierSchemeCodeUnspsc190501.50332915': __('identifierSchemeCodeUnspsc190501.50332915'),
	'identifierSchemeCodeUnspsc190501.50332916': __('identifierSchemeCodeUnspsc190501.50332916'),
	'identifierSchemeCodeUnspsc190501.50333000': __('identifierSchemeCodeUnspsc190501.50333000'),
	'identifierSchemeCodeUnspsc190501.50333001': __('identifierSchemeCodeUnspsc190501.50333001'),
	'identifierSchemeCodeUnspsc190501.50333002': __('identifierSchemeCodeUnspsc190501.50333002'),
	'identifierSchemeCodeUnspsc190501.50333100': __('identifierSchemeCodeUnspsc190501.50333100'),
	'identifierSchemeCodeUnspsc190501.50333101': __('identifierSchemeCodeUnspsc190501.50333101'),
	'identifierSchemeCodeUnspsc190501.50333102': __('identifierSchemeCodeUnspsc190501.50333102'),
	'identifierSchemeCodeUnspsc190501.50333103': __('identifierSchemeCodeUnspsc190501.50333103'),
	'identifierSchemeCodeUnspsc190501.50333104': __('identifierSchemeCodeUnspsc190501.50333104'),
	'identifierSchemeCodeUnspsc190501.50333105': __('identifierSchemeCodeUnspsc190501.50333105'),
	'identifierSchemeCodeUnspsc190501.50333106': __('identifierSchemeCodeUnspsc190501.50333106'),
	'identifierSchemeCodeUnspsc190501.50333107': __('identifierSchemeCodeUnspsc190501.50333107'),
	'identifierSchemeCodeUnspsc190501.50333108': __('identifierSchemeCodeUnspsc190501.50333108'),
	'identifierSchemeCodeUnspsc190501.50333109': __('identifierSchemeCodeUnspsc190501.50333109'),
	'identifierSchemeCodeUnspsc190501.50333110': __('identifierSchemeCodeUnspsc190501.50333110'),
	'identifierSchemeCodeUnspsc190501.50333111': __('identifierSchemeCodeUnspsc190501.50333111'),
	'identifierSchemeCodeUnspsc190501.50333200': __('identifierSchemeCodeUnspsc190501.50333200'),
	'identifierSchemeCodeUnspsc190501.50333201': __('identifierSchemeCodeUnspsc190501.50333201'),
	'identifierSchemeCodeUnspsc190501.50333202': __('identifierSchemeCodeUnspsc190501.50333202'),
	'identifierSchemeCodeUnspsc190501.50333203': __('identifierSchemeCodeUnspsc190501.50333203'),
	'identifierSchemeCodeUnspsc190501.50333204': __('identifierSchemeCodeUnspsc190501.50333204'),
	'identifierSchemeCodeUnspsc190501.50333205': __('identifierSchemeCodeUnspsc190501.50333205'),
	'identifierSchemeCodeUnspsc190501.50333206': __('identifierSchemeCodeUnspsc190501.50333206'),
	'identifierSchemeCodeUnspsc190501.50333207': __('identifierSchemeCodeUnspsc190501.50333207'),
	'identifierSchemeCodeUnspsc190501.50333300': __('identifierSchemeCodeUnspsc190501.50333300'),
	'identifierSchemeCodeUnspsc190501.50333301': __('identifierSchemeCodeUnspsc190501.50333301'),
	'identifierSchemeCodeUnspsc190501.50333302': __('identifierSchemeCodeUnspsc190501.50333302'),
	'identifierSchemeCodeUnspsc190501.50333303': __('identifierSchemeCodeUnspsc190501.50333303'),
	'identifierSchemeCodeUnspsc190501.50333304': __('identifierSchemeCodeUnspsc190501.50333304'),
	'identifierSchemeCodeUnspsc190501.50333305': __('identifierSchemeCodeUnspsc190501.50333305'),
	'identifierSchemeCodeUnspsc190501.50333306': __('identifierSchemeCodeUnspsc190501.50333306'),
	'identifierSchemeCodeUnspsc190501.50333307': __('identifierSchemeCodeUnspsc190501.50333307'),
	'identifierSchemeCodeUnspsc190501.50333308': __('identifierSchemeCodeUnspsc190501.50333308'),
	'identifierSchemeCodeUnspsc190501.50333309': __('identifierSchemeCodeUnspsc190501.50333309'),
	'identifierSchemeCodeUnspsc190501.50333400': __('identifierSchemeCodeUnspsc190501.50333400'),
	'identifierSchemeCodeUnspsc190501.50333401': __('identifierSchemeCodeUnspsc190501.50333401'),
	'identifierSchemeCodeUnspsc190501.50333402': __('identifierSchemeCodeUnspsc190501.50333402'),
	'identifierSchemeCodeUnspsc190501.50333403': __('identifierSchemeCodeUnspsc190501.50333403'),
	'identifierSchemeCodeUnspsc190501.50333404': __('identifierSchemeCodeUnspsc190501.50333404'),
	'identifierSchemeCodeUnspsc190501.50333405': __('identifierSchemeCodeUnspsc190501.50333405'),
	'identifierSchemeCodeUnspsc190501.50333406': __('identifierSchemeCodeUnspsc190501.50333406'),
	'identifierSchemeCodeUnspsc190501.50333407': __('identifierSchemeCodeUnspsc190501.50333407'),
	'identifierSchemeCodeUnspsc190501.50333408': __('identifierSchemeCodeUnspsc190501.50333408'),
	'identifierSchemeCodeUnspsc190501.50333409': __('identifierSchemeCodeUnspsc190501.50333409'),
	'identifierSchemeCodeUnspsc190501.50333410': __('identifierSchemeCodeUnspsc190501.50333410'),
	'identifierSchemeCodeUnspsc190501.50333411': __('identifierSchemeCodeUnspsc190501.50333411'),
	'identifierSchemeCodeUnspsc190501.50333412': __('identifierSchemeCodeUnspsc190501.50333412'),
	'identifierSchemeCodeUnspsc190501.50333413': __('identifierSchemeCodeUnspsc190501.50333413'),
	'identifierSchemeCodeUnspsc190501.50333414': __('identifierSchemeCodeUnspsc190501.50333414'),
	'identifierSchemeCodeUnspsc190501.50333415': __('identifierSchemeCodeUnspsc190501.50333415'),
	'identifierSchemeCodeUnspsc190501.50333416': __('identifierSchemeCodeUnspsc190501.50333416'),
	'identifierSchemeCodeUnspsc190501.50333417': __('identifierSchemeCodeUnspsc190501.50333417'),
	'identifierSchemeCodeUnspsc190501.50333418': __('identifierSchemeCodeUnspsc190501.50333418'),
	'identifierSchemeCodeUnspsc190501.50333419': __('identifierSchemeCodeUnspsc190501.50333419'),
	'identifierSchemeCodeUnspsc190501.50333420': __('identifierSchemeCodeUnspsc190501.50333420'),
	'identifierSchemeCodeUnspsc190501.50333421': __('identifierSchemeCodeUnspsc190501.50333421'),
	'identifierSchemeCodeUnspsc190501.50333422': __('identifierSchemeCodeUnspsc190501.50333422'),
	'identifierSchemeCodeUnspsc190501.50333423': __('identifierSchemeCodeUnspsc190501.50333423'),
	'identifierSchemeCodeUnspsc190501.50333424': __('identifierSchemeCodeUnspsc190501.50333424'),
	'identifierSchemeCodeUnspsc190501.50333425': __('identifierSchemeCodeUnspsc190501.50333425'),
	'identifierSchemeCodeUnspsc190501.50333426': __('identifierSchemeCodeUnspsc190501.50333426'),
	'identifierSchemeCodeUnspsc190501.50333427': __('identifierSchemeCodeUnspsc190501.50333427'),
	'identifierSchemeCodeUnspsc190501.50333428': __('identifierSchemeCodeUnspsc190501.50333428'),
	'identifierSchemeCodeUnspsc190501.50333429': __('identifierSchemeCodeUnspsc190501.50333429'),
	'identifierSchemeCodeUnspsc190501.50333430': __('identifierSchemeCodeUnspsc190501.50333430'),
	'identifierSchemeCodeUnspsc190501.50333431': __('identifierSchemeCodeUnspsc190501.50333431'),
	'identifierSchemeCodeUnspsc190501.50333432': __('identifierSchemeCodeUnspsc190501.50333432'),
	'identifierSchemeCodeUnspsc190501.50333433': __('identifierSchemeCodeUnspsc190501.50333433'),
	'identifierSchemeCodeUnspsc190501.50333434': __('identifierSchemeCodeUnspsc190501.50333434'),
	'identifierSchemeCodeUnspsc190501.50333435': __('identifierSchemeCodeUnspsc190501.50333435'),
	'identifierSchemeCodeUnspsc190501.50333436': __('identifierSchemeCodeUnspsc190501.50333436'),
	'identifierSchemeCodeUnspsc190501.50333437': __('identifierSchemeCodeUnspsc190501.50333437'),
	'identifierSchemeCodeUnspsc190501.50333438': __('identifierSchemeCodeUnspsc190501.50333438'),
	'identifierSchemeCodeUnspsc190501.50333439': __('identifierSchemeCodeUnspsc190501.50333439'),
	'identifierSchemeCodeUnspsc190501.50333440': __('identifierSchemeCodeUnspsc190501.50333440'),
	'identifierSchemeCodeUnspsc190501.50333441': __('identifierSchemeCodeUnspsc190501.50333441'),
	'identifierSchemeCodeUnspsc190501.50333442': __('identifierSchemeCodeUnspsc190501.50333442'),
	'identifierSchemeCodeUnspsc190501.50333443': __('identifierSchemeCodeUnspsc190501.50333443'),
	'identifierSchemeCodeUnspsc190501.50333444': __('identifierSchemeCodeUnspsc190501.50333444'),
	'identifierSchemeCodeUnspsc190501.50333445': __('identifierSchemeCodeUnspsc190501.50333445'),
	'identifierSchemeCodeUnspsc190501.50333446': __('identifierSchemeCodeUnspsc190501.50333446'),
	'identifierSchemeCodeUnspsc190501.50333447': __('identifierSchemeCodeUnspsc190501.50333447'),
	'identifierSchemeCodeUnspsc190501.50333448': __('identifierSchemeCodeUnspsc190501.50333448'),
	'identifierSchemeCodeUnspsc190501.50333449': __('identifierSchemeCodeUnspsc190501.50333449'),
	'identifierSchemeCodeUnspsc190501.50333450': __('identifierSchemeCodeUnspsc190501.50333450'),
	'identifierSchemeCodeUnspsc190501.50333451': __('identifierSchemeCodeUnspsc190501.50333451'),
	'identifierSchemeCodeUnspsc190501.50333452': __('identifierSchemeCodeUnspsc190501.50333452'),
	'identifierSchemeCodeUnspsc190501.50333453': __('identifierSchemeCodeUnspsc190501.50333453'),
	'identifierSchemeCodeUnspsc190501.50333454': __('identifierSchemeCodeUnspsc190501.50333454'),
	'identifierSchemeCodeUnspsc190501.50333455': __('identifierSchemeCodeUnspsc190501.50333455'),
	'identifierSchemeCodeUnspsc190501.50333456': __('identifierSchemeCodeUnspsc190501.50333456'),
	'identifierSchemeCodeUnspsc190501.50333457': __('identifierSchemeCodeUnspsc190501.50333457'),
	'identifierSchemeCodeUnspsc190501.50333458': __('identifierSchemeCodeUnspsc190501.50333458'),
	'identifierSchemeCodeUnspsc190501.50333459': __('identifierSchemeCodeUnspsc190501.50333459'),
	'identifierSchemeCodeUnspsc190501.50333460': __('identifierSchemeCodeUnspsc190501.50333460'),
	'identifierSchemeCodeUnspsc190501.50333461': __('identifierSchemeCodeUnspsc190501.50333461'),
	'identifierSchemeCodeUnspsc190501.50333462': __('identifierSchemeCodeUnspsc190501.50333462'),
	'identifierSchemeCodeUnspsc190501.50333463': __('identifierSchemeCodeUnspsc190501.50333463'),
	'identifierSchemeCodeUnspsc190501.50333464': __('identifierSchemeCodeUnspsc190501.50333464'),
	'identifierSchemeCodeUnspsc190501.50333465': __('identifierSchemeCodeUnspsc190501.50333465'),
	'identifierSchemeCodeUnspsc190501.50333466': __('identifierSchemeCodeUnspsc190501.50333466'),
	'identifierSchemeCodeUnspsc190501.50333467': __('identifierSchemeCodeUnspsc190501.50333467'),
	'identifierSchemeCodeUnspsc190501.50333468': __('identifierSchemeCodeUnspsc190501.50333468'),
	'identifierSchemeCodeUnspsc190501.50333469': __('identifierSchemeCodeUnspsc190501.50333469'),
	'identifierSchemeCodeUnspsc190501.50333470': __('identifierSchemeCodeUnspsc190501.50333470'),
	'identifierSchemeCodeUnspsc190501.50333471': __('identifierSchemeCodeUnspsc190501.50333471'),
	'identifierSchemeCodeUnspsc190501.50333472': __('identifierSchemeCodeUnspsc190501.50333472'),
	'identifierSchemeCodeUnspsc190501.50333473': __('identifierSchemeCodeUnspsc190501.50333473'),
	'identifierSchemeCodeUnspsc190501.50333474': __('identifierSchemeCodeUnspsc190501.50333474'),
	'identifierSchemeCodeUnspsc190501.50333475': __('identifierSchemeCodeUnspsc190501.50333475'),
	'identifierSchemeCodeUnspsc190501.50333476': __('identifierSchemeCodeUnspsc190501.50333476'),
	'identifierSchemeCodeUnspsc190501.50333477': __('identifierSchemeCodeUnspsc190501.50333477'),
	'identifierSchemeCodeUnspsc190501.50333478': __('identifierSchemeCodeUnspsc190501.50333478'),
	'identifierSchemeCodeUnspsc190501.50333479': __('identifierSchemeCodeUnspsc190501.50333479'),
	'identifierSchemeCodeUnspsc190501.50333480': __('identifierSchemeCodeUnspsc190501.50333480'),
	'identifierSchemeCodeUnspsc190501.50333481': __('identifierSchemeCodeUnspsc190501.50333481'),
	'identifierSchemeCodeUnspsc190501.50333482': __('identifierSchemeCodeUnspsc190501.50333482'),
	'identifierSchemeCodeUnspsc190501.50333483': __('identifierSchemeCodeUnspsc190501.50333483'),
	'identifierSchemeCodeUnspsc190501.50333484': __('identifierSchemeCodeUnspsc190501.50333484'),
	'identifierSchemeCodeUnspsc190501.50333485': __('identifierSchemeCodeUnspsc190501.50333485'),
	'identifierSchemeCodeUnspsc190501.50333486': __('identifierSchemeCodeUnspsc190501.50333486'),
	'identifierSchemeCodeUnspsc190501.50333487': __('identifierSchemeCodeUnspsc190501.50333487'),
	'identifierSchemeCodeUnspsc190501.50333488': __('identifierSchemeCodeUnspsc190501.50333488'),
	'identifierSchemeCodeUnspsc190501.50333489': __('identifierSchemeCodeUnspsc190501.50333489'),
	'identifierSchemeCodeUnspsc190501.50333490': __('identifierSchemeCodeUnspsc190501.50333490'),
	'identifierSchemeCodeUnspsc190501.50333491': __('identifierSchemeCodeUnspsc190501.50333491'),
	'identifierSchemeCodeUnspsc190501.50333492': __('identifierSchemeCodeUnspsc190501.50333492'),
	'identifierSchemeCodeUnspsc190501.50333493': __('identifierSchemeCodeUnspsc190501.50333493'),
	'identifierSchemeCodeUnspsc190501.50333494': __('identifierSchemeCodeUnspsc190501.50333494'),
	'identifierSchemeCodeUnspsc190501.50333495': __('identifierSchemeCodeUnspsc190501.50333495'),
	'identifierSchemeCodeUnspsc190501.50333496': __('identifierSchemeCodeUnspsc190501.50333496'),
	'identifierSchemeCodeUnspsc190501.50333500': __('identifierSchemeCodeUnspsc190501.50333500'),
	'identifierSchemeCodeUnspsc190501.50333501': __('identifierSchemeCodeUnspsc190501.50333501'),
	'identifierSchemeCodeUnspsc190501.50333502': __('identifierSchemeCodeUnspsc190501.50333502'),
	'identifierSchemeCodeUnspsc190501.50333503': __('identifierSchemeCodeUnspsc190501.50333503'),
	'identifierSchemeCodeUnspsc190501.50333504': __('identifierSchemeCodeUnspsc190501.50333504'),
	'identifierSchemeCodeUnspsc190501.50333505': __('identifierSchemeCodeUnspsc190501.50333505'),
	'identifierSchemeCodeUnspsc190501.50333506': __('identifierSchemeCodeUnspsc190501.50333506'),
	'identifierSchemeCodeUnspsc190501.50333600': __('identifierSchemeCodeUnspsc190501.50333600'),
	'identifierSchemeCodeUnspsc190501.50333601': __('identifierSchemeCodeUnspsc190501.50333601'),
	'identifierSchemeCodeUnspsc190501.50333602': __('identifierSchemeCodeUnspsc190501.50333602'),
	'identifierSchemeCodeUnspsc190501.50333603': __('identifierSchemeCodeUnspsc190501.50333603'),
	'identifierSchemeCodeUnspsc190501.50333604': __('identifierSchemeCodeUnspsc190501.50333604'),
	'identifierSchemeCodeUnspsc190501.50333605': __('identifierSchemeCodeUnspsc190501.50333605'),
	'identifierSchemeCodeUnspsc190501.50333606': __('identifierSchemeCodeUnspsc190501.50333606'),
	'identifierSchemeCodeUnspsc190501.50333607': __('identifierSchemeCodeUnspsc190501.50333607'),
	'identifierSchemeCodeUnspsc190501.50333608': __('identifierSchemeCodeUnspsc190501.50333608'),
	'identifierSchemeCodeUnspsc190501.50333609': __('identifierSchemeCodeUnspsc190501.50333609'),
	'identifierSchemeCodeUnspsc190501.50333610': __('identifierSchemeCodeUnspsc190501.50333610'),
	'identifierSchemeCodeUnspsc190501.50333611': __('identifierSchemeCodeUnspsc190501.50333611'),
	'identifierSchemeCodeUnspsc190501.50333612': __('identifierSchemeCodeUnspsc190501.50333612'),
	'identifierSchemeCodeUnspsc190501.50333613': __('identifierSchemeCodeUnspsc190501.50333613'),
	'identifierSchemeCodeUnspsc190501.50333614': __('identifierSchemeCodeUnspsc190501.50333614'),
	'identifierSchemeCodeUnspsc190501.50333615': __('identifierSchemeCodeUnspsc190501.50333615'),
	'identifierSchemeCodeUnspsc190501.50333616': __('identifierSchemeCodeUnspsc190501.50333616'),
	'identifierSchemeCodeUnspsc190501.50333617': __('identifierSchemeCodeUnspsc190501.50333617'),
	'identifierSchemeCodeUnspsc190501.50333618': __('identifierSchemeCodeUnspsc190501.50333618'),
	'identifierSchemeCodeUnspsc190501.50333619': __('identifierSchemeCodeUnspsc190501.50333619'),
	'identifierSchemeCodeUnspsc190501.50333620': __('identifierSchemeCodeUnspsc190501.50333620'),
	'identifierSchemeCodeUnspsc190501.50333621': __('identifierSchemeCodeUnspsc190501.50333621'),
	'identifierSchemeCodeUnspsc190501.50333622': __('identifierSchemeCodeUnspsc190501.50333622'),
	'identifierSchemeCodeUnspsc190501.50333623': __('identifierSchemeCodeUnspsc190501.50333623'),
	'identifierSchemeCodeUnspsc190501.50333624': __('identifierSchemeCodeUnspsc190501.50333624'),
	'identifierSchemeCodeUnspsc190501.50333625': __('identifierSchemeCodeUnspsc190501.50333625'),
	'identifierSchemeCodeUnspsc190501.50333626': __('identifierSchemeCodeUnspsc190501.50333626'),
	'identifierSchemeCodeUnspsc190501.50333627': __('identifierSchemeCodeUnspsc190501.50333627'),
	'identifierSchemeCodeUnspsc190501.50333628': __('identifierSchemeCodeUnspsc190501.50333628'),
	'identifierSchemeCodeUnspsc190501.50333629': __('identifierSchemeCodeUnspsc190501.50333629'),
	'identifierSchemeCodeUnspsc190501.50333630': __('identifierSchemeCodeUnspsc190501.50333630'),
	'identifierSchemeCodeUnspsc190501.50333631': __('identifierSchemeCodeUnspsc190501.50333631'),
	'identifierSchemeCodeUnspsc190501.50333632': __('identifierSchemeCodeUnspsc190501.50333632'),
	'identifierSchemeCodeUnspsc190501.50333633': __('identifierSchemeCodeUnspsc190501.50333633'),
	'identifierSchemeCodeUnspsc190501.50333634': __('identifierSchemeCodeUnspsc190501.50333634'),
	'identifierSchemeCodeUnspsc190501.50333635': __('identifierSchemeCodeUnspsc190501.50333635'),
	'identifierSchemeCodeUnspsc190501.50333636': __('identifierSchemeCodeUnspsc190501.50333636'),
	'identifierSchemeCodeUnspsc190501.50333637': __('identifierSchemeCodeUnspsc190501.50333637'),
	'identifierSchemeCodeUnspsc190501.50333638': __('identifierSchemeCodeUnspsc190501.50333638'),
	'identifierSchemeCodeUnspsc190501.50333639': __('identifierSchemeCodeUnspsc190501.50333639'),
	'identifierSchemeCodeUnspsc190501.50333640': __('identifierSchemeCodeUnspsc190501.50333640'),
	'identifierSchemeCodeUnspsc190501.50333641': __('identifierSchemeCodeUnspsc190501.50333641'),
	'identifierSchemeCodeUnspsc190501.50333642': __('identifierSchemeCodeUnspsc190501.50333642'),
	'identifierSchemeCodeUnspsc190501.50333643': __('identifierSchemeCodeUnspsc190501.50333643'),
	'identifierSchemeCodeUnspsc190501.50333644': __('identifierSchemeCodeUnspsc190501.50333644'),
	'identifierSchemeCodeUnspsc190501.50333645': __('identifierSchemeCodeUnspsc190501.50333645'),
	'identifierSchemeCodeUnspsc190501.50333646': __('identifierSchemeCodeUnspsc190501.50333646'),
	'identifierSchemeCodeUnspsc190501.50333647': __('identifierSchemeCodeUnspsc190501.50333647'),
	'identifierSchemeCodeUnspsc190501.50333648': __('identifierSchemeCodeUnspsc190501.50333648'),
	'identifierSchemeCodeUnspsc190501.50333649': __('identifierSchemeCodeUnspsc190501.50333649'),
	'identifierSchemeCodeUnspsc190501.50333650': __('identifierSchemeCodeUnspsc190501.50333650'),
	'identifierSchemeCodeUnspsc190501.50333651': __('identifierSchemeCodeUnspsc190501.50333651'),
	'identifierSchemeCodeUnspsc190501.50333652': __('identifierSchemeCodeUnspsc190501.50333652'),
	'identifierSchemeCodeUnspsc190501.50333653': __('identifierSchemeCodeUnspsc190501.50333653'),
	'identifierSchemeCodeUnspsc190501.50333654': __('identifierSchemeCodeUnspsc190501.50333654'),
	'identifierSchemeCodeUnspsc190501.50333655': __('identifierSchemeCodeUnspsc190501.50333655'),
	'identifierSchemeCodeUnspsc190501.50333656': __('identifierSchemeCodeUnspsc190501.50333656'),
	'identifierSchemeCodeUnspsc190501.50333657': __('identifierSchemeCodeUnspsc190501.50333657'),
	'identifierSchemeCodeUnspsc190501.50333658': __('identifierSchemeCodeUnspsc190501.50333658'),
	'identifierSchemeCodeUnspsc190501.50333659': __('identifierSchemeCodeUnspsc190501.50333659'),
	'identifierSchemeCodeUnspsc190501.50333660': __('identifierSchemeCodeUnspsc190501.50333660'),
	'identifierSchemeCodeUnspsc190501.50333661': __('identifierSchemeCodeUnspsc190501.50333661'),
	'identifierSchemeCodeUnspsc190501.50333662': __('identifierSchemeCodeUnspsc190501.50333662'),
	'identifierSchemeCodeUnspsc190501.50333663': __('identifierSchemeCodeUnspsc190501.50333663'),
	'identifierSchemeCodeUnspsc190501.50333664': __('identifierSchemeCodeUnspsc190501.50333664'),
	'identifierSchemeCodeUnspsc190501.50333665': __('identifierSchemeCodeUnspsc190501.50333665'),
	'identifierSchemeCodeUnspsc190501.50333700': __('identifierSchemeCodeUnspsc190501.50333700'),
	'identifierSchemeCodeUnspsc190501.50333701': __('identifierSchemeCodeUnspsc190501.50333701'),
	'identifierSchemeCodeUnspsc190501.50333702': __('identifierSchemeCodeUnspsc190501.50333702'),
	'identifierSchemeCodeUnspsc190501.50333703': __('identifierSchemeCodeUnspsc190501.50333703'),
	'identifierSchemeCodeUnspsc190501.50333704': __('identifierSchemeCodeUnspsc190501.50333704'),
	'identifierSchemeCodeUnspsc190501.50333800': __('identifierSchemeCodeUnspsc190501.50333800'),
	'identifierSchemeCodeUnspsc190501.50333801': __('identifierSchemeCodeUnspsc190501.50333801'),
	'identifierSchemeCodeUnspsc190501.50333802': __('identifierSchemeCodeUnspsc190501.50333802'),
	'identifierSchemeCodeUnspsc190501.50333803': __('identifierSchemeCodeUnspsc190501.50333803'),
	'identifierSchemeCodeUnspsc190501.50333804': __('identifierSchemeCodeUnspsc190501.50333804'),
	'identifierSchemeCodeUnspsc190501.50333805': __('identifierSchemeCodeUnspsc190501.50333805'),
	'identifierSchemeCodeUnspsc190501.50333900': __('identifierSchemeCodeUnspsc190501.50333900'),
	'identifierSchemeCodeUnspsc190501.50333901': __('identifierSchemeCodeUnspsc190501.50333901'),
	'identifierSchemeCodeUnspsc190501.50333902': __('identifierSchemeCodeUnspsc190501.50333902'),
	'identifierSchemeCodeUnspsc190501.50333903': __('identifierSchemeCodeUnspsc190501.50333903'),
	'identifierSchemeCodeUnspsc190501.50333904': __('identifierSchemeCodeUnspsc190501.50333904'),
	'identifierSchemeCodeUnspsc190501.50333905': __('identifierSchemeCodeUnspsc190501.50333905'),
	'identifierSchemeCodeUnspsc190501.50333906': __('identifierSchemeCodeUnspsc190501.50333906'),
	'identifierSchemeCodeUnspsc190501.50334000': __('identifierSchemeCodeUnspsc190501.50334000'),
	'identifierSchemeCodeUnspsc190501.50334001': __('identifierSchemeCodeUnspsc190501.50334001'),
	'identifierSchemeCodeUnspsc190501.50334002': __('identifierSchemeCodeUnspsc190501.50334002'),
	'identifierSchemeCodeUnspsc190501.50334003': __('identifierSchemeCodeUnspsc190501.50334003'),
	'identifierSchemeCodeUnspsc190501.50334004': __('identifierSchemeCodeUnspsc190501.50334004'),
	'identifierSchemeCodeUnspsc190501.50334005': __('identifierSchemeCodeUnspsc190501.50334005'),
	'identifierSchemeCodeUnspsc190501.50334006': __('identifierSchemeCodeUnspsc190501.50334006'),
	'identifierSchemeCodeUnspsc190501.50334100': __('identifierSchemeCodeUnspsc190501.50334100'),
	'identifierSchemeCodeUnspsc190501.50334101': __('identifierSchemeCodeUnspsc190501.50334101'),
	'identifierSchemeCodeUnspsc190501.50334102': __('identifierSchemeCodeUnspsc190501.50334102'),
	'identifierSchemeCodeUnspsc190501.50334103': __('identifierSchemeCodeUnspsc190501.50334103'),
	'identifierSchemeCodeUnspsc190501.50334104': __('identifierSchemeCodeUnspsc190501.50334104'),
	'identifierSchemeCodeUnspsc190501.50334105': __('identifierSchemeCodeUnspsc190501.50334105'),
	'identifierSchemeCodeUnspsc190501.50334106': __('identifierSchemeCodeUnspsc190501.50334106'),
	'identifierSchemeCodeUnspsc190501.50334107': __('identifierSchemeCodeUnspsc190501.50334107'),
	'identifierSchemeCodeUnspsc190501.50334108': __('identifierSchemeCodeUnspsc190501.50334108'),
	'identifierSchemeCodeUnspsc190501.50334200': __('identifierSchemeCodeUnspsc190501.50334200'),
	'identifierSchemeCodeUnspsc190501.50334201': __('identifierSchemeCodeUnspsc190501.50334201'),
	'identifierSchemeCodeUnspsc190501.50334202': __('identifierSchemeCodeUnspsc190501.50334202'),
	'identifierSchemeCodeUnspsc190501.50334203': __('identifierSchemeCodeUnspsc190501.50334203'),
	'identifierSchemeCodeUnspsc190501.50334204': __('identifierSchemeCodeUnspsc190501.50334204'),
	'identifierSchemeCodeUnspsc190501.50334205': __('identifierSchemeCodeUnspsc190501.50334205'),
	'identifierSchemeCodeUnspsc190501.50334206': __('identifierSchemeCodeUnspsc190501.50334206'),
	'identifierSchemeCodeUnspsc190501.50334207': __('identifierSchemeCodeUnspsc190501.50334207'),
	'identifierSchemeCodeUnspsc190501.50334208': __('identifierSchemeCodeUnspsc190501.50334208'),
	'identifierSchemeCodeUnspsc190501.50334300': __('identifierSchemeCodeUnspsc190501.50334300'),
	'identifierSchemeCodeUnspsc190501.50334301': __('identifierSchemeCodeUnspsc190501.50334301'),
	'identifierSchemeCodeUnspsc190501.50334302': __('identifierSchemeCodeUnspsc190501.50334302'),
	'identifierSchemeCodeUnspsc190501.50334303': __('identifierSchemeCodeUnspsc190501.50334303'),
	'identifierSchemeCodeUnspsc190501.50334304': __('identifierSchemeCodeUnspsc190501.50334304'),
	'identifierSchemeCodeUnspsc190501.50334305': __('identifierSchemeCodeUnspsc190501.50334305'),
	'identifierSchemeCodeUnspsc190501.50334306': __('identifierSchemeCodeUnspsc190501.50334306'),
	'identifierSchemeCodeUnspsc190501.50334307': __('identifierSchemeCodeUnspsc190501.50334307'),
	'identifierSchemeCodeUnspsc190501.50334308': __('identifierSchemeCodeUnspsc190501.50334308'),
	'identifierSchemeCodeUnspsc190501.50334309': __('identifierSchemeCodeUnspsc190501.50334309'),
	'identifierSchemeCodeUnspsc190501.50334310': __('identifierSchemeCodeUnspsc190501.50334310'),
	'identifierSchemeCodeUnspsc190501.50334311': __('identifierSchemeCodeUnspsc190501.50334311'),
	'identifierSchemeCodeUnspsc190501.50334312': __('identifierSchemeCodeUnspsc190501.50334312'),
	'identifierSchemeCodeUnspsc190501.50334313': __('identifierSchemeCodeUnspsc190501.50334313'),
	'identifierSchemeCodeUnspsc190501.50334400': __('identifierSchemeCodeUnspsc190501.50334400'),
	'identifierSchemeCodeUnspsc190501.50334401': __('identifierSchemeCodeUnspsc190501.50334401'),
	'identifierSchemeCodeUnspsc190501.50334402': __('identifierSchemeCodeUnspsc190501.50334402'),
	'identifierSchemeCodeUnspsc190501.50334403': __('identifierSchemeCodeUnspsc190501.50334403'),
	'identifierSchemeCodeUnspsc190501.50334404': __('identifierSchemeCodeUnspsc190501.50334404'),
	'identifierSchemeCodeUnspsc190501.50334405': __('identifierSchemeCodeUnspsc190501.50334405'),
	'identifierSchemeCodeUnspsc190501.50334406': __('identifierSchemeCodeUnspsc190501.50334406'),
	'identifierSchemeCodeUnspsc190501.50334407': __('identifierSchemeCodeUnspsc190501.50334407'),
	'identifierSchemeCodeUnspsc190501.50334408': __('identifierSchemeCodeUnspsc190501.50334408'),
	'identifierSchemeCodeUnspsc190501.50334409': __('identifierSchemeCodeUnspsc190501.50334409'),
	'identifierSchemeCodeUnspsc190501.50334410': __('identifierSchemeCodeUnspsc190501.50334410'),
	'identifierSchemeCodeUnspsc190501.50334411': __('identifierSchemeCodeUnspsc190501.50334411'),
	'identifierSchemeCodeUnspsc190501.50334412': __('identifierSchemeCodeUnspsc190501.50334412'),
	'identifierSchemeCodeUnspsc190501.50334413': __('identifierSchemeCodeUnspsc190501.50334413'),
	'identifierSchemeCodeUnspsc190501.50334414': __('identifierSchemeCodeUnspsc190501.50334414'),
	'identifierSchemeCodeUnspsc190501.50334415': __('identifierSchemeCodeUnspsc190501.50334415'),
	'identifierSchemeCodeUnspsc190501.50334416': __('identifierSchemeCodeUnspsc190501.50334416'),
	'identifierSchemeCodeUnspsc190501.50334417': __('identifierSchemeCodeUnspsc190501.50334417'),
	'identifierSchemeCodeUnspsc190501.50334418': __('identifierSchemeCodeUnspsc190501.50334418'),
	'identifierSchemeCodeUnspsc190501.50334419': __('identifierSchemeCodeUnspsc190501.50334419'),
	'identifierSchemeCodeUnspsc190501.50334420': __('identifierSchemeCodeUnspsc190501.50334420'),
	'identifierSchemeCodeUnspsc190501.50334421': __('identifierSchemeCodeUnspsc190501.50334421'),
	'identifierSchemeCodeUnspsc190501.50334422': __('identifierSchemeCodeUnspsc190501.50334422'),
	'identifierSchemeCodeUnspsc190501.50334423': __('identifierSchemeCodeUnspsc190501.50334423'),
	'identifierSchemeCodeUnspsc190501.50334424': __('identifierSchemeCodeUnspsc190501.50334424'),
	'identifierSchemeCodeUnspsc190501.50334425': __('identifierSchemeCodeUnspsc190501.50334425'),
	'identifierSchemeCodeUnspsc190501.50334426': __('identifierSchemeCodeUnspsc190501.50334426'),
	'identifierSchemeCodeUnspsc190501.50334427': __('identifierSchemeCodeUnspsc190501.50334427'),
	'identifierSchemeCodeUnspsc190501.50334428': __('identifierSchemeCodeUnspsc190501.50334428'),
	'identifierSchemeCodeUnspsc190501.50334429': __('identifierSchemeCodeUnspsc190501.50334429'),
	'identifierSchemeCodeUnspsc190501.50334430': __('identifierSchemeCodeUnspsc190501.50334430'),
	'identifierSchemeCodeUnspsc190501.50334431': __('identifierSchemeCodeUnspsc190501.50334431'),
	'identifierSchemeCodeUnspsc190501.50334432': __('identifierSchemeCodeUnspsc190501.50334432'),
	'identifierSchemeCodeUnspsc190501.50334433': __('identifierSchemeCodeUnspsc190501.50334433'),
	'identifierSchemeCodeUnspsc190501.50334434': __('identifierSchemeCodeUnspsc190501.50334434'),
	'identifierSchemeCodeUnspsc190501.50334435': __('identifierSchemeCodeUnspsc190501.50334435'),
	'identifierSchemeCodeUnspsc190501.50334436': __('identifierSchemeCodeUnspsc190501.50334436'),
	'identifierSchemeCodeUnspsc190501.50334437': __('identifierSchemeCodeUnspsc190501.50334437'),
	'identifierSchemeCodeUnspsc190501.50334438': __('identifierSchemeCodeUnspsc190501.50334438'),
	'identifierSchemeCodeUnspsc190501.50334439': __('identifierSchemeCodeUnspsc190501.50334439'),
	'identifierSchemeCodeUnspsc190501.50334440': __('identifierSchemeCodeUnspsc190501.50334440'),
	'identifierSchemeCodeUnspsc190501.50334441': __('identifierSchemeCodeUnspsc190501.50334441'),
	'identifierSchemeCodeUnspsc190501.50334442': __('identifierSchemeCodeUnspsc190501.50334442'),
	'identifierSchemeCodeUnspsc190501.50334443': __('identifierSchemeCodeUnspsc190501.50334443'),
	'identifierSchemeCodeUnspsc190501.50334444': __('identifierSchemeCodeUnspsc190501.50334444'),
	'identifierSchemeCodeUnspsc190501.50334445': __('identifierSchemeCodeUnspsc190501.50334445'),
	'identifierSchemeCodeUnspsc190501.50334446': __('identifierSchemeCodeUnspsc190501.50334446'),
	'identifierSchemeCodeUnspsc190501.50334500': __('identifierSchemeCodeUnspsc190501.50334500'),
	'identifierSchemeCodeUnspsc190501.50334501': __('identifierSchemeCodeUnspsc190501.50334501'),
	'identifierSchemeCodeUnspsc190501.50334502': __('identifierSchemeCodeUnspsc190501.50334502'),
	'identifierSchemeCodeUnspsc190501.50334503': __('identifierSchemeCodeUnspsc190501.50334503'),
	'identifierSchemeCodeUnspsc190501.50334504': __('identifierSchemeCodeUnspsc190501.50334504'),
	'identifierSchemeCodeUnspsc190501.50334505': __('identifierSchemeCodeUnspsc190501.50334505'),
	'identifierSchemeCodeUnspsc190501.50334506': __('identifierSchemeCodeUnspsc190501.50334506'),
	'identifierSchemeCodeUnspsc190501.50334507': __('identifierSchemeCodeUnspsc190501.50334507'),
	'identifierSchemeCodeUnspsc190501.50334508': __('identifierSchemeCodeUnspsc190501.50334508'),
	'identifierSchemeCodeUnspsc190501.50334509': __('identifierSchemeCodeUnspsc190501.50334509'),
	'identifierSchemeCodeUnspsc190501.50334510': __('identifierSchemeCodeUnspsc190501.50334510'),
	'identifierSchemeCodeUnspsc190501.50334511': __('identifierSchemeCodeUnspsc190501.50334511'),
	'identifierSchemeCodeUnspsc190501.50334512': __('identifierSchemeCodeUnspsc190501.50334512'),
	'identifierSchemeCodeUnspsc190501.50334513': __('identifierSchemeCodeUnspsc190501.50334513'),
	'identifierSchemeCodeUnspsc190501.50334514': __('identifierSchemeCodeUnspsc190501.50334514'),
	'identifierSchemeCodeUnspsc190501.50334515': __('identifierSchemeCodeUnspsc190501.50334515'),
	'identifierSchemeCodeUnspsc190501.50334516': __('identifierSchemeCodeUnspsc190501.50334516'),
	'identifierSchemeCodeUnspsc190501.50334517': __('identifierSchemeCodeUnspsc190501.50334517'),
	'identifierSchemeCodeUnspsc190501.50334518': __('identifierSchemeCodeUnspsc190501.50334518'),
	'identifierSchemeCodeUnspsc190501.50334519': __('identifierSchemeCodeUnspsc190501.50334519'),
	'identifierSchemeCodeUnspsc190501.50334520': __('identifierSchemeCodeUnspsc190501.50334520'),
	'identifierSchemeCodeUnspsc190501.50334521': __('identifierSchemeCodeUnspsc190501.50334521'),
	'identifierSchemeCodeUnspsc190501.50334522': __('identifierSchemeCodeUnspsc190501.50334522'),
	'identifierSchemeCodeUnspsc190501.50334523': __('identifierSchemeCodeUnspsc190501.50334523'),
	'identifierSchemeCodeUnspsc190501.50334524': __('identifierSchemeCodeUnspsc190501.50334524'),
	'identifierSchemeCodeUnspsc190501.50334525': __('identifierSchemeCodeUnspsc190501.50334525'),
	'identifierSchemeCodeUnspsc190501.50334600': __('identifierSchemeCodeUnspsc190501.50334600'),
	'identifierSchemeCodeUnspsc190501.50334601': __('identifierSchemeCodeUnspsc190501.50334601'),
	'identifierSchemeCodeUnspsc190501.50334602': __('identifierSchemeCodeUnspsc190501.50334602'),
	'identifierSchemeCodeUnspsc190501.50334603': __('identifierSchemeCodeUnspsc190501.50334603'),
	'identifierSchemeCodeUnspsc190501.50334604': __('identifierSchemeCodeUnspsc190501.50334604'),
	'identifierSchemeCodeUnspsc190501.50334605': __('identifierSchemeCodeUnspsc190501.50334605'),
	'identifierSchemeCodeUnspsc190501.50334606': __('identifierSchemeCodeUnspsc190501.50334606'),
	'identifierSchemeCodeUnspsc190501.50334607': __('identifierSchemeCodeUnspsc190501.50334607'),
	'identifierSchemeCodeUnspsc190501.50334608': __('identifierSchemeCodeUnspsc190501.50334608'),
	'identifierSchemeCodeUnspsc190501.50334609': __('identifierSchemeCodeUnspsc190501.50334609'),
	'identifierSchemeCodeUnspsc190501.50334610': __('identifierSchemeCodeUnspsc190501.50334610'),
	'identifierSchemeCodeUnspsc190501.50334611': __('identifierSchemeCodeUnspsc190501.50334611'),
	'identifierSchemeCodeUnspsc190501.50334612': __('identifierSchemeCodeUnspsc190501.50334612'),
	'identifierSchemeCodeUnspsc190501.50334613': __('identifierSchemeCodeUnspsc190501.50334613'),
	'identifierSchemeCodeUnspsc190501.50334614': __('identifierSchemeCodeUnspsc190501.50334614'),
	'identifierSchemeCodeUnspsc190501.50334615': __('identifierSchemeCodeUnspsc190501.50334615'),
	'identifierSchemeCodeUnspsc190501.50334616': __('identifierSchemeCodeUnspsc190501.50334616'),
	'identifierSchemeCodeUnspsc190501.50334617': __('identifierSchemeCodeUnspsc190501.50334617'),
	'identifierSchemeCodeUnspsc190501.50334618': __('identifierSchemeCodeUnspsc190501.50334618'),
	'identifierSchemeCodeUnspsc190501.50334619': __('identifierSchemeCodeUnspsc190501.50334619'),
	'identifierSchemeCodeUnspsc190501.50334620': __('identifierSchemeCodeUnspsc190501.50334620'),
	'identifierSchemeCodeUnspsc190501.50334621': __('identifierSchemeCodeUnspsc190501.50334621'),
	'identifierSchemeCodeUnspsc190501.50334622': __('identifierSchemeCodeUnspsc190501.50334622'),
	'identifierSchemeCodeUnspsc190501.50334623': __('identifierSchemeCodeUnspsc190501.50334623'),
	'identifierSchemeCodeUnspsc190501.50334624': __('identifierSchemeCodeUnspsc190501.50334624'),
	'identifierSchemeCodeUnspsc190501.50334625': __('identifierSchemeCodeUnspsc190501.50334625'),
	'identifierSchemeCodeUnspsc190501.50334626': __('identifierSchemeCodeUnspsc190501.50334626'),
	'identifierSchemeCodeUnspsc190501.50334627': __('identifierSchemeCodeUnspsc190501.50334627'),
	'identifierSchemeCodeUnspsc190501.50334628': __('identifierSchemeCodeUnspsc190501.50334628'),
	'identifierSchemeCodeUnspsc190501.50334629': __('identifierSchemeCodeUnspsc190501.50334629'),
	'identifierSchemeCodeUnspsc190501.50334630': __('identifierSchemeCodeUnspsc190501.50334630'),
	'identifierSchemeCodeUnspsc190501.50334631': __('identifierSchemeCodeUnspsc190501.50334631'),
	'identifierSchemeCodeUnspsc190501.50334632': __('identifierSchemeCodeUnspsc190501.50334632'),
	'identifierSchemeCodeUnspsc190501.50334633': __('identifierSchemeCodeUnspsc190501.50334633'),
	'identifierSchemeCodeUnspsc190501.50334634': __('identifierSchemeCodeUnspsc190501.50334634'),
	'identifierSchemeCodeUnspsc190501.50334635': __('identifierSchemeCodeUnspsc190501.50334635'),
	'identifierSchemeCodeUnspsc190501.50334636': __('identifierSchemeCodeUnspsc190501.50334636'),
	'identifierSchemeCodeUnspsc190501.50334637': __('identifierSchemeCodeUnspsc190501.50334637'),
	'identifierSchemeCodeUnspsc190501.50334638': __('identifierSchemeCodeUnspsc190501.50334638'),
	'identifierSchemeCodeUnspsc190501.50334639': __('identifierSchemeCodeUnspsc190501.50334639'),
	'identifierSchemeCodeUnspsc190501.50334640': __('identifierSchemeCodeUnspsc190501.50334640'),
	'identifierSchemeCodeUnspsc190501.50334641': __('identifierSchemeCodeUnspsc190501.50334641'),
	'identifierSchemeCodeUnspsc190501.50334642': __('identifierSchemeCodeUnspsc190501.50334642'),
	'identifierSchemeCodeUnspsc190501.50334643': __('identifierSchemeCodeUnspsc190501.50334643'),
	'identifierSchemeCodeUnspsc190501.50334644': __('identifierSchemeCodeUnspsc190501.50334644'),
	'identifierSchemeCodeUnspsc190501.50334645': __('identifierSchemeCodeUnspsc190501.50334645'),
	'identifierSchemeCodeUnspsc190501.50334646': __('identifierSchemeCodeUnspsc190501.50334646'),
	'identifierSchemeCodeUnspsc190501.50334647': __('identifierSchemeCodeUnspsc190501.50334647'),
	'identifierSchemeCodeUnspsc190501.50334648': __('identifierSchemeCodeUnspsc190501.50334648'),
	'identifierSchemeCodeUnspsc190501.50334649': __('identifierSchemeCodeUnspsc190501.50334649'),
	'identifierSchemeCodeUnspsc190501.50334650': __('identifierSchemeCodeUnspsc190501.50334650'),
	'identifierSchemeCodeUnspsc190501.50334651': __('identifierSchemeCodeUnspsc190501.50334651'),
	'identifierSchemeCodeUnspsc190501.50334652': __('identifierSchemeCodeUnspsc190501.50334652'),
	'identifierSchemeCodeUnspsc190501.50334653': __('identifierSchemeCodeUnspsc190501.50334653'),
	'identifierSchemeCodeUnspsc190501.50334654': __('identifierSchemeCodeUnspsc190501.50334654'),
	'identifierSchemeCodeUnspsc190501.50334655': __('identifierSchemeCodeUnspsc190501.50334655'),
	'identifierSchemeCodeUnspsc190501.50334656': __('identifierSchemeCodeUnspsc190501.50334656'),
	'identifierSchemeCodeUnspsc190501.50334657': __('identifierSchemeCodeUnspsc190501.50334657'),
	'identifierSchemeCodeUnspsc190501.50334658': __('identifierSchemeCodeUnspsc190501.50334658'),
	'identifierSchemeCodeUnspsc190501.50334659': __('identifierSchemeCodeUnspsc190501.50334659'),
	'identifierSchemeCodeUnspsc190501.50334660': __('identifierSchemeCodeUnspsc190501.50334660'),
	'identifierSchemeCodeUnspsc190501.50334661': __('identifierSchemeCodeUnspsc190501.50334661'),
	'identifierSchemeCodeUnspsc190501.50334700': __('identifierSchemeCodeUnspsc190501.50334700'),
	'identifierSchemeCodeUnspsc190501.50334701': __('identifierSchemeCodeUnspsc190501.50334701'),
	'identifierSchemeCodeUnspsc190501.50334702': __('identifierSchemeCodeUnspsc190501.50334702'),
	'identifierSchemeCodeUnspsc190501.50334800': __('identifierSchemeCodeUnspsc190501.50334800'),
	'identifierSchemeCodeUnspsc190501.50334801': __('identifierSchemeCodeUnspsc190501.50334801'),
	'identifierSchemeCodeUnspsc190501.50334802': __('identifierSchemeCodeUnspsc190501.50334802'),
	'identifierSchemeCodeUnspsc190501.50334900': __('identifierSchemeCodeUnspsc190501.50334900'),
	'identifierSchemeCodeUnspsc190501.50334901': __('identifierSchemeCodeUnspsc190501.50334901'),
	'identifierSchemeCodeUnspsc190501.50334902': __('identifierSchemeCodeUnspsc190501.50334902'),
	'identifierSchemeCodeUnspsc190501.50334903': __('identifierSchemeCodeUnspsc190501.50334903'),
	'identifierSchemeCodeUnspsc190501.50334904': __('identifierSchemeCodeUnspsc190501.50334904'),
	'identifierSchemeCodeUnspsc190501.50334905': __('identifierSchemeCodeUnspsc190501.50334905'),
	'identifierSchemeCodeUnspsc190501.50334906': __('identifierSchemeCodeUnspsc190501.50334906'),
	'identifierSchemeCodeUnspsc190501.50334907': __('identifierSchemeCodeUnspsc190501.50334907'),
	'identifierSchemeCodeUnspsc190501.50334908': __('identifierSchemeCodeUnspsc190501.50334908'),
	'identifierSchemeCodeUnspsc190501.50334909': __('identifierSchemeCodeUnspsc190501.50334909'),
	'identifierSchemeCodeUnspsc190501.50334910': __('identifierSchemeCodeUnspsc190501.50334910'),
	'identifierSchemeCodeUnspsc190501.50334911': __('identifierSchemeCodeUnspsc190501.50334911'),
	'identifierSchemeCodeUnspsc190501.50334912': __('identifierSchemeCodeUnspsc190501.50334912'),
	'identifierSchemeCodeUnspsc190501.50334913': __('identifierSchemeCodeUnspsc190501.50334913'),
	'identifierSchemeCodeUnspsc190501.50334914': __('identifierSchemeCodeUnspsc190501.50334914'),
	'identifierSchemeCodeUnspsc190501.50334915': __('identifierSchemeCodeUnspsc190501.50334915'),
	'identifierSchemeCodeUnspsc190501.50334916': __('identifierSchemeCodeUnspsc190501.50334916'),
	'identifierSchemeCodeUnspsc190501.50334917': __('identifierSchemeCodeUnspsc190501.50334917'),
	'identifierSchemeCodeUnspsc190501.50334918': __('identifierSchemeCodeUnspsc190501.50334918'),
	'identifierSchemeCodeUnspsc190501.50334919': __('identifierSchemeCodeUnspsc190501.50334919'),
	'identifierSchemeCodeUnspsc190501.50334920': __('identifierSchemeCodeUnspsc190501.50334920'),
	'identifierSchemeCodeUnspsc190501.50334921': __('identifierSchemeCodeUnspsc190501.50334921'),
	'identifierSchemeCodeUnspsc190501.50334922': __('identifierSchemeCodeUnspsc190501.50334922'),
	'identifierSchemeCodeUnspsc190501.50334923': __('identifierSchemeCodeUnspsc190501.50334923'),
	'identifierSchemeCodeUnspsc190501.50334924': __('identifierSchemeCodeUnspsc190501.50334924'),
	'identifierSchemeCodeUnspsc190501.50334925': __('identifierSchemeCodeUnspsc190501.50334925'),
	'identifierSchemeCodeUnspsc190501.50334926': __('identifierSchemeCodeUnspsc190501.50334926'),
	'identifierSchemeCodeUnspsc190501.50334927': __('identifierSchemeCodeUnspsc190501.50334927'),
	'identifierSchemeCodeUnspsc190501.50334928': __('identifierSchemeCodeUnspsc190501.50334928'),
	'identifierSchemeCodeUnspsc190501.50334929': __('identifierSchemeCodeUnspsc190501.50334929'),
	'identifierSchemeCodeUnspsc190501.50334930': __('identifierSchemeCodeUnspsc190501.50334930'),
	'identifierSchemeCodeUnspsc190501.50334931': __('identifierSchemeCodeUnspsc190501.50334931'),
	'identifierSchemeCodeUnspsc190501.50334932': __('identifierSchemeCodeUnspsc190501.50334932'),
	'identifierSchemeCodeUnspsc190501.50334933': __('identifierSchemeCodeUnspsc190501.50334933'),
	'identifierSchemeCodeUnspsc190501.50334934': __('identifierSchemeCodeUnspsc190501.50334934'),
	'identifierSchemeCodeUnspsc190501.50334935': __('identifierSchemeCodeUnspsc190501.50334935'),
	'identifierSchemeCodeUnspsc190501.50334936': __('identifierSchemeCodeUnspsc190501.50334936'),
	'identifierSchemeCodeUnspsc190501.50334937': __('identifierSchemeCodeUnspsc190501.50334937'),
	'identifierSchemeCodeUnspsc190501.50334938': __('identifierSchemeCodeUnspsc190501.50334938'),
	'identifierSchemeCodeUnspsc190501.50334939': __('identifierSchemeCodeUnspsc190501.50334939'),
	'identifierSchemeCodeUnspsc190501.50334940': __('identifierSchemeCodeUnspsc190501.50334940'),
	'identifierSchemeCodeUnspsc190501.50334941': __('identifierSchemeCodeUnspsc190501.50334941'),
	'identifierSchemeCodeUnspsc190501.50334942': __('identifierSchemeCodeUnspsc190501.50334942'),
	'identifierSchemeCodeUnspsc190501.50334943': __('identifierSchemeCodeUnspsc190501.50334943'),
	'identifierSchemeCodeUnspsc190501.50334944': __('identifierSchemeCodeUnspsc190501.50334944'),
	'identifierSchemeCodeUnspsc190501.50334945': __('identifierSchemeCodeUnspsc190501.50334945'),
	'identifierSchemeCodeUnspsc190501.50334946': __('identifierSchemeCodeUnspsc190501.50334946'),
	'identifierSchemeCodeUnspsc190501.50334947': __('identifierSchemeCodeUnspsc190501.50334947'),
	'identifierSchemeCodeUnspsc190501.50334948': __('identifierSchemeCodeUnspsc190501.50334948'),
	'identifierSchemeCodeUnspsc190501.50334949': __('identifierSchemeCodeUnspsc190501.50334949'),
	'identifierSchemeCodeUnspsc190501.50334950': __('identifierSchemeCodeUnspsc190501.50334950'),
	'identifierSchemeCodeUnspsc190501.50334951': __('identifierSchemeCodeUnspsc190501.50334951'),
	'identifierSchemeCodeUnspsc190501.50334952': __('identifierSchemeCodeUnspsc190501.50334952'),
	'identifierSchemeCodeUnspsc190501.50334953': __('identifierSchemeCodeUnspsc190501.50334953'),
	'identifierSchemeCodeUnspsc190501.50334954': __('identifierSchemeCodeUnspsc190501.50334954'),
	'identifierSchemeCodeUnspsc190501.50334955': __('identifierSchemeCodeUnspsc190501.50334955'),
	'identifierSchemeCodeUnspsc190501.50334956': __('identifierSchemeCodeUnspsc190501.50334956'),
	'identifierSchemeCodeUnspsc190501.50334957': __('identifierSchemeCodeUnspsc190501.50334957'),
	'identifierSchemeCodeUnspsc190501.50335000': __('identifierSchemeCodeUnspsc190501.50335000'),
	'identifierSchemeCodeUnspsc190501.50335001': __('identifierSchemeCodeUnspsc190501.50335001'),
	'identifierSchemeCodeUnspsc190501.50335002': __('identifierSchemeCodeUnspsc190501.50335002'),
	'identifierSchemeCodeUnspsc190501.50335003': __('identifierSchemeCodeUnspsc190501.50335003'),
	'identifierSchemeCodeUnspsc190501.50335004': __('identifierSchemeCodeUnspsc190501.50335004'),
	'identifierSchemeCodeUnspsc190501.50335005': __('identifierSchemeCodeUnspsc190501.50335005'),
	'identifierSchemeCodeUnspsc190501.50335006': __('identifierSchemeCodeUnspsc190501.50335006'),
	'identifierSchemeCodeUnspsc190501.50335007': __('identifierSchemeCodeUnspsc190501.50335007'),
	'identifierSchemeCodeUnspsc190501.50335008': __('identifierSchemeCodeUnspsc190501.50335008'),
	'identifierSchemeCodeUnspsc190501.50335009': __('identifierSchemeCodeUnspsc190501.50335009'),
	'identifierSchemeCodeUnspsc190501.50335010': __('identifierSchemeCodeUnspsc190501.50335010'),
	'identifierSchemeCodeUnspsc190501.50335011': __('identifierSchemeCodeUnspsc190501.50335011'),
	'identifierSchemeCodeUnspsc190501.50335012': __('identifierSchemeCodeUnspsc190501.50335012'),
	'identifierSchemeCodeUnspsc190501.50335013': __('identifierSchemeCodeUnspsc190501.50335013'),
	'identifierSchemeCodeUnspsc190501.50335014': __('identifierSchemeCodeUnspsc190501.50335014'),
	'identifierSchemeCodeUnspsc190501.50335015': __('identifierSchemeCodeUnspsc190501.50335015'),
	'identifierSchemeCodeUnspsc190501.50335016': __('identifierSchemeCodeUnspsc190501.50335016'),
	'identifierSchemeCodeUnspsc190501.50335017': __('identifierSchemeCodeUnspsc190501.50335017'),
	'identifierSchemeCodeUnspsc190501.50335018': __('identifierSchemeCodeUnspsc190501.50335018'),
	'identifierSchemeCodeUnspsc190501.50335019': __('identifierSchemeCodeUnspsc190501.50335019'),
	'identifierSchemeCodeUnspsc190501.50335020': __('identifierSchemeCodeUnspsc190501.50335020'),
	'identifierSchemeCodeUnspsc190501.50335021': __('identifierSchemeCodeUnspsc190501.50335021'),
	'identifierSchemeCodeUnspsc190501.50335022': __('identifierSchemeCodeUnspsc190501.50335022'),
	'identifierSchemeCodeUnspsc190501.50335023': __('identifierSchemeCodeUnspsc190501.50335023'),
	'identifierSchemeCodeUnspsc190501.50335024': __('identifierSchemeCodeUnspsc190501.50335024'),
	'identifierSchemeCodeUnspsc190501.50335025': __('identifierSchemeCodeUnspsc190501.50335025'),
	'identifierSchemeCodeUnspsc190501.50335026': __('identifierSchemeCodeUnspsc190501.50335026'),
	'identifierSchemeCodeUnspsc190501.50335027': __('identifierSchemeCodeUnspsc190501.50335027'),
	'identifierSchemeCodeUnspsc190501.50335028': __('identifierSchemeCodeUnspsc190501.50335028'),
	'identifierSchemeCodeUnspsc190501.50335029': __('identifierSchemeCodeUnspsc190501.50335029'),
	'identifierSchemeCodeUnspsc190501.50335030': __('identifierSchemeCodeUnspsc190501.50335030'),
	'identifierSchemeCodeUnspsc190501.50335031': __('identifierSchemeCodeUnspsc190501.50335031'),
	'identifierSchemeCodeUnspsc190501.50335032': __('identifierSchemeCodeUnspsc190501.50335032'),
	'identifierSchemeCodeUnspsc190501.50335033': __('identifierSchemeCodeUnspsc190501.50335033'),
	'identifierSchemeCodeUnspsc190501.50335034': __('identifierSchemeCodeUnspsc190501.50335034'),
	'identifierSchemeCodeUnspsc190501.50335035': __('identifierSchemeCodeUnspsc190501.50335035'),
	'identifierSchemeCodeUnspsc190501.50335036': __('identifierSchemeCodeUnspsc190501.50335036'),
	'identifierSchemeCodeUnspsc190501.50335037': __('identifierSchemeCodeUnspsc190501.50335037'),
	'identifierSchemeCodeUnspsc190501.50335038': __('identifierSchemeCodeUnspsc190501.50335038'),
	'identifierSchemeCodeUnspsc190501.50335039': __('identifierSchemeCodeUnspsc190501.50335039'),
	'identifierSchemeCodeUnspsc190501.50335040': __('identifierSchemeCodeUnspsc190501.50335040'),
	'identifierSchemeCodeUnspsc190501.50335041': __('identifierSchemeCodeUnspsc190501.50335041'),
	'identifierSchemeCodeUnspsc190501.50335042': __('identifierSchemeCodeUnspsc190501.50335042'),
	'identifierSchemeCodeUnspsc190501.50335043': __('identifierSchemeCodeUnspsc190501.50335043'),
	'identifierSchemeCodeUnspsc190501.50335044': __('identifierSchemeCodeUnspsc190501.50335044'),
	'identifierSchemeCodeUnspsc190501.50335100': __('identifierSchemeCodeUnspsc190501.50335100'),
	'identifierSchemeCodeUnspsc190501.50335101': __('identifierSchemeCodeUnspsc190501.50335101'),
	'identifierSchemeCodeUnspsc190501.50335102': __('identifierSchemeCodeUnspsc190501.50335102'),
	'identifierSchemeCodeUnspsc190501.50335103': __('identifierSchemeCodeUnspsc190501.50335103'),
	'identifierSchemeCodeUnspsc190501.50335104': __('identifierSchemeCodeUnspsc190501.50335104'),
	'identifierSchemeCodeUnspsc190501.50335105': __('identifierSchemeCodeUnspsc190501.50335105'),
	'identifierSchemeCodeUnspsc190501.50335106': __('identifierSchemeCodeUnspsc190501.50335106'),
	'identifierSchemeCodeUnspsc190501.50335200': __('identifierSchemeCodeUnspsc190501.50335200'),
	'identifierSchemeCodeUnspsc190501.50335201': __('identifierSchemeCodeUnspsc190501.50335201'),
	'identifierSchemeCodeUnspsc190501.50335202': __('identifierSchemeCodeUnspsc190501.50335202'),
	'identifierSchemeCodeUnspsc190501.50335203': __('identifierSchemeCodeUnspsc190501.50335203'),
	'identifierSchemeCodeUnspsc190501.50335204': __('identifierSchemeCodeUnspsc190501.50335204'),
	'identifierSchemeCodeUnspsc190501.50335205': __('identifierSchemeCodeUnspsc190501.50335205'),
	'identifierSchemeCodeUnspsc190501.50335206': __('identifierSchemeCodeUnspsc190501.50335206'),
	'identifierSchemeCodeUnspsc190501.50335207': __('identifierSchemeCodeUnspsc190501.50335207'),
	'identifierSchemeCodeUnspsc190501.50335208': __('identifierSchemeCodeUnspsc190501.50335208'),
	'identifierSchemeCodeUnspsc190501.50335209': __('identifierSchemeCodeUnspsc190501.50335209'),
	'identifierSchemeCodeUnspsc190501.50335210': __('identifierSchemeCodeUnspsc190501.50335210'),
	'identifierSchemeCodeUnspsc190501.50335300': __('identifierSchemeCodeUnspsc190501.50335300'),
	'identifierSchemeCodeUnspsc190501.50335301': __('identifierSchemeCodeUnspsc190501.50335301'),
	'identifierSchemeCodeUnspsc190501.50335302': __('identifierSchemeCodeUnspsc190501.50335302'),
	'identifierSchemeCodeUnspsc190501.50335303': __('identifierSchemeCodeUnspsc190501.50335303'),
	'identifierSchemeCodeUnspsc190501.50335304': __('identifierSchemeCodeUnspsc190501.50335304'),
	'identifierSchemeCodeUnspsc190501.50335305': __('identifierSchemeCodeUnspsc190501.50335305'),
	'identifierSchemeCodeUnspsc190501.50335306': __('identifierSchemeCodeUnspsc190501.50335306'),
	'identifierSchemeCodeUnspsc190501.50335307': __('identifierSchemeCodeUnspsc190501.50335307'),
	'identifierSchemeCodeUnspsc190501.50335308': __('identifierSchemeCodeUnspsc190501.50335308'),
	'identifierSchemeCodeUnspsc190501.50335309': __('identifierSchemeCodeUnspsc190501.50335309'),
	'identifierSchemeCodeUnspsc190501.50335310': __('identifierSchemeCodeUnspsc190501.50335310'),
	'identifierSchemeCodeUnspsc190501.50335311': __('identifierSchemeCodeUnspsc190501.50335311'),
	'identifierSchemeCodeUnspsc190501.50335312': __('identifierSchemeCodeUnspsc190501.50335312'),
	'identifierSchemeCodeUnspsc190501.50335313': __('identifierSchemeCodeUnspsc190501.50335313'),
	'identifierSchemeCodeUnspsc190501.50335314': __('identifierSchemeCodeUnspsc190501.50335314'),
	'identifierSchemeCodeUnspsc190501.50335315': __('identifierSchemeCodeUnspsc190501.50335315'),
	'identifierSchemeCodeUnspsc190501.50335316': __('identifierSchemeCodeUnspsc190501.50335316'),
	'identifierSchemeCodeUnspsc190501.50335317': __('identifierSchemeCodeUnspsc190501.50335317'),
	'identifierSchemeCodeUnspsc190501.50335318': __('identifierSchemeCodeUnspsc190501.50335318'),
	'identifierSchemeCodeUnspsc190501.50335319': __('identifierSchemeCodeUnspsc190501.50335319'),
	'identifierSchemeCodeUnspsc190501.50335320': __('identifierSchemeCodeUnspsc190501.50335320'),
	'identifierSchemeCodeUnspsc190501.50335321': __('identifierSchemeCodeUnspsc190501.50335321'),
	'identifierSchemeCodeUnspsc190501.50335322': __('identifierSchemeCodeUnspsc190501.50335322'),
	'identifierSchemeCodeUnspsc190501.50335323': __('identifierSchemeCodeUnspsc190501.50335323'),
	'identifierSchemeCodeUnspsc190501.50335324': __('identifierSchemeCodeUnspsc190501.50335324'),
	'identifierSchemeCodeUnspsc190501.50335325': __('identifierSchemeCodeUnspsc190501.50335325'),
	'identifierSchemeCodeUnspsc190501.50335326': __('identifierSchemeCodeUnspsc190501.50335326'),
	'identifierSchemeCodeUnspsc190501.50335327': __('identifierSchemeCodeUnspsc190501.50335327'),
	'identifierSchemeCodeUnspsc190501.50335328': __('identifierSchemeCodeUnspsc190501.50335328'),
	'identifierSchemeCodeUnspsc190501.50335329': __('identifierSchemeCodeUnspsc190501.50335329'),
	'identifierSchemeCodeUnspsc190501.50335330': __('identifierSchemeCodeUnspsc190501.50335330'),
	'identifierSchemeCodeUnspsc190501.50335331': __('identifierSchemeCodeUnspsc190501.50335331'),
	'identifierSchemeCodeUnspsc190501.50335332': __('identifierSchemeCodeUnspsc190501.50335332'),
	'identifierSchemeCodeUnspsc190501.50335333': __('identifierSchemeCodeUnspsc190501.50335333'),
	'identifierSchemeCodeUnspsc190501.50335334': __('identifierSchemeCodeUnspsc190501.50335334'),
	'identifierSchemeCodeUnspsc190501.50335335': __('identifierSchemeCodeUnspsc190501.50335335'),
	'identifierSchemeCodeUnspsc190501.50335336': __('identifierSchemeCodeUnspsc190501.50335336'),
	'identifierSchemeCodeUnspsc190501.50335337': __('identifierSchemeCodeUnspsc190501.50335337'),
	'identifierSchemeCodeUnspsc190501.50335338': __('identifierSchemeCodeUnspsc190501.50335338'),
	'identifierSchemeCodeUnspsc190501.50335339': __('identifierSchemeCodeUnspsc190501.50335339'),
	'identifierSchemeCodeUnspsc190501.50335340': __('identifierSchemeCodeUnspsc190501.50335340'),
	'identifierSchemeCodeUnspsc190501.50335341': __('identifierSchemeCodeUnspsc190501.50335341'),
	'identifierSchemeCodeUnspsc190501.50335342': __('identifierSchemeCodeUnspsc190501.50335342'),
	'identifierSchemeCodeUnspsc190501.50335343': __('identifierSchemeCodeUnspsc190501.50335343'),
	'identifierSchemeCodeUnspsc190501.50335344': __('identifierSchemeCodeUnspsc190501.50335344'),
	'identifierSchemeCodeUnspsc190501.50335345': __('identifierSchemeCodeUnspsc190501.50335345'),
	'identifierSchemeCodeUnspsc190501.50335346': __('identifierSchemeCodeUnspsc190501.50335346'),
	'identifierSchemeCodeUnspsc190501.50335347': __('identifierSchemeCodeUnspsc190501.50335347'),
	'identifierSchemeCodeUnspsc190501.50335348': __('identifierSchemeCodeUnspsc190501.50335348'),
	'identifierSchemeCodeUnspsc190501.50335349': __('identifierSchemeCodeUnspsc190501.50335349'),
	'identifierSchemeCodeUnspsc190501.50335350': __('identifierSchemeCodeUnspsc190501.50335350'),
	'identifierSchemeCodeUnspsc190501.50335351': __('identifierSchemeCodeUnspsc190501.50335351'),
	'identifierSchemeCodeUnspsc190501.50335352': __('identifierSchemeCodeUnspsc190501.50335352'),
	'identifierSchemeCodeUnspsc190501.50335353': __('identifierSchemeCodeUnspsc190501.50335353'),
	'identifierSchemeCodeUnspsc190501.50335354': __('identifierSchemeCodeUnspsc190501.50335354'),
	'identifierSchemeCodeUnspsc190501.50335355': __('identifierSchemeCodeUnspsc190501.50335355'),
	'identifierSchemeCodeUnspsc190501.50335356': __('identifierSchemeCodeUnspsc190501.50335356'),
	'identifierSchemeCodeUnspsc190501.50335357': __('identifierSchemeCodeUnspsc190501.50335357'),
	'identifierSchemeCodeUnspsc190501.50335358': __('identifierSchemeCodeUnspsc190501.50335358'),
	'identifierSchemeCodeUnspsc190501.50335359': __('identifierSchemeCodeUnspsc190501.50335359'),
	'identifierSchemeCodeUnspsc190501.50335360': __('identifierSchemeCodeUnspsc190501.50335360'),
	'identifierSchemeCodeUnspsc190501.50335361': __('identifierSchemeCodeUnspsc190501.50335361'),
	'identifierSchemeCodeUnspsc190501.50335362': __('identifierSchemeCodeUnspsc190501.50335362'),
	'identifierSchemeCodeUnspsc190501.50335363': __('identifierSchemeCodeUnspsc190501.50335363'),
	'identifierSchemeCodeUnspsc190501.50335364': __('identifierSchemeCodeUnspsc190501.50335364'),
	'identifierSchemeCodeUnspsc190501.50335365': __('identifierSchemeCodeUnspsc190501.50335365'),
	'identifierSchemeCodeUnspsc190501.50335366': __('identifierSchemeCodeUnspsc190501.50335366'),
	'identifierSchemeCodeUnspsc190501.50335367': __('identifierSchemeCodeUnspsc190501.50335367'),
	'identifierSchemeCodeUnspsc190501.50335368': __('identifierSchemeCodeUnspsc190501.50335368'),
	'identifierSchemeCodeUnspsc190501.50335369': __('identifierSchemeCodeUnspsc190501.50335369'),
	'identifierSchemeCodeUnspsc190501.50335370': __('identifierSchemeCodeUnspsc190501.50335370'),
	'identifierSchemeCodeUnspsc190501.50335371': __('identifierSchemeCodeUnspsc190501.50335371'),
	'identifierSchemeCodeUnspsc190501.50335400': __('identifierSchemeCodeUnspsc190501.50335400'),
	'identifierSchemeCodeUnspsc190501.50335401': __('identifierSchemeCodeUnspsc190501.50335401'),
	'identifierSchemeCodeUnspsc190501.50335402': __('identifierSchemeCodeUnspsc190501.50335402'),
	'identifierSchemeCodeUnspsc190501.50335403': __('identifierSchemeCodeUnspsc190501.50335403'),
	'identifierSchemeCodeUnspsc190501.50335404': __('identifierSchemeCodeUnspsc190501.50335404'),
	'identifierSchemeCodeUnspsc190501.50335405': __('identifierSchemeCodeUnspsc190501.50335405'),
	'identifierSchemeCodeUnspsc190501.50335406': __('identifierSchemeCodeUnspsc190501.50335406'),
	'identifierSchemeCodeUnspsc190501.50335407': __('identifierSchemeCodeUnspsc190501.50335407'),
	'identifierSchemeCodeUnspsc190501.50335408': __('identifierSchemeCodeUnspsc190501.50335408'),
	'identifierSchemeCodeUnspsc190501.50335409': __('identifierSchemeCodeUnspsc190501.50335409'),
	'identifierSchemeCodeUnspsc190501.50335410': __('identifierSchemeCodeUnspsc190501.50335410'),
	'identifierSchemeCodeUnspsc190501.50335411': __('identifierSchemeCodeUnspsc190501.50335411'),
	'identifierSchemeCodeUnspsc190501.50335412': __('identifierSchemeCodeUnspsc190501.50335412'),
	'identifierSchemeCodeUnspsc190501.50335413': __('identifierSchemeCodeUnspsc190501.50335413'),
	'identifierSchemeCodeUnspsc190501.50335414': __('identifierSchemeCodeUnspsc190501.50335414'),
	'identifierSchemeCodeUnspsc190501.50335415': __('identifierSchemeCodeUnspsc190501.50335415'),
	'identifierSchemeCodeUnspsc190501.50335416': __('identifierSchemeCodeUnspsc190501.50335416'),
	'identifierSchemeCodeUnspsc190501.50335417': __('identifierSchemeCodeUnspsc190501.50335417'),
	'identifierSchemeCodeUnspsc190501.50335418': __('identifierSchemeCodeUnspsc190501.50335418'),
	'identifierSchemeCodeUnspsc190501.50335419': __('identifierSchemeCodeUnspsc190501.50335419'),
	'identifierSchemeCodeUnspsc190501.50335420': __('identifierSchemeCodeUnspsc190501.50335420'),
	'identifierSchemeCodeUnspsc190501.50335421': __('identifierSchemeCodeUnspsc190501.50335421'),
	'identifierSchemeCodeUnspsc190501.50335422': __('identifierSchemeCodeUnspsc190501.50335422'),
	'identifierSchemeCodeUnspsc190501.50335423': __('identifierSchemeCodeUnspsc190501.50335423'),
	'identifierSchemeCodeUnspsc190501.50335424': __('identifierSchemeCodeUnspsc190501.50335424'),
	'identifierSchemeCodeUnspsc190501.50335425': __('identifierSchemeCodeUnspsc190501.50335425'),
	'identifierSchemeCodeUnspsc190501.50335426': __('identifierSchemeCodeUnspsc190501.50335426'),
	'identifierSchemeCodeUnspsc190501.50335427': __('identifierSchemeCodeUnspsc190501.50335427'),
	'identifierSchemeCodeUnspsc190501.50335428': __('identifierSchemeCodeUnspsc190501.50335428'),
	'identifierSchemeCodeUnspsc190501.50335429': __('identifierSchemeCodeUnspsc190501.50335429'),
	'identifierSchemeCodeUnspsc190501.50335430': __('identifierSchemeCodeUnspsc190501.50335430'),
	'identifierSchemeCodeUnspsc190501.50335431': __('identifierSchemeCodeUnspsc190501.50335431'),
	'identifierSchemeCodeUnspsc190501.50335432': __('identifierSchemeCodeUnspsc190501.50335432'),
	'identifierSchemeCodeUnspsc190501.50335433': __('identifierSchemeCodeUnspsc190501.50335433'),
	'identifierSchemeCodeUnspsc190501.50335434': __('identifierSchemeCodeUnspsc190501.50335434'),
	'identifierSchemeCodeUnspsc190501.50335435': __('identifierSchemeCodeUnspsc190501.50335435'),
	'identifierSchemeCodeUnspsc190501.50335436': __('identifierSchemeCodeUnspsc190501.50335436'),
	'identifierSchemeCodeUnspsc190501.50335437': __('identifierSchemeCodeUnspsc190501.50335437'),
	'identifierSchemeCodeUnspsc190501.50335438': __('identifierSchemeCodeUnspsc190501.50335438'),
	'identifierSchemeCodeUnspsc190501.50335439': __('identifierSchemeCodeUnspsc190501.50335439'),
	'identifierSchemeCodeUnspsc190501.50335440': __('identifierSchemeCodeUnspsc190501.50335440'),
	'identifierSchemeCodeUnspsc190501.50335441': __('identifierSchemeCodeUnspsc190501.50335441'),
	'identifierSchemeCodeUnspsc190501.50335442': __('identifierSchemeCodeUnspsc190501.50335442'),
	'identifierSchemeCodeUnspsc190501.50335443': __('identifierSchemeCodeUnspsc190501.50335443'),
	'identifierSchemeCodeUnspsc190501.50335444': __('identifierSchemeCodeUnspsc190501.50335444'),
	'identifierSchemeCodeUnspsc190501.50335445': __('identifierSchemeCodeUnspsc190501.50335445'),
	'identifierSchemeCodeUnspsc190501.50335446': __('identifierSchemeCodeUnspsc190501.50335446'),
	'identifierSchemeCodeUnspsc190501.50335447': __('identifierSchemeCodeUnspsc190501.50335447'),
	'identifierSchemeCodeUnspsc190501.50335448': __('identifierSchemeCodeUnspsc190501.50335448'),
	'identifierSchemeCodeUnspsc190501.50335449': __('identifierSchemeCodeUnspsc190501.50335449'),
	'identifierSchemeCodeUnspsc190501.50335450': __('identifierSchemeCodeUnspsc190501.50335450'),
	'identifierSchemeCodeUnspsc190501.50335451': __('identifierSchemeCodeUnspsc190501.50335451'),
	'identifierSchemeCodeUnspsc190501.50335452': __('identifierSchemeCodeUnspsc190501.50335452'),
	'identifierSchemeCodeUnspsc190501.50335453': __('identifierSchemeCodeUnspsc190501.50335453'),
	'identifierSchemeCodeUnspsc190501.50335454': __('identifierSchemeCodeUnspsc190501.50335454'),
	'identifierSchemeCodeUnspsc190501.50335455': __('identifierSchemeCodeUnspsc190501.50335455'),
	'identifierSchemeCodeUnspsc190501.50335456': __('identifierSchemeCodeUnspsc190501.50335456'),
	'identifierSchemeCodeUnspsc190501.50335457': __('identifierSchemeCodeUnspsc190501.50335457'),
	'identifierSchemeCodeUnspsc190501.50335458': __('identifierSchemeCodeUnspsc190501.50335458'),
	'identifierSchemeCodeUnspsc190501.50335459': __('identifierSchemeCodeUnspsc190501.50335459'),
	'identifierSchemeCodeUnspsc190501.50335460': __('identifierSchemeCodeUnspsc190501.50335460'),
	'identifierSchemeCodeUnspsc190501.50335500': __('identifierSchemeCodeUnspsc190501.50335500'),
	'identifierSchemeCodeUnspsc190501.50335501': __('identifierSchemeCodeUnspsc190501.50335501'),
	'identifierSchemeCodeUnspsc190501.50335502': __('identifierSchemeCodeUnspsc190501.50335502'),
	'identifierSchemeCodeUnspsc190501.50335503': __('identifierSchemeCodeUnspsc190501.50335503'),
	'identifierSchemeCodeUnspsc190501.50335504': __('identifierSchemeCodeUnspsc190501.50335504'),
	'identifierSchemeCodeUnspsc190501.50335505': __('identifierSchemeCodeUnspsc190501.50335505'),
	'identifierSchemeCodeUnspsc190501.50335506': __('identifierSchemeCodeUnspsc190501.50335506'),
	'identifierSchemeCodeUnspsc190501.50335507': __('identifierSchemeCodeUnspsc190501.50335507'),
	'identifierSchemeCodeUnspsc190501.50335508': __('identifierSchemeCodeUnspsc190501.50335508'),
	'identifierSchemeCodeUnspsc190501.50335509': __('identifierSchemeCodeUnspsc190501.50335509'),
	'identifierSchemeCodeUnspsc190501.50335510': __('identifierSchemeCodeUnspsc190501.50335510'),
	'identifierSchemeCodeUnspsc190501.50335511': __('identifierSchemeCodeUnspsc190501.50335511'),
	'identifierSchemeCodeUnspsc190501.50335512': __('identifierSchemeCodeUnspsc190501.50335512'),
	'identifierSchemeCodeUnspsc190501.50335600': __('identifierSchemeCodeUnspsc190501.50335600'),
	'identifierSchemeCodeUnspsc190501.50335601': __('identifierSchemeCodeUnspsc190501.50335601'),
	'identifierSchemeCodeUnspsc190501.50335602': __('identifierSchemeCodeUnspsc190501.50335602'),
	'identifierSchemeCodeUnspsc190501.50335603': __('identifierSchemeCodeUnspsc190501.50335603'),
	'identifierSchemeCodeUnspsc190501.50335604': __('identifierSchemeCodeUnspsc190501.50335604'),
	'identifierSchemeCodeUnspsc190501.50335605': __('identifierSchemeCodeUnspsc190501.50335605'),
	'identifierSchemeCodeUnspsc190501.50335606': __('identifierSchemeCodeUnspsc190501.50335606'),
	'identifierSchemeCodeUnspsc190501.50335607': __('identifierSchemeCodeUnspsc190501.50335607'),
	'identifierSchemeCodeUnspsc190501.50335608': __('identifierSchemeCodeUnspsc190501.50335608'),
	'identifierSchemeCodeUnspsc190501.50335609': __('identifierSchemeCodeUnspsc190501.50335609'),
	'identifierSchemeCodeUnspsc190501.50335610': __('identifierSchemeCodeUnspsc190501.50335610'),
	'identifierSchemeCodeUnspsc190501.50335700': __('identifierSchemeCodeUnspsc190501.50335700'),
	'identifierSchemeCodeUnspsc190501.50335701': __('identifierSchemeCodeUnspsc190501.50335701'),
	'identifierSchemeCodeUnspsc190501.50335702': __('identifierSchemeCodeUnspsc190501.50335702'),
	'identifierSchemeCodeUnspsc190501.50335703': __('identifierSchemeCodeUnspsc190501.50335703'),
	'identifierSchemeCodeUnspsc190501.50335704': __('identifierSchemeCodeUnspsc190501.50335704'),
	'identifierSchemeCodeUnspsc190501.50335705': __('identifierSchemeCodeUnspsc190501.50335705'),
	'identifierSchemeCodeUnspsc190501.50335706': __('identifierSchemeCodeUnspsc190501.50335706'),
	'identifierSchemeCodeUnspsc190501.50335707': __('identifierSchemeCodeUnspsc190501.50335707'),
	'identifierSchemeCodeUnspsc190501.50335708': __('identifierSchemeCodeUnspsc190501.50335708'),
	'identifierSchemeCodeUnspsc190501.50335709': __('identifierSchemeCodeUnspsc190501.50335709'),
	'identifierSchemeCodeUnspsc190501.50335710': __('identifierSchemeCodeUnspsc190501.50335710'),
	'identifierSchemeCodeUnspsc190501.50335711': __('identifierSchemeCodeUnspsc190501.50335711'),
	'identifierSchemeCodeUnspsc190501.50335712': __('identifierSchemeCodeUnspsc190501.50335712'),
	'identifierSchemeCodeUnspsc190501.50335713': __('identifierSchemeCodeUnspsc190501.50335713'),
	'identifierSchemeCodeUnspsc190501.50335714': __('identifierSchemeCodeUnspsc190501.50335714'),
	'identifierSchemeCodeUnspsc190501.50335715': __('identifierSchemeCodeUnspsc190501.50335715'),
	'identifierSchemeCodeUnspsc190501.50335716': __('identifierSchemeCodeUnspsc190501.50335716'),
	'identifierSchemeCodeUnspsc190501.50335717': __('identifierSchemeCodeUnspsc190501.50335717'),
	'identifierSchemeCodeUnspsc190501.50335718': __('identifierSchemeCodeUnspsc190501.50335718'),
	'identifierSchemeCodeUnspsc190501.50335719': __('identifierSchemeCodeUnspsc190501.50335719'),
	'identifierSchemeCodeUnspsc190501.50335720': __('identifierSchemeCodeUnspsc190501.50335720'),
	'identifierSchemeCodeUnspsc190501.50335721': __('identifierSchemeCodeUnspsc190501.50335721'),
	'identifierSchemeCodeUnspsc190501.50335722': __('identifierSchemeCodeUnspsc190501.50335722'),
	'identifierSchemeCodeUnspsc190501.50335723': __('identifierSchemeCodeUnspsc190501.50335723'),
	'identifierSchemeCodeUnspsc190501.50335800': __('identifierSchemeCodeUnspsc190501.50335800'),
	'identifierSchemeCodeUnspsc190501.50335801': __('identifierSchemeCodeUnspsc190501.50335801'),
	'identifierSchemeCodeUnspsc190501.50335802': __('identifierSchemeCodeUnspsc190501.50335802'),
	'identifierSchemeCodeUnspsc190501.50335803': __('identifierSchemeCodeUnspsc190501.50335803'),
	'identifierSchemeCodeUnspsc190501.50335804': __('identifierSchemeCodeUnspsc190501.50335804'),
	'identifierSchemeCodeUnspsc190501.50335805': __('identifierSchemeCodeUnspsc190501.50335805'),
	'identifierSchemeCodeUnspsc190501.50335806': __('identifierSchemeCodeUnspsc190501.50335806'),
	'identifierSchemeCodeUnspsc190501.50335807': __('identifierSchemeCodeUnspsc190501.50335807'),
	'identifierSchemeCodeUnspsc190501.50335808': __('identifierSchemeCodeUnspsc190501.50335808'),
	'identifierSchemeCodeUnspsc190501.50335809': __('identifierSchemeCodeUnspsc190501.50335809'),
	'identifierSchemeCodeUnspsc190501.50335810': __('identifierSchemeCodeUnspsc190501.50335810'),
	'identifierSchemeCodeUnspsc190501.50335811': __('identifierSchemeCodeUnspsc190501.50335811'),
	'identifierSchemeCodeUnspsc190501.50335812': __('identifierSchemeCodeUnspsc190501.50335812'),
	'identifierSchemeCodeUnspsc190501.50335813': __('identifierSchemeCodeUnspsc190501.50335813'),
	'identifierSchemeCodeUnspsc190501.50335814': __('identifierSchemeCodeUnspsc190501.50335814'),
	'identifierSchemeCodeUnspsc190501.50335815': __('identifierSchemeCodeUnspsc190501.50335815'),
	'identifierSchemeCodeUnspsc190501.50335816': __('identifierSchemeCodeUnspsc190501.50335816'),
	'identifierSchemeCodeUnspsc190501.50335817': __('identifierSchemeCodeUnspsc190501.50335817'),
	'identifierSchemeCodeUnspsc190501.50335818': __('identifierSchemeCodeUnspsc190501.50335818'),
	'identifierSchemeCodeUnspsc190501.50335819': __('identifierSchemeCodeUnspsc190501.50335819'),
	'identifierSchemeCodeUnspsc190501.50335820': __('identifierSchemeCodeUnspsc190501.50335820'),
	'identifierSchemeCodeUnspsc190501.50335821': __('identifierSchemeCodeUnspsc190501.50335821'),
	'identifierSchemeCodeUnspsc190501.50335822': __('identifierSchemeCodeUnspsc190501.50335822'),
	'identifierSchemeCodeUnspsc190501.50335823': __('identifierSchemeCodeUnspsc190501.50335823'),
	'identifierSchemeCodeUnspsc190501.50335824': __('identifierSchemeCodeUnspsc190501.50335824'),
	'identifierSchemeCodeUnspsc190501.50335825': __('identifierSchemeCodeUnspsc190501.50335825'),
	'identifierSchemeCodeUnspsc190501.50335826': __('identifierSchemeCodeUnspsc190501.50335826'),
	'identifierSchemeCodeUnspsc190501.50335827': __('identifierSchemeCodeUnspsc190501.50335827'),
	'identifierSchemeCodeUnspsc190501.50335828': __('identifierSchemeCodeUnspsc190501.50335828'),
	'identifierSchemeCodeUnspsc190501.50335829': __('identifierSchemeCodeUnspsc190501.50335829'),
	'identifierSchemeCodeUnspsc190501.50335830': __('identifierSchemeCodeUnspsc190501.50335830'),
	'identifierSchemeCodeUnspsc190501.50335831': __('identifierSchemeCodeUnspsc190501.50335831'),
	'identifierSchemeCodeUnspsc190501.50335832': __('identifierSchemeCodeUnspsc190501.50335832'),
	'identifierSchemeCodeUnspsc190501.50335833': __('identifierSchemeCodeUnspsc190501.50335833'),
	'identifierSchemeCodeUnspsc190501.50335834': __('identifierSchemeCodeUnspsc190501.50335834'),
	'identifierSchemeCodeUnspsc190501.50335835': __('identifierSchemeCodeUnspsc190501.50335835'),
	'identifierSchemeCodeUnspsc190501.50335836': __('identifierSchemeCodeUnspsc190501.50335836'),
	'identifierSchemeCodeUnspsc190501.50335837': __('identifierSchemeCodeUnspsc190501.50335837'),
	'identifierSchemeCodeUnspsc190501.50335838': __('identifierSchemeCodeUnspsc190501.50335838'),
	'identifierSchemeCodeUnspsc190501.50335839': __('identifierSchemeCodeUnspsc190501.50335839'),
	'identifierSchemeCodeUnspsc190501.50335840': __('identifierSchemeCodeUnspsc190501.50335840'),
	'identifierSchemeCodeUnspsc190501.50335841': __('identifierSchemeCodeUnspsc190501.50335841'),
	'identifierSchemeCodeUnspsc190501.50335842': __('identifierSchemeCodeUnspsc190501.50335842'),
	'identifierSchemeCodeUnspsc190501.50335843': __('identifierSchemeCodeUnspsc190501.50335843'),
	'identifierSchemeCodeUnspsc190501.50335844': __('identifierSchemeCodeUnspsc190501.50335844'),
	'identifierSchemeCodeUnspsc190501.50335845': __('identifierSchemeCodeUnspsc190501.50335845'),
	'identifierSchemeCodeUnspsc190501.50335846': __('identifierSchemeCodeUnspsc190501.50335846'),
	'identifierSchemeCodeUnspsc190501.50335847': __('identifierSchemeCodeUnspsc190501.50335847'),
	'identifierSchemeCodeUnspsc190501.50335848': __('identifierSchemeCodeUnspsc190501.50335848'),
	'identifierSchemeCodeUnspsc190501.50335849': __('identifierSchemeCodeUnspsc190501.50335849'),
	'identifierSchemeCodeUnspsc190501.50335850': __('identifierSchemeCodeUnspsc190501.50335850'),
	'identifierSchemeCodeUnspsc190501.50335851': __('identifierSchemeCodeUnspsc190501.50335851'),
	'identifierSchemeCodeUnspsc190501.50335852': __('identifierSchemeCodeUnspsc190501.50335852'),
	'identifierSchemeCodeUnspsc190501.50335853': __('identifierSchemeCodeUnspsc190501.50335853'),
	'identifierSchemeCodeUnspsc190501.50335854': __('identifierSchemeCodeUnspsc190501.50335854'),
	'identifierSchemeCodeUnspsc190501.50335855': __('identifierSchemeCodeUnspsc190501.50335855'),
	'identifierSchemeCodeUnspsc190501.50335856': __('identifierSchemeCodeUnspsc190501.50335856'),
	'identifierSchemeCodeUnspsc190501.50335857': __('identifierSchemeCodeUnspsc190501.50335857'),
	'identifierSchemeCodeUnspsc190501.50335858': __('identifierSchemeCodeUnspsc190501.50335858'),
	'identifierSchemeCodeUnspsc190501.50335859': __('identifierSchemeCodeUnspsc190501.50335859'),
	'identifierSchemeCodeUnspsc190501.50335860': __('identifierSchemeCodeUnspsc190501.50335860'),
	'identifierSchemeCodeUnspsc190501.50335861': __('identifierSchemeCodeUnspsc190501.50335861'),
	'identifierSchemeCodeUnspsc190501.50335862': __('identifierSchemeCodeUnspsc190501.50335862'),
	'identifierSchemeCodeUnspsc190501.50335863': __('identifierSchemeCodeUnspsc190501.50335863'),
	'identifierSchemeCodeUnspsc190501.50335864': __('identifierSchemeCodeUnspsc190501.50335864'),
	'identifierSchemeCodeUnspsc190501.50335865': __('identifierSchemeCodeUnspsc190501.50335865'),
	'identifierSchemeCodeUnspsc190501.50335866': __('identifierSchemeCodeUnspsc190501.50335866'),
	'identifierSchemeCodeUnspsc190501.50335867': __('identifierSchemeCodeUnspsc190501.50335867'),
	'identifierSchemeCodeUnspsc190501.50335868': __('identifierSchemeCodeUnspsc190501.50335868'),
	'identifierSchemeCodeUnspsc190501.50335869': __('identifierSchemeCodeUnspsc190501.50335869'),
	'identifierSchemeCodeUnspsc190501.50335870': __('identifierSchemeCodeUnspsc190501.50335870'),
	'identifierSchemeCodeUnspsc190501.50335871': __('identifierSchemeCodeUnspsc190501.50335871'),
	'identifierSchemeCodeUnspsc190501.50335872': __('identifierSchemeCodeUnspsc190501.50335872'),
	'identifierSchemeCodeUnspsc190501.50335873': __('identifierSchemeCodeUnspsc190501.50335873'),
	'identifierSchemeCodeUnspsc190501.50335874': __('identifierSchemeCodeUnspsc190501.50335874'),
	'identifierSchemeCodeUnspsc190501.50335875': __('identifierSchemeCodeUnspsc190501.50335875'),
	'identifierSchemeCodeUnspsc190501.50335876': __('identifierSchemeCodeUnspsc190501.50335876'),
	'identifierSchemeCodeUnspsc190501.50335877': __('identifierSchemeCodeUnspsc190501.50335877'),
	'identifierSchemeCodeUnspsc190501.50335900': __('identifierSchemeCodeUnspsc190501.50335900'),
	'identifierSchemeCodeUnspsc190501.50335901': __('identifierSchemeCodeUnspsc190501.50335901'),
	'identifierSchemeCodeUnspsc190501.50335902': __('identifierSchemeCodeUnspsc190501.50335902'),
	'identifierSchemeCodeUnspsc190501.50335903': __('identifierSchemeCodeUnspsc190501.50335903'),
	'identifierSchemeCodeUnspsc190501.50335904': __('identifierSchemeCodeUnspsc190501.50335904'),
	'identifierSchemeCodeUnspsc190501.50335905': __('identifierSchemeCodeUnspsc190501.50335905'),
	'identifierSchemeCodeUnspsc190501.50335906': __('identifierSchemeCodeUnspsc190501.50335906'),
	'identifierSchemeCodeUnspsc190501.50335907': __('identifierSchemeCodeUnspsc190501.50335907'),
	'identifierSchemeCodeUnspsc190501.50335908': __('identifierSchemeCodeUnspsc190501.50335908'),
	'identifierSchemeCodeUnspsc190501.50336000': __('identifierSchemeCodeUnspsc190501.50336000'),
	'identifierSchemeCodeUnspsc190501.50336001': __('identifierSchemeCodeUnspsc190501.50336001'),
	'identifierSchemeCodeUnspsc190501.50336002': __('identifierSchemeCodeUnspsc190501.50336002'),
	'identifierSchemeCodeUnspsc190501.50336003': __('identifierSchemeCodeUnspsc190501.50336003'),
	'identifierSchemeCodeUnspsc190501.50336004': __('identifierSchemeCodeUnspsc190501.50336004'),
	'identifierSchemeCodeUnspsc190501.50336005': __('identifierSchemeCodeUnspsc190501.50336005'),
	'identifierSchemeCodeUnspsc190501.50336006': __('identifierSchemeCodeUnspsc190501.50336006'),
	'identifierSchemeCodeUnspsc190501.50336007': __('identifierSchemeCodeUnspsc190501.50336007'),
	'identifierSchemeCodeUnspsc190501.50336008': __('identifierSchemeCodeUnspsc190501.50336008'),
	'identifierSchemeCodeUnspsc190501.50336100': __('identifierSchemeCodeUnspsc190501.50336100'),
	'identifierSchemeCodeUnspsc190501.50336101': __('identifierSchemeCodeUnspsc190501.50336101'),
	'identifierSchemeCodeUnspsc190501.50336102': __('identifierSchemeCodeUnspsc190501.50336102'),
	'identifierSchemeCodeUnspsc190501.50336103': __('identifierSchemeCodeUnspsc190501.50336103'),
	'identifierSchemeCodeUnspsc190501.50336200': __('identifierSchemeCodeUnspsc190501.50336200'),
	'identifierSchemeCodeUnspsc190501.50336201': __('identifierSchemeCodeUnspsc190501.50336201'),
	'identifierSchemeCodeUnspsc190501.50336202': __('identifierSchemeCodeUnspsc190501.50336202'),
	'identifierSchemeCodeUnspsc190501.50336203': __('identifierSchemeCodeUnspsc190501.50336203'),
	'identifierSchemeCodeUnspsc190501.50336204': __('identifierSchemeCodeUnspsc190501.50336204'),
	'identifierSchemeCodeUnspsc190501.50336205': __('identifierSchemeCodeUnspsc190501.50336205'),
	'identifierSchemeCodeUnspsc190501.50336206': __('identifierSchemeCodeUnspsc190501.50336206'),
	'identifierSchemeCodeUnspsc190501.50336207': __('identifierSchemeCodeUnspsc190501.50336207'),
	'identifierSchemeCodeUnspsc190501.50336208': __('identifierSchemeCodeUnspsc190501.50336208'),
	'identifierSchemeCodeUnspsc190501.50336209': __('identifierSchemeCodeUnspsc190501.50336209'),
	'identifierSchemeCodeUnspsc190501.50336210': __('identifierSchemeCodeUnspsc190501.50336210'),
	'identifierSchemeCodeUnspsc190501.50336211': __('identifierSchemeCodeUnspsc190501.50336211'),
	'identifierSchemeCodeUnspsc190501.50336212': __('identifierSchemeCodeUnspsc190501.50336212'),
	'identifierSchemeCodeUnspsc190501.50336213': __('identifierSchemeCodeUnspsc190501.50336213'),
	'identifierSchemeCodeUnspsc190501.50336214': __('identifierSchemeCodeUnspsc190501.50336214'),
	'identifierSchemeCodeUnspsc190501.50336215': __('identifierSchemeCodeUnspsc190501.50336215'),
	'identifierSchemeCodeUnspsc190501.50336216': __('identifierSchemeCodeUnspsc190501.50336216'),
	'identifierSchemeCodeUnspsc190501.50336217': __('identifierSchemeCodeUnspsc190501.50336217'),
	'identifierSchemeCodeUnspsc190501.50336218': __('identifierSchemeCodeUnspsc190501.50336218'),
	'identifierSchemeCodeUnspsc190501.50336219': __('identifierSchemeCodeUnspsc190501.50336219'),
	'identifierSchemeCodeUnspsc190501.50336220': __('identifierSchemeCodeUnspsc190501.50336220'),
	'identifierSchemeCodeUnspsc190501.50336221': __('identifierSchemeCodeUnspsc190501.50336221'),
	'identifierSchemeCodeUnspsc190501.50336222': __('identifierSchemeCodeUnspsc190501.50336222'),
	'identifierSchemeCodeUnspsc190501.50336223': __('identifierSchemeCodeUnspsc190501.50336223'),
	'identifierSchemeCodeUnspsc190501.50336224': __('identifierSchemeCodeUnspsc190501.50336224'),
	'identifierSchemeCodeUnspsc190501.50336225': __('identifierSchemeCodeUnspsc190501.50336225'),
	'identifierSchemeCodeUnspsc190501.50336226': __('identifierSchemeCodeUnspsc190501.50336226'),
	'identifierSchemeCodeUnspsc190501.50336227': __('identifierSchemeCodeUnspsc190501.50336227'),
	'identifierSchemeCodeUnspsc190501.50336228': __('identifierSchemeCodeUnspsc190501.50336228'),
	'identifierSchemeCodeUnspsc190501.50336229': __('identifierSchemeCodeUnspsc190501.50336229'),
	'identifierSchemeCodeUnspsc190501.50336300': __('identifierSchemeCodeUnspsc190501.50336300'),
	'identifierSchemeCodeUnspsc190501.50336301': __('identifierSchemeCodeUnspsc190501.50336301'),
	'identifierSchemeCodeUnspsc190501.50336302': __('identifierSchemeCodeUnspsc190501.50336302'),
	'identifierSchemeCodeUnspsc190501.50336303': __('identifierSchemeCodeUnspsc190501.50336303'),
	'identifierSchemeCodeUnspsc190501.50336304': __('identifierSchemeCodeUnspsc190501.50336304'),
	'identifierSchemeCodeUnspsc190501.50336305': __('identifierSchemeCodeUnspsc190501.50336305'),
	'identifierSchemeCodeUnspsc190501.50336306': __('identifierSchemeCodeUnspsc190501.50336306'),
	'identifierSchemeCodeUnspsc190501.50336307': __('identifierSchemeCodeUnspsc190501.50336307'),
	'identifierSchemeCodeUnspsc190501.50336308': __('identifierSchemeCodeUnspsc190501.50336308'),
	'identifierSchemeCodeUnspsc190501.50336309': __('identifierSchemeCodeUnspsc190501.50336309'),
	'identifierSchemeCodeUnspsc190501.50336310': __('identifierSchemeCodeUnspsc190501.50336310'),
	'identifierSchemeCodeUnspsc190501.50336400': __('identifierSchemeCodeUnspsc190501.50336400'),
	'identifierSchemeCodeUnspsc190501.50336401': __('identifierSchemeCodeUnspsc190501.50336401'),
	'identifierSchemeCodeUnspsc190501.50336402': __('identifierSchemeCodeUnspsc190501.50336402'),
	'identifierSchemeCodeUnspsc190501.50336403': __('identifierSchemeCodeUnspsc190501.50336403'),
	'identifierSchemeCodeUnspsc190501.50336404': __('identifierSchemeCodeUnspsc190501.50336404'),
	'identifierSchemeCodeUnspsc190501.50336500': __('identifierSchemeCodeUnspsc190501.50336500'),
	'identifierSchemeCodeUnspsc190501.50336501': __('identifierSchemeCodeUnspsc190501.50336501'),
	'identifierSchemeCodeUnspsc190501.50336502': __('identifierSchemeCodeUnspsc190501.50336502'),
	'identifierSchemeCodeUnspsc190501.50336600': __('identifierSchemeCodeUnspsc190501.50336600'),
	'identifierSchemeCodeUnspsc190501.50336601': __('identifierSchemeCodeUnspsc190501.50336601'),
	'identifierSchemeCodeUnspsc190501.50336602': __('identifierSchemeCodeUnspsc190501.50336602'),
	'identifierSchemeCodeUnspsc190501.50336603': __('identifierSchemeCodeUnspsc190501.50336603'),
	'identifierSchemeCodeUnspsc190501.50336604': __('identifierSchemeCodeUnspsc190501.50336604'),
	'identifierSchemeCodeUnspsc190501.50336700': __('identifierSchemeCodeUnspsc190501.50336700'),
	'identifierSchemeCodeUnspsc190501.50336701': __('identifierSchemeCodeUnspsc190501.50336701'),
	'identifierSchemeCodeUnspsc190501.50336702': __('identifierSchemeCodeUnspsc190501.50336702'),
	'identifierSchemeCodeUnspsc190501.50336703': __('identifierSchemeCodeUnspsc190501.50336703'),
	'identifierSchemeCodeUnspsc190501.50336800': __('identifierSchemeCodeUnspsc190501.50336800'),
	'identifierSchemeCodeUnspsc190501.50336801': __('identifierSchemeCodeUnspsc190501.50336801'),
	'identifierSchemeCodeUnspsc190501.50336802': __('identifierSchemeCodeUnspsc190501.50336802'),
	'identifierSchemeCodeUnspsc190501.50336803': __('identifierSchemeCodeUnspsc190501.50336803'),
	'identifierSchemeCodeUnspsc190501.50336900': __('identifierSchemeCodeUnspsc190501.50336900'),
	'identifierSchemeCodeUnspsc190501.50336901': __('identifierSchemeCodeUnspsc190501.50336901'),
	'identifierSchemeCodeUnspsc190501.50336902': __('identifierSchemeCodeUnspsc190501.50336902'),
	'identifierSchemeCodeUnspsc190501.50336903': __('identifierSchemeCodeUnspsc190501.50336903'),
	'identifierSchemeCodeUnspsc190501.50336904': __('identifierSchemeCodeUnspsc190501.50336904'),
	'identifierSchemeCodeUnspsc190501.50336905': __('identifierSchemeCodeUnspsc190501.50336905'),
	'identifierSchemeCodeUnspsc190501.50336906': __('identifierSchemeCodeUnspsc190501.50336906'),
	'identifierSchemeCodeUnspsc190501.50337000': __('identifierSchemeCodeUnspsc190501.50337000'),
	'identifierSchemeCodeUnspsc190501.50337001': __('identifierSchemeCodeUnspsc190501.50337001'),
	'identifierSchemeCodeUnspsc190501.50337002': __('identifierSchemeCodeUnspsc190501.50337002'),
	'identifierSchemeCodeUnspsc190501.50337003': __('identifierSchemeCodeUnspsc190501.50337003'),
	'identifierSchemeCodeUnspsc190501.50337004': __('identifierSchemeCodeUnspsc190501.50337004'),
	'identifierSchemeCodeUnspsc190501.50337005': __('identifierSchemeCodeUnspsc190501.50337005'),
	'identifierSchemeCodeUnspsc190501.50337006': __('identifierSchemeCodeUnspsc190501.50337006'),
	'identifierSchemeCodeUnspsc190501.50337007': __('identifierSchemeCodeUnspsc190501.50337007'),
	'identifierSchemeCodeUnspsc190501.50337008': __('identifierSchemeCodeUnspsc190501.50337008'),
	'identifierSchemeCodeUnspsc190501.50337009': __('identifierSchemeCodeUnspsc190501.50337009'),
	'identifierSchemeCodeUnspsc190501.50337010': __('identifierSchemeCodeUnspsc190501.50337010'),
	'identifierSchemeCodeUnspsc190501.50337011': __('identifierSchemeCodeUnspsc190501.50337011'),
	'identifierSchemeCodeUnspsc190501.50337012': __('identifierSchemeCodeUnspsc190501.50337012'),
	'identifierSchemeCodeUnspsc190501.50337013': __('identifierSchemeCodeUnspsc190501.50337013'),
	'identifierSchemeCodeUnspsc190501.50337014': __('identifierSchemeCodeUnspsc190501.50337014'),
	'identifierSchemeCodeUnspsc190501.50337015': __('identifierSchemeCodeUnspsc190501.50337015'),
	'identifierSchemeCodeUnspsc190501.50337016': __('identifierSchemeCodeUnspsc190501.50337016'),
	'identifierSchemeCodeUnspsc190501.50337017': __('identifierSchemeCodeUnspsc190501.50337017'),
	'identifierSchemeCodeUnspsc190501.50337018': __('identifierSchemeCodeUnspsc190501.50337018'),
	'identifierSchemeCodeUnspsc190501.50337019': __('identifierSchemeCodeUnspsc190501.50337019'),
	'identifierSchemeCodeUnspsc190501.50337020': __('identifierSchemeCodeUnspsc190501.50337020'),
	'identifierSchemeCodeUnspsc190501.50337021': __('identifierSchemeCodeUnspsc190501.50337021'),
	'identifierSchemeCodeUnspsc190501.50337022': __('identifierSchemeCodeUnspsc190501.50337022'),
	'identifierSchemeCodeUnspsc190501.50337023': __('identifierSchemeCodeUnspsc190501.50337023'),
	'identifierSchemeCodeUnspsc190501.50337024': __('identifierSchemeCodeUnspsc190501.50337024'),
	'identifierSchemeCodeUnspsc190501.50337025': __('identifierSchemeCodeUnspsc190501.50337025'),
	'identifierSchemeCodeUnspsc190501.50337026': __('identifierSchemeCodeUnspsc190501.50337026'),
	'identifierSchemeCodeUnspsc190501.50337027': __('identifierSchemeCodeUnspsc190501.50337027'),
	'identifierSchemeCodeUnspsc190501.50337028': __('identifierSchemeCodeUnspsc190501.50337028'),
	'identifierSchemeCodeUnspsc190501.50337029': __('identifierSchemeCodeUnspsc190501.50337029'),
	'identifierSchemeCodeUnspsc190501.50337030': __('identifierSchemeCodeUnspsc190501.50337030'),
	'identifierSchemeCodeUnspsc190501.50337031': __('identifierSchemeCodeUnspsc190501.50337031'),
	'identifierSchemeCodeUnspsc190501.50337032': __('identifierSchemeCodeUnspsc190501.50337032'),
	'identifierSchemeCodeUnspsc190501.50337033': __('identifierSchemeCodeUnspsc190501.50337033'),
	'identifierSchemeCodeUnspsc190501.50337034': __('identifierSchemeCodeUnspsc190501.50337034'),
	'identifierSchemeCodeUnspsc190501.50337035': __('identifierSchemeCodeUnspsc190501.50337035'),
	'identifierSchemeCodeUnspsc190501.50337036': __('identifierSchemeCodeUnspsc190501.50337036'),
	'identifierSchemeCodeUnspsc190501.50337037': __('identifierSchemeCodeUnspsc190501.50337037'),
	'identifierSchemeCodeUnspsc190501.50337100': __('identifierSchemeCodeUnspsc190501.50337100'),
	'identifierSchemeCodeUnspsc190501.50337101': __('identifierSchemeCodeUnspsc190501.50337101'),
	'identifierSchemeCodeUnspsc190501.50337102': __('identifierSchemeCodeUnspsc190501.50337102'),
	'identifierSchemeCodeUnspsc190501.50337103': __('identifierSchemeCodeUnspsc190501.50337103'),
	'identifierSchemeCodeUnspsc190501.50337104': __('identifierSchemeCodeUnspsc190501.50337104'),
	'identifierSchemeCodeUnspsc190501.50337200': __('identifierSchemeCodeUnspsc190501.50337200'),
	'identifierSchemeCodeUnspsc190501.50337201': __('identifierSchemeCodeUnspsc190501.50337201'),
	'identifierSchemeCodeUnspsc190501.50337202': __('identifierSchemeCodeUnspsc190501.50337202'),
	'identifierSchemeCodeUnspsc190501.50337203': __('identifierSchemeCodeUnspsc190501.50337203'),
	'identifierSchemeCodeUnspsc190501.50337204': __('identifierSchemeCodeUnspsc190501.50337204'),
	'identifierSchemeCodeUnspsc190501.50337205': __('identifierSchemeCodeUnspsc190501.50337205'),
	'identifierSchemeCodeUnspsc190501.50337206': __('identifierSchemeCodeUnspsc190501.50337206'),
	'identifierSchemeCodeUnspsc190501.50337207': __('identifierSchemeCodeUnspsc190501.50337207'),
	'identifierSchemeCodeUnspsc190501.50337208': __('identifierSchemeCodeUnspsc190501.50337208'),
	'identifierSchemeCodeUnspsc190501.50337209': __('identifierSchemeCodeUnspsc190501.50337209'),
	'identifierSchemeCodeUnspsc190501.50337210': __('identifierSchemeCodeUnspsc190501.50337210'),
	'identifierSchemeCodeUnspsc190501.50337211': __('identifierSchemeCodeUnspsc190501.50337211'),
	'identifierSchemeCodeUnspsc190501.50337212': __('identifierSchemeCodeUnspsc190501.50337212'),
	'identifierSchemeCodeUnspsc190501.50337213': __('identifierSchemeCodeUnspsc190501.50337213'),
	'identifierSchemeCodeUnspsc190501.50337214': __('identifierSchemeCodeUnspsc190501.50337214'),
	'identifierSchemeCodeUnspsc190501.50337215': __('identifierSchemeCodeUnspsc190501.50337215'),
	'identifierSchemeCodeUnspsc190501.50337216': __('identifierSchemeCodeUnspsc190501.50337216'),
	'identifierSchemeCodeUnspsc190501.50337217': __('identifierSchemeCodeUnspsc190501.50337217'),
	'identifierSchemeCodeUnspsc190501.50337218': __('identifierSchemeCodeUnspsc190501.50337218'),
	'identifierSchemeCodeUnspsc190501.50337219': __('identifierSchemeCodeUnspsc190501.50337219'),
	'identifierSchemeCodeUnspsc190501.50337220': __('identifierSchemeCodeUnspsc190501.50337220'),
	'identifierSchemeCodeUnspsc190501.50337221': __('identifierSchemeCodeUnspsc190501.50337221'),
	'identifierSchemeCodeUnspsc190501.50337222': __('identifierSchemeCodeUnspsc190501.50337222'),
	'identifierSchemeCodeUnspsc190501.50337223': __('identifierSchemeCodeUnspsc190501.50337223'),
	'identifierSchemeCodeUnspsc190501.50337224': __('identifierSchemeCodeUnspsc190501.50337224'),
	'identifierSchemeCodeUnspsc190501.50337225': __('identifierSchemeCodeUnspsc190501.50337225'),
	'identifierSchemeCodeUnspsc190501.50337226': __('identifierSchemeCodeUnspsc190501.50337226'),
	'identifierSchemeCodeUnspsc190501.50337227': __('identifierSchemeCodeUnspsc190501.50337227'),
	'identifierSchemeCodeUnspsc190501.50337228': __('identifierSchemeCodeUnspsc190501.50337228'),
	'identifierSchemeCodeUnspsc190501.50337229': __('identifierSchemeCodeUnspsc190501.50337229'),
	'identifierSchemeCodeUnspsc190501.50337230': __('identifierSchemeCodeUnspsc190501.50337230'),
	'identifierSchemeCodeUnspsc190501.50340000': __('identifierSchemeCodeUnspsc190501.50340000'),
	'identifierSchemeCodeUnspsc190501.50341500': __('identifierSchemeCodeUnspsc190501.50341500'),
	'identifierSchemeCodeUnspsc190501.50341501': __('identifierSchemeCodeUnspsc190501.50341501'),
	'identifierSchemeCodeUnspsc190501.50341502': __('identifierSchemeCodeUnspsc190501.50341502'),
	'identifierSchemeCodeUnspsc190501.50341503': __('identifierSchemeCodeUnspsc190501.50341503'),
	'identifierSchemeCodeUnspsc190501.50341504': __('identifierSchemeCodeUnspsc190501.50341504'),
	'identifierSchemeCodeUnspsc190501.50341505': __('identifierSchemeCodeUnspsc190501.50341505'),
	'identifierSchemeCodeUnspsc190501.50341506': __('identifierSchemeCodeUnspsc190501.50341506'),
	'identifierSchemeCodeUnspsc190501.50341507': __('identifierSchemeCodeUnspsc190501.50341507'),
	'identifierSchemeCodeUnspsc190501.50341508': __('identifierSchemeCodeUnspsc190501.50341508'),
	'identifierSchemeCodeUnspsc190501.50341509': __('identifierSchemeCodeUnspsc190501.50341509'),
	'identifierSchemeCodeUnspsc190501.50341510': __('identifierSchemeCodeUnspsc190501.50341510'),
	'identifierSchemeCodeUnspsc190501.50341511': __('identifierSchemeCodeUnspsc190501.50341511'),
	'identifierSchemeCodeUnspsc190501.50341512': __('identifierSchemeCodeUnspsc190501.50341512'),
	'identifierSchemeCodeUnspsc190501.50341513': __('identifierSchemeCodeUnspsc190501.50341513'),
	'identifierSchemeCodeUnspsc190501.50341514': __('identifierSchemeCodeUnspsc190501.50341514'),
	'identifierSchemeCodeUnspsc190501.50341515': __('identifierSchemeCodeUnspsc190501.50341515'),
	'identifierSchemeCodeUnspsc190501.50341516': __('identifierSchemeCodeUnspsc190501.50341516'),
	'identifierSchemeCodeUnspsc190501.50341517': __('identifierSchemeCodeUnspsc190501.50341517'),
	'identifierSchemeCodeUnspsc190501.50341518': __('identifierSchemeCodeUnspsc190501.50341518'),
	'identifierSchemeCodeUnspsc190501.50341519': __('identifierSchemeCodeUnspsc190501.50341519'),
	'identifierSchemeCodeUnspsc190501.50341520': __('identifierSchemeCodeUnspsc190501.50341520'),
	'identifierSchemeCodeUnspsc190501.50341521': __('identifierSchemeCodeUnspsc190501.50341521'),
	'identifierSchemeCodeUnspsc190501.50341522': __('identifierSchemeCodeUnspsc190501.50341522'),
	'identifierSchemeCodeUnspsc190501.50341523': __('identifierSchemeCodeUnspsc190501.50341523'),
	'identifierSchemeCodeUnspsc190501.50341524': __('identifierSchemeCodeUnspsc190501.50341524'),
	'identifierSchemeCodeUnspsc190501.50341525': __('identifierSchemeCodeUnspsc190501.50341525'),
	'identifierSchemeCodeUnspsc190501.50341526': __('identifierSchemeCodeUnspsc190501.50341526'),
	'identifierSchemeCodeUnspsc190501.50341527': __('identifierSchemeCodeUnspsc190501.50341527'),
	'identifierSchemeCodeUnspsc190501.50341528': __('identifierSchemeCodeUnspsc190501.50341528'),
	'identifierSchemeCodeUnspsc190501.50341529': __('identifierSchemeCodeUnspsc190501.50341529'),
	'identifierSchemeCodeUnspsc190501.50341530': __('identifierSchemeCodeUnspsc190501.50341530'),
	'identifierSchemeCodeUnspsc190501.50341531': __('identifierSchemeCodeUnspsc190501.50341531'),
	'identifierSchemeCodeUnspsc190501.50341532': __('identifierSchemeCodeUnspsc190501.50341532'),
	'identifierSchemeCodeUnspsc190501.50341533': __('identifierSchemeCodeUnspsc190501.50341533'),
	'identifierSchemeCodeUnspsc190501.50341534': __('identifierSchemeCodeUnspsc190501.50341534'),
	'identifierSchemeCodeUnspsc190501.50341535': __('identifierSchemeCodeUnspsc190501.50341535'),
	'identifierSchemeCodeUnspsc190501.50341536': __('identifierSchemeCodeUnspsc190501.50341536'),
	'identifierSchemeCodeUnspsc190501.50341537': __('identifierSchemeCodeUnspsc190501.50341537'),
	'identifierSchemeCodeUnspsc190501.50341538': __('identifierSchemeCodeUnspsc190501.50341538'),
	'identifierSchemeCodeUnspsc190501.50341539': __('identifierSchemeCodeUnspsc190501.50341539'),
	'identifierSchemeCodeUnspsc190501.50341540': __('identifierSchemeCodeUnspsc190501.50341540'),
	'identifierSchemeCodeUnspsc190501.50341541': __('identifierSchemeCodeUnspsc190501.50341541'),
	'identifierSchemeCodeUnspsc190501.50341542': __('identifierSchemeCodeUnspsc190501.50341542'),
	'identifierSchemeCodeUnspsc190501.50341543': __('identifierSchemeCodeUnspsc190501.50341543'),
	'identifierSchemeCodeUnspsc190501.50341544': __('identifierSchemeCodeUnspsc190501.50341544'),
	'identifierSchemeCodeUnspsc190501.50341545': __('identifierSchemeCodeUnspsc190501.50341545'),
	'identifierSchemeCodeUnspsc190501.50341546': __('identifierSchemeCodeUnspsc190501.50341546'),
	'identifierSchemeCodeUnspsc190501.50341547': __('identifierSchemeCodeUnspsc190501.50341547'),
	'identifierSchemeCodeUnspsc190501.50341548': __('identifierSchemeCodeUnspsc190501.50341548'),
	'identifierSchemeCodeUnspsc190501.50341549': __('identifierSchemeCodeUnspsc190501.50341549'),
	'identifierSchemeCodeUnspsc190501.50341550': __('identifierSchemeCodeUnspsc190501.50341550'),
	'identifierSchemeCodeUnspsc190501.50341551': __('identifierSchemeCodeUnspsc190501.50341551'),
	'identifierSchemeCodeUnspsc190501.50341552': __('identifierSchemeCodeUnspsc190501.50341552'),
	'identifierSchemeCodeUnspsc190501.50341553': __('identifierSchemeCodeUnspsc190501.50341553'),
	'identifierSchemeCodeUnspsc190501.50341554': __('identifierSchemeCodeUnspsc190501.50341554'),
	'identifierSchemeCodeUnspsc190501.50341555': __('identifierSchemeCodeUnspsc190501.50341555'),
	'identifierSchemeCodeUnspsc190501.50341556': __('identifierSchemeCodeUnspsc190501.50341556'),
	'identifierSchemeCodeUnspsc190501.50341557': __('identifierSchemeCodeUnspsc190501.50341557'),
	'identifierSchemeCodeUnspsc190501.50341558': __('identifierSchemeCodeUnspsc190501.50341558'),
	'identifierSchemeCodeUnspsc190501.50341559': __('identifierSchemeCodeUnspsc190501.50341559'),
	'identifierSchemeCodeUnspsc190501.50341560': __('identifierSchemeCodeUnspsc190501.50341560'),
	'identifierSchemeCodeUnspsc190501.50341561': __('identifierSchemeCodeUnspsc190501.50341561'),
	'identifierSchemeCodeUnspsc190501.50341562': __('identifierSchemeCodeUnspsc190501.50341562'),
	'identifierSchemeCodeUnspsc190501.50341563': __('identifierSchemeCodeUnspsc190501.50341563'),
	'identifierSchemeCodeUnspsc190501.50341564': __('identifierSchemeCodeUnspsc190501.50341564'),
	'identifierSchemeCodeUnspsc190501.50341565': __('identifierSchemeCodeUnspsc190501.50341565'),
	'identifierSchemeCodeUnspsc190501.50341566': __('identifierSchemeCodeUnspsc190501.50341566'),
	'identifierSchemeCodeUnspsc190501.50341567': __('identifierSchemeCodeUnspsc190501.50341567'),
	'identifierSchemeCodeUnspsc190501.50341568': __('identifierSchemeCodeUnspsc190501.50341568'),
	'identifierSchemeCodeUnspsc190501.50341569': __('identifierSchemeCodeUnspsc190501.50341569'),
	'identifierSchemeCodeUnspsc190501.50341570': __('identifierSchemeCodeUnspsc190501.50341570'),
	'identifierSchemeCodeUnspsc190501.50341571': __('identifierSchemeCodeUnspsc190501.50341571'),
	'identifierSchemeCodeUnspsc190501.50341572': __('identifierSchemeCodeUnspsc190501.50341572'),
	'identifierSchemeCodeUnspsc190501.50341573': __('identifierSchemeCodeUnspsc190501.50341573'),
	'identifierSchemeCodeUnspsc190501.50341574': __('identifierSchemeCodeUnspsc190501.50341574'),
	'identifierSchemeCodeUnspsc190501.50341575': __('identifierSchemeCodeUnspsc190501.50341575'),
	'identifierSchemeCodeUnspsc190501.50341576': __('identifierSchemeCodeUnspsc190501.50341576'),
	'identifierSchemeCodeUnspsc190501.50341577': __('identifierSchemeCodeUnspsc190501.50341577'),
	'identifierSchemeCodeUnspsc190501.50341578': __('identifierSchemeCodeUnspsc190501.50341578'),
	'identifierSchemeCodeUnspsc190501.50341579': __('identifierSchemeCodeUnspsc190501.50341579'),
	'identifierSchemeCodeUnspsc190501.50341580': __('identifierSchemeCodeUnspsc190501.50341580'),
	'identifierSchemeCodeUnspsc190501.50341581': __('identifierSchemeCodeUnspsc190501.50341581'),
	'identifierSchemeCodeUnspsc190501.50341582': __('identifierSchemeCodeUnspsc190501.50341582'),
	'identifierSchemeCodeUnspsc190501.50341583': __('identifierSchemeCodeUnspsc190501.50341583'),
	'identifierSchemeCodeUnspsc190501.50341584': __('identifierSchemeCodeUnspsc190501.50341584'),
	'identifierSchemeCodeUnspsc190501.50341585': __('identifierSchemeCodeUnspsc190501.50341585'),
	'identifierSchemeCodeUnspsc190501.50341586': __('identifierSchemeCodeUnspsc190501.50341586'),
	'identifierSchemeCodeUnspsc190501.50341587': __('identifierSchemeCodeUnspsc190501.50341587'),
	'identifierSchemeCodeUnspsc190501.50341588': __('identifierSchemeCodeUnspsc190501.50341588'),
	'identifierSchemeCodeUnspsc190501.50341589': __('identifierSchemeCodeUnspsc190501.50341589'),
	'identifierSchemeCodeUnspsc190501.50341590': __('identifierSchemeCodeUnspsc190501.50341590'),
	'identifierSchemeCodeUnspsc190501.50341591': __('identifierSchemeCodeUnspsc190501.50341591'),
	'identifierSchemeCodeUnspsc190501.50341592': __('identifierSchemeCodeUnspsc190501.50341592'),
	'identifierSchemeCodeUnspsc190501.50341593': __('identifierSchemeCodeUnspsc190501.50341593'),
	'identifierSchemeCodeUnspsc190501.50341594': __('identifierSchemeCodeUnspsc190501.50341594'),
	'identifierSchemeCodeUnspsc190501.50341595': __('identifierSchemeCodeUnspsc190501.50341595'),
	'identifierSchemeCodeUnspsc190501.50341596': __('identifierSchemeCodeUnspsc190501.50341596'),
	'identifierSchemeCodeUnspsc190501.50341600': __('identifierSchemeCodeUnspsc190501.50341600'),
	'identifierSchemeCodeUnspsc190501.50341601': __('identifierSchemeCodeUnspsc190501.50341601'),
	'identifierSchemeCodeUnspsc190501.50341602': __('identifierSchemeCodeUnspsc190501.50341602'),
	'identifierSchemeCodeUnspsc190501.50341603': __('identifierSchemeCodeUnspsc190501.50341603'),
	'identifierSchemeCodeUnspsc190501.50341604': __('identifierSchemeCodeUnspsc190501.50341604'),
	'identifierSchemeCodeUnspsc190501.50341605': __('identifierSchemeCodeUnspsc190501.50341605'),
	'identifierSchemeCodeUnspsc190501.50341606': __('identifierSchemeCodeUnspsc190501.50341606'),
	'identifierSchemeCodeUnspsc190501.50341607': __('identifierSchemeCodeUnspsc190501.50341607'),
	'identifierSchemeCodeUnspsc190501.50341608': __('identifierSchemeCodeUnspsc190501.50341608'),
	'identifierSchemeCodeUnspsc190501.50341609': __('identifierSchemeCodeUnspsc190501.50341609'),
	'identifierSchemeCodeUnspsc190501.50341610': __('identifierSchemeCodeUnspsc190501.50341610'),
	'identifierSchemeCodeUnspsc190501.50341611': __('identifierSchemeCodeUnspsc190501.50341611'),
	'identifierSchemeCodeUnspsc190501.50341612': __('identifierSchemeCodeUnspsc190501.50341612'),
	'identifierSchemeCodeUnspsc190501.50341613': __('identifierSchemeCodeUnspsc190501.50341613'),
	'identifierSchemeCodeUnspsc190501.50341614': __('identifierSchemeCodeUnspsc190501.50341614'),
	'identifierSchemeCodeUnspsc190501.50341615': __('identifierSchemeCodeUnspsc190501.50341615'),
	'identifierSchemeCodeUnspsc190501.50341616': __('identifierSchemeCodeUnspsc190501.50341616'),
	'identifierSchemeCodeUnspsc190501.50341617': __('identifierSchemeCodeUnspsc190501.50341617'),
	'identifierSchemeCodeUnspsc190501.50341618': __('identifierSchemeCodeUnspsc190501.50341618'),
	'identifierSchemeCodeUnspsc190501.50341619': __('identifierSchemeCodeUnspsc190501.50341619'),
	'identifierSchemeCodeUnspsc190501.50341620': __('identifierSchemeCodeUnspsc190501.50341620'),
	'identifierSchemeCodeUnspsc190501.50341621': __('identifierSchemeCodeUnspsc190501.50341621'),
	'identifierSchemeCodeUnspsc190501.50341622': __('identifierSchemeCodeUnspsc190501.50341622'),
	'identifierSchemeCodeUnspsc190501.50341623': __('identifierSchemeCodeUnspsc190501.50341623'),
	'identifierSchemeCodeUnspsc190501.50341624': __('identifierSchemeCodeUnspsc190501.50341624'),
	'identifierSchemeCodeUnspsc190501.50341625': __('identifierSchemeCodeUnspsc190501.50341625'),
	'identifierSchemeCodeUnspsc190501.50341626': __('identifierSchemeCodeUnspsc190501.50341626'),
	'identifierSchemeCodeUnspsc190501.50341627': __('identifierSchemeCodeUnspsc190501.50341627'),
	'identifierSchemeCodeUnspsc190501.50341628': __('identifierSchemeCodeUnspsc190501.50341628'),
	'identifierSchemeCodeUnspsc190501.50341629': __('identifierSchemeCodeUnspsc190501.50341629'),
	'identifierSchemeCodeUnspsc190501.50341630': __('identifierSchemeCodeUnspsc190501.50341630'),
	'identifierSchemeCodeUnspsc190501.50341631': __('identifierSchemeCodeUnspsc190501.50341631'),
	'identifierSchemeCodeUnspsc190501.50341632': __('identifierSchemeCodeUnspsc190501.50341632'),
	'identifierSchemeCodeUnspsc190501.50341633': __('identifierSchemeCodeUnspsc190501.50341633'),
	'identifierSchemeCodeUnspsc190501.50341634': __('identifierSchemeCodeUnspsc190501.50341634'),
	'identifierSchemeCodeUnspsc190501.50341635': __('identifierSchemeCodeUnspsc190501.50341635'),
	'identifierSchemeCodeUnspsc190501.50341636': __('identifierSchemeCodeUnspsc190501.50341636'),
	'identifierSchemeCodeUnspsc190501.50341637': __('identifierSchemeCodeUnspsc190501.50341637'),
	'identifierSchemeCodeUnspsc190501.50341638': __('identifierSchemeCodeUnspsc190501.50341638'),
	'identifierSchemeCodeUnspsc190501.50341639': __('identifierSchemeCodeUnspsc190501.50341639'),
	'identifierSchemeCodeUnspsc190501.50341640': __('identifierSchemeCodeUnspsc190501.50341640'),
	'identifierSchemeCodeUnspsc190501.50341641': __('identifierSchemeCodeUnspsc190501.50341641'),
	'identifierSchemeCodeUnspsc190501.50341642': __('identifierSchemeCodeUnspsc190501.50341642'),
	'identifierSchemeCodeUnspsc190501.50341643': __('identifierSchemeCodeUnspsc190501.50341643'),
	'identifierSchemeCodeUnspsc190501.50341644': __('identifierSchemeCodeUnspsc190501.50341644'),
	'identifierSchemeCodeUnspsc190501.50341645': __('identifierSchemeCodeUnspsc190501.50341645'),
	'identifierSchemeCodeUnspsc190501.50341646': __('identifierSchemeCodeUnspsc190501.50341646'),
	'identifierSchemeCodeUnspsc190501.50341647': __('identifierSchemeCodeUnspsc190501.50341647'),
	'identifierSchemeCodeUnspsc190501.50341648': __('identifierSchemeCodeUnspsc190501.50341648'),
	'identifierSchemeCodeUnspsc190501.50341649': __('identifierSchemeCodeUnspsc190501.50341649'),
	'identifierSchemeCodeUnspsc190501.50341650': __('identifierSchemeCodeUnspsc190501.50341650'),
	'identifierSchemeCodeUnspsc190501.50341651': __('identifierSchemeCodeUnspsc190501.50341651'),
	'identifierSchemeCodeUnspsc190501.50341652': __('identifierSchemeCodeUnspsc190501.50341652'),
	'identifierSchemeCodeUnspsc190501.50341653': __('identifierSchemeCodeUnspsc190501.50341653'),
	'identifierSchemeCodeUnspsc190501.50341654': __('identifierSchemeCodeUnspsc190501.50341654'),
	'identifierSchemeCodeUnspsc190501.50341655': __('identifierSchemeCodeUnspsc190501.50341655'),
	'identifierSchemeCodeUnspsc190501.50341700': __('identifierSchemeCodeUnspsc190501.50341700'),
	'identifierSchemeCodeUnspsc190501.50341701': __('identifierSchemeCodeUnspsc190501.50341701'),
	'identifierSchemeCodeUnspsc190501.50341702': __('identifierSchemeCodeUnspsc190501.50341702'),
	'identifierSchemeCodeUnspsc190501.50341703': __('identifierSchemeCodeUnspsc190501.50341703'),
	'identifierSchemeCodeUnspsc190501.50341704': __('identifierSchemeCodeUnspsc190501.50341704'),
	'identifierSchemeCodeUnspsc190501.50341705': __('identifierSchemeCodeUnspsc190501.50341705'),
	'identifierSchemeCodeUnspsc190501.50341706': __('identifierSchemeCodeUnspsc190501.50341706'),
	'identifierSchemeCodeUnspsc190501.50341707': __('identifierSchemeCodeUnspsc190501.50341707'),
	'identifierSchemeCodeUnspsc190501.50341708': __('identifierSchemeCodeUnspsc190501.50341708'),
	'identifierSchemeCodeUnspsc190501.50341709': __('identifierSchemeCodeUnspsc190501.50341709'),
	'identifierSchemeCodeUnspsc190501.50341710': __('identifierSchemeCodeUnspsc190501.50341710'),
	'identifierSchemeCodeUnspsc190501.50341711': __('identifierSchemeCodeUnspsc190501.50341711'),
	'identifierSchemeCodeUnspsc190501.50341712': __('identifierSchemeCodeUnspsc190501.50341712'),
	'identifierSchemeCodeUnspsc190501.50341713': __('identifierSchemeCodeUnspsc190501.50341713'),
	'identifierSchemeCodeUnspsc190501.50341714': __('identifierSchemeCodeUnspsc190501.50341714'),
	'identifierSchemeCodeUnspsc190501.50341715': __('identifierSchemeCodeUnspsc190501.50341715'),
	'identifierSchemeCodeUnspsc190501.50341800': __('identifierSchemeCodeUnspsc190501.50341800'),
	'identifierSchemeCodeUnspsc190501.50341801': __('identifierSchemeCodeUnspsc190501.50341801'),
	'identifierSchemeCodeUnspsc190501.50341802': __('identifierSchemeCodeUnspsc190501.50341802'),
	'identifierSchemeCodeUnspsc190501.50341803': __('identifierSchemeCodeUnspsc190501.50341803'),
	'identifierSchemeCodeUnspsc190501.50341804': __('identifierSchemeCodeUnspsc190501.50341804'),
	'identifierSchemeCodeUnspsc190501.50341805': __('identifierSchemeCodeUnspsc190501.50341805'),
	'identifierSchemeCodeUnspsc190501.50341806': __('identifierSchemeCodeUnspsc190501.50341806'),
	'identifierSchemeCodeUnspsc190501.50341807': __('identifierSchemeCodeUnspsc190501.50341807'),
	'identifierSchemeCodeUnspsc190501.50341808': __('identifierSchemeCodeUnspsc190501.50341808'),
	'identifierSchemeCodeUnspsc190501.50341809': __('identifierSchemeCodeUnspsc190501.50341809'),
	'identifierSchemeCodeUnspsc190501.50341810': __('identifierSchemeCodeUnspsc190501.50341810'),
	'identifierSchemeCodeUnspsc190501.50341811': __('identifierSchemeCodeUnspsc190501.50341811'),
	'identifierSchemeCodeUnspsc190501.50341812': __('identifierSchemeCodeUnspsc190501.50341812'),
	'identifierSchemeCodeUnspsc190501.50341813': __('identifierSchemeCodeUnspsc190501.50341813'),
	'identifierSchemeCodeUnspsc190501.50341814': __('identifierSchemeCodeUnspsc190501.50341814'),
	'identifierSchemeCodeUnspsc190501.50341900': __('identifierSchemeCodeUnspsc190501.50341900'),
	'identifierSchemeCodeUnspsc190501.50341901': __('identifierSchemeCodeUnspsc190501.50341901'),
	'identifierSchemeCodeUnspsc190501.50341902': __('identifierSchemeCodeUnspsc190501.50341902'),
	'identifierSchemeCodeUnspsc190501.50341903': __('identifierSchemeCodeUnspsc190501.50341903'),
	'identifierSchemeCodeUnspsc190501.50342000': __('identifierSchemeCodeUnspsc190501.50342000'),
	'identifierSchemeCodeUnspsc190501.50342001': __('identifierSchemeCodeUnspsc190501.50342001'),
	'identifierSchemeCodeUnspsc190501.50342002': __('identifierSchemeCodeUnspsc190501.50342002'),
	'identifierSchemeCodeUnspsc190501.50342003': __('identifierSchemeCodeUnspsc190501.50342003'),
	'identifierSchemeCodeUnspsc190501.50342004': __('identifierSchemeCodeUnspsc190501.50342004'),
	'identifierSchemeCodeUnspsc190501.50342005': __('identifierSchemeCodeUnspsc190501.50342005'),
	'identifierSchemeCodeUnspsc190501.50342006': __('identifierSchemeCodeUnspsc190501.50342006'),
	'identifierSchemeCodeUnspsc190501.50342007': __('identifierSchemeCodeUnspsc190501.50342007'),
	'identifierSchemeCodeUnspsc190501.50342008': __('identifierSchemeCodeUnspsc190501.50342008'),
	'identifierSchemeCodeUnspsc190501.50342009': __('identifierSchemeCodeUnspsc190501.50342009'),
	'identifierSchemeCodeUnspsc190501.50342010': __('identifierSchemeCodeUnspsc190501.50342010'),
	'identifierSchemeCodeUnspsc190501.50342011': __('identifierSchemeCodeUnspsc190501.50342011'),
	'identifierSchemeCodeUnspsc190501.50342012': __('identifierSchemeCodeUnspsc190501.50342012'),
	'identifierSchemeCodeUnspsc190501.50342013': __('identifierSchemeCodeUnspsc190501.50342013'),
	'identifierSchemeCodeUnspsc190501.50342014': __('identifierSchemeCodeUnspsc190501.50342014'),
	'identifierSchemeCodeUnspsc190501.50342015': __('identifierSchemeCodeUnspsc190501.50342015'),
	'identifierSchemeCodeUnspsc190501.50342100': __('identifierSchemeCodeUnspsc190501.50342100'),
	'identifierSchemeCodeUnspsc190501.50342101': __('identifierSchemeCodeUnspsc190501.50342101'),
	'identifierSchemeCodeUnspsc190501.50342102': __('identifierSchemeCodeUnspsc190501.50342102'),
	'identifierSchemeCodeUnspsc190501.50342103': __('identifierSchemeCodeUnspsc190501.50342103'),
	'identifierSchemeCodeUnspsc190501.50342104': __('identifierSchemeCodeUnspsc190501.50342104'),
	'identifierSchemeCodeUnspsc190501.50342200': __('identifierSchemeCodeUnspsc190501.50342200'),
	'identifierSchemeCodeUnspsc190501.50342201': __('identifierSchemeCodeUnspsc190501.50342201'),
	'identifierSchemeCodeUnspsc190501.50342202': __('identifierSchemeCodeUnspsc190501.50342202'),
	'identifierSchemeCodeUnspsc190501.50342203': __('identifierSchemeCodeUnspsc190501.50342203'),
	'identifierSchemeCodeUnspsc190501.50342204': __('identifierSchemeCodeUnspsc190501.50342204'),
	'identifierSchemeCodeUnspsc190501.50342205': __('identifierSchemeCodeUnspsc190501.50342205'),
	'identifierSchemeCodeUnspsc190501.50342206': __('identifierSchemeCodeUnspsc190501.50342206'),
	'identifierSchemeCodeUnspsc190501.50342207': __('identifierSchemeCodeUnspsc190501.50342207'),
	'identifierSchemeCodeUnspsc190501.50342208': __('identifierSchemeCodeUnspsc190501.50342208'),
	'identifierSchemeCodeUnspsc190501.50342209': __('identifierSchemeCodeUnspsc190501.50342209'),
	'identifierSchemeCodeUnspsc190501.50342210': __('identifierSchemeCodeUnspsc190501.50342210'),
	'identifierSchemeCodeUnspsc190501.50342211': __('identifierSchemeCodeUnspsc190501.50342211'),
	'identifierSchemeCodeUnspsc190501.50342212': __('identifierSchemeCodeUnspsc190501.50342212'),
	'identifierSchemeCodeUnspsc190501.50342213': __('identifierSchemeCodeUnspsc190501.50342213'),
	'identifierSchemeCodeUnspsc190501.50342214': __('identifierSchemeCodeUnspsc190501.50342214'),
	'identifierSchemeCodeUnspsc190501.50342215': __('identifierSchemeCodeUnspsc190501.50342215'),
	'identifierSchemeCodeUnspsc190501.50342300': __('identifierSchemeCodeUnspsc190501.50342300'),
	'identifierSchemeCodeUnspsc190501.50342301': __('identifierSchemeCodeUnspsc190501.50342301'),
	'identifierSchemeCodeUnspsc190501.50342302': __('identifierSchemeCodeUnspsc190501.50342302'),
	'identifierSchemeCodeUnspsc190501.50342303': __('identifierSchemeCodeUnspsc190501.50342303'),
	'identifierSchemeCodeUnspsc190501.50342304': __('identifierSchemeCodeUnspsc190501.50342304'),
	'identifierSchemeCodeUnspsc190501.50342400': __('identifierSchemeCodeUnspsc190501.50342400'),
	'identifierSchemeCodeUnspsc190501.50342401': __('identifierSchemeCodeUnspsc190501.50342401'),
	'identifierSchemeCodeUnspsc190501.50342402': __('identifierSchemeCodeUnspsc190501.50342402'),
	'identifierSchemeCodeUnspsc190501.50342403': __('identifierSchemeCodeUnspsc190501.50342403'),
	'identifierSchemeCodeUnspsc190501.50342404': __('identifierSchemeCodeUnspsc190501.50342404'),
	'identifierSchemeCodeUnspsc190501.50342405': __('identifierSchemeCodeUnspsc190501.50342405'),
	'identifierSchemeCodeUnspsc190501.50342406': __('identifierSchemeCodeUnspsc190501.50342406'),
	'identifierSchemeCodeUnspsc190501.50342407': __('identifierSchemeCodeUnspsc190501.50342407'),
	'identifierSchemeCodeUnspsc190501.50342408': __('identifierSchemeCodeUnspsc190501.50342408'),
	'identifierSchemeCodeUnspsc190501.50342409': __('identifierSchemeCodeUnspsc190501.50342409'),
	'identifierSchemeCodeUnspsc190501.50342500': __('identifierSchemeCodeUnspsc190501.50342500'),
	'identifierSchemeCodeUnspsc190501.50342501': __('identifierSchemeCodeUnspsc190501.50342501'),
	'identifierSchemeCodeUnspsc190501.50342502': __('identifierSchemeCodeUnspsc190501.50342502'),
	'identifierSchemeCodeUnspsc190501.50342503': __('identifierSchemeCodeUnspsc190501.50342503'),
	'identifierSchemeCodeUnspsc190501.50342504': __('identifierSchemeCodeUnspsc190501.50342504'),
	'identifierSchemeCodeUnspsc190501.50342505': __('identifierSchemeCodeUnspsc190501.50342505'),
	'identifierSchemeCodeUnspsc190501.50342506': __('identifierSchemeCodeUnspsc190501.50342506'),
	'identifierSchemeCodeUnspsc190501.50342507': __('identifierSchemeCodeUnspsc190501.50342507'),
	'identifierSchemeCodeUnspsc190501.50342508': __('identifierSchemeCodeUnspsc190501.50342508'),
	'identifierSchemeCodeUnspsc190501.50342509': __('identifierSchemeCodeUnspsc190501.50342509'),
	'identifierSchemeCodeUnspsc190501.50342510': __('identifierSchemeCodeUnspsc190501.50342510'),
	'identifierSchemeCodeUnspsc190501.50342511': __('identifierSchemeCodeUnspsc190501.50342511'),
	'identifierSchemeCodeUnspsc190501.50342512': __('identifierSchemeCodeUnspsc190501.50342512'),
	'identifierSchemeCodeUnspsc190501.50342513': __('identifierSchemeCodeUnspsc190501.50342513'),
	'identifierSchemeCodeUnspsc190501.50342514': __('identifierSchemeCodeUnspsc190501.50342514'),
	'identifierSchemeCodeUnspsc190501.50342515': __('identifierSchemeCodeUnspsc190501.50342515'),
	'identifierSchemeCodeUnspsc190501.50342516': __('identifierSchemeCodeUnspsc190501.50342516'),
	'identifierSchemeCodeUnspsc190501.50342517': __('identifierSchemeCodeUnspsc190501.50342517'),
	'identifierSchemeCodeUnspsc190501.50342518': __('identifierSchemeCodeUnspsc190501.50342518'),
	'identifierSchemeCodeUnspsc190501.50342519': __('identifierSchemeCodeUnspsc190501.50342519'),
	'identifierSchemeCodeUnspsc190501.50342520': __('identifierSchemeCodeUnspsc190501.50342520'),
	'identifierSchemeCodeUnspsc190501.50342521': __('identifierSchemeCodeUnspsc190501.50342521'),
	'identifierSchemeCodeUnspsc190501.50342522': __('identifierSchemeCodeUnspsc190501.50342522'),
	'identifierSchemeCodeUnspsc190501.50342523': __('identifierSchemeCodeUnspsc190501.50342523'),
	'identifierSchemeCodeUnspsc190501.50342524': __('identifierSchemeCodeUnspsc190501.50342524'),
	'identifierSchemeCodeUnspsc190501.50342525': __('identifierSchemeCodeUnspsc190501.50342525'),
	'identifierSchemeCodeUnspsc190501.50342527': __('identifierSchemeCodeUnspsc190501.50342527'),
	'identifierSchemeCodeUnspsc190501.50342528': __('identifierSchemeCodeUnspsc190501.50342528'),
	'identifierSchemeCodeUnspsc190501.50342529': __('identifierSchemeCodeUnspsc190501.50342529'),
	'identifierSchemeCodeUnspsc190501.50342530': __('identifierSchemeCodeUnspsc190501.50342530'),
	'identifierSchemeCodeUnspsc190501.50342531': __('identifierSchemeCodeUnspsc190501.50342531'),
	'identifierSchemeCodeUnspsc190501.50342532': __('identifierSchemeCodeUnspsc190501.50342532'),
	'identifierSchemeCodeUnspsc190501.50342600': __('identifierSchemeCodeUnspsc190501.50342600'),
	'identifierSchemeCodeUnspsc190501.50342601': __('identifierSchemeCodeUnspsc190501.50342601'),
	'identifierSchemeCodeUnspsc190501.50342602': __('identifierSchemeCodeUnspsc190501.50342602'),
	'identifierSchemeCodeUnspsc190501.50342603': __('identifierSchemeCodeUnspsc190501.50342603'),
	'identifierSchemeCodeUnspsc190501.50342604': __('identifierSchemeCodeUnspsc190501.50342604'),
	'identifierSchemeCodeUnspsc190501.50342605': __('identifierSchemeCodeUnspsc190501.50342605'),
	'identifierSchemeCodeUnspsc190501.50342606': __('identifierSchemeCodeUnspsc190501.50342606'),
	'identifierSchemeCodeUnspsc190501.50342607': __('identifierSchemeCodeUnspsc190501.50342607'),
	'identifierSchemeCodeUnspsc190501.50342700': __('identifierSchemeCodeUnspsc190501.50342700'),
	'identifierSchemeCodeUnspsc190501.50342701': __('identifierSchemeCodeUnspsc190501.50342701'),
	'identifierSchemeCodeUnspsc190501.50342702': __('identifierSchemeCodeUnspsc190501.50342702'),
	'identifierSchemeCodeUnspsc190501.50342703': __('identifierSchemeCodeUnspsc190501.50342703'),
	'identifierSchemeCodeUnspsc190501.50342704': __('identifierSchemeCodeUnspsc190501.50342704'),
	'identifierSchemeCodeUnspsc190501.50342705': __('identifierSchemeCodeUnspsc190501.50342705'),
	'identifierSchemeCodeUnspsc190501.50342706': __('identifierSchemeCodeUnspsc190501.50342706'),
	'identifierSchemeCodeUnspsc190501.50342707': __('identifierSchemeCodeUnspsc190501.50342707'),
	'identifierSchemeCodeUnspsc190501.50342708': __('identifierSchemeCodeUnspsc190501.50342708'),
	'identifierSchemeCodeUnspsc190501.50342709': __('identifierSchemeCodeUnspsc190501.50342709'),
	'identifierSchemeCodeUnspsc190501.50342710': __('identifierSchemeCodeUnspsc190501.50342710'),
	'identifierSchemeCodeUnspsc190501.50342800': __('identifierSchemeCodeUnspsc190501.50342800'),
	'identifierSchemeCodeUnspsc190501.50342801': __('identifierSchemeCodeUnspsc190501.50342801'),
	'identifierSchemeCodeUnspsc190501.50342802': __('identifierSchemeCodeUnspsc190501.50342802'),
	'identifierSchemeCodeUnspsc190501.50342803': __('identifierSchemeCodeUnspsc190501.50342803'),
	'identifierSchemeCodeUnspsc190501.50342804': __('identifierSchemeCodeUnspsc190501.50342804'),
	'identifierSchemeCodeUnspsc190501.50342805': __('identifierSchemeCodeUnspsc190501.50342805'),
	'identifierSchemeCodeUnspsc190501.50342806': __('identifierSchemeCodeUnspsc190501.50342806'),
	'identifierSchemeCodeUnspsc190501.50342900': __('identifierSchemeCodeUnspsc190501.50342900'),
	'identifierSchemeCodeUnspsc190501.50342901': __('identifierSchemeCodeUnspsc190501.50342901'),
	'identifierSchemeCodeUnspsc190501.50342902': __('identifierSchemeCodeUnspsc190501.50342902'),
	'identifierSchemeCodeUnspsc190501.50342903': __('identifierSchemeCodeUnspsc190501.50342903'),
	'identifierSchemeCodeUnspsc190501.50342904': __('identifierSchemeCodeUnspsc190501.50342904'),
	'identifierSchemeCodeUnspsc190501.50342905': __('identifierSchemeCodeUnspsc190501.50342905'),
	'identifierSchemeCodeUnspsc190501.50342906': __('identifierSchemeCodeUnspsc190501.50342906'),
	'identifierSchemeCodeUnspsc190501.50342907': __('identifierSchemeCodeUnspsc190501.50342907'),
	'identifierSchemeCodeUnspsc190501.50342908': __('identifierSchemeCodeUnspsc190501.50342908'),
	'identifierSchemeCodeUnspsc190501.50342909': __('identifierSchemeCodeUnspsc190501.50342909'),
	'identifierSchemeCodeUnspsc190501.50342910': __('identifierSchemeCodeUnspsc190501.50342910'),
	'identifierSchemeCodeUnspsc190501.50342911': __('identifierSchemeCodeUnspsc190501.50342911'),
	'identifierSchemeCodeUnspsc190501.50342912': __('identifierSchemeCodeUnspsc190501.50342912'),
	'identifierSchemeCodeUnspsc190501.50342913': __('identifierSchemeCodeUnspsc190501.50342913'),
	'identifierSchemeCodeUnspsc190501.50342914': __('identifierSchemeCodeUnspsc190501.50342914'),
	'identifierSchemeCodeUnspsc190501.50342915': __('identifierSchemeCodeUnspsc190501.50342915'),
	'identifierSchemeCodeUnspsc190501.50342916': __('identifierSchemeCodeUnspsc190501.50342916'),
	'identifierSchemeCodeUnspsc190501.50343000': __('identifierSchemeCodeUnspsc190501.50343000'),
	'identifierSchemeCodeUnspsc190501.50343001': __('identifierSchemeCodeUnspsc190501.50343001'),
	'identifierSchemeCodeUnspsc190501.50343002': __('identifierSchemeCodeUnspsc190501.50343002'),
	'identifierSchemeCodeUnspsc190501.50343100': __('identifierSchemeCodeUnspsc190501.50343100'),
	'identifierSchemeCodeUnspsc190501.50343101': __('identifierSchemeCodeUnspsc190501.50343101'),
	'identifierSchemeCodeUnspsc190501.50343102': __('identifierSchemeCodeUnspsc190501.50343102'),
	'identifierSchemeCodeUnspsc190501.50343103': __('identifierSchemeCodeUnspsc190501.50343103'),
	'identifierSchemeCodeUnspsc190501.50343104': __('identifierSchemeCodeUnspsc190501.50343104'),
	'identifierSchemeCodeUnspsc190501.50343105': __('identifierSchemeCodeUnspsc190501.50343105'),
	'identifierSchemeCodeUnspsc190501.50343106': __('identifierSchemeCodeUnspsc190501.50343106'),
	'identifierSchemeCodeUnspsc190501.50343107': __('identifierSchemeCodeUnspsc190501.50343107'),
	'identifierSchemeCodeUnspsc190501.50343108': __('identifierSchemeCodeUnspsc190501.50343108'),
	'identifierSchemeCodeUnspsc190501.50343109': __('identifierSchemeCodeUnspsc190501.50343109'),
	'identifierSchemeCodeUnspsc190501.50343110': __('identifierSchemeCodeUnspsc190501.50343110'),
	'identifierSchemeCodeUnspsc190501.50343111': __('identifierSchemeCodeUnspsc190501.50343111'),
	'identifierSchemeCodeUnspsc190501.50343200': __('identifierSchemeCodeUnspsc190501.50343200'),
	'identifierSchemeCodeUnspsc190501.50343201': __('identifierSchemeCodeUnspsc190501.50343201'),
	'identifierSchemeCodeUnspsc190501.50343202': __('identifierSchemeCodeUnspsc190501.50343202'),
	'identifierSchemeCodeUnspsc190501.50343203': __('identifierSchemeCodeUnspsc190501.50343203'),
	'identifierSchemeCodeUnspsc190501.50343204': __('identifierSchemeCodeUnspsc190501.50343204'),
	'identifierSchemeCodeUnspsc190501.50343205': __('identifierSchemeCodeUnspsc190501.50343205'),
	'identifierSchemeCodeUnspsc190501.50343206': __('identifierSchemeCodeUnspsc190501.50343206'),
	'identifierSchemeCodeUnspsc190501.50343207': __('identifierSchemeCodeUnspsc190501.50343207'),
	'identifierSchemeCodeUnspsc190501.50343300': __('identifierSchemeCodeUnspsc190501.50343300'),
	'identifierSchemeCodeUnspsc190501.50343301': __('identifierSchemeCodeUnspsc190501.50343301'),
	'identifierSchemeCodeUnspsc190501.50343302': __('identifierSchemeCodeUnspsc190501.50343302'),
	'identifierSchemeCodeUnspsc190501.50343303': __('identifierSchemeCodeUnspsc190501.50343303'),
	'identifierSchemeCodeUnspsc190501.50343304': __('identifierSchemeCodeUnspsc190501.50343304'),
	'identifierSchemeCodeUnspsc190501.50343305': __('identifierSchemeCodeUnspsc190501.50343305'),
	'identifierSchemeCodeUnspsc190501.50343306': __('identifierSchemeCodeUnspsc190501.50343306'),
	'identifierSchemeCodeUnspsc190501.50343307': __('identifierSchemeCodeUnspsc190501.50343307'),
	'identifierSchemeCodeUnspsc190501.50343308': __('identifierSchemeCodeUnspsc190501.50343308'),
	'identifierSchemeCodeUnspsc190501.50343309': __('identifierSchemeCodeUnspsc190501.50343309'),
	'identifierSchemeCodeUnspsc190501.50343400': __('identifierSchemeCodeUnspsc190501.50343400'),
	'identifierSchemeCodeUnspsc190501.50343401': __('identifierSchemeCodeUnspsc190501.50343401'),
	'identifierSchemeCodeUnspsc190501.50343402': __('identifierSchemeCodeUnspsc190501.50343402'),
	'identifierSchemeCodeUnspsc190501.50343403': __('identifierSchemeCodeUnspsc190501.50343403'),
	'identifierSchemeCodeUnspsc190501.50343404': __('identifierSchemeCodeUnspsc190501.50343404'),
	'identifierSchemeCodeUnspsc190501.50343405': __('identifierSchemeCodeUnspsc190501.50343405'),
	'identifierSchemeCodeUnspsc190501.50343406': __('identifierSchemeCodeUnspsc190501.50343406'),
	'identifierSchemeCodeUnspsc190501.50343407': __('identifierSchemeCodeUnspsc190501.50343407'),
	'identifierSchemeCodeUnspsc190501.50343408': __('identifierSchemeCodeUnspsc190501.50343408'),
	'identifierSchemeCodeUnspsc190501.50343409': __('identifierSchemeCodeUnspsc190501.50343409'),
	'identifierSchemeCodeUnspsc190501.50343410': __('identifierSchemeCodeUnspsc190501.50343410'),
	'identifierSchemeCodeUnspsc190501.50343411': __('identifierSchemeCodeUnspsc190501.50343411'),
	'identifierSchemeCodeUnspsc190501.50343412': __('identifierSchemeCodeUnspsc190501.50343412'),
	'identifierSchemeCodeUnspsc190501.50343413': __('identifierSchemeCodeUnspsc190501.50343413'),
	'identifierSchemeCodeUnspsc190501.50343414': __('identifierSchemeCodeUnspsc190501.50343414'),
	'identifierSchemeCodeUnspsc190501.50343415': __('identifierSchemeCodeUnspsc190501.50343415'),
	'identifierSchemeCodeUnspsc190501.50343416': __('identifierSchemeCodeUnspsc190501.50343416'),
	'identifierSchemeCodeUnspsc190501.50343417': __('identifierSchemeCodeUnspsc190501.50343417'),
	'identifierSchemeCodeUnspsc190501.50343418': __('identifierSchemeCodeUnspsc190501.50343418'),
	'identifierSchemeCodeUnspsc190501.50343419': __('identifierSchemeCodeUnspsc190501.50343419'),
	'identifierSchemeCodeUnspsc190501.50343420': __('identifierSchemeCodeUnspsc190501.50343420'),
	'identifierSchemeCodeUnspsc190501.50343421': __('identifierSchemeCodeUnspsc190501.50343421'),
	'identifierSchemeCodeUnspsc190501.50343422': __('identifierSchemeCodeUnspsc190501.50343422'),
	'identifierSchemeCodeUnspsc190501.50343423': __('identifierSchemeCodeUnspsc190501.50343423'),
	'identifierSchemeCodeUnspsc190501.50343424': __('identifierSchemeCodeUnspsc190501.50343424'),
	'identifierSchemeCodeUnspsc190501.50343425': __('identifierSchemeCodeUnspsc190501.50343425'),
	'identifierSchemeCodeUnspsc190501.50343426': __('identifierSchemeCodeUnspsc190501.50343426'),
	'identifierSchemeCodeUnspsc190501.50343427': __('identifierSchemeCodeUnspsc190501.50343427'),
	'identifierSchemeCodeUnspsc190501.50343428': __('identifierSchemeCodeUnspsc190501.50343428'),
	'identifierSchemeCodeUnspsc190501.50343429': __('identifierSchemeCodeUnspsc190501.50343429'),
	'identifierSchemeCodeUnspsc190501.50343430': __('identifierSchemeCodeUnspsc190501.50343430'),
	'identifierSchemeCodeUnspsc190501.50343431': __('identifierSchemeCodeUnspsc190501.50343431'),
	'identifierSchemeCodeUnspsc190501.50343432': __('identifierSchemeCodeUnspsc190501.50343432'),
	'identifierSchemeCodeUnspsc190501.50343433': __('identifierSchemeCodeUnspsc190501.50343433'),
	'identifierSchemeCodeUnspsc190501.50343434': __('identifierSchemeCodeUnspsc190501.50343434'),
	'identifierSchemeCodeUnspsc190501.50343435': __('identifierSchemeCodeUnspsc190501.50343435'),
	'identifierSchemeCodeUnspsc190501.50343436': __('identifierSchemeCodeUnspsc190501.50343436'),
	'identifierSchemeCodeUnspsc190501.50343437': __('identifierSchemeCodeUnspsc190501.50343437'),
	'identifierSchemeCodeUnspsc190501.50343438': __('identifierSchemeCodeUnspsc190501.50343438'),
	'identifierSchemeCodeUnspsc190501.50343439': __('identifierSchemeCodeUnspsc190501.50343439'),
	'identifierSchemeCodeUnspsc190501.50343440': __('identifierSchemeCodeUnspsc190501.50343440'),
	'identifierSchemeCodeUnspsc190501.50343441': __('identifierSchemeCodeUnspsc190501.50343441'),
	'identifierSchemeCodeUnspsc190501.50343442': __('identifierSchemeCodeUnspsc190501.50343442'),
	'identifierSchemeCodeUnspsc190501.50343443': __('identifierSchemeCodeUnspsc190501.50343443'),
	'identifierSchemeCodeUnspsc190501.50343444': __('identifierSchemeCodeUnspsc190501.50343444'),
	'identifierSchemeCodeUnspsc190501.50343445': __('identifierSchemeCodeUnspsc190501.50343445'),
	'identifierSchemeCodeUnspsc190501.50343446': __('identifierSchemeCodeUnspsc190501.50343446'),
	'identifierSchemeCodeUnspsc190501.50343447': __('identifierSchemeCodeUnspsc190501.50343447'),
	'identifierSchemeCodeUnspsc190501.50343448': __('identifierSchemeCodeUnspsc190501.50343448'),
	'identifierSchemeCodeUnspsc190501.50343449': __('identifierSchemeCodeUnspsc190501.50343449'),
	'identifierSchemeCodeUnspsc190501.50343450': __('identifierSchemeCodeUnspsc190501.50343450'),
	'identifierSchemeCodeUnspsc190501.50343451': __('identifierSchemeCodeUnspsc190501.50343451'),
	'identifierSchemeCodeUnspsc190501.50343452': __('identifierSchemeCodeUnspsc190501.50343452'),
	'identifierSchemeCodeUnspsc190501.50343453': __('identifierSchemeCodeUnspsc190501.50343453'),
	'identifierSchemeCodeUnspsc190501.50343454': __('identifierSchemeCodeUnspsc190501.50343454'),
	'identifierSchemeCodeUnspsc190501.50343455': __('identifierSchemeCodeUnspsc190501.50343455'),
	'identifierSchemeCodeUnspsc190501.50343456': __('identifierSchemeCodeUnspsc190501.50343456'),
	'identifierSchemeCodeUnspsc190501.50343457': __('identifierSchemeCodeUnspsc190501.50343457'),
	'identifierSchemeCodeUnspsc190501.50343458': __('identifierSchemeCodeUnspsc190501.50343458'),
	'identifierSchemeCodeUnspsc190501.50343459': __('identifierSchemeCodeUnspsc190501.50343459'),
	'identifierSchemeCodeUnspsc190501.50343460': __('identifierSchemeCodeUnspsc190501.50343460'),
	'identifierSchemeCodeUnspsc190501.50343461': __('identifierSchemeCodeUnspsc190501.50343461'),
	'identifierSchemeCodeUnspsc190501.50343462': __('identifierSchemeCodeUnspsc190501.50343462'),
	'identifierSchemeCodeUnspsc190501.50343463': __('identifierSchemeCodeUnspsc190501.50343463'),
	'identifierSchemeCodeUnspsc190501.50343464': __('identifierSchemeCodeUnspsc190501.50343464'),
	'identifierSchemeCodeUnspsc190501.50343465': __('identifierSchemeCodeUnspsc190501.50343465'),
	'identifierSchemeCodeUnspsc190501.50343466': __('identifierSchemeCodeUnspsc190501.50343466'),
	'identifierSchemeCodeUnspsc190501.50343467': __('identifierSchemeCodeUnspsc190501.50343467'),
	'identifierSchemeCodeUnspsc190501.50343468': __('identifierSchemeCodeUnspsc190501.50343468'),
	'identifierSchemeCodeUnspsc190501.50343469': __('identifierSchemeCodeUnspsc190501.50343469'),
	'identifierSchemeCodeUnspsc190501.50343470': __('identifierSchemeCodeUnspsc190501.50343470'),
	'identifierSchemeCodeUnspsc190501.50343471': __('identifierSchemeCodeUnspsc190501.50343471'),
	'identifierSchemeCodeUnspsc190501.50343472': __('identifierSchemeCodeUnspsc190501.50343472'),
	'identifierSchemeCodeUnspsc190501.50343473': __('identifierSchemeCodeUnspsc190501.50343473'),
	'identifierSchemeCodeUnspsc190501.50343474': __('identifierSchemeCodeUnspsc190501.50343474'),
	'identifierSchemeCodeUnspsc190501.50343475': __('identifierSchemeCodeUnspsc190501.50343475'),
	'identifierSchemeCodeUnspsc190501.50343476': __('identifierSchemeCodeUnspsc190501.50343476'),
	'identifierSchemeCodeUnspsc190501.50343477': __('identifierSchemeCodeUnspsc190501.50343477'),
	'identifierSchemeCodeUnspsc190501.50343478': __('identifierSchemeCodeUnspsc190501.50343478'),
	'identifierSchemeCodeUnspsc190501.50343479': __('identifierSchemeCodeUnspsc190501.50343479'),
	'identifierSchemeCodeUnspsc190501.50343480': __('identifierSchemeCodeUnspsc190501.50343480'),
	'identifierSchemeCodeUnspsc190501.50343481': __('identifierSchemeCodeUnspsc190501.50343481'),
	'identifierSchemeCodeUnspsc190501.50343482': __('identifierSchemeCodeUnspsc190501.50343482'),
	'identifierSchemeCodeUnspsc190501.50343483': __('identifierSchemeCodeUnspsc190501.50343483'),
	'identifierSchemeCodeUnspsc190501.50343484': __('identifierSchemeCodeUnspsc190501.50343484'),
	'identifierSchemeCodeUnspsc190501.50343485': __('identifierSchemeCodeUnspsc190501.50343485'),
	'identifierSchemeCodeUnspsc190501.50343486': __('identifierSchemeCodeUnspsc190501.50343486'),
	'identifierSchemeCodeUnspsc190501.50343487': __('identifierSchemeCodeUnspsc190501.50343487'),
	'identifierSchemeCodeUnspsc190501.50343488': __('identifierSchemeCodeUnspsc190501.50343488'),
	'identifierSchemeCodeUnspsc190501.50343489': __('identifierSchemeCodeUnspsc190501.50343489'),
	'identifierSchemeCodeUnspsc190501.50343490': __('identifierSchemeCodeUnspsc190501.50343490'),
	'identifierSchemeCodeUnspsc190501.50343491': __('identifierSchemeCodeUnspsc190501.50343491'),
	'identifierSchemeCodeUnspsc190501.50343492': __('identifierSchemeCodeUnspsc190501.50343492'),
	'identifierSchemeCodeUnspsc190501.50343493': __('identifierSchemeCodeUnspsc190501.50343493'),
	'identifierSchemeCodeUnspsc190501.50343494': __('identifierSchemeCodeUnspsc190501.50343494'),
	'identifierSchemeCodeUnspsc190501.50343495': __('identifierSchemeCodeUnspsc190501.50343495'),
	'identifierSchemeCodeUnspsc190501.50343496': __('identifierSchemeCodeUnspsc190501.50343496'),
	'identifierSchemeCodeUnspsc190501.50343500': __('identifierSchemeCodeUnspsc190501.50343500'),
	'identifierSchemeCodeUnspsc190501.50343501': __('identifierSchemeCodeUnspsc190501.50343501'),
	'identifierSchemeCodeUnspsc190501.50343502': __('identifierSchemeCodeUnspsc190501.50343502'),
	'identifierSchemeCodeUnspsc190501.50343503': __('identifierSchemeCodeUnspsc190501.50343503'),
	'identifierSchemeCodeUnspsc190501.50343504': __('identifierSchemeCodeUnspsc190501.50343504'),
	'identifierSchemeCodeUnspsc190501.50343505': __('identifierSchemeCodeUnspsc190501.50343505'),
	'identifierSchemeCodeUnspsc190501.50343506': __('identifierSchemeCodeUnspsc190501.50343506'),
	'identifierSchemeCodeUnspsc190501.50343600': __('identifierSchemeCodeUnspsc190501.50343600'),
	'identifierSchemeCodeUnspsc190501.50343601': __('identifierSchemeCodeUnspsc190501.50343601'),
	'identifierSchemeCodeUnspsc190501.50343602': __('identifierSchemeCodeUnspsc190501.50343602'),
	'identifierSchemeCodeUnspsc190501.50343603': __('identifierSchemeCodeUnspsc190501.50343603'),
	'identifierSchemeCodeUnspsc190501.50343604': __('identifierSchemeCodeUnspsc190501.50343604'),
	'identifierSchemeCodeUnspsc190501.50343605': __('identifierSchemeCodeUnspsc190501.50343605'),
	'identifierSchemeCodeUnspsc190501.50343606': __('identifierSchemeCodeUnspsc190501.50343606'),
	'identifierSchemeCodeUnspsc190501.50343607': __('identifierSchemeCodeUnspsc190501.50343607'),
	'identifierSchemeCodeUnspsc190501.50343608': __('identifierSchemeCodeUnspsc190501.50343608'),
	'identifierSchemeCodeUnspsc190501.50343609': __('identifierSchemeCodeUnspsc190501.50343609'),
	'identifierSchemeCodeUnspsc190501.50343610': __('identifierSchemeCodeUnspsc190501.50343610'),
	'identifierSchemeCodeUnspsc190501.50343611': __('identifierSchemeCodeUnspsc190501.50343611'),
	'identifierSchemeCodeUnspsc190501.50343612': __('identifierSchemeCodeUnspsc190501.50343612'),
	'identifierSchemeCodeUnspsc190501.50343613': __('identifierSchemeCodeUnspsc190501.50343613'),
	'identifierSchemeCodeUnspsc190501.50343614': __('identifierSchemeCodeUnspsc190501.50343614'),
	'identifierSchemeCodeUnspsc190501.50343615': __('identifierSchemeCodeUnspsc190501.50343615'),
	'identifierSchemeCodeUnspsc190501.50343616': __('identifierSchemeCodeUnspsc190501.50343616'),
	'identifierSchemeCodeUnspsc190501.50343617': __('identifierSchemeCodeUnspsc190501.50343617'),
	'identifierSchemeCodeUnspsc190501.50343618': __('identifierSchemeCodeUnspsc190501.50343618'),
	'identifierSchemeCodeUnspsc190501.50343619': __('identifierSchemeCodeUnspsc190501.50343619'),
	'identifierSchemeCodeUnspsc190501.50343620': __('identifierSchemeCodeUnspsc190501.50343620'),
	'identifierSchemeCodeUnspsc190501.50343621': __('identifierSchemeCodeUnspsc190501.50343621'),
	'identifierSchemeCodeUnspsc190501.50343622': __('identifierSchemeCodeUnspsc190501.50343622'),
	'identifierSchemeCodeUnspsc190501.50343623': __('identifierSchemeCodeUnspsc190501.50343623'),
	'identifierSchemeCodeUnspsc190501.50343624': __('identifierSchemeCodeUnspsc190501.50343624'),
	'identifierSchemeCodeUnspsc190501.50343625': __('identifierSchemeCodeUnspsc190501.50343625'),
	'identifierSchemeCodeUnspsc190501.50343626': __('identifierSchemeCodeUnspsc190501.50343626'),
	'identifierSchemeCodeUnspsc190501.50343627': __('identifierSchemeCodeUnspsc190501.50343627'),
	'identifierSchemeCodeUnspsc190501.50343628': __('identifierSchemeCodeUnspsc190501.50343628'),
	'identifierSchemeCodeUnspsc190501.50343629': __('identifierSchemeCodeUnspsc190501.50343629'),
	'identifierSchemeCodeUnspsc190501.50343630': __('identifierSchemeCodeUnspsc190501.50343630'),
	'identifierSchemeCodeUnspsc190501.50343631': __('identifierSchemeCodeUnspsc190501.50343631'),
	'identifierSchemeCodeUnspsc190501.50343632': __('identifierSchemeCodeUnspsc190501.50343632'),
	'identifierSchemeCodeUnspsc190501.50343633': __('identifierSchemeCodeUnspsc190501.50343633'),
	'identifierSchemeCodeUnspsc190501.50343634': __('identifierSchemeCodeUnspsc190501.50343634'),
	'identifierSchemeCodeUnspsc190501.50343635': __('identifierSchemeCodeUnspsc190501.50343635'),
	'identifierSchemeCodeUnspsc190501.50343636': __('identifierSchemeCodeUnspsc190501.50343636'),
	'identifierSchemeCodeUnspsc190501.50343637': __('identifierSchemeCodeUnspsc190501.50343637'),
	'identifierSchemeCodeUnspsc190501.50343638': __('identifierSchemeCodeUnspsc190501.50343638'),
	'identifierSchemeCodeUnspsc190501.50343639': __('identifierSchemeCodeUnspsc190501.50343639'),
	'identifierSchemeCodeUnspsc190501.50343640': __('identifierSchemeCodeUnspsc190501.50343640'),
	'identifierSchemeCodeUnspsc190501.50343641': __('identifierSchemeCodeUnspsc190501.50343641'),
	'identifierSchemeCodeUnspsc190501.50343642': __('identifierSchemeCodeUnspsc190501.50343642'),
	'identifierSchemeCodeUnspsc190501.50343643': __('identifierSchemeCodeUnspsc190501.50343643'),
	'identifierSchemeCodeUnspsc190501.50343644': __('identifierSchemeCodeUnspsc190501.50343644'),
	'identifierSchemeCodeUnspsc190501.50343645': __('identifierSchemeCodeUnspsc190501.50343645'),
	'identifierSchemeCodeUnspsc190501.50343646': __('identifierSchemeCodeUnspsc190501.50343646'),
	'identifierSchemeCodeUnspsc190501.50343647': __('identifierSchemeCodeUnspsc190501.50343647'),
	'identifierSchemeCodeUnspsc190501.50343648': __('identifierSchemeCodeUnspsc190501.50343648'),
	'identifierSchemeCodeUnspsc190501.50343649': __('identifierSchemeCodeUnspsc190501.50343649'),
	'identifierSchemeCodeUnspsc190501.50343650': __('identifierSchemeCodeUnspsc190501.50343650'),
	'identifierSchemeCodeUnspsc190501.50343651': __('identifierSchemeCodeUnspsc190501.50343651'),
	'identifierSchemeCodeUnspsc190501.50343652': __('identifierSchemeCodeUnspsc190501.50343652'),
	'identifierSchemeCodeUnspsc190501.50343653': __('identifierSchemeCodeUnspsc190501.50343653'),
	'identifierSchemeCodeUnspsc190501.50343654': __('identifierSchemeCodeUnspsc190501.50343654'),
	'identifierSchemeCodeUnspsc190501.50343655': __('identifierSchemeCodeUnspsc190501.50343655'),
	'identifierSchemeCodeUnspsc190501.50343656': __('identifierSchemeCodeUnspsc190501.50343656'),
	'identifierSchemeCodeUnspsc190501.50343657': __('identifierSchemeCodeUnspsc190501.50343657'),
	'identifierSchemeCodeUnspsc190501.50343658': __('identifierSchemeCodeUnspsc190501.50343658'),
	'identifierSchemeCodeUnspsc190501.50343659': __('identifierSchemeCodeUnspsc190501.50343659'),
	'identifierSchemeCodeUnspsc190501.50343660': __('identifierSchemeCodeUnspsc190501.50343660'),
	'identifierSchemeCodeUnspsc190501.50343661': __('identifierSchemeCodeUnspsc190501.50343661'),
	'identifierSchemeCodeUnspsc190501.50343662': __('identifierSchemeCodeUnspsc190501.50343662'),
	'identifierSchemeCodeUnspsc190501.50343663': __('identifierSchemeCodeUnspsc190501.50343663'),
	'identifierSchemeCodeUnspsc190501.50343664': __('identifierSchemeCodeUnspsc190501.50343664'),
	'identifierSchemeCodeUnspsc190501.50343665': __('identifierSchemeCodeUnspsc190501.50343665'),
	'identifierSchemeCodeUnspsc190501.50343700': __('identifierSchemeCodeUnspsc190501.50343700'),
	'identifierSchemeCodeUnspsc190501.50343701': __('identifierSchemeCodeUnspsc190501.50343701'),
	'identifierSchemeCodeUnspsc190501.50343702': __('identifierSchemeCodeUnspsc190501.50343702'),
	'identifierSchemeCodeUnspsc190501.50343703': __('identifierSchemeCodeUnspsc190501.50343703'),
	'identifierSchemeCodeUnspsc190501.50343704': __('identifierSchemeCodeUnspsc190501.50343704'),
	'identifierSchemeCodeUnspsc190501.50343800': __('identifierSchemeCodeUnspsc190501.50343800'),
	'identifierSchemeCodeUnspsc190501.50343801': __('identifierSchemeCodeUnspsc190501.50343801'),
	'identifierSchemeCodeUnspsc190501.50343802': __('identifierSchemeCodeUnspsc190501.50343802'),
	'identifierSchemeCodeUnspsc190501.50343803': __('identifierSchemeCodeUnspsc190501.50343803'),
	'identifierSchemeCodeUnspsc190501.50343804': __('identifierSchemeCodeUnspsc190501.50343804'),
	'identifierSchemeCodeUnspsc190501.50343805': __('identifierSchemeCodeUnspsc190501.50343805'),
	'identifierSchemeCodeUnspsc190501.50343900': __('identifierSchemeCodeUnspsc190501.50343900'),
	'identifierSchemeCodeUnspsc190501.50343901': __('identifierSchemeCodeUnspsc190501.50343901'),
	'identifierSchemeCodeUnspsc190501.50343902': __('identifierSchemeCodeUnspsc190501.50343902'),
	'identifierSchemeCodeUnspsc190501.50343903': __('identifierSchemeCodeUnspsc190501.50343903'),
	'identifierSchemeCodeUnspsc190501.50343904': __('identifierSchemeCodeUnspsc190501.50343904'),
	'identifierSchemeCodeUnspsc190501.50343905': __('identifierSchemeCodeUnspsc190501.50343905'),
	'identifierSchemeCodeUnspsc190501.50343906': __('identifierSchemeCodeUnspsc190501.50343906'),
	'identifierSchemeCodeUnspsc190501.50344000': __('identifierSchemeCodeUnspsc190501.50344000'),
	'identifierSchemeCodeUnspsc190501.50344001': __('identifierSchemeCodeUnspsc190501.50344001'),
	'identifierSchemeCodeUnspsc190501.50344002': __('identifierSchemeCodeUnspsc190501.50344002'),
	'identifierSchemeCodeUnspsc190501.50344003': __('identifierSchemeCodeUnspsc190501.50344003'),
	'identifierSchemeCodeUnspsc190501.50344004': __('identifierSchemeCodeUnspsc190501.50344004'),
	'identifierSchemeCodeUnspsc190501.50344005': __('identifierSchemeCodeUnspsc190501.50344005'),
	'identifierSchemeCodeUnspsc190501.50344006': __('identifierSchemeCodeUnspsc190501.50344006'),
	'identifierSchemeCodeUnspsc190501.50344100': __('identifierSchemeCodeUnspsc190501.50344100'),
	'identifierSchemeCodeUnspsc190501.50344101': __('identifierSchemeCodeUnspsc190501.50344101'),
	'identifierSchemeCodeUnspsc190501.50344102': __('identifierSchemeCodeUnspsc190501.50344102'),
	'identifierSchemeCodeUnspsc190501.50344103': __('identifierSchemeCodeUnspsc190501.50344103'),
	'identifierSchemeCodeUnspsc190501.50344104': __('identifierSchemeCodeUnspsc190501.50344104'),
	'identifierSchemeCodeUnspsc190501.50344105': __('identifierSchemeCodeUnspsc190501.50344105'),
	'identifierSchemeCodeUnspsc190501.50344106': __('identifierSchemeCodeUnspsc190501.50344106'),
	'identifierSchemeCodeUnspsc190501.50344107': __('identifierSchemeCodeUnspsc190501.50344107'),
	'identifierSchemeCodeUnspsc190501.50344108': __('identifierSchemeCodeUnspsc190501.50344108'),
	'identifierSchemeCodeUnspsc190501.50344200': __('identifierSchemeCodeUnspsc190501.50344200'),
	'identifierSchemeCodeUnspsc190501.50344201': __('identifierSchemeCodeUnspsc190501.50344201'),
	'identifierSchemeCodeUnspsc190501.50344202': __('identifierSchemeCodeUnspsc190501.50344202'),
	'identifierSchemeCodeUnspsc190501.50344203': __('identifierSchemeCodeUnspsc190501.50344203'),
	'identifierSchemeCodeUnspsc190501.50344204': __('identifierSchemeCodeUnspsc190501.50344204'),
	'identifierSchemeCodeUnspsc190501.50344205': __('identifierSchemeCodeUnspsc190501.50344205'),
	'identifierSchemeCodeUnspsc190501.50344206': __('identifierSchemeCodeUnspsc190501.50344206'),
	'identifierSchemeCodeUnspsc190501.50344207': __('identifierSchemeCodeUnspsc190501.50344207'),
	'identifierSchemeCodeUnspsc190501.50344208': __('identifierSchemeCodeUnspsc190501.50344208'),
	'identifierSchemeCodeUnspsc190501.50344300': __('identifierSchemeCodeUnspsc190501.50344300'),
	'identifierSchemeCodeUnspsc190501.50344301': __('identifierSchemeCodeUnspsc190501.50344301'),
	'identifierSchemeCodeUnspsc190501.50344302': __('identifierSchemeCodeUnspsc190501.50344302'),
	'identifierSchemeCodeUnspsc190501.50344303': __('identifierSchemeCodeUnspsc190501.50344303'),
	'identifierSchemeCodeUnspsc190501.50344304': __('identifierSchemeCodeUnspsc190501.50344304'),
	'identifierSchemeCodeUnspsc190501.50344305': __('identifierSchemeCodeUnspsc190501.50344305'),
	'identifierSchemeCodeUnspsc190501.50344306': __('identifierSchemeCodeUnspsc190501.50344306'),
	'identifierSchemeCodeUnspsc190501.50344307': __('identifierSchemeCodeUnspsc190501.50344307'),
	'identifierSchemeCodeUnspsc190501.50344308': __('identifierSchemeCodeUnspsc190501.50344308'),
	'identifierSchemeCodeUnspsc190501.50344309': __('identifierSchemeCodeUnspsc190501.50344309'),
	'identifierSchemeCodeUnspsc190501.50344310': __('identifierSchemeCodeUnspsc190501.50344310'),
	'identifierSchemeCodeUnspsc190501.50344311': __('identifierSchemeCodeUnspsc190501.50344311'),
	'identifierSchemeCodeUnspsc190501.50344312': __('identifierSchemeCodeUnspsc190501.50344312'),
	'identifierSchemeCodeUnspsc190501.50344313': __('identifierSchemeCodeUnspsc190501.50344313'),
	'identifierSchemeCodeUnspsc190501.50344400': __('identifierSchemeCodeUnspsc190501.50344400'),
	'identifierSchemeCodeUnspsc190501.50344401': __('identifierSchemeCodeUnspsc190501.50344401'),
	'identifierSchemeCodeUnspsc190501.50344402': __('identifierSchemeCodeUnspsc190501.50344402'),
	'identifierSchemeCodeUnspsc190501.50344403': __('identifierSchemeCodeUnspsc190501.50344403'),
	'identifierSchemeCodeUnspsc190501.50344404': __('identifierSchemeCodeUnspsc190501.50344404'),
	'identifierSchemeCodeUnspsc190501.50344405': __('identifierSchemeCodeUnspsc190501.50344405'),
	'identifierSchemeCodeUnspsc190501.50344406': __('identifierSchemeCodeUnspsc190501.50344406'),
	'identifierSchemeCodeUnspsc190501.50344407': __('identifierSchemeCodeUnspsc190501.50344407'),
	'identifierSchemeCodeUnspsc190501.50344408': __('identifierSchemeCodeUnspsc190501.50344408'),
	'identifierSchemeCodeUnspsc190501.50344409': __('identifierSchemeCodeUnspsc190501.50344409'),
	'identifierSchemeCodeUnspsc190501.50344410': __('identifierSchemeCodeUnspsc190501.50344410'),
	'identifierSchemeCodeUnspsc190501.50344411': __('identifierSchemeCodeUnspsc190501.50344411'),
	'identifierSchemeCodeUnspsc190501.50344412': __('identifierSchemeCodeUnspsc190501.50344412'),
	'identifierSchemeCodeUnspsc190501.50344413': __('identifierSchemeCodeUnspsc190501.50344413'),
	'identifierSchemeCodeUnspsc190501.50344414': __('identifierSchemeCodeUnspsc190501.50344414'),
	'identifierSchemeCodeUnspsc190501.50344415': __('identifierSchemeCodeUnspsc190501.50344415'),
	'identifierSchemeCodeUnspsc190501.50344416': __('identifierSchemeCodeUnspsc190501.50344416'),
	'identifierSchemeCodeUnspsc190501.50344417': __('identifierSchemeCodeUnspsc190501.50344417'),
	'identifierSchemeCodeUnspsc190501.50344418': __('identifierSchemeCodeUnspsc190501.50344418'),
	'identifierSchemeCodeUnspsc190501.50344419': __('identifierSchemeCodeUnspsc190501.50344419'),
	'identifierSchemeCodeUnspsc190501.50344420': __('identifierSchemeCodeUnspsc190501.50344420'),
	'identifierSchemeCodeUnspsc190501.50344421': __('identifierSchemeCodeUnspsc190501.50344421'),
	'identifierSchemeCodeUnspsc190501.50344422': __('identifierSchemeCodeUnspsc190501.50344422'),
	'identifierSchemeCodeUnspsc190501.50344423': __('identifierSchemeCodeUnspsc190501.50344423'),
	'identifierSchemeCodeUnspsc190501.50344424': __('identifierSchemeCodeUnspsc190501.50344424'),
	'identifierSchemeCodeUnspsc190501.50344425': __('identifierSchemeCodeUnspsc190501.50344425'),
	'identifierSchemeCodeUnspsc190501.50344426': __('identifierSchemeCodeUnspsc190501.50344426'),
	'identifierSchemeCodeUnspsc190501.50344427': __('identifierSchemeCodeUnspsc190501.50344427'),
	'identifierSchemeCodeUnspsc190501.50344428': __('identifierSchemeCodeUnspsc190501.50344428'),
	'identifierSchemeCodeUnspsc190501.50344429': __('identifierSchemeCodeUnspsc190501.50344429'),
	'identifierSchemeCodeUnspsc190501.50344430': __('identifierSchemeCodeUnspsc190501.50344430'),
	'identifierSchemeCodeUnspsc190501.50344431': __('identifierSchemeCodeUnspsc190501.50344431'),
	'identifierSchemeCodeUnspsc190501.50344432': __('identifierSchemeCodeUnspsc190501.50344432'),
	'identifierSchemeCodeUnspsc190501.50344433': __('identifierSchemeCodeUnspsc190501.50344433'),
	'identifierSchemeCodeUnspsc190501.50344434': __('identifierSchemeCodeUnspsc190501.50344434'),
	'identifierSchemeCodeUnspsc190501.50344435': __('identifierSchemeCodeUnspsc190501.50344435'),
	'identifierSchemeCodeUnspsc190501.50344436': __('identifierSchemeCodeUnspsc190501.50344436'),
	'identifierSchemeCodeUnspsc190501.50344437': __('identifierSchemeCodeUnspsc190501.50344437'),
	'identifierSchemeCodeUnspsc190501.50344438': __('identifierSchemeCodeUnspsc190501.50344438'),
	'identifierSchemeCodeUnspsc190501.50344439': __('identifierSchemeCodeUnspsc190501.50344439'),
	'identifierSchemeCodeUnspsc190501.50344440': __('identifierSchemeCodeUnspsc190501.50344440'),
	'identifierSchemeCodeUnspsc190501.50344441': __('identifierSchemeCodeUnspsc190501.50344441'),
	'identifierSchemeCodeUnspsc190501.50344442': __('identifierSchemeCodeUnspsc190501.50344442'),
	'identifierSchemeCodeUnspsc190501.50344443': __('identifierSchemeCodeUnspsc190501.50344443'),
	'identifierSchemeCodeUnspsc190501.50344444': __('identifierSchemeCodeUnspsc190501.50344444'),
	'identifierSchemeCodeUnspsc190501.50344445': __('identifierSchemeCodeUnspsc190501.50344445'),
	'identifierSchemeCodeUnspsc190501.50344446': __('identifierSchemeCodeUnspsc190501.50344446'),
	'identifierSchemeCodeUnspsc190501.50344500': __('identifierSchemeCodeUnspsc190501.50344500'),
	'identifierSchemeCodeUnspsc190501.50344501': __('identifierSchemeCodeUnspsc190501.50344501'),
	'identifierSchemeCodeUnspsc190501.50344502': __('identifierSchemeCodeUnspsc190501.50344502'),
	'identifierSchemeCodeUnspsc190501.50344503': __('identifierSchemeCodeUnspsc190501.50344503'),
	'identifierSchemeCodeUnspsc190501.50344504': __('identifierSchemeCodeUnspsc190501.50344504'),
	'identifierSchemeCodeUnspsc190501.50344505': __('identifierSchemeCodeUnspsc190501.50344505'),
	'identifierSchemeCodeUnspsc190501.50344506': __('identifierSchemeCodeUnspsc190501.50344506'),
	'identifierSchemeCodeUnspsc190501.50344507': __('identifierSchemeCodeUnspsc190501.50344507'),
	'identifierSchemeCodeUnspsc190501.50344508': __('identifierSchemeCodeUnspsc190501.50344508'),
	'identifierSchemeCodeUnspsc190501.50344509': __('identifierSchemeCodeUnspsc190501.50344509'),
	'identifierSchemeCodeUnspsc190501.50344510': __('identifierSchemeCodeUnspsc190501.50344510'),
	'identifierSchemeCodeUnspsc190501.50344511': __('identifierSchemeCodeUnspsc190501.50344511'),
	'identifierSchemeCodeUnspsc190501.50344512': __('identifierSchemeCodeUnspsc190501.50344512'),
	'identifierSchemeCodeUnspsc190501.50344513': __('identifierSchemeCodeUnspsc190501.50344513'),
	'identifierSchemeCodeUnspsc190501.50344514': __('identifierSchemeCodeUnspsc190501.50344514'),
	'identifierSchemeCodeUnspsc190501.50344515': __('identifierSchemeCodeUnspsc190501.50344515'),
	'identifierSchemeCodeUnspsc190501.50344516': __('identifierSchemeCodeUnspsc190501.50344516'),
	'identifierSchemeCodeUnspsc190501.50344517': __('identifierSchemeCodeUnspsc190501.50344517'),
	'identifierSchemeCodeUnspsc190501.50344518': __('identifierSchemeCodeUnspsc190501.50344518'),
	'identifierSchemeCodeUnspsc190501.50344519': __('identifierSchemeCodeUnspsc190501.50344519'),
	'identifierSchemeCodeUnspsc190501.50344520': __('identifierSchemeCodeUnspsc190501.50344520'),
	'identifierSchemeCodeUnspsc190501.50344521': __('identifierSchemeCodeUnspsc190501.50344521'),
	'identifierSchemeCodeUnspsc190501.50344522': __('identifierSchemeCodeUnspsc190501.50344522'),
	'identifierSchemeCodeUnspsc190501.50344523': __('identifierSchemeCodeUnspsc190501.50344523'),
	'identifierSchemeCodeUnspsc190501.50344524': __('identifierSchemeCodeUnspsc190501.50344524'),
	'identifierSchemeCodeUnspsc190501.50344525': __('identifierSchemeCodeUnspsc190501.50344525'),
	'identifierSchemeCodeUnspsc190501.50344600': __('identifierSchemeCodeUnspsc190501.50344600'),
	'identifierSchemeCodeUnspsc190501.50344601': __('identifierSchemeCodeUnspsc190501.50344601'),
	'identifierSchemeCodeUnspsc190501.50344602': __('identifierSchemeCodeUnspsc190501.50344602'),
	'identifierSchemeCodeUnspsc190501.50344603': __('identifierSchemeCodeUnspsc190501.50344603'),
	'identifierSchemeCodeUnspsc190501.50344604': __('identifierSchemeCodeUnspsc190501.50344604'),
	'identifierSchemeCodeUnspsc190501.50344605': __('identifierSchemeCodeUnspsc190501.50344605'),
	'identifierSchemeCodeUnspsc190501.50344606': __('identifierSchemeCodeUnspsc190501.50344606'),
	'identifierSchemeCodeUnspsc190501.50344607': __('identifierSchemeCodeUnspsc190501.50344607'),
	'identifierSchemeCodeUnspsc190501.50344608': __('identifierSchemeCodeUnspsc190501.50344608'),
	'identifierSchemeCodeUnspsc190501.50344609': __('identifierSchemeCodeUnspsc190501.50344609'),
	'identifierSchemeCodeUnspsc190501.50344610': __('identifierSchemeCodeUnspsc190501.50344610'),
	'identifierSchemeCodeUnspsc190501.50344611': __('identifierSchemeCodeUnspsc190501.50344611'),
	'identifierSchemeCodeUnspsc190501.50344612': __('identifierSchemeCodeUnspsc190501.50344612'),
	'identifierSchemeCodeUnspsc190501.50344613': __('identifierSchemeCodeUnspsc190501.50344613'),
	'identifierSchemeCodeUnspsc190501.50344614': __('identifierSchemeCodeUnspsc190501.50344614'),
	'identifierSchemeCodeUnspsc190501.50344615': __('identifierSchemeCodeUnspsc190501.50344615'),
	'identifierSchemeCodeUnspsc190501.50344616': __('identifierSchemeCodeUnspsc190501.50344616'),
	'identifierSchemeCodeUnspsc190501.50344617': __('identifierSchemeCodeUnspsc190501.50344617'),
	'identifierSchemeCodeUnspsc190501.50344618': __('identifierSchemeCodeUnspsc190501.50344618'),
	'identifierSchemeCodeUnspsc190501.50344619': __('identifierSchemeCodeUnspsc190501.50344619'),
	'identifierSchemeCodeUnspsc190501.50344620': __('identifierSchemeCodeUnspsc190501.50344620'),
	'identifierSchemeCodeUnspsc190501.50344621': __('identifierSchemeCodeUnspsc190501.50344621'),
	'identifierSchemeCodeUnspsc190501.50344622': __('identifierSchemeCodeUnspsc190501.50344622'),
	'identifierSchemeCodeUnspsc190501.50344623': __('identifierSchemeCodeUnspsc190501.50344623'),
	'identifierSchemeCodeUnspsc190501.50344624': __('identifierSchemeCodeUnspsc190501.50344624'),
	'identifierSchemeCodeUnspsc190501.50344625': __('identifierSchemeCodeUnspsc190501.50344625'),
	'identifierSchemeCodeUnspsc190501.50344626': __('identifierSchemeCodeUnspsc190501.50344626'),
	'identifierSchemeCodeUnspsc190501.50344627': __('identifierSchemeCodeUnspsc190501.50344627'),
	'identifierSchemeCodeUnspsc190501.50344628': __('identifierSchemeCodeUnspsc190501.50344628'),
	'identifierSchemeCodeUnspsc190501.50344629': __('identifierSchemeCodeUnspsc190501.50344629'),
	'identifierSchemeCodeUnspsc190501.50344630': __('identifierSchemeCodeUnspsc190501.50344630'),
	'identifierSchemeCodeUnspsc190501.50344631': __('identifierSchemeCodeUnspsc190501.50344631'),
	'identifierSchemeCodeUnspsc190501.50344632': __('identifierSchemeCodeUnspsc190501.50344632'),
	'identifierSchemeCodeUnspsc190501.50344633': __('identifierSchemeCodeUnspsc190501.50344633'),
	'identifierSchemeCodeUnspsc190501.50344634': __('identifierSchemeCodeUnspsc190501.50344634'),
	'identifierSchemeCodeUnspsc190501.50344635': __('identifierSchemeCodeUnspsc190501.50344635'),
	'identifierSchemeCodeUnspsc190501.50344636': __('identifierSchemeCodeUnspsc190501.50344636'),
	'identifierSchemeCodeUnspsc190501.50344637': __('identifierSchemeCodeUnspsc190501.50344637'),
	'identifierSchemeCodeUnspsc190501.50344638': __('identifierSchemeCodeUnspsc190501.50344638'),
	'identifierSchemeCodeUnspsc190501.50344639': __('identifierSchemeCodeUnspsc190501.50344639'),
	'identifierSchemeCodeUnspsc190501.50344640': __('identifierSchemeCodeUnspsc190501.50344640'),
	'identifierSchemeCodeUnspsc190501.50344641': __('identifierSchemeCodeUnspsc190501.50344641'),
	'identifierSchemeCodeUnspsc190501.50344642': __('identifierSchemeCodeUnspsc190501.50344642'),
	'identifierSchemeCodeUnspsc190501.50344643': __('identifierSchemeCodeUnspsc190501.50344643'),
	'identifierSchemeCodeUnspsc190501.50344644': __('identifierSchemeCodeUnspsc190501.50344644'),
	'identifierSchemeCodeUnspsc190501.50344645': __('identifierSchemeCodeUnspsc190501.50344645'),
	'identifierSchemeCodeUnspsc190501.50344646': __('identifierSchemeCodeUnspsc190501.50344646'),
	'identifierSchemeCodeUnspsc190501.50344647': __('identifierSchemeCodeUnspsc190501.50344647'),
	'identifierSchemeCodeUnspsc190501.50344648': __('identifierSchemeCodeUnspsc190501.50344648'),
	'identifierSchemeCodeUnspsc190501.50344649': __('identifierSchemeCodeUnspsc190501.50344649'),
	'identifierSchemeCodeUnspsc190501.50344650': __('identifierSchemeCodeUnspsc190501.50344650'),
	'identifierSchemeCodeUnspsc190501.50344651': __('identifierSchemeCodeUnspsc190501.50344651'),
	'identifierSchemeCodeUnspsc190501.50344652': __('identifierSchemeCodeUnspsc190501.50344652'),
	'identifierSchemeCodeUnspsc190501.50344653': __('identifierSchemeCodeUnspsc190501.50344653'),
	'identifierSchemeCodeUnspsc190501.50344654': __('identifierSchemeCodeUnspsc190501.50344654'),
	'identifierSchemeCodeUnspsc190501.50344655': __('identifierSchemeCodeUnspsc190501.50344655'),
	'identifierSchemeCodeUnspsc190501.50344656': __('identifierSchemeCodeUnspsc190501.50344656'),
	'identifierSchemeCodeUnspsc190501.50344657': __('identifierSchemeCodeUnspsc190501.50344657'),
	'identifierSchemeCodeUnspsc190501.50344658': __('identifierSchemeCodeUnspsc190501.50344658'),
	'identifierSchemeCodeUnspsc190501.50344659': __('identifierSchemeCodeUnspsc190501.50344659'),
	'identifierSchemeCodeUnspsc190501.50344660': __('identifierSchemeCodeUnspsc190501.50344660'),
	'identifierSchemeCodeUnspsc190501.50344661': __('identifierSchemeCodeUnspsc190501.50344661'),
	'identifierSchemeCodeUnspsc190501.50344700': __('identifierSchemeCodeUnspsc190501.50344700'),
	'identifierSchemeCodeUnspsc190501.50344701': __('identifierSchemeCodeUnspsc190501.50344701'),
	'identifierSchemeCodeUnspsc190501.50344702': __('identifierSchemeCodeUnspsc190501.50344702'),
	'identifierSchemeCodeUnspsc190501.50344800': __('identifierSchemeCodeUnspsc190501.50344800'),
	'identifierSchemeCodeUnspsc190501.50344801': __('identifierSchemeCodeUnspsc190501.50344801'),
	'identifierSchemeCodeUnspsc190501.50344802': __('identifierSchemeCodeUnspsc190501.50344802'),
	'identifierSchemeCodeUnspsc190501.50344900': __('identifierSchemeCodeUnspsc190501.50344900'),
	'identifierSchemeCodeUnspsc190501.50344901': __('identifierSchemeCodeUnspsc190501.50344901'),
	'identifierSchemeCodeUnspsc190501.50344902': __('identifierSchemeCodeUnspsc190501.50344902'),
	'identifierSchemeCodeUnspsc190501.50344903': __('identifierSchemeCodeUnspsc190501.50344903'),
	'identifierSchemeCodeUnspsc190501.50344904': __('identifierSchemeCodeUnspsc190501.50344904'),
	'identifierSchemeCodeUnspsc190501.50344905': __('identifierSchemeCodeUnspsc190501.50344905'),
	'identifierSchemeCodeUnspsc190501.50344906': __('identifierSchemeCodeUnspsc190501.50344906'),
	'identifierSchemeCodeUnspsc190501.50344907': __('identifierSchemeCodeUnspsc190501.50344907'),
	'identifierSchemeCodeUnspsc190501.50344908': __('identifierSchemeCodeUnspsc190501.50344908'),
	'identifierSchemeCodeUnspsc190501.50344909': __('identifierSchemeCodeUnspsc190501.50344909'),
	'identifierSchemeCodeUnspsc190501.50344910': __('identifierSchemeCodeUnspsc190501.50344910'),
	'identifierSchemeCodeUnspsc190501.50344911': __('identifierSchemeCodeUnspsc190501.50344911'),
	'identifierSchemeCodeUnspsc190501.50344912': __('identifierSchemeCodeUnspsc190501.50344912'),
	'identifierSchemeCodeUnspsc190501.50344913': __('identifierSchemeCodeUnspsc190501.50344913'),
	'identifierSchemeCodeUnspsc190501.50344914': __('identifierSchemeCodeUnspsc190501.50344914'),
	'identifierSchemeCodeUnspsc190501.50344915': __('identifierSchemeCodeUnspsc190501.50344915'),
	'identifierSchemeCodeUnspsc190501.50344916': __('identifierSchemeCodeUnspsc190501.50344916'),
	'identifierSchemeCodeUnspsc190501.50344917': __('identifierSchemeCodeUnspsc190501.50344917'),
	'identifierSchemeCodeUnspsc190501.50344918': __('identifierSchemeCodeUnspsc190501.50344918'),
	'identifierSchemeCodeUnspsc190501.50344919': __('identifierSchemeCodeUnspsc190501.50344919'),
	'identifierSchemeCodeUnspsc190501.50344920': __('identifierSchemeCodeUnspsc190501.50344920'),
	'identifierSchemeCodeUnspsc190501.50344921': __('identifierSchemeCodeUnspsc190501.50344921'),
	'identifierSchemeCodeUnspsc190501.50344922': __('identifierSchemeCodeUnspsc190501.50344922'),
	'identifierSchemeCodeUnspsc190501.50344923': __('identifierSchemeCodeUnspsc190501.50344923'),
	'identifierSchemeCodeUnspsc190501.50344924': __('identifierSchemeCodeUnspsc190501.50344924'),
	'identifierSchemeCodeUnspsc190501.50344925': __('identifierSchemeCodeUnspsc190501.50344925'),
	'identifierSchemeCodeUnspsc190501.50344926': __('identifierSchemeCodeUnspsc190501.50344926'),
	'identifierSchemeCodeUnspsc190501.50344927': __('identifierSchemeCodeUnspsc190501.50344927'),
	'identifierSchemeCodeUnspsc190501.50344928': __('identifierSchemeCodeUnspsc190501.50344928'),
	'identifierSchemeCodeUnspsc190501.50344929': __('identifierSchemeCodeUnspsc190501.50344929'),
	'identifierSchemeCodeUnspsc190501.50344930': __('identifierSchemeCodeUnspsc190501.50344930'),
	'identifierSchemeCodeUnspsc190501.50344931': __('identifierSchemeCodeUnspsc190501.50344931'),
	'identifierSchemeCodeUnspsc190501.50344932': __('identifierSchemeCodeUnspsc190501.50344932'),
	'identifierSchemeCodeUnspsc190501.50344933': __('identifierSchemeCodeUnspsc190501.50344933'),
	'identifierSchemeCodeUnspsc190501.50344934': __('identifierSchemeCodeUnspsc190501.50344934'),
	'identifierSchemeCodeUnspsc190501.50344935': __('identifierSchemeCodeUnspsc190501.50344935'),
	'identifierSchemeCodeUnspsc190501.50344936': __('identifierSchemeCodeUnspsc190501.50344936'),
	'identifierSchemeCodeUnspsc190501.50344937': __('identifierSchemeCodeUnspsc190501.50344937'),
	'identifierSchemeCodeUnspsc190501.50344938': __('identifierSchemeCodeUnspsc190501.50344938'),
	'identifierSchemeCodeUnspsc190501.50344939': __('identifierSchemeCodeUnspsc190501.50344939'),
	'identifierSchemeCodeUnspsc190501.50344940': __('identifierSchemeCodeUnspsc190501.50344940'),
	'identifierSchemeCodeUnspsc190501.50344941': __('identifierSchemeCodeUnspsc190501.50344941'),
	'identifierSchemeCodeUnspsc190501.50344942': __('identifierSchemeCodeUnspsc190501.50344942'),
	'identifierSchemeCodeUnspsc190501.50344943': __('identifierSchemeCodeUnspsc190501.50344943'),
	'identifierSchemeCodeUnspsc190501.50344944': __('identifierSchemeCodeUnspsc190501.50344944'),
	'identifierSchemeCodeUnspsc190501.50344945': __('identifierSchemeCodeUnspsc190501.50344945'),
	'identifierSchemeCodeUnspsc190501.50344946': __('identifierSchemeCodeUnspsc190501.50344946'),
	'identifierSchemeCodeUnspsc190501.50344947': __('identifierSchemeCodeUnspsc190501.50344947'),
	'identifierSchemeCodeUnspsc190501.50344948': __('identifierSchemeCodeUnspsc190501.50344948'),
	'identifierSchemeCodeUnspsc190501.50344949': __('identifierSchemeCodeUnspsc190501.50344949'),
	'identifierSchemeCodeUnspsc190501.50344950': __('identifierSchemeCodeUnspsc190501.50344950'),
	'identifierSchemeCodeUnspsc190501.50344951': __('identifierSchemeCodeUnspsc190501.50344951'),
	'identifierSchemeCodeUnspsc190501.50344952': __('identifierSchemeCodeUnspsc190501.50344952'),
	'identifierSchemeCodeUnspsc190501.50344953': __('identifierSchemeCodeUnspsc190501.50344953'),
	'identifierSchemeCodeUnspsc190501.50344954': __('identifierSchemeCodeUnspsc190501.50344954'),
	'identifierSchemeCodeUnspsc190501.50344955': __('identifierSchemeCodeUnspsc190501.50344955'),
	'identifierSchemeCodeUnspsc190501.50344956': __('identifierSchemeCodeUnspsc190501.50344956'),
	'identifierSchemeCodeUnspsc190501.50344957': __('identifierSchemeCodeUnspsc190501.50344957'),
	'identifierSchemeCodeUnspsc190501.50345000': __('identifierSchemeCodeUnspsc190501.50345000'),
	'identifierSchemeCodeUnspsc190501.50345001': __('identifierSchemeCodeUnspsc190501.50345001'),
	'identifierSchemeCodeUnspsc190501.50345002': __('identifierSchemeCodeUnspsc190501.50345002'),
	'identifierSchemeCodeUnspsc190501.50345003': __('identifierSchemeCodeUnspsc190501.50345003'),
	'identifierSchemeCodeUnspsc190501.50345004': __('identifierSchemeCodeUnspsc190501.50345004'),
	'identifierSchemeCodeUnspsc190501.50345005': __('identifierSchemeCodeUnspsc190501.50345005'),
	'identifierSchemeCodeUnspsc190501.50345006': __('identifierSchemeCodeUnspsc190501.50345006'),
	'identifierSchemeCodeUnspsc190501.50345007': __('identifierSchemeCodeUnspsc190501.50345007'),
	'identifierSchemeCodeUnspsc190501.50345008': __('identifierSchemeCodeUnspsc190501.50345008'),
	'identifierSchemeCodeUnspsc190501.50345009': __('identifierSchemeCodeUnspsc190501.50345009'),
	'identifierSchemeCodeUnspsc190501.50345010': __('identifierSchemeCodeUnspsc190501.50345010'),
	'identifierSchemeCodeUnspsc190501.50345011': __('identifierSchemeCodeUnspsc190501.50345011'),
	'identifierSchemeCodeUnspsc190501.50345012': __('identifierSchemeCodeUnspsc190501.50345012'),
	'identifierSchemeCodeUnspsc190501.50345013': __('identifierSchemeCodeUnspsc190501.50345013'),
	'identifierSchemeCodeUnspsc190501.50345014': __('identifierSchemeCodeUnspsc190501.50345014'),
	'identifierSchemeCodeUnspsc190501.50345015': __('identifierSchemeCodeUnspsc190501.50345015'),
	'identifierSchemeCodeUnspsc190501.50345016': __('identifierSchemeCodeUnspsc190501.50345016'),
	'identifierSchemeCodeUnspsc190501.50345017': __('identifierSchemeCodeUnspsc190501.50345017'),
	'identifierSchemeCodeUnspsc190501.50345018': __('identifierSchemeCodeUnspsc190501.50345018'),
	'identifierSchemeCodeUnspsc190501.50345019': __('identifierSchemeCodeUnspsc190501.50345019'),
	'identifierSchemeCodeUnspsc190501.50345020': __('identifierSchemeCodeUnspsc190501.50345020'),
	'identifierSchemeCodeUnspsc190501.50345021': __('identifierSchemeCodeUnspsc190501.50345021'),
	'identifierSchemeCodeUnspsc190501.50345022': __('identifierSchemeCodeUnspsc190501.50345022'),
	'identifierSchemeCodeUnspsc190501.50345023': __('identifierSchemeCodeUnspsc190501.50345023'),
	'identifierSchemeCodeUnspsc190501.50345024': __('identifierSchemeCodeUnspsc190501.50345024'),
	'identifierSchemeCodeUnspsc190501.50345025': __('identifierSchemeCodeUnspsc190501.50345025'),
	'identifierSchemeCodeUnspsc190501.50345026': __('identifierSchemeCodeUnspsc190501.50345026'),
	'identifierSchemeCodeUnspsc190501.50345027': __('identifierSchemeCodeUnspsc190501.50345027'),
	'identifierSchemeCodeUnspsc190501.50345028': __('identifierSchemeCodeUnspsc190501.50345028'),
	'identifierSchemeCodeUnspsc190501.50345029': __('identifierSchemeCodeUnspsc190501.50345029'),
	'identifierSchemeCodeUnspsc190501.50345030': __('identifierSchemeCodeUnspsc190501.50345030'),
	'identifierSchemeCodeUnspsc190501.50345031': __('identifierSchemeCodeUnspsc190501.50345031'),
	'identifierSchemeCodeUnspsc190501.50345032': __('identifierSchemeCodeUnspsc190501.50345032'),
	'identifierSchemeCodeUnspsc190501.50345033': __('identifierSchemeCodeUnspsc190501.50345033'),
	'identifierSchemeCodeUnspsc190501.50345034': __('identifierSchemeCodeUnspsc190501.50345034'),
	'identifierSchemeCodeUnspsc190501.50345035': __('identifierSchemeCodeUnspsc190501.50345035'),
	'identifierSchemeCodeUnspsc190501.50345036': __('identifierSchemeCodeUnspsc190501.50345036'),
	'identifierSchemeCodeUnspsc190501.50345037': __('identifierSchemeCodeUnspsc190501.50345037'),
	'identifierSchemeCodeUnspsc190501.50345038': __('identifierSchemeCodeUnspsc190501.50345038'),
	'identifierSchemeCodeUnspsc190501.50345039': __('identifierSchemeCodeUnspsc190501.50345039'),
	'identifierSchemeCodeUnspsc190501.50345040': __('identifierSchemeCodeUnspsc190501.50345040'),
	'identifierSchemeCodeUnspsc190501.50345041': __('identifierSchemeCodeUnspsc190501.50345041'),
	'identifierSchemeCodeUnspsc190501.50345042': __('identifierSchemeCodeUnspsc190501.50345042'),
	'identifierSchemeCodeUnspsc190501.50345043': __('identifierSchemeCodeUnspsc190501.50345043'),
	'identifierSchemeCodeUnspsc190501.50345044': __('identifierSchemeCodeUnspsc190501.50345044'),
	'identifierSchemeCodeUnspsc190501.50345100': __('identifierSchemeCodeUnspsc190501.50345100'),
	'identifierSchemeCodeUnspsc190501.50345101': __('identifierSchemeCodeUnspsc190501.50345101'),
	'identifierSchemeCodeUnspsc190501.50345102': __('identifierSchemeCodeUnspsc190501.50345102'),
	'identifierSchemeCodeUnspsc190501.50345103': __('identifierSchemeCodeUnspsc190501.50345103'),
	'identifierSchemeCodeUnspsc190501.50345104': __('identifierSchemeCodeUnspsc190501.50345104'),
	'identifierSchemeCodeUnspsc190501.50345105': __('identifierSchemeCodeUnspsc190501.50345105'),
	'identifierSchemeCodeUnspsc190501.50345106': __('identifierSchemeCodeUnspsc190501.50345106'),
	'identifierSchemeCodeUnspsc190501.50345200': __('identifierSchemeCodeUnspsc190501.50345200'),
	'identifierSchemeCodeUnspsc190501.50345201': __('identifierSchemeCodeUnspsc190501.50345201'),
	'identifierSchemeCodeUnspsc190501.50345202': __('identifierSchemeCodeUnspsc190501.50345202'),
	'identifierSchemeCodeUnspsc190501.50345203': __('identifierSchemeCodeUnspsc190501.50345203'),
	'identifierSchemeCodeUnspsc190501.50345204': __('identifierSchemeCodeUnspsc190501.50345204'),
	'identifierSchemeCodeUnspsc190501.50345205': __('identifierSchemeCodeUnspsc190501.50345205'),
	'identifierSchemeCodeUnspsc190501.50345206': __('identifierSchemeCodeUnspsc190501.50345206'),
	'identifierSchemeCodeUnspsc190501.50345207': __('identifierSchemeCodeUnspsc190501.50345207'),
	'identifierSchemeCodeUnspsc190501.50345208': __('identifierSchemeCodeUnspsc190501.50345208'),
	'identifierSchemeCodeUnspsc190501.50345209': __('identifierSchemeCodeUnspsc190501.50345209'),
	'identifierSchemeCodeUnspsc190501.50345210': __('identifierSchemeCodeUnspsc190501.50345210'),
	'identifierSchemeCodeUnspsc190501.50345300': __('identifierSchemeCodeUnspsc190501.50345300'),
	'identifierSchemeCodeUnspsc190501.50345301': __('identifierSchemeCodeUnspsc190501.50345301'),
	'identifierSchemeCodeUnspsc190501.50345302': __('identifierSchemeCodeUnspsc190501.50345302'),
	'identifierSchemeCodeUnspsc190501.50345303': __('identifierSchemeCodeUnspsc190501.50345303'),
	'identifierSchemeCodeUnspsc190501.50345304': __('identifierSchemeCodeUnspsc190501.50345304'),
	'identifierSchemeCodeUnspsc190501.50345305': __('identifierSchemeCodeUnspsc190501.50345305'),
	'identifierSchemeCodeUnspsc190501.50345306': __('identifierSchemeCodeUnspsc190501.50345306'),
	'identifierSchemeCodeUnspsc190501.50345307': __('identifierSchemeCodeUnspsc190501.50345307'),
	'identifierSchemeCodeUnspsc190501.50345308': __('identifierSchemeCodeUnspsc190501.50345308'),
	'identifierSchemeCodeUnspsc190501.50345309': __('identifierSchemeCodeUnspsc190501.50345309'),
	'identifierSchemeCodeUnspsc190501.50345310': __('identifierSchemeCodeUnspsc190501.50345310'),
	'identifierSchemeCodeUnspsc190501.50345311': __('identifierSchemeCodeUnspsc190501.50345311'),
	'identifierSchemeCodeUnspsc190501.50345312': __('identifierSchemeCodeUnspsc190501.50345312'),
	'identifierSchemeCodeUnspsc190501.50345313': __('identifierSchemeCodeUnspsc190501.50345313'),
	'identifierSchemeCodeUnspsc190501.50345314': __('identifierSchemeCodeUnspsc190501.50345314'),
	'identifierSchemeCodeUnspsc190501.50345315': __('identifierSchemeCodeUnspsc190501.50345315'),
	'identifierSchemeCodeUnspsc190501.50345316': __('identifierSchemeCodeUnspsc190501.50345316'),
	'identifierSchemeCodeUnspsc190501.50345317': __('identifierSchemeCodeUnspsc190501.50345317'),
	'identifierSchemeCodeUnspsc190501.50345318': __('identifierSchemeCodeUnspsc190501.50345318'),
	'identifierSchemeCodeUnspsc190501.50345319': __('identifierSchemeCodeUnspsc190501.50345319'),
	'identifierSchemeCodeUnspsc190501.50345320': __('identifierSchemeCodeUnspsc190501.50345320'),
	'identifierSchemeCodeUnspsc190501.50345321': __('identifierSchemeCodeUnspsc190501.50345321'),
	'identifierSchemeCodeUnspsc190501.50345322': __('identifierSchemeCodeUnspsc190501.50345322'),
	'identifierSchemeCodeUnspsc190501.50345323': __('identifierSchemeCodeUnspsc190501.50345323'),
	'identifierSchemeCodeUnspsc190501.50345324': __('identifierSchemeCodeUnspsc190501.50345324'),
	'identifierSchemeCodeUnspsc190501.50345325': __('identifierSchemeCodeUnspsc190501.50345325'),
	'identifierSchemeCodeUnspsc190501.50345326': __('identifierSchemeCodeUnspsc190501.50345326'),
	'identifierSchemeCodeUnspsc190501.50345327': __('identifierSchemeCodeUnspsc190501.50345327'),
	'identifierSchemeCodeUnspsc190501.50345328': __('identifierSchemeCodeUnspsc190501.50345328'),
	'identifierSchemeCodeUnspsc190501.50345329': __('identifierSchemeCodeUnspsc190501.50345329'),
	'identifierSchemeCodeUnspsc190501.50345330': __('identifierSchemeCodeUnspsc190501.50345330'),
	'identifierSchemeCodeUnspsc190501.50345331': __('identifierSchemeCodeUnspsc190501.50345331'),
	'identifierSchemeCodeUnspsc190501.50345332': __('identifierSchemeCodeUnspsc190501.50345332'),
	'identifierSchemeCodeUnspsc190501.50345333': __('identifierSchemeCodeUnspsc190501.50345333'),
	'identifierSchemeCodeUnspsc190501.50345334': __('identifierSchemeCodeUnspsc190501.50345334'),
	'identifierSchemeCodeUnspsc190501.50345335': __('identifierSchemeCodeUnspsc190501.50345335'),
	'identifierSchemeCodeUnspsc190501.50345336': __('identifierSchemeCodeUnspsc190501.50345336'),
	'identifierSchemeCodeUnspsc190501.50345337': __('identifierSchemeCodeUnspsc190501.50345337'),
	'identifierSchemeCodeUnspsc190501.50345338': __('identifierSchemeCodeUnspsc190501.50345338'),
	'identifierSchemeCodeUnspsc190501.50345339': __('identifierSchemeCodeUnspsc190501.50345339'),
	'identifierSchemeCodeUnspsc190501.50345340': __('identifierSchemeCodeUnspsc190501.50345340'),
	'identifierSchemeCodeUnspsc190501.50345341': __('identifierSchemeCodeUnspsc190501.50345341'),
	'identifierSchemeCodeUnspsc190501.50345342': __('identifierSchemeCodeUnspsc190501.50345342'),
	'identifierSchemeCodeUnspsc190501.50345343': __('identifierSchemeCodeUnspsc190501.50345343'),
	'identifierSchemeCodeUnspsc190501.50345344': __('identifierSchemeCodeUnspsc190501.50345344'),
	'identifierSchemeCodeUnspsc190501.50345345': __('identifierSchemeCodeUnspsc190501.50345345'),
	'identifierSchemeCodeUnspsc190501.50345346': __('identifierSchemeCodeUnspsc190501.50345346'),
	'identifierSchemeCodeUnspsc190501.50345347': __('identifierSchemeCodeUnspsc190501.50345347'),
	'identifierSchemeCodeUnspsc190501.50345348': __('identifierSchemeCodeUnspsc190501.50345348'),
	'identifierSchemeCodeUnspsc190501.50345349': __('identifierSchemeCodeUnspsc190501.50345349'),
	'identifierSchemeCodeUnspsc190501.50345350': __('identifierSchemeCodeUnspsc190501.50345350'),
	'identifierSchemeCodeUnspsc190501.50345351': __('identifierSchemeCodeUnspsc190501.50345351'),
	'identifierSchemeCodeUnspsc190501.50345352': __('identifierSchemeCodeUnspsc190501.50345352'),
	'identifierSchemeCodeUnspsc190501.50345353': __('identifierSchemeCodeUnspsc190501.50345353'),
	'identifierSchemeCodeUnspsc190501.50345354': __('identifierSchemeCodeUnspsc190501.50345354'),
	'identifierSchemeCodeUnspsc190501.50345355': __('identifierSchemeCodeUnspsc190501.50345355'),
	'identifierSchemeCodeUnspsc190501.50345356': __('identifierSchemeCodeUnspsc190501.50345356'),
	'identifierSchemeCodeUnspsc190501.50345357': __('identifierSchemeCodeUnspsc190501.50345357'),
	'identifierSchemeCodeUnspsc190501.50345358': __('identifierSchemeCodeUnspsc190501.50345358'),
	'identifierSchemeCodeUnspsc190501.50345359': __('identifierSchemeCodeUnspsc190501.50345359'),
	'identifierSchemeCodeUnspsc190501.50345360': __('identifierSchemeCodeUnspsc190501.50345360'),
	'identifierSchemeCodeUnspsc190501.50345361': __('identifierSchemeCodeUnspsc190501.50345361'),
	'identifierSchemeCodeUnspsc190501.50345362': __('identifierSchemeCodeUnspsc190501.50345362'),
	'identifierSchemeCodeUnspsc190501.50345363': __('identifierSchemeCodeUnspsc190501.50345363'),
	'identifierSchemeCodeUnspsc190501.50345364': __('identifierSchemeCodeUnspsc190501.50345364'),
	'identifierSchemeCodeUnspsc190501.50345365': __('identifierSchemeCodeUnspsc190501.50345365'),
	'identifierSchemeCodeUnspsc190501.50345366': __('identifierSchemeCodeUnspsc190501.50345366'),
	'identifierSchemeCodeUnspsc190501.50345367': __('identifierSchemeCodeUnspsc190501.50345367'),
	'identifierSchemeCodeUnspsc190501.50345368': __('identifierSchemeCodeUnspsc190501.50345368'),
	'identifierSchemeCodeUnspsc190501.50345369': __('identifierSchemeCodeUnspsc190501.50345369'),
	'identifierSchemeCodeUnspsc190501.50345370': __('identifierSchemeCodeUnspsc190501.50345370'),
	'identifierSchemeCodeUnspsc190501.50345371': __('identifierSchemeCodeUnspsc190501.50345371'),
	'identifierSchemeCodeUnspsc190501.50345400': __('identifierSchemeCodeUnspsc190501.50345400'),
	'identifierSchemeCodeUnspsc190501.50345401': __('identifierSchemeCodeUnspsc190501.50345401'),
	'identifierSchemeCodeUnspsc190501.50345402': __('identifierSchemeCodeUnspsc190501.50345402'),
	'identifierSchemeCodeUnspsc190501.50345403': __('identifierSchemeCodeUnspsc190501.50345403'),
	'identifierSchemeCodeUnspsc190501.50345404': __('identifierSchemeCodeUnspsc190501.50345404'),
	'identifierSchemeCodeUnspsc190501.50345405': __('identifierSchemeCodeUnspsc190501.50345405'),
	'identifierSchemeCodeUnspsc190501.50345406': __('identifierSchemeCodeUnspsc190501.50345406'),
	'identifierSchemeCodeUnspsc190501.50345407': __('identifierSchemeCodeUnspsc190501.50345407'),
	'identifierSchemeCodeUnspsc190501.50345408': __('identifierSchemeCodeUnspsc190501.50345408'),
	'identifierSchemeCodeUnspsc190501.50345409': __('identifierSchemeCodeUnspsc190501.50345409'),
	'identifierSchemeCodeUnspsc190501.50345410': __('identifierSchemeCodeUnspsc190501.50345410'),
	'identifierSchemeCodeUnspsc190501.50345411': __('identifierSchemeCodeUnspsc190501.50345411'),
	'identifierSchemeCodeUnspsc190501.50345412': __('identifierSchemeCodeUnspsc190501.50345412'),
	'identifierSchemeCodeUnspsc190501.50345413': __('identifierSchemeCodeUnspsc190501.50345413'),
	'identifierSchemeCodeUnspsc190501.50345414': __('identifierSchemeCodeUnspsc190501.50345414'),
	'identifierSchemeCodeUnspsc190501.50345415': __('identifierSchemeCodeUnspsc190501.50345415'),
	'identifierSchemeCodeUnspsc190501.50345416': __('identifierSchemeCodeUnspsc190501.50345416'),
	'identifierSchemeCodeUnspsc190501.50345417': __('identifierSchemeCodeUnspsc190501.50345417'),
	'identifierSchemeCodeUnspsc190501.50345418': __('identifierSchemeCodeUnspsc190501.50345418'),
	'identifierSchemeCodeUnspsc190501.50345419': __('identifierSchemeCodeUnspsc190501.50345419'),
	'identifierSchemeCodeUnspsc190501.50345420': __('identifierSchemeCodeUnspsc190501.50345420'),
	'identifierSchemeCodeUnspsc190501.50345421': __('identifierSchemeCodeUnspsc190501.50345421'),
	'identifierSchemeCodeUnspsc190501.50345422': __('identifierSchemeCodeUnspsc190501.50345422'),
	'identifierSchemeCodeUnspsc190501.50345423': __('identifierSchemeCodeUnspsc190501.50345423'),
	'identifierSchemeCodeUnspsc190501.50345424': __('identifierSchemeCodeUnspsc190501.50345424'),
	'identifierSchemeCodeUnspsc190501.50345425': __('identifierSchemeCodeUnspsc190501.50345425'),
	'identifierSchemeCodeUnspsc190501.50345426': __('identifierSchemeCodeUnspsc190501.50345426'),
	'identifierSchemeCodeUnspsc190501.50345427': __('identifierSchemeCodeUnspsc190501.50345427'),
	'identifierSchemeCodeUnspsc190501.50345428': __('identifierSchemeCodeUnspsc190501.50345428'),
	'identifierSchemeCodeUnspsc190501.50345429': __('identifierSchemeCodeUnspsc190501.50345429'),
	'identifierSchemeCodeUnspsc190501.50345430': __('identifierSchemeCodeUnspsc190501.50345430'),
	'identifierSchemeCodeUnspsc190501.50345431': __('identifierSchemeCodeUnspsc190501.50345431'),
	'identifierSchemeCodeUnspsc190501.50345432': __('identifierSchemeCodeUnspsc190501.50345432'),
	'identifierSchemeCodeUnspsc190501.50345433': __('identifierSchemeCodeUnspsc190501.50345433'),
	'identifierSchemeCodeUnspsc190501.50345434': __('identifierSchemeCodeUnspsc190501.50345434'),
	'identifierSchemeCodeUnspsc190501.50345435': __('identifierSchemeCodeUnspsc190501.50345435'),
	'identifierSchemeCodeUnspsc190501.50345436': __('identifierSchemeCodeUnspsc190501.50345436'),
	'identifierSchemeCodeUnspsc190501.50345437': __('identifierSchemeCodeUnspsc190501.50345437'),
	'identifierSchemeCodeUnspsc190501.50345438': __('identifierSchemeCodeUnspsc190501.50345438'),
	'identifierSchemeCodeUnspsc190501.50345439': __('identifierSchemeCodeUnspsc190501.50345439'),
	'identifierSchemeCodeUnspsc190501.50345440': __('identifierSchemeCodeUnspsc190501.50345440'),
	'identifierSchemeCodeUnspsc190501.50345441': __('identifierSchemeCodeUnspsc190501.50345441'),
	'identifierSchemeCodeUnspsc190501.50345442': __('identifierSchemeCodeUnspsc190501.50345442'),
	'identifierSchemeCodeUnspsc190501.50345443': __('identifierSchemeCodeUnspsc190501.50345443'),
	'identifierSchemeCodeUnspsc190501.50345444': __('identifierSchemeCodeUnspsc190501.50345444'),
	'identifierSchemeCodeUnspsc190501.50345445': __('identifierSchemeCodeUnspsc190501.50345445'),
	'identifierSchemeCodeUnspsc190501.50345446': __('identifierSchemeCodeUnspsc190501.50345446'),
	'identifierSchemeCodeUnspsc190501.50345447': __('identifierSchemeCodeUnspsc190501.50345447'),
	'identifierSchemeCodeUnspsc190501.50345448': __('identifierSchemeCodeUnspsc190501.50345448'),
	'identifierSchemeCodeUnspsc190501.50345449': __('identifierSchemeCodeUnspsc190501.50345449'),
	'identifierSchemeCodeUnspsc190501.50345450': __('identifierSchemeCodeUnspsc190501.50345450'),
	'identifierSchemeCodeUnspsc190501.50345451': __('identifierSchemeCodeUnspsc190501.50345451'),
	'identifierSchemeCodeUnspsc190501.50345452': __('identifierSchemeCodeUnspsc190501.50345452'),
	'identifierSchemeCodeUnspsc190501.50345453': __('identifierSchemeCodeUnspsc190501.50345453'),
	'identifierSchemeCodeUnspsc190501.50345454': __('identifierSchemeCodeUnspsc190501.50345454'),
	'identifierSchemeCodeUnspsc190501.50345455': __('identifierSchemeCodeUnspsc190501.50345455'),
	'identifierSchemeCodeUnspsc190501.50345456': __('identifierSchemeCodeUnspsc190501.50345456'),
	'identifierSchemeCodeUnspsc190501.50345457': __('identifierSchemeCodeUnspsc190501.50345457'),
	'identifierSchemeCodeUnspsc190501.50345458': __('identifierSchemeCodeUnspsc190501.50345458'),
	'identifierSchemeCodeUnspsc190501.50345459': __('identifierSchemeCodeUnspsc190501.50345459'),
	'identifierSchemeCodeUnspsc190501.50345460': __('identifierSchemeCodeUnspsc190501.50345460'),
	'identifierSchemeCodeUnspsc190501.50345500': __('identifierSchemeCodeUnspsc190501.50345500'),
	'identifierSchemeCodeUnspsc190501.50345501': __('identifierSchemeCodeUnspsc190501.50345501'),
	'identifierSchemeCodeUnspsc190501.50345502': __('identifierSchemeCodeUnspsc190501.50345502'),
	'identifierSchemeCodeUnspsc190501.50345503': __('identifierSchemeCodeUnspsc190501.50345503'),
	'identifierSchemeCodeUnspsc190501.50345504': __('identifierSchemeCodeUnspsc190501.50345504'),
	'identifierSchemeCodeUnspsc190501.50345505': __('identifierSchemeCodeUnspsc190501.50345505'),
	'identifierSchemeCodeUnspsc190501.50345506': __('identifierSchemeCodeUnspsc190501.50345506'),
	'identifierSchemeCodeUnspsc190501.50345507': __('identifierSchemeCodeUnspsc190501.50345507'),
	'identifierSchemeCodeUnspsc190501.50345508': __('identifierSchemeCodeUnspsc190501.50345508'),
	'identifierSchemeCodeUnspsc190501.50345509': __('identifierSchemeCodeUnspsc190501.50345509'),
	'identifierSchemeCodeUnspsc190501.50345510': __('identifierSchemeCodeUnspsc190501.50345510'),
	'identifierSchemeCodeUnspsc190501.50345511': __('identifierSchemeCodeUnspsc190501.50345511'),
	'identifierSchemeCodeUnspsc190501.50345512': __('identifierSchemeCodeUnspsc190501.50345512'),
	'identifierSchemeCodeUnspsc190501.50345600': __('identifierSchemeCodeUnspsc190501.50345600'),
	'identifierSchemeCodeUnspsc190501.50345601': __('identifierSchemeCodeUnspsc190501.50345601'),
	'identifierSchemeCodeUnspsc190501.50345602': __('identifierSchemeCodeUnspsc190501.50345602'),
	'identifierSchemeCodeUnspsc190501.50345603': __('identifierSchemeCodeUnspsc190501.50345603'),
	'identifierSchemeCodeUnspsc190501.50345604': __('identifierSchemeCodeUnspsc190501.50345604'),
	'identifierSchemeCodeUnspsc190501.50345605': __('identifierSchemeCodeUnspsc190501.50345605'),
	'identifierSchemeCodeUnspsc190501.50345606': __('identifierSchemeCodeUnspsc190501.50345606'),
	'identifierSchemeCodeUnspsc190501.50345607': __('identifierSchemeCodeUnspsc190501.50345607'),
	'identifierSchemeCodeUnspsc190501.50345608': __('identifierSchemeCodeUnspsc190501.50345608'),
	'identifierSchemeCodeUnspsc190501.50345609': __('identifierSchemeCodeUnspsc190501.50345609'),
	'identifierSchemeCodeUnspsc190501.50345610': __('identifierSchemeCodeUnspsc190501.50345610'),
	'identifierSchemeCodeUnspsc190501.50345700': __('identifierSchemeCodeUnspsc190501.50345700'),
	'identifierSchemeCodeUnspsc190501.50345701': __('identifierSchemeCodeUnspsc190501.50345701'),
	'identifierSchemeCodeUnspsc190501.50345702': __('identifierSchemeCodeUnspsc190501.50345702'),
	'identifierSchemeCodeUnspsc190501.50345703': __('identifierSchemeCodeUnspsc190501.50345703'),
	'identifierSchemeCodeUnspsc190501.50345704': __('identifierSchemeCodeUnspsc190501.50345704'),
	'identifierSchemeCodeUnspsc190501.50345705': __('identifierSchemeCodeUnspsc190501.50345705'),
	'identifierSchemeCodeUnspsc190501.50345706': __('identifierSchemeCodeUnspsc190501.50345706'),
	'identifierSchemeCodeUnspsc190501.50345707': __('identifierSchemeCodeUnspsc190501.50345707'),
	'identifierSchemeCodeUnspsc190501.50345708': __('identifierSchemeCodeUnspsc190501.50345708'),
	'identifierSchemeCodeUnspsc190501.50345709': __('identifierSchemeCodeUnspsc190501.50345709'),
	'identifierSchemeCodeUnspsc190501.50345710': __('identifierSchemeCodeUnspsc190501.50345710'),
	'identifierSchemeCodeUnspsc190501.50345711': __('identifierSchemeCodeUnspsc190501.50345711'),
	'identifierSchemeCodeUnspsc190501.50345712': __('identifierSchemeCodeUnspsc190501.50345712'),
	'identifierSchemeCodeUnspsc190501.50345713': __('identifierSchemeCodeUnspsc190501.50345713'),
	'identifierSchemeCodeUnspsc190501.50345714': __('identifierSchemeCodeUnspsc190501.50345714'),
	'identifierSchemeCodeUnspsc190501.50345715': __('identifierSchemeCodeUnspsc190501.50345715'),
	'identifierSchemeCodeUnspsc190501.50345716': __('identifierSchemeCodeUnspsc190501.50345716'),
	'identifierSchemeCodeUnspsc190501.50345717': __('identifierSchemeCodeUnspsc190501.50345717'),
	'identifierSchemeCodeUnspsc190501.50345718': __('identifierSchemeCodeUnspsc190501.50345718'),
	'identifierSchemeCodeUnspsc190501.50345719': __('identifierSchemeCodeUnspsc190501.50345719'),
	'identifierSchemeCodeUnspsc190501.50345720': __('identifierSchemeCodeUnspsc190501.50345720'),
	'identifierSchemeCodeUnspsc190501.50345721': __('identifierSchemeCodeUnspsc190501.50345721'),
	'identifierSchemeCodeUnspsc190501.50345722': __('identifierSchemeCodeUnspsc190501.50345722'),
	'identifierSchemeCodeUnspsc190501.50345723': __('identifierSchemeCodeUnspsc190501.50345723'),
	'identifierSchemeCodeUnspsc190501.50345800': __('identifierSchemeCodeUnspsc190501.50345800'),
	'identifierSchemeCodeUnspsc190501.50345801': __('identifierSchemeCodeUnspsc190501.50345801'),
	'identifierSchemeCodeUnspsc190501.50345802': __('identifierSchemeCodeUnspsc190501.50345802'),
	'identifierSchemeCodeUnspsc190501.50345803': __('identifierSchemeCodeUnspsc190501.50345803'),
	'identifierSchemeCodeUnspsc190501.50345804': __('identifierSchemeCodeUnspsc190501.50345804'),
	'identifierSchemeCodeUnspsc190501.50345805': __('identifierSchemeCodeUnspsc190501.50345805'),
	'identifierSchemeCodeUnspsc190501.50345806': __('identifierSchemeCodeUnspsc190501.50345806'),
	'identifierSchemeCodeUnspsc190501.50345807': __('identifierSchemeCodeUnspsc190501.50345807'),
	'identifierSchemeCodeUnspsc190501.50345808': __('identifierSchemeCodeUnspsc190501.50345808'),
	'identifierSchemeCodeUnspsc190501.50345809': __('identifierSchemeCodeUnspsc190501.50345809'),
	'identifierSchemeCodeUnspsc190501.50345810': __('identifierSchemeCodeUnspsc190501.50345810'),
	'identifierSchemeCodeUnspsc190501.50345811': __('identifierSchemeCodeUnspsc190501.50345811'),
	'identifierSchemeCodeUnspsc190501.50345812': __('identifierSchemeCodeUnspsc190501.50345812'),
	'identifierSchemeCodeUnspsc190501.50345813': __('identifierSchemeCodeUnspsc190501.50345813'),
	'identifierSchemeCodeUnspsc190501.50345814': __('identifierSchemeCodeUnspsc190501.50345814'),
	'identifierSchemeCodeUnspsc190501.50345815': __('identifierSchemeCodeUnspsc190501.50345815'),
	'identifierSchemeCodeUnspsc190501.50345816': __('identifierSchemeCodeUnspsc190501.50345816'),
	'identifierSchemeCodeUnspsc190501.50345817': __('identifierSchemeCodeUnspsc190501.50345817'),
	'identifierSchemeCodeUnspsc190501.50345818': __('identifierSchemeCodeUnspsc190501.50345818'),
	'identifierSchemeCodeUnspsc190501.50345819': __('identifierSchemeCodeUnspsc190501.50345819'),
	'identifierSchemeCodeUnspsc190501.50345820': __('identifierSchemeCodeUnspsc190501.50345820'),
	'identifierSchemeCodeUnspsc190501.50345821': __('identifierSchemeCodeUnspsc190501.50345821'),
	'identifierSchemeCodeUnspsc190501.50345822': __('identifierSchemeCodeUnspsc190501.50345822'),
	'identifierSchemeCodeUnspsc190501.50345823': __('identifierSchemeCodeUnspsc190501.50345823'),
	'identifierSchemeCodeUnspsc190501.50345824': __('identifierSchemeCodeUnspsc190501.50345824'),
	'identifierSchemeCodeUnspsc190501.50345825': __('identifierSchemeCodeUnspsc190501.50345825'),
	'identifierSchemeCodeUnspsc190501.50345826': __('identifierSchemeCodeUnspsc190501.50345826'),
	'identifierSchemeCodeUnspsc190501.50345827': __('identifierSchemeCodeUnspsc190501.50345827'),
	'identifierSchemeCodeUnspsc190501.50345828': __('identifierSchemeCodeUnspsc190501.50345828'),
	'identifierSchemeCodeUnspsc190501.50345829': __('identifierSchemeCodeUnspsc190501.50345829'),
	'identifierSchemeCodeUnspsc190501.50345830': __('identifierSchemeCodeUnspsc190501.50345830'),
	'identifierSchemeCodeUnspsc190501.50345831': __('identifierSchemeCodeUnspsc190501.50345831'),
	'identifierSchemeCodeUnspsc190501.50345832': __('identifierSchemeCodeUnspsc190501.50345832'),
	'identifierSchemeCodeUnspsc190501.50345833': __('identifierSchemeCodeUnspsc190501.50345833'),
	'identifierSchemeCodeUnspsc190501.50345834': __('identifierSchemeCodeUnspsc190501.50345834'),
	'identifierSchemeCodeUnspsc190501.50345835': __('identifierSchemeCodeUnspsc190501.50345835'),
	'identifierSchemeCodeUnspsc190501.50345836': __('identifierSchemeCodeUnspsc190501.50345836'),
	'identifierSchemeCodeUnspsc190501.50345837': __('identifierSchemeCodeUnspsc190501.50345837'),
	'identifierSchemeCodeUnspsc190501.50345838': __('identifierSchemeCodeUnspsc190501.50345838'),
	'identifierSchemeCodeUnspsc190501.50345839': __('identifierSchemeCodeUnspsc190501.50345839'),
	'identifierSchemeCodeUnspsc190501.50345840': __('identifierSchemeCodeUnspsc190501.50345840'),
	'identifierSchemeCodeUnspsc190501.50345841': __('identifierSchemeCodeUnspsc190501.50345841'),
	'identifierSchemeCodeUnspsc190501.50345842': __('identifierSchemeCodeUnspsc190501.50345842'),
	'identifierSchemeCodeUnspsc190501.50345843': __('identifierSchemeCodeUnspsc190501.50345843'),
	'identifierSchemeCodeUnspsc190501.50345844': __('identifierSchemeCodeUnspsc190501.50345844'),
	'identifierSchemeCodeUnspsc190501.50345845': __('identifierSchemeCodeUnspsc190501.50345845'),
	'identifierSchemeCodeUnspsc190501.50345846': __('identifierSchemeCodeUnspsc190501.50345846'),
	'identifierSchemeCodeUnspsc190501.50345847': __('identifierSchemeCodeUnspsc190501.50345847'),
	'identifierSchemeCodeUnspsc190501.50345848': __('identifierSchemeCodeUnspsc190501.50345848'),
	'identifierSchemeCodeUnspsc190501.50345849': __('identifierSchemeCodeUnspsc190501.50345849'),
	'identifierSchemeCodeUnspsc190501.50345850': __('identifierSchemeCodeUnspsc190501.50345850'),
	'identifierSchemeCodeUnspsc190501.50345851': __('identifierSchemeCodeUnspsc190501.50345851'),
	'identifierSchemeCodeUnspsc190501.50345852': __('identifierSchemeCodeUnspsc190501.50345852'),
	'identifierSchemeCodeUnspsc190501.50345853': __('identifierSchemeCodeUnspsc190501.50345853'),
	'identifierSchemeCodeUnspsc190501.50345854': __('identifierSchemeCodeUnspsc190501.50345854'),
	'identifierSchemeCodeUnspsc190501.50345855': __('identifierSchemeCodeUnspsc190501.50345855'),
	'identifierSchemeCodeUnspsc190501.50345856': __('identifierSchemeCodeUnspsc190501.50345856'),
	'identifierSchemeCodeUnspsc190501.50345857': __('identifierSchemeCodeUnspsc190501.50345857'),
	'identifierSchemeCodeUnspsc190501.50345858': __('identifierSchemeCodeUnspsc190501.50345858'),
	'identifierSchemeCodeUnspsc190501.50345859': __('identifierSchemeCodeUnspsc190501.50345859'),
	'identifierSchemeCodeUnspsc190501.50345860': __('identifierSchemeCodeUnspsc190501.50345860'),
	'identifierSchemeCodeUnspsc190501.50345861': __('identifierSchemeCodeUnspsc190501.50345861'),
	'identifierSchemeCodeUnspsc190501.50345862': __('identifierSchemeCodeUnspsc190501.50345862'),
	'identifierSchemeCodeUnspsc190501.50345863': __('identifierSchemeCodeUnspsc190501.50345863'),
	'identifierSchemeCodeUnspsc190501.50345864': __('identifierSchemeCodeUnspsc190501.50345864'),
	'identifierSchemeCodeUnspsc190501.50345865': __('identifierSchemeCodeUnspsc190501.50345865'),
	'identifierSchemeCodeUnspsc190501.50345866': __('identifierSchemeCodeUnspsc190501.50345866'),
	'identifierSchemeCodeUnspsc190501.50345867': __('identifierSchemeCodeUnspsc190501.50345867'),
	'identifierSchemeCodeUnspsc190501.50345868': __('identifierSchemeCodeUnspsc190501.50345868'),
	'identifierSchemeCodeUnspsc190501.50345869': __('identifierSchemeCodeUnspsc190501.50345869'),
	'identifierSchemeCodeUnspsc190501.50345870': __('identifierSchemeCodeUnspsc190501.50345870'),
	'identifierSchemeCodeUnspsc190501.50345871': __('identifierSchemeCodeUnspsc190501.50345871'),
	'identifierSchemeCodeUnspsc190501.50345872': __('identifierSchemeCodeUnspsc190501.50345872'),
	'identifierSchemeCodeUnspsc190501.50345873': __('identifierSchemeCodeUnspsc190501.50345873'),
	'identifierSchemeCodeUnspsc190501.50345874': __('identifierSchemeCodeUnspsc190501.50345874'),
	'identifierSchemeCodeUnspsc190501.50345875': __('identifierSchemeCodeUnspsc190501.50345875'),
	'identifierSchemeCodeUnspsc190501.50345876': __('identifierSchemeCodeUnspsc190501.50345876'),
	'identifierSchemeCodeUnspsc190501.50345877': __('identifierSchemeCodeUnspsc190501.50345877'),
	'identifierSchemeCodeUnspsc190501.50345900': __('identifierSchemeCodeUnspsc190501.50345900'),
	'identifierSchemeCodeUnspsc190501.50345901': __('identifierSchemeCodeUnspsc190501.50345901'),
	'identifierSchemeCodeUnspsc190501.50345902': __('identifierSchemeCodeUnspsc190501.50345902'),
	'identifierSchemeCodeUnspsc190501.50345903': __('identifierSchemeCodeUnspsc190501.50345903'),
	'identifierSchemeCodeUnspsc190501.50345904': __('identifierSchemeCodeUnspsc190501.50345904'),
	'identifierSchemeCodeUnspsc190501.50345905': __('identifierSchemeCodeUnspsc190501.50345905'),
	'identifierSchemeCodeUnspsc190501.50345906': __('identifierSchemeCodeUnspsc190501.50345906'),
	'identifierSchemeCodeUnspsc190501.50345907': __('identifierSchemeCodeUnspsc190501.50345907'),
	'identifierSchemeCodeUnspsc190501.50345908': __('identifierSchemeCodeUnspsc190501.50345908'),
	'identifierSchemeCodeUnspsc190501.50346000': __('identifierSchemeCodeUnspsc190501.50346000'),
	'identifierSchemeCodeUnspsc190501.50346001': __('identifierSchemeCodeUnspsc190501.50346001'),
	'identifierSchemeCodeUnspsc190501.50346002': __('identifierSchemeCodeUnspsc190501.50346002'),
	'identifierSchemeCodeUnspsc190501.50346003': __('identifierSchemeCodeUnspsc190501.50346003'),
	'identifierSchemeCodeUnspsc190501.50346004': __('identifierSchemeCodeUnspsc190501.50346004'),
	'identifierSchemeCodeUnspsc190501.50346005': __('identifierSchemeCodeUnspsc190501.50346005'),
	'identifierSchemeCodeUnspsc190501.50346006': __('identifierSchemeCodeUnspsc190501.50346006'),
	'identifierSchemeCodeUnspsc190501.50346007': __('identifierSchemeCodeUnspsc190501.50346007'),
	'identifierSchemeCodeUnspsc190501.50346008': __('identifierSchemeCodeUnspsc190501.50346008'),
	'identifierSchemeCodeUnspsc190501.50346100': __('identifierSchemeCodeUnspsc190501.50346100'),
	'identifierSchemeCodeUnspsc190501.50346101': __('identifierSchemeCodeUnspsc190501.50346101'),
	'identifierSchemeCodeUnspsc190501.50346102': __('identifierSchemeCodeUnspsc190501.50346102'),
	'identifierSchemeCodeUnspsc190501.50346103': __('identifierSchemeCodeUnspsc190501.50346103'),
	'identifierSchemeCodeUnspsc190501.50346200': __('identifierSchemeCodeUnspsc190501.50346200'),
	'identifierSchemeCodeUnspsc190501.50346201': __('identifierSchemeCodeUnspsc190501.50346201'),
	'identifierSchemeCodeUnspsc190501.50346202': __('identifierSchemeCodeUnspsc190501.50346202'),
	'identifierSchemeCodeUnspsc190501.50346203': __('identifierSchemeCodeUnspsc190501.50346203'),
	'identifierSchemeCodeUnspsc190501.50346204': __('identifierSchemeCodeUnspsc190501.50346204'),
	'identifierSchemeCodeUnspsc190501.50346205': __('identifierSchemeCodeUnspsc190501.50346205'),
	'identifierSchemeCodeUnspsc190501.50346206': __('identifierSchemeCodeUnspsc190501.50346206'),
	'identifierSchemeCodeUnspsc190501.50346207': __('identifierSchemeCodeUnspsc190501.50346207'),
	'identifierSchemeCodeUnspsc190501.50346208': __('identifierSchemeCodeUnspsc190501.50346208'),
	'identifierSchemeCodeUnspsc190501.50346209': __('identifierSchemeCodeUnspsc190501.50346209'),
	'identifierSchemeCodeUnspsc190501.50346210': __('identifierSchemeCodeUnspsc190501.50346210'),
	'identifierSchemeCodeUnspsc190501.50346211': __('identifierSchemeCodeUnspsc190501.50346211'),
	'identifierSchemeCodeUnspsc190501.50346212': __('identifierSchemeCodeUnspsc190501.50346212'),
	'identifierSchemeCodeUnspsc190501.50346213': __('identifierSchemeCodeUnspsc190501.50346213'),
	'identifierSchemeCodeUnspsc190501.50346214': __('identifierSchemeCodeUnspsc190501.50346214'),
	'identifierSchemeCodeUnspsc190501.50346215': __('identifierSchemeCodeUnspsc190501.50346215'),
	'identifierSchemeCodeUnspsc190501.50346216': __('identifierSchemeCodeUnspsc190501.50346216'),
	'identifierSchemeCodeUnspsc190501.50346217': __('identifierSchemeCodeUnspsc190501.50346217'),
	'identifierSchemeCodeUnspsc190501.50346218': __('identifierSchemeCodeUnspsc190501.50346218'),
	'identifierSchemeCodeUnspsc190501.50346219': __('identifierSchemeCodeUnspsc190501.50346219'),
	'identifierSchemeCodeUnspsc190501.50346220': __('identifierSchemeCodeUnspsc190501.50346220'),
	'identifierSchemeCodeUnspsc190501.50346221': __('identifierSchemeCodeUnspsc190501.50346221'),
	'identifierSchemeCodeUnspsc190501.50346222': __('identifierSchemeCodeUnspsc190501.50346222'),
	'identifierSchemeCodeUnspsc190501.50346223': __('identifierSchemeCodeUnspsc190501.50346223'),
	'identifierSchemeCodeUnspsc190501.50346224': __('identifierSchemeCodeUnspsc190501.50346224'),
	'identifierSchemeCodeUnspsc190501.50346225': __('identifierSchemeCodeUnspsc190501.50346225'),
	'identifierSchemeCodeUnspsc190501.50346226': __('identifierSchemeCodeUnspsc190501.50346226'),
	'identifierSchemeCodeUnspsc190501.50346227': __('identifierSchemeCodeUnspsc190501.50346227'),
	'identifierSchemeCodeUnspsc190501.50346228': __('identifierSchemeCodeUnspsc190501.50346228'),
	'identifierSchemeCodeUnspsc190501.50346229': __('identifierSchemeCodeUnspsc190501.50346229'),
	'identifierSchemeCodeUnspsc190501.50346300': __('identifierSchemeCodeUnspsc190501.50346300'),
	'identifierSchemeCodeUnspsc190501.50346301': __('identifierSchemeCodeUnspsc190501.50346301'),
	'identifierSchemeCodeUnspsc190501.50346302': __('identifierSchemeCodeUnspsc190501.50346302'),
	'identifierSchemeCodeUnspsc190501.50346303': __('identifierSchemeCodeUnspsc190501.50346303'),
	'identifierSchemeCodeUnspsc190501.50346304': __('identifierSchemeCodeUnspsc190501.50346304'),
	'identifierSchemeCodeUnspsc190501.50346305': __('identifierSchemeCodeUnspsc190501.50346305'),
	'identifierSchemeCodeUnspsc190501.50346306': __('identifierSchemeCodeUnspsc190501.50346306'),
	'identifierSchemeCodeUnspsc190501.50346307': __('identifierSchemeCodeUnspsc190501.50346307'),
	'identifierSchemeCodeUnspsc190501.50346308': __('identifierSchemeCodeUnspsc190501.50346308'),
	'identifierSchemeCodeUnspsc190501.50346309': __('identifierSchemeCodeUnspsc190501.50346309'),
	'identifierSchemeCodeUnspsc190501.50346310': __('identifierSchemeCodeUnspsc190501.50346310'),
	'identifierSchemeCodeUnspsc190501.50346400': __('identifierSchemeCodeUnspsc190501.50346400'),
	'identifierSchemeCodeUnspsc190501.50346401': __('identifierSchemeCodeUnspsc190501.50346401'),
	'identifierSchemeCodeUnspsc190501.50346402': __('identifierSchemeCodeUnspsc190501.50346402'),
	'identifierSchemeCodeUnspsc190501.50346403': __('identifierSchemeCodeUnspsc190501.50346403'),
	'identifierSchemeCodeUnspsc190501.50346404': __('identifierSchemeCodeUnspsc190501.50346404'),
	'identifierSchemeCodeUnspsc190501.50346500': __('identifierSchemeCodeUnspsc190501.50346500'),
	'identifierSchemeCodeUnspsc190501.50346501': __('identifierSchemeCodeUnspsc190501.50346501'),
	'identifierSchemeCodeUnspsc190501.50346502': __('identifierSchemeCodeUnspsc190501.50346502'),
	'identifierSchemeCodeUnspsc190501.50346600': __('identifierSchemeCodeUnspsc190501.50346600'),
	'identifierSchemeCodeUnspsc190501.50346601': __('identifierSchemeCodeUnspsc190501.50346601'),
	'identifierSchemeCodeUnspsc190501.50346602': __('identifierSchemeCodeUnspsc190501.50346602'),
	'identifierSchemeCodeUnspsc190501.50346603': __('identifierSchemeCodeUnspsc190501.50346603'),
	'identifierSchemeCodeUnspsc190501.50346604': __('identifierSchemeCodeUnspsc190501.50346604'),
	'identifierSchemeCodeUnspsc190501.50346700': __('identifierSchemeCodeUnspsc190501.50346700'),
	'identifierSchemeCodeUnspsc190501.50346701': __('identifierSchemeCodeUnspsc190501.50346701'),
	'identifierSchemeCodeUnspsc190501.50346702': __('identifierSchemeCodeUnspsc190501.50346702'),
	'identifierSchemeCodeUnspsc190501.50346703': __('identifierSchemeCodeUnspsc190501.50346703'),
	'identifierSchemeCodeUnspsc190501.50346800': __('identifierSchemeCodeUnspsc190501.50346800'),
	'identifierSchemeCodeUnspsc190501.50346801': __('identifierSchemeCodeUnspsc190501.50346801'),
	'identifierSchemeCodeUnspsc190501.50346802': __('identifierSchemeCodeUnspsc190501.50346802'),
	'identifierSchemeCodeUnspsc190501.50346803': __('identifierSchemeCodeUnspsc190501.50346803'),
	'identifierSchemeCodeUnspsc190501.50346900': __('identifierSchemeCodeUnspsc190501.50346900'),
	'identifierSchemeCodeUnspsc190501.50346901': __('identifierSchemeCodeUnspsc190501.50346901'),
	'identifierSchemeCodeUnspsc190501.50346902': __('identifierSchemeCodeUnspsc190501.50346902'),
	'identifierSchemeCodeUnspsc190501.50346903': __('identifierSchemeCodeUnspsc190501.50346903'),
	'identifierSchemeCodeUnspsc190501.50346904': __('identifierSchemeCodeUnspsc190501.50346904'),
	'identifierSchemeCodeUnspsc190501.50346905': __('identifierSchemeCodeUnspsc190501.50346905'),
	'identifierSchemeCodeUnspsc190501.50346906': __('identifierSchemeCodeUnspsc190501.50346906'),
	'identifierSchemeCodeUnspsc190501.50347000': __('identifierSchemeCodeUnspsc190501.50347000'),
	'identifierSchemeCodeUnspsc190501.50347001': __('identifierSchemeCodeUnspsc190501.50347001'),
	'identifierSchemeCodeUnspsc190501.50347002': __('identifierSchemeCodeUnspsc190501.50347002'),
	'identifierSchemeCodeUnspsc190501.50347003': __('identifierSchemeCodeUnspsc190501.50347003'),
	'identifierSchemeCodeUnspsc190501.50347004': __('identifierSchemeCodeUnspsc190501.50347004'),
	'identifierSchemeCodeUnspsc190501.50347005': __('identifierSchemeCodeUnspsc190501.50347005'),
	'identifierSchemeCodeUnspsc190501.50347006': __('identifierSchemeCodeUnspsc190501.50347006'),
	'identifierSchemeCodeUnspsc190501.50347007': __('identifierSchemeCodeUnspsc190501.50347007'),
	'identifierSchemeCodeUnspsc190501.50347008': __('identifierSchemeCodeUnspsc190501.50347008'),
	'identifierSchemeCodeUnspsc190501.50347009': __('identifierSchemeCodeUnspsc190501.50347009'),
	'identifierSchemeCodeUnspsc190501.50347010': __('identifierSchemeCodeUnspsc190501.50347010'),
	'identifierSchemeCodeUnspsc190501.50347011': __('identifierSchemeCodeUnspsc190501.50347011'),
	'identifierSchemeCodeUnspsc190501.50347012': __('identifierSchemeCodeUnspsc190501.50347012'),
	'identifierSchemeCodeUnspsc190501.50347013': __('identifierSchemeCodeUnspsc190501.50347013'),
	'identifierSchemeCodeUnspsc190501.50347014': __('identifierSchemeCodeUnspsc190501.50347014'),
	'identifierSchemeCodeUnspsc190501.50347015': __('identifierSchemeCodeUnspsc190501.50347015'),
	'identifierSchemeCodeUnspsc190501.50347016': __('identifierSchemeCodeUnspsc190501.50347016'),
	'identifierSchemeCodeUnspsc190501.50347017': __('identifierSchemeCodeUnspsc190501.50347017'),
	'identifierSchemeCodeUnspsc190501.50347018': __('identifierSchemeCodeUnspsc190501.50347018'),
	'identifierSchemeCodeUnspsc190501.50347019': __('identifierSchemeCodeUnspsc190501.50347019'),
	'identifierSchemeCodeUnspsc190501.50347020': __('identifierSchemeCodeUnspsc190501.50347020'),
	'identifierSchemeCodeUnspsc190501.50347021': __('identifierSchemeCodeUnspsc190501.50347021'),
	'identifierSchemeCodeUnspsc190501.50347022': __('identifierSchemeCodeUnspsc190501.50347022'),
	'identifierSchemeCodeUnspsc190501.50347023': __('identifierSchemeCodeUnspsc190501.50347023'),
	'identifierSchemeCodeUnspsc190501.50347024': __('identifierSchemeCodeUnspsc190501.50347024'),
	'identifierSchemeCodeUnspsc190501.50347025': __('identifierSchemeCodeUnspsc190501.50347025'),
	'identifierSchemeCodeUnspsc190501.50347026': __('identifierSchemeCodeUnspsc190501.50347026'),
	'identifierSchemeCodeUnspsc190501.50347027': __('identifierSchemeCodeUnspsc190501.50347027'),
	'identifierSchemeCodeUnspsc190501.50347028': __('identifierSchemeCodeUnspsc190501.50347028'),
	'identifierSchemeCodeUnspsc190501.50347029': __('identifierSchemeCodeUnspsc190501.50347029'),
	'identifierSchemeCodeUnspsc190501.50347030': __('identifierSchemeCodeUnspsc190501.50347030'),
	'identifierSchemeCodeUnspsc190501.50347031': __('identifierSchemeCodeUnspsc190501.50347031'),
	'identifierSchemeCodeUnspsc190501.50347032': __('identifierSchemeCodeUnspsc190501.50347032'),
	'identifierSchemeCodeUnspsc190501.50347033': __('identifierSchemeCodeUnspsc190501.50347033'),
	'identifierSchemeCodeUnspsc190501.50347034': __('identifierSchemeCodeUnspsc190501.50347034'),
	'identifierSchemeCodeUnspsc190501.50347035': __('identifierSchemeCodeUnspsc190501.50347035'),
	'identifierSchemeCodeUnspsc190501.50347036': __('identifierSchemeCodeUnspsc190501.50347036'),
	'identifierSchemeCodeUnspsc190501.50347037': __('identifierSchemeCodeUnspsc190501.50347037'),
	'identifierSchemeCodeUnspsc190501.50347100': __('identifierSchemeCodeUnspsc190501.50347100'),
	'identifierSchemeCodeUnspsc190501.50347101': __('identifierSchemeCodeUnspsc190501.50347101'),
	'identifierSchemeCodeUnspsc190501.50347102': __('identifierSchemeCodeUnspsc190501.50347102'),
	'identifierSchemeCodeUnspsc190501.50347103': __('identifierSchemeCodeUnspsc190501.50347103'),
	'identifierSchemeCodeUnspsc190501.50347104': __('identifierSchemeCodeUnspsc190501.50347104'),
	'identifierSchemeCodeUnspsc190501.50347200': __('identifierSchemeCodeUnspsc190501.50347200'),
	'identifierSchemeCodeUnspsc190501.50347201': __('identifierSchemeCodeUnspsc190501.50347201'),
	'identifierSchemeCodeUnspsc190501.50347202': __('identifierSchemeCodeUnspsc190501.50347202'),
	'identifierSchemeCodeUnspsc190501.50347203': __('identifierSchemeCodeUnspsc190501.50347203'),
	'identifierSchemeCodeUnspsc190501.50347204': __('identifierSchemeCodeUnspsc190501.50347204'),
	'identifierSchemeCodeUnspsc190501.50347205': __('identifierSchemeCodeUnspsc190501.50347205'),
	'identifierSchemeCodeUnspsc190501.50347206': __('identifierSchemeCodeUnspsc190501.50347206'),
	'identifierSchemeCodeUnspsc190501.50347207': __('identifierSchemeCodeUnspsc190501.50347207'),
	'identifierSchemeCodeUnspsc190501.50347208': __('identifierSchemeCodeUnspsc190501.50347208'),
	'identifierSchemeCodeUnspsc190501.50347209': __('identifierSchemeCodeUnspsc190501.50347209'),
	'identifierSchemeCodeUnspsc190501.50347210': __('identifierSchemeCodeUnspsc190501.50347210'),
	'identifierSchemeCodeUnspsc190501.50347211': __('identifierSchemeCodeUnspsc190501.50347211'),
	'identifierSchemeCodeUnspsc190501.50347212': __('identifierSchemeCodeUnspsc190501.50347212'),
	'identifierSchemeCodeUnspsc190501.50347213': __('identifierSchemeCodeUnspsc190501.50347213'),
	'identifierSchemeCodeUnspsc190501.50347214': __('identifierSchemeCodeUnspsc190501.50347214'),
	'identifierSchemeCodeUnspsc190501.50347215': __('identifierSchemeCodeUnspsc190501.50347215'),
	'identifierSchemeCodeUnspsc190501.50347216': __('identifierSchemeCodeUnspsc190501.50347216'),
	'identifierSchemeCodeUnspsc190501.50347217': __('identifierSchemeCodeUnspsc190501.50347217'),
	'identifierSchemeCodeUnspsc190501.50347218': __('identifierSchemeCodeUnspsc190501.50347218'),
	'identifierSchemeCodeUnspsc190501.50347219': __('identifierSchemeCodeUnspsc190501.50347219'),
	'identifierSchemeCodeUnspsc190501.50347220': __('identifierSchemeCodeUnspsc190501.50347220'),
	'identifierSchemeCodeUnspsc190501.50347221': __('identifierSchemeCodeUnspsc190501.50347221'),
	'identifierSchemeCodeUnspsc190501.50347222': __('identifierSchemeCodeUnspsc190501.50347222'),
	'identifierSchemeCodeUnspsc190501.50347223': __('identifierSchemeCodeUnspsc190501.50347223'),
	'identifierSchemeCodeUnspsc190501.50347224': __('identifierSchemeCodeUnspsc190501.50347224'),
	'identifierSchemeCodeUnspsc190501.50347225': __('identifierSchemeCodeUnspsc190501.50347225'),
	'identifierSchemeCodeUnspsc190501.50347226': __('identifierSchemeCodeUnspsc190501.50347226'),
	'identifierSchemeCodeUnspsc190501.50347227': __('identifierSchemeCodeUnspsc190501.50347227'),
	'identifierSchemeCodeUnspsc190501.50347228': __('identifierSchemeCodeUnspsc190501.50347228'),
	'identifierSchemeCodeUnspsc190501.50347229': __('identifierSchemeCodeUnspsc190501.50347229'),
	'identifierSchemeCodeUnspsc190501.50347230': __('identifierSchemeCodeUnspsc190501.50347230'),
	'identifierSchemeCodeUnspsc190501.50350000': __('identifierSchemeCodeUnspsc190501.50350000'),
	'identifierSchemeCodeUnspsc190501.50351500': __('identifierSchemeCodeUnspsc190501.50351500'),
	'identifierSchemeCodeUnspsc190501.50351501': __('identifierSchemeCodeUnspsc190501.50351501'),
	'identifierSchemeCodeUnspsc190501.50351502': __('identifierSchemeCodeUnspsc190501.50351502'),
	'identifierSchemeCodeUnspsc190501.50351503': __('identifierSchemeCodeUnspsc190501.50351503'),
	'identifierSchemeCodeUnspsc190501.50351504': __('identifierSchemeCodeUnspsc190501.50351504'),
	'identifierSchemeCodeUnspsc190501.50351505': __('identifierSchemeCodeUnspsc190501.50351505'),
	'identifierSchemeCodeUnspsc190501.50351506': __('identifierSchemeCodeUnspsc190501.50351506'),
	'identifierSchemeCodeUnspsc190501.50351507': __('identifierSchemeCodeUnspsc190501.50351507'),
	'identifierSchemeCodeUnspsc190501.50351508': __('identifierSchemeCodeUnspsc190501.50351508'),
	'identifierSchemeCodeUnspsc190501.50351509': __('identifierSchemeCodeUnspsc190501.50351509'),
	'identifierSchemeCodeUnspsc190501.50351510': __('identifierSchemeCodeUnspsc190501.50351510'),
	'identifierSchemeCodeUnspsc190501.50351511': __('identifierSchemeCodeUnspsc190501.50351511'),
	'identifierSchemeCodeUnspsc190501.50351512': __('identifierSchemeCodeUnspsc190501.50351512'),
	'identifierSchemeCodeUnspsc190501.50351513': __('identifierSchemeCodeUnspsc190501.50351513'),
	'identifierSchemeCodeUnspsc190501.50351514': __('identifierSchemeCodeUnspsc190501.50351514'),
	'identifierSchemeCodeUnspsc190501.50351515': __('identifierSchemeCodeUnspsc190501.50351515'),
	'identifierSchemeCodeUnspsc190501.50351516': __('identifierSchemeCodeUnspsc190501.50351516'),
	'identifierSchemeCodeUnspsc190501.50351517': __('identifierSchemeCodeUnspsc190501.50351517'),
	'identifierSchemeCodeUnspsc190501.50351518': __('identifierSchemeCodeUnspsc190501.50351518'),
	'identifierSchemeCodeUnspsc190501.50351519': __('identifierSchemeCodeUnspsc190501.50351519'),
	'identifierSchemeCodeUnspsc190501.50351520': __('identifierSchemeCodeUnspsc190501.50351520'),
	'identifierSchemeCodeUnspsc190501.50351521': __('identifierSchemeCodeUnspsc190501.50351521'),
	'identifierSchemeCodeUnspsc190501.50351522': __('identifierSchemeCodeUnspsc190501.50351522'),
	'identifierSchemeCodeUnspsc190501.50351523': __('identifierSchemeCodeUnspsc190501.50351523'),
	'identifierSchemeCodeUnspsc190501.50351524': __('identifierSchemeCodeUnspsc190501.50351524'),
	'identifierSchemeCodeUnspsc190501.50351525': __('identifierSchemeCodeUnspsc190501.50351525'),
	'identifierSchemeCodeUnspsc190501.50351526': __('identifierSchemeCodeUnspsc190501.50351526'),
	'identifierSchemeCodeUnspsc190501.50351527': __('identifierSchemeCodeUnspsc190501.50351527'),
	'identifierSchemeCodeUnspsc190501.50351528': __('identifierSchemeCodeUnspsc190501.50351528'),
	'identifierSchemeCodeUnspsc190501.50351529': __('identifierSchemeCodeUnspsc190501.50351529'),
	'identifierSchemeCodeUnspsc190501.50351530': __('identifierSchemeCodeUnspsc190501.50351530'),
	'identifierSchemeCodeUnspsc190501.50351531': __('identifierSchemeCodeUnspsc190501.50351531'),
	'identifierSchemeCodeUnspsc190501.50351532': __('identifierSchemeCodeUnspsc190501.50351532'),
	'identifierSchemeCodeUnspsc190501.50351533': __('identifierSchemeCodeUnspsc190501.50351533'),
	'identifierSchemeCodeUnspsc190501.50351534': __('identifierSchemeCodeUnspsc190501.50351534'),
	'identifierSchemeCodeUnspsc190501.50351535': __('identifierSchemeCodeUnspsc190501.50351535'),
	'identifierSchemeCodeUnspsc190501.50351536': __('identifierSchemeCodeUnspsc190501.50351536'),
	'identifierSchemeCodeUnspsc190501.50351537': __('identifierSchemeCodeUnspsc190501.50351537'),
	'identifierSchemeCodeUnspsc190501.50351538': __('identifierSchemeCodeUnspsc190501.50351538'),
	'identifierSchemeCodeUnspsc190501.50351539': __('identifierSchemeCodeUnspsc190501.50351539'),
	'identifierSchemeCodeUnspsc190501.50351540': __('identifierSchemeCodeUnspsc190501.50351540'),
	'identifierSchemeCodeUnspsc190501.50351541': __('identifierSchemeCodeUnspsc190501.50351541'),
	'identifierSchemeCodeUnspsc190501.50351542': __('identifierSchemeCodeUnspsc190501.50351542'),
	'identifierSchemeCodeUnspsc190501.50351543': __('identifierSchemeCodeUnspsc190501.50351543'),
	'identifierSchemeCodeUnspsc190501.50351544': __('identifierSchemeCodeUnspsc190501.50351544'),
	'identifierSchemeCodeUnspsc190501.50351545': __('identifierSchemeCodeUnspsc190501.50351545'),
	'identifierSchemeCodeUnspsc190501.50351546': __('identifierSchemeCodeUnspsc190501.50351546'),
	'identifierSchemeCodeUnspsc190501.50351547': __('identifierSchemeCodeUnspsc190501.50351547'),
	'identifierSchemeCodeUnspsc190501.50351548': __('identifierSchemeCodeUnspsc190501.50351548'),
	'identifierSchemeCodeUnspsc190501.50351549': __('identifierSchemeCodeUnspsc190501.50351549'),
	'identifierSchemeCodeUnspsc190501.50351550': __('identifierSchemeCodeUnspsc190501.50351550'),
	'identifierSchemeCodeUnspsc190501.50351551': __('identifierSchemeCodeUnspsc190501.50351551'),
	'identifierSchemeCodeUnspsc190501.50351552': __('identifierSchemeCodeUnspsc190501.50351552'),
	'identifierSchemeCodeUnspsc190501.50351553': __('identifierSchemeCodeUnspsc190501.50351553'),
	'identifierSchemeCodeUnspsc190501.50351554': __('identifierSchemeCodeUnspsc190501.50351554'),
	'identifierSchemeCodeUnspsc190501.50351555': __('identifierSchemeCodeUnspsc190501.50351555'),
	'identifierSchemeCodeUnspsc190501.50351556': __('identifierSchemeCodeUnspsc190501.50351556'),
	'identifierSchemeCodeUnspsc190501.50351557': __('identifierSchemeCodeUnspsc190501.50351557'),
	'identifierSchemeCodeUnspsc190501.50351558': __('identifierSchemeCodeUnspsc190501.50351558'),
	'identifierSchemeCodeUnspsc190501.50351559': __('identifierSchemeCodeUnspsc190501.50351559'),
	'identifierSchemeCodeUnspsc190501.50351560': __('identifierSchemeCodeUnspsc190501.50351560'),
	'identifierSchemeCodeUnspsc190501.50351561': __('identifierSchemeCodeUnspsc190501.50351561'),
	'identifierSchemeCodeUnspsc190501.50351562': __('identifierSchemeCodeUnspsc190501.50351562'),
	'identifierSchemeCodeUnspsc190501.50351563': __('identifierSchemeCodeUnspsc190501.50351563'),
	'identifierSchemeCodeUnspsc190501.50351564': __('identifierSchemeCodeUnspsc190501.50351564'),
	'identifierSchemeCodeUnspsc190501.50351565': __('identifierSchemeCodeUnspsc190501.50351565'),
	'identifierSchemeCodeUnspsc190501.50351566': __('identifierSchemeCodeUnspsc190501.50351566'),
	'identifierSchemeCodeUnspsc190501.50351567': __('identifierSchemeCodeUnspsc190501.50351567'),
	'identifierSchemeCodeUnspsc190501.50351568': __('identifierSchemeCodeUnspsc190501.50351568'),
	'identifierSchemeCodeUnspsc190501.50351569': __('identifierSchemeCodeUnspsc190501.50351569'),
	'identifierSchemeCodeUnspsc190501.50351570': __('identifierSchemeCodeUnspsc190501.50351570'),
	'identifierSchemeCodeUnspsc190501.50351571': __('identifierSchemeCodeUnspsc190501.50351571'),
	'identifierSchemeCodeUnspsc190501.50351572': __('identifierSchemeCodeUnspsc190501.50351572'),
	'identifierSchemeCodeUnspsc190501.50351573': __('identifierSchemeCodeUnspsc190501.50351573'),
	'identifierSchemeCodeUnspsc190501.50351574': __('identifierSchemeCodeUnspsc190501.50351574'),
	'identifierSchemeCodeUnspsc190501.50351575': __('identifierSchemeCodeUnspsc190501.50351575'),
	'identifierSchemeCodeUnspsc190501.50351576': __('identifierSchemeCodeUnspsc190501.50351576'),
	'identifierSchemeCodeUnspsc190501.50351577': __('identifierSchemeCodeUnspsc190501.50351577'),
	'identifierSchemeCodeUnspsc190501.50351578': __('identifierSchemeCodeUnspsc190501.50351578'),
	'identifierSchemeCodeUnspsc190501.50351579': __('identifierSchemeCodeUnspsc190501.50351579'),
	'identifierSchemeCodeUnspsc190501.50351580': __('identifierSchemeCodeUnspsc190501.50351580'),
	'identifierSchemeCodeUnspsc190501.50351581': __('identifierSchemeCodeUnspsc190501.50351581'),
	'identifierSchemeCodeUnspsc190501.50351582': __('identifierSchemeCodeUnspsc190501.50351582'),
	'identifierSchemeCodeUnspsc190501.50351583': __('identifierSchemeCodeUnspsc190501.50351583'),
	'identifierSchemeCodeUnspsc190501.50351584': __('identifierSchemeCodeUnspsc190501.50351584'),
	'identifierSchemeCodeUnspsc190501.50351585': __('identifierSchemeCodeUnspsc190501.50351585'),
	'identifierSchemeCodeUnspsc190501.50351586': __('identifierSchemeCodeUnspsc190501.50351586'),
	'identifierSchemeCodeUnspsc190501.50351587': __('identifierSchemeCodeUnspsc190501.50351587'),
	'identifierSchemeCodeUnspsc190501.50351588': __('identifierSchemeCodeUnspsc190501.50351588'),
	'identifierSchemeCodeUnspsc190501.50351589': __('identifierSchemeCodeUnspsc190501.50351589'),
	'identifierSchemeCodeUnspsc190501.50351590': __('identifierSchemeCodeUnspsc190501.50351590'),
	'identifierSchemeCodeUnspsc190501.50351591': __('identifierSchemeCodeUnspsc190501.50351591'),
	'identifierSchemeCodeUnspsc190501.50351592': __('identifierSchemeCodeUnspsc190501.50351592'),
	'identifierSchemeCodeUnspsc190501.50351593': __('identifierSchemeCodeUnspsc190501.50351593'),
	'identifierSchemeCodeUnspsc190501.50351594': __('identifierSchemeCodeUnspsc190501.50351594'),
	'identifierSchemeCodeUnspsc190501.50351595': __('identifierSchemeCodeUnspsc190501.50351595'),
	'identifierSchemeCodeUnspsc190501.50351596': __('identifierSchemeCodeUnspsc190501.50351596'),
	'identifierSchemeCodeUnspsc190501.50351600': __('identifierSchemeCodeUnspsc190501.50351600'),
	'identifierSchemeCodeUnspsc190501.50351601': __('identifierSchemeCodeUnspsc190501.50351601'),
	'identifierSchemeCodeUnspsc190501.50351602': __('identifierSchemeCodeUnspsc190501.50351602'),
	'identifierSchemeCodeUnspsc190501.50351603': __('identifierSchemeCodeUnspsc190501.50351603'),
	'identifierSchemeCodeUnspsc190501.50351604': __('identifierSchemeCodeUnspsc190501.50351604'),
	'identifierSchemeCodeUnspsc190501.50351605': __('identifierSchemeCodeUnspsc190501.50351605'),
	'identifierSchemeCodeUnspsc190501.50351606': __('identifierSchemeCodeUnspsc190501.50351606'),
	'identifierSchemeCodeUnspsc190501.50351607': __('identifierSchemeCodeUnspsc190501.50351607'),
	'identifierSchemeCodeUnspsc190501.50351608': __('identifierSchemeCodeUnspsc190501.50351608'),
	'identifierSchemeCodeUnspsc190501.50351609': __('identifierSchemeCodeUnspsc190501.50351609'),
	'identifierSchemeCodeUnspsc190501.50351610': __('identifierSchemeCodeUnspsc190501.50351610'),
	'identifierSchemeCodeUnspsc190501.50351611': __('identifierSchemeCodeUnspsc190501.50351611'),
	'identifierSchemeCodeUnspsc190501.50351612': __('identifierSchemeCodeUnspsc190501.50351612'),
	'identifierSchemeCodeUnspsc190501.50351613': __('identifierSchemeCodeUnspsc190501.50351613'),
	'identifierSchemeCodeUnspsc190501.50351614': __('identifierSchemeCodeUnspsc190501.50351614'),
	'identifierSchemeCodeUnspsc190501.50351615': __('identifierSchemeCodeUnspsc190501.50351615'),
	'identifierSchemeCodeUnspsc190501.50351616': __('identifierSchemeCodeUnspsc190501.50351616'),
	'identifierSchemeCodeUnspsc190501.50351617': __('identifierSchemeCodeUnspsc190501.50351617'),
	'identifierSchemeCodeUnspsc190501.50351618': __('identifierSchemeCodeUnspsc190501.50351618'),
	'identifierSchemeCodeUnspsc190501.50351619': __('identifierSchemeCodeUnspsc190501.50351619'),
	'identifierSchemeCodeUnspsc190501.50351620': __('identifierSchemeCodeUnspsc190501.50351620'),
	'identifierSchemeCodeUnspsc190501.50351621': __('identifierSchemeCodeUnspsc190501.50351621'),
	'identifierSchemeCodeUnspsc190501.50351622': __('identifierSchemeCodeUnspsc190501.50351622'),
	'identifierSchemeCodeUnspsc190501.50351623': __('identifierSchemeCodeUnspsc190501.50351623'),
	'identifierSchemeCodeUnspsc190501.50351624': __('identifierSchemeCodeUnspsc190501.50351624'),
	'identifierSchemeCodeUnspsc190501.50351625': __('identifierSchemeCodeUnspsc190501.50351625'),
	'identifierSchemeCodeUnspsc190501.50351626': __('identifierSchemeCodeUnspsc190501.50351626'),
	'identifierSchemeCodeUnspsc190501.50351627': __('identifierSchemeCodeUnspsc190501.50351627'),
	'identifierSchemeCodeUnspsc190501.50351628': __('identifierSchemeCodeUnspsc190501.50351628'),
	'identifierSchemeCodeUnspsc190501.50351629': __('identifierSchemeCodeUnspsc190501.50351629'),
	'identifierSchemeCodeUnspsc190501.50351630': __('identifierSchemeCodeUnspsc190501.50351630'),
	'identifierSchemeCodeUnspsc190501.50351631': __('identifierSchemeCodeUnspsc190501.50351631'),
	'identifierSchemeCodeUnspsc190501.50351632': __('identifierSchemeCodeUnspsc190501.50351632'),
	'identifierSchemeCodeUnspsc190501.50351633': __('identifierSchemeCodeUnspsc190501.50351633'),
	'identifierSchemeCodeUnspsc190501.50351634': __('identifierSchemeCodeUnspsc190501.50351634'),
	'identifierSchemeCodeUnspsc190501.50351635': __('identifierSchemeCodeUnspsc190501.50351635'),
	'identifierSchemeCodeUnspsc190501.50351636': __('identifierSchemeCodeUnspsc190501.50351636'),
	'identifierSchemeCodeUnspsc190501.50351637': __('identifierSchemeCodeUnspsc190501.50351637'),
	'identifierSchemeCodeUnspsc190501.50351638': __('identifierSchemeCodeUnspsc190501.50351638'),
	'identifierSchemeCodeUnspsc190501.50351639': __('identifierSchemeCodeUnspsc190501.50351639'),
	'identifierSchemeCodeUnspsc190501.50351640': __('identifierSchemeCodeUnspsc190501.50351640'),
	'identifierSchemeCodeUnspsc190501.50351641': __('identifierSchemeCodeUnspsc190501.50351641'),
	'identifierSchemeCodeUnspsc190501.50351642': __('identifierSchemeCodeUnspsc190501.50351642'),
	'identifierSchemeCodeUnspsc190501.50351643': __('identifierSchemeCodeUnspsc190501.50351643'),
	'identifierSchemeCodeUnspsc190501.50351644': __('identifierSchemeCodeUnspsc190501.50351644'),
	'identifierSchemeCodeUnspsc190501.50351645': __('identifierSchemeCodeUnspsc190501.50351645'),
	'identifierSchemeCodeUnspsc190501.50351646': __('identifierSchemeCodeUnspsc190501.50351646'),
	'identifierSchemeCodeUnspsc190501.50351647': __('identifierSchemeCodeUnspsc190501.50351647'),
	'identifierSchemeCodeUnspsc190501.50351648': __('identifierSchemeCodeUnspsc190501.50351648'),
	'identifierSchemeCodeUnspsc190501.50351649': __('identifierSchemeCodeUnspsc190501.50351649'),
	'identifierSchemeCodeUnspsc190501.50351650': __('identifierSchemeCodeUnspsc190501.50351650'),
	'identifierSchemeCodeUnspsc190501.50351651': __('identifierSchemeCodeUnspsc190501.50351651'),
	'identifierSchemeCodeUnspsc190501.50351652': __('identifierSchemeCodeUnspsc190501.50351652'),
	'identifierSchemeCodeUnspsc190501.50351653': __('identifierSchemeCodeUnspsc190501.50351653'),
	'identifierSchemeCodeUnspsc190501.50351654': __('identifierSchemeCodeUnspsc190501.50351654'),
	'identifierSchemeCodeUnspsc190501.50351655': __('identifierSchemeCodeUnspsc190501.50351655'),
	'identifierSchemeCodeUnspsc190501.50351700': __('identifierSchemeCodeUnspsc190501.50351700'),
	'identifierSchemeCodeUnspsc190501.50351701': __('identifierSchemeCodeUnspsc190501.50351701'),
	'identifierSchemeCodeUnspsc190501.50351702': __('identifierSchemeCodeUnspsc190501.50351702'),
	'identifierSchemeCodeUnspsc190501.50351703': __('identifierSchemeCodeUnspsc190501.50351703'),
	'identifierSchemeCodeUnspsc190501.50351704': __('identifierSchemeCodeUnspsc190501.50351704'),
	'identifierSchemeCodeUnspsc190501.50351705': __('identifierSchemeCodeUnspsc190501.50351705'),
	'identifierSchemeCodeUnspsc190501.50351706': __('identifierSchemeCodeUnspsc190501.50351706'),
	'identifierSchemeCodeUnspsc190501.50351707': __('identifierSchemeCodeUnspsc190501.50351707'),
	'identifierSchemeCodeUnspsc190501.50351708': __('identifierSchemeCodeUnspsc190501.50351708'),
	'identifierSchemeCodeUnspsc190501.50351709': __('identifierSchemeCodeUnspsc190501.50351709'),
	'identifierSchemeCodeUnspsc190501.50351710': __('identifierSchemeCodeUnspsc190501.50351710'),
	'identifierSchemeCodeUnspsc190501.50351711': __('identifierSchemeCodeUnspsc190501.50351711'),
	'identifierSchemeCodeUnspsc190501.50351712': __('identifierSchemeCodeUnspsc190501.50351712'),
	'identifierSchemeCodeUnspsc190501.50351713': __('identifierSchemeCodeUnspsc190501.50351713'),
	'identifierSchemeCodeUnspsc190501.50351714': __('identifierSchemeCodeUnspsc190501.50351714'),
	'identifierSchemeCodeUnspsc190501.50351715': __('identifierSchemeCodeUnspsc190501.50351715'),
	'identifierSchemeCodeUnspsc190501.50351800': __('identifierSchemeCodeUnspsc190501.50351800'),
	'identifierSchemeCodeUnspsc190501.50351801': __('identifierSchemeCodeUnspsc190501.50351801'),
	'identifierSchemeCodeUnspsc190501.50351802': __('identifierSchemeCodeUnspsc190501.50351802'),
	'identifierSchemeCodeUnspsc190501.50351803': __('identifierSchemeCodeUnspsc190501.50351803'),
	'identifierSchemeCodeUnspsc190501.50351804': __('identifierSchemeCodeUnspsc190501.50351804'),
	'identifierSchemeCodeUnspsc190501.50351805': __('identifierSchemeCodeUnspsc190501.50351805'),
	'identifierSchemeCodeUnspsc190501.50351806': __('identifierSchemeCodeUnspsc190501.50351806'),
	'identifierSchemeCodeUnspsc190501.50351807': __('identifierSchemeCodeUnspsc190501.50351807'),
	'identifierSchemeCodeUnspsc190501.50351808': __('identifierSchemeCodeUnspsc190501.50351808'),
	'identifierSchemeCodeUnspsc190501.50351809': __('identifierSchemeCodeUnspsc190501.50351809'),
	'identifierSchemeCodeUnspsc190501.50351810': __('identifierSchemeCodeUnspsc190501.50351810'),
	'identifierSchemeCodeUnspsc190501.50351811': __('identifierSchemeCodeUnspsc190501.50351811'),
	'identifierSchemeCodeUnspsc190501.50351812': __('identifierSchemeCodeUnspsc190501.50351812'),
	'identifierSchemeCodeUnspsc190501.50351813': __('identifierSchemeCodeUnspsc190501.50351813'),
	'identifierSchemeCodeUnspsc190501.50351814': __('identifierSchemeCodeUnspsc190501.50351814'),
	'identifierSchemeCodeUnspsc190501.50351900': __('identifierSchemeCodeUnspsc190501.50351900'),
	'identifierSchemeCodeUnspsc190501.50351901': __('identifierSchemeCodeUnspsc190501.50351901'),
	'identifierSchemeCodeUnspsc190501.50351902': __('identifierSchemeCodeUnspsc190501.50351902'),
	'identifierSchemeCodeUnspsc190501.50351903': __('identifierSchemeCodeUnspsc190501.50351903'),
	'identifierSchemeCodeUnspsc190501.50352000': __('identifierSchemeCodeUnspsc190501.50352000'),
	'identifierSchemeCodeUnspsc190501.50352001': __('identifierSchemeCodeUnspsc190501.50352001'),
	'identifierSchemeCodeUnspsc190501.50352002': __('identifierSchemeCodeUnspsc190501.50352002'),
	'identifierSchemeCodeUnspsc190501.50352003': __('identifierSchemeCodeUnspsc190501.50352003'),
	'identifierSchemeCodeUnspsc190501.50352004': __('identifierSchemeCodeUnspsc190501.50352004'),
	'identifierSchemeCodeUnspsc190501.50352005': __('identifierSchemeCodeUnspsc190501.50352005'),
	'identifierSchemeCodeUnspsc190501.50352006': __('identifierSchemeCodeUnspsc190501.50352006'),
	'identifierSchemeCodeUnspsc190501.50352007': __('identifierSchemeCodeUnspsc190501.50352007'),
	'identifierSchemeCodeUnspsc190501.50352008': __('identifierSchemeCodeUnspsc190501.50352008'),
	'identifierSchemeCodeUnspsc190501.50352009': __('identifierSchemeCodeUnspsc190501.50352009'),
	'identifierSchemeCodeUnspsc190501.50352010': __('identifierSchemeCodeUnspsc190501.50352010'),
	'identifierSchemeCodeUnspsc190501.50352011': __('identifierSchemeCodeUnspsc190501.50352011'),
	'identifierSchemeCodeUnspsc190501.50352012': __('identifierSchemeCodeUnspsc190501.50352012'),
	'identifierSchemeCodeUnspsc190501.50352013': __('identifierSchemeCodeUnspsc190501.50352013'),
	'identifierSchemeCodeUnspsc190501.50352014': __('identifierSchemeCodeUnspsc190501.50352014'),
	'identifierSchemeCodeUnspsc190501.50352015': __('identifierSchemeCodeUnspsc190501.50352015'),
	'identifierSchemeCodeUnspsc190501.50352100': __('identifierSchemeCodeUnspsc190501.50352100'),
	'identifierSchemeCodeUnspsc190501.50352101': __('identifierSchemeCodeUnspsc190501.50352101'),
	'identifierSchemeCodeUnspsc190501.50352102': __('identifierSchemeCodeUnspsc190501.50352102'),
	'identifierSchemeCodeUnspsc190501.50352103': __('identifierSchemeCodeUnspsc190501.50352103'),
	'identifierSchemeCodeUnspsc190501.50352104': __('identifierSchemeCodeUnspsc190501.50352104'),
	'identifierSchemeCodeUnspsc190501.50352200': __('identifierSchemeCodeUnspsc190501.50352200'),
	'identifierSchemeCodeUnspsc190501.50352201': __('identifierSchemeCodeUnspsc190501.50352201'),
	'identifierSchemeCodeUnspsc190501.50352202': __('identifierSchemeCodeUnspsc190501.50352202'),
	'identifierSchemeCodeUnspsc190501.50352203': __('identifierSchemeCodeUnspsc190501.50352203'),
	'identifierSchemeCodeUnspsc190501.50352204': __('identifierSchemeCodeUnspsc190501.50352204'),
	'identifierSchemeCodeUnspsc190501.50352205': __('identifierSchemeCodeUnspsc190501.50352205'),
	'identifierSchemeCodeUnspsc190501.50352206': __('identifierSchemeCodeUnspsc190501.50352206'),
	'identifierSchemeCodeUnspsc190501.50352207': __('identifierSchemeCodeUnspsc190501.50352207'),
	'identifierSchemeCodeUnspsc190501.50352208': __('identifierSchemeCodeUnspsc190501.50352208'),
	'identifierSchemeCodeUnspsc190501.50352209': __('identifierSchemeCodeUnspsc190501.50352209'),
	'identifierSchemeCodeUnspsc190501.50352210': __('identifierSchemeCodeUnspsc190501.50352210'),
	'identifierSchemeCodeUnspsc190501.50352211': __('identifierSchemeCodeUnspsc190501.50352211'),
	'identifierSchemeCodeUnspsc190501.50352212': __('identifierSchemeCodeUnspsc190501.50352212'),
	'identifierSchemeCodeUnspsc190501.50352213': __('identifierSchemeCodeUnspsc190501.50352213'),
	'identifierSchemeCodeUnspsc190501.50352214': __('identifierSchemeCodeUnspsc190501.50352214'),
	'identifierSchemeCodeUnspsc190501.50352215': __('identifierSchemeCodeUnspsc190501.50352215'),
	'identifierSchemeCodeUnspsc190501.50352300': __('identifierSchemeCodeUnspsc190501.50352300'),
	'identifierSchemeCodeUnspsc190501.50352301': __('identifierSchemeCodeUnspsc190501.50352301'),
	'identifierSchemeCodeUnspsc190501.50352302': __('identifierSchemeCodeUnspsc190501.50352302'),
	'identifierSchemeCodeUnspsc190501.50352303': __('identifierSchemeCodeUnspsc190501.50352303'),
	'identifierSchemeCodeUnspsc190501.50352304': __('identifierSchemeCodeUnspsc190501.50352304'),
	'identifierSchemeCodeUnspsc190501.50352400': __('identifierSchemeCodeUnspsc190501.50352400'),
	'identifierSchemeCodeUnspsc190501.50352401': __('identifierSchemeCodeUnspsc190501.50352401'),
	'identifierSchemeCodeUnspsc190501.50352402': __('identifierSchemeCodeUnspsc190501.50352402'),
	'identifierSchemeCodeUnspsc190501.50352403': __('identifierSchemeCodeUnspsc190501.50352403'),
	'identifierSchemeCodeUnspsc190501.50352404': __('identifierSchemeCodeUnspsc190501.50352404'),
	'identifierSchemeCodeUnspsc190501.50352405': __('identifierSchemeCodeUnspsc190501.50352405'),
	'identifierSchemeCodeUnspsc190501.50352406': __('identifierSchemeCodeUnspsc190501.50352406'),
	'identifierSchemeCodeUnspsc190501.50352407': __('identifierSchemeCodeUnspsc190501.50352407'),
	'identifierSchemeCodeUnspsc190501.50352408': __('identifierSchemeCodeUnspsc190501.50352408'),
	'identifierSchemeCodeUnspsc190501.50352409': __('identifierSchemeCodeUnspsc190501.50352409'),
	'identifierSchemeCodeUnspsc190501.50352500': __('identifierSchemeCodeUnspsc190501.50352500'),
	'identifierSchemeCodeUnspsc190501.50352501': __('identifierSchemeCodeUnspsc190501.50352501'),
	'identifierSchemeCodeUnspsc190501.50352502': __('identifierSchemeCodeUnspsc190501.50352502'),
	'identifierSchemeCodeUnspsc190501.50352503': __('identifierSchemeCodeUnspsc190501.50352503'),
	'identifierSchemeCodeUnspsc190501.50352504': __('identifierSchemeCodeUnspsc190501.50352504'),
	'identifierSchemeCodeUnspsc190501.50352505': __('identifierSchemeCodeUnspsc190501.50352505'),
	'identifierSchemeCodeUnspsc190501.50352506': __('identifierSchemeCodeUnspsc190501.50352506'),
	'identifierSchemeCodeUnspsc190501.50352507': __('identifierSchemeCodeUnspsc190501.50352507'),
	'identifierSchemeCodeUnspsc190501.50352508': __('identifierSchemeCodeUnspsc190501.50352508'),
	'identifierSchemeCodeUnspsc190501.50352509': __('identifierSchemeCodeUnspsc190501.50352509'),
	'identifierSchemeCodeUnspsc190501.50352510': __('identifierSchemeCodeUnspsc190501.50352510'),
	'identifierSchemeCodeUnspsc190501.50352511': __('identifierSchemeCodeUnspsc190501.50352511'),
	'identifierSchemeCodeUnspsc190501.50352512': __('identifierSchemeCodeUnspsc190501.50352512'),
	'identifierSchemeCodeUnspsc190501.50352513': __('identifierSchemeCodeUnspsc190501.50352513'),
	'identifierSchemeCodeUnspsc190501.50352514': __('identifierSchemeCodeUnspsc190501.50352514'),
	'identifierSchemeCodeUnspsc190501.50352515': __('identifierSchemeCodeUnspsc190501.50352515'),
	'identifierSchemeCodeUnspsc190501.50352516': __('identifierSchemeCodeUnspsc190501.50352516'),
	'identifierSchemeCodeUnspsc190501.50352517': __('identifierSchemeCodeUnspsc190501.50352517'),
	'identifierSchemeCodeUnspsc190501.50352518': __('identifierSchemeCodeUnspsc190501.50352518'),
	'identifierSchemeCodeUnspsc190501.50352519': __('identifierSchemeCodeUnspsc190501.50352519'),
	'identifierSchemeCodeUnspsc190501.50352520': __('identifierSchemeCodeUnspsc190501.50352520'),
	'identifierSchemeCodeUnspsc190501.50352521': __('identifierSchemeCodeUnspsc190501.50352521'),
	'identifierSchemeCodeUnspsc190501.50352522': __('identifierSchemeCodeUnspsc190501.50352522'),
	'identifierSchemeCodeUnspsc190501.50352523': __('identifierSchemeCodeUnspsc190501.50352523'),
	'identifierSchemeCodeUnspsc190501.50352524': __('identifierSchemeCodeUnspsc190501.50352524'),
	'identifierSchemeCodeUnspsc190501.50352525': __('identifierSchemeCodeUnspsc190501.50352525'),
	'identifierSchemeCodeUnspsc190501.50352527': __('identifierSchemeCodeUnspsc190501.50352527'),
	'identifierSchemeCodeUnspsc190501.50352528': __('identifierSchemeCodeUnspsc190501.50352528'),
	'identifierSchemeCodeUnspsc190501.50352529': __('identifierSchemeCodeUnspsc190501.50352529'),
	'identifierSchemeCodeUnspsc190501.50352530': __('identifierSchemeCodeUnspsc190501.50352530'),
	'identifierSchemeCodeUnspsc190501.50352531': __('identifierSchemeCodeUnspsc190501.50352531'),
	'identifierSchemeCodeUnspsc190501.50352532': __('identifierSchemeCodeUnspsc190501.50352532'),
	'identifierSchemeCodeUnspsc190501.50352600': __('identifierSchemeCodeUnspsc190501.50352600'),
	'identifierSchemeCodeUnspsc190501.50352601': __('identifierSchemeCodeUnspsc190501.50352601'),
	'identifierSchemeCodeUnspsc190501.50352602': __('identifierSchemeCodeUnspsc190501.50352602'),
	'identifierSchemeCodeUnspsc190501.50352603': __('identifierSchemeCodeUnspsc190501.50352603'),
	'identifierSchemeCodeUnspsc190501.50352604': __('identifierSchemeCodeUnspsc190501.50352604'),
	'identifierSchemeCodeUnspsc190501.50352605': __('identifierSchemeCodeUnspsc190501.50352605'),
	'identifierSchemeCodeUnspsc190501.50352606': __('identifierSchemeCodeUnspsc190501.50352606'),
	'identifierSchemeCodeUnspsc190501.50352607': __('identifierSchemeCodeUnspsc190501.50352607'),
	'identifierSchemeCodeUnspsc190501.50352700': __('identifierSchemeCodeUnspsc190501.50352700'),
	'identifierSchemeCodeUnspsc190501.50352701': __('identifierSchemeCodeUnspsc190501.50352701'),
	'identifierSchemeCodeUnspsc190501.50352702': __('identifierSchemeCodeUnspsc190501.50352702'),
	'identifierSchemeCodeUnspsc190501.50352703': __('identifierSchemeCodeUnspsc190501.50352703'),
	'identifierSchemeCodeUnspsc190501.50352704': __('identifierSchemeCodeUnspsc190501.50352704'),
	'identifierSchemeCodeUnspsc190501.50352705': __('identifierSchemeCodeUnspsc190501.50352705'),
	'identifierSchemeCodeUnspsc190501.50352706': __('identifierSchemeCodeUnspsc190501.50352706'),
	'identifierSchemeCodeUnspsc190501.50352707': __('identifierSchemeCodeUnspsc190501.50352707'),
	'identifierSchemeCodeUnspsc190501.50352708': __('identifierSchemeCodeUnspsc190501.50352708'),
	'identifierSchemeCodeUnspsc190501.50352709': __('identifierSchemeCodeUnspsc190501.50352709'),
	'identifierSchemeCodeUnspsc190501.50352710': __('identifierSchemeCodeUnspsc190501.50352710'),
	'identifierSchemeCodeUnspsc190501.50352800': __('identifierSchemeCodeUnspsc190501.50352800'),
	'identifierSchemeCodeUnspsc190501.50352801': __('identifierSchemeCodeUnspsc190501.50352801'),
	'identifierSchemeCodeUnspsc190501.50352802': __('identifierSchemeCodeUnspsc190501.50352802'),
	'identifierSchemeCodeUnspsc190501.50352803': __('identifierSchemeCodeUnspsc190501.50352803'),
	'identifierSchemeCodeUnspsc190501.50352804': __('identifierSchemeCodeUnspsc190501.50352804'),
	'identifierSchemeCodeUnspsc190501.50352805': __('identifierSchemeCodeUnspsc190501.50352805'),
	'identifierSchemeCodeUnspsc190501.50352806': __('identifierSchemeCodeUnspsc190501.50352806'),
	'identifierSchemeCodeUnspsc190501.50352900': __('identifierSchemeCodeUnspsc190501.50352900'),
	'identifierSchemeCodeUnspsc190501.50352901': __('identifierSchemeCodeUnspsc190501.50352901'),
	'identifierSchemeCodeUnspsc190501.50352902': __('identifierSchemeCodeUnspsc190501.50352902'),
	'identifierSchemeCodeUnspsc190501.50352903': __('identifierSchemeCodeUnspsc190501.50352903'),
	'identifierSchemeCodeUnspsc190501.50352904': __('identifierSchemeCodeUnspsc190501.50352904'),
	'identifierSchemeCodeUnspsc190501.50352905': __('identifierSchemeCodeUnspsc190501.50352905'),
	'identifierSchemeCodeUnspsc190501.50352906': __('identifierSchemeCodeUnspsc190501.50352906'),
	'identifierSchemeCodeUnspsc190501.50352907': __('identifierSchemeCodeUnspsc190501.50352907'),
	'identifierSchemeCodeUnspsc190501.50352908': __('identifierSchemeCodeUnspsc190501.50352908'),
	'identifierSchemeCodeUnspsc190501.50352909': __('identifierSchemeCodeUnspsc190501.50352909'),
	'identifierSchemeCodeUnspsc190501.50352910': __('identifierSchemeCodeUnspsc190501.50352910'),
	'identifierSchemeCodeUnspsc190501.50352911': __('identifierSchemeCodeUnspsc190501.50352911'),
	'identifierSchemeCodeUnspsc190501.50352912': __('identifierSchemeCodeUnspsc190501.50352912'),
	'identifierSchemeCodeUnspsc190501.50352913': __('identifierSchemeCodeUnspsc190501.50352913'),
	'identifierSchemeCodeUnspsc190501.50352914': __('identifierSchemeCodeUnspsc190501.50352914'),
	'identifierSchemeCodeUnspsc190501.50352915': __('identifierSchemeCodeUnspsc190501.50352915'),
	'identifierSchemeCodeUnspsc190501.50352916': __('identifierSchemeCodeUnspsc190501.50352916'),
	'identifierSchemeCodeUnspsc190501.50353000': __('identifierSchemeCodeUnspsc190501.50353000'),
	'identifierSchemeCodeUnspsc190501.50353001': __('identifierSchemeCodeUnspsc190501.50353001'),
	'identifierSchemeCodeUnspsc190501.50353002': __('identifierSchemeCodeUnspsc190501.50353002'),
	'identifierSchemeCodeUnspsc190501.50353100': __('identifierSchemeCodeUnspsc190501.50353100'),
	'identifierSchemeCodeUnspsc190501.50353101': __('identifierSchemeCodeUnspsc190501.50353101'),
	'identifierSchemeCodeUnspsc190501.50353102': __('identifierSchemeCodeUnspsc190501.50353102'),
	'identifierSchemeCodeUnspsc190501.50353103': __('identifierSchemeCodeUnspsc190501.50353103'),
	'identifierSchemeCodeUnspsc190501.50353104': __('identifierSchemeCodeUnspsc190501.50353104'),
	'identifierSchemeCodeUnspsc190501.50353105': __('identifierSchemeCodeUnspsc190501.50353105'),
	'identifierSchemeCodeUnspsc190501.50353106': __('identifierSchemeCodeUnspsc190501.50353106'),
	'identifierSchemeCodeUnspsc190501.50353107': __('identifierSchemeCodeUnspsc190501.50353107'),
	'identifierSchemeCodeUnspsc190501.50353108': __('identifierSchemeCodeUnspsc190501.50353108'),
	'identifierSchemeCodeUnspsc190501.50353109': __('identifierSchemeCodeUnspsc190501.50353109'),
	'identifierSchemeCodeUnspsc190501.50353110': __('identifierSchemeCodeUnspsc190501.50353110'),
	'identifierSchemeCodeUnspsc190501.50353111': __('identifierSchemeCodeUnspsc190501.50353111'),
	'identifierSchemeCodeUnspsc190501.50353200': __('identifierSchemeCodeUnspsc190501.50353200'),
	'identifierSchemeCodeUnspsc190501.50353201': __('identifierSchemeCodeUnspsc190501.50353201'),
	'identifierSchemeCodeUnspsc190501.50353202': __('identifierSchemeCodeUnspsc190501.50353202'),
	'identifierSchemeCodeUnspsc190501.50353203': __('identifierSchemeCodeUnspsc190501.50353203'),
	'identifierSchemeCodeUnspsc190501.50353204': __('identifierSchemeCodeUnspsc190501.50353204'),
	'identifierSchemeCodeUnspsc190501.50353205': __('identifierSchemeCodeUnspsc190501.50353205'),
	'identifierSchemeCodeUnspsc190501.50353206': __('identifierSchemeCodeUnspsc190501.50353206'),
	'identifierSchemeCodeUnspsc190501.50353207': __('identifierSchemeCodeUnspsc190501.50353207'),
	'identifierSchemeCodeUnspsc190501.50353300': __('identifierSchemeCodeUnspsc190501.50353300'),
	'identifierSchemeCodeUnspsc190501.50353301': __('identifierSchemeCodeUnspsc190501.50353301'),
	'identifierSchemeCodeUnspsc190501.50353302': __('identifierSchemeCodeUnspsc190501.50353302'),
	'identifierSchemeCodeUnspsc190501.50353303': __('identifierSchemeCodeUnspsc190501.50353303'),
	'identifierSchemeCodeUnspsc190501.50353304': __('identifierSchemeCodeUnspsc190501.50353304'),
	'identifierSchemeCodeUnspsc190501.50353305': __('identifierSchemeCodeUnspsc190501.50353305'),
	'identifierSchemeCodeUnspsc190501.50353306': __('identifierSchemeCodeUnspsc190501.50353306'),
	'identifierSchemeCodeUnspsc190501.50353307': __('identifierSchemeCodeUnspsc190501.50353307'),
	'identifierSchemeCodeUnspsc190501.50353308': __('identifierSchemeCodeUnspsc190501.50353308'),
	'identifierSchemeCodeUnspsc190501.50353309': __('identifierSchemeCodeUnspsc190501.50353309'),
	'identifierSchemeCodeUnspsc190501.50353400': __('identifierSchemeCodeUnspsc190501.50353400'),
	'identifierSchemeCodeUnspsc190501.50353401': __('identifierSchemeCodeUnspsc190501.50353401'),
	'identifierSchemeCodeUnspsc190501.50353402': __('identifierSchemeCodeUnspsc190501.50353402'),
	'identifierSchemeCodeUnspsc190501.50353403': __('identifierSchemeCodeUnspsc190501.50353403'),
	'identifierSchemeCodeUnspsc190501.50353404': __('identifierSchemeCodeUnspsc190501.50353404'),
	'identifierSchemeCodeUnspsc190501.50353405': __('identifierSchemeCodeUnspsc190501.50353405'),
	'identifierSchemeCodeUnspsc190501.50353406': __('identifierSchemeCodeUnspsc190501.50353406'),
	'identifierSchemeCodeUnspsc190501.50353407': __('identifierSchemeCodeUnspsc190501.50353407'),
	'identifierSchemeCodeUnspsc190501.50353408': __('identifierSchemeCodeUnspsc190501.50353408'),
	'identifierSchemeCodeUnspsc190501.50353409': __('identifierSchemeCodeUnspsc190501.50353409'),
	'identifierSchemeCodeUnspsc190501.50353410': __('identifierSchemeCodeUnspsc190501.50353410'),
	'identifierSchemeCodeUnspsc190501.50353411': __('identifierSchemeCodeUnspsc190501.50353411'),
	'identifierSchemeCodeUnspsc190501.50353412': __('identifierSchemeCodeUnspsc190501.50353412'),
	'identifierSchemeCodeUnspsc190501.50353413': __('identifierSchemeCodeUnspsc190501.50353413'),
	'identifierSchemeCodeUnspsc190501.50353414': __('identifierSchemeCodeUnspsc190501.50353414'),
	'identifierSchemeCodeUnspsc190501.50353415': __('identifierSchemeCodeUnspsc190501.50353415'),
	'identifierSchemeCodeUnspsc190501.50353416': __('identifierSchemeCodeUnspsc190501.50353416'),
	'identifierSchemeCodeUnspsc190501.50353417': __('identifierSchemeCodeUnspsc190501.50353417'),
	'identifierSchemeCodeUnspsc190501.50353418': __('identifierSchemeCodeUnspsc190501.50353418'),
	'identifierSchemeCodeUnspsc190501.50353419': __('identifierSchemeCodeUnspsc190501.50353419'),
	'identifierSchemeCodeUnspsc190501.50353420': __('identifierSchemeCodeUnspsc190501.50353420'),
	'identifierSchemeCodeUnspsc190501.50353421': __('identifierSchemeCodeUnspsc190501.50353421'),
	'identifierSchemeCodeUnspsc190501.50353422': __('identifierSchemeCodeUnspsc190501.50353422'),
	'identifierSchemeCodeUnspsc190501.50353423': __('identifierSchemeCodeUnspsc190501.50353423'),
	'identifierSchemeCodeUnspsc190501.50353424': __('identifierSchemeCodeUnspsc190501.50353424'),
	'identifierSchemeCodeUnspsc190501.50353425': __('identifierSchemeCodeUnspsc190501.50353425'),
	'identifierSchemeCodeUnspsc190501.50353426': __('identifierSchemeCodeUnspsc190501.50353426'),
	'identifierSchemeCodeUnspsc190501.50353427': __('identifierSchemeCodeUnspsc190501.50353427'),
	'identifierSchemeCodeUnspsc190501.50353428': __('identifierSchemeCodeUnspsc190501.50353428'),
	'identifierSchemeCodeUnspsc190501.50353429': __('identifierSchemeCodeUnspsc190501.50353429'),
	'identifierSchemeCodeUnspsc190501.50353430': __('identifierSchemeCodeUnspsc190501.50353430'),
	'identifierSchemeCodeUnspsc190501.50353431': __('identifierSchemeCodeUnspsc190501.50353431'),
	'identifierSchemeCodeUnspsc190501.50353432': __('identifierSchemeCodeUnspsc190501.50353432'),
	'identifierSchemeCodeUnspsc190501.50353433': __('identifierSchemeCodeUnspsc190501.50353433'),
	'identifierSchemeCodeUnspsc190501.50353434': __('identifierSchemeCodeUnspsc190501.50353434'),
	'identifierSchemeCodeUnspsc190501.50353435': __('identifierSchemeCodeUnspsc190501.50353435'),
	'identifierSchemeCodeUnspsc190501.50353436': __('identifierSchemeCodeUnspsc190501.50353436'),
	'identifierSchemeCodeUnspsc190501.50353437': __('identifierSchemeCodeUnspsc190501.50353437'),
	'identifierSchemeCodeUnspsc190501.50353438': __('identifierSchemeCodeUnspsc190501.50353438'),
	'identifierSchemeCodeUnspsc190501.50353439': __('identifierSchemeCodeUnspsc190501.50353439'),
	'identifierSchemeCodeUnspsc190501.50353440': __('identifierSchemeCodeUnspsc190501.50353440'),
	'identifierSchemeCodeUnspsc190501.50353441': __('identifierSchemeCodeUnspsc190501.50353441'),
	'identifierSchemeCodeUnspsc190501.50353442': __('identifierSchemeCodeUnspsc190501.50353442'),
	'identifierSchemeCodeUnspsc190501.50353443': __('identifierSchemeCodeUnspsc190501.50353443'),
	'identifierSchemeCodeUnspsc190501.50353444': __('identifierSchemeCodeUnspsc190501.50353444'),
	'identifierSchemeCodeUnspsc190501.50353445': __('identifierSchemeCodeUnspsc190501.50353445'),
	'identifierSchemeCodeUnspsc190501.50353446': __('identifierSchemeCodeUnspsc190501.50353446'),
	'identifierSchemeCodeUnspsc190501.50353447': __('identifierSchemeCodeUnspsc190501.50353447'),
	'identifierSchemeCodeUnspsc190501.50353448': __('identifierSchemeCodeUnspsc190501.50353448'),
	'identifierSchemeCodeUnspsc190501.50353449': __('identifierSchemeCodeUnspsc190501.50353449'),
	'identifierSchemeCodeUnspsc190501.50353450': __('identifierSchemeCodeUnspsc190501.50353450'),
	'identifierSchemeCodeUnspsc190501.50353451': __('identifierSchemeCodeUnspsc190501.50353451'),
	'identifierSchemeCodeUnspsc190501.50353452': __('identifierSchemeCodeUnspsc190501.50353452'),
	'identifierSchemeCodeUnspsc190501.50353453': __('identifierSchemeCodeUnspsc190501.50353453'),
	'identifierSchemeCodeUnspsc190501.50353454': __('identifierSchemeCodeUnspsc190501.50353454'),
	'identifierSchemeCodeUnspsc190501.50353455': __('identifierSchemeCodeUnspsc190501.50353455'),
	'identifierSchemeCodeUnspsc190501.50353456': __('identifierSchemeCodeUnspsc190501.50353456'),
	'identifierSchemeCodeUnspsc190501.50353457': __('identifierSchemeCodeUnspsc190501.50353457'),
	'identifierSchemeCodeUnspsc190501.50353458': __('identifierSchemeCodeUnspsc190501.50353458'),
	'identifierSchemeCodeUnspsc190501.50353459': __('identifierSchemeCodeUnspsc190501.50353459'),
	'identifierSchemeCodeUnspsc190501.50353460': __('identifierSchemeCodeUnspsc190501.50353460'),
	'identifierSchemeCodeUnspsc190501.50353461': __('identifierSchemeCodeUnspsc190501.50353461'),
	'identifierSchemeCodeUnspsc190501.50353462': __('identifierSchemeCodeUnspsc190501.50353462'),
	'identifierSchemeCodeUnspsc190501.50353463': __('identifierSchemeCodeUnspsc190501.50353463'),
	'identifierSchemeCodeUnspsc190501.50353464': __('identifierSchemeCodeUnspsc190501.50353464'),
	'identifierSchemeCodeUnspsc190501.50353465': __('identifierSchemeCodeUnspsc190501.50353465'),
	'identifierSchemeCodeUnspsc190501.50353466': __('identifierSchemeCodeUnspsc190501.50353466'),
	'identifierSchemeCodeUnspsc190501.50353467': __('identifierSchemeCodeUnspsc190501.50353467'),
	'identifierSchemeCodeUnspsc190501.50353468': __('identifierSchemeCodeUnspsc190501.50353468'),
	'identifierSchemeCodeUnspsc190501.50353469': __('identifierSchemeCodeUnspsc190501.50353469'),
	'identifierSchemeCodeUnspsc190501.50353470': __('identifierSchemeCodeUnspsc190501.50353470'),
	'identifierSchemeCodeUnspsc190501.50353471': __('identifierSchemeCodeUnspsc190501.50353471'),
	'identifierSchemeCodeUnspsc190501.50353472': __('identifierSchemeCodeUnspsc190501.50353472'),
	'identifierSchemeCodeUnspsc190501.50353473': __('identifierSchemeCodeUnspsc190501.50353473'),
	'identifierSchemeCodeUnspsc190501.50353474': __('identifierSchemeCodeUnspsc190501.50353474'),
	'identifierSchemeCodeUnspsc190501.50353475': __('identifierSchemeCodeUnspsc190501.50353475'),
	'identifierSchemeCodeUnspsc190501.50353476': __('identifierSchemeCodeUnspsc190501.50353476'),
	'identifierSchemeCodeUnspsc190501.50353477': __('identifierSchemeCodeUnspsc190501.50353477'),
	'identifierSchemeCodeUnspsc190501.50353478': __('identifierSchemeCodeUnspsc190501.50353478'),
	'identifierSchemeCodeUnspsc190501.50353479': __('identifierSchemeCodeUnspsc190501.50353479'),
	'identifierSchemeCodeUnspsc190501.50353480': __('identifierSchemeCodeUnspsc190501.50353480'),
	'identifierSchemeCodeUnspsc190501.50353481': __('identifierSchemeCodeUnspsc190501.50353481'),
	'identifierSchemeCodeUnspsc190501.50353482': __('identifierSchemeCodeUnspsc190501.50353482'),
	'identifierSchemeCodeUnspsc190501.50353483': __('identifierSchemeCodeUnspsc190501.50353483'),
	'identifierSchemeCodeUnspsc190501.50353484': __('identifierSchemeCodeUnspsc190501.50353484'),
	'identifierSchemeCodeUnspsc190501.50353485': __('identifierSchemeCodeUnspsc190501.50353485'),
	'identifierSchemeCodeUnspsc190501.50353486': __('identifierSchemeCodeUnspsc190501.50353486'),
	'identifierSchemeCodeUnspsc190501.50353487': __('identifierSchemeCodeUnspsc190501.50353487'),
	'identifierSchemeCodeUnspsc190501.50353488': __('identifierSchemeCodeUnspsc190501.50353488'),
	'identifierSchemeCodeUnspsc190501.50353489': __('identifierSchemeCodeUnspsc190501.50353489'),
	'identifierSchemeCodeUnspsc190501.50353490': __('identifierSchemeCodeUnspsc190501.50353490'),
	'identifierSchemeCodeUnspsc190501.50353491': __('identifierSchemeCodeUnspsc190501.50353491'),
	'identifierSchemeCodeUnspsc190501.50353492': __('identifierSchemeCodeUnspsc190501.50353492'),
	'identifierSchemeCodeUnspsc190501.50353493': __('identifierSchemeCodeUnspsc190501.50353493'),
	'identifierSchemeCodeUnspsc190501.50353494': __('identifierSchemeCodeUnspsc190501.50353494'),
	'identifierSchemeCodeUnspsc190501.50353495': __('identifierSchemeCodeUnspsc190501.50353495'),
	'identifierSchemeCodeUnspsc190501.50353496': __('identifierSchemeCodeUnspsc190501.50353496'),
	'identifierSchemeCodeUnspsc190501.50353500': __('identifierSchemeCodeUnspsc190501.50353500'),
	'identifierSchemeCodeUnspsc190501.50353501': __('identifierSchemeCodeUnspsc190501.50353501'),
	'identifierSchemeCodeUnspsc190501.50353502': __('identifierSchemeCodeUnspsc190501.50353502'),
	'identifierSchemeCodeUnspsc190501.50353503': __('identifierSchemeCodeUnspsc190501.50353503'),
	'identifierSchemeCodeUnspsc190501.50353504': __('identifierSchemeCodeUnspsc190501.50353504'),
	'identifierSchemeCodeUnspsc190501.50353505': __('identifierSchemeCodeUnspsc190501.50353505'),
	'identifierSchemeCodeUnspsc190501.50353506': __('identifierSchemeCodeUnspsc190501.50353506'),
	'identifierSchemeCodeUnspsc190501.50353600': __('identifierSchemeCodeUnspsc190501.50353600'),
	'identifierSchemeCodeUnspsc190501.50353601': __('identifierSchemeCodeUnspsc190501.50353601'),
	'identifierSchemeCodeUnspsc190501.50353602': __('identifierSchemeCodeUnspsc190501.50353602'),
	'identifierSchemeCodeUnspsc190501.50353603': __('identifierSchemeCodeUnspsc190501.50353603'),
	'identifierSchemeCodeUnspsc190501.50353604': __('identifierSchemeCodeUnspsc190501.50353604'),
	'identifierSchemeCodeUnspsc190501.50353605': __('identifierSchemeCodeUnspsc190501.50353605'),
	'identifierSchemeCodeUnspsc190501.50353606': __('identifierSchemeCodeUnspsc190501.50353606'),
	'identifierSchemeCodeUnspsc190501.50353607': __('identifierSchemeCodeUnspsc190501.50353607'),
	'identifierSchemeCodeUnspsc190501.50353608': __('identifierSchemeCodeUnspsc190501.50353608'),
	'identifierSchemeCodeUnspsc190501.50353609': __('identifierSchemeCodeUnspsc190501.50353609'),
	'identifierSchemeCodeUnspsc190501.50353610': __('identifierSchemeCodeUnspsc190501.50353610'),
	'identifierSchemeCodeUnspsc190501.50353611': __('identifierSchemeCodeUnspsc190501.50353611'),
	'identifierSchemeCodeUnspsc190501.50353612': __('identifierSchemeCodeUnspsc190501.50353612'),
	'identifierSchemeCodeUnspsc190501.50353613': __('identifierSchemeCodeUnspsc190501.50353613'),
	'identifierSchemeCodeUnspsc190501.50353614': __('identifierSchemeCodeUnspsc190501.50353614'),
	'identifierSchemeCodeUnspsc190501.50353615': __('identifierSchemeCodeUnspsc190501.50353615'),
	'identifierSchemeCodeUnspsc190501.50353616': __('identifierSchemeCodeUnspsc190501.50353616'),
	'identifierSchemeCodeUnspsc190501.50353617': __('identifierSchemeCodeUnspsc190501.50353617'),
	'identifierSchemeCodeUnspsc190501.50353618': __('identifierSchemeCodeUnspsc190501.50353618'),
	'identifierSchemeCodeUnspsc190501.50353619': __('identifierSchemeCodeUnspsc190501.50353619'),
	'identifierSchemeCodeUnspsc190501.50353620': __('identifierSchemeCodeUnspsc190501.50353620'),
	'identifierSchemeCodeUnspsc190501.50353621': __('identifierSchemeCodeUnspsc190501.50353621'),
	'identifierSchemeCodeUnspsc190501.50353622': __('identifierSchemeCodeUnspsc190501.50353622'),
	'identifierSchemeCodeUnspsc190501.50353623': __('identifierSchemeCodeUnspsc190501.50353623'),
	'identifierSchemeCodeUnspsc190501.50353624': __('identifierSchemeCodeUnspsc190501.50353624'),
	'identifierSchemeCodeUnspsc190501.50353625': __('identifierSchemeCodeUnspsc190501.50353625'),
	'identifierSchemeCodeUnspsc190501.50353626': __('identifierSchemeCodeUnspsc190501.50353626'),
	'identifierSchemeCodeUnspsc190501.50353627': __('identifierSchemeCodeUnspsc190501.50353627'),
	'identifierSchemeCodeUnspsc190501.50353628': __('identifierSchemeCodeUnspsc190501.50353628'),
	'identifierSchemeCodeUnspsc190501.50353629': __('identifierSchemeCodeUnspsc190501.50353629'),
	'identifierSchemeCodeUnspsc190501.50353630': __('identifierSchemeCodeUnspsc190501.50353630'),
	'identifierSchemeCodeUnspsc190501.50353631': __('identifierSchemeCodeUnspsc190501.50353631'),
	'identifierSchemeCodeUnspsc190501.50353632': __('identifierSchemeCodeUnspsc190501.50353632'),
	'identifierSchemeCodeUnspsc190501.50353633': __('identifierSchemeCodeUnspsc190501.50353633'),
	'identifierSchemeCodeUnspsc190501.50353634': __('identifierSchemeCodeUnspsc190501.50353634'),
	'identifierSchemeCodeUnspsc190501.50353635': __('identifierSchemeCodeUnspsc190501.50353635'),
	'identifierSchemeCodeUnspsc190501.50353636': __('identifierSchemeCodeUnspsc190501.50353636'),
	'identifierSchemeCodeUnspsc190501.50353637': __('identifierSchemeCodeUnspsc190501.50353637'),
	'identifierSchemeCodeUnspsc190501.50353638': __('identifierSchemeCodeUnspsc190501.50353638'),
	'identifierSchemeCodeUnspsc190501.50353639': __('identifierSchemeCodeUnspsc190501.50353639'),
	'identifierSchemeCodeUnspsc190501.50353640': __('identifierSchemeCodeUnspsc190501.50353640'),
	'identifierSchemeCodeUnspsc190501.50353641': __('identifierSchemeCodeUnspsc190501.50353641'),
	'identifierSchemeCodeUnspsc190501.50353642': __('identifierSchemeCodeUnspsc190501.50353642'),
	'identifierSchemeCodeUnspsc190501.50353643': __('identifierSchemeCodeUnspsc190501.50353643'),
	'identifierSchemeCodeUnspsc190501.50353644': __('identifierSchemeCodeUnspsc190501.50353644'),
	'identifierSchemeCodeUnspsc190501.50353645': __('identifierSchemeCodeUnspsc190501.50353645'),
	'identifierSchemeCodeUnspsc190501.50353646': __('identifierSchemeCodeUnspsc190501.50353646'),
	'identifierSchemeCodeUnspsc190501.50353647': __('identifierSchemeCodeUnspsc190501.50353647'),
	'identifierSchemeCodeUnspsc190501.50353648': __('identifierSchemeCodeUnspsc190501.50353648'),
	'identifierSchemeCodeUnspsc190501.50353649': __('identifierSchemeCodeUnspsc190501.50353649'),
	'identifierSchemeCodeUnspsc190501.50353650': __('identifierSchemeCodeUnspsc190501.50353650'),
	'identifierSchemeCodeUnspsc190501.50353651': __('identifierSchemeCodeUnspsc190501.50353651'),
	'identifierSchemeCodeUnspsc190501.50353652': __('identifierSchemeCodeUnspsc190501.50353652'),
	'identifierSchemeCodeUnspsc190501.50353653': __('identifierSchemeCodeUnspsc190501.50353653'),
	'identifierSchemeCodeUnspsc190501.50353654': __('identifierSchemeCodeUnspsc190501.50353654'),
	'identifierSchemeCodeUnspsc190501.50353655': __('identifierSchemeCodeUnspsc190501.50353655'),
	'identifierSchemeCodeUnspsc190501.50353656': __('identifierSchemeCodeUnspsc190501.50353656'),
	'identifierSchemeCodeUnspsc190501.50353657': __('identifierSchemeCodeUnspsc190501.50353657'),
	'identifierSchemeCodeUnspsc190501.50353658': __('identifierSchemeCodeUnspsc190501.50353658'),
	'identifierSchemeCodeUnspsc190501.50353659': __('identifierSchemeCodeUnspsc190501.50353659'),
	'identifierSchemeCodeUnspsc190501.50353660': __('identifierSchemeCodeUnspsc190501.50353660'),
	'identifierSchemeCodeUnspsc190501.50353661': __('identifierSchemeCodeUnspsc190501.50353661'),
	'identifierSchemeCodeUnspsc190501.50353662': __('identifierSchemeCodeUnspsc190501.50353662'),
	'identifierSchemeCodeUnspsc190501.50353663': __('identifierSchemeCodeUnspsc190501.50353663'),
	'identifierSchemeCodeUnspsc190501.50353664': __('identifierSchemeCodeUnspsc190501.50353664'),
	'identifierSchemeCodeUnspsc190501.50353665': __('identifierSchemeCodeUnspsc190501.50353665'),
	'identifierSchemeCodeUnspsc190501.50353700': __('identifierSchemeCodeUnspsc190501.50353700'),
	'identifierSchemeCodeUnspsc190501.50353701': __('identifierSchemeCodeUnspsc190501.50353701'),
	'identifierSchemeCodeUnspsc190501.50353702': __('identifierSchemeCodeUnspsc190501.50353702'),
	'identifierSchemeCodeUnspsc190501.50353703': __('identifierSchemeCodeUnspsc190501.50353703'),
	'identifierSchemeCodeUnspsc190501.50353704': __('identifierSchemeCodeUnspsc190501.50353704'),
	'identifierSchemeCodeUnspsc190501.50353800': __('identifierSchemeCodeUnspsc190501.50353800'),
	'identifierSchemeCodeUnspsc190501.50353801': __('identifierSchemeCodeUnspsc190501.50353801'),
	'identifierSchemeCodeUnspsc190501.50353802': __('identifierSchemeCodeUnspsc190501.50353802'),
	'identifierSchemeCodeUnspsc190501.50353803': __('identifierSchemeCodeUnspsc190501.50353803'),
	'identifierSchemeCodeUnspsc190501.50353804': __('identifierSchemeCodeUnspsc190501.50353804'),
	'identifierSchemeCodeUnspsc190501.50353805': __('identifierSchemeCodeUnspsc190501.50353805'),
	'identifierSchemeCodeUnspsc190501.50353900': __('identifierSchemeCodeUnspsc190501.50353900'),
	'identifierSchemeCodeUnspsc190501.50353901': __('identifierSchemeCodeUnspsc190501.50353901'),
	'identifierSchemeCodeUnspsc190501.50353902': __('identifierSchemeCodeUnspsc190501.50353902'),
	'identifierSchemeCodeUnspsc190501.50353903': __('identifierSchemeCodeUnspsc190501.50353903'),
	'identifierSchemeCodeUnspsc190501.50353904': __('identifierSchemeCodeUnspsc190501.50353904'),
	'identifierSchemeCodeUnspsc190501.50353905': __('identifierSchemeCodeUnspsc190501.50353905'),
	'identifierSchemeCodeUnspsc190501.50353906': __('identifierSchemeCodeUnspsc190501.50353906'),
	'identifierSchemeCodeUnspsc190501.50354000': __('identifierSchemeCodeUnspsc190501.50354000'),
	'identifierSchemeCodeUnspsc190501.50354001': __('identifierSchemeCodeUnspsc190501.50354001'),
	'identifierSchemeCodeUnspsc190501.50354002': __('identifierSchemeCodeUnspsc190501.50354002'),
	'identifierSchemeCodeUnspsc190501.50354003': __('identifierSchemeCodeUnspsc190501.50354003'),
	'identifierSchemeCodeUnspsc190501.50354004': __('identifierSchemeCodeUnspsc190501.50354004'),
	'identifierSchemeCodeUnspsc190501.50354005': __('identifierSchemeCodeUnspsc190501.50354005'),
	'identifierSchemeCodeUnspsc190501.50354006': __('identifierSchemeCodeUnspsc190501.50354006'),
	'identifierSchemeCodeUnspsc190501.50354100': __('identifierSchemeCodeUnspsc190501.50354100'),
	'identifierSchemeCodeUnspsc190501.50354101': __('identifierSchemeCodeUnspsc190501.50354101'),
	'identifierSchemeCodeUnspsc190501.50354102': __('identifierSchemeCodeUnspsc190501.50354102'),
	'identifierSchemeCodeUnspsc190501.50354103': __('identifierSchemeCodeUnspsc190501.50354103'),
	'identifierSchemeCodeUnspsc190501.50354104': __('identifierSchemeCodeUnspsc190501.50354104'),
	'identifierSchemeCodeUnspsc190501.50354105': __('identifierSchemeCodeUnspsc190501.50354105'),
	'identifierSchemeCodeUnspsc190501.50354106': __('identifierSchemeCodeUnspsc190501.50354106'),
	'identifierSchemeCodeUnspsc190501.50354107': __('identifierSchemeCodeUnspsc190501.50354107'),
	'identifierSchemeCodeUnspsc190501.50354108': __('identifierSchemeCodeUnspsc190501.50354108'),
	'identifierSchemeCodeUnspsc190501.50354200': __('identifierSchemeCodeUnspsc190501.50354200'),
	'identifierSchemeCodeUnspsc190501.50354201': __('identifierSchemeCodeUnspsc190501.50354201'),
	'identifierSchemeCodeUnspsc190501.50354202': __('identifierSchemeCodeUnspsc190501.50354202'),
	'identifierSchemeCodeUnspsc190501.50354203': __('identifierSchemeCodeUnspsc190501.50354203'),
	'identifierSchemeCodeUnspsc190501.50354204': __('identifierSchemeCodeUnspsc190501.50354204'),
	'identifierSchemeCodeUnspsc190501.50354205': __('identifierSchemeCodeUnspsc190501.50354205'),
	'identifierSchemeCodeUnspsc190501.50354206': __('identifierSchemeCodeUnspsc190501.50354206'),
	'identifierSchemeCodeUnspsc190501.50354207': __('identifierSchemeCodeUnspsc190501.50354207'),
	'identifierSchemeCodeUnspsc190501.50354208': __('identifierSchemeCodeUnspsc190501.50354208'),
	'identifierSchemeCodeUnspsc190501.50354300': __('identifierSchemeCodeUnspsc190501.50354300'),
	'identifierSchemeCodeUnspsc190501.50354301': __('identifierSchemeCodeUnspsc190501.50354301'),
	'identifierSchemeCodeUnspsc190501.50354302': __('identifierSchemeCodeUnspsc190501.50354302'),
	'identifierSchemeCodeUnspsc190501.50354303': __('identifierSchemeCodeUnspsc190501.50354303'),
	'identifierSchemeCodeUnspsc190501.50354304': __('identifierSchemeCodeUnspsc190501.50354304'),
	'identifierSchemeCodeUnspsc190501.50354305': __('identifierSchemeCodeUnspsc190501.50354305'),
	'identifierSchemeCodeUnspsc190501.50354306': __('identifierSchemeCodeUnspsc190501.50354306'),
	'identifierSchemeCodeUnspsc190501.50354307': __('identifierSchemeCodeUnspsc190501.50354307'),
	'identifierSchemeCodeUnspsc190501.50354308': __('identifierSchemeCodeUnspsc190501.50354308'),
	'identifierSchemeCodeUnspsc190501.50354309': __('identifierSchemeCodeUnspsc190501.50354309'),
	'identifierSchemeCodeUnspsc190501.50354310': __('identifierSchemeCodeUnspsc190501.50354310'),
	'identifierSchemeCodeUnspsc190501.50354311': __('identifierSchemeCodeUnspsc190501.50354311'),
	'identifierSchemeCodeUnspsc190501.50354312': __('identifierSchemeCodeUnspsc190501.50354312'),
	'identifierSchemeCodeUnspsc190501.50354313': __('identifierSchemeCodeUnspsc190501.50354313'),
	'identifierSchemeCodeUnspsc190501.50354400': __('identifierSchemeCodeUnspsc190501.50354400'),
	'identifierSchemeCodeUnspsc190501.50354401': __('identifierSchemeCodeUnspsc190501.50354401'),
	'identifierSchemeCodeUnspsc190501.50354402': __('identifierSchemeCodeUnspsc190501.50354402'),
	'identifierSchemeCodeUnspsc190501.50354403': __('identifierSchemeCodeUnspsc190501.50354403'),
	'identifierSchemeCodeUnspsc190501.50354404': __('identifierSchemeCodeUnspsc190501.50354404'),
	'identifierSchemeCodeUnspsc190501.50354405': __('identifierSchemeCodeUnspsc190501.50354405'),
	'identifierSchemeCodeUnspsc190501.50354406': __('identifierSchemeCodeUnspsc190501.50354406'),
	'identifierSchemeCodeUnspsc190501.50354407': __('identifierSchemeCodeUnspsc190501.50354407'),
	'identifierSchemeCodeUnspsc190501.50354408': __('identifierSchemeCodeUnspsc190501.50354408'),
	'identifierSchemeCodeUnspsc190501.50354409': __('identifierSchemeCodeUnspsc190501.50354409'),
	'identifierSchemeCodeUnspsc190501.50354410': __('identifierSchemeCodeUnspsc190501.50354410'),
	'identifierSchemeCodeUnspsc190501.50354411': __('identifierSchemeCodeUnspsc190501.50354411'),
	'identifierSchemeCodeUnspsc190501.50354412': __('identifierSchemeCodeUnspsc190501.50354412'),
	'identifierSchemeCodeUnspsc190501.50354413': __('identifierSchemeCodeUnspsc190501.50354413'),
	'identifierSchemeCodeUnspsc190501.50354414': __('identifierSchemeCodeUnspsc190501.50354414'),
	'identifierSchemeCodeUnspsc190501.50354415': __('identifierSchemeCodeUnspsc190501.50354415'),
	'identifierSchemeCodeUnspsc190501.50354416': __('identifierSchemeCodeUnspsc190501.50354416'),
	'identifierSchemeCodeUnspsc190501.50354417': __('identifierSchemeCodeUnspsc190501.50354417'),
	'identifierSchemeCodeUnspsc190501.50354418': __('identifierSchemeCodeUnspsc190501.50354418'),
	'identifierSchemeCodeUnspsc190501.50354419': __('identifierSchemeCodeUnspsc190501.50354419'),
	'identifierSchemeCodeUnspsc190501.50354420': __('identifierSchemeCodeUnspsc190501.50354420'),
	'identifierSchemeCodeUnspsc190501.50354421': __('identifierSchemeCodeUnspsc190501.50354421'),
	'identifierSchemeCodeUnspsc190501.50354422': __('identifierSchemeCodeUnspsc190501.50354422'),
	'identifierSchemeCodeUnspsc190501.50354423': __('identifierSchemeCodeUnspsc190501.50354423'),
	'identifierSchemeCodeUnspsc190501.50354424': __('identifierSchemeCodeUnspsc190501.50354424'),
	'identifierSchemeCodeUnspsc190501.50354425': __('identifierSchemeCodeUnspsc190501.50354425'),
	'identifierSchemeCodeUnspsc190501.50354426': __('identifierSchemeCodeUnspsc190501.50354426'),
	'identifierSchemeCodeUnspsc190501.50354427': __('identifierSchemeCodeUnspsc190501.50354427'),
	'identifierSchemeCodeUnspsc190501.50354428': __('identifierSchemeCodeUnspsc190501.50354428'),
	'identifierSchemeCodeUnspsc190501.50354429': __('identifierSchemeCodeUnspsc190501.50354429'),
	'identifierSchemeCodeUnspsc190501.50354430': __('identifierSchemeCodeUnspsc190501.50354430'),
	'identifierSchemeCodeUnspsc190501.50354431': __('identifierSchemeCodeUnspsc190501.50354431'),
	'identifierSchemeCodeUnspsc190501.50354432': __('identifierSchemeCodeUnspsc190501.50354432'),
	'identifierSchemeCodeUnspsc190501.50354433': __('identifierSchemeCodeUnspsc190501.50354433'),
	'identifierSchemeCodeUnspsc190501.50354434': __('identifierSchemeCodeUnspsc190501.50354434'),
	'identifierSchemeCodeUnspsc190501.50354435': __('identifierSchemeCodeUnspsc190501.50354435'),
	'identifierSchemeCodeUnspsc190501.50354436': __('identifierSchemeCodeUnspsc190501.50354436'),
	'identifierSchemeCodeUnspsc190501.50354437': __('identifierSchemeCodeUnspsc190501.50354437'),
	'identifierSchemeCodeUnspsc190501.50354438': __('identifierSchemeCodeUnspsc190501.50354438'),
	'identifierSchemeCodeUnspsc190501.50354439': __('identifierSchemeCodeUnspsc190501.50354439'),
	'identifierSchemeCodeUnspsc190501.50354440': __('identifierSchemeCodeUnspsc190501.50354440'),
	'identifierSchemeCodeUnspsc190501.50354441': __('identifierSchemeCodeUnspsc190501.50354441'),
	'identifierSchemeCodeUnspsc190501.50354442': __('identifierSchemeCodeUnspsc190501.50354442'),
	'identifierSchemeCodeUnspsc190501.50354443': __('identifierSchemeCodeUnspsc190501.50354443'),
	'identifierSchemeCodeUnspsc190501.50354444': __('identifierSchemeCodeUnspsc190501.50354444'),
	'identifierSchemeCodeUnspsc190501.50354445': __('identifierSchemeCodeUnspsc190501.50354445'),
	'identifierSchemeCodeUnspsc190501.50354446': __('identifierSchemeCodeUnspsc190501.50354446'),
	'identifierSchemeCodeUnspsc190501.50354500': __('identifierSchemeCodeUnspsc190501.50354500'),
	'identifierSchemeCodeUnspsc190501.50354501': __('identifierSchemeCodeUnspsc190501.50354501'),
	'identifierSchemeCodeUnspsc190501.50354502': __('identifierSchemeCodeUnspsc190501.50354502'),
	'identifierSchemeCodeUnspsc190501.50354503': __('identifierSchemeCodeUnspsc190501.50354503'),
	'identifierSchemeCodeUnspsc190501.50354504': __('identifierSchemeCodeUnspsc190501.50354504'),
	'identifierSchemeCodeUnspsc190501.50354505': __('identifierSchemeCodeUnspsc190501.50354505'),
	'identifierSchemeCodeUnspsc190501.50354506': __('identifierSchemeCodeUnspsc190501.50354506'),
	'identifierSchemeCodeUnspsc190501.50354507': __('identifierSchemeCodeUnspsc190501.50354507'),
	'identifierSchemeCodeUnspsc190501.50354508': __('identifierSchemeCodeUnspsc190501.50354508'),
	'identifierSchemeCodeUnspsc190501.50354509': __('identifierSchemeCodeUnspsc190501.50354509'),
	'identifierSchemeCodeUnspsc190501.50354510': __('identifierSchemeCodeUnspsc190501.50354510'),
	'identifierSchemeCodeUnspsc190501.50354511': __('identifierSchemeCodeUnspsc190501.50354511'),
	'identifierSchemeCodeUnspsc190501.50354512': __('identifierSchemeCodeUnspsc190501.50354512'),
	'identifierSchemeCodeUnspsc190501.50354513': __('identifierSchemeCodeUnspsc190501.50354513'),
	'identifierSchemeCodeUnspsc190501.50354514': __('identifierSchemeCodeUnspsc190501.50354514'),
	'identifierSchemeCodeUnspsc190501.50354515': __('identifierSchemeCodeUnspsc190501.50354515'),
	'identifierSchemeCodeUnspsc190501.50354516': __('identifierSchemeCodeUnspsc190501.50354516'),
	'identifierSchemeCodeUnspsc190501.50354517': __('identifierSchemeCodeUnspsc190501.50354517'),
	'identifierSchemeCodeUnspsc190501.50354518': __('identifierSchemeCodeUnspsc190501.50354518'),
	'identifierSchemeCodeUnspsc190501.50354519': __('identifierSchemeCodeUnspsc190501.50354519'),
	'identifierSchemeCodeUnspsc190501.50354520': __('identifierSchemeCodeUnspsc190501.50354520'),
	'identifierSchemeCodeUnspsc190501.50354521': __('identifierSchemeCodeUnspsc190501.50354521'),
	'identifierSchemeCodeUnspsc190501.50354522': __('identifierSchemeCodeUnspsc190501.50354522'),
	'identifierSchemeCodeUnspsc190501.50354523': __('identifierSchemeCodeUnspsc190501.50354523'),
	'identifierSchemeCodeUnspsc190501.50354524': __('identifierSchemeCodeUnspsc190501.50354524'),
	'identifierSchemeCodeUnspsc190501.50354525': __('identifierSchemeCodeUnspsc190501.50354525'),
	'identifierSchemeCodeUnspsc190501.50354600': __('identifierSchemeCodeUnspsc190501.50354600'),
	'identifierSchemeCodeUnspsc190501.50354601': __('identifierSchemeCodeUnspsc190501.50354601'),
	'identifierSchemeCodeUnspsc190501.50354602': __('identifierSchemeCodeUnspsc190501.50354602'),
	'identifierSchemeCodeUnspsc190501.50354603': __('identifierSchemeCodeUnspsc190501.50354603'),
	'identifierSchemeCodeUnspsc190501.50354604': __('identifierSchemeCodeUnspsc190501.50354604'),
	'identifierSchemeCodeUnspsc190501.50354605': __('identifierSchemeCodeUnspsc190501.50354605'),
	'identifierSchemeCodeUnspsc190501.50354606': __('identifierSchemeCodeUnspsc190501.50354606'),
	'identifierSchemeCodeUnspsc190501.50354607': __('identifierSchemeCodeUnspsc190501.50354607'),
	'identifierSchemeCodeUnspsc190501.50354608': __('identifierSchemeCodeUnspsc190501.50354608'),
	'identifierSchemeCodeUnspsc190501.50354609': __('identifierSchemeCodeUnspsc190501.50354609'),
	'identifierSchemeCodeUnspsc190501.50354610': __('identifierSchemeCodeUnspsc190501.50354610'),
	'identifierSchemeCodeUnspsc190501.50354611': __('identifierSchemeCodeUnspsc190501.50354611'),
	'identifierSchemeCodeUnspsc190501.50354612': __('identifierSchemeCodeUnspsc190501.50354612'),
	'identifierSchemeCodeUnspsc190501.50354613': __('identifierSchemeCodeUnspsc190501.50354613'),
	'identifierSchemeCodeUnspsc190501.50354614': __('identifierSchemeCodeUnspsc190501.50354614'),
	'identifierSchemeCodeUnspsc190501.50354615': __('identifierSchemeCodeUnspsc190501.50354615'),
	'identifierSchemeCodeUnspsc190501.50354616': __('identifierSchemeCodeUnspsc190501.50354616'),
	'identifierSchemeCodeUnspsc190501.50354617': __('identifierSchemeCodeUnspsc190501.50354617'),
	'identifierSchemeCodeUnspsc190501.50354618': __('identifierSchemeCodeUnspsc190501.50354618'),
	'identifierSchemeCodeUnspsc190501.50354619': __('identifierSchemeCodeUnspsc190501.50354619'),
	'identifierSchemeCodeUnspsc190501.50354620': __('identifierSchemeCodeUnspsc190501.50354620'),
	'identifierSchemeCodeUnspsc190501.50354621': __('identifierSchemeCodeUnspsc190501.50354621'),
	'identifierSchemeCodeUnspsc190501.50354622': __('identifierSchemeCodeUnspsc190501.50354622'),
	'identifierSchemeCodeUnspsc190501.50354623': __('identifierSchemeCodeUnspsc190501.50354623'),
	'identifierSchemeCodeUnspsc190501.50354624': __('identifierSchemeCodeUnspsc190501.50354624'),
	'identifierSchemeCodeUnspsc190501.50354625': __('identifierSchemeCodeUnspsc190501.50354625'),
	'identifierSchemeCodeUnspsc190501.50354626': __('identifierSchemeCodeUnspsc190501.50354626'),
	'identifierSchemeCodeUnspsc190501.50354627': __('identifierSchemeCodeUnspsc190501.50354627'),
	'identifierSchemeCodeUnspsc190501.50354628': __('identifierSchemeCodeUnspsc190501.50354628'),
	'identifierSchemeCodeUnspsc190501.50354629': __('identifierSchemeCodeUnspsc190501.50354629'),
	'identifierSchemeCodeUnspsc190501.50354630': __('identifierSchemeCodeUnspsc190501.50354630'),
	'identifierSchemeCodeUnspsc190501.50354631': __('identifierSchemeCodeUnspsc190501.50354631'),
	'identifierSchemeCodeUnspsc190501.50354632': __('identifierSchemeCodeUnspsc190501.50354632'),
	'identifierSchemeCodeUnspsc190501.50354633': __('identifierSchemeCodeUnspsc190501.50354633'),
	'identifierSchemeCodeUnspsc190501.50354634': __('identifierSchemeCodeUnspsc190501.50354634'),
	'identifierSchemeCodeUnspsc190501.50354635': __('identifierSchemeCodeUnspsc190501.50354635'),
	'identifierSchemeCodeUnspsc190501.50354636': __('identifierSchemeCodeUnspsc190501.50354636'),
	'identifierSchemeCodeUnspsc190501.50354637': __('identifierSchemeCodeUnspsc190501.50354637'),
	'identifierSchemeCodeUnspsc190501.50354638': __('identifierSchemeCodeUnspsc190501.50354638'),
	'identifierSchemeCodeUnspsc190501.50354639': __('identifierSchemeCodeUnspsc190501.50354639'),
	'identifierSchemeCodeUnspsc190501.50354640': __('identifierSchemeCodeUnspsc190501.50354640'),
	'identifierSchemeCodeUnspsc190501.50354641': __('identifierSchemeCodeUnspsc190501.50354641'),
	'identifierSchemeCodeUnspsc190501.50354642': __('identifierSchemeCodeUnspsc190501.50354642'),
	'identifierSchemeCodeUnspsc190501.50354643': __('identifierSchemeCodeUnspsc190501.50354643'),
	'identifierSchemeCodeUnspsc190501.50354644': __('identifierSchemeCodeUnspsc190501.50354644'),
	'identifierSchemeCodeUnspsc190501.50354645': __('identifierSchemeCodeUnspsc190501.50354645'),
	'identifierSchemeCodeUnspsc190501.50354646': __('identifierSchemeCodeUnspsc190501.50354646'),
	'identifierSchemeCodeUnspsc190501.50354647': __('identifierSchemeCodeUnspsc190501.50354647'),
	'identifierSchemeCodeUnspsc190501.50354648': __('identifierSchemeCodeUnspsc190501.50354648'),
	'identifierSchemeCodeUnspsc190501.50354649': __('identifierSchemeCodeUnspsc190501.50354649'),
	'identifierSchemeCodeUnspsc190501.50354650': __('identifierSchemeCodeUnspsc190501.50354650'),
	'identifierSchemeCodeUnspsc190501.50354651': __('identifierSchemeCodeUnspsc190501.50354651'),
	'identifierSchemeCodeUnspsc190501.50354652': __('identifierSchemeCodeUnspsc190501.50354652'),
	'identifierSchemeCodeUnspsc190501.50354653': __('identifierSchemeCodeUnspsc190501.50354653'),
	'identifierSchemeCodeUnspsc190501.50354654': __('identifierSchemeCodeUnspsc190501.50354654'),
	'identifierSchemeCodeUnspsc190501.50354655': __('identifierSchemeCodeUnspsc190501.50354655'),
	'identifierSchemeCodeUnspsc190501.50354656': __('identifierSchemeCodeUnspsc190501.50354656'),
	'identifierSchemeCodeUnspsc190501.50354657': __('identifierSchemeCodeUnspsc190501.50354657'),
	'identifierSchemeCodeUnspsc190501.50354658': __('identifierSchemeCodeUnspsc190501.50354658'),
	'identifierSchemeCodeUnspsc190501.50354659': __('identifierSchemeCodeUnspsc190501.50354659'),
	'identifierSchemeCodeUnspsc190501.50354660': __('identifierSchemeCodeUnspsc190501.50354660'),
	'identifierSchemeCodeUnspsc190501.50354661': __('identifierSchemeCodeUnspsc190501.50354661'),
	'identifierSchemeCodeUnspsc190501.50354700': __('identifierSchemeCodeUnspsc190501.50354700'),
	'identifierSchemeCodeUnspsc190501.50354701': __('identifierSchemeCodeUnspsc190501.50354701'),
	'identifierSchemeCodeUnspsc190501.50354702': __('identifierSchemeCodeUnspsc190501.50354702'),
	'identifierSchemeCodeUnspsc190501.50354800': __('identifierSchemeCodeUnspsc190501.50354800'),
	'identifierSchemeCodeUnspsc190501.50354801': __('identifierSchemeCodeUnspsc190501.50354801'),
	'identifierSchemeCodeUnspsc190501.50354802': __('identifierSchemeCodeUnspsc190501.50354802'),
	'identifierSchemeCodeUnspsc190501.50354900': __('identifierSchemeCodeUnspsc190501.50354900'),
	'identifierSchemeCodeUnspsc190501.50354901': __('identifierSchemeCodeUnspsc190501.50354901'),
	'identifierSchemeCodeUnspsc190501.50354902': __('identifierSchemeCodeUnspsc190501.50354902'),
	'identifierSchemeCodeUnspsc190501.50354903': __('identifierSchemeCodeUnspsc190501.50354903'),
	'identifierSchemeCodeUnspsc190501.50354904': __('identifierSchemeCodeUnspsc190501.50354904'),
	'identifierSchemeCodeUnspsc190501.50354905': __('identifierSchemeCodeUnspsc190501.50354905'),
	'identifierSchemeCodeUnspsc190501.50354906': __('identifierSchemeCodeUnspsc190501.50354906'),
	'identifierSchemeCodeUnspsc190501.50354907': __('identifierSchemeCodeUnspsc190501.50354907'),
	'identifierSchemeCodeUnspsc190501.50354908': __('identifierSchemeCodeUnspsc190501.50354908'),
	'identifierSchemeCodeUnspsc190501.50354909': __('identifierSchemeCodeUnspsc190501.50354909'),
	'identifierSchemeCodeUnspsc190501.50354910': __('identifierSchemeCodeUnspsc190501.50354910'),
	'identifierSchemeCodeUnspsc190501.50354911': __('identifierSchemeCodeUnspsc190501.50354911'),
	'identifierSchemeCodeUnspsc190501.50354912': __('identifierSchemeCodeUnspsc190501.50354912'),
	'identifierSchemeCodeUnspsc190501.50354913': __('identifierSchemeCodeUnspsc190501.50354913'),
	'identifierSchemeCodeUnspsc190501.50354914': __('identifierSchemeCodeUnspsc190501.50354914'),
	'identifierSchemeCodeUnspsc190501.50354915': __('identifierSchemeCodeUnspsc190501.50354915'),
	'identifierSchemeCodeUnspsc190501.50354916': __('identifierSchemeCodeUnspsc190501.50354916'),
	'identifierSchemeCodeUnspsc190501.50354917': __('identifierSchemeCodeUnspsc190501.50354917'),
	'identifierSchemeCodeUnspsc190501.50354918': __('identifierSchemeCodeUnspsc190501.50354918'),
	'identifierSchemeCodeUnspsc190501.50354919': __('identifierSchemeCodeUnspsc190501.50354919'),
	'identifierSchemeCodeUnspsc190501.50354920': __('identifierSchemeCodeUnspsc190501.50354920'),
	'identifierSchemeCodeUnspsc190501.50354921': __('identifierSchemeCodeUnspsc190501.50354921'),
	'identifierSchemeCodeUnspsc190501.50354922': __('identifierSchemeCodeUnspsc190501.50354922'),
	'identifierSchemeCodeUnspsc190501.50354923': __('identifierSchemeCodeUnspsc190501.50354923'),
	'identifierSchemeCodeUnspsc190501.50354924': __('identifierSchemeCodeUnspsc190501.50354924'),
	'identifierSchemeCodeUnspsc190501.50354925': __('identifierSchemeCodeUnspsc190501.50354925'),
	'identifierSchemeCodeUnspsc190501.50354926': __('identifierSchemeCodeUnspsc190501.50354926'),
	'identifierSchemeCodeUnspsc190501.50354927': __('identifierSchemeCodeUnspsc190501.50354927'),
	'identifierSchemeCodeUnspsc190501.50354928': __('identifierSchemeCodeUnspsc190501.50354928'),
	'identifierSchemeCodeUnspsc190501.50354929': __('identifierSchemeCodeUnspsc190501.50354929'),
	'identifierSchemeCodeUnspsc190501.50354930': __('identifierSchemeCodeUnspsc190501.50354930'),
	'identifierSchemeCodeUnspsc190501.50354931': __('identifierSchemeCodeUnspsc190501.50354931'),
	'identifierSchemeCodeUnspsc190501.50354932': __('identifierSchemeCodeUnspsc190501.50354932'),
	'identifierSchemeCodeUnspsc190501.50354933': __('identifierSchemeCodeUnspsc190501.50354933'),
	'identifierSchemeCodeUnspsc190501.50354934': __('identifierSchemeCodeUnspsc190501.50354934'),
	'identifierSchemeCodeUnspsc190501.50354935': __('identifierSchemeCodeUnspsc190501.50354935'),
	'identifierSchemeCodeUnspsc190501.50354936': __('identifierSchemeCodeUnspsc190501.50354936'),
	'identifierSchemeCodeUnspsc190501.50354937': __('identifierSchemeCodeUnspsc190501.50354937'),
	'identifierSchemeCodeUnspsc190501.50354938': __('identifierSchemeCodeUnspsc190501.50354938'),
	'identifierSchemeCodeUnspsc190501.50354939': __('identifierSchemeCodeUnspsc190501.50354939'),
	'identifierSchemeCodeUnspsc190501.50354940': __('identifierSchemeCodeUnspsc190501.50354940'),
	'identifierSchemeCodeUnspsc190501.50354941': __('identifierSchemeCodeUnspsc190501.50354941'),
	'identifierSchemeCodeUnspsc190501.50354942': __('identifierSchemeCodeUnspsc190501.50354942'),
	'identifierSchemeCodeUnspsc190501.50354943': __('identifierSchemeCodeUnspsc190501.50354943'),
	'identifierSchemeCodeUnspsc190501.50354944': __('identifierSchemeCodeUnspsc190501.50354944'),
	'identifierSchemeCodeUnspsc190501.50354945': __('identifierSchemeCodeUnspsc190501.50354945'),
	'identifierSchemeCodeUnspsc190501.50354946': __('identifierSchemeCodeUnspsc190501.50354946'),
	'identifierSchemeCodeUnspsc190501.50354947': __('identifierSchemeCodeUnspsc190501.50354947'),
	'identifierSchemeCodeUnspsc190501.50354948': __('identifierSchemeCodeUnspsc190501.50354948'),
	'identifierSchemeCodeUnspsc190501.50354949': __('identifierSchemeCodeUnspsc190501.50354949'),
	'identifierSchemeCodeUnspsc190501.50354950': __('identifierSchemeCodeUnspsc190501.50354950'),
	'identifierSchemeCodeUnspsc190501.50354951': __('identifierSchemeCodeUnspsc190501.50354951'),
	'identifierSchemeCodeUnspsc190501.50354952': __('identifierSchemeCodeUnspsc190501.50354952'),
	'identifierSchemeCodeUnspsc190501.50354953': __('identifierSchemeCodeUnspsc190501.50354953'),
	'identifierSchemeCodeUnspsc190501.50354954': __('identifierSchemeCodeUnspsc190501.50354954'),
	'identifierSchemeCodeUnspsc190501.50354955': __('identifierSchemeCodeUnspsc190501.50354955'),
	'identifierSchemeCodeUnspsc190501.50354956': __('identifierSchemeCodeUnspsc190501.50354956'),
	'identifierSchemeCodeUnspsc190501.50354957': __('identifierSchemeCodeUnspsc190501.50354957'),
	'identifierSchemeCodeUnspsc190501.50355000': __('identifierSchemeCodeUnspsc190501.50355000'),
	'identifierSchemeCodeUnspsc190501.50355001': __('identifierSchemeCodeUnspsc190501.50355001'),
	'identifierSchemeCodeUnspsc190501.50355002': __('identifierSchemeCodeUnspsc190501.50355002'),
	'identifierSchemeCodeUnspsc190501.50355003': __('identifierSchemeCodeUnspsc190501.50355003'),
	'identifierSchemeCodeUnspsc190501.50355004': __('identifierSchemeCodeUnspsc190501.50355004'),
	'identifierSchemeCodeUnspsc190501.50355005': __('identifierSchemeCodeUnspsc190501.50355005'),
	'identifierSchemeCodeUnspsc190501.50355006': __('identifierSchemeCodeUnspsc190501.50355006'),
	'identifierSchemeCodeUnspsc190501.50355007': __('identifierSchemeCodeUnspsc190501.50355007'),
	'identifierSchemeCodeUnspsc190501.50355008': __('identifierSchemeCodeUnspsc190501.50355008'),
	'identifierSchemeCodeUnspsc190501.50355009': __('identifierSchemeCodeUnspsc190501.50355009'),
	'identifierSchemeCodeUnspsc190501.50355010': __('identifierSchemeCodeUnspsc190501.50355010'),
	'identifierSchemeCodeUnspsc190501.50355011': __('identifierSchemeCodeUnspsc190501.50355011'),
	'identifierSchemeCodeUnspsc190501.50355012': __('identifierSchemeCodeUnspsc190501.50355012'),
	'identifierSchemeCodeUnspsc190501.50355013': __('identifierSchemeCodeUnspsc190501.50355013'),
	'identifierSchemeCodeUnspsc190501.50355014': __('identifierSchemeCodeUnspsc190501.50355014'),
	'identifierSchemeCodeUnspsc190501.50355015': __('identifierSchemeCodeUnspsc190501.50355015'),
	'identifierSchemeCodeUnspsc190501.50355016': __('identifierSchemeCodeUnspsc190501.50355016'),
	'identifierSchemeCodeUnspsc190501.50355017': __('identifierSchemeCodeUnspsc190501.50355017'),
	'identifierSchemeCodeUnspsc190501.50355018': __('identifierSchemeCodeUnspsc190501.50355018'),
	'identifierSchemeCodeUnspsc190501.50355019': __('identifierSchemeCodeUnspsc190501.50355019'),
	'identifierSchemeCodeUnspsc190501.50355020': __('identifierSchemeCodeUnspsc190501.50355020'),
	'identifierSchemeCodeUnspsc190501.50355021': __('identifierSchemeCodeUnspsc190501.50355021'),
	'identifierSchemeCodeUnspsc190501.50355022': __('identifierSchemeCodeUnspsc190501.50355022'),
	'identifierSchemeCodeUnspsc190501.50355023': __('identifierSchemeCodeUnspsc190501.50355023'),
	'identifierSchemeCodeUnspsc190501.50355024': __('identifierSchemeCodeUnspsc190501.50355024'),
	'identifierSchemeCodeUnspsc190501.50355025': __('identifierSchemeCodeUnspsc190501.50355025'),
	'identifierSchemeCodeUnspsc190501.50355026': __('identifierSchemeCodeUnspsc190501.50355026'),
	'identifierSchemeCodeUnspsc190501.50355027': __('identifierSchemeCodeUnspsc190501.50355027'),
	'identifierSchemeCodeUnspsc190501.50355028': __('identifierSchemeCodeUnspsc190501.50355028'),
	'identifierSchemeCodeUnspsc190501.50355029': __('identifierSchemeCodeUnspsc190501.50355029'),
	'identifierSchemeCodeUnspsc190501.50355030': __('identifierSchemeCodeUnspsc190501.50355030'),
	'identifierSchemeCodeUnspsc190501.50355031': __('identifierSchemeCodeUnspsc190501.50355031'),
	'identifierSchemeCodeUnspsc190501.50355032': __('identifierSchemeCodeUnspsc190501.50355032'),
	'identifierSchemeCodeUnspsc190501.50355033': __('identifierSchemeCodeUnspsc190501.50355033'),
	'identifierSchemeCodeUnspsc190501.50355034': __('identifierSchemeCodeUnspsc190501.50355034'),
	'identifierSchemeCodeUnspsc190501.50355035': __('identifierSchemeCodeUnspsc190501.50355035'),
	'identifierSchemeCodeUnspsc190501.50355036': __('identifierSchemeCodeUnspsc190501.50355036'),
	'identifierSchemeCodeUnspsc190501.50355037': __('identifierSchemeCodeUnspsc190501.50355037'),
	'identifierSchemeCodeUnspsc190501.50355038': __('identifierSchemeCodeUnspsc190501.50355038'),
	'identifierSchemeCodeUnspsc190501.50355039': __('identifierSchemeCodeUnspsc190501.50355039'),
	'identifierSchemeCodeUnspsc190501.50355040': __('identifierSchemeCodeUnspsc190501.50355040'),
	'identifierSchemeCodeUnspsc190501.50355041': __('identifierSchemeCodeUnspsc190501.50355041'),
	'identifierSchemeCodeUnspsc190501.50355042': __('identifierSchemeCodeUnspsc190501.50355042'),
	'identifierSchemeCodeUnspsc190501.50355043': __('identifierSchemeCodeUnspsc190501.50355043'),
	'identifierSchemeCodeUnspsc190501.50355044': __('identifierSchemeCodeUnspsc190501.50355044'),
	'identifierSchemeCodeUnspsc190501.50355100': __('identifierSchemeCodeUnspsc190501.50355100'),
	'identifierSchemeCodeUnspsc190501.50355101': __('identifierSchemeCodeUnspsc190501.50355101'),
	'identifierSchemeCodeUnspsc190501.50355102': __('identifierSchemeCodeUnspsc190501.50355102'),
	'identifierSchemeCodeUnspsc190501.50355103': __('identifierSchemeCodeUnspsc190501.50355103'),
	'identifierSchemeCodeUnspsc190501.50355104': __('identifierSchemeCodeUnspsc190501.50355104'),
	'identifierSchemeCodeUnspsc190501.50355105': __('identifierSchemeCodeUnspsc190501.50355105'),
	'identifierSchemeCodeUnspsc190501.50355106': __('identifierSchemeCodeUnspsc190501.50355106'),
	'identifierSchemeCodeUnspsc190501.50355200': __('identifierSchemeCodeUnspsc190501.50355200'),
	'identifierSchemeCodeUnspsc190501.50355201': __('identifierSchemeCodeUnspsc190501.50355201'),
	'identifierSchemeCodeUnspsc190501.50355202': __('identifierSchemeCodeUnspsc190501.50355202'),
	'identifierSchemeCodeUnspsc190501.50355203': __('identifierSchemeCodeUnspsc190501.50355203'),
	'identifierSchemeCodeUnspsc190501.50355204': __('identifierSchemeCodeUnspsc190501.50355204'),
	'identifierSchemeCodeUnspsc190501.50355205': __('identifierSchemeCodeUnspsc190501.50355205'),
	'identifierSchemeCodeUnspsc190501.50355206': __('identifierSchemeCodeUnspsc190501.50355206'),
	'identifierSchemeCodeUnspsc190501.50355207': __('identifierSchemeCodeUnspsc190501.50355207'),
	'identifierSchemeCodeUnspsc190501.50355208': __('identifierSchemeCodeUnspsc190501.50355208'),
	'identifierSchemeCodeUnspsc190501.50355209': __('identifierSchemeCodeUnspsc190501.50355209'),
	'identifierSchemeCodeUnspsc190501.50355210': __('identifierSchemeCodeUnspsc190501.50355210'),
	'identifierSchemeCodeUnspsc190501.50355300': __('identifierSchemeCodeUnspsc190501.50355300'),
	'identifierSchemeCodeUnspsc190501.50355301': __('identifierSchemeCodeUnspsc190501.50355301'),
	'identifierSchemeCodeUnspsc190501.50355302': __('identifierSchemeCodeUnspsc190501.50355302'),
	'identifierSchemeCodeUnspsc190501.50355303': __('identifierSchemeCodeUnspsc190501.50355303'),
	'identifierSchemeCodeUnspsc190501.50355304': __('identifierSchemeCodeUnspsc190501.50355304'),
	'identifierSchemeCodeUnspsc190501.50355305': __('identifierSchemeCodeUnspsc190501.50355305'),
	'identifierSchemeCodeUnspsc190501.50355306': __('identifierSchemeCodeUnspsc190501.50355306'),
	'identifierSchemeCodeUnspsc190501.50355307': __('identifierSchemeCodeUnspsc190501.50355307'),
	'identifierSchemeCodeUnspsc190501.50355308': __('identifierSchemeCodeUnspsc190501.50355308'),
	'identifierSchemeCodeUnspsc190501.50355309': __('identifierSchemeCodeUnspsc190501.50355309'),
	'identifierSchemeCodeUnspsc190501.50355310': __('identifierSchemeCodeUnspsc190501.50355310'),
	'identifierSchemeCodeUnspsc190501.50355311': __('identifierSchemeCodeUnspsc190501.50355311'),
	'identifierSchemeCodeUnspsc190501.50355312': __('identifierSchemeCodeUnspsc190501.50355312'),
	'identifierSchemeCodeUnspsc190501.50355313': __('identifierSchemeCodeUnspsc190501.50355313'),
	'identifierSchemeCodeUnspsc190501.50355314': __('identifierSchemeCodeUnspsc190501.50355314'),
	'identifierSchemeCodeUnspsc190501.50355315': __('identifierSchemeCodeUnspsc190501.50355315'),
	'identifierSchemeCodeUnspsc190501.50355316': __('identifierSchemeCodeUnspsc190501.50355316'),
	'identifierSchemeCodeUnspsc190501.50355317': __('identifierSchemeCodeUnspsc190501.50355317'),
	'identifierSchemeCodeUnspsc190501.50355318': __('identifierSchemeCodeUnspsc190501.50355318'),
	'identifierSchemeCodeUnspsc190501.50355319': __('identifierSchemeCodeUnspsc190501.50355319'),
	'identifierSchemeCodeUnspsc190501.50355320': __('identifierSchemeCodeUnspsc190501.50355320'),
	'identifierSchemeCodeUnspsc190501.50355321': __('identifierSchemeCodeUnspsc190501.50355321'),
	'identifierSchemeCodeUnspsc190501.50355322': __('identifierSchemeCodeUnspsc190501.50355322'),
	'identifierSchemeCodeUnspsc190501.50355323': __('identifierSchemeCodeUnspsc190501.50355323'),
	'identifierSchemeCodeUnspsc190501.50355324': __('identifierSchemeCodeUnspsc190501.50355324'),
	'identifierSchemeCodeUnspsc190501.50355325': __('identifierSchemeCodeUnspsc190501.50355325'),
	'identifierSchemeCodeUnspsc190501.50355326': __('identifierSchemeCodeUnspsc190501.50355326'),
	'identifierSchemeCodeUnspsc190501.50355327': __('identifierSchemeCodeUnspsc190501.50355327'),
	'identifierSchemeCodeUnspsc190501.50355328': __('identifierSchemeCodeUnspsc190501.50355328'),
	'identifierSchemeCodeUnspsc190501.50355329': __('identifierSchemeCodeUnspsc190501.50355329'),
	'identifierSchemeCodeUnspsc190501.50355330': __('identifierSchemeCodeUnspsc190501.50355330'),
	'identifierSchemeCodeUnspsc190501.50355331': __('identifierSchemeCodeUnspsc190501.50355331'),
	'identifierSchemeCodeUnspsc190501.50355332': __('identifierSchemeCodeUnspsc190501.50355332'),
	'identifierSchemeCodeUnspsc190501.50355333': __('identifierSchemeCodeUnspsc190501.50355333'),
	'identifierSchemeCodeUnspsc190501.50355334': __('identifierSchemeCodeUnspsc190501.50355334'),
	'identifierSchemeCodeUnspsc190501.50355335': __('identifierSchemeCodeUnspsc190501.50355335'),
	'identifierSchemeCodeUnspsc190501.50355336': __('identifierSchemeCodeUnspsc190501.50355336'),
	'identifierSchemeCodeUnspsc190501.50355337': __('identifierSchemeCodeUnspsc190501.50355337'),
	'identifierSchemeCodeUnspsc190501.50355338': __('identifierSchemeCodeUnspsc190501.50355338'),
	'identifierSchemeCodeUnspsc190501.50355339': __('identifierSchemeCodeUnspsc190501.50355339'),
	'identifierSchemeCodeUnspsc190501.50355340': __('identifierSchemeCodeUnspsc190501.50355340'),
	'identifierSchemeCodeUnspsc190501.50355341': __('identifierSchemeCodeUnspsc190501.50355341'),
	'identifierSchemeCodeUnspsc190501.50355342': __('identifierSchemeCodeUnspsc190501.50355342'),
	'identifierSchemeCodeUnspsc190501.50355343': __('identifierSchemeCodeUnspsc190501.50355343'),
	'identifierSchemeCodeUnspsc190501.50355344': __('identifierSchemeCodeUnspsc190501.50355344'),
	'identifierSchemeCodeUnspsc190501.50355345': __('identifierSchemeCodeUnspsc190501.50355345'),
	'identifierSchemeCodeUnspsc190501.50355346': __('identifierSchemeCodeUnspsc190501.50355346'),
	'identifierSchemeCodeUnspsc190501.50355347': __('identifierSchemeCodeUnspsc190501.50355347'),
	'identifierSchemeCodeUnspsc190501.50355348': __('identifierSchemeCodeUnspsc190501.50355348'),
	'identifierSchemeCodeUnspsc190501.50355349': __('identifierSchemeCodeUnspsc190501.50355349'),
	'identifierSchemeCodeUnspsc190501.50355350': __('identifierSchemeCodeUnspsc190501.50355350'),
	'identifierSchemeCodeUnspsc190501.50355351': __('identifierSchemeCodeUnspsc190501.50355351'),
	'identifierSchemeCodeUnspsc190501.50355352': __('identifierSchemeCodeUnspsc190501.50355352'),
	'identifierSchemeCodeUnspsc190501.50355353': __('identifierSchemeCodeUnspsc190501.50355353'),
	'identifierSchemeCodeUnspsc190501.50355354': __('identifierSchemeCodeUnspsc190501.50355354'),
	'identifierSchemeCodeUnspsc190501.50355355': __('identifierSchemeCodeUnspsc190501.50355355'),
	'identifierSchemeCodeUnspsc190501.50355356': __('identifierSchemeCodeUnspsc190501.50355356'),
	'identifierSchemeCodeUnspsc190501.50355357': __('identifierSchemeCodeUnspsc190501.50355357'),
	'identifierSchemeCodeUnspsc190501.50355358': __('identifierSchemeCodeUnspsc190501.50355358'),
	'identifierSchemeCodeUnspsc190501.50355359': __('identifierSchemeCodeUnspsc190501.50355359'),
	'identifierSchemeCodeUnspsc190501.50355360': __('identifierSchemeCodeUnspsc190501.50355360'),
	'identifierSchemeCodeUnspsc190501.50355361': __('identifierSchemeCodeUnspsc190501.50355361'),
	'identifierSchemeCodeUnspsc190501.50355362': __('identifierSchemeCodeUnspsc190501.50355362'),
	'identifierSchemeCodeUnspsc190501.50355363': __('identifierSchemeCodeUnspsc190501.50355363'),
	'identifierSchemeCodeUnspsc190501.50355364': __('identifierSchemeCodeUnspsc190501.50355364'),
	'identifierSchemeCodeUnspsc190501.50355365': __('identifierSchemeCodeUnspsc190501.50355365'),
	'identifierSchemeCodeUnspsc190501.50355366': __('identifierSchemeCodeUnspsc190501.50355366'),
	'identifierSchemeCodeUnspsc190501.50355367': __('identifierSchemeCodeUnspsc190501.50355367'),
	'identifierSchemeCodeUnspsc190501.50355368': __('identifierSchemeCodeUnspsc190501.50355368'),
	'identifierSchemeCodeUnspsc190501.50355369': __('identifierSchemeCodeUnspsc190501.50355369'),
	'identifierSchemeCodeUnspsc190501.50355370': __('identifierSchemeCodeUnspsc190501.50355370'),
	'identifierSchemeCodeUnspsc190501.50355371': __('identifierSchemeCodeUnspsc190501.50355371'),
	'identifierSchemeCodeUnspsc190501.50355400': __('identifierSchemeCodeUnspsc190501.50355400'),
	'identifierSchemeCodeUnspsc190501.50355401': __('identifierSchemeCodeUnspsc190501.50355401'),
	'identifierSchemeCodeUnspsc190501.50355402': __('identifierSchemeCodeUnspsc190501.50355402'),
	'identifierSchemeCodeUnspsc190501.50355403': __('identifierSchemeCodeUnspsc190501.50355403'),
	'identifierSchemeCodeUnspsc190501.50355404': __('identifierSchemeCodeUnspsc190501.50355404'),
	'identifierSchemeCodeUnspsc190501.50355405': __('identifierSchemeCodeUnspsc190501.50355405'),
	'identifierSchemeCodeUnspsc190501.50355406': __('identifierSchemeCodeUnspsc190501.50355406'),
	'identifierSchemeCodeUnspsc190501.50355407': __('identifierSchemeCodeUnspsc190501.50355407'),
	'identifierSchemeCodeUnspsc190501.50355408': __('identifierSchemeCodeUnspsc190501.50355408'),
	'identifierSchemeCodeUnspsc190501.50355409': __('identifierSchemeCodeUnspsc190501.50355409'),
	'identifierSchemeCodeUnspsc190501.50355410': __('identifierSchemeCodeUnspsc190501.50355410'),
	'identifierSchemeCodeUnspsc190501.50355411': __('identifierSchemeCodeUnspsc190501.50355411'),
	'identifierSchemeCodeUnspsc190501.50355412': __('identifierSchemeCodeUnspsc190501.50355412'),
	'identifierSchemeCodeUnspsc190501.50355413': __('identifierSchemeCodeUnspsc190501.50355413'),
	'identifierSchemeCodeUnspsc190501.50355414': __('identifierSchemeCodeUnspsc190501.50355414'),
	'identifierSchemeCodeUnspsc190501.50355415': __('identifierSchemeCodeUnspsc190501.50355415'),
	'identifierSchemeCodeUnspsc190501.50355416': __('identifierSchemeCodeUnspsc190501.50355416'),
	'identifierSchemeCodeUnspsc190501.50355417': __('identifierSchemeCodeUnspsc190501.50355417'),
	'identifierSchemeCodeUnspsc190501.50355418': __('identifierSchemeCodeUnspsc190501.50355418'),
	'identifierSchemeCodeUnspsc190501.50355419': __('identifierSchemeCodeUnspsc190501.50355419'),
	'identifierSchemeCodeUnspsc190501.50355420': __('identifierSchemeCodeUnspsc190501.50355420'),
	'identifierSchemeCodeUnspsc190501.50355421': __('identifierSchemeCodeUnspsc190501.50355421'),
	'identifierSchemeCodeUnspsc190501.50355422': __('identifierSchemeCodeUnspsc190501.50355422'),
	'identifierSchemeCodeUnspsc190501.50355423': __('identifierSchemeCodeUnspsc190501.50355423'),
	'identifierSchemeCodeUnspsc190501.50355424': __('identifierSchemeCodeUnspsc190501.50355424'),
	'identifierSchemeCodeUnspsc190501.50355425': __('identifierSchemeCodeUnspsc190501.50355425'),
	'identifierSchemeCodeUnspsc190501.50355426': __('identifierSchemeCodeUnspsc190501.50355426'),
	'identifierSchemeCodeUnspsc190501.50355427': __('identifierSchemeCodeUnspsc190501.50355427'),
	'identifierSchemeCodeUnspsc190501.50355428': __('identifierSchemeCodeUnspsc190501.50355428'),
	'identifierSchemeCodeUnspsc190501.50355429': __('identifierSchemeCodeUnspsc190501.50355429'),
	'identifierSchemeCodeUnspsc190501.50355430': __('identifierSchemeCodeUnspsc190501.50355430'),
	'identifierSchemeCodeUnspsc190501.50355431': __('identifierSchemeCodeUnspsc190501.50355431'),
	'identifierSchemeCodeUnspsc190501.50355432': __('identifierSchemeCodeUnspsc190501.50355432'),
	'identifierSchemeCodeUnspsc190501.50355433': __('identifierSchemeCodeUnspsc190501.50355433'),
	'identifierSchemeCodeUnspsc190501.50355434': __('identifierSchemeCodeUnspsc190501.50355434'),
	'identifierSchemeCodeUnspsc190501.50355435': __('identifierSchemeCodeUnspsc190501.50355435'),
	'identifierSchemeCodeUnspsc190501.50355436': __('identifierSchemeCodeUnspsc190501.50355436'),
	'identifierSchemeCodeUnspsc190501.50355437': __('identifierSchemeCodeUnspsc190501.50355437'),
	'identifierSchemeCodeUnspsc190501.50355438': __('identifierSchemeCodeUnspsc190501.50355438'),
	'identifierSchemeCodeUnspsc190501.50355439': __('identifierSchemeCodeUnspsc190501.50355439'),
	'identifierSchemeCodeUnspsc190501.50355440': __('identifierSchemeCodeUnspsc190501.50355440'),
	'identifierSchemeCodeUnspsc190501.50355441': __('identifierSchemeCodeUnspsc190501.50355441'),
	'identifierSchemeCodeUnspsc190501.50355442': __('identifierSchemeCodeUnspsc190501.50355442'),
	'identifierSchemeCodeUnspsc190501.50355443': __('identifierSchemeCodeUnspsc190501.50355443'),
	'identifierSchemeCodeUnspsc190501.50355444': __('identifierSchemeCodeUnspsc190501.50355444'),
	'identifierSchemeCodeUnspsc190501.50355445': __('identifierSchemeCodeUnspsc190501.50355445'),
	'identifierSchemeCodeUnspsc190501.50355446': __('identifierSchemeCodeUnspsc190501.50355446'),
	'identifierSchemeCodeUnspsc190501.50355447': __('identifierSchemeCodeUnspsc190501.50355447'),
	'identifierSchemeCodeUnspsc190501.50355448': __('identifierSchemeCodeUnspsc190501.50355448'),
	'identifierSchemeCodeUnspsc190501.50355449': __('identifierSchemeCodeUnspsc190501.50355449'),
	'identifierSchemeCodeUnspsc190501.50355450': __('identifierSchemeCodeUnspsc190501.50355450'),
	'identifierSchemeCodeUnspsc190501.50355451': __('identifierSchemeCodeUnspsc190501.50355451'),
	'identifierSchemeCodeUnspsc190501.50355452': __('identifierSchemeCodeUnspsc190501.50355452'),
	'identifierSchemeCodeUnspsc190501.50355453': __('identifierSchemeCodeUnspsc190501.50355453'),
	'identifierSchemeCodeUnspsc190501.50355454': __('identifierSchemeCodeUnspsc190501.50355454'),
	'identifierSchemeCodeUnspsc190501.50355455': __('identifierSchemeCodeUnspsc190501.50355455'),
	'identifierSchemeCodeUnspsc190501.50355456': __('identifierSchemeCodeUnspsc190501.50355456'),
	'identifierSchemeCodeUnspsc190501.50355457': __('identifierSchemeCodeUnspsc190501.50355457'),
	'identifierSchemeCodeUnspsc190501.50355458': __('identifierSchemeCodeUnspsc190501.50355458'),
	'identifierSchemeCodeUnspsc190501.50355459': __('identifierSchemeCodeUnspsc190501.50355459'),
	'identifierSchemeCodeUnspsc190501.50355460': __('identifierSchemeCodeUnspsc190501.50355460'),
	'identifierSchemeCodeUnspsc190501.50355500': __('identifierSchemeCodeUnspsc190501.50355500'),
	'identifierSchemeCodeUnspsc190501.50355501': __('identifierSchemeCodeUnspsc190501.50355501'),
	'identifierSchemeCodeUnspsc190501.50355502': __('identifierSchemeCodeUnspsc190501.50355502'),
	'identifierSchemeCodeUnspsc190501.50355503': __('identifierSchemeCodeUnspsc190501.50355503'),
	'identifierSchemeCodeUnspsc190501.50355504': __('identifierSchemeCodeUnspsc190501.50355504'),
	'identifierSchemeCodeUnspsc190501.50355505': __('identifierSchemeCodeUnspsc190501.50355505'),
	'identifierSchemeCodeUnspsc190501.50355506': __('identifierSchemeCodeUnspsc190501.50355506'),
	'identifierSchemeCodeUnspsc190501.50355507': __('identifierSchemeCodeUnspsc190501.50355507'),
	'identifierSchemeCodeUnspsc190501.50355508': __('identifierSchemeCodeUnspsc190501.50355508'),
	'identifierSchemeCodeUnspsc190501.50355509': __('identifierSchemeCodeUnspsc190501.50355509'),
	'identifierSchemeCodeUnspsc190501.50355510': __('identifierSchemeCodeUnspsc190501.50355510'),
	'identifierSchemeCodeUnspsc190501.50355511': __('identifierSchemeCodeUnspsc190501.50355511'),
	'identifierSchemeCodeUnspsc190501.50355512': __('identifierSchemeCodeUnspsc190501.50355512'),
	'identifierSchemeCodeUnspsc190501.50355600': __('identifierSchemeCodeUnspsc190501.50355600'),
	'identifierSchemeCodeUnspsc190501.50355601': __('identifierSchemeCodeUnspsc190501.50355601'),
	'identifierSchemeCodeUnspsc190501.50355602': __('identifierSchemeCodeUnspsc190501.50355602'),
	'identifierSchemeCodeUnspsc190501.50355603': __('identifierSchemeCodeUnspsc190501.50355603'),
	'identifierSchemeCodeUnspsc190501.50355604': __('identifierSchemeCodeUnspsc190501.50355604'),
	'identifierSchemeCodeUnspsc190501.50355605': __('identifierSchemeCodeUnspsc190501.50355605'),
	'identifierSchemeCodeUnspsc190501.50355606': __('identifierSchemeCodeUnspsc190501.50355606'),
	'identifierSchemeCodeUnspsc190501.50355607': __('identifierSchemeCodeUnspsc190501.50355607'),
	'identifierSchemeCodeUnspsc190501.50355608': __('identifierSchemeCodeUnspsc190501.50355608'),
	'identifierSchemeCodeUnspsc190501.50355609': __('identifierSchemeCodeUnspsc190501.50355609'),
	'identifierSchemeCodeUnspsc190501.50355610': __('identifierSchemeCodeUnspsc190501.50355610'),
	'identifierSchemeCodeUnspsc190501.50355700': __('identifierSchemeCodeUnspsc190501.50355700'),
	'identifierSchemeCodeUnspsc190501.50355701': __('identifierSchemeCodeUnspsc190501.50355701'),
	'identifierSchemeCodeUnspsc190501.50355702': __('identifierSchemeCodeUnspsc190501.50355702'),
	'identifierSchemeCodeUnspsc190501.50355703': __('identifierSchemeCodeUnspsc190501.50355703'),
	'identifierSchemeCodeUnspsc190501.50355704': __('identifierSchemeCodeUnspsc190501.50355704'),
	'identifierSchemeCodeUnspsc190501.50355705': __('identifierSchemeCodeUnspsc190501.50355705'),
	'identifierSchemeCodeUnspsc190501.50355706': __('identifierSchemeCodeUnspsc190501.50355706'),
	'identifierSchemeCodeUnspsc190501.50355707': __('identifierSchemeCodeUnspsc190501.50355707'),
	'identifierSchemeCodeUnspsc190501.50355708': __('identifierSchemeCodeUnspsc190501.50355708'),
	'identifierSchemeCodeUnspsc190501.50355709': __('identifierSchemeCodeUnspsc190501.50355709'),
	'identifierSchemeCodeUnspsc190501.50355710': __('identifierSchemeCodeUnspsc190501.50355710'),
	'identifierSchemeCodeUnspsc190501.50355711': __('identifierSchemeCodeUnspsc190501.50355711'),
	'identifierSchemeCodeUnspsc190501.50355712': __('identifierSchemeCodeUnspsc190501.50355712'),
	'identifierSchemeCodeUnspsc190501.50355713': __('identifierSchemeCodeUnspsc190501.50355713'),
	'identifierSchemeCodeUnspsc190501.50355714': __('identifierSchemeCodeUnspsc190501.50355714'),
	'identifierSchemeCodeUnspsc190501.50355715': __('identifierSchemeCodeUnspsc190501.50355715'),
	'identifierSchemeCodeUnspsc190501.50355716': __('identifierSchemeCodeUnspsc190501.50355716'),
	'identifierSchemeCodeUnspsc190501.50355717': __('identifierSchemeCodeUnspsc190501.50355717'),
	'identifierSchemeCodeUnspsc190501.50355718': __('identifierSchemeCodeUnspsc190501.50355718'),
	'identifierSchemeCodeUnspsc190501.50355719': __('identifierSchemeCodeUnspsc190501.50355719'),
	'identifierSchemeCodeUnspsc190501.50355720': __('identifierSchemeCodeUnspsc190501.50355720'),
	'identifierSchemeCodeUnspsc190501.50355721': __('identifierSchemeCodeUnspsc190501.50355721'),
	'identifierSchemeCodeUnspsc190501.50355722': __('identifierSchemeCodeUnspsc190501.50355722'),
	'identifierSchemeCodeUnspsc190501.50355723': __('identifierSchemeCodeUnspsc190501.50355723'),
	'identifierSchemeCodeUnspsc190501.50355800': __('identifierSchemeCodeUnspsc190501.50355800'),
	'identifierSchemeCodeUnspsc190501.50355801': __('identifierSchemeCodeUnspsc190501.50355801'),
	'identifierSchemeCodeUnspsc190501.50355802': __('identifierSchemeCodeUnspsc190501.50355802'),
	'identifierSchemeCodeUnspsc190501.50355803': __('identifierSchemeCodeUnspsc190501.50355803'),
	'identifierSchemeCodeUnspsc190501.50355804': __('identifierSchemeCodeUnspsc190501.50355804'),
	'identifierSchemeCodeUnspsc190501.50355805': __('identifierSchemeCodeUnspsc190501.50355805'),
	'identifierSchemeCodeUnspsc190501.50355806': __('identifierSchemeCodeUnspsc190501.50355806'),
	'identifierSchemeCodeUnspsc190501.50355807': __('identifierSchemeCodeUnspsc190501.50355807'),
	'identifierSchemeCodeUnspsc190501.50355808': __('identifierSchemeCodeUnspsc190501.50355808'),
	'identifierSchemeCodeUnspsc190501.50355809': __('identifierSchemeCodeUnspsc190501.50355809'),
	'identifierSchemeCodeUnspsc190501.50355810': __('identifierSchemeCodeUnspsc190501.50355810'),
	'identifierSchemeCodeUnspsc190501.50355811': __('identifierSchemeCodeUnspsc190501.50355811'),
	'identifierSchemeCodeUnspsc190501.50355812': __('identifierSchemeCodeUnspsc190501.50355812'),
	'identifierSchemeCodeUnspsc190501.50355813': __('identifierSchemeCodeUnspsc190501.50355813'),
	'identifierSchemeCodeUnspsc190501.50355814': __('identifierSchemeCodeUnspsc190501.50355814'),
	'identifierSchemeCodeUnspsc190501.50355815': __('identifierSchemeCodeUnspsc190501.50355815'),
	'identifierSchemeCodeUnspsc190501.50355816': __('identifierSchemeCodeUnspsc190501.50355816'),
	'identifierSchemeCodeUnspsc190501.50355817': __('identifierSchemeCodeUnspsc190501.50355817'),
	'identifierSchemeCodeUnspsc190501.50355818': __('identifierSchemeCodeUnspsc190501.50355818'),
	'identifierSchemeCodeUnspsc190501.50355819': __('identifierSchemeCodeUnspsc190501.50355819'),
	'identifierSchemeCodeUnspsc190501.50355820': __('identifierSchemeCodeUnspsc190501.50355820'),
	'identifierSchemeCodeUnspsc190501.50355821': __('identifierSchemeCodeUnspsc190501.50355821'),
	'identifierSchemeCodeUnspsc190501.50355822': __('identifierSchemeCodeUnspsc190501.50355822'),
	'identifierSchemeCodeUnspsc190501.50355823': __('identifierSchemeCodeUnspsc190501.50355823'),
	'identifierSchemeCodeUnspsc190501.50355824': __('identifierSchemeCodeUnspsc190501.50355824'),
	'identifierSchemeCodeUnspsc190501.50355825': __('identifierSchemeCodeUnspsc190501.50355825'),
	'identifierSchemeCodeUnspsc190501.50355826': __('identifierSchemeCodeUnspsc190501.50355826'),
	'identifierSchemeCodeUnspsc190501.50355827': __('identifierSchemeCodeUnspsc190501.50355827'),
	'identifierSchemeCodeUnspsc190501.50355828': __('identifierSchemeCodeUnspsc190501.50355828'),
	'identifierSchemeCodeUnspsc190501.50355829': __('identifierSchemeCodeUnspsc190501.50355829'),
	'identifierSchemeCodeUnspsc190501.50355830': __('identifierSchemeCodeUnspsc190501.50355830'),
	'identifierSchemeCodeUnspsc190501.50355831': __('identifierSchemeCodeUnspsc190501.50355831'),
	'identifierSchemeCodeUnspsc190501.50355832': __('identifierSchemeCodeUnspsc190501.50355832'),
	'identifierSchemeCodeUnspsc190501.50355833': __('identifierSchemeCodeUnspsc190501.50355833'),
	'identifierSchemeCodeUnspsc190501.50355834': __('identifierSchemeCodeUnspsc190501.50355834'),
	'identifierSchemeCodeUnspsc190501.50355835': __('identifierSchemeCodeUnspsc190501.50355835'),
	'identifierSchemeCodeUnspsc190501.50355836': __('identifierSchemeCodeUnspsc190501.50355836'),
	'identifierSchemeCodeUnspsc190501.50355837': __('identifierSchemeCodeUnspsc190501.50355837'),
	'identifierSchemeCodeUnspsc190501.50355838': __('identifierSchemeCodeUnspsc190501.50355838'),
	'identifierSchemeCodeUnspsc190501.50355839': __('identifierSchemeCodeUnspsc190501.50355839'),
	'identifierSchemeCodeUnspsc190501.50355840': __('identifierSchemeCodeUnspsc190501.50355840'),
	'identifierSchemeCodeUnspsc190501.50355841': __('identifierSchemeCodeUnspsc190501.50355841'),
	'identifierSchemeCodeUnspsc190501.50355842': __('identifierSchemeCodeUnspsc190501.50355842'),
	'identifierSchemeCodeUnspsc190501.50355843': __('identifierSchemeCodeUnspsc190501.50355843'),
	'identifierSchemeCodeUnspsc190501.50355844': __('identifierSchemeCodeUnspsc190501.50355844'),
	'identifierSchemeCodeUnspsc190501.50355845': __('identifierSchemeCodeUnspsc190501.50355845'),
	'identifierSchemeCodeUnspsc190501.50355846': __('identifierSchemeCodeUnspsc190501.50355846'),
	'identifierSchemeCodeUnspsc190501.50355847': __('identifierSchemeCodeUnspsc190501.50355847'),
	'identifierSchemeCodeUnspsc190501.50355848': __('identifierSchemeCodeUnspsc190501.50355848'),
	'identifierSchemeCodeUnspsc190501.50355849': __('identifierSchemeCodeUnspsc190501.50355849'),
	'identifierSchemeCodeUnspsc190501.50355850': __('identifierSchemeCodeUnspsc190501.50355850'),
	'identifierSchemeCodeUnspsc190501.50355851': __('identifierSchemeCodeUnspsc190501.50355851'),
	'identifierSchemeCodeUnspsc190501.50355852': __('identifierSchemeCodeUnspsc190501.50355852'),
	'identifierSchemeCodeUnspsc190501.50355853': __('identifierSchemeCodeUnspsc190501.50355853'),
	'identifierSchemeCodeUnspsc190501.50355854': __('identifierSchemeCodeUnspsc190501.50355854'),
	'identifierSchemeCodeUnspsc190501.50355855': __('identifierSchemeCodeUnspsc190501.50355855'),
	'identifierSchemeCodeUnspsc190501.50355856': __('identifierSchemeCodeUnspsc190501.50355856'),
	'identifierSchemeCodeUnspsc190501.50355857': __('identifierSchemeCodeUnspsc190501.50355857'),
	'identifierSchemeCodeUnspsc190501.50355858': __('identifierSchemeCodeUnspsc190501.50355858'),
	'identifierSchemeCodeUnspsc190501.50355859': __('identifierSchemeCodeUnspsc190501.50355859'),
	'identifierSchemeCodeUnspsc190501.50355860': __('identifierSchemeCodeUnspsc190501.50355860'),
	'identifierSchemeCodeUnspsc190501.50355861': __('identifierSchemeCodeUnspsc190501.50355861'),
	'identifierSchemeCodeUnspsc190501.50355862': __('identifierSchemeCodeUnspsc190501.50355862'),
	'identifierSchemeCodeUnspsc190501.50355863': __('identifierSchemeCodeUnspsc190501.50355863'),
	'identifierSchemeCodeUnspsc190501.50355864': __('identifierSchemeCodeUnspsc190501.50355864'),
	'identifierSchemeCodeUnspsc190501.50355865': __('identifierSchemeCodeUnspsc190501.50355865'),
	'identifierSchemeCodeUnspsc190501.50355866': __('identifierSchemeCodeUnspsc190501.50355866'),
	'identifierSchemeCodeUnspsc190501.50355867': __('identifierSchemeCodeUnspsc190501.50355867'),
	'identifierSchemeCodeUnspsc190501.50355868': __('identifierSchemeCodeUnspsc190501.50355868'),
	'identifierSchemeCodeUnspsc190501.50355869': __('identifierSchemeCodeUnspsc190501.50355869'),
	'identifierSchemeCodeUnspsc190501.50355870': __('identifierSchemeCodeUnspsc190501.50355870'),
	'identifierSchemeCodeUnspsc190501.50355871': __('identifierSchemeCodeUnspsc190501.50355871'),
	'identifierSchemeCodeUnspsc190501.50355872': __('identifierSchemeCodeUnspsc190501.50355872'),
	'identifierSchemeCodeUnspsc190501.50355873': __('identifierSchemeCodeUnspsc190501.50355873'),
	'identifierSchemeCodeUnspsc190501.50355874': __('identifierSchemeCodeUnspsc190501.50355874'),
	'identifierSchemeCodeUnspsc190501.50355875': __('identifierSchemeCodeUnspsc190501.50355875'),
	'identifierSchemeCodeUnspsc190501.50355876': __('identifierSchemeCodeUnspsc190501.50355876'),
	'identifierSchemeCodeUnspsc190501.50355877': __('identifierSchemeCodeUnspsc190501.50355877'),
	'identifierSchemeCodeUnspsc190501.50355900': __('identifierSchemeCodeUnspsc190501.50355900'),
	'identifierSchemeCodeUnspsc190501.50355901': __('identifierSchemeCodeUnspsc190501.50355901'),
	'identifierSchemeCodeUnspsc190501.50355902': __('identifierSchemeCodeUnspsc190501.50355902'),
	'identifierSchemeCodeUnspsc190501.50355903': __('identifierSchemeCodeUnspsc190501.50355903'),
	'identifierSchemeCodeUnspsc190501.50355904': __('identifierSchemeCodeUnspsc190501.50355904'),
	'identifierSchemeCodeUnspsc190501.50355905': __('identifierSchemeCodeUnspsc190501.50355905'),
	'identifierSchemeCodeUnspsc190501.50355906': __('identifierSchemeCodeUnspsc190501.50355906'),
	'identifierSchemeCodeUnspsc190501.50355907': __('identifierSchemeCodeUnspsc190501.50355907'),
	'identifierSchemeCodeUnspsc190501.50355908': __('identifierSchemeCodeUnspsc190501.50355908'),
	'identifierSchemeCodeUnspsc190501.50356000': __('identifierSchemeCodeUnspsc190501.50356000'),
	'identifierSchemeCodeUnspsc190501.50356001': __('identifierSchemeCodeUnspsc190501.50356001'),
	'identifierSchemeCodeUnspsc190501.50356002': __('identifierSchemeCodeUnspsc190501.50356002'),
	'identifierSchemeCodeUnspsc190501.50356003': __('identifierSchemeCodeUnspsc190501.50356003'),
	'identifierSchemeCodeUnspsc190501.50356004': __('identifierSchemeCodeUnspsc190501.50356004'),
	'identifierSchemeCodeUnspsc190501.50356005': __('identifierSchemeCodeUnspsc190501.50356005'),
	'identifierSchemeCodeUnspsc190501.50356006': __('identifierSchemeCodeUnspsc190501.50356006'),
	'identifierSchemeCodeUnspsc190501.50356007': __('identifierSchemeCodeUnspsc190501.50356007'),
	'identifierSchemeCodeUnspsc190501.50356008': __('identifierSchemeCodeUnspsc190501.50356008'),
	'identifierSchemeCodeUnspsc190501.50356100': __('identifierSchemeCodeUnspsc190501.50356100'),
	'identifierSchemeCodeUnspsc190501.50356101': __('identifierSchemeCodeUnspsc190501.50356101'),
	'identifierSchemeCodeUnspsc190501.50356102': __('identifierSchemeCodeUnspsc190501.50356102'),
	'identifierSchemeCodeUnspsc190501.50356103': __('identifierSchemeCodeUnspsc190501.50356103'),
	'identifierSchemeCodeUnspsc190501.50356200': __('identifierSchemeCodeUnspsc190501.50356200'),
	'identifierSchemeCodeUnspsc190501.50356201': __('identifierSchemeCodeUnspsc190501.50356201'),
	'identifierSchemeCodeUnspsc190501.50356202': __('identifierSchemeCodeUnspsc190501.50356202'),
	'identifierSchemeCodeUnspsc190501.50356203': __('identifierSchemeCodeUnspsc190501.50356203'),
	'identifierSchemeCodeUnspsc190501.50356204': __('identifierSchemeCodeUnspsc190501.50356204'),
	'identifierSchemeCodeUnspsc190501.50356205': __('identifierSchemeCodeUnspsc190501.50356205'),
	'identifierSchemeCodeUnspsc190501.50356206': __('identifierSchemeCodeUnspsc190501.50356206'),
	'identifierSchemeCodeUnspsc190501.50356207': __('identifierSchemeCodeUnspsc190501.50356207'),
	'identifierSchemeCodeUnspsc190501.50356208': __('identifierSchemeCodeUnspsc190501.50356208'),
	'identifierSchemeCodeUnspsc190501.50356209': __('identifierSchemeCodeUnspsc190501.50356209'),
	'identifierSchemeCodeUnspsc190501.50356210': __('identifierSchemeCodeUnspsc190501.50356210'),
	'identifierSchemeCodeUnspsc190501.50356211': __('identifierSchemeCodeUnspsc190501.50356211'),
	'identifierSchemeCodeUnspsc190501.50356212': __('identifierSchemeCodeUnspsc190501.50356212'),
	'identifierSchemeCodeUnspsc190501.50356213': __('identifierSchemeCodeUnspsc190501.50356213'),
	'identifierSchemeCodeUnspsc190501.50356214': __('identifierSchemeCodeUnspsc190501.50356214'),
	'identifierSchemeCodeUnspsc190501.50356215': __('identifierSchemeCodeUnspsc190501.50356215'),
	'identifierSchemeCodeUnspsc190501.50356216': __('identifierSchemeCodeUnspsc190501.50356216'),
	'identifierSchemeCodeUnspsc190501.50356217': __('identifierSchemeCodeUnspsc190501.50356217'),
	'identifierSchemeCodeUnspsc190501.50356218': __('identifierSchemeCodeUnspsc190501.50356218'),
	'identifierSchemeCodeUnspsc190501.50356219': __('identifierSchemeCodeUnspsc190501.50356219'),
	'identifierSchemeCodeUnspsc190501.50356220': __('identifierSchemeCodeUnspsc190501.50356220'),
	'identifierSchemeCodeUnspsc190501.50356221': __('identifierSchemeCodeUnspsc190501.50356221'),
	'identifierSchemeCodeUnspsc190501.50356222': __('identifierSchemeCodeUnspsc190501.50356222'),
	'identifierSchemeCodeUnspsc190501.50356223': __('identifierSchemeCodeUnspsc190501.50356223'),
	'identifierSchemeCodeUnspsc190501.50356224': __('identifierSchemeCodeUnspsc190501.50356224'),
	'identifierSchemeCodeUnspsc190501.50356225': __('identifierSchemeCodeUnspsc190501.50356225'),
	'identifierSchemeCodeUnspsc190501.50356226': __('identifierSchemeCodeUnspsc190501.50356226'),
	'identifierSchemeCodeUnspsc190501.50356227': __('identifierSchemeCodeUnspsc190501.50356227'),
	'identifierSchemeCodeUnspsc190501.50356228': __('identifierSchemeCodeUnspsc190501.50356228'),
	'identifierSchemeCodeUnspsc190501.50356229': __('identifierSchemeCodeUnspsc190501.50356229'),
	'identifierSchemeCodeUnspsc190501.50356300': __('identifierSchemeCodeUnspsc190501.50356300'),
	'identifierSchemeCodeUnspsc190501.50356301': __('identifierSchemeCodeUnspsc190501.50356301'),
	'identifierSchemeCodeUnspsc190501.50356302': __('identifierSchemeCodeUnspsc190501.50356302'),
	'identifierSchemeCodeUnspsc190501.50356303': __('identifierSchemeCodeUnspsc190501.50356303'),
	'identifierSchemeCodeUnspsc190501.50356304': __('identifierSchemeCodeUnspsc190501.50356304'),
	'identifierSchemeCodeUnspsc190501.50356305': __('identifierSchemeCodeUnspsc190501.50356305'),
	'identifierSchemeCodeUnspsc190501.50356306': __('identifierSchemeCodeUnspsc190501.50356306'),
	'identifierSchemeCodeUnspsc190501.50356307': __('identifierSchemeCodeUnspsc190501.50356307'),
	'identifierSchemeCodeUnspsc190501.50356308': __('identifierSchemeCodeUnspsc190501.50356308'),
	'identifierSchemeCodeUnspsc190501.50356309': __('identifierSchemeCodeUnspsc190501.50356309'),
	'identifierSchemeCodeUnspsc190501.50356310': __('identifierSchemeCodeUnspsc190501.50356310'),
	'identifierSchemeCodeUnspsc190501.50356400': __('identifierSchemeCodeUnspsc190501.50356400'),
	'identifierSchemeCodeUnspsc190501.50356401': __('identifierSchemeCodeUnspsc190501.50356401'),
	'identifierSchemeCodeUnspsc190501.50356402': __('identifierSchemeCodeUnspsc190501.50356402'),
	'identifierSchemeCodeUnspsc190501.50356403': __('identifierSchemeCodeUnspsc190501.50356403'),
	'identifierSchemeCodeUnspsc190501.50356404': __('identifierSchemeCodeUnspsc190501.50356404'),
	'identifierSchemeCodeUnspsc190501.50356500': __('identifierSchemeCodeUnspsc190501.50356500'),
	'identifierSchemeCodeUnspsc190501.50356501': __('identifierSchemeCodeUnspsc190501.50356501'),
	'identifierSchemeCodeUnspsc190501.50356502': __('identifierSchemeCodeUnspsc190501.50356502'),
	'identifierSchemeCodeUnspsc190501.50356600': __('identifierSchemeCodeUnspsc190501.50356600'),
	'identifierSchemeCodeUnspsc190501.50356601': __('identifierSchemeCodeUnspsc190501.50356601'),
	'identifierSchemeCodeUnspsc190501.50356602': __('identifierSchemeCodeUnspsc190501.50356602'),
	'identifierSchemeCodeUnspsc190501.50356603': __('identifierSchemeCodeUnspsc190501.50356603'),
	'identifierSchemeCodeUnspsc190501.50356604': __('identifierSchemeCodeUnspsc190501.50356604'),
	'identifierSchemeCodeUnspsc190501.50356700': __('identifierSchemeCodeUnspsc190501.50356700'),
	'identifierSchemeCodeUnspsc190501.50356701': __('identifierSchemeCodeUnspsc190501.50356701'),
	'identifierSchemeCodeUnspsc190501.50356702': __('identifierSchemeCodeUnspsc190501.50356702'),
	'identifierSchemeCodeUnspsc190501.50356703': __('identifierSchemeCodeUnspsc190501.50356703'),
	'identifierSchemeCodeUnspsc190501.50356800': __('identifierSchemeCodeUnspsc190501.50356800'),
	'identifierSchemeCodeUnspsc190501.50356801': __('identifierSchemeCodeUnspsc190501.50356801'),
	'identifierSchemeCodeUnspsc190501.50356802': __('identifierSchemeCodeUnspsc190501.50356802'),
	'identifierSchemeCodeUnspsc190501.50356803': __('identifierSchemeCodeUnspsc190501.50356803'),
	'identifierSchemeCodeUnspsc190501.50356900': __('identifierSchemeCodeUnspsc190501.50356900'),
	'identifierSchemeCodeUnspsc190501.50356901': __('identifierSchemeCodeUnspsc190501.50356901'),
	'identifierSchemeCodeUnspsc190501.50356902': __('identifierSchemeCodeUnspsc190501.50356902'),
	'identifierSchemeCodeUnspsc190501.50356903': __('identifierSchemeCodeUnspsc190501.50356903'),
	'identifierSchemeCodeUnspsc190501.50356904': __('identifierSchemeCodeUnspsc190501.50356904'),
	'identifierSchemeCodeUnspsc190501.50356905': __('identifierSchemeCodeUnspsc190501.50356905'),
	'identifierSchemeCodeUnspsc190501.50356906': __('identifierSchemeCodeUnspsc190501.50356906'),
	'identifierSchemeCodeUnspsc190501.50357000': __('identifierSchemeCodeUnspsc190501.50357000'),
	'identifierSchemeCodeUnspsc190501.50357001': __('identifierSchemeCodeUnspsc190501.50357001'),
	'identifierSchemeCodeUnspsc190501.50357002': __('identifierSchemeCodeUnspsc190501.50357002'),
	'identifierSchemeCodeUnspsc190501.50357003': __('identifierSchemeCodeUnspsc190501.50357003'),
	'identifierSchemeCodeUnspsc190501.50357004': __('identifierSchemeCodeUnspsc190501.50357004'),
	'identifierSchemeCodeUnspsc190501.50357005': __('identifierSchemeCodeUnspsc190501.50357005'),
	'identifierSchemeCodeUnspsc190501.50357006': __('identifierSchemeCodeUnspsc190501.50357006'),
	'identifierSchemeCodeUnspsc190501.50357007': __('identifierSchemeCodeUnspsc190501.50357007'),
	'identifierSchemeCodeUnspsc190501.50357008': __('identifierSchemeCodeUnspsc190501.50357008'),
	'identifierSchemeCodeUnspsc190501.50357009': __('identifierSchemeCodeUnspsc190501.50357009'),
	'identifierSchemeCodeUnspsc190501.50357010': __('identifierSchemeCodeUnspsc190501.50357010'),
	'identifierSchemeCodeUnspsc190501.50357011': __('identifierSchemeCodeUnspsc190501.50357011'),
	'identifierSchemeCodeUnspsc190501.50357012': __('identifierSchemeCodeUnspsc190501.50357012'),
	'identifierSchemeCodeUnspsc190501.50357013': __('identifierSchemeCodeUnspsc190501.50357013'),
	'identifierSchemeCodeUnspsc190501.50357014': __('identifierSchemeCodeUnspsc190501.50357014'),
	'identifierSchemeCodeUnspsc190501.50357015': __('identifierSchemeCodeUnspsc190501.50357015'),
	'identifierSchemeCodeUnspsc190501.50357016': __('identifierSchemeCodeUnspsc190501.50357016'),
	'identifierSchemeCodeUnspsc190501.50357017': __('identifierSchemeCodeUnspsc190501.50357017'),
	'identifierSchemeCodeUnspsc190501.50357018': __('identifierSchemeCodeUnspsc190501.50357018'),
	'identifierSchemeCodeUnspsc190501.50357019': __('identifierSchemeCodeUnspsc190501.50357019'),
	'identifierSchemeCodeUnspsc190501.50357020': __('identifierSchemeCodeUnspsc190501.50357020'),
	'identifierSchemeCodeUnspsc190501.50357021': __('identifierSchemeCodeUnspsc190501.50357021'),
	'identifierSchemeCodeUnspsc190501.50357022': __('identifierSchemeCodeUnspsc190501.50357022'),
	'identifierSchemeCodeUnspsc190501.50357023': __('identifierSchemeCodeUnspsc190501.50357023'),
	'identifierSchemeCodeUnspsc190501.50357024': __('identifierSchemeCodeUnspsc190501.50357024'),
	'identifierSchemeCodeUnspsc190501.50357025': __('identifierSchemeCodeUnspsc190501.50357025'),
	'identifierSchemeCodeUnspsc190501.50357026': __('identifierSchemeCodeUnspsc190501.50357026'),
	'identifierSchemeCodeUnspsc190501.50357027': __('identifierSchemeCodeUnspsc190501.50357027'),
	'identifierSchemeCodeUnspsc190501.50357028': __('identifierSchemeCodeUnspsc190501.50357028'),
	'identifierSchemeCodeUnspsc190501.50357029': __('identifierSchemeCodeUnspsc190501.50357029'),
	'identifierSchemeCodeUnspsc190501.50357030': __('identifierSchemeCodeUnspsc190501.50357030'),
	'identifierSchemeCodeUnspsc190501.50357031': __('identifierSchemeCodeUnspsc190501.50357031'),
	'identifierSchemeCodeUnspsc190501.50357032': __('identifierSchemeCodeUnspsc190501.50357032'),
	'identifierSchemeCodeUnspsc190501.50357033': __('identifierSchemeCodeUnspsc190501.50357033'),
	'identifierSchemeCodeUnspsc190501.50357034': __('identifierSchemeCodeUnspsc190501.50357034'),
	'identifierSchemeCodeUnspsc190501.50357035': __('identifierSchemeCodeUnspsc190501.50357035'),
	'identifierSchemeCodeUnspsc190501.50357036': __('identifierSchemeCodeUnspsc190501.50357036'),
	'identifierSchemeCodeUnspsc190501.50357037': __('identifierSchemeCodeUnspsc190501.50357037'),
	'identifierSchemeCodeUnspsc190501.50357100': __('identifierSchemeCodeUnspsc190501.50357100'),
	'identifierSchemeCodeUnspsc190501.50357101': __('identifierSchemeCodeUnspsc190501.50357101'),
	'identifierSchemeCodeUnspsc190501.50357102': __('identifierSchemeCodeUnspsc190501.50357102'),
	'identifierSchemeCodeUnspsc190501.50357103': __('identifierSchemeCodeUnspsc190501.50357103'),
	'identifierSchemeCodeUnspsc190501.50357104': __('identifierSchemeCodeUnspsc190501.50357104'),
	'identifierSchemeCodeUnspsc190501.50357200': __('identifierSchemeCodeUnspsc190501.50357200'),
	'identifierSchemeCodeUnspsc190501.50357201': __('identifierSchemeCodeUnspsc190501.50357201'),
	'identifierSchemeCodeUnspsc190501.50357202': __('identifierSchemeCodeUnspsc190501.50357202'),
	'identifierSchemeCodeUnspsc190501.50357203': __('identifierSchemeCodeUnspsc190501.50357203'),
	'identifierSchemeCodeUnspsc190501.50357204': __('identifierSchemeCodeUnspsc190501.50357204'),
	'identifierSchemeCodeUnspsc190501.50357205': __('identifierSchemeCodeUnspsc190501.50357205'),
	'identifierSchemeCodeUnspsc190501.50357206': __('identifierSchemeCodeUnspsc190501.50357206'),
	'identifierSchemeCodeUnspsc190501.50357207': __('identifierSchemeCodeUnspsc190501.50357207'),
	'identifierSchemeCodeUnspsc190501.50357208': __('identifierSchemeCodeUnspsc190501.50357208'),
	'identifierSchemeCodeUnspsc190501.50357209': __('identifierSchemeCodeUnspsc190501.50357209'),
	'identifierSchemeCodeUnspsc190501.50357210': __('identifierSchemeCodeUnspsc190501.50357210'),
	'identifierSchemeCodeUnspsc190501.50357211': __('identifierSchemeCodeUnspsc190501.50357211'),
	'identifierSchemeCodeUnspsc190501.50357212': __('identifierSchemeCodeUnspsc190501.50357212'),
	'identifierSchemeCodeUnspsc190501.50357213': __('identifierSchemeCodeUnspsc190501.50357213'),
	'identifierSchemeCodeUnspsc190501.50357214': __('identifierSchemeCodeUnspsc190501.50357214'),
	'identifierSchemeCodeUnspsc190501.50357215': __('identifierSchemeCodeUnspsc190501.50357215'),
	'identifierSchemeCodeUnspsc190501.50357216': __('identifierSchemeCodeUnspsc190501.50357216'),
	'identifierSchemeCodeUnspsc190501.50357217': __('identifierSchemeCodeUnspsc190501.50357217'),
	'identifierSchemeCodeUnspsc190501.50357218': __('identifierSchemeCodeUnspsc190501.50357218'),
	'identifierSchemeCodeUnspsc190501.50357219': __('identifierSchemeCodeUnspsc190501.50357219'),
	'identifierSchemeCodeUnspsc190501.50357220': __('identifierSchemeCodeUnspsc190501.50357220'),
	'identifierSchemeCodeUnspsc190501.50357221': __('identifierSchemeCodeUnspsc190501.50357221'),
	'identifierSchemeCodeUnspsc190501.50357222': __('identifierSchemeCodeUnspsc190501.50357222'),
	'identifierSchemeCodeUnspsc190501.50357223': __('identifierSchemeCodeUnspsc190501.50357223'),
	'identifierSchemeCodeUnspsc190501.50357224': __('identifierSchemeCodeUnspsc190501.50357224'),
	'identifierSchemeCodeUnspsc190501.50357225': __('identifierSchemeCodeUnspsc190501.50357225'),
	'identifierSchemeCodeUnspsc190501.50357226': __('identifierSchemeCodeUnspsc190501.50357226'),
	'identifierSchemeCodeUnspsc190501.50357227': __('identifierSchemeCodeUnspsc190501.50357227'),
	'identifierSchemeCodeUnspsc190501.50357228': __('identifierSchemeCodeUnspsc190501.50357228'),
	'identifierSchemeCodeUnspsc190501.50357229': __('identifierSchemeCodeUnspsc190501.50357229'),
	'identifierSchemeCodeUnspsc190501.50357230': __('identifierSchemeCodeUnspsc190501.50357230'),
	'identifierSchemeCodeUnspsc190501.50360000': __('identifierSchemeCodeUnspsc190501.50360000'),
	'identifierSchemeCodeUnspsc190501.50361500': __('identifierSchemeCodeUnspsc190501.50361500'),
	'identifierSchemeCodeUnspsc190501.50361501': __('identifierSchemeCodeUnspsc190501.50361501'),
	'identifierSchemeCodeUnspsc190501.50361502': __('identifierSchemeCodeUnspsc190501.50361502'),
	'identifierSchemeCodeUnspsc190501.50361503': __('identifierSchemeCodeUnspsc190501.50361503'),
	'identifierSchemeCodeUnspsc190501.50361504': __('identifierSchemeCodeUnspsc190501.50361504'),
	'identifierSchemeCodeUnspsc190501.50361505': __('identifierSchemeCodeUnspsc190501.50361505'),
	'identifierSchemeCodeUnspsc190501.50361506': __('identifierSchemeCodeUnspsc190501.50361506'),
	'identifierSchemeCodeUnspsc190501.50361507': __('identifierSchemeCodeUnspsc190501.50361507'),
	'identifierSchemeCodeUnspsc190501.50361508': __('identifierSchemeCodeUnspsc190501.50361508'),
	'identifierSchemeCodeUnspsc190501.50361509': __('identifierSchemeCodeUnspsc190501.50361509'),
	'identifierSchemeCodeUnspsc190501.50361510': __('identifierSchemeCodeUnspsc190501.50361510'),
	'identifierSchemeCodeUnspsc190501.50361511': __('identifierSchemeCodeUnspsc190501.50361511'),
	'identifierSchemeCodeUnspsc190501.50361512': __('identifierSchemeCodeUnspsc190501.50361512'),
	'identifierSchemeCodeUnspsc190501.50361513': __('identifierSchemeCodeUnspsc190501.50361513'),
	'identifierSchemeCodeUnspsc190501.50361514': __('identifierSchemeCodeUnspsc190501.50361514'),
	'identifierSchemeCodeUnspsc190501.50361515': __('identifierSchemeCodeUnspsc190501.50361515'),
	'identifierSchemeCodeUnspsc190501.50361516': __('identifierSchemeCodeUnspsc190501.50361516'),
	'identifierSchemeCodeUnspsc190501.50361517': __('identifierSchemeCodeUnspsc190501.50361517'),
	'identifierSchemeCodeUnspsc190501.50361518': __('identifierSchemeCodeUnspsc190501.50361518'),
	'identifierSchemeCodeUnspsc190501.50361519': __('identifierSchemeCodeUnspsc190501.50361519'),
	'identifierSchemeCodeUnspsc190501.50361520': __('identifierSchemeCodeUnspsc190501.50361520'),
	'identifierSchemeCodeUnspsc190501.50361521': __('identifierSchemeCodeUnspsc190501.50361521'),
	'identifierSchemeCodeUnspsc190501.50361522': __('identifierSchemeCodeUnspsc190501.50361522'),
	'identifierSchemeCodeUnspsc190501.50361523': __('identifierSchemeCodeUnspsc190501.50361523'),
	'identifierSchemeCodeUnspsc190501.50361524': __('identifierSchemeCodeUnspsc190501.50361524'),
	'identifierSchemeCodeUnspsc190501.50361525': __('identifierSchemeCodeUnspsc190501.50361525'),
	'identifierSchemeCodeUnspsc190501.50361526': __('identifierSchemeCodeUnspsc190501.50361526'),
	'identifierSchemeCodeUnspsc190501.50361527': __('identifierSchemeCodeUnspsc190501.50361527'),
	'identifierSchemeCodeUnspsc190501.50361528': __('identifierSchemeCodeUnspsc190501.50361528'),
	'identifierSchemeCodeUnspsc190501.50361529': __('identifierSchemeCodeUnspsc190501.50361529'),
	'identifierSchemeCodeUnspsc190501.50361530': __('identifierSchemeCodeUnspsc190501.50361530'),
	'identifierSchemeCodeUnspsc190501.50361531': __('identifierSchemeCodeUnspsc190501.50361531'),
	'identifierSchemeCodeUnspsc190501.50361532': __('identifierSchemeCodeUnspsc190501.50361532'),
	'identifierSchemeCodeUnspsc190501.50361533': __('identifierSchemeCodeUnspsc190501.50361533'),
	'identifierSchemeCodeUnspsc190501.50361534': __('identifierSchemeCodeUnspsc190501.50361534'),
	'identifierSchemeCodeUnspsc190501.50361535': __('identifierSchemeCodeUnspsc190501.50361535'),
	'identifierSchemeCodeUnspsc190501.50361536': __('identifierSchemeCodeUnspsc190501.50361536'),
	'identifierSchemeCodeUnspsc190501.50361537': __('identifierSchemeCodeUnspsc190501.50361537'),
	'identifierSchemeCodeUnspsc190501.50361538': __('identifierSchemeCodeUnspsc190501.50361538'),
	'identifierSchemeCodeUnspsc190501.50361539': __('identifierSchemeCodeUnspsc190501.50361539'),
	'identifierSchemeCodeUnspsc190501.50361540': __('identifierSchemeCodeUnspsc190501.50361540'),
	'identifierSchemeCodeUnspsc190501.50361541': __('identifierSchemeCodeUnspsc190501.50361541'),
	'identifierSchemeCodeUnspsc190501.50361542': __('identifierSchemeCodeUnspsc190501.50361542'),
	'identifierSchemeCodeUnspsc190501.50361543': __('identifierSchemeCodeUnspsc190501.50361543'),
	'identifierSchemeCodeUnspsc190501.50361544': __('identifierSchemeCodeUnspsc190501.50361544'),
	'identifierSchemeCodeUnspsc190501.50361545': __('identifierSchemeCodeUnspsc190501.50361545'),
	'identifierSchemeCodeUnspsc190501.50361546': __('identifierSchemeCodeUnspsc190501.50361546'),
	'identifierSchemeCodeUnspsc190501.50361547': __('identifierSchemeCodeUnspsc190501.50361547'),
	'identifierSchemeCodeUnspsc190501.50361548': __('identifierSchemeCodeUnspsc190501.50361548'),
	'identifierSchemeCodeUnspsc190501.50361549': __('identifierSchemeCodeUnspsc190501.50361549'),
	'identifierSchemeCodeUnspsc190501.50361550': __('identifierSchemeCodeUnspsc190501.50361550'),
	'identifierSchemeCodeUnspsc190501.50361551': __('identifierSchemeCodeUnspsc190501.50361551'),
	'identifierSchemeCodeUnspsc190501.50361552': __('identifierSchemeCodeUnspsc190501.50361552'),
	'identifierSchemeCodeUnspsc190501.50361553': __('identifierSchemeCodeUnspsc190501.50361553'),
	'identifierSchemeCodeUnspsc190501.50361554': __('identifierSchemeCodeUnspsc190501.50361554'),
	'identifierSchemeCodeUnspsc190501.50361555': __('identifierSchemeCodeUnspsc190501.50361555'),
	'identifierSchemeCodeUnspsc190501.50361556': __('identifierSchemeCodeUnspsc190501.50361556'),
	'identifierSchemeCodeUnspsc190501.50361557': __('identifierSchemeCodeUnspsc190501.50361557'),
	'identifierSchemeCodeUnspsc190501.50361558': __('identifierSchemeCodeUnspsc190501.50361558'),
	'identifierSchemeCodeUnspsc190501.50361559': __('identifierSchemeCodeUnspsc190501.50361559'),
	'identifierSchemeCodeUnspsc190501.50361560': __('identifierSchemeCodeUnspsc190501.50361560'),
	'identifierSchemeCodeUnspsc190501.50361561': __('identifierSchemeCodeUnspsc190501.50361561'),
	'identifierSchemeCodeUnspsc190501.50361562': __('identifierSchemeCodeUnspsc190501.50361562'),
	'identifierSchemeCodeUnspsc190501.50361563': __('identifierSchemeCodeUnspsc190501.50361563'),
	'identifierSchemeCodeUnspsc190501.50361564': __('identifierSchemeCodeUnspsc190501.50361564'),
	'identifierSchemeCodeUnspsc190501.50361565': __('identifierSchemeCodeUnspsc190501.50361565'),
	'identifierSchemeCodeUnspsc190501.50361566': __('identifierSchemeCodeUnspsc190501.50361566'),
	'identifierSchemeCodeUnspsc190501.50361567': __('identifierSchemeCodeUnspsc190501.50361567'),
	'identifierSchemeCodeUnspsc190501.50361568': __('identifierSchemeCodeUnspsc190501.50361568'),
	'identifierSchemeCodeUnspsc190501.50361569': __('identifierSchemeCodeUnspsc190501.50361569'),
	'identifierSchemeCodeUnspsc190501.50361570': __('identifierSchemeCodeUnspsc190501.50361570'),
	'identifierSchemeCodeUnspsc190501.50361571': __('identifierSchemeCodeUnspsc190501.50361571'),
	'identifierSchemeCodeUnspsc190501.50361572': __('identifierSchemeCodeUnspsc190501.50361572'),
	'identifierSchemeCodeUnspsc190501.50361573': __('identifierSchemeCodeUnspsc190501.50361573'),
	'identifierSchemeCodeUnspsc190501.50361574': __('identifierSchemeCodeUnspsc190501.50361574'),
	'identifierSchemeCodeUnspsc190501.50361575': __('identifierSchemeCodeUnspsc190501.50361575'),
	'identifierSchemeCodeUnspsc190501.50361576': __('identifierSchemeCodeUnspsc190501.50361576'),
	'identifierSchemeCodeUnspsc190501.50361577': __('identifierSchemeCodeUnspsc190501.50361577'),
	'identifierSchemeCodeUnspsc190501.50361578': __('identifierSchemeCodeUnspsc190501.50361578'),
	'identifierSchemeCodeUnspsc190501.50361579': __('identifierSchemeCodeUnspsc190501.50361579'),
	'identifierSchemeCodeUnspsc190501.50361580': __('identifierSchemeCodeUnspsc190501.50361580'),
	'identifierSchemeCodeUnspsc190501.50361581': __('identifierSchemeCodeUnspsc190501.50361581'),
	'identifierSchemeCodeUnspsc190501.50361582': __('identifierSchemeCodeUnspsc190501.50361582'),
	'identifierSchemeCodeUnspsc190501.50361583': __('identifierSchemeCodeUnspsc190501.50361583'),
	'identifierSchemeCodeUnspsc190501.50361584': __('identifierSchemeCodeUnspsc190501.50361584'),
	'identifierSchemeCodeUnspsc190501.50361585': __('identifierSchemeCodeUnspsc190501.50361585'),
	'identifierSchemeCodeUnspsc190501.50361586': __('identifierSchemeCodeUnspsc190501.50361586'),
	'identifierSchemeCodeUnspsc190501.50361587': __('identifierSchemeCodeUnspsc190501.50361587'),
	'identifierSchemeCodeUnspsc190501.50361588': __('identifierSchemeCodeUnspsc190501.50361588'),
	'identifierSchemeCodeUnspsc190501.50361589': __('identifierSchemeCodeUnspsc190501.50361589'),
	'identifierSchemeCodeUnspsc190501.50361590': __('identifierSchemeCodeUnspsc190501.50361590'),
	'identifierSchemeCodeUnspsc190501.50361591': __('identifierSchemeCodeUnspsc190501.50361591'),
	'identifierSchemeCodeUnspsc190501.50361592': __('identifierSchemeCodeUnspsc190501.50361592'),
	'identifierSchemeCodeUnspsc190501.50361593': __('identifierSchemeCodeUnspsc190501.50361593'),
	'identifierSchemeCodeUnspsc190501.50361594': __('identifierSchemeCodeUnspsc190501.50361594'),
	'identifierSchemeCodeUnspsc190501.50361595': __('identifierSchemeCodeUnspsc190501.50361595'),
	'identifierSchemeCodeUnspsc190501.50361596': __('identifierSchemeCodeUnspsc190501.50361596'),
	'identifierSchemeCodeUnspsc190501.50361600': __('identifierSchemeCodeUnspsc190501.50361600'),
	'identifierSchemeCodeUnspsc190501.50361601': __('identifierSchemeCodeUnspsc190501.50361601'),
	'identifierSchemeCodeUnspsc190501.50361602': __('identifierSchemeCodeUnspsc190501.50361602'),
	'identifierSchemeCodeUnspsc190501.50361603': __('identifierSchemeCodeUnspsc190501.50361603'),
	'identifierSchemeCodeUnspsc190501.50361604': __('identifierSchemeCodeUnspsc190501.50361604'),
	'identifierSchemeCodeUnspsc190501.50361605': __('identifierSchemeCodeUnspsc190501.50361605'),
	'identifierSchemeCodeUnspsc190501.50361606': __('identifierSchemeCodeUnspsc190501.50361606'),
	'identifierSchemeCodeUnspsc190501.50361607': __('identifierSchemeCodeUnspsc190501.50361607'),
	'identifierSchemeCodeUnspsc190501.50361608': __('identifierSchemeCodeUnspsc190501.50361608'),
	'identifierSchemeCodeUnspsc190501.50361609': __('identifierSchemeCodeUnspsc190501.50361609'),
	'identifierSchemeCodeUnspsc190501.50361610': __('identifierSchemeCodeUnspsc190501.50361610'),
	'identifierSchemeCodeUnspsc190501.50361611': __('identifierSchemeCodeUnspsc190501.50361611'),
	'identifierSchemeCodeUnspsc190501.50361612': __('identifierSchemeCodeUnspsc190501.50361612'),
	'identifierSchemeCodeUnspsc190501.50361613': __('identifierSchemeCodeUnspsc190501.50361613'),
	'identifierSchemeCodeUnspsc190501.50361614': __('identifierSchemeCodeUnspsc190501.50361614'),
	'identifierSchemeCodeUnspsc190501.50361615': __('identifierSchemeCodeUnspsc190501.50361615'),
	'identifierSchemeCodeUnspsc190501.50361616': __('identifierSchemeCodeUnspsc190501.50361616'),
	'identifierSchemeCodeUnspsc190501.50361617': __('identifierSchemeCodeUnspsc190501.50361617'),
	'identifierSchemeCodeUnspsc190501.50361618': __('identifierSchemeCodeUnspsc190501.50361618'),
	'identifierSchemeCodeUnspsc190501.50361619': __('identifierSchemeCodeUnspsc190501.50361619'),
	'identifierSchemeCodeUnspsc190501.50361620': __('identifierSchemeCodeUnspsc190501.50361620'),
	'identifierSchemeCodeUnspsc190501.50361621': __('identifierSchemeCodeUnspsc190501.50361621'),
	'identifierSchemeCodeUnspsc190501.50361622': __('identifierSchemeCodeUnspsc190501.50361622'),
	'identifierSchemeCodeUnspsc190501.50361623': __('identifierSchemeCodeUnspsc190501.50361623'),
	'identifierSchemeCodeUnspsc190501.50361624': __('identifierSchemeCodeUnspsc190501.50361624'),
	'identifierSchemeCodeUnspsc190501.50361625': __('identifierSchemeCodeUnspsc190501.50361625'),
	'identifierSchemeCodeUnspsc190501.50361626': __('identifierSchemeCodeUnspsc190501.50361626'),
	'identifierSchemeCodeUnspsc190501.50361627': __('identifierSchemeCodeUnspsc190501.50361627'),
	'identifierSchemeCodeUnspsc190501.50361628': __('identifierSchemeCodeUnspsc190501.50361628'),
	'identifierSchemeCodeUnspsc190501.50361629': __('identifierSchemeCodeUnspsc190501.50361629'),
	'identifierSchemeCodeUnspsc190501.50361630': __('identifierSchemeCodeUnspsc190501.50361630'),
	'identifierSchemeCodeUnspsc190501.50361631': __('identifierSchemeCodeUnspsc190501.50361631'),
	'identifierSchemeCodeUnspsc190501.50361632': __('identifierSchemeCodeUnspsc190501.50361632'),
	'identifierSchemeCodeUnspsc190501.50361633': __('identifierSchemeCodeUnspsc190501.50361633'),
	'identifierSchemeCodeUnspsc190501.50361634': __('identifierSchemeCodeUnspsc190501.50361634'),
	'identifierSchemeCodeUnspsc190501.50361635': __('identifierSchemeCodeUnspsc190501.50361635'),
	'identifierSchemeCodeUnspsc190501.50361636': __('identifierSchemeCodeUnspsc190501.50361636'),
	'identifierSchemeCodeUnspsc190501.50361637': __('identifierSchemeCodeUnspsc190501.50361637'),
	'identifierSchemeCodeUnspsc190501.50361638': __('identifierSchemeCodeUnspsc190501.50361638'),
	'identifierSchemeCodeUnspsc190501.50361639': __('identifierSchemeCodeUnspsc190501.50361639'),
	'identifierSchemeCodeUnspsc190501.50361640': __('identifierSchemeCodeUnspsc190501.50361640'),
	'identifierSchemeCodeUnspsc190501.50361641': __('identifierSchemeCodeUnspsc190501.50361641'),
	'identifierSchemeCodeUnspsc190501.50361642': __('identifierSchemeCodeUnspsc190501.50361642'),
	'identifierSchemeCodeUnspsc190501.50361643': __('identifierSchemeCodeUnspsc190501.50361643'),
	'identifierSchemeCodeUnspsc190501.50361644': __('identifierSchemeCodeUnspsc190501.50361644'),
	'identifierSchemeCodeUnspsc190501.50361645': __('identifierSchemeCodeUnspsc190501.50361645'),
	'identifierSchemeCodeUnspsc190501.50361646': __('identifierSchemeCodeUnspsc190501.50361646'),
	'identifierSchemeCodeUnspsc190501.50361647': __('identifierSchemeCodeUnspsc190501.50361647'),
	'identifierSchemeCodeUnspsc190501.50361648': __('identifierSchemeCodeUnspsc190501.50361648'),
	'identifierSchemeCodeUnspsc190501.50361649': __('identifierSchemeCodeUnspsc190501.50361649'),
	'identifierSchemeCodeUnspsc190501.50361650': __('identifierSchemeCodeUnspsc190501.50361650'),
	'identifierSchemeCodeUnspsc190501.50361651': __('identifierSchemeCodeUnspsc190501.50361651'),
	'identifierSchemeCodeUnspsc190501.50361652': __('identifierSchemeCodeUnspsc190501.50361652'),
	'identifierSchemeCodeUnspsc190501.50361653': __('identifierSchemeCodeUnspsc190501.50361653'),
	'identifierSchemeCodeUnspsc190501.50361654': __('identifierSchemeCodeUnspsc190501.50361654'),
	'identifierSchemeCodeUnspsc190501.50361655': __('identifierSchemeCodeUnspsc190501.50361655'),
	'identifierSchemeCodeUnspsc190501.50361700': __('identifierSchemeCodeUnspsc190501.50361700'),
	'identifierSchemeCodeUnspsc190501.50361701': __('identifierSchemeCodeUnspsc190501.50361701'),
	'identifierSchemeCodeUnspsc190501.50361702': __('identifierSchemeCodeUnspsc190501.50361702'),
	'identifierSchemeCodeUnspsc190501.50361703': __('identifierSchemeCodeUnspsc190501.50361703'),
	'identifierSchemeCodeUnspsc190501.50361704': __('identifierSchemeCodeUnspsc190501.50361704'),
	'identifierSchemeCodeUnspsc190501.50361705': __('identifierSchemeCodeUnspsc190501.50361705'),
	'identifierSchemeCodeUnspsc190501.50361706': __('identifierSchemeCodeUnspsc190501.50361706'),
	'identifierSchemeCodeUnspsc190501.50361707': __('identifierSchemeCodeUnspsc190501.50361707'),
	'identifierSchemeCodeUnspsc190501.50361708': __('identifierSchemeCodeUnspsc190501.50361708'),
	'identifierSchemeCodeUnspsc190501.50361709': __('identifierSchemeCodeUnspsc190501.50361709'),
	'identifierSchemeCodeUnspsc190501.50361710': __('identifierSchemeCodeUnspsc190501.50361710'),
	'identifierSchemeCodeUnspsc190501.50361711': __('identifierSchemeCodeUnspsc190501.50361711'),
	'identifierSchemeCodeUnspsc190501.50361712': __('identifierSchemeCodeUnspsc190501.50361712'),
	'identifierSchemeCodeUnspsc190501.50361713': __('identifierSchemeCodeUnspsc190501.50361713'),
	'identifierSchemeCodeUnspsc190501.50361714': __('identifierSchemeCodeUnspsc190501.50361714'),
	'identifierSchemeCodeUnspsc190501.50361715': __('identifierSchemeCodeUnspsc190501.50361715'),
	'identifierSchemeCodeUnspsc190501.50361800': __('identifierSchemeCodeUnspsc190501.50361800'),
	'identifierSchemeCodeUnspsc190501.50361801': __('identifierSchemeCodeUnspsc190501.50361801'),
	'identifierSchemeCodeUnspsc190501.50361802': __('identifierSchemeCodeUnspsc190501.50361802'),
	'identifierSchemeCodeUnspsc190501.50361803': __('identifierSchemeCodeUnspsc190501.50361803'),
	'identifierSchemeCodeUnspsc190501.50361804': __('identifierSchemeCodeUnspsc190501.50361804'),
	'identifierSchemeCodeUnspsc190501.50361805': __('identifierSchemeCodeUnspsc190501.50361805'),
	'identifierSchemeCodeUnspsc190501.50361806': __('identifierSchemeCodeUnspsc190501.50361806'),
	'identifierSchemeCodeUnspsc190501.50361807': __('identifierSchemeCodeUnspsc190501.50361807'),
	'identifierSchemeCodeUnspsc190501.50361808': __('identifierSchemeCodeUnspsc190501.50361808'),
	'identifierSchemeCodeUnspsc190501.50361809': __('identifierSchemeCodeUnspsc190501.50361809'),
	'identifierSchemeCodeUnspsc190501.50361810': __('identifierSchemeCodeUnspsc190501.50361810'),
	'identifierSchemeCodeUnspsc190501.50361811': __('identifierSchemeCodeUnspsc190501.50361811'),
	'identifierSchemeCodeUnspsc190501.50361812': __('identifierSchemeCodeUnspsc190501.50361812'),
	'identifierSchemeCodeUnspsc190501.50361813': __('identifierSchemeCodeUnspsc190501.50361813'),
	'identifierSchemeCodeUnspsc190501.50361814': __('identifierSchemeCodeUnspsc190501.50361814'),
	'identifierSchemeCodeUnspsc190501.50361900': __('identifierSchemeCodeUnspsc190501.50361900'),
	'identifierSchemeCodeUnspsc190501.50361901': __('identifierSchemeCodeUnspsc190501.50361901'),
	'identifierSchemeCodeUnspsc190501.50361902': __('identifierSchemeCodeUnspsc190501.50361902'),
	'identifierSchemeCodeUnspsc190501.50361903': __('identifierSchemeCodeUnspsc190501.50361903'),
	'identifierSchemeCodeUnspsc190501.50362000': __('identifierSchemeCodeUnspsc190501.50362000'),
	'identifierSchemeCodeUnspsc190501.50362001': __('identifierSchemeCodeUnspsc190501.50362001'),
	'identifierSchemeCodeUnspsc190501.50362002': __('identifierSchemeCodeUnspsc190501.50362002'),
	'identifierSchemeCodeUnspsc190501.50362003': __('identifierSchemeCodeUnspsc190501.50362003'),
	'identifierSchemeCodeUnspsc190501.50362004': __('identifierSchemeCodeUnspsc190501.50362004'),
	'identifierSchemeCodeUnspsc190501.50362005': __('identifierSchemeCodeUnspsc190501.50362005'),
	'identifierSchemeCodeUnspsc190501.50362006': __('identifierSchemeCodeUnspsc190501.50362006'),
	'identifierSchemeCodeUnspsc190501.50362007': __('identifierSchemeCodeUnspsc190501.50362007'),
	'identifierSchemeCodeUnspsc190501.50362008': __('identifierSchemeCodeUnspsc190501.50362008'),
	'identifierSchemeCodeUnspsc190501.50362009': __('identifierSchemeCodeUnspsc190501.50362009'),
	'identifierSchemeCodeUnspsc190501.50362010': __('identifierSchemeCodeUnspsc190501.50362010'),
	'identifierSchemeCodeUnspsc190501.50362011': __('identifierSchemeCodeUnspsc190501.50362011'),
	'identifierSchemeCodeUnspsc190501.50362012': __('identifierSchemeCodeUnspsc190501.50362012'),
	'identifierSchemeCodeUnspsc190501.50362013': __('identifierSchemeCodeUnspsc190501.50362013'),
	'identifierSchemeCodeUnspsc190501.50362014': __('identifierSchemeCodeUnspsc190501.50362014'),
	'identifierSchemeCodeUnspsc190501.50362015': __('identifierSchemeCodeUnspsc190501.50362015'),
	'identifierSchemeCodeUnspsc190501.50362100': __('identifierSchemeCodeUnspsc190501.50362100'),
	'identifierSchemeCodeUnspsc190501.50362101': __('identifierSchemeCodeUnspsc190501.50362101'),
	'identifierSchemeCodeUnspsc190501.50362102': __('identifierSchemeCodeUnspsc190501.50362102'),
	'identifierSchemeCodeUnspsc190501.50362103': __('identifierSchemeCodeUnspsc190501.50362103'),
	'identifierSchemeCodeUnspsc190501.50362104': __('identifierSchemeCodeUnspsc190501.50362104'),
	'identifierSchemeCodeUnspsc190501.50362200': __('identifierSchemeCodeUnspsc190501.50362200'),
	'identifierSchemeCodeUnspsc190501.50362201': __('identifierSchemeCodeUnspsc190501.50362201'),
	'identifierSchemeCodeUnspsc190501.50362202': __('identifierSchemeCodeUnspsc190501.50362202'),
	'identifierSchemeCodeUnspsc190501.50362203': __('identifierSchemeCodeUnspsc190501.50362203'),
	'identifierSchemeCodeUnspsc190501.50362204': __('identifierSchemeCodeUnspsc190501.50362204'),
	'identifierSchemeCodeUnspsc190501.50362205': __('identifierSchemeCodeUnspsc190501.50362205'),
	'identifierSchemeCodeUnspsc190501.50362206': __('identifierSchemeCodeUnspsc190501.50362206'),
	'identifierSchemeCodeUnspsc190501.50362207': __('identifierSchemeCodeUnspsc190501.50362207'),
	'identifierSchemeCodeUnspsc190501.50362208': __('identifierSchemeCodeUnspsc190501.50362208'),
	'identifierSchemeCodeUnspsc190501.50362209': __('identifierSchemeCodeUnspsc190501.50362209'),
	'identifierSchemeCodeUnspsc190501.50362210': __('identifierSchemeCodeUnspsc190501.50362210'),
	'identifierSchemeCodeUnspsc190501.50362211': __('identifierSchemeCodeUnspsc190501.50362211'),
	'identifierSchemeCodeUnspsc190501.50362212': __('identifierSchemeCodeUnspsc190501.50362212'),
	'identifierSchemeCodeUnspsc190501.50362213': __('identifierSchemeCodeUnspsc190501.50362213'),
	'identifierSchemeCodeUnspsc190501.50362214': __('identifierSchemeCodeUnspsc190501.50362214'),
	'identifierSchemeCodeUnspsc190501.50362215': __('identifierSchemeCodeUnspsc190501.50362215'),
	'identifierSchemeCodeUnspsc190501.50362300': __('identifierSchemeCodeUnspsc190501.50362300'),
	'identifierSchemeCodeUnspsc190501.50362301': __('identifierSchemeCodeUnspsc190501.50362301'),
	'identifierSchemeCodeUnspsc190501.50362302': __('identifierSchemeCodeUnspsc190501.50362302'),
	'identifierSchemeCodeUnspsc190501.50362303': __('identifierSchemeCodeUnspsc190501.50362303'),
	'identifierSchemeCodeUnspsc190501.50362304': __('identifierSchemeCodeUnspsc190501.50362304'),
	'identifierSchemeCodeUnspsc190501.50362400': __('identifierSchemeCodeUnspsc190501.50362400'),
	'identifierSchemeCodeUnspsc190501.50362401': __('identifierSchemeCodeUnspsc190501.50362401'),
	'identifierSchemeCodeUnspsc190501.50362402': __('identifierSchemeCodeUnspsc190501.50362402'),
	'identifierSchemeCodeUnspsc190501.50362403': __('identifierSchemeCodeUnspsc190501.50362403'),
	'identifierSchemeCodeUnspsc190501.50362404': __('identifierSchemeCodeUnspsc190501.50362404'),
	'identifierSchemeCodeUnspsc190501.50362405': __('identifierSchemeCodeUnspsc190501.50362405'),
	'identifierSchemeCodeUnspsc190501.50362406': __('identifierSchemeCodeUnspsc190501.50362406'),
	'identifierSchemeCodeUnspsc190501.50362407': __('identifierSchemeCodeUnspsc190501.50362407'),
	'identifierSchemeCodeUnspsc190501.50362408': __('identifierSchemeCodeUnspsc190501.50362408'),
	'identifierSchemeCodeUnspsc190501.50362409': __('identifierSchemeCodeUnspsc190501.50362409'),
	'identifierSchemeCodeUnspsc190501.50362500': __('identifierSchemeCodeUnspsc190501.50362500'),
	'identifierSchemeCodeUnspsc190501.50362501': __('identifierSchemeCodeUnspsc190501.50362501'),
	'identifierSchemeCodeUnspsc190501.50362502': __('identifierSchemeCodeUnspsc190501.50362502'),
	'identifierSchemeCodeUnspsc190501.50362503': __('identifierSchemeCodeUnspsc190501.50362503'),
	'identifierSchemeCodeUnspsc190501.50362504': __('identifierSchemeCodeUnspsc190501.50362504'),
	'identifierSchemeCodeUnspsc190501.50362505': __('identifierSchemeCodeUnspsc190501.50362505'),
	'identifierSchemeCodeUnspsc190501.50362506': __('identifierSchemeCodeUnspsc190501.50362506'),
	'identifierSchemeCodeUnspsc190501.50362507': __('identifierSchemeCodeUnspsc190501.50362507'),
	'identifierSchemeCodeUnspsc190501.50362508': __('identifierSchemeCodeUnspsc190501.50362508'),
	'identifierSchemeCodeUnspsc190501.50362509': __('identifierSchemeCodeUnspsc190501.50362509'),
	'identifierSchemeCodeUnspsc190501.50362510': __('identifierSchemeCodeUnspsc190501.50362510'),
	'identifierSchemeCodeUnspsc190501.50362511': __('identifierSchemeCodeUnspsc190501.50362511'),
	'identifierSchemeCodeUnspsc190501.50362512': __('identifierSchemeCodeUnspsc190501.50362512'),
	'identifierSchemeCodeUnspsc190501.50362513': __('identifierSchemeCodeUnspsc190501.50362513'),
	'identifierSchemeCodeUnspsc190501.50362514': __('identifierSchemeCodeUnspsc190501.50362514'),
	'identifierSchemeCodeUnspsc190501.50362515': __('identifierSchemeCodeUnspsc190501.50362515'),
	'identifierSchemeCodeUnspsc190501.50362516': __('identifierSchemeCodeUnspsc190501.50362516'),
	'identifierSchemeCodeUnspsc190501.50362517': __('identifierSchemeCodeUnspsc190501.50362517'),
	'identifierSchemeCodeUnspsc190501.50362518': __('identifierSchemeCodeUnspsc190501.50362518'),
	'identifierSchemeCodeUnspsc190501.50362519': __('identifierSchemeCodeUnspsc190501.50362519'),
	'identifierSchemeCodeUnspsc190501.50362520': __('identifierSchemeCodeUnspsc190501.50362520'),
	'identifierSchemeCodeUnspsc190501.50362521': __('identifierSchemeCodeUnspsc190501.50362521'),
	'identifierSchemeCodeUnspsc190501.50362522': __('identifierSchemeCodeUnspsc190501.50362522'),
	'identifierSchemeCodeUnspsc190501.50362523': __('identifierSchemeCodeUnspsc190501.50362523'),
	'identifierSchemeCodeUnspsc190501.50362524': __('identifierSchemeCodeUnspsc190501.50362524'),
	'identifierSchemeCodeUnspsc190501.50362525': __('identifierSchemeCodeUnspsc190501.50362525'),
	'identifierSchemeCodeUnspsc190501.50362527': __('identifierSchemeCodeUnspsc190501.50362527'),
	'identifierSchemeCodeUnspsc190501.50362528': __('identifierSchemeCodeUnspsc190501.50362528'),
	'identifierSchemeCodeUnspsc190501.50362529': __('identifierSchemeCodeUnspsc190501.50362529'),
	'identifierSchemeCodeUnspsc190501.50362530': __('identifierSchemeCodeUnspsc190501.50362530'),
	'identifierSchemeCodeUnspsc190501.50362531': __('identifierSchemeCodeUnspsc190501.50362531'),
	'identifierSchemeCodeUnspsc190501.50362532': __('identifierSchemeCodeUnspsc190501.50362532'),
	'identifierSchemeCodeUnspsc190501.50362600': __('identifierSchemeCodeUnspsc190501.50362600'),
	'identifierSchemeCodeUnspsc190501.50362601': __('identifierSchemeCodeUnspsc190501.50362601'),
	'identifierSchemeCodeUnspsc190501.50362602': __('identifierSchemeCodeUnspsc190501.50362602'),
	'identifierSchemeCodeUnspsc190501.50362603': __('identifierSchemeCodeUnspsc190501.50362603'),
	'identifierSchemeCodeUnspsc190501.50362604': __('identifierSchemeCodeUnspsc190501.50362604'),
	'identifierSchemeCodeUnspsc190501.50362605': __('identifierSchemeCodeUnspsc190501.50362605'),
	'identifierSchemeCodeUnspsc190501.50362606': __('identifierSchemeCodeUnspsc190501.50362606'),
	'identifierSchemeCodeUnspsc190501.50362607': __('identifierSchemeCodeUnspsc190501.50362607'),
	'identifierSchemeCodeUnspsc190501.50362700': __('identifierSchemeCodeUnspsc190501.50362700'),
	'identifierSchemeCodeUnspsc190501.50362701': __('identifierSchemeCodeUnspsc190501.50362701'),
	'identifierSchemeCodeUnspsc190501.50362702': __('identifierSchemeCodeUnspsc190501.50362702'),
	'identifierSchemeCodeUnspsc190501.50362703': __('identifierSchemeCodeUnspsc190501.50362703'),
	'identifierSchemeCodeUnspsc190501.50362704': __('identifierSchemeCodeUnspsc190501.50362704'),
	'identifierSchemeCodeUnspsc190501.50362705': __('identifierSchemeCodeUnspsc190501.50362705'),
	'identifierSchemeCodeUnspsc190501.50362706': __('identifierSchemeCodeUnspsc190501.50362706'),
	'identifierSchemeCodeUnspsc190501.50362707': __('identifierSchemeCodeUnspsc190501.50362707'),
	'identifierSchemeCodeUnspsc190501.50362708': __('identifierSchemeCodeUnspsc190501.50362708'),
	'identifierSchemeCodeUnspsc190501.50362709': __('identifierSchemeCodeUnspsc190501.50362709'),
	'identifierSchemeCodeUnspsc190501.50362710': __('identifierSchemeCodeUnspsc190501.50362710'),
	'identifierSchemeCodeUnspsc190501.50362800': __('identifierSchemeCodeUnspsc190501.50362800'),
	'identifierSchemeCodeUnspsc190501.50362801': __('identifierSchemeCodeUnspsc190501.50362801'),
	'identifierSchemeCodeUnspsc190501.50362802': __('identifierSchemeCodeUnspsc190501.50362802'),
	'identifierSchemeCodeUnspsc190501.50362803': __('identifierSchemeCodeUnspsc190501.50362803'),
	'identifierSchemeCodeUnspsc190501.50362804': __('identifierSchemeCodeUnspsc190501.50362804'),
	'identifierSchemeCodeUnspsc190501.50362805': __('identifierSchemeCodeUnspsc190501.50362805'),
	'identifierSchemeCodeUnspsc190501.50362806': __('identifierSchemeCodeUnspsc190501.50362806'),
	'identifierSchemeCodeUnspsc190501.50362900': __('identifierSchemeCodeUnspsc190501.50362900'),
	'identifierSchemeCodeUnspsc190501.50362901': __('identifierSchemeCodeUnspsc190501.50362901'),
	'identifierSchemeCodeUnspsc190501.50362902': __('identifierSchemeCodeUnspsc190501.50362902'),
	'identifierSchemeCodeUnspsc190501.50362903': __('identifierSchemeCodeUnspsc190501.50362903'),
	'identifierSchemeCodeUnspsc190501.50362904': __('identifierSchemeCodeUnspsc190501.50362904'),
	'identifierSchemeCodeUnspsc190501.50362905': __('identifierSchemeCodeUnspsc190501.50362905'),
	'identifierSchemeCodeUnspsc190501.50362906': __('identifierSchemeCodeUnspsc190501.50362906'),
	'identifierSchemeCodeUnspsc190501.50362907': __('identifierSchemeCodeUnspsc190501.50362907'),
	'identifierSchemeCodeUnspsc190501.50362908': __('identifierSchemeCodeUnspsc190501.50362908'),
	'identifierSchemeCodeUnspsc190501.50362909': __('identifierSchemeCodeUnspsc190501.50362909'),
	'identifierSchemeCodeUnspsc190501.50362910': __('identifierSchemeCodeUnspsc190501.50362910'),
	'identifierSchemeCodeUnspsc190501.50362911': __('identifierSchemeCodeUnspsc190501.50362911'),
	'identifierSchemeCodeUnspsc190501.50362912': __('identifierSchemeCodeUnspsc190501.50362912'),
	'identifierSchemeCodeUnspsc190501.50362913': __('identifierSchemeCodeUnspsc190501.50362913'),
	'identifierSchemeCodeUnspsc190501.50362914': __('identifierSchemeCodeUnspsc190501.50362914'),
	'identifierSchemeCodeUnspsc190501.50362915': __('identifierSchemeCodeUnspsc190501.50362915'),
	'identifierSchemeCodeUnspsc190501.50362916': __('identifierSchemeCodeUnspsc190501.50362916'),
	'identifierSchemeCodeUnspsc190501.50363000': __('identifierSchemeCodeUnspsc190501.50363000'),
	'identifierSchemeCodeUnspsc190501.50363001': __('identifierSchemeCodeUnspsc190501.50363001'),
	'identifierSchemeCodeUnspsc190501.50363002': __('identifierSchemeCodeUnspsc190501.50363002'),
	'identifierSchemeCodeUnspsc190501.50363100': __('identifierSchemeCodeUnspsc190501.50363100'),
	'identifierSchemeCodeUnspsc190501.50363101': __('identifierSchemeCodeUnspsc190501.50363101'),
	'identifierSchemeCodeUnspsc190501.50363102': __('identifierSchemeCodeUnspsc190501.50363102'),
	'identifierSchemeCodeUnspsc190501.50363103': __('identifierSchemeCodeUnspsc190501.50363103'),
	'identifierSchemeCodeUnspsc190501.50363104': __('identifierSchemeCodeUnspsc190501.50363104'),
	'identifierSchemeCodeUnspsc190501.50363105': __('identifierSchemeCodeUnspsc190501.50363105'),
	'identifierSchemeCodeUnspsc190501.50363106': __('identifierSchemeCodeUnspsc190501.50363106'),
	'identifierSchemeCodeUnspsc190501.50363107': __('identifierSchemeCodeUnspsc190501.50363107'),
	'identifierSchemeCodeUnspsc190501.50363108': __('identifierSchemeCodeUnspsc190501.50363108'),
	'identifierSchemeCodeUnspsc190501.50363109': __('identifierSchemeCodeUnspsc190501.50363109'),
	'identifierSchemeCodeUnspsc190501.50363110': __('identifierSchemeCodeUnspsc190501.50363110'),
	'identifierSchemeCodeUnspsc190501.50363111': __('identifierSchemeCodeUnspsc190501.50363111'),
	'identifierSchemeCodeUnspsc190501.50363200': __('identifierSchemeCodeUnspsc190501.50363200'),
	'identifierSchemeCodeUnspsc190501.50363201': __('identifierSchemeCodeUnspsc190501.50363201'),
	'identifierSchemeCodeUnspsc190501.50363202': __('identifierSchemeCodeUnspsc190501.50363202'),
	'identifierSchemeCodeUnspsc190501.50363203': __('identifierSchemeCodeUnspsc190501.50363203'),
	'identifierSchemeCodeUnspsc190501.50363204': __('identifierSchemeCodeUnspsc190501.50363204'),
	'identifierSchemeCodeUnspsc190501.50363205': __('identifierSchemeCodeUnspsc190501.50363205'),
	'identifierSchemeCodeUnspsc190501.50363206': __('identifierSchemeCodeUnspsc190501.50363206'),
	'identifierSchemeCodeUnspsc190501.50363207': __('identifierSchemeCodeUnspsc190501.50363207'),
	'identifierSchemeCodeUnspsc190501.50363300': __('identifierSchemeCodeUnspsc190501.50363300'),
	'identifierSchemeCodeUnspsc190501.50363301': __('identifierSchemeCodeUnspsc190501.50363301'),
	'identifierSchemeCodeUnspsc190501.50363302': __('identifierSchemeCodeUnspsc190501.50363302'),
	'identifierSchemeCodeUnspsc190501.50363303': __('identifierSchemeCodeUnspsc190501.50363303'),
	'identifierSchemeCodeUnspsc190501.50363304': __('identifierSchemeCodeUnspsc190501.50363304'),
	'identifierSchemeCodeUnspsc190501.50363305': __('identifierSchemeCodeUnspsc190501.50363305'),
	'identifierSchemeCodeUnspsc190501.50363306': __('identifierSchemeCodeUnspsc190501.50363306'),
	'identifierSchemeCodeUnspsc190501.50363307': __('identifierSchemeCodeUnspsc190501.50363307'),
	'identifierSchemeCodeUnspsc190501.50363308': __('identifierSchemeCodeUnspsc190501.50363308'),
	'identifierSchemeCodeUnspsc190501.50363309': __('identifierSchemeCodeUnspsc190501.50363309'),
	'identifierSchemeCodeUnspsc190501.50363400': __('identifierSchemeCodeUnspsc190501.50363400'),
	'identifierSchemeCodeUnspsc190501.50363401': __('identifierSchemeCodeUnspsc190501.50363401'),
	'identifierSchemeCodeUnspsc190501.50363402': __('identifierSchemeCodeUnspsc190501.50363402'),
	'identifierSchemeCodeUnspsc190501.50363403': __('identifierSchemeCodeUnspsc190501.50363403'),
	'identifierSchemeCodeUnspsc190501.50363404': __('identifierSchemeCodeUnspsc190501.50363404'),
	'identifierSchemeCodeUnspsc190501.50363405': __('identifierSchemeCodeUnspsc190501.50363405'),
	'identifierSchemeCodeUnspsc190501.50363406': __('identifierSchemeCodeUnspsc190501.50363406'),
	'identifierSchemeCodeUnspsc190501.50363407': __('identifierSchemeCodeUnspsc190501.50363407'),
	'identifierSchemeCodeUnspsc190501.50363408': __('identifierSchemeCodeUnspsc190501.50363408'),
	'identifierSchemeCodeUnspsc190501.50363409': __('identifierSchemeCodeUnspsc190501.50363409'),
	'identifierSchemeCodeUnspsc190501.50363410': __('identifierSchemeCodeUnspsc190501.50363410'),
	'identifierSchemeCodeUnspsc190501.50363411': __('identifierSchemeCodeUnspsc190501.50363411'),
	'identifierSchemeCodeUnspsc190501.50363412': __('identifierSchemeCodeUnspsc190501.50363412'),
	'identifierSchemeCodeUnspsc190501.50363413': __('identifierSchemeCodeUnspsc190501.50363413'),
	'identifierSchemeCodeUnspsc190501.50363414': __('identifierSchemeCodeUnspsc190501.50363414'),
	'identifierSchemeCodeUnspsc190501.50363415': __('identifierSchemeCodeUnspsc190501.50363415'),
	'identifierSchemeCodeUnspsc190501.50363416': __('identifierSchemeCodeUnspsc190501.50363416'),
	'identifierSchemeCodeUnspsc190501.50363417': __('identifierSchemeCodeUnspsc190501.50363417'),
	'identifierSchemeCodeUnspsc190501.50363418': __('identifierSchemeCodeUnspsc190501.50363418'),
	'identifierSchemeCodeUnspsc190501.50363419': __('identifierSchemeCodeUnspsc190501.50363419'),
	'identifierSchemeCodeUnspsc190501.50363420': __('identifierSchemeCodeUnspsc190501.50363420'),
	'identifierSchemeCodeUnspsc190501.50363421': __('identifierSchemeCodeUnspsc190501.50363421'),
	'identifierSchemeCodeUnspsc190501.50363422': __('identifierSchemeCodeUnspsc190501.50363422'),
	'identifierSchemeCodeUnspsc190501.50363423': __('identifierSchemeCodeUnspsc190501.50363423'),
	'identifierSchemeCodeUnspsc190501.50363424': __('identifierSchemeCodeUnspsc190501.50363424'),
	'identifierSchemeCodeUnspsc190501.50363425': __('identifierSchemeCodeUnspsc190501.50363425'),
	'identifierSchemeCodeUnspsc190501.50363426': __('identifierSchemeCodeUnspsc190501.50363426'),
	'identifierSchemeCodeUnspsc190501.50363427': __('identifierSchemeCodeUnspsc190501.50363427'),
	'identifierSchemeCodeUnspsc190501.50363428': __('identifierSchemeCodeUnspsc190501.50363428'),
	'identifierSchemeCodeUnspsc190501.50363429': __('identifierSchemeCodeUnspsc190501.50363429'),
	'identifierSchemeCodeUnspsc190501.50363430': __('identifierSchemeCodeUnspsc190501.50363430'),
	'identifierSchemeCodeUnspsc190501.50363431': __('identifierSchemeCodeUnspsc190501.50363431'),
	'identifierSchemeCodeUnspsc190501.50363432': __('identifierSchemeCodeUnspsc190501.50363432'),
	'identifierSchemeCodeUnspsc190501.50363433': __('identifierSchemeCodeUnspsc190501.50363433'),
	'identifierSchemeCodeUnspsc190501.50363434': __('identifierSchemeCodeUnspsc190501.50363434'),
	'identifierSchemeCodeUnspsc190501.50363435': __('identifierSchemeCodeUnspsc190501.50363435'),
	'identifierSchemeCodeUnspsc190501.50363436': __('identifierSchemeCodeUnspsc190501.50363436'),
	'identifierSchemeCodeUnspsc190501.50363437': __('identifierSchemeCodeUnspsc190501.50363437'),
	'identifierSchemeCodeUnspsc190501.50363438': __('identifierSchemeCodeUnspsc190501.50363438'),
	'identifierSchemeCodeUnspsc190501.50363439': __('identifierSchemeCodeUnspsc190501.50363439'),
	'identifierSchemeCodeUnspsc190501.50363440': __('identifierSchemeCodeUnspsc190501.50363440'),
	'identifierSchemeCodeUnspsc190501.50363441': __('identifierSchemeCodeUnspsc190501.50363441'),
	'identifierSchemeCodeUnspsc190501.50363442': __('identifierSchemeCodeUnspsc190501.50363442'),
	'identifierSchemeCodeUnspsc190501.50363443': __('identifierSchemeCodeUnspsc190501.50363443'),
	'identifierSchemeCodeUnspsc190501.50363444': __('identifierSchemeCodeUnspsc190501.50363444'),
	'identifierSchemeCodeUnspsc190501.50363445': __('identifierSchemeCodeUnspsc190501.50363445'),
	'identifierSchemeCodeUnspsc190501.50363446': __('identifierSchemeCodeUnspsc190501.50363446'),
	'identifierSchemeCodeUnspsc190501.50363447': __('identifierSchemeCodeUnspsc190501.50363447'),
	'identifierSchemeCodeUnspsc190501.50363448': __('identifierSchemeCodeUnspsc190501.50363448'),
	'identifierSchemeCodeUnspsc190501.50363449': __('identifierSchemeCodeUnspsc190501.50363449'),
	'identifierSchemeCodeUnspsc190501.50363450': __('identifierSchemeCodeUnspsc190501.50363450'),
	'identifierSchemeCodeUnspsc190501.50363451': __('identifierSchemeCodeUnspsc190501.50363451'),
	'identifierSchemeCodeUnspsc190501.50363452': __('identifierSchemeCodeUnspsc190501.50363452'),
	'identifierSchemeCodeUnspsc190501.50363453': __('identifierSchemeCodeUnspsc190501.50363453'),
	'identifierSchemeCodeUnspsc190501.50363454': __('identifierSchemeCodeUnspsc190501.50363454'),
	'identifierSchemeCodeUnspsc190501.50363455': __('identifierSchemeCodeUnspsc190501.50363455'),
	'identifierSchemeCodeUnspsc190501.50363456': __('identifierSchemeCodeUnspsc190501.50363456'),
	'identifierSchemeCodeUnspsc190501.50363457': __('identifierSchemeCodeUnspsc190501.50363457'),
	'identifierSchemeCodeUnspsc190501.50363458': __('identifierSchemeCodeUnspsc190501.50363458'),
	'identifierSchemeCodeUnspsc190501.50363459': __('identifierSchemeCodeUnspsc190501.50363459'),
	'identifierSchemeCodeUnspsc190501.50363460': __('identifierSchemeCodeUnspsc190501.50363460'),
	'identifierSchemeCodeUnspsc190501.50363461': __('identifierSchemeCodeUnspsc190501.50363461'),
	'identifierSchemeCodeUnspsc190501.50363462': __('identifierSchemeCodeUnspsc190501.50363462'),
	'identifierSchemeCodeUnspsc190501.50363463': __('identifierSchemeCodeUnspsc190501.50363463'),
	'identifierSchemeCodeUnspsc190501.50363464': __('identifierSchemeCodeUnspsc190501.50363464'),
	'identifierSchemeCodeUnspsc190501.50363465': __('identifierSchemeCodeUnspsc190501.50363465'),
	'identifierSchemeCodeUnspsc190501.50363466': __('identifierSchemeCodeUnspsc190501.50363466'),
	'identifierSchemeCodeUnspsc190501.50363467': __('identifierSchemeCodeUnspsc190501.50363467'),
	'identifierSchemeCodeUnspsc190501.50363468': __('identifierSchemeCodeUnspsc190501.50363468'),
	'identifierSchemeCodeUnspsc190501.50363469': __('identifierSchemeCodeUnspsc190501.50363469'),
	'identifierSchemeCodeUnspsc190501.50363470': __('identifierSchemeCodeUnspsc190501.50363470'),
	'identifierSchemeCodeUnspsc190501.50363471': __('identifierSchemeCodeUnspsc190501.50363471'),
	'identifierSchemeCodeUnspsc190501.50363472': __('identifierSchemeCodeUnspsc190501.50363472'),
	'identifierSchemeCodeUnspsc190501.50363473': __('identifierSchemeCodeUnspsc190501.50363473'),
	'identifierSchemeCodeUnspsc190501.50363474': __('identifierSchemeCodeUnspsc190501.50363474'),
	'identifierSchemeCodeUnspsc190501.50363475': __('identifierSchemeCodeUnspsc190501.50363475'),
	'identifierSchemeCodeUnspsc190501.50363476': __('identifierSchemeCodeUnspsc190501.50363476'),
	'identifierSchemeCodeUnspsc190501.50363477': __('identifierSchemeCodeUnspsc190501.50363477'),
	'identifierSchemeCodeUnspsc190501.50363478': __('identifierSchemeCodeUnspsc190501.50363478'),
	'identifierSchemeCodeUnspsc190501.50363479': __('identifierSchemeCodeUnspsc190501.50363479'),
	'identifierSchemeCodeUnspsc190501.50363480': __('identifierSchemeCodeUnspsc190501.50363480'),
	'identifierSchemeCodeUnspsc190501.50363481': __('identifierSchemeCodeUnspsc190501.50363481'),
	'identifierSchemeCodeUnspsc190501.50363482': __('identifierSchemeCodeUnspsc190501.50363482'),
	'identifierSchemeCodeUnspsc190501.50363483': __('identifierSchemeCodeUnspsc190501.50363483'),
	'identifierSchemeCodeUnspsc190501.50363484': __('identifierSchemeCodeUnspsc190501.50363484'),
	'identifierSchemeCodeUnspsc190501.50363485': __('identifierSchemeCodeUnspsc190501.50363485'),
	'identifierSchemeCodeUnspsc190501.50363486': __('identifierSchemeCodeUnspsc190501.50363486'),
	'identifierSchemeCodeUnspsc190501.50363487': __('identifierSchemeCodeUnspsc190501.50363487'),
	'identifierSchemeCodeUnspsc190501.50363488': __('identifierSchemeCodeUnspsc190501.50363488'),
	'identifierSchemeCodeUnspsc190501.50363489': __('identifierSchemeCodeUnspsc190501.50363489'),
	'identifierSchemeCodeUnspsc190501.50363490': __('identifierSchemeCodeUnspsc190501.50363490'),
	'identifierSchemeCodeUnspsc190501.50363491': __('identifierSchemeCodeUnspsc190501.50363491'),
	'identifierSchemeCodeUnspsc190501.50363492': __('identifierSchemeCodeUnspsc190501.50363492'),
	'identifierSchemeCodeUnspsc190501.50363493': __('identifierSchemeCodeUnspsc190501.50363493'),
	'identifierSchemeCodeUnspsc190501.50363494': __('identifierSchemeCodeUnspsc190501.50363494'),
	'identifierSchemeCodeUnspsc190501.50363495': __('identifierSchemeCodeUnspsc190501.50363495'),
	'identifierSchemeCodeUnspsc190501.50363496': __('identifierSchemeCodeUnspsc190501.50363496'),
	'identifierSchemeCodeUnspsc190501.50363500': __('identifierSchemeCodeUnspsc190501.50363500'),
	'identifierSchemeCodeUnspsc190501.50363501': __('identifierSchemeCodeUnspsc190501.50363501'),
	'identifierSchemeCodeUnspsc190501.50363502': __('identifierSchemeCodeUnspsc190501.50363502'),
	'identifierSchemeCodeUnspsc190501.50363503': __('identifierSchemeCodeUnspsc190501.50363503'),
	'identifierSchemeCodeUnspsc190501.50363504': __('identifierSchemeCodeUnspsc190501.50363504'),
	'identifierSchemeCodeUnspsc190501.50363505': __('identifierSchemeCodeUnspsc190501.50363505'),
	'identifierSchemeCodeUnspsc190501.50363506': __('identifierSchemeCodeUnspsc190501.50363506'),
	'identifierSchemeCodeUnspsc190501.50363600': __('identifierSchemeCodeUnspsc190501.50363600'),
	'identifierSchemeCodeUnspsc190501.50363601': __('identifierSchemeCodeUnspsc190501.50363601'),
	'identifierSchemeCodeUnspsc190501.50363602': __('identifierSchemeCodeUnspsc190501.50363602'),
	'identifierSchemeCodeUnspsc190501.50363603': __('identifierSchemeCodeUnspsc190501.50363603'),
	'identifierSchemeCodeUnspsc190501.50363604': __('identifierSchemeCodeUnspsc190501.50363604'),
	'identifierSchemeCodeUnspsc190501.50363605': __('identifierSchemeCodeUnspsc190501.50363605'),
	'identifierSchemeCodeUnspsc190501.50363606': __('identifierSchemeCodeUnspsc190501.50363606'),
	'identifierSchemeCodeUnspsc190501.50363607': __('identifierSchemeCodeUnspsc190501.50363607'),
	'identifierSchemeCodeUnspsc190501.50363608': __('identifierSchemeCodeUnspsc190501.50363608'),
	'identifierSchemeCodeUnspsc190501.50363609': __('identifierSchemeCodeUnspsc190501.50363609'),
	'identifierSchemeCodeUnspsc190501.50363610': __('identifierSchemeCodeUnspsc190501.50363610'),
	'identifierSchemeCodeUnspsc190501.50363611': __('identifierSchemeCodeUnspsc190501.50363611'),
	'identifierSchemeCodeUnspsc190501.50363612': __('identifierSchemeCodeUnspsc190501.50363612'),
	'identifierSchemeCodeUnspsc190501.50363613': __('identifierSchemeCodeUnspsc190501.50363613'),
	'identifierSchemeCodeUnspsc190501.50363614': __('identifierSchemeCodeUnspsc190501.50363614'),
	'identifierSchemeCodeUnspsc190501.50363615': __('identifierSchemeCodeUnspsc190501.50363615'),
	'identifierSchemeCodeUnspsc190501.50363616': __('identifierSchemeCodeUnspsc190501.50363616'),
	'identifierSchemeCodeUnspsc190501.50363617': __('identifierSchemeCodeUnspsc190501.50363617'),
	'identifierSchemeCodeUnspsc190501.50363618': __('identifierSchemeCodeUnspsc190501.50363618'),
	'identifierSchemeCodeUnspsc190501.50363619': __('identifierSchemeCodeUnspsc190501.50363619'),
	'identifierSchemeCodeUnspsc190501.50363620': __('identifierSchemeCodeUnspsc190501.50363620'),
	'identifierSchemeCodeUnspsc190501.50363621': __('identifierSchemeCodeUnspsc190501.50363621'),
	'identifierSchemeCodeUnspsc190501.50363622': __('identifierSchemeCodeUnspsc190501.50363622'),
	'identifierSchemeCodeUnspsc190501.50363623': __('identifierSchemeCodeUnspsc190501.50363623'),
	'identifierSchemeCodeUnspsc190501.50363624': __('identifierSchemeCodeUnspsc190501.50363624'),
	'identifierSchemeCodeUnspsc190501.50363625': __('identifierSchemeCodeUnspsc190501.50363625'),
	'identifierSchemeCodeUnspsc190501.50363626': __('identifierSchemeCodeUnspsc190501.50363626'),
	'identifierSchemeCodeUnspsc190501.50363627': __('identifierSchemeCodeUnspsc190501.50363627'),
	'identifierSchemeCodeUnspsc190501.50363628': __('identifierSchemeCodeUnspsc190501.50363628'),
	'identifierSchemeCodeUnspsc190501.50363629': __('identifierSchemeCodeUnspsc190501.50363629'),
	'identifierSchemeCodeUnspsc190501.50363630': __('identifierSchemeCodeUnspsc190501.50363630'),
	'identifierSchemeCodeUnspsc190501.50363631': __('identifierSchemeCodeUnspsc190501.50363631'),
	'identifierSchemeCodeUnspsc190501.50363632': __('identifierSchemeCodeUnspsc190501.50363632'),
	'identifierSchemeCodeUnspsc190501.50363633': __('identifierSchemeCodeUnspsc190501.50363633'),
	'identifierSchemeCodeUnspsc190501.50363634': __('identifierSchemeCodeUnspsc190501.50363634'),
	'identifierSchemeCodeUnspsc190501.50363635': __('identifierSchemeCodeUnspsc190501.50363635'),
	'identifierSchemeCodeUnspsc190501.50363636': __('identifierSchemeCodeUnspsc190501.50363636'),
	'identifierSchemeCodeUnspsc190501.50363637': __('identifierSchemeCodeUnspsc190501.50363637'),
	'identifierSchemeCodeUnspsc190501.50363638': __('identifierSchemeCodeUnspsc190501.50363638'),
	'identifierSchemeCodeUnspsc190501.50363639': __('identifierSchemeCodeUnspsc190501.50363639'),
	'identifierSchemeCodeUnspsc190501.50363640': __('identifierSchemeCodeUnspsc190501.50363640'),
	'identifierSchemeCodeUnspsc190501.50363641': __('identifierSchemeCodeUnspsc190501.50363641'),
	'identifierSchemeCodeUnspsc190501.50363642': __('identifierSchemeCodeUnspsc190501.50363642'),
	'identifierSchemeCodeUnspsc190501.50363643': __('identifierSchemeCodeUnspsc190501.50363643'),
	'identifierSchemeCodeUnspsc190501.50363644': __('identifierSchemeCodeUnspsc190501.50363644'),
	'identifierSchemeCodeUnspsc190501.50363645': __('identifierSchemeCodeUnspsc190501.50363645'),
	'identifierSchemeCodeUnspsc190501.50363646': __('identifierSchemeCodeUnspsc190501.50363646'),
	'identifierSchemeCodeUnspsc190501.50363647': __('identifierSchemeCodeUnspsc190501.50363647'),
	'identifierSchemeCodeUnspsc190501.50363648': __('identifierSchemeCodeUnspsc190501.50363648'),
	'identifierSchemeCodeUnspsc190501.50363649': __('identifierSchemeCodeUnspsc190501.50363649'),
	'identifierSchemeCodeUnspsc190501.50363650': __('identifierSchemeCodeUnspsc190501.50363650'),
	'identifierSchemeCodeUnspsc190501.50363651': __('identifierSchemeCodeUnspsc190501.50363651'),
	'identifierSchemeCodeUnspsc190501.50363652': __('identifierSchemeCodeUnspsc190501.50363652'),
	'identifierSchemeCodeUnspsc190501.50363653': __('identifierSchemeCodeUnspsc190501.50363653'),
	'identifierSchemeCodeUnspsc190501.50363654': __('identifierSchemeCodeUnspsc190501.50363654'),
	'identifierSchemeCodeUnspsc190501.50363655': __('identifierSchemeCodeUnspsc190501.50363655'),
	'identifierSchemeCodeUnspsc190501.50363656': __('identifierSchemeCodeUnspsc190501.50363656'),
	'identifierSchemeCodeUnspsc190501.50363657': __('identifierSchemeCodeUnspsc190501.50363657'),
	'identifierSchemeCodeUnspsc190501.50363658': __('identifierSchemeCodeUnspsc190501.50363658'),
	'identifierSchemeCodeUnspsc190501.50363659': __('identifierSchemeCodeUnspsc190501.50363659'),
	'identifierSchemeCodeUnspsc190501.50363660': __('identifierSchemeCodeUnspsc190501.50363660'),
	'identifierSchemeCodeUnspsc190501.50363661': __('identifierSchemeCodeUnspsc190501.50363661'),
	'identifierSchemeCodeUnspsc190501.50363662': __('identifierSchemeCodeUnspsc190501.50363662'),
	'identifierSchemeCodeUnspsc190501.50363663': __('identifierSchemeCodeUnspsc190501.50363663'),
	'identifierSchemeCodeUnspsc190501.50363664': __('identifierSchemeCodeUnspsc190501.50363664'),
	'identifierSchemeCodeUnspsc190501.50363665': __('identifierSchemeCodeUnspsc190501.50363665'),
	'identifierSchemeCodeUnspsc190501.50363700': __('identifierSchemeCodeUnspsc190501.50363700'),
	'identifierSchemeCodeUnspsc190501.50363701': __('identifierSchemeCodeUnspsc190501.50363701'),
	'identifierSchemeCodeUnspsc190501.50363702': __('identifierSchemeCodeUnspsc190501.50363702'),
	'identifierSchemeCodeUnspsc190501.50363703': __('identifierSchemeCodeUnspsc190501.50363703'),
	'identifierSchemeCodeUnspsc190501.50363704': __('identifierSchemeCodeUnspsc190501.50363704'),
	'identifierSchemeCodeUnspsc190501.50363800': __('identifierSchemeCodeUnspsc190501.50363800'),
	'identifierSchemeCodeUnspsc190501.50363801': __('identifierSchemeCodeUnspsc190501.50363801'),
	'identifierSchemeCodeUnspsc190501.50363802': __('identifierSchemeCodeUnspsc190501.50363802'),
	'identifierSchemeCodeUnspsc190501.50363803': __('identifierSchemeCodeUnspsc190501.50363803'),
	'identifierSchemeCodeUnspsc190501.50363804': __('identifierSchemeCodeUnspsc190501.50363804'),
	'identifierSchemeCodeUnspsc190501.50363805': __('identifierSchemeCodeUnspsc190501.50363805'),
	'identifierSchemeCodeUnspsc190501.50363900': __('identifierSchemeCodeUnspsc190501.50363900'),
	'identifierSchemeCodeUnspsc190501.50363901': __('identifierSchemeCodeUnspsc190501.50363901'),
	'identifierSchemeCodeUnspsc190501.50363902': __('identifierSchemeCodeUnspsc190501.50363902'),
	'identifierSchemeCodeUnspsc190501.50363903': __('identifierSchemeCodeUnspsc190501.50363903'),
	'identifierSchemeCodeUnspsc190501.50363904': __('identifierSchemeCodeUnspsc190501.50363904'),
	'identifierSchemeCodeUnspsc190501.50363905': __('identifierSchemeCodeUnspsc190501.50363905'),
	'identifierSchemeCodeUnspsc190501.50363906': __('identifierSchemeCodeUnspsc190501.50363906'),
	'identifierSchemeCodeUnspsc190501.50364000': __('identifierSchemeCodeUnspsc190501.50364000'),
	'identifierSchemeCodeUnspsc190501.50364001': __('identifierSchemeCodeUnspsc190501.50364001'),
	'identifierSchemeCodeUnspsc190501.50364002': __('identifierSchemeCodeUnspsc190501.50364002'),
	'identifierSchemeCodeUnspsc190501.50364003': __('identifierSchemeCodeUnspsc190501.50364003'),
	'identifierSchemeCodeUnspsc190501.50364004': __('identifierSchemeCodeUnspsc190501.50364004'),
	'identifierSchemeCodeUnspsc190501.50364005': __('identifierSchemeCodeUnspsc190501.50364005'),
	'identifierSchemeCodeUnspsc190501.50364006': __('identifierSchemeCodeUnspsc190501.50364006'),
	'identifierSchemeCodeUnspsc190501.50364100': __('identifierSchemeCodeUnspsc190501.50364100'),
	'identifierSchemeCodeUnspsc190501.50364101': __('identifierSchemeCodeUnspsc190501.50364101'),
	'identifierSchemeCodeUnspsc190501.50364102': __('identifierSchemeCodeUnspsc190501.50364102'),
	'identifierSchemeCodeUnspsc190501.50364103': __('identifierSchemeCodeUnspsc190501.50364103'),
	'identifierSchemeCodeUnspsc190501.50364104': __('identifierSchemeCodeUnspsc190501.50364104'),
	'identifierSchemeCodeUnspsc190501.50364105': __('identifierSchemeCodeUnspsc190501.50364105'),
	'identifierSchemeCodeUnspsc190501.50364106': __('identifierSchemeCodeUnspsc190501.50364106'),
	'identifierSchemeCodeUnspsc190501.50364107': __('identifierSchemeCodeUnspsc190501.50364107'),
	'identifierSchemeCodeUnspsc190501.50364108': __('identifierSchemeCodeUnspsc190501.50364108'),
	'identifierSchemeCodeUnspsc190501.50364200': __('identifierSchemeCodeUnspsc190501.50364200'),
	'identifierSchemeCodeUnspsc190501.50364201': __('identifierSchemeCodeUnspsc190501.50364201'),
	'identifierSchemeCodeUnspsc190501.50364202': __('identifierSchemeCodeUnspsc190501.50364202'),
	'identifierSchemeCodeUnspsc190501.50364203': __('identifierSchemeCodeUnspsc190501.50364203'),
	'identifierSchemeCodeUnspsc190501.50364204': __('identifierSchemeCodeUnspsc190501.50364204'),
	'identifierSchemeCodeUnspsc190501.50364205': __('identifierSchemeCodeUnspsc190501.50364205'),
	'identifierSchemeCodeUnspsc190501.50364206': __('identifierSchemeCodeUnspsc190501.50364206'),
	'identifierSchemeCodeUnspsc190501.50364207': __('identifierSchemeCodeUnspsc190501.50364207'),
	'identifierSchemeCodeUnspsc190501.50364208': __('identifierSchemeCodeUnspsc190501.50364208'),
	'identifierSchemeCodeUnspsc190501.50364300': __('identifierSchemeCodeUnspsc190501.50364300'),
	'identifierSchemeCodeUnspsc190501.50364301': __('identifierSchemeCodeUnspsc190501.50364301'),
	'identifierSchemeCodeUnspsc190501.50364302': __('identifierSchemeCodeUnspsc190501.50364302'),
	'identifierSchemeCodeUnspsc190501.50364303': __('identifierSchemeCodeUnspsc190501.50364303'),
	'identifierSchemeCodeUnspsc190501.50364304': __('identifierSchemeCodeUnspsc190501.50364304'),
	'identifierSchemeCodeUnspsc190501.50364305': __('identifierSchemeCodeUnspsc190501.50364305'),
	'identifierSchemeCodeUnspsc190501.50364306': __('identifierSchemeCodeUnspsc190501.50364306'),
	'identifierSchemeCodeUnspsc190501.50364307': __('identifierSchemeCodeUnspsc190501.50364307'),
	'identifierSchemeCodeUnspsc190501.50364308': __('identifierSchemeCodeUnspsc190501.50364308'),
	'identifierSchemeCodeUnspsc190501.50364309': __('identifierSchemeCodeUnspsc190501.50364309'),
	'identifierSchemeCodeUnspsc190501.50364310': __('identifierSchemeCodeUnspsc190501.50364310'),
	'identifierSchemeCodeUnspsc190501.50364311': __('identifierSchemeCodeUnspsc190501.50364311'),
	'identifierSchemeCodeUnspsc190501.50364312': __('identifierSchemeCodeUnspsc190501.50364312'),
	'identifierSchemeCodeUnspsc190501.50364313': __('identifierSchemeCodeUnspsc190501.50364313'),
	'identifierSchemeCodeUnspsc190501.50364400': __('identifierSchemeCodeUnspsc190501.50364400'),
	'identifierSchemeCodeUnspsc190501.50364401': __('identifierSchemeCodeUnspsc190501.50364401'),
	'identifierSchemeCodeUnspsc190501.50364402': __('identifierSchemeCodeUnspsc190501.50364402'),
	'identifierSchemeCodeUnspsc190501.50364403': __('identifierSchemeCodeUnspsc190501.50364403'),
	'identifierSchemeCodeUnspsc190501.50364404': __('identifierSchemeCodeUnspsc190501.50364404'),
	'identifierSchemeCodeUnspsc190501.50364405': __('identifierSchemeCodeUnspsc190501.50364405'),
	'identifierSchemeCodeUnspsc190501.50364406': __('identifierSchemeCodeUnspsc190501.50364406'),
	'identifierSchemeCodeUnspsc190501.50364407': __('identifierSchemeCodeUnspsc190501.50364407'),
	'identifierSchemeCodeUnspsc190501.50364408': __('identifierSchemeCodeUnspsc190501.50364408'),
	'identifierSchemeCodeUnspsc190501.50364409': __('identifierSchemeCodeUnspsc190501.50364409'),
	'identifierSchemeCodeUnspsc190501.50364410': __('identifierSchemeCodeUnspsc190501.50364410'),
	'identifierSchemeCodeUnspsc190501.50364411': __('identifierSchemeCodeUnspsc190501.50364411'),
	'identifierSchemeCodeUnspsc190501.50364412': __('identifierSchemeCodeUnspsc190501.50364412'),
	'identifierSchemeCodeUnspsc190501.50364413': __('identifierSchemeCodeUnspsc190501.50364413'),
	'identifierSchemeCodeUnspsc190501.50364414': __('identifierSchemeCodeUnspsc190501.50364414'),
	'identifierSchemeCodeUnspsc190501.50364415': __('identifierSchemeCodeUnspsc190501.50364415'),
	'identifierSchemeCodeUnspsc190501.50364416': __('identifierSchemeCodeUnspsc190501.50364416'),
	'identifierSchemeCodeUnspsc190501.50364417': __('identifierSchemeCodeUnspsc190501.50364417'),
	'identifierSchemeCodeUnspsc190501.50364418': __('identifierSchemeCodeUnspsc190501.50364418'),
	'identifierSchemeCodeUnspsc190501.50364419': __('identifierSchemeCodeUnspsc190501.50364419'),
	'identifierSchemeCodeUnspsc190501.50364420': __('identifierSchemeCodeUnspsc190501.50364420'),
	'identifierSchemeCodeUnspsc190501.50364421': __('identifierSchemeCodeUnspsc190501.50364421'),
	'identifierSchemeCodeUnspsc190501.50364422': __('identifierSchemeCodeUnspsc190501.50364422'),
	'identifierSchemeCodeUnspsc190501.50364423': __('identifierSchemeCodeUnspsc190501.50364423'),
	'identifierSchemeCodeUnspsc190501.50364424': __('identifierSchemeCodeUnspsc190501.50364424'),
	'identifierSchemeCodeUnspsc190501.50364425': __('identifierSchemeCodeUnspsc190501.50364425'),
	'identifierSchemeCodeUnspsc190501.50364426': __('identifierSchemeCodeUnspsc190501.50364426'),
	'identifierSchemeCodeUnspsc190501.50364427': __('identifierSchemeCodeUnspsc190501.50364427'),
	'identifierSchemeCodeUnspsc190501.50364428': __('identifierSchemeCodeUnspsc190501.50364428'),
	'identifierSchemeCodeUnspsc190501.50364429': __('identifierSchemeCodeUnspsc190501.50364429'),
	'identifierSchemeCodeUnspsc190501.50364430': __('identifierSchemeCodeUnspsc190501.50364430'),
	'identifierSchemeCodeUnspsc190501.50364431': __('identifierSchemeCodeUnspsc190501.50364431'),
	'identifierSchemeCodeUnspsc190501.50364432': __('identifierSchemeCodeUnspsc190501.50364432'),
	'identifierSchemeCodeUnspsc190501.50364433': __('identifierSchemeCodeUnspsc190501.50364433'),
	'identifierSchemeCodeUnspsc190501.50364434': __('identifierSchemeCodeUnspsc190501.50364434'),
	'identifierSchemeCodeUnspsc190501.50364435': __('identifierSchemeCodeUnspsc190501.50364435'),
	'identifierSchemeCodeUnspsc190501.50364436': __('identifierSchemeCodeUnspsc190501.50364436'),
	'identifierSchemeCodeUnspsc190501.50364437': __('identifierSchemeCodeUnspsc190501.50364437'),
	'identifierSchemeCodeUnspsc190501.50364438': __('identifierSchemeCodeUnspsc190501.50364438'),
	'identifierSchemeCodeUnspsc190501.50364439': __('identifierSchemeCodeUnspsc190501.50364439'),
	'identifierSchemeCodeUnspsc190501.50364440': __('identifierSchemeCodeUnspsc190501.50364440'),
	'identifierSchemeCodeUnspsc190501.50364441': __('identifierSchemeCodeUnspsc190501.50364441'),
	'identifierSchemeCodeUnspsc190501.50364442': __('identifierSchemeCodeUnspsc190501.50364442'),
	'identifierSchemeCodeUnspsc190501.50364443': __('identifierSchemeCodeUnspsc190501.50364443'),
	'identifierSchemeCodeUnspsc190501.50364444': __('identifierSchemeCodeUnspsc190501.50364444'),
	'identifierSchemeCodeUnspsc190501.50364445': __('identifierSchemeCodeUnspsc190501.50364445'),
	'identifierSchemeCodeUnspsc190501.50364446': __('identifierSchemeCodeUnspsc190501.50364446'),
	'identifierSchemeCodeUnspsc190501.50364500': __('identifierSchemeCodeUnspsc190501.50364500'),
	'identifierSchemeCodeUnspsc190501.50364501': __('identifierSchemeCodeUnspsc190501.50364501'),
	'identifierSchemeCodeUnspsc190501.50364502': __('identifierSchemeCodeUnspsc190501.50364502'),
	'identifierSchemeCodeUnspsc190501.50364503': __('identifierSchemeCodeUnspsc190501.50364503'),
	'identifierSchemeCodeUnspsc190501.50364504': __('identifierSchemeCodeUnspsc190501.50364504'),
	'identifierSchemeCodeUnspsc190501.50364505': __('identifierSchemeCodeUnspsc190501.50364505'),
	'identifierSchemeCodeUnspsc190501.50364506': __('identifierSchemeCodeUnspsc190501.50364506'),
	'identifierSchemeCodeUnspsc190501.50364507': __('identifierSchemeCodeUnspsc190501.50364507'),
	'identifierSchemeCodeUnspsc190501.50364508': __('identifierSchemeCodeUnspsc190501.50364508'),
	'identifierSchemeCodeUnspsc190501.50364509': __('identifierSchemeCodeUnspsc190501.50364509'),
	'identifierSchemeCodeUnspsc190501.50364510': __('identifierSchemeCodeUnspsc190501.50364510'),
	'identifierSchemeCodeUnspsc190501.50364511': __('identifierSchemeCodeUnspsc190501.50364511'),
	'identifierSchemeCodeUnspsc190501.50364512': __('identifierSchemeCodeUnspsc190501.50364512'),
	'identifierSchemeCodeUnspsc190501.50364513': __('identifierSchemeCodeUnspsc190501.50364513'),
	'identifierSchemeCodeUnspsc190501.50364514': __('identifierSchemeCodeUnspsc190501.50364514'),
	'identifierSchemeCodeUnspsc190501.50364515': __('identifierSchemeCodeUnspsc190501.50364515'),
	'identifierSchemeCodeUnspsc190501.50364516': __('identifierSchemeCodeUnspsc190501.50364516'),
	'identifierSchemeCodeUnspsc190501.50364517': __('identifierSchemeCodeUnspsc190501.50364517'),
	'identifierSchemeCodeUnspsc190501.50364518': __('identifierSchemeCodeUnspsc190501.50364518'),
	'identifierSchemeCodeUnspsc190501.50364519': __('identifierSchemeCodeUnspsc190501.50364519'),
	'identifierSchemeCodeUnspsc190501.50364520': __('identifierSchemeCodeUnspsc190501.50364520'),
	'identifierSchemeCodeUnspsc190501.50364521': __('identifierSchemeCodeUnspsc190501.50364521'),
	'identifierSchemeCodeUnspsc190501.50364522': __('identifierSchemeCodeUnspsc190501.50364522'),
	'identifierSchemeCodeUnspsc190501.50364523': __('identifierSchemeCodeUnspsc190501.50364523'),
	'identifierSchemeCodeUnspsc190501.50364524': __('identifierSchemeCodeUnspsc190501.50364524'),
	'identifierSchemeCodeUnspsc190501.50364525': __('identifierSchemeCodeUnspsc190501.50364525'),
	'identifierSchemeCodeUnspsc190501.50364600': __('identifierSchemeCodeUnspsc190501.50364600'),
	'identifierSchemeCodeUnspsc190501.50364601': __('identifierSchemeCodeUnspsc190501.50364601'),
	'identifierSchemeCodeUnspsc190501.50364602': __('identifierSchemeCodeUnspsc190501.50364602'),
	'identifierSchemeCodeUnspsc190501.50364603': __('identifierSchemeCodeUnspsc190501.50364603'),
	'identifierSchemeCodeUnspsc190501.50364604': __('identifierSchemeCodeUnspsc190501.50364604'),
	'identifierSchemeCodeUnspsc190501.50364605': __('identifierSchemeCodeUnspsc190501.50364605'),
	'identifierSchemeCodeUnspsc190501.50364606': __('identifierSchemeCodeUnspsc190501.50364606'),
	'identifierSchemeCodeUnspsc190501.50364607': __('identifierSchemeCodeUnspsc190501.50364607'),
	'identifierSchemeCodeUnspsc190501.50364608': __('identifierSchemeCodeUnspsc190501.50364608'),
	'identifierSchemeCodeUnspsc190501.50364609': __('identifierSchemeCodeUnspsc190501.50364609'),
	'identifierSchemeCodeUnspsc190501.50364610': __('identifierSchemeCodeUnspsc190501.50364610'),
	'identifierSchemeCodeUnspsc190501.50364611': __('identifierSchemeCodeUnspsc190501.50364611'),
	'identifierSchemeCodeUnspsc190501.50364612': __('identifierSchemeCodeUnspsc190501.50364612'),
	'identifierSchemeCodeUnspsc190501.50364613': __('identifierSchemeCodeUnspsc190501.50364613'),
	'identifierSchemeCodeUnspsc190501.50364614': __('identifierSchemeCodeUnspsc190501.50364614'),
	'identifierSchemeCodeUnspsc190501.50364615': __('identifierSchemeCodeUnspsc190501.50364615'),
	'identifierSchemeCodeUnspsc190501.50364616': __('identifierSchemeCodeUnspsc190501.50364616'),
	'identifierSchemeCodeUnspsc190501.50364617': __('identifierSchemeCodeUnspsc190501.50364617'),
	'identifierSchemeCodeUnspsc190501.50364618': __('identifierSchemeCodeUnspsc190501.50364618'),
	'identifierSchemeCodeUnspsc190501.50364619': __('identifierSchemeCodeUnspsc190501.50364619'),
	'identifierSchemeCodeUnspsc190501.50364620': __('identifierSchemeCodeUnspsc190501.50364620'),
	'identifierSchemeCodeUnspsc190501.50364621': __('identifierSchemeCodeUnspsc190501.50364621'),
	'identifierSchemeCodeUnspsc190501.50364622': __('identifierSchemeCodeUnspsc190501.50364622'),
	'identifierSchemeCodeUnspsc190501.50364623': __('identifierSchemeCodeUnspsc190501.50364623'),
	'identifierSchemeCodeUnspsc190501.50364624': __('identifierSchemeCodeUnspsc190501.50364624'),
	'identifierSchemeCodeUnspsc190501.50364625': __('identifierSchemeCodeUnspsc190501.50364625'),
	'identifierSchemeCodeUnspsc190501.50364626': __('identifierSchemeCodeUnspsc190501.50364626'),
	'identifierSchemeCodeUnspsc190501.50364627': __('identifierSchemeCodeUnspsc190501.50364627'),
	'identifierSchemeCodeUnspsc190501.50364628': __('identifierSchemeCodeUnspsc190501.50364628'),
	'identifierSchemeCodeUnspsc190501.50364629': __('identifierSchemeCodeUnspsc190501.50364629'),
	'identifierSchemeCodeUnspsc190501.50364630': __('identifierSchemeCodeUnspsc190501.50364630'),
	'identifierSchemeCodeUnspsc190501.50364631': __('identifierSchemeCodeUnspsc190501.50364631'),
	'identifierSchemeCodeUnspsc190501.50364632': __('identifierSchemeCodeUnspsc190501.50364632'),
	'identifierSchemeCodeUnspsc190501.50364633': __('identifierSchemeCodeUnspsc190501.50364633'),
	'identifierSchemeCodeUnspsc190501.50364634': __('identifierSchemeCodeUnspsc190501.50364634'),
	'identifierSchemeCodeUnspsc190501.50364635': __('identifierSchemeCodeUnspsc190501.50364635'),
	'identifierSchemeCodeUnspsc190501.50364636': __('identifierSchemeCodeUnspsc190501.50364636'),
	'identifierSchemeCodeUnspsc190501.50364637': __('identifierSchemeCodeUnspsc190501.50364637'),
	'identifierSchemeCodeUnspsc190501.50364638': __('identifierSchemeCodeUnspsc190501.50364638'),
	'identifierSchemeCodeUnspsc190501.50364639': __('identifierSchemeCodeUnspsc190501.50364639'),
	'identifierSchemeCodeUnspsc190501.50364640': __('identifierSchemeCodeUnspsc190501.50364640'),
	'identifierSchemeCodeUnspsc190501.50364641': __('identifierSchemeCodeUnspsc190501.50364641'),
	'identifierSchemeCodeUnspsc190501.50364642': __('identifierSchemeCodeUnspsc190501.50364642'),
	'identifierSchemeCodeUnspsc190501.50364643': __('identifierSchemeCodeUnspsc190501.50364643'),
	'identifierSchemeCodeUnspsc190501.50364644': __('identifierSchemeCodeUnspsc190501.50364644'),
	'identifierSchemeCodeUnspsc190501.50364645': __('identifierSchemeCodeUnspsc190501.50364645'),
	'identifierSchemeCodeUnspsc190501.50364646': __('identifierSchemeCodeUnspsc190501.50364646'),
	'identifierSchemeCodeUnspsc190501.50364647': __('identifierSchemeCodeUnspsc190501.50364647'),
	'identifierSchemeCodeUnspsc190501.50364648': __('identifierSchemeCodeUnspsc190501.50364648'),
	'identifierSchemeCodeUnspsc190501.50364649': __('identifierSchemeCodeUnspsc190501.50364649'),
	'identifierSchemeCodeUnspsc190501.50364650': __('identifierSchemeCodeUnspsc190501.50364650'),
	'identifierSchemeCodeUnspsc190501.50364651': __('identifierSchemeCodeUnspsc190501.50364651'),
	'identifierSchemeCodeUnspsc190501.50364652': __('identifierSchemeCodeUnspsc190501.50364652'),
	'identifierSchemeCodeUnspsc190501.50364653': __('identifierSchemeCodeUnspsc190501.50364653'),
	'identifierSchemeCodeUnspsc190501.50364654': __('identifierSchemeCodeUnspsc190501.50364654'),
	'identifierSchemeCodeUnspsc190501.50364655': __('identifierSchemeCodeUnspsc190501.50364655'),
	'identifierSchemeCodeUnspsc190501.50364656': __('identifierSchemeCodeUnspsc190501.50364656'),
	'identifierSchemeCodeUnspsc190501.50364657': __('identifierSchemeCodeUnspsc190501.50364657'),
	'identifierSchemeCodeUnspsc190501.50364658': __('identifierSchemeCodeUnspsc190501.50364658'),
	'identifierSchemeCodeUnspsc190501.50364659': __('identifierSchemeCodeUnspsc190501.50364659'),
	'identifierSchemeCodeUnspsc190501.50364660': __('identifierSchemeCodeUnspsc190501.50364660'),
	'identifierSchemeCodeUnspsc190501.50364661': __('identifierSchemeCodeUnspsc190501.50364661'),
	'identifierSchemeCodeUnspsc190501.50364700': __('identifierSchemeCodeUnspsc190501.50364700'),
	'identifierSchemeCodeUnspsc190501.50364701': __('identifierSchemeCodeUnspsc190501.50364701'),
	'identifierSchemeCodeUnspsc190501.50364702': __('identifierSchemeCodeUnspsc190501.50364702'),
	'identifierSchemeCodeUnspsc190501.50364800': __('identifierSchemeCodeUnspsc190501.50364800'),
	'identifierSchemeCodeUnspsc190501.50364801': __('identifierSchemeCodeUnspsc190501.50364801'),
	'identifierSchemeCodeUnspsc190501.50364802': __('identifierSchemeCodeUnspsc190501.50364802'),
	'identifierSchemeCodeUnspsc190501.50364900': __('identifierSchemeCodeUnspsc190501.50364900'),
	'identifierSchemeCodeUnspsc190501.50364901': __('identifierSchemeCodeUnspsc190501.50364901'),
	'identifierSchemeCodeUnspsc190501.50364902': __('identifierSchemeCodeUnspsc190501.50364902'),
	'identifierSchemeCodeUnspsc190501.50364903': __('identifierSchemeCodeUnspsc190501.50364903'),
	'identifierSchemeCodeUnspsc190501.50364904': __('identifierSchemeCodeUnspsc190501.50364904'),
	'identifierSchemeCodeUnspsc190501.50364905': __('identifierSchemeCodeUnspsc190501.50364905'),
	'identifierSchemeCodeUnspsc190501.50364906': __('identifierSchemeCodeUnspsc190501.50364906'),
	'identifierSchemeCodeUnspsc190501.50364907': __('identifierSchemeCodeUnspsc190501.50364907'),
	'identifierSchemeCodeUnspsc190501.50364908': __('identifierSchemeCodeUnspsc190501.50364908'),
	'identifierSchemeCodeUnspsc190501.50364909': __('identifierSchemeCodeUnspsc190501.50364909'),
	'identifierSchemeCodeUnspsc190501.50364910': __('identifierSchemeCodeUnspsc190501.50364910'),
	'identifierSchemeCodeUnspsc190501.50364911': __('identifierSchemeCodeUnspsc190501.50364911'),
	'identifierSchemeCodeUnspsc190501.50364912': __('identifierSchemeCodeUnspsc190501.50364912'),
	'identifierSchemeCodeUnspsc190501.50364913': __('identifierSchemeCodeUnspsc190501.50364913'),
	'identifierSchemeCodeUnspsc190501.50364914': __('identifierSchemeCodeUnspsc190501.50364914'),
	'identifierSchemeCodeUnspsc190501.50364915': __('identifierSchemeCodeUnspsc190501.50364915'),
	'identifierSchemeCodeUnspsc190501.50364916': __('identifierSchemeCodeUnspsc190501.50364916'),
	'identifierSchemeCodeUnspsc190501.50364917': __('identifierSchemeCodeUnspsc190501.50364917'),
	'identifierSchemeCodeUnspsc190501.50364918': __('identifierSchemeCodeUnspsc190501.50364918'),
	'identifierSchemeCodeUnspsc190501.50364919': __('identifierSchemeCodeUnspsc190501.50364919'),
	'identifierSchemeCodeUnspsc190501.50364920': __('identifierSchemeCodeUnspsc190501.50364920'),
	'identifierSchemeCodeUnspsc190501.50364921': __('identifierSchemeCodeUnspsc190501.50364921'),
	'identifierSchemeCodeUnspsc190501.50364922': __('identifierSchemeCodeUnspsc190501.50364922'),
	'identifierSchemeCodeUnspsc190501.50364923': __('identifierSchemeCodeUnspsc190501.50364923'),
	'identifierSchemeCodeUnspsc190501.50364924': __('identifierSchemeCodeUnspsc190501.50364924'),
	'identifierSchemeCodeUnspsc190501.50364925': __('identifierSchemeCodeUnspsc190501.50364925'),
	'identifierSchemeCodeUnspsc190501.50364926': __('identifierSchemeCodeUnspsc190501.50364926'),
	'identifierSchemeCodeUnspsc190501.50364927': __('identifierSchemeCodeUnspsc190501.50364927'),
	'identifierSchemeCodeUnspsc190501.50364928': __('identifierSchemeCodeUnspsc190501.50364928'),
	'identifierSchemeCodeUnspsc190501.50364929': __('identifierSchemeCodeUnspsc190501.50364929'),
	'identifierSchemeCodeUnspsc190501.50364930': __('identifierSchemeCodeUnspsc190501.50364930'),
	'identifierSchemeCodeUnspsc190501.50364931': __('identifierSchemeCodeUnspsc190501.50364931'),
	'identifierSchemeCodeUnspsc190501.50364932': __('identifierSchemeCodeUnspsc190501.50364932'),
	'identifierSchemeCodeUnspsc190501.50364933': __('identifierSchemeCodeUnspsc190501.50364933'),
	'identifierSchemeCodeUnspsc190501.50364934': __('identifierSchemeCodeUnspsc190501.50364934'),
	'identifierSchemeCodeUnspsc190501.50364935': __('identifierSchemeCodeUnspsc190501.50364935'),
	'identifierSchemeCodeUnspsc190501.50364936': __('identifierSchemeCodeUnspsc190501.50364936'),
	'identifierSchemeCodeUnspsc190501.50364937': __('identifierSchemeCodeUnspsc190501.50364937'),
	'identifierSchemeCodeUnspsc190501.50364938': __('identifierSchemeCodeUnspsc190501.50364938'),
	'identifierSchemeCodeUnspsc190501.50364939': __('identifierSchemeCodeUnspsc190501.50364939'),
	'identifierSchemeCodeUnspsc190501.50364940': __('identifierSchemeCodeUnspsc190501.50364940'),
	'identifierSchemeCodeUnspsc190501.50364941': __('identifierSchemeCodeUnspsc190501.50364941'),
	'identifierSchemeCodeUnspsc190501.50364942': __('identifierSchemeCodeUnspsc190501.50364942'),
	'identifierSchemeCodeUnspsc190501.50364943': __('identifierSchemeCodeUnspsc190501.50364943'),
	'identifierSchemeCodeUnspsc190501.50364944': __('identifierSchemeCodeUnspsc190501.50364944'),
	'identifierSchemeCodeUnspsc190501.50364945': __('identifierSchemeCodeUnspsc190501.50364945'),
	'identifierSchemeCodeUnspsc190501.50364946': __('identifierSchemeCodeUnspsc190501.50364946'),
	'identifierSchemeCodeUnspsc190501.50364947': __('identifierSchemeCodeUnspsc190501.50364947'),
	'identifierSchemeCodeUnspsc190501.50364948': __('identifierSchemeCodeUnspsc190501.50364948'),
	'identifierSchemeCodeUnspsc190501.50364949': __('identifierSchemeCodeUnspsc190501.50364949'),
	'identifierSchemeCodeUnspsc190501.50364950': __('identifierSchemeCodeUnspsc190501.50364950'),
	'identifierSchemeCodeUnspsc190501.50364951': __('identifierSchemeCodeUnspsc190501.50364951'),
	'identifierSchemeCodeUnspsc190501.50364952': __('identifierSchemeCodeUnspsc190501.50364952'),
	'identifierSchemeCodeUnspsc190501.50364953': __('identifierSchemeCodeUnspsc190501.50364953'),
	'identifierSchemeCodeUnspsc190501.50364954': __('identifierSchemeCodeUnspsc190501.50364954'),
	'identifierSchemeCodeUnspsc190501.50364955': __('identifierSchemeCodeUnspsc190501.50364955'),
	'identifierSchemeCodeUnspsc190501.50364956': __('identifierSchemeCodeUnspsc190501.50364956'),
	'identifierSchemeCodeUnspsc190501.50364957': __('identifierSchemeCodeUnspsc190501.50364957'),
	'identifierSchemeCodeUnspsc190501.50365000': __('identifierSchemeCodeUnspsc190501.50365000'),
	'identifierSchemeCodeUnspsc190501.50365001': __('identifierSchemeCodeUnspsc190501.50365001'),
	'identifierSchemeCodeUnspsc190501.50365002': __('identifierSchemeCodeUnspsc190501.50365002'),
	'identifierSchemeCodeUnspsc190501.50365003': __('identifierSchemeCodeUnspsc190501.50365003'),
	'identifierSchemeCodeUnspsc190501.50365004': __('identifierSchemeCodeUnspsc190501.50365004'),
	'identifierSchemeCodeUnspsc190501.50365005': __('identifierSchemeCodeUnspsc190501.50365005'),
	'identifierSchemeCodeUnspsc190501.50365006': __('identifierSchemeCodeUnspsc190501.50365006'),
	'identifierSchemeCodeUnspsc190501.50365007': __('identifierSchemeCodeUnspsc190501.50365007'),
	'identifierSchemeCodeUnspsc190501.50365008': __('identifierSchemeCodeUnspsc190501.50365008'),
	'identifierSchemeCodeUnspsc190501.50365009': __('identifierSchemeCodeUnspsc190501.50365009'),
	'identifierSchemeCodeUnspsc190501.50365010': __('identifierSchemeCodeUnspsc190501.50365010'),
	'identifierSchemeCodeUnspsc190501.50365011': __('identifierSchemeCodeUnspsc190501.50365011'),
	'identifierSchemeCodeUnspsc190501.50365012': __('identifierSchemeCodeUnspsc190501.50365012'),
	'identifierSchemeCodeUnspsc190501.50365013': __('identifierSchemeCodeUnspsc190501.50365013'),
	'identifierSchemeCodeUnspsc190501.50365014': __('identifierSchemeCodeUnspsc190501.50365014'),
	'identifierSchemeCodeUnspsc190501.50365015': __('identifierSchemeCodeUnspsc190501.50365015'),
	'identifierSchemeCodeUnspsc190501.50365016': __('identifierSchemeCodeUnspsc190501.50365016'),
	'identifierSchemeCodeUnspsc190501.50365017': __('identifierSchemeCodeUnspsc190501.50365017'),
	'identifierSchemeCodeUnspsc190501.50365018': __('identifierSchemeCodeUnspsc190501.50365018'),
	'identifierSchemeCodeUnspsc190501.50365019': __('identifierSchemeCodeUnspsc190501.50365019'),
	'identifierSchemeCodeUnspsc190501.50365020': __('identifierSchemeCodeUnspsc190501.50365020'),
	'identifierSchemeCodeUnspsc190501.50365021': __('identifierSchemeCodeUnspsc190501.50365021'),
	'identifierSchemeCodeUnspsc190501.50365022': __('identifierSchemeCodeUnspsc190501.50365022'),
	'identifierSchemeCodeUnspsc190501.50365023': __('identifierSchemeCodeUnspsc190501.50365023'),
	'identifierSchemeCodeUnspsc190501.50365024': __('identifierSchemeCodeUnspsc190501.50365024'),
	'identifierSchemeCodeUnspsc190501.50365025': __('identifierSchemeCodeUnspsc190501.50365025'),
	'identifierSchemeCodeUnspsc190501.50365026': __('identifierSchemeCodeUnspsc190501.50365026'),
	'identifierSchemeCodeUnspsc190501.50365027': __('identifierSchemeCodeUnspsc190501.50365027'),
	'identifierSchemeCodeUnspsc190501.50365028': __('identifierSchemeCodeUnspsc190501.50365028'),
	'identifierSchemeCodeUnspsc190501.50365029': __('identifierSchemeCodeUnspsc190501.50365029'),
	'identifierSchemeCodeUnspsc190501.50365030': __('identifierSchemeCodeUnspsc190501.50365030'),
	'identifierSchemeCodeUnspsc190501.50365031': __('identifierSchemeCodeUnspsc190501.50365031'),
	'identifierSchemeCodeUnspsc190501.50365032': __('identifierSchemeCodeUnspsc190501.50365032'),
	'identifierSchemeCodeUnspsc190501.50365033': __('identifierSchemeCodeUnspsc190501.50365033'),
	'identifierSchemeCodeUnspsc190501.50365034': __('identifierSchemeCodeUnspsc190501.50365034'),
	'identifierSchemeCodeUnspsc190501.50365035': __('identifierSchemeCodeUnspsc190501.50365035'),
	'identifierSchemeCodeUnspsc190501.50365036': __('identifierSchemeCodeUnspsc190501.50365036'),
	'identifierSchemeCodeUnspsc190501.50365037': __('identifierSchemeCodeUnspsc190501.50365037'),
	'identifierSchemeCodeUnspsc190501.50365038': __('identifierSchemeCodeUnspsc190501.50365038'),
	'identifierSchemeCodeUnspsc190501.50365039': __('identifierSchemeCodeUnspsc190501.50365039'),
	'identifierSchemeCodeUnspsc190501.50365040': __('identifierSchemeCodeUnspsc190501.50365040'),
	'identifierSchemeCodeUnspsc190501.50365041': __('identifierSchemeCodeUnspsc190501.50365041'),
	'identifierSchemeCodeUnspsc190501.50365042': __('identifierSchemeCodeUnspsc190501.50365042'),
	'identifierSchemeCodeUnspsc190501.50365043': __('identifierSchemeCodeUnspsc190501.50365043'),
	'identifierSchemeCodeUnspsc190501.50365044': __('identifierSchemeCodeUnspsc190501.50365044'),
	'identifierSchemeCodeUnspsc190501.50365100': __('identifierSchemeCodeUnspsc190501.50365100'),
	'identifierSchemeCodeUnspsc190501.50365101': __('identifierSchemeCodeUnspsc190501.50365101'),
	'identifierSchemeCodeUnspsc190501.50365102': __('identifierSchemeCodeUnspsc190501.50365102'),
	'identifierSchemeCodeUnspsc190501.50365103': __('identifierSchemeCodeUnspsc190501.50365103'),
	'identifierSchemeCodeUnspsc190501.50365104': __('identifierSchemeCodeUnspsc190501.50365104'),
	'identifierSchemeCodeUnspsc190501.50365105': __('identifierSchemeCodeUnspsc190501.50365105'),
	'identifierSchemeCodeUnspsc190501.50365106': __('identifierSchemeCodeUnspsc190501.50365106'),
	'identifierSchemeCodeUnspsc190501.50365200': __('identifierSchemeCodeUnspsc190501.50365200'),
	'identifierSchemeCodeUnspsc190501.50365201': __('identifierSchemeCodeUnspsc190501.50365201'),
	'identifierSchemeCodeUnspsc190501.50365202': __('identifierSchemeCodeUnspsc190501.50365202'),
	'identifierSchemeCodeUnspsc190501.50365203': __('identifierSchemeCodeUnspsc190501.50365203'),
	'identifierSchemeCodeUnspsc190501.50365204': __('identifierSchemeCodeUnspsc190501.50365204'),
	'identifierSchemeCodeUnspsc190501.50365205': __('identifierSchemeCodeUnspsc190501.50365205'),
	'identifierSchemeCodeUnspsc190501.50365206': __('identifierSchemeCodeUnspsc190501.50365206'),
	'identifierSchemeCodeUnspsc190501.50365207': __('identifierSchemeCodeUnspsc190501.50365207'),
	'identifierSchemeCodeUnspsc190501.50365208': __('identifierSchemeCodeUnspsc190501.50365208'),
	'identifierSchemeCodeUnspsc190501.50365209': __('identifierSchemeCodeUnspsc190501.50365209'),
	'identifierSchemeCodeUnspsc190501.50365210': __('identifierSchemeCodeUnspsc190501.50365210'),
	'identifierSchemeCodeUnspsc190501.50365300': __('identifierSchemeCodeUnspsc190501.50365300'),
	'identifierSchemeCodeUnspsc190501.50365301': __('identifierSchemeCodeUnspsc190501.50365301'),
	'identifierSchemeCodeUnspsc190501.50365302': __('identifierSchemeCodeUnspsc190501.50365302'),
	'identifierSchemeCodeUnspsc190501.50365303': __('identifierSchemeCodeUnspsc190501.50365303'),
	'identifierSchemeCodeUnspsc190501.50365304': __('identifierSchemeCodeUnspsc190501.50365304'),
	'identifierSchemeCodeUnspsc190501.50365305': __('identifierSchemeCodeUnspsc190501.50365305'),
	'identifierSchemeCodeUnspsc190501.50365306': __('identifierSchemeCodeUnspsc190501.50365306'),
	'identifierSchemeCodeUnspsc190501.50365307': __('identifierSchemeCodeUnspsc190501.50365307'),
	'identifierSchemeCodeUnspsc190501.50365308': __('identifierSchemeCodeUnspsc190501.50365308'),
	'identifierSchemeCodeUnspsc190501.50365309': __('identifierSchemeCodeUnspsc190501.50365309'),
	'identifierSchemeCodeUnspsc190501.50365310': __('identifierSchemeCodeUnspsc190501.50365310'),
	'identifierSchemeCodeUnspsc190501.50365311': __('identifierSchemeCodeUnspsc190501.50365311'),
	'identifierSchemeCodeUnspsc190501.50365312': __('identifierSchemeCodeUnspsc190501.50365312'),
	'identifierSchemeCodeUnspsc190501.50365313': __('identifierSchemeCodeUnspsc190501.50365313'),
	'identifierSchemeCodeUnspsc190501.50365314': __('identifierSchemeCodeUnspsc190501.50365314'),
	'identifierSchemeCodeUnspsc190501.50365315': __('identifierSchemeCodeUnspsc190501.50365315'),
	'identifierSchemeCodeUnspsc190501.50365316': __('identifierSchemeCodeUnspsc190501.50365316'),
	'identifierSchemeCodeUnspsc190501.50365317': __('identifierSchemeCodeUnspsc190501.50365317'),
	'identifierSchemeCodeUnspsc190501.50365318': __('identifierSchemeCodeUnspsc190501.50365318'),
	'identifierSchemeCodeUnspsc190501.50365319': __('identifierSchemeCodeUnspsc190501.50365319'),
	'identifierSchemeCodeUnspsc190501.50365320': __('identifierSchemeCodeUnspsc190501.50365320'),
	'identifierSchemeCodeUnspsc190501.50365321': __('identifierSchemeCodeUnspsc190501.50365321'),
	'identifierSchemeCodeUnspsc190501.50365322': __('identifierSchemeCodeUnspsc190501.50365322'),
	'identifierSchemeCodeUnspsc190501.50365323': __('identifierSchemeCodeUnspsc190501.50365323'),
	'identifierSchemeCodeUnspsc190501.50365324': __('identifierSchemeCodeUnspsc190501.50365324'),
	'identifierSchemeCodeUnspsc190501.50365325': __('identifierSchemeCodeUnspsc190501.50365325'),
	'identifierSchemeCodeUnspsc190501.50365326': __('identifierSchemeCodeUnspsc190501.50365326'),
	'identifierSchemeCodeUnspsc190501.50365327': __('identifierSchemeCodeUnspsc190501.50365327'),
	'identifierSchemeCodeUnspsc190501.50365328': __('identifierSchemeCodeUnspsc190501.50365328'),
	'identifierSchemeCodeUnspsc190501.50365329': __('identifierSchemeCodeUnspsc190501.50365329'),
	'identifierSchemeCodeUnspsc190501.50365330': __('identifierSchemeCodeUnspsc190501.50365330'),
	'identifierSchemeCodeUnspsc190501.50365331': __('identifierSchemeCodeUnspsc190501.50365331'),
	'identifierSchemeCodeUnspsc190501.50365332': __('identifierSchemeCodeUnspsc190501.50365332'),
	'identifierSchemeCodeUnspsc190501.50365333': __('identifierSchemeCodeUnspsc190501.50365333'),
	'identifierSchemeCodeUnspsc190501.50365334': __('identifierSchemeCodeUnspsc190501.50365334'),
	'identifierSchemeCodeUnspsc190501.50365335': __('identifierSchemeCodeUnspsc190501.50365335'),
	'identifierSchemeCodeUnspsc190501.50365336': __('identifierSchemeCodeUnspsc190501.50365336'),
	'identifierSchemeCodeUnspsc190501.50365337': __('identifierSchemeCodeUnspsc190501.50365337'),
	'identifierSchemeCodeUnspsc190501.50365338': __('identifierSchemeCodeUnspsc190501.50365338'),
	'identifierSchemeCodeUnspsc190501.50365339': __('identifierSchemeCodeUnspsc190501.50365339'),
	'identifierSchemeCodeUnspsc190501.50365340': __('identifierSchemeCodeUnspsc190501.50365340'),
	'identifierSchemeCodeUnspsc190501.50365341': __('identifierSchemeCodeUnspsc190501.50365341'),
	'identifierSchemeCodeUnspsc190501.50365342': __('identifierSchemeCodeUnspsc190501.50365342'),
	'identifierSchemeCodeUnspsc190501.50365343': __('identifierSchemeCodeUnspsc190501.50365343'),
	'identifierSchemeCodeUnspsc190501.50365344': __('identifierSchemeCodeUnspsc190501.50365344'),
	'identifierSchemeCodeUnspsc190501.50365345': __('identifierSchemeCodeUnspsc190501.50365345'),
	'identifierSchemeCodeUnspsc190501.50365346': __('identifierSchemeCodeUnspsc190501.50365346'),
	'identifierSchemeCodeUnspsc190501.50365347': __('identifierSchemeCodeUnspsc190501.50365347'),
	'identifierSchemeCodeUnspsc190501.50365348': __('identifierSchemeCodeUnspsc190501.50365348'),
	'identifierSchemeCodeUnspsc190501.50365349': __('identifierSchemeCodeUnspsc190501.50365349'),
	'identifierSchemeCodeUnspsc190501.50365350': __('identifierSchemeCodeUnspsc190501.50365350'),
	'identifierSchemeCodeUnspsc190501.50365351': __('identifierSchemeCodeUnspsc190501.50365351'),
	'identifierSchemeCodeUnspsc190501.50365352': __('identifierSchemeCodeUnspsc190501.50365352'),
	'identifierSchemeCodeUnspsc190501.50365353': __('identifierSchemeCodeUnspsc190501.50365353'),
	'identifierSchemeCodeUnspsc190501.50365354': __('identifierSchemeCodeUnspsc190501.50365354'),
	'identifierSchemeCodeUnspsc190501.50365355': __('identifierSchemeCodeUnspsc190501.50365355'),
	'identifierSchemeCodeUnspsc190501.50365356': __('identifierSchemeCodeUnspsc190501.50365356'),
	'identifierSchemeCodeUnspsc190501.50365357': __('identifierSchemeCodeUnspsc190501.50365357'),
	'identifierSchemeCodeUnspsc190501.50365358': __('identifierSchemeCodeUnspsc190501.50365358'),
	'identifierSchemeCodeUnspsc190501.50365359': __('identifierSchemeCodeUnspsc190501.50365359'),
	'identifierSchemeCodeUnspsc190501.50365360': __('identifierSchemeCodeUnspsc190501.50365360'),
	'identifierSchemeCodeUnspsc190501.50365361': __('identifierSchemeCodeUnspsc190501.50365361'),
	'identifierSchemeCodeUnspsc190501.50365362': __('identifierSchemeCodeUnspsc190501.50365362'),
	'identifierSchemeCodeUnspsc190501.50365363': __('identifierSchemeCodeUnspsc190501.50365363'),
	'identifierSchemeCodeUnspsc190501.50365364': __('identifierSchemeCodeUnspsc190501.50365364'),
	'identifierSchemeCodeUnspsc190501.50365365': __('identifierSchemeCodeUnspsc190501.50365365'),
	'identifierSchemeCodeUnspsc190501.50365366': __('identifierSchemeCodeUnspsc190501.50365366'),
	'identifierSchemeCodeUnspsc190501.50365367': __('identifierSchemeCodeUnspsc190501.50365367'),
	'identifierSchemeCodeUnspsc190501.50365368': __('identifierSchemeCodeUnspsc190501.50365368'),
	'identifierSchemeCodeUnspsc190501.50365369': __('identifierSchemeCodeUnspsc190501.50365369'),
	'identifierSchemeCodeUnspsc190501.50365370': __('identifierSchemeCodeUnspsc190501.50365370'),
	'identifierSchemeCodeUnspsc190501.50365371': __('identifierSchemeCodeUnspsc190501.50365371'),
	'identifierSchemeCodeUnspsc190501.50365400': __('identifierSchemeCodeUnspsc190501.50365400'),
	'identifierSchemeCodeUnspsc190501.50365401': __('identifierSchemeCodeUnspsc190501.50365401'),
	'identifierSchemeCodeUnspsc190501.50365402': __('identifierSchemeCodeUnspsc190501.50365402'),
	'identifierSchemeCodeUnspsc190501.50365403': __('identifierSchemeCodeUnspsc190501.50365403'),
	'identifierSchemeCodeUnspsc190501.50365404': __('identifierSchemeCodeUnspsc190501.50365404'),
	'identifierSchemeCodeUnspsc190501.50365405': __('identifierSchemeCodeUnspsc190501.50365405'),
	'identifierSchemeCodeUnspsc190501.50365406': __('identifierSchemeCodeUnspsc190501.50365406'),
	'identifierSchemeCodeUnspsc190501.50365407': __('identifierSchemeCodeUnspsc190501.50365407'),
	'identifierSchemeCodeUnspsc190501.50365408': __('identifierSchemeCodeUnspsc190501.50365408'),
	'identifierSchemeCodeUnspsc190501.50365409': __('identifierSchemeCodeUnspsc190501.50365409'),
	'identifierSchemeCodeUnspsc190501.50365410': __('identifierSchemeCodeUnspsc190501.50365410'),
	'identifierSchemeCodeUnspsc190501.50365411': __('identifierSchemeCodeUnspsc190501.50365411'),
	'identifierSchemeCodeUnspsc190501.50365412': __('identifierSchemeCodeUnspsc190501.50365412'),
	'identifierSchemeCodeUnspsc190501.50365413': __('identifierSchemeCodeUnspsc190501.50365413'),
	'identifierSchemeCodeUnspsc190501.50365414': __('identifierSchemeCodeUnspsc190501.50365414'),
	'identifierSchemeCodeUnspsc190501.50365415': __('identifierSchemeCodeUnspsc190501.50365415'),
	'identifierSchemeCodeUnspsc190501.50365416': __('identifierSchemeCodeUnspsc190501.50365416'),
	'identifierSchemeCodeUnspsc190501.50365417': __('identifierSchemeCodeUnspsc190501.50365417'),
	'identifierSchemeCodeUnspsc190501.50365418': __('identifierSchemeCodeUnspsc190501.50365418'),
	'identifierSchemeCodeUnspsc190501.50365419': __('identifierSchemeCodeUnspsc190501.50365419'),
	'identifierSchemeCodeUnspsc190501.50365420': __('identifierSchemeCodeUnspsc190501.50365420'),
	'identifierSchemeCodeUnspsc190501.50365421': __('identifierSchemeCodeUnspsc190501.50365421'),
	'identifierSchemeCodeUnspsc190501.50365422': __('identifierSchemeCodeUnspsc190501.50365422'),
	'identifierSchemeCodeUnspsc190501.50365423': __('identifierSchemeCodeUnspsc190501.50365423'),
	'identifierSchemeCodeUnspsc190501.50365424': __('identifierSchemeCodeUnspsc190501.50365424'),
	'identifierSchemeCodeUnspsc190501.50365425': __('identifierSchemeCodeUnspsc190501.50365425'),
	'identifierSchemeCodeUnspsc190501.50365426': __('identifierSchemeCodeUnspsc190501.50365426'),
	'identifierSchemeCodeUnspsc190501.50365427': __('identifierSchemeCodeUnspsc190501.50365427'),
	'identifierSchemeCodeUnspsc190501.50365428': __('identifierSchemeCodeUnspsc190501.50365428'),
	'identifierSchemeCodeUnspsc190501.50365429': __('identifierSchemeCodeUnspsc190501.50365429'),
	'identifierSchemeCodeUnspsc190501.50365430': __('identifierSchemeCodeUnspsc190501.50365430'),
	'identifierSchemeCodeUnspsc190501.50365431': __('identifierSchemeCodeUnspsc190501.50365431'),
	'identifierSchemeCodeUnspsc190501.50365432': __('identifierSchemeCodeUnspsc190501.50365432'),
	'identifierSchemeCodeUnspsc190501.50365433': __('identifierSchemeCodeUnspsc190501.50365433'),
	'identifierSchemeCodeUnspsc190501.50365434': __('identifierSchemeCodeUnspsc190501.50365434'),
	'identifierSchemeCodeUnspsc190501.50365435': __('identifierSchemeCodeUnspsc190501.50365435'),
	'identifierSchemeCodeUnspsc190501.50365436': __('identifierSchemeCodeUnspsc190501.50365436'),
	'identifierSchemeCodeUnspsc190501.50365437': __('identifierSchemeCodeUnspsc190501.50365437'),
	'identifierSchemeCodeUnspsc190501.50365438': __('identifierSchemeCodeUnspsc190501.50365438'),
	'identifierSchemeCodeUnspsc190501.50365439': __('identifierSchemeCodeUnspsc190501.50365439'),
	'identifierSchemeCodeUnspsc190501.50365440': __('identifierSchemeCodeUnspsc190501.50365440'),
	'identifierSchemeCodeUnspsc190501.50365441': __('identifierSchemeCodeUnspsc190501.50365441'),
	'identifierSchemeCodeUnspsc190501.50365442': __('identifierSchemeCodeUnspsc190501.50365442'),
	'identifierSchemeCodeUnspsc190501.50365443': __('identifierSchemeCodeUnspsc190501.50365443'),
	'identifierSchemeCodeUnspsc190501.50365444': __('identifierSchemeCodeUnspsc190501.50365444'),
	'identifierSchemeCodeUnspsc190501.50365445': __('identifierSchemeCodeUnspsc190501.50365445'),
	'identifierSchemeCodeUnspsc190501.50365446': __('identifierSchemeCodeUnspsc190501.50365446'),
	'identifierSchemeCodeUnspsc190501.50365447': __('identifierSchemeCodeUnspsc190501.50365447'),
	'identifierSchemeCodeUnspsc190501.50365448': __('identifierSchemeCodeUnspsc190501.50365448'),
	'identifierSchemeCodeUnspsc190501.50365449': __('identifierSchemeCodeUnspsc190501.50365449'),
	'identifierSchemeCodeUnspsc190501.50365450': __('identifierSchemeCodeUnspsc190501.50365450'),
	'identifierSchemeCodeUnspsc190501.50365451': __('identifierSchemeCodeUnspsc190501.50365451'),
	'identifierSchemeCodeUnspsc190501.50365452': __('identifierSchemeCodeUnspsc190501.50365452'),
	'identifierSchemeCodeUnspsc190501.50365453': __('identifierSchemeCodeUnspsc190501.50365453'),
	'identifierSchemeCodeUnspsc190501.50365454': __('identifierSchemeCodeUnspsc190501.50365454'),
	'identifierSchemeCodeUnspsc190501.50365455': __('identifierSchemeCodeUnspsc190501.50365455'),
	'identifierSchemeCodeUnspsc190501.50365456': __('identifierSchemeCodeUnspsc190501.50365456'),
	'identifierSchemeCodeUnspsc190501.50365457': __('identifierSchemeCodeUnspsc190501.50365457'),
	'identifierSchemeCodeUnspsc190501.50365458': __('identifierSchemeCodeUnspsc190501.50365458'),
	'identifierSchemeCodeUnspsc190501.50365459': __('identifierSchemeCodeUnspsc190501.50365459'),
	'identifierSchemeCodeUnspsc190501.50365460': __('identifierSchemeCodeUnspsc190501.50365460'),
	'identifierSchemeCodeUnspsc190501.50365500': __('identifierSchemeCodeUnspsc190501.50365500'),
	'identifierSchemeCodeUnspsc190501.50365501': __('identifierSchemeCodeUnspsc190501.50365501'),
	'identifierSchemeCodeUnspsc190501.50365502': __('identifierSchemeCodeUnspsc190501.50365502'),
	'identifierSchemeCodeUnspsc190501.50365503': __('identifierSchemeCodeUnspsc190501.50365503'),
	'identifierSchemeCodeUnspsc190501.50365504': __('identifierSchemeCodeUnspsc190501.50365504'),
	'identifierSchemeCodeUnspsc190501.50365505': __('identifierSchemeCodeUnspsc190501.50365505'),
	'identifierSchemeCodeUnspsc190501.50365506': __('identifierSchemeCodeUnspsc190501.50365506'),
	'identifierSchemeCodeUnspsc190501.50365507': __('identifierSchemeCodeUnspsc190501.50365507'),
	'identifierSchemeCodeUnspsc190501.50365508': __('identifierSchemeCodeUnspsc190501.50365508'),
	'identifierSchemeCodeUnspsc190501.50365509': __('identifierSchemeCodeUnspsc190501.50365509'),
	'identifierSchemeCodeUnspsc190501.50365510': __('identifierSchemeCodeUnspsc190501.50365510'),
	'identifierSchemeCodeUnspsc190501.50365511': __('identifierSchemeCodeUnspsc190501.50365511'),
	'identifierSchemeCodeUnspsc190501.50365512': __('identifierSchemeCodeUnspsc190501.50365512'),
	'identifierSchemeCodeUnspsc190501.50365600': __('identifierSchemeCodeUnspsc190501.50365600'),
	'identifierSchemeCodeUnspsc190501.50365601': __('identifierSchemeCodeUnspsc190501.50365601'),
	'identifierSchemeCodeUnspsc190501.50365602': __('identifierSchemeCodeUnspsc190501.50365602'),
	'identifierSchemeCodeUnspsc190501.50365603': __('identifierSchemeCodeUnspsc190501.50365603'),
	'identifierSchemeCodeUnspsc190501.50365604': __('identifierSchemeCodeUnspsc190501.50365604'),
	'identifierSchemeCodeUnspsc190501.50365605': __('identifierSchemeCodeUnspsc190501.50365605'),
	'identifierSchemeCodeUnspsc190501.50365606': __('identifierSchemeCodeUnspsc190501.50365606'),
	'identifierSchemeCodeUnspsc190501.50365607': __('identifierSchemeCodeUnspsc190501.50365607'),
	'identifierSchemeCodeUnspsc190501.50365608': __('identifierSchemeCodeUnspsc190501.50365608'),
	'identifierSchemeCodeUnspsc190501.50365609': __('identifierSchemeCodeUnspsc190501.50365609'),
	'identifierSchemeCodeUnspsc190501.50365610': __('identifierSchemeCodeUnspsc190501.50365610'),
	'identifierSchemeCodeUnspsc190501.50365700': __('identifierSchemeCodeUnspsc190501.50365700'),
	'identifierSchemeCodeUnspsc190501.50365701': __('identifierSchemeCodeUnspsc190501.50365701'),
	'identifierSchemeCodeUnspsc190501.50365702': __('identifierSchemeCodeUnspsc190501.50365702'),
	'identifierSchemeCodeUnspsc190501.50365703': __('identifierSchemeCodeUnspsc190501.50365703'),
	'identifierSchemeCodeUnspsc190501.50365704': __('identifierSchemeCodeUnspsc190501.50365704'),
	'identifierSchemeCodeUnspsc190501.50365705': __('identifierSchemeCodeUnspsc190501.50365705'),
	'identifierSchemeCodeUnspsc190501.50365706': __('identifierSchemeCodeUnspsc190501.50365706'),
	'identifierSchemeCodeUnspsc190501.50365707': __('identifierSchemeCodeUnspsc190501.50365707'),
	'identifierSchemeCodeUnspsc190501.50365708': __('identifierSchemeCodeUnspsc190501.50365708'),
	'identifierSchemeCodeUnspsc190501.50365709': __('identifierSchemeCodeUnspsc190501.50365709'),
	'identifierSchemeCodeUnspsc190501.50365710': __('identifierSchemeCodeUnspsc190501.50365710'),
	'identifierSchemeCodeUnspsc190501.50365711': __('identifierSchemeCodeUnspsc190501.50365711'),
	'identifierSchemeCodeUnspsc190501.50365712': __('identifierSchemeCodeUnspsc190501.50365712'),
	'identifierSchemeCodeUnspsc190501.50365713': __('identifierSchemeCodeUnspsc190501.50365713'),
	'identifierSchemeCodeUnspsc190501.50365714': __('identifierSchemeCodeUnspsc190501.50365714'),
	'identifierSchemeCodeUnspsc190501.50365715': __('identifierSchemeCodeUnspsc190501.50365715'),
	'identifierSchemeCodeUnspsc190501.50365716': __('identifierSchemeCodeUnspsc190501.50365716'),
	'identifierSchemeCodeUnspsc190501.50365717': __('identifierSchemeCodeUnspsc190501.50365717'),
	'identifierSchemeCodeUnspsc190501.50365718': __('identifierSchemeCodeUnspsc190501.50365718'),
	'identifierSchemeCodeUnspsc190501.50365719': __('identifierSchemeCodeUnspsc190501.50365719'),
	'identifierSchemeCodeUnspsc190501.50365720': __('identifierSchemeCodeUnspsc190501.50365720'),
	'identifierSchemeCodeUnspsc190501.50365721': __('identifierSchemeCodeUnspsc190501.50365721'),
	'identifierSchemeCodeUnspsc190501.50365722': __('identifierSchemeCodeUnspsc190501.50365722'),
	'identifierSchemeCodeUnspsc190501.50365723': __('identifierSchemeCodeUnspsc190501.50365723'),
	'identifierSchemeCodeUnspsc190501.50365800': __('identifierSchemeCodeUnspsc190501.50365800'),
	'identifierSchemeCodeUnspsc190501.50365801': __('identifierSchemeCodeUnspsc190501.50365801'),
	'identifierSchemeCodeUnspsc190501.50365802': __('identifierSchemeCodeUnspsc190501.50365802'),
	'identifierSchemeCodeUnspsc190501.50365803': __('identifierSchemeCodeUnspsc190501.50365803'),
	'identifierSchemeCodeUnspsc190501.50365804': __('identifierSchemeCodeUnspsc190501.50365804'),
	'identifierSchemeCodeUnspsc190501.50365805': __('identifierSchemeCodeUnspsc190501.50365805'),
	'identifierSchemeCodeUnspsc190501.50365806': __('identifierSchemeCodeUnspsc190501.50365806'),
	'identifierSchemeCodeUnspsc190501.50365807': __('identifierSchemeCodeUnspsc190501.50365807'),
	'identifierSchemeCodeUnspsc190501.50365808': __('identifierSchemeCodeUnspsc190501.50365808'),
	'identifierSchemeCodeUnspsc190501.50365809': __('identifierSchemeCodeUnspsc190501.50365809'),
	'identifierSchemeCodeUnspsc190501.50365810': __('identifierSchemeCodeUnspsc190501.50365810'),
	'identifierSchemeCodeUnspsc190501.50365811': __('identifierSchemeCodeUnspsc190501.50365811'),
	'identifierSchemeCodeUnspsc190501.50365812': __('identifierSchemeCodeUnspsc190501.50365812'),
	'identifierSchemeCodeUnspsc190501.50365813': __('identifierSchemeCodeUnspsc190501.50365813'),
	'identifierSchemeCodeUnspsc190501.50365814': __('identifierSchemeCodeUnspsc190501.50365814'),
	'identifierSchemeCodeUnspsc190501.50365815': __('identifierSchemeCodeUnspsc190501.50365815'),
	'identifierSchemeCodeUnspsc190501.50365816': __('identifierSchemeCodeUnspsc190501.50365816'),
	'identifierSchemeCodeUnspsc190501.50365817': __('identifierSchemeCodeUnspsc190501.50365817'),
	'identifierSchemeCodeUnspsc190501.50365818': __('identifierSchemeCodeUnspsc190501.50365818'),
	'identifierSchemeCodeUnspsc190501.50365819': __('identifierSchemeCodeUnspsc190501.50365819'),
	'identifierSchemeCodeUnspsc190501.50365820': __('identifierSchemeCodeUnspsc190501.50365820'),
	'identifierSchemeCodeUnspsc190501.50365821': __('identifierSchemeCodeUnspsc190501.50365821'),
	'identifierSchemeCodeUnspsc190501.50365822': __('identifierSchemeCodeUnspsc190501.50365822'),
	'identifierSchemeCodeUnspsc190501.50365823': __('identifierSchemeCodeUnspsc190501.50365823'),
	'identifierSchemeCodeUnspsc190501.50365824': __('identifierSchemeCodeUnspsc190501.50365824'),
	'identifierSchemeCodeUnspsc190501.50365825': __('identifierSchemeCodeUnspsc190501.50365825'),
	'identifierSchemeCodeUnspsc190501.50365826': __('identifierSchemeCodeUnspsc190501.50365826'),
	'identifierSchemeCodeUnspsc190501.50365827': __('identifierSchemeCodeUnspsc190501.50365827'),
	'identifierSchemeCodeUnspsc190501.50365828': __('identifierSchemeCodeUnspsc190501.50365828'),
	'identifierSchemeCodeUnspsc190501.50365829': __('identifierSchemeCodeUnspsc190501.50365829'),
	'identifierSchemeCodeUnspsc190501.50365830': __('identifierSchemeCodeUnspsc190501.50365830'),
	'identifierSchemeCodeUnspsc190501.50365831': __('identifierSchemeCodeUnspsc190501.50365831'),
	'identifierSchemeCodeUnspsc190501.50365832': __('identifierSchemeCodeUnspsc190501.50365832'),
	'identifierSchemeCodeUnspsc190501.50365833': __('identifierSchemeCodeUnspsc190501.50365833'),
	'identifierSchemeCodeUnspsc190501.50365834': __('identifierSchemeCodeUnspsc190501.50365834'),
	'identifierSchemeCodeUnspsc190501.50365835': __('identifierSchemeCodeUnspsc190501.50365835'),
	'identifierSchemeCodeUnspsc190501.50365836': __('identifierSchemeCodeUnspsc190501.50365836'),
	'identifierSchemeCodeUnspsc190501.50365837': __('identifierSchemeCodeUnspsc190501.50365837'),
	'identifierSchemeCodeUnspsc190501.50365838': __('identifierSchemeCodeUnspsc190501.50365838'),
	'identifierSchemeCodeUnspsc190501.50365839': __('identifierSchemeCodeUnspsc190501.50365839'),
	'identifierSchemeCodeUnspsc190501.50365840': __('identifierSchemeCodeUnspsc190501.50365840'),
	'identifierSchemeCodeUnspsc190501.50365841': __('identifierSchemeCodeUnspsc190501.50365841'),
	'identifierSchemeCodeUnspsc190501.50365842': __('identifierSchemeCodeUnspsc190501.50365842'),
	'identifierSchemeCodeUnspsc190501.50365843': __('identifierSchemeCodeUnspsc190501.50365843'),
	'identifierSchemeCodeUnspsc190501.50365844': __('identifierSchemeCodeUnspsc190501.50365844'),
	'identifierSchemeCodeUnspsc190501.50365845': __('identifierSchemeCodeUnspsc190501.50365845'),
	'identifierSchemeCodeUnspsc190501.50365846': __('identifierSchemeCodeUnspsc190501.50365846'),
	'identifierSchemeCodeUnspsc190501.50365847': __('identifierSchemeCodeUnspsc190501.50365847'),
	'identifierSchemeCodeUnspsc190501.50365848': __('identifierSchemeCodeUnspsc190501.50365848'),
	'identifierSchemeCodeUnspsc190501.50365849': __('identifierSchemeCodeUnspsc190501.50365849'),
	'identifierSchemeCodeUnspsc190501.50365850': __('identifierSchemeCodeUnspsc190501.50365850'),
	'identifierSchemeCodeUnspsc190501.50365851': __('identifierSchemeCodeUnspsc190501.50365851'),
	'identifierSchemeCodeUnspsc190501.50365852': __('identifierSchemeCodeUnspsc190501.50365852'),
	'identifierSchemeCodeUnspsc190501.50365853': __('identifierSchemeCodeUnspsc190501.50365853'),
	'identifierSchemeCodeUnspsc190501.50365854': __('identifierSchemeCodeUnspsc190501.50365854'),
	'identifierSchemeCodeUnspsc190501.50365855': __('identifierSchemeCodeUnspsc190501.50365855'),
	'identifierSchemeCodeUnspsc190501.50365856': __('identifierSchemeCodeUnspsc190501.50365856'),
	'identifierSchemeCodeUnspsc190501.50365857': __('identifierSchemeCodeUnspsc190501.50365857'),
	'identifierSchemeCodeUnspsc190501.50365858': __('identifierSchemeCodeUnspsc190501.50365858'),
	'identifierSchemeCodeUnspsc190501.50365859': __('identifierSchemeCodeUnspsc190501.50365859'),
	'identifierSchemeCodeUnspsc190501.50365860': __('identifierSchemeCodeUnspsc190501.50365860'),
	'identifierSchemeCodeUnspsc190501.50365861': __('identifierSchemeCodeUnspsc190501.50365861'),
	'identifierSchemeCodeUnspsc190501.50365862': __('identifierSchemeCodeUnspsc190501.50365862'),
	'identifierSchemeCodeUnspsc190501.50365863': __('identifierSchemeCodeUnspsc190501.50365863'),
	'identifierSchemeCodeUnspsc190501.50365864': __('identifierSchemeCodeUnspsc190501.50365864'),
	'identifierSchemeCodeUnspsc190501.50365865': __('identifierSchemeCodeUnspsc190501.50365865'),
	'identifierSchemeCodeUnspsc190501.50365866': __('identifierSchemeCodeUnspsc190501.50365866'),
	'identifierSchemeCodeUnspsc190501.50365867': __('identifierSchemeCodeUnspsc190501.50365867'),
	'identifierSchemeCodeUnspsc190501.50365868': __('identifierSchemeCodeUnspsc190501.50365868'),
	'identifierSchemeCodeUnspsc190501.50365869': __('identifierSchemeCodeUnspsc190501.50365869'),
	'identifierSchemeCodeUnspsc190501.50365870': __('identifierSchemeCodeUnspsc190501.50365870'),
	'identifierSchemeCodeUnspsc190501.50365871': __('identifierSchemeCodeUnspsc190501.50365871'),
	'identifierSchemeCodeUnspsc190501.50365872': __('identifierSchemeCodeUnspsc190501.50365872'),
	'identifierSchemeCodeUnspsc190501.50365873': __('identifierSchemeCodeUnspsc190501.50365873'),
	'identifierSchemeCodeUnspsc190501.50365874': __('identifierSchemeCodeUnspsc190501.50365874'),
	'identifierSchemeCodeUnspsc190501.50365875': __('identifierSchemeCodeUnspsc190501.50365875'),
	'identifierSchemeCodeUnspsc190501.50365876': __('identifierSchemeCodeUnspsc190501.50365876'),
	'identifierSchemeCodeUnspsc190501.50365877': __('identifierSchemeCodeUnspsc190501.50365877'),
	'identifierSchemeCodeUnspsc190501.50365900': __('identifierSchemeCodeUnspsc190501.50365900'),
	'identifierSchemeCodeUnspsc190501.50365901': __('identifierSchemeCodeUnspsc190501.50365901'),
	'identifierSchemeCodeUnspsc190501.50365902': __('identifierSchemeCodeUnspsc190501.50365902'),
	'identifierSchemeCodeUnspsc190501.50365903': __('identifierSchemeCodeUnspsc190501.50365903'),
	'identifierSchemeCodeUnspsc190501.50365904': __('identifierSchemeCodeUnspsc190501.50365904'),
	'identifierSchemeCodeUnspsc190501.50365905': __('identifierSchemeCodeUnspsc190501.50365905'),
	'identifierSchemeCodeUnspsc190501.50365906': __('identifierSchemeCodeUnspsc190501.50365906'),
	'identifierSchemeCodeUnspsc190501.50365907': __('identifierSchemeCodeUnspsc190501.50365907'),
	'identifierSchemeCodeUnspsc190501.50365908': __('identifierSchemeCodeUnspsc190501.50365908'),
	'identifierSchemeCodeUnspsc190501.50366000': __('identifierSchemeCodeUnspsc190501.50366000'),
	'identifierSchemeCodeUnspsc190501.50366001': __('identifierSchemeCodeUnspsc190501.50366001'),
	'identifierSchemeCodeUnspsc190501.50366002': __('identifierSchemeCodeUnspsc190501.50366002'),
	'identifierSchemeCodeUnspsc190501.50366003': __('identifierSchemeCodeUnspsc190501.50366003'),
	'identifierSchemeCodeUnspsc190501.50366004': __('identifierSchemeCodeUnspsc190501.50366004'),
	'identifierSchemeCodeUnspsc190501.50366005': __('identifierSchemeCodeUnspsc190501.50366005'),
	'identifierSchemeCodeUnspsc190501.50366006': __('identifierSchemeCodeUnspsc190501.50366006'),
	'identifierSchemeCodeUnspsc190501.50366007': __('identifierSchemeCodeUnspsc190501.50366007'),
	'identifierSchemeCodeUnspsc190501.50366008': __('identifierSchemeCodeUnspsc190501.50366008'),
	'identifierSchemeCodeUnspsc190501.50366100': __('identifierSchemeCodeUnspsc190501.50366100'),
	'identifierSchemeCodeUnspsc190501.50366101': __('identifierSchemeCodeUnspsc190501.50366101'),
	'identifierSchemeCodeUnspsc190501.50366102': __('identifierSchemeCodeUnspsc190501.50366102'),
	'identifierSchemeCodeUnspsc190501.50366103': __('identifierSchemeCodeUnspsc190501.50366103'),
	'identifierSchemeCodeUnspsc190501.50366200': __('identifierSchemeCodeUnspsc190501.50366200'),
	'identifierSchemeCodeUnspsc190501.50366201': __('identifierSchemeCodeUnspsc190501.50366201'),
	'identifierSchemeCodeUnspsc190501.50366202': __('identifierSchemeCodeUnspsc190501.50366202'),
	'identifierSchemeCodeUnspsc190501.50366203': __('identifierSchemeCodeUnspsc190501.50366203'),
	'identifierSchemeCodeUnspsc190501.50366204': __('identifierSchemeCodeUnspsc190501.50366204'),
	'identifierSchemeCodeUnspsc190501.50366205': __('identifierSchemeCodeUnspsc190501.50366205'),
	'identifierSchemeCodeUnspsc190501.50366206': __('identifierSchemeCodeUnspsc190501.50366206'),
	'identifierSchemeCodeUnspsc190501.50366207': __('identifierSchemeCodeUnspsc190501.50366207'),
	'identifierSchemeCodeUnspsc190501.50366208': __('identifierSchemeCodeUnspsc190501.50366208'),
	'identifierSchemeCodeUnspsc190501.50366209': __('identifierSchemeCodeUnspsc190501.50366209'),
	'identifierSchemeCodeUnspsc190501.50366210': __('identifierSchemeCodeUnspsc190501.50366210'),
	'identifierSchemeCodeUnspsc190501.50366211': __('identifierSchemeCodeUnspsc190501.50366211'),
	'identifierSchemeCodeUnspsc190501.50366212': __('identifierSchemeCodeUnspsc190501.50366212'),
	'identifierSchemeCodeUnspsc190501.50366213': __('identifierSchemeCodeUnspsc190501.50366213'),
	'identifierSchemeCodeUnspsc190501.50366214': __('identifierSchemeCodeUnspsc190501.50366214'),
	'identifierSchemeCodeUnspsc190501.50366215': __('identifierSchemeCodeUnspsc190501.50366215'),
	'identifierSchemeCodeUnspsc190501.50366216': __('identifierSchemeCodeUnspsc190501.50366216'),
	'identifierSchemeCodeUnspsc190501.50366217': __('identifierSchemeCodeUnspsc190501.50366217'),
	'identifierSchemeCodeUnspsc190501.50366218': __('identifierSchemeCodeUnspsc190501.50366218'),
	'identifierSchemeCodeUnspsc190501.50366219': __('identifierSchemeCodeUnspsc190501.50366219'),
	'identifierSchemeCodeUnspsc190501.50366220': __('identifierSchemeCodeUnspsc190501.50366220'),
	'identifierSchemeCodeUnspsc190501.50366221': __('identifierSchemeCodeUnspsc190501.50366221'),
	'identifierSchemeCodeUnspsc190501.50366222': __('identifierSchemeCodeUnspsc190501.50366222'),
	'identifierSchemeCodeUnspsc190501.50366223': __('identifierSchemeCodeUnspsc190501.50366223'),
	'identifierSchemeCodeUnspsc190501.50366224': __('identifierSchemeCodeUnspsc190501.50366224'),
	'identifierSchemeCodeUnspsc190501.50366225': __('identifierSchemeCodeUnspsc190501.50366225'),
	'identifierSchemeCodeUnspsc190501.50366226': __('identifierSchemeCodeUnspsc190501.50366226'),
	'identifierSchemeCodeUnspsc190501.50366227': __('identifierSchemeCodeUnspsc190501.50366227'),
	'identifierSchemeCodeUnspsc190501.50366228': __('identifierSchemeCodeUnspsc190501.50366228'),
	'identifierSchemeCodeUnspsc190501.50366229': __('identifierSchemeCodeUnspsc190501.50366229'),
	'identifierSchemeCodeUnspsc190501.50366300': __('identifierSchemeCodeUnspsc190501.50366300'),
	'identifierSchemeCodeUnspsc190501.50366301': __('identifierSchemeCodeUnspsc190501.50366301'),
	'identifierSchemeCodeUnspsc190501.50366302': __('identifierSchemeCodeUnspsc190501.50366302'),
	'identifierSchemeCodeUnspsc190501.50366303': __('identifierSchemeCodeUnspsc190501.50366303'),
	'identifierSchemeCodeUnspsc190501.50366304': __('identifierSchemeCodeUnspsc190501.50366304'),
	'identifierSchemeCodeUnspsc190501.50366305': __('identifierSchemeCodeUnspsc190501.50366305'),
	'identifierSchemeCodeUnspsc190501.50366306': __('identifierSchemeCodeUnspsc190501.50366306'),
	'identifierSchemeCodeUnspsc190501.50366307': __('identifierSchemeCodeUnspsc190501.50366307'),
	'identifierSchemeCodeUnspsc190501.50366308': __('identifierSchemeCodeUnspsc190501.50366308'),
	'identifierSchemeCodeUnspsc190501.50366309': __('identifierSchemeCodeUnspsc190501.50366309'),
	'identifierSchemeCodeUnspsc190501.50366310': __('identifierSchemeCodeUnspsc190501.50366310'),
	'identifierSchemeCodeUnspsc190501.50366400': __('identifierSchemeCodeUnspsc190501.50366400'),
	'identifierSchemeCodeUnspsc190501.50366401': __('identifierSchemeCodeUnspsc190501.50366401'),
	'identifierSchemeCodeUnspsc190501.50366402': __('identifierSchemeCodeUnspsc190501.50366402'),
	'identifierSchemeCodeUnspsc190501.50366403': __('identifierSchemeCodeUnspsc190501.50366403'),
	'identifierSchemeCodeUnspsc190501.50366404': __('identifierSchemeCodeUnspsc190501.50366404'),
	'identifierSchemeCodeUnspsc190501.50366500': __('identifierSchemeCodeUnspsc190501.50366500'),
	'identifierSchemeCodeUnspsc190501.50366501': __('identifierSchemeCodeUnspsc190501.50366501'),
	'identifierSchemeCodeUnspsc190501.50366502': __('identifierSchemeCodeUnspsc190501.50366502'),
	'identifierSchemeCodeUnspsc190501.50366600': __('identifierSchemeCodeUnspsc190501.50366600'),
	'identifierSchemeCodeUnspsc190501.50366601': __('identifierSchemeCodeUnspsc190501.50366601'),
	'identifierSchemeCodeUnspsc190501.50366602': __('identifierSchemeCodeUnspsc190501.50366602'),
	'identifierSchemeCodeUnspsc190501.50366603': __('identifierSchemeCodeUnspsc190501.50366603'),
	'identifierSchemeCodeUnspsc190501.50366604': __('identifierSchemeCodeUnspsc190501.50366604'),
	'identifierSchemeCodeUnspsc190501.50366700': __('identifierSchemeCodeUnspsc190501.50366700'),
	'identifierSchemeCodeUnspsc190501.50366701': __('identifierSchemeCodeUnspsc190501.50366701'),
	'identifierSchemeCodeUnspsc190501.50366702': __('identifierSchemeCodeUnspsc190501.50366702'),
	'identifierSchemeCodeUnspsc190501.50366703': __('identifierSchemeCodeUnspsc190501.50366703'),
	'identifierSchemeCodeUnspsc190501.50366800': __('identifierSchemeCodeUnspsc190501.50366800'),
	'identifierSchemeCodeUnspsc190501.50366801': __('identifierSchemeCodeUnspsc190501.50366801'),
	'identifierSchemeCodeUnspsc190501.50366802': __('identifierSchemeCodeUnspsc190501.50366802'),
	'identifierSchemeCodeUnspsc190501.50366803': __('identifierSchemeCodeUnspsc190501.50366803'),
	'identifierSchemeCodeUnspsc190501.50366900': __('identifierSchemeCodeUnspsc190501.50366900'),
	'identifierSchemeCodeUnspsc190501.50366901': __('identifierSchemeCodeUnspsc190501.50366901'),
	'identifierSchemeCodeUnspsc190501.50366902': __('identifierSchemeCodeUnspsc190501.50366902'),
	'identifierSchemeCodeUnspsc190501.50366903': __('identifierSchemeCodeUnspsc190501.50366903'),
	'identifierSchemeCodeUnspsc190501.50366904': __('identifierSchemeCodeUnspsc190501.50366904'),
	'identifierSchemeCodeUnspsc190501.50366905': __('identifierSchemeCodeUnspsc190501.50366905'),
	'identifierSchemeCodeUnspsc190501.50366906': __('identifierSchemeCodeUnspsc190501.50366906'),
	'identifierSchemeCodeUnspsc190501.50367000': __('identifierSchemeCodeUnspsc190501.50367000'),
	'identifierSchemeCodeUnspsc190501.50367001': __('identifierSchemeCodeUnspsc190501.50367001'),
	'identifierSchemeCodeUnspsc190501.50367002': __('identifierSchemeCodeUnspsc190501.50367002'),
	'identifierSchemeCodeUnspsc190501.50367003': __('identifierSchemeCodeUnspsc190501.50367003'),
	'identifierSchemeCodeUnspsc190501.50367004': __('identifierSchemeCodeUnspsc190501.50367004'),
	'identifierSchemeCodeUnspsc190501.50367005': __('identifierSchemeCodeUnspsc190501.50367005'),
	'identifierSchemeCodeUnspsc190501.50367006': __('identifierSchemeCodeUnspsc190501.50367006'),
	'identifierSchemeCodeUnspsc190501.50367007': __('identifierSchemeCodeUnspsc190501.50367007'),
	'identifierSchemeCodeUnspsc190501.50367008': __('identifierSchemeCodeUnspsc190501.50367008'),
	'identifierSchemeCodeUnspsc190501.50367009': __('identifierSchemeCodeUnspsc190501.50367009'),
	'identifierSchemeCodeUnspsc190501.50367010': __('identifierSchemeCodeUnspsc190501.50367010'),
	'identifierSchemeCodeUnspsc190501.50367011': __('identifierSchemeCodeUnspsc190501.50367011'),
	'identifierSchemeCodeUnspsc190501.50367012': __('identifierSchemeCodeUnspsc190501.50367012'),
	'identifierSchemeCodeUnspsc190501.50367013': __('identifierSchemeCodeUnspsc190501.50367013'),
	'identifierSchemeCodeUnspsc190501.50367014': __('identifierSchemeCodeUnspsc190501.50367014'),
	'identifierSchemeCodeUnspsc190501.50367015': __('identifierSchemeCodeUnspsc190501.50367015'),
	'identifierSchemeCodeUnspsc190501.50367016': __('identifierSchemeCodeUnspsc190501.50367016'),
	'identifierSchemeCodeUnspsc190501.50367017': __('identifierSchemeCodeUnspsc190501.50367017'),
	'identifierSchemeCodeUnspsc190501.50367018': __('identifierSchemeCodeUnspsc190501.50367018'),
	'identifierSchemeCodeUnspsc190501.50367019': __('identifierSchemeCodeUnspsc190501.50367019'),
	'identifierSchemeCodeUnspsc190501.50367020': __('identifierSchemeCodeUnspsc190501.50367020'),
	'identifierSchemeCodeUnspsc190501.50367021': __('identifierSchemeCodeUnspsc190501.50367021'),
	'identifierSchemeCodeUnspsc190501.50367022': __('identifierSchemeCodeUnspsc190501.50367022'),
	'identifierSchemeCodeUnspsc190501.50367023': __('identifierSchemeCodeUnspsc190501.50367023'),
	'identifierSchemeCodeUnspsc190501.50367024': __('identifierSchemeCodeUnspsc190501.50367024'),
	'identifierSchemeCodeUnspsc190501.50367025': __('identifierSchemeCodeUnspsc190501.50367025'),
	'identifierSchemeCodeUnspsc190501.50367026': __('identifierSchemeCodeUnspsc190501.50367026'),
	'identifierSchemeCodeUnspsc190501.50367027': __('identifierSchemeCodeUnspsc190501.50367027'),
	'identifierSchemeCodeUnspsc190501.50367028': __('identifierSchemeCodeUnspsc190501.50367028'),
	'identifierSchemeCodeUnspsc190501.50367029': __('identifierSchemeCodeUnspsc190501.50367029'),
	'identifierSchemeCodeUnspsc190501.50367030': __('identifierSchemeCodeUnspsc190501.50367030'),
	'identifierSchemeCodeUnspsc190501.50367031': __('identifierSchemeCodeUnspsc190501.50367031'),
	'identifierSchemeCodeUnspsc190501.50367032': __('identifierSchemeCodeUnspsc190501.50367032'),
	'identifierSchemeCodeUnspsc190501.50367033': __('identifierSchemeCodeUnspsc190501.50367033'),
	'identifierSchemeCodeUnspsc190501.50367034': __('identifierSchemeCodeUnspsc190501.50367034'),
	'identifierSchemeCodeUnspsc190501.50367035': __('identifierSchemeCodeUnspsc190501.50367035'),
	'identifierSchemeCodeUnspsc190501.50367036': __('identifierSchemeCodeUnspsc190501.50367036'),
	'identifierSchemeCodeUnspsc190501.50367037': __('identifierSchemeCodeUnspsc190501.50367037'),
	'identifierSchemeCodeUnspsc190501.50367100': __('identifierSchemeCodeUnspsc190501.50367100'),
	'identifierSchemeCodeUnspsc190501.50367101': __('identifierSchemeCodeUnspsc190501.50367101'),
	'identifierSchemeCodeUnspsc190501.50367102': __('identifierSchemeCodeUnspsc190501.50367102'),
	'identifierSchemeCodeUnspsc190501.50367103': __('identifierSchemeCodeUnspsc190501.50367103'),
	'identifierSchemeCodeUnspsc190501.50367104': __('identifierSchemeCodeUnspsc190501.50367104'),
	'identifierSchemeCodeUnspsc190501.50367200': __('identifierSchemeCodeUnspsc190501.50367200'),
	'identifierSchemeCodeUnspsc190501.50367201': __('identifierSchemeCodeUnspsc190501.50367201'),
	'identifierSchemeCodeUnspsc190501.50367202': __('identifierSchemeCodeUnspsc190501.50367202'),
	'identifierSchemeCodeUnspsc190501.50367203': __('identifierSchemeCodeUnspsc190501.50367203'),
	'identifierSchemeCodeUnspsc190501.50367204': __('identifierSchemeCodeUnspsc190501.50367204'),
	'identifierSchemeCodeUnspsc190501.50367205': __('identifierSchemeCodeUnspsc190501.50367205'),
	'identifierSchemeCodeUnspsc190501.50367206': __('identifierSchemeCodeUnspsc190501.50367206'),
	'identifierSchemeCodeUnspsc190501.50367207': __('identifierSchemeCodeUnspsc190501.50367207'),
	'identifierSchemeCodeUnspsc190501.50367208': __('identifierSchemeCodeUnspsc190501.50367208'),
	'identifierSchemeCodeUnspsc190501.50367209': __('identifierSchemeCodeUnspsc190501.50367209'),
	'identifierSchemeCodeUnspsc190501.50367210': __('identifierSchemeCodeUnspsc190501.50367210'),
	'identifierSchemeCodeUnspsc190501.50367211': __('identifierSchemeCodeUnspsc190501.50367211'),
	'identifierSchemeCodeUnspsc190501.50367212': __('identifierSchemeCodeUnspsc190501.50367212'),
	'identifierSchemeCodeUnspsc190501.50367213': __('identifierSchemeCodeUnspsc190501.50367213'),
	'identifierSchemeCodeUnspsc190501.50367214': __('identifierSchemeCodeUnspsc190501.50367214'),
	'identifierSchemeCodeUnspsc190501.50367215': __('identifierSchemeCodeUnspsc190501.50367215'),
	'identifierSchemeCodeUnspsc190501.50367216': __('identifierSchemeCodeUnspsc190501.50367216'),
	'identifierSchemeCodeUnspsc190501.50367217': __('identifierSchemeCodeUnspsc190501.50367217'),
	'identifierSchemeCodeUnspsc190501.50367218': __('identifierSchemeCodeUnspsc190501.50367218'),
	'identifierSchemeCodeUnspsc190501.50367219': __('identifierSchemeCodeUnspsc190501.50367219'),
	'identifierSchemeCodeUnspsc190501.50367220': __('identifierSchemeCodeUnspsc190501.50367220'),
	'identifierSchemeCodeUnspsc190501.50367221': __('identifierSchemeCodeUnspsc190501.50367221'),
	'identifierSchemeCodeUnspsc190501.50367222': __('identifierSchemeCodeUnspsc190501.50367222'),
	'identifierSchemeCodeUnspsc190501.50367223': __('identifierSchemeCodeUnspsc190501.50367223'),
	'identifierSchemeCodeUnspsc190501.50367224': __('identifierSchemeCodeUnspsc190501.50367224'),
	'identifierSchemeCodeUnspsc190501.50367225': __('identifierSchemeCodeUnspsc190501.50367225'),
	'identifierSchemeCodeUnspsc190501.50367226': __('identifierSchemeCodeUnspsc190501.50367226'),
	'identifierSchemeCodeUnspsc190501.50367227': __('identifierSchemeCodeUnspsc190501.50367227'),
	'identifierSchemeCodeUnspsc190501.50367228': __('identifierSchemeCodeUnspsc190501.50367228'),
	'identifierSchemeCodeUnspsc190501.50367229': __('identifierSchemeCodeUnspsc190501.50367229'),
	'identifierSchemeCodeUnspsc190501.50367230': __('identifierSchemeCodeUnspsc190501.50367230'),
	'identifierSchemeCodeUnspsc190501.50370000': __('identifierSchemeCodeUnspsc190501.50370000'),
	'identifierSchemeCodeUnspsc190501.50371500': __('identifierSchemeCodeUnspsc190501.50371500'),
	'identifierSchemeCodeUnspsc190501.50371501': __('identifierSchemeCodeUnspsc190501.50371501'),
	'identifierSchemeCodeUnspsc190501.50371502': __('identifierSchemeCodeUnspsc190501.50371502'),
	'identifierSchemeCodeUnspsc190501.50371503': __('identifierSchemeCodeUnspsc190501.50371503'),
	'identifierSchemeCodeUnspsc190501.50371504': __('identifierSchemeCodeUnspsc190501.50371504'),
	'identifierSchemeCodeUnspsc190501.50371505': __('identifierSchemeCodeUnspsc190501.50371505'),
	'identifierSchemeCodeUnspsc190501.50371506': __('identifierSchemeCodeUnspsc190501.50371506'),
	'identifierSchemeCodeUnspsc190501.50371507': __('identifierSchemeCodeUnspsc190501.50371507'),
	'identifierSchemeCodeUnspsc190501.50371508': __('identifierSchemeCodeUnspsc190501.50371508'),
	'identifierSchemeCodeUnspsc190501.50371509': __('identifierSchemeCodeUnspsc190501.50371509'),
	'identifierSchemeCodeUnspsc190501.50371510': __('identifierSchemeCodeUnspsc190501.50371510'),
	'identifierSchemeCodeUnspsc190501.50371511': __('identifierSchemeCodeUnspsc190501.50371511'),
	'identifierSchemeCodeUnspsc190501.50371512': __('identifierSchemeCodeUnspsc190501.50371512'),
	'identifierSchemeCodeUnspsc190501.50371513': __('identifierSchemeCodeUnspsc190501.50371513'),
	'identifierSchemeCodeUnspsc190501.50371514': __('identifierSchemeCodeUnspsc190501.50371514'),
	'identifierSchemeCodeUnspsc190501.50371515': __('identifierSchemeCodeUnspsc190501.50371515'),
	'identifierSchemeCodeUnspsc190501.50371516': __('identifierSchemeCodeUnspsc190501.50371516'),
	'identifierSchemeCodeUnspsc190501.50371517': __('identifierSchemeCodeUnspsc190501.50371517'),
	'identifierSchemeCodeUnspsc190501.50371518': __('identifierSchemeCodeUnspsc190501.50371518'),
	'identifierSchemeCodeUnspsc190501.50371519': __('identifierSchemeCodeUnspsc190501.50371519'),
	'identifierSchemeCodeUnspsc190501.50371520': __('identifierSchemeCodeUnspsc190501.50371520'),
	'identifierSchemeCodeUnspsc190501.50371521': __('identifierSchemeCodeUnspsc190501.50371521'),
	'identifierSchemeCodeUnspsc190501.50371522': __('identifierSchemeCodeUnspsc190501.50371522'),
	'identifierSchemeCodeUnspsc190501.50371523': __('identifierSchemeCodeUnspsc190501.50371523'),
	'identifierSchemeCodeUnspsc190501.50371524': __('identifierSchemeCodeUnspsc190501.50371524'),
	'identifierSchemeCodeUnspsc190501.50371525': __('identifierSchemeCodeUnspsc190501.50371525'),
	'identifierSchemeCodeUnspsc190501.50371526': __('identifierSchemeCodeUnspsc190501.50371526'),
	'identifierSchemeCodeUnspsc190501.50371527': __('identifierSchemeCodeUnspsc190501.50371527'),
	'identifierSchemeCodeUnspsc190501.50371528': __('identifierSchemeCodeUnspsc190501.50371528'),
	'identifierSchemeCodeUnspsc190501.50371529': __('identifierSchemeCodeUnspsc190501.50371529'),
	'identifierSchemeCodeUnspsc190501.50371530': __('identifierSchemeCodeUnspsc190501.50371530'),
	'identifierSchemeCodeUnspsc190501.50371531': __('identifierSchemeCodeUnspsc190501.50371531'),
	'identifierSchemeCodeUnspsc190501.50371532': __('identifierSchemeCodeUnspsc190501.50371532'),
	'identifierSchemeCodeUnspsc190501.50371533': __('identifierSchemeCodeUnspsc190501.50371533'),
	'identifierSchemeCodeUnspsc190501.50371534': __('identifierSchemeCodeUnspsc190501.50371534'),
	'identifierSchemeCodeUnspsc190501.50371535': __('identifierSchemeCodeUnspsc190501.50371535'),
	'identifierSchemeCodeUnspsc190501.50371536': __('identifierSchemeCodeUnspsc190501.50371536'),
	'identifierSchemeCodeUnspsc190501.50371537': __('identifierSchemeCodeUnspsc190501.50371537'),
	'identifierSchemeCodeUnspsc190501.50371538': __('identifierSchemeCodeUnspsc190501.50371538'),
	'identifierSchemeCodeUnspsc190501.50371539': __('identifierSchemeCodeUnspsc190501.50371539'),
	'identifierSchemeCodeUnspsc190501.50371540': __('identifierSchemeCodeUnspsc190501.50371540'),
	'identifierSchemeCodeUnspsc190501.50371541': __('identifierSchemeCodeUnspsc190501.50371541'),
	'identifierSchemeCodeUnspsc190501.50371542': __('identifierSchemeCodeUnspsc190501.50371542'),
	'identifierSchemeCodeUnspsc190501.50371543': __('identifierSchemeCodeUnspsc190501.50371543'),
	'identifierSchemeCodeUnspsc190501.50371544': __('identifierSchemeCodeUnspsc190501.50371544'),
	'identifierSchemeCodeUnspsc190501.50371545': __('identifierSchemeCodeUnspsc190501.50371545'),
	'identifierSchemeCodeUnspsc190501.50371546': __('identifierSchemeCodeUnspsc190501.50371546'),
	'identifierSchemeCodeUnspsc190501.50371547': __('identifierSchemeCodeUnspsc190501.50371547'),
	'identifierSchemeCodeUnspsc190501.50371548': __('identifierSchemeCodeUnspsc190501.50371548'),
	'identifierSchemeCodeUnspsc190501.50371549': __('identifierSchemeCodeUnspsc190501.50371549'),
	'identifierSchemeCodeUnspsc190501.50371550': __('identifierSchemeCodeUnspsc190501.50371550'),
	'identifierSchemeCodeUnspsc190501.50371551': __('identifierSchemeCodeUnspsc190501.50371551'),
	'identifierSchemeCodeUnspsc190501.50371552': __('identifierSchemeCodeUnspsc190501.50371552'),
	'identifierSchemeCodeUnspsc190501.50371553': __('identifierSchemeCodeUnspsc190501.50371553'),
	'identifierSchemeCodeUnspsc190501.50371554': __('identifierSchemeCodeUnspsc190501.50371554'),
	'identifierSchemeCodeUnspsc190501.50371555': __('identifierSchemeCodeUnspsc190501.50371555'),
	'identifierSchemeCodeUnspsc190501.50371556': __('identifierSchemeCodeUnspsc190501.50371556'),
	'identifierSchemeCodeUnspsc190501.50371557': __('identifierSchemeCodeUnspsc190501.50371557'),
	'identifierSchemeCodeUnspsc190501.50371558': __('identifierSchemeCodeUnspsc190501.50371558'),
	'identifierSchemeCodeUnspsc190501.50371559': __('identifierSchemeCodeUnspsc190501.50371559'),
	'identifierSchemeCodeUnspsc190501.50371560': __('identifierSchemeCodeUnspsc190501.50371560'),
	'identifierSchemeCodeUnspsc190501.50371561': __('identifierSchemeCodeUnspsc190501.50371561'),
	'identifierSchemeCodeUnspsc190501.50371562': __('identifierSchemeCodeUnspsc190501.50371562'),
	'identifierSchemeCodeUnspsc190501.50371563': __('identifierSchemeCodeUnspsc190501.50371563'),
	'identifierSchemeCodeUnspsc190501.50371564': __('identifierSchemeCodeUnspsc190501.50371564'),
	'identifierSchemeCodeUnspsc190501.50371565': __('identifierSchemeCodeUnspsc190501.50371565'),
	'identifierSchemeCodeUnspsc190501.50371566': __('identifierSchemeCodeUnspsc190501.50371566'),
	'identifierSchemeCodeUnspsc190501.50371567': __('identifierSchemeCodeUnspsc190501.50371567'),
	'identifierSchemeCodeUnspsc190501.50371568': __('identifierSchemeCodeUnspsc190501.50371568'),
	'identifierSchemeCodeUnspsc190501.50371569': __('identifierSchemeCodeUnspsc190501.50371569'),
	'identifierSchemeCodeUnspsc190501.50371570': __('identifierSchemeCodeUnspsc190501.50371570'),
	'identifierSchemeCodeUnspsc190501.50371571': __('identifierSchemeCodeUnspsc190501.50371571'),
	'identifierSchemeCodeUnspsc190501.50371572': __('identifierSchemeCodeUnspsc190501.50371572'),
	'identifierSchemeCodeUnspsc190501.50371573': __('identifierSchemeCodeUnspsc190501.50371573'),
	'identifierSchemeCodeUnspsc190501.50371574': __('identifierSchemeCodeUnspsc190501.50371574'),
	'identifierSchemeCodeUnspsc190501.50371575': __('identifierSchemeCodeUnspsc190501.50371575'),
	'identifierSchemeCodeUnspsc190501.50371576': __('identifierSchemeCodeUnspsc190501.50371576'),
	'identifierSchemeCodeUnspsc190501.50371577': __('identifierSchemeCodeUnspsc190501.50371577'),
	'identifierSchemeCodeUnspsc190501.50371578': __('identifierSchemeCodeUnspsc190501.50371578'),
	'identifierSchemeCodeUnspsc190501.50371579': __('identifierSchemeCodeUnspsc190501.50371579'),
	'identifierSchemeCodeUnspsc190501.50371580': __('identifierSchemeCodeUnspsc190501.50371580'),
	'identifierSchemeCodeUnspsc190501.50371581': __('identifierSchemeCodeUnspsc190501.50371581'),
	'identifierSchemeCodeUnspsc190501.50371582': __('identifierSchemeCodeUnspsc190501.50371582'),
	'identifierSchemeCodeUnspsc190501.50371583': __('identifierSchemeCodeUnspsc190501.50371583'),
	'identifierSchemeCodeUnspsc190501.50371584': __('identifierSchemeCodeUnspsc190501.50371584'),
	'identifierSchemeCodeUnspsc190501.50371585': __('identifierSchemeCodeUnspsc190501.50371585'),
	'identifierSchemeCodeUnspsc190501.50371586': __('identifierSchemeCodeUnspsc190501.50371586'),
	'identifierSchemeCodeUnspsc190501.50371587': __('identifierSchemeCodeUnspsc190501.50371587'),
	'identifierSchemeCodeUnspsc190501.50371588': __('identifierSchemeCodeUnspsc190501.50371588'),
	'identifierSchemeCodeUnspsc190501.50371589': __('identifierSchemeCodeUnspsc190501.50371589'),
	'identifierSchemeCodeUnspsc190501.50371590': __('identifierSchemeCodeUnspsc190501.50371590'),
	'identifierSchemeCodeUnspsc190501.50371591': __('identifierSchemeCodeUnspsc190501.50371591'),
	'identifierSchemeCodeUnspsc190501.50371592': __('identifierSchemeCodeUnspsc190501.50371592'),
	'identifierSchemeCodeUnspsc190501.50371593': __('identifierSchemeCodeUnspsc190501.50371593'),
	'identifierSchemeCodeUnspsc190501.50371594': __('identifierSchemeCodeUnspsc190501.50371594'),
	'identifierSchemeCodeUnspsc190501.50371595': __('identifierSchemeCodeUnspsc190501.50371595'),
	'identifierSchemeCodeUnspsc190501.50371596': __('identifierSchemeCodeUnspsc190501.50371596'),
	'identifierSchemeCodeUnspsc190501.50371600': __('identifierSchemeCodeUnspsc190501.50371600'),
	'identifierSchemeCodeUnspsc190501.50371601': __('identifierSchemeCodeUnspsc190501.50371601'),
	'identifierSchemeCodeUnspsc190501.50371602': __('identifierSchemeCodeUnspsc190501.50371602'),
	'identifierSchemeCodeUnspsc190501.50371603': __('identifierSchemeCodeUnspsc190501.50371603'),
	'identifierSchemeCodeUnspsc190501.50371604': __('identifierSchemeCodeUnspsc190501.50371604'),
	'identifierSchemeCodeUnspsc190501.50371605': __('identifierSchemeCodeUnspsc190501.50371605'),
	'identifierSchemeCodeUnspsc190501.50371606': __('identifierSchemeCodeUnspsc190501.50371606'),
	'identifierSchemeCodeUnspsc190501.50371607': __('identifierSchemeCodeUnspsc190501.50371607'),
	'identifierSchemeCodeUnspsc190501.50371608': __('identifierSchemeCodeUnspsc190501.50371608'),
	'identifierSchemeCodeUnspsc190501.50371609': __('identifierSchemeCodeUnspsc190501.50371609'),
	'identifierSchemeCodeUnspsc190501.50371610': __('identifierSchemeCodeUnspsc190501.50371610'),
	'identifierSchemeCodeUnspsc190501.50371611': __('identifierSchemeCodeUnspsc190501.50371611'),
	'identifierSchemeCodeUnspsc190501.50371612': __('identifierSchemeCodeUnspsc190501.50371612'),
	'identifierSchemeCodeUnspsc190501.50371613': __('identifierSchemeCodeUnspsc190501.50371613'),
	'identifierSchemeCodeUnspsc190501.50371614': __('identifierSchemeCodeUnspsc190501.50371614'),
	'identifierSchemeCodeUnspsc190501.50371615': __('identifierSchemeCodeUnspsc190501.50371615'),
	'identifierSchemeCodeUnspsc190501.50371616': __('identifierSchemeCodeUnspsc190501.50371616'),
	'identifierSchemeCodeUnspsc190501.50371617': __('identifierSchemeCodeUnspsc190501.50371617'),
	'identifierSchemeCodeUnspsc190501.50371618': __('identifierSchemeCodeUnspsc190501.50371618'),
	'identifierSchemeCodeUnspsc190501.50371619': __('identifierSchemeCodeUnspsc190501.50371619'),
	'identifierSchemeCodeUnspsc190501.50371620': __('identifierSchemeCodeUnspsc190501.50371620'),
	'identifierSchemeCodeUnspsc190501.50371621': __('identifierSchemeCodeUnspsc190501.50371621'),
	'identifierSchemeCodeUnspsc190501.50371622': __('identifierSchemeCodeUnspsc190501.50371622'),
	'identifierSchemeCodeUnspsc190501.50371623': __('identifierSchemeCodeUnspsc190501.50371623'),
	'identifierSchemeCodeUnspsc190501.50371624': __('identifierSchemeCodeUnspsc190501.50371624'),
	'identifierSchemeCodeUnspsc190501.50371625': __('identifierSchemeCodeUnspsc190501.50371625'),
	'identifierSchemeCodeUnspsc190501.50371626': __('identifierSchemeCodeUnspsc190501.50371626'),
	'identifierSchemeCodeUnspsc190501.50371627': __('identifierSchemeCodeUnspsc190501.50371627'),
	'identifierSchemeCodeUnspsc190501.50371628': __('identifierSchemeCodeUnspsc190501.50371628'),
	'identifierSchemeCodeUnspsc190501.50371629': __('identifierSchemeCodeUnspsc190501.50371629'),
	'identifierSchemeCodeUnspsc190501.50371630': __('identifierSchemeCodeUnspsc190501.50371630'),
	'identifierSchemeCodeUnspsc190501.50371631': __('identifierSchemeCodeUnspsc190501.50371631'),
	'identifierSchemeCodeUnspsc190501.50371632': __('identifierSchemeCodeUnspsc190501.50371632'),
	'identifierSchemeCodeUnspsc190501.50371633': __('identifierSchemeCodeUnspsc190501.50371633'),
	'identifierSchemeCodeUnspsc190501.50371634': __('identifierSchemeCodeUnspsc190501.50371634'),
	'identifierSchemeCodeUnspsc190501.50371635': __('identifierSchemeCodeUnspsc190501.50371635'),
	'identifierSchemeCodeUnspsc190501.50371636': __('identifierSchemeCodeUnspsc190501.50371636'),
	'identifierSchemeCodeUnspsc190501.50371637': __('identifierSchemeCodeUnspsc190501.50371637'),
	'identifierSchemeCodeUnspsc190501.50371638': __('identifierSchemeCodeUnspsc190501.50371638'),
	'identifierSchemeCodeUnspsc190501.50371639': __('identifierSchemeCodeUnspsc190501.50371639'),
	'identifierSchemeCodeUnspsc190501.50371640': __('identifierSchemeCodeUnspsc190501.50371640'),
	'identifierSchemeCodeUnspsc190501.50371641': __('identifierSchemeCodeUnspsc190501.50371641'),
	'identifierSchemeCodeUnspsc190501.50371642': __('identifierSchemeCodeUnspsc190501.50371642'),
	'identifierSchemeCodeUnspsc190501.50371643': __('identifierSchemeCodeUnspsc190501.50371643'),
	'identifierSchemeCodeUnspsc190501.50371644': __('identifierSchemeCodeUnspsc190501.50371644'),
	'identifierSchemeCodeUnspsc190501.50371645': __('identifierSchemeCodeUnspsc190501.50371645'),
	'identifierSchemeCodeUnspsc190501.50371646': __('identifierSchemeCodeUnspsc190501.50371646'),
	'identifierSchemeCodeUnspsc190501.50371647': __('identifierSchemeCodeUnspsc190501.50371647'),
	'identifierSchemeCodeUnspsc190501.50371648': __('identifierSchemeCodeUnspsc190501.50371648'),
	'identifierSchemeCodeUnspsc190501.50371649': __('identifierSchemeCodeUnspsc190501.50371649'),
	'identifierSchemeCodeUnspsc190501.50371650': __('identifierSchemeCodeUnspsc190501.50371650'),
	'identifierSchemeCodeUnspsc190501.50371651': __('identifierSchemeCodeUnspsc190501.50371651'),
	'identifierSchemeCodeUnspsc190501.50371652': __('identifierSchemeCodeUnspsc190501.50371652'),
	'identifierSchemeCodeUnspsc190501.50371653': __('identifierSchemeCodeUnspsc190501.50371653'),
	'identifierSchemeCodeUnspsc190501.50371654': __('identifierSchemeCodeUnspsc190501.50371654'),
	'identifierSchemeCodeUnspsc190501.50371655': __('identifierSchemeCodeUnspsc190501.50371655'),
	'identifierSchemeCodeUnspsc190501.50371700': __('identifierSchemeCodeUnspsc190501.50371700'),
	'identifierSchemeCodeUnspsc190501.50371701': __('identifierSchemeCodeUnspsc190501.50371701'),
	'identifierSchemeCodeUnspsc190501.50371702': __('identifierSchemeCodeUnspsc190501.50371702'),
	'identifierSchemeCodeUnspsc190501.50371703': __('identifierSchemeCodeUnspsc190501.50371703'),
	'identifierSchemeCodeUnspsc190501.50371704': __('identifierSchemeCodeUnspsc190501.50371704'),
	'identifierSchemeCodeUnspsc190501.50371705': __('identifierSchemeCodeUnspsc190501.50371705'),
	'identifierSchemeCodeUnspsc190501.50371706': __('identifierSchemeCodeUnspsc190501.50371706'),
	'identifierSchemeCodeUnspsc190501.50371707': __('identifierSchemeCodeUnspsc190501.50371707'),
	'identifierSchemeCodeUnspsc190501.50371708': __('identifierSchemeCodeUnspsc190501.50371708'),
	'identifierSchemeCodeUnspsc190501.50371709': __('identifierSchemeCodeUnspsc190501.50371709'),
	'identifierSchemeCodeUnspsc190501.50371710': __('identifierSchemeCodeUnspsc190501.50371710'),
	'identifierSchemeCodeUnspsc190501.50371711': __('identifierSchemeCodeUnspsc190501.50371711'),
	'identifierSchemeCodeUnspsc190501.50371712': __('identifierSchemeCodeUnspsc190501.50371712'),
	'identifierSchemeCodeUnspsc190501.50371713': __('identifierSchemeCodeUnspsc190501.50371713'),
	'identifierSchemeCodeUnspsc190501.50371714': __('identifierSchemeCodeUnspsc190501.50371714'),
	'identifierSchemeCodeUnspsc190501.50371715': __('identifierSchemeCodeUnspsc190501.50371715'),
	'identifierSchemeCodeUnspsc190501.50371800': __('identifierSchemeCodeUnspsc190501.50371800'),
	'identifierSchemeCodeUnspsc190501.50371801': __('identifierSchemeCodeUnspsc190501.50371801'),
	'identifierSchemeCodeUnspsc190501.50371802': __('identifierSchemeCodeUnspsc190501.50371802'),
	'identifierSchemeCodeUnspsc190501.50371803': __('identifierSchemeCodeUnspsc190501.50371803'),
	'identifierSchemeCodeUnspsc190501.50371804': __('identifierSchemeCodeUnspsc190501.50371804'),
	'identifierSchemeCodeUnspsc190501.50371805': __('identifierSchemeCodeUnspsc190501.50371805'),
	'identifierSchemeCodeUnspsc190501.50371806': __('identifierSchemeCodeUnspsc190501.50371806'),
	'identifierSchemeCodeUnspsc190501.50371807': __('identifierSchemeCodeUnspsc190501.50371807'),
	'identifierSchemeCodeUnspsc190501.50371808': __('identifierSchemeCodeUnspsc190501.50371808'),
	'identifierSchemeCodeUnspsc190501.50371809': __('identifierSchemeCodeUnspsc190501.50371809'),
	'identifierSchemeCodeUnspsc190501.50371810': __('identifierSchemeCodeUnspsc190501.50371810'),
	'identifierSchemeCodeUnspsc190501.50371811': __('identifierSchemeCodeUnspsc190501.50371811'),
	'identifierSchemeCodeUnspsc190501.50371812': __('identifierSchemeCodeUnspsc190501.50371812'),
	'identifierSchemeCodeUnspsc190501.50371813': __('identifierSchemeCodeUnspsc190501.50371813'),
	'identifierSchemeCodeUnspsc190501.50371814': __('identifierSchemeCodeUnspsc190501.50371814'),
	'identifierSchemeCodeUnspsc190501.50371900': __('identifierSchemeCodeUnspsc190501.50371900'),
	'identifierSchemeCodeUnspsc190501.50371901': __('identifierSchemeCodeUnspsc190501.50371901'),
	'identifierSchemeCodeUnspsc190501.50371902': __('identifierSchemeCodeUnspsc190501.50371902'),
	'identifierSchemeCodeUnspsc190501.50371903': __('identifierSchemeCodeUnspsc190501.50371903'),
	'identifierSchemeCodeUnspsc190501.50372000': __('identifierSchemeCodeUnspsc190501.50372000'),
	'identifierSchemeCodeUnspsc190501.50372001': __('identifierSchemeCodeUnspsc190501.50372001'),
	'identifierSchemeCodeUnspsc190501.50372002': __('identifierSchemeCodeUnspsc190501.50372002'),
	'identifierSchemeCodeUnspsc190501.50372003': __('identifierSchemeCodeUnspsc190501.50372003'),
	'identifierSchemeCodeUnspsc190501.50372004': __('identifierSchemeCodeUnspsc190501.50372004'),
	'identifierSchemeCodeUnspsc190501.50372005': __('identifierSchemeCodeUnspsc190501.50372005'),
	'identifierSchemeCodeUnspsc190501.50372006': __('identifierSchemeCodeUnspsc190501.50372006'),
	'identifierSchemeCodeUnspsc190501.50372007': __('identifierSchemeCodeUnspsc190501.50372007'),
	'identifierSchemeCodeUnspsc190501.50372008': __('identifierSchemeCodeUnspsc190501.50372008'),
	'identifierSchemeCodeUnspsc190501.50372009': __('identifierSchemeCodeUnspsc190501.50372009'),
	'identifierSchemeCodeUnspsc190501.50372010': __('identifierSchemeCodeUnspsc190501.50372010'),
	'identifierSchemeCodeUnspsc190501.50372011': __('identifierSchemeCodeUnspsc190501.50372011'),
	'identifierSchemeCodeUnspsc190501.50372012': __('identifierSchemeCodeUnspsc190501.50372012'),
	'identifierSchemeCodeUnspsc190501.50372013': __('identifierSchemeCodeUnspsc190501.50372013'),
	'identifierSchemeCodeUnspsc190501.50372014': __('identifierSchemeCodeUnspsc190501.50372014'),
	'identifierSchemeCodeUnspsc190501.50372015': __('identifierSchemeCodeUnspsc190501.50372015'),
	'identifierSchemeCodeUnspsc190501.50372100': __('identifierSchemeCodeUnspsc190501.50372100'),
	'identifierSchemeCodeUnspsc190501.50372101': __('identifierSchemeCodeUnspsc190501.50372101'),
	'identifierSchemeCodeUnspsc190501.50372102': __('identifierSchemeCodeUnspsc190501.50372102'),
	'identifierSchemeCodeUnspsc190501.50372103': __('identifierSchemeCodeUnspsc190501.50372103'),
	'identifierSchemeCodeUnspsc190501.50372104': __('identifierSchemeCodeUnspsc190501.50372104'),
	'identifierSchemeCodeUnspsc190501.50372200': __('identifierSchemeCodeUnspsc190501.50372200'),
	'identifierSchemeCodeUnspsc190501.50372201': __('identifierSchemeCodeUnspsc190501.50372201'),
	'identifierSchemeCodeUnspsc190501.50372202': __('identifierSchemeCodeUnspsc190501.50372202'),
	'identifierSchemeCodeUnspsc190501.50372203': __('identifierSchemeCodeUnspsc190501.50372203'),
	'identifierSchemeCodeUnspsc190501.50372204': __('identifierSchemeCodeUnspsc190501.50372204'),
	'identifierSchemeCodeUnspsc190501.50372205': __('identifierSchemeCodeUnspsc190501.50372205'),
	'identifierSchemeCodeUnspsc190501.50372206': __('identifierSchemeCodeUnspsc190501.50372206'),
	'identifierSchemeCodeUnspsc190501.50372207': __('identifierSchemeCodeUnspsc190501.50372207'),
	'identifierSchemeCodeUnspsc190501.50372208': __('identifierSchemeCodeUnspsc190501.50372208'),
	'identifierSchemeCodeUnspsc190501.50372209': __('identifierSchemeCodeUnspsc190501.50372209'),
	'identifierSchemeCodeUnspsc190501.50372210': __('identifierSchemeCodeUnspsc190501.50372210'),
	'identifierSchemeCodeUnspsc190501.50372211': __('identifierSchemeCodeUnspsc190501.50372211'),
	'identifierSchemeCodeUnspsc190501.50372212': __('identifierSchemeCodeUnspsc190501.50372212'),
	'identifierSchemeCodeUnspsc190501.50372213': __('identifierSchemeCodeUnspsc190501.50372213'),
	'identifierSchemeCodeUnspsc190501.50372214': __('identifierSchemeCodeUnspsc190501.50372214'),
	'identifierSchemeCodeUnspsc190501.50372215': __('identifierSchemeCodeUnspsc190501.50372215'),
	'identifierSchemeCodeUnspsc190501.50372300': __('identifierSchemeCodeUnspsc190501.50372300'),
	'identifierSchemeCodeUnspsc190501.50372301': __('identifierSchemeCodeUnspsc190501.50372301'),
	'identifierSchemeCodeUnspsc190501.50372302': __('identifierSchemeCodeUnspsc190501.50372302'),
	'identifierSchemeCodeUnspsc190501.50372303': __('identifierSchemeCodeUnspsc190501.50372303'),
	'identifierSchemeCodeUnspsc190501.50372304': __('identifierSchemeCodeUnspsc190501.50372304'),
	'identifierSchemeCodeUnspsc190501.50372400': __('identifierSchemeCodeUnspsc190501.50372400'),
	'identifierSchemeCodeUnspsc190501.50372401': __('identifierSchemeCodeUnspsc190501.50372401'),
	'identifierSchemeCodeUnspsc190501.50372402': __('identifierSchemeCodeUnspsc190501.50372402'),
	'identifierSchemeCodeUnspsc190501.50372403': __('identifierSchemeCodeUnspsc190501.50372403'),
	'identifierSchemeCodeUnspsc190501.50372404': __('identifierSchemeCodeUnspsc190501.50372404'),
	'identifierSchemeCodeUnspsc190501.50372405': __('identifierSchemeCodeUnspsc190501.50372405'),
	'identifierSchemeCodeUnspsc190501.50372406': __('identifierSchemeCodeUnspsc190501.50372406'),
	'identifierSchemeCodeUnspsc190501.50372407': __('identifierSchemeCodeUnspsc190501.50372407'),
	'identifierSchemeCodeUnspsc190501.50372408': __('identifierSchemeCodeUnspsc190501.50372408'),
	'identifierSchemeCodeUnspsc190501.50372409': __('identifierSchemeCodeUnspsc190501.50372409'),
	'identifierSchemeCodeUnspsc190501.50372500': __('identifierSchemeCodeUnspsc190501.50372500'),
	'identifierSchemeCodeUnspsc190501.50372501': __('identifierSchemeCodeUnspsc190501.50372501'),
	'identifierSchemeCodeUnspsc190501.50372502': __('identifierSchemeCodeUnspsc190501.50372502'),
	'identifierSchemeCodeUnspsc190501.50372503': __('identifierSchemeCodeUnspsc190501.50372503'),
	'identifierSchemeCodeUnspsc190501.50372504': __('identifierSchemeCodeUnspsc190501.50372504'),
	'identifierSchemeCodeUnspsc190501.50372505': __('identifierSchemeCodeUnspsc190501.50372505'),
	'identifierSchemeCodeUnspsc190501.50372506': __('identifierSchemeCodeUnspsc190501.50372506'),
	'identifierSchemeCodeUnspsc190501.50372507': __('identifierSchemeCodeUnspsc190501.50372507'),
	'identifierSchemeCodeUnspsc190501.50372508': __('identifierSchemeCodeUnspsc190501.50372508'),
	'identifierSchemeCodeUnspsc190501.50372509': __('identifierSchemeCodeUnspsc190501.50372509'),
	'identifierSchemeCodeUnspsc190501.50372510': __('identifierSchemeCodeUnspsc190501.50372510'),
	'identifierSchemeCodeUnspsc190501.50372511': __('identifierSchemeCodeUnspsc190501.50372511'),
	'identifierSchemeCodeUnspsc190501.50372512': __('identifierSchemeCodeUnspsc190501.50372512'),
	'identifierSchemeCodeUnspsc190501.50372513': __('identifierSchemeCodeUnspsc190501.50372513'),
	'identifierSchemeCodeUnspsc190501.50372514': __('identifierSchemeCodeUnspsc190501.50372514'),
	'identifierSchemeCodeUnspsc190501.50372515': __('identifierSchemeCodeUnspsc190501.50372515'),
	'identifierSchemeCodeUnspsc190501.50372516': __('identifierSchemeCodeUnspsc190501.50372516'),
	'identifierSchemeCodeUnspsc190501.50372517': __('identifierSchemeCodeUnspsc190501.50372517'),
	'identifierSchemeCodeUnspsc190501.50372518': __('identifierSchemeCodeUnspsc190501.50372518'),
	'identifierSchemeCodeUnspsc190501.50372519': __('identifierSchemeCodeUnspsc190501.50372519'),
	'identifierSchemeCodeUnspsc190501.50372520': __('identifierSchemeCodeUnspsc190501.50372520'),
	'identifierSchemeCodeUnspsc190501.50372521': __('identifierSchemeCodeUnspsc190501.50372521'),
	'identifierSchemeCodeUnspsc190501.50372522': __('identifierSchemeCodeUnspsc190501.50372522'),
	'identifierSchemeCodeUnspsc190501.50372523': __('identifierSchemeCodeUnspsc190501.50372523'),
	'identifierSchemeCodeUnspsc190501.50372524': __('identifierSchemeCodeUnspsc190501.50372524'),
	'identifierSchemeCodeUnspsc190501.50372525': __('identifierSchemeCodeUnspsc190501.50372525'),
	'identifierSchemeCodeUnspsc190501.50372527': __('identifierSchemeCodeUnspsc190501.50372527'),
	'identifierSchemeCodeUnspsc190501.50372528': __('identifierSchemeCodeUnspsc190501.50372528'),
	'identifierSchemeCodeUnspsc190501.50372529': __('identifierSchemeCodeUnspsc190501.50372529'),
	'identifierSchemeCodeUnspsc190501.50372530': __('identifierSchemeCodeUnspsc190501.50372530'),
	'identifierSchemeCodeUnspsc190501.50372531': __('identifierSchemeCodeUnspsc190501.50372531'),
	'identifierSchemeCodeUnspsc190501.50372532': __('identifierSchemeCodeUnspsc190501.50372532'),
	'identifierSchemeCodeUnspsc190501.50372600': __('identifierSchemeCodeUnspsc190501.50372600'),
	'identifierSchemeCodeUnspsc190501.50372601': __('identifierSchemeCodeUnspsc190501.50372601'),
	'identifierSchemeCodeUnspsc190501.50372602': __('identifierSchemeCodeUnspsc190501.50372602'),
	'identifierSchemeCodeUnspsc190501.50372603': __('identifierSchemeCodeUnspsc190501.50372603'),
	'identifierSchemeCodeUnspsc190501.50372604': __('identifierSchemeCodeUnspsc190501.50372604'),
	'identifierSchemeCodeUnspsc190501.50372605': __('identifierSchemeCodeUnspsc190501.50372605'),
	'identifierSchemeCodeUnspsc190501.50372606': __('identifierSchemeCodeUnspsc190501.50372606'),
	'identifierSchemeCodeUnspsc190501.50372607': __('identifierSchemeCodeUnspsc190501.50372607'),
	'identifierSchemeCodeUnspsc190501.50372700': __('identifierSchemeCodeUnspsc190501.50372700'),
	'identifierSchemeCodeUnspsc190501.50372701': __('identifierSchemeCodeUnspsc190501.50372701'),
	'identifierSchemeCodeUnspsc190501.50372702': __('identifierSchemeCodeUnspsc190501.50372702'),
	'identifierSchemeCodeUnspsc190501.50372703': __('identifierSchemeCodeUnspsc190501.50372703'),
	'identifierSchemeCodeUnspsc190501.50372704': __('identifierSchemeCodeUnspsc190501.50372704'),
	'identifierSchemeCodeUnspsc190501.50372705': __('identifierSchemeCodeUnspsc190501.50372705'),
	'identifierSchemeCodeUnspsc190501.50372706': __('identifierSchemeCodeUnspsc190501.50372706'),
	'identifierSchemeCodeUnspsc190501.50372707': __('identifierSchemeCodeUnspsc190501.50372707'),
	'identifierSchemeCodeUnspsc190501.50372708': __('identifierSchemeCodeUnspsc190501.50372708'),
	'identifierSchemeCodeUnspsc190501.50372709': __('identifierSchemeCodeUnspsc190501.50372709'),
	'identifierSchemeCodeUnspsc190501.50372710': __('identifierSchemeCodeUnspsc190501.50372710'),
	'identifierSchemeCodeUnspsc190501.50372800': __('identifierSchemeCodeUnspsc190501.50372800'),
	'identifierSchemeCodeUnspsc190501.50372801': __('identifierSchemeCodeUnspsc190501.50372801'),
	'identifierSchemeCodeUnspsc190501.50372802': __('identifierSchemeCodeUnspsc190501.50372802'),
	'identifierSchemeCodeUnspsc190501.50372803': __('identifierSchemeCodeUnspsc190501.50372803'),
	'identifierSchemeCodeUnspsc190501.50372804': __('identifierSchemeCodeUnspsc190501.50372804'),
	'identifierSchemeCodeUnspsc190501.50372805': __('identifierSchemeCodeUnspsc190501.50372805'),
	'identifierSchemeCodeUnspsc190501.50372806': __('identifierSchemeCodeUnspsc190501.50372806'),
	'identifierSchemeCodeUnspsc190501.50372900': __('identifierSchemeCodeUnspsc190501.50372900'),
	'identifierSchemeCodeUnspsc190501.50372901': __('identifierSchemeCodeUnspsc190501.50372901'),
	'identifierSchemeCodeUnspsc190501.50372902': __('identifierSchemeCodeUnspsc190501.50372902'),
	'identifierSchemeCodeUnspsc190501.50372903': __('identifierSchemeCodeUnspsc190501.50372903'),
	'identifierSchemeCodeUnspsc190501.50372904': __('identifierSchemeCodeUnspsc190501.50372904'),
	'identifierSchemeCodeUnspsc190501.50372905': __('identifierSchemeCodeUnspsc190501.50372905'),
	'identifierSchemeCodeUnspsc190501.50372906': __('identifierSchemeCodeUnspsc190501.50372906'),
	'identifierSchemeCodeUnspsc190501.50372907': __('identifierSchemeCodeUnspsc190501.50372907'),
	'identifierSchemeCodeUnspsc190501.50372908': __('identifierSchemeCodeUnspsc190501.50372908'),
	'identifierSchemeCodeUnspsc190501.50372909': __('identifierSchemeCodeUnspsc190501.50372909'),
	'identifierSchemeCodeUnspsc190501.50372910': __('identifierSchemeCodeUnspsc190501.50372910'),
	'identifierSchemeCodeUnspsc190501.50372911': __('identifierSchemeCodeUnspsc190501.50372911'),
	'identifierSchemeCodeUnspsc190501.50372912': __('identifierSchemeCodeUnspsc190501.50372912'),
	'identifierSchemeCodeUnspsc190501.50372913': __('identifierSchemeCodeUnspsc190501.50372913'),
	'identifierSchemeCodeUnspsc190501.50372914': __('identifierSchemeCodeUnspsc190501.50372914'),
	'identifierSchemeCodeUnspsc190501.50372915': __('identifierSchemeCodeUnspsc190501.50372915'),
	'identifierSchemeCodeUnspsc190501.50372916': __('identifierSchemeCodeUnspsc190501.50372916'),
	'identifierSchemeCodeUnspsc190501.50373000': __('identifierSchemeCodeUnspsc190501.50373000'),
	'identifierSchemeCodeUnspsc190501.50373001': __('identifierSchemeCodeUnspsc190501.50373001'),
	'identifierSchemeCodeUnspsc190501.50373002': __('identifierSchemeCodeUnspsc190501.50373002'),
	'identifierSchemeCodeUnspsc190501.50373100': __('identifierSchemeCodeUnspsc190501.50373100'),
	'identifierSchemeCodeUnspsc190501.50373101': __('identifierSchemeCodeUnspsc190501.50373101'),
	'identifierSchemeCodeUnspsc190501.50373102': __('identifierSchemeCodeUnspsc190501.50373102'),
	'identifierSchemeCodeUnspsc190501.50373103': __('identifierSchemeCodeUnspsc190501.50373103'),
	'identifierSchemeCodeUnspsc190501.50373104': __('identifierSchemeCodeUnspsc190501.50373104'),
	'identifierSchemeCodeUnspsc190501.50373105': __('identifierSchemeCodeUnspsc190501.50373105'),
	'identifierSchemeCodeUnspsc190501.50373106': __('identifierSchemeCodeUnspsc190501.50373106'),
	'identifierSchemeCodeUnspsc190501.50373107': __('identifierSchemeCodeUnspsc190501.50373107'),
	'identifierSchemeCodeUnspsc190501.50373108': __('identifierSchemeCodeUnspsc190501.50373108'),
	'identifierSchemeCodeUnspsc190501.50373109': __('identifierSchemeCodeUnspsc190501.50373109'),
	'identifierSchemeCodeUnspsc190501.50373110': __('identifierSchemeCodeUnspsc190501.50373110'),
	'identifierSchemeCodeUnspsc190501.50373111': __('identifierSchemeCodeUnspsc190501.50373111'),
	'identifierSchemeCodeUnspsc190501.50373200': __('identifierSchemeCodeUnspsc190501.50373200'),
	'identifierSchemeCodeUnspsc190501.50373201': __('identifierSchemeCodeUnspsc190501.50373201'),
	'identifierSchemeCodeUnspsc190501.50373202': __('identifierSchemeCodeUnspsc190501.50373202'),
	'identifierSchemeCodeUnspsc190501.50373203': __('identifierSchemeCodeUnspsc190501.50373203'),
	'identifierSchemeCodeUnspsc190501.50373204': __('identifierSchemeCodeUnspsc190501.50373204'),
	'identifierSchemeCodeUnspsc190501.50373205': __('identifierSchemeCodeUnspsc190501.50373205'),
	'identifierSchemeCodeUnspsc190501.50373206': __('identifierSchemeCodeUnspsc190501.50373206'),
	'identifierSchemeCodeUnspsc190501.50373207': __('identifierSchemeCodeUnspsc190501.50373207'),
	'identifierSchemeCodeUnspsc190501.50373300': __('identifierSchemeCodeUnspsc190501.50373300'),
	'identifierSchemeCodeUnspsc190501.50373301': __('identifierSchemeCodeUnspsc190501.50373301'),
	'identifierSchemeCodeUnspsc190501.50373302': __('identifierSchemeCodeUnspsc190501.50373302'),
	'identifierSchemeCodeUnspsc190501.50373303': __('identifierSchemeCodeUnspsc190501.50373303'),
	'identifierSchemeCodeUnspsc190501.50373304': __('identifierSchemeCodeUnspsc190501.50373304'),
	'identifierSchemeCodeUnspsc190501.50373305': __('identifierSchemeCodeUnspsc190501.50373305'),
	'identifierSchemeCodeUnspsc190501.50373306': __('identifierSchemeCodeUnspsc190501.50373306'),
	'identifierSchemeCodeUnspsc190501.50373307': __('identifierSchemeCodeUnspsc190501.50373307'),
	'identifierSchemeCodeUnspsc190501.50373308': __('identifierSchemeCodeUnspsc190501.50373308'),
	'identifierSchemeCodeUnspsc190501.50373309': __('identifierSchemeCodeUnspsc190501.50373309'),
	'identifierSchemeCodeUnspsc190501.50373400': __('identifierSchemeCodeUnspsc190501.50373400'),
	'identifierSchemeCodeUnspsc190501.50373401': __('identifierSchemeCodeUnspsc190501.50373401'),
	'identifierSchemeCodeUnspsc190501.50373402': __('identifierSchemeCodeUnspsc190501.50373402'),
	'identifierSchemeCodeUnspsc190501.50373403': __('identifierSchemeCodeUnspsc190501.50373403'),
	'identifierSchemeCodeUnspsc190501.50373404': __('identifierSchemeCodeUnspsc190501.50373404'),
	'identifierSchemeCodeUnspsc190501.50373405': __('identifierSchemeCodeUnspsc190501.50373405'),
	'identifierSchemeCodeUnspsc190501.50373406': __('identifierSchemeCodeUnspsc190501.50373406'),
	'identifierSchemeCodeUnspsc190501.50373407': __('identifierSchemeCodeUnspsc190501.50373407'),
	'identifierSchemeCodeUnspsc190501.50373408': __('identifierSchemeCodeUnspsc190501.50373408'),
	'identifierSchemeCodeUnspsc190501.50373409': __('identifierSchemeCodeUnspsc190501.50373409'),
	'identifierSchemeCodeUnspsc190501.50373410': __('identifierSchemeCodeUnspsc190501.50373410'),
	'identifierSchemeCodeUnspsc190501.50373411': __('identifierSchemeCodeUnspsc190501.50373411'),
	'identifierSchemeCodeUnspsc190501.50373412': __('identifierSchemeCodeUnspsc190501.50373412'),
	'identifierSchemeCodeUnspsc190501.50373413': __('identifierSchemeCodeUnspsc190501.50373413'),
	'identifierSchemeCodeUnspsc190501.50373414': __('identifierSchemeCodeUnspsc190501.50373414'),
	'identifierSchemeCodeUnspsc190501.50373415': __('identifierSchemeCodeUnspsc190501.50373415'),
	'identifierSchemeCodeUnspsc190501.50373416': __('identifierSchemeCodeUnspsc190501.50373416'),
	'identifierSchemeCodeUnspsc190501.50373417': __('identifierSchemeCodeUnspsc190501.50373417'),
	'identifierSchemeCodeUnspsc190501.50373418': __('identifierSchemeCodeUnspsc190501.50373418'),
	'identifierSchemeCodeUnspsc190501.50373419': __('identifierSchemeCodeUnspsc190501.50373419'),
	'identifierSchemeCodeUnspsc190501.50373420': __('identifierSchemeCodeUnspsc190501.50373420'),
	'identifierSchemeCodeUnspsc190501.50373421': __('identifierSchemeCodeUnspsc190501.50373421'),
	'identifierSchemeCodeUnspsc190501.50373422': __('identifierSchemeCodeUnspsc190501.50373422'),
	'identifierSchemeCodeUnspsc190501.50373423': __('identifierSchemeCodeUnspsc190501.50373423'),
	'identifierSchemeCodeUnspsc190501.50373424': __('identifierSchemeCodeUnspsc190501.50373424'),
	'identifierSchemeCodeUnspsc190501.50373425': __('identifierSchemeCodeUnspsc190501.50373425'),
	'identifierSchemeCodeUnspsc190501.50373426': __('identifierSchemeCodeUnspsc190501.50373426'),
	'identifierSchemeCodeUnspsc190501.50373427': __('identifierSchemeCodeUnspsc190501.50373427'),
	'identifierSchemeCodeUnspsc190501.50373428': __('identifierSchemeCodeUnspsc190501.50373428'),
	'identifierSchemeCodeUnspsc190501.50373429': __('identifierSchemeCodeUnspsc190501.50373429'),
	'identifierSchemeCodeUnspsc190501.50373430': __('identifierSchemeCodeUnspsc190501.50373430'),
	'identifierSchemeCodeUnspsc190501.50373431': __('identifierSchemeCodeUnspsc190501.50373431'),
	'identifierSchemeCodeUnspsc190501.50373432': __('identifierSchemeCodeUnspsc190501.50373432'),
	'identifierSchemeCodeUnspsc190501.50373433': __('identifierSchemeCodeUnspsc190501.50373433'),
	'identifierSchemeCodeUnspsc190501.50373434': __('identifierSchemeCodeUnspsc190501.50373434'),
	'identifierSchemeCodeUnspsc190501.50373435': __('identifierSchemeCodeUnspsc190501.50373435'),
	'identifierSchemeCodeUnspsc190501.50373436': __('identifierSchemeCodeUnspsc190501.50373436'),
	'identifierSchemeCodeUnspsc190501.50373437': __('identifierSchemeCodeUnspsc190501.50373437'),
	'identifierSchemeCodeUnspsc190501.50373438': __('identifierSchemeCodeUnspsc190501.50373438'),
	'identifierSchemeCodeUnspsc190501.50373439': __('identifierSchemeCodeUnspsc190501.50373439'),
	'identifierSchemeCodeUnspsc190501.50373440': __('identifierSchemeCodeUnspsc190501.50373440'),
	'identifierSchemeCodeUnspsc190501.50373441': __('identifierSchemeCodeUnspsc190501.50373441'),
	'identifierSchemeCodeUnspsc190501.50373442': __('identifierSchemeCodeUnspsc190501.50373442'),
	'identifierSchemeCodeUnspsc190501.50373443': __('identifierSchemeCodeUnspsc190501.50373443'),
	'identifierSchemeCodeUnspsc190501.50373444': __('identifierSchemeCodeUnspsc190501.50373444'),
	'identifierSchemeCodeUnspsc190501.50373445': __('identifierSchemeCodeUnspsc190501.50373445'),
	'identifierSchemeCodeUnspsc190501.50373446': __('identifierSchemeCodeUnspsc190501.50373446'),
	'identifierSchemeCodeUnspsc190501.50373447': __('identifierSchemeCodeUnspsc190501.50373447'),
	'identifierSchemeCodeUnspsc190501.50373448': __('identifierSchemeCodeUnspsc190501.50373448'),
	'identifierSchemeCodeUnspsc190501.50373449': __('identifierSchemeCodeUnspsc190501.50373449'),
	'identifierSchemeCodeUnspsc190501.50373450': __('identifierSchemeCodeUnspsc190501.50373450'),
	'identifierSchemeCodeUnspsc190501.50373451': __('identifierSchemeCodeUnspsc190501.50373451'),
	'identifierSchemeCodeUnspsc190501.50373452': __('identifierSchemeCodeUnspsc190501.50373452'),
	'identifierSchemeCodeUnspsc190501.50373453': __('identifierSchemeCodeUnspsc190501.50373453'),
	'identifierSchemeCodeUnspsc190501.50373454': __('identifierSchemeCodeUnspsc190501.50373454'),
	'identifierSchemeCodeUnspsc190501.50373455': __('identifierSchemeCodeUnspsc190501.50373455'),
	'identifierSchemeCodeUnspsc190501.50373456': __('identifierSchemeCodeUnspsc190501.50373456'),
	'identifierSchemeCodeUnspsc190501.50373457': __('identifierSchemeCodeUnspsc190501.50373457'),
	'identifierSchemeCodeUnspsc190501.50373458': __('identifierSchemeCodeUnspsc190501.50373458'),
	'identifierSchemeCodeUnspsc190501.50373459': __('identifierSchemeCodeUnspsc190501.50373459'),
	'identifierSchemeCodeUnspsc190501.50373460': __('identifierSchemeCodeUnspsc190501.50373460'),
	'identifierSchemeCodeUnspsc190501.50373461': __('identifierSchemeCodeUnspsc190501.50373461'),
	'identifierSchemeCodeUnspsc190501.50373462': __('identifierSchemeCodeUnspsc190501.50373462'),
	'identifierSchemeCodeUnspsc190501.50373463': __('identifierSchemeCodeUnspsc190501.50373463'),
	'identifierSchemeCodeUnspsc190501.50373464': __('identifierSchemeCodeUnspsc190501.50373464'),
	'identifierSchemeCodeUnspsc190501.50373465': __('identifierSchemeCodeUnspsc190501.50373465'),
	'identifierSchemeCodeUnspsc190501.50373466': __('identifierSchemeCodeUnspsc190501.50373466'),
	'identifierSchemeCodeUnspsc190501.50373467': __('identifierSchemeCodeUnspsc190501.50373467'),
	'identifierSchemeCodeUnspsc190501.50373468': __('identifierSchemeCodeUnspsc190501.50373468'),
	'identifierSchemeCodeUnspsc190501.50373469': __('identifierSchemeCodeUnspsc190501.50373469'),
	'identifierSchemeCodeUnspsc190501.50373470': __('identifierSchemeCodeUnspsc190501.50373470'),
	'identifierSchemeCodeUnspsc190501.50373471': __('identifierSchemeCodeUnspsc190501.50373471'),
	'identifierSchemeCodeUnspsc190501.50373472': __('identifierSchemeCodeUnspsc190501.50373472'),
	'identifierSchemeCodeUnspsc190501.50373473': __('identifierSchemeCodeUnspsc190501.50373473'),
	'identifierSchemeCodeUnspsc190501.50373474': __('identifierSchemeCodeUnspsc190501.50373474'),
	'identifierSchemeCodeUnspsc190501.50373475': __('identifierSchemeCodeUnspsc190501.50373475'),
	'identifierSchemeCodeUnspsc190501.50373476': __('identifierSchemeCodeUnspsc190501.50373476'),
	'identifierSchemeCodeUnspsc190501.50373477': __('identifierSchemeCodeUnspsc190501.50373477'),
	'identifierSchemeCodeUnspsc190501.50373478': __('identifierSchemeCodeUnspsc190501.50373478'),
	'identifierSchemeCodeUnspsc190501.50373479': __('identifierSchemeCodeUnspsc190501.50373479'),
	'identifierSchemeCodeUnspsc190501.50373480': __('identifierSchemeCodeUnspsc190501.50373480'),
	'identifierSchemeCodeUnspsc190501.50373481': __('identifierSchemeCodeUnspsc190501.50373481'),
	'identifierSchemeCodeUnspsc190501.50373482': __('identifierSchemeCodeUnspsc190501.50373482'),
	'identifierSchemeCodeUnspsc190501.50373483': __('identifierSchemeCodeUnspsc190501.50373483'),
	'identifierSchemeCodeUnspsc190501.50373484': __('identifierSchemeCodeUnspsc190501.50373484'),
	'identifierSchemeCodeUnspsc190501.50373485': __('identifierSchemeCodeUnspsc190501.50373485'),
	'identifierSchemeCodeUnspsc190501.50373486': __('identifierSchemeCodeUnspsc190501.50373486'),
	'identifierSchemeCodeUnspsc190501.50373487': __('identifierSchemeCodeUnspsc190501.50373487'),
	'identifierSchemeCodeUnspsc190501.50373488': __('identifierSchemeCodeUnspsc190501.50373488'),
	'identifierSchemeCodeUnspsc190501.50373489': __('identifierSchemeCodeUnspsc190501.50373489'),
	'identifierSchemeCodeUnspsc190501.50373490': __('identifierSchemeCodeUnspsc190501.50373490'),
	'identifierSchemeCodeUnspsc190501.50373491': __('identifierSchemeCodeUnspsc190501.50373491'),
	'identifierSchemeCodeUnspsc190501.50373492': __('identifierSchemeCodeUnspsc190501.50373492'),
	'identifierSchemeCodeUnspsc190501.50373493': __('identifierSchemeCodeUnspsc190501.50373493'),
	'identifierSchemeCodeUnspsc190501.50373494': __('identifierSchemeCodeUnspsc190501.50373494'),
	'identifierSchemeCodeUnspsc190501.50373495': __('identifierSchemeCodeUnspsc190501.50373495'),
	'identifierSchemeCodeUnspsc190501.50373496': __('identifierSchemeCodeUnspsc190501.50373496'),
	'identifierSchemeCodeUnspsc190501.50373500': __('identifierSchemeCodeUnspsc190501.50373500'),
	'identifierSchemeCodeUnspsc190501.50373501': __('identifierSchemeCodeUnspsc190501.50373501'),
	'identifierSchemeCodeUnspsc190501.50373502': __('identifierSchemeCodeUnspsc190501.50373502'),
	'identifierSchemeCodeUnspsc190501.50373503': __('identifierSchemeCodeUnspsc190501.50373503'),
	'identifierSchemeCodeUnspsc190501.50373504': __('identifierSchemeCodeUnspsc190501.50373504'),
	'identifierSchemeCodeUnspsc190501.50373505': __('identifierSchemeCodeUnspsc190501.50373505'),
	'identifierSchemeCodeUnspsc190501.50373506': __('identifierSchemeCodeUnspsc190501.50373506'),
	'identifierSchemeCodeUnspsc190501.50373600': __('identifierSchemeCodeUnspsc190501.50373600'),
	'identifierSchemeCodeUnspsc190501.50373601': __('identifierSchemeCodeUnspsc190501.50373601'),
	'identifierSchemeCodeUnspsc190501.50373602': __('identifierSchemeCodeUnspsc190501.50373602'),
	'identifierSchemeCodeUnspsc190501.50373603': __('identifierSchemeCodeUnspsc190501.50373603'),
	'identifierSchemeCodeUnspsc190501.50373604': __('identifierSchemeCodeUnspsc190501.50373604'),
	'identifierSchemeCodeUnspsc190501.50373605': __('identifierSchemeCodeUnspsc190501.50373605'),
	'identifierSchemeCodeUnspsc190501.50373606': __('identifierSchemeCodeUnspsc190501.50373606'),
	'identifierSchemeCodeUnspsc190501.50373607': __('identifierSchemeCodeUnspsc190501.50373607'),
	'identifierSchemeCodeUnspsc190501.50373608': __('identifierSchemeCodeUnspsc190501.50373608'),
	'identifierSchemeCodeUnspsc190501.50373609': __('identifierSchemeCodeUnspsc190501.50373609'),
	'identifierSchemeCodeUnspsc190501.50373610': __('identifierSchemeCodeUnspsc190501.50373610'),
	'identifierSchemeCodeUnspsc190501.50373611': __('identifierSchemeCodeUnspsc190501.50373611'),
	'identifierSchemeCodeUnspsc190501.50373612': __('identifierSchemeCodeUnspsc190501.50373612'),
	'identifierSchemeCodeUnspsc190501.50373613': __('identifierSchemeCodeUnspsc190501.50373613'),
	'identifierSchemeCodeUnspsc190501.50373614': __('identifierSchemeCodeUnspsc190501.50373614'),
	'identifierSchemeCodeUnspsc190501.50373615': __('identifierSchemeCodeUnspsc190501.50373615'),
	'identifierSchemeCodeUnspsc190501.50373616': __('identifierSchemeCodeUnspsc190501.50373616'),
	'identifierSchemeCodeUnspsc190501.50373617': __('identifierSchemeCodeUnspsc190501.50373617'),
	'identifierSchemeCodeUnspsc190501.50373618': __('identifierSchemeCodeUnspsc190501.50373618'),
	'identifierSchemeCodeUnspsc190501.50373619': __('identifierSchemeCodeUnspsc190501.50373619'),
	'identifierSchemeCodeUnspsc190501.50373620': __('identifierSchemeCodeUnspsc190501.50373620'),
	'identifierSchemeCodeUnspsc190501.50373621': __('identifierSchemeCodeUnspsc190501.50373621'),
	'identifierSchemeCodeUnspsc190501.50373622': __('identifierSchemeCodeUnspsc190501.50373622'),
	'identifierSchemeCodeUnspsc190501.50373623': __('identifierSchemeCodeUnspsc190501.50373623'),
	'identifierSchemeCodeUnspsc190501.50373624': __('identifierSchemeCodeUnspsc190501.50373624'),
	'identifierSchemeCodeUnspsc190501.50373625': __('identifierSchemeCodeUnspsc190501.50373625'),
	'identifierSchemeCodeUnspsc190501.50373626': __('identifierSchemeCodeUnspsc190501.50373626'),
	'identifierSchemeCodeUnspsc190501.50373627': __('identifierSchemeCodeUnspsc190501.50373627'),
	'identifierSchemeCodeUnspsc190501.50373628': __('identifierSchemeCodeUnspsc190501.50373628'),
	'identifierSchemeCodeUnspsc190501.50373629': __('identifierSchemeCodeUnspsc190501.50373629'),
	'identifierSchemeCodeUnspsc190501.50373630': __('identifierSchemeCodeUnspsc190501.50373630'),
	'identifierSchemeCodeUnspsc190501.50373631': __('identifierSchemeCodeUnspsc190501.50373631'),
	'identifierSchemeCodeUnspsc190501.50373632': __('identifierSchemeCodeUnspsc190501.50373632'),
	'identifierSchemeCodeUnspsc190501.50373633': __('identifierSchemeCodeUnspsc190501.50373633'),
	'identifierSchemeCodeUnspsc190501.50373634': __('identifierSchemeCodeUnspsc190501.50373634'),
	'identifierSchemeCodeUnspsc190501.50373635': __('identifierSchemeCodeUnspsc190501.50373635'),
	'identifierSchemeCodeUnspsc190501.50373636': __('identifierSchemeCodeUnspsc190501.50373636'),
	'identifierSchemeCodeUnspsc190501.50373637': __('identifierSchemeCodeUnspsc190501.50373637'),
	'identifierSchemeCodeUnspsc190501.50373638': __('identifierSchemeCodeUnspsc190501.50373638'),
	'identifierSchemeCodeUnspsc190501.50373639': __('identifierSchemeCodeUnspsc190501.50373639'),
	'identifierSchemeCodeUnspsc190501.50373640': __('identifierSchemeCodeUnspsc190501.50373640'),
	'identifierSchemeCodeUnspsc190501.50373641': __('identifierSchemeCodeUnspsc190501.50373641'),
	'identifierSchemeCodeUnspsc190501.50373642': __('identifierSchemeCodeUnspsc190501.50373642'),
	'identifierSchemeCodeUnspsc190501.50373643': __('identifierSchemeCodeUnspsc190501.50373643'),
	'identifierSchemeCodeUnspsc190501.50373644': __('identifierSchemeCodeUnspsc190501.50373644'),
	'identifierSchemeCodeUnspsc190501.50373645': __('identifierSchemeCodeUnspsc190501.50373645'),
	'identifierSchemeCodeUnspsc190501.50373646': __('identifierSchemeCodeUnspsc190501.50373646'),
	'identifierSchemeCodeUnspsc190501.50373647': __('identifierSchemeCodeUnspsc190501.50373647'),
	'identifierSchemeCodeUnspsc190501.50373648': __('identifierSchemeCodeUnspsc190501.50373648'),
	'identifierSchemeCodeUnspsc190501.50373649': __('identifierSchemeCodeUnspsc190501.50373649'),
	'identifierSchemeCodeUnspsc190501.50373650': __('identifierSchemeCodeUnspsc190501.50373650'),
	'identifierSchemeCodeUnspsc190501.50373651': __('identifierSchemeCodeUnspsc190501.50373651'),
	'identifierSchemeCodeUnspsc190501.50373652': __('identifierSchemeCodeUnspsc190501.50373652'),
	'identifierSchemeCodeUnspsc190501.50373653': __('identifierSchemeCodeUnspsc190501.50373653'),
	'identifierSchemeCodeUnspsc190501.50373654': __('identifierSchemeCodeUnspsc190501.50373654'),
	'identifierSchemeCodeUnspsc190501.50373655': __('identifierSchemeCodeUnspsc190501.50373655'),
	'identifierSchemeCodeUnspsc190501.50373656': __('identifierSchemeCodeUnspsc190501.50373656'),
	'identifierSchemeCodeUnspsc190501.50373657': __('identifierSchemeCodeUnspsc190501.50373657'),
	'identifierSchemeCodeUnspsc190501.50373658': __('identifierSchemeCodeUnspsc190501.50373658'),
	'identifierSchemeCodeUnspsc190501.50373659': __('identifierSchemeCodeUnspsc190501.50373659'),
	'identifierSchemeCodeUnspsc190501.50373660': __('identifierSchemeCodeUnspsc190501.50373660'),
	'identifierSchemeCodeUnspsc190501.50373661': __('identifierSchemeCodeUnspsc190501.50373661'),
	'identifierSchemeCodeUnspsc190501.50373662': __('identifierSchemeCodeUnspsc190501.50373662'),
	'identifierSchemeCodeUnspsc190501.50373663': __('identifierSchemeCodeUnspsc190501.50373663'),
	'identifierSchemeCodeUnspsc190501.50373664': __('identifierSchemeCodeUnspsc190501.50373664'),
	'identifierSchemeCodeUnspsc190501.50373665': __('identifierSchemeCodeUnspsc190501.50373665'),
	'identifierSchemeCodeUnspsc190501.50373700': __('identifierSchemeCodeUnspsc190501.50373700'),
	'identifierSchemeCodeUnspsc190501.50373701': __('identifierSchemeCodeUnspsc190501.50373701'),
	'identifierSchemeCodeUnspsc190501.50373702': __('identifierSchemeCodeUnspsc190501.50373702'),
	'identifierSchemeCodeUnspsc190501.50373703': __('identifierSchemeCodeUnspsc190501.50373703'),
	'identifierSchemeCodeUnspsc190501.50373704': __('identifierSchemeCodeUnspsc190501.50373704'),
	'identifierSchemeCodeUnspsc190501.50373800': __('identifierSchemeCodeUnspsc190501.50373800'),
	'identifierSchemeCodeUnspsc190501.50373801': __('identifierSchemeCodeUnspsc190501.50373801'),
	'identifierSchemeCodeUnspsc190501.50373802': __('identifierSchemeCodeUnspsc190501.50373802'),
	'identifierSchemeCodeUnspsc190501.50373803': __('identifierSchemeCodeUnspsc190501.50373803'),
	'identifierSchemeCodeUnspsc190501.50373804': __('identifierSchemeCodeUnspsc190501.50373804'),
	'identifierSchemeCodeUnspsc190501.50373805': __('identifierSchemeCodeUnspsc190501.50373805'),
	'identifierSchemeCodeUnspsc190501.50373900': __('identifierSchemeCodeUnspsc190501.50373900'),
	'identifierSchemeCodeUnspsc190501.50373901': __('identifierSchemeCodeUnspsc190501.50373901'),
	'identifierSchemeCodeUnspsc190501.50373902': __('identifierSchemeCodeUnspsc190501.50373902'),
	'identifierSchemeCodeUnspsc190501.50373903': __('identifierSchemeCodeUnspsc190501.50373903'),
	'identifierSchemeCodeUnspsc190501.50373904': __('identifierSchemeCodeUnspsc190501.50373904'),
	'identifierSchemeCodeUnspsc190501.50373905': __('identifierSchemeCodeUnspsc190501.50373905'),
	'identifierSchemeCodeUnspsc190501.50373906': __('identifierSchemeCodeUnspsc190501.50373906'),
	'identifierSchemeCodeUnspsc190501.50374000': __('identifierSchemeCodeUnspsc190501.50374000'),
	'identifierSchemeCodeUnspsc190501.50374001': __('identifierSchemeCodeUnspsc190501.50374001'),
	'identifierSchemeCodeUnspsc190501.50374002': __('identifierSchemeCodeUnspsc190501.50374002'),
	'identifierSchemeCodeUnspsc190501.50374003': __('identifierSchemeCodeUnspsc190501.50374003'),
	'identifierSchemeCodeUnspsc190501.50374004': __('identifierSchemeCodeUnspsc190501.50374004'),
	'identifierSchemeCodeUnspsc190501.50374005': __('identifierSchemeCodeUnspsc190501.50374005'),
	'identifierSchemeCodeUnspsc190501.50374006': __('identifierSchemeCodeUnspsc190501.50374006'),
	'identifierSchemeCodeUnspsc190501.50374100': __('identifierSchemeCodeUnspsc190501.50374100'),
	'identifierSchemeCodeUnspsc190501.50374101': __('identifierSchemeCodeUnspsc190501.50374101'),
	'identifierSchemeCodeUnspsc190501.50374102': __('identifierSchemeCodeUnspsc190501.50374102'),
	'identifierSchemeCodeUnspsc190501.50374103': __('identifierSchemeCodeUnspsc190501.50374103'),
	'identifierSchemeCodeUnspsc190501.50374104': __('identifierSchemeCodeUnspsc190501.50374104'),
	'identifierSchemeCodeUnspsc190501.50374105': __('identifierSchemeCodeUnspsc190501.50374105'),
	'identifierSchemeCodeUnspsc190501.50374106': __('identifierSchemeCodeUnspsc190501.50374106'),
	'identifierSchemeCodeUnspsc190501.50374107': __('identifierSchemeCodeUnspsc190501.50374107'),
	'identifierSchemeCodeUnspsc190501.50374108': __('identifierSchemeCodeUnspsc190501.50374108'),
	'identifierSchemeCodeUnspsc190501.50374200': __('identifierSchemeCodeUnspsc190501.50374200'),
	'identifierSchemeCodeUnspsc190501.50374201': __('identifierSchemeCodeUnspsc190501.50374201'),
	'identifierSchemeCodeUnspsc190501.50374202': __('identifierSchemeCodeUnspsc190501.50374202'),
	'identifierSchemeCodeUnspsc190501.50374203': __('identifierSchemeCodeUnspsc190501.50374203'),
	'identifierSchemeCodeUnspsc190501.50374204': __('identifierSchemeCodeUnspsc190501.50374204'),
	'identifierSchemeCodeUnspsc190501.50374205': __('identifierSchemeCodeUnspsc190501.50374205'),
	'identifierSchemeCodeUnspsc190501.50374206': __('identifierSchemeCodeUnspsc190501.50374206'),
	'identifierSchemeCodeUnspsc190501.50374207': __('identifierSchemeCodeUnspsc190501.50374207'),
	'identifierSchemeCodeUnspsc190501.50374208': __('identifierSchemeCodeUnspsc190501.50374208'),
	'identifierSchemeCodeUnspsc190501.50374300': __('identifierSchemeCodeUnspsc190501.50374300'),
	'identifierSchemeCodeUnspsc190501.50374301': __('identifierSchemeCodeUnspsc190501.50374301'),
	'identifierSchemeCodeUnspsc190501.50374302': __('identifierSchemeCodeUnspsc190501.50374302'),
	'identifierSchemeCodeUnspsc190501.50374303': __('identifierSchemeCodeUnspsc190501.50374303'),
	'identifierSchemeCodeUnspsc190501.50374304': __('identifierSchemeCodeUnspsc190501.50374304'),
	'identifierSchemeCodeUnspsc190501.50374305': __('identifierSchemeCodeUnspsc190501.50374305'),
	'identifierSchemeCodeUnspsc190501.50374306': __('identifierSchemeCodeUnspsc190501.50374306'),
	'identifierSchemeCodeUnspsc190501.50374307': __('identifierSchemeCodeUnspsc190501.50374307'),
	'identifierSchemeCodeUnspsc190501.50374308': __('identifierSchemeCodeUnspsc190501.50374308'),
	'identifierSchemeCodeUnspsc190501.50374309': __('identifierSchemeCodeUnspsc190501.50374309'),
	'identifierSchemeCodeUnspsc190501.50374310': __('identifierSchemeCodeUnspsc190501.50374310'),
	'identifierSchemeCodeUnspsc190501.50374311': __('identifierSchemeCodeUnspsc190501.50374311'),
	'identifierSchemeCodeUnspsc190501.50374312': __('identifierSchemeCodeUnspsc190501.50374312'),
	'identifierSchemeCodeUnspsc190501.50374313': __('identifierSchemeCodeUnspsc190501.50374313'),
	'identifierSchemeCodeUnspsc190501.50374400': __('identifierSchemeCodeUnspsc190501.50374400'),
	'identifierSchemeCodeUnspsc190501.50374401': __('identifierSchemeCodeUnspsc190501.50374401'),
	'identifierSchemeCodeUnspsc190501.50374402': __('identifierSchemeCodeUnspsc190501.50374402'),
	'identifierSchemeCodeUnspsc190501.50374403': __('identifierSchemeCodeUnspsc190501.50374403'),
	'identifierSchemeCodeUnspsc190501.50374404': __('identifierSchemeCodeUnspsc190501.50374404'),
	'identifierSchemeCodeUnspsc190501.50374405': __('identifierSchemeCodeUnspsc190501.50374405'),
	'identifierSchemeCodeUnspsc190501.50374406': __('identifierSchemeCodeUnspsc190501.50374406'),
	'identifierSchemeCodeUnspsc190501.50374407': __('identifierSchemeCodeUnspsc190501.50374407'),
	'identifierSchemeCodeUnspsc190501.50374408': __('identifierSchemeCodeUnspsc190501.50374408'),
	'identifierSchemeCodeUnspsc190501.50374409': __('identifierSchemeCodeUnspsc190501.50374409'),
	'identifierSchemeCodeUnspsc190501.50374410': __('identifierSchemeCodeUnspsc190501.50374410'),
	'identifierSchemeCodeUnspsc190501.50374411': __('identifierSchemeCodeUnspsc190501.50374411'),
	'identifierSchemeCodeUnspsc190501.50374412': __('identifierSchemeCodeUnspsc190501.50374412'),
	'identifierSchemeCodeUnspsc190501.50374413': __('identifierSchemeCodeUnspsc190501.50374413'),
	'identifierSchemeCodeUnspsc190501.50374414': __('identifierSchemeCodeUnspsc190501.50374414'),
	'identifierSchemeCodeUnspsc190501.50374415': __('identifierSchemeCodeUnspsc190501.50374415'),
	'identifierSchemeCodeUnspsc190501.50374416': __('identifierSchemeCodeUnspsc190501.50374416'),
	'identifierSchemeCodeUnspsc190501.50374417': __('identifierSchemeCodeUnspsc190501.50374417'),
	'identifierSchemeCodeUnspsc190501.50374418': __('identifierSchemeCodeUnspsc190501.50374418'),
	'identifierSchemeCodeUnspsc190501.50374419': __('identifierSchemeCodeUnspsc190501.50374419'),
	'identifierSchemeCodeUnspsc190501.50374420': __('identifierSchemeCodeUnspsc190501.50374420'),
	'identifierSchemeCodeUnspsc190501.50374421': __('identifierSchemeCodeUnspsc190501.50374421'),
	'identifierSchemeCodeUnspsc190501.50374422': __('identifierSchemeCodeUnspsc190501.50374422'),
	'identifierSchemeCodeUnspsc190501.50374423': __('identifierSchemeCodeUnspsc190501.50374423'),
	'identifierSchemeCodeUnspsc190501.50374424': __('identifierSchemeCodeUnspsc190501.50374424'),
	'identifierSchemeCodeUnspsc190501.50374425': __('identifierSchemeCodeUnspsc190501.50374425'),
	'identifierSchemeCodeUnspsc190501.50374426': __('identifierSchemeCodeUnspsc190501.50374426'),
	'identifierSchemeCodeUnspsc190501.50374427': __('identifierSchemeCodeUnspsc190501.50374427'),
	'identifierSchemeCodeUnspsc190501.50374428': __('identifierSchemeCodeUnspsc190501.50374428'),
	'identifierSchemeCodeUnspsc190501.50374429': __('identifierSchemeCodeUnspsc190501.50374429'),
	'identifierSchemeCodeUnspsc190501.50374430': __('identifierSchemeCodeUnspsc190501.50374430'),
	'identifierSchemeCodeUnspsc190501.50374431': __('identifierSchemeCodeUnspsc190501.50374431'),
	'identifierSchemeCodeUnspsc190501.50374432': __('identifierSchemeCodeUnspsc190501.50374432'),
	'identifierSchemeCodeUnspsc190501.50374433': __('identifierSchemeCodeUnspsc190501.50374433'),
	'identifierSchemeCodeUnspsc190501.50374434': __('identifierSchemeCodeUnspsc190501.50374434'),
	'identifierSchemeCodeUnspsc190501.50374435': __('identifierSchemeCodeUnspsc190501.50374435'),
	'identifierSchemeCodeUnspsc190501.50374436': __('identifierSchemeCodeUnspsc190501.50374436'),
	'identifierSchemeCodeUnspsc190501.50374437': __('identifierSchemeCodeUnspsc190501.50374437'),
	'identifierSchemeCodeUnspsc190501.50374438': __('identifierSchemeCodeUnspsc190501.50374438'),
	'identifierSchemeCodeUnspsc190501.50374439': __('identifierSchemeCodeUnspsc190501.50374439'),
	'identifierSchemeCodeUnspsc190501.50374440': __('identifierSchemeCodeUnspsc190501.50374440'),
	'identifierSchemeCodeUnspsc190501.50374441': __('identifierSchemeCodeUnspsc190501.50374441'),
	'identifierSchemeCodeUnspsc190501.50374442': __('identifierSchemeCodeUnspsc190501.50374442'),
	'identifierSchemeCodeUnspsc190501.50374443': __('identifierSchemeCodeUnspsc190501.50374443'),
	'identifierSchemeCodeUnspsc190501.50374444': __('identifierSchemeCodeUnspsc190501.50374444'),
	'identifierSchemeCodeUnspsc190501.50374445': __('identifierSchemeCodeUnspsc190501.50374445'),
	'identifierSchemeCodeUnspsc190501.50374446': __('identifierSchemeCodeUnspsc190501.50374446'),
	'identifierSchemeCodeUnspsc190501.50374500': __('identifierSchemeCodeUnspsc190501.50374500'),
	'identifierSchemeCodeUnspsc190501.50374501': __('identifierSchemeCodeUnspsc190501.50374501'),
	'identifierSchemeCodeUnspsc190501.50374502': __('identifierSchemeCodeUnspsc190501.50374502'),
	'identifierSchemeCodeUnspsc190501.50374503': __('identifierSchemeCodeUnspsc190501.50374503'),
	'identifierSchemeCodeUnspsc190501.50374504': __('identifierSchemeCodeUnspsc190501.50374504'),
	'identifierSchemeCodeUnspsc190501.50374505': __('identifierSchemeCodeUnspsc190501.50374505'),
	'identifierSchemeCodeUnspsc190501.50374506': __('identifierSchemeCodeUnspsc190501.50374506'),
	'identifierSchemeCodeUnspsc190501.50374507': __('identifierSchemeCodeUnspsc190501.50374507'),
	'identifierSchemeCodeUnspsc190501.50374508': __('identifierSchemeCodeUnspsc190501.50374508'),
	'identifierSchemeCodeUnspsc190501.50374509': __('identifierSchemeCodeUnspsc190501.50374509'),
	'identifierSchemeCodeUnspsc190501.50374510': __('identifierSchemeCodeUnspsc190501.50374510'),
	'identifierSchemeCodeUnspsc190501.50374511': __('identifierSchemeCodeUnspsc190501.50374511'),
	'identifierSchemeCodeUnspsc190501.50374512': __('identifierSchemeCodeUnspsc190501.50374512'),
	'identifierSchemeCodeUnspsc190501.50374513': __('identifierSchemeCodeUnspsc190501.50374513'),
	'identifierSchemeCodeUnspsc190501.50374514': __('identifierSchemeCodeUnspsc190501.50374514'),
	'identifierSchemeCodeUnspsc190501.50374515': __('identifierSchemeCodeUnspsc190501.50374515'),
	'identifierSchemeCodeUnspsc190501.50374516': __('identifierSchemeCodeUnspsc190501.50374516'),
	'identifierSchemeCodeUnspsc190501.50374517': __('identifierSchemeCodeUnspsc190501.50374517'),
	'identifierSchemeCodeUnspsc190501.50374518': __('identifierSchemeCodeUnspsc190501.50374518'),
	'identifierSchemeCodeUnspsc190501.50374519': __('identifierSchemeCodeUnspsc190501.50374519'),
	'identifierSchemeCodeUnspsc190501.50374520': __('identifierSchemeCodeUnspsc190501.50374520'),
	'identifierSchemeCodeUnspsc190501.50374521': __('identifierSchemeCodeUnspsc190501.50374521'),
	'identifierSchemeCodeUnspsc190501.50374522': __('identifierSchemeCodeUnspsc190501.50374522'),
	'identifierSchemeCodeUnspsc190501.50374523': __('identifierSchemeCodeUnspsc190501.50374523'),
	'identifierSchemeCodeUnspsc190501.50374524': __('identifierSchemeCodeUnspsc190501.50374524'),
	'identifierSchemeCodeUnspsc190501.50374525': __('identifierSchemeCodeUnspsc190501.50374525'),
	'identifierSchemeCodeUnspsc190501.50374600': __('identifierSchemeCodeUnspsc190501.50374600'),
	'identifierSchemeCodeUnspsc190501.50374601': __('identifierSchemeCodeUnspsc190501.50374601'),
	'identifierSchemeCodeUnspsc190501.50374602': __('identifierSchemeCodeUnspsc190501.50374602'),
	'identifierSchemeCodeUnspsc190501.50374603': __('identifierSchemeCodeUnspsc190501.50374603'),
	'identifierSchemeCodeUnspsc190501.50374604': __('identifierSchemeCodeUnspsc190501.50374604'),
	'identifierSchemeCodeUnspsc190501.50374605': __('identifierSchemeCodeUnspsc190501.50374605'),
	'identifierSchemeCodeUnspsc190501.50374606': __('identifierSchemeCodeUnspsc190501.50374606'),
	'identifierSchemeCodeUnspsc190501.50374607': __('identifierSchemeCodeUnspsc190501.50374607'),
	'identifierSchemeCodeUnspsc190501.50374608': __('identifierSchemeCodeUnspsc190501.50374608'),
	'identifierSchemeCodeUnspsc190501.50374609': __('identifierSchemeCodeUnspsc190501.50374609'),
	'identifierSchemeCodeUnspsc190501.50374610': __('identifierSchemeCodeUnspsc190501.50374610'),
	'identifierSchemeCodeUnspsc190501.50374611': __('identifierSchemeCodeUnspsc190501.50374611'),
	'identifierSchemeCodeUnspsc190501.50374612': __('identifierSchemeCodeUnspsc190501.50374612'),
	'identifierSchemeCodeUnspsc190501.50374613': __('identifierSchemeCodeUnspsc190501.50374613'),
	'identifierSchemeCodeUnspsc190501.50374614': __('identifierSchemeCodeUnspsc190501.50374614'),
	'identifierSchemeCodeUnspsc190501.50374615': __('identifierSchemeCodeUnspsc190501.50374615'),
	'identifierSchemeCodeUnspsc190501.50374616': __('identifierSchemeCodeUnspsc190501.50374616'),
	'identifierSchemeCodeUnspsc190501.50374617': __('identifierSchemeCodeUnspsc190501.50374617'),
	'identifierSchemeCodeUnspsc190501.50374618': __('identifierSchemeCodeUnspsc190501.50374618'),
	'identifierSchemeCodeUnspsc190501.50374619': __('identifierSchemeCodeUnspsc190501.50374619'),
	'identifierSchemeCodeUnspsc190501.50374620': __('identifierSchemeCodeUnspsc190501.50374620'),
	'identifierSchemeCodeUnspsc190501.50374621': __('identifierSchemeCodeUnspsc190501.50374621'),
	'identifierSchemeCodeUnspsc190501.50374622': __('identifierSchemeCodeUnspsc190501.50374622'),
	'identifierSchemeCodeUnspsc190501.50374623': __('identifierSchemeCodeUnspsc190501.50374623'),
	'identifierSchemeCodeUnspsc190501.50374624': __('identifierSchemeCodeUnspsc190501.50374624'),
	'identifierSchemeCodeUnspsc190501.50374625': __('identifierSchemeCodeUnspsc190501.50374625'),
	'identifierSchemeCodeUnspsc190501.50374626': __('identifierSchemeCodeUnspsc190501.50374626'),
	'identifierSchemeCodeUnspsc190501.50374627': __('identifierSchemeCodeUnspsc190501.50374627'),
	'identifierSchemeCodeUnspsc190501.50374628': __('identifierSchemeCodeUnspsc190501.50374628'),
	'identifierSchemeCodeUnspsc190501.50374629': __('identifierSchemeCodeUnspsc190501.50374629'),
	'identifierSchemeCodeUnspsc190501.50374630': __('identifierSchemeCodeUnspsc190501.50374630'),
	'identifierSchemeCodeUnspsc190501.50374631': __('identifierSchemeCodeUnspsc190501.50374631'),
	'identifierSchemeCodeUnspsc190501.50374632': __('identifierSchemeCodeUnspsc190501.50374632'),
	'identifierSchemeCodeUnspsc190501.50374633': __('identifierSchemeCodeUnspsc190501.50374633'),
	'identifierSchemeCodeUnspsc190501.50374634': __('identifierSchemeCodeUnspsc190501.50374634'),
	'identifierSchemeCodeUnspsc190501.50374635': __('identifierSchemeCodeUnspsc190501.50374635'),
	'identifierSchemeCodeUnspsc190501.50374636': __('identifierSchemeCodeUnspsc190501.50374636'),
	'identifierSchemeCodeUnspsc190501.50374637': __('identifierSchemeCodeUnspsc190501.50374637'),
	'identifierSchemeCodeUnspsc190501.50374638': __('identifierSchemeCodeUnspsc190501.50374638'),
	'identifierSchemeCodeUnspsc190501.50374639': __('identifierSchemeCodeUnspsc190501.50374639'),
	'identifierSchemeCodeUnspsc190501.50374640': __('identifierSchemeCodeUnspsc190501.50374640'),
	'identifierSchemeCodeUnspsc190501.50374641': __('identifierSchemeCodeUnspsc190501.50374641'),
	'identifierSchemeCodeUnspsc190501.50374642': __('identifierSchemeCodeUnspsc190501.50374642'),
	'identifierSchemeCodeUnspsc190501.50374643': __('identifierSchemeCodeUnspsc190501.50374643'),
	'identifierSchemeCodeUnspsc190501.50374644': __('identifierSchemeCodeUnspsc190501.50374644'),
	'identifierSchemeCodeUnspsc190501.50374645': __('identifierSchemeCodeUnspsc190501.50374645'),
	'identifierSchemeCodeUnspsc190501.50374646': __('identifierSchemeCodeUnspsc190501.50374646'),
	'identifierSchemeCodeUnspsc190501.50374647': __('identifierSchemeCodeUnspsc190501.50374647'),
	'identifierSchemeCodeUnspsc190501.50374648': __('identifierSchemeCodeUnspsc190501.50374648'),
	'identifierSchemeCodeUnspsc190501.50374649': __('identifierSchemeCodeUnspsc190501.50374649'),
	'identifierSchemeCodeUnspsc190501.50374650': __('identifierSchemeCodeUnspsc190501.50374650'),
	'identifierSchemeCodeUnspsc190501.50374651': __('identifierSchemeCodeUnspsc190501.50374651'),
	'identifierSchemeCodeUnspsc190501.50374652': __('identifierSchemeCodeUnspsc190501.50374652'),
	'identifierSchemeCodeUnspsc190501.50374653': __('identifierSchemeCodeUnspsc190501.50374653'),
	'identifierSchemeCodeUnspsc190501.50374654': __('identifierSchemeCodeUnspsc190501.50374654'),
	'identifierSchemeCodeUnspsc190501.50374655': __('identifierSchemeCodeUnspsc190501.50374655'),
	'identifierSchemeCodeUnspsc190501.50374656': __('identifierSchemeCodeUnspsc190501.50374656'),
	'identifierSchemeCodeUnspsc190501.50374657': __('identifierSchemeCodeUnspsc190501.50374657'),
	'identifierSchemeCodeUnspsc190501.50374658': __('identifierSchemeCodeUnspsc190501.50374658'),
	'identifierSchemeCodeUnspsc190501.50374659': __('identifierSchemeCodeUnspsc190501.50374659'),
	'identifierSchemeCodeUnspsc190501.50374660': __('identifierSchemeCodeUnspsc190501.50374660'),
	'identifierSchemeCodeUnspsc190501.50374661': __('identifierSchemeCodeUnspsc190501.50374661'),
	'identifierSchemeCodeUnspsc190501.50374700': __('identifierSchemeCodeUnspsc190501.50374700'),
	'identifierSchemeCodeUnspsc190501.50374701': __('identifierSchemeCodeUnspsc190501.50374701'),
	'identifierSchemeCodeUnspsc190501.50374702': __('identifierSchemeCodeUnspsc190501.50374702'),
	'identifierSchemeCodeUnspsc190501.50374800': __('identifierSchemeCodeUnspsc190501.50374800'),
	'identifierSchemeCodeUnspsc190501.50374801': __('identifierSchemeCodeUnspsc190501.50374801'),
	'identifierSchemeCodeUnspsc190501.50374802': __('identifierSchemeCodeUnspsc190501.50374802'),
	'identifierSchemeCodeUnspsc190501.50374900': __('identifierSchemeCodeUnspsc190501.50374900'),
	'identifierSchemeCodeUnspsc190501.50374901': __('identifierSchemeCodeUnspsc190501.50374901'),
	'identifierSchemeCodeUnspsc190501.50374902': __('identifierSchemeCodeUnspsc190501.50374902'),
	'identifierSchemeCodeUnspsc190501.50374903': __('identifierSchemeCodeUnspsc190501.50374903'),
	'identifierSchemeCodeUnspsc190501.50374904': __('identifierSchemeCodeUnspsc190501.50374904'),
	'identifierSchemeCodeUnspsc190501.50374905': __('identifierSchemeCodeUnspsc190501.50374905'),
	'identifierSchemeCodeUnspsc190501.50374906': __('identifierSchemeCodeUnspsc190501.50374906'),
	'identifierSchemeCodeUnspsc190501.50374907': __('identifierSchemeCodeUnspsc190501.50374907'),
	'identifierSchemeCodeUnspsc190501.50374908': __('identifierSchemeCodeUnspsc190501.50374908'),
	'identifierSchemeCodeUnspsc190501.50374909': __('identifierSchemeCodeUnspsc190501.50374909'),
	'identifierSchemeCodeUnspsc190501.50374910': __('identifierSchemeCodeUnspsc190501.50374910'),
	'identifierSchemeCodeUnspsc190501.50374911': __('identifierSchemeCodeUnspsc190501.50374911'),
	'identifierSchemeCodeUnspsc190501.50374912': __('identifierSchemeCodeUnspsc190501.50374912'),
	'identifierSchemeCodeUnspsc190501.50374913': __('identifierSchemeCodeUnspsc190501.50374913'),
	'identifierSchemeCodeUnspsc190501.50374914': __('identifierSchemeCodeUnspsc190501.50374914'),
	'identifierSchemeCodeUnspsc190501.50374915': __('identifierSchemeCodeUnspsc190501.50374915'),
	'identifierSchemeCodeUnspsc190501.50374916': __('identifierSchemeCodeUnspsc190501.50374916'),
	'identifierSchemeCodeUnspsc190501.50374917': __('identifierSchemeCodeUnspsc190501.50374917'),
	'identifierSchemeCodeUnspsc190501.50374918': __('identifierSchemeCodeUnspsc190501.50374918'),
	'identifierSchemeCodeUnspsc190501.50374919': __('identifierSchemeCodeUnspsc190501.50374919'),
	'identifierSchemeCodeUnspsc190501.50374920': __('identifierSchemeCodeUnspsc190501.50374920'),
	'identifierSchemeCodeUnspsc190501.50374921': __('identifierSchemeCodeUnspsc190501.50374921'),
	'identifierSchemeCodeUnspsc190501.50374922': __('identifierSchemeCodeUnspsc190501.50374922'),
	'identifierSchemeCodeUnspsc190501.50374923': __('identifierSchemeCodeUnspsc190501.50374923'),
	'identifierSchemeCodeUnspsc190501.50374924': __('identifierSchemeCodeUnspsc190501.50374924'),
	'identifierSchemeCodeUnspsc190501.50374925': __('identifierSchemeCodeUnspsc190501.50374925'),
	'identifierSchemeCodeUnspsc190501.50374926': __('identifierSchemeCodeUnspsc190501.50374926'),
	'identifierSchemeCodeUnspsc190501.50374927': __('identifierSchemeCodeUnspsc190501.50374927'),
	'identifierSchemeCodeUnspsc190501.50374928': __('identifierSchemeCodeUnspsc190501.50374928'),
	'identifierSchemeCodeUnspsc190501.50374929': __('identifierSchemeCodeUnspsc190501.50374929'),
	'identifierSchemeCodeUnspsc190501.50374930': __('identifierSchemeCodeUnspsc190501.50374930'),
	'identifierSchemeCodeUnspsc190501.50374931': __('identifierSchemeCodeUnspsc190501.50374931'),
	'identifierSchemeCodeUnspsc190501.50374932': __('identifierSchemeCodeUnspsc190501.50374932'),
	'identifierSchemeCodeUnspsc190501.50374933': __('identifierSchemeCodeUnspsc190501.50374933'),
	'identifierSchemeCodeUnspsc190501.50374934': __('identifierSchemeCodeUnspsc190501.50374934'),
	'identifierSchemeCodeUnspsc190501.50374935': __('identifierSchemeCodeUnspsc190501.50374935'),
	'identifierSchemeCodeUnspsc190501.50374936': __('identifierSchemeCodeUnspsc190501.50374936'),
	'identifierSchemeCodeUnspsc190501.50374937': __('identifierSchemeCodeUnspsc190501.50374937'),
	'identifierSchemeCodeUnspsc190501.50374938': __('identifierSchemeCodeUnspsc190501.50374938'),
	'identifierSchemeCodeUnspsc190501.50374939': __('identifierSchemeCodeUnspsc190501.50374939'),
	'identifierSchemeCodeUnspsc190501.50374940': __('identifierSchemeCodeUnspsc190501.50374940'),
	'identifierSchemeCodeUnspsc190501.50374941': __('identifierSchemeCodeUnspsc190501.50374941'),
	'identifierSchemeCodeUnspsc190501.50374942': __('identifierSchemeCodeUnspsc190501.50374942'),
	'identifierSchemeCodeUnspsc190501.50374943': __('identifierSchemeCodeUnspsc190501.50374943'),
	'identifierSchemeCodeUnspsc190501.50374944': __('identifierSchemeCodeUnspsc190501.50374944'),
	'identifierSchemeCodeUnspsc190501.50374945': __('identifierSchemeCodeUnspsc190501.50374945'),
	'identifierSchemeCodeUnspsc190501.50374946': __('identifierSchemeCodeUnspsc190501.50374946'),
	'identifierSchemeCodeUnspsc190501.50374947': __('identifierSchemeCodeUnspsc190501.50374947'),
	'identifierSchemeCodeUnspsc190501.50374948': __('identifierSchemeCodeUnspsc190501.50374948'),
	'identifierSchemeCodeUnspsc190501.50374949': __('identifierSchemeCodeUnspsc190501.50374949'),
	'identifierSchemeCodeUnspsc190501.50374950': __('identifierSchemeCodeUnspsc190501.50374950'),
	'identifierSchemeCodeUnspsc190501.50374951': __('identifierSchemeCodeUnspsc190501.50374951'),
	'identifierSchemeCodeUnspsc190501.50374952': __('identifierSchemeCodeUnspsc190501.50374952'),
	'identifierSchemeCodeUnspsc190501.50374953': __('identifierSchemeCodeUnspsc190501.50374953'),
	'identifierSchemeCodeUnspsc190501.50374954': __('identifierSchemeCodeUnspsc190501.50374954'),
	'identifierSchemeCodeUnspsc190501.50374955': __('identifierSchemeCodeUnspsc190501.50374955'),
	'identifierSchemeCodeUnspsc190501.50374956': __('identifierSchemeCodeUnspsc190501.50374956'),
	'identifierSchemeCodeUnspsc190501.50374957': __('identifierSchemeCodeUnspsc190501.50374957'),
	'identifierSchemeCodeUnspsc190501.50375000': __('identifierSchemeCodeUnspsc190501.50375000'),
	'identifierSchemeCodeUnspsc190501.50375001': __('identifierSchemeCodeUnspsc190501.50375001'),
	'identifierSchemeCodeUnspsc190501.50375002': __('identifierSchemeCodeUnspsc190501.50375002'),
	'identifierSchemeCodeUnspsc190501.50375003': __('identifierSchemeCodeUnspsc190501.50375003'),
	'identifierSchemeCodeUnspsc190501.50375004': __('identifierSchemeCodeUnspsc190501.50375004'),
	'identifierSchemeCodeUnspsc190501.50375005': __('identifierSchemeCodeUnspsc190501.50375005'),
	'identifierSchemeCodeUnspsc190501.50375006': __('identifierSchemeCodeUnspsc190501.50375006'),
	'identifierSchemeCodeUnspsc190501.50375007': __('identifierSchemeCodeUnspsc190501.50375007'),
	'identifierSchemeCodeUnspsc190501.50375008': __('identifierSchemeCodeUnspsc190501.50375008'),
	'identifierSchemeCodeUnspsc190501.50375009': __('identifierSchemeCodeUnspsc190501.50375009'),
	'identifierSchemeCodeUnspsc190501.50375010': __('identifierSchemeCodeUnspsc190501.50375010'),
	'identifierSchemeCodeUnspsc190501.50375011': __('identifierSchemeCodeUnspsc190501.50375011'),
	'identifierSchemeCodeUnspsc190501.50375012': __('identifierSchemeCodeUnspsc190501.50375012'),
	'identifierSchemeCodeUnspsc190501.50375013': __('identifierSchemeCodeUnspsc190501.50375013'),
	'identifierSchemeCodeUnspsc190501.50375014': __('identifierSchemeCodeUnspsc190501.50375014'),
	'identifierSchemeCodeUnspsc190501.50375015': __('identifierSchemeCodeUnspsc190501.50375015'),
	'identifierSchemeCodeUnspsc190501.50375016': __('identifierSchemeCodeUnspsc190501.50375016'),
	'identifierSchemeCodeUnspsc190501.50375017': __('identifierSchemeCodeUnspsc190501.50375017'),
	'identifierSchemeCodeUnspsc190501.50375018': __('identifierSchemeCodeUnspsc190501.50375018'),
	'identifierSchemeCodeUnspsc190501.50375019': __('identifierSchemeCodeUnspsc190501.50375019'),
	'identifierSchemeCodeUnspsc190501.50375020': __('identifierSchemeCodeUnspsc190501.50375020'),
	'identifierSchemeCodeUnspsc190501.50375021': __('identifierSchemeCodeUnspsc190501.50375021'),
	'identifierSchemeCodeUnspsc190501.50375022': __('identifierSchemeCodeUnspsc190501.50375022'),
	'identifierSchemeCodeUnspsc190501.50375023': __('identifierSchemeCodeUnspsc190501.50375023'),
	'identifierSchemeCodeUnspsc190501.50375024': __('identifierSchemeCodeUnspsc190501.50375024'),
	'identifierSchemeCodeUnspsc190501.50375025': __('identifierSchemeCodeUnspsc190501.50375025'),
	'identifierSchemeCodeUnspsc190501.50375026': __('identifierSchemeCodeUnspsc190501.50375026'),
	'identifierSchemeCodeUnspsc190501.50375027': __('identifierSchemeCodeUnspsc190501.50375027'),
	'identifierSchemeCodeUnspsc190501.50375028': __('identifierSchemeCodeUnspsc190501.50375028'),
	'identifierSchemeCodeUnspsc190501.50375029': __('identifierSchemeCodeUnspsc190501.50375029'),
	'identifierSchemeCodeUnspsc190501.50375030': __('identifierSchemeCodeUnspsc190501.50375030'),
	'identifierSchemeCodeUnspsc190501.50375031': __('identifierSchemeCodeUnspsc190501.50375031'),
	'identifierSchemeCodeUnspsc190501.50375032': __('identifierSchemeCodeUnspsc190501.50375032'),
	'identifierSchemeCodeUnspsc190501.50375033': __('identifierSchemeCodeUnspsc190501.50375033'),
	'identifierSchemeCodeUnspsc190501.50375034': __('identifierSchemeCodeUnspsc190501.50375034'),
	'identifierSchemeCodeUnspsc190501.50375035': __('identifierSchemeCodeUnspsc190501.50375035'),
	'identifierSchemeCodeUnspsc190501.50375036': __('identifierSchemeCodeUnspsc190501.50375036'),
	'identifierSchemeCodeUnspsc190501.50375037': __('identifierSchemeCodeUnspsc190501.50375037'),
	'identifierSchemeCodeUnspsc190501.50375038': __('identifierSchemeCodeUnspsc190501.50375038'),
	'identifierSchemeCodeUnspsc190501.50375039': __('identifierSchemeCodeUnspsc190501.50375039'),
	'identifierSchemeCodeUnspsc190501.50375040': __('identifierSchemeCodeUnspsc190501.50375040'),
	'identifierSchemeCodeUnspsc190501.50375041': __('identifierSchemeCodeUnspsc190501.50375041'),
	'identifierSchemeCodeUnspsc190501.50375042': __('identifierSchemeCodeUnspsc190501.50375042'),
	'identifierSchemeCodeUnspsc190501.50375043': __('identifierSchemeCodeUnspsc190501.50375043'),
	'identifierSchemeCodeUnspsc190501.50375044': __('identifierSchemeCodeUnspsc190501.50375044'),
	'identifierSchemeCodeUnspsc190501.50375100': __('identifierSchemeCodeUnspsc190501.50375100'),
	'identifierSchemeCodeUnspsc190501.50375101': __('identifierSchemeCodeUnspsc190501.50375101'),
	'identifierSchemeCodeUnspsc190501.50375102': __('identifierSchemeCodeUnspsc190501.50375102'),
	'identifierSchemeCodeUnspsc190501.50375103': __('identifierSchemeCodeUnspsc190501.50375103'),
	'identifierSchemeCodeUnspsc190501.50375104': __('identifierSchemeCodeUnspsc190501.50375104'),
	'identifierSchemeCodeUnspsc190501.50375105': __('identifierSchemeCodeUnspsc190501.50375105'),
	'identifierSchemeCodeUnspsc190501.50375106': __('identifierSchemeCodeUnspsc190501.50375106'),
	'identifierSchemeCodeUnspsc190501.50375200': __('identifierSchemeCodeUnspsc190501.50375200'),
	'identifierSchemeCodeUnspsc190501.50375201': __('identifierSchemeCodeUnspsc190501.50375201'),
	'identifierSchemeCodeUnspsc190501.50375202': __('identifierSchemeCodeUnspsc190501.50375202'),
	'identifierSchemeCodeUnspsc190501.50375203': __('identifierSchemeCodeUnspsc190501.50375203'),
	'identifierSchemeCodeUnspsc190501.50375204': __('identifierSchemeCodeUnspsc190501.50375204'),
	'identifierSchemeCodeUnspsc190501.50375205': __('identifierSchemeCodeUnspsc190501.50375205'),
	'identifierSchemeCodeUnspsc190501.50375206': __('identifierSchemeCodeUnspsc190501.50375206'),
	'identifierSchemeCodeUnspsc190501.50375207': __('identifierSchemeCodeUnspsc190501.50375207'),
	'identifierSchemeCodeUnspsc190501.50375208': __('identifierSchemeCodeUnspsc190501.50375208'),
	'identifierSchemeCodeUnspsc190501.50375209': __('identifierSchemeCodeUnspsc190501.50375209'),
	'identifierSchemeCodeUnspsc190501.50375210': __('identifierSchemeCodeUnspsc190501.50375210'),
	'identifierSchemeCodeUnspsc190501.50375300': __('identifierSchemeCodeUnspsc190501.50375300'),
	'identifierSchemeCodeUnspsc190501.50375301': __('identifierSchemeCodeUnspsc190501.50375301'),
	'identifierSchemeCodeUnspsc190501.50375302': __('identifierSchemeCodeUnspsc190501.50375302'),
	'identifierSchemeCodeUnspsc190501.50375303': __('identifierSchemeCodeUnspsc190501.50375303'),
	'identifierSchemeCodeUnspsc190501.50375304': __('identifierSchemeCodeUnspsc190501.50375304'),
	'identifierSchemeCodeUnspsc190501.50375305': __('identifierSchemeCodeUnspsc190501.50375305'),
	'identifierSchemeCodeUnspsc190501.50375306': __('identifierSchemeCodeUnspsc190501.50375306'),
	'identifierSchemeCodeUnspsc190501.50375307': __('identifierSchemeCodeUnspsc190501.50375307'),
	'identifierSchemeCodeUnspsc190501.50375308': __('identifierSchemeCodeUnspsc190501.50375308'),
	'identifierSchemeCodeUnspsc190501.50375309': __('identifierSchemeCodeUnspsc190501.50375309'),
	'identifierSchemeCodeUnspsc190501.50375310': __('identifierSchemeCodeUnspsc190501.50375310'),
	'identifierSchemeCodeUnspsc190501.50375311': __('identifierSchemeCodeUnspsc190501.50375311'),
	'identifierSchemeCodeUnspsc190501.50375312': __('identifierSchemeCodeUnspsc190501.50375312'),
	'identifierSchemeCodeUnspsc190501.50375313': __('identifierSchemeCodeUnspsc190501.50375313'),
	'identifierSchemeCodeUnspsc190501.50375314': __('identifierSchemeCodeUnspsc190501.50375314'),
	'identifierSchemeCodeUnspsc190501.50375315': __('identifierSchemeCodeUnspsc190501.50375315'),
	'identifierSchemeCodeUnspsc190501.50375316': __('identifierSchemeCodeUnspsc190501.50375316'),
	'identifierSchemeCodeUnspsc190501.50375317': __('identifierSchemeCodeUnspsc190501.50375317'),
	'identifierSchemeCodeUnspsc190501.50375318': __('identifierSchemeCodeUnspsc190501.50375318'),
	'identifierSchemeCodeUnspsc190501.50375319': __('identifierSchemeCodeUnspsc190501.50375319'),
	'identifierSchemeCodeUnspsc190501.50375320': __('identifierSchemeCodeUnspsc190501.50375320'),
	'identifierSchemeCodeUnspsc190501.50375321': __('identifierSchemeCodeUnspsc190501.50375321'),
	'identifierSchemeCodeUnspsc190501.50375322': __('identifierSchemeCodeUnspsc190501.50375322'),
	'identifierSchemeCodeUnspsc190501.50375323': __('identifierSchemeCodeUnspsc190501.50375323'),
	'identifierSchemeCodeUnspsc190501.50375324': __('identifierSchemeCodeUnspsc190501.50375324'),
	'identifierSchemeCodeUnspsc190501.50375325': __('identifierSchemeCodeUnspsc190501.50375325'),
	'identifierSchemeCodeUnspsc190501.50375326': __('identifierSchemeCodeUnspsc190501.50375326'),
	'identifierSchemeCodeUnspsc190501.50375327': __('identifierSchemeCodeUnspsc190501.50375327'),
	'identifierSchemeCodeUnspsc190501.50375328': __('identifierSchemeCodeUnspsc190501.50375328'),
	'identifierSchemeCodeUnspsc190501.50375329': __('identifierSchemeCodeUnspsc190501.50375329'),
	'identifierSchemeCodeUnspsc190501.50375330': __('identifierSchemeCodeUnspsc190501.50375330'),
	'identifierSchemeCodeUnspsc190501.50375331': __('identifierSchemeCodeUnspsc190501.50375331'),
	'identifierSchemeCodeUnspsc190501.50375332': __('identifierSchemeCodeUnspsc190501.50375332'),
	'identifierSchemeCodeUnspsc190501.50375333': __('identifierSchemeCodeUnspsc190501.50375333'),
	'identifierSchemeCodeUnspsc190501.50375334': __('identifierSchemeCodeUnspsc190501.50375334'),
	'identifierSchemeCodeUnspsc190501.50375335': __('identifierSchemeCodeUnspsc190501.50375335'),
	'identifierSchemeCodeUnspsc190501.50375336': __('identifierSchemeCodeUnspsc190501.50375336'),
	'identifierSchemeCodeUnspsc190501.50375337': __('identifierSchemeCodeUnspsc190501.50375337'),
	'identifierSchemeCodeUnspsc190501.50375338': __('identifierSchemeCodeUnspsc190501.50375338'),
	'identifierSchemeCodeUnspsc190501.50375339': __('identifierSchemeCodeUnspsc190501.50375339'),
	'identifierSchemeCodeUnspsc190501.50375340': __('identifierSchemeCodeUnspsc190501.50375340'),
	'identifierSchemeCodeUnspsc190501.50375341': __('identifierSchemeCodeUnspsc190501.50375341'),
	'identifierSchemeCodeUnspsc190501.50375342': __('identifierSchemeCodeUnspsc190501.50375342'),
	'identifierSchemeCodeUnspsc190501.50375343': __('identifierSchemeCodeUnspsc190501.50375343'),
	'identifierSchemeCodeUnspsc190501.50375344': __('identifierSchemeCodeUnspsc190501.50375344'),
	'identifierSchemeCodeUnspsc190501.50375345': __('identifierSchemeCodeUnspsc190501.50375345'),
	'identifierSchemeCodeUnspsc190501.50375346': __('identifierSchemeCodeUnspsc190501.50375346'),
	'identifierSchemeCodeUnspsc190501.50375347': __('identifierSchemeCodeUnspsc190501.50375347'),
	'identifierSchemeCodeUnspsc190501.50375348': __('identifierSchemeCodeUnspsc190501.50375348'),
	'identifierSchemeCodeUnspsc190501.50375349': __('identifierSchemeCodeUnspsc190501.50375349'),
	'identifierSchemeCodeUnspsc190501.50375350': __('identifierSchemeCodeUnspsc190501.50375350'),
	'identifierSchemeCodeUnspsc190501.50375351': __('identifierSchemeCodeUnspsc190501.50375351'),
	'identifierSchemeCodeUnspsc190501.50375352': __('identifierSchemeCodeUnspsc190501.50375352'),
	'identifierSchemeCodeUnspsc190501.50375353': __('identifierSchemeCodeUnspsc190501.50375353'),
	'identifierSchemeCodeUnspsc190501.50375354': __('identifierSchemeCodeUnspsc190501.50375354'),
	'identifierSchemeCodeUnspsc190501.50375355': __('identifierSchemeCodeUnspsc190501.50375355'),
	'identifierSchemeCodeUnspsc190501.50375356': __('identifierSchemeCodeUnspsc190501.50375356'),
	'identifierSchemeCodeUnspsc190501.50375357': __('identifierSchemeCodeUnspsc190501.50375357'),
	'identifierSchemeCodeUnspsc190501.50375358': __('identifierSchemeCodeUnspsc190501.50375358'),
	'identifierSchemeCodeUnspsc190501.50375359': __('identifierSchemeCodeUnspsc190501.50375359'),
	'identifierSchemeCodeUnspsc190501.50375360': __('identifierSchemeCodeUnspsc190501.50375360'),
	'identifierSchemeCodeUnspsc190501.50375361': __('identifierSchemeCodeUnspsc190501.50375361'),
	'identifierSchemeCodeUnspsc190501.50375362': __('identifierSchemeCodeUnspsc190501.50375362'),
	'identifierSchemeCodeUnspsc190501.50375363': __('identifierSchemeCodeUnspsc190501.50375363'),
	'identifierSchemeCodeUnspsc190501.50375364': __('identifierSchemeCodeUnspsc190501.50375364'),
	'identifierSchemeCodeUnspsc190501.50375365': __('identifierSchemeCodeUnspsc190501.50375365'),
	'identifierSchemeCodeUnspsc190501.50375366': __('identifierSchemeCodeUnspsc190501.50375366'),
	'identifierSchemeCodeUnspsc190501.50375367': __('identifierSchemeCodeUnspsc190501.50375367'),
	'identifierSchemeCodeUnspsc190501.50375368': __('identifierSchemeCodeUnspsc190501.50375368'),
	'identifierSchemeCodeUnspsc190501.50375369': __('identifierSchemeCodeUnspsc190501.50375369'),
	'identifierSchemeCodeUnspsc190501.50375370': __('identifierSchemeCodeUnspsc190501.50375370'),
	'identifierSchemeCodeUnspsc190501.50375371': __('identifierSchemeCodeUnspsc190501.50375371'),
	'identifierSchemeCodeUnspsc190501.50375400': __('identifierSchemeCodeUnspsc190501.50375400'),
	'identifierSchemeCodeUnspsc190501.50375401': __('identifierSchemeCodeUnspsc190501.50375401'),
	'identifierSchemeCodeUnspsc190501.50375402': __('identifierSchemeCodeUnspsc190501.50375402'),
	'identifierSchemeCodeUnspsc190501.50375403': __('identifierSchemeCodeUnspsc190501.50375403'),
	'identifierSchemeCodeUnspsc190501.50375404': __('identifierSchemeCodeUnspsc190501.50375404'),
	'identifierSchemeCodeUnspsc190501.50375405': __('identifierSchemeCodeUnspsc190501.50375405'),
	'identifierSchemeCodeUnspsc190501.50375406': __('identifierSchemeCodeUnspsc190501.50375406'),
	'identifierSchemeCodeUnspsc190501.50375407': __('identifierSchemeCodeUnspsc190501.50375407'),
	'identifierSchemeCodeUnspsc190501.50375408': __('identifierSchemeCodeUnspsc190501.50375408'),
	'identifierSchemeCodeUnspsc190501.50375409': __('identifierSchemeCodeUnspsc190501.50375409'),
	'identifierSchemeCodeUnspsc190501.50375410': __('identifierSchemeCodeUnspsc190501.50375410'),
	'identifierSchemeCodeUnspsc190501.50375411': __('identifierSchemeCodeUnspsc190501.50375411'),
	'identifierSchemeCodeUnspsc190501.50375412': __('identifierSchemeCodeUnspsc190501.50375412'),
	'identifierSchemeCodeUnspsc190501.50375413': __('identifierSchemeCodeUnspsc190501.50375413'),
	'identifierSchemeCodeUnspsc190501.50375414': __('identifierSchemeCodeUnspsc190501.50375414'),
	'identifierSchemeCodeUnspsc190501.50375415': __('identifierSchemeCodeUnspsc190501.50375415'),
	'identifierSchemeCodeUnspsc190501.50375416': __('identifierSchemeCodeUnspsc190501.50375416'),
	'identifierSchemeCodeUnspsc190501.50375417': __('identifierSchemeCodeUnspsc190501.50375417'),
	'identifierSchemeCodeUnspsc190501.50375418': __('identifierSchemeCodeUnspsc190501.50375418'),
	'identifierSchemeCodeUnspsc190501.50375419': __('identifierSchemeCodeUnspsc190501.50375419'),
	'identifierSchemeCodeUnspsc190501.50375420': __('identifierSchemeCodeUnspsc190501.50375420'),
	'identifierSchemeCodeUnspsc190501.50375421': __('identifierSchemeCodeUnspsc190501.50375421'),
	'identifierSchemeCodeUnspsc190501.50375422': __('identifierSchemeCodeUnspsc190501.50375422'),
	'identifierSchemeCodeUnspsc190501.50375423': __('identifierSchemeCodeUnspsc190501.50375423'),
	'identifierSchemeCodeUnspsc190501.50375424': __('identifierSchemeCodeUnspsc190501.50375424'),
	'identifierSchemeCodeUnspsc190501.50375425': __('identifierSchemeCodeUnspsc190501.50375425'),
	'identifierSchemeCodeUnspsc190501.50375426': __('identifierSchemeCodeUnspsc190501.50375426'),
	'identifierSchemeCodeUnspsc190501.50375427': __('identifierSchemeCodeUnspsc190501.50375427'),
	'identifierSchemeCodeUnspsc190501.50375428': __('identifierSchemeCodeUnspsc190501.50375428'),
	'identifierSchemeCodeUnspsc190501.50375429': __('identifierSchemeCodeUnspsc190501.50375429'),
	'identifierSchemeCodeUnspsc190501.50375430': __('identifierSchemeCodeUnspsc190501.50375430'),
	'identifierSchemeCodeUnspsc190501.50375431': __('identifierSchemeCodeUnspsc190501.50375431'),
	'identifierSchemeCodeUnspsc190501.50375432': __('identifierSchemeCodeUnspsc190501.50375432'),
	'identifierSchemeCodeUnspsc190501.50375433': __('identifierSchemeCodeUnspsc190501.50375433'),
	'identifierSchemeCodeUnspsc190501.50375434': __('identifierSchemeCodeUnspsc190501.50375434'),
	'identifierSchemeCodeUnspsc190501.50375435': __('identifierSchemeCodeUnspsc190501.50375435'),
	'identifierSchemeCodeUnspsc190501.50375436': __('identifierSchemeCodeUnspsc190501.50375436'),
	'identifierSchemeCodeUnspsc190501.50375437': __('identifierSchemeCodeUnspsc190501.50375437'),
	'identifierSchemeCodeUnspsc190501.50375438': __('identifierSchemeCodeUnspsc190501.50375438'),
	'identifierSchemeCodeUnspsc190501.50375439': __('identifierSchemeCodeUnspsc190501.50375439'),
	'identifierSchemeCodeUnspsc190501.50375440': __('identifierSchemeCodeUnspsc190501.50375440'),
	'identifierSchemeCodeUnspsc190501.50375441': __('identifierSchemeCodeUnspsc190501.50375441'),
	'identifierSchemeCodeUnspsc190501.50375442': __('identifierSchemeCodeUnspsc190501.50375442'),
	'identifierSchemeCodeUnspsc190501.50375443': __('identifierSchemeCodeUnspsc190501.50375443'),
	'identifierSchemeCodeUnspsc190501.50375444': __('identifierSchemeCodeUnspsc190501.50375444'),
	'identifierSchemeCodeUnspsc190501.50375445': __('identifierSchemeCodeUnspsc190501.50375445'),
	'identifierSchemeCodeUnspsc190501.50375446': __('identifierSchemeCodeUnspsc190501.50375446'),
	'identifierSchemeCodeUnspsc190501.50375447': __('identifierSchemeCodeUnspsc190501.50375447'),
	'identifierSchemeCodeUnspsc190501.50375448': __('identifierSchemeCodeUnspsc190501.50375448'),
	'identifierSchemeCodeUnspsc190501.50375449': __('identifierSchemeCodeUnspsc190501.50375449'),
	'identifierSchemeCodeUnspsc190501.50375450': __('identifierSchemeCodeUnspsc190501.50375450'),
	'identifierSchemeCodeUnspsc190501.50375451': __('identifierSchemeCodeUnspsc190501.50375451'),
	'identifierSchemeCodeUnspsc190501.50375452': __('identifierSchemeCodeUnspsc190501.50375452'),
	'identifierSchemeCodeUnspsc190501.50375453': __('identifierSchemeCodeUnspsc190501.50375453'),
	'identifierSchemeCodeUnspsc190501.50375454': __('identifierSchemeCodeUnspsc190501.50375454'),
	'identifierSchemeCodeUnspsc190501.50375455': __('identifierSchemeCodeUnspsc190501.50375455'),
	'identifierSchemeCodeUnspsc190501.50375456': __('identifierSchemeCodeUnspsc190501.50375456'),
	'identifierSchemeCodeUnspsc190501.50375457': __('identifierSchemeCodeUnspsc190501.50375457'),
	'identifierSchemeCodeUnspsc190501.50375458': __('identifierSchemeCodeUnspsc190501.50375458'),
	'identifierSchemeCodeUnspsc190501.50375459': __('identifierSchemeCodeUnspsc190501.50375459'),
	'identifierSchemeCodeUnspsc190501.50375460': __('identifierSchemeCodeUnspsc190501.50375460'),
	'identifierSchemeCodeUnspsc190501.50375500': __('identifierSchemeCodeUnspsc190501.50375500'),
	'identifierSchemeCodeUnspsc190501.50375501': __('identifierSchemeCodeUnspsc190501.50375501'),
	'identifierSchemeCodeUnspsc190501.50375502': __('identifierSchemeCodeUnspsc190501.50375502'),
	'identifierSchemeCodeUnspsc190501.50375503': __('identifierSchemeCodeUnspsc190501.50375503'),
	'identifierSchemeCodeUnspsc190501.50375504': __('identifierSchemeCodeUnspsc190501.50375504'),
	'identifierSchemeCodeUnspsc190501.50375505': __('identifierSchemeCodeUnspsc190501.50375505'),
	'identifierSchemeCodeUnspsc190501.50375506': __('identifierSchemeCodeUnspsc190501.50375506'),
	'identifierSchemeCodeUnspsc190501.50375507': __('identifierSchemeCodeUnspsc190501.50375507'),
	'identifierSchemeCodeUnspsc190501.50375508': __('identifierSchemeCodeUnspsc190501.50375508'),
	'identifierSchemeCodeUnspsc190501.50375509': __('identifierSchemeCodeUnspsc190501.50375509'),
	'identifierSchemeCodeUnspsc190501.50375510': __('identifierSchemeCodeUnspsc190501.50375510'),
	'identifierSchemeCodeUnspsc190501.50375511': __('identifierSchemeCodeUnspsc190501.50375511'),
	'identifierSchemeCodeUnspsc190501.50375512': __('identifierSchemeCodeUnspsc190501.50375512'),
	'identifierSchemeCodeUnspsc190501.50375600': __('identifierSchemeCodeUnspsc190501.50375600'),
	'identifierSchemeCodeUnspsc190501.50375601': __('identifierSchemeCodeUnspsc190501.50375601'),
	'identifierSchemeCodeUnspsc190501.50375602': __('identifierSchemeCodeUnspsc190501.50375602'),
	'identifierSchemeCodeUnspsc190501.50375603': __('identifierSchemeCodeUnspsc190501.50375603'),
	'identifierSchemeCodeUnspsc190501.50375604': __('identifierSchemeCodeUnspsc190501.50375604'),
	'identifierSchemeCodeUnspsc190501.50375605': __('identifierSchemeCodeUnspsc190501.50375605'),
	'identifierSchemeCodeUnspsc190501.50375606': __('identifierSchemeCodeUnspsc190501.50375606'),
	'identifierSchemeCodeUnspsc190501.50375607': __('identifierSchemeCodeUnspsc190501.50375607'),
	'identifierSchemeCodeUnspsc190501.50375608': __('identifierSchemeCodeUnspsc190501.50375608'),
	'identifierSchemeCodeUnspsc190501.50375609': __('identifierSchemeCodeUnspsc190501.50375609'),
	'identifierSchemeCodeUnspsc190501.50375610': __('identifierSchemeCodeUnspsc190501.50375610'),
	'identifierSchemeCodeUnspsc190501.50375700': __('identifierSchemeCodeUnspsc190501.50375700'),
	'identifierSchemeCodeUnspsc190501.50375701': __('identifierSchemeCodeUnspsc190501.50375701'),
	'identifierSchemeCodeUnspsc190501.50375702': __('identifierSchemeCodeUnspsc190501.50375702'),
	'identifierSchemeCodeUnspsc190501.50375703': __('identifierSchemeCodeUnspsc190501.50375703'),
	'identifierSchemeCodeUnspsc190501.50375704': __('identifierSchemeCodeUnspsc190501.50375704'),
	'identifierSchemeCodeUnspsc190501.50375705': __('identifierSchemeCodeUnspsc190501.50375705'),
	'identifierSchemeCodeUnspsc190501.50375706': __('identifierSchemeCodeUnspsc190501.50375706'),
	'identifierSchemeCodeUnspsc190501.50375707': __('identifierSchemeCodeUnspsc190501.50375707'),
	'identifierSchemeCodeUnspsc190501.50375708': __('identifierSchemeCodeUnspsc190501.50375708'),
	'identifierSchemeCodeUnspsc190501.50375709': __('identifierSchemeCodeUnspsc190501.50375709'),
	'identifierSchemeCodeUnspsc190501.50375710': __('identifierSchemeCodeUnspsc190501.50375710'),
	'identifierSchemeCodeUnspsc190501.50375711': __('identifierSchemeCodeUnspsc190501.50375711'),
	'identifierSchemeCodeUnspsc190501.50375712': __('identifierSchemeCodeUnspsc190501.50375712'),
	'identifierSchemeCodeUnspsc190501.50375713': __('identifierSchemeCodeUnspsc190501.50375713'),
	'identifierSchemeCodeUnspsc190501.50375714': __('identifierSchemeCodeUnspsc190501.50375714'),
	'identifierSchemeCodeUnspsc190501.50375715': __('identifierSchemeCodeUnspsc190501.50375715'),
	'identifierSchemeCodeUnspsc190501.50375716': __('identifierSchemeCodeUnspsc190501.50375716'),
	'identifierSchemeCodeUnspsc190501.50375717': __('identifierSchemeCodeUnspsc190501.50375717'),
	'identifierSchemeCodeUnspsc190501.50375718': __('identifierSchemeCodeUnspsc190501.50375718'),
	'identifierSchemeCodeUnspsc190501.50375719': __('identifierSchemeCodeUnspsc190501.50375719'),
	'identifierSchemeCodeUnspsc190501.50375720': __('identifierSchemeCodeUnspsc190501.50375720'),
	'identifierSchemeCodeUnspsc190501.50375721': __('identifierSchemeCodeUnspsc190501.50375721'),
	'identifierSchemeCodeUnspsc190501.50375722': __('identifierSchemeCodeUnspsc190501.50375722'),
	'identifierSchemeCodeUnspsc190501.50375723': __('identifierSchemeCodeUnspsc190501.50375723'),
	'identifierSchemeCodeUnspsc190501.50375800': __('identifierSchemeCodeUnspsc190501.50375800'),
	'identifierSchemeCodeUnspsc190501.50375801': __('identifierSchemeCodeUnspsc190501.50375801'),
	'identifierSchemeCodeUnspsc190501.50375802': __('identifierSchemeCodeUnspsc190501.50375802'),
	'identifierSchemeCodeUnspsc190501.50375803': __('identifierSchemeCodeUnspsc190501.50375803'),
	'identifierSchemeCodeUnspsc190501.50375804': __('identifierSchemeCodeUnspsc190501.50375804'),
	'identifierSchemeCodeUnspsc190501.50375805': __('identifierSchemeCodeUnspsc190501.50375805'),
	'identifierSchemeCodeUnspsc190501.50375806': __('identifierSchemeCodeUnspsc190501.50375806'),
	'identifierSchemeCodeUnspsc190501.50375807': __('identifierSchemeCodeUnspsc190501.50375807'),
	'identifierSchemeCodeUnspsc190501.50375808': __('identifierSchemeCodeUnspsc190501.50375808'),
	'identifierSchemeCodeUnspsc190501.50375809': __('identifierSchemeCodeUnspsc190501.50375809'),
	'identifierSchemeCodeUnspsc190501.50375810': __('identifierSchemeCodeUnspsc190501.50375810'),
	'identifierSchemeCodeUnspsc190501.50375811': __('identifierSchemeCodeUnspsc190501.50375811'),
	'identifierSchemeCodeUnspsc190501.50375812': __('identifierSchemeCodeUnspsc190501.50375812'),
	'identifierSchemeCodeUnspsc190501.50375813': __('identifierSchemeCodeUnspsc190501.50375813'),
	'identifierSchemeCodeUnspsc190501.50375814': __('identifierSchemeCodeUnspsc190501.50375814'),
	'identifierSchemeCodeUnspsc190501.50375815': __('identifierSchemeCodeUnspsc190501.50375815'),
	'identifierSchemeCodeUnspsc190501.50375816': __('identifierSchemeCodeUnspsc190501.50375816'),
	'identifierSchemeCodeUnspsc190501.50375817': __('identifierSchemeCodeUnspsc190501.50375817'),
	'identifierSchemeCodeUnspsc190501.50375818': __('identifierSchemeCodeUnspsc190501.50375818'),
	'identifierSchemeCodeUnspsc190501.50375819': __('identifierSchemeCodeUnspsc190501.50375819'),
	'identifierSchemeCodeUnspsc190501.50375820': __('identifierSchemeCodeUnspsc190501.50375820'),
	'identifierSchemeCodeUnspsc190501.50375821': __('identifierSchemeCodeUnspsc190501.50375821'),
	'identifierSchemeCodeUnspsc190501.50375822': __('identifierSchemeCodeUnspsc190501.50375822'),
	'identifierSchemeCodeUnspsc190501.50375823': __('identifierSchemeCodeUnspsc190501.50375823'),
	'identifierSchemeCodeUnspsc190501.50375824': __('identifierSchemeCodeUnspsc190501.50375824'),
	'identifierSchemeCodeUnspsc190501.50375825': __('identifierSchemeCodeUnspsc190501.50375825'),
	'identifierSchemeCodeUnspsc190501.50375826': __('identifierSchemeCodeUnspsc190501.50375826'),
	'identifierSchemeCodeUnspsc190501.50375827': __('identifierSchemeCodeUnspsc190501.50375827'),
	'identifierSchemeCodeUnspsc190501.50375828': __('identifierSchemeCodeUnspsc190501.50375828'),
	'identifierSchemeCodeUnspsc190501.50375829': __('identifierSchemeCodeUnspsc190501.50375829'),
	'identifierSchemeCodeUnspsc190501.50375830': __('identifierSchemeCodeUnspsc190501.50375830'),
	'identifierSchemeCodeUnspsc190501.50375831': __('identifierSchemeCodeUnspsc190501.50375831'),
	'identifierSchemeCodeUnspsc190501.50375832': __('identifierSchemeCodeUnspsc190501.50375832'),
	'identifierSchemeCodeUnspsc190501.50375833': __('identifierSchemeCodeUnspsc190501.50375833'),
	'identifierSchemeCodeUnspsc190501.50375834': __('identifierSchemeCodeUnspsc190501.50375834'),
	'identifierSchemeCodeUnspsc190501.50375835': __('identifierSchemeCodeUnspsc190501.50375835'),
	'identifierSchemeCodeUnspsc190501.50375836': __('identifierSchemeCodeUnspsc190501.50375836'),
	'identifierSchemeCodeUnspsc190501.50375837': __('identifierSchemeCodeUnspsc190501.50375837'),
	'identifierSchemeCodeUnspsc190501.50375838': __('identifierSchemeCodeUnspsc190501.50375838'),
	'identifierSchemeCodeUnspsc190501.50375839': __('identifierSchemeCodeUnspsc190501.50375839'),
	'identifierSchemeCodeUnspsc190501.50375840': __('identifierSchemeCodeUnspsc190501.50375840'),
	'identifierSchemeCodeUnspsc190501.50375841': __('identifierSchemeCodeUnspsc190501.50375841'),
	'identifierSchemeCodeUnspsc190501.50375842': __('identifierSchemeCodeUnspsc190501.50375842'),
	'identifierSchemeCodeUnspsc190501.50375843': __('identifierSchemeCodeUnspsc190501.50375843'),
	'identifierSchemeCodeUnspsc190501.50375844': __('identifierSchemeCodeUnspsc190501.50375844'),
	'identifierSchemeCodeUnspsc190501.50375845': __('identifierSchemeCodeUnspsc190501.50375845'),
	'identifierSchemeCodeUnspsc190501.50375846': __('identifierSchemeCodeUnspsc190501.50375846'),
	'identifierSchemeCodeUnspsc190501.50375847': __('identifierSchemeCodeUnspsc190501.50375847'),
	'identifierSchemeCodeUnspsc190501.50375848': __('identifierSchemeCodeUnspsc190501.50375848'),
	'identifierSchemeCodeUnspsc190501.50375849': __('identifierSchemeCodeUnspsc190501.50375849'),
	'identifierSchemeCodeUnspsc190501.50375850': __('identifierSchemeCodeUnspsc190501.50375850'),
	'identifierSchemeCodeUnspsc190501.50375851': __('identifierSchemeCodeUnspsc190501.50375851'),
	'identifierSchemeCodeUnspsc190501.50375852': __('identifierSchemeCodeUnspsc190501.50375852'),
	'identifierSchemeCodeUnspsc190501.50375853': __('identifierSchemeCodeUnspsc190501.50375853'),
	'identifierSchemeCodeUnspsc190501.50375854': __('identifierSchemeCodeUnspsc190501.50375854'),
	'identifierSchemeCodeUnspsc190501.50375855': __('identifierSchemeCodeUnspsc190501.50375855'),
	'identifierSchemeCodeUnspsc190501.50375856': __('identifierSchemeCodeUnspsc190501.50375856'),
	'identifierSchemeCodeUnspsc190501.50375857': __('identifierSchemeCodeUnspsc190501.50375857'),
	'identifierSchemeCodeUnspsc190501.50375858': __('identifierSchemeCodeUnspsc190501.50375858'),
	'identifierSchemeCodeUnspsc190501.50375859': __('identifierSchemeCodeUnspsc190501.50375859'),
	'identifierSchemeCodeUnspsc190501.50375860': __('identifierSchemeCodeUnspsc190501.50375860'),
	'identifierSchemeCodeUnspsc190501.50375861': __('identifierSchemeCodeUnspsc190501.50375861'),
	'identifierSchemeCodeUnspsc190501.50375862': __('identifierSchemeCodeUnspsc190501.50375862'),
	'identifierSchemeCodeUnspsc190501.50375863': __('identifierSchemeCodeUnspsc190501.50375863'),
	'identifierSchemeCodeUnspsc190501.50375864': __('identifierSchemeCodeUnspsc190501.50375864'),
	'identifierSchemeCodeUnspsc190501.50375865': __('identifierSchemeCodeUnspsc190501.50375865'),
	'identifierSchemeCodeUnspsc190501.50375866': __('identifierSchemeCodeUnspsc190501.50375866'),
	'identifierSchemeCodeUnspsc190501.50375867': __('identifierSchemeCodeUnspsc190501.50375867'),
	'identifierSchemeCodeUnspsc190501.50375868': __('identifierSchemeCodeUnspsc190501.50375868'),
	'identifierSchemeCodeUnspsc190501.50375869': __('identifierSchemeCodeUnspsc190501.50375869'),
	'identifierSchemeCodeUnspsc190501.50375870': __('identifierSchemeCodeUnspsc190501.50375870'),
	'identifierSchemeCodeUnspsc190501.50375871': __('identifierSchemeCodeUnspsc190501.50375871'),
	'identifierSchemeCodeUnspsc190501.50375872': __('identifierSchemeCodeUnspsc190501.50375872'),
	'identifierSchemeCodeUnspsc190501.50375873': __('identifierSchemeCodeUnspsc190501.50375873'),
	'identifierSchemeCodeUnspsc190501.50375874': __('identifierSchemeCodeUnspsc190501.50375874'),
	'identifierSchemeCodeUnspsc190501.50375875': __('identifierSchemeCodeUnspsc190501.50375875'),
	'identifierSchemeCodeUnspsc190501.50375876': __('identifierSchemeCodeUnspsc190501.50375876'),
	'identifierSchemeCodeUnspsc190501.50375877': __('identifierSchemeCodeUnspsc190501.50375877'),
	'identifierSchemeCodeUnspsc190501.50375900': __('identifierSchemeCodeUnspsc190501.50375900'),
	'identifierSchemeCodeUnspsc190501.50375901': __('identifierSchemeCodeUnspsc190501.50375901'),
	'identifierSchemeCodeUnspsc190501.50375902': __('identifierSchemeCodeUnspsc190501.50375902'),
	'identifierSchemeCodeUnspsc190501.50375903': __('identifierSchemeCodeUnspsc190501.50375903'),
	'identifierSchemeCodeUnspsc190501.50375904': __('identifierSchemeCodeUnspsc190501.50375904'),
	'identifierSchemeCodeUnspsc190501.50375905': __('identifierSchemeCodeUnspsc190501.50375905'),
	'identifierSchemeCodeUnspsc190501.50375906': __('identifierSchemeCodeUnspsc190501.50375906'),
	'identifierSchemeCodeUnspsc190501.50375907': __('identifierSchemeCodeUnspsc190501.50375907'),
	'identifierSchemeCodeUnspsc190501.50375908': __('identifierSchemeCodeUnspsc190501.50375908'),
	'identifierSchemeCodeUnspsc190501.50376000': __('identifierSchemeCodeUnspsc190501.50376000'),
	'identifierSchemeCodeUnspsc190501.50376001': __('identifierSchemeCodeUnspsc190501.50376001'),
	'identifierSchemeCodeUnspsc190501.50376002': __('identifierSchemeCodeUnspsc190501.50376002'),
	'identifierSchemeCodeUnspsc190501.50376003': __('identifierSchemeCodeUnspsc190501.50376003'),
	'identifierSchemeCodeUnspsc190501.50376004': __('identifierSchemeCodeUnspsc190501.50376004'),
	'identifierSchemeCodeUnspsc190501.50376005': __('identifierSchemeCodeUnspsc190501.50376005'),
	'identifierSchemeCodeUnspsc190501.50376006': __('identifierSchemeCodeUnspsc190501.50376006'),
	'identifierSchemeCodeUnspsc190501.50376007': __('identifierSchemeCodeUnspsc190501.50376007'),
	'identifierSchemeCodeUnspsc190501.50376008': __('identifierSchemeCodeUnspsc190501.50376008'),
	'identifierSchemeCodeUnspsc190501.50376100': __('identifierSchemeCodeUnspsc190501.50376100'),
	'identifierSchemeCodeUnspsc190501.50376101': __('identifierSchemeCodeUnspsc190501.50376101'),
	'identifierSchemeCodeUnspsc190501.50376102': __('identifierSchemeCodeUnspsc190501.50376102'),
	'identifierSchemeCodeUnspsc190501.50376103': __('identifierSchemeCodeUnspsc190501.50376103'),
	'identifierSchemeCodeUnspsc190501.50376200': __('identifierSchemeCodeUnspsc190501.50376200'),
	'identifierSchemeCodeUnspsc190501.50376201': __('identifierSchemeCodeUnspsc190501.50376201'),
	'identifierSchemeCodeUnspsc190501.50376202': __('identifierSchemeCodeUnspsc190501.50376202'),
	'identifierSchemeCodeUnspsc190501.50376203': __('identifierSchemeCodeUnspsc190501.50376203'),
	'identifierSchemeCodeUnspsc190501.50376204': __('identifierSchemeCodeUnspsc190501.50376204'),
	'identifierSchemeCodeUnspsc190501.50376205': __('identifierSchemeCodeUnspsc190501.50376205'),
	'identifierSchemeCodeUnspsc190501.50376206': __('identifierSchemeCodeUnspsc190501.50376206'),
	'identifierSchemeCodeUnspsc190501.50376207': __('identifierSchemeCodeUnspsc190501.50376207'),
	'identifierSchemeCodeUnspsc190501.50376208': __('identifierSchemeCodeUnspsc190501.50376208'),
	'identifierSchemeCodeUnspsc190501.50376209': __('identifierSchemeCodeUnspsc190501.50376209'),
	'identifierSchemeCodeUnspsc190501.50376210': __('identifierSchemeCodeUnspsc190501.50376210'),
	'identifierSchemeCodeUnspsc190501.50376211': __('identifierSchemeCodeUnspsc190501.50376211'),
	'identifierSchemeCodeUnspsc190501.50376212': __('identifierSchemeCodeUnspsc190501.50376212'),
	'identifierSchemeCodeUnspsc190501.50376213': __('identifierSchemeCodeUnspsc190501.50376213'),
	'identifierSchemeCodeUnspsc190501.50376214': __('identifierSchemeCodeUnspsc190501.50376214'),
	'identifierSchemeCodeUnspsc190501.50376215': __('identifierSchemeCodeUnspsc190501.50376215'),
	'identifierSchemeCodeUnspsc190501.50376216': __('identifierSchemeCodeUnspsc190501.50376216'),
	'identifierSchemeCodeUnspsc190501.50376217': __('identifierSchemeCodeUnspsc190501.50376217'),
	'identifierSchemeCodeUnspsc190501.50376218': __('identifierSchemeCodeUnspsc190501.50376218'),
	'identifierSchemeCodeUnspsc190501.50376219': __('identifierSchemeCodeUnspsc190501.50376219'),
	'identifierSchemeCodeUnspsc190501.50376220': __('identifierSchemeCodeUnspsc190501.50376220'),
	'identifierSchemeCodeUnspsc190501.50376221': __('identifierSchemeCodeUnspsc190501.50376221'),
	'identifierSchemeCodeUnspsc190501.50376222': __('identifierSchemeCodeUnspsc190501.50376222'),
	'identifierSchemeCodeUnspsc190501.50376223': __('identifierSchemeCodeUnspsc190501.50376223'),
	'identifierSchemeCodeUnspsc190501.50376224': __('identifierSchemeCodeUnspsc190501.50376224'),
	'identifierSchemeCodeUnspsc190501.50376225': __('identifierSchemeCodeUnspsc190501.50376225'),
	'identifierSchemeCodeUnspsc190501.50376226': __('identifierSchemeCodeUnspsc190501.50376226'),
	'identifierSchemeCodeUnspsc190501.50376227': __('identifierSchemeCodeUnspsc190501.50376227'),
	'identifierSchemeCodeUnspsc190501.50376228': __('identifierSchemeCodeUnspsc190501.50376228'),
	'identifierSchemeCodeUnspsc190501.50376229': __('identifierSchemeCodeUnspsc190501.50376229'),
	'identifierSchemeCodeUnspsc190501.50376300': __('identifierSchemeCodeUnspsc190501.50376300'),
	'identifierSchemeCodeUnspsc190501.50376301': __('identifierSchemeCodeUnspsc190501.50376301'),
	'identifierSchemeCodeUnspsc190501.50376302': __('identifierSchemeCodeUnspsc190501.50376302'),
	'identifierSchemeCodeUnspsc190501.50376303': __('identifierSchemeCodeUnspsc190501.50376303'),
	'identifierSchemeCodeUnspsc190501.50376304': __('identifierSchemeCodeUnspsc190501.50376304'),
	'identifierSchemeCodeUnspsc190501.50376305': __('identifierSchemeCodeUnspsc190501.50376305'),
	'identifierSchemeCodeUnspsc190501.50376306': __('identifierSchemeCodeUnspsc190501.50376306'),
	'identifierSchemeCodeUnspsc190501.50376307': __('identifierSchemeCodeUnspsc190501.50376307'),
	'identifierSchemeCodeUnspsc190501.50376308': __('identifierSchemeCodeUnspsc190501.50376308'),
	'identifierSchemeCodeUnspsc190501.50376309': __('identifierSchemeCodeUnspsc190501.50376309'),
	'identifierSchemeCodeUnspsc190501.50376310': __('identifierSchemeCodeUnspsc190501.50376310'),
	'identifierSchemeCodeUnspsc190501.50376400': __('identifierSchemeCodeUnspsc190501.50376400'),
	'identifierSchemeCodeUnspsc190501.50376401': __('identifierSchemeCodeUnspsc190501.50376401'),
	'identifierSchemeCodeUnspsc190501.50376402': __('identifierSchemeCodeUnspsc190501.50376402'),
	'identifierSchemeCodeUnspsc190501.50376403': __('identifierSchemeCodeUnspsc190501.50376403'),
	'identifierSchemeCodeUnspsc190501.50376404': __('identifierSchemeCodeUnspsc190501.50376404'),
	'identifierSchemeCodeUnspsc190501.50376500': __('identifierSchemeCodeUnspsc190501.50376500'),
	'identifierSchemeCodeUnspsc190501.50376501': __('identifierSchemeCodeUnspsc190501.50376501'),
	'identifierSchemeCodeUnspsc190501.50376502': __('identifierSchemeCodeUnspsc190501.50376502'),
	'identifierSchemeCodeUnspsc190501.50376600': __('identifierSchemeCodeUnspsc190501.50376600'),
	'identifierSchemeCodeUnspsc190501.50376601': __('identifierSchemeCodeUnspsc190501.50376601'),
	'identifierSchemeCodeUnspsc190501.50376602': __('identifierSchemeCodeUnspsc190501.50376602'),
	'identifierSchemeCodeUnspsc190501.50376603': __('identifierSchemeCodeUnspsc190501.50376603'),
	'identifierSchemeCodeUnspsc190501.50376604': __('identifierSchemeCodeUnspsc190501.50376604'),
	'identifierSchemeCodeUnspsc190501.50376700': __('identifierSchemeCodeUnspsc190501.50376700'),
	'identifierSchemeCodeUnspsc190501.50376701': __('identifierSchemeCodeUnspsc190501.50376701'),
	'identifierSchemeCodeUnspsc190501.50376702': __('identifierSchemeCodeUnspsc190501.50376702'),
	'identifierSchemeCodeUnspsc190501.50376703': __('identifierSchemeCodeUnspsc190501.50376703'),
	'identifierSchemeCodeUnspsc190501.50376800': __('identifierSchemeCodeUnspsc190501.50376800'),
	'identifierSchemeCodeUnspsc190501.50376801': __('identifierSchemeCodeUnspsc190501.50376801'),
	'identifierSchemeCodeUnspsc190501.50376802': __('identifierSchemeCodeUnspsc190501.50376802'),
	'identifierSchemeCodeUnspsc190501.50376803': __('identifierSchemeCodeUnspsc190501.50376803'),
	'identifierSchemeCodeUnspsc190501.50376900': __('identifierSchemeCodeUnspsc190501.50376900'),
	'identifierSchemeCodeUnspsc190501.50376901': __('identifierSchemeCodeUnspsc190501.50376901'),
	'identifierSchemeCodeUnspsc190501.50376902': __('identifierSchemeCodeUnspsc190501.50376902'),
	'identifierSchemeCodeUnspsc190501.50376903': __('identifierSchemeCodeUnspsc190501.50376903'),
	'identifierSchemeCodeUnspsc190501.50376904': __('identifierSchemeCodeUnspsc190501.50376904'),
	'identifierSchemeCodeUnspsc190501.50376905': __('identifierSchemeCodeUnspsc190501.50376905'),
	'identifierSchemeCodeUnspsc190501.50376906': __('identifierSchemeCodeUnspsc190501.50376906'),
	'identifierSchemeCodeUnspsc190501.50377000': __('identifierSchemeCodeUnspsc190501.50377000'),
	'identifierSchemeCodeUnspsc190501.50377001': __('identifierSchemeCodeUnspsc190501.50377001'),
	'identifierSchemeCodeUnspsc190501.50377002': __('identifierSchemeCodeUnspsc190501.50377002'),
	'identifierSchemeCodeUnspsc190501.50377003': __('identifierSchemeCodeUnspsc190501.50377003'),
	'identifierSchemeCodeUnspsc190501.50377004': __('identifierSchemeCodeUnspsc190501.50377004'),
	'identifierSchemeCodeUnspsc190501.50377005': __('identifierSchemeCodeUnspsc190501.50377005'),
	'identifierSchemeCodeUnspsc190501.50377006': __('identifierSchemeCodeUnspsc190501.50377006'),
	'identifierSchemeCodeUnspsc190501.50377007': __('identifierSchemeCodeUnspsc190501.50377007'),
	'identifierSchemeCodeUnspsc190501.50377008': __('identifierSchemeCodeUnspsc190501.50377008'),
	'identifierSchemeCodeUnspsc190501.50377009': __('identifierSchemeCodeUnspsc190501.50377009'),
	'identifierSchemeCodeUnspsc190501.50377010': __('identifierSchemeCodeUnspsc190501.50377010'),
	'identifierSchemeCodeUnspsc190501.50377011': __('identifierSchemeCodeUnspsc190501.50377011'),
	'identifierSchemeCodeUnspsc190501.50377012': __('identifierSchemeCodeUnspsc190501.50377012'),
	'identifierSchemeCodeUnspsc190501.50377013': __('identifierSchemeCodeUnspsc190501.50377013'),
	'identifierSchemeCodeUnspsc190501.50377014': __('identifierSchemeCodeUnspsc190501.50377014'),
	'identifierSchemeCodeUnspsc190501.50377015': __('identifierSchemeCodeUnspsc190501.50377015'),
	'identifierSchemeCodeUnspsc190501.50377016': __('identifierSchemeCodeUnspsc190501.50377016'),
	'identifierSchemeCodeUnspsc190501.50377017': __('identifierSchemeCodeUnspsc190501.50377017'),
	'identifierSchemeCodeUnspsc190501.50377018': __('identifierSchemeCodeUnspsc190501.50377018'),
	'identifierSchemeCodeUnspsc190501.50377019': __('identifierSchemeCodeUnspsc190501.50377019'),
	'identifierSchemeCodeUnspsc190501.50377020': __('identifierSchemeCodeUnspsc190501.50377020'),
	'identifierSchemeCodeUnspsc190501.50377021': __('identifierSchemeCodeUnspsc190501.50377021'),
	'identifierSchemeCodeUnspsc190501.50377022': __('identifierSchemeCodeUnspsc190501.50377022'),
	'identifierSchemeCodeUnspsc190501.50377023': __('identifierSchemeCodeUnspsc190501.50377023'),
	'identifierSchemeCodeUnspsc190501.50377024': __('identifierSchemeCodeUnspsc190501.50377024'),
	'identifierSchemeCodeUnspsc190501.50377025': __('identifierSchemeCodeUnspsc190501.50377025'),
	'identifierSchemeCodeUnspsc190501.50377026': __('identifierSchemeCodeUnspsc190501.50377026'),
	'identifierSchemeCodeUnspsc190501.50377027': __('identifierSchemeCodeUnspsc190501.50377027'),
	'identifierSchemeCodeUnspsc190501.50377028': __('identifierSchemeCodeUnspsc190501.50377028'),
	'identifierSchemeCodeUnspsc190501.50377029': __('identifierSchemeCodeUnspsc190501.50377029'),
	'identifierSchemeCodeUnspsc190501.50377030': __('identifierSchemeCodeUnspsc190501.50377030'),
	'identifierSchemeCodeUnspsc190501.50377031': __('identifierSchemeCodeUnspsc190501.50377031'),
	'identifierSchemeCodeUnspsc190501.50377032': __('identifierSchemeCodeUnspsc190501.50377032'),
	'identifierSchemeCodeUnspsc190501.50377033': __('identifierSchemeCodeUnspsc190501.50377033'),
	'identifierSchemeCodeUnspsc190501.50377034': __('identifierSchemeCodeUnspsc190501.50377034'),
	'identifierSchemeCodeUnspsc190501.50377035': __('identifierSchemeCodeUnspsc190501.50377035'),
	'identifierSchemeCodeUnspsc190501.50377036': __('identifierSchemeCodeUnspsc190501.50377036'),
	'identifierSchemeCodeUnspsc190501.50377037': __('identifierSchemeCodeUnspsc190501.50377037'),
	'identifierSchemeCodeUnspsc190501.50377100': __('identifierSchemeCodeUnspsc190501.50377100'),
	'identifierSchemeCodeUnspsc190501.50377101': __('identifierSchemeCodeUnspsc190501.50377101'),
	'identifierSchemeCodeUnspsc190501.50377102': __('identifierSchemeCodeUnspsc190501.50377102'),
	'identifierSchemeCodeUnspsc190501.50377103': __('identifierSchemeCodeUnspsc190501.50377103'),
	'identifierSchemeCodeUnspsc190501.50377104': __('identifierSchemeCodeUnspsc190501.50377104'),
	'identifierSchemeCodeUnspsc190501.50377200': __('identifierSchemeCodeUnspsc190501.50377200'),
	'identifierSchemeCodeUnspsc190501.50377201': __('identifierSchemeCodeUnspsc190501.50377201'),
	'identifierSchemeCodeUnspsc190501.50377202': __('identifierSchemeCodeUnspsc190501.50377202'),
	'identifierSchemeCodeUnspsc190501.50377203': __('identifierSchemeCodeUnspsc190501.50377203'),
	'identifierSchemeCodeUnspsc190501.50377204': __('identifierSchemeCodeUnspsc190501.50377204'),
	'identifierSchemeCodeUnspsc190501.50377205': __('identifierSchemeCodeUnspsc190501.50377205'),
	'identifierSchemeCodeUnspsc190501.50377206': __('identifierSchemeCodeUnspsc190501.50377206'),
	'identifierSchemeCodeUnspsc190501.50377207': __('identifierSchemeCodeUnspsc190501.50377207'),
	'identifierSchemeCodeUnspsc190501.50377208': __('identifierSchemeCodeUnspsc190501.50377208'),
	'identifierSchemeCodeUnspsc190501.50377209': __('identifierSchemeCodeUnspsc190501.50377209'),
	'identifierSchemeCodeUnspsc190501.50377210': __('identifierSchemeCodeUnspsc190501.50377210'),
	'identifierSchemeCodeUnspsc190501.50377211': __('identifierSchemeCodeUnspsc190501.50377211'),
	'identifierSchemeCodeUnspsc190501.50377212': __('identifierSchemeCodeUnspsc190501.50377212'),
	'identifierSchemeCodeUnspsc190501.50377213': __('identifierSchemeCodeUnspsc190501.50377213'),
	'identifierSchemeCodeUnspsc190501.50377214': __('identifierSchemeCodeUnspsc190501.50377214'),
	'identifierSchemeCodeUnspsc190501.50377215': __('identifierSchemeCodeUnspsc190501.50377215'),
	'identifierSchemeCodeUnspsc190501.50377216': __('identifierSchemeCodeUnspsc190501.50377216'),
	'identifierSchemeCodeUnspsc190501.50377217': __('identifierSchemeCodeUnspsc190501.50377217'),
	'identifierSchemeCodeUnspsc190501.50377218': __('identifierSchemeCodeUnspsc190501.50377218'),
	'identifierSchemeCodeUnspsc190501.50377219': __('identifierSchemeCodeUnspsc190501.50377219'),
	'identifierSchemeCodeUnspsc190501.50377220': __('identifierSchemeCodeUnspsc190501.50377220'),
	'identifierSchemeCodeUnspsc190501.50377221': __('identifierSchemeCodeUnspsc190501.50377221'),
	'identifierSchemeCodeUnspsc190501.50377222': __('identifierSchemeCodeUnspsc190501.50377222'),
	'identifierSchemeCodeUnspsc190501.50377223': __('identifierSchemeCodeUnspsc190501.50377223'),
	'identifierSchemeCodeUnspsc190501.50377224': __('identifierSchemeCodeUnspsc190501.50377224'),
	'identifierSchemeCodeUnspsc190501.50377225': __('identifierSchemeCodeUnspsc190501.50377225'),
	'identifierSchemeCodeUnspsc190501.50377226': __('identifierSchemeCodeUnspsc190501.50377226'),
	'identifierSchemeCodeUnspsc190501.50377227': __('identifierSchemeCodeUnspsc190501.50377227'),
	'identifierSchemeCodeUnspsc190501.50377228': __('identifierSchemeCodeUnspsc190501.50377228'),
	'identifierSchemeCodeUnspsc190501.50377229': __('identifierSchemeCodeUnspsc190501.50377229'),
	'identifierSchemeCodeUnspsc190501.50377230': __('identifierSchemeCodeUnspsc190501.50377230'),
	'identifierSchemeCodeUnspsc190501.50380000': __('identifierSchemeCodeUnspsc190501.50380000'),
	'identifierSchemeCodeUnspsc190501.50381900': __('identifierSchemeCodeUnspsc190501.50381900'),
	'identifierSchemeCodeUnspsc190501.50381901': __('identifierSchemeCodeUnspsc190501.50381901'),
	'identifierSchemeCodeUnspsc190501.50381902': __('identifierSchemeCodeUnspsc190501.50381902'),
	'identifierSchemeCodeUnspsc190501.50381903': __('identifierSchemeCodeUnspsc190501.50381903'),
	'identifierSchemeCodeUnspsc190501.50381904': __('identifierSchemeCodeUnspsc190501.50381904'),
	'identifierSchemeCodeUnspsc190501.50381905': __('identifierSchemeCodeUnspsc190501.50381905'),
	'identifierSchemeCodeUnspsc190501.50381906': __('identifierSchemeCodeUnspsc190501.50381906'),
	'identifierSchemeCodeUnspsc190501.50381907': __('identifierSchemeCodeUnspsc190501.50381907'),
	'identifierSchemeCodeUnspsc190501.50381908': __('identifierSchemeCodeUnspsc190501.50381908'),
	'identifierSchemeCodeUnspsc190501.50381909': __('identifierSchemeCodeUnspsc190501.50381909'),
	'identifierSchemeCodeUnspsc190501.50381910': __('identifierSchemeCodeUnspsc190501.50381910'),
	'identifierSchemeCodeUnspsc190501.50381911': __('identifierSchemeCodeUnspsc190501.50381911'),
	'identifierSchemeCodeUnspsc190501.50381912': __('identifierSchemeCodeUnspsc190501.50381912'),
	'identifierSchemeCodeUnspsc190501.50381913': __('identifierSchemeCodeUnspsc190501.50381913'),
	'identifierSchemeCodeUnspsc190501.50381914': __('identifierSchemeCodeUnspsc190501.50381914'),
	'identifierSchemeCodeUnspsc190501.50381915': __('identifierSchemeCodeUnspsc190501.50381915'),
	'identifierSchemeCodeUnspsc190501.50381916': __('identifierSchemeCodeUnspsc190501.50381916'),
	'identifierSchemeCodeUnspsc190501.50381917': __('identifierSchemeCodeUnspsc190501.50381917'),
	'identifierSchemeCodeUnspsc190501.50381918': __('identifierSchemeCodeUnspsc190501.50381918'),
	'identifierSchemeCodeUnspsc190501.50381919': __('identifierSchemeCodeUnspsc190501.50381919'),
	'identifierSchemeCodeUnspsc190501.50381920': __('identifierSchemeCodeUnspsc190501.50381920'),
	'identifierSchemeCodeUnspsc190501.50381921': __('identifierSchemeCodeUnspsc190501.50381921'),
	'identifierSchemeCodeUnspsc190501.50381922': __('identifierSchemeCodeUnspsc190501.50381922'),
	'identifierSchemeCodeUnspsc190501.50381923': __('identifierSchemeCodeUnspsc190501.50381923'),
	'identifierSchemeCodeUnspsc190501.50381924': __('identifierSchemeCodeUnspsc190501.50381924'),
	'identifierSchemeCodeUnspsc190501.50381925': __('identifierSchemeCodeUnspsc190501.50381925'),
	'identifierSchemeCodeUnspsc190501.50381926': __('identifierSchemeCodeUnspsc190501.50381926'),
	'identifierSchemeCodeUnspsc190501.50381927': __('identifierSchemeCodeUnspsc190501.50381927'),
	'identifierSchemeCodeUnspsc190501.50381928': __('identifierSchemeCodeUnspsc190501.50381928'),
	'identifierSchemeCodeUnspsc190501.50381929': __('identifierSchemeCodeUnspsc190501.50381929'),
	'identifierSchemeCodeUnspsc190501.50381930': __('identifierSchemeCodeUnspsc190501.50381930'),
	'identifierSchemeCodeUnspsc190501.50381931': __('identifierSchemeCodeUnspsc190501.50381931'),
	'identifierSchemeCodeUnspsc190501.50381932': __('identifierSchemeCodeUnspsc190501.50381932'),
	'identifierSchemeCodeUnspsc190501.50381933': __('identifierSchemeCodeUnspsc190501.50381933'),
	'identifierSchemeCodeUnspsc190501.50381934': __('identifierSchemeCodeUnspsc190501.50381934'),
	'identifierSchemeCodeUnspsc190501.50381935': __('identifierSchemeCodeUnspsc190501.50381935'),
	'identifierSchemeCodeUnspsc190501.50381936': __('identifierSchemeCodeUnspsc190501.50381936'),
	'identifierSchemeCodeUnspsc190501.50381937': __('identifierSchemeCodeUnspsc190501.50381937'),
	'identifierSchemeCodeUnspsc190501.50381938': __('identifierSchemeCodeUnspsc190501.50381938'),
	'identifierSchemeCodeUnspsc190501.50381939': __('identifierSchemeCodeUnspsc190501.50381939'),
	'identifierSchemeCodeUnspsc190501.50381940': __('identifierSchemeCodeUnspsc190501.50381940'),
	'identifierSchemeCodeUnspsc190501.50381941': __('identifierSchemeCodeUnspsc190501.50381941'),
	'identifierSchemeCodeUnspsc190501.50381942': __('identifierSchemeCodeUnspsc190501.50381942'),
	'identifierSchemeCodeUnspsc190501.50381943': __('identifierSchemeCodeUnspsc190501.50381943'),
	'identifierSchemeCodeUnspsc190501.50381944': __('identifierSchemeCodeUnspsc190501.50381944'),
	'identifierSchemeCodeUnspsc190501.50381945': __('identifierSchemeCodeUnspsc190501.50381945'),
	'identifierSchemeCodeUnspsc190501.50381946': __('identifierSchemeCodeUnspsc190501.50381946'),
	'identifierSchemeCodeUnspsc190501.50381947': __('identifierSchemeCodeUnspsc190501.50381947'),
	'identifierSchemeCodeUnspsc190501.50381948': __('identifierSchemeCodeUnspsc190501.50381948'),
	'identifierSchemeCodeUnspsc190501.50381949': __('identifierSchemeCodeUnspsc190501.50381949'),
	'identifierSchemeCodeUnspsc190501.50381950': __('identifierSchemeCodeUnspsc190501.50381950'),
	'identifierSchemeCodeUnspsc190501.50381951': __('identifierSchemeCodeUnspsc190501.50381951'),
	'identifierSchemeCodeUnspsc190501.50381952': __('identifierSchemeCodeUnspsc190501.50381952'),
	'identifierSchemeCodeUnspsc190501.50381953': __('identifierSchemeCodeUnspsc190501.50381953'),
	'identifierSchemeCodeUnspsc190501.50381954': __('identifierSchemeCodeUnspsc190501.50381954'),
	'identifierSchemeCodeUnspsc190501.50381955': __('identifierSchemeCodeUnspsc190501.50381955'),
	'identifierSchemeCodeUnspsc190501.50381956': __('identifierSchemeCodeUnspsc190501.50381956'),
	'identifierSchemeCodeUnspsc190501.50381957': __('identifierSchemeCodeUnspsc190501.50381957'),
	'identifierSchemeCodeUnspsc190501.50381958': __('identifierSchemeCodeUnspsc190501.50381958'),
	'identifierSchemeCodeUnspsc190501.50381959': __('identifierSchemeCodeUnspsc190501.50381959'),
	'identifierSchemeCodeUnspsc190501.50381960': __('identifierSchemeCodeUnspsc190501.50381960'),
	'identifierSchemeCodeUnspsc190501.50381961': __('identifierSchemeCodeUnspsc190501.50381961'),
	'identifierSchemeCodeUnspsc190501.50381962': __('identifierSchemeCodeUnspsc190501.50381962'),
	'identifierSchemeCodeUnspsc190501.50381963': __('identifierSchemeCodeUnspsc190501.50381963'),
	'identifierSchemeCodeUnspsc190501.50381964': __('identifierSchemeCodeUnspsc190501.50381964'),
	'identifierSchemeCodeUnspsc190501.50381965': __('identifierSchemeCodeUnspsc190501.50381965'),
	'identifierSchemeCodeUnspsc190501.50381966': __('identifierSchemeCodeUnspsc190501.50381966'),
	'identifierSchemeCodeUnspsc190501.50381967': __('identifierSchemeCodeUnspsc190501.50381967'),
	'identifierSchemeCodeUnspsc190501.50381968': __('identifierSchemeCodeUnspsc190501.50381968'),
	'identifierSchemeCodeUnspsc190501.50381969': __('identifierSchemeCodeUnspsc190501.50381969'),
	'identifierSchemeCodeUnspsc190501.50381970': __('identifierSchemeCodeUnspsc190501.50381970'),
	'identifierSchemeCodeUnspsc190501.50381971': __('identifierSchemeCodeUnspsc190501.50381971'),
	'identifierSchemeCodeUnspsc190501.50381972': __('identifierSchemeCodeUnspsc190501.50381972'),
	'identifierSchemeCodeUnspsc190501.50381973': __('identifierSchemeCodeUnspsc190501.50381973'),
	'identifierSchemeCodeUnspsc190501.50381974': __('identifierSchemeCodeUnspsc190501.50381974'),
	'identifierSchemeCodeUnspsc190501.50381975': __('identifierSchemeCodeUnspsc190501.50381975'),
	'identifierSchemeCodeUnspsc190501.50381976': __('identifierSchemeCodeUnspsc190501.50381976'),
	'identifierSchemeCodeUnspsc190501.50381977': __('identifierSchemeCodeUnspsc190501.50381977'),
	'identifierSchemeCodeUnspsc190501.50381978': __('identifierSchemeCodeUnspsc190501.50381978'),
	'identifierSchemeCodeUnspsc190501.50381979': __('identifierSchemeCodeUnspsc190501.50381979'),
	'identifierSchemeCodeUnspsc190501.50381980': __('identifierSchemeCodeUnspsc190501.50381980'),
	'identifierSchemeCodeUnspsc190501.50381981': __('identifierSchemeCodeUnspsc190501.50381981'),
	'identifierSchemeCodeUnspsc190501.50381982': __('identifierSchemeCodeUnspsc190501.50381982'),
	'identifierSchemeCodeUnspsc190501.50381983': __('identifierSchemeCodeUnspsc190501.50381983'),
	'identifierSchemeCodeUnspsc190501.50381984': __('identifierSchemeCodeUnspsc190501.50381984'),
	'identifierSchemeCodeUnspsc190501.50381985': __('identifierSchemeCodeUnspsc190501.50381985'),
	'identifierSchemeCodeUnspsc190501.50381986': __('identifierSchemeCodeUnspsc190501.50381986'),
	'identifierSchemeCodeUnspsc190501.50381987': __('identifierSchemeCodeUnspsc190501.50381987'),
	'identifierSchemeCodeUnspsc190501.50381988': __('identifierSchemeCodeUnspsc190501.50381988'),
	'identifierSchemeCodeUnspsc190501.50381989': __('identifierSchemeCodeUnspsc190501.50381989'),
	'identifierSchemeCodeUnspsc190501.50381990': __('identifierSchemeCodeUnspsc190501.50381990'),
	'identifierSchemeCodeUnspsc190501.50381991': __('identifierSchemeCodeUnspsc190501.50381991'),
	'identifierSchemeCodeUnspsc190501.50381992': __('identifierSchemeCodeUnspsc190501.50381992'),
	'identifierSchemeCodeUnspsc190501.50381993': __('identifierSchemeCodeUnspsc190501.50381993'),
	'identifierSchemeCodeUnspsc190501.50381994': __('identifierSchemeCodeUnspsc190501.50381994'),
	'identifierSchemeCodeUnspsc190501.50381995': __('identifierSchemeCodeUnspsc190501.50381995'),
	'identifierSchemeCodeUnspsc190501.50381996': __('identifierSchemeCodeUnspsc190501.50381996'),
	'identifierSchemeCodeUnspsc190501.50381997': __('identifierSchemeCodeUnspsc190501.50381997'),
	'identifierSchemeCodeUnspsc190501.50381998': __('identifierSchemeCodeUnspsc190501.50381998'),
	'identifierSchemeCodeUnspsc190501.50381999': __('identifierSchemeCodeUnspsc190501.50381999'),
	'identifierSchemeCodeUnspsc190501.50382000': __('identifierSchemeCodeUnspsc190501.50382000'),
	'identifierSchemeCodeUnspsc190501.50382001': __('identifierSchemeCodeUnspsc190501.50382001'),
	'identifierSchemeCodeUnspsc190501.50382002': __('identifierSchemeCodeUnspsc190501.50382002'),
	'identifierSchemeCodeUnspsc190501.50382003': __('identifierSchemeCodeUnspsc190501.50382003'),
	'identifierSchemeCodeUnspsc190501.50382004': __('identifierSchemeCodeUnspsc190501.50382004'),
	'identifierSchemeCodeUnspsc190501.50382005': __('identifierSchemeCodeUnspsc190501.50382005'),
	'identifierSchemeCodeUnspsc190501.50382006': __('identifierSchemeCodeUnspsc190501.50382006'),
	'identifierSchemeCodeUnspsc190501.50382007': __('identifierSchemeCodeUnspsc190501.50382007'),
	'identifierSchemeCodeUnspsc190501.50382008': __('identifierSchemeCodeUnspsc190501.50382008'),
	'identifierSchemeCodeUnspsc190501.50382009': __('identifierSchemeCodeUnspsc190501.50382009'),
	'identifierSchemeCodeUnspsc190501.50382010': __('identifierSchemeCodeUnspsc190501.50382010'),
	'identifierSchemeCodeUnspsc190501.50382011': __('identifierSchemeCodeUnspsc190501.50382011'),
	'identifierSchemeCodeUnspsc190501.50382012': __('identifierSchemeCodeUnspsc190501.50382012'),
	'identifierSchemeCodeUnspsc190501.50382013': __('identifierSchemeCodeUnspsc190501.50382013'),
	'identifierSchemeCodeUnspsc190501.50382014': __('identifierSchemeCodeUnspsc190501.50382014'),
	'identifierSchemeCodeUnspsc190501.50382015': __('identifierSchemeCodeUnspsc190501.50382015'),
	'identifierSchemeCodeUnspsc190501.50382016': __('identifierSchemeCodeUnspsc190501.50382016'),
	'identifierSchemeCodeUnspsc190501.50382017': __('identifierSchemeCodeUnspsc190501.50382017'),
	'identifierSchemeCodeUnspsc190501.50382018': __('identifierSchemeCodeUnspsc190501.50382018'),
	'identifierSchemeCodeUnspsc190501.50382019': __('identifierSchemeCodeUnspsc190501.50382019'),
	'identifierSchemeCodeUnspsc190501.50382020': __('identifierSchemeCodeUnspsc190501.50382020'),
	'identifierSchemeCodeUnspsc190501.50382021': __('identifierSchemeCodeUnspsc190501.50382021'),
	'identifierSchemeCodeUnspsc190501.50382022': __('identifierSchemeCodeUnspsc190501.50382022'),
	'identifierSchemeCodeUnspsc190501.50382023': __('identifierSchemeCodeUnspsc190501.50382023'),
	'identifierSchemeCodeUnspsc190501.50382024': __('identifierSchemeCodeUnspsc190501.50382024'),
	'identifierSchemeCodeUnspsc190501.50382025': __('identifierSchemeCodeUnspsc190501.50382025'),
	'identifierSchemeCodeUnspsc190501.50382026': __('identifierSchemeCodeUnspsc190501.50382026'),
	'identifierSchemeCodeUnspsc190501.50382027': __('identifierSchemeCodeUnspsc190501.50382027'),
	'identifierSchemeCodeUnspsc190501.50382028': __('identifierSchemeCodeUnspsc190501.50382028'),
	'identifierSchemeCodeUnspsc190501.50382029': __('identifierSchemeCodeUnspsc190501.50382029'),
	'identifierSchemeCodeUnspsc190501.50382030': __('identifierSchemeCodeUnspsc190501.50382030'),
	'identifierSchemeCodeUnspsc190501.50382031': __('identifierSchemeCodeUnspsc190501.50382031'),
	'identifierSchemeCodeUnspsc190501.50382032': __('identifierSchemeCodeUnspsc190501.50382032'),
	'identifierSchemeCodeUnspsc190501.50382033': __('identifierSchemeCodeUnspsc190501.50382033'),
	'identifierSchemeCodeUnspsc190501.50382034': __('identifierSchemeCodeUnspsc190501.50382034'),
	'identifierSchemeCodeUnspsc190501.50382035': __('identifierSchemeCodeUnspsc190501.50382035'),
	'identifierSchemeCodeUnspsc190501.50382036': __('identifierSchemeCodeUnspsc190501.50382036'),
	'identifierSchemeCodeUnspsc190501.50382037': __('identifierSchemeCodeUnspsc190501.50382037'),
	'identifierSchemeCodeUnspsc190501.50382038': __('identifierSchemeCodeUnspsc190501.50382038'),
	'identifierSchemeCodeUnspsc190501.50382039': __('identifierSchemeCodeUnspsc190501.50382039'),
	'identifierSchemeCodeUnspsc190501.50382040': __('identifierSchemeCodeUnspsc190501.50382040'),
	'identifierSchemeCodeUnspsc190501.50382041': __('identifierSchemeCodeUnspsc190501.50382041'),
	'identifierSchemeCodeUnspsc190501.50382042': __('identifierSchemeCodeUnspsc190501.50382042'),
	'identifierSchemeCodeUnspsc190501.50382043': __('identifierSchemeCodeUnspsc190501.50382043'),
	'identifierSchemeCodeUnspsc190501.50382044': __('identifierSchemeCodeUnspsc190501.50382044'),
	'identifierSchemeCodeUnspsc190501.50382045': __('identifierSchemeCodeUnspsc190501.50382045'),
	'identifierSchemeCodeUnspsc190501.50382046': __('identifierSchemeCodeUnspsc190501.50382046'),
	'identifierSchemeCodeUnspsc190501.50382047': __('identifierSchemeCodeUnspsc190501.50382047'),
	'identifierSchemeCodeUnspsc190501.50382048': __('identifierSchemeCodeUnspsc190501.50382048'),
	'identifierSchemeCodeUnspsc190501.50382049': __('identifierSchemeCodeUnspsc190501.50382049'),
	'identifierSchemeCodeUnspsc190501.50382050': __('identifierSchemeCodeUnspsc190501.50382050'),
	'identifierSchemeCodeUnspsc190501.50382051': __('identifierSchemeCodeUnspsc190501.50382051'),
	'identifierSchemeCodeUnspsc190501.50382052': __('identifierSchemeCodeUnspsc190501.50382052'),
	'identifierSchemeCodeUnspsc190501.50382053': __('identifierSchemeCodeUnspsc190501.50382053'),
	'identifierSchemeCodeUnspsc190501.50382054': __('identifierSchemeCodeUnspsc190501.50382054'),
	'identifierSchemeCodeUnspsc190501.50382055': __('identifierSchemeCodeUnspsc190501.50382055'),
	'identifierSchemeCodeUnspsc190501.50382100': __('identifierSchemeCodeUnspsc190501.50382100'),
	'identifierSchemeCodeUnspsc190501.50382101': __('identifierSchemeCodeUnspsc190501.50382101'),
	'identifierSchemeCodeUnspsc190501.50382102': __('identifierSchemeCodeUnspsc190501.50382102'),
	'identifierSchemeCodeUnspsc190501.50382103': __('identifierSchemeCodeUnspsc190501.50382103'),
	'identifierSchemeCodeUnspsc190501.50382104': __('identifierSchemeCodeUnspsc190501.50382104'),
	'identifierSchemeCodeUnspsc190501.50382105': __('identifierSchemeCodeUnspsc190501.50382105'),
	'identifierSchemeCodeUnspsc190501.50382106': __('identifierSchemeCodeUnspsc190501.50382106'),
	'identifierSchemeCodeUnspsc190501.50382107': __('identifierSchemeCodeUnspsc190501.50382107'),
	'identifierSchemeCodeUnspsc190501.50382108': __('identifierSchemeCodeUnspsc190501.50382108'),
	'identifierSchemeCodeUnspsc190501.50382109': __('identifierSchemeCodeUnspsc190501.50382109'),
	'identifierSchemeCodeUnspsc190501.50382110': __('identifierSchemeCodeUnspsc190501.50382110'),
	'identifierSchemeCodeUnspsc190501.50382111': __('identifierSchemeCodeUnspsc190501.50382111'),
	'identifierSchemeCodeUnspsc190501.50382112': __('identifierSchemeCodeUnspsc190501.50382112'),
	'identifierSchemeCodeUnspsc190501.50382113': __('identifierSchemeCodeUnspsc190501.50382113'),
	'identifierSchemeCodeUnspsc190501.50382114': __('identifierSchemeCodeUnspsc190501.50382114'),
	'identifierSchemeCodeUnspsc190501.50382115': __('identifierSchemeCodeUnspsc190501.50382115'),
	'identifierSchemeCodeUnspsc190501.50382116': __('identifierSchemeCodeUnspsc190501.50382116'),
	'identifierSchemeCodeUnspsc190501.50382117': __('identifierSchemeCodeUnspsc190501.50382117'),
	'identifierSchemeCodeUnspsc190501.50382118': __('identifierSchemeCodeUnspsc190501.50382118'),
	'identifierSchemeCodeUnspsc190501.50382119': __('identifierSchemeCodeUnspsc190501.50382119'),
	'identifierSchemeCodeUnspsc190501.50382200': __('identifierSchemeCodeUnspsc190501.50382200'),
	'identifierSchemeCodeUnspsc190501.50382201': __('identifierSchemeCodeUnspsc190501.50382201'),
	'identifierSchemeCodeUnspsc190501.50382202': __('identifierSchemeCodeUnspsc190501.50382202'),
	'identifierSchemeCodeUnspsc190501.50382203': __('identifierSchemeCodeUnspsc190501.50382203'),
	'identifierSchemeCodeUnspsc190501.50382204': __('identifierSchemeCodeUnspsc190501.50382204'),
	'identifierSchemeCodeUnspsc190501.50382205': __('identifierSchemeCodeUnspsc190501.50382205'),
	'identifierSchemeCodeUnspsc190501.50382206': __('identifierSchemeCodeUnspsc190501.50382206'),
	'identifierSchemeCodeUnspsc190501.50382207': __('identifierSchemeCodeUnspsc190501.50382207'),
	'identifierSchemeCodeUnspsc190501.50382208': __('identifierSchemeCodeUnspsc190501.50382208'),
	'identifierSchemeCodeUnspsc190501.50382209': __('identifierSchemeCodeUnspsc190501.50382209'),
	'identifierSchemeCodeUnspsc190501.50382210': __('identifierSchemeCodeUnspsc190501.50382210'),
	'identifierSchemeCodeUnspsc190501.50382211': __('identifierSchemeCodeUnspsc190501.50382211'),
	'identifierSchemeCodeUnspsc190501.50382212': __('identifierSchemeCodeUnspsc190501.50382212'),
	'identifierSchemeCodeUnspsc190501.50382213': __('identifierSchemeCodeUnspsc190501.50382213'),
	'identifierSchemeCodeUnspsc190501.50382214': __('identifierSchemeCodeUnspsc190501.50382214'),
	'identifierSchemeCodeUnspsc190501.50382300': __('identifierSchemeCodeUnspsc190501.50382300'),
	'identifierSchemeCodeUnspsc190501.50382301': __('identifierSchemeCodeUnspsc190501.50382301'),
	'identifierSchemeCodeUnspsc190501.50382302': __('identifierSchemeCodeUnspsc190501.50382302'),
	'identifierSchemeCodeUnspsc190501.50382303': __('identifierSchemeCodeUnspsc190501.50382303'),
	'identifierSchemeCodeUnspsc190501.50382400': __('identifierSchemeCodeUnspsc190501.50382400'),
	'identifierSchemeCodeUnspsc190501.50382401': __('identifierSchemeCodeUnspsc190501.50382401'),
	'identifierSchemeCodeUnspsc190501.50382402': __('identifierSchemeCodeUnspsc190501.50382402'),
	'identifierSchemeCodeUnspsc190501.50382403': __('identifierSchemeCodeUnspsc190501.50382403'),
	'identifierSchemeCodeUnspsc190501.50382404': __('identifierSchemeCodeUnspsc190501.50382404'),
	'identifierSchemeCodeUnspsc190501.50382405': __('identifierSchemeCodeUnspsc190501.50382405'),
	'identifierSchemeCodeUnspsc190501.50382406': __('identifierSchemeCodeUnspsc190501.50382406'),
	'identifierSchemeCodeUnspsc190501.50382407': __('identifierSchemeCodeUnspsc190501.50382407'),
	'identifierSchemeCodeUnspsc190501.50382408': __('identifierSchemeCodeUnspsc190501.50382408'),
	'identifierSchemeCodeUnspsc190501.50382409': __('identifierSchemeCodeUnspsc190501.50382409'),
	'identifierSchemeCodeUnspsc190501.50382410': __('identifierSchemeCodeUnspsc190501.50382410'),
	'identifierSchemeCodeUnspsc190501.50382411': __('identifierSchemeCodeUnspsc190501.50382411'),
	'identifierSchemeCodeUnspsc190501.50382412': __('identifierSchemeCodeUnspsc190501.50382412'),
	'identifierSchemeCodeUnspsc190501.50382413': __('identifierSchemeCodeUnspsc190501.50382413'),
	'identifierSchemeCodeUnspsc190501.50382414': __('identifierSchemeCodeUnspsc190501.50382414'),
	'identifierSchemeCodeUnspsc190501.50382415': __('identifierSchemeCodeUnspsc190501.50382415'),
	'identifierSchemeCodeUnspsc190501.50382500': __('identifierSchemeCodeUnspsc190501.50382500'),
	'identifierSchemeCodeUnspsc190501.50382501': __('identifierSchemeCodeUnspsc190501.50382501'),
	'identifierSchemeCodeUnspsc190501.50382502': __('identifierSchemeCodeUnspsc190501.50382502'),
	'identifierSchemeCodeUnspsc190501.50382503': __('identifierSchemeCodeUnspsc190501.50382503'),
	'identifierSchemeCodeUnspsc190501.50382504': __('identifierSchemeCodeUnspsc190501.50382504'),
	'identifierSchemeCodeUnspsc190501.50382600': __('identifierSchemeCodeUnspsc190501.50382600'),
	'identifierSchemeCodeUnspsc190501.50382601': __('identifierSchemeCodeUnspsc190501.50382601'),
	'identifierSchemeCodeUnspsc190501.50382602': __('identifierSchemeCodeUnspsc190501.50382602'),
	'identifierSchemeCodeUnspsc190501.50382603': __('identifierSchemeCodeUnspsc190501.50382603'),
	'identifierSchemeCodeUnspsc190501.50382604': __('identifierSchemeCodeUnspsc190501.50382604'),
	'identifierSchemeCodeUnspsc190501.50382605': __('identifierSchemeCodeUnspsc190501.50382605'),
	'identifierSchemeCodeUnspsc190501.50382606': __('identifierSchemeCodeUnspsc190501.50382606'),
	'identifierSchemeCodeUnspsc190501.50382607': __('identifierSchemeCodeUnspsc190501.50382607'),
	'identifierSchemeCodeUnspsc190501.50382608': __('identifierSchemeCodeUnspsc190501.50382608'),
	'identifierSchemeCodeUnspsc190501.50382609': __('identifierSchemeCodeUnspsc190501.50382609'),
	'identifierSchemeCodeUnspsc190501.50382610': __('identifierSchemeCodeUnspsc190501.50382610'),
	'identifierSchemeCodeUnspsc190501.50382611': __('identifierSchemeCodeUnspsc190501.50382611'),
	'identifierSchemeCodeUnspsc190501.50382612': __('identifierSchemeCodeUnspsc190501.50382612'),
	'identifierSchemeCodeUnspsc190501.50382613': __('identifierSchemeCodeUnspsc190501.50382613'),
	'identifierSchemeCodeUnspsc190501.50382614': __('identifierSchemeCodeUnspsc190501.50382614'),
	'identifierSchemeCodeUnspsc190501.50382615': __('identifierSchemeCodeUnspsc190501.50382615'),
	'identifierSchemeCodeUnspsc190501.50382700': __('identifierSchemeCodeUnspsc190501.50382700'),
	'identifierSchemeCodeUnspsc190501.50382701': __('identifierSchemeCodeUnspsc190501.50382701'),
	'identifierSchemeCodeUnspsc190501.50382702': __('identifierSchemeCodeUnspsc190501.50382702'),
	'identifierSchemeCodeUnspsc190501.50382703': __('identifierSchemeCodeUnspsc190501.50382703'),
	'identifierSchemeCodeUnspsc190501.50382704': __('identifierSchemeCodeUnspsc190501.50382704'),
	'identifierSchemeCodeUnspsc190501.50382800': __('identifierSchemeCodeUnspsc190501.50382800'),
	'identifierSchemeCodeUnspsc190501.50382801': __('identifierSchemeCodeUnspsc190501.50382801'),
	'identifierSchemeCodeUnspsc190501.50382802': __('identifierSchemeCodeUnspsc190501.50382802'),
	'identifierSchemeCodeUnspsc190501.50382803': __('identifierSchemeCodeUnspsc190501.50382803'),
	'identifierSchemeCodeUnspsc190501.50382804': __('identifierSchemeCodeUnspsc190501.50382804'),
	'identifierSchemeCodeUnspsc190501.50382805': __('identifierSchemeCodeUnspsc190501.50382805'),
	'identifierSchemeCodeUnspsc190501.50382806': __('identifierSchemeCodeUnspsc190501.50382806'),
	'identifierSchemeCodeUnspsc190501.50382807': __('identifierSchemeCodeUnspsc190501.50382807'),
	'identifierSchemeCodeUnspsc190501.50382808': __('identifierSchemeCodeUnspsc190501.50382808'),
	'identifierSchemeCodeUnspsc190501.50382809': __('identifierSchemeCodeUnspsc190501.50382809'),
	'identifierSchemeCodeUnspsc190501.50382900': __('identifierSchemeCodeUnspsc190501.50382900'),
	'identifierSchemeCodeUnspsc190501.50382901': __('identifierSchemeCodeUnspsc190501.50382901'),
	'identifierSchemeCodeUnspsc190501.50382902': __('identifierSchemeCodeUnspsc190501.50382902'),
	'identifierSchemeCodeUnspsc190501.50382903': __('identifierSchemeCodeUnspsc190501.50382903'),
	'identifierSchemeCodeUnspsc190501.50382904': __('identifierSchemeCodeUnspsc190501.50382904'),
	'identifierSchemeCodeUnspsc190501.50382905': __('identifierSchemeCodeUnspsc190501.50382905'),
	'identifierSchemeCodeUnspsc190501.50382906': __('identifierSchemeCodeUnspsc190501.50382906'),
	'identifierSchemeCodeUnspsc190501.50382907': __('identifierSchemeCodeUnspsc190501.50382907'),
	'identifierSchemeCodeUnspsc190501.50382908': __('identifierSchemeCodeUnspsc190501.50382908'),
	'identifierSchemeCodeUnspsc190501.50382909': __('identifierSchemeCodeUnspsc190501.50382909'),
	'identifierSchemeCodeUnspsc190501.50382910': __('identifierSchemeCodeUnspsc190501.50382910'),
	'identifierSchemeCodeUnspsc190501.50382911': __('identifierSchemeCodeUnspsc190501.50382911'),
	'identifierSchemeCodeUnspsc190501.50382912': __('identifierSchemeCodeUnspsc190501.50382912'),
	'identifierSchemeCodeUnspsc190501.50382913': __('identifierSchemeCodeUnspsc190501.50382913'),
	'identifierSchemeCodeUnspsc190501.50382914': __('identifierSchemeCodeUnspsc190501.50382914'),
	'identifierSchemeCodeUnspsc190501.50382915': __('identifierSchemeCodeUnspsc190501.50382915'),
	'identifierSchemeCodeUnspsc190501.50382916': __('identifierSchemeCodeUnspsc190501.50382916'),
	'identifierSchemeCodeUnspsc190501.50382917': __('identifierSchemeCodeUnspsc190501.50382917'),
	'identifierSchemeCodeUnspsc190501.50382918': __('identifierSchemeCodeUnspsc190501.50382918'),
	'identifierSchemeCodeUnspsc190501.50382919': __('identifierSchemeCodeUnspsc190501.50382919'),
	'identifierSchemeCodeUnspsc190501.50382920': __('identifierSchemeCodeUnspsc190501.50382920'),
	'identifierSchemeCodeUnspsc190501.50382921': __('identifierSchemeCodeUnspsc190501.50382921'),
	'identifierSchemeCodeUnspsc190501.50382922': __('identifierSchemeCodeUnspsc190501.50382922'),
	'identifierSchemeCodeUnspsc190501.50382923': __('identifierSchemeCodeUnspsc190501.50382923'),
	'identifierSchemeCodeUnspsc190501.50382924': __('identifierSchemeCodeUnspsc190501.50382924'),
	'identifierSchemeCodeUnspsc190501.50382925': __('identifierSchemeCodeUnspsc190501.50382925'),
	'identifierSchemeCodeUnspsc190501.50382926': __('identifierSchemeCodeUnspsc190501.50382926'),
	'identifierSchemeCodeUnspsc190501.50382927': __('identifierSchemeCodeUnspsc190501.50382927'),
	'identifierSchemeCodeUnspsc190501.50382928': __('identifierSchemeCodeUnspsc190501.50382928'),
	'identifierSchemeCodeUnspsc190501.50382929': __('identifierSchemeCodeUnspsc190501.50382929'),
	'identifierSchemeCodeUnspsc190501.50382930': __('identifierSchemeCodeUnspsc190501.50382930'),
	'identifierSchemeCodeUnspsc190501.50382931': __('identifierSchemeCodeUnspsc190501.50382931'),
	'identifierSchemeCodeUnspsc190501.50382932': __('identifierSchemeCodeUnspsc190501.50382932'),
	'identifierSchemeCodeUnspsc190501.50383000': __('identifierSchemeCodeUnspsc190501.50383000'),
	'identifierSchemeCodeUnspsc190501.50383001': __('identifierSchemeCodeUnspsc190501.50383001'),
	'identifierSchemeCodeUnspsc190501.50383002': __('identifierSchemeCodeUnspsc190501.50383002'),
	'identifierSchemeCodeUnspsc190501.50383003': __('identifierSchemeCodeUnspsc190501.50383003'),
	'identifierSchemeCodeUnspsc190501.50383004': __('identifierSchemeCodeUnspsc190501.50383004'),
	'identifierSchemeCodeUnspsc190501.50383005': __('identifierSchemeCodeUnspsc190501.50383005'),
	'identifierSchemeCodeUnspsc190501.50383006': __('identifierSchemeCodeUnspsc190501.50383006'),
	'identifierSchemeCodeUnspsc190501.50383007': __('identifierSchemeCodeUnspsc190501.50383007'),
	'identifierSchemeCodeUnspsc190501.50383100': __('identifierSchemeCodeUnspsc190501.50383100'),
	'identifierSchemeCodeUnspsc190501.50383101': __('identifierSchemeCodeUnspsc190501.50383101'),
	'identifierSchemeCodeUnspsc190501.50383102': __('identifierSchemeCodeUnspsc190501.50383102'),
	'identifierSchemeCodeUnspsc190501.50383103': __('identifierSchemeCodeUnspsc190501.50383103'),
	'identifierSchemeCodeUnspsc190501.50383104': __('identifierSchemeCodeUnspsc190501.50383104'),
	'identifierSchemeCodeUnspsc190501.50383105': __('identifierSchemeCodeUnspsc190501.50383105'),
	'identifierSchemeCodeUnspsc190501.50383106': __('identifierSchemeCodeUnspsc190501.50383106'),
	'identifierSchemeCodeUnspsc190501.50383107': __('identifierSchemeCodeUnspsc190501.50383107'),
	'identifierSchemeCodeUnspsc190501.50383108': __('identifierSchemeCodeUnspsc190501.50383108'),
	'identifierSchemeCodeUnspsc190501.50383109': __('identifierSchemeCodeUnspsc190501.50383109'),
	'identifierSchemeCodeUnspsc190501.50383110': __('identifierSchemeCodeUnspsc190501.50383110'),
	'identifierSchemeCodeUnspsc190501.50383200': __('identifierSchemeCodeUnspsc190501.50383200'),
	'identifierSchemeCodeUnspsc190501.50383201': __('identifierSchemeCodeUnspsc190501.50383201'),
	'identifierSchemeCodeUnspsc190501.50383202': __('identifierSchemeCodeUnspsc190501.50383202'),
	'identifierSchemeCodeUnspsc190501.50383203': __('identifierSchemeCodeUnspsc190501.50383203'),
	'identifierSchemeCodeUnspsc190501.50383204': __('identifierSchemeCodeUnspsc190501.50383204'),
	'identifierSchemeCodeUnspsc190501.50383205': __('identifierSchemeCodeUnspsc190501.50383205'),
	'identifierSchemeCodeUnspsc190501.50383206': __('identifierSchemeCodeUnspsc190501.50383206'),
	'identifierSchemeCodeUnspsc190501.50383300': __('identifierSchemeCodeUnspsc190501.50383300'),
	'identifierSchemeCodeUnspsc190501.50383301': __('identifierSchemeCodeUnspsc190501.50383301'),
	'identifierSchemeCodeUnspsc190501.50383302': __('identifierSchemeCodeUnspsc190501.50383302'),
	'identifierSchemeCodeUnspsc190501.50383303': __('identifierSchemeCodeUnspsc190501.50383303'),
	'identifierSchemeCodeUnspsc190501.50383304': __('identifierSchemeCodeUnspsc190501.50383304'),
	'identifierSchemeCodeUnspsc190501.50383305': __('identifierSchemeCodeUnspsc190501.50383305'),
	'identifierSchemeCodeUnspsc190501.50383306': __('identifierSchemeCodeUnspsc190501.50383306'),
	'identifierSchemeCodeUnspsc190501.50383307': __('identifierSchemeCodeUnspsc190501.50383307'),
	'identifierSchemeCodeUnspsc190501.50383308': __('identifierSchemeCodeUnspsc190501.50383308'),
	'identifierSchemeCodeUnspsc190501.50383309': __('identifierSchemeCodeUnspsc190501.50383309'),
	'identifierSchemeCodeUnspsc190501.50383310': __('identifierSchemeCodeUnspsc190501.50383310'),
	'identifierSchemeCodeUnspsc190501.50383311': __('identifierSchemeCodeUnspsc190501.50383311'),
	'identifierSchemeCodeUnspsc190501.50383312': __('identifierSchemeCodeUnspsc190501.50383312'),
	'identifierSchemeCodeUnspsc190501.50383313': __('identifierSchemeCodeUnspsc190501.50383313'),
	'identifierSchemeCodeUnspsc190501.50383314': __('identifierSchemeCodeUnspsc190501.50383314'),
	'identifierSchemeCodeUnspsc190501.50383315': __('identifierSchemeCodeUnspsc190501.50383315'),
	'identifierSchemeCodeUnspsc190501.50383316': __('identifierSchemeCodeUnspsc190501.50383316'),
	'identifierSchemeCodeUnspsc190501.50383400': __('identifierSchemeCodeUnspsc190501.50383400'),
	'identifierSchemeCodeUnspsc190501.50383401': __('identifierSchemeCodeUnspsc190501.50383401'),
	'identifierSchemeCodeUnspsc190501.50383402': __('identifierSchemeCodeUnspsc190501.50383402'),
	'identifierSchemeCodeUnspsc190501.50383500': __('identifierSchemeCodeUnspsc190501.50383500'),
	'identifierSchemeCodeUnspsc190501.50383501': __('identifierSchemeCodeUnspsc190501.50383501'),
	'identifierSchemeCodeUnspsc190501.50383502': __('identifierSchemeCodeUnspsc190501.50383502'),
	'identifierSchemeCodeUnspsc190501.50383503': __('identifierSchemeCodeUnspsc190501.50383503'),
	'identifierSchemeCodeUnspsc190501.50383504': __('identifierSchemeCodeUnspsc190501.50383504'),
	'identifierSchemeCodeUnspsc190501.50383505': __('identifierSchemeCodeUnspsc190501.50383505'),
	'identifierSchemeCodeUnspsc190501.50383506': __('identifierSchemeCodeUnspsc190501.50383506'),
	'identifierSchemeCodeUnspsc190501.50383507': __('identifierSchemeCodeUnspsc190501.50383507'),
	'identifierSchemeCodeUnspsc190501.50383508': __('identifierSchemeCodeUnspsc190501.50383508'),
	'identifierSchemeCodeUnspsc190501.50383509': __('identifierSchemeCodeUnspsc190501.50383509'),
	'identifierSchemeCodeUnspsc190501.50383510': __('identifierSchemeCodeUnspsc190501.50383510'),
	'identifierSchemeCodeUnspsc190501.50383511': __('identifierSchemeCodeUnspsc190501.50383511'),
	'identifierSchemeCodeUnspsc190501.50383600': __('identifierSchemeCodeUnspsc190501.50383600'),
	'identifierSchemeCodeUnspsc190501.50383601': __('identifierSchemeCodeUnspsc190501.50383601'),
	'identifierSchemeCodeUnspsc190501.50383602': __('identifierSchemeCodeUnspsc190501.50383602'),
	'identifierSchemeCodeUnspsc190501.50383603': __('identifierSchemeCodeUnspsc190501.50383603'),
	'identifierSchemeCodeUnspsc190501.50383604': __('identifierSchemeCodeUnspsc190501.50383604'),
	'identifierSchemeCodeUnspsc190501.50383605': __('identifierSchemeCodeUnspsc190501.50383605'),
	'identifierSchemeCodeUnspsc190501.50383606': __('identifierSchemeCodeUnspsc190501.50383606'),
	'identifierSchemeCodeUnspsc190501.50383607': __('identifierSchemeCodeUnspsc190501.50383607'),
	'identifierSchemeCodeUnspsc190501.50383700': __('identifierSchemeCodeUnspsc190501.50383700'),
	'identifierSchemeCodeUnspsc190501.50383701': __('identifierSchemeCodeUnspsc190501.50383701'),
	'identifierSchemeCodeUnspsc190501.50383702': __('identifierSchemeCodeUnspsc190501.50383702'),
	'identifierSchemeCodeUnspsc190501.50383703': __('identifierSchemeCodeUnspsc190501.50383703'),
	'identifierSchemeCodeUnspsc190501.50383704': __('identifierSchemeCodeUnspsc190501.50383704'),
	'identifierSchemeCodeUnspsc190501.50383705': __('identifierSchemeCodeUnspsc190501.50383705'),
	'identifierSchemeCodeUnspsc190501.50383706': __('identifierSchemeCodeUnspsc190501.50383706'),
	'identifierSchemeCodeUnspsc190501.50383707': __('identifierSchemeCodeUnspsc190501.50383707'),
	'identifierSchemeCodeUnspsc190501.50383708': __('identifierSchemeCodeUnspsc190501.50383708'),
	'identifierSchemeCodeUnspsc190501.50383709': __('identifierSchemeCodeUnspsc190501.50383709'),
	'identifierSchemeCodeUnspsc190501.50383800': __('identifierSchemeCodeUnspsc190501.50383800'),
	'identifierSchemeCodeUnspsc190501.50383801': __('identifierSchemeCodeUnspsc190501.50383801'),
	'identifierSchemeCodeUnspsc190501.50383802': __('identifierSchemeCodeUnspsc190501.50383802'),
	'identifierSchemeCodeUnspsc190501.50383803': __('identifierSchemeCodeUnspsc190501.50383803'),
	'identifierSchemeCodeUnspsc190501.50383804': __('identifierSchemeCodeUnspsc190501.50383804'),
	'identifierSchemeCodeUnspsc190501.50383805': __('identifierSchemeCodeUnspsc190501.50383805'),
	'identifierSchemeCodeUnspsc190501.50383806': __('identifierSchemeCodeUnspsc190501.50383806'),
	'identifierSchemeCodeUnspsc190501.50383807': __('identifierSchemeCodeUnspsc190501.50383807'),
	'identifierSchemeCodeUnspsc190501.50383808': __('identifierSchemeCodeUnspsc190501.50383808'),
	'identifierSchemeCodeUnspsc190501.50383809': __('identifierSchemeCodeUnspsc190501.50383809'),
	'identifierSchemeCodeUnspsc190501.50383810': __('identifierSchemeCodeUnspsc190501.50383810'),
	'identifierSchemeCodeUnspsc190501.50383811': __('identifierSchemeCodeUnspsc190501.50383811'),
	'identifierSchemeCodeUnspsc190501.50383812': __('identifierSchemeCodeUnspsc190501.50383812'),
	'identifierSchemeCodeUnspsc190501.50383813': __('identifierSchemeCodeUnspsc190501.50383813'),
	'identifierSchemeCodeUnspsc190501.50383814': __('identifierSchemeCodeUnspsc190501.50383814'),
	'identifierSchemeCodeUnspsc190501.50383815': __('identifierSchemeCodeUnspsc190501.50383815'),
	'identifierSchemeCodeUnspsc190501.50383816': __('identifierSchemeCodeUnspsc190501.50383816'),
	'identifierSchemeCodeUnspsc190501.50383817': __('identifierSchemeCodeUnspsc190501.50383817'),
	'identifierSchemeCodeUnspsc190501.50383818': __('identifierSchemeCodeUnspsc190501.50383818'),
	'identifierSchemeCodeUnspsc190501.50383819': __('identifierSchemeCodeUnspsc190501.50383819'),
	'identifierSchemeCodeUnspsc190501.50383820': __('identifierSchemeCodeUnspsc190501.50383820'),
	'identifierSchemeCodeUnspsc190501.50383821': __('identifierSchemeCodeUnspsc190501.50383821'),
	'identifierSchemeCodeUnspsc190501.50383822': __('identifierSchemeCodeUnspsc190501.50383822'),
	'identifierSchemeCodeUnspsc190501.50383823': __('identifierSchemeCodeUnspsc190501.50383823'),
	'identifierSchemeCodeUnspsc190501.50383824': __('identifierSchemeCodeUnspsc190501.50383824'),
	'identifierSchemeCodeUnspsc190501.50383825': __('identifierSchemeCodeUnspsc190501.50383825'),
	'identifierSchemeCodeUnspsc190501.50383826': __('identifierSchemeCodeUnspsc190501.50383826'),
	'identifierSchemeCodeUnspsc190501.50383827': __('identifierSchemeCodeUnspsc190501.50383827'),
	'identifierSchemeCodeUnspsc190501.50383828': __('identifierSchemeCodeUnspsc190501.50383828'),
	'identifierSchemeCodeUnspsc190501.50383829': __('identifierSchemeCodeUnspsc190501.50383829'),
	'identifierSchemeCodeUnspsc190501.50383830': __('identifierSchemeCodeUnspsc190501.50383830'),
	'identifierSchemeCodeUnspsc190501.50383831': __('identifierSchemeCodeUnspsc190501.50383831'),
	'identifierSchemeCodeUnspsc190501.50383832': __('identifierSchemeCodeUnspsc190501.50383832'),
	'identifierSchemeCodeUnspsc190501.50383833': __('identifierSchemeCodeUnspsc190501.50383833'),
	'identifierSchemeCodeUnspsc190501.50383834': __('identifierSchemeCodeUnspsc190501.50383834'),
	'identifierSchemeCodeUnspsc190501.50383835': __('identifierSchemeCodeUnspsc190501.50383835'),
	'identifierSchemeCodeUnspsc190501.50383836': __('identifierSchemeCodeUnspsc190501.50383836'),
	'identifierSchemeCodeUnspsc190501.50383837': __('identifierSchemeCodeUnspsc190501.50383837'),
	'identifierSchemeCodeUnspsc190501.50383838': __('identifierSchemeCodeUnspsc190501.50383838'),
	'identifierSchemeCodeUnspsc190501.50383839': __('identifierSchemeCodeUnspsc190501.50383839'),
	'identifierSchemeCodeUnspsc190501.50383840': __('identifierSchemeCodeUnspsc190501.50383840'),
	'identifierSchemeCodeUnspsc190501.50383841': __('identifierSchemeCodeUnspsc190501.50383841'),
	'identifierSchemeCodeUnspsc190501.50383842': __('identifierSchemeCodeUnspsc190501.50383842'),
	'identifierSchemeCodeUnspsc190501.50383843': __('identifierSchemeCodeUnspsc190501.50383843'),
	'identifierSchemeCodeUnspsc190501.50383844': __('identifierSchemeCodeUnspsc190501.50383844'),
	'identifierSchemeCodeUnspsc190501.50383845': __('identifierSchemeCodeUnspsc190501.50383845'),
	'identifierSchemeCodeUnspsc190501.50383846': __('identifierSchemeCodeUnspsc190501.50383846'),
	'identifierSchemeCodeUnspsc190501.50383847': __('identifierSchemeCodeUnspsc190501.50383847'),
	'identifierSchemeCodeUnspsc190501.50383848': __('identifierSchemeCodeUnspsc190501.50383848'),
	'identifierSchemeCodeUnspsc190501.50383849': __('identifierSchemeCodeUnspsc190501.50383849'),
	'identifierSchemeCodeUnspsc190501.50383850': __('identifierSchemeCodeUnspsc190501.50383850'),
	'identifierSchemeCodeUnspsc190501.50383851': __('identifierSchemeCodeUnspsc190501.50383851'),
	'identifierSchemeCodeUnspsc190501.50383852': __('identifierSchemeCodeUnspsc190501.50383852'),
	'identifierSchemeCodeUnspsc190501.50383853': __('identifierSchemeCodeUnspsc190501.50383853'),
	'identifierSchemeCodeUnspsc190501.50383854': __('identifierSchemeCodeUnspsc190501.50383854'),
	'identifierSchemeCodeUnspsc190501.50383855': __('identifierSchemeCodeUnspsc190501.50383855'),
	'identifierSchemeCodeUnspsc190501.50383856': __('identifierSchemeCodeUnspsc190501.50383856'),
	'identifierSchemeCodeUnspsc190501.50383857': __('identifierSchemeCodeUnspsc190501.50383857'),
	'identifierSchemeCodeUnspsc190501.50383858': __('identifierSchemeCodeUnspsc190501.50383858'),
	'identifierSchemeCodeUnspsc190501.50383859': __('identifierSchemeCodeUnspsc190501.50383859'),
	'identifierSchemeCodeUnspsc190501.50383860': __('identifierSchemeCodeUnspsc190501.50383860'),
	'identifierSchemeCodeUnspsc190501.50383861': __('identifierSchemeCodeUnspsc190501.50383861'),
	'identifierSchemeCodeUnspsc190501.50383862': __('identifierSchemeCodeUnspsc190501.50383862'),
	'identifierSchemeCodeUnspsc190501.50383863': __('identifierSchemeCodeUnspsc190501.50383863'),
	'identifierSchemeCodeUnspsc190501.50383864': __('identifierSchemeCodeUnspsc190501.50383864'),
	'identifierSchemeCodeUnspsc190501.50383865': __('identifierSchemeCodeUnspsc190501.50383865'),
	'identifierSchemeCodeUnspsc190501.50383866': __('identifierSchemeCodeUnspsc190501.50383866'),
	'identifierSchemeCodeUnspsc190501.50383867': __('identifierSchemeCodeUnspsc190501.50383867'),
	'identifierSchemeCodeUnspsc190501.50383868': __('identifierSchemeCodeUnspsc190501.50383868'),
	'identifierSchemeCodeUnspsc190501.50383869': __('identifierSchemeCodeUnspsc190501.50383869'),
	'identifierSchemeCodeUnspsc190501.50383870': __('identifierSchemeCodeUnspsc190501.50383870'),
	'identifierSchemeCodeUnspsc190501.50383871': __('identifierSchemeCodeUnspsc190501.50383871'),
	'identifierSchemeCodeUnspsc190501.50383872': __('identifierSchemeCodeUnspsc190501.50383872'),
	'identifierSchemeCodeUnspsc190501.50383873': __('identifierSchemeCodeUnspsc190501.50383873'),
	'identifierSchemeCodeUnspsc190501.50383874': __('identifierSchemeCodeUnspsc190501.50383874'),
	'identifierSchemeCodeUnspsc190501.50383875': __('identifierSchemeCodeUnspsc190501.50383875'),
	'identifierSchemeCodeUnspsc190501.50383876': __('identifierSchemeCodeUnspsc190501.50383876'),
	'identifierSchemeCodeUnspsc190501.50383877': __('identifierSchemeCodeUnspsc190501.50383877'),
	'identifierSchemeCodeUnspsc190501.50383878': __('identifierSchemeCodeUnspsc190501.50383878'),
	'identifierSchemeCodeUnspsc190501.50383879': __('identifierSchemeCodeUnspsc190501.50383879'),
	'identifierSchemeCodeUnspsc190501.50383880': __('identifierSchemeCodeUnspsc190501.50383880'),
	'identifierSchemeCodeUnspsc190501.50383881': __('identifierSchemeCodeUnspsc190501.50383881'),
	'identifierSchemeCodeUnspsc190501.50383882': __('identifierSchemeCodeUnspsc190501.50383882'),
	'identifierSchemeCodeUnspsc190501.50383883': __('identifierSchemeCodeUnspsc190501.50383883'),
	'identifierSchemeCodeUnspsc190501.50383884': __('identifierSchemeCodeUnspsc190501.50383884'),
	'identifierSchemeCodeUnspsc190501.50383885': __('identifierSchemeCodeUnspsc190501.50383885'),
	'identifierSchemeCodeUnspsc190501.50383886': __('identifierSchemeCodeUnspsc190501.50383886'),
	'identifierSchemeCodeUnspsc190501.50383887': __('identifierSchemeCodeUnspsc190501.50383887'),
	'identifierSchemeCodeUnspsc190501.50383888': __('identifierSchemeCodeUnspsc190501.50383888'),
	'identifierSchemeCodeUnspsc190501.50383889': __('identifierSchemeCodeUnspsc190501.50383889'),
	'identifierSchemeCodeUnspsc190501.50383890': __('identifierSchemeCodeUnspsc190501.50383890'),
	'identifierSchemeCodeUnspsc190501.50383891': __('identifierSchemeCodeUnspsc190501.50383891'),
	'identifierSchemeCodeUnspsc190501.50383892': __('identifierSchemeCodeUnspsc190501.50383892'),
	'identifierSchemeCodeUnspsc190501.50383893': __('identifierSchemeCodeUnspsc190501.50383893'),
	'identifierSchemeCodeUnspsc190501.50383894': __('identifierSchemeCodeUnspsc190501.50383894'),
	'identifierSchemeCodeUnspsc190501.50383895': __('identifierSchemeCodeUnspsc190501.50383895'),
	'identifierSchemeCodeUnspsc190501.50383896': __('identifierSchemeCodeUnspsc190501.50383896'),
	'identifierSchemeCodeUnspsc190501.50383897': __('identifierSchemeCodeUnspsc190501.50383897'),
	'identifierSchemeCodeUnspsc190501.50383898': __('identifierSchemeCodeUnspsc190501.50383898'),
	'identifierSchemeCodeUnspsc190501.50383899': __('identifierSchemeCodeUnspsc190501.50383899'),
	'identifierSchemeCodeUnspsc190501.50383900': __('identifierSchemeCodeUnspsc190501.50383900'),
	'identifierSchemeCodeUnspsc190501.50383901': __('identifierSchemeCodeUnspsc190501.50383901'),
	'identifierSchemeCodeUnspsc190501.50383902': __('identifierSchemeCodeUnspsc190501.50383902'),
	'identifierSchemeCodeUnspsc190501.50383903': __('identifierSchemeCodeUnspsc190501.50383903'),
	'identifierSchemeCodeUnspsc190501.50383904': __('identifierSchemeCodeUnspsc190501.50383904'),
	'identifierSchemeCodeUnspsc190501.50383905': __('identifierSchemeCodeUnspsc190501.50383905'),
	'identifierSchemeCodeUnspsc190501.50383906': __('identifierSchemeCodeUnspsc190501.50383906'),
	'identifierSchemeCodeUnspsc190501.50383907': __('identifierSchemeCodeUnspsc190501.50383907'),
	'identifierSchemeCodeUnspsc190501.50383908': __('identifierSchemeCodeUnspsc190501.50383908'),
	'identifierSchemeCodeUnspsc190501.50383909': __('identifierSchemeCodeUnspsc190501.50383909'),
	'identifierSchemeCodeUnspsc190501.50383910': __('identifierSchemeCodeUnspsc190501.50383910'),
	'identifierSchemeCodeUnspsc190501.50383911': __('identifierSchemeCodeUnspsc190501.50383911'),
	'identifierSchemeCodeUnspsc190501.50383912': __('identifierSchemeCodeUnspsc190501.50383912'),
	'identifierSchemeCodeUnspsc190501.50383913': __('identifierSchemeCodeUnspsc190501.50383913'),
	'identifierSchemeCodeUnspsc190501.50383914': __('identifierSchemeCodeUnspsc190501.50383914'),
	'identifierSchemeCodeUnspsc190501.50383915': __('identifierSchemeCodeUnspsc190501.50383915'),
	'identifierSchemeCodeUnspsc190501.50383916': __('identifierSchemeCodeUnspsc190501.50383916'),
	'identifierSchemeCodeUnspsc190501.50383917': __('identifierSchemeCodeUnspsc190501.50383917'),
	'identifierSchemeCodeUnspsc190501.50383918': __('identifierSchemeCodeUnspsc190501.50383918'),
	'identifierSchemeCodeUnspsc190501.50383919': __('identifierSchemeCodeUnspsc190501.50383919'),
	'identifierSchemeCodeUnspsc190501.50383920': __('identifierSchemeCodeUnspsc190501.50383920'),
	'identifierSchemeCodeUnspsc190501.50383921': __('identifierSchemeCodeUnspsc190501.50383921'),
	'identifierSchemeCodeUnspsc190501.50383922': __('identifierSchemeCodeUnspsc190501.50383922'),
	'identifierSchemeCodeUnspsc190501.50383923': __('identifierSchemeCodeUnspsc190501.50383923'),
	'identifierSchemeCodeUnspsc190501.50383924': __('identifierSchemeCodeUnspsc190501.50383924'),
	'identifierSchemeCodeUnspsc190501.50383925': __('identifierSchemeCodeUnspsc190501.50383925'),
	'identifierSchemeCodeUnspsc190501.50383926': __('identifierSchemeCodeUnspsc190501.50383926'),
	'identifierSchemeCodeUnspsc190501.50383927': __('identifierSchemeCodeUnspsc190501.50383927'),
	'identifierSchemeCodeUnspsc190501.50383928': __('identifierSchemeCodeUnspsc190501.50383928'),
	'identifierSchemeCodeUnspsc190501.50383929': __('identifierSchemeCodeUnspsc190501.50383929'),
	'identifierSchemeCodeUnspsc190501.50383930': __('identifierSchemeCodeUnspsc190501.50383930'),
	'identifierSchemeCodeUnspsc190501.50383931': __('identifierSchemeCodeUnspsc190501.50383931'),
	'identifierSchemeCodeUnspsc190501.50383932': __('identifierSchemeCodeUnspsc190501.50383932'),
	'identifierSchemeCodeUnspsc190501.50383933': __('identifierSchemeCodeUnspsc190501.50383933'),
	'identifierSchemeCodeUnspsc190501.50383934': __('identifierSchemeCodeUnspsc190501.50383934'),
	'identifierSchemeCodeUnspsc190501.50383935': __('identifierSchemeCodeUnspsc190501.50383935'),
	'identifierSchemeCodeUnspsc190501.50383936': __('identifierSchemeCodeUnspsc190501.50383936'),
	'identifierSchemeCodeUnspsc190501.50383937': __('identifierSchemeCodeUnspsc190501.50383937'),
	'identifierSchemeCodeUnspsc190501.50383938': __('identifierSchemeCodeUnspsc190501.50383938'),
	'identifierSchemeCodeUnspsc190501.50383939': __('identifierSchemeCodeUnspsc190501.50383939'),
	'identifierSchemeCodeUnspsc190501.50383940': __('identifierSchemeCodeUnspsc190501.50383940'),
	'identifierSchemeCodeUnspsc190501.50383941': __('identifierSchemeCodeUnspsc190501.50383941'),
	'identifierSchemeCodeUnspsc190501.50383942': __('identifierSchemeCodeUnspsc190501.50383942'),
	'identifierSchemeCodeUnspsc190501.50383943': __('identifierSchemeCodeUnspsc190501.50383943'),
	'identifierSchemeCodeUnspsc190501.50383944': __('identifierSchemeCodeUnspsc190501.50383944'),
	'identifierSchemeCodeUnspsc190501.50383945': __('identifierSchemeCodeUnspsc190501.50383945'),
	'identifierSchemeCodeUnspsc190501.50383946': __('identifierSchemeCodeUnspsc190501.50383946'),
	'identifierSchemeCodeUnspsc190501.50383947': __('identifierSchemeCodeUnspsc190501.50383947'),
	'identifierSchemeCodeUnspsc190501.50383948': __('identifierSchemeCodeUnspsc190501.50383948'),
	'identifierSchemeCodeUnspsc190501.50383949': __('identifierSchemeCodeUnspsc190501.50383949'),
	'identifierSchemeCodeUnspsc190501.50383950': __('identifierSchemeCodeUnspsc190501.50383950'),
	'identifierSchemeCodeUnspsc190501.50383951': __('identifierSchemeCodeUnspsc190501.50383951'),
	'identifierSchemeCodeUnspsc190501.50383952': __('identifierSchemeCodeUnspsc190501.50383952'),
	'identifierSchemeCodeUnspsc190501.50383953': __('identifierSchemeCodeUnspsc190501.50383953'),
	'identifierSchemeCodeUnspsc190501.50383954': __('identifierSchemeCodeUnspsc190501.50383954'),
	'identifierSchemeCodeUnspsc190501.50383955': __('identifierSchemeCodeUnspsc190501.50383955'),
	'identifierSchemeCodeUnspsc190501.50383956': __('identifierSchemeCodeUnspsc190501.50383956'),
	'identifierSchemeCodeUnspsc190501.50383957': __('identifierSchemeCodeUnspsc190501.50383957'),
	'identifierSchemeCodeUnspsc190501.50383958': __('identifierSchemeCodeUnspsc190501.50383958'),
	'identifierSchemeCodeUnspsc190501.50383959': __('identifierSchemeCodeUnspsc190501.50383959'),
	'identifierSchemeCodeUnspsc190501.50383960': __('identifierSchemeCodeUnspsc190501.50383960'),
	'identifierSchemeCodeUnspsc190501.50383961': __('identifierSchemeCodeUnspsc190501.50383961'),
	'identifierSchemeCodeUnspsc190501.50383962': __('identifierSchemeCodeUnspsc190501.50383962'),
	'identifierSchemeCodeUnspsc190501.50383963': __('identifierSchemeCodeUnspsc190501.50383963'),
	'identifierSchemeCodeUnspsc190501.50383964': __('identifierSchemeCodeUnspsc190501.50383964'),
	'identifierSchemeCodeUnspsc190501.50383965': __('identifierSchemeCodeUnspsc190501.50383965'),
	'identifierSchemeCodeUnspsc190501.50384000': __('identifierSchemeCodeUnspsc190501.50384000'),
	'identifierSchemeCodeUnspsc190501.50384001': __('identifierSchemeCodeUnspsc190501.50384001'),
	'identifierSchemeCodeUnspsc190501.50384002': __('identifierSchemeCodeUnspsc190501.50384002'),
	'identifierSchemeCodeUnspsc190501.50384003': __('identifierSchemeCodeUnspsc190501.50384003'),
	'identifierSchemeCodeUnspsc190501.50384004': __('identifierSchemeCodeUnspsc190501.50384004'),
	'identifierSchemeCodeUnspsc190501.50384005': __('identifierSchemeCodeUnspsc190501.50384005'),
	'identifierSchemeCodeUnspsc190501.50384006': __('identifierSchemeCodeUnspsc190501.50384006'),
	'identifierSchemeCodeUnspsc190501.50384100': __('identifierSchemeCodeUnspsc190501.50384100'),
	'identifierSchemeCodeUnspsc190501.50384101': __('identifierSchemeCodeUnspsc190501.50384101'),
	'identifierSchemeCodeUnspsc190501.50384102': __('identifierSchemeCodeUnspsc190501.50384102'),
	'identifierSchemeCodeUnspsc190501.50384103': __('identifierSchemeCodeUnspsc190501.50384103'),
	'identifierSchemeCodeUnspsc190501.50384104': __('identifierSchemeCodeUnspsc190501.50384104'),
	'identifierSchemeCodeUnspsc190501.50384200': __('identifierSchemeCodeUnspsc190501.50384200'),
	'identifierSchemeCodeUnspsc190501.50384201': __('identifierSchemeCodeUnspsc190501.50384201'),
	'identifierSchemeCodeUnspsc190501.50384202': __('identifierSchemeCodeUnspsc190501.50384202'),
	'identifierSchemeCodeUnspsc190501.50384203': __('identifierSchemeCodeUnspsc190501.50384203'),
	'identifierSchemeCodeUnspsc190501.50384204': __('identifierSchemeCodeUnspsc190501.50384204'),
	'identifierSchemeCodeUnspsc190501.50384205': __('identifierSchemeCodeUnspsc190501.50384205'),
	'identifierSchemeCodeUnspsc190501.50384300': __('identifierSchemeCodeUnspsc190501.50384300'),
	'identifierSchemeCodeUnspsc190501.50384301': __('identifierSchemeCodeUnspsc190501.50384301'),
	'identifierSchemeCodeUnspsc190501.50384302': __('identifierSchemeCodeUnspsc190501.50384302'),
	'identifierSchemeCodeUnspsc190501.50384303': __('identifierSchemeCodeUnspsc190501.50384303'),
	'identifierSchemeCodeUnspsc190501.50384304': __('identifierSchemeCodeUnspsc190501.50384304'),
	'identifierSchemeCodeUnspsc190501.50384305': __('identifierSchemeCodeUnspsc190501.50384305'),
	'identifierSchemeCodeUnspsc190501.50384306': __('identifierSchemeCodeUnspsc190501.50384306'),
	'identifierSchemeCodeUnspsc190501.50384400': __('identifierSchemeCodeUnspsc190501.50384400'),
	'identifierSchemeCodeUnspsc190501.50384401': __('identifierSchemeCodeUnspsc190501.50384401'),
	'identifierSchemeCodeUnspsc190501.50384402': __('identifierSchemeCodeUnspsc190501.50384402'),
	'identifierSchemeCodeUnspsc190501.50384403': __('identifierSchemeCodeUnspsc190501.50384403'),
	'identifierSchemeCodeUnspsc190501.50384404': __('identifierSchemeCodeUnspsc190501.50384404'),
	'identifierSchemeCodeUnspsc190501.50384405': __('identifierSchemeCodeUnspsc190501.50384405'),
	'identifierSchemeCodeUnspsc190501.50384406': __('identifierSchemeCodeUnspsc190501.50384406'),
	'identifierSchemeCodeUnspsc190501.50384500': __('identifierSchemeCodeUnspsc190501.50384500'),
	'identifierSchemeCodeUnspsc190501.50384501': __('identifierSchemeCodeUnspsc190501.50384501'),
	'identifierSchemeCodeUnspsc190501.50384502': __('identifierSchemeCodeUnspsc190501.50384502'),
	'identifierSchemeCodeUnspsc190501.50384503': __('identifierSchemeCodeUnspsc190501.50384503'),
	'identifierSchemeCodeUnspsc190501.50384504': __('identifierSchemeCodeUnspsc190501.50384504'),
	'identifierSchemeCodeUnspsc190501.50384505': __('identifierSchemeCodeUnspsc190501.50384505'),
	'identifierSchemeCodeUnspsc190501.50384506': __('identifierSchemeCodeUnspsc190501.50384506'),
	'identifierSchemeCodeUnspsc190501.50384507': __('identifierSchemeCodeUnspsc190501.50384507'),
	'identifierSchemeCodeUnspsc190501.50384508': __('identifierSchemeCodeUnspsc190501.50384508'),
	'identifierSchemeCodeUnspsc190501.50384600': __('identifierSchemeCodeUnspsc190501.50384600'),
	'identifierSchemeCodeUnspsc190501.50384601': __('identifierSchemeCodeUnspsc190501.50384601'),
	'identifierSchemeCodeUnspsc190501.50384602': __('identifierSchemeCodeUnspsc190501.50384602'),
	'identifierSchemeCodeUnspsc190501.50384603': __('identifierSchemeCodeUnspsc190501.50384603'),
	'identifierSchemeCodeUnspsc190501.50384604': __('identifierSchemeCodeUnspsc190501.50384604'),
	'identifierSchemeCodeUnspsc190501.50384605': __('identifierSchemeCodeUnspsc190501.50384605'),
	'identifierSchemeCodeUnspsc190501.50384606': __('identifierSchemeCodeUnspsc190501.50384606'),
	'identifierSchemeCodeUnspsc190501.50384607': __('identifierSchemeCodeUnspsc190501.50384607'),
	'identifierSchemeCodeUnspsc190501.50384608': __('identifierSchemeCodeUnspsc190501.50384608'),
	'identifierSchemeCodeUnspsc190501.50384700': __('identifierSchemeCodeUnspsc190501.50384700'),
	'identifierSchemeCodeUnspsc190501.50384701': __('identifierSchemeCodeUnspsc190501.50384701'),
	'identifierSchemeCodeUnspsc190501.50384702': __('identifierSchemeCodeUnspsc190501.50384702'),
	'identifierSchemeCodeUnspsc190501.50384703': __('identifierSchemeCodeUnspsc190501.50384703'),
	'identifierSchemeCodeUnspsc190501.50384704': __('identifierSchemeCodeUnspsc190501.50384704'),
	'identifierSchemeCodeUnspsc190501.50384705': __('identifierSchemeCodeUnspsc190501.50384705'),
	'identifierSchemeCodeUnspsc190501.50384706': __('identifierSchemeCodeUnspsc190501.50384706'),
	'identifierSchemeCodeUnspsc190501.50384707': __('identifierSchemeCodeUnspsc190501.50384707'),
	'identifierSchemeCodeUnspsc190501.50384708': __('identifierSchemeCodeUnspsc190501.50384708'),
	'identifierSchemeCodeUnspsc190501.50384709': __('identifierSchemeCodeUnspsc190501.50384709'),
	'identifierSchemeCodeUnspsc190501.50384710': __('identifierSchemeCodeUnspsc190501.50384710'),
	'identifierSchemeCodeUnspsc190501.50384711': __('identifierSchemeCodeUnspsc190501.50384711'),
	'identifierSchemeCodeUnspsc190501.50384712': __('identifierSchemeCodeUnspsc190501.50384712'),
	'identifierSchemeCodeUnspsc190501.50384713': __('identifierSchemeCodeUnspsc190501.50384713'),
	'identifierSchemeCodeUnspsc190501.50384800': __('identifierSchemeCodeUnspsc190501.50384800'),
	'identifierSchemeCodeUnspsc190501.50384801': __('identifierSchemeCodeUnspsc190501.50384801'),
	'identifierSchemeCodeUnspsc190501.50384802': __('identifierSchemeCodeUnspsc190501.50384802'),
	'identifierSchemeCodeUnspsc190501.50384803': __('identifierSchemeCodeUnspsc190501.50384803'),
	'identifierSchemeCodeUnspsc190501.50384804': __('identifierSchemeCodeUnspsc190501.50384804'),
	'identifierSchemeCodeUnspsc190501.50384805': __('identifierSchemeCodeUnspsc190501.50384805'),
	'identifierSchemeCodeUnspsc190501.50384806': __('identifierSchemeCodeUnspsc190501.50384806'),
	'identifierSchemeCodeUnspsc190501.50384807': __('identifierSchemeCodeUnspsc190501.50384807'),
	'identifierSchemeCodeUnspsc190501.50384808': __('identifierSchemeCodeUnspsc190501.50384808'),
	'identifierSchemeCodeUnspsc190501.50384809': __('identifierSchemeCodeUnspsc190501.50384809'),
	'identifierSchemeCodeUnspsc190501.50384810': __('identifierSchemeCodeUnspsc190501.50384810'),
	'identifierSchemeCodeUnspsc190501.50384811': __('identifierSchemeCodeUnspsc190501.50384811'),
	'identifierSchemeCodeUnspsc190501.50384812': __('identifierSchemeCodeUnspsc190501.50384812'),
	'identifierSchemeCodeUnspsc190501.50384813': __('identifierSchemeCodeUnspsc190501.50384813'),
	'identifierSchemeCodeUnspsc190501.50384814': __('identifierSchemeCodeUnspsc190501.50384814'),
	'identifierSchemeCodeUnspsc190501.50384815': __('identifierSchemeCodeUnspsc190501.50384815'),
	'identifierSchemeCodeUnspsc190501.50384816': __('identifierSchemeCodeUnspsc190501.50384816'),
	'identifierSchemeCodeUnspsc190501.50384817': __('identifierSchemeCodeUnspsc190501.50384817'),
	'identifierSchemeCodeUnspsc190501.50384818': __('identifierSchemeCodeUnspsc190501.50384818'),
	'identifierSchemeCodeUnspsc190501.50384819': __('identifierSchemeCodeUnspsc190501.50384819'),
	'identifierSchemeCodeUnspsc190501.50384820': __('identifierSchemeCodeUnspsc190501.50384820'),
	'identifierSchemeCodeUnspsc190501.50384821': __('identifierSchemeCodeUnspsc190501.50384821'),
	'identifierSchemeCodeUnspsc190501.50384822': __('identifierSchemeCodeUnspsc190501.50384822'),
	'identifierSchemeCodeUnspsc190501.50384823': __('identifierSchemeCodeUnspsc190501.50384823'),
	'identifierSchemeCodeUnspsc190501.50384824': __('identifierSchemeCodeUnspsc190501.50384824'),
	'identifierSchemeCodeUnspsc190501.50384825': __('identifierSchemeCodeUnspsc190501.50384825'),
	'identifierSchemeCodeUnspsc190501.50384826': __('identifierSchemeCodeUnspsc190501.50384826'),
	'identifierSchemeCodeUnspsc190501.50384827': __('identifierSchemeCodeUnspsc190501.50384827'),
	'identifierSchemeCodeUnspsc190501.50384828': __('identifierSchemeCodeUnspsc190501.50384828'),
	'identifierSchemeCodeUnspsc190501.50384829': __('identifierSchemeCodeUnspsc190501.50384829'),
	'identifierSchemeCodeUnspsc190501.50384830': __('identifierSchemeCodeUnspsc190501.50384830'),
	'identifierSchemeCodeUnspsc190501.50384831': __('identifierSchemeCodeUnspsc190501.50384831'),
	'identifierSchemeCodeUnspsc190501.50384832': __('identifierSchemeCodeUnspsc190501.50384832'),
	'identifierSchemeCodeUnspsc190501.50384833': __('identifierSchemeCodeUnspsc190501.50384833'),
	'identifierSchemeCodeUnspsc190501.50384834': __('identifierSchemeCodeUnspsc190501.50384834'),
	'identifierSchemeCodeUnspsc190501.50384835': __('identifierSchemeCodeUnspsc190501.50384835'),
	'identifierSchemeCodeUnspsc190501.50384836': __('identifierSchemeCodeUnspsc190501.50384836'),
	'identifierSchemeCodeUnspsc190501.50384837': __('identifierSchemeCodeUnspsc190501.50384837'),
	'identifierSchemeCodeUnspsc190501.50384838': __('identifierSchemeCodeUnspsc190501.50384838'),
	'identifierSchemeCodeUnspsc190501.50384839': __('identifierSchemeCodeUnspsc190501.50384839'),
	'identifierSchemeCodeUnspsc190501.50384900': __('identifierSchemeCodeUnspsc190501.50384900'),
	'identifierSchemeCodeUnspsc190501.50384901': __('identifierSchemeCodeUnspsc190501.50384901'),
	'identifierSchemeCodeUnspsc190501.50384902': __('identifierSchemeCodeUnspsc190501.50384902'),
	'identifierSchemeCodeUnspsc190501.50384903': __('identifierSchemeCodeUnspsc190501.50384903'),
	'identifierSchemeCodeUnspsc190501.50384904': __('identifierSchemeCodeUnspsc190501.50384904'),
	'identifierSchemeCodeUnspsc190501.50384905': __('identifierSchemeCodeUnspsc190501.50384905'),
	'identifierSchemeCodeUnspsc190501.50384906': __('identifierSchemeCodeUnspsc190501.50384906'),
	'identifierSchemeCodeUnspsc190501.50385000': __('identifierSchemeCodeUnspsc190501.50385000'),
	'identifierSchemeCodeUnspsc190501.50385001': __('identifierSchemeCodeUnspsc190501.50385001'),
	'identifierSchemeCodeUnspsc190501.50385002': __('identifierSchemeCodeUnspsc190501.50385002'),
	'identifierSchemeCodeUnspsc190501.50385003': __('identifierSchemeCodeUnspsc190501.50385003'),
	'identifierSchemeCodeUnspsc190501.50385004': __('identifierSchemeCodeUnspsc190501.50385004'),
	'identifierSchemeCodeUnspsc190501.50385005': __('identifierSchemeCodeUnspsc190501.50385005'),
	'identifierSchemeCodeUnspsc190501.50385006': __('identifierSchemeCodeUnspsc190501.50385006'),
	'identifierSchemeCodeUnspsc190501.50385007': __('identifierSchemeCodeUnspsc190501.50385007'),
	'identifierSchemeCodeUnspsc190501.50385008': __('identifierSchemeCodeUnspsc190501.50385008'),
	'identifierSchemeCodeUnspsc190501.50385009': __('identifierSchemeCodeUnspsc190501.50385009'),
	'identifierSchemeCodeUnspsc190501.50385010': __('identifierSchemeCodeUnspsc190501.50385010'),
	'identifierSchemeCodeUnspsc190501.50385011': __('identifierSchemeCodeUnspsc190501.50385011'),
	'identifierSchemeCodeUnspsc190501.50385012': __('identifierSchemeCodeUnspsc190501.50385012'),
	'identifierSchemeCodeUnspsc190501.50385013': __('identifierSchemeCodeUnspsc190501.50385013'),
	'identifierSchemeCodeUnspsc190501.50385014': __('identifierSchemeCodeUnspsc190501.50385014'),
	'identifierSchemeCodeUnspsc190501.50385015': __('identifierSchemeCodeUnspsc190501.50385015'),
	'identifierSchemeCodeUnspsc190501.50385016': __('identifierSchemeCodeUnspsc190501.50385016'),
	'identifierSchemeCodeUnspsc190501.50385017': __('identifierSchemeCodeUnspsc190501.50385017'),
	'identifierSchemeCodeUnspsc190501.50385018': __('identifierSchemeCodeUnspsc190501.50385018'),
	'identifierSchemeCodeUnspsc190501.50385019': __('identifierSchemeCodeUnspsc190501.50385019'),
	'identifierSchemeCodeUnspsc190501.50385020': __('identifierSchemeCodeUnspsc190501.50385020'),
	'identifierSchemeCodeUnspsc190501.50385021': __('identifierSchemeCodeUnspsc190501.50385021'),
	'identifierSchemeCodeUnspsc190501.50385022': __('identifierSchemeCodeUnspsc190501.50385022'),
	'identifierSchemeCodeUnspsc190501.50385023': __('identifierSchemeCodeUnspsc190501.50385023'),
	'identifierSchemeCodeUnspsc190501.50385024': __('identifierSchemeCodeUnspsc190501.50385024'),
	'identifierSchemeCodeUnspsc190501.50385025': __('identifierSchemeCodeUnspsc190501.50385025'),
	'identifierSchemeCodeUnspsc190501.50385100': __('identifierSchemeCodeUnspsc190501.50385100'),
	'identifierSchemeCodeUnspsc190501.50385101': __('identifierSchemeCodeUnspsc190501.50385101'),
	'identifierSchemeCodeUnspsc190501.50385102': __('identifierSchemeCodeUnspsc190501.50385102'),
	'identifierSchemeCodeUnspsc190501.50385103': __('identifierSchemeCodeUnspsc190501.50385103'),
	'identifierSchemeCodeUnspsc190501.50385104': __('identifierSchemeCodeUnspsc190501.50385104'),
	'identifierSchemeCodeUnspsc190501.50385105': __('identifierSchemeCodeUnspsc190501.50385105'),
	'identifierSchemeCodeUnspsc190501.50385106': __('identifierSchemeCodeUnspsc190501.50385106'),
	'identifierSchemeCodeUnspsc190501.50385107': __('identifierSchemeCodeUnspsc190501.50385107'),
	'identifierSchemeCodeUnspsc190501.50385108': __('identifierSchemeCodeUnspsc190501.50385108'),
	'identifierSchemeCodeUnspsc190501.50385109': __('identifierSchemeCodeUnspsc190501.50385109'),
	'identifierSchemeCodeUnspsc190501.50385110': __('identifierSchemeCodeUnspsc190501.50385110'),
	'identifierSchemeCodeUnspsc190501.50385111': __('identifierSchemeCodeUnspsc190501.50385111'),
	'identifierSchemeCodeUnspsc190501.50385112': __('identifierSchemeCodeUnspsc190501.50385112'),
	'identifierSchemeCodeUnspsc190501.50385113': __('identifierSchemeCodeUnspsc190501.50385113'),
	'identifierSchemeCodeUnspsc190501.50385114': __('identifierSchemeCodeUnspsc190501.50385114'),
	'identifierSchemeCodeUnspsc190501.50385115': __('identifierSchemeCodeUnspsc190501.50385115'),
	'identifierSchemeCodeUnspsc190501.50385116': __('identifierSchemeCodeUnspsc190501.50385116'),
	'identifierSchemeCodeUnspsc190501.50385117': __('identifierSchemeCodeUnspsc190501.50385117'),
	'identifierSchemeCodeUnspsc190501.50385118': __('identifierSchemeCodeUnspsc190501.50385118'),
	'identifierSchemeCodeUnspsc190501.50385119': __('identifierSchemeCodeUnspsc190501.50385119'),
	'identifierSchemeCodeUnspsc190501.50385120': __('identifierSchemeCodeUnspsc190501.50385120'),
	'identifierSchemeCodeUnspsc190501.50385121': __('identifierSchemeCodeUnspsc190501.50385121'),
	'identifierSchemeCodeUnspsc190501.50385122': __('identifierSchemeCodeUnspsc190501.50385122'),
	'identifierSchemeCodeUnspsc190501.50385123': __('identifierSchemeCodeUnspsc190501.50385123'),
	'identifierSchemeCodeUnspsc190501.50385124': __('identifierSchemeCodeUnspsc190501.50385124'),
	'identifierSchemeCodeUnspsc190501.50385125': __('identifierSchemeCodeUnspsc190501.50385125'),
	'identifierSchemeCodeUnspsc190501.50385126': __('identifierSchemeCodeUnspsc190501.50385126'),
	'identifierSchemeCodeUnspsc190501.50385127': __('identifierSchemeCodeUnspsc190501.50385127'),
	'identifierSchemeCodeUnspsc190501.50385128': __('identifierSchemeCodeUnspsc190501.50385128'),
	'identifierSchemeCodeUnspsc190501.50385129': __('identifierSchemeCodeUnspsc190501.50385129'),
	'identifierSchemeCodeUnspsc190501.50385130': __('identifierSchemeCodeUnspsc190501.50385130'),
	'identifierSchemeCodeUnspsc190501.50385131': __('identifierSchemeCodeUnspsc190501.50385131'),
	'identifierSchemeCodeUnspsc190501.50385132': __('identifierSchemeCodeUnspsc190501.50385132'),
	'identifierSchemeCodeUnspsc190501.50385133': __('identifierSchemeCodeUnspsc190501.50385133'),
	'identifierSchemeCodeUnspsc190501.50385134': __('identifierSchemeCodeUnspsc190501.50385134'),
	'identifierSchemeCodeUnspsc190501.50385135': __('identifierSchemeCodeUnspsc190501.50385135'),
	'identifierSchemeCodeUnspsc190501.50385136': __('identifierSchemeCodeUnspsc190501.50385136'),
	'identifierSchemeCodeUnspsc190501.50385137': __('identifierSchemeCodeUnspsc190501.50385137'),
	'identifierSchemeCodeUnspsc190501.50385138': __('identifierSchemeCodeUnspsc190501.50385138'),
	'identifierSchemeCodeUnspsc190501.50385139': __('identifierSchemeCodeUnspsc190501.50385139'),
	'identifierSchemeCodeUnspsc190501.50385140': __('identifierSchemeCodeUnspsc190501.50385140'),
	'identifierSchemeCodeUnspsc190501.50385141': __('identifierSchemeCodeUnspsc190501.50385141'),
	'identifierSchemeCodeUnspsc190501.50385142': __('identifierSchemeCodeUnspsc190501.50385142'),
	'identifierSchemeCodeUnspsc190501.50385143': __('identifierSchemeCodeUnspsc190501.50385143'),
	'identifierSchemeCodeUnspsc190501.50385144': __('identifierSchemeCodeUnspsc190501.50385144'),
	'identifierSchemeCodeUnspsc190501.50385145': __('identifierSchemeCodeUnspsc190501.50385145'),
	'identifierSchemeCodeUnspsc190501.50385146': __('identifierSchemeCodeUnspsc190501.50385146'),
	'identifierSchemeCodeUnspsc190501.50385147': __('identifierSchemeCodeUnspsc190501.50385147'),
	'identifierSchemeCodeUnspsc190501.50385148': __('identifierSchemeCodeUnspsc190501.50385148'),
	'identifierSchemeCodeUnspsc190501.50385149': __('identifierSchemeCodeUnspsc190501.50385149'),
	'identifierSchemeCodeUnspsc190501.50385150': __('identifierSchemeCodeUnspsc190501.50385150'),
	'identifierSchemeCodeUnspsc190501.50385151': __('identifierSchemeCodeUnspsc190501.50385151'),
	'identifierSchemeCodeUnspsc190501.50385152': __('identifierSchemeCodeUnspsc190501.50385152'),
	'identifierSchemeCodeUnspsc190501.50385153': __('identifierSchemeCodeUnspsc190501.50385153'),
	'identifierSchemeCodeUnspsc190501.50385154': __('identifierSchemeCodeUnspsc190501.50385154'),
	'identifierSchemeCodeUnspsc190501.50385155': __('identifierSchemeCodeUnspsc190501.50385155'),
	'identifierSchemeCodeUnspsc190501.50385156': __('identifierSchemeCodeUnspsc190501.50385156'),
	'identifierSchemeCodeUnspsc190501.50385157': __('identifierSchemeCodeUnspsc190501.50385157'),
	'identifierSchemeCodeUnspsc190501.50385158': __('identifierSchemeCodeUnspsc190501.50385158'),
	'identifierSchemeCodeUnspsc190501.50385159': __('identifierSchemeCodeUnspsc190501.50385159'),
	'identifierSchemeCodeUnspsc190501.50385160': __('identifierSchemeCodeUnspsc190501.50385160'),
	'identifierSchemeCodeUnspsc190501.50385161': __('identifierSchemeCodeUnspsc190501.50385161'),
	'identifierSchemeCodeUnspsc190501.50385200': __('identifierSchemeCodeUnspsc190501.50385200'),
	'identifierSchemeCodeUnspsc190501.50385201': __('identifierSchemeCodeUnspsc190501.50385201'),
	'identifierSchemeCodeUnspsc190501.50385202': __('identifierSchemeCodeUnspsc190501.50385202'),
	'identifierSchemeCodeUnspsc190501.50385300': __('identifierSchemeCodeUnspsc190501.50385300'),
	'identifierSchemeCodeUnspsc190501.50385301': __('identifierSchemeCodeUnspsc190501.50385301'),
	'identifierSchemeCodeUnspsc190501.50385302': __('identifierSchemeCodeUnspsc190501.50385302'),
	'identifierSchemeCodeUnspsc190501.50385400': __('identifierSchemeCodeUnspsc190501.50385400'),
	'identifierSchemeCodeUnspsc190501.50385401': __('identifierSchemeCodeUnspsc190501.50385401'),
	'identifierSchemeCodeUnspsc190501.50385402': __('identifierSchemeCodeUnspsc190501.50385402'),
	'identifierSchemeCodeUnspsc190501.50385403': __('identifierSchemeCodeUnspsc190501.50385403'),
	'identifierSchemeCodeUnspsc190501.50385404': __('identifierSchemeCodeUnspsc190501.50385404'),
	'identifierSchemeCodeUnspsc190501.50385405': __('identifierSchemeCodeUnspsc190501.50385405'),
	'identifierSchemeCodeUnspsc190501.50385406': __('identifierSchemeCodeUnspsc190501.50385406'),
	'identifierSchemeCodeUnspsc190501.50385407': __('identifierSchemeCodeUnspsc190501.50385407'),
	'identifierSchemeCodeUnspsc190501.50385408': __('identifierSchemeCodeUnspsc190501.50385408'),
	'identifierSchemeCodeUnspsc190501.50385409': __('identifierSchemeCodeUnspsc190501.50385409'),
	'identifierSchemeCodeUnspsc190501.50385410': __('identifierSchemeCodeUnspsc190501.50385410'),
	'identifierSchemeCodeUnspsc190501.50385411': __('identifierSchemeCodeUnspsc190501.50385411'),
	'identifierSchemeCodeUnspsc190501.50385412': __('identifierSchemeCodeUnspsc190501.50385412'),
	'identifierSchemeCodeUnspsc190501.50385413': __('identifierSchemeCodeUnspsc190501.50385413'),
	'identifierSchemeCodeUnspsc190501.50385414': __('identifierSchemeCodeUnspsc190501.50385414'),
	'identifierSchemeCodeUnspsc190501.50385415': __('identifierSchemeCodeUnspsc190501.50385415'),
	'identifierSchemeCodeUnspsc190501.50385416': __('identifierSchemeCodeUnspsc190501.50385416'),
	'identifierSchemeCodeUnspsc190501.50385417': __('identifierSchemeCodeUnspsc190501.50385417'),
	'identifierSchemeCodeUnspsc190501.50385418': __('identifierSchemeCodeUnspsc190501.50385418'),
	'identifierSchemeCodeUnspsc190501.50385419': __('identifierSchemeCodeUnspsc190501.50385419'),
	'identifierSchemeCodeUnspsc190501.50385420': __('identifierSchemeCodeUnspsc190501.50385420'),
	'identifierSchemeCodeUnspsc190501.50385421': __('identifierSchemeCodeUnspsc190501.50385421'),
	'identifierSchemeCodeUnspsc190501.50385422': __('identifierSchemeCodeUnspsc190501.50385422'),
	'identifierSchemeCodeUnspsc190501.50385423': __('identifierSchemeCodeUnspsc190501.50385423'),
	'identifierSchemeCodeUnspsc190501.50385424': __('identifierSchemeCodeUnspsc190501.50385424'),
	'identifierSchemeCodeUnspsc190501.50385425': __('identifierSchemeCodeUnspsc190501.50385425'),
	'identifierSchemeCodeUnspsc190501.50385426': __('identifierSchemeCodeUnspsc190501.50385426'),
	'identifierSchemeCodeUnspsc190501.50385427': __('identifierSchemeCodeUnspsc190501.50385427'),
	'identifierSchemeCodeUnspsc190501.50385428': __('identifierSchemeCodeUnspsc190501.50385428'),
	'identifierSchemeCodeUnspsc190501.50385429': __('identifierSchemeCodeUnspsc190501.50385429'),
	'identifierSchemeCodeUnspsc190501.50385430': __('identifierSchemeCodeUnspsc190501.50385430'),
	'identifierSchemeCodeUnspsc190501.50385431': __('identifierSchemeCodeUnspsc190501.50385431'),
	'identifierSchemeCodeUnspsc190501.50385432': __('identifierSchemeCodeUnspsc190501.50385432'),
	'identifierSchemeCodeUnspsc190501.50385433': __('identifierSchemeCodeUnspsc190501.50385433'),
	'identifierSchemeCodeUnspsc190501.50385434': __('identifierSchemeCodeUnspsc190501.50385434'),
	'identifierSchemeCodeUnspsc190501.50385435': __('identifierSchemeCodeUnspsc190501.50385435'),
	'identifierSchemeCodeUnspsc190501.50385436': __('identifierSchemeCodeUnspsc190501.50385436'),
	'identifierSchemeCodeUnspsc190501.50385437': __('identifierSchemeCodeUnspsc190501.50385437'),
	'identifierSchemeCodeUnspsc190501.50385438': __('identifierSchemeCodeUnspsc190501.50385438'),
	'identifierSchemeCodeUnspsc190501.50385439': __('identifierSchemeCodeUnspsc190501.50385439'),
	'identifierSchemeCodeUnspsc190501.50385440': __('identifierSchemeCodeUnspsc190501.50385440'),
	'identifierSchemeCodeUnspsc190501.50385441': __('identifierSchemeCodeUnspsc190501.50385441'),
	'identifierSchemeCodeUnspsc190501.50385442': __('identifierSchemeCodeUnspsc190501.50385442'),
	'identifierSchemeCodeUnspsc190501.50385443': __('identifierSchemeCodeUnspsc190501.50385443'),
	'identifierSchemeCodeUnspsc190501.50385444': __('identifierSchemeCodeUnspsc190501.50385444'),
	'identifierSchemeCodeUnspsc190501.50385445': __('identifierSchemeCodeUnspsc190501.50385445'),
	'identifierSchemeCodeUnspsc190501.50385446': __('identifierSchemeCodeUnspsc190501.50385446'),
	'identifierSchemeCodeUnspsc190501.50385447': __('identifierSchemeCodeUnspsc190501.50385447'),
	'identifierSchemeCodeUnspsc190501.50385448': __('identifierSchemeCodeUnspsc190501.50385448'),
	'identifierSchemeCodeUnspsc190501.50385449': __('identifierSchemeCodeUnspsc190501.50385449'),
	'identifierSchemeCodeUnspsc190501.50385450': __('identifierSchemeCodeUnspsc190501.50385450'),
	'identifierSchemeCodeUnspsc190501.50385451': __('identifierSchemeCodeUnspsc190501.50385451'),
	'identifierSchemeCodeUnspsc190501.50385452': __('identifierSchemeCodeUnspsc190501.50385452'),
	'identifierSchemeCodeUnspsc190501.50385453': __('identifierSchemeCodeUnspsc190501.50385453'),
	'identifierSchemeCodeUnspsc190501.50385454': __('identifierSchemeCodeUnspsc190501.50385454'),
	'identifierSchemeCodeUnspsc190501.50385455': __('identifierSchemeCodeUnspsc190501.50385455'),
	'identifierSchemeCodeUnspsc190501.50385456': __('identifierSchemeCodeUnspsc190501.50385456'),
	'identifierSchemeCodeUnspsc190501.50385457': __('identifierSchemeCodeUnspsc190501.50385457'),
	'identifierSchemeCodeUnspsc190501.50385500': __('identifierSchemeCodeUnspsc190501.50385500'),
	'identifierSchemeCodeUnspsc190501.50385501': __('identifierSchemeCodeUnspsc190501.50385501'),
	'identifierSchemeCodeUnspsc190501.50385502': __('identifierSchemeCodeUnspsc190501.50385502'),
	'identifierSchemeCodeUnspsc190501.50385503': __('identifierSchemeCodeUnspsc190501.50385503'),
	'identifierSchemeCodeUnspsc190501.50385504': __('identifierSchemeCodeUnspsc190501.50385504'),
	'identifierSchemeCodeUnspsc190501.50385505': __('identifierSchemeCodeUnspsc190501.50385505'),
	'identifierSchemeCodeUnspsc190501.50385506': __('identifierSchemeCodeUnspsc190501.50385506'),
	'identifierSchemeCodeUnspsc190501.50385507': __('identifierSchemeCodeUnspsc190501.50385507'),
	'identifierSchemeCodeUnspsc190501.50385508': __('identifierSchemeCodeUnspsc190501.50385508'),
	'identifierSchemeCodeUnspsc190501.50385509': __('identifierSchemeCodeUnspsc190501.50385509'),
	'identifierSchemeCodeUnspsc190501.50385510': __('identifierSchemeCodeUnspsc190501.50385510'),
	'identifierSchemeCodeUnspsc190501.50385511': __('identifierSchemeCodeUnspsc190501.50385511'),
	'identifierSchemeCodeUnspsc190501.50385512': __('identifierSchemeCodeUnspsc190501.50385512'),
	'identifierSchemeCodeUnspsc190501.50385513': __('identifierSchemeCodeUnspsc190501.50385513'),
	'identifierSchemeCodeUnspsc190501.50385514': __('identifierSchemeCodeUnspsc190501.50385514'),
	'identifierSchemeCodeUnspsc190501.50385515': __('identifierSchemeCodeUnspsc190501.50385515'),
	'identifierSchemeCodeUnspsc190501.50385516': __('identifierSchemeCodeUnspsc190501.50385516'),
	'identifierSchemeCodeUnspsc190501.50385517': __('identifierSchemeCodeUnspsc190501.50385517'),
	'identifierSchemeCodeUnspsc190501.50385518': __('identifierSchemeCodeUnspsc190501.50385518'),
	'identifierSchemeCodeUnspsc190501.50385519': __('identifierSchemeCodeUnspsc190501.50385519'),
	'identifierSchemeCodeUnspsc190501.50385520': __('identifierSchemeCodeUnspsc190501.50385520'),
	'identifierSchemeCodeUnspsc190501.50385521': __('identifierSchemeCodeUnspsc190501.50385521'),
	'identifierSchemeCodeUnspsc190501.50385522': __('identifierSchemeCodeUnspsc190501.50385522'),
	'identifierSchemeCodeUnspsc190501.50385523': __('identifierSchemeCodeUnspsc190501.50385523'),
	'identifierSchemeCodeUnspsc190501.50385524': __('identifierSchemeCodeUnspsc190501.50385524'),
	'identifierSchemeCodeUnspsc190501.50385525': __('identifierSchemeCodeUnspsc190501.50385525'),
	'identifierSchemeCodeUnspsc190501.50385526': __('identifierSchemeCodeUnspsc190501.50385526'),
	'identifierSchemeCodeUnspsc190501.50385527': __('identifierSchemeCodeUnspsc190501.50385527'),
	'identifierSchemeCodeUnspsc190501.50385528': __('identifierSchemeCodeUnspsc190501.50385528'),
	'identifierSchemeCodeUnspsc190501.50385529': __('identifierSchemeCodeUnspsc190501.50385529'),
	'identifierSchemeCodeUnspsc190501.50385530': __('identifierSchemeCodeUnspsc190501.50385530'),
	'identifierSchemeCodeUnspsc190501.50385531': __('identifierSchemeCodeUnspsc190501.50385531'),
	'identifierSchemeCodeUnspsc190501.50385532': __('identifierSchemeCodeUnspsc190501.50385532'),
	'identifierSchemeCodeUnspsc190501.50385533': __('identifierSchemeCodeUnspsc190501.50385533'),
	'identifierSchemeCodeUnspsc190501.50385534': __('identifierSchemeCodeUnspsc190501.50385534'),
	'identifierSchemeCodeUnspsc190501.50385535': __('identifierSchemeCodeUnspsc190501.50385535'),
	'identifierSchemeCodeUnspsc190501.50385536': __('identifierSchemeCodeUnspsc190501.50385536'),
	'identifierSchemeCodeUnspsc190501.50385537': __('identifierSchemeCodeUnspsc190501.50385537'),
	'identifierSchemeCodeUnspsc190501.50385538': __('identifierSchemeCodeUnspsc190501.50385538'),
	'identifierSchemeCodeUnspsc190501.50385539': __('identifierSchemeCodeUnspsc190501.50385539'),
	'identifierSchemeCodeUnspsc190501.50385540': __('identifierSchemeCodeUnspsc190501.50385540'),
	'identifierSchemeCodeUnspsc190501.50385541': __('identifierSchemeCodeUnspsc190501.50385541'),
	'identifierSchemeCodeUnspsc190501.50385542': __('identifierSchemeCodeUnspsc190501.50385542'),
	'identifierSchemeCodeUnspsc190501.50385543': __('identifierSchemeCodeUnspsc190501.50385543'),
	'identifierSchemeCodeUnspsc190501.50385544': __('identifierSchemeCodeUnspsc190501.50385544'),
	'identifierSchemeCodeUnspsc190501.50385600': __('identifierSchemeCodeUnspsc190501.50385600'),
	'identifierSchemeCodeUnspsc190501.50385601': __('identifierSchemeCodeUnspsc190501.50385601'),
	'identifierSchemeCodeUnspsc190501.50385602': __('identifierSchemeCodeUnspsc190501.50385602'),
	'identifierSchemeCodeUnspsc190501.50385603': __('identifierSchemeCodeUnspsc190501.50385603'),
	'identifierSchemeCodeUnspsc190501.50385604': __('identifierSchemeCodeUnspsc190501.50385604'),
	'identifierSchemeCodeUnspsc190501.50385605': __('identifierSchemeCodeUnspsc190501.50385605'),
	'identifierSchemeCodeUnspsc190501.50385606': __('identifierSchemeCodeUnspsc190501.50385606'),
	'identifierSchemeCodeUnspsc190501.50385700': __('identifierSchemeCodeUnspsc190501.50385700'),
	'identifierSchemeCodeUnspsc190501.50385701': __('identifierSchemeCodeUnspsc190501.50385701'),
	'identifierSchemeCodeUnspsc190501.50385702': __('identifierSchemeCodeUnspsc190501.50385702'),
	'identifierSchemeCodeUnspsc190501.50385703': __('identifierSchemeCodeUnspsc190501.50385703'),
	'identifierSchemeCodeUnspsc190501.50385704': __('identifierSchemeCodeUnspsc190501.50385704'),
	'identifierSchemeCodeUnspsc190501.50385705': __('identifierSchemeCodeUnspsc190501.50385705'),
	'identifierSchemeCodeUnspsc190501.50385706': __('identifierSchemeCodeUnspsc190501.50385706'),
	'identifierSchemeCodeUnspsc190501.50385707': __('identifierSchemeCodeUnspsc190501.50385707'),
	'identifierSchemeCodeUnspsc190501.50385708': __('identifierSchemeCodeUnspsc190501.50385708'),
	'identifierSchemeCodeUnspsc190501.50385709': __('identifierSchemeCodeUnspsc190501.50385709'),
	'identifierSchemeCodeUnspsc190501.50385710': __('identifierSchemeCodeUnspsc190501.50385710'),
	'identifierSchemeCodeUnspsc190501.50385800': __('identifierSchemeCodeUnspsc190501.50385800'),
	'identifierSchemeCodeUnspsc190501.50385801': __('identifierSchemeCodeUnspsc190501.50385801'),
	'identifierSchemeCodeUnspsc190501.50385802': __('identifierSchemeCodeUnspsc190501.50385802'),
	'identifierSchemeCodeUnspsc190501.50385803': __('identifierSchemeCodeUnspsc190501.50385803'),
	'identifierSchemeCodeUnspsc190501.50385804': __('identifierSchemeCodeUnspsc190501.50385804'),
	'identifierSchemeCodeUnspsc190501.50385805': __('identifierSchemeCodeUnspsc190501.50385805'),
	'identifierSchemeCodeUnspsc190501.50385806': __('identifierSchemeCodeUnspsc190501.50385806'),
	'identifierSchemeCodeUnspsc190501.50385807': __('identifierSchemeCodeUnspsc190501.50385807'),
	'identifierSchemeCodeUnspsc190501.50385808': __('identifierSchemeCodeUnspsc190501.50385808'),
	'identifierSchemeCodeUnspsc190501.50385809': __('identifierSchemeCodeUnspsc190501.50385809'),
	'identifierSchemeCodeUnspsc190501.50385810': __('identifierSchemeCodeUnspsc190501.50385810'),
	'identifierSchemeCodeUnspsc190501.50385811': __('identifierSchemeCodeUnspsc190501.50385811'),
	'identifierSchemeCodeUnspsc190501.50385812': __('identifierSchemeCodeUnspsc190501.50385812'),
	'identifierSchemeCodeUnspsc190501.50385813': __('identifierSchemeCodeUnspsc190501.50385813'),
	'identifierSchemeCodeUnspsc190501.50385814': __('identifierSchemeCodeUnspsc190501.50385814'),
	'identifierSchemeCodeUnspsc190501.50385815': __('identifierSchemeCodeUnspsc190501.50385815'),
	'identifierSchemeCodeUnspsc190501.50385816': __('identifierSchemeCodeUnspsc190501.50385816'),
	'identifierSchemeCodeUnspsc190501.50385817': __('identifierSchemeCodeUnspsc190501.50385817'),
	'identifierSchemeCodeUnspsc190501.50385818': __('identifierSchemeCodeUnspsc190501.50385818'),
	'identifierSchemeCodeUnspsc190501.50385819': __('identifierSchemeCodeUnspsc190501.50385819'),
	'identifierSchemeCodeUnspsc190501.50385820': __('identifierSchemeCodeUnspsc190501.50385820'),
	'identifierSchemeCodeUnspsc190501.50385821': __('identifierSchemeCodeUnspsc190501.50385821'),
	'identifierSchemeCodeUnspsc190501.50385822': __('identifierSchemeCodeUnspsc190501.50385822'),
	'identifierSchemeCodeUnspsc190501.50385823': __('identifierSchemeCodeUnspsc190501.50385823'),
	'identifierSchemeCodeUnspsc190501.50385824': __('identifierSchemeCodeUnspsc190501.50385824'),
	'identifierSchemeCodeUnspsc190501.50385825': __('identifierSchemeCodeUnspsc190501.50385825'),
	'identifierSchemeCodeUnspsc190501.50385826': __('identifierSchemeCodeUnspsc190501.50385826'),
	'identifierSchemeCodeUnspsc190501.50385827': __('identifierSchemeCodeUnspsc190501.50385827'),
	'identifierSchemeCodeUnspsc190501.50385828': __('identifierSchemeCodeUnspsc190501.50385828'),
	'identifierSchemeCodeUnspsc190501.50385829': __('identifierSchemeCodeUnspsc190501.50385829'),
	'identifierSchemeCodeUnspsc190501.50385830': __('identifierSchemeCodeUnspsc190501.50385830'),
	'identifierSchemeCodeUnspsc190501.50385831': __('identifierSchemeCodeUnspsc190501.50385831'),
	'identifierSchemeCodeUnspsc190501.50385832': __('identifierSchemeCodeUnspsc190501.50385832'),
	'identifierSchemeCodeUnspsc190501.50385833': __('identifierSchemeCodeUnspsc190501.50385833'),
	'identifierSchemeCodeUnspsc190501.50385834': __('identifierSchemeCodeUnspsc190501.50385834'),
	'identifierSchemeCodeUnspsc190501.50385835': __('identifierSchemeCodeUnspsc190501.50385835'),
	'identifierSchemeCodeUnspsc190501.50385836': __('identifierSchemeCodeUnspsc190501.50385836'),
	'identifierSchemeCodeUnspsc190501.50385837': __('identifierSchemeCodeUnspsc190501.50385837'),
	'identifierSchemeCodeUnspsc190501.50385838': __('identifierSchemeCodeUnspsc190501.50385838'),
	'identifierSchemeCodeUnspsc190501.50385839': __('identifierSchemeCodeUnspsc190501.50385839'),
	'identifierSchemeCodeUnspsc190501.50385840': __('identifierSchemeCodeUnspsc190501.50385840'),
	'identifierSchemeCodeUnspsc190501.50385841': __('identifierSchemeCodeUnspsc190501.50385841'),
	'identifierSchemeCodeUnspsc190501.50385842': __('identifierSchemeCodeUnspsc190501.50385842'),
	'identifierSchemeCodeUnspsc190501.50385843': __('identifierSchemeCodeUnspsc190501.50385843'),
	'identifierSchemeCodeUnspsc190501.50385844': __('identifierSchemeCodeUnspsc190501.50385844'),
	'identifierSchemeCodeUnspsc190501.50385845': __('identifierSchemeCodeUnspsc190501.50385845'),
	'identifierSchemeCodeUnspsc190501.50385846': __('identifierSchemeCodeUnspsc190501.50385846'),
	'identifierSchemeCodeUnspsc190501.50385847': __('identifierSchemeCodeUnspsc190501.50385847'),
	'identifierSchemeCodeUnspsc190501.50385848': __('identifierSchemeCodeUnspsc190501.50385848'),
	'identifierSchemeCodeUnspsc190501.50385849': __('identifierSchemeCodeUnspsc190501.50385849'),
	'identifierSchemeCodeUnspsc190501.50385850': __('identifierSchemeCodeUnspsc190501.50385850'),
	'identifierSchemeCodeUnspsc190501.50385851': __('identifierSchemeCodeUnspsc190501.50385851'),
	'identifierSchemeCodeUnspsc190501.50385852': __('identifierSchemeCodeUnspsc190501.50385852'),
	'identifierSchemeCodeUnspsc190501.50385853': __('identifierSchemeCodeUnspsc190501.50385853'),
	'identifierSchemeCodeUnspsc190501.50385854': __('identifierSchemeCodeUnspsc190501.50385854'),
	'identifierSchemeCodeUnspsc190501.50385855': __('identifierSchemeCodeUnspsc190501.50385855'),
	'identifierSchemeCodeUnspsc190501.50385856': __('identifierSchemeCodeUnspsc190501.50385856'),
	'identifierSchemeCodeUnspsc190501.50385857': __('identifierSchemeCodeUnspsc190501.50385857'),
	'identifierSchemeCodeUnspsc190501.50385858': __('identifierSchemeCodeUnspsc190501.50385858'),
	'identifierSchemeCodeUnspsc190501.50385859': __('identifierSchemeCodeUnspsc190501.50385859'),
	'identifierSchemeCodeUnspsc190501.50385860': __('identifierSchemeCodeUnspsc190501.50385860'),
	'identifierSchemeCodeUnspsc190501.50385861': __('identifierSchemeCodeUnspsc190501.50385861'),
	'identifierSchemeCodeUnspsc190501.50385862': __('identifierSchemeCodeUnspsc190501.50385862'),
	'identifierSchemeCodeUnspsc190501.50385863': __('identifierSchemeCodeUnspsc190501.50385863'),
	'identifierSchemeCodeUnspsc190501.50385864': __('identifierSchemeCodeUnspsc190501.50385864'),
	'identifierSchemeCodeUnspsc190501.50385865': __('identifierSchemeCodeUnspsc190501.50385865'),
	'identifierSchemeCodeUnspsc190501.50385866': __('identifierSchemeCodeUnspsc190501.50385866'),
	'identifierSchemeCodeUnspsc190501.50385867': __('identifierSchemeCodeUnspsc190501.50385867'),
	'identifierSchemeCodeUnspsc190501.50385868': __('identifierSchemeCodeUnspsc190501.50385868'),
	'identifierSchemeCodeUnspsc190501.50385869': __('identifierSchemeCodeUnspsc190501.50385869'),
	'identifierSchemeCodeUnspsc190501.50385870': __('identifierSchemeCodeUnspsc190501.50385870'),
	'identifierSchemeCodeUnspsc190501.50385871': __('identifierSchemeCodeUnspsc190501.50385871'),
	'identifierSchemeCodeUnspsc190501.50385900': __('identifierSchemeCodeUnspsc190501.50385900'),
	'identifierSchemeCodeUnspsc190501.50385901': __('identifierSchemeCodeUnspsc190501.50385901'),
	'identifierSchemeCodeUnspsc190501.50385902': __('identifierSchemeCodeUnspsc190501.50385902'),
	'identifierSchemeCodeUnspsc190501.50385903': __('identifierSchemeCodeUnspsc190501.50385903'),
	'identifierSchemeCodeUnspsc190501.50385904': __('identifierSchemeCodeUnspsc190501.50385904'),
	'identifierSchemeCodeUnspsc190501.50385905': __('identifierSchemeCodeUnspsc190501.50385905'),
	'identifierSchemeCodeUnspsc190501.50385906': __('identifierSchemeCodeUnspsc190501.50385906'),
	'identifierSchemeCodeUnspsc190501.50385907': __('identifierSchemeCodeUnspsc190501.50385907'),
	'identifierSchemeCodeUnspsc190501.50385908': __('identifierSchemeCodeUnspsc190501.50385908'),
	'identifierSchemeCodeUnspsc190501.50385909': __('identifierSchemeCodeUnspsc190501.50385909'),
	'identifierSchemeCodeUnspsc190501.50385910': __('identifierSchemeCodeUnspsc190501.50385910'),
	'identifierSchemeCodeUnspsc190501.50385911': __('identifierSchemeCodeUnspsc190501.50385911'),
	'identifierSchemeCodeUnspsc190501.50385912': __('identifierSchemeCodeUnspsc190501.50385912'),
	'identifierSchemeCodeUnspsc190501.50385913': __('identifierSchemeCodeUnspsc190501.50385913'),
	'identifierSchemeCodeUnspsc190501.50385914': __('identifierSchemeCodeUnspsc190501.50385914'),
	'identifierSchemeCodeUnspsc190501.50385915': __('identifierSchemeCodeUnspsc190501.50385915'),
	'identifierSchemeCodeUnspsc190501.50385916': __('identifierSchemeCodeUnspsc190501.50385916'),
	'identifierSchemeCodeUnspsc190501.50385917': __('identifierSchemeCodeUnspsc190501.50385917'),
	'identifierSchemeCodeUnspsc190501.50385918': __('identifierSchemeCodeUnspsc190501.50385918'),
	'identifierSchemeCodeUnspsc190501.50385919': __('identifierSchemeCodeUnspsc190501.50385919'),
	'identifierSchemeCodeUnspsc190501.50385920': __('identifierSchemeCodeUnspsc190501.50385920'),
	'identifierSchemeCodeUnspsc190501.50385921': __('identifierSchemeCodeUnspsc190501.50385921'),
	'identifierSchemeCodeUnspsc190501.50385922': __('identifierSchemeCodeUnspsc190501.50385922'),
	'identifierSchemeCodeUnspsc190501.50385923': __('identifierSchemeCodeUnspsc190501.50385923'),
	'identifierSchemeCodeUnspsc190501.50385924': __('identifierSchemeCodeUnspsc190501.50385924'),
	'identifierSchemeCodeUnspsc190501.50385925': __('identifierSchemeCodeUnspsc190501.50385925'),
	'identifierSchemeCodeUnspsc190501.50385926': __('identifierSchemeCodeUnspsc190501.50385926'),
	'identifierSchemeCodeUnspsc190501.50385927': __('identifierSchemeCodeUnspsc190501.50385927'),
	'identifierSchemeCodeUnspsc190501.50385928': __('identifierSchemeCodeUnspsc190501.50385928'),
	'identifierSchemeCodeUnspsc190501.50385929': __('identifierSchemeCodeUnspsc190501.50385929'),
	'identifierSchemeCodeUnspsc190501.50385930': __('identifierSchemeCodeUnspsc190501.50385930'),
	'identifierSchemeCodeUnspsc190501.50385931': __('identifierSchemeCodeUnspsc190501.50385931'),
	'identifierSchemeCodeUnspsc190501.50385932': __('identifierSchemeCodeUnspsc190501.50385932'),
	'identifierSchemeCodeUnspsc190501.50385933': __('identifierSchemeCodeUnspsc190501.50385933'),
	'identifierSchemeCodeUnspsc190501.50385934': __('identifierSchemeCodeUnspsc190501.50385934'),
	'identifierSchemeCodeUnspsc190501.50385935': __('identifierSchemeCodeUnspsc190501.50385935'),
	'identifierSchemeCodeUnspsc190501.50385936': __('identifierSchemeCodeUnspsc190501.50385936'),
	'identifierSchemeCodeUnspsc190501.50385937': __('identifierSchemeCodeUnspsc190501.50385937'),
	'identifierSchemeCodeUnspsc190501.50385938': __('identifierSchemeCodeUnspsc190501.50385938'),
	'identifierSchemeCodeUnspsc190501.50385939': __('identifierSchemeCodeUnspsc190501.50385939'),
	'identifierSchemeCodeUnspsc190501.50385940': __('identifierSchemeCodeUnspsc190501.50385940'),
	'identifierSchemeCodeUnspsc190501.50385941': __('identifierSchemeCodeUnspsc190501.50385941'),
	'identifierSchemeCodeUnspsc190501.50385942': __('identifierSchemeCodeUnspsc190501.50385942'),
	'identifierSchemeCodeUnspsc190501.50385943': __('identifierSchemeCodeUnspsc190501.50385943'),
	'identifierSchemeCodeUnspsc190501.50385944': __('identifierSchemeCodeUnspsc190501.50385944'),
	'identifierSchemeCodeUnspsc190501.50385945': __('identifierSchemeCodeUnspsc190501.50385945'),
	'identifierSchemeCodeUnspsc190501.50385946': __('identifierSchemeCodeUnspsc190501.50385946'),
	'identifierSchemeCodeUnspsc190501.50385947': __('identifierSchemeCodeUnspsc190501.50385947'),
	'identifierSchemeCodeUnspsc190501.50385948': __('identifierSchemeCodeUnspsc190501.50385948'),
	'identifierSchemeCodeUnspsc190501.50385949': __('identifierSchemeCodeUnspsc190501.50385949'),
	'identifierSchemeCodeUnspsc190501.50385950': __('identifierSchemeCodeUnspsc190501.50385950'),
	'identifierSchemeCodeUnspsc190501.50385951': __('identifierSchemeCodeUnspsc190501.50385951'),
	'identifierSchemeCodeUnspsc190501.50385952': __('identifierSchemeCodeUnspsc190501.50385952'),
	'identifierSchemeCodeUnspsc190501.50385953': __('identifierSchemeCodeUnspsc190501.50385953'),
	'identifierSchemeCodeUnspsc190501.50385954': __('identifierSchemeCodeUnspsc190501.50385954'),
	'identifierSchemeCodeUnspsc190501.50385955': __('identifierSchemeCodeUnspsc190501.50385955'),
	'identifierSchemeCodeUnspsc190501.50385956': __('identifierSchemeCodeUnspsc190501.50385956'),
	'identifierSchemeCodeUnspsc190501.50385957': __('identifierSchemeCodeUnspsc190501.50385957'),
	'identifierSchemeCodeUnspsc190501.50385958': __('identifierSchemeCodeUnspsc190501.50385958'),
	'identifierSchemeCodeUnspsc190501.50385959': __('identifierSchemeCodeUnspsc190501.50385959'),
	'identifierSchemeCodeUnspsc190501.50385960': __('identifierSchemeCodeUnspsc190501.50385960'),
	'identifierSchemeCodeUnspsc190501.50386000': __('identifierSchemeCodeUnspsc190501.50386000'),
	'identifierSchemeCodeUnspsc190501.50386001': __('identifierSchemeCodeUnspsc190501.50386001'),
	'identifierSchemeCodeUnspsc190501.50386002': __('identifierSchemeCodeUnspsc190501.50386002'),
	'identifierSchemeCodeUnspsc190501.50386003': __('identifierSchemeCodeUnspsc190501.50386003'),
	'identifierSchemeCodeUnspsc190501.50386004': __('identifierSchemeCodeUnspsc190501.50386004'),
	'identifierSchemeCodeUnspsc190501.50386005': __('identifierSchemeCodeUnspsc190501.50386005'),
	'identifierSchemeCodeUnspsc190501.50386006': __('identifierSchemeCodeUnspsc190501.50386006'),
	'identifierSchemeCodeUnspsc190501.50386007': __('identifierSchemeCodeUnspsc190501.50386007'),
	'identifierSchemeCodeUnspsc190501.50386008': __('identifierSchemeCodeUnspsc190501.50386008'),
	'identifierSchemeCodeUnspsc190501.50386009': __('identifierSchemeCodeUnspsc190501.50386009'),
	'identifierSchemeCodeUnspsc190501.50386010': __('identifierSchemeCodeUnspsc190501.50386010'),
	'identifierSchemeCodeUnspsc190501.50386011': __('identifierSchemeCodeUnspsc190501.50386011'),
	'identifierSchemeCodeUnspsc190501.50386012': __('identifierSchemeCodeUnspsc190501.50386012'),
	'identifierSchemeCodeUnspsc190501.50386100': __('identifierSchemeCodeUnspsc190501.50386100'),
	'identifierSchemeCodeUnspsc190501.50386101': __('identifierSchemeCodeUnspsc190501.50386101'),
	'identifierSchemeCodeUnspsc190501.50386102': __('identifierSchemeCodeUnspsc190501.50386102'),
	'identifierSchemeCodeUnspsc190501.50386103': __('identifierSchemeCodeUnspsc190501.50386103'),
	'identifierSchemeCodeUnspsc190501.50386104': __('identifierSchemeCodeUnspsc190501.50386104'),
	'identifierSchemeCodeUnspsc190501.50386105': __('identifierSchemeCodeUnspsc190501.50386105'),
	'identifierSchemeCodeUnspsc190501.50386106': __('identifierSchemeCodeUnspsc190501.50386106'),
	'identifierSchemeCodeUnspsc190501.50386107': __('identifierSchemeCodeUnspsc190501.50386107'),
	'identifierSchemeCodeUnspsc190501.50386108': __('identifierSchemeCodeUnspsc190501.50386108'),
	'identifierSchemeCodeUnspsc190501.50386109': __('identifierSchemeCodeUnspsc190501.50386109'),
	'identifierSchemeCodeUnspsc190501.50386110': __('identifierSchemeCodeUnspsc190501.50386110'),
	'identifierSchemeCodeUnspsc190501.50386200': __('identifierSchemeCodeUnspsc190501.50386200'),
	'identifierSchemeCodeUnspsc190501.50386201': __('identifierSchemeCodeUnspsc190501.50386201'),
	'identifierSchemeCodeUnspsc190501.50386202': __('identifierSchemeCodeUnspsc190501.50386202'),
	'identifierSchemeCodeUnspsc190501.50386203': __('identifierSchemeCodeUnspsc190501.50386203'),
	'identifierSchemeCodeUnspsc190501.50386204': __('identifierSchemeCodeUnspsc190501.50386204'),
	'identifierSchemeCodeUnspsc190501.50386205': __('identifierSchemeCodeUnspsc190501.50386205'),
	'identifierSchemeCodeUnspsc190501.50386206': __('identifierSchemeCodeUnspsc190501.50386206'),
	'identifierSchemeCodeUnspsc190501.50386207': __('identifierSchemeCodeUnspsc190501.50386207'),
	'identifierSchemeCodeUnspsc190501.50386208': __('identifierSchemeCodeUnspsc190501.50386208'),
	'identifierSchemeCodeUnspsc190501.50386209': __('identifierSchemeCodeUnspsc190501.50386209'),
	'identifierSchemeCodeUnspsc190501.50386210': __('identifierSchemeCodeUnspsc190501.50386210'),
	'identifierSchemeCodeUnspsc190501.50386211': __('identifierSchemeCodeUnspsc190501.50386211'),
	'identifierSchemeCodeUnspsc190501.50386212': __('identifierSchemeCodeUnspsc190501.50386212'),
	'identifierSchemeCodeUnspsc190501.50386213': __('identifierSchemeCodeUnspsc190501.50386213'),
	'identifierSchemeCodeUnspsc190501.50386214': __('identifierSchemeCodeUnspsc190501.50386214'),
	'identifierSchemeCodeUnspsc190501.50386215': __('identifierSchemeCodeUnspsc190501.50386215'),
	'identifierSchemeCodeUnspsc190501.50386216': __('identifierSchemeCodeUnspsc190501.50386216'),
	'identifierSchemeCodeUnspsc190501.50386217': __('identifierSchemeCodeUnspsc190501.50386217'),
	'identifierSchemeCodeUnspsc190501.50386218': __('identifierSchemeCodeUnspsc190501.50386218'),
	'identifierSchemeCodeUnspsc190501.50386219': __('identifierSchemeCodeUnspsc190501.50386219'),
	'identifierSchemeCodeUnspsc190501.50386220': __('identifierSchemeCodeUnspsc190501.50386220'),
	'identifierSchemeCodeUnspsc190501.50386221': __('identifierSchemeCodeUnspsc190501.50386221'),
	'identifierSchemeCodeUnspsc190501.50386222': __('identifierSchemeCodeUnspsc190501.50386222'),
	'identifierSchemeCodeUnspsc190501.50386223': __('identifierSchemeCodeUnspsc190501.50386223'),
	'identifierSchemeCodeUnspsc190501.50386300': __('identifierSchemeCodeUnspsc190501.50386300'),
	'identifierSchemeCodeUnspsc190501.50386301': __('identifierSchemeCodeUnspsc190501.50386301'),
	'identifierSchemeCodeUnspsc190501.50386302': __('identifierSchemeCodeUnspsc190501.50386302'),
	'identifierSchemeCodeUnspsc190501.50386303': __('identifierSchemeCodeUnspsc190501.50386303'),
	'identifierSchemeCodeUnspsc190501.50386304': __('identifierSchemeCodeUnspsc190501.50386304'),
	'identifierSchemeCodeUnspsc190501.50386305': __('identifierSchemeCodeUnspsc190501.50386305'),
	'identifierSchemeCodeUnspsc190501.50386306': __('identifierSchemeCodeUnspsc190501.50386306'),
	'identifierSchemeCodeUnspsc190501.50386307': __('identifierSchemeCodeUnspsc190501.50386307'),
	'identifierSchemeCodeUnspsc190501.50386308': __('identifierSchemeCodeUnspsc190501.50386308'),
	'identifierSchemeCodeUnspsc190501.50386309': __('identifierSchemeCodeUnspsc190501.50386309'),
	'identifierSchemeCodeUnspsc190501.50386310': __('identifierSchemeCodeUnspsc190501.50386310'),
	'identifierSchemeCodeUnspsc190501.50386311': __('identifierSchemeCodeUnspsc190501.50386311'),
	'identifierSchemeCodeUnspsc190501.50386312': __('identifierSchemeCodeUnspsc190501.50386312'),
	'identifierSchemeCodeUnspsc190501.50386313': __('identifierSchemeCodeUnspsc190501.50386313'),
	'identifierSchemeCodeUnspsc190501.50386314': __('identifierSchemeCodeUnspsc190501.50386314'),
	'identifierSchemeCodeUnspsc190501.50386315': __('identifierSchemeCodeUnspsc190501.50386315'),
	'identifierSchemeCodeUnspsc190501.50386316': __('identifierSchemeCodeUnspsc190501.50386316'),
	'identifierSchemeCodeUnspsc190501.50386317': __('identifierSchemeCodeUnspsc190501.50386317'),
	'identifierSchemeCodeUnspsc190501.50386318': __('identifierSchemeCodeUnspsc190501.50386318'),
	'identifierSchemeCodeUnspsc190501.50386319': __('identifierSchemeCodeUnspsc190501.50386319'),
	'identifierSchemeCodeUnspsc190501.50386320': __('identifierSchemeCodeUnspsc190501.50386320'),
	'identifierSchemeCodeUnspsc190501.50386321': __('identifierSchemeCodeUnspsc190501.50386321'),
	'identifierSchemeCodeUnspsc190501.50386322': __('identifierSchemeCodeUnspsc190501.50386322'),
	'identifierSchemeCodeUnspsc190501.50386323': __('identifierSchemeCodeUnspsc190501.50386323'),
	'identifierSchemeCodeUnspsc190501.50386324': __('identifierSchemeCodeUnspsc190501.50386324'),
	'identifierSchemeCodeUnspsc190501.50386325': __('identifierSchemeCodeUnspsc190501.50386325'),
	'identifierSchemeCodeUnspsc190501.50386326': __('identifierSchemeCodeUnspsc190501.50386326'),
	'identifierSchemeCodeUnspsc190501.50386327': __('identifierSchemeCodeUnspsc190501.50386327'),
	'identifierSchemeCodeUnspsc190501.50386328': __('identifierSchemeCodeUnspsc190501.50386328'),
	'identifierSchemeCodeUnspsc190501.50386329': __('identifierSchemeCodeUnspsc190501.50386329'),
	'identifierSchemeCodeUnspsc190501.50386330': __('identifierSchemeCodeUnspsc190501.50386330'),
	'identifierSchemeCodeUnspsc190501.50386331': __('identifierSchemeCodeUnspsc190501.50386331'),
	'identifierSchemeCodeUnspsc190501.50386332': __('identifierSchemeCodeUnspsc190501.50386332'),
	'identifierSchemeCodeUnspsc190501.50386333': __('identifierSchemeCodeUnspsc190501.50386333'),
	'identifierSchemeCodeUnspsc190501.50386334': __('identifierSchemeCodeUnspsc190501.50386334'),
	'identifierSchemeCodeUnspsc190501.50386335': __('identifierSchemeCodeUnspsc190501.50386335'),
	'identifierSchemeCodeUnspsc190501.50386336': __('identifierSchemeCodeUnspsc190501.50386336'),
	'identifierSchemeCodeUnspsc190501.50386337': __('identifierSchemeCodeUnspsc190501.50386337'),
	'identifierSchemeCodeUnspsc190501.50386338': __('identifierSchemeCodeUnspsc190501.50386338'),
	'identifierSchemeCodeUnspsc190501.50386339': __('identifierSchemeCodeUnspsc190501.50386339'),
	'identifierSchemeCodeUnspsc190501.50386340': __('identifierSchemeCodeUnspsc190501.50386340'),
	'identifierSchemeCodeUnspsc190501.50386341': __('identifierSchemeCodeUnspsc190501.50386341'),
	'identifierSchemeCodeUnspsc190501.50386342': __('identifierSchemeCodeUnspsc190501.50386342'),
	'identifierSchemeCodeUnspsc190501.50386343': __('identifierSchemeCodeUnspsc190501.50386343'),
	'identifierSchemeCodeUnspsc190501.50386344': __('identifierSchemeCodeUnspsc190501.50386344'),
	'identifierSchemeCodeUnspsc190501.50386345': __('identifierSchemeCodeUnspsc190501.50386345'),
	'identifierSchemeCodeUnspsc190501.50386346': __('identifierSchemeCodeUnspsc190501.50386346'),
	'identifierSchemeCodeUnspsc190501.50386347': __('identifierSchemeCodeUnspsc190501.50386347'),
	'identifierSchemeCodeUnspsc190501.50386348': __('identifierSchemeCodeUnspsc190501.50386348'),
	'identifierSchemeCodeUnspsc190501.50386349': __('identifierSchemeCodeUnspsc190501.50386349'),
	'identifierSchemeCodeUnspsc190501.50386350': __('identifierSchemeCodeUnspsc190501.50386350'),
	'identifierSchemeCodeUnspsc190501.50386351': __('identifierSchemeCodeUnspsc190501.50386351'),
	'identifierSchemeCodeUnspsc190501.50386352': __('identifierSchemeCodeUnspsc190501.50386352'),
	'identifierSchemeCodeUnspsc190501.50386353': __('identifierSchemeCodeUnspsc190501.50386353'),
	'identifierSchemeCodeUnspsc190501.50386354': __('identifierSchemeCodeUnspsc190501.50386354'),
	'identifierSchemeCodeUnspsc190501.50386355': __('identifierSchemeCodeUnspsc190501.50386355'),
	'identifierSchemeCodeUnspsc190501.50386356': __('identifierSchemeCodeUnspsc190501.50386356'),
	'identifierSchemeCodeUnspsc190501.50386357': __('identifierSchemeCodeUnspsc190501.50386357'),
	'identifierSchemeCodeUnspsc190501.50386358': __('identifierSchemeCodeUnspsc190501.50386358'),
	'identifierSchemeCodeUnspsc190501.50386359': __('identifierSchemeCodeUnspsc190501.50386359'),
	'identifierSchemeCodeUnspsc190501.50386360': __('identifierSchemeCodeUnspsc190501.50386360'),
	'identifierSchemeCodeUnspsc190501.50386361': __('identifierSchemeCodeUnspsc190501.50386361'),
	'identifierSchemeCodeUnspsc190501.50386362': __('identifierSchemeCodeUnspsc190501.50386362'),
	'identifierSchemeCodeUnspsc190501.50386363': __('identifierSchemeCodeUnspsc190501.50386363'),
	'identifierSchemeCodeUnspsc190501.50386364': __('identifierSchemeCodeUnspsc190501.50386364'),
	'identifierSchemeCodeUnspsc190501.50386365': __('identifierSchemeCodeUnspsc190501.50386365'),
	'identifierSchemeCodeUnspsc190501.50386366': __('identifierSchemeCodeUnspsc190501.50386366'),
	'identifierSchemeCodeUnspsc190501.50386367': __('identifierSchemeCodeUnspsc190501.50386367'),
	'identifierSchemeCodeUnspsc190501.50386368': __('identifierSchemeCodeUnspsc190501.50386368'),
	'identifierSchemeCodeUnspsc190501.50386369': __('identifierSchemeCodeUnspsc190501.50386369'),
	'identifierSchemeCodeUnspsc190501.50386370': __('identifierSchemeCodeUnspsc190501.50386370'),
	'identifierSchemeCodeUnspsc190501.50386371': __('identifierSchemeCodeUnspsc190501.50386371'),
	'identifierSchemeCodeUnspsc190501.50386372': __('identifierSchemeCodeUnspsc190501.50386372'),
	'identifierSchemeCodeUnspsc190501.50386373': __('identifierSchemeCodeUnspsc190501.50386373'),
	'identifierSchemeCodeUnspsc190501.50386374': __('identifierSchemeCodeUnspsc190501.50386374'),
	'identifierSchemeCodeUnspsc190501.50386375': __('identifierSchemeCodeUnspsc190501.50386375'),
	'identifierSchemeCodeUnspsc190501.50386376': __('identifierSchemeCodeUnspsc190501.50386376'),
	'identifierSchemeCodeUnspsc190501.50386377': __('identifierSchemeCodeUnspsc190501.50386377'),
	'identifierSchemeCodeUnspsc190501.50386378': __('identifierSchemeCodeUnspsc190501.50386378'),
	'identifierSchemeCodeUnspsc190501.50386400': __('identifierSchemeCodeUnspsc190501.50386400'),
	'identifierSchemeCodeUnspsc190501.50386401': __('identifierSchemeCodeUnspsc190501.50386401'),
	'identifierSchemeCodeUnspsc190501.50386402': __('identifierSchemeCodeUnspsc190501.50386402'),
	'identifierSchemeCodeUnspsc190501.50386403': __('identifierSchemeCodeUnspsc190501.50386403'),
	'identifierSchemeCodeUnspsc190501.50386404': __('identifierSchemeCodeUnspsc190501.50386404'),
	'identifierSchemeCodeUnspsc190501.50386405': __('identifierSchemeCodeUnspsc190501.50386405'),
	'identifierSchemeCodeUnspsc190501.50386406': __('identifierSchemeCodeUnspsc190501.50386406'),
	'identifierSchemeCodeUnspsc190501.50386407': __('identifierSchemeCodeUnspsc190501.50386407'),
	'identifierSchemeCodeUnspsc190501.50386408': __('identifierSchemeCodeUnspsc190501.50386408'),
	'identifierSchemeCodeUnspsc190501.50386500': __('identifierSchemeCodeUnspsc190501.50386500'),
	'identifierSchemeCodeUnspsc190501.50386501': __('identifierSchemeCodeUnspsc190501.50386501'),
	'identifierSchemeCodeUnspsc190501.50386502': __('identifierSchemeCodeUnspsc190501.50386502'),
	'identifierSchemeCodeUnspsc190501.50386503': __('identifierSchemeCodeUnspsc190501.50386503'),
	'identifierSchemeCodeUnspsc190501.50386504': __('identifierSchemeCodeUnspsc190501.50386504'),
	'identifierSchemeCodeUnspsc190501.50386505': __('identifierSchemeCodeUnspsc190501.50386505'),
	'identifierSchemeCodeUnspsc190501.50386506': __('identifierSchemeCodeUnspsc190501.50386506'),
	'identifierSchemeCodeUnspsc190501.50386507': __('identifierSchemeCodeUnspsc190501.50386507'),
	'identifierSchemeCodeUnspsc190501.50386508': __('identifierSchemeCodeUnspsc190501.50386508'),
	'identifierSchemeCodeUnspsc190501.50386600': __('identifierSchemeCodeUnspsc190501.50386600'),
	'identifierSchemeCodeUnspsc190501.50386601': __('identifierSchemeCodeUnspsc190501.50386601'),
	'identifierSchemeCodeUnspsc190501.50386602': __('identifierSchemeCodeUnspsc190501.50386602'),
	'identifierSchemeCodeUnspsc190501.50386603': __('identifierSchemeCodeUnspsc190501.50386603'),
	'identifierSchemeCodeUnspsc190501.50386700': __('identifierSchemeCodeUnspsc190501.50386700'),
	'identifierSchemeCodeUnspsc190501.50386701': __('identifierSchemeCodeUnspsc190501.50386701'),
	'identifierSchemeCodeUnspsc190501.50386702': __('identifierSchemeCodeUnspsc190501.50386702'),
	'identifierSchemeCodeUnspsc190501.50386703': __('identifierSchemeCodeUnspsc190501.50386703'),
	'identifierSchemeCodeUnspsc190501.50386704': __('identifierSchemeCodeUnspsc190501.50386704'),
	'identifierSchemeCodeUnspsc190501.50386705': __('identifierSchemeCodeUnspsc190501.50386705'),
	'identifierSchemeCodeUnspsc190501.50386706': __('identifierSchemeCodeUnspsc190501.50386706'),
	'identifierSchemeCodeUnspsc190501.50386707': __('identifierSchemeCodeUnspsc190501.50386707'),
	'identifierSchemeCodeUnspsc190501.50386708': __('identifierSchemeCodeUnspsc190501.50386708'),
	'identifierSchemeCodeUnspsc190501.50386709': __('identifierSchemeCodeUnspsc190501.50386709'),
	'identifierSchemeCodeUnspsc190501.50386710': __('identifierSchemeCodeUnspsc190501.50386710'),
	'identifierSchemeCodeUnspsc190501.50386711': __('identifierSchemeCodeUnspsc190501.50386711'),
	'identifierSchemeCodeUnspsc190501.50386712': __('identifierSchemeCodeUnspsc190501.50386712'),
	'identifierSchemeCodeUnspsc190501.50386713': __('identifierSchemeCodeUnspsc190501.50386713'),
	'identifierSchemeCodeUnspsc190501.50386714': __('identifierSchemeCodeUnspsc190501.50386714'),
	'identifierSchemeCodeUnspsc190501.50386715': __('identifierSchemeCodeUnspsc190501.50386715'),
	'identifierSchemeCodeUnspsc190501.50386716': __('identifierSchemeCodeUnspsc190501.50386716'),
	'identifierSchemeCodeUnspsc190501.50386717': __('identifierSchemeCodeUnspsc190501.50386717'),
	'identifierSchemeCodeUnspsc190501.50386718': __('identifierSchemeCodeUnspsc190501.50386718'),
	'identifierSchemeCodeUnspsc190501.50386719': __('identifierSchemeCodeUnspsc190501.50386719'),
	'identifierSchemeCodeUnspsc190501.50386720': __('identifierSchemeCodeUnspsc190501.50386720'),
	'identifierSchemeCodeUnspsc190501.50386721': __('identifierSchemeCodeUnspsc190501.50386721'),
	'identifierSchemeCodeUnspsc190501.50386722': __('identifierSchemeCodeUnspsc190501.50386722'),
	'identifierSchemeCodeUnspsc190501.50386723': __('identifierSchemeCodeUnspsc190501.50386723'),
	'identifierSchemeCodeUnspsc190501.50386724': __('identifierSchemeCodeUnspsc190501.50386724'),
	'identifierSchemeCodeUnspsc190501.50386725': __('identifierSchemeCodeUnspsc190501.50386725'),
	'identifierSchemeCodeUnspsc190501.50386726': __('identifierSchemeCodeUnspsc190501.50386726'),
	'identifierSchemeCodeUnspsc190501.50386727': __('identifierSchemeCodeUnspsc190501.50386727'),
	'identifierSchemeCodeUnspsc190501.50386728': __('identifierSchemeCodeUnspsc190501.50386728'),
	'identifierSchemeCodeUnspsc190501.50386729': __('identifierSchemeCodeUnspsc190501.50386729'),
	'identifierSchemeCodeUnspsc190501.50386800': __('identifierSchemeCodeUnspsc190501.50386800'),
	'identifierSchemeCodeUnspsc190501.50386801': __('identifierSchemeCodeUnspsc190501.50386801'),
	'identifierSchemeCodeUnspsc190501.50386802': __('identifierSchemeCodeUnspsc190501.50386802'),
	'identifierSchemeCodeUnspsc190501.50386803': __('identifierSchemeCodeUnspsc190501.50386803'),
	'identifierSchemeCodeUnspsc190501.50386804': __('identifierSchemeCodeUnspsc190501.50386804'),
	'identifierSchemeCodeUnspsc190501.50386805': __('identifierSchemeCodeUnspsc190501.50386805'),
	'identifierSchemeCodeUnspsc190501.50386806': __('identifierSchemeCodeUnspsc190501.50386806'),
	'identifierSchemeCodeUnspsc190501.50386807': __('identifierSchemeCodeUnspsc190501.50386807'),
	'identifierSchemeCodeUnspsc190501.50386808': __('identifierSchemeCodeUnspsc190501.50386808'),
	'identifierSchemeCodeUnspsc190501.50386809': __('identifierSchemeCodeUnspsc190501.50386809'),
	'identifierSchemeCodeUnspsc190501.50386810': __('identifierSchemeCodeUnspsc190501.50386810'),
	'identifierSchemeCodeUnspsc190501.50386900': __('identifierSchemeCodeUnspsc190501.50386900'),
	'identifierSchemeCodeUnspsc190501.50386901': __('identifierSchemeCodeUnspsc190501.50386901'),
	'identifierSchemeCodeUnspsc190501.50386902': __('identifierSchemeCodeUnspsc190501.50386902'),
	'identifierSchemeCodeUnspsc190501.50386903': __('identifierSchemeCodeUnspsc190501.50386903'),
	'identifierSchemeCodeUnspsc190501.50386904': __('identifierSchemeCodeUnspsc190501.50386904'),
	'identifierSchemeCodeUnspsc190501.50387000': __('identifierSchemeCodeUnspsc190501.50387000'),
	'identifierSchemeCodeUnspsc190501.50387001': __('identifierSchemeCodeUnspsc190501.50387001'),
	'identifierSchemeCodeUnspsc190501.50387002': __('identifierSchemeCodeUnspsc190501.50387002'),
	'identifierSchemeCodeUnspsc190501.50387100': __('identifierSchemeCodeUnspsc190501.50387100'),
	'identifierSchemeCodeUnspsc190501.50387101': __('identifierSchemeCodeUnspsc190501.50387101'),
	'identifierSchemeCodeUnspsc190501.50387102': __('identifierSchemeCodeUnspsc190501.50387102'),
	'identifierSchemeCodeUnspsc190501.50387103': __('identifierSchemeCodeUnspsc190501.50387103'),
	'identifierSchemeCodeUnspsc190501.50387104': __('identifierSchemeCodeUnspsc190501.50387104'),
	'identifierSchemeCodeUnspsc190501.50387200': __('identifierSchemeCodeUnspsc190501.50387200'),
	'identifierSchemeCodeUnspsc190501.50387201': __('identifierSchemeCodeUnspsc190501.50387201'),
	'identifierSchemeCodeUnspsc190501.50387202': __('identifierSchemeCodeUnspsc190501.50387202'),
	'identifierSchemeCodeUnspsc190501.50387203': __('identifierSchemeCodeUnspsc190501.50387203'),
	'identifierSchemeCodeUnspsc190501.50387300': __('identifierSchemeCodeUnspsc190501.50387300'),
	'identifierSchemeCodeUnspsc190501.50387301': __('identifierSchemeCodeUnspsc190501.50387301'),
	'identifierSchemeCodeUnspsc190501.50387302': __('identifierSchemeCodeUnspsc190501.50387302'),
	'identifierSchemeCodeUnspsc190501.50387303': __('identifierSchemeCodeUnspsc190501.50387303'),
	'identifierSchemeCodeUnspsc190501.50387400': __('identifierSchemeCodeUnspsc190501.50387400'),
	'identifierSchemeCodeUnspsc190501.50387401': __('identifierSchemeCodeUnspsc190501.50387401'),
	'identifierSchemeCodeUnspsc190501.50387402': __('identifierSchemeCodeUnspsc190501.50387402'),
	'identifierSchemeCodeUnspsc190501.50387403': __('identifierSchemeCodeUnspsc190501.50387403'),
	'identifierSchemeCodeUnspsc190501.50387404': __('identifierSchemeCodeUnspsc190501.50387404'),
	'identifierSchemeCodeUnspsc190501.50387405': __('identifierSchemeCodeUnspsc190501.50387405'),
	'identifierSchemeCodeUnspsc190501.50387406': __('identifierSchemeCodeUnspsc190501.50387406'),
	'identifierSchemeCodeUnspsc190501.50387500': __('identifierSchemeCodeUnspsc190501.50387500'),
	'identifierSchemeCodeUnspsc190501.50387501': __('identifierSchemeCodeUnspsc190501.50387501'),
	'identifierSchemeCodeUnspsc190501.50387502': __('identifierSchemeCodeUnspsc190501.50387502'),
	'identifierSchemeCodeUnspsc190501.50387503': __('identifierSchemeCodeUnspsc190501.50387503'),
	'identifierSchemeCodeUnspsc190501.50387504': __('identifierSchemeCodeUnspsc190501.50387504'),
	'identifierSchemeCodeUnspsc190501.50387505': __('identifierSchemeCodeUnspsc190501.50387505'),
	'identifierSchemeCodeUnspsc190501.50387506': __('identifierSchemeCodeUnspsc190501.50387506'),
	'identifierSchemeCodeUnspsc190501.50387507': __('identifierSchemeCodeUnspsc190501.50387507'),
	'identifierSchemeCodeUnspsc190501.50387508': __('identifierSchemeCodeUnspsc190501.50387508'),
	'identifierSchemeCodeUnspsc190501.50387509': __('identifierSchemeCodeUnspsc190501.50387509'),
	'identifierSchemeCodeUnspsc190501.50387510': __('identifierSchemeCodeUnspsc190501.50387510'),
	'identifierSchemeCodeUnspsc190501.50387511': __('identifierSchemeCodeUnspsc190501.50387511'),
	'identifierSchemeCodeUnspsc190501.50387512': __('identifierSchemeCodeUnspsc190501.50387512'),
	'identifierSchemeCodeUnspsc190501.50387513': __('identifierSchemeCodeUnspsc190501.50387513'),
	'identifierSchemeCodeUnspsc190501.50387514': __('identifierSchemeCodeUnspsc190501.50387514'),
	'identifierSchemeCodeUnspsc190501.50387515': __('identifierSchemeCodeUnspsc190501.50387515'),
	'identifierSchemeCodeUnspsc190501.50387516': __('identifierSchemeCodeUnspsc190501.50387516'),
	'identifierSchemeCodeUnspsc190501.50387517': __('identifierSchemeCodeUnspsc190501.50387517'),
	'identifierSchemeCodeUnspsc190501.50387518': __('identifierSchemeCodeUnspsc190501.50387518'),
	'identifierSchemeCodeUnspsc190501.50387519': __('identifierSchemeCodeUnspsc190501.50387519'),
	'identifierSchemeCodeUnspsc190501.50387520': __('identifierSchemeCodeUnspsc190501.50387520'),
	'identifierSchemeCodeUnspsc190501.50387521': __('identifierSchemeCodeUnspsc190501.50387521'),
	'identifierSchemeCodeUnspsc190501.50387522': __('identifierSchemeCodeUnspsc190501.50387522'),
	'identifierSchemeCodeUnspsc190501.50387523': __('identifierSchemeCodeUnspsc190501.50387523'),
	'identifierSchemeCodeUnspsc190501.50387524': __('identifierSchemeCodeUnspsc190501.50387524'),
	'identifierSchemeCodeUnspsc190501.50387525': __('identifierSchemeCodeUnspsc190501.50387525'),
	'identifierSchemeCodeUnspsc190501.50387526': __('identifierSchemeCodeUnspsc190501.50387526'),
	'identifierSchemeCodeUnspsc190501.50387527': __('identifierSchemeCodeUnspsc190501.50387527'),
	'identifierSchemeCodeUnspsc190501.50387528': __('identifierSchemeCodeUnspsc190501.50387528'),
	'identifierSchemeCodeUnspsc190501.50387529': __('identifierSchemeCodeUnspsc190501.50387529'),
	'identifierSchemeCodeUnspsc190501.50387530': __('identifierSchemeCodeUnspsc190501.50387530'),
	'identifierSchemeCodeUnspsc190501.50387531': __('identifierSchemeCodeUnspsc190501.50387531'),
	'identifierSchemeCodeUnspsc190501.50387532': __('identifierSchemeCodeUnspsc190501.50387532'),
	'identifierSchemeCodeUnspsc190501.50387533': __('identifierSchemeCodeUnspsc190501.50387533'),
	'identifierSchemeCodeUnspsc190501.50387534': __('identifierSchemeCodeUnspsc190501.50387534'),
	'identifierSchemeCodeUnspsc190501.50387535': __('identifierSchemeCodeUnspsc190501.50387535'),
	'identifierSchemeCodeUnspsc190501.50387536': __('identifierSchemeCodeUnspsc190501.50387536'),
	'identifierSchemeCodeUnspsc190501.50387537': __('identifierSchemeCodeUnspsc190501.50387537'),
	'identifierSchemeCodeUnspsc190501.50387538': __('identifierSchemeCodeUnspsc190501.50387538'),
	'identifierSchemeCodeUnspsc190501.50387539': __('identifierSchemeCodeUnspsc190501.50387539'),
	'identifierSchemeCodeUnspsc190501.50387540': __('identifierSchemeCodeUnspsc190501.50387540'),
	'identifierSchemeCodeUnspsc190501.50387541': __('identifierSchemeCodeUnspsc190501.50387541'),
	'identifierSchemeCodeUnspsc190501.50387542': __('identifierSchemeCodeUnspsc190501.50387542'),
	'identifierSchemeCodeUnspsc190501.50387543': __('identifierSchemeCodeUnspsc190501.50387543'),
	'identifierSchemeCodeUnspsc190501.50387544': __('identifierSchemeCodeUnspsc190501.50387544'),
	'identifierSchemeCodeUnspsc190501.50387545': __('identifierSchemeCodeUnspsc190501.50387545'),
	'identifierSchemeCodeUnspsc190501.50387546': __('identifierSchemeCodeUnspsc190501.50387546'),
	'identifierSchemeCodeUnspsc190501.50387547': __('identifierSchemeCodeUnspsc190501.50387547'),
	'identifierSchemeCodeUnspsc190501.50387548': __('identifierSchemeCodeUnspsc190501.50387548'),
	'identifierSchemeCodeUnspsc190501.50387549': __('identifierSchemeCodeUnspsc190501.50387549'),
	'identifierSchemeCodeUnspsc190501.50387550': __('identifierSchemeCodeUnspsc190501.50387550'),
	'identifierSchemeCodeUnspsc190501.50387600': __('identifierSchemeCodeUnspsc190501.50387600'),
	'identifierSchemeCodeUnspsc190501.50387601': __('identifierSchemeCodeUnspsc190501.50387601'),
	'identifierSchemeCodeUnspsc190501.50387602': __('identifierSchemeCodeUnspsc190501.50387602'),
	'identifierSchemeCodeUnspsc190501.50387603': __('identifierSchemeCodeUnspsc190501.50387603'),
	'identifierSchemeCodeUnspsc190501.50387604': __('identifierSchemeCodeUnspsc190501.50387604'),
	'identifierSchemeCodeUnspsc190501.50387700': __('identifierSchemeCodeUnspsc190501.50387700'),
	'identifierSchemeCodeUnspsc190501.50387701': __('identifierSchemeCodeUnspsc190501.50387701'),
	'identifierSchemeCodeUnspsc190501.50387702': __('identifierSchemeCodeUnspsc190501.50387702'),
	'identifierSchemeCodeUnspsc190501.50387703': __('identifierSchemeCodeUnspsc190501.50387703'),
	'identifierSchemeCodeUnspsc190501.50387704': __('identifierSchemeCodeUnspsc190501.50387704'),
	'identifierSchemeCodeUnspsc190501.50387705': __('identifierSchemeCodeUnspsc190501.50387705'),
	'identifierSchemeCodeUnspsc190501.50387706': __('identifierSchemeCodeUnspsc190501.50387706'),
	'identifierSchemeCodeUnspsc190501.50387707': __('identifierSchemeCodeUnspsc190501.50387707'),
	'identifierSchemeCodeUnspsc190501.50387708': __('identifierSchemeCodeUnspsc190501.50387708'),
	'identifierSchemeCodeUnspsc190501.50387709': __('identifierSchemeCodeUnspsc190501.50387709'),
	'identifierSchemeCodeUnspsc190501.50387710': __('identifierSchemeCodeUnspsc190501.50387710'),
	'identifierSchemeCodeUnspsc190501.50387711': __('identifierSchemeCodeUnspsc190501.50387711'),
	'identifierSchemeCodeUnspsc190501.50387712': __('identifierSchemeCodeUnspsc190501.50387712'),
	'identifierSchemeCodeUnspsc190501.50387713': __('identifierSchemeCodeUnspsc190501.50387713'),
	'identifierSchemeCodeUnspsc190501.50387714': __('identifierSchemeCodeUnspsc190501.50387714'),
	'identifierSchemeCodeUnspsc190501.50387715': __('identifierSchemeCodeUnspsc190501.50387715'),
	'identifierSchemeCodeUnspsc190501.50387716': __('identifierSchemeCodeUnspsc190501.50387716'),
	'identifierSchemeCodeUnspsc190501.50387717': __('identifierSchemeCodeUnspsc190501.50387717'),
	'identifierSchemeCodeUnspsc190501.50387718': __('identifierSchemeCodeUnspsc190501.50387718'),
	'identifierSchemeCodeUnspsc190501.50387719': __('identifierSchemeCodeUnspsc190501.50387719'),
	'identifierSchemeCodeUnspsc190501.50387720': __('identifierSchemeCodeUnspsc190501.50387720'),
	'identifierSchemeCodeUnspsc190501.50387721': __('identifierSchemeCodeUnspsc190501.50387721'),
	'identifierSchemeCodeUnspsc190501.50387722': __('identifierSchemeCodeUnspsc190501.50387722'),
	'identifierSchemeCodeUnspsc190501.50387723': __('identifierSchemeCodeUnspsc190501.50387723'),
	'identifierSchemeCodeUnspsc190501.50387724': __('identifierSchemeCodeUnspsc190501.50387724'),
	'identifierSchemeCodeUnspsc190501.50387725': __('identifierSchemeCodeUnspsc190501.50387725'),
	'identifierSchemeCodeUnspsc190501.50387726': __('identifierSchemeCodeUnspsc190501.50387726'),
	'identifierSchemeCodeUnspsc190501.50387727': __('identifierSchemeCodeUnspsc190501.50387727'),
	'identifierSchemeCodeUnspsc190501.50387728': __('identifierSchemeCodeUnspsc190501.50387728'),
	'identifierSchemeCodeUnspsc190501.50387729': __('identifierSchemeCodeUnspsc190501.50387729'),
	'identifierSchemeCodeUnspsc190501.50387730': __('identifierSchemeCodeUnspsc190501.50387730'),
	'identifierSchemeCodeUnspsc190501.50390000': __('identifierSchemeCodeUnspsc190501.50390000'),
	'identifierSchemeCodeUnspsc190501.50391500': __('identifierSchemeCodeUnspsc190501.50391500'),
	'identifierSchemeCodeUnspsc190501.50391501': __('identifierSchemeCodeUnspsc190501.50391501'),
	'identifierSchemeCodeUnspsc190501.50391502': __('identifierSchemeCodeUnspsc190501.50391502'),
	'identifierSchemeCodeUnspsc190501.50391503': __('identifierSchemeCodeUnspsc190501.50391503'),
	'identifierSchemeCodeUnspsc190501.50391504': __('identifierSchemeCodeUnspsc190501.50391504'),
	'identifierSchemeCodeUnspsc190501.50391505': __('identifierSchemeCodeUnspsc190501.50391505'),
	'identifierSchemeCodeUnspsc190501.50391506': __('identifierSchemeCodeUnspsc190501.50391506'),
	'identifierSchemeCodeUnspsc190501.50391507': __('identifierSchemeCodeUnspsc190501.50391507'),
	'identifierSchemeCodeUnspsc190501.50391508': __('identifierSchemeCodeUnspsc190501.50391508'),
	'identifierSchemeCodeUnspsc190501.50391509': __('identifierSchemeCodeUnspsc190501.50391509'),
	'identifierSchemeCodeUnspsc190501.50391510': __('identifierSchemeCodeUnspsc190501.50391510'),
	'identifierSchemeCodeUnspsc190501.50391511': __('identifierSchemeCodeUnspsc190501.50391511'),
	'identifierSchemeCodeUnspsc190501.50391512': __('identifierSchemeCodeUnspsc190501.50391512'),
	'identifierSchemeCodeUnspsc190501.50391513': __('identifierSchemeCodeUnspsc190501.50391513'),
	'identifierSchemeCodeUnspsc190501.50391514': __('identifierSchemeCodeUnspsc190501.50391514'),
	'identifierSchemeCodeUnspsc190501.50391515': __('identifierSchemeCodeUnspsc190501.50391515'),
	'identifierSchemeCodeUnspsc190501.50391516': __('identifierSchemeCodeUnspsc190501.50391516'),
	'identifierSchemeCodeUnspsc190501.50391517': __('identifierSchemeCodeUnspsc190501.50391517'),
	'identifierSchemeCodeUnspsc190501.50391518': __('identifierSchemeCodeUnspsc190501.50391518'),
	'identifierSchemeCodeUnspsc190501.50391519': __('identifierSchemeCodeUnspsc190501.50391519'),
	'identifierSchemeCodeUnspsc190501.50391520': __('identifierSchemeCodeUnspsc190501.50391520'),
	'identifierSchemeCodeUnspsc190501.50391521': __('identifierSchemeCodeUnspsc190501.50391521'),
	'identifierSchemeCodeUnspsc190501.50391522': __('identifierSchemeCodeUnspsc190501.50391522'),
	'identifierSchemeCodeUnspsc190501.50391523': __('identifierSchemeCodeUnspsc190501.50391523'),
	'identifierSchemeCodeUnspsc190501.50391524': __('identifierSchemeCodeUnspsc190501.50391524'),
	'identifierSchemeCodeUnspsc190501.50391525': __('identifierSchemeCodeUnspsc190501.50391525'),
	'identifierSchemeCodeUnspsc190501.50391526': __('identifierSchemeCodeUnspsc190501.50391526'),
	'identifierSchemeCodeUnspsc190501.50391527': __('identifierSchemeCodeUnspsc190501.50391527'),
	'identifierSchemeCodeUnspsc190501.50391528': __('identifierSchemeCodeUnspsc190501.50391528'),
	'identifierSchemeCodeUnspsc190501.50391529': __('identifierSchemeCodeUnspsc190501.50391529'),
	'identifierSchemeCodeUnspsc190501.50391530': __('identifierSchemeCodeUnspsc190501.50391530'),
	'identifierSchemeCodeUnspsc190501.50391531': __('identifierSchemeCodeUnspsc190501.50391531'),
	'identifierSchemeCodeUnspsc190501.50391532': __('identifierSchemeCodeUnspsc190501.50391532'),
	'identifierSchemeCodeUnspsc190501.50391533': __('identifierSchemeCodeUnspsc190501.50391533'),
	'identifierSchemeCodeUnspsc190501.50391534': __('identifierSchemeCodeUnspsc190501.50391534'),
	'identifierSchemeCodeUnspsc190501.50391535': __('identifierSchemeCodeUnspsc190501.50391535'),
	'identifierSchemeCodeUnspsc190501.50391536': __('identifierSchemeCodeUnspsc190501.50391536'),
	'identifierSchemeCodeUnspsc190501.50391537': __('identifierSchemeCodeUnspsc190501.50391537'),
	'identifierSchemeCodeUnspsc190501.50391538': __('identifierSchemeCodeUnspsc190501.50391538'),
	'identifierSchemeCodeUnspsc190501.50391539': __('identifierSchemeCodeUnspsc190501.50391539'),
	'identifierSchemeCodeUnspsc190501.50391540': __('identifierSchemeCodeUnspsc190501.50391540'),
	'identifierSchemeCodeUnspsc190501.50391541': __('identifierSchemeCodeUnspsc190501.50391541'),
	'identifierSchemeCodeUnspsc190501.50391542': __('identifierSchemeCodeUnspsc190501.50391542'),
	'identifierSchemeCodeUnspsc190501.50391543': __('identifierSchemeCodeUnspsc190501.50391543'),
	'identifierSchemeCodeUnspsc190501.50391544': __('identifierSchemeCodeUnspsc190501.50391544'),
	'identifierSchemeCodeUnspsc190501.50391545': __('identifierSchemeCodeUnspsc190501.50391545'),
	'identifierSchemeCodeUnspsc190501.50391546': __('identifierSchemeCodeUnspsc190501.50391546'),
	'identifierSchemeCodeUnspsc190501.50391547': __('identifierSchemeCodeUnspsc190501.50391547'),
	'identifierSchemeCodeUnspsc190501.50391548': __('identifierSchemeCodeUnspsc190501.50391548'),
	'identifierSchemeCodeUnspsc190501.50391549': __('identifierSchemeCodeUnspsc190501.50391549'),
	'identifierSchemeCodeUnspsc190501.50391550': __('identifierSchemeCodeUnspsc190501.50391550'),
	'identifierSchemeCodeUnspsc190501.50391551': __('identifierSchemeCodeUnspsc190501.50391551'),
	'identifierSchemeCodeUnspsc190501.50391552': __('identifierSchemeCodeUnspsc190501.50391552'),
	'identifierSchemeCodeUnspsc190501.50391553': __('identifierSchemeCodeUnspsc190501.50391553'),
	'identifierSchemeCodeUnspsc190501.50391554': __('identifierSchemeCodeUnspsc190501.50391554'),
	'identifierSchemeCodeUnspsc190501.50391555': __('identifierSchemeCodeUnspsc190501.50391555'),
	'identifierSchemeCodeUnspsc190501.50391556': __('identifierSchemeCodeUnspsc190501.50391556'),
	'identifierSchemeCodeUnspsc190501.50391557': __('identifierSchemeCodeUnspsc190501.50391557'),
	'identifierSchemeCodeUnspsc190501.50391558': __('identifierSchemeCodeUnspsc190501.50391558'),
	'identifierSchemeCodeUnspsc190501.50391559': __('identifierSchemeCodeUnspsc190501.50391559'),
	'identifierSchemeCodeUnspsc190501.50391560': __('identifierSchemeCodeUnspsc190501.50391560'),
	'identifierSchemeCodeUnspsc190501.50391561': __('identifierSchemeCodeUnspsc190501.50391561'),
	'identifierSchemeCodeUnspsc190501.50391562': __('identifierSchemeCodeUnspsc190501.50391562'),
	'identifierSchemeCodeUnspsc190501.50391563': __('identifierSchemeCodeUnspsc190501.50391563'),
	'identifierSchemeCodeUnspsc190501.50391564': __('identifierSchemeCodeUnspsc190501.50391564'),
	'identifierSchemeCodeUnspsc190501.50391565': __('identifierSchemeCodeUnspsc190501.50391565'),
	'identifierSchemeCodeUnspsc190501.50391566': __('identifierSchemeCodeUnspsc190501.50391566'),
	'identifierSchemeCodeUnspsc190501.50391567': __('identifierSchemeCodeUnspsc190501.50391567'),
	'identifierSchemeCodeUnspsc190501.50391568': __('identifierSchemeCodeUnspsc190501.50391568'),
	'identifierSchemeCodeUnspsc190501.50391569': __('identifierSchemeCodeUnspsc190501.50391569'),
	'identifierSchemeCodeUnspsc190501.50391570': __('identifierSchemeCodeUnspsc190501.50391570'),
	'identifierSchemeCodeUnspsc190501.50391571': __('identifierSchemeCodeUnspsc190501.50391571'),
	'identifierSchemeCodeUnspsc190501.50391572': __('identifierSchemeCodeUnspsc190501.50391572'),
	'identifierSchemeCodeUnspsc190501.50391573': __('identifierSchemeCodeUnspsc190501.50391573'),
	'identifierSchemeCodeUnspsc190501.50391574': __('identifierSchemeCodeUnspsc190501.50391574'),
	'identifierSchemeCodeUnspsc190501.50391575': __('identifierSchemeCodeUnspsc190501.50391575'),
	'identifierSchemeCodeUnspsc190501.50391576': __('identifierSchemeCodeUnspsc190501.50391576'),
	'identifierSchemeCodeUnspsc190501.50391577': __('identifierSchemeCodeUnspsc190501.50391577'),
	'identifierSchemeCodeUnspsc190501.50391578': __('identifierSchemeCodeUnspsc190501.50391578'),
	'identifierSchemeCodeUnspsc190501.50391579': __('identifierSchemeCodeUnspsc190501.50391579'),
	'identifierSchemeCodeUnspsc190501.50391580': __('identifierSchemeCodeUnspsc190501.50391580'),
	'identifierSchemeCodeUnspsc190501.50391581': __('identifierSchemeCodeUnspsc190501.50391581'),
	'identifierSchemeCodeUnspsc190501.50391582': __('identifierSchemeCodeUnspsc190501.50391582'),
	'identifierSchemeCodeUnspsc190501.50391583': __('identifierSchemeCodeUnspsc190501.50391583'),
	'identifierSchemeCodeUnspsc190501.50391584': __('identifierSchemeCodeUnspsc190501.50391584'),
	'identifierSchemeCodeUnspsc190501.50391585': __('identifierSchemeCodeUnspsc190501.50391585'),
	'identifierSchemeCodeUnspsc190501.50391586': __('identifierSchemeCodeUnspsc190501.50391586'),
	'identifierSchemeCodeUnspsc190501.50391587': __('identifierSchemeCodeUnspsc190501.50391587'),
	'identifierSchemeCodeUnspsc190501.50391588': __('identifierSchemeCodeUnspsc190501.50391588'),
	'identifierSchemeCodeUnspsc190501.50391589': __('identifierSchemeCodeUnspsc190501.50391589'),
	'identifierSchemeCodeUnspsc190501.50391590': __('identifierSchemeCodeUnspsc190501.50391590'),
	'identifierSchemeCodeUnspsc190501.50391591': __('identifierSchemeCodeUnspsc190501.50391591'),
	'identifierSchemeCodeUnspsc190501.50391592': __('identifierSchemeCodeUnspsc190501.50391592'),
	'identifierSchemeCodeUnspsc190501.50391593': __('identifierSchemeCodeUnspsc190501.50391593'),
	'identifierSchemeCodeUnspsc190501.50391594': __('identifierSchemeCodeUnspsc190501.50391594'),
	'identifierSchemeCodeUnspsc190501.50391595': __('identifierSchemeCodeUnspsc190501.50391595'),
	'identifierSchemeCodeUnspsc190501.50391596': __('identifierSchemeCodeUnspsc190501.50391596'),
	'identifierSchemeCodeUnspsc190501.50391600': __('identifierSchemeCodeUnspsc190501.50391600'),
	'identifierSchemeCodeUnspsc190501.50391601': __('identifierSchemeCodeUnspsc190501.50391601'),
	'identifierSchemeCodeUnspsc190501.50391602': __('identifierSchemeCodeUnspsc190501.50391602'),
	'identifierSchemeCodeUnspsc190501.50391603': __('identifierSchemeCodeUnspsc190501.50391603'),
	'identifierSchemeCodeUnspsc190501.50391604': __('identifierSchemeCodeUnspsc190501.50391604'),
	'identifierSchemeCodeUnspsc190501.50391605': __('identifierSchemeCodeUnspsc190501.50391605'),
	'identifierSchemeCodeUnspsc190501.50391606': __('identifierSchemeCodeUnspsc190501.50391606'),
	'identifierSchemeCodeUnspsc190501.50391607': __('identifierSchemeCodeUnspsc190501.50391607'),
	'identifierSchemeCodeUnspsc190501.50391608': __('identifierSchemeCodeUnspsc190501.50391608'),
	'identifierSchemeCodeUnspsc190501.50391609': __('identifierSchemeCodeUnspsc190501.50391609'),
	'identifierSchemeCodeUnspsc190501.50391610': __('identifierSchemeCodeUnspsc190501.50391610'),
	'identifierSchemeCodeUnspsc190501.50391611': __('identifierSchemeCodeUnspsc190501.50391611'),
	'identifierSchemeCodeUnspsc190501.50391612': __('identifierSchemeCodeUnspsc190501.50391612'),
	'identifierSchemeCodeUnspsc190501.50391613': __('identifierSchemeCodeUnspsc190501.50391613'),
	'identifierSchemeCodeUnspsc190501.50391614': __('identifierSchemeCodeUnspsc190501.50391614'),
	'identifierSchemeCodeUnspsc190501.50391615': __('identifierSchemeCodeUnspsc190501.50391615'),
	'identifierSchemeCodeUnspsc190501.50391616': __('identifierSchemeCodeUnspsc190501.50391616'),
	'identifierSchemeCodeUnspsc190501.50391617': __('identifierSchemeCodeUnspsc190501.50391617'),
	'identifierSchemeCodeUnspsc190501.50391618': __('identifierSchemeCodeUnspsc190501.50391618'),
	'identifierSchemeCodeUnspsc190501.50391619': __('identifierSchemeCodeUnspsc190501.50391619'),
	'identifierSchemeCodeUnspsc190501.50391620': __('identifierSchemeCodeUnspsc190501.50391620'),
	'identifierSchemeCodeUnspsc190501.50391621': __('identifierSchemeCodeUnspsc190501.50391621'),
	'identifierSchemeCodeUnspsc190501.50391622': __('identifierSchemeCodeUnspsc190501.50391622'),
	'identifierSchemeCodeUnspsc190501.50391623': __('identifierSchemeCodeUnspsc190501.50391623'),
	'identifierSchemeCodeUnspsc190501.50391624': __('identifierSchemeCodeUnspsc190501.50391624'),
	'identifierSchemeCodeUnspsc190501.50391625': __('identifierSchemeCodeUnspsc190501.50391625'),
	'identifierSchemeCodeUnspsc190501.50391626': __('identifierSchemeCodeUnspsc190501.50391626'),
	'identifierSchemeCodeUnspsc190501.50391627': __('identifierSchemeCodeUnspsc190501.50391627'),
	'identifierSchemeCodeUnspsc190501.50391628': __('identifierSchemeCodeUnspsc190501.50391628'),
	'identifierSchemeCodeUnspsc190501.50391629': __('identifierSchemeCodeUnspsc190501.50391629'),
	'identifierSchemeCodeUnspsc190501.50391630': __('identifierSchemeCodeUnspsc190501.50391630'),
	'identifierSchemeCodeUnspsc190501.50391631': __('identifierSchemeCodeUnspsc190501.50391631'),
	'identifierSchemeCodeUnspsc190501.50391632': __('identifierSchemeCodeUnspsc190501.50391632'),
	'identifierSchemeCodeUnspsc190501.50391633': __('identifierSchemeCodeUnspsc190501.50391633'),
	'identifierSchemeCodeUnspsc190501.50391634': __('identifierSchemeCodeUnspsc190501.50391634'),
	'identifierSchemeCodeUnspsc190501.50391635': __('identifierSchemeCodeUnspsc190501.50391635'),
	'identifierSchemeCodeUnspsc190501.50391636': __('identifierSchemeCodeUnspsc190501.50391636'),
	'identifierSchemeCodeUnspsc190501.50391637': __('identifierSchemeCodeUnspsc190501.50391637'),
	'identifierSchemeCodeUnspsc190501.50391638': __('identifierSchemeCodeUnspsc190501.50391638'),
	'identifierSchemeCodeUnspsc190501.50391639': __('identifierSchemeCodeUnspsc190501.50391639'),
	'identifierSchemeCodeUnspsc190501.50391640': __('identifierSchemeCodeUnspsc190501.50391640'),
	'identifierSchemeCodeUnspsc190501.50391641': __('identifierSchemeCodeUnspsc190501.50391641'),
	'identifierSchemeCodeUnspsc190501.50391642': __('identifierSchemeCodeUnspsc190501.50391642'),
	'identifierSchemeCodeUnspsc190501.50391643': __('identifierSchemeCodeUnspsc190501.50391643'),
	'identifierSchemeCodeUnspsc190501.50391644': __('identifierSchemeCodeUnspsc190501.50391644'),
	'identifierSchemeCodeUnspsc190501.50391645': __('identifierSchemeCodeUnspsc190501.50391645'),
	'identifierSchemeCodeUnspsc190501.50391646': __('identifierSchemeCodeUnspsc190501.50391646'),
	'identifierSchemeCodeUnspsc190501.50391647': __('identifierSchemeCodeUnspsc190501.50391647'),
	'identifierSchemeCodeUnspsc190501.50391648': __('identifierSchemeCodeUnspsc190501.50391648'),
	'identifierSchemeCodeUnspsc190501.50391649': __('identifierSchemeCodeUnspsc190501.50391649'),
	'identifierSchemeCodeUnspsc190501.50391650': __('identifierSchemeCodeUnspsc190501.50391650'),
	'identifierSchemeCodeUnspsc190501.50391651': __('identifierSchemeCodeUnspsc190501.50391651'),
	'identifierSchemeCodeUnspsc190501.50391652': __('identifierSchemeCodeUnspsc190501.50391652'),
	'identifierSchemeCodeUnspsc190501.50391653': __('identifierSchemeCodeUnspsc190501.50391653'),
	'identifierSchemeCodeUnspsc190501.50391654': __('identifierSchemeCodeUnspsc190501.50391654'),
	'identifierSchemeCodeUnspsc190501.50391655': __('identifierSchemeCodeUnspsc190501.50391655'),
	'identifierSchemeCodeUnspsc190501.50391700': __('identifierSchemeCodeUnspsc190501.50391700'),
	'identifierSchemeCodeUnspsc190501.50391701': __('identifierSchemeCodeUnspsc190501.50391701'),
	'identifierSchemeCodeUnspsc190501.50391702': __('identifierSchemeCodeUnspsc190501.50391702'),
	'identifierSchemeCodeUnspsc190501.50391703': __('identifierSchemeCodeUnspsc190501.50391703'),
	'identifierSchemeCodeUnspsc190501.50391704': __('identifierSchemeCodeUnspsc190501.50391704'),
	'identifierSchemeCodeUnspsc190501.50391705': __('identifierSchemeCodeUnspsc190501.50391705'),
	'identifierSchemeCodeUnspsc190501.50391706': __('identifierSchemeCodeUnspsc190501.50391706'),
	'identifierSchemeCodeUnspsc190501.50391707': __('identifierSchemeCodeUnspsc190501.50391707'),
	'identifierSchemeCodeUnspsc190501.50391708': __('identifierSchemeCodeUnspsc190501.50391708'),
	'identifierSchemeCodeUnspsc190501.50391709': __('identifierSchemeCodeUnspsc190501.50391709'),
	'identifierSchemeCodeUnspsc190501.50391710': __('identifierSchemeCodeUnspsc190501.50391710'),
	'identifierSchemeCodeUnspsc190501.50391711': __('identifierSchemeCodeUnspsc190501.50391711'),
	'identifierSchemeCodeUnspsc190501.50391712': __('identifierSchemeCodeUnspsc190501.50391712'),
	'identifierSchemeCodeUnspsc190501.50391713': __('identifierSchemeCodeUnspsc190501.50391713'),
	'identifierSchemeCodeUnspsc190501.50391714': __('identifierSchemeCodeUnspsc190501.50391714'),
	'identifierSchemeCodeUnspsc190501.50391715': __('identifierSchemeCodeUnspsc190501.50391715'),
	'identifierSchemeCodeUnspsc190501.50391800': __('identifierSchemeCodeUnspsc190501.50391800'),
	'identifierSchemeCodeUnspsc190501.50391801': __('identifierSchemeCodeUnspsc190501.50391801'),
	'identifierSchemeCodeUnspsc190501.50391802': __('identifierSchemeCodeUnspsc190501.50391802'),
	'identifierSchemeCodeUnspsc190501.50391803': __('identifierSchemeCodeUnspsc190501.50391803'),
	'identifierSchemeCodeUnspsc190501.50391804': __('identifierSchemeCodeUnspsc190501.50391804'),
	'identifierSchemeCodeUnspsc190501.50391805': __('identifierSchemeCodeUnspsc190501.50391805'),
	'identifierSchemeCodeUnspsc190501.50391806': __('identifierSchemeCodeUnspsc190501.50391806'),
	'identifierSchemeCodeUnspsc190501.50391807': __('identifierSchemeCodeUnspsc190501.50391807'),
	'identifierSchemeCodeUnspsc190501.50391808': __('identifierSchemeCodeUnspsc190501.50391808'),
	'identifierSchemeCodeUnspsc190501.50391809': __('identifierSchemeCodeUnspsc190501.50391809'),
	'identifierSchemeCodeUnspsc190501.50391810': __('identifierSchemeCodeUnspsc190501.50391810'),
	'identifierSchemeCodeUnspsc190501.50391811': __('identifierSchemeCodeUnspsc190501.50391811'),
	'identifierSchemeCodeUnspsc190501.50391812': __('identifierSchemeCodeUnspsc190501.50391812'),
	'identifierSchemeCodeUnspsc190501.50391813': __('identifierSchemeCodeUnspsc190501.50391813'),
	'identifierSchemeCodeUnspsc190501.50391814': __('identifierSchemeCodeUnspsc190501.50391814'),
	'identifierSchemeCodeUnspsc190501.50391900': __('identifierSchemeCodeUnspsc190501.50391900'),
	'identifierSchemeCodeUnspsc190501.50391901': __('identifierSchemeCodeUnspsc190501.50391901'),
	'identifierSchemeCodeUnspsc190501.50391902': __('identifierSchemeCodeUnspsc190501.50391902'),
	'identifierSchemeCodeUnspsc190501.50391903': __('identifierSchemeCodeUnspsc190501.50391903'),
	'identifierSchemeCodeUnspsc190501.50392000': __('identifierSchemeCodeUnspsc190501.50392000'),
	'identifierSchemeCodeUnspsc190501.50392001': __('identifierSchemeCodeUnspsc190501.50392001'),
	'identifierSchemeCodeUnspsc190501.50392002': __('identifierSchemeCodeUnspsc190501.50392002'),
	'identifierSchemeCodeUnspsc190501.50392003': __('identifierSchemeCodeUnspsc190501.50392003'),
	'identifierSchemeCodeUnspsc190501.50392004': __('identifierSchemeCodeUnspsc190501.50392004'),
	'identifierSchemeCodeUnspsc190501.50392005': __('identifierSchemeCodeUnspsc190501.50392005'),
	'identifierSchemeCodeUnspsc190501.50392006': __('identifierSchemeCodeUnspsc190501.50392006'),
	'identifierSchemeCodeUnspsc190501.50392007': __('identifierSchemeCodeUnspsc190501.50392007'),
	'identifierSchemeCodeUnspsc190501.50392008': __('identifierSchemeCodeUnspsc190501.50392008'),
	'identifierSchemeCodeUnspsc190501.50392009': __('identifierSchemeCodeUnspsc190501.50392009'),
	'identifierSchemeCodeUnspsc190501.50392010': __('identifierSchemeCodeUnspsc190501.50392010'),
	'identifierSchemeCodeUnspsc190501.50392011': __('identifierSchemeCodeUnspsc190501.50392011'),
	'identifierSchemeCodeUnspsc190501.50392012': __('identifierSchemeCodeUnspsc190501.50392012'),
	'identifierSchemeCodeUnspsc190501.50392013': __('identifierSchemeCodeUnspsc190501.50392013'),
	'identifierSchemeCodeUnspsc190501.50392014': __('identifierSchemeCodeUnspsc190501.50392014'),
	'identifierSchemeCodeUnspsc190501.50392015': __('identifierSchemeCodeUnspsc190501.50392015'),
	'identifierSchemeCodeUnspsc190501.50392100': __('identifierSchemeCodeUnspsc190501.50392100'),
	'identifierSchemeCodeUnspsc190501.50392101': __('identifierSchemeCodeUnspsc190501.50392101'),
	'identifierSchemeCodeUnspsc190501.50392102': __('identifierSchemeCodeUnspsc190501.50392102'),
	'identifierSchemeCodeUnspsc190501.50392103': __('identifierSchemeCodeUnspsc190501.50392103'),
	'identifierSchemeCodeUnspsc190501.50392104': __('identifierSchemeCodeUnspsc190501.50392104'),
	'identifierSchemeCodeUnspsc190501.50392200': __('identifierSchemeCodeUnspsc190501.50392200'),
	'identifierSchemeCodeUnspsc190501.50392201': __('identifierSchemeCodeUnspsc190501.50392201'),
	'identifierSchemeCodeUnspsc190501.50392202': __('identifierSchemeCodeUnspsc190501.50392202'),
	'identifierSchemeCodeUnspsc190501.50392203': __('identifierSchemeCodeUnspsc190501.50392203'),
	'identifierSchemeCodeUnspsc190501.50392204': __('identifierSchemeCodeUnspsc190501.50392204'),
	'identifierSchemeCodeUnspsc190501.50392205': __('identifierSchemeCodeUnspsc190501.50392205'),
	'identifierSchemeCodeUnspsc190501.50392206': __('identifierSchemeCodeUnspsc190501.50392206'),
	'identifierSchemeCodeUnspsc190501.50392207': __('identifierSchemeCodeUnspsc190501.50392207'),
	'identifierSchemeCodeUnspsc190501.50392208': __('identifierSchemeCodeUnspsc190501.50392208'),
	'identifierSchemeCodeUnspsc190501.50392209': __('identifierSchemeCodeUnspsc190501.50392209'),
	'identifierSchemeCodeUnspsc190501.50392210': __('identifierSchemeCodeUnspsc190501.50392210'),
	'identifierSchemeCodeUnspsc190501.50392211': __('identifierSchemeCodeUnspsc190501.50392211'),
	'identifierSchemeCodeUnspsc190501.50392212': __('identifierSchemeCodeUnspsc190501.50392212'),
	'identifierSchemeCodeUnspsc190501.50392213': __('identifierSchemeCodeUnspsc190501.50392213'),
	'identifierSchemeCodeUnspsc190501.50392214': __('identifierSchemeCodeUnspsc190501.50392214'),
	'identifierSchemeCodeUnspsc190501.50392215': __('identifierSchemeCodeUnspsc190501.50392215'),
	'identifierSchemeCodeUnspsc190501.50392300': __('identifierSchemeCodeUnspsc190501.50392300'),
	'identifierSchemeCodeUnspsc190501.50392301': __('identifierSchemeCodeUnspsc190501.50392301'),
	'identifierSchemeCodeUnspsc190501.50392302': __('identifierSchemeCodeUnspsc190501.50392302'),
	'identifierSchemeCodeUnspsc190501.50392303': __('identifierSchemeCodeUnspsc190501.50392303'),
	'identifierSchemeCodeUnspsc190501.50392304': __('identifierSchemeCodeUnspsc190501.50392304'),
	'identifierSchemeCodeUnspsc190501.50392400': __('identifierSchemeCodeUnspsc190501.50392400'),
	'identifierSchemeCodeUnspsc190501.50392401': __('identifierSchemeCodeUnspsc190501.50392401'),
	'identifierSchemeCodeUnspsc190501.50392402': __('identifierSchemeCodeUnspsc190501.50392402'),
	'identifierSchemeCodeUnspsc190501.50392403': __('identifierSchemeCodeUnspsc190501.50392403'),
	'identifierSchemeCodeUnspsc190501.50392404': __('identifierSchemeCodeUnspsc190501.50392404'),
	'identifierSchemeCodeUnspsc190501.50392405': __('identifierSchemeCodeUnspsc190501.50392405'),
	'identifierSchemeCodeUnspsc190501.50392406': __('identifierSchemeCodeUnspsc190501.50392406'),
	'identifierSchemeCodeUnspsc190501.50392407': __('identifierSchemeCodeUnspsc190501.50392407'),
	'identifierSchemeCodeUnspsc190501.50392408': __('identifierSchemeCodeUnspsc190501.50392408'),
	'identifierSchemeCodeUnspsc190501.50392409': __('identifierSchemeCodeUnspsc190501.50392409'),
	'identifierSchemeCodeUnspsc190501.50392500': __('identifierSchemeCodeUnspsc190501.50392500'),
	'identifierSchemeCodeUnspsc190501.50392501': __('identifierSchemeCodeUnspsc190501.50392501'),
	'identifierSchemeCodeUnspsc190501.50392502': __('identifierSchemeCodeUnspsc190501.50392502'),
	'identifierSchemeCodeUnspsc190501.50392503': __('identifierSchemeCodeUnspsc190501.50392503'),
	'identifierSchemeCodeUnspsc190501.50392504': __('identifierSchemeCodeUnspsc190501.50392504'),
	'identifierSchemeCodeUnspsc190501.50392505': __('identifierSchemeCodeUnspsc190501.50392505'),
	'identifierSchemeCodeUnspsc190501.50392506': __('identifierSchemeCodeUnspsc190501.50392506'),
	'identifierSchemeCodeUnspsc190501.50392507': __('identifierSchemeCodeUnspsc190501.50392507'),
	'identifierSchemeCodeUnspsc190501.50392508': __('identifierSchemeCodeUnspsc190501.50392508'),
	'identifierSchemeCodeUnspsc190501.50392509': __('identifierSchemeCodeUnspsc190501.50392509'),
	'identifierSchemeCodeUnspsc190501.50392510': __('identifierSchemeCodeUnspsc190501.50392510'),
	'identifierSchemeCodeUnspsc190501.50392511': __('identifierSchemeCodeUnspsc190501.50392511'),
	'identifierSchemeCodeUnspsc190501.50392512': __('identifierSchemeCodeUnspsc190501.50392512'),
	'identifierSchemeCodeUnspsc190501.50392513': __('identifierSchemeCodeUnspsc190501.50392513'),
	'identifierSchemeCodeUnspsc190501.50392514': __('identifierSchemeCodeUnspsc190501.50392514'),
	'identifierSchemeCodeUnspsc190501.50392515': __('identifierSchemeCodeUnspsc190501.50392515'),
	'identifierSchemeCodeUnspsc190501.50392516': __('identifierSchemeCodeUnspsc190501.50392516'),
	'identifierSchemeCodeUnspsc190501.50392517': __('identifierSchemeCodeUnspsc190501.50392517'),
	'identifierSchemeCodeUnspsc190501.50392518': __('identifierSchemeCodeUnspsc190501.50392518'),
	'identifierSchemeCodeUnspsc190501.50392519': __('identifierSchemeCodeUnspsc190501.50392519'),
	'identifierSchemeCodeUnspsc190501.50392520': __('identifierSchemeCodeUnspsc190501.50392520'),
	'identifierSchemeCodeUnspsc190501.50392521': __('identifierSchemeCodeUnspsc190501.50392521'),
	'identifierSchemeCodeUnspsc190501.50392522': __('identifierSchemeCodeUnspsc190501.50392522'),
	'identifierSchemeCodeUnspsc190501.50392523': __('identifierSchemeCodeUnspsc190501.50392523'),
	'identifierSchemeCodeUnspsc190501.50392524': __('identifierSchemeCodeUnspsc190501.50392524'),
	'identifierSchemeCodeUnspsc190501.50392525': __('identifierSchemeCodeUnspsc190501.50392525'),
	'identifierSchemeCodeUnspsc190501.50392526': __('identifierSchemeCodeUnspsc190501.50392526'),
	'identifierSchemeCodeUnspsc190501.50392527': __('identifierSchemeCodeUnspsc190501.50392527'),
	'identifierSchemeCodeUnspsc190501.50392528': __('identifierSchemeCodeUnspsc190501.50392528'),
	'identifierSchemeCodeUnspsc190501.50392529': __('identifierSchemeCodeUnspsc190501.50392529'),
	'identifierSchemeCodeUnspsc190501.50392530': __('identifierSchemeCodeUnspsc190501.50392530'),
	'identifierSchemeCodeUnspsc190501.50392531': __('identifierSchemeCodeUnspsc190501.50392531'),
	'identifierSchemeCodeUnspsc190501.50392600': __('identifierSchemeCodeUnspsc190501.50392600'),
	'identifierSchemeCodeUnspsc190501.50392601': __('identifierSchemeCodeUnspsc190501.50392601'),
	'identifierSchemeCodeUnspsc190501.50392602': __('identifierSchemeCodeUnspsc190501.50392602'),
	'identifierSchemeCodeUnspsc190501.50392603': __('identifierSchemeCodeUnspsc190501.50392603'),
	'identifierSchemeCodeUnspsc190501.50392604': __('identifierSchemeCodeUnspsc190501.50392604'),
	'identifierSchemeCodeUnspsc190501.50392605': __('identifierSchemeCodeUnspsc190501.50392605'),
	'identifierSchemeCodeUnspsc190501.50392606': __('identifierSchemeCodeUnspsc190501.50392606'),
	'identifierSchemeCodeUnspsc190501.50392607': __('identifierSchemeCodeUnspsc190501.50392607'),
	'identifierSchemeCodeUnspsc190501.50392700': __('identifierSchemeCodeUnspsc190501.50392700'),
	'identifierSchemeCodeUnspsc190501.50392701': __('identifierSchemeCodeUnspsc190501.50392701'),
	'identifierSchemeCodeUnspsc190501.50392702': __('identifierSchemeCodeUnspsc190501.50392702'),
	'identifierSchemeCodeUnspsc190501.50392703': __('identifierSchemeCodeUnspsc190501.50392703'),
	'identifierSchemeCodeUnspsc190501.50392704': __('identifierSchemeCodeUnspsc190501.50392704'),
	'identifierSchemeCodeUnspsc190501.50392705': __('identifierSchemeCodeUnspsc190501.50392705'),
	'identifierSchemeCodeUnspsc190501.50392706': __('identifierSchemeCodeUnspsc190501.50392706'),
	'identifierSchemeCodeUnspsc190501.50392707': __('identifierSchemeCodeUnspsc190501.50392707'),
	'identifierSchemeCodeUnspsc190501.50392708': __('identifierSchemeCodeUnspsc190501.50392708'),
	'identifierSchemeCodeUnspsc190501.50392709': __('identifierSchemeCodeUnspsc190501.50392709'),
	'identifierSchemeCodeUnspsc190501.50392710': __('identifierSchemeCodeUnspsc190501.50392710'),
	'identifierSchemeCodeUnspsc190501.50392800': __('identifierSchemeCodeUnspsc190501.50392800'),
	'identifierSchemeCodeUnspsc190501.50392801': __('identifierSchemeCodeUnspsc190501.50392801'),
	'identifierSchemeCodeUnspsc190501.50392802': __('identifierSchemeCodeUnspsc190501.50392802'),
	'identifierSchemeCodeUnspsc190501.50392803': __('identifierSchemeCodeUnspsc190501.50392803'),
	'identifierSchemeCodeUnspsc190501.50392804': __('identifierSchemeCodeUnspsc190501.50392804'),
	'identifierSchemeCodeUnspsc190501.50392805': __('identifierSchemeCodeUnspsc190501.50392805'),
	'identifierSchemeCodeUnspsc190501.50392806': __('identifierSchemeCodeUnspsc190501.50392806'),
	'identifierSchemeCodeUnspsc190501.50392900': __('identifierSchemeCodeUnspsc190501.50392900'),
	'identifierSchemeCodeUnspsc190501.50392901': __('identifierSchemeCodeUnspsc190501.50392901'),
	'identifierSchemeCodeUnspsc190501.50392902': __('identifierSchemeCodeUnspsc190501.50392902'),
	'identifierSchemeCodeUnspsc190501.50392903': __('identifierSchemeCodeUnspsc190501.50392903'),
	'identifierSchemeCodeUnspsc190501.50392904': __('identifierSchemeCodeUnspsc190501.50392904'),
	'identifierSchemeCodeUnspsc190501.50392905': __('identifierSchemeCodeUnspsc190501.50392905'),
	'identifierSchemeCodeUnspsc190501.50392906': __('identifierSchemeCodeUnspsc190501.50392906'),
	'identifierSchemeCodeUnspsc190501.50392907': __('identifierSchemeCodeUnspsc190501.50392907'),
	'identifierSchemeCodeUnspsc190501.50392908': __('identifierSchemeCodeUnspsc190501.50392908'),
	'identifierSchemeCodeUnspsc190501.50392909': __('identifierSchemeCodeUnspsc190501.50392909'),
	'identifierSchemeCodeUnspsc190501.50392910': __('identifierSchemeCodeUnspsc190501.50392910'),
	'identifierSchemeCodeUnspsc190501.50392911': __('identifierSchemeCodeUnspsc190501.50392911'),
	'identifierSchemeCodeUnspsc190501.50392912': __('identifierSchemeCodeUnspsc190501.50392912'),
	'identifierSchemeCodeUnspsc190501.50392913': __('identifierSchemeCodeUnspsc190501.50392913'),
	'identifierSchemeCodeUnspsc190501.50392914': __('identifierSchemeCodeUnspsc190501.50392914'),
	'identifierSchemeCodeUnspsc190501.50392915': __('identifierSchemeCodeUnspsc190501.50392915'),
	'identifierSchemeCodeUnspsc190501.50392916': __('identifierSchemeCodeUnspsc190501.50392916'),
	'identifierSchemeCodeUnspsc190501.50393000': __('identifierSchemeCodeUnspsc190501.50393000'),
	'identifierSchemeCodeUnspsc190501.50393001': __('identifierSchemeCodeUnspsc190501.50393001'),
	'identifierSchemeCodeUnspsc190501.50393002': __('identifierSchemeCodeUnspsc190501.50393002'),
	'identifierSchemeCodeUnspsc190501.50393100': __('identifierSchemeCodeUnspsc190501.50393100'),
	'identifierSchemeCodeUnspsc190501.50393101': __('identifierSchemeCodeUnspsc190501.50393101'),
	'identifierSchemeCodeUnspsc190501.50393102': __('identifierSchemeCodeUnspsc190501.50393102'),
	'identifierSchemeCodeUnspsc190501.50393103': __('identifierSchemeCodeUnspsc190501.50393103'),
	'identifierSchemeCodeUnspsc190501.50393104': __('identifierSchemeCodeUnspsc190501.50393104'),
	'identifierSchemeCodeUnspsc190501.50393105': __('identifierSchemeCodeUnspsc190501.50393105'),
	'identifierSchemeCodeUnspsc190501.50393106': __('identifierSchemeCodeUnspsc190501.50393106'),
	'identifierSchemeCodeUnspsc190501.50393107': __('identifierSchemeCodeUnspsc190501.50393107'),
	'identifierSchemeCodeUnspsc190501.50393108': __('identifierSchemeCodeUnspsc190501.50393108'),
	'identifierSchemeCodeUnspsc190501.50393109': __('identifierSchemeCodeUnspsc190501.50393109'),
	'identifierSchemeCodeUnspsc190501.50393110': __('identifierSchemeCodeUnspsc190501.50393110'),
	'identifierSchemeCodeUnspsc190501.50393111': __('identifierSchemeCodeUnspsc190501.50393111'),
	'identifierSchemeCodeUnspsc190501.50393200': __('identifierSchemeCodeUnspsc190501.50393200'),
	'identifierSchemeCodeUnspsc190501.50393201': __('identifierSchemeCodeUnspsc190501.50393201'),
	'identifierSchemeCodeUnspsc190501.50393202': __('identifierSchemeCodeUnspsc190501.50393202'),
	'identifierSchemeCodeUnspsc190501.50393203': __('identifierSchemeCodeUnspsc190501.50393203'),
	'identifierSchemeCodeUnspsc190501.50393204': __('identifierSchemeCodeUnspsc190501.50393204'),
	'identifierSchemeCodeUnspsc190501.50393205': __('identifierSchemeCodeUnspsc190501.50393205'),
	'identifierSchemeCodeUnspsc190501.50393206': __('identifierSchemeCodeUnspsc190501.50393206'),
	'identifierSchemeCodeUnspsc190501.50393207': __('identifierSchemeCodeUnspsc190501.50393207'),
	'identifierSchemeCodeUnspsc190501.50393300': __('identifierSchemeCodeUnspsc190501.50393300'),
	'identifierSchemeCodeUnspsc190501.50393301': __('identifierSchemeCodeUnspsc190501.50393301'),
	'identifierSchemeCodeUnspsc190501.50393302': __('identifierSchemeCodeUnspsc190501.50393302'),
	'identifierSchemeCodeUnspsc190501.50393303': __('identifierSchemeCodeUnspsc190501.50393303'),
	'identifierSchemeCodeUnspsc190501.50393304': __('identifierSchemeCodeUnspsc190501.50393304'),
	'identifierSchemeCodeUnspsc190501.50393305': __('identifierSchemeCodeUnspsc190501.50393305'),
	'identifierSchemeCodeUnspsc190501.50393306': __('identifierSchemeCodeUnspsc190501.50393306'),
	'identifierSchemeCodeUnspsc190501.50393307': __('identifierSchemeCodeUnspsc190501.50393307'),
	'identifierSchemeCodeUnspsc190501.50393308': __('identifierSchemeCodeUnspsc190501.50393308'),
	'identifierSchemeCodeUnspsc190501.50393309': __('identifierSchemeCodeUnspsc190501.50393309'),
	'identifierSchemeCodeUnspsc190501.50393400': __('identifierSchemeCodeUnspsc190501.50393400'),
	'identifierSchemeCodeUnspsc190501.50393401': __('identifierSchemeCodeUnspsc190501.50393401'),
	'identifierSchemeCodeUnspsc190501.50393402': __('identifierSchemeCodeUnspsc190501.50393402'),
	'identifierSchemeCodeUnspsc190501.50393403': __('identifierSchemeCodeUnspsc190501.50393403'),
	'identifierSchemeCodeUnspsc190501.50393404': __('identifierSchemeCodeUnspsc190501.50393404'),
	'identifierSchemeCodeUnspsc190501.50393405': __('identifierSchemeCodeUnspsc190501.50393405'),
	'identifierSchemeCodeUnspsc190501.50393406': __('identifierSchemeCodeUnspsc190501.50393406'),
	'identifierSchemeCodeUnspsc190501.50393407': __('identifierSchemeCodeUnspsc190501.50393407'),
	'identifierSchemeCodeUnspsc190501.50393408': __('identifierSchemeCodeUnspsc190501.50393408'),
	'identifierSchemeCodeUnspsc190501.50393409': __('identifierSchemeCodeUnspsc190501.50393409'),
	'identifierSchemeCodeUnspsc190501.50393410': __('identifierSchemeCodeUnspsc190501.50393410'),
	'identifierSchemeCodeUnspsc190501.50393411': __('identifierSchemeCodeUnspsc190501.50393411'),
	'identifierSchemeCodeUnspsc190501.50393412': __('identifierSchemeCodeUnspsc190501.50393412'),
	'identifierSchemeCodeUnspsc190501.50393413': __('identifierSchemeCodeUnspsc190501.50393413'),
	'identifierSchemeCodeUnspsc190501.50393414': __('identifierSchemeCodeUnspsc190501.50393414'),
	'identifierSchemeCodeUnspsc190501.50393415': __('identifierSchemeCodeUnspsc190501.50393415'),
	'identifierSchemeCodeUnspsc190501.50393416': __('identifierSchemeCodeUnspsc190501.50393416'),
	'identifierSchemeCodeUnspsc190501.50393417': __('identifierSchemeCodeUnspsc190501.50393417'),
	'identifierSchemeCodeUnspsc190501.50393418': __('identifierSchemeCodeUnspsc190501.50393418'),
	'identifierSchemeCodeUnspsc190501.50393419': __('identifierSchemeCodeUnspsc190501.50393419'),
	'identifierSchemeCodeUnspsc190501.50393420': __('identifierSchemeCodeUnspsc190501.50393420'),
	'identifierSchemeCodeUnspsc190501.50393421': __('identifierSchemeCodeUnspsc190501.50393421'),
	'identifierSchemeCodeUnspsc190501.50393422': __('identifierSchemeCodeUnspsc190501.50393422'),
	'identifierSchemeCodeUnspsc190501.50393423': __('identifierSchemeCodeUnspsc190501.50393423'),
	'identifierSchemeCodeUnspsc190501.50393424': __('identifierSchemeCodeUnspsc190501.50393424'),
	'identifierSchemeCodeUnspsc190501.50393425': __('identifierSchemeCodeUnspsc190501.50393425'),
	'identifierSchemeCodeUnspsc190501.50393426': __('identifierSchemeCodeUnspsc190501.50393426'),
	'identifierSchemeCodeUnspsc190501.50393427': __('identifierSchemeCodeUnspsc190501.50393427'),
	'identifierSchemeCodeUnspsc190501.50393428': __('identifierSchemeCodeUnspsc190501.50393428'),
	'identifierSchemeCodeUnspsc190501.50393429': __('identifierSchemeCodeUnspsc190501.50393429'),
	'identifierSchemeCodeUnspsc190501.50393430': __('identifierSchemeCodeUnspsc190501.50393430'),
	'identifierSchemeCodeUnspsc190501.50393431': __('identifierSchemeCodeUnspsc190501.50393431'),
	'identifierSchemeCodeUnspsc190501.50393432': __('identifierSchemeCodeUnspsc190501.50393432'),
	'identifierSchemeCodeUnspsc190501.50393433': __('identifierSchemeCodeUnspsc190501.50393433'),
	'identifierSchemeCodeUnspsc190501.50393434': __('identifierSchemeCodeUnspsc190501.50393434'),
	'identifierSchemeCodeUnspsc190501.50393435': __('identifierSchemeCodeUnspsc190501.50393435'),
	'identifierSchemeCodeUnspsc190501.50393436': __('identifierSchemeCodeUnspsc190501.50393436'),
	'identifierSchemeCodeUnspsc190501.50393437': __('identifierSchemeCodeUnspsc190501.50393437'),
	'identifierSchemeCodeUnspsc190501.50393438': __('identifierSchemeCodeUnspsc190501.50393438'),
	'identifierSchemeCodeUnspsc190501.50393439': __('identifierSchemeCodeUnspsc190501.50393439'),
	'identifierSchemeCodeUnspsc190501.50393440': __('identifierSchemeCodeUnspsc190501.50393440'),
	'identifierSchemeCodeUnspsc190501.50393441': __('identifierSchemeCodeUnspsc190501.50393441'),
	'identifierSchemeCodeUnspsc190501.50393442': __('identifierSchemeCodeUnspsc190501.50393442'),
	'identifierSchemeCodeUnspsc190501.50393443': __('identifierSchemeCodeUnspsc190501.50393443'),
	'identifierSchemeCodeUnspsc190501.50393444': __('identifierSchemeCodeUnspsc190501.50393444'),
	'identifierSchemeCodeUnspsc190501.50393445': __('identifierSchemeCodeUnspsc190501.50393445'),
	'identifierSchemeCodeUnspsc190501.50393446': __('identifierSchemeCodeUnspsc190501.50393446'),
	'identifierSchemeCodeUnspsc190501.50393447': __('identifierSchemeCodeUnspsc190501.50393447'),
	'identifierSchemeCodeUnspsc190501.50393448': __('identifierSchemeCodeUnspsc190501.50393448'),
	'identifierSchemeCodeUnspsc190501.50393449': __('identifierSchemeCodeUnspsc190501.50393449'),
	'identifierSchemeCodeUnspsc190501.50393450': __('identifierSchemeCodeUnspsc190501.50393450'),
	'identifierSchemeCodeUnspsc190501.50393451': __('identifierSchemeCodeUnspsc190501.50393451'),
	'identifierSchemeCodeUnspsc190501.50393452': __('identifierSchemeCodeUnspsc190501.50393452'),
	'identifierSchemeCodeUnspsc190501.50393453': __('identifierSchemeCodeUnspsc190501.50393453'),
	'identifierSchemeCodeUnspsc190501.50393454': __('identifierSchemeCodeUnspsc190501.50393454'),
	'identifierSchemeCodeUnspsc190501.50393455': __('identifierSchemeCodeUnspsc190501.50393455'),
	'identifierSchemeCodeUnspsc190501.50393456': __('identifierSchemeCodeUnspsc190501.50393456'),
	'identifierSchemeCodeUnspsc190501.50393457': __('identifierSchemeCodeUnspsc190501.50393457'),
	'identifierSchemeCodeUnspsc190501.50393458': __('identifierSchemeCodeUnspsc190501.50393458'),
	'identifierSchemeCodeUnspsc190501.50393459': __('identifierSchemeCodeUnspsc190501.50393459'),
	'identifierSchemeCodeUnspsc190501.50393460': __('identifierSchemeCodeUnspsc190501.50393460'),
	'identifierSchemeCodeUnspsc190501.50393461': __('identifierSchemeCodeUnspsc190501.50393461'),
	'identifierSchemeCodeUnspsc190501.50393462': __('identifierSchemeCodeUnspsc190501.50393462'),
	'identifierSchemeCodeUnspsc190501.50393463': __('identifierSchemeCodeUnspsc190501.50393463'),
	'identifierSchemeCodeUnspsc190501.50393464': __('identifierSchemeCodeUnspsc190501.50393464'),
	'identifierSchemeCodeUnspsc190501.50393465': __('identifierSchemeCodeUnspsc190501.50393465'),
	'identifierSchemeCodeUnspsc190501.50393466': __('identifierSchemeCodeUnspsc190501.50393466'),
	'identifierSchemeCodeUnspsc190501.50393467': __('identifierSchemeCodeUnspsc190501.50393467'),
	'identifierSchemeCodeUnspsc190501.50393468': __('identifierSchemeCodeUnspsc190501.50393468'),
	'identifierSchemeCodeUnspsc190501.50393469': __('identifierSchemeCodeUnspsc190501.50393469'),
	'identifierSchemeCodeUnspsc190501.50393470': __('identifierSchemeCodeUnspsc190501.50393470'),
	'identifierSchemeCodeUnspsc190501.50393471': __('identifierSchemeCodeUnspsc190501.50393471'),
	'identifierSchemeCodeUnspsc190501.50393472': __('identifierSchemeCodeUnspsc190501.50393472'),
	'identifierSchemeCodeUnspsc190501.50393473': __('identifierSchemeCodeUnspsc190501.50393473'),
	'identifierSchemeCodeUnspsc190501.50393474': __('identifierSchemeCodeUnspsc190501.50393474'),
	'identifierSchemeCodeUnspsc190501.50393475': __('identifierSchemeCodeUnspsc190501.50393475'),
	'identifierSchemeCodeUnspsc190501.50393476': __('identifierSchemeCodeUnspsc190501.50393476'),
	'identifierSchemeCodeUnspsc190501.50393477': __('identifierSchemeCodeUnspsc190501.50393477'),
	'identifierSchemeCodeUnspsc190501.50393478': __('identifierSchemeCodeUnspsc190501.50393478'),
	'identifierSchemeCodeUnspsc190501.50393479': __('identifierSchemeCodeUnspsc190501.50393479'),
	'identifierSchemeCodeUnspsc190501.50393480': __('identifierSchemeCodeUnspsc190501.50393480'),
	'identifierSchemeCodeUnspsc190501.50393481': __('identifierSchemeCodeUnspsc190501.50393481'),
	'identifierSchemeCodeUnspsc190501.50393482': __('identifierSchemeCodeUnspsc190501.50393482'),
	'identifierSchemeCodeUnspsc190501.50393483': __('identifierSchemeCodeUnspsc190501.50393483'),
	'identifierSchemeCodeUnspsc190501.50393484': __('identifierSchemeCodeUnspsc190501.50393484'),
	'identifierSchemeCodeUnspsc190501.50393485': __('identifierSchemeCodeUnspsc190501.50393485'),
	'identifierSchemeCodeUnspsc190501.50393486': __('identifierSchemeCodeUnspsc190501.50393486'),
	'identifierSchemeCodeUnspsc190501.50393487': __('identifierSchemeCodeUnspsc190501.50393487'),
	'identifierSchemeCodeUnspsc190501.50393488': __('identifierSchemeCodeUnspsc190501.50393488'),
	'identifierSchemeCodeUnspsc190501.50393489': __('identifierSchemeCodeUnspsc190501.50393489'),
	'identifierSchemeCodeUnspsc190501.50393490': __('identifierSchemeCodeUnspsc190501.50393490'),
	'identifierSchemeCodeUnspsc190501.50393491': __('identifierSchemeCodeUnspsc190501.50393491'),
	'identifierSchemeCodeUnspsc190501.50393492': __('identifierSchemeCodeUnspsc190501.50393492'),
	'identifierSchemeCodeUnspsc190501.50393493': __('identifierSchemeCodeUnspsc190501.50393493'),
	'identifierSchemeCodeUnspsc190501.50393494': __('identifierSchemeCodeUnspsc190501.50393494'),
	'identifierSchemeCodeUnspsc190501.50393495': __('identifierSchemeCodeUnspsc190501.50393495'),
	'identifierSchemeCodeUnspsc190501.50393496': __('identifierSchemeCodeUnspsc190501.50393496'),
	'identifierSchemeCodeUnspsc190501.50393500': __('identifierSchemeCodeUnspsc190501.50393500'),
	'identifierSchemeCodeUnspsc190501.50393501': __('identifierSchemeCodeUnspsc190501.50393501'),
	'identifierSchemeCodeUnspsc190501.50393502': __('identifierSchemeCodeUnspsc190501.50393502'),
	'identifierSchemeCodeUnspsc190501.50393503': __('identifierSchemeCodeUnspsc190501.50393503'),
	'identifierSchemeCodeUnspsc190501.50393504': __('identifierSchemeCodeUnspsc190501.50393504'),
	'identifierSchemeCodeUnspsc190501.50393505': __('identifierSchemeCodeUnspsc190501.50393505'),
	'identifierSchemeCodeUnspsc190501.50393506': __('identifierSchemeCodeUnspsc190501.50393506'),
	'identifierSchemeCodeUnspsc190501.50393600': __('identifierSchemeCodeUnspsc190501.50393600'),
	'identifierSchemeCodeUnspsc190501.50393601': __('identifierSchemeCodeUnspsc190501.50393601'),
	'identifierSchemeCodeUnspsc190501.50393602': __('identifierSchemeCodeUnspsc190501.50393602'),
	'identifierSchemeCodeUnspsc190501.50393603': __('identifierSchemeCodeUnspsc190501.50393603'),
	'identifierSchemeCodeUnspsc190501.50393604': __('identifierSchemeCodeUnspsc190501.50393604'),
	'identifierSchemeCodeUnspsc190501.50393605': __('identifierSchemeCodeUnspsc190501.50393605'),
	'identifierSchemeCodeUnspsc190501.50393606': __('identifierSchemeCodeUnspsc190501.50393606'),
	'identifierSchemeCodeUnspsc190501.50393607': __('identifierSchemeCodeUnspsc190501.50393607'),
	'identifierSchemeCodeUnspsc190501.50393608': __('identifierSchemeCodeUnspsc190501.50393608'),
	'identifierSchemeCodeUnspsc190501.50393609': __('identifierSchemeCodeUnspsc190501.50393609'),
	'identifierSchemeCodeUnspsc190501.50393610': __('identifierSchemeCodeUnspsc190501.50393610'),
	'identifierSchemeCodeUnspsc190501.50393611': __('identifierSchemeCodeUnspsc190501.50393611'),
	'identifierSchemeCodeUnspsc190501.50393612': __('identifierSchemeCodeUnspsc190501.50393612'),
	'identifierSchemeCodeUnspsc190501.50393613': __('identifierSchemeCodeUnspsc190501.50393613'),
	'identifierSchemeCodeUnspsc190501.50393614': __('identifierSchemeCodeUnspsc190501.50393614'),
	'identifierSchemeCodeUnspsc190501.50393615': __('identifierSchemeCodeUnspsc190501.50393615'),
	'identifierSchemeCodeUnspsc190501.50393616': __('identifierSchemeCodeUnspsc190501.50393616'),
	'identifierSchemeCodeUnspsc190501.50393617': __('identifierSchemeCodeUnspsc190501.50393617'),
	'identifierSchemeCodeUnspsc190501.50393618': __('identifierSchemeCodeUnspsc190501.50393618'),
	'identifierSchemeCodeUnspsc190501.50393619': __('identifierSchemeCodeUnspsc190501.50393619'),
	'identifierSchemeCodeUnspsc190501.50393620': __('identifierSchemeCodeUnspsc190501.50393620'),
	'identifierSchemeCodeUnspsc190501.50393621': __('identifierSchemeCodeUnspsc190501.50393621'),
	'identifierSchemeCodeUnspsc190501.50393622': __('identifierSchemeCodeUnspsc190501.50393622'),
	'identifierSchemeCodeUnspsc190501.50393623': __('identifierSchemeCodeUnspsc190501.50393623'),
	'identifierSchemeCodeUnspsc190501.50393624': __('identifierSchemeCodeUnspsc190501.50393624'),
	'identifierSchemeCodeUnspsc190501.50393625': __('identifierSchemeCodeUnspsc190501.50393625'),
	'identifierSchemeCodeUnspsc190501.50393626': __('identifierSchemeCodeUnspsc190501.50393626'),
	'identifierSchemeCodeUnspsc190501.50393627': __('identifierSchemeCodeUnspsc190501.50393627'),
	'identifierSchemeCodeUnspsc190501.50393628': __('identifierSchemeCodeUnspsc190501.50393628'),
	'identifierSchemeCodeUnspsc190501.50393629': __('identifierSchemeCodeUnspsc190501.50393629'),
	'identifierSchemeCodeUnspsc190501.50393630': __('identifierSchemeCodeUnspsc190501.50393630'),
	'identifierSchemeCodeUnspsc190501.50393631': __('identifierSchemeCodeUnspsc190501.50393631'),
	'identifierSchemeCodeUnspsc190501.50393632': __('identifierSchemeCodeUnspsc190501.50393632'),
	'identifierSchemeCodeUnspsc190501.50393633': __('identifierSchemeCodeUnspsc190501.50393633'),
	'identifierSchemeCodeUnspsc190501.50393634': __('identifierSchemeCodeUnspsc190501.50393634'),
	'identifierSchemeCodeUnspsc190501.50393635': __('identifierSchemeCodeUnspsc190501.50393635'),
	'identifierSchemeCodeUnspsc190501.50393636': __('identifierSchemeCodeUnspsc190501.50393636'),
	'identifierSchemeCodeUnspsc190501.50393637': __('identifierSchemeCodeUnspsc190501.50393637'),
	'identifierSchemeCodeUnspsc190501.50393638': __('identifierSchemeCodeUnspsc190501.50393638'),
	'identifierSchemeCodeUnspsc190501.50393639': __('identifierSchemeCodeUnspsc190501.50393639'),
	'identifierSchemeCodeUnspsc190501.50393640': __('identifierSchemeCodeUnspsc190501.50393640'),
	'identifierSchemeCodeUnspsc190501.50393641': __('identifierSchemeCodeUnspsc190501.50393641'),
	'identifierSchemeCodeUnspsc190501.50393642': __('identifierSchemeCodeUnspsc190501.50393642'),
	'identifierSchemeCodeUnspsc190501.50393643': __('identifierSchemeCodeUnspsc190501.50393643'),
	'identifierSchemeCodeUnspsc190501.50393644': __('identifierSchemeCodeUnspsc190501.50393644'),
	'identifierSchemeCodeUnspsc190501.50393645': __('identifierSchemeCodeUnspsc190501.50393645'),
	'identifierSchemeCodeUnspsc190501.50393646': __('identifierSchemeCodeUnspsc190501.50393646'),
	'identifierSchemeCodeUnspsc190501.50393647': __('identifierSchemeCodeUnspsc190501.50393647'),
	'identifierSchemeCodeUnspsc190501.50393648': __('identifierSchemeCodeUnspsc190501.50393648'),
	'identifierSchemeCodeUnspsc190501.50393649': __('identifierSchemeCodeUnspsc190501.50393649'),
	'identifierSchemeCodeUnspsc190501.50393650': __('identifierSchemeCodeUnspsc190501.50393650'),
	'identifierSchemeCodeUnspsc190501.50393651': __('identifierSchemeCodeUnspsc190501.50393651'),
	'identifierSchemeCodeUnspsc190501.50393652': __('identifierSchemeCodeUnspsc190501.50393652'),
	'identifierSchemeCodeUnspsc190501.50393653': __('identifierSchemeCodeUnspsc190501.50393653'),
	'identifierSchemeCodeUnspsc190501.50393654': __('identifierSchemeCodeUnspsc190501.50393654'),
	'identifierSchemeCodeUnspsc190501.50393655': __('identifierSchemeCodeUnspsc190501.50393655'),
	'identifierSchemeCodeUnspsc190501.50393656': __('identifierSchemeCodeUnspsc190501.50393656'),
	'identifierSchemeCodeUnspsc190501.50393657': __('identifierSchemeCodeUnspsc190501.50393657'),
	'identifierSchemeCodeUnspsc190501.50393658': __('identifierSchemeCodeUnspsc190501.50393658'),
	'identifierSchemeCodeUnspsc190501.50393659': __('identifierSchemeCodeUnspsc190501.50393659'),
	'identifierSchemeCodeUnspsc190501.50393660': __('identifierSchemeCodeUnspsc190501.50393660'),
	'identifierSchemeCodeUnspsc190501.50393661': __('identifierSchemeCodeUnspsc190501.50393661'),
	'identifierSchemeCodeUnspsc190501.50393662': __('identifierSchemeCodeUnspsc190501.50393662'),
	'identifierSchemeCodeUnspsc190501.50393663': __('identifierSchemeCodeUnspsc190501.50393663'),
	'identifierSchemeCodeUnspsc190501.50393664': __('identifierSchemeCodeUnspsc190501.50393664'),
	'identifierSchemeCodeUnspsc190501.50393665': __('identifierSchemeCodeUnspsc190501.50393665'),
	'identifierSchemeCodeUnspsc190501.50393700': __('identifierSchemeCodeUnspsc190501.50393700'),
	'identifierSchemeCodeUnspsc190501.50393701': __('identifierSchemeCodeUnspsc190501.50393701'),
	'identifierSchemeCodeUnspsc190501.50393702': __('identifierSchemeCodeUnspsc190501.50393702'),
	'identifierSchemeCodeUnspsc190501.50393703': __('identifierSchemeCodeUnspsc190501.50393703'),
	'identifierSchemeCodeUnspsc190501.50393704': __('identifierSchemeCodeUnspsc190501.50393704'),
	'identifierSchemeCodeUnspsc190501.50393800': __('identifierSchemeCodeUnspsc190501.50393800'),
	'identifierSchemeCodeUnspsc190501.50393801': __('identifierSchemeCodeUnspsc190501.50393801'),
	'identifierSchemeCodeUnspsc190501.50393802': __('identifierSchemeCodeUnspsc190501.50393802'),
	'identifierSchemeCodeUnspsc190501.50393803': __('identifierSchemeCodeUnspsc190501.50393803'),
	'identifierSchemeCodeUnspsc190501.50393804': __('identifierSchemeCodeUnspsc190501.50393804'),
	'identifierSchemeCodeUnspsc190501.50393805': __('identifierSchemeCodeUnspsc190501.50393805'),
	'identifierSchemeCodeUnspsc190501.50393900': __('identifierSchemeCodeUnspsc190501.50393900'),
	'identifierSchemeCodeUnspsc190501.50393901': __('identifierSchemeCodeUnspsc190501.50393901'),
	'identifierSchemeCodeUnspsc190501.50393902': __('identifierSchemeCodeUnspsc190501.50393902'),
	'identifierSchemeCodeUnspsc190501.50393903': __('identifierSchemeCodeUnspsc190501.50393903'),
	'identifierSchemeCodeUnspsc190501.50393904': __('identifierSchemeCodeUnspsc190501.50393904'),
	'identifierSchemeCodeUnspsc190501.50393905': __('identifierSchemeCodeUnspsc190501.50393905'),
	'identifierSchemeCodeUnspsc190501.50393906': __('identifierSchemeCodeUnspsc190501.50393906'),
	'identifierSchemeCodeUnspsc190501.50394000': __('identifierSchemeCodeUnspsc190501.50394000'),
	'identifierSchemeCodeUnspsc190501.50394001': __('identifierSchemeCodeUnspsc190501.50394001'),
	'identifierSchemeCodeUnspsc190501.50394002': __('identifierSchemeCodeUnspsc190501.50394002'),
	'identifierSchemeCodeUnspsc190501.50394003': __('identifierSchemeCodeUnspsc190501.50394003'),
	'identifierSchemeCodeUnspsc190501.50394004': __('identifierSchemeCodeUnspsc190501.50394004'),
	'identifierSchemeCodeUnspsc190501.50394005': __('identifierSchemeCodeUnspsc190501.50394005'),
	'identifierSchemeCodeUnspsc190501.50394006': __('identifierSchemeCodeUnspsc190501.50394006'),
	'identifierSchemeCodeUnspsc190501.50394100': __('identifierSchemeCodeUnspsc190501.50394100'),
	'identifierSchemeCodeUnspsc190501.50394101': __('identifierSchemeCodeUnspsc190501.50394101'),
	'identifierSchemeCodeUnspsc190501.50394102': __('identifierSchemeCodeUnspsc190501.50394102'),
	'identifierSchemeCodeUnspsc190501.50394103': __('identifierSchemeCodeUnspsc190501.50394103'),
	'identifierSchemeCodeUnspsc190501.50394104': __('identifierSchemeCodeUnspsc190501.50394104'),
	'identifierSchemeCodeUnspsc190501.50394105': __('identifierSchemeCodeUnspsc190501.50394105'),
	'identifierSchemeCodeUnspsc190501.50394106': __('identifierSchemeCodeUnspsc190501.50394106'),
	'identifierSchemeCodeUnspsc190501.50394107': __('identifierSchemeCodeUnspsc190501.50394107'),
	'identifierSchemeCodeUnspsc190501.50394108': __('identifierSchemeCodeUnspsc190501.50394108'),
	'identifierSchemeCodeUnspsc190501.50394200': __('identifierSchemeCodeUnspsc190501.50394200'),
	'identifierSchemeCodeUnspsc190501.50394201': __('identifierSchemeCodeUnspsc190501.50394201'),
	'identifierSchemeCodeUnspsc190501.50394202': __('identifierSchemeCodeUnspsc190501.50394202'),
	'identifierSchemeCodeUnspsc190501.50394203': __('identifierSchemeCodeUnspsc190501.50394203'),
	'identifierSchemeCodeUnspsc190501.50394204': __('identifierSchemeCodeUnspsc190501.50394204'),
	'identifierSchemeCodeUnspsc190501.50394205': __('identifierSchemeCodeUnspsc190501.50394205'),
	'identifierSchemeCodeUnspsc190501.50394206': __('identifierSchemeCodeUnspsc190501.50394206'),
	'identifierSchemeCodeUnspsc190501.50394207': __('identifierSchemeCodeUnspsc190501.50394207'),
	'identifierSchemeCodeUnspsc190501.50394208': __('identifierSchemeCodeUnspsc190501.50394208'),
	'identifierSchemeCodeUnspsc190501.50394300': __('identifierSchemeCodeUnspsc190501.50394300'),
	'identifierSchemeCodeUnspsc190501.50394301': __('identifierSchemeCodeUnspsc190501.50394301'),
	'identifierSchemeCodeUnspsc190501.50394302': __('identifierSchemeCodeUnspsc190501.50394302'),
	'identifierSchemeCodeUnspsc190501.50394303': __('identifierSchemeCodeUnspsc190501.50394303'),
	'identifierSchemeCodeUnspsc190501.50394304': __('identifierSchemeCodeUnspsc190501.50394304'),
	'identifierSchemeCodeUnspsc190501.50394305': __('identifierSchemeCodeUnspsc190501.50394305'),
	'identifierSchemeCodeUnspsc190501.50394306': __('identifierSchemeCodeUnspsc190501.50394306'),
	'identifierSchemeCodeUnspsc190501.50394307': __('identifierSchemeCodeUnspsc190501.50394307'),
	'identifierSchemeCodeUnspsc190501.50394308': __('identifierSchemeCodeUnspsc190501.50394308'),
	'identifierSchemeCodeUnspsc190501.50394309': __('identifierSchemeCodeUnspsc190501.50394309'),
	'identifierSchemeCodeUnspsc190501.50394310': __('identifierSchemeCodeUnspsc190501.50394310'),
	'identifierSchemeCodeUnspsc190501.50394311': __('identifierSchemeCodeUnspsc190501.50394311'),
	'identifierSchemeCodeUnspsc190501.50394312': __('identifierSchemeCodeUnspsc190501.50394312'),
	'identifierSchemeCodeUnspsc190501.50394313': __('identifierSchemeCodeUnspsc190501.50394313'),
	'identifierSchemeCodeUnspsc190501.50394400': __('identifierSchemeCodeUnspsc190501.50394400'),
	'identifierSchemeCodeUnspsc190501.50394401': __('identifierSchemeCodeUnspsc190501.50394401'),
	'identifierSchemeCodeUnspsc190501.50394402': __('identifierSchemeCodeUnspsc190501.50394402'),
	'identifierSchemeCodeUnspsc190501.50394403': __('identifierSchemeCodeUnspsc190501.50394403'),
	'identifierSchemeCodeUnspsc190501.50394404': __('identifierSchemeCodeUnspsc190501.50394404'),
	'identifierSchemeCodeUnspsc190501.50394405': __('identifierSchemeCodeUnspsc190501.50394405'),
	'identifierSchemeCodeUnspsc190501.50394406': __('identifierSchemeCodeUnspsc190501.50394406'),
	'identifierSchemeCodeUnspsc190501.50394407': __('identifierSchemeCodeUnspsc190501.50394407'),
	'identifierSchemeCodeUnspsc190501.50394408': __('identifierSchemeCodeUnspsc190501.50394408'),
	'identifierSchemeCodeUnspsc190501.50394409': __('identifierSchemeCodeUnspsc190501.50394409'),
	'identifierSchemeCodeUnspsc190501.50394410': __('identifierSchemeCodeUnspsc190501.50394410'),
	'identifierSchemeCodeUnspsc190501.50394411': __('identifierSchemeCodeUnspsc190501.50394411'),
	'identifierSchemeCodeUnspsc190501.50394412': __('identifierSchemeCodeUnspsc190501.50394412'),
	'identifierSchemeCodeUnspsc190501.50394413': __('identifierSchemeCodeUnspsc190501.50394413'),
	'identifierSchemeCodeUnspsc190501.50394414': __('identifierSchemeCodeUnspsc190501.50394414'),
	'identifierSchemeCodeUnspsc190501.50394415': __('identifierSchemeCodeUnspsc190501.50394415'),
	'identifierSchemeCodeUnspsc190501.50394416': __('identifierSchemeCodeUnspsc190501.50394416'),
	'identifierSchemeCodeUnspsc190501.50394417': __('identifierSchemeCodeUnspsc190501.50394417'),
	'identifierSchemeCodeUnspsc190501.50394418': __('identifierSchemeCodeUnspsc190501.50394418'),
	'identifierSchemeCodeUnspsc190501.50394419': __('identifierSchemeCodeUnspsc190501.50394419'),
	'identifierSchemeCodeUnspsc190501.50394420': __('identifierSchemeCodeUnspsc190501.50394420'),
	'identifierSchemeCodeUnspsc190501.50394421': __('identifierSchemeCodeUnspsc190501.50394421'),
	'identifierSchemeCodeUnspsc190501.50394422': __('identifierSchemeCodeUnspsc190501.50394422'),
	'identifierSchemeCodeUnspsc190501.50394423': __('identifierSchemeCodeUnspsc190501.50394423'),
	'identifierSchemeCodeUnspsc190501.50394424': __('identifierSchemeCodeUnspsc190501.50394424'),
	'identifierSchemeCodeUnspsc190501.50394425': __('identifierSchemeCodeUnspsc190501.50394425'),
	'identifierSchemeCodeUnspsc190501.50394426': __('identifierSchemeCodeUnspsc190501.50394426'),
	'identifierSchemeCodeUnspsc190501.50394427': __('identifierSchemeCodeUnspsc190501.50394427'),
	'identifierSchemeCodeUnspsc190501.50394428': __('identifierSchemeCodeUnspsc190501.50394428'),
	'identifierSchemeCodeUnspsc190501.50394429': __('identifierSchemeCodeUnspsc190501.50394429'),
	'identifierSchemeCodeUnspsc190501.50394430': __('identifierSchemeCodeUnspsc190501.50394430'),
	'identifierSchemeCodeUnspsc190501.50394431': __('identifierSchemeCodeUnspsc190501.50394431'),
	'identifierSchemeCodeUnspsc190501.50394432': __('identifierSchemeCodeUnspsc190501.50394432'),
	'identifierSchemeCodeUnspsc190501.50394433': __('identifierSchemeCodeUnspsc190501.50394433'),
	'identifierSchemeCodeUnspsc190501.50394434': __('identifierSchemeCodeUnspsc190501.50394434'),
	'identifierSchemeCodeUnspsc190501.50394435': __('identifierSchemeCodeUnspsc190501.50394435'),
	'identifierSchemeCodeUnspsc190501.50394436': __('identifierSchemeCodeUnspsc190501.50394436'),
	'identifierSchemeCodeUnspsc190501.50394437': __('identifierSchemeCodeUnspsc190501.50394437'),
	'identifierSchemeCodeUnspsc190501.50394438': __('identifierSchemeCodeUnspsc190501.50394438'),
	'identifierSchemeCodeUnspsc190501.50394439': __('identifierSchemeCodeUnspsc190501.50394439'),
	'identifierSchemeCodeUnspsc190501.50394500': __('identifierSchemeCodeUnspsc190501.50394500'),
	'identifierSchemeCodeUnspsc190501.50394501': __('identifierSchemeCodeUnspsc190501.50394501'),
	'identifierSchemeCodeUnspsc190501.50394502': __('identifierSchemeCodeUnspsc190501.50394502'),
	'identifierSchemeCodeUnspsc190501.50394503': __('identifierSchemeCodeUnspsc190501.50394503'),
	'identifierSchemeCodeUnspsc190501.50394504': __('identifierSchemeCodeUnspsc190501.50394504'),
	'identifierSchemeCodeUnspsc190501.50394505': __('identifierSchemeCodeUnspsc190501.50394505'),
	'identifierSchemeCodeUnspsc190501.50394506': __('identifierSchemeCodeUnspsc190501.50394506'),
	'identifierSchemeCodeUnspsc190501.50394600': __('identifierSchemeCodeUnspsc190501.50394600'),
	'identifierSchemeCodeUnspsc190501.50394601': __('identifierSchemeCodeUnspsc190501.50394601'),
	'identifierSchemeCodeUnspsc190501.50394602': __('identifierSchemeCodeUnspsc190501.50394602'),
	'identifierSchemeCodeUnspsc190501.50394603': __('identifierSchemeCodeUnspsc190501.50394603'),
	'identifierSchemeCodeUnspsc190501.50394604': __('identifierSchemeCodeUnspsc190501.50394604'),
	'identifierSchemeCodeUnspsc190501.50394605': __('identifierSchemeCodeUnspsc190501.50394605'),
	'identifierSchemeCodeUnspsc190501.50394606': __('identifierSchemeCodeUnspsc190501.50394606'),
	'identifierSchemeCodeUnspsc190501.50394607': __('identifierSchemeCodeUnspsc190501.50394607'),
	'identifierSchemeCodeUnspsc190501.50394608': __('identifierSchemeCodeUnspsc190501.50394608'),
	'identifierSchemeCodeUnspsc190501.50394609': __('identifierSchemeCodeUnspsc190501.50394609'),
	'identifierSchemeCodeUnspsc190501.50394610': __('identifierSchemeCodeUnspsc190501.50394610'),
	'identifierSchemeCodeUnspsc190501.50394611': __('identifierSchemeCodeUnspsc190501.50394611'),
	'identifierSchemeCodeUnspsc190501.50394612': __('identifierSchemeCodeUnspsc190501.50394612'),
	'identifierSchemeCodeUnspsc190501.50394613': __('identifierSchemeCodeUnspsc190501.50394613'),
	'identifierSchemeCodeUnspsc190501.50394614': __('identifierSchemeCodeUnspsc190501.50394614'),
	'identifierSchemeCodeUnspsc190501.50394615': __('identifierSchemeCodeUnspsc190501.50394615'),
	'identifierSchemeCodeUnspsc190501.50394616': __('identifierSchemeCodeUnspsc190501.50394616'),
	'identifierSchemeCodeUnspsc190501.50394617': __('identifierSchemeCodeUnspsc190501.50394617'),
	'identifierSchemeCodeUnspsc190501.50394618': __('identifierSchemeCodeUnspsc190501.50394618'),
	'identifierSchemeCodeUnspsc190501.50394619': __('identifierSchemeCodeUnspsc190501.50394619'),
	'identifierSchemeCodeUnspsc190501.50394620': __('identifierSchemeCodeUnspsc190501.50394620'),
	'identifierSchemeCodeUnspsc190501.50394621': __('identifierSchemeCodeUnspsc190501.50394621'),
	'identifierSchemeCodeUnspsc190501.50394622': __('identifierSchemeCodeUnspsc190501.50394622'),
	'identifierSchemeCodeUnspsc190501.50394623': __('identifierSchemeCodeUnspsc190501.50394623'),
	'identifierSchemeCodeUnspsc190501.50394624': __('identifierSchemeCodeUnspsc190501.50394624'),
	'identifierSchemeCodeUnspsc190501.50394625': __('identifierSchemeCodeUnspsc190501.50394625'),
	'identifierSchemeCodeUnspsc190501.50394700': __('identifierSchemeCodeUnspsc190501.50394700'),
	'identifierSchemeCodeUnspsc190501.50394701': __('identifierSchemeCodeUnspsc190501.50394701'),
	'identifierSchemeCodeUnspsc190501.50394702': __('identifierSchemeCodeUnspsc190501.50394702'),
	'identifierSchemeCodeUnspsc190501.50394703': __('identifierSchemeCodeUnspsc190501.50394703'),
	'identifierSchemeCodeUnspsc190501.50394704': __('identifierSchemeCodeUnspsc190501.50394704'),
	'identifierSchemeCodeUnspsc190501.50394705': __('identifierSchemeCodeUnspsc190501.50394705'),
	'identifierSchemeCodeUnspsc190501.50394706': __('identifierSchemeCodeUnspsc190501.50394706'),
	'identifierSchemeCodeUnspsc190501.50394707': __('identifierSchemeCodeUnspsc190501.50394707'),
	'identifierSchemeCodeUnspsc190501.50394708': __('identifierSchemeCodeUnspsc190501.50394708'),
	'identifierSchemeCodeUnspsc190501.50394709': __('identifierSchemeCodeUnspsc190501.50394709'),
	'identifierSchemeCodeUnspsc190501.50394710': __('identifierSchemeCodeUnspsc190501.50394710'),
	'identifierSchemeCodeUnspsc190501.50394711': __('identifierSchemeCodeUnspsc190501.50394711'),
	'identifierSchemeCodeUnspsc190501.50394712': __('identifierSchemeCodeUnspsc190501.50394712'),
	'identifierSchemeCodeUnspsc190501.50394713': __('identifierSchemeCodeUnspsc190501.50394713'),
	'identifierSchemeCodeUnspsc190501.50394714': __('identifierSchemeCodeUnspsc190501.50394714'),
	'identifierSchemeCodeUnspsc190501.50394715': __('identifierSchemeCodeUnspsc190501.50394715'),
	'identifierSchemeCodeUnspsc190501.50394716': __('identifierSchemeCodeUnspsc190501.50394716'),
	'identifierSchemeCodeUnspsc190501.50394717': __('identifierSchemeCodeUnspsc190501.50394717'),
	'identifierSchemeCodeUnspsc190501.50394718': __('identifierSchemeCodeUnspsc190501.50394718'),
	'identifierSchemeCodeUnspsc190501.50394719': __('identifierSchemeCodeUnspsc190501.50394719'),
	'identifierSchemeCodeUnspsc190501.50394720': __('identifierSchemeCodeUnspsc190501.50394720'),
	'identifierSchemeCodeUnspsc190501.50394721': __('identifierSchemeCodeUnspsc190501.50394721'),
	'identifierSchemeCodeUnspsc190501.50394722': __('identifierSchemeCodeUnspsc190501.50394722'),
	'identifierSchemeCodeUnspsc190501.50394723': __('identifierSchemeCodeUnspsc190501.50394723'),
	'identifierSchemeCodeUnspsc190501.50394724': __('identifierSchemeCodeUnspsc190501.50394724'),
	'identifierSchemeCodeUnspsc190501.50394725': __('identifierSchemeCodeUnspsc190501.50394725'),
	'identifierSchemeCodeUnspsc190501.50394726': __('identifierSchemeCodeUnspsc190501.50394726'),
	'identifierSchemeCodeUnspsc190501.50394727': __('identifierSchemeCodeUnspsc190501.50394727'),
	'identifierSchemeCodeUnspsc190501.50394728': __('identifierSchemeCodeUnspsc190501.50394728'),
	'identifierSchemeCodeUnspsc190501.50394729': __('identifierSchemeCodeUnspsc190501.50394729'),
	'identifierSchemeCodeUnspsc190501.50394730': __('identifierSchemeCodeUnspsc190501.50394730'),
	'identifierSchemeCodeUnspsc190501.50394731': __('identifierSchemeCodeUnspsc190501.50394731'),
	'identifierSchemeCodeUnspsc190501.50394732': __('identifierSchemeCodeUnspsc190501.50394732'),
	'identifierSchemeCodeUnspsc190501.50394733': __('identifierSchemeCodeUnspsc190501.50394733'),
	'identifierSchemeCodeUnspsc190501.50394734': __('identifierSchemeCodeUnspsc190501.50394734'),
	'identifierSchemeCodeUnspsc190501.50394735': __('identifierSchemeCodeUnspsc190501.50394735'),
	'identifierSchemeCodeUnspsc190501.50394736': __('identifierSchemeCodeUnspsc190501.50394736'),
	'identifierSchemeCodeUnspsc190501.50394737': __('identifierSchemeCodeUnspsc190501.50394737'),
	'identifierSchemeCodeUnspsc190501.50394738': __('identifierSchemeCodeUnspsc190501.50394738'),
	'identifierSchemeCodeUnspsc190501.50394739': __('identifierSchemeCodeUnspsc190501.50394739'),
	'identifierSchemeCodeUnspsc190501.50394740': __('identifierSchemeCodeUnspsc190501.50394740'),
	'identifierSchemeCodeUnspsc190501.50394741': __('identifierSchemeCodeUnspsc190501.50394741'),
	'identifierSchemeCodeUnspsc190501.50394742': __('identifierSchemeCodeUnspsc190501.50394742'),
	'identifierSchemeCodeUnspsc190501.50394743': __('identifierSchemeCodeUnspsc190501.50394743'),
	'identifierSchemeCodeUnspsc190501.50394744': __('identifierSchemeCodeUnspsc190501.50394744'),
	'identifierSchemeCodeUnspsc190501.50394745': __('identifierSchemeCodeUnspsc190501.50394745'),
	'identifierSchemeCodeUnspsc190501.50394746': __('identifierSchemeCodeUnspsc190501.50394746'),
	'identifierSchemeCodeUnspsc190501.50394747': __('identifierSchemeCodeUnspsc190501.50394747'),
	'identifierSchemeCodeUnspsc190501.50394748': __('identifierSchemeCodeUnspsc190501.50394748'),
	'identifierSchemeCodeUnspsc190501.50394749': __('identifierSchemeCodeUnspsc190501.50394749'),
	'identifierSchemeCodeUnspsc190501.50394750': __('identifierSchemeCodeUnspsc190501.50394750'),
	'identifierSchemeCodeUnspsc190501.50394751': __('identifierSchemeCodeUnspsc190501.50394751'),
	'identifierSchemeCodeUnspsc190501.50394752': __('identifierSchemeCodeUnspsc190501.50394752'),
	'identifierSchemeCodeUnspsc190501.50394753': __('identifierSchemeCodeUnspsc190501.50394753'),
	'identifierSchemeCodeUnspsc190501.50394754': __('identifierSchemeCodeUnspsc190501.50394754'),
	'identifierSchemeCodeUnspsc190501.50394755': __('identifierSchemeCodeUnspsc190501.50394755'),
	'identifierSchemeCodeUnspsc190501.50394756': __('identifierSchemeCodeUnspsc190501.50394756'),
	'identifierSchemeCodeUnspsc190501.50394757': __('identifierSchemeCodeUnspsc190501.50394757'),
	'identifierSchemeCodeUnspsc190501.50394758': __('identifierSchemeCodeUnspsc190501.50394758'),
	'identifierSchemeCodeUnspsc190501.50394759': __('identifierSchemeCodeUnspsc190501.50394759'),
	'identifierSchemeCodeUnspsc190501.50394760': __('identifierSchemeCodeUnspsc190501.50394760'),
	'identifierSchemeCodeUnspsc190501.50394761': __('identifierSchemeCodeUnspsc190501.50394761'),
	'identifierSchemeCodeUnspsc190501.50394800': __('identifierSchemeCodeUnspsc190501.50394800'),
	'identifierSchemeCodeUnspsc190501.50394801': __('identifierSchemeCodeUnspsc190501.50394801'),
	'identifierSchemeCodeUnspsc190501.50394802': __('identifierSchemeCodeUnspsc190501.50394802'),
	'identifierSchemeCodeUnspsc190501.50394900': __('identifierSchemeCodeUnspsc190501.50394900'),
	'identifierSchemeCodeUnspsc190501.50394901': __('identifierSchemeCodeUnspsc190501.50394901'),
	'identifierSchemeCodeUnspsc190501.50394902': __('identifierSchemeCodeUnspsc190501.50394902'),
	'identifierSchemeCodeUnspsc190501.50395000': __('identifierSchemeCodeUnspsc190501.50395000'),
	'identifierSchemeCodeUnspsc190501.50395001': __('identifierSchemeCodeUnspsc190501.50395001'),
	'identifierSchemeCodeUnspsc190501.50395002': __('identifierSchemeCodeUnspsc190501.50395002'),
	'identifierSchemeCodeUnspsc190501.50395003': __('identifierSchemeCodeUnspsc190501.50395003'),
	'identifierSchemeCodeUnspsc190501.50395004': __('identifierSchemeCodeUnspsc190501.50395004'),
	'identifierSchemeCodeUnspsc190501.50395005': __('identifierSchemeCodeUnspsc190501.50395005'),
	'identifierSchemeCodeUnspsc190501.50395006': __('identifierSchemeCodeUnspsc190501.50395006'),
	'identifierSchemeCodeUnspsc190501.50395007': __('identifierSchemeCodeUnspsc190501.50395007'),
	'identifierSchemeCodeUnspsc190501.50395008': __('identifierSchemeCodeUnspsc190501.50395008'),
	'identifierSchemeCodeUnspsc190501.50395009': __('identifierSchemeCodeUnspsc190501.50395009'),
	'identifierSchemeCodeUnspsc190501.50395010': __('identifierSchemeCodeUnspsc190501.50395010'),
	'identifierSchemeCodeUnspsc190501.50395011': __('identifierSchemeCodeUnspsc190501.50395011'),
	'identifierSchemeCodeUnspsc190501.50395012': __('identifierSchemeCodeUnspsc190501.50395012'),
	'identifierSchemeCodeUnspsc190501.50395013': __('identifierSchemeCodeUnspsc190501.50395013'),
	'identifierSchemeCodeUnspsc190501.50395014': __('identifierSchemeCodeUnspsc190501.50395014'),
	'identifierSchemeCodeUnspsc190501.50395015': __('identifierSchemeCodeUnspsc190501.50395015'),
	'identifierSchemeCodeUnspsc190501.50395016': __('identifierSchemeCodeUnspsc190501.50395016'),
	'identifierSchemeCodeUnspsc190501.50395017': __('identifierSchemeCodeUnspsc190501.50395017'),
	'identifierSchemeCodeUnspsc190501.50395018': __('identifierSchemeCodeUnspsc190501.50395018'),
	'identifierSchemeCodeUnspsc190501.50395019': __('identifierSchemeCodeUnspsc190501.50395019'),
	'identifierSchemeCodeUnspsc190501.50395020': __('identifierSchemeCodeUnspsc190501.50395020'),
	'identifierSchemeCodeUnspsc190501.50395021': __('identifierSchemeCodeUnspsc190501.50395021'),
	'identifierSchemeCodeUnspsc190501.50395022': __('identifierSchemeCodeUnspsc190501.50395022'),
	'identifierSchemeCodeUnspsc190501.50395023': __('identifierSchemeCodeUnspsc190501.50395023'),
	'identifierSchemeCodeUnspsc190501.50395024': __('identifierSchemeCodeUnspsc190501.50395024'),
	'identifierSchemeCodeUnspsc190501.50395025': __('identifierSchemeCodeUnspsc190501.50395025'),
	'identifierSchemeCodeUnspsc190501.50395026': __('identifierSchemeCodeUnspsc190501.50395026'),
	'identifierSchemeCodeUnspsc190501.50395027': __('identifierSchemeCodeUnspsc190501.50395027'),
	'identifierSchemeCodeUnspsc190501.50395028': __('identifierSchemeCodeUnspsc190501.50395028'),
	'identifierSchemeCodeUnspsc190501.50395029': __('identifierSchemeCodeUnspsc190501.50395029'),
	'identifierSchemeCodeUnspsc190501.50395030': __('identifierSchemeCodeUnspsc190501.50395030'),
	'identifierSchemeCodeUnspsc190501.50395031': __('identifierSchemeCodeUnspsc190501.50395031'),
	'identifierSchemeCodeUnspsc190501.50395032': __('identifierSchemeCodeUnspsc190501.50395032'),
	'identifierSchemeCodeUnspsc190501.50395033': __('identifierSchemeCodeUnspsc190501.50395033'),
	'identifierSchemeCodeUnspsc190501.50395034': __('identifierSchemeCodeUnspsc190501.50395034'),
	'identifierSchemeCodeUnspsc190501.50395035': __('identifierSchemeCodeUnspsc190501.50395035'),
	'identifierSchemeCodeUnspsc190501.50395036': __('identifierSchemeCodeUnspsc190501.50395036'),
	'identifierSchemeCodeUnspsc190501.50395037': __('identifierSchemeCodeUnspsc190501.50395037'),
	'identifierSchemeCodeUnspsc190501.50395038': __('identifierSchemeCodeUnspsc190501.50395038'),
	'identifierSchemeCodeUnspsc190501.50395039': __('identifierSchemeCodeUnspsc190501.50395039'),
	'identifierSchemeCodeUnspsc190501.50395040': __('identifierSchemeCodeUnspsc190501.50395040'),
	'identifierSchemeCodeUnspsc190501.50395041': __('identifierSchemeCodeUnspsc190501.50395041'),
	'identifierSchemeCodeUnspsc190501.50395042': __('identifierSchemeCodeUnspsc190501.50395042'),
	'identifierSchemeCodeUnspsc190501.50395043': __('identifierSchemeCodeUnspsc190501.50395043'),
	'identifierSchemeCodeUnspsc190501.50395044': __('identifierSchemeCodeUnspsc190501.50395044'),
	'identifierSchemeCodeUnspsc190501.50395045': __('identifierSchemeCodeUnspsc190501.50395045'),
	'identifierSchemeCodeUnspsc190501.50395046': __('identifierSchemeCodeUnspsc190501.50395046'),
	'identifierSchemeCodeUnspsc190501.50395047': __('identifierSchemeCodeUnspsc190501.50395047'),
	'identifierSchemeCodeUnspsc190501.50395048': __('identifierSchemeCodeUnspsc190501.50395048'),
	'identifierSchemeCodeUnspsc190501.50395049': __('identifierSchemeCodeUnspsc190501.50395049'),
	'identifierSchemeCodeUnspsc190501.50395050': __('identifierSchemeCodeUnspsc190501.50395050'),
	'identifierSchemeCodeUnspsc190501.50395051': __('identifierSchemeCodeUnspsc190501.50395051'),
	'identifierSchemeCodeUnspsc190501.50395052': __('identifierSchemeCodeUnspsc190501.50395052'),
	'identifierSchemeCodeUnspsc190501.50395053': __('identifierSchemeCodeUnspsc190501.50395053'),
	'identifierSchemeCodeUnspsc190501.50395054': __('identifierSchemeCodeUnspsc190501.50395054'),
	'identifierSchemeCodeUnspsc190501.50395055': __('identifierSchemeCodeUnspsc190501.50395055'),
	'identifierSchemeCodeUnspsc190501.50395056': __('identifierSchemeCodeUnspsc190501.50395056'),
	'identifierSchemeCodeUnspsc190501.50395057': __('identifierSchemeCodeUnspsc190501.50395057'),
	'identifierSchemeCodeUnspsc190501.50395100': __('identifierSchemeCodeUnspsc190501.50395100'),
	'identifierSchemeCodeUnspsc190501.50395101': __('identifierSchemeCodeUnspsc190501.50395101'),
	'identifierSchemeCodeUnspsc190501.50395102': __('identifierSchemeCodeUnspsc190501.50395102'),
	'identifierSchemeCodeUnspsc190501.50395103': __('identifierSchemeCodeUnspsc190501.50395103'),
	'identifierSchemeCodeUnspsc190501.50395104': __('identifierSchemeCodeUnspsc190501.50395104'),
	'identifierSchemeCodeUnspsc190501.50395105': __('identifierSchemeCodeUnspsc190501.50395105'),
	'identifierSchemeCodeUnspsc190501.50395106': __('identifierSchemeCodeUnspsc190501.50395106'),
	'identifierSchemeCodeUnspsc190501.50395107': __('identifierSchemeCodeUnspsc190501.50395107'),
	'identifierSchemeCodeUnspsc190501.50395108': __('identifierSchemeCodeUnspsc190501.50395108'),
	'identifierSchemeCodeUnspsc190501.50395109': __('identifierSchemeCodeUnspsc190501.50395109'),
	'identifierSchemeCodeUnspsc190501.50395110': __('identifierSchemeCodeUnspsc190501.50395110'),
	'identifierSchemeCodeUnspsc190501.50395111': __('identifierSchemeCodeUnspsc190501.50395111'),
	'identifierSchemeCodeUnspsc190501.50395112': __('identifierSchemeCodeUnspsc190501.50395112'),
	'identifierSchemeCodeUnspsc190501.50395113': __('identifierSchemeCodeUnspsc190501.50395113'),
	'identifierSchemeCodeUnspsc190501.50395114': __('identifierSchemeCodeUnspsc190501.50395114'),
	'identifierSchemeCodeUnspsc190501.50395115': __('identifierSchemeCodeUnspsc190501.50395115'),
	'identifierSchemeCodeUnspsc190501.50395116': __('identifierSchemeCodeUnspsc190501.50395116'),
	'identifierSchemeCodeUnspsc190501.50395117': __('identifierSchemeCodeUnspsc190501.50395117'),
	'identifierSchemeCodeUnspsc190501.50395118': __('identifierSchemeCodeUnspsc190501.50395118'),
	'identifierSchemeCodeUnspsc190501.50395119': __('identifierSchemeCodeUnspsc190501.50395119'),
	'identifierSchemeCodeUnspsc190501.50395120': __('identifierSchemeCodeUnspsc190501.50395120'),
	'identifierSchemeCodeUnspsc190501.50395121': __('identifierSchemeCodeUnspsc190501.50395121'),
	'identifierSchemeCodeUnspsc190501.50395122': __('identifierSchemeCodeUnspsc190501.50395122'),
	'identifierSchemeCodeUnspsc190501.50395123': __('identifierSchemeCodeUnspsc190501.50395123'),
	'identifierSchemeCodeUnspsc190501.50395124': __('identifierSchemeCodeUnspsc190501.50395124'),
	'identifierSchemeCodeUnspsc190501.50395125': __('identifierSchemeCodeUnspsc190501.50395125'),
	'identifierSchemeCodeUnspsc190501.50395126': __('identifierSchemeCodeUnspsc190501.50395126'),
	'identifierSchemeCodeUnspsc190501.50395127': __('identifierSchemeCodeUnspsc190501.50395127'),
	'identifierSchemeCodeUnspsc190501.50395128': __('identifierSchemeCodeUnspsc190501.50395128'),
	'identifierSchemeCodeUnspsc190501.50395129': __('identifierSchemeCodeUnspsc190501.50395129'),
	'identifierSchemeCodeUnspsc190501.50395130': __('identifierSchemeCodeUnspsc190501.50395130'),
	'identifierSchemeCodeUnspsc190501.50395131': __('identifierSchemeCodeUnspsc190501.50395131'),
	'identifierSchemeCodeUnspsc190501.50395132': __('identifierSchemeCodeUnspsc190501.50395132'),
	'identifierSchemeCodeUnspsc190501.50395133': __('identifierSchemeCodeUnspsc190501.50395133'),
	'identifierSchemeCodeUnspsc190501.50395134': __('identifierSchemeCodeUnspsc190501.50395134'),
	'identifierSchemeCodeUnspsc190501.50395135': __('identifierSchemeCodeUnspsc190501.50395135'),
	'identifierSchemeCodeUnspsc190501.50395136': __('identifierSchemeCodeUnspsc190501.50395136'),
	'identifierSchemeCodeUnspsc190501.50395137': __('identifierSchemeCodeUnspsc190501.50395137'),
	'identifierSchemeCodeUnspsc190501.50395138': __('identifierSchemeCodeUnspsc190501.50395138'),
	'identifierSchemeCodeUnspsc190501.50395139': __('identifierSchemeCodeUnspsc190501.50395139'),
	'identifierSchemeCodeUnspsc190501.50395140': __('identifierSchemeCodeUnspsc190501.50395140'),
	'identifierSchemeCodeUnspsc190501.50395141': __('identifierSchemeCodeUnspsc190501.50395141'),
	'identifierSchemeCodeUnspsc190501.50395142': __('identifierSchemeCodeUnspsc190501.50395142'),
	'identifierSchemeCodeUnspsc190501.50395143': __('identifierSchemeCodeUnspsc190501.50395143'),
	'identifierSchemeCodeUnspsc190501.50395144': __('identifierSchemeCodeUnspsc190501.50395144'),
	'identifierSchemeCodeUnspsc190501.50395200': __('identifierSchemeCodeUnspsc190501.50395200'),
	'identifierSchemeCodeUnspsc190501.50395201': __('identifierSchemeCodeUnspsc190501.50395201'),
	'identifierSchemeCodeUnspsc190501.50395202': __('identifierSchemeCodeUnspsc190501.50395202'),
	'identifierSchemeCodeUnspsc190501.50395203': __('identifierSchemeCodeUnspsc190501.50395203'),
	'identifierSchemeCodeUnspsc190501.50395204': __('identifierSchemeCodeUnspsc190501.50395204'),
	'identifierSchemeCodeUnspsc190501.50395205': __('identifierSchemeCodeUnspsc190501.50395205'),
	'identifierSchemeCodeUnspsc190501.50395206': __('identifierSchemeCodeUnspsc190501.50395206'),
	'identifierSchemeCodeUnspsc190501.50395300': __('identifierSchemeCodeUnspsc190501.50395300'),
	'identifierSchemeCodeUnspsc190501.50395301': __('identifierSchemeCodeUnspsc190501.50395301'),
	'identifierSchemeCodeUnspsc190501.50395302': __('identifierSchemeCodeUnspsc190501.50395302'),
	'identifierSchemeCodeUnspsc190501.50395303': __('identifierSchemeCodeUnspsc190501.50395303'),
	'identifierSchemeCodeUnspsc190501.50395304': __('identifierSchemeCodeUnspsc190501.50395304'),
	'identifierSchemeCodeUnspsc190501.50395305': __('identifierSchemeCodeUnspsc190501.50395305'),
	'identifierSchemeCodeUnspsc190501.50395306': __('identifierSchemeCodeUnspsc190501.50395306'),
	'identifierSchemeCodeUnspsc190501.50395307': __('identifierSchemeCodeUnspsc190501.50395307'),
	'identifierSchemeCodeUnspsc190501.50395308': __('identifierSchemeCodeUnspsc190501.50395308'),
	'identifierSchemeCodeUnspsc190501.50395309': __('identifierSchemeCodeUnspsc190501.50395309'),
	'identifierSchemeCodeUnspsc190501.50395310': __('identifierSchemeCodeUnspsc190501.50395310'),
	'identifierSchemeCodeUnspsc190501.50395400': __('identifierSchemeCodeUnspsc190501.50395400'),
	'identifierSchemeCodeUnspsc190501.50395401': __('identifierSchemeCodeUnspsc190501.50395401'),
	'identifierSchemeCodeUnspsc190501.50395402': __('identifierSchemeCodeUnspsc190501.50395402'),
	'identifierSchemeCodeUnspsc190501.50395403': __('identifierSchemeCodeUnspsc190501.50395403'),
	'identifierSchemeCodeUnspsc190501.50395404': __('identifierSchemeCodeUnspsc190501.50395404'),
	'identifierSchemeCodeUnspsc190501.50395405': __('identifierSchemeCodeUnspsc190501.50395405'),
	'identifierSchemeCodeUnspsc190501.50395406': __('identifierSchemeCodeUnspsc190501.50395406'),
	'identifierSchemeCodeUnspsc190501.50395407': __('identifierSchemeCodeUnspsc190501.50395407'),
	'identifierSchemeCodeUnspsc190501.50395408': __('identifierSchemeCodeUnspsc190501.50395408'),
	'identifierSchemeCodeUnspsc190501.50395409': __('identifierSchemeCodeUnspsc190501.50395409'),
	'identifierSchemeCodeUnspsc190501.50395410': __('identifierSchemeCodeUnspsc190501.50395410'),
	'identifierSchemeCodeUnspsc190501.50395411': __('identifierSchemeCodeUnspsc190501.50395411'),
	'identifierSchemeCodeUnspsc190501.50395412': __('identifierSchemeCodeUnspsc190501.50395412'),
	'identifierSchemeCodeUnspsc190501.50395413': __('identifierSchemeCodeUnspsc190501.50395413'),
	'identifierSchemeCodeUnspsc190501.50395414': __('identifierSchemeCodeUnspsc190501.50395414'),
	'identifierSchemeCodeUnspsc190501.50395415': __('identifierSchemeCodeUnspsc190501.50395415'),
	'identifierSchemeCodeUnspsc190501.50395416': __('identifierSchemeCodeUnspsc190501.50395416'),
	'identifierSchemeCodeUnspsc190501.50395417': __('identifierSchemeCodeUnspsc190501.50395417'),
	'identifierSchemeCodeUnspsc190501.50395418': __('identifierSchemeCodeUnspsc190501.50395418'),
	'identifierSchemeCodeUnspsc190501.50395419': __('identifierSchemeCodeUnspsc190501.50395419'),
	'identifierSchemeCodeUnspsc190501.50395420': __('identifierSchemeCodeUnspsc190501.50395420'),
	'identifierSchemeCodeUnspsc190501.50395421': __('identifierSchemeCodeUnspsc190501.50395421'),
	'identifierSchemeCodeUnspsc190501.50395422': __('identifierSchemeCodeUnspsc190501.50395422'),
	'identifierSchemeCodeUnspsc190501.50395423': __('identifierSchemeCodeUnspsc190501.50395423'),
	'identifierSchemeCodeUnspsc190501.50395424': __('identifierSchemeCodeUnspsc190501.50395424'),
	'identifierSchemeCodeUnspsc190501.50395425': __('identifierSchemeCodeUnspsc190501.50395425'),
	'identifierSchemeCodeUnspsc190501.50395426': __('identifierSchemeCodeUnspsc190501.50395426'),
	'identifierSchemeCodeUnspsc190501.50395427': __('identifierSchemeCodeUnspsc190501.50395427'),
	'identifierSchemeCodeUnspsc190501.50395428': __('identifierSchemeCodeUnspsc190501.50395428'),
	'identifierSchemeCodeUnspsc190501.50395429': __('identifierSchemeCodeUnspsc190501.50395429'),
	'identifierSchemeCodeUnspsc190501.50395430': __('identifierSchemeCodeUnspsc190501.50395430'),
	'identifierSchemeCodeUnspsc190501.50395431': __('identifierSchemeCodeUnspsc190501.50395431'),
	'identifierSchemeCodeUnspsc190501.50395432': __('identifierSchemeCodeUnspsc190501.50395432'),
	'identifierSchemeCodeUnspsc190501.50395433': __('identifierSchemeCodeUnspsc190501.50395433'),
	'identifierSchemeCodeUnspsc190501.50395434': __('identifierSchemeCodeUnspsc190501.50395434'),
	'identifierSchemeCodeUnspsc190501.50395435': __('identifierSchemeCodeUnspsc190501.50395435'),
	'identifierSchemeCodeUnspsc190501.50395436': __('identifierSchemeCodeUnspsc190501.50395436'),
	'identifierSchemeCodeUnspsc190501.50395437': __('identifierSchemeCodeUnspsc190501.50395437'),
	'identifierSchemeCodeUnspsc190501.50395438': __('identifierSchemeCodeUnspsc190501.50395438'),
	'identifierSchemeCodeUnspsc190501.50395439': __('identifierSchemeCodeUnspsc190501.50395439'),
	'identifierSchemeCodeUnspsc190501.50395440': __('identifierSchemeCodeUnspsc190501.50395440'),
	'identifierSchemeCodeUnspsc190501.50395441': __('identifierSchemeCodeUnspsc190501.50395441'),
	'identifierSchemeCodeUnspsc190501.50395442': __('identifierSchemeCodeUnspsc190501.50395442'),
	'identifierSchemeCodeUnspsc190501.50395443': __('identifierSchemeCodeUnspsc190501.50395443'),
	'identifierSchemeCodeUnspsc190501.50395444': __('identifierSchemeCodeUnspsc190501.50395444'),
	'identifierSchemeCodeUnspsc190501.50395445': __('identifierSchemeCodeUnspsc190501.50395445'),
	'identifierSchemeCodeUnspsc190501.50395446': __('identifierSchemeCodeUnspsc190501.50395446'),
	'identifierSchemeCodeUnspsc190501.50395447': __('identifierSchemeCodeUnspsc190501.50395447'),
	'identifierSchemeCodeUnspsc190501.50395448': __('identifierSchemeCodeUnspsc190501.50395448'),
	'identifierSchemeCodeUnspsc190501.50395449': __('identifierSchemeCodeUnspsc190501.50395449'),
	'identifierSchemeCodeUnspsc190501.50395450': __('identifierSchemeCodeUnspsc190501.50395450'),
	'identifierSchemeCodeUnspsc190501.50395451': __('identifierSchemeCodeUnspsc190501.50395451'),
	'identifierSchemeCodeUnspsc190501.50395452': __('identifierSchemeCodeUnspsc190501.50395452'),
	'identifierSchemeCodeUnspsc190501.50395453': __('identifierSchemeCodeUnspsc190501.50395453'),
	'identifierSchemeCodeUnspsc190501.50395454': __('identifierSchemeCodeUnspsc190501.50395454'),
	'identifierSchemeCodeUnspsc190501.50395455': __('identifierSchemeCodeUnspsc190501.50395455'),
	'identifierSchemeCodeUnspsc190501.50395456': __('identifierSchemeCodeUnspsc190501.50395456'),
	'identifierSchemeCodeUnspsc190501.50395457': __('identifierSchemeCodeUnspsc190501.50395457'),
	'identifierSchemeCodeUnspsc190501.50395458': __('identifierSchemeCodeUnspsc190501.50395458'),
	'identifierSchemeCodeUnspsc190501.50395459': __('identifierSchemeCodeUnspsc190501.50395459'),
	'identifierSchemeCodeUnspsc190501.50395460': __('identifierSchemeCodeUnspsc190501.50395460'),
	'identifierSchemeCodeUnspsc190501.50395461': __('identifierSchemeCodeUnspsc190501.50395461'),
	'identifierSchemeCodeUnspsc190501.50395462': __('identifierSchemeCodeUnspsc190501.50395462'),
	'identifierSchemeCodeUnspsc190501.50395463': __('identifierSchemeCodeUnspsc190501.50395463'),
	'identifierSchemeCodeUnspsc190501.50395464': __('identifierSchemeCodeUnspsc190501.50395464'),
	'identifierSchemeCodeUnspsc190501.50395465': __('identifierSchemeCodeUnspsc190501.50395465'),
	'identifierSchemeCodeUnspsc190501.50395466': __('identifierSchemeCodeUnspsc190501.50395466'),
	'identifierSchemeCodeUnspsc190501.50395467': __('identifierSchemeCodeUnspsc190501.50395467'),
	'identifierSchemeCodeUnspsc190501.50395468': __('identifierSchemeCodeUnspsc190501.50395468'),
	'identifierSchemeCodeUnspsc190501.50395469': __('identifierSchemeCodeUnspsc190501.50395469'),
	'identifierSchemeCodeUnspsc190501.50395470': __('identifierSchemeCodeUnspsc190501.50395470'),
	'identifierSchemeCodeUnspsc190501.50395471': __('identifierSchemeCodeUnspsc190501.50395471'),
	'identifierSchemeCodeUnspsc190501.50395500': __('identifierSchemeCodeUnspsc190501.50395500'),
	'identifierSchemeCodeUnspsc190501.50395501': __('identifierSchemeCodeUnspsc190501.50395501'),
	'identifierSchemeCodeUnspsc190501.50395502': __('identifierSchemeCodeUnspsc190501.50395502'),
	'identifierSchemeCodeUnspsc190501.50395503': __('identifierSchemeCodeUnspsc190501.50395503'),
	'identifierSchemeCodeUnspsc190501.50395504': __('identifierSchemeCodeUnspsc190501.50395504'),
	'identifierSchemeCodeUnspsc190501.50395505': __('identifierSchemeCodeUnspsc190501.50395505'),
	'identifierSchemeCodeUnspsc190501.50395506': __('identifierSchemeCodeUnspsc190501.50395506'),
	'identifierSchemeCodeUnspsc190501.50395507': __('identifierSchemeCodeUnspsc190501.50395507'),
	'identifierSchemeCodeUnspsc190501.50395508': __('identifierSchemeCodeUnspsc190501.50395508'),
	'identifierSchemeCodeUnspsc190501.50395509': __('identifierSchemeCodeUnspsc190501.50395509'),
	'identifierSchemeCodeUnspsc190501.50395510': __('identifierSchemeCodeUnspsc190501.50395510'),
	'identifierSchemeCodeUnspsc190501.50395511': __('identifierSchemeCodeUnspsc190501.50395511'),
	'identifierSchemeCodeUnspsc190501.50395512': __('identifierSchemeCodeUnspsc190501.50395512'),
	'identifierSchemeCodeUnspsc190501.50395513': __('identifierSchemeCodeUnspsc190501.50395513'),
	'identifierSchemeCodeUnspsc190501.50395514': __('identifierSchemeCodeUnspsc190501.50395514'),
	'identifierSchemeCodeUnspsc190501.50395515': __('identifierSchemeCodeUnspsc190501.50395515'),
	'identifierSchemeCodeUnspsc190501.50395516': __('identifierSchemeCodeUnspsc190501.50395516'),
	'identifierSchemeCodeUnspsc190501.50395517': __('identifierSchemeCodeUnspsc190501.50395517'),
	'identifierSchemeCodeUnspsc190501.50395518': __('identifierSchemeCodeUnspsc190501.50395518'),
	'identifierSchemeCodeUnspsc190501.50395519': __('identifierSchemeCodeUnspsc190501.50395519'),
	'identifierSchemeCodeUnspsc190501.50395520': __('identifierSchemeCodeUnspsc190501.50395520'),
	'identifierSchemeCodeUnspsc190501.50395521': __('identifierSchemeCodeUnspsc190501.50395521'),
	'identifierSchemeCodeUnspsc190501.50395522': __('identifierSchemeCodeUnspsc190501.50395522'),
	'identifierSchemeCodeUnspsc190501.50395523': __('identifierSchemeCodeUnspsc190501.50395523'),
	'identifierSchemeCodeUnspsc190501.50395524': __('identifierSchemeCodeUnspsc190501.50395524'),
	'identifierSchemeCodeUnspsc190501.50395525': __('identifierSchemeCodeUnspsc190501.50395525'),
	'identifierSchemeCodeUnspsc190501.50395526': __('identifierSchemeCodeUnspsc190501.50395526'),
	'identifierSchemeCodeUnspsc190501.50395527': __('identifierSchemeCodeUnspsc190501.50395527'),
	'identifierSchemeCodeUnspsc190501.50395528': __('identifierSchemeCodeUnspsc190501.50395528'),
	'identifierSchemeCodeUnspsc190501.50395529': __('identifierSchemeCodeUnspsc190501.50395529'),
	'identifierSchemeCodeUnspsc190501.50395530': __('identifierSchemeCodeUnspsc190501.50395530'),
	'identifierSchemeCodeUnspsc190501.50395531': __('identifierSchemeCodeUnspsc190501.50395531'),
	'identifierSchemeCodeUnspsc190501.50395532': __('identifierSchemeCodeUnspsc190501.50395532'),
	'identifierSchemeCodeUnspsc190501.50395533': __('identifierSchemeCodeUnspsc190501.50395533'),
	'identifierSchemeCodeUnspsc190501.50395534': __('identifierSchemeCodeUnspsc190501.50395534'),
	'identifierSchemeCodeUnspsc190501.50395535': __('identifierSchemeCodeUnspsc190501.50395535'),
	'identifierSchemeCodeUnspsc190501.50395536': __('identifierSchemeCodeUnspsc190501.50395536'),
	'identifierSchemeCodeUnspsc190501.50395537': __('identifierSchemeCodeUnspsc190501.50395537'),
	'identifierSchemeCodeUnspsc190501.50395538': __('identifierSchemeCodeUnspsc190501.50395538'),
	'identifierSchemeCodeUnspsc190501.50395539': __('identifierSchemeCodeUnspsc190501.50395539'),
	'identifierSchemeCodeUnspsc190501.50395540': __('identifierSchemeCodeUnspsc190501.50395540'),
	'identifierSchemeCodeUnspsc190501.50395541': __('identifierSchemeCodeUnspsc190501.50395541'),
	'identifierSchemeCodeUnspsc190501.50395542': __('identifierSchemeCodeUnspsc190501.50395542'),
	'identifierSchemeCodeUnspsc190501.50395543': __('identifierSchemeCodeUnspsc190501.50395543'),
	'identifierSchemeCodeUnspsc190501.50395544': __('identifierSchemeCodeUnspsc190501.50395544'),
	'identifierSchemeCodeUnspsc190501.50395545': __('identifierSchemeCodeUnspsc190501.50395545'),
	'identifierSchemeCodeUnspsc190501.50395546': __('identifierSchemeCodeUnspsc190501.50395546'),
	'identifierSchemeCodeUnspsc190501.50395547': __('identifierSchemeCodeUnspsc190501.50395547'),
	'identifierSchemeCodeUnspsc190501.50395548': __('identifierSchemeCodeUnspsc190501.50395548'),
	'identifierSchemeCodeUnspsc190501.50395549': __('identifierSchemeCodeUnspsc190501.50395549'),
	'identifierSchemeCodeUnspsc190501.50395550': __('identifierSchemeCodeUnspsc190501.50395550'),
	'identifierSchemeCodeUnspsc190501.50395551': __('identifierSchemeCodeUnspsc190501.50395551'),
	'identifierSchemeCodeUnspsc190501.50395552': __('identifierSchemeCodeUnspsc190501.50395552'),
	'identifierSchemeCodeUnspsc190501.50395553': __('identifierSchemeCodeUnspsc190501.50395553'),
	'identifierSchemeCodeUnspsc190501.50395554': __('identifierSchemeCodeUnspsc190501.50395554'),
	'identifierSchemeCodeUnspsc190501.50395555': __('identifierSchemeCodeUnspsc190501.50395555'),
	'identifierSchemeCodeUnspsc190501.50395556': __('identifierSchemeCodeUnspsc190501.50395556'),
	'identifierSchemeCodeUnspsc190501.50395557': __('identifierSchemeCodeUnspsc190501.50395557'),
	'identifierSchemeCodeUnspsc190501.50395558': __('identifierSchemeCodeUnspsc190501.50395558'),
	'identifierSchemeCodeUnspsc190501.50395559': __('identifierSchemeCodeUnspsc190501.50395559'),
	'identifierSchemeCodeUnspsc190501.50395560': __('identifierSchemeCodeUnspsc190501.50395560'),
	'identifierSchemeCodeUnspsc190501.50395600': __('identifierSchemeCodeUnspsc190501.50395600'),
	'identifierSchemeCodeUnspsc190501.50395601': __('identifierSchemeCodeUnspsc190501.50395601'),
	'identifierSchemeCodeUnspsc190501.50395602': __('identifierSchemeCodeUnspsc190501.50395602'),
	'identifierSchemeCodeUnspsc190501.50395603': __('identifierSchemeCodeUnspsc190501.50395603'),
	'identifierSchemeCodeUnspsc190501.50395604': __('identifierSchemeCodeUnspsc190501.50395604'),
	'identifierSchemeCodeUnspsc190501.50395605': __('identifierSchemeCodeUnspsc190501.50395605'),
	'identifierSchemeCodeUnspsc190501.50395606': __('identifierSchemeCodeUnspsc190501.50395606'),
	'identifierSchemeCodeUnspsc190501.50395607': __('identifierSchemeCodeUnspsc190501.50395607'),
	'identifierSchemeCodeUnspsc190501.50395608': __('identifierSchemeCodeUnspsc190501.50395608'),
	'identifierSchemeCodeUnspsc190501.50395609': __('identifierSchemeCodeUnspsc190501.50395609'),
	'identifierSchemeCodeUnspsc190501.50395610': __('identifierSchemeCodeUnspsc190501.50395610'),
	'identifierSchemeCodeUnspsc190501.50395611': __('identifierSchemeCodeUnspsc190501.50395611'),
	'identifierSchemeCodeUnspsc190501.50395612': __('identifierSchemeCodeUnspsc190501.50395612'),
	'identifierSchemeCodeUnspsc190501.50395700': __('identifierSchemeCodeUnspsc190501.50395700'),
	'identifierSchemeCodeUnspsc190501.50395701': __('identifierSchemeCodeUnspsc190501.50395701'),
	'identifierSchemeCodeUnspsc190501.50395702': __('identifierSchemeCodeUnspsc190501.50395702'),
	'identifierSchemeCodeUnspsc190501.50395703': __('identifierSchemeCodeUnspsc190501.50395703'),
	'identifierSchemeCodeUnspsc190501.50395704': __('identifierSchemeCodeUnspsc190501.50395704'),
	'identifierSchemeCodeUnspsc190501.50395705': __('identifierSchemeCodeUnspsc190501.50395705'),
	'identifierSchemeCodeUnspsc190501.50395706': __('identifierSchemeCodeUnspsc190501.50395706'),
	'identifierSchemeCodeUnspsc190501.50395707': __('identifierSchemeCodeUnspsc190501.50395707'),
	'identifierSchemeCodeUnspsc190501.50395708': __('identifierSchemeCodeUnspsc190501.50395708'),
	'identifierSchemeCodeUnspsc190501.50395709': __('identifierSchemeCodeUnspsc190501.50395709'),
	'identifierSchemeCodeUnspsc190501.50395710': __('identifierSchemeCodeUnspsc190501.50395710'),
	'identifierSchemeCodeUnspsc190501.50395800': __('identifierSchemeCodeUnspsc190501.50395800'),
	'identifierSchemeCodeUnspsc190501.50395801': __('identifierSchemeCodeUnspsc190501.50395801'),
	'identifierSchemeCodeUnspsc190501.50395802': __('identifierSchemeCodeUnspsc190501.50395802'),
	'identifierSchemeCodeUnspsc190501.50395803': __('identifierSchemeCodeUnspsc190501.50395803'),
	'identifierSchemeCodeUnspsc190501.50395804': __('identifierSchemeCodeUnspsc190501.50395804'),
	'identifierSchemeCodeUnspsc190501.50395805': __('identifierSchemeCodeUnspsc190501.50395805'),
	'identifierSchemeCodeUnspsc190501.50395806': __('identifierSchemeCodeUnspsc190501.50395806'),
	'identifierSchemeCodeUnspsc190501.50395807': __('identifierSchemeCodeUnspsc190501.50395807'),
	'identifierSchemeCodeUnspsc190501.50395808': __('identifierSchemeCodeUnspsc190501.50395808'),
	'identifierSchemeCodeUnspsc190501.50395809': __('identifierSchemeCodeUnspsc190501.50395809'),
	'identifierSchemeCodeUnspsc190501.50395810': __('identifierSchemeCodeUnspsc190501.50395810'),
	'identifierSchemeCodeUnspsc190501.50395811': __('identifierSchemeCodeUnspsc190501.50395811'),
	'identifierSchemeCodeUnspsc190501.50395812': __('identifierSchemeCodeUnspsc190501.50395812'),
	'identifierSchemeCodeUnspsc190501.50395813': __('identifierSchemeCodeUnspsc190501.50395813'),
	'identifierSchemeCodeUnspsc190501.50395814': __('identifierSchemeCodeUnspsc190501.50395814'),
	'identifierSchemeCodeUnspsc190501.50395815': __('identifierSchemeCodeUnspsc190501.50395815'),
	'identifierSchemeCodeUnspsc190501.50395816': __('identifierSchemeCodeUnspsc190501.50395816'),
	'identifierSchemeCodeUnspsc190501.50395817': __('identifierSchemeCodeUnspsc190501.50395817'),
	'identifierSchemeCodeUnspsc190501.50395818': __('identifierSchemeCodeUnspsc190501.50395818'),
	'identifierSchemeCodeUnspsc190501.50395819': __('identifierSchemeCodeUnspsc190501.50395819'),
	'identifierSchemeCodeUnspsc190501.50395820': __('identifierSchemeCodeUnspsc190501.50395820'),
	'identifierSchemeCodeUnspsc190501.50395821': __('identifierSchemeCodeUnspsc190501.50395821'),
	'identifierSchemeCodeUnspsc190501.50395822': __('identifierSchemeCodeUnspsc190501.50395822'),
	'identifierSchemeCodeUnspsc190501.50395823': __('identifierSchemeCodeUnspsc190501.50395823'),
	'identifierSchemeCodeUnspsc190501.50395900': __('identifierSchemeCodeUnspsc190501.50395900'),
	'identifierSchemeCodeUnspsc190501.50395901': __('identifierSchemeCodeUnspsc190501.50395901'),
	'identifierSchemeCodeUnspsc190501.50395902': __('identifierSchemeCodeUnspsc190501.50395902'),
	'identifierSchemeCodeUnspsc190501.50395903': __('identifierSchemeCodeUnspsc190501.50395903'),
	'identifierSchemeCodeUnspsc190501.50395904': __('identifierSchemeCodeUnspsc190501.50395904'),
	'identifierSchemeCodeUnspsc190501.50395905': __('identifierSchemeCodeUnspsc190501.50395905'),
	'identifierSchemeCodeUnspsc190501.50395906': __('identifierSchemeCodeUnspsc190501.50395906'),
	'identifierSchemeCodeUnspsc190501.50395907': __('identifierSchemeCodeUnspsc190501.50395907'),
	'identifierSchemeCodeUnspsc190501.50395908': __('identifierSchemeCodeUnspsc190501.50395908'),
	'identifierSchemeCodeUnspsc190501.50395909': __('identifierSchemeCodeUnspsc190501.50395909'),
	'identifierSchemeCodeUnspsc190501.50395910': __('identifierSchemeCodeUnspsc190501.50395910'),
	'identifierSchemeCodeUnspsc190501.50395911': __('identifierSchemeCodeUnspsc190501.50395911'),
	'identifierSchemeCodeUnspsc190501.50395912': __('identifierSchemeCodeUnspsc190501.50395912'),
	'identifierSchemeCodeUnspsc190501.50395913': __('identifierSchemeCodeUnspsc190501.50395913'),
	'identifierSchemeCodeUnspsc190501.50395914': __('identifierSchemeCodeUnspsc190501.50395914'),
	'identifierSchemeCodeUnspsc190501.50395915': __('identifierSchemeCodeUnspsc190501.50395915'),
	'identifierSchemeCodeUnspsc190501.50395916': __('identifierSchemeCodeUnspsc190501.50395916'),
	'identifierSchemeCodeUnspsc190501.50395917': __('identifierSchemeCodeUnspsc190501.50395917'),
	'identifierSchemeCodeUnspsc190501.50395918': __('identifierSchemeCodeUnspsc190501.50395918'),
	'identifierSchemeCodeUnspsc190501.50395919': __('identifierSchemeCodeUnspsc190501.50395919'),
	'identifierSchemeCodeUnspsc190501.50395920': __('identifierSchemeCodeUnspsc190501.50395920'),
	'identifierSchemeCodeUnspsc190501.50395921': __('identifierSchemeCodeUnspsc190501.50395921'),
	'identifierSchemeCodeUnspsc190501.50395922': __('identifierSchemeCodeUnspsc190501.50395922'),
	'identifierSchemeCodeUnspsc190501.50395923': __('identifierSchemeCodeUnspsc190501.50395923'),
	'identifierSchemeCodeUnspsc190501.50395924': __('identifierSchemeCodeUnspsc190501.50395924'),
	'identifierSchemeCodeUnspsc190501.50395925': __('identifierSchemeCodeUnspsc190501.50395925'),
	'identifierSchemeCodeUnspsc190501.50395926': __('identifierSchemeCodeUnspsc190501.50395926'),
	'identifierSchemeCodeUnspsc190501.50395927': __('identifierSchemeCodeUnspsc190501.50395927'),
	'identifierSchemeCodeUnspsc190501.50395928': __('identifierSchemeCodeUnspsc190501.50395928'),
	'identifierSchemeCodeUnspsc190501.50395929': __('identifierSchemeCodeUnspsc190501.50395929'),
	'identifierSchemeCodeUnspsc190501.50395930': __('identifierSchemeCodeUnspsc190501.50395930'),
	'identifierSchemeCodeUnspsc190501.50395931': __('identifierSchemeCodeUnspsc190501.50395931'),
	'identifierSchemeCodeUnspsc190501.50395932': __('identifierSchemeCodeUnspsc190501.50395932'),
	'identifierSchemeCodeUnspsc190501.50395933': __('identifierSchemeCodeUnspsc190501.50395933'),
	'identifierSchemeCodeUnspsc190501.50395934': __('identifierSchemeCodeUnspsc190501.50395934'),
	'identifierSchemeCodeUnspsc190501.50395935': __('identifierSchemeCodeUnspsc190501.50395935'),
	'identifierSchemeCodeUnspsc190501.50395936': __('identifierSchemeCodeUnspsc190501.50395936'),
	'identifierSchemeCodeUnspsc190501.50395937': __('identifierSchemeCodeUnspsc190501.50395937'),
	'identifierSchemeCodeUnspsc190501.50395938': __('identifierSchemeCodeUnspsc190501.50395938'),
	'identifierSchemeCodeUnspsc190501.50395939': __('identifierSchemeCodeUnspsc190501.50395939'),
	'identifierSchemeCodeUnspsc190501.50395940': __('identifierSchemeCodeUnspsc190501.50395940'),
	'identifierSchemeCodeUnspsc190501.50395941': __('identifierSchemeCodeUnspsc190501.50395941'),
	'identifierSchemeCodeUnspsc190501.50395942': __('identifierSchemeCodeUnspsc190501.50395942'),
	'identifierSchemeCodeUnspsc190501.50395943': __('identifierSchemeCodeUnspsc190501.50395943'),
	'identifierSchemeCodeUnspsc190501.50395944': __('identifierSchemeCodeUnspsc190501.50395944'),
	'identifierSchemeCodeUnspsc190501.50395945': __('identifierSchemeCodeUnspsc190501.50395945'),
	'identifierSchemeCodeUnspsc190501.50395946': __('identifierSchemeCodeUnspsc190501.50395946'),
	'identifierSchemeCodeUnspsc190501.50395947': __('identifierSchemeCodeUnspsc190501.50395947'),
	'identifierSchemeCodeUnspsc190501.50395948': __('identifierSchemeCodeUnspsc190501.50395948'),
	'identifierSchemeCodeUnspsc190501.50395949': __('identifierSchemeCodeUnspsc190501.50395949'),
	'identifierSchemeCodeUnspsc190501.50395950': __('identifierSchemeCodeUnspsc190501.50395950'),
	'identifierSchemeCodeUnspsc190501.50395951': __('identifierSchemeCodeUnspsc190501.50395951'),
	'identifierSchemeCodeUnspsc190501.50395952': __('identifierSchemeCodeUnspsc190501.50395952'),
	'identifierSchemeCodeUnspsc190501.50395953': __('identifierSchemeCodeUnspsc190501.50395953'),
	'identifierSchemeCodeUnspsc190501.50395954': __('identifierSchemeCodeUnspsc190501.50395954'),
	'identifierSchemeCodeUnspsc190501.50395955': __('identifierSchemeCodeUnspsc190501.50395955'),
	'identifierSchemeCodeUnspsc190501.50395956': __('identifierSchemeCodeUnspsc190501.50395956'),
	'identifierSchemeCodeUnspsc190501.50395957': __('identifierSchemeCodeUnspsc190501.50395957'),
	'identifierSchemeCodeUnspsc190501.50395958': __('identifierSchemeCodeUnspsc190501.50395958'),
	'identifierSchemeCodeUnspsc190501.50395959': __('identifierSchemeCodeUnspsc190501.50395959'),
	'identifierSchemeCodeUnspsc190501.50395960': __('identifierSchemeCodeUnspsc190501.50395960'),
	'identifierSchemeCodeUnspsc190501.50395961': __('identifierSchemeCodeUnspsc190501.50395961'),
	'identifierSchemeCodeUnspsc190501.50395962': __('identifierSchemeCodeUnspsc190501.50395962'),
	'identifierSchemeCodeUnspsc190501.50395963': __('identifierSchemeCodeUnspsc190501.50395963'),
	'identifierSchemeCodeUnspsc190501.50395964': __('identifierSchemeCodeUnspsc190501.50395964'),
	'identifierSchemeCodeUnspsc190501.50395965': __('identifierSchemeCodeUnspsc190501.50395965'),
	'identifierSchemeCodeUnspsc190501.50395966': __('identifierSchemeCodeUnspsc190501.50395966'),
	'identifierSchemeCodeUnspsc190501.50395967': __('identifierSchemeCodeUnspsc190501.50395967'),
	'identifierSchemeCodeUnspsc190501.50395968': __('identifierSchemeCodeUnspsc190501.50395968'),
	'identifierSchemeCodeUnspsc190501.50395969': __('identifierSchemeCodeUnspsc190501.50395969'),
	'identifierSchemeCodeUnspsc190501.50395970': __('identifierSchemeCodeUnspsc190501.50395970'),
	'identifierSchemeCodeUnspsc190501.50395971': __('identifierSchemeCodeUnspsc190501.50395971'),
	'identifierSchemeCodeUnspsc190501.50395972': __('identifierSchemeCodeUnspsc190501.50395972'),
	'identifierSchemeCodeUnspsc190501.50395973': __('identifierSchemeCodeUnspsc190501.50395973'),
	'identifierSchemeCodeUnspsc190501.50395974': __('identifierSchemeCodeUnspsc190501.50395974'),
	'identifierSchemeCodeUnspsc190501.50395975': __('identifierSchemeCodeUnspsc190501.50395975'),
	'identifierSchemeCodeUnspsc190501.50395976': __('identifierSchemeCodeUnspsc190501.50395976'),
	'identifierSchemeCodeUnspsc190501.50395977': __('identifierSchemeCodeUnspsc190501.50395977'),
	'identifierSchemeCodeUnspsc190501.50396000': __('identifierSchemeCodeUnspsc190501.50396000'),
	'identifierSchemeCodeUnspsc190501.50396001': __('identifierSchemeCodeUnspsc190501.50396001'),
	'identifierSchemeCodeUnspsc190501.50396002': __('identifierSchemeCodeUnspsc190501.50396002'),
	'identifierSchemeCodeUnspsc190501.50396003': __('identifierSchemeCodeUnspsc190501.50396003'),
	'identifierSchemeCodeUnspsc190501.50396004': __('identifierSchemeCodeUnspsc190501.50396004'),
	'identifierSchemeCodeUnspsc190501.50396005': __('identifierSchemeCodeUnspsc190501.50396005'),
	'identifierSchemeCodeUnspsc190501.50396006': __('identifierSchemeCodeUnspsc190501.50396006'),
	'identifierSchemeCodeUnspsc190501.50396007': __('identifierSchemeCodeUnspsc190501.50396007'),
	'identifierSchemeCodeUnspsc190501.50396008': __('identifierSchemeCodeUnspsc190501.50396008'),
	'identifierSchemeCodeUnspsc190501.50396100': __('identifierSchemeCodeUnspsc190501.50396100'),
	'identifierSchemeCodeUnspsc190501.50396101': __('identifierSchemeCodeUnspsc190501.50396101'),
	'identifierSchemeCodeUnspsc190501.50396102': __('identifierSchemeCodeUnspsc190501.50396102'),
	'identifierSchemeCodeUnspsc190501.50396103': __('identifierSchemeCodeUnspsc190501.50396103'),
	'identifierSchemeCodeUnspsc190501.50396104': __('identifierSchemeCodeUnspsc190501.50396104'),
	'identifierSchemeCodeUnspsc190501.50396105': __('identifierSchemeCodeUnspsc190501.50396105'),
	'identifierSchemeCodeUnspsc190501.50396106': __('identifierSchemeCodeUnspsc190501.50396106'),
	'identifierSchemeCodeUnspsc190501.50396107': __('identifierSchemeCodeUnspsc190501.50396107'),
	'identifierSchemeCodeUnspsc190501.50396108': __('identifierSchemeCodeUnspsc190501.50396108'),
	'identifierSchemeCodeUnspsc190501.50396200': __('identifierSchemeCodeUnspsc190501.50396200'),
	'identifierSchemeCodeUnspsc190501.50396201': __('identifierSchemeCodeUnspsc190501.50396201'),
	'identifierSchemeCodeUnspsc190501.50396202': __('identifierSchemeCodeUnspsc190501.50396202'),
	'identifierSchemeCodeUnspsc190501.50396203': __('identifierSchemeCodeUnspsc190501.50396203'),
	'identifierSchemeCodeUnspsc190501.50396300': __('identifierSchemeCodeUnspsc190501.50396300'),
	'identifierSchemeCodeUnspsc190501.50396301': __('identifierSchemeCodeUnspsc190501.50396301'),
	'identifierSchemeCodeUnspsc190501.50396302': __('identifierSchemeCodeUnspsc190501.50396302'),
	'identifierSchemeCodeUnspsc190501.50396303': __('identifierSchemeCodeUnspsc190501.50396303'),
	'identifierSchemeCodeUnspsc190501.50396304': __('identifierSchemeCodeUnspsc190501.50396304'),
	'identifierSchemeCodeUnspsc190501.50396305': __('identifierSchemeCodeUnspsc190501.50396305'),
	'identifierSchemeCodeUnspsc190501.50396306': __('identifierSchemeCodeUnspsc190501.50396306'),
	'identifierSchemeCodeUnspsc190501.50396307': __('identifierSchemeCodeUnspsc190501.50396307'),
	'identifierSchemeCodeUnspsc190501.50396308': __('identifierSchemeCodeUnspsc190501.50396308'),
	'identifierSchemeCodeUnspsc190501.50396309': __('identifierSchemeCodeUnspsc190501.50396309'),
	'identifierSchemeCodeUnspsc190501.50396310': __('identifierSchemeCodeUnspsc190501.50396310'),
	'identifierSchemeCodeUnspsc190501.50396311': __('identifierSchemeCodeUnspsc190501.50396311'),
	'identifierSchemeCodeUnspsc190501.50396312': __('identifierSchemeCodeUnspsc190501.50396312'),
	'identifierSchemeCodeUnspsc190501.50396313': __('identifierSchemeCodeUnspsc190501.50396313'),
	'identifierSchemeCodeUnspsc190501.50396314': __('identifierSchemeCodeUnspsc190501.50396314'),
	'identifierSchemeCodeUnspsc190501.50396315': __('identifierSchemeCodeUnspsc190501.50396315'),
	'identifierSchemeCodeUnspsc190501.50396316': __('identifierSchemeCodeUnspsc190501.50396316'),
	'identifierSchemeCodeUnspsc190501.50396317': __('identifierSchemeCodeUnspsc190501.50396317'),
	'identifierSchemeCodeUnspsc190501.50396318': __('identifierSchemeCodeUnspsc190501.50396318'),
	'identifierSchemeCodeUnspsc190501.50396319': __('identifierSchemeCodeUnspsc190501.50396319'),
	'identifierSchemeCodeUnspsc190501.50396320': __('identifierSchemeCodeUnspsc190501.50396320'),
	'identifierSchemeCodeUnspsc190501.50396321': __('identifierSchemeCodeUnspsc190501.50396321'),
	'identifierSchemeCodeUnspsc190501.50396322': __('identifierSchemeCodeUnspsc190501.50396322'),
	'identifierSchemeCodeUnspsc190501.50396323': __('identifierSchemeCodeUnspsc190501.50396323'),
	'identifierSchemeCodeUnspsc190501.50396324': __('identifierSchemeCodeUnspsc190501.50396324'),
	'identifierSchemeCodeUnspsc190501.50396325': __('identifierSchemeCodeUnspsc190501.50396325'),
	'identifierSchemeCodeUnspsc190501.50396326': __('identifierSchemeCodeUnspsc190501.50396326'),
	'identifierSchemeCodeUnspsc190501.50396327': __('identifierSchemeCodeUnspsc190501.50396327'),
	'identifierSchemeCodeUnspsc190501.50396328': __('identifierSchemeCodeUnspsc190501.50396328'),
	'identifierSchemeCodeUnspsc190501.50396329': __('identifierSchemeCodeUnspsc190501.50396329'),
	'identifierSchemeCodeUnspsc190501.50396400': __('identifierSchemeCodeUnspsc190501.50396400'),
	'identifierSchemeCodeUnspsc190501.50396401': __('identifierSchemeCodeUnspsc190501.50396401'),
	'identifierSchemeCodeUnspsc190501.50396402': __('identifierSchemeCodeUnspsc190501.50396402'),
	'identifierSchemeCodeUnspsc190501.50396403': __('identifierSchemeCodeUnspsc190501.50396403'),
	'identifierSchemeCodeUnspsc190501.50396404': __('identifierSchemeCodeUnspsc190501.50396404'),
	'identifierSchemeCodeUnspsc190501.50396405': __('identifierSchemeCodeUnspsc190501.50396405'),
	'identifierSchemeCodeUnspsc190501.50396406': __('identifierSchemeCodeUnspsc190501.50396406'),
	'identifierSchemeCodeUnspsc190501.50396407': __('identifierSchemeCodeUnspsc190501.50396407'),
	'identifierSchemeCodeUnspsc190501.50396408': __('identifierSchemeCodeUnspsc190501.50396408'),
	'identifierSchemeCodeUnspsc190501.50396409': __('identifierSchemeCodeUnspsc190501.50396409'),
	'identifierSchemeCodeUnspsc190501.50396410': __('identifierSchemeCodeUnspsc190501.50396410'),
	'identifierSchemeCodeUnspsc190501.50396500': __('identifierSchemeCodeUnspsc190501.50396500'),
	'identifierSchemeCodeUnspsc190501.50396501': __('identifierSchemeCodeUnspsc190501.50396501'),
	'identifierSchemeCodeUnspsc190501.50396502': __('identifierSchemeCodeUnspsc190501.50396502'),
	'identifierSchemeCodeUnspsc190501.50396503': __('identifierSchemeCodeUnspsc190501.50396503'),
	'identifierSchemeCodeUnspsc190501.50396504': __('identifierSchemeCodeUnspsc190501.50396504'),
	'identifierSchemeCodeUnspsc190501.50396600': __('identifierSchemeCodeUnspsc190501.50396600'),
	'identifierSchemeCodeUnspsc190501.50396601': __('identifierSchemeCodeUnspsc190501.50396601'),
	'identifierSchemeCodeUnspsc190501.50396602': __('identifierSchemeCodeUnspsc190501.50396602'),
	'identifierSchemeCodeUnspsc190501.50396700': __('identifierSchemeCodeUnspsc190501.50396700'),
	'identifierSchemeCodeUnspsc190501.50396701': __('identifierSchemeCodeUnspsc190501.50396701'),
	'identifierSchemeCodeUnspsc190501.50396702': __('identifierSchemeCodeUnspsc190501.50396702'),
	'identifierSchemeCodeUnspsc190501.50396703': __('identifierSchemeCodeUnspsc190501.50396703'),
	'identifierSchemeCodeUnspsc190501.50396704': __('identifierSchemeCodeUnspsc190501.50396704'),
	'identifierSchemeCodeUnspsc190501.50396800': __('identifierSchemeCodeUnspsc190501.50396800'),
	'identifierSchemeCodeUnspsc190501.50396801': __('identifierSchemeCodeUnspsc190501.50396801'),
	'identifierSchemeCodeUnspsc190501.50396802': __('identifierSchemeCodeUnspsc190501.50396802'),
	'identifierSchemeCodeUnspsc190501.50396803': __('identifierSchemeCodeUnspsc190501.50396803'),
	'identifierSchemeCodeUnspsc190501.50396900': __('identifierSchemeCodeUnspsc190501.50396900'),
	'identifierSchemeCodeUnspsc190501.50396901': __('identifierSchemeCodeUnspsc190501.50396901'),
	'identifierSchemeCodeUnspsc190501.50396902': __('identifierSchemeCodeUnspsc190501.50396902'),
	'identifierSchemeCodeUnspsc190501.50396903': __('identifierSchemeCodeUnspsc190501.50396903'),
	'identifierSchemeCodeUnspsc190501.50397000': __('identifierSchemeCodeUnspsc190501.50397000'),
	'identifierSchemeCodeUnspsc190501.50397001': __('identifierSchemeCodeUnspsc190501.50397001'),
	'identifierSchemeCodeUnspsc190501.50397002': __('identifierSchemeCodeUnspsc190501.50397002'),
	'identifierSchemeCodeUnspsc190501.50397003': __('identifierSchemeCodeUnspsc190501.50397003'),
	'identifierSchemeCodeUnspsc190501.50397004': __('identifierSchemeCodeUnspsc190501.50397004'),
	'identifierSchemeCodeUnspsc190501.50397005': __('identifierSchemeCodeUnspsc190501.50397005'),
	'identifierSchemeCodeUnspsc190501.50397006': __('identifierSchemeCodeUnspsc190501.50397006'),
	'identifierSchemeCodeUnspsc190501.50397100': __('identifierSchemeCodeUnspsc190501.50397100'),
	'identifierSchemeCodeUnspsc190501.50397101': __('identifierSchemeCodeUnspsc190501.50397101'),
	'identifierSchemeCodeUnspsc190501.50397102': __('identifierSchemeCodeUnspsc190501.50397102'),
	'identifierSchemeCodeUnspsc190501.50397103': __('identifierSchemeCodeUnspsc190501.50397103'),
	'identifierSchemeCodeUnspsc190501.50397104': __('identifierSchemeCodeUnspsc190501.50397104'),
	'identifierSchemeCodeUnspsc190501.50397105': __('identifierSchemeCodeUnspsc190501.50397105'),
	'identifierSchemeCodeUnspsc190501.50397106': __('identifierSchemeCodeUnspsc190501.50397106'),
	'identifierSchemeCodeUnspsc190501.50397107': __('identifierSchemeCodeUnspsc190501.50397107'),
	'identifierSchemeCodeUnspsc190501.50397108': __('identifierSchemeCodeUnspsc190501.50397108'),
	'identifierSchemeCodeUnspsc190501.50397109': __('identifierSchemeCodeUnspsc190501.50397109'),
	'identifierSchemeCodeUnspsc190501.50397110': __('identifierSchemeCodeUnspsc190501.50397110'),
	'identifierSchemeCodeUnspsc190501.50397111': __('identifierSchemeCodeUnspsc190501.50397111'),
	'identifierSchemeCodeUnspsc190501.50397112': __('identifierSchemeCodeUnspsc190501.50397112'),
	'identifierSchemeCodeUnspsc190501.50397113': __('identifierSchemeCodeUnspsc190501.50397113'),
	'identifierSchemeCodeUnspsc190501.50397114': __('identifierSchemeCodeUnspsc190501.50397114'),
	'identifierSchemeCodeUnspsc190501.50397115': __('identifierSchemeCodeUnspsc190501.50397115'),
	'identifierSchemeCodeUnspsc190501.50397116': __('identifierSchemeCodeUnspsc190501.50397116'),
	'identifierSchemeCodeUnspsc190501.50397117': __('identifierSchemeCodeUnspsc190501.50397117'),
	'identifierSchemeCodeUnspsc190501.50397118': __('identifierSchemeCodeUnspsc190501.50397118'),
	'identifierSchemeCodeUnspsc190501.50397119': __('identifierSchemeCodeUnspsc190501.50397119'),
	'identifierSchemeCodeUnspsc190501.50397120': __('identifierSchemeCodeUnspsc190501.50397120'),
	'identifierSchemeCodeUnspsc190501.50397121': __('identifierSchemeCodeUnspsc190501.50397121'),
	'identifierSchemeCodeUnspsc190501.50397122': __('identifierSchemeCodeUnspsc190501.50397122'),
	'identifierSchemeCodeUnspsc190501.50397123': __('identifierSchemeCodeUnspsc190501.50397123'),
	'identifierSchemeCodeUnspsc190501.50397124': __('identifierSchemeCodeUnspsc190501.50397124'),
	'identifierSchemeCodeUnspsc190501.50397125': __('identifierSchemeCodeUnspsc190501.50397125'),
	'identifierSchemeCodeUnspsc190501.50397126': __('identifierSchemeCodeUnspsc190501.50397126'),
	'identifierSchemeCodeUnspsc190501.50397127': __('identifierSchemeCodeUnspsc190501.50397127'),
	'identifierSchemeCodeUnspsc190501.50397128': __('identifierSchemeCodeUnspsc190501.50397128'),
	'identifierSchemeCodeUnspsc190501.50397129': __('identifierSchemeCodeUnspsc190501.50397129'),
	'identifierSchemeCodeUnspsc190501.50397130': __('identifierSchemeCodeUnspsc190501.50397130'),
	'identifierSchemeCodeUnspsc190501.50397131': __('identifierSchemeCodeUnspsc190501.50397131'),
	'identifierSchemeCodeUnspsc190501.50397132': __('identifierSchemeCodeUnspsc190501.50397132'),
	'identifierSchemeCodeUnspsc190501.50397133': __('identifierSchemeCodeUnspsc190501.50397133'),
	'identifierSchemeCodeUnspsc190501.50397134': __('identifierSchemeCodeUnspsc190501.50397134'),
	'identifierSchemeCodeUnspsc190501.50397135': __('identifierSchemeCodeUnspsc190501.50397135'),
	'identifierSchemeCodeUnspsc190501.50397136': __('identifierSchemeCodeUnspsc190501.50397136'),
	'identifierSchemeCodeUnspsc190501.50397137': __('identifierSchemeCodeUnspsc190501.50397137'),
	'identifierSchemeCodeUnspsc190501.50397200': __('identifierSchemeCodeUnspsc190501.50397200'),
	'identifierSchemeCodeUnspsc190501.50397201': __('identifierSchemeCodeUnspsc190501.50397201'),
	'identifierSchemeCodeUnspsc190501.50397202': __('identifierSchemeCodeUnspsc190501.50397202'),
	'identifierSchemeCodeUnspsc190501.50397203': __('identifierSchemeCodeUnspsc190501.50397203'),
	'identifierSchemeCodeUnspsc190501.50397204': __('identifierSchemeCodeUnspsc190501.50397204'),
	'identifierSchemeCodeUnspsc190501.50397300': __('identifierSchemeCodeUnspsc190501.50397300'),
	'identifierSchemeCodeUnspsc190501.50397301': __('identifierSchemeCodeUnspsc190501.50397301'),
	'identifierSchemeCodeUnspsc190501.50397302': __('identifierSchemeCodeUnspsc190501.50397302'),
	'identifierSchemeCodeUnspsc190501.50397303': __('identifierSchemeCodeUnspsc190501.50397303'),
	'identifierSchemeCodeUnspsc190501.50397304': __('identifierSchemeCodeUnspsc190501.50397304'),
	'identifierSchemeCodeUnspsc190501.50397305': __('identifierSchemeCodeUnspsc190501.50397305'),
	'identifierSchemeCodeUnspsc190501.50397306': __('identifierSchemeCodeUnspsc190501.50397306'),
	'identifierSchemeCodeUnspsc190501.50397307': __('identifierSchemeCodeUnspsc190501.50397307'),
	'identifierSchemeCodeUnspsc190501.50397308': __('identifierSchemeCodeUnspsc190501.50397308'),
	'identifierSchemeCodeUnspsc190501.50397309': __('identifierSchemeCodeUnspsc190501.50397309'),
	'identifierSchemeCodeUnspsc190501.50397310': __('identifierSchemeCodeUnspsc190501.50397310'),
	'identifierSchemeCodeUnspsc190501.50397311': __('identifierSchemeCodeUnspsc190501.50397311'),
	'identifierSchemeCodeUnspsc190501.50397312': __('identifierSchemeCodeUnspsc190501.50397312'),
	'identifierSchemeCodeUnspsc190501.50397313': __('identifierSchemeCodeUnspsc190501.50397313'),
	'identifierSchemeCodeUnspsc190501.50397314': __('identifierSchemeCodeUnspsc190501.50397314'),
	'identifierSchemeCodeUnspsc190501.50397315': __('identifierSchemeCodeUnspsc190501.50397315'),
	'identifierSchemeCodeUnspsc190501.50397316': __('identifierSchemeCodeUnspsc190501.50397316'),
	'identifierSchemeCodeUnspsc190501.50397317': __('identifierSchemeCodeUnspsc190501.50397317'),
	'identifierSchemeCodeUnspsc190501.50397318': __('identifierSchemeCodeUnspsc190501.50397318'),
	'identifierSchemeCodeUnspsc190501.50397319': __('identifierSchemeCodeUnspsc190501.50397319'),
	'identifierSchemeCodeUnspsc190501.50397320': __('identifierSchemeCodeUnspsc190501.50397320'),
	'identifierSchemeCodeUnspsc190501.50397321': __('identifierSchemeCodeUnspsc190501.50397321'),
	'identifierSchemeCodeUnspsc190501.50397322': __('identifierSchemeCodeUnspsc190501.50397322'),
	'identifierSchemeCodeUnspsc190501.50397323': __('identifierSchemeCodeUnspsc190501.50397323'),
	'identifierSchemeCodeUnspsc190501.50397324': __('identifierSchemeCodeUnspsc190501.50397324'),
	'identifierSchemeCodeUnspsc190501.50397325': __('identifierSchemeCodeUnspsc190501.50397325'),
	'identifierSchemeCodeUnspsc190501.50397326': __('identifierSchemeCodeUnspsc190501.50397326'),
	'identifierSchemeCodeUnspsc190501.50397327': __('identifierSchemeCodeUnspsc190501.50397327'),
	'identifierSchemeCodeUnspsc190501.50397328': __('identifierSchemeCodeUnspsc190501.50397328'),
	'identifierSchemeCodeUnspsc190501.50397329': __('identifierSchemeCodeUnspsc190501.50397329'),
	'identifierSchemeCodeUnspsc190501.50397330': __('identifierSchemeCodeUnspsc190501.50397330'),
	'identifierSchemeCodeUnspsc190501.50400000': __('identifierSchemeCodeUnspsc190501.50400000'),
	'identifierSchemeCodeUnspsc190501.50401500': __('identifierSchemeCodeUnspsc190501.50401500'),
	'identifierSchemeCodeUnspsc190501.50401501': __('identifierSchemeCodeUnspsc190501.50401501'),
	'identifierSchemeCodeUnspsc190501.50401502': __('identifierSchemeCodeUnspsc190501.50401502'),
	'identifierSchemeCodeUnspsc190501.50401503': __('identifierSchemeCodeUnspsc190501.50401503'),
	'identifierSchemeCodeUnspsc190501.50401504': __('identifierSchemeCodeUnspsc190501.50401504'),
	'identifierSchemeCodeUnspsc190501.50401505': __('identifierSchemeCodeUnspsc190501.50401505'),
	'identifierSchemeCodeUnspsc190501.50401506': __('identifierSchemeCodeUnspsc190501.50401506'),
	'identifierSchemeCodeUnspsc190501.50401507': __('identifierSchemeCodeUnspsc190501.50401507'),
	'identifierSchemeCodeUnspsc190501.50401508': __('identifierSchemeCodeUnspsc190501.50401508'),
	'identifierSchemeCodeUnspsc190501.50401509': __('identifierSchemeCodeUnspsc190501.50401509'),
	'identifierSchemeCodeUnspsc190501.50401510': __('identifierSchemeCodeUnspsc190501.50401510'),
	'identifierSchemeCodeUnspsc190501.50401511': __('identifierSchemeCodeUnspsc190501.50401511'),
	'identifierSchemeCodeUnspsc190501.50401512': __('identifierSchemeCodeUnspsc190501.50401512'),
	'identifierSchemeCodeUnspsc190501.50401513': __('identifierSchemeCodeUnspsc190501.50401513'),
	'identifierSchemeCodeUnspsc190501.50401600': __('identifierSchemeCodeUnspsc190501.50401600'),
	'identifierSchemeCodeUnspsc190501.50401601': __('identifierSchemeCodeUnspsc190501.50401601'),
	'identifierSchemeCodeUnspsc190501.50401602': __('identifierSchemeCodeUnspsc190501.50401602'),
	'identifierSchemeCodeUnspsc190501.50401603': __('identifierSchemeCodeUnspsc190501.50401603'),
	'identifierSchemeCodeUnspsc190501.50401604': __('identifierSchemeCodeUnspsc190501.50401604'),
	'identifierSchemeCodeUnspsc190501.50401605': __('identifierSchemeCodeUnspsc190501.50401605'),
	'identifierSchemeCodeUnspsc190501.50401700': __('identifierSchemeCodeUnspsc190501.50401700'),
	'identifierSchemeCodeUnspsc190501.50401701': __('identifierSchemeCodeUnspsc190501.50401701'),
	'identifierSchemeCodeUnspsc190501.50401702': __('identifierSchemeCodeUnspsc190501.50401702'),
	'identifierSchemeCodeUnspsc190501.50401703': __('identifierSchemeCodeUnspsc190501.50401703'),
	'identifierSchemeCodeUnspsc190501.50401704': __('identifierSchemeCodeUnspsc190501.50401704'),
	'identifierSchemeCodeUnspsc190501.50401705': __('identifierSchemeCodeUnspsc190501.50401705'),
	'identifierSchemeCodeUnspsc190501.50401706': __('identifierSchemeCodeUnspsc190501.50401706'),
	'identifierSchemeCodeUnspsc190501.50401707': __('identifierSchemeCodeUnspsc190501.50401707'),
	'identifierSchemeCodeUnspsc190501.50401708': __('identifierSchemeCodeUnspsc190501.50401708'),
	'identifierSchemeCodeUnspsc190501.50401709': __('identifierSchemeCodeUnspsc190501.50401709'),
	'identifierSchemeCodeUnspsc190501.50401710': __('identifierSchemeCodeUnspsc190501.50401710'),
	'identifierSchemeCodeUnspsc190501.50401711': __('identifierSchemeCodeUnspsc190501.50401711'),
	'identifierSchemeCodeUnspsc190501.50401712': __('identifierSchemeCodeUnspsc190501.50401712'),
	'identifierSchemeCodeUnspsc190501.50401713': __('identifierSchemeCodeUnspsc190501.50401713'),
	'identifierSchemeCodeUnspsc190501.50401714': __('identifierSchemeCodeUnspsc190501.50401714'),
	'identifierSchemeCodeUnspsc190501.50401715': __('identifierSchemeCodeUnspsc190501.50401715'),
	'identifierSchemeCodeUnspsc190501.50401716': __('identifierSchemeCodeUnspsc190501.50401716'),
	'identifierSchemeCodeUnspsc190501.50401717': __('identifierSchemeCodeUnspsc190501.50401717'),
	'identifierSchemeCodeUnspsc190501.50401718': __('identifierSchemeCodeUnspsc190501.50401718'),
	'identifierSchemeCodeUnspsc190501.50401719': __('identifierSchemeCodeUnspsc190501.50401719'),
	'identifierSchemeCodeUnspsc190501.50401720': __('identifierSchemeCodeUnspsc190501.50401720'),
	'identifierSchemeCodeUnspsc190501.50401721': __('identifierSchemeCodeUnspsc190501.50401721'),
	'identifierSchemeCodeUnspsc190501.50401722': __('identifierSchemeCodeUnspsc190501.50401722'),
	'identifierSchemeCodeUnspsc190501.50401723': __('identifierSchemeCodeUnspsc190501.50401723'),
	'identifierSchemeCodeUnspsc190501.50401724': __('identifierSchemeCodeUnspsc190501.50401724'),
	'identifierSchemeCodeUnspsc190501.50401725': __('identifierSchemeCodeUnspsc190501.50401725'),
	'identifierSchemeCodeUnspsc190501.50401726': __('identifierSchemeCodeUnspsc190501.50401726'),
	'identifierSchemeCodeUnspsc190501.50401727': __('identifierSchemeCodeUnspsc190501.50401727'),
	'identifierSchemeCodeUnspsc190501.50401728': __('identifierSchemeCodeUnspsc190501.50401728'),
	'identifierSchemeCodeUnspsc190501.50401729': __('identifierSchemeCodeUnspsc190501.50401729'),
	'identifierSchemeCodeUnspsc190501.50401730': __('identifierSchemeCodeUnspsc190501.50401730'),
	'identifierSchemeCodeUnspsc190501.50401731': __('identifierSchemeCodeUnspsc190501.50401731'),
	'identifierSchemeCodeUnspsc190501.50401732': __('identifierSchemeCodeUnspsc190501.50401732'),
	'identifierSchemeCodeUnspsc190501.50401733': __('identifierSchemeCodeUnspsc190501.50401733'),
	'identifierSchemeCodeUnspsc190501.50401734': __('identifierSchemeCodeUnspsc190501.50401734'),
	'identifierSchemeCodeUnspsc190501.50401735': __('identifierSchemeCodeUnspsc190501.50401735'),
	'identifierSchemeCodeUnspsc190501.50401736': __('identifierSchemeCodeUnspsc190501.50401736'),
	'identifierSchemeCodeUnspsc190501.50401737': __('identifierSchemeCodeUnspsc190501.50401737'),
	'identifierSchemeCodeUnspsc190501.50401738': __('identifierSchemeCodeUnspsc190501.50401738'),
	'identifierSchemeCodeUnspsc190501.50401739': __('identifierSchemeCodeUnspsc190501.50401739'),
	'identifierSchemeCodeUnspsc190501.50401740': __('identifierSchemeCodeUnspsc190501.50401740'),
	'identifierSchemeCodeUnspsc190501.50401741': __('identifierSchemeCodeUnspsc190501.50401741'),
	'identifierSchemeCodeUnspsc190501.50401742': __('identifierSchemeCodeUnspsc190501.50401742'),
	'identifierSchemeCodeUnspsc190501.50401743': __('identifierSchemeCodeUnspsc190501.50401743'),
	'identifierSchemeCodeUnspsc190501.50401744': __('identifierSchemeCodeUnspsc190501.50401744'),
	'identifierSchemeCodeUnspsc190501.50401745': __('identifierSchemeCodeUnspsc190501.50401745'),
	'identifierSchemeCodeUnspsc190501.50401746': __('identifierSchemeCodeUnspsc190501.50401746'),
	'identifierSchemeCodeUnspsc190501.50401747': __('identifierSchemeCodeUnspsc190501.50401747'),
	'identifierSchemeCodeUnspsc190501.50401748': __('identifierSchemeCodeUnspsc190501.50401748'),
	'identifierSchemeCodeUnspsc190501.50401749': __('identifierSchemeCodeUnspsc190501.50401749'),
	'identifierSchemeCodeUnspsc190501.50401750': __('identifierSchemeCodeUnspsc190501.50401750'),
	'identifierSchemeCodeUnspsc190501.50401751': __('identifierSchemeCodeUnspsc190501.50401751'),
	'identifierSchemeCodeUnspsc190501.50401752': __('identifierSchemeCodeUnspsc190501.50401752'),
	'identifierSchemeCodeUnspsc190501.50401753': __('identifierSchemeCodeUnspsc190501.50401753'),
	'identifierSchemeCodeUnspsc190501.50401754': __('identifierSchemeCodeUnspsc190501.50401754'),
	'identifierSchemeCodeUnspsc190501.50401755': __('identifierSchemeCodeUnspsc190501.50401755'),
	'identifierSchemeCodeUnspsc190501.50401756': __('identifierSchemeCodeUnspsc190501.50401756'),
	'identifierSchemeCodeUnspsc190501.50401757': __('identifierSchemeCodeUnspsc190501.50401757'),
	'identifierSchemeCodeUnspsc190501.50401758': __('identifierSchemeCodeUnspsc190501.50401758'),
	'identifierSchemeCodeUnspsc190501.50401759': __('identifierSchemeCodeUnspsc190501.50401759'),
	'identifierSchemeCodeUnspsc190501.50401760': __('identifierSchemeCodeUnspsc190501.50401760'),
	'identifierSchemeCodeUnspsc190501.50401761': __('identifierSchemeCodeUnspsc190501.50401761'),
	'identifierSchemeCodeUnspsc190501.50401762': __('identifierSchemeCodeUnspsc190501.50401762'),
	'identifierSchemeCodeUnspsc190501.50401763': __('identifierSchemeCodeUnspsc190501.50401763'),
	'identifierSchemeCodeUnspsc190501.50401764': __('identifierSchemeCodeUnspsc190501.50401764'),
	'identifierSchemeCodeUnspsc190501.50401765': __('identifierSchemeCodeUnspsc190501.50401765'),
	'identifierSchemeCodeUnspsc190501.50401766': __('identifierSchemeCodeUnspsc190501.50401766'),
	'identifierSchemeCodeUnspsc190501.50401767': __('identifierSchemeCodeUnspsc190501.50401767'),
	'identifierSchemeCodeUnspsc190501.50401768': __('identifierSchemeCodeUnspsc190501.50401768'),
	'identifierSchemeCodeUnspsc190501.50401769': __('identifierSchemeCodeUnspsc190501.50401769'),
	'identifierSchemeCodeUnspsc190501.50401770': __('identifierSchemeCodeUnspsc190501.50401770'),
	'identifierSchemeCodeUnspsc190501.50401771': __('identifierSchemeCodeUnspsc190501.50401771'),
	'identifierSchemeCodeUnspsc190501.50401772': __('identifierSchemeCodeUnspsc190501.50401772'),
	'identifierSchemeCodeUnspsc190501.50401773': __('identifierSchemeCodeUnspsc190501.50401773'),
	'identifierSchemeCodeUnspsc190501.50401774': __('identifierSchemeCodeUnspsc190501.50401774'),
	'identifierSchemeCodeUnspsc190501.50401775': __('identifierSchemeCodeUnspsc190501.50401775'),
	'identifierSchemeCodeUnspsc190501.50401776': __('identifierSchemeCodeUnspsc190501.50401776'),
	'identifierSchemeCodeUnspsc190501.50401777': __('identifierSchemeCodeUnspsc190501.50401777'),
	'identifierSchemeCodeUnspsc190501.50401778': __('identifierSchemeCodeUnspsc190501.50401778'),
	'identifierSchemeCodeUnspsc190501.50401779': __('identifierSchemeCodeUnspsc190501.50401779'),
	'identifierSchemeCodeUnspsc190501.50401780': __('identifierSchemeCodeUnspsc190501.50401780'),
	'identifierSchemeCodeUnspsc190501.50401781': __('identifierSchemeCodeUnspsc190501.50401781'),
	'identifierSchemeCodeUnspsc190501.50401782': __('identifierSchemeCodeUnspsc190501.50401782'),
	'identifierSchemeCodeUnspsc190501.50401800': __('identifierSchemeCodeUnspsc190501.50401800'),
	'identifierSchemeCodeUnspsc190501.50401801': __('identifierSchemeCodeUnspsc190501.50401801'),
	'identifierSchemeCodeUnspsc190501.50401802': __('identifierSchemeCodeUnspsc190501.50401802'),
	'identifierSchemeCodeUnspsc190501.50401803': __('identifierSchemeCodeUnspsc190501.50401803'),
	'identifierSchemeCodeUnspsc190501.50401804': __('identifierSchemeCodeUnspsc190501.50401804'),
	'identifierSchemeCodeUnspsc190501.50401805': __('identifierSchemeCodeUnspsc190501.50401805'),
	'identifierSchemeCodeUnspsc190501.50401806': __('identifierSchemeCodeUnspsc190501.50401806'),
	'identifierSchemeCodeUnspsc190501.50401807': __('identifierSchemeCodeUnspsc190501.50401807'),
	'identifierSchemeCodeUnspsc190501.50401808': __('identifierSchemeCodeUnspsc190501.50401808'),
	'identifierSchemeCodeUnspsc190501.50401809': __('identifierSchemeCodeUnspsc190501.50401809'),
	'identifierSchemeCodeUnspsc190501.50401810': __('identifierSchemeCodeUnspsc190501.50401810'),
	'identifierSchemeCodeUnspsc190501.50401811': __('identifierSchemeCodeUnspsc190501.50401811'),
	'identifierSchemeCodeUnspsc190501.50401812': __('identifierSchemeCodeUnspsc190501.50401812'),
	'identifierSchemeCodeUnspsc190501.50401813': __('identifierSchemeCodeUnspsc190501.50401813'),
	'identifierSchemeCodeUnspsc190501.50401814': __('identifierSchemeCodeUnspsc190501.50401814'),
	'identifierSchemeCodeUnspsc190501.50401815': __('identifierSchemeCodeUnspsc190501.50401815'),
	'identifierSchemeCodeUnspsc190501.50401816': __('identifierSchemeCodeUnspsc190501.50401816'),
	'identifierSchemeCodeUnspsc190501.50401817': __('identifierSchemeCodeUnspsc190501.50401817'),
	'identifierSchemeCodeUnspsc190501.50401818': __('identifierSchemeCodeUnspsc190501.50401818'),
	'identifierSchemeCodeUnspsc190501.50401819': __('identifierSchemeCodeUnspsc190501.50401819'),
	'identifierSchemeCodeUnspsc190501.50401820': __('identifierSchemeCodeUnspsc190501.50401820'),
	'identifierSchemeCodeUnspsc190501.50401821': __('identifierSchemeCodeUnspsc190501.50401821'),
	'identifierSchemeCodeUnspsc190501.50401822': __('identifierSchemeCodeUnspsc190501.50401822'),
	'identifierSchemeCodeUnspsc190501.50401823': __('identifierSchemeCodeUnspsc190501.50401823'),
	'identifierSchemeCodeUnspsc190501.50401824': __('identifierSchemeCodeUnspsc190501.50401824'),
	'identifierSchemeCodeUnspsc190501.50401825': __('identifierSchemeCodeUnspsc190501.50401825'),
	'identifierSchemeCodeUnspsc190501.50401826': __('identifierSchemeCodeUnspsc190501.50401826'),
	'identifierSchemeCodeUnspsc190501.50401827': __('identifierSchemeCodeUnspsc190501.50401827'),
	'identifierSchemeCodeUnspsc190501.50401828': __('identifierSchemeCodeUnspsc190501.50401828'),
	'identifierSchemeCodeUnspsc190501.50401829': __('identifierSchemeCodeUnspsc190501.50401829'),
	'identifierSchemeCodeUnspsc190501.50401830': __('identifierSchemeCodeUnspsc190501.50401830'),
	'identifierSchemeCodeUnspsc190501.50401831': __('identifierSchemeCodeUnspsc190501.50401831'),
	'identifierSchemeCodeUnspsc190501.50401832': __('identifierSchemeCodeUnspsc190501.50401832'),
	'identifierSchemeCodeUnspsc190501.50401833': __('identifierSchemeCodeUnspsc190501.50401833'),
	'identifierSchemeCodeUnspsc190501.50401834': __('identifierSchemeCodeUnspsc190501.50401834'),
	'identifierSchemeCodeUnspsc190501.50401835': __('identifierSchemeCodeUnspsc190501.50401835'),
	'identifierSchemeCodeUnspsc190501.50401836': __('identifierSchemeCodeUnspsc190501.50401836'),
	'identifierSchemeCodeUnspsc190501.50401837': __('identifierSchemeCodeUnspsc190501.50401837'),
	'identifierSchemeCodeUnspsc190501.50401838': __('identifierSchemeCodeUnspsc190501.50401838'),
	'identifierSchemeCodeUnspsc190501.50401839': __('identifierSchemeCodeUnspsc190501.50401839'),
	'identifierSchemeCodeUnspsc190501.50401840': __('identifierSchemeCodeUnspsc190501.50401840'),
	'identifierSchemeCodeUnspsc190501.50401841': __('identifierSchemeCodeUnspsc190501.50401841'),
	'identifierSchemeCodeUnspsc190501.50401842': __('identifierSchemeCodeUnspsc190501.50401842'),
	'identifierSchemeCodeUnspsc190501.50401843': __('identifierSchemeCodeUnspsc190501.50401843'),
	'identifierSchemeCodeUnspsc190501.50401844': __('identifierSchemeCodeUnspsc190501.50401844'),
	'identifierSchemeCodeUnspsc190501.50401845': __('identifierSchemeCodeUnspsc190501.50401845'),
	'identifierSchemeCodeUnspsc190501.50401846': __('identifierSchemeCodeUnspsc190501.50401846'),
	'identifierSchemeCodeUnspsc190501.50401847': __('identifierSchemeCodeUnspsc190501.50401847'),
	'identifierSchemeCodeUnspsc190501.50401848': __('identifierSchemeCodeUnspsc190501.50401848'),
	'identifierSchemeCodeUnspsc190501.50401849': __('identifierSchemeCodeUnspsc190501.50401849'),
	'identifierSchemeCodeUnspsc190501.50401850': __('identifierSchemeCodeUnspsc190501.50401850'),
	'identifierSchemeCodeUnspsc190501.50401851': __('identifierSchemeCodeUnspsc190501.50401851'),
	'identifierSchemeCodeUnspsc190501.50401852': __('identifierSchemeCodeUnspsc190501.50401852'),
	'identifierSchemeCodeUnspsc190501.50401853': __('identifierSchemeCodeUnspsc190501.50401853'),
	'identifierSchemeCodeUnspsc190501.50401854': __('identifierSchemeCodeUnspsc190501.50401854'),
	'identifierSchemeCodeUnspsc190501.50401900': __('identifierSchemeCodeUnspsc190501.50401900'),
	'identifierSchemeCodeUnspsc190501.50401901': __('identifierSchemeCodeUnspsc190501.50401901'),
	'identifierSchemeCodeUnspsc190501.50401902': __('identifierSchemeCodeUnspsc190501.50401902'),
	'identifierSchemeCodeUnspsc190501.50401903': __('identifierSchemeCodeUnspsc190501.50401903'),
	'identifierSchemeCodeUnspsc190501.50401904': __('identifierSchemeCodeUnspsc190501.50401904'),
	'identifierSchemeCodeUnspsc190501.50401905': __('identifierSchemeCodeUnspsc190501.50401905'),
	'identifierSchemeCodeUnspsc190501.50401906': __('identifierSchemeCodeUnspsc190501.50401906'),
	'identifierSchemeCodeUnspsc190501.50401907': __('identifierSchemeCodeUnspsc190501.50401907'),
	'identifierSchemeCodeUnspsc190501.50401908': __('identifierSchemeCodeUnspsc190501.50401908'),
	'identifierSchemeCodeUnspsc190501.50401909': __('identifierSchemeCodeUnspsc190501.50401909'),
	'identifierSchemeCodeUnspsc190501.50401910': __('identifierSchemeCodeUnspsc190501.50401910'),
	'identifierSchemeCodeUnspsc190501.50401911': __('identifierSchemeCodeUnspsc190501.50401911'),
	'identifierSchemeCodeUnspsc190501.50401912': __('identifierSchemeCodeUnspsc190501.50401912'),
	'identifierSchemeCodeUnspsc190501.50401913': __('identifierSchemeCodeUnspsc190501.50401913'),
	'identifierSchemeCodeUnspsc190501.50401914': __('identifierSchemeCodeUnspsc190501.50401914'),
	'identifierSchemeCodeUnspsc190501.50401915': __('identifierSchemeCodeUnspsc190501.50401915'),
	'identifierSchemeCodeUnspsc190501.50401916': __('identifierSchemeCodeUnspsc190501.50401916'),
	'identifierSchemeCodeUnspsc190501.50401917': __('identifierSchemeCodeUnspsc190501.50401917'),
	'identifierSchemeCodeUnspsc190501.50401918': __('identifierSchemeCodeUnspsc190501.50401918'),
	'identifierSchemeCodeUnspsc190501.50401919': __('identifierSchemeCodeUnspsc190501.50401919'),
	'identifierSchemeCodeUnspsc190501.50401920': __('identifierSchemeCodeUnspsc190501.50401920'),
	'identifierSchemeCodeUnspsc190501.50401921': __('identifierSchemeCodeUnspsc190501.50401921'),
	'identifierSchemeCodeUnspsc190501.50401922': __('identifierSchemeCodeUnspsc190501.50401922'),
	'identifierSchemeCodeUnspsc190501.50402000': __('identifierSchemeCodeUnspsc190501.50402000'),
	'identifierSchemeCodeUnspsc190501.50402001': __('identifierSchemeCodeUnspsc190501.50402001'),
	'identifierSchemeCodeUnspsc190501.50402002': __('identifierSchemeCodeUnspsc190501.50402002'),
	'identifierSchemeCodeUnspsc190501.50402003': __('identifierSchemeCodeUnspsc190501.50402003'),
	'identifierSchemeCodeUnspsc190501.50402004': __('identifierSchemeCodeUnspsc190501.50402004'),
	'identifierSchemeCodeUnspsc190501.50402100': __('identifierSchemeCodeUnspsc190501.50402100'),
	'identifierSchemeCodeUnspsc190501.50402101': __('identifierSchemeCodeUnspsc190501.50402101'),
	'identifierSchemeCodeUnspsc190501.50402102': __('identifierSchemeCodeUnspsc190501.50402102'),
	'identifierSchemeCodeUnspsc190501.50402103': __('identifierSchemeCodeUnspsc190501.50402103'),
	'identifierSchemeCodeUnspsc190501.50402104': __('identifierSchemeCodeUnspsc190501.50402104'),
	'identifierSchemeCodeUnspsc190501.50402105': __('identifierSchemeCodeUnspsc190501.50402105'),
	'identifierSchemeCodeUnspsc190501.50402106': __('identifierSchemeCodeUnspsc190501.50402106'),
	'identifierSchemeCodeUnspsc190501.50402107': __('identifierSchemeCodeUnspsc190501.50402107'),
	'identifierSchemeCodeUnspsc190501.50402200': __('identifierSchemeCodeUnspsc190501.50402200'),
	'identifierSchemeCodeUnspsc190501.50402201': __('identifierSchemeCodeUnspsc190501.50402201'),
	'identifierSchemeCodeUnspsc190501.50402202': __('identifierSchemeCodeUnspsc190501.50402202'),
	'identifierSchemeCodeUnspsc190501.50402203': __('identifierSchemeCodeUnspsc190501.50402203'),
	'identifierSchemeCodeUnspsc190501.50402204': __('identifierSchemeCodeUnspsc190501.50402204'),
	'identifierSchemeCodeUnspsc190501.50402205': __('identifierSchemeCodeUnspsc190501.50402205'),
	'identifierSchemeCodeUnspsc190501.50402206': __('identifierSchemeCodeUnspsc190501.50402206'),
	'identifierSchemeCodeUnspsc190501.50402207': __('identifierSchemeCodeUnspsc190501.50402207'),
	'identifierSchemeCodeUnspsc190501.50402208': __('identifierSchemeCodeUnspsc190501.50402208'),
	'identifierSchemeCodeUnspsc190501.50402209': __('identifierSchemeCodeUnspsc190501.50402209'),
	'identifierSchemeCodeUnspsc190501.50402210': __('identifierSchemeCodeUnspsc190501.50402210'),
	'identifierSchemeCodeUnspsc190501.50402211': __('identifierSchemeCodeUnspsc190501.50402211'),
	'identifierSchemeCodeUnspsc190501.50402300': __('identifierSchemeCodeUnspsc190501.50402300'),
	'identifierSchemeCodeUnspsc190501.50402301': __('identifierSchemeCodeUnspsc190501.50402301'),
	'identifierSchemeCodeUnspsc190501.50402302': __('identifierSchemeCodeUnspsc190501.50402302'),
	'identifierSchemeCodeUnspsc190501.50402303': __('identifierSchemeCodeUnspsc190501.50402303'),
	'identifierSchemeCodeUnspsc190501.50402304': __('identifierSchemeCodeUnspsc190501.50402304'),
	'identifierSchemeCodeUnspsc190501.50402305': __('identifierSchemeCodeUnspsc190501.50402305'),
	'identifierSchemeCodeUnspsc190501.50402400': __('identifierSchemeCodeUnspsc190501.50402400'),
	'identifierSchemeCodeUnspsc190501.50402401': __('identifierSchemeCodeUnspsc190501.50402401'),
	'identifierSchemeCodeUnspsc190501.50402402': __('identifierSchemeCodeUnspsc190501.50402402'),
	'identifierSchemeCodeUnspsc190501.50402500': __('identifierSchemeCodeUnspsc190501.50402500'),
	'identifierSchemeCodeUnspsc190501.50402501': __('identifierSchemeCodeUnspsc190501.50402501'),
	'identifierSchemeCodeUnspsc190501.50402502': __('identifierSchemeCodeUnspsc190501.50402502'),
	'identifierSchemeCodeUnspsc190501.50402503': __('identifierSchemeCodeUnspsc190501.50402503'),
	'identifierSchemeCodeUnspsc190501.50402504': __('identifierSchemeCodeUnspsc190501.50402504'),
	'identifierSchemeCodeUnspsc190501.50402505': __('identifierSchemeCodeUnspsc190501.50402505'),
	'identifierSchemeCodeUnspsc190501.50402506': __('identifierSchemeCodeUnspsc190501.50402506'),
	'identifierSchemeCodeUnspsc190501.50402507': __('identifierSchemeCodeUnspsc190501.50402507'),
	'identifierSchemeCodeUnspsc190501.50402600': __('identifierSchemeCodeUnspsc190501.50402600'),
	'identifierSchemeCodeUnspsc190501.50402601': __('identifierSchemeCodeUnspsc190501.50402601'),
	'identifierSchemeCodeUnspsc190501.50402602': __('identifierSchemeCodeUnspsc190501.50402602'),
	'identifierSchemeCodeUnspsc190501.50402603': __('identifierSchemeCodeUnspsc190501.50402603'),
	'identifierSchemeCodeUnspsc190501.50402604': __('identifierSchemeCodeUnspsc190501.50402604'),
	'identifierSchemeCodeUnspsc190501.50402605': __('identifierSchemeCodeUnspsc190501.50402605'),
	'identifierSchemeCodeUnspsc190501.50402606': __('identifierSchemeCodeUnspsc190501.50402606'),
	'identifierSchemeCodeUnspsc190501.50402607': __('identifierSchemeCodeUnspsc190501.50402607'),
	'identifierSchemeCodeUnspsc190501.50402608': __('identifierSchemeCodeUnspsc190501.50402608'),
	'identifierSchemeCodeUnspsc190501.50402609': __('identifierSchemeCodeUnspsc190501.50402609'),
	'identifierSchemeCodeUnspsc190501.50402610': __('identifierSchemeCodeUnspsc190501.50402610'),
	'identifierSchemeCodeUnspsc190501.50402611': __('identifierSchemeCodeUnspsc190501.50402611'),
	'identifierSchemeCodeUnspsc190501.50402612': __('identifierSchemeCodeUnspsc190501.50402612'),
	'identifierSchemeCodeUnspsc190501.50402700': __('identifierSchemeCodeUnspsc190501.50402700'),
	'identifierSchemeCodeUnspsc190501.50402701': __('identifierSchemeCodeUnspsc190501.50402701'),
	'identifierSchemeCodeUnspsc190501.50402702': __('identifierSchemeCodeUnspsc190501.50402702'),
	'identifierSchemeCodeUnspsc190501.50402703': __('identifierSchemeCodeUnspsc190501.50402703'),
	'identifierSchemeCodeUnspsc190501.50402704': __('identifierSchemeCodeUnspsc190501.50402704'),
	'identifierSchemeCodeUnspsc190501.50402705': __('identifierSchemeCodeUnspsc190501.50402705'),
	'identifierSchemeCodeUnspsc190501.50402706': __('identifierSchemeCodeUnspsc190501.50402706'),
	'identifierSchemeCodeUnspsc190501.50402707': __('identifierSchemeCodeUnspsc190501.50402707'),
	'identifierSchemeCodeUnspsc190501.50402708': __('identifierSchemeCodeUnspsc190501.50402708'),
	'identifierSchemeCodeUnspsc190501.50402709': __('identifierSchemeCodeUnspsc190501.50402709'),
	'identifierSchemeCodeUnspsc190501.50402710': __('identifierSchemeCodeUnspsc190501.50402710'),
	'identifierSchemeCodeUnspsc190501.50402711': __('identifierSchemeCodeUnspsc190501.50402711'),
	'identifierSchemeCodeUnspsc190501.50402712': __('identifierSchemeCodeUnspsc190501.50402712'),
	'identifierSchemeCodeUnspsc190501.50402713': __('identifierSchemeCodeUnspsc190501.50402713'),
	'identifierSchemeCodeUnspsc190501.50402714': __('identifierSchemeCodeUnspsc190501.50402714'),
	'identifierSchemeCodeUnspsc190501.50402715': __('identifierSchemeCodeUnspsc190501.50402715'),
	'identifierSchemeCodeUnspsc190501.50402800': __('identifierSchemeCodeUnspsc190501.50402800'),
	'identifierSchemeCodeUnspsc190501.50402801': __('identifierSchemeCodeUnspsc190501.50402801'),
	'identifierSchemeCodeUnspsc190501.50402802': __('identifierSchemeCodeUnspsc190501.50402802'),
	'identifierSchemeCodeUnspsc190501.50402803': __('identifierSchemeCodeUnspsc190501.50402803'),
	'identifierSchemeCodeUnspsc190501.50402804': __('identifierSchemeCodeUnspsc190501.50402804'),
	'identifierSchemeCodeUnspsc190501.50402805': __('identifierSchemeCodeUnspsc190501.50402805'),
	'identifierSchemeCodeUnspsc190501.50402806': __('identifierSchemeCodeUnspsc190501.50402806'),
	'identifierSchemeCodeUnspsc190501.50402807': __('identifierSchemeCodeUnspsc190501.50402807'),
	'identifierSchemeCodeUnspsc190501.50402808': __('identifierSchemeCodeUnspsc190501.50402808'),
	'identifierSchemeCodeUnspsc190501.50402900': __('identifierSchemeCodeUnspsc190501.50402900'),
	'identifierSchemeCodeUnspsc190501.50402901': __('identifierSchemeCodeUnspsc190501.50402901'),
	'identifierSchemeCodeUnspsc190501.50402902': __('identifierSchemeCodeUnspsc190501.50402902'),
	'identifierSchemeCodeUnspsc190501.50402903': __('identifierSchemeCodeUnspsc190501.50402903'),
	'identifierSchemeCodeUnspsc190501.50402904': __('identifierSchemeCodeUnspsc190501.50402904'),
	'identifierSchemeCodeUnspsc190501.50402905': __('identifierSchemeCodeUnspsc190501.50402905'),
	'identifierSchemeCodeUnspsc190501.50402906': __('identifierSchemeCodeUnspsc190501.50402906'),
	'identifierSchemeCodeUnspsc190501.50402907': __('identifierSchemeCodeUnspsc190501.50402907'),
	'identifierSchemeCodeUnspsc190501.50402908': __('identifierSchemeCodeUnspsc190501.50402908'),
	'identifierSchemeCodeUnspsc190501.50402909': __('identifierSchemeCodeUnspsc190501.50402909'),
	'identifierSchemeCodeUnspsc190501.50402910': __('identifierSchemeCodeUnspsc190501.50402910'),
	'identifierSchemeCodeUnspsc190501.50402911': __('identifierSchemeCodeUnspsc190501.50402911'),
	'identifierSchemeCodeUnspsc190501.50403000': __('identifierSchemeCodeUnspsc190501.50403000'),
	'identifierSchemeCodeUnspsc190501.50403001': __('identifierSchemeCodeUnspsc190501.50403001'),
	'identifierSchemeCodeUnspsc190501.50403002': __('identifierSchemeCodeUnspsc190501.50403002'),
	'identifierSchemeCodeUnspsc190501.50403003': __('identifierSchemeCodeUnspsc190501.50403003'),
	'identifierSchemeCodeUnspsc190501.50403004': __('identifierSchemeCodeUnspsc190501.50403004'),
	'identifierSchemeCodeUnspsc190501.50403005': __('identifierSchemeCodeUnspsc190501.50403005'),
	'identifierSchemeCodeUnspsc190501.50403006': __('identifierSchemeCodeUnspsc190501.50403006'),
	'identifierSchemeCodeUnspsc190501.50403007': __('identifierSchemeCodeUnspsc190501.50403007'),
	'identifierSchemeCodeUnspsc190501.50403008': __('identifierSchemeCodeUnspsc190501.50403008'),
	'identifierSchemeCodeUnspsc190501.50403009': __('identifierSchemeCodeUnspsc190501.50403009'),
	'identifierSchemeCodeUnspsc190501.50403010': __('identifierSchemeCodeUnspsc190501.50403010'),
	'identifierSchemeCodeUnspsc190501.50403011': __('identifierSchemeCodeUnspsc190501.50403011'),
	'identifierSchemeCodeUnspsc190501.50403012': __('identifierSchemeCodeUnspsc190501.50403012'),
	'identifierSchemeCodeUnspsc190501.50403013': __('identifierSchemeCodeUnspsc190501.50403013'),
	'identifierSchemeCodeUnspsc190501.50403014': __('identifierSchemeCodeUnspsc190501.50403014'),
	'identifierSchemeCodeUnspsc190501.50403015': __('identifierSchemeCodeUnspsc190501.50403015'),
	'identifierSchemeCodeUnspsc190501.50403016': __('identifierSchemeCodeUnspsc190501.50403016'),
	'identifierSchemeCodeUnspsc190501.50403100': __('identifierSchemeCodeUnspsc190501.50403100'),
	'identifierSchemeCodeUnspsc190501.50403101': __('identifierSchemeCodeUnspsc190501.50403101'),
	'identifierSchemeCodeUnspsc190501.50403102': __('identifierSchemeCodeUnspsc190501.50403102'),
	'identifierSchemeCodeUnspsc190501.50403200': __('identifierSchemeCodeUnspsc190501.50403200'),
	'identifierSchemeCodeUnspsc190501.50403201': __('identifierSchemeCodeUnspsc190501.50403201'),
	'identifierSchemeCodeUnspsc190501.50403202': __('identifierSchemeCodeUnspsc190501.50403202'),
	'identifierSchemeCodeUnspsc190501.50403203': __('identifierSchemeCodeUnspsc190501.50403203'),
	'identifierSchemeCodeUnspsc190501.50403204': __('identifierSchemeCodeUnspsc190501.50403204'),
	'identifierSchemeCodeUnspsc190501.50403205': __('identifierSchemeCodeUnspsc190501.50403205'),
	'identifierSchemeCodeUnspsc190501.50403206': __('identifierSchemeCodeUnspsc190501.50403206'),
	'identifierSchemeCodeUnspsc190501.50403207': __('identifierSchemeCodeUnspsc190501.50403207'),
	'identifierSchemeCodeUnspsc190501.50403208': __('identifierSchemeCodeUnspsc190501.50403208'),
	'identifierSchemeCodeUnspsc190501.50403209': __('identifierSchemeCodeUnspsc190501.50403209'),
	'identifierSchemeCodeUnspsc190501.50403210': __('identifierSchemeCodeUnspsc190501.50403210'),
	'identifierSchemeCodeUnspsc190501.50403211': __('identifierSchemeCodeUnspsc190501.50403211'),
	'identifierSchemeCodeUnspsc190501.50403212': __('identifierSchemeCodeUnspsc190501.50403212'),
	'identifierSchemeCodeUnspsc190501.50403213': __('identifierSchemeCodeUnspsc190501.50403213'),
	'identifierSchemeCodeUnspsc190501.50403214': __('identifierSchemeCodeUnspsc190501.50403214'),
	'identifierSchemeCodeUnspsc190501.50403215': __('identifierSchemeCodeUnspsc190501.50403215'),
	'identifierSchemeCodeUnspsc190501.50403216': __('identifierSchemeCodeUnspsc190501.50403216'),
	'identifierSchemeCodeUnspsc190501.50403217': __('identifierSchemeCodeUnspsc190501.50403217'),
	'identifierSchemeCodeUnspsc190501.50403218': __('identifierSchemeCodeUnspsc190501.50403218'),
	'identifierSchemeCodeUnspsc190501.50403219': __('identifierSchemeCodeUnspsc190501.50403219'),
	'identifierSchemeCodeUnspsc190501.50403220': __('identifierSchemeCodeUnspsc190501.50403220'),
	'identifierSchemeCodeUnspsc190501.50403221': __('identifierSchemeCodeUnspsc190501.50403221'),
	'identifierSchemeCodeUnspsc190501.50403222': __('identifierSchemeCodeUnspsc190501.50403222'),
	'identifierSchemeCodeUnspsc190501.50403223': __('identifierSchemeCodeUnspsc190501.50403223'),
	'identifierSchemeCodeUnspsc190501.50403224': __('identifierSchemeCodeUnspsc190501.50403224'),
	'identifierSchemeCodeUnspsc190501.50403225': __('identifierSchemeCodeUnspsc190501.50403225'),
	'identifierSchemeCodeUnspsc190501.50403226': __('identifierSchemeCodeUnspsc190501.50403226'),
	'identifierSchemeCodeUnspsc190501.50403227': __('identifierSchemeCodeUnspsc190501.50403227'),
	'identifierSchemeCodeUnspsc190501.50403228': __('identifierSchemeCodeUnspsc190501.50403228'),
	'identifierSchemeCodeUnspsc190501.50403229': __('identifierSchemeCodeUnspsc190501.50403229'),
	'identifierSchemeCodeUnspsc190501.50403230': __('identifierSchemeCodeUnspsc190501.50403230'),
	'identifierSchemeCodeUnspsc190501.50403231': __('identifierSchemeCodeUnspsc190501.50403231'),
	'identifierSchemeCodeUnspsc190501.50403232': __('identifierSchemeCodeUnspsc190501.50403232'),
	'identifierSchemeCodeUnspsc190501.50403233': __('identifierSchemeCodeUnspsc190501.50403233'),
	'identifierSchemeCodeUnspsc190501.50403234': __('identifierSchemeCodeUnspsc190501.50403234'),
	'identifierSchemeCodeUnspsc190501.50403235': __('identifierSchemeCodeUnspsc190501.50403235'),
	'identifierSchemeCodeUnspsc190501.50403236': __('identifierSchemeCodeUnspsc190501.50403236'),
	'identifierSchemeCodeUnspsc190501.50403237': __('identifierSchemeCodeUnspsc190501.50403237'),
	'identifierSchemeCodeUnspsc190501.50403238': __('identifierSchemeCodeUnspsc190501.50403238'),
	'identifierSchemeCodeUnspsc190501.50403239': __('identifierSchemeCodeUnspsc190501.50403239'),
	'identifierSchemeCodeUnspsc190501.50403240': __('identifierSchemeCodeUnspsc190501.50403240'),
	'identifierSchemeCodeUnspsc190501.50403241': __('identifierSchemeCodeUnspsc190501.50403241'),
	'identifierSchemeCodeUnspsc190501.50403242': __('identifierSchemeCodeUnspsc190501.50403242'),
	'identifierSchemeCodeUnspsc190501.50403243': __('identifierSchemeCodeUnspsc190501.50403243'),
	'identifierSchemeCodeUnspsc190501.50403244': __('identifierSchemeCodeUnspsc190501.50403244'),
	'identifierSchemeCodeUnspsc190501.50403245': __('identifierSchemeCodeUnspsc190501.50403245'),
	'identifierSchemeCodeUnspsc190501.50403246': __('identifierSchemeCodeUnspsc190501.50403246'),
	'identifierSchemeCodeUnspsc190501.50403247': __('identifierSchemeCodeUnspsc190501.50403247'),
	'identifierSchemeCodeUnspsc190501.50403248': __('identifierSchemeCodeUnspsc190501.50403248'),
	'identifierSchemeCodeUnspsc190501.50403249': __('identifierSchemeCodeUnspsc190501.50403249'),
	'identifierSchemeCodeUnspsc190501.50403250': __('identifierSchemeCodeUnspsc190501.50403250'),
	'identifierSchemeCodeUnspsc190501.50403251': __('identifierSchemeCodeUnspsc190501.50403251'),
	'identifierSchemeCodeUnspsc190501.50403252': __('identifierSchemeCodeUnspsc190501.50403252'),
	'identifierSchemeCodeUnspsc190501.50403253': __('identifierSchemeCodeUnspsc190501.50403253'),
	'identifierSchemeCodeUnspsc190501.50403254': __('identifierSchemeCodeUnspsc190501.50403254'),
	'identifierSchemeCodeUnspsc190501.50403255': __('identifierSchemeCodeUnspsc190501.50403255'),
	'identifierSchemeCodeUnspsc190501.50403256': __('identifierSchemeCodeUnspsc190501.50403256'),
	'identifierSchemeCodeUnspsc190501.50403257': __('identifierSchemeCodeUnspsc190501.50403257'),
	'identifierSchemeCodeUnspsc190501.50403258': __('identifierSchemeCodeUnspsc190501.50403258'),
	'identifierSchemeCodeUnspsc190501.50403259': __('identifierSchemeCodeUnspsc190501.50403259'),
	'identifierSchemeCodeUnspsc190501.50403260': __('identifierSchemeCodeUnspsc190501.50403260'),
	'identifierSchemeCodeUnspsc190501.50403261': __('identifierSchemeCodeUnspsc190501.50403261'),
	'identifierSchemeCodeUnspsc190501.50403262': __('identifierSchemeCodeUnspsc190501.50403262'),
	'identifierSchemeCodeUnspsc190501.50403263': __('identifierSchemeCodeUnspsc190501.50403263'),
	'identifierSchemeCodeUnspsc190501.50403264': __('identifierSchemeCodeUnspsc190501.50403264'),
	'identifierSchemeCodeUnspsc190501.50403265': __('identifierSchemeCodeUnspsc190501.50403265'),
	'identifierSchemeCodeUnspsc190501.50403266': __('identifierSchemeCodeUnspsc190501.50403266'),
	'identifierSchemeCodeUnspsc190501.50403267': __('identifierSchemeCodeUnspsc190501.50403267'),
	'identifierSchemeCodeUnspsc190501.50403268': __('identifierSchemeCodeUnspsc190501.50403268'),
	'identifierSchemeCodeUnspsc190501.50403269': __('identifierSchemeCodeUnspsc190501.50403269'),
	'identifierSchemeCodeUnspsc190501.50403300': __('identifierSchemeCodeUnspsc190501.50403300'),
	'identifierSchemeCodeUnspsc190501.50403301': __('identifierSchemeCodeUnspsc190501.50403301'),
	'identifierSchemeCodeUnspsc190501.50403302': __('identifierSchemeCodeUnspsc190501.50403302'),
	'identifierSchemeCodeUnspsc190501.50403303': __('identifierSchemeCodeUnspsc190501.50403303'),
	'identifierSchemeCodeUnspsc190501.50403304': __('identifierSchemeCodeUnspsc190501.50403304'),
	'identifierSchemeCodeUnspsc190501.50403400': __('identifierSchemeCodeUnspsc190501.50403400'),
	'identifierSchemeCodeUnspsc190501.50403401': __('identifierSchemeCodeUnspsc190501.50403401'),
	'identifierSchemeCodeUnspsc190501.50403402': __('identifierSchemeCodeUnspsc190501.50403402'),
	'identifierSchemeCodeUnspsc190501.50403403': __('identifierSchemeCodeUnspsc190501.50403403'),
	'identifierSchemeCodeUnspsc190501.50403404': __('identifierSchemeCodeUnspsc190501.50403404'),
	'identifierSchemeCodeUnspsc190501.50403405': __('identifierSchemeCodeUnspsc190501.50403405'),
	'identifierSchemeCodeUnspsc190501.50403406': __('identifierSchemeCodeUnspsc190501.50403406'),
	'identifierSchemeCodeUnspsc190501.50403407': __('identifierSchemeCodeUnspsc190501.50403407'),
	'identifierSchemeCodeUnspsc190501.50403408': __('identifierSchemeCodeUnspsc190501.50403408'),
	'identifierSchemeCodeUnspsc190501.50403409': __('identifierSchemeCodeUnspsc190501.50403409'),
	'identifierSchemeCodeUnspsc190501.50403410': __('identifierSchemeCodeUnspsc190501.50403410'),
	'identifierSchemeCodeUnspsc190501.50403411': __('identifierSchemeCodeUnspsc190501.50403411'),
	'identifierSchemeCodeUnspsc190501.50403412': __('identifierSchemeCodeUnspsc190501.50403412'),
	'identifierSchemeCodeUnspsc190501.50403413': __('identifierSchemeCodeUnspsc190501.50403413'),
	'identifierSchemeCodeUnspsc190501.50403414': __('identifierSchemeCodeUnspsc190501.50403414'),
	'identifierSchemeCodeUnspsc190501.50403415': __('identifierSchemeCodeUnspsc190501.50403415'),
	'identifierSchemeCodeUnspsc190501.50403416': __('identifierSchemeCodeUnspsc190501.50403416'),
	'identifierSchemeCodeUnspsc190501.50403417': __('identifierSchemeCodeUnspsc190501.50403417'),
	'identifierSchemeCodeUnspsc190501.50403418': __('identifierSchemeCodeUnspsc190501.50403418'),
	'identifierSchemeCodeUnspsc190501.50403419': __('identifierSchemeCodeUnspsc190501.50403419'),
	'identifierSchemeCodeUnspsc190501.50403420': __('identifierSchemeCodeUnspsc190501.50403420'),
	'identifierSchemeCodeUnspsc190501.50403421': __('identifierSchemeCodeUnspsc190501.50403421'),
	'identifierSchemeCodeUnspsc190501.50403422': __('identifierSchemeCodeUnspsc190501.50403422'),
	'identifierSchemeCodeUnspsc190501.50403423': __('identifierSchemeCodeUnspsc190501.50403423'),
	'identifierSchemeCodeUnspsc190501.50403500': __('identifierSchemeCodeUnspsc190501.50403500'),
	'identifierSchemeCodeUnspsc190501.50403501': __('identifierSchemeCodeUnspsc190501.50403501'),
	'identifierSchemeCodeUnspsc190501.50403502': __('identifierSchemeCodeUnspsc190501.50403502'),
	'identifierSchemeCodeUnspsc190501.50403503': __('identifierSchemeCodeUnspsc190501.50403503'),
	'identifierSchemeCodeUnspsc190501.50403504': __('identifierSchemeCodeUnspsc190501.50403504'),
	'identifierSchemeCodeUnspsc190501.50403505': __('identifierSchemeCodeUnspsc190501.50403505'),
	'identifierSchemeCodeUnspsc190501.50403506': __('identifierSchemeCodeUnspsc190501.50403506'),
	'identifierSchemeCodeUnspsc190501.50403507': __('identifierSchemeCodeUnspsc190501.50403507'),
	'identifierSchemeCodeUnspsc190501.50403508': __('identifierSchemeCodeUnspsc190501.50403508'),
	'identifierSchemeCodeUnspsc190501.50403509': __('identifierSchemeCodeUnspsc190501.50403509'),
	'identifierSchemeCodeUnspsc190501.50403510': __('identifierSchemeCodeUnspsc190501.50403510'),
	'identifierSchemeCodeUnspsc190501.50403511': __('identifierSchemeCodeUnspsc190501.50403511'),
	'identifierSchemeCodeUnspsc190501.50403512': __('identifierSchemeCodeUnspsc190501.50403512'),
	'identifierSchemeCodeUnspsc190501.50403513': __('identifierSchemeCodeUnspsc190501.50403513'),
	'identifierSchemeCodeUnspsc190501.50403514': __('identifierSchemeCodeUnspsc190501.50403514'),
	'identifierSchemeCodeUnspsc190501.50403515': __('identifierSchemeCodeUnspsc190501.50403515'),
	'identifierSchemeCodeUnspsc190501.50403516': __('identifierSchemeCodeUnspsc190501.50403516'),
	'identifierSchemeCodeUnspsc190501.50403517': __('identifierSchemeCodeUnspsc190501.50403517'),
	'identifierSchemeCodeUnspsc190501.50403518': __('identifierSchemeCodeUnspsc190501.50403518'),
	'identifierSchemeCodeUnspsc190501.50403519': __('identifierSchemeCodeUnspsc190501.50403519'),
	'identifierSchemeCodeUnspsc190501.50403520': __('identifierSchemeCodeUnspsc190501.50403520'),
	'identifierSchemeCodeUnspsc190501.50403600': __('identifierSchemeCodeUnspsc190501.50403600'),
	'identifierSchemeCodeUnspsc190501.50403601': __('identifierSchemeCodeUnspsc190501.50403601'),
	'identifierSchemeCodeUnspsc190501.50403602': __('identifierSchemeCodeUnspsc190501.50403602'),
	'identifierSchemeCodeUnspsc190501.50403603': __('identifierSchemeCodeUnspsc190501.50403603'),
	'identifierSchemeCodeUnspsc190501.50403604': __('identifierSchemeCodeUnspsc190501.50403604'),
	'identifierSchemeCodeUnspsc190501.50403605': __('identifierSchemeCodeUnspsc190501.50403605'),
	'identifierSchemeCodeUnspsc190501.50403606': __('identifierSchemeCodeUnspsc190501.50403606'),
	'identifierSchemeCodeUnspsc190501.50403607': __('identifierSchemeCodeUnspsc190501.50403607'),
	'identifierSchemeCodeUnspsc190501.50403608': __('identifierSchemeCodeUnspsc190501.50403608'),
	'identifierSchemeCodeUnspsc190501.50403609': __('identifierSchemeCodeUnspsc190501.50403609'),
	'identifierSchemeCodeUnspsc190501.50403610': __('identifierSchemeCodeUnspsc190501.50403610'),
	'identifierSchemeCodeUnspsc190501.50403611': __('identifierSchemeCodeUnspsc190501.50403611'),
	'identifierSchemeCodeUnspsc190501.50403612': __('identifierSchemeCodeUnspsc190501.50403612'),
	'identifierSchemeCodeUnspsc190501.50403613': __('identifierSchemeCodeUnspsc190501.50403613'),
	'identifierSchemeCodeUnspsc190501.50403614': __('identifierSchemeCodeUnspsc190501.50403614'),
	'identifierSchemeCodeUnspsc190501.50403700': __('identifierSchemeCodeUnspsc190501.50403700'),
	'identifierSchemeCodeUnspsc190501.50403701': __('identifierSchemeCodeUnspsc190501.50403701'),
	'identifierSchemeCodeUnspsc190501.50403702': __('identifierSchemeCodeUnspsc190501.50403702'),
	'identifierSchemeCodeUnspsc190501.50403703': __('identifierSchemeCodeUnspsc190501.50403703'),
	'identifierSchemeCodeUnspsc190501.50403704': __('identifierSchemeCodeUnspsc190501.50403704'),
	'identifierSchemeCodeUnspsc190501.50403705': __('identifierSchemeCodeUnspsc190501.50403705'),
	'identifierSchemeCodeUnspsc190501.50403706': __('identifierSchemeCodeUnspsc190501.50403706'),
	'identifierSchemeCodeUnspsc190501.50403707': __('identifierSchemeCodeUnspsc190501.50403707'),
	'identifierSchemeCodeUnspsc190501.50403800': __('identifierSchemeCodeUnspsc190501.50403800'),
	'identifierSchemeCodeUnspsc190501.50403801': __('identifierSchemeCodeUnspsc190501.50403801'),
	'identifierSchemeCodeUnspsc190501.50403802': __('identifierSchemeCodeUnspsc190501.50403802'),
	'identifierSchemeCodeUnspsc190501.50403803': __('identifierSchemeCodeUnspsc190501.50403803'),
	'identifierSchemeCodeUnspsc190501.50403804': __('identifierSchemeCodeUnspsc190501.50403804'),
	'identifierSchemeCodeUnspsc190501.50403805': __('identifierSchemeCodeUnspsc190501.50403805'),
	'identifierSchemeCodeUnspsc190501.50403806': __('identifierSchemeCodeUnspsc190501.50403806'),
	'identifierSchemeCodeUnspsc190501.50403807': __('identifierSchemeCodeUnspsc190501.50403807'),
	'identifierSchemeCodeUnspsc190501.50403808': __('identifierSchemeCodeUnspsc190501.50403808'),
	'identifierSchemeCodeUnspsc190501.50403809': __('identifierSchemeCodeUnspsc190501.50403809'),
	'identifierSchemeCodeUnspsc190501.50403810': __('identifierSchemeCodeUnspsc190501.50403810'),
	'identifierSchemeCodeUnspsc190501.50403811': __('identifierSchemeCodeUnspsc190501.50403811'),
	'identifierSchemeCodeUnspsc190501.50403900': __('identifierSchemeCodeUnspsc190501.50403900'),
	'identifierSchemeCodeUnspsc190501.50403901': __('identifierSchemeCodeUnspsc190501.50403901'),
	'identifierSchemeCodeUnspsc190501.50403902': __('identifierSchemeCodeUnspsc190501.50403902'),
	'identifierSchemeCodeUnspsc190501.50403903': __('identifierSchemeCodeUnspsc190501.50403903'),
	'identifierSchemeCodeUnspsc190501.50403904': __('identifierSchemeCodeUnspsc190501.50403904'),
	'identifierSchemeCodeUnspsc190501.50403905': __('identifierSchemeCodeUnspsc190501.50403905'),
	'identifierSchemeCodeUnspsc190501.50403906': __('identifierSchemeCodeUnspsc190501.50403906'),
	'identifierSchemeCodeUnspsc190501.50403907': __('identifierSchemeCodeUnspsc190501.50403907'),
	'identifierSchemeCodeUnspsc190501.50403908': __('identifierSchemeCodeUnspsc190501.50403908'),
	'identifierSchemeCodeUnspsc190501.50403909': __('identifierSchemeCodeUnspsc190501.50403909'),
	'identifierSchemeCodeUnspsc190501.50403910': __('identifierSchemeCodeUnspsc190501.50403910'),
	'identifierSchemeCodeUnspsc190501.50403911': __('identifierSchemeCodeUnspsc190501.50403911'),
	'identifierSchemeCodeUnspsc190501.50404000': __('identifierSchemeCodeUnspsc190501.50404000'),
	'identifierSchemeCodeUnspsc190501.50404001': __('identifierSchemeCodeUnspsc190501.50404001'),
	'identifierSchemeCodeUnspsc190501.50404002': __('identifierSchemeCodeUnspsc190501.50404002'),
	'identifierSchemeCodeUnspsc190501.50404003': __('identifierSchemeCodeUnspsc190501.50404003'),
	'identifierSchemeCodeUnspsc190501.50404004': __('identifierSchemeCodeUnspsc190501.50404004'),
	'identifierSchemeCodeUnspsc190501.50404006': __('identifierSchemeCodeUnspsc190501.50404006'),
	'identifierSchemeCodeUnspsc190501.50404100': __('identifierSchemeCodeUnspsc190501.50404100'),
	'identifierSchemeCodeUnspsc190501.50404101': __('identifierSchemeCodeUnspsc190501.50404101'),
	'identifierSchemeCodeUnspsc190501.50404102': __('identifierSchemeCodeUnspsc190501.50404102'),
	'identifierSchemeCodeUnspsc190501.50404103': __('identifierSchemeCodeUnspsc190501.50404103'),
	'identifierSchemeCodeUnspsc190501.50404104': __('identifierSchemeCodeUnspsc190501.50404104'),
	'identifierSchemeCodeUnspsc190501.50404105': __('identifierSchemeCodeUnspsc190501.50404105'),
	'identifierSchemeCodeUnspsc190501.50404106': __('identifierSchemeCodeUnspsc190501.50404106'),
	'identifierSchemeCodeUnspsc190501.50404107': __('identifierSchemeCodeUnspsc190501.50404107'),
	'identifierSchemeCodeUnspsc190501.50404108': __('identifierSchemeCodeUnspsc190501.50404108'),
	'identifierSchemeCodeUnspsc190501.50404109': __('identifierSchemeCodeUnspsc190501.50404109'),
	'identifierSchemeCodeUnspsc190501.50404110': __('identifierSchemeCodeUnspsc190501.50404110'),
	'identifierSchemeCodeUnspsc190501.50404111': __('identifierSchemeCodeUnspsc190501.50404111'),
	'identifierSchemeCodeUnspsc190501.50404112': __('identifierSchemeCodeUnspsc190501.50404112'),
	'identifierSchemeCodeUnspsc190501.50404113': __('identifierSchemeCodeUnspsc190501.50404113'),
	'identifierSchemeCodeUnspsc190501.50404114': __('identifierSchemeCodeUnspsc190501.50404114'),
	'identifierSchemeCodeUnspsc190501.50404115': __('identifierSchemeCodeUnspsc190501.50404115'),
	'identifierSchemeCodeUnspsc190501.50404116': __('identifierSchemeCodeUnspsc190501.50404116'),
	'identifierSchemeCodeUnspsc190501.50404117': __('identifierSchemeCodeUnspsc190501.50404117'),
	'identifierSchemeCodeUnspsc190501.50404118': __('identifierSchemeCodeUnspsc190501.50404118'),
	'identifierSchemeCodeUnspsc190501.50404119': __('identifierSchemeCodeUnspsc190501.50404119'),
	'identifierSchemeCodeUnspsc190501.50404120': __('identifierSchemeCodeUnspsc190501.50404120'),
	'identifierSchemeCodeUnspsc190501.50404121': __('identifierSchemeCodeUnspsc190501.50404121'),
	'identifierSchemeCodeUnspsc190501.50404122': __('identifierSchemeCodeUnspsc190501.50404122'),
	'identifierSchemeCodeUnspsc190501.50404123': __('identifierSchemeCodeUnspsc190501.50404123'),
	'identifierSchemeCodeUnspsc190501.50404124': __('identifierSchemeCodeUnspsc190501.50404124'),
	'identifierSchemeCodeUnspsc190501.50404125': __('identifierSchemeCodeUnspsc190501.50404125'),
	'identifierSchemeCodeUnspsc190501.50404126': __('identifierSchemeCodeUnspsc190501.50404126'),
	'identifierSchemeCodeUnspsc190501.50404127': __('identifierSchemeCodeUnspsc190501.50404127'),
	'identifierSchemeCodeUnspsc190501.50404200': __('identifierSchemeCodeUnspsc190501.50404200'),
	'identifierSchemeCodeUnspsc190501.50404201': __('identifierSchemeCodeUnspsc190501.50404201'),
	'identifierSchemeCodeUnspsc190501.50404202': __('identifierSchemeCodeUnspsc190501.50404202'),
	'identifierSchemeCodeUnspsc190501.50404300': __('identifierSchemeCodeUnspsc190501.50404300'),
	'identifierSchemeCodeUnspsc190501.50404301': __('identifierSchemeCodeUnspsc190501.50404301'),
	'identifierSchemeCodeUnspsc190501.50404302': __('identifierSchemeCodeUnspsc190501.50404302'),
	'identifierSchemeCodeUnspsc190501.50404303': __('identifierSchemeCodeUnspsc190501.50404303'),
	'identifierSchemeCodeUnspsc190501.50404304': __('identifierSchemeCodeUnspsc190501.50404304'),
	'identifierSchemeCodeUnspsc190501.50404305': __('identifierSchemeCodeUnspsc190501.50404305'),
	'identifierSchemeCodeUnspsc190501.50404306': __('identifierSchemeCodeUnspsc190501.50404306'),
	'identifierSchemeCodeUnspsc190501.50404400': __('identifierSchemeCodeUnspsc190501.50404400'),
	'identifierSchemeCodeUnspsc190501.50404401': __('identifierSchemeCodeUnspsc190501.50404401'),
	'identifierSchemeCodeUnspsc190501.50404402': __('identifierSchemeCodeUnspsc190501.50404402'),
	'identifierSchemeCodeUnspsc190501.50404403': __('identifierSchemeCodeUnspsc190501.50404403'),
	'identifierSchemeCodeUnspsc190501.50404404': __('identifierSchemeCodeUnspsc190501.50404404'),
	'identifierSchemeCodeUnspsc190501.50404405': __('identifierSchemeCodeUnspsc190501.50404405'),
	'identifierSchemeCodeUnspsc190501.50404406': __('identifierSchemeCodeUnspsc190501.50404406'),
	'identifierSchemeCodeUnspsc190501.50404500': __('identifierSchemeCodeUnspsc190501.50404500'),
	'identifierSchemeCodeUnspsc190501.50404501': __('identifierSchemeCodeUnspsc190501.50404501'),
	'identifierSchemeCodeUnspsc190501.50404502': __('identifierSchemeCodeUnspsc190501.50404502'),
	'identifierSchemeCodeUnspsc190501.50404503': __('identifierSchemeCodeUnspsc190501.50404503'),
	'identifierSchemeCodeUnspsc190501.50404504': __('identifierSchemeCodeUnspsc190501.50404504'),
	'identifierSchemeCodeUnspsc190501.50404505': __('identifierSchemeCodeUnspsc190501.50404505'),
	'identifierSchemeCodeUnspsc190501.50404506': __('identifierSchemeCodeUnspsc190501.50404506'),
	'identifierSchemeCodeUnspsc190501.50404507': __('identifierSchemeCodeUnspsc190501.50404507'),
	'identifierSchemeCodeUnspsc190501.50404508': __('identifierSchemeCodeUnspsc190501.50404508'),
	'identifierSchemeCodeUnspsc190501.50404600': __('identifierSchemeCodeUnspsc190501.50404600'),
	'identifierSchemeCodeUnspsc190501.50404601': __('identifierSchemeCodeUnspsc190501.50404601'),
	'identifierSchemeCodeUnspsc190501.50404602': __('identifierSchemeCodeUnspsc190501.50404602'),
	'identifierSchemeCodeUnspsc190501.50404603': __('identifierSchemeCodeUnspsc190501.50404603'),
	'identifierSchemeCodeUnspsc190501.50404604': __('identifierSchemeCodeUnspsc190501.50404604'),
	'identifierSchemeCodeUnspsc190501.50404605': __('identifierSchemeCodeUnspsc190501.50404605'),
	'identifierSchemeCodeUnspsc190501.50404606': __('identifierSchemeCodeUnspsc190501.50404606'),
	'identifierSchemeCodeUnspsc190501.50404607': __('identifierSchemeCodeUnspsc190501.50404607'),
	'identifierSchemeCodeUnspsc190501.50404608': __('identifierSchemeCodeUnspsc190501.50404608'),
	'identifierSchemeCodeUnspsc190501.50404609': __('identifierSchemeCodeUnspsc190501.50404609'),
	'identifierSchemeCodeUnspsc190501.50404610': __('identifierSchemeCodeUnspsc190501.50404610'),
	'identifierSchemeCodeUnspsc190501.50404611': __('identifierSchemeCodeUnspsc190501.50404611'),
	'identifierSchemeCodeUnspsc190501.50404612': __('identifierSchemeCodeUnspsc190501.50404612'),
	'identifierSchemeCodeUnspsc190501.50404613': __('identifierSchemeCodeUnspsc190501.50404613'),
	'identifierSchemeCodeUnspsc190501.50404614': __('identifierSchemeCodeUnspsc190501.50404614'),
	'identifierSchemeCodeUnspsc190501.50404615': __('identifierSchemeCodeUnspsc190501.50404615'),
	'identifierSchemeCodeUnspsc190501.50404616': __('identifierSchemeCodeUnspsc190501.50404616'),
	'identifierSchemeCodeUnspsc190501.50404617': __('identifierSchemeCodeUnspsc190501.50404617'),
	'identifierSchemeCodeUnspsc190501.50404618': __('identifierSchemeCodeUnspsc190501.50404618'),
	'identifierSchemeCodeUnspsc190501.50404619': __('identifierSchemeCodeUnspsc190501.50404619'),
	'identifierSchemeCodeUnspsc190501.50404620': __('identifierSchemeCodeUnspsc190501.50404620'),
	'identifierSchemeCodeUnspsc190501.50404621': __('identifierSchemeCodeUnspsc190501.50404621'),
	'identifierSchemeCodeUnspsc190501.50404622': __('identifierSchemeCodeUnspsc190501.50404622'),
	'identifierSchemeCodeUnspsc190501.50404623': __('identifierSchemeCodeUnspsc190501.50404623'),
	'identifierSchemeCodeUnspsc190501.50404700': __('identifierSchemeCodeUnspsc190501.50404700'),
	'identifierSchemeCodeUnspsc190501.50404701': __('identifierSchemeCodeUnspsc190501.50404701'),
	'identifierSchemeCodeUnspsc190501.50404702': __('identifierSchemeCodeUnspsc190501.50404702'),
	'identifierSchemeCodeUnspsc190501.50404703': __('identifierSchemeCodeUnspsc190501.50404703'),
	'identifierSchemeCodeUnspsc190501.50404704': __('identifierSchemeCodeUnspsc190501.50404704'),
	'identifierSchemeCodeUnspsc190501.50404705': __('identifierSchemeCodeUnspsc190501.50404705'),
	'identifierSchemeCodeUnspsc190501.50404706': __('identifierSchemeCodeUnspsc190501.50404706'),
	'identifierSchemeCodeUnspsc190501.50404800': __('identifierSchemeCodeUnspsc190501.50404800'),
	'identifierSchemeCodeUnspsc190501.50404801': __('identifierSchemeCodeUnspsc190501.50404801'),
	'identifierSchemeCodeUnspsc190501.50404802': __('identifierSchemeCodeUnspsc190501.50404802'),
	'identifierSchemeCodeUnspsc190501.50404803': __('identifierSchemeCodeUnspsc190501.50404803'),
	'identifierSchemeCodeUnspsc190501.50404804': __('identifierSchemeCodeUnspsc190501.50404804'),
	'identifierSchemeCodeUnspsc190501.50404805': __('identifierSchemeCodeUnspsc190501.50404805'),
	'identifierSchemeCodeUnspsc190501.50404806': __('identifierSchemeCodeUnspsc190501.50404806'),
	'identifierSchemeCodeUnspsc190501.50404807': __('identifierSchemeCodeUnspsc190501.50404807'),
	'identifierSchemeCodeUnspsc190501.50404808': __('identifierSchemeCodeUnspsc190501.50404808'),
	'identifierSchemeCodeUnspsc190501.50404809': __('identifierSchemeCodeUnspsc190501.50404809'),
	'identifierSchemeCodeUnspsc190501.50404810': __('identifierSchemeCodeUnspsc190501.50404810'),
	'identifierSchemeCodeUnspsc190501.50404811': __('identifierSchemeCodeUnspsc190501.50404811'),
	'identifierSchemeCodeUnspsc190501.50404812': __('identifierSchemeCodeUnspsc190501.50404812'),
	'identifierSchemeCodeUnspsc190501.50404813': __('identifierSchemeCodeUnspsc190501.50404813'),
	'identifierSchemeCodeUnspsc190501.50404814': __('identifierSchemeCodeUnspsc190501.50404814'),
	'identifierSchemeCodeUnspsc190501.50404815': __('identifierSchemeCodeUnspsc190501.50404815'),
	'identifierSchemeCodeUnspsc190501.50404816': __('identifierSchemeCodeUnspsc190501.50404816'),
	'identifierSchemeCodeUnspsc190501.50404817': __('identifierSchemeCodeUnspsc190501.50404817'),
	'identifierSchemeCodeUnspsc190501.50404818': __('identifierSchemeCodeUnspsc190501.50404818'),
	'identifierSchemeCodeUnspsc190501.50404819': __('identifierSchemeCodeUnspsc190501.50404819'),
	'identifierSchemeCodeUnspsc190501.50404820': __('identifierSchemeCodeUnspsc190501.50404820'),
	'identifierSchemeCodeUnspsc190501.50404821': __('identifierSchemeCodeUnspsc190501.50404821'),
	'identifierSchemeCodeUnspsc190501.50404822': __('identifierSchemeCodeUnspsc190501.50404822'),
	'identifierSchemeCodeUnspsc190501.50404823': __('identifierSchemeCodeUnspsc190501.50404823'),
	'identifierSchemeCodeUnspsc190501.50404900': __('identifierSchemeCodeUnspsc190501.50404900'),
	'identifierSchemeCodeUnspsc190501.50404901': __('identifierSchemeCodeUnspsc190501.50404901'),
	'identifierSchemeCodeUnspsc190501.50404902': __('identifierSchemeCodeUnspsc190501.50404902'),
	'identifierSchemeCodeUnspsc190501.50404903': __('identifierSchemeCodeUnspsc190501.50404903'),
	'identifierSchemeCodeUnspsc190501.50404904': __('identifierSchemeCodeUnspsc190501.50404904'),
	'identifierSchemeCodeUnspsc190501.50404905': __('identifierSchemeCodeUnspsc190501.50404905'),
	'identifierSchemeCodeUnspsc190501.50404906': __('identifierSchemeCodeUnspsc190501.50404906'),
	'identifierSchemeCodeUnspsc190501.50405100': __('identifierSchemeCodeUnspsc190501.50405100'),
	'identifierSchemeCodeUnspsc190501.50405101': __('identifierSchemeCodeUnspsc190501.50405101'),
	'identifierSchemeCodeUnspsc190501.50405102': __('identifierSchemeCodeUnspsc190501.50405102'),
	'identifierSchemeCodeUnspsc190501.50405103': __('identifierSchemeCodeUnspsc190501.50405103'),
	'identifierSchemeCodeUnspsc190501.50405104': __('identifierSchemeCodeUnspsc190501.50405104'),
	'identifierSchemeCodeUnspsc190501.50405200': __('identifierSchemeCodeUnspsc190501.50405200'),
	'identifierSchemeCodeUnspsc190501.50405201': __('identifierSchemeCodeUnspsc190501.50405201'),
	'identifierSchemeCodeUnspsc190501.50405202': __('identifierSchemeCodeUnspsc190501.50405202'),
	'identifierSchemeCodeUnspsc190501.50405203': __('identifierSchemeCodeUnspsc190501.50405203'),
	'identifierSchemeCodeUnspsc190501.50405204': __('identifierSchemeCodeUnspsc190501.50405204'),
	'identifierSchemeCodeUnspsc190501.50405205': __('identifierSchemeCodeUnspsc190501.50405205'),
	'identifierSchemeCodeUnspsc190501.50405206': __('identifierSchemeCodeUnspsc190501.50405206'),
	'identifierSchemeCodeUnspsc190501.50405207': __('identifierSchemeCodeUnspsc190501.50405207'),
	'identifierSchemeCodeUnspsc190501.50405300': __('identifierSchemeCodeUnspsc190501.50405300'),
	'identifierSchemeCodeUnspsc190501.50405301': __('identifierSchemeCodeUnspsc190501.50405301'),
	'identifierSchemeCodeUnspsc190501.50405302': __('identifierSchemeCodeUnspsc190501.50405302'),
	'identifierSchemeCodeUnspsc190501.50405303': __('identifierSchemeCodeUnspsc190501.50405303'),
	'identifierSchemeCodeUnspsc190501.50405304': __('identifierSchemeCodeUnspsc190501.50405304'),
	'identifierSchemeCodeUnspsc190501.50405305': __('identifierSchemeCodeUnspsc190501.50405305'),
	'identifierSchemeCodeUnspsc190501.50405306': __('identifierSchemeCodeUnspsc190501.50405306'),
	'identifierSchemeCodeUnspsc190501.50405307': __('identifierSchemeCodeUnspsc190501.50405307'),
	'identifierSchemeCodeUnspsc190501.50405308': __('identifierSchemeCodeUnspsc190501.50405308'),
	'identifierSchemeCodeUnspsc190501.50405309': __('identifierSchemeCodeUnspsc190501.50405309'),
	'identifierSchemeCodeUnspsc190501.50405310': __('identifierSchemeCodeUnspsc190501.50405310'),
	'identifierSchemeCodeUnspsc190501.50405311': __('identifierSchemeCodeUnspsc190501.50405311'),
	'identifierSchemeCodeUnspsc190501.50405312': __('identifierSchemeCodeUnspsc190501.50405312'),
	'identifierSchemeCodeUnspsc190501.50405313': __('identifierSchemeCodeUnspsc190501.50405313'),
	'identifierSchemeCodeUnspsc190501.50405314': __('identifierSchemeCodeUnspsc190501.50405314'),
	'identifierSchemeCodeUnspsc190501.50405315': __('identifierSchemeCodeUnspsc190501.50405315'),
	'identifierSchemeCodeUnspsc190501.50405316': __('identifierSchemeCodeUnspsc190501.50405316'),
	'identifierSchemeCodeUnspsc190501.50405317': __('identifierSchemeCodeUnspsc190501.50405317'),
	'identifierSchemeCodeUnspsc190501.50405318': __('identifierSchemeCodeUnspsc190501.50405318'),
	'identifierSchemeCodeUnspsc190501.50405319': __('identifierSchemeCodeUnspsc190501.50405319'),
	'identifierSchemeCodeUnspsc190501.50405320': __('identifierSchemeCodeUnspsc190501.50405320'),
	'identifierSchemeCodeUnspsc190501.50405321': __('identifierSchemeCodeUnspsc190501.50405321'),
	'identifierSchemeCodeUnspsc190501.50405322': __('identifierSchemeCodeUnspsc190501.50405322'),
	'identifierSchemeCodeUnspsc190501.50405323': __('identifierSchemeCodeUnspsc190501.50405323'),
	'identifierSchemeCodeUnspsc190501.50405400': __('identifierSchemeCodeUnspsc190501.50405400'),
	'identifierSchemeCodeUnspsc190501.50405401': __('identifierSchemeCodeUnspsc190501.50405401'),
	'identifierSchemeCodeUnspsc190501.50405402': __('identifierSchemeCodeUnspsc190501.50405402'),
	'identifierSchemeCodeUnspsc190501.50405403': __('identifierSchemeCodeUnspsc190501.50405403'),
	'identifierSchemeCodeUnspsc190501.50405404': __('identifierSchemeCodeUnspsc190501.50405404'),
	'identifierSchemeCodeUnspsc190501.50405405': __('identifierSchemeCodeUnspsc190501.50405405'),
	'identifierSchemeCodeUnspsc190501.50405406': __('identifierSchemeCodeUnspsc190501.50405406'),
	'identifierSchemeCodeUnspsc190501.50405500': __('identifierSchemeCodeUnspsc190501.50405500'),
	'identifierSchemeCodeUnspsc190501.50405501': __('identifierSchemeCodeUnspsc190501.50405501'),
	'identifierSchemeCodeUnspsc190501.50405502': __('identifierSchemeCodeUnspsc190501.50405502'),
	'identifierSchemeCodeUnspsc190501.50405503': __('identifierSchemeCodeUnspsc190501.50405503'),
	'identifierSchemeCodeUnspsc190501.50405504': __('identifierSchemeCodeUnspsc190501.50405504'),
	'identifierSchemeCodeUnspsc190501.50405505': __('identifierSchemeCodeUnspsc190501.50405505'),
	'identifierSchemeCodeUnspsc190501.50405506': __('identifierSchemeCodeUnspsc190501.50405506'),
	'identifierSchemeCodeUnspsc190501.50405600': __('identifierSchemeCodeUnspsc190501.50405600'),
	'identifierSchemeCodeUnspsc190501.50405601': __('identifierSchemeCodeUnspsc190501.50405601'),
	'identifierSchemeCodeUnspsc190501.50405602': __('identifierSchemeCodeUnspsc190501.50405602'),
	'identifierSchemeCodeUnspsc190501.50405603': __('identifierSchemeCodeUnspsc190501.50405603'),
	'identifierSchemeCodeUnspsc190501.50405604': __('identifierSchemeCodeUnspsc190501.50405604'),
	'identifierSchemeCodeUnspsc190501.50405605': __('identifierSchemeCodeUnspsc190501.50405605'),
	'identifierSchemeCodeUnspsc190501.50405606': __('identifierSchemeCodeUnspsc190501.50405606'),
	'identifierSchemeCodeUnspsc190501.50405607': __('identifierSchemeCodeUnspsc190501.50405607'),
	'identifierSchemeCodeUnspsc190501.50405608': __('identifierSchemeCodeUnspsc190501.50405608'),
	'identifierSchemeCodeUnspsc190501.50405609': __('identifierSchemeCodeUnspsc190501.50405609'),
	'identifierSchemeCodeUnspsc190501.50405610': __('identifierSchemeCodeUnspsc190501.50405610'),
	'identifierSchemeCodeUnspsc190501.50405611': __('identifierSchemeCodeUnspsc190501.50405611'),
	'identifierSchemeCodeUnspsc190501.50405612': __('identifierSchemeCodeUnspsc190501.50405612'),
	'identifierSchemeCodeUnspsc190501.50405613': __('identifierSchemeCodeUnspsc190501.50405613'),
	'identifierSchemeCodeUnspsc190501.50405614': __('identifierSchemeCodeUnspsc190501.50405614'),
	'identifierSchemeCodeUnspsc190501.50405615': __('identifierSchemeCodeUnspsc190501.50405615'),
	'identifierSchemeCodeUnspsc190501.50405616': __('identifierSchemeCodeUnspsc190501.50405616'),
	'identifierSchemeCodeUnspsc190501.50405617': __('identifierSchemeCodeUnspsc190501.50405617'),
	'identifierSchemeCodeUnspsc190501.50405618': __('identifierSchemeCodeUnspsc190501.50405618'),
	'identifierSchemeCodeUnspsc190501.50405619': __('identifierSchemeCodeUnspsc190501.50405619'),
	'identifierSchemeCodeUnspsc190501.50405620': __('identifierSchemeCodeUnspsc190501.50405620'),
	'identifierSchemeCodeUnspsc190501.50405621': __('identifierSchemeCodeUnspsc190501.50405621'),
	'identifierSchemeCodeUnspsc190501.50405622': __('identifierSchemeCodeUnspsc190501.50405622'),
	'identifierSchemeCodeUnspsc190501.50405623': __('identifierSchemeCodeUnspsc190501.50405623'),
	'identifierSchemeCodeUnspsc190501.50405624': __('identifierSchemeCodeUnspsc190501.50405624'),
	'identifierSchemeCodeUnspsc190501.50405625': __('identifierSchemeCodeUnspsc190501.50405625'),
	'identifierSchemeCodeUnspsc190501.50405626': __('identifierSchemeCodeUnspsc190501.50405626'),
	'identifierSchemeCodeUnspsc190501.50405627': __('identifierSchemeCodeUnspsc190501.50405627'),
	'identifierSchemeCodeUnspsc190501.50405628': __('identifierSchemeCodeUnspsc190501.50405628'),
	'identifierSchemeCodeUnspsc190501.50405629': __('identifierSchemeCodeUnspsc190501.50405629'),
	'identifierSchemeCodeUnspsc190501.50405630': __('identifierSchemeCodeUnspsc190501.50405630'),
	'identifierSchemeCodeUnspsc190501.50405631': __('identifierSchemeCodeUnspsc190501.50405631'),
	'identifierSchemeCodeUnspsc190501.50405632': __('identifierSchemeCodeUnspsc190501.50405632'),
	'identifierSchemeCodeUnspsc190501.50405633': __('identifierSchemeCodeUnspsc190501.50405633'),
	'identifierSchemeCodeUnspsc190501.50405634': __('identifierSchemeCodeUnspsc190501.50405634'),
	'identifierSchemeCodeUnspsc190501.50405635': __('identifierSchemeCodeUnspsc190501.50405635'),
	'identifierSchemeCodeUnspsc190501.50405636': __('identifierSchemeCodeUnspsc190501.50405636'),
	'identifierSchemeCodeUnspsc190501.50405637': __('identifierSchemeCodeUnspsc190501.50405637'),
	'identifierSchemeCodeUnspsc190501.50405638': __('identifierSchemeCodeUnspsc190501.50405638'),
	'identifierSchemeCodeUnspsc190501.50405639': __('identifierSchemeCodeUnspsc190501.50405639'),
	'identifierSchemeCodeUnspsc190501.50405640': __('identifierSchemeCodeUnspsc190501.50405640'),
	'identifierSchemeCodeUnspsc190501.50405641': __('identifierSchemeCodeUnspsc190501.50405641'),
	'identifierSchemeCodeUnspsc190501.50405642': __('identifierSchemeCodeUnspsc190501.50405642'),
	'identifierSchemeCodeUnspsc190501.50405700': __('identifierSchemeCodeUnspsc190501.50405700'),
	'identifierSchemeCodeUnspsc190501.50405701': __('identifierSchemeCodeUnspsc190501.50405701'),
	'identifierSchemeCodeUnspsc190501.50405702': __('identifierSchemeCodeUnspsc190501.50405702'),
	'identifierSchemeCodeUnspsc190501.50405703': __('identifierSchemeCodeUnspsc190501.50405703'),
	'identifierSchemeCodeUnspsc190501.50405704': __('identifierSchemeCodeUnspsc190501.50405704'),
	'identifierSchemeCodeUnspsc190501.50405705': __('identifierSchemeCodeUnspsc190501.50405705'),
	'identifierSchemeCodeUnspsc190501.50405706': __('identifierSchemeCodeUnspsc190501.50405706'),
	'identifierSchemeCodeUnspsc190501.50405707': __('identifierSchemeCodeUnspsc190501.50405707'),
	'identifierSchemeCodeUnspsc190501.50405708': __('identifierSchemeCodeUnspsc190501.50405708'),
	'identifierSchemeCodeUnspsc190501.50405709': __('identifierSchemeCodeUnspsc190501.50405709'),
	'identifierSchemeCodeUnspsc190501.50405710': __('identifierSchemeCodeUnspsc190501.50405710'),
	'identifierSchemeCodeUnspsc190501.50405711': __('identifierSchemeCodeUnspsc190501.50405711'),
	'identifierSchemeCodeUnspsc190501.50405712': __('identifierSchemeCodeUnspsc190501.50405712'),
	'identifierSchemeCodeUnspsc190501.50405713': __('identifierSchemeCodeUnspsc190501.50405713'),
	'identifierSchemeCodeUnspsc190501.50405714': __('identifierSchemeCodeUnspsc190501.50405714'),
	'identifierSchemeCodeUnspsc190501.50405715': __('identifierSchemeCodeUnspsc190501.50405715'),
	'identifierSchemeCodeUnspsc190501.50405716': __('identifierSchemeCodeUnspsc190501.50405716'),
	'identifierSchemeCodeUnspsc190501.50405717': __('identifierSchemeCodeUnspsc190501.50405717'),
	'identifierSchemeCodeUnspsc190501.50405718': __('identifierSchemeCodeUnspsc190501.50405718'),
	'identifierSchemeCodeUnspsc190501.50405800': __('identifierSchemeCodeUnspsc190501.50405800'),
	'identifierSchemeCodeUnspsc190501.50405801': __('identifierSchemeCodeUnspsc190501.50405801'),
	'identifierSchemeCodeUnspsc190501.50405802': __('identifierSchemeCodeUnspsc190501.50405802'),
	'identifierSchemeCodeUnspsc190501.50405803': __('identifierSchemeCodeUnspsc190501.50405803'),
	'identifierSchemeCodeUnspsc190501.50405804': __('identifierSchemeCodeUnspsc190501.50405804'),
	'identifierSchemeCodeUnspsc190501.50405900': __('identifierSchemeCodeUnspsc190501.50405900'),
	'identifierSchemeCodeUnspsc190501.50405901': __('identifierSchemeCodeUnspsc190501.50405901'),
	'identifierSchemeCodeUnspsc190501.50405902': __('identifierSchemeCodeUnspsc190501.50405902'),
	'identifierSchemeCodeUnspsc190501.50405903': __('identifierSchemeCodeUnspsc190501.50405903'),
	'identifierSchemeCodeUnspsc190501.50405904': __('identifierSchemeCodeUnspsc190501.50405904'),
	'identifierSchemeCodeUnspsc190501.50405905': __('identifierSchemeCodeUnspsc190501.50405905'),
	'identifierSchemeCodeUnspsc190501.50405906': __('identifierSchemeCodeUnspsc190501.50405906'),
	'identifierSchemeCodeUnspsc190501.50405907': __('identifierSchemeCodeUnspsc190501.50405907'),
	'identifierSchemeCodeUnspsc190501.50405908': __('identifierSchemeCodeUnspsc190501.50405908'),
	'identifierSchemeCodeUnspsc190501.50405909': __('identifierSchemeCodeUnspsc190501.50405909'),
	'identifierSchemeCodeUnspsc190501.50405910': __('identifierSchemeCodeUnspsc190501.50405910'),
	'identifierSchemeCodeUnspsc190501.50405911': __('identifierSchemeCodeUnspsc190501.50405911'),
	'identifierSchemeCodeUnspsc190501.50405912': __('identifierSchemeCodeUnspsc190501.50405912'),
	'identifierSchemeCodeUnspsc190501.50405913': __('identifierSchemeCodeUnspsc190501.50405913'),
	'identifierSchemeCodeUnspsc190501.50405914': __('identifierSchemeCodeUnspsc190501.50405914'),
	'identifierSchemeCodeUnspsc190501.50405915': __('identifierSchemeCodeUnspsc190501.50405915'),
	'identifierSchemeCodeUnspsc190501.50405916': __('identifierSchemeCodeUnspsc190501.50405916'),
	'identifierSchemeCodeUnspsc190501.50405917': __('identifierSchemeCodeUnspsc190501.50405917'),
	'identifierSchemeCodeUnspsc190501.50406000': __('identifierSchemeCodeUnspsc190501.50406000'),
	'identifierSchemeCodeUnspsc190501.50406001': __('identifierSchemeCodeUnspsc190501.50406001'),
	'identifierSchemeCodeUnspsc190501.50406002': __('identifierSchemeCodeUnspsc190501.50406002'),
	'identifierSchemeCodeUnspsc190501.50406003': __('identifierSchemeCodeUnspsc190501.50406003'),
	'identifierSchemeCodeUnspsc190501.50406004': __('identifierSchemeCodeUnspsc190501.50406004'),
	'identifierSchemeCodeUnspsc190501.50406005': __('identifierSchemeCodeUnspsc190501.50406005'),
	'identifierSchemeCodeUnspsc190501.50406006': __('identifierSchemeCodeUnspsc190501.50406006'),
	'identifierSchemeCodeUnspsc190501.50406007': __('identifierSchemeCodeUnspsc190501.50406007'),
	'identifierSchemeCodeUnspsc190501.50406008': __('identifierSchemeCodeUnspsc190501.50406008'),
	'identifierSchemeCodeUnspsc190501.50406009': __('identifierSchemeCodeUnspsc190501.50406009'),
	'identifierSchemeCodeUnspsc190501.50406010': __('identifierSchemeCodeUnspsc190501.50406010'),
	'identifierSchemeCodeUnspsc190501.50406011': __('identifierSchemeCodeUnspsc190501.50406011'),
	'identifierSchemeCodeUnspsc190501.50406100': __('identifierSchemeCodeUnspsc190501.50406100'),
	'identifierSchemeCodeUnspsc190501.50406101': __('identifierSchemeCodeUnspsc190501.50406101'),
	'identifierSchemeCodeUnspsc190501.50406102': __('identifierSchemeCodeUnspsc190501.50406102'),
	'identifierSchemeCodeUnspsc190501.50406103': __('identifierSchemeCodeUnspsc190501.50406103'),
	'identifierSchemeCodeUnspsc190501.50406104': __('identifierSchemeCodeUnspsc190501.50406104'),
	'identifierSchemeCodeUnspsc190501.50406200': __('identifierSchemeCodeUnspsc190501.50406200'),
	'identifierSchemeCodeUnspsc190501.50406201': __('identifierSchemeCodeUnspsc190501.50406201'),
	'identifierSchemeCodeUnspsc190501.50406202': __('identifierSchemeCodeUnspsc190501.50406202'),
	'identifierSchemeCodeUnspsc190501.50406203': __('identifierSchemeCodeUnspsc190501.50406203'),
	'identifierSchemeCodeUnspsc190501.50406204': __('identifierSchemeCodeUnspsc190501.50406204'),
	'identifierSchemeCodeUnspsc190501.50406205': __('identifierSchemeCodeUnspsc190501.50406205'),
	'identifierSchemeCodeUnspsc190501.50406206': __('identifierSchemeCodeUnspsc190501.50406206'),
	'identifierSchemeCodeUnspsc190501.50406207': __('identifierSchemeCodeUnspsc190501.50406207'),
	'identifierSchemeCodeUnspsc190501.50406208': __('identifierSchemeCodeUnspsc190501.50406208'),
	'identifierSchemeCodeUnspsc190501.50406209': __('identifierSchemeCodeUnspsc190501.50406209'),
	'identifierSchemeCodeUnspsc190501.50406210': __('identifierSchemeCodeUnspsc190501.50406210'),
	'identifierSchemeCodeUnspsc190501.50406211': __('identifierSchemeCodeUnspsc190501.50406211'),
	'identifierSchemeCodeUnspsc190501.50406212': __('identifierSchemeCodeUnspsc190501.50406212'),
	'identifierSchemeCodeUnspsc190501.50406213': __('identifierSchemeCodeUnspsc190501.50406213'),
	'identifierSchemeCodeUnspsc190501.50406224': __('identifierSchemeCodeUnspsc190501.50406224'),
	'identifierSchemeCodeUnspsc190501.50406225': __('identifierSchemeCodeUnspsc190501.50406225'),
	'identifierSchemeCodeUnspsc190501.50406300': __('identifierSchemeCodeUnspsc190501.50406300'),
	'identifierSchemeCodeUnspsc190501.50406301': __('identifierSchemeCodeUnspsc190501.50406301'),
	'identifierSchemeCodeUnspsc190501.50406302': __('identifierSchemeCodeUnspsc190501.50406302'),
	'identifierSchemeCodeUnspsc190501.50406303': __('identifierSchemeCodeUnspsc190501.50406303'),
	'identifierSchemeCodeUnspsc190501.50406304': __('identifierSchemeCodeUnspsc190501.50406304'),
	'identifierSchemeCodeUnspsc190501.50406305': __('identifierSchemeCodeUnspsc190501.50406305'),
	'identifierSchemeCodeUnspsc190501.50406306': __('identifierSchemeCodeUnspsc190501.50406306'),
	'identifierSchemeCodeUnspsc190501.50406307': __('identifierSchemeCodeUnspsc190501.50406307'),
	'identifierSchemeCodeUnspsc190501.50406308': __('identifierSchemeCodeUnspsc190501.50406308'),
	'identifierSchemeCodeUnspsc190501.50406309': __('identifierSchemeCodeUnspsc190501.50406309'),
	'identifierSchemeCodeUnspsc190501.50406310': __('identifierSchemeCodeUnspsc190501.50406310'),
	'identifierSchemeCodeUnspsc190501.50406311': __('identifierSchemeCodeUnspsc190501.50406311'),
	'identifierSchemeCodeUnspsc190501.50406312': __('identifierSchemeCodeUnspsc190501.50406312'),
	'identifierSchemeCodeUnspsc190501.50406313': __('identifierSchemeCodeUnspsc190501.50406313'),
	'identifierSchemeCodeUnspsc190501.50406314': __('identifierSchemeCodeUnspsc190501.50406314'),
	'identifierSchemeCodeUnspsc190501.50406315': __('identifierSchemeCodeUnspsc190501.50406315'),
	'identifierSchemeCodeUnspsc190501.50406316': __('identifierSchemeCodeUnspsc190501.50406316'),
	'identifierSchemeCodeUnspsc190501.50406317': __('identifierSchemeCodeUnspsc190501.50406317'),
	'identifierSchemeCodeUnspsc190501.50406318': __('identifierSchemeCodeUnspsc190501.50406318'),
	'identifierSchemeCodeUnspsc190501.50406319': __('identifierSchemeCodeUnspsc190501.50406319'),
	'identifierSchemeCodeUnspsc190501.50406320': __('identifierSchemeCodeUnspsc190501.50406320'),
	'identifierSchemeCodeUnspsc190501.50406321': __('identifierSchemeCodeUnspsc190501.50406321'),
	'identifierSchemeCodeUnspsc190501.50406322': __('identifierSchemeCodeUnspsc190501.50406322'),
	'identifierSchemeCodeUnspsc190501.50406323': __('identifierSchemeCodeUnspsc190501.50406323'),
	'identifierSchemeCodeUnspsc190501.50406324': __('identifierSchemeCodeUnspsc190501.50406324'),
	'identifierSchemeCodeUnspsc190501.50406325': __('identifierSchemeCodeUnspsc190501.50406325'),
	'identifierSchemeCodeUnspsc190501.50406326': __('identifierSchemeCodeUnspsc190501.50406326'),
	'identifierSchemeCodeUnspsc190501.50406327': __('identifierSchemeCodeUnspsc190501.50406327'),
	'identifierSchemeCodeUnspsc190501.50406328': __('identifierSchemeCodeUnspsc190501.50406328'),
	'identifierSchemeCodeUnspsc190501.50406329': __('identifierSchemeCodeUnspsc190501.50406329'),
	'identifierSchemeCodeUnspsc190501.50406400': __('identifierSchemeCodeUnspsc190501.50406400'),
	'identifierSchemeCodeUnspsc190501.50406401': __('identifierSchemeCodeUnspsc190501.50406401'),
	'identifierSchemeCodeUnspsc190501.50406402': __('identifierSchemeCodeUnspsc190501.50406402'),
	'identifierSchemeCodeUnspsc190501.50406403': __('identifierSchemeCodeUnspsc190501.50406403'),
	'identifierSchemeCodeUnspsc190501.50406404': __('identifierSchemeCodeUnspsc190501.50406404'),
	'identifierSchemeCodeUnspsc190501.50406405': __('identifierSchemeCodeUnspsc190501.50406405'),
	'identifierSchemeCodeUnspsc190501.50406406': __('identifierSchemeCodeUnspsc190501.50406406'),
	'identifierSchemeCodeUnspsc190501.50406407': __('identifierSchemeCodeUnspsc190501.50406407'),
	'identifierSchemeCodeUnspsc190501.50406408': __('identifierSchemeCodeUnspsc190501.50406408'),
	'identifierSchemeCodeUnspsc190501.50406409': __('identifierSchemeCodeUnspsc190501.50406409'),
	'identifierSchemeCodeUnspsc190501.50406410': __('identifierSchemeCodeUnspsc190501.50406410'),
	'identifierSchemeCodeUnspsc190501.50406411': __('identifierSchemeCodeUnspsc190501.50406411'),
	'identifierSchemeCodeUnspsc190501.50406412': __('identifierSchemeCodeUnspsc190501.50406412'),
	'identifierSchemeCodeUnspsc190501.50406413': __('identifierSchemeCodeUnspsc190501.50406413'),
	'identifierSchemeCodeUnspsc190501.50406414': __('identifierSchemeCodeUnspsc190501.50406414'),
	'identifierSchemeCodeUnspsc190501.50406415': __('identifierSchemeCodeUnspsc190501.50406415'),
	'identifierSchemeCodeUnspsc190501.50406416': __('identifierSchemeCodeUnspsc190501.50406416'),
	'identifierSchemeCodeUnspsc190501.50406417': __('identifierSchemeCodeUnspsc190501.50406417'),
	'identifierSchemeCodeUnspsc190501.50406418': __('identifierSchemeCodeUnspsc190501.50406418'),
	'identifierSchemeCodeUnspsc190501.50406419': __('identifierSchemeCodeUnspsc190501.50406419'),
	'identifierSchemeCodeUnspsc190501.50406420': __('identifierSchemeCodeUnspsc190501.50406420'),
	'identifierSchemeCodeUnspsc190501.50406421': __('identifierSchemeCodeUnspsc190501.50406421'),
	'identifierSchemeCodeUnspsc190501.50406500': __('identifierSchemeCodeUnspsc190501.50406500'),
	'identifierSchemeCodeUnspsc190501.50406501': __('identifierSchemeCodeUnspsc190501.50406501'),
	'identifierSchemeCodeUnspsc190501.50406502': __('identifierSchemeCodeUnspsc190501.50406502'),
	'identifierSchemeCodeUnspsc190501.50406503': __('identifierSchemeCodeUnspsc190501.50406503'),
	'identifierSchemeCodeUnspsc190501.50406504': __('identifierSchemeCodeUnspsc190501.50406504'),
	'identifierSchemeCodeUnspsc190501.50406505': __('identifierSchemeCodeUnspsc190501.50406505'),
	'identifierSchemeCodeUnspsc190501.50406506': __('identifierSchemeCodeUnspsc190501.50406506'),
	'identifierSchemeCodeUnspsc190501.50406507': __('identifierSchemeCodeUnspsc190501.50406507'),
	'identifierSchemeCodeUnspsc190501.50406508': __('identifierSchemeCodeUnspsc190501.50406508'),
	'identifierSchemeCodeUnspsc190501.50406509': __('identifierSchemeCodeUnspsc190501.50406509'),
	'identifierSchemeCodeUnspsc190501.50406510': __('identifierSchemeCodeUnspsc190501.50406510'),
	'identifierSchemeCodeUnspsc190501.50406511': __('identifierSchemeCodeUnspsc190501.50406511'),
	'identifierSchemeCodeUnspsc190501.50406512': __('identifierSchemeCodeUnspsc190501.50406512'),
	'identifierSchemeCodeUnspsc190501.50406513': __('identifierSchemeCodeUnspsc190501.50406513'),
	'identifierSchemeCodeUnspsc190501.50406514': __('identifierSchemeCodeUnspsc190501.50406514'),
	'identifierSchemeCodeUnspsc190501.50406515': __('identifierSchemeCodeUnspsc190501.50406515'),
	'identifierSchemeCodeUnspsc190501.50406516': __('identifierSchemeCodeUnspsc190501.50406516'),
	'identifierSchemeCodeUnspsc190501.50406517': __('identifierSchemeCodeUnspsc190501.50406517'),
	'identifierSchemeCodeUnspsc190501.50406518': __('identifierSchemeCodeUnspsc190501.50406518'),
	'identifierSchemeCodeUnspsc190501.50406519': __('identifierSchemeCodeUnspsc190501.50406519'),
	'identifierSchemeCodeUnspsc190501.50406520': __('identifierSchemeCodeUnspsc190501.50406520'),
	'identifierSchemeCodeUnspsc190501.50406521': __('identifierSchemeCodeUnspsc190501.50406521'),
	'identifierSchemeCodeUnspsc190501.50406522': __('identifierSchemeCodeUnspsc190501.50406522'),
	'identifierSchemeCodeUnspsc190501.50406523': __('identifierSchemeCodeUnspsc190501.50406523'),
	'identifierSchemeCodeUnspsc190501.50406524': __('identifierSchemeCodeUnspsc190501.50406524'),
	'identifierSchemeCodeUnspsc190501.50406525': __('identifierSchemeCodeUnspsc190501.50406525'),
	'identifierSchemeCodeUnspsc190501.50406526': __('identifierSchemeCodeUnspsc190501.50406526'),
	'identifierSchemeCodeUnspsc190501.50406527': __('identifierSchemeCodeUnspsc190501.50406527'),
	'identifierSchemeCodeUnspsc190501.50406600': __('identifierSchemeCodeUnspsc190501.50406600'),
	'identifierSchemeCodeUnspsc190501.50406601': __('identifierSchemeCodeUnspsc190501.50406601'),
	'identifierSchemeCodeUnspsc190501.50406602': __('identifierSchemeCodeUnspsc190501.50406602'),
	'identifierSchemeCodeUnspsc190501.50406603': __('identifierSchemeCodeUnspsc190501.50406603'),
	'identifierSchemeCodeUnspsc190501.50406604': __('identifierSchemeCodeUnspsc190501.50406604'),
	'identifierSchemeCodeUnspsc190501.50406605': __('identifierSchemeCodeUnspsc190501.50406605'),
	'identifierSchemeCodeUnspsc190501.50406606': __('identifierSchemeCodeUnspsc190501.50406606'),
	'identifierSchemeCodeUnspsc190501.50406607': __('identifierSchemeCodeUnspsc190501.50406607'),
	'identifierSchemeCodeUnspsc190501.50406608': __('identifierSchemeCodeUnspsc190501.50406608'),
	'identifierSchemeCodeUnspsc190501.50406700': __('identifierSchemeCodeUnspsc190501.50406700'),
	'identifierSchemeCodeUnspsc190501.50406701': __('identifierSchemeCodeUnspsc190501.50406701'),
	'identifierSchemeCodeUnspsc190501.50406702': __('identifierSchemeCodeUnspsc190501.50406702'),
	'identifierSchemeCodeUnspsc190501.50406703': __('identifierSchemeCodeUnspsc190501.50406703'),
	'identifierSchemeCodeUnspsc190501.50406704': __('identifierSchemeCodeUnspsc190501.50406704'),
	'identifierSchemeCodeUnspsc190501.50406705': __('identifierSchemeCodeUnspsc190501.50406705'),
	'identifierSchemeCodeUnspsc190501.50406706': __('identifierSchemeCodeUnspsc190501.50406706'),
	'identifierSchemeCodeUnspsc190501.50406707': __('identifierSchemeCodeUnspsc190501.50406707'),
	'identifierSchemeCodeUnspsc190501.50406708': __('identifierSchemeCodeUnspsc190501.50406708'),
	'identifierSchemeCodeUnspsc190501.50406709': __('identifierSchemeCodeUnspsc190501.50406709'),
	'identifierSchemeCodeUnspsc190501.50406710': __('identifierSchemeCodeUnspsc190501.50406710'),
	'identifierSchemeCodeUnspsc190501.50406711': __('identifierSchemeCodeUnspsc190501.50406711'),
	'identifierSchemeCodeUnspsc190501.50406712': __('identifierSchemeCodeUnspsc190501.50406712'),
	'identifierSchemeCodeUnspsc190501.50406713': __('identifierSchemeCodeUnspsc190501.50406713'),
	'identifierSchemeCodeUnspsc190501.50406714': __('identifierSchemeCodeUnspsc190501.50406714'),
	'identifierSchemeCodeUnspsc190501.50406715': __('identifierSchemeCodeUnspsc190501.50406715'),
	'identifierSchemeCodeUnspsc190501.50406716': __('identifierSchemeCodeUnspsc190501.50406716'),
	'identifierSchemeCodeUnspsc190501.50406717': __('identifierSchemeCodeUnspsc190501.50406717'),
	'identifierSchemeCodeUnspsc190501.50406718': __('identifierSchemeCodeUnspsc190501.50406718'),
	'identifierSchemeCodeUnspsc190501.50406719': __('identifierSchemeCodeUnspsc190501.50406719'),
	'identifierSchemeCodeUnspsc190501.50406720': __('identifierSchemeCodeUnspsc190501.50406720'),
	'identifierSchemeCodeUnspsc190501.50406721': __('identifierSchemeCodeUnspsc190501.50406721'),
	'identifierSchemeCodeUnspsc190501.50406722': __('identifierSchemeCodeUnspsc190501.50406722'),
	'identifierSchemeCodeUnspsc190501.50406723': __('identifierSchemeCodeUnspsc190501.50406723'),
	'identifierSchemeCodeUnspsc190501.50406800': __('identifierSchemeCodeUnspsc190501.50406800'),
	'identifierSchemeCodeUnspsc190501.50406801': __('identifierSchemeCodeUnspsc190501.50406801'),
	'identifierSchemeCodeUnspsc190501.50406802': __('identifierSchemeCodeUnspsc190501.50406802'),
	'identifierSchemeCodeUnspsc190501.50406803': __('identifierSchemeCodeUnspsc190501.50406803'),
	'identifierSchemeCodeUnspsc190501.50406804': __('identifierSchemeCodeUnspsc190501.50406804'),
	'identifierSchemeCodeUnspsc190501.50406805': __('identifierSchemeCodeUnspsc190501.50406805'),
	'identifierSchemeCodeUnspsc190501.50406806': __('identifierSchemeCodeUnspsc190501.50406806'),
	'identifierSchemeCodeUnspsc190501.50406807': __('identifierSchemeCodeUnspsc190501.50406807'),
	'identifierSchemeCodeUnspsc190501.50406808': __('identifierSchemeCodeUnspsc190501.50406808'),
	'identifierSchemeCodeUnspsc190501.50406809': __('identifierSchemeCodeUnspsc190501.50406809'),
	'identifierSchemeCodeUnspsc190501.50406810': __('identifierSchemeCodeUnspsc190501.50406810'),
	'identifierSchemeCodeUnspsc190501.50407000': __('identifierSchemeCodeUnspsc190501.50407000'),
	'identifierSchemeCodeUnspsc190501.50407001': __('identifierSchemeCodeUnspsc190501.50407001'),
	'identifierSchemeCodeUnspsc190501.50407002': __('identifierSchemeCodeUnspsc190501.50407002'),
	'identifierSchemeCodeUnspsc190501.50407003': __('identifierSchemeCodeUnspsc190501.50407003'),
	'identifierSchemeCodeUnspsc190501.50407004': __('identifierSchemeCodeUnspsc190501.50407004'),
	'identifierSchemeCodeUnspsc190501.50407005': __('identifierSchemeCodeUnspsc190501.50407005'),
	'identifierSchemeCodeUnspsc190501.50407006': __('identifierSchemeCodeUnspsc190501.50407006'),
	'identifierSchemeCodeUnspsc190501.50407007': __('identifierSchemeCodeUnspsc190501.50407007'),
	'identifierSchemeCodeUnspsc190501.50407008': __('identifierSchemeCodeUnspsc190501.50407008'),
	'identifierSchemeCodeUnspsc190501.50407009': __('identifierSchemeCodeUnspsc190501.50407009'),
	'identifierSchemeCodeUnspsc190501.50407010': __('identifierSchemeCodeUnspsc190501.50407010'),
	'identifierSchemeCodeUnspsc190501.50407011': __('identifierSchemeCodeUnspsc190501.50407011'),
	'identifierSchemeCodeUnspsc190501.50407012': __('identifierSchemeCodeUnspsc190501.50407012'),
	'identifierSchemeCodeUnspsc190501.50407013': __('identifierSchemeCodeUnspsc190501.50407013'),
	'identifierSchemeCodeUnspsc190501.50407014': __('identifierSchemeCodeUnspsc190501.50407014'),
	'identifierSchemeCodeUnspsc190501.50407015': __('identifierSchemeCodeUnspsc190501.50407015'),
	'identifierSchemeCodeUnspsc190501.50407016': __('identifierSchemeCodeUnspsc190501.50407016'),
	'identifierSchemeCodeUnspsc190501.50407017': __('identifierSchemeCodeUnspsc190501.50407017'),
	'identifierSchemeCodeUnspsc190501.50407018': __('identifierSchemeCodeUnspsc190501.50407018'),
	'identifierSchemeCodeUnspsc190501.50407019': __('identifierSchemeCodeUnspsc190501.50407019'),
	'identifierSchemeCodeUnspsc190501.50407020': __('identifierSchemeCodeUnspsc190501.50407020'),
	'identifierSchemeCodeUnspsc190501.50407021': __('identifierSchemeCodeUnspsc190501.50407021'),
	'identifierSchemeCodeUnspsc190501.50407022': __('identifierSchemeCodeUnspsc190501.50407022'),
	'identifierSchemeCodeUnspsc190501.50407023': __('identifierSchemeCodeUnspsc190501.50407023'),
	'identifierSchemeCodeUnspsc190501.50407024': __('identifierSchemeCodeUnspsc190501.50407024'),
	'identifierSchemeCodeUnspsc190501.50407025': __('identifierSchemeCodeUnspsc190501.50407025'),
	'identifierSchemeCodeUnspsc190501.50407026': __('identifierSchemeCodeUnspsc190501.50407026'),
	'identifierSchemeCodeUnspsc190501.50407027': __('identifierSchemeCodeUnspsc190501.50407027'),
	'identifierSchemeCodeUnspsc190501.50407028': __('identifierSchemeCodeUnspsc190501.50407028'),
	'identifierSchemeCodeUnspsc190501.50407029': __('identifierSchemeCodeUnspsc190501.50407029'),
	'identifierSchemeCodeUnspsc190501.50407030': __('identifierSchemeCodeUnspsc190501.50407030'),
	'identifierSchemeCodeUnspsc190501.50407031': __('identifierSchemeCodeUnspsc190501.50407031'),
	'identifierSchemeCodeUnspsc190501.50407032': __('identifierSchemeCodeUnspsc190501.50407032'),
	'identifierSchemeCodeUnspsc190501.50407033': __('identifierSchemeCodeUnspsc190501.50407033'),
	'identifierSchemeCodeUnspsc190501.50407034': __('identifierSchemeCodeUnspsc190501.50407034'),
	'identifierSchemeCodeUnspsc190501.50407035': __('identifierSchemeCodeUnspsc190501.50407035'),
	'identifierSchemeCodeUnspsc190501.50407036': __('identifierSchemeCodeUnspsc190501.50407036'),
	'identifierSchemeCodeUnspsc190501.50407037': __('identifierSchemeCodeUnspsc190501.50407037'),
	'identifierSchemeCodeUnspsc190501.50407038': __('identifierSchemeCodeUnspsc190501.50407038'),
	'identifierSchemeCodeUnspsc190501.50407039': __('identifierSchemeCodeUnspsc190501.50407039'),
	'identifierSchemeCodeUnspsc190501.50407040': __('identifierSchemeCodeUnspsc190501.50407040'),
	'identifierSchemeCodeUnspsc190501.50407041': __('identifierSchemeCodeUnspsc190501.50407041'),
	'identifierSchemeCodeUnspsc190501.50407042': __('identifierSchemeCodeUnspsc190501.50407042'),
	'identifierSchemeCodeUnspsc190501.50407043': __('identifierSchemeCodeUnspsc190501.50407043'),
	'identifierSchemeCodeUnspsc190501.50407044': __('identifierSchemeCodeUnspsc190501.50407044'),
	'identifierSchemeCodeUnspsc190501.50407045': __('identifierSchemeCodeUnspsc190501.50407045'),
	'identifierSchemeCodeUnspsc190501.50407046': __('identifierSchemeCodeUnspsc190501.50407046'),
	'identifierSchemeCodeUnspsc190501.50407047': __('identifierSchemeCodeUnspsc190501.50407047'),
	'identifierSchemeCodeUnspsc190501.50407048': __('identifierSchemeCodeUnspsc190501.50407048'),
	'identifierSchemeCodeUnspsc190501.50407049': __('identifierSchemeCodeUnspsc190501.50407049'),
	'identifierSchemeCodeUnspsc190501.50407050': __('identifierSchemeCodeUnspsc190501.50407050'),
	'identifierSchemeCodeUnspsc190501.50407051': __('identifierSchemeCodeUnspsc190501.50407051'),
	'identifierSchemeCodeUnspsc190501.50407052': __('identifierSchemeCodeUnspsc190501.50407052'),
	'identifierSchemeCodeUnspsc190501.50407053': __('identifierSchemeCodeUnspsc190501.50407053'),
	'identifierSchemeCodeUnspsc190501.50407054': __('identifierSchemeCodeUnspsc190501.50407054'),
	'identifierSchemeCodeUnspsc190501.50407055': __('identifierSchemeCodeUnspsc190501.50407055'),
	'identifierSchemeCodeUnspsc190501.50407056': __('identifierSchemeCodeUnspsc190501.50407056'),
	'identifierSchemeCodeUnspsc190501.50407057': __('identifierSchemeCodeUnspsc190501.50407057'),
	'identifierSchemeCodeUnspsc190501.50407058': __('identifierSchemeCodeUnspsc190501.50407058'),
	'identifierSchemeCodeUnspsc190501.50407059': __('identifierSchemeCodeUnspsc190501.50407059'),
	'identifierSchemeCodeUnspsc190501.50407060': __('identifierSchemeCodeUnspsc190501.50407060'),
	'identifierSchemeCodeUnspsc190501.50407061': __('identifierSchemeCodeUnspsc190501.50407061'),
	'identifierSchemeCodeUnspsc190501.50407062': __('identifierSchemeCodeUnspsc190501.50407062'),
	'identifierSchemeCodeUnspsc190501.50407063': __('identifierSchemeCodeUnspsc190501.50407063'),
	'identifierSchemeCodeUnspsc190501.50407064': __('identifierSchemeCodeUnspsc190501.50407064'),
	'identifierSchemeCodeUnspsc190501.50407065': __('identifierSchemeCodeUnspsc190501.50407065'),
	'identifierSchemeCodeUnspsc190501.50407066': __('identifierSchemeCodeUnspsc190501.50407066'),
	'identifierSchemeCodeUnspsc190501.50407067': __('identifierSchemeCodeUnspsc190501.50407067'),
	'identifierSchemeCodeUnspsc190501.50407068': __('identifierSchemeCodeUnspsc190501.50407068'),
	'identifierSchemeCodeUnspsc190501.50407070': __('identifierSchemeCodeUnspsc190501.50407070'),
	'identifierSchemeCodeUnspsc190501.50407071': __('identifierSchemeCodeUnspsc190501.50407071'),
	'identifierSchemeCodeUnspsc190501.50407072': __('identifierSchemeCodeUnspsc190501.50407072'),
	'identifierSchemeCodeUnspsc190501.50407073': __('identifierSchemeCodeUnspsc190501.50407073'),
	'identifierSchemeCodeUnspsc190501.50407074': __('identifierSchemeCodeUnspsc190501.50407074'),
	'identifierSchemeCodeUnspsc190501.50407100': __('identifierSchemeCodeUnspsc190501.50407100'),
	'identifierSchemeCodeUnspsc190501.50407101': __('identifierSchemeCodeUnspsc190501.50407101'),
	'identifierSchemeCodeUnspsc190501.50407102': __('identifierSchemeCodeUnspsc190501.50407102'),
	'identifierSchemeCodeUnspsc190501.50407103': __('identifierSchemeCodeUnspsc190501.50407103'),
	'identifierSchemeCodeUnspsc190501.50407104': __('identifierSchemeCodeUnspsc190501.50407104'),
	'identifierSchemeCodeUnspsc190501.50407105': __('identifierSchemeCodeUnspsc190501.50407105'),
	'identifierSchemeCodeUnspsc190501.50407106': __('identifierSchemeCodeUnspsc190501.50407106'),
	'identifierSchemeCodeUnspsc190501.50407107': __('identifierSchemeCodeUnspsc190501.50407107'),
	'identifierSchemeCodeUnspsc190501.50407108': __('identifierSchemeCodeUnspsc190501.50407108'),
	'identifierSchemeCodeUnspsc190501.50407109': __('identifierSchemeCodeUnspsc190501.50407109'),
	'identifierSchemeCodeUnspsc190501.50407110': __('identifierSchemeCodeUnspsc190501.50407110'),
	'identifierSchemeCodeUnspsc190501.50407200': __('identifierSchemeCodeUnspsc190501.50407200'),
	'identifierSchemeCodeUnspsc190501.50407201': __('identifierSchemeCodeUnspsc190501.50407201'),
	'identifierSchemeCodeUnspsc190501.50407202': __('identifierSchemeCodeUnspsc190501.50407202'),
	'identifierSchemeCodeUnspsc190501.50407203': __('identifierSchemeCodeUnspsc190501.50407203'),
	'identifierSchemeCodeUnspsc190501.50407204': __('identifierSchemeCodeUnspsc190501.50407204'),
	'identifierSchemeCodeUnspsc190501.50407205': __('identifierSchemeCodeUnspsc190501.50407205'),
	'identifierSchemeCodeUnspsc190501.50410000': __('identifierSchemeCodeUnspsc190501.50410000'),
	'identifierSchemeCodeUnspsc190501.50411500': __('identifierSchemeCodeUnspsc190501.50411500'),
	'identifierSchemeCodeUnspsc190501.50411501': __('identifierSchemeCodeUnspsc190501.50411501'),
	'identifierSchemeCodeUnspsc190501.50411502': __('identifierSchemeCodeUnspsc190501.50411502'),
	'identifierSchemeCodeUnspsc190501.50411503': __('identifierSchemeCodeUnspsc190501.50411503'),
	'identifierSchemeCodeUnspsc190501.50411504': __('identifierSchemeCodeUnspsc190501.50411504'),
	'identifierSchemeCodeUnspsc190501.50411505': __('identifierSchemeCodeUnspsc190501.50411505'),
	'identifierSchemeCodeUnspsc190501.50411506': __('identifierSchemeCodeUnspsc190501.50411506'),
	'identifierSchemeCodeUnspsc190501.50411507': __('identifierSchemeCodeUnspsc190501.50411507'),
	'identifierSchemeCodeUnspsc190501.50411508': __('identifierSchemeCodeUnspsc190501.50411508'),
	'identifierSchemeCodeUnspsc190501.50411509': __('identifierSchemeCodeUnspsc190501.50411509'),
	'identifierSchemeCodeUnspsc190501.50411510': __('identifierSchemeCodeUnspsc190501.50411510'),
	'identifierSchemeCodeUnspsc190501.50411511': __('identifierSchemeCodeUnspsc190501.50411511'),
	'identifierSchemeCodeUnspsc190501.50411512': __('identifierSchemeCodeUnspsc190501.50411512'),
	'identifierSchemeCodeUnspsc190501.50411513': __('identifierSchemeCodeUnspsc190501.50411513'),
	'identifierSchemeCodeUnspsc190501.50411600': __('identifierSchemeCodeUnspsc190501.50411600'),
	'identifierSchemeCodeUnspsc190501.50411601': __('identifierSchemeCodeUnspsc190501.50411601'),
	'identifierSchemeCodeUnspsc190501.50411602': __('identifierSchemeCodeUnspsc190501.50411602'),
	'identifierSchemeCodeUnspsc190501.50411603': __('identifierSchemeCodeUnspsc190501.50411603'),
	'identifierSchemeCodeUnspsc190501.50411604': __('identifierSchemeCodeUnspsc190501.50411604'),
	'identifierSchemeCodeUnspsc190501.50411605': __('identifierSchemeCodeUnspsc190501.50411605'),
	'identifierSchemeCodeUnspsc190501.50411700': __('identifierSchemeCodeUnspsc190501.50411700'),
	'identifierSchemeCodeUnspsc190501.50411701': __('identifierSchemeCodeUnspsc190501.50411701'),
	'identifierSchemeCodeUnspsc190501.50411702': __('identifierSchemeCodeUnspsc190501.50411702'),
	'identifierSchemeCodeUnspsc190501.50411703': __('identifierSchemeCodeUnspsc190501.50411703'),
	'identifierSchemeCodeUnspsc190501.50411704': __('identifierSchemeCodeUnspsc190501.50411704'),
	'identifierSchemeCodeUnspsc190501.50411705': __('identifierSchemeCodeUnspsc190501.50411705'),
	'identifierSchemeCodeUnspsc190501.50411706': __('identifierSchemeCodeUnspsc190501.50411706'),
	'identifierSchemeCodeUnspsc190501.50411707': __('identifierSchemeCodeUnspsc190501.50411707'),
	'identifierSchemeCodeUnspsc190501.50411708': __('identifierSchemeCodeUnspsc190501.50411708'),
	'identifierSchemeCodeUnspsc190501.50411709': __('identifierSchemeCodeUnspsc190501.50411709'),
	'identifierSchemeCodeUnspsc190501.50411710': __('identifierSchemeCodeUnspsc190501.50411710'),
	'identifierSchemeCodeUnspsc190501.50411711': __('identifierSchemeCodeUnspsc190501.50411711'),
	'identifierSchemeCodeUnspsc190501.50411712': __('identifierSchemeCodeUnspsc190501.50411712'),
	'identifierSchemeCodeUnspsc190501.50411713': __('identifierSchemeCodeUnspsc190501.50411713'),
	'identifierSchemeCodeUnspsc190501.50411714': __('identifierSchemeCodeUnspsc190501.50411714'),
	'identifierSchemeCodeUnspsc190501.50411715': __('identifierSchemeCodeUnspsc190501.50411715'),
	'identifierSchemeCodeUnspsc190501.50411716': __('identifierSchemeCodeUnspsc190501.50411716'),
	'identifierSchemeCodeUnspsc190501.50411717': __('identifierSchemeCodeUnspsc190501.50411717'),
	'identifierSchemeCodeUnspsc190501.50411718': __('identifierSchemeCodeUnspsc190501.50411718'),
	'identifierSchemeCodeUnspsc190501.50411719': __('identifierSchemeCodeUnspsc190501.50411719'),
	'identifierSchemeCodeUnspsc190501.50411720': __('identifierSchemeCodeUnspsc190501.50411720'),
	'identifierSchemeCodeUnspsc190501.50411721': __('identifierSchemeCodeUnspsc190501.50411721'),
	'identifierSchemeCodeUnspsc190501.50411722': __('identifierSchemeCodeUnspsc190501.50411722'),
	'identifierSchemeCodeUnspsc190501.50411723': __('identifierSchemeCodeUnspsc190501.50411723'),
	'identifierSchemeCodeUnspsc190501.50411724': __('identifierSchemeCodeUnspsc190501.50411724'),
	'identifierSchemeCodeUnspsc190501.50411725': __('identifierSchemeCodeUnspsc190501.50411725'),
	'identifierSchemeCodeUnspsc190501.50411726': __('identifierSchemeCodeUnspsc190501.50411726'),
	'identifierSchemeCodeUnspsc190501.50411727': __('identifierSchemeCodeUnspsc190501.50411727'),
	'identifierSchemeCodeUnspsc190501.50411728': __('identifierSchemeCodeUnspsc190501.50411728'),
	'identifierSchemeCodeUnspsc190501.50411729': __('identifierSchemeCodeUnspsc190501.50411729'),
	'identifierSchemeCodeUnspsc190501.50411730': __('identifierSchemeCodeUnspsc190501.50411730'),
	'identifierSchemeCodeUnspsc190501.50411731': __('identifierSchemeCodeUnspsc190501.50411731'),
	'identifierSchemeCodeUnspsc190501.50411732': __('identifierSchemeCodeUnspsc190501.50411732'),
	'identifierSchemeCodeUnspsc190501.50411733': __('identifierSchemeCodeUnspsc190501.50411733'),
	'identifierSchemeCodeUnspsc190501.50411734': __('identifierSchemeCodeUnspsc190501.50411734'),
	'identifierSchemeCodeUnspsc190501.50411735': __('identifierSchemeCodeUnspsc190501.50411735'),
	'identifierSchemeCodeUnspsc190501.50411736': __('identifierSchemeCodeUnspsc190501.50411736'),
	'identifierSchemeCodeUnspsc190501.50411737': __('identifierSchemeCodeUnspsc190501.50411737'),
	'identifierSchemeCodeUnspsc190501.50411738': __('identifierSchemeCodeUnspsc190501.50411738'),
	'identifierSchemeCodeUnspsc190501.50411739': __('identifierSchemeCodeUnspsc190501.50411739'),
	'identifierSchemeCodeUnspsc190501.50411740': __('identifierSchemeCodeUnspsc190501.50411740'),
	'identifierSchemeCodeUnspsc190501.50411741': __('identifierSchemeCodeUnspsc190501.50411741'),
	'identifierSchemeCodeUnspsc190501.50411742': __('identifierSchemeCodeUnspsc190501.50411742'),
	'identifierSchemeCodeUnspsc190501.50411743': __('identifierSchemeCodeUnspsc190501.50411743'),
	'identifierSchemeCodeUnspsc190501.50411744': __('identifierSchemeCodeUnspsc190501.50411744'),
	'identifierSchemeCodeUnspsc190501.50411745': __('identifierSchemeCodeUnspsc190501.50411745'),
	'identifierSchemeCodeUnspsc190501.50411746': __('identifierSchemeCodeUnspsc190501.50411746'),
	'identifierSchemeCodeUnspsc190501.50411747': __('identifierSchemeCodeUnspsc190501.50411747'),
	'identifierSchemeCodeUnspsc190501.50411748': __('identifierSchemeCodeUnspsc190501.50411748'),
	'identifierSchemeCodeUnspsc190501.50411749': __('identifierSchemeCodeUnspsc190501.50411749'),
	'identifierSchemeCodeUnspsc190501.50411750': __('identifierSchemeCodeUnspsc190501.50411750'),
	'identifierSchemeCodeUnspsc190501.50411751': __('identifierSchemeCodeUnspsc190501.50411751'),
	'identifierSchemeCodeUnspsc190501.50411752': __('identifierSchemeCodeUnspsc190501.50411752'),
	'identifierSchemeCodeUnspsc190501.50411753': __('identifierSchemeCodeUnspsc190501.50411753'),
	'identifierSchemeCodeUnspsc190501.50411754': __('identifierSchemeCodeUnspsc190501.50411754'),
	'identifierSchemeCodeUnspsc190501.50411755': __('identifierSchemeCodeUnspsc190501.50411755'),
	'identifierSchemeCodeUnspsc190501.50411756': __('identifierSchemeCodeUnspsc190501.50411756'),
	'identifierSchemeCodeUnspsc190501.50411757': __('identifierSchemeCodeUnspsc190501.50411757'),
	'identifierSchemeCodeUnspsc190501.50411758': __('identifierSchemeCodeUnspsc190501.50411758'),
	'identifierSchemeCodeUnspsc190501.50411759': __('identifierSchemeCodeUnspsc190501.50411759'),
	'identifierSchemeCodeUnspsc190501.50411760': __('identifierSchemeCodeUnspsc190501.50411760'),
	'identifierSchemeCodeUnspsc190501.50411761': __('identifierSchemeCodeUnspsc190501.50411761'),
	'identifierSchemeCodeUnspsc190501.50411762': __('identifierSchemeCodeUnspsc190501.50411762'),
	'identifierSchemeCodeUnspsc190501.50411763': __('identifierSchemeCodeUnspsc190501.50411763'),
	'identifierSchemeCodeUnspsc190501.50411764': __('identifierSchemeCodeUnspsc190501.50411764'),
	'identifierSchemeCodeUnspsc190501.50411765': __('identifierSchemeCodeUnspsc190501.50411765'),
	'identifierSchemeCodeUnspsc190501.50411766': __('identifierSchemeCodeUnspsc190501.50411766'),
	'identifierSchemeCodeUnspsc190501.50411767': __('identifierSchemeCodeUnspsc190501.50411767'),
	'identifierSchemeCodeUnspsc190501.50411768': __('identifierSchemeCodeUnspsc190501.50411768'),
	'identifierSchemeCodeUnspsc190501.50411769': __('identifierSchemeCodeUnspsc190501.50411769'),
	'identifierSchemeCodeUnspsc190501.50411770': __('identifierSchemeCodeUnspsc190501.50411770'),
	'identifierSchemeCodeUnspsc190501.50411771': __('identifierSchemeCodeUnspsc190501.50411771'),
	'identifierSchemeCodeUnspsc190501.50411772': __('identifierSchemeCodeUnspsc190501.50411772'),
	'identifierSchemeCodeUnspsc190501.50411773': __('identifierSchemeCodeUnspsc190501.50411773'),
	'identifierSchemeCodeUnspsc190501.50411774': __('identifierSchemeCodeUnspsc190501.50411774'),
	'identifierSchemeCodeUnspsc190501.50411775': __('identifierSchemeCodeUnspsc190501.50411775'),
	'identifierSchemeCodeUnspsc190501.50411776': __('identifierSchemeCodeUnspsc190501.50411776'),
	'identifierSchemeCodeUnspsc190501.50411777': __('identifierSchemeCodeUnspsc190501.50411777'),
	'identifierSchemeCodeUnspsc190501.50411778': __('identifierSchemeCodeUnspsc190501.50411778'),
	'identifierSchemeCodeUnspsc190501.50411779': __('identifierSchemeCodeUnspsc190501.50411779'),
	'identifierSchemeCodeUnspsc190501.50411780': __('identifierSchemeCodeUnspsc190501.50411780'),
	'identifierSchemeCodeUnspsc190501.50411781': __('identifierSchemeCodeUnspsc190501.50411781'),
	'identifierSchemeCodeUnspsc190501.50411782': __('identifierSchemeCodeUnspsc190501.50411782'),
	'identifierSchemeCodeUnspsc190501.50411800': __('identifierSchemeCodeUnspsc190501.50411800'),
	'identifierSchemeCodeUnspsc190501.50411801': __('identifierSchemeCodeUnspsc190501.50411801'),
	'identifierSchemeCodeUnspsc190501.50411802': __('identifierSchemeCodeUnspsc190501.50411802'),
	'identifierSchemeCodeUnspsc190501.50411803': __('identifierSchemeCodeUnspsc190501.50411803'),
	'identifierSchemeCodeUnspsc190501.50411804': __('identifierSchemeCodeUnspsc190501.50411804'),
	'identifierSchemeCodeUnspsc190501.50411805': __('identifierSchemeCodeUnspsc190501.50411805'),
	'identifierSchemeCodeUnspsc190501.50411806': __('identifierSchemeCodeUnspsc190501.50411806'),
	'identifierSchemeCodeUnspsc190501.50411807': __('identifierSchemeCodeUnspsc190501.50411807'),
	'identifierSchemeCodeUnspsc190501.50411808': __('identifierSchemeCodeUnspsc190501.50411808'),
	'identifierSchemeCodeUnspsc190501.50411809': __('identifierSchemeCodeUnspsc190501.50411809'),
	'identifierSchemeCodeUnspsc190501.50411810': __('identifierSchemeCodeUnspsc190501.50411810'),
	'identifierSchemeCodeUnspsc190501.50411811': __('identifierSchemeCodeUnspsc190501.50411811'),
	'identifierSchemeCodeUnspsc190501.50411812': __('identifierSchemeCodeUnspsc190501.50411812'),
	'identifierSchemeCodeUnspsc190501.50411813': __('identifierSchemeCodeUnspsc190501.50411813'),
	'identifierSchemeCodeUnspsc190501.50411814': __('identifierSchemeCodeUnspsc190501.50411814'),
	'identifierSchemeCodeUnspsc190501.50411815': __('identifierSchemeCodeUnspsc190501.50411815'),
	'identifierSchemeCodeUnspsc190501.50411816': __('identifierSchemeCodeUnspsc190501.50411816'),
	'identifierSchemeCodeUnspsc190501.50411817': __('identifierSchemeCodeUnspsc190501.50411817'),
	'identifierSchemeCodeUnspsc190501.50411818': __('identifierSchemeCodeUnspsc190501.50411818'),
	'identifierSchemeCodeUnspsc190501.50411819': __('identifierSchemeCodeUnspsc190501.50411819'),
	'identifierSchemeCodeUnspsc190501.50411820': __('identifierSchemeCodeUnspsc190501.50411820'),
	'identifierSchemeCodeUnspsc190501.50411821': __('identifierSchemeCodeUnspsc190501.50411821'),
	'identifierSchemeCodeUnspsc190501.50411822': __('identifierSchemeCodeUnspsc190501.50411822'),
	'identifierSchemeCodeUnspsc190501.50411823': __('identifierSchemeCodeUnspsc190501.50411823'),
	'identifierSchemeCodeUnspsc190501.50411824': __('identifierSchemeCodeUnspsc190501.50411824'),
	'identifierSchemeCodeUnspsc190501.50411825': __('identifierSchemeCodeUnspsc190501.50411825'),
	'identifierSchemeCodeUnspsc190501.50411826': __('identifierSchemeCodeUnspsc190501.50411826'),
	'identifierSchemeCodeUnspsc190501.50411827': __('identifierSchemeCodeUnspsc190501.50411827'),
	'identifierSchemeCodeUnspsc190501.50411828': __('identifierSchemeCodeUnspsc190501.50411828'),
	'identifierSchemeCodeUnspsc190501.50411829': __('identifierSchemeCodeUnspsc190501.50411829'),
	'identifierSchemeCodeUnspsc190501.50411830': __('identifierSchemeCodeUnspsc190501.50411830'),
	'identifierSchemeCodeUnspsc190501.50411831': __('identifierSchemeCodeUnspsc190501.50411831'),
	'identifierSchemeCodeUnspsc190501.50411832': __('identifierSchemeCodeUnspsc190501.50411832'),
	'identifierSchemeCodeUnspsc190501.50411833': __('identifierSchemeCodeUnspsc190501.50411833'),
	'identifierSchemeCodeUnspsc190501.50411834': __('identifierSchemeCodeUnspsc190501.50411834'),
	'identifierSchemeCodeUnspsc190501.50411835': __('identifierSchemeCodeUnspsc190501.50411835'),
	'identifierSchemeCodeUnspsc190501.50411836': __('identifierSchemeCodeUnspsc190501.50411836'),
	'identifierSchemeCodeUnspsc190501.50411837': __('identifierSchemeCodeUnspsc190501.50411837'),
	'identifierSchemeCodeUnspsc190501.50411838': __('identifierSchemeCodeUnspsc190501.50411838'),
	'identifierSchemeCodeUnspsc190501.50411839': __('identifierSchemeCodeUnspsc190501.50411839'),
	'identifierSchemeCodeUnspsc190501.50411840': __('identifierSchemeCodeUnspsc190501.50411840'),
	'identifierSchemeCodeUnspsc190501.50411841': __('identifierSchemeCodeUnspsc190501.50411841'),
	'identifierSchemeCodeUnspsc190501.50411842': __('identifierSchemeCodeUnspsc190501.50411842'),
	'identifierSchemeCodeUnspsc190501.50411843': __('identifierSchemeCodeUnspsc190501.50411843'),
	'identifierSchemeCodeUnspsc190501.50411844': __('identifierSchemeCodeUnspsc190501.50411844'),
	'identifierSchemeCodeUnspsc190501.50411845': __('identifierSchemeCodeUnspsc190501.50411845'),
	'identifierSchemeCodeUnspsc190501.50411846': __('identifierSchemeCodeUnspsc190501.50411846'),
	'identifierSchemeCodeUnspsc190501.50411847': __('identifierSchemeCodeUnspsc190501.50411847'),
	'identifierSchemeCodeUnspsc190501.50411848': __('identifierSchemeCodeUnspsc190501.50411848'),
	'identifierSchemeCodeUnspsc190501.50411849': __('identifierSchemeCodeUnspsc190501.50411849'),
	'identifierSchemeCodeUnspsc190501.50411850': __('identifierSchemeCodeUnspsc190501.50411850'),
	'identifierSchemeCodeUnspsc190501.50411851': __('identifierSchemeCodeUnspsc190501.50411851'),
	'identifierSchemeCodeUnspsc190501.50411852': __('identifierSchemeCodeUnspsc190501.50411852'),
	'identifierSchemeCodeUnspsc190501.50411900': __('identifierSchemeCodeUnspsc190501.50411900'),
	'identifierSchemeCodeUnspsc190501.50411901': __('identifierSchemeCodeUnspsc190501.50411901'),
	'identifierSchemeCodeUnspsc190501.50411902': __('identifierSchemeCodeUnspsc190501.50411902'),
	'identifierSchemeCodeUnspsc190501.50411903': __('identifierSchemeCodeUnspsc190501.50411903'),
	'identifierSchemeCodeUnspsc190501.50411904': __('identifierSchemeCodeUnspsc190501.50411904'),
	'identifierSchemeCodeUnspsc190501.50411905': __('identifierSchemeCodeUnspsc190501.50411905'),
	'identifierSchemeCodeUnspsc190501.50411906': __('identifierSchemeCodeUnspsc190501.50411906'),
	'identifierSchemeCodeUnspsc190501.50411907': __('identifierSchemeCodeUnspsc190501.50411907'),
	'identifierSchemeCodeUnspsc190501.50411908': __('identifierSchemeCodeUnspsc190501.50411908'),
	'identifierSchemeCodeUnspsc190501.50411909': __('identifierSchemeCodeUnspsc190501.50411909'),
	'identifierSchemeCodeUnspsc190501.50411910': __('identifierSchemeCodeUnspsc190501.50411910'),
	'identifierSchemeCodeUnspsc190501.50411911': __('identifierSchemeCodeUnspsc190501.50411911'),
	'identifierSchemeCodeUnspsc190501.50411912': __('identifierSchemeCodeUnspsc190501.50411912'),
	'identifierSchemeCodeUnspsc190501.50411913': __('identifierSchemeCodeUnspsc190501.50411913'),
	'identifierSchemeCodeUnspsc190501.50411914': __('identifierSchemeCodeUnspsc190501.50411914'),
	'identifierSchemeCodeUnspsc190501.50411915': __('identifierSchemeCodeUnspsc190501.50411915'),
	'identifierSchemeCodeUnspsc190501.50411916': __('identifierSchemeCodeUnspsc190501.50411916'),
	'identifierSchemeCodeUnspsc190501.50411917': __('identifierSchemeCodeUnspsc190501.50411917'),
	'identifierSchemeCodeUnspsc190501.50411918': __('identifierSchemeCodeUnspsc190501.50411918'),
	'identifierSchemeCodeUnspsc190501.50411919': __('identifierSchemeCodeUnspsc190501.50411919'),
	'identifierSchemeCodeUnspsc190501.50411920': __('identifierSchemeCodeUnspsc190501.50411920'),
	'identifierSchemeCodeUnspsc190501.50411921': __('identifierSchemeCodeUnspsc190501.50411921'),
	'identifierSchemeCodeUnspsc190501.50411922': __('identifierSchemeCodeUnspsc190501.50411922'),
	'identifierSchemeCodeUnspsc190501.50412000': __('identifierSchemeCodeUnspsc190501.50412000'),
	'identifierSchemeCodeUnspsc190501.50412001': __('identifierSchemeCodeUnspsc190501.50412001'),
	'identifierSchemeCodeUnspsc190501.50412002': __('identifierSchemeCodeUnspsc190501.50412002'),
	'identifierSchemeCodeUnspsc190501.50412003': __('identifierSchemeCodeUnspsc190501.50412003'),
	'identifierSchemeCodeUnspsc190501.50412004': __('identifierSchemeCodeUnspsc190501.50412004'),
	'identifierSchemeCodeUnspsc190501.50412100': __('identifierSchemeCodeUnspsc190501.50412100'),
	'identifierSchemeCodeUnspsc190501.50412101': __('identifierSchemeCodeUnspsc190501.50412101'),
	'identifierSchemeCodeUnspsc190501.50412102': __('identifierSchemeCodeUnspsc190501.50412102'),
	'identifierSchemeCodeUnspsc190501.50412103': __('identifierSchemeCodeUnspsc190501.50412103'),
	'identifierSchemeCodeUnspsc190501.50412104': __('identifierSchemeCodeUnspsc190501.50412104'),
	'identifierSchemeCodeUnspsc190501.50412105': __('identifierSchemeCodeUnspsc190501.50412105'),
	'identifierSchemeCodeUnspsc190501.50412106': __('identifierSchemeCodeUnspsc190501.50412106'),
	'identifierSchemeCodeUnspsc190501.50412107': __('identifierSchemeCodeUnspsc190501.50412107'),
	'identifierSchemeCodeUnspsc190501.50412200': __('identifierSchemeCodeUnspsc190501.50412200'),
	'identifierSchemeCodeUnspsc190501.50412201': __('identifierSchemeCodeUnspsc190501.50412201'),
	'identifierSchemeCodeUnspsc190501.50412202': __('identifierSchemeCodeUnspsc190501.50412202'),
	'identifierSchemeCodeUnspsc190501.50412203': __('identifierSchemeCodeUnspsc190501.50412203'),
	'identifierSchemeCodeUnspsc190501.50412204': __('identifierSchemeCodeUnspsc190501.50412204'),
	'identifierSchemeCodeUnspsc190501.50412205': __('identifierSchemeCodeUnspsc190501.50412205'),
	'identifierSchemeCodeUnspsc190501.50412206': __('identifierSchemeCodeUnspsc190501.50412206'),
	'identifierSchemeCodeUnspsc190501.50412207': __('identifierSchemeCodeUnspsc190501.50412207'),
	'identifierSchemeCodeUnspsc190501.50412208': __('identifierSchemeCodeUnspsc190501.50412208'),
	'identifierSchemeCodeUnspsc190501.50412209': __('identifierSchemeCodeUnspsc190501.50412209'),
	'identifierSchemeCodeUnspsc190501.50412210': __('identifierSchemeCodeUnspsc190501.50412210'),
	'identifierSchemeCodeUnspsc190501.50412211': __('identifierSchemeCodeUnspsc190501.50412211'),
	'identifierSchemeCodeUnspsc190501.50412300': __('identifierSchemeCodeUnspsc190501.50412300'),
	'identifierSchemeCodeUnspsc190501.50412301': __('identifierSchemeCodeUnspsc190501.50412301'),
	'identifierSchemeCodeUnspsc190501.50412302': __('identifierSchemeCodeUnspsc190501.50412302'),
	'identifierSchemeCodeUnspsc190501.50412303': __('identifierSchemeCodeUnspsc190501.50412303'),
	'identifierSchemeCodeUnspsc190501.50412304': __('identifierSchemeCodeUnspsc190501.50412304'),
	'identifierSchemeCodeUnspsc190501.50412400': __('identifierSchemeCodeUnspsc190501.50412400'),
	'identifierSchemeCodeUnspsc190501.50412401': __('identifierSchemeCodeUnspsc190501.50412401'),
	'identifierSchemeCodeUnspsc190501.50412402': __('identifierSchemeCodeUnspsc190501.50412402'),
	'identifierSchemeCodeUnspsc190501.50412500': __('identifierSchemeCodeUnspsc190501.50412500'),
	'identifierSchemeCodeUnspsc190501.50412501': __('identifierSchemeCodeUnspsc190501.50412501'),
	'identifierSchemeCodeUnspsc190501.50412502': __('identifierSchemeCodeUnspsc190501.50412502'),
	'identifierSchemeCodeUnspsc190501.50412503': __('identifierSchemeCodeUnspsc190501.50412503'),
	'identifierSchemeCodeUnspsc190501.50412504': __('identifierSchemeCodeUnspsc190501.50412504'),
	'identifierSchemeCodeUnspsc190501.50412505': __('identifierSchemeCodeUnspsc190501.50412505'),
	'identifierSchemeCodeUnspsc190501.50412506': __('identifierSchemeCodeUnspsc190501.50412506'),
	'identifierSchemeCodeUnspsc190501.50412600': __('identifierSchemeCodeUnspsc190501.50412600'),
	'identifierSchemeCodeUnspsc190501.50412601': __('identifierSchemeCodeUnspsc190501.50412601'),
	'identifierSchemeCodeUnspsc190501.50412602': __('identifierSchemeCodeUnspsc190501.50412602'),
	'identifierSchemeCodeUnspsc190501.50412603': __('identifierSchemeCodeUnspsc190501.50412603'),
	'identifierSchemeCodeUnspsc190501.50412604': __('identifierSchemeCodeUnspsc190501.50412604'),
	'identifierSchemeCodeUnspsc190501.50412605': __('identifierSchemeCodeUnspsc190501.50412605'),
	'identifierSchemeCodeUnspsc190501.50412606': __('identifierSchemeCodeUnspsc190501.50412606'),
	'identifierSchemeCodeUnspsc190501.50412607': __('identifierSchemeCodeUnspsc190501.50412607'),
	'identifierSchemeCodeUnspsc190501.50412608': __('identifierSchemeCodeUnspsc190501.50412608'),
	'identifierSchemeCodeUnspsc190501.50412609': __('identifierSchemeCodeUnspsc190501.50412609'),
	'identifierSchemeCodeUnspsc190501.50412610': __('identifierSchemeCodeUnspsc190501.50412610'),
	'identifierSchemeCodeUnspsc190501.50412611': __('identifierSchemeCodeUnspsc190501.50412611'),
	'identifierSchemeCodeUnspsc190501.50412612': __('identifierSchemeCodeUnspsc190501.50412612'),
	'identifierSchemeCodeUnspsc190501.50412700': __('identifierSchemeCodeUnspsc190501.50412700'),
	'identifierSchemeCodeUnspsc190501.50412701': __('identifierSchemeCodeUnspsc190501.50412701'),
	'identifierSchemeCodeUnspsc190501.50412702': __('identifierSchemeCodeUnspsc190501.50412702'),
	'identifierSchemeCodeUnspsc190501.50412703': __('identifierSchemeCodeUnspsc190501.50412703'),
	'identifierSchemeCodeUnspsc190501.50412704': __('identifierSchemeCodeUnspsc190501.50412704'),
	'identifierSchemeCodeUnspsc190501.50412705': __('identifierSchemeCodeUnspsc190501.50412705'),
	'identifierSchemeCodeUnspsc190501.50412706': __('identifierSchemeCodeUnspsc190501.50412706'),
	'identifierSchemeCodeUnspsc190501.50412707': __('identifierSchemeCodeUnspsc190501.50412707'),
	'identifierSchemeCodeUnspsc190501.50412708': __('identifierSchemeCodeUnspsc190501.50412708'),
	'identifierSchemeCodeUnspsc190501.50412709': __('identifierSchemeCodeUnspsc190501.50412709'),
	'identifierSchemeCodeUnspsc190501.50412710': __('identifierSchemeCodeUnspsc190501.50412710'),
	'identifierSchemeCodeUnspsc190501.50412711': __('identifierSchemeCodeUnspsc190501.50412711'),
	'identifierSchemeCodeUnspsc190501.50412712': __('identifierSchemeCodeUnspsc190501.50412712'),
	'identifierSchemeCodeUnspsc190501.50412713': __('identifierSchemeCodeUnspsc190501.50412713'),
	'identifierSchemeCodeUnspsc190501.50412714': __('identifierSchemeCodeUnspsc190501.50412714'),
	'identifierSchemeCodeUnspsc190501.50412715': __('identifierSchemeCodeUnspsc190501.50412715'),
	'identifierSchemeCodeUnspsc190501.50412800': __('identifierSchemeCodeUnspsc190501.50412800'),
	'identifierSchemeCodeUnspsc190501.50412801': __('identifierSchemeCodeUnspsc190501.50412801'),
	'identifierSchemeCodeUnspsc190501.50412802': __('identifierSchemeCodeUnspsc190501.50412802'),
	'identifierSchemeCodeUnspsc190501.50412803': __('identifierSchemeCodeUnspsc190501.50412803'),
	'identifierSchemeCodeUnspsc190501.50412804': __('identifierSchemeCodeUnspsc190501.50412804'),
	'identifierSchemeCodeUnspsc190501.50412805': __('identifierSchemeCodeUnspsc190501.50412805'),
	'identifierSchemeCodeUnspsc190501.50412806': __('identifierSchemeCodeUnspsc190501.50412806'),
	'identifierSchemeCodeUnspsc190501.50412807': __('identifierSchemeCodeUnspsc190501.50412807'),
	'identifierSchemeCodeUnspsc190501.50412900': __('identifierSchemeCodeUnspsc190501.50412900'),
	'identifierSchemeCodeUnspsc190501.50412901': __('identifierSchemeCodeUnspsc190501.50412901'),
	'identifierSchemeCodeUnspsc190501.50412902': __('identifierSchemeCodeUnspsc190501.50412902'),
	'identifierSchemeCodeUnspsc190501.50412903': __('identifierSchemeCodeUnspsc190501.50412903'),
	'identifierSchemeCodeUnspsc190501.50412904': __('identifierSchemeCodeUnspsc190501.50412904'),
	'identifierSchemeCodeUnspsc190501.50412905': __('identifierSchemeCodeUnspsc190501.50412905'),
	'identifierSchemeCodeUnspsc190501.50412906': __('identifierSchemeCodeUnspsc190501.50412906'),
	'identifierSchemeCodeUnspsc190501.50412907': __('identifierSchemeCodeUnspsc190501.50412907'),
	'identifierSchemeCodeUnspsc190501.50412908': __('identifierSchemeCodeUnspsc190501.50412908'),
	'identifierSchemeCodeUnspsc190501.50412909': __('identifierSchemeCodeUnspsc190501.50412909'),
	'identifierSchemeCodeUnspsc190501.50412910': __('identifierSchemeCodeUnspsc190501.50412910'),
	'identifierSchemeCodeUnspsc190501.50412911': __('identifierSchemeCodeUnspsc190501.50412911'),
	'identifierSchemeCodeUnspsc190501.50413000': __('identifierSchemeCodeUnspsc190501.50413000'),
	'identifierSchemeCodeUnspsc190501.50413001': __('identifierSchemeCodeUnspsc190501.50413001'),
	'identifierSchemeCodeUnspsc190501.50413002': __('identifierSchemeCodeUnspsc190501.50413002'),
	'identifierSchemeCodeUnspsc190501.50413003': __('identifierSchemeCodeUnspsc190501.50413003'),
	'identifierSchemeCodeUnspsc190501.50413004': __('identifierSchemeCodeUnspsc190501.50413004'),
	'identifierSchemeCodeUnspsc190501.50413005': __('identifierSchemeCodeUnspsc190501.50413005'),
	'identifierSchemeCodeUnspsc190501.50413006': __('identifierSchemeCodeUnspsc190501.50413006'),
	'identifierSchemeCodeUnspsc190501.50413007': __('identifierSchemeCodeUnspsc190501.50413007'),
	'identifierSchemeCodeUnspsc190501.50413008': __('identifierSchemeCodeUnspsc190501.50413008'),
	'identifierSchemeCodeUnspsc190501.50413009': __('identifierSchemeCodeUnspsc190501.50413009'),
	'identifierSchemeCodeUnspsc190501.50413010': __('identifierSchemeCodeUnspsc190501.50413010'),
	'identifierSchemeCodeUnspsc190501.50413011': __('identifierSchemeCodeUnspsc190501.50413011'),
	'identifierSchemeCodeUnspsc190501.50413012': __('identifierSchemeCodeUnspsc190501.50413012'),
	'identifierSchemeCodeUnspsc190501.50413013': __('identifierSchemeCodeUnspsc190501.50413013'),
	'identifierSchemeCodeUnspsc190501.50413014': __('identifierSchemeCodeUnspsc190501.50413014'),
	'identifierSchemeCodeUnspsc190501.50413015': __('identifierSchemeCodeUnspsc190501.50413015'),
	'identifierSchemeCodeUnspsc190501.50413016': __('identifierSchemeCodeUnspsc190501.50413016'),
	'identifierSchemeCodeUnspsc190501.50413100': __('identifierSchemeCodeUnspsc190501.50413100'),
	'identifierSchemeCodeUnspsc190501.50413101': __('identifierSchemeCodeUnspsc190501.50413101'),
	'identifierSchemeCodeUnspsc190501.50413102': __('identifierSchemeCodeUnspsc190501.50413102'),
	'identifierSchemeCodeUnspsc190501.50413200': __('identifierSchemeCodeUnspsc190501.50413200'),
	'identifierSchemeCodeUnspsc190501.50413201': __('identifierSchemeCodeUnspsc190501.50413201'),
	'identifierSchemeCodeUnspsc190501.50413202': __('identifierSchemeCodeUnspsc190501.50413202'),
	'identifierSchemeCodeUnspsc190501.50413203': __('identifierSchemeCodeUnspsc190501.50413203'),
	'identifierSchemeCodeUnspsc190501.50413204': __('identifierSchemeCodeUnspsc190501.50413204'),
	'identifierSchemeCodeUnspsc190501.50413300': __('identifierSchemeCodeUnspsc190501.50413300'),
	'identifierSchemeCodeUnspsc190501.50413301': __('identifierSchemeCodeUnspsc190501.50413301'),
	'identifierSchemeCodeUnspsc190501.50413302': __('identifierSchemeCodeUnspsc190501.50413302'),
	'identifierSchemeCodeUnspsc190501.50413303': __('identifierSchemeCodeUnspsc190501.50413303'),
	'identifierSchemeCodeUnspsc190501.50413304': __('identifierSchemeCodeUnspsc190501.50413304'),
	'identifierSchemeCodeUnspsc190501.50413305': __('identifierSchemeCodeUnspsc190501.50413305'),
	'identifierSchemeCodeUnspsc190501.50413306': __('identifierSchemeCodeUnspsc190501.50413306'),
	'identifierSchemeCodeUnspsc190501.50413307': __('identifierSchemeCodeUnspsc190501.50413307'),
	'identifierSchemeCodeUnspsc190501.50413308': __('identifierSchemeCodeUnspsc190501.50413308'),
	'identifierSchemeCodeUnspsc190501.50413309': __('identifierSchemeCodeUnspsc190501.50413309'),
	'identifierSchemeCodeUnspsc190501.50413310': __('identifierSchemeCodeUnspsc190501.50413310'),
	'identifierSchemeCodeUnspsc190501.50413311': __('identifierSchemeCodeUnspsc190501.50413311'),
	'identifierSchemeCodeUnspsc190501.50413312': __('identifierSchemeCodeUnspsc190501.50413312'),
	'identifierSchemeCodeUnspsc190501.50413313': __('identifierSchemeCodeUnspsc190501.50413313'),
	'identifierSchemeCodeUnspsc190501.50413314': __('identifierSchemeCodeUnspsc190501.50413314'),
	'identifierSchemeCodeUnspsc190501.50413315': __('identifierSchemeCodeUnspsc190501.50413315'),
	'identifierSchemeCodeUnspsc190501.50413316': __('identifierSchemeCodeUnspsc190501.50413316'),
	'identifierSchemeCodeUnspsc190501.50413317': __('identifierSchemeCodeUnspsc190501.50413317'),
	'identifierSchemeCodeUnspsc190501.50413318': __('identifierSchemeCodeUnspsc190501.50413318'),
	'identifierSchemeCodeUnspsc190501.50413319': __('identifierSchemeCodeUnspsc190501.50413319'),
	'identifierSchemeCodeUnspsc190501.50413320': __('identifierSchemeCodeUnspsc190501.50413320'),
	'identifierSchemeCodeUnspsc190501.50413321': __('identifierSchemeCodeUnspsc190501.50413321'),
	'identifierSchemeCodeUnspsc190501.50413322': __('identifierSchemeCodeUnspsc190501.50413322'),
	'identifierSchemeCodeUnspsc190501.50413323': __('identifierSchemeCodeUnspsc190501.50413323'),
	'identifierSchemeCodeUnspsc190501.50413400': __('identifierSchemeCodeUnspsc190501.50413400'),
	'identifierSchemeCodeUnspsc190501.50413401': __('identifierSchemeCodeUnspsc190501.50413401'),
	'identifierSchemeCodeUnspsc190501.50413402': __('identifierSchemeCodeUnspsc190501.50413402'),
	'identifierSchemeCodeUnspsc190501.50413403': __('identifierSchemeCodeUnspsc190501.50413403'),
	'identifierSchemeCodeUnspsc190501.50413404': __('identifierSchemeCodeUnspsc190501.50413404'),
	'identifierSchemeCodeUnspsc190501.50413405': __('identifierSchemeCodeUnspsc190501.50413405'),
	'identifierSchemeCodeUnspsc190501.50413406': __('identifierSchemeCodeUnspsc190501.50413406'),
	'identifierSchemeCodeUnspsc190501.50413407': __('identifierSchemeCodeUnspsc190501.50413407'),
	'identifierSchemeCodeUnspsc190501.50413408': __('identifierSchemeCodeUnspsc190501.50413408'),
	'identifierSchemeCodeUnspsc190501.50413409': __('identifierSchemeCodeUnspsc190501.50413409'),
	'identifierSchemeCodeUnspsc190501.50413410': __('identifierSchemeCodeUnspsc190501.50413410'),
	'identifierSchemeCodeUnspsc190501.50413411': __('identifierSchemeCodeUnspsc190501.50413411'),
	'identifierSchemeCodeUnspsc190501.50413412': __('identifierSchemeCodeUnspsc190501.50413412'),
	'identifierSchemeCodeUnspsc190501.50413413': __('identifierSchemeCodeUnspsc190501.50413413'),
	'identifierSchemeCodeUnspsc190501.50413414': __('identifierSchemeCodeUnspsc190501.50413414'),
	'identifierSchemeCodeUnspsc190501.50413415': __('identifierSchemeCodeUnspsc190501.50413415'),
	'identifierSchemeCodeUnspsc190501.50413416': __('identifierSchemeCodeUnspsc190501.50413416'),
	'identifierSchemeCodeUnspsc190501.50413417': __('identifierSchemeCodeUnspsc190501.50413417'),
	'identifierSchemeCodeUnspsc190501.50413418': __('identifierSchemeCodeUnspsc190501.50413418'),
	'identifierSchemeCodeUnspsc190501.50413419': __('identifierSchemeCodeUnspsc190501.50413419'),
	'identifierSchemeCodeUnspsc190501.50413420': __('identifierSchemeCodeUnspsc190501.50413420'),
	'identifierSchemeCodeUnspsc190501.50413500': __('identifierSchemeCodeUnspsc190501.50413500'),
	'identifierSchemeCodeUnspsc190501.50413501': __('identifierSchemeCodeUnspsc190501.50413501'),
	'identifierSchemeCodeUnspsc190501.50413502': __('identifierSchemeCodeUnspsc190501.50413502'),
	'identifierSchemeCodeUnspsc190501.50413503': __('identifierSchemeCodeUnspsc190501.50413503'),
	'identifierSchemeCodeUnspsc190501.50413504': __('identifierSchemeCodeUnspsc190501.50413504'),
	'identifierSchemeCodeUnspsc190501.50413505': __('identifierSchemeCodeUnspsc190501.50413505'),
	'identifierSchemeCodeUnspsc190501.50413506': __('identifierSchemeCodeUnspsc190501.50413506'),
	'identifierSchemeCodeUnspsc190501.50413507': __('identifierSchemeCodeUnspsc190501.50413507'),
	'identifierSchemeCodeUnspsc190501.50413508': __('identifierSchemeCodeUnspsc190501.50413508'),
	'identifierSchemeCodeUnspsc190501.50413509': __('identifierSchemeCodeUnspsc190501.50413509'),
	'identifierSchemeCodeUnspsc190501.50413510': __('identifierSchemeCodeUnspsc190501.50413510'),
	'identifierSchemeCodeUnspsc190501.50413511': __('identifierSchemeCodeUnspsc190501.50413511'),
	'identifierSchemeCodeUnspsc190501.50413512': __('identifierSchemeCodeUnspsc190501.50413512'),
	'identifierSchemeCodeUnspsc190501.50413513': __('identifierSchemeCodeUnspsc190501.50413513'),
	'identifierSchemeCodeUnspsc190501.50413514': __('identifierSchemeCodeUnspsc190501.50413514'),
	'identifierSchemeCodeUnspsc190501.50413600': __('identifierSchemeCodeUnspsc190501.50413600'),
	'identifierSchemeCodeUnspsc190501.50413601': __('identifierSchemeCodeUnspsc190501.50413601'),
	'identifierSchemeCodeUnspsc190501.50413602': __('identifierSchemeCodeUnspsc190501.50413602'),
	'identifierSchemeCodeUnspsc190501.50413603': __('identifierSchemeCodeUnspsc190501.50413603'),
	'identifierSchemeCodeUnspsc190501.50413604': __('identifierSchemeCodeUnspsc190501.50413604'),
	'identifierSchemeCodeUnspsc190501.50413605': __('identifierSchemeCodeUnspsc190501.50413605'),
	'identifierSchemeCodeUnspsc190501.50413606': __('identifierSchemeCodeUnspsc190501.50413606'),
	'identifierSchemeCodeUnspsc190501.50413607': __('identifierSchemeCodeUnspsc190501.50413607'),
	'identifierSchemeCodeUnspsc190501.50413700': __('identifierSchemeCodeUnspsc190501.50413700'),
	'identifierSchemeCodeUnspsc190501.50413701': __('identifierSchemeCodeUnspsc190501.50413701'),
	'identifierSchemeCodeUnspsc190501.50413702': __('identifierSchemeCodeUnspsc190501.50413702'),
	'identifierSchemeCodeUnspsc190501.50413703': __('identifierSchemeCodeUnspsc190501.50413703'),
	'identifierSchemeCodeUnspsc190501.50413704': __('identifierSchemeCodeUnspsc190501.50413704'),
	'identifierSchemeCodeUnspsc190501.50413705': __('identifierSchemeCodeUnspsc190501.50413705'),
	'identifierSchemeCodeUnspsc190501.50413706': __('identifierSchemeCodeUnspsc190501.50413706'),
	'identifierSchemeCodeUnspsc190501.50413707': __('identifierSchemeCodeUnspsc190501.50413707'),
	'identifierSchemeCodeUnspsc190501.50413708': __('identifierSchemeCodeUnspsc190501.50413708'),
	'identifierSchemeCodeUnspsc190501.50413709': __('identifierSchemeCodeUnspsc190501.50413709'),
	'identifierSchemeCodeUnspsc190501.50413710': __('identifierSchemeCodeUnspsc190501.50413710'),
	'identifierSchemeCodeUnspsc190501.50413711': __('identifierSchemeCodeUnspsc190501.50413711'),
	'identifierSchemeCodeUnspsc190501.50413800': __('identifierSchemeCodeUnspsc190501.50413800'),
	'identifierSchemeCodeUnspsc190501.50413801': __('identifierSchemeCodeUnspsc190501.50413801'),
	'identifierSchemeCodeUnspsc190501.50413802': __('identifierSchemeCodeUnspsc190501.50413802'),
	'identifierSchemeCodeUnspsc190501.50413803': __('identifierSchemeCodeUnspsc190501.50413803'),
	'identifierSchemeCodeUnspsc190501.50413804': __('identifierSchemeCodeUnspsc190501.50413804'),
	'identifierSchemeCodeUnspsc190501.50413805': __('identifierSchemeCodeUnspsc190501.50413805'),
	'identifierSchemeCodeUnspsc190501.50413806': __('identifierSchemeCodeUnspsc190501.50413806'),
	'identifierSchemeCodeUnspsc190501.50413807': __('identifierSchemeCodeUnspsc190501.50413807'),
	'identifierSchemeCodeUnspsc190501.50413808': __('identifierSchemeCodeUnspsc190501.50413808'),
	'identifierSchemeCodeUnspsc190501.50413809': __('identifierSchemeCodeUnspsc190501.50413809'),
	'identifierSchemeCodeUnspsc190501.50413810': __('identifierSchemeCodeUnspsc190501.50413810'),
	'identifierSchemeCodeUnspsc190501.50413811': __('identifierSchemeCodeUnspsc190501.50413811'),
	'identifierSchemeCodeUnspsc190501.50413900': __('identifierSchemeCodeUnspsc190501.50413900'),
	'identifierSchemeCodeUnspsc190501.50413901': __('identifierSchemeCodeUnspsc190501.50413901'),
	'identifierSchemeCodeUnspsc190501.50413902': __('identifierSchemeCodeUnspsc190501.50413902'),
	'identifierSchemeCodeUnspsc190501.50413903': __('identifierSchemeCodeUnspsc190501.50413903'),
	'identifierSchemeCodeUnspsc190501.50413904': __('identifierSchemeCodeUnspsc190501.50413904'),
	'identifierSchemeCodeUnspsc190501.50413906': __('identifierSchemeCodeUnspsc190501.50413906'),
	'identifierSchemeCodeUnspsc190501.50414000': __('identifierSchemeCodeUnspsc190501.50414000'),
	'identifierSchemeCodeUnspsc190501.50414001': __('identifierSchemeCodeUnspsc190501.50414001'),
	'identifierSchemeCodeUnspsc190501.50414002': __('identifierSchemeCodeUnspsc190501.50414002'),
	'identifierSchemeCodeUnspsc190501.50414003': __('identifierSchemeCodeUnspsc190501.50414003'),
	'identifierSchemeCodeUnspsc190501.50414004': __('identifierSchemeCodeUnspsc190501.50414004'),
	'identifierSchemeCodeUnspsc190501.50414005': __('identifierSchemeCodeUnspsc190501.50414005'),
	'identifierSchemeCodeUnspsc190501.50414006': __('identifierSchemeCodeUnspsc190501.50414006'),
	'identifierSchemeCodeUnspsc190501.50414007': __('identifierSchemeCodeUnspsc190501.50414007'),
	'identifierSchemeCodeUnspsc190501.50414008': __('identifierSchemeCodeUnspsc190501.50414008'),
	'identifierSchemeCodeUnspsc190501.50414009': __('identifierSchemeCodeUnspsc190501.50414009'),
	'identifierSchemeCodeUnspsc190501.50414010': __('identifierSchemeCodeUnspsc190501.50414010'),
	'identifierSchemeCodeUnspsc190501.50414011': __('identifierSchemeCodeUnspsc190501.50414011'),
	'identifierSchemeCodeUnspsc190501.50414012': __('identifierSchemeCodeUnspsc190501.50414012'),
	'identifierSchemeCodeUnspsc190501.50414013': __('identifierSchemeCodeUnspsc190501.50414013'),
	'identifierSchemeCodeUnspsc190501.50414014': __('identifierSchemeCodeUnspsc190501.50414014'),
	'identifierSchemeCodeUnspsc190501.50414015': __('identifierSchemeCodeUnspsc190501.50414015'),
	'identifierSchemeCodeUnspsc190501.50414016': __('identifierSchemeCodeUnspsc190501.50414016'),
	'identifierSchemeCodeUnspsc190501.50414017': __('identifierSchemeCodeUnspsc190501.50414017'),
	'identifierSchemeCodeUnspsc190501.50414018': __('identifierSchemeCodeUnspsc190501.50414018'),
	'identifierSchemeCodeUnspsc190501.50414019': __('identifierSchemeCodeUnspsc190501.50414019'),
	'identifierSchemeCodeUnspsc190501.50414020': __('identifierSchemeCodeUnspsc190501.50414020'),
	'identifierSchemeCodeUnspsc190501.50414021': __('identifierSchemeCodeUnspsc190501.50414021'),
	'identifierSchemeCodeUnspsc190501.50414022': __('identifierSchemeCodeUnspsc190501.50414022'),
	'identifierSchemeCodeUnspsc190501.50414023': __('identifierSchemeCodeUnspsc190501.50414023'),
	'identifierSchemeCodeUnspsc190501.50414024': __('identifierSchemeCodeUnspsc190501.50414024'),
	'identifierSchemeCodeUnspsc190501.50414025': __('identifierSchemeCodeUnspsc190501.50414025'),
	'identifierSchemeCodeUnspsc190501.50414026': __('identifierSchemeCodeUnspsc190501.50414026'),
	'identifierSchemeCodeUnspsc190501.50414027': __('identifierSchemeCodeUnspsc190501.50414027'),
	'identifierSchemeCodeUnspsc190501.50414100': __('identifierSchemeCodeUnspsc190501.50414100'),
	'identifierSchemeCodeUnspsc190501.50414101': __('identifierSchemeCodeUnspsc190501.50414101'),
	'identifierSchemeCodeUnspsc190501.50414102': __('identifierSchemeCodeUnspsc190501.50414102'),
	'identifierSchemeCodeUnspsc190501.50414200': __('identifierSchemeCodeUnspsc190501.50414200'),
	'identifierSchemeCodeUnspsc190501.50414201': __('identifierSchemeCodeUnspsc190501.50414201'),
	'identifierSchemeCodeUnspsc190501.50414202': __('identifierSchemeCodeUnspsc190501.50414202'),
	'identifierSchemeCodeUnspsc190501.50414203': __('identifierSchemeCodeUnspsc190501.50414203'),
	'identifierSchemeCodeUnspsc190501.50414204': __('identifierSchemeCodeUnspsc190501.50414204'),
	'identifierSchemeCodeUnspsc190501.50414205': __('identifierSchemeCodeUnspsc190501.50414205'),
	'identifierSchemeCodeUnspsc190501.50414206': __('identifierSchemeCodeUnspsc190501.50414206'),
	'identifierSchemeCodeUnspsc190501.50414300': __('identifierSchemeCodeUnspsc190501.50414300'),
	'identifierSchemeCodeUnspsc190501.50414301': __('identifierSchemeCodeUnspsc190501.50414301'),
	'identifierSchemeCodeUnspsc190501.50414302': __('identifierSchemeCodeUnspsc190501.50414302'),
	'identifierSchemeCodeUnspsc190501.50414303': __('identifierSchemeCodeUnspsc190501.50414303'),
	'identifierSchemeCodeUnspsc190501.50414304': __('identifierSchemeCodeUnspsc190501.50414304'),
	'identifierSchemeCodeUnspsc190501.50414305': __('identifierSchemeCodeUnspsc190501.50414305'),
	'identifierSchemeCodeUnspsc190501.50414306': __('identifierSchemeCodeUnspsc190501.50414306'),
	'identifierSchemeCodeUnspsc190501.50414400': __('identifierSchemeCodeUnspsc190501.50414400'),
	'identifierSchemeCodeUnspsc190501.50414401': __('identifierSchemeCodeUnspsc190501.50414401'),
	'identifierSchemeCodeUnspsc190501.50414402': __('identifierSchemeCodeUnspsc190501.50414402'),
	'identifierSchemeCodeUnspsc190501.50414403': __('identifierSchemeCodeUnspsc190501.50414403'),
	'identifierSchemeCodeUnspsc190501.50414404': __('identifierSchemeCodeUnspsc190501.50414404'),
	'identifierSchemeCodeUnspsc190501.50414405': __('identifierSchemeCodeUnspsc190501.50414405'),
	'identifierSchemeCodeUnspsc190501.50414406': __('identifierSchemeCodeUnspsc190501.50414406'),
	'identifierSchemeCodeUnspsc190501.50414407': __('identifierSchemeCodeUnspsc190501.50414407'),
	'identifierSchemeCodeUnspsc190501.50414408': __('identifierSchemeCodeUnspsc190501.50414408'),
	'identifierSchemeCodeUnspsc190501.50414500': __('identifierSchemeCodeUnspsc190501.50414500'),
	'identifierSchemeCodeUnspsc190501.50414501': __('identifierSchemeCodeUnspsc190501.50414501'),
	'identifierSchemeCodeUnspsc190501.50414502': __('identifierSchemeCodeUnspsc190501.50414502'),
	'identifierSchemeCodeUnspsc190501.50414503': __('identifierSchemeCodeUnspsc190501.50414503'),
	'identifierSchemeCodeUnspsc190501.50414504': __('identifierSchemeCodeUnspsc190501.50414504'),
	'identifierSchemeCodeUnspsc190501.50414505': __('identifierSchemeCodeUnspsc190501.50414505'),
	'identifierSchemeCodeUnspsc190501.50414506': __('identifierSchemeCodeUnspsc190501.50414506'),
	'identifierSchemeCodeUnspsc190501.50414507': __('identifierSchemeCodeUnspsc190501.50414507'),
	'identifierSchemeCodeUnspsc190501.50414508': __('identifierSchemeCodeUnspsc190501.50414508'),
	'identifierSchemeCodeUnspsc190501.50414509': __('identifierSchemeCodeUnspsc190501.50414509'),
	'identifierSchemeCodeUnspsc190501.50414510': __('identifierSchemeCodeUnspsc190501.50414510'),
	'identifierSchemeCodeUnspsc190501.50414511': __('identifierSchemeCodeUnspsc190501.50414511'),
	'identifierSchemeCodeUnspsc190501.50414512': __('identifierSchemeCodeUnspsc190501.50414512'),
	'identifierSchemeCodeUnspsc190501.50414513': __('identifierSchemeCodeUnspsc190501.50414513'),
	'identifierSchemeCodeUnspsc190501.50414514': __('identifierSchemeCodeUnspsc190501.50414514'),
	'identifierSchemeCodeUnspsc190501.50414515': __('identifierSchemeCodeUnspsc190501.50414515'),
	'identifierSchemeCodeUnspsc190501.50414516': __('identifierSchemeCodeUnspsc190501.50414516'),
	'identifierSchemeCodeUnspsc190501.50414517': __('identifierSchemeCodeUnspsc190501.50414517'),
	'identifierSchemeCodeUnspsc190501.50414518': __('identifierSchemeCodeUnspsc190501.50414518'),
	'identifierSchemeCodeUnspsc190501.50414519': __('identifierSchemeCodeUnspsc190501.50414519'),
	'identifierSchemeCodeUnspsc190501.50414520': __('identifierSchemeCodeUnspsc190501.50414520'),
	'identifierSchemeCodeUnspsc190501.50414521': __('identifierSchemeCodeUnspsc190501.50414521'),
	'identifierSchemeCodeUnspsc190501.50414522': __('identifierSchemeCodeUnspsc190501.50414522'),
	'identifierSchemeCodeUnspsc190501.50414523': __('identifierSchemeCodeUnspsc190501.50414523'),
	'identifierSchemeCodeUnspsc190501.50414600': __('identifierSchemeCodeUnspsc190501.50414600'),
	'identifierSchemeCodeUnspsc190501.50414601': __('identifierSchemeCodeUnspsc190501.50414601'),
	'identifierSchemeCodeUnspsc190501.50414602': __('identifierSchemeCodeUnspsc190501.50414602'),
	'identifierSchemeCodeUnspsc190501.50414603': __('identifierSchemeCodeUnspsc190501.50414603'),
	'identifierSchemeCodeUnspsc190501.50414604': __('identifierSchemeCodeUnspsc190501.50414604'),
	'identifierSchemeCodeUnspsc190501.50414605': __('identifierSchemeCodeUnspsc190501.50414605'),
	'identifierSchemeCodeUnspsc190501.50414606': __('identifierSchemeCodeUnspsc190501.50414606'),
	'identifierSchemeCodeUnspsc190501.50414700': __('identifierSchemeCodeUnspsc190501.50414700'),
	'identifierSchemeCodeUnspsc190501.50414701': __('identifierSchemeCodeUnspsc190501.50414701'),
	'identifierSchemeCodeUnspsc190501.50414702': __('identifierSchemeCodeUnspsc190501.50414702'),
	'identifierSchemeCodeUnspsc190501.50414703': __('identifierSchemeCodeUnspsc190501.50414703'),
	'identifierSchemeCodeUnspsc190501.50414704': __('identifierSchemeCodeUnspsc190501.50414704'),
	'identifierSchemeCodeUnspsc190501.50414705': __('identifierSchemeCodeUnspsc190501.50414705'),
	'identifierSchemeCodeUnspsc190501.50414706': __('identifierSchemeCodeUnspsc190501.50414706'),
	'identifierSchemeCodeUnspsc190501.50414707': __('identifierSchemeCodeUnspsc190501.50414707'),
	'identifierSchemeCodeUnspsc190501.50414708': __('identifierSchemeCodeUnspsc190501.50414708'),
	'identifierSchemeCodeUnspsc190501.50414709': __('identifierSchemeCodeUnspsc190501.50414709'),
	'identifierSchemeCodeUnspsc190501.50414710': __('identifierSchemeCodeUnspsc190501.50414710'),
	'identifierSchemeCodeUnspsc190501.50414711': __('identifierSchemeCodeUnspsc190501.50414711'),
	'identifierSchemeCodeUnspsc190501.50414712': __('identifierSchemeCodeUnspsc190501.50414712'),
	'identifierSchemeCodeUnspsc190501.50414713': __('identifierSchemeCodeUnspsc190501.50414713'),
	'identifierSchemeCodeUnspsc190501.50414714': __('identifierSchemeCodeUnspsc190501.50414714'),
	'identifierSchemeCodeUnspsc190501.50414715': __('identifierSchemeCodeUnspsc190501.50414715'),
	'identifierSchemeCodeUnspsc190501.50414716': __('identifierSchemeCodeUnspsc190501.50414716'),
	'identifierSchemeCodeUnspsc190501.50414717': __('identifierSchemeCodeUnspsc190501.50414717'),
	'identifierSchemeCodeUnspsc190501.50414718': __('identifierSchemeCodeUnspsc190501.50414718'),
	'identifierSchemeCodeUnspsc190501.50414719': __('identifierSchemeCodeUnspsc190501.50414719'),
	'identifierSchemeCodeUnspsc190501.50414720': __('identifierSchemeCodeUnspsc190501.50414720'),
	'identifierSchemeCodeUnspsc190501.50414721': __('identifierSchemeCodeUnspsc190501.50414721'),
	'identifierSchemeCodeUnspsc190501.50414800': __('identifierSchemeCodeUnspsc190501.50414800'),
	'identifierSchemeCodeUnspsc190501.50414801': __('identifierSchemeCodeUnspsc190501.50414801'),
	'identifierSchemeCodeUnspsc190501.50414802': __('identifierSchemeCodeUnspsc190501.50414802'),
	'identifierSchemeCodeUnspsc190501.50414803': __('identifierSchemeCodeUnspsc190501.50414803'),
	'identifierSchemeCodeUnspsc190501.50414804': __('identifierSchemeCodeUnspsc190501.50414804'),
	'identifierSchemeCodeUnspsc190501.50414805': __('identifierSchemeCodeUnspsc190501.50414805'),
	'identifierSchemeCodeUnspsc190501.50414806': __('identifierSchemeCodeUnspsc190501.50414806'),
	'identifierSchemeCodeUnspsc190501.50415000': __('identifierSchemeCodeUnspsc190501.50415000'),
	'identifierSchemeCodeUnspsc190501.50415001': __('identifierSchemeCodeUnspsc190501.50415001'),
	'identifierSchemeCodeUnspsc190501.50415002': __('identifierSchemeCodeUnspsc190501.50415002'),
	'identifierSchemeCodeUnspsc190501.50415003': __('identifierSchemeCodeUnspsc190501.50415003'),
	'identifierSchemeCodeUnspsc190501.50415004': __('identifierSchemeCodeUnspsc190501.50415004'),
	'identifierSchemeCodeUnspsc190501.50415100': __('identifierSchemeCodeUnspsc190501.50415100'),
	'identifierSchemeCodeUnspsc190501.50415101': __('identifierSchemeCodeUnspsc190501.50415101'),
	'identifierSchemeCodeUnspsc190501.50415102': __('identifierSchemeCodeUnspsc190501.50415102'),
	'identifierSchemeCodeUnspsc190501.50415103': __('identifierSchemeCodeUnspsc190501.50415103'),
	'identifierSchemeCodeUnspsc190501.50415104': __('identifierSchemeCodeUnspsc190501.50415104'),
	'identifierSchemeCodeUnspsc190501.50415105': __('identifierSchemeCodeUnspsc190501.50415105'),
	'identifierSchemeCodeUnspsc190501.50415106': __('identifierSchemeCodeUnspsc190501.50415106'),
	'identifierSchemeCodeUnspsc190501.50415107': __('identifierSchemeCodeUnspsc190501.50415107'),
	'identifierSchemeCodeUnspsc190501.50415200': __('identifierSchemeCodeUnspsc190501.50415200'),
	'identifierSchemeCodeUnspsc190501.50415201': __('identifierSchemeCodeUnspsc190501.50415201'),
	'identifierSchemeCodeUnspsc190501.50415202': __('identifierSchemeCodeUnspsc190501.50415202'),
	'identifierSchemeCodeUnspsc190501.50415203': __('identifierSchemeCodeUnspsc190501.50415203'),
	'identifierSchemeCodeUnspsc190501.50415204': __('identifierSchemeCodeUnspsc190501.50415204'),
	'identifierSchemeCodeUnspsc190501.50415205': __('identifierSchemeCodeUnspsc190501.50415205'),
	'identifierSchemeCodeUnspsc190501.50415206': __('identifierSchemeCodeUnspsc190501.50415206'),
	'identifierSchemeCodeUnspsc190501.50415207': __('identifierSchemeCodeUnspsc190501.50415207'),
	'identifierSchemeCodeUnspsc190501.50415208': __('identifierSchemeCodeUnspsc190501.50415208'),
	'identifierSchemeCodeUnspsc190501.50415209': __('identifierSchemeCodeUnspsc190501.50415209'),
	'identifierSchemeCodeUnspsc190501.50415210': __('identifierSchemeCodeUnspsc190501.50415210'),
	'identifierSchemeCodeUnspsc190501.50415211': __('identifierSchemeCodeUnspsc190501.50415211'),
	'identifierSchemeCodeUnspsc190501.50415212': __('identifierSchemeCodeUnspsc190501.50415212'),
	'identifierSchemeCodeUnspsc190501.50415213': __('identifierSchemeCodeUnspsc190501.50415213'),
	'identifierSchemeCodeUnspsc190501.50415214': __('identifierSchemeCodeUnspsc190501.50415214'),
	'identifierSchemeCodeUnspsc190501.50415215': __('identifierSchemeCodeUnspsc190501.50415215'),
	'identifierSchemeCodeUnspsc190501.50415216': __('identifierSchemeCodeUnspsc190501.50415216'),
	'identifierSchemeCodeUnspsc190501.50415217': __('identifierSchemeCodeUnspsc190501.50415217'),
	'identifierSchemeCodeUnspsc190501.50415218': __('identifierSchemeCodeUnspsc190501.50415218'),
	'identifierSchemeCodeUnspsc190501.50415219': __('identifierSchemeCodeUnspsc190501.50415219'),
	'identifierSchemeCodeUnspsc190501.50415220': __('identifierSchemeCodeUnspsc190501.50415220'),
	'identifierSchemeCodeUnspsc190501.50415221': __('identifierSchemeCodeUnspsc190501.50415221'),
	'identifierSchemeCodeUnspsc190501.50415300': __('identifierSchemeCodeUnspsc190501.50415300'),
	'identifierSchemeCodeUnspsc190501.50415301': __('identifierSchemeCodeUnspsc190501.50415301'),
	'identifierSchemeCodeUnspsc190501.50415302': __('identifierSchemeCodeUnspsc190501.50415302'),
	'identifierSchemeCodeUnspsc190501.50415303': __('identifierSchemeCodeUnspsc190501.50415303'),
	'identifierSchemeCodeUnspsc190501.50415304': __('identifierSchemeCodeUnspsc190501.50415304'),
	'identifierSchemeCodeUnspsc190501.50415305': __('identifierSchemeCodeUnspsc190501.50415305'),
	'identifierSchemeCodeUnspsc190501.50415306': __('identifierSchemeCodeUnspsc190501.50415306'),
	'identifierSchemeCodeUnspsc190501.50415400': __('identifierSchemeCodeUnspsc190501.50415400'),
	'identifierSchemeCodeUnspsc190501.50415401': __('identifierSchemeCodeUnspsc190501.50415401'),
	'identifierSchemeCodeUnspsc190501.50415402': __('identifierSchemeCodeUnspsc190501.50415402'),
	'identifierSchemeCodeUnspsc190501.50415403': __('identifierSchemeCodeUnspsc190501.50415403'),
	'identifierSchemeCodeUnspsc190501.50415404': __('identifierSchemeCodeUnspsc190501.50415404'),
	'identifierSchemeCodeUnspsc190501.50415405': __('identifierSchemeCodeUnspsc190501.50415405'),
	'identifierSchemeCodeUnspsc190501.50415406': __('identifierSchemeCodeUnspsc190501.50415406'),
	'identifierSchemeCodeUnspsc190501.50415500': __('identifierSchemeCodeUnspsc190501.50415500'),
	'identifierSchemeCodeUnspsc190501.50415501': __('identifierSchemeCodeUnspsc190501.50415501'),
	'identifierSchemeCodeUnspsc190501.50415502': __('identifierSchemeCodeUnspsc190501.50415502'),
	'identifierSchemeCodeUnspsc190501.50415503': __('identifierSchemeCodeUnspsc190501.50415503'),
	'identifierSchemeCodeUnspsc190501.50415504': __('identifierSchemeCodeUnspsc190501.50415504'),
	'identifierSchemeCodeUnspsc190501.50415505': __('identifierSchemeCodeUnspsc190501.50415505'),
	'identifierSchemeCodeUnspsc190501.50415506': __('identifierSchemeCodeUnspsc190501.50415506'),
	'identifierSchemeCodeUnspsc190501.50415507': __('identifierSchemeCodeUnspsc190501.50415507'),
	'identifierSchemeCodeUnspsc190501.50415508': __('identifierSchemeCodeUnspsc190501.50415508'),
	'identifierSchemeCodeUnspsc190501.50415509': __('identifierSchemeCodeUnspsc190501.50415509'),
	'identifierSchemeCodeUnspsc190501.50415510': __('identifierSchemeCodeUnspsc190501.50415510'),
	'identifierSchemeCodeUnspsc190501.50415511': __('identifierSchemeCodeUnspsc190501.50415511'),
	'identifierSchemeCodeUnspsc190501.50415512': __('identifierSchemeCodeUnspsc190501.50415512'),
	'identifierSchemeCodeUnspsc190501.50415513': __('identifierSchemeCodeUnspsc190501.50415513'),
	'identifierSchemeCodeUnspsc190501.50415514': __('identifierSchemeCodeUnspsc190501.50415514'),
	'identifierSchemeCodeUnspsc190501.50415515': __('identifierSchemeCodeUnspsc190501.50415515'),
	'identifierSchemeCodeUnspsc190501.50415516': __('identifierSchemeCodeUnspsc190501.50415516'),
	'identifierSchemeCodeUnspsc190501.50415517': __('identifierSchemeCodeUnspsc190501.50415517'),
	'identifierSchemeCodeUnspsc190501.50415518': __('identifierSchemeCodeUnspsc190501.50415518'),
	'identifierSchemeCodeUnspsc190501.50415519': __('identifierSchemeCodeUnspsc190501.50415519'),
	'identifierSchemeCodeUnspsc190501.50415520': __('identifierSchemeCodeUnspsc190501.50415520'),
	'identifierSchemeCodeUnspsc190501.50415521': __('identifierSchemeCodeUnspsc190501.50415521'),
	'identifierSchemeCodeUnspsc190501.50415522': __('identifierSchemeCodeUnspsc190501.50415522'),
	'identifierSchemeCodeUnspsc190501.50415523': __('identifierSchemeCodeUnspsc190501.50415523'),
	'identifierSchemeCodeUnspsc190501.50415524': __('identifierSchemeCodeUnspsc190501.50415524'),
	'identifierSchemeCodeUnspsc190501.50415525': __('identifierSchemeCodeUnspsc190501.50415525'),
	'identifierSchemeCodeUnspsc190501.50415526': __('identifierSchemeCodeUnspsc190501.50415526'),
	'identifierSchemeCodeUnspsc190501.50415527': __('identifierSchemeCodeUnspsc190501.50415527'),
	'identifierSchemeCodeUnspsc190501.50415528': __('identifierSchemeCodeUnspsc190501.50415528'),
	'identifierSchemeCodeUnspsc190501.50415529': __('identifierSchemeCodeUnspsc190501.50415529'),
	'identifierSchemeCodeUnspsc190501.50415530': __('identifierSchemeCodeUnspsc190501.50415530'),
	'identifierSchemeCodeUnspsc190501.50415531': __('identifierSchemeCodeUnspsc190501.50415531'),
	'identifierSchemeCodeUnspsc190501.50415532': __('identifierSchemeCodeUnspsc190501.50415532'),
	'identifierSchemeCodeUnspsc190501.50415533': __('identifierSchemeCodeUnspsc190501.50415533'),
	'identifierSchemeCodeUnspsc190501.50415534': __('identifierSchemeCodeUnspsc190501.50415534'),
	'identifierSchemeCodeUnspsc190501.50415535': __('identifierSchemeCodeUnspsc190501.50415535'),
	'identifierSchemeCodeUnspsc190501.50415536': __('identifierSchemeCodeUnspsc190501.50415536'),
	'identifierSchemeCodeUnspsc190501.50415537': __('identifierSchemeCodeUnspsc190501.50415537'),
	'identifierSchemeCodeUnspsc190501.50415600': __('identifierSchemeCodeUnspsc190501.50415600'),
	'identifierSchemeCodeUnspsc190501.50415601': __('identifierSchemeCodeUnspsc190501.50415601'),
	'identifierSchemeCodeUnspsc190501.50415602': __('identifierSchemeCodeUnspsc190501.50415602'),
	'identifierSchemeCodeUnspsc190501.50415603': __('identifierSchemeCodeUnspsc190501.50415603'),
	'identifierSchemeCodeUnspsc190501.50415604': __('identifierSchemeCodeUnspsc190501.50415604'),
	'identifierSchemeCodeUnspsc190501.50415605': __('identifierSchemeCodeUnspsc190501.50415605'),
	'identifierSchemeCodeUnspsc190501.50415606': __('identifierSchemeCodeUnspsc190501.50415606'),
	'identifierSchemeCodeUnspsc190501.50415607': __('identifierSchemeCodeUnspsc190501.50415607'),
	'identifierSchemeCodeUnspsc190501.50415608': __('identifierSchemeCodeUnspsc190501.50415608'),
	'identifierSchemeCodeUnspsc190501.50415700': __('identifierSchemeCodeUnspsc190501.50415700'),
	'identifierSchemeCodeUnspsc190501.50415701': __('identifierSchemeCodeUnspsc190501.50415701'),
	'identifierSchemeCodeUnspsc190501.50415702': __('identifierSchemeCodeUnspsc190501.50415702'),
	'identifierSchemeCodeUnspsc190501.50415703': __('identifierSchemeCodeUnspsc190501.50415703'),
	'identifierSchemeCodeUnspsc190501.50415704': __('identifierSchemeCodeUnspsc190501.50415704'),
	'identifierSchemeCodeUnspsc190501.50415800': __('identifierSchemeCodeUnspsc190501.50415800'),
	'identifierSchemeCodeUnspsc190501.50415801': __('identifierSchemeCodeUnspsc190501.50415801'),
	'identifierSchemeCodeUnspsc190501.50415802': __('identifierSchemeCodeUnspsc190501.50415802'),
	'identifierSchemeCodeUnspsc190501.50415803': __('identifierSchemeCodeUnspsc190501.50415803'),
	'identifierSchemeCodeUnspsc190501.50415804': __('identifierSchemeCodeUnspsc190501.50415804'),
	'identifierSchemeCodeUnspsc190501.50415805': __('identifierSchemeCodeUnspsc190501.50415805'),
	'identifierSchemeCodeUnspsc190501.50415806': __('identifierSchemeCodeUnspsc190501.50415806'),
	'identifierSchemeCodeUnspsc190501.50415807': __('identifierSchemeCodeUnspsc190501.50415807'),
	'identifierSchemeCodeUnspsc190501.50415808': __('identifierSchemeCodeUnspsc190501.50415808'),
	'identifierSchemeCodeUnspsc190501.50415809': __('identifierSchemeCodeUnspsc190501.50415809'),
	'identifierSchemeCodeUnspsc190501.50415810': __('identifierSchemeCodeUnspsc190501.50415810'),
	'identifierSchemeCodeUnspsc190501.50415811': __('identifierSchemeCodeUnspsc190501.50415811'),
	'identifierSchemeCodeUnspsc190501.50415812': __('identifierSchemeCodeUnspsc190501.50415812'),
	'identifierSchemeCodeUnspsc190501.50415813': __('identifierSchemeCodeUnspsc190501.50415813'),
	'identifierSchemeCodeUnspsc190501.50415814': __('identifierSchemeCodeUnspsc190501.50415814'),
	'identifierSchemeCodeUnspsc190501.50415815': __('identifierSchemeCodeUnspsc190501.50415815'),
	'identifierSchemeCodeUnspsc190501.50415816': __('identifierSchemeCodeUnspsc190501.50415816'),
	'identifierSchemeCodeUnspsc190501.50415900': __('identifierSchemeCodeUnspsc190501.50415900'),
	'identifierSchemeCodeUnspsc190501.50415901': __('identifierSchemeCodeUnspsc190501.50415901'),
	'identifierSchemeCodeUnspsc190501.50415902': __('identifierSchemeCodeUnspsc190501.50415902'),
	'identifierSchemeCodeUnspsc190501.50415903': __('identifierSchemeCodeUnspsc190501.50415903'),
	'identifierSchemeCodeUnspsc190501.50415904': __('identifierSchemeCodeUnspsc190501.50415904'),
	'identifierSchemeCodeUnspsc190501.50415905': __('identifierSchemeCodeUnspsc190501.50415905'),
	'identifierSchemeCodeUnspsc190501.50415906': __('identifierSchemeCodeUnspsc190501.50415906'),
	'identifierSchemeCodeUnspsc190501.50415907': __('identifierSchemeCodeUnspsc190501.50415907'),
	'identifierSchemeCodeUnspsc190501.50415908': __('identifierSchemeCodeUnspsc190501.50415908'),
	'identifierSchemeCodeUnspsc190501.50415909': __('identifierSchemeCodeUnspsc190501.50415909'),
	'identifierSchemeCodeUnspsc190501.50415910': __('identifierSchemeCodeUnspsc190501.50415910'),
	'identifierSchemeCodeUnspsc190501.50415911': __('identifierSchemeCodeUnspsc190501.50415911'),
	'identifierSchemeCodeUnspsc190501.50416000': __('identifierSchemeCodeUnspsc190501.50416000'),
	'identifierSchemeCodeUnspsc190501.50416001': __('identifierSchemeCodeUnspsc190501.50416001'),
	'identifierSchemeCodeUnspsc190501.50416002': __('identifierSchemeCodeUnspsc190501.50416002'),
	'identifierSchemeCodeUnspsc190501.50416003': __('identifierSchemeCodeUnspsc190501.50416003'),
	'identifierSchemeCodeUnspsc190501.50416004': __('identifierSchemeCodeUnspsc190501.50416004'),
	'identifierSchemeCodeUnspsc190501.50416100': __('identifierSchemeCodeUnspsc190501.50416100'),
	'identifierSchemeCodeUnspsc190501.50416101': __('identifierSchemeCodeUnspsc190501.50416101'),
	'identifierSchemeCodeUnspsc190501.50416102': __('identifierSchemeCodeUnspsc190501.50416102'),
	'identifierSchemeCodeUnspsc190501.50416103': __('identifierSchemeCodeUnspsc190501.50416103'),
	'identifierSchemeCodeUnspsc190501.50416104': __('identifierSchemeCodeUnspsc190501.50416104'),
	'identifierSchemeCodeUnspsc190501.50416105': __('identifierSchemeCodeUnspsc190501.50416105'),
	'identifierSchemeCodeUnspsc190501.50416106': __('identifierSchemeCodeUnspsc190501.50416106'),
	'identifierSchemeCodeUnspsc190501.50416107': __('identifierSchemeCodeUnspsc190501.50416107'),
	'identifierSchemeCodeUnspsc190501.50416108': __('identifierSchemeCodeUnspsc190501.50416108'),
	'identifierSchemeCodeUnspsc190501.50416109': __('identifierSchemeCodeUnspsc190501.50416109'),
	'identifierSchemeCodeUnspsc190501.50416110': __('identifierSchemeCodeUnspsc190501.50416110'),
	'identifierSchemeCodeUnspsc190501.50416111': __('identifierSchemeCodeUnspsc190501.50416111'),
	'identifierSchemeCodeUnspsc190501.50416112': __('identifierSchemeCodeUnspsc190501.50416112'),
	'identifierSchemeCodeUnspsc190501.50416113': __('identifierSchemeCodeUnspsc190501.50416113'),
	'identifierSchemeCodeUnspsc190501.50416114': __('identifierSchemeCodeUnspsc190501.50416114'),
	'identifierSchemeCodeUnspsc190501.50416115': __('identifierSchemeCodeUnspsc190501.50416115'),
	'identifierSchemeCodeUnspsc190501.50416200': __('identifierSchemeCodeUnspsc190501.50416200'),
	'identifierSchemeCodeUnspsc190501.50416201': __('identifierSchemeCodeUnspsc190501.50416201'),
	'identifierSchemeCodeUnspsc190501.50416202': __('identifierSchemeCodeUnspsc190501.50416202'),
	'identifierSchemeCodeUnspsc190501.50416203': __('identifierSchemeCodeUnspsc190501.50416203'),
	'identifierSchemeCodeUnspsc190501.50416204': __('identifierSchemeCodeUnspsc190501.50416204'),
	'identifierSchemeCodeUnspsc190501.50416205': __('identifierSchemeCodeUnspsc190501.50416205'),
	'identifierSchemeCodeUnspsc190501.50416206': __('identifierSchemeCodeUnspsc190501.50416206'),
	'identifierSchemeCodeUnspsc190501.50416207': __('identifierSchemeCodeUnspsc190501.50416207'),
	'identifierSchemeCodeUnspsc190501.50416208': __('identifierSchemeCodeUnspsc190501.50416208'),
	'identifierSchemeCodeUnspsc190501.50416209': __('identifierSchemeCodeUnspsc190501.50416209'),
	'identifierSchemeCodeUnspsc190501.50416210': __('identifierSchemeCodeUnspsc190501.50416210'),
	'identifierSchemeCodeUnspsc190501.50416211': __('identifierSchemeCodeUnspsc190501.50416211'),
	'identifierSchemeCodeUnspsc190501.50416212': __('identifierSchemeCodeUnspsc190501.50416212'),
	'identifierSchemeCodeUnspsc190501.50416213': __('identifierSchemeCodeUnspsc190501.50416213'),
	'identifierSchemeCodeUnspsc190501.50416214': __('identifierSchemeCodeUnspsc190501.50416214'),
	'identifierSchemeCodeUnspsc190501.50416215': __('identifierSchemeCodeUnspsc190501.50416215'),
	'identifierSchemeCodeUnspsc190501.50416216': __('identifierSchemeCodeUnspsc190501.50416216'),
	'identifierSchemeCodeUnspsc190501.50416217': __('identifierSchemeCodeUnspsc190501.50416217'),
	'identifierSchemeCodeUnspsc190501.50416218': __('identifierSchemeCodeUnspsc190501.50416218'),
	'identifierSchemeCodeUnspsc190501.50416219': __('identifierSchemeCodeUnspsc190501.50416219'),
	'identifierSchemeCodeUnspsc190501.50416220': __('identifierSchemeCodeUnspsc190501.50416220'),
	'identifierSchemeCodeUnspsc190501.50416221': __('identifierSchemeCodeUnspsc190501.50416221'),
	'identifierSchemeCodeUnspsc190501.50416222': __('identifierSchemeCodeUnspsc190501.50416222'),
	'identifierSchemeCodeUnspsc190501.50416223': __('identifierSchemeCodeUnspsc190501.50416223'),
	'identifierSchemeCodeUnspsc190501.50416224': __('identifierSchemeCodeUnspsc190501.50416224'),
	'identifierSchemeCodeUnspsc190501.50416225': __('identifierSchemeCodeUnspsc190501.50416225'),
	'identifierSchemeCodeUnspsc190501.50416226': __('identifierSchemeCodeUnspsc190501.50416226'),
	'identifierSchemeCodeUnspsc190501.50416227': __('identifierSchemeCodeUnspsc190501.50416227'),
	'identifierSchemeCodeUnspsc190501.50416228': __('identifierSchemeCodeUnspsc190501.50416228'),
	'identifierSchemeCodeUnspsc190501.50416229': __('identifierSchemeCodeUnspsc190501.50416229'),
	'identifierSchemeCodeUnspsc190501.50416300': __('identifierSchemeCodeUnspsc190501.50416300'),
	'identifierSchemeCodeUnspsc190501.50416301': __('identifierSchemeCodeUnspsc190501.50416301'),
	'identifierSchemeCodeUnspsc190501.50416302': __('identifierSchemeCodeUnspsc190501.50416302'),
	'identifierSchemeCodeUnspsc190501.50416303': __('identifierSchemeCodeUnspsc190501.50416303'),
	'identifierSchemeCodeUnspsc190501.50416304': __('identifierSchemeCodeUnspsc190501.50416304'),
	'identifierSchemeCodeUnspsc190501.50416305': __('identifierSchemeCodeUnspsc190501.50416305'),
	'identifierSchemeCodeUnspsc190501.50416306': __('identifierSchemeCodeUnspsc190501.50416306'),
	'identifierSchemeCodeUnspsc190501.50416307': __('identifierSchemeCodeUnspsc190501.50416307'),
	'identifierSchemeCodeUnspsc190501.50416308': __('identifierSchemeCodeUnspsc190501.50416308'),
	'identifierSchemeCodeUnspsc190501.50416309': __('identifierSchemeCodeUnspsc190501.50416309'),
	'identifierSchemeCodeUnspsc190501.50416310': __('identifierSchemeCodeUnspsc190501.50416310'),
	'identifierSchemeCodeUnspsc190501.50416311': __('identifierSchemeCodeUnspsc190501.50416311'),
	'identifierSchemeCodeUnspsc190501.50416312': __('identifierSchemeCodeUnspsc190501.50416312'),
	'identifierSchemeCodeUnspsc190501.50416313': __('identifierSchemeCodeUnspsc190501.50416313'),
	'identifierSchemeCodeUnspsc190501.50416314': __('identifierSchemeCodeUnspsc190501.50416314'),
	'identifierSchemeCodeUnspsc190501.50416315': __('identifierSchemeCodeUnspsc190501.50416315'),
	'identifierSchemeCodeUnspsc190501.50416316': __('identifierSchemeCodeUnspsc190501.50416316'),
	'identifierSchemeCodeUnspsc190501.50416317': __('identifierSchemeCodeUnspsc190501.50416317'),
	'identifierSchemeCodeUnspsc190501.50416318': __('identifierSchemeCodeUnspsc190501.50416318'),
	'identifierSchemeCodeUnspsc190501.50416319': __('identifierSchemeCodeUnspsc190501.50416319'),
	'identifierSchemeCodeUnspsc190501.50416320': __('identifierSchemeCodeUnspsc190501.50416320'),
	'identifierSchemeCodeUnspsc190501.50416400': __('identifierSchemeCodeUnspsc190501.50416400'),
	'identifierSchemeCodeUnspsc190501.50416401': __('identifierSchemeCodeUnspsc190501.50416401'),
	'identifierSchemeCodeUnspsc190501.50416402': __('identifierSchemeCodeUnspsc190501.50416402'),
	'identifierSchemeCodeUnspsc190501.50416403': __('identifierSchemeCodeUnspsc190501.50416403'),
	'identifierSchemeCodeUnspsc190501.50416404': __('identifierSchemeCodeUnspsc190501.50416404'),
	'identifierSchemeCodeUnspsc190501.50416405': __('identifierSchemeCodeUnspsc190501.50416405'),
	'identifierSchemeCodeUnspsc190501.50416406': __('identifierSchemeCodeUnspsc190501.50416406'),
	'identifierSchemeCodeUnspsc190501.50416407': __('identifierSchemeCodeUnspsc190501.50416407'),
	'identifierSchemeCodeUnspsc190501.50416408': __('identifierSchemeCodeUnspsc190501.50416408'),
	'identifierSchemeCodeUnspsc190501.50416409': __('identifierSchemeCodeUnspsc190501.50416409'),
	'identifierSchemeCodeUnspsc190501.50416410': __('identifierSchemeCodeUnspsc190501.50416410'),
	'identifierSchemeCodeUnspsc190501.50416411': __('identifierSchemeCodeUnspsc190501.50416411'),
	'identifierSchemeCodeUnspsc190501.50416412': __('identifierSchemeCodeUnspsc190501.50416412'),
	'identifierSchemeCodeUnspsc190501.50416413': __('identifierSchemeCodeUnspsc190501.50416413'),
	'identifierSchemeCodeUnspsc190501.50416414': __('identifierSchemeCodeUnspsc190501.50416414'),
	'identifierSchemeCodeUnspsc190501.50416415': __('identifierSchemeCodeUnspsc190501.50416415'),
	'identifierSchemeCodeUnspsc190501.50416416': __('identifierSchemeCodeUnspsc190501.50416416'),
	'identifierSchemeCodeUnspsc190501.50416417': __('identifierSchemeCodeUnspsc190501.50416417'),
	'identifierSchemeCodeUnspsc190501.50416418': __('identifierSchemeCodeUnspsc190501.50416418'),
	'identifierSchemeCodeUnspsc190501.50416419': __('identifierSchemeCodeUnspsc190501.50416419'),
	'identifierSchemeCodeUnspsc190501.50416420': __('identifierSchemeCodeUnspsc190501.50416420'),
	'identifierSchemeCodeUnspsc190501.50416421': __('identifierSchemeCodeUnspsc190501.50416421'),
	'identifierSchemeCodeUnspsc190501.50416422': __('identifierSchemeCodeUnspsc190501.50416422'),
	'identifierSchemeCodeUnspsc190501.50416423': __('identifierSchemeCodeUnspsc190501.50416423'),
	'identifierSchemeCodeUnspsc190501.50416424': __('identifierSchemeCodeUnspsc190501.50416424'),
	'identifierSchemeCodeUnspsc190501.50416425': __('identifierSchemeCodeUnspsc190501.50416425'),
	'identifierSchemeCodeUnspsc190501.50416426': __('identifierSchemeCodeUnspsc190501.50416426'),
	'identifierSchemeCodeUnspsc190501.50416427': __('identifierSchemeCodeUnspsc190501.50416427'),
	'identifierSchemeCodeUnspsc190501.50416500': __('identifierSchemeCodeUnspsc190501.50416500'),
	'identifierSchemeCodeUnspsc190501.50416501': __('identifierSchemeCodeUnspsc190501.50416501'),
	'identifierSchemeCodeUnspsc190501.50416502': __('identifierSchemeCodeUnspsc190501.50416502'),
	'identifierSchemeCodeUnspsc190501.50416503': __('identifierSchemeCodeUnspsc190501.50416503'),
	'identifierSchemeCodeUnspsc190501.50416504': __('identifierSchemeCodeUnspsc190501.50416504'),
	'identifierSchemeCodeUnspsc190501.50416505': __('identifierSchemeCodeUnspsc190501.50416505'),
	'identifierSchemeCodeUnspsc190501.50416506': __('identifierSchemeCodeUnspsc190501.50416506'),
	'identifierSchemeCodeUnspsc190501.50416507': __('identifierSchemeCodeUnspsc190501.50416507'),
	'identifierSchemeCodeUnspsc190501.50416508': __('identifierSchemeCodeUnspsc190501.50416508'),
	'identifierSchemeCodeUnspsc190501.50416600': __('identifierSchemeCodeUnspsc190501.50416600'),
	'identifierSchemeCodeUnspsc190501.50416601': __('identifierSchemeCodeUnspsc190501.50416601'),
	'identifierSchemeCodeUnspsc190501.50416602': __('identifierSchemeCodeUnspsc190501.50416602'),
	'identifierSchemeCodeUnspsc190501.50416603': __('identifierSchemeCodeUnspsc190501.50416603'),
	'identifierSchemeCodeUnspsc190501.50416604': __('identifierSchemeCodeUnspsc190501.50416604'),
	'identifierSchemeCodeUnspsc190501.50416605': __('identifierSchemeCodeUnspsc190501.50416605'),
	'identifierSchemeCodeUnspsc190501.50416606': __('identifierSchemeCodeUnspsc190501.50416606'),
	'identifierSchemeCodeUnspsc190501.50416607': __('identifierSchemeCodeUnspsc190501.50416607'),
	'identifierSchemeCodeUnspsc190501.50416608': __('identifierSchemeCodeUnspsc190501.50416608'),
	'identifierSchemeCodeUnspsc190501.50416609': __('identifierSchemeCodeUnspsc190501.50416609'),
	'identifierSchemeCodeUnspsc190501.50416610': __('identifierSchemeCodeUnspsc190501.50416610'),
	'identifierSchemeCodeUnspsc190501.50416611': __('identifierSchemeCodeUnspsc190501.50416611'),
	'identifierSchemeCodeUnspsc190501.50416612': __('identifierSchemeCodeUnspsc190501.50416612'),
	'identifierSchemeCodeUnspsc190501.50416613': __('identifierSchemeCodeUnspsc190501.50416613'),
	'identifierSchemeCodeUnspsc190501.50416614': __('identifierSchemeCodeUnspsc190501.50416614'),
	'identifierSchemeCodeUnspsc190501.50416615': __('identifierSchemeCodeUnspsc190501.50416615'),
	'identifierSchemeCodeUnspsc190501.50416616': __('identifierSchemeCodeUnspsc190501.50416616'),
	'identifierSchemeCodeUnspsc190501.50416617': __('identifierSchemeCodeUnspsc190501.50416617'),
	'identifierSchemeCodeUnspsc190501.50416618': __('identifierSchemeCodeUnspsc190501.50416618'),
	'identifierSchemeCodeUnspsc190501.50416619': __('identifierSchemeCodeUnspsc190501.50416619'),
	'identifierSchemeCodeUnspsc190501.50416620': __('identifierSchemeCodeUnspsc190501.50416620'),
	'identifierSchemeCodeUnspsc190501.50416700': __('identifierSchemeCodeUnspsc190501.50416700'),
	'identifierSchemeCodeUnspsc190501.50416701': __('identifierSchemeCodeUnspsc190501.50416701'),
	'identifierSchemeCodeUnspsc190501.50416702': __('identifierSchemeCodeUnspsc190501.50416702'),
	'identifierSchemeCodeUnspsc190501.50416703': __('identifierSchemeCodeUnspsc190501.50416703'),
	'identifierSchemeCodeUnspsc190501.50416704': __('identifierSchemeCodeUnspsc190501.50416704'),
	'identifierSchemeCodeUnspsc190501.50416705': __('identifierSchemeCodeUnspsc190501.50416705'),
	'identifierSchemeCodeUnspsc190501.50416706': __('identifierSchemeCodeUnspsc190501.50416706'),
	'identifierSchemeCodeUnspsc190501.50416707': __('identifierSchemeCodeUnspsc190501.50416707'),
	'identifierSchemeCodeUnspsc190501.50416708': __('identifierSchemeCodeUnspsc190501.50416708'),
	'identifierSchemeCodeUnspsc190501.50416709': __('identifierSchemeCodeUnspsc190501.50416709'),
	'identifierSchemeCodeUnspsc190501.50416710': __('identifierSchemeCodeUnspsc190501.50416710'),
	'identifierSchemeCodeUnspsc190501.50416800': __('identifierSchemeCodeUnspsc190501.50416800'),
	'identifierSchemeCodeUnspsc190501.50416801': __('identifierSchemeCodeUnspsc190501.50416801'),
	'identifierSchemeCodeUnspsc190501.50416802': __('identifierSchemeCodeUnspsc190501.50416802'),
	'identifierSchemeCodeUnspsc190501.50416803': __('identifierSchemeCodeUnspsc190501.50416803'),
	'identifierSchemeCodeUnspsc190501.50416804': __('identifierSchemeCodeUnspsc190501.50416804'),
	'identifierSchemeCodeUnspsc190501.50416805': __('identifierSchemeCodeUnspsc190501.50416805'),
	'identifierSchemeCodeUnspsc190501.50416806': __('identifierSchemeCodeUnspsc190501.50416806'),
	'identifierSchemeCodeUnspsc190501.50416807': __('identifierSchemeCodeUnspsc190501.50416807'),
	'identifierSchemeCodeUnspsc190501.50416808': __('identifierSchemeCodeUnspsc190501.50416808'),
	'identifierSchemeCodeUnspsc190501.50416809': __('identifierSchemeCodeUnspsc190501.50416809'),
	'identifierSchemeCodeUnspsc190501.50416810': __('identifierSchemeCodeUnspsc190501.50416810'),
	'identifierSchemeCodeUnspsc190501.50416811': __('identifierSchemeCodeUnspsc190501.50416811'),
	'identifierSchemeCodeUnspsc190501.50416812': __('identifierSchemeCodeUnspsc190501.50416812'),
	'identifierSchemeCodeUnspsc190501.50416813': __('identifierSchemeCodeUnspsc190501.50416813'),
	'identifierSchemeCodeUnspsc190501.50416814': __('identifierSchemeCodeUnspsc190501.50416814'),
	'identifierSchemeCodeUnspsc190501.50416815': __('identifierSchemeCodeUnspsc190501.50416815'),
	'identifierSchemeCodeUnspsc190501.50416816': __('identifierSchemeCodeUnspsc190501.50416816'),
	'identifierSchemeCodeUnspsc190501.50416817': __('identifierSchemeCodeUnspsc190501.50416817'),
	'identifierSchemeCodeUnspsc190501.50416818': __('identifierSchemeCodeUnspsc190501.50416818'),
	'identifierSchemeCodeUnspsc190501.50416819': __('identifierSchemeCodeUnspsc190501.50416819'),
	'identifierSchemeCodeUnspsc190501.50416820': __('identifierSchemeCodeUnspsc190501.50416820'),
	'identifierSchemeCodeUnspsc190501.50416821': __('identifierSchemeCodeUnspsc190501.50416821'),
	'identifierSchemeCodeUnspsc190501.50416822': __('identifierSchemeCodeUnspsc190501.50416822'),
	'identifierSchemeCodeUnspsc190501.50416823': __('identifierSchemeCodeUnspsc190501.50416823'),
	'identifierSchemeCodeUnspsc190501.50416824': __('identifierSchemeCodeUnspsc190501.50416824'),
	'identifierSchemeCodeUnspsc190501.50416825': __('identifierSchemeCodeUnspsc190501.50416825'),
	'identifierSchemeCodeUnspsc190501.50416826': __('identifierSchemeCodeUnspsc190501.50416826'),
	'identifierSchemeCodeUnspsc190501.50416827': __('identifierSchemeCodeUnspsc190501.50416827'),
	'identifierSchemeCodeUnspsc190501.50416828': __('identifierSchemeCodeUnspsc190501.50416828'),
	'identifierSchemeCodeUnspsc190501.50416829': __('identifierSchemeCodeUnspsc190501.50416829'),
	'identifierSchemeCodeUnspsc190501.50416830': __('identifierSchemeCodeUnspsc190501.50416830'),
	'identifierSchemeCodeUnspsc190501.50416831': __('identifierSchemeCodeUnspsc190501.50416831'),
	'identifierSchemeCodeUnspsc190501.50416832': __('identifierSchemeCodeUnspsc190501.50416832'),
	'identifierSchemeCodeUnspsc190501.50416833': __('identifierSchemeCodeUnspsc190501.50416833'),
	'identifierSchemeCodeUnspsc190501.50416834': __('identifierSchemeCodeUnspsc190501.50416834'),
	'identifierSchemeCodeUnspsc190501.50416835': __('identifierSchemeCodeUnspsc190501.50416835'),
	'identifierSchemeCodeUnspsc190501.50416836': __('identifierSchemeCodeUnspsc190501.50416836'),
	'identifierSchemeCodeUnspsc190501.50416837': __('identifierSchemeCodeUnspsc190501.50416837'),
	'identifierSchemeCodeUnspsc190501.50416838': __('identifierSchemeCodeUnspsc190501.50416838'),
	'identifierSchemeCodeUnspsc190501.50416839': __('identifierSchemeCodeUnspsc190501.50416839'),
	'identifierSchemeCodeUnspsc190501.50416840': __('identifierSchemeCodeUnspsc190501.50416840'),
	'identifierSchemeCodeUnspsc190501.50416841': __('identifierSchemeCodeUnspsc190501.50416841'),
	'identifierSchemeCodeUnspsc190501.50416842': __('identifierSchemeCodeUnspsc190501.50416842'),
	'identifierSchemeCodeUnspsc190501.50416843': __('identifierSchemeCodeUnspsc190501.50416843'),
	'identifierSchemeCodeUnspsc190501.50416844': __('identifierSchemeCodeUnspsc190501.50416844'),
	'identifierSchemeCodeUnspsc190501.50416845': __('identifierSchemeCodeUnspsc190501.50416845'),
	'identifierSchemeCodeUnspsc190501.50416846': __('identifierSchemeCodeUnspsc190501.50416846'),
	'identifierSchemeCodeUnspsc190501.50416847': __('identifierSchemeCodeUnspsc190501.50416847'),
	'identifierSchemeCodeUnspsc190501.50416848': __('identifierSchemeCodeUnspsc190501.50416848'),
	'identifierSchemeCodeUnspsc190501.50416849': __('identifierSchemeCodeUnspsc190501.50416849'),
	'identifierSchemeCodeUnspsc190501.50416850': __('identifierSchemeCodeUnspsc190501.50416850'),
	'identifierSchemeCodeUnspsc190501.50416851': __('identifierSchemeCodeUnspsc190501.50416851'),
	'identifierSchemeCodeUnspsc190501.50416852': __('identifierSchemeCodeUnspsc190501.50416852'),
	'identifierSchemeCodeUnspsc190501.50416853': __('identifierSchemeCodeUnspsc190501.50416853'),
	'identifierSchemeCodeUnspsc190501.50416854': __('identifierSchemeCodeUnspsc190501.50416854'),
	'identifierSchemeCodeUnspsc190501.50416855': __('identifierSchemeCodeUnspsc190501.50416855'),
	'identifierSchemeCodeUnspsc190501.50416856': __('identifierSchemeCodeUnspsc190501.50416856'),
	'identifierSchemeCodeUnspsc190501.50416857': __('identifierSchemeCodeUnspsc190501.50416857'),
	'identifierSchemeCodeUnspsc190501.50416858': __('identifierSchemeCodeUnspsc190501.50416858'),
	'identifierSchemeCodeUnspsc190501.50416859': __('identifierSchemeCodeUnspsc190501.50416859'),
	'identifierSchemeCodeUnspsc190501.50416860': __('identifierSchemeCodeUnspsc190501.50416860'),
	'identifierSchemeCodeUnspsc190501.50416861': __('identifierSchemeCodeUnspsc190501.50416861'),
	'identifierSchemeCodeUnspsc190501.50416862': __('identifierSchemeCodeUnspsc190501.50416862'),
	'identifierSchemeCodeUnspsc190501.50416863': __('identifierSchemeCodeUnspsc190501.50416863'),
	'identifierSchemeCodeUnspsc190501.50416864': __('identifierSchemeCodeUnspsc190501.50416864'),
	'identifierSchemeCodeUnspsc190501.50416865': __('identifierSchemeCodeUnspsc190501.50416865'),
	'identifierSchemeCodeUnspsc190501.50416866': __('identifierSchemeCodeUnspsc190501.50416866'),
	'identifierSchemeCodeUnspsc190501.50416867': __('identifierSchemeCodeUnspsc190501.50416867'),
	'identifierSchemeCodeUnspsc190501.50416868': __('identifierSchemeCodeUnspsc190501.50416868'),
	'identifierSchemeCodeUnspsc190501.50416869': __('identifierSchemeCodeUnspsc190501.50416869'),
	'identifierSchemeCodeUnspsc190501.50417000': __('identifierSchemeCodeUnspsc190501.50417000'),
	'identifierSchemeCodeUnspsc190501.50417001': __('identifierSchemeCodeUnspsc190501.50417001'),
	'identifierSchemeCodeUnspsc190501.50417002': __('identifierSchemeCodeUnspsc190501.50417002'),
	'identifierSchemeCodeUnspsc190501.50417003': __('identifierSchemeCodeUnspsc190501.50417003'),
	'identifierSchemeCodeUnspsc190501.50417004': __('identifierSchemeCodeUnspsc190501.50417004'),
	'identifierSchemeCodeUnspsc190501.50417005': __('identifierSchemeCodeUnspsc190501.50417005'),
	'identifierSchemeCodeUnspsc190501.50417006': __('identifierSchemeCodeUnspsc190501.50417006'),
	'identifierSchemeCodeUnspsc190501.50417007': __('identifierSchemeCodeUnspsc190501.50417007'),
	'identifierSchemeCodeUnspsc190501.50417008': __('identifierSchemeCodeUnspsc190501.50417008'),
	'identifierSchemeCodeUnspsc190501.50417009': __('identifierSchemeCodeUnspsc190501.50417009'),
	'identifierSchemeCodeUnspsc190501.50417010': __('identifierSchemeCodeUnspsc190501.50417010'),
	'identifierSchemeCodeUnspsc190501.50417011': __('identifierSchemeCodeUnspsc190501.50417011'),
	'identifierSchemeCodeUnspsc190501.50417012': __('identifierSchemeCodeUnspsc190501.50417012'),
	'identifierSchemeCodeUnspsc190501.50417013': __('identifierSchemeCodeUnspsc190501.50417013'),
	'identifierSchemeCodeUnspsc190501.50417014': __('identifierSchemeCodeUnspsc190501.50417014'),
	'identifierSchemeCodeUnspsc190501.50417015': __('identifierSchemeCodeUnspsc190501.50417015'),
	'identifierSchemeCodeUnspsc190501.50417016': __('identifierSchemeCodeUnspsc190501.50417016'),
	'identifierSchemeCodeUnspsc190501.50417017': __('identifierSchemeCodeUnspsc190501.50417017'),
	'identifierSchemeCodeUnspsc190501.50417018': __('identifierSchemeCodeUnspsc190501.50417018'),
	'identifierSchemeCodeUnspsc190501.50417019': __('identifierSchemeCodeUnspsc190501.50417019'),
	'identifierSchemeCodeUnspsc190501.50417020': __('identifierSchemeCodeUnspsc190501.50417020'),
	'identifierSchemeCodeUnspsc190501.50417021': __('identifierSchemeCodeUnspsc190501.50417021'),
	'identifierSchemeCodeUnspsc190501.50417022': __('identifierSchemeCodeUnspsc190501.50417022'),
	'identifierSchemeCodeUnspsc190501.50417023': __('identifierSchemeCodeUnspsc190501.50417023'),
	'identifierSchemeCodeUnspsc190501.50417024': __('identifierSchemeCodeUnspsc190501.50417024'),
	'identifierSchemeCodeUnspsc190501.50417025': __('identifierSchemeCodeUnspsc190501.50417025'),
	'identifierSchemeCodeUnspsc190501.50417026': __('identifierSchemeCodeUnspsc190501.50417026'),
	'identifierSchemeCodeUnspsc190501.50417027': __('identifierSchemeCodeUnspsc190501.50417027'),
	'identifierSchemeCodeUnspsc190501.50417028': __('identifierSchemeCodeUnspsc190501.50417028'),
	'identifierSchemeCodeUnspsc190501.50417029': __('identifierSchemeCodeUnspsc190501.50417029'),
	'identifierSchemeCodeUnspsc190501.50417030': __('identifierSchemeCodeUnspsc190501.50417030'),
	'identifierSchemeCodeUnspsc190501.50417031': __('identifierSchemeCodeUnspsc190501.50417031'),
	'identifierSchemeCodeUnspsc190501.50417032': __('identifierSchemeCodeUnspsc190501.50417032'),
	'identifierSchemeCodeUnspsc190501.50417033': __('identifierSchemeCodeUnspsc190501.50417033'),
	'identifierSchemeCodeUnspsc190501.50417034': __('identifierSchemeCodeUnspsc190501.50417034'),
	'identifierSchemeCodeUnspsc190501.50417035': __('identifierSchemeCodeUnspsc190501.50417035'),
	'identifierSchemeCodeUnspsc190501.50417036': __('identifierSchemeCodeUnspsc190501.50417036'),
	'identifierSchemeCodeUnspsc190501.50417037': __('identifierSchemeCodeUnspsc190501.50417037'),
	'identifierSchemeCodeUnspsc190501.50417038': __('identifierSchemeCodeUnspsc190501.50417038'),
	'identifierSchemeCodeUnspsc190501.50417039': __('identifierSchemeCodeUnspsc190501.50417039'),
	'identifierSchemeCodeUnspsc190501.50417040': __('identifierSchemeCodeUnspsc190501.50417040'),
	'identifierSchemeCodeUnspsc190501.50417041': __('identifierSchemeCodeUnspsc190501.50417041'),
	'identifierSchemeCodeUnspsc190501.50417042': __('identifierSchemeCodeUnspsc190501.50417042'),
	'identifierSchemeCodeUnspsc190501.50417043': __('identifierSchemeCodeUnspsc190501.50417043'),
	'identifierSchemeCodeUnspsc190501.50417044': __('identifierSchemeCodeUnspsc190501.50417044'),
	'identifierSchemeCodeUnspsc190501.50417045': __('identifierSchemeCodeUnspsc190501.50417045'),
	'identifierSchemeCodeUnspsc190501.50417046': __('identifierSchemeCodeUnspsc190501.50417046'),
	'identifierSchemeCodeUnspsc190501.50417047': __('identifierSchemeCodeUnspsc190501.50417047'),
	'identifierSchemeCodeUnspsc190501.50417048': __('identifierSchemeCodeUnspsc190501.50417048'),
	'identifierSchemeCodeUnspsc190501.50417049': __('identifierSchemeCodeUnspsc190501.50417049'),
	'identifierSchemeCodeUnspsc190501.50417050': __('identifierSchemeCodeUnspsc190501.50417050'),
	'identifierSchemeCodeUnspsc190501.50417051': __('identifierSchemeCodeUnspsc190501.50417051'),
	'identifierSchemeCodeUnspsc190501.50417052': __('identifierSchemeCodeUnspsc190501.50417052'),
	'identifierSchemeCodeUnspsc190501.50417053': __('identifierSchemeCodeUnspsc190501.50417053'),
	'identifierSchemeCodeUnspsc190501.50417054': __('identifierSchemeCodeUnspsc190501.50417054'),
	'identifierSchemeCodeUnspsc190501.50417055': __('identifierSchemeCodeUnspsc190501.50417055'),
	'identifierSchemeCodeUnspsc190501.50417056': __('identifierSchemeCodeUnspsc190501.50417056'),
	'identifierSchemeCodeUnspsc190501.50417057': __('identifierSchemeCodeUnspsc190501.50417057'),
	'identifierSchemeCodeUnspsc190501.50417058': __('identifierSchemeCodeUnspsc190501.50417058'),
	'identifierSchemeCodeUnspsc190501.50417059': __('identifierSchemeCodeUnspsc190501.50417059'),
	'identifierSchemeCodeUnspsc190501.50417060': __('identifierSchemeCodeUnspsc190501.50417060'),
	'identifierSchemeCodeUnspsc190501.50417061': __('identifierSchemeCodeUnspsc190501.50417061'),
	'identifierSchemeCodeUnspsc190501.50417062': __('identifierSchemeCodeUnspsc190501.50417062'),
	'identifierSchemeCodeUnspsc190501.50417063': __('identifierSchemeCodeUnspsc190501.50417063'),
	'identifierSchemeCodeUnspsc190501.50417064': __('identifierSchemeCodeUnspsc190501.50417064'),
	'identifierSchemeCodeUnspsc190501.50417065': __('identifierSchemeCodeUnspsc190501.50417065'),
	'identifierSchemeCodeUnspsc190501.50417066': __('identifierSchemeCodeUnspsc190501.50417066'),
	'identifierSchemeCodeUnspsc190501.50417067': __('identifierSchemeCodeUnspsc190501.50417067'),
	'identifierSchemeCodeUnspsc190501.50417068': __('identifierSchemeCodeUnspsc190501.50417068'),
	'identifierSchemeCodeUnspsc190501.50417069': __('identifierSchemeCodeUnspsc190501.50417069'),
	'identifierSchemeCodeUnspsc190501.50417100': __('identifierSchemeCodeUnspsc190501.50417100'),
	'identifierSchemeCodeUnspsc190501.50417101': __('identifierSchemeCodeUnspsc190501.50417101'),
	'identifierSchemeCodeUnspsc190501.50417102': __('identifierSchemeCodeUnspsc190501.50417102'),
	'identifierSchemeCodeUnspsc190501.50417103': __('identifierSchemeCodeUnspsc190501.50417103'),
	'identifierSchemeCodeUnspsc190501.50417104': __('identifierSchemeCodeUnspsc190501.50417104'),
	'identifierSchemeCodeUnspsc190501.50417105': __('identifierSchemeCodeUnspsc190501.50417105'),
	'identifierSchemeCodeUnspsc190501.50417106': __('identifierSchemeCodeUnspsc190501.50417106'),
	'identifierSchemeCodeUnspsc190501.50417107': __('identifierSchemeCodeUnspsc190501.50417107'),
	'identifierSchemeCodeUnspsc190501.50417108': __('identifierSchemeCodeUnspsc190501.50417108'),
	'identifierSchemeCodeUnspsc190501.50417109': __('identifierSchemeCodeUnspsc190501.50417109'),
	'identifierSchemeCodeUnspsc190501.50417110': __('identifierSchemeCodeUnspsc190501.50417110'),
	'identifierSchemeCodeUnspsc190501.50420000': __('identifierSchemeCodeUnspsc190501.50420000'),
	'identifierSchemeCodeUnspsc190501.50421500': __('identifierSchemeCodeUnspsc190501.50421500'),
	'identifierSchemeCodeUnspsc190501.50421501': __('identifierSchemeCodeUnspsc190501.50421501'),
	'identifierSchemeCodeUnspsc190501.50421502': __('identifierSchemeCodeUnspsc190501.50421502'),
	'identifierSchemeCodeUnspsc190501.50421503': __('identifierSchemeCodeUnspsc190501.50421503'),
	'identifierSchemeCodeUnspsc190501.50421504': __('identifierSchemeCodeUnspsc190501.50421504'),
	'identifierSchemeCodeUnspsc190501.50421505': __('identifierSchemeCodeUnspsc190501.50421505'),
	'identifierSchemeCodeUnspsc190501.50421506': __('identifierSchemeCodeUnspsc190501.50421506'),
	'identifierSchemeCodeUnspsc190501.50421507': __('identifierSchemeCodeUnspsc190501.50421507'),
	'identifierSchemeCodeUnspsc190501.50421508': __('identifierSchemeCodeUnspsc190501.50421508'),
	'identifierSchemeCodeUnspsc190501.50421509': __('identifierSchemeCodeUnspsc190501.50421509'),
	'identifierSchemeCodeUnspsc190501.50421510': __('identifierSchemeCodeUnspsc190501.50421510'),
	'identifierSchemeCodeUnspsc190501.50421511': __('identifierSchemeCodeUnspsc190501.50421511'),
	'identifierSchemeCodeUnspsc190501.50421512': __('identifierSchemeCodeUnspsc190501.50421512'),
	'identifierSchemeCodeUnspsc190501.50421513': __('identifierSchemeCodeUnspsc190501.50421513'),
	'identifierSchemeCodeUnspsc190501.50421600': __('identifierSchemeCodeUnspsc190501.50421600'),
	'identifierSchemeCodeUnspsc190501.50421601': __('identifierSchemeCodeUnspsc190501.50421601'),
	'identifierSchemeCodeUnspsc190501.50421602': __('identifierSchemeCodeUnspsc190501.50421602'),
	'identifierSchemeCodeUnspsc190501.50421603': __('identifierSchemeCodeUnspsc190501.50421603'),
	'identifierSchemeCodeUnspsc190501.50421604': __('identifierSchemeCodeUnspsc190501.50421604'),
	'identifierSchemeCodeUnspsc190501.50421605': __('identifierSchemeCodeUnspsc190501.50421605'),
	'identifierSchemeCodeUnspsc190501.50421700': __('identifierSchemeCodeUnspsc190501.50421700'),
	'identifierSchemeCodeUnspsc190501.50421701': __('identifierSchemeCodeUnspsc190501.50421701'),
	'identifierSchemeCodeUnspsc190501.50421702': __('identifierSchemeCodeUnspsc190501.50421702'),
	'identifierSchemeCodeUnspsc190501.50421703': __('identifierSchemeCodeUnspsc190501.50421703'),
	'identifierSchemeCodeUnspsc190501.50421704': __('identifierSchemeCodeUnspsc190501.50421704'),
	'identifierSchemeCodeUnspsc190501.50421705': __('identifierSchemeCodeUnspsc190501.50421705'),
	'identifierSchemeCodeUnspsc190501.50421706': __('identifierSchemeCodeUnspsc190501.50421706'),
	'identifierSchemeCodeUnspsc190501.50421707': __('identifierSchemeCodeUnspsc190501.50421707'),
	'identifierSchemeCodeUnspsc190501.50421708': __('identifierSchemeCodeUnspsc190501.50421708'),
	'identifierSchemeCodeUnspsc190501.50421709': __('identifierSchemeCodeUnspsc190501.50421709'),
	'identifierSchemeCodeUnspsc190501.50421710': __('identifierSchemeCodeUnspsc190501.50421710'),
	'identifierSchemeCodeUnspsc190501.50421711': __('identifierSchemeCodeUnspsc190501.50421711'),
	'identifierSchemeCodeUnspsc190501.50421712': __('identifierSchemeCodeUnspsc190501.50421712'),
	'identifierSchemeCodeUnspsc190501.50421713': __('identifierSchemeCodeUnspsc190501.50421713'),
	'identifierSchemeCodeUnspsc190501.50421714': __('identifierSchemeCodeUnspsc190501.50421714'),
	'identifierSchemeCodeUnspsc190501.50421715': __('identifierSchemeCodeUnspsc190501.50421715'),
	'identifierSchemeCodeUnspsc190501.50421716': __('identifierSchemeCodeUnspsc190501.50421716'),
	'identifierSchemeCodeUnspsc190501.50421717': __('identifierSchemeCodeUnspsc190501.50421717'),
	'identifierSchemeCodeUnspsc190501.50421718': __('identifierSchemeCodeUnspsc190501.50421718'),
	'identifierSchemeCodeUnspsc190501.50421719': __('identifierSchemeCodeUnspsc190501.50421719'),
	'identifierSchemeCodeUnspsc190501.50421720': __('identifierSchemeCodeUnspsc190501.50421720'),
	'identifierSchemeCodeUnspsc190501.50421721': __('identifierSchemeCodeUnspsc190501.50421721'),
	'identifierSchemeCodeUnspsc190501.50421722': __('identifierSchemeCodeUnspsc190501.50421722'),
	'identifierSchemeCodeUnspsc190501.50421723': __('identifierSchemeCodeUnspsc190501.50421723'),
	'identifierSchemeCodeUnspsc190501.50421724': __('identifierSchemeCodeUnspsc190501.50421724'),
	'identifierSchemeCodeUnspsc190501.50421725': __('identifierSchemeCodeUnspsc190501.50421725'),
	'identifierSchemeCodeUnspsc190501.50421726': __('identifierSchemeCodeUnspsc190501.50421726'),
	'identifierSchemeCodeUnspsc190501.50421727': __('identifierSchemeCodeUnspsc190501.50421727'),
	'identifierSchemeCodeUnspsc190501.50421728': __('identifierSchemeCodeUnspsc190501.50421728'),
	'identifierSchemeCodeUnspsc190501.50421729': __('identifierSchemeCodeUnspsc190501.50421729'),
	'identifierSchemeCodeUnspsc190501.50421730': __('identifierSchemeCodeUnspsc190501.50421730'),
	'identifierSchemeCodeUnspsc190501.50421731': __('identifierSchemeCodeUnspsc190501.50421731'),
	'identifierSchemeCodeUnspsc190501.50421732': __('identifierSchemeCodeUnspsc190501.50421732'),
	'identifierSchemeCodeUnspsc190501.50421733': __('identifierSchemeCodeUnspsc190501.50421733'),
	'identifierSchemeCodeUnspsc190501.50421734': __('identifierSchemeCodeUnspsc190501.50421734'),
	'identifierSchemeCodeUnspsc190501.50421735': __('identifierSchemeCodeUnspsc190501.50421735'),
	'identifierSchemeCodeUnspsc190501.50421736': __('identifierSchemeCodeUnspsc190501.50421736'),
	'identifierSchemeCodeUnspsc190501.50421737': __('identifierSchemeCodeUnspsc190501.50421737'),
	'identifierSchemeCodeUnspsc190501.50421738': __('identifierSchemeCodeUnspsc190501.50421738'),
	'identifierSchemeCodeUnspsc190501.50421739': __('identifierSchemeCodeUnspsc190501.50421739'),
	'identifierSchemeCodeUnspsc190501.50421740': __('identifierSchemeCodeUnspsc190501.50421740'),
	'identifierSchemeCodeUnspsc190501.50421741': __('identifierSchemeCodeUnspsc190501.50421741'),
	'identifierSchemeCodeUnspsc190501.50421742': __('identifierSchemeCodeUnspsc190501.50421742'),
	'identifierSchemeCodeUnspsc190501.50421743': __('identifierSchemeCodeUnspsc190501.50421743'),
	'identifierSchemeCodeUnspsc190501.50421744': __('identifierSchemeCodeUnspsc190501.50421744'),
	'identifierSchemeCodeUnspsc190501.50421745': __('identifierSchemeCodeUnspsc190501.50421745'),
	'identifierSchemeCodeUnspsc190501.50421746': __('identifierSchemeCodeUnspsc190501.50421746'),
	'identifierSchemeCodeUnspsc190501.50421747': __('identifierSchemeCodeUnspsc190501.50421747'),
	'identifierSchemeCodeUnspsc190501.50421748': __('identifierSchemeCodeUnspsc190501.50421748'),
	'identifierSchemeCodeUnspsc190501.50421749': __('identifierSchemeCodeUnspsc190501.50421749'),
	'identifierSchemeCodeUnspsc190501.50421750': __('identifierSchemeCodeUnspsc190501.50421750'),
	'identifierSchemeCodeUnspsc190501.50421751': __('identifierSchemeCodeUnspsc190501.50421751'),
	'identifierSchemeCodeUnspsc190501.50421752': __('identifierSchemeCodeUnspsc190501.50421752'),
	'identifierSchemeCodeUnspsc190501.50421753': __('identifierSchemeCodeUnspsc190501.50421753'),
	'identifierSchemeCodeUnspsc190501.50421754': __('identifierSchemeCodeUnspsc190501.50421754'),
	'identifierSchemeCodeUnspsc190501.50421755': __('identifierSchemeCodeUnspsc190501.50421755'),
	'identifierSchemeCodeUnspsc190501.50421756': __('identifierSchemeCodeUnspsc190501.50421756'),
	'identifierSchemeCodeUnspsc190501.50421757': __('identifierSchemeCodeUnspsc190501.50421757'),
	'identifierSchemeCodeUnspsc190501.50421758': __('identifierSchemeCodeUnspsc190501.50421758'),
	'identifierSchemeCodeUnspsc190501.50421759': __('identifierSchemeCodeUnspsc190501.50421759'),
	'identifierSchemeCodeUnspsc190501.50421760': __('identifierSchemeCodeUnspsc190501.50421760'),
	'identifierSchemeCodeUnspsc190501.50421761': __('identifierSchemeCodeUnspsc190501.50421761'),
	'identifierSchemeCodeUnspsc190501.50421762': __('identifierSchemeCodeUnspsc190501.50421762'),
	'identifierSchemeCodeUnspsc190501.50421763': __('identifierSchemeCodeUnspsc190501.50421763'),
	'identifierSchemeCodeUnspsc190501.50421764': __('identifierSchemeCodeUnspsc190501.50421764'),
	'identifierSchemeCodeUnspsc190501.50421765': __('identifierSchemeCodeUnspsc190501.50421765'),
	'identifierSchemeCodeUnspsc190501.50421766': __('identifierSchemeCodeUnspsc190501.50421766'),
	'identifierSchemeCodeUnspsc190501.50421767': __('identifierSchemeCodeUnspsc190501.50421767'),
	'identifierSchemeCodeUnspsc190501.50421768': __('identifierSchemeCodeUnspsc190501.50421768'),
	'identifierSchemeCodeUnspsc190501.50421769': __('identifierSchemeCodeUnspsc190501.50421769'),
	'identifierSchemeCodeUnspsc190501.50421770': __('identifierSchemeCodeUnspsc190501.50421770'),
	'identifierSchemeCodeUnspsc190501.50421771': __('identifierSchemeCodeUnspsc190501.50421771'),
	'identifierSchemeCodeUnspsc190501.50421772': __('identifierSchemeCodeUnspsc190501.50421772'),
	'identifierSchemeCodeUnspsc190501.50421773': __('identifierSchemeCodeUnspsc190501.50421773'),
	'identifierSchemeCodeUnspsc190501.50421774': __('identifierSchemeCodeUnspsc190501.50421774'),
	'identifierSchemeCodeUnspsc190501.50421775': __('identifierSchemeCodeUnspsc190501.50421775'),
	'identifierSchemeCodeUnspsc190501.50421776': __('identifierSchemeCodeUnspsc190501.50421776'),
	'identifierSchemeCodeUnspsc190501.50421777': __('identifierSchemeCodeUnspsc190501.50421777'),
	'identifierSchemeCodeUnspsc190501.50421778': __('identifierSchemeCodeUnspsc190501.50421778'),
	'identifierSchemeCodeUnspsc190501.50421779': __('identifierSchemeCodeUnspsc190501.50421779'),
	'identifierSchemeCodeUnspsc190501.50421780': __('identifierSchemeCodeUnspsc190501.50421780'),
	'identifierSchemeCodeUnspsc190501.50421781': __('identifierSchemeCodeUnspsc190501.50421781'),
	'identifierSchemeCodeUnspsc190501.50421782': __('identifierSchemeCodeUnspsc190501.50421782'),
	'identifierSchemeCodeUnspsc190501.50421800': __('identifierSchemeCodeUnspsc190501.50421800'),
	'identifierSchemeCodeUnspsc190501.50421801': __('identifierSchemeCodeUnspsc190501.50421801'),
	'identifierSchemeCodeUnspsc190501.50421802': __('identifierSchemeCodeUnspsc190501.50421802'),
	'identifierSchemeCodeUnspsc190501.50421803': __('identifierSchemeCodeUnspsc190501.50421803'),
	'identifierSchemeCodeUnspsc190501.50421804': __('identifierSchemeCodeUnspsc190501.50421804'),
	'identifierSchemeCodeUnspsc190501.50421805': __('identifierSchemeCodeUnspsc190501.50421805'),
	'identifierSchemeCodeUnspsc190501.50421806': __('identifierSchemeCodeUnspsc190501.50421806'),
	'identifierSchemeCodeUnspsc190501.50421807': __('identifierSchemeCodeUnspsc190501.50421807'),
	'identifierSchemeCodeUnspsc190501.50421808': __('identifierSchemeCodeUnspsc190501.50421808'),
	'identifierSchemeCodeUnspsc190501.50421809': __('identifierSchemeCodeUnspsc190501.50421809'),
	'identifierSchemeCodeUnspsc190501.50421810': __('identifierSchemeCodeUnspsc190501.50421810'),
	'identifierSchemeCodeUnspsc190501.50421811': __('identifierSchemeCodeUnspsc190501.50421811'),
	'identifierSchemeCodeUnspsc190501.50421812': __('identifierSchemeCodeUnspsc190501.50421812'),
	'identifierSchemeCodeUnspsc190501.50421813': __('identifierSchemeCodeUnspsc190501.50421813'),
	'identifierSchemeCodeUnspsc190501.50421814': __('identifierSchemeCodeUnspsc190501.50421814'),
	'identifierSchemeCodeUnspsc190501.50421815': __('identifierSchemeCodeUnspsc190501.50421815'),
	'identifierSchemeCodeUnspsc190501.50421816': __('identifierSchemeCodeUnspsc190501.50421816'),
	'identifierSchemeCodeUnspsc190501.50421817': __('identifierSchemeCodeUnspsc190501.50421817'),
	'identifierSchemeCodeUnspsc190501.50421818': __('identifierSchemeCodeUnspsc190501.50421818'),
	'identifierSchemeCodeUnspsc190501.50421819': __('identifierSchemeCodeUnspsc190501.50421819'),
	'identifierSchemeCodeUnspsc190501.50421820': __('identifierSchemeCodeUnspsc190501.50421820'),
	'identifierSchemeCodeUnspsc190501.50421821': __('identifierSchemeCodeUnspsc190501.50421821'),
	'identifierSchemeCodeUnspsc190501.50421822': __('identifierSchemeCodeUnspsc190501.50421822'),
	'identifierSchemeCodeUnspsc190501.50421823': __('identifierSchemeCodeUnspsc190501.50421823'),
	'identifierSchemeCodeUnspsc190501.50421824': __('identifierSchemeCodeUnspsc190501.50421824'),
	'identifierSchemeCodeUnspsc190501.50421825': __('identifierSchemeCodeUnspsc190501.50421825'),
	'identifierSchemeCodeUnspsc190501.50421826': __('identifierSchemeCodeUnspsc190501.50421826'),
	'identifierSchemeCodeUnspsc190501.50421827': __('identifierSchemeCodeUnspsc190501.50421827'),
	'identifierSchemeCodeUnspsc190501.50421828': __('identifierSchemeCodeUnspsc190501.50421828'),
	'identifierSchemeCodeUnspsc190501.50421829': __('identifierSchemeCodeUnspsc190501.50421829'),
	'identifierSchemeCodeUnspsc190501.50421830': __('identifierSchemeCodeUnspsc190501.50421830'),
	'identifierSchemeCodeUnspsc190501.50421831': __('identifierSchemeCodeUnspsc190501.50421831'),
	'identifierSchemeCodeUnspsc190501.50421832': __('identifierSchemeCodeUnspsc190501.50421832'),
	'identifierSchemeCodeUnspsc190501.50421833': __('identifierSchemeCodeUnspsc190501.50421833'),
	'identifierSchemeCodeUnspsc190501.50421834': __('identifierSchemeCodeUnspsc190501.50421834'),
	'identifierSchemeCodeUnspsc190501.50421835': __('identifierSchemeCodeUnspsc190501.50421835'),
	'identifierSchemeCodeUnspsc190501.50421836': __('identifierSchemeCodeUnspsc190501.50421836'),
	'identifierSchemeCodeUnspsc190501.50421837': __('identifierSchemeCodeUnspsc190501.50421837'),
	'identifierSchemeCodeUnspsc190501.50421838': __('identifierSchemeCodeUnspsc190501.50421838'),
	'identifierSchemeCodeUnspsc190501.50421839': __('identifierSchemeCodeUnspsc190501.50421839'),
	'identifierSchemeCodeUnspsc190501.50421840': __('identifierSchemeCodeUnspsc190501.50421840'),
	'identifierSchemeCodeUnspsc190501.50421841': __('identifierSchemeCodeUnspsc190501.50421841'),
	'identifierSchemeCodeUnspsc190501.50421842': __('identifierSchemeCodeUnspsc190501.50421842'),
	'identifierSchemeCodeUnspsc190501.50421843': __('identifierSchemeCodeUnspsc190501.50421843'),
	'identifierSchemeCodeUnspsc190501.50421844': __('identifierSchemeCodeUnspsc190501.50421844'),
	'identifierSchemeCodeUnspsc190501.50421845': __('identifierSchemeCodeUnspsc190501.50421845'),
	'identifierSchemeCodeUnspsc190501.50421846': __('identifierSchemeCodeUnspsc190501.50421846'),
	'identifierSchemeCodeUnspsc190501.50421847': __('identifierSchemeCodeUnspsc190501.50421847'),
	'identifierSchemeCodeUnspsc190501.50421848': __('identifierSchemeCodeUnspsc190501.50421848'),
	'identifierSchemeCodeUnspsc190501.50421849': __('identifierSchemeCodeUnspsc190501.50421849'),
	'identifierSchemeCodeUnspsc190501.50421850': __('identifierSchemeCodeUnspsc190501.50421850'),
	'identifierSchemeCodeUnspsc190501.50421851': __('identifierSchemeCodeUnspsc190501.50421851'),
	'identifierSchemeCodeUnspsc190501.50421852': __('identifierSchemeCodeUnspsc190501.50421852'),
	'identifierSchemeCodeUnspsc190501.50421900': __('identifierSchemeCodeUnspsc190501.50421900'),
	'identifierSchemeCodeUnspsc190501.50421901': __('identifierSchemeCodeUnspsc190501.50421901'),
	'identifierSchemeCodeUnspsc190501.50421902': __('identifierSchemeCodeUnspsc190501.50421902'),
	'identifierSchemeCodeUnspsc190501.50421903': __('identifierSchemeCodeUnspsc190501.50421903'),
	'identifierSchemeCodeUnspsc190501.50421904': __('identifierSchemeCodeUnspsc190501.50421904'),
	'identifierSchemeCodeUnspsc190501.50421905': __('identifierSchemeCodeUnspsc190501.50421905'),
	'identifierSchemeCodeUnspsc190501.50421906': __('identifierSchemeCodeUnspsc190501.50421906'),
	'identifierSchemeCodeUnspsc190501.50421907': __('identifierSchemeCodeUnspsc190501.50421907'),
	'identifierSchemeCodeUnspsc190501.50421908': __('identifierSchemeCodeUnspsc190501.50421908'),
	'identifierSchemeCodeUnspsc190501.50421909': __('identifierSchemeCodeUnspsc190501.50421909'),
	'identifierSchemeCodeUnspsc190501.50421910': __('identifierSchemeCodeUnspsc190501.50421910'),
	'identifierSchemeCodeUnspsc190501.50421911': __('identifierSchemeCodeUnspsc190501.50421911'),
	'identifierSchemeCodeUnspsc190501.50421912': __('identifierSchemeCodeUnspsc190501.50421912'),
	'identifierSchemeCodeUnspsc190501.50421913': __('identifierSchemeCodeUnspsc190501.50421913'),
	'identifierSchemeCodeUnspsc190501.50421914': __('identifierSchemeCodeUnspsc190501.50421914'),
	'identifierSchemeCodeUnspsc190501.50421915': __('identifierSchemeCodeUnspsc190501.50421915'),
	'identifierSchemeCodeUnspsc190501.50421916': __('identifierSchemeCodeUnspsc190501.50421916'),
	'identifierSchemeCodeUnspsc190501.50421917': __('identifierSchemeCodeUnspsc190501.50421917'),
	'identifierSchemeCodeUnspsc190501.50421918': __('identifierSchemeCodeUnspsc190501.50421918'),
	'identifierSchemeCodeUnspsc190501.50421919': __('identifierSchemeCodeUnspsc190501.50421919'),
	'identifierSchemeCodeUnspsc190501.50421920': __('identifierSchemeCodeUnspsc190501.50421920'),
	'identifierSchemeCodeUnspsc190501.50421921': __('identifierSchemeCodeUnspsc190501.50421921'),
	'identifierSchemeCodeUnspsc190501.50421922': __('identifierSchemeCodeUnspsc190501.50421922'),
	'identifierSchemeCodeUnspsc190501.50422000': __('identifierSchemeCodeUnspsc190501.50422000'),
	'identifierSchemeCodeUnspsc190501.50422001': __('identifierSchemeCodeUnspsc190501.50422001'),
	'identifierSchemeCodeUnspsc190501.50422002': __('identifierSchemeCodeUnspsc190501.50422002'),
	'identifierSchemeCodeUnspsc190501.50422003': __('identifierSchemeCodeUnspsc190501.50422003'),
	'identifierSchemeCodeUnspsc190501.50422004': __('identifierSchemeCodeUnspsc190501.50422004'),
	'identifierSchemeCodeUnspsc190501.50422100': __('identifierSchemeCodeUnspsc190501.50422100'),
	'identifierSchemeCodeUnspsc190501.50422101': __('identifierSchemeCodeUnspsc190501.50422101'),
	'identifierSchemeCodeUnspsc190501.50422102': __('identifierSchemeCodeUnspsc190501.50422102'),
	'identifierSchemeCodeUnspsc190501.50422103': __('identifierSchemeCodeUnspsc190501.50422103'),
	'identifierSchemeCodeUnspsc190501.50422104': __('identifierSchemeCodeUnspsc190501.50422104'),
	'identifierSchemeCodeUnspsc190501.50422105': __('identifierSchemeCodeUnspsc190501.50422105'),
	'identifierSchemeCodeUnspsc190501.50422106': __('identifierSchemeCodeUnspsc190501.50422106'),
	'identifierSchemeCodeUnspsc190501.50422107': __('identifierSchemeCodeUnspsc190501.50422107'),
	'identifierSchemeCodeUnspsc190501.50422200': __('identifierSchemeCodeUnspsc190501.50422200'),
	'identifierSchemeCodeUnspsc190501.50422201': __('identifierSchemeCodeUnspsc190501.50422201'),
	'identifierSchemeCodeUnspsc190501.50422202': __('identifierSchemeCodeUnspsc190501.50422202'),
	'identifierSchemeCodeUnspsc190501.50422203': __('identifierSchemeCodeUnspsc190501.50422203'),
	'identifierSchemeCodeUnspsc190501.50422204': __('identifierSchemeCodeUnspsc190501.50422204'),
	'identifierSchemeCodeUnspsc190501.50422205': __('identifierSchemeCodeUnspsc190501.50422205'),
	'identifierSchemeCodeUnspsc190501.50422206': __('identifierSchemeCodeUnspsc190501.50422206'),
	'identifierSchemeCodeUnspsc190501.50422207': __('identifierSchemeCodeUnspsc190501.50422207'),
	'identifierSchemeCodeUnspsc190501.50422208': __('identifierSchemeCodeUnspsc190501.50422208'),
	'identifierSchemeCodeUnspsc190501.50422209': __('identifierSchemeCodeUnspsc190501.50422209'),
	'identifierSchemeCodeUnspsc190501.50422210': __('identifierSchemeCodeUnspsc190501.50422210'),
	'identifierSchemeCodeUnspsc190501.50422211': __('identifierSchemeCodeUnspsc190501.50422211'),
	'identifierSchemeCodeUnspsc190501.50422300': __('identifierSchemeCodeUnspsc190501.50422300'),
	'identifierSchemeCodeUnspsc190501.50422301': __('identifierSchemeCodeUnspsc190501.50422301'),
	'identifierSchemeCodeUnspsc190501.50422302': __('identifierSchemeCodeUnspsc190501.50422302'),
	'identifierSchemeCodeUnspsc190501.50422303': __('identifierSchemeCodeUnspsc190501.50422303'),
	'identifierSchemeCodeUnspsc190501.50422304': __('identifierSchemeCodeUnspsc190501.50422304'),
	'identifierSchemeCodeUnspsc190501.50422400': __('identifierSchemeCodeUnspsc190501.50422400'),
	'identifierSchemeCodeUnspsc190501.50422401': __('identifierSchemeCodeUnspsc190501.50422401'),
	'identifierSchemeCodeUnspsc190501.50422402': __('identifierSchemeCodeUnspsc190501.50422402'),
	'identifierSchemeCodeUnspsc190501.50422500': __('identifierSchemeCodeUnspsc190501.50422500'),
	'identifierSchemeCodeUnspsc190501.50422501': __('identifierSchemeCodeUnspsc190501.50422501'),
	'identifierSchemeCodeUnspsc190501.50422502': __('identifierSchemeCodeUnspsc190501.50422502'),
	'identifierSchemeCodeUnspsc190501.50422503': __('identifierSchemeCodeUnspsc190501.50422503'),
	'identifierSchemeCodeUnspsc190501.50422504': __('identifierSchemeCodeUnspsc190501.50422504'),
	'identifierSchemeCodeUnspsc190501.50422505': __('identifierSchemeCodeUnspsc190501.50422505'),
	'identifierSchemeCodeUnspsc190501.50422506': __('identifierSchemeCodeUnspsc190501.50422506'),
	'identifierSchemeCodeUnspsc190501.50422600': __('identifierSchemeCodeUnspsc190501.50422600'),
	'identifierSchemeCodeUnspsc190501.50422601': __('identifierSchemeCodeUnspsc190501.50422601'),
	'identifierSchemeCodeUnspsc190501.50422602': __('identifierSchemeCodeUnspsc190501.50422602'),
	'identifierSchemeCodeUnspsc190501.50422603': __('identifierSchemeCodeUnspsc190501.50422603'),
	'identifierSchemeCodeUnspsc190501.50422604': __('identifierSchemeCodeUnspsc190501.50422604'),
	'identifierSchemeCodeUnspsc190501.50422605': __('identifierSchemeCodeUnspsc190501.50422605'),
	'identifierSchemeCodeUnspsc190501.50422606': __('identifierSchemeCodeUnspsc190501.50422606'),
	'identifierSchemeCodeUnspsc190501.50422607': __('identifierSchemeCodeUnspsc190501.50422607'),
	'identifierSchemeCodeUnspsc190501.50422608': __('identifierSchemeCodeUnspsc190501.50422608'),
	'identifierSchemeCodeUnspsc190501.50422609': __('identifierSchemeCodeUnspsc190501.50422609'),
	'identifierSchemeCodeUnspsc190501.50422610': __('identifierSchemeCodeUnspsc190501.50422610'),
	'identifierSchemeCodeUnspsc190501.50422611': __('identifierSchemeCodeUnspsc190501.50422611'),
	'identifierSchemeCodeUnspsc190501.50422612': __('identifierSchemeCodeUnspsc190501.50422612'),
	'identifierSchemeCodeUnspsc190501.50422700': __('identifierSchemeCodeUnspsc190501.50422700'),
	'identifierSchemeCodeUnspsc190501.50422701': __('identifierSchemeCodeUnspsc190501.50422701'),
	'identifierSchemeCodeUnspsc190501.50422702': __('identifierSchemeCodeUnspsc190501.50422702'),
	'identifierSchemeCodeUnspsc190501.50422703': __('identifierSchemeCodeUnspsc190501.50422703'),
	'identifierSchemeCodeUnspsc190501.50422704': __('identifierSchemeCodeUnspsc190501.50422704'),
	'identifierSchemeCodeUnspsc190501.50422705': __('identifierSchemeCodeUnspsc190501.50422705'),
	'identifierSchemeCodeUnspsc190501.50422706': __('identifierSchemeCodeUnspsc190501.50422706'),
	'identifierSchemeCodeUnspsc190501.50422707': __('identifierSchemeCodeUnspsc190501.50422707'),
	'identifierSchemeCodeUnspsc190501.50422708': __('identifierSchemeCodeUnspsc190501.50422708'),
	'identifierSchemeCodeUnspsc190501.50422709': __('identifierSchemeCodeUnspsc190501.50422709'),
	'identifierSchemeCodeUnspsc190501.50422710': __('identifierSchemeCodeUnspsc190501.50422710'),
	'identifierSchemeCodeUnspsc190501.50422711': __('identifierSchemeCodeUnspsc190501.50422711'),
	'identifierSchemeCodeUnspsc190501.50422712': __('identifierSchemeCodeUnspsc190501.50422712'),
	'identifierSchemeCodeUnspsc190501.50422713': __('identifierSchemeCodeUnspsc190501.50422713'),
	'identifierSchemeCodeUnspsc190501.50422714': __('identifierSchemeCodeUnspsc190501.50422714'),
	'identifierSchemeCodeUnspsc190501.50422715': __('identifierSchemeCodeUnspsc190501.50422715'),
	'identifierSchemeCodeUnspsc190501.50422800': __('identifierSchemeCodeUnspsc190501.50422800'),
	'identifierSchemeCodeUnspsc190501.50422801': __('identifierSchemeCodeUnspsc190501.50422801'),
	'identifierSchemeCodeUnspsc190501.50422802': __('identifierSchemeCodeUnspsc190501.50422802'),
	'identifierSchemeCodeUnspsc190501.50422803': __('identifierSchemeCodeUnspsc190501.50422803'),
	'identifierSchemeCodeUnspsc190501.50422804': __('identifierSchemeCodeUnspsc190501.50422804'),
	'identifierSchemeCodeUnspsc190501.50422805': __('identifierSchemeCodeUnspsc190501.50422805'),
	'identifierSchemeCodeUnspsc190501.50422806': __('identifierSchemeCodeUnspsc190501.50422806'),
	'identifierSchemeCodeUnspsc190501.50422807': __('identifierSchemeCodeUnspsc190501.50422807'),
	'identifierSchemeCodeUnspsc190501.50422808': __('identifierSchemeCodeUnspsc190501.50422808'),
	'identifierSchemeCodeUnspsc190501.50422900': __('identifierSchemeCodeUnspsc190501.50422900'),
	'identifierSchemeCodeUnspsc190501.50422901': __('identifierSchemeCodeUnspsc190501.50422901'),
	'identifierSchemeCodeUnspsc190501.50422902': __('identifierSchemeCodeUnspsc190501.50422902'),
	'identifierSchemeCodeUnspsc190501.50422903': __('identifierSchemeCodeUnspsc190501.50422903'),
	'identifierSchemeCodeUnspsc190501.50422904': __('identifierSchemeCodeUnspsc190501.50422904'),
	'identifierSchemeCodeUnspsc190501.50422905': __('identifierSchemeCodeUnspsc190501.50422905'),
	'identifierSchemeCodeUnspsc190501.50422906': __('identifierSchemeCodeUnspsc190501.50422906'),
	'identifierSchemeCodeUnspsc190501.50422907': __('identifierSchemeCodeUnspsc190501.50422907'),
	'identifierSchemeCodeUnspsc190501.50422908': __('identifierSchemeCodeUnspsc190501.50422908'),
	'identifierSchemeCodeUnspsc190501.50422909': __('identifierSchemeCodeUnspsc190501.50422909'),
	'identifierSchemeCodeUnspsc190501.50422910': __('identifierSchemeCodeUnspsc190501.50422910'),
	'identifierSchemeCodeUnspsc190501.50422911': __('identifierSchemeCodeUnspsc190501.50422911'),
	'identifierSchemeCodeUnspsc190501.50423000': __('identifierSchemeCodeUnspsc190501.50423000'),
	'identifierSchemeCodeUnspsc190501.50423001': __('identifierSchemeCodeUnspsc190501.50423001'),
	'identifierSchemeCodeUnspsc190501.50423002': __('identifierSchemeCodeUnspsc190501.50423002'),
	'identifierSchemeCodeUnspsc190501.50423003': __('identifierSchemeCodeUnspsc190501.50423003'),
	'identifierSchemeCodeUnspsc190501.50423004': __('identifierSchemeCodeUnspsc190501.50423004'),
	'identifierSchemeCodeUnspsc190501.50423005': __('identifierSchemeCodeUnspsc190501.50423005'),
	'identifierSchemeCodeUnspsc190501.50423006': __('identifierSchemeCodeUnspsc190501.50423006'),
	'identifierSchemeCodeUnspsc190501.50423007': __('identifierSchemeCodeUnspsc190501.50423007'),
	'identifierSchemeCodeUnspsc190501.50423008': __('identifierSchemeCodeUnspsc190501.50423008'),
	'identifierSchemeCodeUnspsc190501.50423009': __('identifierSchemeCodeUnspsc190501.50423009'),
	'identifierSchemeCodeUnspsc190501.50423010': __('identifierSchemeCodeUnspsc190501.50423010'),
	'identifierSchemeCodeUnspsc190501.50423011': __('identifierSchemeCodeUnspsc190501.50423011'),
	'identifierSchemeCodeUnspsc190501.50423012': __('identifierSchemeCodeUnspsc190501.50423012'),
	'identifierSchemeCodeUnspsc190501.50423013': __('identifierSchemeCodeUnspsc190501.50423013'),
	'identifierSchemeCodeUnspsc190501.50423014': __('identifierSchemeCodeUnspsc190501.50423014'),
	'identifierSchemeCodeUnspsc190501.50423015': __('identifierSchemeCodeUnspsc190501.50423015'),
	'identifierSchemeCodeUnspsc190501.50423016': __('identifierSchemeCodeUnspsc190501.50423016'),
	'identifierSchemeCodeUnspsc190501.50423100': __('identifierSchemeCodeUnspsc190501.50423100'),
	'identifierSchemeCodeUnspsc190501.50423101': __('identifierSchemeCodeUnspsc190501.50423101'),
	'identifierSchemeCodeUnspsc190501.50423102': __('identifierSchemeCodeUnspsc190501.50423102'),
	'identifierSchemeCodeUnspsc190501.50423200': __('identifierSchemeCodeUnspsc190501.50423200'),
	'identifierSchemeCodeUnspsc190501.50423201': __('identifierSchemeCodeUnspsc190501.50423201'),
	'identifierSchemeCodeUnspsc190501.50423202': __('identifierSchemeCodeUnspsc190501.50423202'),
	'identifierSchemeCodeUnspsc190501.50423203': __('identifierSchemeCodeUnspsc190501.50423203'),
	'identifierSchemeCodeUnspsc190501.50423204': __('identifierSchemeCodeUnspsc190501.50423204'),
	'identifierSchemeCodeUnspsc190501.50423205': __('identifierSchemeCodeUnspsc190501.50423205'),
	'identifierSchemeCodeUnspsc190501.50423206': __('identifierSchemeCodeUnspsc190501.50423206'),
	'identifierSchemeCodeUnspsc190501.50423207': __('identifierSchemeCodeUnspsc190501.50423207'),
	'identifierSchemeCodeUnspsc190501.50423208': __('identifierSchemeCodeUnspsc190501.50423208'),
	'identifierSchemeCodeUnspsc190501.50423209': __('identifierSchemeCodeUnspsc190501.50423209'),
	'identifierSchemeCodeUnspsc190501.50423210': __('identifierSchemeCodeUnspsc190501.50423210'),
	'identifierSchemeCodeUnspsc190501.50423211': __('identifierSchemeCodeUnspsc190501.50423211'),
	'identifierSchemeCodeUnspsc190501.50423212': __('identifierSchemeCodeUnspsc190501.50423212'),
	'identifierSchemeCodeUnspsc190501.50423213': __('identifierSchemeCodeUnspsc190501.50423213'),
	'identifierSchemeCodeUnspsc190501.50423214': __('identifierSchemeCodeUnspsc190501.50423214'),
	'identifierSchemeCodeUnspsc190501.50423215': __('identifierSchemeCodeUnspsc190501.50423215'),
	'identifierSchemeCodeUnspsc190501.50423216': __('identifierSchemeCodeUnspsc190501.50423216'),
	'identifierSchemeCodeUnspsc190501.50423217': __('identifierSchemeCodeUnspsc190501.50423217'),
	'identifierSchemeCodeUnspsc190501.50423218': __('identifierSchemeCodeUnspsc190501.50423218'),
	'identifierSchemeCodeUnspsc190501.50423219': __('identifierSchemeCodeUnspsc190501.50423219'),
	'identifierSchemeCodeUnspsc190501.50423220': __('identifierSchemeCodeUnspsc190501.50423220'),
	'identifierSchemeCodeUnspsc190501.50423221': __('identifierSchemeCodeUnspsc190501.50423221'),
	'identifierSchemeCodeUnspsc190501.50423222': __('identifierSchemeCodeUnspsc190501.50423222'),
	'identifierSchemeCodeUnspsc190501.50423223': __('identifierSchemeCodeUnspsc190501.50423223'),
	'identifierSchemeCodeUnspsc190501.50423224': __('identifierSchemeCodeUnspsc190501.50423224'),
	'identifierSchemeCodeUnspsc190501.50423225': __('identifierSchemeCodeUnspsc190501.50423225'),
	'identifierSchemeCodeUnspsc190501.50423226': __('identifierSchemeCodeUnspsc190501.50423226'),
	'identifierSchemeCodeUnspsc190501.50423227': __('identifierSchemeCodeUnspsc190501.50423227'),
	'identifierSchemeCodeUnspsc190501.50423228': __('identifierSchemeCodeUnspsc190501.50423228'),
	'identifierSchemeCodeUnspsc190501.50423229': __('identifierSchemeCodeUnspsc190501.50423229'),
	'identifierSchemeCodeUnspsc190501.50423230': __('identifierSchemeCodeUnspsc190501.50423230'),
	'identifierSchemeCodeUnspsc190501.50423231': __('identifierSchemeCodeUnspsc190501.50423231'),
	'identifierSchemeCodeUnspsc190501.50423232': __('identifierSchemeCodeUnspsc190501.50423232'),
	'identifierSchemeCodeUnspsc190501.50423233': __('identifierSchemeCodeUnspsc190501.50423233'),
	'identifierSchemeCodeUnspsc190501.50423234': __('identifierSchemeCodeUnspsc190501.50423234'),
	'identifierSchemeCodeUnspsc190501.50423235': __('identifierSchemeCodeUnspsc190501.50423235'),
	'identifierSchemeCodeUnspsc190501.50423236': __('identifierSchemeCodeUnspsc190501.50423236'),
	'identifierSchemeCodeUnspsc190501.50423237': __('identifierSchemeCodeUnspsc190501.50423237'),
	'identifierSchemeCodeUnspsc190501.50423238': __('identifierSchemeCodeUnspsc190501.50423238'),
	'identifierSchemeCodeUnspsc190501.50423239': __('identifierSchemeCodeUnspsc190501.50423239'),
	'identifierSchemeCodeUnspsc190501.50423240': __('identifierSchemeCodeUnspsc190501.50423240'),
	'identifierSchemeCodeUnspsc190501.50423241': __('identifierSchemeCodeUnspsc190501.50423241'),
	'identifierSchemeCodeUnspsc190501.50423242': __('identifierSchemeCodeUnspsc190501.50423242'),
	'identifierSchemeCodeUnspsc190501.50423243': __('identifierSchemeCodeUnspsc190501.50423243'),
	'identifierSchemeCodeUnspsc190501.50423244': __('identifierSchemeCodeUnspsc190501.50423244'),
	'identifierSchemeCodeUnspsc190501.50423245': __('identifierSchemeCodeUnspsc190501.50423245'),
	'identifierSchemeCodeUnspsc190501.50423246': __('identifierSchemeCodeUnspsc190501.50423246'),
	'identifierSchemeCodeUnspsc190501.50423247': __('identifierSchemeCodeUnspsc190501.50423247'),
	'identifierSchemeCodeUnspsc190501.50423248': __('identifierSchemeCodeUnspsc190501.50423248'),
	'identifierSchemeCodeUnspsc190501.50423249': __('identifierSchemeCodeUnspsc190501.50423249'),
	'identifierSchemeCodeUnspsc190501.50423250': __('identifierSchemeCodeUnspsc190501.50423250'),
	'identifierSchemeCodeUnspsc190501.50423251': __('identifierSchemeCodeUnspsc190501.50423251'),
	'identifierSchemeCodeUnspsc190501.50423252': __('identifierSchemeCodeUnspsc190501.50423252'),
	'identifierSchemeCodeUnspsc190501.50423253': __('identifierSchemeCodeUnspsc190501.50423253'),
	'identifierSchemeCodeUnspsc190501.50423254': __('identifierSchemeCodeUnspsc190501.50423254'),
	'identifierSchemeCodeUnspsc190501.50423255': __('identifierSchemeCodeUnspsc190501.50423255'),
	'identifierSchemeCodeUnspsc190501.50423256': __('identifierSchemeCodeUnspsc190501.50423256'),
	'identifierSchemeCodeUnspsc190501.50423257': __('identifierSchemeCodeUnspsc190501.50423257'),
	'identifierSchemeCodeUnspsc190501.50423258': __('identifierSchemeCodeUnspsc190501.50423258'),
	'identifierSchemeCodeUnspsc190501.50423259': __('identifierSchemeCodeUnspsc190501.50423259'),
	'identifierSchemeCodeUnspsc190501.50423260': __('identifierSchemeCodeUnspsc190501.50423260'),
	'identifierSchemeCodeUnspsc190501.50423261': __('identifierSchemeCodeUnspsc190501.50423261'),
	'identifierSchemeCodeUnspsc190501.50423262': __('identifierSchemeCodeUnspsc190501.50423262'),
	'identifierSchemeCodeUnspsc190501.50423263': __('identifierSchemeCodeUnspsc190501.50423263'),
	'identifierSchemeCodeUnspsc190501.50423264': __('identifierSchemeCodeUnspsc190501.50423264'),
	'identifierSchemeCodeUnspsc190501.50423265': __('identifierSchemeCodeUnspsc190501.50423265'),
	'identifierSchemeCodeUnspsc190501.50423266': __('identifierSchemeCodeUnspsc190501.50423266'),
	'identifierSchemeCodeUnspsc190501.50423267': __('identifierSchemeCodeUnspsc190501.50423267'),
	'identifierSchemeCodeUnspsc190501.50423268': __('identifierSchemeCodeUnspsc190501.50423268'),
	'identifierSchemeCodeUnspsc190501.50423269': __('identifierSchemeCodeUnspsc190501.50423269'),
	'identifierSchemeCodeUnspsc190501.50423300': __('identifierSchemeCodeUnspsc190501.50423300'),
	'identifierSchemeCodeUnspsc190501.50423301': __('identifierSchemeCodeUnspsc190501.50423301'),
	'identifierSchemeCodeUnspsc190501.50423302': __('identifierSchemeCodeUnspsc190501.50423302'),
	'identifierSchemeCodeUnspsc190501.50423303': __('identifierSchemeCodeUnspsc190501.50423303'),
	'identifierSchemeCodeUnspsc190501.50423304': __('identifierSchemeCodeUnspsc190501.50423304'),
	'identifierSchemeCodeUnspsc190501.50423400': __('identifierSchemeCodeUnspsc190501.50423400'),
	'identifierSchemeCodeUnspsc190501.50423401': __('identifierSchemeCodeUnspsc190501.50423401'),
	'identifierSchemeCodeUnspsc190501.50423402': __('identifierSchemeCodeUnspsc190501.50423402'),
	'identifierSchemeCodeUnspsc190501.50423403': __('identifierSchemeCodeUnspsc190501.50423403'),
	'identifierSchemeCodeUnspsc190501.50423404': __('identifierSchemeCodeUnspsc190501.50423404'),
	'identifierSchemeCodeUnspsc190501.50423405': __('identifierSchemeCodeUnspsc190501.50423405'),
	'identifierSchemeCodeUnspsc190501.50423406': __('identifierSchemeCodeUnspsc190501.50423406'),
	'identifierSchemeCodeUnspsc190501.50423407': __('identifierSchemeCodeUnspsc190501.50423407'),
	'identifierSchemeCodeUnspsc190501.50423408': __('identifierSchemeCodeUnspsc190501.50423408'),
	'identifierSchemeCodeUnspsc190501.50423409': __('identifierSchemeCodeUnspsc190501.50423409'),
	'identifierSchemeCodeUnspsc190501.50423410': __('identifierSchemeCodeUnspsc190501.50423410'),
	'identifierSchemeCodeUnspsc190501.50423411': __('identifierSchemeCodeUnspsc190501.50423411'),
	'identifierSchemeCodeUnspsc190501.50423412': __('identifierSchemeCodeUnspsc190501.50423412'),
	'identifierSchemeCodeUnspsc190501.50423413': __('identifierSchemeCodeUnspsc190501.50423413'),
	'identifierSchemeCodeUnspsc190501.50423414': __('identifierSchemeCodeUnspsc190501.50423414'),
	'identifierSchemeCodeUnspsc190501.50423415': __('identifierSchemeCodeUnspsc190501.50423415'),
	'identifierSchemeCodeUnspsc190501.50423416': __('identifierSchemeCodeUnspsc190501.50423416'),
	'identifierSchemeCodeUnspsc190501.50423417': __('identifierSchemeCodeUnspsc190501.50423417'),
	'identifierSchemeCodeUnspsc190501.50423418': __('identifierSchemeCodeUnspsc190501.50423418'),
	'identifierSchemeCodeUnspsc190501.50423419': __('identifierSchemeCodeUnspsc190501.50423419'),
	'identifierSchemeCodeUnspsc190501.50423420': __('identifierSchemeCodeUnspsc190501.50423420'),
	'identifierSchemeCodeUnspsc190501.50423421': __('identifierSchemeCodeUnspsc190501.50423421'),
	'identifierSchemeCodeUnspsc190501.50423422': __('identifierSchemeCodeUnspsc190501.50423422'),
	'identifierSchemeCodeUnspsc190501.50423423': __('identifierSchemeCodeUnspsc190501.50423423'),
	'identifierSchemeCodeUnspsc190501.50423500': __('identifierSchemeCodeUnspsc190501.50423500'),
	'identifierSchemeCodeUnspsc190501.50423501': __('identifierSchemeCodeUnspsc190501.50423501'),
	'identifierSchemeCodeUnspsc190501.50423502': __('identifierSchemeCodeUnspsc190501.50423502'),
	'identifierSchemeCodeUnspsc190501.50423503': __('identifierSchemeCodeUnspsc190501.50423503'),
	'identifierSchemeCodeUnspsc190501.50423504': __('identifierSchemeCodeUnspsc190501.50423504'),
	'identifierSchemeCodeUnspsc190501.50423505': __('identifierSchemeCodeUnspsc190501.50423505'),
	'identifierSchemeCodeUnspsc190501.50423506': __('identifierSchemeCodeUnspsc190501.50423506'),
	'identifierSchemeCodeUnspsc190501.50423507': __('identifierSchemeCodeUnspsc190501.50423507'),
	'identifierSchemeCodeUnspsc190501.50423508': __('identifierSchemeCodeUnspsc190501.50423508'),
	'identifierSchemeCodeUnspsc190501.50423509': __('identifierSchemeCodeUnspsc190501.50423509'),
	'identifierSchemeCodeUnspsc190501.50423510': __('identifierSchemeCodeUnspsc190501.50423510'),
	'identifierSchemeCodeUnspsc190501.50423511': __('identifierSchemeCodeUnspsc190501.50423511'),
	'identifierSchemeCodeUnspsc190501.50423512': __('identifierSchemeCodeUnspsc190501.50423512'),
	'identifierSchemeCodeUnspsc190501.50423513': __('identifierSchemeCodeUnspsc190501.50423513'),
	'identifierSchemeCodeUnspsc190501.50423514': __('identifierSchemeCodeUnspsc190501.50423514'),
	'identifierSchemeCodeUnspsc190501.50423515': __('identifierSchemeCodeUnspsc190501.50423515'),
	'identifierSchemeCodeUnspsc190501.50423516': __('identifierSchemeCodeUnspsc190501.50423516'),
	'identifierSchemeCodeUnspsc190501.50423517': __('identifierSchemeCodeUnspsc190501.50423517'),
	'identifierSchemeCodeUnspsc190501.50423518': __('identifierSchemeCodeUnspsc190501.50423518'),
	'identifierSchemeCodeUnspsc190501.50423519': __('identifierSchemeCodeUnspsc190501.50423519'),
	'identifierSchemeCodeUnspsc190501.50423520': __('identifierSchemeCodeUnspsc190501.50423520'),
	'identifierSchemeCodeUnspsc190501.50423600': __('identifierSchemeCodeUnspsc190501.50423600'),
	'identifierSchemeCodeUnspsc190501.50423601': __('identifierSchemeCodeUnspsc190501.50423601'),
	'identifierSchemeCodeUnspsc190501.50423602': __('identifierSchemeCodeUnspsc190501.50423602'),
	'identifierSchemeCodeUnspsc190501.50423603': __('identifierSchemeCodeUnspsc190501.50423603'),
	'identifierSchemeCodeUnspsc190501.50423604': __('identifierSchemeCodeUnspsc190501.50423604'),
	'identifierSchemeCodeUnspsc190501.50423605': __('identifierSchemeCodeUnspsc190501.50423605'),
	'identifierSchemeCodeUnspsc190501.50423606': __('identifierSchemeCodeUnspsc190501.50423606'),
	'identifierSchemeCodeUnspsc190501.50423607': __('identifierSchemeCodeUnspsc190501.50423607'),
	'identifierSchemeCodeUnspsc190501.50423608': __('identifierSchemeCodeUnspsc190501.50423608'),
	'identifierSchemeCodeUnspsc190501.50423609': __('identifierSchemeCodeUnspsc190501.50423609'),
	'identifierSchemeCodeUnspsc190501.50423610': __('identifierSchemeCodeUnspsc190501.50423610'),
	'identifierSchemeCodeUnspsc190501.50423611': __('identifierSchemeCodeUnspsc190501.50423611'),
	'identifierSchemeCodeUnspsc190501.50423612': __('identifierSchemeCodeUnspsc190501.50423612'),
	'identifierSchemeCodeUnspsc190501.50423613': __('identifierSchemeCodeUnspsc190501.50423613'),
	'identifierSchemeCodeUnspsc190501.50423614': __('identifierSchemeCodeUnspsc190501.50423614'),
	'identifierSchemeCodeUnspsc190501.50423700': __('identifierSchemeCodeUnspsc190501.50423700'),
	'identifierSchemeCodeUnspsc190501.50423701': __('identifierSchemeCodeUnspsc190501.50423701'),
	'identifierSchemeCodeUnspsc190501.50423702': __('identifierSchemeCodeUnspsc190501.50423702'),
	'identifierSchemeCodeUnspsc190501.50423703': __('identifierSchemeCodeUnspsc190501.50423703'),
	'identifierSchemeCodeUnspsc190501.50423704': __('identifierSchemeCodeUnspsc190501.50423704'),
	'identifierSchemeCodeUnspsc190501.50423705': __('identifierSchemeCodeUnspsc190501.50423705'),
	'identifierSchemeCodeUnspsc190501.50423706': __('identifierSchemeCodeUnspsc190501.50423706'),
	'identifierSchemeCodeUnspsc190501.50423707': __('identifierSchemeCodeUnspsc190501.50423707'),
	'identifierSchemeCodeUnspsc190501.50423800': __('identifierSchemeCodeUnspsc190501.50423800'),
	'identifierSchemeCodeUnspsc190501.50423801': __('identifierSchemeCodeUnspsc190501.50423801'),
	'identifierSchemeCodeUnspsc190501.50423802': __('identifierSchemeCodeUnspsc190501.50423802'),
	'identifierSchemeCodeUnspsc190501.50423803': __('identifierSchemeCodeUnspsc190501.50423803'),
	'identifierSchemeCodeUnspsc190501.50423804': __('identifierSchemeCodeUnspsc190501.50423804'),
	'identifierSchemeCodeUnspsc190501.50423805': __('identifierSchemeCodeUnspsc190501.50423805'),
	'identifierSchemeCodeUnspsc190501.50423806': __('identifierSchemeCodeUnspsc190501.50423806'),
	'identifierSchemeCodeUnspsc190501.50423807': __('identifierSchemeCodeUnspsc190501.50423807'),
	'identifierSchemeCodeUnspsc190501.50423808': __('identifierSchemeCodeUnspsc190501.50423808'),
	'identifierSchemeCodeUnspsc190501.50423809': __('identifierSchemeCodeUnspsc190501.50423809'),
	'identifierSchemeCodeUnspsc190501.50423810': __('identifierSchemeCodeUnspsc190501.50423810'),
	'identifierSchemeCodeUnspsc190501.50423811': __('identifierSchemeCodeUnspsc190501.50423811'),
	'identifierSchemeCodeUnspsc190501.50423900': __('identifierSchemeCodeUnspsc190501.50423900'),
	'identifierSchemeCodeUnspsc190501.50423901': __('identifierSchemeCodeUnspsc190501.50423901'),
	'identifierSchemeCodeUnspsc190501.50423902': __('identifierSchemeCodeUnspsc190501.50423902'),
	'identifierSchemeCodeUnspsc190501.50423903': __('identifierSchemeCodeUnspsc190501.50423903'),
	'identifierSchemeCodeUnspsc190501.50423904': __('identifierSchemeCodeUnspsc190501.50423904'),
	'identifierSchemeCodeUnspsc190501.50423905': __('identifierSchemeCodeUnspsc190501.50423905'),
	'identifierSchemeCodeUnspsc190501.50423906': __('identifierSchemeCodeUnspsc190501.50423906'),
	'identifierSchemeCodeUnspsc190501.50423907': __('identifierSchemeCodeUnspsc190501.50423907'),
	'identifierSchemeCodeUnspsc190501.50423908': __('identifierSchemeCodeUnspsc190501.50423908'),
	'identifierSchemeCodeUnspsc190501.50423909': __('identifierSchemeCodeUnspsc190501.50423909'),
	'identifierSchemeCodeUnspsc190501.50423910': __('identifierSchemeCodeUnspsc190501.50423910'),
	'identifierSchemeCodeUnspsc190501.50423911': __('identifierSchemeCodeUnspsc190501.50423911'),
	'identifierSchemeCodeUnspsc190501.50424000': __('identifierSchemeCodeUnspsc190501.50424000'),
	'identifierSchemeCodeUnspsc190501.50424001': __('identifierSchemeCodeUnspsc190501.50424001'),
	'identifierSchemeCodeUnspsc190501.50424002': __('identifierSchemeCodeUnspsc190501.50424002'),
	'identifierSchemeCodeUnspsc190501.50424003': __('identifierSchemeCodeUnspsc190501.50424003'),
	'identifierSchemeCodeUnspsc190501.50424004': __('identifierSchemeCodeUnspsc190501.50424004'),
	'identifierSchemeCodeUnspsc190501.50424005': __('identifierSchemeCodeUnspsc190501.50424005'),
	'identifierSchemeCodeUnspsc190501.50424100': __('identifierSchemeCodeUnspsc190501.50424100'),
	'identifierSchemeCodeUnspsc190501.50424101': __('identifierSchemeCodeUnspsc190501.50424101'),
	'identifierSchemeCodeUnspsc190501.50424102': __('identifierSchemeCodeUnspsc190501.50424102'),
	'identifierSchemeCodeUnspsc190501.50424103': __('identifierSchemeCodeUnspsc190501.50424103'),
	'identifierSchemeCodeUnspsc190501.50424104': __('identifierSchemeCodeUnspsc190501.50424104'),
	'identifierSchemeCodeUnspsc190501.50424105': __('identifierSchemeCodeUnspsc190501.50424105'),
	'identifierSchemeCodeUnspsc190501.50424106': __('identifierSchemeCodeUnspsc190501.50424106'),
	'identifierSchemeCodeUnspsc190501.50424107': __('identifierSchemeCodeUnspsc190501.50424107'),
	'identifierSchemeCodeUnspsc190501.50424108': __('identifierSchemeCodeUnspsc190501.50424108'),
	'identifierSchemeCodeUnspsc190501.50424109': __('identifierSchemeCodeUnspsc190501.50424109'),
	'identifierSchemeCodeUnspsc190501.50424110': __('identifierSchemeCodeUnspsc190501.50424110'),
	'identifierSchemeCodeUnspsc190501.50424111': __('identifierSchemeCodeUnspsc190501.50424111'),
	'identifierSchemeCodeUnspsc190501.50424112': __('identifierSchemeCodeUnspsc190501.50424112'),
	'identifierSchemeCodeUnspsc190501.50424113': __('identifierSchemeCodeUnspsc190501.50424113'),
	'identifierSchemeCodeUnspsc190501.50424114': __('identifierSchemeCodeUnspsc190501.50424114'),
	'identifierSchemeCodeUnspsc190501.50424115': __('identifierSchemeCodeUnspsc190501.50424115'),
	'identifierSchemeCodeUnspsc190501.50424116': __('identifierSchemeCodeUnspsc190501.50424116'),
	'identifierSchemeCodeUnspsc190501.50424117': __('identifierSchemeCodeUnspsc190501.50424117'),
	'identifierSchemeCodeUnspsc190501.50424118': __('identifierSchemeCodeUnspsc190501.50424118'),
	'identifierSchemeCodeUnspsc190501.50424119': __('identifierSchemeCodeUnspsc190501.50424119'),
	'identifierSchemeCodeUnspsc190501.50424120': __('identifierSchemeCodeUnspsc190501.50424120'),
	'identifierSchemeCodeUnspsc190501.50424121': __('identifierSchemeCodeUnspsc190501.50424121'),
	'identifierSchemeCodeUnspsc190501.50424122': __('identifierSchemeCodeUnspsc190501.50424122'),
	'identifierSchemeCodeUnspsc190501.50424123': __('identifierSchemeCodeUnspsc190501.50424123'),
	'identifierSchemeCodeUnspsc190501.50424124': __('identifierSchemeCodeUnspsc190501.50424124'),
	'identifierSchemeCodeUnspsc190501.50424125': __('identifierSchemeCodeUnspsc190501.50424125'),
	'identifierSchemeCodeUnspsc190501.50424126': __('identifierSchemeCodeUnspsc190501.50424126'),
	'identifierSchemeCodeUnspsc190501.50424127': __('identifierSchemeCodeUnspsc190501.50424127'),
	'identifierSchemeCodeUnspsc190501.50424200': __('identifierSchemeCodeUnspsc190501.50424200'),
	'identifierSchemeCodeUnspsc190501.50424201': __('identifierSchemeCodeUnspsc190501.50424201'),
	'identifierSchemeCodeUnspsc190501.50424202': __('identifierSchemeCodeUnspsc190501.50424202'),
	'identifierSchemeCodeUnspsc190501.50424300': __('identifierSchemeCodeUnspsc190501.50424300'),
	'identifierSchemeCodeUnspsc190501.50424301': __('identifierSchemeCodeUnspsc190501.50424301'),
	'identifierSchemeCodeUnspsc190501.50424302': __('identifierSchemeCodeUnspsc190501.50424302'),
	'identifierSchemeCodeUnspsc190501.50424303': __('identifierSchemeCodeUnspsc190501.50424303'),
	'identifierSchemeCodeUnspsc190501.50424304': __('identifierSchemeCodeUnspsc190501.50424304'),
	'identifierSchemeCodeUnspsc190501.50424305': __('identifierSchemeCodeUnspsc190501.50424305'),
	'identifierSchemeCodeUnspsc190501.50424306': __('identifierSchemeCodeUnspsc190501.50424306'),
	'identifierSchemeCodeUnspsc190501.50424400': __('identifierSchemeCodeUnspsc190501.50424400'),
	'identifierSchemeCodeUnspsc190501.50424401': __('identifierSchemeCodeUnspsc190501.50424401'),
	'identifierSchemeCodeUnspsc190501.50424402': __('identifierSchemeCodeUnspsc190501.50424402'),
	'identifierSchemeCodeUnspsc190501.50424403': __('identifierSchemeCodeUnspsc190501.50424403'),
	'identifierSchemeCodeUnspsc190501.50424404': __('identifierSchemeCodeUnspsc190501.50424404'),
	'identifierSchemeCodeUnspsc190501.50424405': __('identifierSchemeCodeUnspsc190501.50424405'),
	'identifierSchemeCodeUnspsc190501.50424406': __('identifierSchemeCodeUnspsc190501.50424406'),
	'identifierSchemeCodeUnspsc190501.50424500': __('identifierSchemeCodeUnspsc190501.50424500'),
	'identifierSchemeCodeUnspsc190501.50424501': __('identifierSchemeCodeUnspsc190501.50424501'),
	'identifierSchemeCodeUnspsc190501.50424502': __('identifierSchemeCodeUnspsc190501.50424502'),
	'identifierSchemeCodeUnspsc190501.50424503': __('identifierSchemeCodeUnspsc190501.50424503'),
	'identifierSchemeCodeUnspsc190501.50424504': __('identifierSchemeCodeUnspsc190501.50424504'),
	'identifierSchemeCodeUnspsc190501.50424505': __('identifierSchemeCodeUnspsc190501.50424505'),
	'identifierSchemeCodeUnspsc190501.50424506': __('identifierSchemeCodeUnspsc190501.50424506'),
	'identifierSchemeCodeUnspsc190501.50424507': __('identifierSchemeCodeUnspsc190501.50424507'),
	'identifierSchemeCodeUnspsc190501.50424508': __('identifierSchemeCodeUnspsc190501.50424508'),
	'identifierSchemeCodeUnspsc190501.50424600': __('identifierSchemeCodeUnspsc190501.50424600'),
	'identifierSchemeCodeUnspsc190501.50424601': __('identifierSchemeCodeUnspsc190501.50424601'),
	'identifierSchemeCodeUnspsc190501.50424602': __('identifierSchemeCodeUnspsc190501.50424602'),
	'identifierSchemeCodeUnspsc190501.50424603': __('identifierSchemeCodeUnspsc190501.50424603'),
	'identifierSchemeCodeUnspsc190501.50424604': __('identifierSchemeCodeUnspsc190501.50424604'),
	'identifierSchemeCodeUnspsc190501.50424605': __('identifierSchemeCodeUnspsc190501.50424605'),
	'identifierSchemeCodeUnspsc190501.50424606': __('identifierSchemeCodeUnspsc190501.50424606'),
	'identifierSchemeCodeUnspsc190501.50424607': __('identifierSchemeCodeUnspsc190501.50424607'),
	'identifierSchemeCodeUnspsc190501.50424608': __('identifierSchemeCodeUnspsc190501.50424608'),
	'identifierSchemeCodeUnspsc190501.50424609': __('identifierSchemeCodeUnspsc190501.50424609'),
	'identifierSchemeCodeUnspsc190501.50424610': __('identifierSchemeCodeUnspsc190501.50424610'),
	'identifierSchemeCodeUnspsc190501.50424611': __('identifierSchemeCodeUnspsc190501.50424611'),
	'identifierSchemeCodeUnspsc190501.50424612': __('identifierSchemeCodeUnspsc190501.50424612'),
	'identifierSchemeCodeUnspsc190501.50424613': __('identifierSchemeCodeUnspsc190501.50424613'),
	'identifierSchemeCodeUnspsc190501.50424614': __('identifierSchemeCodeUnspsc190501.50424614'),
	'identifierSchemeCodeUnspsc190501.50424615': __('identifierSchemeCodeUnspsc190501.50424615'),
	'identifierSchemeCodeUnspsc190501.50424616': __('identifierSchemeCodeUnspsc190501.50424616'),
	'identifierSchemeCodeUnspsc190501.50424617': __('identifierSchemeCodeUnspsc190501.50424617'),
	'identifierSchemeCodeUnspsc190501.50424618': __('identifierSchemeCodeUnspsc190501.50424618'),
	'identifierSchemeCodeUnspsc190501.50424619': __('identifierSchemeCodeUnspsc190501.50424619'),
	'identifierSchemeCodeUnspsc190501.50424620': __('identifierSchemeCodeUnspsc190501.50424620'),
	'identifierSchemeCodeUnspsc190501.50424621': __('identifierSchemeCodeUnspsc190501.50424621'),
	'identifierSchemeCodeUnspsc190501.50424622': __('identifierSchemeCodeUnspsc190501.50424622'),
	'identifierSchemeCodeUnspsc190501.50424623': __('identifierSchemeCodeUnspsc190501.50424623'),
	'identifierSchemeCodeUnspsc190501.50424700': __('identifierSchemeCodeUnspsc190501.50424700'),
	'identifierSchemeCodeUnspsc190501.50424701': __('identifierSchemeCodeUnspsc190501.50424701'),
	'identifierSchemeCodeUnspsc190501.50424702': __('identifierSchemeCodeUnspsc190501.50424702'),
	'identifierSchemeCodeUnspsc190501.50424703': __('identifierSchemeCodeUnspsc190501.50424703'),
	'identifierSchemeCodeUnspsc190501.50424704': __('identifierSchemeCodeUnspsc190501.50424704'),
	'identifierSchemeCodeUnspsc190501.50424705': __('identifierSchemeCodeUnspsc190501.50424705'),
	'identifierSchemeCodeUnspsc190501.50424706': __('identifierSchemeCodeUnspsc190501.50424706'),
	'identifierSchemeCodeUnspsc190501.50424800': __('identifierSchemeCodeUnspsc190501.50424800'),
	'identifierSchemeCodeUnspsc190501.50424801': __('identifierSchemeCodeUnspsc190501.50424801'),
	'identifierSchemeCodeUnspsc190501.50424802': __('identifierSchemeCodeUnspsc190501.50424802'),
	'identifierSchemeCodeUnspsc190501.50424803': __('identifierSchemeCodeUnspsc190501.50424803'),
	'identifierSchemeCodeUnspsc190501.50424804': __('identifierSchemeCodeUnspsc190501.50424804'),
	'identifierSchemeCodeUnspsc190501.50424805': __('identifierSchemeCodeUnspsc190501.50424805'),
	'identifierSchemeCodeUnspsc190501.50424806': __('identifierSchemeCodeUnspsc190501.50424806'),
	'identifierSchemeCodeUnspsc190501.50424807': __('identifierSchemeCodeUnspsc190501.50424807'),
	'identifierSchemeCodeUnspsc190501.50424808': __('identifierSchemeCodeUnspsc190501.50424808'),
	'identifierSchemeCodeUnspsc190501.50424809': __('identifierSchemeCodeUnspsc190501.50424809'),
	'identifierSchemeCodeUnspsc190501.50424810': __('identifierSchemeCodeUnspsc190501.50424810'),
	'identifierSchemeCodeUnspsc190501.50424811': __('identifierSchemeCodeUnspsc190501.50424811'),
	'identifierSchemeCodeUnspsc190501.50424812': __('identifierSchemeCodeUnspsc190501.50424812'),
	'identifierSchemeCodeUnspsc190501.50424813': __('identifierSchemeCodeUnspsc190501.50424813'),
	'identifierSchemeCodeUnspsc190501.50424814': __('identifierSchemeCodeUnspsc190501.50424814'),
	'identifierSchemeCodeUnspsc190501.50424815': __('identifierSchemeCodeUnspsc190501.50424815'),
	'identifierSchemeCodeUnspsc190501.50424816': __('identifierSchemeCodeUnspsc190501.50424816'),
	'identifierSchemeCodeUnspsc190501.50424817': __('identifierSchemeCodeUnspsc190501.50424817'),
	'identifierSchemeCodeUnspsc190501.50424818': __('identifierSchemeCodeUnspsc190501.50424818'),
	'identifierSchemeCodeUnspsc190501.50424819': __('identifierSchemeCodeUnspsc190501.50424819'),
	'identifierSchemeCodeUnspsc190501.50424820': __('identifierSchemeCodeUnspsc190501.50424820'),
	'identifierSchemeCodeUnspsc190501.50424821': __('identifierSchemeCodeUnspsc190501.50424821'),
	'identifierSchemeCodeUnspsc190501.50424900': __('identifierSchemeCodeUnspsc190501.50424900'),
	'identifierSchemeCodeUnspsc190501.50424901': __('identifierSchemeCodeUnspsc190501.50424901'),
	'identifierSchemeCodeUnspsc190501.50424902': __('identifierSchemeCodeUnspsc190501.50424902'),
	'identifierSchemeCodeUnspsc190501.50424903': __('identifierSchemeCodeUnspsc190501.50424903'),
	'identifierSchemeCodeUnspsc190501.50424904': __('identifierSchemeCodeUnspsc190501.50424904'),
	'identifierSchemeCodeUnspsc190501.50424905': __('identifierSchemeCodeUnspsc190501.50424905'),
	'identifierSchemeCodeUnspsc190501.50424906': __('identifierSchemeCodeUnspsc190501.50424906'),
	'identifierSchemeCodeUnspsc190501.50425000': __('identifierSchemeCodeUnspsc190501.50425000'),
	'identifierSchemeCodeUnspsc190501.50425001': __('identifierSchemeCodeUnspsc190501.50425001'),
	'identifierSchemeCodeUnspsc190501.50425002': __('identifierSchemeCodeUnspsc190501.50425002'),
	'identifierSchemeCodeUnspsc190501.50425003': __('identifierSchemeCodeUnspsc190501.50425003'),
	'identifierSchemeCodeUnspsc190501.50425004': __('identifierSchemeCodeUnspsc190501.50425004'),
	'identifierSchemeCodeUnspsc190501.50425100': __('identifierSchemeCodeUnspsc190501.50425100'),
	'identifierSchemeCodeUnspsc190501.50425101': __('identifierSchemeCodeUnspsc190501.50425101'),
	'identifierSchemeCodeUnspsc190501.50425102': __('identifierSchemeCodeUnspsc190501.50425102'),
	'identifierSchemeCodeUnspsc190501.50425103': __('identifierSchemeCodeUnspsc190501.50425103'),
	'identifierSchemeCodeUnspsc190501.50425104': __('identifierSchemeCodeUnspsc190501.50425104'),
	'identifierSchemeCodeUnspsc190501.50425105': __('identifierSchemeCodeUnspsc190501.50425105'),
	'identifierSchemeCodeUnspsc190501.50425106': __('identifierSchemeCodeUnspsc190501.50425106'),
	'identifierSchemeCodeUnspsc190501.50425107': __('identifierSchemeCodeUnspsc190501.50425107'),
	'identifierSchemeCodeUnspsc190501.50425200': __('identifierSchemeCodeUnspsc190501.50425200'),
	'identifierSchemeCodeUnspsc190501.50425201': __('identifierSchemeCodeUnspsc190501.50425201'),
	'identifierSchemeCodeUnspsc190501.50425202': __('identifierSchemeCodeUnspsc190501.50425202'),
	'identifierSchemeCodeUnspsc190501.50425203': __('identifierSchemeCodeUnspsc190501.50425203'),
	'identifierSchemeCodeUnspsc190501.50425204': __('identifierSchemeCodeUnspsc190501.50425204'),
	'identifierSchemeCodeUnspsc190501.50425205': __('identifierSchemeCodeUnspsc190501.50425205'),
	'identifierSchemeCodeUnspsc190501.50425206': __('identifierSchemeCodeUnspsc190501.50425206'),
	'identifierSchemeCodeUnspsc190501.50425207': __('identifierSchemeCodeUnspsc190501.50425207'),
	'identifierSchemeCodeUnspsc190501.50425208': __('identifierSchemeCodeUnspsc190501.50425208'),
	'identifierSchemeCodeUnspsc190501.50425209': __('identifierSchemeCodeUnspsc190501.50425209'),
	'identifierSchemeCodeUnspsc190501.50425210': __('identifierSchemeCodeUnspsc190501.50425210'),
	'identifierSchemeCodeUnspsc190501.50425211': __('identifierSchemeCodeUnspsc190501.50425211'),
	'identifierSchemeCodeUnspsc190501.50425212': __('identifierSchemeCodeUnspsc190501.50425212'),
	'identifierSchemeCodeUnspsc190501.50425213': __('identifierSchemeCodeUnspsc190501.50425213'),
	'identifierSchemeCodeUnspsc190501.50425214': __('identifierSchemeCodeUnspsc190501.50425214'),
	'identifierSchemeCodeUnspsc190501.50425215': __('identifierSchemeCodeUnspsc190501.50425215'),
	'identifierSchemeCodeUnspsc190501.50425216': __('identifierSchemeCodeUnspsc190501.50425216'),
	'identifierSchemeCodeUnspsc190501.50425217': __('identifierSchemeCodeUnspsc190501.50425217'),
	'identifierSchemeCodeUnspsc190501.50425218': __('identifierSchemeCodeUnspsc190501.50425218'),
	'identifierSchemeCodeUnspsc190501.50425219': __('identifierSchemeCodeUnspsc190501.50425219'),
	'identifierSchemeCodeUnspsc190501.50425220': __('identifierSchemeCodeUnspsc190501.50425220'),
	'identifierSchemeCodeUnspsc190501.50425221': __('identifierSchemeCodeUnspsc190501.50425221'),
	'identifierSchemeCodeUnspsc190501.50425300': __('identifierSchemeCodeUnspsc190501.50425300'),
	'identifierSchemeCodeUnspsc190501.50425301': __('identifierSchemeCodeUnspsc190501.50425301'),
	'identifierSchemeCodeUnspsc190501.50425302': __('identifierSchemeCodeUnspsc190501.50425302'),
	'identifierSchemeCodeUnspsc190501.50425303': __('identifierSchemeCodeUnspsc190501.50425303'),
	'identifierSchemeCodeUnspsc190501.50425304': __('identifierSchemeCodeUnspsc190501.50425304'),
	'identifierSchemeCodeUnspsc190501.50425305': __('identifierSchemeCodeUnspsc190501.50425305'),
	'identifierSchemeCodeUnspsc190501.50425306': __('identifierSchemeCodeUnspsc190501.50425306'),
	'identifierSchemeCodeUnspsc190501.50425400': __('identifierSchemeCodeUnspsc190501.50425400'),
	'identifierSchemeCodeUnspsc190501.50425401': __('identifierSchemeCodeUnspsc190501.50425401'),
	'identifierSchemeCodeUnspsc190501.50425402': __('identifierSchemeCodeUnspsc190501.50425402'),
	'identifierSchemeCodeUnspsc190501.50425403': __('identifierSchemeCodeUnspsc190501.50425403'),
	'identifierSchemeCodeUnspsc190501.50425404': __('identifierSchemeCodeUnspsc190501.50425404'),
	'identifierSchemeCodeUnspsc190501.50425405': __('identifierSchemeCodeUnspsc190501.50425405'),
	'identifierSchemeCodeUnspsc190501.50425406': __('identifierSchemeCodeUnspsc190501.50425406'),
	'identifierSchemeCodeUnspsc190501.50425500': __('identifierSchemeCodeUnspsc190501.50425500'),
	'identifierSchemeCodeUnspsc190501.50425501': __('identifierSchemeCodeUnspsc190501.50425501'),
	'identifierSchemeCodeUnspsc190501.50425502': __('identifierSchemeCodeUnspsc190501.50425502'),
	'identifierSchemeCodeUnspsc190501.50425503': __('identifierSchemeCodeUnspsc190501.50425503'),
	'identifierSchemeCodeUnspsc190501.50425504': __('identifierSchemeCodeUnspsc190501.50425504'),
	'identifierSchemeCodeUnspsc190501.50425505': __('identifierSchemeCodeUnspsc190501.50425505'),
	'identifierSchemeCodeUnspsc190501.50425506': __('identifierSchemeCodeUnspsc190501.50425506'),
	'identifierSchemeCodeUnspsc190501.50425507': __('identifierSchemeCodeUnspsc190501.50425507'),
	'identifierSchemeCodeUnspsc190501.50425508': __('identifierSchemeCodeUnspsc190501.50425508'),
	'identifierSchemeCodeUnspsc190501.50425509': __('identifierSchemeCodeUnspsc190501.50425509'),
	'identifierSchemeCodeUnspsc190501.50425510': __('identifierSchemeCodeUnspsc190501.50425510'),
	'identifierSchemeCodeUnspsc190501.50425511': __('identifierSchemeCodeUnspsc190501.50425511'),
	'identifierSchemeCodeUnspsc190501.50425512': __('identifierSchemeCodeUnspsc190501.50425512'),
	'identifierSchemeCodeUnspsc190501.50425513': __('identifierSchemeCodeUnspsc190501.50425513'),
	'identifierSchemeCodeUnspsc190501.50425514': __('identifierSchemeCodeUnspsc190501.50425514'),
	'identifierSchemeCodeUnspsc190501.50425515': __('identifierSchemeCodeUnspsc190501.50425515'),
	'identifierSchemeCodeUnspsc190501.50425516': __('identifierSchemeCodeUnspsc190501.50425516'),
	'identifierSchemeCodeUnspsc190501.50425517': __('identifierSchemeCodeUnspsc190501.50425517'),
	'identifierSchemeCodeUnspsc190501.50425518': __('identifierSchemeCodeUnspsc190501.50425518'),
	'identifierSchemeCodeUnspsc190501.50425519': __('identifierSchemeCodeUnspsc190501.50425519'),
	'identifierSchemeCodeUnspsc190501.50425520': __('identifierSchemeCodeUnspsc190501.50425520'),
	'identifierSchemeCodeUnspsc190501.50425521': __('identifierSchemeCodeUnspsc190501.50425521'),
	'identifierSchemeCodeUnspsc190501.50425522': __('identifierSchemeCodeUnspsc190501.50425522'),
	'identifierSchemeCodeUnspsc190501.50425523': __('identifierSchemeCodeUnspsc190501.50425523'),
	'identifierSchemeCodeUnspsc190501.50425524': __('identifierSchemeCodeUnspsc190501.50425524'),
	'identifierSchemeCodeUnspsc190501.50425525': __('identifierSchemeCodeUnspsc190501.50425525'),
	'identifierSchemeCodeUnspsc190501.50425526': __('identifierSchemeCodeUnspsc190501.50425526'),
	'identifierSchemeCodeUnspsc190501.50425527': __('identifierSchemeCodeUnspsc190501.50425527'),
	'identifierSchemeCodeUnspsc190501.50425528': __('identifierSchemeCodeUnspsc190501.50425528'),
	'identifierSchemeCodeUnspsc190501.50425529': __('identifierSchemeCodeUnspsc190501.50425529'),
	'identifierSchemeCodeUnspsc190501.50425530': __('identifierSchemeCodeUnspsc190501.50425530'),
	'identifierSchemeCodeUnspsc190501.50425531': __('identifierSchemeCodeUnspsc190501.50425531'),
	'identifierSchemeCodeUnspsc190501.50425532': __('identifierSchemeCodeUnspsc190501.50425532'),
	'identifierSchemeCodeUnspsc190501.50425533': __('identifierSchemeCodeUnspsc190501.50425533'),
	'identifierSchemeCodeUnspsc190501.50425534': __('identifierSchemeCodeUnspsc190501.50425534'),
	'identifierSchemeCodeUnspsc190501.50425535': __('identifierSchemeCodeUnspsc190501.50425535'),
	'identifierSchemeCodeUnspsc190501.50425536': __('identifierSchemeCodeUnspsc190501.50425536'),
	'identifierSchemeCodeUnspsc190501.50425537': __('identifierSchemeCodeUnspsc190501.50425537'),
	'identifierSchemeCodeUnspsc190501.50425538': __('identifierSchemeCodeUnspsc190501.50425538'),
	'identifierSchemeCodeUnspsc190501.50425600': __('identifierSchemeCodeUnspsc190501.50425600'),
	'identifierSchemeCodeUnspsc190501.50425601': __('identifierSchemeCodeUnspsc190501.50425601'),
	'identifierSchemeCodeUnspsc190501.50425602': __('identifierSchemeCodeUnspsc190501.50425602'),
	'identifierSchemeCodeUnspsc190501.50425603': __('identifierSchemeCodeUnspsc190501.50425603'),
	'identifierSchemeCodeUnspsc190501.50425604': __('identifierSchemeCodeUnspsc190501.50425604'),
	'identifierSchemeCodeUnspsc190501.50425605': __('identifierSchemeCodeUnspsc190501.50425605'),
	'identifierSchemeCodeUnspsc190501.50425606': __('identifierSchemeCodeUnspsc190501.50425606'),
	'identifierSchemeCodeUnspsc190501.50425607': __('identifierSchemeCodeUnspsc190501.50425607'),
	'identifierSchemeCodeUnspsc190501.50425608': __('identifierSchemeCodeUnspsc190501.50425608'),
	'identifierSchemeCodeUnspsc190501.50425609': __('identifierSchemeCodeUnspsc190501.50425609'),
	'identifierSchemeCodeUnspsc190501.50425700': __('identifierSchemeCodeUnspsc190501.50425700'),
	'identifierSchemeCodeUnspsc190501.50425701': __('identifierSchemeCodeUnspsc190501.50425701'),
	'identifierSchemeCodeUnspsc190501.50425702': __('identifierSchemeCodeUnspsc190501.50425702'),
	'identifierSchemeCodeUnspsc190501.50425703': __('identifierSchemeCodeUnspsc190501.50425703'),
	'identifierSchemeCodeUnspsc190501.50425704': __('identifierSchemeCodeUnspsc190501.50425704'),
	'identifierSchemeCodeUnspsc190501.50425800': __('identifierSchemeCodeUnspsc190501.50425800'),
	'identifierSchemeCodeUnspsc190501.50425801': __('identifierSchemeCodeUnspsc190501.50425801'),
	'identifierSchemeCodeUnspsc190501.50425802': __('identifierSchemeCodeUnspsc190501.50425802'),
	'identifierSchemeCodeUnspsc190501.50425803': __('identifierSchemeCodeUnspsc190501.50425803'),
	'identifierSchemeCodeUnspsc190501.50425804': __('identifierSchemeCodeUnspsc190501.50425804'),
	'identifierSchemeCodeUnspsc190501.50425805': __('identifierSchemeCodeUnspsc190501.50425805'),
	'identifierSchemeCodeUnspsc190501.50425806': __('identifierSchemeCodeUnspsc190501.50425806'),
	'identifierSchemeCodeUnspsc190501.50425807': __('identifierSchemeCodeUnspsc190501.50425807'),
	'identifierSchemeCodeUnspsc190501.50425808': __('identifierSchemeCodeUnspsc190501.50425808'),
	'identifierSchemeCodeUnspsc190501.50425809': __('identifierSchemeCodeUnspsc190501.50425809'),
	'identifierSchemeCodeUnspsc190501.50425810': __('identifierSchemeCodeUnspsc190501.50425810'),
	'identifierSchemeCodeUnspsc190501.50425811': __('identifierSchemeCodeUnspsc190501.50425811'),
	'identifierSchemeCodeUnspsc190501.50425812': __('identifierSchemeCodeUnspsc190501.50425812'),
	'identifierSchemeCodeUnspsc190501.50425813': __('identifierSchemeCodeUnspsc190501.50425813'),
	'identifierSchemeCodeUnspsc190501.50425814': __('identifierSchemeCodeUnspsc190501.50425814'),
	'identifierSchemeCodeUnspsc190501.50425815': __('identifierSchemeCodeUnspsc190501.50425815'),
	'identifierSchemeCodeUnspsc190501.50425816': __('identifierSchemeCodeUnspsc190501.50425816'),
	'identifierSchemeCodeUnspsc190501.50425900': __('identifierSchemeCodeUnspsc190501.50425900'),
	'identifierSchemeCodeUnspsc190501.50425901': __('identifierSchemeCodeUnspsc190501.50425901'),
	'identifierSchemeCodeUnspsc190501.50425902': __('identifierSchemeCodeUnspsc190501.50425902'),
	'identifierSchemeCodeUnspsc190501.50425903': __('identifierSchemeCodeUnspsc190501.50425903'),
	'identifierSchemeCodeUnspsc190501.50425904': __('identifierSchemeCodeUnspsc190501.50425904'),
	'identifierSchemeCodeUnspsc190501.50425905': __('identifierSchemeCodeUnspsc190501.50425905'),
	'identifierSchemeCodeUnspsc190501.50425906': __('identifierSchemeCodeUnspsc190501.50425906'),
	'identifierSchemeCodeUnspsc190501.50425907': __('identifierSchemeCodeUnspsc190501.50425907'),
	'identifierSchemeCodeUnspsc190501.50425908': __('identifierSchemeCodeUnspsc190501.50425908'),
	'identifierSchemeCodeUnspsc190501.50425909': __('identifierSchemeCodeUnspsc190501.50425909'),
	'identifierSchemeCodeUnspsc190501.50425910': __('identifierSchemeCodeUnspsc190501.50425910'),
	'identifierSchemeCodeUnspsc190501.50425911': __('identifierSchemeCodeUnspsc190501.50425911'),
	'identifierSchemeCodeUnspsc190501.50426000': __('identifierSchemeCodeUnspsc190501.50426000'),
	'identifierSchemeCodeUnspsc190501.50426001': __('identifierSchemeCodeUnspsc190501.50426001'),
	'identifierSchemeCodeUnspsc190501.50426002': __('identifierSchemeCodeUnspsc190501.50426002'),
	'identifierSchemeCodeUnspsc190501.50426003': __('identifierSchemeCodeUnspsc190501.50426003'),
	'identifierSchemeCodeUnspsc190501.50426004': __('identifierSchemeCodeUnspsc190501.50426004'),
	'identifierSchemeCodeUnspsc190501.50426100': __('identifierSchemeCodeUnspsc190501.50426100'),
	'identifierSchemeCodeUnspsc190501.50426101': __('identifierSchemeCodeUnspsc190501.50426101'),
	'identifierSchemeCodeUnspsc190501.50426102': __('identifierSchemeCodeUnspsc190501.50426102'),
	'identifierSchemeCodeUnspsc190501.50426103': __('identifierSchemeCodeUnspsc190501.50426103'),
	'identifierSchemeCodeUnspsc190501.50426104': __('identifierSchemeCodeUnspsc190501.50426104'),
	'identifierSchemeCodeUnspsc190501.50426105': __('identifierSchemeCodeUnspsc190501.50426105'),
	'identifierSchemeCodeUnspsc190501.50426106': __('identifierSchemeCodeUnspsc190501.50426106'),
	'identifierSchemeCodeUnspsc190501.50426107': __('identifierSchemeCodeUnspsc190501.50426107'),
	'identifierSchemeCodeUnspsc190501.50426108': __('identifierSchemeCodeUnspsc190501.50426108'),
	'identifierSchemeCodeUnspsc190501.50426109': __('identifierSchemeCodeUnspsc190501.50426109'),
	'identifierSchemeCodeUnspsc190501.50426110': __('identifierSchemeCodeUnspsc190501.50426110'),
	'identifierSchemeCodeUnspsc190501.50426111': __('identifierSchemeCodeUnspsc190501.50426111'),
	'identifierSchemeCodeUnspsc190501.50426112': __('identifierSchemeCodeUnspsc190501.50426112'),
	'identifierSchemeCodeUnspsc190501.50426113': __('identifierSchemeCodeUnspsc190501.50426113'),
	'identifierSchemeCodeUnspsc190501.50426114': __('identifierSchemeCodeUnspsc190501.50426114'),
	'identifierSchemeCodeUnspsc190501.50426115': __('identifierSchemeCodeUnspsc190501.50426115'),
	'identifierSchemeCodeUnspsc190501.50426116': __('identifierSchemeCodeUnspsc190501.50426116'),
	'identifierSchemeCodeUnspsc190501.50426200': __('identifierSchemeCodeUnspsc190501.50426200'),
	'identifierSchemeCodeUnspsc190501.50426201': __('identifierSchemeCodeUnspsc190501.50426201'),
	'identifierSchemeCodeUnspsc190501.50426202': __('identifierSchemeCodeUnspsc190501.50426202'),
	'identifierSchemeCodeUnspsc190501.50426203': __('identifierSchemeCodeUnspsc190501.50426203'),
	'identifierSchemeCodeUnspsc190501.50426204': __('identifierSchemeCodeUnspsc190501.50426204'),
	'identifierSchemeCodeUnspsc190501.50426205': __('identifierSchemeCodeUnspsc190501.50426205'),
	'identifierSchemeCodeUnspsc190501.50426206': __('identifierSchemeCodeUnspsc190501.50426206'),
	'identifierSchemeCodeUnspsc190501.50426207': __('identifierSchemeCodeUnspsc190501.50426207'),
	'identifierSchemeCodeUnspsc190501.50426208': __('identifierSchemeCodeUnspsc190501.50426208'),
	'identifierSchemeCodeUnspsc190501.50426209': __('identifierSchemeCodeUnspsc190501.50426209'),
	'identifierSchemeCodeUnspsc190501.50426210': __('identifierSchemeCodeUnspsc190501.50426210'),
	'identifierSchemeCodeUnspsc190501.50426211': __('identifierSchemeCodeUnspsc190501.50426211'),
	'identifierSchemeCodeUnspsc190501.50426212': __('identifierSchemeCodeUnspsc190501.50426212'),
	'identifierSchemeCodeUnspsc190501.50426213': __('identifierSchemeCodeUnspsc190501.50426213'),
	'identifierSchemeCodeUnspsc190501.50426214': __('identifierSchemeCodeUnspsc190501.50426214'),
	'identifierSchemeCodeUnspsc190501.50426215': __('identifierSchemeCodeUnspsc190501.50426215'),
	'identifierSchemeCodeUnspsc190501.50426216': __('identifierSchemeCodeUnspsc190501.50426216'),
	'identifierSchemeCodeUnspsc190501.50426217': __('identifierSchemeCodeUnspsc190501.50426217'),
	'identifierSchemeCodeUnspsc190501.50426218': __('identifierSchemeCodeUnspsc190501.50426218'),
	'identifierSchemeCodeUnspsc190501.50426219': __('identifierSchemeCodeUnspsc190501.50426219'),
	'identifierSchemeCodeUnspsc190501.50426220': __('identifierSchemeCodeUnspsc190501.50426220'),
	'identifierSchemeCodeUnspsc190501.50426221': __('identifierSchemeCodeUnspsc190501.50426221'),
	'identifierSchemeCodeUnspsc190501.50426222': __('identifierSchemeCodeUnspsc190501.50426222'),
	'identifierSchemeCodeUnspsc190501.50426223': __('identifierSchemeCodeUnspsc190501.50426223'),
	'identifierSchemeCodeUnspsc190501.50426224': __('identifierSchemeCodeUnspsc190501.50426224'),
	'identifierSchemeCodeUnspsc190501.50426225': __('identifierSchemeCodeUnspsc190501.50426225'),
	'identifierSchemeCodeUnspsc190501.50426226': __('identifierSchemeCodeUnspsc190501.50426226'),
	'identifierSchemeCodeUnspsc190501.50426227': __('identifierSchemeCodeUnspsc190501.50426227'),
	'identifierSchemeCodeUnspsc190501.50426228': __('identifierSchemeCodeUnspsc190501.50426228'),
	'identifierSchemeCodeUnspsc190501.50426229': __('identifierSchemeCodeUnspsc190501.50426229'),
	'identifierSchemeCodeUnspsc190501.50426300': __('identifierSchemeCodeUnspsc190501.50426300'),
	'identifierSchemeCodeUnspsc190501.50426301': __('identifierSchemeCodeUnspsc190501.50426301'),
	'identifierSchemeCodeUnspsc190501.50426302': __('identifierSchemeCodeUnspsc190501.50426302'),
	'identifierSchemeCodeUnspsc190501.50426303': __('identifierSchemeCodeUnspsc190501.50426303'),
	'identifierSchemeCodeUnspsc190501.50426304': __('identifierSchemeCodeUnspsc190501.50426304'),
	'identifierSchemeCodeUnspsc190501.50426305': __('identifierSchemeCodeUnspsc190501.50426305'),
	'identifierSchemeCodeUnspsc190501.50426306': __('identifierSchemeCodeUnspsc190501.50426306'),
	'identifierSchemeCodeUnspsc190501.50426307': __('identifierSchemeCodeUnspsc190501.50426307'),
	'identifierSchemeCodeUnspsc190501.50426308': __('identifierSchemeCodeUnspsc190501.50426308'),
	'identifierSchemeCodeUnspsc190501.50426309': __('identifierSchemeCodeUnspsc190501.50426309'),
	'identifierSchemeCodeUnspsc190501.50426310': __('identifierSchemeCodeUnspsc190501.50426310'),
	'identifierSchemeCodeUnspsc190501.50426311': __('identifierSchemeCodeUnspsc190501.50426311'),
	'identifierSchemeCodeUnspsc190501.50426312': __('identifierSchemeCodeUnspsc190501.50426312'),
	'identifierSchemeCodeUnspsc190501.50426313': __('identifierSchemeCodeUnspsc190501.50426313'),
	'identifierSchemeCodeUnspsc190501.50426314': __('identifierSchemeCodeUnspsc190501.50426314'),
	'identifierSchemeCodeUnspsc190501.50426315': __('identifierSchemeCodeUnspsc190501.50426315'),
	'identifierSchemeCodeUnspsc190501.50426316': __('identifierSchemeCodeUnspsc190501.50426316'),
	'identifierSchemeCodeUnspsc190501.50426317': __('identifierSchemeCodeUnspsc190501.50426317'),
	'identifierSchemeCodeUnspsc190501.50426318': __('identifierSchemeCodeUnspsc190501.50426318'),
	'identifierSchemeCodeUnspsc190501.50426319': __('identifierSchemeCodeUnspsc190501.50426319'),
	'identifierSchemeCodeUnspsc190501.50426320': __('identifierSchemeCodeUnspsc190501.50426320'),
	'identifierSchemeCodeUnspsc190501.50426400': __('identifierSchemeCodeUnspsc190501.50426400'),
	'identifierSchemeCodeUnspsc190501.50426401': __('identifierSchemeCodeUnspsc190501.50426401'),
	'identifierSchemeCodeUnspsc190501.50426402': __('identifierSchemeCodeUnspsc190501.50426402'),
	'identifierSchemeCodeUnspsc190501.50426403': __('identifierSchemeCodeUnspsc190501.50426403'),
	'identifierSchemeCodeUnspsc190501.50426404': __('identifierSchemeCodeUnspsc190501.50426404'),
	'identifierSchemeCodeUnspsc190501.50426405': __('identifierSchemeCodeUnspsc190501.50426405'),
	'identifierSchemeCodeUnspsc190501.50426406': __('identifierSchemeCodeUnspsc190501.50426406'),
	'identifierSchemeCodeUnspsc190501.50426407': __('identifierSchemeCodeUnspsc190501.50426407'),
	'identifierSchemeCodeUnspsc190501.50426408': __('identifierSchemeCodeUnspsc190501.50426408'),
	'identifierSchemeCodeUnspsc190501.50426409': __('identifierSchemeCodeUnspsc190501.50426409'),
	'identifierSchemeCodeUnspsc190501.50426410': __('identifierSchemeCodeUnspsc190501.50426410'),
	'identifierSchemeCodeUnspsc190501.50426411': __('identifierSchemeCodeUnspsc190501.50426411'),
	'identifierSchemeCodeUnspsc190501.50426412': __('identifierSchemeCodeUnspsc190501.50426412'),
	'identifierSchemeCodeUnspsc190501.50426413': __('identifierSchemeCodeUnspsc190501.50426413'),
	'identifierSchemeCodeUnspsc190501.50426414': __('identifierSchemeCodeUnspsc190501.50426414'),
	'identifierSchemeCodeUnspsc190501.50426415': __('identifierSchemeCodeUnspsc190501.50426415'),
	'identifierSchemeCodeUnspsc190501.50426416': __('identifierSchemeCodeUnspsc190501.50426416'),
	'identifierSchemeCodeUnspsc190501.50426417': __('identifierSchemeCodeUnspsc190501.50426417'),
	'identifierSchemeCodeUnspsc190501.50426418': __('identifierSchemeCodeUnspsc190501.50426418'),
	'identifierSchemeCodeUnspsc190501.50426419': __('identifierSchemeCodeUnspsc190501.50426419'),
	'identifierSchemeCodeUnspsc190501.50426420': __('identifierSchemeCodeUnspsc190501.50426420'),
	'identifierSchemeCodeUnspsc190501.50426421': __('identifierSchemeCodeUnspsc190501.50426421'),
	'identifierSchemeCodeUnspsc190501.50426422': __('identifierSchemeCodeUnspsc190501.50426422'),
	'identifierSchemeCodeUnspsc190501.50426423': __('identifierSchemeCodeUnspsc190501.50426423'),
	'identifierSchemeCodeUnspsc190501.50426424': __('identifierSchemeCodeUnspsc190501.50426424'),
	'identifierSchemeCodeUnspsc190501.50426425': __('identifierSchemeCodeUnspsc190501.50426425'),
	'identifierSchemeCodeUnspsc190501.50426426': __('identifierSchemeCodeUnspsc190501.50426426'),
	'identifierSchemeCodeUnspsc190501.50426427': __('identifierSchemeCodeUnspsc190501.50426427'),
	'identifierSchemeCodeUnspsc190501.50426500': __('identifierSchemeCodeUnspsc190501.50426500'),
	'identifierSchemeCodeUnspsc190501.50426501': __('identifierSchemeCodeUnspsc190501.50426501'),
	'identifierSchemeCodeUnspsc190501.50426502': __('identifierSchemeCodeUnspsc190501.50426502'),
	'identifierSchemeCodeUnspsc190501.50426503': __('identifierSchemeCodeUnspsc190501.50426503'),
	'identifierSchemeCodeUnspsc190501.50426504': __('identifierSchemeCodeUnspsc190501.50426504'),
	'identifierSchemeCodeUnspsc190501.50426505': __('identifierSchemeCodeUnspsc190501.50426505'),
	'identifierSchemeCodeUnspsc190501.50426506': __('identifierSchemeCodeUnspsc190501.50426506'),
	'identifierSchemeCodeUnspsc190501.50426507': __('identifierSchemeCodeUnspsc190501.50426507'),
	'identifierSchemeCodeUnspsc190501.50426508': __('identifierSchemeCodeUnspsc190501.50426508'),
	'identifierSchemeCodeUnspsc190501.50426600': __('identifierSchemeCodeUnspsc190501.50426600'),
	'identifierSchemeCodeUnspsc190501.50426601': __('identifierSchemeCodeUnspsc190501.50426601'),
	'identifierSchemeCodeUnspsc190501.50426602': __('identifierSchemeCodeUnspsc190501.50426602'),
	'identifierSchemeCodeUnspsc190501.50426603': __('identifierSchemeCodeUnspsc190501.50426603'),
	'identifierSchemeCodeUnspsc190501.50426604': __('identifierSchemeCodeUnspsc190501.50426604'),
	'identifierSchemeCodeUnspsc190501.50426605': __('identifierSchemeCodeUnspsc190501.50426605'),
	'identifierSchemeCodeUnspsc190501.50426606': __('identifierSchemeCodeUnspsc190501.50426606'),
	'identifierSchemeCodeUnspsc190501.50426607': __('identifierSchemeCodeUnspsc190501.50426607'),
	'identifierSchemeCodeUnspsc190501.50426608': __('identifierSchemeCodeUnspsc190501.50426608'),
	'identifierSchemeCodeUnspsc190501.50426609': __('identifierSchemeCodeUnspsc190501.50426609'),
	'identifierSchemeCodeUnspsc190501.50426610': __('identifierSchemeCodeUnspsc190501.50426610'),
	'identifierSchemeCodeUnspsc190501.50426611': __('identifierSchemeCodeUnspsc190501.50426611'),
	'identifierSchemeCodeUnspsc190501.50426612': __('identifierSchemeCodeUnspsc190501.50426612'),
	'identifierSchemeCodeUnspsc190501.50426613': __('identifierSchemeCodeUnspsc190501.50426613'),
	'identifierSchemeCodeUnspsc190501.50426614': __('identifierSchemeCodeUnspsc190501.50426614'),
	'identifierSchemeCodeUnspsc190501.50426615': __('identifierSchemeCodeUnspsc190501.50426615'),
	'identifierSchemeCodeUnspsc190501.50426616': __('identifierSchemeCodeUnspsc190501.50426616'),
	'identifierSchemeCodeUnspsc190501.50426617': __('identifierSchemeCodeUnspsc190501.50426617'),
	'identifierSchemeCodeUnspsc190501.50426618': __('identifierSchemeCodeUnspsc190501.50426618'),
	'identifierSchemeCodeUnspsc190501.50426619': __('identifierSchemeCodeUnspsc190501.50426619'),
	'identifierSchemeCodeUnspsc190501.50426620': __('identifierSchemeCodeUnspsc190501.50426620'),
	'identifierSchemeCodeUnspsc190501.50426700': __('identifierSchemeCodeUnspsc190501.50426700'),
	'identifierSchemeCodeUnspsc190501.50426701': __('identifierSchemeCodeUnspsc190501.50426701'),
	'identifierSchemeCodeUnspsc190501.50426702': __('identifierSchemeCodeUnspsc190501.50426702'),
	'identifierSchemeCodeUnspsc190501.50426703': __('identifierSchemeCodeUnspsc190501.50426703'),
	'identifierSchemeCodeUnspsc190501.50426704': __('identifierSchemeCodeUnspsc190501.50426704'),
	'identifierSchemeCodeUnspsc190501.50426705': __('identifierSchemeCodeUnspsc190501.50426705'),
	'identifierSchemeCodeUnspsc190501.50426706': __('identifierSchemeCodeUnspsc190501.50426706'),
	'identifierSchemeCodeUnspsc190501.50426707': __('identifierSchemeCodeUnspsc190501.50426707'),
	'identifierSchemeCodeUnspsc190501.50426708': __('identifierSchemeCodeUnspsc190501.50426708'),
	'identifierSchemeCodeUnspsc190501.50426709': __('identifierSchemeCodeUnspsc190501.50426709'),
	'identifierSchemeCodeUnspsc190501.50426710': __('identifierSchemeCodeUnspsc190501.50426710'),
	'identifierSchemeCodeUnspsc190501.50426800': __('identifierSchemeCodeUnspsc190501.50426800'),
	'identifierSchemeCodeUnspsc190501.50426801': __('identifierSchemeCodeUnspsc190501.50426801'),
	'identifierSchemeCodeUnspsc190501.50426802': __('identifierSchemeCodeUnspsc190501.50426802'),
	'identifierSchemeCodeUnspsc190501.50426803': __('identifierSchemeCodeUnspsc190501.50426803'),
	'identifierSchemeCodeUnspsc190501.50426804': __('identifierSchemeCodeUnspsc190501.50426804'),
	'identifierSchemeCodeUnspsc190501.50426805': __('identifierSchemeCodeUnspsc190501.50426805'),
	'identifierSchemeCodeUnspsc190501.50426806': __('identifierSchemeCodeUnspsc190501.50426806'),
	'identifierSchemeCodeUnspsc190501.50426807': __('identifierSchemeCodeUnspsc190501.50426807'),
	'identifierSchemeCodeUnspsc190501.50426808': __('identifierSchemeCodeUnspsc190501.50426808'),
	'identifierSchemeCodeUnspsc190501.50426809': __('identifierSchemeCodeUnspsc190501.50426809'),
	'identifierSchemeCodeUnspsc190501.50426810': __('identifierSchemeCodeUnspsc190501.50426810'),
	'identifierSchemeCodeUnspsc190501.50426811': __('identifierSchemeCodeUnspsc190501.50426811'),
	'identifierSchemeCodeUnspsc190501.50426812': __('identifierSchemeCodeUnspsc190501.50426812'),
	'identifierSchemeCodeUnspsc190501.50426813': __('identifierSchemeCodeUnspsc190501.50426813'),
	'identifierSchemeCodeUnspsc190501.50426814': __('identifierSchemeCodeUnspsc190501.50426814'),
	'identifierSchemeCodeUnspsc190501.50426815': __('identifierSchemeCodeUnspsc190501.50426815'),
	'identifierSchemeCodeUnspsc190501.50426816': __('identifierSchemeCodeUnspsc190501.50426816'),
	'identifierSchemeCodeUnspsc190501.50426817': __('identifierSchemeCodeUnspsc190501.50426817'),
	'identifierSchemeCodeUnspsc190501.50426818': __('identifierSchemeCodeUnspsc190501.50426818'),
	'identifierSchemeCodeUnspsc190501.50426819': __('identifierSchemeCodeUnspsc190501.50426819'),
	'identifierSchemeCodeUnspsc190501.50426820': __('identifierSchemeCodeUnspsc190501.50426820'),
	'identifierSchemeCodeUnspsc190501.50426821': __('identifierSchemeCodeUnspsc190501.50426821'),
	'identifierSchemeCodeUnspsc190501.50426822': __('identifierSchemeCodeUnspsc190501.50426822'),
	'identifierSchemeCodeUnspsc190501.50426823': __('identifierSchemeCodeUnspsc190501.50426823'),
	'identifierSchemeCodeUnspsc190501.50426824': __('identifierSchemeCodeUnspsc190501.50426824'),
	'identifierSchemeCodeUnspsc190501.50426825': __('identifierSchemeCodeUnspsc190501.50426825'),
	'identifierSchemeCodeUnspsc190501.50426826': __('identifierSchemeCodeUnspsc190501.50426826'),
	'identifierSchemeCodeUnspsc190501.50426827': __('identifierSchemeCodeUnspsc190501.50426827'),
	'identifierSchemeCodeUnspsc190501.50426828': __('identifierSchemeCodeUnspsc190501.50426828'),
	'identifierSchemeCodeUnspsc190501.50426829': __('identifierSchemeCodeUnspsc190501.50426829'),
	'identifierSchemeCodeUnspsc190501.50426830': __('identifierSchemeCodeUnspsc190501.50426830'),
	'identifierSchemeCodeUnspsc190501.50426831': __('identifierSchemeCodeUnspsc190501.50426831'),
	'identifierSchemeCodeUnspsc190501.50426832': __('identifierSchemeCodeUnspsc190501.50426832'),
	'identifierSchemeCodeUnspsc190501.50426833': __('identifierSchemeCodeUnspsc190501.50426833'),
	'identifierSchemeCodeUnspsc190501.50426834': __('identifierSchemeCodeUnspsc190501.50426834'),
	'identifierSchemeCodeUnspsc190501.50426835': __('identifierSchemeCodeUnspsc190501.50426835'),
	'identifierSchemeCodeUnspsc190501.50426836': __('identifierSchemeCodeUnspsc190501.50426836'),
	'identifierSchemeCodeUnspsc190501.50426837': __('identifierSchemeCodeUnspsc190501.50426837'),
	'identifierSchemeCodeUnspsc190501.50426838': __('identifierSchemeCodeUnspsc190501.50426838'),
	'identifierSchemeCodeUnspsc190501.50426839': __('identifierSchemeCodeUnspsc190501.50426839'),
	'identifierSchemeCodeUnspsc190501.50426840': __('identifierSchemeCodeUnspsc190501.50426840'),
	'identifierSchemeCodeUnspsc190501.50426841': __('identifierSchemeCodeUnspsc190501.50426841'),
	'identifierSchemeCodeUnspsc190501.50426842': __('identifierSchemeCodeUnspsc190501.50426842'),
	'identifierSchemeCodeUnspsc190501.50426843': __('identifierSchemeCodeUnspsc190501.50426843'),
	'identifierSchemeCodeUnspsc190501.50426844': __('identifierSchemeCodeUnspsc190501.50426844'),
	'identifierSchemeCodeUnspsc190501.50426845': __('identifierSchemeCodeUnspsc190501.50426845'),
	'identifierSchemeCodeUnspsc190501.50426846': __('identifierSchemeCodeUnspsc190501.50426846'),
	'identifierSchemeCodeUnspsc190501.50426847': __('identifierSchemeCodeUnspsc190501.50426847'),
	'identifierSchemeCodeUnspsc190501.50426848': __('identifierSchemeCodeUnspsc190501.50426848'),
	'identifierSchemeCodeUnspsc190501.50426849': __('identifierSchemeCodeUnspsc190501.50426849'),
	'identifierSchemeCodeUnspsc190501.50426850': __('identifierSchemeCodeUnspsc190501.50426850'),
	'identifierSchemeCodeUnspsc190501.50426851': __('identifierSchemeCodeUnspsc190501.50426851'),
	'identifierSchemeCodeUnspsc190501.50426852': __('identifierSchemeCodeUnspsc190501.50426852'),
	'identifierSchemeCodeUnspsc190501.50426853': __('identifierSchemeCodeUnspsc190501.50426853'),
	'identifierSchemeCodeUnspsc190501.50426854': __('identifierSchemeCodeUnspsc190501.50426854'),
	'identifierSchemeCodeUnspsc190501.50426855': __('identifierSchemeCodeUnspsc190501.50426855'),
	'identifierSchemeCodeUnspsc190501.50426856': __('identifierSchemeCodeUnspsc190501.50426856'),
	'identifierSchemeCodeUnspsc190501.50426857': __('identifierSchemeCodeUnspsc190501.50426857'),
	'identifierSchemeCodeUnspsc190501.50426858': __('identifierSchemeCodeUnspsc190501.50426858'),
	'identifierSchemeCodeUnspsc190501.50426859': __('identifierSchemeCodeUnspsc190501.50426859'),
	'identifierSchemeCodeUnspsc190501.50426860': __('identifierSchemeCodeUnspsc190501.50426860'),
	'identifierSchemeCodeUnspsc190501.50426861': __('identifierSchemeCodeUnspsc190501.50426861'),
	'identifierSchemeCodeUnspsc190501.50426862': __('identifierSchemeCodeUnspsc190501.50426862'),
	'identifierSchemeCodeUnspsc190501.50426863': __('identifierSchemeCodeUnspsc190501.50426863'),
	'identifierSchemeCodeUnspsc190501.50426864': __('identifierSchemeCodeUnspsc190501.50426864'),
	'identifierSchemeCodeUnspsc190501.50426865': __('identifierSchemeCodeUnspsc190501.50426865'),
	'identifierSchemeCodeUnspsc190501.50426866': __('identifierSchemeCodeUnspsc190501.50426866'),
	'identifierSchemeCodeUnspsc190501.50426867': __('identifierSchemeCodeUnspsc190501.50426867'),
	'identifierSchemeCodeUnspsc190501.50426868': __('identifierSchemeCodeUnspsc190501.50426868'),
	'identifierSchemeCodeUnspsc190501.50426900': __('identifierSchemeCodeUnspsc190501.50426900'),
	'identifierSchemeCodeUnspsc190501.50426901': __('identifierSchemeCodeUnspsc190501.50426901'),
	'identifierSchemeCodeUnspsc190501.50426902': __('identifierSchemeCodeUnspsc190501.50426902'),
	'identifierSchemeCodeUnspsc190501.50426903': __('identifierSchemeCodeUnspsc190501.50426903'),
	'identifierSchemeCodeUnspsc190501.50426904': __('identifierSchemeCodeUnspsc190501.50426904'),
	'identifierSchemeCodeUnspsc190501.50426905': __('identifierSchemeCodeUnspsc190501.50426905'),
	'identifierSchemeCodeUnspsc190501.50426906': __('identifierSchemeCodeUnspsc190501.50426906'),
	'identifierSchemeCodeUnspsc190501.50426907': __('identifierSchemeCodeUnspsc190501.50426907'),
	'identifierSchemeCodeUnspsc190501.50426908': __('identifierSchemeCodeUnspsc190501.50426908'),
	'identifierSchemeCodeUnspsc190501.50426909': __('identifierSchemeCodeUnspsc190501.50426909'),
	'identifierSchemeCodeUnspsc190501.50426910': __('identifierSchemeCodeUnspsc190501.50426910'),
	'identifierSchemeCodeUnspsc190501.50430000': __('identifierSchemeCodeUnspsc190501.50430000'),
	'identifierSchemeCodeUnspsc190501.50431500': __('identifierSchemeCodeUnspsc190501.50431500'),
	'identifierSchemeCodeUnspsc190501.50431501': __('identifierSchemeCodeUnspsc190501.50431501'),
	'identifierSchemeCodeUnspsc190501.50431502': __('identifierSchemeCodeUnspsc190501.50431502'),
	'identifierSchemeCodeUnspsc190501.50431503': __('identifierSchemeCodeUnspsc190501.50431503'),
	'identifierSchemeCodeUnspsc190501.50431504': __('identifierSchemeCodeUnspsc190501.50431504'),
	'identifierSchemeCodeUnspsc190501.50431505': __('identifierSchemeCodeUnspsc190501.50431505'),
	'identifierSchemeCodeUnspsc190501.50431506': __('identifierSchemeCodeUnspsc190501.50431506'),
	'identifierSchemeCodeUnspsc190501.50431507': __('identifierSchemeCodeUnspsc190501.50431507'),
	'identifierSchemeCodeUnspsc190501.50431508': __('identifierSchemeCodeUnspsc190501.50431508'),
	'identifierSchemeCodeUnspsc190501.50431509': __('identifierSchemeCodeUnspsc190501.50431509'),
	'identifierSchemeCodeUnspsc190501.50431510': __('identifierSchemeCodeUnspsc190501.50431510'),
	'identifierSchemeCodeUnspsc190501.50431511': __('identifierSchemeCodeUnspsc190501.50431511'),
	'identifierSchemeCodeUnspsc190501.50431512': __('identifierSchemeCodeUnspsc190501.50431512'),
	'identifierSchemeCodeUnspsc190501.50431513': __('identifierSchemeCodeUnspsc190501.50431513'),
	'identifierSchemeCodeUnspsc190501.50431600': __('identifierSchemeCodeUnspsc190501.50431600'),
	'identifierSchemeCodeUnspsc190501.50431601': __('identifierSchemeCodeUnspsc190501.50431601'),
	'identifierSchemeCodeUnspsc190501.50431602': __('identifierSchemeCodeUnspsc190501.50431602'),
	'identifierSchemeCodeUnspsc190501.50431603': __('identifierSchemeCodeUnspsc190501.50431603'),
	'identifierSchemeCodeUnspsc190501.50431604': __('identifierSchemeCodeUnspsc190501.50431604'),
	'identifierSchemeCodeUnspsc190501.50431605': __('identifierSchemeCodeUnspsc190501.50431605'),
	'identifierSchemeCodeUnspsc190501.50431700': __('identifierSchemeCodeUnspsc190501.50431700'),
	'identifierSchemeCodeUnspsc190501.50431701': __('identifierSchemeCodeUnspsc190501.50431701'),
	'identifierSchemeCodeUnspsc190501.50431702': __('identifierSchemeCodeUnspsc190501.50431702'),
	'identifierSchemeCodeUnspsc190501.50431703': __('identifierSchemeCodeUnspsc190501.50431703'),
	'identifierSchemeCodeUnspsc190501.50431704': __('identifierSchemeCodeUnspsc190501.50431704'),
	'identifierSchemeCodeUnspsc190501.50431705': __('identifierSchemeCodeUnspsc190501.50431705'),
	'identifierSchemeCodeUnspsc190501.50431706': __('identifierSchemeCodeUnspsc190501.50431706'),
	'identifierSchemeCodeUnspsc190501.50431707': __('identifierSchemeCodeUnspsc190501.50431707'),
	'identifierSchemeCodeUnspsc190501.50431708': __('identifierSchemeCodeUnspsc190501.50431708'),
	'identifierSchemeCodeUnspsc190501.50431709': __('identifierSchemeCodeUnspsc190501.50431709'),
	'identifierSchemeCodeUnspsc190501.50431710': __('identifierSchemeCodeUnspsc190501.50431710'),
	'identifierSchemeCodeUnspsc190501.50431711': __('identifierSchemeCodeUnspsc190501.50431711'),
	'identifierSchemeCodeUnspsc190501.50431712': __('identifierSchemeCodeUnspsc190501.50431712'),
	'identifierSchemeCodeUnspsc190501.50431713': __('identifierSchemeCodeUnspsc190501.50431713'),
	'identifierSchemeCodeUnspsc190501.50431714': __('identifierSchemeCodeUnspsc190501.50431714'),
	'identifierSchemeCodeUnspsc190501.50431715': __('identifierSchemeCodeUnspsc190501.50431715'),
	'identifierSchemeCodeUnspsc190501.50431716': __('identifierSchemeCodeUnspsc190501.50431716'),
	'identifierSchemeCodeUnspsc190501.50431717': __('identifierSchemeCodeUnspsc190501.50431717'),
	'identifierSchemeCodeUnspsc190501.50431718': __('identifierSchemeCodeUnspsc190501.50431718'),
	'identifierSchemeCodeUnspsc190501.50431719': __('identifierSchemeCodeUnspsc190501.50431719'),
	'identifierSchemeCodeUnspsc190501.50431720': __('identifierSchemeCodeUnspsc190501.50431720'),
	'identifierSchemeCodeUnspsc190501.50431721': __('identifierSchemeCodeUnspsc190501.50431721'),
	'identifierSchemeCodeUnspsc190501.50431722': __('identifierSchemeCodeUnspsc190501.50431722'),
	'identifierSchemeCodeUnspsc190501.50431723': __('identifierSchemeCodeUnspsc190501.50431723'),
	'identifierSchemeCodeUnspsc190501.50431724': __('identifierSchemeCodeUnspsc190501.50431724'),
	'identifierSchemeCodeUnspsc190501.50431725': __('identifierSchemeCodeUnspsc190501.50431725'),
	'identifierSchemeCodeUnspsc190501.50431726': __('identifierSchemeCodeUnspsc190501.50431726'),
	'identifierSchemeCodeUnspsc190501.50431727': __('identifierSchemeCodeUnspsc190501.50431727'),
	'identifierSchemeCodeUnspsc190501.50431728': __('identifierSchemeCodeUnspsc190501.50431728'),
	'identifierSchemeCodeUnspsc190501.50431729': __('identifierSchemeCodeUnspsc190501.50431729'),
	'identifierSchemeCodeUnspsc190501.50431730': __('identifierSchemeCodeUnspsc190501.50431730'),
	'identifierSchemeCodeUnspsc190501.50431731': __('identifierSchemeCodeUnspsc190501.50431731'),
	'identifierSchemeCodeUnspsc190501.50431732': __('identifierSchemeCodeUnspsc190501.50431732'),
	'identifierSchemeCodeUnspsc190501.50431733': __('identifierSchemeCodeUnspsc190501.50431733'),
	'identifierSchemeCodeUnspsc190501.50431734': __('identifierSchemeCodeUnspsc190501.50431734'),
	'identifierSchemeCodeUnspsc190501.50431735': __('identifierSchemeCodeUnspsc190501.50431735'),
	'identifierSchemeCodeUnspsc190501.50431736': __('identifierSchemeCodeUnspsc190501.50431736'),
	'identifierSchemeCodeUnspsc190501.50431737': __('identifierSchemeCodeUnspsc190501.50431737'),
	'identifierSchemeCodeUnspsc190501.50431738': __('identifierSchemeCodeUnspsc190501.50431738'),
	'identifierSchemeCodeUnspsc190501.50431739': __('identifierSchemeCodeUnspsc190501.50431739'),
	'identifierSchemeCodeUnspsc190501.50431740': __('identifierSchemeCodeUnspsc190501.50431740'),
	'identifierSchemeCodeUnspsc190501.50431741': __('identifierSchemeCodeUnspsc190501.50431741'),
	'identifierSchemeCodeUnspsc190501.50431742': __('identifierSchemeCodeUnspsc190501.50431742'),
	'identifierSchemeCodeUnspsc190501.50431743': __('identifierSchemeCodeUnspsc190501.50431743'),
	'identifierSchemeCodeUnspsc190501.50431744': __('identifierSchemeCodeUnspsc190501.50431744'),
	'identifierSchemeCodeUnspsc190501.50431745': __('identifierSchemeCodeUnspsc190501.50431745'),
	'identifierSchemeCodeUnspsc190501.50431746': __('identifierSchemeCodeUnspsc190501.50431746'),
	'identifierSchemeCodeUnspsc190501.50431747': __('identifierSchemeCodeUnspsc190501.50431747'),
	'identifierSchemeCodeUnspsc190501.50431748': __('identifierSchemeCodeUnspsc190501.50431748'),
	'identifierSchemeCodeUnspsc190501.50431749': __('identifierSchemeCodeUnspsc190501.50431749'),
	'identifierSchemeCodeUnspsc190501.50431750': __('identifierSchemeCodeUnspsc190501.50431750'),
	'identifierSchemeCodeUnspsc190501.50431751': __('identifierSchemeCodeUnspsc190501.50431751'),
	'identifierSchemeCodeUnspsc190501.50431752': __('identifierSchemeCodeUnspsc190501.50431752'),
	'identifierSchemeCodeUnspsc190501.50431753': __('identifierSchemeCodeUnspsc190501.50431753'),
	'identifierSchemeCodeUnspsc190501.50431754': __('identifierSchemeCodeUnspsc190501.50431754'),
	'identifierSchemeCodeUnspsc190501.50431755': __('identifierSchemeCodeUnspsc190501.50431755'),
	'identifierSchemeCodeUnspsc190501.50431756': __('identifierSchemeCodeUnspsc190501.50431756'),
	'identifierSchemeCodeUnspsc190501.50431757': __('identifierSchemeCodeUnspsc190501.50431757'),
	'identifierSchemeCodeUnspsc190501.50431758': __('identifierSchemeCodeUnspsc190501.50431758'),
	'identifierSchemeCodeUnspsc190501.50431759': __('identifierSchemeCodeUnspsc190501.50431759'),
	'identifierSchemeCodeUnspsc190501.50431760': __('identifierSchemeCodeUnspsc190501.50431760'),
	'identifierSchemeCodeUnspsc190501.50431761': __('identifierSchemeCodeUnspsc190501.50431761'),
	'identifierSchemeCodeUnspsc190501.50431762': __('identifierSchemeCodeUnspsc190501.50431762'),
	'identifierSchemeCodeUnspsc190501.50431763': __('identifierSchemeCodeUnspsc190501.50431763'),
	'identifierSchemeCodeUnspsc190501.50431764': __('identifierSchemeCodeUnspsc190501.50431764'),
	'identifierSchemeCodeUnspsc190501.50431765': __('identifierSchemeCodeUnspsc190501.50431765'),
	'identifierSchemeCodeUnspsc190501.50431766': __('identifierSchemeCodeUnspsc190501.50431766'),
	'identifierSchemeCodeUnspsc190501.50431767': __('identifierSchemeCodeUnspsc190501.50431767'),
	'identifierSchemeCodeUnspsc190501.50431768': __('identifierSchemeCodeUnspsc190501.50431768'),
	'identifierSchemeCodeUnspsc190501.50431769': __('identifierSchemeCodeUnspsc190501.50431769'),
	'identifierSchemeCodeUnspsc190501.50431770': __('identifierSchemeCodeUnspsc190501.50431770'),
	'identifierSchemeCodeUnspsc190501.50431771': __('identifierSchemeCodeUnspsc190501.50431771'),
	'identifierSchemeCodeUnspsc190501.50431772': __('identifierSchemeCodeUnspsc190501.50431772'),
	'identifierSchemeCodeUnspsc190501.50431773': __('identifierSchemeCodeUnspsc190501.50431773'),
	'identifierSchemeCodeUnspsc190501.50431774': __('identifierSchemeCodeUnspsc190501.50431774'),
	'identifierSchemeCodeUnspsc190501.50431775': __('identifierSchemeCodeUnspsc190501.50431775'),
	'identifierSchemeCodeUnspsc190501.50431776': __('identifierSchemeCodeUnspsc190501.50431776'),
	'identifierSchemeCodeUnspsc190501.50431777': __('identifierSchemeCodeUnspsc190501.50431777'),
	'identifierSchemeCodeUnspsc190501.50431778': __('identifierSchemeCodeUnspsc190501.50431778'),
	'identifierSchemeCodeUnspsc190501.50431779': __('identifierSchemeCodeUnspsc190501.50431779'),
	'identifierSchemeCodeUnspsc190501.50431780': __('identifierSchemeCodeUnspsc190501.50431780'),
	'identifierSchemeCodeUnspsc190501.50431781': __('identifierSchemeCodeUnspsc190501.50431781'),
	'identifierSchemeCodeUnspsc190501.50431782': __('identifierSchemeCodeUnspsc190501.50431782'),
	'identifierSchemeCodeUnspsc190501.50431800': __('identifierSchemeCodeUnspsc190501.50431800'),
	'identifierSchemeCodeUnspsc190501.50431801': __('identifierSchemeCodeUnspsc190501.50431801'),
	'identifierSchemeCodeUnspsc190501.50431802': __('identifierSchemeCodeUnspsc190501.50431802'),
	'identifierSchemeCodeUnspsc190501.50431803': __('identifierSchemeCodeUnspsc190501.50431803'),
	'identifierSchemeCodeUnspsc190501.50431804': __('identifierSchemeCodeUnspsc190501.50431804'),
	'identifierSchemeCodeUnspsc190501.50431805': __('identifierSchemeCodeUnspsc190501.50431805'),
	'identifierSchemeCodeUnspsc190501.50431806': __('identifierSchemeCodeUnspsc190501.50431806'),
	'identifierSchemeCodeUnspsc190501.50431807': __('identifierSchemeCodeUnspsc190501.50431807'),
	'identifierSchemeCodeUnspsc190501.50431808': __('identifierSchemeCodeUnspsc190501.50431808'),
	'identifierSchemeCodeUnspsc190501.50431809': __('identifierSchemeCodeUnspsc190501.50431809'),
	'identifierSchemeCodeUnspsc190501.50431810': __('identifierSchemeCodeUnspsc190501.50431810'),
	'identifierSchemeCodeUnspsc190501.50431811': __('identifierSchemeCodeUnspsc190501.50431811'),
	'identifierSchemeCodeUnspsc190501.50431812': __('identifierSchemeCodeUnspsc190501.50431812'),
	'identifierSchemeCodeUnspsc190501.50431813': __('identifierSchemeCodeUnspsc190501.50431813'),
	'identifierSchemeCodeUnspsc190501.50431814': __('identifierSchemeCodeUnspsc190501.50431814'),
	'identifierSchemeCodeUnspsc190501.50431815': __('identifierSchemeCodeUnspsc190501.50431815'),
	'identifierSchemeCodeUnspsc190501.50431816': __('identifierSchemeCodeUnspsc190501.50431816'),
	'identifierSchemeCodeUnspsc190501.50431817': __('identifierSchemeCodeUnspsc190501.50431817'),
	'identifierSchemeCodeUnspsc190501.50431818': __('identifierSchemeCodeUnspsc190501.50431818'),
	'identifierSchemeCodeUnspsc190501.50431819': __('identifierSchemeCodeUnspsc190501.50431819'),
	'identifierSchemeCodeUnspsc190501.50431820': __('identifierSchemeCodeUnspsc190501.50431820'),
	'identifierSchemeCodeUnspsc190501.50431821': __('identifierSchemeCodeUnspsc190501.50431821'),
	'identifierSchemeCodeUnspsc190501.50431822': __('identifierSchemeCodeUnspsc190501.50431822'),
	'identifierSchemeCodeUnspsc190501.50431823': __('identifierSchemeCodeUnspsc190501.50431823'),
	'identifierSchemeCodeUnspsc190501.50431824': __('identifierSchemeCodeUnspsc190501.50431824'),
	'identifierSchemeCodeUnspsc190501.50431825': __('identifierSchemeCodeUnspsc190501.50431825'),
	'identifierSchemeCodeUnspsc190501.50431826': __('identifierSchemeCodeUnspsc190501.50431826'),
	'identifierSchemeCodeUnspsc190501.50431827': __('identifierSchemeCodeUnspsc190501.50431827'),
	'identifierSchemeCodeUnspsc190501.50431828': __('identifierSchemeCodeUnspsc190501.50431828'),
	'identifierSchemeCodeUnspsc190501.50431829': __('identifierSchemeCodeUnspsc190501.50431829'),
	'identifierSchemeCodeUnspsc190501.50431830': __('identifierSchemeCodeUnspsc190501.50431830'),
	'identifierSchemeCodeUnspsc190501.50431831': __('identifierSchemeCodeUnspsc190501.50431831'),
	'identifierSchemeCodeUnspsc190501.50431832': __('identifierSchemeCodeUnspsc190501.50431832'),
	'identifierSchemeCodeUnspsc190501.50431833': __('identifierSchemeCodeUnspsc190501.50431833'),
	'identifierSchemeCodeUnspsc190501.50431834': __('identifierSchemeCodeUnspsc190501.50431834'),
	'identifierSchemeCodeUnspsc190501.50431835': __('identifierSchemeCodeUnspsc190501.50431835'),
	'identifierSchemeCodeUnspsc190501.50431836': __('identifierSchemeCodeUnspsc190501.50431836'),
	'identifierSchemeCodeUnspsc190501.50431837': __('identifierSchemeCodeUnspsc190501.50431837'),
	'identifierSchemeCodeUnspsc190501.50431838': __('identifierSchemeCodeUnspsc190501.50431838'),
	'identifierSchemeCodeUnspsc190501.50431839': __('identifierSchemeCodeUnspsc190501.50431839'),
	'identifierSchemeCodeUnspsc190501.50431840': __('identifierSchemeCodeUnspsc190501.50431840'),
	'identifierSchemeCodeUnspsc190501.50431841': __('identifierSchemeCodeUnspsc190501.50431841'),
	'identifierSchemeCodeUnspsc190501.50431842': __('identifierSchemeCodeUnspsc190501.50431842'),
	'identifierSchemeCodeUnspsc190501.50431843': __('identifierSchemeCodeUnspsc190501.50431843'),
	'identifierSchemeCodeUnspsc190501.50431844': __('identifierSchemeCodeUnspsc190501.50431844'),
	'identifierSchemeCodeUnspsc190501.50431845': __('identifierSchemeCodeUnspsc190501.50431845'),
	'identifierSchemeCodeUnspsc190501.50431846': __('identifierSchemeCodeUnspsc190501.50431846'),
	'identifierSchemeCodeUnspsc190501.50431847': __('identifierSchemeCodeUnspsc190501.50431847'),
	'identifierSchemeCodeUnspsc190501.50431848': __('identifierSchemeCodeUnspsc190501.50431848'),
	'identifierSchemeCodeUnspsc190501.50431849': __('identifierSchemeCodeUnspsc190501.50431849'),
	'identifierSchemeCodeUnspsc190501.50431850': __('identifierSchemeCodeUnspsc190501.50431850'),
	'identifierSchemeCodeUnspsc190501.50431851': __('identifierSchemeCodeUnspsc190501.50431851'),
	'identifierSchemeCodeUnspsc190501.50431852': __('identifierSchemeCodeUnspsc190501.50431852'),
	'identifierSchemeCodeUnspsc190501.50431900': __('identifierSchemeCodeUnspsc190501.50431900'),
	'identifierSchemeCodeUnspsc190501.50431901': __('identifierSchemeCodeUnspsc190501.50431901'),
	'identifierSchemeCodeUnspsc190501.50431902': __('identifierSchemeCodeUnspsc190501.50431902'),
	'identifierSchemeCodeUnspsc190501.50431903': __('identifierSchemeCodeUnspsc190501.50431903'),
	'identifierSchemeCodeUnspsc190501.50431904': __('identifierSchemeCodeUnspsc190501.50431904'),
	'identifierSchemeCodeUnspsc190501.50431905': __('identifierSchemeCodeUnspsc190501.50431905'),
	'identifierSchemeCodeUnspsc190501.50431906': __('identifierSchemeCodeUnspsc190501.50431906'),
	'identifierSchemeCodeUnspsc190501.50431907': __('identifierSchemeCodeUnspsc190501.50431907'),
	'identifierSchemeCodeUnspsc190501.50431908': __('identifierSchemeCodeUnspsc190501.50431908'),
	'identifierSchemeCodeUnspsc190501.50431909': __('identifierSchemeCodeUnspsc190501.50431909'),
	'identifierSchemeCodeUnspsc190501.50431910': __('identifierSchemeCodeUnspsc190501.50431910'),
	'identifierSchemeCodeUnspsc190501.50431911': __('identifierSchemeCodeUnspsc190501.50431911'),
	'identifierSchemeCodeUnspsc190501.50431912': __('identifierSchemeCodeUnspsc190501.50431912'),
	'identifierSchemeCodeUnspsc190501.50431913': __('identifierSchemeCodeUnspsc190501.50431913'),
	'identifierSchemeCodeUnspsc190501.50431914': __('identifierSchemeCodeUnspsc190501.50431914'),
	'identifierSchemeCodeUnspsc190501.50431915': __('identifierSchemeCodeUnspsc190501.50431915'),
	'identifierSchemeCodeUnspsc190501.50431916': __('identifierSchemeCodeUnspsc190501.50431916'),
	'identifierSchemeCodeUnspsc190501.50431917': __('identifierSchemeCodeUnspsc190501.50431917'),
	'identifierSchemeCodeUnspsc190501.50431918': __('identifierSchemeCodeUnspsc190501.50431918'),
	'identifierSchemeCodeUnspsc190501.50431919': __('identifierSchemeCodeUnspsc190501.50431919'),
	'identifierSchemeCodeUnspsc190501.50431920': __('identifierSchemeCodeUnspsc190501.50431920'),
	'identifierSchemeCodeUnspsc190501.50431921': __('identifierSchemeCodeUnspsc190501.50431921'),
	'identifierSchemeCodeUnspsc190501.50431922': __('identifierSchemeCodeUnspsc190501.50431922'),
	'identifierSchemeCodeUnspsc190501.50432000': __('identifierSchemeCodeUnspsc190501.50432000'),
	'identifierSchemeCodeUnspsc190501.50432001': __('identifierSchemeCodeUnspsc190501.50432001'),
	'identifierSchemeCodeUnspsc190501.50432002': __('identifierSchemeCodeUnspsc190501.50432002'),
	'identifierSchemeCodeUnspsc190501.50432003': __('identifierSchemeCodeUnspsc190501.50432003'),
	'identifierSchemeCodeUnspsc190501.50432004': __('identifierSchemeCodeUnspsc190501.50432004'),
	'identifierSchemeCodeUnspsc190501.50432100': __('identifierSchemeCodeUnspsc190501.50432100'),
	'identifierSchemeCodeUnspsc190501.50432101': __('identifierSchemeCodeUnspsc190501.50432101'),
	'identifierSchemeCodeUnspsc190501.50432102': __('identifierSchemeCodeUnspsc190501.50432102'),
	'identifierSchemeCodeUnspsc190501.50432103': __('identifierSchemeCodeUnspsc190501.50432103'),
	'identifierSchemeCodeUnspsc190501.50432104': __('identifierSchemeCodeUnspsc190501.50432104'),
	'identifierSchemeCodeUnspsc190501.50432105': __('identifierSchemeCodeUnspsc190501.50432105'),
	'identifierSchemeCodeUnspsc190501.50432106': __('identifierSchemeCodeUnspsc190501.50432106'),
	'identifierSchemeCodeUnspsc190501.50432107': __('identifierSchemeCodeUnspsc190501.50432107'),
	'identifierSchemeCodeUnspsc190501.50432200': __('identifierSchemeCodeUnspsc190501.50432200'),
	'identifierSchemeCodeUnspsc190501.50432201': __('identifierSchemeCodeUnspsc190501.50432201'),
	'identifierSchemeCodeUnspsc190501.50432202': __('identifierSchemeCodeUnspsc190501.50432202'),
	'identifierSchemeCodeUnspsc190501.50432203': __('identifierSchemeCodeUnspsc190501.50432203'),
	'identifierSchemeCodeUnspsc190501.50432204': __('identifierSchemeCodeUnspsc190501.50432204'),
	'identifierSchemeCodeUnspsc190501.50432205': __('identifierSchemeCodeUnspsc190501.50432205'),
	'identifierSchemeCodeUnspsc190501.50432206': __('identifierSchemeCodeUnspsc190501.50432206'),
	'identifierSchemeCodeUnspsc190501.50432207': __('identifierSchemeCodeUnspsc190501.50432207'),
	'identifierSchemeCodeUnspsc190501.50432208': __('identifierSchemeCodeUnspsc190501.50432208'),
	'identifierSchemeCodeUnspsc190501.50432209': __('identifierSchemeCodeUnspsc190501.50432209'),
	'identifierSchemeCodeUnspsc190501.50432210': __('identifierSchemeCodeUnspsc190501.50432210'),
	'identifierSchemeCodeUnspsc190501.50432211': __('identifierSchemeCodeUnspsc190501.50432211'),
	'identifierSchemeCodeUnspsc190501.50432300': __('identifierSchemeCodeUnspsc190501.50432300'),
	'identifierSchemeCodeUnspsc190501.50432301': __('identifierSchemeCodeUnspsc190501.50432301'),
	'identifierSchemeCodeUnspsc190501.50432302': __('identifierSchemeCodeUnspsc190501.50432302'),
	'identifierSchemeCodeUnspsc190501.50432303': __('identifierSchemeCodeUnspsc190501.50432303'),
	'identifierSchemeCodeUnspsc190501.50432304': __('identifierSchemeCodeUnspsc190501.50432304'),
	'identifierSchemeCodeUnspsc190501.50432400': __('identifierSchemeCodeUnspsc190501.50432400'),
	'identifierSchemeCodeUnspsc190501.50432401': __('identifierSchemeCodeUnspsc190501.50432401'),
	'identifierSchemeCodeUnspsc190501.50432402': __('identifierSchemeCodeUnspsc190501.50432402'),
	'identifierSchemeCodeUnspsc190501.50432500': __('identifierSchemeCodeUnspsc190501.50432500'),
	'identifierSchemeCodeUnspsc190501.50432501': __('identifierSchemeCodeUnspsc190501.50432501'),
	'identifierSchemeCodeUnspsc190501.50432502': __('identifierSchemeCodeUnspsc190501.50432502'),
	'identifierSchemeCodeUnspsc190501.50432503': __('identifierSchemeCodeUnspsc190501.50432503'),
	'identifierSchemeCodeUnspsc190501.50432504': __('identifierSchemeCodeUnspsc190501.50432504'),
	'identifierSchemeCodeUnspsc190501.50432505': __('identifierSchemeCodeUnspsc190501.50432505'),
	'identifierSchemeCodeUnspsc190501.50432506': __('identifierSchemeCodeUnspsc190501.50432506'),
	'identifierSchemeCodeUnspsc190501.50432600': __('identifierSchemeCodeUnspsc190501.50432600'),
	'identifierSchemeCodeUnspsc190501.50432601': __('identifierSchemeCodeUnspsc190501.50432601'),
	'identifierSchemeCodeUnspsc190501.50432602': __('identifierSchemeCodeUnspsc190501.50432602'),
	'identifierSchemeCodeUnspsc190501.50432603': __('identifierSchemeCodeUnspsc190501.50432603'),
	'identifierSchemeCodeUnspsc190501.50432604': __('identifierSchemeCodeUnspsc190501.50432604'),
	'identifierSchemeCodeUnspsc190501.50432605': __('identifierSchemeCodeUnspsc190501.50432605'),
	'identifierSchemeCodeUnspsc190501.50432606': __('identifierSchemeCodeUnspsc190501.50432606'),
	'identifierSchemeCodeUnspsc190501.50432607': __('identifierSchemeCodeUnspsc190501.50432607'),
	'identifierSchemeCodeUnspsc190501.50432608': __('identifierSchemeCodeUnspsc190501.50432608'),
	'identifierSchemeCodeUnspsc190501.50432609': __('identifierSchemeCodeUnspsc190501.50432609'),
	'identifierSchemeCodeUnspsc190501.50432610': __('identifierSchemeCodeUnspsc190501.50432610'),
	'identifierSchemeCodeUnspsc190501.50432611': __('identifierSchemeCodeUnspsc190501.50432611'),
	'identifierSchemeCodeUnspsc190501.50432612': __('identifierSchemeCodeUnspsc190501.50432612'),
	'identifierSchemeCodeUnspsc190501.50432700': __('identifierSchemeCodeUnspsc190501.50432700'),
	'identifierSchemeCodeUnspsc190501.50432701': __('identifierSchemeCodeUnspsc190501.50432701'),
	'identifierSchemeCodeUnspsc190501.50432702': __('identifierSchemeCodeUnspsc190501.50432702'),
	'identifierSchemeCodeUnspsc190501.50432703': __('identifierSchemeCodeUnspsc190501.50432703'),
	'identifierSchemeCodeUnspsc190501.50432704': __('identifierSchemeCodeUnspsc190501.50432704'),
	'identifierSchemeCodeUnspsc190501.50432705': __('identifierSchemeCodeUnspsc190501.50432705'),
	'identifierSchemeCodeUnspsc190501.50432706': __('identifierSchemeCodeUnspsc190501.50432706'),
	'identifierSchemeCodeUnspsc190501.50432707': __('identifierSchemeCodeUnspsc190501.50432707'),
	'identifierSchemeCodeUnspsc190501.50432708': __('identifierSchemeCodeUnspsc190501.50432708'),
	'identifierSchemeCodeUnspsc190501.50432709': __('identifierSchemeCodeUnspsc190501.50432709'),
	'identifierSchemeCodeUnspsc190501.50432710': __('identifierSchemeCodeUnspsc190501.50432710'),
	'identifierSchemeCodeUnspsc190501.50432711': __('identifierSchemeCodeUnspsc190501.50432711'),
	'identifierSchemeCodeUnspsc190501.50432712': __('identifierSchemeCodeUnspsc190501.50432712'),
	'identifierSchemeCodeUnspsc190501.50432713': __('identifierSchemeCodeUnspsc190501.50432713'),
	'identifierSchemeCodeUnspsc190501.50432714': __('identifierSchemeCodeUnspsc190501.50432714'),
	'identifierSchemeCodeUnspsc190501.50432715': __('identifierSchemeCodeUnspsc190501.50432715'),
	'identifierSchemeCodeUnspsc190501.50432800': __('identifierSchemeCodeUnspsc190501.50432800'),
	'identifierSchemeCodeUnspsc190501.50432801': __('identifierSchemeCodeUnspsc190501.50432801'),
	'identifierSchemeCodeUnspsc190501.50432802': __('identifierSchemeCodeUnspsc190501.50432802'),
	'identifierSchemeCodeUnspsc190501.50432803': __('identifierSchemeCodeUnspsc190501.50432803'),
	'identifierSchemeCodeUnspsc190501.50432804': __('identifierSchemeCodeUnspsc190501.50432804'),
	'identifierSchemeCodeUnspsc190501.50432805': __('identifierSchemeCodeUnspsc190501.50432805'),
	'identifierSchemeCodeUnspsc190501.50432806': __('identifierSchemeCodeUnspsc190501.50432806'),
	'identifierSchemeCodeUnspsc190501.50432807': __('identifierSchemeCodeUnspsc190501.50432807'),
	'identifierSchemeCodeUnspsc190501.50432808': __('identifierSchemeCodeUnspsc190501.50432808'),
	'identifierSchemeCodeUnspsc190501.50432900': __('identifierSchemeCodeUnspsc190501.50432900'),
	'identifierSchemeCodeUnspsc190501.50432901': __('identifierSchemeCodeUnspsc190501.50432901'),
	'identifierSchemeCodeUnspsc190501.50432902': __('identifierSchemeCodeUnspsc190501.50432902'),
	'identifierSchemeCodeUnspsc190501.50432903': __('identifierSchemeCodeUnspsc190501.50432903'),
	'identifierSchemeCodeUnspsc190501.50432904': __('identifierSchemeCodeUnspsc190501.50432904'),
	'identifierSchemeCodeUnspsc190501.50432905': __('identifierSchemeCodeUnspsc190501.50432905'),
	'identifierSchemeCodeUnspsc190501.50432906': __('identifierSchemeCodeUnspsc190501.50432906'),
	'identifierSchemeCodeUnspsc190501.50432907': __('identifierSchemeCodeUnspsc190501.50432907'),
	'identifierSchemeCodeUnspsc190501.50432908': __('identifierSchemeCodeUnspsc190501.50432908'),
	'identifierSchemeCodeUnspsc190501.50432909': __('identifierSchemeCodeUnspsc190501.50432909'),
	'identifierSchemeCodeUnspsc190501.50432910': __('identifierSchemeCodeUnspsc190501.50432910'),
	'identifierSchemeCodeUnspsc190501.50432911': __('identifierSchemeCodeUnspsc190501.50432911'),
	'identifierSchemeCodeUnspsc190501.50433000': __('identifierSchemeCodeUnspsc190501.50433000'),
	'identifierSchemeCodeUnspsc190501.50433001': __('identifierSchemeCodeUnspsc190501.50433001'),
	'identifierSchemeCodeUnspsc190501.50433002': __('identifierSchemeCodeUnspsc190501.50433002'),
	'identifierSchemeCodeUnspsc190501.50433003': __('identifierSchemeCodeUnspsc190501.50433003'),
	'identifierSchemeCodeUnspsc190501.50433004': __('identifierSchemeCodeUnspsc190501.50433004'),
	'identifierSchemeCodeUnspsc190501.50433005': __('identifierSchemeCodeUnspsc190501.50433005'),
	'identifierSchemeCodeUnspsc190501.50433006': __('identifierSchemeCodeUnspsc190501.50433006'),
	'identifierSchemeCodeUnspsc190501.50433007': __('identifierSchemeCodeUnspsc190501.50433007'),
	'identifierSchemeCodeUnspsc190501.50433008': __('identifierSchemeCodeUnspsc190501.50433008'),
	'identifierSchemeCodeUnspsc190501.50433009': __('identifierSchemeCodeUnspsc190501.50433009'),
	'identifierSchemeCodeUnspsc190501.50433010': __('identifierSchemeCodeUnspsc190501.50433010'),
	'identifierSchemeCodeUnspsc190501.50433011': __('identifierSchemeCodeUnspsc190501.50433011'),
	'identifierSchemeCodeUnspsc190501.50433012': __('identifierSchemeCodeUnspsc190501.50433012'),
	'identifierSchemeCodeUnspsc190501.50433013': __('identifierSchemeCodeUnspsc190501.50433013'),
	'identifierSchemeCodeUnspsc190501.50433014': __('identifierSchemeCodeUnspsc190501.50433014'),
	'identifierSchemeCodeUnspsc190501.50433015': __('identifierSchemeCodeUnspsc190501.50433015'),
	'identifierSchemeCodeUnspsc190501.50433016': __('identifierSchemeCodeUnspsc190501.50433016'),
	'identifierSchemeCodeUnspsc190501.50433100': __('identifierSchemeCodeUnspsc190501.50433100'),
	'identifierSchemeCodeUnspsc190501.50433101': __('identifierSchemeCodeUnspsc190501.50433101'),
	'identifierSchemeCodeUnspsc190501.50433102': __('identifierSchemeCodeUnspsc190501.50433102'),
	'identifierSchemeCodeUnspsc190501.50433200': __('identifierSchemeCodeUnspsc190501.50433200'),
	'identifierSchemeCodeUnspsc190501.50433201': __('identifierSchemeCodeUnspsc190501.50433201'),
	'identifierSchemeCodeUnspsc190501.50433202': __('identifierSchemeCodeUnspsc190501.50433202'),
	'identifierSchemeCodeUnspsc190501.50433203': __('identifierSchemeCodeUnspsc190501.50433203'),
	'identifierSchemeCodeUnspsc190501.50433204': __('identifierSchemeCodeUnspsc190501.50433204'),
	'identifierSchemeCodeUnspsc190501.50433205': __('identifierSchemeCodeUnspsc190501.50433205'),
	'identifierSchemeCodeUnspsc190501.50433206': __('identifierSchemeCodeUnspsc190501.50433206'),
	'identifierSchemeCodeUnspsc190501.50433207': __('identifierSchemeCodeUnspsc190501.50433207'),
	'identifierSchemeCodeUnspsc190501.50433208': __('identifierSchemeCodeUnspsc190501.50433208'),
	'identifierSchemeCodeUnspsc190501.50433209': __('identifierSchemeCodeUnspsc190501.50433209'),
	'identifierSchemeCodeUnspsc190501.50433210': __('identifierSchemeCodeUnspsc190501.50433210'),
	'identifierSchemeCodeUnspsc190501.50433211': __('identifierSchemeCodeUnspsc190501.50433211'),
	'identifierSchemeCodeUnspsc190501.50433212': __('identifierSchemeCodeUnspsc190501.50433212'),
	'identifierSchemeCodeUnspsc190501.50433213': __('identifierSchemeCodeUnspsc190501.50433213'),
	'identifierSchemeCodeUnspsc190501.50433214': __('identifierSchemeCodeUnspsc190501.50433214'),
	'identifierSchemeCodeUnspsc190501.50433215': __('identifierSchemeCodeUnspsc190501.50433215'),
	'identifierSchemeCodeUnspsc190501.50433216': __('identifierSchemeCodeUnspsc190501.50433216'),
	'identifierSchemeCodeUnspsc190501.50433217': __('identifierSchemeCodeUnspsc190501.50433217'),
	'identifierSchemeCodeUnspsc190501.50433218': __('identifierSchemeCodeUnspsc190501.50433218'),
	'identifierSchemeCodeUnspsc190501.50433219': __('identifierSchemeCodeUnspsc190501.50433219'),
	'identifierSchemeCodeUnspsc190501.50433220': __('identifierSchemeCodeUnspsc190501.50433220'),
	'identifierSchemeCodeUnspsc190501.50433221': __('identifierSchemeCodeUnspsc190501.50433221'),
	'identifierSchemeCodeUnspsc190501.50433222': __('identifierSchemeCodeUnspsc190501.50433222'),
	'identifierSchemeCodeUnspsc190501.50433223': __('identifierSchemeCodeUnspsc190501.50433223'),
	'identifierSchemeCodeUnspsc190501.50433224': __('identifierSchemeCodeUnspsc190501.50433224'),
	'identifierSchemeCodeUnspsc190501.50433225': __('identifierSchemeCodeUnspsc190501.50433225'),
	'identifierSchemeCodeUnspsc190501.50433226': __('identifierSchemeCodeUnspsc190501.50433226'),
	'identifierSchemeCodeUnspsc190501.50433227': __('identifierSchemeCodeUnspsc190501.50433227'),
	'identifierSchemeCodeUnspsc190501.50433228': __('identifierSchemeCodeUnspsc190501.50433228'),
	'identifierSchemeCodeUnspsc190501.50433229': __('identifierSchemeCodeUnspsc190501.50433229'),
	'identifierSchemeCodeUnspsc190501.50433230': __('identifierSchemeCodeUnspsc190501.50433230'),
	'identifierSchemeCodeUnspsc190501.50433231': __('identifierSchemeCodeUnspsc190501.50433231'),
	'identifierSchemeCodeUnspsc190501.50433232': __('identifierSchemeCodeUnspsc190501.50433232'),
	'identifierSchemeCodeUnspsc190501.50433233': __('identifierSchemeCodeUnspsc190501.50433233'),
	'identifierSchemeCodeUnspsc190501.50433234': __('identifierSchemeCodeUnspsc190501.50433234'),
	'identifierSchemeCodeUnspsc190501.50433235': __('identifierSchemeCodeUnspsc190501.50433235'),
	'identifierSchemeCodeUnspsc190501.50433236': __('identifierSchemeCodeUnspsc190501.50433236'),
	'identifierSchemeCodeUnspsc190501.50433237': __('identifierSchemeCodeUnspsc190501.50433237'),
	'identifierSchemeCodeUnspsc190501.50433238': __('identifierSchemeCodeUnspsc190501.50433238'),
	'identifierSchemeCodeUnspsc190501.50433239': __('identifierSchemeCodeUnspsc190501.50433239'),
	'identifierSchemeCodeUnspsc190501.50433240': __('identifierSchemeCodeUnspsc190501.50433240'),
	'identifierSchemeCodeUnspsc190501.50433241': __('identifierSchemeCodeUnspsc190501.50433241'),
	'identifierSchemeCodeUnspsc190501.50433242': __('identifierSchemeCodeUnspsc190501.50433242'),
	'identifierSchemeCodeUnspsc190501.50433243': __('identifierSchemeCodeUnspsc190501.50433243'),
	'identifierSchemeCodeUnspsc190501.50433244': __('identifierSchemeCodeUnspsc190501.50433244'),
	'identifierSchemeCodeUnspsc190501.50433245': __('identifierSchemeCodeUnspsc190501.50433245'),
	'identifierSchemeCodeUnspsc190501.50433246': __('identifierSchemeCodeUnspsc190501.50433246'),
	'identifierSchemeCodeUnspsc190501.50433247': __('identifierSchemeCodeUnspsc190501.50433247'),
	'identifierSchemeCodeUnspsc190501.50433248': __('identifierSchemeCodeUnspsc190501.50433248'),
	'identifierSchemeCodeUnspsc190501.50433249': __('identifierSchemeCodeUnspsc190501.50433249'),
	'identifierSchemeCodeUnspsc190501.50433250': __('identifierSchemeCodeUnspsc190501.50433250'),
	'identifierSchemeCodeUnspsc190501.50433251': __('identifierSchemeCodeUnspsc190501.50433251'),
	'identifierSchemeCodeUnspsc190501.50433252': __('identifierSchemeCodeUnspsc190501.50433252'),
	'identifierSchemeCodeUnspsc190501.50433253': __('identifierSchemeCodeUnspsc190501.50433253'),
	'identifierSchemeCodeUnspsc190501.50433254': __('identifierSchemeCodeUnspsc190501.50433254'),
	'identifierSchemeCodeUnspsc190501.50433255': __('identifierSchemeCodeUnspsc190501.50433255'),
	'identifierSchemeCodeUnspsc190501.50433256': __('identifierSchemeCodeUnspsc190501.50433256'),
	'identifierSchemeCodeUnspsc190501.50433257': __('identifierSchemeCodeUnspsc190501.50433257'),
	'identifierSchemeCodeUnspsc190501.50433258': __('identifierSchemeCodeUnspsc190501.50433258'),
	'identifierSchemeCodeUnspsc190501.50433259': __('identifierSchemeCodeUnspsc190501.50433259'),
	'identifierSchemeCodeUnspsc190501.50433260': __('identifierSchemeCodeUnspsc190501.50433260'),
	'identifierSchemeCodeUnspsc190501.50433261': __('identifierSchemeCodeUnspsc190501.50433261'),
	'identifierSchemeCodeUnspsc190501.50433262': __('identifierSchemeCodeUnspsc190501.50433262'),
	'identifierSchemeCodeUnspsc190501.50433263': __('identifierSchemeCodeUnspsc190501.50433263'),
	'identifierSchemeCodeUnspsc190501.50433264': __('identifierSchemeCodeUnspsc190501.50433264'),
	'identifierSchemeCodeUnspsc190501.50433265': __('identifierSchemeCodeUnspsc190501.50433265'),
	'identifierSchemeCodeUnspsc190501.50433266': __('identifierSchemeCodeUnspsc190501.50433266'),
	'identifierSchemeCodeUnspsc190501.50433267': __('identifierSchemeCodeUnspsc190501.50433267'),
	'identifierSchemeCodeUnspsc190501.50433268': __('identifierSchemeCodeUnspsc190501.50433268'),
	'identifierSchemeCodeUnspsc190501.50433269': __('identifierSchemeCodeUnspsc190501.50433269'),
	'identifierSchemeCodeUnspsc190501.50433300': __('identifierSchemeCodeUnspsc190501.50433300'),
	'identifierSchemeCodeUnspsc190501.50433301': __('identifierSchemeCodeUnspsc190501.50433301'),
	'identifierSchemeCodeUnspsc190501.50433302': __('identifierSchemeCodeUnspsc190501.50433302'),
	'identifierSchemeCodeUnspsc190501.50433303': __('identifierSchemeCodeUnspsc190501.50433303'),
	'identifierSchemeCodeUnspsc190501.50433304': __('identifierSchemeCodeUnspsc190501.50433304'),
	'identifierSchemeCodeUnspsc190501.50433400': __('identifierSchemeCodeUnspsc190501.50433400'),
	'identifierSchemeCodeUnspsc190501.50433401': __('identifierSchemeCodeUnspsc190501.50433401'),
	'identifierSchemeCodeUnspsc190501.50433402': __('identifierSchemeCodeUnspsc190501.50433402'),
	'identifierSchemeCodeUnspsc190501.50433403': __('identifierSchemeCodeUnspsc190501.50433403'),
	'identifierSchemeCodeUnspsc190501.50433404': __('identifierSchemeCodeUnspsc190501.50433404'),
	'identifierSchemeCodeUnspsc190501.50433405': __('identifierSchemeCodeUnspsc190501.50433405'),
	'identifierSchemeCodeUnspsc190501.50433406': __('identifierSchemeCodeUnspsc190501.50433406'),
	'identifierSchemeCodeUnspsc190501.50433407': __('identifierSchemeCodeUnspsc190501.50433407'),
	'identifierSchemeCodeUnspsc190501.50433408': __('identifierSchemeCodeUnspsc190501.50433408'),
	'identifierSchemeCodeUnspsc190501.50433409': __('identifierSchemeCodeUnspsc190501.50433409'),
	'identifierSchemeCodeUnspsc190501.50433410': __('identifierSchemeCodeUnspsc190501.50433410'),
	'identifierSchemeCodeUnspsc190501.50433411': __('identifierSchemeCodeUnspsc190501.50433411'),
	'identifierSchemeCodeUnspsc190501.50433412': __('identifierSchemeCodeUnspsc190501.50433412'),
	'identifierSchemeCodeUnspsc190501.50433413': __('identifierSchemeCodeUnspsc190501.50433413'),
	'identifierSchemeCodeUnspsc190501.50433414': __('identifierSchemeCodeUnspsc190501.50433414'),
	'identifierSchemeCodeUnspsc190501.50433415': __('identifierSchemeCodeUnspsc190501.50433415'),
	'identifierSchemeCodeUnspsc190501.50433416': __('identifierSchemeCodeUnspsc190501.50433416'),
	'identifierSchemeCodeUnspsc190501.50433417': __('identifierSchemeCodeUnspsc190501.50433417'),
	'identifierSchemeCodeUnspsc190501.50433418': __('identifierSchemeCodeUnspsc190501.50433418'),
	'identifierSchemeCodeUnspsc190501.50433419': __('identifierSchemeCodeUnspsc190501.50433419'),
	'identifierSchemeCodeUnspsc190501.50433420': __('identifierSchemeCodeUnspsc190501.50433420'),
	'identifierSchemeCodeUnspsc190501.50433421': __('identifierSchemeCodeUnspsc190501.50433421'),
	'identifierSchemeCodeUnspsc190501.50433422': __('identifierSchemeCodeUnspsc190501.50433422'),
	'identifierSchemeCodeUnspsc190501.50433423': __('identifierSchemeCodeUnspsc190501.50433423'),
	'identifierSchemeCodeUnspsc190501.50433500': __('identifierSchemeCodeUnspsc190501.50433500'),
	'identifierSchemeCodeUnspsc190501.50433501': __('identifierSchemeCodeUnspsc190501.50433501'),
	'identifierSchemeCodeUnspsc190501.50433502': __('identifierSchemeCodeUnspsc190501.50433502'),
	'identifierSchemeCodeUnspsc190501.50433503': __('identifierSchemeCodeUnspsc190501.50433503'),
	'identifierSchemeCodeUnspsc190501.50433504': __('identifierSchemeCodeUnspsc190501.50433504'),
	'identifierSchemeCodeUnspsc190501.50433505': __('identifierSchemeCodeUnspsc190501.50433505'),
	'identifierSchemeCodeUnspsc190501.50433506': __('identifierSchemeCodeUnspsc190501.50433506'),
	'identifierSchemeCodeUnspsc190501.50433507': __('identifierSchemeCodeUnspsc190501.50433507'),
	'identifierSchemeCodeUnspsc190501.50433508': __('identifierSchemeCodeUnspsc190501.50433508'),
	'identifierSchemeCodeUnspsc190501.50433509': __('identifierSchemeCodeUnspsc190501.50433509'),
	'identifierSchemeCodeUnspsc190501.50433510': __('identifierSchemeCodeUnspsc190501.50433510'),
	'identifierSchemeCodeUnspsc190501.50433511': __('identifierSchemeCodeUnspsc190501.50433511'),
	'identifierSchemeCodeUnspsc190501.50433512': __('identifierSchemeCodeUnspsc190501.50433512'),
	'identifierSchemeCodeUnspsc190501.50433513': __('identifierSchemeCodeUnspsc190501.50433513'),
	'identifierSchemeCodeUnspsc190501.50433514': __('identifierSchemeCodeUnspsc190501.50433514'),
	'identifierSchemeCodeUnspsc190501.50433515': __('identifierSchemeCodeUnspsc190501.50433515'),
	'identifierSchemeCodeUnspsc190501.50433516': __('identifierSchemeCodeUnspsc190501.50433516'),
	'identifierSchemeCodeUnspsc190501.50433517': __('identifierSchemeCodeUnspsc190501.50433517'),
	'identifierSchemeCodeUnspsc190501.50433518': __('identifierSchemeCodeUnspsc190501.50433518'),
	'identifierSchemeCodeUnspsc190501.50433519': __('identifierSchemeCodeUnspsc190501.50433519'),
	'identifierSchemeCodeUnspsc190501.50433520': __('identifierSchemeCodeUnspsc190501.50433520'),
	'identifierSchemeCodeUnspsc190501.50433600': __('identifierSchemeCodeUnspsc190501.50433600'),
	'identifierSchemeCodeUnspsc190501.50433601': __('identifierSchemeCodeUnspsc190501.50433601'),
	'identifierSchemeCodeUnspsc190501.50433602': __('identifierSchemeCodeUnspsc190501.50433602'),
	'identifierSchemeCodeUnspsc190501.50433603': __('identifierSchemeCodeUnspsc190501.50433603'),
	'identifierSchemeCodeUnspsc190501.50433604': __('identifierSchemeCodeUnspsc190501.50433604'),
	'identifierSchemeCodeUnspsc190501.50433605': __('identifierSchemeCodeUnspsc190501.50433605'),
	'identifierSchemeCodeUnspsc190501.50433606': __('identifierSchemeCodeUnspsc190501.50433606'),
	'identifierSchemeCodeUnspsc190501.50433607': __('identifierSchemeCodeUnspsc190501.50433607'),
	'identifierSchemeCodeUnspsc190501.50433608': __('identifierSchemeCodeUnspsc190501.50433608'),
	'identifierSchemeCodeUnspsc190501.50433609': __('identifierSchemeCodeUnspsc190501.50433609'),
	'identifierSchemeCodeUnspsc190501.50433610': __('identifierSchemeCodeUnspsc190501.50433610'),
	'identifierSchemeCodeUnspsc190501.50433611': __('identifierSchemeCodeUnspsc190501.50433611'),
	'identifierSchemeCodeUnspsc190501.50433612': __('identifierSchemeCodeUnspsc190501.50433612'),
	'identifierSchemeCodeUnspsc190501.50433613': __('identifierSchemeCodeUnspsc190501.50433613'),
	'identifierSchemeCodeUnspsc190501.50433614': __('identifierSchemeCodeUnspsc190501.50433614'),
	'identifierSchemeCodeUnspsc190501.50433700': __('identifierSchemeCodeUnspsc190501.50433700'),
	'identifierSchemeCodeUnspsc190501.50433701': __('identifierSchemeCodeUnspsc190501.50433701'),
	'identifierSchemeCodeUnspsc190501.50433702': __('identifierSchemeCodeUnspsc190501.50433702'),
	'identifierSchemeCodeUnspsc190501.50433703': __('identifierSchemeCodeUnspsc190501.50433703'),
	'identifierSchemeCodeUnspsc190501.50433704': __('identifierSchemeCodeUnspsc190501.50433704'),
	'identifierSchemeCodeUnspsc190501.50433705': __('identifierSchemeCodeUnspsc190501.50433705'),
	'identifierSchemeCodeUnspsc190501.50433706': __('identifierSchemeCodeUnspsc190501.50433706'),
	'identifierSchemeCodeUnspsc190501.50433707': __('identifierSchemeCodeUnspsc190501.50433707'),
	'identifierSchemeCodeUnspsc190501.50433800': __('identifierSchemeCodeUnspsc190501.50433800'),
	'identifierSchemeCodeUnspsc190501.50433801': __('identifierSchemeCodeUnspsc190501.50433801'),
	'identifierSchemeCodeUnspsc190501.50433802': __('identifierSchemeCodeUnspsc190501.50433802'),
	'identifierSchemeCodeUnspsc190501.50433803': __('identifierSchemeCodeUnspsc190501.50433803'),
	'identifierSchemeCodeUnspsc190501.50433804': __('identifierSchemeCodeUnspsc190501.50433804'),
	'identifierSchemeCodeUnspsc190501.50433805': __('identifierSchemeCodeUnspsc190501.50433805'),
	'identifierSchemeCodeUnspsc190501.50433806': __('identifierSchemeCodeUnspsc190501.50433806'),
	'identifierSchemeCodeUnspsc190501.50433807': __('identifierSchemeCodeUnspsc190501.50433807'),
	'identifierSchemeCodeUnspsc190501.50433808': __('identifierSchemeCodeUnspsc190501.50433808'),
	'identifierSchemeCodeUnspsc190501.50433809': __('identifierSchemeCodeUnspsc190501.50433809'),
	'identifierSchemeCodeUnspsc190501.50433810': __('identifierSchemeCodeUnspsc190501.50433810'),
	'identifierSchemeCodeUnspsc190501.50433811': __('identifierSchemeCodeUnspsc190501.50433811'),
	'identifierSchemeCodeUnspsc190501.50433900': __('identifierSchemeCodeUnspsc190501.50433900'),
	'identifierSchemeCodeUnspsc190501.50433901': __('identifierSchemeCodeUnspsc190501.50433901'),
	'identifierSchemeCodeUnspsc190501.50433902': __('identifierSchemeCodeUnspsc190501.50433902'),
	'identifierSchemeCodeUnspsc190501.50433903': __('identifierSchemeCodeUnspsc190501.50433903'),
	'identifierSchemeCodeUnspsc190501.50433904': __('identifierSchemeCodeUnspsc190501.50433904'),
	'identifierSchemeCodeUnspsc190501.50433905': __('identifierSchemeCodeUnspsc190501.50433905'),
	'identifierSchemeCodeUnspsc190501.50433906': __('identifierSchemeCodeUnspsc190501.50433906'),
	'identifierSchemeCodeUnspsc190501.50433907': __('identifierSchemeCodeUnspsc190501.50433907'),
	'identifierSchemeCodeUnspsc190501.50433908': __('identifierSchemeCodeUnspsc190501.50433908'),
	'identifierSchemeCodeUnspsc190501.50433909': __('identifierSchemeCodeUnspsc190501.50433909'),
	'identifierSchemeCodeUnspsc190501.50433910': __('identifierSchemeCodeUnspsc190501.50433910'),
	'identifierSchemeCodeUnspsc190501.50433911': __('identifierSchemeCodeUnspsc190501.50433911'),
	'identifierSchemeCodeUnspsc190501.50434000': __('identifierSchemeCodeUnspsc190501.50434000'),
	'identifierSchemeCodeUnspsc190501.50434001': __('identifierSchemeCodeUnspsc190501.50434001'),
	'identifierSchemeCodeUnspsc190501.50434002': __('identifierSchemeCodeUnspsc190501.50434002'),
	'identifierSchemeCodeUnspsc190501.50434003': __('identifierSchemeCodeUnspsc190501.50434003'),
	'identifierSchemeCodeUnspsc190501.50434004': __('identifierSchemeCodeUnspsc190501.50434004'),
	'identifierSchemeCodeUnspsc190501.50434005': __('identifierSchemeCodeUnspsc190501.50434005'),
	'identifierSchemeCodeUnspsc190501.50434100': __('identifierSchemeCodeUnspsc190501.50434100'),
	'identifierSchemeCodeUnspsc190501.50434101': __('identifierSchemeCodeUnspsc190501.50434101'),
	'identifierSchemeCodeUnspsc190501.50434102': __('identifierSchemeCodeUnspsc190501.50434102'),
	'identifierSchemeCodeUnspsc190501.50434103': __('identifierSchemeCodeUnspsc190501.50434103'),
	'identifierSchemeCodeUnspsc190501.50434104': __('identifierSchemeCodeUnspsc190501.50434104'),
	'identifierSchemeCodeUnspsc190501.50434105': __('identifierSchemeCodeUnspsc190501.50434105'),
	'identifierSchemeCodeUnspsc190501.50434106': __('identifierSchemeCodeUnspsc190501.50434106'),
	'identifierSchemeCodeUnspsc190501.50434107': __('identifierSchemeCodeUnspsc190501.50434107'),
	'identifierSchemeCodeUnspsc190501.50434108': __('identifierSchemeCodeUnspsc190501.50434108'),
	'identifierSchemeCodeUnspsc190501.50434109': __('identifierSchemeCodeUnspsc190501.50434109'),
	'identifierSchemeCodeUnspsc190501.50434110': __('identifierSchemeCodeUnspsc190501.50434110'),
	'identifierSchemeCodeUnspsc190501.50434111': __('identifierSchemeCodeUnspsc190501.50434111'),
	'identifierSchemeCodeUnspsc190501.50434112': __('identifierSchemeCodeUnspsc190501.50434112'),
	'identifierSchemeCodeUnspsc190501.50434113': __('identifierSchemeCodeUnspsc190501.50434113'),
	'identifierSchemeCodeUnspsc190501.50434114': __('identifierSchemeCodeUnspsc190501.50434114'),
	'identifierSchemeCodeUnspsc190501.50434115': __('identifierSchemeCodeUnspsc190501.50434115'),
	'identifierSchemeCodeUnspsc190501.50434116': __('identifierSchemeCodeUnspsc190501.50434116'),
	'identifierSchemeCodeUnspsc190501.50434117': __('identifierSchemeCodeUnspsc190501.50434117'),
	'identifierSchemeCodeUnspsc190501.50434118': __('identifierSchemeCodeUnspsc190501.50434118'),
	'identifierSchemeCodeUnspsc190501.50434119': __('identifierSchemeCodeUnspsc190501.50434119'),
	'identifierSchemeCodeUnspsc190501.50434120': __('identifierSchemeCodeUnspsc190501.50434120'),
	'identifierSchemeCodeUnspsc190501.50434121': __('identifierSchemeCodeUnspsc190501.50434121'),
	'identifierSchemeCodeUnspsc190501.50434122': __('identifierSchemeCodeUnspsc190501.50434122'),
	'identifierSchemeCodeUnspsc190501.50434123': __('identifierSchemeCodeUnspsc190501.50434123'),
	'identifierSchemeCodeUnspsc190501.50434124': __('identifierSchemeCodeUnspsc190501.50434124'),
	'identifierSchemeCodeUnspsc190501.50434125': __('identifierSchemeCodeUnspsc190501.50434125'),
	'identifierSchemeCodeUnspsc190501.50434126': __('identifierSchemeCodeUnspsc190501.50434126'),
	'identifierSchemeCodeUnspsc190501.50434127': __('identifierSchemeCodeUnspsc190501.50434127'),
	'identifierSchemeCodeUnspsc190501.50434200': __('identifierSchemeCodeUnspsc190501.50434200'),
	'identifierSchemeCodeUnspsc190501.50434201': __('identifierSchemeCodeUnspsc190501.50434201'),
	'identifierSchemeCodeUnspsc190501.50434202': __('identifierSchemeCodeUnspsc190501.50434202'),
	'identifierSchemeCodeUnspsc190501.50434300': __('identifierSchemeCodeUnspsc190501.50434300'),
	'identifierSchemeCodeUnspsc190501.50434301': __('identifierSchemeCodeUnspsc190501.50434301'),
	'identifierSchemeCodeUnspsc190501.50434302': __('identifierSchemeCodeUnspsc190501.50434302'),
	'identifierSchemeCodeUnspsc190501.50434303': __('identifierSchemeCodeUnspsc190501.50434303'),
	'identifierSchemeCodeUnspsc190501.50434304': __('identifierSchemeCodeUnspsc190501.50434304'),
	'identifierSchemeCodeUnspsc190501.50434305': __('identifierSchemeCodeUnspsc190501.50434305'),
	'identifierSchemeCodeUnspsc190501.50434306': __('identifierSchemeCodeUnspsc190501.50434306'),
	'identifierSchemeCodeUnspsc190501.50434400': __('identifierSchemeCodeUnspsc190501.50434400'),
	'identifierSchemeCodeUnspsc190501.50434401': __('identifierSchemeCodeUnspsc190501.50434401'),
	'identifierSchemeCodeUnspsc190501.50434402': __('identifierSchemeCodeUnspsc190501.50434402'),
	'identifierSchemeCodeUnspsc190501.50434403': __('identifierSchemeCodeUnspsc190501.50434403'),
	'identifierSchemeCodeUnspsc190501.50434404': __('identifierSchemeCodeUnspsc190501.50434404'),
	'identifierSchemeCodeUnspsc190501.50434405': __('identifierSchemeCodeUnspsc190501.50434405'),
	'identifierSchemeCodeUnspsc190501.50434406': __('identifierSchemeCodeUnspsc190501.50434406'),
	'identifierSchemeCodeUnspsc190501.50434500': __('identifierSchemeCodeUnspsc190501.50434500'),
	'identifierSchemeCodeUnspsc190501.50434501': __('identifierSchemeCodeUnspsc190501.50434501'),
	'identifierSchemeCodeUnspsc190501.50434502': __('identifierSchemeCodeUnspsc190501.50434502'),
	'identifierSchemeCodeUnspsc190501.50434503': __('identifierSchemeCodeUnspsc190501.50434503'),
	'identifierSchemeCodeUnspsc190501.50434504': __('identifierSchemeCodeUnspsc190501.50434504'),
	'identifierSchemeCodeUnspsc190501.50434505': __('identifierSchemeCodeUnspsc190501.50434505'),
	'identifierSchemeCodeUnspsc190501.50434506': __('identifierSchemeCodeUnspsc190501.50434506'),
	'identifierSchemeCodeUnspsc190501.50434507': __('identifierSchemeCodeUnspsc190501.50434507'),
	'identifierSchemeCodeUnspsc190501.50434508': __('identifierSchemeCodeUnspsc190501.50434508'),
	'identifierSchemeCodeUnspsc190501.50434600': __('identifierSchemeCodeUnspsc190501.50434600'),
	'identifierSchemeCodeUnspsc190501.50434601': __('identifierSchemeCodeUnspsc190501.50434601'),
	'identifierSchemeCodeUnspsc190501.50434602': __('identifierSchemeCodeUnspsc190501.50434602'),
	'identifierSchemeCodeUnspsc190501.50434603': __('identifierSchemeCodeUnspsc190501.50434603'),
	'identifierSchemeCodeUnspsc190501.50434604': __('identifierSchemeCodeUnspsc190501.50434604'),
	'identifierSchemeCodeUnspsc190501.50434605': __('identifierSchemeCodeUnspsc190501.50434605'),
	'identifierSchemeCodeUnspsc190501.50434606': __('identifierSchemeCodeUnspsc190501.50434606'),
	'identifierSchemeCodeUnspsc190501.50434607': __('identifierSchemeCodeUnspsc190501.50434607'),
	'identifierSchemeCodeUnspsc190501.50434608': __('identifierSchemeCodeUnspsc190501.50434608'),
	'identifierSchemeCodeUnspsc190501.50434609': __('identifierSchemeCodeUnspsc190501.50434609'),
	'identifierSchemeCodeUnspsc190501.50434610': __('identifierSchemeCodeUnspsc190501.50434610'),
	'identifierSchemeCodeUnspsc190501.50434611': __('identifierSchemeCodeUnspsc190501.50434611'),
	'identifierSchemeCodeUnspsc190501.50434612': __('identifierSchemeCodeUnspsc190501.50434612'),
	'identifierSchemeCodeUnspsc190501.50434613': __('identifierSchemeCodeUnspsc190501.50434613'),
	'identifierSchemeCodeUnspsc190501.50434614': __('identifierSchemeCodeUnspsc190501.50434614'),
	'identifierSchemeCodeUnspsc190501.50434615': __('identifierSchemeCodeUnspsc190501.50434615'),
	'identifierSchemeCodeUnspsc190501.50434616': __('identifierSchemeCodeUnspsc190501.50434616'),
	'identifierSchemeCodeUnspsc190501.50434617': __('identifierSchemeCodeUnspsc190501.50434617'),
	'identifierSchemeCodeUnspsc190501.50434618': __('identifierSchemeCodeUnspsc190501.50434618'),
	'identifierSchemeCodeUnspsc190501.50434619': __('identifierSchemeCodeUnspsc190501.50434619'),
	'identifierSchemeCodeUnspsc190501.50434620': __('identifierSchemeCodeUnspsc190501.50434620'),
	'identifierSchemeCodeUnspsc190501.50434621': __('identifierSchemeCodeUnspsc190501.50434621'),
	'identifierSchemeCodeUnspsc190501.50434622': __('identifierSchemeCodeUnspsc190501.50434622'),
	'identifierSchemeCodeUnspsc190501.50434623': __('identifierSchemeCodeUnspsc190501.50434623'),
	'identifierSchemeCodeUnspsc190501.50434700': __('identifierSchemeCodeUnspsc190501.50434700'),
	'identifierSchemeCodeUnspsc190501.50434701': __('identifierSchemeCodeUnspsc190501.50434701'),
	'identifierSchemeCodeUnspsc190501.50434702': __('identifierSchemeCodeUnspsc190501.50434702'),
	'identifierSchemeCodeUnspsc190501.50434703': __('identifierSchemeCodeUnspsc190501.50434703'),
	'identifierSchemeCodeUnspsc190501.50434704': __('identifierSchemeCodeUnspsc190501.50434704'),
	'identifierSchemeCodeUnspsc190501.50434705': __('identifierSchemeCodeUnspsc190501.50434705'),
	'identifierSchemeCodeUnspsc190501.50434706': __('identifierSchemeCodeUnspsc190501.50434706'),
	'identifierSchemeCodeUnspsc190501.50434800': __('identifierSchemeCodeUnspsc190501.50434800'),
	'identifierSchemeCodeUnspsc190501.50434801': __('identifierSchemeCodeUnspsc190501.50434801'),
	'identifierSchemeCodeUnspsc190501.50434802': __('identifierSchemeCodeUnspsc190501.50434802'),
	'identifierSchemeCodeUnspsc190501.50434803': __('identifierSchemeCodeUnspsc190501.50434803'),
	'identifierSchemeCodeUnspsc190501.50434804': __('identifierSchemeCodeUnspsc190501.50434804'),
	'identifierSchemeCodeUnspsc190501.50434805': __('identifierSchemeCodeUnspsc190501.50434805'),
	'identifierSchemeCodeUnspsc190501.50434806': __('identifierSchemeCodeUnspsc190501.50434806'),
	'identifierSchemeCodeUnspsc190501.50434807': __('identifierSchemeCodeUnspsc190501.50434807'),
	'identifierSchemeCodeUnspsc190501.50434808': __('identifierSchemeCodeUnspsc190501.50434808'),
	'identifierSchemeCodeUnspsc190501.50434809': __('identifierSchemeCodeUnspsc190501.50434809'),
	'identifierSchemeCodeUnspsc190501.50434810': __('identifierSchemeCodeUnspsc190501.50434810'),
	'identifierSchemeCodeUnspsc190501.50434811': __('identifierSchemeCodeUnspsc190501.50434811'),
	'identifierSchemeCodeUnspsc190501.50434812': __('identifierSchemeCodeUnspsc190501.50434812'),
	'identifierSchemeCodeUnspsc190501.50434813': __('identifierSchemeCodeUnspsc190501.50434813'),
	'identifierSchemeCodeUnspsc190501.50434814': __('identifierSchemeCodeUnspsc190501.50434814'),
	'identifierSchemeCodeUnspsc190501.50434815': __('identifierSchemeCodeUnspsc190501.50434815'),
	'identifierSchemeCodeUnspsc190501.50434816': __('identifierSchemeCodeUnspsc190501.50434816'),
	'identifierSchemeCodeUnspsc190501.50434817': __('identifierSchemeCodeUnspsc190501.50434817'),
	'identifierSchemeCodeUnspsc190501.50434818': __('identifierSchemeCodeUnspsc190501.50434818'),
	'identifierSchemeCodeUnspsc190501.50434819': __('identifierSchemeCodeUnspsc190501.50434819'),
	'identifierSchemeCodeUnspsc190501.50434820': __('identifierSchemeCodeUnspsc190501.50434820'),
	'identifierSchemeCodeUnspsc190501.50434821': __('identifierSchemeCodeUnspsc190501.50434821'),
	'identifierSchemeCodeUnspsc190501.50434900': __('identifierSchemeCodeUnspsc190501.50434900'),
	'identifierSchemeCodeUnspsc190501.50434901': __('identifierSchemeCodeUnspsc190501.50434901'),
	'identifierSchemeCodeUnspsc190501.50434902': __('identifierSchemeCodeUnspsc190501.50434902'),
	'identifierSchemeCodeUnspsc190501.50434903': __('identifierSchemeCodeUnspsc190501.50434903'),
	'identifierSchemeCodeUnspsc190501.50434904': __('identifierSchemeCodeUnspsc190501.50434904'),
	'identifierSchemeCodeUnspsc190501.50434905': __('identifierSchemeCodeUnspsc190501.50434905'),
	'identifierSchemeCodeUnspsc190501.50434906': __('identifierSchemeCodeUnspsc190501.50434906'),
	'identifierSchemeCodeUnspsc190501.50435000': __('identifierSchemeCodeUnspsc190501.50435000'),
	'identifierSchemeCodeUnspsc190501.50435001': __('identifierSchemeCodeUnspsc190501.50435001'),
	'identifierSchemeCodeUnspsc190501.50435002': __('identifierSchemeCodeUnspsc190501.50435002'),
	'identifierSchemeCodeUnspsc190501.50435003': __('identifierSchemeCodeUnspsc190501.50435003'),
	'identifierSchemeCodeUnspsc190501.50435004': __('identifierSchemeCodeUnspsc190501.50435004'),
	'identifierSchemeCodeUnspsc190501.50435100': __('identifierSchemeCodeUnspsc190501.50435100'),
	'identifierSchemeCodeUnspsc190501.50435101': __('identifierSchemeCodeUnspsc190501.50435101'),
	'identifierSchemeCodeUnspsc190501.50435102': __('identifierSchemeCodeUnspsc190501.50435102'),
	'identifierSchemeCodeUnspsc190501.50435103': __('identifierSchemeCodeUnspsc190501.50435103'),
	'identifierSchemeCodeUnspsc190501.50435104': __('identifierSchemeCodeUnspsc190501.50435104'),
	'identifierSchemeCodeUnspsc190501.50435105': __('identifierSchemeCodeUnspsc190501.50435105'),
	'identifierSchemeCodeUnspsc190501.50435106': __('identifierSchemeCodeUnspsc190501.50435106'),
	'identifierSchemeCodeUnspsc190501.50435107': __('identifierSchemeCodeUnspsc190501.50435107'),
	'identifierSchemeCodeUnspsc190501.50435200': __('identifierSchemeCodeUnspsc190501.50435200'),
	'identifierSchemeCodeUnspsc190501.50435201': __('identifierSchemeCodeUnspsc190501.50435201'),
	'identifierSchemeCodeUnspsc190501.50435202': __('identifierSchemeCodeUnspsc190501.50435202'),
	'identifierSchemeCodeUnspsc190501.50435203': __('identifierSchemeCodeUnspsc190501.50435203'),
	'identifierSchemeCodeUnspsc190501.50435204': __('identifierSchemeCodeUnspsc190501.50435204'),
	'identifierSchemeCodeUnspsc190501.50435205': __('identifierSchemeCodeUnspsc190501.50435205'),
	'identifierSchemeCodeUnspsc190501.50435206': __('identifierSchemeCodeUnspsc190501.50435206'),
	'identifierSchemeCodeUnspsc190501.50435207': __('identifierSchemeCodeUnspsc190501.50435207'),
	'identifierSchemeCodeUnspsc190501.50435208': __('identifierSchemeCodeUnspsc190501.50435208'),
	'identifierSchemeCodeUnspsc190501.50435209': __('identifierSchemeCodeUnspsc190501.50435209'),
	'identifierSchemeCodeUnspsc190501.50435210': __('identifierSchemeCodeUnspsc190501.50435210'),
	'identifierSchemeCodeUnspsc190501.50435211': __('identifierSchemeCodeUnspsc190501.50435211'),
	'identifierSchemeCodeUnspsc190501.50435212': __('identifierSchemeCodeUnspsc190501.50435212'),
	'identifierSchemeCodeUnspsc190501.50435213': __('identifierSchemeCodeUnspsc190501.50435213'),
	'identifierSchemeCodeUnspsc190501.50435214': __('identifierSchemeCodeUnspsc190501.50435214'),
	'identifierSchemeCodeUnspsc190501.50435215': __('identifierSchemeCodeUnspsc190501.50435215'),
	'identifierSchemeCodeUnspsc190501.50435216': __('identifierSchemeCodeUnspsc190501.50435216'),
	'identifierSchemeCodeUnspsc190501.50435217': __('identifierSchemeCodeUnspsc190501.50435217'),
	'identifierSchemeCodeUnspsc190501.50435218': __('identifierSchemeCodeUnspsc190501.50435218'),
	'identifierSchemeCodeUnspsc190501.50435219': __('identifierSchemeCodeUnspsc190501.50435219'),
	'identifierSchemeCodeUnspsc190501.50435220': __('identifierSchemeCodeUnspsc190501.50435220'),
	'identifierSchemeCodeUnspsc190501.50435221': __('identifierSchemeCodeUnspsc190501.50435221'),
	'identifierSchemeCodeUnspsc190501.50435300': __('identifierSchemeCodeUnspsc190501.50435300'),
	'identifierSchemeCodeUnspsc190501.50435301': __('identifierSchemeCodeUnspsc190501.50435301'),
	'identifierSchemeCodeUnspsc190501.50435302': __('identifierSchemeCodeUnspsc190501.50435302'),
	'identifierSchemeCodeUnspsc190501.50435303': __('identifierSchemeCodeUnspsc190501.50435303'),
	'identifierSchemeCodeUnspsc190501.50435304': __('identifierSchemeCodeUnspsc190501.50435304'),
	'identifierSchemeCodeUnspsc190501.50435305': __('identifierSchemeCodeUnspsc190501.50435305'),
	'identifierSchemeCodeUnspsc190501.50435306': __('identifierSchemeCodeUnspsc190501.50435306'),
	'identifierSchemeCodeUnspsc190501.50435400': __('identifierSchemeCodeUnspsc190501.50435400'),
	'identifierSchemeCodeUnspsc190501.50435401': __('identifierSchemeCodeUnspsc190501.50435401'),
	'identifierSchemeCodeUnspsc190501.50435402': __('identifierSchemeCodeUnspsc190501.50435402'),
	'identifierSchemeCodeUnspsc190501.50435403': __('identifierSchemeCodeUnspsc190501.50435403'),
	'identifierSchemeCodeUnspsc190501.50435404': __('identifierSchemeCodeUnspsc190501.50435404'),
	'identifierSchemeCodeUnspsc190501.50435405': __('identifierSchemeCodeUnspsc190501.50435405'),
	'identifierSchemeCodeUnspsc190501.50435406': __('identifierSchemeCodeUnspsc190501.50435406'),
	'identifierSchemeCodeUnspsc190501.50435500': __('identifierSchemeCodeUnspsc190501.50435500'),
	'identifierSchemeCodeUnspsc190501.50435501': __('identifierSchemeCodeUnspsc190501.50435501'),
	'identifierSchemeCodeUnspsc190501.50435502': __('identifierSchemeCodeUnspsc190501.50435502'),
	'identifierSchemeCodeUnspsc190501.50435503': __('identifierSchemeCodeUnspsc190501.50435503'),
	'identifierSchemeCodeUnspsc190501.50435504': __('identifierSchemeCodeUnspsc190501.50435504'),
	'identifierSchemeCodeUnspsc190501.50435505': __('identifierSchemeCodeUnspsc190501.50435505'),
	'identifierSchemeCodeUnspsc190501.50435506': __('identifierSchemeCodeUnspsc190501.50435506'),
	'identifierSchemeCodeUnspsc190501.50435507': __('identifierSchemeCodeUnspsc190501.50435507'),
	'identifierSchemeCodeUnspsc190501.50435508': __('identifierSchemeCodeUnspsc190501.50435508'),
	'identifierSchemeCodeUnspsc190501.50435509': __('identifierSchemeCodeUnspsc190501.50435509'),
	'identifierSchemeCodeUnspsc190501.50435510': __('identifierSchemeCodeUnspsc190501.50435510'),
	'identifierSchemeCodeUnspsc190501.50435511': __('identifierSchemeCodeUnspsc190501.50435511'),
	'identifierSchemeCodeUnspsc190501.50435512': __('identifierSchemeCodeUnspsc190501.50435512'),
	'identifierSchemeCodeUnspsc190501.50435513': __('identifierSchemeCodeUnspsc190501.50435513'),
	'identifierSchemeCodeUnspsc190501.50435514': __('identifierSchemeCodeUnspsc190501.50435514'),
	'identifierSchemeCodeUnspsc190501.50435515': __('identifierSchemeCodeUnspsc190501.50435515'),
	'identifierSchemeCodeUnspsc190501.50435516': __('identifierSchemeCodeUnspsc190501.50435516'),
	'identifierSchemeCodeUnspsc190501.50435517': __('identifierSchemeCodeUnspsc190501.50435517'),
	'identifierSchemeCodeUnspsc190501.50435518': __('identifierSchemeCodeUnspsc190501.50435518'),
	'identifierSchemeCodeUnspsc190501.50435519': __('identifierSchemeCodeUnspsc190501.50435519'),
	'identifierSchemeCodeUnspsc190501.50435520': __('identifierSchemeCodeUnspsc190501.50435520'),
	'identifierSchemeCodeUnspsc190501.50435521': __('identifierSchemeCodeUnspsc190501.50435521'),
	'identifierSchemeCodeUnspsc190501.50435522': __('identifierSchemeCodeUnspsc190501.50435522'),
	'identifierSchemeCodeUnspsc190501.50435523': __('identifierSchemeCodeUnspsc190501.50435523'),
	'identifierSchemeCodeUnspsc190501.50435524': __('identifierSchemeCodeUnspsc190501.50435524'),
	'identifierSchemeCodeUnspsc190501.50435525': __('identifierSchemeCodeUnspsc190501.50435525'),
	'identifierSchemeCodeUnspsc190501.50435526': __('identifierSchemeCodeUnspsc190501.50435526'),
	'identifierSchemeCodeUnspsc190501.50435527': __('identifierSchemeCodeUnspsc190501.50435527'),
	'identifierSchemeCodeUnspsc190501.50435528': __('identifierSchemeCodeUnspsc190501.50435528'),
	'identifierSchemeCodeUnspsc190501.50435529': __('identifierSchemeCodeUnspsc190501.50435529'),
	'identifierSchemeCodeUnspsc190501.50435530': __('identifierSchemeCodeUnspsc190501.50435530'),
	'identifierSchemeCodeUnspsc190501.50435531': __('identifierSchemeCodeUnspsc190501.50435531'),
	'identifierSchemeCodeUnspsc190501.50435532': __('identifierSchemeCodeUnspsc190501.50435532'),
	'identifierSchemeCodeUnspsc190501.50435533': __('identifierSchemeCodeUnspsc190501.50435533'),
	'identifierSchemeCodeUnspsc190501.50435534': __('identifierSchemeCodeUnspsc190501.50435534'),
	'identifierSchemeCodeUnspsc190501.50435535': __('identifierSchemeCodeUnspsc190501.50435535'),
	'identifierSchemeCodeUnspsc190501.50435536': __('identifierSchemeCodeUnspsc190501.50435536'),
	'identifierSchemeCodeUnspsc190501.50435537': __('identifierSchemeCodeUnspsc190501.50435537'),
	'identifierSchemeCodeUnspsc190501.50435600': __('identifierSchemeCodeUnspsc190501.50435600'),
	'identifierSchemeCodeUnspsc190501.50435601': __('identifierSchemeCodeUnspsc190501.50435601'),
	'identifierSchemeCodeUnspsc190501.50435602': __('identifierSchemeCodeUnspsc190501.50435602'),
	'identifierSchemeCodeUnspsc190501.50435603': __('identifierSchemeCodeUnspsc190501.50435603'),
	'identifierSchemeCodeUnspsc190501.50435604': __('identifierSchemeCodeUnspsc190501.50435604'),
	'identifierSchemeCodeUnspsc190501.50435605': __('identifierSchemeCodeUnspsc190501.50435605'),
	'identifierSchemeCodeUnspsc190501.50435606': __('identifierSchemeCodeUnspsc190501.50435606'),
	'identifierSchemeCodeUnspsc190501.50435607': __('identifierSchemeCodeUnspsc190501.50435607'),
	'identifierSchemeCodeUnspsc190501.50435608': __('identifierSchemeCodeUnspsc190501.50435608'),
	'identifierSchemeCodeUnspsc190501.50435700': __('identifierSchemeCodeUnspsc190501.50435700'),
	'identifierSchemeCodeUnspsc190501.50435701': __('identifierSchemeCodeUnspsc190501.50435701'),
	'identifierSchemeCodeUnspsc190501.50435702': __('identifierSchemeCodeUnspsc190501.50435702'),
	'identifierSchemeCodeUnspsc190501.50435703': __('identifierSchemeCodeUnspsc190501.50435703'),
	'identifierSchemeCodeUnspsc190501.50435704': __('identifierSchemeCodeUnspsc190501.50435704'),
	'identifierSchemeCodeUnspsc190501.50435800': __('identifierSchemeCodeUnspsc190501.50435800'),
	'identifierSchemeCodeUnspsc190501.50435801': __('identifierSchemeCodeUnspsc190501.50435801'),
	'identifierSchemeCodeUnspsc190501.50435802': __('identifierSchemeCodeUnspsc190501.50435802'),
	'identifierSchemeCodeUnspsc190501.50435803': __('identifierSchemeCodeUnspsc190501.50435803'),
	'identifierSchemeCodeUnspsc190501.50435804': __('identifierSchemeCodeUnspsc190501.50435804'),
	'identifierSchemeCodeUnspsc190501.50435805': __('identifierSchemeCodeUnspsc190501.50435805'),
	'identifierSchemeCodeUnspsc190501.50435806': __('identifierSchemeCodeUnspsc190501.50435806'),
	'identifierSchemeCodeUnspsc190501.50435807': __('identifierSchemeCodeUnspsc190501.50435807'),
	'identifierSchemeCodeUnspsc190501.50435808': __('identifierSchemeCodeUnspsc190501.50435808'),
	'identifierSchemeCodeUnspsc190501.50435809': __('identifierSchemeCodeUnspsc190501.50435809'),
	'identifierSchemeCodeUnspsc190501.50435810': __('identifierSchemeCodeUnspsc190501.50435810'),
	'identifierSchemeCodeUnspsc190501.50435811': __('identifierSchemeCodeUnspsc190501.50435811'),
	'identifierSchemeCodeUnspsc190501.50435812': __('identifierSchemeCodeUnspsc190501.50435812'),
	'identifierSchemeCodeUnspsc190501.50435813': __('identifierSchemeCodeUnspsc190501.50435813'),
	'identifierSchemeCodeUnspsc190501.50435814': __('identifierSchemeCodeUnspsc190501.50435814'),
	'identifierSchemeCodeUnspsc190501.50435815': __('identifierSchemeCodeUnspsc190501.50435815'),
	'identifierSchemeCodeUnspsc190501.50435816': __('identifierSchemeCodeUnspsc190501.50435816'),
	'identifierSchemeCodeUnspsc190501.50435900': __('identifierSchemeCodeUnspsc190501.50435900'),
	'identifierSchemeCodeUnspsc190501.50435901': __('identifierSchemeCodeUnspsc190501.50435901'),
	'identifierSchemeCodeUnspsc190501.50435902': __('identifierSchemeCodeUnspsc190501.50435902'),
	'identifierSchemeCodeUnspsc190501.50435903': __('identifierSchemeCodeUnspsc190501.50435903'),
	'identifierSchemeCodeUnspsc190501.50435904': __('identifierSchemeCodeUnspsc190501.50435904'),
	'identifierSchemeCodeUnspsc190501.50435905': __('identifierSchemeCodeUnspsc190501.50435905'),
	'identifierSchemeCodeUnspsc190501.50435906': __('identifierSchemeCodeUnspsc190501.50435906'),
	'identifierSchemeCodeUnspsc190501.50435907': __('identifierSchemeCodeUnspsc190501.50435907'),
	'identifierSchemeCodeUnspsc190501.50435908': __('identifierSchemeCodeUnspsc190501.50435908'),
	'identifierSchemeCodeUnspsc190501.50435909': __('identifierSchemeCodeUnspsc190501.50435909'),
	'identifierSchemeCodeUnspsc190501.50435910': __('identifierSchemeCodeUnspsc190501.50435910'),
	'identifierSchemeCodeUnspsc190501.50435911': __('identifierSchemeCodeUnspsc190501.50435911'),
	'identifierSchemeCodeUnspsc190501.50436000': __('identifierSchemeCodeUnspsc190501.50436000'),
	'identifierSchemeCodeUnspsc190501.50436001': __('identifierSchemeCodeUnspsc190501.50436001'),
	'identifierSchemeCodeUnspsc190501.50436002': __('identifierSchemeCodeUnspsc190501.50436002'),
	'identifierSchemeCodeUnspsc190501.50436003': __('identifierSchemeCodeUnspsc190501.50436003'),
	'identifierSchemeCodeUnspsc190501.50436004': __('identifierSchemeCodeUnspsc190501.50436004'),
	'identifierSchemeCodeUnspsc190501.50436100': __('identifierSchemeCodeUnspsc190501.50436100'),
	'identifierSchemeCodeUnspsc190501.50436101': __('identifierSchemeCodeUnspsc190501.50436101'),
	'identifierSchemeCodeUnspsc190501.50436102': __('identifierSchemeCodeUnspsc190501.50436102'),
	'identifierSchemeCodeUnspsc190501.50436103': __('identifierSchemeCodeUnspsc190501.50436103'),
	'identifierSchemeCodeUnspsc190501.50436104': __('identifierSchemeCodeUnspsc190501.50436104'),
	'identifierSchemeCodeUnspsc190501.50436105': __('identifierSchemeCodeUnspsc190501.50436105'),
	'identifierSchemeCodeUnspsc190501.50436106': __('identifierSchemeCodeUnspsc190501.50436106'),
	'identifierSchemeCodeUnspsc190501.50436107': __('identifierSchemeCodeUnspsc190501.50436107'),
	'identifierSchemeCodeUnspsc190501.50436108': __('identifierSchemeCodeUnspsc190501.50436108'),
	'identifierSchemeCodeUnspsc190501.50436109': __('identifierSchemeCodeUnspsc190501.50436109'),
	'identifierSchemeCodeUnspsc190501.50436110': __('identifierSchemeCodeUnspsc190501.50436110'),
	'identifierSchemeCodeUnspsc190501.50436111': __('identifierSchemeCodeUnspsc190501.50436111'),
	'identifierSchemeCodeUnspsc190501.50436112': __('identifierSchemeCodeUnspsc190501.50436112'),
	'identifierSchemeCodeUnspsc190501.50436113': __('identifierSchemeCodeUnspsc190501.50436113'),
	'identifierSchemeCodeUnspsc190501.50436114': __('identifierSchemeCodeUnspsc190501.50436114'),
	'identifierSchemeCodeUnspsc190501.50436115': __('identifierSchemeCodeUnspsc190501.50436115'),
	'identifierSchemeCodeUnspsc190501.50436116': __('identifierSchemeCodeUnspsc190501.50436116'),
	'identifierSchemeCodeUnspsc190501.50436200': __('identifierSchemeCodeUnspsc190501.50436200'),
	'identifierSchemeCodeUnspsc190501.50436201': __('identifierSchemeCodeUnspsc190501.50436201'),
	'identifierSchemeCodeUnspsc190501.50436202': __('identifierSchemeCodeUnspsc190501.50436202'),
	'identifierSchemeCodeUnspsc190501.50436203': __('identifierSchemeCodeUnspsc190501.50436203'),
	'identifierSchemeCodeUnspsc190501.50436204': __('identifierSchemeCodeUnspsc190501.50436204'),
	'identifierSchemeCodeUnspsc190501.50436205': __('identifierSchemeCodeUnspsc190501.50436205'),
	'identifierSchemeCodeUnspsc190501.50436206': __('identifierSchemeCodeUnspsc190501.50436206'),
	'identifierSchemeCodeUnspsc190501.50436207': __('identifierSchemeCodeUnspsc190501.50436207'),
	'identifierSchemeCodeUnspsc190501.50436208': __('identifierSchemeCodeUnspsc190501.50436208'),
	'identifierSchemeCodeUnspsc190501.50436209': __('identifierSchemeCodeUnspsc190501.50436209'),
	'identifierSchemeCodeUnspsc190501.50436210': __('identifierSchemeCodeUnspsc190501.50436210'),
	'identifierSchemeCodeUnspsc190501.50436211': __('identifierSchemeCodeUnspsc190501.50436211'),
	'identifierSchemeCodeUnspsc190501.50436212': __('identifierSchemeCodeUnspsc190501.50436212'),
	'identifierSchemeCodeUnspsc190501.50436213': __('identifierSchemeCodeUnspsc190501.50436213'),
	'identifierSchemeCodeUnspsc190501.50436214': __('identifierSchemeCodeUnspsc190501.50436214'),
	'identifierSchemeCodeUnspsc190501.50436215': __('identifierSchemeCodeUnspsc190501.50436215'),
	'identifierSchemeCodeUnspsc190501.50436216': __('identifierSchemeCodeUnspsc190501.50436216'),
	'identifierSchemeCodeUnspsc190501.50436217': __('identifierSchemeCodeUnspsc190501.50436217'),
	'identifierSchemeCodeUnspsc190501.50436218': __('identifierSchemeCodeUnspsc190501.50436218'),
	'identifierSchemeCodeUnspsc190501.50436219': __('identifierSchemeCodeUnspsc190501.50436219'),
	'identifierSchemeCodeUnspsc190501.50436220': __('identifierSchemeCodeUnspsc190501.50436220'),
	'identifierSchemeCodeUnspsc190501.50436221': __('identifierSchemeCodeUnspsc190501.50436221'),
	'identifierSchemeCodeUnspsc190501.50436222': __('identifierSchemeCodeUnspsc190501.50436222'),
	'identifierSchemeCodeUnspsc190501.50436223': __('identifierSchemeCodeUnspsc190501.50436223'),
	'identifierSchemeCodeUnspsc190501.50436224': __('identifierSchemeCodeUnspsc190501.50436224'),
	'identifierSchemeCodeUnspsc190501.50436225': __('identifierSchemeCodeUnspsc190501.50436225'),
	'identifierSchemeCodeUnspsc190501.50436226': __('identifierSchemeCodeUnspsc190501.50436226'),
	'identifierSchemeCodeUnspsc190501.50436227': __('identifierSchemeCodeUnspsc190501.50436227'),
	'identifierSchemeCodeUnspsc190501.50436228': __('identifierSchemeCodeUnspsc190501.50436228'),
	'identifierSchemeCodeUnspsc190501.50436229': __('identifierSchemeCodeUnspsc190501.50436229'),
	'identifierSchemeCodeUnspsc190501.50436300': __('identifierSchemeCodeUnspsc190501.50436300'),
	'identifierSchemeCodeUnspsc190501.50436301': __('identifierSchemeCodeUnspsc190501.50436301'),
	'identifierSchemeCodeUnspsc190501.50436302': __('identifierSchemeCodeUnspsc190501.50436302'),
	'identifierSchemeCodeUnspsc190501.50436303': __('identifierSchemeCodeUnspsc190501.50436303'),
	'identifierSchemeCodeUnspsc190501.50436304': __('identifierSchemeCodeUnspsc190501.50436304'),
	'identifierSchemeCodeUnspsc190501.50436305': __('identifierSchemeCodeUnspsc190501.50436305'),
	'identifierSchemeCodeUnspsc190501.50436306': __('identifierSchemeCodeUnspsc190501.50436306'),
	'identifierSchemeCodeUnspsc190501.50436307': __('identifierSchemeCodeUnspsc190501.50436307'),
	'identifierSchemeCodeUnspsc190501.50436308': __('identifierSchemeCodeUnspsc190501.50436308'),
	'identifierSchemeCodeUnspsc190501.50436309': __('identifierSchemeCodeUnspsc190501.50436309'),
	'identifierSchemeCodeUnspsc190501.50436310': __('identifierSchemeCodeUnspsc190501.50436310'),
	'identifierSchemeCodeUnspsc190501.50436311': __('identifierSchemeCodeUnspsc190501.50436311'),
	'identifierSchemeCodeUnspsc190501.50436312': __('identifierSchemeCodeUnspsc190501.50436312'),
	'identifierSchemeCodeUnspsc190501.50436313': __('identifierSchemeCodeUnspsc190501.50436313'),
	'identifierSchemeCodeUnspsc190501.50436314': __('identifierSchemeCodeUnspsc190501.50436314'),
	'identifierSchemeCodeUnspsc190501.50436315': __('identifierSchemeCodeUnspsc190501.50436315'),
	'identifierSchemeCodeUnspsc190501.50436316': __('identifierSchemeCodeUnspsc190501.50436316'),
	'identifierSchemeCodeUnspsc190501.50436317': __('identifierSchemeCodeUnspsc190501.50436317'),
	'identifierSchemeCodeUnspsc190501.50436318': __('identifierSchemeCodeUnspsc190501.50436318'),
	'identifierSchemeCodeUnspsc190501.50436319': __('identifierSchemeCodeUnspsc190501.50436319'),
	'identifierSchemeCodeUnspsc190501.50436320': __('identifierSchemeCodeUnspsc190501.50436320'),
	'identifierSchemeCodeUnspsc190501.50436400': __('identifierSchemeCodeUnspsc190501.50436400'),
	'identifierSchemeCodeUnspsc190501.50436401': __('identifierSchemeCodeUnspsc190501.50436401'),
	'identifierSchemeCodeUnspsc190501.50436402': __('identifierSchemeCodeUnspsc190501.50436402'),
	'identifierSchemeCodeUnspsc190501.50436403': __('identifierSchemeCodeUnspsc190501.50436403'),
	'identifierSchemeCodeUnspsc190501.50436404': __('identifierSchemeCodeUnspsc190501.50436404'),
	'identifierSchemeCodeUnspsc190501.50436405': __('identifierSchemeCodeUnspsc190501.50436405'),
	'identifierSchemeCodeUnspsc190501.50436406': __('identifierSchemeCodeUnspsc190501.50436406'),
	'identifierSchemeCodeUnspsc190501.50436407': __('identifierSchemeCodeUnspsc190501.50436407'),
	'identifierSchemeCodeUnspsc190501.50436408': __('identifierSchemeCodeUnspsc190501.50436408'),
	'identifierSchemeCodeUnspsc190501.50436409': __('identifierSchemeCodeUnspsc190501.50436409'),
	'identifierSchemeCodeUnspsc190501.50436410': __('identifierSchemeCodeUnspsc190501.50436410'),
	'identifierSchemeCodeUnspsc190501.50436411': __('identifierSchemeCodeUnspsc190501.50436411'),
	'identifierSchemeCodeUnspsc190501.50436412': __('identifierSchemeCodeUnspsc190501.50436412'),
	'identifierSchemeCodeUnspsc190501.50436413': __('identifierSchemeCodeUnspsc190501.50436413'),
	'identifierSchemeCodeUnspsc190501.50436414': __('identifierSchemeCodeUnspsc190501.50436414'),
	'identifierSchemeCodeUnspsc190501.50436415': __('identifierSchemeCodeUnspsc190501.50436415'),
	'identifierSchemeCodeUnspsc190501.50436416': __('identifierSchemeCodeUnspsc190501.50436416'),
	'identifierSchemeCodeUnspsc190501.50436417': __('identifierSchemeCodeUnspsc190501.50436417'),
	'identifierSchemeCodeUnspsc190501.50436418': __('identifierSchemeCodeUnspsc190501.50436418'),
	'identifierSchemeCodeUnspsc190501.50436419': __('identifierSchemeCodeUnspsc190501.50436419'),
	'identifierSchemeCodeUnspsc190501.50436420': __('identifierSchemeCodeUnspsc190501.50436420'),
	'identifierSchemeCodeUnspsc190501.50436421': __('identifierSchemeCodeUnspsc190501.50436421'),
	'identifierSchemeCodeUnspsc190501.50436422': __('identifierSchemeCodeUnspsc190501.50436422'),
	'identifierSchemeCodeUnspsc190501.50436423': __('identifierSchemeCodeUnspsc190501.50436423'),
	'identifierSchemeCodeUnspsc190501.50436424': __('identifierSchemeCodeUnspsc190501.50436424'),
	'identifierSchemeCodeUnspsc190501.50436425': __('identifierSchemeCodeUnspsc190501.50436425'),
	'identifierSchemeCodeUnspsc190501.50436426': __('identifierSchemeCodeUnspsc190501.50436426'),
	'identifierSchemeCodeUnspsc190501.50436427': __('identifierSchemeCodeUnspsc190501.50436427'),
	'identifierSchemeCodeUnspsc190501.50436500': __('identifierSchemeCodeUnspsc190501.50436500'),
	'identifierSchemeCodeUnspsc190501.50436501': __('identifierSchemeCodeUnspsc190501.50436501'),
	'identifierSchemeCodeUnspsc190501.50436502': __('identifierSchemeCodeUnspsc190501.50436502'),
	'identifierSchemeCodeUnspsc190501.50436503': __('identifierSchemeCodeUnspsc190501.50436503'),
	'identifierSchemeCodeUnspsc190501.50436504': __('identifierSchemeCodeUnspsc190501.50436504'),
	'identifierSchemeCodeUnspsc190501.50436505': __('identifierSchemeCodeUnspsc190501.50436505'),
	'identifierSchemeCodeUnspsc190501.50436506': __('identifierSchemeCodeUnspsc190501.50436506'),
	'identifierSchemeCodeUnspsc190501.50436507': __('identifierSchemeCodeUnspsc190501.50436507'),
	'identifierSchemeCodeUnspsc190501.50436508': __('identifierSchemeCodeUnspsc190501.50436508'),
	'identifierSchemeCodeUnspsc190501.50436600': __('identifierSchemeCodeUnspsc190501.50436600'),
	'identifierSchemeCodeUnspsc190501.50436601': __('identifierSchemeCodeUnspsc190501.50436601'),
	'identifierSchemeCodeUnspsc190501.50436602': __('identifierSchemeCodeUnspsc190501.50436602'),
	'identifierSchemeCodeUnspsc190501.50436603': __('identifierSchemeCodeUnspsc190501.50436603'),
	'identifierSchemeCodeUnspsc190501.50436604': __('identifierSchemeCodeUnspsc190501.50436604'),
	'identifierSchemeCodeUnspsc190501.50436605': __('identifierSchemeCodeUnspsc190501.50436605'),
	'identifierSchemeCodeUnspsc190501.50436606': __('identifierSchemeCodeUnspsc190501.50436606'),
	'identifierSchemeCodeUnspsc190501.50436607': __('identifierSchemeCodeUnspsc190501.50436607'),
	'identifierSchemeCodeUnspsc190501.50436608': __('identifierSchemeCodeUnspsc190501.50436608'),
	'identifierSchemeCodeUnspsc190501.50436609': __('identifierSchemeCodeUnspsc190501.50436609'),
	'identifierSchemeCodeUnspsc190501.50436610': __('identifierSchemeCodeUnspsc190501.50436610'),
	'identifierSchemeCodeUnspsc190501.50436611': __('identifierSchemeCodeUnspsc190501.50436611'),
	'identifierSchemeCodeUnspsc190501.50436612': __('identifierSchemeCodeUnspsc190501.50436612'),
	'identifierSchemeCodeUnspsc190501.50436613': __('identifierSchemeCodeUnspsc190501.50436613'),
	'identifierSchemeCodeUnspsc190501.50436614': __('identifierSchemeCodeUnspsc190501.50436614'),
	'identifierSchemeCodeUnspsc190501.50436615': __('identifierSchemeCodeUnspsc190501.50436615'),
	'identifierSchemeCodeUnspsc190501.50436616': __('identifierSchemeCodeUnspsc190501.50436616'),
	'identifierSchemeCodeUnspsc190501.50436617': __('identifierSchemeCodeUnspsc190501.50436617'),
	'identifierSchemeCodeUnspsc190501.50436618': __('identifierSchemeCodeUnspsc190501.50436618'),
	'identifierSchemeCodeUnspsc190501.50436619': __('identifierSchemeCodeUnspsc190501.50436619'),
	'identifierSchemeCodeUnspsc190501.50436620': __('identifierSchemeCodeUnspsc190501.50436620'),
	'identifierSchemeCodeUnspsc190501.50436700': __('identifierSchemeCodeUnspsc190501.50436700'),
	'identifierSchemeCodeUnspsc190501.50436701': __('identifierSchemeCodeUnspsc190501.50436701'),
	'identifierSchemeCodeUnspsc190501.50436702': __('identifierSchemeCodeUnspsc190501.50436702'),
	'identifierSchemeCodeUnspsc190501.50436703': __('identifierSchemeCodeUnspsc190501.50436703'),
	'identifierSchemeCodeUnspsc190501.50436704': __('identifierSchemeCodeUnspsc190501.50436704'),
	'identifierSchemeCodeUnspsc190501.50436705': __('identifierSchemeCodeUnspsc190501.50436705'),
	'identifierSchemeCodeUnspsc190501.50436706': __('identifierSchemeCodeUnspsc190501.50436706'),
	'identifierSchemeCodeUnspsc190501.50436707': __('identifierSchemeCodeUnspsc190501.50436707'),
	'identifierSchemeCodeUnspsc190501.50436708': __('identifierSchemeCodeUnspsc190501.50436708'),
	'identifierSchemeCodeUnspsc190501.50436709': __('identifierSchemeCodeUnspsc190501.50436709'),
	'identifierSchemeCodeUnspsc190501.50436710': __('identifierSchemeCodeUnspsc190501.50436710'),
	'identifierSchemeCodeUnspsc190501.50436800': __('identifierSchemeCodeUnspsc190501.50436800'),
	'identifierSchemeCodeUnspsc190501.50436801': __('identifierSchemeCodeUnspsc190501.50436801'),
	'identifierSchemeCodeUnspsc190501.50436802': __('identifierSchemeCodeUnspsc190501.50436802'),
	'identifierSchemeCodeUnspsc190501.50436803': __('identifierSchemeCodeUnspsc190501.50436803'),
	'identifierSchemeCodeUnspsc190501.50436804': __('identifierSchemeCodeUnspsc190501.50436804'),
	'identifierSchemeCodeUnspsc190501.50436805': __('identifierSchemeCodeUnspsc190501.50436805'),
	'identifierSchemeCodeUnspsc190501.50436806': __('identifierSchemeCodeUnspsc190501.50436806'),
	'identifierSchemeCodeUnspsc190501.50436807': __('identifierSchemeCodeUnspsc190501.50436807'),
	'identifierSchemeCodeUnspsc190501.50436808': __('identifierSchemeCodeUnspsc190501.50436808'),
	'identifierSchemeCodeUnspsc190501.50436809': __('identifierSchemeCodeUnspsc190501.50436809'),
	'identifierSchemeCodeUnspsc190501.50436810': __('identifierSchemeCodeUnspsc190501.50436810'),
	'identifierSchemeCodeUnspsc190501.50436811': __('identifierSchemeCodeUnspsc190501.50436811'),
	'identifierSchemeCodeUnspsc190501.50436812': __('identifierSchemeCodeUnspsc190501.50436812'),
	'identifierSchemeCodeUnspsc190501.50436813': __('identifierSchemeCodeUnspsc190501.50436813'),
	'identifierSchemeCodeUnspsc190501.50436814': __('identifierSchemeCodeUnspsc190501.50436814'),
	'identifierSchemeCodeUnspsc190501.50436815': __('identifierSchemeCodeUnspsc190501.50436815'),
	'identifierSchemeCodeUnspsc190501.50436816': __('identifierSchemeCodeUnspsc190501.50436816'),
	'identifierSchemeCodeUnspsc190501.50436817': __('identifierSchemeCodeUnspsc190501.50436817'),
	'identifierSchemeCodeUnspsc190501.50436818': __('identifierSchemeCodeUnspsc190501.50436818'),
	'identifierSchemeCodeUnspsc190501.50436819': __('identifierSchemeCodeUnspsc190501.50436819'),
	'identifierSchemeCodeUnspsc190501.50436820': __('identifierSchemeCodeUnspsc190501.50436820'),
	'identifierSchemeCodeUnspsc190501.50436821': __('identifierSchemeCodeUnspsc190501.50436821'),
	'identifierSchemeCodeUnspsc190501.50436822': __('identifierSchemeCodeUnspsc190501.50436822'),
	'identifierSchemeCodeUnspsc190501.50436823': __('identifierSchemeCodeUnspsc190501.50436823'),
	'identifierSchemeCodeUnspsc190501.50436824': __('identifierSchemeCodeUnspsc190501.50436824'),
	'identifierSchemeCodeUnspsc190501.50436825': __('identifierSchemeCodeUnspsc190501.50436825'),
	'identifierSchemeCodeUnspsc190501.50436826': __('identifierSchemeCodeUnspsc190501.50436826'),
	'identifierSchemeCodeUnspsc190501.50436827': __('identifierSchemeCodeUnspsc190501.50436827'),
	'identifierSchemeCodeUnspsc190501.50436828': __('identifierSchemeCodeUnspsc190501.50436828'),
	'identifierSchemeCodeUnspsc190501.50436829': __('identifierSchemeCodeUnspsc190501.50436829'),
	'identifierSchemeCodeUnspsc190501.50436830': __('identifierSchemeCodeUnspsc190501.50436830'),
	'identifierSchemeCodeUnspsc190501.50436831': __('identifierSchemeCodeUnspsc190501.50436831'),
	'identifierSchemeCodeUnspsc190501.50436832': __('identifierSchemeCodeUnspsc190501.50436832'),
	'identifierSchemeCodeUnspsc190501.50436833': __('identifierSchemeCodeUnspsc190501.50436833'),
	'identifierSchemeCodeUnspsc190501.50436834': __('identifierSchemeCodeUnspsc190501.50436834'),
	'identifierSchemeCodeUnspsc190501.50436835': __('identifierSchemeCodeUnspsc190501.50436835'),
	'identifierSchemeCodeUnspsc190501.50436836': __('identifierSchemeCodeUnspsc190501.50436836'),
	'identifierSchemeCodeUnspsc190501.50436837': __('identifierSchemeCodeUnspsc190501.50436837'),
	'identifierSchemeCodeUnspsc190501.50436838': __('identifierSchemeCodeUnspsc190501.50436838'),
	'identifierSchemeCodeUnspsc190501.50436839': __('identifierSchemeCodeUnspsc190501.50436839'),
	'identifierSchemeCodeUnspsc190501.50436840': __('identifierSchemeCodeUnspsc190501.50436840'),
	'identifierSchemeCodeUnspsc190501.50436841': __('identifierSchemeCodeUnspsc190501.50436841'),
	'identifierSchemeCodeUnspsc190501.50436842': __('identifierSchemeCodeUnspsc190501.50436842'),
	'identifierSchemeCodeUnspsc190501.50436843': __('identifierSchemeCodeUnspsc190501.50436843'),
	'identifierSchemeCodeUnspsc190501.50436844': __('identifierSchemeCodeUnspsc190501.50436844'),
	'identifierSchemeCodeUnspsc190501.50436845': __('identifierSchemeCodeUnspsc190501.50436845'),
	'identifierSchemeCodeUnspsc190501.50436846': __('identifierSchemeCodeUnspsc190501.50436846'),
	'identifierSchemeCodeUnspsc190501.50436847': __('identifierSchemeCodeUnspsc190501.50436847'),
	'identifierSchemeCodeUnspsc190501.50436848': __('identifierSchemeCodeUnspsc190501.50436848'),
	'identifierSchemeCodeUnspsc190501.50436849': __('identifierSchemeCodeUnspsc190501.50436849'),
	'identifierSchemeCodeUnspsc190501.50436850': __('identifierSchemeCodeUnspsc190501.50436850'),
	'identifierSchemeCodeUnspsc190501.50436851': __('identifierSchemeCodeUnspsc190501.50436851'),
	'identifierSchemeCodeUnspsc190501.50436852': __('identifierSchemeCodeUnspsc190501.50436852'),
	'identifierSchemeCodeUnspsc190501.50436853': __('identifierSchemeCodeUnspsc190501.50436853'),
	'identifierSchemeCodeUnspsc190501.50436854': __('identifierSchemeCodeUnspsc190501.50436854'),
	'identifierSchemeCodeUnspsc190501.50436855': __('identifierSchemeCodeUnspsc190501.50436855'),
	'identifierSchemeCodeUnspsc190501.50436856': __('identifierSchemeCodeUnspsc190501.50436856'),
	'identifierSchemeCodeUnspsc190501.50436857': __('identifierSchemeCodeUnspsc190501.50436857'),
	'identifierSchemeCodeUnspsc190501.50436858': __('identifierSchemeCodeUnspsc190501.50436858'),
	'identifierSchemeCodeUnspsc190501.50436859': __('identifierSchemeCodeUnspsc190501.50436859'),
	'identifierSchemeCodeUnspsc190501.50436860': __('identifierSchemeCodeUnspsc190501.50436860'),
	'identifierSchemeCodeUnspsc190501.50436861': __('identifierSchemeCodeUnspsc190501.50436861'),
	'identifierSchemeCodeUnspsc190501.50436862': __('identifierSchemeCodeUnspsc190501.50436862'),
	'identifierSchemeCodeUnspsc190501.50436863': __('identifierSchemeCodeUnspsc190501.50436863'),
	'identifierSchemeCodeUnspsc190501.50436864': __('identifierSchemeCodeUnspsc190501.50436864'),
	'identifierSchemeCodeUnspsc190501.50436865': __('identifierSchemeCodeUnspsc190501.50436865'),
	'identifierSchemeCodeUnspsc190501.50436866': __('identifierSchemeCodeUnspsc190501.50436866'),
	'identifierSchemeCodeUnspsc190501.50436867': __('identifierSchemeCodeUnspsc190501.50436867'),
	'identifierSchemeCodeUnspsc190501.50436868': __('identifierSchemeCodeUnspsc190501.50436868'),
	'identifierSchemeCodeUnspsc190501.50436900': __('identifierSchemeCodeUnspsc190501.50436900'),
	'identifierSchemeCodeUnspsc190501.50436901': __('identifierSchemeCodeUnspsc190501.50436901'),
	'identifierSchemeCodeUnspsc190501.50436902': __('identifierSchemeCodeUnspsc190501.50436902'),
	'identifierSchemeCodeUnspsc190501.50436903': __('identifierSchemeCodeUnspsc190501.50436903'),
	'identifierSchemeCodeUnspsc190501.50436904': __('identifierSchemeCodeUnspsc190501.50436904'),
	'identifierSchemeCodeUnspsc190501.50436905': __('identifierSchemeCodeUnspsc190501.50436905'),
	'identifierSchemeCodeUnspsc190501.50436906': __('identifierSchemeCodeUnspsc190501.50436906'),
	'identifierSchemeCodeUnspsc190501.50436907': __('identifierSchemeCodeUnspsc190501.50436907'),
	'identifierSchemeCodeUnspsc190501.50436908': __('identifierSchemeCodeUnspsc190501.50436908'),
	'identifierSchemeCodeUnspsc190501.50436909': __('identifierSchemeCodeUnspsc190501.50436909'),
	'identifierSchemeCodeUnspsc190501.50436910': __('identifierSchemeCodeUnspsc190501.50436910'),
	'identifierSchemeCodeUnspsc190501.50440000': __('identifierSchemeCodeUnspsc190501.50440000'),
	'identifierSchemeCodeUnspsc190501.50441500': __('identifierSchemeCodeUnspsc190501.50441500'),
	'identifierSchemeCodeUnspsc190501.50441501': __('identifierSchemeCodeUnspsc190501.50441501'),
	'identifierSchemeCodeUnspsc190501.50441502': __('identifierSchemeCodeUnspsc190501.50441502'),
	'identifierSchemeCodeUnspsc190501.50441503': __('identifierSchemeCodeUnspsc190501.50441503'),
	'identifierSchemeCodeUnspsc190501.50441504': __('identifierSchemeCodeUnspsc190501.50441504'),
	'identifierSchemeCodeUnspsc190501.50441505': __('identifierSchemeCodeUnspsc190501.50441505'),
	'identifierSchemeCodeUnspsc190501.50441506': __('identifierSchemeCodeUnspsc190501.50441506'),
	'identifierSchemeCodeUnspsc190501.50441507': __('identifierSchemeCodeUnspsc190501.50441507'),
	'identifierSchemeCodeUnspsc190501.50441508': __('identifierSchemeCodeUnspsc190501.50441508'),
	'identifierSchemeCodeUnspsc190501.50441509': __('identifierSchemeCodeUnspsc190501.50441509'),
	'identifierSchemeCodeUnspsc190501.50441510': __('identifierSchemeCodeUnspsc190501.50441510'),
	'identifierSchemeCodeUnspsc190501.50441511': __('identifierSchemeCodeUnspsc190501.50441511'),
	'identifierSchemeCodeUnspsc190501.50441512': __('identifierSchemeCodeUnspsc190501.50441512'),
	'identifierSchemeCodeUnspsc190501.50441513': __('identifierSchemeCodeUnspsc190501.50441513'),
	'identifierSchemeCodeUnspsc190501.50441600': __('identifierSchemeCodeUnspsc190501.50441600'),
	'identifierSchemeCodeUnspsc190501.50441601': __('identifierSchemeCodeUnspsc190501.50441601'),
	'identifierSchemeCodeUnspsc190501.50441602': __('identifierSchemeCodeUnspsc190501.50441602'),
	'identifierSchemeCodeUnspsc190501.50441603': __('identifierSchemeCodeUnspsc190501.50441603'),
	'identifierSchemeCodeUnspsc190501.50441604': __('identifierSchemeCodeUnspsc190501.50441604'),
	'identifierSchemeCodeUnspsc190501.50441605': __('identifierSchemeCodeUnspsc190501.50441605'),
	'identifierSchemeCodeUnspsc190501.50441700': __('identifierSchemeCodeUnspsc190501.50441700'),
	'identifierSchemeCodeUnspsc190501.50441701': __('identifierSchemeCodeUnspsc190501.50441701'),
	'identifierSchemeCodeUnspsc190501.50441702': __('identifierSchemeCodeUnspsc190501.50441702'),
	'identifierSchemeCodeUnspsc190501.50441703': __('identifierSchemeCodeUnspsc190501.50441703'),
	'identifierSchemeCodeUnspsc190501.50441704': __('identifierSchemeCodeUnspsc190501.50441704'),
	'identifierSchemeCodeUnspsc190501.50441705': __('identifierSchemeCodeUnspsc190501.50441705'),
	'identifierSchemeCodeUnspsc190501.50441706': __('identifierSchemeCodeUnspsc190501.50441706'),
	'identifierSchemeCodeUnspsc190501.50441707': __('identifierSchemeCodeUnspsc190501.50441707'),
	'identifierSchemeCodeUnspsc190501.50441708': __('identifierSchemeCodeUnspsc190501.50441708'),
	'identifierSchemeCodeUnspsc190501.50441709': __('identifierSchemeCodeUnspsc190501.50441709'),
	'identifierSchemeCodeUnspsc190501.50441710': __('identifierSchemeCodeUnspsc190501.50441710'),
	'identifierSchemeCodeUnspsc190501.50441711': __('identifierSchemeCodeUnspsc190501.50441711'),
	'identifierSchemeCodeUnspsc190501.50441712': __('identifierSchemeCodeUnspsc190501.50441712'),
	'identifierSchemeCodeUnspsc190501.50441713': __('identifierSchemeCodeUnspsc190501.50441713'),
	'identifierSchemeCodeUnspsc190501.50441714': __('identifierSchemeCodeUnspsc190501.50441714'),
	'identifierSchemeCodeUnspsc190501.50441715': __('identifierSchemeCodeUnspsc190501.50441715'),
	'identifierSchemeCodeUnspsc190501.50441716': __('identifierSchemeCodeUnspsc190501.50441716'),
	'identifierSchemeCodeUnspsc190501.50441717': __('identifierSchemeCodeUnspsc190501.50441717'),
	'identifierSchemeCodeUnspsc190501.50441718': __('identifierSchemeCodeUnspsc190501.50441718'),
	'identifierSchemeCodeUnspsc190501.50441719': __('identifierSchemeCodeUnspsc190501.50441719'),
	'identifierSchemeCodeUnspsc190501.50441720': __('identifierSchemeCodeUnspsc190501.50441720'),
	'identifierSchemeCodeUnspsc190501.50441721': __('identifierSchemeCodeUnspsc190501.50441721'),
	'identifierSchemeCodeUnspsc190501.50441722': __('identifierSchemeCodeUnspsc190501.50441722'),
	'identifierSchemeCodeUnspsc190501.50441723': __('identifierSchemeCodeUnspsc190501.50441723'),
	'identifierSchemeCodeUnspsc190501.50441724': __('identifierSchemeCodeUnspsc190501.50441724'),
	'identifierSchemeCodeUnspsc190501.50441725': __('identifierSchemeCodeUnspsc190501.50441725'),
	'identifierSchemeCodeUnspsc190501.50441726': __('identifierSchemeCodeUnspsc190501.50441726'),
	'identifierSchemeCodeUnspsc190501.50441727': __('identifierSchemeCodeUnspsc190501.50441727'),
	'identifierSchemeCodeUnspsc190501.50441728': __('identifierSchemeCodeUnspsc190501.50441728'),
	'identifierSchemeCodeUnspsc190501.50441729': __('identifierSchemeCodeUnspsc190501.50441729'),
	'identifierSchemeCodeUnspsc190501.50441730': __('identifierSchemeCodeUnspsc190501.50441730'),
	'identifierSchemeCodeUnspsc190501.50441731': __('identifierSchemeCodeUnspsc190501.50441731'),
	'identifierSchemeCodeUnspsc190501.50441732': __('identifierSchemeCodeUnspsc190501.50441732'),
	'identifierSchemeCodeUnspsc190501.50441733': __('identifierSchemeCodeUnspsc190501.50441733'),
	'identifierSchemeCodeUnspsc190501.50441734': __('identifierSchemeCodeUnspsc190501.50441734'),
	'identifierSchemeCodeUnspsc190501.50441735': __('identifierSchemeCodeUnspsc190501.50441735'),
	'identifierSchemeCodeUnspsc190501.50441736': __('identifierSchemeCodeUnspsc190501.50441736'),
	'identifierSchemeCodeUnspsc190501.50441737': __('identifierSchemeCodeUnspsc190501.50441737'),
	'identifierSchemeCodeUnspsc190501.50441738': __('identifierSchemeCodeUnspsc190501.50441738'),
	'identifierSchemeCodeUnspsc190501.50441739': __('identifierSchemeCodeUnspsc190501.50441739'),
	'identifierSchemeCodeUnspsc190501.50441740': __('identifierSchemeCodeUnspsc190501.50441740'),
	'identifierSchemeCodeUnspsc190501.50441741': __('identifierSchemeCodeUnspsc190501.50441741'),
	'identifierSchemeCodeUnspsc190501.50441742': __('identifierSchemeCodeUnspsc190501.50441742'),
	'identifierSchemeCodeUnspsc190501.50441743': __('identifierSchemeCodeUnspsc190501.50441743'),
	'identifierSchemeCodeUnspsc190501.50441744': __('identifierSchemeCodeUnspsc190501.50441744'),
	'identifierSchemeCodeUnspsc190501.50441745': __('identifierSchemeCodeUnspsc190501.50441745'),
	'identifierSchemeCodeUnspsc190501.50441746': __('identifierSchemeCodeUnspsc190501.50441746'),
	'identifierSchemeCodeUnspsc190501.50441747': __('identifierSchemeCodeUnspsc190501.50441747'),
	'identifierSchemeCodeUnspsc190501.50441748': __('identifierSchemeCodeUnspsc190501.50441748'),
	'identifierSchemeCodeUnspsc190501.50441749': __('identifierSchemeCodeUnspsc190501.50441749'),
	'identifierSchemeCodeUnspsc190501.50441750': __('identifierSchemeCodeUnspsc190501.50441750'),
	'identifierSchemeCodeUnspsc190501.50441751': __('identifierSchemeCodeUnspsc190501.50441751'),
	'identifierSchemeCodeUnspsc190501.50441752': __('identifierSchemeCodeUnspsc190501.50441752'),
	'identifierSchemeCodeUnspsc190501.50441753': __('identifierSchemeCodeUnspsc190501.50441753'),
	'identifierSchemeCodeUnspsc190501.50441754': __('identifierSchemeCodeUnspsc190501.50441754'),
	'identifierSchemeCodeUnspsc190501.50441755': __('identifierSchemeCodeUnspsc190501.50441755'),
	'identifierSchemeCodeUnspsc190501.50441756': __('identifierSchemeCodeUnspsc190501.50441756'),
	'identifierSchemeCodeUnspsc190501.50441757': __('identifierSchemeCodeUnspsc190501.50441757'),
	'identifierSchemeCodeUnspsc190501.50441758': __('identifierSchemeCodeUnspsc190501.50441758'),
	'identifierSchemeCodeUnspsc190501.50441759': __('identifierSchemeCodeUnspsc190501.50441759'),
	'identifierSchemeCodeUnspsc190501.50441760': __('identifierSchemeCodeUnspsc190501.50441760'),
	'identifierSchemeCodeUnspsc190501.50441761': __('identifierSchemeCodeUnspsc190501.50441761'),
	'identifierSchemeCodeUnspsc190501.50441762': __('identifierSchemeCodeUnspsc190501.50441762'),
	'identifierSchemeCodeUnspsc190501.50441763': __('identifierSchemeCodeUnspsc190501.50441763'),
	'identifierSchemeCodeUnspsc190501.50441764': __('identifierSchemeCodeUnspsc190501.50441764'),
	'identifierSchemeCodeUnspsc190501.50441765': __('identifierSchemeCodeUnspsc190501.50441765'),
	'identifierSchemeCodeUnspsc190501.50441766': __('identifierSchemeCodeUnspsc190501.50441766'),
	'identifierSchemeCodeUnspsc190501.50441767': __('identifierSchemeCodeUnspsc190501.50441767'),
	'identifierSchemeCodeUnspsc190501.50441768': __('identifierSchemeCodeUnspsc190501.50441768'),
	'identifierSchemeCodeUnspsc190501.50441769': __('identifierSchemeCodeUnspsc190501.50441769'),
	'identifierSchemeCodeUnspsc190501.50441770': __('identifierSchemeCodeUnspsc190501.50441770'),
	'identifierSchemeCodeUnspsc190501.50441771': __('identifierSchemeCodeUnspsc190501.50441771'),
	'identifierSchemeCodeUnspsc190501.50441772': __('identifierSchemeCodeUnspsc190501.50441772'),
	'identifierSchemeCodeUnspsc190501.50441773': __('identifierSchemeCodeUnspsc190501.50441773'),
	'identifierSchemeCodeUnspsc190501.50441774': __('identifierSchemeCodeUnspsc190501.50441774'),
	'identifierSchemeCodeUnspsc190501.50441775': __('identifierSchemeCodeUnspsc190501.50441775'),
	'identifierSchemeCodeUnspsc190501.50441776': __('identifierSchemeCodeUnspsc190501.50441776'),
	'identifierSchemeCodeUnspsc190501.50441777': __('identifierSchemeCodeUnspsc190501.50441777'),
	'identifierSchemeCodeUnspsc190501.50441778': __('identifierSchemeCodeUnspsc190501.50441778'),
	'identifierSchemeCodeUnspsc190501.50441779': __('identifierSchemeCodeUnspsc190501.50441779'),
	'identifierSchemeCodeUnspsc190501.50441780': __('identifierSchemeCodeUnspsc190501.50441780'),
	'identifierSchemeCodeUnspsc190501.50441781': __('identifierSchemeCodeUnspsc190501.50441781'),
	'identifierSchemeCodeUnspsc190501.50441782': __('identifierSchemeCodeUnspsc190501.50441782'),
	'identifierSchemeCodeUnspsc190501.50441800': __('identifierSchemeCodeUnspsc190501.50441800'),
	'identifierSchemeCodeUnspsc190501.50441801': __('identifierSchemeCodeUnspsc190501.50441801'),
	'identifierSchemeCodeUnspsc190501.50441802': __('identifierSchemeCodeUnspsc190501.50441802'),
	'identifierSchemeCodeUnspsc190501.50441803': __('identifierSchemeCodeUnspsc190501.50441803'),
	'identifierSchemeCodeUnspsc190501.50441804': __('identifierSchemeCodeUnspsc190501.50441804'),
	'identifierSchemeCodeUnspsc190501.50441805': __('identifierSchemeCodeUnspsc190501.50441805'),
	'identifierSchemeCodeUnspsc190501.50441806': __('identifierSchemeCodeUnspsc190501.50441806'),
	'identifierSchemeCodeUnspsc190501.50441807': __('identifierSchemeCodeUnspsc190501.50441807'),
	'identifierSchemeCodeUnspsc190501.50441808': __('identifierSchemeCodeUnspsc190501.50441808'),
	'identifierSchemeCodeUnspsc190501.50441809': __('identifierSchemeCodeUnspsc190501.50441809'),
	'identifierSchemeCodeUnspsc190501.50441810': __('identifierSchemeCodeUnspsc190501.50441810'),
	'identifierSchemeCodeUnspsc190501.50441811': __('identifierSchemeCodeUnspsc190501.50441811'),
	'identifierSchemeCodeUnspsc190501.50441812': __('identifierSchemeCodeUnspsc190501.50441812'),
	'identifierSchemeCodeUnspsc190501.50441813': __('identifierSchemeCodeUnspsc190501.50441813'),
	'identifierSchemeCodeUnspsc190501.50441814': __('identifierSchemeCodeUnspsc190501.50441814'),
	'identifierSchemeCodeUnspsc190501.50441815': __('identifierSchemeCodeUnspsc190501.50441815'),
	'identifierSchemeCodeUnspsc190501.50441816': __('identifierSchemeCodeUnspsc190501.50441816'),
	'identifierSchemeCodeUnspsc190501.50441817': __('identifierSchemeCodeUnspsc190501.50441817'),
	'identifierSchemeCodeUnspsc190501.50441818': __('identifierSchemeCodeUnspsc190501.50441818'),
	'identifierSchemeCodeUnspsc190501.50441819': __('identifierSchemeCodeUnspsc190501.50441819'),
	'identifierSchemeCodeUnspsc190501.50441820': __('identifierSchemeCodeUnspsc190501.50441820'),
	'identifierSchemeCodeUnspsc190501.50441821': __('identifierSchemeCodeUnspsc190501.50441821'),
	'identifierSchemeCodeUnspsc190501.50441822': __('identifierSchemeCodeUnspsc190501.50441822'),
	'identifierSchemeCodeUnspsc190501.50441823': __('identifierSchemeCodeUnspsc190501.50441823'),
	'identifierSchemeCodeUnspsc190501.50441824': __('identifierSchemeCodeUnspsc190501.50441824'),
	'identifierSchemeCodeUnspsc190501.50441825': __('identifierSchemeCodeUnspsc190501.50441825'),
	'identifierSchemeCodeUnspsc190501.50441826': __('identifierSchemeCodeUnspsc190501.50441826'),
	'identifierSchemeCodeUnspsc190501.50441827': __('identifierSchemeCodeUnspsc190501.50441827'),
	'identifierSchemeCodeUnspsc190501.50441828': __('identifierSchemeCodeUnspsc190501.50441828'),
	'identifierSchemeCodeUnspsc190501.50441829': __('identifierSchemeCodeUnspsc190501.50441829'),
	'identifierSchemeCodeUnspsc190501.50441830': __('identifierSchemeCodeUnspsc190501.50441830'),
	'identifierSchemeCodeUnspsc190501.50441831': __('identifierSchemeCodeUnspsc190501.50441831'),
	'identifierSchemeCodeUnspsc190501.50441832': __('identifierSchemeCodeUnspsc190501.50441832'),
	'identifierSchemeCodeUnspsc190501.50441833': __('identifierSchemeCodeUnspsc190501.50441833'),
	'identifierSchemeCodeUnspsc190501.50441834': __('identifierSchemeCodeUnspsc190501.50441834'),
	'identifierSchemeCodeUnspsc190501.50441835': __('identifierSchemeCodeUnspsc190501.50441835'),
	'identifierSchemeCodeUnspsc190501.50441836': __('identifierSchemeCodeUnspsc190501.50441836'),
	'identifierSchemeCodeUnspsc190501.50441837': __('identifierSchemeCodeUnspsc190501.50441837'),
	'identifierSchemeCodeUnspsc190501.50441838': __('identifierSchemeCodeUnspsc190501.50441838'),
	'identifierSchemeCodeUnspsc190501.50441839': __('identifierSchemeCodeUnspsc190501.50441839'),
	'identifierSchemeCodeUnspsc190501.50441840': __('identifierSchemeCodeUnspsc190501.50441840'),
	'identifierSchemeCodeUnspsc190501.50441841': __('identifierSchemeCodeUnspsc190501.50441841'),
	'identifierSchemeCodeUnspsc190501.50441842': __('identifierSchemeCodeUnspsc190501.50441842'),
	'identifierSchemeCodeUnspsc190501.50441843': __('identifierSchemeCodeUnspsc190501.50441843'),
	'identifierSchemeCodeUnspsc190501.50441844': __('identifierSchemeCodeUnspsc190501.50441844'),
	'identifierSchemeCodeUnspsc190501.50441845': __('identifierSchemeCodeUnspsc190501.50441845'),
	'identifierSchemeCodeUnspsc190501.50441846': __('identifierSchemeCodeUnspsc190501.50441846'),
	'identifierSchemeCodeUnspsc190501.50441847': __('identifierSchemeCodeUnspsc190501.50441847'),
	'identifierSchemeCodeUnspsc190501.50441848': __('identifierSchemeCodeUnspsc190501.50441848'),
	'identifierSchemeCodeUnspsc190501.50441849': __('identifierSchemeCodeUnspsc190501.50441849'),
	'identifierSchemeCodeUnspsc190501.50441850': __('identifierSchemeCodeUnspsc190501.50441850'),
	'identifierSchemeCodeUnspsc190501.50441851': __('identifierSchemeCodeUnspsc190501.50441851'),
	'identifierSchemeCodeUnspsc190501.50441852': __('identifierSchemeCodeUnspsc190501.50441852'),
	'identifierSchemeCodeUnspsc190501.50441900': __('identifierSchemeCodeUnspsc190501.50441900'),
	'identifierSchemeCodeUnspsc190501.50441901': __('identifierSchemeCodeUnspsc190501.50441901'),
	'identifierSchemeCodeUnspsc190501.50441902': __('identifierSchemeCodeUnspsc190501.50441902'),
	'identifierSchemeCodeUnspsc190501.50441903': __('identifierSchemeCodeUnspsc190501.50441903'),
	'identifierSchemeCodeUnspsc190501.50441904': __('identifierSchemeCodeUnspsc190501.50441904'),
	'identifierSchemeCodeUnspsc190501.50441905': __('identifierSchemeCodeUnspsc190501.50441905'),
	'identifierSchemeCodeUnspsc190501.50441906': __('identifierSchemeCodeUnspsc190501.50441906'),
	'identifierSchemeCodeUnspsc190501.50441907': __('identifierSchemeCodeUnspsc190501.50441907'),
	'identifierSchemeCodeUnspsc190501.50441908': __('identifierSchemeCodeUnspsc190501.50441908'),
	'identifierSchemeCodeUnspsc190501.50441909': __('identifierSchemeCodeUnspsc190501.50441909'),
	'identifierSchemeCodeUnspsc190501.50441910': __('identifierSchemeCodeUnspsc190501.50441910'),
	'identifierSchemeCodeUnspsc190501.50441911': __('identifierSchemeCodeUnspsc190501.50441911'),
	'identifierSchemeCodeUnspsc190501.50441912': __('identifierSchemeCodeUnspsc190501.50441912'),
	'identifierSchemeCodeUnspsc190501.50441913': __('identifierSchemeCodeUnspsc190501.50441913'),
	'identifierSchemeCodeUnspsc190501.50441914': __('identifierSchemeCodeUnspsc190501.50441914'),
	'identifierSchemeCodeUnspsc190501.50441915': __('identifierSchemeCodeUnspsc190501.50441915'),
	'identifierSchemeCodeUnspsc190501.50441916': __('identifierSchemeCodeUnspsc190501.50441916'),
	'identifierSchemeCodeUnspsc190501.50441917': __('identifierSchemeCodeUnspsc190501.50441917'),
	'identifierSchemeCodeUnspsc190501.50441918': __('identifierSchemeCodeUnspsc190501.50441918'),
	'identifierSchemeCodeUnspsc190501.50441919': __('identifierSchemeCodeUnspsc190501.50441919'),
	'identifierSchemeCodeUnspsc190501.50441920': __('identifierSchemeCodeUnspsc190501.50441920'),
	'identifierSchemeCodeUnspsc190501.50441921': __('identifierSchemeCodeUnspsc190501.50441921'),
	'identifierSchemeCodeUnspsc190501.50441922': __('identifierSchemeCodeUnspsc190501.50441922'),
	'identifierSchemeCodeUnspsc190501.50442000': __('identifierSchemeCodeUnspsc190501.50442000'),
	'identifierSchemeCodeUnspsc190501.50442001': __('identifierSchemeCodeUnspsc190501.50442001'),
	'identifierSchemeCodeUnspsc190501.50442002': __('identifierSchemeCodeUnspsc190501.50442002'),
	'identifierSchemeCodeUnspsc190501.50442003': __('identifierSchemeCodeUnspsc190501.50442003'),
	'identifierSchemeCodeUnspsc190501.50442004': __('identifierSchemeCodeUnspsc190501.50442004'),
	'identifierSchemeCodeUnspsc190501.50442100': __('identifierSchemeCodeUnspsc190501.50442100'),
	'identifierSchemeCodeUnspsc190501.50442101': __('identifierSchemeCodeUnspsc190501.50442101'),
	'identifierSchemeCodeUnspsc190501.50442102': __('identifierSchemeCodeUnspsc190501.50442102'),
	'identifierSchemeCodeUnspsc190501.50442103': __('identifierSchemeCodeUnspsc190501.50442103'),
	'identifierSchemeCodeUnspsc190501.50442104': __('identifierSchemeCodeUnspsc190501.50442104'),
	'identifierSchemeCodeUnspsc190501.50442105': __('identifierSchemeCodeUnspsc190501.50442105'),
	'identifierSchemeCodeUnspsc190501.50442106': __('identifierSchemeCodeUnspsc190501.50442106'),
	'identifierSchemeCodeUnspsc190501.50442107': __('identifierSchemeCodeUnspsc190501.50442107'),
	'identifierSchemeCodeUnspsc190501.50442200': __('identifierSchemeCodeUnspsc190501.50442200'),
	'identifierSchemeCodeUnspsc190501.50442201': __('identifierSchemeCodeUnspsc190501.50442201'),
	'identifierSchemeCodeUnspsc190501.50442202': __('identifierSchemeCodeUnspsc190501.50442202'),
	'identifierSchemeCodeUnspsc190501.50442203': __('identifierSchemeCodeUnspsc190501.50442203'),
	'identifierSchemeCodeUnspsc190501.50442204': __('identifierSchemeCodeUnspsc190501.50442204'),
	'identifierSchemeCodeUnspsc190501.50442205': __('identifierSchemeCodeUnspsc190501.50442205'),
	'identifierSchemeCodeUnspsc190501.50442206': __('identifierSchemeCodeUnspsc190501.50442206'),
	'identifierSchemeCodeUnspsc190501.50442207': __('identifierSchemeCodeUnspsc190501.50442207'),
	'identifierSchemeCodeUnspsc190501.50442208': __('identifierSchemeCodeUnspsc190501.50442208'),
	'identifierSchemeCodeUnspsc190501.50442209': __('identifierSchemeCodeUnspsc190501.50442209'),
	'identifierSchemeCodeUnspsc190501.50442210': __('identifierSchemeCodeUnspsc190501.50442210'),
	'identifierSchemeCodeUnspsc190501.50442211': __('identifierSchemeCodeUnspsc190501.50442211'),
	'identifierSchemeCodeUnspsc190501.50442300': __('identifierSchemeCodeUnspsc190501.50442300'),
	'identifierSchemeCodeUnspsc190501.50442301': __('identifierSchemeCodeUnspsc190501.50442301'),
	'identifierSchemeCodeUnspsc190501.50442302': __('identifierSchemeCodeUnspsc190501.50442302'),
	'identifierSchemeCodeUnspsc190501.50442303': __('identifierSchemeCodeUnspsc190501.50442303'),
	'identifierSchemeCodeUnspsc190501.50442304': __('identifierSchemeCodeUnspsc190501.50442304'),
	'identifierSchemeCodeUnspsc190501.50442400': __('identifierSchemeCodeUnspsc190501.50442400'),
	'identifierSchemeCodeUnspsc190501.50442401': __('identifierSchemeCodeUnspsc190501.50442401'),
	'identifierSchemeCodeUnspsc190501.50442402': __('identifierSchemeCodeUnspsc190501.50442402'),
	'identifierSchemeCodeUnspsc190501.50442500': __('identifierSchemeCodeUnspsc190501.50442500'),
	'identifierSchemeCodeUnspsc190501.50442501': __('identifierSchemeCodeUnspsc190501.50442501'),
	'identifierSchemeCodeUnspsc190501.50442502': __('identifierSchemeCodeUnspsc190501.50442502'),
	'identifierSchemeCodeUnspsc190501.50442503': __('identifierSchemeCodeUnspsc190501.50442503'),
	'identifierSchemeCodeUnspsc190501.50442504': __('identifierSchemeCodeUnspsc190501.50442504'),
	'identifierSchemeCodeUnspsc190501.50442505': __('identifierSchemeCodeUnspsc190501.50442505'),
	'identifierSchemeCodeUnspsc190501.50442506': __('identifierSchemeCodeUnspsc190501.50442506'),
	'identifierSchemeCodeUnspsc190501.50442600': __('identifierSchemeCodeUnspsc190501.50442600'),
	'identifierSchemeCodeUnspsc190501.50442601': __('identifierSchemeCodeUnspsc190501.50442601'),
	'identifierSchemeCodeUnspsc190501.50442602': __('identifierSchemeCodeUnspsc190501.50442602'),
	'identifierSchemeCodeUnspsc190501.50442603': __('identifierSchemeCodeUnspsc190501.50442603'),
	'identifierSchemeCodeUnspsc190501.50442604': __('identifierSchemeCodeUnspsc190501.50442604'),
	'identifierSchemeCodeUnspsc190501.50442605': __('identifierSchemeCodeUnspsc190501.50442605'),
	'identifierSchemeCodeUnspsc190501.50442606': __('identifierSchemeCodeUnspsc190501.50442606'),
	'identifierSchemeCodeUnspsc190501.50442607': __('identifierSchemeCodeUnspsc190501.50442607'),
	'identifierSchemeCodeUnspsc190501.50442608': __('identifierSchemeCodeUnspsc190501.50442608'),
	'identifierSchemeCodeUnspsc190501.50442609': __('identifierSchemeCodeUnspsc190501.50442609'),
	'identifierSchemeCodeUnspsc190501.50442610': __('identifierSchemeCodeUnspsc190501.50442610'),
	'identifierSchemeCodeUnspsc190501.50442611': __('identifierSchemeCodeUnspsc190501.50442611'),
	'identifierSchemeCodeUnspsc190501.50442612': __('identifierSchemeCodeUnspsc190501.50442612'),
	'identifierSchemeCodeUnspsc190501.50442700': __('identifierSchemeCodeUnspsc190501.50442700'),
	'identifierSchemeCodeUnspsc190501.50442701': __('identifierSchemeCodeUnspsc190501.50442701'),
	'identifierSchemeCodeUnspsc190501.50442702': __('identifierSchemeCodeUnspsc190501.50442702'),
	'identifierSchemeCodeUnspsc190501.50442703': __('identifierSchemeCodeUnspsc190501.50442703'),
	'identifierSchemeCodeUnspsc190501.50442704': __('identifierSchemeCodeUnspsc190501.50442704'),
	'identifierSchemeCodeUnspsc190501.50442705': __('identifierSchemeCodeUnspsc190501.50442705'),
	'identifierSchemeCodeUnspsc190501.50442706': __('identifierSchemeCodeUnspsc190501.50442706'),
	'identifierSchemeCodeUnspsc190501.50442707': __('identifierSchemeCodeUnspsc190501.50442707'),
	'identifierSchemeCodeUnspsc190501.50442708': __('identifierSchemeCodeUnspsc190501.50442708'),
	'identifierSchemeCodeUnspsc190501.50442709': __('identifierSchemeCodeUnspsc190501.50442709'),
	'identifierSchemeCodeUnspsc190501.50442710': __('identifierSchemeCodeUnspsc190501.50442710'),
	'identifierSchemeCodeUnspsc190501.50442711': __('identifierSchemeCodeUnspsc190501.50442711'),
	'identifierSchemeCodeUnspsc190501.50442712': __('identifierSchemeCodeUnspsc190501.50442712'),
	'identifierSchemeCodeUnspsc190501.50442713': __('identifierSchemeCodeUnspsc190501.50442713'),
	'identifierSchemeCodeUnspsc190501.50442714': __('identifierSchemeCodeUnspsc190501.50442714'),
	'identifierSchemeCodeUnspsc190501.50442715': __('identifierSchemeCodeUnspsc190501.50442715'),
	'identifierSchemeCodeUnspsc190501.50442800': __('identifierSchemeCodeUnspsc190501.50442800'),
	'identifierSchemeCodeUnspsc190501.50442801': __('identifierSchemeCodeUnspsc190501.50442801'),
	'identifierSchemeCodeUnspsc190501.50442802': __('identifierSchemeCodeUnspsc190501.50442802'),
	'identifierSchemeCodeUnspsc190501.50442803': __('identifierSchemeCodeUnspsc190501.50442803'),
	'identifierSchemeCodeUnspsc190501.50442804': __('identifierSchemeCodeUnspsc190501.50442804'),
	'identifierSchemeCodeUnspsc190501.50442805': __('identifierSchemeCodeUnspsc190501.50442805'),
	'identifierSchemeCodeUnspsc190501.50442806': __('identifierSchemeCodeUnspsc190501.50442806'),
	'identifierSchemeCodeUnspsc190501.50442807': __('identifierSchemeCodeUnspsc190501.50442807'),
	'identifierSchemeCodeUnspsc190501.50442808': __('identifierSchemeCodeUnspsc190501.50442808'),
	'identifierSchemeCodeUnspsc190501.50442900': __('identifierSchemeCodeUnspsc190501.50442900'),
	'identifierSchemeCodeUnspsc190501.50442901': __('identifierSchemeCodeUnspsc190501.50442901'),
	'identifierSchemeCodeUnspsc190501.50442902': __('identifierSchemeCodeUnspsc190501.50442902'),
	'identifierSchemeCodeUnspsc190501.50442903': __('identifierSchemeCodeUnspsc190501.50442903'),
	'identifierSchemeCodeUnspsc190501.50442904': __('identifierSchemeCodeUnspsc190501.50442904'),
	'identifierSchemeCodeUnspsc190501.50442905': __('identifierSchemeCodeUnspsc190501.50442905'),
	'identifierSchemeCodeUnspsc190501.50442906': __('identifierSchemeCodeUnspsc190501.50442906'),
	'identifierSchemeCodeUnspsc190501.50442907': __('identifierSchemeCodeUnspsc190501.50442907'),
	'identifierSchemeCodeUnspsc190501.50442908': __('identifierSchemeCodeUnspsc190501.50442908'),
	'identifierSchemeCodeUnspsc190501.50442909': __('identifierSchemeCodeUnspsc190501.50442909'),
	'identifierSchemeCodeUnspsc190501.50442910': __('identifierSchemeCodeUnspsc190501.50442910'),
	'identifierSchemeCodeUnspsc190501.50442911': __('identifierSchemeCodeUnspsc190501.50442911'),
	'identifierSchemeCodeUnspsc190501.50443000': __('identifierSchemeCodeUnspsc190501.50443000'),
	'identifierSchemeCodeUnspsc190501.50443001': __('identifierSchemeCodeUnspsc190501.50443001'),
	'identifierSchemeCodeUnspsc190501.50443002': __('identifierSchemeCodeUnspsc190501.50443002'),
	'identifierSchemeCodeUnspsc190501.50443003': __('identifierSchemeCodeUnspsc190501.50443003'),
	'identifierSchemeCodeUnspsc190501.50443004': __('identifierSchemeCodeUnspsc190501.50443004'),
	'identifierSchemeCodeUnspsc190501.50443005': __('identifierSchemeCodeUnspsc190501.50443005'),
	'identifierSchemeCodeUnspsc190501.50443006': __('identifierSchemeCodeUnspsc190501.50443006'),
	'identifierSchemeCodeUnspsc190501.50443007': __('identifierSchemeCodeUnspsc190501.50443007'),
	'identifierSchemeCodeUnspsc190501.50443008': __('identifierSchemeCodeUnspsc190501.50443008'),
	'identifierSchemeCodeUnspsc190501.50443009': __('identifierSchemeCodeUnspsc190501.50443009'),
	'identifierSchemeCodeUnspsc190501.50443010': __('identifierSchemeCodeUnspsc190501.50443010'),
	'identifierSchemeCodeUnspsc190501.50443011': __('identifierSchemeCodeUnspsc190501.50443011'),
	'identifierSchemeCodeUnspsc190501.50443012': __('identifierSchemeCodeUnspsc190501.50443012'),
	'identifierSchemeCodeUnspsc190501.50443013': __('identifierSchemeCodeUnspsc190501.50443013'),
	'identifierSchemeCodeUnspsc190501.50443014': __('identifierSchemeCodeUnspsc190501.50443014'),
	'identifierSchemeCodeUnspsc190501.50443015': __('identifierSchemeCodeUnspsc190501.50443015'),
	'identifierSchemeCodeUnspsc190501.50443016': __('identifierSchemeCodeUnspsc190501.50443016'),
	'identifierSchemeCodeUnspsc190501.50443100': __('identifierSchemeCodeUnspsc190501.50443100'),
	'identifierSchemeCodeUnspsc190501.50443101': __('identifierSchemeCodeUnspsc190501.50443101'),
	'identifierSchemeCodeUnspsc190501.50443102': __('identifierSchemeCodeUnspsc190501.50443102'),
	'identifierSchemeCodeUnspsc190501.50443200': __('identifierSchemeCodeUnspsc190501.50443200'),
	'identifierSchemeCodeUnspsc190501.50443201': __('identifierSchemeCodeUnspsc190501.50443201'),
	'identifierSchemeCodeUnspsc190501.50443202': __('identifierSchemeCodeUnspsc190501.50443202'),
	'identifierSchemeCodeUnspsc190501.50443203': __('identifierSchemeCodeUnspsc190501.50443203'),
	'identifierSchemeCodeUnspsc190501.50443204': __('identifierSchemeCodeUnspsc190501.50443204'),
	'identifierSchemeCodeUnspsc190501.50443205': __('identifierSchemeCodeUnspsc190501.50443205'),
	'identifierSchemeCodeUnspsc190501.50443206': __('identifierSchemeCodeUnspsc190501.50443206'),
	'identifierSchemeCodeUnspsc190501.50443207': __('identifierSchemeCodeUnspsc190501.50443207'),
	'identifierSchemeCodeUnspsc190501.50443208': __('identifierSchemeCodeUnspsc190501.50443208'),
	'identifierSchemeCodeUnspsc190501.50443209': __('identifierSchemeCodeUnspsc190501.50443209'),
	'identifierSchemeCodeUnspsc190501.50443210': __('identifierSchemeCodeUnspsc190501.50443210'),
	'identifierSchemeCodeUnspsc190501.50443211': __('identifierSchemeCodeUnspsc190501.50443211'),
	'identifierSchemeCodeUnspsc190501.50443212': __('identifierSchemeCodeUnspsc190501.50443212'),
	'identifierSchemeCodeUnspsc190501.50443213': __('identifierSchemeCodeUnspsc190501.50443213'),
	'identifierSchemeCodeUnspsc190501.50443214': __('identifierSchemeCodeUnspsc190501.50443214'),
	'identifierSchemeCodeUnspsc190501.50443215': __('identifierSchemeCodeUnspsc190501.50443215'),
	'identifierSchemeCodeUnspsc190501.50443216': __('identifierSchemeCodeUnspsc190501.50443216'),
	'identifierSchemeCodeUnspsc190501.50443217': __('identifierSchemeCodeUnspsc190501.50443217'),
	'identifierSchemeCodeUnspsc190501.50443218': __('identifierSchemeCodeUnspsc190501.50443218'),
	'identifierSchemeCodeUnspsc190501.50443219': __('identifierSchemeCodeUnspsc190501.50443219'),
	'identifierSchemeCodeUnspsc190501.50443220': __('identifierSchemeCodeUnspsc190501.50443220'),
	'identifierSchemeCodeUnspsc190501.50443221': __('identifierSchemeCodeUnspsc190501.50443221'),
	'identifierSchemeCodeUnspsc190501.50443222': __('identifierSchemeCodeUnspsc190501.50443222'),
	'identifierSchemeCodeUnspsc190501.50443223': __('identifierSchemeCodeUnspsc190501.50443223'),
	'identifierSchemeCodeUnspsc190501.50443224': __('identifierSchemeCodeUnspsc190501.50443224'),
	'identifierSchemeCodeUnspsc190501.50443225': __('identifierSchemeCodeUnspsc190501.50443225'),
	'identifierSchemeCodeUnspsc190501.50443226': __('identifierSchemeCodeUnspsc190501.50443226'),
	'identifierSchemeCodeUnspsc190501.50443227': __('identifierSchemeCodeUnspsc190501.50443227'),
	'identifierSchemeCodeUnspsc190501.50443228': __('identifierSchemeCodeUnspsc190501.50443228'),
	'identifierSchemeCodeUnspsc190501.50443229': __('identifierSchemeCodeUnspsc190501.50443229'),
	'identifierSchemeCodeUnspsc190501.50443230': __('identifierSchemeCodeUnspsc190501.50443230'),
	'identifierSchemeCodeUnspsc190501.50443231': __('identifierSchemeCodeUnspsc190501.50443231'),
	'identifierSchemeCodeUnspsc190501.50443232': __('identifierSchemeCodeUnspsc190501.50443232'),
	'identifierSchemeCodeUnspsc190501.50443233': __('identifierSchemeCodeUnspsc190501.50443233'),
	'identifierSchemeCodeUnspsc190501.50443234': __('identifierSchemeCodeUnspsc190501.50443234'),
	'identifierSchemeCodeUnspsc190501.50443235': __('identifierSchemeCodeUnspsc190501.50443235'),
	'identifierSchemeCodeUnspsc190501.50443236': __('identifierSchemeCodeUnspsc190501.50443236'),
	'identifierSchemeCodeUnspsc190501.50443237': __('identifierSchemeCodeUnspsc190501.50443237'),
	'identifierSchemeCodeUnspsc190501.50443238': __('identifierSchemeCodeUnspsc190501.50443238'),
	'identifierSchemeCodeUnspsc190501.50443239': __('identifierSchemeCodeUnspsc190501.50443239'),
	'identifierSchemeCodeUnspsc190501.50443240': __('identifierSchemeCodeUnspsc190501.50443240'),
	'identifierSchemeCodeUnspsc190501.50443241': __('identifierSchemeCodeUnspsc190501.50443241'),
	'identifierSchemeCodeUnspsc190501.50443242': __('identifierSchemeCodeUnspsc190501.50443242'),
	'identifierSchemeCodeUnspsc190501.50443243': __('identifierSchemeCodeUnspsc190501.50443243'),
	'identifierSchemeCodeUnspsc190501.50443244': __('identifierSchemeCodeUnspsc190501.50443244'),
	'identifierSchemeCodeUnspsc190501.50443245': __('identifierSchemeCodeUnspsc190501.50443245'),
	'identifierSchemeCodeUnspsc190501.50443246': __('identifierSchemeCodeUnspsc190501.50443246'),
	'identifierSchemeCodeUnspsc190501.50443247': __('identifierSchemeCodeUnspsc190501.50443247'),
	'identifierSchemeCodeUnspsc190501.50443248': __('identifierSchemeCodeUnspsc190501.50443248'),
	'identifierSchemeCodeUnspsc190501.50443249': __('identifierSchemeCodeUnspsc190501.50443249'),
	'identifierSchemeCodeUnspsc190501.50443250': __('identifierSchemeCodeUnspsc190501.50443250'),
	'identifierSchemeCodeUnspsc190501.50443251': __('identifierSchemeCodeUnspsc190501.50443251'),
	'identifierSchemeCodeUnspsc190501.50443252': __('identifierSchemeCodeUnspsc190501.50443252'),
	'identifierSchemeCodeUnspsc190501.50443253': __('identifierSchemeCodeUnspsc190501.50443253'),
	'identifierSchemeCodeUnspsc190501.50443254': __('identifierSchemeCodeUnspsc190501.50443254'),
	'identifierSchemeCodeUnspsc190501.50443255': __('identifierSchemeCodeUnspsc190501.50443255'),
	'identifierSchemeCodeUnspsc190501.50443256': __('identifierSchemeCodeUnspsc190501.50443256'),
	'identifierSchemeCodeUnspsc190501.50443257': __('identifierSchemeCodeUnspsc190501.50443257'),
	'identifierSchemeCodeUnspsc190501.50443258': __('identifierSchemeCodeUnspsc190501.50443258'),
	'identifierSchemeCodeUnspsc190501.50443259': __('identifierSchemeCodeUnspsc190501.50443259'),
	'identifierSchemeCodeUnspsc190501.50443260': __('identifierSchemeCodeUnspsc190501.50443260'),
	'identifierSchemeCodeUnspsc190501.50443261': __('identifierSchemeCodeUnspsc190501.50443261'),
	'identifierSchemeCodeUnspsc190501.50443262': __('identifierSchemeCodeUnspsc190501.50443262'),
	'identifierSchemeCodeUnspsc190501.50443263': __('identifierSchemeCodeUnspsc190501.50443263'),
	'identifierSchemeCodeUnspsc190501.50443264': __('identifierSchemeCodeUnspsc190501.50443264'),
	'identifierSchemeCodeUnspsc190501.50443265': __('identifierSchemeCodeUnspsc190501.50443265'),
	'identifierSchemeCodeUnspsc190501.50443266': __('identifierSchemeCodeUnspsc190501.50443266'),
	'identifierSchemeCodeUnspsc190501.50443267': __('identifierSchemeCodeUnspsc190501.50443267'),
	'identifierSchemeCodeUnspsc190501.50443268': __('identifierSchemeCodeUnspsc190501.50443268'),
	'identifierSchemeCodeUnspsc190501.50443269': __('identifierSchemeCodeUnspsc190501.50443269'),
	'identifierSchemeCodeUnspsc190501.50443300': __('identifierSchemeCodeUnspsc190501.50443300'),
	'identifierSchemeCodeUnspsc190501.50443301': __('identifierSchemeCodeUnspsc190501.50443301'),
	'identifierSchemeCodeUnspsc190501.50443302': __('identifierSchemeCodeUnspsc190501.50443302'),
	'identifierSchemeCodeUnspsc190501.50443303': __('identifierSchemeCodeUnspsc190501.50443303'),
	'identifierSchemeCodeUnspsc190501.50443304': __('identifierSchemeCodeUnspsc190501.50443304'),
	'identifierSchemeCodeUnspsc190501.50443400': __('identifierSchemeCodeUnspsc190501.50443400'),
	'identifierSchemeCodeUnspsc190501.50443401': __('identifierSchemeCodeUnspsc190501.50443401'),
	'identifierSchemeCodeUnspsc190501.50443402': __('identifierSchemeCodeUnspsc190501.50443402'),
	'identifierSchemeCodeUnspsc190501.50443403': __('identifierSchemeCodeUnspsc190501.50443403'),
	'identifierSchemeCodeUnspsc190501.50443404': __('identifierSchemeCodeUnspsc190501.50443404'),
	'identifierSchemeCodeUnspsc190501.50443405': __('identifierSchemeCodeUnspsc190501.50443405'),
	'identifierSchemeCodeUnspsc190501.50443406': __('identifierSchemeCodeUnspsc190501.50443406'),
	'identifierSchemeCodeUnspsc190501.50443407': __('identifierSchemeCodeUnspsc190501.50443407'),
	'identifierSchemeCodeUnspsc190501.50443408': __('identifierSchemeCodeUnspsc190501.50443408'),
	'identifierSchemeCodeUnspsc190501.50443409': __('identifierSchemeCodeUnspsc190501.50443409'),
	'identifierSchemeCodeUnspsc190501.50443410': __('identifierSchemeCodeUnspsc190501.50443410'),
	'identifierSchemeCodeUnspsc190501.50443411': __('identifierSchemeCodeUnspsc190501.50443411'),
	'identifierSchemeCodeUnspsc190501.50443412': __('identifierSchemeCodeUnspsc190501.50443412'),
	'identifierSchemeCodeUnspsc190501.50443413': __('identifierSchemeCodeUnspsc190501.50443413'),
	'identifierSchemeCodeUnspsc190501.50443414': __('identifierSchemeCodeUnspsc190501.50443414'),
	'identifierSchemeCodeUnspsc190501.50443415': __('identifierSchemeCodeUnspsc190501.50443415'),
	'identifierSchemeCodeUnspsc190501.50443416': __('identifierSchemeCodeUnspsc190501.50443416'),
	'identifierSchemeCodeUnspsc190501.50443417': __('identifierSchemeCodeUnspsc190501.50443417'),
	'identifierSchemeCodeUnspsc190501.50443418': __('identifierSchemeCodeUnspsc190501.50443418'),
	'identifierSchemeCodeUnspsc190501.50443419': __('identifierSchemeCodeUnspsc190501.50443419'),
	'identifierSchemeCodeUnspsc190501.50443420': __('identifierSchemeCodeUnspsc190501.50443420'),
	'identifierSchemeCodeUnspsc190501.50443421': __('identifierSchemeCodeUnspsc190501.50443421'),
	'identifierSchemeCodeUnspsc190501.50443422': __('identifierSchemeCodeUnspsc190501.50443422'),
	'identifierSchemeCodeUnspsc190501.50443423': __('identifierSchemeCodeUnspsc190501.50443423'),
	'identifierSchemeCodeUnspsc190501.50443500': __('identifierSchemeCodeUnspsc190501.50443500'),
	'identifierSchemeCodeUnspsc190501.50443501': __('identifierSchemeCodeUnspsc190501.50443501'),
	'identifierSchemeCodeUnspsc190501.50443502': __('identifierSchemeCodeUnspsc190501.50443502'),
	'identifierSchemeCodeUnspsc190501.50443503': __('identifierSchemeCodeUnspsc190501.50443503'),
	'identifierSchemeCodeUnspsc190501.50443504': __('identifierSchemeCodeUnspsc190501.50443504'),
	'identifierSchemeCodeUnspsc190501.50443505': __('identifierSchemeCodeUnspsc190501.50443505'),
	'identifierSchemeCodeUnspsc190501.50443506': __('identifierSchemeCodeUnspsc190501.50443506'),
	'identifierSchemeCodeUnspsc190501.50443507': __('identifierSchemeCodeUnspsc190501.50443507'),
	'identifierSchemeCodeUnspsc190501.50443508': __('identifierSchemeCodeUnspsc190501.50443508'),
	'identifierSchemeCodeUnspsc190501.50443509': __('identifierSchemeCodeUnspsc190501.50443509'),
	'identifierSchemeCodeUnspsc190501.50443510': __('identifierSchemeCodeUnspsc190501.50443510'),
	'identifierSchemeCodeUnspsc190501.50443511': __('identifierSchemeCodeUnspsc190501.50443511'),
	'identifierSchemeCodeUnspsc190501.50443512': __('identifierSchemeCodeUnspsc190501.50443512'),
	'identifierSchemeCodeUnspsc190501.50443513': __('identifierSchemeCodeUnspsc190501.50443513'),
	'identifierSchemeCodeUnspsc190501.50443514': __('identifierSchemeCodeUnspsc190501.50443514'),
	'identifierSchemeCodeUnspsc190501.50443515': __('identifierSchemeCodeUnspsc190501.50443515'),
	'identifierSchemeCodeUnspsc190501.50443516': __('identifierSchemeCodeUnspsc190501.50443516'),
	'identifierSchemeCodeUnspsc190501.50443517': __('identifierSchemeCodeUnspsc190501.50443517'),
	'identifierSchemeCodeUnspsc190501.50443518': __('identifierSchemeCodeUnspsc190501.50443518'),
	'identifierSchemeCodeUnspsc190501.50443519': __('identifierSchemeCodeUnspsc190501.50443519'),
	'identifierSchemeCodeUnspsc190501.50443520': __('identifierSchemeCodeUnspsc190501.50443520'),
	'identifierSchemeCodeUnspsc190501.50443600': __('identifierSchemeCodeUnspsc190501.50443600'),
	'identifierSchemeCodeUnspsc190501.50443601': __('identifierSchemeCodeUnspsc190501.50443601'),
	'identifierSchemeCodeUnspsc190501.50443602': __('identifierSchemeCodeUnspsc190501.50443602'),
	'identifierSchemeCodeUnspsc190501.50443603': __('identifierSchemeCodeUnspsc190501.50443603'),
	'identifierSchemeCodeUnspsc190501.50443604': __('identifierSchemeCodeUnspsc190501.50443604'),
	'identifierSchemeCodeUnspsc190501.50443605': __('identifierSchemeCodeUnspsc190501.50443605'),
	'identifierSchemeCodeUnspsc190501.50443606': __('identifierSchemeCodeUnspsc190501.50443606'),
	'identifierSchemeCodeUnspsc190501.50443607': __('identifierSchemeCodeUnspsc190501.50443607'),
	'identifierSchemeCodeUnspsc190501.50443608': __('identifierSchemeCodeUnspsc190501.50443608'),
	'identifierSchemeCodeUnspsc190501.50443609': __('identifierSchemeCodeUnspsc190501.50443609'),
	'identifierSchemeCodeUnspsc190501.50443610': __('identifierSchemeCodeUnspsc190501.50443610'),
	'identifierSchemeCodeUnspsc190501.50443611': __('identifierSchemeCodeUnspsc190501.50443611'),
	'identifierSchemeCodeUnspsc190501.50443612': __('identifierSchemeCodeUnspsc190501.50443612'),
	'identifierSchemeCodeUnspsc190501.50443613': __('identifierSchemeCodeUnspsc190501.50443613'),
	'identifierSchemeCodeUnspsc190501.50443614': __('identifierSchemeCodeUnspsc190501.50443614'),
	'identifierSchemeCodeUnspsc190501.50443700': __('identifierSchemeCodeUnspsc190501.50443700'),
	'identifierSchemeCodeUnspsc190501.50443701': __('identifierSchemeCodeUnspsc190501.50443701'),
	'identifierSchemeCodeUnspsc190501.50443702': __('identifierSchemeCodeUnspsc190501.50443702'),
	'identifierSchemeCodeUnspsc190501.50443703': __('identifierSchemeCodeUnspsc190501.50443703'),
	'identifierSchemeCodeUnspsc190501.50443704': __('identifierSchemeCodeUnspsc190501.50443704'),
	'identifierSchemeCodeUnspsc190501.50443705': __('identifierSchemeCodeUnspsc190501.50443705'),
	'identifierSchemeCodeUnspsc190501.50443706': __('identifierSchemeCodeUnspsc190501.50443706'),
	'identifierSchemeCodeUnspsc190501.50443707': __('identifierSchemeCodeUnspsc190501.50443707'),
	'identifierSchemeCodeUnspsc190501.50443800': __('identifierSchemeCodeUnspsc190501.50443800'),
	'identifierSchemeCodeUnspsc190501.50443801': __('identifierSchemeCodeUnspsc190501.50443801'),
	'identifierSchemeCodeUnspsc190501.50443802': __('identifierSchemeCodeUnspsc190501.50443802'),
	'identifierSchemeCodeUnspsc190501.50443803': __('identifierSchemeCodeUnspsc190501.50443803'),
	'identifierSchemeCodeUnspsc190501.50443804': __('identifierSchemeCodeUnspsc190501.50443804'),
	'identifierSchemeCodeUnspsc190501.50443805': __('identifierSchemeCodeUnspsc190501.50443805'),
	'identifierSchemeCodeUnspsc190501.50443806': __('identifierSchemeCodeUnspsc190501.50443806'),
	'identifierSchemeCodeUnspsc190501.50443807': __('identifierSchemeCodeUnspsc190501.50443807'),
	'identifierSchemeCodeUnspsc190501.50443808': __('identifierSchemeCodeUnspsc190501.50443808'),
	'identifierSchemeCodeUnspsc190501.50443809': __('identifierSchemeCodeUnspsc190501.50443809'),
	'identifierSchemeCodeUnspsc190501.50443810': __('identifierSchemeCodeUnspsc190501.50443810'),
	'identifierSchemeCodeUnspsc190501.50443811': __('identifierSchemeCodeUnspsc190501.50443811'),
	'identifierSchemeCodeUnspsc190501.50443900': __('identifierSchemeCodeUnspsc190501.50443900'),
	'identifierSchemeCodeUnspsc190501.50443901': __('identifierSchemeCodeUnspsc190501.50443901'),
	'identifierSchemeCodeUnspsc190501.50443902': __('identifierSchemeCodeUnspsc190501.50443902'),
	'identifierSchemeCodeUnspsc190501.50443903': __('identifierSchemeCodeUnspsc190501.50443903'),
	'identifierSchemeCodeUnspsc190501.50443904': __('identifierSchemeCodeUnspsc190501.50443904'),
	'identifierSchemeCodeUnspsc190501.50443905': __('identifierSchemeCodeUnspsc190501.50443905'),
	'identifierSchemeCodeUnspsc190501.50443906': __('identifierSchemeCodeUnspsc190501.50443906'),
	'identifierSchemeCodeUnspsc190501.50443907': __('identifierSchemeCodeUnspsc190501.50443907'),
	'identifierSchemeCodeUnspsc190501.50443908': __('identifierSchemeCodeUnspsc190501.50443908'),
	'identifierSchemeCodeUnspsc190501.50443909': __('identifierSchemeCodeUnspsc190501.50443909'),
	'identifierSchemeCodeUnspsc190501.50443910': __('identifierSchemeCodeUnspsc190501.50443910'),
	'identifierSchemeCodeUnspsc190501.50443911': __('identifierSchemeCodeUnspsc190501.50443911'),
	'identifierSchemeCodeUnspsc190501.50444000': __('identifierSchemeCodeUnspsc190501.50444000'),
	'identifierSchemeCodeUnspsc190501.50444001': __('identifierSchemeCodeUnspsc190501.50444001'),
	'identifierSchemeCodeUnspsc190501.50444002': __('identifierSchemeCodeUnspsc190501.50444002'),
	'identifierSchemeCodeUnspsc190501.50444003': __('identifierSchemeCodeUnspsc190501.50444003'),
	'identifierSchemeCodeUnspsc190501.50444004': __('identifierSchemeCodeUnspsc190501.50444004'),
	'identifierSchemeCodeUnspsc190501.50444005': __('identifierSchemeCodeUnspsc190501.50444005'),
	'identifierSchemeCodeUnspsc190501.50444100': __('identifierSchemeCodeUnspsc190501.50444100'),
	'identifierSchemeCodeUnspsc190501.50444101': __('identifierSchemeCodeUnspsc190501.50444101'),
	'identifierSchemeCodeUnspsc190501.50444102': __('identifierSchemeCodeUnspsc190501.50444102'),
	'identifierSchemeCodeUnspsc190501.50444103': __('identifierSchemeCodeUnspsc190501.50444103'),
	'identifierSchemeCodeUnspsc190501.50444104': __('identifierSchemeCodeUnspsc190501.50444104'),
	'identifierSchemeCodeUnspsc190501.50444105': __('identifierSchemeCodeUnspsc190501.50444105'),
	'identifierSchemeCodeUnspsc190501.50444106': __('identifierSchemeCodeUnspsc190501.50444106'),
	'identifierSchemeCodeUnspsc190501.50444107': __('identifierSchemeCodeUnspsc190501.50444107'),
	'identifierSchemeCodeUnspsc190501.50444108': __('identifierSchemeCodeUnspsc190501.50444108'),
	'identifierSchemeCodeUnspsc190501.50444109': __('identifierSchemeCodeUnspsc190501.50444109'),
	'identifierSchemeCodeUnspsc190501.50444110': __('identifierSchemeCodeUnspsc190501.50444110'),
	'identifierSchemeCodeUnspsc190501.50444111': __('identifierSchemeCodeUnspsc190501.50444111'),
	'identifierSchemeCodeUnspsc190501.50444112': __('identifierSchemeCodeUnspsc190501.50444112'),
	'identifierSchemeCodeUnspsc190501.50444113': __('identifierSchemeCodeUnspsc190501.50444113'),
	'identifierSchemeCodeUnspsc190501.50444114': __('identifierSchemeCodeUnspsc190501.50444114'),
	'identifierSchemeCodeUnspsc190501.50444115': __('identifierSchemeCodeUnspsc190501.50444115'),
	'identifierSchemeCodeUnspsc190501.50444116': __('identifierSchemeCodeUnspsc190501.50444116'),
	'identifierSchemeCodeUnspsc190501.50444117': __('identifierSchemeCodeUnspsc190501.50444117'),
	'identifierSchemeCodeUnspsc190501.50444118': __('identifierSchemeCodeUnspsc190501.50444118'),
	'identifierSchemeCodeUnspsc190501.50444119': __('identifierSchemeCodeUnspsc190501.50444119'),
	'identifierSchemeCodeUnspsc190501.50444120': __('identifierSchemeCodeUnspsc190501.50444120'),
	'identifierSchemeCodeUnspsc190501.50444121': __('identifierSchemeCodeUnspsc190501.50444121'),
	'identifierSchemeCodeUnspsc190501.50444122': __('identifierSchemeCodeUnspsc190501.50444122'),
	'identifierSchemeCodeUnspsc190501.50444123': __('identifierSchemeCodeUnspsc190501.50444123'),
	'identifierSchemeCodeUnspsc190501.50444124': __('identifierSchemeCodeUnspsc190501.50444124'),
	'identifierSchemeCodeUnspsc190501.50444125': __('identifierSchemeCodeUnspsc190501.50444125'),
	'identifierSchemeCodeUnspsc190501.50444126': __('identifierSchemeCodeUnspsc190501.50444126'),
	'identifierSchemeCodeUnspsc190501.50444127': __('identifierSchemeCodeUnspsc190501.50444127'),
	'identifierSchemeCodeUnspsc190501.50444200': __('identifierSchemeCodeUnspsc190501.50444200'),
	'identifierSchemeCodeUnspsc190501.50444201': __('identifierSchemeCodeUnspsc190501.50444201'),
	'identifierSchemeCodeUnspsc190501.50444202': __('identifierSchemeCodeUnspsc190501.50444202'),
	'identifierSchemeCodeUnspsc190501.50444300': __('identifierSchemeCodeUnspsc190501.50444300'),
	'identifierSchemeCodeUnspsc190501.50444301': __('identifierSchemeCodeUnspsc190501.50444301'),
	'identifierSchemeCodeUnspsc190501.50444302': __('identifierSchemeCodeUnspsc190501.50444302'),
	'identifierSchemeCodeUnspsc190501.50444303': __('identifierSchemeCodeUnspsc190501.50444303'),
	'identifierSchemeCodeUnspsc190501.50444304': __('identifierSchemeCodeUnspsc190501.50444304'),
	'identifierSchemeCodeUnspsc190501.50444305': __('identifierSchemeCodeUnspsc190501.50444305'),
	'identifierSchemeCodeUnspsc190501.50444306': __('identifierSchemeCodeUnspsc190501.50444306'),
	'identifierSchemeCodeUnspsc190501.50444400': __('identifierSchemeCodeUnspsc190501.50444400'),
	'identifierSchemeCodeUnspsc190501.50444401': __('identifierSchemeCodeUnspsc190501.50444401'),
	'identifierSchemeCodeUnspsc190501.50444402': __('identifierSchemeCodeUnspsc190501.50444402'),
	'identifierSchemeCodeUnspsc190501.50444403': __('identifierSchemeCodeUnspsc190501.50444403'),
	'identifierSchemeCodeUnspsc190501.50444404': __('identifierSchemeCodeUnspsc190501.50444404'),
	'identifierSchemeCodeUnspsc190501.50444405': __('identifierSchemeCodeUnspsc190501.50444405'),
	'identifierSchemeCodeUnspsc190501.50444406': __('identifierSchemeCodeUnspsc190501.50444406'),
	'identifierSchemeCodeUnspsc190501.50444500': __('identifierSchemeCodeUnspsc190501.50444500'),
	'identifierSchemeCodeUnspsc190501.50444501': __('identifierSchemeCodeUnspsc190501.50444501'),
	'identifierSchemeCodeUnspsc190501.50444502': __('identifierSchemeCodeUnspsc190501.50444502'),
	'identifierSchemeCodeUnspsc190501.50444503': __('identifierSchemeCodeUnspsc190501.50444503'),
	'identifierSchemeCodeUnspsc190501.50444504': __('identifierSchemeCodeUnspsc190501.50444504'),
	'identifierSchemeCodeUnspsc190501.50444505': __('identifierSchemeCodeUnspsc190501.50444505'),
	'identifierSchemeCodeUnspsc190501.50444506': __('identifierSchemeCodeUnspsc190501.50444506'),
	'identifierSchemeCodeUnspsc190501.50444507': __('identifierSchemeCodeUnspsc190501.50444507'),
	'identifierSchemeCodeUnspsc190501.50444508': __('identifierSchemeCodeUnspsc190501.50444508'),
	'identifierSchemeCodeUnspsc190501.50444600': __('identifierSchemeCodeUnspsc190501.50444600'),
	'identifierSchemeCodeUnspsc190501.50444601': __('identifierSchemeCodeUnspsc190501.50444601'),
	'identifierSchemeCodeUnspsc190501.50444602': __('identifierSchemeCodeUnspsc190501.50444602'),
	'identifierSchemeCodeUnspsc190501.50444603': __('identifierSchemeCodeUnspsc190501.50444603'),
	'identifierSchemeCodeUnspsc190501.50444604': __('identifierSchemeCodeUnspsc190501.50444604'),
	'identifierSchemeCodeUnspsc190501.50444605': __('identifierSchemeCodeUnspsc190501.50444605'),
	'identifierSchemeCodeUnspsc190501.50444606': __('identifierSchemeCodeUnspsc190501.50444606'),
	'identifierSchemeCodeUnspsc190501.50444607': __('identifierSchemeCodeUnspsc190501.50444607'),
	'identifierSchemeCodeUnspsc190501.50444608': __('identifierSchemeCodeUnspsc190501.50444608'),
	'identifierSchemeCodeUnspsc190501.50444609': __('identifierSchemeCodeUnspsc190501.50444609'),
	'identifierSchemeCodeUnspsc190501.50444610': __('identifierSchemeCodeUnspsc190501.50444610'),
	'identifierSchemeCodeUnspsc190501.50444611': __('identifierSchemeCodeUnspsc190501.50444611'),
	'identifierSchemeCodeUnspsc190501.50444612': __('identifierSchemeCodeUnspsc190501.50444612'),
	'identifierSchemeCodeUnspsc190501.50444613': __('identifierSchemeCodeUnspsc190501.50444613'),
	'identifierSchemeCodeUnspsc190501.50444614': __('identifierSchemeCodeUnspsc190501.50444614'),
	'identifierSchemeCodeUnspsc190501.50444615': __('identifierSchemeCodeUnspsc190501.50444615'),
	'identifierSchemeCodeUnspsc190501.50444616': __('identifierSchemeCodeUnspsc190501.50444616'),
	'identifierSchemeCodeUnspsc190501.50444617': __('identifierSchemeCodeUnspsc190501.50444617'),
	'identifierSchemeCodeUnspsc190501.50444618': __('identifierSchemeCodeUnspsc190501.50444618'),
	'identifierSchemeCodeUnspsc190501.50444619': __('identifierSchemeCodeUnspsc190501.50444619'),
	'identifierSchemeCodeUnspsc190501.50444620': __('identifierSchemeCodeUnspsc190501.50444620'),
	'identifierSchemeCodeUnspsc190501.50444621': __('identifierSchemeCodeUnspsc190501.50444621'),
	'identifierSchemeCodeUnspsc190501.50444622': __('identifierSchemeCodeUnspsc190501.50444622'),
	'identifierSchemeCodeUnspsc190501.50444623': __('identifierSchemeCodeUnspsc190501.50444623'),
	'identifierSchemeCodeUnspsc190501.50444700': __('identifierSchemeCodeUnspsc190501.50444700'),
	'identifierSchemeCodeUnspsc190501.50444701': __('identifierSchemeCodeUnspsc190501.50444701'),
	'identifierSchemeCodeUnspsc190501.50444702': __('identifierSchemeCodeUnspsc190501.50444702'),
	'identifierSchemeCodeUnspsc190501.50444703': __('identifierSchemeCodeUnspsc190501.50444703'),
	'identifierSchemeCodeUnspsc190501.50444704': __('identifierSchemeCodeUnspsc190501.50444704'),
	'identifierSchemeCodeUnspsc190501.50444705': __('identifierSchemeCodeUnspsc190501.50444705'),
	'identifierSchemeCodeUnspsc190501.50444706': __('identifierSchemeCodeUnspsc190501.50444706'),
	'identifierSchemeCodeUnspsc190501.50444800': __('identifierSchemeCodeUnspsc190501.50444800'),
	'identifierSchemeCodeUnspsc190501.50444801': __('identifierSchemeCodeUnspsc190501.50444801'),
	'identifierSchemeCodeUnspsc190501.50444802': __('identifierSchemeCodeUnspsc190501.50444802'),
	'identifierSchemeCodeUnspsc190501.50444803': __('identifierSchemeCodeUnspsc190501.50444803'),
	'identifierSchemeCodeUnspsc190501.50444804': __('identifierSchemeCodeUnspsc190501.50444804'),
	'identifierSchemeCodeUnspsc190501.50444805': __('identifierSchemeCodeUnspsc190501.50444805'),
	'identifierSchemeCodeUnspsc190501.50444806': __('identifierSchemeCodeUnspsc190501.50444806'),
	'identifierSchemeCodeUnspsc190501.50444807': __('identifierSchemeCodeUnspsc190501.50444807'),
	'identifierSchemeCodeUnspsc190501.50444808': __('identifierSchemeCodeUnspsc190501.50444808'),
	'identifierSchemeCodeUnspsc190501.50444809': __('identifierSchemeCodeUnspsc190501.50444809'),
	'identifierSchemeCodeUnspsc190501.50444810': __('identifierSchemeCodeUnspsc190501.50444810'),
	'identifierSchemeCodeUnspsc190501.50444811': __('identifierSchemeCodeUnspsc190501.50444811'),
	'identifierSchemeCodeUnspsc190501.50444812': __('identifierSchemeCodeUnspsc190501.50444812'),
	'identifierSchemeCodeUnspsc190501.50444813': __('identifierSchemeCodeUnspsc190501.50444813'),
	'identifierSchemeCodeUnspsc190501.50444814': __('identifierSchemeCodeUnspsc190501.50444814'),
	'identifierSchemeCodeUnspsc190501.50444815': __('identifierSchemeCodeUnspsc190501.50444815'),
	'identifierSchemeCodeUnspsc190501.50444816': __('identifierSchemeCodeUnspsc190501.50444816'),
	'identifierSchemeCodeUnspsc190501.50444817': __('identifierSchemeCodeUnspsc190501.50444817'),
	'identifierSchemeCodeUnspsc190501.50444818': __('identifierSchemeCodeUnspsc190501.50444818'),
	'identifierSchemeCodeUnspsc190501.50444819': __('identifierSchemeCodeUnspsc190501.50444819'),
	'identifierSchemeCodeUnspsc190501.50444820': __('identifierSchemeCodeUnspsc190501.50444820'),
	'identifierSchemeCodeUnspsc190501.50444821': __('identifierSchemeCodeUnspsc190501.50444821'),
	'identifierSchemeCodeUnspsc190501.50444900': __('identifierSchemeCodeUnspsc190501.50444900'),
	'identifierSchemeCodeUnspsc190501.50444901': __('identifierSchemeCodeUnspsc190501.50444901'),
	'identifierSchemeCodeUnspsc190501.50444902': __('identifierSchemeCodeUnspsc190501.50444902'),
	'identifierSchemeCodeUnspsc190501.50444903': __('identifierSchemeCodeUnspsc190501.50444903'),
	'identifierSchemeCodeUnspsc190501.50444904': __('identifierSchemeCodeUnspsc190501.50444904'),
	'identifierSchemeCodeUnspsc190501.50444905': __('identifierSchemeCodeUnspsc190501.50444905'),
	'identifierSchemeCodeUnspsc190501.50444906': __('identifierSchemeCodeUnspsc190501.50444906'),
	'identifierSchemeCodeUnspsc190501.50445000': __('identifierSchemeCodeUnspsc190501.50445000'),
	'identifierSchemeCodeUnspsc190501.50445001': __('identifierSchemeCodeUnspsc190501.50445001'),
	'identifierSchemeCodeUnspsc190501.50445002': __('identifierSchemeCodeUnspsc190501.50445002'),
	'identifierSchemeCodeUnspsc190501.50445003': __('identifierSchemeCodeUnspsc190501.50445003'),
	'identifierSchemeCodeUnspsc190501.50445004': __('identifierSchemeCodeUnspsc190501.50445004'),
	'identifierSchemeCodeUnspsc190501.50445100': __('identifierSchemeCodeUnspsc190501.50445100'),
	'identifierSchemeCodeUnspsc190501.50445101': __('identifierSchemeCodeUnspsc190501.50445101'),
	'identifierSchemeCodeUnspsc190501.50445102': __('identifierSchemeCodeUnspsc190501.50445102'),
	'identifierSchemeCodeUnspsc190501.50445103': __('identifierSchemeCodeUnspsc190501.50445103'),
	'identifierSchemeCodeUnspsc190501.50445104': __('identifierSchemeCodeUnspsc190501.50445104'),
	'identifierSchemeCodeUnspsc190501.50445105': __('identifierSchemeCodeUnspsc190501.50445105'),
	'identifierSchemeCodeUnspsc190501.50445106': __('identifierSchemeCodeUnspsc190501.50445106'),
	'identifierSchemeCodeUnspsc190501.50445107': __('identifierSchemeCodeUnspsc190501.50445107'),
	'identifierSchemeCodeUnspsc190501.50445200': __('identifierSchemeCodeUnspsc190501.50445200'),
	'identifierSchemeCodeUnspsc190501.50445201': __('identifierSchemeCodeUnspsc190501.50445201'),
	'identifierSchemeCodeUnspsc190501.50445202': __('identifierSchemeCodeUnspsc190501.50445202'),
	'identifierSchemeCodeUnspsc190501.50445203': __('identifierSchemeCodeUnspsc190501.50445203'),
	'identifierSchemeCodeUnspsc190501.50445204': __('identifierSchemeCodeUnspsc190501.50445204'),
	'identifierSchemeCodeUnspsc190501.50445205': __('identifierSchemeCodeUnspsc190501.50445205'),
	'identifierSchemeCodeUnspsc190501.50445206': __('identifierSchemeCodeUnspsc190501.50445206'),
	'identifierSchemeCodeUnspsc190501.50445207': __('identifierSchemeCodeUnspsc190501.50445207'),
	'identifierSchemeCodeUnspsc190501.50445208': __('identifierSchemeCodeUnspsc190501.50445208'),
	'identifierSchemeCodeUnspsc190501.50445209': __('identifierSchemeCodeUnspsc190501.50445209'),
	'identifierSchemeCodeUnspsc190501.50445210': __('identifierSchemeCodeUnspsc190501.50445210'),
	'identifierSchemeCodeUnspsc190501.50445211': __('identifierSchemeCodeUnspsc190501.50445211'),
	'identifierSchemeCodeUnspsc190501.50445212': __('identifierSchemeCodeUnspsc190501.50445212'),
	'identifierSchemeCodeUnspsc190501.50445213': __('identifierSchemeCodeUnspsc190501.50445213'),
	'identifierSchemeCodeUnspsc190501.50445214': __('identifierSchemeCodeUnspsc190501.50445214'),
	'identifierSchemeCodeUnspsc190501.50445215': __('identifierSchemeCodeUnspsc190501.50445215'),
	'identifierSchemeCodeUnspsc190501.50445216': __('identifierSchemeCodeUnspsc190501.50445216'),
	'identifierSchemeCodeUnspsc190501.50445217': __('identifierSchemeCodeUnspsc190501.50445217'),
	'identifierSchemeCodeUnspsc190501.50445218': __('identifierSchemeCodeUnspsc190501.50445218'),
	'identifierSchemeCodeUnspsc190501.50445219': __('identifierSchemeCodeUnspsc190501.50445219'),
	'identifierSchemeCodeUnspsc190501.50445220': __('identifierSchemeCodeUnspsc190501.50445220'),
	'identifierSchemeCodeUnspsc190501.50445221': __('identifierSchemeCodeUnspsc190501.50445221'),
	'identifierSchemeCodeUnspsc190501.50445300': __('identifierSchemeCodeUnspsc190501.50445300'),
	'identifierSchemeCodeUnspsc190501.50445301': __('identifierSchemeCodeUnspsc190501.50445301'),
	'identifierSchemeCodeUnspsc190501.50445302': __('identifierSchemeCodeUnspsc190501.50445302'),
	'identifierSchemeCodeUnspsc190501.50445303': __('identifierSchemeCodeUnspsc190501.50445303'),
	'identifierSchemeCodeUnspsc190501.50445304': __('identifierSchemeCodeUnspsc190501.50445304'),
	'identifierSchemeCodeUnspsc190501.50445305': __('identifierSchemeCodeUnspsc190501.50445305'),
	'identifierSchemeCodeUnspsc190501.50445306': __('identifierSchemeCodeUnspsc190501.50445306'),
	'identifierSchemeCodeUnspsc190501.50445400': __('identifierSchemeCodeUnspsc190501.50445400'),
	'identifierSchemeCodeUnspsc190501.50445401': __('identifierSchemeCodeUnspsc190501.50445401'),
	'identifierSchemeCodeUnspsc190501.50445402': __('identifierSchemeCodeUnspsc190501.50445402'),
	'identifierSchemeCodeUnspsc190501.50445403': __('identifierSchemeCodeUnspsc190501.50445403'),
	'identifierSchemeCodeUnspsc190501.50445404': __('identifierSchemeCodeUnspsc190501.50445404'),
	'identifierSchemeCodeUnspsc190501.50445405': __('identifierSchemeCodeUnspsc190501.50445405'),
	'identifierSchemeCodeUnspsc190501.50445406': __('identifierSchemeCodeUnspsc190501.50445406'),
	'identifierSchemeCodeUnspsc190501.50445500': __('identifierSchemeCodeUnspsc190501.50445500'),
	'identifierSchemeCodeUnspsc190501.50445501': __('identifierSchemeCodeUnspsc190501.50445501'),
	'identifierSchemeCodeUnspsc190501.50445502': __('identifierSchemeCodeUnspsc190501.50445502'),
	'identifierSchemeCodeUnspsc190501.50445503': __('identifierSchemeCodeUnspsc190501.50445503'),
	'identifierSchemeCodeUnspsc190501.50445504': __('identifierSchemeCodeUnspsc190501.50445504'),
	'identifierSchemeCodeUnspsc190501.50445505': __('identifierSchemeCodeUnspsc190501.50445505'),
	'identifierSchemeCodeUnspsc190501.50445506': __('identifierSchemeCodeUnspsc190501.50445506'),
	'identifierSchemeCodeUnspsc190501.50445507': __('identifierSchemeCodeUnspsc190501.50445507'),
	'identifierSchemeCodeUnspsc190501.50445508': __('identifierSchemeCodeUnspsc190501.50445508'),
	'identifierSchemeCodeUnspsc190501.50445509': __('identifierSchemeCodeUnspsc190501.50445509'),
	'identifierSchemeCodeUnspsc190501.50445510': __('identifierSchemeCodeUnspsc190501.50445510'),
	'identifierSchemeCodeUnspsc190501.50445511': __('identifierSchemeCodeUnspsc190501.50445511'),
	'identifierSchemeCodeUnspsc190501.50445512': __('identifierSchemeCodeUnspsc190501.50445512'),
	'identifierSchemeCodeUnspsc190501.50445513': __('identifierSchemeCodeUnspsc190501.50445513'),
	'identifierSchemeCodeUnspsc190501.50445514': __('identifierSchemeCodeUnspsc190501.50445514'),
	'identifierSchemeCodeUnspsc190501.50445515': __('identifierSchemeCodeUnspsc190501.50445515'),
	'identifierSchemeCodeUnspsc190501.50445516': __('identifierSchemeCodeUnspsc190501.50445516'),
	'identifierSchemeCodeUnspsc190501.50445517': __('identifierSchemeCodeUnspsc190501.50445517'),
	'identifierSchemeCodeUnspsc190501.50445518': __('identifierSchemeCodeUnspsc190501.50445518'),
	'identifierSchemeCodeUnspsc190501.50445519': __('identifierSchemeCodeUnspsc190501.50445519'),
	'identifierSchemeCodeUnspsc190501.50445520': __('identifierSchemeCodeUnspsc190501.50445520'),
	'identifierSchemeCodeUnspsc190501.50445521': __('identifierSchemeCodeUnspsc190501.50445521'),
	'identifierSchemeCodeUnspsc190501.50445522': __('identifierSchemeCodeUnspsc190501.50445522'),
	'identifierSchemeCodeUnspsc190501.50445523': __('identifierSchemeCodeUnspsc190501.50445523'),
	'identifierSchemeCodeUnspsc190501.50445524': __('identifierSchemeCodeUnspsc190501.50445524'),
	'identifierSchemeCodeUnspsc190501.50445525': __('identifierSchemeCodeUnspsc190501.50445525'),
	'identifierSchemeCodeUnspsc190501.50445526': __('identifierSchemeCodeUnspsc190501.50445526'),
	'identifierSchemeCodeUnspsc190501.50445527': __('identifierSchemeCodeUnspsc190501.50445527'),
	'identifierSchemeCodeUnspsc190501.50445528': __('identifierSchemeCodeUnspsc190501.50445528'),
	'identifierSchemeCodeUnspsc190501.50445529': __('identifierSchemeCodeUnspsc190501.50445529'),
	'identifierSchemeCodeUnspsc190501.50445530': __('identifierSchemeCodeUnspsc190501.50445530'),
	'identifierSchemeCodeUnspsc190501.50445531': __('identifierSchemeCodeUnspsc190501.50445531'),
	'identifierSchemeCodeUnspsc190501.50445532': __('identifierSchemeCodeUnspsc190501.50445532'),
	'identifierSchemeCodeUnspsc190501.50445533': __('identifierSchemeCodeUnspsc190501.50445533'),
	'identifierSchemeCodeUnspsc190501.50445534': __('identifierSchemeCodeUnspsc190501.50445534'),
	'identifierSchemeCodeUnspsc190501.50445535': __('identifierSchemeCodeUnspsc190501.50445535'),
	'identifierSchemeCodeUnspsc190501.50445536': __('identifierSchemeCodeUnspsc190501.50445536'),
	'identifierSchemeCodeUnspsc190501.50445537': __('identifierSchemeCodeUnspsc190501.50445537'),
	'identifierSchemeCodeUnspsc190501.50445600': __('identifierSchemeCodeUnspsc190501.50445600'),
	'identifierSchemeCodeUnspsc190501.50445601': __('identifierSchemeCodeUnspsc190501.50445601'),
	'identifierSchemeCodeUnspsc190501.50445602': __('identifierSchemeCodeUnspsc190501.50445602'),
	'identifierSchemeCodeUnspsc190501.50445603': __('identifierSchemeCodeUnspsc190501.50445603'),
	'identifierSchemeCodeUnspsc190501.50445604': __('identifierSchemeCodeUnspsc190501.50445604'),
	'identifierSchemeCodeUnspsc190501.50445605': __('identifierSchemeCodeUnspsc190501.50445605'),
	'identifierSchemeCodeUnspsc190501.50445606': __('identifierSchemeCodeUnspsc190501.50445606'),
	'identifierSchemeCodeUnspsc190501.50445607': __('identifierSchemeCodeUnspsc190501.50445607'),
	'identifierSchemeCodeUnspsc190501.50445608': __('identifierSchemeCodeUnspsc190501.50445608'),
	'identifierSchemeCodeUnspsc190501.50445609': __('identifierSchemeCodeUnspsc190501.50445609'),
	'identifierSchemeCodeUnspsc190501.50445610': __('identifierSchemeCodeUnspsc190501.50445610'),
	'identifierSchemeCodeUnspsc190501.50445611': __('identifierSchemeCodeUnspsc190501.50445611'),
	'identifierSchemeCodeUnspsc190501.50445700': __('identifierSchemeCodeUnspsc190501.50445700'),
	'identifierSchemeCodeUnspsc190501.50445701': __('identifierSchemeCodeUnspsc190501.50445701'),
	'identifierSchemeCodeUnspsc190501.50445702': __('identifierSchemeCodeUnspsc190501.50445702'),
	'identifierSchemeCodeUnspsc190501.50445703': __('identifierSchemeCodeUnspsc190501.50445703'),
	'identifierSchemeCodeUnspsc190501.50445704': __('identifierSchemeCodeUnspsc190501.50445704'),
	'identifierSchemeCodeUnspsc190501.50445800': __('identifierSchemeCodeUnspsc190501.50445800'),
	'identifierSchemeCodeUnspsc190501.50445801': __('identifierSchemeCodeUnspsc190501.50445801'),
	'identifierSchemeCodeUnspsc190501.50445802': __('identifierSchemeCodeUnspsc190501.50445802'),
	'identifierSchemeCodeUnspsc190501.50445803': __('identifierSchemeCodeUnspsc190501.50445803'),
	'identifierSchemeCodeUnspsc190501.50445804': __('identifierSchemeCodeUnspsc190501.50445804'),
	'identifierSchemeCodeUnspsc190501.50445805': __('identifierSchemeCodeUnspsc190501.50445805'),
	'identifierSchemeCodeUnspsc190501.50445806': __('identifierSchemeCodeUnspsc190501.50445806'),
	'identifierSchemeCodeUnspsc190501.50445807': __('identifierSchemeCodeUnspsc190501.50445807'),
	'identifierSchemeCodeUnspsc190501.50445808': __('identifierSchemeCodeUnspsc190501.50445808'),
	'identifierSchemeCodeUnspsc190501.50445809': __('identifierSchemeCodeUnspsc190501.50445809'),
	'identifierSchemeCodeUnspsc190501.50445810': __('identifierSchemeCodeUnspsc190501.50445810'),
	'identifierSchemeCodeUnspsc190501.50445811': __('identifierSchemeCodeUnspsc190501.50445811'),
	'identifierSchemeCodeUnspsc190501.50445812': __('identifierSchemeCodeUnspsc190501.50445812'),
	'identifierSchemeCodeUnspsc190501.50445813': __('identifierSchemeCodeUnspsc190501.50445813'),
	'identifierSchemeCodeUnspsc190501.50445814': __('identifierSchemeCodeUnspsc190501.50445814'),
	'identifierSchemeCodeUnspsc190501.50445815': __('identifierSchemeCodeUnspsc190501.50445815'),
	'identifierSchemeCodeUnspsc190501.50445816': __('identifierSchemeCodeUnspsc190501.50445816'),
	'identifierSchemeCodeUnspsc190501.50445900': __('identifierSchemeCodeUnspsc190501.50445900'),
	'identifierSchemeCodeUnspsc190501.50445901': __('identifierSchemeCodeUnspsc190501.50445901'),
	'identifierSchemeCodeUnspsc190501.50445902': __('identifierSchemeCodeUnspsc190501.50445902'),
	'identifierSchemeCodeUnspsc190501.50445903': __('identifierSchemeCodeUnspsc190501.50445903'),
	'identifierSchemeCodeUnspsc190501.50445904': __('identifierSchemeCodeUnspsc190501.50445904'),
	'identifierSchemeCodeUnspsc190501.50445905': __('identifierSchemeCodeUnspsc190501.50445905'),
	'identifierSchemeCodeUnspsc190501.50445906': __('identifierSchemeCodeUnspsc190501.50445906'),
	'identifierSchemeCodeUnspsc190501.50445907': __('identifierSchemeCodeUnspsc190501.50445907'),
	'identifierSchemeCodeUnspsc190501.50445908': __('identifierSchemeCodeUnspsc190501.50445908'),
	'identifierSchemeCodeUnspsc190501.50445909': __('identifierSchemeCodeUnspsc190501.50445909'),
	'identifierSchemeCodeUnspsc190501.50445910': __('identifierSchemeCodeUnspsc190501.50445910'),
	'identifierSchemeCodeUnspsc190501.50445911': __('identifierSchemeCodeUnspsc190501.50445911'),
	'identifierSchemeCodeUnspsc190501.50446000': __('identifierSchemeCodeUnspsc190501.50446000'),
	'identifierSchemeCodeUnspsc190501.50446001': __('identifierSchemeCodeUnspsc190501.50446001'),
	'identifierSchemeCodeUnspsc190501.50446002': __('identifierSchemeCodeUnspsc190501.50446002'),
	'identifierSchemeCodeUnspsc190501.50446003': __('identifierSchemeCodeUnspsc190501.50446003'),
	'identifierSchemeCodeUnspsc190501.50446004': __('identifierSchemeCodeUnspsc190501.50446004'),
	'identifierSchemeCodeUnspsc190501.50446100': __('identifierSchemeCodeUnspsc190501.50446100'),
	'identifierSchemeCodeUnspsc190501.50446101': __('identifierSchemeCodeUnspsc190501.50446101'),
	'identifierSchemeCodeUnspsc190501.50446102': __('identifierSchemeCodeUnspsc190501.50446102'),
	'identifierSchemeCodeUnspsc190501.50446103': __('identifierSchemeCodeUnspsc190501.50446103'),
	'identifierSchemeCodeUnspsc190501.50446104': __('identifierSchemeCodeUnspsc190501.50446104'),
	'identifierSchemeCodeUnspsc190501.50446105': __('identifierSchemeCodeUnspsc190501.50446105'),
	'identifierSchemeCodeUnspsc190501.50446106': __('identifierSchemeCodeUnspsc190501.50446106'),
	'identifierSchemeCodeUnspsc190501.50446107': __('identifierSchemeCodeUnspsc190501.50446107'),
	'identifierSchemeCodeUnspsc190501.50446108': __('identifierSchemeCodeUnspsc190501.50446108'),
	'identifierSchemeCodeUnspsc190501.50446109': __('identifierSchemeCodeUnspsc190501.50446109'),
	'identifierSchemeCodeUnspsc190501.50446110': __('identifierSchemeCodeUnspsc190501.50446110'),
	'identifierSchemeCodeUnspsc190501.50446111': __('identifierSchemeCodeUnspsc190501.50446111'),
	'identifierSchemeCodeUnspsc190501.50446112': __('identifierSchemeCodeUnspsc190501.50446112'),
	'identifierSchemeCodeUnspsc190501.50446113': __('identifierSchemeCodeUnspsc190501.50446113'),
	'identifierSchemeCodeUnspsc190501.50446114': __('identifierSchemeCodeUnspsc190501.50446114'),
	'identifierSchemeCodeUnspsc190501.50446115': __('identifierSchemeCodeUnspsc190501.50446115'),
	'identifierSchemeCodeUnspsc190501.50446116': __('identifierSchemeCodeUnspsc190501.50446116'),
	'identifierSchemeCodeUnspsc190501.50446200': __('identifierSchemeCodeUnspsc190501.50446200'),
	'identifierSchemeCodeUnspsc190501.50446201': __('identifierSchemeCodeUnspsc190501.50446201'),
	'identifierSchemeCodeUnspsc190501.50446202': __('identifierSchemeCodeUnspsc190501.50446202'),
	'identifierSchemeCodeUnspsc190501.50446203': __('identifierSchemeCodeUnspsc190501.50446203'),
	'identifierSchemeCodeUnspsc190501.50446204': __('identifierSchemeCodeUnspsc190501.50446204'),
	'identifierSchemeCodeUnspsc190501.50446205': __('identifierSchemeCodeUnspsc190501.50446205'),
	'identifierSchemeCodeUnspsc190501.50446206': __('identifierSchemeCodeUnspsc190501.50446206'),
	'identifierSchemeCodeUnspsc190501.50446207': __('identifierSchemeCodeUnspsc190501.50446207'),
	'identifierSchemeCodeUnspsc190501.50446208': __('identifierSchemeCodeUnspsc190501.50446208'),
	'identifierSchemeCodeUnspsc190501.50446209': __('identifierSchemeCodeUnspsc190501.50446209'),
	'identifierSchemeCodeUnspsc190501.50446210': __('identifierSchemeCodeUnspsc190501.50446210'),
	'identifierSchemeCodeUnspsc190501.50446211': __('identifierSchemeCodeUnspsc190501.50446211'),
	'identifierSchemeCodeUnspsc190501.50446212': __('identifierSchemeCodeUnspsc190501.50446212'),
	'identifierSchemeCodeUnspsc190501.50446213': __('identifierSchemeCodeUnspsc190501.50446213'),
	'identifierSchemeCodeUnspsc190501.50446214': __('identifierSchemeCodeUnspsc190501.50446214'),
	'identifierSchemeCodeUnspsc190501.50446215': __('identifierSchemeCodeUnspsc190501.50446215'),
	'identifierSchemeCodeUnspsc190501.50446216': __('identifierSchemeCodeUnspsc190501.50446216'),
	'identifierSchemeCodeUnspsc190501.50446217': __('identifierSchemeCodeUnspsc190501.50446217'),
	'identifierSchemeCodeUnspsc190501.50446218': __('identifierSchemeCodeUnspsc190501.50446218'),
	'identifierSchemeCodeUnspsc190501.50446219': __('identifierSchemeCodeUnspsc190501.50446219'),
	'identifierSchemeCodeUnspsc190501.50446220': __('identifierSchemeCodeUnspsc190501.50446220'),
	'identifierSchemeCodeUnspsc190501.50446221': __('identifierSchemeCodeUnspsc190501.50446221'),
	'identifierSchemeCodeUnspsc190501.50446222': __('identifierSchemeCodeUnspsc190501.50446222'),
	'identifierSchemeCodeUnspsc190501.50446223': __('identifierSchemeCodeUnspsc190501.50446223'),
	'identifierSchemeCodeUnspsc190501.50446224': __('identifierSchemeCodeUnspsc190501.50446224'),
	'identifierSchemeCodeUnspsc190501.50446225': __('identifierSchemeCodeUnspsc190501.50446225'),
	'identifierSchemeCodeUnspsc190501.50446226': __('identifierSchemeCodeUnspsc190501.50446226'),
	'identifierSchemeCodeUnspsc190501.50446227': __('identifierSchemeCodeUnspsc190501.50446227'),
	'identifierSchemeCodeUnspsc190501.50446228': __('identifierSchemeCodeUnspsc190501.50446228'),
	'identifierSchemeCodeUnspsc190501.50446229': __('identifierSchemeCodeUnspsc190501.50446229'),
	'identifierSchemeCodeUnspsc190501.50446300': __('identifierSchemeCodeUnspsc190501.50446300'),
	'identifierSchemeCodeUnspsc190501.50446301': __('identifierSchemeCodeUnspsc190501.50446301'),
	'identifierSchemeCodeUnspsc190501.50446302': __('identifierSchemeCodeUnspsc190501.50446302'),
	'identifierSchemeCodeUnspsc190501.50446303': __('identifierSchemeCodeUnspsc190501.50446303'),
	'identifierSchemeCodeUnspsc190501.50446304': __('identifierSchemeCodeUnspsc190501.50446304'),
	'identifierSchemeCodeUnspsc190501.50446305': __('identifierSchemeCodeUnspsc190501.50446305'),
	'identifierSchemeCodeUnspsc190501.50446306': __('identifierSchemeCodeUnspsc190501.50446306'),
	'identifierSchemeCodeUnspsc190501.50446307': __('identifierSchemeCodeUnspsc190501.50446307'),
	'identifierSchemeCodeUnspsc190501.50446308': __('identifierSchemeCodeUnspsc190501.50446308'),
	'identifierSchemeCodeUnspsc190501.50446309': __('identifierSchemeCodeUnspsc190501.50446309'),
	'identifierSchemeCodeUnspsc190501.50446310': __('identifierSchemeCodeUnspsc190501.50446310'),
	'identifierSchemeCodeUnspsc190501.50446311': __('identifierSchemeCodeUnspsc190501.50446311'),
	'identifierSchemeCodeUnspsc190501.50446312': __('identifierSchemeCodeUnspsc190501.50446312'),
	'identifierSchemeCodeUnspsc190501.50446313': __('identifierSchemeCodeUnspsc190501.50446313'),
	'identifierSchemeCodeUnspsc190501.50446314': __('identifierSchemeCodeUnspsc190501.50446314'),
	'identifierSchemeCodeUnspsc190501.50446315': __('identifierSchemeCodeUnspsc190501.50446315'),
	'identifierSchemeCodeUnspsc190501.50446316': __('identifierSchemeCodeUnspsc190501.50446316'),
	'identifierSchemeCodeUnspsc190501.50446317': __('identifierSchemeCodeUnspsc190501.50446317'),
	'identifierSchemeCodeUnspsc190501.50446318': __('identifierSchemeCodeUnspsc190501.50446318'),
	'identifierSchemeCodeUnspsc190501.50446319': __('identifierSchemeCodeUnspsc190501.50446319'),
	'identifierSchemeCodeUnspsc190501.50446320': __('identifierSchemeCodeUnspsc190501.50446320'),
	'identifierSchemeCodeUnspsc190501.50446321': __('identifierSchemeCodeUnspsc190501.50446321'),
	'identifierSchemeCodeUnspsc190501.50446400': __('identifierSchemeCodeUnspsc190501.50446400'),
	'identifierSchemeCodeUnspsc190501.50446401': __('identifierSchemeCodeUnspsc190501.50446401'),
	'identifierSchemeCodeUnspsc190501.50446402': __('identifierSchemeCodeUnspsc190501.50446402'),
	'identifierSchemeCodeUnspsc190501.50446403': __('identifierSchemeCodeUnspsc190501.50446403'),
	'identifierSchemeCodeUnspsc190501.50446404': __('identifierSchemeCodeUnspsc190501.50446404'),
	'identifierSchemeCodeUnspsc190501.50446405': __('identifierSchemeCodeUnspsc190501.50446405'),
	'identifierSchemeCodeUnspsc190501.50446406': __('identifierSchemeCodeUnspsc190501.50446406'),
	'identifierSchemeCodeUnspsc190501.50446407': __('identifierSchemeCodeUnspsc190501.50446407'),
	'identifierSchemeCodeUnspsc190501.50446408': __('identifierSchemeCodeUnspsc190501.50446408'),
	'identifierSchemeCodeUnspsc190501.50446409': __('identifierSchemeCodeUnspsc190501.50446409'),
	'identifierSchemeCodeUnspsc190501.50446410': __('identifierSchemeCodeUnspsc190501.50446410'),
	'identifierSchemeCodeUnspsc190501.50446411': __('identifierSchemeCodeUnspsc190501.50446411'),
	'identifierSchemeCodeUnspsc190501.50446412': __('identifierSchemeCodeUnspsc190501.50446412'),
	'identifierSchemeCodeUnspsc190501.50446413': __('identifierSchemeCodeUnspsc190501.50446413'),
	'identifierSchemeCodeUnspsc190501.50446414': __('identifierSchemeCodeUnspsc190501.50446414'),
	'identifierSchemeCodeUnspsc190501.50446415': __('identifierSchemeCodeUnspsc190501.50446415'),
	'identifierSchemeCodeUnspsc190501.50446416': __('identifierSchemeCodeUnspsc190501.50446416'),
	'identifierSchemeCodeUnspsc190501.50446417': __('identifierSchemeCodeUnspsc190501.50446417'),
	'identifierSchemeCodeUnspsc190501.50446418': __('identifierSchemeCodeUnspsc190501.50446418'),
	'identifierSchemeCodeUnspsc190501.50446419': __('identifierSchemeCodeUnspsc190501.50446419'),
	'identifierSchemeCodeUnspsc190501.50446420': __('identifierSchemeCodeUnspsc190501.50446420'),
	'identifierSchemeCodeUnspsc190501.50446421': __('identifierSchemeCodeUnspsc190501.50446421'),
	'identifierSchemeCodeUnspsc190501.50446422': __('identifierSchemeCodeUnspsc190501.50446422'),
	'identifierSchemeCodeUnspsc190501.50446423': __('identifierSchemeCodeUnspsc190501.50446423'),
	'identifierSchemeCodeUnspsc190501.50446424': __('identifierSchemeCodeUnspsc190501.50446424'),
	'identifierSchemeCodeUnspsc190501.50446425': __('identifierSchemeCodeUnspsc190501.50446425'),
	'identifierSchemeCodeUnspsc190501.50446426': __('identifierSchemeCodeUnspsc190501.50446426'),
	'identifierSchemeCodeUnspsc190501.50446427': __('identifierSchemeCodeUnspsc190501.50446427'),
	'identifierSchemeCodeUnspsc190501.50446500': __('identifierSchemeCodeUnspsc190501.50446500'),
	'identifierSchemeCodeUnspsc190501.50446501': __('identifierSchemeCodeUnspsc190501.50446501'),
	'identifierSchemeCodeUnspsc190501.50446502': __('identifierSchemeCodeUnspsc190501.50446502'),
	'identifierSchemeCodeUnspsc190501.50446503': __('identifierSchemeCodeUnspsc190501.50446503'),
	'identifierSchemeCodeUnspsc190501.50446504': __('identifierSchemeCodeUnspsc190501.50446504'),
	'identifierSchemeCodeUnspsc190501.50446505': __('identifierSchemeCodeUnspsc190501.50446505'),
	'identifierSchemeCodeUnspsc190501.50446506': __('identifierSchemeCodeUnspsc190501.50446506'),
	'identifierSchemeCodeUnspsc190501.50446507': __('identifierSchemeCodeUnspsc190501.50446507'),
	'identifierSchemeCodeUnspsc190501.50446508': __('identifierSchemeCodeUnspsc190501.50446508'),
	'identifierSchemeCodeUnspsc190501.50446600': __('identifierSchemeCodeUnspsc190501.50446600'),
	'identifierSchemeCodeUnspsc190501.50446601': __('identifierSchemeCodeUnspsc190501.50446601'),
	'identifierSchemeCodeUnspsc190501.50446602': __('identifierSchemeCodeUnspsc190501.50446602'),
	'identifierSchemeCodeUnspsc190501.50446603': __('identifierSchemeCodeUnspsc190501.50446603'),
	'identifierSchemeCodeUnspsc190501.50446604': __('identifierSchemeCodeUnspsc190501.50446604'),
	'identifierSchemeCodeUnspsc190501.50446605': __('identifierSchemeCodeUnspsc190501.50446605'),
	'identifierSchemeCodeUnspsc190501.50446606': __('identifierSchemeCodeUnspsc190501.50446606'),
	'identifierSchemeCodeUnspsc190501.50446607': __('identifierSchemeCodeUnspsc190501.50446607'),
	'identifierSchemeCodeUnspsc190501.50446608': __('identifierSchemeCodeUnspsc190501.50446608'),
	'identifierSchemeCodeUnspsc190501.50446609': __('identifierSchemeCodeUnspsc190501.50446609'),
	'identifierSchemeCodeUnspsc190501.50446610': __('identifierSchemeCodeUnspsc190501.50446610'),
	'identifierSchemeCodeUnspsc190501.50446611': __('identifierSchemeCodeUnspsc190501.50446611'),
	'identifierSchemeCodeUnspsc190501.50446612': __('identifierSchemeCodeUnspsc190501.50446612'),
	'identifierSchemeCodeUnspsc190501.50446613': __('identifierSchemeCodeUnspsc190501.50446613'),
	'identifierSchemeCodeUnspsc190501.50446614': __('identifierSchemeCodeUnspsc190501.50446614'),
	'identifierSchemeCodeUnspsc190501.50446615': __('identifierSchemeCodeUnspsc190501.50446615'),
	'identifierSchemeCodeUnspsc190501.50446616': __('identifierSchemeCodeUnspsc190501.50446616'),
	'identifierSchemeCodeUnspsc190501.50446617': __('identifierSchemeCodeUnspsc190501.50446617'),
	'identifierSchemeCodeUnspsc190501.50446618': __('identifierSchemeCodeUnspsc190501.50446618'),
	'identifierSchemeCodeUnspsc190501.50446619': __('identifierSchemeCodeUnspsc190501.50446619'),
	'identifierSchemeCodeUnspsc190501.50446620': __('identifierSchemeCodeUnspsc190501.50446620'),
	'identifierSchemeCodeUnspsc190501.50446700': __('identifierSchemeCodeUnspsc190501.50446700'),
	'identifierSchemeCodeUnspsc190501.50446701': __('identifierSchemeCodeUnspsc190501.50446701'),
	'identifierSchemeCodeUnspsc190501.50446702': __('identifierSchemeCodeUnspsc190501.50446702'),
	'identifierSchemeCodeUnspsc190501.50446703': __('identifierSchemeCodeUnspsc190501.50446703'),
	'identifierSchemeCodeUnspsc190501.50446704': __('identifierSchemeCodeUnspsc190501.50446704'),
	'identifierSchemeCodeUnspsc190501.50446705': __('identifierSchemeCodeUnspsc190501.50446705'),
	'identifierSchemeCodeUnspsc190501.50446706': __('identifierSchemeCodeUnspsc190501.50446706'),
	'identifierSchemeCodeUnspsc190501.50446707': __('identifierSchemeCodeUnspsc190501.50446707'),
	'identifierSchemeCodeUnspsc190501.50446708': __('identifierSchemeCodeUnspsc190501.50446708'),
	'identifierSchemeCodeUnspsc190501.50446709': __('identifierSchemeCodeUnspsc190501.50446709'),
	'identifierSchemeCodeUnspsc190501.50446710': __('identifierSchemeCodeUnspsc190501.50446710'),
	'identifierSchemeCodeUnspsc190501.50446800': __('identifierSchemeCodeUnspsc190501.50446800'),
	'identifierSchemeCodeUnspsc190501.50446801': __('identifierSchemeCodeUnspsc190501.50446801'),
	'identifierSchemeCodeUnspsc190501.50446802': __('identifierSchemeCodeUnspsc190501.50446802'),
	'identifierSchemeCodeUnspsc190501.50446803': __('identifierSchemeCodeUnspsc190501.50446803'),
	'identifierSchemeCodeUnspsc190501.50446804': __('identifierSchemeCodeUnspsc190501.50446804'),
	'identifierSchemeCodeUnspsc190501.50446805': __('identifierSchemeCodeUnspsc190501.50446805'),
	'identifierSchemeCodeUnspsc190501.50446806': __('identifierSchemeCodeUnspsc190501.50446806'),
	'identifierSchemeCodeUnspsc190501.50446807': __('identifierSchemeCodeUnspsc190501.50446807'),
	'identifierSchemeCodeUnspsc190501.50446808': __('identifierSchemeCodeUnspsc190501.50446808'),
	'identifierSchemeCodeUnspsc190501.50446809': __('identifierSchemeCodeUnspsc190501.50446809'),
	'identifierSchemeCodeUnspsc190501.50446810': __('identifierSchemeCodeUnspsc190501.50446810'),
	'identifierSchemeCodeUnspsc190501.50446811': __('identifierSchemeCodeUnspsc190501.50446811'),
	'identifierSchemeCodeUnspsc190501.50446812': __('identifierSchemeCodeUnspsc190501.50446812'),
	'identifierSchemeCodeUnspsc190501.50446813': __('identifierSchemeCodeUnspsc190501.50446813'),
	'identifierSchemeCodeUnspsc190501.50446814': __('identifierSchemeCodeUnspsc190501.50446814'),
	'identifierSchemeCodeUnspsc190501.50446815': __('identifierSchemeCodeUnspsc190501.50446815'),
	'identifierSchemeCodeUnspsc190501.50446816': __('identifierSchemeCodeUnspsc190501.50446816'),
	'identifierSchemeCodeUnspsc190501.50446817': __('identifierSchemeCodeUnspsc190501.50446817'),
	'identifierSchemeCodeUnspsc190501.50446818': __('identifierSchemeCodeUnspsc190501.50446818'),
	'identifierSchemeCodeUnspsc190501.50446819': __('identifierSchemeCodeUnspsc190501.50446819'),
	'identifierSchemeCodeUnspsc190501.50446820': __('identifierSchemeCodeUnspsc190501.50446820'),
	'identifierSchemeCodeUnspsc190501.50446821': __('identifierSchemeCodeUnspsc190501.50446821'),
	'identifierSchemeCodeUnspsc190501.50446822': __('identifierSchemeCodeUnspsc190501.50446822'),
	'identifierSchemeCodeUnspsc190501.50446823': __('identifierSchemeCodeUnspsc190501.50446823'),
	'identifierSchemeCodeUnspsc190501.50446824': __('identifierSchemeCodeUnspsc190501.50446824'),
	'identifierSchemeCodeUnspsc190501.50446825': __('identifierSchemeCodeUnspsc190501.50446825'),
	'identifierSchemeCodeUnspsc190501.50446826': __('identifierSchemeCodeUnspsc190501.50446826'),
	'identifierSchemeCodeUnspsc190501.50446827': __('identifierSchemeCodeUnspsc190501.50446827'),
	'identifierSchemeCodeUnspsc190501.50446828': __('identifierSchemeCodeUnspsc190501.50446828'),
	'identifierSchemeCodeUnspsc190501.50446829': __('identifierSchemeCodeUnspsc190501.50446829'),
	'identifierSchemeCodeUnspsc190501.50446830': __('identifierSchemeCodeUnspsc190501.50446830'),
	'identifierSchemeCodeUnspsc190501.50446831': __('identifierSchemeCodeUnspsc190501.50446831'),
	'identifierSchemeCodeUnspsc190501.50446832': __('identifierSchemeCodeUnspsc190501.50446832'),
	'identifierSchemeCodeUnspsc190501.50446833': __('identifierSchemeCodeUnspsc190501.50446833'),
	'identifierSchemeCodeUnspsc190501.50446834': __('identifierSchemeCodeUnspsc190501.50446834'),
	'identifierSchemeCodeUnspsc190501.50446835': __('identifierSchemeCodeUnspsc190501.50446835'),
	'identifierSchemeCodeUnspsc190501.50446836': __('identifierSchemeCodeUnspsc190501.50446836'),
	'identifierSchemeCodeUnspsc190501.50446837': __('identifierSchemeCodeUnspsc190501.50446837'),
	'identifierSchemeCodeUnspsc190501.50446838': __('identifierSchemeCodeUnspsc190501.50446838'),
	'identifierSchemeCodeUnspsc190501.50446839': __('identifierSchemeCodeUnspsc190501.50446839'),
	'identifierSchemeCodeUnspsc190501.50446840': __('identifierSchemeCodeUnspsc190501.50446840'),
	'identifierSchemeCodeUnspsc190501.50446841': __('identifierSchemeCodeUnspsc190501.50446841'),
	'identifierSchemeCodeUnspsc190501.50446842': __('identifierSchemeCodeUnspsc190501.50446842'),
	'identifierSchemeCodeUnspsc190501.50446843': __('identifierSchemeCodeUnspsc190501.50446843'),
	'identifierSchemeCodeUnspsc190501.50446844': __('identifierSchemeCodeUnspsc190501.50446844'),
	'identifierSchemeCodeUnspsc190501.50446845': __('identifierSchemeCodeUnspsc190501.50446845'),
	'identifierSchemeCodeUnspsc190501.50446846': __('identifierSchemeCodeUnspsc190501.50446846'),
	'identifierSchemeCodeUnspsc190501.50446847': __('identifierSchemeCodeUnspsc190501.50446847'),
	'identifierSchemeCodeUnspsc190501.50446848': __('identifierSchemeCodeUnspsc190501.50446848'),
	'identifierSchemeCodeUnspsc190501.50446849': __('identifierSchemeCodeUnspsc190501.50446849'),
	'identifierSchemeCodeUnspsc190501.50446850': __('identifierSchemeCodeUnspsc190501.50446850'),
	'identifierSchemeCodeUnspsc190501.50446851': __('identifierSchemeCodeUnspsc190501.50446851'),
	'identifierSchemeCodeUnspsc190501.50446852': __('identifierSchemeCodeUnspsc190501.50446852'),
	'identifierSchemeCodeUnspsc190501.50446853': __('identifierSchemeCodeUnspsc190501.50446853'),
	'identifierSchemeCodeUnspsc190501.50446854': __('identifierSchemeCodeUnspsc190501.50446854'),
	'identifierSchemeCodeUnspsc190501.50446855': __('identifierSchemeCodeUnspsc190501.50446855'),
	'identifierSchemeCodeUnspsc190501.50446856': __('identifierSchemeCodeUnspsc190501.50446856'),
	'identifierSchemeCodeUnspsc190501.50446857': __('identifierSchemeCodeUnspsc190501.50446857'),
	'identifierSchemeCodeUnspsc190501.50446858': __('identifierSchemeCodeUnspsc190501.50446858'),
	'identifierSchemeCodeUnspsc190501.50446859': __('identifierSchemeCodeUnspsc190501.50446859'),
	'identifierSchemeCodeUnspsc190501.50446860': __('identifierSchemeCodeUnspsc190501.50446860'),
	'identifierSchemeCodeUnspsc190501.50446861': __('identifierSchemeCodeUnspsc190501.50446861'),
	'identifierSchemeCodeUnspsc190501.50446862': __('identifierSchemeCodeUnspsc190501.50446862'),
	'identifierSchemeCodeUnspsc190501.50446863': __('identifierSchemeCodeUnspsc190501.50446863'),
	'identifierSchemeCodeUnspsc190501.50446864': __('identifierSchemeCodeUnspsc190501.50446864'),
	'identifierSchemeCodeUnspsc190501.50446865': __('identifierSchemeCodeUnspsc190501.50446865'),
	'identifierSchemeCodeUnspsc190501.50446866': __('identifierSchemeCodeUnspsc190501.50446866'),
	'identifierSchemeCodeUnspsc190501.50446867': __('identifierSchemeCodeUnspsc190501.50446867'),
	'identifierSchemeCodeUnspsc190501.50446868': __('identifierSchemeCodeUnspsc190501.50446868'),
	'identifierSchemeCodeUnspsc190501.50446900': __('identifierSchemeCodeUnspsc190501.50446900'),
	'identifierSchemeCodeUnspsc190501.50446901': __('identifierSchemeCodeUnspsc190501.50446901'),
	'identifierSchemeCodeUnspsc190501.50446902': __('identifierSchemeCodeUnspsc190501.50446902'),
	'identifierSchemeCodeUnspsc190501.50446903': __('identifierSchemeCodeUnspsc190501.50446903'),
	'identifierSchemeCodeUnspsc190501.50446904': __('identifierSchemeCodeUnspsc190501.50446904'),
	'identifierSchemeCodeUnspsc190501.50446905': __('identifierSchemeCodeUnspsc190501.50446905'),
	'identifierSchemeCodeUnspsc190501.50446906': __('identifierSchemeCodeUnspsc190501.50446906'),
	'identifierSchemeCodeUnspsc190501.50446907': __('identifierSchemeCodeUnspsc190501.50446907'),
	'identifierSchemeCodeUnspsc190501.50446908': __('identifierSchemeCodeUnspsc190501.50446908'),
	'identifierSchemeCodeUnspsc190501.50446909': __('identifierSchemeCodeUnspsc190501.50446909'),
	'identifierSchemeCodeUnspsc190501.50446910': __('identifierSchemeCodeUnspsc190501.50446910'),
	'identifierSchemeCodeUnspsc190501.50450000': __('identifierSchemeCodeUnspsc190501.50450000'),
	'identifierSchemeCodeUnspsc190501.50451500': __('identifierSchemeCodeUnspsc190501.50451500'),
	'identifierSchemeCodeUnspsc190501.50451501': __('identifierSchemeCodeUnspsc190501.50451501'),
	'identifierSchemeCodeUnspsc190501.50451502': __('identifierSchemeCodeUnspsc190501.50451502'),
	'identifierSchemeCodeUnspsc190501.50451503': __('identifierSchemeCodeUnspsc190501.50451503'),
	'identifierSchemeCodeUnspsc190501.50451504': __('identifierSchemeCodeUnspsc190501.50451504'),
	'identifierSchemeCodeUnspsc190501.50451505': __('identifierSchemeCodeUnspsc190501.50451505'),
	'identifierSchemeCodeUnspsc190501.50451506': __('identifierSchemeCodeUnspsc190501.50451506'),
	'identifierSchemeCodeUnspsc190501.50451507': __('identifierSchemeCodeUnspsc190501.50451507'),
	'identifierSchemeCodeUnspsc190501.50451508': __('identifierSchemeCodeUnspsc190501.50451508'),
	'identifierSchemeCodeUnspsc190501.50451509': __('identifierSchemeCodeUnspsc190501.50451509'),
	'identifierSchemeCodeUnspsc190501.50451510': __('identifierSchemeCodeUnspsc190501.50451510'),
	'identifierSchemeCodeUnspsc190501.50451511': __('identifierSchemeCodeUnspsc190501.50451511'),
	'identifierSchemeCodeUnspsc190501.50451512': __('identifierSchemeCodeUnspsc190501.50451512'),
	'identifierSchemeCodeUnspsc190501.50451513': __('identifierSchemeCodeUnspsc190501.50451513'),
	'identifierSchemeCodeUnspsc190501.50451600': __('identifierSchemeCodeUnspsc190501.50451600'),
	'identifierSchemeCodeUnspsc190501.50451601': __('identifierSchemeCodeUnspsc190501.50451601'),
	'identifierSchemeCodeUnspsc190501.50451602': __('identifierSchemeCodeUnspsc190501.50451602'),
	'identifierSchemeCodeUnspsc190501.50451603': __('identifierSchemeCodeUnspsc190501.50451603'),
	'identifierSchemeCodeUnspsc190501.50451604': __('identifierSchemeCodeUnspsc190501.50451604'),
	'identifierSchemeCodeUnspsc190501.50451605': __('identifierSchemeCodeUnspsc190501.50451605'),
	'identifierSchemeCodeUnspsc190501.50451700': __('identifierSchemeCodeUnspsc190501.50451700'),
	'identifierSchemeCodeUnspsc190501.50451701': __('identifierSchemeCodeUnspsc190501.50451701'),
	'identifierSchemeCodeUnspsc190501.50451702': __('identifierSchemeCodeUnspsc190501.50451702'),
	'identifierSchemeCodeUnspsc190501.50451703': __('identifierSchemeCodeUnspsc190501.50451703'),
	'identifierSchemeCodeUnspsc190501.50451704': __('identifierSchemeCodeUnspsc190501.50451704'),
	'identifierSchemeCodeUnspsc190501.50451705': __('identifierSchemeCodeUnspsc190501.50451705'),
	'identifierSchemeCodeUnspsc190501.50451706': __('identifierSchemeCodeUnspsc190501.50451706'),
	'identifierSchemeCodeUnspsc190501.50451707': __('identifierSchemeCodeUnspsc190501.50451707'),
	'identifierSchemeCodeUnspsc190501.50451708': __('identifierSchemeCodeUnspsc190501.50451708'),
	'identifierSchemeCodeUnspsc190501.50451709': __('identifierSchemeCodeUnspsc190501.50451709'),
	'identifierSchemeCodeUnspsc190501.50451710': __('identifierSchemeCodeUnspsc190501.50451710'),
	'identifierSchemeCodeUnspsc190501.50451711': __('identifierSchemeCodeUnspsc190501.50451711'),
	'identifierSchemeCodeUnspsc190501.50451712': __('identifierSchemeCodeUnspsc190501.50451712'),
	'identifierSchemeCodeUnspsc190501.50451713': __('identifierSchemeCodeUnspsc190501.50451713'),
	'identifierSchemeCodeUnspsc190501.50451714': __('identifierSchemeCodeUnspsc190501.50451714'),
	'identifierSchemeCodeUnspsc190501.50451715': __('identifierSchemeCodeUnspsc190501.50451715'),
	'identifierSchemeCodeUnspsc190501.50451716': __('identifierSchemeCodeUnspsc190501.50451716'),
	'identifierSchemeCodeUnspsc190501.50451717': __('identifierSchemeCodeUnspsc190501.50451717'),
	'identifierSchemeCodeUnspsc190501.50451718': __('identifierSchemeCodeUnspsc190501.50451718'),
	'identifierSchemeCodeUnspsc190501.50451719': __('identifierSchemeCodeUnspsc190501.50451719'),
	'identifierSchemeCodeUnspsc190501.50451720': __('identifierSchemeCodeUnspsc190501.50451720'),
	'identifierSchemeCodeUnspsc190501.50451721': __('identifierSchemeCodeUnspsc190501.50451721'),
	'identifierSchemeCodeUnspsc190501.50451722': __('identifierSchemeCodeUnspsc190501.50451722'),
	'identifierSchemeCodeUnspsc190501.50451723': __('identifierSchemeCodeUnspsc190501.50451723'),
	'identifierSchemeCodeUnspsc190501.50451724': __('identifierSchemeCodeUnspsc190501.50451724'),
	'identifierSchemeCodeUnspsc190501.50451725': __('identifierSchemeCodeUnspsc190501.50451725'),
	'identifierSchemeCodeUnspsc190501.50451726': __('identifierSchemeCodeUnspsc190501.50451726'),
	'identifierSchemeCodeUnspsc190501.50451727': __('identifierSchemeCodeUnspsc190501.50451727'),
	'identifierSchemeCodeUnspsc190501.50451728': __('identifierSchemeCodeUnspsc190501.50451728'),
	'identifierSchemeCodeUnspsc190501.50451729': __('identifierSchemeCodeUnspsc190501.50451729'),
	'identifierSchemeCodeUnspsc190501.50451730': __('identifierSchemeCodeUnspsc190501.50451730'),
	'identifierSchemeCodeUnspsc190501.50451731': __('identifierSchemeCodeUnspsc190501.50451731'),
	'identifierSchemeCodeUnspsc190501.50451732': __('identifierSchemeCodeUnspsc190501.50451732'),
	'identifierSchemeCodeUnspsc190501.50451733': __('identifierSchemeCodeUnspsc190501.50451733'),
	'identifierSchemeCodeUnspsc190501.50451734': __('identifierSchemeCodeUnspsc190501.50451734'),
	'identifierSchemeCodeUnspsc190501.50451735': __('identifierSchemeCodeUnspsc190501.50451735'),
	'identifierSchemeCodeUnspsc190501.50451736': __('identifierSchemeCodeUnspsc190501.50451736'),
	'identifierSchemeCodeUnspsc190501.50451737': __('identifierSchemeCodeUnspsc190501.50451737'),
	'identifierSchemeCodeUnspsc190501.50451738': __('identifierSchemeCodeUnspsc190501.50451738'),
	'identifierSchemeCodeUnspsc190501.50451739': __('identifierSchemeCodeUnspsc190501.50451739'),
	'identifierSchemeCodeUnspsc190501.50451740': __('identifierSchemeCodeUnspsc190501.50451740'),
	'identifierSchemeCodeUnspsc190501.50451741': __('identifierSchemeCodeUnspsc190501.50451741'),
	'identifierSchemeCodeUnspsc190501.50451742': __('identifierSchemeCodeUnspsc190501.50451742'),
	'identifierSchemeCodeUnspsc190501.50451743': __('identifierSchemeCodeUnspsc190501.50451743'),
	'identifierSchemeCodeUnspsc190501.50451744': __('identifierSchemeCodeUnspsc190501.50451744'),
	'identifierSchemeCodeUnspsc190501.50451745': __('identifierSchemeCodeUnspsc190501.50451745'),
	'identifierSchemeCodeUnspsc190501.50451746': __('identifierSchemeCodeUnspsc190501.50451746'),
	'identifierSchemeCodeUnspsc190501.50451747': __('identifierSchemeCodeUnspsc190501.50451747'),
	'identifierSchemeCodeUnspsc190501.50451748': __('identifierSchemeCodeUnspsc190501.50451748'),
	'identifierSchemeCodeUnspsc190501.50451749': __('identifierSchemeCodeUnspsc190501.50451749'),
	'identifierSchemeCodeUnspsc190501.50451750': __('identifierSchemeCodeUnspsc190501.50451750'),
	'identifierSchemeCodeUnspsc190501.50451751': __('identifierSchemeCodeUnspsc190501.50451751'),
	'identifierSchemeCodeUnspsc190501.50451752': __('identifierSchemeCodeUnspsc190501.50451752'),
	'identifierSchemeCodeUnspsc190501.50451753': __('identifierSchemeCodeUnspsc190501.50451753'),
	'identifierSchemeCodeUnspsc190501.50451754': __('identifierSchemeCodeUnspsc190501.50451754'),
	'identifierSchemeCodeUnspsc190501.50451755': __('identifierSchemeCodeUnspsc190501.50451755'),
	'identifierSchemeCodeUnspsc190501.50451756': __('identifierSchemeCodeUnspsc190501.50451756'),
	'identifierSchemeCodeUnspsc190501.50451757': __('identifierSchemeCodeUnspsc190501.50451757'),
	'identifierSchemeCodeUnspsc190501.50451758': __('identifierSchemeCodeUnspsc190501.50451758'),
	'identifierSchemeCodeUnspsc190501.50451759': __('identifierSchemeCodeUnspsc190501.50451759'),
	'identifierSchemeCodeUnspsc190501.50451760': __('identifierSchemeCodeUnspsc190501.50451760'),
	'identifierSchemeCodeUnspsc190501.50451761': __('identifierSchemeCodeUnspsc190501.50451761'),
	'identifierSchemeCodeUnspsc190501.50451762': __('identifierSchemeCodeUnspsc190501.50451762'),
	'identifierSchemeCodeUnspsc190501.50451763': __('identifierSchemeCodeUnspsc190501.50451763'),
	'identifierSchemeCodeUnspsc190501.50451764': __('identifierSchemeCodeUnspsc190501.50451764'),
	'identifierSchemeCodeUnspsc190501.50451765': __('identifierSchemeCodeUnspsc190501.50451765'),
	'identifierSchemeCodeUnspsc190501.50451766': __('identifierSchemeCodeUnspsc190501.50451766'),
	'identifierSchemeCodeUnspsc190501.50451767': __('identifierSchemeCodeUnspsc190501.50451767'),
	'identifierSchemeCodeUnspsc190501.50451768': __('identifierSchemeCodeUnspsc190501.50451768'),
	'identifierSchemeCodeUnspsc190501.50451769': __('identifierSchemeCodeUnspsc190501.50451769'),
	'identifierSchemeCodeUnspsc190501.50451770': __('identifierSchemeCodeUnspsc190501.50451770'),
	'identifierSchemeCodeUnspsc190501.50451771': __('identifierSchemeCodeUnspsc190501.50451771'),
	'identifierSchemeCodeUnspsc190501.50451772': __('identifierSchemeCodeUnspsc190501.50451772'),
	'identifierSchemeCodeUnspsc190501.50451773': __('identifierSchemeCodeUnspsc190501.50451773'),
	'identifierSchemeCodeUnspsc190501.50451774': __('identifierSchemeCodeUnspsc190501.50451774'),
	'identifierSchemeCodeUnspsc190501.50451775': __('identifierSchemeCodeUnspsc190501.50451775'),
	'identifierSchemeCodeUnspsc190501.50451776': __('identifierSchemeCodeUnspsc190501.50451776'),
	'identifierSchemeCodeUnspsc190501.50451777': __('identifierSchemeCodeUnspsc190501.50451777'),
	'identifierSchemeCodeUnspsc190501.50451778': __('identifierSchemeCodeUnspsc190501.50451778'),
	'identifierSchemeCodeUnspsc190501.50451779': __('identifierSchemeCodeUnspsc190501.50451779'),
	'identifierSchemeCodeUnspsc190501.50451780': __('identifierSchemeCodeUnspsc190501.50451780'),
	'identifierSchemeCodeUnspsc190501.50451781': __('identifierSchemeCodeUnspsc190501.50451781'),
	'identifierSchemeCodeUnspsc190501.50451782': __('identifierSchemeCodeUnspsc190501.50451782'),
	'identifierSchemeCodeUnspsc190501.50451800': __('identifierSchemeCodeUnspsc190501.50451800'),
	'identifierSchemeCodeUnspsc190501.50451801': __('identifierSchemeCodeUnspsc190501.50451801'),
	'identifierSchemeCodeUnspsc190501.50451802': __('identifierSchemeCodeUnspsc190501.50451802'),
	'identifierSchemeCodeUnspsc190501.50451803': __('identifierSchemeCodeUnspsc190501.50451803'),
	'identifierSchemeCodeUnspsc190501.50451804': __('identifierSchemeCodeUnspsc190501.50451804'),
	'identifierSchemeCodeUnspsc190501.50451805': __('identifierSchemeCodeUnspsc190501.50451805'),
	'identifierSchemeCodeUnspsc190501.50451806': __('identifierSchemeCodeUnspsc190501.50451806'),
	'identifierSchemeCodeUnspsc190501.50451807': __('identifierSchemeCodeUnspsc190501.50451807'),
	'identifierSchemeCodeUnspsc190501.50451808': __('identifierSchemeCodeUnspsc190501.50451808'),
	'identifierSchemeCodeUnspsc190501.50451809': __('identifierSchemeCodeUnspsc190501.50451809'),
	'identifierSchemeCodeUnspsc190501.50451810': __('identifierSchemeCodeUnspsc190501.50451810'),
	'identifierSchemeCodeUnspsc190501.50451811': __('identifierSchemeCodeUnspsc190501.50451811'),
	'identifierSchemeCodeUnspsc190501.50451812': __('identifierSchemeCodeUnspsc190501.50451812'),
	'identifierSchemeCodeUnspsc190501.50451813': __('identifierSchemeCodeUnspsc190501.50451813'),
	'identifierSchemeCodeUnspsc190501.50451814': __('identifierSchemeCodeUnspsc190501.50451814'),
	'identifierSchemeCodeUnspsc190501.50451815': __('identifierSchemeCodeUnspsc190501.50451815'),
	'identifierSchemeCodeUnspsc190501.50451816': __('identifierSchemeCodeUnspsc190501.50451816'),
	'identifierSchemeCodeUnspsc190501.50451817': __('identifierSchemeCodeUnspsc190501.50451817'),
	'identifierSchemeCodeUnspsc190501.50451818': __('identifierSchemeCodeUnspsc190501.50451818'),
	'identifierSchemeCodeUnspsc190501.50451819': __('identifierSchemeCodeUnspsc190501.50451819'),
	'identifierSchemeCodeUnspsc190501.50451820': __('identifierSchemeCodeUnspsc190501.50451820'),
	'identifierSchemeCodeUnspsc190501.50451821': __('identifierSchemeCodeUnspsc190501.50451821'),
	'identifierSchemeCodeUnspsc190501.50451822': __('identifierSchemeCodeUnspsc190501.50451822'),
	'identifierSchemeCodeUnspsc190501.50451823': __('identifierSchemeCodeUnspsc190501.50451823'),
	'identifierSchemeCodeUnspsc190501.50451824': __('identifierSchemeCodeUnspsc190501.50451824'),
	'identifierSchemeCodeUnspsc190501.50451825': __('identifierSchemeCodeUnspsc190501.50451825'),
	'identifierSchemeCodeUnspsc190501.50451826': __('identifierSchemeCodeUnspsc190501.50451826'),
	'identifierSchemeCodeUnspsc190501.50451827': __('identifierSchemeCodeUnspsc190501.50451827'),
	'identifierSchemeCodeUnspsc190501.50451828': __('identifierSchemeCodeUnspsc190501.50451828'),
	'identifierSchemeCodeUnspsc190501.50451829': __('identifierSchemeCodeUnspsc190501.50451829'),
	'identifierSchemeCodeUnspsc190501.50451830': __('identifierSchemeCodeUnspsc190501.50451830'),
	'identifierSchemeCodeUnspsc190501.50451831': __('identifierSchemeCodeUnspsc190501.50451831'),
	'identifierSchemeCodeUnspsc190501.50451832': __('identifierSchemeCodeUnspsc190501.50451832'),
	'identifierSchemeCodeUnspsc190501.50451833': __('identifierSchemeCodeUnspsc190501.50451833'),
	'identifierSchemeCodeUnspsc190501.50451834': __('identifierSchemeCodeUnspsc190501.50451834'),
	'identifierSchemeCodeUnspsc190501.50451835': __('identifierSchemeCodeUnspsc190501.50451835'),
	'identifierSchemeCodeUnspsc190501.50451836': __('identifierSchemeCodeUnspsc190501.50451836'),
	'identifierSchemeCodeUnspsc190501.50451837': __('identifierSchemeCodeUnspsc190501.50451837'),
	'identifierSchemeCodeUnspsc190501.50451838': __('identifierSchemeCodeUnspsc190501.50451838'),
	'identifierSchemeCodeUnspsc190501.50451839': __('identifierSchemeCodeUnspsc190501.50451839'),
	'identifierSchemeCodeUnspsc190501.50451840': __('identifierSchemeCodeUnspsc190501.50451840'),
	'identifierSchemeCodeUnspsc190501.50451841': __('identifierSchemeCodeUnspsc190501.50451841'),
	'identifierSchemeCodeUnspsc190501.50451842': __('identifierSchemeCodeUnspsc190501.50451842'),
	'identifierSchemeCodeUnspsc190501.50451843': __('identifierSchemeCodeUnspsc190501.50451843'),
	'identifierSchemeCodeUnspsc190501.50451844': __('identifierSchemeCodeUnspsc190501.50451844'),
	'identifierSchemeCodeUnspsc190501.50451845': __('identifierSchemeCodeUnspsc190501.50451845'),
	'identifierSchemeCodeUnspsc190501.50451846': __('identifierSchemeCodeUnspsc190501.50451846'),
	'identifierSchemeCodeUnspsc190501.50451847': __('identifierSchemeCodeUnspsc190501.50451847'),
	'identifierSchemeCodeUnspsc190501.50451848': __('identifierSchemeCodeUnspsc190501.50451848'),
	'identifierSchemeCodeUnspsc190501.50451849': __('identifierSchemeCodeUnspsc190501.50451849'),
	'identifierSchemeCodeUnspsc190501.50451850': __('identifierSchemeCodeUnspsc190501.50451850'),
	'identifierSchemeCodeUnspsc190501.50451851': __('identifierSchemeCodeUnspsc190501.50451851'),
	'identifierSchemeCodeUnspsc190501.50451852': __('identifierSchemeCodeUnspsc190501.50451852'),
	'identifierSchemeCodeUnspsc190501.50451900': __('identifierSchemeCodeUnspsc190501.50451900'),
	'identifierSchemeCodeUnspsc190501.50451901': __('identifierSchemeCodeUnspsc190501.50451901'),
	'identifierSchemeCodeUnspsc190501.50451902': __('identifierSchemeCodeUnspsc190501.50451902'),
	'identifierSchemeCodeUnspsc190501.50451903': __('identifierSchemeCodeUnspsc190501.50451903'),
	'identifierSchemeCodeUnspsc190501.50451904': __('identifierSchemeCodeUnspsc190501.50451904'),
	'identifierSchemeCodeUnspsc190501.50451905': __('identifierSchemeCodeUnspsc190501.50451905'),
	'identifierSchemeCodeUnspsc190501.50451906': __('identifierSchemeCodeUnspsc190501.50451906'),
	'identifierSchemeCodeUnspsc190501.50451907': __('identifierSchemeCodeUnspsc190501.50451907'),
	'identifierSchemeCodeUnspsc190501.50451908': __('identifierSchemeCodeUnspsc190501.50451908'),
	'identifierSchemeCodeUnspsc190501.50451909': __('identifierSchemeCodeUnspsc190501.50451909'),
	'identifierSchemeCodeUnspsc190501.50451910': __('identifierSchemeCodeUnspsc190501.50451910'),
	'identifierSchemeCodeUnspsc190501.50451911': __('identifierSchemeCodeUnspsc190501.50451911'),
	'identifierSchemeCodeUnspsc190501.50451912': __('identifierSchemeCodeUnspsc190501.50451912'),
	'identifierSchemeCodeUnspsc190501.50451913': __('identifierSchemeCodeUnspsc190501.50451913'),
	'identifierSchemeCodeUnspsc190501.50451914': __('identifierSchemeCodeUnspsc190501.50451914'),
	'identifierSchemeCodeUnspsc190501.50451915': __('identifierSchemeCodeUnspsc190501.50451915'),
	'identifierSchemeCodeUnspsc190501.50451916': __('identifierSchemeCodeUnspsc190501.50451916'),
	'identifierSchemeCodeUnspsc190501.50451917': __('identifierSchemeCodeUnspsc190501.50451917'),
	'identifierSchemeCodeUnspsc190501.50451918': __('identifierSchemeCodeUnspsc190501.50451918'),
	'identifierSchemeCodeUnspsc190501.50451919': __('identifierSchemeCodeUnspsc190501.50451919'),
	'identifierSchemeCodeUnspsc190501.50451920': __('identifierSchemeCodeUnspsc190501.50451920'),
	'identifierSchemeCodeUnspsc190501.50451921': __('identifierSchemeCodeUnspsc190501.50451921'),
	'identifierSchemeCodeUnspsc190501.50451922': __('identifierSchemeCodeUnspsc190501.50451922'),
	'identifierSchemeCodeUnspsc190501.50452000': __('identifierSchemeCodeUnspsc190501.50452000'),
	'identifierSchemeCodeUnspsc190501.50452001': __('identifierSchemeCodeUnspsc190501.50452001'),
	'identifierSchemeCodeUnspsc190501.50452002': __('identifierSchemeCodeUnspsc190501.50452002'),
	'identifierSchemeCodeUnspsc190501.50452003': __('identifierSchemeCodeUnspsc190501.50452003'),
	'identifierSchemeCodeUnspsc190501.50452004': __('identifierSchemeCodeUnspsc190501.50452004'),
	'identifierSchemeCodeUnspsc190501.50452100': __('identifierSchemeCodeUnspsc190501.50452100'),
	'identifierSchemeCodeUnspsc190501.50452101': __('identifierSchemeCodeUnspsc190501.50452101'),
	'identifierSchemeCodeUnspsc190501.50452102': __('identifierSchemeCodeUnspsc190501.50452102'),
	'identifierSchemeCodeUnspsc190501.50452103': __('identifierSchemeCodeUnspsc190501.50452103'),
	'identifierSchemeCodeUnspsc190501.50452104': __('identifierSchemeCodeUnspsc190501.50452104'),
	'identifierSchemeCodeUnspsc190501.50452105': __('identifierSchemeCodeUnspsc190501.50452105'),
	'identifierSchemeCodeUnspsc190501.50452106': __('identifierSchemeCodeUnspsc190501.50452106'),
	'identifierSchemeCodeUnspsc190501.50452107': __('identifierSchemeCodeUnspsc190501.50452107'),
	'identifierSchemeCodeUnspsc190501.50452200': __('identifierSchemeCodeUnspsc190501.50452200'),
	'identifierSchemeCodeUnspsc190501.50452201': __('identifierSchemeCodeUnspsc190501.50452201'),
	'identifierSchemeCodeUnspsc190501.50452202': __('identifierSchemeCodeUnspsc190501.50452202'),
	'identifierSchemeCodeUnspsc190501.50452203': __('identifierSchemeCodeUnspsc190501.50452203'),
	'identifierSchemeCodeUnspsc190501.50452204': __('identifierSchemeCodeUnspsc190501.50452204'),
	'identifierSchemeCodeUnspsc190501.50452205': __('identifierSchemeCodeUnspsc190501.50452205'),
	'identifierSchemeCodeUnspsc190501.50452206': __('identifierSchemeCodeUnspsc190501.50452206'),
	'identifierSchemeCodeUnspsc190501.50452207': __('identifierSchemeCodeUnspsc190501.50452207'),
	'identifierSchemeCodeUnspsc190501.50452208': __('identifierSchemeCodeUnspsc190501.50452208'),
	'identifierSchemeCodeUnspsc190501.50452209': __('identifierSchemeCodeUnspsc190501.50452209'),
	'identifierSchemeCodeUnspsc190501.50452210': __('identifierSchemeCodeUnspsc190501.50452210'),
	'identifierSchemeCodeUnspsc190501.50452211': __('identifierSchemeCodeUnspsc190501.50452211'),
	'identifierSchemeCodeUnspsc190501.50452300': __('identifierSchemeCodeUnspsc190501.50452300'),
	'identifierSchemeCodeUnspsc190501.50452301': __('identifierSchemeCodeUnspsc190501.50452301'),
	'identifierSchemeCodeUnspsc190501.50452302': __('identifierSchemeCodeUnspsc190501.50452302'),
	'identifierSchemeCodeUnspsc190501.50452303': __('identifierSchemeCodeUnspsc190501.50452303'),
	'identifierSchemeCodeUnspsc190501.50452304': __('identifierSchemeCodeUnspsc190501.50452304'),
	'identifierSchemeCodeUnspsc190501.50452400': __('identifierSchemeCodeUnspsc190501.50452400'),
	'identifierSchemeCodeUnspsc190501.50452401': __('identifierSchemeCodeUnspsc190501.50452401'),
	'identifierSchemeCodeUnspsc190501.50452402': __('identifierSchemeCodeUnspsc190501.50452402'),
	'identifierSchemeCodeUnspsc190501.50452500': __('identifierSchemeCodeUnspsc190501.50452500'),
	'identifierSchemeCodeUnspsc190501.50452501': __('identifierSchemeCodeUnspsc190501.50452501'),
	'identifierSchemeCodeUnspsc190501.50452502': __('identifierSchemeCodeUnspsc190501.50452502'),
	'identifierSchemeCodeUnspsc190501.50452503': __('identifierSchemeCodeUnspsc190501.50452503'),
	'identifierSchemeCodeUnspsc190501.50452504': __('identifierSchemeCodeUnspsc190501.50452504'),
	'identifierSchemeCodeUnspsc190501.50452505': __('identifierSchemeCodeUnspsc190501.50452505'),
	'identifierSchemeCodeUnspsc190501.50452506': __('identifierSchemeCodeUnspsc190501.50452506'),
	'identifierSchemeCodeUnspsc190501.50452600': __('identifierSchemeCodeUnspsc190501.50452600'),
	'identifierSchemeCodeUnspsc190501.50452601': __('identifierSchemeCodeUnspsc190501.50452601'),
	'identifierSchemeCodeUnspsc190501.50452602': __('identifierSchemeCodeUnspsc190501.50452602'),
	'identifierSchemeCodeUnspsc190501.50452603': __('identifierSchemeCodeUnspsc190501.50452603'),
	'identifierSchemeCodeUnspsc190501.50452604': __('identifierSchemeCodeUnspsc190501.50452604'),
	'identifierSchemeCodeUnspsc190501.50452605': __('identifierSchemeCodeUnspsc190501.50452605'),
	'identifierSchemeCodeUnspsc190501.50452606': __('identifierSchemeCodeUnspsc190501.50452606'),
	'identifierSchemeCodeUnspsc190501.50452607': __('identifierSchemeCodeUnspsc190501.50452607'),
	'identifierSchemeCodeUnspsc190501.50452608': __('identifierSchemeCodeUnspsc190501.50452608'),
	'identifierSchemeCodeUnspsc190501.50452609': __('identifierSchemeCodeUnspsc190501.50452609'),
	'identifierSchemeCodeUnspsc190501.50452610': __('identifierSchemeCodeUnspsc190501.50452610'),
	'identifierSchemeCodeUnspsc190501.50452611': __('identifierSchemeCodeUnspsc190501.50452611'),
	'identifierSchemeCodeUnspsc190501.50452612': __('identifierSchemeCodeUnspsc190501.50452612'),
	'identifierSchemeCodeUnspsc190501.50452700': __('identifierSchemeCodeUnspsc190501.50452700'),
	'identifierSchemeCodeUnspsc190501.50452701': __('identifierSchemeCodeUnspsc190501.50452701'),
	'identifierSchemeCodeUnspsc190501.50452702': __('identifierSchemeCodeUnspsc190501.50452702'),
	'identifierSchemeCodeUnspsc190501.50452703': __('identifierSchemeCodeUnspsc190501.50452703'),
	'identifierSchemeCodeUnspsc190501.50452704': __('identifierSchemeCodeUnspsc190501.50452704'),
	'identifierSchemeCodeUnspsc190501.50452705': __('identifierSchemeCodeUnspsc190501.50452705'),
	'identifierSchemeCodeUnspsc190501.50452706': __('identifierSchemeCodeUnspsc190501.50452706'),
	'identifierSchemeCodeUnspsc190501.50452707': __('identifierSchemeCodeUnspsc190501.50452707'),
	'identifierSchemeCodeUnspsc190501.50452708': __('identifierSchemeCodeUnspsc190501.50452708'),
	'identifierSchemeCodeUnspsc190501.50452709': __('identifierSchemeCodeUnspsc190501.50452709'),
	'identifierSchemeCodeUnspsc190501.50452710': __('identifierSchemeCodeUnspsc190501.50452710'),
	'identifierSchemeCodeUnspsc190501.50452711': __('identifierSchemeCodeUnspsc190501.50452711'),
	'identifierSchemeCodeUnspsc190501.50452712': __('identifierSchemeCodeUnspsc190501.50452712'),
	'identifierSchemeCodeUnspsc190501.50452713': __('identifierSchemeCodeUnspsc190501.50452713'),
	'identifierSchemeCodeUnspsc190501.50452714': __('identifierSchemeCodeUnspsc190501.50452714'),
	'identifierSchemeCodeUnspsc190501.50452715': __('identifierSchemeCodeUnspsc190501.50452715'),
	'identifierSchemeCodeUnspsc190501.50452800': __('identifierSchemeCodeUnspsc190501.50452800'),
	'identifierSchemeCodeUnspsc190501.50452801': __('identifierSchemeCodeUnspsc190501.50452801'),
	'identifierSchemeCodeUnspsc190501.50452802': __('identifierSchemeCodeUnspsc190501.50452802'),
	'identifierSchemeCodeUnspsc190501.50452803': __('identifierSchemeCodeUnspsc190501.50452803'),
	'identifierSchemeCodeUnspsc190501.50452804': __('identifierSchemeCodeUnspsc190501.50452804'),
	'identifierSchemeCodeUnspsc190501.50452805': __('identifierSchemeCodeUnspsc190501.50452805'),
	'identifierSchemeCodeUnspsc190501.50452806': __('identifierSchemeCodeUnspsc190501.50452806'),
	'identifierSchemeCodeUnspsc190501.50452807': __('identifierSchemeCodeUnspsc190501.50452807'),
	'identifierSchemeCodeUnspsc190501.50452808': __('identifierSchemeCodeUnspsc190501.50452808'),
	'identifierSchemeCodeUnspsc190501.50452900': __('identifierSchemeCodeUnspsc190501.50452900'),
	'identifierSchemeCodeUnspsc190501.50452901': __('identifierSchemeCodeUnspsc190501.50452901'),
	'identifierSchemeCodeUnspsc190501.50452902': __('identifierSchemeCodeUnspsc190501.50452902'),
	'identifierSchemeCodeUnspsc190501.50452903': __('identifierSchemeCodeUnspsc190501.50452903'),
	'identifierSchemeCodeUnspsc190501.50452904': __('identifierSchemeCodeUnspsc190501.50452904'),
	'identifierSchemeCodeUnspsc190501.50452905': __('identifierSchemeCodeUnspsc190501.50452905'),
	'identifierSchemeCodeUnspsc190501.50452906': __('identifierSchemeCodeUnspsc190501.50452906'),
	'identifierSchemeCodeUnspsc190501.50452907': __('identifierSchemeCodeUnspsc190501.50452907'),
	'identifierSchemeCodeUnspsc190501.50452908': __('identifierSchemeCodeUnspsc190501.50452908'),
	'identifierSchemeCodeUnspsc190501.50452909': __('identifierSchemeCodeUnspsc190501.50452909'),
	'identifierSchemeCodeUnspsc190501.50452910': __('identifierSchemeCodeUnspsc190501.50452910'),
	'identifierSchemeCodeUnspsc190501.50452911': __('identifierSchemeCodeUnspsc190501.50452911'),
	'identifierSchemeCodeUnspsc190501.50453000': __('identifierSchemeCodeUnspsc190501.50453000'),
	'identifierSchemeCodeUnspsc190501.50453001': __('identifierSchemeCodeUnspsc190501.50453001'),
	'identifierSchemeCodeUnspsc190501.50453002': __('identifierSchemeCodeUnspsc190501.50453002'),
	'identifierSchemeCodeUnspsc190501.50453003': __('identifierSchemeCodeUnspsc190501.50453003'),
	'identifierSchemeCodeUnspsc190501.50453004': __('identifierSchemeCodeUnspsc190501.50453004'),
	'identifierSchemeCodeUnspsc190501.50453005': __('identifierSchemeCodeUnspsc190501.50453005'),
	'identifierSchemeCodeUnspsc190501.50453006': __('identifierSchemeCodeUnspsc190501.50453006'),
	'identifierSchemeCodeUnspsc190501.50453007': __('identifierSchemeCodeUnspsc190501.50453007'),
	'identifierSchemeCodeUnspsc190501.50453008': __('identifierSchemeCodeUnspsc190501.50453008'),
	'identifierSchemeCodeUnspsc190501.50453009': __('identifierSchemeCodeUnspsc190501.50453009'),
	'identifierSchemeCodeUnspsc190501.50453010': __('identifierSchemeCodeUnspsc190501.50453010'),
	'identifierSchemeCodeUnspsc190501.50453011': __('identifierSchemeCodeUnspsc190501.50453011'),
	'identifierSchemeCodeUnspsc190501.50453012': __('identifierSchemeCodeUnspsc190501.50453012'),
	'identifierSchemeCodeUnspsc190501.50453013': __('identifierSchemeCodeUnspsc190501.50453013'),
	'identifierSchemeCodeUnspsc190501.50453014': __('identifierSchemeCodeUnspsc190501.50453014'),
	'identifierSchemeCodeUnspsc190501.50453015': __('identifierSchemeCodeUnspsc190501.50453015'),
	'identifierSchemeCodeUnspsc190501.50453016': __('identifierSchemeCodeUnspsc190501.50453016'),
	'identifierSchemeCodeUnspsc190501.50453100': __('identifierSchemeCodeUnspsc190501.50453100'),
	'identifierSchemeCodeUnspsc190501.50453101': __('identifierSchemeCodeUnspsc190501.50453101'),
	'identifierSchemeCodeUnspsc190501.50453102': __('identifierSchemeCodeUnspsc190501.50453102'),
	'identifierSchemeCodeUnspsc190501.50453200': __('identifierSchemeCodeUnspsc190501.50453200'),
	'identifierSchemeCodeUnspsc190501.50453201': __('identifierSchemeCodeUnspsc190501.50453201'),
	'identifierSchemeCodeUnspsc190501.50453202': __('identifierSchemeCodeUnspsc190501.50453202'),
	'identifierSchemeCodeUnspsc190501.50453203': __('identifierSchemeCodeUnspsc190501.50453203'),
	'identifierSchemeCodeUnspsc190501.50453204': __('identifierSchemeCodeUnspsc190501.50453204'),
	'identifierSchemeCodeUnspsc190501.50453205': __('identifierSchemeCodeUnspsc190501.50453205'),
	'identifierSchemeCodeUnspsc190501.50453206': __('identifierSchemeCodeUnspsc190501.50453206'),
	'identifierSchemeCodeUnspsc190501.50453207': __('identifierSchemeCodeUnspsc190501.50453207'),
	'identifierSchemeCodeUnspsc190501.50453208': __('identifierSchemeCodeUnspsc190501.50453208'),
	'identifierSchemeCodeUnspsc190501.50453209': __('identifierSchemeCodeUnspsc190501.50453209'),
	'identifierSchemeCodeUnspsc190501.50453210': __('identifierSchemeCodeUnspsc190501.50453210'),
	'identifierSchemeCodeUnspsc190501.50453211': __('identifierSchemeCodeUnspsc190501.50453211'),
	'identifierSchemeCodeUnspsc190501.50453212': __('identifierSchemeCodeUnspsc190501.50453212'),
	'identifierSchemeCodeUnspsc190501.50453213': __('identifierSchemeCodeUnspsc190501.50453213'),
	'identifierSchemeCodeUnspsc190501.50453214': __('identifierSchemeCodeUnspsc190501.50453214'),
	'identifierSchemeCodeUnspsc190501.50453215': __('identifierSchemeCodeUnspsc190501.50453215'),
	'identifierSchemeCodeUnspsc190501.50453216': __('identifierSchemeCodeUnspsc190501.50453216'),
	'identifierSchemeCodeUnspsc190501.50453217': __('identifierSchemeCodeUnspsc190501.50453217'),
	'identifierSchemeCodeUnspsc190501.50453218': __('identifierSchemeCodeUnspsc190501.50453218'),
	'identifierSchemeCodeUnspsc190501.50453219': __('identifierSchemeCodeUnspsc190501.50453219'),
	'identifierSchemeCodeUnspsc190501.50453220': __('identifierSchemeCodeUnspsc190501.50453220'),
	'identifierSchemeCodeUnspsc190501.50453221': __('identifierSchemeCodeUnspsc190501.50453221'),
	'identifierSchemeCodeUnspsc190501.50453222': __('identifierSchemeCodeUnspsc190501.50453222'),
	'identifierSchemeCodeUnspsc190501.50453223': __('identifierSchemeCodeUnspsc190501.50453223'),
	'identifierSchemeCodeUnspsc190501.50453224': __('identifierSchemeCodeUnspsc190501.50453224'),
	'identifierSchemeCodeUnspsc190501.50453225': __('identifierSchemeCodeUnspsc190501.50453225'),
	'identifierSchemeCodeUnspsc190501.50453226': __('identifierSchemeCodeUnspsc190501.50453226'),
	'identifierSchemeCodeUnspsc190501.50453227': __('identifierSchemeCodeUnspsc190501.50453227'),
	'identifierSchemeCodeUnspsc190501.50453228': __('identifierSchemeCodeUnspsc190501.50453228'),
	'identifierSchemeCodeUnspsc190501.50453229': __('identifierSchemeCodeUnspsc190501.50453229'),
	'identifierSchemeCodeUnspsc190501.50453230': __('identifierSchemeCodeUnspsc190501.50453230'),
	'identifierSchemeCodeUnspsc190501.50453231': __('identifierSchemeCodeUnspsc190501.50453231'),
	'identifierSchemeCodeUnspsc190501.50453232': __('identifierSchemeCodeUnspsc190501.50453232'),
	'identifierSchemeCodeUnspsc190501.50453233': __('identifierSchemeCodeUnspsc190501.50453233'),
	'identifierSchemeCodeUnspsc190501.50453234': __('identifierSchemeCodeUnspsc190501.50453234'),
	'identifierSchemeCodeUnspsc190501.50453235': __('identifierSchemeCodeUnspsc190501.50453235'),
	'identifierSchemeCodeUnspsc190501.50453236': __('identifierSchemeCodeUnspsc190501.50453236'),
	'identifierSchemeCodeUnspsc190501.50453237': __('identifierSchemeCodeUnspsc190501.50453237'),
	'identifierSchemeCodeUnspsc190501.50453238': __('identifierSchemeCodeUnspsc190501.50453238'),
	'identifierSchemeCodeUnspsc190501.50453239': __('identifierSchemeCodeUnspsc190501.50453239'),
	'identifierSchemeCodeUnspsc190501.50453240': __('identifierSchemeCodeUnspsc190501.50453240'),
	'identifierSchemeCodeUnspsc190501.50453241': __('identifierSchemeCodeUnspsc190501.50453241'),
	'identifierSchemeCodeUnspsc190501.50453242': __('identifierSchemeCodeUnspsc190501.50453242'),
	'identifierSchemeCodeUnspsc190501.50453243': __('identifierSchemeCodeUnspsc190501.50453243'),
	'identifierSchemeCodeUnspsc190501.50453244': __('identifierSchemeCodeUnspsc190501.50453244'),
	'identifierSchemeCodeUnspsc190501.50453245': __('identifierSchemeCodeUnspsc190501.50453245'),
	'identifierSchemeCodeUnspsc190501.50453246': __('identifierSchemeCodeUnspsc190501.50453246'),
	'identifierSchemeCodeUnspsc190501.50453247': __('identifierSchemeCodeUnspsc190501.50453247'),
	'identifierSchemeCodeUnspsc190501.50453248': __('identifierSchemeCodeUnspsc190501.50453248'),
	'identifierSchemeCodeUnspsc190501.50453249': __('identifierSchemeCodeUnspsc190501.50453249'),
	'identifierSchemeCodeUnspsc190501.50453250': __('identifierSchemeCodeUnspsc190501.50453250'),
	'identifierSchemeCodeUnspsc190501.50453251': __('identifierSchemeCodeUnspsc190501.50453251'),
	'identifierSchemeCodeUnspsc190501.50453252': __('identifierSchemeCodeUnspsc190501.50453252'),
	'identifierSchemeCodeUnspsc190501.50453253': __('identifierSchemeCodeUnspsc190501.50453253'),
	'identifierSchemeCodeUnspsc190501.50453254': __('identifierSchemeCodeUnspsc190501.50453254'),
	'identifierSchemeCodeUnspsc190501.50453255': __('identifierSchemeCodeUnspsc190501.50453255'),
	'identifierSchemeCodeUnspsc190501.50453256': __('identifierSchemeCodeUnspsc190501.50453256'),
	'identifierSchemeCodeUnspsc190501.50453257': __('identifierSchemeCodeUnspsc190501.50453257'),
	'identifierSchemeCodeUnspsc190501.50453258': __('identifierSchemeCodeUnspsc190501.50453258'),
	'identifierSchemeCodeUnspsc190501.50453259': __('identifierSchemeCodeUnspsc190501.50453259'),
	'identifierSchemeCodeUnspsc190501.50453260': __('identifierSchemeCodeUnspsc190501.50453260'),
	'identifierSchemeCodeUnspsc190501.50453261': __('identifierSchemeCodeUnspsc190501.50453261'),
	'identifierSchemeCodeUnspsc190501.50453262': __('identifierSchemeCodeUnspsc190501.50453262'),
	'identifierSchemeCodeUnspsc190501.50453263': __('identifierSchemeCodeUnspsc190501.50453263'),
	'identifierSchemeCodeUnspsc190501.50453264': __('identifierSchemeCodeUnspsc190501.50453264'),
	'identifierSchemeCodeUnspsc190501.50453265': __('identifierSchemeCodeUnspsc190501.50453265'),
	'identifierSchemeCodeUnspsc190501.50453266': __('identifierSchemeCodeUnspsc190501.50453266'),
	'identifierSchemeCodeUnspsc190501.50453267': __('identifierSchemeCodeUnspsc190501.50453267'),
	'identifierSchemeCodeUnspsc190501.50453268': __('identifierSchemeCodeUnspsc190501.50453268'),
	'identifierSchemeCodeUnspsc190501.50453269': __('identifierSchemeCodeUnspsc190501.50453269'),
	'identifierSchemeCodeUnspsc190501.50453300': __('identifierSchemeCodeUnspsc190501.50453300'),
	'identifierSchemeCodeUnspsc190501.50453301': __('identifierSchemeCodeUnspsc190501.50453301'),
	'identifierSchemeCodeUnspsc190501.50453302': __('identifierSchemeCodeUnspsc190501.50453302'),
	'identifierSchemeCodeUnspsc190501.50453303': __('identifierSchemeCodeUnspsc190501.50453303'),
	'identifierSchemeCodeUnspsc190501.50453304': __('identifierSchemeCodeUnspsc190501.50453304'),
	'identifierSchemeCodeUnspsc190501.50453400': __('identifierSchemeCodeUnspsc190501.50453400'),
	'identifierSchemeCodeUnspsc190501.50453401': __('identifierSchemeCodeUnspsc190501.50453401'),
	'identifierSchemeCodeUnspsc190501.50453402': __('identifierSchemeCodeUnspsc190501.50453402'),
	'identifierSchemeCodeUnspsc190501.50453403': __('identifierSchemeCodeUnspsc190501.50453403'),
	'identifierSchemeCodeUnspsc190501.50453404': __('identifierSchemeCodeUnspsc190501.50453404'),
	'identifierSchemeCodeUnspsc190501.50453405': __('identifierSchemeCodeUnspsc190501.50453405'),
	'identifierSchemeCodeUnspsc190501.50453406': __('identifierSchemeCodeUnspsc190501.50453406'),
	'identifierSchemeCodeUnspsc190501.50453407': __('identifierSchemeCodeUnspsc190501.50453407'),
	'identifierSchemeCodeUnspsc190501.50453408': __('identifierSchemeCodeUnspsc190501.50453408'),
	'identifierSchemeCodeUnspsc190501.50453409': __('identifierSchemeCodeUnspsc190501.50453409'),
	'identifierSchemeCodeUnspsc190501.50453410': __('identifierSchemeCodeUnspsc190501.50453410'),
	'identifierSchemeCodeUnspsc190501.50453411': __('identifierSchemeCodeUnspsc190501.50453411'),
	'identifierSchemeCodeUnspsc190501.50453412': __('identifierSchemeCodeUnspsc190501.50453412'),
	'identifierSchemeCodeUnspsc190501.50453413': __('identifierSchemeCodeUnspsc190501.50453413'),
	'identifierSchemeCodeUnspsc190501.50453414': __('identifierSchemeCodeUnspsc190501.50453414'),
	'identifierSchemeCodeUnspsc190501.50453415': __('identifierSchemeCodeUnspsc190501.50453415'),
	'identifierSchemeCodeUnspsc190501.50453416': __('identifierSchemeCodeUnspsc190501.50453416'),
	'identifierSchemeCodeUnspsc190501.50453417': __('identifierSchemeCodeUnspsc190501.50453417'),
	'identifierSchemeCodeUnspsc190501.50453418': __('identifierSchemeCodeUnspsc190501.50453418'),
	'identifierSchemeCodeUnspsc190501.50453419': __('identifierSchemeCodeUnspsc190501.50453419'),
	'identifierSchemeCodeUnspsc190501.50453420': __('identifierSchemeCodeUnspsc190501.50453420'),
	'identifierSchemeCodeUnspsc190501.50453421': __('identifierSchemeCodeUnspsc190501.50453421'),
	'identifierSchemeCodeUnspsc190501.50453422': __('identifierSchemeCodeUnspsc190501.50453422'),
	'identifierSchemeCodeUnspsc190501.50453423': __('identifierSchemeCodeUnspsc190501.50453423'),
	'identifierSchemeCodeUnspsc190501.50453500': __('identifierSchemeCodeUnspsc190501.50453500'),
	'identifierSchemeCodeUnspsc190501.50453501': __('identifierSchemeCodeUnspsc190501.50453501'),
	'identifierSchemeCodeUnspsc190501.50453502': __('identifierSchemeCodeUnspsc190501.50453502'),
	'identifierSchemeCodeUnspsc190501.50453503': __('identifierSchemeCodeUnspsc190501.50453503'),
	'identifierSchemeCodeUnspsc190501.50453504': __('identifierSchemeCodeUnspsc190501.50453504'),
	'identifierSchemeCodeUnspsc190501.50453505': __('identifierSchemeCodeUnspsc190501.50453505'),
	'identifierSchemeCodeUnspsc190501.50453506': __('identifierSchemeCodeUnspsc190501.50453506'),
	'identifierSchemeCodeUnspsc190501.50453507': __('identifierSchemeCodeUnspsc190501.50453507'),
	'identifierSchemeCodeUnspsc190501.50453508': __('identifierSchemeCodeUnspsc190501.50453508'),
	'identifierSchemeCodeUnspsc190501.50453509': __('identifierSchemeCodeUnspsc190501.50453509'),
	'identifierSchemeCodeUnspsc190501.50453510': __('identifierSchemeCodeUnspsc190501.50453510'),
	'identifierSchemeCodeUnspsc190501.50453511': __('identifierSchemeCodeUnspsc190501.50453511'),
	'identifierSchemeCodeUnspsc190501.50453512': __('identifierSchemeCodeUnspsc190501.50453512'),
	'identifierSchemeCodeUnspsc190501.50453513': __('identifierSchemeCodeUnspsc190501.50453513'),
	'identifierSchemeCodeUnspsc190501.50453514': __('identifierSchemeCodeUnspsc190501.50453514'),
	'identifierSchemeCodeUnspsc190501.50453515': __('identifierSchemeCodeUnspsc190501.50453515'),
	'identifierSchemeCodeUnspsc190501.50453516': __('identifierSchemeCodeUnspsc190501.50453516'),
	'identifierSchemeCodeUnspsc190501.50453517': __('identifierSchemeCodeUnspsc190501.50453517'),
	'identifierSchemeCodeUnspsc190501.50453518': __('identifierSchemeCodeUnspsc190501.50453518'),
	'identifierSchemeCodeUnspsc190501.50453519': __('identifierSchemeCodeUnspsc190501.50453519'),
	'identifierSchemeCodeUnspsc190501.50453520': __('identifierSchemeCodeUnspsc190501.50453520'),
	'identifierSchemeCodeUnspsc190501.50453600': __('identifierSchemeCodeUnspsc190501.50453600'),
	'identifierSchemeCodeUnspsc190501.50453601': __('identifierSchemeCodeUnspsc190501.50453601'),
	'identifierSchemeCodeUnspsc190501.50453602': __('identifierSchemeCodeUnspsc190501.50453602'),
	'identifierSchemeCodeUnspsc190501.50453603': __('identifierSchemeCodeUnspsc190501.50453603'),
	'identifierSchemeCodeUnspsc190501.50453604': __('identifierSchemeCodeUnspsc190501.50453604'),
	'identifierSchemeCodeUnspsc190501.50453605': __('identifierSchemeCodeUnspsc190501.50453605'),
	'identifierSchemeCodeUnspsc190501.50453606': __('identifierSchemeCodeUnspsc190501.50453606'),
	'identifierSchemeCodeUnspsc190501.50453607': __('identifierSchemeCodeUnspsc190501.50453607'),
	'identifierSchemeCodeUnspsc190501.50453608': __('identifierSchemeCodeUnspsc190501.50453608'),
	'identifierSchemeCodeUnspsc190501.50453609': __('identifierSchemeCodeUnspsc190501.50453609'),
	'identifierSchemeCodeUnspsc190501.50453610': __('identifierSchemeCodeUnspsc190501.50453610'),
	'identifierSchemeCodeUnspsc190501.50453611': __('identifierSchemeCodeUnspsc190501.50453611'),
	'identifierSchemeCodeUnspsc190501.50453612': __('identifierSchemeCodeUnspsc190501.50453612'),
	'identifierSchemeCodeUnspsc190501.50453613': __('identifierSchemeCodeUnspsc190501.50453613'),
	'identifierSchemeCodeUnspsc190501.50453614': __('identifierSchemeCodeUnspsc190501.50453614'),
	'identifierSchemeCodeUnspsc190501.50453700': __('identifierSchemeCodeUnspsc190501.50453700'),
	'identifierSchemeCodeUnspsc190501.50453701': __('identifierSchemeCodeUnspsc190501.50453701'),
	'identifierSchemeCodeUnspsc190501.50453702': __('identifierSchemeCodeUnspsc190501.50453702'),
	'identifierSchemeCodeUnspsc190501.50453703': __('identifierSchemeCodeUnspsc190501.50453703'),
	'identifierSchemeCodeUnspsc190501.50453704': __('identifierSchemeCodeUnspsc190501.50453704'),
	'identifierSchemeCodeUnspsc190501.50453705': __('identifierSchemeCodeUnspsc190501.50453705'),
	'identifierSchemeCodeUnspsc190501.50453706': __('identifierSchemeCodeUnspsc190501.50453706'),
	'identifierSchemeCodeUnspsc190501.50453707': __('identifierSchemeCodeUnspsc190501.50453707'),
	'identifierSchemeCodeUnspsc190501.50453800': __('identifierSchemeCodeUnspsc190501.50453800'),
	'identifierSchemeCodeUnspsc190501.50453801': __('identifierSchemeCodeUnspsc190501.50453801'),
	'identifierSchemeCodeUnspsc190501.50453802': __('identifierSchemeCodeUnspsc190501.50453802'),
	'identifierSchemeCodeUnspsc190501.50453803': __('identifierSchemeCodeUnspsc190501.50453803'),
	'identifierSchemeCodeUnspsc190501.50453804': __('identifierSchemeCodeUnspsc190501.50453804'),
	'identifierSchemeCodeUnspsc190501.50453805': __('identifierSchemeCodeUnspsc190501.50453805'),
	'identifierSchemeCodeUnspsc190501.50453806': __('identifierSchemeCodeUnspsc190501.50453806'),
	'identifierSchemeCodeUnspsc190501.50453807': __('identifierSchemeCodeUnspsc190501.50453807'),
	'identifierSchemeCodeUnspsc190501.50453808': __('identifierSchemeCodeUnspsc190501.50453808'),
	'identifierSchemeCodeUnspsc190501.50453809': __('identifierSchemeCodeUnspsc190501.50453809'),
	'identifierSchemeCodeUnspsc190501.50453810': __('identifierSchemeCodeUnspsc190501.50453810'),
	'identifierSchemeCodeUnspsc190501.50453811': __('identifierSchemeCodeUnspsc190501.50453811'),
	'identifierSchemeCodeUnspsc190501.50453900': __('identifierSchemeCodeUnspsc190501.50453900'),
	'identifierSchemeCodeUnspsc190501.50453901': __('identifierSchemeCodeUnspsc190501.50453901'),
	'identifierSchemeCodeUnspsc190501.50453902': __('identifierSchemeCodeUnspsc190501.50453902'),
	'identifierSchemeCodeUnspsc190501.50453903': __('identifierSchemeCodeUnspsc190501.50453903'),
	'identifierSchemeCodeUnspsc190501.50453904': __('identifierSchemeCodeUnspsc190501.50453904'),
	'identifierSchemeCodeUnspsc190501.50453905': __('identifierSchemeCodeUnspsc190501.50453905'),
	'identifierSchemeCodeUnspsc190501.50453906': __('identifierSchemeCodeUnspsc190501.50453906'),
	'identifierSchemeCodeUnspsc190501.50453907': __('identifierSchemeCodeUnspsc190501.50453907'),
	'identifierSchemeCodeUnspsc190501.50453908': __('identifierSchemeCodeUnspsc190501.50453908'),
	'identifierSchemeCodeUnspsc190501.50453909': __('identifierSchemeCodeUnspsc190501.50453909'),
	'identifierSchemeCodeUnspsc190501.50453910': __('identifierSchemeCodeUnspsc190501.50453910'),
	'identifierSchemeCodeUnspsc190501.50453911': __('identifierSchemeCodeUnspsc190501.50453911'),
	'identifierSchemeCodeUnspsc190501.50454000': __('identifierSchemeCodeUnspsc190501.50454000'),
	'identifierSchemeCodeUnspsc190501.50454001': __('identifierSchemeCodeUnspsc190501.50454001'),
	'identifierSchemeCodeUnspsc190501.50454002': __('identifierSchemeCodeUnspsc190501.50454002'),
	'identifierSchemeCodeUnspsc190501.50454003': __('identifierSchemeCodeUnspsc190501.50454003'),
	'identifierSchemeCodeUnspsc190501.50454004': __('identifierSchemeCodeUnspsc190501.50454004'),
	'identifierSchemeCodeUnspsc190501.50454005': __('identifierSchemeCodeUnspsc190501.50454005'),
	'identifierSchemeCodeUnspsc190501.50454100': __('identifierSchemeCodeUnspsc190501.50454100'),
	'identifierSchemeCodeUnspsc190501.50454101': __('identifierSchemeCodeUnspsc190501.50454101'),
	'identifierSchemeCodeUnspsc190501.50454102': __('identifierSchemeCodeUnspsc190501.50454102'),
	'identifierSchemeCodeUnspsc190501.50454103': __('identifierSchemeCodeUnspsc190501.50454103'),
	'identifierSchemeCodeUnspsc190501.50454104': __('identifierSchemeCodeUnspsc190501.50454104'),
	'identifierSchemeCodeUnspsc190501.50454105': __('identifierSchemeCodeUnspsc190501.50454105'),
	'identifierSchemeCodeUnspsc190501.50454106': __('identifierSchemeCodeUnspsc190501.50454106'),
	'identifierSchemeCodeUnspsc190501.50454107': __('identifierSchemeCodeUnspsc190501.50454107'),
	'identifierSchemeCodeUnspsc190501.50454108': __('identifierSchemeCodeUnspsc190501.50454108'),
	'identifierSchemeCodeUnspsc190501.50454109': __('identifierSchemeCodeUnspsc190501.50454109'),
	'identifierSchemeCodeUnspsc190501.50454110': __('identifierSchemeCodeUnspsc190501.50454110'),
	'identifierSchemeCodeUnspsc190501.50454111': __('identifierSchemeCodeUnspsc190501.50454111'),
	'identifierSchemeCodeUnspsc190501.50454112': __('identifierSchemeCodeUnspsc190501.50454112'),
	'identifierSchemeCodeUnspsc190501.50454113': __('identifierSchemeCodeUnspsc190501.50454113'),
	'identifierSchemeCodeUnspsc190501.50454114': __('identifierSchemeCodeUnspsc190501.50454114'),
	'identifierSchemeCodeUnspsc190501.50454115': __('identifierSchemeCodeUnspsc190501.50454115'),
	'identifierSchemeCodeUnspsc190501.50454116': __('identifierSchemeCodeUnspsc190501.50454116'),
	'identifierSchemeCodeUnspsc190501.50454117': __('identifierSchemeCodeUnspsc190501.50454117'),
	'identifierSchemeCodeUnspsc190501.50454118': __('identifierSchemeCodeUnspsc190501.50454118'),
	'identifierSchemeCodeUnspsc190501.50454119': __('identifierSchemeCodeUnspsc190501.50454119'),
	'identifierSchemeCodeUnspsc190501.50454120': __('identifierSchemeCodeUnspsc190501.50454120'),
	'identifierSchemeCodeUnspsc190501.50454121': __('identifierSchemeCodeUnspsc190501.50454121'),
	'identifierSchemeCodeUnspsc190501.50454122': __('identifierSchemeCodeUnspsc190501.50454122'),
	'identifierSchemeCodeUnspsc190501.50454123': __('identifierSchemeCodeUnspsc190501.50454123'),
	'identifierSchemeCodeUnspsc190501.50454124': __('identifierSchemeCodeUnspsc190501.50454124'),
	'identifierSchemeCodeUnspsc190501.50454125': __('identifierSchemeCodeUnspsc190501.50454125'),
	'identifierSchemeCodeUnspsc190501.50454126': __('identifierSchemeCodeUnspsc190501.50454126'),
	'identifierSchemeCodeUnspsc190501.50454127': __('identifierSchemeCodeUnspsc190501.50454127'),
	'identifierSchemeCodeUnspsc190501.50454200': __('identifierSchemeCodeUnspsc190501.50454200'),
	'identifierSchemeCodeUnspsc190501.50454201': __('identifierSchemeCodeUnspsc190501.50454201'),
	'identifierSchemeCodeUnspsc190501.50454202': __('identifierSchemeCodeUnspsc190501.50454202'),
	'identifierSchemeCodeUnspsc190501.50454300': __('identifierSchemeCodeUnspsc190501.50454300'),
	'identifierSchemeCodeUnspsc190501.50454301': __('identifierSchemeCodeUnspsc190501.50454301'),
	'identifierSchemeCodeUnspsc190501.50454302': __('identifierSchemeCodeUnspsc190501.50454302'),
	'identifierSchemeCodeUnspsc190501.50454303': __('identifierSchemeCodeUnspsc190501.50454303'),
	'identifierSchemeCodeUnspsc190501.50454304': __('identifierSchemeCodeUnspsc190501.50454304'),
	'identifierSchemeCodeUnspsc190501.50454305': __('identifierSchemeCodeUnspsc190501.50454305'),
	'identifierSchemeCodeUnspsc190501.50454306': __('identifierSchemeCodeUnspsc190501.50454306'),
	'identifierSchemeCodeUnspsc190501.50454400': __('identifierSchemeCodeUnspsc190501.50454400'),
	'identifierSchemeCodeUnspsc190501.50454401': __('identifierSchemeCodeUnspsc190501.50454401'),
	'identifierSchemeCodeUnspsc190501.50454402': __('identifierSchemeCodeUnspsc190501.50454402'),
	'identifierSchemeCodeUnspsc190501.50454403': __('identifierSchemeCodeUnspsc190501.50454403'),
	'identifierSchemeCodeUnspsc190501.50454404': __('identifierSchemeCodeUnspsc190501.50454404'),
	'identifierSchemeCodeUnspsc190501.50454405': __('identifierSchemeCodeUnspsc190501.50454405'),
	'identifierSchemeCodeUnspsc190501.50454406': __('identifierSchemeCodeUnspsc190501.50454406'),
	'identifierSchemeCodeUnspsc190501.50454500': __('identifierSchemeCodeUnspsc190501.50454500'),
	'identifierSchemeCodeUnspsc190501.50454501': __('identifierSchemeCodeUnspsc190501.50454501'),
	'identifierSchemeCodeUnspsc190501.50454502': __('identifierSchemeCodeUnspsc190501.50454502'),
	'identifierSchemeCodeUnspsc190501.50454503': __('identifierSchemeCodeUnspsc190501.50454503'),
	'identifierSchemeCodeUnspsc190501.50454504': __('identifierSchemeCodeUnspsc190501.50454504'),
	'identifierSchemeCodeUnspsc190501.50454505': __('identifierSchemeCodeUnspsc190501.50454505'),
	'identifierSchemeCodeUnspsc190501.50454506': __('identifierSchemeCodeUnspsc190501.50454506'),
	'identifierSchemeCodeUnspsc190501.50454507': __('identifierSchemeCodeUnspsc190501.50454507'),
	'identifierSchemeCodeUnspsc190501.50454508': __('identifierSchemeCodeUnspsc190501.50454508'),
	'identifierSchemeCodeUnspsc190501.50454600': __('identifierSchemeCodeUnspsc190501.50454600'),
	'identifierSchemeCodeUnspsc190501.50454601': __('identifierSchemeCodeUnspsc190501.50454601'),
	'identifierSchemeCodeUnspsc190501.50454602': __('identifierSchemeCodeUnspsc190501.50454602'),
	'identifierSchemeCodeUnspsc190501.50454603': __('identifierSchemeCodeUnspsc190501.50454603'),
	'identifierSchemeCodeUnspsc190501.50454604': __('identifierSchemeCodeUnspsc190501.50454604'),
	'identifierSchemeCodeUnspsc190501.50454605': __('identifierSchemeCodeUnspsc190501.50454605'),
	'identifierSchemeCodeUnspsc190501.50454606': __('identifierSchemeCodeUnspsc190501.50454606'),
	'identifierSchemeCodeUnspsc190501.50454607': __('identifierSchemeCodeUnspsc190501.50454607'),
	'identifierSchemeCodeUnspsc190501.50454608': __('identifierSchemeCodeUnspsc190501.50454608'),
	'identifierSchemeCodeUnspsc190501.50454609': __('identifierSchemeCodeUnspsc190501.50454609'),
	'identifierSchemeCodeUnspsc190501.50454610': __('identifierSchemeCodeUnspsc190501.50454610'),
	'identifierSchemeCodeUnspsc190501.50454611': __('identifierSchemeCodeUnspsc190501.50454611'),
	'identifierSchemeCodeUnspsc190501.50454612': __('identifierSchemeCodeUnspsc190501.50454612'),
	'identifierSchemeCodeUnspsc190501.50454613': __('identifierSchemeCodeUnspsc190501.50454613'),
	'identifierSchemeCodeUnspsc190501.50454614': __('identifierSchemeCodeUnspsc190501.50454614'),
	'identifierSchemeCodeUnspsc190501.50454615': __('identifierSchemeCodeUnspsc190501.50454615'),
	'identifierSchemeCodeUnspsc190501.50454616': __('identifierSchemeCodeUnspsc190501.50454616'),
	'identifierSchemeCodeUnspsc190501.50454617': __('identifierSchemeCodeUnspsc190501.50454617'),
	'identifierSchemeCodeUnspsc190501.50454618': __('identifierSchemeCodeUnspsc190501.50454618'),
	'identifierSchemeCodeUnspsc190501.50454619': __('identifierSchemeCodeUnspsc190501.50454619'),
	'identifierSchemeCodeUnspsc190501.50454620': __('identifierSchemeCodeUnspsc190501.50454620'),
	'identifierSchemeCodeUnspsc190501.50454621': __('identifierSchemeCodeUnspsc190501.50454621'),
	'identifierSchemeCodeUnspsc190501.50454622': __('identifierSchemeCodeUnspsc190501.50454622'),
	'identifierSchemeCodeUnspsc190501.50454623': __('identifierSchemeCodeUnspsc190501.50454623'),
	'identifierSchemeCodeUnspsc190501.50454700': __('identifierSchemeCodeUnspsc190501.50454700'),
	'identifierSchemeCodeUnspsc190501.50454701': __('identifierSchemeCodeUnspsc190501.50454701'),
	'identifierSchemeCodeUnspsc190501.50454702': __('identifierSchemeCodeUnspsc190501.50454702'),
	'identifierSchemeCodeUnspsc190501.50454703': __('identifierSchemeCodeUnspsc190501.50454703'),
	'identifierSchemeCodeUnspsc190501.50454704': __('identifierSchemeCodeUnspsc190501.50454704'),
	'identifierSchemeCodeUnspsc190501.50454705': __('identifierSchemeCodeUnspsc190501.50454705'),
	'identifierSchemeCodeUnspsc190501.50454706': __('identifierSchemeCodeUnspsc190501.50454706'),
	'identifierSchemeCodeUnspsc190501.50454800': __('identifierSchemeCodeUnspsc190501.50454800'),
	'identifierSchemeCodeUnspsc190501.50454801': __('identifierSchemeCodeUnspsc190501.50454801'),
	'identifierSchemeCodeUnspsc190501.50454802': __('identifierSchemeCodeUnspsc190501.50454802'),
	'identifierSchemeCodeUnspsc190501.50454803': __('identifierSchemeCodeUnspsc190501.50454803'),
	'identifierSchemeCodeUnspsc190501.50454804': __('identifierSchemeCodeUnspsc190501.50454804'),
	'identifierSchemeCodeUnspsc190501.50454805': __('identifierSchemeCodeUnspsc190501.50454805'),
	'identifierSchemeCodeUnspsc190501.50454806': __('identifierSchemeCodeUnspsc190501.50454806'),
	'identifierSchemeCodeUnspsc190501.50454807': __('identifierSchemeCodeUnspsc190501.50454807'),
	'identifierSchemeCodeUnspsc190501.50454808': __('identifierSchemeCodeUnspsc190501.50454808'),
	'identifierSchemeCodeUnspsc190501.50454809': __('identifierSchemeCodeUnspsc190501.50454809'),
	'identifierSchemeCodeUnspsc190501.50454810': __('identifierSchemeCodeUnspsc190501.50454810'),
	'identifierSchemeCodeUnspsc190501.50454811': __('identifierSchemeCodeUnspsc190501.50454811'),
	'identifierSchemeCodeUnspsc190501.50454812': __('identifierSchemeCodeUnspsc190501.50454812'),
	'identifierSchemeCodeUnspsc190501.50454813': __('identifierSchemeCodeUnspsc190501.50454813'),
	'identifierSchemeCodeUnspsc190501.50454814': __('identifierSchemeCodeUnspsc190501.50454814'),
	'identifierSchemeCodeUnspsc190501.50454815': __('identifierSchemeCodeUnspsc190501.50454815'),
	'identifierSchemeCodeUnspsc190501.50454816': __('identifierSchemeCodeUnspsc190501.50454816'),
	'identifierSchemeCodeUnspsc190501.50454817': __('identifierSchemeCodeUnspsc190501.50454817'),
	'identifierSchemeCodeUnspsc190501.50454818': __('identifierSchemeCodeUnspsc190501.50454818'),
	'identifierSchemeCodeUnspsc190501.50454819': __('identifierSchemeCodeUnspsc190501.50454819'),
	'identifierSchemeCodeUnspsc190501.50454820': __('identifierSchemeCodeUnspsc190501.50454820'),
	'identifierSchemeCodeUnspsc190501.50454821': __('identifierSchemeCodeUnspsc190501.50454821'),
	'identifierSchemeCodeUnspsc190501.50454900': __('identifierSchemeCodeUnspsc190501.50454900'),
	'identifierSchemeCodeUnspsc190501.50454901': __('identifierSchemeCodeUnspsc190501.50454901'),
	'identifierSchemeCodeUnspsc190501.50454902': __('identifierSchemeCodeUnspsc190501.50454902'),
	'identifierSchemeCodeUnspsc190501.50454903': __('identifierSchemeCodeUnspsc190501.50454903'),
	'identifierSchemeCodeUnspsc190501.50454904': __('identifierSchemeCodeUnspsc190501.50454904'),
	'identifierSchemeCodeUnspsc190501.50454905': __('identifierSchemeCodeUnspsc190501.50454905'),
	'identifierSchemeCodeUnspsc190501.50454906': __('identifierSchemeCodeUnspsc190501.50454906'),
	'identifierSchemeCodeUnspsc190501.50455000': __('identifierSchemeCodeUnspsc190501.50455000'),
	'identifierSchemeCodeUnspsc190501.50455001': __('identifierSchemeCodeUnspsc190501.50455001'),
	'identifierSchemeCodeUnspsc190501.50455002': __('identifierSchemeCodeUnspsc190501.50455002'),
	'identifierSchemeCodeUnspsc190501.50455003': __('identifierSchemeCodeUnspsc190501.50455003'),
	'identifierSchemeCodeUnspsc190501.50455004': __('identifierSchemeCodeUnspsc190501.50455004'),
	'identifierSchemeCodeUnspsc190501.50455100': __('identifierSchemeCodeUnspsc190501.50455100'),
	'identifierSchemeCodeUnspsc190501.50455101': __('identifierSchemeCodeUnspsc190501.50455101'),
	'identifierSchemeCodeUnspsc190501.50455102': __('identifierSchemeCodeUnspsc190501.50455102'),
	'identifierSchemeCodeUnspsc190501.50455103': __('identifierSchemeCodeUnspsc190501.50455103'),
	'identifierSchemeCodeUnspsc190501.50455104': __('identifierSchemeCodeUnspsc190501.50455104'),
	'identifierSchemeCodeUnspsc190501.50455105': __('identifierSchemeCodeUnspsc190501.50455105'),
	'identifierSchemeCodeUnspsc190501.50455106': __('identifierSchemeCodeUnspsc190501.50455106'),
	'identifierSchemeCodeUnspsc190501.50455107': __('identifierSchemeCodeUnspsc190501.50455107'),
	'identifierSchemeCodeUnspsc190501.50455200': __('identifierSchemeCodeUnspsc190501.50455200'),
	'identifierSchemeCodeUnspsc190501.50455201': __('identifierSchemeCodeUnspsc190501.50455201'),
	'identifierSchemeCodeUnspsc190501.50455202': __('identifierSchemeCodeUnspsc190501.50455202'),
	'identifierSchemeCodeUnspsc190501.50455203': __('identifierSchemeCodeUnspsc190501.50455203'),
	'identifierSchemeCodeUnspsc190501.50455204': __('identifierSchemeCodeUnspsc190501.50455204'),
	'identifierSchemeCodeUnspsc190501.50455205': __('identifierSchemeCodeUnspsc190501.50455205'),
	'identifierSchemeCodeUnspsc190501.50455206': __('identifierSchemeCodeUnspsc190501.50455206'),
	'identifierSchemeCodeUnspsc190501.50455207': __('identifierSchemeCodeUnspsc190501.50455207'),
	'identifierSchemeCodeUnspsc190501.50455208': __('identifierSchemeCodeUnspsc190501.50455208'),
	'identifierSchemeCodeUnspsc190501.50455209': __('identifierSchemeCodeUnspsc190501.50455209'),
	'identifierSchemeCodeUnspsc190501.50455210': __('identifierSchemeCodeUnspsc190501.50455210'),
	'identifierSchemeCodeUnspsc190501.50455211': __('identifierSchemeCodeUnspsc190501.50455211'),
	'identifierSchemeCodeUnspsc190501.50455212': __('identifierSchemeCodeUnspsc190501.50455212'),
	'identifierSchemeCodeUnspsc190501.50455213': __('identifierSchemeCodeUnspsc190501.50455213'),
	'identifierSchemeCodeUnspsc190501.50455214': __('identifierSchemeCodeUnspsc190501.50455214'),
	'identifierSchemeCodeUnspsc190501.50455215': __('identifierSchemeCodeUnspsc190501.50455215'),
	'identifierSchemeCodeUnspsc190501.50455216': __('identifierSchemeCodeUnspsc190501.50455216'),
	'identifierSchemeCodeUnspsc190501.50455217': __('identifierSchemeCodeUnspsc190501.50455217'),
	'identifierSchemeCodeUnspsc190501.50455218': __('identifierSchemeCodeUnspsc190501.50455218'),
	'identifierSchemeCodeUnspsc190501.50455219': __('identifierSchemeCodeUnspsc190501.50455219'),
	'identifierSchemeCodeUnspsc190501.50455220': __('identifierSchemeCodeUnspsc190501.50455220'),
	'identifierSchemeCodeUnspsc190501.50455221': __('identifierSchemeCodeUnspsc190501.50455221'),
	'identifierSchemeCodeUnspsc190501.50455300': __('identifierSchemeCodeUnspsc190501.50455300'),
	'identifierSchemeCodeUnspsc190501.50455301': __('identifierSchemeCodeUnspsc190501.50455301'),
	'identifierSchemeCodeUnspsc190501.50455302': __('identifierSchemeCodeUnspsc190501.50455302'),
	'identifierSchemeCodeUnspsc190501.50455303': __('identifierSchemeCodeUnspsc190501.50455303'),
	'identifierSchemeCodeUnspsc190501.50455304': __('identifierSchemeCodeUnspsc190501.50455304'),
	'identifierSchemeCodeUnspsc190501.50455305': __('identifierSchemeCodeUnspsc190501.50455305'),
	'identifierSchemeCodeUnspsc190501.50455306': __('identifierSchemeCodeUnspsc190501.50455306'),
	'identifierSchemeCodeUnspsc190501.50455400': __('identifierSchemeCodeUnspsc190501.50455400'),
	'identifierSchemeCodeUnspsc190501.50455401': __('identifierSchemeCodeUnspsc190501.50455401'),
	'identifierSchemeCodeUnspsc190501.50455402': __('identifierSchemeCodeUnspsc190501.50455402'),
	'identifierSchemeCodeUnspsc190501.50455403': __('identifierSchemeCodeUnspsc190501.50455403'),
	'identifierSchemeCodeUnspsc190501.50455404': __('identifierSchemeCodeUnspsc190501.50455404'),
	'identifierSchemeCodeUnspsc190501.50455405': __('identifierSchemeCodeUnspsc190501.50455405'),
	'identifierSchemeCodeUnspsc190501.50455406': __('identifierSchemeCodeUnspsc190501.50455406'),
	'identifierSchemeCodeUnspsc190501.50455500': __('identifierSchemeCodeUnspsc190501.50455500'),
	'identifierSchemeCodeUnspsc190501.50455501': __('identifierSchemeCodeUnspsc190501.50455501'),
	'identifierSchemeCodeUnspsc190501.50455502': __('identifierSchemeCodeUnspsc190501.50455502'),
	'identifierSchemeCodeUnspsc190501.50455503': __('identifierSchemeCodeUnspsc190501.50455503'),
	'identifierSchemeCodeUnspsc190501.50455504': __('identifierSchemeCodeUnspsc190501.50455504'),
	'identifierSchemeCodeUnspsc190501.50455505': __('identifierSchemeCodeUnspsc190501.50455505'),
	'identifierSchemeCodeUnspsc190501.50455506': __('identifierSchemeCodeUnspsc190501.50455506'),
	'identifierSchemeCodeUnspsc190501.50455507': __('identifierSchemeCodeUnspsc190501.50455507'),
	'identifierSchemeCodeUnspsc190501.50455508': __('identifierSchemeCodeUnspsc190501.50455508'),
	'identifierSchemeCodeUnspsc190501.50455509': __('identifierSchemeCodeUnspsc190501.50455509'),
	'identifierSchemeCodeUnspsc190501.50455510': __('identifierSchemeCodeUnspsc190501.50455510'),
	'identifierSchemeCodeUnspsc190501.50455511': __('identifierSchemeCodeUnspsc190501.50455511'),
	'identifierSchemeCodeUnspsc190501.50455512': __('identifierSchemeCodeUnspsc190501.50455512'),
	'identifierSchemeCodeUnspsc190501.50455513': __('identifierSchemeCodeUnspsc190501.50455513'),
	'identifierSchemeCodeUnspsc190501.50455514': __('identifierSchemeCodeUnspsc190501.50455514'),
	'identifierSchemeCodeUnspsc190501.50455515': __('identifierSchemeCodeUnspsc190501.50455515'),
	'identifierSchemeCodeUnspsc190501.50455516': __('identifierSchemeCodeUnspsc190501.50455516'),
	'identifierSchemeCodeUnspsc190501.50455517': __('identifierSchemeCodeUnspsc190501.50455517'),
	'identifierSchemeCodeUnspsc190501.50455518': __('identifierSchemeCodeUnspsc190501.50455518'),
	'identifierSchemeCodeUnspsc190501.50455519': __('identifierSchemeCodeUnspsc190501.50455519'),
	'identifierSchemeCodeUnspsc190501.50455520': __('identifierSchemeCodeUnspsc190501.50455520'),
	'identifierSchemeCodeUnspsc190501.50455521': __('identifierSchemeCodeUnspsc190501.50455521'),
	'identifierSchemeCodeUnspsc190501.50455522': __('identifierSchemeCodeUnspsc190501.50455522'),
	'identifierSchemeCodeUnspsc190501.50455523': __('identifierSchemeCodeUnspsc190501.50455523'),
	'identifierSchemeCodeUnspsc190501.50455524': __('identifierSchemeCodeUnspsc190501.50455524'),
	'identifierSchemeCodeUnspsc190501.50455525': __('identifierSchemeCodeUnspsc190501.50455525'),
	'identifierSchemeCodeUnspsc190501.50455526': __('identifierSchemeCodeUnspsc190501.50455526'),
	'identifierSchemeCodeUnspsc190501.50455527': __('identifierSchemeCodeUnspsc190501.50455527'),
	'identifierSchemeCodeUnspsc190501.50455528': __('identifierSchemeCodeUnspsc190501.50455528'),
	'identifierSchemeCodeUnspsc190501.50455529': __('identifierSchemeCodeUnspsc190501.50455529'),
	'identifierSchemeCodeUnspsc190501.50455530': __('identifierSchemeCodeUnspsc190501.50455530'),
	'identifierSchemeCodeUnspsc190501.50455531': __('identifierSchemeCodeUnspsc190501.50455531'),
	'identifierSchemeCodeUnspsc190501.50455532': __('identifierSchemeCodeUnspsc190501.50455532'),
	'identifierSchemeCodeUnspsc190501.50455533': __('identifierSchemeCodeUnspsc190501.50455533'),
	'identifierSchemeCodeUnspsc190501.50455534': __('identifierSchemeCodeUnspsc190501.50455534'),
	'identifierSchemeCodeUnspsc190501.50455535': __('identifierSchemeCodeUnspsc190501.50455535'),
	'identifierSchemeCodeUnspsc190501.50455536': __('identifierSchemeCodeUnspsc190501.50455536'),
	'identifierSchemeCodeUnspsc190501.50455537': __('identifierSchemeCodeUnspsc190501.50455537'),
	'identifierSchemeCodeUnspsc190501.50455600': __('identifierSchemeCodeUnspsc190501.50455600'),
	'identifierSchemeCodeUnspsc190501.50455601': __('identifierSchemeCodeUnspsc190501.50455601'),
	'identifierSchemeCodeUnspsc190501.50455602': __('identifierSchemeCodeUnspsc190501.50455602'),
	'identifierSchemeCodeUnspsc190501.50455603': __('identifierSchemeCodeUnspsc190501.50455603'),
	'identifierSchemeCodeUnspsc190501.50455604': __('identifierSchemeCodeUnspsc190501.50455604'),
	'identifierSchemeCodeUnspsc190501.50455605': __('identifierSchemeCodeUnspsc190501.50455605'),
	'identifierSchemeCodeUnspsc190501.50455606': __('identifierSchemeCodeUnspsc190501.50455606'),
	'identifierSchemeCodeUnspsc190501.50455607': __('identifierSchemeCodeUnspsc190501.50455607'),
	'identifierSchemeCodeUnspsc190501.50455608': __('identifierSchemeCodeUnspsc190501.50455608'),
	'identifierSchemeCodeUnspsc190501.50455700': __('identifierSchemeCodeUnspsc190501.50455700'),
	'identifierSchemeCodeUnspsc190501.50455701': __('identifierSchemeCodeUnspsc190501.50455701'),
	'identifierSchemeCodeUnspsc190501.50455702': __('identifierSchemeCodeUnspsc190501.50455702'),
	'identifierSchemeCodeUnspsc190501.50455703': __('identifierSchemeCodeUnspsc190501.50455703'),
	'identifierSchemeCodeUnspsc190501.50455704': __('identifierSchemeCodeUnspsc190501.50455704'),
	'identifierSchemeCodeUnspsc190501.50455800': __('identifierSchemeCodeUnspsc190501.50455800'),
	'identifierSchemeCodeUnspsc190501.50455801': __('identifierSchemeCodeUnspsc190501.50455801'),
	'identifierSchemeCodeUnspsc190501.50455802': __('identifierSchemeCodeUnspsc190501.50455802'),
	'identifierSchemeCodeUnspsc190501.50455803': __('identifierSchemeCodeUnspsc190501.50455803'),
	'identifierSchemeCodeUnspsc190501.50455804': __('identifierSchemeCodeUnspsc190501.50455804'),
	'identifierSchemeCodeUnspsc190501.50455805': __('identifierSchemeCodeUnspsc190501.50455805'),
	'identifierSchemeCodeUnspsc190501.50455806': __('identifierSchemeCodeUnspsc190501.50455806'),
	'identifierSchemeCodeUnspsc190501.50455807': __('identifierSchemeCodeUnspsc190501.50455807'),
	'identifierSchemeCodeUnspsc190501.50455808': __('identifierSchemeCodeUnspsc190501.50455808'),
	'identifierSchemeCodeUnspsc190501.50455809': __('identifierSchemeCodeUnspsc190501.50455809'),
	'identifierSchemeCodeUnspsc190501.50455810': __('identifierSchemeCodeUnspsc190501.50455810'),
	'identifierSchemeCodeUnspsc190501.50455811': __('identifierSchemeCodeUnspsc190501.50455811'),
	'identifierSchemeCodeUnspsc190501.50455812': __('identifierSchemeCodeUnspsc190501.50455812'),
	'identifierSchemeCodeUnspsc190501.50455813': __('identifierSchemeCodeUnspsc190501.50455813'),
	'identifierSchemeCodeUnspsc190501.50455814': __('identifierSchemeCodeUnspsc190501.50455814'),
	'identifierSchemeCodeUnspsc190501.50455815': __('identifierSchemeCodeUnspsc190501.50455815'),
	'identifierSchemeCodeUnspsc190501.50455816': __('identifierSchemeCodeUnspsc190501.50455816'),
	'identifierSchemeCodeUnspsc190501.50455900': __('identifierSchemeCodeUnspsc190501.50455900'),
	'identifierSchemeCodeUnspsc190501.50455901': __('identifierSchemeCodeUnspsc190501.50455901'),
	'identifierSchemeCodeUnspsc190501.50455902': __('identifierSchemeCodeUnspsc190501.50455902'),
	'identifierSchemeCodeUnspsc190501.50455903': __('identifierSchemeCodeUnspsc190501.50455903'),
	'identifierSchemeCodeUnspsc190501.50455904': __('identifierSchemeCodeUnspsc190501.50455904'),
	'identifierSchemeCodeUnspsc190501.50455905': __('identifierSchemeCodeUnspsc190501.50455905'),
	'identifierSchemeCodeUnspsc190501.50455906': __('identifierSchemeCodeUnspsc190501.50455906'),
	'identifierSchemeCodeUnspsc190501.50455907': __('identifierSchemeCodeUnspsc190501.50455907'),
	'identifierSchemeCodeUnspsc190501.50455908': __('identifierSchemeCodeUnspsc190501.50455908'),
	'identifierSchemeCodeUnspsc190501.50455909': __('identifierSchemeCodeUnspsc190501.50455909'),
	'identifierSchemeCodeUnspsc190501.50455910': __('identifierSchemeCodeUnspsc190501.50455910'),
	'identifierSchemeCodeUnspsc190501.50455911': __('identifierSchemeCodeUnspsc190501.50455911'),
	'identifierSchemeCodeUnspsc190501.50456000': __('identifierSchemeCodeUnspsc190501.50456000'),
	'identifierSchemeCodeUnspsc190501.50456001': __('identifierSchemeCodeUnspsc190501.50456001'),
	'identifierSchemeCodeUnspsc190501.50456002': __('identifierSchemeCodeUnspsc190501.50456002'),
	'identifierSchemeCodeUnspsc190501.50456003': __('identifierSchemeCodeUnspsc190501.50456003'),
	'identifierSchemeCodeUnspsc190501.50456004': __('identifierSchemeCodeUnspsc190501.50456004'),
	'identifierSchemeCodeUnspsc190501.50456100': __('identifierSchemeCodeUnspsc190501.50456100'),
	'identifierSchemeCodeUnspsc190501.50456101': __('identifierSchemeCodeUnspsc190501.50456101'),
	'identifierSchemeCodeUnspsc190501.50456102': __('identifierSchemeCodeUnspsc190501.50456102'),
	'identifierSchemeCodeUnspsc190501.50456103': __('identifierSchemeCodeUnspsc190501.50456103'),
	'identifierSchemeCodeUnspsc190501.50456104': __('identifierSchemeCodeUnspsc190501.50456104'),
	'identifierSchemeCodeUnspsc190501.50456105': __('identifierSchemeCodeUnspsc190501.50456105'),
	'identifierSchemeCodeUnspsc190501.50456106': __('identifierSchemeCodeUnspsc190501.50456106'),
	'identifierSchemeCodeUnspsc190501.50456107': __('identifierSchemeCodeUnspsc190501.50456107'),
	'identifierSchemeCodeUnspsc190501.50456108': __('identifierSchemeCodeUnspsc190501.50456108'),
	'identifierSchemeCodeUnspsc190501.50456109': __('identifierSchemeCodeUnspsc190501.50456109'),
	'identifierSchemeCodeUnspsc190501.50456110': __('identifierSchemeCodeUnspsc190501.50456110'),
	'identifierSchemeCodeUnspsc190501.50456111': __('identifierSchemeCodeUnspsc190501.50456111'),
	'identifierSchemeCodeUnspsc190501.50456112': __('identifierSchemeCodeUnspsc190501.50456112'),
	'identifierSchemeCodeUnspsc190501.50456113': __('identifierSchemeCodeUnspsc190501.50456113'),
	'identifierSchemeCodeUnspsc190501.50456114': __('identifierSchemeCodeUnspsc190501.50456114'),
	'identifierSchemeCodeUnspsc190501.50456115': __('identifierSchemeCodeUnspsc190501.50456115'),
	'identifierSchemeCodeUnspsc190501.50456116': __('identifierSchemeCodeUnspsc190501.50456116'),
	'identifierSchemeCodeUnspsc190501.50456200': __('identifierSchemeCodeUnspsc190501.50456200'),
	'identifierSchemeCodeUnspsc190501.50456201': __('identifierSchemeCodeUnspsc190501.50456201'),
	'identifierSchemeCodeUnspsc190501.50456202': __('identifierSchemeCodeUnspsc190501.50456202'),
	'identifierSchemeCodeUnspsc190501.50456203': __('identifierSchemeCodeUnspsc190501.50456203'),
	'identifierSchemeCodeUnspsc190501.50456204': __('identifierSchemeCodeUnspsc190501.50456204'),
	'identifierSchemeCodeUnspsc190501.50456205': __('identifierSchemeCodeUnspsc190501.50456205'),
	'identifierSchemeCodeUnspsc190501.50456206': __('identifierSchemeCodeUnspsc190501.50456206'),
	'identifierSchemeCodeUnspsc190501.50456207': __('identifierSchemeCodeUnspsc190501.50456207'),
	'identifierSchemeCodeUnspsc190501.50456208': __('identifierSchemeCodeUnspsc190501.50456208'),
	'identifierSchemeCodeUnspsc190501.50456209': __('identifierSchemeCodeUnspsc190501.50456209'),
	'identifierSchemeCodeUnspsc190501.50456210': __('identifierSchemeCodeUnspsc190501.50456210'),
	'identifierSchemeCodeUnspsc190501.50456211': __('identifierSchemeCodeUnspsc190501.50456211'),
	'identifierSchemeCodeUnspsc190501.50456212': __('identifierSchemeCodeUnspsc190501.50456212'),
	'identifierSchemeCodeUnspsc190501.50456213': __('identifierSchemeCodeUnspsc190501.50456213'),
	'identifierSchemeCodeUnspsc190501.50456214': __('identifierSchemeCodeUnspsc190501.50456214'),
	'identifierSchemeCodeUnspsc190501.50456215': __('identifierSchemeCodeUnspsc190501.50456215'),
	'identifierSchemeCodeUnspsc190501.50456216': __('identifierSchemeCodeUnspsc190501.50456216'),
	'identifierSchemeCodeUnspsc190501.50456217': __('identifierSchemeCodeUnspsc190501.50456217'),
	'identifierSchemeCodeUnspsc190501.50456218': __('identifierSchemeCodeUnspsc190501.50456218'),
	'identifierSchemeCodeUnspsc190501.50456219': __('identifierSchemeCodeUnspsc190501.50456219'),
	'identifierSchemeCodeUnspsc190501.50456220': __('identifierSchemeCodeUnspsc190501.50456220'),
	'identifierSchemeCodeUnspsc190501.50456221': __('identifierSchemeCodeUnspsc190501.50456221'),
	'identifierSchemeCodeUnspsc190501.50456222': __('identifierSchemeCodeUnspsc190501.50456222'),
	'identifierSchemeCodeUnspsc190501.50456223': __('identifierSchemeCodeUnspsc190501.50456223'),
	'identifierSchemeCodeUnspsc190501.50456224': __('identifierSchemeCodeUnspsc190501.50456224'),
	'identifierSchemeCodeUnspsc190501.50456225': __('identifierSchemeCodeUnspsc190501.50456225'),
	'identifierSchemeCodeUnspsc190501.50456226': __('identifierSchemeCodeUnspsc190501.50456226'),
	'identifierSchemeCodeUnspsc190501.50456227': __('identifierSchemeCodeUnspsc190501.50456227'),
	'identifierSchemeCodeUnspsc190501.50456228': __('identifierSchemeCodeUnspsc190501.50456228'),
	'identifierSchemeCodeUnspsc190501.50456229': __('identifierSchemeCodeUnspsc190501.50456229'),
	'identifierSchemeCodeUnspsc190501.50456300': __('identifierSchemeCodeUnspsc190501.50456300'),
	'identifierSchemeCodeUnspsc190501.50456301': __('identifierSchemeCodeUnspsc190501.50456301'),
	'identifierSchemeCodeUnspsc190501.50456302': __('identifierSchemeCodeUnspsc190501.50456302'),
	'identifierSchemeCodeUnspsc190501.50456303': __('identifierSchemeCodeUnspsc190501.50456303'),
	'identifierSchemeCodeUnspsc190501.50456304': __('identifierSchemeCodeUnspsc190501.50456304'),
	'identifierSchemeCodeUnspsc190501.50456305': __('identifierSchemeCodeUnspsc190501.50456305'),
	'identifierSchemeCodeUnspsc190501.50456306': __('identifierSchemeCodeUnspsc190501.50456306'),
	'identifierSchemeCodeUnspsc190501.50456307': __('identifierSchemeCodeUnspsc190501.50456307'),
	'identifierSchemeCodeUnspsc190501.50456308': __('identifierSchemeCodeUnspsc190501.50456308'),
	'identifierSchemeCodeUnspsc190501.50456309': __('identifierSchemeCodeUnspsc190501.50456309'),
	'identifierSchemeCodeUnspsc190501.50456310': __('identifierSchemeCodeUnspsc190501.50456310'),
	'identifierSchemeCodeUnspsc190501.50456311': __('identifierSchemeCodeUnspsc190501.50456311'),
	'identifierSchemeCodeUnspsc190501.50456312': __('identifierSchemeCodeUnspsc190501.50456312'),
	'identifierSchemeCodeUnspsc190501.50456313': __('identifierSchemeCodeUnspsc190501.50456313'),
	'identifierSchemeCodeUnspsc190501.50456314': __('identifierSchemeCodeUnspsc190501.50456314'),
	'identifierSchemeCodeUnspsc190501.50456315': __('identifierSchemeCodeUnspsc190501.50456315'),
	'identifierSchemeCodeUnspsc190501.50456316': __('identifierSchemeCodeUnspsc190501.50456316'),
	'identifierSchemeCodeUnspsc190501.50456317': __('identifierSchemeCodeUnspsc190501.50456317'),
	'identifierSchemeCodeUnspsc190501.50456318': __('identifierSchemeCodeUnspsc190501.50456318'),
	'identifierSchemeCodeUnspsc190501.50456319': __('identifierSchemeCodeUnspsc190501.50456319'),
	'identifierSchemeCodeUnspsc190501.50456320': __('identifierSchemeCodeUnspsc190501.50456320'),
	'identifierSchemeCodeUnspsc190501.50456400': __('identifierSchemeCodeUnspsc190501.50456400'),
	'identifierSchemeCodeUnspsc190501.50456401': __('identifierSchemeCodeUnspsc190501.50456401'),
	'identifierSchemeCodeUnspsc190501.50456402': __('identifierSchemeCodeUnspsc190501.50456402'),
	'identifierSchemeCodeUnspsc190501.50456403': __('identifierSchemeCodeUnspsc190501.50456403'),
	'identifierSchemeCodeUnspsc190501.50456404': __('identifierSchemeCodeUnspsc190501.50456404'),
	'identifierSchemeCodeUnspsc190501.50456405': __('identifierSchemeCodeUnspsc190501.50456405'),
	'identifierSchemeCodeUnspsc190501.50456406': __('identifierSchemeCodeUnspsc190501.50456406'),
	'identifierSchemeCodeUnspsc190501.50456407': __('identifierSchemeCodeUnspsc190501.50456407'),
	'identifierSchemeCodeUnspsc190501.50456408': __('identifierSchemeCodeUnspsc190501.50456408'),
	'identifierSchemeCodeUnspsc190501.50456409': __('identifierSchemeCodeUnspsc190501.50456409'),
	'identifierSchemeCodeUnspsc190501.50456410': __('identifierSchemeCodeUnspsc190501.50456410'),
	'identifierSchemeCodeUnspsc190501.50456411': __('identifierSchemeCodeUnspsc190501.50456411'),
	'identifierSchemeCodeUnspsc190501.50456412': __('identifierSchemeCodeUnspsc190501.50456412'),
	'identifierSchemeCodeUnspsc190501.50456413': __('identifierSchemeCodeUnspsc190501.50456413'),
	'identifierSchemeCodeUnspsc190501.50456414': __('identifierSchemeCodeUnspsc190501.50456414'),
	'identifierSchemeCodeUnspsc190501.50456415': __('identifierSchemeCodeUnspsc190501.50456415'),
	'identifierSchemeCodeUnspsc190501.50456416': __('identifierSchemeCodeUnspsc190501.50456416'),
	'identifierSchemeCodeUnspsc190501.50456417': __('identifierSchemeCodeUnspsc190501.50456417'),
	'identifierSchemeCodeUnspsc190501.50456418': __('identifierSchemeCodeUnspsc190501.50456418'),
	'identifierSchemeCodeUnspsc190501.50456419': __('identifierSchemeCodeUnspsc190501.50456419'),
	'identifierSchemeCodeUnspsc190501.50456420': __('identifierSchemeCodeUnspsc190501.50456420'),
	'identifierSchemeCodeUnspsc190501.50456421': __('identifierSchemeCodeUnspsc190501.50456421'),
	'identifierSchemeCodeUnspsc190501.50456422': __('identifierSchemeCodeUnspsc190501.50456422'),
	'identifierSchemeCodeUnspsc190501.50456423': __('identifierSchemeCodeUnspsc190501.50456423'),
	'identifierSchemeCodeUnspsc190501.50456424': __('identifierSchemeCodeUnspsc190501.50456424'),
	'identifierSchemeCodeUnspsc190501.50456425': __('identifierSchemeCodeUnspsc190501.50456425'),
	'identifierSchemeCodeUnspsc190501.50456426': __('identifierSchemeCodeUnspsc190501.50456426'),
	'identifierSchemeCodeUnspsc190501.50456427': __('identifierSchemeCodeUnspsc190501.50456427'),
	'identifierSchemeCodeUnspsc190501.50456500': __('identifierSchemeCodeUnspsc190501.50456500'),
	'identifierSchemeCodeUnspsc190501.50456501': __('identifierSchemeCodeUnspsc190501.50456501'),
	'identifierSchemeCodeUnspsc190501.50456502': __('identifierSchemeCodeUnspsc190501.50456502'),
	'identifierSchemeCodeUnspsc190501.50456503': __('identifierSchemeCodeUnspsc190501.50456503'),
	'identifierSchemeCodeUnspsc190501.50456504': __('identifierSchemeCodeUnspsc190501.50456504'),
	'identifierSchemeCodeUnspsc190501.50456505': __('identifierSchemeCodeUnspsc190501.50456505'),
	'identifierSchemeCodeUnspsc190501.50456506': __('identifierSchemeCodeUnspsc190501.50456506'),
	'identifierSchemeCodeUnspsc190501.50456507': __('identifierSchemeCodeUnspsc190501.50456507'),
	'identifierSchemeCodeUnspsc190501.50456508': __('identifierSchemeCodeUnspsc190501.50456508'),
	'identifierSchemeCodeUnspsc190501.50456600': __('identifierSchemeCodeUnspsc190501.50456600'),
	'identifierSchemeCodeUnspsc190501.50456601': __('identifierSchemeCodeUnspsc190501.50456601'),
	'identifierSchemeCodeUnspsc190501.50456602': __('identifierSchemeCodeUnspsc190501.50456602'),
	'identifierSchemeCodeUnspsc190501.50456603': __('identifierSchemeCodeUnspsc190501.50456603'),
	'identifierSchemeCodeUnspsc190501.50456604': __('identifierSchemeCodeUnspsc190501.50456604'),
	'identifierSchemeCodeUnspsc190501.50456605': __('identifierSchemeCodeUnspsc190501.50456605'),
	'identifierSchemeCodeUnspsc190501.50456606': __('identifierSchemeCodeUnspsc190501.50456606'),
	'identifierSchemeCodeUnspsc190501.50456607': __('identifierSchemeCodeUnspsc190501.50456607'),
	'identifierSchemeCodeUnspsc190501.50456608': __('identifierSchemeCodeUnspsc190501.50456608'),
	'identifierSchemeCodeUnspsc190501.50456609': __('identifierSchemeCodeUnspsc190501.50456609'),
	'identifierSchemeCodeUnspsc190501.50456610': __('identifierSchemeCodeUnspsc190501.50456610'),
	'identifierSchemeCodeUnspsc190501.50456611': __('identifierSchemeCodeUnspsc190501.50456611'),
	'identifierSchemeCodeUnspsc190501.50456612': __('identifierSchemeCodeUnspsc190501.50456612'),
	'identifierSchemeCodeUnspsc190501.50456613': __('identifierSchemeCodeUnspsc190501.50456613'),
	'identifierSchemeCodeUnspsc190501.50456614': __('identifierSchemeCodeUnspsc190501.50456614'),
	'identifierSchemeCodeUnspsc190501.50456615': __('identifierSchemeCodeUnspsc190501.50456615'),
	'identifierSchemeCodeUnspsc190501.50456616': __('identifierSchemeCodeUnspsc190501.50456616'),
	'identifierSchemeCodeUnspsc190501.50456617': __('identifierSchemeCodeUnspsc190501.50456617'),
	'identifierSchemeCodeUnspsc190501.50456618': __('identifierSchemeCodeUnspsc190501.50456618'),
	'identifierSchemeCodeUnspsc190501.50456619': __('identifierSchemeCodeUnspsc190501.50456619'),
	'identifierSchemeCodeUnspsc190501.50456620': __('identifierSchemeCodeUnspsc190501.50456620'),
	'identifierSchemeCodeUnspsc190501.50456700': __('identifierSchemeCodeUnspsc190501.50456700'),
	'identifierSchemeCodeUnspsc190501.50456701': __('identifierSchemeCodeUnspsc190501.50456701'),
	'identifierSchemeCodeUnspsc190501.50456702': __('identifierSchemeCodeUnspsc190501.50456702'),
	'identifierSchemeCodeUnspsc190501.50456703': __('identifierSchemeCodeUnspsc190501.50456703'),
	'identifierSchemeCodeUnspsc190501.50456704': __('identifierSchemeCodeUnspsc190501.50456704'),
	'identifierSchemeCodeUnspsc190501.50456705': __('identifierSchemeCodeUnspsc190501.50456705'),
	'identifierSchemeCodeUnspsc190501.50456706': __('identifierSchemeCodeUnspsc190501.50456706'),
	'identifierSchemeCodeUnspsc190501.50456707': __('identifierSchemeCodeUnspsc190501.50456707'),
	'identifierSchemeCodeUnspsc190501.50456708': __('identifierSchemeCodeUnspsc190501.50456708'),
	'identifierSchemeCodeUnspsc190501.50456709': __('identifierSchemeCodeUnspsc190501.50456709'),
	'identifierSchemeCodeUnspsc190501.50456710': __('identifierSchemeCodeUnspsc190501.50456710'),
	'identifierSchemeCodeUnspsc190501.50456800': __('identifierSchemeCodeUnspsc190501.50456800'),
	'identifierSchemeCodeUnspsc190501.50456801': __('identifierSchemeCodeUnspsc190501.50456801'),
	'identifierSchemeCodeUnspsc190501.50456802': __('identifierSchemeCodeUnspsc190501.50456802'),
	'identifierSchemeCodeUnspsc190501.50456803': __('identifierSchemeCodeUnspsc190501.50456803'),
	'identifierSchemeCodeUnspsc190501.50456804': __('identifierSchemeCodeUnspsc190501.50456804'),
	'identifierSchemeCodeUnspsc190501.50456805': __('identifierSchemeCodeUnspsc190501.50456805'),
	'identifierSchemeCodeUnspsc190501.50456806': __('identifierSchemeCodeUnspsc190501.50456806'),
	'identifierSchemeCodeUnspsc190501.50456807': __('identifierSchemeCodeUnspsc190501.50456807'),
	'identifierSchemeCodeUnspsc190501.50456808': __('identifierSchemeCodeUnspsc190501.50456808'),
	'identifierSchemeCodeUnspsc190501.50456809': __('identifierSchemeCodeUnspsc190501.50456809'),
	'identifierSchemeCodeUnspsc190501.50456810': __('identifierSchemeCodeUnspsc190501.50456810'),
	'identifierSchemeCodeUnspsc190501.50456811': __('identifierSchemeCodeUnspsc190501.50456811'),
	'identifierSchemeCodeUnspsc190501.50456812': __('identifierSchemeCodeUnspsc190501.50456812'),
	'identifierSchemeCodeUnspsc190501.50456813': __('identifierSchemeCodeUnspsc190501.50456813'),
	'identifierSchemeCodeUnspsc190501.50456814': __('identifierSchemeCodeUnspsc190501.50456814'),
	'identifierSchemeCodeUnspsc190501.50456815': __('identifierSchemeCodeUnspsc190501.50456815'),
	'identifierSchemeCodeUnspsc190501.50456816': __('identifierSchemeCodeUnspsc190501.50456816'),
	'identifierSchemeCodeUnspsc190501.50456817': __('identifierSchemeCodeUnspsc190501.50456817'),
	'identifierSchemeCodeUnspsc190501.50456818': __('identifierSchemeCodeUnspsc190501.50456818'),
	'identifierSchemeCodeUnspsc190501.50456819': __('identifierSchemeCodeUnspsc190501.50456819'),
	'identifierSchemeCodeUnspsc190501.50456820': __('identifierSchemeCodeUnspsc190501.50456820'),
	'identifierSchemeCodeUnspsc190501.50456821': __('identifierSchemeCodeUnspsc190501.50456821'),
	'identifierSchemeCodeUnspsc190501.50456822': __('identifierSchemeCodeUnspsc190501.50456822'),
	'identifierSchemeCodeUnspsc190501.50456823': __('identifierSchemeCodeUnspsc190501.50456823'),
	'identifierSchemeCodeUnspsc190501.50456824': __('identifierSchemeCodeUnspsc190501.50456824'),
	'identifierSchemeCodeUnspsc190501.50456825': __('identifierSchemeCodeUnspsc190501.50456825'),
	'identifierSchemeCodeUnspsc190501.50456826': __('identifierSchemeCodeUnspsc190501.50456826'),
	'identifierSchemeCodeUnspsc190501.50456827': __('identifierSchemeCodeUnspsc190501.50456827'),
	'identifierSchemeCodeUnspsc190501.50456828': __('identifierSchemeCodeUnspsc190501.50456828'),
	'identifierSchemeCodeUnspsc190501.50456829': __('identifierSchemeCodeUnspsc190501.50456829'),
	'identifierSchemeCodeUnspsc190501.50456830': __('identifierSchemeCodeUnspsc190501.50456830'),
	'identifierSchemeCodeUnspsc190501.50456831': __('identifierSchemeCodeUnspsc190501.50456831'),
	'identifierSchemeCodeUnspsc190501.50456832': __('identifierSchemeCodeUnspsc190501.50456832'),
	'identifierSchemeCodeUnspsc190501.50456833': __('identifierSchemeCodeUnspsc190501.50456833'),
	'identifierSchemeCodeUnspsc190501.50456834': __('identifierSchemeCodeUnspsc190501.50456834'),
	'identifierSchemeCodeUnspsc190501.50456835': __('identifierSchemeCodeUnspsc190501.50456835'),
	'identifierSchemeCodeUnspsc190501.50456836': __('identifierSchemeCodeUnspsc190501.50456836'),
	'identifierSchemeCodeUnspsc190501.50456837': __('identifierSchemeCodeUnspsc190501.50456837'),
	'identifierSchemeCodeUnspsc190501.50456838': __('identifierSchemeCodeUnspsc190501.50456838'),
	'identifierSchemeCodeUnspsc190501.50456839': __('identifierSchemeCodeUnspsc190501.50456839'),
	'identifierSchemeCodeUnspsc190501.50456840': __('identifierSchemeCodeUnspsc190501.50456840'),
	'identifierSchemeCodeUnspsc190501.50456841': __('identifierSchemeCodeUnspsc190501.50456841'),
	'identifierSchemeCodeUnspsc190501.50456842': __('identifierSchemeCodeUnspsc190501.50456842'),
	'identifierSchemeCodeUnspsc190501.50456843': __('identifierSchemeCodeUnspsc190501.50456843'),
	'identifierSchemeCodeUnspsc190501.50456844': __('identifierSchemeCodeUnspsc190501.50456844'),
	'identifierSchemeCodeUnspsc190501.50456845': __('identifierSchemeCodeUnspsc190501.50456845'),
	'identifierSchemeCodeUnspsc190501.50456846': __('identifierSchemeCodeUnspsc190501.50456846'),
	'identifierSchemeCodeUnspsc190501.50456847': __('identifierSchemeCodeUnspsc190501.50456847'),
	'identifierSchemeCodeUnspsc190501.50456848': __('identifierSchemeCodeUnspsc190501.50456848'),
	'identifierSchemeCodeUnspsc190501.50456849': __('identifierSchemeCodeUnspsc190501.50456849'),
	'identifierSchemeCodeUnspsc190501.50456850': __('identifierSchemeCodeUnspsc190501.50456850'),
	'identifierSchemeCodeUnspsc190501.50456851': __('identifierSchemeCodeUnspsc190501.50456851'),
	'identifierSchemeCodeUnspsc190501.50456852': __('identifierSchemeCodeUnspsc190501.50456852'),
	'identifierSchemeCodeUnspsc190501.50456853': __('identifierSchemeCodeUnspsc190501.50456853'),
	'identifierSchemeCodeUnspsc190501.50456854': __('identifierSchemeCodeUnspsc190501.50456854'),
	'identifierSchemeCodeUnspsc190501.50456855': __('identifierSchemeCodeUnspsc190501.50456855'),
	'identifierSchemeCodeUnspsc190501.50456856': __('identifierSchemeCodeUnspsc190501.50456856'),
	'identifierSchemeCodeUnspsc190501.50456857': __('identifierSchemeCodeUnspsc190501.50456857'),
	'identifierSchemeCodeUnspsc190501.50456858': __('identifierSchemeCodeUnspsc190501.50456858'),
	'identifierSchemeCodeUnspsc190501.50456859': __('identifierSchemeCodeUnspsc190501.50456859'),
	'identifierSchemeCodeUnspsc190501.50456860': __('identifierSchemeCodeUnspsc190501.50456860'),
	'identifierSchemeCodeUnspsc190501.50456861': __('identifierSchemeCodeUnspsc190501.50456861'),
	'identifierSchemeCodeUnspsc190501.50456862': __('identifierSchemeCodeUnspsc190501.50456862'),
	'identifierSchemeCodeUnspsc190501.50456863': __('identifierSchemeCodeUnspsc190501.50456863'),
	'identifierSchemeCodeUnspsc190501.50456864': __('identifierSchemeCodeUnspsc190501.50456864'),
	'identifierSchemeCodeUnspsc190501.50456865': __('identifierSchemeCodeUnspsc190501.50456865'),
	'identifierSchemeCodeUnspsc190501.50456866': __('identifierSchemeCodeUnspsc190501.50456866'),
	'identifierSchemeCodeUnspsc190501.50456867': __('identifierSchemeCodeUnspsc190501.50456867'),
	'identifierSchemeCodeUnspsc190501.50456868': __('identifierSchemeCodeUnspsc190501.50456868'),
	'identifierSchemeCodeUnspsc190501.50456900': __('identifierSchemeCodeUnspsc190501.50456900'),
	'identifierSchemeCodeUnspsc190501.50456901': __('identifierSchemeCodeUnspsc190501.50456901'),
	'identifierSchemeCodeUnspsc190501.50456902': __('identifierSchemeCodeUnspsc190501.50456902'),
	'identifierSchemeCodeUnspsc190501.50456903': __('identifierSchemeCodeUnspsc190501.50456903'),
	'identifierSchemeCodeUnspsc190501.50456904': __('identifierSchemeCodeUnspsc190501.50456904'),
	'identifierSchemeCodeUnspsc190501.50456905': __('identifierSchemeCodeUnspsc190501.50456905'),
	'identifierSchemeCodeUnspsc190501.50456906': __('identifierSchemeCodeUnspsc190501.50456906'),
	'identifierSchemeCodeUnspsc190501.50456907': __('identifierSchemeCodeUnspsc190501.50456907'),
	'identifierSchemeCodeUnspsc190501.50456908': __('identifierSchemeCodeUnspsc190501.50456908'),
	'identifierSchemeCodeUnspsc190501.50456909': __('identifierSchemeCodeUnspsc190501.50456909'),
	'identifierSchemeCodeUnspsc190501.50456910': __('identifierSchemeCodeUnspsc190501.50456910'),
	'identifierSchemeCodeUnspsc190501.50460000': __('identifierSchemeCodeUnspsc190501.50460000'),
	'identifierSchemeCodeUnspsc190501.50461500': __('identifierSchemeCodeUnspsc190501.50461500'),
	'identifierSchemeCodeUnspsc190501.50461501': __('identifierSchemeCodeUnspsc190501.50461501'),
	'identifierSchemeCodeUnspsc190501.50461502': __('identifierSchemeCodeUnspsc190501.50461502'),
	'identifierSchemeCodeUnspsc190501.50461503': __('identifierSchemeCodeUnspsc190501.50461503'),
	'identifierSchemeCodeUnspsc190501.50461504': __('identifierSchemeCodeUnspsc190501.50461504'),
	'identifierSchemeCodeUnspsc190501.50461505': __('identifierSchemeCodeUnspsc190501.50461505'),
	'identifierSchemeCodeUnspsc190501.50461506': __('identifierSchemeCodeUnspsc190501.50461506'),
	'identifierSchemeCodeUnspsc190501.50461507': __('identifierSchemeCodeUnspsc190501.50461507'),
	'identifierSchemeCodeUnspsc190501.50461508': __('identifierSchemeCodeUnspsc190501.50461508'),
	'identifierSchemeCodeUnspsc190501.50461509': __('identifierSchemeCodeUnspsc190501.50461509'),
	'identifierSchemeCodeUnspsc190501.50461510': __('identifierSchemeCodeUnspsc190501.50461510'),
	'identifierSchemeCodeUnspsc190501.50461511': __('identifierSchemeCodeUnspsc190501.50461511'),
	'identifierSchemeCodeUnspsc190501.50461512': __('identifierSchemeCodeUnspsc190501.50461512'),
	'identifierSchemeCodeUnspsc190501.50461513': __('identifierSchemeCodeUnspsc190501.50461513'),
	'identifierSchemeCodeUnspsc190501.50461600': __('identifierSchemeCodeUnspsc190501.50461600'),
	'identifierSchemeCodeUnspsc190501.50461601': __('identifierSchemeCodeUnspsc190501.50461601'),
	'identifierSchemeCodeUnspsc190501.50461602': __('identifierSchemeCodeUnspsc190501.50461602'),
	'identifierSchemeCodeUnspsc190501.50461603': __('identifierSchemeCodeUnspsc190501.50461603'),
	'identifierSchemeCodeUnspsc190501.50461604': __('identifierSchemeCodeUnspsc190501.50461604'),
	'identifierSchemeCodeUnspsc190501.50461605': __('identifierSchemeCodeUnspsc190501.50461605'),
	'identifierSchemeCodeUnspsc190501.50461700': __('identifierSchemeCodeUnspsc190501.50461700'),
	'identifierSchemeCodeUnspsc190501.50461701': __('identifierSchemeCodeUnspsc190501.50461701'),
	'identifierSchemeCodeUnspsc190501.50461702': __('identifierSchemeCodeUnspsc190501.50461702'),
	'identifierSchemeCodeUnspsc190501.50461703': __('identifierSchemeCodeUnspsc190501.50461703'),
	'identifierSchemeCodeUnspsc190501.50461704': __('identifierSchemeCodeUnspsc190501.50461704'),
	'identifierSchemeCodeUnspsc190501.50461705': __('identifierSchemeCodeUnspsc190501.50461705'),
	'identifierSchemeCodeUnspsc190501.50461706': __('identifierSchemeCodeUnspsc190501.50461706'),
	'identifierSchemeCodeUnspsc190501.50461707': __('identifierSchemeCodeUnspsc190501.50461707'),
	'identifierSchemeCodeUnspsc190501.50461708': __('identifierSchemeCodeUnspsc190501.50461708'),
	'identifierSchemeCodeUnspsc190501.50461709': __('identifierSchemeCodeUnspsc190501.50461709'),
	'identifierSchemeCodeUnspsc190501.50461710': __('identifierSchemeCodeUnspsc190501.50461710'),
	'identifierSchemeCodeUnspsc190501.50461711': __('identifierSchemeCodeUnspsc190501.50461711'),
	'identifierSchemeCodeUnspsc190501.50461712': __('identifierSchemeCodeUnspsc190501.50461712'),
	'identifierSchemeCodeUnspsc190501.50461713': __('identifierSchemeCodeUnspsc190501.50461713'),
	'identifierSchemeCodeUnspsc190501.50461714': __('identifierSchemeCodeUnspsc190501.50461714'),
	'identifierSchemeCodeUnspsc190501.50461715': __('identifierSchemeCodeUnspsc190501.50461715'),
	'identifierSchemeCodeUnspsc190501.50461716': __('identifierSchemeCodeUnspsc190501.50461716'),
	'identifierSchemeCodeUnspsc190501.50461717': __('identifierSchemeCodeUnspsc190501.50461717'),
	'identifierSchemeCodeUnspsc190501.50461718': __('identifierSchemeCodeUnspsc190501.50461718'),
	'identifierSchemeCodeUnspsc190501.50461719': __('identifierSchemeCodeUnspsc190501.50461719'),
	'identifierSchemeCodeUnspsc190501.50461720': __('identifierSchemeCodeUnspsc190501.50461720'),
	'identifierSchemeCodeUnspsc190501.50461721': __('identifierSchemeCodeUnspsc190501.50461721'),
	'identifierSchemeCodeUnspsc190501.50461722': __('identifierSchemeCodeUnspsc190501.50461722'),
	'identifierSchemeCodeUnspsc190501.50461723': __('identifierSchemeCodeUnspsc190501.50461723'),
	'identifierSchemeCodeUnspsc190501.50461724': __('identifierSchemeCodeUnspsc190501.50461724'),
	'identifierSchemeCodeUnspsc190501.50461725': __('identifierSchemeCodeUnspsc190501.50461725'),
	'identifierSchemeCodeUnspsc190501.50461726': __('identifierSchemeCodeUnspsc190501.50461726'),
	'identifierSchemeCodeUnspsc190501.50461727': __('identifierSchemeCodeUnspsc190501.50461727'),
	'identifierSchemeCodeUnspsc190501.50461728': __('identifierSchemeCodeUnspsc190501.50461728'),
	'identifierSchemeCodeUnspsc190501.50461729': __('identifierSchemeCodeUnspsc190501.50461729'),
	'identifierSchemeCodeUnspsc190501.50461730': __('identifierSchemeCodeUnspsc190501.50461730'),
	'identifierSchemeCodeUnspsc190501.50461731': __('identifierSchemeCodeUnspsc190501.50461731'),
	'identifierSchemeCodeUnspsc190501.50461732': __('identifierSchemeCodeUnspsc190501.50461732'),
	'identifierSchemeCodeUnspsc190501.50461733': __('identifierSchemeCodeUnspsc190501.50461733'),
	'identifierSchemeCodeUnspsc190501.50461734': __('identifierSchemeCodeUnspsc190501.50461734'),
	'identifierSchemeCodeUnspsc190501.50461735': __('identifierSchemeCodeUnspsc190501.50461735'),
	'identifierSchemeCodeUnspsc190501.50461736': __('identifierSchemeCodeUnspsc190501.50461736'),
	'identifierSchemeCodeUnspsc190501.50461737': __('identifierSchemeCodeUnspsc190501.50461737'),
	'identifierSchemeCodeUnspsc190501.50461738': __('identifierSchemeCodeUnspsc190501.50461738'),
	'identifierSchemeCodeUnspsc190501.50461739': __('identifierSchemeCodeUnspsc190501.50461739'),
	'identifierSchemeCodeUnspsc190501.50461740': __('identifierSchemeCodeUnspsc190501.50461740'),
	'identifierSchemeCodeUnspsc190501.50461741': __('identifierSchemeCodeUnspsc190501.50461741'),
	'identifierSchemeCodeUnspsc190501.50461742': __('identifierSchemeCodeUnspsc190501.50461742'),
	'identifierSchemeCodeUnspsc190501.50461743': __('identifierSchemeCodeUnspsc190501.50461743'),
	'identifierSchemeCodeUnspsc190501.50461744': __('identifierSchemeCodeUnspsc190501.50461744'),
	'identifierSchemeCodeUnspsc190501.50461745': __('identifierSchemeCodeUnspsc190501.50461745'),
	'identifierSchemeCodeUnspsc190501.50461746': __('identifierSchemeCodeUnspsc190501.50461746'),
	'identifierSchemeCodeUnspsc190501.50461747': __('identifierSchemeCodeUnspsc190501.50461747'),
	'identifierSchemeCodeUnspsc190501.50461748': __('identifierSchemeCodeUnspsc190501.50461748'),
	'identifierSchemeCodeUnspsc190501.50461749': __('identifierSchemeCodeUnspsc190501.50461749'),
	'identifierSchemeCodeUnspsc190501.50461750': __('identifierSchemeCodeUnspsc190501.50461750'),
	'identifierSchemeCodeUnspsc190501.50461751': __('identifierSchemeCodeUnspsc190501.50461751'),
	'identifierSchemeCodeUnspsc190501.50461752': __('identifierSchemeCodeUnspsc190501.50461752'),
	'identifierSchemeCodeUnspsc190501.50461753': __('identifierSchemeCodeUnspsc190501.50461753'),
	'identifierSchemeCodeUnspsc190501.50461754': __('identifierSchemeCodeUnspsc190501.50461754'),
	'identifierSchemeCodeUnspsc190501.50461755': __('identifierSchemeCodeUnspsc190501.50461755'),
	'identifierSchemeCodeUnspsc190501.50461756': __('identifierSchemeCodeUnspsc190501.50461756'),
	'identifierSchemeCodeUnspsc190501.50461757': __('identifierSchemeCodeUnspsc190501.50461757'),
	'identifierSchemeCodeUnspsc190501.50461758': __('identifierSchemeCodeUnspsc190501.50461758'),
	'identifierSchemeCodeUnspsc190501.50461759': __('identifierSchemeCodeUnspsc190501.50461759'),
	'identifierSchemeCodeUnspsc190501.50461760': __('identifierSchemeCodeUnspsc190501.50461760'),
	'identifierSchemeCodeUnspsc190501.50461761': __('identifierSchemeCodeUnspsc190501.50461761'),
	'identifierSchemeCodeUnspsc190501.50461762': __('identifierSchemeCodeUnspsc190501.50461762'),
	'identifierSchemeCodeUnspsc190501.50461763': __('identifierSchemeCodeUnspsc190501.50461763'),
	'identifierSchemeCodeUnspsc190501.50461764': __('identifierSchemeCodeUnspsc190501.50461764'),
	'identifierSchemeCodeUnspsc190501.50461765': __('identifierSchemeCodeUnspsc190501.50461765'),
	'identifierSchemeCodeUnspsc190501.50461766': __('identifierSchemeCodeUnspsc190501.50461766'),
	'identifierSchemeCodeUnspsc190501.50461767': __('identifierSchemeCodeUnspsc190501.50461767'),
	'identifierSchemeCodeUnspsc190501.50461768': __('identifierSchemeCodeUnspsc190501.50461768'),
	'identifierSchemeCodeUnspsc190501.50461769': __('identifierSchemeCodeUnspsc190501.50461769'),
	'identifierSchemeCodeUnspsc190501.50461770': __('identifierSchemeCodeUnspsc190501.50461770'),
	'identifierSchemeCodeUnspsc190501.50461771': __('identifierSchemeCodeUnspsc190501.50461771'),
	'identifierSchemeCodeUnspsc190501.50461772': __('identifierSchemeCodeUnspsc190501.50461772'),
	'identifierSchemeCodeUnspsc190501.50461773': __('identifierSchemeCodeUnspsc190501.50461773'),
	'identifierSchemeCodeUnspsc190501.50461774': __('identifierSchemeCodeUnspsc190501.50461774'),
	'identifierSchemeCodeUnspsc190501.50461775': __('identifierSchemeCodeUnspsc190501.50461775'),
	'identifierSchemeCodeUnspsc190501.50461776': __('identifierSchemeCodeUnspsc190501.50461776'),
	'identifierSchemeCodeUnspsc190501.50461777': __('identifierSchemeCodeUnspsc190501.50461777'),
	'identifierSchemeCodeUnspsc190501.50461778': __('identifierSchemeCodeUnspsc190501.50461778'),
	'identifierSchemeCodeUnspsc190501.50461779': __('identifierSchemeCodeUnspsc190501.50461779'),
	'identifierSchemeCodeUnspsc190501.50461780': __('identifierSchemeCodeUnspsc190501.50461780'),
	'identifierSchemeCodeUnspsc190501.50461781': __('identifierSchemeCodeUnspsc190501.50461781'),
	'identifierSchemeCodeUnspsc190501.50461782': __('identifierSchemeCodeUnspsc190501.50461782'),
	'identifierSchemeCodeUnspsc190501.50461800': __('identifierSchemeCodeUnspsc190501.50461800'),
	'identifierSchemeCodeUnspsc190501.50461801': __('identifierSchemeCodeUnspsc190501.50461801'),
	'identifierSchemeCodeUnspsc190501.50461802': __('identifierSchemeCodeUnspsc190501.50461802'),
	'identifierSchemeCodeUnspsc190501.50461803': __('identifierSchemeCodeUnspsc190501.50461803'),
	'identifierSchemeCodeUnspsc190501.50461804': __('identifierSchemeCodeUnspsc190501.50461804'),
	'identifierSchemeCodeUnspsc190501.50461805': __('identifierSchemeCodeUnspsc190501.50461805'),
	'identifierSchemeCodeUnspsc190501.50461806': __('identifierSchemeCodeUnspsc190501.50461806'),
	'identifierSchemeCodeUnspsc190501.50461807': __('identifierSchemeCodeUnspsc190501.50461807'),
	'identifierSchemeCodeUnspsc190501.50461808': __('identifierSchemeCodeUnspsc190501.50461808'),
	'identifierSchemeCodeUnspsc190501.50461809': __('identifierSchemeCodeUnspsc190501.50461809'),
	'identifierSchemeCodeUnspsc190501.50461810': __('identifierSchemeCodeUnspsc190501.50461810'),
	'identifierSchemeCodeUnspsc190501.50461811': __('identifierSchemeCodeUnspsc190501.50461811'),
	'identifierSchemeCodeUnspsc190501.50461812': __('identifierSchemeCodeUnspsc190501.50461812'),
	'identifierSchemeCodeUnspsc190501.50461813': __('identifierSchemeCodeUnspsc190501.50461813'),
	'identifierSchemeCodeUnspsc190501.50461814': __('identifierSchemeCodeUnspsc190501.50461814'),
	'identifierSchemeCodeUnspsc190501.50461815': __('identifierSchemeCodeUnspsc190501.50461815'),
	'identifierSchemeCodeUnspsc190501.50461816': __('identifierSchemeCodeUnspsc190501.50461816'),
	'identifierSchemeCodeUnspsc190501.50461817': __('identifierSchemeCodeUnspsc190501.50461817'),
	'identifierSchemeCodeUnspsc190501.50461818': __('identifierSchemeCodeUnspsc190501.50461818'),
	'identifierSchemeCodeUnspsc190501.50461819': __('identifierSchemeCodeUnspsc190501.50461819'),
	'identifierSchemeCodeUnspsc190501.50461820': __('identifierSchemeCodeUnspsc190501.50461820'),
	'identifierSchemeCodeUnspsc190501.50461821': __('identifierSchemeCodeUnspsc190501.50461821'),
	'identifierSchemeCodeUnspsc190501.50461822': __('identifierSchemeCodeUnspsc190501.50461822'),
	'identifierSchemeCodeUnspsc190501.50461823': __('identifierSchemeCodeUnspsc190501.50461823'),
	'identifierSchemeCodeUnspsc190501.50461824': __('identifierSchemeCodeUnspsc190501.50461824'),
	'identifierSchemeCodeUnspsc190501.50461825': __('identifierSchemeCodeUnspsc190501.50461825'),
	'identifierSchemeCodeUnspsc190501.50461826': __('identifierSchemeCodeUnspsc190501.50461826'),
	'identifierSchemeCodeUnspsc190501.50461827': __('identifierSchemeCodeUnspsc190501.50461827'),
	'identifierSchemeCodeUnspsc190501.50461828': __('identifierSchemeCodeUnspsc190501.50461828'),
	'identifierSchemeCodeUnspsc190501.50461829': __('identifierSchemeCodeUnspsc190501.50461829'),
	'identifierSchemeCodeUnspsc190501.50461830': __('identifierSchemeCodeUnspsc190501.50461830'),
	'identifierSchemeCodeUnspsc190501.50461831': __('identifierSchemeCodeUnspsc190501.50461831'),
	'identifierSchemeCodeUnspsc190501.50461832': __('identifierSchemeCodeUnspsc190501.50461832'),
	'identifierSchemeCodeUnspsc190501.50461833': __('identifierSchemeCodeUnspsc190501.50461833'),
	'identifierSchemeCodeUnspsc190501.50461834': __('identifierSchemeCodeUnspsc190501.50461834'),
	'identifierSchemeCodeUnspsc190501.50461835': __('identifierSchemeCodeUnspsc190501.50461835'),
	'identifierSchemeCodeUnspsc190501.50461836': __('identifierSchemeCodeUnspsc190501.50461836'),
	'identifierSchemeCodeUnspsc190501.50461837': __('identifierSchemeCodeUnspsc190501.50461837'),
	'identifierSchemeCodeUnspsc190501.50461838': __('identifierSchemeCodeUnspsc190501.50461838'),
	'identifierSchemeCodeUnspsc190501.50461839': __('identifierSchemeCodeUnspsc190501.50461839'),
	'identifierSchemeCodeUnspsc190501.50461840': __('identifierSchemeCodeUnspsc190501.50461840'),
	'identifierSchemeCodeUnspsc190501.50461841': __('identifierSchemeCodeUnspsc190501.50461841'),
	'identifierSchemeCodeUnspsc190501.50461842': __('identifierSchemeCodeUnspsc190501.50461842'),
	'identifierSchemeCodeUnspsc190501.50461843': __('identifierSchemeCodeUnspsc190501.50461843'),
	'identifierSchemeCodeUnspsc190501.50461844': __('identifierSchemeCodeUnspsc190501.50461844'),
	'identifierSchemeCodeUnspsc190501.50461845': __('identifierSchemeCodeUnspsc190501.50461845'),
	'identifierSchemeCodeUnspsc190501.50461846': __('identifierSchemeCodeUnspsc190501.50461846'),
	'identifierSchemeCodeUnspsc190501.50461847': __('identifierSchemeCodeUnspsc190501.50461847'),
	'identifierSchemeCodeUnspsc190501.50461848': __('identifierSchemeCodeUnspsc190501.50461848'),
	'identifierSchemeCodeUnspsc190501.50461849': __('identifierSchemeCodeUnspsc190501.50461849'),
	'identifierSchemeCodeUnspsc190501.50461850': __('identifierSchemeCodeUnspsc190501.50461850'),
	'identifierSchemeCodeUnspsc190501.50461851': __('identifierSchemeCodeUnspsc190501.50461851'),
	'identifierSchemeCodeUnspsc190501.50461852': __('identifierSchemeCodeUnspsc190501.50461852'),
	'identifierSchemeCodeUnspsc190501.50461900': __('identifierSchemeCodeUnspsc190501.50461900'),
	'identifierSchemeCodeUnspsc190501.50461901': __('identifierSchemeCodeUnspsc190501.50461901'),
	'identifierSchemeCodeUnspsc190501.50461902': __('identifierSchemeCodeUnspsc190501.50461902'),
	'identifierSchemeCodeUnspsc190501.50461903': __('identifierSchemeCodeUnspsc190501.50461903'),
	'identifierSchemeCodeUnspsc190501.50461904': __('identifierSchemeCodeUnspsc190501.50461904'),
	'identifierSchemeCodeUnspsc190501.50461905': __('identifierSchemeCodeUnspsc190501.50461905'),
	'identifierSchemeCodeUnspsc190501.50461906': __('identifierSchemeCodeUnspsc190501.50461906'),
	'identifierSchemeCodeUnspsc190501.50461907': __('identifierSchemeCodeUnspsc190501.50461907'),
	'identifierSchemeCodeUnspsc190501.50461908': __('identifierSchemeCodeUnspsc190501.50461908'),
	'identifierSchemeCodeUnspsc190501.50461909': __('identifierSchemeCodeUnspsc190501.50461909'),
	'identifierSchemeCodeUnspsc190501.50461910': __('identifierSchemeCodeUnspsc190501.50461910'),
	'identifierSchemeCodeUnspsc190501.50461911': __('identifierSchemeCodeUnspsc190501.50461911'),
	'identifierSchemeCodeUnspsc190501.50461912': __('identifierSchemeCodeUnspsc190501.50461912'),
	'identifierSchemeCodeUnspsc190501.50461913': __('identifierSchemeCodeUnspsc190501.50461913'),
	'identifierSchemeCodeUnspsc190501.50461914': __('identifierSchemeCodeUnspsc190501.50461914'),
	'identifierSchemeCodeUnspsc190501.50461915': __('identifierSchemeCodeUnspsc190501.50461915'),
	'identifierSchemeCodeUnspsc190501.50461916': __('identifierSchemeCodeUnspsc190501.50461916'),
	'identifierSchemeCodeUnspsc190501.50461917': __('identifierSchemeCodeUnspsc190501.50461917'),
	'identifierSchemeCodeUnspsc190501.50461918': __('identifierSchemeCodeUnspsc190501.50461918'),
	'identifierSchemeCodeUnspsc190501.50461919': __('identifierSchemeCodeUnspsc190501.50461919'),
	'identifierSchemeCodeUnspsc190501.50461920': __('identifierSchemeCodeUnspsc190501.50461920'),
	'identifierSchemeCodeUnspsc190501.50461921': __('identifierSchemeCodeUnspsc190501.50461921'),
	'identifierSchemeCodeUnspsc190501.50461922': __('identifierSchemeCodeUnspsc190501.50461922'),
	'identifierSchemeCodeUnspsc190501.50462000': __('identifierSchemeCodeUnspsc190501.50462000'),
	'identifierSchemeCodeUnspsc190501.50462001': __('identifierSchemeCodeUnspsc190501.50462001'),
	'identifierSchemeCodeUnspsc190501.50462002': __('identifierSchemeCodeUnspsc190501.50462002'),
	'identifierSchemeCodeUnspsc190501.50462003': __('identifierSchemeCodeUnspsc190501.50462003'),
	'identifierSchemeCodeUnspsc190501.50462004': __('identifierSchemeCodeUnspsc190501.50462004'),
	'identifierSchemeCodeUnspsc190501.50462100': __('identifierSchemeCodeUnspsc190501.50462100'),
	'identifierSchemeCodeUnspsc190501.50462101': __('identifierSchemeCodeUnspsc190501.50462101'),
	'identifierSchemeCodeUnspsc190501.50462102': __('identifierSchemeCodeUnspsc190501.50462102'),
	'identifierSchemeCodeUnspsc190501.50462103': __('identifierSchemeCodeUnspsc190501.50462103'),
	'identifierSchemeCodeUnspsc190501.50462104': __('identifierSchemeCodeUnspsc190501.50462104'),
	'identifierSchemeCodeUnspsc190501.50462105': __('identifierSchemeCodeUnspsc190501.50462105'),
	'identifierSchemeCodeUnspsc190501.50462106': __('identifierSchemeCodeUnspsc190501.50462106'),
	'identifierSchemeCodeUnspsc190501.50462107': __('identifierSchemeCodeUnspsc190501.50462107'),
	'identifierSchemeCodeUnspsc190501.50462200': __('identifierSchemeCodeUnspsc190501.50462200'),
	'identifierSchemeCodeUnspsc190501.50462201': __('identifierSchemeCodeUnspsc190501.50462201'),
	'identifierSchemeCodeUnspsc190501.50462202': __('identifierSchemeCodeUnspsc190501.50462202'),
	'identifierSchemeCodeUnspsc190501.50462203': __('identifierSchemeCodeUnspsc190501.50462203'),
	'identifierSchemeCodeUnspsc190501.50462204': __('identifierSchemeCodeUnspsc190501.50462204'),
	'identifierSchemeCodeUnspsc190501.50462205': __('identifierSchemeCodeUnspsc190501.50462205'),
	'identifierSchemeCodeUnspsc190501.50462206': __('identifierSchemeCodeUnspsc190501.50462206'),
	'identifierSchemeCodeUnspsc190501.50462207': __('identifierSchemeCodeUnspsc190501.50462207'),
	'identifierSchemeCodeUnspsc190501.50462208': __('identifierSchemeCodeUnspsc190501.50462208'),
	'identifierSchemeCodeUnspsc190501.50462209': __('identifierSchemeCodeUnspsc190501.50462209'),
	'identifierSchemeCodeUnspsc190501.50462210': __('identifierSchemeCodeUnspsc190501.50462210'),
	'identifierSchemeCodeUnspsc190501.50462211': __('identifierSchemeCodeUnspsc190501.50462211'),
	'identifierSchemeCodeUnspsc190501.50462300': __('identifierSchemeCodeUnspsc190501.50462300'),
	'identifierSchemeCodeUnspsc190501.50462301': __('identifierSchemeCodeUnspsc190501.50462301'),
	'identifierSchemeCodeUnspsc190501.50462302': __('identifierSchemeCodeUnspsc190501.50462302'),
	'identifierSchemeCodeUnspsc190501.50462303': __('identifierSchemeCodeUnspsc190501.50462303'),
	'identifierSchemeCodeUnspsc190501.50462304': __('identifierSchemeCodeUnspsc190501.50462304'),
	'identifierSchemeCodeUnspsc190501.50462400': __('identifierSchemeCodeUnspsc190501.50462400'),
	'identifierSchemeCodeUnspsc190501.50462401': __('identifierSchemeCodeUnspsc190501.50462401'),
	'identifierSchemeCodeUnspsc190501.50462402': __('identifierSchemeCodeUnspsc190501.50462402'),
	'identifierSchemeCodeUnspsc190501.50462500': __('identifierSchemeCodeUnspsc190501.50462500'),
	'identifierSchemeCodeUnspsc190501.50462501': __('identifierSchemeCodeUnspsc190501.50462501'),
	'identifierSchemeCodeUnspsc190501.50462502': __('identifierSchemeCodeUnspsc190501.50462502'),
	'identifierSchemeCodeUnspsc190501.50462503': __('identifierSchemeCodeUnspsc190501.50462503'),
	'identifierSchemeCodeUnspsc190501.50462504': __('identifierSchemeCodeUnspsc190501.50462504'),
	'identifierSchemeCodeUnspsc190501.50462505': __('identifierSchemeCodeUnspsc190501.50462505'),
	'identifierSchemeCodeUnspsc190501.50462506': __('identifierSchemeCodeUnspsc190501.50462506'),
	'identifierSchemeCodeUnspsc190501.50462600': __('identifierSchemeCodeUnspsc190501.50462600'),
	'identifierSchemeCodeUnspsc190501.50462601': __('identifierSchemeCodeUnspsc190501.50462601'),
	'identifierSchemeCodeUnspsc190501.50462602': __('identifierSchemeCodeUnspsc190501.50462602'),
	'identifierSchemeCodeUnspsc190501.50462603': __('identifierSchemeCodeUnspsc190501.50462603'),
	'identifierSchemeCodeUnspsc190501.50462604': __('identifierSchemeCodeUnspsc190501.50462604'),
	'identifierSchemeCodeUnspsc190501.50462605': __('identifierSchemeCodeUnspsc190501.50462605'),
	'identifierSchemeCodeUnspsc190501.50462606': __('identifierSchemeCodeUnspsc190501.50462606'),
	'identifierSchemeCodeUnspsc190501.50462607': __('identifierSchemeCodeUnspsc190501.50462607'),
	'identifierSchemeCodeUnspsc190501.50462608': __('identifierSchemeCodeUnspsc190501.50462608'),
	'identifierSchemeCodeUnspsc190501.50462609': __('identifierSchemeCodeUnspsc190501.50462609'),
	'identifierSchemeCodeUnspsc190501.50462610': __('identifierSchemeCodeUnspsc190501.50462610'),
	'identifierSchemeCodeUnspsc190501.50462611': __('identifierSchemeCodeUnspsc190501.50462611'),
	'identifierSchemeCodeUnspsc190501.50462612': __('identifierSchemeCodeUnspsc190501.50462612'),
	'identifierSchemeCodeUnspsc190501.50462700': __('identifierSchemeCodeUnspsc190501.50462700'),
	'identifierSchemeCodeUnspsc190501.50462701': __('identifierSchemeCodeUnspsc190501.50462701'),
	'identifierSchemeCodeUnspsc190501.50462702': __('identifierSchemeCodeUnspsc190501.50462702'),
	'identifierSchemeCodeUnspsc190501.50462703': __('identifierSchemeCodeUnspsc190501.50462703'),
	'identifierSchemeCodeUnspsc190501.50462704': __('identifierSchemeCodeUnspsc190501.50462704'),
	'identifierSchemeCodeUnspsc190501.50462705': __('identifierSchemeCodeUnspsc190501.50462705'),
	'identifierSchemeCodeUnspsc190501.50462706': __('identifierSchemeCodeUnspsc190501.50462706'),
	'identifierSchemeCodeUnspsc190501.50462707': __('identifierSchemeCodeUnspsc190501.50462707'),
	'identifierSchemeCodeUnspsc190501.50462708': __('identifierSchemeCodeUnspsc190501.50462708'),
	'identifierSchemeCodeUnspsc190501.50462709': __('identifierSchemeCodeUnspsc190501.50462709'),
	'identifierSchemeCodeUnspsc190501.50462710': __('identifierSchemeCodeUnspsc190501.50462710'),
	'identifierSchemeCodeUnspsc190501.50462711': __('identifierSchemeCodeUnspsc190501.50462711'),
	'identifierSchemeCodeUnspsc190501.50462712': __('identifierSchemeCodeUnspsc190501.50462712'),
	'identifierSchemeCodeUnspsc190501.50462713': __('identifierSchemeCodeUnspsc190501.50462713'),
	'identifierSchemeCodeUnspsc190501.50462714': __('identifierSchemeCodeUnspsc190501.50462714'),
	'identifierSchemeCodeUnspsc190501.50462715': __('identifierSchemeCodeUnspsc190501.50462715'),
	'identifierSchemeCodeUnspsc190501.50462800': __('identifierSchemeCodeUnspsc190501.50462800'),
	'identifierSchemeCodeUnspsc190501.50462801': __('identifierSchemeCodeUnspsc190501.50462801'),
	'identifierSchemeCodeUnspsc190501.50462802': __('identifierSchemeCodeUnspsc190501.50462802'),
	'identifierSchemeCodeUnspsc190501.50462803': __('identifierSchemeCodeUnspsc190501.50462803'),
	'identifierSchemeCodeUnspsc190501.50462804': __('identifierSchemeCodeUnspsc190501.50462804'),
	'identifierSchemeCodeUnspsc190501.50462805': __('identifierSchemeCodeUnspsc190501.50462805'),
	'identifierSchemeCodeUnspsc190501.50462806': __('identifierSchemeCodeUnspsc190501.50462806'),
	'identifierSchemeCodeUnspsc190501.50462807': __('identifierSchemeCodeUnspsc190501.50462807'),
	'identifierSchemeCodeUnspsc190501.50462808': __('identifierSchemeCodeUnspsc190501.50462808'),
	'identifierSchemeCodeUnspsc190501.50462900': __('identifierSchemeCodeUnspsc190501.50462900'),
	'identifierSchemeCodeUnspsc190501.50462901': __('identifierSchemeCodeUnspsc190501.50462901'),
	'identifierSchemeCodeUnspsc190501.50462902': __('identifierSchemeCodeUnspsc190501.50462902'),
	'identifierSchemeCodeUnspsc190501.50462903': __('identifierSchemeCodeUnspsc190501.50462903'),
	'identifierSchemeCodeUnspsc190501.50462904': __('identifierSchemeCodeUnspsc190501.50462904'),
	'identifierSchemeCodeUnspsc190501.50462905': __('identifierSchemeCodeUnspsc190501.50462905'),
	'identifierSchemeCodeUnspsc190501.50462906': __('identifierSchemeCodeUnspsc190501.50462906'),
	'identifierSchemeCodeUnspsc190501.50462907': __('identifierSchemeCodeUnspsc190501.50462907'),
	'identifierSchemeCodeUnspsc190501.50462908': __('identifierSchemeCodeUnspsc190501.50462908'),
	'identifierSchemeCodeUnspsc190501.50462909': __('identifierSchemeCodeUnspsc190501.50462909'),
	'identifierSchemeCodeUnspsc190501.50462910': __('identifierSchemeCodeUnspsc190501.50462910'),
	'identifierSchemeCodeUnspsc190501.50462911': __('identifierSchemeCodeUnspsc190501.50462911'),
	'identifierSchemeCodeUnspsc190501.50463000': __('identifierSchemeCodeUnspsc190501.50463000'),
	'identifierSchemeCodeUnspsc190501.50463001': __('identifierSchemeCodeUnspsc190501.50463001'),
	'identifierSchemeCodeUnspsc190501.50463002': __('identifierSchemeCodeUnspsc190501.50463002'),
	'identifierSchemeCodeUnspsc190501.50463003': __('identifierSchemeCodeUnspsc190501.50463003'),
	'identifierSchemeCodeUnspsc190501.50463004': __('identifierSchemeCodeUnspsc190501.50463004'),
	'identifierSchemeCodeUnspsc190501.50463005': __('identifierSchemeCodeUnspsc190501.50463005'),
	'identifierSchemeCodeUnspsc190501.50463006': __('identifierSchemeCodeUnspsc190501.50463006'),
	'identifierSchemeCodeUnspsc190501.50463007': __('identifierSchemeCodeUnspsc190501.50463007'),
	'identifierSchemeCodeUnspsc190501.50463008': __('identifierSchemeCodeUnspsc190501.50463008'),
	'identifierSchemeCodeUnspsc190501.50463009': __('identifierSchemeCodeUnspsc190501.50463009'),
	'identifierSchemeCodeUnspsc190501.50463010': __('identifierSchemeCodeUnspsc190501.50463010'),
	'identifierSchemeCodeUnspsc190501.50463011': __('identifierSchemeCodeUnspsc190501.50463011'),
	'identifierSchemeCodeUnspsc190501.50463012': __('identifierSchemeCodeUnspsc190501.50463012'),
	'identifierSchemeCodeUnspsc190501.50463013': __('identifierSchemeCodeUnspsc190501.50463013'),
	'identifierSchemeCodeUnspsc190501.50463014': __('identifierSchemeCodeUnspsc190501.50463014'),
	'identifierSchemeCodeUnspsc190501.50463015': __('identifierSchemeCodeUnspsc190501.50463015'),
	'identifierSchemeCodeUnspsc190501.50463016': __('identifierSchemeCodeUnspsc190501.50463016'),
	'identifierSchemeCodeUnspsc190501.50463100': __('identifierSchemeCodeUnspsc190501.50463100'),
	'identifierSchemeCodeUnspsc190501.50463101': __('identifierSchemeCodeUnspsc190501.50463101'),
	'identifierSchemeCodeUnspsc190501.50463102': __('identifierSchemeCodeUnspsc190501.50463102'),
	'identifierSchemeCodeUnspsc190501.50463200': __('identifierSchemeCodeUnspsc190501.50463200'),
	'identifierSchemeCodeUnspsc190501.50463201': __('identifierSchemeCodeUnspsc190501.50463201'),
	'identifierSchemeCodeUnspsc190501.50463202': __('identifierSchemeCodeUnspsc190501.50463202'),
	'identifierSchemeCodeUnspsc190501.50463203': __('identifierSchemeCodeUnspsc190501.50463203'),
	'identifierSchemeCodeUnspsc190501.50463204': __('identifierSchemeCodeUnspsc190501.50463204'),
	'identifierSchemeCodeUnspsc190501.50463205': __('identifierSchemeCodeUnspsc190501.50463205'),
	'identifierSchemeCodeUnspsc190501.50463206': __('identifierSchemeCodeUnspsc190501.50463206'),
	'identifierSchemeCodeUnspsc190501.50463207': __('identifierSchemeCodeUnspsc190501.50463207'),
	'identifierSchemeCodeUnspsc190501.50463208': __('identifierSchemeCodeUnspsc190501.50463208'),
	'identifierSchemeCodeUnspsc190501.50463209': __('identifierSchemeCodeUnspsc190501.50463209'),
	'identifierSchemeCodeUnspsc190501.50463210': __('identifierSchemeCodeUnspsc190501.50463210'),
	'identifierSchemeCodeUnspsc190501.50463211': __('identifierSchemeCodeUnspsc190501.50463211'),
	'identifierSchemeCodeUnspsc190501.50463212': __('identifierSchemeCodeUnspsc190501.50463212'),
	'identifierSchemeCodeUnspsc190501.50463213': __('identifierSchemeCodeUnspsc190501.50463213'),
	'identifierSchemeCodeUnspsc190501.50463214': __('identifierSchemeCodeUnspsc190501.50463214'),
	'identifierSchemeCodeUnspsc190501.50463215': __('identifierSchemeCodeUnspsc190501.50463215'),
	'identifierSchemeCodeUnspsc190501.50463216': __('identifierSchemeCodeUnspsc190501.50463216'),
	'identifierSchemeCodeUnspsc190501.50463217': __('identifierSchemeCodeUnspsc190501.50463217'),
	'identifierSchemeCodeUnspsc190501.50463218': __('identifierSchemeCodeUnspsc190501.50463218'),
	'identifierSchemeCodeUnspsc190501.50463219': __('identifierSchemeCodeUnspsc190501.50463219'),
	'identifierSchemeCodeUnspsc190501.50463220': __('identifierSchemeCodeUnspsc190501.50463220'),
	'identifierSchemeCodeUnspsc190501.50463221': __('identifierSchemeCodeUnspsc190501.50463221'),
	'identifierSchemeCodeUnspsc190501.50463222': __('identifierSchemeCodeUnspsc190501.50463222'),
	'identifierSchemeCodeUnspsc190501.50463223': __('identifierSchemeCodeUnspsc190501.50463223'),
	'identifierSchemeCodeUnspsc190501.50463224': __('identifierSchemeCodeUnspsc190501.50463224'),
	'identifierSchemeCodeUnspsc190501.50463225': __('identifierSchemeCodeUnspsc190501.50463225'),
	'identifierSchemeCodeUnspsc190501.50463226': __('identifierSchemeCodeUnspsc190501.50463226'),
	'identifierSchemeCodeUnspsc190501.50463227': __('identifierSchemeCodeUnspsc190501.50463227'),
	'identifierSchemeCodeUnspsc190501.50463228': __('identifierSchemeCodeUnspsc190501.50463228'),
	'identifierSchemeCodeUnspsc190501.50463229': __('identifierSchemeCodeUnspsc190501.50463229'),
	'identifierSchemeCodeUnspsc190501.50463230': __('identifierSchemeCodeUnspsc190501.50463230'),
	'identifierSchemeCodeUnspsc190501.50463231': __('identifierSchemeCodeUnspsc190501.50463231'),
	'identifierSchemeCodeUnspsc190501.50463232': __('identifierSchemeCodeUnspsc190501.50463232'),
	'identifierSchemeCodeUnspsc190501.50463233': __('identifierSchemeCodeUnspsc190501.50463233'),
	'identifierSchemeCodeUnspsc190501.50463234': __('identifierSchemeCodeUnspsc190501.50463234'),
	'identifierSchemeCodeUnspsc190501.50463235': __('identifierSchemeCodeUnspsc190501.50463235'),
	'identifierSchemeCodeUnspsc190501.50463236': __('identifierSchemeCodeUnspsc190501.50463236'),
	'identifierSchemeCodeUnspsc190501.50463237': __('identifierSchemeCodeUnspsc190501.50463237'),
	'identifierSchemeCodeUnspsc190501.50463238': __('identifierSchemeCodeUnspsc190501.50463238'),
	'identifierSchemeCodeUnspsc190501.50463239': __('identifierSchemeCodeUnspsc190501.50463239'),
	'identifierSchemeCodeUnspsc190501.50463240': __('identifierSchemeCodeUnspsc190501.50463240'),
	'identifierSchemeCodeUnspsc190501.50463241': __('identifierSchemeCodeUnspsc190501.50463241'),
	'identifierSchemeCodeUnspsc190501.50463242': __('identifierSchemeCodeUnspsc190501.50463242'),
	'identifierSchemeCodeUnspsc190501.50463243': __('identifierSchemeCodeUnspsc190501.50463243'),
	'identifierSchemeCodeUnspsc190501.50463244': __('identifierSchemeCodeUnspsc190501.50463244'),
	'identifierSchemeCodeUnspsc190501.50463245': __('identifierSchemeCodeUnspsc190501.50463245'),
	'identifierSchemeCodeUnspsc190501.50463246': __('identifierSchemeCodeUnspsc190501.50463246'),
	'identifierSchemeCodeUnspsc190501.50463247': __('identifierSchemeCodeUnspsc190501.50463247'),
	'identifierSchemeCodeUnspsc190501.50463248': __('identifierSchemeCodeUnspsc190501.50463248'),
	'identifierSchemeCodeUnspsc190501.50463249': __('identifierSchemeCodeUnspsc190501.50463249'),
	'identifierSchemeCodeUnspsc190501.50463250': __('identifierSchemeCodeUnspsc190501.50463250'),
	'identifierSchemeCodeUnspsc190501.50463251': __('identifierSchemeCodeUnspsc190501.50463251'),
	'identifierSchemeCodeUnspsc190501.50463252': __('identifierSchemeCodeUnspsc190501.50463252'),
	'identifierSchemeCodeUnspsc190501.50463253': __('identifierSchemeCodeUnspsc190501.50463253'),
	'identifierSchemeCodeUnspsc190501.50463254': __('identifierSchemeCodeUnspsc190501.50463254'),
	'identifierSchemeCodeUnspsc190501.50463255': __('identifierSchemeCodeUnspsc190501.50463255'),
	'identifierSchemeCodeUnspsc190501.50463256': __('identifierSchemeCodeUnspsc190501.50463256'),
	'identifierSchemeCodeUnspsc190501.50463257': __('identifierSchemeCodeUnspsc190501.50463257'),
	'identifierSchemeCodeUnspsc190501.50463258': __('identifierSchemeCodeUnspsc190501.50463258'),
	'identifierSchemeCodeUnspsc190501.50463259': __('identifierSchemeCodeUnspsc190501.50463259'),
	'identifierSchemeCodeUnspsc190501.50463260': __('identifierSchemeCodeUnspsc190501.50463260'),
	'identifierSchemeCodeUnspsc190501.50463261': __('identifierSchemeCodeUnspsc190501.50463261'),
	'identifierSchemeCodeUnspsc190501.50463262': __('identifierSchemeCodeUnspsc190501.50463262'),
	'identifierSchemeCodeUnspsc190501.50463263': __('identifierSchemeCodeUnspsc190501.50463263'),
	'identifierSchemeCodeUnspsc190501.50463264': __('identifierSchemeCodeUnspsc190501.50463264'),
	'identifierSchemeCodeUnspsc190501.50463265': __('identifierSchemeCodeUnspsc190501.50463265'),
	'identifierSchemeCodeUnspsc190501.50463266': __('identifierSchemeCodeUnspsc190501.50463266'),
	'identifierSchemeCodeUnspsc190501.50463267': __('identifierSchemeCodeUnspsc190501.50463267'),
	'identifierSchemeCodeUnspsc190501.50463268': __('identifierSchemeCodeUnspsc190501.50463268'),
	'identifierSchemeCodeUnspsc190501.50463269': __('identifierSchemeCodeUnspsc190501.50463269'),
	'identifierSchemeCodeUnspsc190501.50463300': __('identifierSchemeCodeUnspsc190501.50463300'),
	'identifierSchemeCodeUnspsc190501.50463301': __('identifierSchemeCodeUnspsc190501.50463301'),
	'identifierSchemeCodeUnspsc190501.50463302': __('identifierSchemeCodeUnspsc190501.50463302'),
	'identifierSchemeCodeUnspsc190501.50463303': __('identifierSchemeCodeUnspsc190501.50463303'),
	'identifierSchemeCodeUnspsc190501.50463304': __('identifierSchemeCodeUnspsc190501.50463304'),
	'identifierSchemeCodeUnspsc190501.50463400': __('identifierSchemeCodeUnspsc190501.50463400'),
	'identifierSchemeCodeUnspsc190501.50463401': __('identifierSchemeCodeUnspsc190501.50463401'),
	'identifierSchemeCodeUnspsc190501.50463402': __('identifierSchemeCodeUnspsc190501.50463402'),
	'identifierSchemeCodeUnspsc190501.50463403': __('identifierSchemeCodeUnspsc190501.50463403'),
	'identifierSchemeCodeUnspsc190501.50463404': __('identifierSchemeCodeUnspsc190501.50463404'),
	'identifierSchemeCodeUnspsc190501.50463405': __('identifierSchemeCodeUnspsc190501.50463405'),
	'identifierSchemeCodeUnspsc190501.50463406': __('identifierSchemeCodeUnspsc190501.50463406'),
	'identifierSchemeCodeUnspsc190501.50463407': __('identifierSchemeCodeUnspsc190501.50463407'),
	'identifierSchemeCodeUnspsc190501.50463408': __('identifierSchemeCodeUnspsc190501.50463408'),
	'identifierSchemeCodeUnspsc190501.50463409': __('identifierSchemeCodeUnspsc190501.50463409'),
	'identifierSchemeCodeUnspsc190501.50463410': __('identifierSchemeCodeUnspsc190501.50463410'),
	'identifierSchemeCodeUnspsc190501.50463411': __('identifierSchemeCodeUnspsc190501.50463411'),
	'identifierSchemeCodeUnspsc190501.50463412': __('identifierSchemeCodeUnspsc190501.50463412'),
	'identifierSchemeCodeUnspsc190501.50463413': __('identifierSchemeCodeUnspsc190501.50463413'),
	'identifierSchemeCodeUnspsc190501.50463414': __('identifierSchemeCodeUnspsc190501.50463414'),
	'identifierSchemeCodeUnspsc190501.50463415': __('identifierSchemeCodeUnspsc190501.50463415'),
	'identifierSchemeCodeUnspsc190501.50463416': __('identifierSchemeCodeUnspsc190501.50463416'),
	'identifierSchemeCodeUnspsc190501.50463417': __('identifierSchemeCodeUnspsc190501.50463417'),
	'identifierSchemeCodeUnspsc190501.50463418': __('identifierSchemeCodeUnspsc190501.50463418'),
	'identifierSchemeCodeUnspsc190501.50463419': __('identifierSchemeCodeUnspsc190501.50463419'),
	'identifierSchemeCodeUnspsc190501.50463420': __('identifierSchemeCodeUnspsc190501.50463420'),
	'identifierSchemeCodeUnspsc190501.50463421': __('identifierSchemeCodeUnspsc190501.50463421'),
	'identifierSchemeCodeUnspsc190501.50463422': __('identifierSchemeCodeUnspsc190501.50463422'),
	'identifierSchemeCodeUnspsc190501.50463423': __('identifierSchemeCodeUnspsc190501.50463423'),
	'identifierSchemeCodeUnspsc190501.50463500': __('identifierSchemeCodeUnspsc190501.50463500'),
	'identifierSchemeCodeUnspsc190501.50463501': __('identifierSchemeCodeUnspsc190501.50463501'),
	'identifierSchemeCodeUnspsc190501.50463502': __('identifierSchemeCodeUnspsc190501.50463502'),
	'identifierSchemeCodeUnspsc190501.50463503': __('identifierSchemeCodeUnspsc190501.50463503'),
	'identifierSchemeCodeUnspsc190501.50463504': __('identifierSchemeCodeUnspsc190501.50463504'),
	'identifierSchemeCodeUnspsc190501.50463505': __('identifierSchemeCodeUnspsc190501.50463505'),
	'identifierSchemeCodeUnspsc190501.50463506': __('identifierSchemeCodeUnspsc190501.50463506'),
	'identifierSchemeCodeUnspsc190501.50463507': __('identifierSchemeCodeUnspsc190501.50463507'),
	'identifierSchemeCodeUnspsc190501.50463508': __('identifierSchemeCodeUnspsc190501.50463508'),
	'identifierSchemeCodeUnspsc190501.50463509': __('identifierSchemeCodeUnspsc190501.50463509'),
	'identifierSchemeCodeUnspsc190501.50463510': __('identifierSchemeCodeUnspsc190501.50463510'),
	'identifierSchemeCodeUnspsc190501.50463511': __('identifierSchemeCodeUnspsc190501.50463511'),
	'identifierSchemeCodeUnspsc190501.50463512': __('identifierSchemeCodeUnspsc190501.50463512'),
	'identifierSchemeCodeUnspsc190501.50463513': __('identifierSchemeCodeUnspsc190501.50463513'),
	'identifierSchemeCodeUnspsc190501.50463514': __('identifierSchemeCodeUnspsc190501.50463514'),
	'identifierSchemeCodeUnspsc190501.50463515': __('identifierSchemeCodeUnspsc190501.50463515'),
	'identifierSchemeCodeUnspsc190501.50463516': __('identifierSchemeCodeUnspsc190501.50463516'),
	'identifierSchemeCodeUnspsc190501.50463517': __('identifierSchemeCodeUnspsc190501.50463517'),
	'identifierSchemeCodeUnspsc190501.50463518': __('identifierSchemeCodeUnspsc190501.50463518'),
	'identifierSchemeCodeUnspsc190501.50463519': __('identifierSchemeCodeUnspsc190501.50463519'),
	'identifierSchemeCodeUnspsc190501.50463520': __('identifierSchemeCodeUnspsc190501.50463520'),
	'identifierSchemeCodeUnspsc190501.50463600': __('identifierSchemeCodeUnspsc190501.50463600'),
	'identifierSchemeCodeUnspsc190501.50463601': __('identifierSchemeCodeUnspsc190501.50463601'),
	'identifierSchemeCodeUnspsc190501.50463602': __('identifierSchemeCodeUnspsc190501.50463602'),
	'identifierSchemeCodeUnspsc190501.50463603': __('identifierSchemeCodeUnspsc190501.50463603'),
	'identifierSchemeCodeUnspsc190501.50463604': __('identifierSchemeCodeUnspsc190501.50463604'),
	'identifierSchemeCodeUnspsc190501.50463605': __('identifierSchemeCodeUnspsc190501.50463605'),
	'identifierSchemeCodeUnspsc190501.50463606': __('identifierSchemeCodeUnspsc190501.50463606'),
	'identifierSchemeCodeUnspsc190501.50463607': __('identifierSchemeCodeUnspsc190501.50463607'),
	'identifierSchemeCodeUnspsc190501.50463608': __('identifierSchemeCodeUnspsc190501.50463608'),
	'identifierSchemeCodeUnspsc190501.50463609': __('identifierSchemeCodeUnspsc190501.50463609'),
	'identifierSchemeCodeUnspsc190501.50463610': __('identifierSchemeCodeUnspsc190501.50463610'),
	'identifierSchemeCodeUnspsc190501.50463611': __('identifierSchemeCodeUnspsc190501.50463611'),
	'identifierSchemeCodeUnspsc190501.50463612': __('identifierSchemeCodeUnspsc190501.50463612'),
	'identifierSchemeCodeUnspsc190501.50463613': __('identifierSchemeCodeUnspsc190501.50463613'),
	'identifierSchemeCodeUnspsc190501.50463614': __('identifierSchemeCodeUnspsc190501.50463614'),
	'identifierSchemeCodeUnspsc190501.50463700': __('identifierSchemeCodeUnspsc190501.50463700'),
	'identifierSchemeCodeUnspsc190501.50463701': __('identifierSchemeCodeUnspsc190501.50463701'),
	'identifierSchemeCodeUnspsc190501.50463702': __('identifierSchemeCodeUnspsc190501.50463702'),
	'identifierSchemeCodeUnspsc190501.50463703': __('identifierSchemeCodeUnspsc190501.50463703'),
	'identifierSchemeCodeUnspsc190501.50463704': __('identifierSchemeCodeUnspsc190501.50463704'),
	'identifierSchemeCodeUnspsc190501.50463705': __('identifierSchemeCodeUnspsc190501.50463705'),
	'identifierSchemeCodeUnspsc190501.50463706': __('identifierSchemeCodeUnspsc190501.50463706'),
	'identifierSchemeCodeUnspsc190501.50463707': __('identifierSchemeCodeUnspsc190501.50463707'),
	'identifierSchemeCodeUnspsc190501.50463800': __('identifierSchemeCodeUnspsc190501.50463800'),
	'identifierSchemeCodeUnspsc190501.50463801': __('identifierSchemeCodeUnspsc190501.50463801'),
	'identifierSchemeCodeUnspsc190501.50463802': __('identifierSchemeCodeUnspsc190501.50463802'),
	'identifierSchemeCodeUnspsc190501.50463803': __('identifierSchemeCodeUnspsc190501.50463803'),
	'identifierSchemeCodeUnspsc190501.50463804': __('identifierSchemeCodeUnspsc190501.50463804'),
	'identifierSchemeCodeUnspsc190501.50463805': __('identifierSchemeCodeUnspsc190501.50463805'),
	'identifierSchemeCodeUnspsc190501.50463806': __('identifierSchemeCodeUnspsc190501.50463806'),
	'identifierSchemeCodeUnspsc190501.50463807': __('identifierSchemeCodeUnspsc190501.50463807'),
	'identifierSchemeCodeUnspsc190501.50463808': __('identifierSchemeCodeUnspsc190501.50463808'),
	'identifierSchemeCodeUnspsc190501.50463809': __('identifierSchemeCodeUnspsc190501.50463809'),
	'identifierSchemeCodeUnspsc190501.50463810': __('identifierSchemeCodeUnspsc190501.50463810'),
	'identifierSchemeCodeUnspsc190501.50463811': __('identifierSchemeCodeUnspsc190501.50463811'),
	'identifierSchemeCodeUnspsc190501.50463900': __('identifierSchemeCodeUnspsc190501.50463900'),
	'identifierSchemeCodeUnspsc190501.50463901': __('identifierSchemeCodeUnspsc190501.50463901'),
	'identifierSchemeCodeUnspsc190501.50463902': __('identifierSchemeCodeUnspsc190501.50463902'),
	'identifierSchemeCodeUnspsc190501.50463903': __('identifierSchemeCodeUnspsc190501.50463903'),
	'identifierSchemeCodeUnspsc190501.50463904': __('identifierSchemeCodeUnspsc190501.50463904'),
	'identifierSchemeCodeUnspsc190501.50463905': __('identifierSchemeCodeUnspsc190501.50463905'),
	'identifierSchemeCodeUnspsc190501.50463906': __('identifierSchemeCodeUnspsc190501.50463906'),
	'identifierSchemeCodeUnspsc190501.50463907': __('identifierSchemeCodeUnspsc190501.50463907'),
	'identifierSchemeCodeUnspsc190501.50463908': __('identifierSchemeCodeUnspsc190501.50463908'),
	'identifierSchemeCodeUnspsc190501.50463909': __('identifierSchemeCodeUnspsc190501.50463909'),
	'identifierSchemeCodeUnspsc190501.50463910': __('identifierSchemeCodeUnspsc190501.50463910'),
	'identifierSchemeCodeUnspsc190501.50463911': __('identifierSchemeCodeUnspsc190501.50463911'),
	'identifierSchemeCodeUnspsc190501.50464000': __('identifierSchemeCodeUnspsc190501.50464000'),
	'identifierSchemeCodeUnspsc190501.50464001': __('identifierSchemeCodeUnspsc190501.50464001'),
	'identifierSchemeCodeUnspsc190501.50464002': __('identifierSchemeCodeUnspsc190501.50464002'),
	'identifierSchemeCodeUnspsc190501.50464003': __('identifierSchemeCodeUnspsc190501.50464003'),
	'identifierSchemeCodeUnspsc190501.50464004': __('identifierSchemeCodeUnspsc190501.50464004'),
	'identifierSchemeCodeUnspsc190501.50464005': __('identifierSchemeCodeUnspsc190501.50464005'),
	'identifierSchemeCodeUnspsc190501.50464100': __('identifierSchemeCodeUnspsc190501.50464100'),
	'identifierSchemeCodeUnspsc190501.50464101': __('identifierSchemeCodeUnspsc190501.50464101'),
	'identifierSchemeCodeUnspsc190501.50464102': __('identifierSchemeCodeUnspsc190501.50464102'),
	'identifierSchemeCodeUnspsc190501.50464103': __('identifierSchemeCodeUnspsc190501.50464103'),
	'identifierSchemeCodeUnspsc190501.50464104': __('identifierSchemeCodeUnspsc190501.50464104'),
	'identifierSchemeCodeUnspsc190501.50464105': __('identifierSchemeCodeUnspsc190501.50464105'),
	'identifierSchemeCodeUnspsc190501.50464106': __('identifierSchemeCodeUnspsc190501.50464106'),
	'identifierSchemeCodeUnspsc190501.50464107': __('identifierSchemeCodeUnspsc190501.50464107'),
	'identifierSchemeCodeUnspsc190501.50464108': __('identifierSchemeCodeUnspsc190501.50464108'),
	'identifierSchemeCodeUnspsc190501.50464109': __('identifierSchemeCodeUnspsc190501.50464109'),
	'identifierSchemeCodeUnspsc190501.50464110': __('identifierSchemeCodeUnspsc190501.50464110'),
	'identifierSchemeCodeUnspsc190501.50464111': __('identifierSchemeCodeUnspsc190501.50464111'),
	'identifierSchemeCodeUnspsc190501.50464112': __('identifierSchemeCodeUnspsc190501.50464112'),
	'identifierSchemeCodeUnspsc190501.50464113': __('identifierSchemeCodeUnspsc190501.50464113'),
	'identifierSchemeCodeUnspsc190501.50464114': __('identifierSchemeCodeUnspsc190501.50464114'),
	'identifierSchemeCodeUnspsc190501.50464115': __('identifierSchemeCodeUnspsc190501.50464115'),
	'identifierSchemeCodeUnspsc190501.50464116': __('identifierSchemeCodeUnspsc190501.50464116'),
	'identifierSchemeCodeUnspsc190501.50464117': __('identifierSchemeCodeUnspsc190501.50464117'),
	'identifierSchemeCodeUnspsc190501.50464118': __('identifierSchemeCodeUnspsc190501.50464118'),
	'identifierSchemeCodeUnspsc190501.50464119': __('identifierSchemeCodeUnspsc190501.50464119'),
	'identifierSchemeCodeUnspsc190501.50464120': __('identifierSchemeCodeUnspsc190501.50464120'),
	'identifierSchemeCodeUnspsc190501.50464121': __('identifierSchemeCodeUnspsc190501.50464121'),
	'identifierSchemeCodeUnspsc190501.50464122': __('identifierSchemeCodeUnspsc190501.50464122'),
	'identifierSchemeCodeUnspsc190501.50464123': __('identifierSchemeCodeUnspsc190501.50464123'),
	'identifierSchemeCodeUnspsc190501.50464124': __('identifierSchemeCodeUnspsc190501.50464124'),
	'identifierSchemeCodeUnspsc190501.50464125': __('identifierSchemeCodeUnspsc190501.50464125'),
	'identifierSchemeCodeUnspsc190501.50464126': __('identifierSchemeCodeUnspsc190501.50464126'),
	'identifierSchemeCodeUnspsc190501.50464127': __('identifierSchemeCodeUnspsc190501.50464127'),
	'identifierSchemeCodeUnspsc190501.50464200': __('identifierSchemeCodeUnspsc190501.50464200'),
	'identifierSchemeCodeUnspsc190501.50464201': __('identifierSchemeCodeUnspsc190501.50464201'),
	'identifierSchemeCodeUnspsc190501.50464202': __('identifierSchemeCodeUnspsc190501.50464202'),
	'identifierSchemeCodeUnspsc190501.50464300': __('identifierSchemeCodeUnspsc190501.50464300'),
	'identifierSchemeCodeUnspsc190501.50464301': __('identifierSchemeCodeUnspsc190501.50464301'),
	'identifierSchemeCodeUnspsc190501.50464302': __('identifierSchemeCodeUnspsc190501.50464302'),
	'identifierSchemeCodeUnspsc190501.50464303': __('identifierSchemeCodeUnspsc190501.50464303'),
	'identifierSchemeCodeUnspsc190501.50464304': __('identifierSchemeCodeUnspsc190501.50464304'),
	'identifierSchemeCodeUnspsc190501.50464305': __('identifierSchemeCodeUnspsc190501.50464305'),
	'identifierSchemeCodeUnspsc190501.50464306': __('identifierSchemeCodeUnspsc190501.50464306'),
	'identifierSchemeCodeUnspsc190501.50464400': __('identifierSchemeCodeUnspsc190501.50464400'),
	'identifierSchemeCodeUnspsc190501.50464401': __('identifierSchemeCodeUnspsc190501.50464401'),
	'identifierSchemeCodeUnspsc190501.50464402': __('identifierSchemeCodeUnspsc190501.50464402'),
	'identifierSchemeCodeUnspsc190501.50464403': __('identifierSchemeCodeUnspsc190501.50464403'),
	'identifierSchemeCodeUnspsc190501.50464404': __('identifierSchemeCodeUnspsc190501.50464404'),
	'identifierSchemeCodeUnspsc190501.50464405': __('identifierSchemeCodeUnspsc190501.50464405'),
	'identifierSchemeCodeUnspsc190501.50464406': __('identifierSchemeCodeUnspsc190501.50464406'),
	'identifierSchemeCodeUnspsc190501.50464500': __('identifierSchemeCodeUnspsc190501.50464500'),
	'identifierSchemeCodeUnspsc190501.50464501': __('identifierSchemeCodeUnspsc190501.50464501'),
	'identifierSchemeCodeUnspsc190501.50464502': __('identifierSchemeCodeUnspsc190501.50464502'),
	'identifierSchemeCodeUnspsc190501.50464503': __('identifierSchemeCodeUnspsc190501.50464503'),
	'identifierSchemeCodeUnspsc190501.50464504': __('identifierSchemeCodeUnspsc190501.50464504'),
	'identifierSchemeCodeUnspsc190501.50464505': __('identifierSchemeCodeUnspsc190501.50464505'),
	'identifierSchemeCodeUnspsc190501.50464506': __('identifierSchemeCodeUnspsc190501.50464506'),
	'identifierSchemeCodeUnspsc190501.50464507': __('identifierSchemeCodeUnspsc190501.50464507'),
	'identifierSchemeCodeUnspsc190501.50464508': __('identifierSchemeCodeUnspsc190501.50464508'),
	'identifierSchemeCodeUnspsc190501.50464600': __('identifierSchemeCodeUnspsc190501.50464600'),
	'identifierSchemeCodeUnspsc190501.50464601': __('identifierSchemeCodeUnspsc190501.50464601'),
	'identifierSchemeCodeUnspsc190501.50464602': __('identifierSchemeCodeUnspsc190501.50464602'),
	'identifierSchemeCodeUnspsc190501.50464603': __('identifierSchemeCodeUnspsc190501.50464603'),
	'identifierSchemeCodeUnspsc190501.50464604': __('identifierSchemeCodeUnspsc190501.50464604'),
	'identifierSchemeCodeUnspsc190501.50464605': __('identifierSchemeCodeUnspsc190501.50464605'),
	'identifierSchemeCodeUnspsc190501.50464606': __('identifierSchemeCodeUnspsc190501.50464606'),
	'identifierSchemeCodeUnspsc190501.50464607': __('identifierSchemeCodeUnspsc190501.50464607'),
	'identifierSchemeCodeUnspsc190501.50464608': __('identifierSchemeCodeUnspsc190501.50464608'),
	'identifierSchemeCodeUnspsc190501.50464609': __('identifierSchemeCodeUnspsc190501.50464609'),
	'identifierSchemeCodeUnspsc190501.50464610': __('identifierSchemeCodeUnspsc190501.50464610'),
	'identifierSchemeCodeUnspsc190501.50464611': __('identifierSchemeCodeUnspsc190501.50464611'),
	'identifierSchemeCodeUnspsc190501.50464612': __('identifierSchemeCodeUnspsc190501.50464612'),
	'identifierSchemeCodeUnspsc190501.50464613': __('identifierSchemeCodeUnspsc190501.50464613'),
	'identifierSchemeCodeUnspsc190501.50464614': __('identifierSchemeCodeUnspsc190501.50464614'),
	'identifierSchemeCodeUnspsc190501.50464615': __('identifierSchemeCodeUnspsc190501.50464615'),
	'identifierSchemeCodeUnspsc190501.50464616': __('identifierSchemeCodeUnspsc190501.50464616'),
	'identifierSchemeCodeUnspsc190501.50464617': __('identifierSchemeCodeUnspsc190501.50464617'),
	'identifierSchemeCodeUnspsc190501.50464618': __('identifierSchemeCodeUnspsc190501.50464618'),
	'identifierSchemeCodeUnspsc190501.50464619': __('identifierSchemeCodeUnspsc190501.50464619'),
	'identifierSchemeCodeUnspsc190501.50464620': __('identifierSchemeCodeUnspsc190501.50464620'),
	'identifierSchemeCodeUnspsc190501.50464621': __('identifierSchemeCodeUnspsc190501.50464621'),
	'identifierSchemeCodeUnspsc190501.50464622': __('identifierSchemeCodeUnspsc190501.50464622'),
	'identifierSchemeCodeUnspsc190501.50464623': __('identifierSchemeCodeUnspsc190501.50464623'),
	'identifierSchemeCodeUnspsc190501.50464700': __('identifierSchemeCodeUnspsc190501.50464700'),
	'identifierSchemeCodeUnspsc190501.50464701': __('identifierSchemeCodeUnspsc190501.50464701'),
	'identifierSchemeCodeUnspsc190501.50464702': __('identifierSchemeCodeUnspsc190501.50464702'),
	'identifierSchemeCodeUnspsc190501.50464703': __('identifierSchemeCodeUnspsc190501.50464703'),
	'identifierSchemeCodeUnspsc190501.50464704': __('identifierSchemeCodeUnspsc190501.50464704'),
	'identifierSchemeCodeUnspsc190501.50464705': __('identifierSchemeCodeUnspsc190501.50464705'),
	'identifierSchemeCodeUnspsc190501.50464706': __('identifierSchemeCodeUnspsc190501.50464706'),
	'identifierSchemeCodeUnspsc190501.50464800': __('identifierSchemeCodeUnspsc190501.50464800'),
	'identifierSchemeCodeUnspsc190501.50464801': __('identifierSchemeCodeUnspsc190501.50464801'),
	'identifierSchemeCodeUnspsc190501.50464802': __('identifierSchemeCodeUnspsc190501.50464802'),
	'identifierSchemeCodeUnspsc190501.50464803': __('identifierSchemeCodeUnspsc190501.50464803'),
	'identifierSchemeCodeUnspsc190501.50464804': __('identifierSchemeCodeUnspsc190501.50464804'),
	'identifierSchemeCodeUnspsc190501.50464805': __('identifierSchemeCodeUnspsc190501.50464805'),
	'identifierSchemeCodeUnspsc190501.50464806': __('identifierSchemeCodeUnspsc190501.50464806'),
	'identifierSchemeCodeUnspsc190501.50464807': __('identifierSchemeCodeUnspsc190501.50464807'),
	'identifierSchemeCodeUnspsc190501.50464808': __('identifierSchemeCodeUnspsc190501.50464808'),
	'identifierSchemeCodeUnspsc190501.50464809': __('identifierSchemeCodeUnspsc190501.50464809'),
	'identifierSchemeCodeUnspsc190501.50464810': __('identifierSchemeCodeUnspsc190501.50464810'),
	'identifierSchemeCodeUnspsc190501.50464811': __('identifierSchemeCodeUnspsc190501.50464811'),
	'identifierSchemeCodeUnspsc190501.50464812': __('identifierSchemeCodeUnspsc190501.50464812'),
	'identifierSchemeCodeUnspsc190501.50464813': __('identifierSchemeCodeUnspsc190501.50464813'),
	'identifierSchemeCodeUnspsc190501.50464814': __('identifierSchemeCodeUnspsc190501.50464814'),
	'identifierSchemeCodeUnspsc190501.50464815': __('identifierSchemeCodeUnspsc190501.50464815'),
	'identifierSchemeCodeUnspsc190501.50464816': __('identifierSchemeCodeUnspsc190501.50464816'),
	'identifierSchemeCodeUnspsc190501.50464817': __('identifierSchemeCodeUnspsc190501.50464817'),
	'identifierSchemeCodeUnspsc190501.50464818': __('identifierSchemeCodeUnspsc190501.50464818'),
	'identifierSchemeCodeUnspsc190501.50464819': __('identifierSchemeCodeUnspsc190501.50464819'),
	'identifierSchemeCodeUnspsc190501.50464820': __('identifierSchemeCodeUnspsc190501.50464820'),
	'identifierSchemeCodeUnspsc190501.50464821': __('identifierSchemeCodeUnspsc190501.50464821'),
	'identifierSchemeCodeUnspsc190501.50464900': __('identifierSchemeCodeUnspsc190501.50464900'),
	'identifierSchemeCodeUnspsc190501.50464901': __('identifierSchemeCodeUnspsc190501.50464901'),
	'identifierSchemeCodeUnspsc190501.50464902': __('identifierSchemeCodeUnspsc190501.50464902'),
	'identifierSchemeCodeUnspsc190501.50464903': __('identifierSchemeCodeUnspsc190501.50464903'),
	'identifierSchemeCodeUnspsc190501.50464904': __('identifierSchemeCodeUnspsc190501.50464904'),
	'identifierSchemeCodeUnspsc190501.50464905': __('identifierSchemeCodeUnspsc190501.50464905'),
	'identifierSchemeCodeUnspsc190501.50464906': __('identifierSchemeCodeUnspsc190501.50464906'),
	'identifierSchemeCodeUnspsc190501.50465000': __('identifierSchemeCodeUnspsc190501.50465000'),
	'identifierSchemeCodeUnspsc190501.50465001': __('identifierSchemeCodeUnspsc190501.50465001'),
	'identifierSchemeCodeUnspsc190501.50465002': __('identifierSchemeCodeUnspsc190501.50465002'),
	'identifierSchemeCodeUnspsc190501.50465003': __('identifierSchemeCodeUnspsc190501.50465003'),
	'identifierSchemeCodeUnspsc190501.50465004': __('identifierSchemeCodeUnspsc190501.50465004'),
	'identifierSchemeCodeUnspsc190501.50465100': __('identifierSchemeCodeUnspsc190501.50465100'),
	'identifierSchemeCodeUnspsc190501.50465101': __('identifierSchemeCodeUnspsc190501.50465101'),
	'identifierSchemeCodeUnspsc190501.50465102': __('identifierSchemeCodeUnspsc190501.50465102'),
	'identifierSchemeCodeUnspsc190501.50465103': __('identifierSchemeCodeUnspsc190501.50465103'),
	'identifierSchemeCodeUnspsc190501.50465104': __('identifierSchemeCodeUnspsc190501.50465104'),
	'identifierSchemeCodeUnspsc190501.50465105': __('identifierSchemeCodeUnspsc190501.50465105'),
	'identifierSchemeCodeUnspsc190501.50465106': __('identifierSchemeCodeUnspsc190501.50465106'),
	'identifierSchemeCodeUnspsc190501.50465107': __('identifierSchemeCodeUnspsc190501.50465107'),
	'identifierSchemeCodeUnspsc190501.50465200': __('identifierSchemeCodeUnspsc190501.50465200'),
	'identifierSchemeCodeUnspsc190501.50465201': __('identifierSchemeCodeUnspsc190501.50465201'),
	'identifierSchemeCodeUnspsc190501.50465202': __('identifierSchemeCodeUnspsc190501.50465202'),
	'identifierSchemeCodeUnspsc190501.50465203': __('identifierSchemeCodeUnspsc190501.50465203'),
	'identifierSchemeCodeUnspsc190501.50465204': __('identifierSchemeCodeUnspsc190501.50465204'),
	'identifierSchemeCodeUnspsc190501.50465205': __('identifierSchemeCodeUnspsc190501.50465205'),
	'identifierSchemeCodeUnspsc190501.50465206': __('identifierSchemeCodeUnspsc190501.50465206'),
	'identifierSchemeCodeUnspsc190501.50465207': __('identifierSchemeCodeUnspsc190501.50465207'),
	'identifierSchemeCodeUnspsc190501.50465208': __('identifierSchemeCodeUnspsc190501.50465208'),
	'identifierSchemeCodeUnspsc190501.50465209': __('identifierSchemeCodeUnspsc190501.50465209'),
	'identifierSchemeCodeUnspsc190501.50465210': __('identifierSchemeCodeUnspsc190501.50465210'),
	'identifierSchemeCodeUnspsc190501.50465211': __('identifierSchemeCodeUnspsc190501.50465211'),
	'identifierSchemeCodeUnspsc190501.50465212': __('identifierSchemeCodeUnspsc190501.50465212'),
	'identifierSchemeCodeUnspsc190501.50465213': __('identifierSchemeCodeUnspsc190501.50465213'),
	'identifierSchemeCodeUnspsc190501.50465214': __('identifierSchemeCodeUnspsc190501.50465214'),
	'identifierSchemeCodeUnspsc190501.50465215': __('identifierSchemeCodeUnspsc190501.50465215'),
	'identifierSchemeCodeUnspsc190501.50465216': __('identifierSchemeCodeUnspsc190501.50465216'),
	'identifierSchemeCodeUnspsc190501.50465217': __('identifierSchemeCodeUnspsc190501.50465217'),
	'identifierSchemeCodeUnspsc190501.50465218': __('identifierSchemeCodeUnspsc190501.50465218'),
	'identifierSchemeCodeUnspsc190501.50465219': __('identifierSchemeCodeUnspsc190501.50465219'),
	'identifierSchemeCodeUnspsc190501.50465220': __('identifierSchemeCodeUnspsc190501.50465220'),
	'identifierSchemeCodeUnspsc190501.50465221': __('identifierSchemeCodeUnspsc190501.50465221'),
	'identifierSchemeCodeUnspsc190501.50465300': __('identifierSchemeCodeUnspsc190501.50465300'),
	'identifierSchemeCodeUnspsc190501.50465301': __('identifierSchemeCodeUnspsc190501.50465301'),
	'identifierSchemeCodeUnspsc190501.50465302': __('identifierSchemeCodeUnspsc190501.50465302'),
	'identifierSchemeCodeUnspsc190501.50465303': __('identifierSchemeCodeUnspsc190501.50465303'),
	'identifierSchemeCodeUnspsc190501.50465304': __('identifierSchemeCodeUnspsc190501.50465304'),
	'identifierSchemeCodeUnspsc190501.50465305': __('identifierSchemeCodeUnspsc190501.50465305'),
	'identifierSchemeCodeUnspsc190501.50465306': __('identifierSchemeCodeUnspsc190501.50465306'),
	'identifierSchemeCodeUnspsc190501.50465400': __('identifierSchemeCodeUnspsc190501.50465400'),
	'identifierSchemeCodeUnspsc190501.50465401': __('identifierSchemeCodeUnspsc190501.50465401'),
	'identifierSchemeCodeUnspsc190501.50465402': __('identifierSchemeCodeUnspsc190501.50465402'),
	'identifierSchemeCodeUnspsc190501.50465403': __('identifierSchemeCodeUnspsc190501.50465403'),
	'identifierSchemeCodeUnspsc190501.50465404': __('identifierSchemeCodeUnspsc190501.50465404'),
	'identifierSchemeCodeUnspsc190501.50465405': __('identifierSchemeCodeUnspsc190501.50465405'),
	'identifierSchemeCodeUnspsc190501.50465406': __('identifierSchemeCodeUnspsc190501.50465406'),
	'identifierSchemeCodeUnspsc190501.50465500': __('identifierSchemeCodeUnspsc190501.50465500'),
	'identifierSchemeCodeUnspsc190501.50465501': __('identifierSchemeCodeUnspsc190501.50465501'),
	'identifierSchemeCodeUnspsc190501.50465502': __('identifierSchemeCodeUnspsc190501.50465502'),
	'identifierSchemeCodeUnspsc190501.50465503': __('identifierSchemeCodeUnspsc190501.50465503'),
	'identifierSchemeCodeUnspsc190501.50465504': __('identifierSchemeCodeUnspsc190501.50465504'),
	'identifierSchemeCodeUnspsc190501.50465505': __('identifierSchemeCodeUnspsc190501.50465505'),
	'identifierSchemeCodeUnspsc190501.50465506': __('identifierSchemeCodeUnspsc190501.50465506'),
	'identifierSchemeCodeUnspsc190501.50465507': __('identifierSchemeCodeUnspsc190501.50465507'),
	'identifierSchemeCodeUnspsc190501.50465508': __('identifierSchemeCodeUnspsc190501.50465508'),
	'identifierSchemeCodeUnspsc190501.50465509': __('identifierSchemeCodeUnspsc190501.50465509'),
	'identifierSchemeCodeUnspsc190501.50465510': __('identifierSchemeCodeUnspsc190501.50465510'),
	'identifierSchemeCodeUnspsc190501.50465511': __('identifierSchemeCodeUnspsc190501.50465511'),
	'identifierSchemeCodeUnspsc190501.50465512': __('identifierSchemeCodeUnspsc190501.50465512'),
	'identifierSchemeCodeUnspsc190501.50465513': __('identifierSchemeCodeUnspsc190501.50465513'),
	'identifierSchemeCodeUnspsc190501.50465514': __('identifierSchemeCodeUnspsc190501.50465514'),
	'identifierSchemeCodeUnspsc190501.50465515': __('identifierSchemeCodeUnspsc190501.50465515'),
	'identifierSchemeCodeUnspsc190501.50465516': __('identifierSchemeCodeUnspsc190501.50465516'),
	'identifierSchemeCodeUnspsc190501.50465517': __('identifierSchemeCodeUnspsc190501.50465517'),
	'identifierSchemeCodeUnspsc190501.50465518': __('identifierSchemeCodeUnspsc190501.50465518'),
	'identifierSchemeCodeUnspsc190501.50465519': __('identifierSchemeCodeUnspsc190501.50465519'),
	'identifierSchemeCodeUnspsc190501.50465520': __('identifierSchemeCodeUnspsc190501.50465520'),
	'identifierSchemeCodeUnspsc190501.50465521': __('identifierSchemeCodeUnspsc190501.50465521'),
	'identifierSchemeCodeUnspsc190501.50465522': __('identifierSchemeCodeUnspsc190501.50465522'),
	'identifierSchemeCodeUnspsc190501.50465523': __('identifierSchemeCodeUnspsc190501.50465523'),
	'identifierSchemeCodeUnspsc190501.50465524': __('identifierSchemeCodeUnspsc190501.50465524'),
	'identifierSchemeCodeUnspsc190501.50465525': __('identifierSchemeCodeUnspsc190501.50465525'),
	'identifierSchemeCodeUnspsc190501.50465526': __('identifierSchemeCodeUnspsc190501.50465526'),
	'identifierSchemeCodeUnspsc190501.50465527': __('identifierSchemeCodeUnspsc190501.50465527'),
	'identifierSchemeCodeUnspsc190501.50465528': __('identifierSchemeCodeUnspsc190501.50465528'),
	'identifierSchemeCodeUnspsc190501.50465529': __('identifierSchemeCodeUnspsc190501.50465529'),
	'identifierSchemeCodeUnspsc190501.50465530': __('identifierSchemeCodeUnspsc190501.50465530'),
	'identifierSchemeCodeUnspsc190501.50465531': __('identifierSchemeCodeUnspsc190501.50465531'),
	'identifierSchemeCodeUnspsc190501.50465532': __('identifierSchemeCodeUnspsc190501.50465532'),
	'identifierSchemeCodeUnspsc190501.50465533': __('identifierSchemeCodeUnspsc190501.50465533'),
	'identifierSchemeCodeUnspsc190501.50465534': __('identifierSchemeCodeUnspsc190501.50465534'),
	'identifierSchemeCodeUnspsc190501.50465535': __('identifierSchemeCodeUnspsc190501.50465535'),
	'identifierSchemeCodeUnspsc190501.50465536': __('identifierSchemeCodeUnspsc190501.50465536'),
	'identifierSchemeCodeUnspsc190501.50465537': __('identifierSchemeCodeUnspsc190501.50465537'),
	'identifierSchemeCodeUnspsc190501.50465600': __('identifierSchemeCodeUnspsc190501.50465600'),
	'identifierSchemeCodeUnspsc190501.50465601': __('identifierSchemeCodeUnspsc190501.50465601'),
	'identifierSchemeCodeUnspsc190501.50465602': __('identifierSchemeCodeUnspsc190501.50465602'),
	'identifierSchemeCodeUnspsc190501.50465603': __('identifierSchemeCodeUnspsc190501.50465603'),
	'identifierSchemeCodeUnspsc190501.50465604': __('identifierSchemeCodeUnspsc190501.50465604'),
	'identifierSchemeCodeUnspsc190501.50465605': __('identifierSchemeCodeUnspsc190501.50465605'),
	'identifierSchemeCodeUnspsc190501.50465606': __('identifierSchemeCodeUnspsc190501.50465606'),
	'identifierSchemeCodeUnspsc190501.50465607': __('identifierSchemeCodeUnspsc190501.50465607'),
	'identifierSchemeCodeUnspsc190501.50465608': __('identifierSchemeCodeUnspsc190501.50465608'),
	'identifierSchemeCodeUnspsc190501.50465700': __('identifierSchemeCodeUnspsc190501.50465700'),
	'identifierSchemeCodeUnspsc190501.50465701': __('identifierSchemeCodeUnspsc190501.50465701'),
	'identifierSchemeCodeUnspsc190501.50465702': __('identifierSchemeCodeUnspsc190501.50465702'),
	'identifierSchemeCodeUnspsc190501.50465703': __('identifierSchemeCodeUnspsc190501.50465703'),
	'identifierSchemeCodeUnspsc190501.50465704': __('identifierSchemeCodeUnspsc190501.50465704'),
	'identifierSchemeCodeUnspsc190501.50465800': __('identifierSchemeCodeUnspsc190501.50465800'),
	'identifierSchemeCodeUnspsc190501.50465801': __('identifierSchemeCodeUnspsc190501.50465801'),
	'identifierSchemeCodeUnspsc190501.50465802': __('identifierSchemeCodeUnspsc190501.50465802'),
	'identifierSchemeCodeUnspsc190501.50465803': __('identifierSchemeCodeUnspsc190501.50465803'),
	'identifierSchemeCodeUnspsc190501.50465804': __('identifierSchemeCodeUnspsc190501.50465804'),
	'identifierSchemeCodeUnspsc190501.50465805': __('identifierSchemeCodeUnspsc190501.50465805'),
	'identifierSchemeCodeUnspsc190501.50465806': __('identifierSchemeCodeUnspsc190501.50465806'),
	'identifierSchemeCodeUnspsc190501.50465807': __('identifierSchemeCodeUnspsc190501.50465807'),
	'identifierSchemeCodeUnspsc190501.50465808': __('identifierSchemeCodeUnspsc190501.50465808'),
	'identifierSchemeCodeUnspsc190501.50465809': __('identifierSchemeCodeUnspsc190501.50465809'),
	'identifierSchemeCodeUnspsc190501.50465810': __('identifierSchemeCodeUnspsc190501.50465810'),
	'identifierSchemeCodeUnspsc190501.50465811': __('identifierSchemeCodeUnspsc190501.50465811'),
	'identifierSchemeCodeUnspsc190501.50465812': __('identifierSchemeCodeUnspsc190501.50465812'),
	'identifierSchemeCodeUnspsc190501.50465813': __('identifierSchemeCodeUnspsc190501.50465813'),
	'identifierSchemeCodeUnspsc190501.50465814': __('identifierSchemeCodeUnspsc190501.50465814'),
	'identifierSchemeCodeUnspsc190501.50465815': __('identifierSchemeCodeUnspsc190501.50465815'),
	'identifierSchemeCodeUnspsc190501.50465816': __('identifierSchemeCodeUnspsc190501.50465816'),
	'identifierSchemeCodeUnspsc190501.50465900': __('identifierSchemeCodeUnspsc190501.50465900'),
	'identifierSchemeCodeUnspsc190501.50465901': __('identifierSchemeCodeUnspsc190501.50465901'),
	'identifierSchemeCodeUnspsc190501.50465902': __('identifierSchemeCodeUnspsc190501.50465902'),
	'identifierSchemeCodeUnspsc190501.50465903': __('identifierSchemeCodeUnspsc190501.50465903'),
	'identifierSchemeCodeUnspsc190501.50465904': __('identifierSchemeCodeUnspsc190501.50465904'),
	'identifierSchemeCodeUnspsc190501.50465905': __('identifierSchemeCodeUnspsc190501.50465905'),
	'identifierSchemeCodeUnspsc190501.50465906': __('identifierSchemeCodeUnspsc190501.50465906'),
	'identifierSchemeCodeUnspsc190501.50465907': __('identifierSchemeCodeUnspsc190501.50465907'),
	'identifierSchemeCodeUnspsc190501.50465908': __('identifierSchemeCodeUnspsc190501.50465908'),
	'identifierSchemeCodeUnspsc190501.50465909': __('identifierSchemeCodeUnspsc190501.50465909'),
	'identifierSchemeCodeUnspsc190501.50465910': __('identifierSchemeCodeUnspsc190501.50465910'),
	'identifierSchemeCodeUnspsc190501.50465911': __('identifierSchemeCodeUnspsc190501.50465911'),
	'identifierSchemeCodeUnspsc190501.50466000': __('identifierSchemeCodeUnspsc190501.50466000'),
	'identifierSchemeCodeUnspsc190501.50466001': __('identifierSchemeCodeUnspsc190501.50466001'),
	'identifierSchemeCodeUnspsc190501.50466002': __('identifierSchemeCodeUnspsc190501.50466002'),
	'identifierSchemeCodeUnspsc190501.50466003': __('identifierSchemeCodeUnspsc190501.50466003'),
	'identifierSchemeCodeUnspsc190501.50466004': __('identifierSchemeCodeUnspsc190501.50466004'),
	'identifierSchemeCodeUnspsc190501.50466100': __('identifierSchemeCodeUnspsc190501.50466100'),
	'identifierSchemeCodeUnspsc190501.50466101': __('identifierSchemeCodeUnspsc190501.50466101'),
	'identifierSchemeCodeUnspsc190501.50466102': __('identifierSchemeCodeUnspsc190501.50466102'),
	'identifierSchemeCodeUnspsc190501.50466103': __('identifierSchemeCodeUnspsc190501.50466103'),
	'identifierSchemeCodeUnspsc190501.50466104': __('identifierSchemeCodeUnspsc190501.50466104'),
	'identifierSchemeCodeUnspsc190501.50466105': __('identifierSchemeCodeUnspsc190501.50466105'),
	'identifierSchemeCodeUnspsc190501.50466106': __('identifierSchemeCodeUnspsc190501.50466106'),
	'identifierSchemeCodeUnspsc190501.50466107': __('identifierSchemeCodeUnspsc190501.50466107'),
	'identifierSchemeCodeUnspsc190501.50466108': __('identifierSchemeCodeUnspsc190501.50466108'),
	'identifierSchemeCodeUnspsc190501.50466109': __('identifierSchemeCodeUnspsc190501.50466109'),
	'identifierSchemeCodeUnspsc190501.50466110': __('identifierSchemeCodeUnspsc190501.50466110'),
	'identifierSchemeCodeUnspsc190501.50466111': __('identifierSchemeCodeUnspsc190501.50466111'),
	'identifierSchemeCodeUnspsc190501.50466112': __('identifierSchemeCodeUnspsc190501.50466112'),
	'identifierSchemeCodeUnspsc190501.50466113': __('identifierSchemeCodeUnspsc190501.50466113'),
	'identifierSchemeCodeUnspsc190501.50466114': __('identifierSchemeCodeUnspsc190501.50466114'),
	'identifierSchemeCodeUnspsc190501.50466115': __('identifierSchemeCodeUnspsc190501.50466115'),
	'identifierSchemeCodeUnspsc190501.50466116': __('identifierSchemeCodeUnspsc190501.50466116'),
	'identifierSchemeCodeUnspsc190501.50466200': __('identifierSchemeCodeUnspsc190501.50466200'),
	'identifierSchemeCodeUnspsc190501.50466201': __('identifierSchemeCodeUnspsc190501.50466201'),
	'identifierSchemeCodeUnspsc190501.50466202': __('identifierSchemeCodeUnspsc190501.50466202'),
	'identifierSchemeCodeUnspsc190501.50466203': __('identifierSchemeCodeUnspsc190501.50466203'),
	'identifierSchemeCodeUnspsc190501.50466204': __('identifierSchemeCodeUnspsc190501.50466204'),
	'identifierSchemeCodeUnspsc190501.50466205': __('identifierSchemeCodeUnspsc190501.50466205'),
	'identifierSchemeCodeUnspsc190501.50466206': __('identifierSchemeCodeUnspsc190501.50466206'),
	'identifierSchemeCodeUnspsc190501.50466207': __('identifierSchemeCodeUnspsc190501.50466207'),
	'identifierSchemeCodeUnspsc190501.50466208': __('identifierSchemeCodeUnspsc190501.50466208'),
	'identifierSchemeCodeUnspsc190501.50466209': __('identifierSchemeCodeUnspsc190501.50466209'),
	'identifierSchemeCodeUnspsc190501.50466210': __('identifierSchemeCodeUnspsc190501.50466210'),
	'identifierSchemeCodeUnspsc190501.50466211': __('identifierSchemeCodeUnspsc190501.50466211'),
	'identifierSchemeCodeUnspsc190501.50466212': __('identifierSchemeCodeUnspsc190501.50466212'),
	'identifierSchemeCodeUnspsc190501.50466213': __('identifierSchemeCodeUnspsc190501.50466213'),
	'identifierSchemeCodeUnspsc190501.50466214': __('identifierSchemeCodeUnspsc190501.50466214'),
	'identifierSchemeCodeUnspsc190501.50466215': __('identifierSchemeCodeUnspsc190501.50466215'),
	'identifierSchemeCodeUnspsc190501.50466216': __('identifierSchemeCodeUnspsc190501.50466216'),
	'identifierSchemeCodeUnspsc190501.50466217': __('identifierSchemeCodeUnspsc190501.50466217'),
	'identifierSchemeCodeUnspsc190501.50466218': __('identifierSchemeCodeUnspsc190501.50466218'),
	'identifierSchemeCodeUnspsc190501.50466219': __('identifierSchemeCodeUnspsc190501.50466219'),
	'identifierSchemeCodeUnspsc190501.50466220': __('identifierSchemeCodeUnspsc190501.50466220'),
	'identifierSchemeCodeUnspsc190501.50466221': __('identifierSchemeCodeUnspsc190501.50466221'),
	'identifierSchemeCodeUnspsc190501.50466222': __('identifierSchemeCodeUnspsc190501.50466222'),
	'identifierSchemeCodeUnspsc190501.50466223': __('identifierSchemeCodeUnspsc190501.50466223'),
	'identifierSchemeCodeUnspsc190501.50466224': __('identifierSchemeCodeUnspsc190501.50466224'),
	'identifierSchemeCodeUnspsc190501.50466225': __('identifierSchemeCodeUnspsc190501.50466225'),
	'identifierSchemeCodeUnspsc190501.50466226': __('identifierSchemeCodeUnspsc190501.50466226'),
	'identifierSchemeCodeUnspsc190501.50466227': __('identifierSchemeCodeUnspsc190501.50466227'),
	'identifierSchemeCodeUnspsc190501.50466228': __('identifierSchemeCodeUnspsc190501.50466228'),
	'identifierSchemeCodeUnspsc190501.50466229': __('identifierSchemeCodeUnspsc190501.50466229'),
	'identifierSchemeCodeUnspsc190501.50466300': __('identifierSchemeCodeUnspsc190501.50466300'),
	'identifierSchemeCodeUnspsc190501.50466301': __('identifierSchemeCodeUnspsc190501.50466301'),
	'identifierSchemeCodeUnspsc190501.50466302': __('identifierSchemeCodeUnspsc190501.50466302'),
	'identifierSchemeCodeUnspsc190501.50466303': __('identifierSchemeCodeUnspsc190501.50466303'),
	'identifierSchemeCodeUnspsc190501.50466304': __('identifierSchemeCodeUnspsc190501.50466304'),
	'identifierSchemeCodeUnspsc190501.50466305': __('identifierSchemeCodeUnspsc190501.50466305'),
	'identifierSchemeCodeUnspsc190501.50466306': __('identifierSchemeCodeUnspsc190501.50466306'),
	'identifierSchemeCodeUnspsc190501.50466307': __('identifierSchemeCodeUnspsc190501.50466307'),
	'identifierSchemeCodeUnspsc190501.50466308': __('identifierSchemeCodeUnspsc190501.50466308'),
	'identifierSchemeCodeUnspsc190501.50466309': __('identifierSchemeCodeUnspsc190501.50466309'),
	'identifierSchemeCodeUnspsc190501.50466310': __('identifierSchemeCodeUnspsc190501.50466310'),
	'identifierSchemeCodeUnspsc190501.50466311': __('identifierSchemeCodeUnspsc190501.50466311'),
	'identifierSchemeCodeUnspsc190501.50466312': __('identifierSchemeCodeUnspsc190501.50466312'),
	'identifierSchemeCodeUnspsc190501.50466313': __('identifierSchemeCodeUnspsc190501.50466313'),
	'identifierSchemeCodeUnspsc190501.50466314': __('identifierSchemeCodeUnspsc190501.50466314'),
	'identifierSchemeCodeUnspsc190501.50466315': __('identifierSchemeCodeUnspsc190501.50466315'),
	'identifierSchemeCodeUnspsc190501.50466316': __('identifierSchemeCodeUnspsc190501.50466316'),
	'identifierSchemeCodeUnspsc190501.50466317': __('identifierSchemeCodeUnspsc190501.50466317'),
	'identifierSchemeCodeUnspsc190501.50466318': __('identifierSchemeCodeUnspsc190501.50466318'),
	'identifierSchemeCodeUnspsc190501.50466319': __('identifierSchemeCodeUnspsc190501.50466319'),
	'identifierSchemeCodeUnspsc190501.50466320': __('identifierSchemeCodeUnspsc190501.50466320'),
	'identifierSchemeCodeUnspsc190501.50466400': __('identifierSchemeCodeUnspsc190501.50466400'),
	'identifierSchemeCodeUnspsc190501.50466401': __('identifierSchemeCodeUnspsc190501.50466401'),
	'identifierSchemeCodeUnspsc190501.50466402': __('identifierSchemeCodeUnspsc190501.50466402'),
	'identifierSchemeCodeUnspsc190501.50466403': __('identifierSchemeCodeUnspsc190501.50466403'),
	'identifierSchemeCodeUnspsc190501.50466404': __('identifierSchemeCodeUnspsc190501.50466404'),
	'identifierSchemeCodeUnspsc190501.50466405': __('identifierSchemeCodeUnspsc190501.50466405'),
	'identifierSchemeCodeUnspsc190501.50466406': __('identifierSchemeCodeUnspsc190501.50466406'),
	'identifierSchemeCodeUnspsc190501.50466407': __('identifierSchemeCodeUnspsc190501.50466407'),
	'identifierSchemeCodeUnspsc190501.50466408': __('identifierSchemeCodeUnspsc190501.50466408'),
	'identifierSchemeCodeUnspsc190501.50466409': __('identifierSchemeCodeUnspsc190501.50466409'),
	'identifierSchemeCodeUnspsc190501.50466410': __('identifierSchemeCodeUnspsc190501.50466410'),
	'identifierSchemeCodeUnspsc190501.50466411': __('identifierSchemeCodeUnspsc190501.50466411'),
	'identifierSchemeCodeUnspsc190501.50466412': __('identifierSchemeCodeUnspsc190501.50466412'),
	'identifierSchemeCodeUnspsc190501.50466413': __('identifierSchemeCodeUnspsc190501.50466413'),
	'identifierSchemeCodeUnspsc190501.50466414': __('identifierSchemeCodeUnspsc190501.50466414'),
	'identifierSchemeCodeUnspsc190501.50466415': __('identifierSchemeCodeUnspsc190501.50466415'),
	'identifierSchemeCodeUnspsc190501.50466416': __('identifierSchemeCodeUnspsc190501.50466416'),
	'identifierSchemeCodeUnspsc190501.50466417': __('identifierSchemeCodeUnspsc190501.50466417'),
	'identifierSchemeCodeUnspsc190501.50466418': __('identifierSchemeCodeUnspsc190501.50466418'),
	'identifierSchemeCodeUnspsc190501.50466419': __('identifierSchemeCodeUnspsc190501.50466419'),
	'identifierSchemeCodeUnspsc190501.50466420': __('identifierSchemeCodeUnspsc190501.50466420'),
	'identifierSchemeCodeUnspsc190501.50466421': __('identifierSchemeCodeUnspsc190501.50466421'),
	'identifierSchemeCodeUnspsc190501.50466422': __('identifierSchemeCodeUnspsc190501.50466422'),
	'identifierSchemeCodeUnspsc190501.50466423': __('identifierSchemeCodeUnspsc190501.50466423'),
	'identifierSchemeCodeUnspsc190501.50466424': __('identifierSchemeCodeUnspsc190501.50466424'),
	'identifierSchemeCodeUnspsc190501.50466425': __('identifierSchemeCodeUnspsc190501.50466425'),
	'identifierSchemeCodeUnspsc190501.50466426': __('identifierSchemeCodeUnspsc190501.50466426'),
	'identifierSchemeCodeUnspsc190501.50466427': __('identifierSchemeCodeUnspsc190501.50466427'),
	'identifierSchemeCodeUnspsc190501.50466500': __('identifierSchemeCodeUnspsc190501.50466500'),
	'identifierSchemeCodeUnspsc190501.50466501': __('identifierSchemeCodeUnspsc190501.50466501'),
	'identifierSchemeCodeUnspsc190501.50466502': __('identifierSchemeCodeUnspsc190501.50466502'),
	'identifierSchemeCodeUnspsc190501.50466503': __('identifierSchemeCodeUnspsc190501.50466503'),
	'identifierSchemeCodeUnspsc190501.50466504': __('identifierSchemeCodeUnspsc190501.50466504'),
	'identifierSchemeCodeUnspsc190501.50466505': __('identifierSchemeCodeUnspsc190501.50466505'),
	'identifierSchemeCodeUnspsc190501.50466506': __('identifierSchemeCodeUnspsc190501.50466506'),
	'identifierSchemeCodeUnspsc190501.50466507': __('identifierSchemeCodeUnspsc190501.50466507'),
	'identifierSchemeCodeUnspsc190501.50466508': __('identifierSchemeCodeUnspsc190501.50466508'),
	'identifierSchemeCodeUnspsc190501.50466600': __('identifierSchemeCodeUnspsc190501.50466600'),
	'identifierSchemeCodeUnspsc190501.50466601': __('identifierSchemeCodeUnspsc190501.50466601'),
	'identifierSchemeCodeUnspsc190501.50466602': __('identifierSchemeCodeUnspsc190501.50466602'),
	'identifierSchemeCodeUnspsc190501.50466603': __('identifierSchemeCodeUnspsc190501.50466603'),
	'identifierSchemeCodeUnspsc190501.50466604': __('identifierSchemeCodeUnspsc190501.50466604'),
	'identifierSchemeCodeUnspsc190501.50466605': __('identifierSchemeCodeUnspsc190501.50466605'),
	'identifierSchemeCodeUnspsc190501.50466606': __('identifierSchemeCodeUnspsc190501.50466606'),
	'identifierSchemeCodeUnspsc190501.50466607': __('identifierSchemeCodeUnspsc190501.50466607'),
	'identifierSchemeCodeUnspsc190501.50466608': __('identifierSchemeCodeUnspsc190501.50466608'),
	'identifierSchemeCodeUnspsc190501.50466609': __('identifierSchemeCodeUnspsc190501.50466609'),
	'identifierSchemeCodeUnspsc190501.50466610': __('identifierSchemeCodeUnspsc190501.50466610'),
	'identifierSchemeCodeUnspsc190501.50466611': __('identifierSchemeCodeUnspsc190501.50466611'),
	'identifierSchemeCodeUnspsc190501.50466612': __('identifierSchemeCodeUnspsc190501.50466612'),
	'identifierSchemeCodeUnspsc190501.50466613': __('identifierSchemeCodeUnspsc190501.50466613'),
	'identifierSchemeCodeUnspsc190501.50466614': __('identifierSchemeCodeUnspsc190501.50466614'),
	'identifierSchemeCodeUnspsc190501.50466615': __('identifierSchemeCodeUnspsc190501.50466615'),
	'identifierSchemeCodeUnspsc190501.50466616': __('identifierSchemeCodeUnspsc190501.50466616'),
	'identifierSchemeCodeUnspsc190501.50466617': __('identifierSchemeCodeUnspsc190501.50466617'),
	'identifierSchemeCodeUnspsc190501.50466618': __('identifierSchemeCodeUnspsc190501.50466618'),
	'identifierSchemeCodeUnspsc190501.50466619': __('identifierSchemeCodeUnspsc190501.50466619'),
	'identifierSchemeCodeUnspsc190501.50466620': __('identifierSchemeCodeUnspsc190501.50466620'),
	'identifierSchemeCodeUnspsc190501.50466700': __('identifierSchemeCodeUnspsc190501.50466700'),
	'identifierSchemeCodeUnspsc190501.50466701': __('identifierSchemeCodeUnspsc190501.50466701'),
	'identifierSchemeCodeUnspsc190501.50466702': __('identifierSchemeCodeUnspsc190501.50466702'),
	'identifierSchemeCodeUnspsc190501.50466703': __('identifierSchemeCodeUnspsc190501.50466703'),
	'identifierSchemeCodeUnspsc190501.50466704': __('identifierSchemeCodeUnspsc190501.50466704'),
	'identifierSchemeCodeUnspsc190501.50466705': __('identifierSchemeCodeUnspsc190501.50466705'),
	'identifierSchemeCodeUnspsc190501.50466706': __('identifierSchemeCodeUnspsc190501.50466706'),
	'identifierSchemeCodeUnspsc190501.50466707': __('identifierSchemeCodeUnspsc190501.50466707'),
	'identifierSchemeCodeUnspsc190501.50466708': __('identifierSchemeCodeUnspsc190501.50466708'),
	'identifierSchemeCodeUnspsc190501.50466709': __('identifierSchemeCodeUnspsc190501.50466709'),
	'identifierSchemeCodeUnspsc190501.50466710': __('identifierSchemeCodeUnspsc190501.50466710'),
	'identifierSchemeCodeUnspsc190501.50466800': __('identifierSchemeCodeUnspsc190501.50466800'),
	'identifierSchemeCodeUnspsc190501.50466801': __('identifierSchemeCodeUnspsc190501.50466801'),
	'identifierSchemeCodeUnspsc190501.50466802': __('identifierSchemeCodeUnspsc190501.50466802'),
	'identifierSchemeCodeUnspsc190501.50466803': __('identifierSchemeCodeUnspsc190501.50466803'),
	'identifierSchemeCodeUnspsc190501.50466804': __('identifierSchemeCodeUnspsc190501.50466804'),
	'identifierSchemeCodeUnspsc190501.50466805': __('identifierSchemeCodeUnspsc190501.50466805'),
	'identifierSchemeCodeUnspsc190501.50466806': __('identifierSchemeCodeUnspsc190501.50466806'),
	'identifierSchemeCodeUnspsc190501.50466807': __('identifierSchemeCodeUnspsc190501.50466807'),
	'identifierSchemeCodeUnspsc190501.50466808': __('identifierSchemeCodeUnspsc190501.50466808'),
	'identifierSchemeCodeUnspsc190501.50466809': __('identifierSchemeCodeUnspsc190501.50466809'),
	'identifierSchemeCodeUnspsc190501.50466810': __('identifierSchemeCodeUnspsc190501.50466810'),
	'identifierSchemeCodeUnspsc190501.50466811': __('identifierSchemeCodeUnspsc190501.50466811'),
	'identifierSchemeCodeUnspsc190501.50466812': __('identifierSchemeCodeUnspsc190501.50466812'),
	'identifierSchemeCodeUnspsc190501.50466813': __('identifierSchemeCodeUnspsc190501.50466813'),
	'identifierSchemeCodeUnspsc190501.50466814': __('identifierSchemeCodeUnspsc190501.50466814'),
	'identifierSchemeCodeUnspsc190501.50466815': __('identifierSchemeCodeUnspsc190501.50466815'),
	'identifierSchemeCodeUnspsc190501.50466816': __('identifierSchemeCodeUnspsc190501.50466816'),
	'identifierSchemeCodeUnspsc190501.50466817': __('identifierSchemeCodeUnspsc190501.50466817'),
	'identifierSchemeCodeUnspsc190501.50466818': __('identifierSchemeCodeUnspsc190501.50466818'),
	'identifierSchemeCodeUnspsc190501.50466819': __('identifierSchemeCodeUnspsc190501.50466819'),
	'identifierSchemeCodeUnspsc190501.50466820': __('identifierSchemeCodeUnspsc190501.50466820'),
	'identifierSchemeCodeUnspsc190501.50466821': __('identifierSchemeCodeUnspsc190501.50466821'),
	'identifierSchemeCodeUnspsc190501.50466822': __('identifierSchemeCodeUnspsc190501.50466822'),
	'identifierSchemeCodeUnspsc190501.50466823': __('identifierSchemeCodeUnspsc190501.50466823'),
	'identifierSchemeCodeUnspsc190501.50466824': __('identifierSchemeCodeUnspsc190501.50466824'),
	'identifierSchemeCodeUnspsc190501.50466825': __('identifierSchemeCodeUnspsc190501.50466825'),
	'identifierSchemeCodeUnspsc190501.50466826': __('identifierSchemeCodeUnspsc190501.50466826'),
	'identifierSchemeCodeUnspsc190501.50466827': __('identifierSchemeCodeUnspsc190501.50466827'),
	'identifierSchemeCodeUnspsc190501.50466828': __('identifierSchemeCodeUnspsc190501.50466828'),
	'identifierSchemeCodeUnspsc190501.50466829': __('identifierSchemeCodeUnspsc190501.50466829'),
	'identifierSchemeCodeUnspsc190501.50466830': __('identifierSchemeCodeUnspsc190501.50466830'),
	'identifierSchemeCodeUnspsc190501.50466831': __('identifierSchemeCodeUnspsc190501.50466831'),
	'identifierSchemeCodeUnspsc190501.50466832': __('identifierSchemeCodeUnspsc190501.50466832'),
	'identifierSchemeCodeUnspsc190501.50466833': __('identifierSchemeCodeUnspsc190501.50466833'),
	'identifierSchemeCodeUnspsc190501.50466834': __('identifierSchemeCodeUnspsc190501.50466834'),
	'identifierSchemeCodeUnspsc190501.50466835': __('identifierSchemeCodeUnspsc190501.50466835'),
	'identifierSchemeCodeUnspsc190501.50466836': __('identifierSchemeCodeUnspsc190501.50466836'),
	'identifierSchemeCodeUnspsc190501.50466837': __('identifierSchemeCodeUnspsc190501.50466837'),
	'identifierSchemeCodeUnspsc190501.50466838': __('identifierSchemeCodeUnspsc190501.50466838'),
	'identifierSchemeCodeUnspsc190501.50466839': __('identifierSchemeCodeUnspsc190501.50466839'),
	'identifierSchemeCodeUnspsc190501.50466840': __('identifierSchemeCodeUnspsc190501.50466840'),
	'identifierSchemeCodeUnspsc190501.50466841': __('identifierSchemeCodeUnspsc190501.50466841'),
	'identifierSchemeCodeUnspsc190501.50466842': __('identifierSchemeCodeUnspsc190501.50466842'),
	'identifierSchemeCodeUnspsc190501.50466843': __('identifierSchemeCodeUnspsc190501.50466843'),
	'identifierSchemeCodeUnspsc190501.50466844': __('identifierSchemeCodeUnspsc190501.50466844'),
	'identifierSchemeCodeUnspsc190501.50466845': __('identifierSchemeCodeUnspsc190501.50466845'),
	'identifierSchemeCodeUnspsc190501.50466846': __('identifierSchemeCodeUnspsc190501.50466846'),
	'identifierSchemeCodeUnspsc190501.50466847': __('identifierSchemeCodeUnspsc190501.50466847'),
	'identifierSchemeCodeUnspsc190501.50466848': __('identifierSchemeCodeUnspsc190501.50466848'),
	'identifierSchemeCodeUnspsc190501.50466849': __('identifierSchemeCodeUnspsc190501.50466849'),
	'identifierSchemeCodeUnspsc190501.50466850': __('identifierSchemeCodeUnspsc190501.50466850'),
	'identifierSchemeCodeUnspsc190501.50466851': __('identifierSchemeCodeUnspsc190501.50466851'),
	'identifierSchemeCodeUnspsc190501.50466852': __('identifierSchemeCodeUnspsc190501.50466852'),
	'identifierSchemeCodeUnspsc190501.50466853': __('identifierSchemeCodeUnspsc190501.50466853'),
	'identifierSchemeCodeUnspsc190501.50466854': __('identifierSchemeCodeUnspsc190501.50466854'),
	'identifierSchemeCodeUnspsc190501.50466855': __('identifierSchemeCodeUnspsc190501.50466855'),
	'identifierSchemeCodeUnspsc190501.50466856': __('identifierSchemeCodeUnspsc190501.50466856'),
	'identifierSchemeCodeUnspsc190501.50466857': __('identifierSchemeCodeUnspsc190501.50466857'),
	'identifierSchemeCodeUnspsc190501.50466858': __('identifierSchemeCodeUnspsc190501.50466858'),
	'identifierSchemeCodeUnspsc190501.50466859': __('identifierSchemeCodeUnspsc190501.50466859'),
	'identifierSchemeCodeUnspsc190501.50466860': __('identifierSchemeCodeUnspsc190501.50466860'),
	'identifierSchemeCodeUnspsc190501.50466861': __('identifierSchemeCodeUnspsc190501.50466861'),
	'identifierSchemeCodeUnspsc190501.50466862': __('identifierSchemeCodeUnspsc190501.50466862'),
	'identifierSchemeCodeUnspsc190501.50466863': __('identifierSchemeCodeUnspsc190501.50466863'),
	'identifierSchemeCodeUnspsc190501.50466864': __('identifierSchemeCodeUnspsc190501.50466864'),
	'identifierSchemeCodeUnspsc190501.50466865': __('identifierSchemeCodeUnspsc190501.50466865'),
	'identifierSchemeCodeUnspsc190501.50466866': __('identifierSchemeCodeUnspsc190501.50466866'),
	'identifierSchemeCodeUnspsc190501.50466867': __('identifierSchemeCodeUnspsc190501.50466867'),
	'identifierSchemeCodeUnspsc190501.50466868': __('identifierSchemeCodeUnspsc190501.50466868'),
	'identifierSchemeCodeUnspsc190501.50466900': __('identifierSchemeCodeUnspsc190501.50466900'),
	'identifierSchemeCodeUnspsc190501.50466901': __('identifierSchemeCodeUnspsc190501.50466901'),
	'identifierSchemeCodeUnspsc190501.50466902': __('identifierSchemeCodeUnspsc190501.50466902'),
	'identifierSchemeCodeUnspsc190501.50466903': __('identifierSchemeCodeUnspsc190501.50466903'),
	'identifierSchemeCodeUnspsc190501.50466904': __('identifierSchemeCodeUnspsc190501.50466904'),
	'identifierSchemeCodeUnspsc190501.50466905': __('identifierSchemeCodeUnspsc190501.50466905'),
	'identifierSchemeCodeUnspsc190501.50466906': __('identifierSchemeCodeUnspsc190501.50466906'),
	'identifierSchemeCodeUnspsc190501.50466907': __('identifierSchemeCodeUnspsc190501.50466907'),
	'identifierSchemeCodeUnspsc190501.50466908': __('identifierSchemeCodeUnspsc190501.50466908'),
	'identifierSchemeCodeUnspsc190501.50466909': __('identifierSchemeCodeUnspsc190501.50466909'),
	'identifierSchemeCodeUnspsc190501.50466910': __('identifierSchemeCodeUnspsc190501.50466910'),
	'identifierSchemeCodeUnspsc190501.50467000': __('identifierSchemeCodeUnspsc190501.50467000'),
	'identifierSchemeCodeUnspsc190501.50467001': __('identifierSchemeCodeUnspsc190501.50467001'),
	'identifierSchemeCodeUnspsc190501.50467002': __('identifierSchemeCodeUnspsc190501.50467002'),
	'identifierSchemeCodeUnspsc190501.50467003': __('identifierSchemeCodeUnspsc190501.50467003'),
	'identifierSchemeCodeUnspsc190501.50467004': __('identifierSchemeCodeUnspsc190501.50467004'),
	'identifierSchemeCodeUnspsc190501.50467005': __('identifierSchemeCodeUnspsc190501.50467005'),
	'identifierSchemeCodeUnspsc190501.50467006': __('identifierSchemeCodeUnspsc190501.50467006'),
	'identifierSchemeCodeUnspsc190501.50470000': __('identifierSchemeCodeUnspsc190501.50470000'),
	'identifierSchemeCodeUnspsc190501.50471500': __('identifierSchemeCodeUnspsc190501.50471500'),
	'identifierSchemeCodeUnspsc190501.50471501': __('identifierSchemeCodeUnspsc190501.50471501'),
	'identifierSchemeCodeUnspsc190501.50471502': __('identifierSchemeCodeUnspsc190501.50471502'),
	'identifierSchemeCodeUnspsc190501.50471503': __('identifierSchemeCodeUnspsc190501.50471503'),
	'identifierSchemeCodeUnspsc190501.50471504': __('identifierSchemeCodeUnspsc190501.50471504'),
	'identifierSchemeCodeUnspsc190501.50471505': __('identifierSchemeCodeUnspsc190501.50471505'),
	'identifierSchemeCodeUnspsc190501.50471506': __('identifierSchemeCodeUnspsc190501.50471506'),
	'identifierSchemeCodeUnspsc190501.50471507': __('identifierSchemeCodeUnspsc190501.50471507'),
	'identifierSchemeCodeUnspsc190501.50471508': __('identifierSchemeCodeUnspsc190501.50471508'),
	'identifierSchemeCodeUnspsc190501.50471509': __('identifierSchemeCodeUnspsc190501.50471509'),
	'identifierSchemeCodeUnspsc190501.50471510': __('identifierSchemeCodeUnspsc190501.50471510'),
	'identifierSchemeCodeUnspsc190501.50471511': __('identifierSchemeCodeUnspsc190501.50471511'),
	'identifierSchemeCodeUnspsc190501.50471512': __('identifierSchemeCodeUnspsc190501.50471512'),
	'identifierSchemeCodeUnspsc190501.50471513': __('identifierSchemeCodeUnspsc190501.50471513'),
	'identifierSchemeCodeUnspsc190501.50471600': __('identifierSchemeCodeUnspsc190501.50471600'),
	'identifierSchemeCodeUnspsc190501.50471601': __('identifierSchemeCodeUnspsc190501.50471601'),
	'identifierSchemeCodeUnspsc190501.50471602': __('identifierSchemeCodeUnspsc190501.50471602'),
	'identifierSchemeCodeUnspsc190501.50471603': __('identifierSchemeCodeUnspsc190501.50471603'),
	'identifierSchemeCodeUnspsc190501.50471604': __('identifierSchemeCodeUnspsc190501.50471604'),
	'identifierSchemeCodeUnspsc190501.50471605': __('identifierSchemeCodeUnspsc190501.50471605'),
	'identifierSchemeCodeUnspsc190501.50471700': __('identifierSchemeCodeUnspsc190501.50471700'),
	'identifierSchemeCodeUnspsc190501.50471701': __('identifierSchemeCodeUnspsc190501.50471701'),
	'identifierSchemeCodeUnspsc190501.50471702': __('identifierSchemeCodeUnspsc190501.50471702'),
	'identifierSchemeCodeUnspsc190501.50471703': __('identifierSchemeCodeUnspsc190501.50471703'),
	'identifierSchemeCodeUnspsc190501.50471704': __('identifierSchemeCodeUnspsc190501.50471704'),
	'identifierSchemeCodeUnspsc190501.50471705': __('identifierSchemeCodeUnspsc190501.50471705'),
	'identifierSchemeCodeUnspsc190501.50471706': __('identifierSchemeCodeUnspsc190501.50471706'),
	'identifierSchemeCodeUnspsc190501.50471707': __('identifierSchemeCodeUnspsc190501.50471707'),
	'identifierSchemeCodeUnspsc190501.50471708': __('identifierSchemeCodeUnspsc190501.50471708'),
	'identifierSchemeCodeUnspsc190501.50471709': __('identifierSchemeCodeUnspsc190501.50471709'),
	'identifierSchemeCodeUnspsc190501.50471710': __('identifierSchemeCodeUnspsc190501.50471710'),
	'identifierSchemeCodeUnspsc190501.50471711': __('identifierSchemeCodeUnspsc190501.50471711'),
	'identifierSchemeCodeUnspsc190501.50471712': __('identifierSchemeCodeUnspsc190501.50471712'),
	'identifierSchemeCodeUnspsc190501.50471713': __('identifierSchemeCodeUnspsc190501.50471713'),
	'identifierSchemeCodeUnspsc190501.50471714': __('identifierSchemeCodeUnspsc190501.50471714'),
	'identifierSchemeCodeUnspsc190501.50471715': __('identifierSchemeCodeUnspsc190501.50471715'),
	'identifierSchemeCodeUnspsc190501.50471716': __('identifierSchemeCodeUnspsc190501.50471716'),
	'identifierSchemeCodeUnspsc190501.50471717': __('identifierSchemeCodeUnspsc190501.50471717'),
	'identifierSchemeCodeUnspsc190501.50471718': __('identifierSchemeCodeUnspsc190501.50471718'),
	'identifierSchemeCodeUnspsc190501.50471719': __('identifierSchemeCodeUnspsc190501.50471719'),
	'identifierSchemeCodeUnspsc190501.50471720': __('identifierSchemeCodeUnspsc190501.50471720'),
	'identifierSchemeCodeUnspsc190501.50471721': __('identifierSchemeCodeUnspsc190501.50471721'),
	'identifierSchemeCodeUnspsc190501.50471722': __('identifierSchemeCodeUnspsc190501.50471722'),
	'identifierSchemeCodeUnspsc190501.50471723': __('identifierSchemeCodeUnspsc190501.50471723'),
	'identifierSchemeCodeUnspsc190501.50471724': __('identifierSchemeCodeUnspsc190501.50471724'),
	'identifierSchemeCodeUnspsc190501.50471725': __('identifierSchemeCodeUnspsc190501.50471725'),
	'identifierSchemeCodeUnspsc190501.50471726': __('identifierSchemeCodeUnspsc190501.50471726'),
	'identifierSchemeCodeUnspsc190501.50471727': __('identifierSchemeCodeUnspsc190501.50471727'),
	'identifierSchemeCodeUnspsc190501.50471728': __('identifierSchemeCodeUnspsc190501.50471728'),
	'identifierSchemeCodeUnspsc190501.50471729': __('identifierSchemeCodeUnspsc190501.50471729'),
	'identifierSchemeCodeUnspsc190501.50471730': __('identifierSchemeCodeUnspsc190501.50471730'),
	'identifierSchemeCodeUnspsc190501.50471731': __('identifierSchemeCodeUnspsc190501.50471731'),
	'identifierSchemeCodeUnspsc190501.50471732': __('identifierSchemeCodeUnspsc190501.50471732'),
	'identifierSchemeCodeUnspsc190501.50471733': __('identifierSchemeCodeUnspsc190501.50471733'),
	'identifierSchemeCodeUnspsc190501.50471734': __('identifierSchemeCodeUnspsc190501.50471734'),
	'identifierSchemeCodeUnspsc190501.50471735': __('identifierSchemeCodeUnspsc190501.50471735'),
	'identifierSchemeCodeUnspsc190501.50471736': __('identifierSchemeCodeUnspsc190501.50471736'),
	'identifierSchemeCodeUnspsc190501.50471737': __('identifierSchemeCodeUnspsc190501.50471737'),
	'identifierSchemeCodeUnspsc190501.50471738': __('identifierSchemeCodeUnspsc190501.50471738'),
	'identifierSchemeCodeUnspsc190501.50471739': __('identifierSchemeCodeUnspsc190501.50471739'),
	'identifierSchemeCodeUnspsc190501.50471740': __('identifierSchemeCodeUnspsc190501.50471740'),
	'identifierSchemeCodeUnspsc190501.50471741': __('identifierSchemeCodeUnspsc190501.50471741'),
	'identifierSchemeCodeUnspsc190501.50471742': __('identifierSchemeCodeUnspsc190501.50471742'),
	'identifierSchemeCodeUnspsc190501.50471743': __('identifierSchemeCodeUnspsc190501.50471743'),
	'identifierSchemeCodeUnspsc190501.50471744': __('identifierSchemeCodeUnspsc190501.50471744'),
	'identifierSchemeCodeUnspsc190501.50471745': __('identifierSchemeCodeUnspsc190501.50471745'),
	'identifierSchemeCodeUnspsc190501.50471746': __('identifierSchemeCodeUnspsc190501.50471746'),
	'identifierSchemeCodeUnspsc190501.50471747': __('identifierSchemeCodeUnspsc190501.50471747'),
	'identifierSchemeCodeUnspsc190501.50471748': __('identifierSchemeCodeUnspsc190501.50471748'),
	'identifierSchemeCodeUnspsc190501.50471749': __('identifierSchemeCodeUnspsc190501.50471749'),
	'identifierSchemeCodeUnspsc190501.50471750': __('identifierSchemeCodeUnspsc190501.50471750'),
	'identifierSchemeCodeUnspsc190501.50471751': __('identifierSchemeCodeUnspsc190501.50471751'),
	'identifierSchemeCodeUnspsc190501.50471752': __('identifierSchemeCodeUnspsc190501.50471752'),
	'identifierSchemeCodeUnspsc190501.50471753': __('identifierSchemeCodeUnspsc190501.50471753'),
	'identifierSchemeCodeUnspsc190501.50471754': __('identifierSchemeCodeUnspsc190501.50471754'),
	'identifierSchemeCodeUnspsc190501.50471755': __('identifierSchemeCodeUnspsc190501.50471755'),
	'identifierSchemeCodeUnspsc190501.50471756': __('identifierSchemeCodeUnspsc190501.50471756'),
	'identifierSchemeCodeUnspsc190501.50471757': __('identifierSchemeCodeUnspsc190501.50471757'),
	'identifierSchemeCodeUnspsc190501.50471758': __('identifierSchemeCodeUnspsc190501.50471758'),
	'identifierSchemeCodeUnspsc190501.50471759': __('identifierSchemeCodeUnspsc190501.50471759'),
	'identifierSchemeCodeUnspsc190501.50471760': __('identifierSchemeCodeUnspsc190501.50471760'),
	'identifierSchemeCodeUnspsc190501.50471761': __('identifierSchemeCodeUnspsc190501.50471761'),
	'identifierSchemeCodeUnspsc190501.50471762': __('identifierSchemeCodeUnspsc190501.50471762'),
	'identifierSchemeCodeUnspsc190501.50471763': __('identifierSchemeCodeUnspsc190501.50471763'),
	'identifierSchemeCodeUnspsc190501.50471764': __('identifierSchemeCodeUnspsc190501.50471764'),
	'identifierSchemeCodeUnspsc190501.50471765': __('identifierSchemeCodeUnspsc190501.50471765'),
	'identifierSchemeCodeUnspsc190501.50471766': __('identifierSchemeCodeUnspsc190501.50471766'),
	'identifierSchemeCodeUnspsc190501.50471767': __('identifierSchemeCodeUnspsc190501.50471767'),
	'identifierSchemeCodeUnspsc190501.50471768': __('identifierSchemeCodeUnspsc190501.50471768'),
	'identifierSchemeCodeUnspsc190501.50471769': __('identifierSchemeCodeUnspsc190501.50471769'),
	'identifierSchemeCodeUnspsc190501.50471770': __('identifierSchemeCodeUnspsc190501.50471770'),
	'identifierSchemeCodeUnspsc190501.50471771': __('identifierSchemeCodeUnspsc190501.50471771'),
	'identifierSchemeCodeUnspsc190501.50471772': __('identifierSchemeCodeUnspsc190501.50471772'),
	'identifierSchemeCodeUnspsc190501.50471773': __('identifierSchemeCodeUnspsc190501.50471773'),
	'identifierSchemeCodeUnspsc190501.50471774': __('identifierSchemeCodeUnspsc190501.50471774'),
	'identifierSchemeCodeUnspsc190501.50471775': __('identifierSchemeCodeUnspsc190501.50471775'),
	'identifierSchemeCodeUnspsc190501.50471776': __('identifierSchemeCodeUnspsc190501.50471776'),
	'identifierSchemeCodeUnspsc190501.50471777': __('identifierSchemeCodeUnspsc190501.50471777'),
	'identifierSchemeCodeUnspsc190501.50471778': __('identifierSchemeCodeUnspsc190501.50471778'),
	'identifierSchemeCodeUnspsc190501.50471779': __('identifierSchemeCodeUnspsc190501.50471779'),
	'identifierSchemeCodeUnspsc190501.50471780': __('identifierSchemeCodeUnspsc190501.50471780'),
	'identifierSchemeCodeUnspsc190501.50471781': __('identifierSchemeCodeUnspsc190501.50471781'),
	'identifierSchemeCodeUnspsc190501.50471782': __('identifierSchemeCodeUnspsc190501.50471782'),
	'identifierSchemeCodeUnspsc190501.50471800': __('identifierSchemeCodeUnspsc190501.50471800'),
	'identifierSchemeCodeUnspsc190501.50471801': __('identifierSchemeCodeUnspsc190501.50471801'),
	'identifierSchemeCodeUnspsc190501.50471802': __('identifierSchemeCodeUnspsc190501.50471802'),
	'identifierSchemeCodeUnspsc190501.50471803': __('identifierSchemeCodeUnspsc190501.50471803'),
	'identifierSchemeCodeUnspsc190501.50471804': __('identifierSchemeCodeUnspsc190501.50471804'),
	'identifierSchemeCodeUnspsc190501.50471805': __('identifierSchemeCodeUnspsc190501.50471805'),
	'identifierSchemeCodeUnspsc190501.50471806': __('identifierSchemeCodeUnspsc190501.50471806'),
	'identifierSchemeCodeUnspsc190501.50471807': __('identifierSchemeCodeUnspsc190501.50471807'),
	'identifierSchemeCodeUnspsc190501.50471808': __('identifierSchemeCodeUnspsc190501.50471808'),
	'identifierSchemeCodeUnspsc190501.50471809': __('identifierSchemeCodeUnspsc190501.50471809'),
	'identifierSchemeCodeUnspsc190501.50471810': __('identifierSchemeCodeUnspsc190501.50471810'),
	'identifierSchemeCodeUnspsc190501.50471811': __('identifierSchemeCodeUnspsc190501.50471811'),
	'identifierSchemeCodeUnspsc190501.50471812': __('identifierSchemeCodeUnspsc190501.50471812'),
	'identifierSchemeCodeUnspsc190501.50471813': __('identifierSchemeCodeUnspsc190501.50471813'),
	'identifierSchemeCodeUnspsc190501.50471814': __('identifierSchemeCodeUnspsc190501.50471814'),
	'identifierSchemeCodeUnspsc190501.50471815': __('identifierSchemeCodeUnspsc190501.50471815'),
	'identifierSchemeCodeUnspsc190501.50471816': __('identifierSchemeCodeUnspsc190501.50471816'),
	'identifierSchemeCodeUnspsc190501.50471817': __('identifierSchemeCodeUnspsc190501.50471817'),
	'identifierSchemeCodeUnspsc190501.50471818': __('identifierSchemeCodeUnspsc190501.50471818'),
	'identifierSchemeCodeUnspsc190501.50471819': __('identifierSchemeCodeUnspsc190501.50471819'),
	'identifierSchemeCodeUnspsc190501.50471820': __('identifierSchemeCodeUnspsc190501.50471820'),
	'identifierSchemeCodeUnspsc190501.50471821': __('identifierSchemeCodeUnspsc190501.50471821'),
	'identifierSchemeCodeUnspsc190501.50471822': __('identifierSchemeCodeUnspsc190501.50471822'),
	'identifierSchemeCodeUnspsc190501.50471823': __('identifierSchemeCodeUnspsc190501.50471823'),
	'identifierSchemeCodeUnspsc190501.50471824': __('identifierSchemeCodeUnspsc190501.50471824'),
	'identifierSchemeCodeUnspsc190501.50471825': __('identifierSchemeCodeUnspsc190501.50471825'),
	'identifierSchemeCodeUnspsc190501.50471826': __('identifierSchemeCodeUnspsc190501.50471826'),
	'identifierSchemeCodeUnspsc190501.50471827': __('identifierSchemeCodeUnspsc190501.50471827'),
	'identifierSchemeCodeUnspsc190501.50471828': __('identifierSchemeCodeUnspsc190501.50471828'),
	'identifierSchemeCodeUnspsc190501.50471829': __('identifierSchemeCodeUnspsc190501.50471829'),
	'identifierSchemeCodeUnspsc190501.50471830': __('identifierSchemeCodeUnspsc190501.50471830'),
	'identifierSchemeCodeUnspsc190501.50471831': __('identifierSchemeCodeUnspsc190501.50471831'),
	'identifierSchemeCodeUnspsc190501.50471832': __('identifierSchemeCodeUnspsc190501.50471832'),
	'identifierSchemeCodeUnspsc190501.50471833': __('identifierSchemeCodeUnspsc190501.50471833'),
	'identifierSchemeCodeUnspsc190501.50471834': __('identifierSchemeCodeUnspsc190501.50471834'),
	'identifierSchemeCodeUnspsc190501.50471835': __('identifierSchemeCodeUnspsc190501.50471835'),
	'identifierSchemeCodeUnspsc190501.50471836': __('identifierSchemeCodeUnspsc190501.50471836'),
	'identifierSchemeCodeUnspsc190501.50471837': __('identifierSchemeCodeUnspsc190501.50471837'),
	'identifierSchemeCodeUnspsc190501.50471838': __('identifierSchemeCodeUnspsc190501.50471838'),
	'identifierSchemeCodeUnspsc190501.50471839': __('identifierSchemeCodeUnspsc190501.50471839'),
	'identifierSchemeCodeUnspsc190501.50471840': __('identifierSchemeCodeUnspsc190501.50471840'),
	'identifierSchemeCodeUnspsc190501.50471841': __('identifierSchemeCodeUnspsc190501.50471841'),
	'identifierSchemeCodeUnspsc190501.50471842': __('identifierSchemeCodeUnspsc190501.50471842'),
	'identifierSchemeCodeUnspsc190501.50471843': __('identifierSchemeCodeUnspsc190501.50471843'),
	'identifierSchemeCodeUnspsc190501.50471844': __('identifierSchemeCodeUnspsc190501.50471844'),
	'identifierSchemeCodeUnspsc190501.50471845': __('identifierSchemeCodeUnspsc190501.50471845'),
	'identifierSchemeCodeUnspsc190501.50471846': __('identifierSchemeCodeUnspsc190501.50471846'),
	'identifierSchemeCodeUnspsc190501.50471847': __('identifierSchemeCodeUnspsc190501.50471847'),
	'identifierSchemeCodeUnspsc190501.50471848': __('identifierSchemeCodeUnspsc190501.50471848'),
	'identifierSchemeCodeUnspsc190501.50471849': __('identifierSchemeCodeUnspsc190501.50471849'),
	'identifierSchemeCodeUnspsc190501.50471850': __('identifierSchemeCodeUnspsc190501.50471850'),
	'identifierSchemeCodeUnspsc190501.50471851': __('identifierSchemeCodeUnspsc190501.50471851'),
	'identifierSchemeCodeUnspsc190501.50471852': __('identifierSchemeCodeUnspsc190501.50471852'),
	'identifierSchemeCodeUnspsc190501.50471900': __('identifierSchemeCodeUnspsc190501.50471900'),
	'identifierSchemeCodeUnspsc190501.50471901': __('identifierSchemeCodeUnspsc190501.50471901'),
	'identifierSchemeCodeUnspsc190501.50471902': __('identifierSchemeCodeUnspsc190501.50471902'),
	'identifierSchemeCodeUnspsc190501.50471903': __('identifierSchemeCodeUnspsc190501.50471903'),
	'identifierSchemeCodeUnspsc190501.50471904': __('identifierSchemeCodeUnspsc190501.50471904'),
	'identifierSchemeCodeUnspsc190501.50471905': __('identifierSchemeCodeUnspsc190501.50471905'),
	'identifierSchemeCodeUnspsc190501.50471906': __('identifierSchemeCodeUnspsc190501.50471906'),
	'identifierSchemeCodeUnspsc190501.50471907': __('identifierSchemeCodeUnspsc190501.50471907'),
	'identifierSchemeCodeUnspsc190501.50471908': __('identifierSchemeCodeUnspsc190501.50471908'),
	'identifierSchemeCodeUnspsc190501.50471909': __('identifierSchemeCodeUnspsc190501.50471909'),
	'identifierSchemeCodeUnspsc190501.50471910': __('identifierSchemeCodeUnspsc190501.50471910'),
	'identifierSchemeCodeUnspsc190501.50471911': __('identifierSchemeCodeUnspsc190501.50471911'),
	'identifierSchemeCodeUnspsc190501.50471912': __('identifierSchemeCodeUnspsc190501.50471912'),
	'identifierSchemeCodeUnspsc190501.50471913': __('identifierSchemeCodeUnspsc190501.50471913'),
	'identifierSchemeCodeUnspsc190501.50471914': __('identifierSchemeCodeUnspsc190501.50471914'),
	'identifierSchemeCodeUnspsc190501.50471915': __('identifierSchemeCodeUnspsc190501.50471915'),
	'identifierSchemeCodeUnspsc190501.50471916': __('identifierSchemeCodeUnspsc190501.50471916'),
	'identifierSchemeCodeUnspsc190501.50471917': __('identifierSchemeCodeUnspsc190501.50471917'),
	'identifierSchemeCodeUnspsc190501.50471918': __('identifierSchemeCodeUnspsc190501.50471918'),
	'identifierSchemeCodeUnspsc190501.50471919': __('identifierSchemeCodeUnspsc190501.50471919'),
	'identifierSchemeCodeUnspsc190501.50471920': __('identifierSchemeCodeUnspsc190501.50471920'),
	'identifierSchemeCodeUnspsc190501.50471921': __('identifierSchemeCodeUnspsc190501.50471921'),
	'identifierSchemeCodeUnspsc190501.50471922': __('identifierSchemeCodeUnspsc190501.50471922'),
	'identifierSchemeCodeUnspsc190501.50472000': __('identifierSchemeCodeUnspsc190501.50472000'),
	'identifierSchemeCodeUnspsc190501.50472001': __('identifierSchemeCodeUnspsc190501.50472001'),
	'identifierSchemeCodeUnspsc190501.50472002': __('identifierSchemeCodeUnspsc190501.50472002'),
	'identifierSchemeCodeUnspsc190501.50472003': __('identifierSchemeCodeUnspsc190501.50472003'),
	'identifierSchemeCodeUnspsc190501.50472004': __('identifierSchemeCodeUnspsc190501.50472004'),
	'identifierSchemeCodeUnspsc190501.50472100': __('identifierSchemeCodeUnspsc190501.50472100'),
	'identifierSchemeCodeUnspsc190501.50472101': __('identifierSchemeCodeUnspsc190501.50472101'),
	'identifierSchemeCodeUnspsc190501.50472102': __('identifierSchemeCodeUnspsc190501.50472102'),
	'identifierSchemeCodeUnspsc190501.50472103': __('identifierSchemeCodeUnspsc190501.50472103'),
	'identifierSchemeCodeUnspsc190501.50472104': __('identifierSchemeCodeUnspsc190501.50472104'),
	'identifierSchemeCodeUnspsc190501.50472105': __('identifierSchemeCodeUnspsc190501.50472105'),
	'identifierSchemeCodeUnspsc190501.50472106': __('identifierSchemeCodeUnspsc190501.50472106'),
	'identifierSchemeCodeUnspsc190501.50472107': __('identifierSchemeCodeUnspsc190501.50472107'),
	'identifierSchemeCodeUnspsc190501.50472200': __('identifierSchemeCodeUnspsc190501.50472200'),
	'identifierSchemeCodeUnspsc190501.50472201': __('identifierSchemeCodeUnspsc190501.50472201'),
	'identifierSchemeCodeUnspsc190501.50472202': __('identifierSchemeCodeUnspsc190501.50472202'),
	'identifierSchemeCodeUnspsc190501.50472203': __('identifierSchemeCodeUnspsc190501.50472203'),
	'identifierSchemeCodeUnspsc190501.50472204': __('identifierSchemeCodeUnspsc190501.50472204'),
	'identifierSchemeCodeUnspsc190501.50472205': __('identifierSchemeCodeUnspsc190501.50472205'),
	'identifierSchemeCodeUnspsc190501.50472206': __('identifierSchemeCodeUnspsc190501.50472206'),
	'identifierSchemeCodeUnspsc190501.50472207': __('identifierSchemeCodeUnspsc190501.50472207'),
	'identifierSchemeCodeUnspsc190501.50472208': __('identifierSchemeCodeUnspsc190501.50472208'),
	'identifierSchemeCodeUnspsc190501.50472209': __('identifierSchemeCodeUnspsc190501.50472209'),
	'identifierSchemeCodeUnspsc190501.50472210': __('identifierSchemeCodeUnspsc190501.50472210'),
	'identifierSchemeCodeUnspsc190501.50472211': __('identifierSchemeCodeUnspsc190501.50472211'),
	'identifierSchemeCodeUnspsc190501.50472300': __('identifierSchemeCodeUnspsc190501.50472300'),
	'identifierSchemeCodeUnspsc190501.50472301': __('identifierSchemeCodeUnspsc190501.50472301'),
	'identifierSchemeCodeUnspsc190501.50472302': __('identifierSchemeCodeUnspsc190501.50472302'),
	'identifierSchemeCodeUnspsc190501.50472303': __('identifierSchemeCodeUnspsc190501.50472303'),
	'identifierSchemeCodeUnspsc190501.50472304': __('identifierSchemeCodeUnspsc190501.50472304'),
	'identifierSchemeCodeUnspsc190501.50472400': __('identifierSchemeCodeUnspsc190501.50472400'),
	'identifierSchemeCodeUnspsc190501.50472401': __('identifierSchemeCodeUnspsc190501.50472401'),
	'identifierSchemeCodeUnspsc190501.50472402': __('identifierSchemeCodeUnspsc190501.50472402'),
	'identifierSchemeCodeUnspsc190501.50472500': __('identifierSchemeCodeUnspsc190501.50472500'),
	'identifierSchemeCodeUnspsc190501.50472501': __('identifierSchemeCodeUnspsc190501.50472501'),
	'identifierSchemeCodeUnspsc190501.50472502': __('identifierSchemeCodeUnspsc190501.50472502'),
	'identifierSchemeCodeUnspsc190501.50472503': __('identifierSchemeCodeUnspsc190501.50472503'),
	'identifierSchemeCodeUnspsc190501.50472504': __('identifierSchemeCodeUnspsc190501.50472504'),
	'identifierSchemeCodeUnspsc190501.50472505': __('identifierSchemeCodeUnspsc190501.50472505'),
	'identifierSchemeCodeUnspsc190501.50472506': __('identifierSchemeCodeUnspsc190501.50472506'),
	'identifierSchemeCodeUnspsc190501.50472600': __('identifierSchemeCodeUnspsc190501.50472600'),
	'identifierSchemeCodeUnspsc190501.50472601': __('identifierSchemeCodeUnspsc190501.50472601'),
	'identifierSchemeCodeUnspsc190501.50472602': __('identifierSchemeCodeUnspsc190501.50472602'),
	'identifierSchemeCodeUnspsc190501.50472603': __('identifierSchemeCodeUnspsc190501.50472603'),
	'identifierSchemeCodeUnspsc190501.50472604': __('identifierSchemeCodeUnspsc190501.50472604'),
	'identifierSchemeCodeUnspsc190501.50472605': __('identifierSchemeCodeUnspsc190501.50472605'),
	'identifierSchemeCodeUnspsc190501.50472606': __('identifierSchemeCodeUnspsc190501.50472606'),
	'identifierSchemeCodeUnspsc190501.50472607': __('identifierSchemeCodeUnspsc190501.50472607'),
	'identifierSchemeCodeUnspsc190501.50472608': __('identifierSchemeCodeUnspsc190501.50472608'),
	'identifierSchemeCodeUnspsc190501.50472609': __('identifierSchemeCodeUnspsc190501.50472609'),
	'identifierSchemeCodeUnspsc190501.50472610': __('identifierSchemeCodeUnspsc190501.50472610'),
	'identifierSchemeCodeUnspsc190501.50472611': __('identifierSchemeCodeUnspsc190501.50472611'),
	'identifierSchemeCodeUnspsc190501.50472612': __('identifierSchemeCodeUnspsc190501.50472612'),
	'identifierSchemeCodeUnspsc190501.50472700': __('identifierSchemeCodeUnspsc190501.50472700'),
	'identifierSchemeCodeUnspsc190501.50472701': __('identifierSchemeCodeUnspsc190501.50472701'),
	'identifierSchemeCodeUnspsc190501.50472702': __('identifierSchemeCodeUnspsc190501.50472702'),
	'identifierSchemeCodeUnspsc190501.50472703': __('identifierSchemeCodeUnspsc190501.50472703'),
	'identifierSchemeCodeUnspsc190501.50472704': __('identifierSchemeCodeUnspsc190501.50472704'),
	'identifierSchemeCodeUnspsc190501.50472705': __('identifierSchemeCodeUnspsc190501.50472705'),
	'identifierSchemeCodeUnspsc190501.50472706': __('identifierSchemeCodeUnspsc190501.50472706'),
	'identifierSchemeCodeUnspsc190501.50472707': __('identifierSchemeCodeUnspsc190501.50472707'),
	'identifierSchemeCodeUnspsc190501.50472708': __('identifierSchemeCodeUnspsc190501.50472708'),
	'identifierSchemeCodeUnspsc190501.50472709': __('identifierSchemeCodeUnspsc190501.50472709'),
	'identifierSchemeCodeUnspsc190501.50472710': __('identifierSchemeCodeUnspsc190501.50472710'),
	'identifierSchemeCodeUnspsc190501.50472711': __('identifierSchemeCodeUnspsc190501.50472711'),
	'identifierSchemeCodeUnspsc190501.50472712': __('identifierSchemeCodeUnspsc190501.50472712'),
	'identifierSchemeCodeUnspsc190501.50472713': __('identifierSchemeCodeUnspsc190501.50472713'),
	'identifierSchemeCodeUnspsc190501.50472714': __('identifierSchemeCodeUnspsc190501.50472714'),
	'identifierSchemeCodeUnspsc190501.50472715': __('identifierSchemeCodeUnspsc190501.50472715'),
	'identifierSchemeCodeUnspsc190501.50472800': __('identifierSchemeCodeUnspsc190501.50472800'),
	'identifierSchemeCodeUnspsc190501.50472801': __('identifierSchemeCodeUnspsc190501.50472801'),
	'identifierSchemeCodeUnspsc190501.50472802': __('identifierSchemeCodeUnspsc190501.50472802'),
	'identifierSchemeCodeUnspsc190501.50472803': __('identifierSchemeCodeUnspsc190501.50472803'),
	'identifierSchemeCodeUnspsc190501.50472804': __('identifierSchemeCodeUnspsc190501.50472804'),
	'identifierSchemeCodeUnspsc190501.50472805': __('identifierSchemeCodeUnspsc190501.50472805'),
	'identifierSchemeCodeUnspsc190501.50472806': __('identifierSchemeCodeUnspsc190501.50472806'),
	'identifierSchemeCodeUnspsc190501.50472807': __('identifierSchemeCodeUnspsc190501.50472807'),
	'identifierSchemeCodeUnspsc190501.50472808': __('identifierSchemeCodeUnspsc190501.50472808'),
	'identifierSchemeCodeUnspsc190501.50472900': __('identifierSchemeCodeUnspsc190501.50472900'),
	'identifierSchemeCodeUnspsc190501.50472901': __('identifierSchemeCodeUnspsc190501.50472901'),
	'identifierSchemeCodeUnspsc190501.50472902': __('identifierSchemeCodeUnspsc190501.50472902'),
	'identifierSchemeCodeUnspsc190501.50472903': __('identifierSchemeCodeUnspsc190501.50472903'),
	'identifierSchemeCodeUnspsc190501.50472904': __('identifierSchemeCodeUnspsc190501.50472904'),
	'identifierSchemeCodeUnspsc190501.50472905': __('identifierSchemeCodeUnspsc190501.50472905'),
	'identifierSchemeCodeUnspsc190501.50472906': __('identifierSchemeCodeUnspsc190501.50472906'),
	'identifierSchemeCodeUnspsc190501.50472907': __('identifierSchemeCodeUnspsc190501.50472907'),
	'identifierSchemeCodeUnspsc190501.50472908': __('identifierSchemeCodeUnspsc190501.50472908'),
	'identifierSchemeCodeUnspsc190501.50472909': __('identifierSchemeCodeUnspsc190501.50472909'),
	'identifierSchemeCodeUnspsc190501.50472910': __('identifierSchemeCodeUnspsc190501.50472910'),
	'identifierSchemeCodeUnspsc190501.50472911': __('identifierSchemeCodeUnspsc190501.50472911'),
	'identifierSchemeCodeUnspsc190501.50473000': __('identifierSchemeCodeUnspsc190501.50473000'),
	'identifierSchemeCodeUnspsc190501.50473001': __('identifierSchemeCodeUnspsc190501.50473001'),
	'identifierSchemeCodeUnspsc190501.50473002': __('identifierSchemeCodeUnspsc190501.50473002'),
	'identifierSchemeCodeUnspsc190501.50473003': __('identifierSchemeCodeUnspsc190501.50473003'),
	'identifierSchemeCodeUnspsc190501.50473004': __('identifierSchemeCodeUnspsc190501.50473004'),
	'identifierSchemeCodeUnspsc190501.50473005': __('identifierSchemeCodeUnspsc190501.50473005'),
	'identifierSchemeCodeUnspsc190501.50473006': __('identifierSchemeCodeUnspsc190501.50473006'),
	'identifierSchemeCodeUnspsc190501.50473007': __('identifierSchemeCodeUnspsc190501.50473007'),
	'identifierSchemeCodeUnspsc190501.50473008': __('identifierSchemeCodeUnspsc190501.50473008'),
	'identifierSchemeCodeUnspsc190501.50473009': __('identifierSchemeCodeUnspsc190501.50473009'),
	'identifierSchemeCodeUnspsc190501.50473010': __('identifierSchemeCodeUnspsc190501.50473010'),
	'identifierSchemeCodeUnspsc190501.50473011': __('identifierSchemeCodeUnspsc190501.50473011'),
	'identifierSchemeCodeUnspsc190501.50473012': __('identifierSchemeCodeUnspsc190501.50473012'),
	'identifierSchemeCodeUnspsc190501.50473013': __('identifierSchemeCodeUnspsc190501.50473013'),
	'identifierSchemeCodeUnspsc190501.50473014': __('identifierSchemeCodeUnspsc190501.50473014'),
	'identifierSchemeCodeUnspsc190501.50473015': __('identifierSchemeCodeUnspsc190501.50473015'),
	'identifierSchemeCodeUnspsc190501.50473016': __('identifierSchemeCodeUnspsc190501.50473016'),
	'identifierSchemeCodeUnspsc190501.50473100': __('identifierSchemeCodeUnspsc190501.50473100'),
	'identifierSchemeCodeUnspsc190501.50473101': __('identifierSchemeCodeUnspsc190501.50473101'),
	'identifierSchemeCodeUnspsc190501.50473102': __('identifierSchemeCodeUnspsc190501.50473102'),
	'identifierSchemeCodeUnspsc190501.50473200': __('identifierSchemeCodeUnspsc190501.50473200'),
	'identifierSchemeCodeUnspsc190501.50473201': __('identifierSchemeCodeUnspsc190501.50473201'),
	'identifierSchemeCodeUnspsc190501.50473202': __('identifierSchemeCodeUnspsc190501.50473202'),
	'identifierSchemeCodeUnspsc190501.50473203': __('identifierSchemeCodeUnspsc190501.50473203'),
	'identifierSchemeCodeUnspsc190501.50473204': __('identifierSchemeCodeUnspsc190501.50473204'),
	'identifierSchemeCodeUnspsc190501.50473205': __('identifierSchemeCodeUnspsc190501.50473205'),
	'identifierSchemeCodeUnspsc190501.50473206': __('identifierSchemeCodeUnspsc190501.50473206'),
	'identifierSchemeCodeUnspsc190501.50473207': __('identifierSchemeCodeUnspsc190501.50473207'),
	'identifierSchemeCodeUnspsc190501.50473208': __('identifierSchemeCodeUnspsc190501.50473208'),
	'identifierSchemeCodeUnspsc190501.50473209': __('identifierSchemeCodeUnspsc190501.50473209'),
	'identifierSchemeCodeUnspsc190501.50473210': __('identifierSchemeCodeUnspsc190501.50473210'),
	'identifierSchemeCodeUnspsc190501.50473211': __('identifierSchemeCodeUnspsc190501.50473211'),
	'identifierSchemeCodeUnspsc190501.50473212': __('identifierSchemeCodeUnspsc190501.50473212'),
	'identifierSchemeCodeUnspsc190501.50473213': __('identifierSchemeCodeUnspsc190501.50473213'),
	'identifierSchemeCodeUnspsc190501.50473214': __('identifierSchemeCodeUnspsc190501.50473214'),
	'identifierSchemeCodeUnspsc190501.50473215': __('identifierSchemeCodeUnspsc190501.50473215'),
	'identifierSchemeCodeUnspsc190501.50473216': __('identifierSchemeCodeUnspsc190501.50473216'),
	'identifierSchemeCodeUnspsc190501.50473217': __('identifierSchemeCodeUnspsc190501.50473217'),
	'identifierSchemeCodeUnspsc190501.50473218': __('identifierSchemeCodeUnspsc190501.50473218'),
	'identifierSchemeCodeUnspsc190501.50473219': __('identifierSchemeCodeUnspsc190501.50473219'),
	'identifierSchemeCodeUnspsc190501.50473220': __('identifierSchemeCodeUnspsc190501.50473220'),
	'identifierSchemeCodeUnspsc190501.50473221': __('identifierSchemeCodeUnspsc190501.50473221'),
	'identifierSchemeCodeUnspsc190501.50473222': __('identifierSchemeCodeUnspsc190501.50473222'),
	'identifierSchemeCodeUnspsc190501.50473223': __('identifierSchemeCodeUnspsc190501.50473223'),
	'identifierSchemeCodeUnspsc190501.50473224': __('identifierSchemeCodeUnspsc190501.50473224'),
	'identifierSchemeCodeUnspsc190501.50473225': __('identifierSchemeCodeUnspsc190501.50473225'),
	'identifierSchemeCodeUnspsc190501.50473226': __('identifierSchemeCodeUnspsc190501.50473226'),
	'identifierSchemeCodeUnspsc190501.50473227': __('identifierSchemeCodeUnspsc190501.50473227'),
	'identifierSchemeCodeUnspsc190501.50473228': __('identifierSchemeCodeUnspsc190501.50473228'),
	'identifierSchemeCodeUnspsc190501.50473229': __('identifierSchemeCodeUnspsc190501.50473229'),
	'identifierSchemeCodeUnspsc190501.50473230': __('identifierSchemeCodeUnspsc190501.50473230'),
	'identifierSchemeCodeUnspsc190501.50473231': __('identifierSchemeCodeUnspsc190501.50473231'),
	'identifierSchemeCodeUnspsc190501.50473232': __('identifierSchemeCodeUnspsc190501.50473232'),
	'identifierSchemeCodeUnspsc190501.50473233': __('identifierSchemeCodeUnspsc190501.50473233'),
	'identifierSchemeCodeUnspsc190501.50473234': __('identifierSchemeCodeUnspsc190501.50473234'),
	'identifierSchemeCodeUnspsc190501.50473235': __('identifierSchemeCodeUnspsc190501.50473235'),
	'identifierSchemeCodeUnspsc190501.50473236': __('identifierSchemeCodeUnspsc190501.50473236'),
	'identifierSchemeCodeUnspsc190501.50473237': __('identifierSchemeCodeUnspsc190501.50473237'),
	'identifierSchemeCodeUnspsc190501.50473238': __('identifierSchemeCodeUnspsc190501.50473238'),
	'identifierSchemeCodeUnspsc190501.50473239': __('identifierSchemeCodeUnspsc190501.50473239'),
	'identifierSchemeCodeUnspsc190501.50473240': __('identifierSchemeCodeUnspsc190501.50473240'),
	'identifierSchemeCodeUnspsc190501.50473241': __('identifierSchemeCodeUnspsc190501.50473241'),
	'identifierSchemeCodeUnspsc190501.50473242': __('identifierSchemeCodeUnspsc190501.50473242'),
	'identifierSchemeCodeUnspsc190501.50473243': __('identifierSchemeCodeUnspsc190501.50473243'),
	'identifierSchemeCodeUnspsc190501.50473244': __('identifierSchemeCodeUnspsc190501.50473244'),
	'identifierSchemeCodeUnspsc190501.50473245': __('identifierSchemeCodeUnspsc190501.50473245'),
	'identifierSchemeCodeUnspsc190501.50473246': __('identifierSchemeCodeUnspsc190501.50473246'),
	'identifierSchemeCodeUnspsc190501.50473247': __('identifierSchemeCodeUnspsc190501.50473247'),
	'identifierSchemeCodeUnspsc190501.50473248': __('identifierSchemeCodeUnspsc190501.50473248'),
	'identifierSchemeCodeUnspsc190501.50473249': __('identifierSchemeCodeUnspsc190501.50473249'),
	'identifierSchemeCodeUnspsc190501.50473250': __('identifierSchemeCodeUnspsc190501.50473250'),
	'identifierSchemeCodeUnspsc190501.50473251': __('identifierSchemeCodeUnspsc190501.50473251'),
	'identifierSchemeCodeUnspsc190501.50473252': __('identifierSchemeCodeUnspsc190501.50473252'),
	'identifierSchemeCodeUnspsc190501.50473253': __('identifierSchemeCodeUnspsc190501.50473253'),
	'identifierSchemeCodeUnspsc190501.50473254': __('identifierSchemeCodeUnspsc190501.50473254'),
	'identifierSchemeCodeUnspsc190501.50473255': __('identifierSchemeCodeUnspsc190501.50473255'),
	'identifierSchemeCodeUnspsc190501.50473256': __('identifierSchemeCodeUnspsc190501.50473256'),
	'identifierSchemeCodeUnspsc190501.50473257': __('identifierSchemeCodeUnspsc190501.50473257'),
	'identifierSchemeCodeUnspsc190501.50473258': __('identifierSchemeCodeUnspsc190501.50473258'),
	'identifierSchemeCodeUnspsc190501.50473259': __('identifierSchemeCodeUnspsc190501.50473259'),
	'identifierSchemeCodeUnspsc190501.50473260': __('identifierSchemeCodeUnspsc190501.50473260'),
	'identifierSchemeCodeUnspsc190501.50473261': __('identifierSchemeCodeUnspsc190501.50473261'),
	'identifierSchemeCodeUnspsc190501.50473262': __('identifierSchemeCodeUnspsc190501.50473262'),
	'identifierSchemeCodeUnspsc190501.50473263': __('identifierSchemeCodeUnspsc190501.50473263'),
	'identifierSchemeCodeUnspsc190501.50473264': __('identifierSchemeCodeUnspsc190501.50473264'),
	'identifierSchemeCodeUnspsc190501.50473265': __('identifierSchemeCodeUnspsc190501.50473265'),
	'identifierSchemeCodeUnspsc190501.50473266': __('identifierSchemeCodeUnspsc190501.50473266'),
	'identifierSchemeCodeUnspsc190501.50473267': __('identifierSchemeCodeUnspsc190501.50473267'),
	'identifierSchemeCodeUnspsc190501.50473268': __('identifierSchemeCodeUnspsc190501.50473268'),
	'identifierSchemeCodeUnspsc190501.50473269': __('identifierSchemeCodeUnspsc190501.50473269'),
	'identifierSchemeCodeUnspsc190501.50473300': __('identifierSchemeCodeUnspsc190501.50473300'),
	'identifierSchemeCodeUnspsc190501.50473301': __('identifierSchemeCodeUnspsc190501.50473301'),
	'identifierSchemeCodeUnspsc190501.50473302': __('identifierSchemeCodeUnspsc190501.50473302'),
	'identifierSchemeCodeUnspsc190501.50473303': __('identifierSchemeCodeUnspsc190501.50473303'),
	'identifierSchemeCodeUnspsc190501.50473304': __('identifierSchemeCodeUnspsc190501.50473304'),
	'identifierSchemeCodeUnspsc190501.50473400': __('identifierSchemeCodeUnspsc190501.50473400'),
	'identifierSchemeCodeUnspsc190501.50473401': __('identifierSchemeCodeUnspsc190501.50473401'),
	'identifierSchemeCodeUnspsc190501.50473402': __('identifierSchemeCodeUnspsc190501.50473402'),
	'identifierSchemeCodeUnspsc190501.50473403': __('identifierSchemeCodeUnspsc190501.50473403'),
	'identifierSchemeCodeUnspsc190501.50473404': __('identifierSchemeCodeUnspsc190501.50473404'),
	'identifierSchemeCodeUnspsc190501.50473405': __('identifierSchemeCodeUnspsc190501.50473405'),
	'identifierSchemeCodeUnspsc190501.50473406': __('identifierSchemeCodeUnspsc190501.50473406'),
	'identifierSchemeCodeUnspsc190501.50473407': __('identifierSchemeCodeUnspsc190501.50473407'),
	'identifierSchemeCodeUnspsc190501.50473408': __('identifierSchemeCodeUnspsc190501.50473408'),
	'identifierSchemeCodeUnspsc190501.50473409': __('identifierSchemeCodeUnspsc190501.50473409'),
	'identifierSchemeCodeUnspsc190501.50473410': __('identifierSchemeCodeUnspsc190501.50473410'),
	'identifierSchemeCodeUnspsc190501.50473411': __('identifierSchemeCodeUnspsc190501.50473411'),
	'identifierSchemeCodeUnspsc190501.50473412': __('identifierSchemeCodeUnspsc190501.50473412'),
	'identifierSchemeCodeUnspsc190501.50473413': __('identifierSchemeCodeUnspsc190501.50473413'),
	'identifierSchemeCodeUnspsc190501.50473414': __('identifierSchemeCodeUnspsc190501.50473414'),
	'identifierSchemeCodeUnspsc190501.50473415': __('identifierSchemeCodeUnspsc190501.50473415'),
	'identifierSchemeCodeUnspsc190501.50473416': __('identifierSchemeCodeUnspsc190501.50473416'),
	'identifierSchemeCodeUnspsc190501.50473417': __('identifierSchemeCodeUnspsc190501.50473417'),
	'identifierSchemeCodeUnspsc190501.50473418': __('identifierSchemeCodeUnspsc190501.50473418'),
	'identifierSchemeCodeUnspsc190501.50473419': __('identifierSchemeCodeUnspsc190501.50473419'),
	'identifierSchemeCodeUnspsc190501.50473420': __('identifierSchemeCodeUnspsc190501.50473420'),
	'identifierSchemeCodeUnspsc190501.50473421': __('identifierSchemeCodeUnspsc190501.50473421'),
	'identifierSchemeCodeUnspsc190501.50473422': __('identifierSchemeCodeUnspsc190501.50473422'),
	'identifierSchemeCodeUnspsc190501.50473423': __('identifierSchemeCodeUnspsc190501.50473423'),
	'identifierSchemeCodeUnspsc190501.50473500': __('identifierSchemeCodeUnspsc190501.50473500'),
	'identifierSchemeCodeUnspsc190501.50473501': __('identifierSchemeCodeUnspsc190501.50473501'),
	'identifierSchemeCodeUnspsc190501.50473502': __('identifierSchemeCodeUnspsc190501.50473502'),
	'identifierSchemeCodeUnspsc190501.50473503': __('identifierSchemeCodeUnspsc190501.50473503'),
	'identifierSchemeCodeUnspsc190501.50473504': __('identifierSchemeCodeUnspsc190501.50473504'),
	'identifierSchemeCodeUnspsc190501.50473505': __('identifierSchemeCodeUnspsc190501.50473505'),
	'identifierSchemeCodeUnspsc190501.50473506': __('identifierSchemeCodeUnspsc190501.50473506'),
	'identifierSchemeCodeUnspsc190501.50473507': __('identifierSchemeCodeUnspsc190501.50473507'),
	'identifierSchemeCodeUnspsc190501.50473508': __('identifierSchemeCodeUnspsc190501.50473508'),
	'identifierSchemeCodeUnspsc190501.50473509': __('identifierSchemeCodeUnspsc190501.50473509'),
	'identifierSchemeCodeUnspsc190501.50473510': __('identifierSchemeCodeUnspsc190501.50473510'),
	'identifierSchemeCodeUnspsc190501.50473511': __('identifierSchemeCodeUnspsc190501.50473511'),
	'identifierSchemeCodeUnspsc190501.50473512': __('identifierSchemeCodeUnspsc190501.50473512'),
	'identifierSchemeCodeUnspsc190501.50473513': __('identifierSchemeCodeUnspsc190501.50473513'),
	'identifierSchemeCodeUnspsc190501.50473514': __('identifierSchemeCodeUnspsc190501.50473514'),
	'identifierSchemeCodeUnspsc190501.50473515': __('identifierSchemeCodeUnspsc190501.50473515'),
	'identifierSchemeCodeUnspsc190501.50473516': __('identifierSchemeCodeUnspsc190501.50473516'),
	'identifierSchemeCodeUnspsc190501.50473517': __('identifierSchemeCodeUnspsc190501.50473517'),
	'identifierSchemeCodeUnspsc190501.50473518': __('identifierSchemeCodeUnspsc190501.50473518'),
	'identifierSchemeCodeUnspsc190501.50473519': __('identifierSchemeCodeUnspsc190501.50473519'),
	'identifierSchemeCodeUnspsc190501.50473520': __('identifierSchemeCodeUnspsc190501.50473520'),
	'identifierSchemeCodeUnspsc190501.50473600': __('identifierSchemeCodeUnspsc190501.50473600'),
	'identifierSchemeCodeUnspsc190501.50473601': __('identifierSchemeCodeUnspsc190501.50473601'),
	'identifierSchemeCodeUnspsc190501.50473602': __('identifierSchemeCodeUnspsc190501.50473602'),
	'identifierSchemeCodeUnspsc190501.50473603': __('identifierSchemeCodeUnspsc190501.50473603'),
	'identifierSchemeCodeUnspsc190501.50473604': __('identifierSchemeCodeUnspsc190501.50473604'),
	'identifierSchemeCodeUnspsc190501.50473605': __('identifierSchemeCodeUnspsc190501.50473605'),
	'identifierSchemeCodeUnspsc190501.50473606': __('identifierSchemeCodeUnspsc190501.50473606'),
	'identifierSchemeCodeUnspsc190501.50473607': __('identifierSchemeCodeUnspsc190501.50473607'),
	'identifierSchemeCodeUnspsc190501.50473608': __('identifierSchemeCodeUnspsc190501.50473608'),
	'identifierSchemeCodeUnspsc190501.50473609': __('identifierSchemeCodeUnspsc190501.50473609'),
	'identifierSchemeCodeUnspsc190501.50473610': __('identifierSchemeCodeUnspsc190501.50473610'),
	'identifierSchemeCodeUnspsc190501.50473611': __('identifierSchemeCodeUnspsc190501.50473611'),
	'identifierSchemeCodeUnspsc190501.50473612': __('identifierSchemeCodeUnspsc190501.50473612'),
	'identifierSchemeCodeUnspsc190501.50473613': __('identifierSchemeCodeUnspsc190501.50473613'),
	'identifierSchemeCodeUnspsc190501.50473614': __('identifierSchemeCodeUnspsc190501.50473614'),
	'identifierSchemeCodeUnspsc190501.50473700': __('identifierSchemeCodeUnspsc190501.50473700'),
	'identifierSchemeCodeUnspsc190501.50473701': __('identifierSchemeCodeUnspsc190501.50473701'),
	'identifierSchemeCodeUnspsc190501.50473702': __('identifierSchemeCodeUnspsc190501.50473702'),
	'identifierSchemeCodeUnspsc190501.50473703': __('identifierSchemeCodeUnspsc190501.50473703'),
	'identifierSchemeCodeUnspsc190501.50473704': __('identifierSchemeCodeUnspsc190501.50473704'),
	'identifierSchemeCodeUnspsc190501.50473705': __('identifierSchemeCodeUnspsc190501.50473705'),
	'identifierSchemeCodeUnspsc190501.50473706': __('identifierSchemeCodeUnspsc190501.50473706'),
	'identifierSchemeCodeUnspsc190501.50473707': __('identifierSchemeCodeUnspsc190501.50473707'),
	'identifierSchemeCodeUnspsc190501.50473800': __('identifierSchemeCodeUnspsc190501.50473800'),
	'identifierSchemeCodeUnspsc190501.50473801': __('identifierSchemeCodeUnspsc190501.50473801'),
	'identifierSchemeCodeUnspsc190501.50473802': __('identifierSchemeCodeUnspsc190501.50473802'),
	'identifierSchemeCodeUnspsc190501.50473803': __('identifierSchemeCodeUnspsc190501.50473803'),
	'identifierSchemeCodeUnspsc190501.50473804': __('identifierSchemeCodeUnspsc190501.50473804'),
	'identifierSchemeCodeUnspsc190501.50473805': __('identifierSchemeCodeUnspsc190501.50473805'),
	'identifierSchemeCodeUnspsc190501.50473806': __('identifierSchemeCodeUnspsc190501.50473806'),
	'identifierSchemeCodeUnspsc190501.50473807': __('identifierSchemeCodeUnspsc190501.50473807'),
	'identifierSchemeCodeUnspsc190501.50473808': __('identifierSchemeCodeUnspsc190501.50473808'),
	'identifierSchemeCodeUnspsc190501.50473809': __('identifierSchemeCodeUnspsc190501.50473809'),
	'identifierSchemeCodeUnspsc190501.50473810': __('identifierSchemeCodeUnspsc190501.50473810'),
	'identifierSchemeCodeUnspsc190501.50473811': __('identifierSchemeCodeUnspsc190501.50473811'),
	'identifierSchemeCodeUnspsc190501.50473900': __('identifierSchemeCodeUnspsc190501.50473900'),
	'identifierSchemeCodeUnspsc190501.50473901': __('identifierSchemeCodeUnspsc190501.50473901'),
	'identifierSchemeCodeUnspsc190501.50473902': __('identifierSchemeCodeUnspsc190501.50473902'),
	'identifierSchemeCodeUnspsc190501.50473903': __('identifierSchemeCodeUnspsc190501.50473903'),
	'identifierSchemeCodeUnspsc190501.50473904': __('identifierSchemeCodeUnspsc190501.50473904'),
	'identifierSchemeCodeUnspsc190501.50473905': __('identifierSchemeCodeUnspsc190501.50473905'),
	'identifierSchemeCodeUnspsc190501.50473906': __('identifierSchemeCodeUnspsc190501.50473906'),
	'identifierSchemeCodeUnspsc190501.50473907': __('identifierSchemeCodeUnspsc190501.50473907'),
	'identifierSchemeCodeUnspsc190501.50473908': __('identifierSchemeCodeUnspsc190501.50473908'),
	'identifierSchemeCodeUnspsc190501.50473909': __('identifierSchemeCodeUnspsc190501.50473909'),
	'identifierSchemeCodeUnspsc190501.50473910': __('identifierSchemeCodeUnspsc190501.50473910'),
	'identifierSchemeCodeUnspsc190501.50473911': __('identifierSchemeCodeUnspsc190501.50473911'),
	'identifierSchemeCodeUnspsc190501.50474000': __('identifierSchemeCodeUnspsc190501.50474000'),
	'identifierSchemeCodeUnspsc190501.50474001': __('identifierSchemeCodeUnspsc190501.50474001'),
	'identifierSchemeCodeUnspsc190501.50474002': __('identifierSchemeCodeUnspsc190501.50474002'),
	'identifierSchemeCodeUnspsc190501.50474003': __('identifierSchemeCodeUnspsc190501.50474003'),
	'identifierSchemeCodeUnspsc190501.50474004': __('identifierSchemeCodeUnspsc190501.50474004'),
	'identifierSchemeCodeUnspsc190501.50474005': __('identifierSchemeCodeUnspsc190501.50474005'),
	'identifierSchemeCodeUnspsc190501.50474100': __('identifierSchemeCodeUnspsc190501.50474100'),
	'identifierSchemeCodeUnspsc190501.50474101': __('identifierSchemeCodeUnspsc190501.50474101'),
	'identifierSchemeCodeUnspsc190501.50474102': __('identifierSchemeCodeUnspsc190501.50474102'),
	'identifierSchemeCodeUnspsc190501.50474103': __('identifierSchemeCodeUnspsc190501.50474103'),
	'identifierSchemeCodeUnspsc190501.50474104': __('identifierSchemeCodeUnspsc190501.50474104'),
	'identifierSchemeCodeUnspsc190501.50474105': __('identifierSchemeCodeUnspsc190501.50474105'),
	'identifierSchemeCodeUnspsc190501.50474106': __('identifierSchemeCodeUnspsc190501.50474106'),
	'identifierSchemeCodeUnspsc190501.50474107': __('identifierSchemeCodeUnspsc190501.50474107'),
	'identifierSchemeCodeUnspsc190501.50474108': __('identifierSchemeCodeUnspsc190501.50474108'),
	'identifierSchemeCodeUnspsc190501.50474109': __('identifierSchemeCodeUnspsc190501.50474109'),
	'identifierSchemeCodeUnspsc190501.50474110': __('identifierSchemeCodeUnspsc190501.50474110'),
	'identifierSchemeCodeUnspsc190501.50474111': __('identifierSchemeCodeUnspsc190501.50474111'),
	'identifierSchemeCodeUnspsc190501.50474112': __('identifierSchemeCodeUnspsc190501.50474112'),
	'identifierSchemeCodeUnspsc190501.50474113': __('identifierSchemeCodeUnspsc190501.50474113'),
	'identifierSchemeCodeUnspsc190501.50474114': __('identifierSchemeCodeUnspsc190501.50474114'),
	'identifierSchemeCodeUnspsc190501.50474115': __('identifierSchemeCodeUnspsc190501.50474115'),
	'identifierSchemeCodeUnspsc190501.50474116': __('identifierSchemeCodeUnspsc190501.50474116'),
	'identifierSchemeCodeUnspsc190501.50474117': __('identifierSchemeCodeUnspsc190501.50474117'),
	'identifierSchemeCodeUnspsc190501.50474118': __('identifierSchemeCodeUnspsc190501.50474118'),
	'identifierSchemeCodeUnspsc190501.50474119': __('identifierSchemeCodeUnspsc190501.50474119'),
	'identifierSchemeCodeUnspsc190501.50474120': __('identifierSchemeCodeUnspsc190501.50474120'),
	'identifierSchemeCodeUnspsc190501.50474121': __('identifierSchemeCodeUnspsc190501.50474121'),
	'identifierSchemeCodeUnspsc190501.50474122': __('identifierSchemeCodeUnspsc190501.50474122'),
	'identifierSchemeCodeUnspsc190501.50474123': __('identifierSchemeCodeUnspsc190501.50474123'),
	'identifierSchemeCodeUnspsc190501.50474124': __('identifierSchemeCodeUnspsc190501.50474124'),
	'identifierSchemeCodeUnspsc190501.50474125': __('identifierSchemeCodeUnspsc190501.50474125'),
	'identifierSchemeCodeUnspsc190501.50474126': __('identifierSchemeCodeUnspsc190501.50474126'),
	'identifierSchemeCodeUnspsc190501.50474127': __('identifierSchemeCodeUnspsc190501.50474127'),
	'identifierSchemeCodeUnspsc190501.50474200': __('identifierSchemeCodeUnspsc190501.50474200'),
	'identifierSchemeCodeUnspsc190501.50474201': __('identifierSchemeCodeUnspsc190501.50474201'),
	'identifierSchemeCodeUnspsc190501.50474202': __('identifierSchemeCodeUnspsc190501.50474202'),
	'identifierSchemeCodeUnspsc190501.50474300': __('identifierSchemeCodeUnspsc190501.50474300'),
	'identifierSchemeCodeUnspsc190501.50474301': __('identifierSchemeCodeUnspsc190501.50474301'),
	'identifierSchemeCodeUnspsc190501.50474302': __('identifierSchemeCodeUnspsc190501.50474302'),
	'identifierSchemeCodeUnspsc190501.50474303': __('identifierSchemeCodeUnspsc190501.50474303'),
	'identifierSchemeCodeUnspsc190501.50474304': __('identifierSchemeCodeUnspsc190501.50474304'),
	'identifierSchemeCodeUnspsc190501.50474305': __('identifierSchemeCodeUnspsc190501.50474305'),
	'identifierSchemeCodeUnspsc190501.50474306': __('identifierSchemeCodeUnspsc190501.50474306'),
	'identifierSchemeCodeUnspsc190501.50474400': __('identifierSchemeCodeUnspsc190501.50474400'),
	'identifierSchemeCodeUnspsc190501.50474401': __('identifierSchemeCodeUnspsc190501.50474401'),
	'identifierSchemeCodeUnspsc190501.50474402': __('identifierSchemeCodeUnspsc190501.50474402'),
	'identifierSchemeCodeUnspsc190501.50474403': __('identifierSchemeCodeUnspsc190501.50474403'),
	'identifierSchemeCodeUnspsc190501.50474404': __('identifierSchemeCodeUnspsc190501.50474404'),
	'identifierSchemeCodeUnspsc190501.50474405': __('identifierSchemeCodeUnspsc190501.50474405'),
	'identifierSchemeCodeUnspsc190501.50474406': __('identifierSchemeCodeUnspsc190501.50474406'),
	'identifierSchemeCodeUnspsc190501.50474500': __('identifierSchemeCodeUnspsc190501.50474500'),
	'identifierSchemeCodeUnspsc190501.50474501': __('identifierSchemeCodeUnspsc190501.50474501'),
	'identifierSchemeCodeUnspsc190501.50474502': __('identifierSchemeCodeUnspsc190501.50474502'),
	'identifierSchemeCodeUnspsc190501.50474503': __('identifierSchemeCodeUnspsc190501.50474503'),
	'identifierSchemeCodeUnspsc190501.50474504': __('identifierSchemeCodeUnspsc190501.50474504'),
	'identifierSchemeCodeUnspsc190501.50474505': __('identifierSchemeCodeUnspsc190501.50474505'),
	'identifierSchemeCodeUnspsc190501.50474506': __('identifierSchemeCodeUnspsc190501.50474506'),
	'identifierSchemeCodeUnspsc190501.50474507': __('identifierSchemeCodeUnspsc190501.50474507'),
	'identifierSchemeCodeUnspsc190501.50474508': __('identifierSchemeCodeUnspsc190501.50474508'),
	'identifierSchemeCodeUnspsc190501.50474600': __('identifierSchemeCodeUnspsc190501.50474600'),
	'identifierSchemeCodeUnspsc190501.50474601': __('identifierSchemeCodeUnspsc190501.50474601'),
	'identifierSchemeCodeUnspsc190501.50474602': __('identifierSchemeCodeUnspsc190501.50474602'),
	'identifierSchemeCodeUnspsc190501.50474603': __('identifierSchemeCodeUnspsc190501.50474603'),
	'identifierSchemeCodeUnspsc190501.50474604': __('identifierSchemeCodeUnspsc190501.50474604'),
	'identifierSchemeCodeUnspsc190501.50474605': __('identifierSchemeCodeUnspsc190501.50474605'),
	'identifierSchemeCodeUnspsc190501.50474606': __('identifierSchemeCodeUnspsc190501.50474606'),
	'identifierSchemeCodeUnspsc190501.50474607': __('identifierSchemeCodeUnspsc190501.50474607'),
	'identifierSchemeCodeUnspsc190501.50474608': __('identifierSchemeCodeUnspsc190501.50474608'),
	'identifierSchemeCodeUnspsc190501.50474609': __('identifierSchemeCodeUnspsc190501.50474609'),
	'identifierSchemeCodeUnspsc190501.50474610': __('identifierSchemeCodeUnspsc190501.50474610'),
	'identifierSchemeCodeUnspsc190501.50474611': __('identifierSchemeCodeUnspsc190501.50474611'),
	'identifierSchemeCodeUnspsc190501.50474612': __('identifierSchemeCodeUnspsc190501.50474612'),
	'identifierSchemeCodeUnspsc190501.50474613': __('identifierSchemeCodeUnspsc190501.50474613'),
	'identifierSchemeCodeUnspsc190501.50474614': __('identifierSchemeCodeUnspsc190501.50474614'),
	'identifierSchemeCodeUnspsc190501.50474615': __('identifierSchemeCodeUnspsc190501.50474615'),
	'identifierSchemeCodeUnspsc190501.50474616': __('identifierSchemeCodeUnspsc190501.50474616'),
	'identifierSchemeCodeUnspsc190501.50474617': __('identifierSchemeCodeUnspsc190501.50474617'),
	'identifierSchemeCodeUnspsc190501.50474618': __('identifierSchemeCodeUnspsc190501.50474618'),
	'identifierSchemeCodeUnspsc190501.50474619': __('identifierSchemeCodeUnspsc190501.50474619'),
	'identifierSchemeCodeUnspsc190501.50474620': __('identifierSchemeCodeUnspsc190501.50474620'),
	'identifierSchemeCodeUnspsc190501.50474621': __('identifierSchemeCodeUnspsc190501.50474621'),
	'identifierSchemeCodeUnspsc190501.50474622': __('identifierSchemeCodeUnspsc190501.50474622'),
	'identifierSchemeCodeUnspsc190501.50474623': __('identifierSchemeCodeUnspsc190501.50474623'),
	'identifierSchemeCodeUnspsc190501.50474700': __('identifierSchemeCodeUnspsc190501.50474700'),
	'identifierSchemeCodeUnspsc190501.50474701': __('identifierSchemeCodeUnspsc190501.50474701'),
	'identifierSchemeCodeUnspsc190501.50474702': __('identifierSchemeCodeUnspsc190501.50474702'),
	'identifierSchemeCodeUnspsc190501.50474703': __('identifierSchemeCodeUnspsc190501.50474703'),
	'identifierSchemeCodeUnspsc190501.50474704': __('identifierSchemeCodeUnspsc190501.50474704'),
	'identifierSchemeCodeUnspsc190501.50474705': __('identifierSchemeCodeUnspsc190501.50474705'),
	'identifierSchemeCodeUnspsc190501.50474706': __('identifierSchemeCodeUnspsc190501.50474706'),
	'identifierSchemeCodeUnspsc190501.50474800': __('identifierSchemeCodeUnspsc190501.50474800'),
	'identifierSchemeCodeUnspsc190501.50474801': __('identifierSchemeCodeUnspsc190501.50474801'),
	'identifierSchemeCodeUnspsc190501.50474802': __('identifierSchemeCodeUnspsc190501.50474802'),
	'identifierSchemeCodeUnspsc190501.50474803': __('identifierSchemeCodeUnspsc190501.50474803'),
	'identifierSchemeCodeUnspsc190501.50474804': __('identifierSchemeCodeUnspsc190501.50474804'),
	'identifierSchemeCodeUnspsc190501.50474805': __('identifierSchemeCodeUnspsc190501.50474805'),
	'identifierSchemeCodeUnspsc190501.50474806': __('identifierSchemeCodeUnspsc190501.50474806'),
	'identifierSchemeCodeUnspsc190501.50474807': __('identifierSchemeCodeUnspsc190501.50474807'),
	'identifierSchemeCodeUnspsc190501.50474808': __('identifierSchemeCodeUnspsc190501.50474808'),
	'identifierSchemeCodeUnspsc190501.50474809': __('identifierSchemeCodeUnspsc190501.50474809'),
	'identifierSchemeCodeUnspsc190501.50474810': __('identifierSchemeCodeUnspsc190501.50474810'),
	'identifierSchemeCodeUnspsc190501.50474811': __('identifierSchemeCodeUnspsc190501.50474811'),
	'identifierSchemeCodeUnspsc190501.50474812': __('identifierSchemeCodeUnspsc190501.50474812'),
	'identifierSchemeCodeUnspsc190501.50474813': __('identifierSchemeCodeUnspsc190501.50474813'),
	'identifierSchemeCodeUnspsc190501.50474814': __('identifierSchemeCodeUnspsc190501.50474814'),
	'identifierSchemeCodeUnspsc190501.50474815': __('identifierSchemeCodeUnspsc190501.50474815'),
	'identifierSchemeCodeUnspsc190501.50474816': __('identifierSchemeCodeUnspsc190501.50474816'),
	'identifierSchemeCodeUnspsc190501.50474817': __('identifierSchemeCodeUnspsc190501.50474817'),
	'identifierSchemeCodeUnspsc190501.50474818': __('identifierSchemeCodeUnspsc190501.50474818'),
	'identifierSchemeCodeUnspsc190501.50474819': __('identifierSchemeCodeUnspsc190501.50474819'),
	'identifierSchemeCodeUnspsc190501.50474820': __('identifierSchemeCodeUnspsc190501.50474820'),
	'identifierSchemeCodeUnspsc190501.50474821': __('identifierSchemeCodeUnspsc190501.50474821'),
	'identifierSchemeCodeUnspsc190501.50474900': __('identifierSchemeCodeUnspsc190501.50474900'),
	'identifierSchemeCodeUnspsc190501.50474901': __('identifierSchemeCodeUnspsc190501.50474901'),
	'identifierSchemeCodeUnspsc190501.50474902': __('identifierSchemeCodeUnspsc190501.50474902'),
	'identifierSchemeCodeUnspsc190501.50474903': __('identifierSchemeCodeUnspsc190501.50474903'),
	'identifierSchemeCodeUnspsc190501.50474904': __('identifierSchemeCodeUnspsc190501.50474904'),
	'identifierSchemeCodeUnspsc190501.50474905': __('identifierSchemeCodeUnspsc190501.50474905'),
	'identifierSchemeCodeUnspsc190501.50474906': __('identifierSchemeCodeUnspsc190501.50474906'),
	'identifierSchemeCodeUnspsc190501.50475000': __('identifierSchemeCodeUnspsc190501.50475000'),
	'identifierSchemeCodeUnspsc190501.50475001': __('identifierSchemeCodeUnspsc190501.50475001'),
	'identifierSchemeCodeUnspsc190501.50475002': __('identifierSchemeCodeUnspsc190501.50475002'),
	'identifierSchemeCodeUnspsc190501.50475003': __('identifierSchemeCodeUnspsc190501.50475003'),
	'identifierSchemeCodeUnspsc190501.50475004': __('identifierSchemeCodeUnspsc190501.50475004'),
	'identifierSchemeCodeUnspsc190501.50475100': __('identifierSchemeCodeUnspsc190501.50475100'),
	'identifierSchemeCodeUnspsc190501.50475101': __('identifierSchemeCodeUnspsc190501.50475101'),
	'identifierSchemeCodeUnspsc190501.50475102': __('identifierSchemeCodeUnspsc190501.50475102'),
	'identifierSchemeCodeUnspsc190501.50475103': __('identifierSchemeCodeUnspsc190501.50475103'),
	'identifierSchemeCodeUnspsc190501.50475104': __('identifierSchemeCodeUnspsc190501.50475104'),
	'identifierSchemeCodeUnspsc190501.50475105': __('identifierSchemeCodeUnspsc190501.50475105'),
	'identifierSchemeCodeUnspsc190501.50475106': __('identifierSchemeCodeUnspsc190501.50475106'),
	'identifierSchemeCodeUnspsc190501.50475107': __('identifierSchemeCodeUnspsc190501.50475107'),
	'identifierSchemeCodeUnspsc190501.50475200': __('identifierSchemeCodeUnspsc190501.50475200'),
	'identifierSchemeCodeUnspsc190501.50475201': __('identifierSchemeCodeUnspsc190501.50475201'),
	'identifierSchemeCodeUnspsc190501.50475202': __('identifierSchemeCodeUnspsc190501.50475202'),
	'identifierSchemeCodeUnspsc190501.50475203': __('identifierSchemeCodeUnspsc190501.50475203'),
	'identifierSchemeCodeUnspsc190501.50475204': __('identifierSchemeCodeUnspsc190501.50475204'),
	'identifierSchemeCodeUnspsc190501.50475205': __('identifierSchemeCodeUnspsc190501.50475205'),
	'identifierSchemeCodeUnspsc190501.50475206': __('identifierSchemeCodeUnspsc190501.50475206'),
	'identifierSchemeCodeUnspsc190501.50475207': __('identifierSchemeCodeUnspsc190501.50475207'),
	'identifierSchemeCodeUnspsc190501.50475208': __('identifierSchemeCodeUnspsc190501.50475208'),
	'identifierSchemeCodeUnspsc190501.50475209': __('identifierSchemeCodeUnspsc190501.50475209'),
	'identifierSchemeCodeUnspsc190501.50475210': __('identifierSchemeCodeUnspsc190501.50475210'),
	'identifierSchemeCodeUnspsc190501.50475211': __('identifierSchemeCodeUnspsc190501.50475211'),
	'identifierSchemeCodeUnspsc190501.50475212': __('identifierSchemeCodeUnspsc190501.50475212'),
	'identifierSchemeCodeUnspsc190501.50475213': __('identifierSchemeCodeUnspsc190501.50475213'),
	'identifierSchemeCodeUnspsc190501.50475214': __('identifierSchemeCodeUnspsc190501.50475214'),
	'identifierSchemeCodeUnspsc190501.50475215': __('identifierSchemeCodeUnspsc190501.50475215'),
	'identifierSchemeCodeUnspsc190501.50475216': __('identifierSchemeCodeUnspsc190501.50475216'),
	'identifierSchemeCodeUnspsc190501.50475217': __('identifierSchemeCodeUnspsc190501.50475217'),
	'identifierSchemeCodeUnspsc190501.50475218': __('identifierSchemeCodeUnspsc190501.50475218'),
	'identifierSchemeCodeUnspsc190501.50475219': __('identifierSchemeCodeUnspsc190501.50475219'),
	'identifierSchemeCodeUnspsc190501.50475220': __('identifierSchemeCodeUnspsc190501.50475220'),
	'identifierSchemeCodeUnspsc190501.50475221': __('identifierSchemeCodeUnspsc190501.50475221'),
	'identifierSchemeCodeUnspsc190501.50475300': __('identifierSchemeCodeUnspsc190501.50475300'),
	'identifierSchemeCodeUnspsc190501.50475301': __('identifierSchemeCodeUnspsc190501.50475301'),
	'identifierSchemeCodeUnspsc190501.50475302': __('identifierSchemeCodeUnspsc190501.50475302'),
	'identifierSchemeCodeUnspsc190501.50475303': __('identifierSchemeCodeUnspsc190501.50475303'),
	'identifierSchemeCodeUnspsc190501.50475304': __('identifierSchemeCodeUnspsc190501.50475304'),
	'identifierSchemeCodeUnspsc190501.50475305': __('identifierSchemeCodeUnspsc190501.50475305'),
	'identifierSchemeCodeUnspsc190501.50475306': __('identifierSchemeCodeUnspsc190501.50475306'),
	'identifierSchemeCodeUnspsc190501.50475400': __('identifierSchemeCodeUnspsc190501.50475400'),
	'identifierSchemeCodeUnspsc190501.50475401': __('identifierSchemeCodeUnspsc190501.50475401'),
	'identifierSchemeCodeUnspsc190501.50475402': __('identifierSchemeCodeUnspsc190501.50475402'),
	'identifierSchemeCodeUnspsc190501.50475403': __('identifierSchemeCodeUnspsc190501.50475403'),
	'identifierSchemeCodeUnspsc190501.50475404': __('identifierSchemeCodeUnspsc190501.50475404'),
	'identifierSchemeCodeUnspsc190501.50475405': __('identifierSchemeCodeUnspsc190501.50475405'),
	'identifierSchemeCodeUnspsc190501.50475406': __('identifierSchemeCodeUnspsc190501.50475406'),
	'identifierSchemeCodeUnspsc190501.50475500': __('identifierSchemeCodeUnspsc190501.50475500'),
	'identifierSchemeCodeUnspsc190501.50475501': __('identifierSchemeCodeUnspsc190501.50475501'),
	'identifierSchemeCodeUnspsc190501.50475502': __('identifierSchemeCodeUnspsc190501.50475502'),
	'identifierSchemeCodeUnspsc190501.50475503': __('identifierSchemeCodeUnspsc190501.50475503'),
	'identifierSchemeCodeUnspsc190501.50475504': __('identifierSchemeCodeUnspsc190501.50475504'),
	'identifierSchemeCodeUnspsc190501.50475505': __('identifierSchemeCodeUnspsc190501.50475505'),
	'identifierSchemeCodeUnspsc190501.50475506': __('identifierSchemeCodeUnspsc190501.50475506'),
	'identifierSchemeCodeUnspsc190501.50475507': __('identifierSchemeCodeUnspsc190501.50475507'),
	'identifierSchemeCodeUnspsc190501.50475508': __('identifierSchemeCodeUnspsc190501.50475508'),
	'identifierSchemeCodeUnspsc190501.50475509': __('identifierSchemeCodeUnspsc190501.50475509'),
	'identifierSchemeCodeUnspsc190501.50475510': __('identifierSchemeCodeUnspsc190501.50475510'),
	'identifierSchemeCodeUnspsc190501.50475511': __('identifierSchemeCodeUnspsc190501.50475511'),
	'identifierSchemeCodeUnspsc190501.50475512': __('identifierSchemeCodeUnspsc190501.50475512'),
	'identifierSchemeCodeUnspsc190501.50475513': __('identifierSchemeCodeUnspsc190501.50475513'),
	'identifierSchemeCodeUnspsc190501.50475514': __('identifierSchemeCodeUnspsc190501.50475514'),
	'identifierSchemeCodeUnspsc190501.50475515': __('identifierSchemeCodeUnspsc190501.50475515'),
	'identifierSchemeCodeUnspsc190501.50475516': __('identifierSchemeCodeUnspsc190501.50475516'),
	'identifierSchemeCodeUnspsc190501.50475517': __('identifierSchemeCodeUnspsc190501.50475517'),
	'identifierSchemeCodeUnspsc190501.50475518': __('identifierSchemeCodeUnspsc190501.50475518'),
	'identifierSchemeCodeUnspsc190501.50475519': __('identifierSchemeCodeUnspsc190501.50475519'),
	'identifierSchemeCodeUnspsc190501.50475520': __('identifierSchemeCodeUnspsc190501.50475520'),
	'identifierSchemeCodeUnspsc190501.50475521': __('identifierSchemeCodeUnspsc190501.50475521'),
	'identifierSchemeCodeUnspsc190501.50475522': __('identifierSchemeCodeUnspsc190501.50475522'),
	'identifierSchemeCodeUnspsc190501.50475523': __('identifierSchemeCodeUnspsc190501.50475523'),
	'identifierSchemeCodeUnspsc190501.50475524': __('identifierSchemeCodeUnspsc190501.50475524'),
	'identifierSchemeCodeUnspsc190501.50475525': __('identifierSchemeCodeUnspsc190501.50475525'),
	'identifierSchemeCodeUnspsc190501.50475526': __('identifierSchemeCodeUnspsc190501.50475526'),
	'identifierSchemeCodeUnspsc190501.50475527': __('identifierSchemeCodeUnspsc190501.50475527'),
	'identifierSchemeCodeUnspsc190501.50475528': __('identifierSchemeCodeUnspsc190501.50475528'),
	'identifierSchemeCodeUnspsc190501.50475529': __('identifierSchemeCodeUnspsc190501.50475529'),
	'identifierSchemeCodeUnspsc190501.50475530': __('identifierSchemeCodeUnspsc190501.50475530'),
	'identifierSchemeCodeUnspsc190501.50475531': __('identifierSchemeCodeUnspsc190501.50475531'),
	'identifierSchemeCodeUnspsc190501.50475532': __('identifierSchemeCodeUnspsc190501.50475532'),
	'identifierSchemeCodeUnspsc190501.50475533': __('identifierSchemeCodeUnspsc190501.50475533'),
	'identifierSchemeCodeUnspsc190501.50475534': __('identifierSchemeCodeUnspsc190501.50475534'),
	'identifierSchemeCodeUnspsc190501.50475535': __('identifierSchemeCodeUnspsc190501.50475535'),
	'identifierSchemeCodeUnspsc190501.50475536': __('identifierSchemeCodeUnspsc190501.50475536'),
	'identifierSchemeCodeUnspsc190501.50475537': __('identifierSchemeCodeUnspsc190501.50475537'),
	'identifierSchemeCodeUnspsc190501.50475600': __('identifierSchemeCodeUnspsc190501.50475600'),
	'identifierSchemeCodeUnspsc190501.50475601': __('identifierSchemeCodeUnspsc190501.50475601'),
	'identifierSchemeCodeUnspsc190501.50475602': __('identifierSchemeCodeUnspsc190501.50475602'),
	'identifierSchemeCodeUnspsc190501.50475603': __('identifierSchemeCodeUnspsc190501.50475603'),
	'identifierSchemeCodeUnspsc190501.50475604': __('identifierSchemeCodeUnspsc190501.50475604'),
	'identifierSchemeCodeUnspsc190501.50475605': __('identifierSchemeCodeUnspsc190501.50475605'),
	'identifierSchemeCodeUnspsc190501.50475606': __('identifierSchemeCodeUnspsc190501.50475606'),
	'identifierSchemeCodeUnspsc190501.50475607': __('identifierSchemeCodeUnspsc190501.50475607'),
	'identifierSchemeCodeUnspsc190501.50475608': __('identifierSchemeCodeUnspsc190501.50475608'),
	'identifierSchemeCodeUnspsc190501.50475700': __('identifierSchemeCodeUnspsc190501.50475700'),
	'identifierSchemeCodeUnspsc190501.50475701': __('identifierSchemeCodeUnspsc190501.50475701'),
	'identifierSchemeCodeUnspsc190501.50475702': __('identifierSchemeCodeUnspsc190501.50475702'),
	'identifierSchemeCodeUnspsc190501.50475703': __('identifierSchemeCodeUnspsc190501.50475703'),
	'identifierSchemeCodeUnspsc190501.50475704': __('identifierSchemeCodeUnspsc190501.50475704'),
	'identifierSchemeCodeUnspsc190501.50475800': __('identifierSchemeCodeUnspsc190501.50475800'),
	'identifierSchemeCodeUnspsc190501.50475801': __('identifierSchemeCodeUnspsc190501.50475801'),
	'identifierSchemeCodeUnspsc190501.50475802': __('identifierSchemeCodeUnspsc190501.50475802'),
	'identifierSchemeCodeUnspsc190501.50475803': __('identifierSchemeCodeUnspsc190501.50475803'),
	'identifierSchemeCodeUnspsc190501.50475804': __('identifierSchemeCodeUnspsc190501.50475804'),
	'identifierSchemeCodeUnspsc190501.50475805': __('identifierSchemeCodeUnspsc190501.50475805'),
	'identifierSchemeCodeUnspsc190501.50475806': __('identifierSchemeCodeUnspsc190501.50475806'),
	'identifierSchemeCodeUnspsc190501.50475807': __('identifierSchemeCodeUnspsc190501.50475807'),
	'identifierSchemeCodeUnspsc190501.50475808': __('identifierSchemeCodeUnspsc190501.50475808'),
	'identifierSchemeCodeUnspsc190501.50475809': __('identifierSchemeCodeUnspsc190501.50475809'),
	'identifierSchemeCodeUnspsc190501.50475810': __('identifierSchemeCodeUnspsc190501.50475810'),
	'identifierSchemeCodeUnspsc190501.50475811': __('identifierSchemeCodeUnspsc190501.50475811'),
	'identifierSchemeCodeUnspsc190501.50475812': __('identifierSchemeCodeUnspsc190501.50475812'),
	'identifierSchemeCodeUnspsc190501.50475813': __('identifierSchemeCodeUnspsc190501.50475813'),
	'identifierSchemeCodeUnspsc190501.50475814': __('identifierSchemeCodeUnspsc190501.50475814'),
	'identifierSchemeCodeUnspsc190501.50475815': __('identifierSchemeCodeUnspsc190501.50475815'),
	'identifierSchemeCodeUnspsc190501.50475816': __('identifierSchemeCodeUnspsc190501.50475816'),
	'identifierSchemeCodeUnspsc190501.50475900': __('identifierSchemeCodeUnspsc190501.50475900'),
	'identifierSchemeCodeUnspsc190501.50475901': __('identifierSchemeCodeUnspsc190501.50475901'),
	'identifierSchemeCodeUnspsc190501.50475902': __('identifierSchemeCodeUnspsc190501.50475902'),
	'identifierSchemeCodeUnspsc190501.50475903': __('identifierSchemeCodeUnspsc190501.50475903'),
	'identifierSchemeCodeUnspsc190501.50475904': __('identifierSchemeCodeUnspsc190501.50475904'),
	'identifierSchemeCodeUnspsc190501.50475905': __('identifierSchemeCodeUnspsc190501.50475905'),
	'identifierSchemeCodeUnspsc190501.50475906': __('identifierSchemeCodeUnspsc190501.50475906'),
	'identifierSchemeCodeUnspsc190501.50475907': __('identifierSchemeCodeUnspsc190501.50475907'),
	'identifierSchemeCodeUnspsc190501.50475908': __('identifierSchemeCodeUnspsc190501.50475908'),
	'identifierSchemeCodeUnspsc190501.50475909': __('identifierSchemeCodeUnspsc190501.50475909'),
	'identifierSchemeCodeUnspsc190501.50475910': __('identifierSchemeCodeUnspsc190501.50475910'),
	'identifierSchemeCodeUnspsc190501.50475911': __('identifierSchemeCodeUnspsc190501.50475911'),
	'identifierSchemeCodeUnspsc190501.50476000': __('identifierSchemeCodeUnspsc190501.50476000'),
	'identifierSchemeCodeUnspsc190501.50476001': __('identifierSchemeCodeUnspsc190501.50476001'),
	'identifierSchemeCodeUnspsc190501.50476002': __('identifierSchemeCodeUnspsc190501.50476002'),
	'identifierSchemeCodeUnspsc190501.50476003': __('identifierSchemeCodeUnspsc190501.50476003'),
	'identifierSchemeCodeUnspsc190501.50476004': __('identifierSchemeCodeUnspsc190501.50476004'),
	'identifierSchemeCodeUnspsc190501.50476100': __('identifierSchemeCodeUnspsc190501.50476100'),
	'identifierSchemeCodeUnspsc190501.50476101': __('identifierSchemeCodeUnspsc190501.50476101'),
	'identifierSchemeCodeUnspsc190501.50476102': __('identifierSchemeCodeUnspsc190501.50476102'),
	'identifierSchemeCodeUnspsc190501.50476103': __('identifierSchemeCodeUnspsc190501.50476103'),
	'identifierSchemeCodeUnspsc190501.50476104': __('identifierSchemeCodeUnspsc190501.50476104'),
	'identifierSchemeCodeUnspsc190501.50476105': __('identifierSchemeCodeUnspsc190501.50476105'),
	'identifierSchemeCodeUnspsc190501.50476106': __('identifierSchemeCodeUnspsc190501.50476106'),
	'identifierSchemeCodeUnspsc190501.50476107': __('identifierSchemeCodeUnspsc190501.50476107'),
	'identifierSchemeCodeUnspsc190501.50476108': __('identifierSchemeCodeUnspsc190501.50476108'),
	'identifierSchemeCodeUnspsc190501.50476109': __('identifierSchemeCodeUnspsc190501.50476109'),
	'identifierSchemeCodeUnspsc190501.50476110': __('identifierSchemeCodeUnspsc190501.50476110'),
	'identifierSchemeCodeUnspsc190501.50476111': __('identifierSchemeCodeUnspsc190501.50476111'),
	'identifierSchemeCodeUnspsc190501.50476112': __('identifierSchemeCodeUnspsc190501.50476112'),
	'identifierSchemeCodeUnspsc190501.50476113': __('identifierSchemeCodeUnspsc190501.50476113'),
	'identifierSchemeCodeUnspsc190501.50476114': __('identifierSchemeCodeUnspsc190501.50476114'),
	'identifierSchemeCodeUnspsc190501.50476115': __('identifierSchemeCodeUnspsc190501.50476115'),
	'identifierSchemeCodeUnspsc190501.50476116': __('identifierSchemeCodeUnspsc190501.50476116'),
	'identifierSchemeCodeUnspsc190501.50476200': __('identifierSchemeCodeUnspsc190501.50476200'),
	'identifierSchemeCodeUnspsc190501.50476201': __('identifierSchemeCodeUnspsc190501.50476201'),
	'identifierSchemeCodeUnspsc190501.50476202': __('identifierSchemeCodeUnspsc190501.50476202'),
	'identifierSchemeCodeUnspsc190501.50476203': __('identifierSchemeCodeUnspsc190501.50476203'),
	'identifierSchemeCodeUnspsc190501.50476204': __('identifierSchemeCodeUnspsc190501.50476204'),
	'identifierSchemeCodeUnspsc190501.50476205': __('identifierSchemeCodeUnspsc190501.50476205'),
	'identifierSchemeCodeUnspsc190501.50476206': __('identifierSchemeCodeUnspsc190501.50476206'),
	'identifierSchemeCodeUnspsc190501.50476207': __('identifierSchemeCodeUnspsc190501.50476207'),
	'identifierSchemeCodeUnspsc190501.50476208': __('identifierSchemeCodeUnspsc190501.50476208'),
	'identifierSchemeCodeUnspsc190501.50476209': __('identifierSchemeCodeUnspsc190501.50476209'),
	'identifierSchemeCodeUnspsc190501.50476210': __('identifierSchemeCodeUnspsc190501.50476210'),
	'identifierSchemeCodeUnspsc190501.50476211': __('identifierSchemeCodeUnspsc190501.50476211'),
	'identifierSchemeCodeUnspsc190501.50476212': __('identifierSchemeCodeUnspsc190501.50476212'),
	'identifierSchemeCodeUnspsc190501.50476213': __('identifierSchemeCodeUnspsc190501.50476213'),
	'identifierSchemeCodeUnspsc190501.50476214': __('identifierSchemeCodeUnspsc190501.50476214'),
	'identifierSchemeCodeUnspsc190501.50476215': __('identifierSchemeCodeUnspsc190501.50476215'),
	'identifierSchemeCodeUnspsc190501.50476216': __('identifierSchemeCodeUnspsc190501.50476216'),
	'identifierSchemeCodeUnspsc190501.50476217': __('identifierSchemeCodeUnspsc190501.50476217'),
	'identifierSchemeCodeUnspsc190501.50476218': __('identifierSchemeCodeUnspsc190501.50476218'),
	'identifierSchemeCodeUnspsc190501.50476219': __('identifierSchemeCodeUnspsc190501.50476219'),
	'identifierSchemeCodeUnspsc190501.50476220': __('identifierSchemeCodeUnspsc190501.50476220'),
	'identifierSchemeCodeUnspsc190501.50476221': __('identifierSchemeCodeUnspsc190501.50476221'),
	'identifierSchemeCodeUnspsc190501.50476222': __('identifierSchemeCodeUnspsc190501.50476222'),
	'identifierSchemeCodeUnspsc190501.50476223': __('identifierSchemeCodeUnspsc190501.50476223'),
	'identifierSchemeCodeUnspsc190501.50476224': __('identifierSchemeCodeUnspsc190501.50476224'),
	'identifierSchemeCodeUnspsc190501.50476225': __('identifierSchemeCodeUnspsc190501.50476225'),
	'identifierSchemeCodeUnspsc190501.50476226': __('identifierSchemeCodeUnspsc190501.50476226'),
	'identifierSchemeCodeUnspsc190501.50476227': __('identifierSchemeCodeUnspsc190501.50476227'),
	'identifierSchemeCodeUnspsc190501.50476228': __('identifierSchemeCodeUnspsc190501.50476228'),
	'identifierSchemeCodeUnspsc190501.50476229': __('identifierSchemeCodeUnspsc190501.50476229'),
	'identifierSchemeCodeUnspsc190501.50476300': __('identifierSchemeCodeUnspsc190501.50476300'),
	'identifierSchemeCodeUnspsc190501.50476301': __('identifierSchemeCodeUnspsc190501.50476301'),
	'identifierSchemeCodeUnspsc190501.50476302': __('identifierSchemeCodeUnspsc190501.50476302'),
	'identifierSchemeCodeUnspsc190501.50476303': __('identifierSchemeCodeUnspsc190501.50476303'),
	'identifierSchemeCodeUnspsc190501.50476304': __('identifierSchemeCodeUnspsc190501.50476304'),
	'identifierSchemeCodeUnspsc190501.50476305': __('identifierSchemeCodeUnspsc190501.50476305'),
	'identifierSchemeCodeUnspsc190501.50476306': __('identifierSchemeCodeUnspsc190501.50476306'),
	'identifierSchemeCodeUnspsc190501.50476307': __('identifierSchemeCodeUnspsc190501.50476307'),
	'identifierSchemeCodeUnspsc190501.50476308': __('identifierSchemeCodeUnspsc190501.50476308'),
	'identifierSchemeCodeUnspsc190501.50476309': __('identifierSchemeCodeUnspsc190501.50476309'),
	'identifierSchemeCodeUnspsc190501.50476310': __('identifierSchemeCodeUnspsc190501.50476310'),
	'identifierSchemeCodeUnspsc190501.50476311': __('identifierSchemeCodeUnspsc190501.50476311'),
	'identifierSchemeCodeUnspsc190501.50476312': __('identifierSchemeCodeUnspsc190501.50476312'),
	'identifierSchemeCodeUnspsc190501.50476313': __('identifierSchemeCodeUnspsc190501.50476313'),
	'identifierSchemeCodeUnspsc190501.50476314': __('identifierSchemeCodeUnspsc190501.50476314'),
	'identifierSchemeCodeUnspsc190501.50476315': __('identifierSchemeCodeUnspsc190501.50476315'),
	'identifierSchemeCodeUnspsc190501.50476316': __('identifierSchemeCodeUnspsc190501.50476316'),
	'identifierSchemeCodeUnspsc190501.50476317': __('identifierSchemeCodeUnspsc190501.50476317'),
	'identifierSchemeCodeUnspsc190501.50476318': __('identifierSchemeCodeUnspsc190501.50476318'),
	'identifierSchemeCodeUnspsc190501.50476319': __('identifierSchemeCodeUnspsc190501.50476319'),
	'identifierSchemeCodeUnspsc190501.50476320': __('identifierSchemeCodeUnspsc190501.50476320'),
	'identifierSchemeCodeUnspsc190501.50476400': __('identifierSchemeCodeUnspsc190501.50476400'),
	'identifierSchemeCodeUnspsc190501.50476401': __('identifierSchemeCodeUnspsc190501.50476401'),
	'identifierSchemeCodeUnspsc190501.50476402': __('identifierSchemeCodeUnspsc190501.50476402'),
	'identifierSchemeCodeUnspsc190501.50476403': __('identifierSchemeCodeUnspsc190501.50476403'),
	'identifierSchemeCodeUnspsc190501.50476404': __('identifierSchemeCodeUnspsc190501.50476404'),
	'identifierSchemeCodeUnspsc190501.50476405': __('identifierSchemeCodeUnspsc190501.50476405'),
	'identifierSchemeCodeUnspsc190501.50476406': __('identifierSchemeCodeUnspsc190501.50476406'),
	'identifierSchemeCodeUnspsc190501.50476407': __('identifierSchemeCodeUnspsc190501.50476407'),
	'identifierSchemeCodeUnspsc190501.50476408': __('identifierSchemeCodeUnspsc190501.50476408'),
	'identifierSchemeCodeUnspsc190501.50476409': __('identifierSchemeCodeUnspsc190501.50476409'),
	'identifierSchemeCodeUnspsc190501.50476410': __('identifierSchemeCodeUnspsc190501.50476410'),
	'identifierSchemeCodeUnspsc190501.50476411': __('identifierSchemeCodeUnspsc190501.50476411'),
	'identifierSchemeCodeUnspsc190501.50476412': __('identifierSchemeCodeUnspsc190501.50476412'),
	'identifierSchemeCodeUnspsc190501.50476413': __('identifierSchemeCodeUnspsc190501.50476413'),
	'identifierSchemeCodeUnspsc190501.50476414': __('identifierSchemeCodeUnspsc190501.50476414'),
	'identifierSchemeCodeUnspsc190501.50476415': __('identifierSchemeCodeUnspsc190501.50476415'),
	'identifierSchemeCodeUnspsc190501.50476416': __('identifierSchemeCodeUnspsc190501.50476416'),
	'identifierSchemeCodeUnspsc190501.50476417': __('identifierSchemeCodeUnspsc190501.50476417'),
	'identifierSchemeCodeUnspsc190501.50476418': __('identifierSchemeCodeUnspsc190501.50476418'),
	'identifierSchemeCodeUnspsc190501.50476419': __('identifierSchemeCodeUnspsc190501.50476419'),
	'identifierSchemeCodeUnspsc190501.50476420': __('identifierSchemeCodeUnspsc190501.50476420'),
	'identifierSchemeCodeUnspsc190501.50476421': __('identifierSchemeCodeUnspsc190501.50476421'),
	'identifierSchemeCodeUnspsc190501.50476422': __('identifierSchemeCodeUnspsc190501.50476422'),
	'identifierSchemeCodeUnspsc190501.50476423': __('identifierSchemeCodeUnspsc190501.50476423'),
	'identifierSchemeCodeUnspsc190501.50476424': __('identifierSchemeCodeUnspsc190501.50476424'),
	'identifierSchemeCodeUnspsc190501.50476425': __('identifierSchemeCodeUnspsc190501.50476425'),
	'identifierSchemeCodeUnspsc190501.50476426': __('identifierSchemeCodeUnspsc190501.50476426'),
	'identifierSchemeCodeUnspsc190501.50476427': __('identifierSchemeCodeUnspsc190501.50476427'),
	'identifierSchemeCodeUnspsc190501.50476500': __('identifierSchemeCodeUnspsc190501.50476500'),
	'identifierSchemeCodeUnspsc190501.50476501': __('identifierSchemeCodeUnspsc190501.50476501'),
	'identifierSchemeCodeUnspsc190501.50476502': __('identifierSchemeCodeUnspsc190501.50476502'),
	'identifierSchemeCodeUnspsc190501.50476503': __('identifierSchemeCodeUnspsc190501.50476503'),
	'identifierSchemeCodeUnspsc190501.50476504': __('identifierSchemeCodeUnspsc190501.50476504'),
	'identifierSchemeCodeUnspsc190501.50476505': __('identifierSchemeCodeUnspsc190501.50476505'),
	'identifierSchemeCodeUnspsc190501.50476506': __('identifierSchemeCodeUnspsc190501.50476506'),
	'identifierSchemeCodeUnspsc190501.50476507': __('identifierSchemeCodeUnspsc190501.50476507'),
	'identifierSchemeCodeUnspsc190501.50476508': __('identifierSchemeCodeUnspsc190501.50476508'),
	'identifierSchemeCodeUnspsc190501.50476600': __('identifierSchemeCodeUnspsc190501.50476600'),
	'identifierSchemeCodeUnspsc190501.50476601': __('identifierSchemeCodeUnspsc190501.50476601'),
	'identifierSchemeCodeUnspsc190501.50476602': __('identifierSchemeCodeUnspsc190501.50476602'),
	'identifierSchemeCodeUnspsc190501.50476603': __('identifierSchemeCodeUnspsc190501.50476603'),
	'identifierSchemeCodeUnspsc190501.50476604': __('identifierSchemeCodeUnspsc190501.50476604'),
	'identifierSchemeCodeUnspsc190501.50476605': __('identifierSchemeCodeUnspsc190501.50476605'),
	'identifierSchemeCodeUnspsc190501.50476606': __('identifierSchemeCodeUnspsc190501.50476606'),
	'identifierSchemeCodeUnspsc190501.50476607': __('identifierSchemeCodeUnspsc190501.50476607'),
	'identifierSchemeCodeUnspsc190501.50476608': __('identifierSchemeCodeUnspsc190501.50476608'),
	'identifierSchemeCodeUnspsc190501.50476609': __('identifierSchemeCodeUnspsc190501.50476609'),
	'identifierSchemeCodeUnspsc190501.50476610': __('identifierSchemeCodeUnspsc190501.50476610'),
	'identifierSchemeCodeUnspsc190501.50476611': __('identifierSchemeCodeUnspsc190501.50476611'),
	'identifierSchemeCodeUnspsc190501.50476612': __('identifierSchemeCodeUnspsc190501.50476612'),
	'identifierSchemeCodeUnspsc190501.50476613': __('identifierSchemeCodeUnspsc190501.50476613'),
	'identifierSchemeCodeUnspsc190501.50476614': __('identifierSchemeCodeUnspsc190501.50476614'),
	'identifierSchemeCodeUnspsc190501.50476615': __('identifierSchemeCodeUnspsc190501.50476615'),
	'identifierSchemeCodeUnspsc190501.50476616': __('identifierSchemeCodeUnspsc190501.50476616'),
	'identifierSchemeCodeUnspsc190501.50476617': __('identifierSchemeCodeUnspsc190501.50476617'),
	'identifierSchemeCodeUnspsc190501.50476618': __('identifierSchemeCodeUnspsc190501.50476618'),
	'identifierSchemeCodeUnspsc190501.50476619': __('identifierSchemeCodeUnspsc190501.50476619'),
	'identifierSchemeCodeUnspsc190501.50476620': __('identifierSchemeCodeUnspsc190501.50476620'),
	'identifierSchemeCodeUnspsc190501.50476700': __('identifierSchemeCodeUnspsc190501.50476700'),
	'identifierSchemeCodeUnspsc190501.50476701': __('identifierSchemeCodeUnspsc190501.50476701'),
	'identifierSchemeCodeUnspsc190501.50476702': __('identifierSchemeCodeUnspsc190501.50476702'),
	'identifierSchemeCodeUnspsc190501.50476703': __('identifierSchemeCodeUnspsc190501.50476703'),
	'identifierSchemeCodeUnspsc190501.50476704': __('identifierSchemeCodeUnspsc190501.50476704'),
	'identifierSchemeCodeUnspsc190501.50476705': __('identifierSchemeCodeUnspsc190501.50476705'),
	'identifierSchemeCodeUnspsc190501.50476706': __('identifierSchemeCodeUnspsc190501.50476706'),
	'identifierSchemeCodeUnspsc190501.50476707': __('identifierSchemeCodeUnspsc190501.50476707'),
	'identifierSchemeCodeUnspsc190501.50476708': __('identifierSchemeCodeUnspsc190501.50476708'),
	'identifierSchemeCodeUnspsc190501.50476709': __('identifierSchemeCodeUnspsc190501.50476709'),
	'identifierSchemeCodeUnspsc190501.50476710': __('identifierSchemeCodeUnspsc190501.50476710'),
	'identifierSchemeCodeUnspsc190501.50476800': __('identifierSchemeCodeUnspsc190501.50476800'),
	'identifierSchemeCodeUnspsc190501.50476801': __('identifierSchemeCodeUnspsc190501.50476801'),
	'identifierSchemeCodeUnspsc190501.50476802': __('identifierSchemeCodeUnspsc190501.50476802'),
	'identifierSchemeCodeUnspsc190501.50476803': __('identifierSchemeCodeUnspsc190501.50476803'),
	'identifierSchemeCodeUnspsc190501.50476804': __('identifierSchemeCodeUnspsc190501.50476804'),
	'identifierSchemeCodeUnspsc190501.50476805': __('identifierSchemeCodeUnspsc190501.50476805'),
	'identifierSchemeCodeUnspsc190501.50476806': __('identifierSchemeCodeUnspsc190501.50476806'),
	'identifierSchemeCodeUnspsc190501.50476807': __('identifierSchemeCodeUnspsc190501.50476807'),
	'identifierSchemeCodeUnspsc190501.50476808': __('identifierSchemeCodeUnspsc190501.50476808'),
	'identifierSchemeCodeUnspsc190501.50476809': __('identifierSchemeCodeUnspsc190501.50476809'),
	'identifierSchemeCodeUnspsc190501.50476810': __('identifierSchemeCodeUnspsc190501.50476810'),
	'identifierSchemeCodeUnspsc190501.50476811': __('identifierSchemeCodeUnspsc190501.50476811'),
	'identifierSchemeCodeUnspsc190501.50476812': __('identifierSchemeCodeUnspsc190501.50476812'),
	'identifierSchemeCodeUnspsc190501.50476813': __('identifierSchemeCodeUnspsc190501.50476813'),
	'identifierSchemeCodeUnspsc190501.50476814': __('identifierSchemeCodeUnspsc190501.50476814'),
	'identifierSchemeCodeUnspsc190501.50476815': __('identifierSchemeCodeUnspsc190501.50476815'),
	'identifierSchemeCodeUnspsc190501.50476816': __('identifierSchemeCodeUnspsc190501.50476816'),
	'identifierSchemeCodeUnspsc190501.50476817': __('identifierSchemeCodeUnspsc190501.50476817'),
	'identifierSchemeCodeUnspsc190501.50476818': __('identifierSchemeCodeUnspsc190501.50476818'),
	'identifierSchemeCodeUnspsc190501.50476819': __('identifierSchemeCodeUnspsc190501.50476819'),
	'identifierSchemeCodeUnspsc190501.50476820': __('identifierSchemeCodeUnspsc190501.50476820'),
	'identifierSchemeCodeUnspsc190501.50476821': __('identifierSchemeCodeUnspsc190501.50476821'),
	'identifierSchemeCodeUnspsc190501.50476822': __('identifierSchemeCodeUnspsc190501.50476822'),
	'identifierSchemeCodeUnspsc190501.50476823': __('identifierSchemeCodeUnspsc190501.50476823'),
	'identifierSchemeCodeUnspsc190501.50476824': __('identifierSchemeCodeUnspsc190501.50476824'),
	'identifierSchemeCodeUnspsc190501.50476825': __('identifierSchemeCodeUnspsc190501.50476825'),
	'identifierSchemeCodeUnspsc190501.50476826': __('identifierSchemeCodeUnspsc190501.50476826'),
	'identifierSchemeCodeUnspsc190501.50476827': __('identifierSchemeCodeUnspsc190501.50476827'),
	'identifierSchemeCodeUnspsc190501.50476828': __('identifierSchemeCodeUnspsc190501.50476828'),
	'identifierSchemeCodeUnspsc190501.50476829': __('identifierSchemeCodeUnspsc190501.50476829'),
	'identifierSchemeCodeUnspsc190501.50476830': __('identifierSchemeCodeUnspsc190501.50476830'),
	'identifierSchemeCodeUnspsc190501.50476831': __('identifierSchemeCodeUnspsc190501.50476831'),
	'identifierSchemeCodeUnspsc190501.50476832': __('identifierSchemeCodeUnspsc190501.50476832'),
	'identifierSchemeCodeUnspsc190501.50476833': __('identifierSchemeCodeUnspsc190501.50476833'),
	'identifierSchemeCodeUnspsc190501.50476834': __('identifierSchemeCodeUnspsc190501.50476834'),
	'identifierSchemeCodeUnspsc190501.50476835': __('identifierSchemeCodeUnspsc190501.50476835'),
	'identifierSchemeCodeUnspsc190501.50476836': __('identifierSchemeCodeUnspsc190501.50476836'),
	'identifierSchemeCodeUnspsc190501.50476837': __('identifierSchemeCodeUnspsc190501.50476837'),
	'identifierSchemeCodeUnspsc190501.50476838': __('identifierSchemeCodeUnspsc190501.50476838'),
	'identifierSchemeCodeUnspsc190501.50476839': __('identifierSchemeCodeUnspsc190501.50476839'),
	'identifierSchemeCodeUnspsc190501.50476840': __('identifierSchemeCodeUnspsc190501.50476840'),
	'identifierSchemeCodeUnspsc190501.50476841': __('identifierSchemeCodeUnspsc190501.50476841'),
	'identifierSchemeCodeUnspsc190501.50476842': __('identifierSchemeCodeUnspsc190501.50476842'),
	'identifierSchemeCodeUnspsc190501.50476843': __('identifierSchemeCodeUnspsc190501.50476843'),
	'identifierSchemeCodeUnspsc190501.50476844': __('identifierSchemeCodeUnspsc190501.50476844'),
	'identifierSchemeCodeUnspsc190501.50476845': __('identifierSchemeCodeUnspsc190501.50476845'),
	'identifierSchemeCodeUnspsc190501.50476846': __('identifierSchemeCodeUnspsc190501.50476846'),
	'identifierSchemeCodeUnspsc190501.50476847': __('identifierSchemeCodeUnspsc190501.50476847'),
	'identifierSchemeCodeUnspsc190501.50476848': __('identifierSchemeCodeUnspsc190501.50476848'),
	'identifierSchemeCodeUnspsc190501.50476849': __('identifierSchemeCodeUnspsc190501.50476849'),
	'identifierSchemeCodeUnspsc190501.50476850': __('identifierSchemeCodeUnspsc190501.50476850'),
	'identifierSchemeCodeUnspsc190501.50476851': __('identifierSchemeCodeUnspsc190501.50476851'),
	'identifierSchemeCodeUnspsc190501.50476852': __('identifierSchemeCodeUnspsc190501.50476852'),
	'identifierSchemeCodeUnspsc190501.50476853': __('identifierSchemeCodeUnspsc190501.50476853'),
	'identifierSchemeCodeUnspsc190501.50476854': __('identifierSchemeCodeUnspsc190501.50476854'),
	'identifierSchemeCodeUnspsc190501.50476855': __('identifierSchemeCodeUnspsc190501.50476855'),
	'identifierSchemeCodeUnspsc190501.50476856': __('identifierSchemeCodeUnspsc190501.50476856'),
	'identifierSchemeCodeUnspsc190501.50476857': __('identifierSchemeCodeUnspsc190501.50476857'),
	'identifierSchemeCodeUnspsc190501.50476858': __('identifierSchemeCodeUnspsc190501.50476858'),
	'identifierSchemeCodeUnspsc190501.50476859': __('identifierSchemeCodeUnspsc190501.50476859'),
	'identifierSchemeCodeUnspsc190501.50476860': __('identifierSchemeCodeUnspsc190501.50476860'),
	'identifierSchemeCodeUnspsc190501.50476861': __('identifierSchemeCodeUnspsc190501.50476861'),
	'identifierSchemeCodeUnspsc190501.50476862': __('identifierSchemeCodeUnspsc190501.50476862'),
	'identifierSchemeCodeUnspsc190501.50476863': __('identifierSchemeCodeUnspsc190501.50476863'),
	'identifierSchemeCodeUnspsc190501.50476864': __('identifierSchemeCodeUnspsc190501.50476864'),
	'identifierSchemeCodeUnspsc190501.50476865': __('identifierSchemeCodeUnspsc190501.50476865'),
	'identifierSchemeCodeUnspsc190501.50476866': __('identifierSchemeCodeUnspsc190501.50476866'),
	'identifierSchemeCodeUnspsc190501.50476867': __('identifierSchemeCodeUnspsc190501.50476867'),
	'identifierSchemeCodeUnspsc190501.50476868': __('identifierSchemeCodeUnspsc190501.50476868'),
	'identifierSchemeCodeUnspsc190501.50476900': __('identifierSchemeCodeUnspsc190501.50476900'),
	'identifierSchemeCodeUnspsc190501.50476901': __('identifierSchemeCodeUnspsc190501.50476901'),
	'identifierSchemeCodeUnspsc190501.50476902': __('identifierSchemeCodeUnspsc190501.50476902'),
	'identifierSchemeCodeUnspsc190501.50476903': __('identifierSchemeCodeUnspsc190501.50476903'),
	'identifierSchemeCodeUnspsc190501.50476904': __('identifierSchemeCodeUnspsc190501.50476904'),
	'identifierSchemeCodeUnspsc190501.50476905': __('identifierSchemeCodeUnspsc190501.50476905'),
	'identifierSchemeCodeUnspsc190501.50476906': __('identifierSchemeCodeUnspsc190501.50476906'),
	'identifierSchemeCodeUnspsc190501.50476907': __('identifierSchemeCodeUnspsc190501.50476907'),
	'identifierSchemeCodeUnspsc190501.50476908': __('identifierSchemeCodeUnspsc190501.50476908'),
	'identifierSchemeCodeUnspsc190501.50476909': __('identifierSchemeCodeUnspsc190501.50476909'),
	'identifierSchemeCodeUnspsc190501.50476910': __('identifierSchemeCodeUnspsc190501.50476910'),
	'identifierSchemeCodeUnspsc190501.50480000': __('identifierSchemeCodeUnspsc190501.50480000'),
	'identifierSchemeCodeUnspsc190501.50481500': __('identifierSchemeCodeUnspsc190501.50481500'),
	'identifierSchemeCodeUnspsc190501.50481501': __('identifierSchemeCodeUnspsc190501.50481501'),
	'identifierSchemeCodeUnspsc190501.50481502': __('identifierSchemeCodeUnspsc190501.50481502'),
	'identifierSchemeCodeUnspsc190501.50481503': __('identifierSchemeCodeUnspsc190501.50481503'),
	'identifierSchemeCodeUnspsc190501.50481504': __('identifierSchemeCodeUnspsc190501.50481504'),
	'identifierSchemeCodeUnspsc190501.50481505': __('identifierSchemeCodeUnspsc190501.50481505'),
	'identifierSchemeCodeUnspsc190501.50481506': __('identifierSchemeCodeUnspsc190501.50481506'),
	'identifierSchemeCodeUnspsc190501.50481507': __('identifierSchemeCodeUnspsc190501.50481507'),
	'identifierSchemeCodeUnspsc190501.50481508': __('identifierSchemeCodeUnspsc190501.50481508'),
	'identifierSchemeCodeUnspsc190501.50481509': __('identifierSchemeCodeUnspsc190501.50481509'),
	'identifierSchemeCodeUnspsc190501.50481510': __('identifierSchemeCodeUnspsc190501.50481510'),
	'identifierSchemeCodeUnspsc190501.50481511': __('identifierSchemeCodeUnspsc190501.50481511'),
	'identifierSchemeCodeUnspsc190501.50481512': __('identifierSchemeCodeUnspsc190501.50481512'),
	'identifierSchemeCodeUnspsc190501.50481513': __('identifierSchemeCodeUnspsc190501.50481513'),
	'identifierSchemeCodeUnspsc190501.50481600': __('identifierSchemeCodeUnspsc190501.50481600'),
	'identifierSchemeCodeUnspsc190501.50481601': __('identifierSchemeCodeUnspsc190501.50481601'),
	'identifierSchemeCodeUnspsc190501.50481602': __('identifierSchemeCodeUnspsc190501.50481602'),
	'identifierSchemeCodeUnspsc190501.50481700': __('identifierSchemeCodeUnspsc190501.50481700'),
	'identifierSchemeCodeUnspsc190501.50481701': __('identifierSchemeCodeUnspsc190501.50481701'),
	'identifierSchemeCodeUnspsc190501.50481702': __('identifierSchemeCodeUnspsc190501.50481702'),
	'identifierSchemeCodeUnspsc190501.50481703': __('identifierSchemeCodeUnspsc190501.50481703'),
	'identifierSchemeCodeUnspsc190501.50481704': __('identifierSchemeCodeUnspsc190501.50481704'),
	'identifierSchemeCodeUnspsc190501.50481705': __('identifierSchemeCodeUnspsc190501.50481705'),
	'identifierSchemeCodeUnspsc190501.50481800': __('identifierSchemeCodeUnspsc190501.50481800'),
	'identifierSchemeCodeUnspsc190501.50481801': __('identifierSchemeCodeUnspsc190501.50481801'),
	'identifierSchemeCodeUnspsc190501.50481802': __('identifierSchemeCodeUnspsc190501.50481802'),
	'identifierSchemeCodeUnspsc190501.50481803': __('identifierSchemeCodeUnspsc190501.50481803'),
	'identifierSchemeCodeUnspsc190501.50481804': __('identifierSchemeCodeUnspsc190501.50481804'),
	'identifierSchemeCodeUnspsc190501.50481805': __('identifierSchemeCodeUnspsc190501.50481805'),
	'identifierSchemeCodeUnspsc190501.50481806': __('identifierSchemeCodeUnspsc190501.50481806'),
	'identifierSchemeCodeUnspsc190501.50481807': __('identifierSchemeCodeUnspsc190501.50481807'),
	'identifierSchemeCodeUnspsc190501.50481808': __('identifierSchemeCodeUnspsc190501.50481808'),
	'identifierSchemeCodeUnspsc190501.50481809': __('identifierSchemeCodeUnspsc190501.50481809'),
	'identifierSchemeCodeUnspsc190501.50481810': __('identifierSchemeCodeUnspsc190501.50481810'),
	'identifierSchemeCodeUnspsc190501.50481811': __('identifierSchemeCodeUnspsc190501.50481811'),
	'identifierSchemeCodeUnspsc190501.50481812': __('identifierSchemeCodeUnspsc190501.50481812'),
	'identifierSchemeCodeUnspsc190501.50481813': __('identifierSchemeCodeUnspsc190501.50481813'),
	'identifierSchemeCodeUnspsc190501.50481814': __('identifierSchemeCodeUnspsc190501.50481814'),
	'identifierSchemeCodeUnspsc190501.50481815': __('identifierSchemeCodeUnspsc190501.50481815'),
	'identifierSchemeCodeUnspsc190501.50481816': __('identifierSchemeCodeUnspsc190501.50481816'),
	'identifierSchemeCodeUnspsc190501.50481817': __('identifierSchemeCodeUnspsc190501.50481817'),
	'identifierSchemeCodeUnspsc190501.50481818': __('identifierSchemeCodeUnspsc190501.50481818'),
	'identifierSchemeCodeUnspsc190501.50481819': __('identifierSchemeCodeUnspsc190501.50481819'),
	'identifierSchemeCodeUnspsc190501.50481820': __('identifierSchemeCodeUnspsc190501.50481820'),
	'identifierSchemeCodeUnspsc190501.50481821': __('identifierSchemeCodeUnspsc190501.50481821'),
	'identifierSchemeCodeUnspsc190501.50481822': __('identifierSchemeCodeUnspsc190501.50481822'),
	'identifierSchemeCodeUnspsc190501.50481823': __('identifierSchemeCodeUnspsc190501.50481823'),
	'identifierSchemeCodeUnspsc190501.50481824': __('identifierSchemeCodeUnspsc190501.50481824'),
	'identifierSchemeCodeUnspsc190501.50481825': __('identifierSchemeCodeUnspsc190501.50481825'),
	'identifierSchemeCodeUnspsc190501.50481826': __('identifierSchemeCodeUnspsc190501.50481826'),
	'identifierSchemeCodeUnspsc190501.50481827': __('identifierSchemeCodeUnspsc190501.50481827'),
	'identifierSchemeCodeUnspsc190501.50481828': __('identifierSchemeCodeUnspsc190501.50481828'),
	'identifierSchemeCodeUnspsc190501.50481829': __('identifierSchemeCodeUnspsc190501.50481829'),
	'identifierSchemeCodeUnspsc190501.50481830': __('identifierSchemeCodeUnspsc190501.50481830'),
	'identifierSchemeCodeUnspsc190501.50481831': __('identifierSchemeCodeUnspsc190501.50481831'),
	'identifierSchemeCodeUnspsc190501.50481832': __('identifierSchemeCodeUnspsc190501.50481832'),
	'identifierSchemeCodeUnspsc190501.50481833': __('identifierSchemeCodeUnspsc190501.50481833'),
	'identifierSchemeCodeUnspsc190501.50481834': __('identifierSchemeCodeUnspsc190501.50481834'),
	'identifierSchemeCodeUnspsc190501.50481835': __('identifierSchemeCodeUnspsc190501.50481835'),
	'identifierSchemeCodeUnspsc190501.50481836': __('identifierSchemeCodeUnspsc190501.50481836'),
	'identifierSchemeCodeUnspsc190501.50481837': __('identifierSchemeCodeUnspsc190501.50481837'),
	'identifierSchemeCodeUnspsc190501.50481838': __('identifierSchemeCodeUnspsc190501.50481838'),
	'identifierSchemeCodeUnspsc190501.50481839': __('identifierSchemeCodeUnspsc190501.50481839'),
	'identifierSchemeCodeUnspsc190501.50481840': __('identifierSchemeCodeUnspsc190501.50481840'),
	'identifierSchemeCodeUnspsc190501.50481841': __('identifierSchemeCodeUnspsc190501.50481841'),
	'identifierSchemeCodeUnspsc190501.50481842': __('identifierSchemeCodeUnspsc190501.50481842'),
	'identifierSchemeCodeUnspsc190501.50481843': __('identifierSchemeCodeUnspsc190501.50481843'),
	'identifierSchemeCodeUnspsc190501.50481844': __('identifierSchemeCodeUnspsc190501.50481844'),
	'identifierSchemeCodeUnspsc190501.50481845': __('identifierSchemeCodeUnspsc190501.50481845'),
	'identifierSchemeCodeUnspsc190501.50481846': __('identifierSchemeCodeUnspsc190501.50481846'),
	'identifierSchemeCodeUnspsc190501.50481847': __('identifierSchemeCodeUnspsc190501.50481847'),
	'identifierSchemeCodeUnspsc190501.50481848': __('identifierSchemeCodeUnspsc190501.50481848'),
	'identifierSchemeCodeUnspsc190501.50481849': __('identifierSchemeCodeUnspsc190501.50481849'),
	'identifierSchemeCodeUnspsc190501.50481850': __('identifierSchemeCodeUnspsc190501.50481850'),
	'identifierSchemeCodeUnspsc190501.50481851': __('identifierSchemeCodeUnspsc190501.50481851'),
	'identifierSchemeCodeUnspsc190501.50481852': __('identifierSchemeCodeUnspsc190501.50481852'),
	'identifierSchemeCodeUnspsc190501.50481853': __('identifierSchemeCodeUnspsc190501.50481853'),
	'identifierSchemeCodeUnspsc190501.50481854': __('identifierSchemeCodeUnspsc190501.50481854'),
	'identifierSchemeCodeUnspsc190501.50481855': __('identifierSchemeCodeUnspsc190501.50481855'),
	'identifierSchemeCodeUnspsc190501.50481856': __('identifierSchemeCodeUnspsc190501.50481856'),
	'identifierSchemeCodeUnspsc190501.50481857': __('identifierSchemeCodeUnspsc190501.50481857'),
	'identifierSchemeCodeUnspsc190501.50481858': __('identifierSchemeCodeUnspsc190501.50481858'),
	'identifierSchemeCodeUnspsc190501.50481859': __('identifierSchemeCodeUnspsc190501.50481859'),
	'identifierSchemeCodeUnspsc190501.50481860': __('identifierSchemeCodeUnspsc190501.50481860'),
	'identifierSchemeCodeUnspsc190501.50481861': __('identifierSchemeCodeUnspsc190501.50481861'),
	'identifierSchemeCodeUnspsc190501.50481862': __('identifierSchemeCodeUnspsc190501.50481862'),
	'identifierSchemeCodeUnspsc190501.50481863': __('identifierSchemeCodeUnspsc190501.50481863'),
	'identifierSchemeCodeUnspsc190501.50481864': __('identifierSchemeCodeUnspsc190501.50481864'),
	'identifierSchemeCodeUnspsc190501.50481865': __('identifierSchemeCodeUnspsc190501.50481865'),
	'identifierSchemeCodeUnspsc190501.50481866': __('identifierSchemeCodeUnspsc190501.50481866'),
	'identifierSchemeCodeUnspsc190501.50481867': __('identifierSchemeCodeUnspsc190501.50481867'),
	'identifierSchemeCodeUnspsc190501.50481868': __('identifierSchemeCodeUnspsc190501.50481868'),
	'identifierSchemeCodeUnspsc190501.50481869': __('identifierSchemeCodeUnspsc190501.50481869'),
	'identifierSchemeCodeUnspsc190501.50481870': __('identifierSchemeCodeUnspsc190501.50481870'),
	'identifierSchemeCodeUnspsc190501.50481871': __('identifierSchemeCodeUnspsc190501.50481871'),
	'identifierSchemeCodeUnspsc190501.50481872': __('identifierSchemeCodeUnspsc190501.50481872'),
	'identifierSchemeCodeUnspsc190501.50481873': __('identifierSchemeCodeUnspsc190501.50481873'),
	'identifierSchemeCodeUnspsc190501.50481874': __('identifierSchemeCodeUnspsc190501.50481874'),
	'identifierSchemeCodeUnspsc190501.50481875': __('identifierSchemeCodeUnspsc190501.50481875'),
	'identifierSchemeCodeUnspsc190501.50481876': __('identifierSchemeCodeUnspsc190501.50481876'),
	'identifierSchemeCodeUnspsc190501.50481877': __('identifierSchemeCodeUnspsc190501.50481877'),
	'identifierSchemeCodeUnspsc190501.50481878': __('identifierSchemeCodeUnspsc190501.50481878'),
	'identifierSchemeCodeUnspsc190501.50481879': __('identifierSchemeCodeUnspsc190501.50481879'),
	'identifierSchemeCodeUnspsc190501.50481880': __('identifierSchemeCodeUnspsc190501.50481880'),
	'identifierSchemeCodeUnspsc190501.50481881': __('identifierSchemeCodeUnspsc190501.50481881'),
	'identifierSchemeCodeUnspsc190501.50481882': __('identifierSchemeCodeUnspsc190501.50481882'),
	'identifierSchemeCodeUnspsc190501.50481900': __('identifierSchemeCodeUnspsc190501.50481900'),
	'identifierSchemeCodeUnspsc190501.50481901': __('identifierSchemeCodeUnspsc190501.50481901'),
	'identifierSchemeCodeUnspsc190501.50481902': __('identifierSchemeCodeUnspsc190501.50481902'),
	'identifierSchemeCodeUnspsc190501.50481903': __('identifierSchemeCodeUnspsc190501.50481903'),
	'identifierSchemeCodeUnspsc190501.50481904': __('identifierSchemeCodeUnspsc190501.50481904'),
	'identifierSchemeCodeUnspsc190501.50481905': __('identifierSchemeCodeUnspsc190501.50481905'),
	'identifierSchemeCodeUnspsc190501.50481906': __('identifierSchemeCodeUnspsc190501.50481906'),
	'identifierSchemeCodeUnspsc190501.50481907': __('identifierSchemeCodeUnspsc190501.50481907'),
	'identifierSchemeCodeUnspsc190501.50481908': __('identifierSchemeCodeUnspsc190501.50481908'),
	'identifierSchemeCodeUnspsc190501.50481909': __('identifierSchemeCodeUnspsc190501.50481909'),
	'identifierSchemeCodeUnspsc190501.50481910': __('identifierSchemeCodeUnspsc190501.50481910'),
	'identifierSchemeCodeUnspsc190501.50481911': __('identifierSchemeCodeUnspsc190501.50481911'),
	'identifierSchemeCodeUnspsc190501.50481912': __('identifierSchemeCodeUnspsc190501.50481912'),
	'identifierSchemeCodeUnspsc190501.50481913': __('identifierSchemeCodeUnspsc190501.50481913'),
	'identifierSchemeCodeUnspsc190501.50481914': __('identifierSchemeCodeUnspsc190501.50481914'),
	'identifierSchemeCodeUnspsc190501.50481915': __('identifierSchemeCodeUnspsc190501.50481915'),
	'identifierSchemeCodeUnspsc190501.50481916': __('identifierSchemeCodeUnspsc190501.50481916'),
	'identifierSchemeCodeUnspsc190501.50481917': __('identifierSchemeCodeUnspsc190501.50481917'),
	'identifierSchemeCodeUnspsc190501.50481918': __('identifierSchemeCodeUnspsc190501.50481918'),
	'identifierSchemeCodeUnspsc190501.50481919': __('identifierSchemeCodeUnspsc190501.50481919'),
	'identifierSchemeCodeUnspsc190501.50481920': __('identifierSchemeCodeUnspsc190501.50481920'),
	'identifierSchemeCodeUnspsc190501.50481921': __('identifierSchemeCodeUnspsc190501.50481921'),
	'identifierSchemeCodeUnspsc190501.50481922': __('identifierSchemeCodeUnspsc190501.50481922'),
	'identifierSchemeCodeUnspsc190501.50481923': __('identifierSchemeCodeUnspsc190501.50481923'),
	'identifierSchemeCodeUnspsc190501.50481924': __('identifierSchemeCodeUnspsc190501.50481924'),
	'identifierSchemeCodeUnspsc190501.50481925': __('identifierSchemeCodeUnspsc190501.50481925'),
	'identifierSchemeCodeUnspsc190501.50481926': __('identifierSchemeCodeUnspsc190501.50481926'),
	'identifierSchemeCodeUnspsc190501.50481927': __('identifierSchemeCodeUnspsc190501.50481927'),
	'identifierSchemeCodeUnspsc190501.50481928': __('identifierSchemeCodeUnspsc190501.50481928'),
	'identifierSchemeCodeUnspsc190501.50481929': __('identifierSchemeCodeUnspsc190501.50481929'),
	'identifierSchemeCodeUnspsc190501.50481930': __('identifierSchemeCodeUnspsc190501.50481930'),
	'identifierSchemeCodeUnspsc190501.50481931': __('identifierSchemeCodeUnspsc190501.50481931'),
	'identifierSchemeCodeUnspsc190501.50481932': __('identifierSchemeCodeUnspsc190501.50481932'),
	'identifierSchemeCodeUnspsc190501.50481933': __('identifierSchemeCodeUnspsc190501.50481933'),
	'identifierSchemeCodeUnspsc190501.50481934': __('identifierSchemeCodeUnspsc190501.50481934'),
	'identifierSchemeCodeUnspsc190501.50481935': __('identifierSchemeCodeUnspsc190501.50481935'),
	'identifierSchemeCodeUnspsc190501.50481936': __('identifierSchemeCodeUnspsc190501.50481936'),
	'identifierSchemeCodeUnspsc190501.50481937': __('identifierSchemeCodeUnspsc190501.50481937'),
	'identifierSchemeCodeUnspsc190501.50481938': __('identifierSchemeCodeUnspsc190501.50481938'),
	'identifierSchemeCodeUnspsc190501.50481939': __('identifierSchemeCodeUnspsc190501.50481939'),
	'identifierSchemeCodeUnspsc190501.50481940': __('identifierSchemeCodeUnspsc190501.50481940'),
	'identifierSchemeCodeUnspsc190501.50481941': __('identifierSchemeCodeUnspsc190501.50481941'),
	'identifierSchemeCodeUnspsc190501.50481942': __('identifierSchemeCodeUnspsc190501.50481942'),
	'identifierSchemeCodeUnspsc190501.50481943': __('identifierSchemeCodeUnspsc190501.50481943'),
	'identifierSchemeCodeUnspsc190501.50481944': __('identifierSchemeCodeUnspsc190501.50481944'),
	'identifierSchemeCodeUnspsc190501.50481945': __('identifierSchemeCodeUnspsc190501.50481945'),
	'identifierSchemeCodeUnspsc190501.50481946': __('identifierSchemeCodeUnspsc190501.50481946'),
	'identifierSchemeCodeUnspsc190501.50481947': __('identifierSchemeCodeUnspsc190501.50481947'),
	'identifierSchemeCodeUnspsc190501.50481948': __('identifierSchemeCodeUnspsc190501.50481948'),
	'identifierSchemeCodeUnspsc190501.50481949': __('identifierSchemeCodeUnspsc190501.50481949'),
	'identifierSchemeCodeUnspsc190501.50481950': __('identifierSchemeCodeUnspsc190501.50481950'),
	'identifierSchemeCodeUnspsc190501.50481951': __('identifierSchemeCodeUnspsc190501.50481951'),
	'identifierSchemeCodeUnspsc190501.50481952': __('identifierSchemeCodeUnspsc190501.50481952'),
	'identifierSchemeCodeUnspsc190501.50481953': __('identifierSchemeCodeUnspsc190501.50481953'),
	'identifierSchemeCodeUnspsc190501.50482000': __('identifierSchemeCodeUnspsc190501.50482000'),
	'identifierSchemeCodeUnspsc190501.50482001': __('identifierSchemeCodeUnspsc190501.50482001'),
	'identifierSchemeCodeUnspsc190501.50482002': __('identifierSchemeCodeUnspsc190501.50482002'),
	'identifierSchemeCodeUnspsc190501.50482003': __('identifierSchemeCodeUnspsc190501.50482003'),
	'identifierSchemeCodeUnspsc190501.50482004': __('identifierSchemeCodeUnspsc190501.50482004'),
	'identifierSchemeCodeUnspsc190501.50482005': __('identifierSchemeCodeUnspsc190501.50482005'),
	'identifierSchemeCodeUnspsc190501.50482006': __('identifierSchemeCodeUnspsc190501.50482006'),
	'identifierSchemeCodeUnspsc190501.50482007': __('identifierSchemeCodeUnspsc190501.50482007'),
	'identifierSchemeCodeUnspsc190501.50482008': __('identifierSchemeCodeUnspsc190501.50482008'),
	'identifierSchemeCodeUnspsc190501.50482009': __('identifierSchemeCodeUnspsc190501.50482009'),
	'identifierSchemeCodeUnspsc190501.50482010': __('identifierSchemeCodeUnspsc190501.50482010'),
	'identifierSchemeCodeUnspsc190501.50482011': __('identifierSchemeCodeUnspsc190501.50482011'),
	'identifierSchemeCodeUnspsc190501.50482012': __('identifierSchemeCodeUnspsc190501.50482012'),
	'identifierSchemeCodeUnspsc190501.50482013': __('identifierSchemeCodeUnspsc190501.50482013'),
	'identifierSchemeCodeUnspsc190501.50482014': __('identifierSchemeCodeUnspsc190501.50482014'),
	'identifierSchemeCodeUnspsc190501.50482015': __('identifierSchemeCodeUnspsc190501.50482015'),
	'identifierSchemeCodeUnspsc190501.50482016': __('identifierSchemeCodeUnspsc190501.50482016'),
	'identifierSchemeCodeUnspsc190501.50482017': __('identifierSchemeCodeUnspsc190501.50482017'),
	'identifierSchemeCodeUnspsc190501.50482018': __('identifierSchemeCodeUnspsc190501.50482018'),
	'identifierSchemeCodeUnspsc190501.50482019': __('identifierSchemeCodeUnspsc190501.50482019'),
	'identifierSchemeCodeUnspsc190501.50482020': __('identifierSchemeCodeUnspsc190501.50482020'),
	'identifierSchemeCodeUnspsc190501.50482021': __('identifierSchemeCodeUnspsc190501.50482021'),
	'identifierSchemeCodeUnspsc190501.50482022': __('identifierSchemeCodeUnspsc190501.50482022'),
	'identifierSchemeCodeUnspsc190501.50482100': __('identifierSchemeCodeUnspsc190501.50482100'),
	'identifierSchemeCodeUnspsc190501.50482101': __('identifierSchemeCodeUnspsc190501.50482101'),
	'identifierSchemeCodeUnspsc190501.50482102': __('identifierSchemeCodeUnspsc190501.50482102'),
	'identifierSchemeCodeUnspsc190501.50482103': __('identifierSchemeCodeUnspsc190501.50482103'),
	'identifierSchemeCodeUnspsc190501.50482200': __('identifierSchemeCodeUnspsc190501.50482200'),
	'identifierSchemeCodeUnspsc190501.50482201': __('identifierSchemeCodeUnspsc190501.50482201'),
	'identifierSchemeCodeUnspsc190501.50482202': __('identifierSchemeCodeUnspsc190501.50482202'),
	'identifierSchemeCodeUnspsc190501.50482203': __('identifierSchemeCodeUnspsc190501.50482203'),
	'identifierSchemeCodeUnspsc190501.50482204': __('identifierSchemeCodeUnspsc190501.50482204'),
	'identifierSchemeCodeUnspsc190501.50482205': __('identifierSchemeCodeUnspsc190501.50482205'),
	'identifierSchemeCodeUnspsc190501.50482300': __('identifierSchemeCodeUnspsc190501.50482300'),
	'identifierSchemeCodeUnspsc190501.50482301': __('identifierSchemeCodeUnspsc190501.50482301'),
	'identifierSchemeCodeUnspsc190501.50482302': __('identifierSchemeCodeUnspsc190501.50482302'),
	'identifierSchemeCodeUnspsc190501.50482303': __('identifierSchemeCodeUnspsc190501.50482303'),
	'identifierSchemeCodeUnspsc190501.50482304': __('identifierSchemeCodeUnspsc190501.50482304'),
	'identifierSchemeCodeUnspsc190501.50482305': __('identifierSchemeCodeUnspsc190501.50482305'),
	'identifierSchemeCodeUnspsc190501.50482306': __('identifierSchemeCodeUnspsc190501.50482306'),
	'identifierSchemeCodeUnspsc190501.50482307': __('identifierSchemeCodeUnspsc190501.50482307'),
	'identifierSchemeCodeUnspsc190501.50482308': __('identifierSchemeCodeUnspsc190501.50482308'),
	'identifierSchemeCodeUnspsc190501.50482309': __('identifierSchemeCodeUnspsc190501.50482309'),
	'identifierSchemeCodeUnspsc190501.50482310': __('identifierSchemeCodeUnspsc190501.50482310'),
	'identifierSchemeCodeUnspsc190501.50482311': __('identifierSchemeCodeUnspsc190501.50482311'),
	'identifierSchemeCodeUnspsc190501.50482400': __('identifierSchemeCodeUnspsc190501.50482400'),
	'identifierSchemeCodeUnspsc190501.50482401': __('identifierSchemeCodeUnspsc190501.50482401'),
	'identifierSchemeCodeUnspsc190501.50482402': __('identifierSchemeCodeUnspsc190501.50482402'),
	'identifierSchemeCodeUnspsc190501.50482403': __('identifierSchemeCodeUnspsc190501.50482403'),
	'identifierSchemeCodeUnspsc190501.50482404': __('identifierSchemeCodeUnspsc190501.50482404'),
	'identifierSchemeCodeUnspsc190501.50482405': __('identifierSchemeCodeUnspsc190501.50482405'),
	'identifierSchemeCodeUnspsc190501.50482500': __('identifierSchemeCodeUnspsc190501.50482500'),
	'identifierSchemeCodeUnspsc190501.50482501': __('identifierSchemeCodeUnspsc190501.50482501'),
	'identifierSchemeCodeUnspsc190501.50482502': __('identifierSchemeCodeUnspsc190501.50482502'),
	'identifierSchemeCodeUnspsc190501.50482600': __('identifierSchemeCodeUnspsc190501.50482600'),
	'identifierSchemeCodeUnspsc190501.50482601': __('identifierSchemeCodeUnspsc190501.50482601'),
	'identifierSchemeCodeUnspsc190501.50482602': __('identifierSchemeCodeUnspsc190501.50482602'),
	'identifierSchemeCodeUnspsc190501.50482603': __('identifierSchemeCodeUnspsc190501.50482603'),
	'identifierSchemeCodeUnspsc190501.50482604': __('identifierSchemeCodeUnspsc190501.50482604'),
	'identifierSchemeCodeUnspsc190501.50482605': __('identifierSchemeCodeUnspsc190501.50482605'),
	'identifierSchemeCodeUnspsc190501.50482606': __('identifierSchemeCodeUnspsc190501.50482606'),
	'identifierSchemeCodeUnspsc190501.50482607': __('identifierSchemeCodeUnspsc190501.50482607'),
	'identifierSchemeCodeUnspsc190501.50482700': __('identifierSchemeCodeUnspsc190501.50482700'),
	'identifierSchemeCodeUnspsc190501.50482701': __('identifierSchemeCodeUnspsc190501.50482701'),
	'identifierSchemeCodeUnspsc190501.50482702': __('identifierSchemeCodeUnspsc190501.50482702'),
	'identifierSchemeCodeUnspsc190501.50482703': __('identifierSchemeCodeUnspsc190501.50482703'),
	'identifierSchemeCodeUnspsc190501.50482704': __('identifierSchemeCodeUnspsc190501.50482704'),
	'identifierSchemeCodeUnspsc190501.50482705': __('identifierSchemeCodeUnspsc190501.50482705'),
	'identifierSchemeCodeUnspsc190501.50482706': __('identifierSchemeCodeUnspsc190501.50482706'),
	'identifierSchemeCodeUnspsc190501.50482707': __('identifierSchemeCodeUnspsc190501.50482707'),
	'identifierSchemeCodeUnspsc190501.50482708': __('identifierSchemeCodeUnspsc190501.50482708'),
	'identifierSchemeCodeUnspsc190501.50482709': __('identifierSchemeCodeUnspsc190501.50482709'),
	'identifierSchemeCodeUnspsc190501.50482710': __('identifierSchemeCodeUnspsc190501.50482710'),
	'identifierSchemeCodeUnspsc190501.50482711': __('identifierSchemeCodeUnspsc190501.50482711'),
	'identifierSchemeCodeUnspsc190501.50482712': __('identifierSchemeCodeUnspsc190501.50482712'),
	'identifierSchemeCodeUnspsc190501.50482800': __('identifierSchemeCodeUnspsc190501.50482800'),
	'identifierSchemeCodeUnspsc190501.50482801': __('identifierSchemeCodeUnspsc190501.50482801'),
	'identifierSchemeCodeUnspsc190501.50482802': __('identifierSchemeCodeUnspsc190501.50482802'),
	'identifierSchemeCodeUnspsc190501.50482803': __('identifierSchemeCodeUnspsc190501.50482803'),
	'identifierSchemeCodeUnspsc190501.50482804': __('identifierSchemeCodeUnspsc190501.50482804'),
	'identifierSchemeCodeUnspsc190501.50482805': __('identifierSchemeCodeUnspsc190501.50482805'),
	'identifierSchemeCodeUnspsc190501.50482806': __('identifierSchemeCodeUnspsc190501.50482806'),
	'identifierSchemeCodeUnspsc190501.50482807': __('identifierSchemeCodeUnspsc190501.50482807'),
	'identifierSchemeCodeUnspsc190501.50482808': __('identifierSchemeCodeUnspsc190501.50482808'),
	'identifierSchemeCodeUnspsc190501.50482809': __('identifierSchemeCodeUnspsc190501.50482809'),
	'identifierSchemeCodeUnspsc190501.50482810': __('identifierSchemeCodeUnspsc190501.50482810'),
	'identifierSchemeCodeUnspsc190501.50482811': __('identifierSchemeCodeUnspsc190501.50482811'),
	'identifierSchemeCodeUnspsc190501.50482812': __('identifierSchemeCodeUnspsc190501.50482812'),
	'identifierSchemeCodeUnspsc190501.50482813': __('identifierSchemeCodeUnspsc190501.50482813'),
	'identifierSchemeCodeUnspsc190501.50482814': __('identifierSchemeCodeUnspsc190501.50482814'),
	'identifierSchemeCodeUnspsc190501.50482815': __('identifierSchemeCodeUnspsc190501.50482815'),
	'identifierSchemeCodeUnspsc190501.50482900': __('identifierSchemeCodeUnspsc190501.50482900'),
	'identifierSchemeCodeUnspsc190501.50482901': __('identifierSchemeCodeUnspsc190501.50482901'),
	'identifierSchemeCodeUnspsc190501.50482902': __('identifierSchemeCodeUnspsc190501.50482902'),
	'identifierSchemeCodeUnspsc190501.50482903': __('identifierSchemeCodeUnspsc190501.50482903'),
	'identifierSchemeCodeUnspsc190501.50482904': __('identifierSchemeCodeUnspsc190501.50482904'),
	'identifierSchemeCodeUnspsc190501.50482905': __('identifierSchemeCodeUnspsc190501.50482905'),
	'identifierSchemeCodeUnspsc190501.50482906': __('identifierSchemeCodeUnspsc190501.50482906'),
	'identifierSchemeCodeUnspsc190501.50482907': __('identifierSchemeCodeUnspsc190501.50482907'),
	'identifierSchemeCodeUnspsc190501.50482908': __('identifierSchemeCodeUnspsc190501.50482908'),
	'identifierSchemeCodeUnspsc190501.50483000': __('identifierSchemeCodeUnspsc190501.50483000'),
	'identifierSchemeCodeUnspsc190501.50483001': __('identifierSchemeCodeUnspsc190501.50483001'),
	'identifierSchemeCodeUnspsc190501.50483002': __('identifierSchemeCodeUnspsc190501.50483002'),
	'identifierSchemeCodeUnspsc190501.50483003': __('identifierSchemeCodeUnspsc190501.50483003'),
	'identifierSchemeCodeUnspsc190501.50483004': __('identifierSchemeCodeUnspsc190501.50483004'),
	'identifierSchemeCodeUnspsc190501.50483005': __('identifierSchemeCodeUnspsc190501.50483005'),
	'identifierSchemeCodeUnspsc190501.50483006': __('identifierSchemeCodeUnspsc190501.50483006'),
	'identifierSchemeCodeUnspsc190501.50483007': __('identifierSchemeCodeUnspsc190501.50483007'),
	'identifierSchemeCodeUnspsc190501.50483008': __('identifierSchemeCodeUnspsc190501.50483008'),
	'identifierSchemeCodeUnspsc190501.50483009': __('identifierSchemeCodeUnspsc190501.50483009'),
	'identifierSchemeCodeUnspsc190501.50483010': __('identifierSchemeCodeUnspsc190501.50483010'),
	'identifierSchemeCodeUnspsc190501.50483011': __('identifierSchemeCodeUnspsc190501.50483011'),
	'identifierSchemeCodeUnspsc190501.50483100': __('identifierSchemeCodeUnspsc190501.50483100'),
	'identifierSchemeCodeUnspsc190501.50483101': __('identifierSchemeCodeUnspsc190501.50483101'),
	'identifierSchemeCodeUnspsc190501.50483102': __('identifierSchemeCodeUnspsc190501.50483102'),
	'identifierSchemeCodeUnspsc190501.50483103': __('identifierSchemeCodeUnspsc190501.50483103'),
	'identifierSchemeCodeUnspsc190501.50483104': __('identifierSchemeCodeUnspsc190501.50483104'),
	'identifierSchemeCodeUnspsc190501.50483105': __('identifierSchemeCodeUnspsc190501.50483105'),
	'identifierSchemeCodeUnspsc190501.50483106': __('identifierSchemeCodeUnspsc190501.50483106'),
	'identifierSchemeCodeUnspsc190501.50483107': __('identifierSchemeCodeUnspsc190501.50483107'),
	'identifierSchemeCodeUnspsc190501.50483108': __('identifierSchemeCodeUnspsc190501.50483108'),
	'identifierSchemeCodeUnspsc190501.50483109': __('identifierSchemeCodeUnspsc190501.50483109'),
	'identifierSchemeCodeUnspsc190501.50483110': __('identifierSchemeCodeUnspsc190501.50483110'),
	'identifierSchemeCodeUnspsc190501.50483111': __('identifierSchemeCodeUnspsc190501.50483111'),
	'identifierSchemeCodeUnspsc190501.50483112': __('identifierSchemeCodeUnspsc190501.50483112'),
	'identifierSchemeCodeUnspsc190501.50483113': __('identifierSchemeCodeUnspsc190501.50483113'),
	'identifierSchemeCodeUnspsc190501.50483114': __('identifierSchemeCodeUnspsc190501.50483114'),
	'identifierSchemeCodeUnspsc190501.50483115': __('identifierSchemeCodeUnspsc190501.50483115'),
	'identifierSchemeCodeUnspsc190501.50483116': __('identifierSchemeCodeUnspsc190501.50483116'),
	'identifierSchemeCodeUnspsc190501.50483200': __('identifierSchemeCodeUnspsc190501.50483200'),
	'identifierSchemeCodeUnspsc190501.50483201': __('identifierSchemeCodeUnspsc190501.50483201'),
	'identifierSchemeCodeUnspsc190501.50483202': __('identifierSchemeCodeUnspsc190501.50483202'),
	'identifierSchemeCodeUnspsc190501.50483203': __('identifierSchemeCodeUnspsc190501.50483203'),
	'identifierSchemeCodeUnspsc190501.50483204': __('identifierSchemeCodeUnspsc190501.50483204'),
	'identifierSchemeCodeUnspsc190501.50483205': __('identifierSchemeCodeUnspsc190501.50483205'),
	'identifierSchemeCodeUnspsc190501.50483206': __('identifierSchemeCodeUnspsc190501.50483206'),
	'identifierSchemeCodeUnspsc190501.50483207': __('identifierSchemeCodeUnspsc190501.50483207'),
	'identifierSchemeCodeUnspsc190501.50483208': __('identifierSchemeCodeUnspsc190501.50483208'),
	'identifierSchemeCodeUnspsc190501.50483209': __('identifierSchemeCodeUnspsc190501.50483209'),
	'identifierSchemeCodeUnspsc190501.50483210': __('identifierSchemeCodeUnspsc190501.50483210'),
	'identifierSchemeCodeUnspsc190501.50483211': __('identifierSchemeCodeUnspsc190501.50483211'),
	'identifierSchemeCodeUnspsc190501.50483212': __('identifierSchemeCodeUnspsc190501.50483212'),
	'identifierSchemeCodeUnspsc190501.50483213': __('identifierSchemeCodeUnspsc190501.50483213'),
	'identifierSchemeCodeUnspsc190501.50483214': __('identifierSchemeCodeUnspsc190501.50483214'),
	'identifierSchemeCodeUnspsc190501.50483215': __('identifierSchemeCodeUnspsc190501.50483215'),
	'identifierSchemeCodeUnspsc190501.50483216': __('identifierSchemeCodeUnspsc190501.50483216'),
	'identifierSchemeCodeUnspsc190501.50483217': __('identifierSchemeCodeUnspsc190501.50483217'),
	'identifierSchemeCodeUnspsc190501.50483218': __('identifierSchemeCodeUnspsc190501.50483218'),
	'identifierSchemeCodeUnspsc190501.50483219': __('identifierSchemeCodeUnspsc190501.50483219'),
	'identifierSchemeCodeUnspsc190501.50483220': __('identifierSchemeCodeUnspsc190501.50483220'),
	'identifierSchemeCodeUnspsc190501.50483221': __('identifierSchemeCodeUnspsc190501.50483221'),
	'identifierSchemeCodeUnspsc190501.50483222': __('identifierSchemeCodeUnspsc190501.50483222'),
	'identifierSchemeCodeUnspsc190501.50483223': __('identifierSchemeCodeUnspsc190501.50483223'),
	'identifierSchemeCodeUnspsc190501.50483224': __('identifierSchemeCodeUnspsc190501.50483224'),
	'identifierSchemeCodeUnspsc190501.50483225': __('identifierSchemeCodeUnspsc190501.50483225'),
	'identifierSchemeCodeUnspsc190501.50483226': __('identifierSchemeCodeUnspsc190501.50483226'),
	'identifierSchemeCodeUnspsc190501.50483227': __('identifierSchemeCodeUnspsc190501.50483227'),
	'identifierSchemeCodeUnspsc190501.50483228': __('identifierSchemeCodeUnspsc190501.50483228'),
	'identifierSchemeCodeUnspsc190501.50483229': __('identifierSchemeCodeUnspsc190501.50483229'),
	'identifierSchemeCodeUnspsc190501.50483230': __('identifierSchemeCodeUnspsc190501.50483230'),
	'identifierSchemeCodeUnspsc190501.50483231': __('identifierSchemeCodeUnspsc190501.50483231'),
	'identifierSchemeCodeUnspsc190501.50483232': __('identifierSchemeCodeUnspsc190501.50483232'),
	'identifierSchemeCodeUnspsc190501.50483233': __('identifierSchemeCodeUnspsc190501.50483233'),
	'identifierSchemeCodeUnspsc190501.50483234': __('identifierSchemeCodeUnspsc190501.50483234'),
	'identifierSchemeCodeUnspsc190501.50483235': __('identifierSchemeCodeUnspsc190501.50483235'),
	'identifierSchemeCodeUnspsc190501.50483236': __('identifierSchemeCodeUnspsc190501.50483236'),
	'identifierSchemeCodeUnspsc190501.50483237': __('identifierSchemeCodeUnspsc190501.50483237'),
	'identifierSchemeCodeUnspsc190501.50483238': __('identifierSchemeCodeUnspsc190501.50483238'),
	'identifierSchemeCodeUnspsc190501.50483239': __('identifierSchemeCodeUnspsc190501.50483239'),
	'identifierSchemeCodeUnspsc190501.50483240': __('identifierSchemeCodeUnspsc190501.50483240'),
	'identifierSchemeCodeUnspsc190501.50483241': __('identifierSchemeCodeUnspsc190501.50483241'),
	'identifierSchemeCodeUnspsc190501.50483242': __('identifierSchemeCodeUnspsc190501.50483242'),
	'identifierSchemeCodeUnspsc190501.50483243': __('identifierSchemeCodeUnspsc190501.50483243'),
	'identifierSchemeCodeUnspsc190501.50483244': __('identifierSchemeCodeUnspsc190501.50483244'),
	'identifierSchemeCodeUnspsc190501.50483245': __('identifierSchemeCodeUnspsc190501.50483245'),
	'identifierSchemeCodeUnspsc190501.50483246': __('identifierSchemeCodeUnspsc190501.50483246'),
	'identifierSchemeCodeUnspsc190501.50483247': __('identifierSchemeCodeUnspsc190501.50483247'),
	'identifierSchemeCodeUnspsc190501.50483248': __('identifierSchemeCodeUnspsc190501.50483248'),
	'identifierSchemeCodeUnspsc190501.50483249': __('identifierSchemeCodeUnspsc190501.50483249'),
	'identifierSchemeCodeUnspsc190501.50483250': __('identifierSchemeCodeUnspsc190501.50483250'),
	'identifierSchemeCodeUnspsc190501.50483251': __('identifierSchemeCodeUnspsc190501.50483251'),
	'identifierSchemeCodeUnspsc190501.50483252': __('identifierSchemeCodeUnspsc190501.50483252'),
	'identifierSchemeCodeUnspsc190501.50483253': __('identifierSchemeCodeUnspsc190501.50483253'),
	'identifierSchemeCodeUnspsc190501.50483254': __('identifierSchemeCodeUnspsc190501.50483254'),
	'identifierSchemeCodeUnspsc190501.50483255': __('identifierSchemeCodeUnspsc190501.50483255'),
	'identifierSchemeCodeUnspsc190501.50483256': __('identifierSchemeCodeUnspsc190501.50483256'),
	'identifierSchemeCodeUnspsc190501.50483257': __('identifierSchemeCodeUnspsc190501.50483257'),
	'identifierSchemeCodeUnspsc190501.50483258': __('identifierSchemeCodeUnspsc190501.50483258'),
	'identifierSchemeCodeUnspsc190501.50483259': __('identifierSchemeCodeUnspsc190501.50483259'),
	'identifierSchemeCodeUnspsc190501.50483260': __('identifierSchemeCodeUnspsc190501.50483260'),
	'identifierSchemeCodeUnspsc190501.50483261': __('identifierSchemeCodeUnspsc190501.50483261'),
	'identifierSchemeCodeUnspsc190501.50483262': __('identifierSchemeCodeUnspsc190501.50483262'),
	'identifierSchemeCodeUnspsc190501.50483263': __('identifierSchemeCodeUnspsc190501.50483263'),
	'identifierSchemeCodeUnspsc190501.50483264': __('identifierSchemeCodeUnspsc190501.50483264'),
	'identifierSchemeCodeUnspsc190501.50483265': __('identifierSchemeCodeUnspsc190501.50483265'),
	'identifierSchemeCodeUnspsc190501.50483266': __('identifierSchemeCodeUnspsc190501.50483266'),
	'identifierSchemeCodeUnspsc190501.50483267': __('identifierSchemeCodeUnspsc190501.50483267'),
	'identifierSchemeCodeUnspsc190501.50483268': __('identifierSchemeCodeUnspsc190501.50483268'),
	'identifierSchemeCodeUnspsc190501.50483269': __('identifierSchemeCodeUnspsc190501.50483269'),
	'identifierSchemeCodeUnspsc190501.50483300': __('identifierSchemeCodeUnspsc190501.50483300'),
	'identifierSchemeCodeUnspsc190501.50483301': __('identifierSchemeCodeUnspsc190501.50483301'),
	'identifierSchemeCodeUnspsc190501.50483302': __('identifierSchemeCodeUnspsc190501.50483302'),
	'identifierSchemeCodeUnspsc190501.50483303': __('identifierSchemeCodeUnspsc190501.50483303'),
	'identifierSchemeCodeUnspsc190501.50483304': __('identifierSchemeCodeUnspsc190501.50483304'),
	'identifierSchemeCodeUnspsc190501.50483400': __('identifierSchemeCodeUnspsc190501.50483400'),
	'identifierSchemeCodeUnspsc190501.50483401': __('identifierSchemeCodeUnspsc190501.50483401'),
	'identifierSchemeCodeUnspsc190501.50483402': __('identifierSchemeCodeUnspsc190501.50483402'),
	'identifierSchemeCodeUnspsc190501.50483403': __('identifierSchemeCodeUnspsc190501.50483403'),
	'identifierSchemeCodeUnspsc190501.50483404': __('identifierSchemeCodeUnspsc190501.50483404'),
	'identifierSchemeCodeUnspsc190501.50483405': __('identifierSchemeCodeUnspsc190501.50483405'),
	'identifierSchemeCodeUnspsc190501.50483406': __('identifierSchemeCodeUnspsc190501.50483406'),
	'identifierSchemeCodeUnspsc190501.50483407': __('identifierSchemeCodeUnspsc190501.50483407'),
	'identifierSchemeCodeUnspsc190501.50483408': __('identifierSchemeCodeUnspsc190501.50483408'),
	'identifierSchemeCodeUnspsc190501.50483409': __('identifierSchemeCodeUnspsc190501.50483409'),
	'identifierSchemeCodeUnspsc190501.50483410': __('identifierSchemeCodeUnspsc190501.50483410'),
	'identifierSchemeCodeUnspsc190501.50483411': __('identifierSchemeCodeUnspsc190501.50483411'),
	'identifierSchemeCodeUnspsc190501.50483412': __('identifierSchemeCodeUnspsc190501.50483412'),
	'identifierSchemeCodeUnspsc190501.50483413': __('identifierSchemeCodeUnspsc190501.50483413'),
	'identifierSchemeCodeUnspsc190501.50483414': __('identifierSchemeCodeUnspsc190501.50483414'),
	'identifierSchemeCodeUnspsc190501.50483415': __('identifierSchemeCodeUnspsc190501.50483415'),
	'identifierSchemeCodeUnspsc190501.50483416': __('identifierSchemeCodeUnspsc190501.50483416'),
	'identifierSchemeCodeUnspsc190501.50483417': __('identifierSchemeCodeUnspsc190501.50483417'),
	'identifierSchemeCodeUnspsc190501.50483418': __('identifierSchemeCodeUnspsc190501.50483418'),
	'identifierSchemeCodeUnspsc190501.50483419': __('identifierSchemeCodeUnspsc190501.50483419'),
	'identifierSchemeCodeUnspsc190501.50483420': __('identifierSchemeCodeUnspsc190501.50483420'),
	'identifierSchemeCodeUnspsc190501.50483421': __('identifierSchemeCodeUnspsc190501.50483421'),
	'identifierSchemeCodeUnspsc190501.50483422': __('identifierSchemeCodeUnspsc190501.50483422'),
	'identifierSchemeCodeUnspsc190501.50483423': __('identifierSchemeCodeUnspsc190501.50483423'),
	'identifierSchemeCodeUnspsc190501.50483500': __('identifierSchemeCodeUnspsc190501.50483500'),
	'identifierSchemeCodeUnspsc190501.50483501': __('identifierSchemeCodeUnspsc190501.50483501'),
	'identifierSchemeCodeUnspsc190501.50483502': __('identifierSchemeCodeUnspsc190501.50483502'),
	'identifierSchemeCodeUnspsc190501.50483503': __('identifierSchemeCodeUnspsc190501.50483503'),
	'identifierSchemeCodeUnspsc190501.50483504': __('identifierSchemeCodeUnspsc190501.50483504'),
	'identifierSchemeCodeUnspsc190501.50483505': __('identifierSchemeCodeUnspsc190501.50483505'),
	'identifierSchemeCodeUnspsc190501.50483506': __('identifierSchemeCodeUnspsc190501.50483506'),
	'identifierSchemeCodeUnspsc190501.50483507': __('identifierSchemeCodeUnspsc190501.50483507'),
	'identifierSchemeCodeUnspsc190501.50483508': __('identifierSchemeCodeUnspsc190501.50483508'),
	'identifierSchemeCodeUnspsc190501.50483509': __('identifierSchemeCodeUnspsc190501.50483509'),
	'identifierSchemeCodeUnspsc190501.50483510': __('identifierSchemeCodeUnspsc190501.50483510'),
	'identifierSchemeCodeUnspsc190501.50483511': __('identifierSchemeCodeUnspsc190501.50483511'),
	'identifierSchemeCodeUnspsc190501.50483512': __('identifierSchemeCodeUnspsc190501.50483512'),
	'identifierSchemeCodeUnspsc190501.50483513': __('identifierSchemeCodeUnspsc190501.50483513'),
	'identifierSchemeCodeUnspsc190501.50483514': __('identifierSchemeCodeUnspsc190501.50483514'),
	'identifierSchemeCodeUnspsc190501.50483515': __('identifierSchemeCodeUnspsc190501.50483515'),
	'identifierSchemeCodeUnspsc190501.50483516': __('identifierSchemeCodeUnspsc190501.50483516'),
	'identifierSchemeCodeUnspsc190501.50483517': __('identifierSchemeCodeUnspsc190501.50483517'),
	'identifierSchemeCodeUnspsc190501.50483518': __('identifierSchemeCodeUnspsc190501.50483518'),
	'identifierSchemeCodeUnspsc190501.50483519': __('identifierSchemeCodeUnspsc190501.50483519'),
	'identifierSchemeCodeUnspsc190501.50483520': __('identifierSchemeCodeUnspsc190501.50483520'),
	'identifierSchemeCodeUnspsc190501.50483600': __('identifierSchemeCodeUnspsc190501.50483600'),
	'identifierSchemeCodeUnspsc190501.50483601': __('identifierSchemeCodeUnspsc190501.50483601'),
	'identifierSchemeCodeUnspsc190501.50483602': __('identifierSchemeCodeUnspsc190501.50483602'),
	'identifierSchemeCodeUnspsc190501.50483603': __('identifierSchemeCodeUnspsc190501.50483603'),
	'identifierSchemeCodeUnspsc190501.50483604': __('identifierSchemeCodeUnspsc190501.50483604'),
	'identifierSchemeCodeUnspsc190501.50483605': __('identifierSchemeCodeUnspsc190501.50483605'),
	'identifierSchemeCodeUnspsc190501.50483606': __('identifierSchemeCodeUnspsc190501.50483606'),
	'identifierSchemeCodeUnspsc190501.50483607': __('identifierSchemeCodeUnspsc190501.50483607'),
	'identifierSchemeCodeUnspsc190501.50483608': __('identifierSchemeCodeUnspsc190501.50483608'),
	'identifierSchemeCodeUnspsc190501.50483609': __('identifierSchemeCodeUnspsc190501.50483609'),
	'identifierSchemeCodeUnspsc190501.50483610': __('identifierSchemeCodeUnspsc190501.50483610'),
	'identifierSchemeCodeUnspsc190501.50483611': __('identifierSchemeCodeUnspsc190501.50483611'),
	'identifierSchemeCodeUnspsc190501.50483612': __('identifierSchemeCodeUnspsc190501.50483612'),
	'identifierSchemeCodeUnspsc190501.50483613': __('identifierSchemeCodeUnspsc190501.50483613'),
	'identifierSchemeCodeUnspsc190501.50483614': __('identifierSchemeCodeUnspsc190501.50483614'),
	'identifierSchemeCodeUnspsc190501.50483700': __('identifierSchemeCodeUnspsc190501.50483700'),
	'identifierSchemeCodeUnspsc190501.50483701': __('identifierSchemeCodeUnspsc190501.50483701'),
	'identifierSchemeCodeUnspsc190501.50483702': __('identifierSchemeCodeUnspsc190501.50483702'),
	'identifierSchemeCodeUnspsc190501.50483703': __('identifierSchemeCodeUnspsc190501.50483703'),
	'identifierSchemeCodeUnspsc190501.50483704': __('identifierSchemeCodeUnspsc190501.50483704'),
	'identifierSchemeCodeUnspsc190501.50483705': __('identifierSchemeCodeUnspsc190501.50483705'),
	'identifierSchemeCodeUnspsc190501.50483706': __('identifierSchemeCodeUnspsc190501.50483706'),
	'identifierSchemeCodeUnspsc190501.50483707': __('identifierSchemeCodeUnspsc190501.50483707'),
	'identifierSchemeCodeUnspsc190501.50483800': __('identifierSchemeCodeUnspsc190501.50483800'),
	'identifierSchemeCodeUnspsc190501.50483801': __('identifierSchemeCodeUnspsc190501.50483801'),
	'identifierSchemeCodeUnspsc190501.50483802': __('identifierSchemeCodeUnspsc190501.50483802'),
	'identifierSchemeCodeUnspsc190501.50483803': __('identifierSchemeCodeUnspsc190501.50483803'),
	'identifierSchemeCodeUnspsc190501.50483804': __('identifierSchemeCodeUnspsc190501.50483804'),
	'identifierSchemeCodeUnspsc190501.50483805': __('identifierSchemeCodeUnspsc190501.50483805'),
	'identifierSchemeCodeUnspsc190501.50483806': __('identifierSchemeCodeUnspsc190501.50483806'),
	'identifierSchemeCodeUnspsc190501.50483807': __('identifierSchemeCodeUnspsc190501.50483807'),
	'identifierSchemeCodeUnspsc190501.50483808': __('identifierSchemeCodeUnspsc190501.50483808'),
	'identifierSchemeCodeUnspsc190501.50483809': __('identifierSchemeCodeUnspsc190501.50483809'),
	'identifierSchemeCodeUnspsc190501.50483810': __('identifierSchemeCodeUnspsc190501.50483810'),
	'identifierSchemeCodeUnspsc190501.50483811': __('identifierSchemeCodeUnspsc190501.50483811'),
	'identifierSchemeCodeUnspsc190501.50483900': __('identifierSchemeCodeUnspsc190501.50483900'),
	'identifierSchemeCodeUnspsc190501.50483901': __('identifierSchemeCodeUnspsc190501.50483901'),
	'identifierSchemeCodeUnspsc190501.50483902': __('identifierSchemeCodeUnspsc190501.50483902'),
	'identifierSchemeCodeUnspsc190501.50483903': __('identifierSchemeCodeUnspsc190501.50483903'),
	'identifierSchemeCodeUnspsc190501.50483904': __('identifierSchemeCodeUnspsc190501.50483904'),
	'identifierSchemeCodeUnspsc190501.50483905': __('identifierSchemeCodeUnspsc190501.50483905'),
	'identifierSchemeCodeUnspsc190501.50483906': __('identifierSchemeCodeUnspsc190501.50483906'),
	'identifierSchemeCodeUnspsc190501.50483907': __('identifierSchemeCodeUnspsc190501.50483907'),
	'identifierSchemeCodeUnspsc190501.50483908': __('identifierSchemeCodeUnspsc190501.50483908'),
	'identifierSchemeCodeUnspsc190501.50483909': __('identifierSchemeCodeUnspsc190501.50483909'),
	'identifierSchemeCodeUnspsc190501.50483910': __('identifierSchemeCodeUnspsc190501.50483910'),
	'identifierSchemeCodeUnspsc190501.50483911': __('identifierSchemeCodeUnspsc190501.50483911'),
	'identifierSchemeCodeUnspsc190501.50484000': __('identifierSchemeCodeUnspsc190501.50484000'),
	'identifierSchemeCodeUnspsc190501.50484001': __('identifierSchemeCodeUnspsc190501.50484001'),
	'identifierSchemeCodeUnspsc190501.50484002': __('identifierSchemeCodeUnspsc190501.50484002'),
	'identifierSchemeCodeUnspsc190501.50484003': __('identifierSchemeCodeUnspsc190501.50484003'),
	'identifierSchemeCodeUnspsc190501.50484004': __('identifierSchemeCodeUnspsc190501.50484004'),
	'identifierSchemeCodeUnspsc190501.50484005': __('identifierSchemeCodeUnspsc190501.50484005'),
	'identifierSchemeCodeUnspsc190501.50484100': __('identifierSchemeCodeUnspsc190501.50484100'),
	'identifierSchemeCodeUnspsc190501.50484101': __('identifierSchemeCodeUnspsc190501.50484101'),
	'identifierSchemeCodeUnspsc190501.50484102': __('identifierSchemeCodeUnspsc190501.50484102'),
	'identifierSchemeCodeUnspsc190501.50484103': __('identifierSchemeCodeUnspsc190501.50484103'),
	'identifierSchemeCodeUnspsc190501.50484104': __('identifierSchemeCodeUnspsc190501.50484104'),
	'identifierSchemeCodeUnspsc190501.50484105': __('identifierSchemeCodeUnspsc190501.50484105'),
	'identifierSchemeCodeUnspsc190501.50484106': __('identifierSchemeCodeUnspsc190501.50484106'),
	'identifierSchemeCodeUnspsc190501.50484107': __('identifierSchemeCodeUnspsc190501.50484107'),
	'identifierSchemeCodeUnspsc190501.50484108': __('identifierSchemeCodeUnspsc190501.50484108'),
	'identifierSchemeCodeUnspsc190501.50484109': __('identifierSchemeCodeUnspsc190501.50484109'),
	'identifierSchemeCodeUnspsc190501.50484110': __('identifierSchemeCodeUnspsc190501.50484110'),
	'identifierSchemeCodeUnspsc190501.50484111': __('identifierSchemeCodeUnspsc190501.50484111'),
	'identifierSchemeCodeUnspsc190501.50484112': __('identifierSchemeCodeUnspsc190501.50484112'),
	'identifierSchemeCodeUnspsc190501.50484113': __('identifierSchemeCodeUnspsc190501.50484113'),
	'identifierSchemeCodeUnspsc190501.50484114': __('identifierSchemeCodeUnspsc190501.50484114'),
	'identifierSchemeCodeUnspsc190501.50484115': __('identifierSchemeCodeUnspsc190501.50484115'),
	'identifierSchemeCodeUnspsc190501.50484116': __('identifierSchemeCodeUnspsc190501.50484116'),
	'identifierSchemeCodeUnspsc190501.50484117': __('identifierSchemeCodeUnspsc190501.50484117'),
	'identifierSchemeCodeUnspsc190501.50484118': __('identifierSchemeCodeUnspsc190501.50484118'),
	'identifierSchemeCodeUnspsc190501.50484119': __('identifierSchemeCodeUnspsc190501.50484119'),
	'identifierSchemeCodeUnspsc190501.50484120': __('identifierSchemeCodeUnspsc190501.50484120'),
	'identifierSchemeCodeUnspsc190501.50484121': __('identifierSchemeCodeUnspsc190501.50484121'),
	'identifierSchemeCodeUnspsc190501.50484122': __('identifierSchemeCodeUnspsc190501.50484122'),
	'identifierSchemeCodeUnspsc190501.50484123': __('identifierSchemeCodeUnspsc190501.50484123'),
	'identifierSchemeCodeUnspsc190501.50484124': __('identifierSchemeCodeUnspsc190501.50484124'),
	'identifierSchemeCodeUnspsc190501.50484125': __('identifierSchemeCodeUnspsc190501.50484125'),
	'identifierSchemeCodeUnspsc190501.50484126': __('identifierSchemeCodeUnspsc190501.50484126'),
	'identifierSchemeCodeUnspsc190501.50484127': __('identifierSchemeCodeUnspsc190501.50484127'),
	'identifierSchemeCodeUnspsc190501.50484200': __('identifierSchemeCodeUnspsc190501.50484200'),
	'identifierSchemeCodeUnspsc190501.50484201': __('identifierSchemeCodeUnspsc190501.50484201'),
	'identifierSchemeCodeUnspsc190501.50484202': __('identifierSchemeCodeUnspsc190501.50484202'),
	'identifierSchemeCodeUnspsc190501.50484300': __('identifierSchemeCodeUnspsc190501.50484300'),
	'identifierSchemeCodeUnspsc190501.50484301': __('identifierSchemeCodeUnspsc190501.50484301'),
	'identifierSchemeCodeUnspsc190501.50484302': __('identifierSchemeCodeUnspsc190501.50484302'),
	'identifierSchemeCodeUnspsc190501.50484303': __('identifierSchemeCodeUnspsc190501.50484303'),
	'identifierSchemeCodeUnspsc190501.50484304': __('identifierSchemeCodeUnspsc190501.50484304'),
	'identifierSchemeCodeUnspsc190501.50484305': __('identifierSchemeCodeUnspsc190501.50484305'),
	'identifierSchemeCodeUnspsc190501.50484306': __('identifierSchemeCodeUnspsc190501.50484306'),
	'identifierSchemeCodeUnspsc190501.50484400': __('identifierSchemeCodeUnspsc190501.50484400'),
	'identifierSchemeCodeUnspsc190501.50484401': __('identifierSchemeCodeUnspsc190501.50484401'),
	'identifierSchemeCodeUnspsc190501.50484402': __('identifierSchemeCodeUnspsc190501.50484402'),
	'identifierSchemeCodeUnspsc190501.50484403': __('identifierSchemeCodeUnspsc190501.50484403'),
	'identifierSchemeCodeUnspsc190501.50484404': __('identifierSchemeCodeUnspsc190501.50484404'),
	'identifierSchemeCodeUnspsc190501.50484405': __('identifierSchemeCodeUnspsc190501.50484405'),
	'identifierSchemeCodeUnspsc190501.50484406': __('identifierSchemeCodeUnspsc190501.50484406'),
	'identifierSchemeCodeUnspsc190501.50484500': __('identifierSchemeCodeUnspsc190501.50484500'),
	'identifierSchemeCodeUnspsc190501.50484501': __('identifierSchemeCodeUnspsc190501.50484501'),
	'identifierSchemeCodeUnspsc190501.50484502': __('identifierSchemeCodeUnspsc190501.50484502'),
	'identifierSchemeCodeUnspsc190501.50484503': __('identifierSchemeCodeUnspsc190501.50484503'),
	'identifierSchemeCodeUnspsc190501.50484504': __('identifierSchemeCodeUnspsc190501.50484504'),
	'identifierSchemeCodeUnspsc190501.50484505': __('identifierSchemeCodeUnspsc190501.50484505'),
	'identifierSchemeCodeUnspsc190501.50484506': __('identifierSchemeCodeUnspsc190501.50484506'),
	'identifierSchemeCodeUnspsc190501.50484507': __('identifierSchemeCodeUnspsc190501.50484507'),
	'identifierSchemeCodeUnspsc190501.50484508': __('identifierSchemeCodeUnspsc190501.50484508'),
	'identifierSchemeCodeUnspsc190501.50484600': __('identifierSchemeCodeUnspsc190501.50484600'),
	'identifierSchemeCodeUnspsc190501.50484601': __('identifierSchemeCodeUnspsc190501.50484601'),
	'identifierSchemeCodeUnspsc190501.50484602': __('identifierSchemeCodeUnspsc190501.50484602'),
	'identifierSchemeCodeUnspsc190501.50484603': __('identifierSchemeCodeUnspsc190501.50484603'),
	'identifierSchemeCodeUnspsc190501.50484604': __('identifierSchemeCodeUnspsc190501.50484604'),
	'identifierSchemeCodeUnspsc190501.50484605': __('identifierSchemeCodeUnspsc190501.50484605'),
	'identifierSchemeCodeUnspsc190501.50484606': __('identifierSchemeCodeUnspsc190501.50484606'),
	'identifierSchemeCodeUnspsc190501.50484607': __('identifierSchemeCodeUnspsc190501.50484607'),
	'identifierSchemeCodeUnspsc190501.50484608': __('identifierSchemeCodeUnspsc190501.50484608'),
	'identifierSchemeCodeUnspsc190501.50484609': __('identifierSchemeCodeUnspsc190501.50484609'),
	'identifierSchemeCodeUnspsc190501.50484610': __('identifierSchemeCodeUnspsc190501.50484610'),
	'identifierSchemeCodeUnspsc190501.50484611': __('identifierSchemeCodeUnspsc190501.50484611'),
	'identifierSchemeCodeUnspsc190501.50484612': __('identifierSchemeCodeUnspsc190501.50484612'),
	'identifierSchemeCodeUnspsc190501.50484613': __('identifierSchemeCodeUnspsc190501.50484613'),
	'identifierSchemeCodeUnspsc190501.50484614': __('identifierSchemeCodeUnspsc190501.50484614'),
	'identifierSchemeCodeUnspsc190501.50484615': __('identifierSchemeCodeUnspsc190501.50484615'),
	'identifierSchemeCodeUnspsc190501.50484616': __('identifierSchemeCodeUnspsc190501.50484616'),
	'identifierSchemeCodeUnspsc190501.50484617': __('identifierSchemeCodeUnspsc190501.50484617'),
	'identifierSchemeCodeUnspsc190501.50484618': __('identifierSchemeCodeUnspsc190501.50484618'),
	'identifierSchemeCodeUnspsc190501.50484619': __('identifierSchemeCodeUnspsc190501.50484619'),
	'identifierSchemeCodeUnspsc190501.50484620': __('identifierSchemeCodeUnspsc190501.50484620'),
	'identifierSchemeCodeUnspsc190501.50484621': __('identifierSchemeCodeUnspsc190501.50484621'),
	'identifierSchemeCodeUnspsc190501.50484622': __('identifierSchemeCodeUnspsc190501.50484622'),
	'identifierSchemeCodeUnspsc190501.50484623': __('identifierSchemeCodeUnspsc190501.50484623'),
	'identifierSchemeCodeUnspsc190501.50484700': __('identifierSchemeCodeUnspsc190501.50484700'),
	'identifierSchemeCodeUnspsc190501.50484701': __('identifierSchemeCodeUnspsc190501.50484701'),
	'identifierSchemeCodeUnspsc190501.50484702': __('identifierSchemeCodeUnspsc190501.50484702'),
	'identifierSchemeCodeUnspsc190501.50484703': __('identifierSchemeCodeUnspsc190501.50484703'),
	'identifierSchemeCodeUnspsc190501.50484704': __('identifierSchemeCodeUnspsc190501.50484704'),
	'identifierSchemeCodeUnspsc190501.50484705': __('identifierSchemeCodeUnspsc190501.50484705'),
	'identifierSchemeCodeUnspsc190501.50484706': __('identifierSchemeCodeUnspsc190501.50484706'),
	'identifierSchemeCodeUnspsc190501.50484800': __('identifierSchemeCodeUnspsc190501.50484800'),
	'identifierSchemeCodeUnspsc190501.50484801': __('identifierSchemeCodeUnspsc190501.50484801'),
	'identifierSchemeCodeUnspsc190501.50484802': __('identifierSchemeCodeUnspsc190501.50484802'),
	'identifierSchemeCodeUnspsc190501.50484803': __('identifierSchemeCodeUnspsc190501.50484803'),
	'identifierSchemeCodeUnspsc190501.50484804': __('identifierSchemeCodeUnspsc190501.50484804'),
	'identifierSchemeCodeUnspsc190501.50484805': __('identifierSchemeCodeUnspsc190501.50484805'),
	'identifierSchemeCodeUnspsc190501.50484806': __('identifierSchemeCodeUnspsc190501.50484806'),
	'identifierSchemeCodeUnspsc190501.50484807': __('identifierSchemeCodeUnspsc190501.50484807'),
	'identifierSchemeCodeUnspsc190501.50484808': __('identifierSchemeCodeUnspsc190501.50484808'),
	'identifierSchemeCodeUnspsc190501.50484809': __('identifierSchemeCodeUnspsc190501.50484809'),
	'identifierSchemeCodeUnspsc190501.50484810': __('identifierSchemeCodeUnspsc190501.50484810'),
	'identifierSchemeCodeUnspsc190501.50484811': __('identifierSchemeCodeUnspsc190501.50484811'),
	'identifierSchemeCodeUnspsc190501.50484812': __('identifierSchemeCodeUnspsc190501.50484812'),
	'identifierSchemeCodeUnspsc190501.50484813': __('identifierSchemeCodeUnspsc190501.50484813'),
	'identifierSchemeCodeUnspsc190501.50484814': __('identifierSchemeCodeUnspsc190501.50484814'),
	'identifierSchemeCodeUnspsc190501.50484815': __('identifierSchemeCodeUnspsc190501.50484815'),
	'identifierSchemeCodeUnspsc190501.50484816': __('identifierSchemeCodeUnspsc190501.50484816'),
	'identifierSchemeCodeUnspsc190501.50484817': __('identifierSchemeCodeUnspsc190501.50484817'),
	'identifierSchemeCodeUnspsc190501.50484818': __('identifierSchemeCodeUnspsc190501.50484818'),
	'identifierSchemeCodeUnspsc190501.50484819': __('identifierSchemeCodeUnspsc190501.50484819'),
	'identifierSchemeCodeUnspsc190501.50484820': __('identifierSchemeCodeUnspsc190501.50484820'),
	'identifierSchemeCodeUnspsc190501.50484821': __('identifierSchemeCodeUnspsc190501.50484821'),
	'identifierSchemeCodeUnspsc190501.50484822': __('identifierSchemeCodeUnspsc190501.50484822'),
	'identifierSchemeCodeUnspsc190501.50484823': __('identifierSchemeCodeUnspsc190501.50484823'),
	'identifierSchemeCodeUnspsc190501.50484900': __('identifierSchemeCodeUnspsc190501.50484900'),
	'identifierSchemeCodeUnspsc190501.50484901': __('identifierSchemeCodeUnspsc190501.50484901'),
	'identifierSchemeCodeUnspsc190501.50484902': __('identifierSchemeCodeUnspsc190501.50484902'),
	'identifierSchemeCodeUnspsc190501.50484903': __('identifierSchemeCodeUnspsc190501.50484903'),
	'identifierSchemeCodeUnspsc190501.50484904': __('identifierSchemeCodeUnspsc190501.50484904'),
	'identifierSchemeCodeUnspsc190501.50484905': __('identifierSchemeCodeUnspsc190501.50484905'),
	'identifierSchemeCodeUnspsc190501.50484906': __('identifierSchemeCodeUnspsc190501.50484906'),
	'identifierSchemeCodeUnspsc190501.50485000': __('identifierSchemeCodeUnspsc190501.50485000'),
	'identifierSchemeCodeUnspsc190501.50485001': __('identifierSchemeCodeUnspsc190501.50485001'),
	'identifierSchemeCodeUnspsc190501.50485002': __('identifierSchemeCodeUnspsc190501.50485002'),
	'identifierSchemeCodeUnspsc190501.50485003': __('identifierSchemeCodeUnspsc190501.50485003'),
	'identifierSchemeCodeUnspsc190501.50485004': __('identifierSchemeCodeUnspsc190501.50485004'),
	'identifierSchemeCodeUnspsc190501.50485100': __('identifierSchemeCodeUnspsc190501.50485100'),
	'identifierSchemeCodeUnspsc190501.50485101': __('identifierSchemeCodeUnspsc190501.50485101'),
	'identifierSchemeCodeUnspsc190501.50485102': __('identifierSchemeCodeUnspsc190501.50485102'),
	'identifierSchemeCodeUnspsc190501.50485103': __('identifierSchemeCodeUnspsc190501.50485103'),
	'identifierSchemeCodeUnspsc190501.50485104': __('identifierSchemeCodeUnspsc190501.50485104'),
	'identifierSchemeCodeUnspsc190501.50485105': __('identifierSchemeCodeUnspsc190501.50485105'),
	'identifierSchemeCodeUnspsc190501.50485106': __('identifierSchemeCodeUnspsc190501.50485106'),
	'identifierSchemeCodeUnspsc190501.50485107': __('identifierSchemeCodeUnspsc190501.50485107'),
	'identifierSchemeCodeUnspsc190501.50485200': __('identifierSchemeCodeUnspsc190501.50485200'),
	'identifierSchemeCodeUnspsc190501.50485201': __('identifierSchemeCodeUnspsc190501.50485201'),
	'identifierSchemeCodeUnspsc190501.50485202': __('identifierSchemeCodeUnspsc190501.50485202'),
	'identifierSchemeCodeUnspsc190501.50485203': __('identifierSchemeCodeUnspsc190501.50485203'),
	'identifierSchemeCodeUnspsc190501.50485204': __('identifierSchemeCodeUnspsc190501.50485204'),
	'identifierSchemeCodeUnspsc190501.50485205': __('identifierSchemeCodeUnspsc190501.50485205'),
	'identifierSchemeCodeUnspsc190501.50485206': __('identifierSchemeCodeUnspsc190501.50485206'),
	'identifierSchemeCodeUnspsc190501.50485207': __('identifierSchemeCodeUnspsc190501.50485207'),
	'identifierSchemeCodeUnspsc190501.50485208': __('identifierSchemeCodeUnspsc190501.50485208'),
	'identifierSchemeCodeUnspsc190501.50485209': __('identifierSchemeCodeUnspsc190501.50485209'),
	'identifierSchemeCodeUnspsc190501.50485210': __('identifierSchemeCodeUnspsc190501.50485210'),
	'identifierSchemeCodeUnspsc190501.50485211': __('identifierSchemeCodeUnspsc190501.50485211'),
	'identifierSchemeCodeUnspsc190501.50485212': __('identifierSchemeCodeUnspsc190501.50485212'),
	'identifierSchemeCodeUnspsc190501.50485213': __('identifierSchemeCodeUnspsc190501.50485213'),
	'identifierSchemeCodeUnspsc190501.50485214': __('identifierSchemeCodeUnspsc190501.50485214'),
	'identifierSchemeCodeUnspsc190501.50485215': __('identifierSchemeCodeUnspsc190501.50485215'),
	'identifierSchemeCodeUnspsc190501.50485216': __('identifierSchemeCodeUnspsc190501.50485216'),
	'identifierSchemeCodeUnspsc190501.50485217': __('identifierSchemeCodeUnspsc190501.50485217'),
	'identifierSchemeCodeUnspsc190501.50485218': __('identifierSchemeCodeUnspsc190501.50485218'),
	'identifierSchemeCodeUnspsc190501.50485219': __('identifierSchemeCodeUnspsc190501.50485219'),
	'identifierSchemeCodeUnspsc190501.50485220': __('identifierSchemeCodeUnspsc190501.50485220'),
	'identifierSchemeCodeUnspsc190501.50485221': __('identifierSchemeCodeUnspsc190501.50485221'),
	'identifierSchemeCodeUnspsc190501.50485222': __('identifierSchemeCodeUnspsc190501.50485222'),
	'identifierSchemeCodeUnspsc190501.50485223': __('identifierSchemeCodeUnspsc190501.50485223'),
	'identifierSchemeCodeUnspsc190501.50485300': __('identifierSchemeCodeUnspsc190501.50485300'),
	'identifierSchemeCodeUnspsc190501.50485301': __('identifierSchemeCodeUnspsc190501.50485301'),
	'identifierSchemeCodeUnspsc190501.50485302': __('identifierSchemeCodeUnspsc190501.50485302'),
	'identifierSchemeCodeUnspsc190501.50485303': __('identifierSchemeCodeUnspsc190501.50485303'),
	'identifierSchemeCodeUnspsc190501.50485304': __('identifierSchemeCodeUnspsc190501.50485304'),
	'identifierSchemeCodeUnspsc190501.50485305': __('identifierSchemeCodeUnspsc190501.50485305'),
	'identifierSchemeCodeUnspsc190501.50485306': __('identifierSchemeCodeUnspsc190501.50485306'),
	'identifierSchemeCodeUnspsc190501.50485400': __('identifierSchemeCodeUnspsc190501.50485400'),
	'identifierSchemeCodeUnspsc190501.50485401': __('identifierSchemeCodeUnspsc190501.50485401'),
	'identifierSchemeCodeUnspsc190501.50485402': __('identifierSchemeCodeUnspsc190501.50485402'),
	'identifierSchemeCodeUnspsc190501.50485403': __('identifierSchemeCodeUnspsc190501.50485403'),
	'identifierSchemeCodeUnspsc190501.50485404': __('identifierSchemeCodeUnspsc190501.50485404'),
	'identifierSchemeCodeUnspsc190501.50485405': __('identifierSchemeCodeUnspsc190501.50485405'),
	'identifierSchemeCodeUnspsc190501.50485406': __('identifierSchemeCodeUnspsc190501.50485406'),
	'identifierSchemeCodeUnspsc190501.50485500': __('identifierSchemeCodeUnspsc190501.50485500'),
	'identifierSchemeCodeUnspsc190501.50485501': __('identifierSchemeCodeUnspsc190501.50485501'),
	'identifierSchemeCodeUnspsc190501.50485502': __('identifierSchemeCodeUnspsc190501.50485502'),
	'identifierSchemeCodeUnspsc190501.50485503': __('identifierSchemeCodeUnspsc190501.50485503'),
	'identifierSchemeCodeUnspsc190501.50485504': __('identifierSchemeCodeUnspsc190501.50485504'),
	'identifierSchemeCodeUnspsc190501.50485505': __('identifierSchemeCodeUnspsc190501.50485505'),
	'identifierSchemeCodeUnspsc190501.50485506': __('identifierSchemeCodeUnspsc190501.50485506'),
	'identifierSchemeCodeUnspsc190501.50485507': __('identifierSchemeCodeUnspsc190501.50485507'),
	'identifierSchemeCodeUnspsc190501.50485508': __('identifierSchemeCodeUnspsc190501.50485508'),
	'identifierSchemeCodeUnspsc190501.50485509': __('identifierSchemeCodeUnspsc190501.50485509'),
	'identifierSchemeCodeUnspsc190501.50485510': __('identifierSchemeCodeUnspsc190501.50485510'),
	'identifierSchemeCodeUnspsc190501.50485511': __('identifierSchemeCodeUnspsc190501.50485511'),
	'identifierSchemeCodeUnspsc190501.50485512': __('identifierSchemeCodeUnspsc190501.50485512'),
	'identifierSchemeCodeUnspsc190501.50485513': __('identifierSchemeCodeUnspsc190501.50485513'),
	'identifierSchemeCodeUnspsc190501.50485514': __('identifierSchemeCodeUnspsc190501.50485514'),
	'identifierSchemeCodeUnspsc190501.50485515': __('identifierSchemeCodeUnspsc190501.50485515'),
	'identifierSchemeCodeUnspsc190501.50485516': __('identifierSchemeCodeUnspsc190501.50485516'),
	'identifierSchemeCodeUnspsc190501.50485517': __('identifierSchemeCodeUnspsc190501.50485517'),
	'identifierSchemeCodeUnspsc190501.50485518': __('identifierSchemeCodeUnspsc190501.50485518'),
	'identifierSchemeCodeUnspsc190501.50485519': __('identifierSchemeCodeUnspsc190501.50485519'),
	'identifierSchemeCodeUnspsc190501.50485520': __('identifierSchemeCodeUnspsc190501.50485520'),
	'identifierSchemeCodeUnspsc190501.50485521': __('identifierSchemeCodeUnspsc190501.50485521'),
	'identifierSchemeCodeUnspsc190501.50485522': __('identifierSchemeCodeUnspsc190501.50485522'),
	'identifierSchemeCodeUnspsc190501.50485523': __('identifierSchemeCodeUnspsc190501.50485523'),
	'identifierSchemeCodeUnspsc190501.50485524': __('identifierSchemeCodeUnspsc190501.50485524'),
	'identifierSchemeCodeUnspsc190501.50485525': __('identifierSchemeCodeUnspsc190501.50485525'),
	'identifierSchemeCodeUnspsc190501.50485526': __('identifierSchemeCodeUnspsc190501.50485526'),
	'identifierSchemeCodeUnspsc190501.50485527': __('identifierSchemeCodeUnspsc190501.50485527'),
	'identifierSchemeCodeUnspsc190501.50485528': __('identifierSchemeCodeUnspsc190501.50485528'),
	'identifierSchemeCodeUnspsc190501.50485529': __('identifierSchemeCodeUnspsc190501.50485529'),
	'identifierSchemeCodeUnspsc190501.50485530': __('identifierSchemeCodeUnspsc190501.50485530'),
	'identifierSchemeCodeUnspsc190501.50485531': __('identifierSchemeCodeUnspsc190501.50485531'),
	'identifierSchemeCodeUnspsc190501.50485532': __('identifierSchemeCodeUnspsc190501.50485532'),
	'identifierSchemeCodeUnspsc190501.50485533': __('identifierSchemeCodeUnspsc190501.50485533'),
	'identifierSchemeCodeUnspsc190501.50485534': __('identifierSchemeCodeUnspsc190501.50485534'),
	'identifierSchemeCodeUnspsc190501.50485535': __('identifierSchemeCodeUnspsc190501.50485535'),
	'identifierSchemeCodeUnspsc190501.50485536': __('identifierSchemeCodeUnspsc190501.50485536'),
	'identifierSchemeCodeUnspsc190501.50485537': __('identifierSchemeCodeUnspsc190501.50485537'),
	'identifierSchemeCodeUnspsc190501.50485538': __('identifierSchemeCodeUnspsc190501.50485538'),
	'identifierSchemeCodeUnspsc190501.50485539': __('identifierSchemeCodeUnspsc190501.50485539'),
	'identifierSchemeCodeUnspsc190501.50485540': __('identifierSchemeCodeUnspsc190501.50485540'),
	'identifierSchemeCodeUnspsc190501.50485541': __('identifierSchemeCodeUnspsc190501.50485541'),
	'identifierSchemeCodeUnspsc190501.50485542': __('identifierSchemeCodeUnspsc190501.50485542'),
	'identifierSchemeCodeUnspsc190501.50485600': __('identifierSchemeCodeUnspsc190501.50485600'),
	'identifierSchemeCodeUnspsc190501.50485601': __('identifierSchemeCodeUnspsc190501.50485601'),
	'identifierSchemeCodeUnspsc190501.50485602': __('identifierSchemeCodeUnspsc190501.50485602'),
	'identifierSchemeCodeUnspsc190501.50485603': __('identifierSchemeCodeUnspsc190501.50485603'),
	'identifierSchemeCodeUnspsc190501.50485604': __('identifierSchemeCodeUnspsc190501.50485604'),
	'identifierSchemeCodeUnspsc190501.50485605': __('identifierSchemeCodeUnspsc190501.50485605'),
	'identifierSchemeCodeUnspsc190501.50485606': __('identifierSchemeCodeUnspsc190501.50485606'),
	'identifierSchemeCodeUnspsc190501.50485607': __('identifierSchemeCodeUnspsc190501.50485607'),
	'identifierSchemeCodeUnspsc190501.50485608': __('identifierSchemeCodeUnspsc190501.50485608'),
	'identifierSchemeCodeUnspsc190501.50485609': __('identifierSchemeCodeUnspsc190501.50485609'),
	'identifierSchemeCodeUnspsc190501.50485610': __('identifierSchemeCodeUnspsc190501.50485610'),
	'identifierSchemeCodeUnspsc190501.50485611': __('identifierSchemeCodeUnspsc190501.50485611'),
	'identifierSchemeCodeUnspsc190501.50485700': __('identifierSchemeCodeUnspsc190501.50485700'),
	'identifierSchemeCodeUnspsc190501.50485701': __('identifierSchemeCodeUnspsc190501.50485701'),
	'identifierSchemeCodeUnspsc190501.50485702': __('identifierSchemeCodeUnspsc190501.50485702'),
	'identifierSchemeCodeUnspsc190501.50485703': __('identifierSchemeCodeUnspsc190501.50485703'),
	'identifierSchemeCodeUnspsc190501.50485704': __('identifierSchemeCodeUnspsc190501.50485704'),
	'identifierSchemeCodeUnspsc190501.50485800': __('identifierSchemeCodeUnspsc190501.50485800'),
	'identifierSchemeCodeUnspsc190501.50485801': __('identifierSchemeCodeUnspsc190501.50485801'),
	'identifierSchemeCodeUnspsc190501.50485802': __('identifierSchemeCodeUnspsc190501.50485802'),
	'identifierSchemeCodeUnspsc190501.50485803': __('identifierSchemeCodeUnspsc190501.50485803'),
	'identifierSchemeCodeUnspsc190501.50485804': __('identifierSchemeCodeUnspsc190501.50485804'),
	'identifierSchemeCodeUnspsc190501.50485805': __('identifierSchemeCodeUnspsc190501.50485805'),
	'identifierSchemeCodeUnspsc190501.50485806': __('identifierSchemeCodeUnspsc190501.50485806'),
	'identifierSchemeCodeUnspsc190501.50485807': __('identifierSchemeCodeUnspsc190501.50485807'),
	'identifierSchemeCodeUnspsc190501.50485808': __('identifierSchemeCodeUnspsc190501.50485808'),
	'identifierSchemeCodeUnspsc190501.50485809': __('identifierSchemeCodeUnspsc190501.50485809'),
	'identifierSchemeCodeUnspsc190501.50485810': __('identifierSchemeCodeUnspsc190501.50485810'),
	'identifierSchemeCodeUnspsc190501.50485811': __('identifierSchemeCodeUnspsc190501.50485811'),
	'identifierSchemeCodeUnspsc190501.50485812': __('identifierSchemeCodeUnspsc190501.50485812'),
	'identifierSchemeCodeUnspsc190501.50485813': __('identifierSchemeCodeUnspsc190501.50485813'),
	'identifierSchemeCodeUnspsc190501.50485814': __('identifierSchemeCodeUnspsc190501.50485814'),
	'identifierSchemeCodeUnspsc190501.50485815': __('identifierSchemeCodeUnspsc190501.50485815'),
	'identifierSchemeCodeUnspsc190501.50485816': __('identifierSchemeCodeUnspsc190501.50485816'),
	'identifierSchemeCodeUnspsc190501.50485817': __('identifierSchemeCodeUnspsc190501.50485817'),
	'identifierSchemeCodeUnspsc190501.50485900': __('identifierSchemeCodeUnspsc190501.50485900'),
	'identifierSchemeCodeUnspsc190501.50485901': __('identifierSchemeCodeUnspsc190501.50485901'),
	'identifierSchemeCodeUnspsc190501.50485902': __('identifierSchemeCodeUnspsc190501.50485902'),
	'identifierSchemeCodeUnspsc190501.50485903': __('identifierSchemeCodeUnspsc190501.50485903'),
	'identifierSchemeCodeUnspsc190501.50485904': __('identifierSchemeCodeUnspsc190501.50485904'),
	'identifierSchemeCodeUnspsc190501.50485905': __('identifierSchemeCodeUnspsc190501.50485905'),
	'identifierSchemeCodeUnspsc190501.50485906': __('identifierSchemeCodeUnspsc190501.50485906'),
	'identifierSchemeCodeUnspsc190501.50485907': __('identifierSchemeCodeUnspsc190501.50485907'),
	'identifierSchemeCodeUnspsc190501.50485908': __('identifierSchemeCodeUnspsc190501.50485908'),
	'identifierSchemeCodeUnspsc190501.50485909': __('identifierSchemeCodeUnspsc190501.50485909'),
	'identifierSchemeCodeUnspsc190501.50485910': __('identifierSchemeCodeUnspsc190501.50485910'),
	'identifierSchemeCodeUnspsc190501.50485911': __('identifierSchemeCodeUnspsc190501.50485911'),
	'identifierSchemeCodeUnspsc190501.50486000': __('identifierSchemeCodeUnspsc190501.50486000'),
	'identifierSchemeCodeUnspsc190501.50486001': __('identifierSchemeCodeUnspsc190501.50486001'),
	'identifierSchemeCodeUnspsc190501.50486002': __('identifierSchemeCodeUnspsc190501.50486002'),
	'identifierSchemeCodeUnspsc190501.50486003': __('identifierSchemeCodeUnspsc190501.50486003'),
	'identifierSchemeCodeUnspsc190501.50486004': __('identifierSchemeCodeUnspsc190501.50486004'),
	'identifierSchemeCodeUnspsc190501.50486100': __('identifierSchemeCodeUnspsc190501.50486100'),
	'identifierSchemeCodeUnspsc190501.50486101': __('identifierSchemeCodeUnspsc190501.50486101'),
	'identifierSchemeCodeUnspsc190501.50486102': __('identifierSchemeCodeUnspsc190501.50486102'),
	'identifierSchemeCodeUnspsc190501.50486103': __('identifierSchemeCodeUnspsc190501.50486103'),
	'identifierSchemeCodeUnspsc190501.50486104': __('identifierSchemeCodeUnspsc190501.50486104'),
	'identifierSchemeCodeUnspsc190501.50486105': __('identifierSchemeCodeUnspsc190501.50486105'),
	'identifierSchemeCodeUnspsc190501.50486106': __('identifierSchemeCodeUnspsc190501.50486106'),
	'identifierSchemeCodeUnspsc190501.50486107': __('identifierSchemeCodeUnspsc190501.50486107'),
	'identifierSchemeCodeUnspsc190501.50486108': __('identifierSchemeCodeUnspsc190501.50486108'),
	'identifierSchemeCodeUnspsc190501.50486109': __('identifierSchemeCodeUnspsc190501.50486109'),
	'identifierSchemeCodeUnspsc190501.50486110': __('identifierSchemeCodeUnspsc190501.50486110'),
	'identifierSchemeCodeUnspsc190501.50486111': __('identifierSchemeCodeUnspsc190501.50486111'),
	'identifierSchemeCodeUnspsc190501.50486112': __('identifierSchemeCodeUnspsc190501.50486112'),
	'identifierSchemeCodeUnspsc190501.50486113': __('identifierSchemeCodeUnspsc190501.50486113'),
	'identifierSchemeCodeUnspsc190501.50486114': __('identifierSchemeCodeUnspsc190501.50486114'),
	'identifierSchemeCodeUnspsc190501.50486115': __('identifierSchemeCodeUnspsc190501.50486115'),
	'identifierSchemeCodeUnspsc190501.50486200': __('identifierSchemeCodeUnspsc190501.50486200'),
	'identifierSchemeCodeUnspsc190501.50486201': __('identifierSchemeCodeUnspsc190501.50486201'),
	'identifierSchemeCodeUnspsc190501.50486202': __('identifierSchemeCodeUnspsc190501.50486202'),
	'identifierSchemeCodeUnspsc190501.50486203': __('identifierSchemeCodeUnspsc190501.50486203'),
	'identifierSchemeCodeUnspsc190501.50486204': __('identifierSchemeCodeUnspsc190501.50486204'),
	'identifierSchemeCodeUnspsc190501.50486205': __('identifierSchemeCodeUnspsc190501.50486205'),
	'identifierSchemeCodeUnspsc190501.50486206': __('identifierSchemeCodeUnspsc190501.50486206'),
	'identifierSchemeCodeUnspsc190501.50486207': __('identifierSchemeCodeUnspsc190501.50486207'),
	'identifierSchemeCodeUnspsc190501.50486208': __('identifierSchemeCodeUnspsc190501.50486208'),
	'identifierSchemeCodeUnspsc190501.50486209': __('identifierSchemeCodeUnspsc190501.50486209'),
	'identifierSchemeCodeUnspsc190501.50486210': __('identifierSchemeCodeUnspsc190501.50486210'),
	'identifierSchemeCodeUnspsc190501.50486211': __('identifierSchemeCodeUnspsc190501.50486211'),
	'identifierSchemeCodeUnspsc190501.50486212': __('identifierSchemeCodeUnspsc190501.50486212'),
	'identifierSchemeCodeUnspsc190501.50486213': __('identifierSchemeCodeUnspsc190501.50486213'),
	'identifierSchemeCodeUnspsc190501.50486214': __('identifierSchemeCodeUnspsc190501.50486214'),
	'identifierSchemeCodeUnspsc190501.50486215': __('identifierSchemeCodeUnspsc190501.50486215'),
	'identifierSchemeCodeUnspsc190501.50486216': __('identifierSchemeCodeUnspsc190501.50486216'),
	'identifierSchemeCodeUnspsc190501.50486217': __('identifierSchemeCodeUnspsc190501.50486217'),
	'identifierSchemeCodeUnspsc190501.50486218': __('identifierSchemeCodeUnspsc190501.50486218'),
	'identifierSchemeCodeUnspsc190501.50486219': __('identifierSchemeCodeUnspsc190501.50486219'),
	'identifierSchemeCodeUnspsc190501.50486220': __('identifierSchemeCodeUnspsc190501.50486220'),
	'identifierSchemeCodeUnspsc190501.50486221': __('identifierSchemeCodeUnspsc190501.50486221'),
	'identifierSchemeCodeUnspsc190501.50486222': __('identifierSchemeCodeUnspsc190501.50486222'),
	'identifierSchemeCodeUnspsc190501.50486223': __('identifierSchemeCodeUnspsc190501.50486223'),
	'identifierSchemeCodeUnspsc190501.50486224': __('identifierSchemeCodeUnspsc190501.50486224'),
	'identifierSchemeCodeUnspsc190501.50486225': __('identifierSchemeCodeUnspsc190501.50486225'),
	'identifierSchemeCodeUnspsc190501.50486226': __('identifierSchemeCodeUnspsc190501.50486226'),
	'identifierSchemeCodeUnspsc190501.50486227': __('identifierSchemeCodeUnspsc190501.50486227'),
	'identifierSchemeCodeUnspsc190501.50486228': __('identifierSchemeCodeUnspsc190501.50486228'),
	'identifierSchemeCodeUnspsc190501.50486229': __('identifierSchemeCodeUnspsc190501.50486229'),
	'identifierSchemeCodeUnspsc190501.50486300': __('identifierSchemeCodeUnspsc190501.50486300'),
	'identifierSchemeCodeUnspsc190501.50486301': __('identifierSchemeCodeUnspsc190501.50486301'),
	'identifierSchemeCodeUnspsc190501.50486302': __('identifierSchemeCodeUnspsc190501.50486302'),
	'identifierSchemeCodeUnspsc190501.50486303': __('identifierSchemeCodeUnspsc190501.50486303'),
	'identifierSchemeCodeUnspsc190501.50486304': __('identifierSchemeCodeUnspsc190501.50486304'),
	'identifierSchemeCodeUnspsc190501.50486305': __('identifierSchemeCodeUnspsc190501.50486305'),
	'identifierSchemeCodeUnspsc190501.50486306': __('identifierSchemeCodeUnspsc190501.50486306'),
	'identifierSchemeCodeUnspsc190501.50486307': __('identifierSchemeCodeUnspsc190501.50486307'),
	'identifierSchemeCodeUnspsc190501.50486308': __('identifierSchemeCodeUnspsc190501.50486308'),
	'identifierSchemeCodeUnspsc190501.50486309': __('identifierSchemeCodeUnspsc190501.50486309'),
	'identifierSchemeCodeUnspsc190501.50486310': __('identifierSchemeCodeUnspsc190501.50486310'),
	'identifierSchemeCodeUnspsc190501.50486311': __('identifierSchemeCodeUnspsc190501.50486311'),
	'identifierSchemeCodeUnspsc190501.50486312': __('identifierSchemeCodeUnspsc190501.50486312'),
	'identifierSchemeCodeUnspsc190501.50486313': __('identifierSchemeCodeUnspsc190501.50486313'),
	'identifierSchemeCodeUnspsc190501.50486314': __('identifierSchemeCodeUnspsc190501.50486314'),
	'identifierSchemeCodeUnspsc190501.50486315': __('identifierSchemeCodeUnspsc190501.50486315'),
	'identifierSchemeCodeUnspsc190501.50486316': __('identifierSchemeCodeUnspsc190501.50486316'),
	'identifierSchemeCodeUnspsc190501.50486317': __('identifierSchemeCodeUnspsc190501.50486317'),
	'identifierSchemeCodeUnspsc190501.50486318': __('identifierSchemeCodeUnspsc190501.50486318'),
	'identifierSchemeCodeUnspsc190501.50486319': __('identifierSchemeCodeUnspsc190501.50486319'),
	'identifierSchemeCodeUnspsc190501.50486320': __('identifierSchemeCodeUnspsc190501.50486320'),
	'identifierSchemeCodeUnspsc190501.50486321': __('identifierSchemeCodeUnspsc190501.50486321'),
	'identifierSchemeCodeUnspsc190501.50486400': __('identifierSchemeCodeUnspsc190501.50486400'),
	'identifierSchemeCodeUnspsc190501.50486401': __('identifierSchemeCodeUnspsc190501.50486401'),
	'identifierSchemeCodeUnspsc190501.50486402': __('identifierSchemeCodeUnspsc190501.50486402'),
	'identifierSchemeCodeUnspsc190501.50486403': __('identifierSchemeCodeUnspsc190501.50486403'),
	'identifierSchemeCodeUnspsc190501.50486404': __('identifierSchemeCodeUnspsc190501.50486404'),
	'identifierSchemeCodeUnspsc190501.50486405': __('identifierSchemeCodeUnspsc190501.50486405'),
	'identifierSchemeCodeUnspsc190501.50486406': __('identifierSchemeCodeUnspsc190501.50486406'),
	'identifierSchemeCodeUnspsc190501.50486407': __('identifierSchemeCodeUnspsc190501.50486407'),
	'identifierSchemeCodeUnspsc190501.50486408': __('identifierSchemeCodeUnspsc190501.50486408'),
	'identifierSchemeCodeUnspsc190501.50486409': __('identifierSchemeCodeUnspsc190501.50486409'),
	'identifierSchemeCodeUnspsc190501.50486410': __('identifierSchemeCodeUnspsc190501.50486410'),
	'identifierSchemeCodeUnspsc190501.50486411': __('identifierSchemeCodeUnspsc190501.50486411'),
	'identifierSchemeCodeUnspsc190501.50486412': __('identifierSchemeCodeUnspsc190501.50486412'),
	'identifierSchemeCodeUnspsc190501.50486413': __('identifierSchemeCodeUnspsc190501.50486413'),
	'identifierSchemeCodeUnspsc190501.50486414': __('identifierSchemeCodeUnspsc190501.50486414'),
	'identifierSchemeCodeUnspsc190501.50486415': __('identifierSchemeCodeUnspsc190501.50486415'),
	'identifierSchemeCodeUnspsc190501.50486416': __('identifierSchemeCodeUnspsc190501.50486416'),
	'identifierSchemeCodeUnspsc190501.50486417': __('identifierSchemeCodeUnspsc190501.50486417'),
	'identifierSchemeCodeUnspsc190501.50486418': __('identifierSchemeCodeUnspsc190501.50486418'),
	'identifierSchemeCodeUnspsc190501.50486419': __('identifierSchemeCodeUnspsc190501.50486419'),
	'identifierSchemeCodeUnspsc190501.50486420': __('identifierSchemeCodeUnspsc190501.50486420'),
	'identifierSchemeCodeUnspsc190501.50486421': __('identifierSchemeCodeUnspsc190501.50486421'),
	'identifierSchemeCodeUnspsc190501.50486422': __('identifierSchemeCodeUnspsc190501.50486422'),
	'identifierSchemeCodeUnspsc190501.50486423': __('identifierSchemeCodeUnspsc190501.50486423'),
	'identifierSchemeCodeUnspsc190501.50486424': __('identifierSchemeCodeUnspsc190501.50486424'),
	'identifierSchemeCodeUnspsc190501.50486425': __('identifierSchemeCodeUnspsc190501.50486425'),
	'identifierSchemeCodeUnspsc190501.50486426': __('identifierSchemeCodeUnspsc190501.50486426'),
	'identifierSchemeCodeUnspsc190501.50486427': __('identifierSchemeCodeUnspsc190501.50486427'),
	'identifierSchemeCodeUnspsc190501.50486500': __('identifierSchemeCodeUnspsc190501.50486500'),
	'identifierSchemeCodeUnspsc190501.50486501': __('identifierSchemeCodeUnspsc190501.50486501'),
	'identifierSchemeCodeUnspsc190501.50486502': __('identifierSchemeCodeUnspsc190501.50486502'),
	'identifierSchemeCodeUnspsc190501.50486503': __('identifierSchemeCodeUnspsc190501.50486503'),
	'identifierSchemeCodeUnspsc190501.50486504': __('identifierSchemeCodeUnspsc190501.50486504'),
	'identifierSchemeCodeUnspsc190501.50486505': __('identifierSchemeCodeUnspsc190501.50486505'),
	'identifierSchemeCodeUnspsc190501.50486506': __('identifierSchemeCodeUnspsc190501.50486506'),
	'identifierSchemeCodeUnspsc190501.50486507': __('identifierSchemeCodeUnspsc190501.50486507'),
	'identifierSchemeCodeUnspsc190501.50486508': __('identifierSchemeCodeUnspsc190501.50486508'),
	'identifierSchemeCodeUnspsc190501.50486600': __('identifierSchemeCodeUnspsc190501.50486600'),
	'identifierSchemeCodeUnspsc190501.50486601': __('identifierSchemeCodeUnspsc190501.50486601'),
	'identifierSchemeCodeUnspsc190501.50486602': __('identifierSchemeCodeUnspsc190501.50486602'),
	'identifierSchemeCodeUnspsc190501.50486603': __('identifierSchemeCodeUnspsc190501.50486603'),
	'identifierSchemeCodeUnspsc190501.50486604': __('identifierSchemeCodeUnspsc190501.50486604'),
	'identifierSchemeCodeUnspsc190501.50486605': __('identifierSchemeCodeUnspsc190501.50486605'),
	'identifierSchemeCodeUnspsc190501.50486606': __('identifierSchemeCodeUnspsc190501.50486606'),
	'identifierSchemeCodeUnspsc190501.50486607': __('identifierSchemeCodeUnspsc190501.50486607'),
	'identifierSchemeCodeUnspsc190501.50486608': __('identifierSchemeCodeUnspsc190501.50486608'),
	'identifierSchemeCodeUnspsc190501.50486609': __('identifierSchemeCodeUnspsc190501.50486609'),
	'identifierSchemeCodeUnspsc190501.50486610': __('identifierSchemeCodeUnspsc190501.50486610'),
	'identifierSchemeCodeUnspsc190501.50486611': __('identifierSchemeCodeUnspsc190501.50486611'),
	'identifierSchemeCodeUnspsc190501.50486612': __('identifierSchemeCodeUnspsc190501.50486612'),
	'identifierSchemeCodeUnspsc190501.50486613': __('identifierSchemeCodeUnspsc190501.50486613'),
	'identifierSchemeCodeUnspsc190501.50486614': __('identifierSchemeCodeUnspsc190501.50486614'),
	'identifierSchemeCodeUnspsc190501.50486615': __('identifierSchemeCodeUnspsc190501.50486615'),
	'identifierSchemeCodeUnspsc190501.50486616': __('identifierSchemeCodeUnspsc190501.50486616'),
	'identifierSchemeCodeUnspsc190501.50486617': __('identifierSchemeCodeUnspsc190501.50486617'),
	'identifierSchemeCodeUnspsc190501.50486618': __('identifierSchemeCodeUnspsc190501.50486618'),
	'identifierSchemeCodeUnspsc190501.50486619': __('identifierSchemeCodeUnspsc190501.50486619'),
	'identifierSchemeCodeUnspsc190501.50486620': __('identifierSchemeCodeUnspsc190501.50486620'),
	'identifierSchemeCodeUnspsc190501.50486621': __('identifierSchemeCodeUnspsc190501.50486621'),
	'identifierSchemeCodeUnspsc190501.50486622': __('identifierSchemeCodeUnspsc190501.50486622'),
	'identifierSchemeCodeUnspsc190501.50486623': __('identifierSchemeCodeUnspsc190501.50486623'),
	'identifierSchemeCodeUnspsc190501.50486700': __('identifierSchemeCodeUnspsc190501.50486700'),
	'identifierSchemeCodeUnspsc190501.50486701': __('identifierSchemeCodeUnspsc190501.50486701'),
	'identifierSchemeCodeUnspsc190501.50486702': __('identifierSchemeCodeUnspsc190501.50486702'),
	'identifierSchemeCodeUnspsc190501.50486703': __('identifierSchemeCodeUnspsc190501.50486703'),
	'identifierSchemeCodeUnspsc190501.50486704': __('identifierSchemeCodeUnspsc190501.50486704'),
	'identifierSchemeCodeUnspsc190501.50486705': __('identifierSchemeCodeUnspsc190501.50486705'),
	'identifierSchemeCodeUnspsc190501.50486706': __('identifierSchemeCodeUnspsc190501.50486706'),
	'identifierSchemeCodeUnspsc190501.50486707': __('identifierSchemeCodeUnspsc190501.50486707'),
	'identifierSchemeCodeUnspsc190501.50486708': __('identifierSchemeCodeUnspsc190501.50486708'),
	'identifierSchemeCodeUnspsc190501.50486709': __('identifierSchemeCodeUnspsc190501.50486709'),
	'identifierSchemeCodeUnspsc190501.50486710': __('identifierSchemeCodeUnspsc190501.50486710'),
	'identifierSchemeCodeUnspsc190501.50486800': __('identifierSchemeCodeUnspsc190501.50486800'),
	'identifierSchemeCodeUnspsc190501.50486801': __('identifierSchemeCodeUnspsc190501.50486801'),
	'identifierSchemeCodeUnspsc190501.50486802': __('identifierSchemeCodeUnspsc190501.50486802'),
	'identifierSchemeCodeUnspsc190501.50486803': __('identifierSchemeCodeUnspsc190501.50486803'),
	'identifierSchemeCodeUnspsc190501.50486804': __('identifierSchemeCodeUnspsc190501.50486804'),
	'identifierSchemeCodeUnspsc190501.50486805': __('identifierSchemeCodeUnspsc190501.50486805'),
	'identifierSchemeCodeUnspsc190501.50486806': __('identifierSchemeCodeUnspsc190501.50486806'),
	'identifierSchemeCodeUnspsc190501.50486807': __('identifierSchemeCodeUnspsc190501.50486807'),
	'identifierSchemeCodeUnspsc190501.50486808': __('identifierSchemeCodeUnspsc190501.50486808'),
	'identifierSchemeCodeUnspsc190501.50486809': __('identifierSchemeCodeUnspsc190501.50486809'),
	'identifierSchemeCodeUnspsc190501.50486810': __('identifierSchemeCodeUnspsc190501.50486810'),
	'identifierSchemeCodeUnspsc190501.50486811': __('identifierSchemeCodeUnspsc190501.50486811'),
	'identifierSchemeCodeUnspsc190501.50486812': __('identifierSchemeCodeUnspsc190501.50486812'),
	'identifierSchemeCodeUnspsc190501.50486813': __('identifierSchemeCodeUnspsc190501.50486813'),
	'identifierSchemeCodeUnspsc190501.50486814': __('identifierSchemeCodeUnspsc190501.50486814'),
	'identifierSchemeCodeUnspsc190501.50486815': __('identifierSchemeCodeUnspsc190501.50486815'),
	'identifierSchemeCodeUnspsc190501.50486816': __('identifierSchemeCodeUnspsc190501.50486816'),
	'identifierSchemeCodeUnspsc190501.50486817': __('identifierSchemeCodeUnspsc190501.50486817'),
	'identifierSchemeCodeUnspsc190501.50486818': __('identifierSchemeCodeUnspsc190501.50486818'),
	'identifierSchemeCodeUnspsc190501.50486819': __('identifierSchemeCodeUnspsc190501.50486819'),
	'identifierSchemeCodeUnspsc190501.50486820': __('identifierSchemeCodeUnspsc190501.50486820'),
	'identifierSchemeCodeUnspsc190501.50486821': __('identifierSchemeCodeUnspsc190501.50486821'),
	'identifierSchemeCodeUnspsc190501.50486822': __('identifierSchemeCodeUnspsc190501.50486822'),
	'identifierSchemeCodeUnspsc190501.50486823': __('identifierSchemeCodeUnspsc190501.50486823'),
	'identifierSchemeCodeUnspsc190501.50486824': __('identifierSchemeCodeUnspsc190501.50486824'),
	'identifierSchemeCodeUnspsc190501.50486825': __('identifierSchemeCodeUnspsc190501.50486825'),
	'identifierSchemeCodeUnspsc190501.50486826': __('identifierSchemeCodeUnspsc190501.50486826'),
	'identifierSchemeCodeUnspsc190501.50486827': __('identifierSchemeCodeUnspsc190501.50486827'),
	'identifierSchemeCodeUnspsc190501.50486828': __('identifierSchemeCodeUnspsc190501.50486828'),
	'identifierSchemeCodeUnspsc190501.50486829': __('identifierSchemeCodeUnspsc190501.50486829'),
	'identifierSchemeCodeUnspsc190501.50486830': __('identifierSchemeCodeUnspsc190501.50486830'),
	'identifierSchemeCodeUnspsc190501.50486831': __('identifierSchemeCodeUnspsc190501.50486831'),
	'identifierSchemeCodeUnspsc190501.50486832': __('identifierSchemeCodeUnspsc190501.50486832'),
	'identifierSchemeCodeUnspsc190501.50486833': __('identifierSchemeCodeUnspsc190501.50486833'),
	'identifierSchemeCodeUnspsc190501.50486834': __('identifierSchemeCodeUnspsc190501.50486834'),
	'identifierSchemeCodeUnspsc190501.50486835': __('identifierSchemeCodeUnspsc190501.50486835'),
	'identifierSchemeCodeUnspsc190501.50486836': __('identifierSchemeCodeUnspsc190501.50486836'),
	'identifierSchemeCodeUnspsc190501.50486837': __('identifierSchemeCodeUnspsc190501.50486837'),
	'identifierSchemeCodeUnspsc190501.50486838': __('identifierSchemeCodeUnspsc190501.50486838'),
	'identifierSchemeCodeUnspsc190501.50486839': __('identifierSchemeCodeUnspsc190501.50486839'),
	'identifierSchemeCodeUnspsc190501.50486840': __('identifierSchemeCodeUnspsc190501.50486840'),
	'identifierSchemeCodeUnspsc190501.50486841': __('identifierSchemeCodeUnspsc190501.50486841'),
	'identifierSchemeCodeUnspsc190501.50486842': __('identifierSchemeCodeUnspsc190501.50486842'),
	'identifierSchemeCodeUnspsc190501.50486843': __('identifierSchemeCodeUnspsc190501.50486843'),
	'identifierSchemeCodeUnspsc190501.50486844': __('identifierSchemeCodeUnspsc190501.50486844'),
	'identifierSchemeCodeUnspsc190501.50486845': __('identifierSchemeCodeUnspsc190501.50486845'),
	'identifierSchemeCodeUnspsc190501.50486846': __('identifierSchemeCodeUnspsc190501.50486846'),
	'identifierSchemeCodeUnspsc190501.50486847': __('identifierSchemeCodeUnspsc190501.50486847'),
	'identifierSchemeCodeUnspsc190501.50486848': __('identifierSchemeCodeUnspsc190501.50486848'),
	'identifierSchemeCodeUnspsc190501.50486849': __('identifierSchemeCodeUnspsc190501.50486849'),
	'identifierSchemeCodeUnspsc190501.50486850': __('identifierSchemeCodeUnspsc190501.50486850'),
	'identifierSchemeCodeUnspsc190501.50486851': __('identifierSchemeCodeUnspsc190501.50486851'),
	'identifierSchemeCodeUnspsc190501.50486852': __('identifierSchemeCodeUnspsc190501.50486852'),
	'identifierSchemeCodeUnspsc190501.50486853': __('identifierSchemeCodeUnspsc190501.50486853'),
	'identifierSchemeCodeUnspsc190501.50486854': __('identifierSchemeCodeUnspsc190501.50486854'),
	'identifierSchemeCodeUnspsc190501.50486855': __('identifierSchemeCodeUnspsc190501.50486855'),
	'identifierSchemeCodeUnspsc190501.50486856': __('identifierSchemeCodeUnspsc190501.50486856'),
	'identifierSchemeCodeUnspsc190501.50486857': __('identifierSchemeCodeUnspsc190501.50486857'),
	'identifierSchemeCodeUnspsc190501.50486858': __('identifierSchemeCodeUnspsc190501.50486858'),
	'identifierSchemeCodeUnspsc190501.50486859': __('identifierSchemeCodeUnspsc190501.50486859'),
	'identifierSchemeCodeUnspsc190501.50486860': __('identifierSchemeCodeUnspsc190501.50486860'),
	'identifierSchemeCodeUnspsc190501.50486861': __('identifierSchemeCodeUnspsc190501.50486861'),
	'identifierSchemeCodeUnspsc190501.50486862': __('identifierSchemeCodeUnspsc190501.50486862'),
	'identifierSchemeCodeUnspsc190501.50486863': __('identifierSchemeCodeUnspsc190501.50486863'),
	'identifierSchemeCodeUnspsc190501.50486864': __('identifierSchemeCodeUnspsc190501.50486864'),
	'identifierSchemeCodeUnspsc190501.50486865': __('identifierSchemeCodeUnspsc190501.50486865'),
	'identifierSchemeCodeUnspsc190501.50486866': __('identifierSchemeCodeUnspsc190501.50486866'),
	'identifierSchemeCodeUnspsc190501.50486867': __('identifierSchemeCodeUnspsc190501.50486867'),
	'identifierSchemeCodeUnspsc190501.50486868': __('identifierSchemeCodeUnspsc190501.50486868'),
	'identifierSchemeCodeUnspsc190501.50486869': __('identifierSchemeCodeUnspsc190501.50486869'),
	'identifierSchemeCodeUnspsc190501.50486870': __('identifierSchemeCodeUnspsc190501.50486870'),
	'identifierSchemeCodeUnspsc190501.50486871': __('identifierSchemeCodeUnspsc190501.50486871'),
	'identifierSchemeCodeUnspsc190501.50486872': __('identifierSchemeCodeUnspsc190501.50486872'),
	'identifierSchemeCodeUnspsc190501.50486873': __('identifierSchemeCodeUnspsc190501.50486873'),
	'identifierSchemeCodeUnspsc190501.50486900': __('identifierSchemeCodeUnspsc190501.50486900'),
	'identifierSchemeCodeUnspsc190501.50486901': __('identifierSchemeCodeUnspsc190501.50486901'),
	'identifierSchemeCodeUnspsc190501.50486902': __('identifierSchemeCodeUnspsc190501.50486902'),
	'identifierSchemeCodeUnspsc190501.50486903': __('identifierSchemeCodeUnspsc190501.50486903'),
	'identifierSchemeCodeUnspsc190501.50486904': __('identifierSchemeCodeUnspsc190501.50486904'),
	'identifierSchemeCodeUnspsc190501.50486905': __('identifierSchemeCodeUnspsc190501.50486905'),
	'identifierSchemeCodeUnspsc190501.50486906': __('identifierSchemeCodeUnspsc190501.50486906'),
	'identifierSchemeCodeUnspsc190501.50486907': __('identifierSchemeCodeUnspsc190501.50486907'),
	'identifierSchemeCodeUnspsc190501.50486908': __('identifierSchemeCodeUnspsc190501.50486908'),
	'identifierSchemeCodeUnspsc190501.50486909': __('identifierSchemeCodeUnspsc190501.50486909'),
	'identifierSchemeCodeUnspsc190501.50486910': __('identifierSchemeCodeUnspsc190501.50486910'),
	'identifierSchemeCodeUnspsc190501.50487000': __('identifierSchemeCodeUnspsc190501.50487000'),
	'identifierSchemeCodeUnspsc190501.50487001': __('identifierSchemeCodeUnspsc190501.50487001'),
	'identifierSchemeCodeUnspsc190501.50487002': __('identifierSchemeCodeUnspsc190501.50487002'),
	'identifierSchemeCodeUnspsc190501.50487003': __('identifierSchemeCodeUnspsc190501.50487003'),
	'identifierSchemeCodeUnspsc190501.50487004': __('identifierSchemeCodeUnspsc190501.50487004'),
	'identifierSchemeCodeUnspsc190501.50487005': __('identifierSchemeCodeUnspsc190501.50487005'),
	'identifierSchemeCodeUnspsc190501.50487006': __('identifierSchemeCodeUnspsc190501.50487006'),
	'identifierSchemeCodeUnspsc190501.50487007': __('identifierSchemeCodeUnspsc190501.50487007'),
	'identifierSchemeCodeUnspsc190501.50487008': __('identifierSchemeCodeUnspsc190501.50487008'),
	'identifierSchemeCodeUnspsc190501.50490000': __('identifierSchemeCodeUnspsc190501.50490000'),
	'identifierSchemeCodeUnspsc190501.50491500': __('identifierSchemeCodeUnspsc190501.50491500'),
	'identifierSchemeCodeUnspsc190501.50491501': __('identifierSchemeCodeUnspsc190501.50491501'),
	'identifierSchemeCodeUnspsc190501.50491502': __('identifierSchemeCodeUnspsc190501.50491502'),
	'identifierSchemeCodeUnspsc190501.50491503': __('identifierSchemeCodeUnspsc190501.50491503'),
	'identifierSchemeCodeUnspsc190501.50491504': __('identifierSchemeCodeUnspsc190501.50491504'),
	'identifierSchemeCodeUnspsc190501.50491505': __('identifierSchemeCodeUnspsc190501.50491505'),
	'identifierSchemeCodeUnspsc190501.50491506': __('identifierSchemeCodeUnspsc190501.50491506'),
	'identifierSchemeCodeUnspsc190501.50491507': __('identifierSchemeCodeUnspsc190501.50491507'),
	'identifierSchemeCodeUnspsc190501.50491508': __('identifierSchemeCodeUnspsc190501.50491508'),
	'identifierSchemeCodeUnspsc190501.50491509': __('identifierSchemeCodeUnspsc190501.50491509'),
	'identifierSchemeCodeUnspsc190501.50491510': __('identifierSchemeCodeUnspsc190501.50491510'),
	'identifierSchemeCodeUnspsc190501.50491511': __('identifierSchemeCodeUnspsc190501.50491511'),
	'identifierSchemeCodeUnspsc190501.50491512': __('identifierSchemeCodeUnspsc190501.50491512'),
	'identifierSchemeCodeUnspsc190501.50491513': __('identifierSchemeCodeUnspsc190501.50491513'),
	'identifierSchemeCodeUnspsc190501.50491600': __('identifierSchemeCodeUnspsc190501.50491600'),
	'identifierSchemeCodeUnspsc190501.50491601': __('identifierSchemeCodeUnspsc190501.50491601'),
	'identifierSchemeCodeUnspsc190501.50491602': __('identifierSchemeCodeUnspsc190501.50491602'),
	'identifierSchemeCodeUnspsc190501.50491603': __('identifierSchemeCodeUnspsc190501.50491603'),
	'identifierSchemeCodeUnspsc190501.50491604': __('identifierSchemeCodeUnspsc190501.50491604'),
	'identifierSchemeCodeUnspsc190501.50491605': __('identifierSchemeCodeUnspsc190501.50491605'),
	'identifierSchemeCodeUnspsc190501.50491700': __('identifierSchemeCodeUnspsc190501.50491700'),
	'identifierSchemeCodeUnspsc190501.50491701': __('identifierSchemeCodeUnspsc190501.50491701'),
	'identifierSchemeCodeUnspsc190501.50491702': __('identifierSchemeCodeUnspsc190501.50491702'),
	'identifierSchemeCodeUnspsc190501.50491703': __('identifierSchemeCodeUnspsc190501.50491703'),
	'identifierSchemeCodeUnspsc190501.50491704': __('identifierSchemeCodeUnspsc190501.50491704'),
	'identifierSchemeCodeUnspsc190501.50491705': __('identifierSchemeCodeUnspsc190501.50491705'),
	'identifierSchemeCodeUnspsc190501.50491706': __('identifierSchemeCodeUnspsc190501.50491706'),
	'identifierSchemeCodeUnspsc190501.50491707': __('identifierSchemeCodeUnspsc190501.50491707'),
	'identifierSchemeCodeUnspsc190501.50491708': __('identifierSchemeCodeUnspsc190501.50491708'),
	'identifierSchemeCodeUnspsc190501.50491709': __('identifierSchemeCodeUnspsc190501.50491709'),
	'identifierSchemeCodeUnspsc190501.50491710': __('identifierSchemeCodeUnspsc190501.50491710'),
	'identifierSchemeCodeUnspsc190501.50491711': __('identifierSchemeCodeUnspsc190501.50491711'),
	'identifierSchemeCodeUnspsc190501.50491712': __('identifierSchemeCodeUnspsc190501.50491712'),
	'identifierSchemeCodeUnspsc190501.50491713': __('identifierSchemeCodeUnspsc190501.50491713'),
	'identifierSchemeCodeUnspsc190501.50491714': __('identifierSchemeCodeUnspsc190501.50491714'),
	'identifierSchemeCodeUnspsc190501.50491715': __('identifierSchemeCodeUnspsc190501.50491715'),
	'identifierSchemeCodeUnspsc190501.50491716': __('identifierSchemeCodeUnspsc190501.50491716'),
	'identifierSchemeCodeUnspsc190501.50491717': __('identifierSchemeCodeUnspsc190501.50491717'),
	'identifierSchemeCodeUnspsc190501.50491718': __('identifierSchemeCodeUnspsc190501.50491718'),
	'identifierSchemeCodeUnspsc190501.50491719': __('identifierSchemeCodeUnspsc190501.50491719'),
	'identifierSchemeCodeUnspsc190501.50491720': __('identifierSchemeCodeUnspsc190501.50491720'),
	'identifierSchemeCodeUnspsc190501.50491721': __('identifierSchemeCodeUnspsc190501.50491721'),
	'identifierSchemeCodeUnspsc190501.50491722': __('identifierSchemeCodeUnspsc190501.50491722'),
	'identifierSchemeCodeUnspsc190501.50491723': __('identifierSchemeCodeUnspsc190501.50491723'),
	'identifierSchemeCodeUnspsc190501.50491724': __('identifierSchemeCodeUnspsc190501.50491724'),
	'identifierSchemeCodeUnspsc190501.50491725': __('identifierSchemeCodeUnspsc190501.50491725'),
	'identifierSchemeCodeUnspsc190501.50491726': __('identifierSchemeCodeUnspsc190501.50491726'),
	'identifierSchemeCodeUnspsc190501.50491727': __('identifierSchemeCodeUnspsc190501.50491727'),
	'identifierSchemeCodeUnspsc190501.50491728': __('identifierSchemeCodeUnspsc190501.50491728'),
	'identifierSchemeCodeUnspsc190501.50491729': __('identifierSchemeCodeUnspsc190501.50491729'),
	'identifierSchemeCodeUnspsc190501.50491730': __('identifierSchemeCodeUnspsc190501.50491730'),
	'identifierSchemeCodeUnspsc190501.50491731': __('identifierSchemeCodeUnspsc190501.50491731'),
	'identifierSchemeCodeUnspsc190501.50491732': __('identifierSchemeCodeUnspsc190501.50491732'),
	'identifierSchemeCodeUnspsc190501.50491733': __('identifierSchemeCodeUnspsc190501.50491733'),
	'identifierSchemeCodeUnspsc190501.50491734': __('identifierSchemeCodeUnspsc190501.50491734'),
	'identifierSchemeCodeUnspsc190501.50491735': __('identifierSchemeCodeUnspsc190501.50491735'),
	'identifierSchemeCodeUnspsc190501.50491736': __('identifierSchemeCodeUnspsc190501.50491736'),
	'identifierSchemeCodeUnspsc190501.50491737': __('identifierSchemeCodeUnspsc190501.50491737'),
	'identifierSchemeCodeUnspsc190501.50491738': __('identifierSchemeCodeUnspsc190501.50491738'),
	'identifierSchemeCodeUnspsc190501.50491739': __('identifierSchemeCodeUnspsc190501.50491739'),
	'identifierSchemeCodeUnspsc190501.50491740': __('identifierSchemeCodeUnspsc190501.50491740'),
	'identifierSchemeCodeUnspsc190501.50491741': __('identifierSchemeCodeUnspsc190501.50491741'),
	'identifierSchemeCodeUnspsc190501.50491742': __('identifierSchemeCodeUnspsc190501.50491742'),
	'identifierSchemeCodeUnspsc190501.50491743': __('identifierSchemeCodeUnspsc190501.50491743'),
	'identifierSchemeCodeUnspsc190501.50491744': __('identifierSchemeCodeUnspsc190501.50491744'),
	'identifierSchemeCodeUnspsc190501.50491745': __('identifierSchemeCodeUnspsc190501.50491745'),
	'identifierSchemeCodeUnspsc190501.50491746': __('identifierSchemeCodeUnspsc190501.50491746'),
	'identifierSchemeCodeUnspsc190501.50491747': __('identifierSchemeCodeUnspsc190501.50491747'),
	'identifierSchemeCodeUnspsc190501.50491748': __('identifierSchemeCodeUnspsc190501.50491748'),
	'identifierSchemeCodeUnspsc190501.50491749': __('identifierSchemeCodeUnspsc190501.50491749'),
	'identifierSchemeCodeUnspsc190501.50491750': __('identifierSchemeCodeUnspsc190501.50491750'),
	'identifierSchemeCodeUnspsc190501.50491751': __('identifierSchemeCodeUnspsc190501.50491751'),
	'identifierSchemeCodeUnspsc190501.50491752': __('identifierSchemeCodeUnspsc190501.50491752'),
	'identifierSchemeCodeUnspsc190501.50491753': __('identifierSchemeCodeUnspsc190501.50491753'),
	'identifierSchemeCodeUnspsc190501.50491754': __('identifierSchemeCodeUnspsc190501.50491754'),
	'identifierSchemeCodeUnspsc190501.50491755': __('identifierSchemeCodeUnspsc190501.50491755'),
	'identifierSchemeCodeUnspsc190501.50491756': __('identifierSchemeCodeUnspsc190501.50491756'),
	'identifierSchemeCodeUnspsc190501.50491757': __('identifierSchemeCodeUnspsc190501.50491757'),
	'identifierSchemeCodeUnspsc190501.50491758': __('identifierSchemeCodeUnspsc190501.50491758'),
	'identifierSchemeCodeUnspsc190501.50491759': __('identifierSchemeCodeUnspsc190501.50491759'),
	'identifierSchemeCodeUnspsc190501.50491760': __('identifierSchemeCodeUnspsc190501.50491760'),
	'identifierSchemeCodeUnspsc190501.50491761': __('identifierSchemeCodeUnspsc190501.50491761'),
	'identifierSchemeCodeUnspsc190501.50491762': __('identifierSchemeCodeUnspsc190501.50491762'),
	'identifierSchemeCodeUnspsc190501.50491763': __('identifierSchemeCodeUnspsc190501.50491763'),
	'identifierSchemeCodeUnspsc190501.50491764': __('identifierSchemeCodeUnspsc190501.50491764'),
	'identifierSchemeCodeUnspsc190501.50491765': __('identifierSchemeCodeUnspsc190501.50491765'),
	'identifierSchemeCodeUnspsc190501.50491766': __('identifierSchemeCodeUnspsc190501.50491766'),
	'identifierSchemeCodeUnspsc190501.50491767': __('identifierSchemeCodeUnspsc190501.50491767'),
	'identifierSchemeCodeUnspsc190501.50491768': __('identifierSchemeCodeUnspsc190501.50491768'),
	'identifierSchemeCodeUnspsc190501.50491769': __('identifierSchemeCodeUnspsc190501.50491769'),
	'identifierSchemeCodeUnspsc190501.50491770': __('identifierSchemeCodeUnspsc190501.50491770'),
	'identifierSchemeCodeUnspsc190501.50491771': __('identifierSchemeCodeUnspsc190501.50491771'),
	'identifierSchemeCodeUnspsc190501.50491772': __('identifierSchemeCodeUnspsc190501.50491772'),
	'identifierSchemeCodeUnspsc190501.50491773': __('identifierSchemeCodeUnspsc190501.50491773'),
	'identifierSchemeCodeUnspsc190501.50491774': __('identifierSchemeCodeUnspsc190501.50491774'),
	'identifierSchemeCodeUnspsc190501.50491775': __('identifierSchemeCodeUnspsc190501.50491775'),
	'identifierSchemeCodeUnspsc190501.50491776': __('identifierSchemeCodeUnspsc190501.50491776'),
	'identifierSchemeCodeUnspsc190501.50491777': __('identifierSchemeCodeUnspsc190501.50491777'),
	'identifierSchemeCodeUnspsc190501.50491778': __('identifierSchemeCodeUnspsc190501.50491778'),
	'identifierSchemeCodeUnspsc190501.50491779': __('identifierSchemeCodeUnspsc190501.50491779'),
	'identifierSchemeCodeUnspsc190501.50491780': __('identifierSchemeCodeUnspsc190501.50491780'),
	'identifierSchemeCodeUnspsc190501.50491781': __('identifierSchemeCodeUnspsc190501.50491781'),
	'identifierSchemeCodeUnspsc190501.50491782': __('identifierSchemeCodeUnspsc190501.50491782'),
	'identifierSchemeCodeUnspsc190501.50491800': __('identifierSchemeCodeUnspsc190501.50491800'),
	'identifierSchemeCodeUnspsc190501.50491801': __('identifierSchemeCodeUnspsc190501.50491801'),
	'identifierSchemeCodeUnspsc190501.50491802': __('identifierSchemeCodeUnspsc190501.50491802'),
	'identifierSchemeCodeUnspsc190501.50491803': __('identifierSchemeCodeUnspsc190501.50491803'),
	'identifierSchemeCodeUnspsc190501.50491804': __('identifierSchemeCodeUnspsc190501.50491804'),
	'identifierSchemeCodeUnspsc190501.50491805': __('identifierSchemeCodeUnspsc190501.50491805'),
	'identifierSchemeCodeUnspsc190501.50491806': __('identifierSchemeCodeUnspsc190501.50491806'),
	'identifierSchemeCodeUnspsc190501.50491807': __('identifierSchemeCodeUnspsc190501.50491807'),
	'identifierSchemeCodeUnspsc190501.50491808': __('identifierSchemeCodeUnspsc190501.50491808'),
	'identifierSchemeCodeUnspsc190501.50491809': __('identifierSchemeCodeUnspsc190501.50491809'),
	'identifierSchemeCodeUnspsc190501.50491810': __('identifierSchemeCodeUnspsc190501.50491810'),
	'identifierSchemeCodeUnspsc190501.50491811': __('identifierSchemeCodeUnspsc190501.50491811'),
	'identifierSchemeCodeUnspsc190501.50491812': __('identifierSchemeCodeUnspsc190501.50491812'),
	'identifierSchemeCodeUnspsc190501.50491813': __('identifierSchemeCodeUnspsc190501.50491813'),
	'identifierSchemeCodeUnspsc190501.50491814': __('identifierSchemeCodeUnspsc190501.50491814'),
	'identifierSchemeCodeUnspsc190501.50491815': __('identifierSchemeCodeUnspsc190501.50491815'),
	'identifierSchemeCodeUnspsc190501.50491816': __('identifierSchemeCodeUnspsc190501.50491816'),
	'identifierSchemeCodeUnspsc190501.50491817': __('identifierSchemeCodeUnspsc190501.50491817'),
	'identifierSchemeCodeUnspsc190501.50491818': __('identifierSchemeCodeUnspsc190501.50491818'),
	'identifierSchemeCodeUnspsc190501.50491819': __('identifierSchemeCodeUnspsc190501.50491819'),
	'identifierSchemeCodeUnspsc190501.50491820': __('identifierSchemeCodeUnspsc190501.50491820'),
	'identifierSchemeCodeUnspsc190501.50491821': __('identifierSchemeCodeUnspsc190501.50491821'),
	'identifierSchemeCodeUnspsc190501.50491822': __('identifierSchemeCodeUnspsc190501.50491822'),
	'identifierSchemeCodeUnspsc190501.50491823': __('identifierSchemeCodeUnspsc190501.50491823'),
	'identifierSchemeCodeUnspsc190501.50491824': __('identifierSchemeCodeUnspsc190501.50491824'),
	'identifierSchemeCodeUnspsc190501.50491825': __('identifierSchemeCodeUnspsc190501.50491825'),
	'identifierSchemeCodeUnspsc190501.50491826': __('identifierSchemeCodeUnspsc190501.50491826'),
	'identifierSchemeCodeUnspsc190501.50491827': __('identifierSchemeCodeUnspsc190501.50491827'),
	'identifierSchemeCodeUnspsc190501.50491828': __('identifierSchemeCodeUnspsc190501.50491828'),
	'identifierSchemeCodeUnspsc190501.50491829': __('identifierSchemeCodeUnspsc190501.50491829'),
	'identifierSchemeCodeUnspsc190501.50491830': __('identifierSchemeCodeUnspsc190501.50491830'),
	'identifierSchemeCodeUnspsc190501.50491831': __('identifierSchemeCodeUnspsc190501.50491831'),
	'identifierSchemeCodeUnspsc190501.50491832': __('identifierSchemeCodeUnspsc190501.50491832'),
	'identifierSchemeCodeUnspsc190501.50491833': __('identifierSchemeCodeUnspsc190501.50491833'),
	'identifierSchemeCodeUnspsc190501.50491834': __('identifierSchemeCodeUnspsc190501.50491834'),
	'identifierSchemeCodeUnspsc190501.50491835': __('identifierSchemeCodeUnspsc190501.50491835'),
	'identifierSchemeCodeUnspsc190501.50491836': __('identifierSchemeCodeUnspsc190501.50491836'),
	'identifierSchemeCodeUnspsc190501.50491837': __('identifierSchemeCodeUnspsc190501.50491837'),
	'identifierSchemeCodeUnspsc190501.50491838': __('identifierSchemeCodeUnspsc190501.50491838'),
	'identifierSchemeCodeUnspsc190501.50491839': __('identifierSchemeCodeUnspsc190501.50491839'),
	'identifierSchemeCodeUnspsc190501.50491840': __('identifierSchemeCodeUnspsc190501.50491840'),
	'identifierSchemeCodeUnspsc190501.50491841': __('identifierSchemeCodeUnspsc190501.50491841'),
	'identifierSchemeCodeUnspsc190501.50491842': __('identifierSchemeCodeUnspsc190501.50491842'),
	'identifierSchemeCodeUnspsc190501.50491843': __('identifierSchemeCodeUnspsc190501.50491843'),
	'identifierSchemeCodeUnspsc190501.50491844': __('identifierSchemeCodeUnspsc190501.50491844'),
	'identifierSchemeCodeUnspsc190501.50491845': __('identifierSchemeCodeUnspsc190501.50491845'),
	'identifierSchemeCodeUnspsc190501.50491846': __('identifierSchemeCodeUnspsc190501.50491846'),
	'identifierSchemeCodeUnspsc190501.50491847': __('identifierSchemeCodeUnspsc190501.50491847'),
	'identifierSchemeCodeUnspsc190501.50491848': __('identifierSchemeCodeUnspsc190501.50491848'),
	'identifierSchemeCodeUnspsc190501.50491849': __('identifierSchemeCodeUnspsc190501.50491849'),
	'identifierSchemeCodeUnspsc190501.50491850': __('identifierSchemeCodeUnspsc190501.50491850'),
	'identifierSchemeCodeUnspsc190501.50491851': __('identifierSchemeCodeUnspsc190501.50491851'),
	'identifierSchemeCodeUnspsc190501.50491852': __('identifierSchemeCodeUnspsc190501.50491852'),
	'identifierSchemeCodeUnspsc190501.50491900': __('identifierSchemeCodeUnspsc190501.50491900'),
	'identifierSchemeCodeUnspsc190501.50491901': __('identifierSchemeCodeUnspsc190501.50491901'),
	'identifierSchemeCodeUnspsc190501.50491902': __('identifierSchemeCodeUnspsc190501.50491902'),
	'identifierSchemeCodeUnspsc190501.50491903': __('identifierSchemeCodeUnspsc190501.50491903'),
	'identifierSchemeCodeUnspsc190501.50491904': __('identifierSchemeCodeUnspsc190501.50491904'),
	'identifierSchemeCodeUnspsc190501.50491905': __('identifierSchemeCodeUnspsc190501.50491905'),
	'identifierSchemeCodeUnspsc190501.50491906': __('identifierSchemeCodeUnspsc190501.50491906'),
	'identifierSchemeCodeUnspsc190501.50491907': __('identifierSchemeCodeUnspsc190501.50491907'),
	'identifierSchemeCodeUnspsc190501.50491908': __('identifierSchemeCodeUnspsc190501.50491908'),
	'identifierSchemeCodeUnspsc190501.50491909': __('identifierSchemeCodeUnspsc190501.50491909'),
	'identifierSchemeCodeUnspsc190501.50491910': __('identifierSchemeCodeUnspsc190501.50491910'),
	'identifierSchemeCodeUnspsc190501.50491911': __('identifierSchemeCodeUnspsc190501.50491911'),
	'identifierSchemeCodeUnspsc190501.50491912': __('identifierSchemeCodeUnspsc190501.50491912'),
	'identifierSchemeCodeUnspsc190501.50491913': __('identifierSchemeCodeUnspsc190501.50491913'),
	'identifierSchemeCodeUnspsc190501.50491914': __('identifierSchemeCodeUnspsc190501.50491914'),
	'identifierSchemeCodeUnspsc190501.50491915': __('identifierSchemeCodeUnspsc190501.50491915'),
	'identifierSchemeCodeUnspsc190501.50491916': __('identifierSchemeCodeUnspsc190501.50491916'),
	'identifierSchemeCodeUnspsc190501.50491917': __('identifierSchemeCodeUnspsc190501.50491917'),
	'identifierSchemeCodeUnspsc190501.50491918': __('identifierSchemeCodeUnspsc190501.50491918'),
	'identifierSchemeCodeUnspsc190501.50491919': __('identifierSchemeCodeUnspsc190501.50491919'),
	'identifierSchemeCodeUnspsc190501.50491920': __('identifierSchemeCodeUnspsc190501.50491920'),
	'identifierSchemeCodeUnspsc190501.50491921': __('identifierSchemeCodeUnspsc190501.50491921'),
	'identifierSchemeCodeUnspsc190501.50491922': __('identifierSchemeCodeUnspsc190501.50491922'),
	'identifierSchemeCodeUnspsc190501.50492000': __('identifierSchemeCodeUnspsc190501.50492000'),
	'identifierSchemeCodeUnspsc190501.50492001': __('identifierSchemeCodeUnspsc190501.50492001'),
	'identifierSchemeCodeUnspsc190501.50492002': __('identifierSchemeCodeUnspsc190501.50492002'),
	'identifierSchemeCodeUnspsc190501.50492003': __('identifierSchemeCodeUnspsc190501.50492003'),
	'identifierSchemeCodeUnspsc190501.50492100': __('identifierSchemeCodeUnspsc190501.50492100'),
	'identifierSchemeCodeUnspsc190501.50492101': __('identifierSchemeCodeUnspsc190501.50492101'),
	'identifierSchemeCodeUnspsc190501.50492102': __('identifierSchemeCodeUnspsc190501.50492102'),
	'identifierSchemeCodeUnspsc190501.50492103': __('identifierSchemeCodeUnspsc190501.50492103'),
	'identifierSchemeCodeUnspsc190501.50492104': __('identifierSchemeCodeUnspsc190501.50492104'),
	'identifierSchemeCodeUnspsc190501.50492105': __('identifierSchemeCodeUnspsc190501.50492105'),
	'identifierSchemeCodeUnspsc190501.50492200': __('identifierSchemeCodeUnspsc190501.50492200'),
	'identifierSchemeCodeUnspsc190501.50492201': __('identifierSchemeCodeUnspsc190501.50492201'),
	'identifierSchemeCodeUnspsc190501.50492202': __('identifierSchemeCodeUnspsc190501.50492202'),
	'identifierSchemeCodeUnspsc190501.50492203': __('identifierSchemeCodeUnspsc190501.50492203'),
	'identifierSchemeCodeUnspsc190501.50492204': __('identifierSchemeCodeUnspsc190501.50492204'),
	'identifierSchemeCodeUnspsc190501.50492205': __('identifierSchemeCodeUnspsc190501.50492205'),
	'identifierSchemeCodeUnspsc190501.50492206': __('identifierSchemeCodeUnspsc190501.50492206'),
	'identifierSchemeCodeUnspsc190501.50492207': __('identifierSchemeCodeUnspsc190501.50492207'),
	'identifierSchemeCodeUnspsc190501.50492208': __('identifierSchemeCodeUnspsc190501.50492208'),
	'identifierSchemeCodeUnspsc190501.50492209': __('identifierSchemeCodeUnspsc190501.50492209'),
	'identifierSchemeCodeUnspsc190501.50492210': __('identifierSchemeCodeUnspsc190501.50492210'),
	'identifierSchemeCodeUnspsc190501.50492211': __('identifierSchemeCodeUnspsc190501.50492211'),
	'identifierSchemeCodeUnspsc190501.50492300': __('identifierSchemeCodeUnspsc190501.50492300'),
	'identifierSchemeCodeUnspsc190501.50492301': __('identifierSchemeCodeUnspsc190501.50492301'),
	'identifierSchemeCodeUnspsc190501.50492302': __('identifierSchemeCodeUnspsc190501.50492302'),
	'identifierSchemeCodeUnspsc190501.50492303': __('identifierSchemeCodeUnspsc190501.50492303'),
	'identifierSchemeCodeUnspsc190501.50492304': __('identifierSchemeCodeUnspsc190501.50492304'),
	'identifierSchemeCodeUnspsc190501.50492400': __('identifierSchemeCodeUnspsc190501.50492400'),
	'identifierSchemeCodeUnspsc190501.50492401': __('identifierSchemeCodeUnspsc190501.50492401'),
	'identifierSchemeCodeUnspsc190501.50492402': __('identifierSchemeCodeUnspsc190501.50492402'),
	'identifierSchemeCodeUnspsc190501.50492500': __('identifierSchemeCodeUnspsc190501.50492500'),
	'identifierSchemeCodeUnspsc190501.50492501': __('identifierSchemeCodeUnspsc190501.50492501'),
	'identifierSchemeCodeUnspsc190501.50492502': __('identifierSchemeCodeUnspsc190501.50492502'),
	'identifierSchemeCodeUnspsc190501.50492503': __('identifierSchemeCodeUnspsc190501.50492503'),
	'identifierSchemeCodeUnspsc190501.50492504': __('identifierSchemeCodeUnspsc190501.50492504'),
	'identifierSchemeCodeUnspsc190501.50492505': __('identifierSchemeCodeUnspsc190501.50492505'),
	'identifierSchemeCodeUnspsc190501.50492506': __('identifierSchemeCodeUnspsc190501.50492506'),
	'identifierSchemeCodeUnspsc190501.50492600': __('identifierSchemeCodeUnspsc190501.50492600'),
	'identifierSchemeCodeUnspsc190501.50492601': __('identifierSchemeCodeUnspsc190501.50492601'),
	'identifierSchemeCodeUnspsc190501.50492602': __('identifierSchemeCodeUnspsc190501.50492602'),
	'identifierSchemeCodeUnspsc190501.50492603': __('identifierSchemeCodeUnspsc190501.50492603'),
	'identifierSchemeCodeUnspsc190501.50492604': __('identifierSchemeCodeUnspsc190501.50492604'),
	'identifierSchemeCodeUnspsc190501.50492605': __('identifierSchemeCodeUnspsc190501.50492605'),
	'identifierSchemeCodeUnspsc190501.50492606': __('identifierSchemeCodeUnspsc190501.50492606'),
	'identifierSchemeCodeUnspsc190501.50492607': __('identifierSchemeCodeUnspsc190501.50492607'),
	'identifierSchemeCodeUnspsc190501.50492608': __('identifierSchemeCodeUnspsc190501.50492608'),
	'identifierSchemeCodeUnspsc190501.50492609': __('identifierSchemeCodeUnspsc190501.50492609'),
	'identifierSchemeCodeUnspsc190501.50492610': __('identifierSchemeCodeUnspsc190501.50492610'),
	'identifierSchemeCodeUnspsc190501.50492611': __('identifierSchemeCodeUnspsc190501.50492611'),
	'identifierSchemeCodeUnspsc190501.50492612': __('identifierSchemeCodeUnspsc190501.50492612'),
	'identifierSchemeCodeUnspsc190501.50492700': __('identifierSchemeCodeUnspsc190501.50492700'),
	'identifierSchemeCodeUnspsc190501.50492701': __('identifierSchemeCodeUnspsc190501.50492701'),
	'identifierSchemeCodeUnspsc190501.50492702': __('identifierSchemeCodeUnspsc190501.50492702'),
	'identifierSchemeCodeUnspsc190501.50492703': __('identifierSchemeCodeUnspsc190501.50492703'),
	'identifierSchemeCodeUnspsc190501.50492704': __('identifierSchemeCodeUnspsc190501.50492704'),
	'identifierSchemeCodeUnspsc190501.50492705': __('identifierSchemeCodeUnspsc190501.50492705'),
	'identifierSchemeCodeUnspsc190501.50492706': __('identifierSchemeCodeUnspsc190501.50492706'),
	'identifierSchemeCodeUnspsc190501.50492707': __('identifierSchemeCodeUnspsc190501.50492707'),
	'identifierSchemeCodeUnspsc190501.50492708': __('identifierSchemeCodeUnspsc190501.50492708'),
	'identifierSchemeCodeUnspsc190501.50492709': __('identifierSchemeCodeUnspsc190501.50492709'),
	'identifierSchemeCodeUnspsc190501.50492710': __('identifierSchemeCodeUnspsc190501.50492710'),
	'identifierSchemeCodeUnspsc190501.50492711': __('identifierSchemeCodeUnspsc190501.50492711'),
	'identifierSchemeCodeUnspsc190501.50492712': __('identifierSchemeCodeUnspsc190501.50492712'),
	'identifierSchemeCodeUnspsc190501.50492713': __('identifierSchemeCodeUnspsc190501.50492713'),
	'identifierSchemeCodeUnspsc190501.50492714': __('identifierSchemeCodeUnspsc190501.50492714'),
	'identifierSchemeCodeUnspsc190501.50492715': __('identifierSchemeCodeUnspsc190501.50492715'),
	'identifierSchemeCodeUnspsc190501.50492800': __('identifierSchemeCodeUnspsc190501.50492800'),
	'identifierSchemeCodeUnspsc190501.50492801': __('identifierSchemeCodeUnspsc190501.50492801'),
	'identifierSchemeCodeUnspsc190501.50492802': __('identifierSchemeCodeUnspsc190501.50492802'),
	'identifierSchemeCodeUnspsc190501.50492803': __('identifierSchemeCodeUnspsc190501.50492803'),
	'identifierSchemeCodeUnspsc190501.50492804': __('identifierSchemeCodeUnspsc190501.50492804'),
	'identifierSchemeCodeUnspsc190501.50492805': __('identifierSchemeCodeUnspsc190501.50492805'),
	'identifierSchemeCodeUnspsc190501.50492806': __('identifierSchemeCodeUnspsc190501.50492806'),
	'identifierSchemeCodeUnspsc190501.50492807': __('identifierSchemeCodeUnspsc190501.50492807'),
	'identifierSchemeCodeUnspsc190501.50492900': __('identifierSchemeCodeUnspsc190501.50492900'),
	'identifierSchemeCodeUnspsc190501.50492901': __('identifierSchemeCodeUnspsc190501.50492901'),
	'identifierSchemeCodeUnspsc190501.50492902': __('identifierSchemeCodeUnspsc190501.50492902'),
	'identifierSchemeCodeUnspsc190501.50492903': __('identifierSchemeCodeUnspsc190501.50492903'),
	'identifierSchemeCodeUnspsc190501.50492904': __('identifierSchemeCodeUnspsc190501.50492904'),
	'identifierSchemeCodeUnspsc190501.50492905': __('identifierSchemeCodeUnspsc190501.50492905'),
	'identifierSchemeCodeUnspsc190501.50492906': __('identifierSchemeCodeUnspsc190501.50492906'),
	'identifierSchemeCodeUnspsc190501.50492907': __('identifierSchemeCodeUnspsc190501.50492907'),
	'identifierSchemeCodeUnspsc190501.50492908': __('identifierSchemeCodeUnspsc190501.50492908'),
	'identifierSchemeCodeUnspsc190501.50492909': __('identifierSchemeCodeUnspsc190501.50492909'),
	'identifierSchemeCodeUnspsc190501.50492910': __('identifierSchemeCodeUnspsc190501.50492910'),
	'identifierSchemeCodeUnspsc190501.50492911': __('identifierSchemeCodeUnspsc190501.50492911'),
	'identifierSchemeCodeUnspsc190501.50493000': __('identifierSchemeCodeUnspsc190501.50493000'),
	'identifierSchemeCodeUnspsc190501.50493001': __('identifierSchemeCodeUnspsc190501.50493001'),
	'identifierSchemeCodeUnspsc190501.50493002': __('identifierSchemeCodeUnspsc190501.50493002'),
	'identifierSchemeCodeUnspsc190501.50493003': __('identifierSchemeCodeUnspsc190501.50493003'),
	'identifierSchemeCodeUnspsc190501.50493004': __('identifierSchemeCodeUnspsc190501.50493004'),
	'identifierSchemeCodeUnspsc190501.50493005': __('identifierSchemeCodeUnspsc190501.50493005'),
	'identifierSchemeCodeUnspsc190501.50493006': __('identifierSchemeCodeUnspsc190501.50493006'),
	'identifierSchemeCodeUnspsc190501.50493007': __('identifierSchemeCodeUnspsc190501.50493007'),
	'identifierSchemeCodeUnspsc190501.50493008': __('identifierSchemeCodeUnspsc190501.50493008'),
	'identifierSchemeCodeUnspsc190501.50493009': __('identifierSchemeCodeUnspsc190501.50493009'),
	'identifierSchemeCodeUnspsc190501.50493010': __('identifierSchemeCodeUnspsc190501.50493010'),
	'identifierSchemeCodeUnspsc190501.50493011': __('identifierSchemeCodeUnspsc190501.50493011'),
	'identifierSchemeCodeUnspsc190501.50493012': __('identifierSchemeCodeUnspsc190501.50493012'),
	'identifierSchemeCodeUnspsc190501.50493013': __('identifierSchemeCodeUnspsc190501.50493013'),
	'identifierSchemeCodeUnspsc190501.50493014': __('identifierSchemeCodeUnspsc190501.50493014'),
	'identifierSchemeCodeUnspsc190501.50493015': __('identifierSchemeCodeUnspsc190501.50493015'),
	'identifierSchemeCodeUnspsc190501.50493016': __('identifierSchemeCodeUnspsc190501.50493016'),
	'identifierSchemeCodeUnspsc190501.50493100': __('identifierSchemeCodeUnspsc190501.50493100'),
	'identifierSchemeCodeUnspsc190501.50493101': __('identifierSchemeCodeUnspsc190501.50493101'),
	'identifierSchemeCodeUnspsc190501.50493102': __('identifierSchemeCodeUnspsc190501.50493102'),
	'identifierSchemeCodeUnspsc190501.50493200': __('identifierSchemeCodeUnspsc190501.50493200'),
	'identifierSchemeCodeUnspsc190501.50493201': __('identifierSchemeCodeUnspsc190501.50493201'),
	'identifierSchemeCodeUnspsc190501.50493202': __('identifierSchemeCodeUnspsc190501.50493202'),
	'identifierSchemeCodeUnspsc190501.50493203': __('identifierSchemeCodeUnspsc190501.50493203'),
	'identifierSchemeCodeUnspsc190501.50493204': __('identifierSchemeCodeUnspsc190501.50493204'),
	'identifierSchemeCodeUnspsc190501.50493300': __('identifierSchemeCodeUnspsc190501.50493300'),
	'identifierSchemeCodeUnspsc190501.50493301': __('identifierSchemeCodeUnspsc190501.50493301'),
	'identifierSchemeCodeUnspsc190501.50493302': __('identifierSchemeCodeUnspsc190501.50493302'),
	'identifierSchemeCodeUnspsc190501.50493303': __('identifierSchemeCodeUnspsc190501.50493303'),
	'identifierSchemeCodeUnspsc190501.50493304': __('identifierSchemeCodeUnspsc190501.50493304'),
	'identifierSchemeCodeUnspsc190501.50493305': __('identifierSchemeCodeUnspsc190501.50493305'),
	'identifierSchemeCodeUnspsc190501.50493306': __('identifierSchemeCodeUnspsc190501.50493306'),
	'identifierSchemeCodeUnspsc190501.50493307': __('identifierSchemeCodeUnspsc190501.50493307'),
	'identifierSchemeCodeUnspsc190501.50493308': __('identifierSchemeCodeUnspsc190501.50493308'),
	'identifierSchemeCodeUnspsc190501.50493309': __('identifierSchemeCodeUnspsc190501.50493309'),
	'identifierSchemeCodeUnspsc190501.50493310': __('identifierSchemeCodeUnspsc190501.50493310'),
	'identifierSchemeCodeUnspsc190501.50493311': __('identifierSchemeCodeUnspsc190501.50493311'),
	'identifierSchemeCodeUnspsc190501.50493312': __('identifierSchemeCodeUnspsc190501.50493312'),
	'identifierSchemeCodeUnspsc190501.50493313': __('identifierSchemeCodeUnspsc190501.50493313'),
	'identifierSchemeCodeUnspsc190501.50493314': __('identifierSchemeCodeUnspsc190501.50493314'),
	'identifierSchemeCodeUnspsc190501.50493315': __('identifierSchemeCodeUnspsc190501.50493315'),
	'identifierSchemeCodeUnspsc190501.50493316': __('identifierSchemeCodeUnspsc190501.50493316'),
	'identifierSchemeCodeUnspsc190501.50493317': __('identifierSchemeCodeUnspsc190501.50493317'),
	'identifierSchemeCodeUnspsc190501.50493318': __('identifierSchemeCodeUnspsc190501.50493318'),
	'identifierSchemeCodeUnspsc190501.50493319': __('identifierSchemeCodeUnspsc190501.50493319'),
	'identifierSchemeCodeUnspsc190501.50493320': __('identifierSchemeCodeUnspsc190501.50493320'),
	'identifierSchemeCodeUnspsc190501.50493321': __('identifierSchemeCodeUnspsc190501.50493321'),
	'identifierSchemeCodeUnspsc190501.50493322': __('identifierSchemeCodeUnspsc190501.50493322'),
	'identifierSchemeCodeUnspsc190501.50493323': __('identifierSchemeCodeUnspsc190501.50493323'),
	'identifierSchemeCodeUnspsc190501.50493400': __('identifierSchemeCodeUnspsc190501.50493400'),
	'identifierSchemeCodeUnspsc190501.50493401': __('identifierSchemeCodeUnspsc190501.50493401'),
	'identifierSchemeCodeUnspsc190501.50493402': __('identifierSchemeCodeUnspsc190501.50493402'),
	'identifierSchemeCodeUnspsc190501.50493403': __('identifierSchemeCodeUnspsc190501.50493403'),
	'identifierSchemeCodeUnspsc190501.50493404': __('identifierSchemeCodeUnspsc190501.50493404'),
	'identifierSchemeCodeUnspsc190501.50493405': __('identifierSchemeCodeUnspsc190501.50493405'),
	'identifierSchemeCodeUnspsc190501.50493406': __('identifierSchemeCodeUnspsc190501.50493406'),
	'identifierSchemeCodeUnspsc190501.50493407': __('identifierSchemeCodeUnspsc190501.50493407'),
	'identifierSchemeCodeUnspsc190501.50493408': __('identifierSchemeCodeUnspsc190501.50493408'),
	'identifierSchemeCodeUnspsc190501.50493409': __('identifierSchemeCodeUnspsc190501.50493409'),
	'identifierSchemeCodeUnspsc190501.50493410': __('identifierSchemeCodeUnspsc190501.50493410'),
	'identifierSchemeCodeUnspsc190501.50493411': __('identifierSchemeCodeUnspsc190501.50493411'),
	'identifierSchemeCodeUnspsc190501.50493412': __('identifierSchemeCodeUnspsc190501.50493412'),
	'identifierSchemeCodeUnspsc190501.50493413': __('identifierSchemeCodeUnspsc190501.50493413'),
	'identifierSchemeCodeUnspsc190501.50493414': __('identifierSchemeCodeUnspsc190501.50493414'),
	'identifierSchemeCodeUnspsc190501.50493415': __('identifierSchemeCodeUnspsc190501.50493415'),
	'identifierSchemeCodeUnspsc190501.50493416': __('identifierSchemeCodeUnspsc190501.50493416'),
	'identifierSchemeCodeUnspsc190501.50493417': __('identifierSchemeCodeUnspsc190501.50493417'),
	'identifierSchemeCodeUnspsc190501.50493418': __('identifierSchemeCodeUnspsc190501.50493418'),
	'identifierSchemeCodeUnspsc190501.50493419': __('identifierSchemeCodeUnspsc190501.50493419'),
	'identifierSchemeCodeUnspsc190501.50493420': __('identifierSchemeCodeUnspsc190501.50493420'),
	'identifierSchemeCodeUnspsc190501.50493500': __('identifierSchemeCodeUnspsc190501.50493500'),
	'identifierSchemeCodeUnspsc190501.50493501': __('identifierSchemeCodeUnspsc190501.50493501'),
	'identifierSchemeCodeUnspsc190501.50493502': __('identifierSchemeCodeUnspsc190501.50493502'),
	'identifierSchemeCodeUnspsc190501.50493503': __('identifierSchemeCodeUnspsc190501.50493503'),
	'identifierSchemeCodeUnspsc190501.50493504': __('identifierSchemeCodeUnspsc190501.50493504'),
	'identifierSchemeCodeUnspsc190501.50493505': __('identifierSchemeCodeUnspsc190501.50493505'),
	'identifierSchemeCodeUnspsc190501.50493506': __('identifierSchemeCodeUnspsc190501.50493506'),
	'identifierSchemeCodeUnspsc190501.50493507': __('identifierSchemeCodeUnspsc190501.50493507'),
	'identifierSchemeCodeUnspsc190501.50493508': __('identifierSchemeCodeUnspsc190501.50493508'),
	'identifierSchemeCodeUnspsc190501.50493509': __('identifierSchemeCodeUnspsc190501.50493509'),
	'identifierSchemeCodeUnspsc190501.50493510': __('identifierSchemeCodeUnspsc190501.50493510'),
	'identifierSchemeCodeUnspsc190501.50493511': __('identifierSchemeCodeUnspsc190501.50493511'),
	'identifierSchemeCodeUnspsc190501.50493512': __('identifierSchemeCodeUnspsc190501.50493512'),
	'identifierSchemeCodeUnspsc190501.50493513': __('identifierSchemeCodeUnspsc190501.50493513'),
	'identifierSchemeCodeUnspsc190501.50493514': __('identifierSchemeCodeUnspsc190501.50493514'),
	'identifierSchemeCodeUnspsc190501.50493600': __('identifierSchemeCodeUnspsc190501.50493600'),
	'identifierSchemeCodeUnspsc190501.50493601': __('identifierSchemeCodeUnspsc190501.50493601'),
	'identifierSchemeCodeUnspsc190501.50493602': __('identifierSchemeCodeUnspsc190501.50493602'),
	'identifierSchemeCodeUnspsc190501.50493603': __('identifierSchemeCodeUnspsc190501.50493603'),
	'identifierSchemeCodeUnspsc190501.50493604': __('identifierSchemeCodeUnspsc190501.50493604'),
	'identifierSchemeCodeUnspsc190501.50493605': __('identifierSchemeCodeUnspsc190501.50493605'),
	'identifierSchemeCodeUnspsc190501.50493606': __('identifierSchemeCodeUnspsc190501.50493606'),
	'identifierSchemeCodeUnspsc190501.50493607': __('identifierSchemeCodeUnspsc190501.50493607'),
	'identifierSchemeCodeUnspsc190501.50493700': __('identifierSchemeCodeUnspsc190501.50493700'),
	'identifierSchemeCodeUnspsc190501.50493701': __('identifierSchemeCodeUnspsc190501.50493701'),
	'identifierSchemeCodeUnspsc190501.50493702': __('identifierSchemeCodeUnspsc190501.50493702'),
	'identifierSchemeCodeUnspsc190501.50493703': __('identifierSchemeCodeUnspsc190501.50493703'),
	'identifierSchemeCodeUnspsc190501.50493704': __('identifierSchemeCodeUnspsc190501.50493704'),
	'identifierSchemeCodeUnspsc190501.50493705': __('identifierSchemeCodeUnspsc190501.50493705'),
	'identifierSchemeCodeUnspsc190501.50493706': __('identifierSchemeCodeUnspsc190501.50493706'),
	'identifierSchemeCodeUnspsc190501.50493707': __('identifierSchemeCodeUnspsc190501.50493707'),
	'identifierSchemeCodeUnspsc190501.50493708': __('identifierSchemeCodeUnspsc190501.50493708'),
	'identifierSchemeCodeUnspsc190501.50493709': __('identifierSchemeCodeUnspsc190501.50493709'),
	'identifierSchemeCodeUnspsc190501.50493710': __('identifierSchemeCodeUnspsc190501.50493710'),
	'identifierSchemeCodeUnspsc190501.50493711': __('identifierSchemeCodeUnspsc190501.50493711'),
	'identifierSchemeCodeUnspsc190501.50493800': __('identifierSchemeCodeUnspsc190501.50493800'),
	'identifierSchemeCodeUnspsc190501.50493801': __('identifierSchemeCodeUnspsc190501.50493801'),
	'identifierSchemeCodeUnspsc190501.50493802': __('identifierSchemeCodeUnspsc190501.50493802'),
	'identifierSchemeCodeUnspsc190501.50493803': __('identifierSchemeCodeUnspsc190501.50493803'),
	'identifierSchemeCodeUnspsc190501.50493804': __('identifierSchemeCodeUnspsc190501.50493804'),
	'identifierSchemeCodeUnspsc190501.50493805': __('identifierSchemeCodeUnspsc190501.50493805'),
	'identifierSchemeCodeUnspsc190501.50493806': __('identifierSchemeCodeUnspsc190501.50493806'),
	'identifierSchemeCodeUnspsc190501.50493807': __('identifierSchemeCodeUnspsc190501.50493807'),
	'identifierSchemeCodeUnspsc190501.50493808': __('identifierSchemeCodeUnspsc190501.50493808'),
	'identifierSchemeCodeUnspsc190501.50493809': __('identifierSchemeCodeUnspsc190501.50493809'),
	'identifierSchemeCodeUnspsc190501.50493810': __('identifierSchemeCodeUnspsc190501.50493810'),
	'identifierSchemeCodeUnspsc190501.50493811': __('identifierSchemeCodeUnspsc190501.50493811'),
	'identifierSchemeCodeUnspsc190501.50493900': __('identifierSchemeCodeUnspsc190501.50493900'),
	'identifierSchemeCodeUnspsc190501.50493901': __('identifierSchemeCodeUnspsc190501.50493901'),
	'identifierSchemeCodeUnspsc190501.50493902': __('identifierSchemeCodeUnspsc190501.50493902'),
	'identifierSchemeCodeUnspsc190501.50493903': __('identifierSchemeCodeUnspsc190501.50493903'),
	'identifierSchemeCodeUnspsc190501.50493904': __('identifierSchemeCodeUnspsc190501.50493904'),
	'identifierSchemeCodeUnspsc190501.50493905': __('identifierSchemeCodeUnspsc190501.50493905'),
	'identifierSchemeCodeUnspsc190501.50494000': __('identifierSchemeCodeUnspsc190501.50494000'),
	'identifierSchemeCodeUnspsc190501.50494001': __('identifierSchemeCodeUnspsc190501.50494001'),
	'identifierSchemeCodeUnspsc190501.50494002': __('identifierSchemeCodeUnspsc190501.50494002'),
	'identifierSchemeCodeUnspsc190501.50494003': __('identifierSchemeCodeUnspsc190501.50494003'),
	'identifierSchemeCodeUnspsc190501.50494004': __('identifierSchemeCodeUnspsc190501.50494004'),
	'identifierSchemeCodeUnspsc190501.50494005': __('identifierSchemeCodeUnspsc190501.50494005'),
	'identifierSchemeCodeUnspsc190501.50494006': __('identifierSchemeCodeUnspsc190501.50494006'),
	'identifierSchemeCodeUnspsc190501.50494007': __('identifierSchemeCodeUnspsc190501.50494007'),
	'identifierSchemeCodeUnspsc190501.50494008': __('identifierSchemeCodeUnspsc190501.50494008'),
	'identifierSchemeCodeUnspsc190501.50494009': __('identifierSchemeCodeUnspsc190501.50494009'),
	'identifierSchemeCodeUnspsc190501.50494010': __('identifierSchemeCodeUnspsc190501.50494010'),
	'identifierSchemeCodeUnspsc190501.50494011': __('identifierSchemeCodeUnspsc190501.50494011'),
	'identifierSchemeCodeUnspsc190501.50494012': __('identifierSchemeCodeUnspsc190501.50494012'),
	'identifierSchemeCodeUnspsc190501.50494013': __('identifierSchemeCodeUnspsc190501.50494013'),
	'identifierSchemeCodeUnspsc190501.50494014': __('identifierSchemeCodeUnspsc190501.50494014'),
	'identifierSchemeCodeUnspsc190501.50494015': __('identifierSchemeCodeUnspsc190501.50494015'),
	'identifierSchemeCodeUnspsc190501.50494016': __('identifierSchemeCodeUnspsc190501.50494016'),
	'identifierSchemeCodeUnspsc190501.50494017': __('identifierSchemeCodeUnspsc190501.50494017'),
	'identifierSchemeCodeUnspsc190501.50494018': __('identifierSchemeCodeUnspsc190501.50494018'),
	'identifierSchemeCodeUnspsc190501.50494019': __('identifierSchemeCodeUnspsc190501.50494019'),
	'identifierSchemeCodeUnspsc190501.50494020': __('identifierSchemeCodeUnspsc190501.50494020'),
	'identifierSchemeCodeUnspsc190501.50494021': __('identifierSchemeCodeUnspsc190501.50494021'),
	'identifierSchemeCodeUnspsc190501.50494022': __('identifierSchemeCodeUnspsc190501.50494022'),
	'identifierSchemeCodeUnspsc190501.50494023': __('identifierSchemeCodeUnspsc190501.50494023'),
	'identifierSchemeCodeUnspsc190501.50494024': __('identifierSchemeCodeUnspsc190501.50494024'),
	'identifierSchemeCodeUnspsc190501.50494025': __('identifierSchemeCodeUnspsc190501.50494025'),
	'identifierSchemeCodeUnspsc190501.50494026': __('identifierSchemeCodeUnspsc190501.50494026'),
	'identifierSchemeCodeUnspsc190501.50494027': __('identifierSchemeCodeUnspsc190501.50494027'),
	'identifierSchemeCodeUnspsc190501.50494100': __('identifierSchemeCodeUnspsc190501.50494100'),
	'identifierSchemeCodeUnspsc190501.50494101': __('identifierSchemeCodeUnspsc190501.50494101'),
	'identifierSchemeCodeUnspsc190501.50494102': __('identifierSchemeCodeUnspsc190501.50494102'),
	'identifierSchemeCodeUnspsc190501.50494200': __('identifierSchemeCodeUnspsc190501.50494200'),
	'identifierSchemeCodeUnspsc190501.50494201': __('identifierSchemeCodeUnspsc190501.50494201'),
	'identifierSchemeCodeUnspsc190501.50494202': __('identifierSchemeCodeUnspsc190501.50494202'),
	'identifierSchemeCodeUnspsc190501.50494203': __('identifierSchemeCodeUnspsc190501.50494203'),
	'identifierSchemeCodeUnspsc190501.50494204': __('identifierSchemeCodeUnspsc190501.50494204'),
	'identifierSchemeCodeUnspsc190501.50494205': __('identifierSchemeCodeUnspsc190501.50494205'),
	'identifierSchemeCodeUnspsc190501.50494206': __('identifierSchemeCodeUnspsc190501.50494206'),
	'identifierSchemeCodeUnspsc190501.50494300': __('identifierSchemeCodeUnspsc190501.50494300'),
	'identifierSchemeCodeUnspsc190501.50494301': __('identifierSchemeCodeUnspsc190501.50494301'),
	'identifierSchemeCodeUnspsc190501.50494302': __('identifierSchemeCodeUnspsc190501.50494302'),
	'identifierSchemeCodeUnspsc190501.50494303': __('identifierSchemeCodeUnspsc190501.50494303'),
	'identifierSchemeCodeUnspsc190501.50494304': __('identifierSchemeCodeUnspsc190501.50494304'),
	'identifierSchemeCodeUnspsc190501.50494305': __('identifierSchemeCodeUnspsc190501.50494305'),
	'identifierSchemeCodeUnspsc190501.50494306': __('identifierSchemeCodeUnspsc190501.50494306'),
	'identifierSchemeCodeUnspsc190501.50494400': __('identifierSchemeCodeUnspsc190501.50494400'),
	'identifierSchemeCodeUnspsc190501.50494401': __('identifierSchemeCodeUnspsc190501.50494401'),
	'identifierSchemeCodeUnspsc190501.50494402': __('identifierSchemeCodeUnspsc190501.50494402'),
	'identifierSchemeCodeUnspsc190501.50494403': __('identifierSchemeCodeUnspsc190501.50494403'),
	'identifierSchemeCodeUnspsc190501.50494404': __('identifierSchemeCodeUnspsc190501.50494404'),
	'identifierSchemeCodeUnspsc190501.50494405': __('identifierSchemeCodeUnspsc190501.50494405'),
	'identifierSchemeCodeUnspsc190501.50494406': __('identifierSchemeCodeUnspsc190501.50494406'),
	'identifierSchemeCodeUnspsc190501.50494407': __('identifierSchemeCodeUnspsc190501.50494407'),
	'identifierSchemeCodeUnspsc190501.50494408': __('identifierSchemeCodeUnspsc190501.50494408'),
	'identifierSchemeCodeUnspsc190501.50494500': __('identifierSchemeCodeUnspsc190501.50494500'),
	'identifierSchemeCodeUnspsc190501.50494501': __('identifierSchemeCodeUnspsc190501.50494501'),
	'identifierSchemeCodeUnspsc190501.50494502': __('identifierSchemeCodeUnspsc190501.50494502'),
	'identifierSchemeCodeUnspsc190501.50494503': __('identifierSchemeCodeUnspsc190501.50494503'),
	'identifierSchemeCodeUnspsc190501.50494504': __('identifierSchemeCodeUnspsc190501.50494504'),
	'identifierSchemeCodeUnspsc190501.50494505': __('identifierSchemeCodeUnspsc190501.50494505'),
	'identifierSchemeCodeUnspsc190501.50494506': __('identifierSchemeCodeUnspsc190501.50494506'),
	'identifierSchemeCodeUnspsc190501.50494507': __('identifierSchemeCodeUnspsc190501.50494507'),
	'identifierSchemeCodeUnspsc190501.50494508': __('identifierSchemeCodeUnspsc190501.50494508'),
	'identifierSchemeCodeUnspsc190501.50494509': __('identifierSchemeCodeUnspsc190501.50494509'),
	'identifierSchemeCodeUnspsc190501.50494510': __('identifierSchemeCodeUnspsc190501.50494510'),
	'identifierSchemeCodeUnspsc190501.50494511': __('identifierSchemeCodeUnspsc190501.50494511'),
	'identifierSchemeCodeUnspsc190501.50494512': __('identifierSchemeCodeUnspsc190501.50494512'),
	'identifierSchemeCodeUnspsc190501.50494513': __('identifierSchemeCodeUnspsc190501.50494513'),
	'identifierSchemeCodeUnspsc190501.50494514': __('identifierSchemeCodeUnspsc190501.50494514'),
	'identifierSchemeCodeUnspsc190501.50494515': __('identifierSchemeCodeUnspsc190501.50494515'),
	'identifierSchemeCodeUnspsc190501.50494516': __('identifierSchemeCodeUnspsc190501.50494516'),
	'identifierSchemeCodeUnspsc190501.50494517': __('identifierSchemeCodeUnspsc190501.50494517'),
	'identifierSchemeCodeUnspsc190501.50494518': __('identifierSchemeCodeUnspsc190501.50494518'),
	'identifierSchemeCodeUnspsc190501.50494519': __('identifierSchemeCodeUnspsc190501.50494519'),
	'identifierSchemeCodeUnspsc190501.50494520': __('identifierSchemeCodeUnspsc190501.50494520'),
	'identifierSchemeCodeUnspsc190501.50494521': __('identifierSchemeCodeUnspsc190501.50494521'),
	'identifierSchemeCodeUnspsc190501.50494522': __('identifierSchemeCodeUnspsc190501.50494522'),
	'identifierSchemeCodeUnspsc190501.50494523': __('identifierSchemeCodeUnspsc190501.50494523'),
	'identifierSchemeCodeUnspsc190501.50494600': __('identifierSchemeCodeUnspsc190501.50494600'),
	'identifierSchemeCodeUnspsc190501.50494601': __('identifierSchemeCodeUnspsc190501.50494601'),
	'identifierSchemeCodeUnspsc190501.50494602': __('identifierSchemeCodeUnspsc190501.50494602'),
	'identifierSchemeCodeUnspsc190501.50494603': __('identifierSchemeCodeUnspsc190501.50494603'),
	'identifierSchemeCodeUnspsc190501.50494604': __('identifierSchemeCodeUnspsc190501.50494604'),
	'identifierSchemeCodeUnspsc190501.50494605': __('identifierSchemeCodeUnspsc190501.50494605'),
	'identifierSchemeCodeUnspsc190501.50494606': __('identifierSchemeCodeUnspsc190501.50494606'),
	'identifierSchemeCodeUnspsc190501.50494700': __('identifierSchemeCodeUnspsc190501.50494700'),
	'identifierSchemeCodeUnspsc190501.50494701': __('identifierSchemeCodeUnspsc190501.50494701'),
	'identifierSchemeCodeUnspsc190501.50494702': __('identifierSchemeCodeUnspsc190501.50494702'),
	'identifierSchemeCodeUnspsc190501.50494703': __('identifierSchemeCodeUnspsc190501.50494703'),
	'identifierSchemeCodeUnspsc190501.50494704': __('identifierSchemeCodeUnspsc190501.50494704'),
	'identifierSchemeCodeUnspsc190501.50494705': __('identifierSchemeCodeUnspsc190501.50494705'),
	'identifierSchemeCodeUnspsc190501.50494706': __('identifierSchemeCodeUnspsc190501.50494706'),
	'identifierSchemeCodeUnspsc190501.50494707': __('identifierSchemeCodeUnspsc190501.50494707'),
	'identifierSchemeCodeUnspsc190501.50494708': __('identifierSchemeCodeUnspsc190501.50494708'),
	'identifierSchemeCodeUnspsc190501.50494709': __('identifierSchemeCodeUnspsc190501.50494709'),
	'identifierSchemeCodeUnspsc190501.50494710': __('identifierSchemeCodeUnspsc190501.50494710'),
	'identifierSchemeCodeUnspsc190501.50494711': __('identifierSchemeCodeUnspsc190501.50494711'),
	'identifierSchemeCodeUnspsc190501.50494712': __('identifierSchemeCodeUnspsc190501.50494712'),
	'identifierSchemeCodeUnspsc190501.50494713': __('identifierSchemeCodeUnspsc190501.50494713'),
	'identifierSchemeCodeUnspsc190501.50494714': __('identifierSchemeCodeUnspsc190501.50494714'),
	'identifierSchemeCodeUnspsc190501.50494715': __('identifierSchemeCodeUnspsc190501.50494715'),
	'identifierSchemeCodeUnspsc190501.50494716': __('identifierSchemeCodeUnspsc190501.50494716'),
	'identifierSchemeCodeUnspsc190501.50494717': __('identifierSchemeCodeUnspsc190501.50494717'),
	'identifierSchemeCodeUnspsc190501.50494718': __('identifierSchemeCodeUnspsc190501.50494718'),
	'identifierSchemeCodeUnspsc190501.50494719': __('identifierSchemeCodeUnspsc190501.50494719'),
	'identifierSchemeCodeUnspsc190501.50494720': __('identifierSchemeCodeUnspsc190501.50494720'),
	'identifierSchemeCodeUnspsc190501.50494721': __('identifierSchemeCodeUnspsc190501.50494721'),
	'identifierSchemeCodeUnspsc190501.50494800': __('identifierSchemeCodeUnspsc190501.50494800'),
	'identifierSchemeCodeUnspsc190501.50494801': __('identifierSchemeCodeUnspsc190501.50494801'),
	'identifierSchemeCodeUnspsc190501.50494802': __('identifierSchemeCodeUnspsc190501.50494802'),
	'identifierSchemeCodeUnspsc190501.50494803': __('identifierSchemeCodeUnspsc190501.50494803'),
	'identifierSchemeCodeUnspsc190501.50494804': __('identifierSchemeCodeUnspsc190501.50494804'),
	'identifierSchemeCodeUnspsc190501.50494805': __('identifierSchemeCodeUnspsc190501.50494805'),
	'identifierSchemeCodeUnspsc190501.50494806': __('identifierSchemeCodeUnspsc190501.50494806'),
	'identifierSchemeCodeUnspsc190501.50494900': __('identifierSchemeCodeUnspsc190501.50494900'),
	'identifierSchemeCodeUnspsc190501.50494901': __('identifierSchemeCodeUnspsc190501.50494901'),
	'identifierSchemeCodeUnspsc190501.50494902': __('identifierSchemeCodeUnspsc190501.50494902'),
	'identifierSchemeCodeUnspsc190501.50494903': __('identifierSchemeCodeUnspsc190501.50494903'),
	'identifierSchemeCodeUnspsc190501.50494904': __('identifierSchemeCodeUnspsc190501.50494904'),
	'identifierSchemeCodeUnspsc190501.50495000': __('identifierSchemeCodeUnspsc190501.50495000'),
	'identifierSchemeCodeUnspsc190501.50495001': __('identifierSchemeCodeUnspsc190501.50495001'),
	'identifierSchemeCodeUnspsc190501.50495002': __('identifierSchemeCodeUnspsc190501.50495002'),
	'identifierSchemeCodeUnspsc190501.50495003': __('identifierSchemeCodeUnspsc190501.50495003'),
	'identifierSchemeCodeUnspsc190501.50495004': __('identifierSchemeCodeUnspsc190501.50495004'),
	'identifierSchemeCodeUnspsc190501.50495005': __('identifierSchemeCodeUnspsc190501.50495005'),
	'identifierSchemeCodeUnspsc190501.50495006': __('identifierSchemeCodeUnspsc190501.50495006'),
	'identifierSchemeCodeUnspsc190501.50495007': __('identifierSchemeCodeUnspsc190501.50495007'),
	'identifierSchemeCodeUnspsc190501.50495100': __('identifierSchemeCodeUnspsc190501.50495100'),
	'identifierSchemeCodeUnspsc190501.50495101': __('identifierSchemeCodeUnspsc190501.50495101'),
	'identifierSchemeCodeUnspsc190501.50495102': __('identifierSchemeCodeUnspsc190501.50495102'),
	'identifierSchemeCodeUnspsc190501.50495103': __('identifierSchemeCodeUnspsc190501.50495103'),
	'identifierSchemeCodeUnspsc190501.50495104': __('identifierSchemeCodeUnspsc190501.50495104'),
	'identifierSchemeCodeUnspsc190501.50495105': __('identifierSchemeCodeUnspsc190501.50495105'),
	'identifierSchemeCodeUnspsc190501.50495106': __('identifierSchemeCodeUnspsc190501.50495106'),
	'identifierSchemeCodeUnspsc190501.50495107': __('identifierSchemeCodeUnspsc190501.50495107'),
	'identifierSchemeCodeUnspsc190501.50495108': __('identifierSchemeCodeUnspsc190501.50495108'),
	'identifierSchemeCodeUnspsc190501.50495109': __('identifierSchemeCodeUnspsc190501.50495109'),
	'identifierSchemeCodeUnspsc190501.50495110': __('identifierSchemeCodeUnspsc190501.50495110'),
	'identifierSchemeCodeUnspsc190501.50495111': __('identifierSchemeCodeUnspsc190501.50495111'),
	'identifierSchemeCodeUnspsc190501.50495112': __('identifierSchemeCodeUnspsc190501.50495112'),
	'identifierSchemeCodeUnspsc190501.50495113': __('identifierSchemeCodeUnspsc190501.50495113'),
	'identifierSchemeCodeUnspsc190501.50495114': __('identifierSchemeCodeUnspsc190501.50495114'),
	'identifierSchemeCodeUnspsc190501.50495115': __('identifierSchemeCodeUnspsc190501.50495115'),
	'identifierSchemeCodeUnspsc190501.50495116': __('identifierSchemeCodeUnspsc190501.50495116'),
	'identifierSchemeCodeUnspsc190501.50495117': __('identifierSchemeCodeUnspsc190501.50495117'),
	'identifierSchemeCodeUnspsc190501.50495118': __('identifierSchemeCodeUnspsc190501.50495118'),
	'identifierSchemeCodeUnspsc190501.50495119': __('identifierSchemeCodeUnspsc190501.50495119'),
	'identifierSchemeCodeUnspsc190501.50495120': __('identifierSchemeCodeUnspsc190501.50495120'),
	'identifierSchemeCodeUnspsc190501.50495121': __('identifierSchemeCodeUnspsc190501.50495121'),
	'identifierSchemeCodeUnspsc190501.50495200': __('identifierSchemeCodeUnspsc190501.50495200'),
	'identifierSchemeCodeUnspsc190501.50495201': __('identifierSchemeCodeUnspsc190501.50495201'),
	'identifierSchemeCodeUnspsc190501.50495202': __('identifierSchemeCodeUnspsc190501.50495202'),
	'identifierSchemeCodeUnspsc190501.50495203': __('identifierSchemeCodeUnspsc190501.50495203'),
	'identifierSchemeCodeUnspsc190501.50495204': __('identifierSchemeCodeUnspsc190501.50495204'),
	'identifierSchemeCodeUnspsc190501.50495205': __('identifierSchemeCodeUnspsc190501.50495205'),
	'identifierSchemeCodeUnspsc190501.50495206': __('identifierSchemeCodeUnspsc190501.50495206'),
	'identifierSchemeCodeUnspsc190501.50495300': __('identifierSchemeCodeUnspsc190501.50495300'),
	'identifierSchemeCodeUnspsc190501.50495301': __('identifierSchemeCodeUnspsc190501.50495301'),
	'identifierSchemeCodeUnspsc190501.50495302': __('identifierSchemeCodeUnspsc190501.50495302'),
	'identifierSchemeCodeUnspsc190501.50495303': __('identifierSchemeCodeUnspsc190501.50495303'),
	'identifierSchemeCodeUnspsc190501.50495304': __('identifierSchemeCodeUnspsc190501.50495304'),
	'identifierSchemeCodeUnspsc190501.50495305': __('identifierSchemeCodeUnspsc190501.50495305'),
	'identifierSchemeCodeUnspsc190501.50495306': __('identifierSchemeCodeUnspsc190501.50495306'),
	'identifierSchemeCodeUnspsc190501.50495400': __('identifierSchemeCodeUnspsc190501.50495400'),
	'identifierSchemeCodeUnspsc190501.50495401': __('identifierSchemeCodeUnspsc190501.50495401'),
	'identifierSchemeCodeUnspsc190501.50495402': __('identifierSchemeCodeUnspsc190501.50495402'),
	'identifierSchemeCodeUnspsc190501.50495403': __('identifierSchemeCodeUnspsc190501.50495403'),
	'identifierSchemeCodeUnspsc190501.50495404': __('identifierSchemeCodeUnspsc190501.50495404'),
	'identifierSchemeCodeUnspsc190501.50495405': __('identifierSchemeCodeUnspsc190501.50495405'),
	'identifierSchemeCodeUnspsc190501.50495406': __('identifierSchemeCodeUnspsc190501.50495406'),
	'identifierSchemeCodeUnspsc190501.50495407': __('identifierSchemeCodeUnspsc190501.50495407'),
	'identifierSchemeCodeUnspsc190501.50495408': __('identifierSchemeCodeUnspsc190501.50495408'),
	'identifierSchemeCodeUnspsc190501.50495409': __('identifierSchemeCodeUnspsc190501.50495409'),
	'identifierSchemeCodeUnspsc190501.50495410': __('identifierSchemeCodeUnspsc190501.50495410'),
	'identifierSchemeCodeUnspsc190501.50495411': __('identifierSchemeCodeUnspsc190501.50495411'),
	'identifierSchemeCodeUnspsc190501.50495412': __('identifierSchemeCodeUnspsc190501.50495412'),
	'identifierSchemeCodeUnspsc190501.50495413': __('identifierSchemeCodeUnspsc190501.50495413'),
	'identifierSchemeCodeUnspsc190501.50495414': __('identifierSchemeCodeUnspsc190501.50495414'),
	'identifierSchemeCodeUnspsc190501.50495415': __('identifierSchemeCodeUnspsc190501.50495415'),
	'identifierSchemeCodeUnspsc190501.50495416': __('identifierSchemeCodeUnspsc190501.50495416'),
	'identifierSchemeCodeUnspsc190501.50495417': __('identifierSchemeCodeUnspsc190501.50495417'),
	'identifierSchemeCodeUnspsc190501.50495418': __('identifierSchemeCodeUnspsc190501.50495418'),
	'identifierSchemeCodeUnspsc190501.50495419': __('identifierSchemeCodeUnspsc190501.50495419'),
	'identifierSchemeCodeUnspsc190501.50495420': __('identifierSchemeCodeUnspsc190501.50495420'),
	'identifierSchemeCodeUnspsc190501.50495421': __('identifierSchemeCodeUnspsc190501.50495421'),
	'identifierSchemeCodeUnspsc190501.50495422': __('identifierSchemeCodeUnspsc190501.50495422'),
	'identifierSchemeCodeUnspsc190501.50495423': __('identifierSchemeCodeUnspsc190501.50495423'),
	'identifierSchemeCodeUnspsc190501.50495424': __('identifierSchemeCodeUnspsc190501.50495424'),
	'identifierSchemeCodeUnspsc190501.50495425': __('identifierSchemeCodeUnspsc190501.50495425'),
	'identifierSchemeCodeUnspsc190501.50495426': __('identifierSchemeCodeUnspsc190501.50495426'),
	'identifierSchemeCodeUnspsc190501.50495427': __('identifierSchemeCodeUnspsc190501.50495427'),
	'identifierSchemeCodeUnspsc190501.50495428': __('identifierSchemeCodeUnspsc190501.50495428'),
	'identifierSchemeCodeUnspsc190501.50495429': __('identifierSchemeCodeUnspsc190501.50495429'),
	'identifierSchemeCodeUnspsc190501.50495430': __('identifierSchemeCodeUnspsc190501.50495430'),
	'identifierSchemeCodeUnspsc190501.50495431': __('identifierSchemeCodeUnspsc190501.50495431'),
	'identifierSchemeCodeUnspsc190501.50495432': __('identifierSchemeCodeUnspsc190501.50495432'),
	'identifierSchemeCodeUnspsc190501.50495433': __('identifierSchemeCodeUnspsc190501.50495433'),
	'identifierSchemeCodeUnspsc190501.50495434': __('identifierSchemeCodeUnspsc190501.50495434'),
	'identifierSchemeCodeUnspsc190501.50495435': __('identifierSchemeCodeUnspsc190501.50495435'),
	'identifierSchemeCodeUnspsc190501.50495436': __('identifierSchemeCodeUnspsc190501.50495436'),
	'identifierSchemeCodeUnspsc190501.50495437': __('identifierSchemeCodeUnspsc190501.50495437'),
	'identifierSchemeCodeUnspsc190501.50495500': __('identifierSchemeCodeUnspsc190501.50495500'),
	'identifierSchemeCodeUnspsc190501.50495501': __('identifierSchemeCodeUnspsc190501.50495501'),
	'identifierSchemeCodeUnspsc190501.50495502': __('identifierSchemeCodeUnspsc190501.50495502'),
	'identifierSchemeCodeUnspsc190501.50495503': __('identifierSchemeCodeUnspsc190501.50495503'),
	'identifierSchemeCodeUnspsc190501.50495504': __('identifierSchemeCodeUnspsc190501.50495504'),
	'identifierSchemeCodeUnspsc190501.50495505': __('identifierSchemeCodeUnspsc190501.50495505'),
	'identifierSchemeCodeUnspsc190501.50495506': __('identifierSchemeCodeUnspsc190501.50495506'),
	'identifierSchemeCodeUnspsc190501.50495507': __('identifierSchemeCodeUnspsc190501.50495507'),
	'identifierSchemeCodeUnspsc190501.50495508': __('identifierSchemeCodeUnspsc190501.50495508'),
	'identifierSchemeCodeUnspsc190501.50495600': __('identifierSchemeCodeUnspsc190501.50495600'),
	'identifierSchemeCodeUnspsc190501.50495601': __('identifierSchemeCodeUnspsc190501.50495601'),
	'identifierSchemeCodeUnspsc190501.50495602': __('identifierSchemeCodeUnspsc190501.50495602'),
	'identifierSchemeCodeUnspsc190501.50495603': __('identifierSchemeCodeUnspsc190501.50495603'),
	'identifierSchemeCodeUnspsc190501.50495604': __('identifierSchemeCodeUnspsc190501.50495604'),
	'identifierSchemeCodeUnspsc190501.50495700': __('identifierSchemeCodeUnspsc190501.50495700'),
	'identifierSchemeCodeUnspsc190501.50495701': __('identifierSchemeCodeUnspsc190501.50495701'),
	'identifierSchemeCodeUnspsc190501.50495702': __('identifierSchemeCodeUnspsc190501.50495702'),
	'identifierSchemeCodeUnspsc190501.50495703': __('identifierSchemeCodeUnspsc190501.50495703'),
	'identifierSchemeCodeUnspsc190501.50495704': __('identifierSchemeCodeUnspsc190501.50495704'),
	'identifierSchemeCodeUnspsc190501.50495705': __('identifierSchemeCodeUnspsc190501.50495705'),
	'identifierSchemeCodeUnspsc190501.50495706': __('identifierSchemeCodeUnspsc190501.50495706'),
	'identifierSchemeCodeUnspsc190501.50495707': __('identifierSchemeCodeUnspsc190501.50495707'),
	'identifierSchemeCodeUnspsc190501.50495708': __('identifierSchemeCodeUnspsc190501.50495708'),
	'identifierSchemeCodeUnspsc190501.50495709': __('identifierSchemeCodeUnspsc190501.50495709'),
	'identifierSchemeCodeUnspsc190501.50495710': __('identifierSchemeCodeUnspsc190501.50495710'),
	'identifierSchemeCodeUnspsc190501.50495711': __('identifierSchemeCodeUnspsc190501.50495711'),
	'identifierSchemeCodeUnspsc190501.50495712': __('identifierSchemeCodeUnspsc190501.50495712'),
	'identifierSchemeCodeUnspsc190501.50495713': __('identifierSchemeCodeUnspsc190501.50495713'),
	'identifierSchemeCodeUnspsc190501.50495714': __('identifierSchemeCodeUnspsc190501.50495714'),
	'identifierSchemeCodeUnspsc190501.50495715': __('identifierSchemeCodeUnspsc190501.50495715'),
	'identifierSchemeCodeUnspsc190501.50495716': __('identifierSchemeCodeUnspsc190501.50495716'),
	'identifierSchemeCodeUnspsc190501.50495800': __('identifierSchemeCodeUnspsc190501.50495800'),
	'identifierSchemeCodeUnspsc190501.50495801': __('identifierSchemeCodeUnspsc190501.50495801'),
	'identifierSchemeCodeUnspsc190501.50495802': __('identifierSchemeCodeUnspsc190501.50495802'),
	'identifierSchemeCodeUnspsc190501.50495803': __('identifierSchemeCodeUnspsc190501.50495803'),
	'identifierSchemeCodeUnspsc190501.50495804': __('identifierSchemeCodeUnspsc190501.50495804'),
	'identifierSchemeCodeUnspsc190501.50495805': __('identifierSchemeCodeUnspsc190501.50495805'),
	'identifierSchemeCodeUnspsc190501.50495806': __('identifierSchemeCodeUnspsc190501.50495806'),
	'identifierSchemeCodeUnspsc190501.50495807': __('identifierSchemeCodeUnspsc190501.50495807'),
	'identifierSchemeCodeUnspsc190501.50495808': __('identifierSchemeCodeUnspsc190501.50495808'),
	'identifierSchemeCodeUnspsc190501.50495809': __('identifierSchemeCodeUnspsc190501.50495809'),
	'identifierSchemeCodeUnspsc190501.50495810': __('identifierSchemeCodeUnspsc190501.50495810'),
	'identifierSchemeCodeUnspsc190501.50495811': __('identifierSchemeCodeUnspsc190501.50495811'),
	'identifierSchemeCodeUnspsc190501.50495900': __('identifierSchemeCodeUnspsc190501.50495900'),
	'identifierSchemeCodeUnspsc190501.50495901': __('identifierSchemeCodeUnspsc190501.50495901'),
	'identifierSchemeCodeUnspsc190501.50495902': __('identifierSchemeCodeUnspsc190501.50495902'),
	'identifierSchemeCodeUnspsc190501.50495903': __('identifierSchemeCodeUnspsc190501.50495903'),
	'identifierSchemeCodeUnspsc190501.50495904': __('identifierSchemeCodeUnspsc190501.50495904'),
	'identifierSchemeCodeUnspsc190501.50496000': __('identifierSchemeCodeUnspsc190501.50496000'),
	'identifierSchemeCodeUnspsc190501.50496001': __('identifierSchemeCodeUnspsc190501.50496001'),
	'identifierSchemeCodeUnspsc190501.50496002': __('identifierSchemeCodeUnspsc190501.50496002'),
	'identifierSchemeCodeUnspsc190501.50496003': __('identifierSchemeCodeUnspsc190501.50496003'),
	'identifierSchemeCodeUnspsc190501.50496004': __('identifierSchemeCodeUnspsc190501.50496004'),
	'identifierSchemeCodeUnspsc190501.50496005': __('identifierSchemeCodeUnspsc190501.50496005'),
	'identifierSchemeCodeUnspsc190501.50496006': __('identifierSchemeCodeUnspsc190501.50496006'),
	'identifierSchemeCodeUnspsc190501.50496007': __('identifierSchemeCodeUnspsc190501.50496007'),
	'identifierSchemeCodeUnspsc190501.50496008': __('identifierSchemeCodeUnspsc190501.50496008'),
	'identifierSchemeCodeUnspsc190501.50496009': __('identifierSchemeCodeUnspsc190501.50496009'),
	'identifierSchemeCodeUnspsc190501.50496010': __('identifierSchemeCodeUnspsc190501.50496010'),
	'identifierSchemeCodeUnspsc190501.50496011': __('identifierSchemeCodeUnspsc190501.50496011'),
	'identifierSchemeCodeUnspsc190501.50496012': __('identifierSchemeCodeUnspsc190501.50496012'),
	'identifierSchemeCodeUnspsc190501.50496013': __('identifierSchemeCodeUnspsc190501.50496013'),
	'identifierSchemeCodeUnspsc190501.50496014': __('identifierSchemeCodeUnspsc190501.50496014'),
	'identifierSchemeCodeUnspsc190501.50496015': __('identifierSchemeCodeUnspsc190501.50496015'),
	'identifierSchemeCodeUnspsc190501.50496100': __('identifierSchemeCodeUnspsc190501.50496100'),
	'identifierSchemeCodeUnspsc190501.50496101': __('identifierSchemeCodeUnspsc190501.50496101'),
	'identifierSchemeCodeUnspsc190501.50496102': __('identifierSchemeCodeUnspsc190501.50496102'),
	'identifierSchemeCodeUnspsc190501.50496103': __('identifierSchemeCodeUnspsc190501.50496103'),
	'identifierSchemeCodeUnspsc190501.50496104': __('identifierSchemeCodeUnspsc190501.50496104'),
	'identifierSchemeCodeUnspsc190501.50496105': __('identifierSchemeCodeUnspsc190501.50496105'),
	'identifierSchemeCodeUnspsc190501.50496106': __('identifierSchemeCodeUnspsc190501.50496106'),
	'identifierSchemeCodeUnspsc190501.50496107': __('identifierSchemeCodeUnspsc190501.50496107'),
	'identifierSchemeCodeUnspsc190501.50496108': __('identifierSchemeCodeUnspsc190501.50496108'),
	'identifierSchemeCodeUnspsc190501.50496109': __('identifierSchemeCodeUnspsc190501.50496109'),
	'identifierSchemeCodeUnspsc190501.50496110': __('identifierSchemeCodeUnspsc190501.50496110'),
	'identifierSchemeCodeUnspsc190501.50496111': __('identifierSchemeCodeUnspsc190501.50496111'),
	'identifierSchemeCodeUnspsc190501.50496112': __('identifierSchemeCodeUnspsc190501.50496112'),
	'identifierSchemeCodeUnspsc190501.50496113': __('identifierSchemeCodeUnspsc190501.50496113'),
	'identifierSchemeCodeUnspsc190501.50496114': __('identifierSchemeCodeUnspsc190501.50496114'),
	'identifierSchemeCodeUnspsc190501.50496115': __('identifierSchemeCodeUnspsc190501.50496115'),
	'identifierSchemeCodeUnspsc190501.50496116': __('identifierSchemeCodeUnspsc190501.50496116'),
	'identifierSchemeCodeUnspsc190501.50496117': __('identifierSchemeCodeUnspsc190501.50496117'),
	'identifierSchemeCodeUnspsc190501.50496118': __('identifierSchemeCodeUnspsc190501.50496118'),
	'identifierSchemeCodeUnspsc190501.50496119': __('identifierSchemeCodeUnspsc190501.50496119'),
	'identifierSchemeCodeUnspsc190501.50496120': __('identifierSchemeCodeUnspsc190501.50496120'),
	'identifierSchemeCodeUnspsc190501.50496121': __('identifierSchemeCodeUnspsc190501.50496121'),
	'identifierSchemeCodeUnspsc190501.50496122': __('identifierSchemeCodeUnspsc190501.50496122'),
	'identifierSchemeCodeUnspsc190501.50496123': __('identifierSchemeCodeUnspsc190501.50496123'),
	'identifierSchemeCodeUnspsc190501.50496124': __('identifierSchemeCodeUnspsc190501.50496124'),
	'identifierSchemeCodeUnspsc190501.50496125': __('identifierSchemeCodeUnspsc190501.50496125'),
	'identifierSchemeCodeUnspsc190501.50496126': __('identifierSchemeCodeUnspsc190501.50496126'),
	'identifierSchemeCodeUnspsc190501.50496127': __('identifierSchemeCodeUnspsc190501.50496127'),
	'identifierSchemeCodeUnspsc190501.50496128': __('identifierSchemeCodeUnspsc190501.50496128'),
	'identifierSchemeCodeUnspsc190501.50496129': __('identifierSchemeCodeUnspsc190501.50496129'),
	'identifierSchemeCodeUnspsc190501.50496200': __('identifierSchemeCodeUnspsc190501.50496200'),
	'identifierSchemeCodeUnspsc190501.50496201': __('identifierSchemeCodeUnspsc190501.50496201'),
	'identifierSchemeCodeUnspsc190501.50496202': __('identifierSchemeCodeUnspsc190501.50496202'),
	'identifierSchemeCodeUnspsc190501.50496203': __('identifierSchemeCodeUnspsc190501.50496203'),
	'identifierSchemeCodeUnspsc190501.50496204': __('identifierSchemeCodeUnspsc190501.50496204'),
	'identifierSchemeCodeUnspsc190501.50496205': __('identifierSchemeCodeUnspsc190501.50496205'),
	'identifierSchemeCodeUnspsc190501.50496206': __('identifierSchemeCodeUnspsc190501.50496206'),
	'identifierSchemeCodeUnspsc190501.50496207': __('identifierSchemeCodeUnspsc190501.50496207'),
	'identifierSchemeCodeUnspsc190501.50496208': __('identifierSchemeCodeUnspsc190501.50496208'),
	'identifierSchemeCodeUnspsc190501.50496209': __('identifierSchemeCodeUnspsc190501.50496209'),
	'identifierSchemeCodeUnspsc190501.50496210': __('identifierSchemeCodeUnspsc190501.50496210'),
	'identifierSchemeCodeUnspsc190501.50496211': __('identifierSchemeCodeUnspsc190501.50496211'),
	'identifierSchemeCodeUnspsc190501.50496212': __('identifierSchemeCodeUnspsc190501.50496212'),
	'identifierSchemeCodeUnspsc190501.50496213': __('identifierSchemeCodeUnspsc190501.50496213'),
	'identifierSchemeCodeUnspsc190501.50496214': __('identifierSchemeCodeUnspsc190501.50496214'),
	'identifierSchemeCodeUnspsc190501.50496215': __('identifierSchemeCodeUnspsc190501.50496215'),
	'identifierSchemeCodeUnspsc190501.50496216': __('identifierSchemeCodeUnspsc190501.50496216'),
	'identifierSchemeCodeUnspsc190501.50496217': __('identifierSchemeCodeUnspsc190501.50496217'),
	'identifierSchemeCodeUnspsc190501.50496218': __('identifierSchemeCodeUnspsc190501.50496218'),
	'identifierSchemeCodeUnspsc190501.50496219': __('identifierSchemeCodeUnspsc190501.50496219'),
	'identifierSchemeCodeUnspsc190501.50496220': __('identifierSchemeCodeUnspsc190501.50496220'),
	'identifierSchemeCodeUnspsc190501.50496300': __('identifierSchemeCodeUnspsc190501.50496300'),
	'identifierSchemeCodeUnspsc190501.50496301': __('identifierSchemeCodeUnspsc190501.50496301'),
	'identifierSchemeCodeUnspsc190501.50496302': __('identifierSchemeCodeUnspsc190501.50496302'),
	'identifierSchemeCodeUnspsc190501.50496303': __('identifierSchemeCodeUnspsc190501.50496303'),
	'identifierSchemeCodeUnspsc190501.50496304': __('identifierSchemeCodeUnspsc190501.50496304'),
	'identifierSchemeCodeUnspsc190501.50496305': __('identifierSchemeCodeUnspsc190501.50496305'),
	'identifierSchemeCodeUnspsc190501.50496306': __('identifierSchemeCodeUnspsc190501.50496306'),
	'identifierSchemeCodeUnspsc190501.50496307': __('identifierSchemeCodeUnspsc190501.50496307'),
	'identifierSchemeCodeUnspsc190501.50496308': __('identifierSchemeCodeUnspsc190501.50496308'),
	'identifierSchemeCodeUnspsc190501.50496309': __('identifierSchemeCodeUnspsc190501.50496309'),
	'identifierSchemeCodeUnspsc190501.50496310': __('identifierSchemeCodeUnspsc190501.50496310'),
	'identifierSchemeCodeUnspsc190501.50496311': __('identifierSchemeCodeUnspsc190501.50496311'),
	'identifierSchemeCodeUnspsc190501.50496312': __('identifierSchemeCodeUnspsc190501.50496312'),
	'identifierSchemeCodeUnspsc190501.50496313': __('identifierSchemeCodeUnspsc190501.50496313'),
	'identifierSchemeCodeUnspsc190501.50496314': __('identifierSchemeCodeUnspsc190501.50496314'),
	'identifierSchemeCodeUnspsc190501.50496315': __('identifierSchemeCodeUnspsc190501.50496315'),
	'identifierSchemeCodeUnspsc190501.50496316': __('identifierSchemeCodeUnspsc190501.50496316'),
	'identifierSchemeCodeUnspsc190501.50496317': __('identifierSchemeCodeUnspsc190501.50496317'),
	'identifierSchemeCodeUnspsc190501.50496318': __('identifierSchemeCodeUnspsc190501.50496318'),
	'identifierSchemeCodeUnspsc190501.50496319': __('identifierSchemeCodeUnspsc190501.50496319'),
	'identifierSchemeCodeUnspsc190501.50496320': __('identifierSchemeCodeUnspsc190501.50496320'),
	'identifierSchemeCodeUnspsc190501.50496321': __('identifierSchemeCodeUnspsc190501.50496321'),
	'identifierSchemeCodeUnspsc190501.50496322': __('identifierSchemeCodeUnspsc190501.50496322'),
	'identifierSchemeCodeUnspsc190501.50496323': __('identifierSchemeCodeUnspsc190501.50496323'),
	'identifierSchemeCodeUnspsc190501.50496324': __('identifierSchemeCodeUnspsc190501.50496324'),
	'identifierSchemeCodeUnspsc190501.50496325': __('identifierSchemeCodeUnspsc190501.50496325'),
	'identifierSchemeCodeUnspsc190501.50496326': __('identifierSchemeCodeUnspsc190501.50496326'),
	'identifierSchemeCodeUnspsc190501.50496327': __('identifierSchemeCodeUnspsc190501.50496327'),
	'identifierSchemeCodeUnspsc190501.50496400': __('identifierSchemeCodeUnspsc190501.50496400'),
	'identifierSchemeCodeUnspsc190501.50496401': __('identifierSchemeCodeUnspsc190501.50496401'),
	'identifierSchemeCodeUnspsc190501.50496402': __('identifierSchemeCodeUnspsc190501.50496402'),
	'identifierSchemeCodeUnspsc190501.50496403': __('identifierSchemeCodeUnspsc190501.50496403'),
	'identifierSchemeCodeUnspsc190501.50496404': __('identifierSchemeCodeUnspsc190501.50496404'),
	'identifierSchemeCodeUnspsc190501.50496405': __('identifierSchemeCodeUnspsc190501.50496405'),
	'identifierSchemeCodeUnspsc190501.50496406': __('identifierSchemeCodeUnspsc190501.50496406'),
	'identifierSchemeCodeUnspsc190501.50496407': __('identifierSchemeCodeUnspsc190501.50496407'),
	'identifierSchemeCodeUnspsc190501.50496408': __('identifierSchemeCodeUnspsc190501.50496408'),
	'identifierSchemeCodeUnspsc190501.50496500': __('identifierSchemeCodeUnspsc190501.50496500'),
	'identifierSchemeCodeUnspsc190501.50496501': __('identifierSchemeCodeUnspsc190501.50496501'),
	'identifierSchemeCodeUnspsc190501.50496502': __('identifierSchemeCodeUnspsc190501.50496502'),
	'identifierSchemeCodeUnspsc190501.50496503': __('identifierSchemeCodeUnspsc190501.50496503'),
	'identifierSchemeCodeUnspsc190501.50496504': __('identifierSchemeCodeUnspsc190501.50496504'),
	'identifierSchemeCodeUnspsc190501.50496505': __('identifierSchemeCodeUnspsc190501.50496505'),
	'identifierSchemeCodeUnspsc190501.50496506': __('identifierSchemeCodeUnspsc190501.50496506'),
	'identifierSchemeCodeUnspsc190501.50496507': __('identifierSchemeCodeUnspsc190501.50496507'),
	'identifierSchemeCodeUnspsc190501.50496508': __('identifierSchemeCodeUnspsc190501.50496508'),
	'identifierSchemeCodeUnspsc190501.50496509': __('identifierSchemeCodeUnspsc190501.50496509'),
	'identifierSchemeCodeUnspsc190501.50496510': __('identifierSchemeCodeUnspsc190501.50496510'),
	'identifierSchemeCodeUnspsc190501.50496511': __('identifierSchemeCodeUnspsc190501.50496511'),
	'identifierSchemeCodeUnspsc190501.50496512': __('identifierSchemeCodeUnspsc190501.50496512'),
	'identifierSchemeCodeUnspsc190501.50496513': __('identifierSchemeCodeUnspsc190501.50496513'),
	'identifierSchemeCodeUnspsc190501.50496514': __('identifierSchemeCodeUnspsc190501.50496514'),
	'identifierSchemeCodeUnspsc190501.50496515': __('identifierSchemeCodeUnspsc190501.50496515'),
	'identifierSchemeCodeUnspsc190501.50496516': __('identifierSchemeCodeUnspsc190501.50496516'),
	'identifierSchemeCodeUnspsc190501.50496517': __('identifierSchemeCodeUnspsc190501.50496517'),
	'identifierSchemeCodeUnspsc190501.50496518': __('identifierSchemeCodeUnspsc190501.50496518'),
	'identifierSchemeCodeUnspsc190501.50496519': __('identifierSchemeCodeUnspsc190501.50496519'),
	'identifierSchemeCodeUnspsc190501.50496520': __('identifierSchemeCodeUnspsc190501.50496520'),
	'identifierSchemeCodeUnspsc190501.50496600': __('identifierSchemeCodeUnspsc190501.50496600'),
	'identifierSchemeCodeUnspsc190501.50496601': __('identifierSchemeCodeUnspsc190501.50496601'),
	'identifierSchemeCodeUnspsc190501.50496602': __('identifierSchemeCodeUnspsc190501.50496602'),
	'identifierSchemeCodeUnspsc190501.50496603': __('identifierSchemeCodeUnspsc190501.50496603'),
	'identifierSchemeCodeUnspsc190501.50496604': __('identifierSchemeCodeUnspsc190501.50496604'),
	'identifierSchemeCodeUnspsc190501.50496605': __('identifierSchemeCodeUnspsc190501.50496605'),
	'identifierSchemeCodeUnspsc190501.50496606': __('identifierSchemeCodeUnspsc190501.50496606'),
	'identifierSchemeCodeUnspsc190501.50496607': __('identifierSchemeCodeUnspsc190501.50496607'),
	'identifierSchemeCodeUnspsc190501.50496608': __('identifierSchemeCodeUnspsc190501.50496608'),
	'identifierSchemeCodeUnspsc190501.50496609': __('identifierSchemeCodeUnspsc190501.50496609'),
	'identifierSchemeCodeUnspsc190501.50496610': __('identifierSchemeCodeUnspsc190501.50496610'),
	'identifierSchemeCodeUnspsc190501.50496700': __('identifierSchemeCodeUnspsc190501.50496700'),
	'identifierSchemeCodeUnspsc190501.50496701': __('identifierSchemeCodeUnspsc190501.50496701'),
	'identifierSchemeCodeUnspsc190501.50496702': __('identifierSchemeCodeUnspsc190501.50496702'),
	'identifierSchemeCodeUnspsc190501.50496703': __('identifierSchemeCodeUnspsc190501.50496703'),
	'identifierSchemeCodeUnspsc190501.50496704': __('identifierSchemeCodeUnspsc190501.50496704'),
	'identifierSchemeCodeUnspsc190501.50496705': __('identifierSchemeCodeUnspsc190501.50496705'),
	'identifierSchemeCodeUnspsc190501.50496706': __('identifierSchemeCodeUnspsc190501.50496706'),
	'identifierSchemeCodeUnspsc190501.50496707': __('identifierSchemeCodeUnspsc190501.50496707'),
	'identifierSchemeCodeUnspsc190501.50496708': __('identifierSchemeCodeUnspsc190501.50496708'),
	'identifierSchemeCodeUnspsc190501.50496709': __('identifierSchemeCodeUnspsc190501.50496709'),
	'identifierSchemeCodeUnspsc190501.50496710': __('identifierSchemeCodeUnspsc190501.50496710'),
	'identifierSchemeCodeUnspsc190501.50496711': __('identifierSchemeCodeUnspsc190501.50496711'),
	'identifierSchemeCodeUnspsc190501.50496712': __('identifierSchemeCodeUnspsc190501.50496712'),
	'identifierSchemeCodeUnspsc190501.50496713': __('identifierSchemeCodeUnspsc190501.50496713'),
	'identifierSchemeCodeUnspsc190501.50496714': __('identifierSchemeCodeUnspsc190501.50496714'),
	'identifierSchemeCodeUnspsc190501.50496715': __('identifierSchemeCodeUnspsc190501.50496715'),
	'identifierSchemeCodeUnspsc190501.50496716': __('identifierSchemeCodeUnspsc190501.50496716'),
	'identifierSchemeCodeUnspsc190501.50496717': __('identifierSchemeCodeUnspsc190501.50496717'),
	'identifierSchemeCodeUnspsc190501.50496718': __('identifierSchemeCodeUnspsc190501.50496718'),
	'identifierSchemeCodeUnspsc190501.50496719': __('identifierSchemeCodeUnspsc190501.50496719'),
	'identifierSchemeCodeUnspsc190501.50496720': __('identifierSchemeCodeUnspsc190501.50496720'),
	'identifierSchemeCodeUnspsc190501.50496721': __('identifierSchemeCodeUnspsc190501.50496721'),
	'identifierSchemeCodeUnspsc190501.50496722': __('identifierSchemeCodeUnspsc190501.50496722'),
	'identifierSchemeCodeUnspsc190501.50496723': __('identifierSchemeCodeUnspsc190501.50496723'),
	'identifierSchemeCodeUnspsc190501.50496724': __('identifierSchemeCodeUnspsc190501.50496724'),
	'identifierSchemeCodeUnspsc190501.50496725': __('identifierSchemeCodeUnspsc190501.50496725'),
	'identifierSchemeCodeUnspsc190501.50496726': __('identifierSchemeCodeUnspsc190501.50496726'),
	'identifierSchemeCodeUnspsc190501.50496727': __('identifierSchemeCodeUnspsc190501.50496727'),
	'identifierSchemeCodeUnspsc190501.50496728': __('identifierSchemeCodeUnspsc190501.50496728'),
	'identifierSchemeCodeUnspsc190501.50496729': __('identifierSchemeCodeUnspsc190501.50496729'),
	'identifierSchemeCodeUnspsc190501.50496730': __('identifierSchemeCodeUnspsc190501.50496730'),
	'identifierSchemeCodeUnspsc190501.50496731': __('identifierSchemeCodeUnspsc190501.50496731'),
	'identifierSchemeCodeUnspsc190501.50496732': __('identifierSchemeCodeUnspsc190501.50496732'),
	'identifierSchemeCodeUnspsc190501.50496733': __('identifierSchemeCodeUnspsc190501.50496733'),
	'identifierSchemeCodeUnspsc190501.50496734': __('identifierSchemeCodeUnspsc190501.50496734'),
	'identifierSchemeCodeUnspsc190501.50496735': __('identifierSchemeCodeUnspsc190501.50496735'),
	'identifierSchemeCodeUnspsc190501.50496736': __('identifierSchemeCodeUnspsc190501.50496736'),
	'identifierSchemeCodeUnspsc190501.50496737': __('identifierSchemeCodeUnspsc190501.50496737'),
	'identifierSchemeCodeUnspsc190501.50496738': __('identifierSchemeCodeUnspsc190501.50496738'),
	'identifierSchemeCodeUnspsc190501.50496739': __('identifierSchemeCodeUnspsc190501.50496739'),
	'identifierSchemeCodeUnspsc190501.50496740': __('identifierSchemeCodeUnspsc190501.50496740'),
	'identifierSchemeCodeUnspsc190501.50496741': __('identifierSchemeCodeUnspsc190501.50496741'),
	'identifierSchemeCodeUnspsc190501.50496742': __('identifierSchemeCodeUnspsc190501.50496742'),
	'identifierSchemeCodeUnspsc190501.50496743': __('identifierSchemeCodeUnspsc190501.50496743'),
	'identifierSchemeCodeUnspsc190501.50496744': __('identifierSchemeCodeUnspsc190501.50496744'),
	'identifierSchemeCodeUnspsc190501.50496745': __('identifierSchemeCodeUnspsc190501.50496745'),
	'identifierSchemeCodeUnspsc190501.50496746': __('identifierSchemeCodeUnspsc190501.50496746'),
	'identifierSchemeCodeUnspsc190501.50496747': __('identifierSchemeCodeUnspsc190501.50496747'),
	'identifierSchemeCodeUnspsc190501.50496748': __('identifierSchemeCodeUnspsc190501.50496748'),
	'identifierSchemeCodeUnspsc190501.50496749': __('identifierSchemeCodeUnspsc190501.50496749'),
	'identifierSchemeCodeUnspsc190501.50496750': __('identifierSchemeCodeUnspsc190501.50496750'),
	'identifierSchemeCodeUnspsc190501.50496751': __('identifierSchemeCodeUnspsc190501.50496751'),
	'identifierSchemeCodeUnspsc190501.50496752': __('identifierSchemeCodeUnspsc190501.50496752'),
	'identifierSchemeCodeUnspsc190501.50496753': __('identifierSchemeCodeUnspsc190501.50496753'),
	'identifierSchemeCodeUnspsc190501.50496754': __('identifierSchemeCodeUnspsc190501.50496754'),
	'identifierSchemeCodeUnspsc190501.50496755': __('identifierSchemeCodeUnspsc190501.50496755'),
	'identifierSchemeCodeUnspsc190501.50496756': __('identifierSchemeCodeUnspsc190501.50496756'),
	'identifierSchemeCodeUnspsc190501.50496757': __('identifierSchemeCodeUnspsc190501.50496757'),
	'identifierSchemeCodeUnspsc190501.50496758': __('identifierSchemeCodeUnspsc190501.50496758'),
	'identifierSchemeCodeUnspsc190501.50496759': __('identifierSchemeCodeUnspsc190501.50496759'),
	'identifierSchemeCodeUnspsc190501.50496760': __('identifierSchemeCodeUnspsc190501.50496760'),
	'identifierSchemeCodeUnspsc190501.50496761': __('identifierSchemeCodeUnspsc190501.50496761'),
	'identifierSchemeCodeUnspsc190501.50496762': __('identifierSchemeCodeUnspsc190501.50496762'),
	'identifierSchemeCodeUnspsc190501.50496763': __('identifierSchemeCodeUnspsc190501.50496763'),
	'identifierSchemeCodeUnspsc190501.50496764': __('identifierSchemeCodeUnspsc190501.50496764'),
	'identifierSchemeCodeUnspsc190501.50496765': __('identifierSchemeCodeUnspsc190501.50496765'),
	'identifierSchemeCodeUnspsc190501.50496766': __('identifierSchemeCodeUnspsc190501.50496766'),
	'identifierSchemeCodeUnspsc190501.50496767': __('identifierSchemeCodeUnspsc190501.50496767'),
	'identifierSchemeCodeUnspsc190501.50496768': __('identifierSchemeCodeUnspsc190501.50496768'),
	'identifierSchemeCodeUnspsc190501.50496769': __('identifierSchemeCodeUnspsc190501.50496769'),
	'identifierSchemeCodeUnspsc190501.50496800': __('identifierSchemeCodeUnspsc190501.50496800'),
	'identifierSchemeCodeUnspsc190501.50496801': __('identifierSchemeCodeUnspsc190501.50496801'),
	'identifierSchemeCodeUnspsc190501.50496802': __('identifierSchemeCodeUnspsc190501.50496802'),
	'identifierSchemeCodeUnspsc190501.50496803': __('identifierSchemeCodeUnspsc190501.50496803'),
	'identifierSchemeCodeUnspsc190501.50496804': __('identifierSchemeCodeUnspsc190501.50496804'),
	'identifierSchemeCodeUnspsc190501.50496805': __('identifierSchemeCodeUnspsc190501.50496805'),
	'identifierSchemeCodeUnspsc190501.50496806': __('identifierSchemeCodeUnspsc190501.50496806'),
	'identifierSchemeCodeUnspsc190501.50496807': __('identifierSchemeCodeUnspsc190501.50496807'),
	'identifierSchemeCodeUnspsc190501.50496808': __('identifierSchemeCodeUnspsc190501.50496808'),
	'identifierSchemeCodeUnspsc190501.50496809': __('identifierSchemeCodeUnspsc190501.50496809'),
	'identifierSchemeCodeUnspsc190501.50496810': __('identifierSchemeCodeUnspsc190501.50496810'),
	'identifierSchemeCodeUnspsc190501.50496811': __('identifierSchemeCodeUnspsc190501.50496811'),
	'identifierSchemeCodeUnspsc190501.50496812': __('identifierSchemeCodeUnspsc190501.50496812'),
	'identifierSchemeCodeUnspsc190501.50496813': __('identifierSchemeCodeUnspsc190501.50496813'),
	'identifierSchemeCodeUnspsc190501.50496814': __('identifierSchemeCodeUnspsc190501.50496814'),
	'identifierSchemeCodeUnspsc190501.50496815': __('identifierSchemeCodeUnspsc190501.50496815'),
	'identifierSchemeCodeUnspsc190501.50496816': __('identifierSchemeCodeUnspsc190501.50496816'),
	'identifierSchemeCodeUnspsc190501.50496817': __('identifierSchemeCodeUnspsc190501.50496817'),
	'identifierSchemeCodeUnspsc190501.50496818': __('identifierSchemeCodeUnspsc190501.50496818'),
	'identifierSchemeCodeUnspsc190501.50496819': __('identifierSchemeCodeUnspsc190501.50496819'),
	'identifierSchemeCodeUnspsc190501.50496820': __('identifierSchemeCodeUnspsc190501.50496820'),
	'identifierSchemeCodeUnspsc190501.50496821': __('identifierSchemeCodeUnspsc190501.50496821'),
	'identifierSchemeCodeUnspsc190501.50496822': __('identifierSchemeCodeUnspsc190501.50496822'),
	'identifierSchemeCodeUnspsc190501.50496823': __('identifierSchemeCodeUnspsc190501.50496823'),
	'identifierSchemeCodeUnspsc190501.50496824': __('identifierSchemeCodeUnspsc190501.50496824'),
	'identifierSchemeCodeUnspsc190501.50496825': __('identifierSchemeCodeUnspsc190501.50496825'),
	'identifierSchemeCodeUnspsc190501.50496826': __('identifierSchemeCodeUnspsc190501.50496826'),
	'identifierSchemeCodeUnspsc190501.50496827': __('identifierSchemeCodeUnspsc190501.50496827'),
	'identifierSchemeCodeUnspsc190501.50496828': __('identifierSchemeCodeUnspsc190501.50496828'),
	'identifierSchemeCodeUnspsc190501.50496829': __('identifierSchemeCodeUnspsc190501.50496829'),
	'identifierSchemeCodeUnspsc190501.50496830': __('identifierSchemeCodeUnspsc190501.50496830'),
	'identifierSchemeCodeUnspsc190501.50496831': __('identifierSchemeCodeUnspsc190501.50496831'),
	'identifierSchemeCodeUnspsc190501.50496832': __('identifierSchemeCodeUnspsc190501.50496832'),
	'identifierSchemeCodeUnspsc190501.50496833': __('identifierSchemeCodeUnspsc190501.50496833'),
	'identifierSchemeCodeUnspsc190501.50496834': __('identifierSchemeCodeUnspsc190501.50496834'),
	'identifierSchemeCodeUnspsc190501.50496835': __('identifierSchemeCodeUnspsc190501.50496835'),
	'identifierSchemeCodeUnspsc190501.50496836': __('identifierSchemeCodeUnspsc190501.50496836'),
	'identifierSchemeCodeUnspsc190501.50496837': __('identifierSchemeCodeUnspsc190501.50496837'),
	'identifierSchemeCodeUnspsc190501.50496838': __('identifierSchemeCodeUnspsc190501.50496838'),
	'identifierSchemeCodeUnspsc190501.50496839': __('identifierSchemeCodeUnspsc190501.50496839'),
	'identifierSchemeCodeUnspsc190501.50496840': __('identifierSchemeCodeUnspsc190501.50496840'),
	'identifierSchemeCodeUnspsc190501.50496841': __('identifierSchemeCodeUnspsc190501.50496841'),
	'identifierSchemeCodeUnspsc190501.50496842': __('identifierSchemeCodeUnspsc190501.50496842'),
	'identifierSchemeCodeUnspsc190501.50496843': __('identifierSchemeCodeUnspsc190501.50496843'),
	'identifierSchemeCodeUnspsc190501.50496844': __('identifierSchemeCodeUnspsc190501.50496844'),
	'identifierSchemeCodeUnspsc190501.50496845': __('identifierSchemeCodeUnspsc190501.50496845'),
	'identifierSchemeCodeUnspsc190501.50496846': __('identifierSchemeCodeUnspsc190501.50496846'),
	'identifierSchemeCodeUnspsc190501.50496847': __('identifierSchemeCodeUnspsc190501.50496847'),
	'identifierSchemeCodeUnspsc190501.50496848': __('identifierSchemeCodeUnspsc190501.50496848'),
	'identifierSchemeCodeUnspsc190501.50496849': __('identifierSchemeCodeUnspsc190501.50496849'),
	'identifierSchemeCodeUnspsc190501.50496850': __('identifierSchemeCodeUnspsc190501.50496850'),
	'identifierSchemeCodeUnspsc190501.50496851': __('identifierSchemeCodeUnspsc190501.50496851'),
	'identifierSchemeCodeUnspsc190501.50496852': __('identifierSchemeCodeUnspsc190501.50496852'),
	'identifierSchemeCodeUnspsc190501.50496853': __('identifierSchemeCodeUnspsc190501.50496853'),
	'identifierSchemeCodeUnspsc190501.50496854': __('identifierSchemeCodeUnspsc190501.50496854'),
	'identifierSchemeCodeUnspsc190501.50496855': __('identifierSchemeCodeUnspsc190501.50496855'),
	'identifierSchemeCodeUnspsc190501.50496856': __('identifierSchemeCodeUnspsc190501.50496856'),
	'identifierSchemeCodeUnspsc190501.50496857': __('identifierSchemeCodeUnspsc190501.50496857'),
	'identifierSchemeCodeUnspsc190501.50496858': __('identifierSchemeCodeUnspsc190501.50496858'),
	'identifierSchemeCodeUnspsc190501.50496859': __('identifierSchemeCodeUnspsc190501.50496859'),
	'identifierSchemeCodeUnspsc190501.50496860': __('identifierSchemeCodeUnspsc190501.50496860'),
	'identifierSchemeCodeUnspsc190501.50496861': __('identifierSchemeCodeUnspsc190501.50496861'),
	'identifierSchemeCodeUnspsc190501.50496862': __('identifierSchemeCodeUnspsc190501.50496862'),
	'identifierSchemeCodeUnspsc190501.50496863': __('identifierSchemeCodeUnspsc190501.50496863'),
	'identifierSchemeCodeUnspsc190501.50496864': __('identifierSchemeCodeUnspsc190501.50496864'),
	'identifierSchemeCodeUnspsc190501.50496865': __('identifierSchemeCodeUnspsc190501.50496865'),
	'identifierSchemeCodeUnspsc190501.50496866': __('identifierSchemeCodeUnspsc190501.50496866'),
	'identifierSchemeCodeUnspsc190501.50496867': __('identifierSchemeCodeUnspsc190501.50496867'),
	'identifierSchemeCodeUnspsc190501.50496868': __('identifierSchemeCodeUnspsc190501.50496868'),
	'identifierSchemeCodeUnspsc190501.50496869': __('identifierSchemeCodeUnspsc190501.50496869'),
	'identifierSchemeCodeUnspsc190501.50496900': __('identifierSchemeCodeUnspsc190501.50496900'),
	'identifierSchemeCodeUnspsc190501.50496901': __('identifierSchemeCodeUnspsc190501.50496901'),
	'identifierSchemeCodeUnspsc190501.50496902': __('identifierSchemeCodeUnspsc190501.50496902'),
	'identifierSchemeCodeUnspsc190501.50496903': __('identifierSchemeCodeUnspsc190501.50496903'),
	'identifierSchemeCodeUnspsc190501.50496904': __('identifierSchemeCodeUnspsc190501.50496904'),
	'identifierSchemeCodeUnspsc190501.50496905': __('identifierSchemeCodeUnspsc190501.50496905'),
	'identifierSchemeCodeUnspsc190501.50496906': __('identifierSchemeCodeUnspsc190501.50496906'),
	'identifierSchemeCodeUnspsc190501.50496907': __('identifierSchemeCodeUnspsc190501.50496907'),
	'identifierSchemeCodeUnspsc190501.50496908': __('identifierSchemeCodeUnspsc190501.50496908'),
	'identifierSchemeCodeUnspsc190501.50496909': __('identifierSchemeCodeUnspsc190501.50496909'),
	'identifierSchemeCodeUnspsc190501.50496910': __('identifierSchemeCodeUnspsc190501.50496910'),
	'identifierSchemeCodeUnspsc190501.50496911': __('identifierSchemeCodeUnspsc190501.50496911'),
	'identifierSchemeCodeUnspsc190501.50496912': __('identifierSchemeCodeUnspsc190501.50496912'),
	'identifierSchemeCodeUnspsc190501.50496913': __('identifierSchemeCodeUnspsc190501.50496913'),
	'identifierSchemeCodeUnspsc190501.50500000': __('identifierSchemeCodeUnspsc190501.50500000'),
	'identifierSchemeCodeUnspsc190501.50501500': __('identifierSchemeCodeUnspsc190501.50501500'),
	'identifierSchemeCodeUnspsc190501.50501501': __('identifierSchemeCodeUnspsc190501.50501501'),
	'identifierSchemeCodeUnspsc190501.50501502': __('identifierSchemeCodeUnspsc190501.50501502'),
	'identifierSchemeCodeUnspsc190501.50501503': __('identifierSchemeCodeUnspsc190501.50501503'),
	'identifierSchemeCodeUnspsc190501.50501504': __('identifierSchemeCodeUnspsc190501.50501504'),
	'identifierSchemeCodeUnspsc190501.50501505': __('identifierSchemeCodeUnspsc190501.50501505'),
	'identifierSchemeCodeUnspsc190501.50501506': __('identifierSchemeCodeUnspsc190501.50501506'),
	'identifierSchemeCodeUnspsc190501.50501507': __('identifierSchemeCodeUnspsc190501.50501507'),
	'identifierSchemeCodeUnspsc190501.50501508': __('identifierSchemeCodeUnspsc190501.50501508'),
	'identifierSchemeCodeUnspsc190501.50501509': __('identifierSchemeCodeUnspsc190501.50501509'),
	'identifierSchemeCodeUnspsc190501.50501510': __('identifierSchemeCodeUnspsc190501.50501510'),
	'identifierSchemeCodeUnspsc190501.50501511': __('identifierSchemeCodeUnspsc190501.50501511'),
	'identifierSchemeCodeUnspsc190501.50501512': __('identifierSchemeCodeUnspsc190501.50501512'),
	'identifierSchemeCodeUnspsc190501.50501513': __('identifierSchemeCodeUnspsc190501.50501513'),
	'identifierSchemeCodeUnspsc190501.50501514': __('identifierSchemeCodeUnspsc190501.50501514'),
	'identifierSchemeCodeUnspsc190501.50501515': __('identifierSchemeCodeUnspsc190501.50501515'),
	'identifierSchemeCodeUnspsc190501.50501516': __('identifierSchemeCodeUnspsc190501.50501516'),
	'identifierSchemeCodeUnspsc190501.50501517': __('identifierSchemeCodeUnspsc190501.50501517'),
	'identifierSchemeCodeUnspsc190501.50501518': __('identifierSchemeCodeUnspsc190501.50501518'),
	'identifierSchemeCodeUnspsc190501.50501519': __('identifierSchemeCodeUnspsc190501.50501519'),
	'identifierSchemeCodeUnspsc190501.50501520': __('identifierSchemeCodeUnspsc190501.50501520'),
	'identifierSchemeCodeUnspsc190501.50501521': __('identifierSchemeCodeUnspsc190501.50501521'),
	'identifierSchemeCodeUnspsc190501.50501522': __('identifierSchemeCodeUnspsc190501.50501522'),
	'identifierSchemeCodeUnspsc190501.50501523': __('identifierSchemeCodeUnspsc190501.50501523'),
	'identifierSchemeCodeUnspsc190501.50501600': __('identifierSchemeCodeUnspsc190501.50501600'),
	'identifierSchemeCodeUnspsc190501.50501601': __('identifierSchemeCodeUnspsc190501.50501601'),
	'identifierSchemeCodeUnspsc190501.50501602': __('identifierSchemeCodeUnspsc190501.50501602'),
	'identifierSchemeCodeUnspsc190501.50501603': __('identifierSchemeCodeUnspsc190501.50501603'),
	'identifierSchemeCodeUnspsc190501.50501604': __('identifierSchemeCodeUnspsc190501.50501604'),
	'identifierSchemeCodeUnspsc190501.50501605': __('identifierSchemeCodeUnspsc190501.50501605'),
	'identifierSchemeCodeUnspsc190501.50501606': __('identifierSchemeCodeUnspsc190501.50501606'),
	'identifierSchemeCodeUnspsc190501.50501607': __('identifierSchemeCodeUnspsc190501.50501607'),
	'identifierSchemeCodeUnspsc190501.50501608': __('identifierSchemeCodeUnspsc190501.50501608'),
	'identifierSchemeCodeUnspsc190501.50501609': __('identifierSchemeCodeUnspsc190501.50501609'),
	'identifierSchemeCodeUnspsc190501.50501610': __('identifierSchemeCodeUnspsc190501.50501610'),
	'identifierSchemeCodeUnspsc190501.50501611': __('identifierSchemeCodeUnspsc190501.50501611'),
	'identifierSchemeCodeUnspsc190501.50501612': __('identifierSchemeCodeUnspsc190501.50501612'),
	'identifierSchemeCodeUnspsc190501.50501613': __('identifierSchemeCodeUnspsc190501.50501613'),
	'identifierSchemeCodeUnspsc190501.50501700': __('identifierSchemeCodeUnspsc190501.50501700'),
	'identifierSchemeCodeUnspsc190501.50501701': __('identifierSchemeCodeUnspsc190501.50501701'),
	'identifierSchemeCodeUnspsc190501.50501702': __('identifierSchemeCodeUnspsc190501.50501702'),
	'identifierSchemeCodeUnspsc190501.50501703': __('identifierSchemeCodeUnspsc190501.50501703'),
	'identifierSchemeCodeUnspsc190501.50501704': __('identifierSchemeCodeUnspsc190501.50501704'),
	'identifierSchemeCodeUnspsc190501.50501705': __('identifierSchemeCodeUnspsc190501.50501705'),
	'identifierSchemeCodeUnspsc190501.50501706': __('identifierSchemeCodeUnspsc190501.50501706'),
	'identifierSchemeCodeUnspsc190501.50501707': __('identifierSchemeCodeUnspsc190501.50501707'),
	'identifierSchemeCodeUnspsc190501.50501800': __('identifierSchemeCodeUnspsc190501.50501800'),
	'identifierSchemeCodeUnspsc190501.50501801': __('identifierSchemeCodeUnspsc190501.50501801'),
	'identifierSchemeCodeUnspsc190501.50501802': __('identifierSchemeCodeUnspsc190501.50501802'),
	'identifierSchemeCodeUnspsc190501.50501803': __('identifierSchemeCodeUnspsc190501.50501803'),
	'identifierSchemeCodeUnspsc190501.50501804': __('identifierSchemeCodeUnspsc190501.50501804'),
	'identifierSchemeCodeUnspsc190501.50501805': __('identifierSchemeCodeUnspsc190501.50501805'),
	'identifierSchemeCodeUnspsc190501.50501900': __('identifierSchemeCodeUnspsc190501.50501900'),
	'identifierSchemeCodeUnspsc190501.50501901': __('identifierSchemeCodeUnspsc190501.50501901'),
	'identifierSchemeCodeUnspsc190501.50501902': __('identifierSchemeCodeUnspsc190501.50501902'),
	'identifierSchemeCodeUnspsc190501.50501903': __('identifierSchemeCodeUnspsc190501.50501903'),
	'identifierSchemeCodeUnspsc190501.50501904': __('identifierSchemeCodeUnspsc190501.50501904'),
	'identifierSchemeCodeUnspsc190501.50520000': __('identifierSchemeCodeUnspsc190501.50520000'),
	'identifierSchemeCodeUnspsc190501.50521500': __('identifierSchemeCodeUnspsc190501.50521500'),
	'identifierSchemeCodeUnspsc190501.50521501': __('identifierSchemeCodeUnspsc190501.50521501'),
	'identifierSchemeCodeUnspsc190501.50521502': __('identifierSchemeCodeUnspsc190501.50521502'),
	'identifierSchemeCodeUnspsc190501.50521503': __('identifierSchemeCodeUnspsc190501.50521503'),
	'identifierSchemeCodeUnspsc190501.50521504': __('identifierSchemeCodeUnspsc190501.50521504'),
	'identifierSchemeCodeUnspsc190501.50521505': __('identifierSchemeCodeUnspsc190501.50521505'),
	'identifierSchemeCodeUnspsc190501.50521506': __('identifierSchemeCodeUnspsc190501.50521506'),
	'identifierSchemeCodeUnspsc190501.50521507': __('identifierSchemeCodeUnspsc190501.50521507'),
	'identifierSchemeCodeUnspsc190501.50521508': __('identifierSchemeCodeUnspsc190501.50521508'),
	'identifierSchemeCodeUnspsc190501.50521509': __('identifierSchemeCodeUnspsc190501.50521509'),
	'identifierSchemeCodeUnspsc190501.50521510': __('identifierSchemeCodeUnspsc190501.50521510'),
	'identifierSchemeCodeUnspsc190501.50521511': __('identifierSchemeCodeUnspsc190501.50521511'),
	'identifierSchemeCodeUnspsc190501.50521512': __('identifierSchemeCodeUnspsc190501.50521512'),
	'identifierSchemeCodeUnspsc190501.50521513': __('identifierSchemeCodeUnspsc190501.50521513'),
	'identifierSchemeCodeUnspsc190501.50521514': __('identifierSchemeCodeUnspsc190501.50521514'),
	'identifierSchemeCodeUnspsc190501.50521515': __('identifierSchemeCodeUnspsc190501.50521515'),
	'identifierSchemeCodeUnspsc190501.50521516': __('identifierSchemeCodeUnspsc190501.50521516'),
	'identifierSchemeCodeUnspsc190501.50521517': __('identifierSchemeCodeUnspsc190501.50521517'),
	'identifierSchemeCodeUnspsc190501.50521518': __('identifierSchemeCodeUnspsc190501.50521518'),
	'identifierSchemeCodeUnspsc190501.50521519': __('identifierSchemeCodeUnspsc190501.50521519'),
	'identifierSchemeCodeUnspsc190501.50521520': __('identifierSchemeCodeUnspsc190501.50521520'),
	'identifierSchemeCodeUnspsc190501.50521521': __('identifierSchemeCodeUnspsc190501.50521521'),
	'identifierSchemeCodeUnspsc190501.50521522': __('identifierSchemeCodeUnspsc190501.50521522'),
	'identifierSchemeCodeUnspsc190501.50521523': __('identifierSchemeCodeUnspsc190501.50521523'),
	'identifierSchemeCodeUnspsc190501.50521524': __('identifierSchemeCodeUnspsc190501.50521524'),
	'identifierSchemeCodeUnspsc190501.50521525': __('identifierSchemeCodeUnspsc190501.50521525'),
	'identifierSchemeCodeUnspsc190501.50521526': __('identifierSchemeCodeUnspsc190501.50521526'),
	'identifierSchemeCodeUnspsc190501.50521527': __('identifierSchemeCodeUnspsc190501.50521527'),
	'identifierSchemeCodeUnspsc190501.50521528': __('identifierSchemeCodeUnspsc190501.50521528'),
	'identifierSchemeCodeUnspsc190501.50521529': __('identifierSchemeCodeUnspsc190501.50521529'),
	'identifierSchemeCodeUnspsc190501.50521530': __('identifierSchemeCodeUnspsc190501.50521530'),
	'identifierSchemeCodeUnspsc190501.50521531': __('identifierSchemeCodeUnspsc190501.50521531'),
	'identifierSchemeCodeUnspsc190501.50521532': __('identifierSchemeCodeUnspsc190501.50521532'),
	'identifierSchemeCodeUnspsc190501.50521533': __('identifierSchemeCodeUnspsc190501.50521533'),
	'identifierSchemeCodeUnspsc190501.50521534': __('identifierSchemeCodeUnspsc190501.50521534'),
	'identifierSchemeCodeUnspsc190501.50521535': __('identifierSchemeCodeUnspsc190501.50521535'),
	'identifierSchemeCodeUnspsc190501.50521536': __('identifierSchemeCodeUnspsc190501.50521536'),
	'identifierSchemeCodeUnspsc190501.50521537': __('identifierSchemeCodeUnspsc190501.50521537'),
	'identifierSchemeCodeUnspsc190501.50521538': __('identifierSchemeCodeUnspsc190501.50521538'),
	'identifierSchemeCodeUnspsc190501.50521539': __('identifierSchemeCodeUnspsc190501.50521539'),
	'identifierSchemeCodeUnspsc190501.50521540': __('identifierSchemeCodeUnspsc190501.50521540'),
	'identifierSchemeCodeUnspsc190501.50521541': __('identifierSchemeCodeUnspsc190501.50521541'),
	'identifierSchemeCodeUnspsc190501.50521542': __('identifierSchemeCodeUnspsc190501.50521542'),
	'identifierSchemeCodeUnspsc190501.50521543': __('identifierSchemeCodeUnspsc190501.50521543'),
	'identifierSchemeCodeUnspsc190501.50521544': __('identifierSchemeCodeUnspsc190501.50521544'),
	'identifierSchemeCodeUnspsc190501.50521545': __('identifierSchemeCodeUnspsc190501.50521545'),
	'identifierSchemeCodeUnspsc190501.50521546': __('identifierSchemeCodeUnspsc190501.50521546'),
	'identifierSchemeCodeUnspsc190501.50521547': __('identifierSchemeCodeUnspsc190501.50521547'),
	'identifierSchemeCodeUnspsc190501.50521548': __('identifierSchemeCodeUnspsc190501.50521548'),
	'identifierSchemeCodeUnspsc190501.50521549': __('identifierSchemeCodeUnspsc190501.50521549'),
	'identifierSchemeCodeUnspsc190501.50521550': __('identifierSchemeCodeUnspsc190501.50521550'),
	'identifierSchemeCodeUnspsc190501.50521551': __('identifierSchemeCodeUnspsc190501.50521551'),
	'identifierSchemeCodeUnspsc190501.50521552': __('identifierSchemeCodeUnspsc190501.50521552'),
	'identifierSchemeCodeUnspsc190501.50521553': __('identifierSchemeCodeUnspsc190501.50521553'),
	'identifierSchemeCodeUnspsc190501.50521554': __('identifierSchemeCodeUnspsc190501.50521554'),
	'identifierSchemeCodeUnspsc190501.50521555': __('identifierSchemeCodeUnspsc190501.50521555'),
	'identifierSchemeCodeUnspsc190501.50521556': __('identifierSchemeCodeUnspsc190501.50521556'),
	'identifierSchemeCodeUnspsc190501.50521557': __('identifierSchemeCodeUnspsc190501.50521557'),
	'identifierSchemeCodeUnspsc190501.50521558': __('identifierSchemeCodeUnspsc190501.50521558'),
	'identifierSchemeCodeUnspsc190501.50521559': __('identifierSchemeCodeUnspsc190501.50521559'),
	'identifierSchemeCodeUnspsc190501.50521560': __('identifierSchemeCodeUnspsc190501.50521560'),
	'identifierSchemeCodeUnspsc190501.50521561': __('identifierSchemeCodeUnspsc190501.50521561'),
	'identifierSchemeCodeUnspsc190501.50521562': __('identifierSchemeCodeUnspsc190501.50521562'),
	'identifierSchemeCodeUnspsc190501.50521563': __('identifierSchemeCodeUnspsc190501.50521563'),
	'identifierSchemeCodeUnspsc190501.50521564': __('identifierSchemeCodeUnspsc190501.50521564'),
	'identifierSchemeCodeUnspsc190501.50521565': __('identifierSchemeCodeUnspsc190501.50521565'),
	'identifierSchemeCodeUnspsc190501.50521566': __('identifierSchemeCodeUnspsc190501.50521566'),
	'identifierSchemeCodeUnspsc190501.50521567': __('identifierSchemeCodeUnspsc190501.50521567'),
	'identifierSchemeCodeUnspsc190501.50521568': __('identifierSchemeCodeUnspsc190501.50521568'),
	'identifierSchemeCodeUnspsc190501.50521569': __('identifierSchemeCodeUnspsc190501.50521569'),
	'identifierSchemeCodeUnspsc190501.50521570': __('identifierSchemeCodeUnspsc190501.50521570'),
	'identifierSchemeCodeUnspsc190501.50521571': __('identifierSchemeCodeUnspsc190501.50521571'),
	'identifierSchemeCodeUnspsc190501.50521572': __('identifierSchemeCodeUnspsc190501.50521572'),
	'identifierSchemeCodeUnspsc190501.50521573': __('identifierSchemeCodeUnspsc190501.50521573'),
	'identifierSchemeCodeUnspsc190501.50521574': __('identifierSchemeCodeUnspsc190501.50521574'),
	'identifierSchemeCodeUnspsc190501.50521575': __('identifierSchemeCodeUnspsc190501.50521575'),
	'identifierSchemeCodeUnspsc190501.50521576': __('identifierSchemeCodeUnspsc190501.50521576'),
	'identifierSchemeCodeUnspsc190501.50521577': __('identifierSchemeCodeUnspsc190501.50521577'),
	'identifierSchemeCodeUnspsc190501.50521578': __('identifierSchemeCodeUnspsc190501.50521578'),
	'identifierSchemeCodeUnspsc190501.50521579': __('identifierSchemeCodeUnspsc190501.50521579'),
	'identifierSchemeCodeUnspsc190501.50521580': __('identifierSchemeCodeUnspsc190501.50521580'),
	'identifierSchemeCodeUnspsc190501.50521581': __('identifierSchemeCodeUnspsc190501.50521581'),
	'identifierSchemeCodeUnspsc190501.50521582': __('identifierSchemeCodeUnspsc190501.50521582'),
	'identifierSchemeCodeUnspsc190501.50521583': __('identifierSchemeCodeUnspsc190501.50521583'),
	'identifierSchemeCodeUnspsc190501.50521584': __('identifierSchemeCodeUnspsc190501.50521584'),
	'identifierSchemeCodeUnspsc190501.50521585': __('identifierSchemeCodeUnspsc190501.50521585'),
	'identifierSchemeCodeUnspsc190501.50521586': __('identifierSchemeCodeUnspsc190501.50521586'),
	'identifierSchemeCodeUnspsc190501.50521587': __('identifierSchemeCodeUnspsc190501.50521587'),
	'identifierSchemeCodeUnspsc190501.50521588': __('identifierSchemeCodeUnspsc190501.50521588'),
	'identifierSchemeCodeUnspsc190501.50521589': __('identifierSchemeCodeUnspsc190501.50521589'),
	'identifierSchemeCodeUnspsc190501.50521590': __('identifierSchemeCodeUnspsc190501.50521590'),
	'identifierSchemeCodeUnspsc190501.50521591': __('identifierSchemeCodeUnspsc190501.50521591'),
	'identifierSchemeCodeUnspsc190501.50521592': __('identifierSchemeCodeUnspsc190501.50521592'),
	'identifierSchemeCodeUnspsc190501.50521593': __('identifierSchemeCodeUnspsc190501.50521593'),
	'identifierSchemeCodeUnspsc190501.50521594': __('identifierSchemeCodeUnspsc190501.50521594'),
	'identifierSchemeCodeUnspsc190501.50521595': __('identifierSchemeCodeUnspsc190501.50521595'),
	'identifierSchemeCodeUnspsc190501.50521596': __('identifierSchemeCodeUnspsc190501.50521596'),
	'identifierSchemeCodeUnspsc190501.50521600': __('identifierSchemeCodeUnspsc190501.50521600'),
	'identifierSchemeCodeUnspsc190501.50521601': __('identifierSchemeCodeUnspsc190501.50521601'),
	'identifierSchemeCodeUnspsc190501.50521602': __('identifierSchemeCodeUnspsc190501.50521602'),
	'identifierSchemeCodeUnspsc190501.50521603': __('identifierSchemeCodeUnspsc190501.50521603'),
	'identifierSchemeCodeUnspsc190501.50521604': __('identifierSchemeCodeUnspsc190501.50521604'),
	'identifierSchemeCodeUnspsc190501.50521605': __('identifierSchemeCodeUnspsc190501.50521605'),
	'identifierSchemeCodeUnspsc190501.50521606': __('identifierSchemeCodeUnspsc190501.50521606'),
	'identifierSchemeCodeUnspsc190501.50521607': __('identifierSchemeCodeUnspsc190501.50521607'),
	'identifierSchemeCodeUnspsc190501.50521608': __('identifierSchemeCodeUnspsc190501.50521608'),
	'identifierSchemeCodeUnspsc190501.50521609': __('identifierSchemeCodeUnspsc190501.50521609'),
	'identifierSchemeCodeUnspsc190501.50521610': __('identifierSchemeCodeUnspsc190501.50521610'),
	'identifierSchemeCodeUnspsc190501.50521611': __('identifierSchemeCodeUnspsc190501.50521611'),
	'identifierSchemeCodeUnspsc190501.50521612': __('identifierSchemeCodeUnspsc190501.50521612'),
	'identifierSchemeCodeUnspsc190501.50521613': __('identifierSchemeCodeUnspsc190501.50521613'),
	'identifierSchemeCodeUnspsc190501.50521614': __('identifierSchemeCodeUnspsc190501.50521614'),
	'identifierSchemeCodeUnspsc190501.50521615': __('identifierSchemeCodeUnspsc190501.50521615'),
	'identifierSchemeCodeUnspsc190501.50521616': __('identifierSchemeCodeUnspsc190501.50521616'),
	'identifierSchemeCodeUnspsc190501.50521617': __('identifierSchemeCodeUnspsc190501.50521617'),
	'identifierSchemeCodeUnspsc190501.50521618': __('identifierSchemeCodeUnspsc190501.50521618'),
	'identifierSchemeCodeUnspsc190501.50521619': __('identifierSchemeCodeUnspsc190501.50521619'),
	'identifierSchemeCodeUnspsc190501.50521620': __('identifierSchemeCodeUnspsc190501.50521620'),
	'identifierSchemeCodeUnspsc190501.50521621': __('identifierSchemeCodeUnspsc190501.50521621'),
	'identifierSchemeCodeUnspsc190501.50521622': __('identifierSchemeCodeUnspsc190501.50521622'),
	'identifierSchemeCodeUnspsc190501.50521623': __('identifierSchemeCodeUnspsc190501.50521623'),
	'identifierSchemeCodeUnspsc190501.50521624': __('identifierSchemeCodeUnspsc190501.50521624'),
	'identifierSchemeCodeUnspsc190501.50521625': __('identifierSchemeCodeUnspsc190501.50521625'),
	'identifierSchemeCodeUnspsc190501.50521626': __('identifierSchemeCodeUnspsc190501.50521626'),
	'identifierSchemeCodeUnspsc190501.50521627': __('identifierSchemeCodeUnspsc190501.50521627'),
	'identifierSchemeCodeUnspsc190501.50521628': __('identifierSchemeCodeUnspsc190501.50521628'),
	'identifierSchemeCodeUnspsc190501.50521629': __('identifierSchemeCodeUnspsc190501.50521629'),
	'identifierSchemeCodeUnspsc190501.50521630': __('identifierSchemeCodeUnspsc190501.50521630'),
	'identifierSchemeCodeUnspsc190501.50521631': __('identifierSchemeCodeUnspsc190501.50521631'),
	'identifierSchemeCodeUnspsc190501.50521632': __('identifierSchemeCodeUnspsc190501.50521632'),
	'identifierSchemeCodeUnspsc190501.50521633': __('identifierSchemeCodeUnspsc190501.50521633'),
	'identifierSchemeCodeUnspsc190501.50521634': __('identifierSchemeCodeUnspsc190501.50521634'),
	'identifierSchemeCodeUnspsc190501.50521635': __('identifierSchemeCodeUnspsc190501.50521635'),
	'identifierSchemeCodeUnspsc190501.50521636': __('identifierSchemeCodeUnspsc190501.50521636'),
	'identifierSchemeCodeUnspsc190501.50521637': __('identifierSchemeCodeUnspsc190501.50521637'),
	'identifierSchemeCodeUnspsc190501.50521638': __('identifierSchemeCodeUnspsc190501.50521638'),
	'identifierSchemeCodeUnspsc190501.50521639': __('identifierSchemeCodeUnspsc190501.50521639'),
	'identifierSchemeCodeUnspsc190501.50521640': __('identifierSchemeCodeUnspsc190501.50521640'),
	'identifierSchemeCodeUnspsc190501.50521641': __('identifierSchemeCodeUnspsc190501.50521641'),
	'identifierSchemeCodeUnspsc190501.50521642': __('identifierSchemeCodeUnspsc190501.50521642'),
	'identifierSchemeCodeUnspsc190501.50521643': __('identifierSchemeCodeUnspsc190501.50521643'),
	'identifierSchemeCodeUnspsc190501.50521644': __('identifierSchemeCodeUnspsc190501.50521644'),
	'identifierSchemeCodeUnspsc190501.50521645': __('identifierSchemeCodeUnspsc190501.50521645'),
	'identifierSchemeCodeUnspsc190501.50521646': __('identifierSchemeCodeUnspsc190501.50521646'),
	'identifierSchemeCodeUnspsc190501.50521647': __('identifierSchemeCodeUnspsc190501.50521647'),
	'identifierSchemeCodeUnspsc190501.50521648': __('identifierSchemeCodeUnspsc190501.50521648'),
	'identifierSchemeCodeUnspsc190501.50521649': __('identifierSchemeCodeUnspsc190501.50521649'),
	'identifierSchemeCodeUnspsc190501.50521650': __('identifierSchemeCodeUnspsc190501.50521650'),
	'identifierSchemeCodeUnspsc190501.50521651': __('identifierSchemeCodeUnspsc190501.50521651'),
	'identifierSchemeCodeUnspsc190501.50521652': __('identifierSchemeCodeUnspsc190501.50521652'),
	'identifierSchemeCodeUnspsc190501.50521653': __('identifierSchemeCodeUnspsc190501.50521653'),
	'identifierSchemeCodeUnspsc190501.50521654': __('identifierSchemeCodeUnspsc190501.50521654'),
	'identifierSchemeCodeUnspsc190501.50521655': __('identifierSchemeCodeUnspsc190501.50521655'),
	'identifierSchemeCodeUnspsc190501.50521700': __('identifierSchemeCodeUnspsc190501.50521700'),
	'identifierSchemeCodeUnspsc190501.50521701': __('identifierSchemeCodeUnspsc190501.50521701'),
	'identifierSchemeCodeUnspsc190501.50521702': __('identifierSchemeCodeUnspsc190501.50521702'),
	'identifierSchemeCodeUnspsc190501.50521703': __('identifierSchemeCodeUnspsc190501.50521703'),
	'identifierSchemeCodeUnspsc190501.50521704': __('identifierSchemeCodeUnspsc190501.50521704'),
	'identifierSchemeCodeUnspsc190501.50521705': __('identifierSchemeCodeUnspsc190501.50521705'),
	'identifierSchemeCodeUnspsc190501.50521706': __('identifierSchemeCodeUnspsc190501.50521706'),
	'identifierSchemeCodeUnspsc190501.50521707': __('identifierSchemeCodeUnspsc190501.50521707'),
	'identifierSchemeCodeUnspsc190501.50521708': __('identifierSchemeCodeUnspsc190501.50521708'),
	'identifierSchemeCodeUnspsc190501.50521709': __('identifierSchemeCodeUnspsc190501.50521709'),
	'identifierSchemeCodeUnspsc190501.50521710': __('identifierSchemeCodeUnspsc190501.50521710'),
	'identifierSchemeCodeUnspsc190501.50521711': __('identifierSchemeCodeUnspsc190501.50521711'),
	'identifierSchemeCodeUnspsc190501.50521712': __('identifierSchemeCodeUnspsc190501.50521712'),
	'identifierSchemeCodeUnspsc190501.50521713': __('identifierSchemeCodeUnspsc190501.50521713'),
	'identifierSchemeCodeUnspsc190501.50521714': __('identifierSchemeCodeUnspsc190501.50521714'),
	'identifierSchemeCodeUnspsc190501.50521715': __('identifierSchemeCodeUnspsc190501.50521715'),
	'identifierSchemeCodeUnspsc190501.50521800': __('identifierSchemeCodeUnspsc190501.50521800'),
	'identifierSchemeCodeUnspsc190501.50521801': __('identifierSchemeCodeUnspsc190501.50521801'),
	'identifierSchemeCodeUnspsc190501.50521802': __('identifierSchemeCodeUnspsc190501.50521802'),
	'identifierSchemeCodeUnspsc190501.50521803': __('identifierSchemeCodeUnspsc190501.50521803'),
	'identifierSchemeCodeUnspsc190501.50521804': __('identifierSchemeCodeUnspsc190501.50521804'),
	'identifierSchemeCodeUnspsc190501.50521805': __('identifierSchemeCodeUnspsc190501.50521805'),
	'identifierSchemeCodeUnspsc190501.50521806': __('identifierSchemeCodeUnspsc190501.50521806'),
	'identifierSchemeCodeUnspsc190501.50521807': __('identifierSchemeCodeUnspsc190501.50521807'),
	'identifierSchemeCodeUnspsc190501.50521808': __('identifierSchemeCodeUnspsc190501.50521808'),
	'identifierSchemeCodeUnspsc190501.50521809': __('identifierSchemeCodeUnspsc190501.50521809'),
	'identifierSchemeCodeUnspsc190501.50521810': __('identifierSchemeCodeUnspsc190501.50521810'),
	'identifierSchemeCodeUnspsc190501.50521811': __('identifierSchemeCodeUnspsc190501.50521811'),
	'identifierSchemeCodeUnspsc190501.50521812': __('identifierSchemeCodeUnspsc190501.50521812'),
	'identifierSchemeCodeUnspsc190501.50521813': __('identifierSchemeCodeUnspsc190501.50521813'),
	'identifierSchemeCodeUnspsc190501.50521814': __('identifierSchemeCodeUnspsc190501.50521814'),
	'identifierSchemeCodeUnspsc190501.50521900': __('identifierSchemeCodeUnspsc190501.50521900'),
	'identifierSchemeCodeUnspsc190501.50521901': __('identifierSchemeCodeUnspsc190501.50521901'),
	'identifierSchemeCodeUnspsc190501.50521902': __('identifierSchemeCodeUnspsc190501.50521902'),
	'identifierSchemeCodeUnspsc190501.50521903': __('identifierSchemeCodeUnspsc190501.50521903'),
	'identifierSchemeCodeUnspsc190501.50522000': __('identifierSchemeCodeUnspsc190501.50522000'),
	'identifierSchemeCodeUnspsc190501.50522001': __('identifierSchemeCodeUnspsc190501.50522001'),
	'identifierSchemeCodeUnspsc190501.50522002': __('identifierSchemeCodeUnspsc190501.50522002'),
	'identifierSchemeCodeUnspsc190501.50522003': __('identifierSchemeCodeUnspsc190501.50522003'),
	'identifierSchemeCodeUnspsc190501.50522004': __('identifierSchemeCodeUnspsc190501.50522004'),
	'identifierSchemeCodeUnspsc190501.50522005': __('identifierSchemeCodeUnspsc190501.50522005'),
	'identifierSchemeCodeUnspsc190501.50522006': __('identifierSchemeCodeUnspsc190501.50522006'),
	'identifierSchemeCodeUnspsc190501.50522007': __('identifierSchemeCodeUnspsc190501.50522007'),
	'identifierSchemeCodeUnspsc190501.50522008': __('identifierSchemeCodeUnspsc190501.50522008'),
	'identifierSchemeCodeUnspsc190501.50522009': __('identifierSchemeCodeUnspsc190501.50522009'),
	'identifierSchemeCodeUnspsc190501.50522010': __('identifierSchemeCodeUnspsc190501.50522010'),
	'identifierSchemeCodeUnspsc190501.50522011': __('identifierSchemeCodeUnspsc190501.50522011'),
	'identifierSchemeCodeUnspsc190501.50522012': __('identifierSchemeCodeUnspsc190501.50522012'),
	'identifierSchemeCodeUnspsc190501.50522013': __('identifierSchemeCodeUnspsc190501.50522013'),
	'identifierSchemeCodeUnspsc190501.50522014': __('identifierSchemeCodeUnspsc190501.50522014'),
	'identifierSchemeCodeUnspsc190501.50522015': __('identifierSchemeCodeUnspsc190501.50522015'),
	'identifierSchemeCodeUnspsc190501.50522100': __('identifierSchemeCodeUnspsc190501.50522100'),
	'identifierSchemeCodeUnspsc190501.50522101': __('identifierSchemeCodeUnspsc190501.50522101'),
	'identifierSchemeCodeUnspsc190501.50522102': __('identifierSchemeCodeUnspsc190501.50522102'),
	'identifierSchemeCodeUnspsc190501.50522103': __('identifierSchemeCodeUnspsc190501.50522103'),
	'identifierSchemeCodeUnspsc190501.50522104': __('identifierSchemeCodeUnspsc190501.50522104'),
	'identifierSchemeCodeUnspsc190501.50522200': __('identifierSchemeCodeUnspsc190501.50522200'),
	'identifierSchemeCodeUnspsc190501.50522201': __('identifierSchemeCodeUnspsc190501.50522201'),
	'identifierSchemeCodeUnspsc190501.50522202': __('identifierSchemeCodeUnspsc190501.50522202'),
	'identifierSchemeCodeUnspsc190501.50522203': __('identifierSchemeCodeUnspsc190501.50522203'),
	'identifierSchemeCodeUnspsc190501.50522204': __('identifierSchemeCodeUnspsc190501.50522204'),
	'identifierSchemeCodeUnspsc190501.50522205': __('identifierSchemeCodeUnspsc190501.50522205'),
	'identifierSchemeCodeUnspsc190501.50522206': __('identifierSchemeCodeUnspsc190501.50522206'),
	'identifierSchemeCodeUnspsc190501.50522207': __('identifierSchemeCodeUnspsc190501.50522207'),
	'identifierSchemeCodeUnspsc190501.50522208': __('identifierSchemeCodeUnspsc190501.50522208'),
	'identifierSchemeCodeUnspsc190501.50522209': __('identifierSchemeCodeUnspsc190501.50522209'),
	'identifierSchemeCodeUnspsc190501.50522210': __('identifierSchemeCodeUnspsc190501.50522210'),
	'identifierSchemeCodeUnspsc190501.50522211': __('identifierSchemeCodeUnspsc190501.50522211'),
	'identifierSchemeCodeUnspsc190501.50522212': __('identifierSchemeCodeUnspsc190501.50522212'),
	'identifierSchemeCodeUnspsc190501.50522213': __('identifierSchemeCodeUnspsc190501.50522213'),
	'identifierSchemeCodeUnspsc190501.50522214': __('identifierSchemeCodeUnspsc190501.50522214'),
	'identifierSchemeCodeUnspsc190501.50522215': __('identifierSchemeCodeUnspsc190501.50522215'),
	'identifierSchemeCodeUnspsc190501.50522300': __('identifierSchemeCodeUnspsc190501.50522300'),
	'identifierSchemeCodeUnspsc190501.50522301': __('identifierSchemeCodeUnspsc190501.50522301'),
	'identifierSchemeCodeUnspsc190501.50522302': __('identifierSchemeCodeUnspsc190501.50522302'),
	'identifierSchemeCodeUnspsc190501.50522303': __('identifierSchemeCodeUnspsc190501.50522303'),
	'identifierSchemeCodeUnspsc190501.50522304': __('identifierSchemeCodeUnspsc190501.50522304'),
	'identifierSchemeCodeUnspsc190501.50522400': __('identifierSchemeCodeUnspsc190501.50522400'),
	'identifierSchemeCodeUnspsc190501.50522401': __('identifierSchemeCodeUnspsc190501.50522401'),
	'identifierSchemeCodeUnspsc190501.50522402': __('identifierSchemeCodeUnspsc190501.50522402'),
	'identifierSchemeCodeUnspsc190501.50522403': __('identifierSchemeCodeUnspsc190501.50522403'),
	'identifierSchemeCodeUnspsc190501.50522404': __('identifierSchemeCodeUnspsc190501.50522404'),
	'identifierSchemeCodeUnspsc190501.50522405': __('identifierSchemeCodeUnspsc190501.50522405'),
	'identifierSchemeCodeUnspsc190501.50522406': __('identifierSchemeCodeUnspsc190501.50522406'),
	'identifierSchemeCodeUnspsc190501.50522407': __('identifierSchemeCodeUnspsc190501.50522407'),
	'identifierSchemeCodeUnspsc190501.50522408': __('identifierSchemeCodeUnspsc190501.50522408'),
	'identifierSchemeCodeUnspsc190501.50522409': __('identifierSchemeCodeUnspsc190501.50522409'),
	'identifierSchemeCodeUnspsc190501.50522500': __('identifierSchemeCodeUnspsc190501.50522500'),
	'identifierSchemeCodeUnspsc190501.50522501': __('identifierSchemeCodeUnspsc190501.50522501'),
	'identifierSchemeCodeUnspsc190501.50522502': __('identifierSchemeCodeUnspsc190501.50522502'),
	'identifierSchemeCodeUnspsc190501.50522503': __('identifierSchemeCodeUnspsc190501.50522503'),
	'identifierSchemeCodeUnspsc190501.50522504': __('identifierSchemeCodeUnspsc190501.50522504'),
	'identifierSchemeCodeUnspsc190501.50522505': __('identifierSchemeCodeUnspsc190501.50522505'),
	'identifierSchemeCodeUnspsc190501.50522506': __('identifierSchemeCodeUnspsc190501.50522506'),
	'identifierSchemeCodeUnspsc190501.50522507': __('identifierSchemeCodeUnspsc190501.50522507'),
	'identifierSchemeCodeUnspsc190501.50522508': __('identifierSchemeCodeUnspsc190501.50522508'),
	'identifierSchemeCodeUnspsc190501.50522509': __('identifierSchemeCodeUnspsc190501.50522509'),
	'identifierSchemeCodeUnspsc190501.50522510': __('identifierSchemeCodeUnspsc190501.50522510'),
	'identifierSchemeCodeUnspsc190501.50522511': __('identifierSchemeCodeUnspsc190501.50522511'),
	'identifierSchemeCodeUnspsc190501.50522512': __('identifierSchemeCodeUnspsc190501.50522512'),
	'identifierSchemeCodeUnspsc190501.50522513': __('identifierSchemeCodeUnspsc190501.50522513'),
	'identifierSchemeCodeUnspsc190501.50522514': __('identifierSchemeCodeUnspsc190501.50522514'),
	'identifierSchemeCodeUnspsc190501.50522515': __('identifierSchemeCodeUnspsc190501.50522515'),
	'identifierSchemeCodeUnspsc190501.50522516': __('identifierSchemeCodeUnspsc190501.50522516'),
	'identifierSchemeCodeUnspsc190501.50522517': __('identifierSchemeCodeUnspsc190501.50522517'),
	'identifierSchemeCodeUnspsc190501.50522518': __('identifierSchemeCodeUnspsc190501.50522518'),
	'identifierSchemeCodeUnspsc190501.50522519': __('identifierSchemeCodeUnspsc190501.50522519'),
	'identifierSchemeCodeUnspsc190501.50522520': __('identifierSchemeCodeUnspsc190501.50522520'),
	'identifierSchemeCodeUnspsc190501.50522521': __('identifierSchemeCodeUnspsc190501.50522521'),
	'identifierSchemeCodeUnspsc190501.50522522': __('identifierSchemeCodeUnspsc190501.50522522'),
	'identifierSchemeCodeUnspsc190501.50522523': __('identifierSchemeCodeUnspsc190501.50522523'),
	'identifierSchemeCodeUnspsc190501.50522524': __('identifierSchemeCodeUnspsc190501.50522524'),
	'identifierSchemeCodeUnspsc190501.50522525': __('identifierSchemeCodeUnspsc190501.50522525'),
	'identifierSchemeCodeUnspsc190501.50522526': __('identifierSchemeCodeUnspsc190501.50522526'),
	'identifierSchemeCodeUnspsc190501.50522527': __('identifierSchemeCodeUnspsc190501.50522527'),
	'identifierSchemeCodeUnspsc190501.50522528': __('identifierSchemeCodeUnspsc190501.50522528'),
	'identifierSchemeCodeUnspsc190501.50522529': __('identifierSchemeCodeUnspsc190501.50522529'),
	'identifierSchemeCodeUnspsc190501.50522530': __('identifierSchemeCodeUnspsc190501.50522530'),
	'identifierSchemeCodeUnspsc190501.50522531': __('identifierSchemeCodeUnspsc190501.50522531'),
	'identifierSchemeCodeUnspsc190501.50522600': __('identifierSchemeCodeUnspsc190501.50522600'),
	'identifierSchemeCodeUnspsc190501.50522601': __('identifierSchemeCodeUnspsc190501.50522601'),
	'identifierSchemeCodeUnspsc190501.50522602': __('identifierSchemeCodeUnspsc190501.50522602'),
	'identifierSchemeCodeUnspsc190501.50522603': __('identifierSchemeCodeUnspsc190501.50522603'),
	'identifierSchemeCodeUnspsc190501.50522604': __('identifierSchemeCodeUnspsc190501.50522604'),
	'identifierSchemeCodeUnspsc190501.50522605': __('identifierSchemeCodeUnspsc190501.50522605'),
	'identifierSchemeCodeUnspsc190501.50522606': __('identifierSchemeCodeUnspsc190501.50522606'),
	'identifierSchemeCodeUnspsc190501.50522607': __('identifierSchemeCodeUnspsc190501.50522607'),
	'identifierSchemeCodeUnspsc190501.50522700': __('identifierSchemeCodeUnspsc190501.50522700'),
	'identifierSchemeCodeUnspsc190501.50522701': __('identifierSchemeCodeUnspsc190501.50522701'),
	'identifierSchemeCodeUnspsc190501.50522702': __('identifierSchemeCodeUnspsc190501.50522702'),
	'identifierSchemeCodeUnspsc190501.50522703': __('identifierSchemeCodeUnspsc190501.50522703'),
	'identifierSchemeCodeUnspsc190501.50522704': __('identifierSchemeCodeUnspsc190501.50522704'),
	'identifierSchemeCodeUnspsc190501.50522705': __('identifierSchemeCodeUnspsc190501.50522705'),
	'identifierSchemeCodeUnspsc190501.50522706': __('identifierSchemeCodeUnspsc190501.50522706'),
	'identifierSchemeCodeUnspsc190501.50522707': __('identifierSchemeCodeUnspsc190501.50522707'),
	'identifierSchemeCodeUnspsc190501.50522708': __('identifierSchemeCodeUnspsc190501.50522708'),
	'identifierSchemeCodeUnspsc190501.50522709': __('identifierSchemeCodeUnspsc190501.50522709'),
	'identifierSchemeCodeUnspsc190501.50522710': __('identifierSchemeCodeUnspsc190501.50522710'),
	'identifierSchemeCodeUnspsc190501.50522800': __('identifierSchemeCodeUnspsc190501.50522800'),
	'identifierSchemeCodeUnspsc190501.50522801': __('identifierSchemeCodeUnspsc190501.50522801'),
	'identifierSchemeCodeUnspsc190501.50522802': __('identifierSchemeCodeUnspsc190501.50522802'),
	'identifierSchemeCodeUnspsc190501.50522803': __('identifierSchemeCodeUnspsc190501.50522803'),
	'identifierSchemeCodeUnspsc190501.50522804': __('identifierSchemeCodeUnspsc190501.50522804'),
	'identifierSchemeCodeUnspsc190501.50522805': __('identifierSchemeCodeUnspsc190501.50522805'),
	'identifierSchemeCodeUnspsc190501.50522806': __('identifierSchemeCodeUnspsc190501.50522806'),
	'identifierSchemeCodeUnspsc190501.50522900': __('identifierSchemeCodeUnspsc190501.50522900'),
	'identifierSchemeCodeUnspsc190501.50522901': __('identifierSchemeCodeUnspsc190501.50522901'),
	'identifierSchemeCodeUnspsc190501.50522902': __('identifierSchemeCodeUnspsc190501.50522902'),
	'identifierSchemeCodeUnspsc190501.50522903': __('identifierSchemeCodeUnspsc190501.50522903'),
	'identifierSchemeCodeUnspsc190501.50522904': __('identifierSchemeCodeUnspsc190501.50522904'),
	'identifierSchemeCodeUnspsc190501.50522905': __('identifierSchemeCodeUnspsc190501.50522905'),
	'identifierSchemeCodeUnspsc190501.50522906': __('identifierSchemeCodeUnspsc190501.50522906'),
	'identifierSchemeCodeUnspsc190501.50522907': __('identifierSchemeCodeUnspsc190501.50522907'),
	'identifierSchemeCodeUnspsc190501.50522908': __('identifierSchemeCodeUnspsc190501.50522908'),
	'identifierSchemeCodeUnspsc190501.50522909': __('identifierSchemeCodeUnspsc190501.50522909'),
	'identifierSchemeCodeUnspsc190501.50522910': __('identifierSchemeCodeUnspsc190501.50522910'),
	'identifierSchemeCodeUnspsc190501.50522911': __('identifierSchemeCodeUnspsc190501.50522911'),
	'identifierSchemeCodeUnspsc190501.50522912': __('identifierSchemeCodeUnspsc190501.50522912'),
	'identifierSchemeCodeUnspsc190501.50522913': __('identifierSchemeCodeUnspsc190501.50522913'),
	'identifierSchemeCodeUnspsc190501.50522914': __('identifierSchemeCodeUnspsc190501.50522914'),
	'identifierSchemeCodeUnspsc190501.50522915': __('identifierSchemeCodeUnspsc190501.50522915'),
	'identifierSchemeCodeUnspsc190501.50522916': __('identifierSchemeCodeUnspsc190501.50522916'),
	'identifierSchemeCodeUnspsc190501.50523000': __('identifierSchemeCodeUnspsc190501.50523000'),
	'identifierSchemeCodeUnspsc190501.50523001': __('identifierSchemeCodeUnspsc190501.50523001'),
	'identifierSchemeCodeUnspsc190501.50523002': __('identifierSchemeCodeUnspsc190501.50523002'),
	'identifierSchemeCodeUnspsc190501.50523100': __('identifierSchemeCodeUnspsc190501.50523100'),
	'identifierSchemeCodeUnspsc190501.50523101': __('identifierSchemeCodeUnspsc190501.50523101'),
	'identifierSchemeCodeUnspsc190501.50523102': __('identifierSchemeCodeUnspsc190501.50523102'),
	'identifierSchemeCodeUnspsc190501.50523103': __('identifierSchemeCodeUnspsc190501.50523103'),
	'identifierSchemeCodeUnspsc190501.50523104': __('identifierSchemeCodeUnspsc190501.50523104'),
	'identifierSchemeCodeUnspsc190501.50523105': __('identifierSchemeCodeUnspsc190501.50523105'),
	'identifierSchemeCodeUnspsc190501.50523106': __('identifierSchemeCodeUnspsc190501.50523106'),
	'identifierSchemeCodeUnspsc190501.50523107': __('identifierSchemeCodeUnspsc190501.50523107'),
	'identifierSchemeCodeUnspsc190501.50523108': __('identifierSchemeCodeUnspsc190501.50523108'),
	'identifierSchemeCodeUnspsc190501.50523109': __('identifierSchemeCodeUnspsc190501.50523109'),
	'identifierSchemeCodeUnspsc190501.50523110': __('identifierSchemeCodeUnspsc190501.50523110'),
	'identifierSchemeCodeUnspsc190501.50523111': __('identifierSchemeCodeUnspsc190501.50523111'),
	'identifierSchemeCodeUnspsc190501.50523200': __('identifierSchemeCodeUnspsc190501.50523200'),
	'identifierSchemeCodeUnspsc190501.50523201': __('identifierSchemeCodeUnspsc190501.50523201'),
	'identifierSchemeCodeUnspsc190501.50523202': __('identifierSchemeCodeUnspsc190501.50523202'),
	'identifierSchemeCodeUnspsc190501.50523203': __('identifierSchemeCodeUnspsc190501.50523203'),
	'identifierSchemeCodeUnspsc190501.50523204': __('identifierSchemeCodeUnspsc190501.50523204'),
	'identifierSchemeCodeUnspsc190501.50523205': __('identifierSchemeCodeUnspsc190501.50523205'),
	'identifierSchemeCodeUnspsc190501.50523206': __('identifierSchemeCodeUnspsc190501.50523206'),
	'identifierSchemeCodeUnspsc190501.50523207': __('identifierSchemeCodeUnspsc190501.50523207'),
	'identifierSchemeCodeUnspsc190501.50523300': __('identifierSchemeCodeUnspsc190501.50523300'),
	'identifierSchemeCodeUnspsc190501.50523301': __('identifierSchemeCodeUnspsc190501.50523301'),
	'identifierSchemeCodeUnspsc190501.50523302': __('identifierSchemeCodeUnspsc190501.50523302'),
	'identifierSchemeCodeUnspsc190501.50523303': __('identifierSchemeCodeUnspsc190501.50523303'),
	'identifierSchemeCodeUnspsc190501.50523304': __('identifierSchemeCodeUnspsc190501.50523304'),
	'identifierSchemeCodeUnspsc190501.50523305': __('identifierSchemeCodeUnspsc190501.50523305'),
	'identifierSchemeCodeUnspsc190501.50523306': __('identifierSchemeCodeUnspsc190501.50523306'),
	'identifierSchemeCodeUnspsc190501.50523307': __('identifierSchemeCodeUnspsc190501.50523307'),
	'identifierSchemeCodeUnspsc190501.50523308': __('identifierSchemeCodeUnspsc190501.50523308'),
	'identifierSchemeCodeUnspsc190501.50523309': __('identifierSchemeCodeUnspsc190501.50523309'),
	'identifierSchemeCodeUnspsc190501.50523400': __('identifierSchemeCodeUnspsc190501.50523400'),
	'identifierSchemeCodeUnspsc190501.50523401': __('identifierSchemeCodeUnspsc190501.50523401'),
	'identifierSchemeCodeUnspsc190501.50523402': __('identifierSchemeCodeUnspsc190501.50523402'),
	'identifierSchemeCodeUnspsc190501.50523403': __('identifierSchemeCodeUnspsc190501.50523403'),
	'identifierSchemeCodeUnspsc190501.50523404': __('identifierSchemeCodeUnspsc190501.50523404'),
	'identifierSchemeCodeUnspsc190501.50523405': __('identifierSchemeCodeUnspsc190501.50523405'),
	'identifierSchemeCodeUnspsc190501.50523406': __('identifierSchemeCodeUnspsc190501.50523406'),
	'identifierSchemeCodeUnspsc190501.50523407': __('identifierSchemeCodeUnspsc190501.50523407'),
	'identifierSchemeCodeUnspsc190501.50523408': __('identifierSchemeCodeUnspsc190501.50523408'),
	'identifierSchemeCodeUnspsc190501.50523409': __('identifierSchemeCodeUnspsc190501.50523409'),
	'identifierSchemeCodeUnspsc190501.50523410': __('identifierSchemeCodeUnspsc190501.50523410'),
	'identifierSchemeCodeUnspsc190501.50523411': __('identifierSchemeCodeUnspsc190501.50523411'),
	'identifierSchemeCodeUnspsc190501.50523412': __('identifierSchemeCodeUnspsc190501.50523412'),
	'identifierSchemeCodeUnspsc190501.50523413': __('identifierSchemeCodeUnspsc190501.50523413'),
	'identifierSchemeCodeUnspsc190501.50523414': __('identifierSchemeCodeUnspsc190501.50523414'),
	'identifierSchemeCodeUnspsc190501.50523415': __('identifierSchemeCodeUnspsc190501.50523415'),
	'identifierSchemeCodeUnspsc190501.50523416': __('identifierSchemeCodeUnspsc190501.50523416'),
	'identifierSchemeCodeUnspsc190501.50523417': __('identifierSchemeCodeUnspsc190501.50523417'),
	'identifierSchemeCodeUnspsc190501.50523418': __('identifierSchemeCodeUnspsc190501.50523418'),
	'identifierSchemeCodeUnspsc190501.50523419': __('identifierSchemeCodeUnspsc190501.50523419'),
	'identifierSchemeCodeUnspsc190501.50523420': __('identifierSchemeCodeUnspsc190501.50523420'),
	'identifierSchemeCodeUnspsc190501.50523421': __('identifierSchemeCodeUnspsc190501.50523421'),
	'identifierSchemeCodeUnspsc190501.50523422': __('identifierSchemeCodeUnspsc190501.50523422'),
	'identifierSchemeCodeUnspsc190501.50523423': __('identifierSchemeCodeUnspsc190501.50523423'),
	'identifierSchemeCodeUnspsc190501.50523424': __('identifierSchemeCodeUnspsc190501.50523424'),
	'identifierSchemeCodeUnspsc190501.50523425': __('identifierSchemeCodeUnspsc190501.50523425'),
	'identifierSchemeCodeUnspsc190501.50523426': __('identifierSchemeCodeUnspsc190501.50523426'),
	'identifierSchemeCodeUnspsc190501.50523427': __('identifierSchemeCodeUnspsc190501.50523427'),
	'identifierSchemeCodeUnspsc190501.50523428': __('identifierSchemeCodeUnspsc190501.50523428'),
	'identifierSchemeCodeUnspsc190501.50523429': __('identifierSchemeCodeUnspsc190501.50523429'),
	'identifierSchemeCodeUnspsc190501.50523430': __('identifierSchemeCodeUnspsc190501.50523430'),
	'identifierSchemeCodeUnspsc190501.50523431': __('identifierSchemeCodeUnspsc190501.50523431'),
	'identifierSchemeCodeUnspsc190501.50523432': __('identifierSchemeCodeUnspsc190501.50523432'),
	'identifierSchemeCodeUnspsc190501.50523433': __('identifierSchemeCodeUnspsc190501.50523433'),
	'identifierSchemeCodeUnspsc190501.50523434': __('identifierSchemeCodeUnspsc190501.50523434'),
	'identifierSchemeCodeUnspsc190501.50523435': __('identifierSchemeCodeUnspsc190501.50523435'),
	'identifierSchemeCodeUnspsc190501.50523436': __('identifierSchemeCodeUnspsc190501.50523436'),
	'identifierSchemeCodeUnspsc190501.50523437': __('identifierSchemeCodeUnspsc190501.50523437'),
	'identifierSchemeCodeUnspsc190501.50523438': __('identifierSchemeCodeUnspsc190501.50523438'),
	'identifierSchemeCodeUnspsc190501.50523439': __('identifierSchemeCodeUnspsc190501.50523439'),
	'identifierSchemeCodeUnspsc190501.50523440': __('identifierSchemeCodeUnspsc190501.50523440'),
	'identifierSchemeCodeUnspsc190501.50523441': __('identifierSchemeCodeUnspsc190501.50523441'),
	'identifierSchemeCodeUnspsc190501.50523442': __('identifierSchemeCodeUnspsc190501.50523442'),
	'identifierSchemeCodeUnspsc190501.50523443': __('identifierSchemeCodeUnspsc190501.50523443'),
	'identifierSchemeCodeUnspsc190501.50523444': __('identifierSchemeCodeUnspsc190501.50523444'),
	'identifierSchemeCodeUnspsc190501.50523445': __('identifierSchemeCodeUnspsc190501.50523445'),
	'identifierSchemeCodeUnspsc190501.50523446': __('identifierSchemeCodeUnspsc190501.50523446'),
	'identifierSchemeCodeUnspsc190501.50523447': __('identifierSchemeCodeUnspsc190501.50523447'),
	'identifierSchemeCodeUnspsc190501.50523448': __('identifierSchemeCodeUnspsc190501.50523448'),
	'identifierSchemeCodeUnspsc190501.50523449': __('identifierSchemeCodeUnspsc190501.50523449'),
	'identifierSchemeCodeUnspsc190501.50523450': __('identifierSchemeCodeUnspsc190501.50523450'),
	'identifierSchemeCodeUnspsc190501.50523451': __('identifierSchemeCodeUnspsc190501.50523451'),
	'identifierSchemeCodeUnspsc190501.50523452': __('identifierSchemeCodeUnspsc190501.50523452'),
	'identifierSchemeCodeUnspsc190501.50523453': __('identifierSchemeCodeUnspsc190501.50523453'),
	'identifierSchemeCodeUnspsc190501.50523454': __('identifierSchemeCodeUnspsc190501.50523454'),
	'identifierSchemeCodeUnspsc190501.50523455': __('identifierSchemeCodeUnspsc190501.50523455'),
	'identifierSchemeCodeUnspsc190501.50523456': __('identifierSchemeCodeUnspsc190501.50523456'),
	'identifierSchemeCodeUnspsc190501.50523457': __('identifierSchemeCodeUnspsc190501.50523457'),
	'identifierSchemeCodeUnspsc190501.50523458': __('identifierSchemeCodeUnspsc190501.50523458'),
	'identifierSchemeCodeUnspsc190501.50523459': __('identifierSchemeCodeUnspsc190501.50523459'),
	'identifierSchemeCodeUnspsc190501.50523460': __('identifierSchemeCodeUnspsc190501.50523460'),
	'identifierSchemeCodeUnspsc190501.50523461': __('identifierSchemeCodeUnspsc190501.50523461'),
	'identifierSchemeCodeUnspsc190501.50523462': __('identifierSchemeCodeUnspsc190501.50523462'),
	'identifierSchemeCodeUnspsc190501.50523463': __('identifierSchemeCodeUnspsc190501.50523463'),
	'identifierSchemeCodeUnspsc190501.50523464': __('identifierSchemeCodeUnspsc190501.50523464'),
	'identifierSchemeCodeUnspsc190501.50523465': __('identifierSchemeCodeUnspsc190501.50523465'),
	'identifierSchemeCodeUnspsc190501.50523466': __('identifierSchemeCodeUnspsc190501.50523466'),
	'identifierSchemeCodeUnspsc190501.50523467': __('identifierSchemeCodeUnspsc190501.50523467'),
	'identifierSchemeCodeUnspsc190501.50523468': __('identifierSchemeCodeUnspsc190501.50523468'),
	'identifierSchemeCodeUnspsc190501.50523469': __('identifierSchemeCodeUnspsc190501.50523469'),
	'identifierSchemeCodeUnspsc190501.50523470': __('identifierSchemeCodeUnspsc190501.50523470'),
	'identifierSchemeCodeUnspsc190501.50523471': __('identifierSchemeCodeUnspsc190501.50523471'),
	'identifierSchemeCodeUnspsc190501.50523472': __('identifierSchemeCodeUnspsc190501.50523472'),
	'identifierSchemeCodeUnspsc190501.50523473': __('identifierSchemeCodeUnspsc190501.50523473'),
	'identifierSchemeCodeUnspsc190501.50523474': __('identifierSchemeCodeUnspsc190501.50523474'),
	'identifierSchemeCodeUnspsc190501.50523475': __('identifierSchemeCodeUnspsc190501.50523475'),
	'identifierSchemeCodeUnspsc190501.50523476': __('identifierSchemeCodeUnspsc190501.50523476'),
	'identifierSchemeCodeUnspsc190501.50523477': __('identifierSchemeCodeUnspsc190501.50523477'),
	'identifierSchemeCodeUnspsc190501.50523478': __('identifierSchemeCodeUnspsc190501.50523478'),
	'identifierSchemeCodeUnspsc190501.50523479': __('identifierSchemeCodeUnspsc190501.50523479'),
	'identifierSchemeCodeUnspsc190501.50523480': __('identifierSchemeCodeUnspsc190501.50523480'),
	'identifierSchemeCodeUnspsc190501.50523481': __('identifierSchemeCodeUnspsc190501.50523481'),
	'identifierSchemeCodeUnspsc190501.50523482': __('identifierSchemeCodeUnspsc190501.50523482'),
	'identifierSchemeCodeUnspsc190501.50523483': __('identifierSchemeCodeUnspsc190501.50523483'),
	'identifierSchemeCodeUnspsc190501.50523484': __('identifierSchemeCodeUnspsc190501.50523484'),
	'identifierSchemeCodeUnspsc190501.50523485': __('identifierSchemeCodeUnspsc190501.50523485'),
	'identifierSchemeCodeUnspsc190501.50523486': __('identifierSchemeCodeUnspsc190501.50523486'),
	'identifierSchemeCodeUnspsc190501.50523487': __('identifierSchemeCodeUnspsc190501.50523487'),
	'identifierSchemeCodeUnspsc190501.50523488': __('identifierSchemeCodeUnspsc190501.50523488'),
	'identifierSchemeCodeUnspsc190501.50523489': __('identifierSchemeCodeUnspsc190501.50523489'),
	'identifierSchemeCodeUnspsc190501.50523490': __('identifierSchemeCodeUnspsc190501.50523490'),
	'identifierSchemeCodeUnspsc190501.50523491': __('identifierSchemeCodeUnspsc190501.50523491'),
	'identifierSchemeCodeUnspsc190501.50523492': __('identifierSchemeCodeUnspsc190501.50523492'),
	'identifierSchemeCodeUnspsc190501.50523493': __('identifierSchemeCodeUnspsc190501.50523493'),
	'identifierSchemeCodeUnspsc190501.50523494': __('identifierSchemeCodeUnspsc190501.50523494'),
	'identifierSchemeCodeUnspsc190501.50523495': __('identifierSchemeCodeUnspsc190501.50523495'),
	'identifierSchemeCodeUnspsc190501.50523496': __('identifierSchemeCodeUnspsc190501.50523496'),
	'identifierSchemeCodeUnspsc190501.50523500': __('identifierSchemeCodeUnspsc190501.50523500'),
	'identifierSchemeCodeUnspsc190501.50523501': __('identifierSchemeCodeUnspsc190501.50523501'),
	'identifierSchemeCodeUnspsc190501.50523502': __('identifierSchemeCodeUnspsc190501.50523502'),
	'identifierSchemeCodeUnspsc190501.50523503': __('identifierSchemeCodeUnspsc190501.50523503'),
	'identifierSchemeCodeUnspsc190501.50523504': __('identifierSchemeCodeUnspsc190501.50523504'),
	'identifierSchemeCodeUnspsc190501.50523505': __('identifierSchemeCodeUnspsc190501.50523505'),
	'identifierSchemeCodeUnspsc190501.50523506': __('identifierSchemeCodeUnspsc190501.50523506'),
	'identifierSchemeCodeUnspsc190501.50523600': __('identifierSchemeCodeUnspsc190501.50523600'),
	'identifierSchemeCodeUnspsc190501.50523601': __('identifierSchemeCodeUnspsc190501.50523601'),
	'identifierSchemeCodeUnspsc190501.50523602': __('identifierSchemeCodeUnspsc190501.50523602'),
	'identifierSchemeCodeUnspsc190501.50523603': __('identifierSchemeCodeUnspsc190501.50523603'),
	'identifierSchemeCodeUnspsc190501.50523604': __('identifierSchemeCodeUnspsc190501.50523604'),
	'identifierSchemeCodeUnspsc190501.50523605': __('identifierSchemeCodeUnspsc190501.50523605'),
	'identifierSchemeCodeUnspsc190501.50523606': __('identifierSchemeCodeUnspsc190501.50523606'),
	'identifierSchemeCodeUnspsc190501.50523607': __('identifierSchemeCodeUnspsc190501.50523607'),
	'identifierSchemeCodeUnspsc190501.50523608': __('identifierSchemeCodeUnspsc190501.50523608'),
	'identifierSchemeCodeUnspsc190501.50523609': __('identifierSchemeCodeUnspsc190501.50523609'),
	'identifierSchemeCodeUnspsc190501.50523610': __('identifierSchemeCodeUnspsc190501.50523610'),
	'identifierSchemeCodeUnspsc190501.50523611': __('identifierSchemeCodeUnspsc190501.50523611'),
	'identifierSchemeCodeUnspsc190501.50523612': __('identifierSchemeCodeUnspsc190501.50523612'),
	'identifierSchemeCodeUnspsc190501.50523613': __('identifierSchemeCodeUnspsc190501.50523613'),
	'identifierSchemeCodeUnspsc190501.50523614': __('identifierSchemeCodeUnspsc190501.50523614'),
	'identifierSchemeCodeUnspsc190501.50523615': __('identifierSchemeCodeUnspsc190501.50523615'),
	'identifierSchemeCodeUnspsc190501.50523616': __('identifierSchemeCodeUnspsc190501.50523616'),
	'identifierSchemeCodeUnspsc190501.50523617': __('identifierSchemeCodeUnspsc190501.50523617'),
	'identifierSchemeCodeUnspsc190501.50523618': __('identifierSchemeCodeUnspsc190501.50523618'),
	'identifierSchemeCodeUnspsc190501.50523619': __('identifierSchemeCodeUnspsc190501.50523619'),
	'identifierSchemeCodeUnspsc190501.50523620': __('identifierSchemeCodeUnspsc190501.50523620'),
	'identifierSchemeCodeUnspsc190501.50523621': __('identifierSchemeCodeUnspsc190501.50523621'),
	'identifierSchemeCodeUnspsc190501.50523622': __('identifierSchemeCodeUnspsc190501.50523622'),
	'identifierSchemeCodeUnspsc190501.50523623': __('identifierSchemeCodeUnspsc190501.50523623'),
	'identifierSchemeCodeUnspsc190501.50523624': __('identifierSchemeCodeUnspsc190501.50523624'),
	'identifierSchemeCodeUnspsc190501.50523625': __('identifierSchemeCodeUnspsc190501.50523625'),
	'identifierSchemeCodeUnspsc190501.50523626': __('identifierSchemeCodeUnspsc190501.50523626'),
	'identifierSchemeCodeUnspsc190501.50523627': __('identifierSchemeCodeUnspsc190501.50523627'),
	'identifierSchemeCodeUnspsc190501.50523628': __('identifierSchemeCodeUnspsc190501.50523628'),
	'identifierSchemeCodeUnspsc190501.50523629': __('identifierSchemeCodeUnspsc190501.50523629'),
	'identifierSchemeCodeUnspsc190501.50523630': __('identifierSchemeCodeUnspsc190501.50523630'),
	'identifierSchemeCodeUnspsc190501.50523631': __('identifierSchemeCodeUnspsc190501.50523631'),
	'identifierSchemeCodeUnspsc190501.50523632': __('identifierSchemeCodeUnspsc190501.50523632'),
	'identifierSchemeCodeUnspsc190501.50523633': __('identifierSchemeCodeUnspsc190501.50523633'),
	'identifierSchemeCodeUnspsc190501.50523634': __('identifierSchemeCodeUnspsc190501.50523634'),
	'identifierSchemeCodeUnspsc190501.50523635': __('identifierSchemeCodeUnspsc190501.50523635'),
	'identifierSchemeCodeUnspsc190501.50523636': __('identifierSchemeCodeUnspsc190501.50523636'),
	'identifierSchemeCodeUnspsc190501.50523637': __('identifierSchemeCodeUnspsc190501.50523637'),
	'identifierSchemeCodeUnspsc190501.50523638': __('identifierSchemeCodeUnspsc190501.50523638'),
	'identifierSchemeCodeUnspsc190501.50523639': __('identifierSchemeCodeUnspsc190501.50523639'),
	'identifierSchemeCodeUnspsc190501.50523640': __('identifierSchemeCodeUnspsc190501.50523640'),
	'identifierSchemeCodeUnspsc190501.50523641': __('identifierSchemeCodeUnspsc190501.50523641'),
	'identifierSchemeCodeUnspsc190501.50523642': __('identifierSchemeCodeUnspsc190501.50523642'),
	'identifierSchemeCodeUnspsc190501.50523643': __('identifierSchemeCodeUnspsc190501.50523643'),
	'identifierSchemeCodeUnspsc190501.50523644': __('identifierSchemeCodeUnspsc190501.50523644'),
	'identifierSchemeCodeUnspsc190501.50523645': __('identifierSchemeCodeUnspsc190501.50523645'),
	'identifierSchemeCodeUnspsc190501.50523646': __('identifierSchemeCodeUnspsc190501.50523646'),
	'identifierSchemeCodeUnspsc190501.50523647': __('identifierSchemeCodeUnspsc190501.50523647'),
	'identifierSchemeCodeUnspsc190501.50523648': __('identifierSchemeCodeUnspsc190501.50523648'),
	'identifierSchemeCodeUnspsc190501.50523649': __('identifierSchemeCodeUnspsc190501.50523649'),
	'identifierSchemeCodeUnspsc190501.50523650': __('identifierSchemeCodeUnspsc190501.50523650'),
	'identifierSchemeCodeUnspsc190501.50523651': __('identifierSchemeCodeUnspsc190501.50523651'),
	'identifierSchemeCodeUnspsc190501.50523652': __('identifierSchemeCodeUnspsc190501.50523652'),
	'identifierSchemeCodeUnspsc190501.50523653': __('identifierSchemeCodeUnspsc190501.50523653'),
	'identifierSchemeCodeUnspsc190501.50523654': __('identifierSchemeCodeUnspsc190501.50523654'),
	'identifierSchemeCodeUnspsc190501.50523655': __('identifierSchemeCodeUnspsc190501.50523655'),
	'identifierSchemeCodeUnspsc190501.50523656': __('identifierSchemeCodeUnspsc190501.50523656'),
	'identifierSchemeCodeUnspsc190501.50523657': __('identifierSchemeCodeUnspsc190501.50523657'),
	'identifierSchemeCodeUnspsc190501.50523658': __('identifierSchemeCodeUnspsc190501.50523658'),
	'identifierSchemeCodeUnspsc190501.50523659': __('identifierSchemeCodeUnspsc190501.50523659'),
	'identifierSchemeCodeUnspsc190501.50523660': __('identifierSchemeCodeUnspsc190501.50523660'),
	'identifierSchemeCodeUnspsc190501.50523661': __('identifierSchemeCodeUnspsc190501.50523661'),
	'identifierSchemeCodeUnspsc190501.50523662': __('identifierSchemeCodeUnspsc190501.50523662'),
	'identifierSchemeCodeUnspsc190501.50523663': __('identifierSchemeCodeUnspsc190501.50523663'),
	'identifierSchemeCodeUnspsc190501.50523664': __('identifierSchemeCodeUnspsc190501.50523664'),
	'identifierSchemeCodeUnspsc190501.50523665': __('identifierSchemeCodeUnspsc190501.50523665'),
	'identifierSchemeCodeUnspsc190501.50523700': __('identifierSchemeCodeUnspsc190501.50523700'),
	'identifierSchemeCodeUnspsc190501.50523701': __('identifierSchemeCodeUnspsc190501.50523701'),
	'identifierSchemeCodeUnspsc190501.50523702': __('identifierSchemeCodeUnspsc190501.50523702'),
	'identifierSchemeCodeUnspsc190501.50523703': __('identifierSchemeCodeUnspsc190501.50523703'),
	'identifierSchemeCodeUnspsc190501.50523704': __('identifierSchemeCodeUnspsc190501.50523704'),
	'identifierSchemeCodeUnspsc190501.50523800': __('identifierSchemeCodeUnspsc190501.50523800'),
	'identifierSchemeCodeUnspsc190501.50523801': __('identifierSchemeCodeUnspsc190501.50523801'),
	'identifierSchemeCodeUnspsc190501.50523802': __('identifierSchemeCodeUnspsc190501.50523802'),
	'identifierSchemeCodeUnspsc190501.50523803': __('identifierSchemeCodeUnspsc190501.50523803'),
	'identifierSchemeCodeUnspsc190501.50523804': __('identifierSchemeCodeUnspsc190501.50523804'),
	'identifierSchemeCodeUnspsc190501.50523805': __('identifierSchemeCodeUnspsc190501.50523805'),
	'identifierSchemeCodeUnspsc190501.50523900': __('identifierSchemeCodeUnspsc190501.50523900'),
	'identifierSchemeCodeUnspsc190501.50523901': __('identifierSchemeCodeUnspsc190501.50523901'),
	'identifierSchemeCodeUnspsc190501.50523902': __('identifierSchemeCodeUnspsc190501.50523902'),
	'identifierSchemeCodeUnspsc190501.50523903': __('identifierSchemeCodeUnspsc190501.50523903'),
	'identifierSchemeCodeUnspsc190501.50523904': __('identifierSchemeCodeUnspsc190501.50523904'),
	'identifierSchemeCodeUnspsc190501.50523905': __('identifierSchemeCodeUnspsc190501.50523905'),
	'identifierSchemeCodeUnspsc190501.50523906': __('identifierSchemeCodeUnspsc190501.50523906'),
	'identifierSchemeCodeUnspsc190501.50524000': __('identifierSchemeCodeUnspsc190501.50524000'),
	'identifierSchemeCodeUnspsc190501.50524001': __('identifierSchemeCodeUnspsc190501.50524001'),
	'identifierSchemeCodeUnspsc190501.50524002': __('identifierSchemeCodeUnspsc190501.50524002'),
	'identifierSchemeCodeUnspsc190501.50524003': __('identifierSchemeCodeUnspsc190501.50524003'),
	'identifierSchemeCodeUnspsc190501.50524004': __('identifierSchemeCodeUnspsc190501.50524004'),
	'identifierSchemeCodeUnspsc190501.50524005': __('identifierSchemeCodeUnspsc190501.50524005'),
	'identifierSchemeCodeUnspsc190501.50524006': __('identifierSchemeCodeUnspsc190501.50524006'),
	'identifierSchemeCodeUnspsc190501.50524100': __('identifierSchemeCodeUnspsc190501.50524100'),
	'identifierSchemeCodeUnspsc190501.50524101': __('identifierSchemeCodeUnspsc190501.50524101'),
	'identifierSchemeCodeUnspsc190501.50524102': __('identifierSchemeCodeUnspsc190501.50524102'),
	'identifierSchemeCodeUnspsc190501.50524103': __('identifierSchemeCodeUnspsc190501.50524103'),
	'identifierSchemeCodeUnspsc190501.50524104': __('identifierSchemeCodeUnspsc190501.50524104'),
	'identifierSchemeCodeUnspsc190501.50524105': __('identifierSchemeCodeUnspsc190501.50524105'),
	'identifierSchemeCodeUnspsc190501.50524106': __('identifierSchemeCodeUnspsc190501.50524106'),
	'identifierSchemeCodeUnspsc190501.50524107': __('identifierSchemeCodeUnspsc190501.50524107'),
	'identifierSchemeCodeUnspsc190501.50524108': __('identifierSchemeCodeUnspsc190501.50524108'),
	'identifierSchemeCodeUnspsc190501.50524200': __('identifierSchemeCodeUnspsc190501.50524200'),
	'identifierSchemeCodeUnspsc190501.50524201': __('identifierSchemeCodeUnspsc190501.50524201'),
	'identifierSchemeCodeUnspsc190501.50524202': __('identifierSchemeCodeUnspsc190501.50524202'),
	'identifierSchemeCodeUnspsc190501.50524203': __('identifierSchemeCodeUnspsc190501.50524203'),
	'identifierSchemeCodeUnspsc190501.50524204': __('identifierSchemeCodeUnspsc190501.50524204'),
	'identifierSchemeCodeUnspsc190501.50524205': __('identifierSchemeCodeUnspsc190501.50524205'),
	'identifierSchemeCodeUnspsc190501.50524206': __('identifierSchemeCodeUnspsc190501.50524206'),
	'identifierSchemeCodeUnspsc190501.50524207': __('identifierSchemeCodeUnspsc190501.50524207'),
	'identifierSchemeCodeUnspsc190501.50524208': __('identifierSchemeCodeUnspsc190501.50524208'),
	'identifierSchemeCodeUnspsc190501.50524300': __('identifierSchemeCodeUnspsc190501.50524300'),
	'identifierSchemeCodeUnspsc190501.50524301': __('identifierSchemeCodeUnspsc190501.50524301'),
	'identifierSchemeCodeUnspsc190501.50524302': __('identifierSchemeCodeUnspsc190501.50524302'),
	'identifierSchemeCodeUnspsc190501.50524303': __('identifierSchemeCodeUnspsc190501.50524303'),
	'identifierSchemeCodeUnspsc190501.50524304': __('identifierSchemeCodeUnspsc190501.50524304'),
	'identifierSchemeCodeUnspsc190501.50524305': __('identifierSchemeCodeUnspsc190501.50524305'),
	'identifierSchemeCodeUnspsc190501.50524306': __('identifierSchemeCodeUnspsc190501.50524306'),
	'identifierSchemeCodeUnspsc190501.50524307': __('identifierSchemeCodeUnspsc190501.50524307'),
	'identifierSchemeCodeUnspsc190501.50524308': __('identifierSchemeCodeUnspsc190501.50524308'),
	'identifierSchemeCodeUnspsc190501.50524309': __('identifierSchemeCodeUnspsc190501.50524309'),
	'identifierSchemeCodeUnspsc190501.50524310': __('identifierSchemeCodeUnspsc190501.50524310'),
	'identifierSchemeCodeUnspsc190501.50524311': __('identifierSchemeCodeUnspsc190501.50524311'),
	'identifierSchemeCodeUnspsc190501.50524312': __('identifierSchemeCodeUnspsc190501.50524312'),
	'identifierSchemeCodeUnspsc190501.50524313': __('identifierSchemeCodeUnspsc190501.50524313'),
	'identifierSchemeCodeUnspsc190501.50524400': __('identifierSchemeCodeUnspsc190501.50524400'),
	'identifierSchemeCodeUnspsc190501.50524401': __('identifierSchemeCodeUnspsc190501.50524401'),
	'identifierSchemeCodeUnspsc190501.50524402': __('identifierSchemeCodeUnspsc190501.50524402'),
	'identifierSchemeCodeUnspsc190501.50524403': __('identifierSchemeCodeUnspsc190501.50524403'),
	'identifierSchemeCodeUnspsc190501.50524404': __('identifierSchemeCodeUnspsc190501.50524404'),
	'identifierSchemeCodeUnspsc190501.50524405': __('identifierSchemeCodeUnspsc190501.50524405'),
	'identifierSchemeCodeUnspsc190501.50524406': __('identifierSchemeCodeUnspsc190501.50524406'),
	'identifierSchemeCodeUnspsc190501.50524407': __('identifierSchemeCodeUnspsc190501.50524407'),
	'identifierSchemeCodeUnspsc190501.50524408': __('identifierSchemeCodeUnspsc190501.50524408'),
	'identifierSchemeCodeUnspsc190501.50524409': __('identifierSchemeCodeUnspsc190501.50524409'),
	'identifierSchemeCodeUnspsc190501.50524410': __('identifierSchemeCodeUnspsc190501.50524410'),
	'identifierSchemeCodeUnspsc190501.50524411': __('identifierSchemeCodeUnspsc190501.50524411'),
	'identifierSchemeCodeUnspsc190501.50524412': __('identifierSchemeCodeUnspsc190501.50524412'),
	'identifierSchemeCodeUnspsc190501.50524413': __('identifierSchemeCodeUnspsc190501.50524413'),
	'identifierSchemeCodeUnspsc190501.50524414': __('identifierSchemeCodeUnspsc190501.50524414'),
	'identifierSchemeCodeUnspsc190501.50524415': __('identifierSchemeCodeUnspsc190501.50524415'),
	'identifierSchemeCodeUnspsc190501.50524416': __('identifierSchemeCodeUnspsc190501.50524416'),
	'identifierSchemeCodeUnspsc190501.50524417': __('identifierSchemeCodeUnspsc190501.50524417'),
	'identifierSchemeCodeUnspsc190501.50524418': __('identifierSchemeCodeUnspsc190501.50524418'),
	'identifierSchemeCodeUnspsc190501.50524419': __('identifierSchemeCodeUnspsc190501.50524419'),
	'identifierSchemeCodeUnspsc190501.50524420': __('identifierSchemeCodeUnspsc190501.50524420'),
	'identifierSchemeCodeUnspsc190501.50524421': __('identifierSchemeCodeUnspsc190501.50524421'),
	'identifierSchemeCodeUnspsc190501.50524422': __('identifierSchemeCodeUnspsc190501.50524422'),
	'identifierSchemeCodeUnspsc190501.50524423': __('identifierSchemeCodeUnspsc190501.50524423'),
	'identifierSchemeCodeUnspsc190501.50524424': __('identifierSchemeCodeUnspsc190501.50524424'),
	'identifierSchemeCodeUnspsc190501.50524425': __('identifierSchemeCodeUnspsc190501.50524425'),
	'identifierSchemeCodeUnspsc190501.50524426': __('identifierSchemeCodeUnspsc190501.50524426'),
	'identifierSchemeCodeUnspsc190501.50524427': __('identifierSchemeCodeUnspsc190501.50524427'),
	'identifierSchemeCodeUnspsc190501.50524428': __('identifierSchemeCodeUnspsc190501.50524428'),
	'identifierSchemeCodeUnspsc190501.50524429': __('identifierSchemeCodeUnspsc190501.50524429'),
	'identifierSchemeCodeUnspsc190501.50524430': __('identifierSchemeCodeUnspsc190501.50524430'),
	'identifierSchemeCodeUnspsc190501.50524431': __('identifierSchemeCodeUnspsc190501.50524431'),
	'identifierSchemeCodeUnspsc190501.50524432': __('identifierSchemeCodeUnspsc190501.50524432'),
	'identifierSchemeCodeUnspsc190501.50524433': __('identifierSchemeCodeUnspsc190501.50524433'),
	'identifierSchemeCodeUnspsc190501.50524434': __('identifierSchemeCodeUnspsc190501.50524434'),
	'identifierSchemeCodeUnspsc190501.50524435': __('identifierSchemeCodeUnspsc190501.50524435'),
	'identifierSchemeCodeUnspsc190501.50524436': __('identifierSchemeCodeUnspsc190501.50524436'),
	'identifierSchemeCodeUnspsc190501.50524437': __('identifierSchemeCodeUnspsc190501.50524437'),
	'identifierSchemeCodeUnspsc190501.50524438': __('identifierSchemeCodeUnspsc190501.50524438'),
	'identifierSchemeCodeUnspsc190501.50524439': __('identifierSchemeCodeUnspsc190501.50524439'),
	'identifierSchemeCodeUnspsc190501.50524440': __('identifierSchemeCodeUnspsc190501.50524440'),
	'identifierSchemeCodeUnspsc190501.50524441': __('identifierSchemeCodeUnspsc190501.50524441'),
	'identifierSchemeCodeUnspsc190501.50524442': __('identifierSchemeCodeUnspsc190501.50524442'),
	'identifierSchemeCodeUnspsc190501.50524443': __('identifierSchemeCodeUnspsc190501.50524443'),
	'identifierSchemeCodeUnspsc190501.50524444': __('identifierSchemeCodeUnspsc190501.50524444'),
	'identifierSchemeCodeUnspsc190501.50524445': __('identifierSchemeCodeUnspsc190501.50524445'),
	'identifierSchemeCodeUnspsc190501.50524446': __('identifierSchemeCodeUnspsc190501.50524446'),
	'identifierSchemeCodeUnspsc190501.50524500': __('identifierSchemeCodeUnspsc190501.50524500'),
	'identifierSchemeCodeUnspsc190501.50524501': __('identifierSchemeCodeUnspsc190501.50524501'),
	'identifierSchemeCodeUnspsc190501.50524502': __('identifierSchemeCodeUnspsc190501.50524502'),
	'identifierSchemeCodeUnspsc190501.50524503': __('identifierSchemeCodeUnspsc190501.50524503'),
	'identifierSchemeCodeUnspsc190501.50524504': __('identifierSchemeCodeUnspsc190501.50524504'),
	'identifierSchemeCodeUnspsc190501.50524505': __('identifierSchemeCodeUnspsc190501.50524505'),
	'identifierSchemeCodeUnspsc190501.50524506': __('identifierSchemeCodeUnspsc190501.50524506'),
	'identifierSchemeCodeUnspsc190501.50524507': __('identifierSchemeCodeUnspsc190501.50524507'),
	'identifierSchemeCodeUnspsc190501.50524508': __('identifierSchemeCodeUnspsc190501.50524508'),
	'identifierSchemeCodeUnspsc190501.50524509': __('identifierSchemeCodeUnspsc190501.50524509'),
	'identifierSchemeCodeUnspsc190501.50524510': __('identifierSchemeCodeUnspsc190501.50524510'),
	'identifierSchemeCodeUnspsc190501.50524511': __('identifierSchemeCodeUnspsc190501.50524511'),
	'identifierSchemeCodeUnspsc190501.50524512': __('identifierSchemeCodeUnspsc190501.50524512'),
	'identifierSchemeCodeUnspsc190501.50524513': __('identifierSchemeCodeUnspsc190501.50524513'),
	'identifierSchemeCodeUnspsc190501.50524514': __('identifierSchemeCodeUnspsc190501.50524514'),
	'identifierSchemeCodeUnspsc190501.50524515': __('identifierSchemeCodeUnspsc190501.50524515'),
	'identifierSchemeCodeUnspsc190501.50524516': __('identifierSchemeCodeUnspsc190501.50524516'),
	'identifierSchemeCodeUnspsc190501.50524517': __('identifierSchemeCodeUnspsc190501.50524517'),
	'identifierSchemeCodeUnspsc190501.50524518': __('identifierSchemeCodeUnspsc190501.50524518'),
	'identifierSchemeCodeUnspsc190501.50524519': __('identifierSchemeCodeUnspsc190501.50524519'),
	'identifierSchemeCodeUnspsc190501.50524520': __('identifierSchemeCodeUnspsc190501.50524520'),
	'identifierSchemeCodeUnspsc190501.50524521': __('identifierSchemeCodeUnspsc190501.50524521'),
	'identifierSchemeCodeUnspsc190501.50524522': __('identifierSchemeCodeUnspsc190501.50524522'),
	'identifierSchemeCodeUnspsc190501.50524523': __('identifierSchemeCodeUnspsc190501.50524523'),
	'identifierSchemeCodeUnspsc190501.50524524': __('identifierSchemeCodeUnspsc190501.50524524'),
	'identifierSchemeCodeUnspsc190501.50524525': __('identifierSchemeCodeUnspsc190501.50524525'),
	'identifierSchemeCodeUnspsc190501.50524600': __('identifierSchemeCodeUnspsc190501.50524600'),
	'identifierSchemeCodeUnspsc190501.50524601': __('identifierSchemeCodeUnspsc190501.50524601'),
	'identifierSchemeCodeUnspsc190501.50524602': __('identifierSchemeCodeUnspsc190501.50524602'),
	'identifierSchemeCodeUnspsc190501.50524603': __('identifierSchemeCodeUnspsc190501.50524603'),
	'identifierSchemeCodeUnspsc190501.50524604': __('identifierSchemeCodeUnspsc190501.50524604'),
	'identifierSchemeCodeUnspsc190501.50524605': __('identifierSchemeCodeUnspsc190501.50524605'),
	'identifierSchemeCodeUnspsc190501.50524606': __('identifierSchemeCodeUnspsc190501.50524606'),
	'identifierSchemeCodeUnspsc190501.50524607': __('identifierSchemeCodeUnspsc190501.50524607'),
	'identifierSchemeCodeUnspsc190501.50524608': __('identifierSchemeCodeUnspsc190501.50524608'),
	'identifierSchemeCodeUnspsc190501.50524609': __('identifierSchemeCodeUnspsc190501.50524609'),
	'identifierSchemeCodeUnspsc190501.50524610': __('identifierSchemeCodeUnspsc190501.50524610'),
	'identifierSchemeCodeUnspsc190501.50524611': __('identifierSchemeCodeUnspsc190501.50524611'),
	'identifierSchemeCodeUnspsc190501.50524612': __('identifierSchemeCodeUnspsc190501.50524612'),
	'identifierSchemeCodeUnspsc190501.50524613': __('identifierSchemeCodeUnspsc190501.50524613'),
	'identifierSchemeCodeUnspsc190501.50524614': __('identifierSchemeCodeUnspsc190501.50524614'),
	'identifierSchemeCodeUnspsc190501.50524615': __('identifierSchemeCodeUnspsc190501.50524615'),
	'identifierSchemeCodeUnspsc190501.50524616': __('identifierSchemeCodeUnspsc190501.50524616'),
	'identifierSchemeCodeUnspsc190501.50524617': __('identifierSchemeCodeUnspsc190501.50524617'),
	'identifierSchemeCodeUnspsc190501.50524618': __('identifierSchemeCodeUnspsc190501.50524618'),
	'identifierSchemeCodeUnspsc190501.50524619': __('identifierSchemeCodeUnspsc190501.50524619'),
	'identifierSchemeCodeUnspsc190501.50524620': __('identifierSchemeCodeUnspsc190501.50524620'),
	'identifierSchemeCodeUnspsc190501.50524621': __('identifierSchemeCodeUnspsc190501.50524621'),
	'identifierSchemeCodeUnspsc190501.50524622': __('identifierSchemeCodeUnspsc190501.50524622'),
	'identifierSchemeCodeUnspsc190501.50524623': __('identifierSchemeCodeUnspsc190501.50524623'),
	'identifierSchemeCodeUnspsc190501.50524624': __('identifierSchemeCodeUnspsc190501.50524624'),
	'identifierSchemeCodeUnspsc190501.50524625': __('identifierSchemeCodeUnspsc190501.50524625'),
	'identifierSchemeCodeUnspsc190501.50524626': __('identifierSchemeCodeUnspsc190501.50524626'),
	'identifierSchemeCodeUnspsc190501.50524627': __('identifierSchemeCodeUnspsc190501.50524627'),
	'identifierSchemeCodeUnspsc190501.50524628': __('identifierSchemeCodeUnspsc190501.50524628'),
	'identifierSchemeCodeUnspsc190501.50524629': __('identifierSchemeCodeUnspsc190501.50524629'),
	'identifierSchemeCodeUnspsc190501.50524630': __('identifierSchemeCodeUnspsc190501.50524630'),
	'identifierSchemeCodeUnspsc190501.50524631': __('identifierSchemeCodeUnspsc190501.50524631'),
	'identifierSchemeCodeUnspsc190501.50524632': __('identifierSchemeCodeUnspsc190501.50524632'),
	'identifierSchemeCodeUnspsc190501.50524633': __('identifierSchemeCodeUnspsc190501.50524633'),
	'identifierSchemeCodeUnspsc190501.50524634': __('identifierSchemeCodeUnspsc190501.50524634'),
	'identifierSchemeCodeUnspsc190501.50524635': __('identifierSchemeCodeUnspsc190501.50524635'),
	'identifierSchemeCodeUnspsc190501.50524636': __('identifierSchemeCodeUnspsc190501.50524636'),
	'identifierSchemeCodeUnspsc190501.50524637': __('identifierSchemeCodeUnspsc190501.50524637'),
	'identifierSchemeCodeUnspsc190501.50524638': __('identifierSchemeCodeUnspsc190501.50524638'),
	'identifierSchemeCodeUnspsc190501.50524639': __('identifierSchemeCodeUnspsc190501.50524639'),
	'identifierSchemeCodeUnspsc190501.50524640': __('identifierSchemeCodeUnspsc190501.50524640'),
	'identifierSchemeCodeUnspsc190501.50524641': __('identifierSchemeCodeUnspsc190501.50524641'),
	'identifierSchemeCodeUnspsc190501.50524642': __('identifierSchemeCodeUnspsc190501.50524642'),
	'identifierSchemeCodeUnspsc190501.50524643': __('identifierSchemeCodeUnspsc190501.50524643'),
	'identifierSchemeCodeUnspsc190501.50524644': __('identifierSchemeCodeUnspsc190501.50524644'),
	'identifierSchemeCodeUnspsc190501.50524645': __('identifierSchemeCodeUnspsc190501.50524645'),
	'identifierSchemeCodeUnspsc190501.50524646': __('identifierSchemeCodeUnspsc190501.50524646'),
	'identifierSchemeCodeUnspsc190501.50524647': __('identifierSchemeCodeUnspsc190501.50524647'),
	'identifierSchemeCodeUnspsc190501.50524648': __('identifierSchemeCodeUnspsc190501.50524648'),
	'identifierSchemeCodeUnspsc190501.50524649': __('identifierSchemeCodeUnspsc190501.50524649'),
	'identifierSchemeCodeUnspsc190501.50524650': __('identifierSchemeCodeUnspsc190501.50524650'),
	'identifierSchemeCodeUnspsc190501.50524651': __('identifierSchemeCodeUnspsc190501.50524651'),
	'identifierSchemeCodeUnspsc190501.50524652': __('identifierSchemeCodeUnspsc190501.50524652'),
	'identifierSchemeCodeUnspsc190501.50524653': __('identifierSchemeCodeUnspsc190501.50524653'),
	'identifierSchemeCodeUnspsc190501.50524654': __('identifierSchemeCodeUnspsc190501.50524654'),
	'identifierSchemeCodeUnspsc190501.50524655': __('identifierSchemeCodeUnspsc190501.50524655'),
	'identifierSchemeCodeUnspsc190501.50524656': __('identifierSchemeCodeUnspsc190501.50524656'),
	'identifierSchemeCodeUnspsc190501.50524657': __('identifierSchemeCodeUnspsc190501.50524657'),
	'identifierSchemeCodeUnspsc190501.50524658': __('identifierSchemeCodeUnspsc190501.50524658'),
	'identifierSchemeCodeUnspsc190501.50524659': __('identifierSchemeCodeUnspsc190501.50524659'),
	'identifierSchemeCodeUnspsc190501.50524660': __('identifierSchemeCodeUnspsc190501.50524660'),
	'identifierSchemeCodeUnspsc190501.50524661': __('identifierSchemeCodeUnspsc190501.50524661'),
	'identifierSchemeCodeUnspsc190501.50524700': __('identifierSchemeCodeUnspsc190501.50524700'),
	'identifierSchemeCodeUnspsc190501.50524701': __('identifierSchemeCodeUnspsc190501.50524701'),
	'identifierSchemeCodeUnspsc190501.50524702': __('identifierSchemeCodeUnspsc190501.50524702'),
	'identifierSchemeCodeUnspsc190501.50524800': __('identifierSchemeCodeUnspsc190501.50524800'),
	'identifierSchemeCodeUnspsc190501.50524801': __('identifierSchemeCodeUnspsc190501.50524801'),
	'identifierSchemeCodeUnspsc190501.50524802': __('identifierSchemeCodeUnspsc190501.50524802'),
	'identifierSchemeCodeUnspsc190501.50524900': __('identifierSchemeCodeUnspsc190501.50524900'),
	'identifierSchemeCodeUnspsc190501.50524901': __('identifierSchemeCodeUnspsc190501.50524901'),
	'identifierSchemeCodeUnspsc190501.50524902': __('identifierSchemeCodeUnspsc190501.50524902'),
	'identifierSchemeCodeUnspsc190501.50524903': __('identifierSchemeCodeUnspsc190501.50524903'),
	'identifierSchemeCodeUnspsc190501.50524904': __('identifierSchemeCodeUnspsc190501.50524904'),
	'identifierSchemeCodeUnspsc190501.50524905': __('identifierSchemeCodeUnspsc190501.50524905'),
	'identifierSchemeCodeUnspsc190501.50524906': __('identifierSchemeCodeUnspsc190501.50524906'),
	'identifierSchemeCodeUnspsc190501.50524907': __('identifierSchemeCodeUnspsc190501.50524907'),
	'identifierSchemeCodeUnspsc190501.50524908': __('identifierSchemeCodeUnspsc190501.50524908'),
	'identifierSchemeCodeUnspsc190501.50524909': __('identifierSchemeCodeUnspsc190501.50524909'),
	'identifierSchemeCodeUnspsc190501.50524910': __('identifierSchemeCodeUnspsc190501.50524910'),
	'identifierSchemeCodeUnspsc190501.50524911': __('identifierSchemeCodeUnspsc190501.50524911'),
	'identifierSchemeCodeUnspsc190501.50524912': __('identifierSchemeCodeUnspsc190501.50524912'),
	'identifierSchemeCodeUnspsc190501.50524913': __('identifierSchemeCodeUnspsc190501.50524913'),
	'identifierSchemeCodeUnspsc190501.50524914': __('identifierSchemeCodeUnspsc190501.50524914'),
	'identifierSchemeCodeUnspsc190501.50524915': __('identifierSchemeCodeUnspsc190501.50524915'),
	'identifierSchemeCodeUnspsc190501.50524916': __('identifierSchemeCodeUnspsc190501.50524916'),
	'identifierSchemeCodeUnspsc190501.50524917': __('identifierSchemeCodeUnspsc190501.50524917'),
	'identifierSchemeCodeUnspsc190501.50524918': __('identifierSchemeCodeUnspsc190501.50524918'),
	'identifierSchemeCodeUnspsc190501.50524919': __('identifierSchemeCodeUnspsc190501.50524919'),
	'identifierSchemeCodeUnspsc190501.50524920': __('identifierSchemeCodeUnspsc190501.50524920'),
	'identifierSchemeCodeUnspsc190501.50524921': __('identifierSchemeCodeUnspsc190501.50524921'),
	'identifierSchemeCodeUnspsc190501.50524922': __('identifierSchemeCodeUnspsc190501.50524922'),
	'identifierSchemeCodeUnspsc190501.50524923': __('identifierSchemeCodeUnspsc190501.50524923'),
	'identifierSchemeCodeUnspsc190501.50524924': __('identifierSchemeCodeUnspsc190501.50524924'),
	'identifierSchemeCodeUnspsc190501.50524925': __('identifierSchemeCodeUnspsc190501.50524925'),
	'identifierSchemeCodeUnspsc190501.50524926': __('identifierSchemeCodeUnspsc190501.50524926'),
	'identifierSchemeCodeUnspsc190501.50524927': __('identifierSchemeCodeUnspsc190501.50524927'),
	'identifierSchemeCodeUnspsc190501.50524928': __('identifierSchemeCodeUnspsc190501.50524928'),
	'identifierSchemeCodeUnspsc190501.50524929': __('identifierSchemeCodeUnspsc190501.50524929'),
	'identifierSchemeCodeUnspsc190501.50524930': __('identifierSchemeCodeUnspsc190501.50524930'),
	'identifierSchemeCodeUnspsc190501.50524931': __('identifierSchemeCodeUnspsc190501.50524931'),
	'identifierSchemeCodeUnspsc190501.50524932': __('identifierSchemeCodeUnspsc190501.50524932'),
	'identifierSchemeCodeUnspsc190501.50524933': __('identifierSchemeCodeUnspsc190501.50524933'),
	'identifierSchemeCodeUnspsc190501.50524934': __('identifierSchemeCodeUnspsc190501.50524934'),
	'identifierSchemeCodeUnspsc190501.50524935': __('identifierSchemeCodeUnspsc190501.50524935'),
	'identifierSchemeCodeUnspsc190501.50524936': __('identifierSchemeCodeUnspsc190501.50524936'),
	'identifierSchemeCodeUnspsc190501.50524937': __('identifierSchemeCodeUnspsc190501.50524937'),
	'identifierSchemeCodeUnspsc190501.50524938': __('identifierSchemeCodeUnspsc190501.50524938'),
	'identifierSchemeCodeUnspsc190501.50524939': __('identifierSchemeCodeUnspsc190501.50524939'),
	'identifierSchemeCodeUnspsc190501.50524940': __('identifierSchemeCodeUnspsc190501.50524940'),
	'identifierSchemeCodeUnspsc190501.50524941': __('identifierSchemeCodeUnspsc190501.50524941'),
	'identifierSchemeCodeUnspsc190501.50524942': __('identifierSchemeCodeUnspsc190501.50524942'),
	'identifierSchemeCodeUnspsc190501.50524943': __('identifierSchemeCodeUnspsc190501.50524943'),
	'identifierSchemeCodeUnspsc190501.50524944': __('identifierSchemeCodeUnspsc190501.50524944'),
	'identifierSchemeCodeUnspsc190501.50524945': __('identifierSchemeCodeUnspsc190501.50524945'),
	'identifierSchemeCodeUnspsc190501.50524946': __('identifierSchemeCodeUnspsc190501.50524946'),
	'identifierSchemeCodeUnspsc190501.50524947': __('identifierSchemeCodeUnspsc190501.50524947'),
	'identifierSchemeCodeUnspsc190501.50524948': __('identifierSchemeCodeUnspsc190501.50524948'),
	'identifierSchemeCodeUnspsc190501.50524949': __('identifierSchemeCodeUnspsc190501.50524949'),
	'identifierSchemeCodeUnspsc190501.50524950': __('identifierSchemeCodeUnspsc190501.50524950'),
	'identifierSchemeCodeUnspsc190501.50524951': __('identifierSchemeCodeUnspsc190501.50524951'),
	'identifierSchemeCodeUnspsc190501.50524952': __('identifierSchemeCodeUnspsc190501.50524952'),
	'identifierSchemeCodeUnspsc190501.50524953': __('identifierSchemeCodeUnspsc190501.50524953'),
	'identifierSchemeCodeUnspsc190501.50524954': __('identifierSchemeCodeUnspsc190501.50524954'),
	'identifierSchemeCodeUnspsc190501.50524955': __('identifierSchemeCodeUnspsc190501.50524955'),
	'identifierSchemeCodeUnspsc190501.50524956': __('identifierSchemeCodeUnspsc190501.50524956'),
	'identifierSchemeCodeUnspsc190501.50524957': __('identifierSchemeCodeUnspsc190501.50524957'),
	'identifierSchemeCodeUnspsc190501.50525000': __('identifierSchemeCodeUnspsc190501.50525000'),
	'identifierSchemeCodeUnspsc190501.50525001': __('identifierSchemeCodeUnspsc190501.50525001'),
	'identifierSchemeCodeUnspsc190501.50525002': __('identifierSchemeCodeUnspsc190501.50525002'),
	'identifierSchemeCodeUnspsc190501.50525003': __('identifierSchemeCodeUnspsc190501.50525003'),
	'identifierSchemeCodeUnspsc190501.50525004': __('identifierSchemeCodeUnspsc190501.50525004'),
	'identifierSchemeCodeUnspsc190501.50525005': __('identifierSchemeCodeUnspsc190501.50525005'),
	'identifierSchemeCodeUnspsc190501.50525006': __('identifierSchemeCodeUnspsc190501.50525006'),
	'identifierSchemeCodeUnspsc190501.50525007': __('identifierSchemeCodeUnspsc190501.50525007'),
	'identifierSchemeCodeUnspsc190501.50525008': __('identifierSchemeCodeUnspsc190501.50525008'),
	'identifierSchemeCodeUnspsc190501.50525009': __('identifierSchemeCodeUnspsc190501.50525009'),
	'identifierSchemeCodeUnspsc190501.50525010': __('identifierSchemeCodeUnspsc190501.50525010'),
	'identifierSchemeCodeUnspsc190501.50525011': __('identifierSchemeCodeUnspsc190501.50525011'),
	'identifierSchemeCodeUnspsc190501.50525012': __('identifierSchemeCodeUnspsc190501.50525012'),
	'identifierSchemeCodeUnspsc190501.50525013': __('identifierSchemeCodeUnspsc190501.50525013'),
	'identifierSchemeCodeUnspsc190501.50525014': __('identifierSchemeCodeUnspsc190501.50525014'),
	'identifierSchemeCodeUnspsc190501.50525015': __('identifierSchemeCodeUnspsc190501.50525015'),
	'identifierSchemeCodeUnspsc190501.50525016': __('identifierSchemeCodeUnspsc190501.50525016'),
	'identifierSchemeCodeUnspsc190501.50525017': __('identifierSchemeCodeUnspsc190501.50525017'),
	'identifierSchemeCodeUnspsc190501.50525018': __('identifierSchemeCodeUnspsc190501.50525018'),
	'identifierSchemeCodeUnspsc190501.50525019': __('identifierSchemeCodeUnspsc190501.50525019'),
	'identifierSchemeCodeUnspsc190501.50525020': __('identifierSchemeCodeUnspsc190501.50525020'),
	'identifierSchemeCodeUnspsc190501.50525021': __('identifierSchemeCodeUnspsc190501.50525021'),
	'identifierSchemeCodeUnspsc190501.50525022': __('identifierSchemeCodeUnspsc190501.50525022'),
	'identifierSchemeCodeUnspsc190501.50525023': __('identifierSchemeCodeUnspsc190501.50525023'),
	'identifierSchemeCodeUnspsc190501.50525024': __('identifierSchemeCodeUnspsc190501.50525024'),
	'identifierSchemeCodeUnspsc190501.50525025': __('identifierSchemeCodeUnspsc190501.50525025'),
	'identifierSchemeCodeUnspsc190501.50525026': __('identifierSchemeCodeUnspsc190501.50525026'),
	'identifierSchemeCodeUnspsc190501.50525027': __('identifierSchemeCodeUnspsc190501.50525027'),
	'identifierSchemeCodeUnspsc190501.50525028': __('identifierSchemeCodeUnspsc190501.50525028'),
	'identifierSchemeCodeUnspsc190501.50525029': __('identifierSchemeCodeUnspsc190501.50525029'),
	'identifierSchemeCodeUnspsc190501.50525030': __('identifierSchemeCodeUnspsc190501.50525030'),
	'identifierSchemeCodeUnspsc190501.50525031': __('identifierSchemeCodeUnspsc190501.50525031'),
	'identifierSchemeCodeUnspsc190501.50525032': __('identifierSchemeCodeUnspsc190501.50525032'),
	'identifierSchemeCodeUnspsc190501.50525033': __('identifierSchemeCodeUnspsc190501.50525033'),
	'identifierSchemeCodeUnspsc190501.50525034': __('identifierSchemeCodeUnspsc190501.50525034'),
	'identifierSchemeCodeUnspsc190501.50525035': __('identifierSchemeCodeUnspsc190501.50525035'),
	'identifierSchemeCodeUnspsc190501.50525036': __('identifierSchemeCodeUnspsc190501.50525036'),
	'identifierSchemeCodeUnspsc190501.50525037': __('identifierSchemeCodeUnspsc190501.50525037'),
	'identifierSchemeCodeUnspsc190501.50525038': __('identifierSchemeCodeUnspsc190501.50525038'),
	'identifierSchemeCodeUnspsc190501.50525039': __('identifierSchemeCodeUnspsc190501.50525039'),
	'identifierSchemeCodeUnspsc190501.50525040': __('identifierSchemeCodeUnspsc190501.50525040'),
	'identifierSchemeCodeUnspsc190501.50525041': __('identifierSchemeCodeUnspsc190501.50525041'),
	'identifierSchemeCodeUnspsc190501.50525042': __('identifierSchemeCodeUnspsc190501.50525042'),
	'identifierSchemeCodeUnspsc190501.50525043': __('identifierSchemeCodeUnspsc190501.50525043'),
	'identifierSchemeCodeUnspsc190501.50525044': __('identifierSchemeCodeUnspsc190501.50525044'),
	'identifierSchemeCodeUnspsc190501.50525100': __('identifierSchemeCodeUnspsc190501.50525100'),
	'identifierSchemeCodeUnspsc190501.50525101': __('identifierSchemeCodeUnspsc190501.50525101'),
	'identifierSchemeCodeUnspsc190501.50525102': __('identifierSchemeCodeUnspsc190501.50525102'),
	'identifierSchemeCodeUnspsc190501.50525103': __('identifierSchemeCodeUnspsc190501.50525103'),
	'identifierSchemeCodeUnspsc190501.50525104': __('identifierSchemeCodeUnspsc190501.50525104'),
	'identifierSchemeCodeUnspsc190501.50525105': __('identifierSchemeCodeUnspsc190501.50525105'),
	'identifierSchemeCodeUnspsc190501.50525106': __('identifierSchemeCodeUnspsc190501.50525106'),
	'identifierSchemeCodeUnspsc190501.50525200': __('identifierSchemeCodeUnspsc190501.50525200'),
	'identifierSchemeCodeUnspsc190501.50525201': __('identifierSchemeCodeUnspsc190501.50525201'),
	'identifierSchemeCodeUnspsc190501.50525202': __('identifierSchemeCodeUnspsc190501.50525202'),
	'identifierSchemeCodeUnspsc190501.50525203': __('identifierSchemeCodeUnspsc190501.50525203'),
	'identifierSchemeCodeUnspsc190501.50525204': __('identifierSchemeCodeUnspsc190501.50525204'),
	'identifierSchemeCodeUnspsc190501.50525205': __('identifierSchemeCodeUnspsc190501.50525205'),
	'identifierSchemeCodeUnspsc190501.50525206': __('identifierSchemeCodeUnspsc190501.50525206'),
	'identifierSchemeCodeUnspsc190501.50525207': __('identifierSchemeCodeUnspsc190501.50525207'),
	'identifierSchemeCodeUnspsc190501.50525208': __('identifierSchemeCodeUnspsc190501.50525208'),
	'identifierSchemeCodeUnspsc190501.50525209': __('identifierSchemeCodeUnspsc190501.50525209'),
	'identifierSchemeCodeUnspsc190501.50525210': __('identifierSchemeCodeUnspsc190501.50525210'),
	'identifierSchemeCodeUnspsc190501.50525300': __('identifierSchemeCodeUnspsc190501.50525300'),
	'identifierSchemeCodeUnspsc190501.50525301': __('identifierSchemeCodeUnspsc190501.50525301'),
	'identifierSchemeCodeUnspsc190501.50525302': __('identifierSchemeCodeUnspsc190501.50525302'),
	'identifierSchemeCodeUnspsc190501.50525303': __('identifierSchemeCodeUnspsc190501.50525303'),
	'identifierSchemeCodeUnspsc190501.50525304': __('identifierSchemeCodeUnspsc190501.50525304'),
	'identifierSchemeCodeUnspsc190501.50525305': __('identifierSchemeCodeUnspsc190501.50525305'),
	'identifierSchemeCodeUnspsc190501.50525306': __('identifierSchemeCodeUnspsc190501.50525306'),
	'identifierSchemeCodeUnspsc190501.50525307': __('identifierSchemeCodeUnspsc190501.50525307'),
	'identifierSchemeCodeUnspsc190501.50525308': __('identifierSchemeCodeUnspsc190501.50525308'),
	'identifierSchemeCodeUnspsc190501.50525309': __('identifierSchemeCodeUnspsc190501.50525309'),
	'identifierSchemeCodeUnspsc190501.50525310': __('identifierSchemeCodeUnspsc190501.50525310'),
	'identifierSchemeCodeUnspsc190501.50525311': __('identifierSchemeCodeUnspsc190501.50525311'),
	'identifierSchemeCodeUnspsc190501.50525312': __('identifierSchemeCodeUnspsc190501.50525312'),
	'identifierSchemeCodeUnspsc190501.50525313': __('identifierSchemeCodeUnspsc190501.50525313'),
	'identifierSchemeCodeUnspsc190501.50525314': __('identifierSchemeCodeUnspsc190501.50525314'),
	'identifierSchemeCodeUnspsc190501.50525315': __('identifierSchemeCodeUnspsc190501.50525315'),
	'identifierSchemeCodeUnspsc190501.50525316': __('identifierSchemeCodeUnspsc190501.50525316'),
	'identifierSchemeCodeUnspsc190501.50525317': __('identifierSchemeCodeUnspsc190501.50525317'),
	'identifierSchemeCodeUnspsc190501.50525318': __('identifierSchemeCodeUnspsc190501.50525318'),
	'identifierSchemeCodeUnspsc190501.50525319': __('identifierSchemeCodeUnspsc190501.50525319'),
	'identifierSchemeCodeUnspsc190501.50525320': __('identifierSchemeCodeUnspsc190501.50525320'),
	'identifierSchemeCodeUnspsc190501.50525321': __('identifierSchemeCodeUnspsc190501.50525321'),
	'identifierSchemeCodeUnspsc190501.50525322': __('identifierSchemeCodeUnspsc190501.50525322'),
	'identifierSchemeCodeUnspsc190501.50525323': __('identifierSchemeCodeUnspsc190501.50525323'),
	'identifierSchemeCodeUnspsc190501.50525324': __('identifierSchemeCodeUnspsc190501.50525324'),
	'identifierSchemeCodeUnspsc190501.50525325': __('identifierSchemeCodeUnspsc190501.50525325'),
	'identifierSchemeCodeUnspsc190501.50525326': __('identifierSchemeCodeUnspsc190501.50525326'),
	'identifierSchemeCodeUnspsc190501.50525327': __('identifierSchemeCodeUnspsc190501.50525327'),
	'identifierSchemeCodeUnspsc190501.50525328': __('identifierSchemeCodeUnspsc190501.50525328'),
	'identifierSchemeCodeUnspsc190501.50525329': __('identifierSchemeCodeUnspsc190501.50525329'),
	'identifierSchemeCodeUnspsc190501.50525330': __('identifierSchemeCodeUnspsc190501.50525330'),
	'identifierSchemeCodeUnspsc190501.50525331': __('identifierSchemeCodeUnspsc190501.50525331'),
	'identifierSchemeCodeUnspsc190501.50525332': __('identifierSchemeCodeUnspsc190501.50525332'),
	'identifierSchemeCodeUnspsc190501.50525333': __('identifierSchemeCodeUnspsc190501.50525333'),
	'identifierSchemeCodeUnspsc190501.50525334': __('identifierSchemeCodeUnspsc190501.50525334'),
	'identifierSchemeCodeUnspsc190501.50525335': __('identifierSchemeCodeUnspsc190501.50525335'),
	'identifierSchemeCodeUnspsc190501.50525336': __('identifierSchemeCodeUnspsc190501.50525336'),
	'identifierSchemeCodeUnspsc190501.50525337': __('identifierSchemeCodeUnspsc190501.50525337'),
	'identifierSchemeCodeUnspsc190501.50525338': __('identifierSchemeCodeUnspsc190501.50525338'),
	'identifierSchemeCodeUnspsc190501.50525339': __('identifierSchemeCodeUnspsc190501.50525339'),
	'identifierSchemeCodeUnspsc190501.50525340': __('identifierSchemeCodeUnspsc190501.50525340'),
	'identifierSchemeCodeUnspsc190501.50525341': __('identifierSchemeCodeUnspsc190501.50525341'),
	'identifierSchemeCodeUnspsc190501.50525342': __('identifierSchemeCodeUnspsc190501.50525342'),
	'identifierSchemeCodeUnspsc190501.50525343': __('identifierSchemeCodeUnspsc190501.50525343'),
	'identifierSchemeCodeUnspsc190501.50525344': __('identifierSchemeCodeUnspsc190501.50525344'),
	'identifierSchemeCodeUnspsc190501.50525345': __('identifierSchemeCodeUnspsc190501.50525345'),
	'identifierSchemeCodeUnspsc190501.50525346': __('identifierSchemeCodeUnspsc190501.50525346'),
	'identifierSchemeCodeUnspsc190501.50525347': __('identifierSchemeCodeUnspsc190501.50525347'),
	'identifierSchemeCodeUnspsc190501.50525348': __('identifierSchemeCodeUnspsc190501.50525348'),
	'identifierSchemeCodeUnspsc190501.50525349': __('identifierSchemeCodeUnspsc190501.50525349'),
	'identifierSchemeCodeUnspsc190501.50525350': __('identifierSchemeCodeUnspsc190501.50525350'),
	'identifierSchemeCodeUnspsc190501.50525351': __('identifierSchemeCodeUnspsc190501.50525351'),
	'identifierSchemeCodeUnspsc190501.50525352': __('identifierSchemeCodeUnspsc190501.50525352'),
	'identifierSchemeCodeUnspsc190501.50525353': __('identifierSchemeCodeUnspsc190501.50525353'),
	'identifierSchemeCodeUnspsc190501.50525354': __('identifierSchemeCodeUnspsc190501.50525354'),
	'identifierSchemeCodeUnspsc190501.50525355': __('identifierSchemeCodeUnspsc190501.50525355'),
	'identifierSchemeCodeUnspsc190501.50525356': __('identifierSchemeCodeUnspsc190501.50525356'),
	'identifierSchemeCodeUnspsc190501.50525357': __('identifierSchemeCodeUnspsc190501.50525357'),
	'identifierSchemeCodeUnspsc190501.50525358': __('identifierSchemeCodeUnspsc190501.50525358'),
	'identifierSchemeCodeUnspsc190501.50525359': __('identifierSchemeCodeUnspsc190501.50525359'),
	'identifierSchemeCodeUnspsc190501.50525360': __('identifierSchemeCodeUnspsc190501.50525360'),
	'identifierSchemeCodeUnspsc190501.50525361': __('identifierSchemeCodeUnspsc190501.50525361'),
	'identifierSchemeCodeUnspsc190501.50525362': __('identifierSchemeCodeUnspsc190501.50525362'),
	'identifierSchemeCodeUnspsc190501.50525363': __('identifierSchemeCodeUnspsc190501.50525363'),
	'identifierSchemeCodeUnspsc190501.50525364': __('identifierSchemeCodeUnspsc190501.50525364'),
	'identifierSchemeCodeUnspsc190501.50525365': __('identifierSchemeCodeUnspsc190501.50525365'),
	'identifierSchemeCodeUnspsc190501.50525366': __('identifierSchemeCodeUnspsc190501.50525366'),
	'identifierSchemeCodeUnspsc190501.50525367': __('identifierSchemeCodeUnspsc190501.50525367'),
	'identifierSchemeCodeUnspsc190501.50525368': __('identifierSchemeCodeUnspsc190501.50525368'),
	'identifierSchemeCodeUnspsc190501.50525369': __('identifierSchemeCodeUnspsc190501.50525369'),
	'identifierSchemeCodeUnspsc190501.50525370': __('identifierSchemeCodeUnspsc190501.50525370'),
	'identifierSchemeCodeUnspsc190501.50525371': __('identifierSchemeCodeUnspsc190501.50525371'),
	'identifierSchemeCodeUnspsc190501.50525400': __('identifierSchemeCodeUnspsc190501.50525400'),
	'identifierSchemeCodeUnspsc190501.50525401': __('identifierSchemeCodeUnspsc190501.50525401'),
	'identifierSchemeCodeUnspsc190501.50525402': __('identifierSchemeCodeUnspsc190501.50525402'),
	'identifierSchemeCodeUnspsc190501.50525403': __('identifierSchemeCodeUnspsc190501.50525403'),
	'identifierSchemeCodeUnspsc190501.50525404': __('identifierSchemeCodeUnspsc190501.50525404'),
	'identifierSchemeCodeUnspsc190501.50525405': __('identifierSchemeCodeUnspsc190501.50525405'),
	'identifierSchemeCodeUnspsc190501.50525406': __('identifierSchemeCodeUnspsc190501.50525406'),
	'identifierSchemeCodeUnspsc190501.50525407': __('identifierSchemeCodeUnspsc190501.50525407'),
	'identifierSchemeCodeUnspsc190501.50525408': __('identifierSchemeCodeUnspsc190501.50525408'),
	'identifierSchemeCodeUnspsc190501.50525409': __('identifierSchemeCodeUnspsc190501.50525409'),
	'identifierSchemeCodeUnspsc190501.50525410': __('identifierSchemeCodeUnspsc190501.50525410'),
	'identifierSchemeCodeUnspsc190501.50525411': __('identifierSchemeCodeUnspsc190501.50525411'),
	'identifierSchemeCodeUnspsc190501.50525412': __('identifierSchemeCodeUnspsc190501.50525412'),
	'identifierSchemeCodeUnspsc190501.50525413': __('identifierSchemeCodeUnspsc190501.50525413'),
	'identifierSchemeCodeUnspsc190501.50525414': __('identifierSchemeCodeUnspsc190501.50525414'),
	'identifierSchemeCodeUnspsc190501.50525415': __('identifierSchemeCodeUnspsc190501.50525415'),
	'identifierSchemeCodeUnspsc190501.50525416': __('identifierSchemeCodeUnspsc190501.50525416'),
	'identifierSchemeCodeUnspsc190501.50525417': __('identifierSchemeCodeUnspsc190501.50525417'),
	'identifierSchemeCodeUnspsc190501.50525418': __('identifierSchemeCodeUnspsc190501.50525418'),
	'identifierSchemeCodeUnspsc190501.50525419': __('identifierSchemeCodeUnspsc190501.50525419'),
	'identifierSchemeCodeUnspsc190501.50525420': __('identifierSchemeCodeUnspsc190501.50525420'),
	'identifierSchemeCodeUnspsc190501.50525421': __('identifierSchemeCodeUnspsc190501.50525421'),
	'identifierSchemeCodeUnspsc190501.50525422': __('identifierSchemeCodeUnspsc190501.50525422'),
	'identifierSchemeCodeUnspsc190501.50525423': __('identifierSchemeCodeUnspsc190501.50525423'),
	'identifierSchemeCodeUnspsc190501.50525424': __('identifierSchemeCodeUnspsc190501.50525424'),
	'identifierSchemeCodeUnspsc190501.50525425': __('identifierSchemeCodeUnspsc190501.50525425'),
	'identifierSchemeCodeUnspsc190501.50525426': __('identifierSchemeCodeUnspsc190501.50525426'),
	'identifierSchemeCodeUnspsc190501.50525427': __('identifierSchemeCodeUnspsc190501.50525427'),
	'identifierSchemeCodeUnspsc190501.50525428': __('identifierSchemeCodeUnspsc190501.50525428'),
	'identifierSchemeCodeUnspsc190501.50525429': __('identifierSchemeCodeUnspsc190501.50525429'),
	'identifierSchemeCodeUnspsc190501.50525430': __('identifierSchemeCodeUnspsc190501.50525430'),
	'identifierSchemeCodeUnspsc190501.50525431': __('identifierSchemeCodeUnspsc190501.50525431'),
	'identifierSchemeCodeUnspsc190501.50525432': __('identifierSchemeCodeUnspsc190501.50525432'),
	'identifierSchemeCodeUnspsc190501.50525433': __('identifierSchemeCodeUnspsc190501.50525433'),
	'identifierSchemeCodeUnspsc190501.50525434': __('identifierSchemeCodeUnspsc190501.50525434'),
	'identifierSchemeCodeUnspsc190501.50525435': __('identifierSchemeCodeUnspsc190501.50525435'),
	'identifierSchemeCodeUnspsc190501.50525436': __('identifierSchemeCodeUnspsc190501.50525436'),
	'identifierSchemeCodeUnspsc190501.50525437': __('identifierSchemeCodeUnspsc190501.50525437'),
	'identifierSchemeCodeUnspsc190501.50525438': __('identifierSchemeCodeUnspsc190501.50525438'),
	'identifierSchemeCodeUnspsc190501.50525439': __('identifierSchemeCodeUnspsc190501.50525439'),
	'identifierSchemeCodeUnspsc190501.50525440': __('identifierSchemeCodeUnspsc190501.50525440'),
	'identifierSchemeCodeUnspsc190501.50525441': __('identifierSchemeCodeUnspsc190501.50525441'),
	'identifierSchemeCodeUnspsc190501.50525442': __('identifierSchemeCodeUnspsc190501.50525442'),
	'identifierSchemeCodeUnspsc190501.50525443': __('identifierSchemeCodeUnspsc190501.50525443'),
	'identifierSchemeCodeUnspsc190501.50525444': __('identifierSchemeCodeUnspsc190501.50525444'),
	'identifierSchemeCodeUnspsc190501.50525445': __('identifierSchemeCodeUnspsc190501.50525445'),
	'identifierSchemeCodeUnspsc190501.50525446': __('identifierSchemeCodeUnspsc190501.50525446'),
	'identifierSchemeCodeUnspsc190501.50525447': __('identifierSchemeCodeUnspsc190501.50525447'),
	'identifierSchemeCodeUnspsc190501.50525448': __('identifierSchemeCodeUnspsc190501.50525448'),
	'identifierSchemeCodeUnspsc190501.50525449': __('identifierSchemeCodeUnspsc190501.50525449'),
	'identifierSchemeCodeUnspsc190501.50525450': __('identifierSchemeCodeUnspsc190501.50525450'),
	'identifierSchemeCodeUnspsc190501.50525451': __('identifierSchemeCodeUnspsc190501.50525451'),
	'identifierSchemeCodeUnspsc190501.50525452': __('identifierSchemeCodeUnspsc190501.50525452'),
	'identifierSchemeCodeUnspsc190501.50525453': __('identifierSchemeCodeUnspsc190501.50525453'),
	'identifierSchemeCodeUnspsc190501.50525454': __('identifierSchemeCodeUnspsc190501.50525454'),
	'identifierSchemeCodeUnspsc190501.50525455': __('identifierSchemeCodeUnspsc190501.50525455'),
	'identifierSchemeCodeUnspsc190501.50525456': __('identifierSchemeCodeUnspsc190501.50525456'),
	'identifierSchemeCodeUnspsc190501.50525457': __('identifierSchemeCodeUnspsc190501.50525457'),
	'identifierSchemeCodeUnspsc190501.50525458': __('identifierSchemeCodeUnspsc190501.50525458'),
	'identifierSchemeCodeUnspsc190501.50525459': __('identifierSchemeCodeUnspsc190501.50525459'),
	'identifierSchemeCodeUnspsc190501.50525460': __('identifierSchemeCodeUnspsc190501.50525460'),
	'identifierSchemeCodeUnspsc190501.50525500': __('identifierSchemeCodeUnspsc190501.50525500'),
	'identifierSchemeCodeUnspsc190501.50525501': __('identifierSchemeCodeUnspsc190501.50525501'),
	'identifierSchemeCodeUnspsc190501.50525502': __('identifierSchemeCodeUnspsc190501.50525502'),
	'identifierSchemeCodeUnspsc190501.50525503': __('identifierSchemeCodeUnspsc190501.50525503'),
	'identifierSchemeCodeUnspsc190501.50525504': __('identifierSchemeCodeUnspsc190501.50525504'),
	'identifierSchemeCodeUnspsc190501.50525505': __('identifierSchemeCodeUnspsc190501.50525505'),
	'identifierSchemeCodeUnspsc190501.50525506': __('identifierSchemeCodeUnspsc190501.50525506'),
	'identifierSchemeCodeUnspsc190501.50525507': __('identifierSchemeCodeUnspsc190501.50525507'),
	'identifierSchemeCodeUnspsc190501.50525508': __('identifierSchemeCodeUnspsc190501.50525508'),
	'identifierSchemeCodeUnspsc190501.50525509': __('identifierSchemeCodeUnspsc190501.50525509'),
	'identifierSchemeCodeUnspsc190501.50525510': __('identifierSchemeCodeUnspsc190501.50525510'),
	'identifierSchemeCodeUnspsc190501.50525511': __('identifierSchemeCodeUnspsc190501.50525511'),
	'identifierSchemeCodeUnspsc190501.50525512': __('identifierSchemeCodeUnspsc190501.50525512'),
	'identifierSchemeCodeUnspsc190501.50525600': __('identifierSchemeCodeUnspsc190501.50525600'),
	'identifierSchemeCodeUnspsc190501.50525601': __('identifierSchemeCodeUnspsc190501.50525601'),
	'identifierSchemeCodeUnspsc190501.50525602': __('identifierSchemeCodeUnspsc190501.50525602'),
	'identifierSchemeCodeUnspsc190501.50525603': __('identifierSchemeCodeUnspsc190501.50525603'),
	'identifierSchemeCodeUnspsc190501.50525604': __('identifierSchemeCodeUnspsc190501.50525604'),
	'identifierSchemeCodeUnspsc190501.50525605': __('identifierSchemeCodeUnspsc190501.50525605'),
	'identifierSchemeCodeUnspsc190501.50525606': __('identifierSchemeCodeUnspsc190501.50525606'),
	'identifierSchemeCodeUnspsc190501.50525607': __('identifierSchemeCodeUnspsc190501.50525607'),
	'identifierSchemeCodeUnspsc190501.50525608': __('identifierSchemeCodeUnspsc190501.50525608'),
	'identifierSchemeCodeUnspsc190501.50525609': __('identifierSchemeCodeUnspsc190501.50525609'),
	'identifierSchemeCodeUnspsc190501.50525610': __('identifierSchemeCodeUnspsc190501.50525610'),
	'identifierSchemeCodeUnspsc190501.50525700': __('identifierSchemeCodeUnspsc190501.50525700'),
	'identifierSchemeCodeUnspsc190501.50525701': __('identifierSchemeCodeUnspsc190501.50525701'),
	'identifierSchemeCodeUnspsc190501.50525702': __('identifierSchemeCodeUnspsc190501.50525702'),
	'identifierSchemeCodeUnspsc190501.50525703': __('identifierSchemeCodeUnspsc190501.50525703'),
	'identifierSchemeCodeUnspsc190501.50525704': __('identifierSchemeCodeUnspsc190501.50525704'),
	'identifierSchemeCodeUnspsc190501.50525705': __('identifierSchemeCodeUnspsc190501.50525705'),
	'identifierSchemeCodeUnspsc190501.50525706': __('identifierSchemeCodeUnspsc190501.50525706'),
	'identifierSchemeCodeUnspsc190501.50525707': __('identifierSchemeCodeUnspsc190501.50525707'),
	'identifierSchemeCodeUnspsc190501.50525708': __('identifierSchemeCodeUnspsc190501.50525708'),
	'identifierSchemeCodeUnspsc190501.50525709': __('identifierSchemeCodeUnspsc190501.50525709'),
	'identifierSchemeCodeUnspsc190501.50525710': __('identifierSchemeCodeUnspsc190501.50525710'),
	'identifierSchemeCodeUnspsc190501.50525711': __('identifierSchemeCodeUnspsc190501.50525711'),
	'identifierSchemeCodeUnspsc190501.50525712': __('identifierSchemeCodeUnspsc190501.50525712'),
	'identifierSchemeCodeUnspsc190501.50525713': __('identifierSchemeCodeUnspsc190501.50525713'),
	'identifierSchemeCodeUnspsc190501.50525714': __('identifierSchemeCodeUnspsc190501.50525714'),
	'identifierSchemeCodeUnspsc190501.50525715': __('identifierSchemeCodeUnspsc190501.50525715'),
	'identifierSchemeCodeUnspsc190501.50525716': __('identifierSchemeCodeUnspsc190501.50525716'),
	'identifierSchemeCodeUnspsc190501.50525717': __('identifierSchemeCodeUnspsc190501.50525717'),
	'identifierSchemeCodeUnspsc190501.50525718': __('identifierSchemeCodeUnspsc190501.50525718'),
	'identifierSchemeCodeUnspsc190501.50525719': __('identifierSchemeCodeUnspsc190501.50525719'),
	'identifierSchemeCodeUnspsc190501.50525720': __('identifierSchemeCodeUnspsc190501.50525720'),
	'identifierSchemeCodeUnspsc190501.50525721': __('identifierSchemeCodeUnspsc190501.50525721'),
	'identifierSchemeCodeUnspsc190501.50525722': __('identifierSchemeCodeUnspsc190501.50525722'),
	'identifierSchemeCodeUnspsc190501.50525723': __('identifierSchemeCodeUnspsc190501.50525723'),
	'identifierSchemeCodeUnspsc190501.50525800': __('identifierSchemeCodeUnspsc190501.50525800'),
	'identifierSchemeCodeUnspsc190501.50525801': __('identifierSchemeCodeUnspsc190501.50525801'),
	'identifierSchemeCodeUnspsc190501.50525802': __('identifierSchemeCodeUnspsc190501.50525802'),
	'identifierSchemeCodeUnspsc190501.50525803': __('identifierSchemeCodeUnspsc190501.50525803'),
	'identifierSchemeCodeUnspsc190501.50525804': __('identifierSchemeCodeUnspsc190501.50525804'),
	'identifierSchemeCodeUnspsc190501.50525805': __('identifierSchemeCodeUnspsc190501.50525805'),
	'identifierSchemeCodeUnspsc190501.50525806': __('identifierSchemeCodeUnspsc190501.50525806'),
	'identifierSchemeCodeUnspsc190501.50525807': __('identifierSchemeCodeUnspsc190501.50525807'),
	'identifierSchemeCodeUnspsc190501.50525808': __('identifierSchemeCodeUnspsc190501.50525808'),
	'identifierSchemeCodeUnspsc190501.50525809': __('identifierSchemeCodeUnspsc190501.50525809'),
	'identifierSchemeCodeUnspsc190501.50525810': __('identifierSchemeCodeUnspsc190501.50525810'),
	'identifierSchemeCodeUnspsc190501.50525811': __('identifierSchemeCodeUnspsc190501.50525811'),
	'identifierSchemeCodeUnspsc190501.50525812': __('identifierSchemeCodeUnspsc190501.50525812'),
	'identifierSchemeCodeUnspsc190501.50525813': __('identifierSchemeCodeUnspsc190501.50525813'),
	'identifierSchemeCodeUnspsc190501.50525814': __('identifierSchemeCodeUnspsc190501.50525814'),
	'identifierSchemeCodeUnspsc190501.50525815': __('identifierSchemeCodeUnspsc190501.50525815'),
	'identifierSchemeCodeUnspsc190501.50525816': __('identifierSchemeCodeUnspsc190501.50525816'),
	'identifierSchemeCodeUnspsc190501.50525817': __('identifierSchemeCodeUnspsc190501.50525817'),
	'identifierSchemeCodeUnspsc190501.50525818': __('identifierSchemeCodeUnspsc190501.50525818'),
	'identifierSchemeCodeUnspsc190501.50525819': __('identifierSchemeCodeUnspsc190501.50525819'),
	'identifierSchemeCodeUnspsc190501.50525820': __('identifierSchemeCodeUnspsc190501.50525820'),
	'identifierSchemeCodeUnspsc190501.50525821': __('identifierSchemeCodeUnspsc190501.50525821'),
	'identifierSchemeCodeUnspsc190501.50525822': __('identifierSchemeCodeUnspsc190501.50525822'),
	'identifierSchemeCodeUnspsc190501.50525823': __('identifierSchemeCodeUnspsc190501.50525823'),
	'identifierSchemeCodeUnspsc190501.50525824': __('identifierSchemeCodeUnspsc190501.50525824'),
	'identifierSchemeCodeUnspsc190501.50525825': __('identifierSchemeCodeUnspsc190501.50525825'),
	'identifierSchemeCodeUnspsc190501.50525826': __('identifierSchemeCodeUnspsc190501.50525826'),
	'identifierSchemeCodeUnspsc190501.50525827': __('identifierSchemeCodeUnspsc190501.50525827'),
	'identifierSchemeCodeUnspsc190501.50525828': __('identifierSchemeCodeUnspsc190501.50525828'),
	'identifierSchemeCodeUnspsc190501.50525829': __('identifierSchemeCodeUnspsc190501.50525829'),
	'identifierSchemeCodeUnspsc190501.50525830': __('identifierSchemeCodeUnspsc190501.50525830'),
	'identifierSchemeCodeUnspsc190501.50525831': __('identifierSchemeCodeUnspsc190501.50525831'),
	'identifierSchemeCodeUnspsc190501.50525832': __('identifierSchemeCodeUnspsc190501.50525832'),
	'identifierSchemeCodeUnspsc190501.50525833': __('identifierSchemeCodeUnspsc190501.50525833'),
	'identifierSchemeCodeUnspsc190501.50525834': __('identifierSchemeCodeUnspsc190501.50525834'),
	'identifierSchemeCodeUnspsc190501.50525835': __('identifierSchemeCodeUnspsc190501.50525835'),
	'identifierSchemeCodeUnspsc190501.50525836': __('identifierSchemeCodeUnspsc190501.50525836'),
	'identifierSchemeCodeUnspsc190501.50525837': __('identifierSchemeCodeUnspsc190501.50525837'),
	'identifierSchemeCodeUnspsc190501.50525838': __('identifierSchemeCodeUnspsc190501.50525838'),
	'identifierSchemeCodeUnspsc190501.50525839': __('identifierSchemeCodeUnspsc190501.50525839'),
	'identifierSchemeCodeUnspsc190501.50525840': __('identifierSchemeCodeUnspsc190501.50525840'),
	'identifierSchemeCodeUnspsc190501.50525841': __('identifierSchemeCodeUnspsc190501.50525841'),
	'identifierSchemeCodeUnspsc190501.50525842': __('identifierSchemeCodeUnspsc190501.50525842'),
	'identifierSchemeCodeUnspsc190501.50525843': __('identifierSchemeCodeUnspsc190501.50525843'),
	'identifierSchemeCodeUnspsc190501.50525844': __('identifierSchemeCodeUnspsc190501.50525844'),
	'identifierSchemeCodeUnspsc190501.50525845': __('identifierSchemeCodeUnspsc190501.50525845'),
	'identifierSchemeCodeUnspsc190501.50525846': __('identifierSchemeCodeUnspsc190501.50525846'),
	'identifierSchemeCodeUnspsc190501.50525847': __('identifierSchemeCodeUnspsc190501.50525847'),
	'identifierSchemeCodeUnspsc190501.50525848': __('identifierSchemeCodeUnspsc190501.50525848'),
	'identifierSchemeCodeUnspsc190501.50525849': __('identifierSchemeCodeUnspsc190501.50525849'),
	'identifierSchemeCodeUnspsc190501.50525850': __('identifierSchemeCodeUnspsc190501.50525850'),
	'identifierSchemeCodeUnspsc190501.50525851': __('identifierSchemeCodeUnspsc190501.50525851'),
	'identifierSchemeCodeUnspsc190501.50525852': __('identifierSchemeCodeUnspsc190501.50525852'),
	'identifierSchemeCodeUnspsc190501.50525853': __('identifierSchemeCodeUnspsc190501.50525853'),
	'identifierSchemeCodeUnspsc190501.50525854': __('identifierSchemeCodeUnspsc190501.50525854'),
	'identifierSchemeCodeUnspsc190501.50525855': __('identifierSchemeCodeUnspsc190501.50525855'),
	'identifierSchemeCodeUnspsc190501.50525856': __('identifierSchemeCodeUnspsc190501.50525856'),
	'identifierSchemeCodeUnspsc190501.50525857': __('identifierSchemeCodeUnspsc190501.50525857'),
	'identifierSchemeCodeUnspsc190501.50525858': __('identifierSchemeCodeUnspsc190501.50525858'),
	'identifierSchemeCodeUnspsc190501.50525859': __('identifierSchemeCodeUnspsc190501.50525859'),
	'identifierSchemeCodeUnspsc190501.50525860': __('identifierSchemeCodeUnspsc190501.50525860'),
	'identifierSchemeCodeUnspsc190501.50525861': __('identifierSchemeCodeUnspsc190501.50525861'),
	'identifierSchemeCodeUnspsc190501.50525862': __('identifierSchemeCodeUnspsc190501.50525862'),
	'identifierSchemeCodeUnspsc190501.50525863': __('identifierSchemeCodeUnspsc190501.50525863'),
	'identifierSchemeCodeUnspsc190501.50525864': __('identifierSchemeCodeUnspsc190501.50525864'),
	'identifierSchemeCodeUnspsc190501.50525865': __('identifierSchemeCodeUnspsc190501.50525865'),
	'identifierSchemeCodeUnspsc190501.50525866': __('identifierSchemeCodeUnspsc190501.50525866'),
	'identifierSchemeCodeUnspsc190501.50525867': __('identifierSchemeCodeUnspsc190501.50525867'),
	'identifierSchemeCodeUnspsc190501.50525868': __('identifierSchemeCodeUnspsc190501.50525868'),
	'identifierSchemeCodeUnspsc190501.50525869': __('identifierSchemeCodeUnspsc190501.50525869'),
	'identifierSchemeCodeUnspsc190501.50525870': __('identifierSchemeCodeUnspsc190501.50525870'),
	'identifierSchemeCodeUnspsc190501.50525871': __('identifierSchemeCodeUnspsc190501.50525871'),
	'identifierSchemeCodeUnspsc190501.50525872': __('identifierSchemeCodeUnspsc190501.50525872'),
	'identifierSchemeCodeUnspsc190501.50525873': __('identifierSchemeCodeUnspsc190501.50525873'),
	'identifierSchemeCodeUnspsc190501.50525874': __('identifierSchemeCodeUnspsc190501.50525874'),
	'identifierSchemeCodeUnspsc190501.50525875': __('identifierSchemeCodeUnspsc190501.50525875'),
	'identifierSchemeCodeUnspsc190501.50525876': __('identifierSchemeCodeUnspsc190501.50525876'),
	'identifierSchemeCodeUnspsc190501.50525877': __('identifierSchemeCodeUnspsc190501.50525877'),
	'identifierSchemeCodeUnspsc190501.50525900': __('identifierSchemeCodeUnspsc190501.50525900'),
	'identifierSchemeCodeUnspsc190501.50525901': __('identifierSchemeCodeUnspsc190501.50525901'),
	'identifierSchemeCodeUnspsc190501.50525902': __('identifierSchemeCodeUnspsc190501.50525902'),
	'identifierSchemeCodeUnspsc190501.50525903': __('identifierSchemeCodeUnspsc190501.50525903'),
	'identifierSchemeCodeUnspsc190501.50525904': __('identifierSchemeCodeUnspsc190501.50525904'),
	'identifierSchemeCodeUnspsc190501.50525905': __('identifierSchemeCodeUnspsc190501.50525905'),
	'identifierSchemeCodeUnspsc190501.50525906': __('identifierSchemeCodeUnspsc190501.50525906'),
	'identifierSchemeCodeUnspsc190501.50525907': __('identifierSchemeCodeUnspsc190501.50525907'),
	'identifierSchemeCodeUnspsc190501.50525908': __('identifierSchemeCodeUnspsc190501.50525908'),
	'identifierSchemeCodeUnspsc190501.50526000': __('identifierSchemeCodeUnspsc190501.50526000'),
	'identifierSchemeCodeUnspsc190501.50526001': __('identifierSchemeCodeUnspsc190501.50526001'),
	'identifierSchemeCodeUnspsc190501.50526002': __('identifierSchemeCodeUnspsc190501.50526002'),
	'identifierSchemeCodeUnspsc190501.50526003': __('identifierSchemeCodeUnspsc190501.50526003'),
	'identifierSchemeCodeUnspsc190501.50526004': __('identifierSchemeCodeUnspsc190501.50526004'),
	'identifierSchemeCodeUnspsc190501.50526005': __('identifierSchemeCodeUnspsc190501.50526005'),
	'identifierSchemeCodeUnspsc190501.50526006': __('identifierSchemeCodeUnspsc190501.50526006'),
	'identifierSchemeCodeUnspsc190501.50526007': __('identifierSchemeCodeUnspsc190501.50526007'),
	'identifierSchemeCodeUnspsc190501.50526008': __('identifierSchemeCodeUnspsc190501.50526008'),
	'identifierSchemeCodeUnspsc190501.50526100': __('identifierSchemeCodeUnspsc190501.50526100'),
	'identifierSchemeCodeUnspsc190501.50526101': __('identifierSchemeCodeUnspsc190501.50526101'),
	'identifierSchemeCodeUnspsc190501.50526102': __('identifierSchemeCodeUnspsc190501.50526102'),
	'identifierSchemeCodeUnspsc190501.50526103': __('identifierSchemeCodeUnspsc190501.50526103'),
	'identifierSchemeCodeUnspsc190501.50526200': __('identifierSchemeCodeUnspsc190501.50526200'),
	'identifierSchemeCodeUnspsc190501.50526201': __('identifierSchemeCodeUnspsc190501.50526201'),
	'identifierSchemeCodeUnspsc190501.50526202': __('identifierSchemeCodeUnspsc190501.50526202'),
	'identifierSchemeCodeUnspsc190501.50526203': __('identifierSchemeCodeUnspsc190501.50526203'),
	'identifierSchemeCodeUnspsc190501.50526204': __('identifierSchemeCodeUnspsc190501.50526204'),
	'identifierSchemeCodeUnspsc190501.50526205': __('identifierSchemeCodeUnspsc190501.50526205'),
	'identifierSchemeCodeUnspsc190501.50526206': __('identifierSchemeCodeUnspsc190501.50526206'),
	'identifierSchemeCodeUnspsc190501.50526207': __('identifierSchemeCodeUnspsc190501.50526207'),
	'identifierSchemeCodeUnspsc190501.50526208': __('identifierSchemeCodeUnspsc190501.50526208'),
	'identifierSchemeCodeUnspsc190501.50526209': __('identifierSchemeCodeUnspsc190501.50526209'),
	'identifierSchemeCodeUnspsc190501.50526210': __('identifierSchemeCodeUnspsc190501.50526210'),
	'identifierSchemeCodeUnspsc190501.50526211': __('identifierSchemeCodeUnspsc190501.50526211'),
	'identifierSchemeCodeUnspsc190501.50526212': __('identifierSchemeCodeUnspsc190501.50526212'),
	'identifierSchemeCodeUnspsc190501.50526213': __('identifierSchemeCodeUnspsc190501.50526213'),
	'identifierSchemeCodeUnspsc190501.50526214': __('identifierSchemeCodeUnspsc190501.50526214'),
	'identifierSchemeCodeUnspsc190501.50526215': __('identifierSchemeCodeUnspsc190501.50526215'),
	'identifierSchemeCodeUnspsc190501.50526216': __('identifierSchemeCodeUnspsc190501.50526216'),
	'identifierSchemeCodeUnspsc190501.50526217': __('identifierSchemeCodeUnspsc190501.50526217'),
	'identifierSchemeCodeUnspsc190501.50526218': __('identifierSchemeCodeUnspsc190501.50526218'),
	'identifierSchemeCodeUnspsc190501.50526219': __('identifierSchemeCodeUnspsc190501.50526219'),
	'identifierSchemeCodeUnspsc190501.50526220': __('identifierSchemeCodeUnspsc190501.50526220'),
	'identifierSchemeCodeUnspsc190501.50526221': __('identifierSchemeCodeUnspsc190501.50526221'),
	'identifierSchemeCodeUnspsc190501.50526222': __('identifierSchemeCodeUnspsc190501.50526222'),
	'identifierSchemeCodeUnspsc190501.50526223': __('identifierSchemeCodeUnspsc190501.50526223'),
	'identifierSchemeCodeUnspsc190501.50526224': __('identifierSchemeCodeUnspsc190501.50526224'),
	'identifierSchemeCodeUnspsc190501.50526225': __('identifierSchemeCodeUnspsc190501.50526225'),
	'identifierSchemeCodeUnspsc190501.50526226': __('identifierSchemeCodeUnspsc190501.50526226'),
	'identifierSchemeCodeUnspsc190501.50526227': __('identifierSchemeCodeUnspsc190501.50526227'),
	'identifierSchemeCodeUnspsc190501.50526228': __('identifierSchemeCodeUnspsc190501.50526228'),
	'identifierSchemeCodeUnspsc190501.50526229': __('identifierSchemeCodeUnspsc190501.50526229'),
	'identifierSchemeCodeUnspsc190501.50526300': __('identifierSchemeCodeUnspsc190501.50526300'),
	'identifierSchemeCodeUnspsc190501.50526301': __('identifierSchemeCodeUnspsc190501.50526301'),
	'identifierSchemeCodeUnspsc190501.50526302': __('identifierSchemeCodeUnspsc190501.50526302'),
	'identifierSchemeCodeUnspsc190501.50526303': __('identifierSchemeCodeUnspsc190501.50526303'),
	'identifierSchemeCodeUnspsc190501.50526304': __('identifierSchemeCodeUnspsc190501.50526304'),
	'identifierSchemeCodeUnspsc190501.50526305': __('identifierSchemeCodeUnspsc190501.50526305'),
	'identifierSchemeCodeUnspsc190501.50526306': __('identifierSchemeCodeUnspsc190501.50526306'),
	'identifierSchemeCodeUnspsc190501.50526307': __('identifierSchemeCodeUnspsc190501.50526307'),
	'identifierSchemeCodeUnspsc190501.50526308': __('identifierSchemeCodeUnspsc190501.50526308'),
	'identifierSchemeCodeUnspsc190501.50526309': __('identifierSchemeCodeUnspsc190501.50526309'),
	'identifierSchemeCodeUnspsc190501.50526310': __('identifierSchemeCodeUnspsc190501.50526310'),
	'identifierSchemeCodeUnspsc190501.50526400': __('identifierSchemeCodeUnspsc190501.50526400'),
	'identifierSchemeCodeUnspsc190501.50526401': __('identifierSchemeCodeUnspsc190501.50526401'),
	'identifierSchemeCodeUnspsc190501.50526402': __('identifierSchemeCodeUnspsc190501.50526402'),
	'identifierSchemeCodeUnspsc190501.50526403': __('identifierSchemeCodeUnspsc190501.50526403'),
	'identifierSchemeCodeUnspsc190501.50526404': __('identifierSchemeCodeUnspsc190501.50526404'),
	'identifierSchemeCodeUnspsc190501.50526500': __('identifierSchemeCodeUnspsc190501.50526500'),
	'identifierSchemeCodeUnspsc190501.50526501': __('identifierSchemeCodeUnspsc190501.50526501'),
	'identifierSchemeCodeUnspsc190501.50526600': __('identifierSchemeCodeUnspsc190501.50526600'),
	'identifierSchemeCodeUnspsc190501.50526601': __('identifierSchemeCodeUnspsc190501.50526601'),
	'identifierSchemeCodeUnspsc190501.50526602': __('identifierSchemeCodeUnspsc190501.50526602'),
	'identifierSchemeCodeUnspsc190501.50526603': __('identifierSchemeCodeUnspsc190501.50526603'),
	'identifierSchemeCodeUnspsc190501.50526604': __('identifierSchemeCodeUnspsc190501.50526604'),
	'identifierSchemeCodeUnspsc190501.50526700': __('identifierSchemeCodeUnspsc190501.50526700'),
	'identifierSchemeCodeUnspsc190501.50526701': __('identifierSchemeCodeUnspsc190501.50526701'),
	'identifierSchemeCodeUnspsc190501.50526702': __('identifierSchemeCodeUnspsc190501.50526702'),
	'identifierSchemeCodeUnspsc190501.50526703': __('identifierSchemeCodeUnspsc190501.50526703'),
	'identifierSchemeCodeUnspsc190501.50526800': __('identifierSchemeCodeUnspsc190501.50526800'),
	'identifierSchemeCodeUnspsc190501.50526801': __('identifierSchemeCodeUnspsc190501.50526801'),
	'identifierSchemeCodeUnspsc190501.50526802': __('identifierSchemeCodeUnspsc190501.50526802'),
	'identifierSchemeCodeUnspsc190501.50526803': __('identifierSchemeCodeUnspsc190501.50526803'),
	'identifierSchemeCodeUnspsc190501.50526900': __('identifierSchemeCodeUnspsc190501.50526900'),
	'identifierSchemeCodeUnspsc190501.50526901': __('identifierSchemeCodeUnspsc190501.50526901'),
	'identifierSchemeCodeUnspsc190501.50526902': __('identifierSchemeCodeUnspsc190501.50526902'),
	'identifierSchemeCodeUnspsc190501.50526903': __('identifierSchemeCodeUnspsc190501.50526903'),
	'identifierSchemeCodeUnspsc190501.50526904': __('identifierSchemeCodeUnspsc190501.50526904'),
	'identifierSchemeCodeUnspsc190501.50526905': __('identifierSchemeCodeUnspsc190501.50526905'),
	'identifierSchemeCodeUnspsc190501.50526906': __('identifierSchemeCodeUnspsc190501.50526906'),
	'identifierSchemeCodeUnspsc190501.50527000': __('identifierSchemeCodeUnspsc190501.50527000'),
	'identifierSchemeCodeUnspsc190501.50527001': __('identifierSchemeCodeUnspsc190501.50527001'),
	'identifierSchemeCodeUnspsc190501.50527002': __('identifierSchemeCodeUnspsc190501.50527002'),
	'identifierSchemeCodeUnspsc190501.50527003': __('identifierSchemeCodeUnspsc190501.50527003'),
	'identifierSchemeCodeUnspsc190501.50527004': __('identifierSchemeCodeUnspsc190501.50527004'),
	'identifierSchemeCodeUnspsc190501.50527005': __('identifierSchemeCodeUnspsc190501.50527005'),
	'identifierSchemeCodeUnspsc190501.50527006': __('identifierSchemeCodeUnspsc190501.50527006'),
	'identifierSchemeCodeUnspsc190501.50527007': __('identifierSchemeCodeUnspsc190501.50527007'),
	'identifierSchemeCodeUnspsc190501.50527008': __('identifierSchemeCodeUnspsc190501.50527008'),
	'identifierSchemeCodeUnspsc190501.50527009': __('identifierSchemeCodeUnspsc190501.50527009'),
	'identifierSchemeCodeUnspsc190501.50527010': __('identifierSchemeCodeUnspsc190501.50527010'),
	'identifierSchemeCodeUnspsc190501.50527011': __('identifierSchemeCodeUnspsc190501.50527011'),
	'identifierSchemeCodeUnspsc190501.50527012': __('identifierSchemeCodeUnspsc190501.50527012'),
	'identifierSchemeCodeUnspsc190501.50527013': __('identifierSchemeCodeUnspsc190501.50527013'),
	'identifierSchemeCodeUnspsc190501.50527014': __('identifierSchemeCodeUnspsc190501.50527014'),
	'identifierSchemeCodeUnspsc190501.50527015': __('identifierSchemeCodeUnspsc190501.50527015'),
	'identifierSchemeCodeUnspsc190501.50527016': __('identifierSchemeCodeUnspsc190501.50527016'),
	'identifierSchemeCodeUnspsc190501.50527017': __('identifierSchemeCodeUnspsc190501.50527017'),
	'identifierSchemeCodeUnspsc190501.50527018': __('identifierSchemeCodeUnspsc190501.50527018'),
	'identifierSchemeCodeUnspsc190501.50527019': __('identifierSchemeCodeUnspsc190501.50527019'),
	'identifierSchemeCodeUnspsc190501.50527020': __('identifierSchemeCodeUnspsc190501.50527020'),
	'identifierSchemeCodeUnspsc190501.50527021': __('identifierSchemeCodeUnspsc190501.50527021'),
	'identifierSchemeCodeUnspsc190501.50527022': __('identifierSchemeCodeUnspsc190501.50527022'),
	'identifierSchemeCodeUnspsc190501.50527023': __('identifierSchemeCodeUnspsc190501.50527023'),
	'identifierSchemeCodeUnspsc190501.50527024': __('identifierSchemeCodeUnspsc190501.50527024'),
	'identifierSchemeCodeUnspsc190501.50527025': __('identifierSchemeCodeUnspsc190501.50527025'),
	'identifierSchemeCodeUnspsc190501.50527026': __('identifierSchemeCodeUnspsc190501.50527026'),
	'identifierSchemeCodeUnspsc190501.50527027': __('identifierSchemeCodeUnspsc190501.50527027'),
	'identifierSchemeCodeUnspsc190501.50527028': __('identifierSchemeCodeUnspsc190501.50527028'),
	'identifierSchemeCodeUnspsc190501.50527029': __('identifierSchemeCodeUnspsc190501.50527029'),
	'identifierSchemeCodeUnspsc190501.50527030': __('identifierSchemeCodeUnspsc190501.50527030'),
	'identifierSchemeCodeUnspsc190501.50527031': __('identifierSchemeCodeUnspsc190501.50527031'),
	'identifierSchemeCodeUnspsc190501.50527032': __('identifierSchemeCodeUnspsc190501.50527032'),
	'identifierSchemeCodeUnspsc190501.50527033': __('identifierSchemeCodeUnspsc190501.50527033'),
	'identifierSchemeCodeUnspsc190501.50527034': __('identifierSchemeCodeUnspsc190501.50527034'),
	'identifierSchemeCodeUnspsc190501.50527035': __('identifierSchemeCodeUnspsc190501.50527035'),
	'identifierSchemeCodeUnspsc190501.50527036': __('identifierSchemeCodeUnspsc190501.50527036'),
	'identifierSchemeCodeUnspsc190501.50527037': __('identifierSchemeCodeUnspsc190501.50527037'),
	'identifierSchemeCodeUnspsc190501.50527100': __('identifierSchemeCodeUnspsc190501.50527100'),
	'identifierSchemeCodeUnspsc190501.50527101': __('identifierSchemeCodeUnspsc190501.50527101'),
	'identifierSchemeCodeUnspsc190501.50527102': __('identifierSchemeCodeUnspsc190501.50527102'),
	'identifierSchemeCodeUnspsc190501.50527103': __('identifierSchemeCodeUnspsc190501.50527103'),
	'identifierSchemeCodeUnspsc190501.50527104': __('identifierSchemeCodeUnspsc190501.50527104'),
	'identifierSchemeCodeUnspsc190501.50527200': __('identifierSchemeCodeUnspsc190501.50527200'),
	'identifierSchemeCodeUnspsc190501.50527201': __('identifierSchemeCodeUnspsc190501.50527201'),
	'identifierSchemeCodeUnspsc190501.50527202': __('identifierSchemeCodeUnspsc190501.50527202'),
	'identifierSchemeCodeUnspsc190501.50527203': __('identifierSchemeCodeUnspsc190501.50527203'),
	'identifierSchemeCodeUnspsc190501.50527204': __('identifierSchemeCodeUnspsc190501.50527204'),
	'identifierSchemeCodeUnspsc190501.50527205': __('identifierSchemeCodeUnspsc190501.50527205'),
	'identifierSchemeCodeUnspsc190501.50527206': __('identifierSchemeCodeUnspsc190501.50527206'),
	'identifierSchemeCodeUnspsc190501.50527207': __('identifierSchemeCodeUnspsc190501.50527207'),
	'identifierSchemeCodeUnspsc190501.50527208': __('identifierSchemeCodeUnspsc190501.50527208'),
	'identifierSchemeCodeUnspsc190501.50527209': __('identifierSchemeCodeUnspsc190501.50527209'),
	'identifierSchemeCodeUnspsc190501.50527210': __('identifierSchemeCodeUnspsc190501.50527210'),
	'identifierSchemeCodeUnspsc190501.50527211': __('identifierSchemeCodeUnspsc190501.50527211'),
	'identifierSchemeCodeUnspsc190501.50527212': __('identifierSchemeCodeUnspsc190501.50527212'),
	'identifierSchemeCodeUnspsc190501.50527213': __('identifierSchemeCodeUnspsc190501.50527213'),
	'identifierSchemeCodeUnspsc190501.50527214': __('identifierSchemeCodeUnspsc190501.50527214'),
	'identifierSchemeCodeUnspsc190501.50527215': __('identifierSchemeCodeUnspsc190501.50527215'),
	'identifierSchemeCodeUnspsc190501.50527216': __('identifierSchemeCodeUnspsc190501.50527216'),
	'identifierSchemeCodeUnspsc190501.50527217': __('identifierSchemeCodeUnspsc190501.50527217'),
	'identifierSchemeCodeUnspsc190501.50527218': __('identifierSchemeCodeUnspsc190501.50527218'),
	'identifierSchemeCodeUnspsc190501.50527219': __('identifierSchemeCodeUnspsc190501.50527219'),
	'identifierSchemeCodeUnspsc190501.50527220': __('identifierSchemeCodeUnspsc190501.50527220'),
	'identifierSchemeCodeUnspsc190501.50527221': __('identifierSchemeCodeUnspsc190501.50527221'),
	'identifierSchemeCodeUnspsc190501.50527222': __('identifierSchemeCodeUnspsc190501.50527222'),
	'identifierSchemeCodeUnspsc190501.50527223': __('identifierSchemeCodeUnspsc190501.50527223'),
	'identifierSchemeCodeUnspsc190501.50527224': __('identifierSchemeCodeUnspsc190501.50527224'),
	'identifierSchemeCodeUnspsc190501.50527225': __('identifierSchemeCodeUnspsc190501.50527225'),
	'identifierSchemeCodeUnspsc190501.50527226': __('identifierSchemeCodeUnspsc190501.50527226'),
	'identifierSchemeCodeUnspsc190501.50527227': __('identifierSchemeCodeUnspsc190501.50527227'),
	'identifierSchemeCodeUnspsc190501.50527228': __('identifierSchemeCodeUnspsc190501.50527228'),
	'identifierSchemeCodeUnspsc190501.50527229': __('identifierSchemeCodeUnspsc190501.50527229'),
	'identifierSchemeCodeUnspsc190501.50527230': __('identifierSchemeCodeUnspsc190501.50527230'),
	'identifierSchemeCodeUnspsc190501.50530000': __('identifierSchemeCodeUnspsc190501.50530000'),
	'identifierSchemeCodeUnspsc190501.50531500': __('identifierSchemeCodeUnspsc190501.50531500'),
	'identifierSchemeCodeUnspsc190501.50531501': __('identifierSchemeCodeUnspsc190501.50531501'),
	'identifierSchemeCodeUnspsc190501.50531502': __('identifierSchemeCodeUnspsc190501.50531502'),
	'identifierSchemeCodeUnspsc190501.50531503': __('identifierSchemeCodeUnspsc190501.50531503'),
	'identifierSchemeCodeUnspsc190501.50531504': __('identifierSchemeCodeUnspsc190501.50531504'),
	'identifierSchemeCodeUnspsc190501.50531505': __('identifierSchemeCodeUnspsc190501.50531505'),
	'identifierSchemeCodeUnspsc190501.50531506': __('identifierSchemeCodeUnspsc190501.50531506'),
	'identifierSchemeCodeUnspsc190501.50531507': __('identifierSchemeCodeUnspsc190501.50531507'),
	'identifierSchemeCodeUnspsc190501.50531508': __('identifierSchemeCodeUnspsc190501.50531508'),
	'identifierSchemeCodeUnspsc190501.50531509': __('identifierSchemeCodeUnspsc190501.50531509'),
	'identifierSchemeCodeUnspsc190501.50531510': __('identifierSchemeCodeUnspsc190501.50531510'),
	'identifierSchemeCodeUnspsc190501.50531511': __('identifierSchemeCodeUnspsc190501.50531511'),
	'identifierSchemeCodeUnspsc190501.50531512': __('identifierSchemeCodeUnspsc190501.50531512'),
	'identifierSchemeCodeUnspsc190501.50531513': __('identifierSchemeCodeUnspsc190501.50531513'),
	'identifierSchemeCodeUnspsc190501.50531514': __('identifierSchemeCodeUnspsc190501.50531514'),
	'identifierSchemeCodeUnspsc190501.50531515': __('identifierSchemeCodeUnspsc190501.50531515'),
	'identifierSchemeCodeUnspsc190501.50531516': __('identifierSchemeCodeUnspsc190501.50531516'),
	'identifierSchemeCodeUnspsc190501.50531517': __('identifierSchemeCodeUnspsc190501.50531517'),
	'identifierSchemeCodeUnspsc190501.50531518': __('identifierSchemeCodeUnspsc190501.50531518'),
	'identifierSchemeCodeUnspsc190501.50531519': __('identifierSchemeCodeUnspsc190501.50531519'),
	'identifierSchemeCodeUnspsc190501.50531520': __('identifierSchemeCodeUnspsc190501.50531520'),
	'identifierSchemeCodeUnspsc190501.50531521': __('identifierSchemeCodeUnspsc190501.50531521'),
	'identifierSchemeCodeUnspsc190501.50531522': __('identifierSchemeCodeUnspsc190501.50531522'),
	'identifierSchemeCodeUnspsc190501.50531523': __('identifierSchemeCodeUnspsc190501.50531523'),
	'identifierSchemeCodeUnspsc190501.50531524': __('identifierSchemeCodeUnspsc190501.50531524'),
	'identifierSchemeCodeUnspsc190501.50531525': __('identifierSchemeCodeUnspsc190501.50531525'),
	'identifierSchemeCodeUnspsc190501.50531526': __('identifierSchemeCodeUnspsc190501.50531526'),
	'identifierSchemeCodeUnspsc190501.50531527': __('identifierSchemeCodeUnspsc190501.50531527'),
	'identifierSchemeCodeUnspsc190501.50531528': __('identifierSchemeCodeUnspsc190501.50531528'),
	'identifierSchemeCodeUnspsc190501.50531529': __('identifierSchemeCodeUnspsc190501.50531529'),
	'identifierSchemeCodeUnspsc190501.50531530': __('identifierSchemeCodeUnspsc190501.50531530'),
	'identifierSchemeCodeUnspsc190501.50531531': __('identifierSchemeCodeUnspsc190501.50531531'),
	'identifierSchemeCodeUnspsc190501.50531532': __('identifierSchemeCodeUnspsc190501.50531532'),
	'identifierSchemeCodeUnspsc190501.50531533': __('identifierSchemeCodeUnspsc190501.50531533'),
	'identifierSchemeCodeUnspsc190501.50531534': __('identifierSchemeCodeUnspsc190501.50531534'),
	'identifierSchemeCodeUnspsc190501.50531535': __('identifierSchemeCodeUnspsc190501.50531535'),
	'identifierSchemeCodeUnspsc190501.50531536': __('identifierSchemeCodeUnspsc190501.50531536'),
	'identifierSchemeCodeUnspsc190501.50531537': __('identifierSchemeCodeUnspsc190501.50531537'),
	'identifierSchemeCodeUnspsc190501.50531538': __('identifierSchemeCodeUnspsc190501.50531538'),
	'identifierSchemeCodeUnspsc190501.50531539': __('identifierSchemeCodeUnspsc190501.50531539'),
	'identifierSchemeCodeUnspsc190501.50531540': __('identifierSchemeCodeUnspsc190501.50531540'),
	'identifierSchemeCodeUnspsc190501.50531541': __('identifierSchemeCodeUnspsc190501.50531541'),
	'identifierSchemeCodeUnspsc190501.50531542': __('identifierSchemeCodeUnspsc190501.50531542'),
	'identifierSchemeCodeUnspsc190501.50531543': __('identifierSchemeCodeUnspsc190501.50531543'),
	'identifierSchemeCodeUnspsc190501.50531544': __('identifierSchemeCodeUnspsc190501.50531544'),
	'identifierSchemeCodeUnspsc190501.50531545': __('identifierSchemeCodeUnspsc190501.50531545'),
	'identifierSchemeCodeUnspsc190501.50531546': __('identifierSchemeCodeUnspsc190501.50531546'),
	'identifierSchemeCodeUnspsc190501.50531547': __('identifierSchemeCodeUnspsc190501.50531547'),
	'identifierSchemeCodeUnspsc190501.50531548': __('identifierSchemeCodeUnspsc190501.50531548'),
	'identifierSchemeCodeUnspsc190501.50531549': __('identifierSchemeCodeUnspsc190501.50531549'),
	'identifierSchemeCodeUnspsc190501.50531550': __('identifierSchemeCodeUnspsc190501.50531550'),
	'identifierSchemeCodeUnspsc190501.50531551': __('identifierSchemeCodeUnspsc190501.50531551'),
	'identifierSchemeCodeUnspsc190501.50531552': __('identifierSchemeCodeUnspsc190501.50531552'),
	'identifierSchemeCodeUnspsc190501.50531553': __('identifierSchemeCodeUnspsc190501.50531553'),
	'identifierSchemeCodeUnspsc190501.50531554': __('identifierSchemeCodeUnspsc190501.50531554'),
	'identifierSchemeCodeUnspsc190501.50531555': __('identifierSchemeCodeUnspsc190501.50531555'),
	'identifierSchemeCodeUnspsc190501.50531556': __('identifierSchemeCodeUnspsc190501.50531556'),
	'identifierSchemeCodeUnspsc190501.50531557': __('identifierSchemeCodeUnspsc190501.50531557'),
	'identifierSchemeCodeUnspsc190501.50531558': __('identifierSchemeCodeUnspsc190501.50531558'),
	'identifierSchemeCodeUnspsc190501.50531559': __('identifierSchemeCodeUnspsc190501.50531559'),
	'identifierSchemeCodeUnspsc190501.50531560': __('identifierSchemeCodeUnspsc190501.50531560'),
	'identifierSchemeCodeUnspsc190501.50531561': __('identifierSchemeCodeUnspsc190501.50531561'),
	'identifierSchemeCodeUnspsc190501.50531562': __('identifierSchemeCodeUnspsc190501.50531562'),
	'identifierSchemeCodeUnspsc190501.50531563': __('identifierSchemeCodeUnspsc190501.50531563'),
	'identifierSchemeCodeUnspsc190501.50531564': __('identifierSchemeCodeUnspsc190501.50531564'),
	'identifierSchemeCodeUnspsc190501.50531565': __('identifierSchemeCodeUnspsc190501.50531565'),
	'identifierSchemeCodeUnspsc190501.50531566': __('identifierSchemeCodeUnspsc190501.50531566'),
	'identifierSchemeCodeUnspsc190501.50531567': __('identifierSchemeCodeUnspsc190501.50531567'),
	'identifierSchemeCodeUnspsc190501.50531568': __('identifierSchemeCodeUnspsc190501.50531568'),
	'identifierSchemeCodeUnspsc190501.50531569': __('identifierSchemeCodeUnspsc190501.50531569'),
	'identifierSchemeCodeUnspsc190501.50531570': __('identifierSchemeCodeUnspsc190501.50531570'),
	'identifierSchemeCodeUnspsc190501.50531571': __('identifierSchemeCodeUnspsc190501.50531571'),
	'identifierSchemeCodeUnspsc190501.50531572': __('identifierSchemeCodeUnspsc190501.50531572'),
	'identifierSchemeCodeUnspsc190501.50531573': __('identifierSchemeCodeUnspsc190501.50531573'),
	'identifierSchemeCodeUnspsc190501.50531574': __('identifierSchemeCodeUnspsc190501.50531574'),
	'identifierSchemeCodeUnspsc190501.50531575': __('identifierSchemeCodeUnspsc190501.50531575'),
	'identifierSchemeCodeUnspsc190501.50531576': __('identifierSchemeCodeUnspsc190501.50531576'),
	'identifierSchemeCodeUnspsc190501.50531577': __('identifierSchemeCodeUnspsc190501.50531577'),
	'identifierSchemeCodeUnspsc190501.50531578': __('identifierSchemeCodeUnspsc190501.50531578'),
	'identifierSchemeCodeUnspsc190501.50531579': __('identifierSchemeCodeUnspsc190501.50531579'),
	'identifierSchemeCodeUnspsc190501.50531580': __('identifierSchemeCodeUnspsc190501.50531580'),
	'identifierSchemeCodeUnspsc190501.50531581': __('identifierSchemeCodeUnspsc190501.50531581'),
	'identifierSchemeCodeUnspsc190501.50531582': __('identifierSchemeCodeUnspsc190501.50531582'),
	'identifierSchemeCodeUnspsc190501.50531583': __('identifierSchemeCodeUnspsc190501.50531583'),
	'identifierSchemeCodeUnspsc190501.50531584': __('identifierSchemeCodeUnspsc190501.50531584'),
	'identifierSchemeCodeUnspsc190501.50531585': __('identifierSchemeCodeUnspsc190501.50531585'),
	'identifierSchemeCodeUnspsc190501.50531586': __('identifierSchemeCodeUnspsc190501.50531586'),
	'identifierSchemeCodeUnspsc190501.50531587': __('identifierSchemeCodeUnspsc190501.50531587'),
	'identifierSchemeCodeUnspsc190501.50531588': __('identifierSchemeCodeUnspsc190501.50531588'),
	'identifierSchemeCodeUnspsc190501.50531589': __('identifierSchemeCodeUnspsc190501.50531589'),
	'identifierSchemeCodeUnspsc190501.50531590': __('identifierSchemeCodeUnspsc190501.50531590'),
	'identifierSchemeCodeUnspsc190501.50531591': __('identifierSchemeCodeUnspsc190501.50531591'),
	'identifierSchemeCodeUnspsc190501.50531592': __('identifierSchemeCodeUnspsc190501.50531592'),
	'identifierSchemeCodeUnspsc190501.50531593': __('identifierSchemeCodeUnspsc190501.50531593'),
	'identifierSchemeCodeUnspsc190501.50531594': __('identifierSchemeCodeUnspsc190501.50531594'),
	'identifierSchemeCodeUnspsc190501.50531595': __('identifierSchemeCodeUnspsc190501.50531595'),
	'identifierSchemeCodeUnspsc190501.50531596': __('identifierSchemeCodeUnspsc190501.50531596'),
	'identifierSchemeCodeUnspsc190501.50531600': __('identifierSchemeCodeUnspsc190501.50531600'),
	'identifierSchemeCodeUnspsc190501.50531601': __('identifierSchemeCodeUnspsc190501.50531601'),
	'identifierSchemeCodeUnspsc190501.50531602': __('identifierSchemeCodeUnspsc190501.50531602'),
	'identifierSchemeCodeUnspsc190501.50531603': __('identifierSchemeCodeUnspsc190501.50531603'),
	'identifierSchemeCodeUnspsc190501.50531604': __('identifierSchemeCodeUnspsc190501.50531604'),
	'identifierSchemeCodeUnspsc190501.50531605': __('identifierSchemeCodeUnspsc190501.50531605'),
	'identifierSchemeCodeUnspsc190501.50531606': __('identifierSchemeCodeUnspsc190501.50531606'),
	'identifierSchemeCodeUnspsc190501.50531607': __('identifierSchemeCodeUnspsc190501.50531607'),
	'identifierSchemeCodeUnspsc190501.50531608': __('identifierSchemeCodeUnspsc190501.50531608'),
	'identifierSchemeCodeUnspsc190501.50531609': __('identifierSchemeCodeUnspsc190501.50531609'),
	'identifierSchemeCodeUnspsc190501.50531610': __('identifierSchemeCodeUnspsc190501.50531610'),
	'identifierSchemeCodeUnspsc190501.50531611': __('identifierSchemeCodeUnspsc190501.50531611'),
	'identifierSchemeCodeUnspsc190501.50531612': __('identifierSchemeCodeUnspsc190501.50531612'),
	'identifierSchemeCodeUnspsc190501.50531613': __('identifierSchemeCodeUnspsc190501.50531613'),
	'identifierSchemeCodeUnspsc190501.50531614': __('identifierSchemeCodeUnspsc190501.50531614'),
	'identifierSchemeCodeUnspsc190501.50531615': __('identifierSchemeCodeUnspsc190501.50531615'),
	'identifierSchemeCodeUnspsc190501.50531616': __('identifierSchemeCodeUnspsc190501.50531616'),
	'identifierSchemeCodeUnspsc190501.50531617': __('identifierSchemeCodeUnspsc190501.50531617'),
	'identifierSchemeCodeUnspsc190501.50531618': __('identifierSchemeCodeUnspsc190501.50531618'),
	'identifierSchemeCodeUnspsc190501.50531619': __('identifierSchemeCodeUnspsc190501.50531619'),
	'identifierSchemeCodeUnspsc190501.50531620': __('identifierSchemeCodeUnspsc190501.50531620'),
	'identifierSchemeCodeUnspsc190501.50531621': __('identifierSchemeCodeUnspsc190501.50531621'),
	'identifierSchemeCodeUnspsc190501.50531622': __('identifierSchemeCodeUnspsc190501.50531622'),
	'identifierSchemeCodeUnspsc190501.50531623': __('identifierSchemeCodeUnspsc190501.50531623'),
	'identifierSchemeCodeUnspsc190501.50531624': __('identifierSchemeCodeUnspsc190501.50531624'),
	'identifierSchemeCodeUnspsc190501.50531625': __('identifierSchemeCodeUnspsc190501.50531625'),
	'identifierSchemeCodeUnspsc190501.50531626': __('identifierSchemeCodeUnspsc190501.50531626'),
	'identifierSchemeCodeUnspsc190501.50531627': __('identifierSchemeCodeUnspsc190501.50531627'),
	'identifierSchemeCodeUnspsc190501.50531628': __('identifierSchemeCodeUnspsc190501.50531628'),
	'identifierSchemeCodeUnspsc190501.50531629': __('identifierSchemeCodeUnspsc190501.50531629'),
	'identifierSchemeCodeUnspsc190501.50531630': __('identifierSchemeCodeUnspsc190501.50531630'),
	'identifierSchemeCodeUnspsc190501.50531631': __('identifierSchemeCodeUnspsc190501.50531631'),
	'identifierSchemeCodeUnspsc190501.50531632': __('identifierSchemeCodeUnspsc190501.50531632'),
	'identifierSchemeCodeUnspsc190501.50531633': __('identifierSchemeCodeUnspsc190501.50531633'),
	'identifierSchemeCodeUnspsc190501.50531634': __('identifierSchemeCodeUnspsc190501.50531634'),
	'identifierSchemeCodeUnspsc190501.50531635': __('identifierSchemeCodeUnspsc190501.50531635'),
	'identifierSchemeCodeUnspsc190501.50531636': __('identifierSchemeCodeUnspsc190501.50531636'),
	'identifierSchemeCodeUnspsc190501.50531637': __('identifierSchemeCodeUnspsc190501.50531637'),
	'identifierSchemeCodeUnspsc190501.50531638': __('identifierSchemeCodeUnspsc190501.50531638'),
	'identifierSchemeCodeUnspsc190501.50531639': __('identifierSchemeCodeUnspsc190501.50531639'),
	'identifierSchemeCodeUnspsc190501.50531640': __('identifierSchemeCodeUnspsc190501.50531640'),
	'identifierSchemeCodeUnspsc190501.50531641': __('identifierSchemeCodeUnspsc190501.50531641'),
	'identifierSchemeCodeUnspsc190501.50531642': __('identifierSchemeCodeUnspsc190501.50531642'),
	'identifierSchemeCodeUnspsc190501.50531643': __('identifierSchemeCodeUnspsc190501.50531643'),
	'identifierSchemeCodeUnspsc190501.50531644': __('identifierSchemeCodeUnspsc190501.50531644'),
	'identifierSchemeCodeUnspsc190501.50531645': __('identifierSchemeCodeUnspsc190501.50531645'),
	'identifierSchemeCodeUnspsc190501.50531646': __('identifierSchemeCodeUnspsc190501.50531646'),
	'identifierSchemeCodeUnspsc190501.50531647': __('identifierSchemeCodeUnspsc190501.50531647'),
	'identifierSchemeCodeUnspsc190501.50531648': __('identifierSchemeCodeUnspsc190501.50531648'),
	'identifierSchemeCodeUnspsc190501.50531649': __('identifierSchemeCodeUnspsc190501.50531649'),
	'identifierSchemeCodeUnspsc190501.50531650': __('identifierSchemeCodeUnspsc190501.50531650'),
	'identifierSchemeCodeUnspsc190501.50531651': __('identifierSchemeCodeUnspsc190501.50531651'),
	'identifierSchemeCodeUnspsc190501.50531652': __('identifierSchemeCodeUnspsc190501.50531652'),
	'identifierSchemeCodeUnspsc190501.50531653': __('identifierSchemeCodeUnspsc190501.50531653'),
	'identifierSchemeCodeUnspsc190501.50531654': __('identifierSchemeCodeUnspsc190501.50531654'),
	'identifierSchemeCodeUnspsc190501.50531655': __('identifierSchemeCodeUnspsc190501.50531655'),
	'identifierSchemeCodeUnspsc190501.50531700': __('identifierSchemeCodeUnspsc190501.50531700'),
	'identifierSchemeCodeUnspsc190501.50531701': __('identifierSchemeCodeUnspsc190501.50531701'),
	'identifierSchemeCodeUnspsc190501.50531702': __('identifierSchemeCodeUnspsc190501.50531702'),
	'identifierSchemeCodeUnspsc190501.50531703': __('identifierSchemeCodeUnspsc190501.50531703'),
	'identifierSchemeCodeUnspsc190501.50531704': __('identifierSchemeCodeUnspsc190501.50531704'),
	'identifierSchemeCodeUnspsc190501.50531705': __('identifierSchemeCodeUnspsc190501.50531705'),
	'identifierSchemeCodeUnspsc190501.50531706': __('identifierSchemeCodeUnspsc190501.50531706'),
	'identifierSchemeCodeUnspsc190501.50531707': __('identifierSchemeCodeUnspsc190501.50531707'),
	'identifierSchemeCodeUnspsc190501.50531708': __('identifierSchemeCodeUnspsc190501.50531708'),
	'identifierSchemeCodeUnspsc190501.50531709': __('identifierSchemeCodeUnspsc190501.50531709'),
	'identifierSchemeCodeUnspsc190501.50531710': __('identifierSchemeCodeUnspsc190501.50531710'),
	'identifierSchemeCodeUnspsc190501.50531711': __('identifierSchemeCodeUnspsc190501.50531711'),
	'identifierSchemeCodeUnspsc190501.50531712': __('identifierSchemeCodeUnspsc190501.50531712'),
	'identifierSchemeCodeUnspsc190501.50531713': __('identifierSchemeCodeUnspsc190501.50531713'),
	'identifierSchemeCodeUnspsc190501.50531714': __('identifierSchemeCodeUnspsc190501.50531714'),
	'identifierSchemeCodeUnspsc190501.50531715': __('identifierSchemeCodeUnspsc190501.50531715'),
	'identifierSchemeCodeUnspsc190501.50531800': __('identifierSchemeCodeUnspsc190501.50531800'),
	'identifierSchemeCodeUnspsc190501.50531801': __('identifierSchemeCodeUnspsc190501.50531801'),
	'identifierSchemeCodeUnspsc190501.50531802': __('identifierSchemeCodeUnspsc190501.50531802'),
	'identifierSchemeCodeUnspsc190501.50531803': __('identifierSchemeCodeUnspsc190501.50531803'),
	'identifierSchemeCodeUnspsc190501.50531804': __('identifierSchemeCodeUnspsc190501.50531804'),
	'identifierSchemeCodeUnspsc190501.50531805': __('identifierSchemeCodeUnspsc190501.50531805'),
	'identifierSchemeCodeUnspsc190501.50531806': __('identifierSchemeCodeUnspsc190501.50531806'),
	'identifierSchemeCodeUnspsc190501.50531807': __('identifierSchemeCodeUnspsc190501.50531807'),
	'identifierSchemeCodeUnspsc190501.50531808': __('identifierSchemeCodeUnspsc190501.50531808'),
	'identifierSchemeCodeUnspsc190501.50531809': __('identifierSchemeCodeUnspsc190501.50531809'),
	'identifierSchemeCodeUnspsc190501.50531810': __('identifierSchemeCodeUnspsc190501.50531810'),
	'identifierSchemeCodeUnspsc190501.50531811': __('identifierSchemeCodeUnspsc190501.50531811'),
	'identifierSchemeCodeUnspsc190501.50531812': __('identifierSchemeCodeUnspsc190501.50531812'),
	'identifierSchemeCodeUnspsc190501.50531813': __('identifierSchemeCodeUnspsc190501.50531813'),
	'identifierSchemeCodeUnspsc190501.50531814': __('identifierSchemeCodeUnspsc190501.50531814'),
	'identifierSchemeCodeUnspsc190501.50531900': __('identifierSchemeCodeUnspsc190501.50531900'),
	'identifierSchemeCodeUnspsc190501.50531901': __('identifierSchemeCodeUnspsc190501.50531901'),
	'identifierSchemeCodeUnspsc190501.50531902': __('identifierSchemeCodeUnspsc190501.50531902'),
	'identifierSchemeCodeUnspsc190501.50531903': __('identifierSchemeCodeUnspsc190501.50531903'),
	'identifierSchemeCodeUnspsc190501.50532000': __('identifierSchemeCodeUnspsc190501.50532000'),
	'identifierSchemeCodeUnspsc190501.50532001': __('identifierSchemeCodeUnspsc190501.50532001'),
	'identifierSchemeCodeUnspsc190501.50532002': __('identifierSchemeCodeUnspsc190501.50532002'),
	'identifierSchemeCodeUnspsc190501.50532003': __('identifierSchemeCodeUnspsc190501.50532003'),
	'identifierSchemeCodeUnspsc190501.50532004': __('identifierSchemeCodeUnspsc190501.50532004'),
	'identifierSchemeCodeUnspsc190501.50532005': __('identifierSchemeCodeUnspsc190501.50532005'),
	'identifierSchemeCodeUnspsc190501.50532006': __('identifierSchemeCodeUnspsc190501.50532006'),
	'identifierSchemeCodeUnspsc190501.50532007': __('identifierSchemeCodeUnspsc190501.50532007'),
	'identifierSchemeCodeUnspsc190501.50532008': __('identifierSchemeCodeUnspsc190501.50532008'),
	'identifierSchemeCodeUnspsc190501.50532009': __('identifierSchemeCodeUnspsc190501.50532009'),
	'identifierSchemeCodeUnspsc190501.50532010': __('identifierSchemeCodeUnspsc190501.50532010'),
	'identifierSchemeCodeUnspsc190501.50532011': __('identifierSchemeCodeUnspsc190501.50532011'),
	'identifierSchemeCodeUnspsc190501.50532012': __('identifierSchemeCodeUnspsc190501.50532012'),
	'identifierSchemeCodeUnspsc190501.50532013': __('identifierSchemeCodeUnspsc190501.50532013'),
	'identifierSchemeCodeUnspsc190501.50532014': __('identifierSchemeCodeUnspsc190501.50532014'),
	'identifierSchemeCodeUnspsc190501.50532015': __('identifierSchemeCodeUnspsc190501.50532015'),
	'identifierSchemeCodeUnspsc190501.50532100': __('identifierSchemeCodeUnspsc190501.50532100'),
	'identifierSchemeCodeUnspsc190501.50532101': __('identifierSchemeCodeUnspsc190501.50532101'),
	'identifierSchemeCodeUnspsc190501.50532102': __('identifierSchemeCodeUnspsc190501.50532102'),
	'identifierSchemeCodeUnspsc190501.50532103': __('identifierSchemeCodeUnspsc190501.50532103'),
	'identifierSchemeCodeUnspsc190501.50532104': __('identifierSchemeCodeUnspsc190501.50532104'),
	'identifierSchemeCodeUnspsc190501.50532200': __('identifierSchemeCodeUnspsc190501.50532200'),
	'identifierSchemeCodeUnspsc190501.50532201': __('identifierSchemeCodeUnspsc190501.50532201'),
	'identifierSchemeCodeUnspsc190501.50532202': __('identifierSchemeCodeUnspsc190501.50532202'),
	'identifierSchemeCodeUnspsc190501.50532203': __('identifierSchemeCodeUnspsc190501.50532203'),
	'identifierSchemeCodeUnspsc190501.50532204': __('identifierSchemeCodeUnspsc190501.50532204'),
	'identifierSchemeCodeUnspsc190501.50532205': __('identifierSchemeCodeUnspsc190501.50532205'),
	'identifierSchemeCodeUnspsc190501.50532206': __('identifierSchemeCodeUnspsc190501.50532206'),
	'identifierSchemeCodeUnspsc190501.50532207': __('identifierSchemeCodeUnspsc190501.50532207'),
	'identifierSchemeCodeUnspsc190501.50532208': __('identifierSchemeCodeUnspsc190501.50532208'),
	'identifierSchemeCodeUnspsc190501.50532209': __('identifierSchemeCodeUnspsc190501.50532209'),
	'identifierSchemeCodeUnspsc190501.50532210': __('identifierSchemeCodeUnspsc190501.50532210'),
	'identifierSchemeCodeUnspsc190501.50532211': __('identifierSchemeCodeUnspsc190501.50532211'),
	'identifierSchemeCodeUnspsc190501.50532212': __('identifierSchemeCodeUnspsc190501.50532212'),
	'identifierSchemeCodeUnspsc190501.50532213': __('identifierSchemeCodeUnspsc190501.50532213'),
	'identifierSchemeCodeUnspsc190501.50532214': __('identifierSchemeCodeUnspsc190501.50532214'),
	'identifierSchemeCodeUnspsc190501.50532215': __('identifierSchemeCodeUnspsc190501.50532215'),
	'identifierSchemeCodeUnspsc190501.50532300': __('identifierSchemeCodeUnspsc190501.50532300'),
	'identifierSchemeCodeUnspsc190501.50532301': __('identifierSchemeCodeUnspsc190501.50532301'),
	'identifierSchemeCodeUnspsc190501.50532302': __('identifierSchemeCodeUnspsc190501.50532302'),
	'identifierSchemeCodeUnspsc190501.50532303': __('identifierSchemeCodeUnspsc190501.50532303'),
	'identifierSchemeCodeUnspsc190501.50532304': __('identifierSchemeCodeUnspsc190501.50532304'),
	'identifierSchemeCodeUnspsc190501.50532400': __('identifierSchemeCodeUnspsc190501.50532400'),
	'identifierSchemeCodeUnspsc190501.50532401': __('identifierSchemeCodeUnspsc190501.50532401'),
	'identifierSchemeCodeUnspsc190501.50532402': __('identifierSchemeCodeUnspsc190501.50532402'),
	'identifierSchemeCodeUnspsc190501.50532403': __('identifierSchemeCodeUnspsc190501.50532403'),
	'identifierSchemeCodeUnspsc190501.50532404': __('identifierSchemeCodeUnspsc190501.50532404'),
	'identifierSchemeCodeUnspsc190501.50532405': __('identifierSchemeCodeUnspsc190501.50532405'),
	'identifierSchemeCodeUnspsc190501.50532406': __('identifierSchemeCodeUnspsc190501.50532406'),
	'identifierSchemeCodeUnspsc190501.50532407': __('identifierSchemeCodeUnspsc190501.50532407'),
	'identifierSchemeCodeUnspsc190501.50532408': __('identifierSchemeCodeUnspsc190501.50532408'),
	'identifierSchemeCodeUnspsc190501.50532500': __('identifierSchemeCodeUnspsc190501.50532500'),
	'identifierSchemeCodeUnspsc190501.50532501': __('identifierSchemeCodeUnspsc190501.50532501'),
	'identifierSchemeCodeUnspsc190501.50532502': __('identifierSchemeCodeUnspsc190501.50532502'),
	'identifierSchemeCodeUnspsc190501.50532503': __('identifierSchemeCodeUnspsc190501.50532503'),
	'identifierSchemeCodeUnspsc190501.50532504': __('identifierSchemeCodeUnspsc190501.50532504'),
	'identifierSchemeCodeUnspsc190501.50532505': __('identifierSchemeCodeUnspsc190501.50532505'),
	'identifierSchemeCodeUnspsc190501.50532506': __('identifierSchemeCodeUnspsc190501.50532506'),
	'identifierSchemeCodeUnspsc190501.50532507': __('identifierSchemeCodeUnspsc190501.50532507'),
	'identifierSchemeCodeUnspsc190501.50532508': __('identifierSchemeCodeUnspsc190501.50532508'),
	'identifierSchemeCodeUnspsc190501.50532509': __('identifierSchemeCodeUnspsc190501.50532509'),
	'identifierSchemeCodeUnspsc190501.50532510': __('identifierSchemeCodeUnspsc190501.50532510'),
	'identifierSchemeCodeUnspsc190501.50532511': __('identifierSchemeCodeUnspsc190501.50532511'),
	'identifierSchemeCodeUnspsc190501.50532512': __('identifierSchemeCodeUnspsc190501.50532512'),
	'identifierSchemeCodeUnspsc190501.50532513': __('identifierSchemeCodeUnspsc190501.50532513'),
	'identifierSchemeCodeUnspsc190501.50532514': __('identifierSchemeCodeUnspsc190501.50532514'),
	'identifierSchemeCodeUnspsc190501.50532515': __('identifierSchemeCodeUnspsc190501.50532515'),
	'identifierSchemeCodeUnspsc190501.50532516': __('identifierSchemeCodeUnspsc190501.50532516'),
	'identifierSchemeCodeUnspsc190501.50532517': __('identifierSchemeCodeUnspsc190501.50532517'),
	'identifierSchemeCodeUnspsc190501.50532518': __('identifierSchemeCodeUnspsc190501.50532518'),
	'identifierSchemeCodeUnspsc190501.50532519': __('identifierSchemeCodeUnspsc190501.50532519'),
	'identifierSchemeCodeUnspsc190501.50532520': __('identifierSchemeCodeUnspsc190501.50532520'),
	'identifierSchemeCodeUnspsc190501.50532521': __('identifierSchemeCodeUnspsc190501.50532521'),
	'identifierSchemeCodeUnspsc190501.50532522': __('identifierSchemeCodeUnspsc190501.50532522'),
	'identifierSchemeCodeUnspsc190501.50532523': __('identifierSchemeCodeUnspsc190501.50532523'),
	'identifierSchemeCodeUnspsc190501.50532524': __('identifierSchemeCodeUnspsc190501.50532524'),
	'identifierSchemeCodeUnspsc190501.50532525': __('identifierSchemeCodeUnspsc190501.50532525'),
	'identifierSchemeCodeUnspsc190501.50532526': __('identifierSchemeCodeUnspsc190501.50532526'),
	'identifierSchemeCodeUnspsc190501.50532527': __('identifierSchemeCodeUnspsc190501.50532527'),
	'identifierSchemeCodeUnspsc190501.50532528': __('identifierSchemeCodeUnspsc190501.50532528'),
	'identifierSchemeCodeUnspsc190501.50532529': __('identifierSchemeCodeUnspsc190501.50532529'),
	'identifierSchemeCodeUnspsc190501.50532530': __('identifierSchemeCodeUnspsc190501.50532530'),
	'identifierSchemeCodeUnspsc190501.50532531': __('identifierSchemeCodeUnspsc190501.50532531'),
	'identifierSchemeCodeUnspsc190501.50532600': __('identifierSchemeCodeUnspsc190501.50532600'),
	'identifierSchemeCodeUnspsc190501.50532601': __('identifierSchemeCodeUnspsc190501.50532601'),
	'identifierSchemeCodeUnspsc190501.50532602': __('identifierSchemeCodeUnspsc190501.50532602'),
	'identifierSchemeCodeUnspsc190501.50532603': __('identifierSchemeCodeUnspsc190501.50532603'),
	'identifierSchemeCodeUnspsc190501.50532604': __('identifierSchemeCodeUnspsc190501.50532604'),
	'identifierSchemeCodeUnspsc190501.50532605': __('identifierSchemeCodeUnspsc190501.50532605'),
	'identifierSchemeCodeUnspsc190501.50532606': __('identifierSchemeCodeUnspsc190501.50532606'),
	'identifierSchemeCodeUnspsc190501.50532607': __('identifierSchemeCodeUnspsc190501.50532607'),
	'identifierSchemeCodeUnspsc190501.50532700': __('identifierSchemeCodeUnspsc190501.50532700'),
	'identifierSchemeCodeUnspsc190501.50532701': __('identifierSchemeCodeUnspsc190501.50532701'),
	'identifierSchemeCodeUnspsc190501.50532702': __('identifierSchemeCodeUnspsc190501.50532702'),
	'identifierSchemeCodeUnspsc190501.50532703': __('identifierSchemeCodeUnspsc190501.50532703'),
	'identifierSchemeCodeUnspsc190501.50532704': __('identifierSchemeCodeUnspsc190501.50532704'),
	'identifierSchemeCodeUnspsc190501.50532705': __('identifierSchemeCodeUnspsc190501.50532705'),
	'identifierSchemeCodeUnspsc190501.50532706': __('identifierSchemeCodeUnspsc190501.50532706'),
	'identifierSchemeCodeUnspsc190501.50532707': __('identifierSchemeCodeUnspsc190501.50532707'),
	'identifierSchemeCodeUnspsc190501.50532708': __('identifierSchemeCodeUnspsc190501.50532708'),
	'identifierSchemeCodeUnspsc190501.50532709': __('identifierSchemeCodeUnspsc190501.50532709'),
	'identifierSchemeCodeUnspsc190501.50532710': __('identifierSchemeCodeUnspsc190501.50532710'),
	'identifierSchemeCodeUnspsc190501.50532800': __('identifierSchemeCodeUnspsc190501.50532800'),
	'identifierSchemeCodeUnspsc190501.50532801': __('identifierSchemeCodeUnspsc190501.50532801'),
	'identifierSchemeCodeUnspsc190501.50532802': __('identifierSchemeCodeUnspsc190501.50532802'),
	'identifierSchemeCodeUnspsc190501.50532803': __('identifierSchemeCodeUnspsc190501.50532803'),
	'identifierSchemeCodeUnspsc190501.50532804': __('identifierSchemeCodeUnspsc190501.50532804'),
	'identifierSchemeCodeUnspsc190501.50532805': __('identifierSchemeCodeUnspsc190501.50532805'),
	'identifierSchemeCodeUnspsc190501.50532806': __('identifierSchemeCodeUnspsc190501.50532806'),
	'identifierSchemeCodeUnspsc190501.50532900': __('identifierSchemeCodeUnspsc190501.50532900'),
	'identifierSchemeCodeUnspsc190501.50532901': __('identifierSchemeCodeUnspsc190501.50532901'),
	'identifierSchemeCodeUnspsc190501.50532902': __('identifierSchemeCodeUnspsc190501.50532902'),
	'identifierSchemeCodeUnspsc190501.50532903': __('identifierSchemeCodeUnspsc190501.50532903'),
	'identifierSchemeCodeUnspsc190501.50532904': __('identifierSchemeCodeUnspsc190501.50532904'),
	'identifierSchemeCodeUnspsc190501.50532905': __('identifierSchemeCodeUnspsc190501.50532905'),
	'identifierSchemeCodeUnspsc190501.50532906': __('identifierSchemeCodeUnspsc190501.50532906'),
	'identifierSchemeCodeUnspsc190501.50532907': __('identifierSchemeCodeUnspsc190501.50532907'),
	'identifierSchemeCodeUnspsc190501.50532908': __('identifierSchemeCodeUnspsc190501.50532908'),
	'identifierSchemeCodeUnspsc190501.50532909': __('identifierSchemeCodeUnspsc190501.50532909'),
	'identifierSchemeCodeUnspsc190501.50532910': __('identifierSchemeCodeUnspsc190501.50532910'),
	'identifierSchemeCodeUnspsc190501.50532911': __('identifierSchemeCodeUnspsc190501.50532911'),
	'identifierSchemeCodeUnspsc190501.50532912': __('identifierSchemeCodeUnspsc190501.50532912'),
	'identifierSchemeCodeUnspsc190501.50532913': __('identifierSchemeCodeUnspsc190501.50532913'),
	'identifierSchemeCodeUnspsc190501.50532914': __('identifierSchemeCodeUnspsc190501.50532914'),
	'identifierSchemeCodeUnspsc190501.50532915': __('identifierSchemeCodeUnspsc190501.50532915'),
	'identifierSchemeCodeUnspsc190501.50532916': __('identifierSchemeCodeUnspsc190501.50532916'),
	'identifierSchemeCodeUnspsc190501.50533000': __('identifierSchemeCodeUnspsc190501.50533000'),
	'identifierSchemeCodeUnspsc190501.50533001': __('identifierSchemeCodeUnspsc190501.50533001'),
	'identifierSchemeCodeUnspsc190501.50533002': __('identifierSchemeCodeUnspsc190501.50533002'),
	'identifierSchemeCodeUnspsc190501.50533100': __('identifierSchemeCodeUnspsc190501.50533100'),
	'identifierSchemeCodeUnspsc190501.50533101': __('identifierSchemeCodeUnspsc190501.50533101'),
	'identifierSchemeCodeUnspsc190501.50533102': __('identifierSchemeCodeUnspsc190501.50533102'),
	'identifierSchemeCodeUnspsc190501.50533103': __('identifierSchemeCodeUnspsc190501.50533103'),
	'identifierSchemeCodeUnspsc190501.50533104': __('identifierSchemeCodeUnspsc190501.50533104'),
	'identifierSchemeCodeUnspsc190501.50533105': __('identifierSchemeCodeUnspsc190501.50533105'),
	'identifierSchemeCodeUnspsc190501.50533106': __('identifierSchemeCodeUnspsc190501.50533106'),
	'identifierSchemeCodeUnspsc190501.50533107': __('identifierSchemeCodeUnspsc190501.50533107'),
	'identifierSchemeCodeUnspsc190501.50533108': __('identifierSchemeCodeUnspsc190501.50533108'),
	'identifierSchemeCodeUnspsc190501.50533109': __('identifierSchemeCodeUnspsc190501.50533109'),
	'identifierSchemeCodeUnspsc190501.50533110': __('identifierSchemeCodeUnspsc190501.50533110'),
	'identifierSchemeCodeUnspsc190501.50533111': __('identifierSchemeCodeUnspsc190501.50533111'),
	'identifierSchemeCodeUnspsc190501.50533200': __('identifierSchemeCodeUnspsc190501.50533200'),
	'identifierSchemeCodeUnspsc190501.50533201': __('identifierSchemeCodeUnspsc190501.50533201'),
	'identifierSchemeCodeUnspsc190501.50533202': __('identifierSchemeCodeUnspsc190501.50533202'),
	'identifierSchemeCodeUnspsc190501.50533203': __('identifierSchemeCodeUnspsc190501.50533203'),
	'identifierSchemeCodeUnspsc190501.50533204': __('identifierSchemeCodeUnspsc190501.50533204'),
	'identifierSchemeCodeUnspsc190501.50533205': __('identifierSchemeCodeUnspsc190501.50533205'),
	'identifierSchemeCodeUnspsc190501.50533206': __('identifierSchemeCodeUnspsc190501.50533206'),
	'identifierSchemeCodeUnspsc190501.50533207': __('identifierSchemeCodeUnspsc190501.50533207'),
	'identifierSchemeCodeUnspsc190501.50533300': __('identifierSchemeCodeUnspsc190501.50533300'),
	'identifierSchemeCodeUnspsc190501.50533301': __('identifierSchemeCodeUnspsc190501.50533301'),
	'identifierSchemeCodeUnspsc190501.50533302': __('identifierSchemeCodeUnspsc190501.50533302'),
	'identifierSchemeCodeUnspsc190501.50533303': __('identifierSchemeCodeUnspsc190501.50533303'),
	'identifierSchemeCodeUnspsc190501.50533304': __('identifierSchemeCodeUnspsc190501.50533304'),
	'identifierSchemeCodeUnspsc190501.50533305': __('identifierSchemeCodeUnspsc190501.50533305'),
	'identifierSchemeCodeUnspsc190501.50533306': __('identifierSchemeCodeUnspsc190501.50533306'),
	'identifierSchemeCodeUnspsc190501.50533307': __('identifierSchemeCodeUnspsc190501.50533307'),
	'identifierSchemeCodeUnspsc190501.50533308': __('identifierSchemeCodeUnspsc190501.50533308'),
	'identifierSchemeCodeUnspsc190501.50533309': __('identifierSchemeCodeUnspsc190501.50533309'),
	'identifierSchemeCodeUnspsc190501.50533400': __('identifierSchemeCodeUnspsc190501.50533400'),
	'identifierSchemeCodeUnspsc190501.50533401': __('identifierSchemeCodeUnspsc190501.50533401'),
	'identifierSchemeCodeUnspsc190501.50533402': __('identifierSchemeCodeUnspsc190501.50533402'),
	'identifierSchemeCodeUnspsc190501.50533403': __('identifierSchemeCodeUnspsc190501.50533403'),
	'identifierSchemeCodeUnspsc190501.50533404': __('identifierSchemeCodeUnspsc190501.50533404'),
	'identifierSchemeCodeUnspsc190501.50533405': __('identifierSchemeCodeUnspsc190501.50533405'),
	'identifierSchemeCodeUnspsc190501.50533406': __('identifierSchemeCodeUnspsc190501.50533406'),
	'identifierSchemeCodeUnspsc190501.50533407': __('identifierSchemeCodeUnspsc190501.50533407'),
	'identifierSchemeCodeUnspsc190501.50533408': __('identifierSchemeCodeUnspsc190501.50533408'),
	'identifierSchemeCodeUnspsc190501.50533409': __('identifierSchemeCodeUnspsc190501.50533409'),
	'identifierSchemeCodeUnspsc190501.50533410': __('identifierSchemeCodeUnspsc190501.50533410'),
	'identifierSchemeCodeUnspsc190501.50533411': __('identifierSchemeCodeUnspsc190501.50533411'),
	'identifierSchemeCodeUnspsc190501.50533412': __('identifierSchemeCodeUnspsc190501.50533412'),
	'identifierSchemeCodeUnspsc190501.50533413': __('identifierSchemeCodeUnspsc190501.50533413'),
	'identifierSchemeCodeUnspsc190501.50533414': __('identifierSchemeCodeUnspsc190501.50533414'),
	'identifierSchemeCodeUnspsc190501.50533415': __('identifierSchemeCodeUnspsc190501.50533415'),
	'identifierSchemeCodeUnspsc190501.50533416': __('identifierSchemeCodeUnspsc190501.50533416'),
	'identifierSchemeCodeUnspsc190501.50533417': __('identifierSchemeCodeUnspsc190501.50533417'),
	'identifierSchemeCodeUnspsc190501.50533418': __('identifierSchemeCodeUnspsc190501.50533418'),
	'identifierSchemeCodeUnspsc190501.50533419': __('identifierSchemeCodeUnspsc190501.50533419'),
	'identifierSchemeCodeUnspsc190501.50533420': __('identifierSchemeCodeUnspsc190501.50533420'),
	'identifierSchemeCodeUnspsc190501.50533421': __('identifierSchemeCodeUnspsc190501.50533421'),
	'identifierSchemeCodeUnspsc190501.50533422': __('identifierSchemeCodeUnspsc190501.50533422'),
	'identifierSchemeCodeUnspsc190501.50533423': __('identifierSchemeCodeUnspsc190501.50533423'),
	'identifierSchemeCodeUnspsc190501.50533424': __('identifierSchemeCodeUnspsc190501.50533424'),
	'identifierSchemeCodeUnspsc190501.50533425': __('identifierSchemeCodeUnspsc190501.50533425'),
	'identifierSchemeCodeUnspsc190501.50533426': __('identifierSchemeCodeUnspsc190501.50533426'),
	'identifierSchemeCodeUnspsc190501.50533427': __('identifierSchemeCodeUnspsc190501.50533427'),
	'identifierSchemeCodeUnspsc190501.50533428': __('identifierSchemeCodeUnspsc190501.50533428'),
	'identifierSchemeCodeUnspsc190501.50533429': __('identifierSchemeCodeUnspsc190501.50533429'),
	'identifierSchemeCodeUnspsc190501.50533430': __('identifierSchemeCodeUnspsc190501.50533430'),
	'identifierSchemeCodeUnspsc190501.50533431': __('identifierSchemeCodeUnspsc190501.50533431'),
	'identifierSchemeCodeUnspsc190501.50533432': __('identifierSchemeCodeUnspsc190501.50533432'),
	'identifierSchemeCodeUnspsc190501.50533433': __('identifierSchemeCodeUnspsc190501.50533433'),
	'identifierSchemeCodeUnspsc190501.50533434': __('identifierSchemeCodeUnspsc190501.50533434'),
	'identifierSchemeCodeUnspsc190501.50533435': __('identifierSchemeCodeUnspsc190501.50533435'),
	'identifierSchemeCodeUnspsc190501.50533436': __('identifierSchemeCodeUnspsc190501.50533436'),
	'identifierSchemeCodeUnspsc190501.50533437': __('identifierSchemeCodeUnspsc190501.50533437'),
	'identifierSchemeCodeUnspsc190501.50533438': __('identifierSchemeCodeUnspsc190501.50533438'),
	'identifierSchemeCodeUnspsc190501.50533439': __('identifierSchemeCodeUnspsc190501.50533439'),
	'identifierSchemeCodeUnspsc190501.50533440': __('identifierSchemeCodeUnspsc190501.50533440'),
	'identifierSchemeCodeUnspsc190501.50533441': __('identifierSchemeCodeUnspsc190501.50533441'),
	'identifierSchemeCodeUnspsc190501.50533442': __('identifierSchemeCodeUnspsc190501.50533442'),
	'identifierSchemeCodeUnspsc190501.50533443': __('identifierSchemeCodeUnspsc190501.50533443'),
	'identifierSchemeCodeUnspsc190501.50533444': __('identifierSchemeCodeUnspsc190501.50533444'),
	'identifierSchemeCodeUnspsc190501.50533445': __('identifierSchemeCodeUnspsc190501.50533445'),
	'identifierSchemeCodeUnspsc190501.50533446': __('identifierSchemeCodeUnspsc190501.50533446'),
	'identifierSchemeCodeUnspsc190501.50533447': __('identifierSchemeCodeUnspsc190501.50533447'),
	'identifierSchemeCodeUnspsc190501.50533448': __('identifierSchemeCodeUnspsc190501.50533448'),
	'identifierSchemeCodeUnspsc190501.50533449': __('identifierSchemeCodeUnspsc190501.50533449'),
	'identifierSchemeCodeUnspsc190501.50533450': __('identifierSchemeCodeUnspsc190501.50533450'),
	'identifierSchemeCodeUnspsc190501.50533451': __('identifierSchemeCodeUnspsc190501.50533451'),
	'identifierSchemeCodeUnspsc190501.50533452': __('identifierSchemeCodeUnspsc190501.50533452'),
	'identifierSchemeCodeUnspsc190501.50533453': __('identifierSchemeCodeUnspsc190501.50533453'),
	'identifierSchemeCodeUnspsc190501.50533454': __('identifierSchemeCodeUnspsc190501.50533454'),
	'identifierSchemeCodeUnspsc190501.50533455': __('identifierSchemeCodeUnspsc190501.50533455'),
	'identifierSchemeCodeUnspsc190501.50533456': __('identifierSchemeCodeUnspsc190501.50533456'),
	'identifierSchemeCodeUnspsc190501.50533457': __('identifierSchemeCodeUnspsc190501.50533457'),
	'identifierSchemeCodeUnspsc190501.50533458': __('identifierSchemeCodeUnspsc190501.50533458'),
	'identifierSchemeCodeUnspsc190501.50533459': __('identifierSchemeCodeUnspsc190501.50533459'),
	'identifierSchemeCodeUnspsc190501.50533460': __('identifierSchemeCodeUnspsc190501.50533460'),
	'identifierSchemeCodeUnspsc190501.50533461': __('identifierSchemeCodeUnspsc190501.50533461'),
	'identifierSchemeCodeUnspsc190501.50533462': __('identifierSchemeCodeUnspsc190501.50533462'),
	'identifierSchemeCodeUnspsc190501.50533463': __('identifierSchemeCodeUnspsc190501.50533463'),
	'identifierSchemeCodeUnspsc190501.50533464': __('identifierSchemeCodeUnspsc190501.50533464'),
	'identifierSchemeCodeUnspsc190501.50533465': __('identifierSchemeCodeUnspsc190501.50533465'),
	'identifierSchemeCodeUnspsc190501.50533466': __('identifierSchemeCodeUnspsc190501.50533466'),
	'identifierSchemeCodeUnspsc190501.50533467': __('identifierSchemeCodeUnspsc190501.50533467'),
	'identifierSchemeCodeUnspsc190501.50533468': __('identifierSchemeCodeUnspsc190501.50533468'),
	'identifierSchemeCodeUnspsc190501.50533469': __('identifierSchemeCodeUnspsc190501.50533469'),
	'identifierSchemeCodeUnspsc190501.50533470': __('identifierSchemeCodeUnspsc190501.50533470'),
	'identifierSchemeCodeUnspsc190501.50533471': __('identifierSchemeCodeUnspsc190501.50533471'),
	'identifierSchemeCodeUnspsc190501.50533472': __('identifierSchemeCodeUnspsc190501.50533472'),
	'identifierSchemeCodeUnspsc190501.50533473': __('identifierSchemeCodeUnspsc190501.50533473'),
	'identifierSchemeCodeUnspsc190501.50533474': __('identifierSchemeCodeUnspsc190501.50533474'),
	'identifierSchemeCodeUnspsc190501.50533475': __('identifierSchemeCodeUnspsc190501.50533475'),
	'identifierSchemeCodeUnspsc190501.50533476': __('identifierSchemeCodeUnspsc190501.50533476'),
	'identifierSchemeCodeUnspsc190501.50533477': __('identifierSchemeCodeUnspsc190501.50533477'),
	'identifierSchemeCodeUnspsc190501.50533478': __('identifierSchemeCodeUnspsc190501.50533478'),
	'identifierSchemeCodeUnspsc190501.50533479': __('identifierSchemeCodeUnspsc190501.50533479'),
	'identifierSchemeCodeUnspsc190501.50533480': __('identifierSchemeCodeUnspsc190501.50533480'),
	'identifierSchemeCodeUnspsc190501.50533481': __('identifierSchemeCodeUnspsc190501.50533481'),
	'identifierSchemeCodeUnspsc190501.50533482': __('identifierSchemeCodeUnspsc190501.50533482'),
	'identifierSchemeCodeUnspsc190501.50533483': __('identifierSchemeCodeUnspsc190501.50533483'),
	'identifierSchemeCodeUnspsc190501.50533484': __('identifierSchemeCodeUnspsc190501.50533484'),
	'identifierSchemeCodeUnspsc190501.50533485': __('identifierSchemeCodeUnspsc190501.50533485'),
	'identifierSchemeCodeUnspsc190501.50533486': __('identifierSchemeCodeUnspsc190501.50533486'),
	'identifierSchemeCodeUnspsc190501.50533487': __('identifierSchemeCodeUnspsc190501.50533487'),
	'identifierSchemeCodeUnspsc190501.50533488': __('identifierSchemeCodeUnspsc190501.50533488'),
	'identifierSchemeCodeUnspsc190501.50533489': __('identifierSchemeCodeUnspsc190501.50533489'),
	'identifierSchemeCodeUnspsc190501.50533490': __('identifierSchemeCodeUnspsc190501.50533490'),
	'identifierSchemeCodeUnspsc190501.50533491': __('identifierSchemeCodeUnspsc190501.50533491'),
	'identifierSchemeCodeUnspsc190501.50533492': __('identifierSchemeCodeUnspsc190501.50533492'),
	'identifierSchemeCodeUnspsc190501.50533493': __('identifierSchemeCodeUnspsc190501.50533493'),
	'identifierSchemeCodeUnspsc190501.50533494': __('identifierSchemeCodeUnspsc190501.50533494'),
	'identifierSchemeCodeUnspsc190501.50533495': __('identifierSchemeCodeUnspsc190501.50533495'),
	'identifierSchemeCodeUnspsc190501.50533496': __('identifierSchemeCodeUnspsc190501.50533496'),
	'identifierSchemeCodeUnspsc190501.50533500': __('identifierSchemeCodeUnspsc190501.50533500'),
	'identifierSchemeCodeUnspsc190501.50533501': __('identifierSchemeCodeUnspsc190501.50533501'),
	'identifierSchemeCodeUnspsc190501.50533502': __('identifierSchemeCodeUnspsc190501.50533502'),
	'identifierSchemeCodeUnspsc190501.50533503': __('identifierSchemeCodeUnspsc190501.50533503'),
	'identifierSchemeCodeUnspsc190501.50533504': __('identifierSchemeCodeUnspsc190501.50533504'),
	'identifierSchemeCodeUnspsc190501.50533505': __('identifierSchemeCodeUnspsc190501.50533505'),
	'identifierSchemeCodeUnspsc190501.50533506': __('identifierSchemeCodeUnspsc190501.50533506'),
	'identifierSchemeCodeUnspsc190501.50533600': __('identifierSchemeCodeUnspsc190501.50533600'),
	'identifierSchemeCodeUnspsc190501.50533601': __('identifierSchemeCodeUnspsc190501.50533601'),
	'identifierSchemeCodeUnspsc190501.50533602': __('identifierSchemeCodeUnspsc190501.50533602'),
	'identifierSchemeCodeUnspsc190501.50533603': __('identifierSchemeCodeUnspsc190501.50533603'),
	'identifierSchemeCodeUnspsc190501.50533604': __('identifierSchemeCodeUnspsc190501.50533604'),
	'identifierSchemeCodeUnspsc190501.50533605': __('identifierSchemeCodeUnspsc190501.50533605'),
	'identifierSchemeCodeUnspsc190501.50533606': __('identifierSchemeCodeUnspsc190501.50533606'),
	'identifierSchemeCodeUnspsc190501.50533607': __('identifierSchemeCodeUnspsc190501.50533607'),
	'identifierSchemeCodeUnspsc190501.50533608': __('identifierSchemeCodeUnspsc190501.50533608'),
	'identifierSchemeCodeUnspsc190501.50533609': __('identifierSchemeCodeUnspsc190501.50533609'),
	'identifierSchemeCodeUnspsc190501.50533610': __('identifierSchemeCodeUnspsc190501.50533610'),
	'identifierSchemeCodeUnspsc190501.50533611': __('identifierSchemeCodeUnspsc190501.50533611'),
	'identifierSchemeCodeUnspsc190501.50533612': __('identifierSchemeCodeUnspsc190501.50533612'),
	'identifierSchemeCodeUnspsc190501.50533613': __('identifierSchemeCodeUnspsc190501.50533613'),
	'identifierSchemeCodeUnspsc190501.50533614': __('identifierSchemeCodeUnspsc190501.50533614'),
	'identifierSchemeCodeUnspsc190501.50533615': __('identifierSchemeCodeUnspsc190501.50533615'),
	'identifierSchemeCodeUnspsc190501.50533616': __('identifierSchemeCodeUnspsc190501.50533616'),
	'identifierSchemeCodeUnspsc190501.50533617': __('identifierSchemeCodeUnspsc190501.50533617'),
	'identifierSchemeCodeUnspsc190501.50533618': __('identifierSchemeCodeUnspsc190501.50533618'),
	'identifierSchemeCodeUnspsc190501.50533619': __('identifierSchemeCodeUnspsc190501.50533619'),
	'identifierSchemeCodeUnspsc190501.50533620': __('identifierSchemeCodeUnspsc190501.50533620'),
	'identifierSchemeCodeUnspsc190501.50533621': __('identifierSchemeCodeUnspsc190501.50533621'),
	'identifierSchemeCodeUnspsc190501.50533622': __('identifierSchemeCodeUnspsc190501.50533622'),
	'identifierSchemeCodeUnspsc190501.50533623': __('identifierSchemeCodeUnspsc190501.50533623'),
	'identifierSchemeCodeUnspsc190501.50533624': __('identifierSchemeCodeUnspsc190501.50533624'),
	'identifierSchemeCodeUnspsc190501.50533625': __('identifierSchemeCodeUnspsc190501.50533625'),
	'identifierSchemeCodeUnspsc190501.50533626': __('identifierSchemeCodeUnspsc190501.50533626'),
	'identifierSchemeCodeUnspsc190501.50533627': __('identifierSchemeCodeUnspsc190501.50533627'),
	'identifierSchemeCodeUnspsc190501.50533628': __('identifierSchemeCodeUnspsc190501.50533628'),
	'identifierSchemeCodeUnspsc190501.50533629': __('identifierSchemeCodeUnspsc190501.50533629'),
	'identifierSchemeCodeUnspsc190501.50533630': __('identifierSchemeCodeUnspsc190501.50533630'),
	'identifierSchemeCodeUnspsc190501.50533631': __('identifierSchemeCodeUnspsc190501.50533631'),
	'identifierSchemeCodeUnspsc190501.50533632': __('identifierSchemeCodeUnspsc190501.50533632'),
	'identifierSchemeCodeUnspsc190501.50533633': __('identifierSchemeCodeUnspsc190501.50533633'),
	'identifierSchemeCodeUnspsc190501.50533634': __('identifierSchemeCodeUnspsc190501.50533634'),
	'identifierSchemeCodeUnspsc190501.50533635': __('identifierSchemeCodeUnspsc190501.50533635'),
	'identifierSchemeCodeUnspsc190501.50533636': __('identifierSchemeCodeUnspsc190501.50533636'),
	'identifierSchemeCodeUnspsc190501.50533637': __('identifierSchemeCodeUnspsc190501.50533637'),
	'identifierSchemeCodeUnspsc190501.50533638': __('identifierSchemeCodeUnspsc190501.50533638'),
	'identifierSchemeCodeUnspsc190501.50533639': __('identifierSchemeCodeUnspsc190501.50533639'),
	'identifierSchemeCodeUnspsc190501.50533640': __('identifierSchemeCodeUnspsc190501.50533640'),
	'identifierSchemeCodeUnspsc190501.50533641': __('identifierSchemeCodeUnspsc190501.50533641'),
	'identifierSchemeCodeUnspsc190501.50533642': __('identifierSchemeCodeUnspsc190501.50533642'),
	'identifierSchemeCodeUnspsc190501.50533643': __('identifierSchemeCodeUnspsc190501.50533643'),
	'identifierSchemeCodeUnspsc190501.50533644': __('identifierSchemeCodeUnspsc190501.50533644'),
	'identifierSchemeCodeUnspsc190501.50533645': __('identifierSchemeCodeUnspsc190501.50533645'),
	'identifierSchemeCodeUnspsc190501.50533646': __('identifierSchemeCodeUnspsc190501.50533646'),
	'identifierSchemeCodeUnspsc190501.50533647': __('identifierSchemeCodeUnspsc190501.50533647'),
	'identifierSchemeCodeUnspsc190501.50533648': __('identifierSchemeCodeUnspsc190501.50533648'),
	'identifierSchemeCodeUnspsc190501.50533649': __('identifierSchemeCodeUnspsc190501.50533649'),
	'identifierSchemeCodeUnspsc190501.50533650': __('identifierSchemeCodeUnspsc190501.50533650'),
	'identifierSchemeCodeUnspsc190501.50533651': __('identifierSchemeCodeUnspsc190501.50533651'),
	'identifierSchemeCodeUnspsc190501.50533652': __('identifierSchemeCodeUnspsc190501.50533652'),
	'identifierSchemeCodeUnspsc190501.50533653': __('identifierSchemeCodeUnspsc190501.50533653'),
	'identifierSchemeCodeUnspsc190501.50533654': __('identifierSchemeCodeUnspsc190501.50533654'),
	'identifierSchemeCodeUnspsc190501.50533655': __('identifierSchemeCodeUnspsc190501.50533655'),
	'identifierSchemeCodeUnspsc190501.50533656': __('identifierSchemeCodeUnspsc190501.50533656'),
	'identifierSchemeCodeUnspsc190501.50533657': __('identifierSchemeCodeUnspsc190501.50533657'),
	'identifierSchemeCodeUnspsc190501.50533658': __('identifierSchemeCodeUnspsc190501.50533658'),
	'identifierSchemeCodeUnspsc190501.50533659': __('identifierSchemeCodeUnspsc190501.50533659'),
	'identifierSchemeCodeUnspsc190501.50533660': __('identifierSchemeCodeUnspsc190501.50533660'),
	'identifierSchemeCodeUnspsc190501.50533661': __('identifierSchemeCodeUnspsc190501.50533661'),
	'identifierSchemeCodeUnspsc190501.50533662': __('identifierSchemeCodeUnspsc190501.50533662'),
	'identifierSchemeCodeUnspsc190501.50533663': __('identifierSchemeCodeUnspsc190501.50533663'),
	'identifierSchemeCodeUnspsc190501.50533664': __('identifierSchemeCodeUnspsc190501.50533664'),
	'identifierSchemeCodeUnspsc190501.50533665': __('identifierSchemeCodeUnspsc190501.50533665'),
	'identifierSchemeCodeUnspsc190501.50533700': __('identifierSchemeCodeUnspsc190501.50533700'),
	'identifierSchemeCodeUnspsc190501.50533701': __('identifierSchemeCodeUnspsc190501.50533701'),
	'identifierSchemeCodeUnspsc190501.50533702': __('identifierSchemeCodeUnspsc190501.50533702'),
	'identifierSchemeCodeUnspsc190501.50533703': __('identifierSchemeCodeUnspsc190501.50533703'),
	'identifierSchemeCodeUnspsc190501.50533704': __('identifierSchemeCodeUnspsc190501.50533704'),
	'identifierSchemeCodeUnspsc190501.50533800': __('identifierSchemeCodeUnspsc190501.50533800'),
	'identifierSchemeCodeUnspsc190501.50533801': __('identifierSchemeCodeUnspsc190501.50533801'),
	'identifierSchemeCodeUnspsc190501.50533802': __('identifierSchemeCodeUnspsc190501.50533802'),
	'identifierSchemeCodeUnspsc190501.50533803': __('identifierSchemeCodeUnspsc190501.50533803'),
	'identifierSchemeCodeUnspsc190501.50533804': __('identifierSchemeCodeUnspsc190501.50533804'),
	'identifierSchemeCodeUnspsc190501.50533805': __('identifierSchemeCodeUnspsc190501.50533805'),
	'identifierSchemeCodeUnspsc190501.50533900': __('identifierSchemeCodeUnspsc190501.50533900'),
	'identifierSchemeCodeUnspsc190501.50533901': __('identifierSchemeCodeUnspsc190501.50533901'),
	'identifierSchemeCodeUnspsc190501.50533902': __('identifierSchemeCodeUnspsc190501.50533902'),
	'identifierSchemeCodeUnspsc190501.50533903': __('identifierSchemeCodeUnspsc190501.50533903'),
	'identifierSchemeCodeUnspsc190501.50533904': __('identifierSchemeCodeUnspsc190501.50533904'),
	'identifierSchemeCodeUnspsc190501.50533905': __('identifierSchemeCodeUnspsc190501.50533905'),
	'identifierSchemeCodeUnspsc190501.50534000': __('identifierSchemeCodeUnspsc190501.50534000'),
	'identifierSchemeCodeUnspsc190501.50534001': __('identifierSchemeCodeUnspsc190501.50534001'),
	'identifierSchemeCodeUnspsc190501.50534002': __('identifierSchemeCodeUnspsc190501.50534002'),
	'identifierSchemeCodeUnspsc190501.50534003': __('identifierSchemeCodeUnspsc190501.50534003'),
	'identifierSchemeCodeUnspsc190501.50534004': __('identifierSchemeCodeUnspsc190501.50534004'),
	'identifierSchemeCodeUnspsc190501.50534005': __('identifierSchemeCodeUnspsc190501.50534005'),
	'identifierSchemeCodeUnspsc190501.50534006': __('identifierSchemeCodeUnspsc190501.50534006'),
	'identifierSchemeCodeUnspsc190501.50534100': __('identifierSchemeCodeUnspsc190501.50534100'),
	'identifierSchemeCodeUnspsc190501.50534101': __('identifierSchemeCodeUnspsc190501.50534101'),
	'identifierSchemeCodeUnspsc190501.50534102': __('identifierSchemeCodeUnspsc190501.50534102'),
	'identifierSchemeCodeUnspsc190501.50534103': __('identifierSchemeCodeUnspsc190501.50534103'),
	'identifierSchemeCodeUnspsc190501.50534104': __('identifierSchemeCodeUnspsc190501.50534104'),
	'identifierSchemeCodeUnspsc190501.50534105': __('identifierSchemeCodeUnspsc190501.50534105'),
	'identifierSchemeCodeUnspsc190501.50534106': __('identifierSchemeCodeUnspsc190501.50534106'),
	'identifierSchemeCodeUnspsc190501.50534107': __('identifierSchemeCodeUnspsc190501.50534107'),
	'identifierSchemeCodeUnspsc190501.50534108': __('identifierSchemeCodeUnspsc190501.50534108'),
	'identifierSchemeCodeUnspsc190501.50534200': __('identifierSchemeCodeUnspsc190501.50534200'),
	'identifierSchemeCodeUnspsc190501.50534201': __('identifierSchemeCodeUnspsc190501.50534201'),
	'identifierSchemeCodeUnspsc190501.50534202': __('identifierSchemeCodeUnspsc190501.50534202'),
	'identifierSchemeCodeUnspsc190501.50534203': __('identifierSchemeCodeUnspsc190501.50534203'),
	'identifierSchemeCodeUnspsc190501.50534204': __('identifierSchemeCodeUnspsc190501.50534204'),
	'identifierSchemeCodeUnspsc190501.50534205': __('identifierSchemeCodeUnspsc190501.50534205'),
	'identifierSchemeCodeUnspsc190501.50534206': __('identifierSchemeCodeUnspsc190501.50534206'),
	'identifierSchemeCodeUnspsc190501.50534207': __('identifierSchemeCodeUnspsc190501.50534207'),
	'identifierSchemeCodeUnspsc190501.50534208': __('identifierSchemeCodeUnspsc190501.50534208'),
	'identifierSchemeCodeUnspsc190501.50534300': __('identifierSchemeCodeUnspsc190501.50534300'),
	'identifierSchemeCodeUnspsc190501.50534301': __('identifierSchemeCodeUnspsc190501.50534301'),
	'identifierSchemeCodeUnspsc190501.50534302': __('identifierSchemeCodeUnspsc190501.50534302'),
	'identifierSchemeCodeUnspsc190501.50534303': __('identifierSchemeCodeUnspsc190501.50534303'),
	'identifierSchemeCodeUnspsc190501.50534304': __('identifierSchemeCodeUnspsc190501.50534304'),
	'identifierSchemeCodeUnspsc190501.50534305': __('identifierSchemeCodeUnspsc190501.50534305'),
	'identifierSchemeCodeUnspsc190501.50534306': __('identifierSchemeCodeUnspsc190501.50534306'),
	'identifierSchemeCodeUnspsc190501.50534307': __('identifierSchemeCodeUnspsc190501.50534307'),
	'identifierSchemeCodeUnspsc190501.50534308': __('identifierSchemeCodeUnspsc190501.50534308'),
	'identifierSchemeCodeUnspsc190501.50534309': __('identifierSchemeCodeUnspsc190501.50534309'),
	'identifierSchemeCodeUnspsc190501.50534310': __('identifierSchemeCodeUnspsc190501.50534310'),
	'identifierSchemeCodeUnspsc190501.50534311': __('identifierSchemeCodeUnspsc190501.50534311'),
	'identifierSchemeCodeUnspsc190501.50534312': __('identifierSchemeCodeUnspsc190501.50534312'),
	'identifierSchemeCodeUnspsc190501.50534313': __('identifierSchemeCodeUnspsc190501.50534313'),
	'identifierSchemeCodeUnspsc190501.50534400': __('identifierSchemeCodeUnspsc190501.50534400'),
	'identifierSchemeCodeUnspsc190501.50534401': __('identifierSchemeCodeUnspsc190501.50534401'),
	'identifierSchemeCodeUnspsc190501.50534402': __('identifierSchemeCodeUnspsc190501.50534402'),
	'identifierSchemeCodeUnspsc190501.50534403': __('identifierSchemeCodeUnspsc190501.50534403'),
	'identifierSchemeCodeUnspsc190501.50534404': __('identifierSchemeCodeUnspsc190501.50534404'),
	'identifierSchemeCodeUnspsc190501.50534405': __('identifierSchemeCodeUnspsc190501.50534405'),
	'identifierSchemeCodeUnspsc190501.50534406': __('identifierSchemeCodeUnspsc190501.50534406'),
	'identifierSchemeCodeUnspsc190501.50534407': __('identifierSchemeCodeUnspsc190501.50534407'),
	'identifierSchemeCodeUnspsc190501.50534408': __('identifierSchemeCodeUnspsc190501.50534408'),
	'identifierSchemeCodeUnspsc190501.50534409': __('identifierSchemeCodeUnspsc190501.50534409'),
	'identifierSchemeCodeUnspsc190501.50534410': __('identifierSchemeCodeUnspsc190501.50534410'),
	'identifierSchemeCodeUnspsc190501.50534411': __('identifierSchemeCodeUnspsc190501.50534411'),
	'identifierSchemeCodeUnspsc190501.50534412': __('identifierSchemeCodeUnspsc190501.50534412'),
	'identifierSchemeCodeUnspsc190501.50534413': __('identifierSchemeCodeUnspsc190501.50534413'),
	'identifierSchemeCodeUnspsc190501.50534414': __('identifierSchemeCodeUnspsc190501.50534414'),
	'identifierSchemeCodeUnspsc190501.50534415': __('identifierSchemeCodeUnspsc190501.50534415'),
	'identifierSchemeCodeUnspsc190501.50534416': __('identifierSchemeCodeUnspsc190501.50534416'),
	'identifierSchemeCodeUnspsc190501.50534417': __('identifierSchemeCodeUnspsc190501.50534417'),
	'identifierSchemeCodeUnspsc190501.50534418': __('identifierSchemeCodeUnspsc190501.50534418'),
	'identifierSchemeCodeUnspsc190501.50534419': __('identifierSchemeCodeUnspsc190501.50534419'),
	'identifierSchemeCodeUnspsc190501.50534420': __('identifierSchemeCodeUnspsc190501.50534420'),
	'identifierSchemeCodeUnspsc190501.50534421': __('identifierSchemeCodeUnspsc190501.50534421'),
	'identifierSchemeCodeUnspsc190501.50534422': __('identifierSchemeCodeUnspsc190501.50534422'),
	'identifierSchemeCodeUnspsc190501.50534423': __('identifierSchemeCodeUnspsc190501.50534423'),
	'identifierSchemeCodeUnspsc190501.50534424': __('identifierSchemeCodeUnspsc190501.50534424'),
	'identifierSchemeCodeUnspsc190501.50534425': __('identifierSchemeCodeUnspsc190501.50534425'),
	'identifierSchemeCodeUnspsc190501.50534426': __('identifierSchemeCodeUnspsc190501.50534426'),
	'identifierSchemeCodeUnspsc190501.50534427': __('identifierSchemeCodeUnspsc190501.50534427'),
	'identifierSchemeCodeUnspsc190501.50534428': __('identifierSchemeCodeUnspsc190501.50534428'),
	'identifierSchemeCodeUnspsc190501.50534429': __('identifierSchemeCodeUnspsc190501.50534429'),
	'identifierSchemeCodeUnspsc190501.50534430': __('identifierSchemeCodeUnspsc190501.50534430'),
	'identifierSchemeCodeUnspsc190501.50534431': __('identifierSchemeCodeUnspsc190501.50534431'),
	'identifierSchemeCodeUnspsc190501.50534432': __('identifierSchemeCodeUnspsc190501.50534432'),
	'identifierSchemeCodeUnspsc190501.50534433': __('identifierSchemeCodeUnspsc190501.50534433'),
	'identifierSchemeCodeUnspsc190501.50534434': __('identifierSchemeCodeUnspsc190501.50534434'),
	'identifierSchemeCodeUnspsc190501.50534435': __('identifierSchemeCodeUnspsc190501.50534435'),
	'identifierSchemeCodeUnspsc190501.50534436': __('identifierSchemeCodeUnspsc190501.50534436'),
	'identifierSchemeCodeUnspsc190501.50534437': __('identifierSchemeCodeUnspsc190501.50534437'),
	'identifierSchemeCodeUnspsc190501.50534438': __('identifierSchemeCodeUnspsc190501.50534438'),
	'identifierSchemeCodeUnspsc190501.50534439': __('identifierSchemeCodeUnspsc190501.50534439'),
	'identifierSchemeCodeUnspsc190501.50534440': __('identifierSchemeCodeUnspsc190501.50534440'),
	'identifierSchemeCodeUnspsc190501.50534441': __('identifierSchemeCodeUnspsc190501.50534441'),
	'identifierSchemeCodeUnspsc190501.50534442': __('identifierSchemeCodeUnspsc190501.50534442'),
	'identifierSchemeCodeUnspsc190501.50534443': __('identifierSchemeCodeUnspsc190501.50534443'),
	'identifierSchemeCodeUnspsc190501.50534444': __('identifierSchemeCodeUnspsc190501.50534444'),
	'identifierSchemeCodeUnspsc190501.50534445': __('identifierSchemeCodeUnspsc190501.50534445'),
	'identifierSchemeCodeUnspsc190501.50534446': __('identifierSchemeCodeUnspsc190501.50534446'),
	'identifierSchemeCodeUnspsc190501.50534500': __('identifierSchemeCodeUnspsc190501.50534500'),
	'identifierSchemeCodeUnspsc190501.50534501': __('identifierSchemeCodeUnspsc190501.50534501'),
	'identifierSchemeCodeUnspsc190501.50534502': __('identifierSchemeCodeUnspsc190501.50534502'),
	'identifierSchemeCodeUnspsc190501.50534503': __('identifierSchemeCodeUnspsc190501.50534503'),
	'identifierSchemeCodeUnspsc190501.50534504': __('identifierSchemeCodeUnspsc190501.50534504'),
	'identifierSchemeCodeUnspsc190501.50534505': __('identifierSchemeCodeUnspsc190501.50534505'),
	'identifierSchemeCodeUnspsc190501.50534506': __('identifierSchemeCodeUnspsc190501.50534506'),
	'identifierSchemeCodeUnspsc190501.50534507': __('identifierSchemeCodeUnspsc190501.50534507'),
	'identifierSchemeCodeUnspsc190501.50534508': __('identifierSchemeCodeUnspsc190501.50534508'),
	'identifierSchemeCodeUnspsc190501.50534509': __('identifierSchemeCodeUnspsc190501.50534509'),
	'identifierSchemeCodeUnspsc190501.50534510': __('identifierSchemeCodeUnspsc190501.50534510'),
	'identifierSchemeCodeUnspsc190501.50534511': __('identifierSchemeCodeUnspsc190501.50534511'),
	'identifierSchemeCodeUnspsc190501.50534512': __('identifierSchemeCodeUnspsc190501.50534512'),
	'identifierSchemeCodeUnspsc190501.50534513': __('identifierSchemeCodeUnspsc190501.50534513'),
	'identifierSchemeCodeUnspsc190501.50534514': __('identifierSchemeCodeUnspsc190501.50534514'),
	'identifierSchemeCodeUnspsc190501.50534515': __('identifierSchemeCodeUnspsc190501.50534515'),
	'identifierSchemeCodeUnspsc190501.50534516': __('identifierSchemeCodeUnspsc190501.50534516'),
	'identifierSchemeCodeUnspsc190501.50534517': __('identifierSchemeCodeUnspsc190501.50534517'),
	'identifierSchemeCodeUnspsc190501.50534518': __('identifierSchemeCodeUnspsc190501.50534518'),
	'identifierSchemeCodeUnspsc190501.50534519': __('identifierSchemeCodeUnspsc190501.50534519'),
	'identifierSchemeCodeUnspsc190501.50534520': __('identifierSchemeCodeUnspsc190501.50534520'),
	'identifierSchemeCodeUnspsc190501.50534521': __('identifierSchemeCodeUnspsc190501.50534521'),
	'identifierSchemeCodeUnspsc190501.50534522': __('identifierSchemeCodeUnspsc190501.50534522'),
	'identifierSchemeCodeUnspsc190501.50534523': __('identifierSchemeCodeUnspsc190501.50534523'),
	'identifierSchemeCodeUnspsc190501.50534524': __('identifierSchemeCodeUnspsc190501.50534524'),
	'identifierSchemeCodeUnspsc190501.50534525': __('identifierSchemeCodeUnspsc190501.50534525'),
	'identifierSchemeCodeUnspsc190501.50534600': __('identifierSchemeCodeUnspsc190501.50534600'),
	'identifierSchemeCodeUnspsc190501.50534601': __('identifierSchemeCodeUnspsc190501.50534601'),
	'identifierSchemeCodeUnspsc190501.50534602': __('identifierSchemeCodeUnspsc190501.50534602'),
	'identifierSchemeCodeUnspsc190501.50534603': __('identifierSchemeCodeUnspsc190501.50534603'),
	'identifierSchemeCodeUnspsc190501.50534604': __('identifierSchemeCodeUnspsc190501.50534604'),
	'identifierSchemeCodeUnspsc190501.50534605': __('identifierSchemeCodeUnspsc190501.50534605'),
	'identifierSchemeCodeUnspsc190501.50534606': __('identifierSchemeCodeUnspsc190501.50534606'),
	'identifierSchemeCodeUnspsc190501.50534607': __('identifierSchemeCodeUnspsc190501.50534607'),
	'identifierSchemeCodeUnspsc190501.50534608': __('identifierSchemeCodeUnspsc190501.50534608'),
	'identifierSchemeCodeUnspsc190501.50534609': __('identifierSchemeCodeUnspsc190501.50534609'),
	'identifierSchemeCodeUnspsc190501.50534610': __('identifierSchemeCodeUnspsc190501.50534610'),
	'identifierSchemeCodeUnspsc190501.50534611': __('identifierSchemeCodeUnspsc190501.50534611'),
	'identifierSchemeCodeUnspsc190501.50534612': __('identifierSchemeCodeUnspsc190501.50534612'),
	'identifierSchemeCodeUnspsc190501.50534613': __('identifierSchemeCodeUnspsc190501.50534613'),
	'identifierSchemeCodeUnspsc190501.50534614': __('identifierSchemeCodeUnspsc190501.50534614'),
	'identifierSchemeCodeUnspsc190501.50534615': __('identifierSchemeCodeUnspsc190501.50534615'),
	'identifierSchemeCodeUnspsc190501.50534616': __('identifierSchemeCodeUnspsc190501.50534616'),
	'identifierSchemeCodeUnspsc190501.50534617': __('identifierSchemeCodeUnspsc190501.50534617'),
	'identifierSchemeCodeUnspsc190501.50534618': __('identifierSchemeCodeUnspsc190501.50534618'),
	'identifierSchemeCodeUnspsc190501.50534619': __('identifierSchemeCodeUnspsc190501.50534619'),
	'identifierSchemeCodeUnspsc190501.50534620': __('identifierSchemeCodeUnspsc190501.50534620'),
	'identifierSchemeCodeUnspsc190501.50534621': __('identifierSchemeCodeUnspsc190501.50534621'),
	'identifierSchemeCodeUnspsc190501.50534622': __('identifierSchemeCodeUnspsc190501.50534622'),
	'identifierSchemeCodeUnspsc190501.50534623': __('identifierSchemeCodeUnspsc190501.50534623'),
	'identifierSchemeCodeUnspsc190501.50534624': __('identifierSchemeCodeUnspsc190501.50534624'),
	'identifierSchemeCodeUnspsc190501.50534625': __('identifierSchemeCodeUnspsc190501.50534625'),
	'identifierSchemeCodeUnspsc190501.50534626': __('identifierSchemeCodeUnspsc190501.50534626'),
	'identifierSchemeCodeUnspsc190501.50534627': __('identifierSchemeCodeUnspsc190501.50534627'),
	'identifierSchemeCodeUnspsc190501.50534628': __('identifierSchemeCodeUnspsc190501.50534628'),
	'identifierSchemeCodeUnspsc190501.50534629': __('identifierSchemeCodeUnspsc190501.50534629'),
	'identifierSchemeCodeUnspsc190501.50534630': __('identifierSchemeCodeUnspsc190501.50534630'),
	'identifierSchemeCodeUnspsc190501.50534631': __('identifierSchemeCodeUnspsc190501.50534631'),
	'identifierSchemeCodeUnspsc190501.50534632': __('identifierSchemeCodeUnspsc190501.50534632'),
	'identifierSchemeCodeUnspsc190501.50534633': __('identifierSchemeCodeUnspsc190501.50534633'),
	'identifierSchemeCodeUnspsc190501.50534634': __('identifierSchemeCodeUnspsc190501.50534634'),
	'identifierSchemeCodeUnspsc190501.50534635': __('identifierSchemeCodeUnspsc190501.50534635'),
	'identifierSchemeCodeUnspsc190501.50534636': __('identifierSchemeCodeUnspsc190501.50534636'),
	'identifierSchemeCodeUnspsc190501.50534637': __('identifierSchemeCodeUnspsc190501.50534637'),
	'identifierSchemeCodeUnspsc190501.50534638': __('identifierSchemeCodeUnspsc190501.50534638'),
	'identifierSchemeCodeUnspsc190501.50534639': __('identifierSchemeCodeUnspsc190501.50534639'),
	'identifierSchemeCodeUnspsc190501.50534640': __('identifierSchemeCodeUnspsc190501.50534640'),
	'identifierSchemeCodeUnspsc190501.50534641': __('identifierSchemeCodeUnspsc190501.50534641'),
	'identifierSchemeCodeUnspsc190501.50534642': __('identifierSchemeCodeUnspsc190501.50534642'),
	'identifierSchemeCodeUnspsc190501.50534643': __('identifierSchemeCodeUnspsc190501.50534643'),
	'identifierSchemeCodeUnspsc190501.50534644': __('identifierSchemeCodeUnspsc190501.50534644'),
	'identifierSchemeCodeUnspsc190501.50534645': __('identifierSchemeCodeUnspsc190501.50534645'),
	'identifierSchemeCodeUnspsc190501.50534646': __('identifierSchemeCodeUnspsc190501.50534646'),
	'identifierSchemeCodeUnspsc190501.50534647': __('identifierSchemeCodeUnspsc190501.50534647'),
	'identifierSchemeCodeUnspsc190501.50534648': __('identifierSchemeCodeUnspsc190501.50534648'),
	'identifierSchemeCodeUnspsc190501.50534649': __('identifierSchemeCodeUnspsc190501.50534649'),
	'identifierSchemeCodeUnspsc190501.50534650': __('identifierSchemeCodeUnspsc190501.50534650'),
	'identifierSchemeCodeUnspsc190501.50534651': __('identifierSchemeCodeUnspsc190501.50534651'),
	'identifierSchemeCodeUnspsc190501.50534652': __('identifierSchemeCodeUnspsc190501.50534652'),
	'identifierSchemeCodeUnspsc190501.50534653': __('identifierSchemeCodeUnspsc190501.50534653'),
	'identifierSchemeCodeUnspsc190501.50534654': __('identifierSchemeCodeUnspsc190501.50534654'),
	'identifierSchemeCodeUnspsc190501.50534655': __('identifierSchemeCodeUnspsc190501.50534655'),
	'identifierSchemeCodeUnspsc190501.50534656': __('identifierSchemeCodeUnspsc190501.50534656'),
	'identifierSchemeCodeUnspsc190501.50534657': __('identifierSchemeCodeUnspsc190501.50534657'),
	'identifierSchemeCodeUnspsc190501.50534658': __('identifierSchemeCodeUnspsc190501.50534658'),
	'identifierSchemeCodeUnspsc190501.50534659': __('identifierSchemeCodeUnspsc190501.50534659'),
	'identifierSchemeCodeUnspsc190501.50534660': __('identifierSchemeCodeUnspsc190501.50534660'),
	'identifierSchemeCodeUnspsc190501.50534661': __('identifierSchemeCodeUnspsc190501.50534661'),
	'identifierSchemeCodeUnspsc190501.50534700': __('identifierSchemeCodeUnspsc190501.50534700'),
	'identifierSchemeCodeUnspsc190501.50534701': __('identifierSchemeCodeUnspsc190501.50534701'),
	'identifierSchemeCodeUnspsc190501.50534702': __('identifierSchemeCodeUnspsc190501.50534702'),
	'identifierSchemeCodeUnspsc190501.50534800': __('identifierSchemeCodeUnspsc190501.50534800'),
	'identifierSchemeCodeUnspsc190501.50534801': __('identifierSchemeCodeUnspsc190501.50534801'),
	'identifierSchemeCodeUnspsc190501.50534802': __('identifierSchemeCodeUnspsc190501.50534802'),
	'identifierSchemeCodeUnspsc190501.50534900': __('identifierSchemeCodeUnspsc190501.50534900'),
	'identifierSchemeCodeUnspsc190501.50534901': __('identifierSchemeCodeUnspsc190501.50534901'),
	'identifierSchemeCodeUnspsc190501.50534902': __('identifierSchemeCodeUnspsc190501.50534902'),
	'identifierSchemeCodeUnspsc190501.50534903': __('identifierSchemeCodeUnspsc190501.50534903'),
	'identifierSchemeCodeUnspsc190501.50534904': __('identifierSchemeCodeUnspsc190501.50534904'),
	'identifierSchemeCodeUnspsc190501.50534905': __('identifierSchemeCodeUnspsc190501.50534905'),
	'identifierSchemeCodeUnspsc190501.50534906': __('identifierSchemeCodeUnspsc190501.50534906'),
	'identifierSchemeCodeUnspsc190501.50534907': __('identifierSchemeCodeUnspsc190501.50534907'),
	'identifierSchemeCodeUnspsc190501.50534908': __('identifierSchemeCodeUnspsc190501.50534908'),
	'identifierSchemeCodeUnspsc190501.50534909': __('identifierSchemeCodeUnspsc190501.50534909'),
	'identifierSchemeCodeUnspsc190501.50534910': __('identifierSchemeCodeUnspsc190501.50534910'),
	'identifierSchemeCodeUnspsc190501.50534911': __('identifierSchemeCodeUnspsc190501.50534911'),
	'identifierSchemeCodeUnspsc190501.50534912': __('identifierSchemeCodeUnspsc190501.50534912'),
	'identifierSchemeCodeUnspsc190501.50534913': __('identifierSchemeCodeUnspsc190501.50534913'),
	'identifierSchemeCodeUnspsc190501.50534914': __('identifierSchemeCodeUnspsc190501.50534914'),
	'identifierSchemeCodeUnspsc190501.50534915': __('identifierSchemeCodeUnspsc190501.50534915'),
	'identifierSchemeCodeUnspsc190501.50534916': __('identifierSchemeCodeUnspsc190501.50534916'),
	'identifierSchemeCodeUnspsc190501.50534917': __('identifierSchemeCodeUnspsc190501.50534917'),
	'identifierSchemeCodeUnspsc190501.50534918': __('identifierSchemeCodeUnspsc190501.50534918'),
	'identifierSchemeCodeUnspsc190501.50534919': __('identifierSchemeCodeUnspsc190501.50534919'),
	'identifierSchemeCodeUnspsc190501.50534920': __('identifierSchemeCodeUnspsc190501.50534920'),
	'identifierSchemeCodeUnspsc190501.50534921': __('identifierSchemeCodeUnspsc190501.50534921'),
	'identifierSchemeCodeUnspsc190501.50534922': __('identifierSchemeCodeUnspsc190501.50534922'),
	'identifierSchemeCodeUnspsc190501.50534923': __('identifierSchemeCodeUnspsc190501.50534923'),
	'identifierSchemeCodeUnspsc190501.50534924': __('identifierSchemeCodeUnspsc190501.50534924'),
	'identifierSchemeCodeUnspsc190501.50534925': __('identifierSchemeCodeUnspsc190501.50534925'),
	'identifierSchemeCodeUnspsc190501.50534926': __('identifierSchemeCodeUnspsc190501.50534926'),
	'identifierSchemeCodeUnspsc190501.50534927': __('identifierSchemeCodeUnspsc190501.50534927'),
	'identifierSchemeCodeUnspsc190501.50534928': __('identifierSchemeCodeUnspsc190501.50534928'),
	'identifierSchemeCodeUnspsc190501.50534929': __('identifierSchemeCodeUnspsc190501.50534929'),
	'identifierSchemeCodeUnspsc190501.50534930': __('identifierSchemeCodeUnspsc190501.50534930'),
	'identifierSchemeCodeUnspsc190501.50534931': __('identifierSchemeCodeUnspsc190501.50534931'),
	'identifierSchemeCodeUnspsc190501.50534932': __('identifierSchemeCodeUnspsc190501.50534932'),
	'identifierSchemeCodeUnspsc190501.50534933': __('identifierSchemeCodeUnspsc190501.50534933'),
	'identifierSchemeCodeUnspsc190501.50534934': __('identifierSchemeCodeUnspsc190501.50534934'),
	'identifierSchemeCodeUnspsc190501.50534935': __('identifierSchemeCodeUnspsc190501.50534935'),
	'identifierSchemeCodeUnspsc190501.50534936': __('identifierSchemeCodeUnspsc190501.50534936'),
	'identifierSchemeCodeUnspsc190501.50534937': __('identifierSchemeCodeUnspsc190501.50534937'),
	'identifierSchemeCodeUnspsc190501.50534938': __('identifierSchemeCodeUnspsc190501.50534938'),
	'identifierSchemeCodeUnspsc190501.50534939': __('identifierSchemeCodeUnspsc190501.50534939'),
	'identifierSchemeCodeUnspsc190501.50534940': __('identifierSchemeCodeUnspsc190501.50534940'),
	'identifierSchemeCodeUnspsc190501.50534941': __('identifierSchemeCodeUnspsc190501.50534941'),
	'identifierSchemeCodeUnspsc190501.50534942': __('identifierSchemeCodeUnspsc190501.50534942'),
	'identifierSchemeCodeUnspsc190501.50534943': __('identifierSchemeCodeUnspsc190501.50534943'),
	'identifierSchemeCodeUnspsc190501.50534944': __('identifierSchemeCodeUnspsc190501.50534944'),
	'identifierSchemeCodeUnspsc190501.50534945': __('identifierSchemeCodeUnspsc190501.50534945'),
	'identifierSchemeCodeUnspsc190501.50534946': __('identifierSchemeCodeUnspsc190501.50534946'),
	'identifierSchemeCodeUnspsc190501.50534947': __('identifierSchemeCodeUnspsc190501.50534947'),
	'identifierSchemeCodeUnspsc190501.50534948': __('identifierSchemeCodeUnspsc190501.50534948'),
	'identifierSchemeCodeUnspsc190501.50534949': __('identifierSchemeCodeUnspsc190501.50534949'),
	'identifierSchemeCodeUnspsc190501.50534950': __('identifierSchemeCodeUnspsc190501.50534950'),
	'identifierSchemeCodeUnspsc190501.50534951': __('identifierSchemeCodeUnspsc190501.50534951'),
	'identifierSchemeCodeUnspsc190501.50534952': __('identifierSchemeCodeUnspsc190501.50534952'),
	'identifierSchemeCodeUnspsc190501.50534953': __('identifierSchemeCodeUnspsc190501.50534953'),
	'identifierSchemeCodeUnspsc190501.50534954': __('identifierSchemeCodeUnspsc190501.50534954'),
	'identifierSchemeCodeUnspsc190501.50534955': __('identifierSchemeCodeUnspsc190501.50534955'),
	'identifierSchemeCodeUnspsc190501.50534956': __('identifierSchemeCodeUnspsc190501.50534956'),
	'identifierSchemeCodeUnspsc190501.50534957': __('identifierSchemeCodeUnspsc190501.50534957'),
	'identifierSchemeCodeUnspsc190501.50535000': __('identifierSchemeCodeUnspsc190501.50535000'),
	'identifierSchemeCodeUnspsc190501.50535001': __('identifierSchemeCodeUnspsc190501.50535001'),
	'identifierSchemeCodeUnspsc190501.50535002': __('identifierSchemeCodeUnspsc190501.50535002'),
	'identifierSchemeCodeUnspsc190501.50535003': __('identifierSchemeCodeUnspsc190501.50535003'),
	'identifierSchemeCodeUnspsc190501.50535004': __('identifierSchemeCodeUnspsc190501.50535004'),
	'identifierSchemeCodeUnspsc190501.50535005': __('identifierSchemeCodeUnspsc190501.50535005'),
	'identifierSchemeCodeUnspsc190501.50535006': __('identifierSchemeCodeUnspsc190501.50535006'),
	'identifierSchemeCodeUnspsc190501.50535007': __('identifierSchemeCodeUnspsc190501.50535007'),
	'identifierSchemeCodeUnspsc190501.50535008': __('identifierSchemeCodeUnspsc190501.50535008'),
	'identifierSchemeCodeUnspsc190501.50535009': __('identifierSchemeCodeUnspsc190501.50535009'),
	'identifierSchemeCodeUnspsc190501.50535010': __('identifierSchemeCodeUnspsc190501.50535010'),
	'identifierSchemeCodeUnspsc190501.50535011': __('identifierSchemeCodeUnspsc190501.50535011'),
	'identifierSchemeCodeUnspsc190501.50535012': __('identifierSchemeCodeUnspsc190501.50535012'),
	'identifierSchemeCodeUnspsc190501.50535013': __('identifierSchemeCodeUnspsc190501.50535013'),
	'identifierSchemeCodeUnspsc190501.50535014': __('identifierSchemeCodeUnspsc190501.50535014'),
	'identifierSchemeCodeUnspsc190501.50535015': __('identifierSchemeCodeUnspsc190501.50535015'),
	'identifierSchemeCodeUnspsc190501.50535016': __('identifierSchemeCodeUnspsc190501.50535016'),
	'identifierSchemeCodeUnspsc190501.50535017': __('identifierSchemeCodeUnspsc190501.50535017'),
	'identifierSchemeCodeUnspsc190501.50535018': __('identifierSchemeCodeUnspsc190501.50535018'),
	'identifierSchemeCodeUnspsc190501.50535019': __('identifierSchemeCodeUnspsc190501.50535019'),
	'identifierSchemeCodeUnspsc190501.50535020': __('identifierSchemeCodeUnspsc190501.50535020'),
	'identifierSchemeCodeUnspsc190501.50535021': __('identifierSchemeCodeUnspsc190501.50535021'),
	'identifierSchemeCodeUnspsc190501.50535022': __('identifierSchemeCodeUnspsc190501.50535022'),
	'identifierSchemeCodeUnspsc190501.50535023': __('identifierSchemeCodeUnspsc190501.50535023'),
	'identifierSchemeCodeUnspsc190501.50535024': __('identifierSchemeCodeUnspsc190501.50535024'),
	'identifierSchemeCodeUnspsc190501.50535025': __('identifierSchemeCodeUnspsc190501.50535025'),
	'identifierSchemeCodeUnspsc190501.50535026': __('identifierSchemeCodeUnspsc190501.50535026'),
	'identifierSchemeCodeUnspsc190501.50535027': __('identifierSchemeCodeUnspsc190501.50535027'),
	'identifierSchemeCodeUnspsc190501.50535028': __('identifierSchemeCodeUnspsc190501.50535028'),
	'identifierSchemeCodeUnspsc190501.50535029': __('identifierSchemeCodeUnspsc190501.50535029'),
	'identifierSchemeCodeUnspsc190501.50535030': __('identifierSchemeCodeUnspsc190501.50535030'),
	'identifierSchemeCodeUnspsc190501.50535031': __('identifierSchemeCodeUnspsc190501.50535031'),
	'identifierSchemeCodeUnspsc190501.50535032': __('identifierSchemeCodeUnspsc190501.50535032'),
	'identifierSchemeCodeUnspsc190501.50535033': __('identifierSchemeCodeUnspsc190501.50535033'),
	'identifierSchemeCodeUnspsc190501.50535034': __('identifierSchemeCodeUnspsc190501.50535034'),
	'identifierSchemeCodeUnspsc190501.50535035': __('identifierSchemeCodeUnspsc190501.50535035'),
	'identifierSchemeCodeUnspsc190501.50535036': __('identifierSchemeCodeUnspsc190501.50535036'),
	'identifierSchemeCodeUnspsc190501.50535037': __('identifierSchemeCodeUnspsc190501.50535037'),
	'identifierSchemeCodeUnspsc190501.50535038': __('identifierSchemeCodeUnspsc190501.50535038'),
	'identifierSchemeCodeUnspsc190501.50535039': __('identifierSchemeCodeUnspsc190501.50535039'),
	'identifierSchemeCodeUnspsc190501.50535040': __('identifierSchemeCodeUnspsc190501.50535040'),
	'identifierSchemeCodeUnspsc190501.50535041': __('identifierSchemeCodeUnspsc190501.50535041'),
	'identifierSchemeCodeUnspsc190501.50535042': __('identifierSchemeCodeUnspsc190501.50535042'),
	'identifierSchemeCodeUnspsc190501.50535043': __('identifierSchemeCodeUnspsc190501.50535043'),
	'identifierSchemeCodeUnspsc190501.50535044': __('identifierSchemeCodeUnspsc190501.50535044'),
	'identifierSchemeCodeUnspsc190501.50535100': __('identifierSchemeCodeUnspsc190501.50535100'),
	'identifierSchemeCodeUnspsc190501.50535101': __('identifierSchemeCodeUnspsc190501.50535101'),
	'identifierSchemeCodeUnspsc190501.50535102': __('identifierSchemeCodeUnspsc190501.50535102'),
	'identifierSchemeCodeUnspsc190501.50535103': __('identifierSchemeCodeUnspsc190501.50535103'),
	'identifierSchemeCodeUnspsc190501.50535104': __('identifierSchemeCodeUnspsc190501.50535104'),
	'identifierSchemeCodeUnspsc190501.50535105': __('identifierSchemeCodeUnspsc190501.50535105'),
	'identifierSchemeCodeUnspsc190501.50535106': __('identifierSchemeCodeUnspsc190501.50535106'),
	'identifierSchemeCodeUnspsc190501.50535200': __('identifierSchemeCodeUnspsc190501.50535200'),
	'identifierSchemeCodeUnspsc190501.50535201': __('identifierSchemeCodeUnspsc190501.50535201'),
	'identifierSchemeCodeUnspsc190501.50535202': __('identifierSchemeCodeUnspsc190501.50535202'),
	'identifierSchemeCodeUnspsc190501.50535203': __('identifierSchemeCodeUnspsc190501.50535203'),
	'identifierSchemeCodeUnspsc190501.50535204': __('identifierSchemeCodeUnspsc190501.50535204'),
	'identifierSchemeCodeUnspsc190501.50535205': __('identifierSchemeCodeUnspsc190501.50535205'),
	'identifierSchemeCodeUnspsc190501.50535206': __('identifierSchemeCodeUnspsc190501.50535206'),
	'identifierSchemeCodeUnspsc190501.50535207': __('identifierSchemeCodeUnspsc190501.50535207'),
	'identifierSchemeCodeUnspsc190501.50535208': __('identifierSchemeCodeUnspsc190501.50535208'),
	'identifierSchemeCodeUnspsc190501.50535209': __('identifierSchemeCodeUnspsc190501.50535209'),
	'identifierSchemeCodeUnspsc190501.50535210': __('identifierSchemeCodeUnspsc190501.50535210'),
	'identifierSchemeCodeUnspsc190501.50535300': __('identifierSchemeCodeUnspsc190501.50535300'),
	'identifierSchemeCodeUnspsc190501.50535301': __('identifierSchemeCodeUnspsc190501.50535301'),
	'identifierSchemeCodeUnspsc190501.50535302': __('identifierSchemeCodeUnspsc190501.50535302'),
	'identifierSchemeCodeUnspsc190501.50535303': __('identifierSchemeCodeUnspsc190501.50535303'),
	'identifierSchemeCodeUnspsc190501.50535304': __('identifierSchemeCodeUnspsc190501.50535304'),
	'identifierSchemeCodeUnspsc190501.50535305': __('identifierSchemeCodeUnspsc190501.50535305'),
	'identifierSchemeCodeUnspsc190501.50535306': __('identifierSchemeCodeUnspsc190501.50535306'),
	'identifierSchemeCodeUnspsc190501.50535307': __('identifierSchemeCodeUnspsc190501.50535307'),
	'identifierSchemeCodeUnspsc190501.50535308': __('identifierSchemeCodeUnspsc190501.50535308'),
	'identifierSchemeCodeUnspsc190501.50535309': __('identifierSchemeCodeUnspsc190501.50535309'),
	'identifierSchemeCodeUnspsc190501.50535310': __('identifierSchemeCodeUnspsc190501.50535310'),
	'identifierSchemeCodeUnspsc190501.50535311': __('identifierSchemeCodeUnspsc190501.50535311'),
	'identifierSchemeCodeUnspsc190501.50535312': __('identifierSchemeCodeUnspsc190501.50535312'),
	'identifierSchemeCodeUnspsc190501.50535313': __('identifierSchemeCodeUnspsc190501.50535313'),
	'identifierSchemeCodeUnspsc190501.50535314': __('identifierSchemeCodeUnspsc190501.50535314'),
	'identifierSchemeCodeUnspsc190501.50535315': __('identifierSchemeCodeUnspsc190501.50535315'),
	'identifierSchemeCodeUnspsc190501.50535316': __('identifierSchemeCodeUnspsc190501.50535316'),
	'identifierSchemeCodeUnspsc190501.50535317': __('identifierSchemeCodeUnspsc190501.50535317'),
	'identifierSchemeCodeUnspsc190501.50535318': __('identifierSchemeCodeUnspsc190501.50535318'),
	'identifierSchemeCodeUnspsc190501.50535319': __('identifierSchemeCodeUnspsc190501.50535319'),
	'identifierSchemeCodeUnspsc190501.50535320': __('identifierSchemeCodeUnspsc190501.50535320'),
	'identifierSchemeCodeUnspsc190501.50535321': __('identifierSchemeCodeUnspsc190501.50535321'),
	'identifierSchemeCodeUnspsc190501.50535322': __('identifierSchemeCodeUnspsc190501.50535322'),
	'identifierSchemeCodeUnspsc190501.50535323': __('identifierSchemeCodeUnspsc190501.50535323'),
	'identifierSchemeCodeUnspsc190501.50535324': __('identifierSchemeCodeUnspsc190501.50535324'),
	'identifierSchemeCodeUnspsc190501.50535325': __('identifierSchemeCodeUnspsc190501.50535325'),
	'identifierSchemeCodeUnspsc190501.50535326': __('identifierSchemeCodeUnspsc190501.50535326'),
	'identifierSchemeCodeUnspsc190501.50535327': __('identifierSchemeCodeUnspsc190501.50535327'),
	'identifierSchemeCodeUnspsc190501.50535328': __('identifierSchemeCodeUnspsc190501.50535328'),
	'identifierSchemeCodeUnspsc190501.50535329': __('identifierSchemeCodeUnspsc190501.50535329'),
	'identifierSchemeCodeUnspsc190501.50535330': __('identifierSchemeCodeUnspsc190501.50535330'),
	'identifierSchemeCodeUnspsc190501.50535331': __('identifierSchemeCodeUnspsc190501.50535331'),
	'identifierSchemeCodeUnspsc190501.50535332': __('identifierSchemeCodeUnspsc190501.50535332'),
	'identifierSchemeCodeUnspsc190501.50535333': __('identifierSchemeCodeUnspsc190501.50535333'),
	'identifierSchemeCodeUnspsc190501.50535334': __('identifierSchemeCodeUnspsc190501.50535334'),
	'identifierSchemeCodeUnspsc190501.50535335': __('identifierSchemeCodeUnspsc190501.50535335'),
	'identifierSchemeCodeUnspsc190501.50535336': __('identifierSchemeCodeUnspsc190501.50535336'),
	'identifierSchemeCodeUnspsc190501.50535337': __('identifierSchemeCodeUnspsc190501.50535337'),
	'identifierSchemeCodeUnspsc190501.50535338': __('identifierSchemeCodeUnspsc190501.50535338'),
	'identifierSchemeCodeUnspsc190501.50535339': __('identifierSchemeCodeUnspsc190501.50535339'),
	'identifierSchemeCodeUnspsc190501.50535340': __('identifierSchemeCodeUnspsc190501.50535340'),
	'identifierSchemeCodeUnspsc190501.50535341': __('identifierSchemeCodeUnspsc190501.50535341'),
	'identifierSchemeCodeUnspsc190501.50535342': __('identifierSchemeCodeUnspsc190501.50535342'),
	'identifierSchemeCodeUnspsc190501.50535343': __('identifierSchemeCodeUnspsc190501.50535343'),
	'identifierSchemeCodeUnspsc190501.50535344': __('identifierSchemeCodeUnspsc190501.50535344'),
	'identifierSchemeCodeUnspsc190501.50535345': __('identifierSchemeCodeUnspsc190501.50535345'),
	'identifierSchemeCodeUnspsc190501.50535346': __('identifierSchemeCodeUnspsc190501.50535346'),
	'identifierSchemeCodeUnspsc190501.50535347': __('identifierSchemeCodeUnspsc190501.50535347'),
	'identifierSchemeCodeUnspsc190501.50535348': __('identifierSchemeCodeUnspsc190501.50535348'),
	'identifierSchemeCodeUnspsc190501.50535349': __('identifierSchemeCodeUnspsc190501.50535349'),
	'identifierSchemeCodeUnspsc190501.50535350': __('identifierSchemeCodeUnspsc190501.50535350'),
	'identifierSchemeCodeUnspsc190501.50535351': __('identifierSchemeCodeUnspsc190501.50535351'),
	'identifierSchemeCodeUnspsc190501.50535352': __('identifierSchemeCodeUnspsc190501.50535352'),
	'identifierSchemeCodeUnspsc190501.50535353': __('identifierSchemeCodeUnspsc190501.50535353'),
	'identifierSchemeCodeUnspsc190501.50535354': __('identifierSchemeCodeUnspsc190501.50535354'),
	'identifierSchemeCodeUnspsc190501.50535355': __('identifierSchemeCodeUnspsc190501.50535355'),
	'identifierSchemeCodeUnspsc190501.50535356': __('identifierSchemeCodeUnspsc190501.50535356'),
	'identifierSchemeCodeUnspsc190501.50535357': __('identifierSchemeCodeUnspsc190501.50535357'),
	'identifierSchemeCodeUnspsc190501.50535358': __('identifierSchemeCodeUnspsc190501.50535358'),
	'identifierSchemeCodeUnspsc190501.50535359': __('identifierSchemeCodeUnspsc190501.50535359'),
	'identifierSchemeCodeUnspsc190501.50535360': __('identifierSchemeCodeUnspsc190501.50535360'),
	'identifierSchemeCodeUnspsc190501.50535361': __('identifierSchemeCodeUnspsc190501.50535361'),
	'identifierSchemeCodeUnspsc190501.50535362': __('identifierSchemeCodeUnspsc190501.50535362'),
	'identifierSchemeCodeUnspsc190501.50535363': __('identifierSchemeCodeUnspsc190501.50535363'),
	'identifierSchemeCodeUnspsc190501.50535364': __('identifierSchemeCodeUnspsc190501.50535364'),
	'identifierSchemeCodeUnspsc190501.50535365': __('identifierSchemeCodeUnspsc190501.50535365'),
	'identifierSchemeCodeUnspsc190501.50535366': __('identifierSchemeCodeUnspsc190501.50535366'),
	'identifierSchemeCodeUnspsc190501.50535367': __('identifierSchemeCodeUnspsc190501.50535367'),
	'identifierSchemeCodeUnspsc190501.50535368': __('identifierSchemeCodeUnspsc190501.50535368'),
	'identifierSchemeCodeUnspsc190501.50535369': __('identifierSchemeCodeUnspsc190501.50535369'),
	'identifierSchemeCodeUnspsc190501.50535370': __('identifierSchemeCodeUnspsc190501.50535370'),
	'identifierSchemeCodeUnspsc190501.50535371': __('identifierSchemeCodeUnspsc190501.50535371'),
	'identifierSchemeCodeUnspsc190501.50535400': __('identifierSchemeCodeUnspsc190501.50535400'),
	'identifierSchemeCodeUnspsc190501.50535401': __('identifierSchemeCodeUnspsc190501.50535401'),
	'identifierSchemeCodeUnspsc190501.50535402': __('identifierSchemeCodeUnspsc190501.50535402'),
	'identifierSchemeCodeUnspsc190501.50535403': __('identifierSchemeCodeUnspsc190501.50535403'),
	'identifierSchemeCodeUnspsc190501.50535404': __('identifierSchemeCodeUnspsc190501.50535404'),
	'identifierSchemeCodeUnspsc190501.50535405': __('identifierSchemeCodeUnspsc190501.50535405'),
	'identifierSchemeCodeUnspsc190501.50535406': __('identifierSchemeCodeUnspsc190501.50535406'),
	'identifierSchemeCodeUnspsc190501.50535407': __('identifierSchemeCodeUnspsc190501.50535407'),
	'identifierSchemeCodeUnspsc190501.50535408': __('identifierSchemeCodeUnspsc190501.50535408'),
	'identifierSchemeCodeUnspsc190501.50535409': __('identifierSchemeCodeUnspsc190501.50535409'),
	'identifierSchemeCodeUnspsc190501.50535410': __('identifierSchemeCodeUnspsc190501.50535410'),
	'identifierSchemeCodeUnspsc190501.50535411': __('identifierSchemeCodeUnspsc190501.50535411'),
	'identifierSchemeCodeUnspsc190501.50535412': __('identifierSchemeCodeUnspsc190501.50535412'),
	'identifierSchemeCodeUnspsc190501.50535413': __('identifierSchemeCodeUnspsc190501.50535413'),
	'identifierSchemeCodeUnspsc190501.50535414': __('identifierSchemeCodeUnspsc190501.50535414'),
	'identifierSchemeCodeUnspsc190501.50535415': __('identifierSchemeCodeUnspsc190501.50535415'),
	'identifierSchemeCodeUnspsc190501.50535416': __('identifierSchemeCodeUnspsc190501.50535416'),
	'identifierSchemeCodeUnspsc190501.50535417': __('identifierSchemeCodeUnspsc190501.50535417'),
	'identifierSchemeCodeUnspsc190501.50535418': __('identifierSchemeCodeUnspsc190501.50535418'),
	'identifierSchemeCodeUnspsc190501.50535419': __('identifierSchemeCodeUnspsc190501.50535419'),
	'identifierSchemeCodeUnspsc190501.50535420': __('identifierSchemeCodeUnspsc190501.50535420'),
	'identifierSchemeCodeUnspsc190501.50535421': __('identifierSchemeCodeUnspsc190501.50535421'),
	'identifierSchemeCodeUnspsc190501.50535422': __('identifierSchemeCodeUnspsc190501.50535422'),
	'identifierSchemeCodeUnspsc190501.50535423': __('identifierSchemeCodeUnspsc190501.50535423'),
	'identifierSchemeCodeUnspsc190501.50535424': __('identifierSchemeCodeUnspsc190501.50535424'),
	'identifierSchemeCodeUnspsc190501.50535425': __('identifierSchemeCodeUnspsc190501.50535425'),
	'identifierSchemeCodeUnspsc190501.50535426': __('identifierSchemeCodeUnspsc190501.50535426'),
	'identifierSchemeCodeUnspsc190501.50535427': __('identifierSchemeCodeUnspsc190501.50535427'),
	'identifierSchemeCodeUnspsc190501.50535428': __('identifierSchemeCodeUnspsc190501.50535428'),
	'identifierSchemeCodeUnspsc190501.50535429': __('identifierSchemeCodeUnspsc190501.50535429'),
	'identifierSchemeCodeUnspsc190501.50535430': __('identifierSchemeCodeUnspsc190501.50535430'),
	'identifierSchemeCodeUnspsc190501.50535431': __('identifierSchemeCodeUnspsc190501.50535431'),
	'identifierSchemeCodeUnspsc190501.50535432': __('identifierSchemeCodeUnspsc190501.50535432'),
	'identifierSchemeCodeUnspsc190501.50535433': __('identifierSchemeCodeUnspsc190501.50535433'),
	'identifierSchemeCodeUnspsc190501.50535434': __('identifierSchemeCodeUnspsc190501.50535434'),
	'identifierSchemeCodeUnspsc190501.50535435': __('identifierSchemeCodeUnspsc190501.50535435'),
	'identifierSchemeCodeUnspsc190501.50535436': __('identifierSchemeCodeUnspsc190501.50535436'),
	'identifierSchemeCodeUnspsc190501.50535437': __('identifierSchemeCodeUnspsc190501.50535437'),
	'identifierSchemeCodeUnspsc190501.50535438': __('identifierSchemeCodeUnspsc190501.50535438'),
	'identifierSchemeCodeUnspsc190501.50535439': __('identifierSchemeCodeUnspsc190501.50535439'),
	'identifierSchemeCodeUnspsc190501.50535440': __('identifierSchemeCodeUnspsc190501.50535440'),
	'identifierSchemeCodeUnspsc190501.50535441': __('identifierSchemeCodeUnspsc190501.50535441'),
	'identifierSchemeCodeUnspsc190501.50535442': __('identifierSchemeCodeUnspsc190501.50535442'),
	'identifierSchemeCodeUnspsc190501.50535443': __('identifierSchemeCodeUnspsc190501.50535443'),
	'identifierSchemeCodeUnspsc190501.50535444': __('identifierSchemeCodeUnspsc190501.50535444'),
	'identifierSchemeCodeUnspsc190501.50535445': __('identifierSchemeCodeUnspsc190501.50535445'),
	'identifierSchemeCodeUnspsc190501.50535446': __('identifierSchemeCodeUnspsc190501.50535446'),
	'identifierSchemeCodeUnspsc190501.50535447': __('identifierSchemeCodeUnspsc190501.50535447'),
	'identifierSchemeCodeUnspsc190501.50535448': __('identifierSchemeCodeUnspsc190501.50535448'),
	'identifierSchemeCodeUnspsc190501.50535449': __('identifierSchemeCodeUnspsc190501.50535449'),
	'identifierSchemeCodeUnspsc190501.50535450': __('identifierSchemeCodeUnspsc190501.50535450'),
	'identifierSchemeCodeUnspsc190501.50535451': __('identifierSchemeCodeUnspsc190501.50535451'),
	'identifierSchemeCodeUnspsc190501.50535452': __('identifierSchemeCodeUnspsc190501.50535452'),
	'identifierSchemeCodeUnspsc190501.50535453': __('identifierSchemeCodeUnspsc190501.50535453'),
	'identifierSchemeCodeUnspsc190501.50535454': __('identifierSchemeCodeUnspsc190501.50535454'),
	'identifierSchemeCodeUnspsc190501.50535455': __('identifierSchemeCodeUnspsc190501.50535455'),
	'identifierSchemeCodeUnspsc190501.50535456': __('identifierSchemeCodeUnspsc190501.50535456'),
	'identifierSchemeCodeUnspsc190501.50535457': __('identifierSchemeCodeUnspsc190501.50535457'),
	'identifierSchemeCodeUnspsc190501.50535458': __('identifierSchemeCodeUnspsc190501.50535458'),
	'identifierSchemeCodeUnspsc190501.50535459': __('identifierSchemeCodeUnspsc190501.50535459'),
	'identifierSchemeCodeUnspsc190501.50535460': __('identifierSchemeCodeUnspsc190501.50535460'),
	'identifierSchemeCodeUnspsc190501.50535500': __('identifierSchemeCodeUnspsc190501.50535500'),
	'identifierSchemeCodeUnspsc190501.50535501': __('identifierSchemeCodeUnspsc190501.50535501'),
	'identifierSchemeCodeUnspsc190501.50535502': __('identifierSchemeCodeUnspsc190501.50535502'),
	'identifierSchemeCodeUnspsc190501.50535503': __('identifierSchemeCodeUnspsc190501.50535503'),
	'identifierSchemeCodeUnspsc190501.50535504': __('identifierSchemeCodeUnspsc190501.50535504'),
	'identifierSchemeCodeUnspsc190501.50535505': __('identifierSchemeCodeUnspsc190501.50535505'),
	'identifierSchemeCodeUnspsc190501.50535506': __('identifierSchemeCodeUnspsc190501.50535506'),
	'identifierSchemeCodeUnspsc190501.50535507': __('identifierSchemeCodeUnspsc190501.50535507'),
	'identifierSchemeCodeUnspsc190501.50535508': __('identifierSchemeCodeUnspsc190501.50535508'),
	'identifierSchemeCodeUnspsc190501.50535509': __('identifierSchemeCodeUnspsc190501.50535509'),
	'identifierSchemeCodeUnspsc190501.50535510': __('identifierSchemeCodeUnspsc190501.50535510'),
	'identifierSchemeCodeUnspsc190501.50535511': __('identifierSchemeCodeUnspsc190501.50535511'),
	'identifierSchemeCodeUnspsc190501.50535512': __('identifierSchemeCodeUnspsc190501.50535512'),
	'identifierSchemeCodeUnspsc190501.50535600': __('identifierSchemeCodeUnspsc190501.50535600'),
	'identifierSchemeCodeUnspsc190501.50535601': __('identifierSchemeCodeUnspsc190501.50535601'),
	'identifierSchemeCodeUnspsc190501.50535602': __('identifierSchemeCodeUnspsc190501.50535602'),
	'identifierSchemeCodeUnspsc190501.50535603': __('identifierSchemeCodeUnspsc190501.50535603'),
	'identifierSchemeCodeUnspsc190501.50535604': __('identifierSchemeCodeUnspsc190501.50535604'),
	'identifierSchemeCodeUnspsc190501.50535605': __('identifierSchemeCodeUnspsc190501.50535605'),
	'identifierSchemeCodeUnspsc190501.50535606': __('identifierSchemeCodeUnspsc190501.50535606'),
	'identifierSchemeCodeUnspsc190501.50535607': __('identifierSchemeCodeUnspsc190501.50535607'),
	'identifierSchemeCodeUnspsc190501.50535608': __('identifierSchemeCodeUnspsc190501.50535608'),
	'identifierSchemeCodeUnspsc190501.50535609': __('identifierSchemeCodeUnspsc190501.50535609'),
	'identifierSchemeCodeUnspsc190501.50535610': __('identifierSchemeCodeUnspsc190501.50535610'),
	'identifierSchemeCodeUnspsc190501.50535700': __('identifierSchemeCodeUnspsc190501.50535700'),
	'identifierSchemeCodeUnspsc190501.50535701': __('identifierSchemeCodeUnspsc190501.50535701'),
	'identifierSchemeCodeUnspsc190501.50535702': __('identifierSchemeCodeUnspsc190501.50535702'),
	'identifierSchemeCodeUnspsc190501.50535703': __('identifierSchemeCodeUnspsc190501.50535703'),
	'identifierSchemeCodeUnspsc190501.50535704': __('identifierSchemeCodeUnspsc190501.50535704'),
	'identifierSchemeCodeUnspsc190501.50535705': __('identifierSchemeCodeUnspsc190501.50535705'),
	'identifierSchemeCodeUnspsc190501.50535706': __('identifierSchemeCodeUnspsc190501.50535706'),
	'identifierSchemeCodeUnspsc190501.50535707': __('identifierSchemeCodeUnspsc190501.50535707'),
	'identifierSchemeCodeUnspsc190501.50535708': __('identifierSchemeCodeUnspsc190501.50535708'),
	'identifierSchemeCodeUnspsc190501.50535709': __('identifierSchemeCodeUnspsc190501.50535709'),
	'identifierSchemeCodeUnspsc190501.50535710': __('identifierSchemeCodeUnspsc190501.50535710'),
	'identifierSchemeCodeUnspsc190501.50535711': __('identifierSchemeCodeUnspsc190501.50535711'),
	'identifierSchemeCodeUnspsc190501.50535712': __('identifierSchemeCodeUnspsc190501.50535712'),
	'identifierSchemeCodeUnspsc190501.50535713': __('identifierSchemeCodeUnspsc190501.50535713'),
	'identifierSchemeCodeUnspsc190501.50535714': __('identifierSchemeCodeUnspsc190501.50535714'),
	'identifierSchemeCodeUnspsc190501.50535715': __('identifierSchemeCodeUnspsc190501.50535715'),
	'identifierSchemeCodeUnspsc190501.50535716': __('identifierSchemeCodeUnspsc190501.50535716'),
	'identifierSchemeCodeUnspsc190501.50535717': __('identifierSchemeCodeUnspsc190501.50535717'),
	'identifierSchemeCodeUnspsc190501.50535718': __('identifierSchemeCodeUnspsc190501.50535718'),
	'identifierSchemeCodeUnspsc190501.50535719': __('identifierSchemeCodeUnspsc190501.50535719'),
	'identifierSchemeCodeUnspsc190501.50535720': __('identifierSchemeCodeUnspsc190501.50535720'),
	'identifierSchemeCodeUnspsc190501.50535721': __('identifierSchemeCodeUnspsc190501.50535721'),
	'identifierSchemeCodeUnspsc190501.50535722': __('identifierSchemeCodeUnspsc190501.50535722'),
	'identifierSchemeCodeUnspsc190501.50535723': __('identifierSchemeCodeUnspsc190501.50535723'),
	'identifierSchemeCodeUnspsc190501.50535800': __('identifierSchemeCodeUnspsc190501.50535800'),
	'identifierSchemeCodeUnspsc190501.50535801': __('identifierSchemeCodeUnspsc190501.50535801'),
	'identifierSchemeCodeUnspsc190501.50535802': __('identifierSchemeCodeUnspsc190501.50535802'),
	'identifierSchemeCodeUnspsc190501.50535803': __('identifierSchemeCodeUnspsc190501.50535803'),
	'identifierSchemeCodeUnspsc190501.50535804': __('identifierSchemeCodeUnspsc190501.50535804'),
	'identifierSchemeCodeUnspsc190501.50535805': __('identifierSchemeCodeUnspsc190501.50535805'),
	'identifierSchemeCodeUnspsc190501.50535806': __('identifierSchemeCodeUnspsc190501.50535806'),
	'identifierSchemeCodeUnspsc190501.50535807': __('identifierSchemeCodeUnspsc190501.50535807'),
	'identifierSchemeCodeUnspsc190501.50535808': __('identifierSchemeCodeUnspsc190501.50535808'),
	'identifierSchemeCodeUnspsc190501.50535809': __('identifierSchemeCodeUnspsc190501.50535809'),
	'identifierSchemeCodeUnspsc190501.50535810': __('identifierSchemeCodeUnspsc190501.50535810'),
	'identifierSchemeCodeUnspsc190501.50535811': __('identifierSchemeCodeUnspsc190501.50535811'),
	'identifierSchemeCodeUnspsc190501.50535812': __('identifierSchemeCodeUnspsc190501.50535812'),
	'identifierSchemeCodeUnspsc190501.50535813': __('identifierSchemeCodeUnspsc190501.50535813'),
	'identifierSchemeCodeUnspsc190501.50535814': __('identifierSchemeCodeUnspsc190501.50535814'),
	'identifierSchemeCodeUnspsc190501.50535815': __('identifierSchemeCodeUnspsc190501.50535815'),
	'identifierSchemeCodeUnspsc190501.50535816': __('identifierSchemeCodeUnspsc190501.50535816'),
	'identifierSchemeCodeUnspsc190501.50535817': __('identifierSchemeCodeUnspsc190501.50535817'),
	'identifierSchemeCodeUnspsc190501.50535818': __('identifierSchemeCodeUnspsc190501.50535818'),
	'identifierSchemeCodeUnspsc190501.50535819': __('identifierSchemeCodeUnspsc190501.50535819'),
	'identifierSchemeCodeUnspsc190501.50535820': __('identifierSchemeCodeUnspsc190501.50535820'),
	'identifierSchemeCodeUnspsc190501.50535821': __('identifierSchemeCodeUnspsc190501.50535821'),
	'identifierSchemeCodeUnspsc190501.50535822': __('identifierSchemeCodeUnspsc190501.50535822'),
	'identifierSchemeCodeUnspsc190501.50535823': __('identifierSchemeCodeUnspsc190501.50535823'),
	'identifierSchemeCodeUnspsc190501.50535824': __('identifierSchemeCodeUnspsc190501.50535824'),
	'identifierSchemeCodeUnspsc190501.50535825': __('identifierSchemeCodeUnspsc190501.50535825'),
	'identifierSchemeCodeUnspsc190501.50535826': __('identifierSchemeCodeUnspsc190501.50535826'),
	'identifierSchemeCodeUnspsc190501.50535827': __('identifierSchemeCodeUnspsc190501.50535827'),
	'identifierSchemeCodeUnspsc190501.50535828': __('identifierSchemeCodeUnspsc190501.50535828'),
	'identifierSchemeCodeUnspsc190501.50535829': __('identifierSchemeCodeUnspsc190501.50535829'),
	'identifierSchemeCodeUnspsc190501.50535830': __('identifierSchemeCodeUnspsc190501.50535830'),
	'identifierSchemeCodeUnspsc190501.50535831': __('identifierSchemeCodeUnspsc190501.50535831'),
	'identifierSchemeCodeUnspsc190501.50535832': __('identifierSchemeCodeUnspsc190501.50535832'),
	'identifierSchemeCodeUnspsc190501.50535833': __('identifierSchemeCodeUnspsc190501.50535833'),
	'identifierSchemeCodeUnspsc190501.50535834': __('identifierSchemeCodeUnspsc190501.50535834'),
	'identifierSchemeCodeUnspsc190501.50535835': __('identifierSchemeCodeUnspsc190501.50535835'),
	'identifierSchemeCodeUnspsc190501.50535836': __('identifierSchemeCodeUnspsc190501.50535836'),
	'identifierSchemeCodeUnspsc190501.50535837': __('identifierSchemeCodeUnspsc190501.50535837'),
	'identifierSchemeCodeUnspsc190501.50535838': __('identifierSchemeCodeUnspsc190501.50535838'),
	'identifierSchemeCodeUnspsc190501.50535839': __('identifierSchemeCodeUnspsc190501.50535839'),
	'identifierSchemeCodeUnspsc190501.50535840': __('identifierSchemeCodeUnspsc190501.50535840'),
	'identifierSchemeCodeUnspsc190501.50535841': __('identifierSchemeCodeUnspsc190501.50535841'),
	'identifierSchemeCodeUnspsc190501.50535842': __('identifierSchemeCodeUnspsc190501.50535842'),
	'identifierSchemeCodeUnspsc190501.50535843': __('identifierSchemeCodeUnspsc190501.50535843'),
	'identifierSchemeCodeUnspsc190501.50535844': __('identifierSchemeCodeUnspsc190501.50535844'),
	'identifierSchemeCodeUnspsc190501.50535845': __('identifierSchemeCodeUnspsc190501.50535845'),
	'identifierSchemeCodeUnspsc190501.50535846': __('identifierSchemeCodeUnspsc190501.50535846'),
	'identifierSchemeCodeUnspsc190501.50535847': __('identifierSchemeCodeUnspsc190501.50535847'),
	'identifierSchemeCodeUnspsc190501.50535848': __('identifierSchemeCodeUnspsc190501.50535848'),
	'identifierSchemeCodeUnspsc190501.50535849': __('identifierSchemeCodeUnspsc190501.50535849'),
	'identifierSchemeCodeUnspsc190501.50535850': __('identifierSchemeCodeUnspsc190501.50535850'),
	'identifierSchemeCodeUnspsc190501.50535851': __('identifierSchemeCodeUnspsc190501.50535851'),
	'identifierSchemeCodeUnspsc190501.50535852': __('identifierSchemeCodeUnspsc190501.50535852'),
	'identifierSchemeCodeUnspsc190501.50535853': __('identifierSchemeCodeUnspsc190501.50535853'),
	'identifierSchemeCodeUnspsc190501.50535854': __('identifierSchemeCodeUnspsc190501.50535854'),
	'identifierSchemeCodeUnspsc190501.50535855': __('identifierSchemeCodeUnspsc190501.50535855'),
	'identifierSchemeCodeUnspsc190501.50535856': __('identifierSchemeCodeUnspsc190501.50535856'),
	'identifierSchemeCodeUnspsc190501.50535857': __('identifierSchemeCodeUnspsc190501.50535857'),
	'identifierSchemeCodeUnspsc190501.50535858': __('identifierSchemeCodeUnspsc190501.50535858'),
	'identifierSchemeCodeUnspsc190501.50535859': __('identifierSchemeCodeUnspsc190501.50535859'),
	'identifierSchemeCodeUnspsc190501.50535860': __('identifierSchemeCodeUnspsc190501.50535860'),
	'identifierSchemeCodeUnspsc190501.50535861': __('identifierSchemeCodeUnspsc190501.50535861'),
	'identifierSchemeCodeUnspsc190501.50535862': __('identifierSchemeCodeUnspsc190501.50535862'),
	'identifierSchemeCodeUnspsc190501.50535863': __('identifierSchemeCodeUnspsc190501.50535863'),
	'identifierSchemeCodeUnspsc190501.50535864': __('identifierSchemeCodeUnspsc190501.50535864'),
	'identifierSchemeCodeUnspsc190501.50535865': __('identifierSchemeCodeUnspsc190501.50535865'),
	'identifierSchemeCodeUnspsc190501.50535866': __('identifierSchemeCodeUnspsc190501.50535866'),
	'identifierSchemeCodeUnspsc190501.50535867': __('identifierSchemeCodeUnspsc190501.50535867'),
	'identifierSchemeCodeUnspsc190501.50535868': __('identifierSchemeCodeUnspsc190501.50535868'),
	'identifierSchemeCodeUnspsc190501.50535869': __('identifierSchemeCodeUnspsc190501.50535869'),
	'identifierSchemeCodeUnspsc190501.50535870': __('identifierSchemeCodeUnspsc190501.50535870'),
	'identifierSchemeCodeUnspsc190501.50535871': __('identifierSchemeCodeUnspsc190501.50535871'),
	'identifierSchemeCodeUnspsc190501.50535872': __('identifierSchemeCodeUnspsc190501.50535872'),
	'identifierSchemeCodeUnspsc190501.50535873': __('identifierSchemeCodeUnspsc190501.50535873'),
	'identifierSchemeCodeUnspsc190501.50535874': __('identifierSchemeCodeUnspsc190501.50535874'),
	'identifierSchemeCodeUnspsc190501.50535875': __('identifierSchemeCodeUnspsc190501.50535875'),
	'identifierSchemeCodeUnspsc190501.50535876': __('identifierSchemeCodeUnspsc190501.50535876'),
	'identifierSchemeCodeUnspsc190501.50535877': __('identifierSchemeCodeUnspsc190501.50535877'),
	'identifierSchemeCodeUnspsc190501.50535900': __('identifierSchemeCodeUnspsc190501.50535900'),
	'identifierSchemeCodeUnspsc190501.50535901': __('identifierSchemeCodeUnspsc190501.50535901'),
	'identifierSchemeCodeUnspsc190501.50535902': __('identifierSchemeCodeUnspsc190501.50535902'),
	'identifierSchemeCodeUnspsc190501.50535903': __('identifierSchemeCodeUnspsc190501.50535903'),
	'identifierSchemeCodeUnspsc190501.50535904': __('identifierSchemeCodeUnspsc190501.50535904'),
	'identifierSchemeCodeUnspsc190501.50535905': __('identifierSchemeCodeUnspsc190501.50535905'),
	'identifierSchemeCodeUnspsc190501.50535906': __('identifierSchemeCodeUnspsc190501.50535906'),
	'identifierSchemeCodeUnspsc190501.50535907': __('identifierSchemeCodeUnspsc190501.50535907'),
	'identifierSchemeCodeUnspsc190501.50535908': __('identifierSchemeCodeUnspsc190501.50535908'),
	'identifierSchemeCodeUnspsc190501.50536000': __('identifierSchemeCodeUnspsc190501.50536000'),
	'identifierSchemeCodeUnspsc190501.50536001': __('identifierSchemeCodeUnspsc190501.50536001'),
	'identifierSchemeCodeUnspsc190501.50536002': __('identifierSchemeCodeUnspsc190501.50536002'),
	'identifierSchemeCodeUnspsc190501.50536003': __('identifierSchemeCodeUnspsc190501.50536003'),
	'identifierSchemeCodeUnspsc190501.50536004': __('identifierSchemeCodeUnspsc190501.50536004'),
	'identifierSchemeCodeUnspsc190501.50536005': __('identifierSchemeCodeUnspsc190501.50536005'),
	'identifierSchemeCodeUnspsc190501.50536006': __('identifierSchemeCodeUnspsc190501.50536006'),
	'identifierSchemeCodeUnspsc190501.50536007': __('identifierSchemeCodeUnspsc190501.50536007'),
	'identifierSchemeCodeUnspsc190501.50536008': __('identifierSchemeCodeUnspsc190501.50536008'),
	'identifierSchemeCodeUnspsc190501.50536100': __('identifierSchemeCodeUnspsc190501.50536100'),
	'identifierSchemeCodeUnspsc190501.50536101': __('identifierSchemeCodeUnspsc190501.50536101'),
	'identifierSchemeCodeUnspsc190501.50536102': __('identifierSchemeCodeUnspsc190501.50536102'),
	'identifierSchemeCodeUnspsc190501.50536103': __('identifierSchemeCodeUnspsc190501.50536103'),
	'identifierSchemeCodeUnspsc190501.50536200': __('identifierSchemeCodeUnspsc190501.50536200'),
	'identifierSchemeCodeUnspsc190501.50536201': __('identifierSchemeCodeUnspsc190501.50536201'),
	'identifierSchemeCodeUnspsc190501.50536202': __('identifierSchemeCodeUnspsc190501.50536202'),
	'identifierSchemeCodeUnspsc190501.50536203': __('identifierSchemeCodeUnspsc190501.50536203'),
	'identifierSchemeCodeUnspsc190501.50536204': __('identifierSchemeCodeUnspsc190501.50536204'),
	'identifierSchemeCodeUnspsc190501.50536205': __('identifierSchemeCodeUnspsc190501.50536205'),
	'identifierSchemeCodeUnspsc190501.50536206': __('identifierSchemeCodeUnspsc190501.50536206'),
	'identifierSchemeCodeUnspsc190501.50536207': __('identifierSchemeCodeUnspsc190501.50536207'),
	'identifierSchemeCodeUnspsc190501.50536208': __('identifierSchemeCodeUnspsc190501.50536208'),
	'identifierSchemeCodeUnspsc190501.50536209': __('identifierSchemeCodeUnspsc190501.50536209'),
	'identifierSchemeCodeUnspsc190501.50536210': __('identifierSchemeCodeUnspsc190501.50536210'),
	'identifierSchemeCodeUnspsc190501.50536211': __('identifierSchemeCodeUnspsc190501.50536211'),
	'identifierSchemeCodeUnspsc190501.50536212': __('identifierSchemeCodeUnspsc190501.50536212'),
	'identifierSchemeCodeUnspsc190501.50536213': __('identifierSchemeCodeUnspsc190501.50536213'),
	'identifierSchemeCodeUnspsc190501.50536214': __('identifierSchemeCodeUnspsc190501.50536214'),
	'identifierSchemeCodeUnspsc190501.50536215': __('identifierSchemeCodeUnspsc190501.50536215'),
	'identifierSchemeCodeUnspsc190501.50536216': __('identifierSchemeCodeUnspsc190501.50536216'),
	'identifierSchemeCodeUnspsc190501.50536217': __('identifierSchemeCodeUnspsc190501.50536217'),
	'identifierSchemeCodeUnspsc190501.50536218': __('identifierSchemeCodeUnspsc190501.50536218'),
	'identifierSchemeCodeUnspsc190501.50536219': __('identifierSchemeCodeUnspsc190501.50536219'),
	'identifierSchemeCodeUnspsc190501.50536220': __('identifierSchemeCodeUnspsc190501.50536220'),
	'identifierSchemeCodeUnspsc190501.50536221': __('identifierSchemeCodeUnspsc190501.50536221'),
	'identifierSchemeCodeUnspsc190501.50536222': __('identifierSchemeCodeUnspsc190501.50536222'),
	'identifierSchemeCodeUnspsc190501.50536223': __('identifierSchemeCodeUnspsc190501.50536223'),
	'identifierSchemeCodeUnspsc190501.50536224': __('identifierSchemeCodeUnspsc190501.50536224'),
	'identifierSchemeCodeUnspsc190501.50536225': __('identifierSchemeCodeUnspsc190501.50536225'),
	'identifierSchemeCodeUnspsc190501.50536226': __('identifierSchemeCodeUnspsc190501.50536226'),
	'identifierSchemeCodeUnspsc190501.50536227': __('identifierSchemeCodeUnspsc190501.50536227'),
	'identifierSchemeCodeUnspsc190501.50536228': __('identifierSchemeCodeUnspsc190501.50536228'),
	'identifierSchemeCodeUnspsc190501.50536229': __('identifierSchemeCodeUnspsc190501.50536229'),
	'identifierSchemeCodeUnspsc190501.50536300': __('identifierSchemeCodeUnspsc190501.50536300'),
	'identifierSchemeCodeUnspsc190501.50536301': __('identifierSchemeCodeUnspsc190501.50536301'),
	'identifierSchemeCodeUnspsc190501.50536302': __('identifierSchemeCodeUnspsc190501.50536302'),
	'identifierSchemeCodeUnspsc190501.50536303': __('identifierSchemeCodeUnspsc190501.50536303'),
	'identifierSchemeCodeUnspsc190501.50536304': __('identifierSchemeCodeUnspsc190501.50536304'),
	'identifierSchemeCodeUnspsc190501.50536305': __('identifierSchemeCodeUnspsc190501.50536305'),
	'identifierSchemeCodeUnspsc190501.50536306': __('identifierSchemeCodeUnspsc190501.50536306'),
	'identifierSchemeCodeUnspsc190501.50536307': __('identifierSchemeCodeUnspsc190501.50536307'),
	'identifierSchemeCodeUnspsc190501.50536308': __('identifierSchemeCodeUnspsc190501.50536308'),
	'identifierSchemeCodeUnspsc190501.50536309': __('identifierSchemeCodeUnspsc190501.50536309'),
	'identifierSchemeCodeUnspsc190501.50536310': __('identifierSchemeCodeUnspsc190501.50536310'),
	'identifierSchemeCodeUnspsc190501.50536400': __('identifierSchemeCodeUnspsc190501.50536400'),
	'identifierSchemeCodeUnspsc190501.50536401': __('identifierSchemeCodeUnspsc190501.50536401'),
	'identifierSchemeCodeUnspsc190501.50536402': __('identifierSchemeCodeUnspsc190501.50536402'),
	'identifierSchemeCodeUnspsc190501.50536403': __('identifierSchemeCodeUnspsc190501.50536403'),
	'identifierSchemeCodeUnspsc190501.50536404': __('identifierSchemeCodeUnspsc190501.50536404'),
	'identifierSchemeCodeUnspsc190501.50536500': __('identifierSchemeCodeUnspsc190501.50536500'),
	'identifierSchemeCodeUnspsc190501.50536501': __('identifierSchemeCodeUnspsc190501.50536501'),
	'identifierSchemeCodeUnspsc190501.50536502': __('identifierSchemeCodeUnspsc190501.50536502'),
	'identifierSchemeCodeUnspsc190501.50536600': __('identifierSchemeCodeUnspsc190501.50536600'),
	'identifierSchemeCodeUnspsc190501.50536601': __('identifierSchemeCodeUnspsc190501.50536601'),
	'identifierSchemeCodeUnspsc190501.50536602': __('identifierSchemeCodeUnspsc190501.50536602'),
	'identifierSchemeCodeUnspsc190501.50536603': __('identifierSchemeCodeUnspsc190501.50536603'),
	'identifierSchemeCodeUnspsc190501.50536604': __('identifierSchemeCodeUnspsc190501.50536604'),
	'identifierSchemeCodeUnspsc190501.50536700': __('identifierSchemeCodeUnspsc190501.50536700'),
	'identifierSchemeCodeUnspsc190501.50536701': __('identifierSchemeCodeUnspsc190501.50536701'),
	'identifierSchemeCodeUnspsc190501.50536702': __('identifierSchemeCodeUnspsc190501.50536702'),
	'identifierSchemeCodeUnspsc190501.50536703': __('identifierSchemeCodeUnspsc190501.50536703'),
	'identifierSchemeCodeUnspsc190501.50536800': __('identifierSchemeCodeUnspsc190501.50536800'),
	'identifierSchemeCodeUnspsc190501.50536801': __('identifierSchemeCodeUnspsc190501.50536801'),
	'identifierSchemeCodeUnspsc190501.50536802': __('identifierSchemeCodeUnspsc190501.50536802'),
	'identifierSchemeCodeUnspsc190501.50536803': __('identifierSchemeCodeUnspsc190501.50536803'),
	'identifierSchemeCodeUnspsc190501.50536900': __('identifierSchemeCodeUnspsc190501.50536900'),
	'identifierSchemeCodeUnspsc190501.50536901': __('identifierSchemeCodeUnspsc190501.50536901'),
	'identifierSchemeCodeUnspsc190501.50536902': __('identifierSchemeCodeUnspsc190501.50536902'),
	'identifierSchemeCodeUnspsc190501.50536903': __('identifierSchemeCodeUnspsc190501.50536903'),
	'identifierSchemeCodeUnspsc190501.50536904': __('identifierSchemeCodeUnspsc190501.50536904'),
	'identifierSchemeCodeUnspsc190501.50536905': __('identifierSchemeCodeUnspsc190501.50536905'),
	'identifierSchemeCodeUnspsc190501.50536906': __('identifierSchemeCodeUnspsc190501.50536906'),
	'identifierSchemeCodeUnspsc190501.50537000': __('identifierSchemeCodeUnspsc190501.50537000'),
	'identifierSchemeCodeUnspsc190501.50537001': __('identifierSchemeCodeUnspsc190501.50537001'),
	'identifierSchemeCodeUnspsc190501.50537002': __('identifierSchemeCodeUnspsc190501.50537002'),
	'identifierSchemeCodeUnspsc190501.50537003': __('identifierSchemeCodeUnspsc190501.50537003'),
	'identifierSchemeCodeUnspsc190501.50537004': __('identifierSchemeCodeUnspsc190501.50537004'),
	'identifierSchemeCodeUnspsc190501.50537005': __('identifierSchemeCodeUnspsc190501.50537005'),
	'identifierSchemeCodeUnspsc190501.50537006': __('identifierSchemeCodeUnspsc190501.50537006'),
	'identifierSchemeCodeUnspsc190501.50537007': __('identifierSchemeCodeUnspsc190501.50537007'),
	'identifierSchemeCodeUnspsc190501.50537008': __('identifierSchemeCodeUnspsc190501.50537008'),
	'identifierSchemeCodeUnspsc190501.50537009': __('identifierSchemeCodeUnspsc190501.50537009'),
	'identifierSchemeCodeUnspsc190501.50537010': __('identifierSchemeCodeUnspsc190501.50537010'),
	'identifierSchemeCodeUnspsc190501.50537011': __('identifierSchemeCodeUnspsc190501.50537011'),
	'identifierSchemeCodeUnspsc190501.50537012': __('identifierSchemeCodeUnspsc190501.50537012'),
	'identifierSchemeCodeUnspsc190501.50537013': __('identifierSchemeCodeUnspsc190501.50537013'),
	'identifierSchemeCodeUnspsc190501.50537014': __('identifierSchemeCodeUnspsc190501.50537014'),
	'identifierSchemeCodeUnspsc190501.50537015': __('identifierSchemeCodeUnspsc190501.50537015'),
	'identifierSchemeCodeUnspsc190501.50537016': __('identifierSchemeCodeUnspsc190501.50537016'),
	'identifierSchemeCodeUnspsc190501.50537017': __('identifierSchemeCodeUnspsc190501.50537017'),
	'identifierSchemeCodeUnspsc190501.50537018': __('identifierSchemeCodeUnspsc190501.50537018'),
	'identifierSchemeCodeUnspsc190501.50537019': __('identifierSchemeCodeUnspsc190501.50537019'),
	'identifierSchemeCodeUnspsc190501.50537020': __('identifierSchemeCodeUnspsc190501.50537020'),
	'identifierSchemeCodeUnspsc190501.50537021': __('identifierSchemeCodeUnspsc190501.50537021'),
	'identifierSchemeCodeUnspsc190501.50537022': __('identifierSchemeCodeUnspsc190501.50537022'),
	'identifierSchemeCodeUnspsc190501.50537023': __('identifierSchemeCodeUnspsc190501.50537023'),
	'identifierSchemeCodeUnspsc190501.50537024': __('identifierSchemeCodeUnspsc190501.50537024'),
	'identifierSchemeCodeUnspsc190501.50537025': __('identifierSchemeCodeUnspsc190501.50537025'),
	'identifierSchemeCodeUnspsc190501.50537026': __('identifierSchemeCodeUnspsc190501.50537026'),
	'identifierSchemeCodeUnspsc190501.50537027': __('identifierSchemeCodeUnspsc190501.50537027'),
	'identifierSchemeCodeUnspsc190501.50537028': __('identifierSchemeCodeUnspsc190501.50537028'),
	'identifierSchemeCodeUnspsc190501.50537029': __('identifierSchemeCodeUnspsc190501.50537029'),
	'identifierSchemeCodeUnspsc190501.50537030': __('identifierSchemeCodeUnspsc190501.50537030'),
	'identifierSchemeCodeUnspsc190501.50537031': __('identifierSchemeCodeUnspsc190501.50537031'),
	'identifierSchemeCodeUnspsc190501.50537032': __('identifierSchemeCodeUnspsc190501.50537032'),
	'identifierSchemeCodeUnspsc190501.50537033': __('identifierSchemeCodeUnspsc190501.50537033'),
	'identifierSchemeCodeUnspsc190501.50537034': __('identifierSchemeCodeUnspsc190501.50537034'),
	'identifierSchemeCodeUnspsc190501.50537035': __('identifierSchemeCodeUnspsc190501.50537035'),
	'identifierSchemeCodeUnspsc190501.50537036': __('identifierSchemeCodeUnspsc190501.50537036'),
	'identifierSchemeCodeUnspsc190501.50537037': __('identifierSchemeCodeUnspsc190501.50537037'),
	'identifierSchemeCodeUnspsc190501.50537100': __('identifierSchemeCodeUnspsc190501.50537100'),
	'identifierSchemeCodeUnspsc190501.50537101': __('identifierSchemeCodeUnspsc190501.50537101'),
	'identifierSchemeCodeUnspsc190501.50537102': __('identifierSchemeCodeUnspsc190501.50537102'),
	'identifierSchemeCodeUnspsc190501.50537103': __('identifierSchemeCodeUnspsc190501.50537103'),
	'identifierSchemeCodeUnspsc190501.50537104': __('identifierSchemeCodeUnspsc190501.50537104'),
	'identifierSchemeCodeUnspsc190501.50537200': __('identifierSchemeCodeUnspsc190501.50537200'),
	'identifierSchemeCodeUnspsc190501.50537201': __('identifierSchemeCodeUnspsc190501.50537201'),
	'identifierSchemeCodeUnspsc190501.50537202': __('identifierSchemeCodeUnspsc190501.50537202'),
	'identifierSchemeCodeUnspsc190501.50537203': __('identifierSchemeCodeUnspsc190501.50537203'),
	'identifierSchemeCodeUnspsc190501.50537204': __('identifierSchemeCodeUnspsc190501.50537204'),
	'identifierSchemeCodeUnspsc190501.50537205': __('identifierSchemeCodeUnspsc190501.50537205'),
	'identifierSchemeCodeUnspsc190501.50537206': __('identifierSchemeCodeUnspsc190501.50537206'),
	'identifierSchemeCodeUnspsc190501.50537207': __('identifierSchemeCodeUnspsc190501.50537207'),
	'identifierSchemeCodeUnspsc190501.50537208': __('identifierSchemeCodeUnspsc190501.50537208'),
	'identifierSchemeCodeUnspsc190501.50537209': __('identifierSchemeCodeUnspsc190501.50537209'),
	'identifierSchemeCodeUnspsc190501.50537210': __('identifierSchemeCodeUnspsc190501.50537210'),
	'identifierSchemeCodeUnspsc190501.50537211': __('identifierSchemeCodeUnspsc190501.50537211'),
	'identifierSchemeCodeUnspsc190501.50537212': __('identifierSchemeCodeUnspsc190501.50537212'),
	'identifierSchemeCodeUnspsc190501.50537213': __('identifierSchemeCodeUnspsc190501.50537213'),
	'identifierSchemeCodeUnspsc190501.50537214': __('identifierSchemeCodeUnspsc190501.50537214'),
	'identifierSchemeCodeUnspsc190501.50537215': __('identifierSchemeCodeUnspsc190501.50537215'),
	'identifierSchemeCodeUnspsc190501.50537216': __('identifierSchemeCodeUnspsc190501.50537216'),
	'identifierSchemeCodeUnspsc190501.50537217': __('identifierSchemeCodeUnspsc190501.50537217'),
	'identifierSchemeCodeUnspsc190501.50537218': __('identifierSchemeCodeUnspsc190501.50537218'),
	'identifierSchemeCodeUnspsc190501.50537219': __('identifierSchemeCodeUnspsc190501.50537219'),
	'identifierSchemeCodeUnspsc190501.50537220': __('identifierSchemeCodeUnspsc190501.50537220'),
	'identifierSchemeCodeUnspsc190501.50537221': __('identifierSchemeCodeUnspsc190501.50537221'),
	'identifierSchemeCodeUnspsc190501.50537222': __('identifierSchemeCodeUnspsc190501.50537222'),
	'identifierSchemeCodeUnspsc190501.50537223': __('identifierSchemeCodeUnspsc190501.50537223'),
	'identifierSchemeCodeUnspsc190501.50537224': __('identifierSchemeCodeUnspsc190501.50537224'),
	'identifierSchemeCodeUnspsc190501.50537225': __('identifierSchemeCodeUnspsc190501.50537225'),
	'identifierSchemeCodeUnspsc190501.50537226': __('identifierSchemeCodeUnspsc190501.50537226'),
	'identifierSchemeCodeUnspsc190501.50537227': __('identifierSchemeCodeUnspsc190501.50537227'),
	'identifierSchemeCodeUnspsc190501.50537228': __('identifierSchemeCodeUnspsc190501.50537228'),
	'identifierSchemeCodeUnspsc190501.50537229': __('identifierSchemeCodeUnspsc190501.50537229'),
	'identifierSchemeCodeUnspsc190501.50537230': __('identifierSchemeCodeUnspsc190501.50537230'),
	'identifierSchemeCodeUnspsc190501.50540000': __('identifierSchemeCodeUnspsc190501.50540000'),
	'identifierSchemeCodeUnspsc190501.50541500': __('identifierSchemeCodeUnspsc190501.50541500'),
	'identifierSchemeCodeUnspsc190501.50541501': __('identifierSchemeCodeUnspsc190501.50541501'),
	'identifierSchemeCodeUnspsc190501.50541502': __('identifierSchemeCodeUnspsc190501.50541502'),
	'identifierSchemeCodeUnspsc190501.50541503': __('identifierSchemeCodeUnspsc190501.50541503'),
	'identifierSchemeCodeUnspsc190501.50541504': __('identifierSchemeCodeUnspsc190501.50541504'),
	'identifierSchemeCodeUnspsc190501.50541505': __('identifierSchemeCodeUnspsc190501.50541505'),
	'identifierSchemeCodeUnspsc190501.50541506': __('identifierSchemeCodeUnspsc190501.50541506'),
	'identifierSchemeCodeUnspsc190501.50541507': __('identifierSchemeCodeUnspsc190501.50541507'),
	'identifierSchemeCodeUnspsc190501.50541508': __('identifierSchemeCodeUnspsc190501.50541508'),
	'identifierSchemeCodeUnspsc190501.50541509': __('identifierSchemeCodeUnspsc190501.50541509'),
	'identifierSchemeCodeUnspsc190501.50541510': __('identifierSchemeCodeUnspsc190501.50541510'),
	'identifierSchemeCodeUnspsc190501.50541511': __('identifierSchemeCodeUnspsc190501.50541511'),
	'identifierSchemeCodeUnspsc190501.50541512': __('identifierSchemeCodeUnspsc190501.50541512'),
	'identifierSchemeCodeUnspsc190501.50541513': __('identifierSchemeCodeUnspsc190501.50541513'),
	'identifierSchemeCodeUnspsc190501.50541514': __('identifierSchemeCodeUnspsc190501.50541514'),
	'identifierSchemeCodeUnspsc190501.50541515': __('identifierSchemeCodeUnspsc190501.50541515'),
	'identifierSchemeCodeUnspsc190501.50541516': __('identifierSchemeCodeUnspsc190501.50541516'),
	'identifierSchemeCodeUnspsc190501.50541517': __('identifierSchemeCodeUnspsc190501.50541517'),
	'identifierSchemeCodeUnspsc190501.50541518': __('identifierSchemeCodeUnspsc190501.50541518'),
	'identifierSchemeCodeUnspsc190501.50541519': __('identifierSchemeCodeUnspsc190501.50541519'),
	'identifierSchemeCodeUnspsc190501.50541520': __('identifierSchemeCodeUnspsc190501.50541520'),
	'identifierSchemeCodeUnspsc190501.50541521': __('identifierSchemeCodeUnspsc190501.50541521'),
	'identifierSchemeCodeUnspsc190501.50541522': __('identifierSchemeCodeUnspsc190501.50541522'),
	'identifierSchemeCodeUnspsc190501.50541523': __('identifierSchemeCodeUnspsc190501.50541523'),
	'identifierSchemeCodeUnspsc190501.50541524': __('identifierSchemeCodeUnspsc190501.50541524'),
	'identifierSchemeCodeUnspsc190501.50541525': __('identifierSchemeCodeUnspsc190501.50541525'),
	'identifierSchemeCodeUnspsc190501.50541526': __('identifierSchemeCodeUnspsc190501.50541526'),
	'identifierSchemeCodeUnspsc190501.50541527': __('identifierSchemeCodeUnspsc190501.50541527'),
	'identifierSchemeCodeUnspsc190501.50541528': __('identifierSchemeCodeUnspsc190501.50541528'),
	'identifierSchemeCodeUnspsc190501.50541529': __('identifierSchemeCodeUnspsc190501.50541529'),
	'identifierSchemeCodeUnspsc190501.50541530': __('identifierSchemeCodeUnspsc190501.50541530'),
	'identifierSchemeCodeUnspsc190501.50541531': __('identifierSchemeCodeUnspsc190501.50541531'),
	'identifierSchemeCodeUnspsc190501.50541532': __('identifierSchemeCodeUnspsc190501.50541532'),
	'identifierSchemeCodeUnspsc190501.50541533': __('identifierSchemeCodeUnspsc190501.50541533'),
	'identifierSchemeCodeUnspsc190501.50541534': __('identifierSchemeCodeUnspsc190501.50541534'),
	'identifierSchemeCodeUnspsc190501.50541535': __('identifierSchemeCodeUnspsc190501.50541535'),
	'identifierSchemeCodeUnspsc190501.50541536': __('identifierSchemeCodeUnspsc190501.50541536'),
	'identifierSchemeCodeUnspsc190501.50541537': __('identifierSchemeCodeUnspsc190501.50541537'),
	'identifierSchemeCodeUnspsc190501.50541538': __('identifierSchemeCodeUnspsc190501.50541538'),
	'identifierSchemeCodeUnspsc190501.50541539': __('identifierSchemeCodeUnspsc190501.50541539'),
	'identifierSchemeCodeUnspsc190501.50541540': __('identifierSchemeCodeUnspsc190501.50541540'),
	'identifierSchemeCodeUnspsc190501.50541541': __('identifierSchemeCodeUnspsc190501.50541541'),
	'identifierSchemeCodeUnspsc190501.50541542': __('identifierSchemeCodeUnspsc190501.50541542'),
	'identifierSchemeCodeUnspsc190501.50541543': __('identifierSchemeCodeUnspsc190501.50541543'),
	'identifierSchemeCodeUnspsc190501.50541544': __('identifierSchemeCodeUnspsc190501.50541544'),
	'identifierSchemeCodeUnspsc190501.50541545': __('identifierSchemeCodeUnspsc190501.50541545'),
	'identifierSchemeCodeUnspsc190501.50541546': __('identifierSchemeCodeUnspsc190501.50541546'),
	'identifierSchemeCodeUnspsc190501.50541547': __('identifierSchemeCodeUnspsc190501.50541547'),
	'identifierSchemeCodeUnspsc190501.50541548': __('identifierSchemeCodeUnspsc190501.50541548'),
	'identifierSchemeCodeUnspsc190501.50541549': __('identifierSchemeCodeUnspsc190501.50541549'),
	'identifierSchemeCodeUnspsc190501.50541550': __('identifierSchemeCodeUnspsc190501.50541550'),
	'identifierSchemeCodeUnspsc190501.50541551': __('identifierSchemeCodeUnspsc190501.50541551'),
	'identifierSchemeCodeUnspsc190501.50541552': __('identifierSchemeCodeUnspsc190501.50541552'),
	'identifierSchemeCodeUnspsc190501.50541553': __('identifierSchemeCodeUnspsc190501.50541553'),
	'identifierSchemeCodeUnspsc190501.50541554': __('identifierSchemeCodeUnspsc190501.50541554'),
	'identifierSchemeCodeUnspsc190501.50541555': __('identifierSchemeCodeUnspsc190501.50541555'),
	'identifierSchemeCodeUnspsc190501.50541556': __('identifierSchemeCodeUnspsc190501.50541556'),
	'identifierSchemeCodeUnspsc190501.50541557': __('identifierSchemeCodeUnspsc190501.50541557'),
	'identifierSchemeCodeUnspsc190501.50541558': __('identifierSchemeCodeUnspsc190501.50541558'),
	'identifierSchemeCodeUnspsc190501.50541559': __('identifierSchemeCodeUnspsc190501.50541559'),
	'identifierSchemeCodeUnspsc190501.50541560': __('identifierSchemeCodeUnspsc190501.50541560'),
	'identifierSchemeCodeUnspsc190501.50541561': __('identifierSchemeCodeUnspsc190501.50541561'),
	'identifierSchemeCodeUnspsc190501.50541562': __('identifierSchemeCodeUnspsc190501.50541562'),
	'identifierSchemeCodeUnspsc190501.50541563': __('identifierSchemeCodeUnspsc190501.50541563'),
	'identifierSchemeCodeUnspsc190501.50541564': __('identifierSchemeCodeUnspsc190501.50541564'),
	'identifierSchemeCodeUnspsc190501.50541565': __('identifierSchemeCodeUnspsc190501.50541565'),
	'identifierSchemeCodeUnspsc190501.50541566': __('identifierSchemeCodeUnspsc190501.50541566'),
	'identifierSchemeCodeUnspsc190501.50541567': __('identifierSchemeCodeUnspsc190501.50541567'),
	'identifierSchemeCodeUnspsc190501.50541568': __('identifierSchemeCodeUnspsc190501.50541568'),
	'identifierSchemeCodeUnspsc190501.50541569': __('identifierSchemeCodeUnspsc190501.50541569'),
	'identifierSchemeCodeUnspsc190501.50541570': __('identifierSchemeCodeUnspsc190501.50541570'),
	'identifierSchemeCodeUnspsc190501.50541571': __('identifierSchemeCodeUnspsc190501.50541571'),
	'identifierSchemeCodeUnspsc190501.50541572': __('identifierSchemeCodeUnspsc190501.50541572'),
	'identifierSchemeCodeUnspsc190501.50541573': __('identifierSchemeCodeUnspsc190501.50541573'),
	'identifierSchemeCodeUnspsc190501.50541574': __('identifierSchemeCodeUnspsc190501.50541574'),
	'identifierSchemeCodeUnspsc190501.50541575': __('identifierSchemeCodeUnspsc190501.50541575'),
	'identifierSchemeCodeUnspsc190501.50541576': __('identifierSchemeCodeUnspsc190501.50541576'),
	'identifierSchemeCodeUnspsc190501.50541577': __('identifierSchemeCodeUnspsc190501.50541577'),
	'identifierSchemeCodeUnspsc190501.50541578': __('identifierSchemeCodeUnspsc190501.50541578'),
	'identifierSchemeCodeUnspsc190501.50541579': __('identifierSchemeCodeUnspsc190501.50541579'),
	'identifierSchemeCodeUnspsc190501.50541580': __('identifierSchemeCodeUnspsc190501.50541580'),
	'identifierSchemeCodeUnspsc190501.50541581': __('identifierSchemeCodeUnspsc190501.50541581'),
	'identifierSchemeCodeUnspsc190501.50541582': __('identifierSchemeCodeUnspsc190501.50541582'),
	'identifierSchemeCodeUnspsc190501.50541583': __('identifierSchemeCodeUnspsc190501.50541583'),
	'identifierSchemeCodeUnspsc190501.50541584': __('identifierSchemeCodeUnspsc190501.50541584'),
	'identifierSchemeCodeUnspsc190501.50541585': __('identifierSchemeCodeUnspsc190501.50541585'),
	'identifierSchemeCodeUnspsc190501.50541586': __('identifierSchemeCodeUnspsc190501.50541586'),
	'identifierSchemeCodeUnspsc190501.50541587': __('identifierSchemeCodeUnspsc190501.50541587'),
	'identifierSchemeCodeUnspsc190501.50541588': __('identifierSchemeCodeUnspsc190501.50541588'),
	'identifierSchemeCodeUnspsc190501.50541589': __('identifierSchemeCodeUnspsc190501.50541589'),
	'identifierSchemeCodeUnspsc190501.50541590': __('identifierSchemeCodeUnspsc190501.50541590'),
	'identifierSchemeCodeUnspsc190501.50541591': __('identifierSchemeCodeUnspsc190501.50541591'),
	'identifierSchemeCodeUnspsc190501.50541592': __('identifierSchemeCodeUnspsc190501.50541592'),
	'identifierSchemeCodeUnspsc190501.50541593': __('identifierSchemeCodeUnspsc190501.50541593'),
	'identifierSchemeCodeUnspsc190501.50541594': __('identifierSchemeCodeUnspsc190501.50541594'),
	'identifierSchemeCodeUnspsc190501.50541595': __('identifierSchemeCodeUnspsc190501.50541595'),
	'identifierSchemeCodeUnspsc190501.50541596': __('identifierSchemeCodeUnspsc190501.50541596'),
	'identifierSchemeCodeUnspsc190501.50541600': __('identifierSchemeCodeUnspsc190501.50541600'),
	'identifierSchemeCodeUnspsc190501.50541601': __('identifierSchemeCodeUnspsc190501.50541601'),
	'identifierSchemeCodeUnspsc190501.50541602': __('identifierSchemeCodeUnspsc190501.50541602'),
	'identifierSchemeCodeUnspsc190501.50541603': __('identifierSchemeCodeUnspsc190501.50541603'),
	'identifierSchemeCodeUnspsc190501.50541604': __('identifierSchemeCodeUnspsc190501.50541604'),
	'identifierSchemeCodeUnspsc190501.50541605': __('identifierSchemeCodeUnspsc190501.50541605'),
	'identifierSchemeCodeUnspsc190501.50541606': __('identifierSchemeCodeUnspsc190501.50541606'),
	'identifierSchemeCodeUnspsc190501.50541607': __('identifierSchemeCodeUnspsc190501.50541607'),
	'identifierSchemeCodeUnspsc190501.50541608': __('identifierSchemeCodeUnspsc190501.50541608'),
	'identifierSchemeCodeUnspsc190501.50541609': __('identifierSchemeCodeUnspsc190501.50541609'),
	'identifierSchemeCodeUnspsc190501.50541610': __('identifierSchemeCodeUnspsc190501.50541610'),
	'identifierSchemeCodeUnspsc190501.50541611': __('identifierSchemeCodeUnspsc190501.50541611'),
	'identifierSchemeCodeUnspsc190501.50541612': __('identifierSchemeCodeUnspsc190501.50541612'),
	'identifierSchemeCodeUnspsc190501.50541613': __('identifierSchemeCodeUnspsc190501.50541613'),
	'identifierSchemeCodeUnspsc190501.50541614': __('identifierSchemeCodeUnspsc190501.50541614'),
	'identifierSchemeCodeUnspsc190501.50541615': __('identifierSchemeCodeUnspsc190501.50541615'),
	'identifierSchemeCodeUnspsc190501.50541616': __('identifierSchemeCodeUnspsc190501.50541616'),
	'identifierSchemeCodeUnspsc190501.50541617': __('identifierSchemeCodeUnspsc190501.50541617'),
	'identifierSchemeCodeUnspsc190501.50541618': __('identifierSchemeCodeUnspsc190501.50541618'),
	'identifierSchemeCodeUnspsc190501.50541619': __('identifierSchemeCodeUnspsc190501.50541619'),
	'identifierSchemeCodeUnspsc190501.50541620': __('identifierSchemeCodeUnspsc190501.50541620'),
	'identifierSchemeCodeUnspsc190501.50541621': __('identifierSchemeCodeUnspsc190501.50541621'),
	'identifierSchemeCodeUnspsc190501.50541622': __('identifierSchemeCodeUnspsc190501.50541622'),
	'identifierSchemeCodeUnspsc190501.50541623': __('identifierSchemeCodeUnspsc190501.50541623'),
	'identifierSchemeCodeUnspsc190501.50541624': __('identifierSchemeCodeUnspsc190501.50541624'),
	'identifierSchemeCodeUnspsc190501.50541625': __('identifierSchemeCodeUnspsc190501.50541625'),
	'identifierSchemeCodeUnspsc190501.50541626': __('identifierSchemeCodeUnspsc190501.50541626'),
	'identifierSchemeCodeUnspsc190501.50541627': __('identifierSchemeCodeUnspsc190501.50541627'),
	'identifierSchemeCodeUnspsc190501.50541628': __('identifierSchemeCodeUnspsc190501.50541628'),
	'identifierSchemeCodeUnspsc190501.50541629': __('identifierSchemeCodeUnspsc190501.50541629'),
	'identifierSchemeCodeUnspsc190501.50541630': __('identifierSchemeCodeUnspsc190501.50541630'),
	'identifierSchemeCodeUnspsc190501.50541631': __('identifierSchemeCodeUnspsc190501.50541631'),
	'identifierSchemeCodeUnspsc190501.50541632': __('identifierSchemeCodeUnspsc190501.50541632'),
	'identifierSchemeCodeUnspsc190501.50541633': __('identifierSchemeCodeUnspsc190501.50541633'),
	'identifierSchemeCodeUnspsc190501.50541634': __('identifierSchemeCodeUnspsc190501.50541634'),
	'identifierSchemeCodeUnspsc190501.50541635': __('identifierSchemeCodeUnspsc190501.50541635'),
	'identifierSchemeCodeUnspsc190501.50541636': __('identifierSchemeCodeUnspsc190501.50541636'),
	'identifierSchemeCodeUnspsc190501.50541637': __('identifierSchemeCodeUnspsc190501.50541637'),
	'identifierSchemeCodeUnspsc190501.50541638': __('identifierSchemeCodeUnspsc190501.50541638'),
	'identifierSchemeCodeUnspsc190501.50541639': __('identifierSchemeCodeUnspsc190501.50541639'),
	'identifierSchemeCodeUnspsc190501.50541640': __('identifierSchemeCodeUnspsc190501.50541640'),
	'identifierSchemeCodeUnspsc190501.50541641': __('identifierSchemeCodeUnspsc190501.50541641'),
	'identifierSchemeCodeUnspsc190501.50541642': __('identifierSchemeCodeUnspsc190501.50541642'),
	'identifierSchemeCodeUnspsc190501.50541643': __('identifierSchemeCodeUnspsc190501.50541643'),
	'identifierSchemeCodeUnspsc190501.50541644': __('identifierSchemeCodeUnspsc190501.50541644'),
	'identifierSchemeCodeUnspsc190501.50541645': __('identifierSchemeCodeUnspsc190501.50541645'),
	'identifierSchemeCodeUnspsc190501.50541646': __('identifierSchemeCodeUnspsc190501.50541646'),
	'identifierSchemeCodeUnspsc190501.50541647': __('identifierSchemeCodeUnspsc190501.50541647'),
	'identifierSchemeCodeUnspsc190501.50541648': __('identifierSchemeCodeUnspsc190501.50541648'),
	'identifierSchemeCodeUnspsc190501.50541649': __('identifierSchemeCodeUnspsc190501.50541649'),
	'identifierSchemeCodeUnspsc190501.50541650': __('identifierSchemeCodeUnspsc190501.50541650'),
	'identifierSchemeCodeUnspsc190501.50541651': __('identifierSchemeCodeUnspsc190501.50541651'),
	'identifierSchemeCodeUnspsc190501.50541652': __('identifierSchemeCodeUnspsc190501.50541652'),
	'identifierSchemeCodeUnspsc190501.50541653': __('identifierSchemeCodeUnspsc190501.50541653'),
	'identifierSchemeCodeUnspsc190501.50541654': __('identifierSchemeCodeUnspsc190501.50541654'),
	'identifierSchemeCodeUnspsc190501.50541655': __('identifierSchemeCodeUnspsc190501.50541655'),
	'identifierSchemeCodeUnspsc190501.50541700': __('identifierSchemeCodeUnspsc190501.50541700'),
	'identifierSchemeCodeUnspsc190501.50541701': __('identifierSchemeCodeUnspsc190501.50541701'),
	'identifierSchemeCodeUnspsc190501.50541702': __('identifierSchemeCodeUnspsc190501.50541702'),
	'identifierSchemeCodeUnspsc190501.50541703': __('identifierSchemeCodeUnspsc190501.50541703'),
	'identifierSchemeCodeUnspsc190501.50541704': __('identifierSchemeCodeUnspsc190501.50541704'),
	'identifierSchemeCodeUnspsc190501.50541705': __('identifierSchemeCodeUnspsc190501.50541705'),
	'identifierSchemeCodeUnspsc190501.50541706': __('identifierSchemeCodeUnspsc190501.50541706'),
	'identifierSchemeCodeUnspsc190501.50541707': __('identifierSchemeCodeUnspsc190501.50541707'),
	'identifierSchemeCodeUnspsc190501.50541708': __('identifierSchemeCodeUnspsc190501.50541708'),
	'identifierSchemeCodeUnspsc190501.50541709': __('identifierSchemeCodeUnspsc190501.50541709'),
	'identifierSchemeCodeUnspsc190501.50541710': __('identifierSchemeCodeUnspsc190501.50541710'),
	'identifierSchemeCodeUnspsc190501.50541711': __('identifierSchemeCodeUnspsc190501.50541711'),
	'identifierSchemeCodeUnspsc190501.50541712': __('identifierSchemeCodeUnspsc190501.50541712'),
	'identifierSchemeCodeUnspsc190501.50541713': __('identifierSchemeCodeUnspsc190501.50541713'),
	'identifierSchemeCodeUnspsc190501.50541714': __('identifierSchemeCodeUnspsc190501.50541714'),
	'identifierSchemeCodeUnspsc190501.50541715': __('identifierSchemeCodeUnspsc190501.50541715'),
	'identifierSchemeCodeUnspsc190501.50541800': __('identifierSchemeCodeUnspsc190501.50541800'),
	'identifierSchemeCodeUnspsc190501.50541801': __('identifierSchemeCodeUnspsc190501.50541801'),
	'identifierSchemeCodeUnspsc190501.50541802': __('identifierSchemeCodeUnspsc190501.50541802'),
	'identifierSchemeCodeUnspsc190501.50541803': __('identifierSchemeCodeUnspsc190501.50541803'),
	'identifierSchemeCodeUnspsc190501.50541804': __('identifierSchemeCodeUnspsc190501.50541804'),
	'identifierSchemeCodeUnspsc190501.50541805': __('identifierSchemeCodeUnspsc190501.50541805'),
	'identifierSchemeCodeUnspsc190501.50541806': __('identifierSchemeCodeUnspsc190501.50541806'),
	'identifierSchemeCodeUnspsc190501.50541807': __('identifierSchemeCodeUnspsc190501.50541807'),
	'identifierSchemeCodeUnspsc190501.50541808': __('identifierSchemeCodeUnspsc190501.50541808'),
	'identifierSchemeCodeUnspsc190501.50541809': __('identifierSchemeCodeUnspsc190501.50541809'),
	'identifierSchemeCodeUnspsc190501.50541810': __('identifierSchemeCodeUnspsc190501.50541810'),
	'identifierSchemeCodeUnspsc190501.50541811': __('identifierSchemeCodeUnspsc190501.50541811'),
	'identifierSchemeCodeUnspsc190501.50541812': __('identifierSchemeCodeUnspsc190501.50541812'),
	'identifierSchemeCodeUnspsc190501.50541813': __('identifierSchemeCodeUnspsc190501.50541813'),
	'identifierSchemeCodeUnspsc190501.50541814': __('identifierSchemeCodeUnspsc190501.50541814'),
	'identifierSchemeCodeUnspsc190501.50541900': __('identifierSchemeCodeUnspsc190501.50541900'),
	'identifierSchemeCodeUnspsc190501.50541901': __('identifierSchemeCodeUnspsc190501.50541901'),
	'identifierSchemeCodeUnspsc190501.50541902': __('identifierSchemeCodeUnspsc190501.50541902'),
	'identifierSchemeCodeUnspsc190501.50541903': __('identifierSchemeCodeUnspsc190501.50541903'),
	'identifierSchemeCodeUnspsc190501.50542000': __('identifierSchemeCodeUnspsc190501.50542000'),
	'identifierSchemeCodeUnspsc190501.50542001': __('identifierSchemeCodeUnspsc190501.50542001'),
	'identifierSchemeCodeUnspsc190501.50542002': __('identifierSchemeCodeUnspsc190501.50542002'),
	'identifierSchemeCodeUnspsc190501.50542003': __('identifierSchemeCodeUnspsc190501.50542003'),
	'identifierSchemeCodeUnspsc190501.50542004': __('identifierSchemeCodeUnspsc190501.50542004'),
	'identifierSchemeCodeUnspsc190501.50542005': __('identifierSchemeCodeUnspsc190501.50542005'),
	'identifierSchemeCodeUnspsc190501.50542006': __('identifierSchemeCodeUnspsc190501.50542006'),
	'identifierSchemeCodeUnspsc190501.50542007': __('identifierSchemeCodeUnspsc190501.50542007'),
	'identifierSchemeCodeUnspsc190501.50542008': __('identifierSchemeCodeUnspsc190501.50542008'),
	'identifierSchemeCodeUnspsc190501.50542009': __('identifierSchemeCodeUnspsc190501.50542009'),
	'identifierSchemeCodeUnspsc190501.50542010': __('identifierSchemeCodeUnspsc190501.50542010'),
	'identifierSchemeCodeUnspsc190501.50542011': __('identifierSchemeCodeUnspsc190501.50542011'),
	'identifierSchemeCodeUnspsc190501.50542012': __('identifierSchemeCodeUnspsc190501.50542012'),
	'identifierSchemeCodeUnspsc190501.50542013': __('identifierSchemeCodeUnspsc190501.50542013'),
	'identifierSchemeCodeUnspsc190501.50542014': __('identifierSchemeCodeUnspsc190501.50542014'),
	'identifierSchemeCodeUnspsc190501.50542015': __('identifierSchemeCodeUnspsc190501.50542015'),
	'identifierSchemeCodeUnspsc190501.50542100': __('identifierSchemeCodeUnspsc190501.50542100'),
	'identifierSchemeCodeUnspsc190501.50542101': __('identifierSchemeCodeUnspsc190501.50542101'),
	'identifierSchemeCodeUnspsc190501.50542102': __('identifierSchemeCodeUnspsc190501.50542102'),
	'identifierSchemeCodeUnspsc190501.50542103': __('identifierSchemeCodeUnspsc190501.50542103'),
	'identifierSchemeCodeUnspsc190501.50542104': __('identifierSchemeCodeUnspsc190501.50542104'),
	'identifierSchemeCodeUnspsc190501.50542200': __('identifierSchemeCodeUnspsc190501.50542200'),
	'identifierSchemeCodeUnspsc190501.50542201': __('identifierSchemeCodeUnspsc190501.50542201'),
	'identifierSchemeCodeUnspsc190501.50542202': __('identifierSchemeCodeUnspsc190501.50542202'),
	'identifierSchemeCodeUnspsc190501.50542203': __('identifierSchemeCodeUnspsc190501.50542203'),
	'identifierSchemeCodeUnspsc190501.50542204': __('identifierSchemeCodeUnspsc190501.50542204'),
	'identifierSchemeCodeUnspsc190501.50542205': __('identifierSchemeCodeUnspsc190501.50542205'),
	'identifierSchemeCodeUnspsc190501.50542206': __('identifierSchemeCodeUnspsc190501.50542206'),
	'identifierSchemeCodeUnspsc190501.50542207': __('identifierSchemeCodeUnspsc190501.50542207'),
	'identifierSchemeCodeUnspsc190501.50542208': __('identifierSchemeCodeUnspsc190501.50542208'),
	'identifierSchemeCodeUnspsc190501.50542209': __('identifierSchemeCodeUnspsc190501.50542209'),
	'identifierSchemeCodeUnspsc190501.50542210': __('identifierSchemeCodeUnspsc190501.50542210'),
	'identifierSchemeCodeUnspsc190501.50542211': __('identifierSchemeCodeUnspsc190501.50542211'),
	'identifierSchemeCodeUnspsc190501.50542212': __('identifierSchemeCodeUnspsc190501.50542212'),
	'identifierSchemeCodeUnspsc190501.50542213': __('identifierSchemeCodeUnspsc190501.50542213'),
	'identifierSchemeCodeUnspsc190501.50542214': __('identifierSchemeCodeUnspsc190501.50542214'),
	'identifierSchemeCodeUnspsc190501.50542215': __('identifierSchemeCodeUnspsc190501.50542215'),
	'identifierSchemeCodeUnspsc190501.50542300': __('identifierSchemeCodeUnspsc190501.50542300'),
	'identifierSchemeCodeUnspsc190501.50542301': __('identifierSchemeCodeUnspsc190501.50542301'),
	'identifierSchemeCodeUnspsc190501.50542302': __('identifierSchemeCodeUnspsc190501.50542302'),
	'identifierSchemeCodeUnspsc190501.50542303': __('identifierSchemeCodeUnspsc190501.50542303'),
	'identifierSchemeCodeUnspsc190501.50542304': __('identifierSchemeCodeUnspsc190501.50542304'),
	'identifierSchemeCodeUnspsc190501.50542400': __('identifierSchemeCodeUnspsc190501.50542400'),
	'identifierSchemeCodeUnspsc190501.50542401': __('identifierSchemeCodeUnspsc190501.50542401'),
	'identifierSchemeCodeUnspsc190501.50542402': __('identifierSchemeCodeUnspsc190501.50542402'),
	'identifierSchemeCodeUnspsc190501.50542403': __('identifierSchemeCodeUnspsc190501.50542403'),
	'identifierSchemeCodeUnspsc190501.50542404': __('identifierSchemeCodeUnspsc190501.50542404'),
	'identifierSchemeCodeUnspsc190501.50542405': __('identifierSchemeCodeUnspsc190501.50542405'),
	'identifierSchemeCodeUnspsc190501.50542406': __('identifierSchemeCodeUnspsc190501.50542406'),
	'identifierSchemeCodeUnspsc190501.50542407': __('identifierSchemeCodeUnspsc190501.50542407'),
	'identifierSchemeCodeUnspsc190501.50542408': __('identifierSchemeCodeUnspsc190501.50542408'),
	'identifierSchemeCodeUnspsc190501.50542409': __('identifierSchemeCodeUnspsc190501.50542409'),
	'identifierSchemeCodeUnspsc190501.50542500': __('identifierSchemeCodeUnspsc190501.50542500'),
	'identifierSchemeCodeUnspsc190501.50542501': __('identifierSchemeCodeUnspsc190501.50542501'),
	'identifierSchemeCodeUnspsc190501.50542502': __('identifierSchemeCodeUnspsc190501.50542502'),
	'identifierSchemeCodeUnspsc190501.50542503': __('identifierSchemeCodeUnspsc190501.50542503'),
	'identifierSchemeCodeUnspsc190501.50542504': __('identifierSchemeCodeUnspsc190501.50542504'),
	'identifierSchemeCodeUnspsc190501.50542505': __('identifierSchemeCodeUnspsc190501.50542505'),
	'identifierSchemeCodeUnspsc190501.50542506': __('identifierSchemeCodeUnspsc190501.50542506'),
	'identifierSchemeCodeUnspsc190501.50542507': __('identifierSchemeCodeUnspsc190501.50542507'),
	'identifierSchemeCodeUnspsc190501.50542508': __('identifierSchemeCodeUnspsc190501.50542508'),
	'identifierSchemeCodeUnspsc190501.50542509': __('identifierSchemeCodeUnspsc190501.50542509'),
	'identifierSchemeCodeUnspsc190501.50542510': __('identifierSchemeCodeUnspsc190501.50542510'),
	'identifierSchemeCodeUnspsc190501.50542511': __('identifierSchemeCodeUnspsc190501.50542511'),
	'identifierSchemeCodeUnspsc190501.50542512': __('identifierSchemeCodeUnspsc190501.50542512'),
	'identifierSchemeCodeUnspsc190501.50542513': __('identifierSchemeCodeUnspsc190501.50542513'),
	'identifierSchemeCodeUnspsc190501.50542514': __('identifierSchemeCodeUnspsc190501.50542514'),
	'identifierSchemeCodeUnspsc190501.50542515': __('identifierSchemeCodeUnspsc190501.50542515'),
	'identifierSchemeCodeUnspsc190501.50542516': __('identifierSchemeCodeUnspsc190501.50542516'),
	'identifierSchemeCodeUnspsc190501.50542517': __('identifierSchemeCodeUnspsc190501.50542517'),
	'identifierSchemeCodeUnspsc190501.50542518': __('identifierSchemeCodeUnspsc190501.50542518'),
	'identifierSchemeCodeUnspsc190501.50542519': __('identifierSchemeCodeUnspsc190501.50542519'),
	'identifierSchemeCodeUnspsc190501.50542520': __('identifierSchemeCodeUnspsc190501.50542520'),
	'identifierSchemeCodeUnspsc190501.50542521': __('identifierSchemeCodeUnspsc190501.50542521'),
	'identifierSchemeCodeUnspsc190501.50542522': __('identifierSchemeCodeUnspsc190501.50542522'),
	'identifierSchemeCodeUnspsc190501.50542523': __('identifierSchemeCodeUnspsc190501.50542523'),
	'identifierSchemeCodeUnspsc190501.50542524': __('identifierSchemeCodeUnspsc190501.50542524'),
	'identifierSchemeCodeUnspsc190501.50542525': __('identifierSchemeCodeUnspsc190501.50542525'),
	'identifierSchemeCodeUnspsc190501.50542526': __('identifierSchemeCodeUnspsc190501.50542526'),
	'identifierSchemeCodeUnspsc190501.50542527': __('identifierSchemeCodeUnspsc190501.50542527'),
	'identifierSchemeCodeUnspsc190501.50542528': __('identifierSchemeCodeUnspsc190501.50542528'),
	'identifierSchemeCodeUnspsc190501.50542529': __('identifierSchemeCodeUnspsc190501.50542529'),
	'identifierSchemeCodeUnspsc190501.50542530': __('identifierSchemeCodeUnspsc190501.50542530'),
	'identifierSchemeCodeUnspsc190501.50542531': __('identifierSchemeCodeUnspsc190501.50542531'),
	'identifierSchemeCodeUnspsc190501.50542600': __('identifierSchemeCodeUnspsc190501.50542600'),
	'identifierSchemeCodeUnspsc190501.50542601': __('identifierSchemeCodeUnspsc190501.50542601'),
	'identifierSchemeCodeUnspsc190501.50542602': __('identifierSchemeCodeUnspsc190501.50542602'),
	'identifierSchemeCodeUnspsc190501.50542603': __('identifierSchemeCodeUnspsc190501.50542603'),
	'identifierSchemeCodeUnspsc190501.50542604': __('identifierSchemeCodeUnspsc190501.50542604'),
	'identifierSchemeCodeUnspsc190501.50542605': __('identifierSchemeCodeUnspsc190501.50542605'),
	'identifierSchemeCodeUnspsc190501.50542606': __('identifierSchemeCodeUnspsc190501.50542606'),
	'identifierSchemeCodeUnspsc190501.50542607': __('identifierSchemeCodeUnspsc190501.50542607'),
	'identifierSchemeCodeUnspsc190501.50542700': __('identifierSchemeCodeUnspsc190501.50542700'),
	'identifierSchemeCodeUnspsc190501.50542701': __('identifierSchemeCodeUnspsc190501.50542701'),
	'identifierSchemeCodeUnspsc190501.50542702': __('identifierSchemeCodeUnspsc190501.50542702'),
	'identifierSchemeCodeUnspsc190501.50542703': __('identifierSchemeCodeUnspsc190501.50542703'),
	'identifierSchemeCodeUnspsc190501.50542704': __('identifierSchemeCodeUnspsc190501.50542704'),
	'identifierSchemeCodeUnspsc190501.50542705': __('identifierSchemeCodeUnspsc190501.50542705'),
	'identifierSchemeCodeUnspsc190501.50542706': __('identifierSchemeCodeUnspsc190501.50542706'),
	'identifierSchemeCodeUnspsc190501.50542707': __('identifierSchemeCodeUnspsc190501.50542707'),
	'identifierSchemeCodeUnspsc190501.50542708': __('identifierSchemeCodeUnspsc190501.50542708'),
	'identifierSchemeCodeUnspsc190501.50542709': __('identifierSchemeCodeUnspsc190501.50542709'),
	'identifierSchemeCodeUnspsc190501.50542710': __('identifierSchemeCodeUnspsc190501.50542710'),
	'identifierSchemeCodeUnspsc190501.50542800': __('identifierSchemeCodeUnspsc190501.50542800'),
	'identifierSchemeCodeUnspsc190501.50542801': __('identifierSchemeCodeUnspsc190501.50542801'),
	'identifierSchemeCodeUnspsc190501.50542802': __('identifierSchemeCodeUnspsc190501.50542802'),
	'identifierSchemeCodeUnspsc190501.50542803': __('identifierSchemeCodeUnspsc190501.50542803'),
	'identifierSchemeCodeUnspsc190501.50542804': __('identifierSchemeCodeUnspsc190501.50542804'),
	'identifierSchemeCodeUnspsc190501.50542805': __('identifierSchemeCodeUnspsc190501.50542805'),
	'identifierSchemeCodeUnspsc190501.50542806': __('identifierSchemeCodeUnspsc190501.50542806'),
	'identifierSchemeCodeUnspsc190501.50542900': __('identifierSchemeCodeUnspsc190501.50542900'),
	'identifierSchemeCodeUnspsc190501.50542901': __('identifierSchemeCodeUnspsc190501.50542901'),
	'identifierSchemeCodeUnspsc190501.50542902': __('identifierSchemeCodeUnspsc190501.50542902'),
	'identifierSchemeCodeUnspsc190501.50542903': __('identifierSchemeCodeUnspsc190501.50542903'),
	'identifierSchemeCodeUnspsc190501.50542904': __('identifierSchemeCodeUnspsc190501.50542904'),
	'identifierSchemeCodeUnspsc190501.50542905': __('identifierSchemeCodeUnspsc190501.50542905'),
	'identifierSchemeCodeUnspsc190501.50542906': __('identifierSchemeCodeUnspsc190501.50542906'),
	'identifierSchemeCodeUnspsc190501.50542907': __('identifierSchemeCodeUnspsc190501.50542907'),
	'identifierSchemeCodeUnspsc190501.50542908': __('identifierSchemeCodeUnspsc190501.50542908'),
	'identifierSchemeCodeUnspsc190501.50542909': __('identifierSchemeCodeUnspsc190501.50542909'),
	'identifierSchemeCodeUnspsc190501.50542910': __('identifierSchemeCodeUnspsc190501.50542910'),
	'identifierSchemeCodeUnspsc190501.50542911': __('identifierSchemeCodeUnspsc190501.50542911'),
	'identifierSchemeCodeUnspsc190501.50542912': __('identifierSchemeCodeUnspsc190501.50542912'),
	'identifierSchemeCodeUnspsc190501.50542913': __('identifierSchemeCodeUnspsc190501.50542913'),
	'identifierSchemeCodeUnspsc190501.50542914': __('identifierSchemeCodeUnspsc190501.50542914'),
	'identifierSchemeCodeUnspsc190501.50542915': __('identifierSchemeCodeUnspsc190501.50542915'),
	'identifierSchemeCodeUnspsc190501.50542916': __('identifierSchemeCodeUnspsc190501.50542916'),
	'identifierSchemeCodeUnspsc190501.50543000': __('identifierSchemeCodeUnspsc190501.50543000'),
	'identifierSchemeCodeUnspsc190501.50543001': __('identifierSchemeCodeUnspsc190501.50543001'),
	'identifierSchemeCodeUnspsc190501.50543002': __('identifierSchemeCodeUnspsc190501.50543002'),
	'identifierSchemeCodeUnspsc190501.50543100': __('identifierSchemeCodeUnspsc190501.50543100'),
	'identifierSchemeCodeUnspsc190501.50543101': __('identifierSchemeCodeUnspsc190501.50543101'),
	'identifierSchemeCodeUnspsc190501.50543102': __('identifierSchemeCodeUnspsc190501.50543102'),
	'identifierSchemeCodeUnspsc190501.50543103': __('identifierSchemeCodeUnspsc190501.50543103'),
	'identifierSchemeCodeUnspsc190501.50543104': __('identifierSchemeCodeUnspsc190501.50543104'),
	'identifierSchemeCodeUnspsc190501.50543105': __('identifierSchemeCodeUnspsc190501.50543105'),
	'identifierSchemeCodeUnspsc190501.50543106': __('identifierSchemeCodeUnspsc190501.50543106'),
	'identifierSchemeCodeUnspsc190501.50543107': __('identifierSchemeCodeUnspsc190501.50543107'),
	'identifierSchemeCodeUnspsc190501.50543108': __('identifierSchemeCodeUnspsc190501.50543108'),
	'identifierSchemeCodeUnspsc190501.50543109': __('identifierSchemeCodeUnspsc190501.50543109'),
	'identifierSchemeCodeUnspsc190501.50543110': __('identifierSchemeCodeUnspsc190501.50543110'),
	'identifierSchemeCodeUnspsc190501.50543111': __('identifierSchemeCodeUnspsc190501.50543111'),
	'identifierSchemeCodeUnspsc190501.50543200': __('identifierSchemeCodeUnspsc190501.50543200'),
	'identifierSchemeCodeUnspsc190501.50543201': __('identifierSchemeCodeUnspsc190501.50543201'),
	'identifierSchemeCodeUnspsc190501.50543202': __('identifierSchemeCodeUnspsc190501.50543202'),
	'identifierSchemeCodeUnspsc190501.50543203': __('identifierSchemeCodeUnspsc190501.50543203'),
	'identifierSchemeCodeUnspsc190501.50543204': __('identifierSchemeCodeUnspsc190501.50543204'),
	'identifierSchemeCodeUnspsc190501.50543205': __('identifierSchemeCodeUnspsc190501.50543205'),
	'identifierSchemeCodeUnspsc190501.50543206': __('identifierSchemeCodeUnspsc190501.50543206'),
	'identifierSchemeCodeUnspsc190501.50543207': __('identifierSchemeCodeUnspsc190501.50543207'),
	'identifierSchemeCodeUnspsc190501.50543300': __('identifierSchemeCodeUnspsc190501.50543300'),
	'identifierSchemeCodeUnspsc190501.50543301': __('identifierSchemeCodeUnspsc190501.50543301'),
	'identifierSchemeCodeUnspsc190501.50543302': __('identifierSchemeCodeUnspsc190501.50543302'),
	'identifierSchemeCodeUnspsc190501.50543303': __('identifierSchemeCodeUnspsc190501.50543303'),
	'identifierSchemeCodeUnspsc190501.50543304': __('identifierSchemeCodeUnspsc190501.50543304'),
	'identifierSchemeCodeUnspsc190501.50543305': __('identifierSchemeCodeUnspsc190501.50543305'),
	'identifierSchemeCodeUnspsc190501.50543306': __('identifierSchemeCodeUnspsc190501.50543306'),
	'identifierSchemeCodeUnspsc190501.50543307': __('identifierSchemeCodeUnspsc190501.50543307'),
	'identifierSchemeCodeUnspsc190501.50543308': __('identifierSchemeCodeUnspsc190501.50543308'),
	'identifierSchemeCodeUnspsc190501.50543309': __('identifierSchemeCodeUnspsc190501.50543309'),
	'identifierSchemeCodeUnspsc190501.50543400': __('identifierSchemeCodeUnspsc190501.50543400'),
	'identifierSchemeCodeUnspsc190501.50543401': __('identifierSchemeCodeUnspsc190501.50543401'),
	'identifierSchemeCodeUnspsc190501.50543402': __('identifierSchemeCodeUnspsc190501.50543402'),
	'identifierSchemeCodeUnspsc190501.50543403': __('identifierSchemeCodeUnspsc190501.50543403'),
	'identifierSchemeCodeUnspsc190501.50543404': __('identifierSchemeCodeUnspsc190501.50543404'),
	'identifierSchemeCodeUnspsc190501.50543405': __('identifierSchemeCodeUnspsc190501.50543405'),
	'identifierSchemeCodeUnspsc190501.50543406': __('identifierSchemeCodeUnspsc190501.50543406'),
	'identifierSchemeCodeUnspsc190501.50543407': __('identifierSchemeCodeUnspsc190501.50543407'),
	'identifierSchemeCodeUnspsc190501.50543408': __('identifierSchemeCodeUnspsc190501.50543408'),
	'identifierSchemeCodeUnspsc190501.50543409': __('identifierSchemeCodeUnspsc190501.50543409'),
	'identifierSchemeCodeUnspsc190501.50543410': __('identifierSchemeCodeUnspsc190501.50543410'),
	'identifierSchemeCodeUnspsc190501.50543411': __('identifierSchemeCodeUnspsc190501.50543411'),
	'identifierSchemeCodeUnspsc190501.50543412': __('identifierSchemeCodeUnspsc190501.50543412'),
	'identifierSchemeCodeUnspsc190501.50543413': __('identifierSchemeCodeUnspsc190501.50543413'),
	'identifierSchemeCodeUnspsc190501.50543414': __('identifierSchemeCodeUnspsc190501.50543414'),
	'identifierSchemeCodeUnspsc190501.50543415': __('identifierSchemeCodeUnspsc190501.50543415'),
	'identifierSchemeCodeUnspsc190501.50543416': __('identifierSchemeCodeUnspsc190501.50543416'),
	'identifierSchemeCodeUnspsc190501.50543417': __('identifierSchemeCodeUnspsc190501.50543417'),
	'identifierSchemeCodeUnspsc190501.50543418': __('identifierSchemeCodeUnspsc190501.50543418'),
	'identifierSchemeCodeUnspsc190501.50543419': __('identifierSchemeCodeUnspsc190501.50543419'),
	'identifierSchemeCodeUnspsc190501.50543420': __('identifierSchemeCodeUnspsc190501.50543420'),
	'identifierSchemeCodeUnspsc190501.50543421': __('identifierSchemeCodeUnspsc190501.50543421'),
	'identifierSchemeCodeUnspsc190501.50543422': __('identifierSchemeCodeUnspsc190501.50543422'),
	'identifierSchemeCodeUnspsc190501.50543423': __('identifierSchemeCodeUnspsc190501.50543423'),
	'identifierSchemeCodeUnspsc190501.50543424': __('identifierSchemeCodeUnspsc190501.50543424'),
	'identifierSchemeCodeUnspsc190501.50543425': __('identifierSchemeCodeUnspsc190501.50543425'),
	'identifierSchemeCodeUnspsc190501.50543426': __('identifierSchemeCodeUnspsc190501.50543426'),
	'identifierSchemeCodeUnspsc190501.50543427': __('identifierSchemeCodeUnspsc190501.50543427'),
	'identifierSchemeCodeUnspsc190501.50543428': __('identifierSchemeCodeUnspsc190501.50543428'),
	'identifierSchemeCodeUnspsc190501.50543429': __('identifierSchemeCodeUnspsc190501.50543429'),
	'identifierSchemeCodeUnspsc190501.50543430': __('identifierSchemeCodeUnspsc190501.50543430'),
	'identifierSchemeCodeUnspsc190501.50543431': __('identifierSchemeCodeUnspsc190501.50543431'),
	'identifierSchemeCodeUnspsc190501.50543432': __('identifierSchemeCodeUnspsc190501.50543432'),
	'identifierSchemeCodeUnspsc190501.50543433': __('identifierSchemeCodeUnspsc190501.50543433'),
	'identifierSchemeCodeUnspsc190501.50543434': __('identifierSchemeCodeUnspsc190501.50543434'),
	'identifierSchemeCodeUnspsc190501.50543435': __('identifierSchemeCodeUnspsc190501.50543435'),
	'identifierSchemeCodeUnspsc190501.50543436': __('identifierSchemeCodeUnspsc190501.50543436'),
	'identifierSchemeCodeUnspsc190501.50543437': __('identifierSchemeCodeUnspsc190501.50543437'),
	'identifierSchemeCodeUnspsc190501.50543438': __('identifierSchemeCodeUnspsc190501.50543438'),
	'identifierSchemeCodeUnspsc190501.50543439': __('identifierSchemeCodeUnspsc190501.50543439'),
	'identifierSchemeCodeUnspsc190501.50543440': __('identifierSchemeCodeUnspsc190501.50543440'),
	'identifierSchemeCodeUnspsc190501.50543441': __('identifierSchemeCodeUnspsc190501.50543441'),
	'identifierSchemeCodeUnspsc190501.50543442': __('identifierSchemeCodeUnspsc190501.50543442'),
	'identifierSchemeCodeUnspsc190501.50543443': __('identifierSchemeCodeUnspsc190501.50543443'),
	'identifierSchemeCodeUnspsc190501.50543444': __('identifierSchemeCodeUnspsc190501.50543444'),
	'identifierSchemeCodeUnspsc190501.50543445': __('identifierSchemeCodeUnspsc190501.50543445'),
	'identifierSchemeCodeUnspsc190501.50543446': __('identifierSchemeCodeUnspsc190501.50543446'),
	'identifierSchemeCodeUnspsc190501.50543447': __('identifierSchemeCodeUnspsc190501.50543447'),
	'identifierSchemeCodeUnspsc190501.50543448': __('identifierSchemeCodeUnspsc190501.50543448'),
	'identifierSchemeCodeUnspsc190501.50543449': __('identifierSchemeCodeUnspsc190501.50543449'),
	'identifierSchemeCodeUnspsc190501.50543450': __('identifierSchemeCodeUnspsc190501.50543450'),
	'identifierSchemeCodeUnspsc190501.50543451': __('identifierSchemeCodeUnspsc190501.50543451'),
	'identifierSchemeCodeUnspsc190501.50543452': __('identifierSchemeCodeUnspsc190501.50543452'),
	'identifierSchemeCodeUnspsc190501.50543453': __('identifierSchemeCodeUnspsc190501.50543453'),
	'identifierSchemeCodeUnspsc190501.50543454': __('identifierSchemeCodeUnspsc190501.50543454'),
	'identifierSchemeCodeUnspsc190501.50543455': __('identifierSchemeCodeUnspsc190501.50543455'),
	'identifierSchemeCodeUnspsc190501.50543456': __('identifierSchemeCodeUnspsc190501.50543456'),
	'identifierSchemeCodeUnspsc190501.50543457': __('identifierSchemeCodeUnspsc190501.50543457'),
	'identifierSchemeCodeUnspsc190501.50543458': __('identifierSchemeCodeUnspsc190501.50543458'),
	'identifierSchemeCodeUnspsc190501.50543459': __('identifierSchemeCodeUnspsc190501.50543459'),
	'identifierSchemeCodeUnspsc190501.50543460': __('identifierSchemeCodeUnspsc190501.50543460'),
	'identifierSchemeCodeUnspsc190501.50543461': __('identifierSchemeCodeUnspsc190501.50543461'),
	'identifierSchemeCodeUnspsc190501.50543462': __('identifierSchemeCodeUnspsc190501.50543462'),
	'identifierSchemeCodeUnspsc190501.50543463': __('identifierSchemeCodeUnspsc190501.50543463'),
	'identifierSchemeCodeUnspsc190501.50543464': __('identifierSchemeCodeUnspsc190501.50543464'),
	'identifierSchemeCodeUnspsc190501.50543465': __('identifierSchemeCodeUnspsc190501.50543465'),
	'identifierSchemeCodeUnspsc190501.50543466': __('identifierSchemeCodeUnspsc190501.50543466'),
	'identifierSchemeCodeUnspsc190501.50543467': __('identifierSchemeCodeUnspsc190501.50543467'),
	'identifierSchemeCodeUnspsc190501.50543468': __('identifierSchemeCodeUnspsc190501.50543468'),
	'identifierSchemeCodeUnspsc190501.50543469': __('identifierSchemeCodeUnspsc190501.50543469'),
	'identifierSchemeCodeUnspsc190501.50543470': __('identifierSchemeCodeUnspsc190501.50543470'),
	'identifierSchemeCodeUnspsc190501.50543471': __('identifierSchemeCodeUnspsc190501.50543471'),
	'identifierSchemeCodeUnspsc190501.50543472': __('identifierSchemeCodeUnspsc190501.50543472'),
	'identifierSchemeCodeUnspsc190501.50543473': __('identifierSchemeCodeUnspsc190501.50543473'),
	'identifierSchemeCodeUnspsc190501.50543474': __('identifierSchemeCodeUnspsc190501.50543474'),
	'identifierSchemeCodeUnspsc190501.50543475': __('identifierSchemeCodeUnspsc190501.50543475'),
	'identifierSchemeCodeUnspsc190501.50543476': __('identifierSchemeCodeUnspsc190501.50543476'),
	'identifierSchemeCodeUnspsc190501.50543477': __('identifierSchemeCodeUnspsc190501.50543477'),
	'identifierSchemeCodeUnspsc190501.50543478': __('identifierSchemeCodeUnspsc190501.50543478'),
	'identifierSchemeCodeUnspsc190501.50543479': __('identifierSchemeCodeUnspsc190501.50543479'),
	'identifierSchemeCodeUnspsc190501.50543480': __('identifierSchemeCodeUnspsc190501.50543480'),
	'identifierSchemeCodeUnspsc190501.50543481': __('identifierSchemeCodeUnspsc190501.50543481'),
	'identifierSchemeCodeUnspsc190501.50543482': __('identifierSchemeCodeUnspsc190501.50543482'),
	'identifierSchemeCodeUnspsc190501.50543483': __('identifierSchemeCodeUnspsc190501.50543483'),
	'identifierSchemeCodeUnspsc190501.50543484': __('identifierSchemeCodeUnspsc190501.50543484'),
	'identifierSchemeCodeUnspsc190501.50543485': __('identifierSchemeCodeUnspsc190501.50543485'),
	'identifierSchemeCodeUnspsc190501.50543486': __('identifierSchemeCodeUnspsc190501.50543486'),
	'identifierSchemeCodeUnspsc190501.50543487': __('identifierSchemeCodeUnspsc190501.50543487'),
	'identifierSchemeCodeUnspsc190501.50543488': __('identifierSchemeCodeUnspsc190501.50543488'),
	'identifierSchemeCodeUnspsc190501.50543489': __('identifierSchemeCodeUnspsc190501.50543489'),
	'identifierSchemeCodeUnspsc190501.50543490': __('identifierSchemeCodeUnspsc190501.50543490'),
	'identifierSchemeCodeUnspsc190501.50543491': __('identifierSchemeCodeUnspsc190501.50543491'),
	'identifierSchemeCodeUnspsc190501.50543492': __('identifierSchemeCodeUnspsc190501.50543492'),
	'identifierSchemeCodeUnspsc190501.50543493': __('identifierSchemeCodeUnspsc190501.50543493'),
	'identifierSchemeCodeUnspsc190501.50543494': __('identifierSchemeCodeUnspsc190501.50543494'),
	'identifierSchemeCodeUnspsc190501.50543495': __('identifierSchemeCodeUnspsc190501.50543495'),
	'identifierSchemeCodeUnspsc190501.50543496': __('identifierSchemeCodeUnspsc190501.50543496'),
	'identifierSchemeCodeUnspsc190501.50543500': __('identifierSchemeCodeUnspsc190501.50543500'),
	'identifierSchemeCodeUnspsc190501.50543501': __('identifierSchemeCodeUnspsc190501.50543501'),
	'identifierSchemeCodeUnspsc190501.50543502': __('identifierSchemeCodeUnspsc190501.50543502'),
	'identifierSchemeCodeUnspsc190501.50543503': __('identifierSchemeCodeUnspsc190501.50543503'),
	'identifierSchemeCodeUnspsc190501.50543504': __('identifierSchemeCodeUnspsc190501.50543504'),
	'identifierSchemeCodeUnspsc190501.50543505': __('identifierSchemeCodeUnspsc190501.50543505'),
	'identifierSchemeCodeUnspsc190501.50543506': __('identifierSchemeCodeUnspsc190501.50543506'),
	'identifierSchemeCodeUnspsc190501.50543600': __('identifierSchemeCodeUnspsc190501.50543600'),
	'identifierSchemeCodeUnspsc190501.50543601': __('identifierSchemeCodeUnspsc190501.50543601'),
	'identifierSchemeCodeUnspsc190501.50543602': __('identifierSchemeCodeUnspsc190501.50543602'),
	'identifierSchemeCodeUnspsc190501.50543603': __('identifierSchemeCodeUnspsc190501.50543603'),
	'identifierSchemeCodeUnspsc190501.50543604': __('identifierSchemeCodeUnspsc190501.50543604'),
	'identifierSchemeCodeUnspsc190501.50543605': __('identifierSchemeCodeUnspsc190501.50543605'),
	'identifierSchemeCodeUnspsc190501.50543606': __('identifierSchemeCodeUnspsc190501.50543606'),
	'identifierSchemeCodeUnspsc190501.50543607': __('identifierSchemeCodeUnspsc190501.50543607'),
	'identifierSchemeCodeUnspsc190501.50543608': __('identifierSchemeCodeUnspsc190501.50543608'),
	'identifierSchemeCodeUnspsc190501.50543609': __('identifierSchemeCodeUnspsc190501.50543609'),
	'identifierSchemeCodeUnspsc190501.50543610': __('identifierSchemeCodeUnspsc190501.50543610'),
	'identifierSchemeCodeUnspsc190501.50543611': __('identifierSchemeCodeUnspsc190501.50543611'),
	'identifierSchemeCodeUnspsc190501.50543612': __('identifierSchemeCodeUnspsc190501.50543612'),
	'identifierSchemeCodeUnspsc190501.50543613': __('identifierSchemeCodeUnspsc190501.50543613'),
	'identifierSchemeCodeUnspsc190501.50543614': __('identifierSchemeCodeUnspsc190501.50543614'),
	'identifierSchemeCodeUnspsc190501.50543615': __('identifierSchemeCodeUnspsc190501.50543615'),
	'identifierSchemeCodeUnspsc190501.50543616': __('identifierSchemeCodeUnspsc190501.50543616'),
	'identifierSchemeCodeUnspsc190501.50543617': __('identifierSchemeCodeUnspsc190501.50543617'),
	'identifierSchemeCodeUnspsc190501.50543618': __('identifierSchemeCodeUnspsc190501.50543618'),
	'identifierSchemeCodeUnspsc190501.50543619': __('identifierSchemeCodeUnspsc190501.50543619'),
	'identifierSchemeCodeUnspsc190501.50543620': __('identifierSchemeCodeUnspsc190501.50543620'),
	'identifierSchemeCodeUnspsc190501.50543621': __('identifierSchemeCodeUnspsc190501.50543621'),
	'identifierSchemeCodeUnspsc190501.50543622': __('identifierSchemeCodeUnspsc190501.50543622'),
	'identifierSchemeCodeUnspsc190501.50543623': __('identifierSchemeCodeUnspsc190501.50543623'),
	'identifierSchemeCodeUnspsc190501.50543624': __('identifierSchemeCodeUnspsc190501.50543624'),
	'identifierSchemeCodeUnspsc190501.50543625': __('identifierSchemeCodeUnspsc190501.50543625'),
	'identifierSchemeCodeUnspsc190501.50543626': __('identifierSchemeCodeUnspsc190501.50543626'),
	'identifierSchemeCodeUnspsc190501.50543627': __('identifierSchemeCodeUnspsc190501.50543627'),
	'identifierSchemeCodeUnspsc190501.50543628': __('identifierSchemeCodeUnspsc190501.50543628'),
	'identifierSchemeCodeUnspsc190501.50543629': __('identifierSchemeCodeUnspsc190501.50543629'),
	'identifierSchemeCodeUnspsc190501.50543630': __('identifierSchemeCodeUnspsc190501.50543630'),
	'identifierSchemeCodeUnspsc190501.50543631': __('identifierSchemeCodeUnspsc190501.50543631'),
	'identifierSchemeCodeUnspsc190501.50543632': __('identifierSchemeCodeUnspsc190501.50543632'),
	'identifierSchemeCodeUnspsc190501.50543633': __('identifierSchemeCodeUnspsc190501.50543633'),
	'identifierSchemeCodeUnspsc190501.50543634': __('identifierSchemeCodeUnspsc190501.50543634'),
	'identifierSchemeCodeUnspsc190501.50543635': __('identifierSchemeCodeUnspsc190501.50543635'),
	'identifierSchemeCodeUnspsc190501.50543636': __('identifierSchemeCodeUnspsc190501.50543636'),
	'identifierSchemeCodeUnspsc190501.50543637': __('identifierSchemeCodeUnspsc190501.50543637'),
	'identifierSchemeCodeUnspsc190501.50543638': __('identifierSchemeCodeUnspsc190501.50543638'),
	'identifierSchemeCodeUnspsc190501.50543639': __('identifierSchemeCodeUnspsc190501.50543639'),
	'identifierSchemeCodeUnspsc190501.50543640': __('identifierSchemeCodeUnspsc190501.50543640'),
	'identifierSchemeCodeUnspsc190501.50543641': __('identifierSchemeCodeUnspsc190501.50543641'),
	'identifierSchemeCodeUnspsc190501.50543642': __('identifierSchemeCodeUnspsc190501.50543642'),
	'identifierSchemeCodeUnspsc190501.50543643': __('identifierSchemeCodeUnspsc190501.50543643'),
	'identifierSchemeCodeUnspsc190501.50543644': __('identifierSchemeCodeUnspsc190501.50543644'),
	'identifierSchemeCodeUnspsc190501.50543645': __('identifierSchemeCodeUnspsc190501.50543645'),
	'identifierSchemeCodeUnspsc190501.50543646': __('identifierSchemeCodeUnspsc190501.50543646'),
	'identifierSchemeCodeUnspsc190501.50543647': __('identifierSchemeCodeUnspsc190501.50543647'),
	'identifierSchemeCodeUnspsc190501.50543648': __('identifierSchemeCodeUnspsc190501.50543648'),
	'identifierSchemeCodeUnspsc190501.50543649': __('identifierSchemeCodeUnspsc190501.50543649'),
	'identifierSchemeCodeUnspsc190501.50543650': __('identifierSchemeCodeUnspsc190501.50543650'),
	'identifierSchemeCodeUnspsc190501.50543651': __('identifierSchemeCodeUnspsc190501.50543651'),
	'identifierSchemeCodeUnspsc190501.50543652': __('identifierSchemeCodeUnspsc190501.50543652'),
	'identifierSchemeCodeUnspsc190501.50543653': __('identifierSchemeCodeUnspsc190501.50543653'),
	'identifierSchemeCodeUnspsc190501.50543654': __('identifierSchemeCodeUnspsc190501.50543654'),
	'identifierSchemeCodeUnspsc190501.50543655': __('identifierSchemeCodeUnspsc190501.50543655'),
	'identifierSchemeCodeUnspsc190501.50543656': __('identifierSchemeCodeUnspsc190501.50543656'),
	'identifierSchemeCodeUnspsc190501.50543657': __('identifierSchemeCodeUnspsc190501.50543657'),
	'identifierSchemeCodeUnspsc190501.50543658': __('identifierSchemeCodeUnspsc190501.50543658'),
	'identifierSchemeCodeUnspsc190501.50543659': __('identifierSchemeCodeUnspsc190501.50543659'),
	'identifierSchemeCodeUnspsc190501.50543660': __('identifierSchemeCodeUnspsc190501.50543660'),
	'identifierSchemeCodeUnspsc190501.50543661': __('identifierSchemeCodeUnspsc190501.50543661'),
	'identifierSchemeCodeUnspsc190501.50543662': __('identifierSchemeCodeUnspsc190501.50543662'),
	'identifierSchemeCodeUnspsc190501.50543663': __('identifierSchemeCodeUnspsc190501.50543663'),
	'identifierSchemeCodeUnspsc190501.50543664': __('identifierSchemeCodeUnspsc190501.50543664'),
	'identifierSchemeCodeUnspsc190501.50543665': __('identifierSchemeCodeUnspsc190501.50543665'),
	'identifierSchemeCodeUnspsc190501.50543700': __('identifierSchemeCodeUnspsc190501.50543700'),
	'identifierSchemeCodeUnspsc190501.50543701': __('identifierSchemeCodeUnspsc190501.50543701'),
	'identifierSchemeCodeUnspsc190501.50543702': __('identifierSchemeCodeUnspsc190501.50543702'),
	'identifierSchemeCodeUnspsc190501.50543703': __('identifierSchemeCodeUnspsc190501.50543703'),
	'identifierSchemeCodeUnspsc190501.50543704': __('identifierSchemeCodeUnspsc190501.50543704'),
	'identifierSchemeCodeUnspsc190501.50543800': __('identifierSchemeCodeUnspsc190501.50543800'),
	'identifierSchemeCodeUnspsc190501.50543801': __('identifierSchemeCodeUnspsc190501.50543801'),
	'identifierSchemeCodeUnspsc190501.50543802': __('identifierSchemeCodeUnspsc190501.50543802'),
	'identifierSchemeCodeUnspsc190501.50543803': __('identifierSchemeCodeUnspsc190501.50543803'),
	'identifierSchemeCodeUnspsc190501.50543804': __('identifierSchemeCodeUnspsc190501.50543804'),
	'identifierSchemeCodeUnspsc190501.50543805': __('identifierSchemeCodeUnspsc190501.50543805'),
	'identifierSchemeCodeUnspsc190501.50543900': __('identifierSchemeCodeUnspsc190501.50543900'),
	'identifierSchemeCodeUnspsc190501.50543901': __('identifierSchemeCodeUnspsc190501.50543901'),
	'identifierSchemeCodeUnspsc190501.50543902': __('identifierSchemeCodeUnspsc190501.50543902'),
	'identifierSchemeCodeUnspsc190501.50543903': __('identifierSchemeCodeUnspsc190501.50543903'),
	'identifierSchemeCodeUnspsc190501.50543904': __('identifierSchemeCodeUnspsc190501.50543904'),
	'identifierSchemeCodeUnspsc190501.50543905': __('identifierSchemeCodeUnspsc190501.50543905'),
	'identifierSchemeCodeUnspsc190501.50543906': __('identifierSchemeCodeUnspsc190501.50543906'),
	'identifierSchemeCodeUnspsc190501.50544000': __('identifierSchemeCodeUnspsc190501.50544000'),
	'identifierSchemeCodeUnspsc190501.50544001': __('identifierSchemeCodeUnspsc190501.50544001'),
	'identifierSchemeCodeUnspsc190501.50544002': __('identifierSchemeCodeUnspsc190501.50544002'),
	'identifierSchemeCodeUnspsc190501.50544003': __('identifierSchemeCodeUnspsc190501.50544003'),
	'identifierSchemeCodeUnspsc190501.50544004': __('identifierSchemeCodeUnspsc190501.50544004'),
	'identifierSchemeCodeUnspsc190501.50544005': __('identifierSchemeCodeUnspsc190501.50544005'),
	'identifierSchemeCodeUnspsc190501.50544006': __('identifierSchemeCodeUnspsc190501.50544006'),
	'identifierSchemeCodeUnspsc190501.50544400': __('identifierSchemeCodeUnspsc190501.50544400'),
	'identifierSchemeCodeUnspsc190501.50544401': __('identifierSchemeCodeUnspsc190501.50544401'),
	'identifierSchemeCodeUnspsc190501.50544402': __('identifierSchemeCodeUnspsc190501.50544402'),
	'identifierSchemeCodeUnspsc190501.50544403': __('identifierSchemeCodeUnspsc190501.50544403'),
	'identifierSchemeCodeUnspsc190501.50544404': __('identifierSchemeCodeUnspsc190501.50544404'),
	'identifierSchemeCodeUnspsc190501.50544405': __('identifierSchemeCodeUnspsc190501.50544405'),
	'identifierSchemeCodeUnspsc190501.50544406': __('identifierSchemeCodeUnspsc190501.50544406'),
	'identifierSchemeCodeUnspsc190501.50544407': __('identifierSchemeCodeUnspsc190501.50544407'),
	'identifierSchemeCodeUnspsc190501.50544408': __('identifierSchemeCodeUnspsc190501.50544408'),
	'identifierSchemeCodeUnspsc190501.50544409': __('identifierSchemeCodeUnspsc190501.50544409'),
	'identifierSchemeCodeUnspsc190501.50544410': __('identifierSchemeCodeUnspsc190501.50544410'),
	'identifierSchemeCodeUnspsc190501.50544411': __('identifierSchemeCodeUnspsc190501.50544411'),
	'identifierSchemeCodeUnspsc190501.50544412': __('identifierSchemeCodeUnspsc190501.50544412'),
	'identifierSchemeCodeUnspsc190501.50544413': __('identifierSchemeCodeUnspsc190501.50544413'),
	'identifierSchemeCodeUnspsc190501.50544414': __('identifierSchemeCodeUnspsc190501.50544414'),
	'identifierSchemeCodeUnspsc190501.50544415': __('identifierSchemeCodeUnspsc190501.50544415'),
	'identifierSchemeCodeUnspsc190501.50544416': __('identifierSchemeCodeUnspsc190501.50544416'),
	'identifierSchemeCodeUnspsc190501.50544417': __('identifierSchemeCodeUnspsc190501.50544417'),
	'identifierSchemeCodeUnspsc190501.50544418': __('identifierSchemeCodeUnspsc190501.50544418'),
	'identifierSchemeCodeUnspsc190501.50544419': __('identifierSchemeCodeUnspsc190501.50544419'),
	'identifierSchemeCodeUnspsc190501.50544420': __('identifierSchemeCodeUnspsc190501.50544420'),
	'identifierSchemeCodeUnspsc190501.50544421': __('identifierSchemeCodeUnspsc190501.50544421'),
	'identifierSchemeCodeUnspsc190501.50544422': __('identifierSchemeCodeUnspsc190501.50544422'),
	'identifierSchemeCodeUnspsc190501.50544423': __('identifierSchemeCodeUnspsc190501.50544423'),
	'identifierSchemeCodeUnspsc190501.50544424': __('identifierSchemeCodeUnspsc190501.50544424'),
	'identifierSchemeCodeUnspsc190501.50544425': __('identifierSchemeCodeUnspsc190501.50544425'),
	'identifierSchemeCodeUnspsc190501.50544426': __('identifierSchemeCodeUnspsc190501.50544426'),
	'identifierSchemeCodeUnspsc190501.50544427': __('identifierSchemeCodeUnspsc190501.50544427'),
	'identifierSchemeCodeUnspsc190501.50544428': __('identifierSchemeCodeUnspsc190501.50544428'),
	'identifierSchemeCodeUnspsc190501.50544429': __('identifierSchemeCodeUnspsc190501.50544429'),
	'identifierSchemeCodeUnspsc190501.50544430': __('identifierSchemeCodeUnspsc190501.50544430'),
	'identifierSchemeCodeUnspsc190501.50544431': __('identifierSchemeCodeUnspsc190501.50544431'),
	'identifierSchemeCodeUnspsc190501.50544432': __('identifierSchemeCodeUnspsc190501.50544432'),
	'identifierSchemeCodeUnspsc190501.50544433': __('identifierSchemeCodeUnspsc190501.50544433'),
	'identifierSchemeCodeUnspsc190501.50544434': __('identifierSchemeCodeUnspsc190501.50544434'),
	'identifierSchemeCodeUnspsc190501.50544435': __('identifierSchemeCodeUnspsc190501.50544435'),
	'identifierSchemeCodeUnspsc190501.50544436': __('identifierSchemeCodeUnspsc190501.50544436'),
	'identifierSchemeCodeUnspsc190501.50544437': __('identifierSchemeCodeUnspsc190501.50544437'),
	'identifierSchemeCodeUnspsc190501.50544438': __('identifierSchemeCodeUnspsc190501.50544438'),
	'identifierSchemeCodeUnspsc190501.50544439': __('identifierSchemeCodeUnspsc190501.50544439'),
	'identifierSchemeCodeUnspsc190501.50544440': __('identifierSchemeCodeUnspsc190501.50544440'),
	'identifierSchemeCodeUnspsc190501.50544441': __('identifierSchemeCodeUnspsc190501.50544441'),
	'identifierSchemeCodeUnspsc190501.50544442': __('identifierSchemeCodeUnspsc190501.50544442'),
	'identifierSchemeCodeUnspsc190501.50544443': __('identifierSchemeCodeUnspsc190501.50544443'),
	'identifierSchemeCodeUnspsc190501.50544444': __('identifierSchemeCodeUnspsc190501.50544444'),
	'identifierSchemeCodeUnspsc190501.50544445': __('identifierSchemeCodeUnspsc190501.50544445'),
	'identifierSchemeCodeUnspsc190501.50544446': __('identifierSchemeCodeUnspsc190501.50544446'),
	'identifierSchemeCodeUnspsc190501.50544500': __('identifierSchemeCodeUnspsc190501.50544500'),
	'identifierSchemeCodeUnspsc190501.50544501': __('identifierSchemeCodeUnspsc190501.50544501'),
	'identifierSchemeCodeUnspsc190501.50544502': __('identifierSchemeCodeUnspsc190501.50544502'),
	'identifierSchemeCodeUnspsc190501.50544503': __('identifierSchemeCodeUnspsc190501.50544503'),
	'identifierSchemeCodeUnspsc190501.50544504': __('identifierSchemeCodeUnspsc190501.50544504'),
	'identifierSchemeCodeUnspsc190501.50544505': __('identifierSchemeCodeUnspsc190501.50544505'),
	'identifierSchemeCodeUnspsc190501.50544506': __('identifierSchemeCodeUnspsc190501.50544506'),
	'identifierSchemeCodeUnspsc190501.50544507': __('identifierSchemeCodeUnspsc190501.50544507'),
	'identifierSchemeCodeUnspsc190501.50544508': __('identifierSchemeCodeUnspsc190501.50544508'),
	'identifierSchemeCodeUnspsc190501.50544509': __('identifierSchemeCodeUnspsc190501.50544509'),
	'identifierSchemeCodeUnspsc190501.50544510': __('identifierSchemeCodeUnspsc190501.50544510'),
	'identifierSchemeCodeUnspsc190501.50544511': __('identifierSchemeCodeUnspsc190501.50544511'),
	'identifierSchemeCodeUnspsc190501.50544512': __('identifierSchemeCodeUnspsc190501.50544512'),
	'identifierSchemeCodeUnspsc190501.50544513': __('identifierSchemeCodeUnspsc190501.50544513'),
	'identifierSchemeCodeUnspsc190501.50544514': __('identifierSchemeCodeUnspsc190501.50544514'),
	'identifierSchemeCodeUnspsc190501.50544515': __('identifierSchemeCodeUnspsc190501.50544515'),
	'identifierSchemeCodeUnspsc190501.50544516': __('identifierSchemeCodeUnspsc190501.50544516'),
	'identifierSchemeCodeUnspsc190501.50544517': __('identifierSchemeCodeUnspsc190501.50544517'),
	'identifierSchemeCodeUnspsc190501.50544518': __('identifierSchemeCodeUnspsc190501.50544518'),
	'identifierSchemeCodeUnspsc190501.50544519': __('identifierSchemeCodeUnspsc190501.50544519'),
	'identifierSchemeCodeUnspsc190501.50544520': __('identifierSchemeCodeUnspsc190501.50544520'),
	'identifierSchemeCodeUnspsc190501.50544521': __('identifierSchemeCodeUnspsc190501.50544521'),
	'identifierSchemeCodeUnspsc190501.50544522': __('identifierSchemeCodeUnspsc190501.50544522'),
	'identifierSchemeCodeUnspsc190501.50544523': __('identifierSchemeCodeUnspsc190501.50544523'),
	'identifierSchemeCodeUnspsc190501.50544524': __('identifierSchemeCodeUnspsc190501.50544524'),
	'identifierSchemeCodeUnspsc190501.50544525': __('identifierSchemeCodeUnspsc190501.50544525'),
	'identifierSchemeCodeUnspsc190501.50544600': __('identifierSchemeCodeUnspsc190501.50544600'),
	'identifierSchemeCodeUnspsc190501.50544601': __('identifierSchemeCodeUnspsc190501.50544601'),
	'identifierSchemeCodeUnspsc190501.50544602': __('identifierSchemeCodeUnspsc190501.50544602'),
	'identifierSchemeCodeUnspsc190501.50544603': __('identifierSchemeCodeUnspsc190501.50544603'),
	'identifierSchemeCodeUnspsc190501.50544604': __('identifierSchemeCodeUnspsc190501.50544604'),
	'identifierSchemeCodeUnspsc190501.50544605': __('identifierSchemeCodeUnspsc190501.50544605'),
	'identifierSchemeCodeUnspsc190501.50544606': __('identifierSchemeCodeUnspsc190501.50544606'),
	'identifierSchemeCodeUnspsc190501.50544607': __('identifierSchemeCodeUnspsc190501.50544607'),
	'identifierSchemeCodeUnspsc190501.50544608': __('identifierSchemeCodeUnspsc190501.50544608'),
	'identifierSchemeCodeUnspsc190501.50544609': __('identifierSchemeCodeUnspsc190501.50544609'),
	'identifierSchemeCodeUnspsc190501.50544610': __('identifierSchemeCodeUnspsc190501.50544610'),
	'identifierSchemeCodeUnspsc190501.50544611': __('identifierSchemeCodeUnspsc190501.50544611'),
	'identifierSchemeCodeUnspsc190501.50544612': __('identifierSchemeCodeUnspsc190501.50544612'),
	'identifierSchemeCodeUnspsc190501.50544613': __('identifierSchemeCodeUnspsc190501.50544613'),
	'identifierSchemeCodeUnspsc190501.50544614': __('identifierSchemeCodeUnspsc190501.50544614'),
	'identifierSchemeCodeUnspsc190501.50544615': __('identifierSchemeCodeUnspsc190501.50544615'),
	'identifierSchemeCodeUnspsc190501.50544616': __('identifierSchemeCodeUnspsc190501.50544616'),
	'identifierSchemeCodeUnspsc190501.50544617': __('identifierSchemeCodeUnspsc190501.50544617'),
	'identifierSchemeCodeUnspsc190501.50544618': __('identifierSchemeCodeUnspsc190501.50544618'),
	'identifierSchemeCodeUnspsc190501.50544619': __('identifierSchemeCodeUnspsc190501.50544619'),
	'identifierSchemeCodeUnspsc190501.50544620': __('identifierSchemeCodeUnspsc190501.50544620'),
	'identifierSchemeCodeUnspsc190501.50544621': __('identifierSchemeCodeUnspsc190501.50544621'),
	'identifierSchemeCodeUnspsc190501.50544622': __('identifierSchemeCodeUnspsc190501.50544622'),
	'identifierSchemeCodeUnspsc190501.50544623': __('identifierSchemeCodeUnspsc190501.50544623'),
	'identifierSchemeCodeUnspsc190501.50544624': __('identifierSchemeCodeUnspsc190501.50544624'),
	'identifierSchemeCodeUnspsc190501.50544625': __('identifierSchemeCodeUnspsc190501.50544625'),
	'identifierSchemeCodeUnspsc190501.50544626': __('identifierSchemeCodeUnspsc190501.50544626'),
	'identifierSchemeCodeUnspsc190501.50544627': __('identifierSchemeCodeUnspsc190501.50544627'),
	'identifierSchemeCodeUnspsc190501.50544628': __('identifierSchemeCodeUnspsc190501.50544628'),
	'identifierSchemeCodeUnspsc190501.50544629': __('identifierSchemeCodeUnspsc190501.50544629'),
	'identifierSchemeCodeUnspsc190501.50544630': __('identifierSchemeCodeUnspsc190501.50544630'),
	'identifierSchemeCodeUnspsc190501.50544631': __('identifierSchemeCodeUnspsc190501.50544631'),
	'identifierSchemeCodeUnspsc190501.50544632': __('identifierSchemeCodeUnspsc190501.50544632'),
	'identifierSchemeCodeUnspsc190501.50544633': __('identifierSchemeCodeUnspsc190501.50544633'),
	'identifierSchemeCodeUnspsc190501.50544634': __('identifierSchemeCodeUnspsc190501.50544634'),
	'identifierSchemeCodeUnspsc190501.50544635': __('identifierSchemeCodeUnspsc190501.50544635'),
	'identifierSchemeCodeUnspsc190501.50544636': __('identifierSchemeCodeUnspsc190501.50544636'),
	'identifierSchemeCodeUnspsc190501.50544637': __('identifierSchemeCodeUnspsc190501.50544637'),
	'identifierSchemeCodeUnspsc190501.50544638': __('identifierSchemeCodeUnspsc190501.50544638'),
	'identifierSchemeCodeUnspsc190501.50544639': __('identifierSchemeCodeUnspsc190501.50544639'),
	'identifierSchemeCodeUnspsc190501.50544640': __('identifierSchemeCodeUnspsc190501.50544640'),
	'identifierSchemeCodeUnspsc190501.50544641': __('identifierSchemeCodeUnspsc190501.50544641'),
	'identifierSchemeCodeUnspsc190501.50544642': __('identifierSchemeCodeUnspsc190501.50544642'),
	'identifierSchemeCodeUnspsc190501.50544643': __('identifierSchemeCodeUnspsc190501.50544643'),
	'identifierSchemeCodeUnspsc190501.50544644': __('identifierSchemeCodeUnspsc190501.50544644'),
	'identifierSchemeCodeUnspsc190501.50544645': __('identifierSchemeCodeUnspsc190501.50544645'),
	'identifierSchemeCodeUnspsc190501.50544646': __('identifierSchemeCodeUnspsc190501.50544646'),
	'identifierSchemeCodeUnspsc190501.50544647': __('identifierSchemeCodeUnspsc190501.50544647'),
	'identifierSchemeCodeUnspsc190501.50544648': __('identifierSchemeCodeUnspsc190501.50544648'),
	'identifierSchemeCodeUnspsc190501.50544649': __('identifierSchemeCodeUnspsc190501.50544649'),
	'identifierSchemeCodeUnspsc190501.50544650': __('identifierSchemeCodeUnspsc190501.50544650'),
	'identifierSchemeCodeUnspsc190501.50544651': __('identifierSchemeCodeUnspsc190501.50544651'),
	'identifierSchemeCodeUnspsc190501.50544652': __('identifierSchemeCodeUnspsc190501.50544652'),
	'identifierSchemeCodeUnspsc190501.50544653': __('identifierSchemeCodeUnspsc190501.50544653'),
	'identifierSchemeCodeUnspsc190501.50544654': __('identifierSchemeCodeUnspsc190501.50544654'),
	'identifierSchemeCodeUnspsc190501.50544655': __('identifierSchemeCodeUnspsc190501.50544655'),
	'identifierSchemeCodeUnspsc190501.50544656': __('identifierSchemeCodeUnspsc190501.50544656'),
	'identifierSchemeCodeUnspsc190501.50544657': __('identifierSchemeCodeUnspsc190501.50544657'),
	'identifierSchemeCodeUnspsc190501.50544658': __('identifierSchemeCodeUnspsc190501.50544658'),
	'identifierSchemeCodeUnspsc190501.50544659': __('identifierSchemeCodeUnspsc190501.50544659'),
	'identifierSchemeCodeUnspsc190501.50544660': __('identifierSchemeCodeUnspsc190501.50544660'),
	'identifierSchemeCodeUnspsc190501.50544661': __('identifierSchemeCodeUnspsc190501.50544661'),
	'identifierSchemeCodeUnspsc190501.50544700': __('identifierSchemeCodeUnspsc190501.50544700'),
	'identifierSchemeCodeUnspsc190501.50544701': __('identifierSchemeCodeUnspsc190501.50544701'),
	'identifierSchemeCodeUnspsc190501.50544702': __('identifierSchemeCodeUnspsc190501.50544702'),
	'identifierSchemeCodeUnspsc190501.50544800': __('identifierSchemeCodeUnspsc190501.50544800'),
	'identifierSchemeCodeUnspsc190501.50544801': __('identifierSchemeCodeUnspsc190501.50544801'),
	'identifierSchemeCodeUnspsc190501.50544802': __('identifierSchemeCodeUnspsc190501.50544802'),
	'identifierSchemeCodeUnspsc190501.50544900': __('identifierSchemeCodeUnspsc190501.50544900'),
	'identifierSchemeCodeUnspsc190501.50544901': __('identifierSchemeCodeUnspsc190501.50544901'),
	'identifierSchemeCodeUnspsc190501.50544902': __('identifierSchemeCodeUnspsc190501.50544902'),
	'identifierSchemeCodeUnspsc190501.50544903': __('identifierSchemeCodeUnspsc190501.50544903'),
	'identifierSchemeCodeUnspsc190501.50544904': __('identifierSchemeCodeUnspsc190501.50544904'),
	'identifierSchemeCodeUnspsc190501.50544905': __('identifierSchemeCodeUnspsc190501.50544905'),
	'identifierSchemeCodeUnspsc190501.50544906': __('identifierSchemeCodeUnspsc190501.50544906'),
	'identifierSchemeCodeUnspsc190501.50544907': __('identifierSchemeCodeUnspsc190501.50544907'),
	'identifierSchemeCodeUnspsc190501.50544908': __('identifierSchemeCodeUnspsc190501.50544908'),
	'identifierSchemeCodeUnspsc190501.50544909': __('identifierSchemeCodeUnspsc190501.50544909'),
	'identifierSchemeCodeUnspsc190501.50544910': __('identifierSchemeCodeUnspsc190501.50544910'),
	'identifierSchemeCodeUnspsc190501.50544911': __('identifierSchemeCodeUnspsc190501.50544911'),
	'identifierSchemeCodeUnspsc190501.50544912': __('identifierSchemeCodeUnspsc190501.50544912'),
	'identifierSchemeCodeUnspsc190501.50544913': __('identifierSchemeCodeUnspsc190501.50544913'),
	'identifierSchemeCodeUnspsc190501.50544914': __('identifierSchemeCodeUnspsc190501.50544914'),
	'identifierSchemeCodeUnspsc190501.50544915': __('identifierSchemeCodeUnspsc190501.50544915'),
	'identifierSchemeCodeUnspsc190501.50544916': __('identifierSchemeCodeUnspsc190501.50544916'),
	'identifierSchemeCodeUnspsc190501.50544917': __('identifierSchemeCodeUnspsc190501.50544917'),
	'identifierSchemeCodeUnspsc190501.50544918': __('identifierSchemeCodeUnspsc190501.50544918'),
	'identifierSchemeCodeUnspsc190501.50544919': __('identifierSchemeCodeUnspsc190501.50544919'),
	'identifierSchemeCodeUnspsc190501.50544920': __('identifierSchemeCodeUnspsc190501.50544920'),
	'identifierSchemeCodeUnspsc190501.50544921': __('identifierSchemeCodeUnspsc190501.50544921'),
	'identifierSchemeCodeUnspsc190501.50544922': __('identifierSchemeCodeUnspsc190501.50544922'),
	'identifierSchemeCodeUnspsc190501.50544923': __('identifierSchemeCodeUnspsc190501.50544923'),
	'identifierSchemeCodeUnspsc190501.50544924': __('identifierSchemeCodeUnspsc190501.50544924'),
	'identifierSchemeCodeUnspsc190501.50544925': __('identifierSchemeCodeUnspsc190501.50544925'),
	'identifierSchemeCodeUnspsc190501.50544926': __('identifierSchemeCodeUnspsc190501.50544926'),
	'identifierSchemeCodeUnspsc190501.50544927': __('identifierSchemeCodeUnspsc190501.50544927'),
	'identifierSchemeCodeUnspsc190501.50544928': __('identifierSchemeCodeUnspsc190501.50544928'),
	'identifierSchemeCodeUnspsc190501.50544929': __('identifierSchemeCodeUnspsc190501.50544929'),
	'identifierSchemeCodeUnspsc190501.50544930': __('identifierSchemeCodeUnspsc190501.50544930'),
	'identifierSchemeCodeUnspsc190501.50544931': __('identifierSchemeCodeUnspsc190501.50544931'),
	'identifierSchemeCodeUnspsc190501.50544932': __('identifierSchemeCodeUnspsc190501.50544932'),
	'identifierSchemeCodeUnspsc190501.50544933': __('identifierSchemeCodeUnspsc190501.50544933'),
	'identifierSchemeCodeUnspsc190501.50544934': __('identifierSchemeCodeUnspsc190501.50544934'),
	'identifierSchemeCodeUnspsc190501.50544935': __('identifierSchemeCodeUnspsc190501.50544935'),
	'identifierSchemeCodeUnspsc190501.50544936': __('identifierSchemeCodeUnspsc190501.50544936'),
	'identifierSchemeCodeUnspsc190501.50544937': __('identifierSchemeCodeUnspsc190501.50544937'),
	'identifierSchemeCodeUnspsc190501.50544938': __('identifierSchemeCodeUnspsc190501.50544938'),
	'identifierSchemeCodeUnspsc190501.50544939': __('identifierSchemeCodeUnspsc190501.50544939'),
	'identifierSchemeCodeUnspsc190501.50544940': __('identifierSchemeCodeUnspsc190501.50544940'),
	'identifierSchemeCodeUnspsc190501.50544941': __('identifierSchemeCodeUnspsc190501.50544941'),
	'identifierSchemeCodeUnspsc190501.50544942': __('identifierSchemeCodeUnspsc190501.50544942'),
	'identifierSchemeCodeUnspsc190501.50544943': __('identifierSchemeCodeUnspsc190501.50544943'),
	'identifierSchemeCodeUnspsc190501.50544944': __('identifierSchemeCodeUnspsc190501.50544944'),
	'identifierSchemeCodeUnspsc190501.50544945': __('identifierSchemeCodeUnspsc190501.50544945'),
	'identifierSchemeCodeUnspsc190501.50544946': __('identifierSchemeCodeUnspsc190501.50544946'),
	'identifierSchemeCodeUnspsc190501.50544947': __('identifierSchemeCodeUnspsc190501.50544947'),
	'identifierSchemeCodeUnspsc190501.50544948': __('identifierSchemeCodeUnspsc190501.50544948'),
	'identifierSchemeCodeUnspsc190501.50544949': __('identifierSchemeCodeUnspsc190501.50544949'),
	'identifierSchemeCodeUnspsc190501.50544950': __('identifierSchemeCodeUnspsc190501.50544950'),
	'identifierSchemeCodeUnspsc190501.50544951': __('identifierSchemeCodeUnspsc190501.50544951'),
	'identifierSchemeCodeUnspsc190501.50544952': __('identifierSchemeCodeUnspsc190501.50544952'),
	'identifierSchemeCodeUnspsc190501.50544953': __('identifierSchemeCodeUnspsc190501.50544953'),
	'identifierSchemeCodeUnspsc190501.50544954': __('identifierSchemeCodeUnspsc190501.50544954'),
	'identifierSchemeCodeUnspsc190501.50544955': __('identifierSchemeCodeUnspsc190501.50544955'),
	'identifierSchemeCodeUnspsc190501.50544956': __('identifierSchemeCodeUnspsc190501.50544956'),
	'identifierSchemeCodeUnspsc190501.50544957': __('identifierSchemeCodeUnspsc190501.50544957'),
	'identifierSchemeCodeUnspsc190501.50545000': __('identifierSchemeCodeUnspsc190501.50545000'),
	'identifierSchemeCodeUnspsc190501.50545001': __('identifierSchemeCodeUnspsc190501.50545001'),
	'identifierSchemeCodeUnspsc190501.50545002': __('identifierSchemeCodeUnspsc190501.50545002'),
	'identifierSchemeCodeUnspsc190501.50545003': __('identifierSchemeCodeUnspsc190501.50545003'),
	'identifierSchemeCodeUnspsc190501.50545004': __('identifierSchemeCodeUnspsc190501.50545004'),
	'identifierSchemeCodeUnspsc190501.50545005': __('identifierSchemeCodeUnspsc190501.50545005'),
	'identifierSchemeCodeUnspsc190501.50545006': __('identifierSchemeCodeUnspsc190501.50545006'),
	'identifierSchemeCodeUnspsc190501.50545007': __('identifierSchemeCodeUnspsc190501.50545007'),
	'identifierSchemeCodeUnspsc190501.50545008': __('identifierSchemeCodeUnspsc190501.50545008'),
	'identifierSchemeCodeUnspsc190501.50545009': __('identifierSchemeCodeUnspsc190501.50545009'),
	'identifierSchemeCodeUnspsc190501.50545010': __('identifierSchemeCodeUnspsc190501.50545010'),
	'identifierSchemeCodeUnspsc190501.50545011': __('identifierSchemeCodeUnspsc190501.50545011'),
	'identifierSchemeCodeUnspsc190501.50545012': __('identifierSchemeCodeUnspsc190501.50545012'),
	'identifierSchemeCodeUnspsc190501.50545013': __('identifierSchemeCodeUnspsc190501.50545013'),
	'identifierSchemeCodeUnspsc190501.50545014': __('identifierSchemeCodeUnspsc190501.50545014'),
	'identifierSchemeCodeUnspsc190501.50545015': __('identifierSchemeCodeUnspsc190501.50545015'),
	'identifierSchemeCodeUnspsc190501.50545016': __('identifierSchemeCodeUnspsc190501.50545016'),
	'identifierSchemeCodeUnspsc190501.50545017': __('identifierSchemeCodeUnspsc190501.50545017'),
	'identifierSchemeCodeUnspsc190501.50545018': __('identifierSchemeCodeUnspsc190501.50545018'),
	'identifierSchemeCodeUnspsc190501.50545019': __('identifierSchemeCodeUnspsc190501.50545019'),
	'identifierSchemeCodeUnspsc190501.50545020': __('identifierSchemeCodeUnspsc190501.50545020'),
	'identifierSchemeCodeUnspsc190501.50545021': __('identifierSchemeCodeUnspsc190501.50545021'),
	'identifierSchemeCodeUnspsc190501.50545022': __('identifierSchemeCodeUnspsc190501.50545022'),
	'identifierSchemeCodeUnspsc190501.50545023': __('identifierSchemeCodeUnspsc190501.50545023'),
	'identifierSchemeCodeUnspsc190501.50545024': __('identifierSchemeCodeUnspsc190501.50545024'),
	'identifierSchemeCodeUnspsc190501.50545025': __('identifierSchemeCodeUnspsc190501.50545025'),
	'identifierSchemeCodeUnspsc190501.50545026': __('identifierSchemeCodeUnspsc190501.50545026'),
	'identifierSchemeCodeUnspsc190501.50545027': __('identifierSchemeCodeUnspsc190501.50545027'),
	'identifierSchemeCodeUnspsc190501.50545028': __('identifierSchemeCodeUnspsc190501.50545028'),
	'identifierSchemeCodeUnspsc190501.50545029': __('identifierSchemeCodeUnspsc190501.50545029'),
	'identifierSchemeCodeUnspsc190501.50545030': __('identifierSchemeCodeUnspsc190501.50545030'),
	'identifierSchemeCodeUnspsc190501.50545031': __('identifierSchemeCodeUnspsc190501.50545031'),
	'identifierSchemeCodeUnspsc190501.50545032': __('identifierSchemeCodeUnspsc190501.50545032'),
	'identifierSchemeCodeUnspsc190501.50545033': __('identifierSchemeCodeUnspsc190501.50545033'),
	'identifierSchemeCodeUnspsc190501.50545034': __('identifierSchemeCodeUnspsc190501.50545034'),
	'identifierSchemeCodeUnspsc190501.50545035': __('identifierSchemeCodeUnspsc190501.50545035'),
	'identifierSchemeCodeUnspsc190501.50545036': __('identifierSchemeCodeUnspsc190501.50545036'),
	'identifierSchemeCodeUnspsc190501.50545037': __('identifierSchemeCodeUnspsc190501.50545037'),
	'identifierSchemeCodeUnspsc190501.50545038': __('identifierSchemeCodeUnspsc190501.50545038'),
	'identifierSchemeCodeUnspsc190501.50545039': __('identifierSchemeCodeUnspsc190501.50545039'),
	'identifierSchemeCodeUnspsc190501.50545040': __('identifierSchemeCodeUnspsc190501.50545040'),
	'identifierSchemeCodeUnspsc190501.50545041': __('identifierSchemeCodeUnspsc190501.50545041'),
	'identifierSchemeCodeUnspsc190501.50545042': __('identifierSchemeCodeUnspsc190501.50545042'),
	'identifierSchemeCodeUnspsc190501.50545043': __('identifierSchemeCodeUnspsc190501.50545043'),
	'identifierSchemeCodeUnspsc190501.50545044': __('identifierSchemeCodeUnspsc190501.50545044'),
	'identifierSchemeCodeUnspsc190501.50545100': __('identifierSchemeCodeUnspsc190501.50545100'),
	'identifierSchemeCodeUnspsc190501.50545101': __('identifierSchemeCodeUnspsc190501.50545101'),
	'identifierSchemeCodeUnspsc190501.50545102': __('identifierSchemeCodeUnspsc190501.50545102'),
	'identifierSchemeCodeUnspsc190501.50545103': __('identifierSchemeCodeUnspsc190501.50545103'),
	'identifierSchemeCodeUnspsc190501.50545104': __('identifierSchemeCodeUnspsc190501.50545104'),
	'identifierSchemeCodeUnspsc190501.50545105': __('identifierSchemeCodeUnspsc190501.50545105'),
	'identifierSchemeCodeUnspsc190501.50545106': __('identifierSchemeCodeUnspsc190501.50545106'),
	'identifierSchemeCodeUnspsc190501.50545200': __('identifierSchemeCodeUnspsc190501.50545200'),
	'identifierSchemeCodeUnspsc190501.50545201': __('identifierSchemeCodeUnspsc190501.50545201'),
	'identifierSchemeCodeUnspsc190501.50545202': __('identifierSchemeCodeUnspsc190501.50545202'),
	'identifierSchemeCodeUnspsc190501.50545203': __('identifierSchemeCodeUnspsc190501.50545203'),
	'identifierSchemeCodeUnspsc190501.50545204': __('identifierSchemeCodeUnspsc190501.50545204'),
	'identifierSchemeCodeUnspsc190501.50545205': __('identifierSchemeCodeUnspsc190501.50545205'),
	'identifierSchemeCodeUnspsc190501.50545206': __('identifierSchemeCodeUnspsc190501.50545206'),
	'identifierSchemeCodeUnspsc190501.50545207': __('identifierSchemeCodeUnspsc190501.50545207'),
	'identifierSchemeCodeUnspsc190501.50545208': __('identifierSchemeCodeUnspsc190501.50545208'),
	'identifierSchemeCodeUnspsc190501.50545209': __('identifierSchemeCodeUnspsc190501.50545209'),
	'identifierSchemeCodeUnspsc190501.50545210': __('identifierSchemeCodeUnspsc190501.50545210'),
	'identifierSchemeCodeUnspsc190501.50545300': __('identifierSchemeCodeUnspsc190501.50545300'),
	'identifierSchemeCodeUnspsc190501.50545301': __('identifierSchemeCodeUnspsc190501.50545301'),
	'identifierSchemeCodeUnspsc190501.50545302': __('identifierSchemeCodeUnspsc190501.50545302'),
	'identifierSchemeCodeUnspsc190501.50545303': __('identifierSchemeCodeUnspsc190501.50545303'),
	'identifierSchemeCodeUnspsc190501.50545304': __('identifierSchemeCodeUnspsc190501.50545304'),
	'identifierSchemeCodeUnspsc190501.50545305': __('identifierSchemeCodeUnspsc190501.50545305'),
	'identifierSchemeCodeUnspsc190501.50545306': __('identifierSchemeCodeUnspsc190501.50545306'),
	'identifierSchemeCodeUnspsc190501.50545307': __('identifierSchemeCodeUnspsc190501.50545307'),
	'identifierSchemeCodeUnspsc190501.50545308': __('identifierSchemeCodeUnspsc190501.50545308'),
	'identifierSchemeCodeUnspsc190501.50545309': __('identifierSchemeCodeUnspsc190501.50545309'),
	'identifierSchemeCodeUnspsc190501.50545310': __('identifierSchemeCodeUnspsc190501.50545310'),
	'identifierSchemeCodeUnspsc190501.50545311': __('identifierSchemeCodeUnspsc190501.50545311'),
	'identifierSchemeCodeUnspsc190501.50545312': __('identifierSchemeCodeUnspsc190501.50545312'),
	'identifierSchemeCodeUnspsc190501.50545313': __('identifierSchemeCodeUnspsc190501.50545313'),
	'identifierSchemeCodeUnspsc190501.50545314': __('identifierSchemeCodeUnspsc190501.50545314'),
	'identifierSchemeCodeUnspsc190501.50545315': __('identifierSchemeCodeUnspsc190501.50545315'),
	'identifierSchemeCodeUnspsc190501.50545316': __('identifierSchemeCodeUnspsc190501.50545316'),
	'identifierSchemeCodeUnspsc190501.50545317': __('identifierSchemeCodeUnspsc190501.50545317'),
	'identifierSchemeCodeUnspsc190501.50545318': __('identifierSchemeCodeUnspsc190501.50545318'),
	'identifierSchemeCodeUnspsc190501.50545319': __('identifierSchemeCodeUnspsc190501.50545319'),
	'identifierSchemeCodeUnspsc190501.50545320': __('identifierSchemeCodeUnspsc190501.50545320'),
	'identifierSchemeCodeUnspsc190501.50545321': __('identifierSchemeCodeUnspsc190501.50545321'),
	'identifierSchemeCodeUnspsc190501.50545322': __('identifierSchemeCodeUnspsc190501.50545322'),
	'identifierSchemeCodeUnspsc190501.50545323': __('identifierSchemeCodeUnspsc190501.50545323'),
	'identifierSchemeCodeUnspsc190501.50545324': __('identifierSchemeCodeUnspsc190501.50545324'),
	'identifierSchemeCodeUnspsc190501.50545325': __('identifierSchemeCodeUnspsc190501.50545325'),
	'identifierSchemeCodeUnspsc190501.50545326': __('identifierSchemeCodeUnspsc190501.50545326'),
	'identifierSchemeCodeUnspsc190501.50545327': __('identifierSchemeCodeUnspsc190501.50545327'),
	'identifierSchemeCodeUnspsc190501.50545328': __('identifierSchemeCodeUnspsc190501.50545328'),
	'identifierSchemeCodeUnspsc190501.50545329': __('identifierSchemeCodeUnspsc190501.50545329'),
	'identifierSchemeCodeUnspsc190501.50545330': __('identifierSchemeCodeUnspsc190501.50545330'),
	'identifierSchemeCodeUnspsc190501.50545331': __('identifierSchemeCodeUnspsc190501.50545331'),
	'identifierSchemeCodeUnspsc190501.50545332': __('identifierSchemeCodeUnspsc190501.50545332'),
	'identifierSchemeCodeUnspsc190501.50545333': __('identifierSchemeCodeUnspsc190501.50545333'),
	'identifierSchemeCodeUnspsc190501.50545334': __('identifierSchemeCodeUnspsc190501.50545334'),
	'identifierSchemeCodeUnspsc190501.50545335': __('identifierSchemeCodeUnspsc190501.50545335'),
	'identifierSchemeCodeUnspsc190501.50545336': __('identifierSchemeCodeUnspsc190501.50545336'),
	'identifierSchemeCodeUnspsc190501.50545337': __('identifierSchemeCodeUnspsc190501.50545337'),
	'identifierSchemeCodeUnspsc190501.50545338': __('identifierSchemeCodeUnspsc190501.50545338'),
	'identifierSchemeCodeUnspsc190501.50545339': __('identifierSchemeCodeUnspsc190501.50545339'),
	'identifierSchemeCodeUnspsc190501.50545340': __('identifierSchemeCodeUnspsc190501.50545340'),
	'identifierSchemeCodeUnspsc190501.50545341': __('identifierSchemeCodeUnspsc190501.50545341'),
	'identifierSchemeCodeUnspsc190501.50545342': __('identifierSchemeCodeUnspsc190501.50545342'),
	'identifierSchemeCodeUnspsc190501.50545343': __('identifierSchemeCodeUnspsc190501.50545343'),
	'identifierSchemeCodeUnspsc190501.50545344': __('identifierSchemeCodeUnspsc190501.50545344'),
	'identifierSchemeCodeUnspsc190501.50545345': __('identifierSchemeCodeUnspsc190501.50545345'),
	'identifierSchemeCodeUnspsc190501.50545346': __('identifierSchemeCodeUnspsc190501.50545346'),
	'identifierSchemeCodeUnspsc190501.50545347': __('identifierSchemeCodeUnspsc190501.50545347'),
	'identifierSchemeCodeUnspsc190501.50545348': __('identifierSchemeCodeUnspsc190501.50545348'),
	'identifierSchemeCodeUnspsc190501.50545349': __('identifierSchemeCodeUnspsc190501.50545349'),
	'identifierSchemeCodeUnspsc190501.50545350': __('identifierSchemeCodeUnspsc190501.50545350'),
	'identifierSchemeCodeUnspsc190501.50545351': __('identifierSchemeCodeUnspsc190501.50545351'),
	'identifierSchemeCodeUnspsc190501.50545352': __('identifierSchemeCodeUnspsc190501.50545352'),
	'identifierSchemeCodeUnspsc190501.50545353': __('identifierSchemeCodeUnspsc190501.50545353'),
	'identifierSchemeCodeUnspsc190501.50545354': __('identifierSchemeCodeUnspsc190501.50545354'),
	'identifierSchemeCodeUnspsc190501.50545355': __('identifierSchemeCodeUnspsc190501.50545355'),
	'identifierSchemeCodeUnspsc190501.50545356': __('identifierSchemeCodeUnspsc190501.50545356'),
	'identifierSchemeCodeUnspsc190501.50545357': __('identifierSchemeCodeUnspsc190501.50545357'),
	'identifierSchemeCodeUnspsc190501.50545358': __('identifierSchemeCodeUnspsc190501.50545358'),
	'identifierSchemeCodeUnspsc190501.50545359': __('identifierSchemeCodeUnspsc190501.50545359'),
	'identifierSchemeCodeUnspsc190501.50545360': __('identifierSchemeCodeUnspsc190501.50545360'),
	'identifierSchemeCodeUnspsc190501.50545361': __('identifierSchemeCodeUnspsc190501.50545361'),
	'identifierSchemeCodeUnspsc190501.50545362': __('identifierSchemeCodeUnspsc190501.50545362'),
	'identifierSchemeCodeUnspsc190501.50545363': __('identifierSchemeCodeUnspsc190501.50545363'),
	'identifierSchemeCodeUnspsc190501.50545364': __('identifierSchemeCodeUnspsc190501.50545364'),
	'identifierSchemeCodeUnspsc190501.50545365': __('identifierSchemeCodeUnspsc190501.50545365'),
	'identifierSchemeCodeUnspsc190501.50545366': __('identifierSchemeCodeUnspsc190501.50545366'),
	'identifierSchemeCodeUnspsc190501.50545367': __('identifierSchemeCodeUnspsc190501.50545367'),
	'identifierSchemeCodeUnspsc190501.50545368': __('identifierSchemeCodeUnspsc190501.50545368'),
	'identifierSchemeCodeUnspsc190501.50545369': __('identifierSchemeCodeUnspsc190501.50545369'),
	'identifierSchemeCodeUnspsc190501.50545370': __('identifierSchemeCodeUnspsc190501.50545370'),
	'identifierSchemeCodeUnspsc190501.50545371': __('identifierSchemeCodeUnspsc190501.50545371'),
	'identifierSchemeCodeUnspsc190501.50545400': __('identifierSchemeCodeUnspsc190501.50545400'),
	'identifierSchemeCodeUnspsc190501.50545401': __('identifierSchemeCodeUnspsc190501.50545401'),
	'identifierSchemeCodeUnspsc190501.50545402': __('identifierSchemeCodeUnspsc190501.50545402'),
	'identifierSchemeCodeUnspsc190501.50545403': __('identifierSchemeCodeUnspsc190501.50545403'),
	'identifierSchemeCodeUnspsc190501.50545404': __('identifierSchemeCodeUnspsc190501.50545404'),
	'identifierSchemeCodeUnspsc190501.50545405': __('identifierSchemeCodeUnspsc190501.50545405'),
	'identifierSchemeCodeUnspsc190501.50545406': __('identifierSchemeCodeUnspsc190501.50545406'),
	'identifierSchemeCodeUnspsc190501.50545407': __('identifierSchemeCodeUnspsc190501.50545407'),
	'identifierSchemeCodeUnspsc190501.50545408': __('identifierSchemeCodeUnspsc190501.50545408'),
	'identifierSchemeCodeUnspsc190501.50545409': __('identifierSchemeCodeUnspsc190501.50545409'),
	'identifierSchemeCodeUnspsc190501.50545410': __('identifierSchemeCodeUnspsc190501.50545410'),
	'identifierSchemeCodeUnspsc190501.50545411': __('identifierSchemeCodeUnspsc190501.50545411'),
	'identifierSchemeCodeUnspsc190501.50545412': __('identifierSchemeCodeUnspsc190501.50545412'),
	'identifierSchemeCodeUnspsc190501.50545413': __('identifierSchemeCodeUnspsc190501.50545413'),
	'identifierSchemeCodeUnspsc190501.50545414': __('identifierSchemeCodeUnspsc190501.50545414'),
	'identifierSchemeCodeUnspsc190501.50545415': __('identifierSchemeCodeUnspsc190501.50545415'),
	'identifierSchemeCodeUnspsc190501.50545416': __('identifierSchemeCodeUnspsc190501.50545416'),
	'identifierSchemeCodeUnspsc190501.50545417': __('identifierSchemeCodeUnspsc190501.50545417'),
	'identifierSchemeCodeUnspsc190501.50545418': __('identifierSchemeCodeUnspsc190501.50545418'),
	'identifierSchemeCodeUnspsc190501.50545419': __('identifierSchemeCodeUnspsc190501.50545419'),
	'identifierSchemeCodeUnspsc190501.50545420': __('identifierSchemeCodeUnspsc190501.50545420'),
	'identifierSchemeCodeUnspsc190501.50545421': __('identifierSchemeCodeUnspsc190501.50545421'),
	'identifierSchemeCodeUnspsc190501.50545422': __('identifierSchemeCodeUnspsc190501.50545422'),
	'identifierSchemeCodeUnspsc190501.50545423': __('identifierSchemeCodeUnspsc190501.50545423'),
	'identifierSchemeCodeUnspsc190501.50545424': __('identifierSchemeCodeUnspsc190501.50545424'),
	'identifierSchemeCodeUnspsc190501.50545425': __('identifierSchemeCodeUnspsc190501.50545425'),
	'identifierSchemeCodeUnspsc190501.50545426': __('identifierSchemeCodeUnspsc190501.50545426'),
	'identifierSchemeCodeUnspsc190501.50545427': __('identifierSchemeCodeUnspsc190501.50545427'),
	'identifierSchemeCodeUnspsc190501.50545428': __('identifierSchemeCodeUnspsc190501.50545428'),
	'identifierSchemeCodeUnspsc190501.50545429': __('identifierSchemeCodeUnspsc190501.50545429'),
	'identifierSchemeCodeUnspsc190501.50545430': __('identifierSchemeCodeUnspsc190501.50545430'),
	'identifierSchemeCodeUnspsc190501.50545431': __('identifierSchemeCodeUnspsc190501.50545431'),
	'identifierSchemeCodeUnspsc190501.50545432': __('identifierSchemeCodeUnspsc190501.50545432'),
	'identifierSchemeCodeUnspsc190501.50545433': __('identifierSchemeCodeUnspsc190501.50545433'),
	'identifierSchemeCodeUnspsc190501.50545434': __('identifierSchemeCodeUnspsc190501.50545434'),
	'identifierSchemeCodeUnspsc190501.50545435': __('identifierSchemeCodeUnspsc190501.50545435'),
	'identifierSchemeCodeUnspsc190501.50545436': __('identifierSchemeCodeUnspsc190501.50545436'),
	'identifierSchemeCodeUnspsc190501.50545437': __('identifierSchemeCodeUnspsc190501.50545437'),
	'identifierSchemeCodeUnspsc190501.50545438': __('identifierSchemeCodeUnspsc190501.50545438'),
	'identifierSchemeCodeUnspsc190501.50545439': __('identifierSchemeCodeUnspsc190501.50545439'),
	'identifierSchemeCodeUnspsc190501.50545440': __('identifierSchemeCodeUnspsc190501.50545440'),
	'identifierSchemeCodeUnspsc190501.50545441': __('identifierSchemeCodeUnspsc190501.50545441'),
	'identifierSchemeCodeUnspsc190501.50545442': __('identifierSchemeCodeUnspsc190501.50545442'),
	'identifierSchemeCodeUnspsc190501.50545443': __('identifierSchemeCodeUnspsc190501.50545443'),
	'identifierSchemeCodeUnspsc190501.50545444': __('identifierSchemeCodeUnspsc190501.50545444'),
	'identifierSchemeCodeUnspsc190501.50545445': __('identifierSchemeCodeUnspsc190501.50545445'),
	'identifierSchemeCodeUnspsc190501.50545446': __('identifierSchemeCodeUnspsc190501.50545446'),
	'identifierSchemeCodeUnspsc190501.50545447': __('identifierSchemeCodeUnspsc190501.50545447'),
	'identifierSchemeCodeUnspsc190501.50545448': __('identifierSchemeCodeUnspsc190501.50545448'),
	'identifierSchemeCodeUnspsc190501.50545449': __('identifierSchemeCodeUnspsc190501.50545449'),
	'identifierSchemeCodeUnspsc190501.50545450': __('identifierSchemeCodeUnspsc190501.50545450'),
	'identifierSchemeCodeUnspsc190501.50545451': __('identifierSchemeCodeUnspsc190501.50545451'),
	'identifierSchemeCodeUnspsc190501.50545452': __('identifierSchemeCodeUnspsc190501.50545452'),
	'identifierSchemeCodeUnspsc190501.50545453': __('identifierSchemeCodeUnspsc190501.50545453'),
	'identifierSchemeCodeUnspsc190501.50545454': __('identifierSchemeCodeUnspsc190501.50545454'),
	'identifierSchemeCodeUnspsc190501.50545455': __('identifierSchemeCodeUnspsc190501.50545455'),
	'identifierSchemeCodeUnspsc190501.50545456': __('identifierSchemeCodeUnspsc190501.50545456'),
	'identifierSchemeCodeUnspsc190501.50545457': __('identifierSchemeCodeUnspsc190501.50545457'),
	'identifierSchemeCodeUnspsc190501.50545458': __('identifierSchemeCodeUnspsc190501.50545458'),
	'identifierSchemeCodeUnspsc190501.50545459': __('identifierSchemeCodeUnspsc190501.50545459'),
	'identifierSchemeCodeUnspsc190501.50545460': __('identifierSchemeCodeUnspsc190501.50545460'),
	'identifierSchemeCodeUnspsc190501.50545500': __('identifierSchemeCodeUnspsc190501.50545500'),
	'identifierSchemeCodeUnspsc190501.50545501': __('identifierSchemeCodeUnspsc190501.50545501'),
	'identifierSchemeCodeUnspsc190501.50545502': __('identifierSchemeCodeUnspsc190501.50545502'),
	'identifierSchemeCodeUnspsc190501.50545503': __('identifierSchemeCodeUnspsc190501.50545503'),
	'identifierSchemeCodeUnspsc190501.50545504': __('identifierSchemeCodeUnspsc190501.50545504'),
	'identifierSchemeCodeUnspsc190501.50545505': __('identifierSchemeCodeUnspsc190501.50545505'),
	'identifierSchemeCodeUnspsc190501.50545506': __('identifierSchemeCodeUnspsc190501.50545506'),
	'identifierSchemeCodeUnspsc190501.50545507': __('identifierSchemeCodeUnspsc190501.50545507'),
	'identifierSchemeCodeUnspsc190501.50545508': __('identifierSchemeCodeUnspsc190501.50545508'),
	'identifierSchemeCodeUnspsc190501.50545509': __('identifierSchemeCodeUnspsc190501.50545509'),
	'identifierSchemeCodeUnspsc190501.50545510': __('identifierSchemeCodeUnspsc190501.50545510'),
	'identifierSchemeCodeUnspsc190501.50545511': __('identifierSchemeCodeUnspsc190501.50545511'),
	'identifierSchemeCodeUnspsc190501.50545512': __('identifierSchemeCodeUnspsc190501.50545512'),
	'identifierSchemeCodeUnspsc190501.50545600': __('identifierSchemeCodeUnspsc190501.50545600'),
	'identifierSchemeCodeUnspsc190501.50545601': __('identifierSchemeCodeUnspsc190501.50545601'),
	'identifierSchemeCodeUnspsc190501.50545602': __('identifierSchemeCodeUnspsc190501.50545602'),
	'identifierSchemeCodeUnspsc190501.50545603': __('identifierSchemeCodeUnspsc190501.50545603'),
	'identifierSchemeCodeUnspsc190501.50545604': __('identifierSchemeCodeUnspsc190501.50545604'),
	'identifierSchemeCodeUnspsc190501.50545605': __('identifierSchemeCodeUnspsc190501.50545605'),
	'identifierSchemeCodeUnspsc190501.50545606': __('identifierSchemeCodeUnspsc190501.50545606'),
	'identifierSchemeCodeUnspsc190501.50545607': __('identifierSchemeCodeUnspsc190501.50545607'),
	'identifierSchemeCodeUnspsc190501.50545608': __('identifierSchemeCodeUnspsc190501.50545608'),
	'identifierSchemeCodeUnspsc190501.50545609': __('identifierSchemeCodeUnspsc190501.50545609'),
	'identifierSchemeCodeUnspsc190501.50545610': __('identifierSchemeCodeUnspsc190501.50545610'),
	'identifierSchemeCodeUnspsc190501.50545700': __('identifierSchemeCodeUnspsc190501.50545700'),
	'identifierSchemeCodeUnspsc190501.50545701': __('identifierSchemeCodeUnspsc190501.50545701'),
	'identifierSchemeCodeUnspsc190501.50545702': __('identifierSchemeCodeUnspsc190501.50545702'),
	'identifierSchemeCodeUnspsc190501.50545703': __('identifierSchemeCodeUnspsc190501.50545703'),
	'identifierSchemeCodeUnspsc190501.50545704': __('identifierSchemeCodeUnspsc190501.50545704'),
	'identifierSchemeCodeUnspsc190501.50545705': __('identifierSchemeCodeUnspsc190501.50545705'),
	'identifierSchemeCodeUnspsc190501.50545706': __('identifierSchemeCodeUnspsc190501.50545706'),
	'identifierSchemeCodeUnspsc190501.50545707': __('identifierSchemeCodeUnspsc190501.50545707'),
	'identifierSchemeCodeUnspsc190501.50545708': __('identifierSchemeCodeUnspsc190501.50545708'),
	'identifierSchemeCodeUnspsc190501.50545709': __('identifierSchemeCodeUnspsc190501.50545709'),
	'identifierSchemeCodeUnspsc190501.50545710': __('identifierSchemeCodeUnspsc190501.50545710'),
	'identifierSchemeCodeUnspsc190501.50545711': __('identifierSchemeCodeUnspsc190501.50545711'),
	'identifierSchemeCodeUnspsc190501.50545712': __('identifierSchemeCodeUnspsc190501.50545712'),
	'identifierSchemeCodeUnspsc190501.50545713': __('identifierSchemeCodeUnspsc190501.50545713'),
	'identifierSchemeCodeUnspsc190501.50545714': __('identifierSchemeCodeUnspsc190501.50545714'),
	'identifierSchemeCodeUnspsc190501.50545715': __('identifierSchemeCodeUnspsc190501.50545715'),
	'identifierSchemeCodeUnspsc190501.50545716': __('identifierSchemeCodeUnspsc190501.50545716'),
	'identifierSchemeCodeUnspsc190501.50545717': __('identifierSchemeCodeUnspsc190501.50545717'),
	'identifierSchemeCodeUnspsc190501.50545718': __('identifierSchemeCodeUnspsc190501.50545718'),
	'identifierSchemeCodeUnspsc190501.50545719': __('identifierSchemeCodeUnspsc190501.50545719'),
	'identifierSchemeCodeUnspsc190501.50545720': __('identifierSchemeCodeUnspsc190501.50545720'),
	'identifierSchemeCodeUnspsc190501.50545721': __('identifierSchemeCodeUnspsc190501.50545721'),
	'identifierSchemeCodeUnspsc190501.50545722': __('identifierSchemeCodeUnspsc190501.50545722'),
	'identifierSchemeCodeUnspsc190501.50545723': __('identifierSchemeCodeUnspsc190501.50545723'),
	'identifierSchemeCodeUnspsc190501.50545800': __('identifierSchemeCodeUnspsc190501.50545800'),
	'identifierSchemeCodeUnspsc190501.50545801': __('identifierSchemeCodeUnspsc190501.50545801'),
	'identifierSchemeCodeUnspsc190501.50545802': __('identifierSchemeCodeUnspsc190501.50545802'),
	'identifierSchemeCodeUnspsc190501.50545803': __('identifierSchemeCodeUnspsc190501.50545803'),
	'identifierSchemeCodeUnspsc190501.50545804': __('identifierSchemeCodeUnspsc190501.50545804'),
	'identifierSchemeCodeUnspsc190501.50545805': __('identifierSchemeCodeUnspsc190501.50545805'),
	'identifierSchemeCodeUnspsc190501.50545806': __('identifierSchemeCodeUnspsc190501.50545806'),
	'identifierSchemeCodeUnspsc190501.50545807': __('identifierSchemeCodeUnspsc190501.50545807'),
	'identifierSchemeCodeUnspsc190501.50545808': __('identifierSchemeCodeUnspsc190501.50545808'),
	'identifierSchemeCodeUnspsc190501.50545809': __('identifierSchemeCodeUnspsc190501.50545809'),
	'identifierSchemeCodeUnspsc190501.50545810': __('identifierSchemeCodeUnspsc190501.50545810'),
	'identifierSchemeCodeUnspsc190501.50545811': __('identifierSchemeCodeUnspsc190501.50545811'),
	'identifierSchemeCodeUnspsc190501.50545812': __('identifierSchemeCodeUnspsc190501.50545812'),
	'identifierSchemeCodeUnspsc190501.50545813': __('identifierSchemeCodeUnspsc190501.50545813'),
	'identifierSchemeCodeUnspsc190501.50545814': __('identifierSchemeCodeUnspsc190501.50545814'),
	'identifierSchemeCodeUnspsc190501.50545815': __('identifierSchemeCodeUnspsc190501.50545815'),
	'identifierSchemeCodeUnspsc190501.50545816': __('identifierSchemeCodeUnspsc190501.50545816'),
	'identifierSchemeCodeUnspsc190501.50545817': __('identifierSchemeCodeUnspsc190501.50545817'),
	'identifierSchemeCodeUnspsc190501.50545818': __('identifierSchemeCodeUnspsc190501.50545818'),
	'identifierSchemeCodeUnspsc190501.50545819': __('identifierSchemeCodeUnspsc190501.50545819'),
	'identifierSchemeCodeUnspsc190501.50545820': __('identifierSchemeCodeUnspsc190501.50545820'),
	'identifierSchemeCodeUnspsc190501.50545821': __('identifierSchemeCodeUnspsc190501.50545821'),
	'identifierSchemeCodeUnspsc190501.50545822': __('identifierSchemeCodeUnspsc190501.50545822'),
	'identifierSchemeCodeUnspsc190501.50545823': __('identifierSchemeCodeUnspsc190501.50545823'),
	'identifierSchemeCodeUnspsc190501.50545824': __('identifierSchemeCodeUnspsc190501.50545824'),
	'identifierSchemeCodeUnspsc190501.50545825': __('identifierSchemeCodeUnspsc190501.50545825'),
	'identifierSchemeCodeUnspsc190501.50545826': __('identifierSchemeCodeUnspsc190501.50545826'),
	'identifierSchemeCodeUnspsc190501.50545827': __('identifierSchemeCodeUnspsc190501.50545827'),
	'identifierSchemeCodeUnspsc190501.50545828': __('identifierSchemeCodeUnspsc190501.50545828'),
	'identifierSchemeCodeUnspsc190501.50545829': __('identifierSchemeCodeUnspsc190501.50545829'),
	'identifierSchemeCodeUnspsc190501.50545830': __('identifierSchemeCodeUnspsc190501.50545830'),
	'identifierSchemeCodeUnspsc190501.50545831': __('identifierSchemeCodeUnspsc190501.50545831'),
	'identifierSchemeCodeUnspsc190501.50545832': __('identifierSchemeCodeUnspsc190501.50545832'),
	'identifierSchemeCodeUnspsc190501.50545833': __('identifierSchemeCodeUnspsc190501.50545833'),
	'identifierSchemeCodeUnspsc190501.50545834': __('identifierSchemeCodeUnspsc190501.50545834'),
	'identifierSchemeCodeUnspsc190501.50545835': __('identifierSchemeCodeUnspsc190501.50545835'),
	'identifierSchemeCodeUnspsc190501.50545836': __('identifierSchemeCodeUnspsc190501.50545836'),
	'identifierSchemeCodeUnspsc190501.50545837': __('identifierSchemeCodeUnspsc190501.50545837'),
	'identifierSchemeCodeUnspsc190501.50545838': __('identifierSchemeCodeUnspsc190501.50545838'),
	'identifierSchemeCodeUnspsc190501.50545839': __('identifierSchemeCodeUnspsc190501.50545839'),
	'identifierSchemeCodeUnspsc190501.50545840': __('identifierSchemeCodeUnspsc190501.50545840'),
	'identifierSchemeCodeUnspsc190501.50545841': __('identifierSchemeCodeUnspsc190501.50545841'),
	'identifierSchemeCodeUnspsc190501.50545842': __('identifierSchemeCodeUnspsc190501.50545842'),
	'identifierSchemeCodeUnspsc190501.50545843': __('identifierSchemeCodeUnspsc190501.50545843'),
	'identifierSchemeCodeUnspsc190501.50545844': __('identifierSchemeCodeUnspsc190501.50545844'),
	'identifierSchemeCodeUnspsc190501.50545845': __('identifierSchemeCodeUnspsc190501.50545845'),
	'identifierSchemeCodeUnspsc190501.50545846': __('identifierSchemeCodeUnspsc190501.50545846'),
	'identifierSchemeCodeUnspsc190501.50545847': __('identifierSchemeCodeUnspsc190501.50545847'),
	'identifierSchemeCodeUnspsc190501.50545848': __('identifierSchemeCodeUnspsc190501.50545848'),
	'identifierSchemeCodeUnspsc190501.50545849': __('identifierSchemeCodeUnspsc190501.50545849'),
	'identifierSchemeCodeUnspsc190501.50545850': __('identifierSchemeCodeUnspsc190501.50545850'),
	'identifierSchemeCodeUnspsc190501.50545851': __('identifierSchemeCodeUnspsc190501.50545851'),
	'identifierSchemeCodeUnspsc190501.50545852': __('identifierSchemeCodeUnspsc190501.50545852'),
	'identifierSchemeCodeUnspsc190501.50545853': __('identifierSchemeCodeUnspsc190501.50545853'),
	'identifierSchemeCodeUnspsc190501.50545854': __('identifierSchemeCodeUnspsc190501.50545854'),
	'identifierSchemeCodeUnspsc190501.50545855': __('identifierSchemeCodeUnspsc190501.50545855'),
	'identifierSchemeCodeUnspsc190501.50545856': __('identifierSchemeCodeUnspsc190501.50545856'),
	'identifierSchemeCodeUnspsc190501.50545857': __('identifierSchemeCodeUnspsc190501.50545857'),
	'identifierSchemeCodeUnspsc190501.50545858': __('identifierSchemeCodeUnspsc190501.50545858'),
	'identifierSchemeCodeUnspsc190501.50545859': __('identifierSchemeCodeUnspsc190501.50545859'),
	'identifierSchemeCodeUnspsc190501.50545860': __('identifierSchemeCodeUnspsc190501.50545860'),
	'identifierSchemeCodeUnspsc190501.50545861': __('identifierSchemeCodeUnspsc190501.50545861'),
	'identifierSchemeCodeUnspsc190501.50545862': __('identifierSchemeCodeUnspsc190501.50545862'),
	'identifierSchemeCodeUnspsc190501.50545863': __('identifierSchemeCodeUnspsc190501.50545863'),
	'identifierSchemeCodeUnspsc190501.50545864': __('identifierSchemeCodeUnspsc190501.50545864'),
	'identifierSchemeCodeUnspsc190501.50545865': __('identifierSchemeCodeUnspsc190501.50545865'),
	'identifierSchemeCodeUnspsc190501.50545866': __('identifierSchemeCodeUnspsc190501.50545866'),
	'identifierSchemeCodeUnspsc190501.50545867': __('identifierSchemeCodeUnspsc190501.50545867'),
	'identifierSchemeCodeUnspsc190501.50545868': __('identifierSchemeCodeUnspsc190501.50545868'),
	'identifierSchemeCodeUnspsc190501.50545869': __('identifierSchemeCodeUnspsc190501.50545869'),
	'identifierSchemeCodeUnspsc190501.50545870': __('identifierSchemeCodeUnspsc190501.50545870'),
	'identifierSchemeCodeUnspsc190501.50545871': __('identifierSchemeCodeUnspsc190501.50545871'),
	'identifierSchemeCodeUnspsc190501.50545872': __('identifierSchemeCodeUnspsc190501.50545872'),
	'identifierSchemeCodeUnspsc190501.50545873': __('identifierSchemeCodeUnspsc190501.50545873'),
	'identifierSchemeCodeUnspsc190501.50545874': __('identifierSchemeCodeUnspsc190501.50545874'),
	'identifierSchemeCodeUnspsc190501.50545875': __('identifierSchemeCodeUnspsc190501.50545875'),
	'identifierSchemeCodeUnspsc190501.50545876': __('identifierSchemeCodeUnspsc190501.50545876'),
	'identifierSchemeCodeUnspsc190501.50545877': __('identifierSchemeCodeUnspsc190501.50545877'),
	'identifierSchemeCodeUnspsc190501.50545900': __('identifierSchemeCodeUnspsc190501.50545900'),
	'identifierSchemeCodeUnspsc190501.50545901': __('identifierSchemeCodeUnspsc190501.50545901'),
	'identifierSchemeCodeUnspsc190501.50545902': __('identifierSchemeCodeUnspsc190501.50545902'),
	'identifierSchemeCodeUnspsc190501.50545903': __('identifierSchemeCodeUnspsc190501.50545903'),
	'identifierSchemeCodeUnspsc190501.50545904': __('identifierSchemeCodeUnspsc190501.50545904'),
	'identifierSchemeCodeUnspsc190501.50545905': __('identifierSchemeCodeUnspsc190501.50545905'),
	'identifierSchemeCodeUnspsc190501.50545906': __('identifierSchemeCodeUnspsc190501.50545906'),
	'identifierSchemeCodeUnspsc190501.50545907': __('identifierSchemeCodeUnspsc190501.50545907'),
	'identifierSchemeCodeUnspsc190501.50545908': __('identifierSchemeCodeUnspsc190501.50545908'),
	'identifierSchemeCodeUnspsc190501.50546000': __('identifierSchemeCodeUnspsc190501.50546000'),
	'identifierSchemeCodeUnspsc190501.50546001': __('identifierSchemeCodeUnspsc190501.50546001'),
	'identifierSchemeCodeUnspsc190501.50546002': __('identifierSchemeCodeUnspsc190501.50546002'),
	'identifierSchemeCodeUnspsc190501.50546003': __('identifierSchemeCodeUnspsc190501.50546003'),
	'identifierSchemeCodeUnspsc190501.50546004': __('identifierSchemeCodeUnspsc190501.50546004'),
	'identifierSchemeCodeUnspsc190501.50546005': __('identifierSchemeCodeUnspsc190501.50546005'),
	'identifierSchemeCodeUnspsc190501.50546006': __('identifierSchemeCodeUnspsc190501.50546006'),
	'identifierSchemeCodeUnspsc190501.50546007': __('identifierSchemeCodeUnspsc190501.50546007'),
	'identifierSchemeCodeUnspsc190501.50546008': __('identifierSchemeCodeUnspsc190501.50546008'),
	'identifierSchemeCodeUnspsc190501.50546100': __('identifierSchemeCodeUnspsc190501.50546100'),
	'identifierSchemeCodeUnspsc190501.50546101': __('identifierSchemeCodeUnspsc190501.50546101'),
	'identifierSchemeCodeUnspsc190501.50546102': __('identifierSchemeCodeUnspsc190501.50546102'),
	'identifierSchemeCodeUnspsc190501.50546103': __('identifierSchemeCodeUnspsc190501.50546103'),
	'identifierSchemeCodeUnspsc190501.50546200': __('identifierSchemeCodeUnspsc190501.50546200'),
	'identifierSchemeCodeUnspsc190501.50546201': __('identifierSchemeCodeUnspsc190501.50546201'),
	'identifierSchemeCodeUnspsc190501.50546202': __('identifierSchemeCodeUnspsc190501.50546202'),
	'identifierSchemeCodeUnspsc190501.50546203': __('identifierSchemeCodeUnspsc190501.50546203'),
	'identifierSchemeCodeUnspsc190501.50546204': __('identifierSchemeCodeUnspsc190501.50546204'),
	'identifierSchemeCodeUnspsc190501.50546205': __('identifierSchemeCodeUnspsc190501.50546205'),
	'identifierSchemeCodeUnspsc190501.50546206': __('identifierSchemeCodeUnspsc190501.50546206'),
	'identifierSchemeCodeUnspsc190501.50546207': __('identifierSchemeCodeUnspsc190501.50546207'),
	'identifierSchemeCodeUnspsc190501.50546208': __('identifierSchemeCodeUnspsc190501.50546208'),
	'identifierSchemeCodeUnspsc190501.50546209': __('identifierSchemeCodeUnspsc190501.50546209'),
	'identifierSchemeCodeUnspsc190501.50546210': __('identifierSchemeCodeUnspsc190501.50546210'),
	'identifierSchemeCodeUnspsc190501.50546211': __('identifierSchemeCodeUnspsc190501.50546211'),
	'identifierSchemeCodeUnspsc190501.50546212': __('identifierSchemeCodeUnspsc190501.50546212'),
	'identifierSchemeCodeUnspsc190501.50546213': __('identifierSchemeCodeUnspsc190501.50546213'),
	'identifierSchemeCodeUnspsc190501.50546214': __('identifierSchemeCodeUnspsc190501.50546214'),
	'identifierSchemeCodeUnspsc190501.50546215': __('identifierSchemeCodeUnspsc190501.50546215'),
	'identifierSchemeCodeUnspsc190501.50546216': __('identifierSchemeCodeUnspsc190501.50546216'),
	'identifierSchemeCodeUnspsc190501.50546217': __('identifierSchemeCodeUnspsc190501.50546217'),
	'identifierSchemeCodeUnspsc190501.50546218': __('identifierSchemeCodeUnspsc190501.50546218'),
	'identifierSchemeCodeUnspsc190501.50546219': __('identifierSchemeCodeUnspsc190501.50546219'),
	'identifierSchemeCodeUnspsc190501.50546220': __('identifierSchemeCodeUnspsc190501.50546220'),
	'identifierSchemeCodeUnspsc190501.50546221': __('identifierSchemeCodeUnspsc190501.50546221'),
	'identifierSchemeCodeUnspsc190501.50546222': __('identifierSchemeCodeUnspsc190501.50546222'),
	'identifierSchemeCodeUnspsc190501.50546223': __('identifierSchemeCodeUnspsc190501.50546223'),
	'identifierSchemeCodeUnspsc190501.50546224': __('identifierSchemeCodeUnspsc190501.50546224'),
	'identifierSchemeCodeUnspsc190501.50546225': __('identifierSchemeCodeUnspsc190501.50546225'),
	'identifierSchemeCodeUnspsc190501.50546226': __('identifierSchemeCodeUnspsc190501.50546226'),
	'identifierSchemeCodeUnspsc190501.50546227': __('identifierSchemeCodeUnspsc190501.50546227'),
	'identifierSchemeCodeUnspsc190501.50546228': __('identifierSchemeCodeUnspsc190501.50546228'),
	'identifierSchemeCodeUnspsc190501.50546229': __('identifierSchemeCodeUnspsc190501.50546229'),
	'identifierSchemeCodeUnspsc190501.50546300': __('identifierSchemeCodeUnspsc190501.50546300'),
	'identifierSchemeCodeUnspsc190501.50546301': __('identifierSchemeCodeUnspsc190501.50546301'),
	'identifierSchemeCodeUnspsc190501.50546302': __('identifierSchemeCodeUnspsc190501.50546302'),
	'identifierSchemeCodeUnspsc190501.50546303': __('identifierSchemeCodeUnspsc190501.50546303'),
	'identifierSchemeCodeUnspsc190501.50546304': __('identifierSchemeCodeUnspsc190501.50546304'),
	'identifierSchemeCodeUnspsc190501.50546305': __('identifierSchemeCodeUnspsc190501.50546305'),
	'identifierSchemeCodeUnspsc190501.50546306': __('identifierSchemeCodeUnspsc190501.50546306'),
	'identifierSchemeCodeUnspsc190501.50546307': __('identifierSchemeCodeUnspsc190501.50546307'),
	'identifierSchemeCodeUnspsc190501.50546308': __('identifierSchemeCodeUnspsc190501.50546308'),
	'identifierSchemeCodeUnspsc190501.50546309': __('identifierSchemeCodeUnspsc190501.50546309'),
	'identifierSchemeCodeUnspsc190501.50546310': __('identifierSchemeCodeUnspsc190501.50546310'),
	'identifierSchemeCodeUnspsc190501.50546400': __('identifierSchemeCodeUnspsc190501.50546400'),
	'identifierSchemeCodeUnspsc190501.50546401': __('identifierSchemeCodeUnspsc190501.50546401'),
	'identifierSchemeCodeUnspsc190501.50546402': __('identifierSchemeCodeUnspsc190501.50546402'),
	'identifierSchemeCodeUnspsc190501.50546403': __('identifierSchemeCodeUnspsc190501.50546403'),
	'identifierSchemeCodeUnspsc190501.50546404': __('identifierSchemeCodeUnspsc190501.50546404'),
	'identifierSchemeCodeUnspsc190501.50546500': __('identifierSchemeCodeUnspsc190501.50546500'),
	'identifierSchemeCodeUnspsc190501.50546501': __('identifierSchemeCodeUnspsc190501.50546501'),
	'identifierSchemeCodeUnspsc190501.50546502': __('identifierSchemeCodeUnspsc190501.50546502'),
	'identifierSchemeCodeUnspsc190501.50546600': __('identifierSchemeCodeUnspsc190501.50546600'),
	'identifierSchemeCodeUnspsc190501.50546601': __('identifierSchemeCodeUnspsc190501.50546601'),
	'identifierSchemeCodeUnspsc190501.50546602': __('identifierSchemeCodeUnspsc190501.50546602'),
	'identifierSchemeCodeUnspsc190501.50546603': __('identifierSchemeCodeUnspsc190501.50546603'),
	'identifierSchemeCodeUnspsc190501.50546604': __('identifierSchemeCodeUnspsc190501.50546604'),
	'identifierSchemeCodeUnspsc190501.50546700': __('identifierSchemeCodeUnspsc190501.50546700'),
	'identifierSchemeCodeUnspsc190501.50546701': __('identifierSchemeCodeUnspsc190501.50546701'),
	'identifierSchemeCodeUnspsc190501.50546702': __('identifierSchemeCodeUnspsc190501.50546702'),
	'identifierSchemeCodeUnspsc190501.50546703': __('identifierSchemeCodeUnspsc190501.50546703'),
	'identifierSchemeCodeUnspsc190501.50546800': __('identifierSchemeCodeUnspsc190501.50546800'),
	'identifierSchemeCodeUnspsc190501.50546801': __('identifierSchemeCodeUnspsc190501.50546801'),
	'identifierSchemeCodeUnspsc190501.50546802': __('identifierSchemeCodeUnspsc190501.50546802'),
	'identifierSchemeCodeUnspsc190501.50546803': __('identifierSchemeCodeUnspsc190501.50546803'),
	'identifierSchemeCodeUnspsc190501.50546900': __('identifierSchemeCodeUnspsc190501.50546900'),
	'identifierSchemeCodeUnspsc190501.50546901': __('identifierSchemeCodeUnspsc190501.50546901'),
	'identifierSchemeCodeUnspsc190501.50546902': __('identifierSchemeCodeUnspsc190501.50546902'),
	'identifierSchemeCodeUnspsc190501.50546903': __('identifierSchemeCodeUnspsc190501.50546903'),
	'identifierSchemeCodeUnspsc190501.50546904': __('identifierSchemeCodeUnspsc190501.50546904'),
	'identifierSchemeCodeUnspsc190501.50546905': __('identifierSchemeCodeUnspsc190501.50546905'),
	'identifierSchemeCodeUnspsc190501.50546906': __('identifierSchemeCodeUnspsc190501.50546906'),
	'identifierSchemeCodeUnspsc190501.50547000': __('identifierSchemeCodeUnspsc190501.50547000'),
	'identifierSchemeCodeUnspsc190501.50547001': __('identifierSchemeCodeUnspsc190501.50547001'),
	'identifierSchemeCodeUnspsc190501.50547002': __('identifierSchemeCodeUnspsc190501.50547002'),
	'identifierSchemeCodeUnspsc190501.50547003': __('identifierSchemeCodeUnspsc190501.50547003'),
	'identifierSchemeCodeUnspsc190501.50547004': __('identifierSchemeCodeUnspsc190501.50547004'),
	'identifierSchemeCodeUnspsc190501.50547005': __('identifierSchemeCodeUnspsc190501.50547005'),
	'identifierSchemeCodeUnspsc190501.50547006': __('identifierSchemeCodeUnspsc190501.50547006'),
	'identifierSchemeCodeUnspsc190501.50547007': __('identifierSchemeCodeUnspsc190501.50547007'),
	'identifierSchemeCodeUnspsc190501.50547008': __('identifierSchemeCodeUnspsc190501.50547008'),
	'identifierSchemeCodeUnspsc190501.50547009': __('identifierSchemeCodeUnspsc190501.50547009'),
	'identifierSchemeCodeUnspsc190501.50547010': __('identifierSchemeCodeUnspsc190501.50547010'),
	'identifierSchemeCodeUnspsc190501.50547011': __('identifierSchemeCodeUnspsc190501.50547011'),
	'identifierSchemeCodeUnspsc190501.50547012': __('identifierSchemeCodeUnspsc190501.50547012'),
	'identifierSchemeCodeUnspsc190501.50547013': __('identifierSchemeCodeUnspsc190501.50547013'),
	'identifierSchemeCodeUnspsc190501.50547014': __('identifierSchemeCodeUnspsc190501.50547014'),
	'identifierSchemeCodeUnspsc190501.50547015': __('identifierSchemeCodeUnspsc190501.50547015'),
	'identifierSchemeCodeUnspsc190501.50547016': __('identifierSchemeCodeUnspsc190501.50547016'),
	'identifierSchemeCodeUnspsc190501.50547017': __('identifierSchemeCodeUnspsc190501.50547017'),
	'identifierSchemeCodeUnspsc190501.50547018': __('identifierSchemeCodeUnspsc190501.50547018'),
	'identifierSchemeCodeUnspsc190501.50547019': __('identifierSchemeCodeUnspsc190501.50547019'),
	'identifierSchemeCodeUnspsc190501.50547020': __('identifierSchemeCodeUnspsc190501.50547020'),
	'identifierSchemeCodeUnspsc190501.50547021': __('identifierSchemeCodeUnspsc190501.50547021'),
	'identifierSchemeCodeUnspsc190501.50547022': __('identifierSchemeCodeUnspsc190501.50547022'),
	'identifierSchemeCodeUnspsc190501.50547023': __('identifierSchemeCodeUnspsc190501.50547023'),
	'identifierSchemeCodeUnspsc190501.50547024': __('identifierSchemeCodeUnspsc190501.50547024'),
	'identifierSchemeCodeUnspsc190501.50547025': __('identifierSchemeCodeUnspsc190501.50547025'),
	'identifierSchemeCodeUnspsc190501.50547026': __('identifierSchemeCodeUnspsc190501.50547026'),
	'identifierSchemeCodeUnspsc190501.50547027': __('identifierSchemeCodeUnspsc190501.50547027'),
	'identifierSchemeCodeUnspsc190501.50547028': __('identifierSchemeCodeUnspsc190501.50547028'),
	'identifierSchemeCodeUnspsc190501.50547029': __('identifierSchemeCodeUnspsc190501.50547029'),
	'identifierSchemeCodeUnspsc190501.50547030': __('identifierSchemeCodeUnspsc190501.50547030'),
	'identifierSchemeCodeUnspsc190501.50547031': __('identifierSchemeCodeUnspsc190501.50547031'),
	'identifierSchemeCodeUnspsc190501.50547032': __('identifierSchemeCodeUnspsc190501.50547032'),
	'identifierSchemeCodeUnspsc190501.50547033': __('identifierSchemeCodeUnspsc190501.50547033'),
	'identifierSchemeCodeUnspsc190501.50547034': __('identifierSchemeCodeUnspsc190501.50547034'),
	'identifierSchemeCodeUnspsc190501.50547035': __('identifierSchemeCodeUnspsc190501.50547035'),
	'identifierSchemeCodeUnspsc190501.50547036': __('identifierSchemeCodeUnspsc190501.50547036'),
	'identifierSchemeCodeUnspsc190501.50547037': __('identifierSchemeCodeUnspsc190501.50547037'),
	'identifierSchemeCodeUnspsc190501.50547100': __('identifierSchemeCodeUnspsc190501.50547100'),
	'identifierSchemeCodeUnspsc190501.50547101': __('identifierSchemeCodeUnspsc190501.50547101'),
	'identifierSchemeCodeUnspsc190501.50547102': __('identifierSchemeCodeUnspsc190501.50547102'),
	'identifierSchemeCodeUnspsc190501.50547103': __('identifierSchemeCodeUnspsc190501.50547103'),
	'identifierSchemeCodeUnspsc190501.50547104': __('identifierSchemeCodeUnspsc190501.50547104'),
	'identifierSchemeCodeUnspsc190501.50547200': __('identifierSchemeCodeUnspsc190501.50547200'),
	'identifierSchemeCodeUnspsc190501.50547201': __('identifierSchemeCodeUnspsc190501.50547201'),
	'identifierSchemeCodeUnspsc190501.50547202': __('identifierSchemeCodeUnspsc190501.50547202'),
	'identifierSchemeCodeUnspsc190501.50547203': __('identifierSchemeCodeUnspsc190501.50547203'),
	'identifierSchemeCodeUnspsc190501.50547204': __('identifierSchemeCodeUnspsc190501.50547204'),
	'identifierSchemeCodeUnspsc190501.50547205': __('identifierSchemeCodeUnspsc190501.50547205'),
	'identifierSchemeCodeUnspsc190501.50547206': __('identifierSchemeCodeUnspsc190501.50547206'),
	'identifierSchemeCodeUnspsc190501.50547207': __('identifierSchemeCodeUnspsc190501.50547207'),
	'identifierSchemeCodeUnspsc190501.50547208': __('identifierSchemeCodeUnspsc190501.50547208'),
	'identifierSchemeCodeUnspsc190501.50547209': __('identifierSchemeCodeUnspsc190501.50547209'),
	'identifierSchemeCodeUnspsc190501.50547210': __('identifierSchemeCodeUnspsc190501.50547210'),
	'identifierSchemeCodeUnspsc190501.50547211': __('identifierSchemeCodeUnspsc190501.50547211'),
	'identifierSchemeCodeUnspsc190501.50547212': __('identifierSchemeCodeUnspsc190501.50547212'),
	'identifierSchemeCodeUnspsc190501.50547213': __('identifierSchemeCodeUnspsc190501.50547213'),
	'identifierSchemeCodeUnspsc190501.50547214': __('identifierSchemeCodeUnspsc190501.50547214'),
	'identifierSchemeCodeUnspsc190501.50547215': __('identifierSchemeCodeUnspsc190501.50547215'),
	'identifierSchemeCodeUnspsc190501.50547216': __('identifierSchemeCodeUnspsc190501.50547216'),
	'identifierSchemeCodeUnspsc190501.50547217': __('identifierSchemeCodeUnspsc190501.50547217'),
	'identifierSchemeCodeUnspsc190501.50547218': __('identifierSchemeCodeUnspsc190501.50547218'),
	'identifierSchemeCodeUnspsc190501.50547219': __('identifierSchemeCodeUnspsc190501.50547219'),
	'identifierSchemeCodeUnspsc190501.50547220': __('identifierSchemeCodeUnspsc190501.50547220'),
	'identifierSchemeCodeUnspsc190501.50547221': __('identifierSchemeCodeUnspsc190501.50547221'),
	'identifierSchemeCodeUnspsc190501.50547222': __('identifierSchemeCodeUnspsc190501.50547222'),
	'identifierSchemeCodeUnspsc190501.50547223': __('identifierSchemeCodeUnspsc190501.50547223'),
	'identifierSchemeCodeUnspsc190501.50547224': __('identifierSchemeCodeUnspsc190501.50547224'),
	'identifierSchemeCodeUnspsc190501.50547225': __('identifierSchemeCodeUnspsc190501.50547225'),
	'identifierSchemeCodeUnspsc190501.50547226': __('identifierSchemeCodeUnspsc190501.50547226'),
	'identifierSchemeCodeUnspsc190501.50547227': __('identifierSchemeCodeUnspsc190501.50547227'),
	'identifierSchemeCodeUnspsc190501.50547228': __('identifierSchemeCodeUnspsc190501.50547228'),
	'identifierSchemeCodeUnspsc190501.50547229': __('identifierSchemeCodeUnspsc190501.50547229'),
	'identifierSchemeCodeUnspsc190501.50547230': __('identifierSchemeCodeUnspsc190501.50547230'),
	'identifierSchemeCodeUnspsc190501.50550000': __('identifierSchemeCodeUnspsc190501.50550000'),
	'identifierSchemeCodeUnspsc190501.50551500': __('identifierSchemeCodeUnspsc190501.50551500'),
	'identifierSchemeCodeUnspsc190501.50551501': __('identifierSchemeCodeUnspsc190501.50551501'),
	'identifierSchemeCodeUnspsc190501.50551502': __('identifierSchemeCodeUnspsc190501.50551502'),
	'identifierSchemeCodeUnspsc190501.50551503': __('identifierSchemeCodeUnspsc190501.50551503'),
	'identifierSchemeCodeUnspsc190501.50551504': __('identifierSchemeCodeUnspsc190501.50551504'),
	'identifierSchemeCodeUnspsc190501.50551505': __('identifierSchemeCodeUnspsc190501.50551505'),
	'identifierSchemeCodeUnspsc190501.50551506': __('identifierSchemeCodeUnspsc190501.50551506'),
	'identifierSchemeCodeUnspsc190501.50551507': __('identifierSchemeCodeUnspsc190501.50551507'),
	'identifierSchemeCodeUnspsc190501.50551508': __('identifierSchemeCodeUnspsc190501.50551508'),
	'identifierSchemeCodeUnspsc190501.50551509': __('identifierSchemeCodeUnspsc190501.50551509'),
	'identifierSchemeCodeUnspsc190501.50551510': __('identifierSchemeCodeUnspsc190501.50551510'),
	'identifierSchemeCodeUnspsc190501.50551511': __('identifierSchemeCodeUnspsc190501.50551511'),
	'identifierSchemeCodeUnspsc190501.50551512': __('identifierSchemeCodeUnspsc190501.50551512'),
	'identifierSchemeCodeUnspsc190501.50551513': __('identifierSchemeCodeUnspsc190501.50551513'),
	'identifierSchemeCodeUnspsc190501.50551514': __('identifierSchemeCodeUnspsc190501.50551514'),
	'identifierSchemeCodeUnspsc190501.50551515': __('identifierSchemeCodeUnspsc190501.50551515'),
	'identifierSchemeCodeUnspsc190501.50551516': __('identifierSchemeCodeUnspsc190501.50551516'),
	'identifierSchemeCodeUnspsc190501.50551517': __('identifierSchemeCodeUnspsc190501.50551517'),
	'identifierSchemeCodeUnspsc190501.50551518': __('identifierSchemeCodeUnspsc190501.50551518'),
	'identifierSchemeCodeUnspsc190501.50551519': __('identifierSchemeCodeUnspsc190501.50551519'),
	'identifierSchemeCodeUnspsc190501.50551520': __('identifierSchemeCodeUnspsc190501.50551520'),
	'identifierSchemeCodeUnspsc190501.50551521': __('identifierSchemeCodeUnspsc190501.50551521'),
	'identifierSchemeCodeUnspsc190501.50551522': __('identifierSchemeCodeUnspsc190501.50551522'),
	'identifierSchemeCodeUnspsc190501.50551523': __('identifierSchemeCodeUnspsc190501.50551523'),
	'identifierSchemeCodeUnspsc190501.50551524': __('identifierSchemeCodeUnspsc190501.50551524'),
	'identifierSchemeCodeUnspsc190501.50551525': __('identifierSchemeCodeUnspsc190501.50551525'),
	'identifierSchemeCodeUnspsc190501.50551526': __('identifierSchemeCodeUnspsc190501.50551526'),
	'identifierSchemeCodeUnspsc190501.50551527': __('identifierSchemeCodeUnspsc190501.50551527'),
	'identifierSchemeCodeUnspsc190501.50551528': __('identifierSchemeCodeUnspsc190501.50551528'),
	'identifierSchemeCodeUnspsc190501.50551529': __('identifierSchemeCodeUnspsc190501.50551529'),
	'identifierSchemeCodeUnspsc190501.50551530': __('identifierSchemeCodeUnspsc190501.50551530'),
	'identifierSchemeCodeUnspsc190501.50551531': __('identifierSchemeCodeUnspsc190501.50551531'),
	'identifierSchemeCodeUnspsc190501.50551532': __('identifierSchemeCodeUnspsc190501.50551532'),
	'identifierSchemeCodeUnspsc190501.50551533': __('identifierSchemeCodeUnspsc190501.50551533'),
	'identifierSchemeCodeUnspsc190501.50551534': __('identifierSchemeCodeUnspsc190501.50551534'),
	'identifierSchemeCodeUnspsc190501.50551535': __('identifierSchemeCodeUnspsc190501.50551535'),
	'identifierSchemeCodeUnspsc190501.50551536': __('identifierSchemeCodeUnspsc190501.50551536'),
	'identifierSchemeCodeUnspsc190501.50551537': __('identifierSchemeCodeUnspsc190501.50551537'),
	'identifierSchemeCodeUnspsc190501.50551538': __('identifierSchemeCodeUnspsc190501.50551538'),
	'identifierSchemeCodeUnspsc190501.50551539': __('identifierSchemeCodeUnspsc190501.50551539'),
	'identifierSchemeCodeUnspsc190501.50551540': __('identifierSchemeCodeUnspsc190501.50551540'),
	'identifierSchemeCodeUnspsc190501.50551541': __('identifierSchemeCodeUnspsc190501.50551541'),
	'identifierSchemeCodeUnspsc190501.50551542': __('identifierSchemeCodeUnspsc190501.50551542'),
	'identifierSchemeCodeUnspsc190501.50551543': __('identifierSchemeCodeUnspsc190501.50551543'),
	'identifierSchemeCodeUnspsc190501.50551544': __('identifierSchemeCodeUnspsc190501.50551544'),
	'identifierSchemeCodeUnspsc190501.50551545': __('identifierSchemeCodeUnspsc190501.50551545'),
	'identifierSchemeCodeUnspsc190501.50551546': __('identifierSchemeCodeUnspsc190501.50551546'),
	'identifierSchemeCodeUnspsc190501.50551547': __('identifierSchemeCodeUnspsc190501.50551547'),
	'identifierSchemeCodeUnspsc190501.50551548': __('identifierSchemeCodeUnspsc190501.50551548'),
	'identifierSchemeCodeUnspsc190501.50551549': __('identifierSchemeCodeUnspsc190501.50551549'),
	'identifierSchemeCodeUnspsc190501.50551550': __('identifierSchemeCodeUnspsc190501.50551550'),
	'identifierSchemeCodeUnspsc190501.50551551': __('identifierSchemeCodeUnspsc190501.50551551'),
	'identifierSchemeCodeUnspsc190501.50551552': __('identifierSchemeCodeUnspsc190501.50551552'),
	'identifierSchemeCodeUnspsc190501.50551553': __('identifierSchemeCodeUnspsc190501.50551553'),
	'identifierSchemeCodeUnspsc190501.50551554': __('identifierSchemeCodeUnspsc190501.50551554'),
	'identifierSchemeCodeUnspsc190501.50551555': __('identifierSchemeCodeUnspsc190501.50551555'),
	'identifierSchemeCodeUnspsc190501.50551556': __('identifierSchemeCodeUnspsc190501.50551556'),
	'identifierSchemeCodeUnspsc190501.50551557': __('identifierSchemeCodeUnspsc190501.50551557'),
	'identifierSchemeCodeUnspsc190501.50551558': __('identifierSchemeCodeUnspsc190501.50551558'),
	'identifierSchemeCodeUnspsc190501.50551559': __('identifierSchemeCodeUnspsc190501.50551559'),
	'identifierSchemeCodeUnspsc190501.50551560': __('identifierSchemeCodeUnspsc190501.50551560'),
	'identifierSchemeCodeUnspsc190501.50551561': __('identifierSchemeCodeUnspsc190501.50551561'),
	'identifierSchemeCodeUnspsc190501.50551562': __('identifierSchemeCodeUnspsc190501.50551562'),
	'identifierSchemeCodeUnspsc190501.50551563': __('identifierSchemeCodeUnspsc190501.50551563'),
	'identifierSchemeCodeUnspsc190501.50551564': __('identifierSchemeCodeUnspsc190501.50551564'),
	'identifierSchemeCodeUnspsc190501.50551565': __('identifierSchemeCodeUnspsc190501.50551565'),
	'identifierSchemeCodeUnspsc190501.50551566': __('identifierSchemeCodeUnspsc190501.50551566'),
	'identifierSchemeCodeUnspsc190501.50551567': __('identifierSchemeCodeUnspsc190501.50551567'),
	'identifierSchemeCodeUnspsc190501.50551568': __('identifierSchemeCodeUnspsc190501.50551568'),
	'identifierSchemeCodeUnspsc190501.50551569': __('identifierSchemeCodeUnspsc190501.50551569'),
	'identifierSchemeCodeUnspsc190501.50551570': __('identifierSchemeCodeUnspsc190501.50551570'),
	'identifierSchemeCodeUnspsc190501.50551571': __('identifierSchemeCodeUnspsc190501.50551571'),
	'identifierSchemeCodeUnspsc190501.50551572': __('identifierSchemeCodeUnspsc190501.50551572'),
	'identifierSchemeCodeUnspsc190501.50551573': __('identifierSchemeCodeUnspsc190501.50551573'),
	'identifierSchemeCodeUnspsc190501.50551574': __('identifierSchemeCodeUnspsc190501.50551574'),
	'identifierSchemeCodeUnspsc190501.50551575': __('identifierSchemeCodeUnspsc190501.50551575'),
	'identifierSchemeCodeUnspsc190501.50551576': __('identifierSchemeCodeUnspsc190501.50551576'),
	'identifierSchemeCodeUnspsc190501.50551577': __('identifierSchemeCodeUnspsc190501.50551577'),
	'identifierSchemeCodeUnspsc190501.50551578': __('identifierSchemeCodeUnspsc190501.50551578'),
	'identifierSchemeCodeUnspsc190501.50551579': __('identifierSchemeCodeUnspsc190501.50551579'),
	'identifierSchemeCodeUnspsc190501.50551580': __('identifierSchemeCodeUnspsc190501.50551580'),
	'identifierSchemeCodeUnspsc190501.50551581': __('identifierSchemeCodeUnspsc190501.50551581'),
	'identifierSchemeCodeUnspsc190501.50551582': __('identifierSchemeCodeUnspsc190501.50551582'),
	'identifierSchemeCodeUnspsc190501.50551583': __('identifierSchemeCodeUnspsc190501.50551583'),
	'identifierSchemeCodeUnspsc190501.50551584': __('identifierSchemeCodeUnspsc190501.50551584'),
	'identifierSchemeCodeUnspsc190501.50551585': __('identifierSchemeCodeUnspsc190501.50551585'),
	'identifierSchemeCodeUnspsc190501.50551586': __('identifierSchemeCodeUnspsc190501.50551586'),
	'identifierSchemeCodeUnspsc190501.50551587': __('identifierSchemeCodeUnspsc190501.50551587'),
	'identifierSchemeCodeUnspsc190501.50551588': __('identifierSchemeCodeUnspsc190501.50551588'),
	'identifierSchemeCodeUnspsc190501.50551589': __('identifierSchemeCodeUnspsc190501.50551589'),
	'identifierSchemeCodeUnspsc190501.50551590': __('identifierSchemeCodeUnspsc190501.50551590'),
	'identifierSchemeCodeUnspsc190501.50551591': __('identifierSchemeCodeUnspsc190501.50551591'),
	'identifierSchemeCodeUnspsc190501.50551592': __('identifierSchemeCodeUnspsc190501.50551592'),
	'identifierSchemeCodeUnspsc190501.50551593': __('identifierSchemeCodeUnspsc190501.50551593'),
	'identifierSchemeCodeUnspsc190501.50551594': __('identifierSchemeCodeUnspsc190501.50551594'),
	'identifierSchemeCodeUnspsc190501.50551595': __('identifierSchemeCodeUnspsc190501.50551595'),
	'identifierSchemeCodeUnspsc190501.50551596': __('identifierSchemeCodeUnspsc190501.50551596'),
	'identifierSchemeCodeUnspsc190501.50551600': __('identifierSchemeCodeUnspsc190501.50551600'),
	'identifierSchemeCodeUnspsc190501.50551601': __('identifierSchemeCodeUnspsc190501.50551601'),
	'identifierSchemeCodeUnspsc190501.50551602': __('identifierSchemeCodeUnspsc190501.50551602'),
	'identifierSchemeCodeUnspsc190501.50551603': __('identifierSchemeCodeUnspsc190501.50551603'),
	'identifierSchemeCodeUnspsc190501.50551604': __('identifierSchemeCodeUnspsc190501.50551604'),
	'identifierSchemeCodeUnspsc190501.50551605': __('identifierSchemeCodeUnspsc190501.50551605'),
	'identifierSchemeCodeUnspsc190501.50551606': __('identifierSchemeCodeUnspsc190501.50551606'),
	'identifierSchemeCodeUnspsc190501.50551607': __('identifierSchemeCodeUnspsc190501.50551607'),
	'identifierSchemeCodeUnspsc190501.50551608': __('identifierSchemeCodeUnspsc190501.50551608'),
	'identifierSchemeCodeUnspsc190501.50551609': __('identifierSchemeCodeUnspsc190501.50551609'),
	'identifierSchemeCodeUnspsc190501.50551610': __('identifierSchemeCodeUnspsc190501.50551610'),
	'identifierSchemeCodeUnspsc190501.50551611': __('identifierSchemeCodeUnspsc190501.50551611'),
	'identifierSchemeCodeUnspsc190501.50551612': __('identifierSchemeCodeUnspsc190501.50551612'),
	'identifierSchemeCodeUnspsc190501.50551613': __('identifierSchemeCodeUnspsc190501.50551613'),
	'identifierSchemeCodeUnspsc190501.50551614': __('identifierSchemeCodeUnspsc190501.50551614'),
	'identifierSchemeCodeUnspsc190501.50551615': __('identifierSchemeCodeUnspsc190501.50551615'),
	'identifierSchemeCodeUnspsc190501.50551616': __('identifierSchemeCodeUnspsc190501.50551616'),
	'identifierSchemeCodeUnspsc190501.50551617': __('identifierSchemeCodeUnspsc190501.50551617'),
	'identifierSchemeCodeUnspsc190501.50551618': __('identifierSchemeCodeUnspsc190501.50551618'),
	'identifierSchemeCodeUnspsc190501.50551619': __('identifierSchemeCodeUnspsc190501.50551619'),
	'identifierSchemeCodeUnspsc190501.50551620': __('identifierSchemeCodeUnspsc190501.50551620'),
	'identifierSchemeCodeUnspsc190501.50551621': __('identifierSchemeCodeUnspsc190501.50551621'),
	'identifierSchemeCodeUnspsc190501.50551622': __('identifierSchemeCodeUnspsc190501.50551622'),
	'identifierSchemeCodeUnspsc190501.50551623': __('identifierSchemeCodeUnspsc190501.50551623'),
	'identifierSchemeCodeUnspsc190501.50551624': __('identifierSchemeCodeUnspsc190501.50551624'),
	'identifierSchemeCodeUnspsc190501.50551625': __('identifierSchemeCodeUnspsc190501.50551625'),
	'identifierSchemeCodeUnspsc190501.50551626': __('identifierSchemeCodeUnspsc190501.50551626'),
	'identifierSchemeCodeUnspsc190501.50551627': __('identifierSchemeCodeUnspsc190501.50551627'),
	'identifierSchemeCodeUnspsc190501.50551628': __('identifierSchemeCodeUnspsc190501.50551628'),
	'identifierSchemeCodeUnspsc190501.50551629': __('identifierSchemeCodeUnspsc190501.50551629'),
	'identifierSchemeCodeUnspsc190501.50551630': __('identifierSchemeCodeUnspsc190501.50551630'),
	'identifierSchemeCodeUnspsc190501.50551631': __('identifierSchemeCodeUnspsc190501.50551631'),
	'identifierSchemeCodeUnspsc190501.50551632': __('identifierSchemeCodeUnspsc190501.50551632'),
	'identifierSchemeCodeUnspsc190501.50551633': __('identifierSchemeCodeUnspsc190501.50551633'),
	'identifierSchemeCodeUnspsc190501.50551634': __('identifierSchemeCodeUnspsc190501.50551634'),
	'identifierSchemeCodeUnspsc190501.50551635': __('identifierSchemeCodeUnspsc190501.50551635'),
	'identifierSchemeCodeUnspsc190501.50551636': __('identifierSchemeCodeUnspsc190501.50551636'),
	'identifierSchemeCodeUnspsc190501.50551637': __('identifierSchemeCodeUnspsc190501.50551637'),
	'identifierSchemeCodeUnspsc190501.50551638': __('identifierSchemeCodeUnspsc190501.50551638'),
	'identifierSchemeCodeUnspsc190501.50551639': __('identifierSchemeCodeUnspsc190501.50551639'),
	'identifierSchemeCodeUnspsc190501.50551640': __('identifierSchemeCodeUnspsc190501.50551640'),
	'identifierSchemeCodeUnspsc190501.50551641': __('identifierSchemeCodeUnspsc190501.50551641'),
	'identifierSchemeCodeUnspsc190501.50551642': __('identifierSchemeCodeUnspsc190501.50551642'),
	'identifierSchemeCodeUnspsc190501.50551643': __('identifierSchemeCodeUnspsc190501.50551643'),
	'identifierSchemeCodeUnspsc190501.50551644': __('identifierSchemeCodeUnspsc190501.50551644'),
	'identifierSchemeCodeUnspsc190501.50551645': __('identifierSchemeCodeUnspsc190501.50551645'),
	'identifierSchemeCodeUnspsc190501.50551646': __('identifierSchemeCodeUnspsc190501.50551646'),
	'identifierSchemeCodeUnspsc190501.50551647': __('identifierSchemeCodeUnspsc190501.50551647'),
	'identifierSchemeCodeUnspsc190501.50551648': __('identifierSchemeCodeUnspsc190501.50551648'),
	'identifierSchemeCodeUnspsc190501.50551649': __('identifierSchemeCodeUnspsc190501.50551649'),
	'identifierSchemeCodeUnspsc190501.50551650': __('identifierSchemeCodeUnspsc190501.50551650'),
	'identifierSchemeCodeUnspsc190501.50551651': __('identifierSchemeCodeUnspsc190501.50551651'),
	'identifierSchemeCodeUnspsc190501.50551652': __('identifierSchemeCodeUnspsc190501.50551652'),
	'identifierSchemeCodeUnspsc190501.50551653': __('identifierSchemeCodeUnspsc190501.50551653'),
	'identifierSchemeCodeUnspsc190501.50551654': __('identifierSchemeCodeUnspsc190501.50551654'),
	'identifierSchemeCodeUnspsc190501.50551655': __('identifierSchemeCodeUnspsc190501.50551655'),
	'identifierSchemeCodeUnspsc190501.50551700': __('identifierSchemeCodeUnspsc190501.50551700'),
	'identifierSchemeCodeUnspsc190501.50551701': __('identifierSchemeCodeUnspsc190501.50551701'),
	'identifierSchemeCodeUnspsc190501.50551702': __('identifierSchemeCodeUnspsc190501.50551702'),
	'identifierSchemeCodeUnspsc190501.50551703': __('identifierSchemeCodeUnspsc190501.50551703'),
	'identifierSchemeCodeUnspsc190501.50551704': __('identifierSchemeCodeUnspsc190501.50551704'),
	'identifierSchemeCodeUnspsc190501.50551705': __('identifierSchemeCodeUnspsc190501.50551705'),
	'identifierSchemeCodeUnspsc190501.50551706': __('identifierSchemeCodeUnspsc190501.50551706'),
	'identifierSchemeCodeUnspsc190501.50551707': __('identifierSchemeCodeUnspsc190501.50551707'),
	'identifierSchemeCodeUnspsc190501.50551708': __('identifierSchemeCodeUnspsc190501.50551708'),
	'identifierSchemeCodeUnspsc190501.50551709': __('identifierSchemeCodeUnspsc190501.50551709'),
	'identifierSchemeCodeUnspsc190501.50551710': __('identifierSchemeCodeUnspsc190501.50551710'),
	'identifierSchemeCodeUnspsc190501.50551711': __('identifierSchemeCodeUnspsc190501.50551711'),
	'identifierSchemeCodeUnspsc190501.50551712': __('identifierSchemeCodeUnspsc190501.50551712'),
	'identifierSchemeCodeUnspsc190501.50551713': __('identifierSchemeCodeUnspsc190501.50551713'),
	'identifierSchemeCodeUnspsc190501.50551714': __('identifierSchemeCodeUnspsc190501.50551714'),
	'identifierSchemeCodeUnspsc190501.50551715': __('identifierSchemeCodeUnspsc190501.50551715'),
	'identifierSchemeCodeUnspsc190501.50551800': __('identifierSchemeCodeUnspsc190501.50551800'),
	'identifierSchemeCodeUnspsc190501.50551801': __('identifierSchemeCodeUnspsc190501.50551801'),
	'identifierSchemeCodeUnspsc190501.50551802': __('identifierSchemeCodeUnspsc190501.50551802'),
	'identifierSchemeCodeUnspsc190501.50551803': __('identifierSchemeCodeUnspsc190501.50551803'),
	'identifierSchemeCodeUnspsc190501.50551804': __('identifierSchemeCodeUnspsc190501.50551804'),
	'identifierSchemeCodeUnspsc190501.50551805': __('identifierSchemeCodeUnspsc190501.50551805'),
	'identifierSchemeCodeUnspsc190501.50551806': __('identifierSchemeCodeUnspsc190501.50551806'),
	'identifierSchemeCodeUnspsc190501.50551807': __('identifierSchemeCodeUnspsc190501.50551807'),
	'identifierSchemeCodeUnspsc190501.50551808': __('identifierSchemeCodeUnspsc190501.50551808'),
	'identifierSchemeCodeUnspsc190501.50551809': __('identifierSchemeCodeUnspsc190501.50551809'),
	'identifierSchemeCodeUnspsc190501.50551810': __('identifierSchemeCodeUnspsc190501.50551810'),
	'identifierSchemeCodeUnspsc190501.50551811': __('identifierSchemeCodeUnspsc190501.50551811'),
	'identifierSchemeCodeUnspsc190501.50551812': __('identifierSchemeCodeUnspsc190501.50551812'),
	'identifierSchemeCodeUnspsc190501.50551813': __('identifierSchemeCodeUnspsc190501.50551813'),
	'identifierSchemeCodeUnspsc190501.50551814': __('identifierSchemeCodeUnspsc190501.50551814'),
	'identifierSchemeCodeUnspsc190501.50551900': __('identifierSchemeCodeUnspsc190501.50551900'),
	'identifierSchemeCodeUnspsc190501.50551901': __('identifierSchemeCodeUnspsc190501.50551901'),
	'identifierSchemeCodeUnspsc190501.50551902': __('identifierSchemeCodeUnspsc190501.50551902'),
	'identifierSchemeCodeUnspsc190501.50551903': __('identifierSchemeCodeUnspsc190501.50551903'),
	'identifierSchemeCodeUnspsc190501.50552000': __('identifierSchemeCodeUnspsc190501.50552000'),
	'identifierSchemeCodeUnspsc190501.50552001': __('identifierSchemeCodeUnspsc190501.50552001'),
	'identifierSchemeCodeUnspsc190501.50552002': __('identifierSchemeCodeUnspsc190501.50552002'),
	'identifierSchemeCodeUnspsc190501.50552003': __('identifierSchemeCodeUnspsc190501.50552003'),
	'identifierSchemeCodeUnspsc190501.50552004': __('identifierSchemeCodeUnspsc190501.50552004'),
	'identifierSchemeCodeUnspsc190501.50552005': __('identifierSchemeCodeUnspsc190501.50552005'),
	'identifierSchemeCodeUnspsc190501.50552006': __('identifierSchemeCodeUnspsc190501.50552006'),
	'identifierSchemeCodeUnspsc190501.50552007': __('identifierSchemeCodeUnspsc190501.50552007'),
	'identifierSchemeCodeUnspsc190501.50552008': __('identifierSchemeCodeUnspsc190501.50552008'),
	'identifierSchemeCodeUnspsc190501.50552009': __('identifierSchemeCodeUnspsc190501.50552009'),
	'identifierSchemeCodeUnspsc190501.50552010': __('identifierSchemeCodeUnspsc190501.50552010'),
	'identifierSchemeCodeUnspsc190501.50552011': __('identifierSchemeCodeUnspsc190501.50552011'),
	'identifierSchemeCodeUnspsc190501.50552012': __('identifierSchemeCodeUnspsc190501.50552012'),
	'identifierSchemeCodeUnspsc190501.50552013': __('identifierSchemeCodeUnspsc190501.50552013'),
	'identifierSchemeCodeUnspsc190501.50552014': __('identifierSchemeCodeUnspsc190501.50552014'),
	'identifierSchemeCodeUnspsc190501.50552015': __('identifierSchemeCodeUnspsc190501.50552015'),
	'identifierSchemeCodeUnspsc190501.50552100': __('identifierSchemeCodeUnspsc190501.50552100'),
	'identifierSchemeCodeUnspsc190501.50552101': __('identifierSchemeCodeUnspsc190501.50552101'),
	'identifierSchemeCodeUnspsc190501.50552102': __('identifierSchemeCodeUnspsc190501.50552102'),
	'identifierSchemeCodeUnspsc190501.50552103': __('identifierSchemeCodeUnspsc190501.50552103'),
	'identifierSchemeCodeUnspsc190501.50552104': __('identifierSchemeCodeUnspsc190501.50552104'),
	'identifierSchemeCodeUnspsc190501.50552200': __('identifierSchemeCodeUnspsc190501.50552200'),
	'identifierSchemeCodeUnspsc190501.50552201': __('identifierSchemeCodeUnspsc190501.50552201'),
	'identifierSchemeCodeUnspsc190501.50552202': __('identifierSchemeCodeUnspsc190501.50552202'),
	'identifierSchemeCodeUnspsc190501.50552203': __('identifierSchemeCodeUnspsc190501.50552203'),
	'identifierSchemeCodeUnspsc190501.50552204': __('identifierSchemeCodeUnspsc190501.50552204'),
	'identifierSchemeCodeUnspsc190501.50552205': __('identifierSchemeCodeUnspsc190501.50552205'),
	'identifierSchemeCodeUnspsc190501.50552206': __('identifierSchemeCodeUnspsc190501.50552206'),
	'identifierSchemeCodeUnspsc190501.50552207': __('identifierSchemeCodeUnspsc190501.50552207'),
	'identifierSchemeCodeUnspsc190501.50552208': __('identifierSchemeCodeUnspsc190501.50552208'),
	'identifierSchemeCodeUnspsc190501.50552209': __('identifierSchemeCodeUnspsc190501.50552209'),
	'identifierSchemeCodeUnspsc190501.50552210': __('identifierSchemeCodeUnspsc190501.50552210'),
	'identifierSchemeCodeUnspsc190501.50552211': __('identifierSchemeCodeUnspsc190501.50552211'),
	'identifierSchemeCodeUnspsc190501.50552212': __('identifierSchemeCodeUnspsc190501.50552212'),
	'identifierSchemeCodeUnspsc190501.50552213': __('identifierSchemeCodeUnspsc190501.50552213'),
	'identifierSchemeCodeUnspsc190501.50552214': __('identifierSchemeCodeUnspsc190501.50552214'),
	'identifierSchemeCodeUnspsc190501.50552215': __('identifierSchemeCodeUnspsc190501.50552215'),
	'identifierSchemeCodeUnspsc190501.50552300': __('identifierSchemeCodeUnspsc190501.50552300'),
	'identifierSchemeCodeUnspsc190501.50552301': __('identifierSchemeCodeUnspsc190501.50552301'),
	'identifierSchemeCodeUnspsc190501.50552302': __('identifierSchemeCodeUnspsc190501.50552302'),
	'identifierSchemeCodeUnspsc190501.50552303': __('identifierSchemeCodeUnspsc190501.50552303'),
	'identifierSchemeCodeUnspsc190501.50552304': __('identifierSchemeCodeUnspsc190501.50552304'),
	'identifierSchemeCodeUnspsc190501.50552400': __('identifierSchemeCodeUnspsc190501.50552400'),
	'identifierSchemeCodeUnspsc190501.50552401': __('identifierSchemeCodeUnspsc190501.50552401'),
	'identifierSchemeCodeUnspsc190501.50552402': __('identifierSchemeCodeUnspsc190501.50552402'),
	'identifierSchemeCodeUnspsc190501.50552403': __('identifierSchemeCodeUnspsc190501.50552403'),
	'identifierSchemeCodeUnspsc190501.50552404': __('identifierSchemeCodeUnspsc190501.50552404'),
	'identifierSchemeCodeUnspsc190501.50552405': __('identifierSchemeCodeUnspsc190501.50552405'),
	'identifierSchemeCodeUnspsc190501.50552406': __('identifierSchemeCodeUnspsc190501.50552406'),
	'identifierSchemeCodeUnspsc190501.50552407': __('identifierSchemeCodeUnspsc190501.50552407'),
	'identifierSchemeCodeUnspsc190501.50552408': __('identifierSchemeCodeUnspsc190501.50552408'),
	'identifierSchemeCodeUnspsc190501.50552409': __('identifierSchemeCodeUnspsc190501.50552409'),
	'identifierSchemeCodeUnspsc190501.50552500': __('identifierSchemeCodeUnspsc190501.50552500'),
	'identifierSchemeCodeUnspsc190501.50552501': __('identifierSchemeCodeUnspsc190501.50552501'),
	'identifierSchemeCodeUnspsc190501.50552502': __('identifierSchemeCodeUnspsc190501.50552502'),
	'identifierSchemeCodeUnspsc190501.50552503': __('identifierSchemeCodeUnspsc190501.50552503'),
	'identifierSchemeCodeUnspsc190501.50552504': __('identifierSchemeCodeUnspsc190501.50552504'),
	'identifierSchemeCodeUnspsc190501.50552505': __('identifierSchemeCodeUnspsc190501.50552505'),
	'identifierSchemeCodeUnspsc190501.50552506': __('identifierSchemeCodeUnspsc190501.50552506'),
	'identifierSchemeCodeUnspsc190501.50552507': __('identifierSchemeCodeUnspsc190501.50552507'),
	'identifierSchemeCodeUnspsc190501.50552508': __('identifierSchemeCodeUnspsc190501.50552508'),
	'identifierSchemeCodeUnspsc190501.50552509': __('identifierSchemeCodeUnspsc190501.50552509'),
	'identifierSchemeCodeUnspsc190501.50552510': __('identifierSchemeCodeUnspsc190501.50552510'),
	'identifierSchemeCodeUnspsc190501.50552511': __('identifierSchemeCodeUnspsc190501.50552511'),
	'identifierSchemeCodeUnspsc190501.50552512': __('identifierSchemeCodeUnspsc190501.50552512'),
	'identifierSchemeCodeUnspsc190501.50552513': __('identifierSchemeCodeUnspsc190501.50552513'),
	'identifierSchemeCodeUnspsc190501.50552514': __('identifierSchemeCodeUnspsc190501.50552514'),
	'identifierSchemeCodeUnspsc190501.50552515': __('identifierSchemeCodeUnspsc190501.50552515'),
	'identifierSchemeCodeUnspsc190501.50552516': __('identifierSchemeCodeUnspsc190501.50552516'),
	'identifierSchemeCodeUnspsc190501.50552517': __('identifierSchemeCodeUnspsc190501.50552517'),
	'identifierSchemeCodeUnspsc190501.50552518': __('identifierSchemeCodeUnspsc190501.50552518'),
	'identifierSchemeCodeUnspsc190501.50552519': __('identifierSchemeCodeUnspsc190501.50552519'),
	'identifierSchemeCodeUnspsc190501.50552520': __('identifierSchemeCodeUnspsc190501.50552520'),
	'identifierSchemeCodeUnspsc190501.50552521': __('identifierSchemeCodeUnspsc190501.50552521'),
	'identifierSchemeCodeUnspsc190501.50552522': __('identifierSchemeCodeUnspsc190501.50552522'),
	'identifierSchemeCodeUnspsc190501.50552523': __('identifierSchemeCodeUnspsc190501.50552523'),
	'identifierSchemeCodeUnspsc190501.50552524': __('identifierSchemeCodeUnspsc190501.50552524'),
	'identifierSchemeCodeUnspsc190501.50552525': __('identifierSchemeCodeUnspsc190501.50552525'),
	'identifierSchemeCodeUnspsc190501.50552526': __('identifierSchemeCodeUnspsc190501.50552526'),
	'identifierSchemeCodeUnspsc190501.50552527': __('identifierSchemeCodeUnspsc190501.50552527'),
	'identifierSchemeCodeUnspsc190501.50552528': __('identifierSchemeCodeUnspsc190501.50552528'),
	'identifierSchemeCodeUnspsc190501.50552529': __('identifierSchemeCodeUnspsc190501.50552529'),
	'identifierSchemeCodeUnspsc190501.50552530': __('identifierSchemeCodeUnspsc190501.50552530'),
	'identifierSchemeCodeUnspsc190501.50552531': __('identifierSchemeCodeUnspsc190501.50552531'),
	'identifierSchemeCodeUnspsc190501.50552600': __('identifierSchemeCodeUnspsc190501.50552600'),
	'identifierSchemeCodeUnspsc190501.50552601': __('identifierSchemeCodeUnspsc190501.50552601'),
	'identifierSchemeCodeUnspsc190501.50552602': __('identifierSchemeCodeUnspsc190501.50552602'),
	'identifierSchemeCodeUnspsc190501.50552603': __('identifierSchemeCodeUnspsc190501.50552603'),
	'identifierSchemeCodeUnspsc190501.50552604': __('identifierSchemeCodeUnspsc190501.50552604'),
	'identifierSchemeCodeUnspsc190501.50552605': __('identifierSchemeCodeUnspsc190501.50552605'),
	'identifierSchemeCodeUnspsc190501.50552606': __('identifierSchemeCodeUnspsc190501.50552606'),
	'identifierSchemeCodeUnspsc190501.50552607': __('identifierSchemeCodeUnspsc190501.50552607'),
	'identifierSchemeCodeUnspsc190501.50552700': __('identifierSchemeCodeUnspsc190501.50552700'),
	'identifierSchemeCodeUnspsc190501.50552701': __('identifierSchemeCodeUnspsc190501.50552701'),
	'identifierSchemeCodeUnspsc190501.50552702': __('identifierSchemeCodeUnspsc190501.50552702'),
	'identifierSchemeCodeUnspsc190501.50552703': __('identifierSchemeCodeUnspsc190501.50552703'),
	'identifierSchemeCodeUnspsc190501.50552704': __('identifierSchemeCodeUnspsc190501.50552704'),
	'identifierSchemeCodeUnspsc190501.50552705': __('identifierSchemeCodeUnspsc190501.50552705'),
	'identifierSchemeCodeUnspsc190501.50552706': __('identifierSchemeCodeUnspsc190501.50552706'),
	'identifierSchemeCodeUnspsc190501.50552707': __('identifierSchemeCodeUnspsc190501.50552707'),
	'identifierSchemeCodeUnspsc190501.50552708': __('identifierSchemeCodeUnspsc190501.50552708'),
	'identifierSchemeCodeUnspsc190501.50552709': __('identifierSchemeCodeUnspsc190501.50552709'),
	'identifierSchemeCodeUnspsc190501.50552710': __('identifierSchemeCodeUnspsc190501.50552710'),
	'identifierSchemeCodeUnspsc190501.50552800': __('identifierSchemeCodeUnspsc190501.50552800'),
	'identifierSchemeCodeUnspsc190501.50552801': __('identifierSchemeCodeUnspsc190501.50552801'),
	'identifierSchemeCodeUnspsc190501.50552802': __('identifierSchemeCodeUnspsc190501.50552802'),
	'identifierSchemeCodeUnspsc190501.50552803': __('identifierSchemeCodeUnspsc190501.50552803'),
	'identifierSchemeCodeUnspsc190501.50552804': __('identifierSchemeCodeUnspsc190501.50552804'),
	'identifierSchemeCodeUnspsc190501.50552805': __('identifierSchemeCodeUnspsc190501.50552805'),
	'identifierSchemeCodeUnspsc190501.50552806': __('identifierSchemeCodeUnspsc190501.50552806'),
	'identifierSchemeCodeUnspsc190501.50552900': __('identifierSchemeCodeUnspsc190501.50552900'),
	'identifierSchemeCodeUnspsc190501.50552901': __('identifierSchemeCodeUnspsc190501.50552901'),
	'identifierSchemeCodeUnspsc190501.50552902': __('identifierSchemeCodeUnspsc190501.50552902'),
	'identifierSchemeCodeUnspsc190501.50552903': __('identifierSchemeCodeUnspsc190501.50552903'),
	'identifierSchemeCodeUnspsc190501.50552904': __('identifierSchemeCodeUnspsc190501.50552904'),
	'identifierSchemeCodeUnspsc190501.50552905': __('identifierSchemeCodeUnspsc190501.50552905'),
	'identifierSchemeCodeUnspsc190501.50552906': __('identifierSchemeCodeUnspsc190501.50552906'),
	'identifierSchemeCodeUnspsc190501.50552907': __('identifierSchemeCodeUnspsc190501.50552907'),
	'identifierSchemeCodeUnspsc190501.50552908': __('identifierSchemeCodeUnspsc190501.50552908'),
	'identifierSchemeCodeUnspsc190501.50552909': __('identifierSchemeCodeUnspsc190501.50552909'),
	'identifierSchemeCodeUnspsc190501.50552910': __('identifierSchemeCodeUnspsc190501.50552910'),
	'identifierSchemeCodeUnspsc190501.50552911': __('identifierSchemeCodeUnspsc190501.50552911'),
	'identifierSchemeCodeUnspsc190501.50552912': __('identifierSchemeCodeUnspsc190501.50552912'),
	'identifierSchemeCodeUnspsc190501.50552913': __('identifierSchemeCodeUnspsc190501.50552913'),
	'identifierSchemeCodeUnspsc190501.50552914': __('identifierSchemeCodeUnspsc190501.50552914'),
	'identifierSchemeCodeUnspsc190501.50552915': __('identifierSchemeCodeUnspsc190501.50552915'),
	'identifierSchemeCodeUnspsc190501.50552916': __('identifierSchemeCodeUnspsc190501.50552916'),
	'identifierSchemeCodeUnspsc190501.50553000': __('identifierSchemeCodeUnspsc190501.50553000'),
	'identifierSchemeCodeUnspsc190501.50553001': __('identifierSchemeCodeUnspsc190501.50553001'),
	'identifierSchemeCodeUnspsc190501.50553002': __('identifierSchemeCodeUnspsc190501.50553002'),
	'identifierSchemeCodeUnspsc190501.50553100': __('identifierSchemeCodeUnspsc190501.50553100'),
	'identifierSchemeCodeUnspsc190501.50553101': __('identifierSchemeCodeUnspsc190501.50553101'),
	'identifierSchemeCodeUnspsc190501.50553102': __('identifierSchemeCodeUnspsc190501.50553102'),
	'identifierSchemeCodeUnspsc190501.50553103': __('identifierSchemeCodeUnspsc190501.50553103'),
	'identifierSchemeCodeUnspsc190501.50553104': __('identifierSchemeCodeUnspsc190501.50553104'),
	'identifierSchemeCodeUnspsc190501.50553105': __('identifierSchemeCodeUnspsc190501.50553105'),
	'identifierSchemeCodeUnspsc190501.50553106': __('identifierSchemeCodeUnspsc190501.50553106'),
	'identifierSchemeCodeUnspsc190501.50553107': __('identifierSchemeCodeUnspsc190501.50553107'),
	'identifierSchemeCodeUnspsc190501.50553108': __('identifierSchemeCodeUnspsc190501.50553108'),
	'identifierSchemeCodeUnspsc190501.50553109': __('identifierSchemeCodeUnspsc190501.50553109'),
	'identifierSchemeCodeUnspsc190501.50553110': __('identifierSchemeCodeUnspsc190501.50553110'),
	'identifierSchemeCodeUnspsc190501.50553111': __('identifierSchemeCodeUnspsc190501.50553111'),
	'identifierSchemeCodeUnspsc190501.50553200': __('identifierSchemeCodeUnspsc190501.50553200'),
	'identifierSchemeCodeUnspsc190501.50553201': __('identifierSchemeCodeUnspsc190501.50553201'),
	'identifierSchemeCodeUnspsc190501.50553202': __('identifierSchemeCodeUnspsc190501.50553202'),
	'identifierSchemeCodeUnspsc190501.50553203': __('identifierSchemeCodeUnspsc190501.50553203'),
	'identifierSchemeCodeUnspsc190501.50553204': __('identifierSchemeCodeUnspsc190501.50553204'),
	'identifierSchemeCodeUnspsc190501.50553205': __('identifierSchemeCodeUnspsc190501.50553205'),
	'identifierSchemeCodeUnspsc190501.50553206': __('identifierSchemeCodeUnspsc190501.50553206'),
	'identifierSchemeCodeUnspsc190501.50553207': __('identifierSchemeCodeUnspsc190501.50553207'),
	'identifierSchemeCodeUnspsc190501.50553300': __('identifierSchemeCodeUnspsc190501.50553300'),
	'identifierSchemeCodeUnspsc190501.50553301': __('identifierSchemeCodeUnspsc190501.50553301'),
	'identifierSchemeCodeUnspsc190501.50553302': __('identifierSchemeCodeUnspsc190501.50553302'),
	'identifierSchemeCodeUnspsc190501.50553303': __('identifierSchemeCodeUnspsc190501.50553303'),
	'identifierSchemeCodeUnspsc190501.50553304': __('identifierSchemeCodeUnspsc190501.50553304'),
	'identifierSchemeCodeUnspsc190501.50553305': __('identifierSchemeCodeUnspsc190501.50553305'),
	'identifierSchemeCodeUnspsc190501.50553306': __('identifierSchemeCodeUnspsc190501.50553306'),
	'identifierSchemeCodeUnspsc190501.50553307': __('identifierSchemeCodeUnspsc190501.50553307'),
	'identifierSchemeCodeUnspsc190501.50553308': __('identifierSchemeCodeUnspsc190501.50553308'),
	'identifierSchemeCodeUnspsc190501.50553309': __('identifierSchemeCodeUnspsc190501.50553309'),
	'identifierSchemeCodeUnspsc190501.50553400': __('identifierSchemeCodeUnspsc190501.50553400'),
	'identifierSchemeCodeUnspsc190501.50553401': __('identifierSchemeCodeUnspsc190501.50553401'),
	'identifierSchemeCodeUnspsc190501.50553402': __('identifierSchemeCodeUnspsc190501.50553402'),
	'identifierSchemeCodeUnspsc190501.50553403': __('identifierSchemeCodeUnspsc190501.50553403'),
	'identifierSchemeCodeUnspsc190501.50553404': __('identifierSchemeCodeUnspsc190501.50553404'),
	'identifierSchemeCodeUnspsc190501.50553405': __('identifierSchemeCodeUnspsc190501.50553405'),
	'identifierSchemeCodeUnspsc190501.50553406': __('identifierSchemeCodeUnspsc190501.50553406'),
	'identifierSchemeCodeUnspsc190501.50553407': __('identifierSchemeCodeUnspsc190501.50553407'),
	'identifierSchemeCodeUnspsc190501.50553408': __('identifierSchemeCodeUnspsc190501.50553408'),
	'identifierSchemeCodeUnspsc190501.50553409': __('identifierSchemeCodeUnspsc190501.50553409'),
	'identifierSchemeCodeUnspsc190501.50553410': __('identifierSchemeCodeUnspsc190501.50553410'),
	'identifierSchemeCodeUnspsc190501.50553411': __('identifierSchemeCodeUnspsc190501.50553411'),
	'identifierSchemeCodeUnspsc190501.50553412': __('identifierSchemeCodeUnspsc190501.50553412'),
	'identifierSchemeCodeUnspsc190501.50553413': __('identifierSchemeCodeUnspsc190501.50553413'),
	'identifierSchemeCodeUnspsc190501.50553414': __('identifierSchemeCodeUnspsc190501.50553414'),
	'identifierSchemeCodeUnspsc190501.50553415': __('identifierSchemeCodeUnspsc190501.50553415'),
	'identifierSchemeCodeUnspsc190501.50553416': __('identifierSchemeCodeUnspsc190501.50553416'),
	'identifierSchemeCodeUnspsc190501.50553417': __('identifierSchemeCodeUnspsc190501.50553417'),
	'identifierSchemeCodeUnspsc190501.50553418': __('identifierSchemeCodeUnspsc190501.50553418'),
	'identifierSchemeCodeUnspsc190501.50553419': __('identifierSchemeCodeUnspsc190501.50553419'),
	'identifierSchemeCodeUnspsc190501.50553420': __('identifierSchemeCodeUnspsc190501.50553420'),
	'identifierSchemeCodeUnspsc190501.50553421': __('identifierSchemeCodeUnspsc190501.50553421'),
	'identifierSchemeCodeUnspsc190501.50553422': __('identifierSchemeCodeUnspsc190501.50553422'),
	'identifierSchemeCodeUnspsc190501.50553423': __('identifierSchemeCodeUnspsc190501.50553423'),
	'identifierSchemeCodeUnspsc190501.50553424': __('identifierSchemeCodeUnspsc190501.50553424'),
	'identifierSchemeCodeUnspsc190501.50553425': __('identifierSchemeCodeUnspsc190501.50553425'),
	'identifierSchemeCodeUnspsc190501.50553426': __('identifierSchemeCodeUnspsc190501.50553426'),
	'identifierSchemeCodeUnspsc190501.50553427': __('identifierSchemeCodeUnspsc190501.50553427'),
	'identifierSchemeCodeUnspsc190501.50553428': __('identifierSchemeCodeUnspsc190501.50553428'),
	'identifierSchemeCodeUnspsc190501.50553429': __('identifierSchemeCodeUnspsc190501.50553429'),
	'identifierSchemeCodeUnspsc190501.50553430': __('identifierSchemeCodeUnspsc190501.50553430'),
	'identifierSchemeCodeUnspsc190501.50553431': __('identifierSchemeCodeUnspsc190501.50553431'),
	'identifierSchemeCodeUnspsc190501.50553432': __('identifierSchemeCodeUnspsc190501.50553432'),
	'identifierSchemeCodeUnspsc190501.50553433': __('identifierSchemeCodeUnspsc190501.50553433'),
	'identifierSchemeCodeUnspsc190501.50553434': __('identifierSchemeCodeUnspsc190501.50553434'),
	'identifierSchemeCodeUnspsc190501.50553435': __('identifierSchemeCodeUnspsc190501.50553435'),
	'identifierSchemeCodeUnspsc190501.50553436': __('identifierSchemeCodeUnspsc190501.50553436'),
	'identifierSchemeCodeUnspsc190501.50553437': __('identifierSchemeCodeUnspsc190501.50553437'),
	'identifierSchemeCodeUnspsc190501.50553438': __('identifierSchemeCodeUnspsc190501.50553438'),
	'identifierSchemeCodeUnspsc190501.50553439': __('identifierSchemeCodeUnspsc190501.50553439'),
	'identifierSchemeCodeUnspsc190501.50553440': __('identifierSchemeCodeUnspsc190501.50553440'),
	'identifierSchemeCodeUnspsc190501.50553441': __('identifierSchemeCodeUnspsc190501.50553441'),
	'identifierSchemeCodeUnspsc190501.50553442': __('identifierSchemeCodeUnspsc190501.50553442'),
	'identifierSchemeCodeUnspsc190501.50553443': __('identifierSchemeCodeUnspsc190501.50553443'),
	'identifierSchemeCodeUnspsc190501.50553444': __('identifierSchemeCodeUnspsc190501.50553444'),
	'identifierSchemeCodeUnspsc190501.50553445': __('identifierSchemeCodeUnspsc190501.50553445'),
	'identifierSchemeCodeUnspsc190501.50553446': __('identifierSchemeCodeUnspsc190501.50553446'),
	'identifierSchemeCodeUnspsc190501.50553447': __('identifierSchemeCodeUnspsc190501.50553447'),
	'identifierSchemeCodeUnspsc190501.50553448': __('identifierSchemeCodeUnspsc190501.50553448'),
	'identifierSchemeCodeUnspsc190501.50553449': __('identifierSchemeCodeUnspsc190501.50553449'),
	'identifierSchemeCodeUnspsc190501.50553450': __('identifierSchemeCodeUnspsc190501.50553450'),
	'identifierSchemeCodeUnspsc190501.50553451': __('identifierSchemeCodeUnspsc190501.50553451'),
	'identifierSchemeCodeUnspsc190501.50553452': __('identifierSchemeCodeUnspsc190501.50553452'),
	'identifierSchemeCodeUnspsc190501.50553453': __('identifierSchemeCodeUnspsc190501.50553453'),
	'identifierSchemeCodeUnspsc190501.50553454': __('identifierSchemeCodeUnspsc190501.50553454'),
	'identifierSchemeCodeUnspsc190501.50553455': __('identifierSchemeCodeUnspsc190501.50553455'),
	'identifierSchemeCodeUnspsc190501.50553456': __('identifierSchemeCodeUnspsc190501.50553456'),
	'identifierSchemeCodeUnspsc190501.50553457': __('identifierSchemeCodeUnspsc190501.50553457'),
	'identifierSchemeCodeUnspsc190501.50553458': __('identifierSchemeCodeUnspsc190501.50553458'),
	'identifierSchemeCodeUnspsc190501.50553459': __('identifierSchemeCodeUnspsc190501.50553459'),
	'identifierSchemeCodeUnspsc190501.50553460': __('identifierSchemeCodeUnspsc190501.50553460'),
	'identifierSchemeCodeUnspsc190501.50553461': __('identifierSchemeCodeUnspsc190501.50553461'),
	'identifierSchemeCodeUnspsc190501.50553462': __('identifierSchemeCodeUnspsc190501.50553462'),
	'identifierSchemeCodeUnspsc190501.50553463': __('identifierSchemeCodeUnspsc190501.50553463'),
	'identifierSchemeCodeUnspsc190501.50553464': __('identifierSchemeCodeUnspsc190501.50553464'),
	'identifierSchemeCodeUnspsc190501.50553465': __('identifierSchemeCodeUnspsc190501.50553465'),
	'identifierSchemeCodeUnspsc190501.50553466': __('identifierSchemeCodeUnspsc190501.50553466'),
	'identifierSchemeCodeUnspsc190501.50553467': __('identifierSchemeCodeUnspsc190501.50553467'),
	'identifierSchemeCodeUnspsc190501.50553468': __('identifierSchemeCodeUnspsc190501.50553468'),
	'identifierSchemeCodeUnspsc190501.50553469': __('identifierSchemeCodeUnspsc190501.50553469'),
	'identifierSchemeCodeUnspsc190501.50553470': __('identifierSchemeCodeUnspsc190501.50553470'),
	'identifierSchemeCodeUnspsc190501.50553471': __('identifierSchemeCodeUnspsc190501.50553471'),
	'identifierSchemeCodeUnspsc190501.50553472': __('identifierSchemeCodeUnspsc190501.50553472'),
	'identifierSchemeCodeUnspsc190501.50553473': __('identifierSchemeCodeUnspsc190501.50553473'),
	'identifierSchemeCodeUnspsc190501.50553474': __('identifierSchemeCodeUnspsc190501.50553474'),
	'identifierSchemeCodeUnspsc190501.50553475': __('identifierSchemeCodeUnspsc190501.50553475'),
	'identifierSchemeCodeUnspsc190501.50553476': __('identifierSchemeCodeUnspsc190501.50553476'),
	'identifierSchemeCodeUnspsc190501.50553477': __('identifierSchemeCodeUnspsc190501.50553477'),
	'identifierSchemeCodeUnspsc190501.50553478': __('identifierSchemeCodeUnspsc190501.50553478'),
	'identifierSchemeCodeUnspsc190501.50553479': __('identifierSchemeCodeUnspsc190501.50553479'),
	'identifierSchemeCodeUnspsc190501.50553480': __('identifierSchemeCodeUnspsc190501.50553480'),
	'identifierSchemeCodeUnspsc190501.50553481': __('identifierSchemeCodeUnspsc190501.50553481'),
	'identifierSchemeCodeUnspsc190501.50553482': __('identifierSchemeCodeUnspsc190501.50553482'),
	'identifierSchemeCodeUnspsc190501.50553483': __('identifierSchemeCodeUnspsc190501.50553483'),
	'identifierSchemeCodeUnspsc190501.50553484': __('identifierSchemeCodeUnspsc190501.50553484'),
	'identifierSchemeCodeUnspsc190501.50553485': __('identifierSchemeCodeUnspsc190501.50553485'),
	'identifierSchemeCodeUnspsc190501.50553486': __('identifierSchemeCodeUnspsc190501.50553486'),
	'identifierSchemeCodeUnspsc190501.50553487': __('identifierSchemeCodeUnspsc190501.50553487'),
	'identifierSchemeCodeUnspsc190501.50553488': __('identifierSchemeCodeUnspsc190501.50553488'),
	'identifierSchemeCodeUnspsc190501.50553489': __('identifierSchemeCodeUnspsc190501.50553489'),
	'identifierSchemeCodeUnspsc190501.50553490': __('identifierSchemeCodeUnspsc190501.50553490'),
	'identifierSchemeCodeUnspsc190501.50553491': __('identifierSchemeCodeUnspsc190501.50553491'),
	'identifierSchemeCodeUnspsc190501.50553492': __('identifierSchemeCodeUnspsc190501.50553492'),
	'identifierSchemeCodeUnspsc190501.50553493': __('identifierSchemeCodeUnspsc190501.50553493'),
	'identifierSchemeCodeUnspsc190501.50553494': __('identifierSchemeCodeUnspsc190501.50553494'),
	'identifierSchemeCodeUnspsc190501.50553495': __('identifierSchemeCodeUnspsc190501.50553495'),
	'identifierSchemeCodeUnspsc190501.50553496': __('identifierSchemeCodeUnspsc190501.50553496'),
	'identifierSchemeCodeUnspsc190501.50553500': __('identifierSchemeCodeUnspsc190501.50553500'),
	'identifierSchemeCodeUnspsc190501.50553501': __('identifierSchemeCodeUnspsc190501.50553501'),
	'identifierSchemeCodeUnspsc190501.50553502': __('identifierSchemeCodeUnspsc190501.50553502'),
	'identifierSchemeCodeUnspsc190501.50553503': __('identifierSchemeCodeUnspsc190501.50553503'),
	'identifierSchemeCodeUnspsc190501.50553504': __('identifierSchemeCodeUnspsc190501.50553504'),
	'identifierSchemeCodeUnspsc190501.50553505': __('identifierSchemeCodeUnspsc190501.50553505'),
	'identifierSchemeCodeUnspsc190501.50553506': __('identifierSchemeCodeUnspsc190501.50553506'),
	'identifierSchemeCodeUnspsc190501.50553600': __('identifierSchemeCodeUnspsc190501.50553600'),
	'identifierSchemeCodeUnspsc190501.50553601': __('identifierSchemeCodeUnspsc190501.50553601'),
	'identifierSchemeCodeUnspsc190501.50553602': __('identifierSchemeCodeUnspsc190501.50553602'),
	'identifierSchemeCodeUnspsc190501.50553603': __('identifierSchemeCodeUnspsc190501.50553603'),
	'identifierSchemeCodeUnspsc190501.50553604': __('identifierSchemeCodeUnspsc190501.50553604'),
	'identifierSchemeCodeUnspsc190501.50553605': __('identifierSchemeCodeUnspsc190501.50553605'),
	'identifierSchemeCodeUnspsc190501.50553606': __('identifierSchemeCodeUnspsc190501.50553606'),
	'identifierSchemeCodeUnspsc190501.50553607': __('identifierSchemeCodeUnspsc190501.50553607'),
	'identifierSchemeCodeUnspsc190501.50553608': __('identifierSchemeCodeUnspsc190501.50553608'),
	'identifierSchemeCodeUnspsc190501.50553609': __('identifierSchemeCodeUnspsc190501.50553609'),
	'identifierSchemeCodeUnspsc190501.50553610': __('identifierSchemeCodeUnspsc190501.50553610'),
	'identifierSchemeCodeUnspsc190501.50553611': __('identifierSchemeCodeUnspsc190501.50553611'),
	'identifierSchemeCodeUnspsc190501.50553612': __('identifierSchemeCodeUnspsc190501.50553612'),
	'identifierSchemeCodeUnspsc190501.50553613': __('identifierSchemeCodeUnspsc190501.50553613'),
	'identifierSchemeCodeUnspsc190501.50553614': __('identifierSchemeCodeUnspsc190501.50553614'),
	'identifierSchemeCodeUnspsc190501.50553615': __('identifierSchemeCodeUnspsc190501.50553615'),
	'identifierSchemeCodeUnspsc190501.50553616': __('identifierSchemeCodeUnspsc190501.50553616'),
	'identifierSchemeCodeUnspsc190501.50553617': __('identifierSchemeCodeUnspsc190501.50553617'),
	'identifierSchemeCodeUnspsc190501.50553618': __('identifierSchemeCodeUnspsc190501.50553618'),
	'identifierSchemeCodeUnspsc190501.50553619': __('identifierSchemeCodeUnspsc190501.50553619'),
	'identifierSchemeCodeUnspsc190501.50553620': __('identifierSchemeCodeUnspsc190501.50553620'),
	'identifierSchemeCodeUnspsc190501.50553621': __('identifierSchemeCodeUnspsc190501.50553621'),
	'identifierSchemeCodeUnspsc190501.50553622': __('identifierSchemeCodeUnspsc190501.50553622'),
	'identifierSchemeCodeUnspsc190501.50553623': __('identifierSchemeCodeUnspsc190501.50553623'),
	'identifierSchemeCodeUnspsc190501.50553624': __('identifierSchemeCodeUnspsc190501.50553624'),
	'identifierSchemeCodeUnspsc190501.50553625': __('identifierSchemeCodeUnspsc190501.50553625'),
	'identifierSchemeCodeUnspsc190501.50553626': __('identifierSchemeCodeUnspsc190501.50553626'),
	'identifierSchemeCodeUnspsc190501.50553627': __('identifierSchemeCodeUnspsc190501.50553627'),
	'identifierSchemeCodeUnspsc190501.50553628': __('identifierSchemeCodeUnspsc190501.50553628'),
	'identifierSchemeCodeUnspsc190501.50553629': __('identifierSchemeCodeUnspsc190501.50553629'),
	'identifierSchemeCodeUnspsc190501.50553630': __('identifierSchemeCodeUnspsc190501.50553630'),
	'identifierSchemeCodeUnspsc190501.50553631': __('identifierSchemeCodeUnspsc190501.50553631'),
	'identifierSchemeCodeUnspsc190501.50553632': __('identifierSchemeCodeUnspsc190501.50553632'),
	'identifierSchemeCodeUnspsc190501.50553633': __('identifierSchemeCodeUnspsc190501.50553633'),
	'identifierSchemeCodeUnspsc190501.50553634': __('identifierSchemeCodeUnspsc190501.50553634'),
	'identifierSchemeCodeUnspsc190501.50553635': __('identifierSchemeCodeUnspsc190501.50553635'),
	'identifierSchemeCodeUnspsc190501.50553636': __('identifierSchemeCodeUnspsc190501.50553636'),
	'identifierSchemeCodeUnspsc190501.50553637': __('identifierSchemeCodeUnspsc190501.50553637'),
	'identifierSchemeCodeUnspsc190501.50553638': __('identifierSchemeCodeUnspsc190501.50553638'),
	'identifierSchemeCodeUnspsc190501.50553639': __('identifierSchemeCodeUnspsc190501.50553639'),
	'identifierSchemeCodeUnspsc190501.50553640': __('identifierSchemeCodeUnspsc190501.50553640'),
	'identifierSchemeCodeUnspsc190501.50553641': __('identifierSchemeCodeUnspsc190501.50553641'),
	'identifierSchemeCodeUnspsc190501.50553642': __('identifierSchemeCodeUnspsc190501.50553642'),
	'identifierSchemeCodeUnspsc190501.50553643': __('identifierSchemeCodeUnspsc190501.50553643'),
	'identifierSchemeCodeUnspsc190501.50553644': __('identifierSchemeCodeUnspsc190501.50553644'),
	'identifierSchemeCodeUnspsc190501.50553645': __('identifierSchemeCodeUnspsc190501.50553645'),
	'identifierSchemeCodeUnspsc190501.50553646': __('identifierSchemeCodeUnspsc190501.50553646'),
	'identifierSchemeCodeUnspsc190501.50553647': __('identifierSchemeCodeUnspsc190501.50553647'),
	'identifierSchemeCodeUnspsc190501.50553648': __('identifierSchemeCodeUnspsc190501.50553648'),
	'identifierSchemeCodeUnspsc190501.50553649': __('identifierSchemeCodeUnspsc190501.50553649'),
	'identifierSchemeCodeUnspsc190501.50553650': __('identifierSchemeCodeUnspsc190501.50553650'),
	'identifierSchemeCodeUnspsc190501.50553651': __('identifierSchemeCodeUnspsc190501.50553651'),
	'identifierSchemeCodeUnspsc190501.50553652': __('identifierSchemeCodeUnspsc190501.50553652'),
	'identifierSchemeCodeUnspsc190501.50553653': __('identifierSchemeCodeUnspsc190501.50553653'),
	'identifierSchemeCodeUnspsc190501.50553654': __('identifierSchemeCodeUnspsc190501.50553654'),
	'identifierSchemeCodeUnspsc190501.50553655': __('identifierSchemeCodeUnspsc190501.50553655'),
	'identifierSchemeCodeUnspsc190501.50553656': __('identifierSchemeCodeUnspsc190501.50553656'),
	'identifierSchemeCodeUnspsc190501.50553657': __('identifierSchemeCodeUnspsc190501.50553657'),
	'identifierSchemeCodeUnspsc190501.50553658': __('identifierSchemeCodeUnspsc190501.50553658'),
	'identifierSchemeCodeUnspsc190501.50553659': __('identifierSchemeCodeUnspsc190501.50553659'),
	'identifierSchemeCodeUnspsc190501.50553660': __('identifierSchemeCodeUnspsc190501.50553660'),
	'identifierSchemeCodeUnspsc190501.50553661': __('identifierSchemeCodeUnspsc190501.50553661'),
	'identifierSchemeCodeUnspsc190501.50553662': __('identifierSchemeCodeUnspsc190501.50553662'),
	'identifierSchemeCodeUnspsc190501.50553663': __('identifierSchemeCodeUnspsc190501.50553663'),
	'identifierSchemeCodeUnspsc190501.50553664': __('identifierSchemeCodeUnspsc190501.50553664'),
	'identifierSchemeCodeUnspsc190501.50553665': __('identifierSchemeCodeUnspsc190501.50553665'),
	'identifierSchemeCodeUnspsc190501.50553700': __('identifierSchemeCodeUnspsc190501.50553700'),
	'identifierSchemeCodeUnspsc190501.50553701': __('identifierSchemeCodeUnspsc190501.50553701'),
	'identifierSchemeCodeUnspsc190501.50553702': __('identifierSchemeCodeUnspsc190501.50553702'),
	'identifierSchemeCodeUnspsc190501.50553703': __('identifierSchemeCodeUnspsc190501.50553703'),
	'identifierSchemeCodeUnspsc190501.50553704': __('identifierSchemeCodeUnspsc190501.50553704'),
	'identifierSchemeCodeUnspsc190501.50553800': __('identifierSchemeCodeUnspsc190501.50553800'),
	'identifierSchemeCodeUnspsc190501.50553801': __('identifierSchemeCodeUnspsc190501.50553801'),
	'identifierSchemeCodeUnspsc190501.50553802': __('identifierSchemeCodeUnspsc190501.50553802'),
	'identifierSchemeCodeUnspsc190501.50553803': __('identifierSchemeCodeUnspsc190501.50553803'),
	'identifierSchemeCodeUnspsc190501.50553804': __('identifierSchemeCodeUnspsc190501.50553804'),
	'identifierSchemeCodeUnspsc190501.50553805': __('identifierSchemeCodeUnspsc190501.50553805'),
	'identifierSchemeCodeUnspsc190501.50553900': __('identifierSchemeCodeUnspsc190501.50553900'),
	'identifierSchemeCodeUnspsc190501.50553901': __('identifierSchemeCodeUnspsc190501.50553901'),
	'identifierSchemeCodeUnspsc190501.50553902': __('identifierSchemeCodeUnspsc190501.50553902'),
	'identifierSchemeCodeUnspsc190501.50553903': __('identifierSchemeCodeUnspsc190501.50553903'),
	'identifierSchemeCodeUnspsc190501.50553904': __('identifierSchemeCodeUnspsc190501.50553904'),
	'identifierSchemeCodeUnspsc190501.50553905': __('identifierSchemeCodeUnspsc190501.50553905'),
	'identifierSchemeCodeUnspsc190501.50553906': __('identifierSchemeCodeUnspsc190501.50553906'),
	'identifierSchemeCodeUnspsc190501.50554000': __('identifierSchemeCodeUnspsc190501.50554000'),
	'identifierSchemeCodeUnspsc190501.50554001': __('identifierSchemeCodeUnspsc190501.50554001'),
	'identifierSchemeCodeUnspsc190501.50554002': __('identifierSchemeCodeUnspsc190501.50554002'),
	'identifierSchemeCodeUnspsc190501.50554003': __('identifierSchemeCodeUnspsc190501.50554003'),
	'identifierSchemeCodeUnspsc190501.50554004': __('identifierSchemeCodeUnspsc190501.50554004'),
	'identifierSchemeCodeUnspsc190501.50554005': __('identifierSchemeCodeUnspsc190501.50554005'),
	'identifierSchemeCodeUnspsc190501.50554006': __('identifierSchemeCodeUnspsc190501.50554006'),
	'identifierSchemeCodeUnspsc190501.50554100': __('identifierSchemeCodeUnspsc190501.50554100'),
	'identifierSchemeCodeUnspsc190501.50554101': __('identifierSchemeCodeUnspsc190501.50554101'),
	'identifierSchemeCodeUnspsc190501.50554102': __('identifierSchemeCodeUnspsc190501.50554102'),
	'identifierSchemeCodeUnspsc190501.50554103': __('identifierSchemeCodeUnspsc190501.50554103'),
	'identifierSchemeCodeUnspsc190501.50554104': __('identifierSchemeCodeUnspsc190501.50554104'),
	'identifierSchemeCodeUnspsc190501.50554105': __('identifierSchemeCodeUnspsc190501.50554105'),
	'identifierSchemeCodeUnspsc190501.50554106': __('identifierSchemeCodeUnspsc190501.50554106'),
	'identifierSchemeCodeUnspsc190501.50554107': __('identifierSchemeCodeUnspsc190501.50554107'),
	'identifierSchemeCodeUnspsc190501.50554108': __('identifierSchemeCodeUnspsc190501.50554108'),
	'identifierSchemeCodeUnspsc190501.50554200': __('identifierSchemeCodeUnspsc190501.50554200'),
	'identifierSchemeCodeUnspsc190501.50554201': __('identifierSchemeCodeUnspsc190501.50554201'),
	'identifierSchemeCodeUnspsc190501.50554202': __('identifierSchemeCodeUnspsc190501.50554202'),
	'identifierSchemeCodeUnspsc190501.50554203': __('identifierSchemeCodeUnspsc190501.50554203'),
	'identifierSchemeCodeUnspsc190501.50554204': __('identifierSchemeCodeUnspsc190501.50554204'),
	'identifierSchemeCodeUnspsc190501.50554205': __('identifierSchemeCodeUnspsc190501.50554205'),
	'identifierSchemeCodeUnspsc190501.50554206': __('identifierSchemeCodeUnspsc190501.50554206'),
	'identifierSchemeCodeUnspsc190501.50554207': __('identifierSchemeCodeUnspsc190501.50554207'),
	'identifierSchemeCodeUnspsc190501.50554208': __('identifierSchemeCodeUnspsc190501.50554208'),
	'identifierSchemeCodeUnspsc190501.50554300': __('identifierSchemeCodeUnspsc190501.50554300'),
	'identifierSchemeCodeUnspsc190501.50554301': __('identifierSchemeCodeUnspsc190501.50554301'),
	'identifierSchemeCodeUnspsc190501.50554302': __('identifierSchemeCodeUnspsc190501.50554302'),
	'identifierSchemeCodeUnspsc190501.50554303': __('identifierSchemeCodeUnspsc190501.50554303'),
	'identifierSchemeCodeUnspsc190501.50554304': __('identifierSchemeCodeUnspsc190501.50554304'),
	'identifierSchemeCodeUnspsc190501.50554305': __('identifierSchemeCodeUnspsc190501.50554305'),
	'identifierSchemeCodeUnspsc190501.50554306': __('identifierSchemeCodeUnspsc190501.50554306'),
	'identifierSchemeCodeUnspsc190501.50554307': __('identifierSchemeCodeUnspsc190501.50554307'),
	'identifierSchemeCodeUnspsc190501.50554308': __('identifierSchemeCodeUnspsc190501.50554308'),
	'identifierSchemeCodeUnspsc190501.50554309': __('identifierSchemeCodeUnspsc190501.50554309'),
	'identifierSchemeCodeUnspsc190501.50554310': __('identifierSchemeCodeUnspsc190501.50554310'),
	'identifierSchemeCodeUnspsc190501.50554311': __('identifierSchemeCodeUnspsc190501.50554311'),
	'identifierSchemeCodeUnspsc190501.50554312': __('identifierSchemeCodeUnspsc190501.50554312'),
	'identifierSchemeCodeUnspsc190501.50554313': __('identifierSchemeCodeUnspsc190501.50554313'),
	'identifierSchemeCodeUnspsc190501.50554400': __('identifierSchemeCodeUnspsc190501.50554400'),
	'identifierSchemeCodeUnspsc190501.50554401': __('identifierSchemeCodeUnspsc190501.50554401'),
	'identifierSchemeCodeUnspsc190501.50554402': __('identifierSchemeCodeUnspsc190501.50554402'),
	'identifierSchemeCodeUnspsc190501.50554403': __('identifierSchemeCodeUnspsc190501.50554403'),
	'identifierSchemeCodeUnspsc190501.50554404': __('identifierSchemeCodeUnspsc190501.50554404'),
	'identifierSchemeCodeUnspsc190501.50554405': __('identifierSchemeCodeUnspsc190501.50554405'),
	'identifierSchemeCodeUnspsc190501.50554406': __('identifierSchemeCodeUnspsc190501.50554406'),
	'identifierSchemeCodeUnspsc190501.50554407': __('identifierSchemeCodeUnspsc190501.50554407'),
	'identifierSchemeCodeUnspsc190501.50554408': __('identifierSchemeCodeUnspsc190501.50554408'),
	'identifierSchemeCodeUnspsc190501.50554409': __('identifierSchemeCodeUnspsc190501.50554409'),
	'identifierSchemeCodeUnspsc190501.50554410': __('identifierSchemeCodeUnspsc190501.50554410'),
	'identifierSchemeCodeUnspsc190501.50554411': __('identifierSchemeCodeUnspsc190501.50554411'),
	'identifierSchemeCodeUnspsc190501.50554412': __('identifierSchemeCodeUnspsc190501.50554412'),
	'identifierSchemeCodeUnspsc190501.50554413': __('identifierSchemeCodeUnspsc190501.50554413'),
	'identifierSchemeCodeUnspsc190501.50554414': __('identifierSchemeCodeUnspsc190501.50554414'),
	'identifierSchemeCodeUnspsc190501.50554415': __('identifierSchemeCodeUnspsc190501.50554415'),
	'identifierSchemeCodeUnspsc190501.50554416': __('identifierSchemeCodeUnspsc190501.50554416'),
	'identifierSchemeCodeUnspsc190501.50554417': __('identifierSchemeCodeUnspsc190501.50554417'),
	'identifierSchemeCodeUnspsc190501.50554418': __('identifierSchemeCodeUnspsc190501.50554418'),
	'identifierSchemeCodeUnspsc190501.50554419': __('identifierSchemeCodeUnspsc190501.50554419'),
	'identifierSchemeCodeUnspsc190501.50554420': __('identifierSchemeCodeUnspsc190501.50554420'),
	'identifierSchemeCodeUnspsc190501.50554421': __('identifierSchemeCodeUnspsc190501.50554421'),
	'identifierSchemeCodeUnspsc190501.50554422': __('identifierSchemeCodeUnspsc190501.50554422'),
	'identifierSchemeCodeUnspsc190501.50554423': __('identifierSchemeCodeUnspsc190501.50554423'),
	'identifierSchemeCodeUnspsc190501.50554424': __('identifierSchemeCodeUnspsc190501.50554424'),
	'identifierSchemeCodeUnspsc190501.50554425': __('identifierSchemeCodeUnspsc190501.50554425'),
	'identifierSchemeCodeUnspsc190501.50554426': __('identifierSchemeCodeUnspsc190501.50554426'),
	'identifierSchemeCodeUnspsc190501.50554427': __('identifierSchemeCodeUnspsc190501.50554427'),
	'identifierSchemeCodeUnspsc190501.50554428': __('identifierSchemeCodeUnspsc190501.50554428'),
	'identifierSchemeCodeUnspsc190501.50554429': __('identifierSchemeCodeUnspsc190501.50554429'),
	'identifierSchemeCodeUnspsc190501.50554430': __('identifierSchemeCodeUnspsc190501.50554430'),
	'identifierSchemeCodeUnspsc190501.50554431': __('identifierSchemeCodeUnspsc190501.50554431'),
	'identifierSchemeCodeUnspsc190501.50554432': __('identifierSchemeCodeUnspsc190501.50554432'),
	'identifierSchemeCodeUnspsc190501.50554433': __('identifierSchemeCodeUnspsc190501.50554433'),
	'identifierSchemeCodeUnspsc190501.50554434': __('identifierSchemeCodeUnspsc190501.50554434'),
	'identifierSchemeCodeUnspsc190501.50554435': __('identifierSchemeCodeUnspsc190501.50554435'),
	'identifierSchemeCodeUnspsc190501.50554436': __('identifierSchemeCodeUnspsc190501.50554436'),
	'identifierSchemeCodeUnspsc190501.50554437': __('identifierSchemeCodeUnspsc190501.50554437'),
	'identifierSchemeCodeUnspsc190501.50554438': __('identifierSchemeCodeUnspsc190501.50554438'),
	'identifierSchemeCodeUnspsc190501.50554439': __('identifierSchemeCodeUnspsc190501.50554439'),
	'identifierSchemeCodeUnspsc190501.50554440': __('identifierSchemeCodeUnspsc190501.50554440'),
	'identifierSchemeCodeUnspsc190501.50554441': __('identifierSchemeCodeUnspsc190501.50554441'),
	'identifierSchemeCodeUnspsc190501.50554442': __('identifierSchemeCodeUnspsc190501.50554442'),
	'identifierSchemeCodeUnspsc190501.50554443': __('identifierSchemeCodeUnspsc190501.50554443'),
	'identifierSchemeCodeUnspsc190501.50554444': __('identifierSchemeCodeUnspsc190501.50554444'),
	'identifierSchemeCodeUnspsc190501.50554445': __('identifierSchemeCodeUnspsc190501.50554445'),
	'identifierSchemeCodeUnspsc190501.50554446': __('identifierSchemeCodeUnspsc190501.50554446'),
	'identifierSchemeCodeUnspsc190501.50554500': __('identifierSchemeCodeUnspsc190501.50554500'),
	'identifierSchemeCodeUnspsc190501.50554501': __('identifierSchemeCodeUnspsc190501.50554501'),
	'identifierSchemeCodeUnspsc190501.50554502': __('identifierSchemeCodeUnspsc190501.50554502'),
	'identifierSchemeCodeUnspsc190501.50554503': __('identifierSchemeCodeUnspsc190501.50554503'),
	'identifierSchemeCodeUnspsc190501.50554504': __('identifierSchemeCodeUnspsc190501.50554504'),
	'identifierSchemeCodeUnspsc190501.50554505': __('identifierSchemeCodeUnspsc190501.50554505'),
	'identifierSchemeCodeUnspsc190501.50554506': __('identifierSchemeCodeUnspsc190501.50554506'),
	'identifierSchemeCodeUnspsc190501.50554507': __('identifierSchemeCodeUnspsc190501.50554507'),
	'identifierSchemeCodeUnspsc190501.50554508': __('identifierSchemeCodeUnspsc190501.50554508'),
	'identifierSchemeCodeUnspsc190501.50554509': __('identifierSchemeCodeUnspsc190501.50554509'),
	'identifierSchemeCodeUnspsc190501.50554510': __('identifierSchemeCodeUnspsc190501.50554510'),
	'identifierSchemeCodeUnspsc190501.50554511': __('identifierSchemeCodeUnspsc190501.50554511'),
	'identifierSchemeCodeUnspsc190501.50554512': __('identifierSchemeCodeUnspsc190501.50554512'),
	'identifierSchemeCodeUnspsc190501.50554513': __('identifierSchemeCodeUnspsc190501.50554513'),
	'identifierSchemeCodeUnspsc190501.50554514': __('identifierSchemeCodeUnspsc190501.50554514'),
	'identifierSchemeCodeUnspsc190501.50554515': __('identifierSchemeCodeUnspsc190501.50554515'),
	'identifierSchemeCodeUnspsc190501.50554516': __('identifierSchemeCodeUnspsc190501.50554516'),
	'identifierSchemeCodeUnspsc190501.50554517': __('identifierSchemeCodeUnspsc190501.50554517'),
	'identifierSchemeCodeUnspsc190501.50554518': __('identifierSchemeCodeUnspsc190501.50554518'),
	'identifierSchemeCodeUnspsc190501.50554519': __('identifierSchemeCodeUnspsc190501.50554519'),
	'identifierSchemeCodeUnspsc190501.50554520': __('identifierSchemeCodeUnspsc190501.50554520'),
	'identifierSchemeCodeUnspsc190501.50554521': __('identifierSchemeCodeUnspsc190501.50554521'),
	'identifierSchemeCodeUnspsc190501.50554522': __('identifierSchemeCodeUnspsc190501.50554522'),
	'identifierSchemeCodeUnspsc190501.50554523': __('identifierSchemeCodeUnspsc190501.50554523'),
	'identifierSchemeCodeUnspsc190501.50554524': __('identifierSchemeCodeUnspsc190501.50554524'),
	'identifierSchemeCodeUnspsc190501.50554525': __('identifierSchemeCodeUnspsc190501.50554525'),
	'identifierSchemeCodeUnspsc190501.50554600': __('identifierSchemeCodeUnspsc190501.50554600'),
	'identifierSchemeCodeUnspsc190501.50554601': __('identifierSchemeCodeUnspsc190501.50554601'),
	'identifierSchemeCodeUnspsc190501.50554602': __('identifierSchemeCodeUnspsc190501.50554602'),
	'identifierSchemeCodeUnspsc190501.50554603': __('identifierSchemeCodeUnspsc190501.50554603'),
	'identifierSchemeCodeUnspsc190501.50554604': __('identifierSchemeCodeUnspsc190501.50554604'),
	'identifierSchemeCodeUnspsc190501.50554605': __('identifierSchemeCodeUnspsc190501.50554605'),
	'identifierSchemeCodeUnspsc190501.50554606': __('identifierSchemeCodeUnspsc190501.50554606'),
	'identifierSchemeCodeUnspsc190501.50554607': __('identifierSchemeCodeUnspsc190501.50554607'),
	'identifierSchemeCodeUnspsc190501.50554608': __('identifierSchemeCodeUnspsc190501.50554608'),
	'identifierSchemeCodeUnspsc190501.50554609': __('identifierSchemeCodeUnspsc190501.50554609'),
	'identifierSchemeCodeUnspsc190501.50554610': __('identifierSchemeCodeUnspsc190501.50554610'),
	'identifierSchemeCodeUnspsc190501.50554611': __('identifierSchemeCodeUnspsc190501.50554611'),
	'identifierSchemeCodeUnspsc190501.50554612': __('identifierSchemeCodeUnspsc190501.50554612'),
	'identifierSchemeCodeUnspsc190501.50554613': __('identifierSchemeCodeUnspsc190501.50554613'),
	'identifierSchemeCodeUnspsc190501.50554614': __('identifierSchemeCodeUnspsc190501.50554614'),
	'identifierSchemeCodeUnspsc190501.50554615': __('identifierSchemeCodeUnspsc190501.50554615'),
	'identifierSchemeCodeUnspsc190501.50554616': __('identifierSchemeCodeUnspsc190501.50554616'),
	'identifierSchemeCodeUnspsc190501.50554617': __('identifierSchemeCodeUnspsc190501.50554617'),
	'identifierSchemeCodeUnspsc190501.50554618': __('identifierSchemeCodeUnspsc190501.50554618'),
	'identifierSchemeCodeUnspsc190501.50554619': __('identifierSchemeCodeUnspsc190501.50554619'),
	'identifierSchemeCodeUnspsc190501.50554620': __('identifierSchemeCodeUnspsc190501.50554620'),
	'identifierSchemeCodeUnspsc190501.50554621': __('identifierSchemeCodeUnspsc190501.50554621'),
	'identifierSchemeCodeUnspsc190501.50554622': __('identifierSchemeCodeUnspsc190501.50554622'),
	'identifierSchemeCodeUnspsc190501.50554623': __('identifierSchemeCodeUnspsc190501.50554623'),
	'identifierSchemeCodeUnspsc190501.50554624': __('identifierSchemeCodeUnspsc190501.50554624'),
	'identifierSchemeCodeUnspsc190501.50554625': __('identifierSchemeCodeUnspsc190501.50554625'),
	'identifierSchemeCodeUnspsc190501.50554626': __('identifierSchemeCodeUnspsc190501.50554626'),
	'identifierSchemeCodeUnspsc190501.50554627': __('identifierSchemeCodeUnspsc190501.50554627'),
	'identifierSchemeCodeUnspsc190501.50554628': __('identifierSchemeCodeUnspsc190501.50554628'),
	'identifierSchemeCodeUnspsc190501.50554629': __('identifierSchemeCodeUnspsc190501.50554629'),
	'identifierSchemeCodeUnspsc190501.50554630': __('identifierSchemeCodeUnspsc190501.50554630'),
	'identifierSchemeCodeUnspsc190501.50554631': __('identifierSchemeCodeUnspsc190501.50554631'),
	'identifierSchemeCodeUnspsc190501.50554632': __('identifierSchemeCodeUnspsc190501.50554632'),
	'identifierSchemeCodeUnspsc190501.50554633': __('identifierSchemeCodeUnspsc190501.50554633'),
	'identifierSchemeCodeUnspsc190501.50554634': __('identifierSchemeCodeUnspsc190501.50554634'),
	'identifierSchemeCodeUnspsc190501.50554635': __('identifierSchemeCodeUnspsc190501.50554635'),
	'identifierSchemeCodeUnspsc190501.50554636': __('identifierSchemeCodeUnspsc190501.50554636'),
	'identifierSchemeCodeUnspsc190501.50554637': __('identifierSchemeCodeUnspsc190501.50554637'),
	'identifierSchemeCodeUnspsc190501.50554638': __('identifierSchemeCodeUnspsc190501.50554638'),
	'identifierSchemeCodeUnspsc190501.50554639': __('identifierSchemeCodeUnspsc190501.50554639'),
	'identifierSchemeCodeUnspsc190501.50554640': __('identifierSchemeCodeUnspsc190501.50554640'),
	'identifierSchemeCodeUnspsc190501.50554641': __('identifierSchemeCodeUnspsc190501.50554641'),
	'identifierSchemeCodeUnspsc190501.50554642': __('identifierSchemeCodeUnspsc190501.50554642'),
	'identifierSchemeCodeUnspsc190501.50554643': __('identifierSchemeCodeUnspsc190501.50554643'),
	'identifierSchemeCodeUnspsc190501.50554644': __('identifierSchemeCodeUnspsc190501.50554644'),
	'identifierSchemeCodeUnspsc190501.50554645': __('identifierSchemeCodeUnspsc190501.50554645'),
	'identifierSchemeCodeUnspsc190501.50554646': __('identifierSchemeCodeUnspsc190501.50554646'),
	'identifierSchemeCodeUnspsc190501.50554647': __('identifierSchemeCodeUnspsc190501.50554647'),
	'identifierSchemeCodeUnspsc190501.50554648': __('identifierSchemeCodeUnspsc190501.50554648'),
	'identifierSchemeCodeUnspsc190501.50554649': __('identifierSchemeCodeUnspsc190501.50554649'),
	'identifierSchemeCodeUnspsc190501.50554650': __('identifierSchemeCodeUnspsc190501.50554650'),
	'identifierSchemeCodeUnspsc190501.50554651': __('identifierSchemeCodeUnspsc190501.50554651'),
	'identifierSchemeCodeUnspsc190501.50554652': __('identifierSchemeCodeUnspsc190501.50554652'),
	'identifierSchemeCodeUnspsc190501.50554653': __('identifierSchemeCodeUnspsc190501.50554653'),
	'identifierSchemeCodeUnspsc190501.50554654': __('identifierSchemeCodeUnspsc190501.50554654'),
	'identifierSchemeCodeUnspsc190501.50554655': __('identifierSchemeCodeUnspsc190501.50554655'),
	'identifierSchemeCodeUnspsc190501.50554656': __('identifierSchemeCodeUnspsc190501.50554656'),
	'identifierSchemeCodeUnspsc190501.50554657': __('identifierSchemeCodeUnspsc190501.50554657'),
	'identifierSchemeCodeUnspsc190501.50554658': __('identifierSchemeCodeUnspsc190501.50554658'),
	'identifierSchemeCodeUnspsc190501.50554659': __('identifierSchemeCodeUnspsc190501.50554659'),
	'identifierSchemeCodeUnspsc190501.50554660': __('identifierSchemeCodeUnspsc190501.50554660'),
	'identifierSchemeCodeUnspsc190501.50554661': __('identifierSchemeCodeUnspsc190501.50554661'),
	'identifierSchemeCodeUnspsc190501.50554700': __('identifierSchemeCodeUnspsc190501.50554700'),
	'identifierSchemeCodeUnspsc190501.50554701': __('identifierSchemeCodeUnspsc190501.50554701'),
	'identifierSchemeCodeUnspsc190501.50554702': __('identifierSchemeCodeUnspsc190501.50554702'),
	'identifierSchemeCodeUnspsc190501.50554800': __('identifierSchemeCodeUnspsc190501.50554800'),
	'identifierSchemeCodeUnspsc190501.50554801': __('identifierSchemeCodeUnspsc190501.50554801'),
	'identifierSchemeCodeUnspsc190501.50554802': __('identifierSchemeCodeUnspsc190501.50554802'),
	'identifierSchemeCodeUnspsc190501.50554900': __('identifierSchemeCodeUnspsc190501.50554900'),
	'identifierSchemeCodeUnspsc190501.50554901': __('identifierSchemeCodeUnspsc190501.50554901'),
	'identifierSchemeCodeUnspsc190501.50554902': __('identifierSchemeCodeUnspsc190501.50554902'),
	'identifierSchemeCodeUnspsc190501.50554903': __('identifierSchemeCodeUnspsc190501.50554903'),
	'identifierSchemeCodeUnspsc190501.50554904': __('identifierSchemeCodeUnspsc190501.50554904'),
	'identifierSchemeCodeUnspsc190501.50554905': __('identifierSchemeCodeUnspsc190501.50554905'),
	'identifierSchemeCodeUnspsc190501.50554906': __('identifierSchemeCodeUnspsc190501.50554906'),
	'identifierSchemeCodeUnspsc190501.50554907': __('identifierSchemeCodeUnspsc190501.50554907'),
	'identifierSchemeCodeUnspsc190501.50554908': __('identifierSchemeCodeUnspsc190501.50554908'),
	'identifierSchemeCodeUnspsc190501.50554909': __('identifierSchemeCodeUnspsc190501.50554909'),
	'identifierSchemeCodeUnspsc190501.50554910': __('identifierSchemeCodeUnspsc190501.50554910'),
	'identifierSchemeCodeUnspsc190501.50554911': __('identifierSchemeCodeUnspsc190501.50554911'),
	'identifierSchemeCodeUnspsc190501.50554912': __('identifierSchemeCodeUnspsc190501.50554912'),
	'identifierSchemeCodeUnspsc190501.50554913': __('identifierSchemeCodeUnspsc190501.50554913'),
	'identifierSchemeCodeUnspsc190501.50554914': __('identifierSchemeCodeUnspsc190501.50554914'),
	'identifierSchemeCodeUnspsc190501.50554915': __('identifierSchemeCodeUnspsc190501.50554915'),
	'identifierSchemeCodeUnspsc190501.50554916': __('identifierSchemeCodeUnspsc190501.50554916'),
	'identifierSchemeCodeUnspsc190501.50554917': __('identifierSchemeCodeUnspsc190501.50554917'),
	'identifierSchemeCodeUnspsc190501.50554918': __('identifierSchemeCodeUnspsc190501.50554918'),
	'identifierSchemeCodeUnspsc190501.50554919': __('identifierSchemeCodeUnspsc190501.50554919'),
	'identifierSchemeCodeUnspsc190501.50554920': __('identifierSchemeCodeUnspsc190501.50554920'),
	'identifierSchemeCodeUnspsc190501.50554921': __('identifierSchemeCodeUnspsc190501.50554921'),
	'identifierSchemeCodeUnspsc190501.50554922': __('identifierSchemeCodeUnspsc190501.50554922'),
	'identifierSchemeCodeUnspsc190501.50554923': __('identifierSchemeCodeUnspsc190501.50554923'),
	'identifierSchemeCodeUnspsc190501.50554924': __('identifierSchemeCodeUnspsc190501.50554924'),
	'identifierSchemeCodeUnspsc190501.50554925': __('identifierSchemeCodeUnspsc190501.50554925'),
	'identifierSchemeCodeUnspsc190501.50554926': __('identifierSchemeCodeUnspsc190501.50554926'),
	'identifierSchemeCodeUnspsc190501.50554927': __('identifierSchemeCodeUnspsc190501.50554927'),
	'identifierSchemeCodeUnspsc190501.50554928': __('identifierSchemeCodeUnspsc190501.50554928'),
	'identifierSchemeCodeUnspsc190501.50554929': __('identifierSchemeCodeUnspsc190501.50554929'),
	'identifierSchemeCodeUnspsc190501.50554930': __('identifierSchemeCodeUnspsc190501.50554930'),
	'identifierSchemeCodeUnspsc190501.50554931': __('identifierSchemeCodeUnspsc190501.50554931'),
	'identifierSchemeCodeUnspsc190501.50554932': __('identifierSchemeCodeUnspsc190501.50554932'),
	'identifierSchemeCodeUnspsc190501.50554933': __('identifierSchemeCodeUnspsc190501.50554933'),
	'identifierSchemeCodeUnspsc190501.50554934': __('identifierSchemeCodeUnspsc190501.50554934'),
	'identifierSchemeCodeUnspsc190501.50554935': __('identifierSchemeCodeUnspsc190501.50554935'),
	'identifierSchemeCodeUnspsc190501.50554936': __('identifierSchemeCodeUnspsc190501.50554936'),
	'identifierSchemeCodeUnspsc190501.50554937': __('identifierSchemeCodeUnspsc190501.50554937'),
	'identifierSchemeCodeUnspsc190501.50554938': __('identifierSchemeCodeUnspsc190501.50554938'),
	'identifierSchemeCodeUnspsc190501.50554939': __('identifierSchemeCodeUnspsc190501.50554939'),
	'identifierSchemeCodeUnspsc190501.50554940': __('identifierSchemeCodeUnspsc190501.50554940'),
	'identifierSchemeCodeUnspsc190501.50554941': __('identifierSchemeCodeUnspsc190501.50554941'),
	'identifierSchemeCodeUnspsc190501.50554942': __('identifierSchemeCodeUnspsc190501.50554942'),
	'identifierSchemeCodeUnspsc190501.50554943': __('identifierSchemeCodeUnspsc190501.50554943'),
	'identifierSchemeCodeUnspsc190501.50554944': __('identifierSchemeCodeUnspsc190501.50554944'),
	'identifierSchemeCodeUnspsc190501.50554945': __('identifierSchemeCodeUnspsc190501.50554945'),
	'identifierSchemeCodeUnspsc190501.50554946': __('identifierSchemeCodeUnspsc190501.50554946'),
	'identifierSchemeCodeUnspsc190501.50554947': __('identifierSchemeCodeUnspsc190501.50554947'),
	'identifierSchemeCodeUnspsc190501.50554948': __('identifierSchemeCodeUnspsc190501.50554948'),
	'identifierSchemeCodeUnspsc190501.50554949': __('identifierSchemeCodeUnspsc190501.50554949'),
	'identifierSchemeCodeUnspsc190501.50554950': __('identifierSchemeCodeUnspsc190501.50554950'),
	'identifierSchemeCodeUnspsc190501.50554951': __('identifierSchemeCodeUnspsc190501.50554951'),
	'identifierSchemeCodeUnspsc190501.50554952': __('identifierSchemeCodeUnspsc190501.50554952'),
	'identifierSchemeCodeUnspsc190501.50554953': __('identifierSchemeCodeUnspsc190501.50554953'),
	'identifierSchemeCodeUnspsc190501.50554954': __('identifierSchemeCodeUnspsc190501.50554954'),
	'identifierSchemeCodeUnspsc190501.50554955': __('identifierSchemeCodeUnspsc190501.50554955'),
	'identifierSchemeCodeUnspsc190501.50554956': __('identifierSchemeCodeUnspsc190501.50554956'),
	'identifierSchemeCodeUnspsc190501.50554957': __('identifierSchemeCodeUnspsc190501.50554957'),
	'identifierSchemeCodeUnspsc190501.50555000': __('identifierSchemeCodeUnspsc190501.50555000'),
	'identifierSchemeCodeUnspsc190501.50555001': __('identifierSchemeCodeUnspsc190501.50555001'),
	'identifierSchemeCodeUnspsc190501.50555002': __('identifierSchemeCodeUnspsc190501.50555002'),
	'identifierSchemeCodeUnspsc190501.50555003': __('identifierSchemeCodeUnspsc190501.50555003'),
	'identifierSchemeCodeUnspsc190501.50555004': __('identifierSchemeCodeUnspsc190501.50555004'),
	'identifierSchemeCodeUnspsc190501.50555005': __('identifierSchemeCodeUnspsc190501.50555005'),
	'identifierSchemeCodeUnspsc190501.50555006': __('identifierSchemeCodeUnspsc190501.50555006'),
	'identifierSchemeCodeUnspsc190501.50555007': __('identifierSchemeCodeUnspsc190501.50555007'),
	'identifierSchemeCodeUnspsc190501.50555008': __('identifierSchemeCodeUnspsc190501.50555008'),
	'identifierSchemeCodeUnspsc190501.50555009': __('identifierSchemeCodeUnspsc190501.50555009'),
	'identifierSchemeCodeUnspsc190501.50555010': __('identifierSchemeCodeUnspsc190501.50555010'),
	'identifierSchemeCodeUnspsc190501.50555011': __('identifierSchemeCodeUnspsc190501.50555011'),
	'identifierSchemeCodeUnspsc190501.50555012': __('identifierSchemeCodeUnspsc190501.50555012'),
	'identifierSchemeCodeUnspsc190501.50555013': __('identifierSchemeCodeUnspsc190501.50555013'),
	'identifierSchemeCodeUnspsc190501.50555014': __('identifierSchemeCodeUnspsc190501.50555014'),
	'identifierSchemeCodeUnspsc190501.50555015': __('identifierSchemeCodeUnspsc190501.50555015'),
	'identifierSchemeCodeUnspsc190501.50555016': __('identifierSchemeCodeUnspsc190501.50555016'),
	'identifierSchemeCodeUnspsc190501.50555017': __('identifierSchemeCodeUnspsc190501.50555017'),
	'identifierSchemeCodeUnspsc190501.50555018': __('identifierSchemeCodeUnspsc190501.50555018'),
	'identifierSchemeCodeUnspsc190501.50555019': __('identifierSchemeCodeUnspsc190501.50555019'),
	'identifierSchemeCodeUnspsc190501.50555020': __('identifierSchemeCodeUnspsc190501.50555020'),
	'identifierSchemeCodeUnspsc190501.50555021': __('identifierSchemeCodeUnspsc190501.50555021'),
	'identifierSchemeCodeUnspsc190501.50555022': __('identifierSchemeCodeUnspsc190501.50555022'),
	'identifierSchemeCodeUnspsc190501.50555023': __('identifierSchemeCodeUnspsc190501.50555023'),
	'identifierSchemeCodeUnspsc190501.50555024': __('identifierSchemeCodeUnspsc190501.50555024'),
	'identifierSchemeCodeUnspsc190501.50555025': __('identifierSchemeCodeUnspsc190501.50555025'),
	'identifierSchemeCodeUnspsc190501.50555026': __('identifierSchemeCodeUnspsc190501.50555026'),
	'identifierSchemeCodeUnspsc190501.50555027': __('identifierSchemeCodeUnspsc190501.50555027'),
	'identifierSchemeCodeUnspsc190501.50555028': __('identifierSchemeCodeUnspsc190501.50555028'),
	'identifierSchemeCodeUnspsc190501.50555029': __('identifierSchemeCodeUnspsc190501.50555029'),
	'identifierSchemeCodeUnspsc190501.50555030': __('identifierSchemeCodeUnspsc190501.50555030'),
	'identifierSchemeCodeUnspsc190501.50555031': __('identifierSchemeCodeUnspsc190501.50555031'),
	'identifierSchemeCodeUnspsc190501.50555032': __('identifierSchemeCodeUnspsc190501.50555032'),
	'identifierSchemeCodeUnspsc190501.50555033': __('identifierSchemeCodeUnspsc190501.50555033'),
	'identifierSchemeCodeUnspsc190501.50555034': __('identifierSchemeCodeUnspsc190501.50555034'),
	'identifierSchemeCodeUnspsc190501.50555035': __('identifierSchemeCodeUnspsc190501.50555035'),
	'identifierSchemeCodeUnspsc190501.50555036': __('identifierSchemeCodeUnspsc190501.50555036'),
	'identifierSchemeCodeUnspsc190501.50555037': __('identifierSchemeCodeUnspsc190501.50555037'),
	'identifierSchemeCodeUnspsc190501.50555038': __('identifierSchemeCodeUnspsc190501.50555038'),
	'identifierSchemeCodeUnspsc190501.50555039': __('identifierSchemeCodeUnspsc190501.50555039'),
	'identifierSchemeCodeUnspsc190501.50555040': __('identifierSchemeCodeUnspsc190501.50555040'),
	'identifierSchemeCodeUnspsc190501.50555041': __('identifierSchemeCodeUnspsc190501.50555041'),
	'identifierSchemeCodeUnspsc190501.50555042': __('identifierSchemeCodeUnspsc190501.50555042'),
	'identifierSchemeCodeUnspsc190501.50555043': __('identifierSchemeCodeUnspsc190501.50555043'),
	'identifierSchemeCodeUnspsc190501.50555044': __('identifierSchemeCodeUnspsc190501.50555044'),
	'identifierSchemeCodeUnspsc190501.50555100': __('identifierSchemeCodeUnspsc190501.50555100'),
	'identifierSchemeCodeUnspsc190501.50555101': __('identifierSchemeCodeUnspsc190501.50555101'),
	'identifierSchemeCodeUnspsc190501.50555102': __('identifierSchemeCodeUnspsc190501.50555102'),
	'identifierSchemeCodeUnspsc190501.50555103': __('identifierSchemeCodeUnspsc190501.50555103'),
	'identifierSchemeCodeUnspsc190501.50555104': __('identifierSchemeCodeUnspsc190501.50555104'),
	'identifierSchemeCodeUnspsc190501.50555105': __('identifierSchemeCodeUnspsc190501.50555105'),
	'identifierSchemeCodeUnspsc190501.50555106': __('identifierSchemeCodeUnspsc190501.50555106'),
	'identifierSchemeCodeUnspsc190501.50555200': __('identifierSchemeCodeUnspsc190501.50555200'),
	'identifierSchemeCodeUnspsc190501.50555201': __('identifierSchemeCodeUnspsc190501.50555201'),
	'identifierSchemeCodeUnspsc190501.50555202': __('identifierSchemeCodeUnspsc190501.50555202'),
	'identifierSchemeCodeUnspsc190501.50555203': __('identifierSchemeCodeUnspsc190501.50555203'),
	'identifierSchemeCodeUnspsc190501.50555204': __('identifierSchemeCodeUnspsc190501.50555204'),
	'identifierSchemeCodeUnspsc190501.50555205': __('identifierSchemeCodeUnspsc190501.50555205'),
	'identifierSchemeCodeUnspsc190501.50555206': __('identifierSchemeCodeUnspsc190501.50555206'),
	'identifierSchemeCodeUnspsc190501.50555207': __('identifierSchemeCodeUnspsc190501.50555207'),
	'identifierSchemeCodeUnspsc190501.50555208': __('identifierSchemeCodeUnspsc190501.50555208'),
	'identifierSchemeCodeUnspsc190501.50555209': __('identifierSchemeCodeUnspsc190501.50555209'),
	'identifierSchemeCodeUnspsc190501.50555210': __('identifierSchemeCodeUnspsc190501.50555210'),
	'identifierSchemeCodeUnspsc190501.50555300': __('identifierSchemeCodeUnspsc190501.50555300'),
	'identifierSchemeCodeUnspsc190501.50555301': __('identifierSchemeCodeUnspsc190501.50555301'),
	'identifierSchemeCodeUnspsc190501.50555302': __('identifierSchemeCodeUnspsc190501.50555302'),
	'identifierSchemeCodeUnspsc190501.50555303': __('identifierSchemeCodeUnspsc190501.50555303'),
	'identifierSchemeCodeUnspsc190501.50555304': __('identifierSchemeCodeUnspsc190501.50555304'),
	'identifierSchemeCodeUnspsc190501.50555305': __('identifierSchemeCodeUnspsc190501.50555305'),
	'identifierSchemeCodeUnspsc190501.50555306': __('identifierSchemeCodeUnspsc190501.50555306'),
	'identifierSchemeCodeUnspsc190501.50555307': __('identifierSchemeCodeUnspsc190501.50555307'),
	'identifierSchemeCodeUnspsc190501.50555308': __('identifierSchemeCodeUnspsc190501.50555308'),
	'identifierSchemeCodeUnspsc190501.50555309': __('identifierSchemeCodeUnspsc190501.50555309'),
	'identifierSchemeCodeUnspsc190501.50555310': __('identifierSchemeCodeUnspsc190501.50555310'),
	'identifierSchemeCodeUnspsc190501.50555311': __('identifierSchemeCodeUnspsc190501.50555311'),
	'identifierSchemeCodeUnspsc190501.50555312': __('identifierSchemeCodeUnspsc190501.50555312'),
	'identifierSchemeCodeUnspsc190501.50555313': __('identifierSchemeCodeUnspsc190501.50555313'),
	'identifierSchemeCodeUnspsc190501.50555314': __('identifierSchemeCodeUnspsc190501.50555314'),
	'identifierSchemeCodeUnspsc190501.50555315': __('identifierSchemeCodeUnspsc190501.50555315'),
	'identifierSchemeCodeUnspsc190501.50555316': __('identifierSchemeCodeUnspsc190501.50555316'),
	'identifierSchemeCodeUnspsc190501.50555317': __('identifierSchemeCodeUnspsc190501.50555317'),
	'identifierSchemeCodeUnspsc190501.50555318': __('identifierSchemeCodeUnspsc190501.50555318'),
	'identifierSchemeCodeUnspsc190501.50555319': __('identifierSchemeCodeUnspsc190501.50555319'),
	'identifierSchemeCodeUnspsc190501.50555320': __('identifierSchemeCodeUnspsc190501.50555320'),
	'identifierSchemeCodeUnspsc190501.50555321': __('identifierSchemeCodeUnspsc190501.50555321'),
	'identifierSchemeCodeUnspsc190501.50555322': __('identifierSchemeCodeUnspsc190501.50555322'),
	'identifierSchemeCodeUnspsc190501.50555323': __('identifierSchemeCodeUnspsc190501.50555323'),
	'identifierSchemeCodeUnspsc190501.50555324': __('identifierSchemeCodeUnspsc190501.50555324'),
	'identifierSchemeCodeUnspsc190501.50555325': __('identifierSchemeCodeUnspsc190501.50555325'),
	'identifierSchemeCodeUnspsc190501.50555326': __('identifierSchemeCodeUnspsc190501.50555326'),
	'identifierSchemeCodeUnspsc190501.50555327': __('identifierSchemeCodeUnspsc190501.50555327'),
	'identifierSchemeCodeUnspsc190501.50555328': __('identifierSchemeCodeUnspsc190501.50555328'),
	'identifierSchemeCodeUnspsc190501.50555329': __('identifierSchemeCodeUnspsc190501.50555329'),
	'identifierSchemeCodeUnspsc190501.50555330': __('identifierSchemeCodeUnspsc190501.50555330'),
	'identifierSchemeCodeUnspsc190501.50555331': __('identifierSchemeCodeUnspsc190501.50555331'),
	'identifierSchemeCodeUnspsc190501.50555332': __('identifierSchemeCodeUnspsc190501.50555332'),
	'identifierSchemeCodeUnspsc190501.50555333': __('identifierSchemeCodeUnspsc190501.50555333'),
	'identifierSchemeCodeUnspsc190501.50555334': __('identifierSchemeCodeUnspsc190501.50555334'),
	'identifierSchemeCodeUnspsc190501.50555335': __('identifierSchemeCodeUnspsc190501.50555335'),
	'identifierSchemeCodeUnspsc190501.50555336': __('identifierSchemeCodeUnspsc190501.50555336'),
	'identifierSchemeCodeUnspsc190501.50555337': __('identifierSchemeCodeUnspsc190501.50555337'),
	'identifierSchemeCodeUnspsc190501.50555338': __('identifierSchemeCodeUnspsc190501.50555338'),
	'identifierSchemeCodeUnspsc190501.50555339': __('identifierSchemeCodeUnspsc190501.50555339'),
	'identifierSchemeCodeUnspsc190501.50555340': __('identifierSchemeCodeUnspsc190501.50555340'),
	'identifierSchemeCodeUnspsc190501.50555341': __('identifierSchemeCodeUnspsc190501.50555341'),
	'identifierSchemeCodeUnspsc190501.50555342': __('identifierSchemeCodeUnspsc190501.50555342'),
	'identifierSchemeCodeUnspsc190501.50555343': __('identifierSchemeCodeUnspsc190501.50555343'),
	'identifierSchemeCodeUnspsc190501.50555344': __('identifierSchemeCodeUnspsc190501.50555344'),
	'identifierSchemeCodeUnspsc190501.50555345': __('identifierSchemeCodeUnspsc190501.50555345'),
	'identifierSchemeCodeUnspsc190501.50555346': __('identifierSchemeCodeUnspsc190501.50555346'),
	'identifierSchemeCodeUnspsc190501.50555347': __('identifierSchemeCodeUnspsc190501.50555347'),
	'identifierSchemeCodeUnspsc190501.50555348': __('identifierSchemeCodeUnspsc190501.50555348'),
	'identifierSchemeCodeUnspsc190501.50555349': __('identifierSchemeCodeUnspsc190501.50555349'),
	'identifierSchemeCodeUnspsc190501.50555350': __('identifierSchemeCodeUnspsc190501.50555350'),
	'identifierSchemeCodeUnspsc190501.50555351': __('identifierSchemeCodeUnspsc190501.50555351'),
	'identifierSchemeCodeUnspsc190501.50555352': __('identifierSchemeCodeUnspsc190501.50555352'),
	'identifierSchemeCodeUnspsc190501.50555353': __('identifierSchemeCodeUnspsc190501.50555353'),
	'identifierSchemeCodeUnspsc190501.50555354': __('identifierSchemeCodeUnspsc190501.50555354'),
	'identifierSchemeCodeUnspsc190501.50555355': __('identifierSchemeCodeUnspsc190501.50555355'),
	'identifierSchemeCodeUnspsc190501.50555356': __('identifierSchemeCodeUnspsc190501.50555356'),
	'identifierSchemeCodeUnspsc190501.50555357': __('identifierSchemeCodeUnspsc190501.50555357'),
	'identifierSchemeCodeUnspsc190501.50555358': __('identifierSchemeCodeUnspsc190501.50555358'),
	'identifierSchemeCodeUnspsc190501.50555359': __('identifierSchemeCodeUnspsc190501.50555359'),
	'identifierSchemeCodeUnspsc190501.50555360': __('identifierSchemeCodeUnspsc190501.50555360'),
	'identifierSchemeCodeUnspsc190501.50555361': __('identifierSchemeCodeUnspsc190501.50555361'),
	'identifierSchemeCodeUnspsc190501.50555362': __('identifierSchemeCodeUnspsc190501.50555362'),
	'identifierSchemeCodeUnspsc190501.50555363': __('identifierSchemeCodeUnspsc190501.50555363'),
	'identifierSchemeCodeUnspsc190501.50555364': __('identifierSchemeCodeUnspsc190501.50555364'),
	'identifierSchemeCodeUnspsc190501.50555365': __('identifierSchemeCodeUnspsc190501.50555365'),
	'identifierSchemeCodeUnspsc190501.50555366': __('identifierSchemeCodeUnspsc190501.50555366'),
	'identifierSchemeCodeUnspsc190501.50555367': __('identifierSchemeCodeUnspsc190501.50555367'),
	'identifierSchemeCodeUnspsc190501.50555368': __('identifierSchemeCodeUnspsc190501.50555368'),
	'identifierSchemeCodeUnspsc190501.50555369': __('identifierSchemeCodeUnspsc190501.50555369'),
	'identifierSchemeCodeUnspsc190501.50555370': __('identifierSchemeCodeUnspsc190501.50555370'),
	'identifierSchemeCodeUnspsc190501.50555371': __('identifierSchemeCodeUnspsc190501.50555371'),
	'identifierSchemeCodeUnspsc190501.50555400': __('identifierSchemeCodeUnspsc190501.50555400'),
	'identifierSchemeCodeUnspsc190501.50555401': __('identifierSchemeCodeUnspsc190501.50555401'),
	'identifierSchemeCodeUnspsc190501.50555402': __('identifierSchemeCodeUnspsc190501.50555402'),
	'identifierSchemeCodeUnspsc190501.50555403': __('identifierSchemeCodeUnspsc190501.50555403'),
	'identifierSchemeCodeUnspsc190501.50555404': __('identifierSchemeCodeUnspsc190501.50555404'),
	'identifierSchemeCodeUnspsc190501.50555405': __('identifierSchemeCodeUnspsc190501.50555405'),
	'identifierSchemeCodeUnspsc190501.50555406': __('identifierSchemeCodeUnspsc190501.50555406'),
	'identifierSchemeCodeUnspsc190501.50555407': __('identifierSchemeCodeUnspsc190501.50555407'),
	'identifierSchemeCodeUnspsc190501.50555408': __('identifierSchemeCodeUnspsc190501.50555408'),
	'identifierSchemeCodeUnspsc190501.50555409': __('identifierSchemeCodeUnspsc190501.50555409'),
	'identifierSchemeCodeUnspsc190501.50555410': __('identifierSchemeCodeUnspsc190501.50555410'),
	'identifierSchemeCodeUnspsc190501.50555411': __('identifierSchemeCodeUnspsc190501.50555411'),
	'identifierSchemeCodeUnspsc190501.50555412': __('identifierSchemeCodeUnspsc190501.50555412'),
	'identifierSchemeCodeUnspsc190501.50555413': __('identifierSchemeCodeUnspsc190501.50555413'),
	'identifierSchemeCodeUnspsc190501.50555414': __('identifierSchemeCodeUnspsc190501.50555414'),
	'identifierSchemeCodeUnspsc190501.50555415': __('identifierSchemeCodeUnspsc190501.50555415'),
	'identifierSchemeCodeUnspsc190501.50555416': __('identifierSchemeCodeUnspsc190501.50555416'),
	'identifierSchemeCodeUnspsc190501.50555417': __('identifierSchemeCodeUnspsc190501.50555417'),
	'identifierSchemeCodeUnspsc190501.50555418': __('identifierSchemeCodeUnspsc190501.50555418'),
	'identifierSchemeCodeUnspsc190501.50555419': __('identifierSchemeCodeUnspsc190501.50555419'),
	'identifierSchemeCodeUnspsc190501.50555420': __('identifierSchemeCodeUnspsc190501.50555420'),
	'identifierSchemeCodeUnspsc190501.50555421': __('identifierSchemeCodeUnspsc190501.50555421'),
	'identifierSchemeCodeUnspsc190501.50555422': __('identifierSchemeCodeUnspsc190501.50555422'),
	'identifierSchemeCodeUnspsc190501.50555423': __('identifierSchemeCodeUnspsc190501.50555423'),
	'identifierSchemeCodeUnspsc190501.50555424': __('identifierSchemeCodeUnspsc190501.50555424'),
	'identifierSchemeCodeUnspsc190501.50555425': __('identifierSchemeCodeUnspsc190501.50555425'),
	'identifierSchemeCodeUnspsc190501.50555426': __('identifierSchemeCodeUnspsc190501.50555426'),
	'identifierSchemeCodeUnspsc190501.50555427': __('identifierSchemeCodeUnspsc190501.50555427'),
	'identifierSchemeCodeUnspsc190501.50555428': __('identifierSchemeCodeUnspsc190501.50555428'),
	'identifierSchemeCodeUnspsc190501.50555429': __('identifierSchemeCodeUnspsc190501.50555429'),
	'identifierSchemeCodeUnspsc190501.50555430': __('identifierSchemeCodeUnspsc190501.50555430'),
	'identifierSchemeCodeUnspsc190501.50555431': __('identifierSchemeCodeUnspsc190501.50555431'),
	'identifierSchemeCodeUnspsc190501.50555432': __('identifierSchemeCodeUnspsc190501.50555432'),
	'identifierSchemeCodeUnspsc190501.50555433': __('identifierSchemeCodeUnspsc190501.50555433'),
	'identifierSchemeCodeUnspsc190501.50555434': __('identifierSchemeCodeUnspsc190501.50555434'),
	'identifierSchemeCodeUnspsc190501.50555435': __('identifierSchemeCodeUnspsc190501.50555435'),
	'identifierSchemeCodeUnspsc190501.50555436': __('identifierSchemeCodeUnspsc190501.50555436'),
	'identifierSchemeCodeUnspsc190501.50555437': __('identifierSchemeCodeUnspsc190501.50555437'),
	'identifierSchemeCodeUnspsc190501.50555438': __('identifierSchemeCodeUnspsc190501.50555438'),
	'identifierSchemeCodeUnspsc190501.50555439': __('identifierSchemeCodeUnspsc190501.50555439'),
	'identifierSchemeCodeUnspsc190501.50555440': __('identifierSchemeCodeUnspsc190501.50555440'),
	'identifierSchemeCodeUnspsc190501.50555441': __('identifierSchemeCodeUnspsc190501.50555441'),
	'identifierSchemeCodeUnspsc190501.50555442': __('identifierSchemeCodeUnspsc190501.50555442'),
	'identifierSchemeCodeUnspsc190501.50555443': __('identifierSchemeCodeUnspsc190501.50555443'),
	'identifierSchemeCodeUnspsc190501.50555444': __('identifierSchemeCodeUnspsc190501.50555444'),
	'identifierSchemeCodeUnspsc190501.50555445': __('identifierSchemeCodeUnspsc190501.50555445'),
	'identifierSchemeCodeUnspsc190501.50555446': __('identifierSchemeCodeUnspsc190501.50555446'),
	'identifierSchemeCodeUnspsc190501.50555447': __('identifierSchemeCodeUnspsc190501.50555447'),
	'identifierSchemeCodeUnspsc190501.50555448': __('identifierSchemeCodeUnspsc190501.50555448'),
	'identifierSchemeCodeUnspsc190501.50555449': __('identifierSchemeCodeUnspsc190501.50555449'),
	'identifierSchemeCodeUnspsc190501.50555450': __('identifierSchemeCodeUnspsc190501.50555450'),
	'identifierSchemeCodeUnspsc190501.50555451': __('identifierSchemeCodeUnspsc190501.50555451'),
	'identifierSchemeCodeUnspsc190501.50555452': __('identifierSchemeCodeUnspsc190501.50555452'),
	'identifierSchemeCodeUnspsc190501.50555453': __('identifierSchemeCodeUnspsc190501.50555453'),
	'identifierSchemeCodeUnspsc190501.50555454': __('identifierSchemeCodeUnspsc190501.50555454'),
	'identifierSchemeCodeUnspsc190501.50555455': __('identifierSchemeCodeUnspsc190501.50555455'),
	'identifierSchemeCodeUnspsc190501.50555456': __('identifierSchemeCodeUnspsc190501.50555456'),
	'identifierSchemeCodeUnspsc190501.50555457': __('identifierSchemeCodeUnspsc190501.50555457'),
	'identifierSchemeCodeUnspsc190501.50555458': __('identifierSchemeCodeUnspsc190501.50555458'),
	'identifierSchemeCodeUnspsc190501.50555459': __('identifierSchemeCodeUnspsc190501.50555459'),
	'identifierSchemeCodeUnspsc190501.50555460': __('identifierSchemeCodeUnspsc190501.50555460'),
	'identifierSchemeCodeUnspsc190501.50555500': __('identifierSchemeCodeUnspsc190501.50555500'),
	'identifierSchemeCodeUnspsc190501.50555501': __('identifierSchemeCodeUnspsc190501.50555501'),
	'identifierSchemeCodeUnspsc190501.50555502': __('identifierSchemeCodeUnspsc190501.50555502'),
	'identifierSchemeCodeUnspsc190501.50555503': __('identifierSchemeCodeUnspsc190501.50555503'),
	'identifierSchemeCodeUnspsc190501.50555504': __('identifierSchemeCodeUnspsc190501.50555504'),
	'identifierSchemeCodeUnspsc190501.50555505': __('identifierSchemeCodeUnspsc190501.50555505'),
	'identifierSchemeCodeUnspsc190501.50555506': __('identifierSchemeCodeUnspsc190501.50555506'),
	'identifierSchemeCodeUnspsc190501.50555507': __('identifierSchemeCodeUnspsc190501.50555507'),
	'identifierSchemeCodeUnspsc190501.50555508': __('identifierSchemeCodeUnspsc190501.50555508'),
	'identifierSchemeCodeUnspsc190501.50555509': __('identifierSchemeCodeUnspsc190501.50555509'),
	'identifierSchemeCodeUnspsc190501.50555510': __('identifierSchemeCodeUnspsc190501.50555510'),
	'identifierSchemeCodeUnspsc190501.50555511': __('identifierSchemeCodeUnspsc190501.50555511'),
	'identifierSchemeCodeUnspsc190501.50555512': __('identifierSchemeCodeUnspsc190501.50555512'),
	'identifierSchemeCodeUnspsc190501.50555600': __('identifierSchemeCodeUnspsc190501.50555600'),
	'identifierSchemeCodeUnspsc190501.50555601': __('identifierSchemeCodeUnspsc190501.50555601'),
	'identifierSchemeCodeUnspsc190501.50555602': __('identifierSchemeCodeUnspsc190501.50555602'),
	'identifierSchemeCodeUnspsc190501.50555603': __('identifierSchemeCodeUnspsc190501.50555603'),
	'identifierSchemeCodeUnspsc190501.50555604': __('identifierSchemeCodeUnspsc190501.50555604'),
	'identifierSchemeCodeUnspsc190501.50555605': __('identifierSchemeCodeUnspsc190501.50555605'),
	'identifierSchemeCodeUnspsc190501.50555606': __('identifierSchemeCodeUnspsc190501.50555606'),
	'identifierSchemeCodeUnspsc190501.50555607': __('identifierSchemeCodeUnspsc190501.50555607'),
	'identifierSchemeCodeUnspsc190501.50555608': __('identifierSchemeCodeUnspsc190501.50555608'),
	'identifierSchemeCodeUnspsc190501.50555609': __('identifierSchemeCodeUnspsc190501.50555609'),
	'identifierSchemeCodeUnspsc190501.50555610': __('identifierSchemeCodeUnspsc190501.50555610'),
	'identifierSchemeCodeUnspsc190501.50555700': __('identifierSchemeCodeUnspsc190501.50555700'),
	'identifierSchemeCodeUnspsc190501.50555701': __('identifierSchemeCodeUnspsc190501.50555701'),
	'identifierSchemeCodeUnspsc190501.50555702': __('identifierSchemeCodeUnspsc190501.50555702'),
	'identifierSchemeCodeUnspsc190501.50555703': __('identifierSchemeCodeUnspsc190501.50555703'),
	'identifierSchemeCodeUnspsc190501.50555704': __('identifierSchemeCodeUnspsc190501.50555704'),
	'identifierSchemeCodeUnspsc190501.50555705': __('identifierSchemeCodeUnspsc190501.50555705'),
	'identifierSchemeCodeUnspsc190501.50555706': __('identifierSchemeCodeUnspsc190501.50555706'),
	'identifierSchemeCodeUnspsc190501.50555707': __('identifierSchemeCodeUnspsc190501.50555707'),
	'identifierSchemeCodeUnspsc190501.50555708': __('identifierSchemeCodeUnspsc190501.50555708'),
	'identifierSchemeCodeUnspsc190501.50555709': __('identifierSchemeCodeUnspsc190501.50555709'),
	'identifierSchemeCodeUnspsc190501.50555710': __('identifierSchemeCodeUnspsc190501.50555710'),
	'identifierSchemeCodeUnspsc190501.50555711': __('identifierSchemeCodeUnspsc190501.50555711'),
	'identifierSchemeCodeUnspsc190501.50555712': __('identifierSchemeCodeUnspsc190501.50555712'),
	'identifierSchemeCodeUnspsc190501.50555713': __('identifierSchemeCodeUnspsc190501.50555713'),
	'identifierSchemeCodeUnspsc190501.50555714': __('identifierSchemeCodeUnspsc190501.50555714'),
	'identifierSchemeCodeUnspsc190501.50555715': __('identifierSchemeCodeUnspsc190501.50555715'),
	'identifierSchemeCodeUnspsc190501.50555716': __('identifierSchemeCodeUnspsc190501.50555716'),
	'identifierSchemeCodeUnspsc190501.50555717': __('identifierSchemeCodeUnspsc190501.50555717'),
	'identifierSchemeCodeUnspsc190501.50555718': __('identifierSchemeCodeUnspsc190501.50555718'),
	'identifierSchemeCodeUnspsc190501.50555719': __('identifierSchemeCodeUnspsc190501.50555719'),
	'identifierSchemeCodeUnspsc190501.50555720': __('identifierSchemeCodeUnspsc190501.50555720'),
	'identifierSchemeCodeUnspsc190501.50555721': __('identifierSchemeCodeUnspsc190501.50555721'),
	'identifierSchemeCodeUnspsc190501.50555722': __('identifierSchemeCodeUnspsc190501.50555722'),
	'identifierSchemeCodeUnspsc190501.50555723': __('identifierSchemeCodeUnspsc190501.50555723'),
	'identifierSchemeCodeUnspsc190501.50555800': __('identifierSchemeCodeUnspsc190501.50555800'),
	'identifierSchemeCodeUnspsc190501.50555801': __('identifierSchemeCodeUnspsc190501.50555801'),
	'identifierSchemeCodeUnspsc190501.50555802': __('identifierSchemeCodeUnspsc190501.50555802'),
	'identifierSchemeCodeUnspsc190501.50555803': __('identifierSchemeCodeUnspsc190501.50555803'),
	'identifierSchemeCodeUnspsc190501.50555804': __('identifierSchemeCodeUnspsc190501.50555804'),
	'identifierSchemeCodeUnspsc190501.50555805': __('identifierSchemeCodeUnspsc190501.50555805'),
	'identifierSchemeCodeUnspsc190501.50555806': __('identifierSchemeCodeUnspsc190501.50555806'),
	'identifierSchemeCodeUnspsc190501.50555807': __('identifierSchemeCodeUnspsc190501.50555807'),
	'identifierSchemeCodeUnspsc190501.50555808': __('identifierSchemeCodeUnspsc190501.50555808'),
	'identifierSchemeCodeUnspsc190501.50555809': __('identifierSchemeCodeUnspsc190501.50555809'),
	'identifierSchemeCodeUnspsc190501.50555810': __('identifierSchemeCodeUnspsc190501.50555810'),
	'identifierSchemeCodeUnspsc190501.50555811': __('identifierSchemeCodeUnspsc190501.50555811'),
	'identifierSchemeCodeUnspsc190501.50555812': __('identifierSchemeCodeUnspsc190501.50555812'),
	'identifierSchemeCodeUnspsc190501.50555813': __('identifierSchemeCodeUnspsc190501.50555813'),
	'identifierSchemeCodeUnspsc190501.50555814': __('identifierSchemeCodeUnspsc190501.50555814'),
	'identifierSchemeCodeUnspsc190501.50555815': __('identifierSchemeCodeUnspsc190501.50555815'),
	'identifierSchemeCodeUnspsc190501.50555816': __('identifierSchemeCodeUnspsc190501.50555816'),
	'identifierSchemeCodeUnspsc190501.50555817': __('identifierSchemeCodeUnspsc190501.50555817'),
	'identifierSchemeCodeUnspsc190501.50555818': __('identifierSchemeCodeUnspsc190501.50555818'),
	'identifierSchemeCodeUnspsc190501.50555819': __('identifierSchemeCodeUnspsc190501.50555819'),
	'identifierSchemeCodeUnspsc190501.50555820': __('identifierSchemeCodeUnspsc190501.50555820'),
	'identifierSchemeCodeUnspsc190501.50555821': __('identifierSchemeCodeUnspsc190501.50555821'),
	'identifierSchemeCodeUnspsc190501.50555822': __('identifierSchemeCodeUnspsc190501.50555822'),
	'identifierSchemeCodeUnspsc190501.50555823': __('identifierSchemeCodeUnspsc190501.50555823'),
	'identifierSchemeCodeUnspsc190501.50555824': __('identifierSchemeCodeUnspsc190501.50555824'),
	'identifierSchemeCodeUnspsc190501.50555825': __('identifierSchemeCodeUnspsc190501.50555825'),
	'identifierSchemeCodeUnspsc190501.50555826': __('identifierSchemeCodeUnspsc190501.50555826'),
	'identifierSchemeCodeUnspsc190501.50555827': __('identifierSchemeCodeUnspsc190501.50555827'),
	'identifierSchemeCodeUnspsc190501.50555828': __('identifierSchemeCodeUnspsc190501.50555828'),
	'identifierSchemeCodeUnspsc190501.50555829': __('identifierSchemeCodeUnspsc190501.50555829'),
	'identifierSchemeCodeUnspsc190501.50555830': __('identifierSchemeCodeUnspsc190501.50555830'),
	'identifierSchemeCodeUnspsc190501.50555831': __('identifierSchemeCodeUnspsc190501.50555831'),
	'identifierSchemeCodeUnspsc190501.50555832': __('identifierSchemeCodeUnspsc190501.50555832'),
	'identifierSchemeCodeUnspsc190501.50555833': __('identifierSchemeCodeUnspsc190501.50555833'),
	'identifierSchemeCodeUnspsc190501.50555834': __('identifierSchemeCodeUnspsc190501.50555834'),
	'identifierSchemeCodeUnspsc190501.50555835': __('identifierSchemeCodeUnspsc190501.50555835'),
	'identifierSchemeCodeUnspsc190501.50555836': __('identifierSchemeCodeUnspsc190501.50555836'),
	'identifierSchemeCodeUnspsc190501.50555837': __('identifierSchemeCodeUnspsc190501.50555837'),
	'identifierSchemeCodeUnspsc190501.50555838': __('identifierSchemeCodeUnspsc190501.50555838'),
	'identifierSchemeCodeUnspsc190501.50555839': __('identifierSchemeCodeUnspsc190501.50555839'),
	'identifierSchemeCodeUnspsc190501.50555840': __('identifierSchemeCodeUnspsc190501.50555840'),
	'identifierSchemeCodeUnspsc190501.50555841': __('identifierSchemeCodeUnspsc190501.50555841'),
	'identifierSchemeCodeUnspsc190501.50555842': __('identifierSchemeCodeUnspsc190501.50555842'),
	'identifierSchemeCodeUnspsc190501.50555843': __('identifierSchemeCodeUnspsc190501.50555843'),
	'identifierSchemeCodeUnspsc190501.50555844': __('identifierSchemeCodeUnspsc190501.50555844'),
	'identifierSchemeCodeUnspsc190501.50555845': __('identifierSchemeCodeUnspsc190501.50555845'),
	'identifierSchemeCodeUnspsc190501.50555846': __('identifierSchemeCodeUnspsc190501.50555846'),
	'identifierSchemeCodeUnspsc190501.50555847': __('identifierSchemeCodeUnspsc190501.50555847'),
	'identifierSchemeCodeUnspsc190501.50555848': __('identifierSchemeCodeUnspsc190501.50555848'),
	'identifierSchemeCodeUnspsc190501.50555849': __('identifierSchemeCodeUnspsc190501.50555849'),
	'identifierSchemeCodeUnspsc190501.50555850': __('identifierSchemeCodeUnspsc190501.50555850'),
	'identifierSchemeCodeUnspsc190501.50555851': __('identifierSchemeCodeUnspsc190501.50555851'),
	'identifierSchemeCodeUnspsc190501.50555852': __('identifierSchemeCodeUnspsc190501.50555852'),
	'identifierSchemeCodeUnspsc190501.50555853': __('identifierSchemeCodeUnspsc190501.50555853'),
	'identifierSchemeCodeUnspsc190501.50555854': __('identifierSchemeCodeUnspsc190501.50555854'),
	'identifierSchemeCodeUnspsc190501.50555855': __('identifierSchemeCodeUnspsc190501.50555855'),
	'identifierSchemeCodeUnspsc190501.50555856': __('identifierSchemeCodeUnspsc190501.50555856'),
	'identifierSchemeCodeUnspsc190501.50555857': __('identifierSchemeCodeUnspsc190501.50555857'),
	'identifierSchemeCodeUnspsc190501.50555858': __('identifierSchemeCodeUnspsc190501.50555858'),
	'identifierSchemeCodeUnspsc190501.50555859': __('identifierSchemeCodeUnspsc190501.50555859'),
	'identifierSchemeCodeUnspsc190501.50555860': __('identifierSchemeCodeUnspsc190501.50555860'),
	'identifierSchemeCodeUnspsc190501.50555861': __('identifierSchemeCodeUnspsc190501.50555861'),
	'identifierSchemeCodeUnspsc190501.50555862': __('identifierSchemeCodeUnspsc190501.50555862'),
	'identifierSchemeCodeUnspsc190501.50555863': __('identifierSchemeCodeUnspsc190501.50555863'),
	'identifierSchemeCodeUnspsc190501.50555864': __('identifierSchemeCodeUnspsc190501.50555864'),
	'identifierSchemeCodeUnspsc190501.50555865': __('identifierSchemeCodeUnspsc190501.50555865'),
	'identifierSchemeCodeUnspsc190501.50555866': __('identifierSchemeCodeUnspsc190501.50555866'),
	'identifierSchemeCodeUnspsc190501.50555867': __('identifierSchemeCodeUnspsc190501.50555867'),
	'identifierSchemeCodeUnspsc190501.50555868': __('identifierSchemeCodeUnspsc190501.50555868'),
	'identifierSchemeCodeUnspsc190501.50555869': __('identifierSchemeCodeUnspsc190501.50555869'),
	'identifierSchemeCodeUnspsc190501.50555870': __('identifierSchemeCodeUnspsc190501.50555870'),
	'identifierSchemeCodeUnspsc190501.50555871': __('identifierSchemeCodeUnspsc190501.50555871'),
	'identifierSchemeCodeUnspsc190501.50555872': __('identifierSchemeCodeUnspsc190501.50555872'),
	'identifierSchemeCodeUnspsc190501.50555873': __('identifierSchemeCodeUnspsc190501.50555873'),
	'identifierSchemeCodeUnspsc190501.50555874': __('identifierSchemeCodeUnspsc190501.50555874'),
	'identifierSchemeCodeUnspsc190501.50555875': __('identifierSchemeCodeUnspsc190501.50555875'),
	'identifierSchemeCodeUnspsc190501.50555876': __('identifierSchemeCodeUnspsc190501.50555876'),
	'identifierSchemeCodeUnspsc190501.50555877': __('identifierSchemeCodeUnspsc190501.50555877'),
	'identifierSchemeCodeUnspsc190501.50555900': __('identifierSchemeCodeUnspsc190501.50555900'),
	'identifierSchemeCodeUnspsc190501.50555901': __('identifierSchemeCodeUnspsc190501.50555901'),
	'identifierSchemeCodeUnspsc190501.50555902': __('identifierSchemeCodeUnspsc190501.50555902'),
	'identifierSchemeCodeUnspsc190501.50555903': __('identifierSchemeCodeUnspsc190501.50555903'),
	'identifierSchemeCodeUnspsc190501.50555904': __('identifierSchemeCodeUnspsc190501.50555904'),
	'identifierSchemeCodeUnspsc190501.50555905': __('identifierSchemeCodeUnspsc190501.50555905'),
	'identifierSchemeCodeUnspsc190501.50555906': __('identifierSchemeCodeUnspsc190501.50555906'),
	'identifierSchemeCodeUnspsc190501.50555907': __('identifierSchemeCodeUnspsc190501.50555907'),
	'identifierSchemeCodeUnspsc190501.50555908': __('identifierSchemeCodeUnspsc190501.50555908'),
	'identifierSchemeCodeUnspsc190501.50556000': __('identifierSchemeCodeUnspsc190501.50556000'),
	'identifierSchemeCodeUnspsc190501.50556001': __('identifierSchemeCodeUnspsc190501.50556001'),
	'identifierSchemeCodeUnspsc190501.50556002': __('identifierSchemeCodeUnspsc190501.50556002'),
	'identifierSchemeCodeUnspsc190501.50556003': __('identifierSchemeCodeUnspsc190501.50556003'),
	'identifierSchemeCodeUnspsc190501.50556004': __('identifierSchemeCodeUnspsc190501.50556004'),
	'identifierSchemeCodeUnspsc190501.50556005': __('identifierSchemeCodeUnspsc190501.50556005'),
	'identifierSchemeCodeUnspsc190501.50556006': __('identifierSchemeCodeUnspsc190501.50556006'),
	'identifierSchemeCodeUnspsc190501.50556007': __('identifierSchemeCodeUnspsc190501.50556007'),
	'identifierSchemeCodeUnspsc190501.50556008': __('identifierSchemeCodeUnspsc190501.50556008'),
	'identifierSchemeCodeUnspsc190501.50556100': __('identifierSchemeCodeUnspsc190501.50556100'),
	'identifierSchemeCodeUnspsc190501.50556101': __('identifierSchemeCodeUnspsc190501.50556101'),
	'identifierSchemeCodeUnspsc190501.50556102': __('identifierSchemeCodeUnspsc190501.50556102'),
	'identifierSchemeCodeUnspsc190501.50556103': __('identifierSchemeCodeUnspsc190501.50556103'),
	'identifierSchemeCodeUnspsc190501.50556200': __('identifierSchemeCodeUnspsc190501.50556200'),
	'identifierSchemeCodeUnspsc190501.50556201': __('identifierSchemeCodeUnspsc190501.50556201'),
	'identifierSchemeCodeUnspsc190501.50556202': __('identifierSchemeCodeUnspsc190501.50556202'),
	'identifierSchemeCodeUnspsc190501.50556203': __('identifierSchemeCodeUnspsc190501.50556203'),
	'identifierSchemeCodeUnspsc190501.50556204': __('identifierSchemeCodeUnspsc190501.50556204'),
	'identifierSchemeCodeUnspsc190501.50556205': __('identifierSchemeCodeUnspsc190501.50556205'),
	'identifierSchemeCodeUnspsc190501.50556206': __('identifierSchemeCodeUnspsc190501.50556206'),
	'identifierSchemeCodeUnspsc190501.50556207': __('identifierSchemeCodeUnspsc190501.50556207'),
	'identifierSchemeCodeUnspsc190501.50556208': __('identifierSchemeCodeUnspsc190501.50556208'),
	'identifierSchemeCodeUnspsc190501.50556209': __('identifierSchemeCodeUnspsc190501.50556209'),
	'identifierSchemeCodeUnspsc190501.50556210': __('identifierSchemeCodeUnspsc190501.50556210'),
	'identifierSchemeCodeUnspsc190501.50556211': __('identifierSchemeCodeUnspsc190501.50556211'),
	'identifierSchemeCodeUnspsc190501.50556212': __('identifierSchemeCodeUnspsc190501.50556212'),
	'identifierSchemeCodeUnspsc190501.50556213': __('identifierSchemeCodeUnspsc190501.50556213'),
	'identifierSchemeCodeUnspsc190501.50556214': __('identifierSchemeCodeUnspsc190501.50556214'),
	'identifierSchemeCodeUnspsc190501.50556215': __('identifierSchemeCodeUnspsc190501.50556215'),
	'identifierSchemeCodeUnspsc190501.50556216': __('identifierSchemeCodeUnspsc190501.50556216'),
	'identifierSchemeCodeUnspsc190501.50556217': __('identifierSchemeCodeUnspsc190501.50556217'),
	'identifierSchemeCodeUnspsc190501.50556218': __('identifierSchemeCodeUnspsc190501.50556218'),
	'identifierSchemeCodeUnspsc190501.50556219': __('identifierSchemeCodeUnspsc190501.50556219'),
	'identifierSchemeCodeUnspsc190501.50556220': __('identifierSchemeCodeUnspsc190501.50556220'),
	'identifierSchemeCodeUnspsc190501.50556221': __('identifierSchemeCodeUnspsc190501.50556221'),
	'identifierSchemeCodeUnspsc190501.50556222': __('identifierSchemeCodeUnspsc190501.50556222'),
	'identifierSchemeCodeUnspsc190501.50556223': __('identifierSchemeCodeUnspsc190501.50556223'),
	'identifierSchemeCodeUnspsc190501.50556224': __('identifierSchemeCodeUnspsc190501.50556224'),
	'identifierSchemeCodeUnspsc190501.50556225': __('identifierSchemeCodeUnspsc190501.50556225'),
	'identifierSchemeCodeUnspsc190501.50556226': __('identifierSchemeCodeUnspsc190501.50556226'),
	'identifierSchemeCodeUnspsc190501.50556227': __('identifierSchemeCodeUnspsc190501.50556227'),
	'identifierSchemeCodeUnspsc190501.50556228': __('identifierSchemeCodeUnspsc190501.50556228'),
	'identifierSchemeCodeUnspsc190501.50556229': __('identifierSchemeCodeUnspsc190501.50556229'),
	'identifierSchemeCodeUnspsc190501.50556300': __('identifierSchemeCodeUnspsc190501.50556300'),
	'identifierSchemeCodeUnspsc190501.50556301': __('identifierSchemeCodeUnspsc190501.50556301'),
	'identifierSchemeCodeUnspsc190501.50556302': __('identifierSchemeCodeUnspsc190501.50556302'),
	'identifierSchemeCodeUnspsc190501.50556303': __('identifierSchemeCodeUnspsc190501.50556303'),
	'identifierSchemeCodeUnspsc190501.50556304': __('identifierSchemeCodeUnspsc190501.50556304'),
	'identifierSchemeCodeUnspsc190501.50556305': __('identifierSchemeCodeUnspsc190501.50556305'),
	'identifierSchemeCodeUnspsc190501.50556306': __('identifierSchemeCodeUnspsc190501.50556306'),
	'identifierSchemeCodeUnspsc190501.50556307': __('identifierSchemeCodeUnspsc190501.50556307'),
	'identifierSchemeCodeUnspsc190501.50556308': __('identifierSchemeCodeUnspsc190501.50556308'),
	'identifierSchemeCodeUnspsc190501.50556309': __('identifierSchemeCodeUnspsc190501.50556309'),
	'identifierSchemeCodeUnspsc190501.50556310': __('identifierSchemeCodeUnspsc190501.50556310'),
	'identifierSchemeCodeUnspsc190501.50556400': __('identifierSchemeCodeUnspsc190501.50556400'),
	'identifierSchemeCodeUnspsc190501.50556401': __('identifierSchemeCodeUnspsc190501.50556401'),
	'identifierSchemeCodeUnspsc190501.50556402': __('identifierSchemeCodeUnspsc190501.50556402'),
	'identifierSchemeCodeUnspsc190501.50556403': __('identifierSchemeCodeUnspsc190501.50556403'),
	'identifierSchemeCodeUnspsc190501.50556404': __('identifierSchemeCodeUnspsc190501.50556404'),
	'identifierSchemeCodeUnspsc190501.50556500': __('identifierSchemeCodeUnspsc190501.50556500'),
	'identifierSchemeCodeUnspsc190501.50556501': __('identifierSchemeCodeUnspsc190501.50556501'),
	'identifierSchemeCodeUnspsc190501.50556502': __('identifierSchemeCodeUnspsc190501.50556502'),
	'identifierSchemeCodeUnspsc190501.50556600': __('identifierSchemeCodeUnspsc190501.50556600'),
	'identifierSchemeCodeUnspsc190501.50556601': __('identifierSchemeCodeUnspsc190501.50556601'),
	'identifierSchemeCodeUnspsc190501.50556602': __('identifierSchemeCodeUnspsc190501.50556602'),
	'identifierSchemeCodeUnspsc190501.50556603': __('identifierSchemeCodeUnspsc190501.50556603'),
	'identifierSchemeCodeUnspsc190501.50556604': __('identifierSchemeCodeUnspsc190501.50556604'),
	'identifierSchemeCodeUnspsc190501.50556700': __('identifierSchemeCodeUnspsc190501.50556700'),
	'identifierSchemeCodeUnspsc190501.50556701': __('identifierSchemeCodeUnspsc190501.50556701'),
	'identifierSchemeCodeUnspsc190501.50556702': __('identifierSchemeCodeUnspsc190501.50556702'),
	'identifierSchemeCodeUnspsc190501.50556703': __('identifierSchemeCodeUnspsc190501.50556703'),
	'identifierSchemeCodeUnspsc190501.50556800': __('identifierSchemeCodeUnspsc190501.50556800'),
	'identifierSchemeCodeUnspsc190501.50556801': __('identifierSchemeCodeUnspsc190501.50556801'),
	'identifierSchemeCodeUnspsc190501.50556802': __('identifierSchemeCodeUnspsc190501.50556802'),
	'identifierSchemeCodeUnspsc190501.50556803': __('identifierSchemeCodeUnspsc190501.50556803'),
	'identifierSchemeCodeUnspsc190501.50556900': __('identifierSchemeCodeUnspsc190501.50556900'),
	'identifierSchemeCodeUnspsc190501.50556901': __('identifierSchemeCodeUnspsc190501.50556901'),
	'identifierSchemeCodeUnspsc190501.50556902': __('identifierSchemeCodeUnspsc190501.50556902'),
	'identifierSchemeCodeUnspsc190501.50556903': __('identifierSchemeCodeUnspsc190501.50556903'),
	'identifierSchemeCodeUnspsc190501.50556904': __('identifierSchemeCodeUnspsc190501.50556904'),
	'identifierSchemeCodeUnspsc190501.50556905': __('identifierSchemeCodeUnspsc190501.50556905'),
	'identifierSchemeCodeUnspsc190501.50556906': __('identifierSchemeCodeUnspsc190501.50556906'),
	'identifierSchemeCodeUnspsc190501.50557000': __('identifierSchemeCodeUnspsc190501.50557000'),
	'identifierSchemeCodeUnspsc190501.50557001': __('identifierSchemeCodeUnspsc190501.50557001'),
	'identifierSchemeCodeUnspsc190501.50557002': __('identifierSchemeCodeUnspsc190501.50557002'),
	'identifierSchemeCodeUnspsc190501.50557003': __('identifierSchemeCodeUnspsc190501.50557003'),
	'identifierSchemeCodeUnspsc190501.50557004': __('identifierSchemeCodeUnspsc190501.50557004'),
	'identifierSchemeCodeUnspsc190501.50557005': __('identifierSchemeCodeUnspsc190501.50557005'),
	'identifierSchemeCodeUnspsc190501.50557006': __('identifierSchemeCodeUnspsc190501.50557006'),
	'identifierSchemeCodeUnspsc190501.50557007': __('identifierSchemeCodeUnspsc190501.50557007'),
	'identifierSchemeCodeUnspsc190501.50557008': __('identifierSchemeCodeUnspsc190501.50557008'),
	'identifierSchemeCodeUnspsc190501.50557009': __('identifierSchemeCodeUnspsc190501.50557009'),
	'identifierSchemeCodeUnspsc190501.50557010': __('identifierSchemeCodeUnspsc190501.50557010'),
	'identifierSchemeCodeUnspsc190501.50557011': __('identifierSchemeCodeUnspsc190501.50557011'),
	'identifierSchemeCodeUnspsc190501.50557012': __('identifierSchemeCodeUnspsc190501.50557012'),
	'identifierSchemeCodeUnspsc190501.50557013': __('identifierSchemeCodeUnspsc190501.50557013'),
	'identifierSchemeCodeUnspsc190501.50557014': __('identifierSchemeCodeUnspsc190501.50557014'),
	'identifierSchemeCodeUnspsc190501.50557015': __('identifierSchemeCodeUnspsc190501.50557015'),
	'identifierSchemeCodeUnspsc190501.50557016': __('identifierSchemeCodeUnspsc190501.50557016'),
	'identifierSchemeCodeUnspsc190501.50557017': __('identifierSchemeCodeUnspsc190501.50557017'),
	'identifierSchemeCodeUnspsc190501.50557018': __('identifierSchemeCodeUnspsc190501.50557018'),
	'identifierSchemeCodeUnspsc190501.50557019': __('identifierSchemeCodeUnspsc190501.50557019'),
	'identifierSchemeCodeUnspsc190501.50557020': __('identifierSchemeCodeUnspsc190501.50557020'),
	'identifierSchemeCodeUnspsc190501.50557021': __('identifierSchemeCodeUnspsc190501.50557021'),
	'identifierSchemeCodeUnspsc190501.50557022': __('identifierSchemeCodeUnspsc190501.50557022'),
	'identifierSchemeCodeUnspsc190501.50557023': __('identifierSchemeCodeUnspsc190501.50557023'),
	'identifierSchemeCodeUnspsc190501.50557024': __('identifierSchemeCodeUnspsc190501.50557024'),
	'identifierSchemeCodeUnspsc190501.50557025': __('identifierSchemeCodeUnspsc190501.50557025'),
	'identifierSchemeCodeUnspsc190501.50557026': __('identifierSchemeCodeUnspsc190501.50557026'),
	'identifierSchemeCodeUnspsc190501.50557027': __('identifierSchemeCodeUnspsc190501.50557027'),
	'identifierSchemeCodeUnspsc190501.50557028': __('identifierSchemeCodeUnspsc190501.50557028'),
	'identifierSchemeCodeUnspsc190501.50557029': __('identifierSchemeCodeUnspsc190501.50557029'),
	'identifierSchemeCodeUnspsc190501.50557030': __('identifierSchemeCodeUnspsc190501.50557030'),
	'identifierSchemeCodeUnspsc190501.50557031': __('identifierSchemeCodeUnspsc190501.50557031'),
	'identifierSchemeCodeUnspsc190501.50557032': __('identifierSchemeCodeUnspsc190501.50557032'),
	'identifierSchemeCodeUnspsc190501.50557033': __('identifierSchemeCodeUnspsc190501.50557033'),
	'identifierSchemeCodeUnspsc190501.50557034': __('identifierSchemeCodeUnspsc190501.50557034'),
	'identifierSchemeCodeUnspsc190501.50557035': __('identifierSchemeCodeUnspsc190501.50557035'),
	'identifierSchemeCodeUnspsc190501.50557036': __('identifierSchemeCodeUnspsc190501.50557036'),
	'identifierSchemeCodeUnspsc190501.50557037': __('identifierSchemeCodeUnspsc190501.50557037'),
	'identifierSchemeCodeUnspsc190501.50557100': __('identifierSchemeCodeUnspsc190501.50557100'),
	'identifierSchemeCodeUnspsc190501.50557101': __('identifierSchemeCodeUnspsc190501.50557101'),
	'identifierSchemeCodeUnspsc190501.50557102': __('identifierSchemeCodeUnspsc190501.50557102'),
	'identifierSchemeCodeUnspsc190501.50557103': __('identifierSchemeCodeUnspsc190501.50557103'),
	'identifierSchemeCodeUnspsc190501.50557104': __('identifierSchemeCodeUnspsc190501.50557104'),
	'identifierSchemeCodeUnspsc190501.50557200': __('identifierSchemeCodeUnspsc190501.50557200'),
	'identifierSchemeCodeUnspsc190501.50557201': __('identifierSchemeCodeUnspsc190501.50557201'),
	'identifierSchemeCodeUnspsc190501.50557202': __('identifierSchemeCodeUnspsc190501.50557202'),
	'identifierSchemeCodeUnspsc190501.50557203': __('identifierSchemeCodeUnspsc190501.50557203'),
	'identifierSchemeCodeUnspsc190501.50557204': __('identifierSchemeCodeUnspsc190501.50557204'),
	'identifierSchemeCodeUnspsc190501.50557205': __('identifierSchemeCodeUnspsc190501.50557205'),
	'identifierSchemeCodeUnspsc190501.50557206': __('identifierSchemeCodeUnspsc190501.50557206'),
	'identifierSchemeCodeUnspsc190501.50557207': __('identifierSchemeCodeUnspsc190501.50557207'),
	'identifierSchemeCodeUnspsc190501.50557208': __('identifierSchemeCodeUnspsc190501.50557208'),
	'identifierSchemeCodeUnspsc190501.50557209': __('identifierSchemeCodeUnspsc190501.50557209'),
	'identifierSchemeCodeUnspsc190501.50557210': __('identifierSchemeCodeUnspsc190501.50557210'),
	'identifierSchemeCodeUnspsc190501.50557211': __('identifierSchemeCodeUnspsc190501.50557211'),
	'identifierSchemeCodeUnspsc190501.50557212': __('identifierSchemeCodeUnspsc190501.50557212'),
	'identifierSchemeCodeUnspsc190501.50557213': __('identifierSchemeCodeUnspsc190501.50557213'),
	'identifierSchemeCodeUnspsc190501.50557214': __('identifierSchemeCodeUnspsc190501.50557214'),
	'identifierSchemeCodeUnspsc190501.50557215': __('identifierSchemeCodeUnspsc190501.50557215'),
	'identifierSchemeCodeUnspsc190501.50557216': __('identifierSchemeCodeUnspsc190501.50557216'),
	'identifierSchemeCodeUnspsc190501.50557217': __('identifierSchemeCodeUnspsc190501.50557217'),
	'identifierSchemeCodeUnspsc190501.50557218': __('identifierSchemeCodeUnspsc190501.50557218'),
	'identifierSchemeCodeUnspsc190501.50557219': __('identifierSchemeCodeUnspsc190501.50557219'),
	'identifierSchemeCodeUnspsc190501.50557220': __('identifierSchemeCodeUnspsc190501.50557220'),
	'identifierSchemeCodeUnspsc190501.50557221': __('identifierSchemeCodeUnspsc190501.50557221'),
	'identifierSchemeCodeUnspsc190501.50557222': __('identifierSchemeCodeUnspsc190501.50557222'),
	'identifierSchemeCodeUnspsc190501.50557223': __('identifierSchemeCodeUnspsc190501.50557223'),
	'identifierSchemeCodeUnspsc190501.50557224': __('identifierSchemeCodeUnspsc190501.50557224'),
	'identifierSchemeCodeUnspsc190501.50557225': __('identifierSchemeCodeUnspsc190501.50557225'),
	'identifierSchemeCodeUnspsc190501.50557226': __('identifierSchemeCodeUnspsc190501.50557226'),
	'identifierSchemeCodeUnspsc190501.50557227': __('identifierSchemeCodeUnspsc190501.50557227'),
	'identifierSchemeCodeUnspsc190501.50557228': __('identifierSchemeCodeUnspsc190501.50557228'),
	'identifierSchemeCodeUnspsc190501.50557229': __('identifierSchemeCodeUnspsc190501.50557229'),
	'identifierSchemeCodeUnspsc190501.50557230': __('identifierSchemeCodeUnspsc190501.50557230'),
	'identifierSchemeCodeUnspsc190501.50560000': __('identifierSchemeCodeUnspsc190501.50560000'),
	'identifierSchemeCodeUnspsc190501.50561500': __('identifierSchemeCodeUnspsc190501.50561500'),
	'identifierSchemeCodeUnspsc190501.50561501': __('identifierSchemeCodeUnspsc190501.50561501'),
	'identifierSchemeCodeUnspsc190501.50561502': __('identifierSchemeCodeUnspsc190501.50561502'),
	'identifierSchemeCodeUnspsc190501.50561503': __('identifierSchemeCodeUnspsc190501.50561503'),
	'identifierSchemeCodeUnspsc190501.50561504': __('identifierSchemeCodeUnspsc190501.50561504'),
	'identifierSchemeCodeUnspsc190501.50561505': __('identifierSchemeCodeUnspsc190501.50561505'),
	'identifierSchemeCodeUnspsc190501.50561506': __('identifierSchemeCodeUnspsc190501.50561506'),
	'identifierSchemeCodeUnspsc190501.50561507': __('identifierSchemeCodeUnspsc190501.50561507'),
	'identifierSchemeCodeUnspsc190501.50561508': __('identifierSchemeCodeUnspsc190501.50561508'),
	'identifierSchemeCodeUnspsc190501.50561509': __('identifierSchemeCodeUnspsc190501.50561509'),
	'identifierSchemeCodeUnspsc190501.50561510': __('identifierSchemeCodeUnspsc190501.50561510'),
	'identifierSchemeCodeUnspsc190501.50561511': __('identifierSchemeCodeUnspsc190501.50561511'),
	'identifierSchemeCodeUnspsc190501.50561512': __('identifierSchemeCodeUnspsc190501.50561512'),
	'identifierSchemeCodeUnspsc190501.50561513': __('identifierSchemeCodeUnspsc190501.50561513'),
	'identifierSchemeCodeUnspsc190501.50561514': __('identifierSchemeCodeUnspsc190501.50561514'),
	'identifierSchemeCodeUnspsc190501.50561515': __('identifierSchemeCodeUnspsc190501.50561515'),
	'identifierSchemeCodeUnspsc190501.50561516': __('identifierSchemeCodeUnspsc190501.50561516'),
	'identifierSchemeCodeUnspsc190501.50561517': __('identifierSchemeCodeUnspsc190501.50561517'),
	'identifierSchemeCodeUnspsc190501.50561518': __('identifierSchemeCodeUnspsc190501.50561518'),
	'identifierSchemeCodeUnspsc190501.50561519': __('identifierSchemeCodeUnspsc190501.50561519'),
	'identifierSchemeCodeUnspsc190501.50561520': __('identifierSchemeCodeUnspsc190501.50561520'),
	'identifierSchemeCodeUnspsc190501.50561521': __('identifierSchemeCodeUnspsc190501.50561521'),
	'identifierSchemeCodeUnspsc190501.50561522': __('identifierSchemeCodeUnspsc190501.50561522'),
	'identifierSchemeCodeUnspsc190501.50561523': __('identifierSchemeCodeUnspsc190501.50561523'),
	'identifierSchemeCodeUnspsc190501.50561524': __('identifierSchemeCodeUnspsc190501.50561524'),
	'identifierSchemeCodeUnspsc190501.50561525': __('identifierSchemeCodeUnspsc190501.50561525'),
	'identifierSchemeCodeUnspsc190501.50561526': __('identifierSchemeCodeUnspsc190501.50561526'),
	'identifierSchemeCodeUnspsc190501.50561527': __('identifierSchemeCodeUnspsc190501.50561527'),
	'identifierSchemeCodeUnspsc190501.50561528': __('identifierSchemeCodeUnspsc190501.50561528'),
	'identifierSchemeCodeUnspsc190501.50561529': __('identifierSchemeCodeUnspsc190501.50561529'),
	'identifierSchemeCodeUnspsc190501.50561530': __('identifierSchemeCodeUnspsc190501.50561530'),
	'identifierSchemeCodeUnspsc190501.50561531': __('identifierSchemeCodeUnspsc190501.50561531'),
	'identifierSchemeCodeUnspsc190501.50561532': __('identifierSchemeCodeUnspsc190501.50561532'),
	'identifierSchemeCodeUnspsc190501.50561533': __('identifierSchemeCodeUnspsc190501.50561533'),
	'identifierSchemeCodeUnspsc190501.50561534': __('identifierSchemeCodeUnspsc190501.50561534'),
	'identifierSchemeCodeUnspsc190501.50561535': __('identifierSchemeCodeUnspsc190501.50561535'),
	'identifierSchemeCodeUnspsc190501.50561536': __('identifierSchemeCodeUnspsc190501.50561536'),
	'identifierSchemeCodeUnspsc190501.50561537': __('identifierSchemeCodeUnspsc190501.50561537'),
	'identifierSchemeCodeUnspsc190501.50561538': __('identifierSchemeCodeUnspsc190501.50561538'),
	'identifierSchemeCodeUnspsc190501.50561539': __('identifierSchemeCodeUnspsc190501.50561539'),
	'identifierSchemeCodeUnspsc190501.50561540': __('identifierSchemeCodeUnspsc190501.50561540'),
	'identifierSchemeCodeUnspsc190501.50561541': __('identifierSchemeCodeUnspsc190501.50561541'),
	'identifierSchemeCodeUnspsc190501.50561542': __('identifierSchemeCodeUnspsc190501.50561542'),
	'identifierSchemeCodeUnspsc190501.50561543': __('identifierSchemeCodeUnspsc190501.50561543'),
	'identifierSchemeCodeUnspsc190501.50561544': __('identifierSchemeCodeUnspsc190501.50561544'),
	'identifierSchemeCodeUnspsc190501.50561545': __('identifierSchemeCodeUnspsc190501.50561545'),
	'identifierSchemeCodeUnspsc190501.50561546': __('identifierSchemeCodeUnspsc190501.50561546'),
	'identifierSchemeCodeUnspsc190501.50561547': __('identifierSchemeCodeUnspsc190501.50561547'),
	'identifierSchemeCodeUnspsc190501.50561548': __('identifierSchemeCodeUnspsc190501.50561548'),
	'identifierSchemeCodeUnspsc190501.50561549': __('identifierSchemeCodeUnspsc190501.50561549'),
	'identifierSchemeCodeUnspsc190501.50561550': __('identifierSchemeCodeUnspsc190501.50561550'),
	'identifierSchemeCodeUnspsc190501.50561551': __('identifierSchemeCodeUnspsc190501.50561551'),
	'identifierSchemeCodeUnspsc190501.50561552': __('identifierSchemeCodeUnspsc190501.50561552'),
	'identifierSchemeCodeUnspsc190501.50561553': __('identifierSchemeCodeUnspsc190501.50561553'),
	'identifierSchemeCodeUnspsc190501.50561554': __('identifierSchemeCodeUnspsc190501.50561554'),
	'identifierSchemeCodeUnspsc190501.50561555': __('identifierSchemeCodeUnspsc190501.50561555'),
	'identifierSchemeCodeUnspsc190501.50561556': __('identifierSchemeCodeUnspsc190501.50561556'),
	'identifierSchemeCodeUnspsc190501.50561557': __('identifierSchemeCodeUnspsc190501.50561557'),
	'identifierSchemeCodeUnspsc190501.50561558': __('identifierSchemeCodeUnspsc190501.50561558'),
	'identifierSchemeCodeUnspsc190501.50561559': __('identifierSchemeCodeUnspsc190501.50561559'),
	'identifierSchemeCodeUnspsc190501.50561560': __('identifierSchemeCodeUnspsc190501.50561560'),
	'identifierSchemeCodeUnspsc190501.50561561': __('identifierSchemeCodeUnspsc190501.50561561'),
	'identifierSchemeCodeUnspsc190501.50561562': __('identifierSchemeCodeUnspsc190501.50561562'),
	'identifierSchemeCodeUnspsc190501.50561563': __('identifierSchemeCodeUnspsc190501.50561563'),
	'identifierSchemeCodeUnspsc190501.50561564': __('identifierSchemeCodeUnspsc190501.50561564'),
	'identifierSchemeCodeUnspsc190501.50561565': __('identifierSchemeCodeUnspsc190501.50561565'),
	'identifierSchemeCodeUnspsc190501.50561566': __('identifierSchemeCodeUnspsc190501.50561566'),
	'identifierSchemeCodeUnspsc190501.50561567': __('identifierSchemeCodeUnspsc190501.50561567'),
	'identifierSchemeCodeUnspsc190501.50561568': __('identifierSchemeCodeUnspsc190501.50561568'),
	'identifierSchemeCodeUnspsc190501.50561569': __('identifierSchemeCodeUnspsc190501.50561569'),
	'identifierSchemeCodeUnspsc190501.50561570': __('identifierSchemeCodeUnspsc190501.50561570'),
	'identifierSchemeCodeUnspsc190501.50561571': __('identifierSchemeCodeUnspsc190501.50561571'),
	'identifierSchemeCodeUnspsc190501.50561572': __('identifierSchemeCodeUnspsc190501.50561572'),
	'identifierSchemeCodeUnspsc190501.50561573': __('identifierSchemeCodeUnspsc190501.50561573'),
	'identifierSchemeCodeUnspsc190501.50561574': __('identifierSchemeCodeUnspsc190501.50561574'),
	'identifierSchemeCodeUnspsc190501.50561575': __('identifierSchemeCodeUnspsc190501.50561575'),
	'identifierSchemeCodeUnspsc190501.50561576': __('identifierSchemeCodeUnspsc190501.50561576'),
	'identifierSchemeCodeUnspsc190501.50561577': __('identifierSchemeCodeUnspsc190501.50561577'),
	'identifierSchemeCodeUnspsc190501.50561578': __('identifierSchemeCodeUnspsc190501.50561578'),
	'identifierSchemeCodeUnspsc190501.50561579': __('identifierSchemeCodeUnspsc190501.50561579'),
	'identifierSchemeCodeUnspsc190501.50561580': __('identifierSchemeCodeUnspsc190501.50561580'),
	'identifierSchemeCodeUnspsc190501.50561581': __('identifierSchemeCodeUnspsc190501.50561581'),
	'identifierSchemeCodeUnspsc190501.50561582': __('identifierSchemeCodeUnspsc190501.50561582'),
	'identifierSchemeCodeUnspsc190501.50561583': __('identifierSchemeCodeUnspsc190501.50561583'),
	'identifierSchemeCodeUnspsc190501.50561584': __('identifierSchemeCodeUnspsc190501.50561584'),
	'identifierSchemeCodeUnspsc190501.50561585': __('identifierSchemeCodeUnspsc190501.50561585'),
	'identifierSchemeCodeUnspsc190501.50561586': __('identifierSchemeCodeUnspsc190501.50561586'),
	'identifierSchemeCodeUnspsc190501.50561587': __('identifierSchemeCodeUnspsc190501.50561587'),
	'identifierSchemeCodeUnspsc190501.50561588': __('identifierSchemeCodeUnspsc190501.50561588'),
	'identifierSchemeCodeUnspsc190501.50561589': __('identifierSchemeCodeUnspsc190501.50561589'),
	'identifierSchemeCodeUnspsc190501.50561590': __('identifierSchemeCodeUnspsc190501.50561590'),
	'identifierSchemeCodeUnspsc190501.50561591': __('identifierSchemeCodeUnspsc190501.50561591'),
	'identifierSchemeCodeUnspsc190501.50561592': __('identifierSchemeCodeUnspsc190501.50561592'),
	'identifierSchemeCodeUnspsc190501.50561593': __('identifierSchemeCodeUnspsc190501.50561593'),
	'identifierSchemeCodeUnspsc190501.50561594': __('identifierSchemeCodeUnspsc190501.50561594'),
	'identifierSchemeCodeUnspsc190501.50561595': __('identifierSchemeCodeUnspsc190501.50561595'),
	'identifierSchemeCodeUnspsc190501.50561596': __('identifierSchemeCodeUnspsc190501.50561596'),
	'identifierSchemeCodeUnspsc190501.50561600': __('identifierSchemeCodeUnspsc190501.50561600'),
	'identifierSchemeCodeUnspsc190501.50561601': __('identifierSchemeCodeUnspsc190501.50561601'),
	'identifierSchemeCodeUnspsc190501.50561602': __('identifierSchemeCodeUnspsc190501.50561602'),
	'identifierSchemeCodeUnspsc190501.50561603': __('identifierSchemeCodeUnspsc190501.50561603'),
	'identifierSchemeCodeUnspsc190501.50561604': __('identifierSchemeCodeUnspsc190501.50561604'),
	'identifierSchemeCodeUnspsc190501.50561605': __('identifierSchemeCodeUnspsc190501.50561605'),
	'identifierSchemeCodeUnspsc190501.50561606': __('identifierSchemeCodeUnspsc190501.50561606'),
	'identifierSchemeCodeUnspsc190501.50561607': __('identifierSchemeCodeUnspsc190501.50561607'),
	'identifierSchemeCodeUnspsc190501.50561608': __('identifierSchemeCodeUnspsc190501.50561608'),
	'identifierSchemeCodeUnspsc190501.50561609': __('identifierSchemeCodeUnspsc190501.50561609'),
	'identifierSchemeCodeUnspsc190501.50561610': __('identifierSchemeCodeUnspsc190501.50561610'),
	'identifierSchemeCodeUnspsc190501.50561611': __('identifierSchemeCodeUnspsc190501.50561611'),
	'identifierSchemeCodeUnspsc190501.50561612': __('identifierSchemeCodeUnspsc190501.50561612'),
	'identifierSchemeCodeUnspsc190501.50561613': __('identifierSchemeCodeUnspsc190501.50561613'),
	'identifierSchemeCodeUnspsc190501.50561614': __('identifierSchemeCodeUnspsc190501.50561614'),
	'identifierSchemeCodeUnspsc190501.50561615': __('identifierSchemeCodeUnspsc190501.50561615'),
	'identifierSchemeCodeUnspsc190501.50561616': __('identifierSchemeCodeUnspsc190501.50561616'),
	'identifierSchemeCodeUnspsc190501.50561617': __('identifierSchemeCodeUnspsc190501.50561617'),
	'identifierSchemeCodeUnspsc190501.50561618': __('identifierSchemeCodeUnspsc190501.50561618'),
	'identifierSchemeCodeUnspsc190501.50561619': __('identifierSchemeCodeUnspsc190501.50561619'),
	'identifierSchemeCodeUnspsc190501.50561620': __('identifierSchemeCodeUnspsc190501.50561620'),
	'identifierSchemeCodeUnspsc190501.50561621': __('identifierSchemeCodeUnspsc190501.50561621'),
	'identifierSchemeCodeUnspsc190501.50561622': __('identifierSchemeCodeUnspsc190501.50561622'),
	'identifierSchemeCodeUnspsc190501.50561623': __('identifierSchemeCodeUnspsc190501.50561623'),
	'identifierSchemeCodeUnspsc190501.50561624': __('identifierSchemeCodeUnspsc190501.50561624'),
	'identifierSchemeCodeUnspsc190501.50561625': __('identifierSchemeCodeUnspsc190501.50561625'),
	'identifierSchemeCodeUnspsc190501.50561626': __('identifierSchemeCodeUnspsc190501.50561626'),
	'identifierSchemeCodeUnspsc190501.50561627': __('identifierSchemeCodeUnspsc190501.50561627'),
	'identifierSchemeCodeUnspsc190501.50561628': __('identifierSchemeCodeUnspsc190501.50561628'),
	'identifierSchemeCodeUnspsc190501.50561629': __('identifierSchemeCodeUnspsc190501.50561629'),
	'identifierSchemeCodeUnspsc190501.50561630': __('identifierSchemeCodeUnspsc190501.50561630'),
	'identifierSchemeCodeUnspsc190501.50561631': __('identifierSchemeCodeUnspsc190501.50561631'),
	'identifierSchemeCodeUnspsc190501.50561632': __('identifierSchemeCodeUnspsc190501.50561632'),
	'identifierSchemeCodeUnspsc190501.50561633': __('identifierSchemeCodeUnspsc190501.50561633'),
	'identifierSchemeCodeUnspsc190501.50561634': __('identifierSchemeCodeUnspsc190501.50561634'),
	'identifierSchemeCodeUnspsc190501.50561635': __('identifierSchemeCodeUnspsc190501.50561635'),
	'identifierSchemeCodeUnspsc190501.50561636': __('identifierSchemeCodeUnspsc190501.50561636'),
	'identifierSchemeCodeUnspsc190501.50561637': __('identifierSchemeCodeUnspsc190501.50561637'),
	'identifierSchemeCodeUnspsc190501.50561638': __('identifierSchemeCodeUnspsc190501.50561638'),
	'identifierSchemeCodeUnspsc190501.50561639': __('identifierSchemeCodeUnspsc190501.50561639'),
	'identifierSchemeCodeUnspsc190501.50561640': __('identifierSchemeCodeUnspsc190501.50561640'),
	'identifierSchemeCodeUnspsc190501.50561641': __('identifierSchemeCodeUnspsc190501.50561641'),
	'identifierSchemeCodeUnspsc190501.50561642': __('identifierSchemeCodeUnspsc190501.50561642'),
	'identifierSchemeCodeUnspsc190501.50561643': __('identifierSchemeCodeUnspsc190501.50561643'),
	'identifierSchemeCodeUnspsc190501.50561644': __('identifierSchemeCodeUnspsc190501.50561644'),
	'identifierSchemeCodeUnspsc190501.50561645': __('identifierSchemeCodeUnspsc190501.50561645'),
	'identifierSchemeCodeUnspsc190501.50561646': __('identifierSchemeCodeUnspsc190501.50561646'),
	'identifierSchemeCodeUnspsc190501.50561647': __('identifierSchemeCodeUnspsc190501.50561647'),
	'identifierSchemeCodeUnspsc190501.50561648': __('identifierSchemeCodeUnspsc190501.50561648'),
	'identifierSchemeCodeUnspsc190501.50561649': __('identifierSchemeCodeUnspsc190501.50561649'),
	'identifierSchemeCodeUnspsc190501.50561650': __('identifierSchemeCodeUnspsc190501.50561650'),
	'identifierSchemeCodeUnspsc190501.50561651': __('identifierSchemeCodeUnspsc190501.50561651'),
	'identifierSchemeCodeUnspsc190501.50561652': __('identifierSchemeCodeUnspsc190501.50561652'),
	'identifierSchemeCodeUnspsc190501.50561653': __('identifierSchemeCodeUnspsc190501.50561653'),
	'identifierSchemeCodeUnspsc190501.50561654': __('identifierSchemeCodeUnspsc190501.50561654'),
	'identifierSchemeCodeUnspsc190501.50561655': __('identifierSchemeCodeUnspsc190501.50561655'),
	'identifierSchemeCodeUnspsc190501.50561700': __('identifierSchemeCodeUnspsc190501.50561700'),
	'identifierSchemeCodeUnspsc190501.50561701': __('identifierSchemeCodeUnspsc190501.50561701'),
	'identifierSchemeCodeUnspsc190501.50561702': __('identifierSchemeCodeUnspsc190501.50561702'),
	'identifierSchemeCodeUnspsc190501.50561703': __('identifierSchemeCodeUnspsc190501.50561703'),
	'identifierSchemeCodeUnspsc190501.50561704': __('identifierSchemeCodeUnspsc190501.50561704'),
	'identifierSchemeCodeUnspsc190501.50561705': __('identifierSchemeCodeUnspsc190501.50561705'),
	'identifierSchemeCodeUnspsc190501.50561706': __('identifierSchemeCodeUnspsc190501.50561706'),
	'identifierSchemeCodeUnspsc190501.50561707': __('identifierSchemeCodeUnspsc190501.50561707'),
	'identifierSchemeCodeUnspsc190501.50561708': __('identifierSchemeCodeUnspsc190501.50561708'),
	'identifierSchemeCodeUnspsc190501.50561709': __('identifierSchemeCodeUnspsc190501.50561709'),
	'identifierSchemeCodeUnspsc190501.50561710': __('identifierSchemeCodeUnspsc190501.50561710'),
	'identifierSchemeCodeUnspsc190501.50561711': __('identifierSchemeCodeUnspsc190501.50561711'),
	'identifierSchemeCodeUnspsc190501.50561712': __('identifierSchemeCodeUnspsc190501.50561712'),
	'identifierSchemeCodeUnspsc190501.50561713': __('identifierSchemeCodeUnspsc190501.50561713'),
	'identifierSchemeCodeUnspsc190501.50561714': __('identifierSchemeCodeUnspsc190501.50561714'),
	'identifierSchemeCodeUnspsc190501.50561715': __('identifierSchemeCodeUnspsc190501.50561715'),
	'identifierSchemeCodeUnspsc190501.50561800': __('identifierSchemeCodeUnspsc190501.50561800'),
	'identifierSchemeCodeUnspsc190501.50561801': __('identifierSchemeCodeUnspsc190501.50561801'),
	'identifierSchemeCodeUnspsc190501.50561802': __('identifierSchemeCodeUnspsc190501.50561802'),
	'identifierSchemeCodeUnspsc190501.50561803': __('identifierSchemeCodeUnspsc190501.50561803'),
	'identifierSchemeCodeUnspsc190501.50561804': __('identifierSchemeCodeUnspsc190501.50561804'),
	'identifierSchemeCodeUnspsc190501.50561805': __('identifierSchemeCodeUnspsc190501.50561805'),
	'identifierSchemeCodeUnspsc190501.50561806': __('identifierSchemeCodeUnspsc190501.50561806'),
	'identifierSchemeCodeUnspsc190501.50561807': __('identifierSchemeCodeUnspsc190501.50561807'),
	'identifierSchemeCodeUnspsc190501.50561808': __('identifierSchemeCodeUnspsc190501.50561808'),
	'identifierSchemeCodeUnspsc190501.50561809': __('identifierSchemeCodeUnspsc190501.50561809'),
	'identifierSchemeCodeUnspsc190501.50561810': __('identifierSchemeCodeUnspsc190501.50561810'),
	'identifierSchemeCodeUnspsc190501.50561811': __('identifierSchemeCodeUnspsc190501.50561811'),
	'identifierSchemeCodeUnspsc190501.50561812': __('identifierSchemeCodeUnspsc190501.50561812'),
	'identifierSchemeCodeUnspsc190501.50561813': __('identifierSchemeCodeUnspsc190501.50561813'),
	'identifierSchemeCodeUnspsc190501.50561814': __('identifierSchemeCodeUnspsc190501.50561814'),
	'identifierSchemeCodeUnspsc190501.50561900': __('identifierSchemeCodeUnspsc190501.50561900'),
	'identifierSchemeCodeUnspsc190501.50561901': __('identifierSchemeCodeUnspsc190501.50561901'),
	'identifierSchemeCodeUnspsc190501.50561902': __('identifierSchemeCodeUnspsc190501.50561902'),
	'identifierSchemeCodeUnspsc190501.50561903': __('identifierSchemeCodeUnspsc190501.50561903'),
	'identifierSchemeCodeUnspsc190501.50561904': __('identifierSchemeCodeUnspsc190501.50561904'),
	'identifierSchemeCodeUnspsc190501.50561905': __('identifierSchemeCodeUnspsc190501.50561905'),
	'identifierSchemeCodeUnspsc190501.50561906': __('identifierSchemeCodeUnspsc190501.50561906'),
	'identifierSchemeCodeUnspsc190501.50561907': __('identifierSchemeCodeUnspsc190501.50561907'),
	'identifierSchemeCodeUnspsc190501.50561908': __('identifierSchemeCodeUnspsc190501.50561908'),
	'identifierSchemeCodeUnspsc190501.50561909': __('identifierSchemeCodeUnspsc190501.50561909'),
	'identifierSchemeCodeUnspsc190501.50561910': __('identifierSchemeCodeUnspsc190501.50561910'),
	'identifierSchemeCodeUnspsc190501.50561911': __('identifierSchemeCodeUnspsc190501.50561911'),
	'identifierSchemeCodeUnspsc190501.50561912': __('identifierSchemeCodeUnspsc190501.50561912'),
	'identifierSchemeCodeUnspsc190501.50561913': __('identifierSchemeCodeUnspsc190501.50561913'),
	'identifierSchemeCodeUnspsc190501.50561914': __('identifierSchemeCodeUnspsc190501.50561914'),
	'identifierSchemeCodeUnspsc190501.50561915': __('identifierSchemeCodeUnspsc190501.50561915'),
	'identifierSchemeCodeUnspsc190501.50562000': __('identifierSchemeCodeUnspsc190501.50562000'),
	'identifierSchemeCodeUnspsc190501.50562001': __('identifierSchemeCodeUnspsc190501.50562001'),
	'identifierSchemeCodeUnspsc190501.50562002': __('identifierSchemeCodeUnspsc190501.50562002'),
	'identifierSchemeCodeUnspsc190501.50562003': __('identifierSchemeCodeUnspsc190501.50562003'),
	'identifierSchemeCodeUnspsc190501.50562004': __('identifierSchemeCodeUnspsc190501.50562004'),
	'identifierSchemeCodeUnspsc190501.50562100': __('identifierSchemeCodeUnspsc190501.50562100'),
	'identifierSchemeCodeUnspsc190501.50562101': __('identifierSchemeCodeUnspsc190501.50562101'),
	'identifierSchemeCodeUnspsc190501.50562102': __('identifierSchemeCodeUnspsc190501.50562102'),
	'identifierSchemeCodeUnspsc190501.50562103': __('identifierSchemeCodeUnspsc190501.50562103'),
	'identifierSchemeCodeUnspsc190501.50562104': __('identifierSchemeCodeUnspsc190501.50562104'),
	'identifierSchemeCodeUnspsc190501.50562105': __('identifierSchemeCodeUnspsc190501.50562105'),
	'identifierSchemeCodeUnspsc190501.50562106': __('identifierSchemeCodeUnspsc190501.50562106'),
	'identifierSchemeCodeUnspsc190501.50562107': __('identifierSchemeCodeUnspsc190501.50562107'),
	'identifierSchemeCodeUnspsc190501.50562108': __('identifierSchemeCodeUnspsc190501.50562108'),
	'identifierSchemeCodeUnspsc190501.50562109': __('identifierSchemeCodeUnspsc190501.50562109'),
	'identifierSchemeCodeUnspsc190501.50562110': __('identifierSchemeCodeUnspsc190501.50562110'),
	'identifierSchemeCodeUnspsc190501.50562111': __('identifierSchemeCodeUnspsc190501.50562111'),
	'identifierSchemeCodeUnspsc190501.50562112': __('identifierSchemeCodeUnspsc190501.50562112'),
	'identifierSchemeCodeUnspsc190501.50562113': __('identifierSchemeCodeUnspsc190501.50562113'),
	'identifierSchemeCodeUnspsc190501.50562114': __('identifierSchemeCodeUnspsc190501.50562114'),
	'identifierSchemeCodeUnspsc190501.50562115': __('identifierSchemeCodeUnspsc190501.50562115'),
	'identifierSchemeCodeUnspsc190501.50562200': __('identifierSchemeCodeUnspsc190501.50562200'),
	'identifierSchemeCodeUnspsc190501.50562201': __('identifierSchemeCodeUnspsc190501.50562201'),
	'identifierSchemeCodeUnspsc190501.50562202': __('identifierSchemeCodeUnspsc190501.50562202'),
	'identifierSchemeCodeUnspsc190501.50562203': __('identifierSchemeCodeUnspsc190501.50562203'),
	'identifierSchemeCodeUnspsc190501.50562204': __('identifierSchemeCodeUnspsc190501.50562204'),
	'identifierSchemeCodeUnspsc190501.50562300': __('identifierSchemeCodeUnspsc190501.50562300'),
	'identifierSchemeCodeUnspsc190501.50562301': __('identifierSchemeCodeUnspsc190501.50562301'),
	'identifierSchemeCodeUnspsc190501.50562302': __('identifierSchemeCodeUnspsc190501.50562302'),
	'identifierSchemeCodeUnspsc190501.50562303': __('identifierSchemeCodeUnspsc190501.50562303'),
	'identifierSchemeCodeUnspsc190501.50562304': __('identifierSchemeCodeUnspsc190501.50562304'),
	'identifierSchemeCodeUnspsc190501.50562305': __('identifierSchemeCodeUnspsc190501.50562305'),
	'identifierSchemeCodeUnspsc190501.50562306': __('identifierSchemeCodeUnspsc190501.50562306'),
	'identifierSchemeCodeUnspsc190501.50562307': __('identifierSchemeCodeUnspsc190501.50562307'),
	'identifierSchemeCodeUnspsc190501.50562308': __('identifierSchemeCodeUnspsc190501.50562308'),
	'identifierSchemeCodeUnspsc190501.50562309': __('identifierSchemeCodeUnspsc190501.50562309'),
	'identifierSchemeCodeUnspsc190501.50562400': __('identifierSchemeCodeUnspsc190501.50562400'),
	'identifierSchemeCodeUnspsc190501.50562401': __('identifierSchemeCodeUnspsc190501.50562401'),
	'identifierSchemeCodeUnspsc190501.50562402': __('identifierSchemeCodeUnspsc190501.50562402'),
	'identifierSchemeCodeUnspsc190501.50562403': __('identifierSchemeCodeUnspsc190501.50562403'),
	'identifierSchemeCodeUnspsc190501.50562404': __('identifierSchemeCodeUnspsc190501.50562404'),
	'identifierSchemeCodeUnspsc190501.50562405': __('identifierSchemeCodeUnspsc190501.50562405'),
	'identifierSchemeCodeUnspsc190501.50562406': __('identifierSchemeCodeUnspsc190501.50562406'),
	'identifierSchemeCodeUnspsc190501.50562407': __('identifierSchemeCodeUnspsc190501.50562407'),
	'identifierSchemeCodeUnspsc190501.50562408': __('identifierSchemeCodeUnspsc190501.50562408'),
	'identifierSchemeCodeUnspsc190501.50562409': __('identifierSchemeCodeUnspsc190501.50562409'),
	'identifierSchemeCodeUnspsc190501.50562410': __('identifierSchemeCodeUnspsc190501.50562410'),
	'identifierSchemeCodeUnspsc190501.50562411': __('identifierSchemeCodeUnspsc190501.50562411'),
	'identifierSchemeCodeUnspsc190501.50562412': __('identifierSchemeCodeUnspsc190501.50562412'),
	'identifierSchemeCodeUnspsc190501.50562413': __('identifierSchemeCodeUnspsc190501.50562413'),
	'identifierSchemeCodeUnspsc190501.50562414': __('identifierSchemeCodeUnspsc190501.50562414'),
	'identifierSchemeCodeUnspsc190501.50562415': __('identifierSchemeCodeUnspsc190501.50562415'),
	'identifierSchemeCodeUnspsc190501.50562416': __('identifierSchemeCodeUnspsc190501.50562416'),
	'identifierSchemeCodeUnspsc190501.50562417': __('identifierSchemeCodeUnspsc190501.50562417'),
	'identifierSchemeCodeUnspsc190501.50562418': __('identifierSchemeCodeUnspsc190501.50562418'),
	'identifierSchemeCodeUnspsc190501.50562419': __('identifierSchemeCodeUnspsc190501.50562419'),
	'identifierSchemeCodeUnspsc190501.50562420': __('identifierSchemeCodeUnspsc190501.50562420'),
	'identifierSchemeCodeUnspsc190501.50562421': __('identifierSchemeCodeUnspsc190501.50562421'),
	'identifierSchemeCodeUnspsc190501.50562422': __('identifierSchemeCodeUnspsc190501.50562422'),
	'identifierSchemeCodeUnspsc190501.50562423': __('identifierSchemeCodeUnspsc190501.50562423'),
	'identifierSchemeCodeUnspsc190501.50562424': __('identifierSchemeCodeUnspsc190501.50562424'),
	'identifierSchemeCodeUnspsc190501.50562425': __('identifierSchemeCodeUnspsc190501.50562425'),
	'identifierSchemeCodeUnspsc190501.50562426': __('identifierSchemeCodeUnspsc190501.50562426'),
	'identifierSchemeCodeUnspsc190501.50562427': __('identifierSchemeCodeUnspsc190501.50562427'),
	'identifierSchemeCodeUnspsc190501.50562428': __('identifierSchemeCodeUnspsc190501.50562428'),
	'identifierSchemeCodeUnspsc190501.50562429': __('identifierSchemeCodeUnspsc190501.50562429'),
	'identifierSchemeCodeUnspsc190501.50562430': __('identifierSchemeCodeUnspsc190501.50562430'),
	'identifierSchemeCodeUnspsc190501.50562431': __('identifierSchemeCodeUnspsc190501.50562431'),
	'identifierSchemeCodeUnspsc190501.50562500': __('identifierSchemeCodeUnspsc190501.50562500'),
	'identifierSchemeCodeUnspsc190501.50562501': __('identifierSchemeCodeUnspsc190501.50562501'),
	'identifierSchemeCodeUnspsc190501.50562502': __('identifierSchemeCodeUnspsc190501.50562502'),
	'identifierSchemeCodeUnspsc190501.50562503': __('identifierSchemeCodeUnspsc190501.50562503'),
	'identifierSchemeCodeUnspsc190501.50562504': __('identifierSchemeCodeUnspsc190501.50562504'),
	'identifierSchemeCodeUnspsc190501.50562505': __('identifierSchemeCodeUnspsc190501.50562505'),
	'identifierSchemeCodeUnspsc190501.50562506': __('identifierSchemeCodeUnspsc190501.50562506'),
	'identifierSchemeCodeUnspsc190501.50562507': __('identifierSchemeCodeUnspsc190501.50562507'),
	'identifierSchemeCodeUnspsc190501.50562600': __('identifierSchemeCodeUnspsc190501.50562600'),
	'identifierSchemeCodeUnspsc190501.50562601': __('identifierSchemeCodeUnspsc190501.50562601'),
	'identifierSchemeCodeUnspsc190501.50562602': __('identifierSchemeCodeUnspsc190501.50562602'),
	'identifierSchemeCodeUnspsc190501.50562603': __('identifierSchemeCodeUnspsc190501.50562603'),
	'identifierSchemeCodeUnspsc190501.50562604': __('identifierSchemeCodeUnspsc190501.50562604'),
	'identifierSchemeCodeUnspsc190501.50562605': __('identifierSchemeCodeUnspsc190501.50562605'),
	'identifierSchemeCodeUnspsc190501.50562606': __('identifierSchemeCodeUnspsc190501.50562606'),
	'identifierSchemeCodeUnspsc190501.50562607': __('identifierSchemeCodeUnspsc190501.50562607'),
	'identifierSchemeCodeUnspsc190501.50562608': __('identifierSchemeCodeUnspsc190501.50562608'),
	'identifierSchemeCodeUnspsc190501.50562609': __('identifierSchemeCodeUnspsc190501.50562609'),
	'identifierSchemeCodeUnspsc190501.50562610': __('identifierSchemeCodeUnspsc190501.50562610'),
	'identifierSchemeCodeUnspsc190501.50562700': __('identifierSchemeCodeUnspsc190501.50562700'),
	'identifierSchemeCodeUnspsc190501.50562701': __('identifierSchemeCodeUnspsc190501.50562701'),
	'identifierSchemeCodeUnspsc190501.50562702': __('identifierSchemeCodeUnspsc190501.50562702'),
	'identifierSchemeCodeUnspsc190501.50562703': __('identifierSchemeCodeUnspsc190501.50562703'),
	'identifierSchemeCodeUnspsc190501.50562704': __('identifierSchemeCodeUnspsc190501.50562704'),
	'identifierSchemeCodeUnspsc190501.50562705': __('identifierSchemeCodeUnspsc190501.50562705'),
	'identifierSchemeCodeUnspsc190501.50562706': __('identifierSchemeCodeUnspsc190501.50562706'),
	'identifierSchemeCodeUnspsc190501.50562800': __('identifierSchemeCodeUnspsc190501.50562800'),
	'identifierSchemeCodeUnspsc190501.50562801': __('identifierSchemeCodeUnspsc190501.50562801'),
	'identifierSchemeCodeUnspsc190501.50562802': __('identifierSchemeCodeUnspsc190501.50562802'),
	'identifierSchemeCodeUnspsc190501.50562803': __('identifierSchemeCodeUnspsc190501.50562803'),
	'identifierSchemeCodeUnspsc190501.50562804': __('identifierSchemeCodeUnspsc190501.50562804'),
	'identifierSchemeCodeUnspsc190501.50562805': __('identifierSchemeCodeUnspsc190501.50562805'),
	'identifierSchemeCodeUnspsc190501.50562806': __('identifierSchemeCodeUnspsc190501.50562806'),
	'identifierSchemeCodeUnspsc190501.50562807': __('identifierSchemeCodeUnspsc190501.50562807'),
	'identifierSchemeCodeUnspsc190501.50562808': __('identifierSchemeCodeUnspsc190501.50562808'),
	'identifierSchemeCodeUnspsc190501.50562809': __('identifierSchemeCodeUnspsc190501.50562809'),
	'identifierSchemeCodeUnspsc190501.50562810': __('identifierSchemeCodeUnspsc190501.50562810'),
	'identifierSchemeCodeUnspsc190501.50562811': __('identifierSchemeCodeUnspsc190501.50562811'),
	'identifierSchemeCodeUnspsc190501.50562812': __('identifierSchemeCodeUnspsc190501.50562812'),
	'identifierSchemeCodeUnspsc190501.50562813': __('identifierSchemeCodeUnspsc190501.50562813'),
	'identifierSchemeCodeUnspsc190501.50562814': __('identifierSchemeCodeUnspsc190501.50562814'),
	'identifierSchemeCodeUnspsc190501.50562815': __('identifierSchemeCodeUnspsc190501.50562815'),
	'identifierSchemeCodeUnspsc190501.50562816': __('identifierSchemeCodeUnspsc190501.50562816'),
	'identifierSchemeCodeUnspsc190501.50562900': __('identifierSchemeCodeUnspsc190501.50562900'),
	'identifierSchemeCodeUnspsc190501.50562901': __('identifierSchemeCodeUnspsc190501.50562901'),
	'identifierSchemeCodeUnspsc190501.50562902': __('identifierSchemeCodeUnspsc190501.50562902'),
	'identifierSchemeCodeUnspsc190501.50562903': __('identifierSchemeCodeUnspsc190501.50562903'),
	'identifierSchemeCodeUnspsc190501.50562904': __('identifierSchemeCodeUnspsc190501.50562904'),
	'identifierSchemeCodeUnspsc190501.50562905': __('identifierSchemeCodeUnspsc190501.50562905'),
	'identifierSchemeCodeUnspsc190501.50562906': __('identifierSchemeCodeUnspsc190501.50562906'),
	'identifierSchemeCodeUnspsc190501.50562907': __('identifierSchemeCodeUnspsc190501.50562907'),
	'identifierSchemeCodeUnspsc190501.50562908': __('identifierSchemeCodeUnspsc190501.50562908'),
	'identifierSchemeCodeUnspsc190501.50562909': __('identifierSchemeCodeUnspsc190501.50562909'),
	'identifierSchemeCodeUnspsc190501.50562910': __('identifierSchemeCodeUnspsc190501.50562910'),
	'identifierSchemeCodeUnspsc190501.50562911': __('identifierSchemeCodeUnspsc190501.50562911'),
	'identifierSchemeCodeUnspsc190501.50563000': __('identifierSchemeCodeUnspsc190501.50563000'),
	'identifierSchemeCodeUnspsc190501.50563001': __('identifierSchemeCodeUnspsc190501.50563001'),
	'identifierSchemeCodeUnspsc190501.50563002': __('identifierSchemeCodeUnspsc190501.50563002'),
	'identifierSchemeCodeUnspsc190501.50563003': __('identifierSchemeCodeUnspsc190501.50563003'),
	'identifierSchemeCodeUnspsc190501.50563004': __('identifierSchemeCodeUnspsc190501.50563004'),
	'identifierSchemeCodeUnspsc190501.50563005': __('identifierSchemeCodeUnspsc190501.50563005'),
	'identifierSchemeCodeUnspsc190501.50563006': __('identifierSchemeCodeUnspsc190501.50563006'),
	'identifierSchemeCodeUnspsc190501.50563007': __('identifierSchemeCodeUnspsc190501.50563007'),
	'identifierSchemeCodeUnspsc190501.50563100': __('identifierSchemeCodeUnspsc190501.50563100'),
	'identifierSchemeCodeUnspsc190501.50563101': __('identifierSchemeCodeUnspsc190501.50563101'),
	'identifierSchemeCodeUnspsc190501.50563102': __('identifierSchemeCodeUnspsc190501.50563102'),
	'identifierSchemeCodeUnspsc190501.50563103': __('identifierSchemeCodeUnspsc190501.50563103'),
	'identifierSchemeCodeUnspsc190501.50563104': __('identifierSchemeCodeUnspsc190501.50563104'),
	'identifierSchemeCodeUnspsc190501.50563105': __('identifierSchemeCodeUnspsc190501.50563105'),
	'identifierSchemeCodeUnspsc190501.50563106': __('identifierSchemeCodeUnspsc190501.50563106'),
	'identifierSchemeCodeUnspsc190501.50563107': __('identifierSchemeCodeUnspsc190501.50563107'),
	'identifierSchemeCodeUnspsc190501.50563108': __('identifierSchemeCodeUnspsc190501.50563108'),
	'identifierSchemeCodeUnspsc190501.50563109': __('identifierSchemeCodeUnspsc190501.50563109'),
	'identifierSchemeCodeUnspsc190501.50563200': __('identifierSchemeCodeUnspsc190501.50563200'),
	'identifierSchemeCodeUnspsc190501.50563201': __('identifierSchemeCodeUnspsc190501.50563201'),
	'identifierSchemeCodeUnspsc190501.50563202': __('identifierSchemeCodeUnspsc190501.50563202'),
	'identifierSchemeCodeUnspsc190501.50563203': __('identifierSchemeCodeUnspsc190501.50563203'),
	'identifierSchemeCodeUnspsc190501.50563204': __('identifierSchemeCodeUnspsc190501.50563204'),
	'identifierSchemeCodeUnspsc190501.50563205': __('identifierSchemeCodeUnspsc190501.50563205'),
	'identifierSchemeCodeUnspsc190501.50563206': __('identifierSchemeCodeUnspsc190501.50563206'),
	'identifierSchemeCodeUnspsc190501.50563207': __('identifierSchemeCodeUnspsc190501.50563207'),
	'identifierSchemeCodeUnspsc190501.50563208': __('identifierSchemeCodeUnspsc190501.50563208'),
	'identifierSchemeCodeUnspsc190501.50563209': __('identifierSchemeCodeUnspsc190501.50563209'),
	'identifierSchemeCodeUnspsc190501.50563210': __('identifierSchemeCodeUnspsc190501.50563210'),
	'identifierSchemeCodeUnspsc190501.50563211': __('identifierSchemeCodeUnspsc190501.50563211'),
	'identifierSchemeCodeUnspsc190501.50563212': __('identifierSchemeCodeUnspsc190501.50563212'),
	'identifierSchemeCodeUnspsc190501.50563213': __('identifierSchemeCodeUnspsc190501.50563213'),
	'identifierSchemeCodeUnspsc190501.50563214': __('identifierSchemeCodeUnspsc190501.50563214'),
	'identifierSchemeCodeUnspsc190501.50563215': __('identifierSchemeCodeUnspsc190501.50563215'),
	'identifierSchemeCodeUnspsc190501.50563216': __('identifierSchemeCodeUnspsc190501.50563216'),
	'identifierSchemeCodeUnspsc190501.50563217': __('identifierSchemeCodeUnspsc190501.50563217'),
	'identifierSchemeCodeUnspsc190501.50563218': __('identifierSchemeCodeUnspsc190501.50563218'),
	'identifierSchemeCodeUnspsc190501.50563219': __('identifierSchemeCodeUnspsc190501.50563219'),
	'identifierSchemeCodeUnspsc190501.50563220': __('identifierSchemeCodeUnspsc190501.50563220'),
	'identifierSchemeCodeUnspsc190501.50563221': __('identifierSchemeCodeUnspsc190501.50563221'),
	'identifierSchemeCodeUnspsc190501.50563222': __('identifierSchemeCodeUnspsc190501.50563222'),
	'identifierSchemeCodeUnspsc190501.50563223': __('identifierSchemeCodeUnspsc190501.50563223'),
	'identifierSchemeCodeUnspsc190501.50563224': __('identifierSchemeCodeUnspsc190501.50563224'),
	'identifierSchemeCodeUnspsc190501.50563225': __('identifierSchemeCodeUnspsc190501.50563225'),
	'identifierSchemeCodeUnspsc190501.50563226': __('identifierSchemeCodeUnspsc190501.50563226'),
	'identifierSchemeCodeUnspsc190501.50563227': __('identifierSchemeCodeUnspsc190501.50563227'),
	'identifierSchemeCodeUnspsc190501.50563228': __('identifierSchemeCodeUnspsc190501.50563228'),
	'identifierSchemeCodeUnspsc190501.50563229': __('identifierSchemeCodeUnspsc190501.50563229'),
	'identifierSchemeCodeUnspsc190501.50563230': __('identifierSchemeCodeUnspsc190501.50563230'),
	'identifierSchemeCodeUnspsc190501.50563231': __('identifierSchemeCodeUnspsc190501.50563231'),
	'identifierSchemeCodeUnspsc190501.50563232': __('identifierSchemeCodeUnspsc190501.50563232'),
	'identifierSchemeCodeUnspsc190501.50563233': __('identifierSchemeCodeUnspsc190501.50563233'),
	'identifierSchemeCodeUnspsc190501.50563234': __('identifierSchemeCodeUnspsc190501.50563234'),
	'identifierSchemeCodeUnspsc190501.50563235': __('identifierSchemeCodeUnspsc190501.50563235'),
	'identifierSchemeCodeUnspsc190501.50563236': __('identifierSchemeCodeUnspsc190501.50563236'),
	'identifierSchemeCodeUnspsc190501.50563237': __('identifierSchemeCodeUnspsc190501.50563237'),
	'identifierSchemeCodeUnspsc190501.50563238': __('identifierSchemeCodeUnspsc190501.50563238'),
	'identifierSchemeCodeUnspsc190501.50563239': __('identifierSchemeCodeUnspsc190501.50563239'),
	'identifierSchemeCodeUnspsc190501.50563240': __('identifierSchemeCodeUnspsc190501.50563240'),
	'identifierSchemeCodeUnspsc190501.50563241': __('identifierSchemeCodeUnspsc190501.50563241'),
	'identifierSchemeCodeUnspsc190501.50563242': __('identifierSchemeCodeUnspsc190501.50563242'),
	'identifierSchemeCodeUnspsc190501.50563243': __('identifierSchemeCodeUnspsc190501.50563243'),
	'identifierSchemeCodeUnspsc190501.50563244': __('identifierSchemeCodeUnspsc190501.50563244'),
	'identifierSchemeCodeUnspsc190501.50563245': __('identifierSchemeCodeUnspsc190501.50563245'),
	'identifierSchemeCodeUnspsc190501.50563246': __('identifierSchemeCodeUnspsc190501.50563246'),
	'identifierSchemeCodeUnspsc190501.50563247': __('identifierSchemeCodeUnspsc190501.50563247'),
	'identifierSchemeCodeUnspsc190501.50563248': __('identifierSchemeCodeUnspsc190501.50563248'),
	'identifierSchemeCodeUnspsc190501.50563249': __('identifierSchemeCodeUnspsc190501.50563249'),
	'identifierSchemeCodeUnspsc190501.50563250': __('identifierSchemeCodeUnspsc190501.50563250'),
	'identifierSchemeCodeUnspsc190501.50563251': __('identifierSchemeCodeUnspsc190501.50563251'),
	'identifierSchemeCodeUnspsc190501.50563252': __('identifierSchemeCodeUnspsc190501.50563252'),
	'identifierSchemeCodeUnspsc190501.50563253': __('identifierSchemeCodeUnspsc190501.50563253'),
	'identifierSchemeCodeUnspsc190501.50563254': __('identifierSchemeCodeUnspsc190501.50563254'),
	'identifierSchemeCodeUnspsc190501.50563255': __('identifierSchemeCodeUnspsc190501.50563255'),
	'identifierSchemeCodeUnspsc190501.50563256': __('identifierSchemeCodeUnspsc190501.50563256'),
	'identifierSchemeCodeUnspsc190501.50563257': __('identifierSchemeCodeUnspsc190501.50563257'),
	'identifierSchemeCodeUnspsc190501.50563258': __('identifierSchemeCodeUnspsc190501.50563258'),
	'identifierSchemeCodeUnspsc190501.50563259': __('identifierSchemeCodeUnspsc190501.50563259'),
	'identifierSchemeCodeUnspsc190501.50563260': __('identifierSchemeCodeUnspsc190501.50563260'),
	'identifierSchemeCodeUnspsc190501.50563261': __('identifierSchemeCodeUnspsc190501.50563261'),
	'identifierSchemeCodeUnspsc190501.50563262': __('identifierSchemeCodeUnspsc190501.50563262'),
	'identifierSchemeCodeUnspsc190501.50563263': __('identifierSchemeCodeUnspsc190501.50563263'),
	'identifierSchemeCodeUnspsc190501.50563264': __('identifierSchemeCodeUnspsc190501.50563264'),
	'identifierSchemeCodeUnspsc190501.50563265': __('identifierSchemeCodeUnspsc190501.50563265'),
	'identifierSchemeCodeUnspsc190501.50563266': __('identifierSchemeCodeUnspsc190501.50563266'),
	'identifierSchemeCodeUnspsc190501.50563267': __('identifierSchemeCodeUnspsc190501.50563267'),
	'identifierSchemeCodeUnspsc190501.50563268': __('identifierSchemeCodeUnspsc190501.50563268'),
	'identifierSchemeCodeUnspsc190501.50563269': __('identifierSchemeCodeUnspsc190501.50563269'),
	'identifierSchemeCodeUnspsc190501.50563270': __('identifierSchemeCodeUnspsc190501.50563270'),
	'identifierSchemeCodeUnspsc190501.50563271': __('identifierSchemeCodeUnspsc190501.50563271'),
	'identifierSchemeCodeUnspsc190501.50563272': __('identifierSchemeCodeUnspsc190501.50563272'),
	'identifierSchemeCodeUnspsc190501.50563273': __('identifierSchemeCodeUnspsc190501.50563273'),
	'identifierSchemeCodeUnspsc190501.50563274': __('identifierSchemeCodeUnspsc190501.50563274'),
	'identifierSchemeCodeUnspsc190501.50563275': __('identifierSchemeCodeUnspsc190501.50563275'),
	'identifierSchemeCodeUnspsc190501.50563276': __('identifierSchemeCodeUnspsc190501.50563276'),
	'identifierSchemeCodeUnspsc190501.50563277': __('identifierSchemeCodeUnspsc190501.50563277'),
	'identifierSchemeCodeUnspsc190501.50563278': __('identifierSchemeCodeUnspsc190501.50563278'),
	'identifierSchemeCodeUnspsc190501.50563279': __('identifierSchemeCodeUnspsc190501.50563279'),
	'identifierSchemeCodeUnspsc190501.50563280': __('identifierSchemeCodeUnspsc190501.50563280'),
	'identifierSchemeCodeUnspsc190501.50563281': __('identifierSchemeCodeUnspsc190501.50563281'),
	'identifierSchemeCodeUnspsc190501.50563282': __('identifierSchemeCodeUnspsc190501.50563282'),
	'identifierSchemeCodeUnspsc190501.50563283': __('identifierSchemeCodeUnspsc190501.50563283'),
	'identifierSchemeCodeUnspsc190501.50563284': __('identifierSchemeCodeUnspsc190501.50563284'),
	'identifierSchemeCodeUnspsc190501.50563285': __('identifierSchemeCodeUnspsc190501.50563285'),
	'identifierSchemeCodeUnspsc190501.50563286': __('identifierSchemeCodeUnspsc190501.50563286'),
	'identifierSchemeCodeUnspsc190501.50563287': __('identifierSchemeCodeUnspsc190501.50563287'),
	'identifierSchemeCodeUnspsc190501.50563288': __('identifierSchemeCodeUnspsc190501.50563288'),
	'identifierSchemeCodeUnspsc190501.50563289': __('identifierSchemeCodeUnspsc190501.50563289'),
	'identifierSchemeCodeUnspsc190501.50563290': __('identifierSchemeCodeUnspsc190501.50563290'),
	'identifierSchemeCodeUnspsc190501.50563291': __('identifierSchemeCodeUnspsc190501.50563291'),
	'identifierSchemeCodeUnspsc190501.50563292': __('identifierSchemeCodeUnspsc190501.50563292'),
	'identifierSchemeCodeUnspsc190501.50563293': __('identifierSchemeCodeUnspsc190501.50563293'),
	'identifierSchemeCodeUnspsc190501.50563294': __('identifierSchemeCodeUnspsc190501.50563294'),
	'identifierSchemeCodeUnspsc190501.50563295': __('identifierSchemeCodeUnspsc190501.50563295'),
	'identifierSchemeCodeUnspsc190501.50563296': __('identifierSchemeCodeUnspsc190501.50563296'),
	'identifierSchemeCodeUnspsc190501.50563297': __('identifierSchemeCodeUnspsc190501.50563297'),
	'identifierSchemeCodeUnspsc190501.50563298': __('identifierSchemeCodeUnspsc190501.50563298'),
	'identifierSchemeCodeUnspsc190501.50563299': __('identifierSchemeCodeUnspsc190501.50563299'),
	'identifierSchemeCodeUnspsc190501.50563300': __('identifierSchemeCodeUnspsc190501.50563300'),
	'identifierSchemeCodeUnspsc190501.50563301': __('identifierSchemeCodeUnspsc190501.50563301'),
	'identifierSchemeCodeUnspsc190501.50563302': __('identifierSchemeCodeUnspsc190501.50563302'),
	'identifierSchemeCodeUnspsc190501.50563303': __('identifierSchemeCodeUnspsc190501.50563303'),
	'identifierSchemeCodeUnspsc190501.50563304': __('identifierSchemeCodeUnspsc190501.50563304'),
	'identifierSchemeCodeUnspsc190501.50563305': __('identifierSchemeCodeUnspsc190501.50563305'),
	'identifierSchemeCodeUnspsc190501.50563306': __('identifierSchemeCodeUnspsc190501.50563306'),
	'identifierSchemeCodeUnspsc190501.50563307': __('identifierSchemeCodeUnspsc190501.50563307'),
	'identifierSchemeCodeUnspsc190501.50563308': __('identifierSchemeCodeUnspsc190501.50563308'),
	'identifierSchemeCodeUnspsc190501.50563309': __('identifierSchemeCodeUnspsc190501.50563309'),
	'identifierSchemeCodeUnspsc190501.50563310': __('identifierSchemeCodeUnspsc190501.50563310'),
	'identifierSchemeCodeUnspsc190501.50563311': __('identifierSchemeCodeUnspsc190501.50563311'),
	'identifierSchemeCodeUnspsc190501.50563312': __('identifierSchemeCodeUnspsc190501.50563312'),
	'identifierSchemeCodeUnspsc190501.50563313': __('identifierSchemeCodeUnspsc190501.50563313'),
	'identifierSchemeCodeUnspsc190501.50563314': __('identifierSchemeCodeUnspsc190501.50563314'),
	'identifierSchemeCodeUnspsc190501.50563315': __('identifierSchemeCodeUnspsc190501.50563315'),
	'identifierSchemeCodeUnspsc190501.50563316': __('identifierSchemeCodeUnspsc190501.50563316'),
	'identifierSchemeCodeUnspsc190501.50563317': __('identifierSchemeCodeUnspsc190501.50563317'),
	'identifierSchemeCodeUnspsc190501.50563318': __('identifierSchemeCodeUnspsc190501.50563318'),
	'identifierSchemeCodeUnspsc190501.50563319': __('identifierSchemeCodeUnspsc190501.50563319'),
	'identifierSchemeCodeUnspsc190501.50563320': __('identifierSchemeCodeUnspsc190501.50563320'),
	'identifierSchemeCodeUnspsc190501.50563321': __('identifierSchemeCodeUnspsc190501.50563321'),
	'identifierSchemeCodeUnspsc190501.50563322': __('identifierSchemeCodeUnspsc190501.50563322'),
	'identifierSchemeCodeUnspsc190501.50563323': __('identifierSchemeCodeUnspsc190501.50563323'),
	'identifierSchemeCodeUnspsc190501.50563324': __('identifierSchemeCodeUnspsc190501.50563324'),
	'identifierSchemeCodeUnspsc190501.50563325': __('identifierSchemeCodeUnspsc190501.50563325'),
	'identifierSchemeCodeUnspsc190501.50563326': __('identifierSchemeCodeUnspsc190501.50563326'),
	'identifierSchemeCodeUnspsc190501.50563327': __('identifierSchemeCodeUnspsc190501.50563327'),
	'identifierSchemeCodeUnspsc190501.50563328': __('identifierSchemeCodeUnspsc190501.50563328'),
	'identifierSchemeCodeUnspsc190501.50563329': __('identifierSchemeCodeUnspsc190501.50563329'),
	'identifierSchemeCodeUnspsc190501.50563330': __('identifierSchemeCodeUnspsc190501.50563330'),
	'identifierSchemeCodeUnspsc190501.50563331': __('identifierSchemeCodeUnspsc190501.50563331'),
	'identifierSchemeCodeUnspsc190501.50563332': __('identifierSchemeCodeUnspsc190501.50563332'),
	'identifierSchemeCodeUnspsc190501.50563333': __('identifierSchemeCodeUnspsc190501.50563333'),
	'identifierSchemeCodeUnspsc190501.50563334': __('identifierSchemeCodeUnspsc190501.50563334'),
	'identifierSchemeCodeUnspsc190501.50563335': __('identifierSchemeCodeUnspsc190501.50563335'),
	'identifierSchemeCodeUnspsc190501.50563336': __('identifierSchemeCodeUnspsc190501.50563336'),
	'identifierSchemeCodeUnspsc190501.50563337': __('identifierSchemeCodeUnspsc190501.50563337'),
	'identifierSchemeCodeUnspsc190501.50563338': __('identifierSchemeCodeUnspsc190501.50563338'),
	'identifierSchemeCodeUnspsc190501.50563339': __('identifierSchemeCodeUnspsc190501.50563339'),
	'identifierSchemeCodeUnspsc190501.50563340': __('identifierSchemeCodeUnspsc190501.50563340'),
	'identifierSchemeCodeUnspsc190501.50563341': __('identifierSchemeCodeUnspsc190501.50563341'),
	'identifierSchemeCodeUnspsc190501.50563342': __('identifierSchemeCodeUnspsc190501.50563342'),
	'identifierSchemeCodeUnspsc190501.50563343': __('identifierSchemeCodeUnspsc190501.50563343'),
	'identifierSchemeCodeUnspsc190501.50563344': __('identifierSchemeCodeUnspsc190501.50563344'),
	'identifierSchemeCodeUnspsc190501.50563345': __('identifierSchemeCodeUnspsc190501.50563345'),
	'identifierSchemeCodeUnspsc190501.50563346': __('identifierSchemeCodeUnspsc190501.50563346'),
	'identifierSchemeCodeUnspsc190501.50563347': __('identifierSchemeCodeUnspsc190501.50563347'),
	'identifierSchemeCodeUnspsc190501.50563348': __('identifierSchemeCodeUnspsc190501.50563348'),
	'identifierSchemeCodeUnspsc190501.50563349': __('identifierSchemeCodeUnspsc190501.50563349'),
	'identifierSchemeCodeUnspsc190501.50563350': __('identifierSchemeCodeUnspsc190501.50563350'),
	'identifierSchemeCodeUnspsc190501.50563351': __('identifierSchemeCodeUnspsc190501.50563351'),
	'identifierSchemeCodeUnspsc190501.50563352': __('identifierSchemeCodeUnspsc190501.50563352'),
	'identifierSchemeCodeUnspsc190501.50563353': __('identifierSchemeCodeUnspsc190501.50563353'),
	'identifierSchemeCodeUnspsc190501.50563354': __('identifierSchemeCodeUnspsc190501.50563354'),
	'identifierSchemeCodeUnspsc190501.50563355': __('identifierSchemeCodeUnspsc190501.50563355'),
	'identifierSchemeCodeUnspsc190501.50563356': __('identifierSchemeCodeUnspsc190501.50563356'),
	'identifierSchemeCodeUnspsc190501.50563357': __('identifierSchemeCodeUnspsc190501.50563357'),
	'identifierSchemeCodeUnspsc190501.50563358': __('identifierSchemeCodeUnspsc190501.50563358'),
	'identifierSchemeCodeUnspsc190501.50563359': __('identifierSchemeCodeUnspsc190501.50563359'),
	'identifierSchemeCodeUnspsc190501.50563360': __('identifierSchemeCodeUnspsc190501.50563360'),
	'identifierSchemeCodeUnspsc190501.50563361': __('identifierSchemeCodeUnspsc190501.50563361'),
	'identifierSchemeCodeUnspsc190501.50563362': __('identifierSchemeCodeUnspsc190501.50563362'),
	'identifierSchemeCodeUnspsc190501.50563363': __('identifierSchemeCodeUnspsc190501.50563363'),
	'identifierSchemeCodeUnspsc190501.50563364': __('identifierSchemeCodeUnspsc190501.50563364'),
	'identifierSchemeCodeUnspsc190501.50563365': __('identifierSchemeCodeUnspsc190501.50563365'),
	'identifierSchemeCodeUnspsc190501.50563500': __('identifierSchemeCodeUnspsc190501.50563500'),
	'identifierSchemeCodeUnspsc190501.50563501': __('identifierSchemeCodeUnspsc190501.50563501'),
	'identifierSchemeCodeUnspsc190501.50563502': __('identifierSchemeCodeUnspsc190501.50563502'),
	'identifierSchemeCodeUnspsc190501.50563503': __('identifierSchemeCodeUnspsc190501.50563503'),
	'identifierSchemeCodeUnspsc190501.50563504': __('identifierSchemeCodeUnspsc190501.50563504'),
	'identifierSchemeCodeUnspsc190501.50563600': __('identifierSchemeCodeUnspsc190501.50563600'),
	'identifierSchemeCodeUnspsc190501.50563601': __('identifierSchemeCodeUnspsc190501.50563601'),
	'identifierSchemeCodeUnspsc190501.50563602': __('identifierSchemeCodeUnspsc190501.50563602'),
	'identifierSchemeCodeUnspsc190501.50563603': __('identifierSchemeCodeUnspsc190501.50563603'),
	'identifierSchemeCodeUnspsc190501.50563604': __('identifierSchemeCodeUnspsc190501.50563604'),
	'identifierSchemeCodeUnspsc190501.50563605': __('identifierSchemeCodeUnspsc190501.50563605'),
	'identifierSchemeCodeUnspsc190501.50563700': __('identifierSchemeCodeUnspsc190501.50563700'),
	'identifierSchemeCodeUnspsc190501.50563701': __('identifierSchemeCodeUnspsc190501.50563701'),
	'identifierSchemeCodeUnspsc190501.50563702': __('identifierSchemeCodeUnspsc190501.50563702'),
	'identifierSchemeCodeUnspsc190501.50563703': __('identifierSchemeCodeUnspsc190501.50563703'),
	'identifierSchemeCodeUnspsc190501.50563704': __('identifierSchemeCodeUnspsc190501.50563704'),
	'identifierSchemeCodeUnspsc190501.50563705': __('identifierSchemeCodeUnspsc190501.50563705'),
	'identifierSchemeCodeUnspsc190501.50563706': __('identifierSchemeCodeUnspsc190501.50563706'),
	'identifierSchemeCodeUnspsc190501.50563800': __('identifierSchemeCodeUnspsc190501.50563800'),
	'identifierSchemeCodeUnspsc190501.50563801': __('identifierSchemeCodeUnspsc190501.50563801'),
	'identifierSchemeCodeUnspsc190501.50563802': __('identifierSchemeCodeUnspsc190501.50563802'),
	'identifierSchemeCodeUnspsc190501.50563803': __('identifierSchemeCodeUnspsc190501.50563803'),
	'identifierSchemeCodeUnspsc190501.50563804': __('identifierSchemeCodeUnspsc190501.50563804'),
	'identifierSchemeCodeUnspsc190501.50563805': __('identifierSchemeCodeUnspsc190501.50563805'),
	'identifierSchemeCodeUnspsc190501.50563806': __('identifierSchemeCodeUnspsc190501.50563806'),
	'identifierSchemeCodeUnspsc190501.50563900': __('identifierSchemeCodeUnspsc190501.50563900'),
	'identifierSchemeCodeUnspsc190501.50563901': __('identifierSchemeCodeUnspsc190501.50563901'),
	'identifierSchemeCodeUnspsc190501.50563902': __('identifierSchemeCodeUnspsc190501.50563902'),
	'identifierSchemeCodeUnspsc190501.50563903': __('identifierSchemeCodeUnspsc190501.50563903'),
	'identifierSchemeCodeUnspsc190501.50563904': __('identifierSchemeCodeUnspsc190501.50563904'),
	'identifierSchemeCodeUnspsc190501.50563905': __('identifierSchemeCodeUnspsc190501.50563905'),
	'identifierSchemeCodeUnspsc190501.50563906': __('identifierSchemeCodeUnspsc190501.50563906'),
	'identifierSchemeCodeUnspsc190501.50563907': __('identifierSchemeCodeUnspsc190501.50563907'),
	'identifierSchemeCodeUnspsc190501.50563908': __('identifierSchemeCodeUnspsc190501.50563908'),
	'identifierSchemeCodeUnspsc190501.50564000': __('identifierSchemeCodeUnspsc190501.50564000'),
	'identifierSchemeCodeUnspsc190501.50564001': __('identifierSchemeCodeUnspsc190501.50564001'),
	'identifierSchemeCodeUnspsc190501.50564002': __('identifierSchemeCodeUnspsc190501.50564002'),
	'identifierSchemeCodeUnspsc190501.50564003': __('identifierSchemeCodeUnspsc190501.50564003'),
	'identifierSchemeCodeUnspsc190501.50564004': __('identifierSchemeCodeUnspsc190501.50564004'),
	'identifierSchemeCodeUnspsc190501.50564005': __('identifierSchemeCodeUnspsc190501.50564005'),
	'identifierSchemeCodeUnspsc190501.50564006': __('identifierSchemeCodeUnspsc190501.50564006'),
	'identifierSchemeCodeUnspsc190501.50564007': __('identifierSchemeCodeUnspsc190501.50564007'),
	'identifierSchemeCodeUnspsc190501.50564008': __('identifierSchemeCodeUnspsc190501.50564008'),
	'identifierSchemeCodeUnspsc190501.50564100': __('identifierSchemeCodeUnspsc190501.50564100'),
	'identifierSchemeCodeUnspsc190501.50564101': __('identifierSchemeCodeUnspsc190501.50564101'),
	'identifierSchemeCodeUnspsc190501.50564102': __('identifierSchemeCodeUnspsc190501.50564102'),
	'identifierSchemeCodeUnspsc190501.50564103': __('identifierSchemeCodeUnspsc190501.50564103'),
	'identifierSchemeCodeUnspsc190501.50564104': __('identifierSchemeCodeUnspsc190501.50564104'),
	'identifierSchemeCodeUnspsc190501.50564105': __('identifierSchemeCodeUnspsc190501.50564105'),
	'identifierSchemeCodeUnspsc190501.50564106': __('identifierSchemeCodeUnspsc190501.50564106'),
	'identifierSchemeCodeUnspsc190501.50564107': __('identifierSchemeCodeUnspsc190501.50564107'),
	'identifierSchemeCodeUnspsc190501.50564108': __('identifierSchemeCodeUnspsc190501.50564108'),
	'identifierSchemeCodeUnspsc190501.50564109': __('identifierSchemeCodeUnspsc190501.50564109'),
	'identifierSchemeCodeUnspsc190501.50564110': __('identifierSchemeCodeUnspsc190501.50564110'),
	'identifierSchemeCodeUnspsc190501.50564111': __('identifierSchemeCodeUnspsc190501.50564111'),
	'identifierSchemeCodeUnspsc190501.50564112': __('identifierSchemeCodeUnspsc190501.50564112'),
	'identifierSchemeCodeUnspsc190501.50564113': __('identifierSchemeCodeUnspsc190501.50564113'),
	'identifierSchemeCodeUnspsc190501.50564200': __('identifierSchemeCodeUnspsc190501.50564200'),
	'identifierSchemeCodeUnspsc190501.50564201': __('identifierSchemeCodeUnspsc190501.50564201'),
	'identifierSchemeCodeUnspsc190501.50564202': __('identifierSchemeCodeUnspsc190501.50564202'),
	'identifierSchemeCodeUnspsc190501.50564203': __('identifierSchemeCodeUnspsc190501.50564203'),
	'identifierSchemeCodeUnspsc190501.50564204': __('identifierSchemeCodeUnspsc190501.50564204'),
	'identifierSchemeCodeUnspsc190501.50564205': __('identifierSchemeCodeUnspsc190501.50564205'),
	'identifierSchemeCodeUnspsc190501.50564206': __('identifierSchemeCodeUnspsc190501.50564206'),
	'identifierSchemeCodeUnspsc190501.50564207': __('identifierSchemeCodeUnspsc190501.50564207'),
	'identifierSchemeCodeUnspsc190501.50564208': __('identifierSchemeCodeUnspsc190501.50564208'),
	'identifierSchemeCodeUnspsc190501.50564209': __('identifierSchemeCodeUnspsc190501.50564209'),
	'identifierSchemeCodeUnspsc190501.50564210': __('identifierSchemeCodeUnspsc190501.50564210'),
	'identifierSchemeCodeUnspsc190501.50564211': __('identifierSchemeCodeUnspsc190501.50564211'),
	'identifierSchemeCodeUnspsc190501.50564212': __('identifierSchemeCodeUnspsc190501.50564212'),
	'identifierSchemeCodeUnspsc190501.50564213': __('identifierSchemeCodeUnspsc190501.50564213'),
	'identifierSchemeCodeUnspsc190501.50564214': __('identifierSchemeCodeUnspsc190501.50564214'),
	'identifierSchemeCodeUnspsc190501.50564215': __('identifierSchemeCodeUnspsc190501.50564215'),
	'identifierSchemeCodeUnspsc190501.50564216': __('identifierSchemeCodeUnspsc190501.50564216'),
	'identifierSchemeCodeUnspsc190501.50564217': __('identifierSchemeCodeUnspsc190501.50564217'),
	'identifierSchemeCodeUnspsc190501.50564218': __('identifierSchemeCodeUnspsc190501.50564218'),
	'identifierSchemeCodeUnspsc190501.50564219': __('identifierSchemeCodeUnspsc190501.50564219'),
	'identifierSchemeCodeUnspsc190501.50564220': __('identifierSchemeCodeUnspsc190501.50564220'),
	'identifierSchemeCodeUnspsc190501.50564221': __('identifierSchemeCodeUnspsc190501.50564221'),
	'identifierSchemeCodeUnspsc190501.50564222': __('identifierSchemeCodeUnspsc190501.50564222'),
	'identifierSchemeCodeUnspsc190501.50564223': __('identifierSchemeCodeUnspsc190501.50564223'),
	'identifierSchemeCodeUnspsc190501.50564224': __('identifierSchemeCodeUnspsc190501.50564224'),
	'identifierSchemeCodeUnspsc190501.50564225': __('identifierSchemeCodeUnspsc190501.50564225'),
	'identifierSchemeCodeUnspsc190501.50564226': __('identifierSchemeCodeUnspsc190501.50564226'),
	'identifierSchemeCodeUnspsc190501.50564227': __('identifierSchemeCodeUnspsc190501.50564227'),
	'identifierSchemeCodeUnspsc190501.50564228': __('identifierSchemeCodeUnspsc190501.50564228'),
	'identifierSchemeCodeUnspsc190501.50564229': __('identifierSchemeCodeUnspsc190501.50564229'),
	'identifierSchemeCodeUnspsc190501.50564230': __('identifierSchemeCodeUnspsc190501.50564230'),
	'identifierSchemeCodeUnspsc190501.50564231': __('identifierSchemeCodeUnspsc190501.50564231'),
	'identifierSchemeCodeUnspsc190501.50564232': __('identifierSchemeCodeUnspsc190501.50564232'),
	'identifierSchemeCodeUnspsc190501.50564233': __('identifierSchemeCodeUnspsc190501.50564233'),
	'identifierSchemeCodeUnspsc190501.50564234': __('identifierSchemeCodeUnspsc190501.50564234'),
	'identifierSchemeCodeUnspsc190501.50564235': __('identifierSchemeCodeUnspsc190501.50564235'),
	'identifierSchemeCodeUnspsc190501.50564236': __('identifierSchemeCodeUnspsc190501.50564236'),
	'identifierSchemeCodeUnspsc190501.50564237': __('identifierSchemeCodeUnspsc190501.50564237'),
	'identifierSchemeCodeUnspsc190501.50564238': __('identifierSchemeCodeUnspsc190501.50564238'),
	'identifierSchemeCodeUnspsc190501.50564239': __('identifierSchemeCodeUnspsc190501.50564239'),
	'identifierSchemeCodeUnspsc190501.50564300': __('identifierSchemeCodeUnspsc190501.50564300'),
	'identifierSchemeCodeUnspsc190501.50564301': __('identifierSchemeCodeUnspsc190501.50564301'),
	'identifierSchemeCodeUnspsc190501.50564302': __('identifierSchemeCodeUnspsc190501.50564302'),
	'identifierSchemeCodeUnspsc190501.50564303': __('identifierSchemeCodeUnspsc190501.50564303'),
	'identifierSchemeCodeUnspsc190501.50564304': __('identifierSchemeCodeUnspsc190501.50564304'),
	'identifierSchemeCodeUnspsc190501.50564305': __('identifierSchemeCodeUnspsc190501.50564305'),
	'identifierSchemeCodeUnspsc190501.50564306': __('identifierSchemeCodeUnspsc190501.50564306'),
	'identifierSchemeCodeUnspsc190501.50564400': __('identifierSchemeCodeUnspsc190501.50564400'),
	'identifierSchemeCodeUnspsc190501.50564401': __('identifierSchemeCodeUnspsc190501.50564401'),
	'identifierSchemeCodeUnspsc190501.50564402': __('identifierSchemeCodeUnspsc190501.50564402'),
	'identifierSchemeCodeUnspsc190501.50564403': __('identifierSchemeCodeUnspsc190501.50564403'),
	'identifierSchemeCodeUnspsc190501.50564404': __('identifierSchemeCodeUnspsc190501.50564404'),
	'identifierSchemeCodeUnspsc190501.50564405': __('identifierSchemeCodeUnspsc190501.50564405'),
	'identifierSchemeCodeUnspsc190501.50564406': __('identifierSchemeCodeUnspsc190501.50564406'),
	'identifierSchemeCodeUnspsc190501.50564407': __('identifierSchemeCodeUnspsc190501.50564407'),
	'identifierSchemeCodeUnspsc190501.50564408': __('identifierSchemeCodeUnspsc190501.50564408'),
	'identifierSchemeCodeUnspsc190501.50564409': __('identifierSchemeCodeUnspsc190501.50564409'),
	'identifierSchemeCodeUnspsc190501.50564410': __('identifierSchemeCodeUnspsc190501.50564410'),
	'identifierSchemeCodeUnspsc190501.50564411': __('identifierSchemeCodeUnspsc190501.50564411'),
	'identifierSchemeCodeUnspsc190501.50564412': __('identifierSchemeCodeUnspsc190501.50564412'),
	'identifierSchemeCodeUnspsc190501.50564413': __('identifierSchemeCodeUnspsc190501.50564413'),
	'identifierSchemeCodeUnspsc190501.50564414': __('identifierSchemeCodeUnspsc190501.50564414'),
	'identifierSchemeCodeUnspsc190501.50564415': __('identifierSchemeCodeUnspsc190501.50564415'),
	'identifierSchemeCodeUnspsc190501.50564416': __('identifierSchemeCodeUnspsc190501.50564416'),
	'identifierSchemeCodeUnspsc190501.50564417': __('identifierSchemeCodeUnspsc190501.50564417'),
	'identifierSchemeCodeUnspsc190501.50564418': __('identifierSchemeCodeUnspsc190501.50564418'),
	'identifierSchemeCodeUnspsc190501.50564419': __('identifierSchemeCodeUnspsc190501.50564419'),
	'identifierSchemeCodeUnspsc190501.50564420': __('identifierSchemeCodeUnspsc190501.50564420'),
	'identifierSchemeCodeUnspsc190501.50564421': __('identifierSchemeCodeUnspsc190501.50564421'),
	'identifierSchemeCodeUnspsc190501.50564422': __('identifierSchemeCodeUnspsc190501.50564422'),
	'identifierSchemeCodeUnspsc190501.50564423': __('identifierSchemeCodeUnspsc190501.50564423'),
	'identifierSchemeCodeUnspsc190501.50564424': __('identifierSchemeCodeUnspsc190501.50564424'),
	'identifierSchemeCodeUnspsc190501.50564425': __('identifierSchemeCodeUnspsc190501.50564425'),
	'identifierSchemeCodeUnspsc190501.50564500': __('identifierSchemeCodeUnspsc190501.50564500'),
	'identifierSchemeCodeUnspsc190501.50564501': __('identifierSchemeCodeUnspsc190501.50564501'),
	'identifierSchemeCodeUnspsc190501.50564502': __('identifierSchemeCodeUnspsc190501.50564502'),
	'identifierSchemeCodeUnspsc190501.50564503': __('identifierSchemeCodeUnspsc190501.50564503'),
	'identifierSchemeCodeUnspsc190501.50564504': __('identifierSchemeCodeUnspsc190501.50564504'),
	'identifierSchemeCodeUnspsc190501.50564505': __('identifierSchemeCodeUnspsc190501.50564505'),
	'identifierSchemeCodeUnspsc190501.50564506': __('identifierSchemeCodeUnspsc190501.50564506'),
	'identifierSchemeCodeUnspsc190501.50564507': __('identifierSchemeCodeUnspsc190501.50564507'),
	'identifierSchemeCodeUnspsc190501.50564508': __('identifierSchemeCodeUnspsc190501.50564508'),
	'identifierSchemeCodeUnspsc190501.50564509': __('identifierSchemeCodeUnspsc190501.50564509'),
	'identifierSchemeCodeUnspsc190501.50564510': __('identifierSchemeCodeUnspsc190501.50564510'),
	'identifierSchemeCodeUnspsc190501.50564511': __('identifierSchemeCodeUnspsc190501.50564511'),
	'identifierSchemeCodeUnspsc190501.50564512': __('identifierSchemeCodeUnspsc190501.50564512'),
	'identifierSchemeCodeUnspsc190501.50564513': __('identifierSchemeCodeUnspsc190501.50564513'),
	'identifierSchemeCodeUnspsc190501.50564514': __('identifierSchemeCodeUnspsc190501.50564514'),
	'identifierSchemeCodeUnspsc190501.50564515': __('identifierSchemeCodeUnspsc190501.50564515'),
	'identifierSchemeCodeUnspsc190501.50564516': __('identifierSchemeCodeUnspsc190501.50564516'),
	'identifierSchemeCodeUnspsc190501.50564517': __('identifierSchemeCodeUnspsc190501.50564517'),
	'identifierSchemeCodeUnspsc190501.50564518': __('identifierSchemeCodeUnspsc190501.50564518'),
	'identifierSchemeCodeUnspsc190501.50564519': __('identifierSchemeCodeUnspsc190501.50564519'),
	'identifierSchemeCodeUnspsc190501.50564520': __('identifierSchemeCodeUnspsc190501.50564520'),
	'identifierSchemeCodeUnspsc190501.50564521': __('identifierSchemeCodeUnspsc190501.50564521'),
	'identifierSchemeCodeUnspsc190501.50564522': __('identifierSchemeCodeUnspsc190501.50564522'),
	'identifierSchemeCodeUnspsc190501.50564523': __('identifierSchemeCodeUnspsc190501.50564523'),
	'identifierSchemeCodeUnspsc190501.50564524': __('identifierSchemeCodeUnspsc190501.50564524'),
	'identifierSchemeCodeUnspsc190501.50564525': __('identifierSchemeCodeUnspsc190501.50564525'),
	'identifierSchemeCodeUnspsc190501.50564526': __('identifierSchemeCodeUnspsc190501.50564526'),
	'identifierSchemeCodeUnspsc190501.50564527': __('identifierSchemeCodeUnspsc190501.50564527'),
	'identifierSchemeCodeUnspsc190501.50564528': __('identifierSchemeCodeUnspsc190501.50564528'),
	'identifierSchemeCodeUnspsc190501.50564529': __('identifierSchemeCodeUnspsc190501.50564529'),
	'identifierSchemeCodeUnspsc190501.50564530': __('identifierSchemeCodeUnspsc190501.50564530'),
	'identifierSchemeCodeUnspsc190501.50564531': __('identifierSchemeCodeUnspsc190501.50564531'),
	'identifierSchemeCodeUnspsc190501.50564532': __('identifierSchemeCodeUnspsc190501.50564532'),
	'identifierSchemeCodeUnspsc190501.50564533': __('identifierSchemeCodeUnspsc190501.50564533'),
	'identifierSchemeCodeUnspsc190501.50564534': __('identifierSchemeCodeUnspsc190501.50564534'),
	'identifierSchemeCodeUnspsc190501.50564535': __('identifierSchemeCodeUnspsc190501.50564535'),
	'identifierSchemeCodeUnspsc190501.50564536': __('identifierSchemeCodeUnspsc190501.50564536'),
	'identifierSchemeCodeUnspsc190501.50564537': __('identifierSchemeCodeUnspsc190501.50564537'),
	'identifierSchemeCodeUnspsc190501.50564538': __('identifierSchemeCodeUnspsc190501.50564538'),
	'identifierSchemeCodeUnspsc190501.50564539': __('identifierSchemeCodeUnspsc190501.50564539'),
	'identifierSchemeCodeUnspsc190501.50564540': __('identifierSchemeCodeUnspsc190501.50564540'),
	'identifierSchemeCodeUnspsc190501.50564541': __('identifierSchemeCodeUnspsc190501.50564541'),
	'identifierSchemeCodeUnspsc190501.50564542': __('identifierSchemeCodeUnspsc190501.50564542'),
	'identifierSchemeCodeUnspsc190501.50564543': __('identifierSchemeCodeUnspsc190501.50564543'),
	'identifierSchemeCodeUnspsc190501.50564544': __('identifierSchemeCodeUnspsc190501.50564544'),
	'identifierSchemeCodeUnspsc190501.50564545': __('identifierSchemeCodeUnspsc190501.50564545'),
	'identifierSchemeCodeUnspsc190501.50564546': __('identifierSchemeCodeUnspsc190501.50564546'),
	'identifierSchemeCodeUnspsc190501.50564547': __('identifierSchemeCodeUnspsc190501.50564547'),
	'identifierSchemeCodeUnspsc190501.50564548': __('identifierSchemeCodeUnspsc190501.50564548'),
	'identifierSchemeCodeUnspsc190501.50564549': __('identifierSchemeCodeUnspsc190501.50564549'),
	'identifierSchemeCodeUnspsc190501.50564550': __('identifierSchemeCodeUnspsc190501.50564550'),
	'identifierSchemeCodeUnspsc190501.50564551': __('identifierSchemeCodeUnspsc190501.50564551'),
	'identifierSchemeCodeUnspsc190501.50564552': __('identifierSchemeCodeUnspsc190501.50564552'),
	'identifierSchemeCodeUnspsc190501.50564553': __('identifierSchemeCodeUnspsc190501.50564553'),
	'identifierSchemeCodeUnspsc190501.50564554': __('identifierSchemeCodeUnspsc190501.50564554'),
	'identifierSchemeCodeUnspsc190501.50564555': __('identifierSchemeCodeUnspsc190501.50564555'),
	'identifierSchemeCodeUnspsc190501.50564556': __('identifierSchemeCodeUnspsc190501.50564556'),
	'identifierSchemeCodeUnspsc190501.50564557': __('identifierSchemeCodeUnspsc190501.50564557'),
	'identifierSchemeCodeUnspsc190501.50564558': __('identifierSchemeCodeUnspsc190501.50564558'),
	'identifierSchemeCodeUnspsc190501.50564559': __('identifierSchemeCodeUnspsc190501.50564559'),
	'identifierSchemeCodeUnspsc190501.50564560': __('identifierSchemeCodeUnspsc190501.50564560'),
	'identifierSchemeCodeUnspsc190501.50564561': __('identifierSchemeCodeUnspsc190501.50564561'),
	'identifierSchemeCodeUnspsc190501.50564600': __('identifierSchemeCodeUnspsc190501.50564600'),
	'identifierSchemeCodeUnspsc190501.50564601': __('identifierSchemeCodeUnspsc190501.50564601'),
	'identifierSchemeCodeUnspsc190501.50564602': __('identifierSchemeCodeUnspsc190501.50564602'),
	'identifierSchemeCodeUnspsc190501.50564700': __('identifierSchemeCodeUnspsc190501.50564700'),
	'identifierSchemeCodeUnspsc190501.50564701': __('identifierSchemeCodeUnspsc190501.50564701'),
	'identifierSchemeCodeUnspsc190501.50564702': __('identifierSchemeCodeUnspsc190501.50564702'),
	'identifierSchemeCodeUnspsc190501.50564800': __('identifierSchemeCodeUnspsc190501.50564800'),
	'identifierSchemeCodeUnspsc190501.50564801': __('identifierSchemeCodeUnspsc190501.50564801'),
	'identifierSchemeCodeUnspsc190501.50564802': __('identifierSchemeCodeUnspsc190501.50564802'),
	'identifierSchemeCodeUnspsc190501.50564803': __('identifierSchemeCodeUnspsc190501.50564803'),
	'identifierSchemeCodeUnspsc190501.50564804': __('identifierSchemeCodeUnspsc190501.50564804'),
	'identifierSchemeCodeUnspsc190501.50564805': __('identifierSchemeCodeUnspsc190501.50564805'),
	'identifierSchemeCodeUnspsc190501.50564806': __('identifierSchemeCodeUnspsc190501.50564806'),
	'identifierSchemeCodeUnspsc190501.50564807': __('identifierSchemeCodeUnspsc190501.50564807'),
	'identifierSchemeCodeUnspsc190501.50564808': __('identifierSchemeCodeUnspsc190501.50564808'),
	'identifierSchemeCodeUnspsc190501.50564809': __('identifierSchemeCodeUnspsc190501.50564809'),
	'identifierSchemeCodeUnspsc190501.50564810': __('identifierSchemeCodeUnspsc190501.50564810'),
	'identifierSchemeCodeUnspsc190501.50564811': __('identifierSchemeCodeUnspsc190501.50564811'),
	'identifierSchemeCodeUnspsc190501.50564812': __('identifierSchemeCodeUnspsc190501.50564812'),
	'identifierSchemeCodeUnspsc190501.50564813': __('identifierSchemeCodeUnspsc190501.50564813'),
	'identifierSchemeCodeUnspsc190501.50564814': __('identifierSchemeCodeUnspsc190501.50564814'),
	'identifierSchemeCodeUnspsc190501.50564815': __('identifierSchemeCodeUnspsc190501.50564815'),
	'identifierSchemeCodeUnspsc190501.50564816': __('identifierSchemeCodeUnspsc190501.50564816'),
	'identifierSchemeCodeUnspsc190501.50564817': __('identifierSchemeCodeUnspsc190501.50564817'),
	'identifierSchemeCodeUnspsc190501.50564818': __('identifierSchemeCodeUnspsc190501.50564818'),
	'identifierSchemeCodeUnspsc190501.50564819': __('identifierSchemeCodeUnspsc190501.50564819'),
	'identifierSchemeCodeUnspsc190501.50564820': __('identifierSchemeCodeUnspsc190501.50564820'),
	'identifierSchemeCodeUnspsc190501.50564821': __('identifierSchemeCodeUnspsc190501.50564821'),
	'identifierSchemeCodeUnspsc190501.50564822': __('identifierSchemeCodeUnspsc190501.50564822'),
	'identifierSchemeCodeUnspsc190501.50564823': __('identifierSchemeCodeUnspsc190501.50564823'),
	'identifierSchemeCodeUnspsc190501.50564824': __('identifierSchemeCodeUnspsc190501.50564824'),
	'identifierSchemeCodeUnspsc190501.50564825': __('identifierSchemeCodeUnspsc190501.50564825'),
	'identifierSchemeCodeUnspsc190501.50564826': __('identifierSchemeCodeUnspsc190501.50564826'),
	'identifierSchemeCodeUnspsc190501.50564827': __('identifierSchemeCodeUnspsc190501.50564827'),
	'identifierSchemeCodeUnspsc190501.50564828': __('identifierSchemeCodeUnspsc190501.50564828'),
	'identifierSchemeCodeUnspsc190501.50564829': __('identifierSchemeCodeUnspsc190501.50564829'),
	'identifierSchemeCodeUnspsc190501.50564830': __('identifierSchemeCodeUnspsc190501.50564830'),
	'identifierSchemeCodeUnspsc190501.50564831': __('identifierSchemeCodeUnspsc190501.50564831'),
	'identifierSchemeCodeUnspsc190501.50564832': __('identifierSchemeCodeUnspsc190501.50564832'),
	'identifierSchemeCodeUnspsc190501.50564833': __('identifierSchemeCodeUnspsc190501.50564833'),
	'identifierSchemeCodeUnspsc190501.50564834': __('identifierSchemeCodeUnspsc190501.50564834'),
	'identifierSchemeCodeUnspsc190501.50564835': __('identifierSchemeCodeUnspsc190501.50564835'),
	'identifierSchemeCodeUnspsc190501.50564836': __('identifierSchemeCodeUnspsc190501.50564836'),
	'identifierSchemeCodeUnspsc190501.50564837': __('identifierSchemeCodeUnspsc190501.50564837'),
	'identifierSchemeCodeUnspsc190501.50564838': __('identifierSchemeCodeUnspsc190501.50564838'),
	'identifierSchemeCodeUnspsc190501.50564839': __('identifierSchemeCodeUnspsc190501.50564839'),
	'identifierSchemeCodeUnspsc190501.50564840': __('identifierSchemeCodeUnspsc190501.50564840'),
	'identifierSchemeCodeUnspsc190501.50564841': __('identifierSchemeCodeUnspsc190501.50564841'),
	'identifierSchemeCodeUnspsc190501.50564842': __('identifierSchemeCodeUnspsc190501.50564842'),
	'identifierSchemeCodeUnspsc190501.50564843': __('identifierSchemeCodeUnspsc190501.50564843'),
	'identifierSchemeCodeUnspsc190501.50564844': __('identifierSchemeCodeUnspsc190501.50564844'),
	'identifierSchemeCodeUnspsc190501.50564845': __('identifierSchemeCodeUnspsc190501.50564845'),
	'identifierSchemeCodeUnspsc190501.50564846': __('identifierSchemeCodeUnspsc190501.50564846'),
	'identifierSchemeCodeUnspsc190501.50564847': __('identifierSchemeCodeUnspsc190501.50564847'),
	'identifierSchemeCodeUnspsc190501.50564848': __('identifierSchemeCodeUnspsc190501.50564848'),
	'identifierSchemeCodeUnspsc190501.50564849': __('identifierSchemeCodeUnspsc190501.50564849'),
	'identifierSchemeCodeUnspsc190501.50564850': __('identifierSchemeCodeUnspsc190501.50564850'),
	'identifierSchemeCodeUnspsc190501.50564851': __('identifierSchemeCodeUnspsc190501.50564851'),
	'identifierSchemeCodeUnspsc190501.50564852': __('identifierSchemeCodeUnspsc190501.50564852'),
	'identifierSchemeCodeUnspsc190501.50564853': __('identifierSchemeCodeUnspsc190501.50564853'),
	'identifierSchemeCodeUnspsc190501.50564854': __('identifierSchemeCodeUnspsc190501.50564854'),
	'identifierSchemeCodeUnspsc190501.50564855': __('identifierSchemeCodeUnspsc190501.50564855'),
	'identifierSchemeCodeUnspsc190501.50564856': __('identifierSchemeCodeUnspsc190501.50564856'),
	'identifierSchemeCodeUnspsc190501.50564857': __('identifierSchemeCodeUnspsc190501.50564857'),
	'identifierSchemeCodeUnspsc190501.50564900': __('identifierSchemeCodeUnspsc190501.50564900'),
	'identifierSchemeCodeUnspsc190501.50564901': __('identifierSchemeCodeUnspsc190501.50564901'),
	'identifierSchemeCodeUnspsc190501.50564902': __('identifierSchemeCodeUnspsc190501.50564902'),
	'identifierSchemeCodeUnspsc190501.50564903': __('identifierSchemeCodeUnspsc190501.50564903'),
	'identifierSchemeCodeUnspsc190501.50564904': __('identifierSchemeCodeUnspsc190501.50564904'),
	'identifierSchemeCodeUnspsc190501.50564905': __('identifierSchemeCodeUnspsc190501.50564905'),
	'identifierSchemeCodeUnspsc190501.50564906': __('identifierSchemeCodeUnspsc190501.50564906'),
	'identifierSchemeCodeUnspsc190501.50564907': __('identifierSchemeCodeUnspsc190501.50564907'),
	'identifierSchemeCodeUnspsc190501.50564908': __('identifierSchemeCodeUnspsc190501.50564908'),
	'identifierSchemeCodeUnspsc190501.50564909': __('identifierSchemeCodeUnspsc190501.50564909'),
	'identifierSchemeCodeUnspsc190501.50564910': __('identifierSchemeCodeUnspsc190501.50564910'),
	'identifierSchemeCodeUnspsc190501.50564911': __('identifierSchemeCodeUnspsc190501.50564911'),
	'identifierSchemeCodeUnspsc190501.50564912': __('identifierSchemeCodeUnspsc190501.50564912'),
	'identifierSchemeCodeUnspsc190501.50564913': __('identifierSchemeCodeUnspsc190501.50564913'),
	'identifierSchemeCodeUnspsc190501.50564914': __('identifierSchemeCodeUnspsc190501.50564914'),
	'identifierSchemeCodeUnspsc190501.50564915': __('identifierSchemeCodeUnspsc190501.50564915'),
	'identifierSchemeCodeUnspsc190501.50564916': __('identifierSchemeCodeUnspsc190501.50564916'),
	'identifierSchemeCodeUnspsc190501.50564917': __('identifierSchemeCodeUnspsc190501.50564917'),
	'identifierSchemeCodeUnspsc190501.50564918': __('identifierSchemeCodeUnspsc190501.50564918'),
	'identifierSchemeCodeUnspsc190501.50564919': __('identifierSchemeCodeUnspsc190501.50564919'),
	'identifierSchemeCodeUnspsc190501.50564920': __('identifierSchemeCodeUnspsc190501.50564920'),
	'identifierSchemeCodeUnspsc190501.50564921': __('identifierSchemeCodeUnspsc190501.50564921'),
	'identifierSchemeCodeUnspsc190501.50564922': __('identifierSchemeCodeUnspsc190501.50564922'),
	'identifierSchemeCodeUnspsc190501.50564923': __('identifierSchemeCodeUnspsc190501.50564923'),
	'identifierSchemeCodeUnspsc190501.50564924': __('identifierSchemeCodeUnspsc190501.50564924'),
	'identifierSchemeCodeUnspsc190501.50564925': __('identifierSchemeCodeUnspsc190501.50564925'),
	'identifierSchemeCodeUnspsc190501.50564926': __('identifierSchemeCodeUnspsc190501.50564926'),
	'identifierSchemeCodeUnspsc190501.50564927': __('identifierSchemeCodeUnspsc190501.50564927'),
	'identifierSchemeCodeUnspsc190501.50564928': __('identifierSchemeCodeUnspsc190501.50564928'),
	'identifierSchemeCodeUnspsc190501.50564929': __('identifierSchemeCodeUnspsc190501.50564929'),
	'identifierSchemeCodeUnspsc190501.50564930': __('identifierSchemeCodeUnspsc190501.50564930'),
	'identifierSchemeCodeUnspsc190501.50564931': __('identifierSchemeCodeUnspsc190501.50564931'),
	'identifierSchemeCodeUnspsc190501.50564932': __('identifierSchemeCodeUnspsc190501.50564932'),
	'identifierSchemeCodeUnspsc190501.50564933': __('identifierSchemeCodeUnspsc190501.50564933'),
	'identifierSchemeCodeUnspsc190501.50564934': __('identifierSchemeCodeUnspsc190501.50564934'),
	'identifierSchemeCodeUnspsc190501.50564935': __('identifierSchemeCodeUnspsc190501.50564935'),
	'identifierSchemeCodeUnspsc190501.50564936': __('identifierSchemeCodeUnspsc190501.50564936'),
	'identifierSchemeCodeUnspsc190501.50564937': __('identifierSchemeCodeUnspsc190501.50564937'),
	'identifierSchemeCodeUnspsc190501.50564938': __('identifierSchemeCodeUnspsc190501.50564938'),
	'identifierSchemeCodeUnspsc190501.50564939': __('identifierSchemeCodeUnspsc190501.50564939'),
	'identifierSchemeCodeUnspsc190501.50564940': __('identifierSchemeCodeUnspsc190501.50564940'),
	'identifierSchemeCodeUnspsc190501.50564941': __('identifierSchemeCodeUnspsc190501.50564941'),
	'identifierSchemeCodeUnspsc190501.50564942': __('identifierSchemeCodeUnspsc190501.50564942'),
	'identifierSchemeCodeUnspsc190501.50564943': __('identifierSchemeCodeUnspsc190501.50564943'),
	'identifierSchemeCodeUnspsc190501.50564944': __('identifierSchemeCodeUnspsc190501.50564944'),
	'identifierSchemeCodeUnspsc190501.50565000': __('identifierSchemeCodeUnspsc190501.50565000'),
	'identifierSchemeCodeUnspsc190501.50565001': __('identifierSchemeCodeUnspsc190501.50565001'),
	'identifierSchemeCodeUnspsc190501.50565002': __('identifierSchemeCodeUnspsc190501.50565002'),
	'identifierSchemeCodeUnspsc190501.50565003': __('identifierSchemeCodeUnspsc190501.50565003'),
	'identifierSchemeCodeUnspsc190501.50565004': __('identifierSchemeCodeUnspsc190501.50565004'),
	'identifierSchemeCodeUnspsc190501.50565005': __('identifierSchemeCodeUnspsc190501.50565005'),
	'identifierSchemeCodeUnspsc190501.50565006': __('identifierSchemeCodeUnspsc190501.50565006'),
	'identifierSchemeCodeUnspsc190501.50565100': __('identifierSchemeCodeUnspsc190501.50565100'),
	'identifierSchemeCodeUnspsc190501.50565101': __('identifierSchemeCodeUnspsc190501.50565101'),
	'identifierSchemeCodeUnspsc190501.50565102': __('identifierSchemeCodeUnspsc190501.50565102'),
	'identifierSchemeCodeUnspsc190501.50565103': __('identifierSchemeCodeUnspsc190501.50565103'),
	'identifierSchemeCodeUnspsc190501.50565104': __('identifierSchemeCodeUnspsc190501.50565104'),
	'identifierSchemeCodeUnspsc190501.50565105': __('identifierSchemeCodeUnspsc190501.50565105'),
	'identifierSchemeCodeUnspsc190501.50565106': __('identifierSchemeCodeUnspsc190501.50565106'),
	'identifierSchemeCodeUnspsc190501.50565107': __('identifierSchemeCodeUnspsc190501.50565107'),
	'identifierSchemeCodeUnspsc190501.50565108': __('identifierSchemeCodeUnspsc190501.50565108'),
	'identifierSchemeCodeUnspsc190501.50565109': __('identifierSchemeCodeUnspsc190501.50565109'),
	'identifierSchemeCodeUnspsc190501.50565110': __('identifierSchemeCodeUnspsc190501.50565110'),
	'identifierSchemeCodeUnspsc190501.50565200': __('identifierSchemeCodeUnspsc190501.50565200'),
	'identifierSchemeCodeUnspsc190501.50565201': __('identifierSchemeCodeUnspsc190501.50565201'),
	'identifierSchemeCodeUnspsc190501.50565202': __('identifierSchemeCodeUnspsc190501.50565202'),
	'identifierSchemeCodeUnspsc190501.50565203': __('identifierSchemeCodeUnspsc190501.50565203'),
	'identifierSchemeCodeUnspsc190501.50565204': __('identifierSchemeCodeUnspsc190501.50565204'),
	'identifierSchemeCodeUnspsc190501.50565205': __('identifierSchemeCodeUnspsc190501.50565205'),
	'identifierSchemeCodeUnspsc190501.50565206': __('identifierSchemeCodeUnspsc190501.50565206'),
	'identifierSchemeCodeUnspsc190501.50565207': __('identifierSchemeCodeUnspsc190501.50565207'),
	'identifierSchemeCodeUnspsc190501.50565208': __('identifierSchemeCodeUnspsc190501.50565208'),
	'identifierSchemeCodeUnspsc190501.50565209': __('identifierSchemeCodeUnspsc190501.50565209'),
	'identifierSchemeCodeUnspsc190501.50565210': __('identifierSchemeCodeUnspsc190501.50565210'),
	'identifierSchemeCodeUnspsc190501.50565211': __('identifierSchemeCodeUnspsc190501.50565211'),
	'identifierSchemeCodeUnspsc190501.50565212': __('identifierSchemeCodeUnspsc190501.50565212'),
	'identifierSchemeCodeUnspsc190501.50565213': __('identifierSchemeCodeUnspsc190501.50565213'),
	'identifierSchemeCodeUnspsc190501.50565214': __('identifierSchemeCodeUnspsc190501.50565214'),
	'identifierSchemeCodeUnspsc190501.50565215': __('identifierSchemeCodeUnspsc190501.50565215'),
	'identifierSchemeCodeUnspsc190501.50565216': __('identifierSchemeCodeUnspsc190501.50565216'),
	'identifierSchemeCodeUnspsc190501.50565217': __('identifierSchemeCodeUnspsc190501.50565217'),
	'identifierSchemeCodeUnspsc190501.50565218': __('identifierSchemeCodeUnspsc190501.50565218'),
	'identifierSchemeCodeUnspsc190501.50565219': __('identifierSchemeCodeUnspsc190501.50565219'),
	'identifierSchemeCodeUnspsc190501.50565220': __('identifierSchemeCodeUnspsc190501.50565220'),
	'identifierSchemeCodeUnspsc190501.50565221': __('identifierSchemeCodeUnspsc190501.50565221'),
	'identifierSchemeCodeUnspsc190501.50565222': __('identifierSchemeCodeUnspsc190501.50565222'),
	'identifierSchemeCodeUnspsc190501.50565223': __('identifierSchemeCodeUnspsc190501.50565223'),
	'identifierSchemeCodeUnspsc190501.50565224': __('identifierSchemeCodeUnspsc190501.50565224'),
	'identifierSchemeCodeUnspsc190501.50565225': __('identifierSchemeCodeUnspsc190501.50565225'),
	'identifierSchemeCodeUnspsc190501.50565226': __('identifierSchemeCodeUnspsc190501.50565226'),
	'identifierSchemeCodeUnspsc190501.50565227': __('identifierSchemeCodeUnspsc190501.50565227'),
	'identifierSchemeCodeUnspsc190501.50565228': __('identifierSchemeCodeUnspsc190501.50565228'),
	'identifierSchemeCodeUnspsc190501.50565229': __('identifierSchemeCodeUnspsc190501.50565229'),
	'identifierSchemeCodeUnspsc190501.50565230': __('identifierSchemeCodeUnspsc190501.50565230'),
	'identifierSchemeCodeUnspsc190501.50565231': __('identifierSchemeCodeUnspsc190501.50565231'),
	'identifierSchemeCodeUnspsc190501.50565232': __('identifierSchemeCodeUnspsc190501.50565232'),
	'identifierSchemeCodeUnspsc190501.50565233': __('identifierSchemeCodeUnspsc190501.50565233'),
	'identifierSchemeCodeUnspsc190501.50565234': __('identifierSchemeCodeUnspsc190501.50565234'),
	'identifierSchemeCodeUnspsc190501.50565235': __('identifierSchemeCodeUnspsc190501.50565235'),
	'identifierSchemeCodeUnspsc190501.50565236': __('identifierSchemeCodeUnspsc190501.50565236'),
	'identifierSchemeCodeUnspsc190501.50565237': __('identifierSchemeCodeUnspsc190501.50565237'),
	'identifierSchemeCodeUnspsc190501.50565238': __('identifierSchemeCodeUnspsc190501.50565238'),
	'identifierSchemeCodeUnspsc190501.50565239': __('identifierSchemeCodeUnspsc190501.50565239'),
	'identifierSchemeCodeUnspsc190501.50565240': __('identifierSchemeCodeUnspsc190501.50565240'),
	'identifierSchemeCodeUnspsc190501.50565241': __('identifierSchemeCodeUnspsc190501.50565241'),
	'identifierSchemeCodeUnspsc190501.50565242': __('identifierSchemeCodeUnspsc190501.50565242'),
	'identifierSchemeCodeUnspsc190501.50565243': __('identifierSchemeCodeUnspsc190501.50565243'),
	'identifierSchemeCodeUnspsc190501.50565244': __('identifierSchemeCodeUnspsc190501.50565244'),
	'identifierSchemeCodeUnspsc190501.50565245': __('identifierSchemeCodeUnspsc190501.50565245'),
	'identifierSchemeCodeUnspsc190501.50565246': __('identifierSchemeCodeUnspsc190501.50565246'),
	'identifierSchemeCodeUnspsc190501.50565247': __('identifierSchemeCodeUnspsc190501.50565247'),
	'identifierSchemeCodeUnspsc190501.50565248': __('identifierSchemeCodeUnspsc190501.50565248'),
	'identifierSchemeCodeUnspsc190501.50565249': __('identifierSchemeCodeUnspsc190501.50565249'),
	'identifierSchemeCodeUnspsc190501.50565250': __('identifierSchemeCodeUnspsc190501.50565250'),
	'identifierSchemeCodeUnspsc190501.50565251': __('identifierSchemeCodeUnspsc190501.50565251'),
	'identifierSchemeCodeUnspsc190501.50565252': __('identifierSchemeCodeUnspsc190501.50565252'),
	'identifierSchemeCodeUnspsc190501.50565253': __('identifierSchemeCodeUnspsc190501.50565253'),
	'identifierSchemeCodeUnspsc190501.50565254': __('identifierSchemeCodeUnspsc190501.50565254'),
	'identifierSchemeCodeUnspsc190501.50565255': __('identifierSchemeCodeUnspsc190501.50565255'),
	'identifierSchemeCodeUnspsc190501.50565256': __('identifierSchemeCodeUnspsc190501.50565256'),
	'identifierSchemeCodeUnspsc190501.50565257': __('identifierSchemeCodeUnspsc190501.50565257'),
	'identifierSchemeCodeUnspsc190501.50565258': __('identifierSchemeCodeUnspsc190501.50565258'),
	'identifierSchemeCodeUnspsc190501.50565259': __('identifierSchemeCodeUnspsc190501.50565259'),
	'identifierSchemeCodeUnspsc190501.50565260': __('identifierSchemeCodeUnspsc190501.50565260'),
	'identifierSchemeCodeUnspsc190501.50565261': __('identifierSchemeCodeUnspsc190501.50565261'),
	'identifierSchemeCodeUnspsc190501.50565262': __('identifierSchemeCodeUnspsc190501.50565262'),
	'identifierSchemeCodeUnspsc190501.50565263': __('identifierSchemeCodeUnspsc190501.50565263'),
	'identifierSchemeCodeUnspsc190501.50565264': __('identifierSchemeCodeUnspsc190501.50565264'),
	'identifierSchemeCodeUnspsc190501.50565265': __('identifierSchemeCodeUnspsc190501.50565265'),
	'identifierSchemeCodeUnspsc190501.50565266': __('identifierSchemeCodeUnspsc190501.50565266'),
	'identifierSchemeCodeUnspsc190501.50565267': __('identifierSchemeCodeUnspsc190501.50565267'),
	'identifierSchemeCodeUnspsc190501.50565268': __('identifierSchemeCodeUnspsc190501.50565268'),
	'identifierSchemeCodeUnspsc190501.50565269': __('identifierSchemeCodeUnspsc190501.50565269'),
	'identifierSchemeCodeUnspsc190501.50565270': __('identifierSchemeCodeUnspsc190501.50565270'),
	'identifierSchemeCodeUnspsc190501.50565271': __('identifierSchemeCodeUnspsc190501.50565271'),
	'identifierSchemeCodeUnspsc190501.50565300': __('identifierSchemeCodeUnspsc190501.50565300'),
	'identifierSchemeCodeUnspsc190501.50565301': __('identifierSchemeCodeUnspsc190501.50565301'),
	'identifierSchemeCodeUnspsc190501.50565302': __('identifierSchemeCodeUnspsc190501.50565302'),
	'identifierSchemeCodeUnspsc190501.50565303': __('identifierSchemeCodeUnspsc190501.50565303'),
	'identifierSchemeCodeUnspsc190501.50565304': __('identifierSchemeCodeUnspsc190501.50565304'),
	'identifierSchemeCodeUnspsc190501.50565305': __('identifierSchemeCodeUnspsc190501.50565305'),
	'identifierSchemeCodeUnspsc190501.50565306': __('identifierSchemeCodeUnspsc190501.50565306'),
	'identifierSchemeCodeUnspsc190501.50565307': __('identifierSchemeCodeUnspsc190501.50565307'),
	'identifierSchemeCodeUnspsc190501.50565308': __('identifierSchemeCodeUnspsc190501.50565308'),
	'identifierSchemeCodeUnspsc190501.50565309': __('identifierSchemeCodeUnspsc190501.50565309'),
	'identifierSchemeCodeUnspsc190501.50565310': __('identifierSchemeCodeUnspsc190501.50565310'),
	'identifierSchemeCodeUnspsc190501.50565311': __('identifierSchemeCodeUnspsc190501.50565311'),
	'identifierSchemeCodeUnspsc190501.50565312': __('identifierSchemeCodeUnspsc190501.50565312'),
	'identifierSchemeCodeUnspsc190501.50565313': __('identifierSchemeCodeUnspsc190501.50565313'),
	'identifierSchemeCodeUnspsc190501.50565314': __('identifierSchemeCodeUnspsc190501.50565314'),
	'identifierSchemeCodeUnspsc190501.50565315': __('identifierSchemeCodeUnspsc190501.50565315'),
	'identifierSchemeCodeUnspsc190501.50565316': __('identifierSchemeCodeUnspsc190501.50565316'),
	'identifierSchemeCodeUnspsc190501.50565317': __('identifierSchemeCodeUnspsc190501.50565317'),
	'identifierSchemeCodeUnspsc190501.50565318': __('identifierSchemeCodeUnspsc190501.50565318'),
	'identifierSchemeCodeUnspsc190501.50565319': __('identifierSchemeCodeUnspsc190501.50565319'),
	'identifierSchemeCodeUnspsc190501.50565320': __('identifierSchemeCodeUnspsc190501.50565320'),
	'identifierSchemeCodeUnspsc190501.50565321': __('identifierSchemeCodeUnspsc190501.50565321'),
	'identifierSchemeCodeUnspsc190501.50565322': __('identifierSchemeCodeUnspsc190501.50565322'),
	'identifierSchemeCodeUnspsc190501.50565323': __('identifierSchemeCodeUnspsc190501.50565323'),
	'identifierSchemeCodeUnspsc190501.50565324': __('identifierSchemeCodeUnspsc190501.50565324'),
	'identifierSchemeCodeUnspsc190501.50565325': __('identifierSchemeCodeUnspsc190501.50565325'),
	'identifierSchemeCodeUnspsc190501.50565326': __('identifierSchemeCodeUnspsc190501.50565326'),
	'identifierSchemeCodeUnspsc190501.50565327': __('identifierSchemeCodeUnspsc190501.50565327'),
	'identifierSchemeCodeUnspsc190501.50565328': __('identifierSchemeCodeUnspsc190501.50565328'),
	'identifierSchemeCodeUnspsc190501.50565329': __('identifierSchemeCodeUnspsc190501.50565329'),
	'identifierSchemeCodeUnspsc190501.50565330': __('identifierSchemeCodeUnspsc190501.50565330'),
	'identifierSchemeCodeUnspsc190501.50565331': __('identifierSchemeCodeUnspsc190501.50565331'),
	'identifierSchemeCodeUnspsc190501.50565332': __('identifierSchemeCodeUnspsc190501.50565332'),
	'identifierSchemeCodeUnspsc190501.50565333': __('identifierSchemeCodeUnspsc190501.50565333'),
	'identifierSchemeCodeUnspsc190501.50565334': __('identifierSchemeCodeUnspsc190501.50565334'),
	'identifierSchemeCodeUnspsc190501.50565335': __('identifierSchemeCodeUnspsc190501.50565335'),
	'identifierSchemeCodeUnspsc190501.50565336': __('identifierSchemeCodeUnspsc190501.50565336'),
	'identifierSchemeCodeUnspsc190501.50565337': __('identifierSchemeCodeUnspsc190501.50565337'),
	'identifierSchemeCodeUnspsc190501.50565338': __('identifierSchemeCodeUnspsc190501.50565338'),
	'identifierSchemeCodeUnspsc190501.50565339': __('identifierSchemeCodeUnspsc190501.50565339'),
	'identifierSchemeCodeUnspsc190501.50565340': __('identifierSchemeCodeUnspsc190501.50565340'),
	'identifierSchemeCodeUnspsc190501.50565341': __('identifierSchemeCodeUnspsc190501.50565341'),
	'identifierSchemeCodeUnspsc190501.50565342': __('identifierSchemeCodeUnspsc190501.50565342'),
	'identifierSchemeCodeUnspsc190501.50565343': __('identifierSchemeCodeUnspsc190501.50565343'),
	'identifierSchemeCodeUnspsc190501.50565344': __('identifierSchemeCodeUnspsc190501.50565344'),
	'identifierSchemeCodeUnspsc190501.50565345': __('identifierSchemeCodeUnspsc190501.50565345'),
	'identifierSchemeCodeUnspsc190501.50565346': __('identifierSchemeCodeUnspsc190501.50565346'),
	'identifierSchemeCodeUnspsc190501.50565347': __('identifierSchemeCodeUnspsc190501.50565347'),
	'identifierSchemeCodeUnspsc190501.50565348': __('identifierSchemeCodeUnspsc190501.50565348'),
	'identifierSchemeCodeUnspsc190501.50565349': __('identifierSchemeCodeUnspsc190501.50565349'),
	'identifierSchemeCodeUnspsc190501.50565350': __('identifierSchemeCodeUnspsc190501.50565350'),
	'identifierSchemeCodeUnspsc190501.50565351': __('identifierSchemeCodeUnspsc190501.50565351'),
	'identifierSchemeCodeUnspsc190501.50565352': __('identifierSchemeCodeUnspsc190501.50565352'),
	'identifierSchemeCodeUnspsc190501.50565353': __('identifierSchemeCodeUnspsc190501.50565353'),
	'identifierSchemeCodeUnspsc190501.50565354': __('identifierSchemeCodeUnspsc190501.50565354'),
	'identifierSchemeCodeUnspsc190501.50565355': __('identifierSchemeCodeUnspsc190501.50565355'),
	'identifierSchemeCodeUnspsc190501.50565356': __('identifierSchemeCodeUnspsc190501.50565356'),
	'identifierSchemeCodeUnspsc190501.50565357': __('identifierSchemeCodeUnspsc190501.50565357'),
	'identifierSchemeCodeUnspsc190501.50565358': __('identifierSchemeCodeUnspsc190501.50565358'),
	'identifierSchemeCodeUnspsc190501.50565359': __('identifierSchemeCodeUnspsc190501.50565359'),
	'identifierSchemeCodeUnspsc190501.50565360': __('identifierSchemeCodeUnspsc190501.50565360'),
	'identifierSchemeCodeUnspsc190501.50565400': __('identifierSchemeCodeUnspsc190501.50565400'),
	'identifierSchemeCodeUnspsc190501.50565401': __('identifierSchemeCodeUnspsc190501.50565401'),
	'identifierSchemeCodeUnspsc190501.50565402': __('identifierSchemeCodeUnspsc190501.50565402'),
	'identifierSchemeCodeUnspsc190501.50565403': __('identifierSchemeCodeUnspsc190501.50565403'),
	'identifierSchemeCodeUnspsc190501.50565404': __('identifierSchemeCodeUnspsc190501.50565404'),
	'identifierSchemeCodeUnspsc190501.50565405': __('identifierSchemeCodeUnspsc190501.50565405'),
	'identifierSchemeCodeUnspsc190501.50565406': __('identifierSchemeCodeUnspsc190501.50565406'),
	'identifierSchemeCodeUnspsc190501.50565407': __('identifierSchemeCodeUnspsc190501.50565407'),
	'identifierSchemeCodeUnspsc190501.50565408': __('identifierSchemeCodeUnspsc190501.50565408'),
	'identifierSchemeCodeUnspsc190501.50565409': __('identifierSchemeCodeUnspsc190501.50565409'),
	'identifierSchemeCodeUnspsc190501.50565410': __('identifierSchemeCodeUnspsc190501.50565410'),
	'identifierSchemeCodeUnspsc190501.50565411': __('identifierSchemeCodeUnspsc190501.50565411'),
	'identifierSchemeCodeUnspsc190501.50565412': __('identifierSchemeCodeUnspsc190501.50565412'),
	'identifierSchemeCodeUnspsc190501.50565500': __('identifierSchemeCodeUnspsc190501.50565500'),
	'identifierSchemeCodeUnspsc190501.50565501': __('identifierSchemeCodeUnspsc190501.50565501'),
	'identifierSchemeCodeUnspsc190501.50565502': __('identifierSchemeCodeUnspsc190501.50565502'),
	'identifierSchemeCodeUnspsc190501.50565503': __('identifierSchemeCodeUnspsc190501.50565503'),
	'identifierSchemeCodeUnspsc190501.50565504': __('identifierSchemeCodeUnspsc190501.50565504'),
	'identifierSchemeCodeUnspsc190501.50565505': __('identifierSchemeCodeUnspsc190501.50565505'),
	'identifierSchemeCodeUnspsc190501.50565506': __('identifierSchemeCodeUnspsc190501.50565506'),
	'identifierSchemeCodeUnspsc190501.50565507': __('identifierSchemeCodeUnspsc190501.50565507'),
	'identifierSchemeCodeUnspsc190501.50565508': __('identifierSchemeCodeUnspsc190501.50565508'),
	'identifierSchemeCodeUnspsc190501.50565509': __('identifierSchemeCodeUnspsc190501.50565509'),
	'identifierSchemeCodeUnspsc190501.50565510': __('identifierSchemeCodeUnspsc190501.50565510'),
	'identifierSchemeCodeUnspsc190501.50565600': __('identifierSchemeCodeUnspsc190501.50565600'),
	'identifierSchemeCodeUnspsc190501.50565601': __('identifierSchemeCodeUnspsc190501.50565601'),
	'identifierSchemeCodeUnspsc190501.50565602': __('identifierSchemeCodeUnspsc190501.50565602'),
	'identifierSchemeCodeUnspsc190501.50565603': __('identifierSchemeCodeUnspsc190501.50565603'),
	'identifierSchemeCodeUnspsc190501.50565604': __('identifierSchemeCodeUnspsc190501.50565604'),
	'identifierSchemeCodeUnspsc190501.50565605': __('identifierSchemeCodeUnspsc190501.50565605'),
	'identifierSchemeCodeUnspsc190501.50565606': __('identifierSchemeCodeUnspsc190501.50565606'),
	'identifierSchemeCodeUnspsc190501.50565607': __('identifierSchemeCodeUnspsc190501.50565607'),
	'identifierSchemeCodeUnspsc190501.50565608': __('identifierSchemeCodeUnspsc190501.50565608'),
	'identifierSchemeCodeUnspsc190501.50565609': __('identifierSchemeCodeUnspsc190501.50565609'),
	'identifierSchemeCodeUnspsc190501.50565610': __('identifierSchemeCodeUnspsc190501.50565610'),
	'identifierSchemeCodeUnspsc190501.50565611': __('identifierSchemeCodeUnspsc190501.50565611'),
	'identifierSchemeCodeUnspsc190501.50565612': __('identifierSchemeCodeUnspsc190501.50565612'),
	'identifierSchemeCodeUnspsc190501.50565613': __('identifierSchemeCodeUnspsc190501.50565613'),
	'identifierSchemeCodeUnspsc190501.50565614': __('identifierSchemeCodeUnspsc190501.50565614'),
	'identifierSchemeCodeUnspsc190501.50565615': __('identifierSchemeCodeUnspsc190501.50565615'),
	'identifierSchemeCodeUnspsc190501.50565616': __('identifierSchemeCodeUnspsc190501.50565616'),
	'identifierSchemeCodeUnspsc190501.50565617': __('identifierSchemeCodeUnspsc190501.50565617'),
	'identifierSchemeCodeUnspsc190501.50565618': __('identifierSchemeCodeUnspsc190501.50565618'),
	'identifierSchemeCodeUnspsc190501.50565619': __('identifierSchemeCodeUnspsc190501.50565619'),
	'identifierSchemeCodeUnspsc190501.50565620': __('identifierSchemeCodeUnspsc190501.50565620'),
	'identifierSchemeCodeUnspsc190501.50565621': __('identifierSchemeCodeUnspsc190501.50565621'),
	'identifierSchemeCodeUnspsc190501.50565622': __('identifierSchemeCodeUnspsc190501.50565622'),
	'identifierSchemeCodeUnspsc190501.50565623': __('identifierSchemeCodeUnspsc190501.50565623'),
	'identifierSchemeCodeUnspsc190501.50565700': __('identifierSchemeCodeUnspsc190501.50565700'),
	'identifierSchemeCodeUnspsc190501.50565701': __('identifierSchemeCodeUnspsc190501.50565701'),
	'identifierSchemeCodeUnspsc190501.50565702': __('identifierSchemeCodeUnspsc190501.50565702'),
	'identifierSchemeCodeUnspsc190501.50565703': __('identifierSchemeCodeUnspsc190501.50565703'),
	'identifierSchemeCodeUnspsc190501.50565704': __('identifierSchemeCodeUnspsc190501.50565704'),
	'identifierSchemeCodeUnspsc190501.50565705': __('identifierSchemeCodeUnspsc190501.50565705'),
	'identifierSchemeCodeUnspsc190501.50565706': __('identifierSchemeCodeUnspsc190501.50565706'),
	'identifierSchemeCodeUnspsc190501.50565707': __('identifierSchemeCodeUnspsc190501.50565707'),
	'identifierSchemeCodeUnspsc190501.50565708': __('identifierSchemeCodeUnspsc190501.50565708'),
	'identifierSchemeCodeUnspsc190501.50565709': __('identifierSchemeCodeUnspsc190501.50565709'),
	'identifierSchemeCodeUnspsc190501.50565710': __('identifierSchemeCodeUnspsc190501.50565710'),
	'identifierSchemeCodeUnspsc190501.50565711': __('identifierSchemeCodeUnspsc190501.50565711'),
	'identifierSchemeCodeUnspsc190501.50565712': __('identifierSchemeCodeUnspsc190501.50565712'),
	'identifierSchemeCodeUnspsc190501.50565713': __('identifierSchemeCodeUnspsc190501.50565713'),
	'identifierSchemeCodeUnspsc190501.50565714': __('identifierSchemeCodeUnspsc190501.50565714'),
	'identifierSchemeCodeUnspsc190501.50565715': __('identifierSchemeCodeUnspsc190501.50565715'),
	'identifierSchemeCodeUnspsc190501.50565716': __('identifierSchemeCodeUnspsc190501.50565716'),
	'identifierSchemeCodeUnspsc190501.50565717': __('identifierSchemeCodeUnspsc190501.50565717'),
	'identifierSchemeCodeUnspsc190501.50565718': __('identifierSchemeCodeUnspsc190501.50565718'),
	'identifierSchemeCodeUnspsc190501.50565719': __('identifierSchemeCodeUnspsc190501.50565719'),
	'identifierSchemeCodeUnspsc190501.50565720': __('identifierSchemeCodeUnspsc190501.50565720'),
	'identifierSchemeCodeUnspsc190501.50565721': __('identifierSchemeCodeUnspsc190501.50565721'),
	'identifierSchemeCodeUnspsc190501.50565722': __('identifierSchemeCodeUnspsc190501.50565722'),
	'identifierSchemeCodeUnspsc190501.50565723': __('identifierSchemeCodeUnspsc190501.50565723'),
	'identifierSchemeCodeUnspsc190501.50565724': __('identifierSchemeCodeUnspsc190501.50565724'),
	'identifierSchemeCodeUnspsc190501.50565725': __('identifierSchemeCodeUnspsc190501.50565725'),
	'identifierSchemeCodeUnspsc190501.50565726': __('identifierSchemeCodeUnspsc190501.50565726'),
	'identifierSchemeCodeUnspsc190501.50565727': __('identifierSchemeCodeUnspsc190501.50565727'),
	'identifierSchemeCodeUnspsc190501.50565728': __('identifierSchemeCodeUnspsc190501.50565728'),
	'identifierSchemeCodeUnspsc190501.50565729': __('identifierSchemeCodeUnspsc190501.50565729'),
	'identifierSchemeCodeUnspsc190501.50565730': __('identifierSchemeCodeUnspsc190501.50565730'),
	'identifierSchemeCodeUnspsc190501.50565731': __('identifierSchemeCodeUnspsc190501.50565731'),
	'identifierSchemeCodeUnspsc190501.50565732': __('identifierSchemeCodeUnspsc190501.50565732'),
	'identifierSchemeCodeUnspsc190501.50565733': __('identifierSchemeCodeUnspsc190501.50565733'),
	'identifierSchemeCodeUnspsc190501.50565734': __('identifierSchemeCodeUnspsc190501.50565734'),
	'identifierSchemeCodeUnspsc190501.50565735': __('identifierSchemeCodeUnspsc190501.50565735'),
	'identifierSchemeCodeUnspsc190501.50565736': __('identifierSchemeCodeUnspsc190501.50565736'),
	'identifierSchemeCodeUnspsc190501.50565737': __('identifierSchemeCodeUnspsc190501.50565737'),
	'identifierSchemeCodeUnspsc190501.50565738': __('identifierSchemeCodeUnspsc190501.50565738'),
	'identifierSchemeCodeUnspsc190501.50565739': __('identifierSchemeCodeUnspsc190501.50565739'),
	'identifierSchemeCodeUnspsc190501.50565740': __('identifierSchemeCodeUnspsc190501.50565740'),
	'identifierSchemeCodeUnspsc190501.50565741': __('identifierSchemeCodeUnspsc190501.50565741'),
	'identifierSchemeCodeUnspsc190501.50565742': __('identifierSchemeCodeUnspsc190501.50565742'),
	'identifierSchemeCodeUnspsc190501.50565743': __('identifierSchemeCodeUnspsc190501.50565743'),
	'identifierSchemeCodeUnspsc190501.50565744': __('identifierSchemeCodeUnspsc190501.50565744'),
	'identifierSchemeCodeUnspsc190501.50565745': __('identifierSchemeCodeUnspsc190501.50565745'),
	'identifierSchemeCodeUnspsc190501.50565746': __('identifierSchemeCodeUnspsc190501.50565746'),
	'identifierSchemeCodeUnspsc190501.50565747': __('identifierSchemeCodeUnspsc190501.50565747'),
	'identifierSchemeCodeUnspsc190501.50565748': __('identifierSchemeCodeUnspsc190501.50565748'),
	'identifierSchemeCodeUnspsc190501.50565749': __('identifierSchemeCodeUnspsc190501.50565749'),
	'identifierSchemeCodeUnspsc190501.50565750': __('identifierSchemeCodeUnspsc190501.50565750'),
	'identifierSchemeCodeUnspsc190501.50565751': __('identifierSchemeCodeUnspsc190501.50565751'),
	'identifierSchemeCodeUnspsc190501.50565752': __('identifierSchemeCodeUnspsc190501.50565752'),
	'identifierSchemeCodeUnspsc190501.50565753': __('identifierSchemeCodeUnspsc190501.50565753'),
	'identifierSchemeCodeUnspsc190501.50565754': __('identifierSchemeCodeUnspsc190501.50565754'),
	'identifierSchemeCodeUnspsc190501.50565755': __('identifierSchemeCodeUnspsc190501.50565755'),
	'identifierSchemeCodeUnspsc190501.50565756': __('identifierSchemeCodeUnspsc190501.50565756'),
	'identifierSchemeCodeUnspsc190501.50565757': __('identifierSchemeCodeUnspsc190501.50565757'),
	'identifierSchemeCodeUnspsc190501.50565758': __('identifierSchemeCodeUnspsc190501.50565758'),
	'identifierSchemeCodeUnspsc190501.50565759': __('identifierSchemeCodeUnspsc190501.50565759'),
	'identifierSchemeCodeUnspsc190501.50565760': __('identifierSchemeCodeUnspsc190501.50565760'),
	'identifierSchemeCodeUnspsc190501.50565761': __('identifierSchemeCodeUnspsc190501.50565761'),
	'identifierSchemeCodeUnspsc190501.50565762': __('identifierSchemeCodeUnspsc190501.50565762'),
	'identifierSchemeCodeUnspsc190501.50565763': __('identifierSchemeCodeUnspsc190501.50565763'),
	'identifierSchemeCodeUnspsc190501.50565764': __('identifierSchemeCodeUnspsc190501.50565764'),
	'identifierSchemeCodeUnspsc190501.50565765': __('identifierSchemeCodeUnspsc190501.50565765'),
	'identifierSchemeCodeUnspsc190501.50565766': __('identifierSchemeCodeUnspsc190501.50565766'),
	'identifierSchemeCodeUnspsc190501.50565767': __('identifierSchemeCodeUnspsc190501.50565767'),
	'identifierSchemeCodeUnspsc190501.50565768': __('identifierSchemeCodeUnspsc190501.50565768'),
	'identifierSchemeCodeUnspsc190501.50565769': __('identifierSchemeCodeUnspsc190501.50565769'),
	'identifierSchemeCodeUnspsc190501.50565770': __('identifierSchemeCodeUnspsc190501.50565770'),
	'identifierSchemeCodeUnspsc190501.50565771': __('identifierSchemeCodeUnspsc190501.50565771'),
	'identifierSchemeCodeUnspsc190501.50565772': __('identifierSchemeCodeUnspsc190501.50565772'),
	'identifierSchemeCodeUnspsc190501.50565773': __('identifierSchemeCodeUnspsc190501.50565773'),
	'identifierSchemeCodeUnspsc190501.50565774': __('identifierSchemeCodeUnspsc190501.50565774'),
	'identifierSchemeCodeUnspsc190501.50565775': __('identifierSchemeCodeUnspsc190501.50565775'),
	'identifierSchemeCodeUnspsc190501.50565776': __('identifierSchemeCodeUnspsc190501.50565776'),
	'identifierSchemeCodeUnspsc190501.50565777': __('identifierSchemeCodeUnspsc190501.50565777'),
	'identifierSchemeCodeUnspsc190501.50565800': __('identifierSchemeCodeUnspsc190501.50565800'),
	'identifierSchemeCodeUnspsc190501.50565801': __('identifierSchemeCodeUnspsc190501.50565801'),
	'identifierSchemeCodeUnspsc190501.50565802': __('identifierSchemeCodeUnspsc190501.50565802'),
	'identifierSchemeCodeUnspsc190501.50565803': __('identifierSchemeCodeUnspsc190501.50565803'),
	'identifierSchemeCodeUnspsc190501.50565804': __('identifierSchemeCodeUnspsc190501.50565804'),
	'identifierSchemeCodeUnspsc190501.50565805': __('identifierSchemeCodeUnspsc190501.50565805'),
	'identifierSchemeCodeUnspsc190501.50565806': __('identifierSchemeCodeUnspsc190501.50565806'),
	'identifierSchemeCodeUnspsc190501.50565807': __('identifierSchemeCodeUnspsc190501.50565807'),
	'identifierSchemeCodeUnspsc190501.50565808': __('identifierSchemeCodeUnspsc190501.50565808'),
	'identifierSchemeCodeUnspsc190501.50565900': __('identifierSchemeCodeUnspsc190501.50565900'),
	'identifierSchemeCodeUnspsc190501.50565901': __('identifierSchemeCodeUnspsc190501.50565901'),
	'identifierSchemeCodeUnspsc190501.50565902': __('identifierSchemeCodeUnspsc190501.50565902'),
	'identifierSchemeCodeUnspsc190501.50565903': __('identifierSchemeCodeUnspsc190501.50565903'),
	'identifierSchemeCodeUnspsc190501.50565904': __('identifierSchemeCodeUnspsc190501.50565904'),
	'identifierSchemeCodeUnspsc190501.50565905': __('identifierSchemeCodeUnspsc190501.50565905'),
	'identifierSchemeCodeUnspsc190501.50565906': __('identifierSchemeCodeUnspsc190501.50565906'),
	'identifierSchemeCodeUnspsc190501.50565907': __('identifierSchemeCodeUnspsc190501.50565907'),
	'identifierSchemeCodeUnspsc190501.50565908': __('identifierSchemeCodeUnspsc190501.50565908'),
	'identifierSchemeCodeUnspsc190501.50566000': __('identifierSchemeCodeUnspsc190501.50566000'),
	'identifierSchemeCodeUnspsc190501.50566001': __('identifierSchemeCodeUnspsc190501.50566001'),
	'identifierSchemeCodeUnspsc190501.50566002': __('identifierSchemeCodeUnspsc190501.50566002'),
	'identifierSchemeCodeUnspsc190501.50566003': __('identifierSchemeCodeUnspsc190501.50566003'),
	'identifierSchemeCodeUnspsc190501.50566100': __('identifierSchemeCodeUnspsc190501.50566100'),
	'identifierSchemeCodeUnspsc190501.50566101': __('identifierSchemeCodeUnspsc190501.50566101'),
	'identifierSchemeCodeUnspsc190501.50566102': __('identifierSchemeCodeUnspsc190501.50566102'),
	'identifierSchemeCodeUnspsc190501.50566103': __('identifierSchemeCodeUnspsc190501.50566103'),
	'identifierSchemeCodeUnspsc190501.50566104': __('identifierSchemeCodeUnspsc190501.50566104'),
	'identifierSchemeCodeUnspsc190501.50566105': __('identifierSchemeCodeUnspsc190501.50566105'),
	'identifierSchemeCodeUnspsc190501.50566106': __('identifierSchemeCodeUnspsc190501.50566106'),
	'identifierSchemeCodeUnspsc190501.50566107': __('identifierSchemeCodeUnspsc190501.50566107'),
	'identifierSchemeCodeUnspsc190501.50566108': __('identifierSchemeCodeUnspsc190501.50566108'),
	'identifierSchemeCodeUnspsc190501.50566109': __('identifierSchemeCodeUnspsc190501.50566109'),
	'identifierSchemeCodeUnspsc190501.50566110': __('identifierSchemeCodeUnspsc190501.50566110'),
	'identifierSchemeCodeUnspsc190501.50566111': __('identifierSchemeCodeUnspsc190501.50566111'),
	'identifierSchemeCodeUnspsc190501.50566112': __('identifierSchemeCodeUnspsc190501.50566112'),
	'identifierSchemeCodeUnspsc190501.50566113': __('identifierSchemeCodeUnspsc190501.50566113'),
	'identifierSchemeCodeUnspsc190501.50566114': __('identifierSchemeCodeUnspsc190501.50566114'),
	'identifierSchemeCodeUnspsc190501.50566115': __('identifierSchemeCodeUnspsc190501.50566115'),
	'identifierSchemeCodeUnspsc190501.50566116': __('identifierSchemeCodeUnspsc190501.50566116'),
	'identifierSchemeCodeUnspsc190501.50566117': __('identifierSchemeCodeUnspsc190501.50566117'),
	'identifierSchemeCodeUnspsc190501.50566118': __('identifierSchemeCodeUnspsc190501.50566118'),
	'identifierSchemeCodeUnspsc190501.50566119': __('identifierSchemeCodeUnspsc190501.50566119'),
	'identifierSchemeCodeUnspsc190501.50566120': __('identifierSchemeCodeUnspsc190501.50566120'),
	'identifierSchemeCodeUnspsc190501.50566121': __('identifierSchemeCodeUnspsc190501.50566121'),
	'identifierSchemeCodeUnspsc190501.50566122': __('identifierSchemeCodeUnspsc190501.50566122'),
	'identifierSchemeCodeUnspsc190501.50566123': __('identifierSchemeCodeUnspsc190501.50566123'),
	'identifierSchemeCodeUnspsc190501.50566124': __('identifierSchemeCodeUnspsc190501.50566124'),
	'identifierSchemeCodeUnspsc190501.50566125': __('identifierSchemeCodeUnspsc190501.50566125'),
	'identifierSchemeCodeUnspsc190501.50566126': __('identifierSchemeCodeUnspsc190501.50566126'),
	'identifierSchemeCodeUnspsc190501.50566127': __('identifierSchemeCodeUnspsc190501.50566127'),
	'identifierSchemeCodeUnspsc190501.50566128': __('identifierSchemeCodeUnspsc190501.50566128'),
	'identifierSchemeCodeUnspsc190501.50566129': __('identifierSchemeCodeUnspsc190501.50566129'),
	'identifierSchemeCodeUnspsc190501.50566200': __('identifierSchemeCodeUnspsc190501.50566200'),
	'identifierSchemeCodeUnspsc190501.50566201': __('identifierSchemeCodeUnspsc190501.50566201'),
	'identifierSchemeCodeUnspsc190501.50566202': __('identifierSchemeCodeUnspsc190501.50566202'),
	'identifierSchemeCodeUnspsc190501.50566203': __('identifierSchemeCodeUnspsc190501.50566203'),
	'identifierSchemeCodeUnspsc190501.50566204': __('identifierSchemeCodeUnspsc190501.50566204'),
	'identifierSchemeCodeUnspsc190501.50566205': __('identifierSchemeCodeUnspsc190501.50566205'),
	'identifierSchemeCodeUnspsc190501.50566206': __('identifierSchemeCodeUnspsc190501.50566206'),
	'identifierSchemeCodeUnspsc190501.50566207': __('identifierSchemeCodeUnspsc190501.50566207'),
	'identifierSchemeCodeUnspsc190501.50566208': __('identifierSchemeCodeUnspsc190501.50566208'),
	'identifierSchemeCodeUnspsc190501.50566209': __('identifierSchemeCodeUnspsc190501.50566209'),
	'identifierSchemeCodeUnspsc190501.50566210': __('identifierSchemeCodeUnspsc190501.50566210'),
	'identifierSchemeCodeUnspsc190501.50566300': __('identifierSchemeCodeUnspsc190501.50566300'),
	'identifierSchemeCodeUnspsc190501.50566301': __('identifierSchemeCodeUnspsc190501.50566301'),
	'identifierSchemeCodeUnspsc190501.50566302': __('identifierSchemeCodeUnspsc190501.50566302'),
	'identifierSchemeCodeUnspsc190501.50566303': __('identifierSchemeCodeUnspsc190501.50566303'),
	'identifierSchemeCodeUnspsc190501.50566304': __('identifierSchemeCodeUnspsc190501.50566304'),
	'identifierSchemeCodeUnspsc190501.50566400': __('identifierSchemeCodeUnspsc190501.50566400'),
	'identifierSchemeCodeUnspsc190501.50566401': __('identifierSchemeCodeUnspsc190501.50566401'),
	'identifierSchemeCodeUnspsc190501.50566402': __('identifierSchemeCodeUnspsc190501.50566402'),
	'identifierSchemeCodeUnspsc190501.50566500': __('identifierSchemeCodeUnspsc190501.50566500'),
	'identifierSchemeCodeUnspsc190501.50566501': __('identifierSchemeCodeUnspsc190501.50566501'),
	'identifierSchemeCodeUnspsc190501.50566502': __('identifierSchemeCodeUnspsc190501.50566502'),
	'identifierSchemeCodeUnspsc190501.50566503': __('identifierSchemeCodeUnspsc190501.50566503'),
	'identifierSchemeCodeUnspsc190501.50566504': __('identifierSchemeCodeUnspsc190501.50566504'),
	'identifierSchemeCodeUnspsc190501.50566600': __('identifierSchemeCodeUnspsc190501.50566600'),
	'identifierSchemeCodeUnspsc190501.50566601': __('identifierSchemeCodeUnspsc190501.50566601'),
	'identifierSchemeCodeUnspsc190501.50566602': __('identifierSchemeCodeUnspsc190501.50566602'),
	'identifierSchemeCodeUnspsc190501.50566603': __('identifierSchemeCodeUnspsc190501.50566603'),
	'identifierSchemeCodeUnspsc190501.50566700': __('identifierSchemeCodeUnspsc190501.50566700'),
	'identifierSchemeCodeUnspsc190501.50566701': __('identifierSchemeCodeUnspsc190501.50566701'),
	'identifierSchemeCodeUnspsc190501.50566702': __('identifierSchemeCodeUnspsc190501.50566702'),
	'identifierSchemeCodeUnspsc190501.50566703': __('identifierSchemeCodeUnspsc190501.50566703'),
	'identifierSchemeCodeUnspsc190501.50566800': __('identifierSchemeCodeUnspsc190501.50566800'),
	'identifierSchemeCodeUnspsc190501.50566801': __('identifierSchemeCodeUnspsc190501.50566801'),
	'identifierSchemeCodeUnspsc190501.50566802': __('identifierSchemeCodeUnspsc190501.50566802'),
	'identifierSchemeCodeUnspsc190501.50566803': __('identifierSchemeCodeUnspsc190501.50566803'),
	'identifierSchemeCodeUnspsc190501.50566804': __('identifierSchemeCodeUnspsc190501.50566804'),
	'identifierSchemeCodeUnspsc190501.50566805': __('identifierSchemeCodeUnspsc190501.50566805'),
	'identifierSchemeCodeUnspsc190501.50566806': __('identifierSchemeCodeUnspsc190501.50566806'),
	'identifierSchemeCodeUnspsc190501.50566900': __('identifierSchemeCodeUnspsc190501.50566900'),
	'identifierSchemeCodeUnspsc190501.50566901': __('identifierSchemeCodeUnspsc190501.50566901'),
	'identifierSchemeCodeUnspsc190501.50566902': __('identifierSchemeCodeUnspsc190501.50566902'),
	'identifierSchemeCodeUnspsc190501.50566903': __('identifierSchemeCodeUnspsc190501.50566903'),
	'identifierSchemeCodeUnspsc190501.50566904': __('identifierSchemeCodeUnspsc190501.50566904'),
	'identifierSchemeCodeUnspsc190501.50566905': __('identifierSchemeCodeUnspsc190501.50566905'),
	'identifierSchemeCodeUnspsc190501.50566906': __('identifierSchemeCodeUnspsc190501.50566906'),
	'identifierSchemeCodeUnspsc190501.50566907': __('identifierSchemeCodeUnspsc190501.50566907'),
	'identifierSchemeCodeUnspsc190501.50566908': __('identifierSchemeCodeUnspsc190501.50566908'),
	'identifierSchemeCodeUnspsc190501.50566909': __('identifierSchemeCodeUnspsc190501.50566909'),
	'identifierSchemeCodeUnspsc190501.50566910': __('identifierSchemeCodeUnspsc190501.50566910'),
	'identifierSchemeCodeUnspsc190501.50566911': __('identifierSchemeCodeUnspsc190501.50566911'),
	'identifierSchemeCodeUnspsc190501.50566912': __('identifierSchemeCodeUnspsc190501.50566912'),
	'identifierSchemeCodeUnspsc190501.50566913': __('identifierSchemeCodeUnspsc190501.50566913'),
	'identifierSchemeCodeUnspsc190501.50566914': __('identifierSchemeCodeUnspsc190501.50566914'),
	'identifierSchemeCodeUnspsc190501.50566915': __('identifierSchemeCodeUnspsc190501.50566915'),
	'identifierSchemeCodeUnspsc190501.50566916': __('identifierSchemeCodeUnspsc190501.50566916'),
	'identifierSchemeCodeUnspsc190501.50566917': __('identifierSchemeCodeUnspsc190501.50566917'),
	'identifierSchemeCodeUnspsc190501.50566918': __('identifierSchemeCodeUnspsc190501.50566918'),
	'identifierSchemeCodeUnspsc190501.50566919': __('identifierSchemeCodeUnspsc190501.50566919'),
	'identifierSchemeCodeUnspsc190501.50566920': __('identifierSchemeCodeUnspsc190501.50566920'),
	'identifierSchemeCodeUnspsc190501.50566921': __('identifierSchemeCodeUnspsc190501.50566921'),
	'identifierSchemeCodeUnspsc190501.50566922': __('identifierSchemeCodeUnspsc190501.50566922'),
	'identifierSchemeCodeUnspsc190501.50566923': __('identifierSchemeCodeUnspsc190501.50566923'),
	'identifierSchemeCodeUnspsc190501.50566924': __('identifierSchemeCodeUnspsc190501.50566924'),
	'identifierSchemeCodeUnspsc190501.50566925': __('identifierSchemeCodeUnspsc190501.50566925'),
	'identifierSchemeCodeUnspsc190501.50566926': __('identifierSchemeCodeUnspsc190501.50566926'),
	'identifierSchemeCodeUnspsc190501.50566927': __('identifierSchemeCodeUnspsc190501.50566927'),
	'identifierSchemeCodeUnspsc190501.50566928': __('identifierSchemeCodeUnspsc190501.50566928'),
	'identifierSchemeCodeUnspsc190501.50566929': __('identifierSchemeCodeUnspsc190501.50566929'),
	'identifierSchemeCodeUnspsc190501.50566930': __('identifierSchemeCodeUnspsc190501.50566930'),
	'identifierSchemeCodeUnspsc190501.50566931': __('identifierSchemeCodeUnspsc190501.50566931'),
	'identifierSchemeCodeUnspsc190501.50566932': __('identifierSchemeCodeUnspsc190501.50566932'),
	'identifierSchemeCodeUnspsc190501.50566933': __('identifierSchemeCodeUnspsc190501.50566933'),
	'identifierSchemeCodeUnspsc190501.50566934': __('identifierSchemeCodeUnspsc190501.50566934'),
	'identifierSchemeCodeUnspsc190501.50566935': __('identifierSchemeCodeUnspsc190501.50566935'),
	'identifierSchemeCodeUnspsc190501.50566936': __('identifierSchemeCodeUnspsc190501.50566936'),
	'identifierSchemeCodeUnspsc190501.50566937': __('identifierSchemeCodeUnspsc190501.50566937'),
	'identifierSchemeCodeUnspsc190501.50567000': __('identifierSchemeCodeUnspsc190501.50567000'),
	'identifierSchemeCodeUnspsc190501.50567001': __('identifierSchemeCodeUnspsc190501.50567001'),
	'identifierSchemeCodeUnspsc190501.50567002': __('identifierSchemeCodeUnspsc190501.50567002'),
	'identifierSchemeCodeUnspsc190501.50567003': __('identifierSchemeCodeUnspsc190501.50567003'),
	'identifierSchemeCodeUnspsc190501.50567004': __('identifierSchemeCodeUnspsc190501.50567004'),
	'identifierSchemeCodeUnspsc190501.50567100': __('identifierSchemeCodeUnspsc190501.50567100'),
	'identifierSchemeCodeUnspsc190501.50567101': __('identifierSchemeCodeUnspsc190501.50567101'),
	'identifierSchemeCodeUnspsc190501.50567102': __('identifierSchemeCodeUnspsc190501.50567102'),
	'identifierSchemeCodeUnspsc190501.50567103': __('identifierSchemeCodeUnspsc190501.50567103'),
	'identifierSchemeCodeUnspsc190501.50567104': __('identifierSchemeCodeUnspsc190501.50567104'),
	'identifierSchemeCodeUnspsc190501.50567105': __('identifierSchemeCodeUnspsc190501.50567105'),
	'identifierSchemeCodeUnspsc190501.50567106': __('identifierSchemeCodeUnspsc190501.50567106'),
	'identifierSchemeCodeUnspsc190501.50567107': __('identifierSchemeCodeUnspsc190501.50567107'),
	'identifierSchemeCodeUnspsc190501.50567108': __('identifierSchemeCodeUnspsc190501.50567108'),
	'identifierSchemeCodeUnspsc190501.50567109': __('identifierSchemeCodeUnspsc190501.50567109'),
	'identifierSchemeCodeUnspsc190501.50567110': __('identifierSchemeCodeUnspsc190501.50567110'),
	'identifierSchemeCodeUnspsc190501.50567111': __('identifierSchemeCodeUnspsc190501.50567111'),
	'identifierSchemeCodeUnspsc190501.50567112': __('identifierSchemeCodeUnspsc190501.50567112'),
	'identifierSchemeCodeUnspsc190501.50567113': __('identifierSchemeCodeUnspsc190501.50567113'),
	'identifierSchemeCodeUnspsc190501.50567114': __('identifierSchemeCodeUnspsc190501.50567114'),
	'identifierSchemeCodeUnspsc190501.50567115': __('identifierSchemeCodeUnspsc190501.50567115'),
	'identifierSchemeCodeUnspsc190501.50567116': __('identifierSchemeCodeUnspsc190501.50567116'),
	'identifierSchemeCodeUnspsc190501.50567117': __('identifierSchemeCodeUnspsc190501.50567117'),
	'identifierSchemeCodeUnspsc190501.50567118': __('identifierSchemeCodeUnspsc190501.50567118'),
	'identifierSchemeCodeUnspsc190501.50567119': __('identifierSchemeCodeUnspsc190501.50567119'),
	'identifierSchemeCodeUnspsc190501.50567120': __('identifierSchemeCodeUnspsc190501.50567120'),
	'identifierSchemeCodeUnspsc190501.50567121': __('identifierSchemeCodeUnspsc190501.50567121'),
	'identifierSchemeCodeUnspsc190501.50567122': __('identifierSchemeCodeUnspsc190501.50567122'),
	'identifierSchemeCodeUnspsc190501.50567123': __('identifierSchemeCodeUnspsc190501.50567123'),
	'identifierSchemeCodeUnspsc190501.50567124': __('identifierSchemeCodeUnspsc190501.50567124'),
	'identifierSchemeCodeUnspsc190501.50567125': __('identifierSchemeCodeUnspsc190501.50567125'),
	'identifierSchemeCodeUnspsc190501.50567126': __('identifierSchemeCodeUnspsc190501.50567126'),
	'identifierSchemeCodeUnspsc190501.50567127': __('identifierSchemeCodeUnspsc190501.50567127'),
	'identifierSchemeCodeUnspsc190501.50567128': __('identifierSchemeCodeUnspsc190501.50567128'),
	'identifierSchemeCodeUnspsc190501.50567129': __('identifierSchemeCodeUnspsc190501.50567129'),
	'identifierSchemeCodeUnspsc190501.50567130': __('identifierSchemeCodeUnspsc190501.50567130'),
	'identifierSchemeCodeUnspsc190501.50567200': __('identifierSchemeCodeUnspsc190501.50567200'),
	'identifierSchemeCodeUnspsc190501.50567201': __('identifierSchemeCodeUnspsc190501.50567201'),
	'identifierSchemeCodeUnspsc190501.50567202': __('identifierSchemeCodeUnspsc190501.50567202'),
	'identifierSchemeCodeUnspsc190501.50567203': __('identifierSchemeCodeUnspsc190501.50567203'),
	'identifierSchemeCodeUnspsc190501.50567300': __('identifierSchemeCodeUnspsc190501.50567300'),
	'identifierSchemeCodeUnspsc190501.50567301': __('identifierSchemeCodeUnspsc190501.50567301'),
	'identifierSchemeCodeUnspsc190501.50567302': __('identifierSchemeCodeUnspsc190501.50567302'),
	'identifierSchemeCodeUnspsc190501.50580000': __('identifierSchemeCodeUnspsc190501.50580000'),
	'identifierSchemeCodeUnspsc190501.50581500': __('identifierSchemeCodeUnspsc190501.50581500'),
	'identifierSchemeCodeUnspsc190501.50581501': __('identifierSchemeCodeUnspsc190501.50581501'),
	'identifierSchemeCodeUnspsc190501.50581502': __('identifierSchemeCodeUnspsc190501.50581502'),
	'identifierSchemeCodeUnspsc190501.50581503': __('identifierSchemeCodeUnspsc190501.50581503'),
	'identifierSchemeCodeUnspsc190501.50581504': __('identifierSchemeCodeUnspsc190501.50581504'),
	'identifierSchemeCodeUnspsc190501.50581505': __('identifierSchemeCodeUnspsc190501.50581505'),
	'identifierSchemeCodeUnspsc190501.50581506': __('identifierSchemeCodeUnspsc190501.50581506'),
	'identifierSchemeCodeUnspsc190501.50581507': __('identifierSchemeCodeUnspsc190501.50581507'),
	'identifierSchemeCodeUnspsc190501.50581508': __('identifierSchemeCodeUnspsc190501.50581508'),
	'identifierSchemeCodeUnspsc190501.50581509': __('identifierSchemeCodeUnspsc190501.50581509'),
	'identifierSchemeCodeUnspsc190501.50581510': __('identifierSchemeCodeUnspsc190501.50581510'),
	'identifierSchemeCodeUnspsc190501.50581511': __('identifierSchemeCodeUnspsc190501.50581511'),
	'identifierSchemeCodeUnspsc190501.50581512': __('identifierSchemeCodeUnspsc190501.50581512'),
	'identifierSchemeCodeUnspsc190501.50581513': __('identifierSchemeCodeUnspsc190501.50581513'),
	'identifierSchemeCodeUnspsc190501.50581600': __('identifierSchemeCodeUnspsc190501.50581600'),
	'identifierSchemeCodeUnspsc190501.50581601': __('identifierSchemeCodeUnspsc190501.50581601'),
	'identifierSchemeCodeUnspsc190501.50581602': __('identifierSchemeCodeUnspsc190501.50581602'),
	'identifierSchemeCodeUnspsc190501.50581603': __('identifierSchemeCodeUnspsc190501.50581603'),
	'identifierSchemeCodeUnspsc190501.50581604': __('identifierSchemeCodeUnspsc190501.50581604'),
	'identifierSchemeCodeUnspsc190501.50581605': __('identifierSchemeCodeUnspsc190501.50581605'),
	'identifierSchemeCodeUnspsc190501.50581700': __('identifierSchemeCodeUnspsc190501.50581700'),
	'identifierSchemeCodeUnspsc190501.50581701': __('identifierSchemeCodeUnspsc190501.50581701'),
	'identifierSchemeCodeUnspsc190501.50581702': __('identifierSchemeCodeUnspsc190501.50581702'),
	'identifierSchemeCodeUnspsc190501.50581703': __('identifierSchemeCodeUnspsc190501.50581703'),
	'identifierSchemeCodeUnspsc190501.50581704': __('identifierSchemeCodeUnspsc190501.50581704'),
	'identifierSchemeCodeUnspsc190501.50581705': __('identifierSchemeCodeUnspsc190501.50581705'),
	'identifierSchemeCodeUnspsc190501.50581706': __('identifierSchemeCodeUnspsc190501.50581706'),
	'identifierSchemeCodeUnspsc190501.50581707': __('identifierSchemeCodeUnspsc190501.50581707'),
	'identifierSchemeCodeUnspsc190501.50581708': __('identifierSchemeCodeUnspsc190501.50581708'),
	'identifierSchemeCodeUnspsc190501.50581709': __('identifierSchemeCodeUnspsc190501.50581709'),
	'identifierSchemeCodeUnspsc190501.50581710': __('identifierSchemeCodeUnspsc190501.50581710'),
	'identifierSchemeCodeUnspsc190501.50581711': __('identifierSchemeCodeUnspsc190501.50581711'),
	'identifierSchemeCodeUnspsc190501.50581712': __('identifierSchemeCodeUnspsc190501.50581712'),
	'identifierSchemeCodeUnspsc190501.50581713': __('identifierSchemeCodeUnspsc190501.50581713'),
	'identifierSchemeCodeUnspsc190501.50581714': __('identifierSchemeCodeUnspsc190501.50581714'),
	'identifierSchemeCodeUnspsc190501.50581715': __('identifierSchemeCodeUnspsc190501.50581715'),
	'identifierSchemeCodeUnspsc190501.50581716': __('identifierSchemeCodeUnspsc190501.50581716'),
	'identifierSchemeCodeUnspsc190501.50581717': __('identifierSchemeCodeUnspsc190501.50581717'),
	'identifierSchemeCodeUnspsc190501.50581718': __('identifierSchemeCodeUnspsc190501.50581718'),
	'identifierSchemeCodeUnspsc190501.50581719': __('identifierSchemeCodeUnspsc190501.50581719'),
	'identifierSchemeCodeUnspsc190501.50581720': __('identifierSchemeCodeUnspsc190501.50581720'),
	'identifierSchemeCodeUnspsc190501.50581721': __('identifierSchemeCodeUnspsc190501.50581721'),
	'identifierSchemeCodeUnspsc190501.50581722': __('identifierSchemeCodeUnspsc190501.50581722'),
	'identifierSchemeCodeUnspsc190501.50581723': __('identifierSchemeCodeUnspsc190501.50581723'),
	'identifierSchemeCodeUnspsc190501.50581724': __('identifierSchemeCodeUnspsc190501.50581724'),
	'identifierSchemeCodeUnspsc190501.50581725': __('identifierSchemeCodeUnspsc190501.50581725'),
	'identifierSchemeCodeUnspsc190501.50581726': __('identifierSchemeCodeUnspsc190501.50581726'),
	'identifierSchemeCodeUnspsc190501.50581727': __('identifierSchemeCodeUnspsc190501.50581727'),
	'identifierSchemeCodeUnspsc190501.50581728': __('identifierSchemeCodeUnspsc190501.50581728'),
	'identifierSchemeCodeUnspsc190501.50581729': __('identifierSchemeCodeUnspsc190501.50581729'),
	'identifierSchemeCodeUnspsc190501.50581730': __('identifierSchemeCodeUnspsc190501.50581730'),
	'identifierSchemeCodeUnspsc190501.50581731': __('identifierSchemeCodeUnspsc190501.50581731'),
	'identifierSchemeCodeUnspsc190501.50581732': __('identifierSchemeCodeUnspsc190501.50581732'),
	'identifierSchemeCodeUnspsc190501.50581733': __('identifierSchemeCodeUnspsc190501.50581733'),
	'identifierSchemeCodeUnspsc190501.50581734': __('identifierSchemeCodeUnspsc190501.50581734'),
	'identifierSchemeCodeUnspsc190501.50581735': __('identifierSchemeCodeUnspsc190501.50581735'),
	'identifierSchemeCodeUnspsc190501.50581736': __('identifierSchemeCodeUnspsc190501.50581736'),
	'identifierSchemeCodeUnspsc190501.50581737': __('identifierSchemeCodeUnspsc190501.50581737'),
	'identifierSchemeCodeUnspsc190501.50581738': __('identifierSchemeCodeUnspsc190501.50581738'),
	'identifierSchemeCodeUnspsc190501.50581739': __('identifierSchemeCodeUnspsc190501.50581739'),
	'identifierSchemeCodeUnspsc190501.50581740': __('identifierSchemeCodeUnspsc190501.50581740'),
	'identifierSchemeCodeUnspsc190501.50581741': __('identifierSchemeCodeUnspsc190501.50581741'),
	'identifierSchemeCodeUnspsc190501.50581742': __('identifierSchemeCodeUnspsc190501.50581742'),
	'identifierSchemeCodeUnspsc190501.50581743': __('identifierSchemeCodeUnspsc190501.50581743'),
	'identifierSchemeCodeUnspsc190501.50581744': __('identifierSchemeCodeUnspsc190501.50581744'),
	'identifierSchemeCodeUnspsc190501.50581745': __('identifierSchemeCodeUnspsc190501.50581745'),
	'identifierSchemeCodeUnspsc190501.50581746': __('identifierSchemeCodeUnspsc190501.50581746'),
	'identifierSchemeCodeUnspsc190501.50581747': __('identifierSchemeCodeUnspsc190501.50581747'),
	'identifierSchemeCodeUnspsc190501.50581748': __('identifierSchemeCodeUnspsc190501.50581748'),
	'identifierSchemeCodeUnspsc190501.50581749': __('identifierSchemeCodeUnspsc190501.50581749'),
	'identifierSchemeCodeUnspsc190501.50581750': __('identifierSchemeCodeUnspsc190501.50581750'),
	'identifierSchemeCodeUnspsc190501.50581751': __('identifierSchemeCodeUnspsc190501.50581751'),
	'identifierSchemeCodeUnspsc190501.50581752': __('identifierSchemeCodeUnspsc190501.50581752'),
	'identifierSchemeCodeUnspsc190501.50581753': __('identifierSchemeCodeUnspsc190501.50581753'),
	'identifierSchemeCodeUnspsc190501.50581754': __('identifierSchemeCodeUnspsc190501.50581754'),
	'identifierSchemeCodeUnspsc190501.50581755': __('identifierSchemeCodeUnspsc190501.50581755'),
	'identifierSchemeCodeUnspsc190501.50581756': __('identifierSchemeCodeUnspsc190501.50581756'),
	'identifierSchemeCodeUnspsc190501.50581757': __('identifierSchemeCodeUnspsc190501.50581757'),
	'identifierSchemeCodeUnspsc190501.50581758': __('identifierSchemeCodeUnspsc190501.50581758'),
	'identifierSchemeCodeUnspsc190501.50581759': __('identifierSchemeCodeUnspsc190501.50581759'),
	'identifierSchemeCodeUnspsc190501.50581760': __('identifierSchemeCodeUnspsc190501.50581760'),
	'identifierSchemeCodeUnspsc190501.50581761': __('identifierSchemeCodeUnspsc190501.50581761'),
	'identifierSchemeCodeUnspsc190501.50581762': __('identifierSchemeCodeUnspsc190501.50581762'),
	'identifierSchemeCodeUnspsc190501.50581763': __('identifierSchemeCodeUnspsc190501.50581763'),
	'identifierSchemeCodeUnspsc190501.50581764': __('identifierSchemeCodeUnspsc190501.50581764'),
	'identifierSchemeCodeUnspsc190501.50581765': __('identifierSchemeCodeUnspsc190501.50581765'),
	'identifierSchemeCodeUnspsc190501.50581766': __('identifierSchemeCodeUnspsc190501.50581766'),
	'identifierSchemeCodeUnspsc190501.50581767': __('identifierSchemeCodeUnspsc190501.50581767'),
	'identifierSchemeCodeUnspsc190501.50581768': __('identifierSchemeCodeUnspsc190501.50581768'),
	'identifierSchemeCodeUnspsc190501.50581769': __('identifierSchemeCodeUnspsc190501.50581769'),
	'identifierSchemeCodeUnspsc190501.50581770': __('identifierSchemeCodeUnspsc190501.50581770'),
	'identifierSchemeCodeUnspsc190501.50581771': __('identifierSchemeCodeUnspsc190501.50581771'),
	'identifierSchemeCodeUnspsc190501.50581772': __('identifierSchemeCodeUnspsc190501.50581772'),
	'identifierSchemeCodeUnspsc190501.50581773': __('identifierSchemeCodeUnspsc190501.50581773'),
	'identifierSchemeCodeUnspsc190501.50581774': __('identifierSchemeCodeUnspsc190501.50581774'),
	'identifierSchemeCodeUnspsc190501.50581775': __('identifierSchemeCodeUnspsc190501.50581775'),
	'identifierSchemeCodeUnspsc190501.50581776': __('identifierSchemeCodeUnspsc190501.50581776'),
	'identifierSchemeCodeUnspsc190501.50581777': __('identifierSchemeCodeUnspsc190501.50581777'),
	'identifierSchemeCodeUnspsc190501.50581778': __('identifierSchemeCodeUnspsc190501.50581778'),
	'identifierSchemeCodeUnspsc190501.50581779': __('identifierSchemeCodeUnspsc190501.50581779'),
	'identifierSchemeCodeUnspsc190501.50581780': __('identifierSchemeCodeUnspsc190501.50581780'),
	'identifierSchemeCodeUnspsc190501.50581781': __('identifierSchemeCodeUnspsc190501.50581781'),
	'identifierSchemeCodeUnspsc190501.50581782': __('identifierSchemeCodeUnspsc190501.50581782'),
	'identifierSchemeCodeUnspsc190501.50581800': __('identifierSchemeCodeUnspsc190501.50581800'),
	'identifierSchemeCodeUnspsc190501.50581801': __('identifierSchemeCodeUnspsc190501.50581801'),
	'identifierSchemeCodeUnspsc190501.50581802': __('identifierSchemeCodeUnspsc190501.50581802'),
	'identifierSchemeCodeUnspsc190501.50581803': __('identifierSchemeCodeUnspsc190501.50581803'),
	'identifierSchemeCodeUnspsc190501.50581804': __('identifierSchemeCodeUnspsc190501.50581804'),
	'identifierSchemeCodeUnspsc190501.50581805': __('identifierSchemeCodeUnspsc190501.50581805'),
	'identifierSchemeCodeUnspsc190501.50581806': __('identifierSchemeCodeUnspsc190501.50581806'),
	'identifierSchemeCodeUnspsc190501.50581807': __('identifierSchemeCodeUnspsc190501.50581807'),
	'identifierSchemeCodeUnspsc190501.50581808': __('identifierSchemeCodeUnspsc190501.50581808'),
	'identifierSchemeCodeUnspsc190501.50581809': __('identifierSchemeCodeUnspsc190501.50581809'),
	'identifierSchemeCodeUnspsc190501.50581810': __('identifierSchemeCodeUnspsc190501.50581810'),
	'identifierSchemeCodeUnspsc190501.50581811': __('identifierSchemeCodeUnspsc190501.50581811'),
	'identifierSchemeCodeUnspsc190501.50581812': __('identifierSchemeCodeUnspsc190501.50581812'),
	'identifierSchemeCodeUnspsc190501.50581813': __('identifierSchemeCodeUnspsc190501.50581813'),
	'identifierSchemeCodeUnspsc190501.50581814': __('identifierSchemeCodeUnspsc190501.50581814'),
	'identifierSchemeCodeUnspsc190501.50581815': __('identifierSchemeCodeUnspsc190501.50581815'),
	'identifierSchemeCodeUnspsc190501.50581816': __('identifierSchemeCodeUnspsc190501.50581816'),
	'identifierSchemeCodeUnspsc190501.50581817': __('identifierSchemeCodeUnspsc190501.50581817'),
	'identifierSchemeCodeUnspsc190501.50581818': __('identifierSchemeCodeUnspsc190501.50581818'),
	'identifierSchemeCodeUnspsc190501.50581819': __('identifierSchemeCodeUnspsc190501.50581819'),
	'identifierSchemeCodeUnspsc190501.50581820': __('identifierSchemeCodeUnspsc190501.50581820'),
	'identifierSchemeCodeUnspsc190501.50581821': __('identifierSchemeCodeUnspsc190501.50581821'),
	'identifierSchemeCodeUnspsc190501.50581822': __('identifierSchemeCodeUnspsc190501.50581822'),
	'identifierSchemeCodeUnspsc190501.50581823': __('identifierSchemeCodeUnspsc190501.50581823'),
	'identifierSchemeCodeUnspsc190501.50581824': __('identifierSchemeCodeUnspsc190501.50581824'),
	'identifierSchemeCodeUnspsc190501.50581825': __('identifierSchemeCodeUnspsc190501.50581825'),
	'identifierSchemeCodeUnspsc190501.50581826': __('identifierSchemeCodeUnspsc190501.50581826'),
	'identifierSchemeCodeUnspsc190501.50581827': __('identifierSchemeCodeUnspsc190501.50581827'),
	'identifierSchemeCodeUnspsc190501.50581828': __('identifierSchemeCodeUnspsc190501.50581828'),
	'identifierSchemeCodeUnspsc190501.50581829': __('identifierSchemeCodeUnspsc190501.50581829'),
	'identifierSchemeCodeUnspsc190501.50581830': __('identifierSchemeCodeUnspsc190501.50581830'),
	'identifierSchemeCodeUnspsc190501.50581831': __('identifierSchemeCodeUnspsc190501.50581831'),
	'identifierSchemeCodeUnspsc190501.50581832': __('identifierSchemeCodeUnspsc190501.50581832'),
	'identifierSchemeCodeUnspsc190501.50581833': __('identifierSchemeCodeUnspsc190501.50581833'),
	'identifierSchemeCodeUnspsc190501.50581834': __('identifierSchemeCodeUnspsc190501.50581834'),
	'identifierSchemeCodeUnspsc190501.50581835': __('identifierSchemeCodeUnspsc190501.50581835'),
	'identifierSchemeCodeUnspsc190501.50581836': __('identifierSchemeCodeUnspsc190501.50581836'),
	'identifierSchemeCodeUnspsc190501.50581837': __('identifierSchemeCodeUnspsc190501.50581837'),
	'identifierSchemeCodeUnspsc190501.50581838': __('identifierSchemeCodeUnspsc190501.50581838'),
	'identifierSchemeCodeUnspsc190501.50581839': __('identifierSchemeCodeUnspsc190501.50581839'),
	'identifierSchemeCodeUnspsc190501.50581840': __('identifierSchemeCodeUnspsc190501.50581840'),
	'identifierSchemeCodeUnspsc190501.50581841': __('identifierSchemeCodeUnspsc190501.50581841'),
	'identifierSchemeCodeUnspsc190501.50581842': __('identifierSchemeCodeUnspsc190501.50581842'),
	'identifierSchemeCodeUnspsc190501.50581843': __('identifierSchemeCodeUnspsc190501.50581843'),
	'identifierSchemeCodeUnspsc190501.50581844': __('identifierSchemeCodeUnspsc190501.50581844'),
	'identifierSchemeCodeUnspsc190501.50581845': __('identifierSchemeCodeUnspsc190501.50581845'),
	'identifierSchemeCodeUnspsc190501.50581846': __('identifierSchemeCodeUnspsc190501.50581846'),
	'identifierSchemeCodeUnspsc190501.50581847': __('identifierSchemeCodeUnspsc190501.50581847'),
	'identifierSchemeCodeUnspsc190501.50581848': __('identifierSchemeCodeUnspsc190501.50581848'),
	'identifierSchemeCodeUnspsc190501.50581849': __('identifierSchemeCodeUnspsc190501.50581849'),
	'identifierSchemeCodeUnspsc190501.50581850': __('identifierSchemeCodeUnspsc190501.50581850'),
	'identifierSchemeCodeUnspsc190501.50581851': __('identifierSchemeCodeUnspsc190501.50581851'),
	'identifierSchemeCodeUnspsc190501.50581852': __('identifierSchemeCodeUnspsc190501.50581852'),
	'identifierSchemeCodeUnspsc190501.50581900': __('identifierSchemeCodeUnspsc190501.50581900'),
	'identifierSchemeCodeUnspsc190501.50581901': __('identifierSchemeCodeUnspsc190501.50581901'),
	'identifierSchemeCodeUnspsc190501.50581902': __('identifierSchemeCodeUnspsc190501.50581902'),
	'identifierSchemeCodeUnspsc190501.50581903': __('identifierSchemeCodeUnspsc190501.50581903'),
	'identifierSchemeCodeUnspsc190501.50581904': __('identifierSchemeCodeUnspsc190501.50581904'),
	'identifierSchemeCodeUnspsc190501.50581905': __('identifierSchemeCodeUnspsc190501.50581905'),
	'identifierSchemeCodeUnspsc190501.50581906': __('identifierSchemeCodeUnspsc190501.50581906'),
	'identifierSchemeCodeUnspsc190501.50581907': __('identifierSchemeCodeUnspsc190501.50581907'),
	'identifierSchemeCodeUnspsc190501.50581908': __('identifierSchemeCodeUnspsc190501.50581908'),
	'identifierSchemeCodeUnspsc190501.50581909': __('identifierSchemeCodeUnspsc190501.50581909'),
	'identifierSchemeCodeUnspsc190501.50581910': __('identifierSchemeCodeUnspsc190501.50581910'),
	'identifierSchemeCodeUnspsc190501.50581911': __('identifierSchemeCodeUnspsc190501.50581911'),
	'identifierSchemeCodeUnspsc190501.50581912': __('identifierSchemeCodeUnspsc190501.50581912'),
	'identifierSchemeCodeUnspsc190501.50581913': __('identifierSchemeCodeUnspsc190501.50581913'),
	'identifierSchemeCodeUnspsc190501.50581914': __('identifierSchemeCodeUnspsc190501.50581914'),
	'identifierSchemeCodeUnspsc190501.50581915': __('identifierSchemeCodeUnspsc190501.50581915'),
	'identifierSchemeCodeUnspsc190501.50581916': __('identifierSchemeCodeUnspsc190501.50581916'),
	'identifierSchemeCodeUnspsc190501.50581917': __('identifierSchemeCodeUnspsc190501.50581917'),
	'identifierSchemeCodeUnspsc190501.50581918': __('identifierSchemeCodeUnspsc190501.50581918'),
	'identifierSchemeCodeUnspsc190501.50581919': __('identifierSchemeCodeUnspsc190501.50581919'),
	'identifierSchemeCodeUnspsc190501.50581920': __('identifierSchemeCodeUnspsc190501.50581920'),
	'identifierSchemeCodeUnspsc190501.50581921': __('identifierSchemeCodeUnspsc190501.50581921'),
	'identifierSchemeCodeUnspsc190501.50581922': __('identifierSchemeCodeUnspsc190501.50581922'),
	'identifierSchemeCodeUnspsc190501.50582000': __('identifierSchemeCodeUnspsc190501.50582000'),
	'identifierSchemeCodeUnspsc190501.50582001': __('identifierSchemeCodeUnspsc190501.50582001'),
	'identifierSchemeCodeUnspsc190501.50582002': __('identifierSchemeCodeUnspsc190501.50582002'),
	'identifierSchemeCodeUnspsc190501.50582003': __('identifierSchemeCodeUnspsc190501.50582003'),
	'identifierSchemeCodeUnspsc190501.50582004': __('identifierSchemeCodeUnspsc190501.50582004'),
	'identifierSchemeCodeUnspsc190501.50582100': __('identifierSchemeCodeUnspsc190501.50582100'),
	'identifierSchemeCodeUnspsc190501.50582101': __('identifierSchemeCodeUnspsc190501.50582101'),
	'identifierSchemeCodeUnspsc190501.50582102': __('identifierSchemeCodeUnspsc190501.50582102'),
	'identifierSchemeCodeUnspsc190501.50582103': __('identifierSchemeCodeUnspsc190501.50582103'),
	'identifierSchemeCodeUnspsc190501.50582104': __('identifierSchemeCodeUnspsc190501.50582104'),
	'identifierSchemeCodeUnspsc190501.50582105': __('identifierSchemeCodeUnspsc190501.50582105'),
	'identifierSchemeCodeUnspsc190501.50582106': __('identifierSchemeCodeUnspsc190501.50582106'),
	'identifierSchemeCodeUnspsc190501.50582107': __('identifierSchemeCodeUnspsc190501.50582107'),
	'identifierSchemeCodeUnspsc190501.50582200': __('identifierSchemeCodeUnspsc190501.50582200'),
	'identifierSchemeCodeUnspsc190501.50582201': __('identifierSchemeCodeUnspsc190501.50582201'),
	'identifierSchemeCodeUnspsc190501.50582202': __('identifierSchemeCodeUnspsc190501.50582202'),
	'identifierSchemeCodeUnspsc190501.50582203': __('identifierSchemeCodeUnspsc190501.50582203'),
	'identifierSchemeCodeUnspsc190501.50582204': __('identifierSchemeCodeUnspsc190501.50582204'),
	'identifierSchemeCodeUnspsc190501.50582205': __('identifierSchemeCodeUnspsc190501.50582205'),
	'identifierSchemeCodeUnspsc190501.50582206': __('identifierSchemeCodeUnspsc190501.50582206'),
	'identifierSchemeCodeUnspsc190501.50582207': __('identifierSchemeCodeUnspsc190501.50582207'),
	'identifierSchemeCodeUnspsc190501.50582208': __('identifierSchemeCodeUnspsc190501.50582208'),
	'identifierSchemeCodeUnspsc190501.50582209': __('identifierSchemeCodeUnspsc190501.50582209'),
	'identifierSchemeCodeUnspsc190501.50582210': __('identifierSchemeCodeUnspsc190501.50582210'),
	'identifierSchemeCodeUnspsc190501.50582211': __('identifierSchemeCodeUnspsc190501.50582211'),
	'identifierSchemeCodeUnspsc190501.50582300': __('identifierSchemeCodeUnspsc190501.50582300'),
	'identifierSchemeCodeUnspsc190501.50582301': __('identifierSchemeCodeUnspsc190501.50582301'),
	'identifierSchemeCodeUnspsc190501.50582302': __('identifierSchemeCodeUnspsc190501.50582302'),
	'identifierSchemeCodeUnspsc190501.50582303': __('identifierSchemeCodeUnspsc190501.50582303'),
	'identifierSchemeCodeUnspsc190501.50582304': __('identifierSchemeCodeUnspsc190501.50582304'),
	'identifierSchemeCodeUnspsc190501.50582400': __('identifierSchemeCodeUnspsc190501.50582400'),
	'identifierSchemeCodeUnspsc190501.50582401': __('identifierSchemeCodeUnspsc190501.50582401'),
	'identifierSchemeCodeUnspsc190501.50582402': __('identifierSchemeCodeUnspsc190501.50582402'),
	'identifierSchemeCodeUnspsc190501.50582500': __('identifierSchemeCodeUnspsc190501.50582500'),
	'identifierSchemeCodeUnspsc190501.50582501': __('identifierSchemeCodeUnspsc190501.50582501'),
	'identifierSchemeCodeUnspsc190501.50582502': __('identifierSchemeCodeUnspsc190501.50582502'),
	'identifierSchemeCodeUnspsc190501.50582503': __('identifierSchemeCodeUnspsc190501.50582503'),
	'identifierSchemeCodeUnspsc190501.50582504': __('identifierSchemeCodeUnspsc190501.50582504'),
	'identifierSchemeCodeUnspsc190501.50582505': __('identifierSchemeCodeUnspsc190501.50582505'),
	'identifierSchemeCodeUnspsc190501.50582506': __('identifierSchemeCodeUnspsc190501.50582506'),
	'identifierSchemeCodeUnspsc190501.50582600': __('identifierSchemeCodeUnspsc190501.50582600'),
	'identifierSchemeCodeUnspsc190501.50582601': __('identifierSchemeCodeUnspsc190501.50582601'),
	'identifierSchemeCodeUnspsc190501.50582602': __('identifierSchemeCodeUnspsc190501.50582602'),
	'identifierSchemeCodeUnspsc190501.50582603': __('identifierSchemeCodeUnspsc190501.50582603'),
	'identifierSchemeCodeUnspsc190501.50582604': __('identifierSchemeCodeUnspsc190501.50582604'),
	'identifierSchemeCodeUnspsc190501.50582605': __('identifierSchemeCodeUnspsc190501.50582605'),
	'identifierSchemeCodeUnspsc190501.50582606': __('identifierSchemeCodeUnspsc190501.50582606'),
	'identifierSchemeCodeUnspsc190501.50582607': __('identifierSchemeCodeUnspsc190501.50582607'),
	'identifierSchemeCodeUnspsc190501.50582608': __('identifierSchemeCodeUnspsc190501.50582608'),
	'identifierSchemeCodeUnspsc190501.50582609': __('identifierSchemeCodeUnspsc190501.50582609'),
	'identifierSchemeCodeUnspsc190501.50582610': __('identifierSchemeCodeUnspsc190501.50582610'),
	'identifierSchemeCodeUnspsc190501.50582611': __('identifierSchemeCodeUnspsc190501.50582611'),
	'identifierSchemeCodeUnspsc190501.50582612': __('identifierSchemeCodeUnspsc190501.50582612'),
	'identifierSchemeCodeUnspsc190501.50582700': __('identifierSchemeCodeUnspsc190501.50582700'),
	'identifierSchemeCodeUnspsc190501.50582701': __('identifierSchemeCodeUnspsc190501.50582701'),
	'identifierSchemeCodeUnspsc190501.50582702': __('identifierSchemeCodeUnspsc190501.50582702'),
	'identifierSchemeCodeUnspsc190501.50582703': __('identifierSchemeCodeUnspsc190501.50582703'),
	'identifierSchemeCodeUnspsc190501.50582704': __('identifierSchemeCodeUnspsc190501.50582704'),
	'identifierSchemeCodeUnspsc190501.50582705': __('identifierSchemeCodeUnspsc190501.50582705'),
	'identifierSchemeCodeUnspsc190501.50582706': __('identifierSchemeCodeUnspsc190501.50582706'),
	'identifierSchemeCodeUnspsc190501.50582707': __('identifierSchemeCodeUnspsc190501.50582707'),
	'identifierSchemeCodeUnspsc190501.50582708': __('identifierSchemeCodeUnspsc190501.50582708'),
	'identifierSchemeCodeUnspsc190501.50582709': __('identifierSchemeCodeUnspsc190501.50582709'),
	'identifierSchemeCodeUnspsc190501.50582710': __('identifierSchemeCodeUnspsc190501.50582710'),
	'identifierSchemeCodeUnspsc190501.50582711': __('identifierSchemeCodeUnspsc190501.50582711'),
	'identifierSchemeCodeUnspsc190501.50582712': __('identifierSchemeCodeUnspsc190501.50582712'),
	'identifierSchemeCodeUnspsc190501.50582713': __('identifierSchemeCodeUnspsc190501.50582713'),
	'identifierSchemeCodeUnspsc190501.50582714': __('identifierSchemeCodeUnspsc190501.50582714'),
	'identifierSchemeCodeUnspsc190501.50582715': __('identifierSchemeCodeUnspsc190501.50582715'),
	'identifierSchemeCodeUnspsc190501.50582800': __('identifierSchemeCodeUnspsc190501.50582800'),
	'identifierSchemeCodeUnspsc190501.50582801': __('identifierSchemeCodeUnspsc190501.50582801'),
	'identifierSchemeCodeUnspsc190501.50582802': __('identifierSchemeCodeUnspsc190501.50582802'),
	'identifierSchemeCodeUnspsc190501.50582803': __('identifierSchemeCodeUnspsc190501.50582803'),
	'identifierSchemeCodeUnspsc190501.50582804': __('identifierSchemeCodeUnspsc190501.50582804'),
	'identifierSchemeCodeUnspsc190501.50582805': __('identifierSchemeCodeUnspsc190501.50582805'),
	'identifierSchemeCodeUnspsc190501.50582806': __('identifierSchemeCodeUnspsc190501.50582806'),
	'identifierSchemeCodeUnspsc190501.50582807': __('identifierSchemeCodeUnspsc190501.50582807'),
	'identifierSchemeCodeUnspsc190501.50582900': __('identifierSchemeCodeUnspsc190501.50582900'),
	'identifierSchemeCodeUnspsc190501.50582901': __('identifierSchemeCodeUnspsc190501.50582901'),
	'identifierSchemeCodeUnspsc190501.50582902': __('identifierSchemeCodeUnspsc190501.50582902'),
	'identifierSchemeCodeUnspsc190501.50582903': __('identifierSchemeCodeUnspsc190501.50582903'),
	'identifierSchemeCodeUnspsc190501.50582904': __('identifierSchemeCodeUnspsc190501.50582904'),
	'identifierSchemeCodeUnspsc190501.50582905': __('identifierSchemeCodeUnspsc190501.50582905'),
	'identifierSchemeCodeUnspsc190501.50582906': __('identifierSchemeCodeUnspsc190501.50582906'),
	'identifierSchemeCodeUnspsc190501.50582907': __('identifierSchemeCodeUnspsc190501.50582907'),
	'identifierSchemeCodeUnspsc190501.50582908': __('identifierSchemeCodeUnspsc190501.50582908'),
	'identifierSchemeCodeUnspsc190501.50582909': __('identifierSchemeCodeUnspsc190501.50582909'),
	'identifierSchemeCodeUnspsc190501.50582910': __('identifierSchemeCodeUnspsc190501.50582910'),
	'identifierSchemeCodeUnspsc190501.50582911': __('identifierSchemeCodeUnspsc190501.50582911'),
	'identifierSchemeCodeUnspsc190501.50583000': __('identifierSchemeCodeUnspsc190501.50583000'),
	'identifierSchemeCodeUnspsc190501.50583001': __('identifierSchemeCodeUnspsc190501.50583001'),
	'identifierSchemeCodeUnspsc190501.50583002': __('identifierSchemeCodeUnspsc190501.50583002'),
	'identifierSchemeCodeUnspsc190501.50583003': __('identifierSchemeCodeUnspsc190501.50583003'),
	'identifierSchemeCodeUnspsc190501.50583004': __('identifierSchemeCodeUnspsc190501.50583004'),
	'identifierSchemeCodeUnspsc190501.50583005': __('identifierSchemeCodeUnspsc190501.50583005'),
	'identifierSchemeCodeUnspsc190501.50583006': __('identifierSchemeCodeUnspsc190501.50583006'),
	'identifierSchemeCodeUnspsc190501.50583007': __('identifierSchemeCodeUnspsc190501.50583007'),
	'identifierSchemeCodeUnspsc190501.50583008': __('identifierSchemeCodeUnspsc190501.50583008'),
	'identifierSchemeCodeUnspsc190501.50583009': __('identifierSchemeCodeUnspsc190501.50583009'),
	'identifierSchemeCodeUnspsc190501.50583010': __('identifierSchemeCodeUnspsc190501.50583010'),
	'identifierSchemeCodeUnspsc190501.50583011': __('identifierSchemeCodeUnspsc190501.50583011'),
	'identifierSchemeCodeUnspsc190501.50583012': __('identifierSchemeCodeUnspsc190501.50583012'),
	'identifierSchemeCodeUnspsc190501.50583013': __('identifierSchemeCodeUnspsc190501.50583013'),
	'identifierSchemeCodeUnspsc190501.50583014': __('identifierSchemeCodeUnspsc190501.50583014'),
	'identifierSchemeCodeUnspsc190501.50583015': __('identifierSchemeCodeUnspsc190501.50583015'),
	'identifierSchemeCodeUnspsc190501.50583016': __('identifierSchemeCodeUnspsc190501.50583016'),
	'identifierSchemeCodeUnspsc190501.50583100': __('identifierSchemeCodeUnspsc190501.50583100'),
	'identifierSchemeCodeUnspsc190501.50583101': __('identifierSchemeCodeUnspsc190501.50583101'),
	'identifierSchemeCodeUnspsc190501.50583102': __('identifierSchemeCodeUnspsc190501.50583102'),
	'identifierSchemeCodeUnspsc190501.50583200': __('identifierSchemeCodeUnspsc190501.50583200'),
	'identifierSchemeCodeUnspsc190501.50583201': __('identifierSchemeCodeUnspsc190501.50583201'),
	'identifierSchemeCodeUnspsc190501.50583202': __('identifierSchemeCodeUnspsc190501.50583202'),
	'identifierSchemeCodeUnspsc190501.50583203': __('identifierSchemeCodeUnspsc190501.50583203'),
	'identifierSchemeCodeUnspsc190501.50583204': __('identifierSchemeCodeUnspsc190501.50583204'),
	'identifierSchemeCodeUnspsc190501.50583300': __('identifierSchemeCodeUnspsc190501.50583300'),
	'identifierSchemeCodeUnspsc190501.50583301': __('identifierSchemeCodeUnspsc190501.50583301'),
	'identifierSchemeCodeUnspsc190501.50583302': __('identifierSchemeCodeUnspsc190501.50583302'),
	'identifierSchemeCodeUnspsc190501.50583303': __('identifierSchemeCodeUnspsc190501.50583303'),
	'identifierSchemeCodeUnspsc190501.50583304': __('identifierSchemeCodeUnspsc190501.50583304'),
	'identifierSchemeCodeUnspsc190501.50583305': __('identifierSchemeCodeUnspsc190501.50583305'),
	'identifierSchemeCodeUnspsc190501.50583306': __('identifierSchemeCodeUnspsc190501.50583306'),
	'identifierSchemeCodeUnspsc190501.50583307': __('identifierSchemeCodeUnspsc190501.50583307'),
	'identifierSchemeCodeUnspsc190501.50583308': __('identifierSchemeCodeUnspsc190501.50583308'),
	'identifierSchemeCodeUnspsc190501.50583309': __('identifierSchemeCodeUnspsc190501.50583309'),
	'identifierSchemeCodeUnspsc190501.50583310': __('identifierSchemeCodeUnspsc190501.50583310'),
	'identifierSchemeCodeUnspsc190501.50583311': __('identifierSchemeCodeUnspsc190501.50583311'),
	'identifierSchemeCodeUnspsc190501.50583312': __('identifierSchemeCodeUnspsc190501.50583312'),
	'identifierSchemeCodeUnspsc190501.50583313': __('identifierSchemeCodeUnspsc190501.50583313'),
	'identifierSchemeCodeUnspsc190501.50583314': __('identifierSchemeCodeUnspsc190501.50583314'),
	'identifierSchemeCodeUnspsc190501.50583315': __('identifierSchemeCodeUnspsc190501.50583315'),
	'identifierSchemeCodeUnspsc190501.50583316': __('identifierSchemeCodeUnspsc190501.50583316'),
	'identifierSchemeCodeUnspsc190501.50583317': __('identifierSchemeCodeUnspsc190501.50583317'),
	'identifierSchemeCodeUnspsc190501.50583318': __('identifierSchemeCodeUnspsc190501.50583318'),
	'identifierSchemeCodeUnspsc190501.50583319': __('identifierSchemeCodeUnspsc190501.50583319'),
	'identifierSchemeCodeUnspsc190501.50583320': __('identifierSchemeCodeUnspsc190501.50583320'),
	'identifierSchemeCodeUnspsc190501.50583321': __('identifierSchemeCodeUnspsc190501.50583321'),
	'identifierSchemeCodeUnspsc190501.50583322': __('identifierSchemeCodeUnspsc190501.50583322'),
	'identifierSchemeCodeUnspsc190501.50583323': __('identifierSchemeCodeUnspsc190501.50583323'),
	'identifierSchemeCodeUnspsc190501.50583400': __('identifierSchemeCodeUnspsc190501.50583400'),
	'identifierSchemeCodeUnspsc190501.50583401': __('identifierSchemeCodeUnspsc190501.50583401'),
	'identifierSchemeCodeUnspsc190501.50583402': __('identifierSchemeCodeUnspsc190501.50583402'),
	'identifierSchemeCodeUnspsc190501.50583403': __('identifierSchemeCodeUnspsc190501.50583403'),
	'identifierSchemeCodeUnspsc190501.50583404': __('identifierSchemeCodeUnspsc190501.50583404'),
	'identifierSchemeCodeUnspsc190501.50583405': __('identifierSchemeCodeUnspsc190501.50583405'),
	'identifierSchemeCodeUnspsc190501.50583406': __('identifierSchemeCodeUnspsc190501.50583406'),
	'identifierSchemeCodeUnspsc190501.50583407': __('identifierSchemeCodeUnspsc190501.50583407'),
	'identifierSchemeCodeUnspsc190501.50583408': __('identifierSchemeCodeUnspsc190501.50583408'),
	'identifierSchemeCodeUnspsc190501.50583409': __('identifierSchemeCodeUnspsc190501.50583409'),
	'identifierSchemeCodeUnspsc190501.50583410': __('identifierSchemeCodeUnspsc190501.50583410'),
	'identifierSchemeCodeUnspsc190501.50583411': __('identifierSchemeCodeUnspsc190501.50583411'),
	'identifierSchemeCodeUnspsc190501.50583412': __('identifierSchemeCodeUnspsc190501.50583412'),
	'identifierSchemeCodeUnspsc190501.50583413': __('identifierSchemeCodeUnspsc190501.50583413'),
	'identifierSchemeCodeUnspsc190501.50583414': __('identifierSchemeCodeUnspsc190501.50583414'),
	'identifierSchemeCodeUnspsc190501.50583415': __('identifierSchemeCodeUnspsc190501.50583415'),
	'identifierSchemeCodeUnspsc190501.50583416': __('identifierSchemeCodeUnspsc190501.50583416'),
	'identifierSchemeCodeUnspsc190501.50583417': __('identifierSchemeCodeUnspsc190501.50583417'),
	'identifierSchemeCodeUnspsc190501.50583418': __('identifierSchemeCodeUnspsc190501.50583418'),
	'identifierSchemeCodeUnspsc190501.50583419': __('identifierSchemeCodeUnspsc190501.50583419'),
	'identifierSchemeCodeUnspsc190501.50583420': __('identifierSchemeCodeUnspsc190501.50583420'),
	'identifierSchemeCodeUnspsc190501.50583500': __('identifierSchemeCodeUnspsc190501.50583500'),
	'identifierSchemeCodeUnspsc190501.50583501': __('identifierSchemeCodeUnspsc190501.50583501'),
	'identifierSchemeCodeUnspsc190501.50583502': __('identifierSchemeCodeUnspsc190501.50583502'),
	'identifierSchemeCodeUnspsc190501.50583503': __('identifierSchemeCodeUnspsc190501.50583503'),
	'identifierSchemeCodeUnspsc190501.50583504': __('identifierSchemeCodeUnspsc190501.50583504'),
	'identifierSchemeCodeUnspsc190501.50583505': __('identifierSchemeCodeUnspsc190501.50583505'),
	'identifierSchemeCodeUnspsc190501.50583506': __('identifierSchemeCodeUnspsc190501.50583506'),
	'identifierSchemeCodeUnspsc190501.50583507': __('identifierSchemeCodeUnspsc190501.50583507'),
	'identifierSchemeCodeUnspsc190501.50583508': __('identifierSchemeCodeUnspsc190501.50583508'),
	'identifierSchemeCodeUnspsc190501.50583509': __('identifierSchemeCodeUnspsc190501.50583509'),
	'identifierSchemeCodeUnspsc190501.50583510': __('identifierSchemeCodeUnspsc190501.50583510'),
	'identifierSchemeCodeUnspsc190501.50583511': __('identifierSchemeCodeUnspsc190501.50583511'),
	'identifierSchemeCodeUnspsc190501.50583512': __('identifierSchemeCodeUnspsc190501.50583512'),
	'identifierSchemeCodeUnspsc190501.50583513': __('identifierSchemeCodeUnspsc190501.50583513'),
	'identifierSchemeCodeUnspsc190501.50583514': __('identifierSchemeCodeUnspsc190501.50583514'),
	'identifierSchemeCodeUnspsc190501.50583600': __('identifierSchemeCodeUnspsc190501.50583600'),
	'identifierSchemeCodeUnspsc190501.50583601': __('identifierSchemeCodeUnspsc190501.50583601'),
	'identifierSchemeCodeUnspsc190501.50583602': __('identifierSchemeCodeUnspsc190501.50583602'),
	'identifierSchemeCodeUnspsc190501.50583603': __('identifierSchemeCodeUnspsc190501.50583603'),
	'identifierSchemeCodeUnspsc190501.50583604': __('identifierSchemeCodeUnspsc190501.50583604'),
	'identifierSchemeCodeUnspsc190501.50583605': __('identifierSchemeCodeUnspsc190501.50583605'),
	'identifierSchemeCodeUnspsc190501.50583606': __('identifierSchemeCodeUnspsc190501.50583606'),
	'identifierSchemeCodeUnspsc190501.50583607': __('identifierSchemeCodeUnspsc190501.50583607'),
	'identifierSchemeCodeUnspsc190501.50583700': __('identifierSchemeCodeUnspsc190501.50583700'),
	'identifierSchemeCodeUnspsc190501.50583701': __('identifierSchemeCodeUnspsc190501.50583701'),
	'identifierSchemeCodeUnspsc190501.50583702': __('identifierSchemeCodeUnspsc190501.50583702'),
	'identifierSchemeCodeUnspsc190501.50583703': __('identifierSchemeCodeUnspsc190501.50583703'),
	'identifierSchemeCodeUnspsc190501.50583704': __('identifierSchemeCodeUnspsc190501.50583704'),
	'identifierSchemeCodeUnspsc190501.50583705': __('identifierSchemeCodeUnspsc190501.50583705'),
	'identifierSchemeCodeUnspsc190501.50583706': __('identifierSchemeCodeUnspsc190501.50583706'),
	'identifierSchemeCodeUnspsc190501.50583707': __('identifierSchemeCodeUnspsc190501.50583707'),
	'identifierSchemeCodeUnspsc190501.50583708': __('identifierSchemeCodeUnspsc190501.50583708'),
	'identifierSchemeCodeUnspsc190501.50583709': __('identifierSchemeCodeUnspsc190501.50583709'),
	'identifierSchemeCodeUnspsc190501.50583710': __('identifierSchemeCodeUnspsc190501.50583710'),
	'identifierSchemeCodeUnspsc190501.50583711': __('identifierSchemeCodeUnspsc190501.50583711'),
	'identifierSchemeCodeUnspsc190501.50583800': __('identifierSchemeCodeUnspsc190501.50583800'),
	'identifierSchemeCodeUnspsc190501.50583801': __('identifierSchemeCodeUnspsc190501.50583801'),
	'identifierSchemeCodeUnspsc190501.50583802': __('identifierSchemeCodeUnspsc190501.50583802'),
	'identifierSchemeCodeUnspsc190501.50583803': __('identifierSchemeCodeUnspsc190501.50583803'),
	'identifierSchemeCodeUnspsc190501.50583804': __('identifierSchemeCodeUnspsc190501.50583804'),
	'identifierSchemeCodeUnspsc190501.50583805': __('identifierSchemeCodeUnspsc190501.50583805'),
	'identifierSchemeCodeUnspsc190501.50583806': __('identifierSchemeCodeUnspsc190501.50583806'),
	'identifierSchemeCodeUnspsc190501.50583807': __('identifierSchemeCodeUnspsc190501.50583807'),
	'identifierSchemeCodeUnspsc190501.50583808': __('identifierSchemeCodeUnspsc190501.50583808'),
	'identifierSchemeCodeUnspsc190501.50583809': __('identifierSchemeCodeUnspsc190501.50583809'),
	'identifierSchemeCodeUnspsc190501.50583810': __('identifierSchemeCodeUnspsc190501.50583810'),
	'identifierSchemeCodeUnspsc190501.50583811': __('identifierSchemeCodeUnspsc190501.50583811'),
	'identifierSchemeCodeUnspsc190501.50583900': __('identifierSchemeCodeUnspsc190501.50583900'),
	'identifierSchemeCodeUnspsc190501.50583901': __('identifierSchemeCodeUnspsc190501.50583901'),
	'identifierSchemeCodeUnspsc190501.50583902': __('identifierSchemeCodeUnspsc190501.50583902'),
	'identifierSchemeCodeUnspsc190501.50583903': __('identifierSchemeCodeUnspsc190501.50583903'),
	'identifierSchemeCodeUnspsc190501.50583904': __('identifierSchemeCodeUnspsc190501.50583904'),
	'identifierSchemeCodeUnspsc190501.50583905': __('identifierSchemeCodeUnspsc190501.50583905'),
	'identifierSchemeCodeUnspsc190501.50584000': __('identifierSchemeCodeUnspsc190501.50584000'),
	'identifierSchemeCodeUnspsc190501.50584001': __('identifierSchemeCodeUnspsc190501.50584001'),
	'identifierSchemeCodeUnspsc190501.50584002': __('identifierSchemeCodeUnspsc190501.50584002'),
	'identifierSchemeCodeUnspsc190501.50584003': __('identifierSchemeCodeUnspsc190501.50584003'),
	'identifierSchemeCodeUnspsc190501.50584004': __('identifierSchemeCodeUnspsc190501.50584004'),
	'identifierSchemeCodeUnspsc190501.50584005': __('identifierSchemeCodeUnspsc190501.50584005'),
	'identifierSchemeCodeUnspsc190501.50584006': __('identifierSchemeCodeUnspsc190501.50584006'),
	'identifierSchemeCodeUnspsc190501.50584007': __('identifierSchemeCodeUnspsc190501.50584007'),
	'identifierSchemeCodeUnspsc190501.50584008': __('identifierSchemeCodeUnspsc190501.50584008'),
	'identifierSchemeCodeUnspsc190501.50584009': __('identifierSchemeCodeUnspsc190501.50584009'),
	'identifierSchemeCodeUnspsc190501.50584010': __('identifierSchemeCodeUnspsc190501.50584010'),
	'identifierSchemeCodeUnspsc190501.50584011': __('identifierSchemeCodeUnspsc190501.50584011'),
	'identifierSchemeCodeUnspsc190501.50584012': __('identifierSchemeCodeUnspsc190501.50584012'),
	'identifierSchemeCodeUnspsc190501.50584013': __('identifierSchemeCodeUnspsc190501.50584013'),
	'identifierSchemeCodeUnspsc190501.50584014': __('identifierSchemeCodeUnspsc190501.50584014'),
	'identifierSchemeCodeUnspsc190501.50584015': __('identifierSchemeCodeUnspsc190501.50584015'),
	'identifierSchemeCodeUnspsc190501.50584016': __('identifierSchemeCodeUnspsc190501.50584016'),
	'identifierSchemeCodeUnspsc190501.50584017': __('identifierSchemeCodeUnspsc190501.50584017'),
	'identifierSchemeCodeUnspsc190501.50584018': __('identifierSchemeCodeUnspsc190501.50584018'),
	'identifierSchemeCodeUnspsc190501.50584019': __('identifierSchemeCodeUnspsc190501.50584019'),
	'identifierSchemeCodeUnspsc190501.50584020': __('identifierSchemeCodeUnspsc190501.50584020'),
	'identifierSchemeCodeUnspsc190501.50584021': __('identifierSchemeCodeUnspsc190501.50584021'),
	'identifierSchemeCodeUnspsc190501.50584022': __('identifierSchemeCodeUnspsc190501.50584022'),
	'identifierSchemeCodeUnspsc190501.50584023': __('identifierSchemeCodeUnspsc190501.50584023'),
	'identifierSchemeCodeUnspsc190501.50584024': __('identifierSchemeCodeUnspsc190501.50584024'),
	'identifierSchemeCodeUnspsc190501.50584025': __('identifierSchemeCodeUnspsc190501.50584025'),
	'identifierSchemeCodeUnspsc190501.50584026': __('identifierSchemeCodeUnspsc190501.50584026'),
	'identifierSchemeCodeUnspsc190501.50584027': __('identifierSchemeCodeUnspsc190501.50584027'),
	'identifierSchemeCodeUnspsc190501.50584100': __('identifierSchemeCodeUnspsc190501.50584100'),
	'identifierSchemeCodeUnspsc190501.50584101': __('identifierSchemeCodeUnspsc190501.50584101'),
	'identifierSchemeCodeUnspsc190501.50584102': __('identifierSchemeCodeUnspsc190501.50584102'),
	'identifierSchemeCodeUnspsc190501.50584200': __('identifierSchemeCodeUnspsc190501.50584200'),
	'identifierSchemeCodeUnspsc190501.50584201': __('identifierSchemeCodeUnspsc190501.50584201'),
	'identifierSchemeCodeUnspsc190501.50584202': __('identifierSchemeCodeUnspsc190501.50584202'),
	'identifierSchemeCodeUnspsc190501.50584203': __('identifierSchemeCodeUnspsc190501.50584203'),
	'identifierSchemeCodeUnspsc190501.50584204': __('identifierSchemeCodeUnspsc190501.50584204'),
	'identifierSchemeCodeUnspsc190501.50584205': __('identifierSchemeCodeUnspsc190501.50584205'),
	'identifierSchemeCodeUnspsc190501.50584206': __('identifierSchemeCodeUnspsc190501.50584206'),
	'identifierSchemeCodeUnspsc190501.50584300': __('identifierSchemeCodeUnspsc190501.50584300'),
	'identifierSchemeCodeUnspsc190501.50584301': __('identifierSchemeCodeUnspsc190501.50584301'),
	'identifierSchemeCodeUnspsc190501.50584302': __('identifierSchemeCodeUnspsc190501.50584302'),
	'identifierSchemeCodeUnspsc190501.50584303': __('identifierSchemeCodeUnspsc190501.50584303'),
	'identifierSchemeCodeUnspsc190501.50584304': __('identifierSchemeCodeUnspsc190501.50584304'),
	'identifierSchemeCodeUnspsc190501.50584305': __('identifierSchemeCodeUnspsc190501.50584305'),
	'identifierSchemeCodeUnspsc190501.50584306': __('identifierSchemeCodeUnspsc190501.50584306'),
	'identifierSchemeCodeUnspsc190501.50584400': __('identifierSchemeCodeUnspsc190501.50584400'),
	'identifierSchemeCodeUnspsc190501.50584401': __('identifierSchemeCodeUnspsc190501.50584401'),
	'identifierSchemeCodeUnspsc190501.50584402': __('identifierSchemeCodeUnspsc190501.50584402'),
	'identifierSchemeCodeUnspsc190501.50584403': __('identifierSchemeCodeUnspsc190501.50584403'),
	'identifierSchemeCodeUnspsc190501.50584404': __('identifierSchemeCodeUnspsc190501.50584404'),
	'identifierSchemeCodeUnspsc190501.50584405': __('identifierSchemeCodeUnspsc190501.50584405'),
	'identifierSchemeCodeUnspsc190501.50584406': __('identifierSchemeCodeUnspsc190501.50584406'),
	'identifierSchemeCodeUnspsc190501.50584407': __('identifierSchemeCodeUnspsc190501.50584407'),
	'identifierSchemeCodeUnspsc190501.50584408': __('identifierSchemeCodeUnspsc190501.50584408'),
	'identifierSchemeCodeUnspsc190501.50584500': __('identifierSchemeCodeUnspsc190501.50584500'),
	'identifierSchemeCodeUnspsc190501.50584501': __('identifierSchemeCodeUnspsc190501.50584501'),
	'identifierSchemeCodeUnspsc190501.50584502': __('identifierSchemeCodeUnspsc190501.50584502'),
	'identifierSchemeCodeUnspsc190501.50584503': __('identifierSchemeCodeUnspsc190501.50584503'),
	'identifierSchemeCodeUnspsc190501.50584504': __('identifierSchemeCodeUnspsc190501.50584504'),
	'identifierSchemeCodeUnspsc190501.50584505': __('identifierSchemeCodeUnspsc190501.50584505'),
	'identifierSchemeCodeUnspsc190501.50584506': __('identifierSchemeCodeUnspsc190501.50584506'),
	'identifierSchemeCodeUnspsc190501.50584507': __('identifierSchemeCodeUnspsc190501.50584507'),
	'identifierSchemeCodeUnspsc190501.50584508': __('identifierSchemeCodeUnspsc190501.50584508'),
	'identifierSchemeCodeUnspsc190501.50584509': __('identifierSchemeCodeUnspsc190501.50584509'),
	'identifierSchemeCodeUnspsc190501.50584510': __('identifierSchemeCodeUnspsc190501.50584510'),
	'identifierSchemeCodeUnspsc190501.50584511': __('identifierSchemeCodeUnspsc190501.50584511'),
	'identifierSchemeCodeUnspsc190501.50584512': __('identifierSchemeCodeUnspsc190501.50584512'),
	'identifierSchemeCodeUnspsc190501.50584513': __('identifierSchemeCodeUnspsc190501.50584513'),
	'identifierSchemeCodeUnspsc190501.50584514': __('identifierSchemeCodeUnspsc190501.50584514'),
	'identifierSchemeCodeUnspsc190501.50584515': __('identifierSchemeCodeUnspsc190501.50584515'),
	'identifierSchemeCodeUnspsc190501.50584516': __('identifierSchemeCodeUnspsc190501.50584516'),
	'identifierSchemeCodeUnspsc190501.50584517': __('identifierSchemeCodeUnspsc190501.50584517'),
	'identifierSchemeCodeUnspsc190501.50584518': __('identifierSchemeCodeUnspsc190501.50584518'),
	'identifierSchemeCodeUnspsc190501.50584519': __('identifierSchemeCodeUnspsc190501.50584519'),
	'identifierSchemeCodeUnspsc190501.50584520': __('identifierSchemeCodeUnspsc190501.50584520'),
	'identifierSchemeCodeUnspsc190501.50584521': __('identifierSchemeCodeUnspsc190501.50584521'),
	'identifierSchemeCodeUnspsc190501.50584522': __('identifierSchemeCodeUnspsc190501.50584522'),
	'identifierSchemeCodeUnspsc190501.50584523': __('identifierSchemeCodeUnspsc190501.50584523'),
	'identifierSchemeCodeUnspsc190501.50584600': __('identifierSchemeCodeUnspsc190501.50584600'),
	'identifierSchemeCodeUnspsc190501.50584601': __('identifierSchemeCodeUnspsc190501.50584601'),
	'identifierSchemeCodeUnspsc190501.50584602': __('identifierSchemeCodeUnspsc190501.50584602'),
	'identifierSchemeCodeUnspsc190501.50584603': __('identifierSchemeCodeUnspsc190501.50584603'),
	'identifierSchemeCodeUnspsc190501.50584604': __('identifierSchemeCodeUnspsc190501.50584604'),
	'identifierSchemeCodeUnspsc190501.50584605': __('identifierSchemeCodeUnspsc190501.50584605'),
	'identifierSchemeCodeUnspsc190501.50584606': __('identifierSchemeCodeUnspsc190501.50584606'),
	'identifierSchemeCodeUnspsc190501.50584700': __('identifierSchemeCodeUnspsc190501.50584700'),
	'identifierSchemeCodeUnspsc190501.50584701': __('identifierSchemeCodeUnspsc190501.50584701'),
	'identifierSchemeCodeUnspsc190501.50584702': __('identifierSchemeCodeUnspsc190501.50584702'),
	'identifierSchemeCodeUnspsc190501.50584703': __('identifierSchemeCodeUnspsc190501.50584703'),
	'identifierSchemeCodeUnspsc190501.50584704': __('identifierSchemeCodeUnspsc190501.50584704'),
	'identifierSchemeCodeUnspsc190501.50584705': __('identifierSchemeCodeUnspsc190501.50584705'),
	'identifierSchemeCodeUnspsc190501.50584706': __('identifierSchemeCodeUnspsc190501.50584706'),
	'identifierSchemeCodeUnspsc190501.50584707': __('identifierSchemeCodeUnspsc190501.50584707'),
	'identifierSchemeCodeUnspsc190501.50584708': __('identifierSchemeCodeUnspsc190501.50584708'),
	'identifierSchemeCodeUnspsc190501.50584709': __('identifierSchemeCodeUnspsc190501.50584709'),
	'identifierSchemeCodeUnspsc190501.50584710': __('identifierSchemeCodeUnspsc190501.50584710'),
	'identifierSchemeCodeUnspsc190501.50584711': __('identifierSchemeCodeUnspsc190501.50584711'),
	'identifierSchemeCodeUnspsc190501.50584712': __('identifierSchemeCodeUnspsc190501.50584712'),
	'identifierSchemeCodeUnspsc190501.50584713': __('identifierSchemeCodeUnspsc190501.50584713'),
	'identifierSchemeCodeUnspsc190501.50584714': __('identifierSchemeCodeUnspsc190501.50584714'),
	'identifierSchemeCodeUnspsc190501.50584715': __('identifierSchemeCodeUnspsc190501.50584715'),
	'identifierSchemeCodeUnspsc190501.50584716': __('identifierSchemeCodeUnspsc190501.50584716'),
	'identifierSchemeCodeUnspsc190501.50584717': __('identifierSchemeCodeUnspsc190501.50584717'),
	'identifierSchemeCodeUnspsc190501.50584718': __('identifierSchemeCodeUnspsc190501.50584718'),
	'identifierSchemeCodeUnspsc190501.50584719': __('identifierSchemeCodeUnspsc190501.50584719'),
	'identifierSchemeCodeUnspsc190501.50584720': __('identifierSchemeCodeUnspsc190501.50584720'),
	'identifierSchemeCodeUnspsc190501.50584721': __('identifierSchemeCodeUnspsc190501.50584721'),
	'identifierSchemeCodeUnspsc190501.50584800': __('identifierSchemeCodeUnspsc190501.50584800'),
	'identifierSchemeCodeUnspsc190501.50584801': __('identifierSchemeCodeUnspsc190501.50584801'),
	'identifierSchemeCodeUnspsc190501.50584802': __('identifierSchemeCodeUnspsc190501.50584802'),
	'identifierSchemeCodeUnspsc190501.50584803': __('identifierSchemeCodeUnspsc190501.50584803'),
	'identifierSchemeCodeUnspsc190501.50584804': __('identifierSchemeCodeUnspsc190501.50584804'),
	'identifierSchemeCodeUnspsc190501.50584805': __('identifierSchemeCodeUnspsc190501.50584805'),
	'identifierSchemeCodeUnspsc190501.50584806': __('identifierSchemeCodeUnspsc190501.50584806'),
	'identifierSchemeCodeUnspsc190501.50584900': __('identifierSchemeCodeUnspsc190501.50584900'),
	'identifierSchemeCodeUnspsc190501.50584901': __('identifierSchemeCodeUnspsc190501.50584901'),
	'identifierSchemeCodeUnspsc190501.50584902': __('identifierSchemeCodeUnspsc190501.50584902'),
	'identifierSchemeCodeUnspsc190501.50584903': __('identifierSchemeCodeUnspsc190501.50584903'),
	'identifierSchemeCodeUnspsc190501.50584904': __('identifierSchemeCodeUnspsc190501.50584904'),
	'identifierSchemeCodeUnspsc190501.50585000': __('identifierSchemeCodeUnspsc190501.50585000'),
	'identifierSchemeCodeUnspsc190501.50585001': __('identifierSchemeCodeUnspsc190501.50585001'),
	'identifierSchemeCodeUnspsc190501.50585002': __('identifierSchemeCodeUnspsc190501.50585002'),
	'identifierSchemeCodeUnspsc190501.50585003': __('identifierSchemeCodeUnspsc190501.50585003'),
	'identifierSchemeCodeUnspsc190501.50585004': __('identifierSchemeCodeUnspsc190501.50585004'),
	'identifierSchemeCodeUnspsc190501.50585005': __('identifierSchemeCodeUnspsc190501.50585005'),
	'identifierSchemeCodeUnspsc190501.50585006': __('identifierSchemeCodeUnspsc190501.50585006'),
	'identifierSchemeCodeUnspsc190501.50585007': __('identifierSchemeCodeUnspsc190501.50585007'),
	'identifierSchemeCodeUnspsc190501.50585100': __('identifierSchemeCodeUnspsc190501.50585100'),
	'identifierSchemeCodeUnspsc190501.50585101': __('identifierSchemeCodeUnspsc190501.50585101'),
	'identifierSchemeCodeUnspsc190501.50585102': __('identifierSchemeCodeUnspsc190501.50585102'),
	'identifierSchemeCodeUnspsc190501.50585103': __('identifierSchemeCodeUnspsc190501.50585103'),
	'identifierSchemeCodeUnspsc190501.50585104': __('identifierSchemeCodeUnspsc190501.50585104'),
	'identifierSchemeCodeUnspsc190501.50585105': __('identifierSchemeCodeUnspsc190501.50585105'),
	'identifierSchemeCodeUnspsc190501.50585106': __('identifierSchemeCodeUnspsc190501.50585106'),
	'identifierSchemeCodeUnspsc190501.50585107': __('identifierSchemeCodeUnspsc190501.50585107'),
	'identifierSchemeCodeUnspsc190501.50585108': __('identifierSchemeCodeUnspsc190501.50585108'),
	'identifierSchemeCodeUnspsc190501.50585109': __('identifierSchemeCodeUnspsc190501.50585109'),
	'identifierSchemeCodeUnspsc190501.50585110': __('identifierSchemeCodeUnspsc190501.50585110'),
	'identifierSchemeCodeUnspsc190501.50585111': __('identifierSchemeCodeUnspsc190501.50585111'),
	'identifierSchemeCodeUnspsc190501.50585112': __('identifierSchemeCodeUnspsc190501.50585112'),
	'identifierSchemeCodeUnspsc190501.50585113': __('identifierSchemeCodeUnspsc190501.50585113'),
	'identifierSchemeCodeUnspsc190501.50585114': __('identifierSchemeCodeUnspsc190501.50585114'),
	'identifierSchemeCodeUnspsc190501.50585115': __('identifierSchemeCodeUnspsc190501.50585115'),
	'identifierSchemeCodeUnspsc190501.50585116': __('identifierSchemeCodeUnspsc190501.50585116'),
	'identifierSchemeCodeUnspsc190501.50585117': __('identifierSchemeCodeUnspsc190501.50585117'),
	'identifierSchemeCodeUnspsc190501.50585118': __('identifierSchemeCodeUnspsc190501.50585118'),
	'identifierSchemeCodeUnspsc190501.50585119': __('identifierSchemeCodeUnspsc190501.50585119'),
	'identifierSchemeCodeUnspsc190501.50585120': __('identifierSchemeCodeUnspsc190501.50585120'),
	'identifierSchemeCodeUnspsc190501.50585121': __('identifierSchemeCodeUnspsc190501.50585121'),
	'identifierSchemeCodeUnspsc190501.50585200': __('identifierSchemeCodeUnspsc190501.50585200'),
	'identifierSchemeCodeUnspsc190501.50585201': __('identifierSchemeCodeUnspsc190501.50585201'),
	'identifierSchemeCodeUnspsc190501.50585202': __('identifierSchemeCodeUnspsc190501.50585202'),
	'identifierSchemeCodeUnspsc190501.50585203': __('identifierSchemeCodeUnspsc190501.50585203'),
	'identifierSchemeCodeUnspsc190501.50585204': __('identifierSchemeCodeUnspsc190501.50585204'),
	'identifierSchemeCodeUnspsc190501.50585205': __('identifierSchemeCodeUnspsc190501.50585205'),
	'identifierSchemeCodeUnspsc190501.50585206': __('identifierSchemeCodeUnspsc190501.50585206'),
	'identifierSchemeCodeUnspsc190501.50585300': __('identifierSchemeCodeUnspsc190501.50585300'),
	'identifierSchemeCodeUnspsc190501.50585301': __('identifierSchemeCodeUnspsc190501.50585301'),
	'identifierSchemeCodeUnspsc190501.50585302': __('identifierSchemeCodeUnspsc190501.50585302'),
	'identifierSchemeCodeUnspsc190501.50585303': __('identifierSchemeCodeUnspsc190501.50585303'),
	'identifierSchemeCodeUnspsc190501.50585304': __('identifierSchemeCodeUnspsc190501.50585304'),
	'identifierSchemeCodeUnspsc190501.50585305': __('identifierSchemeCodeUnspsc190501.50585305'),
	'identifierSchemeCodeUnspsc190501.50585306': __('identifierSchemeCodeUnspsc190501.50585306'),
	'identifierSchemeCodeUnspsc190501.50585400': __('identifierSchemeCodeUnspsc190501.50585400'),
	'identifierSchemeCodeUnspsc190501.50585401': __('identifierSchemeCodeUnspsc190501.50585401'),
	'identifierSchemeCodeUnspsc190501.50585402': __('identifierSchemeCodeUnspsc190501.50585402'),
	'identifierSchemeCodeUnspsc190501.50585403': __('identifierSchemeCodeUnspsc190501.50585403'),
	'identifierSchemeCodeUnspsc190501.50585404': __('identifierSchemeCodeUnspsc190501.50585404'),
	'identifierSchemeCodeUnspsc190501.50585405': __('identifierSchemeCodeUnspsc190501.50585405'),
	'identifierSchemeCodeUnspsc190501.50585406': __('identifierSchemeCodeUnspsc190501.50585406'),
	'identifierSchemeCodeUnspsc190501.50585407': __('identifierSchemeCodeUnspsc190501.50585407'),
	'identifierSchemeCodeUnspsc190501.50585408': __('identifierSchemeCodeUnspsc190501.50585408'),
	'identifierSchemeCodeUnspsc190501.50585409': __('identifierSchemeCodeUnspsc190501.50585409'),
	'identifierSchemeCodeUnspsc190501.50585410': __('identifierSchemeCodeUnspsc190501.50585410'),
	'identifierSchemeCodeUnspsc190501.50585411': __('identifierSchemeCodeUnspsc190501.50585411'),
	'identifierSchemeCodeUnspsc190501.50585412': __('identifierSchemeCodeUnspsc190501.50585412'),
	'identifierSchemeCodeUnspsc190501.50585413': __('identifierSchemeCodeUnspsc190501.50585413'),
	'identifierSchemeCodeUnspsc190501.50585414': __('identifierSchemeCodeUnspsc190501.50585414'),
	'identifierSchemeCodeUnspsc190501.50585415': __('identifierSchemeCodeUnspsc190501.50585415'),
	'identifierSchemeCodeUnspsc190501.50585416': __('identifierSchemeCodeUnspsc190501.50585416'),
	'identifierSchemeCodeUnspsc190501.50585417': __('identifierSchemeCodeUnspsc190501.50585417'),
	'identifierSchemeCodeUnspsc190501.50585418': __('identifierSchemeCodeUnspsc190501.50585418'),
	'identifierSchemeCodeUnspsc190501.50585419': __('identifierSchemeCodeUnspsc190501.50585419'),
	'identifierSchemeCodeUnspsc190501.50585420': __('identifierSchemeCodeUnspsc190501.50585420'),
	'identifierSchemeCodeUnspsc190501.50585421': __('identifierSchemeCodeUnspsc190501.50585421'),
	'identifierSchemeCodeUnspsc190501.50585422': __('identifierSchemeCodeUnspsc190501.50585422'),
	'identifierSchemeCodeUnspsc190501.50585423': __('identifierSchemeCodeUnspsc190501.50585423'),
	'identifierSchemeCodeUnspsc190501.50585424': __('identifierSchemeCodeUnspsc190501.50585424'),
	'identifierSchemeCodeUnspsc190501.50585425': __('identifierSchemeCodeUnspsc190501.50585425'),
	'identifierSchemeCodeUnspsc190501.50585426': __('identifierSchemeCodeUnspsc190501.50585426'),
	'identifierSchemeCodeUnspsc190501.50585427': __('identifierSchemeCodeUnspsc190501.50585427'),
	'identifierSchemeCodeUnspsc190501.50585428': __('identifierSchemeCodeUnspsc190501.50585428'),
	'identifierSchemeCodeUnspsc190501.50585429': __('identifierSchemeCodeUnspsc190501.50585429'),
	'identifierSchemeCodeUnspsc190501.50585430': __('identifierSchemeCodeUnspsc190501.50585430'),
	'identifierSchemeCodeUnspsc190501.50585431': __('identifierSchemeCodeUnspsc190501.50585431'),
	'identifierSchemeCodeUnspsc190501.50585432': __('identifierSchemeCodeUnspsc190501.50585432'),
	'identifierSchemeCodeUnspsc190501.50585433': __('identifierSchemeCodeUnspsc190501.50585433'),
	'identifierSchemeCodeUnspsc190501.50585434': __('identifierSchemeCodeUnspsc190501.50585434'),
	'identifierSchemeCodeUnspsc190501.50585435': __('identifierSchemeCodeUnspsc190501.50585435'),
	'identifierSchemeCodeUnspsc190501.50585436': __('identifierSchemeCodeUnspsc190501.50585436'),
	'identifierSchemeCodeUnspsc190501.50585437': __('identifierSchemeCodeUnspsc190501.50585437'),
	'identifierSchemeCodeUnspsc190501.50585500': __('identifierSchemeCodeUnspsc190501.50585500'),
	'identifierSchemeCodeUnspsc190501.50585501': __('identifierSchemeCodeUnspsc190501.50585501'),
	'identifierSchemeCodeUnspsc190501.50585502': __('identifierSchemeCodeUnspsc190501.50585502'),
	'identifierSchemeCodeUnspsc190501.50585503': __('identifierSchemeCodeUnspsc190501.50585503'),
	'identifierSchemeCodeUnspsc190501.50585504': __('identifierSchemeCodeUnspsc190501.50585504'),
	'identifierSchemeCodeUnspsc190501.50585505': __('identifierSchemeCodeUnspsc190501.50585505'),
	'identifierSchemeCodeUnspsc190501.50585506': __('identifierSchemeCodeUnspsc190501.50585506'),
	'identifierSchemeCodeUnspsc190501.50585507': __('identifierSchemeCodeUnspsc190501.50585507'),
	'identifierSchemeCodeUnspsc190501.50585508': __('identifierSchemeCodeUnspsc190501.50585508'),
	'identifierSchemeCodeUnspsc190501.50585600': __('identifierSchemeCodeUnspsc190501.50585600'),
	'identifierSchemeCodeUnspsc190501.50585601': __('identifierSchemeCodeUnspsc190501.50585601'),
	'identifierSchemeCodeUnspsc190501.50585602': __('identifierSchemeCodeUnspsc190501.50585602'),
	'identifierSchemeCodeUnspsc190501.50585603': __('identifierSchemeCodeUnspsc190501.50585603'),
	'identifierSchemeCodeUnspsc190501.50585604': __('identifierSchemeCodeUnspsc190501.50585604'),
	'identifierSchemeCodeUnspsc190501.50585700': __('identifierSchemeCodeUnspsc190501.50585700'),
	'identifierSchemeCodeUnspsc190501.50585701': __('identifierSchemeCodeUnspsc190501.50585701'),
	'identifierSchemeCodeUnspsc190501.50585702': __('identifierSchemeCodeUnspsc190501.50585702'),
	'identifierSchemeCodeUnspsc190501.50585703': __('identifierSchemeCodeUnspsc190501.50585703'),
	'identifierSchemeCodeUnspsc190501.50585704': __('identifierSchemeCodeUnspsc190501.50585704'),
	'identifierSchemeCodeUnspsc190501.50585705': __('identifierSchemeCodeUnspsc190501.50585705'),
	'identifierSchemeCodeUnspsc190501.50585706': __('identifierSchemeCodeUnspsc190501.50585706'),
	'identifierSchemeCodeUnspsc190501.50585707': __('identifierSchemeCodeUnspsc190501.50585707'),
	'identifierSchemeCodeUnspsc190501.50585708': __('identifierSchemeCodeUnspsc190501.50585708'),
	'identifierSchemeCodeUnspsc190501.50585709': __('identifierSchemeCodeUnspsc190501.50585709'),
	'identifierSchemeCodeUnspsc190501.50585710': __('identifierSchemeCodeUnspsc190501.50585710'),
	'identifierSchemeCodeUnspsc190501.50585711': __('identifierSchemeCodeUnspsc190501.50585711'),
	'identifierSchemeCodeUnspsc190501.50585712': __('identifierSchemeCodeUnspsc190501.50585712'),
	'identifierSchemeCodeUnspsc190501.50585713': __('identifierSchemeCodeUnspsc190501.50585713'),
	'identifierSchemeCodeUnspsc190501.50585714': __('identifierSchemeCodeUnspsc190501.50585714'),
	'identifierSchemeCodeUnspsc190501.50585715': __('identifierSchemeCodeUnspsc190501.50585715'),
	'identifierSchemeCodeUnspsc190501.50585716': __('identifierSchemeCodeUnspsc190501.50585716'),
	'identifierSchemeCodeUnspsc190501.50585800': __('identifierSchemeCodeUnspsc190501.50585800'),
	'identifierSchemeCodeUnspsc190501.50585801': __('identifierSchemeCodeUnspsc190501.50585801'),
	'identifierSchemeCodeUnspsc190501.50585802': __('identifierSchemeCodeUnspsc190501.50585802'),
	'identifierSchemeCodeUnspsc190501.50585803': __('identifierSchemeCodeUnspsc190501.50585803'),
	'identifierSchemeCodeUnspsc190501.50585804': __('identifierSchemeCodeUnspsc190501.50585804'),
	'identifierSchemeCodeUnspsc190501.50585805': __('identifierSchemeCodeUnspsc190501.50585805'),
	'identifierSchemeCodeUnspsc190501.50585806': __('identifierSchemeCodeUnspsc190501.50585806'),
	'identifierSchemeCodeUnspsc190501.50585807': __('identifierSchemeCodeUnspsc190501.50585807'),
	'identifierSchemeCodeUnspsc190501.50585808': __('identifierSchemeCodeUnspsc190501.50585808'),
	'identifierSchemeCodeUnspsc190501.50585809': __('identifierSchemeCodeUnspsc190501.50585809'),
	'identifierSchemeCodeUnspsc190501.50585810': __('identifierSchemeCodeUnspsc190501.50585810'),
	'identifierSchemeCodeUnspsc190501.50585811': __('identifierSchemeCodeUnspsc190501.50585811'),
	'identifierSchemeCodeUnspsc190501.50585900': __('identifierSchemeCodeUnspsc190501.50585900'),
	'identifierSchemeCodeUnspsc190501.50585901': __('identifierSchemeCodeUnspsc190501.50585901'),
	'identifierSchemeCodeUnspsc190501.50585902': __('identifierSchemeCodeUnspsc190501.50585902'),
	'identifierSchemeCodeUnspsc190501.50585903': __('identifierSchemeCodeUnspsc190501.50585903'),
	'identifierSchemeCodeUnspsc190501.50585904': __('identifierSchemeCodeUnspsc190501.50585904'),
	'identifierSchemeCodeUnspsc190501.50586000': __('identifierSchemeCodeUnspsc190501.50586000'),
	'identifierSchemeCodeUnspsc190501.50586001': __('identifierSchemeCodeUnspsc190501.50586001'),
	'identifierSchemeCodeUnspsc190501.50586002': __('identifierSchemeCodeUnspsc190501.50586002'),
	'identifierSchemeCodeUnspsc190501.50586003': __('identifierSchemeCodeUnspsc190501.50586003'),
	'identifierSchemeCodeUnspsc190501.50586004': __('identifierSchemeCodeUnspsc190501.50586004'),
	'identifierSchemeCodeUnspsc190501.50586005': __('identifierSchemeCodeUnspsc190501.50586005'),
	'identifierSchemeCodeUnspsc190501.50586006': __('identifierSchemeCodeUnspsc190501.50586006'),
	'identifierSchemeCodeUnspsc190501.50586007': __('identifierSchemeCodeUnspsc190501.50586007'),
	'identifierSchemeCodeUnspsc190501.50586008': __('identifierSchemeCodeUnspsc190501.50586008'),
	'identifierSchemeCodeUnspsc190501.50586009': __('identifierSchemeCodeUnspsc190501.50586009'),
	'identifierSchemeCodeUnspsc190501.50586010': __('identifierSchemeCodeUnspsc190501.50586010'),
	'identifierSchemeCodeUnspsc190501.50586011': __('identifierSchemeCodeUnspsc190501.50586011'),
	'identifierSchemeCodeUnspsc190501.50586012': __('identifierSchemeCodeUnspsc190501.50586012'),
	'identifierSchemeCodeUnspsc190501.50586013': __('identifierSchemeCodeUnspsc190501.50586013'),
	'identifierSchemeCodeUnspsc190501.50586014': __('identifierSchemeCodeUnspsc190501.50586014'),
	'identifierSchemeCodeUnspsc190501.50586015': __('identifierSchemeCodeUnspsc190501.50586015'),
	'identifierSchemeCodeUnspsc190501.50586100': __('identifierSchemeCodeUnspsc190501.50586100'),
	'identifierSchemeCodeUnspsc190501.50586101': __('identifierSchemeCodeUnspsc190501.50586101'),
	'identifierSchemeCodeUnspsc190501.50586102': __('identifierSchemeCodeUnspsc190501.50586102'),
	'identifierSchemeCodeUnspsc190501.50586103': __('identifierSchemeCodeUnspsc190501.50586103'),
	'identifierSchemeCodeUnspsc190501.50586104': __('identifierSchemeCodeUnspsc190501.50586104'),
	'identifierSchemeCodeUnspsc190501.50586105': __('identifierSchemeCodeUnspsc190501.50586105'),
	'identifierSchemeCodeUnspsc190501.50586106': __('identifierSchemeCodeUnspsc190501.50586106'),
	'identifierSchemeCodeUnspsc190501.50586107': __('identifierSchemeCodeUnspsc190501.50586107'),
	'identifierSchemeCodeUnspsc190501.50586108': __('identifierSchemeCodeUnspsc190501.50586108'),
	'identifierSchemeCodeUnspsc190501.50586109': __('identifierSchemeCodeUnspsc190501.50586109'),
	'identifierSchemeCodeUnspsc190501.50586110': __('identifierSchemeCodeUnspsc190501.50586110'),
	'identifierSchemeCodeUnspsc190501.50586111': __('identifierSchemeCodeUnspsc190501.50586111'),
	'identifierSchemeCodeUnspsc190501.50586112': __('identifierSchemeCodeUnspsc190501.50586112'),
	'identifierSchemeCodeUnspsc190501.50586113': __('identifierSchemeCodeUnspsc190501.50586113'),
	'identifierSchemeCodeUnspsc190501.50586114': __('identifierSchemeCodeUnspsc190501.50586114'),
	'identifierSchemeCodeUnspsc190501.50586115': __('identifierSchemeCodeUnspsc190501.50586115'),
	'identifierSchemeCodeUnspsc190501.50586116': __('identifierSchemeCodeUnspsc190501.50586116'),
	'identifierSchemeCodeUnspsc190501.50586117': __('identifierSchemeCodeUnspsc190501.50586117'),
	'identifierSchemeCodeUnspsc190501.50586118': __('identifierSchemeCodeUnspsc190501.50586118'),
	'identifierSchemeCodeUnspsc190501.50586119': __('identifierSchemeCodeUnspsc190501.50586119'),
	'identifierSchemeCodeUnspsc190501.50586120': __('identifierSchemeCodeUnspsc190501.50586120'),
	'identifierSchemeCodeUnspsc190501.50586121': __('identifierSchemeCodeUnspsc190501.50586121'),
	'identifierSchemeCodeUnspsc190501.50586122': __('identifierSchemeCodeUnspsc190501.50586122'),
	'identifierSchemeCodeUnspsc190501.50586123': __('identifierSchemeCodeUnspsc190501.50586123'),
	'identifierSchemeCodeUnspsc190501.50586124': __('identifierSchemeCodeUnspsc190501.50586124'),
	'identifierSchemeCodeUnspsc190501.50586125': __('identifierSchemeCodeUnspsc190501.50586125'),
	'identifierSchemeCodeUnspsc190501.50586126': __('identifierSchemeCodeUnspsc190501.50586126'),
	'identifierSchemeCodeUnspsc190501.50586127': __('identifierSchemeCodeUnspsc190501.50586127'),
	'identifierSchemeCodeUnspsc190501.50586128': __('identifierSchemeCodeUnspsc190501.50586128'),
	'identifierSchemeCodeUnspsc190501.50586129': __('identifierSchemeCodeUnspsc190501.50586129'),
	'identifierSchemeCodeUnspsc190501.50586200': __('identifierSchemeCodeUnspsc190501.50586200'),
	'identifierSchemeCodeUnspsc190501.50586201': __('identifierSchemeCodeUnspsc190501.50586201'),
	'identifierSchemeCodeUnspsc190501.50586202': __('identifierSchemeCodeUnspsc190501.50586202'),
	'identifierSchemeCodeUnspsc190501.50586203': __('identifierSchemeCodeUnspsc190501.50586203'),
	'identifierSchemeCodeUnspsc190501.50586204': __('identifierSchemeCodeUnspsc190501.50586204'),
	'identifierSchemeCodeUnspsc190501.50586205': __('identifierSchemeCodeUnspsc190501.50586205'),
	'identifierSchemeCodeUnspsc190501.50586206': __('identifierSchemeCodeUnspsc190501.50586206'),
	'identifierSchemeCodeUnspsc190501.50586207': __('identifierSchemeCodeUnspsc190501.50586207'),
	'identifierSchemeCodeUnspsc190501.50586208': __('identifierSchemeCodeUnspsc190501.50586208'),
	'identifierSchemeCodeUnspsc190501.50586209': __('identifierSchemeCodeUnspsc190501.50586209'),
	'identifierSchemeCodeUnspsc190501.50586210': __('identifierSchemeCodeUnspsc190501.50586210'),
	'identifierSchemeCodeUnspsc190501.50586211': __('identifierSchemeCodeUnspsc190501.50586211'),
	'identifierSchemeCodeUnspsc190501.50586212': __('identifierSchemeCodeUnspsc190501.50586212'),
	'identifierSchemeCodeUnspsc190501.50586213': __('identifierSchemeCodeUnspsc190501.50586213'),
	'identifierSchemeCodeUnspsc190501.50586214': __('identifierSchemeCodeUnspsc190501.50586214'),
	'identifierSchemeCodeUnspsc190501.50586215': __('identifierSchemeCodeUnspsc190501.50586215'),
	'identifierSchemeCodeUnspsc190501.50586216': __('identifierSchemeCodeUnspsc190501.50586216'),
	'identifierSchemeCodeUnspsc190501.50586217': __('identifierSchemeCodeUnspsc190501.50586217'),
	'identifierSchemeCodeUnspsc190501.50586218': __('identifierSchemeCodeUnspsc190501.50586218'),
	'identifierSchemeCodeUnspsc190501.50586219': __('identifierSchemeCodeUnspsc190501.50586219'),
	'identifierSchemeCodeUnspsc190501.50586220': __('identifierSchemeCodeUnspsc190501.50586220'),
	'identifierSchemeCodeUnspsc190501.50586300': __('identifierSchemeCodeUnspsc190501.50586300'),
	'identifierSchemeCodeUnspsc190501.50586301': __('identifierSchemeCodeUnspsc190501.50586301'),
	'identifierSchemeCodeUnspsc190501.50586302': __('identifierSchemeCodeUnspsc190501.50586302'),
	'identifierSchemeCodeUnspsc190501.50586303': __('identifierSchemeCodeUnspsc190501.50586303'),
	'identifierSchemeCodeUnspsc190501.50586304': __('identifierSchemeCodeUnspsc190501.50586304'),
	'identifierSchemeCodeUnspsc190501.50586305': __('identifierSchemeCodeUnspsc190501.50586305'),
	'identifierSchemeCodeUnspsc190501.50586306': __('identifierSchemeCodeUnspsc190501.50586306'),
	'identifierSchemeCodeUnspsc190501.50586307': __('identifierSchemeCodeUnspsc190501.50586307'),
	'identifierSchemeCodeUnspsc190501.50586308': __('identifierSchemeCodeUnspsc190501.50586308'),
	'identifierSchemeCodeUnspsc190501.50586309': __('identifierSchemeCodeUnspsc190501.50586309'),
	'identifierSchemeCodeUnspsc190501.50586310': __('identifierSchemeCodeUnspsc190501.50586310'),
	'identifierSchemeCodeUnspsc190501.50586311': __('identifierSchemeCodeUnspsc190501.50586311'),
	'identifierSchemeCodeUnspsc190501.50586312': __('identifierSchemeCodeUnspsc190501.50586312'),
	'identifierSchemeCodeUnspsc190501.50586313': __('identifierSchemeCodeUnspsc190501.50586313'),
	'identifierSchemeCodeUnspsc190501.50586314': __('identifierSchemeCodeUnspsc190501.50586314'),
	'identifierSchemeCodeUnspsc190501.50586315': __('identifierSchemeCodeUnspsc190501.50586315'),
	'identifierSchemeCodeUnspsc190501.50586316': __('identifierSchemeCodeUnspsc190501.50586316'),
	'identifierSchemeCodeUnspsc190501.50586317': __('identifierSchemeCodeUnspsc190501.50586317'),
	'identifierSchemeCodeUnspsc190501.50586318': __('identifierSchemeCodeUnspsc190501.50586318'),
	'identifierSchemeCodeUnspsc190501.50586319': __('identifierSchemeCodeUnspsc190501.50586319'),
	'identifierSchemeCodeUnspsc190501.50586320': __('identifierSchemeCodeUnspsc190501.50586320'),
	'identifierSchemeCodeUnspsc190501.50586321': __('identifierSchemeCodeUnspsc190501.50586321'),
	'identifierSchemeCodeUnspsc190501.50586322': __('identifierSchemeCodeUnspsc190501.50586322'),
	'identifierSchemeCodeUnspsc190501.50586323': __('identifierSchemeCodeUnspsc190501.50586323'),
	'identifierSchemeCodeUnspsc190501.50586324': __('identifierSchemeCodeUnspsc190501.50586324'),
	'identifierSchemeCodeUnspsc190501.50586325': __('identifierSchemeCodeUnspsc190501.50586325'),
	'identifierSchemeCodeUnspsc190501.50586326': __('identifierSchemeCodeUnspsc190501.50586326'),
	'identifierSchemeCodeUnspsc190501.50586327': __('identifierSchemeCodeUnspsc190501.50586327'),
	'identifierSchemeCodeUnspsc190501.50586400': __('identifierSchemeCodeUnspsc190501.50586400'),
	'identifierSchemeCodeUnspsc190501.50586401': __('identifierSchemeCodeUnspsc190501.50586401'),
	'identifierSchemeCodeUnspsc190501.50586402': __('identifierSchemeCodeUnspsc190501.50586402'),
	'identifierSchemeCodeUnspsc190501.50586403': __('identifierSchemeCodeUnspsc190501.50586403'),
	'identifierSchemeCodeUnspsc190501.50586404': __('identifierSchemeCodeUnspsc190501.50586404'),
	'identifierSchemeCodeUnspsc190501.50586405': __('identifierSchemeCodeUnspsc190501.50586405'),
	'identifierSchemeCodeUnspsc190501.50586406': __('identifierSchemeCodeUnspsc190501.50586406'),
	'identifierSchemeCodeUnspsc190501.50586407': __('identifierSchemeCodeUnspsc190501.50586407'),
	'identifierSchemeCodeUnspsc190501.50586408': __('identifierSchemeCodeUnspsc190501.50586408'),
	'identifierSchemeCodeUnspsc190501.50586500': __('identifierSchemeCodeUnspsc190501.50586500'),
	'identifierSchemeCodeUnspsc190501.50586501': __('identifierSchemeCodeUnspsc190501.50586501'),
	'identifierSchemeCodeUnspsc190501.50586502': __('identifierSchemeCodeUnspsc190501.50586502'),
	'identifierSchemeCodeUnspsc190501.50586503': __('identifierSchemeCodeUnspsc190501.50586503'),
	'identifierSchemeCodeUnspsc190501.50586504': __('identifierSchemeCodeUnspsc190501.50586504'),
	'identifierSchemeCodeUnspsc190501.50586505': __('identifierSchemeCodeUnspsc190501.50586505'),
	'identifierSchemeCodeUnspsc190501.50586506': __('identifierSchemeCodeUnspsc190501.50586506'),
	'identifierSchemeCodeUnspsc190501.50586507': __('identifierSchemeCodeUnspsc190501.50586507'),
	'identifierSchemeCodeUnspsc190501.50586508': __('identifierSchemeCodeUnspsc190501.50586508'),
	'identifierSchemeCodeUnspsc190501.50586509': __('identifierSchemeCodeUnspsc190501.50586509'),
	'identifierSchemeCodeUnspsc190501.50586510': __('identifierSchemeCodeUnspsc190501.50586510'),
	'identifierSchemeCodeUnspsc190501.50586511': __('identifierSchemeCodeUnspsc190501.50586511'),
	'identifierSchemeCodeUnspsc190501.50586512': __('identifierSchemeCodeUnspsc190501.50586512'),
	'identifierSchemeCodeUnspsc190501.50586513': __('identifierSchemeCodeUnspsc190501.50586513'),
	'identifierSchemeCodeUnspsc190501.50586514': __('identifierSchemeCodeUnspsc190501.50586514'),
	'identifierSchemeCodeUnspsc190501.50586515': __('identifierSchemeCodeUnspsc190501.50586515'),
	'identifierSchemeCodeUnspsc190501.50586516': __('identifierSchemeCodeUnspsc190501.50586516'),
	'identifierSchemeCodeUnspsc190501.50586517': __('identifierSchemeCodeUnspsc190501.50586517'),
	'identifierSchemeCodeUnspsc190501.50586518': __('identifierSchemeCodeUnspsc190501.50586518'),
	'identifierSchemeCodeUnspsc190501.50586519': __('identifierSchemeCodeUnspsc190501.50586519'),
	'identifierSchemeCodeUnspsc190501.50586520': __('identifierSchemeCodeUnspsc190501.50586520'),
	'identifierSchemeCodeUnspsc190501.50586600': __('identifierSchemeCodeUnspsc190501.50586600'),
	'identifierSchemeCodeUnspsc190501.50586601': __('identifierSchemeCodeUnspsc190501.50586601'),
	'identifierSchemeCodeUnspsc190501.50586602': __('identifierSchemeCodeUnspsc190501.50586602'),
	'identifierSchemeCodeUnspsc190501.50586603': __('identifierSchemeCodeUnspsc190501.50586603'),
	'identifierSchemeCodeUnspsc190501.50586604': __('identifierSchemeCodeUnspsc190501.50586604'),
	'identifierSchemeCodeUnspsc190501.50586605': __('identifierSchemeCodeUnspsc190501.50586605'),
	'identifierSchemeCodeUnspsc190501.50586606': __('identifierSchemeCodeUnspsc190501.50586606'),
	'identifierSchemeCodeUnspsc190501.50586607': __('identifierSchemeCodeUnspsc190501.50586607'),
	'identifierSchemeCodeUnspsc190501.50586608': __('identifierSchemeCodeUnspsc190501.50586608'),
	'identifierSchemeCodeUnspsc190501.50586609': __('identifierSchemeCodeUnspsc190501.50586609'),
	'identifierSchemeCodeUnspsc190501.50586610': __('identifierSchemeCodeUnspsc190501.50586610'),
	'identifierSchemeCodeUnspsc190501.50586700': __('identifierSchemeCodeUnspsc190501.50586700'),
	'identifierSchemeCodeUnspsc190501.50586701': __('identifierSchemeCodeUnspsc190501.50586701'),
	'identifierSchemeCodeUnspsc190501.50586702': __('identifierSchemeCodeUnspsc190501.50586702'),
	'identifierSchemeCodeUnspsc190501.50586703': __('identifierSchemeCodeUnspsc190501.50586703'),
	'identifierSchemeCodeUnspsc190501.50586704': __('identifierSchemeCodeUnspsc190501.50586704'),
	'identifierSchemeCodeUnspsc190501.50586705': __('identifierSchemeCodeUnspsc190501.50586705'),
	'identifierSchemeCodeUnspsc190501.50586706': __('identifierSchemeCodeUnspsc190501.50586706'),
	'identifierSchemeCodeUnspsc190501.50586707': __('identifierSchemeCodeUnspsc190501.50586707'),
	'identifierSchemeCodeUnspsc190501.50586708': __('identifierSchemeCodeUnspsc190501.50586708'),
	'identifierSchemeCodeUnspsc190501.50586709': __('identifierSchemeCodeUnspsc190501.50586709'),
	'identifierSchemeCodeUnspsc190501.50586710': __('identifierSchemeCodeUnspsc190501.50586710'),
	'identifierSchemeCodeUnspsc190501.50586711': __('identifierSchemeCodeUnspsc190501.50586711'),
	'identifierSchemeCodeUnspsc190501.50586712': __('identifierSchemeCodeUnspsc190501.50586712'),
	'identifierSchemeCodeUnspsc190501.50586713': __('identifierSchemeCodeUnspsc190501.50586713'),
	'identifierSchemeCodeUnspsc190501.50586714': __('identifierSchemeCodeUnspsc190501.50586714'),
	'identifierSchemeCodeUnspsc190501.50586715': __('identifierSchemeCodeUnspsc190501.50586715'),
	'identifierSchemeCodeUnspsc190501.50586716': __('identifierSchemeCodeUnspsc190501.50586716'),
	'identifierSchemeCodeUnspsc190501.50586717': __('identifierSchemeCodeUnspsc190501.50586717'),
	'identifierSchemeCodeUnspsc190501.50586718': __('identifierSchemeCodeUnspsc190501.50586718'),
	'identifierSchemeCodeUnspsc190501.50586719': __('identifierSchemeCodeUnspsc190501.50586719'),
	'identifierSchemeCodeUnspsc190501.50586720': __('identifierSchemeCodeUnspsc190501.50586720'),
	'identifierSchemeCodeUnspsc190501.50586721': __('identifierSchemeCodeUnspsc190501.50586721'),
	'identifierSchemeCodeUnspsc190501.50586722': __('identifierSchemeCodeUnspsc190501.50586722'),
	'identifierSchemeCodeUnspsc190501.50586723': __('identifierSchemeCodeUnspsc190501.50586723'),
	'identifierSchemeCodeUnspsc190501.50586724': __('identifierSchemeCodeUnspsc190501.50586724'),
	'identifierSchemeCodeUnspsc190501.50586725': __('identifierSchemeCodeUnspsc190501.50586725'),
	'identifierSchemeCodeUnspsc190501.50586726': __('identifierSchemeCodeUnspsc190501.50586726'),
	'identifierSchemeCodeUnspsc190501.50586727': __('identifierSchemeCodeUnspsc190501.50586727'),
	'identifierSchemeCodeUnspsc190501.50586728': __('identifierSchemeCodeUnspsc190501.50586728'),
	'identifierSchemeCodeUnspsc190501.50586729': __('identifierSchemeCodeUnspsc190501.50586729'),
	'identifierSchemeCodeUnspsc190501.50586730': __('identifierSchemeCodeUnspsc190501.50586730'),
	'identifierSchemeCodeUnspsc190501.50586731': __('identifierSchemeCodeUnspsc190501.50586731'),
	'identifierSchemeCodeUnspsc190501.50586732': __('identifierSchemeCodeUnspsc190501.50586732'),
	'identifierSchemeCodeUnspsc190501.50586733': __('identifierSchemeCodeUnspsc190501.50586733'),
	'identifierSchemeCodeUnspsc190501.50586734': __('identifierSchemeCodeUnspsc190501.50586734'),
	'identifierSchemeCodeUnspsc190501.50586735': __('identifierSchemeCodeUnspsc190501.50586735'),
	'identifierSchemeCodeUnspsc190501.50586736': __('identifierSchemeCodeUnspsc190501.50586736'),
	'identifierSchemeCodeUnspsc190501.50586737': __('identifierSchemeCodeUnspsc190501.50586737'),
	'identifierSchemeCodeUnspsc190501.50586738': __('identifierSchemeCodeUnspsc190501.50586738'),
	'identifierSchemeCodeUnspsc190501.50586739': __('identifierSchemeCodeUnspsc190501.50586739'),
	'identifierSchemeCodeUnspsc190501.50586740': __('identifierSchemeCodeUnspsc190501.50586740'),
	'identifierSchemeCodeUnspsc190501.50586741': __('identifierSchemeCodeUnspsc190501.50586741'),
	'identifierSchemeCodeUnspsc190501.50586742': __('identifierSchemeCodeUnspsc190501.50586742'),
	'identifierSchemeCodeUnspsc190501.50586743': __('identifierSchemeCodeUnspsc190501.50586743'),
	'identifierSchemeCodeUnspsc190501.50586744': __('identifierSchemeCodeUnspsc190501.50586744'),
	'identifierSchemeCodeUnspsc190501.50586745': __('identifierSchemeCodeUnspsc190501.50586745'),
	'identifierSchemeCodeUnspsc190501.50586746': __('identifierSchemeCodeUnspsc190501.50586746'),
	'identifierSchemeCodeUnspsc190501.50586747': __('identifierSchemeCodeUnspsc190501.50586747'),
	'identifierSchemeCodeUnspsc190501.50586748': __('identifierSchemeCodeUnspsc190501.50586748'),
	'identifierSchemeCodeUnspsc190501.50586749': __('identifierSchemeCodeUnspsc190501.50586749'),
	'identifierSchemeCodeUnspsc190501.50586750': __('identifierSchemeCodeUnspsc190501.50586750'),
	'identifierSchemeCodeUnspsc190501.50586751': __('identifierSchemeCodeUnspsc190501.50586751'),
	'identifierSchemeCodeUnspsc190501.50586752': __('identifierSchemeCodeUnspsc190501.50586752'),
	'identifierSchemeCodeUnspsc190501.50586753': __('identifierSchemeCodeUnspsc190501.50586753'),
	'identifierSchemeCodeUnspsc190501.50586754': __('identifierSchemeCodeUnspsc190501.50586754'),
	'identifierSchemeCodeUnspsc190501.50586755': __('identifierSchemeCodeUnspsc190501.50586755'),
	'identifierSchemeCodeUnspsc190501.50586756': __('identifierSchemeCodeUnspsc190501.50586756'),
	'identifierSchemeCodeUnspsc190501.50586757': __('identifierSchemeCodeUnspsc190501.50586757'),
	'identifierSchemeCodeUnspsc190501.50586758': __('identifierSchemeCodeUnspsc190501.50586758'),
	'identifierSchemeCodeUnspsc190501.50586759': __('identifierSchemeCodeUnspsc190501.50586759'),
	'identifierSchemeCodeUnspsc190501.50586760': __('identifierSchemeCodeUnspsc190501.50586760'),
	'identifierSchemeCodeUnspsc190501.50586761': __('identifierSchemeCodeUnspsc190501.50586761'),
	'identifierSchemeCodeUnspsc190501.50586762': __('identifierSchemeCodeUnspsc190501.50586762'),
	'identifierSchemeCodeUnspsc190501.50586763': __('identifierSchemeCodeUnspsc190501.50586763'),
	'identifierSchemeCodeUnspsc190501.50586764': __('identifierSchemeCodeUnspsc190501.50586764'),
	'identifierSchemeCodeUnspsc190501.50586765': __('identifierSchemeCodeUnspsc190501.50586765'),
	'identifierSchemeCodeUnspsc190501.50586766': __('identifierSchemeCodeUnspsc190501.50586766'),
	'identifierSchemeCodeUnspsc190501.50586767': __('identifierSchemeCodeUnspsc190501.50586767'),
	'identifierSchemeCodeUnspsc190501.50586768': __('identifierSchemeCodeUnspsc190501.50586768'),
	'identifierSchemeCodeUnspsc190501.50586769': __('identifierSchemeCodeUnspsc190501.50586769'),
	'identifierSchemeCodeUnspsc190501.50586800': __('identifierSchemeCodeUnspsc190501.50586800'),
	'identifierSchemeCodeUnspsc190501.50586801': __('identifierSchemeCodeUnspsc190501.50586801'),
	'identifierSchemeCodeUnspsc190501.50586802': __('identifierSchemeCodeUnspsc190501.50586802'),
	'identifierSchemeCodeUnspsc190501.50586803': __('identifierSchemeCodeUnspsc190501.50586803'),
	'identifierSchemeCodeUnspsc190501.50586804': __('identifierSchemeCodeUnspsc190501.50586804'),
	'identifierSchemeCodeUnspsc190501.50586805': __('identifierSchemeCodeUnspsc190501.50586805'),
	'identifierSchemeCodeUnspsc190501.50586806': __('identifierSchemeCodeUnspsc190501.50586806'),
	'identifierSchemeCodeUnspsc190501.50586807': __('identifierSchemeCodeUnspsc190501.50586807'),
	'identifierSchemeCodeUnspsc190501.50586808': __('identifierSchemeCodeUnspsc190501.50586808'),
	'identifierSchemeCodeUnspsc190501.50586809': __('identifierSchemeCodeUnspsc190501.50586809'),
	'identifierSchemeCodeUnspsc190501.50586810': __('identifierSchemeCodeUnspsc190501.50586810'),
	'identifierSchemeCodeUnspsc190501.50586811': __('identifierSchemeCodeUnspsc190501.50586811'),
	'identifierSchemeCodeUnspsc190501.50586812': __('identifierSchemeCodeUnspsc190501.50586812'),
	'identifierSchemeCodeUnspsc190501.50586813': __('identifierSchemeCodeUnspsc190501.50586813'),
	'identifierSchemeCodeUnspsc190501.50586814': __('identifierSchemeCodeUnspsc190501.50586814'),
	'identifierSchemeCodeUnspsc190501.50586815': __('identifierSchemeCodeUnspsc190501.50586815'),
	'identifierSchemeCodeUnspsc190501.50586816': __('identifierSchemeCodeUnspsc190501.50586816'),
	'identifierSchemeCodeUnspsc190501.50586817': __('identifierSchemeCodeUnspsc190501.50586817'),
	'identifierSchemeCodeUnspsc190501.50586818': __('identifierSchemeCodeUnspsc190501.50586818'),
	'identifierSchemeCodeUnspsc190501.50586819': __('identifierSchemeCodeUnspsc190501.50586819'),
	'identifierSchemeCodeUnspsc190501.50586820': __('identifierSchemeCodeUnspsc190501.50586820'),
	'identifierSchemeCodeUnspsc190501.50586821': __('identifierSchemeCodeUnspsc190501.50586821'),
	'identifierSchemeCodeUnspsc190501.50586822': __('identifierSchemeCodeUnspsc190501.50586822'),
	'identifierSchemeCodeUnspsc190501.50586823': __('identifierSchemeCodeUnspsc190501.50586823'),
	'identifierSchemeCodeUnspsc190501.50586824': __('identifierSchemeCodeUnspsc190501.50586824'),
	'identifierSchemeCodeUnspsc190501.50586825': __('identifierSchemeCodeUnspsc190501.50586825'),
	'identifierSchemeCodeUnspsc190501.50586826': __('identifierSchemeCodeUnspsc190501.50586826'),
	'identifierSchemeCodeUnspsc190501.50586827': __('identifierSchemeCodeUnspsc190501.50586827'),
	'identifierSchemeCodeUnspsc190501.50586828': __('identifierSchemeCodeUnspsc190501.50586828'),
	'identifierSchemeCodeUnspsc190501.50586829': __('identifierSchemeCodeUnspsc190501.50586829'),
	'identifierSchemeCodeUnspsc190501.50586830': __('identifierSchemeCodeUnspsc190501.50586830'),
	'identifierSchemeCodeUnspsc190501.50586831': __('identifierSchemeCodeUnspsc190501.50586831'),
	'identifierSchemeCodeUnspsc190501.50586832': __('identifierSchemeCodeUnspsc190501.50586832'),
	'identifierSchemeCodeUnspsc190501.50586833': __('identifierSchemeCodeUnspsc190501.50586833'),
	'identifierSchemeCodeUnspsc190501.50586834': __('identifierSchemeCodeUnspsc190501.50586834'),
	'identifierSchemeCodeUnspsc190501.50586835': __('identifierSchemeCodeUnspsc190501.50586835'),
	'identifierSchemeCodeUnspsc190501.50586836': __('identifierSchemeCodeUnspsc190501.50586836'),
	'identifierSchemeCodeUnspsc190501.50586837': __('identifierSchemeCodeUnspsc190501.50586837'),
	'identifierSchemeCodeUnspsc190501.50586838': __('identifierSchemeCodeUnspsc190501.50586838'),
	'identifierSchemeCodeUnspsc190501.50586839': __('identifierSchemeCodeUnspsc190501.50586839'),
	'identifierSchemeCodeUnspsc190501.50586840': __('identifierSchemeCodeUnspsc190501.50586840'),
	'identifierSchemeCodeUnspsc190501.50586841': __('identifierSchemeCodeUnspsc190501.50586841'),
	'identifierSchemeCodeUnspsc190501.50586842': __('identifierSchemeCodeUnspsc190501.50586842'),
	'identifierSchemeCodeUnspsc190501.50586843': __('identifierSchemeCodeUnspsc190501.50586843'),
	'identifierSchemeCodeUnspsc190501.50586844': __('identifierSchemeCodeUnspsc190501.50586844'),
	'identifierSchemeCodeUnspsc190501.50586845': __('identifierSchemeCodeUnspsc190501.50586845'),
	'identifierSchemeCodeUnspsc190501.50586846': __('identifierSchemeCodeUnspsc190501.50586846'),
	'identifierSchemeCodeUnspsc190501.50586847': __('identifierSchemeCodeUnspsc190501.50586847'),
	'identifierSchemeCodeUnspsc190501.50586848': __('identifierSchemeCodeUnspsc190501.50586848'),
	'identifierSchemeCodeUnspsc190501.50586849': __('identifierSchemeCodeUnspsc190501.50586849'),
	'identifierSchemeCodeUnspsc190501.50586850': __('identifierSchemeCodeUnspsc190501.50586850'),
	'identifierSchemeCodeUnspsc190501.50586851': __('identifierSchemeCodeUnspsc190501.50586851'),
	'identifierSchemeCodeUnspsc190501.50586852': __('identifierSchemeCodeUnspsc190501.50586852'),
	'identifierSchemeCodeUnspsc190501.50586853': __('identifierSchemeCodeUnspsc190501.50586853'),
	'identifierSchemeCodeUnspsc190501.50586854': __('identifierSchemeCodeUnspsc190501.50586854'),
	'identifierSchemeCodeUnspsc190501.50586855': __('identifierSchemeCodeUnspsc190501.50586855'),
	'identifierSchemeCodeUnspsc190501.50586856': __('identifierSchemeCodeUnspsc190501.50586856'),
	'identifierSchemeCodeUnspsc190501.50586857': __('identifierSchemeCodeUnspsc190501.50586857'),
	'identifierSchemeCodeUnspsc190501.50586858': __('identifierSchemeCodeUnspsc190501.50586858'),
	'identifierSchemeCodeUnspsc190501.50586859': __('identifierSchemeCodeUnspsc190501.50586859'),
	'identifierSchemeCodeUnspsc190501.50586860': __('identifierSchemeCodeUnspsc190501.50586860'),
	'identifierSchemeCodeUnspsc190501.50586861': __('identifierSchemeCodeUnspsc190501.50586861'),
	'identifierSchemeCodeUnspsc190501.50586862': __('identifierSchemeCodeUnspsc190501.50586862'),
	'identifierSchemeCodeUnspsc190501.50586863': __('identifierSchemeCodeUnspsc190501.50586863'),
	'identifierSchemeCodeUnspsc190501.50586864': __('identifierSchemeCodeUnspsc190501.50586864'),
	'identifierSchemeCodeUnspsc190501.50586865': __('identifierSchemeCodeUnspsc190501.50586865'),
	'identifierSchemeCodeUnspsc190501.50586866': __('identifierSchemeCodeUnspsc190501.50586866'),
	'identifierSchemeCodeUnspsc190501.50586867': __('identifierSchemeCodeUnspsc190501.50586867'),
	'identifierSchemeCodeUnspsc190501.50586868': __('identifierSchemeCodeUnspsc190501.50586868'),
	'identifierSchemeCodeUnspsc190501.50586869': __('identifierSchemeCodeUnspsc190501.50586869'),
	'identifierSchemeCodeUnspsc190501.50586900': __('identifierSchemeCodeUnspsc190501.50586900'),
	'identifierSchemeCodeUnspsc190501.50586901': __('identifierSchemeCodeUnspsc190501.50586901'),
	'identifierSchemeCodeUnspsc190501.50586902': __('identifierSchemeCodeUnspsc190501.50586902'),
	'identifierSchemeCodeUnspsc190501.50590000': __('identifierSchemeCodeUnspsc190501.50590000'),
	'identifierSchemeCodeUnspsc190501.50591500': __('identifierSchemeCodeUnspsc190501.50591500'),
	'identifierSchemeCodeUnspsc190501.50591501': __('identifierSchemeCodeUnspsc190501.50591501'),
	'identifierSchemeCodeUnspsc190501.50591502': __('identifierSchemeCodeUnspsc190501.50591502'),
	'identifierSchemeCodeUnspsc190501.50591503': __('identifierSchemeCodeUnspsc190501.50591503'),
	'identifierSchemeCodeUnspsc190501.50591504': __('identifierSchemeCodeUnspsc190501.50591504'),
	'identifierSchemeCodeUnspsc190501.50591505': __('identifierSchemeCodeUnspsc190501.50591505'),
	'identifierSchemeCodeUnspsc190501.50591506': __('identifierSchemeCodeUnspsc190501.50591506'),
	'identifierSchemeCodeUnspsc190501.50591507': __('identifierSchemeCodeUnspsc190501.50591507'),
	'identifierSchemeCodeUnspsc190501.50591508': __('identifierSchemeCodeUnspsc190501.50591508'),
	'identifierSchemeCodeUnspsc190501.50591509': __('identifierSchemeCodeUnspsc190501.50591509'),
	'identifierSchemeCodeUnspsc190501.50591510': __('identifierSchemeCodeUnspsc190501.50591510'),
	'identifierSchemeCodeUnspsc190501.50591511': __('identifierSchemeCodeUnspsc190501.50591511'),
	'identifierSchemeCodeUnspsc190501.50591512': __('identifierSchemeCodeUnspsc190501.50591512'),
	'identifierSchemeCodeUnspsc190501.50591513': __('identifierSchemeCodeUnspsc190501.50591513'),
	'identifierSchemeCodeUnspsc190501.50591600': __('identifierSchemeCodeUnspsc190501.50591600'),
	'identifierSchemeCodeUnspsc190501.50591601': __('identifierSchemeCodeUnspsc190501.50591601'),
	'identifierSchemeCodeUnspsc190501.50591602': __('identifierSchemeCodeUnspsc190501.50591602'),
	'identifierSchemeCodeUnspsc190501.50591603': __('identifierSchemeCodeUnspsc190501.50591603'),
	'identifierSchemeCodeUnspsc190501.50591604': __('identifierSchemeCodeUnspsc190501.50591604'),
	'identifierSchemeCodeUnspsc190501.50591605': __('identifierSchemeCodeUnspsc190501.50591605'),
	'identifierSchemeCodeUnspsc190501.50591700': __('identifierSchemeCodeUnspsc190501.50591700'),
	'identifierSchemeCodeUnspsc190501.50591701': __('identifierSchemeCodeUnspsc190501.50591701'),
	'identifierSchemeCodeUnspsc190501.50591702': __('identifierSchemeCodeUnspsc190501.50591702'),
	'identifierSchemeCodeUnspsc190501.50591703': __('identifierSchemeCodeUnspsc190501.50591703'),
	'identifierSchemeCodeUnspsc190501.50591704': __('identifierSchemeCodeUnspsc190501.50591704'),
	'identifierSchemeCodeUnspsc190501.50591705': __('identifierSchemeCodeUnspsc190501.50591705'),
	'identifierSchemeCodeUnspsc190501.50591706': __('identifierSchemeCodeUnspsc190501.50591706'),
	'identifierSchemeCodeUnspsc190501.50591707': __('identifierSchemeCodeUnspsc190501.50591707'),
	'identifierSchemeCodeUnspsc190501.50591708': __('identifierSchemeCodeUnspsc190501.50591708'),
	'identifierSchemeCodeUnspsc190501.50591709': __('identifierSchemeCodeUnspsc190501.50591709'),
	'identifierSchemeCodeUnspsc190501.50591710': __('identifierSchemeCodeUnspsc190501.50591710'),
	'identifierSchemeCodeUnspsc190501.50591711': __('identifierSchemeCodeUnspsc190501.50591711'),
	'identifierSchemeCodeUnspsc190501.50591712': __('identifierSchemeCodeUnspsc190501.50591712'),
	'identifierSchemeCodeUnspsc190501.50591713': __('identifierSchemeCodeUnspsc190501.50591713'),
	'identifierSchemeCodeUnspsc190501.50591714': __('identifierSchemeCodeUnspsc190501.50591714'),
	'identifierSchemeCodeUnspsc190501.50591715': __('identifierSchemeCodeUnspsc190501.50591715'),
	'identifierSchemeCodeUnspsc190501.50591716': __('identifierSchemeCodeUnspsc190501.50591716'),
	'identifierSchemeCodeUnspsc190501.50591717': __('identifierSchemeCodeUnspsc190501.50591717'),
	'identifierSchemeCodeUnspsc190501.50591718': __('identifierSchemeCodeUnspsc190501.50591718'),
	'identifierSchemeCodeUnspsc190501.50591719': __('identifierSchemeCodeUnspsc190501.50591719'),
	'identifierSchemeCodeUnspsc190501.50591720': __('identifierSchemeCodeUnspsc190501.50591720'),
	'identifierSchemeCodeUnspsc190501.50591721': __('identifierSchemeCodeUnspsc190501.50591721'),
	'identifierSchemeCodeUnspsc190501.50591722': __('identifierSchemeCodeUnspsc190501.50591722'),
	'identifierSchemeCodeUnspsc190501.50591723': __('identifierSchemeCodeUnspsc190501.50591723'),
	'identifierSchemeCodeUnspsc190501.50591724': __('identifierSchemeCodeUnspsc190501.50591724'),
	'identifierSchemeCodeUnspsc190501.50591725': __('identifierSchemeCodeUnspsc190501.50591725'),
	'identifierSchemeCodeUnspsc190501.50591726': __('identifierSchemeCodeUnspsc190501.50591726'),
	'identifierSchemeCodeUnspsc190501.50591727': __('identifierSchemeCodeUnspsc190501.50591727'),
	'identifierSchemeCodeUnspsc190501.50591728': __('identifierSchemeCodeUnspsc190501.50591728'),
	'identifierSchemeCodeUnspsc190501.50591729': __('identifierSchemeCodeUnspsc190501.50591729'),
	'identifierSchemeCodeUnspsc190501.50591730': __('identifierSchemeCodeUnspsc190501.50591730'),
	'identifierSchemeCodeUnspsc190501.50591731': __('identifierSchemeCodeUnspsc190501.50591731'),
	'identifierSchemeCodeUnspsc190501.50591732': __('identifierSchemeCodeUnspsc190501.50591732'),
	'identifierSchemeCodeUnspsc190501.50591733': __('identifierSchemeCodeUnspsc190501.50591733'),
	'identifierSchemeCodeUnspsc190501.50591734': __('identifierSchemeCodeUnspsc190501.50591734'),
	'identifierSchemeCodeUnspsc190501.50591735': __('identifierSchemeCodeUnspsc190501.50591735'),
	'identifierSchemeCodeUnspsc190501.50591736': __('identifierSchemeCodeUnspsc190501.50591736'),
	'identifierSchemeCodeUnspsc190501.50591737': __('identifierSchemeCodeUnspsc190501.50591737'),
	'identifierSchemeCodeUnspsc190501.50591738': __('identifierSchemeCodeUnspsc190501.50591738'),
	'identifierSchemeCodeUnspsc190501.50591739': __('identifierSchemeCodeUnspsc190501.50591739'),
	'identifierSchemeCodeUnspsc190501.50591740': __('identifierSchemeCodeUnspsc190501.50591740'),
	'identifierSchemeCodeUnspsc190501.50591741': __('identifierSchemeCodeUnspsc190501.50591741'),
	'identifierSchemeCodeUnspsc190501.50591742': __('identifierSchemeCodeUnspsc190501.50591742'),
	'identifierSchemeCodeUnspsc190501.50591743': __('identifierSchemeCodeUnspsc190501.50591743'),
	'identifierSchemeCodeUnspsc190501.50591744': __('identifierSchemeCodeUnspsc190501.50591744'),
	'identifierSchemeCodeUnspsc190501.50591745': __('identifierSchemeCodeUnspsc190501.50591745'),
	'identifierSchemeCodeUnspsc190501.50591746': __('identifierSchemeCodeUnspsc190501.50591746'),
	'identifierSchemeCodeUnspsc190501.50591747': __('identifierSchemeCodeUnspsc190501.50591747'),
	'identifierSchemeCodeUnspsc190501.50591748': __('identifierSchemeCodeUnspsc190501.50591748'),
	'identifierSchemeCodeUnspsc190501.50591749': __('identifierSchemeCodeUnspsc190501.50591749'),
	'identifierSchemeCodeUnspsc190501.50591750': __('identifierSchemeCodeUnspsc190501.50591750'),
	'identifierSchemeCodeUnspsc190501.50591751': __('identifierSchemeCodeUnspsc190501.50591751'),
	'identifierSchemeCodeUnspsc190501.50591752': __('identifierSchemeCodeUnspsc190501.50591752'),
	'identifierSchemeCodeUnspsc190501.50591753': __('identifierSchemeCodeUnspsc190501.50591753'),
	'identifierSchemeCodeUnspsc190501.50591754': __('identifierSchemeCodeUnspsc190501.50591754'),
	'identifierSchemeCodeUnspsc190501.50591755': __('identifierSchemeCodeUnspsc190501.50591755'),
	'identifierSchemeCodeUnspsc190501.50591756': __('identifierSchemeCodeUnspsc190501.50591756'),
	'identifierSchemeCodeUnspsc190501.50591757': __('identifierSchemeCodeUnspsc190501.50591757'),
	'identifierSchemeCodeUnspsc190501.50591758': __('identifierSchemeCodeUnspsc190501.50591758'),
	'identifierSchemeCodeUnspsc190501.50591759': __('identifierSchemeCodeUnspsc190501.50591759'),
	'identifierSchemeCodeUnspsc190501.50591760': __('identifierSchemeCodeUnspsc190501.50591760'),
	'identifierSchemeCodeUnspsc190501.50591761': __('identifierSchemeCodeUnspsc190501.50591761'),
	'identifierSchemeCodeUnspsc190501.50591762': __('identifierSchemeCodeUnspsc190501.50591762'),
	'identifierSchemeCodeUnspsc190501.50591763': __('identifierSchemeCodeUnspsc190501.50591763'),
	'identifierSchemeCodeUnspsc190501.50591764': __('identifierSchemeCodeUnspsc190501.50591764'),
	'identifierSchemeCodeUnspsc190501.50591765': __('identifierSchemeCodeUnspsc190501.50591765'),
	'identifierSchemeCodeUnspsc190501.50591766': __('identifierSchemeCodeUnspsc190501.50591766'),
	'identifierSchemeCodeUnspsc190501.50591767': __('identifierSchemeCodeUnspsc190501.50591767'),
	'identifierSchemeCodeUnspsc190501.50591768': __('identifierSchemeCodeUnspsc190501.50591768'),
	'identifierSchemeCodeUnspsc190501.50591769': __('identifierSchemeCodeUnspsc190501.50591769'),
	'identifierSchemeCodeUnspsc190501.50591770': __('identifierSchemeCodeUnspsc190501.50591770'),
	'identifierSchemeCodeUnspsc190501.50591771': __('identifierSchemeCodeUnspsc190501.50591771'),
	'identifierSchemeCodeUnspsc190501.50591772': __('identifierSchemeCodeUnspsc190501.50591772'),
	'identifierSchemeCodeUnspsc190501.50591773': __('identifierSchemeCodeUnspsc190501.50591773'),
	'identifierSchemeCodeUnspsc190501.50591774': __('identifierSchemeCodeUnspsc190501.50591774'),
	'identifierSchemeCodeUnspsc190501.50591775': __('identifierSchemeCodeUnspsc190501.50591775'),
	'identifierSchemeCodeUnspsc190501.50591776': __('identifierSchemeCodeUnspsc190501.50591776'),
	'identifierSchemeCodeUnspsc190501.50591777': __('identifierSchemeCodeUnspsc190501.50591777'),
	'identifierSchemeCodeUnspsc190501.50591778': __('identifierSchemeCodeUnspsc190501.50591778'),
	'identifierSchemeCodeUnspsc190501.50591779': __('identifierSchemeCodeUnspsc190501.50591779'),
	'identifierSchemeCodeUnspsc190501.50591780': __('identifierSchemeCodeUnspsc190501.50591780'),
	'identifierSchemeCodeUnspsc190501.50591781': __('identifierSchemeCodeUnspsc190501.50591781'),
	'identifierSchemeCodeUnspsc190501.50591782': __('identifierSchemeCodeUnspsc190501.50591782'),
	'identifierSchemeCodeUnspsc190501.50591800': __('identifierSchemeCodeUnspsc190501.50591800'),
	'identifierSchemeCodeUnspsc190501.50591801': __('identifierSchemeCodeUnspsc190501.50591801'),
	'identifierSchemeCodeUnspsc190501.50591802': __('identifierSchemeCodeUnspsc190501.50591802'),
	'identifierSchemeCodeUnspsc190501.50591803': __('identifierSchemeCodeUnspsc190501.50591803'),
	'identifierSchemeCodeUnspsc190501.50591804': __('identifierSchemeCodeUnspsc190501.50591804'),
	'identifierSchemeCodeUnspsc190501.50591805': __('identifierSchemeCodeUnspsc190501.50591805'),
	'identifierSchemeCodeUnspsc190501.50591806': __('identifierSchemeCodeUnspsc190501.50591806'),
	'identifierSchemeCodeUnspsc190501.50591807': __('identifierSchemeCodeUnspsc190501.50591807'),
	'identifierSchemeCodeUnspsc190501.50591808': __('identifierSchemeCodeUnspsc190501.50591808'),
	'identifierSchemeCodeUnspsc190501.50591809': __('identifierSchemeCodeUnspsc190501.50591809'),
	'identifierSchemeCodeUnspsc190501.50591810': __('identifierSchemeCodeUnspsc190501.50591810'),
	'identifierSchemeCodeUnspsc190501.50591811': __('identifierSchemeCodeUnspsc190501.50591811'),
	'identifierSchemeCodeUnspsc190501.50591812': __('identifierSchemeCodeUnspsc190501.50591812'),
	'identifierSchemeCodeUnspsc190501.50591813': __('identifierSchemeCodeUnspsc190501.50591813'),
	'identifierSchemeCodeUnspsc190501.50591814': __('identifierSchemeCodeUnspsc190501.50591814'),
	'identifierSchemeCodeUnspsc190501.50591815': __('identifierSchemeCodeUnspsc190501.50591815'),
	'identifierSchemeCodeUnspsc190501.50591816': __('identifierSchemeCodeUnspsc190501.50591816'),
	'identifierSchemeCodeUnspsc190501.50591817': __('identifierSchemeCodeUnspsc190501.50591817'),
	'identifierSchemeCodeUnspsc190501.50591818': __('identifierSchemeCodeUnspsc190501.50591818'),
	'identifierSchemeCodeUnspsc190501.50591819': __('identifierSchemeCodeUnspsc190501.50591819'),
	'identifierSchemeCodeUnspsc190501.50591820': __('identifierSchemeCodeUnspsc190501.50591820'),
	'identifierSchemeCodeUnspsc190501.50591821': __('identifierSchemeCodeUnspsc190501.50591821'),
	'identifierSchemeCodeUnspsc190501.50591822': __('identifierSchemeCodeUnspsc190501.50591822'),
	'identifierSchemeCodeUnspsc190501.50591823': __('identifierSchemeCodeUnspsc190501.50591823'),
	'identifierSchemeCodeUnspsc190501.50591824': __('identifierSchemeCodeUnspsc190501.50591824'),
	'identifierSchemeCodeUnspsc190501.50591825': __('identifierSchemeCodeUnspsc190501.50591825'),
	'identifierSchemeCodeUnspsc190501.50591826': __('identifierSchemeCodeUnspsc190501.50591826'),
	'identifierSchemeCodeUnspsc190501.50591827': __('identifierSchemeCodeUnspsc190501.50591827'),
	'identifierSchemeCodeUnspsc190501.50591828': __('identifierSchemeCodeUnspsc190501.50591828'),
	'identifierSchemeCodeUnspsc190501.50591829': __('identifierSchemeCodeUnspsc190501.50591829'),
	'identifierSchemeCodeUnspsc190501.50591830': __('identifierSchemeCodeUnspsc190501.50591830'),
	'identifierSchemeCodeUnspsc190501.50591831': __('identifierSchemeCodeUnspsc190501.50591831'),
	'identifierSchemeCodeUnspsc190501.50591832': __('identifierSchemeCodeUnspsc190501.50591832'),
	'identifierSchemeCodeUnspsc190501.50591833': __('identifierSchemeCodeUnspsc190501.50591833'),
	'identifierSchemeCodeUnspsc190501.50591834': __('identifierSchemeCodeUnspsc190501.50591834'),
	'identifierSchemeCodeUnspsc190501.50591835': __('identifierSchemeCodeUnspsc190501.50591835'),
	'identifierSchemeCodeUnspsc190501.50591836': __('identifierSchemeCodeUnspsc190501.50591836'),
	'identifierSchemeCodeUnspsc190501.50591837': __('identifierSchemeCodeUnspsc190501.50591837'),
	'identifierSchemeCodeUnspsc190501.50591838': __('identifierSchemeCodeUnspsc190501.50591838'),
	'identifierSchemeCodeUnspsc190501.50591839': __('identifierSchemeCodeUnspsc190501.50591839'),
	'identifierSchemeCodeUnspsc190501.50591840': __('identifierSchemeCodeUnspsc190501.50591840'),
	'identifierSchemeCodeUnspsc190501.50591841': __('identifierSchemeCodeUnspsc190501.50591841'),
	'identifierSchemeCodeUnspsc190501.50591842': __('identifierSchemeCodeUnspsc190501.50591842'),
	'identifierSchemeCodeUnspsc190501.50591843': __('identifierSchemeCodeUnspsc190501.50591843'),
	'identifierSchemeCodeUnspsc190501.50591844': __('identifierSchemeCodeUnspsc190501.50591844'),
	'identifierSchemeCodeUnspsc190501.50591845': __('identifierSchemeCodeUnspsc190501.50591845'),
	'identifierSchemeCodeUnspsc190501.50591846': __('identifierSchemeCodeUnspsc190501.50591846'),
	'identifierSchemeCodeUnspsc190501.50591847': __('identifierSchemeCodeUnspsc190501.50591847'),
	'identifierSchemeCodeUnspsc190501.50591848': __('identifierSchemeCodeUnspsc190501.50591848'),
	'identifierSchemeCodeUnspsc190501.50591849': __('identifierSchemeCodeUnspsc190501.50591849'),
	'identifierSchemeCodeUnspsc190501.50591850': __('identifierSchemeCodeUnspsc190501.50591850'),
	'identifierSchemeCodeUnspsc190501.50591851': __('identifierSchemeCodeUnspsc190501.50591851'),
	'identifierSchemeCodeUnspsc190501.50591852': __('identifierSchemeCodeUnspsc190501.50591852'),
	'identifierSchemeCodeUnspsc190501.50591900': __('identifierSchemeCodeUnspsc190501.50591900'),
	'identifierSchemeCodeUnspsc190501.50591901': __('identifierSchemeCodeUnspsc190501.50591901'),
	'identifierSchemeCodeUnspsc190501.50591902': __('identifierSchemeCodeUnspsc190501.50591902'),
	'identifierSchemeCodeUnspsc190501.50591903': __('identifierSchemeCodeUnspsc190501.50591903'),
	'identifierSchemeCodeUnspsc190501.50591904': __('identifierSchemeCodeUnspsc190501.50591904'),
	'identifierSchemeCodeUnspsc190501.50591905': __('identifierSchemeCodeUnspsc190501.50591905'),
	'identifierSchemeCodeUnspsc190501.50591906': __('identifierSchemeCodeUnspsc190501.50591906'),
	'identifierSchemeCodeUnspsc190501.50591907': __('identifierSchemeCodeUnspsc190501.50591907'),
	'identifierSchemeCodeUnspsc190501.50591908': __('identifierSchemeCodeUnspsc190501.50591908'),
	'identifierSchemeCodeUnspsc190501.50591909': __('identifierSchemeCodeUnspsc190501.50591909'),
	'identifierSchemeCodeUnspsc190501.50591910': __('identifierSchemeCodeUnspsc190501.50591910'),
	'identifierSchemeCodeUnspsc190501.50591911': __('identifierSchemeCodeUnspsc190501.50591911'),
	'identifierSchemeCodeUnspsc190501.50591912': __('identifierSchemeCodeUnspsc190501.50591912'),
	'identifierSchemeCodeUnspsc190501.50591913': __('identifierSchemeCodeUnspsc190501.50591913'),
	'identifierSchemeCodeUnspsc190501.50591914': __('identifierSchemeCodeUnspsc190501.50591914'),
	'identifierSchemeCodeUnspsc190501.50591915': __('identifierSchemeCodeUnspsc190501.50591915'),
	'identifierSchemeCodeUnspsc190501.50591916': __('identifierSchemeCodeUnspsc190501.50591916'),
	'identifierSchemeCodeUnspsc190501.50591917': __('identifierSchemeCodeUnspsc190501.50591917'),
	'identifierSchemeCodeUnspsc190501.50591918': __('identifierSchemeCodeUnspsc190501.50591918'),
	'identifierSchemeCodeUnspsc190501.50591919': __('identifierSchemeCodeUnspsc190501.50591919'),
	'identifierSchemeCodeUnspsc190501.50591920': __('identifierSchemeCodeUnspsc190501.50591920'),
	'identifierSchemeCodeUnspsc190501.50591921': __('identifierSchemeCodeUnspsc190501.50591921'),
	'identifierSchemeCodeUnspsc190501.50591922': __('identifierSchemeCodeUnspsc190501.50591922'),
	'identifierSchemeCodeUnspsc190501.50592000': __('identifierSchemeCodeUnspsc190501.50592000'),
	'identifierSchemeCodeUnspsc190501.50592001': __('identifierSchemeCodeUnspsc190501.50592001'),
	'identifierSchemeCodeUnspsc190501.50592002': __('identifierSchemeCodeUnspsc190501.50592002'),
	'identifierSchemeCodeUnspsc190501.50592003': __('identifierSchemeCodeUnspsc190501.50592003'),
	'identifierSchemeCodeUnspsc190501.50592004': __('identifierSchemeCodeUnspsc190501.50592004'),
	'identifierSchemeCodeUnspsc190501.50592100': __('identifierSchemeCodeUnspsc190501.50592100'),
	'identifierSchemeCodeUnspsc190501.50592101': __('identifierSchemeCodeUnspsc190501.50592101'),
	'identifierSchemeCodeUnspsc190501.50592102': __('identifierSchemeCodeUnspsc190501.50592102'),
	'identifierSchemeCodeUnspsc190501.50592103': __('identifierSchemeCodeUnspsc190501.50592103'),
	'identifierSchemeCodeUnspsc190501.50592104': __('identifierSchemeCodeUnspsc190501.50592104'),
	'identifierSchemeCodeUnspsc190501.50592105': __('identifierSchemeCodeUnspsc190501.50592105'),
	'identifierSchemeCodeUnspsc190501.50592106': __('identifierSchemeCodeUnspsc190501.50592106'),
	'identifierSchemeCodeUnspsc190501.50592107': __('identifierSchemeCodeUnspsc190501.50592107'),
	'identifierSchemeCodeUnspsc190501.50592200': __('identifierSchemeCodeUnspsc190501.50592200'),
	'identifierSchemeCodeUnspsc190501.50592201': __('identifierSchemeCodeUnspsc190501.50592201'),
	'identifierSchemeCodeUnspsc190501.50592202': __('identifierSchemeCodeUnspsc190501.50592202'),
	'identifierSchemeCodeUnspsc190501.50592203': __('identifierSchemeCodeUnspsc190501.50592203'),
	'identifierSchemeCodeUnspsc190501.50592204': __('identifierSchemeCodeUnspsc190501.50592204'),
	'identifierSchemeCodeUnspsc190501.50592205': __('identifierSchemeCodeUnspsc190501.50592205'),
	'identifierSchemeCodeUnspsc190501.50592206': __('identifierSchemeCodeUnspsc190501.50592206'),
	'identifierSchemeCodeUnspsc190501.50592207': __('identifierSchemeCodeUnspsc190501.50592207'),
	'identifierSchemeCodeUnspsc190501.50592208': __('identifierSchemeCodeUnspsc190501.50592208'),
	'identifierSchemeCodeUnspsc190501.50592209': __('identifierSchemeCodeUnspsc190501.50592209'),
	'identifierSchemeCodeUnspsc190501.50592210': __('identifierSchemeCodeUnspsc190501.50592210'),
	'identifierSchemeCodeUnspsc190501.50592211': __('identifierSchemeCodeUnspsc190501.50592211'),
	'identifierSchemeCodeUnspsc190501.50592300': __('identifierSchemeCodeUnspsc190501.50592300'),
	'identifierSchemeCodeUnspsc190501.50592301': __('identifierSchemeCodeUnspsc190501.50592301'),
	'identifierSchemeCodeUnspsc190501.50592302': __('identifierSchemeCodeUnspsc190501.50592302'),
	'identifierSchemeCodeUnspsc190501.50592303': __('identifierSchemeCodeUnspsc190501.50592303'),
	'identifierSchemeCodeUnspsc190501.50592304': __('identifierSchemeCodeUnspsc190501.50592304'),
	'identifierSchemeCodeUnspsc190501.50592400': __('identifierSchemeCodeUnspsc190501.50592400'),
	'identifierSchemeCodeUnspsc190501.50592401': __('identifierSchemeCodeUnspsc190501.50592401'),
	'identifierSchemeCodeUnspsc190501.50592402': __('identifierSchemeCodeUnspsc190501.50592402'),
	'identifierSchemeCodeUnspsc190501.50592500': __('identifierSchemeCodeUnspsc190501.50592500'),
	'identifierSchemeCodeUnspsc190501.50592501': __('identifierSchemeCodeUnspsc190501.50592501'),
	'identifierSchemeCodeUnspsc190501.50592502': __('identifierSchemeCodeUnspsc190501.50592502'),
	'identifierSchemeCodeUnspsc190501.50592503': __('identifierSchemeCodeUnspsc190501.50592503'),
	'identifierSchemeCodeUnspsc190501.50592504': __('identifierSchemeCodeUnspsc190501.50592504'),
	'identifierSchemeCodeUnspsc190501.50592505': __('identifierSchemeCodeUnspsc190501.50592505'),
	'identifierSchemeCodeUnspsc190501.50592506': __('identifierSchemeCodeUnspsc190501.50592506'),
	'identifierSchemeCodeUnspsc190501.50592600': __('identifierSchemeCodeUnspsc190501.50592600'),
	'identifierSchemeCodeUnspsc190501.50592601': __('identifierSchemeCodeUnspsc190501.50592601'),
	'identifierSchemeCodeUnspsc190501.50592602': __('identifierSchemeCodeUnspsc190501.50592602'),
	'identifierSchemeCodeUnspsc190501.50592603': __('identifierSchemeCodeUnspsc190501.50592603'),
	'identifierSchemeCodeUnspsc190501.50592604': __('identifierSchemeCodeUnspsc190501.50592604'),
	'identifierSchemeCodeUnspsc190501.50592605': __('identifierSchemeCodeUnspsc190501.50592605'),
	'identifierSchemeCodeUnspsc190501.50592606': __('identifierSchemeCodeUnspsc190501.50592606'),
	'identifierSchemeCodeUnspsc190501.50592607': __('identifierSchemeCodeUnspsc190501.50592607'),
	'identifierSchemeCodeUnspsc190501.50592608': __('identifierSchemeCodeUnspsc190501.50592608'),
	'identifierSchemeCodeUnspsc190501.50592609': __('identifierSchemeCodeUnspsc190501.50592609'),
	'identifierSchemeCodeUnspsc190501.50592610': __('identifierSchemeCodeUnspsc190501.50592610'),
	'identifierSchemeCodeUnspsc190501.50592611': __('identifierSchemeCodeUnspsc190501.50592611'),
	'identifierSchemeCodeUnspsc190501.50592612': __('identifierSchemeCodeUnspsc190501.50592612'),
	'identifierSchemeCodeUnspsc190501.50592700': __('identifierSchemeCodeUnspsc190501.50592700'),
	'identifierSchemeCodeUnspsc190501.50592701': __('identifierSchemeCodeUnspsc190501.50592701'),
	'identifierSchemeCodeUnspsc190501.50592702': __('identifierSchemeCodeUnspsc190501.50592702'),
	'identifierSchemeCodeUnspsc190501.50592703': __('identifierSchemeCodeUnspsc190501.50592703'),
	'identifierSchemeCodeUnspsc190501.50592704': __('identifierSchemeCodeUnspsc190501.50592704'),
	'identifierSchemeCodeUnspsc190501.50592705': __('identifierSchemeCodeUnspsc190501.50592705'),
	'identifierSchemeCodeUnspsc190501.50592706': __('identifierSchemeCodeUnspsc190501.50592706'),
	'identifierSchemeCodeUnspsc190501.50592707': __('identifierSchemeCodeUnspsc190501.50592707'),
	'identifierSchemeCodeUnspsc190501.50592708': __('identifierSchemeCodeUnspsc190501.50592708'),
	'identifierSchemeCodeUnspsc190501.50592709': __('identifierSchemeCodeUnspsc190501.50592709'),
	'identifierSchemeCodeUnspsc190501.50592710': __('identifierSchemeCodeUnspsc190501.50592710'),
	'identifierSchemeCodeUnspsc190501.50592711': __('identifierSchemeCodeUnspsc190501.50592711'),
	'identifierSchemeCodeUnspsc190501.50592712': __('identifierSchemeCodeUnspsc190501.50592712'),
	'identifierSchemeCodeUnspsc190501.50592713': __('identifierSchemeCodeUnspsc190501.50592713'),
	'identifierSchemeCodeUnspsc190501.50592714': __('identifierSchemeCodeUnspsc190501.50592714'),
	'identifierSchemeCodeUnspsc190501.50592715': __('identifierSchemeCodeUnspsc190501.50592715'),
	'identifierSchemeCodeUnspsc190501.50592800': __('identifierSchemeCodeUnspsc190501.50592800'),
	'identifierSchemeCodeUnspsc190501.50592801': __('identifierSchemeCodeUnspsc190501.50592801'),
	'identifierSchemeCodeUnspsc190501.50592802': __('identifierSchemeCodeUnspsc190501.50592802'),
	'identifierSchemeCodeUnspsc190501.50592803': __('identifierSchemeCodeUnspsc190501.50592803'),
	'identifierSchemeCodeUnspsc190501.50592804': __('identifierSchemeCodeUnspsc190501.50592804'),
	'identifierSchemeCodeUnspsc190501.50592805': __('identifierSchemeCodeUnspsc190501.50592805'),
	'identifierSchemeCodeUnspsc190501.50592806': __('identifierSchemeCodeUnspsc190501.50592806'),
	'identifierSchemeCodeUnspsc190501.50592807': __('identifierSchemeCodeUnspsc190501.50592807'),
	'identifierSchemeCodeUnspsc190501.50592808': __('identifierSchemeCodeUnspsc190501.50592808'),
	'identifierSchemeCodeUnspsc190501.50592900': __('identifierSchemeCodeUnspsc190501.50592900'),
	'identifierSchemeCodeUnspsc190501.50592901': __('identifierSchemeCodeUnspsc190501.50592901'),
	'identifierSchemeCodeUnspsc190501.50592902': __('identifierSchemeCodeUnspsc190501.50592902'),
	'identifierSchemeCodeUnspsc190501.50592903': __('identifierSchemeCodeUnspsc190501.50592903'),
	'identifierSchemeCodeUnspsc190501.50592904': __('identifierSchemeCodeUnspsc190501.50592904'),
	'identifierSchemeCodeUnspsc190501.50592905': __('identifierSchemeCodeUnspsc190501.50592905'),
	'identifierSchemeCodeUnspsc190501.50592906': __('identifierSchemeCodeUnspsc190501.50592906'),
	'identifierSchemeCodeUnspsc190501.50592907': __('identifierSchemeCodeUnspsc190501.50592907'),
	'identifierSchemeCodeUnspsc190501.50592908': __('identifierSchemeCodeUnspsc190501.50592908'),
	'identifierSchemeCodeUnspsc190501.50592909': __('identifierSchemeCodeUnspsc190501.50592909'),
	'identifierSchemeCodeUnspsc190501.50592910': __('identifierSchemeCodeUnspsc190501.50592910'),
	'identifierSchemeCodeUnspsc190501.50592911': __('identifierSchemeCodeUnspsc190501.50592911'),
	'identifierSchemeCodeUnspsc190501.50593000': __('identifierSchemeCodeUnspsc190501.50593000'),
	'identifierSchemeCodeUnspsc190501.50593001': __('identifierSchemeCodeUnspsc190501.50593001'),
	'identifierSchemeCodeUnspsc190501.50593002': __('identifierSchemeCodeUnspsc190501.50593002'),
	'identifierSchemeCodeUnspsc190501.50593003': __('identifierSchemeCodeUnspsc190501.50593003'),
	'identifierSchemeCodeUnspsc190501.50593004': __('identifierSchemeCodeUnspsc190501.50593004'),
	'identifierSchemeCodeUnspsc190501.50593005': __('identifierSchemeCodeUnspsc190501.50593005'),
	'identifierSchemeCodeUnspsc190501.50593006': __('identifierSchemeCodeUnspsc190501.50593006'),
	'identifierSchemeCodeUnspsc190501.50593007': __('identifierSchemeCodeUnspsc190501.50593007'),
	'identifierSchemeCodeUnspsc190501.50593008': __('identifierSchemeCodeUnspsc190501.50593008'),
	'identifierSchemeCodeUnspsc190501.50593009': __('identifierSchemeCodeUnspsc190501.50593009'),
	'identifierSchemeCodeUnspsc190501.50593010': __('identifierSchemeCodeUnspsc190501.50593010'),
	'identifierSchemeCodeUnspsc190501.50593011': __('identifierSchemeCodeUnspsc190501.50593011'),
	'identifierSchemeCodeUnspsc190501.50593012': __('identifierSchemeCodeUnspsc190501.50593012'),
	'identifierSchemeCodeUnspsc190501.50593013': __('identifierSchemeCodeUnspsc190501.50593013'),
	'identifierSchemeCodeUnspsc190501.50593014': __('identifierSchemeCodeUnspsc190501.50593014'),
	'identifierSchemeCodeUnspsc190501.50593015': __('identifierSchemeCodeUnspsc190501.50593015'),
	'identifierSchemeCodeUnspsc190501.50593016': __('identifierSchemeCodeUnspsc190501.50593016'),
	'identifierSchemeCodeUnspsc190501.50593100': __('identifierSchemeCodeUnspsc190501.50593100'),
	'identifierSchemeCodeUnspsc190501.50593101': __('identifierSchemeCodeUnspsc190501.50593101'),
	'identifierSchemeCodeUnspsc190501.50593102': __('identifierSchemeCodeUnspsc190501.50593102'),
	'identifierSchemeCodeUnspsc190501.50593200': __('identifierSchemeCodeUnspsc190501.50593200'),
	'identifierSchemeCodeUnspsc190501.50593201': __('identifierSchemeCodeUnspsc190501.50593201'),
	'identifierSchemeCodeUnspsc190501.50593202': __('identifierSchemeCodeUnspsc190501.50593202'),
	'identifierSchemeCodeUnspsc190501.50593203': __('identifierSchemeCodeUnspsc190501.50593203'),
	'identifierSchemeCodeUnspsc190501.50593204': __('identifierSchemeCodeUnspsc190501.50593204'),
	'identifierSchemeCodeUnspsc190501.50593205': __('identifierSchemeCodeUnspsc190501.50593205'),
	'identifierSchemeCodeUnspsc190501.50593206': __('identifierSchemeCodeUnspsc190501.50593206'),
	'identifierSchemeCodeUnspsc190501.50593207': __('identifierSchemeCodeUnspsc190501.50593207'),
	'identifierSchemeCodeUnspsc190501.50593208': __('identifierSchemeCodeUnspsc190501.50593208'),
	'identifierSchemeCodeUnspsc190501.50593209': __('identifierSchemeCodeUnspsc190501.50593209'),
	'identifierSchemeCodeUnspsc190501.50593210': __('identifierSchemeCodeUnspsc190501.50593210'),
	'identifierSchemeCodeUnspsc190501.50593211': __('identifierSchemeCodeUnspsc190501.50593211'),
	'identifierSchemeCodeUnspsc190501.50593212': __('identifierSchemeCodeUnspsc190501.50593212'),
	'identifierSchemeCodeUnspsc190501.50593213': __('identifierSchemeCodeUnspsc190501.50593213'),
	'identifierSchemeCodeUnspsc190501.50593214': __('identifierSchemeCodeUnspsc190501.50593214'),
	'identifierSchemeCodeUnspsc190501.50593215': __('identifierSchemeCodeUnspsc190501.50593215'),
	'identifierSchemeCodeUnspsc190501.50593216': __('identifierSchemeCodeUnspsc190501.50593216'),
	'identifierSchemeCodeUnspsc190501.50593217': __('identifierSchemeCodeUnspsc190501.50593217'),
	'identifierSchemeCodeUnspsc190501.50593218': __('identifierSchemeCodeUnspsc190501.50593218'),
	'identifierSchemeCodeUnspsc190501.50593219': __('identifierSchemeCodeUnspsc190501.50593219'),
	'identifierSchemeCodeUnspsc190501.50593220': __('identifierSchemeCodeUnspsc190501.50593220'),
	'identifierSchemeCodeUnspsc190501.50593221': __('identifierSchemeCodeUnspsc190501.50593221'),
	'identifierSchemeCodeUnspsc190501.50593222': __('identifierSchemeCodeUnspsc190501.50593222'),
	'identifierSchemeCodeUnspsc190501.50593223': __('identifierSchemeCodeUnspsc190501.50593223'),
	'identifierSchemeCodeUnspsc190501.50593224': __('identifierSchemeCodeUnspsc190501.50593224'),
	'identifierSchemeCodeUnspsc190501.50593225': __('identifierSchemeCodeUnspsc190501.50593225'),
	'identifierSchemeCodeUnspsc190501.50593226': __('identifierSchemeCodeUnspsc190501.50593226'),
	'identifierSchemeCodeUnspsc190501.50593227': __('identifierSchemeCodeUnspsc190501.50593227'),
	'identifierSchemeCodeUnspsc190501.50593228': __('identifierSchemeCodeUnspsc190501.50593228'),
	'identifierSchemeCodeUnspsc190501.50593229': __('identifierSchemeCodeUnspsc190501.50593229'),
	'identifierSchemeCodeUnspsc190501.50593230': __('identifierSchemeCodeUnspsc190501.50593230'),
	'identifierSchemeCodeUnspsc190501.50593231': __('identifierSchemeCodeUnspsc190501.50593231'),
	'identifierSchemeCodeUnspsc190501.50593232': __('identifierSchemeCodeUnspsc190501.50593232'),
	'identifierSchemeCodeUnspsc190501.50593233': __('identifierSchemeCodeUnspsc190501.50593233'),
	'identifierSchemeCodeUnspsc190501.50593234': __('identifierSchemeCodeUnspsc190501.50593234'),
	'identifierSchemeCodeUnspsc190501.50593235': __('identifierSchemeCodeUnspsc190501.50593235'),
	'identifierSchemeCodeUnspsc190501.50593236': __('identifierSchemeCodeUnspsc190501.50593236'),
	'identifierSchemeCodeUnspsc190501.50593237': __('identifierSchemeCodeUnspsc190501.50593237'),
	'identifierSchemeCodeUnspsc190501.50593238': __('identifierSchemeCodeUnspsc190501.50593238'),
	'identifierSchemeCodeUnspsc190501.50593239': __('identifierSchemeCodeUnspsc190501.50593239'),
	'identifierSchemeCodeUnspsc190501.50593240': __('identifierSchemeCodeUnspsc190501.50593240'),
	'identifierSchemeCodeUnspsc190501.50593241': __('identifierSchemeCodeUnspsc190501.50593241'),
	'identifierSchemeCodeUnspsc190501.50593242': __('identifierSchemeCodeUnspsc190501.50593242'),
	'identifierSchemeCodeUnspsc190501.50593243': __('identifierSchemeCodeUnspsc190501.50593243'),
	'identifierSchemeCodeUnspsc190501.50593244': __('identifierSchemeCodeUnspsc190501.50593244'),
	'identifierSchemeCodeUnspsc190501.50593245': __('identifierSchemeCodeUnspsc190501.50593245'),
	'identifierSchemeCodeUnspsc190501.50593246': __('identifierSchemeCodeUnspsc190501.50593246'),
	'identifierSchemeCodeUnspsc190501.50593247': __('identifierSchemeCodeUnspsc190501.50593247'),
	'identifierSchemeCodeUnspsc190501.50593248': __('identifierSchemeCodeUnspsc190501.50593248'),
	'identifierSchemeCodeUnspsc190501.50593249': __('identifierSchemeCodeUnspsc190501.50593249'),
	'identifierSchemeCodeUnspsc190501.50593250': __('identifierSchemeCodeUnspsc190501.50593250'),
	'identifierSchemeCodeUnspsc190501.50593251': __('identifierSchemeCodeUnspsc190501.50593251'),
	'identifierSchemeCodeUnspsc190501.50593252': __('identifierSchemeCodeUnspsc190501.50593252'),
	'identifierSchemeCodeUnspsc190501.50593253': __('identifierSchemeCodeUnspsc190501.50593253'),
	'identifierSchemeCodeUnspsc190501.50593254': __('identifierSchemeCodeUnspsc190501.50593254'),
	'identifierSchemeCodeUnspsc190501.50593255': __('identifierSchemeCodeUnspsc190501.50593255'),
	'identifierSchemeCodeUnspsc190501.50593256': __('identifierSchemeCodeUnspsc190501.50593256'),
	'identifierSchemeCodeUnspsc190501.50593257': __('identifierSchemeCodeUnspsc190501.50593257'),
	'identifierSchemeCodeUnspsc190501.50593258': __('identifierSchemeCodeUnspsc190501.50593258'),
	'identifierSchemeCodeUnspsc190501.50593259': __('identifierSchemeCodeUnspsc190501.50593259'),
	'identifierSchemeCodeUnspsc190501.50593260': __('identifierSchemeCodeUnspsc190501.50593260'),
	'identifierSchemeCodeUnspsc190501.50593261': __('identifierSchemeCodeUnspsc190501.50593261'),
	'identifierSchemeCodeUnspsc190501.50593262': __('identifierSchemeCodeUnspsc190501.50593262'),
	'identifierSchemeCodeUnspsc190501.50593263': __('identifierSchemeCodeUnspsc190501.50593263'),
	'identifierSchemeCodeUnspsc190501.50593264': __('identifierSchemeCodeUnspsc190501.50593264'),
	'identifierSchemeCodeUnspsc190501.50593265': __('identifierSchemeCodeUnspsc190501.50593265'),
	'identifierSchemeCodeUnspsc190501.50593266': __('identifierSchemeCodeUnspsc190501.50593266'),
	'identifierSchemeCodeUnspsc190501.50593267': __('identifierSchemeCodeUnspsc190501.50593267'),
	'identifierSchemeCodeUnspsc190501.50593268': __('identifierSchemeCodeUnspsc190501.50593268'),
	'identifierSchemeCodeUnspsc190501.50593269': __('identifierSchemeCodeUnspsc190501.50593269'),
	'identifierSchemeCodeUnspsc190501.50593300': __('identifierSchemeCodeUnspsc190501.50593300'),
	'identifierSchemeCodeUnspsc190501.50593301': __('identifierSchemeCodeUnspsc190501.50593301'),
	'identifierSchemeCodeUnspsc190501.50593302': __('identifierSchemeCodeUnspsc190501.50593302'),
	'identifierSchemeCodeUnspsc190501.50593303': __('identifierSchemeCodeUnspsc190501.50593303'),
	'identifierSchemeCodeUnspsc190501.50593304': __('identifierSchemeCodeUnspsc190501.50593304'),
	'identifierSchemeCodeUnspsc190501.50593400': __('identifierSchemeCodeUnspsc190501.50593400'),
	'identifierSchemeCodeUnspsc190501.50593401': __('identifierSchemeCodeUnspsc190501.50593401'),
	'identifierSchemeCodeUnspsc190501.50593402': __('identifierSchemeCodeUnspsc190501.50593402'),
	'identifierSchemeCodeUnspsc190501.50593403': __('identifierSchemeCodeUnspsc190501.50593403'),
	'identifierSchemeCodeUnspsc190501.50593404': __('identifierSchemeCodeUnspsc190501.50593404'),
	'identifierSchemeCodeUnspsc190501.50593405': __('identifierSchemeCodeUnspsc190501.50593405'),
	'identifierSchemeCodeUnspsc190501.50593406': __('identifierSchemeCodeUnspsc190501.50593406'),
	'identifierSchemeCodeUnspsc190501.50593407': __('identifierSchemeCodeUnspsc190501.50593407'),
	'identifierSchemeCodeUnspsc190501.50593408': __('identifierSchemeCodeUnspsc190501.50593408'),
	'identifierSchemeCodeUnspsc190501.50593409': __('identifierSchemeCodeUnspsc190501.50593409'),
	'identifierSchemeCodeUnspsc190501.50593410': __('identifierSchemeCodeUnspsc190501.50593410'),
	'identifierSchemeCodeUnspsc190501.50593411': __('identifierSchemeCodeUnspsc190501.50593411'),
	'identifierSchemeCodeUnspsc190501.50593412': __('identifierSchemeCodeUnspsc190501.50593412'),
	'identifierSchemeCodeUnspsc190501.50593413': __('identifierSchemeCodeUnspsc190501.50593413'),
	'identifierSchemeCodeUnspsc190501.50593414': __('identifierSchemeCodeUnspsc190501.50593414'),
	'identifierSchemeCodeUnspsc190501.50593415': __('identifierSchemeCodeUnspsc190501.50593415'),
	'identifierSchemeCodeUnspsc190501.50593416': __('identifierSchemeCodeUnspsc190501.50593416'),
	'identifierSchemeCodeUnspsc190501.50593417': __('identifierSchemeCodeUnspsc190501.50593417'),
	'identifierSchemeCodeUnspsc190501.50593418': __('identifierSchemeCodeUnspsc190501.50593418'),
	'identifierSchemeCodeUnspsc190501.50593419': __('identifierSchemeCodeUnspsc190501.50593419'),
	'identifierSchemeCodeUnspsc190501.50593420': __('identifierSchemeCodeUnspsc190501.50593420'),
	'identifierSchemeCodeUnspsc190501.50593421': __('identifierSchemeCodeUnspsc190501.50593421'),
	'identifierSchemeCodeUnspsc190501.50593422': __('identifierSchemeCodeUnspsc190501.50593422'),
	'identifierSchemeCodeUnspsc190501.50593423': __('identifierSchemeCodeUnspsc190501.50593423'),
	'identifierSchemeCodeUnspsc190501.50593500': __('identifierSchemeCodeUnspsc190501.50593500'),
	'identifierSchemeCodeUnspsc190501.50593501': __('identifierSchemeCodeUnspsc190501.50593501'),
	'identifierSchemeCodeUnspsc190501.50593502': __('identifierSchemeCodeUnspsc190501.50593502'),
	'identifierSchemeCodeUnspsc190501.50593503': __('identifierSchemeCodeUnspsc190501.50593503'),
	'identifierSchemeCodeUnspsc190501.50593504': __('identifierSchemeCodeUnspsc190501.50593504'),
	'identifierSchemeCodeUnspsc190501.50593505': __('identifierSchemeCodeUnspsc190501.50593505'),
	'identifierSchemeCodeUnspsc190501.50593506': __('identifierSchemeCodeUnspsc190501.50593506'),
	'identifierSchemeCodeUnspsc190501.50593507': __('identifierSchemeCodeUnspsc190501.50593507'),
	'identifierSchemeCodeUnspsc190501.50593508': __('identifierSchemeCodeUnspsc190501.50593508'),
	'identifierSchemeCodeUnspsc190501.50593509': __('identifierSchemeCodeUnspsc190501.50593509'),
	'identifierSchemeCodeUnspsc190501.50593510': __('identifierSchemeCodeUnspsc190501.50593510'),
	'identifierSchemeCodeUnspsc190501.50593511': __('identifierSchemeCodeUnspsc190501.50593511'),
	'identifierSchemeCodeUnspsc190501.50593512': __('identifierSchemeCodeUnspsc190501.50593512'),
	'identifierSchemeCodeUnspsc190501.50593513': __('identifierSchemeCodeUnspsc190501.50593513'),
	'identifierSchemeCodeUnspsc190501.50593514': __('identifierSchemeCodeUnspsc190501.50593514'),
	'identifierSchemeCodeUnspsc190501.50593515': __('identifierSchemeCodeUnspsc190501.50593515'),
	'identifierSchemeCodeUnspsc190501.50593516': __('identifierSchemeCodeUnspsc190501.50593516'),
	'identifierSchemeCodeUnspsc190501.50593517': __('identifierSchemeCodeUnspsc190501.50593517'),
	'identifierSchemeCodeUnspsc190501.50593518': __('identifierSchemeCodeUnspsc190501.50593518'),
	'identifierSchemeCodeUnspsc190501.50593519': __('identifierSchemeCodeUnspsc190501.50593519'),
	'identifierSchemeCodeUnspsc190501.50593520': __('identifierSchemeCodeUnspsc190501.50593520'),
	'identifierSchemeCodeUnspsc190501.50593600': __('identifierSchemeCodeUnspsc190501.50593600'),
	'identifierSchemeCodeUnspsc190501.50593601': __('identifierSchemeCodeUnspsc190501.50593601'),
	'identifierSchemeCodeUnspsc190501.50593602': __('identifierSchemeCodeUnspsc190501.50593602'),
	'identifierSchemeCodeUnspsc190501.50593603': __('identifierSchemeCodeUnspsc190501.50593603'),
	'identifierSchemeCodeUnspsc190501.50593604': __('identifierSchemeCodeUnspsc190501.50593604'),
	'identifierSchemeCodeUnspsc190501.50593605': __('identifierSchemeCodeUnspsc190501.50593605'),
	'identifierSchemeCodeUnspsc190501.50593606': __('identifierSchemeCodeUnspsc190501.50593606'),
	'identifierSchemeCodeUnspsc190501.50593607': __('identifierSchemeCodeUnspsc190501.50593607'),
	'identifierSchemeCodeUnspsc190501.50593608': __('identifierSchemeCodeUnspsc190501.50593608'),
	'identifierSchemeCodeUnspsc190501.50593609': __('identifierSchemeCodeUnspsc190501.50593609'),
	'identifierSchemeCodeUnspsc190501.50593610': __('identifierSchemeCodeUnspsc190501.50593610'),
	'identifierSchemeCodeUnspsc190501.50593611': __('identifierSchemeCodeUnspsc190501.50593611'),
	'identifierSchemeCodeUnspsc190501.50593612': __('identifierSchemeCodeUnspsc190501.50593612'),
	'identifierSchemeCodeUnspsc190501.50593613': __('identifierSchemeCodeUnspsc190501.50593613'),
	'identifierSchemeCodeUnspsc190501.50593614': __('identifierSchemeCodeUnspsc190501.50593614'),
	'identifierSchemeCodeUnspsc190501.50593700': __('identifierSchemeCodeUnspsc190501.50593700'),
	'identifierSchemeCodeUnspsc190501.50593701': __('identifierSchemeCodeUnspsc190501.50593701'),
	'identifierSchemeCodeUnspsc190501.50593702': __('identifierSchemeCodeUnspsc190501.50593702'),
	'identifierSchemeCodeUnspsc190501.50593703': __('identifierSchemeCodeUnspsc190501.50593703'),
	'identifierSchemeCodeUnspsc190501.50593704': __('identifierSchemeCodeUnspsc190501.50593704'),
	'identifierSchemeCodeUnspsc190501.50593705': __('identifierSchemeCodeUnspsc190501.50593705'),
	'identifierSchemeCodeUnspsc190501.50593706': __('identifierSchemeCodeUnspsc190501.50593706'),
	'identifierSchemeCodeUnspsc190501.50593707': __('identifierSchemeCodeUnspsc190501.50593707'),
	'identifierSchemeCodeUnspsc190501.50593800': __('identifierSchemeCodeUnspsc190501.50593800'),
	'identifierSchemeCodeUnspsc190501.50593801': __('identifierSchemeCodeUnspsc190501.50593801'),
	'identifierSchemeCodeUnspsc190501.50593802': __('identifierSchemeCodeUnspsc190501.50593802'),
	'identifierSchemeCodeUnspsc190501.50593803': __('identifierSchemeCodeUnspsc190501.50593803'),
	'identifierSchemeCodeUnspsc190501.50593804': __('identifierSchemeCodeUnspsc190501.50593804'),
	'identifierSchemeCodeUnspsc190501.50593805': __('identifierSchemeCodeUnspsc190501.50593805'),
	'identifierSchemeCodeUnspsc190501.50593806': __('identifierSchemeCodeUnspsc190501.50593806'),
	'identifierSchemeCodeUnspsc190501.50593807': __('identifierSchemeCodeUnspsc190501.50593807'),
	'identifierSchemeCodeUnspsc190501.50593808': __('identifierSchemeCodeUnspsc190501.50593808'),
	'identifierSchemeCodeUnspsc190501.50593809': __('identifierSchemeCodeUnspsc190501.50593809'),
	'identifierSchemeCodeUnspsc190501.50593810': __('identifierSchemeCodeUnspsc190501.50593810'),
	'identifierSchemeCodeUnspsc190501.50593811': __('identifierSchemeCodeUnspsc190501.50593811'),
	'identifierSchemeCodeUnspsc190501.50593900': __('identifierSchemeCodeUnspsc190501.50593900'),
	'identifierSchemeCodeUnspsc190501.50593901': __('identifierSchemeCodeUnspsc190501.50593901'),
	'identifierSchemeCodeUnspsc190501.50593902': __('identifierSchemeCodeUnspsc190501.50593902'),
	'identifierSchemeCodeUnspsc190501.50593903': __('identifierSchemeCodeUnspsc190501.50593903'),
	'identifierSchemeCodeUnspsc190501.50593904': __('identifierSchemeCodeUnspsc190501.50593904'),
	'identifierSchemeCodeUnspsc190501.50593905': __('identifierSchemeCodeUnspsc190501.50593905'),
	'identifierSchemeCodeUnspsc190501.50593906': __('identifierSchemeCodeUnspsc190501.50593906'),
	'identifierSchemeCodeUnspsc190501.50593907': __('identifierSchemeCodeUnspsc190501.50593907'),
	'identifierSchemeCodeUnspsc190501.50593908': __('identifierSchemeCodeUnspsc190501.50593908'),
	'identifierSchemeCodeUnspsc190501.50593909': __('identifierSchemeCodeUnspsc190501.50593909'),
	'identifierSchemeCodeUnspsc190501.50593910': __('identifierSchemeCodeUnspsc190501.50593910'),
	'identifierSchemeCodeUnspsc190501.50593911': __('identifierSchemeCodeUnspsc190501.50593911'),
	'identifierSchemeCodeUnspsc190501.50594000': __('identifierSchemeCodeUnspsc190501.50594000'),
	'identifierSchemeCodeUnspsc190501.50594001': __('identifierSchemeCodeUnspsc190501.50594001'),
	'identifierSchemeCodeUnspsc190501.50594002': __('identifierSchemeCodeUnspsc190501.50594002'),
	'identifierSchemeCodeUnspsc190501.50594003': __('identifierSchemeCodeUnspsc190501.50594003'),
	'identifierSchemeCodeUnspsc190501.50594004': __('identifierSchemeCodeUnspsc190501.50594004'),
	'identifierSchemeCodeUnspsc190501.50594005': __('identifierSchemeCodeUnspsc190501.50594005'),
	'identifierSchemeCodeUnspsc190501.50594100': __('identifierSchemeCodeUnspsc190501.50594100'),
	'identifierSchemeCodeUnspsc190501.50594101': __('identifierSchemeCodeUnspsc190501.50594101'),
	'identifierSchemeCodeUnspsc190501.50594102': __('identifierSchemeCodeUnspsc190501.50594102'),
	'identifierSchemeCodeUnspsc190501.50594103': __('identifierSchemeCodeUnspsc190501.50594103'),
	'identifierSchemeCodeUnspsc190501.50594104': __('identifierSchemeCodeUnspsc190501.50594104'),
	'identifierSchemeCodeUnspsc190501.50594105': __('identifierSchemeCodeUnspsc190501.50594105'),
	'identifierSchemeCodeUnspsc190501.50594106': __('identifierSchemeCodeUnspsc190501.50594106'),
	'identifierSchemeCodeUnspsc190501.50594107': __('identifierSchemeCodeUnspsc190501.50594107'),
	'identifierSchemeCodeUnspsc190501.50594108': __('identifierSchemeCodeUnspsc190501.50594108'),
	'identifierSchemeCodeUnspsc190501.50594109': __('identifierSchemeCodeUnspsc190501.50594109'),
	'identifierSchemeCodeUnspsc190501.50594110': __('identifierSchemeCodeUnspsc190501.50594110'),
	'identifierSchemeCodeUnspsc190501.50594111': __('identifierSchemeCodeUnspsc190501.50594111'),
	'identifierSchemeCodeUnspsc190501.50594112': __('identifierSchemeCodeUnspsc190501.50594112'),
	'identifierSchemeCodeUnspsc190501.50594113': __('identifierSchemeCodeUnspsc190501.50594113'),
	'identifierSchemeCodeUnspsc190501.50594114': __('identifierSchemeCodeUnspsc190501.50594114'),
	'identifierSchemeCodeUnspsc190501.50594115': __('identifierSchemeCodeUnspsc190501.50594115'),
	'identifierSchemeCodeUnspsc190501.50594116': __('identifierSchemeCodeUnspsc190501.50594116'),
	'identifierSchemeCodeUnspsc190501.50594117': __('identifierSchemeCodeUnspsc190501.50594117'),
	'identifierSchemeCodeUnspsc190501.50594118': __('identifierSchemeCodeUnspsc190501.50594118'),
	'identifierSchemeCodeUnspsc190501.50594119': __('identifierSchemeCodeUnspsc190501.50594119'),
	'identifierSchemeCodeUnspsc190501.50594120': __('identifierSchemeCodeUnspsc190501.50594120'),
	'identifierSchemeCodeUnspsc190501.50594121': __('identifierSchemeCodeUnspsc190501.50594121'),
	'identifierSchemeCodeUnspsc190501.50594122': __('identifierSchemeCodeUnspsc190501.50594122'),
	'identifierSchemeCodeUnspsc190501.50594123': __('identifierSchemeCodeUnspsc190501.50594123'),
	'identifierSchemeCodeUnspsc190501.50594124': __('identifierSchemeCodeUnspsc190501.50594124'),
	'identifierSchemeCodeUnspsc190501.50594125': __('identifierSchemeCodeUnspsc190501.50594125'),
	'identifierSchemeCodeUnspsc190501.50594126': __('identifierSchemeCodeUnspsc190501.50594126'),
	'identifierSchemeCodeUnspsc190501.50594127': __('identifierSchemeCodeUnspsc190501.50594127'),
	'identifierSchemeCodeUnspsc190501.50594200': __('identifierSchemeCodeUnspsc190501.50594200'),
	'identifierSchemeCodeUnspsc190501.50594201': __('identifierSchemeCodeUnspsc190501.50594201'),
	'identifierSchemeCodeUnspsc190501.50594202': __('identifierSchemeCodeUnspsc190501.50594202'),
	'identifierSchemeCodeUnspsc190501.50594300': __('identifierSchemeCodeUnspsc190501.50594300'),
	'identifierSchemeCodeUnspsc190501.50594301': __('identifierSchemeCodeUnspsc190501.50594301'),
	'identifierSchemeCodeUnspsc190501.50594302': __('identifierSchemeCodeUnspsc190501.50594302'),
	'identifierSchemeCodeUnspsc190501.50594303': __('identifierSchemeCodeUnspsc190501.50594303'),
	'identifierSchemeCodeUnspsc190501.50594304': __('identifierSchemeCodeUnspsc190501.50594304'),
	'identifierSchemeCodeUnspsc190501.50594305': __('identifierSchemeCodeUnspsc190501.50594305'),
	'identifierSchemeCodeUnspsc190501.50594306': __('identifierSchemeCodeUnspsc190501.50594306'),
	'identifierSchemeCodeUnspsc190501.50594400': __('identifierSchemeCodeUnspsc190501.50594400'),
	'identifierSchemeCodeUnspsc190501.50594401': __('identifierSchemeCodeUnspsc190501.50594401'),
	'identifierSchemeCodeUnspsc190501.50594402': __('identifierSchemeCodeUnspsc190501.50594402'),
	'identifierSchemeCodeUnspsc190501.50594403': __('identifierSchemeCodeUnspsc190501.50594403'),
	'identifierSchemeCodeUnspsc190501.50594404': __('identifierSchemeCodeUnspsc190501.50594404'),
	'identifierSchemeCodeUnspsc190501.50594405': __('identifierSchemeCodeUnspsc190501.50594405'),
	'identifierSchemeCodeUnspsc190501.50594406': __('identifierSchemeCodeUnspsc190501.50594406'),
	'identifierSchemeCodeUnspsc190501.50594500': __('identifierSchemeCodeUnspsc190501.50594500'),
	'identifierSchemeCodeUnspsc190501.50594501': __('identifierSchemeCodeUnspsc190501.50594501'),
	'identifierSchemeCodeUnspsc190501.50594502': __('identifierSchemeCodeUnspsc190501.50594502'),
	'identifierSchemeCodeUnspsc190501.50594503': __('identifierSchemeCodeUnspsc190501.50594503'),
	'identifierSchemeCodeUnspsc190501.50594504': __('identifierSchemeCodeUnspsc190501.50594504'),
	'identifierSchemeCodeUnspsc190501.50594505': __('identifierSchemeCodeUnspsc190501.50594505'),
	'identifierSchemeCodeUnspsc190501.50594506': __('identifierSchemeCodeUnspsc190501.50594506'),
	'identifierSchemeCodeUnspsc190501.50594507': __('identifierSchemeCodeUnspsc190501.50594507'),
	'identifierSchemeCodeUnspsc190501.50594508': __('identifierSchemeCodeUnspsc190501.50594508'),
	'identifierSchemeCodeUnspsc190501.50594600': __('identifierSchemeCodeUnspsc190501.50594600'),
	'identifierSchemeCodeUnspsc190501.50594601': __('identifierSchemeCodeUnspsc190501.50594601'),
	'identifierSchemeCodeUnspsc190501.50594602': __('identifierSchemeCodeUnspsc190501.50594602'),
	'identifierSchemeCodeUnspsc190501.50594603': __('identifierSchemeCodeUnspsc190501.50594603'),
	'identifierSchemeCodeUnspsc190501.50594604': __('identifierSchemeCodeUnspsc190501.50594604'),
	'identifierSchemeCodeUnspsc190501.50594605': __('identifierSchemeCodeUnspsc190501.50594605'),
	'identifierSchemeCodeUnspsc190501.50594606': __('identifierSchemeCodeUnspsc190501.50594606'),
	'identifierSchemeCodeUnspsc190501.50594607': __('identifierSchemeCodeUnspsc190501.50594607'),
	'identifierSchemeCodeUnspsc190501.50594608': __('identifierSchemeCodeUnspsc190501.50594608'),
	'identifierSchemeCodeUnspsc190501.50594609': __('identifierSchemeCodeUnspsc190501.50594609'),
	'identifierSchemeCodeUnspsc190501.50594610': __('identifierSchemeCodeUnspsc190501.50594610'),
	'identifierSchemeCodeUnspsc190501.50594611': __('identifierSchemeCodeUnspsc190501.50594611'),
	'identifierSchemeCodeUnspsc190501.50594612': __('identifierSchemeCodeUnspsc190501.50594612'),
	'identifierSchemeCodeUnspsc190501.50594613': __('identifierSchemeCodeUnspsc190501.50594613'),
	'identifierSchemeCodeUnspsc190501.50594614': __('identifierSchemeCodeUnspsc190501.50594614'),
	'identifierSchemeCodeUnspsc190501.50594615': __('identifierSchemeCodeUnspsc190501.50594615'),
	'identifierSchemeCodeUnspsc190501.50594616': __('identifierSchemeCodeUnspsc190501.50594616'),
	'identifierSchemeCodeUnspsc190501.50594617': __('identifierSchemeCodeUnspsc190501.50594617'),
	'identifierSchemeCodeUnspsc190501.50594618': __('identifierSchemeCodeUnspsc190501.50594618'),
	'identifierSchemeCodeUnspsc190501.50594619': __('identifierSchemeCodeUnspsc190501.50594619'),
	'identifierSchemeCodeUnspsc190501.50594620': __('identifierSchemeCodeUnspsc190501.50594620'),
	'identifierSchemeCodeUnspsc190501.50594621': __('identifierSchemeCodeUnspsc190501.50594621'),
	'identifierSchemeCodeUnspsc190501.50594622': __('identifierSchemeCodeUnspsc190501.50594622'),
	'identifierSchemeCodeUnspsc190501.50594623': __('identifierSchemeCodeUnspsc190501.50594623'),
	'identifierSchemeCodeUnspsc190501.50594700': __('identifierSchemeCodeUnspsc190501.50594700'),
	'identifierSchemeCodeUnspsc190501.50594701': __('identifierSchemeCodeUnspsc190501.50594701'),
	'identifierSchemeCodeUnspsc190501.50594702': __('identifierSchemeCodeUnspsc190501.50594702'),
	'identifierSchemeCodeUnspsc190501.50594703': __('identifierSchemeCodeUnspsc190501.50594703'),
	'identifierSchemeCodeUnspsc190501.50594704': __('identifierSchemeCodeUnspsc190501.50594704'),
	'identifierSchemeCodeUnspsc190501.50594705': __('identifierSchemeCodeUnspsc190501.50594705'),
	'identifierSchemeCodeUnspsc190501.50594706': __('identifierSchemeCodeUnspsc190501.50594706'),
	'identifierSchemeCodeUnspsc190501.50594800': __('identifierSchemeCodeUnspsc190501.50594800'),
	'identifierSchemeCodeUnspsc190501.50594801': __('identifierSchemeCodeUnspsc190501.50594801'),
	'identifierSchemeCodeUnspsc190501.50594802': __('identifierSchemeCodeUnspsc190501.50594802'),
	'identifierSchemeCodeUnspsc190501.50594803': __('identifierSchemeCodeUnspsc190501.50594803'),
	'identifierSchemeCodeUnspsc190501.50594804': __('identifierSchemeCodeUnspsc190501.50594804'),
	'identifierSchemeCodeUnspsc190501.50594805': __('identifierSchemeCodeUnspsc190501.50594805'),
	'identifierSchemeCodeUnspsc190501.50594806': __('identifierSchemeCodeUnspsc190501.50594806'),
	'identifierSchemeCodeUnspsc190501.50594807': __('identifierSchemeCodeUnspsc190501.50594807'),
	'identifierSchemeCodeUnspsc190501.50594808': __('identifierSchemeCodeUnspsc190501.50594808'),
	'identifierSchemeCodeUnspsc190501.50594809': __('identifierSchemeCodeUnspsc190501.50594809'),
	'identifierSchemeCodeUnspsc190501.50594810': __('identifierSchemeCodeUnspsc190501.50594810'),
	'identifierSchemeCodeUnspsc190501.50594811': __('identifierSchemeCodeUnspsc190501.50594811'),
	'identifierSchemeCodeUnspsc190501.50594812': __('identifierSchemeCodeUnspsc190501.50594812'),
	'identifierSchemeCodeUnspsc190501.50594813': __('identifierSchemeCodeUnspsc190501.50594813'),
	'identifierSchemeCodeUnspsc190501.50594814': __('identifierSchemeCodeUnspsc190501.50594814'),
	'identifierSchemeCodeUnspsc190501.50594815': __('identifierSchemeCodeUnspsc190501.50594815'),
	'identifierSchemeCodeUnspsc190501.50594816': __('identifierSchemeCodeUnspsc190501.50594816'),
	'identifierSchemeCodeUnspsc190501.50594817': __('identifierSchemeCodeUnspsc190501.50594817'),
	'identifierSchemeCodeUnspsc190501.50594818': __('identifierSchemeCodeUnspsc190501.50594818'),
	'identifierSchemeCodeUnspsc190501.50594819': __('identifierSchemeCodeUnspsc190501.50594819'),
	'identifierSchemeCodeUnspsc190501.50594820': __('identifierSchemeCodeUnspsc190501.50594820'),
	'identifierSchemeCodeUnspsc190501.50594821': __('identifierSchemeCodeUnspsc190501.50594821'),
	'identifierSchemeCodeUnspsc190501.50594900': __('identifierSchemeCodeUnspsc190501.50594900'),
	'identifierSchemeCodeUnspsc190501.50594901': __('identifierSchemeCodeUnspsc190501.50594901'),
	'identifierSchemeCodeUnspsc190501.50594902': __('identifierSchemeCodeUnspsc190501.50594902'),
	'identifierSchemeCodeUnspsc190501.50594903': __('identifierSchemeCodeUnspsc190501.50594903'),
	'identifierSchemeCodeUnspsc190501.50594904': __('identifierSchemeCodeUnspsc190501.50594904'),
	'identifierSchemeCodeUnspsc190501.50594905': __('identifierSchemeCodeUnspsc190501.50594905'),
	'identifierSchemeCodeUnspsc190501.50594906': __('identifierSchemeCodeUnspsc190501.50594906'),
	'identifierSchemeCodeUnspsc190501.50595000': __('identifierSchemeCodeUnspsc190501.50595000'),
	'identifierSchemeCodeUnspsc190501.50595001': __('identifierSchemeCodeUnspsc190501.50595001'),
	'identifierSchemeCodeUnspsc190501.50595002': __('identifierSchemeCodeUnspsc190501.50595002'),
	'identifierSchemeCodeUnspsc190501.50595003': __('identifierSchemeCodeUnspsc190501.50595003'),
	'identifierSchemeCodeUnspsc190501.50595004': __('identifierSchemeCodeUnspsc190501.50595004'),
	'identifierSchemeCodeUnspsc190501.50595100': __('identifierSchemeCodeUnspsc190501.50595100'),
	'identifierSchemeCodeUnspsc190501.50595101': __('identifierSchemeCodeUnspsc190501.50595101'),
	'identifierSchemeCodeUnspsc190501.50595102': __('identifierSchemeCodeUnspsc190501.50595102'),
	'identifierSchemeCodeUnspsc190501.50595103': __('identifierSchemeCodeUnspsc190501.50595103'),
	'identifierSchemeCodeUnspsc190501.50595104': __('identifierSchemeCodeUnspsc190501.50595104'),
	'identifierSchemeCodeUnspsc190501.50595105': __('identifierSchemeCodeUnspsc190501.50595105'),
	'identifierSchemeCodeUnspsc190501.50595106': __('identifierSchemeCodeUnspsc190501.50595106'),
	'identifierSchemeCodeUnspsc190501.50595107': __('identifierSchemeCodeUnspsc190501.50595107'),
	'identifierSchemeCodeUnspsc190501.50595200': __('identifierSchemeCodeUnspsc190501.50595200'),
	'identifierSchemeCodeUnspsc190501.50595201': __('identifierSchemeCodeUnspsc190501.50595201'),
	'identifierSchemeCodeUnspsc190501.50595202': __('identifierSchemeCodeUnspsc190501.50595202'),
	'identifierSchemeCodeUnspsc190501.50595203': __('identifierSchemeCodeUnspsc190501.50595203'),
	'identifierSchemeCodeUnspsc190501.50595204': __('identifierSchemeCodeUnspsc190501.50595204'),
	'identifierSchemeCodeUnspsc190501.50595205': __('identifierSchemeCodeUnspsc190501.50595205'),
	'identifierSchemeCodeUnspsc190501.50595206': __('identifierSchemeCodeUnspsc190501.50595206'),
	'identifierSchemeCodeUnspsc190501.50595207': __('identifierSchemeCodeUnspsc190501.50595207'),
	'identifierSchemeCodeUnspsc190501.50595208': __('identifierSchemeCodeUnspsc190501.50595208'),
	'identifierSchemeCodeUnspsc190501.50595209': __('identifierSchemeCodeUnspsc190501.50595209'),
	'identifierSchemeCodeUnspsc190501.50595210': __('identifierSchemeCodeUnspsc190501.50595210'),
	'identifierSchemeCodeUnspsc190501.50595211': __('identifierSchemeCodeUnspsc190501.50595211'),
	'identifierSchemeCodeUnspsc190501.50595212': __('identifierSchemeCodeUnspsc190501.50595212'),
	'identifierSchemeCodeUnspsc190501.50595213': __('identifierSchemeCodeUnspsc190501.50595213'),
	'identifierSchemeCodeUnspsc190501.50595214': __('identifierSchemeCodeUnspsc190501.50595214'),
	'identifierSchemeCodeUnspsc190501.50595215': __('identifierSchemeCodeUnspsc190501.50595215'),
	'identifierSchemeCodeUnspsc190501.50595216': __('identifierSchemeCodeUnspsc190501.50595216'),
	'identifierSchemeCodeUnspsc190501.50595217': __('identifierSchemeCodeUnspsc190501.50595217'),
	'identifierSchemeCodeUnspsc190501.50595218': __('identifierSchemeCodeUnspsc190501.50595218'),
	'identifierSchemeCodeUnspsc190501.50595219': __('identifierSchemeCodeUnspsc190501.50595219'),
	'identifierSchemeCodeUnspsc190501.50595220': __('identifierSchemeCodeUnspsc190501.50595220'),
	'identifierSchemeCodeUnspsc190501.50595221': __('identifierSchemeCodeUnspsc190501.50595221'),
	'identifierSchemeCodeUnspsc190501.50595300': __('identifierSchemeCodeUnspsc190501.50595300'),
	'identifierSchemeCodeUnspsc190501.50595301': __('identifierSchemeCodeUnspsc190501.50595301'),
	'identifierSchemeCodeUnspsc190501.50595302': __('identifierSchemeCodeUnspsc190501.50595302'),
	'identifierSchemeCodeUnspsc190501.50595303': __('identifierSchemeCodeUnspsc190501.50595303'),
	'identifierSchemeCodeUnspsc190501.50595304': __('identifierSchemeCodeUnspsc190501.50595304'),
	'identifierSchemeCodeUnspsc190501.50595305': __('identifierSchemeCodeUnspsc190501.50595305'),
	'identifierSchemeCodeUnspsc190501.50595306': __('identifierSchemeCodeUnspsc190501.50595306'),
	'identifierSchemeCodeUnspsc190501.50595400': __('identifierSchemeCodeUnspsc190501.50595400'),
	'identifierSchemeCodeUnspsc190501.50595401': __('identifierSchemeCodeUnspsc190501.50595401'),
	'identifierSchemeCodeUnspsc190501.50595402': __('identifierSchemeCodeUnspsc190501.50595402'),
	'identifierSchemeCodeUnspsc190501.50595403': __('identifierSchemeCodeUnspsc190501.50595403'),
	'identifierSchemeCodeUnspsc190501.50595404': __('identifierSchemeCodeUnspsc190501.50595404'),
	'identifierSchemeCodeUnspsc190501.50595405': __('identifierSchemeCodeUnspsc190501.50595405'),
	'identifierSchemeCodeUnspsc190501.50595406': __('identifierSchemeCodeUnspsc190501.50595406'),
	'identifierSchemeCodeUnspsc190501.50595500': __('identifierSchemeCodeUnspsc190501.50595500'),
	'identifierSchemeCodeUnspsc190501.50595501': __('identifierSchemeCodeUnspsc190501.50595501'),
	'identifierSchemeCodeUnspsc190501.50595502': __('identifierSchemeCodeUnspsc190501.50595502'),
	'identifierSchemeCodeUnspsc190501.50595503': __('identifierSchemeCodeUnspsc190501.50595503'),
	'identifierSchemeCodeUnspsc190501.50595504': __('identifierSchemeCodeUnspsc190501.50595504'),
	'identifierSchemeCodeUnspsc190501.50595505': __('identifierSchemeCodeUnspsc190501.50595505'),
	'identifierSchemeCodeUnspsc190501.50595506': __('identifierSchemeCodeUnspsc190501.50595506'),
	'identifierSchemeCodeUnspsc190501.50595507': __('identifierSchemeCodeUnspsc190501.50595507'),
	'identifierSchemeCodeUnspsc190501.50595508': __('identifierSchemeCodeUnspsc190501.50595508'),
	'identifierSchemeCodeUnspsc190501.50595509': __('identifierSchemeCodeUnspsc190501.50595509'),
	'identifierSchemeCodeUnspsc190501.50595510': __('identifierSchemeCodeUnspsc190501.50595510'),
	'identifierSchemeCodeUnspsc190501.50595511': __('identifierSchemeCodeUnspsc190501.50595511'),
	'identifierSchemeCodeUnspsc190501.50595512': __('identifierSchemeCodeUnspsc190501.50595512'),
	'identifierSchemeCodeUnspsc190501.50595513': __('identifierSchemeCodeUnspsc190501.50595513'),
	'identifierSchemeCodeUnspsc190501.50595514': __('identifierSchemeCodeUnspsc190501.50595514'),
	'identifierSchemeCodeUnspsc190501.50595515': __('identifierSchemeCodeUnspsc190501.50595515'),
	'identifierSchemeCodeUnspsc190501.50595516': __('identifierSchemeCodeUnspsc190501.50595516'),
	'identifierSchemeCodeUnspsc190501.50595517': __('identifierSchemeCodeUnspsc190501.50595517'),
	'identifierSchemeCodeUnspsc190501.50595518': __('identifierSchemeCodeUnspsc190501.50595518'),
	'identifierSchemeCodeUnspsc190501.50595519': __('identifierSchemeCodeUnspsc190501.50595519'),
	'identifierSchemeCodeUnspsc190501.50595520': __('identifierSchemeCodeUnspsc190501.50595520'),
	'identifierSchemeCodeUnspsc190501.50595521': __('identifierSchemeCodeUnspsc190501.50595521'),
	'identifierSchemeCodeUnspsc190501.50595522': __('identifierSchemeCodeUnspsc190501.50595522'),
	'identifierSchemeCodeUnspsc190501.50595523': __('identifierSchemeCodeUnspsc190501.50595523'),
	'identifierSchemeCodeUnspsc190501.50595524': __('identifierSchemeCodeUnspsc190501.50595524'),
	'identifierSchemeCodeUnspsc190501.50595525': __('identifierSchemeCodeUnspsc190501.50595525'),
	'identifierSchemeCodeUnspsc190501.50595526': __('identifierSchemeCodeUnspsc190501.50595526'),
	'identifierSchemeCodeUnspsc190501.50595527': __('identifierSchemeCodeUnspsc190501.50595527'),
	'identifierSchemeCodeUnspsc190501.50595528': __('identifierSchemeCodeUnspsc190501.50595528'),
	'identifierSchemeCodeUnspsc190501.50595529': __('identifierSchemeCodeUnspsc190501.50595529'),
	'identifierSchemeCodeUnspsc190501.50595530': __('identifierSchemeCodeUnspsc190501.50595530'),
	'identifierSchemeCodeUnspsc190501.50595531': __('identifierSchemeCodeUnspsc190501.50595531'),
	'identifierSchemeCodeUnspsc190501.50595532': __('identifierSchemeCodeUnspsc190501.50595532'),
	'identifierSchemeCodeUnspsc190501.50595533': __('identifierSchemeCodeUnspsc190501.50595533'),
	'identifierSchemeCodeUnspsc190501.50595534': __('identifierSchemeCodeUnspsc190501.50595534'),
	'identifierSchemeCodeUnspsc190501.50595535': __('identifierSchemeCodeUnspsc190501.50595535'),
	'identifierSchemeCodeUnspsc190501.50595536': __('identifierSchemeCodeUnspsc190501.50595536'),
	'identifierSchemeCodeUnspsc190501.50595537': __('identifierSchemeCodeUnspsc190501.50595537'),
	'identifierSchemeCodeUnspsc190501.50595600': __('identifierSchemeCodeUnspsc190501.50595600'),
	'identifierSchemeCodeUnspsc190501.50595601': __('identifierSchemeCodeUnspsc190501.50595601'),
	'identifierSchemeCodeUnspsc190501.50595602': __('identifierSchemeCodeUnspsc190501.50595602'),
	'identifierSchemeCodeUnspsc190501.50595603': __('identifierSchemeCodeUnspsc190501.50595603'),
	'identifierSchemeCodeUnspsc190501.50595604': __('identifierSchemeCodeUnspsc190501.50595604'),
	'identifierSchemeCodeUnspsc190501.50595605': __('identifierSchemeCodeUnspsc190501.50595605'),
	'identifierSchemeCodeUnspsc190501.50595606': __('identifierSchemeCodeUnspsc190501.50595606'),
	'identifierSchemeCodeUnspsc190501.50595607': __('identifierSchemeCodeUnspsc190501.50595607'),
	'identifierSchemeCodeUnspsc190501.50595608': __('identifierSchemeCodeUnspsc190501.50595608'),
	'identifierSchemeCodeUnspsc190501.50595700': __('identifierSchemeCodeUnspsc190501.50595700'),
	'identifierSchemeCodeUnspsc190501.50595701': __('identifierSchemeCodeUnspsc190501.50595701'),
	'identifierSchemeCodeUnspsc190501.50595702': __('identifierSchemeCodeUnspsc190501.50595702'),
	'identifierSchemeCodeUnspsc190501.50595703': __('identifierSchemeCodeUnspsc190501.50595703'),
	'identifierSchemeCodeUnspsc190501.50595704': __('identifierSchemeCodeUnspsc190501.50595704'),
	'identifierSchemeCodeUnspsc190501.50595800': __('identifierSchemeCodeUnspsc190501.50595800'),
	'identifierSchemeCodeUnspsc190501.50595801': __('identifierSchemeCodeUnspsc190501.50595801'),
	'identifierSchemeCodeUnspsc190501.50595802': __('identifierSchemeCodeUnspsc190501.50595802'),
	'identifierSchemeCodeUnspsc190501.50595803': __('identifierSchemeCodeUnspsc190501.50595803'),
	'identifierSchemeCodeUnspsc190501.50595804': __('identifierSchemeCodeUnspsc190501.50595804'),
	'identifierSchemeCodeUnspsc190501.50595805': __('identifierSchemeCodeUnspsc190501.50595805'),
	'identifierSchemeCodeUnspsc190501.50595806': __('identifierSchemeCodeUnspsc190501.50595806'),
	'identifierSchemeCodeUnspsc190501.50595807': __('identifierSchemeCodeUnspsc190501.50595807'),
	'identifierSchemeCodeUnspsc190501.50595808': __('identifierSchemeCodeUnspsc190501.50595808'),
	'identifierSchemeCodeUnspsc190501.50595809': __('identifierSchemeCodeUnspsc190501.50595809'),
	'identifierSchemeCodeUnspsc190501.50595810': __('identifierSchemeCodeUnspsc190501.50595810'),
	'identifierSchemeCodeUnspsc190501.50595811': __('identifierSchemeCodeUnspsc190501.50595811'),
	'identifierSchemeCodeUnspsc190501.50595812': __('identifierSchemeCodeUnspsc190501.50595812'),
	'identifierSchemeCodeUnspsc190501.50595813': __('identifierSchemeCodeUnspsc190501.50595813'),
	'identifierSchemeCodeUnspsc190501.50595814': __('identifierSchemeCodeUnspsc190501.50595814'),
	'identifierSchemeCodeUnspsc190501.50595815': __('identifierSchemeCodeUnspsc190501.50595815'),
	'identifierSchemeCodeUnspsc190501.50595816': __('identifierSchemeCodeUnspsc190501.50595816'),
	'identifierSchemeCodeUnspsc190501.50595900': __('identifierSchemeCodeUnspsc190501.50595900'),
	'identifierSchemeCodeUnspsc190501.50595901': __('identifierSchemeCodeUnspsc190501.50595901'),
	'identifierSchemeCodeUnspsc190501.50595902': __('identifierSchemeCodeUnspsc190501.50595902'),
	'identifierSchemeCodeUnspsc190501.50595903': __('identifierSchemeCodeUnspsc190501.50595903'),
	'identifierSchemeCodeUnspsc190501.50595904': __('identifierSchemeCodeUnspsc190501.50595904'),
	'identifierSchemeCodeUnspsc190501.50595905': __('identifierSchemeCodeUnspsc190501.50595905'),
	'identifierSchemeCodeUnspsc190501.50595906': __('identifierSchemeCodeUnspsc190501.50595906'),
	'identifierSchemeCodeUnspsc190501.50595907': __('identifierSchemeCodeUnspsc190501.50595907'),
	'identifierSchemeCodeUnspsc190501.50595908': __('identifierSchemeCodeUnspsc190501.50595908'),
	'identifierSchemeCodeUnspsc190501.50595909': __('identifierSchemeCodeUnspsc190501.50595909'),
	'identifierSchemeCodeUnspsc190501.50595910': __('identifierSchemeCodeUnspsc190501.50595910'),
	'identifierSchemeCodeUnspsc190501.50595911': __('identifierSchemeCodeUnspsc190501.50595911'),
	'identifierSchemeCodeUnspsc190501.50596000': __('identifierSchemeCodeUnspsc190501.50596000'),
	'identifierSchemeCodeUnspsc190501.50596001': __('identifierSchemeCodeUnspsc190501.50596001'),
	'identifierSchemeCodeUnspsc190501.50596002': __('identifierSchemeCodeUnspsc190501.50596002'),
	'identifierSchemeCodeUnspsc190501.50596003': __('identifierSchemeCodeUnspsc190501.50596003'),
	'identifierSchemeCodeUnspsc190501.50596004': __('identifierSchemeCodeUnspsc190501.50596004'),
	'identifierSchemeCodeUnspsc190501.50596100': __('identifierSchemeCodeUnspsc190501.50596100'),
	'identifierSchemeCodeUnspsc190501.50596101': __('identifierSchemeCodeUnspsc190501.50596101'),
	'identifierSchemeCodeUnspsc190501.50596102': __('identifierSchemeCodeUnspsc190501.50596102'),
	'identifierSchemeCodeUnspsc190501.50596103': __('identifierSchemeCodeUnspsc190501.50596103'),
	'identifierSchemeCodeUnspsc190501.50596104': __('identifierSchemeCodeUnspsc190501.50596104'),
	'identifierSchemeCodeUnspsc190501.50596105': __('identifierSchemeCodeUnspsc190501.50596105'),
	'identifierSchemeCodeUnspsc190501.50596106': __('identifierSchemeCodeUnspsc190501.50596106'),
	'identifierSchemeCodeUnspsc190501.50596107': __('identifierSchemeCodeUnspsc190501.50596107'),
	'identifierSchemeCodeUnspsc190501.50596108': __('identifierSchemeCodeUnspsc190501.50596108'),
	'identifierSchemeCodeUnspsc190501.50596109': __('identifierSchemeCodeUnspsc190501.50596109'),
	'identifierSchemeCodeUnspsc190501.50596110': __('identifierSchemeCodeUnspsc190501.50596110'),
	'identifierSchemeCodeUnspsc190501.50596111': __('identifierSchemeCodeUnspsc190501.50596111'),
	'identifierSchemeCodeUnspsc190501.50596112': __('identifierSchemeCodeUnspsc190501.50596112'),
	'identifierSchemeCodeUnspsc190501.50596113': __('identifierSchemeCodeUnspsc190501.50596113'),
	'identifierSchemeCodeUnspsc190501.50596114': __('identifierSchemeCodeUnspsc190501.50596114'),
	'identifierSchemeCodeUnspsc190501.50596115': __('identifierSchemeCodeUnspsc190501.50596115'),
	'identifierSchemeCodeUnspsc190501.50596116': __('identifierSchemeCodeUnspsc190501.50596116'),
	'identifierSchemeCodeUnspsc190501.50596200': __('identifierSchemeCodeUnspsc190501.50596200'),
	'identifierSchemeCodeUnspsc190501.50596201': __('identifierSchemeCodeUnspsc190501.50596201'),
	'identifierSchemeCodeUnspsc190501.50596202': __('identifierSchemeCodeUnspsc190501.50596202'),
	'identifierSchemeCodeUnspsc190501.50596203': __('identifierSchemeCodeUnspsc190501.50596203'),
	'identifierSchemeCodeUnspsc190501.50596204': __('identifierSchemeCodeUnspsc190501.50596204'),
	'identifierSchemeCodeUnspsc190501.50596205': __('identifierSchemeCodeUnspsc190501.50596205'),
	'identifierSchemeCodeUnspsc190501.50596206': __('identifierSchemeCodeUnspsc190501.50596206'),
	'identifierSchemeCodeUnspsc190501.50596207': __('identifierSchemeCodeUnspsc190501.50596207'),
	'identifierSchemeCodeUnspsc190501.50596208': __('identifierSchemeCodeUnspsc190501.50596208'),
	'identifierSchemeCodeUnspsc190501.50596209': __('identifierSchemeCodeUnspsc190501.50596209'),
	'identifierSchemeCodeUnspsc190501.50596210': __('identifierSchemeCodeUnspsc190501.50596210'),
	'identifierSchemeCodeUnspsc190501.50596211': __('identifierSchemeCodeUnspsc190501.50596211'),
	'identifierSchemeCodeUnspsc190501.50596212': __('identifierSchemeCodeUnspsc190501.50596212'),
	'identifierSchemeCodeUnspsc190501.50596213': __('identifierSchemeCodeUnspsc190501.50596213'),
	'identifierSchemeCodeUnspsc190501.50596214': __('identifierSchemeCodeUnspsc190501.50596214'),
	'identifierSchemeCodeUnspsc190501.50596215': __('identifierSchemeCodeUnspsc190501.50596215'),
	'identifierSchemeCodeUnspsc190501.50596216': __('identifierSchemeCodeUnspsc190501.50596216'),
	'identifierSchemeCodeUnspsc190501.50596217': __('identifierSchemeCodeUnspsc190501.50596217'),
	'identifierSchemeCodeUnspsc190501.50596218': __('identifierSchemeCodeUnspsc190501.50596218'),
	'identifierSchemeCodeUnspsc190501.50596219': __('identifierSchemeCodeUnspsc190501.50596219'),
	'identifierSchemeCodeUnspsc190501.50596220': __('identifierSchemeCodeUnspsc190501.50596220'),
	'identifierSchemeCodeUnspsc190501.50596221': __('identifierSchemeCodeUnspsc190501.50596221'),
	'identifierSchemeCodeUnspsc190501.50596222': __('identifierSchemeCodeUnspsc190501.50596222'),
	'identifierSchemeCodeUnspsc190501.50596223': __('identifierSchemeCodeUnspsc190501.50596223'),
	'identifierSchemeCodeUnspsc190501.50596224': __('identifierSchemeCodeUnspsc190501.50596224'),
	'identifierSchemeCodeUnspsc190501.50596225': __('identifierSchemeCodeUnspsc190501.50596225'),
	'identifierSchemeCodeUnspsc190501.50596226': __('identifierSchemeCodeUnspsc190501.50596226'),
	'identifierSchemeCodeUnspsc190501.50596227': __('identifierSchemeCodeUnspsc190501.50596227'),
	'identifierSchemeCodeUnspsc190501.50596228': __('identifierSchemeCodeUnspsc190501.50596228'),
	'identifierSchemeCodeUnspsc190501.50596229': __('identifierSchemeCodeUnspsc190501.50596229'),
	'identifierSchemeCodeUnspsc190501.50596300': __('identifierSchemeCodeUnspsc190501.50596300'),
	'identifierSchemeCodeUnspsc190501.50596301': __('identifierSchemeCodeUnspsc190501.50596301'),
	'identifierSchemeCodeUnspsc190501.50596302': __('identifierSchemeCodeUnspsc190501.50596302'),
	'identifierSchemeCodeUnspsc190501.50596303': __('identifierSchemeCodeUnspsc190501.50596303'),
	'identifierSchemeCodeUnspsc190501.50596304': __('identifierSchemeCodeUnspsc190501.50596304'),
	'identifierSchemeCodeUnspsc190501.50596305': __('identifierSchemeCodeUnspsc190501.50596305'),
	'identifierSchemeCodeUnspsc190501.50596306': __('identifierSchemeCodeUnspsc190501.50596306'),
	'identifierSchemeCodeUnspsc190501.50596307': __('identifierSchemeCodeUnspsc190501.50596307'),
	'identifierSchemeCodeUnspsc190501.50596308': __('identifierSchemeCodeUnspsc190501.50596308'),
	'identifierSchemeCodeUnspsc190501.50596309': __('identifierSchemeCodeUnspsc190501.50596309'),
	'identifierSchemeCodeUnspsc190501.50596310': __('identifierSchemeCodeUnspsc190501.50596310'),
	'identifierSchemeCodeUnspsc190501.50596311': __('identifierSchemeCodeUnspsc190501.50596311'),
	'identifierSchemeCodeUnspsc190501.50596312': __('identifierSchemeCodeUnspsc190501.50596312'),
	'identifierSchemeCodeUnspsc190501.50596313': __('identifierSchemeCodeUnspsc190501.50596313'),
	'identifierSchemeCodeUnspsc190501.50596314': __('identifierSchemeCodeUnspsc190501.50596314'),
	'identifierSchemeCodeUnspsc190501.50596315': __('identifierSchemeCodeUnspsc190501.50596315'),
	'identifierSchemeCodeUnspsc190501.50596316': __('identifierSchemeCodeUnspsc190501.50596316'),
	'identifierSchemeCodeUnspsc190501.50596317': __('identifierSchemeCodeUnspsc190501.50596317'),
	'identifierSchemeCodeUnspsc190501.50596318': __('identifierSchemeCodeUnspsc190501.50596318'),
	'identifierSchemeCodeUnspsc190501.50596319': __('identifierSchemeCodeUnspsc190501.50596319'),
	'identifierSchemeCodeUnspsc190501.50596320': __('identifierSchemeCodeUnspsc190501.50596320'),
	'identifierSchemeCodeUnspsc190501.50596400': __('identifierSchemeCodeUnspsc190501.50596400'),
	'identifierSchemeCodeUnspsc190501.50596401': __('identifierSchemeCodeUnspsc190501.50596401'),
	'identifierSchemeCodeUnspsc190501.50596402': __('identifierSchemeCodeUnspsc190501.50596402'),
	'identifierSchemeCodeUnspsc190501.50596403': __('identifierSchemeCodeUnspsc190501.50596403'),
	'identifierSchemeCodeUnspsc190501.50596404': __('identifierSchemeCodeUnspsc190501.50596404'),
	'identifierSchemeCodeUnspsc190501.50596405': __('identifierSchemeCodeUnspsc190501.50596405'),
	'identifierSchemeCodeUnspsc190501.50596406': __('identifierSchemeCodeUnspsc190501.50596406'),
	'identifierSchemeCodeUnspsc190501.50596407': __('identifierSchemeCodeUnspsc190501.50596407'),
	'identifierSchemeCodeUnspsc190501.50596408': __('identifierSchemeCodeUnspsc190501.50596408'),
	'identifierSchemeCodeUnspsc190501.50596409': __('identifierSchemeCodeUnspsc190501.50596409'),
	'identifierSchemeCodeUnspsc190501.50596410': __('identifierSchemeCodeUnspsc190501.50596410'),
	'identifierSchemeCodeUnspsc190501.50596411': __('identifierSchemeCodeUnspsc190501.50596411'),
	'identifierSchemeCodeUnspsc190501.50596412': __('identifierSchemeCodeUnspsc190501.50596412'),
	'identifierSchemeCodeUnspsc190501.50596413': __('identifierSchemeCodeUnspsc190501.50596413'),
	'identifierSchemeCodeUnspsc190501.50596414': __('identifierSchemeCodeUnspsc190501.50596414'),
	'identifierSchemeCodeUnspsc190501.50596415': __('identifierSchemeCodeUnspsc190501.50596415'),
	'identifierSchemeCodeUnspsc190501.50596416': __('identifierSchemeCodeUnspsc190501.50596416'),
	'identifierSchemeCodeUnspsc190501.50596417': __('identifierSchemeCodeUnspsc190501.50596417'),
	'identifierSchemeCodeUnspsc190501.50596418': __('identifierSchemeCodeUnspsc190501.50596418'),
	'identifierSchemeCodeUnspsc190501.50596419': __('identifierSchemeCodeUnspsc190501.50596419'),
	'identifierSchemeCodeUnspsc190501.50596420': __('identifierSchemeCodeUnspsc190501.50596420'),
	'identifierSchemeCodeUnspsc190501.50596421': __('identifierSchemeCodeUnspsc190501.50596421'),
	'identifierSchemeCodeUnspsc190501.50596422': __('identifierSchemeCodeUnspsc190501.50596422'),
	'identifierSchemeCodeUnspsc190501.50596423': __('identifierSchemeCodeUnspsc190501.50596423'),
	'identifierSchemeCodeUnspsc190501.50596424': __('identifierSchemeCodeUnspsc190501.50596424'),
	'identifierSchemeCodeUnspsc190501.50596425': __('identifierSchemeCodeUnspsc190501.50596425'),
	'identifierSchemeCodeUnspsc190501.50596426': __('identifierSchemeCodeUnspsc190501.50596426'),
	'identifierSchemeCodeUnspsc190501.50596427': __('identifierSchemeCodeUnspsc190501.50596427'),
	'identifierSchemeCodeUnspsc190501.50596500': __('identifierSchemeCodeUnspsc190501.50596500'),
	'identifierSchemeCodeUnspsc190501.50596501': __('identifierSchemeCodeUnspsc190501.50596501'),
	'identifierSchemeCodeUnspsc190501.50596502': __('identifierSchemeCodeUnspsc190501.50596502'),
	'identifierSchemeCodeUnspsc190501.50596503': __('identifierSchemeCodeUnspsc190501.50596503'),
	'identifierSchemeCodeUnspsc190501.50596504': __('identifierSchemeCodeUnspsc190501.50596504'),
	'identifierSchemeCodeUnspsc190501.50596505': __('identifierSchemeCodeUnspsc190501.50596505'),
	'identifierSchemeCodeUnspsc190501.50596506': __('identifierSchemeCodeUnspsc190501.50596506'),
	'identifierSchemeCodeUnspsc190501.50596507': __('identifierSchemeCodeUnspsc190501.50596507'),
	'identifierSchemeCodeUnspsc190501.50596508': __('identifierSchemeCodeUnspsc190501.50596508'),
	'identifierSchemeCodeUnspsc190501.50596600': __('identifierSchemeCodeUnspsc190501.50596600'),
	'identifierSchemeCodeUnspsc190501.50596601': __('identifierSchemeCodeUnspsc190501.50596601'),
	'identifierSchemeCodeUnspsc190501.50596602': __('identifierSchemeCodeUnspsc190501.50596602'),
	'identifierSchemeCodeUnspsc190501.50596603': __('identifierSchemeCodeUnspsc190501.50596603'),
	'identifierSchemeCodeUnspsc190501.50596604': __('identifierSchemeCodeUnspsc190501.50596604'),
	'identifierSchemeCodeUnspsc190501.50596605': __('identifierSchemeCodeUnspsc190501.50596605'),
	'identifierSchemeCodeUnspsc190501.50596606': __('identifierSchemeCodeUnspsc190501.50596606'),
	'identifierSchemeCodeUnspsc190501.50596607': __('identifierSchemeCodeUnspsc190501.50596607'),
	'identifierSchemeCodeUnspsc190501.50596608': __('identifierSchemeCodeUnspsc190501.50596608'),
	'identifierSchemeCodeUnspsc190501.50596609': __('identifierSchemeCodeUnspsc190501.50596609'),
	'identifierSchemeCodeUnspsc190501.50596610': __('identifierSchemeCodeUnspsc190501.50596610'),
	'identifierSchemeCodeUnspsc190501.50596611': __('identifierSchemeCodeUnspsc190501.50596611'),
	'identifierSchemeCodeUnspsc190501.50596612': __('identifierSchemeCodeUnspsc190501.50596612'),
	'identifierSchemeCodeUnspsc190501.50596613': __('identifierSchemeCodeUnspsc190501.50596613'),
	'identifierSchemeCodeUnspsc190501.50596614': __('identifierSchemeCodeUnspsc190501.50596614'),
	'identifierSchemeCodeUnspsc190501.50596615': __('identifierSchemeCodeUnspsc190501.50596615'),
	'identifierSchemeCodeUnspsc190501.50596616': __('identifierSchemeCodeUnspsc190501.50596616'),
	'identifierSchemeCodeUnspsc190501.50596617': __('identifierSchemeCodeUnspsc190501.50596617'),
	'identifierSchemeCodeUnspsc190501.50596618': __('identifierSchemeCodeUnspsc190501.50596618'),
	'identifierSchemeCodeUnspsc190501.50596619': __('identifierSchemeCodeUnspsc190501.50596619'),
	'identifierSchemeCodeUnspsc190501.50596620': __('identifierSchemeCodeUnspsc190501.50596620'),
	'identifierSchemeCodeUnspsc190501.50596700': __('identifierSchemeCodeUnspsc190501.50596700'),
	'identifierSchemeCodeUnspsc190501.50596701': __('identifierSchemeCodeUnspsc190501.50596701'),
	'identifierSchemeCodeUnspsc190501.50596702': __('identifierSchemeCodeUnspsc190501.50596702'),
	'identifierSchemeCodeUnspsc190501.50596703': __('identifierSchemeCodeUnspsc190501.50596703'),
	'identifierSchemeCodeUnspsc190501.50596704': __('identifierSchemeCodeUnspsc190501.50596704'),
	'identifierSchemeCodeUnspsc190501.50596705': __('identifierSchemeCodeUnspsc190501.50596705'),
	'identifierSchemeCodeUnspsc190501.50596706': __('identifierSchemeCodeUnspsc190501.50596706'),
	'identifierSchemeCodeUnspsc190501.50596707': __('identifierSchemeCodeUnspsc190501.50596707'),
	'identifierSchemeCodeUnspsc190501.50596708': __('identifierSchemeCodeUnspsc190501.50596708'),
	'identifierSchemeCodeUnspsc190501.50596709': __('identifierSchemeCodeUnspsc190501.50596709'),
	'identifierSchemeCodeUnspsc190501.50596710': __('identifierSchemeCodeUnspsc190501.50596710'),
	'identifierSchemeCodeUnspsc190501.50596800': __('identifierSchemeCodeUnspsc190501.50596800'),
	'identifierSchemeCodeUnspsc190501.50596801': __('identifierSchemeCodeUnspsc190501.50596801'),
	'identifierSchemeCodeUnspsc190501.50596802': __('identifierSchemeCodeUnspsc190501.50596802'),
	'identifierSchemeCodeUnspsc190501.50596803': __('identifierSchemeCodeUnspsc190501.50596803'),
	'identifierSchemeCodeUnspsc190501.50596804': __('identifierSchemeCodeUnspsc190501.50596804'),
	'identifierSchemeCodeUnspsc190501.50596805': __('identifierSchemeCodeUnspsc190501.50596805'),
	'identifierSchemeCodeUnspsc190501.50596806': __('identifierSchemeCodeUnspsc190501.50596806'),
	'identifierSchemeCodeUnspsc190501.50596807': __('identifierSchemeCodeUnspsc190501.50596807'),
	'identifierSchemeCodeUnspsc190501.50596808': __('identifierSchemeCodeUnspsc190501.50596808'),
	'identifierSchemeCodeUnspsc190501.50596809': __('identifierSchemeCodeUnspsc190501.50596809'),
	'identifierSchemeCodeUnspsc190501.50596810': __('identifierSchemeCodeUnspsc190501.50596810'),
	'identifierSchemeCodeUnspsc190501.50596811': __('identifierSchemeCodeUnspsc190501.50596811'),
	'identifierSchemeCodeUnspsc190501.50596812': __('identifierSchemeCodeUnspsc190501.50596812'),
	'identifierSchemeCodeUnspsc190501.50596813': __('identifierSchemeCodeUnspsc190501.50596813'),
	'identifierSchemeCodeUnspsc190501.50596814': __('identifierSchemeCodeUnspsc190501.50596814'),
	'identifierSchemeCodeUnspsc190501.50596815': __('identifierSchemeCodeUnspsc190501.50596815'),
	'identifierSchemeCodeUnspsc190501.50596816': __('identifierSchemeCodeUnspsc190501.50596816'),
	'identifierSchemeCodeUnspsc190501.50596817': __('identifierSchemeCodeUnspsc190501.50596817'),
	'identifierSchemeCodeUnspsc190501.50596818': __('identifierSchemeCodeUnspsc190501.50596818'),
	'identifierSchemeCodeUnspsc190501.50596819': __('identifierSchemeCodeUnspsc190501.50596819'),
	'identifierSchemeCodeUnspsc190501.50596820': __('identifierSchemeCodeUnspsc190501.50596820'),
	'identifierSchemeCodeUnspsc190501.50596821': __('identifierSchemeCodeUnspsc190501.50596821'),
	'identifierSchemeCodeUnspsc190501.50596822': __('identifierSchemeCodeUnspsc190501.50596822'),
	'identifierSchemeCodeUnspsc190501.50596823': __('identifierSchemeCodeUnspsc190501.50596823'),
	'identifierSchemeCodeUnspsc190501.50596824': __('identifierSchemeCodeUnspsc190501.50596824'),
	'identifierSchemeCodeUnspsc190501.50596825': __('identifierSchemeCodeUnspsc190501.50596825'),
	'identifierSchemeCodeUnspsc190501.50596826': __('identifierSchemeCodeUnspsc190501.50596826'),
	'identifierSchemeCodeUnspsc190501.50596827': __('identifierSchemeCodeUnspsc190501.50596827'),
	'identifierSchemeCodeUnspsc190501.50596828': __('identifierSchemeCodeUnspsc190501.50596828'),
	'identifierSchemeCodeUnspsc190501.50596829': __('identifierSchemeCodeUnspsc190501.50596829'),
	'identifierSchemeCodeUnspsc190501.50596830': __('identifierSchemeCodeUnspsc190501.50596830'),
	'identifierSchemeCodeUnspsc190501.50596831': __('identifierSchemeCodeUnspsc190501.50596831'),
	'identifierSchemeCodeUnspsc190501.50596832': __('identifierSchemeCodeUnspsc190501.50596832'),
	'identifierSchemeCodeUnspsc190501.50596833': __('identifierSchemeCodeUnspsc190501.50596833'),
	'identifierSchemeCodeUnspsc190501.50596834': __('identifierSchemeCodeUnspsc190501.50596834'),
	'identifierSchemeCodeUnspsc190501.50596835': __('identifierSchemeCodeUnspsc190501.50596835'),
	'identifierSchemeCodeUnspsc190501.50596836': __('identifierSchemeCodeUnspsc190501.50596836'),
	'identifierSchemeCodeUnspsc190501.50596837': __('identifierSchemeCodeUnspsc190501.50596837'),
	'identifierSchemeCodeUnspsc190501.50596838': __('identifierSchemeCodeUnspsc190501.50596838'),
	'identifierSchemeCodeUnspsc190501.50596839': __('identifierSchemeCodeUnspsc190501.50596839'),
	'identifierSchemeCodeUnspsc190501.50596840': __('identifierSchemeCodeUnspsc190501.50596840'),
	'identifierSchemeCodeUnspsc190501.50596841': __('identifierSchemeCodeUnspsc190501.50596841'),
	'identifierSchemeCodeUnspsc190501.50596842': __('identifierSchemeCodeUnspsc190501.50596842'),
	'identifierSchemeCodeUnspsc190501.50596843': __('identifierSchemeCodeUnspsc190501.50596843'),
	'identifierSchemeCodeUnspsc190501.50596844': __('identifierSchemeCodeUnspsc190501.50596844'),
	'identifierSchemeCodeUnspsc190501.50596845': __('identifierSchemeCodeUnspsc190501.50596845'),
	'identifierSchemeCodeUnspsc190501.50596846': __('identifierSchemeCodeUnspsc190501.50596846'),
	'identifierSchemeCodeUnspsc190501.50596847': __('identifierSchemeCodeUnspsc190501.50596847'),
	'identifierSchemeCodeUnspsc190501.50596848': __('identifierSchemeCodeUnspsc190501.50596848'),
	'identifierSchemeCodeUnspsc190501.50596849': __('identifierSchemeCodeUnspsc190501.50596849'),
	'identifierSchemeCodeUnspsc190501.50596850': __('identifierSchemeCodeUnspsc190501.50596850'),
	'identifierSchemeCodeUnspsc190501.50596851': __('identifierSchemeCodeUnspsc190501.50596851'),
	'identifierSchemeCodeUnspsc190501.50596852': __('identifierSchemeCodeUnspsc190501.50596852'),
	'identifierSchemeCodeUnspsc190501.50596853': __('identifierSchemeCodeUnspsc190501.50596853'),
	'identifierSchemeCodeUnspsc190501.50596854': __('identifierSchemeCodeUnspsc190501.50596854'),
	'identifierSchemeCodeUnspsc190501.50596855': __('identifierSchemeCodeUnspsc190501.50596855'),
	'identifierSchemeCodeUnspsc190501.50596856': __('identifierSchemeCodeUnspsc190501.50596856'),
	'identifierSchemeCodeUnspsc190501.50596857': __('identifierSchemeCodeUnspsc190501.50596857'),
	'identifierSchemeCodeUnspsc190501.50596858': __('identifierSchemeCodeUnspsc190501.50596858'),
	'identifierSchemeCodeUnspsc190501.50596859': __('identifierSchemeCodeUnspsc190501.50596859'),
	'identifierSchemeCodeUnspsc190501.50596860': __('identifierSchemeCodeUnspsc190501.50596860'),
	'identifierSchemeCodeUnspsc190501.50596861': __('identifierSchemeCodeUnspsc190501.50596861'),
	'identifierSchemeCodeUnspsc190501.50596862': __('identifierSchemeCodeUnspsc190501.50596862'),
	'identifierSchemeCodeUnspsc190501.50596863': __('identifierSchemeCodeUnspsc190501.50596863'),
	'identifierSchemeCodeUnspsc190501.50596864': __('identifierSchemeCodeUnspsc190501.50596864'),
	'identifierSchemeCodeUnspsc190501.50596865': __('identifierSchemeCodeUnspsc190501.50596865'),
	'identifierSchemeCodeUnspsc190501.50596866': __('identifierSchemeCodeUnspsc190501.50596866'),
	'identifierSchemeCodeUnspsc190501.50596867': __('identifierSchemeCodeUnspsc190501.50596867'),
	'identifierSchemeCodeUnspsc190501.50596868': __('identifierSchemeCodeUnspsc190501.50596868'),
	'identifierSchemeCodeUnspsc190501.50596900': __('identifierSchemeCodeUnspsc190501.50596900'),
	'identifierSchemeCodeUnspsc190501.50596901': __('identifierSchemeCodeUnspsc190501.50596901'),
	'identifierSchemeCodeUnspsc190501.50596902': __('identifierSchemeCodeUnspsc190501.50596902'),
	'identifierSchemeCodeUnspsc190501.50596903': __('identifierSchemeCodeUnspsc190501.50596903'),
	'identifierSchemeCodeUnspsc190501.50596904': __('identifierSchemeCodeUnspsc190501.50596904'),
	'identifierSchemeCodeUnspsc190501.50596905': __('identifierSchemeCodeUnspsc190501.50596905'),
	'identifierSchemeCodeUnspsc190501.50596906': __('identifierSchemeCodeUnspsc190501.50596906'),
	'identifierSchemeCodeUnspsc190501.50596907': __('identifierSchemeCodeUnspsc190501.50596907'),
	'identifierSchemeCodeUnspsc190501.50596908': __('identifierSchemeCodeUnspsc190501.50596908'),
	'identifierSchemeCodeUnspsc190501.50596909': __('identifierSchemeCodeUnspsc190501.50596909'),
	'identifierSchemeCodeUnspsc190501.50596910': __('identifierSchemeCodeUnspsc190501.50596910'),
	'identifierSchemeCodeUnspsc190501.50600000': __('identifierSchemeCodeUnspsc190501.50600000'),
	'identifierSchemeCodeUnspsc190501.50601500': __('identifierSchemeCodeUnspsc190501.50601500'),
	'identifierSchemeCodeUnspsc190501.50601501': __('identifierSchemeCodeUnspsc190501.50601501'),
	'identifierSchemeCodeUnspsc190501.50601502': __('identifierSchemeCodeUnspsc190501.50601502'),
	'identifierSchemeCodeUnspsc190501.50601503': __('identifierSchemeCodeUnspsc190501.50601503'),
	'identifierSchemeCodeUnspsc190501.50601504': __('identifierSchemeCodeUnspsc190501.50601504'),
	'identifierSchemeCodeUnspsc190501.50601505': __('identifierSchemeCodeUnspsc190501.50601505'),
	'identifierSchemeCodeUnspsc190501.50601506': __('identifierSchemeCodeUnspsc190501.50601506'),
	'identifierSchemeCodeUnspsc190501.50601507': __('identifierSchemeCodeUnspsc190501.50601507'),
	'identifierSchemeCodeUnspsc190501.50601508': __('identifierSchemeCodeUnspsc190501.50601508'),
	'identifierSchemeCodeUnspsc190501.50601509': __('identifierSchemeCodeUnspsc190501.50601509'),
	'identifierSchemeCodeUnspsc190501.50601510': __('identifierSchemeCodeUnspsc190501.50601510'),
	'identifierSchemeCodeUnspsc190501.50601511': __('identifierSchemeCodeUnspsc190501.50601511'),
	'identifierSchemeCodeUnspsc190501.50601512': __('identifierSchemeCodeUnspsc190501.50601512'),
	'identifierSchemeCodeUnspsc190501.50601513': __('identifierSchemeCodeUnspsc190501.50601513'),
	'identifierSchemeCodeUnspsc190501.50601600': __('identifierSchemeCodeUnspsc190501.50601600'),
	'identifierSchemeCodeUnspsc190501.50601601': __('identifierSchemeCodeUnspsc190501.50601601'),
	'identifierSchemeCodeUnspsc190501.50601602': __('identifierSchemeCodeUnspsc190501.50601602'),
	'identifierSchemeCodeUnspsc190501.50601603': __('identifierSchemeCodeUnspsc190501.50601603'),
	'identifierSchemeCodeUnspsc190501.50601604': __('identifierSchemeCodeUnspsc190501.50601604'),
	'identifierSchemeCodeUnspsc190501.50601605': __('identifierSchemeCodeUnspsc190501.50601605'),
	'identifierSchemeCodeUnspsc190501.50601700': __('identifierSchemeCodeUnspsc190501.50601700'),
	'identifierSchemeCodeUnspsc190501.50601701': __('identifierSchemeCodeUnspsc190501.50601701'),
	'identifierSchemeCodeUnspsc190501.50601702': __('identifierSchemeCodeUnspsc190501.50601702'),
	'identifierSchemeCodeUnspsc190501.50601703': __('identifierSchemeCodeUnspsc190501.50601703'),
	'identifierSchemeCodeUnspsc190501.50601704': __('identifierSchemeCodeUnspsc190501.50601704'),
	'identifierSchemeCodeUnspsc190501.50601705': __('identifierSchemeCodeUnspsc190501.50601705'),
	'identifierSchemeCodeUnspsc190501.50601706': __('identifierSchemeCodeUnspsc190501.50601706'),
	'identifierSchemeCodeUnspsc190501.50601707': __('identifierSchemeCodeUnspsc190501.50601707'),
	'identifierSchemeCodeUnspsc190501.50601708': __('identifierSchemeCodeUnspsc190501.50601708'),
	'identifierSchemeCodeUnspsc190501.50601709': __('identifierSchemeCodeUnspsc190501.50601709'),
	'identifierSchemeCodeUnspsc190501.50601710': __('identifierSchemeCodeUnspsc190501.50601710'),
	'identifierSchemeCodeUnspsc190501.50601711': __('identifierSchemeCodeUnspsc190501.50601711'),
	'identifierSchemeCodeUnspsc190501.50601712': __('identifierSchemeCodeUnspsc190501.50601712'),
	'identifierSchemeCodeUnspsc190501.50601713': __('identifierSchemeCodeUnspsc190501.50601713'),
	'identifierSchemeCodeUnspsc190501.50601714': __('identifierSchemeCodeUnspsc190501.50601714'),
	'identifierSchemeCodeUnspsc190501.50601715': __('identifierSchemeCodeUnspsc190501.50601715'),
	'identifierSchemeCodeUnspsc190501.50601716': __('identifierSchemeCodeUnspsc190501.50601716'),
	'identifierSchemeCodeUnspsc190501.50601717': __('identifierSchemeCodeUnspsc190501.50601717'),
	'identifierSchemeCodeUnspsc190501.50601718': __('identifierSchemeCodeUnspsc190501.50601718'),
	'identifierSchemeCodeUnspsc190501.50601719': __('identifierSchemeCodeUnspsc190501.50601719'),
	'identifierSchemeCodeUnspsc190501.50601720': __('identifierSchemeCodeUnspsc190501.50601720'),
	'identifierSchemeCodeUnspsc190501.50601721': __('identifierSchemeCodeUnspsc190501.50601721'),
	'identifierSchemeCodeUnspsc190501.50601722': __('identifierSchemeCodeUnspsc190501.50601722'),
	'identifierSchemeCodeUnspsc190501.50601723': __('identifierSchemeCodeUnspsc190501.50601723'),
	'identifierSchemeCodeUnspsc190501.50601724': __('identifierSchemeCodeUnspsc190501.50601724'),
	'identifierSchemeCodeUnspsc190501.50601725': __('identifierSchemeCodeUnspsc190501.50601725'),
	'identifierSchemeCodeUnspsc190501.50601726': __('identifierSchemeCodeUnspsc190501.50601726'),
	'identifierSchemeCodeUnspsc190501.50601727': __('identifierSchemeCodeUnspsc190501.50601727'),
	'identifierSchemeCodeUnspsc190501.50601728': __('identifierSchemeCodeUnspsc190501.50601728'),
	'identifierSchemeCodeUnspsc190501.50601729': __('identifierSchemeCodeUnspsc190501.50601729'),
	'identifierSchemeCodeUnspsc190501.50601730': __('identifierSchemeCodeUnspsc190501.50601730'),
	'identifierSchemeCodeUnspsc190501.50601731': __('identifierSchemeCodeUnspsc190501.50601731'),
	'identifierSchemeCodeUnspsc190501.50601732': __('identifierSchemeCodeUnspsc190501.50601732'),
	'identifierSchemeCodeUnspsc190501.50601733': __('identifierSchemeCodeUnspsc190501.50601733'),
	'identifierSchemeCodeUnspsc190501.50601734': __('identifierSchemeCodeUnspsc190501.50601734'),
	'identifierSchemeCodeUnspsc190501.50601735': __('identifierSchemeCodeUnspsc190501.50601735'),
	'identifierSchemeCodeUnspsc190501.50601736': __('identifierSchemeCodeUnspsc190501.50601736'),
	'identifierSchemeCodeUnspsc190501.50601737': __('identifierSchemeCodeUnspsc190501.50601737'),
	'identifierSchemeCodeUnspsc190501.50601738': __('identifierSchemeCodeUnspsc190501.50601738'),
	'identifierSchemeCodeUnspsc190501.50601739': __('identifierSchemeCodeUnspsc190501.50601739'),
	'identifierSchemeCodeUnspsc190501.50601740': __('identifierSchemeCodeUnspsc190501.50601740'),
	'identifierSchemeCodeUnspsc190501.50601741': __('identifierSchemeCodeUnspsc190501.50601741'),
	'identifierSchemeCodeUnspsc190501.50601742': __('identifierSchemeCodeUnspsc190501.50601742'),
	'identifierSchemeCodeUnspsc190501.50601743': __('identifierSchemeCodeUnspsc190501.50601743'),
	'identifierSchemeCodeUnspsc190501.50601744': __('identifierSchemeCodeUnspsc190501.50601744'),
	'identifierSchemeCodeUnspsc190501.50601745': __('identifierSchemeCodeUnspsc190501.50601745'),
	'identifierSchemeCodeUnspsc190501.50601746': __('identifierSchemeCodeUnspsc190501.50601746'),
	'identifierSchemeCodeUnspsc190501.50601747': __('identifierSchemeCodeUnspsc190501.50601747'),
	'identifierSchemeCodeUnspsc190501.50601748': __('identifierSchemeCodeUnspsc190501.50601748'),
	'identifierSchemeCodeUnspsc190501.50601749': __('identifierSchemeCodeUnspsc190501.50601749'),
	'identifierSchemeCodeUnspsc190501.50601750': __('identifierSchemeCodeUnspsc190501.50601750'),
	'identifierSchemeCodeUnspsc190501.50601751': __('identifierSchemeCodeUnspsc190501.50601751'),
	'identifierSchemeCodeUnspsc190501.50601752': __('identifierSchemeCodeUnspsc190501.50601752'),
	'identifierSchemeCodeUnspsc190501.50601753': __('identifierSchemeCodeUnspsc190501.50601753'),
	'identifierSchemeCodeUnspsc190501.50601754': __('identifierSchemeCodeUnspsc190501.50601754'),
	'identifierSchemeCodeUnspsc190501.50601755': __('identifierSchemeCodeUnspsc190501.50601755'),
	'identifierSchemeCodeUnspsc190501.50601756': __('identifierSchemeCodeUnspsc190501.50601756'),
	'identifierSchemeCodeUnspsc190501.50601757': __('identifierSchemeCodeUnspsc190501.50601757'),
	'identifierSchemeCodeUnspsc190501.50601758': __('identifierSchemeCodeUnspsc190501.50601758'),
	'identifierSchemeCodeUnspsc190501.50601759': __('identifierSchemeCodeUnspsc190501.50601759'),
	'identifierSchemeCodeUnspsc190501.50601760': __('identifierSchemeCodeUnspsc190501.50601760'),
	'identifierSchemeCodeUnspsc190501.50601761': __('identifierSchemeCodeUnspsc190501.50601761'),
	'identifierSchemeCodeUnspsc190501.50601762': __('identifierSchemeCodeUnspsc190501.50601762'),
	'identifierSchemeCodeUnspsc190501.50601763': __('identifierSchemeCodeUnspsc190501.50601763'),
	'identifierSchemeCodeUnspsc190501.50601764': __('identifierSchemeCodeUnspsc190501.50601764'),
	'identifierSchemeCodeUnspsc190501.50601765': __('identifierSchemeCodeUnspsc190501.50601765'),
	'identifierSchemeCodeUnspsc190501.50601766': __('identifierSchemeCodeUnspsc190501.50601766'),
	'identifierSchemeCodeUnspsc190501.50601767': __('identifierSchemeCodeUnspsc190501.50601767'),
	'identifierSchemeCodeUnspsc190501.50601768': __('identifierSchemeCodeUnspsc190501.50601768'),
	'identifierSchemeCodeUnspsc190501.50601769': __('identifierSchemeCodeUnspsc190501.50601769'),
	'identifierSchemeCodeUnspsc190501.50601770': __('identifierSchemeCodeUnspsc190501.50601770'),
	'identifierSchemeCodeUnspsc190501.50601771': __('identifierSchemeCodeUnspsc190501.50601771'),
	'identifierSchemeCodeUnspsc190501.50601772': __('identifierSchemeCodeUnspsc190501.50601772'),
	'identifierSchemeCodeUnspsc190501.50601773': __('identifierSchemeCodeUnspsc190501.50601773'),
	'identifierSchemeCodeUnspsc190501.50601774': __('identifierSchemeCodeUnspsc190501.50601774'),
	'identifierSchemeCodeUnspsc190501.50601775': __('identifierSchemeCodeUnspsc190501.50601775'),
	'identifierSchemeCodeUnspsc190501.50601776': __('identifierSchemeCodeUnspsc190501.50601776'),
	'identifierSchemeCodeUnspsc190501.50601777': __('identifierSchemeCodeUnspsc190501.50601777'),
	'identifierSchemeCodeUnspsc190501.50601778': __('identifierSchemeCodeUnspsc190501.50601778'),
	'identifierSchemeCodeUnspsc190501.50601779': __('identifierSchemeCodeUnspsc190501.50601779'),
	'identifierSchemeCodeUnspsc190501.50601780': __('identifierSchemeCodeUnspsc190501.50601780'),
	'identifierSchemeCodeUnspsc190501.50601781': __('identifierSchemeCodeUnspsc190501.50601781'),
	'identifierSchemeCodeUnspsc190501.50601782': __('identifierSchemeCodeUnspsc190501.50601782'),
	'identifierSchemeCodeUnspsc190501.50601800': __('identifierSchemeCodeUnspsc190501.50601800'),
	'identifierSchemeCodeUnspsc190501.50601801': __('identifierSchemeCodeUnspsc190501.50601801'),
	'identifierSchemeCodeUnspsc190501.50601802': __('identifierSchemeCodeUnspsc190501.50601802'),
	'identifierSchemeCodeUnspsc190501.50601803': __('identifierSchemeCodeUnspsc190501.50601803'),
	'identifierSchemeCodeUnspsc190501.50601804': __('identifierSchemeCodeUnspsc190501.50601804'),
	'identifierSchemeCodeUnspsc190501.50601805': __('identifierSchemeCodeUnspsc190501.50601805'),
	'identifierSchemeCodeUnspsc190501.50601806': __('identifierSchemeCodeUnspsc190501.50601806'),
	'identifierSchemeCodeUnspsc190501.50601807': __('identifierSchemeCodeUnspsc190501.50601807'),
	'identifierSchemeCodeUnspsc190501.50601808': __('identifierSchemeCodeUnspsc190501.50601808'),
	'identifierSchemeCodeUnspsc190501.50601809': __('identifierSchemeCodeUnspsc190501.50601809'),
	'identifierSchemeCodeUnspsc190501.50601810': __('identifierSchemeCodeUnspsc190501.50601810'),
	'identifierSchemeCodeUnspsc190501.50601811': __('identifierSchemeCodeUnspsc190501.50601811'),
	'identifierSchemeCodeUnspsc190501.50601812': __('identifierSchemeCodeUnspsc190501.50601812'),
	'identifierSchemeCodeUnspsc190501.50601813': __('identifierSchemeCodeUnspsc190501.50601813'),
	'identifierSchemeCodeUnspsc190501.50601814': __('identifierSchemeCodeUnspsc190501.50601814'),
	'identifierSchemeCodeUnspsc190501.50601815': __('identifierSchemeCodeUnspsc190501.50601815'),
	'identifierSchemeCodeUnspsc190501.50601816': __('identifierSchemeCodeUnspsc190501.50601816'),
	'identifierSchemeCodeUnspsc190501.50601817': __('identifierSchemeCodeUnspsc190501.50601817'),
	'identifierSchemeCodeUnspsc190501.50601818': __('identifierSchemeCodeUnspsc190501.50601818'),
	'identifierSchemeCodeUnspsc190501.50601819': __('identifierSchemeCodeUnspsc190501.50601819'),
	'identifierSchemeCodeUnspsc190501.50601820': __('identifierSchemeCodeUnspsc190501.50601820'),
	'identifierSchemeCodeUnspsc190501.50601821': __('identifierSchemeCodeUnspsc190501.50601821'),
	'identifierSchemeCodeUnspsc190501.50601822': __('identifierSchemeCodeUnspsc190501.50601822'),
	'identifierSchemeCodeUnspsc190501.50601823': __('identifierSchemeCodeUnspsc190501.50601823'),
	'identifierSchemeCodeUnspsc190501.50601824': __('identifierSchemeCodeUnspsc190501.50601824'),
	'identifierSchemeCodeUnspsc190501.50601825': __('identifierSchemeCodeUnspsc190501.50601825'),
	'identifierSchemeCodeUnspsc190501.50601826': __('identifierSchemeCodeUnspsc190501.50601826'),
	'identifierSchemeCodeUnspsc190501.50601827': __('identifierSchemeCodeUnspsc190501.50601827'),
	'identifierSchemeCodeUnspsc190501.50601828': __('identifierSchemeCodeUnspsc190501.50601828'),
	'identifierSchemeCodeUnspsc190501.50601829': __('identifierSchemeCodeUnspsc190501.50601829'),
	'identifierSchemeCodeUnspsc190501.50601830': __('identifierSchemeCodeUnspsc190501.50601830'),
	'identifierSchemeCodeUnspsc190501.50601831': __('identifierSchemeCodeUnspsc190501.50601831'),
	'identifierSchemeCodeUnspsc190501.50601832': __('identifierSchemeCodeUnspsc190501.50601832'),
	'identifierSchemeCodeUnspsc190501.50601833': __('identifierSchemeCodeUnspsc190501.50601833'),
	'identifierSchemeCodeUnspsc190501.50601834': __('identifierSchemeCodeUnspsc190501.50601834'),
	'identifierSchemeCodeUnspsc190501.50601835': __('identifierSchemeCodeUnspsc190501.50601835'),
	'identifierSchemeCodeUnspsc190501.50601836': __('identifierSchemeCodeUnspsc190501.50601836'),
	'identifierSchemeCodeUnspsc190501.50601837': __('identifierSchemeCodeUnspsc190501.50601837'),
	'identifierSchemeCodeUnspsc190501.50601838': __('identifierSchemeCodeUnspsc190501.50601838'),
	'identifierSchemeCodeUnspsc190501.50601839': __('identifierSchemeCodeUnspsc190501.50601839'),
	'identifierSchemeCodeUnspsc190501.50601840': __('identifierSchemeCodeUnspsc190501.50601840'),
	'identifierSchemeCodeUnspsc190501.50601841': __('identifierSchemeCodeUnspsc190501.50601841'),
	'identifierSchemeCodeUnspsc190501.50601842': __('identifierSchemeCodeUnspsc190501.50601842'),
	'identifierSchemeCodeUnspsc190501.50601843': __('identifierSchemeCodeUnspsc190501.50601843'),
	'identifierSchemeCodeUnspsc190501.50601844': __('identifierSchemeCodeUnspsc190501.50601844'),
	'identifierSchemeCodeUnspsc190501.50601845': __('identifierSchemeCodeUnspsc190501.50601845'),
	'identifierSchemeCodeUnspsc190501.50601846': __('identifierSchemeCodeUnspsc190501.50601846'),
	'identifierSchemeCodeUnspsc190501.50601847': __('identifierSchemeCodeUnspsc190501.50601847'),
	'identifierSchemeCodeUnspsc190501.50601848': __('identifierSchemeCodeUnspsc190501.50601848'),
	'identifierSchemeCodeUnspsc190501.50601849': __('identifierSchemeCodeUnspsc190501.50601849'),
	'identifierSchemeCodeUnspsc190501.50601850': __('identifierSchemeCodeUnspsc190501.50601850'),
	'identifierSchemeCodeUnspsc190501.50601851': __('identifierSchemeCodeUnspsc190501.50601851'),
	'identifierSchemeCodeUnspsc190501.50601852': __('identifierSchemeCodeUnspsc190501.50601852'),
	'identifierSchemeCodeUnspsc190501.50601900': __('identifierSchemeCodeUnspsc190501.50601900'),
	'identifierSchemeCodeUnspsc190501.50601901': __('identifierSchemeCodeUnspsc190501.50601901'),
	'identifierSchemeCodeUnspsc190501.50601902': __('identifierSchemeCodeUnspsc190501.50601902'),
	'identifierSchemeCodeUnspsc190501.50601903': __('identifierSchemeCodeUnspsc190501.50601903'),
	'identifierSchemeCodeUnspsc190501.50601904': __('identifierSchemeCodeUnspsc190501.50601904'),
	'identifierSchemeCodeUnspsc190501.50601905': __('identifierSchemeCodeUnspsc190501.50601905'),
	'identifierSchemeCodeUnspsc190501.50601906': __('identifierSchemeCodeUnspsc190501.50601906'),
	'identifierSchemeCodeUnspsc190501.50601907': __('identifierSchemeCodeUnspsc190501.50601907'),
	'identifierSchemeCodeUnspsc190501.50601908': __('identifierSchemeCodeUnspsc190501.50601908'),
	'identifierSchemeCodeUnspsc190501.50601909': __('identifierSchemeCodeUnspsc190501.50601909'),
	'identifierSchemeCodeUnspsc190501.50601910': __('identifierSchemeCodeUnspsc190501.50601910'),
	'identifierSchemeCodeUnspsc190501.50601911': __('identifierSchemeCodeUnspsc190501.50601911'),
	'identifierSchemeCodeUnspsc190501.50601912': __('identifierSchemeCodeUnspsc190501.50601912'),
	'identifierSchemeCodeUnspsc190501.50601913': __('identifierSchemeCodeUnspsc190501.50601913'),
	'identifierSchemeCodeUnspsc190501.50601914': __('identifierSchemeCodeUnspsc190501.50601914'),
	'identifierSchemeCodeUnspsc190501.50601915': __('identifierSchemeCodeUnspsc190501.50601915'),
	'identifierSchemeCodeUnspsc190501.50601916': __('identifierSchemeCodeUnspsc190501.50601916'),
	'identifierSchemeCodeUnspsc190501.50601917': __('identifierSchemeCodeUnspsc190501.50601917'),
	'identifierSchemeCodeUnspsc190501.50601918': __('identifierSchemeCodeUnspsc190501.50601918'),
	'identifierSchemeCodeUnspsc190501.50601919': __('identifierSchemeCodeUnspsc190501.50601919'),
	'identifierSchemeCodeUnspsc190501.50601920': __('identifierSchemeCodeUnspsc190501.50601920'),
	'identifierSchemeCodeUnspsc190501.50601921': __('identifierSchemeCodeUnspsc190501.50601921'),
	'identifierSchemeCodeUnspsc190501.50601922': __('identifierSchemeCodeUnspsc190501.50601922'),
	'identifierSchemeCodeUnspsc190501.50602000': __('identifierSchemeCodeUnspsc190501.50602000'),
	'identifierSchemeCodeUnspsc190501.50602001': __('identifierSchemeCodeUnspsc190501.50602001'),
	'identifierSchemeCodeUnspsc190501.50602002': __('identifierSchemeCodeUnspsc190501.50602002'),
	'identifierSchemeCodeUnspsc190501.50602003': __('identifierSchemeCodeUnspsc190501.50602003'),
	'identifierSchemeCodeUnspsc190501.50602004': __('identifierSchemeCodeUnspsc190501.50602004'),
	'identifierSchemeCodeUnspsc190501.50602100': __('identifierSchemeCodeUnspsc190501.50602100'),
	'identifierSchemeCodeUnspsc190501.50602101': __('identifierSchemeCodeUnspsc190501.50602101'),
	'identifierSchemeCodeUnspsc190501.50602102': __('identifierSchemeCodeUnspsc190501.50602102'),
	'identifierSchemeCodeUnspsc190501.50602103': __('identifierSchemeCodeUnspsc190501.50602103'),
	'identifierSchemeCodeUnspsc190501.50602104': __('identifierSchemeCodeUnspsc190501.50602104'),
	'identifierSchemeCodeUnspsc190501.50602105': __('identifierSchemeCodeUnspsc190501.50602105'),
	'identifierSchemeCodeUnspsc190501.50602106': __('identifierSchemeCodeUnspsc190501.50602106'),
	'identifierSchemeCodeUnspsc190501.50602107': __('identifierSchemeCodeUnspsc190501.50602107'),
	'identifierSchemeCodeUnspsc190501.50602200': __('identifierSchemeCodeUnspsc190501.50602200'),
	'identifierSchemeCodeUnspsc190501.50602201': __('identifierSchemeCodeUnspsc190501.50602201'),
	'identifierSchemeCodeUnspsc190501.50602202': __('identifierSchemeCodeUnspsc190501.50602202'),
	'identifierSchemeCodeUnspsc190501.50602203': __('identifierSchemeCodeUnspsc190501.50602203'),
	'identifierSchemeCodeUnspsc190501.50602204': __('identifierSchemeCodeUnspsc190501.50602204'),
	'identifierSchemeCodeUnspsc190501.50602205': __('identifierSchemeCodeUnspsc190501.50602205'),
	'identifierSchemeCodeUnspsc190501.50602206': __('identifierSchemeCodeUnspsc190501.50602206'),
	'identifierSchemeCodeUnspsc190501.50602207': __('identifierSchemeCodeUnspsc190501.50602207'),
	'identifierSchemeCodeUnspsc190501.50602208': __('identifierSchemeCodeUnspsc190501.50602208'),
	'identifierSchemeCodeUnspsc190501.50602209': __('identifierSchemeCodeUnspsc190501.50602209'),
	'identifierSchemeCodeUnspsc190501.50602210': __('identifierSchemeCodeUnspsc190501.50602210'),
	'identifierSchemeCodeUnspsc190501.50602211': __('identifierSchemeCodeUnspsc190501.50602211'),
	'identifierSchemeCodeUnspsc190501.50602300': __('identifierSchemeCodeUnspsc190501.50602300'),
	'identifierSchemeCodeUnspsc190501.50602301': __('identifierSchemeCodeUnspsc190501.50602301'),
	'identifierSchemeCodeUnspsc190501.50602302': __('identifierSchemeCodeUnspsc190501.50602302'),
	'identifierSchemeCodeUnspsc190501.50602303': __('identifierSchemeCodeUnspsc190501.50602303'),
	'identifierSchemeCodeUnspsc190501.50602304': __('identifierSchemeCodeUnspsc190501.50602304'),
	'identifierSchemeCodeUnspsc190501.50602400': __('identifierSchemeCodeUnspsc190501.50602400'),
	'identifierSchemeCodeUnspsc190501.50602401': __('identifierSchemeCodeUnspsc190501.50602401'),
	'identifierSchemeCodeUnspsc190501.50602402': __('identifierSchemeCodeUnspsc190501.50602402'),
	'identifierSchemeCodeUnspsc190501.50602500': __('identifierSchemeCodeUnspsc190501.50602500'),
	'identifierSchemeCodeUnspsc190501.50602501': __('identifierSchemeCodeUnspsc190501.50602501'),
	'identifierSchemeCodeUnspsc190501.50602502': __('identifierSchemeCodeUnspsc190501.50602502'),
	'identifierSchemeCodeUnspsc190501.50602503': __('identifierSchemeCodeUnspsc190501.50602503'),
	'identifierSchemeCodeUnspsc190501.50602504': __('identifierSchemeCodeUnspsc190501.50602504'),
	'identifierSchemeCodeUnspsc190501.50602505': __('identifierSchemeCodeUnspsc190501.50602505'),
	'identifierSchemeCodeUnspsc190501.50602506': __('identifierSchemeCodeUnspsc190501.50602506'),
	'identifierSchemeCodeUnspsc190501.50602600': __('identifierSchemeCodeUnspsc190501.50602600'),
	'identifierSchemeCodeUnspsc190501.50602601': __('identifierSchemeCodeUnspsc190501.50602601'),
	'identifierSchemeCodeUnspsc190501.50602602': __('identifierSchemeCodeUnspsc190501.50602602'),
	'identifierSchemeCodeUnspsc190501.50602603': __('identifierSchemeCodeUnspsc190501.50602603'),
	'identifierSchemeCodeUnspsc190501.50602604': __('identifierSchemeCodeUnspsc190501.50602604'),
	'identifierSchemeCodeUnspsc190501.50602605': __('identifierSchemeCodeUnspsc190501.50602605'),
	'identifierSchemeCodeUnspsc190501.50602606': __('identifierSchemeCodeUnspsc190501.50602606'),
	'identifierSchemeCodeUnspsc190501.50602607': __('identifierSchemeCodeUnspsc190501.50602607'),
	'identifierSchemeCodeUnspsc190501.50602608': __('identifierSchemeCodeUnspsc190501.50602608'),
	'identifierSchemeCodeUnspsc190501.50602609': __('identifierSchemeCodeUnspsc190501.50602609'),
	'identifierSchemeCodeUnspsc190501.50602610': __('identifierSchemeCodeUnspsc190501.50602610'),
	'identifierSchemeCodeUnspsc190501.50602611': __('identifierSchemeCodeUnspsc190501.50602611'),
	'identifierSchemeCodeUnspsc190501.50602612': __('identifierSchemeCodeUnspsc190501.50602612'),
	'identifierSchemeCodeUnspsc190501.50602700': __('identifierSchemeCodeUnspsc190501.50602700'),
	'identifierSchemeCodeUnspsc190501.50602701': __('identifierSchemeCodeUnspsc190501.50602701'),
	'identifierSchemeCodeUnspsc190501.50602702': __('identifierSchemeCodeUnspsc190501.50602702'),
	'identifierSchemeCodeUnspsc190501.50602703': __('identifierSchemeCodeUnspsc190501.50602703'),
	'identifierSchemeCodeUnspsc190501.50602704': __('identifierSchemeCodeUnspsc190501.50602704'),
	'identifierSchemeCodeUnspsc190501.50602705': __('identifierSchemeCodeUnspsc190501.50602705'),
	'identifierSchemeCodeUnspsc190501.50602706': __('identifierSchemeCodeUnspsc190501.50602706'),
	'identifierSchemeCodeUnspsc190501.50602707': __('identifierSchemeCodeUnspsc190501.50602707'),
	'identifierSchemeCodeUnspsc190501.50602708': __('identifierSchemeCodeUnspsc190501.50602708'),
	'identifierSchemeCodeUnspsc190501.50602709': __('identifierSchemeCodeUnspsc190501.50602709'),
	'identifierSchemeCodeUnspsc190501.50602710': __('identifierSchemeCodeUnspsc190501.50602710'),
	'identifierSchemeCodeUnspsc190501.50602711': __('identifierSchemeCodeUnspsc190501.50602711'),
	'identifierSchemeCodeUnspsc190501.50602712': __('identifierSchemeCodeUnspsc190501.50602712'),
	'identifierSchemeCodeUnspsc190501.50602713': __('identifierSchemeCodeUnspsc190501.50602713'),
	'identifierSchemeCodeUnspsc190501.50602714': __('identifierSchemeCodeUnspsc190501.50602714'),
	'identifierSchemeCodeUnspsc190501.50602715': __('identifierSchemeCodeUnspsc190501.50602715'),
	'identifierSchemeCodeUnspsc190501.50602800': __('identifierSchemeCodeUnspsc190501.50602800'),
	'identifierSchemeCodeUnspsc190501.50602801': __('identifierSchemeCodeUnspsc190501.50602801'),
	'identifierSchemeCodeUnspsc190501.50602802': __('identifierSchemeCodeUnspsc190501.50602802'),
	'identifierSchemeCodeUnspsc190501.50602803': __('identifierSchemeCodeUnspsc190501.50602803'),
	'identifierSchemeCodeUnspsc190501.50602804': __('identifierSchemeCodeUnspsc190501.50602804'),
	'identifierSchemeCodeUnspsc190501.50602805': __('identifierSchemeCodeUnspsc190501.50602805'),
	'identifierSchemeCodeUnspsc190501.50602806': __('identifierSchemeCodeUnspsc190501.50602806'),
	'identifierSchemeCodeUnspsc190501.50602807': __('identifierSchemeCodeUnspsc190501.50602807'),
	'identifierSchemeCodeUnspsc190501.50602808': __('identifierSchemeCodeUnspsc190501.50602808'),
	'identifierSchemeCodeUnspsc190501.50602900': __('identifierSchemeCodeUnspsc190501.50602900'),
	'identifierSchemeCodeUnspsc190501.50602901': __('identifierSchemeCodeUnspsc190501.50602901'),
	'identifierSchemeCodeUnspsc190501.50602902': __('identifierSchemeCodeUnspsc190501.50602902'),
	'identifierSchemeCodeUnspsc190501.50602903': __('identifierSchemeCodeUnspsc190501.50602903'),
	'identifierSchemeCodeUnspsc190501.50602904': __('identifierSchemeCodeUnspsc190501.50602904'),
	'identifierSchemeCodeUnspsc190501.50602905': __('identifierSchemeCodeUnspsc190501.50602905'),
	'identifierSchemeCodeUnspsc190501.50602906': __('identifierSchemeCodeUnspsc190501.50602906'),
	'identifierSchemeCodeUnspsc190501.50602907': __('identifierSchemeCodeUnspsc190501.50602907'),
	'identifierSchemeCodeUnspsc190501.50602908': __('identifierSchemeCodeUnspsc190501.50602908'),
	'identifierSchemeCodeUnspsc190501.50602909': __('identifierSchemeCodeUnspsc190501.50602909'),
	'identifierSchemeCodeUnspsc190501.50602910': __('identifierSchemeCodeUnspsc190501.50602910'),
	'identifierSchemeCodeUnspsc190501.50602911': __('identifierSchemeCodeUnspsc190501.50602911'),
	'identifierSchemeCodeUnspsc190501.50603000': __('identifierSchemeCodeUnspsc190501.50603000'),
	'identifierSchemeCodeUnspsc190501.50603001': __('identifierSchemeCodeUnspsc190501.50603001'),
	'identifierSchemeCodeUnspsc190501.50603002': __('identifierSchemeCodeUnspsc190501.50603002'),
	'identifierSchemeCodeUnspsc190501.50603003': __('identifierSchemeCodeUnspsc190501.50603003'),
	'identifierSchemeCodeUnspsc190501.50603004': __('identifierSchemeCodeUnspsc190501.50603004'),
	'identifierSchemeCodeUnspsc190501.50603005': __('identifierSchemeCodeUnspsc190501.50603005'),
	'identifierSchemeCodeUnspsc190501.50603006': __('identifierSchemeCodeUnspsc190501.50603006'),
	'identifierSchemeCodeUnspsc190501.50603007': __('identifierSchemeCodeUnspsc190501.50603007'),
	'identifierSchemeCodeUnspsc190501.50603008': __('identifierSchemeCodeUnspsc190501.50603008'),
	'identifierSchemeCodeUnspsc190501.50603009': __('identifierSchemeCodeUnspsc190501.50603009'),
	'identifierSchemeCodeUnspsc190501.50603010': __('identifierSchemeCodeUnspsc190501.50603010'),
	'identifierSchemeCodeUnspsc190501.50603011': __('identifierSchemeCodeUnspsc190501.50603011'),
	'identifierSchemeCodeUnspsc190501.50603012': __('identifierSchemeCodeUnspsc190501.50603012'),
	'identifierSchemeCodeUnspsc190501.50603013': __('identifierSchemeCodeUnspsc190501.50603013'),
	'identifierSchemeCodeUnspsc190501.50603014': __('identifierSchemeCodeUnspsc190501.50603014'),
	'identifierSchemeCodeUnspsc190501.50603015': __('identifierSchemeCodeUnspsc190501.50603015'),
	'identifierSchemeCodeUnspsc190501.50603016': __('identifierSchemeCodeUnspsc190501.50603016'),
	'identifierSchemeCodeUnspsc190501.50603100': __('identifierSchemeCodeUnspsc190501.50603100'),
	'identifierSchemeCodeUnspsc190501.50603101': __('identifierSchemeCodeUnspsc190501.50603101'),
	'identifierSchemeCodeUnspsc190501.50603102': __('identifierSchemeCodeUnspsc190501.50603102'),
	'identifierSchemeCodeUnspsc190501.50603200': __('identifierSchemeCodeUnspsc190501.50603200'),
	'identifierSchemeCodeUnspsc190501.50603201': __('identifierSchemeCodeUnspsc190501.50603201'),
	'identifierSchemeCodeUnspsc190501.50603202': __('identifierSchemeCodeUnspsc190501.50603202'),
	'identifierSchemeCodeUnspsc190501.50603203': __('identifierSchemeCodeUnspsc190501.50603203'),
	'identifierSchemeCodeUnspsc190501.50603204': __('identifierSchemeCodeUnspsc190501.50603204'),
	'identifierSchemeCodeUnspsc190501.50603205': __('identifierSchemeCodeUnspsc190501.50603205'),
	'identifierSchemeCodeUnspsc190501.50603206': __('identifierSchemeCodeUnspsc190501.50603206'),
	'identifierSchemeCodeUnspsc190501.50603207': __('identifierSchemeCodeUnspsc190501.50603207'),
	'identifierSchemeCodeUnspsc190501.50603208': __('identifierSchemeCodeUnspsc190501.50603208'),
	'identifierSchemeCodeUnspsc190501.50603209': __('identifierSchemeCodeUnspsc190501.50603209'),
	'identifierSchemeCodeUnspsc190501.50603210': __('identifierSchemeCodeUnspsc190501.50603210'),
	'identifierSchemeCodeUnspsc190501.50603211': __('identifierSchemeCodeUnspsc190501.50603211'),
	'identifierSchemeCodeUnspsc190501.50603212': __('identifierSchemeCodeUnspsc190501.50603212'),
	'identifierSchemeCodeUnspsc190501.50603213': __('identifierSchemeCodeUnspsc190501.50603213'),
	'identifierSchemeCodeUnspsc190501.50603214': __('identifierSchemeCodeUnspsc190501.50603214'),
	'identifierSchemeCodeUnspsc190501.50603215': __('identifierSchemeCodeUnspsc190501.50603215'),
	'identifierSchemeCodeUnspsc190501.50603216': __('identifierSchemeCodeUnspsc190501.50603216'),
	'identifierSchemeCodeUnspsc190501.50603217': __('identifierSchemeCodeUnspsc190501.50603217'),
	'identifierSchemeCodeUnspsc190501.50603218': __('identifierSchemeCodeUnspsc190501.50603218'),
	'identifierSchemeCodeUnspsc190501.50603219': __('identifierSchemeCodeUnspsc190501.50603219'),
	'identifierSchemeCodeUnspsc190501.50603220': __('identifierSchemeCodeUnspsc190501.50603220'),
	'identifierSchemeCodeUnspsc190501.50603221': __('identifierSchemeCodeUnspsc190501.50603221'),
	'identifierSchemeCodeUnspsc190501.50603222': __('identifierSchemeCodeUnspsc190501.50603222'),
	'identifierSchemeCodeUnspsc190501.50603223': __('identifierSchemeCodeUnspsc190501.50603223'),
	'identifierSchemeCodeUnspsc190501.50603224': __('identifierSchemeCodeUnspsc190501.50603224'),
	'identifierSchemeCodeUnspsc190501.50603225': __('identifierSchemeCodeUnspsc190501.50603225'),
	'identifierSchemeCodeUnspsc190501.50603226': __('identifierSchemeCodeUnspsc190501.50603226'),
	'identifierSchemeCodeUnspsc190501.50603227': __('identifierSchemeCodeUnspsc190501.50603227'),
	'identifierSchemeCodeUnspsc190501.50603228': __('identifierSchemeCodeUnspsc190501.50603228'),
	'identifierSchemeCodeUnspsc190501.50603229': __('identifierSchemeCodeUnspsc190501.50603229'),
	'identifierSchemeCodeUnspsc190501.50603230': __('identifierSchemeCodeUnspsc190501.50603230'),
	'identifierSchemeCodeUnspsc190501.50603231': __('identifierSchemeCodeUnspsc190501.50603231'),
	'identifierSchemeCodeUnspsc190501.50603232': __('identifierSchemeCodeUnspsc190501.50603232'),
	'identifierSchemeCodeUnspsc190501.50603233': __('identifierSchemeCodeUnspsc190501.50603233'),
	'identifierSchemeCodeUnspsc190501.50603234': __('identifierSchemeCodeUnspsc190501.50603234'),
	'identifierSchemeCodeUnspsc190501.50603235': __('identifierSchemeCodeUnspsc190501.50603235'),
	'identifierSchemeCodeUnspsc190501.50603236': __('identifierSchemeCodeUnspsc190501.50603236'),
	'identifierSchemeCodeUnspsc190501.50603237': __('identifierSchemeCodeUnspsc190501.50603237'),
	'identifierSchemeCodeUnspsc190501.50603238': __('identifierSchemeCodeUnspsc190501.50603238'),
	'identifierSchemeCodeUnspsc190501.50603239': __('identifierSchemeCodeUnspsc190501.50603239'),
	'identifierSchemeCodeUnspsc190501.50603240': __('identifierSchemeCodeUnspsc190501.50603240'),
	'identifierSchemeCodeUnspsc190501.50603241': __('identifierSchemeCodeUnspsc190501.50603241'),
	'identifierSchemeCodeUnspsc190501.50603242': __('identifierSchemeCodeUnspsc190501.50603242'),
	'identifierSchemeCodeUnspsc190501.50603243': __('identifierSchemeCodeUnspsc190501.50603243'),
	'identifierSchemeCodeUnspsc190501.50603244': __('identifierSchemeCodeUnspsc190501.50603244'),
	'identifierSchemeCodeUnspsc190501.50603245': __('identifierSchemeCodeUnspsc190501.50603245'),
	'identifierSchemeCodeUnspsc190501.50603246': __('identifierSchemeCodeUnspsc190501.50603246'),
	'identifierSchemeCodeUnspsc190501.50603247': __('identifierSchemeCodeUnspsc190501.50603247'),
	'identifierSchemeCodeUnspsc190501.50603248': __('identifierSchemeCodeUnspsc190501.50603248'),
	'identifierSchemeCodeUnspsc190501.50603249': __('identifierSchemeCodeUnspsc190501.50603249'),
	'identifierSchemeCodeUnspsc190501.50603250': __('identifierSchemeCodeUnspsc190501.50603250'),
	'identifierSchemeCodeUnspsc190501.50603251': __('identifierSchemeCodeUnspsc190501.50603251'),
	'identifierSchemeCodeUnspsc190501.50603252': __('identifierSchemeCodeUnspsc190501.50603252'),
	'identifierSchemeCodeUnspsc190501.50603253': __('identifierSchemeCodeUnspsc190501.50603253'),
	'identifierSchemeCodeUnspsc190501.50603254': __('identifierSchemeCodeUnspsc190501.50603254'),
	'identifierSchemeCodeUnspsc190501.50603255': __('identifierSchemeCodeUnspsc190501.50603255'),
	'identifierSchemeCodeUnspsc190501.50603256': __('identifierSchemeCodeUnspsc190501.50603256'),
	'identifierSchemeCodeUnspsc190501.50603257': __('identifierSchemeCodeUnspsc190501.50603257'),
	'identifierSchemeCodeUnspsc190501.50603258': __('identifierSchemeCodeUnspsc190501.50603258'),
	'identifierSchemeCodeUnspsc190501.50603259': __('identifierSchemeCodeUnspsc190501.50603259'),
	'identifierSchemeCodeUnspsc190501.50603260': __('identifierSchemeCodeUnspsc190501.50603260'),
	'identifierSchemeCodeUnspsc190501.50603261': __('identifierSchemeCodeUnspsc190501.50603261'),
	'identifierSchemeCodeUnspsc190501.50603262': __('identifierSchemeCodeUnspsc190501.50603262'),
	'identifierSchemeCodeUnspsc190501.50603263': __('identifierSchemeCodeUnspsc190501.50603263'),
	'identifierSchemeCodeUnspsc190501.50603264': __('identifierSchemeCodeUnspsc190501.50603264'),
	'identifierSchemeCodeUnspsc190501.50603265': __('identifierSchemeCodeUnspsc190501.50603265'),
	'identifierSchemeCodeUnspsc190501.50603266': __('identifierSchemeCodeUnspsc190501.50603266'),
	'identifierSchemeCodeUnspsc190501.50603267': __('identifierSchemeCodeUnspsc190501.50603267'),
	'identifierSchemeCodeUnspsc190501.50603268': __('identifierSchemeCodeUnspsc190501.50603268'),
	'identifierSchemeCodeUnspsc190501.50603269': __('identifierSchemeCodeUnspsc190501.50603269'),
	'identifierSchemeCodeUnspsc190501.50603300': __('identifierSchemeCodeUnspsc190501.50603300'),
	'identifierSchemeCodeUnspsc190501.50603301': __('identifierSchemeCodeUnspsc190501.50603301'),
	'identifierSchemeCodeUnspsc190501.50603302': __('identifierSchemeCodeUnspsc190501.50603302'),
	'identifierSchemeCodeUnspsc190501.50603303': __('identifierSchemeCodeUnspsc190501.50603303'),
	'identifierSchemeCodeUnspsc190501.50603304': __('identifierSchemeCodeUnspsc190501.50603304'),
	'identifierSchemeCodeUnspsc190501.50603400': __('identifierSchemeCodeUnspsc190501.50603400'),
	'identifierSchemeCodeUnspsc190501.50603401': __('identifierSchemeCodeUnspsc190501.50603401'),
	'identifierSchemeCodeUnspsc190501.50603402': __('identifierSchemeCodeUnspsc190501.50603402'),
	'identifierSchemeCodeUnspsc190501.50603403': __('identifierSchemeCodeUnspsc190501.50603403'),
	'identifierSchemeCodeUnspsc190501.50603404': __('identifierSchemeCodeUnspsc190501.50603404'),
	'identifierSchemeCodeUnspsc190501.50603405': __('identifierSchemeCodeUnspsc190501.50603405'),
	'identifierSchemeCodeUnspsc190501.50603406': __('identifierSchemeCodeUnspsc190501.50603406'),
	'identifierSchemeCodeUnspsc190501.50603407': __('identifierSchemeCodeUnspsc190501.50603407'),
	'identifierSchemeCodeUnspsc190501.50603408': __('identifierSchemeCodeUnspsc190501.50603408'),
	'identifierSchemeCodeUnspsc190501.50603409': __('identifierSchemeCodeUnspsc190501.50603409'),
	'identifierSchemeCodeUnspsc190501.50603410': __('identifierSchemeCodeUnspsc190501.50603410'),
	'identifierSchemeCodeUnspsc190501.50603411': __('identifierSchemeCodeUnspsc190501.50603411'),
	'identifierSchemeCodeUnspsc190501.50603412': __('identifierSchemeCodeUnspsc190501.50603412'),
	'identifierSchemeCodeUnspsc190501.50603413': __('identifierSchemeCodeUnspsc190501.50603413'),
	'identifierSchemeCodeUnspsc190501.50603414': __('identifierSchemeCodeUnspsc190501.50603414'),
	'identifierSchemeCodeUnspsc190501.50603415': __('identifierSchemeCodeUnspsc190501.50603415'),
	'identifierSchemeCodeUnspsc190501.50603416': __('identifierSchemeCodeUnspsc190501.50603416'),
	'identifierSchemeCodeUnspsc190501.50603417': __('identifierSchemeCodeUnspsc190501.50603417'),
	'identifierSchemeCodeUnspsc190501.50603418': __('identifierSchemeCodeUnspsc190501.50603418'),
	'identifierSchemeCodeUnspsc190501.50603419': __('identifierSchemeCodeUnspsc190501.50603419'),
	'identifierSchemeCodeUnspsc190501.50603420': __('identifierSchemeCodeUnspsc190501.50603420'),
	'identifierSchemeCodeUnspsc190501.50603421': __('identifierSchemeCodeUnspsc190501.50603421'),
	'identifierSchemeCodeUnspsc190501.50603422': __('identifierSchemeCodeUnspsc190501.50603422'),
	'identifierSchemeCodeUnspsc190501.50603423': __('identifierSchemeCodeUnspsc190501.50603423'),
	'identifierSchemeCodeUnspsc190501.50603500': __('identifierSchemeCodeUnspsc190501.50603500'),
	'identifierSchemeCodeUnspsc190501.50603501': __('identifierSchemeCodeUnspsc190501.50603501'),
	'identifierSchemeCodeUnspsc190501.50603502': __('identifierSchemeCodeUnspsc190501.50603502'),
	'identifierSchemeCodeUnspsc190501.50603503': __('identifierSchemeCodeUnspsc190501.50603503'),
	'identifierSchemeCodeUnspsc190501.50603504': __('identifierSchemeCodeUnspsc190501.50603504'),
	'identifierSchemeCodeUnspsc190501.50603505': __('identifierSchemeCodeUnspsc190501.50603505'),
	'identifierSchemeCodeUnspsc190501.50603506': __('identifierSchemeCodeUnspsc190501.50603506'),
	'identifierSchemeCodeUnspsc190501.50603507': __('identifierSchemeCodeUnspsc190501.50603507'),
	'identifierSchemeCodeUnspsc190501.50603508': __('identifierSchemeCodeUnspsc190501.50603508'),
	'identifierSchemeCodeUnspsc190501.50603509': __('identifierSchemeCodeUnspsc190501.50603509'),
	'identifierSchemeCodeUnspsc190501.50603510': __('identifierSchemeCodeUnspsc190501.50603510'),
	'identifierSchemeCodeUnspsc190501.50603511': __('identifierSchemeCodeUnspsc190501.50603511'),
	'identifierSchemeCodeUnspsc190501.50603512': __('identifierSchemeCodeUnspsc190501.50603512'),
	'identifierSchemeCodeUnspsc190501.50603513': __('identifierSchemeCodeUnspsc190501.50603513'),
	'identifierSchemeCodeUnspsc190501.50603514': __('identifierSchemeCodeUnspsc190501.50603514'),
	'identifierSchemeCodeUnspsc190501.50603515': __('identifierSchemeCodeUnspsc190501.50603515'),
	'identifierSchemeCodeUnspsc190501.50603516': __('identifierSchemeCodeUnspsc190501.50603516'),
	'identifierSchemeCodeUnspsc190501.50603517': __('identifierSchemeCodeUnspsc190501.50603517'),
	'identifierSchemeCodeUnspsc190501.50603518': __('identifierSchemeCodeUnspsc190501.50603518'),
	'identifierSchemeCodeUnspsc190501.50603519': __('identifierSchemeCodeUnspsc190501.50603519'),
	'identifierSchemeCodeUnspsc190501.50603520': __('identifierSchemeCodeUnspsc190501.50603520'),
	'identifierSchemeCodeUnspsc190501.50603600': __('identifierSchemeCodeUnspsc190501.50603600'),
	'identifierSchemeCodeUnspsc190501.50603601': __('identifierSchemeCodeUnspsc190501.50603601'),
	'identifierSchemeCodeUnspsc190501.50603602': __('identifierSchemeCodeUnspsc190501.50603602'),
	'identifierSchemeCodeUnspsc190501.50603603': __('identifierSchemeCodeUnspsc190501.50603603'),
	'identifierSchemeCodeUnspsc190501.50603604': __('identifierSchemeCodeUnspsc190501.50603604'),
	'identifierSchemeCodeUnspsc190501.50603605': __('identifierSchemeCodeUnspsc190501.50603605'),
	'identifierSchemeCodeUnspsc190501.50603606': __('identifierSchemeCodeUnspsc190501.50603606'),
	'identifierSchemeCodeUnspsc190501.50603607': __('identifierSchemeCodeUnspsc190501.50603607'),
	'identifierSchemeCodeUnspsc190501.50603608': __('identifierSchemeCodeUnspsc190501.50603608'),
	'identifierSchemeCodeUnspsc190501.50603609': __('identifierSchemeCodeUnspsc190501.50603609'),
	'identifierSchemeCodeUnspsc190501.50603610': __('identifierSchemeCodeUnspsc190501.50603610'),
	'identifierSchemeCodeUnspsc190501.50603611': __('identifierSchemeCodeUnspsc190501.50603611'),
	'identifierSchemeCodeUnspsc190501.50603612': __('identifierSchemeCodeUnspsc190501.50603612'),
	'identifierSchemeCodeUnspsc190501.50603613': __('identifierSchemeCodeUnspsc190501.50603613'),
	'identifierSchemeCodeUnspsc190501.50603614': __('identifierSchemeCodeUnspsc190501.50603614'),
	'identifierSchemeCodeUnspsc190501.50603700': __('identifierSchemeCodeUnspsc190501.50603700'),
	'identifierSchemeCodeUnspsc190501.50603701': __('identifierSchemeCodeUnspsc190501.50603701'),
	'identifierSchemeCodeUnspsc190501.50603702': __('identifierSchemeCodeUnspsc190501.50603702'),
	'identifierSchemeCodeUnspsc190501.50603703': __('identifierSchemeCodeUnspsc190501.50603703'),
	'identifierSchemeCodeUnspsc190501.50603704': __('identifierSchemeCodeUnspsc190501.50603704'),
	'identifierSchemeCodeUnspsc190501.50603705': __('identifierSchemeCodeUnspsc190501.50603705'),
	'identifierSchemeCodeUnspsc190501.50603706': __('identifierSchemeCodeUnspsc190501.50603706'),
	'identifierSchemeCodeUnspsc190501.50603707': __('identifierSchemeCodeUnspsc190501.50603707'),
	'identifierSchemeCodeUnspsc190501.50603800': __('identifierSchemeCodeUnspsc190501.50603800'),
	'identifierSchemeCodeUnspsc190501.50603801': __('identifierSchemeCodeUnspsc190501.50603801'),
	'identifierSchemeCodeUnspsc190501.50603802': __('identifierSchemeCodeUnspsc190501.50603802'),
	'identifierSchemeCodeUnspsc190501.50603803': __('identifierSchemeCodeUnspsc190501.50603803'),
	'identifierSchemeCodeUnspsc190501.50603804': __('identifierSchemeCodeUnspsc190501.50603804'),
	'identifierSchemeCodeUnspsc190501.50603805': __('identifierSchemeCodeUnspsc190501.50603805'),
	'identifierSchemeCodeUnspsc190501.50603806': __('identifierSchemeCodeUnspsc190501.50603806'),
	'identifierSchemeCodeUnspsc190501.50603807': __('identifierSchemeCodeUnspsc190501.50603807'),
	'identifierSchemeCodeUnspsc190501.50603808': __('identifierSchemeCodeUnspsc190501.50603808'),
	'identifierSchemeCodeUnspsc190501.50603809': __('identifierSchemeCodeUnspsc190501.50603809'),
	'identifierSchemeCodeUnspsc190501.50603810': __('identifierSchemeCodeUnspsc190501.50603810'),
	'identifierSchemeCodeUnspsc190501.50603811': __('identifierSchemeCodeUnspsc190501.50603811'),
	'identifierSchemeCodeUnspsc190501.50603900': __('identifierSchemeCodeUnspsc190501.50603900'),
	'identifierSchemeCodeUnspsc190501.50603901': __('identifierSchemeCodeUnspsc190501.50603901'),
	'identifierSchemeCodeUnspsc190501.50603902': __('identifierSchemeCodeUnspsc190501.50603902'),
	'identifierSchemeCodeUnspsc190501.50603903': __('identifierSchemeCodeUnspsc190501.50603903'),
	'identifierSchemeCodeUnspsc190501.50603904': __('identifierSchemeCodeUnspsc190501.50603904'),
	'identifierSchemeCodeUnspsc190501.50603905': __('identifierSchemeCodeUnspsc190501.50603905'),
	'identifierSchemeCodeUnspsc190501.50603906': __('identifierSchemeCodeUnspsc190501.50603906'),
	'identifierSchemeCodeUnspsc190501.50603907': __('identifierSchemeCodeUnspsc190501.50603907'),
	'identifierSchemeCodeUnspsc190501.50603908': __('identifierSchemeCodeUnspsc190501.50603908'),
	'identifierSchemeCodeUnspsc190501.50603909': __('identifierSchemeCodeUnspsc190501.50603909'),
	'identifierSchemeCodeUnspsc190501.50603910': __('identifierSchemeCodeUnspsc190501.50603910'),
	'identifierSchemeCodeUnspsc190501.50603911': __('identifierSchemeCodeUnspsc190501.50603911'),
	'identifierSchemeCodeUnspsc190501.50604000': __('identifierSchemeCodeUnspsc190501.50604000'),
	'identifierSchemeCodeUnspsc190501.50604001': __('identifierSchemeCodeUnspsc190501.50604001'),
	'identifierSchemeCodeUnspsc190501.50604002': __('identifierSchemeCodeUnspsc190501.50604002'),
	'identifierSchemeCodeUnspsc190501.50604003': __('identifierSchemeCodeUnspsc190501.50604003'),
	'identifierSchemeCodeUnspsc190501.50604004': __('identifierSchemeCodeUnspsc190501.50604004'),
	'identifierSchemeCodeUnspsc190501.50604005': __('identifierSchemeCodeUnspsc190501.50604005'),
	'identifierSchemeCodeUnspsc190501.50604100': __('identifierSchemeCodeUnspsc190501.50604100'),
	'identifierSchemeCodeUnspsc190501.50604101': __('identifierSchemeCodeUnspsc190501.50604101'),
	'identifierSchemeCodeUnspsc190501.50604102': __('identifierSchemeCodeUnspsc190501.50604102'),
	'identifierSchemeCodeUnspsc190501.50604103': __('identifierSchemeCodeUnspsc190501.50604103'),
	'identifierSchemeCodeUnspsc190501.50604104': __('identifierSchemeCodeUnspsc190501.50604104'),
	'identifierSchemeCodeUnspsc190501.50604105': __('identifierSchemeCodeUnspsc190501.50604105'),
	'identifierSchemeCodeUnspsc190501.50604106': __('identifierSchemeCodeUnspsc190501.50604106'),
	'identifierSchemeCodeUnspsc190501.50604107': __('identifierSchemeCodeUnspsc190501.50604107'),
	'identifierSchemeCodeUnspsc190501.50604108': __('identifierSchemeCodeUnspsc190501.50604108'),
	'identifierSchemeCodeUnspsc190501.50604109': __('identifierSchemeCodeUnspsc190501.50604109'),
	'identifierSchemeCodeUnspsc190501.50604110': __('identifierSchemeCodeUnspsc190501.50604110'),
	'identifierSchemeCodeUnspsc190501.50604111': __('identifierSchemeCodeUnspsc190501.50604111'),
	'identifierSchemeCodeUnspsc190501.50604112': __('identifierSchemeCodeUnspsc190501.50604112'),
	'identifierSchemeCodeUnspsc190501.50604113': __('identifierSchemeCodeUnspsc190501.50604113'),
	'identifierSchemeCodeUnspsc190501.50604114': __('identifierSchemeCodeUnspsc190501.50604114'),
	'identifierSchemeCodeUnspsc190501.50604115': __('identifierSchemeCodeUnspsc190501.50604115'),
	'identifierSchemeCodeUnspsc190501.50604116': __('identifierSchemeCodeUnspsc190501.50604116'),
	'identifierSchemeCodeUnspsc190501.50604117': __('identifierSchemeCodeUnspsc190501.50604117'),
	'identifierSchemeCodeUnspsc190501.50604118': __('identifierSchemeCodeUnspsc190501.50604118'),
	'identifierSchemeCodeUnspsc190501.50604119': __('identifierSchemeCodeUnspsc190501.50604119'),
	'identifierSchemeCodeUnspsc190501.50604120': __('identifierSchemeCodeUnspsc190501.50604120'),
	'identifierSchemeCodeUnspsc190501.50604121': __('identifierSchemeCodeUnspsc190501.50604121'),
	'identifierSchemeCodeUnspsc190501.50604122': __('identifierSchemeCodeUnspsc190501.50604122'),
	'identifierSchemeCodeUnspsc190501.50604123': __('identifierSchemeCodeUnspsc190501.50604123'),
	'identifierSchemeCodeUnspsc190501.50604124': __('identifierSchemeCodeUnspsc190501.50604124'),
	'identifierSchemeCodeUnspsc190501.50604125': __('identifierSchemeCodeUnspsc190501.50604125'),
	'identifierSchemeCodeUnspsc190501.50604126': __('identifierSchemeCodeUnspsc190501.50604126'),
	'identifierSchemeCodeUnspsc190501.50604127': __('identifierSchemeCodeUnspsc190501.50604127'),
	'identifierSchemeCodeUnspsc190501.50604200': __('identifierSchemeCodeUnspsc190501.50604200'),
	'identifierSchemeCodeUnspsc190501.50604201': __('identifierSchemeCodeUnspsc190501.50604201'),
	'identifierSchemeCodeUnspsc190501.50604202': __('identifierSchemeCodeUnspsc190501.50604202'),
	'identifierSchemeCodeUnspsc190501.50604300': __('identifierSchemeCodeUnspsc190501.50604300'),
	'identifierSchemeCodeUnspsc190501.50604301': __('identifierSchemeCodeUnspsc190501.50604301'),
	'identifierSchemeCodeUnspsc190501.50604302': __('identifierSchemeCodeUnspsc190501.50604302'),
	'identifierSchemeCodeUnspsc190501.50604303': __('identifierSchemeCodeUnspsc190501.50604303'),
	'identifierSchemeCodeUnspsc190501.50604304': __('identifierSchemeCodeUnspsc190501.50604304'),
	'identifierSchemeCodeUnspsc190501.50604305': __('identifierSchemeCodeUnspsc190501.50604305'),
	'identifierSchemeCodeUnspsc190501.50604306': __('identifierSchemeCodeUnspsc190501.50604306'),
	'identifierSchemeCodeUnspsc190501.50604400': __('identifierSchemeCodeUnspsc190501.50604400'),
	'identifierSchemeCodeUnspsc190501.50604401': __('identifierSchemeCodeUnspsc190501.50604401'),
	'identifierSchemeCodeUnspsc190501.50604402': __('identifierSchemeCodeUnspsc190501.50604402'),
	'identifierSchemeCodeUnspsc190501.50604403': __('identifierSchemeCodeUnspsc190501.50604403'),
	'identifierSchemeCodeUnspsc190501.50604404': __('identifierSchemeCodeUnspsc190501.50604404'),
	'identifierSchemeCodeUnspsc190501.50604405': __('identifierSchemeCodeUnspsc190501.50604405'),
	'identifierSchemeCodeUnspsc190501.50604406': __('identifierSchemeCodeUnspsc190501.50604406'),
	'identifierSchemeCodeUnspsc190501.50604500': __('identifierSchemeCodeUnspsc190501.50604500'),
	'identifierSchemeCodeUnspsc190501.50604501': __('identifierSchemeCodeUnspsc190501.50604501'),
	'identifierSchemeCodeUnspsc190501.50604502': __('identifierSchemeCodeUnspsc190501.50604502'),
	'identifierSchemeCodeUnspsc190501.50604503': __('identifierSchemeCodeUnspsc190501.50604503'),
	'identifierSchemeCodeUnspsc190501.50604504': __('identifierSchemeCodeUnspsc190501.50604504'),
	'identifierSchemeCodeUnspsc190501.50604505': __('identifierSchemeCodeUnspsc190501.50604505'),
	'identifierSchemeCodeUnspsc190501.50604506': __('identifierSchemeCodeUnspsc190501.50604506'),
	'identifierSchemeCodeUnspsc190501.50604507': __('identifierSchemeCodeUnspsc190501.50604507'),
	'identifierSchemeCodeUnspsc190501.50604508': __('identifierSchemeCodeUnspsc190501.50604508'),
	'identifierSchemeCodeUnspsc190501.50604600': __('identifierSchemeCodeUnspsc190501.50604600'),
	'identifierSchemeCodeUnspsc190501.50604601': __('identifierSchemeCodeUnspsc190501.50604601'),
	'identifierSchemeCodeUnspsc190501.50604602': __('identifierSchemeCodeUnspsc190501.50604602'),
	'identifierSchemeCodeUnspsc190501.50604603': __('identifierSchemeCodeUnspsc190501.50604603'),
	'identifierSchemeCodeUnspsc190501.50604604': __('identifierSchemeCodeUnspsc190501.50604604'),
	'identifierSchemeCodeUnspsc190501.50604605': __('identifierSchemeCodeUnspsc190501.50604605'),
	'identifierSchemeCodeUnspsc190501.50604606': __('identifierSchemeCodeUnspsc190501.50604606'),
	'identifierSchemeCodeUnspsc190501.50604607': __('identifierSchemeCodeUnspsc190501.50604607'),
	'identifierSchemeCodeUnspsc190501.50604608': __('identifierSchemeCodeUnspsc190501.50604608'),
	'identifierSchemeCodeUnspsc190501.50604609': __('identifierSchemeCodeUnspsc190501.50604609'),
	'identifierSchemeCodeUnspsc190501.50604610': __('identifierSchemeCodeUnspsc190501.50604610'),
	'identifierSchemeCodeUnspsc190501.50604611': __('identifierSchemeCodeUnspsc190501.50604611'),
	'identifierSchemeCodeUnspsc190501.50604612': __('identifierSchemeCodeUnspsc190501.50604612'),
	'identifierSchemeCodeUnspsc190501.50604613': __('identifierSchemeCodeUnspsc190501.50604613'),
	'identifierSchemeCodeUnspsc190501.50604614': __('identifierSchemeCodeUnspsc190501.50604614'),
	'identifierSchemeCodeUnspsc190501.50604615': __('identifierSchemeCodeUnspsc190501.50604615'),
	'identifierSchemeCodeUnspsc190501.50604616': __('identifierSchemeCodeUnspsc190501.50604616'),
	'identifierSchemeCodeUnspsc190501.50604617': __('identifierSchemeCodeUnspsc190501.50604617'),
	'identifierSchemeCodeUnspsc190501.50604618': __('identifierSchemeCodeUnspsc190501.50604618'),
	'identifierSchemeCodeUnspsc190501.50604619': __('identifierSchemeCodeUnspsc190501.50604619'),
	'identifierSchemeCodeUnspsc190501.50604620': __('identifierSchemeCodeUnspsc190501.50604620'),
	'identifierSchemeCodeUnspsc190501.50604621': __('identifierSchemeCodeUnspsc190501.50604621'),
	'identifierSchemeCodeUnspsc190501.50604622': __('identifierSchemeCodeUnspsc190501.50604622'),
	'identifierSchemeCodeUnspsc190501.50604623': __('identifierSchemeCodeUnspsc190501.50604623'),
	'identifierSchemeCodeUnspsc190501.50604700': __('identifierSchemeCodeUnspsc190501.50604700'),
	'identifierSchemeCodeUnspsc190501.50604701': __('identifierSchemeCodeUnspsc190501.50604701'),
	'identifierSchemeCodeUnspsc190501.50604702': __('identifierSchemeCodeUnspsc190501.50604702'),
	'identifierSchemeCodeUnspsc190501.50604703': __('identifierSchemeCodeUnspsc190501.50604703'),
	'identifierSchemeCodeUnspsc190501.50604704': __('identifierSchemeCodeUnspsc190501.50604704'),
	'identifierSchemeCodeUnspsc190501.50604705': __('identifierSchemeCodeUnspsc190501.50604705'),
	'identifierSchemeCodeUnspsc190501.50604706': __('identifierSchemeCodeUnspsc190501.50604706'),
	'identifierSchemeCodeUnspsc190501.50604800': __('identifierSchemeCodeUnspsc190501.50604800'),
	'identifierSchemeCodeUnspsc190501.50604801': __('identifierSchemeCodeUnspsc190501.50604801'),
	'identifierSchemeCodeUnspsc190501.50604802': __('identifierSchemeCodeUnspsc190501.50604802'),
	'identifierSchemeCodeUnspsc190501.50604803': __('identifierSchemeCodeUnspsc190501.50604803'),
	'identifierSchemeCodeUnspsc190501.50604804': __('identifierSchemeCodeUnspsc190501.50604804'),
	'identifierSchemeCodeUnspsc190501.50604805': __('identifierSchemeCodeUnspsc190501.50604805'),
	'identifierSchemeCodeUnspsc190501.50604806': __('identifierSchemeCodeUnspsc190501.50604806'),
	'identifierSchemeCodeUnspsc190501.50604807': __('identifierSchemeCodeUnspsc190501.50604807'),
	'identifierSchemeCodeUnspsc190501.50604808': __('identifierSchemeCodeUnspsc190501.50604808'),
	'identifierSchemeCodeUnspsc190501.50604809': __('identifierSchemeCodeUnspsc190501.50604809'),
	'identifierSchemeCodeUnspsc190501.50604810': __('identifierSchemeCodeUnspsc190501.50604810'),
	'identifierSchemeCodeUnspsc190501.50604811': __('identifierSchemeCodeUnspsc190501.50604811'),
	'identifierSchemeCodeUnspsc190501.50604812': __('identifierSchemeCodeUnspsc190501.50604812'),
	'identifierSchemeCodeUnspsc190501.50604813': __('identifierSchemeCodeUnspsc190501.50604813'),
	'identifierSchemeCodeUnspsc190501.50604814': __('identifierSchemeCodeUnspsc190501.50604814'),
	'identifierSchemeCodeUnspsc190501.50604815': __('identifierSchemeCodeUnspsc190501.50604815'),
	'identifierSchemeCodeUnspsc190501.50604816': __('identifierSchemeCodeUnspsc190501.50604816'),
	'identifierSchemeCodeUnspsc190501.50604817': __('identifierSchemeCodeUnspsc190501.50604817'),
	'identifierSchemeCodeUnspsc190501.50604818': __('identifierSchemeCodeUnspsc190501.50604818'),
	'identifierSchemeCodeUnspsc190501.50604819': __('identifierSchemeCodeUnspsc190501.50604819'),
	'identifierSchemeCodeUnspsc190501.50604820': __('identifierSchemeCodeUnspsc190501.50604820'),
	'identifierSchemeCodeUnspsc190501.50604821': __('identifierSchemeCodeUnspsc190501.50604821'),
	'identifierSchemeCodeUnspsc190501.50604900': __('identifierSchemeCodeUnspsc190501.50604900'),
	'identifierSchemeCodeUnspsc190501.50604901': __('identifierSchemeCodeUnspsc190501.50604901'),
	'identifierSchemeCodeUnspsc190501.50604902': __('identifierSchemeCodeUnspsc190501.50604902'),
	'identifierSchemeCodeUnspsc190501.50604903': __('identifierSchemeCodeUnspsc190501.50604903'),
	'identifierSchemeCodeUnspsc190501.50604904': __('identifierSchemeCodeUnspsc190501.50604904'),
	'identifierSchemeCodeUnspsc190501.50604905': __('identifierSchemeCodeUnspsc190501.50604905'),
	'identifierSchemeCodeUnspsc190501.50604906': __('identifierSchemeCodeUnspsc190501.50604906'),
	'identifierSchemeCodeUnspsc190501.50605000': __('identifierSchemeCodeUnspsc190501.50605000'),
	'identifierSchemeCodeUnspsc190501.50605001': __('identifierSchemeCodeUnspsc190501.50605001'),
	'identifierSchemeCodeUnspsc190501.50605002': __('identifierSchemeCodeUnspsc190501.50605002'),
	'identifierSchemeCodeUnspsc190501.50605003': __('identifierSchemeCodeUnspsc190501.50605003'),
	'identifierSchemeCodeUnspsc190501.50605004': __('identifierSchemeCodeUnspsc190501.50605004'),
	'identifierSchemeCodeUnspsc190501.50605100': __('identifierSchemeCodeUnspsc190501.50605100'),
	'identifierSchemeCodeUnspsc190501.50605101': __('identifierSchemeCodeUnspsc190501.50605101'),
	'identifierSchemeCodeUnspsc190501.50605102': __('identifierSchemeCodeUnspsc190501.50605102'),
	'identifierSchemeCodeUnspsc190501.50605103': __('identifierSchemeCodeUnspsc190501.50605103'),
	'identifierSchemeCodeUnspsc190501.50605104': __('identifierSchemeCodeUnspsc190501.50605104'),
	'identifierSchemeCodeUnspsc190501.50605105': __('identifierSchemeCodeUnspsc190501.50605105'),
	'identifierSchemeCodeUnspsc190501.50605106': __('identifierSchemeCodeUnspsc190501.50605106'),
	'identifierSchemeCodeUnspsc190501.50605107': __('identifierSchemeCodeUnspsc190501.50605107'),
	'identifierSchemeCodeUnspsc190501.50605200': __('identifierSchemeCodeUnspsc190501.50605200'),
	'identifierSchemeCodeUnspsc190501.50605201': __('identifierSchemeCodeUnspsc190501.50605201'),
	'identifierSchemeCodeUnspsc190501.50605202': __('identifierSchemeCodeUnspsc190501.50605202'),
	'identifierSchemeCodeUnspsc190501.50605203': __('identifierSchemeCodeUnspsc190501.50605203'),
	'identifierSchemeCodeUnspsc190501.50605204': __('identifierSchemeCodeUnspsc190501.50605204'),
	'identifierSchemeCodeUnspsc190501.50605205': __('identifierSchemeCodeUnspsc190501.50605205'),
	'identifierSchemeCodeUnspsc190501.50605206': __('identifierSchemeCodeUnspsc190501.50605206'),
	'identifierSchemeCodeUnspsc190501.50605207': __('identifierSchemeCodeUnspsc190501.50605207'),
	'identifierSchemeCodeUnspsc190501.50605208': __('identifierSchemeCodeUnspsc190501.50605208'),
	'identifierSchemeCodeUnspsc190501.50605209': __('identifierSchemeCodeUnspsc190501.50605209'),
	'identifierSchemeCodeUnspsc190501.50605210': __('identifierSchemeCodeUnspsc190501.50605210'),
	'identifierSchemeCodeUnspsc190501.50605211': __('identifierSchemeCodeUnspsc190501.50605211'),
	'identifierSchemeCodeUnspsc190501.50605212': __('identifierSchemeCodeUnspsc190501.50605212'),
	'identifierSchemeCodeUnspsc190501.50605213': __('identifierSchemeCodeUnspsc190501.50605213'),
	'identifierSchemeCodeUnspsc190501.50605214': __('identifierSchemeCodeUnspsc190501.50605214'),
	'identifierSchemeCodeUnspsc190501.50605215': __('identifierSchemeCodeUnspsc190501.50605215'),
	'identifierSchemeCodeUnspsc190501.50605216': __('identifierSchemeCodeUnspsc190501.50605216'),
	'identifierSchemeCodeUnspsc190501.50605217': __('identifierSchemeCodeUnspsc190501.50605217'),
	'identifierSchemeCodeUnspsc190501.50605218': __('identifierSchemeCodeUnspsc190501.50605218'),
	'identifierSchemeCodeUnspsc190501.50605219': __('identifierSchemeCodeUnspsc190501.50605219'),
	'identifierSchemeCodeUnspsc190501.50605220': __('identifierSchemeCodeUnspsc190501.50605220'),
	'identifierSchemeCodeUnspsc190501.50605221': __('identifierSchemeCodeUnspsc190501.50605221'),
	'identifierSchemeCodeUnspsc190501.50605300': __('identifierSchemeCodeUnspsc190501.50605300'),
	'identifierSchemeCodeUnspsc190501.50605301': __('identifierSchemeCodeUnspsc190501.50605301'),
	'identifierSchemeCodeUnspsc190501.50605302': __('identifierSchemeCodeUnspsc190501.50605302'),
	'identifierSchemeCodeUnspsc190501.50605303': __('identifierSchemeCodeUnspsc190501.50605303'),
	'identifierSchemeCodeUnspsc190501.50605304': __('identifierSchemeCodeUnspsc190501.50605304'),
	'identifierSchemeCodeUnspsc190501.50605305': __('identifierSchemeCodeUnspsc190501.50605305'),
	'identifierSchemeCodeUnspsc190501.50605306': __('identifierSchemeCodeUnspsc190501.50605306'),
	'identifierSchemeCodeUnspsc190501.50605400': __('identifierSchemeCodeUnspsc190501.50605400'),
	'identifierSchemeCodeUnspsc190501.50605401': __('identifierSchemeCodeUnspsc190501.50605401'),
	'identifierSchemeCodeUnspsc190501.50605402': __('identifierSchemeCodeUnspsc190501.50605402'),
	'identifierSchemeCodeUnspsc190501.50605403': __('identifierSchemeCodeUnspsc190501.50605403'),
	'identifierSchemeCodeUnspsc190501.50605404': __('identifierSchemeCodeUnspsc190501.50605404'),
	'identifierSchemeCodeUnspsc190501.50605405': __('identifierSchemeCodeUnspsc190501.50605405'),
	'identifierSchemeCodeUnspsc190501.50605406': __('identifierSchemeCodeUnspsc190501.50605406'),
	'identifierSchemeCodeUnspsc190501.50605500': __('identifierSchemeCodeUnspsc190501.50605500'),
	'identifierSchemeCodeUnspsc190501.50605501': __('identifierSchemeCodeUnspsc190501.50605501'),
	'identifierSchemeCodeUnspsc190501.50605502': __('identifierSchemeCodeUnspsc190501.50605502'),
	'identifierSchemeCodeUnspsc190501.50605503': __('identifierSchemeCodeUnspsc190501.50605503'),
	'identifierSchemeCodeUnspsc190501.50605504': __('identifierSchemeCodeUnspsc190501.50605504'),
	'identifierSchemeCodeUnspsc190501.50605505': __('identifierSchemeCodeUnspsc190501.50605505'),
	'identifierSchemeCodeUnspsc190501.50605506': __('identifierSchemeCodeUnspsc190501.50605506'),
	'identifierSchemeCodeUnspsc190501.50605507': __('identifierSchemeCodeUnspsc190501.50605507'),
	'identifierSchemeCodeUnspsc190501.50605508': __('identifierSchemeCodeUnspsc190501.50605508'),
	'identifierSchemeCodeUnspsc190501.50605509': __('identifierSchemeCodeUnspsc190501.50605509'),
	'identifierSchemeCodeUnspsc190501.50605510': __('identifierSchemeCodeUnspsc190501.50605510'),
	'identifierSchemeCodeUnspsc190501.50605511': __('identifierSchemeCodeUnspsc190501.50605511'),
	'identifierSchemeCodeUnspsc190501.50605512': __('identifierSchemeCodeUnspsc190501.50605512'),
	'identifierSchemeCodeUnspsc190501.50605513': __('identifierSchemeCodeUnspsc190501.50605513'),
	'identifierSchemeCodeUnspsc190501.50605514': __('identifierSchemeCodeUnspsc190501.50605514'),
	'identifierSchemeCodeUnspsc190501.50605515': __('identifierSchemeCodeUnspsc190501.50605515'),
	'identifierSchemeCodeUnspsc190501.50605516': __('identifierSchemeCodeUnspsc190501.50605516'),
	'identifierSchemeCodeUnspsc190501.50605517': __('identifierSchemeCodeUnspsc190501.50605517'),
	'identifierSchemeCodeUnspsc190501.50605518': __('identifierSchemeCodeUnspsc190501.50605518'),
	'identifierSchemeCodeUnspsc190501.50605519': __('identifierSchemeCodeUnspsc190501.50605519'),
	'identifierSchemeCodeUnspsc190501.50605520': __('identifierSchemeCodeUnspsc190501.50605520'),
	'identifierSchemeCodeUnspsc190501.50605521': __('identifierSchemeCodeUnspsc190501.50605521'),
	'identifierSchemeCodeUnspsc190501.50605522': __('identifierSchemeCodeUnspsc190501.50605522'),
	'identifierSchemeCodeUnspsc190501.50605523': __('identifierSchemeCodeUnspsc190501.50605523'),
	'identifierSchemeCodeUnspsc190501.50605524': __('identifierSchemeCodeUnspsc190501.50605524'),
	'identifierSchemeCodeUnspsc190501.50605525': __('identifierSchemeCodeUnspsc190501.50605525'),
	'identifierSchemeCodeUnspsc190501.50605526': __('identifierSchemeCodeUnspsc190501.50605526'),
	'identifierSchemeCodeUnspsc190501.50605527': __('identifierSchemeCodeUnspsc190501.50605527'),
	'identifierSchemeCodeUnspsc190501.50605528': __('identifierSchemeCodeUnspsc190501.50605528'),
	'identifierSchemeCodeUnspsc190501.50605529': __('identifierSchemeCodeUnspsc190501.50605529'),
	'identifierSchemeCodeUnspsc190501.50605530': __('identifierSchemeCodeUnspsc190501.50605530'),
	'identifierSchemeCodeUnspsc190501.50605531': __('identifierSchemeCodeUnspsc190501.50605531'),
	'identifierSchemeCodeUnspsc190501.50605532': __('identifierSchemeCodeUnspsc190501.50605532'),
	'identifierSchemeCodeUnspsc190501.50605533': __('identifierSchemeCodeUnspsc190501.50605533'),
	'identifierSchemeCodeUnspsc190501.50605534': __('identifierSchemeCodeUnspsc190501.50605534'),
	'identifierSchemeCodeUnspsc190501.50605535': __('identifierSchemeCodeUnspsc190501.50605535'),
	'identifierSchemeCodeUnspsc190501.50605536': __('identifierSchemeCodeUnspsc190501.50605536'),
	'identifierSchemeCodeUnspsc190501.50605537': __('identifierSchemeCodeUnspsc190501.50605537'),
	'identifierSchemeCodeUnspsc190501.50605600': __('identifierSchemeCodeUnspsc190501.50605600'),
	'identifierSchemeCodeUnspsc190501.50605601': __('identifierSchemeCodeUnspsc190501.50605601'),
	'identifierSchemeCodeUnspsc190501.50605602': __('identifierSchemeCodeUnspsc190501.50605602'),
	'identifierSchemeCodeUnspsc190501.50605603': __('identifierSchemeCodeUnspsc190501.50605603'),
	'identifierSchemeCodeUnspsc190501.50605604': __('identifierSchemeCodeUnspsc190501.50605604'),
	'identifierSchemeCodeUnspsc190501.50605605': __('identifierSchemeCodeUnspsc190501.50605605'),
	'identifierSchemeCodeUnspsc190501.50605606': __('identifierSchemeCodeUnspsc190501.50605606'),
	'identifierSchemeCodeUnspsc190501.50605607': __('identifierSchemeCodeUnspsc190501.50605607'),
	'identifierSchemeCodeUnspsc190501.50605608': __('identifierSchemeCodeUnspsc190501.50605608'),
	'identifierSchemeCodeUnspsc190501.50605700': __('identifierSchemeCodeUnspsc190501.50605700'),
	'identifierSchemeCodeUnspsc190501.50605701': __('identifierSchemeCodeUnspsc190501.50605701'),
	'identifierSchemeCodeUnspsc190501.50605702': __('identifierSchemeCodeUnspsc190501.50605702'),
	'identifierSchemeCodeUnspsc190501.50605703': __('identifierSchemeCodeUnspsc190501.50605703'),
	'identifierSchemeCodeUnspsc190501.50605704': __('identifierSchemeCodeUnspsc190501.50605704'),
	'identifierSchemeCodeUnspsc190501.50605800': __('identifierSchemeCodeUnspsc190501.50605800'),
	'identifierSchemeCodeUnspsc190501.50605801': __('identifierSchemeCodeUnspsc190501.50605801'),
	'identifierSchemeCodeUnspsc190501.50605802': __('identifierSchemeCodeUnspsc190501.50605802'),
	'identifierSchemeCodeUnspsc190501.50605803': __('identifierSchemeCodeUnspsc190501.50605803'),
	'identifierSchemeCodeUnspsc190501.50605804': __('identifierSchemeCodeUnspsc190501.50605804'),
	'identifierSchemeCodeUnspsc190501.50605805': __('identifierSchemeCodeUnspsc190501.50605805'),
	'identifierSchemeCodeUnspsc190501.50605806': __('identifierSchemeCodeUnspsc190501.50605806'),
	'identifierSchemeCodeUnspsc190501.50605807': __('identifierSchemeCodeUnspsc190501.50605807'),
	'identifierSchemeCodeUnspsc190501.50605808': __('identifierSchemeCodeUnspsc190501.50605808'),
	'identifierSchemeCodeUnspsc190501.50605809': __('identifierSchemeCodeUnspsc190501.50605809'),
	'identifierSchemeCodeUnspsc190501.50605810': __('identifierSchemeCodeUnspsc190501.50605810'),
	'identifierSchemeCodeUnspsc190501.50605811': __('identifierSchemeCodeUnspsc190501.50605811'),
	'identifierSchemeCodeUnspsc190501.50605812': __('identifierSchemeCodeUnspsc190501.50605812'),
	'identifierSchemeCodeUnspsc190501.50605813': __('identifierSchemeCodeUnspsc190501.50605813'),
	'identifierSchemeCodeUnspsc190501.50605814': __('identifierSchemeCodeUnspsc190501.50605814'),
	'identifierSchemeCodeUnspsc190501.50605815': __('identifierSchemeCodeUnspsc190501.50605815'),
	'identifierSchemeCodeUnspsc190501.50605816': __('identifierSchemeCodeUnspsc190501.50605816'),
	'identifierSchemeCodeUnspsc190501.50605900': __('identifierSchemeCodeUnspsc190501.50605900'),
	'identifierSchemeCodeUnspsc190501.50605901': __('identifierSchemeCodeUnspsc190501.50605901'),
	'identifierSchemeCodeUnspsc190501.50605902': __('identifierSchemeCodeUnspsc190501.50605902'),
	'identifierSchemeCodeUnspsc190501.50605903': __('identifierSchemeCodeUnspsc190501.50605903'),
	'identifierSchemeCodeUnspsc190501.50605904': __('identifierSchemeCodeUnspsc190501.50605904'),
	'identifierSchemeCodeUnspsc190501.50605905': __('identifierSchemeCodeUnspsc190501.50605905'),
	'identifierSchemeCodeUnspsc190501.50605906': __('identifierSchemeCodeUnspsc190501.50605906'),
	'identifierSchemeCodeUnspsc190501.50605907': __('identifierSchemeCodeUnspsc190501.50605907'),
	'identifierSchemeCodeUnspsc190501.50605908': __('identifierSchemeCodeUnspsc190501.50605908'),
	'identifierSchemeCodeUnspsc190501.50605909': __('identifierSchemeCodeUnspsc190501.50605909'),
	'identifierSchemeCodeUnspsc190501.50605910': __('identifierSchemeCodeUnspsc190501.50605910'),
	'identifierSchemeCodeUnspsc190501.50605911': __('identifierSchemeCodeUnspsc190501.50605911'),
	'identifierSchemeCodeUnspsc190501.50606000': __('identifierSchemeCodeUnspsc190501.50606000'),
	'identifierSchemeCodeUnspsc190501.50606001': __('identifierSchemeCodeUnspsc190501.50606001'),
	'identifierSchemeCodeUnspsc190501.50606002': __('identifierSchemeCodeUnspsc190501.50606002'),
	'identifierSchemeCodeUnspsc190501.50606003': __('identifierSchemeCodeUnspsc190501.50606003'),
	'identifierSchemeCodeUnspsc190501.50606004': __('identifierSchemeCodeUnspsc190501.50606004'),
	'identifierSchemeCodeUnspsc190501.50606100': __('identifierSchemeCodeUnspsc190501.50606100'),
	'identifierSchemeCodeUnspsc190501.50606101': __('identifierSchemeCodeUnspsc190501.50606101'),
	'identifierSchemeCodeUnspsc190501.50606102': __('identifierSchemeCodeUnspsc190501.50606102'),
	'identifierSchemeCodeUnspsc190501.50606103': __('identifierSchemeCodeUnspsc190501.50606103'),
	'identifierSchemeCodeUnspsc190501.50606104': __('identifierSchemeCodeUnspsc190501.50606104'),
	'identifierSchemeCodeUnspsc190501.50606105': __('identifierSchemeCodeUnspsc190501.50606105'),
	'identifierSchemeCodeUnspsc190501.50606106': __('identifierSchemeCodeUnspsc190501.50606106'),
	'identifierSchemeCodeUnspsc190501.50606107': __('identifierSchemeCodeUnspsc190501.50606107'),
	'identifierSchemeCodeUnspsc190501.50606108': __('identifierSchemeCodeUnspsc190501.50606108'),
	'identifierSchemeCodeUnspsc190501.50606109': __('identifierSchemeCodeUnspsc190501.50606109'),
	'identifierSchemeCodeUnspsc190501.50606110': __('identifierSchemeCodeUnspsc190501.50606110'),
	'identifierSchemeCodeUnspsc190501.50606111': __('identifierSchemeCodeUnspsc190501.50606111'),
	'identifierSchemeCodeUnspsc190501.50606112': __('identifierSchemeCodeUnspsc190501.50606112'),
	'identifierSchemeCodeUnspsc190501.50606113': __('identifierSchemeCodeUnspsc190501.50606113'),
	'identifierSchemeCodeUnspsc190501.50606114': __('identifierSchemeCodeUnspsc190501.50606114'),
	'identifierSchemeCodeUnspsc190501.50606115': __('identifierSchemeCodeUnspsc190501.50606115'),
	'identifierSchemeCodeUnspsc190501.50606116': __('identifierSchemeCodeUnspsc190501.50606116'),
	'identifierSchemeCodeUnspsc190501.50606200': __('identifierSchemeCodeUnspsc190501.50606200'),
	'identifierSchemeCodeUnspsc190501.50606201': __('identifierSchemeCodeUnspsc190501.50606201'),
	'identifierSchemeCodeUnspsc190501.50606202': __('identifierSchemeCodeUnspsc190501.50606202'),
	'identifierSchemeCodeUnspsc190501.50606203': __('identifierSchemeCodeUnspsc190501.50606203'),
	'identifierSchemeCodeUnspsc190501.50606204': __('identifierSchemeCodeUnspsc190501.50606204'),
	'identifierSchemeCodeUnspsc190501.50606205': __('identifierSchemeCodeUnspsc190501.50606205'),
	'identifierSchemeCodeUnspsc190501.50606206': __('identifierSchemeCodeUnspsc190501.50606206'),
	'identifierSchemeCodeUnspsc190501.50606207': __('identifierSchemeCodeUnspsc190501.50606207'),
	'identifierSchemeCodeUnspsc190501.50606208': __('identifierSchemeCodeUnspsc190501.50606208'),
	'identifierSchemeCodeUnspsc190501.50606209': __('identifierSchemeCodeUnspsc190501.50606209'),
	'identifierSchemeCodeUnspsc190501.50606210': __('identifierSchemeCodeUnspsc190501.50606210'),
	'identifierSchemeCodeUnspsc190501.50606211': __('identifierSchemeCodeUnspsc190501.50606211'),
	'identifierSchemeCodeUnspsc190501.50606212': __('identifierSchemeCodeUnspsc190501.50606212'),
	'identifierSchemeCodeUnspsc190501.50606213': __('identifierSchemeCodeUnspsc190501.50606213'),
	'identifierSchemeCodeUnspsc190501.50606214': __('identifierSchemeCodeUnspsc190501.50606214'),
	'identifierSchemeCodeUnspsc190501.50606215': __('identifierSchemeCodeUnspsc190501.50606215'),
	'identifierSchemeCodeUnspsc190501.50606216': __('identifierSchemeCodeUnspsc190501.50606216'),
	'identifierSchemeCodeUnspsc190501.50606217': __('identifierSchemeCodeUnspsc190501.50606217'),
	'identifierSchemeCodeUnspsc190501.50606218': __('identifierSchemeCodeUnspsc190501.50606218'),
	'identifierSchemeCodeUnspsc190501.50606219': __('identifierSchemeCodeUnspsc190501.50606219'),
	'identifierSchemeCodeUnspsc190501.50606220': __('identifierSchemeCodeUnspsc190501.50606220'),
	'identifierSchemeCodeUnspsc190501.50606221': __('identifierSchemeCodeUnspsc190501.50606221'),
	'identifierSchemeCodeUnspsc190501.50606222': __('identifierSchemeCodeUnspsc190501.50606222'),
	'identifierSchemeCodeUnspsc190501.50606223': __('identifierSchemeCodeUnspsc190501.50606223'),
	'identifierSchemeCodeUnspsc190501.50606224': __('identifierSchemeCodeUnspsc190501.50606224'),
	'identifierSchemeCodeUnspsc190501.50606225': __('identifierSchemeCodeUnspsc190501.50606225'),
	'identifierSchemeCodeUnspsc190501.50606226': __('identifierSchemeCodeUnspsc190501.50606226'),
	'identifierSchemeCodeUnspsc190501.50606227': __('identifierSchemeCodeUnspsc190501.50606227'),
	'identifierSchemeCodeUnspsc190501.50606228': __('identifierSchemeCodeUnspsc190501.50606228'),
	'identifierSchemeCodeUnspsc190501.50606229': __('identifierSchemeCodeUnspsc190501.50606229'),
	'identifierSchemeCodeUnspsc190501.50606300': __('identifierSchemeCodeUnspsc190501.50606300'),
	'identifierSchemeCodeUnspsc190501.50606301': __('identifierSchemeCodeUnspsc190501.50606301'),
	'identifierSchemeCodeUnspsc190501.50606302': __('identifierSchemeCodeUnspsc190501.50606302'),
	'identifierSchemeCodeUnspsc190501.50606303': __('identifierSchemeCodeUnspsc190501.50606303'),
	'identifierSchemeCodeUnspsc190501.50606304': __('identifierSchemeCodeUnspsc190501.50606304'),
	'identifierSchemeCodeUnspsc190501.50606305': __('identifierSchemeCodeUnspsc190501.50606305'),
	'identifierSchemeCodeUnspsc190501.50606306': __('identifierSchemeCodeUnspsc190501.50606306'),
	'identifierSchemeCodeUnspsc190501.50606307': __('identifierSchemeCodeUnspsc190501.50606307'),
	'identifierSchemeCodeUnspsc190501.50606308': __('identifierSchemeCodeUnspsc190501.50606308'),
	'identifierSchemeCodeUnspsc190501.50606309': __('identifierSchemeCodeUnspsc190501.50606309'),
	'identifierSchemeCodeUnspsc190501.50606310': __('identifierSchemeCodeUnspsc190501.50606310'),
	'identifierSchemeCodeUnspsc190501.50606311': __('identifierSchemeCodeUnspsc190501.50606311'),
	'identifierSchemeCodeUnspsc190501.50606312': __('identifierSchemeCodeUnspsc190501.50606312'),
	'identifierSchemeCodeUnspsc190501.50606313': __('identifierSchemeCodeUnspsc190501.50606313'),
	'identifierSchemeCodeUnspsc190501.50606314': __('identifierSchemeCodeUnspsc190501.50606314'),
	'identifierSchemeCodeUnspsc190501.50606315': __('identifierSchemeCodeUnspsc190501.50606315'),
	'identifierSchemeCodeUnspsc190501.50606316': __('identifierSchemeCodeUnspsc190501.50606316'),
	'identifierSchemeCodeUnspsc190501.50606317': __('identifierSchemeCodeUnspsc190501.50606317'),
	'identifierSchemeCodeUnspsc190501.50606318': __('identifierSchemeCodeUnspsc190501.50606318'),
	'identifierSchemeCodeUnspsc190501.50606319': __('identifierSchemeCodeUnspsc190501.50606319'),
	'identifierSchemeCodeUnspsc190501.50606320': __('identifierSchemeCodeUnspsc190501.50606320'),
	'identifierSchemeCodeUnspsc190501.50606400': __('identifierSchemeCodeUnspsc190501.50606400'),
	'identifierSchemeCodeUnspsc190501.50606401': __('identifierSchemeCodeUnspsc190501.50606401'),
	'identifierSchemeCodeUnspsc190501.50606402': __('identifierSchemeCodeUnspsc190501.50606402'),
	'identifierSchemeCodeUnspsc190501.50606403': __('identifierSchemeCodeUnspsc190501.50606403'),
	'identifierSchemeCodeUnspsc190501.50606404': __('identifierSchemeCodeUnspsc190501.50606404'),
	'identifierSchemeCodeUnspsc190501.50606405': __('identifierSchemeCodeUnspsc190501.50606405'),
	'identifierSchemeCodeUnspsc190501.50606406': __('identifierSchemeCodeUnspsc190501.50606406'),
	'identifierSchemeCodeUnspsc190501.50606407': __('identifierSchemeCodeUnspsc190501.50606407'),
	'identifierSchemeCodeUnspsc190501.50606408': __('identifierSchemeCodeUnspsc190501.50606408'),
	'identifierSchemeCodeUnspsc190501.50606409': __('identifierSchemeCodeUnspsc190501.50606409'),
	'identifierSchemeCodeUnspsc190501.50606410': __('identifierSchemeCodeUnspsc190501.50606410'),
	'identifierSchemeCodeUnspsc190501.50606411': __('identifierSchemeCodeUnspsc190501.50606411'),
	'identifierSchemeCodeUnspsc190501.50606412': __('identifierSchemeCodeUnspsc190501.50606412'),
	'identifierSchemeCodeUnspsc190501.50606413': __('identifierSchemeCodeUnspsc190501.50606413'),
	'identifierSchemeCodeUnspsc190501.50606414': __('identifierSchemeCodeUnspsc190501.50606414'),
	'identifierSchemeCodeUnspsc190501.50606415': __('identifierSchemeCodeUnspsc190501.50606415'),
	'identifierSchemeCodeUnspsc190501.50606416': __('identifierSchemeCodeUnspsc190501.50606416'),
	'identifierSchemeCodeUnspsc190501.50606417': __('identifierSchemeCodeUnspsc190501.50606417'),
	'identifierSchemeCodeUnspsc190501.50606418': __('identifierSchemeCodeUnspsc190501.50606418'),
	'identifierSchemeCodeUnspsc190501.50606419': __('identifierSchemeCodeUnspsc190501.50606419'),
	'identifierSchemeCodeUnspsc190501.50606420': __('identifierSchemeCodeUnspsc190501.50606420'),
	'identifierSchemeCodeUnspsc190501.50606421': __('identifierSchemeCodeUnspsc190501.50606421'),
	'identifierSchemeCodeUnspsc190501.50606422': __('identifierSchemeCodeUnspsc190501.50606422'),
	'identifierSchemeCodeUnspsc190501.50606423': __('identifierSchemeCodeUnspsc190501.50606423'),
	'identifierSchemeCodeUnspsc190501.50606424': __('identifierSchemeCodeUnspsc190501.50606424'),
	'identifierSchemeCodeUnspsc190501.50606425': __('identifierSchemeCodeUnspsc190501.50606425'),
	'identifierSchemeCodeUnspsc190501.50606426': __('identifierSchemeCodeUnspsc190501.50606426'),
	'identifierSchemeCodeUnspsc190501.50606427': __('identifierSchemeCodeUnspsc190501.50606427'),
	'identifierSchemeCodeUnspsc190501.50606500': __('identifierSchemeCodeUnspsc190501.50606500'),
	'identifierSchemeCodeUnspsc190501.50606501': __('identifierSchemeCodeUnspsc190501.50606501'),
	'identifierSchemeCodeUnspsc190501.50606502': __('identifierSchemeCodeUnspsc190501.50606502'),
	'identifierSchemeCodeUnspsc190501.50606503': __('identifierSchemeCodeUnspsc190501.50606503'),
	'identifierSchemeCodeUnspsc190501.50606504': __('identifierSchemeCodeUnspsc190501.50606504'),
	'identifierSchemeCodeUnspsc190501.50606505': __('identifierSchemeCodeUnspsc190501.50606505'),
	'identifierSchemeCodeUnspsc190501.50606506': __('identifierSchemeCodeUnspsc190501.50606506'),
	'identifierSchemeCodeUnspsc190501.50606507': __('identifierSchemeCodeUnspsc190501.50606507'),
	'identifierSchemeCodeUnspsc190501.50606508': __('identifierSchemeCodeUnspsc190501.50606508'),
	'identifierSchemeCodeUnspsc190501.50606600': __('identifierSchemeCodeUnspsc190501.50606600'),
	'identifierSchemeCodeUnspsc190501.50606601': __('identifierSchemeCodeUnspsc190501.50606601'),
	'identifierSchemeCodeUnspsc190501.50606602': __('identifierSchemeCodeUnspsc190501.50606602'),
	'identifierSchemeCodeUnspsc190501.50606603': __('identifierSchemeCodeUnspsc190501.50606603'),
	'identifierSchemeCodeUnspsc190501.50606604': __('identifierSchemeCodeUnspsc190501.50606604'),
	'identifierSchemeCodeUnspsc190501.50606605': __('identifierSchemeCodeUnspsc190501.50606605'),
	'identifierSchemeCodeUnspsc190501.50606606': __('identifierSchemeCodeUnspsc190501.50606606'),
	'identifierSchemeCodeUnspsc190501.50606607': __('identifierSchemeCodeUnspsc190501.50606607'),
	'identifierSchemeCodeUnspsc190501.50606608': __('identifierSchemeCodeUnspsc190501.50606608'),
	'identifierSchemeCodeUnspsc190501.50606609': __('identifierSchemeCodeUnspsc190501.50606609'),
	'identifierSchemeCodeUnspsc190501.50606610': __('identifierSchemeCodeUnspsc190501.50606610'),
	'identifierSchemeCodeUnspsc190501.50606611': __('identifierSchemeCodeUnspsc190501.50606611'),
	'identifierSchemeCodeUnspsc190501.50606612': __('identifierSchemeCodeUnspsc190501.50606612'),
	'identifierSchemeCodeUnspsc190501.50606613': __('identifierSchemeCodeUnspsc190501.50606613'),
	'identifierSchemeCodeUnspsc190501.50606614': __('identifierSchemeCodeUnspsc190501.50606614'),
	'identifierSchemeCodeUnspsc190501.50606615': __('identifierSchemeCodeUnspsc190501.50606615'),
	'identifierSchemeCodeUnspsc190501.50606616': __('identifierSchemeCodeUnspsc190501.50606616'),
	'identifierSchemeCodeUnspsc190501.50606617': __('identifierSchemeCodeUnspsc190501.50606617'),
	'identifierSchemeCodeUnspsc190501.50606618': __('identifierSchemeCodeUnspsc190501.50606618'),
	'identifierSchemeCodeUnspsc190501.50606619': __('identifierSchemeCodeUnspsc190501.50606619'),
	'identifierSchemeCodeUnspsc190501.50606620': __('identifierSchemeCodeUnspsc190501.50606620'),
	'identifierSchemeCodeUnspsc190501.50606700': __('identifierSchemeCodeUnspsc190501.50606700'),
	'identifierSchemeCodeUnspsc190501.50606701': __('identifierSchemeCodeUnspsc190501.50606701'),
	'identifierSchemeCodeUnspsc190501.50606702': __('identifierSchemeCodeUnspsc190501.50606702'),
	'identifierSchemeCodeUnspsc190501.50606703': __('identifierSchemeCodeUnspsc190501.50606703'),
	'identifierSchemeCodeUnspsc190501.50606704': __('identifierSchemeCodeUnspsc190501.50606704'),
	'identifierSchemeCodeUnspsc190501.50606705': __('identifierSchemeCodeUnspsc190501.50606705'),
	'identifierSchemeCodeUnspsc190501.50606706': __('identifierSchemeCodeUnspsc190501.50606706'),
	'identifierSchemeCodeUnspsc190501.50606707': __('identifierSchemeCodeUnspsc190501.50606707'),
	'identifierSchemeCodeUnspsc190501.50606708': __('identifierSchemeCodeUnspsc190501.50606708'),
	'identifierSchemeCodeUnspsc190501.50606709': __('identifierSchemeCodeUnspsc190501.50606709'),
	'identifierSchemeCodeUnspsc190501.50606710': __('identifierSchemeCodeUnspsc190501.50606710'),
	'identifierSchemeCodeUnspsc190501.50606800': __('identifierSchemeCodeUnspsc190501.50606800'),
	'identifierSchemeCodeUnspsc190501.50606801': __('identifierSchemeCodeUnspsc190501.50606801'),
	'identifierSchemeCodeUnspsc190501.50606802': __('identifierSchemeCodeUnspsc190501.50606802'),
	'identifierSchemeCodeUnspsc190501.50606803': __('identifierSchemeCodeUnspsc190501.50606803'),
	'identifierSchemeCodeUnspsc190501.50606804': __('identifierSchemeCodeUnspsc190501.50606804'),
	'identifierSchemeCodeUnspsc190501.50606805': __('identifierSchemeCodeUnspsc190501.50606805'),
	'identifierSchemeCodeUnspsc190501.50606806': __('identifierSchemeCodeUnspsc190501.50606806'),
	'identifierSchemeCodeUnspsc190501.50606807': __('identifierSchemeCodeUnspsc190501.50606807'),
	'identifierSchemeCodeUnspsc190501.50606808': __('identifierSchemeCodeUnspsc190501.50606808'),
	'identifierSchemeCodeUnspsc190501.50606809': __('identifierSchemeCodeUnspsc190501.50606809'),
	'identifierSchemeCodeUnspsc190501.50606810': __('identifierSchemeCodeUnspsc190501.50606810'),
	'identifierSchemeCodeUnspsc190501.50606811': __('identifierSchemeCodeUnspsc190501.50606811'),
	'identifierSchemeCodeUnspsc190501.50606812': __('identifierSchemeCodeUnspsc190501.50606812'),
	'identifierSchemeCodeUnspsc190501.50606813': __('identifierSchemeCodeUnspsc190501.50606813'),
	'identifierSchemeCodeUnspsc190501.50606814': __('identifierSchemeCodeUnspsc190501.50606814'),
	'identifierSchemeCodeUnspsc190501.50606815': __('identifierSchemeCodeUnspsc190501.50606815'),
	'identifierSchemeCodeUnspsc190501.50606816': __('identifierSchemeCodeUnspsc190501.50606816'),
	'identifierSchemeCodeUnspsc190501.50606817': __('identifierSchemeCodeUnspsc190501.50606817'),
	'identifierSchemeCodeUnspsc190501.50606818': __('identifierSchemeCodeUnspsc190501.50606818'),
	'identifierSchemeCodeUnspsc190501.50606819': __('identifierSchemeCodeUnspsc190501.50606819'),
	'identifierSchemeCodeUnspsc190501.50606820': __('identifierSchemeCodeUnspsc190501.50606820'),
	'identifierSchemeCodeUnspsc190501.50606821': __('identifierSchemeCodeUnspsc190501.50606821'),
	'identifierSchemeCodeUnspsc190501.50606822': __('identifierSchemeCodeUnspsc190501.50606822'),
	'identifierSchemeCodeUnspsc190501.50606823': __('identifierSchemeCodeUnspsc190501.50606823'),
	'identifierSchemeCodeUnspsc190501.50606824': __('identifierSchemeCodeUnspsc190501.50606824'),
	'identifierSchemeCodeUnspsc190501.50606825': __('identifierSchemeCodeUnspsc190501.50606825'),
	'identifierSchemeCodeUnspsc190501.50606826': __('identifierSchemeCodeUnspsc190501.50606826'),
	'identifierSchemeCodeUnspsc190501.50606827': __('identifierSchemeCodeUnspsc190501.50606827'),
	'identifierSchemeCodeUnspsc190501.50606828': __('identifierSchemeCodeUnspsc190501.50606828'),
	'identifierSchemeCodeUnspsc190501.50606829': __('identifierSchemeCodeUnspsc190501.50606829'),
	'identifierSchemeCodeUnspsc190501.50606830': __('identifierSchemeCodeUnspsc190501.50606830'),
	'identifierSchemeCodeUnspsc190501.50606831': __('identifierSchemeCodeUnspsc190501.50606831'),
	'identifierSchemeCodeUnspsc190501.50606832': __('identifierSchemeCodeUnspsc190501.50606832'),
	'identifierSchemeCodeUnspsc190501.50606833': __('identifierSchemeCodeUnspsc190501.50606833'),
	'identifierSchemeCodeUnspsc190501.50606834': __('identifierSchemeCodeUnspsc190501.50606834'),
	'identifierSchemeCodeUnspsc190501.50606835': __('identifierSchemeCodeUnspsc190501.50606835'),
	'identifierSchemeCodeUnspsc190501.50606836': __('identifierSchemeCodeUnspsc190501.50606836'),
	'identifierSchemeCodeUnspsc190501.50606837': __('identifierSchemeCodeUnspsc190501.50606837'),
	'identifierSchemeCodeUnspsc190501.50606838': __('identifierSchemeCodeUnspsc190501.50606838'),
	'identifierSchemeCodeUnspsc190501.50606839': __('identifierSchemeCodeUnspsc190501.50606839'),
	'identifierSchemeCodeUnspsc190501.50606840': __('identifierSchemeCodeUnspsc190501.50606840'),
	'identifierSchemeCodeUnspsc190501.50606841': __('identifierSchemeCodeUnspsc190501.50606841'),
	'identifierSchemeCodeUnspsc190501.50606842': __('identifierSchemeCodeUnspsc190501.50606842'),
	'identifierSchemeCodeUnspsc190501.50606843': __('identifierSchemeCodeUnspsc190501.50606843'),
	'identifierSchemeCodeUnspsc190501.50606844': __('identifierSchemeCodeUnspsc190501.50606844'),
	'identifierSchemeCodeUnspsc190501.50606845': __('identifierSchemeCodeUnspsc190501.50606845'),
	'identifierSchemeCodeUnspsc190501.50606846': __('identifierSchemeCodeUnspsc190501.50606846'),
	'identifierSchemeCodeUnspsc190501.50606847': __('identifierSchemeCodeUnspsc190501.50606847'),
	'identifierSchemeCodeUnspsc190501.50606848': __('identifierSchemeCodeUnspsc190501.50606848'),
	'identifierSchemeCodeUnspsc190501.50606849': __('identifierSchemeCodeUnspsc190501.50606849'),
	'identifierSchemeCodeUnspsc190501.50606850': __('identifierSchemeCodeUnspsc190501.50606850'),
	'identifierSchemeCodeUnspsc190501.50606851': __('identifierSchemeCodeUnspsc190501.50606851'),
	'identifierSchemeCodeUnspsc190501.50606852': __('identifierSchemeCodeUnspsc190501.50606852'),
	'identifierSchemeCodeUnspsc190501.50606853': __('identifierSchemeCodeUnspsc190501.50606853'),
	'identifierSchemeCodeUnspsc190501.50606854': __('identifierSchemeCodeUnspsc190501.50606854'),
	'identifierSchemeCodeUnspsc190501.50606855': __('identifierSchemeCodeUnspsc190501.50606855'),
	'identifierSchemeCodeUnspsc190501.50606856': __('identifierSchemeCodeUnspsc190501.50606856'),
	'identifierSchemeCodeUnspsc190501.50606857': __('identifierSchemeCodeUnspsc190501.50606857'),
	'identifierSchemeCodeUnspsc190501.50606858': __('identifierSchemeCodeUnspsc190501.50606858'),
	'identifierSchemeCodeUnspsc190501.50606859': __('identifierSchemeCodeUnspsc190501.50606859'),
	'identifierSchemeCodeUnspsc190501.50606860': __('identifierSchemeCodeUnspsc190501.50606860'),
	'identifierSchemeCodeUnspsc190501.50606861': __('identifierSchemeCodeUnspsc190501.50606861'),
	'identifierSchemeCodeUnspsc190501.50606862': __('identifierSchemeCodeUnspsc190501.50606862'),
	'identifierSchemeCodeUnspsc190501.50606863': __('identifierSchemeCodeUnspsc190501.50606863'),
	'identifierSchemeCodeUnspsc190501.50606864': __('identifierSchemeCodeUnspsc190501.50606864'),
	'identifierSchemeCodeUnspsc190501.50606865': __('identifierSchemeCodeUnspsc190501.50606865'),
	'identifierSchemeCodeUnspsc190501.50606866': __('identifierSchemeCodeUnspsc190501.50606866'),
	'identifierSchemeCodeUnspsc190501.50606867': __('identifierSchemeCodeUnspsc190501.50606867'),
	'identifierSchemeCodeUnspsc190501.50606868': __('identifierSchemeCodeUnspsc190501.50606868'),
	'identifierSchemeCodeUnspsc190501.50606900': __('identifierSchemeCodeUnspsc190501.50606900'),
	'identifierSchemeCodeUnspsc190501.50606901': __('identifierSchemeCodeUnspsc190501.50606901'),
	'identifierSchemeCodeUnspsc190501.50606902': __('identifierSchemeCodeUnspsc190501.50606902'),
	'identifierSchemeCodeUnspsc190501.50606903': __('identifierSchemeCodeUnspsc190501.50606903'),
	'identifierSchemeCodeUnspsc190501.50606904': __('identifierSchemeCodeUnspsc190501.50606904'),
	'identifierSchemeCodeUnspsc190501.50606905': __('identifierSchemeCodeUnspsc190501.50606905'),
	'identifierSchemeCodeUnspsc190501.50606906': __('identifierSchemeCodeUnspsc190501.50606906'),
	'identifierSchemeCodeUnspsc190501.50606907': __('identifierSchemeCodeUnspsc190501.50606907'),
	'identifierSchemeCodeUnspsc190501.50606908': __('identifierSchemeCodeUnspsc190501.50606908'),
	'identifierSchemeCodeUnspsc190501.50606909': __('identifierSchemeCodeUnspsc190501.50606909'),
	'identifierSchemeCodeUnspsc190501.50606910': __('identifierSchemeCodeUnspsc190501.50606910'),
	'identifierSchemeCodeUnspsc190501.50610000': __('identifierSchemeCodeUnspsc190501.50610000'),
	'identifierSchemeCodeUnspsc190501.50611500': __('identifierSchemeCodeUnspsc190501.50611500'),
	'identifierSchemeCodeUnspsc190501.50611501': __('identifierSchemeCodeUnspsc190501.50611501'),
	'identifierSchemeCodeUnspsc190501.50611502': __('identifierSchemeCodeUnspsc190501.50611502'),
	'identifierSchemeCodeUnspsc190501.50611503': __('identifierSchemeCodeUnspsc190501.50611503'),
	'identifierSchemeCodeUnspsc190501.50611504': __('identifierSchemeCodeUnspsc190501.50611504'),
	'identifierSchemeCodeUnspsc190501.50611505': __('identifierSchemeCodeUnspsc190501.50611505'),
	'identifierSchemeCodeUnspsc190501.50611506': __('identifierSchemeCodeUnspsc190501.50611506'),
	'identifierSchemeCodeUnspsc190501.50611507': __('identifierSchemeCodeUnspsc190501.50611507'),
	'identifierSchemeCodeUnspsc190501.50611508': __('identifierSchemeCodeUnspsc190501.50611508'),
	'identifierSchemeCodeUnspsc190501.50611509': __('identifierSchemeCodeUnspsc190501.50611509'),
	'identifierSchemeCodeUnspsc190501.50611510': __('identifierSchemeCodeUnspsc190501.50611510'),
	'identifierSchemeCodeUnspsc190501.50611511': __('identifierSchemeCodeUnspsc190501.50611511'),
	'identifierSchemeCodeUnspsc190501.50611512': __('identifierSchemeCodeUnspsc190501.50611512'),
	'identifierSchemeCodeUnspsc190501.50611513': __('identifierSchemeCodeUnspsc190501.50611513'),
	'identifierSchemeCodeUnspsc190501.50611600': __('identifierSchemeCodeUnspsc190501.50611600'),
	'identifierSchemeCodeUnspsc190501.50611601': __('identifierSchemeCodeUnspsc190501.50611601'),
	'identifierSchemeCodeUnspsc190501.50611602': __('identifierSchemeCodeUnspsc190501.50611602'),
	'identifierSchemeCodeUnspsc190501.50611603': __('identifierSchemeCodeUnspsc190501.50611603'),
	'identifierSchemeCodeUnspsc190501.50611604': __('identifierSchemeCodeUnspsc190501.50611604'),
	'identifierSchemeCodeUnspsc190501.50611605': __('identifierSchemeCodeUnspsc190501.50611605'),
	'identifierSchemeCodeUnspsc190501.50611700': __('identifierSchemeCodeUnspsc190501.50611700'),
	'identifierSchemeCodeUnspsc190501.50611701': __('identifierSchemeCodeUnspsc190501.50611701'),
	'identifierSchemeCodeUnspsc190501.50611702': __('identifierSchemeCodeUnspsc190501.50611702'),
	'identifierSchemeCodeUnspsc190501.50611703': __('identifierSchemeCodeUnspsc190501.50611703'),
	'identifierSchemeCodeUnspsc190501.50611704': __('identifierSchemeCodeUnspsc190501.50611704'),
	'identifierSchemeCodeUnspsc190501.50611705': __('identifierSchemeCodeUnspsc190501.50611705'),
	'identifierSchemeCodeUnspsc190501.50611706': __('identifierSchemeCodeUnspsc190501.50611706'),
	'identifierSchemeCodeUnspsc190501.50611707': __('identifierSchemeCodeUnspsc190501.50611707'),
	'identifierSchemeCodeUnspsc190501.50611708': __('identifierSchemeCodeUnspsc190501.50611708'),
	'identifierSchemeCodeUnspsc190501.50611709': __('identifierSchemeCodeUnspsc190501.50611709'),
	'identifierSchemeCodeUnspsc190501.50611710': __('identifierSchemeCodeUnspsc190501.50611710'),
	'identifierSchemeCodeUnspsc190501.50611711': __('identifierSchemeCodeUnspsc190501.50611711'),
	'identifierSchemeCodeUnspsc190501.50611712': __('identifierSchemeCodeUnspsc190501.50611712'),
	'identifierSchemeCodeUnspsc190501.50611713': __('identifierSchemeCodeUnspsc190501.50611713'),
	'identifierSchemeCodeUnspsc190501.50611714': __('identifierSchemeCodeUnspsc190501.50611714'),
	'identifierSchemeCodeUnspsc190501.50611715': __('identifierSchemeCodeUnspsc190501.50611715'),
	'identifierSchemeCodeUnspsc190501.50611716': __('identifierSchemeCodeUnspsc190501.50611716'),
	'identifierSchemeCodeUnspsc190501.50611717': __('identifierSchemeCodeUnspsc190501.50611717'),
	'identifierSchemeCodeUnspsc190501.50611718': __('identifierSchemeCodeUnspsc190501.50611718'),
	'identifierSchemeCodeUnspsc190501.50611719': __('identifierSchemeCodeUnspsc190501.50611719'),
	'identifierSchemeCodeUnspsc190501.50611720': __('identifierSchemeCodeUnspsc190501.50611720'),
	'identifierSchemeCodeUnspsc190501.50611721': __('identifierSchemeCodeUnspsc190501.50611721'),
	'identifierSchemeCodeUnspsc190501.50611722': __('identifierSchemeCodeUnspsc190501.50611722'),
	'identifierSchemeCodeUnspsc190501.50611723': __('identifierSchemeCodeUnspsc190501.50611723'),
	'identifierSchemeCodeUnspsc190501.50611724': __('identifierSchemeCodeUnspsc190501.50611724'),
	'identifierSchemeCodeUnspsc190501.50611725': __('identifierSchemeCodeUnspsc190501.50611725'),
	'identifierSchemeCodeUnspsc190501.50611726': __('identifierSchemeCodeUnspsc190501.50611726'),
	'identifierSchemeCodeUnspsc190501.50611727': __('identifierSchemeCodeUnspsc190501.50611727'),
	'identifierSchemeCodeUnspsc190501.50611728': __('identifierSchemeCodeUnspsc190501.50611728'),
	'identifierSchemeCodeUnspsc190501.50611729': __('identifierSchemeCodeUnspsc190501.50611729'),
	'identifierSchemeCodeUnspsc190501.50611730': __('identifierSchemeCodeUnspsc190501.50611730'),
	'identifierSchemeCodeUnspsc190501.50611731': __('identifierSchemeCodeUnspsc190501.50611731'),
	'identifierSchemeCodeUnspsc190501.50611732': __('identifierSchemeCodeUnspsc190501.50611732'),
	'identifierSchemeCodeUnspsc190501.50611733': __('identifierSchemeCodeUnspsc190501.50611733'),
	'identifierSchemeCodeUnspsc190501.50611734': __('identifierSchemeCodeUnspsc190501.50611734'),
	'identifierSchemeCodeUnspsc190501.50611735': __('identifierSchemeCodeUnspsc190501.50611735'),
	'identifierSchemeCodeUnspsc190501.50611736': __('identifierSchemeCodeUnspsc190501.50611736'),
	'identifierSchemeCodeUnspsc190501.50611737': __('identifierSchemeCodeUnspsc190501.50611737'),
	'identifierSchemeCodeUnspsc190501.50611738': __('identifierSchemeCodeUnspsc190501.50611738'),
	'identifierSchemeCodeUnspsc190501.50611739': __('identifierSchemeCodeUnspsc190501.50611739'),
	'identifierSchemeCodeUnspsc190501.50611740': __('identifierSchemeCodeUnspsc190501.50611740'),
	'identifierSchemeCodeUnspsc190501.50611741': __('identifierSchemeCodeUnspsc190501.50611741'),
	'identifierSchemeCodeUnspsc190501.50611742': __('identifierSchemeCodeUnspsc190501.50611742'),
	'identifierSchemeCodeUnspsc190501.50611743': __('identifierSchemeCodeUnspsc190501.50611743'),
	'identifierSchemeCodeUnspsc190501.50611744': __('identifierSchemeCodeUnspsc190501.50611744'),
	'identifierSchemeCodeUnspsc190501.50611745': __('identifierSchemeCodeUnspsc190501.50611745'),
	'identifierSchemeCodeUnspsc190501.50611746': __('identifierSchemeCodeUnspsc190501.50611746'),
	'identifierSchemeCodeUnspsc190501.50611747': __('identifierSchemeCodeUnspsc190501.50611747'),
	'identifierSchemeCodeUnspsc190501.50611748': __('identifierSchemeCodeUnspsc190501.50611748'),
	'identifierSchemeCodeUnspsc190501.50611749': __('identifierSchemeCodeUnspsc190501.50611749'),
	'identifierSchemeCodeUnspsc190501.50611750': __('identifierSchemeCodeUnspsc190501.50611750'),
	'identifierSchemeCodeUnspsc190501.50611751': __('identifierSchemeCodeUnspsc190501.50611751'),
	'identifierSchemeCodeUnspsc190501.50611752': __('identifierSchemeCodeUnspsc190501.50611752'),
	'identifierSchemeCodeUnspsc190501.50611753': __('identifierSchemeCodeUnspsc190501.50611753'),
	'identifierSchemeCodeUnspsc190501.50611754': __('identifierSchemeCodeUnspsc190501.50611754'),
	'identifierSchemeCodeUnspsc190501.50611755': __('identifierSchemeCodeUnspsc190501.50611755'),
	'identifierSchemeCodeUnspsc190501.50611756': __('identifierSchemeCodeUnspsc190501.50611756'),
	'identifierSchemeCodeUnspsc190501.50611757': __('identifierSchemeCodeUnspsc190501.50611757'),
	'identifierSchemeCodeUnspsc190501.50611758': __('identifierSchemeCodeUnspsc190501.50611758'),
	'identifierSchemeCodeUnspsc190501.50611759': __('identifierSchemeCodeUnspsc190501.50611759'),
	'identifierSchemeCodeUnspsc190501.50611760': __('identifierSchemeCodeUnspsc190501.50611760'),
	'identifierSchemeCodeUnspsc190501.50611761': __('identifierSchemeCodeUnspsc190501.50611761'),
	'identifierSchemeCodeUnspsc190501.50611762': __('identifierSchemeCodeUnspsc190501.50611762'),
	'identifierSchemeCodeUnspsc190501.50611763': __('identifierSchemeCodeUnspsc190501.50611763'),
	'identifierSchemeCodeUnspsc190501.50611764': __('identifierSchemeCodeUnspsc190501.50611764'),
	'identifierSchemeCodeUnspsc190501.50611765': __('identifierSchemeCodeUnspsc190501.50611765'),
	'identifierSchemeCodeUnspsc190501.50611766': __('identifierSchemeCodeUnspsc190501.50611766'),
	'identifierSchemeCodeUnspsc190501.50611767': __('identifierSchemeCodeUnspsc190501.50611767'),
	'identifierSchemeCodeUnspsc190501.50611768': __('identifierSchemeCodeUnspsc190501.50611768'),
	'identifierSchemeCodeUnspsc190501.50611769': __('identifierSchemeCodeUnspsc190501.50611769'),
	'identifierSchemeCodeUnspsc190501.50611770': __('identifierSchemeCodeUnspsc190501.50611770'),
	'identifierSchemeCodeUnspsc190501.50611771': __('identifierSchemeCodeUnspsc190501.50611771'),
	'identifierSchemeCodeUnspsc190501.50611772': __('identifierSchemeCodeUnspsc190501.50611772'),
	'identifierSchemeCodeUnspsc190501.50611773': __('identifierSchemeCodeUnspsc190501.50611773'),
	'identifierSchemeCodeUnspsc190501.50611774': __('identifierSchemeCodeUnspsc190501.50611774'),
	'identifierSchemeCodeUnspsc190501.50611775': __('identifierSchemeCodeUnspsc190501.50611775'),
	'identifierSchemeCodeUnspsc190501.50611776': __('identifierSchemeCodeUnspsc190501.50611776'),
	'identifierSchemeCodeUnspsc190501.50611777': __('identifierSchemeCodeUnspsc190501.50611777'),
	'identifierSchemeCodeUnspsc190501.50611778': __('identifierSchemeCodeUnspsc190501.50611778'),
	'identifierSchemeCodeUnspsc190501.50611779': __('identifierSchemeCodeUnspsc190501.50611779'),
	'identifierSchemeCodeUnspsc190501.50611780': __('identifierSchemeCodeUnspsc190501.50611780'),
	'identifierSchemeCodeUnspsc190501.50611781': __('identifierSchemeCodeUnspsc190501.50611781'),
	'identifierSchemeCodeUnspsc190501.50611782': __('identifierSchemeCodeUnspsc190501.50611782'),
	'identifierSchemeCodeUnspsc190501.50611800': __('identifierSchemeCodeUnspsc190501.50611800'),
	'identifierSchemeCodeUnspsc190501.50611801': __('identifierSchemeCodeUnspsc190501.50611801'),
	'identifierSchemeCodeUnspsc190501.50611802': __('identifierSchemeCodeUnspsc190501.50611802'),
	'identifierSchemeCodeUnspsc190501.50611803': __('identifierSchemeCodeUnspsc190501.50611803'),
	'identifierSchemeCodeUnspsc190501.50611804': __('identifierSchemeCodeUnspsc190501.50611804'),
	'identifierSchemeCodeUnspsc190501.50611805': __('identifierSchemeCodeUnspsc190501.50611805'),
	'identifierSchemeCodeUnspsc190501.50611806': __('identifierSchemeCodeUnspsc190501.50611806'),
	'identifierSchemeCodeUnspsc190501.50611807': __('identifierSchemeCodeUnspsc190501.50611807'),
	'identifierSchemeCodeUnspsc190501.50611808': __('identifierSchemeCodeUnspsc190501.50611808'),
	'identifierSchemeCodeUnspsc190501.50611809': __('identifierSchemeCodeUnspsc190501.50611809'),
	'identifierSchemeCodeUnspsc190501.50611810': __('identifierSchemeCodeUnspsc190501.50611810'),
	'identifierSchemeCodeUnspsc190501.50611811': __('identifierSchemeCodeUnspsc190501.50611811'),
	'identifierSchemeCodeUnspsc190501.50611812': __('identifierSchemeCodeUnspsc190501.50611812'),
	'identifierSchemeCodeUnspsc190501.50611813': __('identifierSchemeCodeUnspsc190501.50611813'),
	'identifierSchemeCodeUnspsc190501.50611814': __('identifierSchemeCodeUnspsc190501.50611814'),
	'identifierSchemeCodeUnspsc190501.50611815': __('identifierSchemeCodeUnspsc190501.50611815'),
	'identifierSchemeCodeUnspsc190501.50611816': __('identifierSchemeCodeUnspsc190501.50611816'),
	'identifierSchemeCodeUnspsc190501.50611817': __('identifierSchemeCodeUnspsc190501.50611817'),
	'identifierSchemeCodeUnspsc190501.50611818': __('identifierSchemeCodeUnspsc190501.50611818'),
	'identifierSchemeCodeUnspsc190501.50611819': __('identifierSchemeCodeUnspsc190501.50611819'),
	'identifierSchemeCodeUnspsc190501.50611820': __('identifierSchemeCodeUnspsc190501.50611820'),
	'identifierSchemeCodeUnspsc190501.50611821': __('identifierSchemeCodeUnspsc190501.50611821'),
	'identifierSchemeCodeUnspsc190501.50611822': __('identifierSchemeCodeUnspsc190501.50611822'),
	'identifierSchemeCodeUnspsc190501.50611823': __('identifierSchemeCodeUnspsc190501.50611823'),
	'identifierSchemeCodeUnspsc190501.50611824': __('identifierSchemeCodeUnspsc190501.50611824'),
	'identifierSchemeCodeUnspsc190501.50611825': __('identifierSchemeCodeUnspsc190501.50611825'),
	'identifierSchemeCodeUnspsc190501.50611826': __('identifierSchemeCodeUnspsc190501.50611826'),
	'identifierSchemeCodeUnspsc190501.50611827': __('identifierSchemeCodeUnspsc190501.50611827'),
	'identifierSchemeCodeUnspsc190501.50611828': __('identifierSchemeCodeUnspsc190501.50611828'),
	'identifierSchemeCodeUnspsc190501.50611829': __('identifierSchemeCodeUnspsc190501.50611829'),
	'identifierSchemeCodeUnspsc190501.50611830': __('identifierSchemeCodeUnspsc190501.50611830'),
	'identifierSchemeCodeUnspsc190501.50611831': __('identifierSchemeCodeUnspsc190501.50611831'),
	'identifierSchemeCodeUnspsc190501.50611832': __('identifierSchemeCodeUnspsc190501.50611832'),
	'identifierSchemeCodeUnspsc190501.50611833': __('identifierSchemeCodeUnspsc190501.50611833'),
	'identifierSchemeCodeUnspsc190501.50611834': __('identifierSchemeCodeUnspsc190501.50611834'),
	'identifierSchemeCodeUnspsc190501.50611835': __('identifierSchemeCodeUnspsc190501.50611835'),
	'identifierSchemeCodeUnspsc190501.50611836': __('identifierSchemeCodeUnspsc190501.50611836'),
	'identifierSchemeCodeUnspsc190501.50611837': __('identifierSchemeCodeUnspsc190501.50611837'),
	'identifierSchemeCodeUnspsc190501.50611838': __('identifierSchemeCodeUnspsc190501.50611838'),
	'identifierSchemeCodeUnspsc190501.50611839': __('identifierSchemeCodeUnspsc190501.50611839'),
	'identifierSchemeCodeUnspsc190501.50611840': __('identifierSchemeCodeUnspsc190501.50611840'),
	'identifierSchemeCodeUnspsc190501.50611841': __('identifierSchemeCodeUnspsc190501.50611841'),
	'identifierSchemeCodeUnspsc190501.50611842': __('identifierSchemeCodeUnspsc190501.50611842'),
	'identifierSchemeCodeUnspsc190501.50611843': __('identifierSchemeCodeUnspsc190501.50611843'),
	'identifierSchemeCodeUnspsc190501.50611844': __('identifierSchemeCodeUnspsc190501.50611844'),
	'identifierSchemeCodeUnspsc190501.50611845': __('identifierSchemeCodeUnspsc190501.50611845'),
	'identifierSchemeCodeUnspsc190501.50611846': __('identifierSchemeCodeUnspsc190501.50611846'),
	'identifierSchemeCodeUnspsc190501.50611847': __('identifierSchemeCodeUnspsc190501.50611847'),
	'identifierSchemeCodeUnspsc190501.50611848': __('identifierSchemeCodeUnspsc190501.50611848'),
	'identifierSchemeCodeUnspsc190501.50611849': __('identifierSchemeCodeUnspsc190501.50611849'),
	'identifierSchemeCodeUnspsc190501.50611850': __('identifierSchemeCodeUnspsc190501.50611850'),
	'identifierSchemeCodeUnspsc190501.50611851': __('identifierSchemeCodeUnspsc190501.50611851'),
	'identifierSchemeCodeUnspsc190501.50611852': __('identifierSchemeCodeUnspsc190501.50611852'),
	'identifierSchemeCodeUnspsc190501.50611900': __('identifierSchemeCodeUnspsc190501.50611900'),
	'identifierSchemeCodeUnspsc190501.50611901': __('identifierSchemeCodeUnspsc190501.50611901'),
	'identifierSchemeCodeUnspsc190501.50611902': __('identifierSchemeCodeUnspsc190501.50611902'),
	'identifierSchemeCodeUnspsc190501.50611903': __('identifierSchemeCodeUnspsc190501.50611903'),
	'identifierSchemeCodeUnspsc190501.50611904': __('identifierSchemeCodeUnspsc190501.50611904'),
	'identifierSchemeCodeUnspsc190501.50611905': __('identifierSchemeCodeUnspsc190501.50611905'),
	'identifierSchemeCodeUnspsc190501.50611906': __('identifierSchemeCodeUnspsc190501.50611906'),
	'identifierSchemeCodeUnspsc190501.50611907': __('identifierSchemeCodeUnspsc190501.50611907'),
	'identifierSchemeCodeUnspsc190501.50611908': __('identifierSchemeCodeUnspsc190501.50611908'),
	'identifierSchemeCodeUnspsc190501.50611909': __('identifierSchemeCodeUnspsc190501.50611909'),
	'identifierSchemeCodeUnspsc190501.50611910': __('identifierSchemeCodeUnspsc190501.50611910'),
	'identifierSchemeCodeUnspsc190501.50611911': __('identifierSchemeCodeUnspsc190501.50611911'),
	'identifierSchemeCodeUnspsc190501.50611912': __('identifierSchemeCodeUnspsc190501.50611912'),
	'identifierSchemeCodeUnspsc190501.50611913': __('identifierSchemeCodeUnspsc190501.50611913'),
	'identifierSchemeCodeUnspsc190501.50611914': __('identifierSchemeCodeUnspsc190501.50611914'),
	'identifierSchemeCodeUnspsc190501.50611915': __('identifierSchemeCodeUnspsc190501.50611915'),
	'identifierSchemeCodeUnspsc190501.50611916': __('identifierSchemeCodeUnspsc190501.50611916'),
	'identifierSchemeCodeUnspsc190501.50611917': __('identifierSchemeCodeUnspsc190501.50611917'),
	'identifierSchemeCodeUnspsc190501.50611918': __('identifierSchemeCodeUnspsc190501.50611918'),
	'identifierSchemeCodeUnspsc190501.50611919': __('identifierSchemeCodeUnspsc190501.50611919'),
	'identifierSchemeCodeUnspsc190501.50611920': __('identifierSchemeCodeUnspsc190501.50611920'),
	'identifierSchemeCodeUnspsc190501.50611921': __('identifierSchemeCodeUnspsc190501.50611921'),
	'identifierSchemeCodeUnspsc190501.50611922': __('identifierSchemeCodeUnspsc190501.50611922'),
	'identifierSchemeCodeUnspsc190501.50612000': __('identifierSchemeCodeUnspsc190501.50612000'),
	'identifierSchemeCodeUnspsc190501.50612001': __('identifierSchemeCodeUnspsc190501.50612001'),
	'identifierSchemeCodeUnspsc190501.50612002': __('identifierSchemeCodeUnspsc190501.50612002'),
	'identifierSchemeCodeUnspsc190501.50612003': __('identifierSchemeCodeUnspsc190501.50612003'),
	'identifierSchemeCodeUnspsc190501.50612004': __('identifierSchemeCodeUnspsc190501.50612004'),
	'identifierSchemeCodeUnspsc190501.50612100': __('identifierSchemeCodeUnspsc190501.50612100'),
	'identifierSchemeCodeUnspsc190501.50612101': __('identifierSchemeCodeUnspsc190501.50612101'),
	'identifierSchemeCodeUnspsc190501.50612102': __('identifierSchemeCodeUnspsc190501.50612102'),
	'identifierSchemeCodeUnspsc190501.50612103': __('identifierSchemeCodeUnspsc190501.50612103'),
	'identifierSchemeCodeUnspsc190501.50612104': __('identifierSchemeCodeUnspsc190501.50612104'),
	'identifierSchemeCodeUnspsc190501.50612105': __('identifierSchemeCodeUnspsc190501.50612105'),
	'identifierSchemeCodeUnspsc190501.50612106': __('identifierSchemeCodeUnspsc190501.50612106'),
	'identifierSchemeCodeUnspsc190501.50612107': __('identifierSchemeCodeUnspsc190501.50612107'),
	'identifierSchemeCodeUnspsc190501.50612200': __('identifierSchemeCodeUnspsc190501.50612200'),
	'identifierSchemeCodeUnspsc190501.50612201': __('identifierSchemeCodeUnspsc190501.50612201'),
	'identifierSchemeCodeUnspsc190501.50612202': __('identifierSchemeCodeUnspsc190501.50612202'),
	'identifierSchemeCodeUnspsc190501.50612203': __('identifierSchemeCodeUnspsc190501.50612203'),
	'identifierSchemeCodeUnspsc190501.50612204': __('identifierSchemeCodeUnspsc190501.50612204'),
	'identifierSchemeCodeUnspsc190501.50612205': __('identifierSchemeCodeUnspsc190501.50612205'),
	'identifierSchemeCodeUnspsc190501.50612206': __('identifierSchemeCodeUnspsc190501.50612206'),
	'identifierSchemeCodeUnspsc190501.50612207': __('identifierSchemeCodeUnspsc190501.50612207'),
	'identifierSchemeCodeUnspsc190501.50612208': __('identifierSchemeCodeUnspsc190501.50612208'),
	'identifierSchemeCodeUnspsc190501.50612209': __('identifierSchemeCodeUnspsc190501.50612209'),
	'identifierSchemeCodeUnspsc190501.50612210': __('identifierSchemeCodeUnspsc190501.50612210'),
	'identifierSchemeCodeUnspsc190501.50612211': __('identifierSchemeCodeUnspsc190501.50612211'),
	'identifierSchemeCodeUnspsc190501.50612300': __('identifierSchemeCodeUnspsc190501.50612300'),
	'identifierSchemeCodeUnspsc190501.50612301': __('identifierSchemeCodeUnspsc190501.50612301'),
	'identifierSchemeCodeUnspsc190501.50612302': __('identifierSchemeCodeUnspsc190501.50612302'),
	'identifierSchemeCodeUnspsc190501.50612303': __('identifierSchemeCodeUnspsc190501.50612303'),
	'identifierSchemeCodeUnspsc190501.50612304': __('identifierSchemeCodeUnspsc190501.50612304'),
	'identifierSchemeCodeUnspsc190501.50612400': __('identifierSchemeCodeUnspsc190501.50612400'),
	'identifierSchemeCodeUnspsc190501.50612401': __('identifierSchemeCodeUnspsc190501.50612401'),
	'identifierSchemeCodeUnspsc190501.50612402': __('identifierSchemeCodeUnspsc190501.50612402'),
	'identifierSchemeCodeUnspsc190501.50612500': __('identifierSchemeCodeUnspsc190501.50612500'),
	'identifierSchemeCodeUnspsc190501.50612501': __('identifierSchemeCodeUnspsc190501.50612501'),
	'identifierSchemeCodeUnspsc190501.50612502': __('identifierSchemeCodeUnspsc190501.50612502'),
	'identifierSchemeCodeUnspsc190501.50612503': __('identifierSchemeCodeUnspsc190501.50612503'),
	'identifierSchemeCodeUnspsc190501.50612504': __('identifierSchemeCodeUnspsc190501.50612504'),
	'identifierSchemeCodeUnspsc190501.50612505': __('identifierSchemeCodeUnspsc190501.50612505'),
	'identifierSchemeCodeUnspsc190501.50612506': __('identifierSchemeCodeUnspsc190501.50612506'),
	'identifierSchemeCodeUnspsc190501.50612600': __('identifierSchemeCodeUnspsc190501.50612600'),
	'identifierSchemeCodeUnspsc190501.50612601': __('identifierSchemeCodeUnspsc190501.50612601'),
	'identifierSchemeCodeUnspsc190501.50612602': __('identifierSchemeCodeUnspsc190501.50612602'),
	'identifierSchemeCodeUnspsc190501.50612603': __('identifierSchemeCodeUnspsc190501.50612603'),
	'identifierSchemeCodeUnspsc190501.50612604': __('identifierSchemeCodeUnspsc190501.50612604'),
	'identifierSchemeCodeUnspsc190501.50612605': __('identifierSchemeCodeUnspsc190501.50612605'),
	'identifierSchemeCodeUnspsc190501.50612606': __('identifierSchemeCodeUnspsc190501.50612606'),
	'identifierSchemeCodeUnspsc190501.50612607': __('identifierSchemeCodeUnspsc190501.50612607'),
	'identifierSchemeCodeUnspsc190501.50612608': __('identifierSchemeCodeUnspsc190501.50612608'),
	'identifierSchemeCodeUnspsc190501.50612609': __('identifierSchemeCodeUnspsc190501.50612609'),
	'identifierSchemeCodeUnspsc190501.50612610': __('identifierSchemeCodeUnspsc190501.50612610'),
	'identifierSchemeCodeUnspsc190501.50612611': __('identifierSchemeCodeUnspsc190501.50612611'),
	'identifierSchemeCodeUnspsc190501.50612612': __('identifierSchemeCodeUnspsc190501.50612612'),
	'identifierSchemeCodeUnspsc190501.50612700': __('identifierSchemeCodeUnspsc190501.50612700'),
	'identifierSchemeCodeUnspsc190501.50612701': __('identifierSchemeCodeUnspsc190501.50612701'),
	'identifierSchemeCodeUnspsc190501.50612702': __('identifierSchemeCodeUnspsc190501.50612702'),
	'identifierSchemeCodeUnspsc190501.50612703': __('identifierSchemeCodeUnspsc190501.50612703'),
	'identifierSchemeCodeUnspsc190501.50612704': __('identifierSchemeCodeUnspsc190501.50612704'),
	'identifierSchemeCodeUnspsc190501.50612705': __('identifierSchemeCodeUnspsc190501.50612705'),
	'identifierSchemeCodeUnspsc190501.50612706': __('identifierSchemeCodeUnspsc190501.50612706'),
	'identifierSchemeCodeUnspsc190501.50612707': __('identifierSchemeCodeUnspsc190501.50612707'),
	'identifierSchemeCodeUnspsc190501.50612708': __('identifierSchemeCodeUnspsc190501.50612708'),
	'identifierSchemeCodeUnspsc190501.50612709': __('identifierSchemeCodeUnspsc190501.50612709'),
	'identifierSchemeCodeUnspsc190501.50612710': __('identifierSchemeCodeUnspsc190501.50612710'),
	'identifierSchemeCodeUnspsc190501.50612711': __('identifierSchemeCodeUnspsc190501.50612711'),
	'identifierSchemeCodeUnspsc190501.50612712': __('identifierSchemeCodeUnspsc190501.50612712'),
	'identifierSchemeCodeUnspsc190501.50612713': __('identifierSchemeCodeUnspsc190501.50612713'),
	'identifierSchemeCodeUnspsc190501.50612714': __('identifierSchemeCodeUnspsc190501.50612714'),
	'identifierSchemeCodeUnspsc190501.50612715': __('identifierSchemeCodeUnspsc190501.50612715'),
	'identifierSchemeCodeUnspsc190501.50612800': __('identifierSchemeCodeUnspsc190501.50612800'),
	'identifierSchemeCodeUnspsc190501.50612801': __('identifierSchemeCodeUnspsc190501.50612801'),
	'identifierSchemeCodeUnspsc190501.50612802': __('identifierSchemeCodeUnspsc190501.50612802'),
	'identifierSchemeCodeUnspsc190501.50612803': __('identifierSchemeCodeUnspsc190501.50612803'),
	'identifierSchemeCodeUnspsc190501.50612804': __('identifierSchemeCodeUnspsc190501.50612804'),
	'identifierSchemeCodeUnspsc190501.50612805': __('identifierSchemeCodeUnspsc190501.50612805'),
	'identifierSchemeCodeUnspsc190501.50612806': __('identifierSchemeCodeUnspsc190501.50612806'),
	'identifierSchemeCodeUnspsc190501.50612807': __('identifierSchemeCodeUnspsc190501.50612807'),
	'identifierSchemeCodeUnspsc190501.50612808': __('identifierSchemeCodeUnspsc190501.50612808'),
	'identifierSchemeCodeUnspsc190501.50612900': __('identifierSchemeCodeUnspsc190501.50612900'),
	'identifierSchemeCodeUnspsc190501.50612901': __('identifierSchemeCodeUnspsc190501.50612901'),
	'identifierSchemeCodeUnspsc190501.50612902': __('identifierSchemeCodeUnspsc190501.50612902'),
	'identifierSchemeCodeUnspsc190501.50612903': __('identifierSchemeCodeUnspsc190501.50612903'),
	'identifierSchemeCodeUnspsc190501.50612904': __('identifierSchemeCodeUnspsc190501.50612904'),
	'identifierSchemeCodeUnspsc190501.50612905': __('identifierSchemeCodeUnspsc190501.50612905'),
	'identifierSchemeCodeUnspsc190501.50612906': __('identifierSchemeCodeUnspsc190501.50612906'),
	'identifierSchemeCodeUnspsc190501.50612907': __('identifierSchemeCodeUnspsc190501.50612907'),
	'identifierSchemeCodeUnspsc190501.50612908': __('identifierSchemeCodeUnspsc190501.50612908'),
	'identifierSchemeCodeUnspsc190501.50612909': __('identifierSchemeCodeUnspsc190501.50612909'),
	'identifierSchemeCodeUnspsc190501.50612910': __('identifierSchemeCodeUnspsc190501.50612910'),
	'identifierSchemeCodeUnspsc190501.50612911': __('identifierSchemeCodeUnspsc190501.50612911'),
	'identifierSchemeCodeUnspsc190501.50613000': __('identifierSchemeCodeUnspsc190501.50613000'),
	'identifierSchemeCodeUnspsc190501.50613001': __('identifierSchemeCodeUnspsc190501.50613001'),
	'identifierSchemeCodeUnspsc190501.50613002': __('identifierSchemeCodeUnspsc190501.50613002'),
	'identifierSchemeCodeUnspsc190501.50613003': __('identifierSchemeCodeUnspsc190501.50613003'),
	'identifierSchemeCodeUnspsc190501.50613004': __('identifierSchemeCodeUnspsc190501.50613004'),
	'identifierSchemeCodeUnspsc190501.50613005': __('identifierSchemeCodeUnspsc190501.50613005'),
	'identifierSchemeCodeUnspsc190501.50613006': __('identifierSchemeCodeUnspsc190501.50613006'),
	'identifierSchemeCodeUnspsc190501.50613007': __('identifierSchemeCodeUnspsc190501.50613007'),
	'identifierSchemeCodeUnspsc190501.50613008': __('identifierSchemeCodeUnspsc190501.50613008'),
	'identifierSchemeCodeUnspsc190501.50613009': __('identifierSchemeCodeUnspsc190501.50613009'),
	'identifierSchemeCodeUnspsc190501.50613010': __('identifierSchemeCodeUnspsc190501.50613010'),
	'identifierSchemeCodeUnspsc190501.50613011': __('identifierSchemeCodeUnspsc190501.50613011'),
	'identifierSchemeCodeUnspsc190501.50613012': __('identifierSchemeCodeUnspsc190501.50613012'),
	'identifierSchemeCodeUnspsc190501.50613013': __('identifierSchemeCodeUnspsc190501.50613013'),
	'identifierSchemeCodeUnspsc190501.50613014': __('identifierSchemeCodeUnspsc190501.50613014'),
	'identifierSchemeCodeUnspsc190501.50613015': __('identifierSchemeCodeUnspsc190501.50613015'),
	'identifierSchemeCodeUnspsc190501.50613016': __('identifierSchemeCodeUnspsc190501.50613016'),
	'identifierSchemeCodeUnspsc190501.50613100': __('identifierSchemeCodeUnspsc190501.50613100'),
	'identifierSchemeCodeUnspsc190501.50613101': __('identifierSchemeCodeUnspsc190501.50613101'),
	'identifierSchemeCodeUnspsc190501.50613102': __('identifierSchemeCodeUnspsc190501.50613102'),
	'identifierSchemeCodeUnspsc190501.50613200': __('identifierSchemeCodeUnspsc190501.50613200'),
	'identifierSchemeCodeUnspsc190501.50613201': __('identifierSchemeCodeUnspsc190501.50613201'),
	'identifierSchemeCodeUnspsc190501.50613202': __('identifierSchemeCodeUnspsc190501.50613202'),
	'identifierSchemeCodeUnspsc190501.50613203': __('identifierSchemeCodeUnspsc190501.50613203'),
	'identifierSchemeCodeUnspsc190501.50613204': __('identifierSchemeCodeUnspsc190501.50613204'),
	'identifierSchemeCodeUnspsc190501.50613205': __('identifierSchemeCodeUnspsc190501.50613205'),
	'identifierSchemeCodeUnspsc190501.50613206': __('identifierSchemeCodeUnspsc190501.50613206'),
	'identifierSchemeCodeUnspsc190501.50613207': __('identifierSchemeCodeUnspsc190501.50613207'),
	'identifierSchemeCodeUnspsc190501.50613208': __('identifierSchemeCodeUnspsc190501.50613208'),
	'identifierSchemeCodeUnspsc190501.50613209': __('identifierSchemeCodeUnspsc190501.50613209'),
	'identifierSchemeCodeUnspsc190501.50613210': __('identifierSchemeCodeUnspsc190501.50613210'),
	'identifierSchemeCodeUnspsc190501.50613211': __('identifierSchemeCodeUnspsc190501.50613211'),
	'identifierSchemeCodeUnspsc190501.50613212': __('identifierSchemeCodeUnspsc190501.50613212'),
	'identifierSchemeCodeUnspsc190501.50613213': __('identifierSchemeCodeUnspsc190501.50613213'),
	'identifierSchemeCodeUnspsc190501.50613214': __('identifierSchemeCodeUnspsc190501.50613214'),
	'identifierSchemeCodeUnspsc190501.50613215': __('identifierSchemeCodeUnspsc190501.50613215'),
	'identifierSchemeCodeUnspsc190501.50613216': __('identifierSchemeCodeUnspsc190501.50613216'),
	'identifierSchemeCodeUnspsc190501.50613217': __('identifierSchemeCodeUnspsc190501.50613217'),
	'identifierSchemeCodeUnspsc190501.50613218': __('identifierSchemeCodeUnspsc190501.50613218'),
	'identifierSchemeCodeUnspsc190501.50613219': __('identifierSchemeCodeUnspsc190501.50613219'),
	'identifierSchemeCodeUnspsc190501.50613220': __('identifierSchemeCodeUnspsc190501.50613220'),
	'identifierSchemeCodeUnspsc190501.50613221': __('identifierSchemeCodeUnspsc190501.50613221'),
	'identifierSchemeCodeUnspsc190501.50613222': __('identifierSchemeCodeUnspsc190501.50613222'),
	'identifierSchemeCodeUnspsc190501.50613223': __('identifierSchemeCodeUnspsc190501.50613223'),
	'identifierSchemeCodeUnspsc190501.50613224': __('identifierSchemeCodeUnspsc190501.50613224'),
	'identifierSchemeCodeUnspsc190501.50613225': __('identifierSchemeCodeUnspsc190501.50613225'),
	'identifierSchemeCodeUnspsc190501.50613226': __('identifierSchemeCodeUnspsc190501.50613226'),
	'identifierSchemeCodeUnspsc190501.50613227': __('identifierSchemeCodeUnspsc190501.50613227'),
	'identifierSchemeCodeUnspsc190501.50613228': __('identifierSchemeCodeUnspsc190501.50613228'),
	'identifierSchemeCodeUnspsc190501.50613229': __('identifierSchemeCodeUnspsc190501.50613229'),
	'identifierSchemeCodeUnspsc190501.50613230': __('identifierSchemeCodeUnspsc190501.50613230'),
	'identifierSchemeCodeUnspsc190501.50613231': __('identifierSchemeCodeUnspsc190501.50613231'),
	'identifierSchemeCodeUnspsc190501.50613232': __('identifierSchemeCodeUnspsc190501.50613232'),
	'identifierSchemeCodeUnspsc190501.50613233': __('identifierSchemeCodeUnspsc190501.50613233'),
	'identifierSchemeCodeUnspsc190501.50613234': __('identifierSchemeCodeUnspsc190501.50613234'),
	'identifierSchemeCodeUnspsc190501.50613235': __('identifierSchemeCodeUnspsc190501.50613235'),
	'identifierSchemeCodeUnspsc190501.50613236': __('identifierSchemeCodeUnspsc190501.50613236'),
	'identifierSchemeCodeUnspsc190501.50613237': __('identifierSchemeCodeUnspsc190501.50613237'),
	'identifierSchemeCodeUnspsc190501.50613238': __('identifierSchemeCodeUnspsc190501.50613238'),
	'identifierSchemeCodeUnspsc190501.50613239': __('identifierSchemeCodeUnspsc190501.50613239'),
	'identifierSchemeCodeUnspsc190501.50613240': __('identifierSchemeCodeUnspsc190501.50613240'),
	'identifierSchemeCodeUnspsc190501.50613241': __('identifierSchemeCodeUnspsc190501.50613241'),
	'identifierSchemeCodeUnspsc190501.50613242': __('identifierSchemeCodeUnspsc190501.50613242'),
	'identifierSchemeCodeUnspsc190501.50613243': __('identifierSchemeCodeUnspsc190501.50613243'),
	'identifierSchemeCodeUnspsc190501.50613244': __('identifierSchemeCodeUnspsc190501.50613244'),
	'identifierSchemeCodeUnspsc190501.50613245': __('identifierSchemeCodeUnspsc190501.50613245'),
	'identifierSchemeCodeUnspsc190501.50613246': __('identifierSchemeCodeUnspsc190501.50613246'),
	'identifierSchemeCodeUnspsc190501.50613247': __('identifierSchemeCodeUnspsc190501.50613247'),
	'identifierSchemeCodeUnspsc190501.50613248': __('identifierSchemeCodeUnspsc190501.50613248'),
	'identifierSchemeCodeUnspsc190501.50613249': __('identifierSchemeCodeUnspsc190501.50613249'),
	'identifierSchemeCodeUnspsc190501.50613250': __('identifierSchemeCodeUnspsc190501.50613250'),
	'identifierSchemeCodeUnspsc190501.50613251': __('identifierSchemeCodeUnspsc190501.50613251'),
	'identifierSchemeCodeUnspsc190501.50613252': __('identifierSchemeCodeUnspsc190501.50613252'),
	'identifierSchemeCodeUnspsc190501.50613253': __('identifierSchemeCodeUnspsc190501.50613253'),
	'identifierSchemeCodeUnspsc190501.50613254': __('identifierSchemeCodeUnspsc190501.50613254'),
	'identifierSchemeCodeUnspsc190501.50613255': __('identifierSchemeCodeUnspsc190501.50613255'),
	'identifierSchemeCodeUnspsc190501.50613256': __('identifierSchemeCodeUnspsc190501.50613256'),
	'identifierSchemeCodeUnspsc190501.50613257': __('identifierSchemeCodeUnspsc190501.50613257'),
	'identifierSchemeCodeUnspsc190501.50613258': __('identifierSchemeCodeUnspsc190501.50613258'),
	'identifierSchemeCodeUnspsc190501.50613259': __('identifierSchemeCodeUnspsc190501.50613259'),
	'identifierSchemeCodeUnspsc190501.50613260': __('identifierSchemeCodeUnspsc190501.50613260'),
	'identifierSchemeCodeUnspsc190501.50613261': __('identifierSchemeCodeUnspsc190501.50613261'),
	'identifierSchemeCodeUnspsc190501.50613262': __('identifierSchemeCodeUnspsc190501.50613262'),
	'identifierSchemeCodeUnspsc190501.50613263': __('identifierSchemeCodeUnspsc190501.50613263'),
	'identifierSchemeCodeUnspsc190501.50613264': __('identifierSchemeCodeUnspsc190501.50613264'),
	'identifierSchemeCodeUnspsc190501.50613265': __('identifierSchemeCodeUnspsc190501.50613265'),
	'identifierSchemeCodeUnspsc190501.50613266': __('identifierSchemeCodeUnspsc190501.50613266'),
	'identifierSchemeCodeUnspsc190501.50613267': __('identifierSchemeCodeUnspsc190501.50613267'),
	'identifierSchemeCodeUnspsc190501.50613268': __('identifierSchemeCodeUnspsc190501.50613268'),
	'identifierSchemeCodeUnspsc190501.50613269': __('identifierSchemeCodeUnspsc190501.50613269'),
	'identifierSchemeCodeUnspsc190501.50613300': __('identifierSchemeCodeUnspsc190501.50613300'),
	'identifierSchemeCodeUnspsc190501.50613301': __('identifierSchemeCodeUnspsc190501.50613301'),
	'identifierSchemeCodeUnspsc190501.50613302': __('identifierSchemeCodeUnspsc190501.50613302'),
	'identifierSchemeCodeUnspsc190501.50613303': __('identifierSchemeCodeUnspsc190501.50613303'),
	'identifierSchemeCodeUnspsc190501.50613304': __('identifierSchemeCodeUnspsc190501.50613304'),
	'identifierSchemeCodeUnspsc190501.50613400': __('identifierSchemeCodeUnspsc190501.50613400'),
	'identifierSchemeCodeUnspsc190501.50613401': __('identifierSchemeCodeUnspsc190501.50613401'),
	'identifierSchemeCodeUnspsc190501.50613402': __('identifierSchemeCodeUnspsc190501.50613402'),
	'identifierSchemeCodeUnspsc190501.50613403': __('identifierSchemeCodeUnspsc190501.50613403'),
	'identifierSchemeCodeUnspsc190501.50613404': __('identifierSchemeCodeUnspsc190501.50613404'),
	'identifierSchemeCodeUnspsc190501.50613405': __('identifierSchemeCodeUnspsc190501.50613405'),
	'identifierSchemeCodeUnspsc190501.50613406': __('identifierSchemeCodeUnspsc190501.50613406'),
	'identifierSchemeCodeUnspsc190501.50613407': __('identifierSchemeCodeUnspsc190501.50613407'),
	'identifierSchemeCodeUnspsc190501.50613408': __('identifierSchemeCodeUnspsc190501.50613408'),
	'identifierSchemeCodeUnspsc190501.50613409': __('identifierSchemeCodeUnspsc190501.50613409'),
	'identifierSchemeCodeUnspsc190501.50613410': __('identifierSchemeCodeUnspsc190501.50613410'),
	'identifierSchemeCodeUnspsc190501.50613411': __('identifierSchemeCodeUnspsc190501.50613411'),
	'identifierSchemeCodeUnspsc190501.50613412': __('identifierSchemeCodeUnspsc190501.50613412'),
	'identifierSchemeCodeUnspsc190501.50613413': __('identifierSchemeCodeUnspsc190501.50613413'),
	'identifierSchemeCodeUnspsc190501.50613414': __('identifierSchemeCodeUnspsc190501.50613414'),
	'identifierSchemeCodeUnspsc190501.50613415': __('identifierSchemeCodeUnspsc190501.50613415'),
	'identifierSchemeCodeUnspsc190501.50613416': __('identifierSchemeCodeUnspsc190501.50613416'),
	'identifierSchemeCodeUnspsc190501.50613417': __('identifierSchemeCodeUnspsc190501.50613417'),
	'identifierSchemeCodeUnspsc190501.50613418': __('identifierSchemeCodeUnspsc190501.50613418'),
	'identifierSchemeCodeUnspsc190501.50613419': __('identifierSchemeCodeUnspsc190501.50613419'),
	'identifierSchemeCodeUnspsc190501.50613420': __('identifierSchemeCodeUnspsc190501.50613420'),
	'identifierSchemeCodeUnspsc190501.50613421': __('identifierSchemeCodeUnspsc190501.50613421'),
	'identifierSchemeCodeUnspsc190501.50613422': __('identifierSchemeCodeUnspsc190501.50613422'),
	'identifierSchemeCodeUnspsc190501.50613423': __('identifierSchemeCodeUnspsc190501.50613423'),
	'identifierSchemeCodeUnspsc190501.50613500': __('identifierSchemeCodeUnspsc190501.50613500'),
	'identifierSchemeCodeUnspsc190501.50613501': __('identifierSchemeCodeUnspsc190501.50613501'),
	'identifierSchemeCodeUnspsc190501.50613502': __('identifierSchemeCodeUnspsc190501.50613502'),
	'identifierSchemeCodeUnspsc190501.50613503': __('identifierSchemeCodeUnspsc190501.50613503'),
	'identifierSchemeCodeUnspsc190501.50613504': __('identifierSchemeCodeUnspsc190501.50613504'),
	'identifierSchemeCodeUnspsc190501.50613505': __('identifierSchemeCodeUnspsc190501.50613505'),
	'identifierSchemeCodeUnspsc190501.50613506': __('identifierSchemeCodeUnspsc190501.50613506'),
	'identifierSchemeCodeUnspsc190501.50613507': __('identifierSchemeCodeUnspsc190501.50613507'),
	'identifierSchemeCodeUnspsc190501.50613508': __('identifierSchemeCodeUnspsc190501.50613508'),
	'identifierSchemeCodeUnspsc190501.50613509': __('identifierSchemeCodeUnspsc190501.50613509'),
	'identifierSchemeCodeUnspsc190501.50613510': __('identifierSchemeCodeUnspsc190501.50613510'),
	'identifierSchemeCodeUnspsc190501.50613511': __('identifierSchemeCodeUnspsc190501.50613511'),
	'identifierSchemeCodeUnspsc190501.50613512': __('identifierSchemeCodeUnspsc190501.50613512'),
	'identifierSchemeCodeUnspsc190501.50613513': __('identifierSchemeCodeUnspsc190501.50613513'),
	'identifierSchemeCodeUnspsc190501.50613514': __('identifierSchemeCodeUnspsc190501.50613514'),
	'identifierSchemeCodeUnspsc190501.50613515': __('identifierSchemeCodeUnspsc190501.50613515'),
	'identifierSchemeCodeUnspsc190501.50613516': __('identifierSchemeCodeUnspsc190501.50613516'),
	'identifierSchemeCodeUnspsc190501.50613517': __('identifierSchemeCodeUnspsc190501.50613517'),
	'identifierSchemeCodeUnspsc190501.50613518': __('identifierSchemeCodeUnspsc190501.50613518'),
	'identifierSchemeCodeUnspsc190501.50613519': __('identifierSchemeCodeUnspsc190501.50613519'),
	'identifierSchemeCodeUnspsc190501.50613520': __('identifierSchemeCodeUnspsc190501.50613520'),
	'identifierSchemeCodeUnspsc190501.50613600': __('identifierSchemeCodeUnspsc190501.50613600'),
	'identifierSchemeCodeUnspsc190501.50613601': __('identifierSchemeCodeUnspsc190501.50613601'),
	'identifierSchemeCodeUnspsc190501.50613602': __('identifierSchemeCodeUnspsc190501.50613602'),
	'identifierSchemeCodeUnspsc190501.50613603': __('identifierSchemeCodeUnspsc190501.50613603'),
	'identifierSchemeCodeUnspsc190501.50613604': __('identifierSchemeCodeUnspsc190501.50613604'),
	'identifierSchemeCodeUnspsc190501.50613605': __('identifierSchemeCodeUnspsc190501.50613605'),
	'identifierSchemeCodeUnspsc190501.50613606': __('identifierSchemeCodeUnspsc190501.50613606'),
	'identifierSchemeCodeUnspsc190501.50613607': __('identifierSchemeCodeUnspsc190501.50613607'),
	'identifierSchemeCodeUnspsc190501.50613608': __('identifierSchemeCodeUnspsc190501.50613608'),
	'identifierSchemeCodeUnspsc190501.50613609': __('identifierSchemeCodeUnspsc190501.50613609'),
	'identifierSchemeCodeUnspsc190501.50613610': __('identifierSchemeCodeUnspsc190501.50613610'),
	'identifierSchemeCodeUnspsc190501.50613611': __('identifierSchemeCodeUnspsc190501.50613611'),
	'identifierSchemeCodeUnspsc190501.50613612': __('identifierSchemeCodeUnspsc190501.50613612'),
	'identifierSchemeCodeUnspsc190501.50613613': __('identifierSchemeCodeUnspsc190501.50613613'),
	'identifierSchemeCodeUnspsc190501.50613614': __('identifierSchemeCodeUnspsc190501.50613614'),
	'identifierSchemeCodeUnspsc190501.50613700': __('identifierSchemeCodeUnspsc190501.50613700'),
	'identifierSchemeCodeUnspsc190501.50613701': __('identifierSchemeCodeUnspsc190501.50613701'),
	'identifierSchemeCodeUnspsc190501.50613702': __('identifierSchemeCodeUnspsc190501.50613702'),
	'identifierSchemeCodeUnspsc190501.50613703': __('identifierSchemeCodeUnspsc190501.50613703'),
	'identifierSchemeCodeUnspsc190501.50613704': __('identifierSchemeCodeUnspsc190501.50613704'),
	'identifierSchemeCodeUnspsc190501.50613705': __('identifierSchemeCodeUnspsc190501.50613705'),
	'identifierSchemeCodeUnspsc190501.50613706': __('identifierSchemeCodeUnspsc190501.50613706'),
	'identifierSchemeCodeUnspsc190501.50613707': __('identifierSchemeCodeUnspsc190501.50613707'),
	'identifierSchemeCodeUnspsc190501.50613800': __('identifierSchemeCodeUnspsc190501.50613800'),
	'identifierSchemeCodeUnspsc190501.50613801': __('identifierSchemeCodeUnspsc190501.50613801'),
	'identifierSchemeCodeUnspsc190501.50613802': __('identifierSchemeCodeUnspsc190501.50613802'),
	'identifierSchemeCodeUnspsc190501.50613803': __('identifierSchemeCodeUnspsc190501.50613803'),
	'identifierSchemeCodeUnspsc190501.50613804': __('identifierSchemeCodeUnspsc190501.50613804'),
	'identifierSchemeCodeUnspsc190501.50613805': __('identifierSchemeCodeUnspsc190501.50613805'),
	'identifierSchemeCodeUnspsc190501.50613806': __('identifierSchemeCodeUnspsc190501.50613806'),
	'identifierSchemeCodeUnspsc190501.50613807': __('identifierSchemeCodeUnspsc190501.50613807'),
	'identifierSchemeCodeUnspsc190501.50613808': __('identifierSchemeCodeUnspsc190501.50613808'),
	'identifierSchemeCodeUnspsc190501.50613809': __('identifierSchemeCodeUnspsc190501.50613809'),
	'identifierSchemeCodeUnspsc190501.50613810': __('identifierSchemeCodeUnspsc190501.50613810'),
	'identifierSchemeCodeUnspsc190501.50613811': __('identifierSchemeCodeUnspsc190501.50613811'),
	'identifierSchemeCodeUnspsc190501.50613900': __('identifierSchemeCodeUnspsc190501.50613900'),
	'identifierSchemeCodeUnspsc190501.50613901': __('identifierSchemeCodeUnspsc190501.50613901'),
	'identifierSchemeCodeUnspsc190501.50613902': __('identifierSchemeCodeUnspsc190501.50613902'),
	'identifierSchemeCodeUnspsc190501.50613903': __('identifierSchemeCodeUnspsc190501.50613903'),
	'identifierSchemeCodeUnspsc190501.50613904': __('identifierSchemeCodeUnspsc190501.50613904'),
	'identifierSchemeCodeUnspsc190501.50613905': __('identifierSchemeCodeUnspsc190501.50613905'),
	'identifierSchemeCodeUnspsc190501.50613906': __('identifierSchemeCodeUnspsc190501.50613906'),
	'identifierSchemeCodeUnspsc190501.50613907': __('identifierSchemeCodeUnspsc190501.50613907'),
	'identifierSchemeCodeUnspsc190501.50613908': __('identifierSchemeCodeUnspsc190501.50613908'),
	'identifierSchemeCodeUnspsc190501.50613909': __('identifierSchemeCodeUnspsc190501.50613909'),
	'identifierSchemeCodeUnspsc190501.50613910': __('identifierSchemeCodeUnspsc190501.50613910'),
	'identifierSchemeCodeUnspsc190501.50613911': __('identifierSchemeCodeUnspsc190501.50613911'),
	'identifierSchemeCodeUnspsc190501.50614000': __('identifierSchemeCodeUnspsc190501.50614000'),
	'identifierSchemeCodeUnspsc190501.50614001': __('identifierSchemeCodeUnspsc190501.50614001'),
	'identifierSchemeCodeUnspsc190501.50614002': __('identifierSchemeCodeUnspsc190501.50614002'),
	'identifierSchemeCodeUnspsc190501.50614003': __('identifierSchemeCodeUnspsc190501.50614003'),
	'identifierSchemeCodeUnspsc190501.50614004': __('identifierSchemeCodeUnspsc190501.50614004'),
	'identifierSchemeCodeUnspsc190501.50614005': __('identifierSchemeCodeUnspsc190501.50614005'),
	'identifierSchemeCodeUnspsc190501.50614100': __('identifierSchemeCodeUnspsc190501.50614100'),
	'identifierSchemeCodeUnspsc190501.50614101': __('identifierSchemeCodeUnspsc190501.50614101'),
	'identifierSchemeCodeUnspsc190501.50614102': __('identifierSchemeCodeUnspsc190501.50614102'),
	'identifierSchemeCodeUnspsc190501.50614103': __('identifierSchemeCodeUnspsc190501.50614103'),
	'identifierSchemeCodeUnspsc190501.50614104': __('identifierSchemeCodeUnspsc190501.50614104'),
	'identifierSchemeCodeUnspsc190501.50614105': __('identifierSchemeCodeUnspsc190501.50614105'),
	'identifierSchemeCodeUnspsc190501.50614106': __('identifierSchemeCodeUnspsc190501.50614106'),
	'identifierSchemeCodeUnspsc190501.50614107': __('identifierSchemeCodeUnspsc190501.50614107'),
	'identifierSchemeCodeUnspsc190501.50614108': __('identifierSchemeCodeUnspsc190501.50614108'),
	'identifierSchemeCodeUnspsc190501.50614109': __('identifierSchemeCodeUnspsc190501.50614109'),
	'identifierSchemeCodeUnspsc190501.50614110': __('identifierSchemeCodeUnspsc190501.50614110'),
	'identifierSchemeCodeUnspsc190501.50614111': __('identifierSchemeCodeUnspsc190501.50614111'),
	'identifierSchemeCodeUnspsc190501.50614112': __('identifierSchemeCodeUnspsc190501.50614112'),
	'identifierSchemeCodeUnspsc190501.50614113': __('identifierSchemeCodeUnspsc190501.50614113'),
	'identifierSchemeCodeUnspsc190501.50614114': __('identifierSchemeCodeUnspsc190501.50614114'),
	'identifierSchemeCodeUnspsc190501.50614115': __('identifierSchemeCodeUnspsc190501.50614115'),
	'identifierSchemeCodeUnspsc190501.50614116': __('identifierSchemeCodeUnspsc190501.50614116'),
	'identifierSchemeCodeUnspsc190501.50614117': __('identifierSchemeCodeUnspsc190501.50614117'),
	'identifierSchemeCodeUnspsc190501.50614118': __('identifierSchemeCodeUnspsc190501.50614118'),
	'identifierSchemeCodeUnspsc190501.50614119': __('identifierSchemeCodeUnspsc190501.50614119'),
	'identifierSchemeCodeUnspsc190501.50614120': __('identifierSchemeCodeUnspsc190501.50614120'),
	'identifierSchemeCodeUnspsc190501.50614121': __('identifierSchemeCodeUnspsc190501.50614121'),
	'identifierSchemeCodeUnspsc190501.50614122': __('identifierSchemeCodeUnspsc190501.50614122'),
	'identifierSchemeCodeUnspsc190501.50614123': __('identifierSchemeCodeUnspsc190501.50614123'),
	'identifierSchemeCodeUnspsc190501.50614124': __('identifierSchemeCodeUnspsc190501.50614124'),
	'identifierSchemeCodeUnspsc190501.50614125': __('identifierSchemeCodeUnspsc190501.50614125'),
	'identifierSchemeCodeUnspsc190501.50614126': __('identifierSchemeCodeUnspsc190501.50614126'),
	'identifierSchemeCodeUnspsc190501.50614127': __('identifierSchemeCodeUnspsc190501.50614127'),
	'identifierSchemeCodeUnspsc190501.50614200': __('identifierSchemeCodeUnspsc190501.50614200'),
	'identifierSchemeCodeUnspsc190501.50614201': __('identifierSchemeCodeUnspsc190501.50614201'),
	'identifierSchemeCodeUnspsc190501.50614202': __('identifierSchemeCodeUnspsc190501.50614202'),
	'identifierSchemeCodeUnspsc190501.50614300': __('identifierSchemeCodeUnspsc190501.50614300'),
	'identifierSchemeCodeUnspsc190501.50614301': __('identifierSchemeCodeUnspsc190501.50614301'),
	'identifierSchemeCodeUnspsc190501.50614302': __('identifierSchemeCodeUnspsc190501.50614302'),
	'identifierSchemeCodeUnspsc190501.50614303': __('identifierSchemeCodeUnspsc190501.50614303'),
	'identifierSchemeCodeUnspsc190501.50614304': __('identifierSchemeCodeUnspsc190501.50614304'),
	'identifierSchemeCodeUnspsc190501.50614305': __('identifierSchemeCodeUnspsc190501.50614305'),
	'identifierSchemeCodeUnspsc190501.50614306': __('identifierSchemeCodeUnspsc190501.50614306'),
	'identifierSchemeCodeUnspsc190501.50614400': __('identifierSchemeCodeUnspsc190501.50614400'),
	'identifierSchemeCodeUnspsc190501.50614401': __('identifierSchemeCodeUnspsc190501.50614401'),
	'identifierSchemeCodeUnspsc190501.50614402': __('identifierSchemeCodeUnspsc190501.50614402'),
	'identifierSchemeCodeUnspsc190501.50614403': __('identifierSchemeCodeUnspsc190501.50614403'),
	'identifierSchemeCodeUnspsc190501.50614404': __('identifierSchemeCodeUnspsc190501.50614404'),
	'identifierSchemeCodeUnspsc190501.50614405': __('identifierSchemeCodeUnspsc190501.50614405'),
	'identifierSchemeCodeUnspsc190501.50614406': __('identifierSchemeCodeUnspsc190501.50614406'),
	'identifierSchemeCodeUnspsc190501.50614500': __('identifierSchemeCodeUnspsc190501.50614500'),
	'identifierSchemeCodeUnspsc190501.50614501': __('identifierSchemeCodeUnspsc190501.50614501'),
	'identifierSchemeCodeUnspsc190501.50614502': __('identifierSchemeCodeUnspsc190501.50614502'),
	'identifierSchemeCodeUnspsc190501.50614503': __('identifierSchemeCodeUnspsc190501.50614503'),
	'identifierSchemeCodeUnspsc190501.50614504': __('identifierSchemeCodeUnspsc190501.50614504'),
	'identifierSchemeCodeUnspsc190501.50614505': __('identifierSchemeCodeUnspsc190501.50614505'),
	'identifierSchemeCodeUnspsc190501.50614506': __('identifierSchemeCodeUnspsc190501.50614506'),
	'identifierSchemeCodeUnspsc190501.50614507': __('identifierSchemeCodeUnspsc190501.50614507'),
	'identifierSchemeCodeUnspsc190501.50614508': __('identifierSchemeCodeUnspsc190501.50614508'),
	'identifierSchemeCodeUnspsc190501.50614600': __('identifierSchemeCodeUnspsc190501.50614600'),
	'identifierSchemeCodeUnspsc190501.50614601': __('identifierSchemeCodeUnspsc190501.50614601'),
	'identifierSchemeCodeUnspsc190501.50614602': __('identifierSchemeCodeUnspsc190501.50614602'),
	'identifierSchemeCodeUnspsc190501.50614603': __('identifierSchemeCodeUnspsc190501.50614603'),
	'identifierSchemeCodeUnspsc190501.50614604': __('identifierSchemeCodeUnspsc190501.50614604'),
	'identifierSchemeCodeUnspsc190501.50614605': __('identifierSchemeCodeUnspsc190501.50614605'),
	'identifierSchemeCodeUnspsc190501.50614606': __('identifierSchemeCodeUnspsc190501.50614606'),
	'identifierSchemeCodeUnspsc190501.50614607': __('identifierSchemeCodeUnspsc190501.50614607'),
	'identifierSchemeCodeUnspsc190501.50614608': __('identifierSchemeCodeUnspsc190501.50614608'),
	'identifierSchemeCodeUnspsc190501.50614609': __('identifierSchemeCodeUnspsc190501.50614609'),
	'identifierSchemeCodeUnspsc190501.50614610': __('identifierSchemeCodeUnspsc190501.50614610'),
	'identifierSchemeCodeUnspsc190501.50614611': __('identifierSchemeCodeUnspsc190501.50614611'),
	'identifierSchemeCodeUnspsc190501.50614612': __('identifierSchemeCodeUnspsc190501.50614612'),
	'identifierSchemeCodeUnspsc190501.50614613': __('identifierSchemeCodeUnspsc190501.50614613'),
	'identifierSchemeCodeUnspsc190501.50614614': __('identifierSchemeCodeUnspsc190501.50614614'),
	'identifierSchemeCodeUnspsc190501.50614615': __('identifierSchemeCodeUnspsc190501.50614615'),
	'identifierSchemeCodeUnspsc190501.50614616': __('identifierSchemeCodeUnspsc190501.50614616'),
	'identifierSchemeCodeUnspsc190501.50614617': __('identifierSchemeCodeUnspsc190501.50614617'),
	'identifierSchemeCodeUnspsc190501.50614618': __('identifierSchemeCodeUnspsc190501.50614618'),
	'identifierSchemeCodeUnspsc190501.50614619': __('identifierSchemeCodeUnspsc190501.50614619'),
	'identifierSchemeCodeUnspsc190501.50614620': __('identifierSchemeCodeUnspsc190501.50614620'),
	'identifierSchemeCodeUnspsc190501.50614621': __('identifierSchemeCodeUnspsc190501.50614621'),
	'identifierSchemeCodeUnspsc190501.50614622': __('identifierSchemeCodeUnspsc190501.50614622'),
	'identifierSchemeCodeUnspsc190501.50614623': __('identifierSchemeCodeUnspsc190501.50614623'),
	'identifierSchemeCodeUnspsc190501.50614700': __('identifierSchemeCodeUnspsc190501.50614700'),
	'identifierSchemeCodeUnspsc190501.50614701': __('identifierSchemeCodeUnspsc190501.50614701'),
	'identifierSchemeCodeUnspsc190501.50614702': __('identifierSchemeCodeUnspsc190501.50614702'),
	'identifierSchemeCodeUnspsc190501.50614703': __('identifierSchemeCodeUnspsc190501.50614703'),
	'identifierSchemeCodeUnspsc190501.50614704': __('identifierSchemeCodeUnspsc190501.50614704'),
	'identifierSchemeCodeUnspsc190501.50614705': __('identifierSchemeCodeUnspsc190501.50614705'),
	'identifierSchemeCodeUnspsc190501.50614706': __('identifierSchemeCodeUnspsc190501.50614706'),
	'identifierSchemeCodeUnspsc190501.50614800': __('identifierSchemeCodeUnspsc190501.50614800'),
	'identifierSchemeCodeUnspsc190501.50614801': __('identifierSchemeCodeUnspsc190501.50614801'),
	'identifierSchemeCodeUnspsc190501.50614802': __('identifierSchemeCodeUnspsc190501.50614802'),
	'identifierSchemeCodeUnspsc190501.50614803': __('identifierSchemeCodeUnspsc190501.50614803'),
	'identifierSchemeCodeUnspsc190501.50614804': __('identifierSchemeCodeUnspsc190501.50614804'),
	'identifierSchemeCodeUnspsc190501.50614805': __('identifierSchemeCodeUnspsc190501.50614805'),
	'identifierSchemeCodeUnspsc190501.50614806': __('identifierSchemeCodeUnspsc190501.50614806'),
	'identifierSchemeCodeUnspsc190501.50614807': __('identifierSchemeCodeUnspsc190501.50614807'),
	'identifierSchemeCodeUnspsc190501.50614808': __('identifierSchemeCodeUnspsc190501.50614808'),
	'identifierSchemeCodeUnspsc190501.50614809': __('identifierSchemeCodeUnspsc190501.50614809'),
	'identifierSchemeCodeUnspsc190501.50614810': __('identifierSchemeCodeUnspsc190501.50614810'),
	'identifierSchemeCodeUnspsc190501.50614811': __('identifierSchemeCodeUnspsc190501.50614811'),
	'identifierSchemeCodeUnspsc190501.50614812': __('identifierSchemeCodeUnspsc190501.50614812'),
	'identifierSchemeCodeUnspsc190501.50614813': __('identifierSchemeCodeUnspsc190501.50614813'),
	'identifierSchemeCodeUnspsc190501.50614814': __('identifierSchemeCodeUnspsc190501.50614814'),
	'identifierSchemeCodeUnspsc190501.50614815': __('identifierSchemeCodeUnspsc190501.50614815'),
	'identifierSchemeCodeUnspsc190501.50614816': __('identifierSchemeCodeUnspsc190501.50614816'),
	'identifierSchemeCodeUnspsc190501.50614817': __('identifierSchemeCodeUnspsc190501.50614817'),
	'identifierSchemeCodeUnspsc190501.50614818': __('identifierSchemeCodeUnspsc190501.50614818'),
	'identifierSchemeCodeUnspsc190501.50614819': __('identifierSchemeCodeUnspsc190501.50614819'),
	'identifierSchemeCodeUnspsc190501.50614820': __('identifierSchemeCodeUnspsc190501.50614820'),
	'identifierSchemeCodeUnspsc190501.50614821': __('identifierSchemeCodeUnspsc190501.50614821'),
	'identifierSchemeCodeUnspsc190501.50614900': __('identifierSchemeCodeUnspsc190501.50614900'),
	'identifierSchemeCodeUnspsc190501.50614901': __('identifierSchemeCodeUnspsc190501.50614901'),
	'identifierSchemeCodeUnspsc190501.50614902': __('identifierSchemeCodeUnspsc190501.50614902'),
	'identifierSchemeCodeUnspsc190501.50614903': __('identifierSchemeCodeUnspsc190501.50614903'),
	'identifierSchemeCodeUnspsc190501.50614904': __('identifierSchemeCodeUnspsc190501.50614904'),
	'identifierSchemeCodeUnspsc190501.50614905': __('identifierSchemeCodeUnspsc190501.50614905'),
	'identifierSchemeCodeUnspsc190501.50614906': __('identifierSchemeCodeUnspsc190501.50614906'),
	'identifierSchemeCodeUnspsc190501.50615000': __('identifierSchemeCodeUnspsc190501.50615000'),
	'identifierSchemeCodeUnspsc190501.50615001': __('identifierSchemeCodeUnspsc190501.50615001'),
	'identifierSchemeCodeUnspsc190501.50615002': __('identifierSchemeCodeUnspsc190501.50615002'),
	'identifierSchemeCodeUnspsc190501.50615003': __('identifierSchemeCodeUnspsc190501.50615003'),
	'identifierSchemeCodeUnspsc190501.50615004': __('identifierSchemeCodeUnspsc190501.50615004'),
	'identifierSchemeCodeUnspsc190501.50615100': __('identifierSchemeCodeUnspsc190501.50615100'),
	'identifierSchemeCodeUnspsc190501.50615101': __('identifierSchemeCodeUnspsc190501.50615101'),
	'identifierSchemeCodeUnspsc190501.50615102': __('identifierSchemeCodeUnspsc190501.50615102'),
	'identifierSchemeCodeUnspsc190501.50615103': __('identifierSchemeCodeUnspsc190501.50615103'),
	'identifierSchemeCodeUnspsc190501.50615104': __('identifierSchemeCodeUnspsc190501.50615104'),
	'identifierSchemeCodeUnspsc190501.50615105': __('identifierSchemeCodeUnspsc190501.50615105'),
	'identifierSchemeCodeUnspsc190501.50615106': __('identifierSchemeCodeUnspsc190501.50615106'),
	'identifierSchemeCodeUnspsc190501.50615107': __('identifierSchemeCodeUnspsc190501.50615107'),
	'identifierSchemeCodeUnspsc190501.50615200': __('identifierSchemeCodeUnspsc190501.50615200'),
	'identifierSchemeCodeUnspsc190501.50615201': __('identifierSchemeCodeUnspsc190501.50615201'),
	'identifierSchemeCodeUnspsc190501.50615202': __('identifierSchemeCodeUnspsc190501.50615202'),
	'identifierSchemeCodeUnspsc190501.50615203': __('identifierSchemeCodeUnspsc190501.50615203'),
	'identifierSchemeCodeUnspsc190501.50615204': __('identifierSchemeCodeUnspsc190501.50615204'),
	'identifierSchemeCodeUnspsc190501.50615205': __('identifierSchemeCodeUnspsc190501.50615205'),
	'identifierSchemeCodeUnspsc190501.50615206': __('identifierSchemeCodeUnspsc190501.50615206'),
	'identifierSchemeCodeUnspsc190501.50615207': __('identifierSchemeCodeUnspsc190501.50615207'),
	'identifierSchemeCodeUnspsc190501.50615208': __('identifierSchemeCodeUnspsc190501.50615208'),
	'identifierSchemeCodeUnspsc190501.50615209': __('identifierSchemeCodeUnspsc190501.50615209'),
	'identifierSchemeCodeUnspsc190501.50615210': __('identifierSchemeCodeUnspsc190501.50615210'),
	'identifierSchemeCodeUnspsc190501.50615211': __('identifierSchemeCodeUnspsc190501.50615211'),
	'identifierSchemeCodeUnspsc190501.50615212': __('identifierSchemeCodeUnspsc190501.50615212'),
	'identifierSchemeCodeUnspsc190501.50615213': __('identifierSchemeCodeUnspsc190501.50615213'),
	'identifierSchemeCodeUnspsc190501.50615214': __('identifierSchemeCodeUnspsc190501.50615214'),
	'identifierSchemeCodeUnspsc190501.50615215': __('identifierSchemeCodeUnspsc190501.50615215'),
	'identifierSchemeCodeUnspsc190501.50615216': __('identifierSchemeCodeUnspsc190501.50615216'),
	'identifierSchemeCodeUnspsc190501.50615217': __('identifierSchemeCodeUnspsc190501.50615217'),
	'identifierSchemeCodeUnspsc190501.50615218': __('identifierSchemeCodeUnspsc190501.50615218'),
	'identifierSchemeCodeUnspsc190501.50615219': __('identifierSchemeCodeUnspsc190501.50615219'),
	'identifierSchemeCodeUnspsc190501.50615220': __('identifierSchemeCodeUnspsc190501.50615220'),
	'identifierSchemeCodeUnspsc190501.50615221': __('identifierSchemeCodeUnspsc190501.50615221'),
	'identifierSchemeCodeUnspsc190501.50615300': __('identifierSchemeCodeUnspsc190501.50615300'),
	'identifierSchemeCodeUnspsc190501.50615301': __('identifierSchemeCodeUnspsc190501.50615301'),
	'identifierSchemeCodeUnspsc190501.50615302': __('identifierSchemeCodeUnspsc190501.50615302'),
	'identifierSchemeCodeUnspsc190501.50615303': __('identifierSchemeCodeUnspsc190501.50615303'),
	'identifierSchemeCodeUnspsc190501.50615304': __('identifierSchemeCodeUnspsc190501.50615304'),
	'identifierSchemeCodeUnspsc190501.50615305': __('identifierSchemeCodeUnspsc190501.50615305'),
	'identifierSchemeCodeUnspsc190501.50615306': __('identifierSchemeCodeUnspsc190501.50615306'),
	'identifierSchemeCodeUnspsc190501.50615400': __('identifierSchemeCodeUnspsc190501.50615400'),
	'identifierSchemeCodeUnspsc190501.50615401': __('identifierSchemeCodeUnspsc190501.50615401'),
	'identifierSchemeCodeUnspsc190501.50615402': __('identifierSchemeCodeUnspsc190501.50615402'),
	'identifierSchemeCodeUnspsc190501.50615403': __('identifierSchemeCodeUnspsc190501.50615403'),
	'identifierSchemeCodeUnspsc190501.50615404': __('identifierSchemeCodeUnspsc190501.50615404'),
	'identifierSchemeCodeUnspsc190501.50615405': __('identifierSchemeCodeUnspsc190501.50615405'),
	'identifierSchemeCodeUnspsc190501.50615406': __('identifierSchemeCodeUnspsc190501.50615406'),
	'identifierSchemeCodeUnspsc190501.50615500': __('identifierSchemeCodeUnspsc190501.50615500'),
	'identifierSchemeCodeUnspsc190501.50615501': __('identifierSchemeCodeUnspsc190501.50615501'),
	'identifierSchemeCodeUnspsc190501.50615502': __('identifierSchemeCodeUnspsc190501.50615502'),
	'identifierSchemeCodeUnspsc190501.50615503': __('identifierSchemeCodeUnspsc190501.50615503'),
	'identifierSchemeCodeUnspsc190501.50615504': __('identifierSchemeCodeUnspsc190501.50615504'),
	'identifierSchemeCodeUnspsc190501.50615505': __('identifierSchemeCodeUnspsc190501.50615505'),
	'identifierSchemeCodeUnspsc190501.50615506': __('identifierSchemeCodeUnspsc190501.50615506'),
	'identifierSchemeCodeUnspsc190501.50615507': __('identifierSchemeCodeUnspsc190501.50615507'),
	'identifierSchemeCodeUnspsc190501.50615508': __('identifierSchemeCodeUnspsc190501.50615508'),
	'identifierSchemeCodeUnspsc190501.50615509': __('identifierSchemeCodeUnspsc190501.50615509'),
	'identifierSchemeCodeUnspsc190501.50615510': __('identifierSchemeCodeUnspsc190501.50615510'),
	'identifierSchemeCodeUnspsc190501.50615511': __('identifierSchemeCodeUnspsc190501.50615511'),
	'identifierSchemeCodeUnspsc190501.50615512': __('identifierSchemeCodeUnspsc190501.50615512'),
	'identifierSchemeCodeUnspsc190501.50615513': __('identifierSchemeCodeUnspsc190501.50615513'),
	'identifierSchemeCodeUnspsc190501.50615514': __('identifierSchemeCodeUnspsc190501.50615514'),
	'identifierSchemeCodeUnspsc190501.50615515': __('identifierSchemeCodeUnspsc190501.50615515'),
	'identifierSchemeCodeUnspsc190501.50615516': __('identifierSchemeCodeUnspsc190501.50615516'),
	'identifierSchemeCodeUnspsc190501.50615517': __('identifierSchemeCodeUnspsc190501.50615517'),
	'identifierSchemeCodeUnspsc190501.50615518': __('identifierSchemeCodeUnspsc190501.50615518'),
	'identifierSchemeCodeUnspsc190501.50615519': __('identifierSchemeCodeUnspsc190501.50615519'),
	'identifierSchemeCodeUnspsc190501.50615520': __('identifierSchemeCodeUnspsc190501.50615520'),
	'identifierSchemeCodeUnspsc190501.50615521': __('identifierSchemeCodeUnspsc190501.50615521'),
	'identifierSchemeCodeUnspsc190501.50615522': __('identifierSchemeCodeUnspsc190501.50615522'),
	'identifierSchemeCodeUnspsc190501.50615523': __('identifierSchemeCodeUnspsc190501.50615523'),
	'identifierSchemeCodeUnspsc190501.50615524': __('identifierSchemeCodeUnspsc190501.50615524'),
	'identifierSchemeCodeUnspsc190501.50615525': __('identifierSchemeCodeUnspsc190501.50615525'),
	'identifierSchemeCodeUnspsc190501.50615526': __('identifierSchemeCodeUnspsc190501.50615526'),
	'identifierSchemeCodeUnspsc190501.50615527': __('identifierSchemeCodeUnspsc190501.50615527'),
	'identifierSchemeCodeUnspsc190501.50615528': __('identifierSchemeCodeUnspsc190501.50615528'),
	'identifierSchemeCodeUnspsc190501.50615529': __('identifierSchemeCodeUnspsc190501.50615529'),
	'identifierSchemeCodeUnspsc190501.50615530': __('identifierSchemeCodeUnspsc190501.50615530'),
	'identifierSchemeCodeUnspsc190501.50615531': __('identifierSchemeCodeUnspsc190501.50615531'),
	'identifierSchemeCodeUnspsc190501.50615532': __('identifierSchemeCodeUnspsc190501.50615532'),
	'identifierSchemeCodeUnspsc190501.50615533': __('identifierSchemeCodeUnspsc190501.50615533'),
	'identifierSchemeCodeUnspsc190501.50615534': __('identifierSchemeCodeUnspsc190501.50615534'),
	'identifierSchemeCodeUnspsc190501.50615535': __('identifierSchemeCodeUnspsc190501.50615535'),
	'identifierSchemeCodeUnspsc190501.50615536': __('identifierSchemeCodeUnspsc190501.50615536'),
	'identifierSchemeCodeUnspsc190501.50615537': __('identifierSchemeCodeUnspsc190501.50615537'),
	'identifierSchemeCodeUnspsc190501.50615600': __('identifierSchemeCodeUnspsc190501.50615600'),
	'identifierSchemeCodeUnspsc190501.50615601': __('identifierSchemeCodeUnspsc190501.50615601'),
	'identifierSchemeCodeUnspsc190501.50615602': __('identifierSchemeCodeUnspsc190501.50615602'),
	'identifierSchemeCodeUnspsc190501.50615603': __('identifierSchemeCodeUnspsc190501.50615603'),
	'identifierSchemeCodeUnspsc190501.50615604': __('identifierSchemeCodeUnspsc190501.50615604'),
	'identifierSchemeCodeUnspsc190501.50615605': __('identifierSchemeCodeUnspsc190501.50615605'),
	'identifierSchemeCodeUnspsc190501.50615606': __('identifierSchemeCodeUnspsc190501.50615606'),
	'identifierSchemeCodeUnspsc190501.50615607': __('identifierSchemeCodeUnspsc190501.50615607'),
	'identifierSchemeCodeUnspsc190501.50615608': __('identifierSchemeCodeUnspsc190501.50615608'),
	'identifierSchemeCodeUnspsc190501.50615700': __('identifierSchemeCodeUnspsc190501.50615700'),
	'identifierSchemeCodeUnspsc190501.50615701': __('identifierSchemeCodeUnspsc190501.50615701'),
	'identifierSchemeCodeUnspsc190501.50615702': __('identifierSchemeCodeUnspsc190501.50615702'),
	'identifierSchemeCodeUnspsc190501.50615703': __('identifierSchemeCodeUnspsc190501.50615703'),
	'identifierSchemeCodeUnspsc190501.50615704': __('identifierSchemeCodeUnspsc190501.50615704'),
	'identifierSchemeCodeUnspsc190501.50615800': __('identifierSchemeCodeUnspsc190501.50615800'),
	'identifierSchemeCodeUnspsc190501.50615801': __('identifierSchemeCodeUnspsc190501.50615801'),
	'identifierSchemeCodeUnspsc190501.50615802': __('identifierSchemeCodeUnspsc190501.50615802'),
	'identifierSchemeCodeUnspsc190501.50615803': __('identifierSchemeCodeUnspsc190501.50615803'),
	'identifierSchemeCodeUnspsc190501.50615804': __('identifierSchemeCodeUnspsc190501.50615804'),
	'identifierSchemeCodeUnspsc190501.50615805': __('identifierSchemeCodeUnspsc190501.50615805'),
	'identifierSchemeCodeUnspsc190501.50615806': __('identifierSchemeCodeUnspsc190501.50615806'),
	'identifierSchemeCodeUnspsc190501.50615807': __('identifierSchemeCodeUnspsc190501.50615807'),
	'identifierSchemeCodeUnspsc190501.50615808': __('identifierSchemeCodeUnspsc190501.50615808'),
	'identifierSchemeCodeUnspsc190501.50615809': __('identifierSchemeCodeUnspsc190501.50615809'),
	'identifierSchemeCodeUnspsc190501.50615810': __('identifierSchemeCodeUnspsc190501.50615810'),
	'identifierSchemeCodeUnspsc190501.50615811': __('identifierSchemeCodeUnspsc190501.50615811'),
	'identifierSchemeCodeUnspsc190501.50615812': __('identifierSchemeCodeUnspsc190501.50615812'),
	'identifierSchemeCodeUnspsc190501.50615813': __('identifierSchemeCodeUnspsc190501.50615813'),
	'identifierSchemeCodeUnspsc190501.50615814': __('identifierSchemeCodeUnspsc190501.50615814'),
	'identifierSchemeCodeUnspsc190501.50615815': __('identifierSchemeCodeUnspsc190501.50615815'),
	'identifierSchemeCodeUnspsc190501.50615816': __('identifierSchemeCodeUnspsc190501.50615816'),
	'identifierSchemeCodeUnspsc190501.50615900': __('identifierSchemeCodeUnspsc190501.50615900'),
	'identifierSchemeCodeUnspsc190501.50615901': __('identifierSchemeCodeUnspsc190501.50615901'),
	'identifierSchemeCodeUnspsc190501.50615902': __('identifierSchemeCodeUnspsc190501.50615902'),
	'identifierSchemeCodeUnspsc190501.50615903': __('identifierSchemeCodeUnspsc190501.50615903'),
	'identifierSchemeCodeUnspsc190501.50615904': __('identifierSchemeCodeUnspsc190501.50615904'),
	'identifierSchemeCodeUnspsc190501.50615905': __('identifierSchemeCodeUnspsc190501.50615905'),
	'identifierSchemeCodeUnspsc190501.50615906': __('identifierSchemeCodeUnspsc190501.50615906'),
	'identifierSchemeCodeUnspsc190501.50615907': __('identifierSchemeCodeUnspsc190501.50615907'),
	'identifierSchemeCodeUnspsc190501.50615908': __('identifierSchemeCodeUnspsc190501.50615908'),
	'identifierSchemeCodeUnspsc190501.50615909': __('identifierSchemeCodeUnspsc190501.50615909'),
	'identifierSchemeCodeUnspsc190501.50615910': __('identifierSchemeCodeUnspsc190501.50615910'),
	'identifierSchemeCodeUnspsc190501.50615911': __('identifierSchemeCodeUnspsc190501.50615911'),
	'identifierSchemeCodeUnspsc190501.50616000': __('identifierSchemeCodeUnspsc190501.50616000'),
	'identifierSchemeCodeUnspsc190501.50616001': __('identifierSchemeCodeUnspsc190501.50616001'),
	'identifierSchemeCodeUnspsc190501.50616002': __('identifierSchemeCodeUnspsc190501.50616002'),
	'identifierSchemeCodeUnspsc190501.50616003': __('identifierSchemeCodeUnspsc190501.50616003'),
	'identifierSchemeCodeUnspsc190501.50616004': __('identifierSchemeCodeUnspsc190501.50616004'),
	'identifierSchemeCodeUnspsc190501.50616100': __('identifierSchemeCodeUnspsc190501.50616100'),
	'identifierSchemeCodeUnspsc190501.50616101': __('identifierSchemeCodeUnspsc190501.50616101'),
	'identifierSchemeCodeUnspsc190501.50616102': __('identifierSchemeCodeUnspsc190501.50616102'),
	'identifierSchemeCodeUnspsc190501.50616103': __('identifierSchemeCodeUnspsc190501.50616103'),
	'identifierSchemeCodeUnspsc190501.50616104': __('identifierSchemeCodeUnspsc190501.50616104'),
	'identifierSchemeCodeUnspsc190501.50616105': __('identifierSchemeCodeUnspsc190501.50616105'),
	'identifierSchemeCodeUnspsc190501.50616106': __('identifierSchemeCodeUnspsc190501.50616106'),
	'identifierSchemeCodeUnspsc190501.50616107': __('identifierSchemeCodeUnspsc190501.50616107'),
	'identifierSchemeCodeUnspsc190501.50616108': __('identifierSchemeCodeUnspsc190501.50616108'),
	'identifierSchemeCodeUnspsc190501.50616109': __('identifierSchemeCodeUnspsc190501.50616109'),
	'identifierSchemeCodeUnspsc190501.50616110': __('identifierSchemeCodeUnspsc190501.50616110'),
	'identifierSchemeCodeUnspsc190501.50616111': __('identifierSchemeCodeUnspsc190501.50616111'),
	'identifierSchemeCodeUnspsc190501.50616112': __('identifierSchemeCodeUnspsc190501.50616112'),
	'identifierSchemeCodeUnspsc190501.50616113': __('identifierSchemeCodeUnspsc190501.50616113'),
	'identifierSchemeCodeUnspsc190501.50616114': __('identifierSchemeCodeUnspsc190501.50616114'),
	'identifierSchemeCodeUnspsc190501.50616115': __('identifierSchemeCodeUnspsc190501.50616115'),
	'identifierSchemeCodeUnspsc190501.50616116': __('identifierSchemeCodeUnspsc190501.50616116'),
	'identifierSchemeCodeUnspsc190501.50616200': __('identifierSchemeCodeUnspsc190501.50616200'),
	'identifierSchemeCodeUnspsc190501.50616201': __('identifierSchemeCodeUnspsc190501.50616201'),
	'identifierSchemeCodeUnspsc190501.50616202': __('identifierSchemeCodeUnspsc190501.50616202'),
	'identifierSchemeCodeUnspsc190501.50616203': __('identifierSchemeCodeUnspsc190501.50616203'),
	'identifierSchemeCodeUnspsc190501.50616204': __('identifierSchemeCodeUnspsc190501.50616204'),
	'identifierSchemeCodeUnspsc190501.50616205': __('identifierSchemeCodeUnspsc190501.50616205'),
	'identifierSchemeCodeUnspsc190501.50616206': __('identifierSchemeCodeUnspsc190501.50616206'),
	'identifierSchemeCodeUnspsc190501.50616207': __('identifierSchemeCodeUnspsc190501.50616207'),
	'identifierSchemeCodeUnspsc190501.50616208': __('identifierSchemeCodeUnspsc190501.50616208'),
	'identifierSchemeCodeUnspsc190501.50616209': __('identifierSchemeCodeUnspsc190501.50616209'),
	'identifierSchemeCodeUnspsc190501.50616210': __('identifierSchemeCodeUnspsc190501.50616210'),
	'identifierSchemeCodeUnspsc190501.50616211': __('identifierSchemeCodeUnspsc190501.50616211'),
	'identifierSchemeCodeUnspsc190501.50616212': __('identifierSchemeCodeUnspsc190501.50616212'),
	'identifierSchemeCodeUnspsc190501.50616213': __('identifierSchemeCodeUnspsc190501.50616213'),
	'identifierSchemeCodeUnspsc190501.50616214': __('identifierSchemeCodeUnspsc190501.50616214'),
	'identifierSchemeCodeUnspsc190501.50616215': __('identifierSchemeCodeUnspsc190501.50616215'),
	'identifierSchemeCodeUnspsc190501.50616216': __('identifierSchemeCodeUnspsc190501.50616216'),
	'identifierSchemeCodeUnspsc190501.50616217': __('identifierSchemeCodeUnspsc190501.50616217'),
	'identifierSchemeCodeUnspsc190501.50616218': __('identifierSchemeCodeUnspsc190501.50616218'),
	'identifierSchemeCodeUnspsc190501.50616219': __('identifierSchemeCodeUnspsc190501.50616219'),
	'identifierSchemeCodeUnspsc190501.50616220': __('identifierSchemeCodeUnspsc190501.50616220'),
	'identifierSchemeCodeUnspsc190501.50616221': __('identifierSchemeCodeUnspsc190501.50616221'),
	'identifierSchemeCodeUnspsc190501.50616222': __('identifierSchemeCodeUnspsc190501.50616222'),
	'identifierSchemeCodeUnspsc190501.50616223': __('identifierSchemeCodeUnspsc190501.50616223'),
	'identifierSchemeCodeUnspsc190501.50616224': __('identifierSchemeCodeUnspsc190501.50616224'),
	'identifierSchemeCodeUnspsc190501.50616225': __('identifierSchemeCodeUnspsc190501.50616225'),
	'identifierSchemeCodeUnspsc190501.50616226': __('identifierSchemeCodeUnspsc190501.50616226'),
	'identifierSchemeCodeUnspsc190501.50616227': __('identifierSchemeCodeUnspsc190501.50616227'),
	'identifierSchemeCodeUnspsc190501.50616228': __('identifierSchemeCodeUnspsc190501.50616228'),
	'identifierSchemeCodeUnspsc190501.50616229': __('identifierSchemeCodeUnspsc190501.50616229'),
	'identifierSchemeCodeUnspsc190501.50616300': __('identifierSchemeCodeUnspsc190501.50616300'),
	'identifierSchemeCodeUnspsc190501.50616301': __('identifierSchemeCodeUnspsc190501.50616301'),
	'identifierSchemeCodeUnspsc190501.50616302': __('identifierSchemeCodeUnspsc190501.50616302'),
	'identifierSchemeCodeUnspsc190501.50616303': __('identifierSchemeCodeUnspsc190501.50616303'),
	'identifierSchemeCodeUnspsc190501.50616304': __('identifierSchemeCodeUnspsc190501.50616304'),
	'identifierSchemeCodeUnspsc190501.50616305': __('identifierSchemeCodeUnspsc190501.50616305'),
	'identifierSchemeCodeUnspsc190501.50616306': __('identifierSchemeCodeUnspsc190501.50616306'),
	'identifierSchemeCodeUnspsc190501.50616307': __('identifierSchemeCodeUnspsc190501.50616307'),
	'identifierSchemeCodeUnspsc190501.50616308': __('identifierSchemeCodeUnspsc190501.50616308'),
	'identifierSchemeCodeUnspsc190501.50616309': __('identifierSchemeCodeUnspsc190501.50616309'),
	'identifierSchemeCodeUnspsc190501.50616310': __('identifierSchemeCodeUnspsc190501.50616310'),
	'identifierSchemeCodeUnspsc190501.50616311': __('identifierSchemeCodeUnspsc190501.50616311'),
	'identifierSchemeCodeUnspsc190501.50616312': __('identifierSchemeCodeUnspsc190501.50616312'),
	'identifierSchemeCodeUnspsc190501.50616313': __('identifierSchemeCodeUnspsc190501.50616313'),
	'identifierSchemeCodeUnspsc190501.50616314': __('identifierSchemeCodeUnspsc190501.50616314'),
	'identifierSchemeCodeUnspsc190501.50616315': __('identifierSchemeCodeUnspsc190501.50616315'),
	'identifierSchemeCodeUnspsc190501.50616316': __('identifierSchemeCodeUnspsc190501.50616316'),
	'identifierSchemeCodeUnspsc190501.50616317': __('identifierSchemeCodeUnspsc190501.50616317'),
	'identifierSchemeCodeUnspsc190501.50616318': __('identifierSchemeCodeUnspsc190501.50616318'),
	'identifierSchemeCodeUnspsc190501.50616319': __('identifierSchemeCodeUnspsc190501.50616319'),
	'identifierSchemeCodeUnspsc190501.50616320': __('identifierSchemeCodeUnspsc190501.50616320'),
	'identifierSchemeCodeUnspsc190501.50616400': __('identifierSchemeCodeUnspsc190501.50616400'),
	'identifierSchemeCodeUnspsc190501.50616401': __('identifierSchemeCodeUnspsc190501.50616401'),
	'identifierSchemeCodeUnspsc190501.50616402': __('identifierSchemeCodeUnspsc190501.50616402'),
	'identifierSchemeCodeUnspsc190501.50616403': __('identifierSchemeCodeUnspsc190501.50616403'),
	'identifierSchemeCodeUnspsc190501.50616404': __('identifierSchemeCodeUnspsc190501.50616404'),
	'identifierSchemeCodeUnspsc190501.50616405': __('identifierSchemeCodeUnspsc190501.50616405'),
	'identifierSchemeCodeUnspsc190501.50616406': __('identifierSchemeCodeUnspsc190501.50616406'),
	'identifierSchemeCodeUnspsc190501.50616407': __('identifierSchemeCodeUnspsc190501.50616407'),
	'identifierSchemeCodeUnspsc190501.50616408': __('identifierSchemeCodeUnspsc190501.50616408'),
	'identifierSchemeCodeUnspsc190501.50616409': __('identifierSchemeCodeUnspsc190501.50616409'),
	'identifierSchemeCodeUnspsc190501.50616410': __('identifierSchemeCodeUnspsc190501.50616410'),
	'identifierSchemeCodeUnspsc190501.50616411': __('identifierSchemeCodeUnspsc190501.50616411'),
	'identifierSchemeCodeUnspsc190501.50616412': __('identifierSchemeCodeUnspsc190501.50616412'),
	'identifierSchemeCodeUnspsc190501.50616413': __('identifierSchemeCodeUnspsc190501.50616413'),
	'identifierSchemeCodeUnspsc190501.50616414': __('identifierSchemeCodeUnspsc190501.50616414'),
	'identifierSchemeCodeUnspsc190501.50616415': __('identifierSchemeCodeUnspsc190501.50616415'),
	'identifierSchemeCodeUnspsc190501.50616416': __('identifierSchemeCodeUnspsc190501.50616416'),
	'identifierSchemeCodeUnspsc190501.50616417': __('identifierSchemeCodeUnspsc190501.50616417'),
	'identifierSchemeCodeUnspsc190501.50616418': __('identifierSchemeCodeUnspsc190501.50616418'),
	'identifierSchemeCodeUnspsc190501.50616419': __('identifierSchemeCodeUnspsc190501.50616419'),
	'identifierSchemeCodeUnspsc190501.50616420': __('identifierSchemeCodeUnspsc190501.50616420'),
	'identifierSchemeCodeUnspsc190501.50616421': __('identifierSchemeCodeUnspsc190501.50616421'),
	'identifierSchemeCodeUnspsc190501.50616422': __('identifierSchemeCodeUnspsc190501.50616422'),
	'identifierSchemeCodeUnspsc190501.50616423': __('identifierSchemeCodeUnspsc190501.50616423'),
	'identifierSchemeCodeUnspsc190501.50616424': __('identifierSchemeCodeUnspsc190501.50616424'),
	'identifierSchemeCodeUnspsc190501.50616425': __('identifierSchemeCodeUnspsc190501.50616425'),
	'identifierSchemeCodeUnspsc190501.50616426': __('identifierSchemeCodeUnspsc190501.50616426'),
	'identifierSchemeCodeUnspsc190501.50616427': __('identifierSchemeCodeUnspsc190501.50616427'),
	'identifierSchemeCodeUnspsc190501.50616500': __('identifierSchemeCodeUnspsc190501.50616500'),
	'identifierSchemeCodeUnspsc190501.50616501': __('identifierSchemeCodeUnspsc190501.50616501'),
	'identifierSchemeCodeUnspsc190501.50616502': __('identifierSchemeCodeUnspsc190501.50616502'),
	'identifierSchemeCodeUnspsc190501.50616503': __('identifierSchemeCodeUnspsc190501.50616503'),
	'identifierSchemeCodeUnspsc190501.50616504': __('identifierSchemeCodeUnspsc190501.50616504'),
	'identifierSchemeCodeUnspsc190501.50616505': __('identifierSchemeCodeUnspsc190501.50616505'),
	'identifierSchemeCodeUnspsc190501.50616506': __('identifierSchemeCodeUnspsc190501.50616506'),
	'identifierSchemeCodeUnspsc190501.50616507': __('identifierSchemeCodeUnspsc190501.50616507'),
	'identifierSchemeCodeUnspsc190501.50616508': __('identifierSchemeCodeUnspsc190501.50616508'),
	'identifierSchemeCodeUnspsc190501.50616600': __('identifierSchemeCodeUnspsc190501.50616600'),
	'identifierSchemeCodeUnspsc190501.50616601': __('identifierSchemeCodeUnspsc190501.50616601'),
	'identifierSchemeCodeUnspsc190501.50616602': __('identifierSchemeCodeUnspsc190501.50616602'),
	'identifierSchemeCodeUnspsc190501.50616603': __('identifierSchemeCodeUnspsc190501.50616603'),
	'identifierSchemeCodeUnspsc190501.50616604': __('identifierSchemeCodeUnspsc190501.50616604'),
	'identifierSchemeCodeUnspsc190501.50616605': __('identifierSchemeCodeUnspsc190501.50616605'),
	'identifierSchemeCodeUnspsc190501.50616606': __('identifierSchemeCodeUnspsc190501.50616606'),
	'identifierSchemeCodeUnspsc190501.50616607': __('identifierSchemeCodeUnspsc190501.50616607'),
	'identifierSchemeCodeUnspsc190501.50616608': __('identifierSchemeCodeUnspsc190501.50616608'),
	'identifierSchemeCodeUnspsc190501.50616609': __('identifierSchemeCodeUnspsc190501.50616609'),
	'identifierSchemeCodeUnspsc190501.50616610': __('identifierSchemeCodeUnspsc190501.50616610'),
	'identifierSchemeCodeUnspsc190501.50616611': __('identifierSchemeCodeUnspsc190501.50616611'),
	'identifierSchemeCodeUnspsc190501.50616612': __('identifierSchemeCodeUnspsc190501.50616612'),
	'identifierSchemeCodeUnspsc190501.50616613': __('identifierSchemeCodeUnspsc190501.50616613'),
	'identifierSchemeCodeUnspsc190501.50616614': __('identifierSchemeCodeUnspsc190501.50616614'),
	'identifierSchemeCodeUnspsc190501.50616615': __('identifierSchemeCodeUnspsc190501.50616615'),
	'identifierSchemeCodeUnspsc190501.50616616': __('identifierSchemeCodeUnspsc190501.50616616'),
	'identifierSchemeCodeUnspsc190501.50616617': __('identifierSchemeCodeUnspsc190501.50616617'),
	'identifierSchemeCodeUnspsc190501.50616618': __('identifierSchemeCodeUnspsc190501.50616618'),
	'identifierSchemeCodeUnspsc190501.50616619': __('identifierSchemeCodeUnspsc190501.50616619'),
	'identifierSchemeCodeUnspsc190501.50616620': __('identifierSchemeCodeUnspsc190501.50616620'),
	'identifierSchemeCodeUnspsc190501.50616700': __('identifierSchemeCodeUnspsc190501.50616700'),
	'identifierSchemeCodeUnspsc190501.50616701': __('identifierSchemeCodeUnspsc190501.50616701'),
	'identifierSchemeCodeUnspsc190501.50616702': __('identifierSchemeCodeUnspsc190501.50616702'),
	'identifierSchemeCodeUnspsc190501.50616703': __('identifierSchemeCodeUnspsc190501.50616703'),
	'identifierSchemeCodeUnspsc190501.50616704': __('identifierSchemeCodeUnspsc190501.50616704'),
	'identifierSchemeCodeUnspsc190501.50616705': __('identifierSchemeCodeUnspsc190501.50616705'),
	'identifierSchemeCodeUnspsc190501.50616706': __('identifierSchemeCodeUnspsc190501.50616706'),
	'identifierSchemeCodeUnspsc190501.50616707': __('identifierSchemeCodeUnspsc190501.50616707'),
	'identifierSchemeCodeUnspsc190501.50616708': __('identifierSchemeCodeUnspsc190501.50616708'),
	'identifierSchemeCodeUnspsc190501.50616709': __('identifierSchemeCodeUnspsc190501.50616709'),
	'identifierSchemeCodeUnspsc190501.50616710': __('identifierSchemeCodeUnspsc190501.50616710'),
	'identifierSchemeCodeUnspsc190501.50616800': __('identifierSchemeCodeUnspsc190501.50616800'),
	'identifierSchemeCodeUnspsc190501.50616801': __('identifierSchemeCodeUnspsc190501.50616801'),
	'identifierSchemeCodeUnspsc190501.50616802': __('identifierSchemeCodeUnspsc190501.50616802'),
	'identifierSchemeCodeUnspsc190501.50616803': __('identifierSchemeCodeUnspsc190501.50616803'),
	'identifierSchemeCodeUnspsc190501.50616804': __('identifierSchemeCodeUnspsc190501.50616804'),
	'identifierSchemeCodeUnspsc190501.50616805': __('identifierSchemeCodeUnspsc190501.50616805'),
	'identifierSchemeCodeUnspsc190501.50616806': __('identifierSchemeCodeUnspsc190501.50616806'),
	'identifierSchemeCodeUnspsc190501.50616807': __('identifierSchemeCodeUnspsc190501.50616807'),
	'identifierSchemeCodeUnspsc190501.50616808': __('identifierSchemeCodeUnspsc190501.50616808'),
	'identifierSchemeCodeUnspsc190501.50616809': __('identifierSchemeCodeUnspsc190501.50616809'),
	'identifierSchemeCodeUnspsc190501.50616810': __('identifierSchemeCodeUnspsc190501.50616810'),
	'identifierSchemeCodeUnspsc190501.50616811': __('identifierSchemeCodeUnspsc190501.50616811'),
	'identifierSchemeCodeUnspsc190501.50616812': __('identifierSchemeCodeUnspsc190501.50616812'),
	'identifierSchemeCodeUnspsc190501.50616813': __('identifierSchemeCodeUnspsc190501.50616813'),
	'identifierSchemeCodeUnspsc190501.50616814': __('identifierSchemeCodeUnspsc190501.50616814'),
	'identifierSchemeCodeUnspsc190501.50616815': __('identifierSchemeCodeUnspsc190501.50616815'),
	'identifierSchemeCodeUnspsc190501.50616816': __('identifierSchemeCodeUnspsc190501.50616816'),
	'identifierSchemeCodeUnspsc190501.50616817': __('identifierSchemeCodeUnspsc190501.50616817'),
	'identifierSchemeCodeUnspsc190501.50616818': __('identifierSchemeCodeUnspsc190501.50616818'),
	'identifierSchemeCodeUnspsc190501.50616819': __('identifierSchemeCodeUnspsc190501.50616819'),
	'identifierSchemeCodeUnspsc190501.50616820': __('identifierSchemeCodeUnspsc190501.50616820'),
	'identifierSchemeCodeUnspsc190501.50616821': __('identifierSchemeCodeUnspsc190501.50616821'),
	'identifierSchemeCodeUnspsc190501.50616822': __('identifierSchemeCodeUnspsc190501.50616822'),
	'identifierSchemeCodeUnspsc190501.50616823': __('identifierSchemeCodeUnspsc190501.50616823'),
	'identifierSchemeCodeUnspsc190501.50616824': __('identifierSchemeCodeUnspsc190501.50616824'),
	'identifierSchemeCodeUnspsc190501.50616825': __('identifierSchemeCodeUnspsc190501.50616825'),
	'identifierSchemeCodeUnspsc190501.50616826': __('identifierSchemeCodeUnspsc190501.50616826'),
	'identifierSchemeCodeUnspsc190501.50616827': __('identifierSchemeCodeUnspsc190501.50616827'),
	'identifierSchemeCodeUnspsc190501.50616828': __('identifierSchemeCodeUnspsc190501.50616828'),
	'identifierSchemeCodeUnspsc190501.50616829': __('identifierSchemeCodeUnspsc190501.50616829'),
	'identifierSchemeCodeUnspsc190501.50616830': __('identifierSchemeCodeUnspsc190501.50616830'),
	'identifierSchemeCodeUnspsc190501.50616831': __('identifierSchemeCodeUnspsc190501.50616831'),
	'identifierSchemeCodeUnspsc190501.50616832': __('identifierSchemeCodeUnspsc190501.50616832'),
	'identifierSchemeCodeUnspsc190501.50616833': __('identifierSchemeCodeUnspsc190501.50616833'),
	'identifierSchemeCodeUnspsc190501.50616834': __('identifierSchemeCodeUnspsc190501.50616834'),
	'identifierSchemeCodeUnspsc190501.50616835': __('identifierSchemeCodeUnspsc190501.50616835'),
	'identifierSchemeCodeUnspsc190501.50616836': __('identifierSchemeCodeUnspsc190501.50616836'),
	'identifierSchemeCodeUnspsc190501.50616837': __('identifierSchemeCodeUnspsc190501.50616837'),
	'identifierSchemeCodeUnspsc190501.50616838': __('identifierSchemeCodeUnspsc190501.50616838'),
	'identifierSchemeCodeUnspsc190501.50616839': __('identifierSchemeCodeUnspsc190501.50616839'),
	'identifierSchemeCodeUnspsc190501.50616840': __('identifierSchemeCodeUnspsc190501.50616840'),
	'identifierSchemeCodeUnspsc190501.50616841': __('identifierSchemeCodeUnspsc190501.50616841'),
	'identifierSchemeCodeUnspsc190501.50616842': __('identifierSchemeCodeUnspsc190501.50616842'),
	'identifierSchemeCodeUnspsc190501.50616843': __('identifierSchemeCodeUnspsc190501.50616843'),
	'identifierSchemeCodeUnspsc190501.50616844': __('identifierSchemeCodeUnspsc190501.50616844'),
	'identifierSchemeCodeUnspsc190501.50616845': __('identifierSchemeCodeUnspsc190501.50616845'),
	'identifierSchemeCodeUnspsc190501.50616846': __('identifierSchemeCodeUnspsc190501.50616846'),
	'identifierSchemeCodeUnspsc190501.50616847': __('identifierSchemeCodeUnspsc190501.50616847'),
	'identifierSchemeCodeUnspsc190501.50616848': __('identifierSchemeCodeUnspsc190501.50616848'),
	'identifierSchemeCodeUnspsc190501.50616849': __('identifierSchemeCodeUnspsc190501.50616849'),
	'identifierSchemeCodeUnspsc190501.50616850': __('identifierSchemeCodeUnspsc190501.50616850'),
	'identifierSchemeCodeUnspsc190501.50616851': __('identifierSchemeCodeUnspsc190501.50616851'),
	'identifierSchemeCodeUnspsc190501.50616852': __('identifierSchemeCodeUnspsc190501.50616852'),
	'identifierSchemeCodeUnspsc190501.50616853': __('identifierSchemeCodeUnspsc190501.50616853'),
	'identifierSchemeCodeUnspsc190501.50616854': __('identifierSchemeCodeUnspsc190501.50616854'),
	'identifierSchemeCodeUnspsc190501.50616855': __('identifierSchemeCodeUnspsc190501.50616855'),
	'identifierSchemeCodeUnspsc190501.50616856': __('identifierSchemeCodeUnspsc190501.50616856'),
	'identifierSchemeCodeUnspsc190501.50616857': __('identifierSchemeCodeUnspsc190501.50616857'),
	'identifierSchemeCodeUnspsc190501.50616858': __('identifierSchemeCodeUnspsc190501.50616858'),
	'identifierSchemeCodeUnspsc190501.50616859': __('identifierSchemeCodeUnspsc190501.50616859'),
	'identifierSchemeCodeUnspsc190501.50616860': __('identifierSchemeCodeUnspsc190501.50616860'),
	'identifierSchemeCodeUnspsc190501.50616861': __('identifierSchemeCodeUnspsc190501.50616861'),
	'identifierSchemeCodeUnspsc190501.50616862': __('identifierSchemeCodeUnspsc190501.50616862'),
	'identifierSchemeCodeUnspsc190501.50616863': __('identifierSchemeCodeUnspsc190501.50616863'),
	'identifierSchemeCodeUnspsc190501.50616864': __('identifierSchemeCodeUnspsc190501.50616864'),
	'identifierSchemeCodeUnspsc190501.50616865': __('identifierSchemeCodeUnspsc190501.50616865'),
	'identifierSchemeCodeUnspsc190501.50616866': __('identifierSchemeCodeUnspsc190501.50616866'),
	'identifierSchemeCodeUnspsc190501.50616867': __('identifierSchemeCodeUnspsc190501.50616867'),
	'identifierSchemeCodeUnspsc190501.50616868': __('identifierSchemeCodeUnspsc190501.50616868'),
	'identifierSchemeCodeUnspsc190501.50616900': __('identifierSchemeCodeUnspsc190501.50616900'),
	'identifierSchemeCodeUnspsc190501.50616901': __('identifierSchemeCodeUnspsc190501.50616901'),
	'identifierSchemeCodeUnspsc190501.50616902': __('identifierSchemeCodeUnspsc190501.50616902'),
	'identifierSchemeCodeUnspsc190501.50616903': __('identifierSchemeCodeUnspsc190501.50616903'),
	'identifierSchemeCodeUnspsc190501.50616904': __('identifierSchemeCodeUnspsc190501.50616904'),
	'identifierSchemeCodeUnspsc190501.50616905': __('identifierSchemeCodeUnspsc190501.50616905'),
	'identifierSchemeCodeUnspsc190501.50616906': __('identifierSchemeCodeUnspsc190501.50616906'),
	'identifierSchemeCodeUnspsc190501.50616907': __('identifierSchemeCodeUnspsc190501.50616907'),
	'identifierSchemeCodeUnspsc190501.50616908': __('identifierSchemeCodeUnspsc190501.50616908'),
	'identifierSchemeCodeUnspsc190501.50616909': __('identifierSchemeCodeUnspsc190501.50616909'),
	'identifierSchemeCodeUnspsc190501.50616910': __('identifierSchemeCodeUnspsc190501.50616910'),
	'identifierSchemeCodeUnspsc190501.50620000': __('identifierSchemeCodeUnspsc190501.50620000'),
	'identifierSchemeCodeUnspsc190501.50621500': __('identifierSchemeCodeUnspsc190501.50621500'),
	'identifierSchemeCodeUnspsc190501.50621501': __('identifierSchemeCodeUnspsc190501.50621501'),
	'identifierSchemeCodeUnspsc190501.50621502': __('identifierSchemeCodeUnspsc190501.50621502'),
	'identifierSchemeCodeUnspsc190501.50621503': __('identifierSchemeCodeUnspsc190501.50621503'),
	'identifierSchemeCodeUnspsc190501.50621504': __('identifierSchemeCodeUnspsc190501.50621504'),
	'identifierSchemeCodeUnspsc190501.50621505': __('identifierSchemeCodeUnspsc190501.50621505'),
	'identifierSchemeCodeUnspsc190501.50621506': __('identifierSchemeCodeUnspsc190501.50621506'),
	'identifierSchemeCodeUnspsc190501.50621507': __('identifierSchemeCodeUnspsc190501.50621507'),
	'identifierSchemeCodeUnspsc190501.50621508': __('identifierSchemeCodeUnspsc190501.50621508'),
	'identifierSchemeCodeUnspsc190501.50621509': __('identifierSchemeCodeUnspsc190501.50621509'),
	'identifierSchemeCodeUnspsc190501.50621510': __('identifierSchemeCodeUnspsc190501.50621510'),
	'identifierSchemeCodeUnspsc190501.50621511': __('identifierSchemeCodeUnspsc190501.50621511'),
	'identifierSchemeCodeUnspsc190501.50621512': __('identifierSchemeCodeUnspsc190501.50621512'),
	'identifierSchemeCodeUnspsc190501.50621513': __('identifierSchemeCodeUnspsc190501.50621513'),
	'identifierSchemeCodeUnspsc190501.50621600': __('identifierSchemeCodeUnspsc190501.50621600'),
	'identifierSchemeCodeUnspsc190501.50621601': __('identifierSchemeCodeUnspsc190501.50621601'),
	'identifierSchemeCodeUnspsc190501.50621602': __('identifierSchemeCodeUnspsc190501.50621602'),
	'identifierSchemeCodeUnspsc190501.50621603': __('identifierSchemeCodeUnspsc190501.50621603'),
	'identifierSchemeCodeUnspsc190501.50621604': __('identifierSchemeCodeUnspsc190501.50621604'),
	'identifierSchemeCodeUnspsc190501.50621605': __('identifierSchemeCodeUnspsc190501.50621605'),
	'identifierSchemeCodeUnspsc190501.50621700': __('identifierSchemeCodeUnspsc190501.50621700'),
	'identifierSchemeCodeUnspsc190501.50621701': __('identifierSchemeCodeUnspsc190501.50621701'),
	'identifierSchemeCodeUnspsc190501.50621702': __('identifierSchemeCodeUnspsc190501.50621702'),
	'identifierSchemeCodeUnspsc190501.50621703': __('identifierSchemeCodeUnspsc190501.50621703'),
	'identifierSchemeCodeUnspsc190501.50621704': __('identifierSchemeCodeUnspsc190501.50621704'),
	'identifierSchemeCodeUnspsc190501.50621705': __('identifierSchemeCodeUnspsc190501.50621705'),
	'identifierSchemeCodeUnspsc190501.50621706': __('identifierSchemeCodeUnspsc190501.50621706'),
	'identifierSchemeCodeUnspsc190501.50621707': __('identifierSchemeCodeUnspsc190501.50621707'),
	'identifierSchemeCodeUnspsc190501.50621708': __('identifierSchemeCodeUnspsc190501.50621708'),
	'identifierSchemeCodeUnspsc190501.50621709': __('identifierSchemeCodeUnspsc190501.50621709'),
	'identifierSchemeCodeUnspsc190501.50621710': __('identifierSchemeCodeUnspsc190501.50621710'),
	'identifierSchemeCodeUnspsc190501.50621711': __('identifierSchemeCodeUnspsc190501.50621711'),
	'identifierSchemeCodeUnspsc190501.50621712': __('identifierSchemeCodeUnspsc190501.50621712'),
	'identifierSchemeCodeUnspsc190501.50621713': __('identifierSchemeCodeUnspsc190501.50621713'),
	'identifierSchemeCodeUnspsc190501.50621714': __('identifierSchemeCodeUnspsc190501.50621714'),
	'identifierSchemeCodeUnspsc190501.50621715': __('identifierSchemeCodeUnspsc190501.50621715'),
	'identifierSchemeCodeUnspsc190501.50621716': __('identifierSchemeCodeUnspsc190501.50621716'),
	'identifierSchemeCodeUnspsc190501.50621717': __('identifierSchemeCodeUnspsc190501.50621717'),
	'identifierSchemeCodeUnspsc190501.50621718': __('identifierSchemeCodeUnspsc190501.50621718'),
	'identifierSchemeCodeUnspsc190501.50621719': __('identifierSchemeCodeUnspsc190501.50621719'),
	'identifierSchemeCodeUnspsc190501.50621720': __('identifierSchemeCodeUnspsc190501.50621720'),
	'identifierSchemeCodeUnspsc190501.50621721': __('identifierSchemeCodeUnspsc190501.50621721'),
	'identifierSchemeCodeUnspsc190501.50621722': __('identifierSchemeCodeUnspsc190501.50621722'),
	'identifierSchemeCodeUnspsc190501.50621723': __('identifierSchemeCodeUnspsc190501.50621723'),
	'identifierSchemeCodeUnspsc190501.50621724': __('identifierSchemeCodeUnspsc190501.50621724'),
	'identifierSchemeCodeUnspsc190501.50621725': __('identifierSchemeCodeUnspsc190501.50621725'),
	'identifierSchemeCodeUnspsc190501.50621726': __('identifierSchemeCodeUnspsc190501.50621726'),
	'identifierSchemeCodeUnspsc190501.50621727': __('identifierSchemeCodeUnspsc190501.50621727'),
	'identifierSchemeCodeUnspsc190501.50621728': __('identifierSchemeCodeUnspsc190501.50621728'),
	'identifierSchemeCodeUnspsc190501.50621729': __('identifierSchemeCodeUnspsc190501.50621729'),
	'identifierSchemeCodeUnspsc190501.50621730': __('identifierSchemeCodeUnspsc190501.50621730'),
	'identifierSchemeCodeUnspsc190501.50621731': __('identifierSchemeCodeUnspsc190501.50621731'),
	'identifierSchemeCodeUnspsc190501.50621732': __('identifierSchemeCodeUnspsc190501.50621732'),
	'identifierSchemeCodeUnspsc190501.50621733': __('identifierSchemeCodeUnspsc190501.50621733'),
	'identifierSchemeCodeUnspsc190501.50621734': __('identifierSchemeCodeUnspsc190501.50621734'),
	'identifierSchemeCodeUnspsc190501.50621735': __('identifierSchemeCodeUnspsc190501.50621735'),
	'identifierSchemeCodeUnspsc190501.50621736': __('identifierSchemeCodeUnspsc190501.50621736'),
	'identifierSchemeCodeUnspsc190501.50621737': __('identifierSchemeCodeUnspsc190501.50621737'),
	'identifierSchemeCodeUnspsc190501.50621738': __('identifierSchemeCodeUnspsc190501.50621738'),
	'identifierSchemeCodeUnspsc190501.50621739': __('identifierSchemeCodeUnspsc190501.50621739'),
	'identifierSchemeCodeUnspsc190501.50621740': __('identifierSchemeCodeUnspsc190501.50621740'),
	'identifierSchemeCodeUnspsc190501.50621741': __('identifierSchemeCodeUnspsc190501.50621741'),
	'identifierSchemeCodeUnspsc190501.50621742': __('identifierSchemeCodeUnspsc190501.50621742'),
	'identifierSchemeCodeUnspsc190501.50621743': __('identifierSchemeCodeUnspsc190501.50621743'),
	'identifierSchemeCodeUnspsc190501.50621744': __('identifierSchemeCodeUnspsc190501.50621744'),
	'identifierSchemeCodeUnspsc190501.50621745': __('identifierSchemeCodeUnspsc190501.50621745'),
	'identifierSchemeCodeUnspsc190501.50621746': __('identifierSchemeCodeUnspsc190501.50621746'),
	'identifierSchemeCodeUnspsc190501.50621747': __('identifierSchemeCodeUnspsc190501.50621747'),
	'identifierSchemeCodeUnspsc190501.50621748': __('identifierSchemeCodeUnspsc190501.50621748'),
	'identifierSchemeCodeUnspsc190501.50621749': __('identifierSchemeCodeUnspsc190501.50621749'),
	'identifierSchemeCodeUnspsc190501.50621750': __('identifierSchemeCodeUnspsc190501.50621750'),
	'identifierSchemeCodeUnspsc190501.50621751': __('identifierSchemeCodeUnspsc190501.50621751'),
	'identifierSchemeCodeUnspsc190501.50621752': __('identifierSchemeCodeUnspsc190501.50621752'),
	'identifierSchemeCodeUnspsc190501.50621753': __('identifierSchemeCodeUnspsc190501.50621753'),
	'identifierSchemeCodeUnspsc190501.50621754': __('identifierSchemeCodeUnspsc190501.50621754'),
	'identifierSchemeCodeUnspsc190501.50621755': __('identifierSchemeCodeUnspsc190501.50621755'),
	'identifierSchemeCodeUnspsc190501.50621756': __('identifierSchemeCodeUnspsc190501.50621756'),
	'identifierSchemeCodeUnspsc190501.50621757': __('identifierSchemeCodeUnspsc190501.50621757'),
	'identifierSchemeCodeUnspsc190501.50621758': __('identifierSchemeCodeUnspsc190501.50621758'),
	'identifierSchemeCodeUnspsc190501.50621759': __('identifierSchemeCodeUnspsc190501.50621759'),
	'identifierSchemeCodeUnspsc190501.50621760': __('identifierSchemeCodeUnspsc190501.50621760'),
	'identifierSchemeCodeUnspsc190501.50621761': __('identifierSchemeCodeUnspsc190501.50621761'),
	'identifierSchemeCodeUnspsc190501.50621762': __('identifierSchemeCodeUnspsc190501.50621762'),
	'identifierSchemeCodeUnspsc190501.50621763': __('identifierSchemeCodeUnspsc190501.50621763'),
	'identifierSchemeCodeUnspsc190501.50621764': __('identifierSchemeCodeUnspsc190501.50621764'),
	'identifierSchemeCodeUnspsc190501.50621765': __('identifierSchemeCodeUnspsc190501.50621765'),
	'identifierSchemeCodeUnspsc190501.50621766': __('identifierSchemeCodeUnspsc190501.50621766'),
	'identifierSchemeCodeUnspsc190501.50621767': __('identifierSchemeCodeUnspsc190501.50621767'),
	'identifierSchemeCodeUnspsc190501.50621768': __('identifierSchemeCodeUnspsc190501.50621768'),
	'identifierSchemeCodeUnspsc190501.50621769': __('identifierSchemeCodeUnspsc190501.50621769'),
	'identifierSchemeCodeUnspsc190501.50621770': __('identifierSchemeCodeUnspsc190501.50621770'),
	'identifierSchemeCodeUnspsc190501.50621771': __('identifierSchemeCodeUnspsc190501.50621771'),
	'identifierSchemeCodeUnspsc190501.50621772': __('identifierSchemeCodeUnspsc190501.50621772'),
	'identifierSchemeCodeUnspsc190501.50621773': __('identifierSchemeCodeUnspsc190501.50621773'),
	'identifierSchemeCodeUnspsc190501.50621774': __('identifierSchemeCodeUnspsc190501.50621774'),
	'identifierSchemeCodeUnspsc190501.50621775': __('identifierSchemeCodeUnspsc190501.50621775'),
	'identifierSchemeCodeUnspsc190501.50621776': __('identifierSchemeCodeUnspsc190501.50621776'),
	'identifierSchemeCodeUnspsc190501.50621777': __('identifierSchemeCodeUnspsc190501.50621777'),
	'identifierSchemeCodeUnspsc190501.50621778': __('identifierSchemeCodeUnspsc190501.50621778'),
	'identifierSchemeCodeUnspsc190501.50621779': __('identifierSchemeCodeUnspsc190501.50621779'),
	'identifierSchemeCodeUnspsc190501.50621780': __('identifierSchemeCodeUnspsc190501.50621780'),
	'identifierSchemeCodeUnspsc190501.50621781': __('identifierSchemeCodeUnspsc190501.50621781'),
	'identifierSchemeCodeUnspsc190501.50621782': __('identifierSchemeCodeUnspsc190501.50621782'),
	'identifierSchemeCodeUnspsc190501.50621800': __('identifierSchemeCodeUnspsc190501.50621800'),
	'identifierSchemeCodeUnspsc190501.50621801': __('identifierSchemeCodeUnspsc190501.50621801'),
	'identifierSchemeCodeUnspsc190501.50621802': __('identifierSchemeCodeUnspsc190501.50621802'),
	'identifierSchemeCodeUnspsc190501.50621803': __('identifierSchemeCodeUnspsc190501.50621803'),
	'identifierSchemeCodeUnspsc190501.50621804': __('identifierSchemeCodeUnspsc190501.50621804'),
	'identifierSchemeCodeUnspsc190501.50621805': __('identifierSchemeCodeUnspsc190501.50621805'),
	'identifierSchemeCodeUnspsc190501.50621806': __('identifierSchemeCodeUnspsc190501.50621806'),
	'identifierSchemeCodeUnspsc190501.50621807': __('identifierSchemeCodeUnspsc190501.50621807'),
	'identifierSchemeCodeUnspsc190501.50621808': __('identifierSchemeCodeUnspsc190501.50621808'),
	'identifierSchemeCodeUnspsc190501.50621809': __('identifierSchemeCodeUnspsc190501.50621809'),
	'identifierSchemeCodeUnspsc190501.50621810': __('identifierSchemeCodeUnspsc190501.50621810'),
	'identifierSchemeCodeUnspsc190501.50621811': __('identifierSchemeCodeUnspsc190501.50621811'),
	'identifierSchemeCodeUnspsc190501.50621812': __('identifierSchemeCodeUnspsc190501.50621812'),
	'identifierSchemeCodeUnspsc190501.50621813': __('identifierSchemeCodeUnspsc190501.50621813'),
	'identifierSchemeCodeUnspsc190501.50621814': __('identifierSchemeCodeUnspsc190501.50621814'),
	'identifierSchemeCodeUnspsc190501.50621815': __('identifierSchemeCodeUnspsc190501.50621815'),
	'identifierSchemeCodeUnspsc190501.50621816': __('identifierSchemeCodeUnspsc190501.50621816'),
	'identifierSchemeCodeUnspsc190501.50621817': __('identifierSchemeCodeUnspsc190501.50621817'),
	'identifierSchemeCodeUnspsc190501.50621818': __('identifierSchemeCodeUnspsc190501.50621818'),
	'identifierSchemeCodeUnspsc190501.50621819': __('identifierSchemeCodeUnspsc190501.50621819'),
	'identifierSchemeCodeUnspsc190501.50621820': __('identifierSchemeCodeUnspsc190501.50621820'),
	'identifierSchemeCodeUnspsc190501.50621821': __('identifierSchemeCodeUnspsc190501.50621821'),
	'identifierSchemeCodeUnspsc190501.50621822': __('identifierSchemeCodeUnspsc190501.50621822'),
	'identifierSchemeCodeUnspsc190501.50621823': __('identifierSchemeCodeUnspsc190501.50621823'),
	'identifierSchemeCodeUnspsc190501.50621824': __('identifierSchemeCodeUnspsc190501.50621824'),
	'identifierSchemeCodeUnspsc190501.50621825': __('identifierSchemeCodeUnspsc190501.50621825'),
	'identifierSchemeCodeUnspsc190501.50621826': __('identifierSchemeCodeUnspsc190501.50621826'),
	'identifierSchemeCodeUnspsc190501.50621827': __('identifierSchemeCodeUnspsc190501.50621827'),
	'identifierSchemeCodeUnspsc190501.50621828': __('identifierSchemeCodeUnspsc190501.50621828'),
	'identifierSchemeCodeUnspsc190501.50621829': __('identifierSchemeCodeUnspsc190501.50621829'),
	'identifierSchemeCodeUnspsc190501.50621830': __('identifierSchemeCodeUnspsc190501.50621830'),
	'identifierSchemeCodeUnspsc190501.50621831': __('identifierSchemeCodeUnspsc190501.50621831'),
	'identifierSchemeCodeUnspsc190501.50621832': __('identifierSchemeCodeUnspsc190501.50621832'),
	'identifierSchemeCodeUnspsc190501.50621833': __('identifierSchemeCodeUnspsc190501.50621833'),
	'identifierSchemeCodeUnspsc190501.50621834': __('identifierSchemeCodeUnspsc190501.50621834'),
	'identifierSchemeCodeUnspsc190501.50621835': __('identifierSchemeCodeUnspsc190501.50621835'),
	'identifierSchemeCodeUnspsc190501.50621836': __('identifierSchemeCodeUnspsc190501.50621836'),
	'identifierSchemeCodeUnspsc190501.50621837': __('identifierSchemeCodeUnspsc190501.50621837'),
	'identifierSchemeCodeUnspsc190501.50621838': __('identifierSchemeCodeUnspsc190501.50621838'),
	'identifierSchemeCodeUnspsc190501.50621839': __('identifierSchemeCodeUnspsc190501.50621839'),
	'identifierSchemeCodeUnspsc190501.50621840': __('identifierSchemeCodeUnspsc190501.50621840'),
	'identifierSchemeCodeUnspsc190501.50621841': __('identifierSchemeCodeUnspsc190501.50621841'),
	'identifierSchemeCodeUnspsc190501.50621842': __('identifierSchemeCodeUnspsc190501.50621842'),
	'identifierSchemeCodeUnspsc190501.50621843': __('identifierSchemeCodeUnspsc190501.50621843'),
	'identifierSchemeCodeUnspsc190501.50621844': __('identifierSchemeCodeUnspsc190501.50621844'),
	'identifierSchemeCodeUnspsc190501.50621845': __('identifierSchemeCodeUnspsc190501.50621845'),
	'identifierSchemeCodeUnspsc190501.50621846': __('identifierSchemeCodeUnspsc190501.50621846'),
	'identifierSchemeCodeUnspsc190501.50621847': __('identifierSchemeCodeUnspsc190501.50621847'),
	'identifierSchemeCodeUnspsc190501.50621848': __('identifierSchemeCodeUnspsc190501.50621848'),
	'identifierSchemeCodeUnspsc190501.50621849': __('identifierSchemeCodeUnspsc190501.50621849'),
	'identifierSchemeCodeUnspsc190501.50621850': __('identifierSchemeCodeUnspsc190501.50621850'),
	'identifierSchemeCodeUnspsc190501.50621851': __('identifierSchemeCodeUnspsc190501.50621851'),
	'identifierSchemeCodeUnspsc190501.50621852': __('identifierSchemeCodeUnspsc190501.50621852'),
	'identifierSchemeCodeUnspsc190501.50621900': __('identifierSchemeCodeUnspsc190501.50621900'),
	'identifierSchemeCodeUnspsc190501.50621901': __('identifierSchemeCodeUnspsc190501.50621901'),
	'identifierSchemeCodeUnspsc190501.50621902': __('identifierSchemeCodeUnspsc190501.50621902'),
	'identifierSchemeCodeUnspsc190501.50621903': __('identifierSchemeCodeUnspsc190501.50621903'),
	'identifierSchemeCodeUnspsc190501.50621904': __('identifierSchemeCodeUnspsc190501.50621904'),
	'identifierSchemeCodeUnspsc190501.50621905': __('identifierSchemeCodeUnspsc190501.50621905'),
	'identifierSchemeCodeUnspsc190501.50621906': __('identifierSchemeCodeUnspsc190501.50621906'),
	'identifierSchemeCodeUnspsc190501.50621907': __('identifierSchemeCodeUnspsc190501.50621907'),
	'identifierSchemeCodeUnspsc190501.50621908': __('identifierSchemeCodeUnspsc190501.50621908'),
	'identifierSchemeCodeUnspsc190501.50621909': __('identifierSchemeCodeUnspsc190501.50621909'),
	'identifierSchemeCodeUnspsc190501.50621910': __('identifierSchemeCodeUnspsc190501.50621910'),
	'identifierSchemeCodeUnspsc190501.50621911': __('identifierSchemeCodeUnspsc190501.50621911'),
	'identifierSchemeCodeUnspsc190501.50621912': __('identifierSchemeCodeUnspsc190501.50621912'),
	'identifierSchemeCodeUnspsc190501.50621913': __('identifierSchemeCodeUnspsc190501.50621913'),
	'identifierSchemeCodeUnspsc190501.50621914': __('identifierSchemeCodeUnspsc190501.50621914'),
	'identifierSchemeCodeUnspsc190501.50621915': __('identifierSchemeCodeUnspsc190501.50621915'),
	'identifierSchemeCodeUnspsc190501.50621916': __('identifierSchemeCodeUnspsc190501.50621916'),
	'identifierSchemeCodeUnspsc190501.50621917': __('identifierSchemeCodeUnspsc190501.50621917'),
	'identifierSchemeCodeUnspsc190501.50621918': __('identifierSchemeCodeUnspsc190501.50621918'),
	'identifierSchemeCodeUnspsc190501.50621919': __('identifierSchemeCodeUnspsc190501.50621919'),
	'identifierSchemeCodeUnspsc190501.50621920': __('identifierSchemeCodeUnspsc190501.50621920'),
	'identifierSchemeCodeUnspsc190501.50621921': __('identifierSchemeCodeUnspsc190501.50621921'),
	'identifierSchemeCodeUnspsc190501.50621922': __('identifierSchemeCodeUnspsc190501.50621922'),
	'identifierSchemeCodeUnspsc190501.50622000': __('identifierSchemeCodeUnspsc190501.50622000'),
	'identifierSchemeCodeUnspsc190501.50622001': __('identifierSchemeCodeUnspsc190501.50622001'),
	'identifierSchemeCodeUnspsc190501.50622002': __('identifierSchemeCodeUnspsc190501.50622002'),
	'identifierSchemeCodeUnspsc190501.50622003': __('identifierSchemeCodeUnspsc190501.50622003'),
	'identifierSchemeCodeUnspsc190501.50622100': __('identifierSchemeCodeUnspsc190501.50622100'),
	'identifierSchemeCodeUnspsc190501.50622101': __('identifierSchemeCodeUnspsc190501.50622101'),
	'identifierSchemeCodeUnspsc190501.50622102': __('identifierSchemeCodeUnspsc190501.50622102'),
	'identifierSchemeCodeUnspsc190501.50622103': __('identifierSchemeCodeUnspsc190501.50622103'),
	'identifierSchemeCodeUnspsc190501.50622104': __('identifierSchemeCodeUnspsc190501.50622104'),
	'identifierSchemeCodeUnspsc190501.50622105': __('identifierSchemeCodeUnspsc190501.50622105'),
	'identifierSchemeCodeUnspsc190501.50622200': __('identifierSchemeCodeUnspsc190501.50622200'),
	'identifierSchemeCodeUnspsc190501.50622201': __('identifierSchemeCodeUnspsc190501.50622201'),
	'identifierSchemeCodeUnspsc190501.50622202': __('identifierSchemeCodeUnspsc190501.50622202'),
	'identifierSchemeCodeUnspsc190501.50622203': __('identifierSchemeCodeUnspsc190501.50622203'),
	'identifierSchemeCodeUnspsc190501.50622204': __('identifierSchemeCodeUnspsc190501.50622204'),
	'identifierSchemeCodeUnspsc190501.50622205': __('identifierSchemeCodeUnspsc190501.50622205'),
	'identifierSchemeCodeUnspsc190501.50622206': __('identifierSchemeCodeUnspsc190501.50622206'),
	'identifierSchemeCodeUnspsc190501.50622207': __('identifierSchemeCodeUnspsc190501.50622207'),
	'identifierSchemeCodeUnspsc190501.50622208': __('identifierSchemeCodeUnspsc190501.50622208'),
	'identifierSchemeCodeUnspsc190501.50622209': __('identifierSchemeCodeUnspsc190501.50622209'),
	'identifierSchemeCodeUnspsc190501.50622210': __('identifierSchemeCodeUnspsc190501.50622210'),
	'identifierSchemeCodeUnspsc190501.50622211': __('identifierSchemeCodeUnspsc190501.50622211'),
	'identifierSchemeCodeUnspsc190501.50622300': __('identifierSchemeCodeUnspsc190501.50622300'),
	'identifierSchemeCodeUnspsc190501.50622301': __('identifierSchemeCodeUnspsc190501.50622301'),
	'identifierSchemeCodeUnspsc190501.50622302': __('identifierSchemeCodeUnspsc190501.50622302'),
	'identifierSchemeCodeUnspsc190501.50622303': __('identifierSchemeCodeUnspsc190501.50622303'),
	'identifierSchemeCodeUnspsc190501.50622304': __('identifierSchemeCodeUnspsc190501.50622304'),
	'identifierSchemeCodeUnspsc190501.50622400': __('identifierSchemeCodeUnspsc190501.50622400'),
	'identifierSchemeCodeUnspsc190501.50622401': __('identifierSchemeCodeUnspsc190501.50622401'),
	'identifierSchemeCodeUnspsc190501.50622402': __('identifierSchemeCodeUnspsc190501.50622402'),
	'identifierSchemeCodeUnspsc190501.50622500': __('identifierSchemeCodeUnspsc190501.50622500'),
	'identifierSchemeCodeUnspsc190501.50622501': __('identifierSchemeCodeUnspsc190501.50622501'),
	'identifierSchemeCodeUnspsc190501.50622502': __('identifierSchemeCodeUnspsc190501.50622502'),
	'identifierSchemeCodeUnspsc190501.50622503': __('identifierSchemeCodeUnspsc190501.50622503'),
	'identifierSchemeCodeUnspsc190501.50622504': __('identifierSchemeCodeUnspsc190501.50622504'),
	'identifierSchemeCodeUnspsc190501.50622505': __('identifierSchemeCodeUnspsc190501.50622505'),
	'identifierSchemeCodeUnspsc190501.50622506': __('identifierSchemeCodeUnspsc190501.50622506'),
	'identifierSchemeCodeUnspsc190501.50622600': __('identifierSchemeCodeUnspsc190501.50622600'),
	'identifierSchemeCodeUnspsc190501.50622601': __('identifierSchemeCodeUnspsc190501.50622601'),
	'identifierSchemeCodeUnspsc190501.50622602': __('identifierSchemeCodeUnspsc190501.50622602'),
	'identifierSchemeCodeUnspsc190501.50622603': __('identifierSchemeCodeUnspsc190501.50622603'),
	'identifierSchemeCodeUnspsc190501.50622604': __('identifierSchemeCodeUnspsc190501.50622604'),
	'identifierSchemeCodeUnspsc190501.50622605': __('identifierSchemeCodeUnspsc190501.50622605'),
	'identifierSchemeCodeUnspsc190501.50622606': __('identifierSchemeCodeUnspsc190501.50622606'),
	'identifierSchemeCodeUnspsc190501.50622607': __('identifierSchemeCodeUnspsc190501.50622607'),
	'identifierSchemeCodeUnspsc190501.50622608': __('identifierSchemeCodeUnspsc190501.50622608'),
	'identifierSchemeCodeUnspsc190501.50622609': __('identifierSchemeCodeUnspsc190501.50622609'),
	'identifierSchemeCodeUnspsc190501.50622610': __('identifierSchemeCodeUnspsc190501.50622610'),
	'identifierSchemeCodeUnspsc190501.50622611': __('identifierSchemeCodeUnspsc190501.50622611'),
	'identifierSchemeCodeUnspsc190501.50622612': __('identifierSchemeCodeUnspsc190501.50622612'),
	'identifierSchemeCodeUnspsc190501.50622700': __('identifierSchemeCodeUnspsc190501.50622700'),
	'identifierSchemeCodeUnspsc190501.50622701': __('identifierSchemeCodeUnspsc190501.50622701'),
	'identifierSchemeCodeUnspsc190501.50622702': __('identifierSchemeCodeUnspsc190501.50622702'),
	'identifierSchemeCodeUnspsc190501.50622703': __('identifierSchemeCodeUnspsc190501.50622703'),
	'identifierSchemeCodeUnspsc190501.50622704': __('identifierSchemeCodeUnspsc190501.50622704'),
	'identifierSchemeCodeUnspsc190501.50622705': __('identifierSchemeCodeUnspsc190501.50622705'),
	'identifierSchemeCodeUnspsc190501.50622706': __('identifierSchemeCodeUnspsc190501.50622706'),
	'identifierSchemeCodeUnspsc190501.50622707': __('identifierSchemeCodeUnspsc190501.50622707'),
	'identifierSchemeCodeUnspsc190501.50622708': __('identifierSchemeCodeUnspsc190501.50622708'),
	'identifierSchemeCodeUnspsc190501.50622709': __('identifierSchemeCodeUnspsc190501.50622709'),
	'identifierSchemeCodeUnspsc190501.50622710': __('identifierSchemeCodeUnspsc190501.50622710'),
	'identifierSchemeCodeUnspsc190501.50622711': __('identifierSchemeCodeUnspsc190501.50622711'),
	'identifierSchemeCodeUnspsc190501.50622712': __('identifierSchemeCodeUnspsc190501.50622712'),
	'identifierSchemeCodeUnspsc190501.50622713': __('identifierSchemeCodeUnspsc190501.50622713'),
	'identifierSchemeCodeUnspsc190501.50622714': __('identifierSchemeCodeUnspsc190501.50622714'),
	'identifierSchemeCodeUnspsc190501.50622715': __('identifierSchemeCodeUnspsc190501.50622715'),
	'identifierSchemeCodeUnspsc190501.50622800': __('identifierSchemeCodeUnspsc190501.50622800'),
	'identifierSchemeCodeUnspsc190501.50622801': __('identifierSchemeCodeUnspsc190501.50622801'),
	'identifierSchemeCodeUnspsc190501.50622802': __('identifierSchemeCodeUnspsc190501.50622802'),
	'identifierSchemeCodeUnspsc190501.50622803': __('identifierSchemeCodeUnspsc190501.50622803'),
	'identifierSchemeCodeUnspsc190501.50622804': __('identifierSchemeCodeUnspsc190501.50622804'),
	'identifierSchemeCodeUnspsc190501.50622805': __('identifierSchemeCodeUnspsc190501.50622805'),
	'identifierSchemeCodeUnspsc190501.50622806': __('identifierSchemeCodeUnspsc190501.50622806'),
	'identifierSchemeCodeUnspsc190501.50622807': __('identifierSchemeCodeUnspsc190501.50622807'),
	'identifierSchemeCodeUnspsc190501.50622900': __('identifierSchemeCodeUnspsc190501.50622900'),
	'identifierSchemeCodeUnspsc190501.50622901': __('identifierSchemeCodeUnspsc190501.50622901'),
	'identifierSchemeCodeUnspsc190501.50622902': __('identifierSchemeCodeUnspsc190501.50622902'),
	'identifierSchemeCodeUnspsc190501.50622903': __('identifierSchemeCodeUnspsc190501.50622903'),
	'identifierSchemeCodeUnspsc190501.50622904': __('identifierSchemeCodeUnspsc190501.50622904'),
	'identifierSchemeCodeUnspsc190501.50622905': __('identifierSchemeCodeUnspsc190501.50622905'),
	'identifierSchemeCodeUnspsc190501.50622906': __('identifierSchemeCodeUnspsc190501.50622906'),
	'identifierSchemeCodeUnspsc190501.50622907': __('identifierSchemeCodeUnspsc190501.50622907'),
	'identifierSchemeCodeUnspsc190501.50622908': __('identifierSchemeCodeUnspsc190501.50622908'),
	'identifierSchemeCodeUnspsc190501.50622909': __('identifierSchemeCodeUnspsc190501.50622909'),
	'identifierSchemeCodeUnspsc190501.50622910': __('identifierSchemeCodeUnspsc190501.50622910'),
	'identifierSchemeCodeUnspsc190501.50622911': __('identifierSchemeCodeUnspsc190501.50622911'),
	'identifierSchemeCodeUnspsc190501.50623000': __('identifierSchemeCodeUnspsc190501.50623000'),
	'identifierSchemeCodeUnspsc190501.50623001': __('identifierSchemeCodeUnspsc190501.50623001'),
	'identifierSchemeCodeUnspsc190501.50623002': __('identifierSchemeCodeUnspsc190501.50623002'),
	'identifierSchemeCodeUnspsc190501.50623003': __('identifierSchemeCodeUnspsc190501.50623003'),
	'identifierSchemeCodeUnspsc190501.50623004': __('identifierSchemeCodeUnspsc190501.50623004'),
	'identifierSchemeCodeUnspsc190501.50623005': __('identifierSchemeCodeUnspsc190501.50623005'),
	'identifierSchemeCodeUnspsc190501.50623006': __('identifierSchemeCodeUnspsc190501.50623006'),
	'identifierSchemeCodeUnspsc190501.50623007': __('identifierSchemeCodeUnspsc190501.50623007'),
	'identifierSchemeCodeUnspsc190501.50623008': __('identifierSchemeCodeUnspsc190501.50623008'),
	'identifierSchemeCodeUnspsc190501.50623009': __('identifierSchemeCodeUnspsc190501.50623009'),
	'identifierSchemeCodeUnspsc190501.50623010': __('identifierSchemeCodeUnspsc190501.50623010'),
	'identifierSchemeCodeUnspsc190501.50623011': __('identifierSchemeCodeUnspsc190501.50623011'),
	'identifierSchemeCodeUnspsc190501.50623012': __('identifierSchemeCodeUnspsc190501.50623012'),
	'identifierSchemeCodeUnspsc190501.50623013': __('identifierSchemeCodeUnspsc190501.50623013'),
	'identifierSchemeCodeUnspsc190501.50623014': __('identifierSchemeCodeUnspsc190501.50623014'),
	'identifierSchemeCodeUnspsc190501.50623015': __('identifierSchemeCodeUnspsc190501.50623015'),
	'identifierSchemeCodeUnspsc190501.50623016': __('identifierSchemeCodeUnspsc190501.50623016'),
	'identifierSchemeCodeUnspsc190501.50623100': __('identifierSchemeCodeUnspsc190501.50623100'),
	'identifierSchemeCodeUnspsc190501.50623101': __('identifierSchemeCodeUnspsc190501.50623101'),
	'identifierSchemeCodeUnspsc190501.50623102': __('identifierSchemeCodeUnspsc190501.50623102'),
	'identifierSchemeCodeUnspsc190501.50623200': __('identifierSchemeCodeUnspsc190501.50623200'),
	'identifierSchemeCodeUnspsc190501.50623201': __('identifierSchemeCodeUnspsc190501.50623201'),
	'identifierSchemeCodeUnspsc190501.50623202': __('identifierSchemeCodeUnspsc190501.50623202'),
	'identifierSchemeCodeUnspsc190501.50623203': __('identifierSchemeCodeUnspsc190501.50623203'),
	'identifierSchemeCodeUnspsc190501.50623204': __('identifierSchemeCodeUnspsc190501.50623204'),
	'identifierSchemeCodeUnspsc190501.50623300': __('identifierSchemeCodeUnspsc190501.50623300'),
	'identifierSchemeCodeUnspsc190501.50623301': __('identifierSchemeCodeUnspsc190501.50623301'),
	'identifierSchemeCodeUnspsc190501.50623302': __('identifierSchemeCodeUnspsc190501.50623302'),
	'identifierSchemeCodeUnspsc190501.50623303': __('identifierSchemeCodeUnspsc190501.50623303'),
	'identifierSchemeCodeUnspsc190501.50623304': __('identifierSchemeCodeUnspsc190501.50623304'),
	'identifierSchemeCodeUnspsc190501.50623305': __('identifierSchemeCodeUnspsc190501.50623305'),
	'identifierSchemeCodeUnspsc190501.50623306': __('identifierSchemeCodeUnspsc190501.50623306'),
	'identifierSchemeCodeUnspsc190501.50623307': __('identifierSchemeCodeUnspsc190501.50623307'),
	'identifierSchemeCodeUnspsc190501.50623308': __('identifierSchemeCodeUnspsc190501.50623308'),
	'identifierSchemeCodeUnspsc190501.50623309': __('identifierSchemeCodeUnspsc190501.50623309'),
	'identifierSchemeCodeUnspsc190501.50623310': __('identifierSchemeCodeUnspsc190501.50623310'),
	'identifierSchemeCodeUnspsc190501.50623311': __('identifierSchemeCodeUnspsc190501.50623311'),
	'identifierSchemeCodeUnspsc190501.50623312': __('identifierSchemeCodeUnspsc190501.50623312'),
	'identifierSchemeCodeUnspsc190501.50623313': __('identifierSchemeCodeUnspsc190501.50623313'),
	'identifierSchemeCodeUnspsc190501.50623314': __('identifierSchemeCodeUnspsc190501.50623314'),
	'identifierSchemeCodeUnspsc190501.50623315': __('identifierSchemeCodeUnspsc190501.50623315'),
	'identifierSchemeCodeUnspsc190501.50623316': __('identifierSchemeCodeUnspsc190501.50623316'),
	'identifierSchemeCodeUnspsc190501.50623317': __('identifierSchemeCodeUnspsc190501.50623317'),
	'identifierSchemeCodeUnspsc190501.50623318': __('identifierSchemeCodeUnspsc190501.50623318'),
	'identifierSchemeCodeUnspsc190501.50623319': __('identifierSchemeCodeUnspsc190501.50623319'),
	'identifierSchemeCodeUnspsc190501.50623320': __('identifierSchemeCodeUnspsc190501.50623320'),
	'identifierSchemeCodeUnspsc190501.50623321': __('identifierSchemeCodeUnspsc190501.50623321'),
	'identifierSchemeCodeUnspsc190501.50623322': __('identifierSchemeCodeUnspsc190501.50623322'),
	'identifierSchemeCodeUnspsc190501.50623323': __('identifierSchemeCodeUnspsc190501.50623323'),
	'identifierSchemeCodeUnspsc190501.50623400': __('identifierSchemeCodeUnspsc190501.50623400'),
	'identifierSchemeCodeUnspsc190501.50623401': __('identifierSchemeCodeUnspsc190501.50623401'),
	'identifierSchemeCodeUnspsc190501.50623402': __('identifierSchemeCodeUnspsc190501.50623402'),
	'identifierSchemeCodeUnspsc190501.50623403': __('identifierSchemeCodeUnspsc190501.50623403'),
	'identifierSchemeCodeUnspsc190501.50623404': __('identifierSchemeCodeUnspsc190501.50623404'),
	'identifierSchemeCodeUnspsc190501.50623405': __('identifierSchemeCodeUnspsc190501.50623405'),
	'identifierSchemeCodeUnspsc190501.50623406': __('identifierSchemeCodeUnspsc190501.50623406'),
	'identifierSchemeCodeUnspsc190501.50623407': __('identifierSchemeCodeUnspsc190501.50623407'),
	'identifierSchemeCodeUnspsc190501.50623408': __('identifierSchemeCodeUnspsc190501.50623408'),
	'identifierSchemeCodeUnspsc190501.50623409': __('identifierSchemeCodeUnspsc190501.50623409'),
	'identifierSchemeCodeUnspsc190501.50623410': __('identifierSchemeCodeUnspsc190501.50623410'),
	'identifierSchemeCodeUnspsc190501.50623411': __('identifierSchemeCodeUnspsc190501.50623411'),
	'identifierSchemeCodeUnspsc190501.50623412': __('identifierSchemeCodeUnspsc190501.50623412'),
	'identifierSchemeCodeUnspsc190501.50623413': __('identifierSchemeCodeUnspsc190501.50623413'),
	'identifierSchemeCodeUnspsc190501.50623414': __('identifierSchemeCodeUnspsc190501.50623414'),
	'identifierSchemeCodeUnspsc190501.50623415': __('identifierSchemeCodeUnspsc190501.50623415'),
	'identifierSchemeCodeUnspsc190501.50623416': __('identifierSchemeCodeUnspsc190501.50623416'),
	'identifierSchemeCodeUnspsc190501.50623417': __('identifierSchemeCodeUnspsc190501.50623417'),
	'identifierSchemeCodeUnspsc190501.50623418': __('identifierSchemeCodeUnspsc190501.50623418'),
	'identifierSchemeCodeUnspsc190501.50623419': __('identifierSchemeCodeUnspsc190501.50623419'),
	'identifierSchemeCodeUnspsc190501.50623420': __('identifierSchemeCodeUnspsc190501.50623420'),
	'identifierSchemeCodeUnspsc190501.50623500': __('identifierSchemeCodeUnspsc190501.50623500'),
	'identifierSchemeCodeUnspsc190501.50623501': __('identifierSchemeCodeUnspsc190501.50623501'),
	'identifierSchemeCodeUnspsc190501.50623502': __('identifierSchemeCodeUnspsc190501.50623502'),
	'identifierSchemeCodeUnspsc190501.50623503': __('identifierSchemeCodeUnspsc190501.50623503'),
	'identifierSchemeCodeUnspsc190501.50623504': __('identifierSchemeCodeUnspsc190501.50623504'),
	'identifierSchemeCodeUnspsc190501.50623505': __('identifierSchemeCodeUnspsc190501.50623505'),
	'identifierSchemeCodeUnspsc190501.50623506': __('identifierSchemeCodeUnspsc190501.50623506'),
	'identifierSchemeCodeUnspsc190501.50623507': __('identifierSchemeCodeUnspsc190501.50623507'),
	'identifierSchemeCodeUnspsc190501.50623508': __('identifierSchemeCodeUnspsc190501.50623508'),
	'identifierSchemeCodeUnspsc190501.50623509': __('identifierSchemeCodeUnspsc190501.50623509'),
	'identifierSchemeCodeUnspsc190501.50623510': __('identifierSchemeCodeUnspsc190501.50623510'),
	'identifierSchemeCodeUnspsc190501.50623511': __('identifierSchemeCodeUnspsc190501.50623511'),
	'identifierSchemeCodeUnspsc190501.50623512': __('identifierSchemeCodeUnspsc190501.50623512'),
	'identifierSchemeCodeUnspsc190501.50623513': __('identifierSchemeCodeUnspsc190501.50623513'),
	'identifierSchemeCodeUnspsc190501.50623514': __('identifierSchemeCodeUnspsc190501.50623514'),
	'identifierSchemeCodeUnspsc190501.50623600': __('identifierSchemeCodeUnspsc190501.50623600'),
	'identifierSchemeCodeUnspsc190501.50623601': __('identifierSchemeCodeUnspsc190501.50623601'),
	'identifierSchemeCodeUnspsc190501.50623602': __('identifierSchemeCodeUnspsc190501.50623602'),
	'identifierSchemeCodeUnspsc190501.50623603': __('identifierSchemeCodeUnspsc190501.50623603'),
	'identifierSchemeCodeUnspsc190501.50623604': __('identifierSchemeCodeUnspsc190501.50623604'),
	'identifierSchemeCodeUnspsc190501.50623605': __('identifierSchemeCodeUnspsc190501.50623605'),
	'identifierSchemeCodeUnspsc190501.50623606': __('identifierSchemeCodeUnspsc190501.50623606'),
	'identifierSchemeCodeUnspsc190501.50623607': __('identifierSchemeCodeUnspsc190501.50623607'),
	'identifierSchemeCodeUnspsc190501.50623700': __('identifierSchemeCodeUnspsc190501.50623700'),
	'identifierSchemeCodeUnspsc190501.50623701': __('identifierSchemeCodeUnspsc190501.50623701'),
	'identifierSchemeCodeUnspsc190501.50623702': __('identifierSchemeCodeUnspsc190501.50623702'),
	'identifierSchemeCodeUnspsc190501.50623703': __('identifierSchemeCodeUnspsc190501.50623703'),
	'identifierSchemeCodeUnspsc190501.50623704': __('identifierSchemeCodeUnspsc190501.50623704'),
	'identifierSchemeCodeUnspsc190501.50623705': __('identifierSchemeCodeUnspsc190501.50623705'),
	'identifierSchemeCodeUnspsc190501.50623706': __('identifierSchemeCodeUnspsc190501.50623706'),
	'identifierSchemeCodeUnspsc190501.50623707': __('identifierSchemeCodeUnspsc190501.50623707'),
	'identifierSchemeCodeUnspsc190501.50623708': __('identifierSchemeCodeUnspsc190501.50623708'),
	'identifierSchemeCodeUnspsc190501.50623709': __('identifierSchemeCodeUnspsc190501.50623709'),
	'identifierSchemeCodeUnspsc190501.50623710': __('identifierSchemeCodeUnspsc190501.50623710'),
	'identifierSchemeCodeUnspsc190501.50623711': __('identifierSchemeCodeUnspsc190501.50623711'),
	'identifierSchemeCodeUnspsc190501.50623800': __('identifierSchemeCodeUnspsc190501.50623800'),
	'identifierSchemeCodeUnspsc190501.50623801': __('identifierSchemeCodeUnspsc190501.50623801'),
	'identifierSchemeCodeUnspsc190501.50623802': __('identifierSchemeCodeUnspsc190501.50623802'),
	'identifierSchemeCodeUnspsc190501.50623803': __('identifierSchemeCodeUnspsc190501.50623803'),
	'identifierSchemeCodeUnspsc190501.50623804': __('identifierSchemeCodeUnspsc190501.50623804'),
	'identifierSchemeCodeUnspsc190501.50623805': __('identifierSchemeCodeUnspsc190501.50623805'),
	'identifierSchemeCodeUnspsc190501.50623806': __('identifierSchemeCodeUnspsc190501.50623806'),
	'identifierSchemeCodeUnspsc190501.50623807': __('identifierSchemeCodeUnspsc190501.50623807'),
	'identifierSchemeCodeUnspsc190501.50623808': __('identifierSchemeCodeUnspsc190501.50623808'),
	'identifierSchemeCodeUnspsc190501.50623809': __('identifierSchemeCodeUnspsc190501.50623809'),
	'identifierSchemeCodeUnspsc190501.50623810': __('identifierSchemeCodeUnspsc190501.50623810'),
	'identifierSchemeCodeUnspsc190501.50623811': __('identifierSchemeCodeUnspsc190501.50623811'),
	'identifierSchemeCodeUnspsc190501.50623900': __('identifierSchemeCodeUnspsc190501.50623900'),
	'identifierSchemeCodeUnspsc190501.50623901': __('identifierSchemeCodeUnspsc190501.50623901'),
	'identifierSchemeCodeUnspsc190501.50623902': __('identifierSchemeCodeUnspsc190501.50623902'),
	'identifierSchemeCodeUnspsc190501.50623903': __('identifierSchemeCodeUnspsc190501.50623903'),
	'identifierSchemeCodeUnspsc190501.50623904': __('identifierSchemeCodeUnspsc190501.50623904'),
	'identifierSchemeCodeUnspsc190501.50623905': __('identifierSchemeCodeUnspsc190501.50623905'),
	'identifierSchemeCodeUnspsc190501.50624000': __('identifierSchemeCodeUnspsc190501.50624000'),
	'identifierSchemeCodeUnspsc190501.50624001': __('identifierSchemeCodeUnspsc190501.50624001'),
	'identifierSchemeCodeUnspsc190501.50624002': __('identifierSchemeCodeUnspsc190501.50624002'),
	'identifierSchemeCodeUnspsc190501.50624003': __('identifierSchemeCodeUnspsc190501.50624003'),
	'identifierSchemeCodeUnspsc190501.50624004': __('identifierSchemeCodeUnspsc190501.50624004'),
	'identifierSchemeCodeUnspsc190501.50624005': __('identifierSchemeCodeUnspsc190501.50624005'),
	'identifierSchemeCodeUnspsc190501.50624006': __('identifierSchemeCodeUnspsc190501.50624006'),
	'identifierSchemeCodeUnspsc190501.50624007': __('identifierSchemeCodeUnspsc190501.50624007'),
	'identifierSchemeCodeUnspsc190501.50624008': __('identifierSchemeCodeUnspsc190501.50624008'),
	'identifierSchemeCodeUnspsc190501.50624009': __('identifierSchemeCodeUnspsc190501.50624009'),
	'identifierSchemeCodeUnspsc190501.50624010': __('identifierSchemeCodeUnspsc190501.50624010'),
	'identifierSchemeCodeUnspsc190501.50624011': __('identifierSchemeCodeUnspsc190501.50624011'),
	'identifierSchemeCodeUnspsc190501.50624012': __('identifierSchemeCodeUnspsc190501.50624012'),
	'identifierSchemeCodeUnspsc190501.50624013': __('identifierSchemeCodeUnspsc190501.50624013'),
	'identifierSchemeCodeUnspsc190501.50624014': __('identifierSchemeCodeUnspsc190501.50624014'),
	'identifierSchemeCodeUnspsc190501.50624015': __('identifierSchemeCodeUnspsc190501.50624015'),
	'identifierSchemeCodeUnspsc190501.50624016': __('identifierSchemeCodeUnspsc190501.50624016'),
	'identifierSchemeCodeUnspsc190501.50624017': __('identifierSchemeCodeUnspsc190501.50624017'),
	'identifierSchemeCodeUnspsc190501.50624018': __('identifierSchemeCodeUnspsc190501.50624018'),
	'identifierSchemeCodeUnspsc190501.50624019': __('identifierSchemeCodeUnspsc190501.50624019'),
	'identifierSchemeCodeUnspsc190501.50624020': __('identifierSchemeCodeUnspsc190501.50624020'),
	'identifierSchemeCodeUnspsc190501.50624021': __('identifierSchemeCodeUnspsc190501.50624021'),
	'identifierSchemeCodeUnspsc190501.50624022': __('identifierSchemeCodeUnspsc190501.50624022'),
	'identifierSchemeCodeUnspsc190501.50624023': __('identifierSchemeCodeUnspsc190501.50624023'),
	'identifierSchemeCodeUnspsc190501.50624024': __('identifierSchemeCodeUnspsc190501.50624024'),
	'identifierSchemeCodeUnspsc190501.50624025': __('identifierSchemeCodeUnspsc190501.50624025'),
	'identifierSchemeCodeUnspsc190501.50624026': __('identifierSchemeCodeUnspsc190501.50624026'),
	'identifierSchemeCodeUnspsc190501.50624027': __('identifierSchemeCodeUnspsc190501.50624027'),
	'identifierSchemeCodeUnspsc190501.50624100': __('identifierSchemeCodeUnspsc190501.50624100'),
	'identifierSchemeCodeUnspsc190501.50624101': __('identifierSchemeCodeUnspsc190501.50624101'),
	'identifierSchemeCodeUnspsc190501.50624102': __('identifierSchemeCodeUnspsc190501.50624102'),
	'identifierSchemeCodeUnspsc190501.50624200': __('identifierSchemeCodeUnspsc190501.50624200'),
	'identifierSchemeCodeUnspsc190501.50624201': __('identifierSchemeCodeUnspsc190501.50624201'),
	'identifierSchemeCodeUnspsc190501.50624202': __('identifierSchemeCodeUnspsc190501.50624202'),
	'identifierSchemeCodeUnspsc190501.50624203': __('identifierSchemeCodeUnspsc190501.50624203'),
	'identifierSchemeCodeUnspsc190501.50624204': __('identifierSchemeCodeUnspsc190501.50624204'),
	'identifierSchemeCodeUnspsc190501.50624205': __('identifierSchemeCodeUnspsc190501.50624205'),
	'identifierSchemeCodeUnspsc190501.50624206': __('identifierSchemeCodeUnspsc190501.50624206'),
	'identifierSchemeCodeUnspsc190501.50624300': __('identifierSchemeCodeUnspsc190501.50624300'),
	'identifierSchemeCodeUnspsc190501.50624301': __('identifierSchemeCodeUnspsc190501.50624301'),
	'identifierSchemeCodeUnspsc190501.50624302': __('identifierSchemeCodeUnspsc190501.50624302'),
	'identifierSchemeCodeUnspsc190501.50624303': __('identifierSchemeCodeUnspsc190501.50624303'),
	'identifierSchemeCodeUnspsc190501.50624304': __('identifierSchemeCodeUnspsc190501.50624304'),
	'identifierSchemeCodeUnspsc190501.50624305': __('identifierSchemeCodeUnspsc190501.50624305'),
	'identifierSchemeCodeUnspsc190501.50624306': __('identifierSchemeCodeUnspsc190501.50624306'),
	'identifierSchemeCodeUnspsc190501.50624400': __('identifierSchemeCodeUnspsc190501.50624400'),
	'identifierSchemeCodeUnspsc190501.50624401': __('identifierSchemeCodeUnspsc190501.50624401'),
	'identifierSchemeCodeUnspsc190501.50624402': __('identifierSchemeCodeUnspsc190501.50624402'),
	'identifierSchemeCodeUnspsc190501.50624403': __('identifierSchemeCodeUnspsc190501.50624403'),
	'identifierSchemeCodeUnspsc190501.50624404': __('identifierSchemeCodeUnspsc190501.50624404'),
	'identifierSchemeCodeUnspsc190501.50624405': __('identifierSchemeCodeUnspsc190501.50624405'),
	'identifierSchemeCodeUnspsc190501.50624406': __('identifierSchemeCodeUnspsc190501.50624406'),
	'identifierSchemeCodeUnspsc190501.50624407': __('identifierSchemeCodeUnspsc190501.50624407'),
	'identifierSchemeCodeUnspsc190501.50624408': __('identifierSchemeCodeUnspsc190501.50624408'),
	'identifierSchemeCodeUnspsc190501.50624500': __('identifierSchemeCodeUnspsc190501.50624500'),
	'identifierSchemeCodeUnspsc190501.50624501': __('identifierSchemeCodeUnspsc190501.50624501'),
	'identifierSchemeCodeUnspsc190501.50624502': __('identifierSchemeCodeUnspsc190501.50624502'),
	'identifierSchemeCodeUnspsc190501.50624503': __('identifierSchemeCodeUnspsc190501.50624503'),
	'identifierSchemeCodeUnspsc190501.50624504': __('identifierSchemeCodeUnspsc190501.50624504'),
	'identifierSchemeCodeUnspsc190501.50624505': __('identifierSchemeCodeUnspsc190501.50624505'),
	'identifierSchemeCodeUnspsc190501.50624506': __('identifierSchemeCodeUnspsc190501.50624506'),
	'identifierSchemeCodeUnspsc190501.50624507': __('identifierSchemeCodeUnspsc190501.50624507'),
	'identifierSchemeCodeUnspsc190501.50624508': __('identifierSchemeCodeUnspsc190501.50624508'),
	'identifierSchemeCodeUnspsc190501.50624509': __('identifierSchemeCodeUnspsc190501.50624509'),
	'identifierSchemeCodeUnspsc190501.50624510': __('identifierSchemeCodeUnspsc190501.50624510'),
	'identifierSchemeCodeUnspsc190501.50624511': __('identifierSchemeCodeUnspsc190501.50624511'),
	'identifierSchemeCodeUnspsc190501.50624512': __('identifierSchemeCodeUnspsc190501.50624512'),
	'identifierSchemeCodeUnspsc190501.50624513': __('identifierSchemeCodeUnspsc190501.50624513'),
	'identifierSchemeCodeUnspsc190501.50624514': __('identifierSchemeCodeUnspsc190501.50624514'),
	'identifierSchemeCodeUnspsc190501.50624515': __('identifierSchemeCodeUnspsc190501.50624515'),
	'identifierSchemeCodeUnspsc190501.50624516': __('identifierSchemeCodeUnspsc190501.50624516'),
	'identifierSchemeCodeUnspsc190501.50624517': __('identifierSchemeCodeUnspsc190501.50624517'),
	'identifierSchemeCodeUnspsc190501.50624518': __('identifierSchemeCodeUnspsc190501.50624518'),
	'identifierSchemeCodeUnspsc190501.50624519': __('identifierSchemeCodeUnspsc190501.50624519'),
	'identifierSchemeCodeUnspsc190501.50624520': __('identifierSchemeCodeUnspsc190501.50624520'),
	'identifierSchemeCodeUnspsc190501.50624521': __('identifierSchemeCodeUnspsc190501.50624521'),
	'identifierSchemeCodeUnspsc190501.50624522': __('identifierSchemeCodeUnspsc190501.50624522'),
	'identifierSchemeCodeUnspsc190501.50624523': __('identifierSchemeCodeUnspsc190501.50624523'),
	'identifierSchemeCodeUnspsc190501.50624600': __('identifierSchemeCodeUnspsc190501.50624600'),
	'identifierSchemeCodeUnspsc190501.50624601': __('identifierSchemeCodeUnspsc190501.50624601'),
	'identifierSchemeCodeUnspsc190501.50624602': __('identifierSchemeCodeUnspsc190501.50624602'),
	'identifierSchemeCodeUnspsc190501.50624603': __('identifierSchemeCodeUnspsc190501.50624603'),
	'identifierSchemeCodeUnspsc190501.50624604': __('identifierSchemeCodeUnspsc190501.50624604'),
	'identifierSchemeCodeUnspsc190501.50624605': __('identifierSchemeCodeUnspsc190501.50624605'),
	'identifierSchemeCodeUnspsc190501.50624606': __('identifierSchemeCodeUnspsc190501.50624606'),
	'identifierSchemeCodeUnspsc190501.50624700': __('identifierSchemeCodeUnspsc190501.50624700'),
	'identifierSchemeCodeUnspsc190501.50624701': __('identifierSchemeCodeUnspsc190501.50624701'),
	'identifierSchemeCodeUnspsc190501.50624702': __('identifierSchemeCodeUnspsc190501.50624702'),
	'identifierSchemeCodeUnspsc190501.50624703': __('identifierSchemeCodeUnspsc190501.50624703'),
	'identifierSchemeCodeUnspsc190501.50624704': __('identifierSchemeCodeUnspsc190501.50624704'),
	'identifierSchemeCodeUnspsc190501.50624705': __('identifierSchemeCodeUnspsc190501.50624705'),
	'identifierSchemeCodeUnspsc190501.50624706': __('identifierSchemeCodeUnspsc190501.50624706'),
	'identifierSchemeCodeUnspsc190501.50624707': __('identifierSchemeCodeUnspsc190501.50624707'),
	'identifierSchemeCodeUnspsc190501.50624708': __('identifierSchemeCodeUnspsc190501.50624708'),
	'identifierSchemeCodeUnspsc190501.50624709': __('identifierSchemeCodeUnspsc190501.50624709'),
	'identifierSchemeCodeUnspsc190501.50624710': __('identifierSchemeCodeUnspsc190501.50624710'),
	'identifierSchemeCodeUnspsc190501.50624711': __('identifierSchemeCodeUnspsc190501.50624711'),
	'identifierSchemeCodeUnspsc190501.50624712': __('identifierSchemeCodeUnspsc190501.50624712'),
	'identifierSchemeCodeUnspsc190501.50624713': __('identifierSchemeCodeUnspsc190501.50624713'),
	'identifierSchemeCodeUnspsc190501.50624714': __('identifierSchemeCodeUnspsc190501.50624714'),
	'identifierSchemeCodeUnspsc190501.50624715': __('identifierSchemeCodeUnspsc190501.50624715'),
	'identifierSchemeCodeUnspsc190501.50624716': __('identifierSchemeCodeUnspsc190501.50624716'),
	'identifierSchemeCodeUnspsc190501.50624717': __('identifierSchemeCodeUnspsc190501.50624717'),
	'identifierSchemeCodeUnspsc190501.50624718': __('identifierSchemeCodeUnspsc190501.50624718'),
	'identifierSchemeCodeUnspsc190501.50624719': __('identifierSchemeCodeUnspsc190501.50624719'),
	'identifierSchemeCodeUnspsc190501.50624720': __('identifierSchemeCodeUnspsc190501.50624720'),
	'identifierSchemeCodeUnspsc190501.50624721': __('identifierSchemeCodeUnspsc190501.50624721'),
	'identifierSchemeCodeUnspsc190501.50624800': __('identifierSchemeCodeUnspsc190501.50624800'),
	'identifierSchemeCodeUnspsc190501.50624801': __('identifierSchemeCodeUnspsc190501.50624801'),
	'identifierSchemeCodeUnspsc190501.50624802': __('identifierSchemeCodeUnspsc190501.50624802'),
	'identifierSchemeCodeUnspsc190501.50624803': __('identifierSchemeCodeUnspsc190501.50624803'),
	'identifierSchemeCodeUnspsc190501.50624804': __('identifierSchemeCodeUnspsc190501.50624804'),
	'identifierSchemeCodeUnspsc190501.50624805': __('identifierSchemeCodeUnspsc190501.50624805'),
	'identifierSchemeCodeUnspsc190501.50624806': __('identifierSchemeCodeUnspsc190501.50624806'),
	'identifierSchemeCodeUnspsc190501.50624900': __('identifierSchemeCodeUnspsc190501.50624900'),
	'identifierSchemeCodeUnspsc190501.50624901': __('identifierSchemeCodeUnspsc190501.50624901'),
	'identifierSchemeCodeUnspsc190501.50624902': __('identifierSchemeCodeUnspsc190501.50624902'),
	'identifierSchemeCodeUnspsc190501.50624903': __('identifierSchemeCodeUnspsc190501.50624903'),
	'identifierSchemeCodeUnspsc190501.50624904': __('identifierSchemeCodeUnspsc190501.50624904'),
	'identifierSchemeCodeUnspsc190501.50625000': __('identifierSchemeCodeUnspsc190501.50625000'),
	'identifierSchemeCodeUnspsc190501.50625001': __('identifierSchemeCodeUnspsc190501.50625001'),
	'identifierSchemeCodeUnspsc190501.50625002': __('identifierSchemeCodeUnspsc190501.50625002'),
	'identifierSchemeCodeUnspsc190501.50625003': __('identifierSchemeCodeUnspsc190501.50625003'),
	'identifierSchemeCodeUnspsc190501.50625004': __('identifierSchemeCodeUnspsc190501.50625004'),
	'identifierSchemeCodeUnspsc190501.50625005': __('identifierSchemeCodeUnspsc190501.50625005'),
	'identifierSchemeCodeUnspsc190501.50625006': __('identifierSchemeCodeUnspsc190501.50625006'),
	'identifierSchemeCodeUnspsc190501.50625007': __('identifierSchemeCodeUnspsc190501.50625007'),
	'identifierSchemeCodeUnspsc190501.50625100': __('identifierSchemeCodeUnspsc190501.50625100'),
	'identifierSchemeCodeUnspsc190501.50625101': __('identifierSchemeCodeUnspsc190501.50625101'),
	'identifierSchemeCodeUnspsc190501.50625102': __('identifierSchemeCodeUnspsc190501.50625102'),
	'identifierSchemeCodeUnspsc190501.50625103': __('identifierSchemeCodeUnspsc190501.50625103'),
	'identifierSchemeCodeUnspsc190501.50625104': __('identifierSchemeCodeUnspsc190501.50625104'),
	'identifierSchemeCodeUnspsc190501.50625105': __('identifierSchemeCodeUnspsc190501.50625105'),
	'identifierSchemeCodeUnspsc190501.50625106': __('identifierSchemeCodeUnspsc190501.50625106'),
	'identifierSchemeCodeUnspsc190501.50625107': __('identifierSchemeCodeUnspsc190501.50625107'),
	'identifierSchemeCodeUnspsc190501.50625108': __('identifierSchemeCodeUnspsc190501.50625108'),
	'identifierSchemeCodeUnspsc190501.50625109': __('identifierSchemeCodeUnspsc190501.50625109'),
	'identifierSchemeCodeUnspsc190501.50625110': __('identifierSchemeCodeUnspsc190501.50625110'),
	'identifierSchemeCodeUnspsc190501.50625111': __('identifierSchemeCodeUnspsc190501.50625111'),
	'identifierSchemeCodeUnspsc190501.50625112': __('identifierSchemeCodeUnspsc190501.50625112'),
	'identifierSchemeCodeUnspsc190501.50625113': __('identifierSchemeCodeUnspsc190501.50625113'),
	'identifierSchemeCodeUnspsc190501.50625114': __('identifierSchemeCodeUnspsc190501.50625114'),
	'identifierSchemeCodeUnspsc190501.50625115': __('identifierSchemeCodeUnspsc190501.50625115'),
	'identifierSchemeCodeUnspsc190501.50625116': __('identifierSchemeCodeUnspsc190501.50625116'),
	'identifierSchemeCodeUnspsc190501.50625117': __('identifierSchemeCodeUnspsc190501.50625117'),
	'identifierSchemeCodeUnspsc190501.50625118': __('identifierSchemeCodeUnspsc190501.50625118'),
	'identifierSchemeCodeUnspsc190501.50625119': __('identifierSchemeCodeUnspsc190501.50625119'),
	'identifierSchemeCodeUnspsc190501.50625120': __('identifierSchemeCodeUnspsc190501.50625120'),
	'identifierSchemeCodeUnspsc190501.50625121': __('identifierSchemeCodeUnspsc190501.50625121'),
	'identifierSchemeCodeUnspsc190501.50625200': __('identifierSchemeCodeUnspsc190501.50625200'),
	'identifierSchemeCodeUnspsc190501.50625201': __('identifierSchemeCodeUnspsc190501.50625201'),
	'identifierSchemeCodeUnspsc190501.50625202': __('identifierSchemeCodeUnspsc190501.50625202'),
	'identifierSchemeCodeUnspsc190501.50625203': __('identifierSchemeCodeUnspsc190501.50625203'),
	'identifierSchemeCodeUnspsc190501.50625204': __('identifierSchemeCodeUnspsc190501.50625204'),
	'identifierSchemeCodeUnspsc190501.50625205': __('identifierSchemeCodeUnspsc190501.50625205'),
	'identifierSchemeCodeUnspsc190501.50625206': __('identifierSchemeCodeUnspsc190501.50625206'),
	'identifierSchemeCodeUnspsc190501.50625300': __('identifierSchemeCodeUnspsc190501.50625300'),
	'identifierSchemeCodeUnspsc190501.50625301': __('identifierSchemeCodeUnspsc190501.50625301'),
	'identifierSchemeCodeUnspsc190501.50625302': __('identifierSchemeCodeUnspsc190501.50625302'),
	'identifierSchemeCodeUnspsc190501.50625303': __('identifierSchemeCodeUnspsc190501.50625303'),
	'identifierSchemeCodeUnspsc190501.50625304': __('identifierSchemeCodeUnspsc190501.50625304'),
	'identifierSchemeCodeUnspsc190501.50625305': __('identifierSchemeCodeUnspsc190501.50625305'),
	'identifierSchemeCodeUnspsc190501.50625306': __('identifierSchemeCodeUnspsc190501.50625306'),
	'identifierSchemeCodeUnspsc190501.50625400': __('identifierSchemeCodeUnspsc190501.50625400'),
	'identifierSchemeCodeUnspsc190501.50625401': __('identifierSchemeCodeUnspsc190501.50625401'),
	'identifierSchemeCodeUnspsc190501.50625402': __('identifierSchemeCodeUnspsc190501.50625402'),
	'identifierSchemeCodeUnspsc190501.50625403': __('identifierSchemeCodeUnspsc190501.50625403'),
	'identifierSchemeCodeUnspsc190501.50625404': __('identifierSchemeCodeUnspsc190501.50625404'),
	'identifierSchemeCodeUnspsc190501.50625405': __('identifierSchemeCodeUnspsc190501.50625405'),
	'identifierSchemeCodeUnspsc190501.50625406': __('identifierSchemeCodeUnspsc190501.50625406'),
	'identifierSchemeCodeUnspsc190501.50625407': __('identifierSchemeCodeUnspsc190501.50625407'),
	'identifierSchemeCodeUnspsc190501.50625408': __('identifierSchemeCodeUnspsc190501.50625408'),
	'identifierSchemeCodeUnspsc190501.50625409': __('identifierSchemeCodeUnspsc190501.50625409'),
	'identifierSchemeCodeUnspsc190501.50625410': __('identifierSchemeCodeUnspsc190501.50625410'),
	'identifierSchemeCodeUnspsc190501.50625411': __('identifierSchemeCodeUnspsc190501.50625411'),
	'identifierSchemeCodeUnspsc190501.50625412': __('identifierSchemeCodeUnspsc190501.50625412'),
	'identifierSchemeCodeUnspsc190501.50625413': __('identifierSchemeCodeUnspsc190501.50625413'),
	'identifierSchemeCodeUnspsc190501.50625414': __('identifierSchemeCodeUnspsc190501.50625414'),
	'identifierSchemeCodeUnspsc190501.50625415': __('identifierSchemeCodeUnspsc190501.50625415'),
	'identifierSchemeCodeUnspsc190501.50625416': __('identifierSchemeCodeUnspsc190501.50625416'),
	'identifierSchemeCodeUnspsc190501.50625417': __('identifierSchemeCodeUnspsc190501.50625417'),
	'identifierSchemeCodeUnspsc190501.50625418': __('identifierSchemeCodeUnspsc190501.50625418'),
	'identifierSchemeCodeUnspsc190501.50625419': __('identifierSchemeCodeUnspsc190501.50625419'),
	'identifierSchemeCodeUnspsc190501.50625420': __('identifierSchemeCodeUnspsc190501.50625420'),
	'identifierSchemeCodeUnspsc190501.50625421': __('identifierSchemeCodeUnspsc190501.50625421'),
	'identifierSchemeCodeUnspsc190501.50625422': __('identifierSchemeCodeUnspsc190501.50625422'),
	'identifierSchemeCodeUnspsc190501.50625423': __('identifierSchemeCodeUnspsc190501.50625423'),
	'identifierSchemeCodeUnspsc190501.50625424': __('identifierSchemeCodeUnspsc190501.50625424'),
	'identifierSchemeCodeUnspsc190501.50625425': __('identifierSchemeCodeUnspsc190501.50625425'),
	'identifierSchemeCodeUnspsc190501.50625426': __('identifierSchemeCodeUnspsc190501.50625426'),
	'identifierSchemeCodeUnspsc190501.50625427': __('identifierSchemeCodeUnspsc190501.50625427'),
	'identifierSchemeCodeUnspsc190501.50625428': __('identifierSchemeCodeUnspsc190501.50625428'),
	'identifierSchemeCodeUnspsc190501.50625429': __('identifierSchemeCodeUnspsc190501.50625429'),
	'identifierSchemeCodeUnspsc190501.50625430': __('identifierSchemeCodeUnspsc190501.50625430'),
	'identifierSchemeCodeUnspsc190501.50625431': __('identifierSchemeCodeUnspsc190501.50625431'),
	'identifierSchemeCodeUnspsc190501.50625432': __('identifierSchemeCodeUnspsc190501.50625432'),
	'identifierSchemeCodeUnspsc190501.50625433': __('identifierSchemeCodeUnspsc190501.50625433'),
	'identifierSchemeCodeUnspsc190501.50625434': __('identifierSchemeCodeUnspsc190501.50625434'),
	'identifierSchemeCodeUnspsc190501.50625435': __('identifierSchemeCodeUnspsc190501.50625435'),
	'identifierSchemeCodeUnspsc190501.50625436': __('identifierSchemeCodeUnspsc190501.50625436'),
	'identifierSchemeCodeUnspsc190501.50625437': __('identifierSchemeCodeUnspsc190501.50625437'),
	'identifierSchemeCodeUnspsc190501.50625500': __('identifierSchemeCodeUnspsc190501.50625500'),
	'identifierSchemeCodeUnspsc190501.50625501': __('identifierSchemeCodeUnspsc190501.50625501'),
	'identifierSchemeCodeUnspsc190501.50625502': __('identifierSchemeCodeUnspsc190501.50625502'),
	'identifierSchemeCodeUnspsc190501.50625503': __('identifierSchemeCodeUnspsc190501.50625503'),
	'identifierSchemeCodeUnspsc190501.50625504': __('identifierSchemeCodeUnspsc190501.50625504'),
	'identifierSchemeCodeUnspsc190501.50625505': __('identifierSchemeCodeUnspsc190501.50625505'),
	'identifierSchemeCodeUnspsc190501.50625506': __('identifierSchemeCodeUnspsc190501.50625506'),
	'identifierSchemeCodeUnspsc190501.50625507': __('identifierSchemeCodeUnspsc190501.50625507'),
	'identifierSchemeCodeUnspsc190501.50625508': __('identifierSchemeCodeUnspsc190501.50625508'),
	'identifierSchemeCodeUnspsc190501.50625600': __('identifierSchemeCodeUnspsc190501.50625600'),
	'identifierSchemeCodeUnspsc190501.50625601': __('identifierSchemeCodeUnspsc190501.50625601'),
	'identifierSchemeCodeUnspsc190501.50625602': __('identifierSchemeCodeUnspsc190501.50625602'),
	'identifierSchemeCodeUnspsc190501.50625603': __('identifierSchemeCodeUnspsc190501.50625603'),
	'identifierSchemeCodeUnspsc190501.50625604': __('identifierSchemeCodeUnspsc190501.50625604'),
	'identifierSchemeCodeUnspsc190501.50625700': __('identifierSchemeCodeUnspsc190501.50625700'),
	'identifierSchemeCodeUnspsc190501.50625701': __('identifierSchemeCodeUnspsc190501.50625701'),
	'identifierSchemeCodeUnspsc190501.50625702': __('identifierSchemeCodeUnspsc190501.50625702'),
	'identifierSchemeCodeUnspsc190501.50625703': __('identifierSchemeCodeUnspsc190501.50625703'),
	'identifierSchemeCodeUnspsc190501.50625704': __('identifierSchemeCodeUnspsc190501.50625704'),
	'identifierSchemeCodeUnspsc190501.50625705': __('identifierSchemeCodeUnspsc190501.50625705'),
	'identifierSchemeCodeUnspsc190501.50625706': __('identifierSchemeCodeUnspsc190501.50625706'),
	'identifierSchemeCodeUnspsc190501.50625707': __('identifierSchemeCodeUnspsc190501.50625707'),
	'identifierSchemeCodeUnspsc190501.50625708': __('identifierSchemeCodeUnspsc190501.50625708'),
	'identifierSchemeCodeUnspsc190501.50625709': __('identifierSchemeCodeUnspsc190501.50625709'),
	'identifierSchemeCodeUnspsc190501.50625710': __('identifierSchemeCodeUnspsc190501.50625710'),
	'identifierSchemeCodeUnspsc190501.50625711': __('identifierSchemeCodeUnspsc190501.50625711'),
	'identifierSchemeCodeUnspsc190501.50625712': __('identifierSchemeCodeUnspsc190501.50625712'),
	'identifierSchemeCodeUnspsc190501.50625713': __('identifierSchemeCodeUnspsc190501.50625713'),
	'identifierSchemeCodeUnspsc190501.50625714': __('identifierSchemeCodeUnspsc190501.50625714'),
	'identifierSchemeCodeUnspsc190501.50625715': __('identifierSchemeCodeUnspsc190501.50625715'),
	'identifierSchemeCodeUnspsc190501.50625716': __('identifierSchemeCodeUnspsc190501.50625716'),
	'identifierSchemeCodeUnspsc190501.50625800': __('identifierSchemeCodeUnspsc190501.50625800'),
	'identifierSchemeCodeUnspsc190501.50625801': __('identifierSchemeCodeUnspsc190501.50625801'),
	'identifierSchemeCodeUnspsc190501.50625802': __('identifierSchemeCodeUnspsc190501.50625802'),
	'identifierSchemeCodeUnspsc190501.50625803': __('identifierSchemeCodeUnspsc190501.50625803'),
	'identifierSchemeCodeUnspsc190501.50625804': __('identifierSchemeCodeUnspsc190501.50625804'),
	'identifierSchemeCodeUnspsc190501.50625805': __('identifierSchemeCodeUnspsc190501.50625805'),
	'identifierSchemeCodeUnspsc190501.50625806': __('identifierSchemeCodeUnspsc190501.50625806'),
	'identifierSchemeCodeUnspsc190501.50625807': __('identifierSchemeCodeUnspsc190501.50625807'),
	'identifierSchemeCodeUnspsc190501.50625808': __('identifierSchemeCodeUnspsc190501.50625808'),
	'identifierSchemeCodeUnspsc190501.50625809': __('identifierSchemeCodeUnspsc190501.50625809'),
	'identifierSchemeCodeUnspsc190501.50625810': __('identifierSchemeCodeUnspsc190501.50625810'),
	'identifierSchemeCodeUnspsc190501.50625811': __('identifierSchemeCodeUnspsc190501.50625811'),
	'identifierSchemeCodeUnspsc190501.50625900': __('identifierSchemeCodeUnspsc190501.50625900'),
	'identifierSchemeCodeUnspsc190501.50625901': __('identifierSchemeCodeUnspsc190501.50625901'),
	'identifierSchemeCodeUnspsc190501.50625902': __('identifierSchemeCodeUnspsc190501.50625902'),
	'identifierSchemeCodeUnspsc190501.50625903': __('identifierSchemeCodeUnspsc190501.50625903'),
	'identifierSchemeCodeUnspsc190501.50625904': __('identifierSchemeCodeUnspsc190501.50625904'),
	'identifierSchemeCodeUnspsc190501.50626000': __('identifierSchemeCodeUnspsc190501.50626000'),
	'identifierSchemeCodeUnspsc190501.50626001': __('identifierSchemeCodeUnspsc190501.50626001'),
	'identifierSchemeCodeUnspsc190501.50626002': __('identifierSchemeCodeUnspsc190501.50626002'),
	'identifierSchemeCodeUnspsc190501.50626003': __('identifierSchemeCodeUnspsc190501.50626003'),
	'identifierSchemeCodeUnspsc190501.50626004': __('identifierSchemeCodeUnspsc190501.50626004'),
	'identifierSchemeCodeUnspsc190501.50626005': __('identifierSchemeCodeUnspsc190501.50626005'),
	'identifierSchemeCodeUnspsc190501.50626006': __('identifierSchemeCodeUnspsc190501.50626006'),
	'identifierSchemeCodeUnspsc190501.50626007': __('identifierSchemeCodeUnspsc190501.50626007'),
	'identifierSchemeCodeUnspsc190501.50626008': __('identifierSchemeCodeUnspsc190501.50626008'),
	'identifierSchemeCodeUnspsc190501.50626009': __('identifierSchemeCodeUnspsc190501.50626009'),
	'identifierSchemeCodeUnspsc190501.50626010': __('identifierSchemeCodeUnspsc190501.50626010'),
	'identifierSchemeCodeUnspsc190501.50626011': __('identifierSchemeCodeUnspsc190501.50626011'),
	'identifierSchemeCodeUnspsc190501.50626012': __('identifierSchemeCodeUnspsc190501.50626012'),
	'identifierSchemeCodeUnspsc190501.50626013': __('identifierSchemeCodeUnspsc190501.50626013'),
	'identifierSchemeCodeUnspsc190501.50626014': __('identifierSchemeCodeUnspsc190501.50626014'),
	'identifierSchemeCodeUnspsc190501.50626015': __('identifierSchemeCodeUnspsc190501.50626015'),
	'identifierSchemeCodeUnspsc190501.50626100': __('identifierSchemeCodeUnspsc190501.50626100'),
	'identifierSchemeCodeUnspsc190501.50626101': __('identifierSchemeCodeUnspsc190501.50626101'),
	'identifierSchemeCodeUnspsc190501.50626102': __('identifierSchemeCodeUnspsc190501.50626102'),
	'identifierSchemeCodeUnspsc190501.50626103': __('identifierSchemeCodeUnspsc190501.50626103'),
	'identifierSchemeCodeUnspsc190501.50626104': __('identifierSchemeCodeUnspsc190501.50626104'),
	'identifierSchemeCodeUnspsc190501.50626105': __('identifierSchemeCodeUnspsc190501.50626105'),
	'identifierSchemeCodeUnspsc190501.50626106': __('identifierSchemeCodeUnspsc190501.50626106'),
	'identifierSchemeCodeUnspsc190501.50626107': __('identifierSchemeCodeUnspsc190501.50626107'),
	'identifierSchemeCodeUnspsc190501.50626108': __('identifierSchemeCodeUnspsc190501.50626108'),
	'identifierSchemeCodeUnspsc190501.50626109': __('identifierSchemeCodeUnspsc190501.50626109'),
	'identifierSchemeCodeUnspsc190501.50626110': __('identifierSchemeCodeUnspsc190501.50626110'),
	'identifierSchemeCodeUnspsc190501.50626111': __('identifierSchemeCodeUnspsc190501.50626111'),
	'identifierSchemeCodeUnspsc190501.50626112': __('identifierSchemeCodeUnspsc190501.50626112'),
	'identifierSchemeCodeUnspsc190501.50626113': __('identifierSchemeCodeUnspsc190501.50626113'),
	'identifierSchemeCodeUnspsc190501.50626114': __('identifierSchemeCodeUnspsc190501.50626114'),
	'identifierSchemeCodeUnspsc190501.50626115': __('identifierSchemeCodeUnspsc190501.50626115'),
	'identifierSchemeCodeUnspsc190501.50626116': __('identifierSchemeCodeUnspsc190501.50626116'),
	'identifierSchemeCodeUnspsc190501.50626117': __('identifierSchemeCodeUnspsc190501.50626117'),
	'identifierSchemeCodeUnspsc190501.50626118': __('identifierSchemeCodeUnspsc190501.50626118'),
	'identifierSchemeCodeUnspsc190501.50626119': __('identifierSchemeCodeUnspsc190501.50626119'),
	'identifierSchemeCodeUnspsc190501.50626120': __('identifierSchemeCodeUnspsc190501.50626120'),
	'identifierSchemeCodeUnspsc190501.50626121': __('identifierSchemeCodeUnspsc190501.50626121'),
	'identifierSchemeCodeUnspsc190501.50626122': __('identifierSchemeCodeUnspsc190501.50626122'),
	'identifierSchemeCodeUnspsc190501.50626123': __('identifierSchemeCodeUnspsc190501.50626123'),
	'identifierSchemeCodeUnspsc190501.50626124': __('identifierSchemeCodeUnspsc190501.50626124'),
	'identifierSchemeCodeUnspsc190501.50626125': __('identifierSchemeCodeUnspsc190501.50626125'),
	'identifierSchemeCodeUnspsc190501.50626126': __('identifierSchemeCodeUnspsc190501.50626126'),
	'identifierSchemeCodeUnspsc190501.50626127': __('identifierSchemeCodeUnspsc190501.50626127'),
	'identifierSchemeCodeUnspsc190501.50626128': __('identifierSchemeCodeUnspsc190501.50626128'),
	'identifierSchemeCodeUnspsc190501.50626129': __('identifierSchemeCodeUnspsc190501.50626129'),
	'identifierSchemeCodeUnspsc190501.50626200': __('identifierSchemeCodeUnspsc190501.50626200'),
	'identifierSchemeCodeUnspsc190501.50626201': __('identifierSchemeCodeUnspsc190501.50626201'),
	'identifierSchemeCodeUnspsc190501.50626202': __('identifierSchemeCodeUnspsc190501.50626202'),
	'identifierSchemeCodeUnspsc190501.50626203': __('identifierSchemeCodeUnspsc190501.50626203'),
	'identifierSchemeCodeUnspsc190501.50626204': __('identifierSchemeCodeUnspsc190501.50626204'),
	'identifierSchemeCodeUnspsc190501.50626205': __('identifierSchemeCodeUnspsc190501.50626205'),
	'identifierSchemeCodeUnspsc190501.50626206': __('identifierSchemeCodeUnspsc190501.50626206'),
	'identifierSchemeCodeUnspsc190501.50626207': __('identifierSchemeCodeUnspsc190501.50626207'),
	'identifierSchemeCodeUnspsc190501.50626208': __('identifierSchemeCodeUnspsc190501.50626208'),
	'identifierSchemeCodeUnspsc190501.50626209': __('identifierSchemeCodeUnspsc190501.50626209'),
	'identifierSchemeCodeUnspsc190501.50626210': __('identifierSchemeCodeUnspsc190501.50626210'),
	'identifierSchemeCodeUnspsc190501.50626211': __('identifierSchemeCodeUnspsc190501.50626211'),
	'identifierSchemeCodeUnspsc190501.50626212': __('identifierSchemeCodeUnspsc190501.50626212'),
	'identifierSchemeCodeUnspsc190501.50626213': __('identifierSchemeCodeUnspsc190501.50626213'),
	'identifierSchemeCodeUnspsc190501.50626214': __('identifierSchemeCodeUnspsc190501.50626214'),
	'identifierSchemeCodeUnspsc190501.50626215': __('identifierSchemeCodeUnspsc190501.50626215'),
	'identifierSchemeCodeUnspsc190501.50626216': __('identifierSchemeCodeUnspsc190501.50626216'),
	'identifierSchemeCodeUnspsc190501.50626217': __('identifierSchemeCodeUnspsc190501.50626217'),
	'identifierSchemeCodeUnspsc190501.50626218': __('identifierSchemeCodeUnspsc190501.50626218'),
	'identifierSchemeCodeUnspsc190501.50626219': __('identifierSchemeCodeUnspsc190501.50626219'),
	'identifierSchemeCodeUnspsc190501.50626220': __('identifierSchemeCodeUnspsc190501.50626220'),
	'identifierSchemeCodeUnspsc190501.50626300': __('identifierSchemeCodeUnspsc190501.50626300'),
	'identifierSchemeCodeUnspsc190501.50626301': __('identifierSchemeCodeUnspsc190501.50626301'),
	'identifierSchemeCodeUnspsc190501.50626302': __('identifierSchemeCodeUnspsc190501.50626302'),
	'identifierSchemeCodeUnspsc190501.50626303': __('identifierSchemeCodeUnspsc190501.50626303'),
	'identifierSchemeCodeUnspsc190501.50626304': __('identifierSchemeCodeUnspsc190501.50626304'),
	'identifierSchemeCodeUnspsc190501.50626305': __('identifierSchemeCodeUnspsc190501.50626305'),
	'identifierSchemeCodeUnspsc190501.50626306': __('identifierSchemeCodeUnspsc190501.50626306'),
	'identifierSchemeCodeUnspsc190501.50626307': __('identifierSchemeCodeUnspsc190501.50626307'),
	'identifierSchemeCodeUnspsc190501.50626308': __('identifierSchemeCodeUnspsc190501.50626308'),
	'identifierSchemeCodeUnspsc190501.50626309': __('identifierSchemeCodeUnspsc190501.50626309'),
	'identifierSchemeCodeUnspsc190501.50626310': __('identifierSchemeCodeUnspsc190501.50626310'),
	'identifierSchemeCodeUnspsc190501.50626311': __('identifierSchemeCodeUnspsc190501.50626311'),
	'identifierSchemeCodeUnspsc190501.50626312': __('identifierSchemeCodeUnspsc190501.50626312'),
	'identifierSchemeCodeUnspsc190501.50626313': __('identifierSchemeCodeUnspsc190501.50626313'),
	'identifierSchemeCodeUnspsc190501.50626314': __('identifierSchemeCodeUnspsc190501.50626314'),
	'identifierSchemeCodeUnspsc190501.50626315': __('identifierSchemeCodeUnspsc190501.50626315'),
	'identifierSchemeCodeUnspsc190501.50626316': __('identifierSchemeCodeUnspsc190501.50626316'),
	'identifierSchemeCodeUnspsc190501.50626317': __('identifierSchemeCodeUnspsc190501.50626317'),
	'identifierSchemeCodeUnspsc190501.50626318': __('identifierSchemeCodeUnspsc190501.50626318'),
	'identifierSchemeCodeUnspsc190501.50626319': __('identifierSchemeCodeUnspsc190501.50626319'),
	'identifierSchemeCodeUnspsc190501.50626320': __('identifierSchemeCodeUnspsc190501.50626320'),
	'identifierSchemeCodeUnspsc190501.50626321': __('identifierSchemeCodeUnspsc190501.50626321'),
	'identifierSchemeCodeUnspsc190501.50626322': __('identifierSchemeCodeUnspsc190501.50626322'),
	'identifierSchemeCodeUnspsc190501.50626323': __('identifierSchemeCodeUnspsc190501.50626323'),
	'identifierSchemeCodeUnspsc190501.50626324': __('identifierSchemeCodeUnspsc190501.50626324'),
	'identifierSchemeCodeUnspsc190501.50626325': __('identifierSchemeCodeUnspsc190501.50626325'),
	'identifierSchemeCodeUnspsc190501.50626326': __('identifierSchemeCodeUnspsc190501.50626326'),
	'identifierSchemeCodeUnspsc190501.50626327': __('identifierSchemeCodeUnspsc190501.50626327'),
	'identifierSchemeCodeUnspsc190501.50626400': __('identifierSchemeCodeUnspsc190501.50626400'),
	'identifierSchemeCodeUnspsc190501.50626401': __('identifierSchemeCodeUnspsc190501.50626401'),
	'identifierSchemeCodeUnspsc190501.50626402': __('identifierSchemeCodeUnspsc190501.50626402'),
	'identifierSchemeCodeUnspsc190501.50626403': __('identifierSchemeCodeUnspsc190501.50626403'),
	'identifierSchemeCodeUnspsc190501.50626404': __('identifierSchemeCodeUnspsc190501.50626404'),
	'identifierSchemeCodeUnspsc190501.50626405': __('identifierSchemeCodeUnspsc190501.50626405'),
	'identifierSchemeCodeUnspsc190501.50626406': __('identifierSchemeCodeUnspsc190501.50626406'),
	'identifierSchemeCodeUnspsc190501.50626407': __('identifierSchemeCodeUnspsc190501.50626407'),
	'identifierSchemeCodeUnspsc190501.50626408': __('identifierSchemeCodeUnspsc190501.50626408'),
	'identifierSchemeCodeUnspsc190501.50626500': __('identifierSchemeCodeUnspsc190501.50626500'),
	'identifierSchemeCodeUnspsc190501.50626501': __('identifierSchemeCodeUnspsc190501.50626501'),
	'identifierSchemeCodeUnspsc190501.50626502': __('identifierSchemeCodeUnspsc190501.50626502'),
	'identifierSchemeCodeUnspsc190501.50626503': __('identifierSchemeCodeUnspsc190501.50626503'),
	'identifierSchemeCodeUnspsc190501.50626504': __('identifierSchemeCodeUnspsc190501.50626504'),
	'identifierSchemeCodeUnspsc190501.50626505': __('identifierSchemeCodeUnspsc190501.50626505'),
	'identifierSchemeCodeUnspsc190501.50626506': __('identifierSchemeCodeUnspsc190501.50626506'),
	'identifierSchemeCodeUnspsc190501.50626507': __('identifierSchemeCodeUnspsc190501.50626507'),
	'identifierSchemeCodeUnspsc190501.50626508': __('identifierSchemeCodeUnspsc190501.50626508'),
	'identifierSchemeCodeUnspsc190501.50626509': __('identifierSchemeCodeUnspsc190501.50626509'),
	'identifierSchemeCodeUnspsc190501.50626510': __('identifierSchemeCodeUnspsc190501.50626510'),
	'identifierSchemeCodeUnspsc190501.50626511': __('identifierSchemeCodeUnspsc190501.50626511'),
	'identifierSchemeCodeUnspsc190501.50626512': __('identifierSchemeCodeUnspsc190501.50626512'),
	'identifierSchemeCodeUnspsc190501.50626513': __('identifierSchemeCodeUnspsc190501.50626513'),
	'identifierSchemeCodeUnspsc190501.50626514': __('identifierSchemeCodeUnspsc190501.50626514'),
	'identifierSchemeCodeUnspsc190501.50626515': __('identifierSchemeCodeUnspsc190501.50626515'),
	'identifierSchemeCodeUnspsc190501.50626516': __('identifierSchemeCodeUnspsc190501.50626516'),
	'identifierSchemeCodeUnspsc190501.50626517': __('identifierSchemeCodeUnspsc190501.50626517'),
	'identifierSchemeCodeUnspsc190501.50626518': __('identifierSchemeCodeUnspsc190501.50626518'),
	'identifierSchemeCodeUnspsc190501.50626519': __('identifierSchemeCodeUnspsc190501.50626519'),
	'identifierSchemeCodeUnspsc190501.50626520': __('identifierSchemeCodeUnspsc190501.50626520'),
	'identifierSchemeCodeUnspsc190501.50626600': __('identifierSchemeCodeUnspsc190501.50626600'),
	'identifierSchemeCodeUnspsc190501.50626601': __('identifierSchemeCodeUnspsc190501.50626601'),
	'identifierSchemeCodeUnspsc190501.50626602': __('identifierSchemeCodeUnspsc190501.50626602'),
	'identifierSchemeCodeUnspsc190501.50626603': __('identifierSchemeCodeUnspsc190501.50626603'),
	'identifierSchemeCodeUnspsc190501.50626604': __('identifierSchemeCodeUnspsc190501.50626604'),
	'identifierSchemeCodeUnspsc190501.50626605': __('identifierSchemeCodeUnspsc190501.50626605'),
	'identifierSchemeCodeUnspsc190501.50626606': __('identifierSchemeCodeUnspsc190501.50626606'),
	'identifierSchemeCodeUnspsc190501.50626607': __('identifierSchemeCodeUnspsc190501.50626607'),
	'identifierSchemeCodeUnspsc190501.50626608': __('identifierSchemeCodeUnspsc190501.50626608'),
	'identifierSchemeCodeUnspsc190501.50626609': __('identifierSchemeCodeUnspsc190501.50626609'),
	'identifierSchemeCodeUnspsc190501.50626610': __('identifierSchemeCodeUnspsc190501.50626610'),
	'identifierSchemeCodeUnspsc190501.50626700': __('identifierSchemeCodeUnspsc190501.50626700'),
	'identifierSchemeCodeUnspsc190501.50626701': __('identifierSchemeCodeUnspsc190501.50626701'),
	'identifierSchemeCodeUnspsc190501.50626702': __('identifierSchemeCodeUnspsc190501.50626702'),
	'identifierSchemeCodeUnspsc190501.50626703': __('identifierSchemeCodeUnspsc190501.50626703'),
	'identifierSchemeCodeUnspsc190501.50626704': __('identifierSchemeCodeUnspsc190501.50626704'),
	'identifierSchemeCodeUnspsc190501.50626705': __('identifierSchemeCodeUnspsc190501.50626705'),
	'identifierSchemeCodeUnspsc190501.50626706': __('identifierSchemeCodeUnspsc190501.50626706'),
	'identifierSchemeCodeUnspsc190501.50626707': __('identifierSchemeCodeUnspsc190501.50626707'),
	'identifierSchemeCodeUnspsc190501.50626708': __('identifierSchemeCodeUnspsc190501.50626708'),
	'identifierSchemeCodeUnspsc190501.50626709': __('identifierSchemeCodeUnspsc190501.50626709'),
	'identifierSchemeCodeUnspsc190501.50626710': __('identifierSchemeCodeUnspsc190501.50626710'),
	'identifierSchemeCodeUnspsc190501.50626711': __('identifierSchemeCodeUnspsc190501.50626711'),
	'identifierSchemeCodeUnspsc190501.50626712': __('identifierSchemeCodeUnspsc190501.50626712'),
	'identifierSchemeCodeUnspsc190501.50626713': __('identifierSchemeCodeUnspsc190501.50626713'),
	'identifierSchemeCodeUnspsc190501.50626714': __('identifierSchemeCodeUnspsc190501.50626714'),
	'identifierSchemeCodeUnspsc190501.50626715': __('identifierSchemeCodeUnspsc190501.50626715'),
	'identifierSchemeCodeUnspsc190501.50626716': __('identifierSchemeCodeUnspsc190501.50626716'),
	'identifierSchemeCodeUnspsc190501.50626717': __('identifierSchemeCodeUnspsc190501.50626717'),
	'identifierSchemeCodeUnspsc190501.50626718': __('identifierSchemeCodeUnspsc190501.50626718'),
	'identifierSchemeCodeUnspsc190501.50626719': __('identifierSchemeCodeUnspsc190501.50626719'),
	'identifierSchemeCodeUnspsc190501.50626720': __('identifierSchemeCodeUnspsc190501.50626720'),
	'identifierSchemeCodeUnspsc190501.50626721': __('identifierSchemeCodeUnspsc190501.50626721'),
	'identifierSchemeCodeUnspsc190501.50626722': __('identifierSchemeCodeUnspsc190501.50626722'),
	'identifierSchemeCodeUnspsc190501.50626723': __('identifierSchemeCodeUnspsc190501.50626723'),
	'identifierSchemeCodeUnspsc190501.50626724': __('identifierSchemeCodeUnspsc190501.50626724'),
	'identifierSchemeCodeUnspsc190501.50626725': __('identifierSchemeCodeUnspsc190501.50626725'),
	'identifierSchemeCodeUnspsc190501.50626726': __('identifierSchemeCodeUnspsc190501.50626726'),
	'identifierSchemeCodeUnspsc190501.50626727': __('identifierSchemeCodeUnspsc190501.50626727'),
	'identifierSchemeCodeUnspsc190501.50626728': __('identifierSchemeCodeUnspsc190501.50626728'),
	'identifierSchemeCodeUnspsc190501.50626729': __('identifierSchemeCodeUnspsc190501.50626729'),
	'identifierSchemeCodeUnspsc190501.50626730': __('identifierSchemeCodeUnspsc190501.50626730'),
	'identifierSchemeCodeUnspsc190501.50626731': __('identifierSchemeCodeUnspsc190501.50626731'),
	'identifierSchemeCodeUnspsc190501.50626732': __('identifierSchemeCodeUnspsc190501.50626732'),
	'identifierSchemeCodeUnspsc190501.50626733': __('identifierSchemeCodeUnspsc190501.50626733'),
	'identifierSchemeCodeUnspsc190501.50626734': __('identifierSchemeCodeUnspsc190501.50626734'),
	'identifierSchemeCodeUnspsc190501.50626735': __('identifierSchemeCodeUnspsc190501.50626735'),
	'identifierSchemeCodeUnspsc190501.50626736': __('identifierSchemeCodeUnspsc190501.50626736'),
	'identifierSchemeCodeUnspsc190501.50626737': __('identifierSchemeCodeUnspsc190501.50626737'),
	'identifierSchemeCodeUnspsc190501.50626738': __('identifierSchemeCodeUnspsc190501.50626738'),
	'identifierSchemeCodeUnspsc190501.50626739': __('identifierSchemeCodeUnspsc190501.50626739'),
	'identifierSchemeCodeUnspsc190501.50626740': __('identifierSchemeCodeUnspsc190501.50626740'),
	'identifierSchemeCodeUnspsc190501.50626741': __('identifierSchemeCodeUnspsc190501.50626741'),
	'identifierSchemeCodeUnspsc190501.50626742': __('identifierSchemeCodeUnspsc190501.50626742'),
	'identifierSchemeCodeUnspsc190501.50626743': __('identifierSchemeCodeUnspsc190501.50626743'),
	'identifierSchemeCodeUnspsc190501.50626744': __('identifierSchemeCodeUnspsc190501.50626744'),
	'identifierSchemeCodeUnspsc190501.50626745': __('identifierSchemeCodeUnspsc190501.50626745'),
	'identifierSchemeCodeUnspsc190501.50626746': __('identifierSchemeCodeUnspsc190501.50626746'),
	'identifierSchemeCodeUnspsc190501.50626747': __('identifierSchemeCodeUnspsc190501.50626747'),
	'identifierSchemeCodeUnspsc190501.50626748': __('identifierSchemeCodeUnspsc190501.50626748'),
	'identifierSchemeCodeUnspsc190501.50626749': __('identifierSchemeCodeUnspsc190501.50626749'),
	'identifierSchemeCodeUnspsc190501.50626750': __('identifierSchemeCodeUnspsc190501.50626750'),
	'identifierSchemeCodeUnspsc190501.50626751': __('identifierSchemeCodeUnspsc190501.50626751'),
	'identifierSchemeCodeUnspsc190501.50626752': __('identifierSchemeCodeUnspsc190501.50626752'),
	'identifierSchemeCodeUnspsc190501.50626753': __('identifierSchemeCodeUnspsc190501.50626753'),
	'identifierSchemeCodeUnspsc190501.50626754': __('identifierSchemeCodeUnspsc190501.50626754'),
	'identifierSchemeCodeUnspsc190501.50626755': __('identifierSchemeCodeUnspsc190501.50626755'),
	'identifierSchemeCodeUnspsc190501.50626756': __('identifierSchemeCodeUnspsc190501.50626756'),
	'identifierSchemeCodeUnspsc190501.50626757': __('identifierSchemeCodeUnspsc190501.50626757'),
	'identifierSchemeCodeUnspsc190501.50626758': __('identifierSchemeCodeUnspsc190501.50626758'),
	'identifierSchemeCodeUnspsc190501.50626759': __('identifierSchemeCodeUnspsc190501.50626759'),
	'identifierSchemeCodeUnspsc190501.50626760': __('identifierSchemeCodeUnspsc190501.50626760'),
	'identifierSchemeCodeUnspsc190501.50626761': __('identifierSchemeCodeUnspsc190501.50626761'),
	'identifierSchemeCodeUnspsc190501.50626762': __('identifierSchemeCodeUnspsc190501.50626762'),
	'identifierSchemeCodeUnspsc190501.50626763': __('identifierSchemeCodeUnspsc190501.50626763'),
	'identifierSchemeCodeUnspsc190501.50626764': __('identifierSchemeCodeUnspsc190501.50626764'),
	'identifierSchemeCodeUnspsc190501.50626765': __('identifierSchemeCodeUnspsc190501.50626765'),
	'identifierSchemeCodeUnspsc190501.50626766': __('identifierSchemeCodeUnspsc190501.50626766'),
	'identifierSchemeCodeUnspsc190501.50626767': __('identifierSchemeCodeUnspsc190501.50626767'),
	'identifierSchemeCodeUnspsc190501.50626768': __('identifierSchemeCodeUnspsc190501.50626768'),
	'identifierSchemeCodeUnspsc190501.50626769': __('identifierSchemeCodeUnspsc190501.50626769'),
	'identifierSchemeCodeUnspsc190501.50626800': __('identifierSchemeCodeUnspsc190501.50626800'),
	'identifierSchemeCodeUnspsc190501.50626801': __('identifierSchemeCodeUnspsc190501.50626801'),
	'identifierSchemeCodeUnspsc190501.50626802': __('identifierSchemeCodeUnspsc190501.50626802'),
	'identifierSchemeCodeUnspsc190501.50626803': __('identifierSchemeCodeUnspsc190501.50626803'),
	'identifierSchemeCodeUnspsc190501.50626804': __('identifierSchemeCodeUnspsc190501.50626804'),
	'identifierSchemeCodeUnspsc190501.50626805': __('identifierSchemeCodeUnspsc190501.50626805'),
	'identifierSchemeCodeUnspsc190501.50626806': __('identifierSchemeCodeUnspsc190501.50626806'),
	'identifierSchemeCodeUnspsc190501.50626807': __('identifierSchemeCodeUnspsc190501.50626807'),
	'identifierSchemeCodeUnspsc190501.50626808': __('identifierSchemeCodeUnspsc190501.50626808'),
	'identifierSchemeCodeUnspsc190501.50626809': __('identifierSchemeCodeUnspsc190501.50626809'),
	'identifierSchemeCodeUnspsc190501.50626810': __('identifierSchemeCodeUnspsc190501.50626810'),
	'identifierSchemeCodeUnspsc190501.50626811': __('identifierSchemeCodeUnspsc190501.50626811'),
	'identifierSchemeCodeUnspsc190501.50626812': __('identifierSchemeCodeUnspsc190501.50626812'),
	'identifierSchemeCodeUnspsc190501.50626813': __('identifierSchemeCodeUnspsc190501.50626813'),
	'identifierSchemeCodeUnspsc190501.50626814': __('identifierSchemeCodeUnspsc190501.50626814'),
	'identifierSchemeCodeUnspsc190501.50626815': __('identifierSchemeCodeUnspsc190501.50626815'),
	'identifierSchemeCodeUnspsc190501.50626816': __('identifierSchemeCodeUnspsc190501.50626816'),
	'identifierSchemeCodeUnspsc190501.50626817': __('identifierSchemeCodeUnspsc190501.50626817'),
	'identifierSchemeCodeUnspsc190501.50626818': __('identifierSchemeCodeUnspsc190501.50626818'),
	'identifierSchemeCodeUnspsc190501.50626819': __('identifierSchemeCodeUnspsc190501.50626819'),
	'identifierSchemeCodeUnspsc190501.50626820': __('identifierSchemeCodeUnspsc190501.50626820'),
	'identifierSchemeCodeUnspsc190501.50626821': __('identifierSchemeCodeUnspsc190501.50626821'),
	'identifierSchemeCodeUnspsc190501.50626822': __('identifierSchemeCodeUnspsc190501.50626822'),
	'identifierSchemeCodeUnspsc190501.50626823': __('identifierSchemeCodeUnspsc190501.50626823'),
	'identifierSchemeCodeUnspsc190501.50626824': __('identifierSchemeCodeUnspsc190501.50626824'),
	'identifierSchemeCodeUnspsc190501.50626825': __('identifierSchemeCodeUnspsc190501.50626825'),
	'identifierSchemeCodeUnspsc190501.50626826': __('identifierSchemeCodeUnspsc190501.50626826'),
	'identifierSchemeCodeUnspsc190501.50626827': __('identifierSchemeCodeUnspsc190501.50626827'),
	'identifierSchemeCodeUnspsc190501.50626828': __('identifierSchemeCodeUnspsc190501.50626828'),
	'identifierSchemeCodeUnspsc190501.50626829': __('identifierSchemeCodeUnspsc190501.50626829'),
	'identifierSchemeCodeUnspsc190501.50626830': __('identifierSchemeCodeUnspsc190501.50626830'),
	'identifierSchemeCodeUnspsc190501.50626831': __('identifierSchemeCodeUnspsc190501.50626831'),
	'identifierSchemeCodeUnspsc190501.50626832': __('identifierSchemeCodeUnspsc190501.50626832'),
	'identifierSchemeCodeUnspsc190501.50626833': __('identifierSchemeCodeUnspsc190501.50626833'),
	'identifierSchemeCodeUnspsc190501.50626834': __('identifierSchemeCodeUnspsc190501.50626834'),
	'identifierSchemeCodeUnspsc190501.50626835': __('identifierSchemeCodeUnspsc190501.50626835'),
	'identifierSchemeCodeUnspsc190501.50626836': __('identifierSchemeCodeUnspsc190501.50626836'),
	'identifierSchemeCodeUnspsc190501.50626837': __('identifierSchemeCodeUnspsc190501.50626837'),
	'identifierSchemeCodeUnspsc190501.50626838': __('identifierSchemeCodeUnspsc190501.50626838'),
	'identifierSchemeCodeUnspsc190501.50626839': __('identifierSchemeCodeUnspsc190501.50626839'),
	'identifierSchemeCodeUnspsc190501.50626840': __('identifierSchemeCodeUnspsc190501.50626840'),
	'identifierSchemeCodeUnspsc190501.50626841': __('identifierSchemeCodeUnspsc190501.50626841'),
	'identifierSchemeCodeUnspsc190501.50626842': __('identifierSchemeCodeUnspsc190501.50626842'),
	'identifierSchemeCodeUnspsc190501.50626843': __('identifierSchemeCodeUnspsc190501.50626843'),
	'identifierSchemeCodeUnspsc190501.50626844': __('identifierSchemeCodeUnspsc190501.50626844'),
	'identifierSchemeCodeUnspsc190501.50626845': __('identifierSchemeCodeUnspsc190501.50626845'),
	'identifierSchemeCodeUnspsc190501.50626846': __('identifierSchemeCodeUnspsc190501.50626846'),
	'identifierSchemeCodeUnspsc190501.50626847': __('identifierSchemeCodeUnspsc190501.50626847'),
	'identifierSchemeCodeUnspsc190501.50626848': __('identifierSchemeCodeUnspsc190501.50626848'),
	'identifierSchemeCodeUnspsc190501.50626849': __('identifierSchemeCodeUnspsc190501.50626849'),
	'identifierSchemeCodeUnspsc190501.50626850': __('identifierSchemeCodeUnspsc190501.50626850'),
	'identifierSchemeCodeUnspsc190501.50626851': __('identifierSchemeCodeUnspsc190501.50626851'),
	'identifierSchemeCodeUnspsc190501.50626852': __('identifierSchemeCodeUnspsc190501.50626852'),
	'identifierSchemeCodeUnspsc190501.50626853': __('identifierSchemeCodeUnspsc190501.50626853'),
	'identifierSchemeCodeUnspsc190501.50626854': __('identifierSchemeCodeUnspsc190501.50626854'),
	'identifierSchemeCodeUnspsc190501.50626855': __('identifierSchemeCodeUnspsc190501.50626855'),
	'identifierSchemeCodeUnspsc190501.50626856': __('identifierSchemeCodeUnspsc190501.50626856'),
	'identifierSchemeCodeUnspsc190501.50626857': __('identifierSchemeCodeUnspsc190501.50626857'),
	'identifierSchemeCodeUnspsc190501.50626858': __('identifierSchemeCodeUnspsc190501.50626858'),
	'identifierSchemeCodeUnspsc190501.50626859': __('identifierSchemeCodeUnspsc190501.50626859'),
	'identifierSchemeCodeUnspsc190501.50626860': __('identifierSchemeCodeUnspsc190501.50626860'),
	'identifierSchemeCodeUnspsc190501.50626861': __('identifierSchemeCodeUnspsc190501.50626861'),
	'identifierSchemeCodeUnspsc190501.50626862': __('identifierSchemeCodeUnspsc190501.50626862'),
	'identifierSchemeCodeUnspsc190501.50626863': __('identifierSchemeCodeUnspsc190501.50626863'),
	'identifierSchemeCodeUnspsc190501.50626864': __('identifierSchemeCodeUnspsc190501.50626864'),
	'identifierSchemeCodeUnspsc190501.50626865': __('identifierSchemeCodeUnspsc190501.50626865'),
	'identifierSchemeCodeUnspsc190501.50626866': __('identifierSchemeCodeUnspsc190501.50626866'),
	'identifierSchemeCodeUnspsc190501.50626867': __('identifierSchemeCodeUnspsc190501.50626867'),
	'identifierSchemeCodeUnspsc190501.50626868': __('identifierSchemeCodeUnspsc190501.50626868'),
	'identifierSchemeCodeUnspsc190501.50626869': __('identifierSchemeCodeUnspsc190501.50626869'),
	'identifierSchemeCodeUnspsc190501.50626900': __('identifierSchemeCodeUnspsc190501.50626900'),
	'identifierSchemeCodeUnspsc190501.50626901': __('identifierSchemeCodeUnspsc190501.50626901'),
	'identifierSchemeCodeUnspsc190501.50626902': __('identifierSchemeCodeUnspsc190501.50626902'),
	'identifierSchemeCodeUnspsc190501.50626903': __('identifierSchemeCodeUnspsc190501.50626903'),
	'identifierSchemeCodeUnspsc190501.50626904': __('identifierSchemeCodeUnspsc190501.50626904'),
	'identifierSchemeCodeUnspsc190501.50626905': __('identifierSchemeCodeUnspsc190501.50626905'),
	'identifierSchemeCodeUnspsc190501.50626906': __('identifierSchemeCodeUnspsc190501.50626906'),
	'identifierSchemeCodeUnspsc190501.50626907': __('identifierSchemeCodeUnspsc190501.50626907'),
	'identifierSchemeCodeUnspsc190501.50626908': __('identifierSchemeCodeUnspsc190501.50626908'),
	'identifierSchemeCodeUnspsc190501.50626909': __('identifierSchemeCodeUnspsc190501.50626909'),
	'identifierSchemeCodeUnspsc190501.50626910': __('identifierSchemeCodeUnspsc190501.50626910'),
	'identifierSchemeCodeUnspsc190501.50626911': __('identifierSchemeCodeUnspsc190501.50626911'),
	'identifierSchemeCodeUnspsc190501.50626912': __('identifierSchemeCodeUnspsc190501.50626912'),
	'identifierSchemeCodeUnspsc190501.50626913': __('identifierSchemeCodeUnspsc190501.50626913'),
	'identifierSchemeCodeUnspsc190501.50626914': __('identifierSchemeCodeUnspsc190501.50626914'),
	'identifierSchemeCodeUnspsc190501.50626915': __('identifierSchemeCodeUnspsc190501.50626915'),
	'identifierSchemeCodeUnspsc190501.50626916': __('identifierSchemeCodeUnspsc190501.50626916'),
	'identifierSchemeCodeUnspsc190501.50626917': __('identifierSchemeCodeUnspsc190501.50626917'),
	'identifierSchemeCodeUnspsc190501.50626918': __('identifierSchemeCodeUnspsc190501.50626918'),
	'identifierSchemeCodeUnspsc190501.50626919': __('identifierSchemeCodeUnspsc190501.50626919'),
	'identifierSchemeCodeUnspsc190501.50626920': __('identifierSchemeCodeUnspsc190501.50626920'),
	'identifierSchemeCodeUnspsc190501.50626921': __('identifierSchemeCodeUnspsc190501.50626921'),
	'identifierSchemeCodeUnspsc190501.51000000': __('identifierSchemeCodeUnspsc190501.51000000'),
	'identifierSchemeCodeUnspsc190501.51100000': __('identifierSchemeCodeUnspsc190501.51100000'),
	'identifierSchemeCodeUnspsc190501.51101600': __('identifierSchemeCodeUnspsc190501.51101600'),
	'identifierSchemeCodeUnspsc190501.51101602': __('identifierSchemeCodeUnspsc190501.51101602'),
	'identifierSchemeCodeUnspsc190501.51101604': __('identifierSchemeCodeUnspsc190501.51101604'),
	'identifierSchemeCodeUnspsc190501.51101607': __('identifierSchemeCodeUnspsc190501.51101607'),
	'identifierSchemeCodeUnspsc190501.51101612': __('identifierSchemeCodeUnspsc190501.51101612'),
	'identifierSchemeCodeUnspsc190501.51101613': __('identifierSchemeCodeUnspsc190501.51101613'),
	'identifierSchemeCodeUnspsc190501.51101614': __('identifierSchemeCodeUnspsc190501.51101614'),
	'identifierSchemeCodeUnspsc190501.51101616': __('identifierSchemeCodeUnspsc190501.51101616'),
	'identifierSchemeCodeUnspsc190501.51101618': __('identifierSchemeCodeUnspsc190501.51101618'),
	'identifierSchemeCodeUnspsc190501.51101620': __('identifierSchemeCodeUnspsc190501.51101620'),
	'identifierSchemeCodeUnspsc190501.51101629': __('identifierSchemeCodeUnspsc190501.51101629'),
	'identifierSchemeCodeUnspsc190501.51101630': __('identifierSchemeCodeUnspsc190501.51101630'),
	'identifierSchemeCodeUnspsc190501.51101633': __('identifierSchemeCodeUnspsc190501.51101633'),
	'identifierSchemeCodeUnspsc190501.51101634': __('identifierSchemeCodeUnspsc190501.51101634'),
	'identifierSchemeCodeUnspsc190501.51101635': __('identifierSchemeCodeUnspsc190501.51101635'),
	'identifierSchemeCodeUnspsc190501.51101636': __('identifierSchemeCodeUnspsc190501.51101636'),
	'identifierSchemeCodeUnspsc190501.51101637': __('identifierSchemeCodeUnspsc190501.51101637'),
	'identifierSchemeCodeUnspsc190501.51101638': __('identifierSchemeCodeUnspsc190501.51101638'),
	'identifierSchemeCodeUnspsc190501.51101639': __('identifierSchemeCodeUnspsc190501.51101639'),
	'identifierSchemeCodeUnspsc190501.51101640': __('identifierSchemeCodeUnspsc190501.51101640'),
	'identifierSchemeCodeUnspsc190501.51101641': __('identifierSchemeCodeUnspsc190501.51101641'),
	'identifierSchemeCodeUnspsc190501.51101642': __('identifierSchemeCodeUnspsc190501.51101642'),
	'identifierSchemeCodeUnspsc190501.51101643': __('identifierSchemeCodeUnspsc190501.51101643'),
	'identifierSchemeCodeUnspsc190501.51101644': __('identifierSchemeCodeUnspsc190501.51101644'),
	'identifierSchemeCodeUnspsc190501.51101645': __('identifierSchemeCodeUnspsc190501.51101645'),
	'identifierSchemeCodeUnspsc190501.51101646': __('identifierSchemeCodeUnspsc190501.51101646'),
	'identifierSchemeCodeUnspsc190501.51101647': __('identifierSchemeCodeUnspsc190501.51101647'),
	'identifierSchemeCodeUnspsc190501.51101648': __('identifierSchemeCodeUnspsc190501.51101648'),
	'identifierSchemeCodeUnspsc190501.51101649': __('identifierSchemeCodeUnspsc190501.51101649'),
	'identifierSchemeCodeUnspsc190501.51101650': __('identifierSchemeCodeUnspsc190501.51101650'),
	'identifierSchemeCodeUnspsc190501.51101651': __('identifierSchemeCodeUnspsc190501.51101651'),
	'identifierSchemeCodeUnspsc190501.51101652': __('identifierSchemeCodeUnspsc190501.51101652'),
	'identifierSchemeCodeUnspsc190501.51101653': __('identifierSchemeCodeUnspsc190501.51101653'),
	'identifierSchemeCodeUnspsc190501.51101654': __('identifierSchemeCodeUnspsc190501.51101654'),
	'identifierSchemeCodeUnspsc190501.51101655': __('identifierSchemeCodeUnspsc190501.51101655'),
	'identifierSchemeCodeUnspsc190501.51101656': __('identifierSchemeCodeUnspsc190501.51101656'),
	'identifierSchemeCodeUnspsc190501.51101657': __('identifierSchemeCodeUnspsc190501.51101657'),
	'identifierSchemeCodeUnspsc190501.51101658': __('identifierSchemeCodeUnspsc190501.51101658'),
	'identifierSchemeCodeUnspsc190501.51101659': __('identifierSchemeCodeUnspsc190501.51101659'),
	'identifierSchemeCodeUnspsc190501.51101660': __('identifierSchemeCodeUnspsc190501.51101660'),
	'identifierSchemeCodeUnspsc190501.51101661': __('identifierSchemeCodeUnspsc190501.51101661'),
	'identifierSchemeCodeUnspsc190501.51101662': __('identifierSchemeCodeUnspsc190501.51101662'),
	'identifierSchemeCodeUnspsc190501.51101663': __('identifierSchemeCodeUnspsc190501.51101663'),
	'identifierSchemeCodeUnspsc190501.51101664': __('identifierSchemeCodeUnspsc190501.51101664'),
	'identifierSchemeCodeUnspsc190501.51101665': __('identifierSchemeCodeUnspsc190501.51101665'),
	'identifierSchemeCodeUnspsc190501.51101666': __('identifierSchemeCodeUnspsc190501.51101666'),
	'identifierSchemeCodeUnspsc190501.51101667': __('identifierSchemeCodeUnspsc190501.51101667'),
	'identifierSchemeCodeUnspsc190501.51101668': __('identifierSchemeCodeUnspsc190501.51101668'),
	'identifierSchemeCodeUnspsc190501.51101669': __('identifierSchemeCodeUnspsc190501.51101669'),
	'identifierSchemeCodeUnspsc190501.51101670': __('identifierSchemeCodeUnspsc190501.51101670'),
	'identifierSchemeCodeUnspsc190501.51101671': __('identifierSchemeCodeUnspsc190501.51101671'),
	'identifierSchemeCodeUnspsc190501.51101672': __('identifierSchemeCodeUnspsc190501.51101672'),
	'identifierSchemeCodeUnspsc190501.51101673': __('identifierSchemeCodeUnspsc190501.51101673'),
	'identifierSchemeCodeUnspsc190501.51101674': __('identifierSchemeCodeUnspsc190501.51101674'),
	'identifierSchemeCodeUnspsc190501.51101675': __('identifierSchemeCodeUnspsc190501.51101675'),
	'identifierSchemeCodeUnspsc190501.51101676': __('identifierSchemeCodeUnspsc190501.51101676'),
	'identifierSchemeCodeUnspsc190501.51101677': __('identifierSchemeCodeUnspsc190501.51101677'),
	'identifierSchemeCodeUnspsc190501.51101678': __('identifierSchemeCodeUnspsc190501.51101678'),
	'identifierSchemeCodeUnspsc190501.51101679': __('identifierSchemeCodeUnspsc190501.51101679'),
	'identifierSchemeCodeUnspsc190501.51101680': __('identifierSchemeCodeUnspsc190501.51101680'),
	'identifierSchemeCodeUnspsc190501.51101681': __('identifierSchemeCodeUnspsc190501.51101681'),
	'identifierSchemeCodeUnspsc190501.51101682': __('identifierSchemeCodeUnspsc190501.51101682'),
	'identifierSchemeCodeUnspsc190501.51101683': __('identifierSchemeCodeUnspsc190501.51101683'),
	'identifierSchemeCodeUnspsc190501.51101684': __('identifierSchemeCodeUnspsc190501.51101684'),
	'identifierSchemeCodeUnspsc190501.51101685': __('identifierSchemeCodeUnspsc190501.51101685'),
	'identifierSchemeCodeUnspsc190501.51101686': __('identifierSchemeCodeUnspsc190501.51101686'),
	'identifierSchemeCodeUnspsc190501.51101687': __('identifierSchemeCodeUnspsc190501.51101687'),
	'identifierSchemeCodeUnspsc190501.51101688': __('identifierSchemeCodeUnspsc190501.51101688'),
	'identifierSchemeCodeUnspsc190501.51101689': __('identifierSchemeCodeUnspsc190501.51101689'),
	'identifierSchemeCodeUnspsc190501.51101690': __('identifierSchemeCodeUnspsc190501.51101690'),
	'identifierSchemeCodeUnspsc190501.51101691': __('identifierSchemeCodeUnspsc190501.51101691'),
	'identifierSchemeCodeUnspsc190501.51101692': __('identifierSchemeCodeUnspsc190501.51101692'),
	'identifierSchemeCodeUnspsc190501.51101693': __('identifierSchemeCodeUnspsc190501.51101693'),
	'identifierSchemeCodeUnspsc190501.51101694': __('identifierSchemeCodeUnspsc190501.51101694'),
	'identifierSchemeCodeUnspsc190501.51101695': __('identifierSchemeCodeUnspsc190501.51101695'),
	'identifierSchemeCodeUnspsc190501.51101696': __('identifierSchemeCodeUnspsc190501.51101696'),
	'identifierSchemeCodeUnspsc190501.51101697': __('identifierSchemeCodeUnspsc190501.51101697'),
	'identifierSchemeCodeUnspsc190501.51101698': __('identifierSchemeCodeUnspsc190501.51101698'),
	'identifierSchemeCodeUnspsc190501.51101699': __('identifierSchemeCodeUnspsc190501.51101699'),
	'identifierSchemeCodeUnspsc190501.51101900': __('identifierSchemeCodeUnspsc190501.51101900'),
	'identifierSchemeCodeUnspsc190501.51101902': __('identifierSchemeCodeUnspsc190501.51101902'),
	'identifierSchemeCodeUnspsc190501.51101903': __('identifierSchemeCodeUnspsc190501.51101903'),
	'identifierSchemeCodeUnspsc190501.51101904': __('identifierSchemeCodeUnspsc190501.51101904'),
	'identifierSchemeCodeUnspsc190501.51101905': __('identifierSchemeCodeUnspsc190501.51101905'),
	'identifierSchemeCodeUnspsc190501.51101906': __('identifierSchemeCodeUnspsc190501.51101906'),
	'identifierSchemeCodeUnspsc190501.51101907': __('identifierSchemeCodeUnspsc190501.51101907'),
	'identifierSchemeCodeUnspsc190501.51101908': __('identifierSchemeCodeUnspsc190501.51101908'),
	'identifierSchemeCodeUnspsc190501.51101911': __('identifierSchemeCodeUnspsc190501.51101911'),
	'identifierSchemeCodeUnspsc190501.51101912': __('identifierSchemeCodeUnspsc190501.51101912'),
	'identifierSchemeCodeUnspsc190501.51101913': __('identifierSchemeCodeUnspsc190501.51101913'),
	'identifierSchemeCodeUnspsc190501.51101914': __('identifierSchemeCodeUnspsc190501.51101914'),
	'identifierSchemeCodeUnspsc190501.51101915': __('identifierSchemeCodeUnspsc190501.51101915'),
	'identifierSchemeCodeUnspsc190501.51101916': __('identifierSchemeCodeUnspsc190501.51101916'),
	'identifierSchemeCodeUnspsc190501.51101917': __('identifierSchemeCodeUnspsc190501.51101917'),
	'identifierSchemeCodeUnspsc190501.51101918': __('identifierSchemeCodeUnspsc190501.51101918'),
	'identifierSchemeCodeUnspsc190501.51101919': __('identifierSchemeCodeUnspsc190501.51101919'),
	'identifierSchemeCodeUnspsc190501.51101920': __('identifierSchemeCodeUnspsc190501.51101920'),
	'identifierSchemeCodeUnspsc190501.51101921': __('identifierSchemeCodeUnspsc190501.51101921'),
	'identifierSchemeCodeUnspsc190501.51101922': __('identifierSchemeCodeUnspsc190501.51101922'),
	'identifierSchemeCodeUnspsc190501.51101923': __('identifierSchemeCodeUnspsc190501.51101923'),
	'identifierSchemeCodeUnspsc190501.51101924': __('identifierSchemeCodeUnspsc190501.51101924'),
	'identifierSchemeCodeUnspsc190501.51101925': __('identifierSchemeCodeUnspsc190501.51101925'),
	'identifierSchemeCodeUnspsc190501.51101926': __('identifierSchemeCodeUnspsc190501.51101926'),
	'identifierSchemeCodeUnspsc190501.51101927': __('identifierSchemeCodeUnspsc190501.51101927'),
	'identifierSchemeCodeUnspsc190501.51101928': __('identifierSchemeCodeUnspsc190501.51101928'),
	'identifierSchemeCodeUnspsc190501.51101929': __('identifierSchemeCodeUnspsc190501.51101929'),
	'identifierSchemeCodeUnspsc190501.51101930': __('identifierSchemeCodeUnspsc190501.51101930'),
	'identifierSchemeCodeUnspsc190501.51101931': __('identifierSchemeCodeUnspsc190501.51101931'),
	'identifierSchemeCodeUnspsc190501.51101932': __('identifierSchemeCodeUnspsc190501.51101932'),
	'identifierSchemeCodeUnspsc190501.51101933': __('identifierSchemeCodeUnspsc190501.51101933'),
	'identifierSchemeCodeUnspsc190501.51101934': __('identifierSchemeCodeUnspsc190501.51101934'),
	'identifierSchemeCodeUnspsc190501.51101935': __('identifierSchemeCodeUnspsc190501.51101935'),
	'identifierSchemeCodeUnspsc190501.51101936': __('identifierSchemeCodeUnspsc190501.51101936'),
	'identifierSchemeCodeUnspsc190501.51101937': __('identifierSchemeCodeUnspsc190501.51101937'),
	'identifierSchemeCodeUnspsc190501.51101938': __('identifierSchemeCodeUnspsc190501.51101938'),
	'identifierSchemeCodeUnspsc190501.51101939': __('identifierSchemeCodeUnspsc190501.51101939'),
	'identifierSchemeCodeUnspsc190501.51101940': __('identifierSchemeCodeUnspsc190501.51101940'),
	'identifierSchemeCodeUnspsc190501.51102800': __('identifierSchemeCodeUnspsc190501.51102800'),
	'identifierSchemeCodeUnspsc190501.51102801': __('identifierSchemeCodeUnspsc190501.51102801'),
	'identifierSchemeCodeUnspsc190501.51102802': __('identifierSchemeCodeUnspsc190501.51102802'),
	'identifierSchemeCodeUnspsc190501.51102803': __('identifierSchemeCodeUnspsc190501.51102803'),
	'identifierSchemeCodeUnspsc190501.51102804': __('identifierSchemeCodeUnspsc190501.51102804'),
	'identifierSchemeCodeUnspsc190501.51102805': __('identifierSchemeCodeUnspsc190501.51102805'),
	'identifierSchemeCodeUnspsc190501.51102806': __('identifierSchemeCodeUnspsc190501.51102806'),
	'identifierSchemeCodeUnspsc190501.51102807': __('identifierSchemeCodeUnspsc190501.51102807'),
	'identifierSchemeCodeUnspsc190501.51102808': __('identifierSchemeCodeUnspsc190501.51102808'),
	'identifierSchemeCodeUnspsc190501.51102809': __('identifierSchemeCodeUnspsc190501.51102809'),
	'identifierSchemeCodeUnspsc190501.51102810': __('identifierSchemeCodeUnspsc190501.51102810'),
	'identifierSchemeCodeUnspsc190501.51102811': __('identifierSchemeCodeUnspsc190501.51102811'),
	'identifierSchemeCodeUnspsc190501.51102812': __('identifierSchemeCodeUnspsc190501.51102812'),
	'identifierSchemeCodeUnspsc190501.51102813': __('identifierSchemeCodeUnspsc190501.51102813'),
	'identifierSchemeCodeUnspsc190501.51102814': __('identifierSchemeCodeUnspsc190501.51102814'),
	'identifierSchemeCodeUnspsc190501.51102815': __('identifierSchemeCodeUnspsc190501.51102815'),
	'identifierSchemeCodeUnspsc190501.51102816': __('identifierSchemeCodeUnspsc190501.51102816'),
	'identifierSchemeCodeUnspsc190501.51102817': __('identifierSchemeCodeUnspsc190501.51102817'),
	'identifierSchemeCodeUnspsc190501.51102818': __('identifierSchemeCodeUnspsc190501.51102818'),
	'identifierSchemeCodeUnspsc190501.51102819': __('identifierSchemeCodeUnspsc190501.51102819'),
	'identifierSchemeCodeUnspsc190501.51102820': __('identifierSchemeCodeUnspsc190501.51102820'),
	'identifierSchemeCodeUnspsc190501.51102821': __('identifierSchemeCodeUnspsc190501.51102821'),
	'identifierSchemeCodeUnspsc190501.51102822': __('identifierSchemeCodeUnspsc190501.51102822'),
	'identifierSchemeCodeUnspsc190501.51102823': __('identifierSchemeCodeUnspsc190501.51102823'),
	'identifierSchemeCodeUnspsc190501.51102824': __('identifierSchemeCodeUnspsc190501.51102824'),
	'identifierSchemeCodeUnspsc190501.51102825': __('identifierSchemeCodeUnspsc190501.51102825'),
	'identifierSchemeCodeUnspsc190501.51102826': __('identifierSchemeCodeUnspsc190501.51102826'),
	'identifierSchemeCodeUnspsc190501.51102827': __('identifierSchemeCodeUnspsc190501.51102827'),
	'identifierSchemeCodeUnspsc190501.51102828': __('identifierSchemeCodeUnspsc190501.51102828'),
	'identifierSchemeCodeUnspsc190501.51102829': __('identifierSchemeCodeUnspsc190501.51102829'),
	'identifierSchemeCodeUnspsc190501.51102830': __('identifierSchemeCodeUnspsc190501.51102830'),
	'identifierSchemeCodeUnspsc190501.51102831': __('identifierSchemeCodeUnspsc190501.51102831'),
	'identifierSchemeCodeUnspsc190501.51102832': __('identifierSchemeCodeUnspsc190501.51102832'),
	'identifierSchemeCodeUnspsc190501.51102833': __('identifierSchemeCodeUnspsc190501.51102833'),
	'identifierSchemeCodeUnspsc190501.51102834': __('identifierSchemeCodeUnspsc190501.51102834'),
	'identifierSchemeCodeUnspsc190501.51102835': __('identifierSchemeCodeUnspsc190501.51102835'),
	'identifierSchemeCodeUnspsc190501.51102836': __('identifierSchemeCodeUnspsc190501.51102836'),
	'identifierSchemeCodeUnspsc190501.51102837': __('identifierSchemeCodeUnspsc190501.51102837'),
	'identifierSchemeCodeUnspsc190501.51102838': __('identifierSchemeCodeUnspsc190501.51102838'),
	'identifierSchemeCodeUnspsc190501.51102839': __('identifierSchemeCodeUnspsc190501.51102839'),
	'identifierSchemeCodeUnspsc190501.51102840': __('identifierSchemeCodeUnspsc190501.51102840'),
	'identifierSchemeCodeUnspsc190501.51102841': __('identifierSchemeCodeUnspsc190501.51102841'),
	'identifierSchemeCodeUnspsc190501.51102842': __('identifierSchemeCodeUnspsc190501.51102842'),
	'identifierSchemeCodeUnspsc190501.51102843': __('identifierSchemeCodeUnspsc190501.51102843'),
	'identifierSchemeCodeUnspsc190501.51102844': __('identifierSchemeCodeUnspsc190501.51102844'),
	'identifierSchemeCodeUnspsc190501.51102845': __('identifierSchemeCodeUnspsc190501.51102845'),
	'identifierSchemeCodeUnspsc190501.51102846': __('identifierSchemeCodeUnspsc190501.51102846'),
	'identifierSchemeCodeUnspsc190501.51102847': __('identifierSchemeCodeUnspsc190501.51102847'),
	'identifierSchemeCodeUnspsc190501.51102848': __('identifierSchemeCodeUnspsc190501.51102848'),
	'identifierSchemeCodeUnspsc190501.51102849': __('identifierSchemeCodeUnspsc190501.51102849'),
	'identifierSchemeCodeUnspsc190501.51102850': __('identifierSchemeCodeUnspsc190501.51102850'),
	'identifierSchemeCodeUnspsc190501.51102851': __('identifierSchemeCodeUnspsc190501.51102851'),
	'identifierSchemeCodeUnspsc190501.51102852': __('identifierSchemeCodeUnspsc190501.51102852'),
	'identifierSchemeCodeUnspsc190501.51102853': __('identifierSchemeCodeUnspsc190501.51102853'),
	'identifierSchemeCodeUnspsc190501.51102854': __('identifierSchemeCodeUnspsc190501.51102854'),
	'identifierSchemeCodeUnspsc190501.51102855': __('identifierSchemeCodeUnspsc190501.51102855'),
	'identifierSchemeCodeUnspsc190501.51102856': __('identifierSchemeCodeUnspsc190501.51102856'),
	'identifierSchemeCodeUnspsc190501.51102857': __('identifierSchemeCodeUnspsc190501.51102857'),
	'identifierSchemeCodeUnspsc190501.51102858': __('identifierSchemeCodeUnspsc190501.51102858'),
	'identifierSchemeCodeUnspsc190501.51102859': __('identifierSchemeCodeUnspsc190501.51102859'),
	'identifierSchemeCodeUnspsc190501.51102860': __('identifierSchemeCodeUnspsc190501.51102860'),
	'identifierSchemeCodeUnspsc190501.51102861': __('identifierSchemeCodeUnspsc190501.51102861'),
	'identifierSchemeCodeUnspsc190501.51102862': __('identifierSchemeCodeUnspsc190501.51102862'),
	'identifierSchemeCodeUnspsc190501.51102863': __('identifierSchemeCodeUnspsc190501.51102863'),
	'identifierSchemeCodeUnspsc190501.51102864': __('identifierSchemeCodeUnspsc190501.51102864'),
	'identifierSchemeCodeUnspsc190501.51102865': __('identifierSchemeCodeUnspsc190501.51102865'),
	'identifierSchemeCodeUnspsc190501.51102866': __('identifierSchemeCodeUnspsc190501.51102866'),
	'identifierSchemeCodeUnspsc190501.51102867': __('identifierSchemeCodeUnspsc190501.51102867'),
	'identifierSchemeCodeUnspsc190501.51102868': __('identifierSchemeCodeUnspsc190501.51102868'),
	'identifierSchemeCodeUnspsc190501.51102869': __('identifierSchemeCodeUnspsc190501.51102869'),
	'identifierSchemeCodeUnspsc190501.51102871': __('identifierSchemeCodeUnspsc190501.51102871'),
	'identifierSchemeCodeUnspsc190501.51102872': __('identifierSchemeCodeUnspsc190501.51102872'),
	'identifierSchemeCodeUnspsc190501.51102873': __('identifierSchemeCodeUnspsc190501.51102873'),
	'identifierSchemeCodeUnspsc190501.51102874': __('identifierSchemeCodeUnspsc190501.51102874'),
	'identifierSchemeCodeUnspsc190501.51102875': __('identifierSchemeCodeUnspsc190501.51102875'),
	'identifierSchemeCodeUnspsc190501.51102876': __('identifierSchemeCodeUnspsc190501.51102876'),
	'identifierSchemeCodeUnspsc190501.51102877': __('identifierSchemeCodeUnspsc190501.51102877'),
	'identifierSchemeCodeUnspsc190501.51102900': __('identifierSchemeCodeUnspsc190501.51102900'),
	'identifierSchemeCodeUnspsc190501.51102901': __('identifierSchemeCodeUnspsc190501.51102901'),
	'identifierSchemeCodeUnspsc190501.51102902': __('identifierSchemeCodeUnspsc190501.51102902'),
	'identifierSchemeCodeUnspsc190501.51102903': __('identifierSchemeCodeUnspsc190501.51102903'),
	'identifierSchemeCodeUnspsc190501.51102904': __('identifierSchemeCodeUnspsc190501.51102904'),
	'identifierSchemeCodeUnspsc190501.51103000': __('identifierSchemeCodeUnspsc190501.51103000'),
	'identifierSchemeCodeUnspsc190501.51103001': __('identifierSchemeCodeUnspsc190501.51103001'),
	'identifierSchemeCodeUnspsc190501.51103002': __('identifierSchemeCodeUnspsc190501.51103002'),
	'identifierSchemeCodeUnspsc190501.51103003': __('identifierSchemeCodeUnspsc190501.51103003'),
	'identifierSchemeCodeUnspsc190501.51110000': __('identifierSchemeCodeUnspsc190501.51110000'),
	'identifierSchemeCodeUnspsc190501.51111600': __('identifierSchemeCodeUnspsc190501.51111600'),
	'identifierSchemeCodeUnspsc190501.51111602': __('identifierSchemeCodeUnspsc190501.51111602'),
	'identifierSchemeCodeUnspsc190501.51111603': __('identifierSchemeCodeUnspsc190501.51111603'),
	'identifierSchemeCodeUnspsc190501.51111606': __('identifierSchemeCodeUnspsc190501.51111606'),
	'identifierSchemeCodeUnspsc190501.51111610': __('identifierSchemeCodeUnspsc190501.51111610'),
	'identifierSchemeCodeUnspsc190501.51111611': __('identifierSchemeCodeUnspsc190501.51111611'),
	'identifierSchemeCodeUnspsc190501.51111613': __('identifierSchemeCodeUnspsc190501.51111613'),
	'identifierSchemeCodeUnspsc190501.51111615': __('identifierSchemeCodeUnspsc190501.51111615'),
	'identifierSchemeCodeUnspsc190501.51111617': __('identifierSchemeCodeUnspsc190501.51111617'),
	'identifierSchemeCodeUnspsc190501.51111619': __('identifierSchemeCodeUnspsc190501.51111619'),
	'identifierSchemeCodeUnspsc190501.51111620': __('identifierSchemeCodeUnspsc190501.51111620'),
	'identifierSchemeCodeUnspsc190501.51111621': __('identifierSchemeCodeUnspsc190501.51111621'),
	'identifierSchemeCodeUnspsc190501.51111622': __('identifierSchemeCodeUnspsc190501.51111622'),
	'identifierSchemeCodeUnspsc190501.51111623': __('identifierSchemeCodeUnspsc190501.51111623'),
	'identifierSchemeCodeUnspsc190501.51111624': __('identifierSchemeCodeUnspsc190501.51111624'),
	'identifierSchemeCodeUnspsc190501.51111625': __('identifierSchemeCodeUnspsc190501.51111625'),
	'identifierSchemeCodeUnspsc190501.51111626': __('identifierSchemeCodeUnspsc190501.51111626'),
	'identifierSchemeCodeUnspsc190501.51111627': __('identifierSchemeCodeUnspsc190501.51111627'),
	'identifierSchemeCodeUnspsc190501.51111628': __('identifierSchemeCodeUnspsc190501.51111628'),
	'identifierSchemeCodeUnspsc190501.51111629': __('identifierSchemeCodeUnspsc190501.51111629'),
	'identifierSchemeCodeUnspsc190501.51111630': __('identifierSchemeCodeUnspsc190501.51111630'),
	'identifierSchemeCodeUnspsc190501.51111631': __('identifierSchemeCodeUnspsc190501.51111631'),
	'identifierSchemeCodeUnspsc190501.51111632': __('identifierSchemeCodeUnspsc190501.51111632'),
	'identifierSchemeCodeUnspsc190501.51111633': __('identifierSchemeCodeUnspsc190501.51111633'),
	'identifierSchemeCodeUnspsc190501.51111634': __('identifierSchemeCodeUnspsc190501.51111634'),
	'identifierSchemeCodeUnspsc190501.51111635': __('identifierSchemeCodeUnspsc190501.51111635'),
	'identifierSchemeCodeUnspsc190501.51111636': __('identifierSchemeCodeUnspsc190501.51111636'),
	'identifierSchemeCodeUnspsc190501.51111637': __('identifierSchemeCodeUnspsc190501.51111637'),
	'identifierSchemeCodeUnspsc190501.51111638': __('identifierSchemeCodeUnspsc190501.51111638'),
	'identifierSchemeCodeUnspsc190501.51111639': __('identifierSchemeCodeUnspsc190501.51111639'),
	'identifierSchemeCodeUnspsc190501.51111640': __('identifierSchemeCodeUnspsc190501.51111640'),
	'identifierSchemeCodeUnspsc190501.51111641': __('identifierSchemeCodeUnspsc190501.51111641'),
	'identifierSchemeCodeUnspsc190501.51111642': __('identifierSchemeCodeUnspsc190501.51111642'),
	'identifierSchemeCodeUnspsc190501.51111643': __('identifierSchemeCodeUnspsc190501.51111643'),
	'identifierSchemeCodeUnspsc190501.51111644': __('identifierSchemeCodeUnspsc190501.51111644'),
	'identifierSchemeCodeUnspsc190501.51111645': __('identifierSchemeCodeUnspsc190501.51111645'),
	'identifierSchemeCodeUnspsc190501.51111646': __('identifierSchemeCodeUnspsc190501.51111646'),
	'identifierSchemeCodeUnspsc190501.51111647': __('identifierSchemeCodeUnspsc190501.51111647'),
	'identifierSchemeCodeUnspsc190501.51111648': __('identifierSchemeCodeUnspsc190501.51111648'),
	'identifierSchemeCodeUnspsc190501.51111649': __('identifierSchemeCodeUnspsc190501.51111649'),
	'identifierSchemeCodeUnspsc190501.51111650': __('identifierSchemeCodeUnspsc190501.51111650'),
	'identifierSchemeCodeUnspsc190501.51111651': __('identifierSchemeCodeUnspsc190501.51111651'),
	'identifierSchemeCodeUnspsc190501.51111652': __('identifierSchemeCodeUnspsc190501.51111652'),
	'identifierSchemeCodeUnspsc190501.51111653': __('identifierSchemeCodeUnspsc190501.51111653'),
	'identifierSchemeCodeUnspsc190501.51111654': __('identifierSchemeCodeUnspsc190501.51111654'),
	'identifierSchemeCodeUnspsc190501.51111655': __('identifierSchemeCodeUnspsc190501.51111655'),
	'identifierSchemeCodeUnspsc190501.51111656': __('identifierSchemeCodeUnspsc190501.51111656'),
	'identifierSchemeCodeUnspsc190501.51111657': __('identifierSchemeCodeUnspsc190501.51111657'),
	'identifierSchemeCodeUnspsc190501.51111658': __('identifierSchemeCodeUnspsc190501.51111658'),
	'identifierSchemeCodeUnspsc190501.51111659': __('identifierSchemeCodeUnspsc190501.51111659'),
	'identifierSchemeCodeUnspsc190501.51111660': __('identifierSchemeCodeUnspsc190501.51111660'),
	'identifierSchemeCodeUnspsc190501.51111661': __('identifierSchemeCodeUnspsc190501.51111661'),
	'identifierSchemeCodeUnspsc190501.51111662': __('identifierSchemeCodeUnspsc190501.51111662'),
	'identifierSchemeCodeUnspsc190501.51111663': __('identifierSchemeCodeUnspsc190501.51111663'),
	'identifierSchemeCodeUnspsc190501.51111664': __('identifierSchemeCodeUnspsc190501.51111664'),
	'identifierSchemeCodeUnspsc190501.51111665': __('identifierSchemeCodeUnspsc190501.51111665'),
	'identifierSchemeCodeUnspsc190501.51111700': __('identifierSchemeCodeUnspsc190501.51111700'),
	'identifierSchemeCodeUnspsc190501.51111703': __('identifierSchemeCodeUnspsc190501.51111703'),
	'identifierSchemeCodeUnspsc190501.51111704': __('identifierSchemeCodeUnspsc190501.51111704'),
	'identifierSchemeCodeUnspsc190501.51111707': __('identifierSchemeCodeUnspsc190501.51111707'),
	'identifierSchemeCodeUnspsc190501.51111716': __('identifierSchemeCodeUnspsc190501.51111716'),
	'identifierSchemeCodeUnspsc190501.51111717': __('identifierSchemeCodeUnspsc190501.51111717'),
	'identifierSchemeCodeUnspsc190501.51111724': __('identifierSchemeCodeUnspsc190501.51111724'),
	'identifierSchemeCodeUnspsc190501.51111725': __('identifierSchemeCodeUnspsc190501.51111725'),
	'identifierSchemeCodeUnspsc190501.51111726': __('identifierSchemeCodeUnspsc190501.51111726'),
	'identifierSchemeCodeUnspsc190501.51111727': __('identifierSchemeCodeUnspsc190501.51111727'),
	'identifierSchemeCodeUnspsc190501.51111728': __('identifierSchemeCodeUnspsc190501.51111728'),
	'identifierSchemeCodeUnspsc190501.51111729': __('identifierSchemeCodeUnspsc190501.51111729'),
	'identifierSchemeCodeUnspsc190501.51111730': __('identifierSchemeCodeUnspsc190501.51111730'),
	'identifierSchemeCodeUnspsc190501.51111731': __('identifierSchemeCodeUnspsc190501.51111731'),
	'identifierSchemeCodeUnspsc190501.51111732': __('identifierSchemeCodeUnspsc190501.51111732'),
	'identifierSchemeCodeUnspsc190501.51111733': __('identifierSchemeCodeUnspsc190501.51111733'),
	'identifierSchemeCodeUnspsc190501.51111734': __('identifierSchemeCodeUnspsc190501.51111734'),
	'identifierSchemeCodeUnspsc190501.51111735': __('identifierSchemeCodeUnspsc190501.51111735'),
	'identifierSchemeCodeUnspsc190501.51111736': __('identifierSchemeCodeUnspsc190501.51111736'),
	'identifierSchemeCodeUnspsc190501.51111737': __('identifierSchemeCodeUnspsc190501.51111737'),
	'identifierSchemeCodeUnspsc190501.51111738': __('identifierSchemeCodeUnspsc190501.51111738'),
	'identifierSchemeCodeUnspsc190501.51111739': __('identifierSchemeCodeUnspsc190501.51111739'),
	'identifierSchemeCodeUnspsc190501.51111740': __('identifierSchemeCodeUnspsc190501.51111740'),
	'identifierSchemeCodeUnspsc190501.51111741': __('identifierSchemeCodeUnspsc190501.51111741'),
	'identifierSchemeCodeUnspsc190501.51111742': __('identifierSchemeCodeUnspsc190501.51111742'),
	'identifierSchemeCodeUnspsc190501.51111743': __('identifierSchemeCodeUnspsc190501.51111743'),
	'identifierSchemeCodeUnspsc190501.51111744': __('identifierSchemeCodeUnspsc190501.51111744'),
	'identifierSchemeCodeUnspsc190501.51111745': __('identifierSchemeCodeUnspsc190501.51111745'),
	'identifierSchemeCodeUnspsc190501.51111746': __('identifierSchemeCodeUnspsc190501.51111746'),
	'identifierSchemeCodeUnspsc190501.51111747': __('identifierSchemeCodeUnspsc190501.51111747'),
	'identifierSchemeCodeUnspsc190501.51111748': __('identifierSchemeCodeUnspsc190501.51111748'),
	'identifierSchemeCodeUnspsc190501.51111749': __('identifierSchemeCodeUnspsc190501.51111749'),
	'identifierSchemeCodeUnspsc190501.51111750': __('identifierSchemeCodeUnspsc190501.51111750'),
	'identifierSchemeCodeUnspsc190501.51111751': __('identifierSchemeCodeUnspsc190501.51111751'),
	'identifierSchemeCodeUnspsc190501.51111752': __('identifierSchemeCodeUnspsc190501.51111752'),
	'identifierSchemeCodeUnspsc190501.51111753': __('identifierSchemeCodeUnspsc190501.51111753'),
	'identifierSchemeCodeUnspsc190501.51111754': __('identifierSchemeCodeUnspsc190501.51111754'),
	'identifierSchemeCodeUnspsc190501.51111755': __('identifierSchemeCodeUnspsc190501.51111755'),
	'identifierSchemeCodeUnspsc190501.51111756': __('identifierSchemeCodeUnspsc190501.51111756'),
	'identifierSchemeCodeUnspsc190501.51111757': __('identifierSchemeCodeUnspsc190501.51111757'),
	'identifierSchemeCodeUnspsc190501.51111758': __('identifierSchemeCodeUnspsc190501.51111758'),
	'identifierSchemeCodeUnspsc190501.51111759': __('identifierSchemeCodeUnspsc190501.51111759'),
	'identifierSchemeCodeUnspsc190501.51111760': __('identifierSchemeCodeUnspsc190501.51111760'),
	'identifierSchemeCodeUnspsc190501.51111761': __('identifierSchemeCodeUnspsc190501.51111761'),
	'identifierSchemeCodeUnspsc190501.51111762': __('identifierSchemeCodeUnspsc190501.51111762'),
	'identifierSchemeCodeUnspsc190501.51111763': __('identifierSchemeCodeUnspsc190501.51111763'),
	'identifierSchemeCodeUnspsc190501.51111764': __('identifierSchemeCodeUnspsc190501.51111764'),
	'identifierSchemeCodeUnspsc190501.51111765': __('identifierSchemeCodeUnspsc190501.51111765'),
	'identifierSchemeCodeUnspsc190501.51111766': __('identifierSchemeCodeUnspsc190501.51111766'),
	'identifierSchemeCodeUnspsc190501.51111767': __('identifierSchemeCodeUnspsc190501.51111767'),
	'identifierSchemeCodeUnspsc190501.51111768': __('identifierSchemeCodeUnspsc190501.51111768'),
	'identifierSchemeCodeUnspsc190501.51111769': __('identifierSchemeCodeUnspsc190501.51111769'),
	'identifierSchemeCodeUnspsc190501.51111770': __('identifierSchemeCodeUnspsc190501.51111770'),
	'identifierSchemeCodeUnspsc190501.51111771': __('identifierSchemeCodeUnspsc190501.51111771'),
	'identifierSchemeCodeUnspsc190501.51111772': __('identifierSchemeCodeUnspsc190501.51111772'),
	'identifierSchemeCodeUnspsc190501.51111773': __('identifierSchemeCodeUnspsc190501.51111773'),
	'identifierSchemeCodeUnspsc190501.51111774': __('identifierSchemeCodeUnspsc190501.51111774'),
	'identifierSchemeCodeUnspsc190501.51111775': __('identifierSchemeCodeUnspsc190501.51111775'),
	'identifierSchemeCodeUnspsc190501.51111776': __('identifierSchemeCodeUnspsc190501.51111776'),
	'identifierSchemeCodeUnspsc190501.51111777': __('identifierSchemeCodeUnspsc190501.51111777'),
	'identifierSchemeCodeUnspsc190501.51111800': __('identifierSchemeCodeUnspsc190501.51111800'),
	'identifierSchemeCodeUnspsc190501.51111802': __('identifierSchemeCodeUnspsc190501.51111802'),
	'identifierSchemeCodeUnspsc190501.51111803': __('identifierSchemeCodeUnspsc190501.51111803'),
	'identifierSchemeCodeUnspsc190501.51111805': __('identifierSchemeCodeUnspsc190501.51111805'),
	'identifierSchemeCodeUnspsc190501.51111807': __('identifierSchemeCodeUnspsc190501.51111807'),
	'identifierSchemeCodeUnspsc190501.51111810': __('identifierSchemeCodeUnspsc190501.51111810'),
	'identifierSchemeCodeUnspsc190501.51111815': __('identifierSchemeCodeUnspsc190501.51111815'),
	'identifierSchemeCodeUnspsc190501.51111820': __('identifierSchemeCodeUnspsc190501.51111820'),
	'identifierSchemeCodeUnspsc190501.51111824': __('identifierSchemeCodeUnspsc190501.51111824'),
	'identifierSchemeCodeUnspsc190501.51111828': __('identifierSchemeCodeUnspsc190501.51111828'),
	'identifierSchemeCodeUnspsc190501.51111829': __('identifierSchemeCodeUnspsc190501.51111829'),
	'identifierSchemeCodeUnspsc190501.51111830': __('identifierSchemeCodeUnspsc190501.51111830'),
	'identifierSchemeCodeUnspsc190501.51111831': __('identifierSchemeCodeUnspsc190501.51111831'),
	'identifierSchemeCodeUnspsc190501.51111832': __('identifierSchemeCodeUnspsc190501.51111832'),
	'identifierSchemeCodeUnspsc190501.51111833': __('identifierSchemeCodeUnspsc190501.51111833'),
	'identifierSchemeCodeUnspsc190501.51111834': __('identifierSchemeCodeUnspsc190501.51111834'),
	'identifierSchemeCodeUnspsc190501.51111835': __('identifierSchemeCodeUnspsc190501.51111835'),
	'identifierSchemeCodeUnspsc190501.51111837': __('identifierSchemeCodeUnspsc190501.51111837'),
	'identifierSchemeCodeUnspsc190501.51111838': __('identifierSchemeCodeUnspsc190501.51111838'),
	'identifierSchemeCodeUnspsc190501.51111839': __('identifierSchemeCodeUnspsc190501.51111839'),
	'identifierSchemeCodeUnspsc190501.51111840': __('identifierSchemeCodeUnspsc190501.51111840'),
	'identifierSchemeCodeUnspsc190501.51111841': __('identifierSchemeCodeUnspsc190501.51111841'),
	'identifierSchemeCodeUnspsc190501.51111842': __('identifierSchemeCodeUnspsc190501.51111842'),
	'identifierSchemeCodeUnspsc190501.51111843': __('identifierSchemeCodeUnspsc190501.51111843'),
	'identifierSchemeCodeUnspsc190501.51111844': __('identifierSchemeCodeUnspsc190501.51111844'),
	'identifierSchemeCodeUnspsc190501.51111845': __('identifierSchemeCodeUnspsc190501.51111845'),
	'identifierSchemeCodeUnspsc190501.51111846': __('identifierSchemeCodeUnspsc190501.51111846'),
	'identifierSchemeCodeUnspsc190501.51111847': __('identifierSchemeCodeUnspsc190501.51111847'),
	'identifierSchemeCodeUnspsc190501.51111848': __('identifierSchemeCodeUnspsc190501.51111848'),
	'identifierSchemeCodeUnspsc190501.51111849': __('identifierSchemeCodeUnspsc190501.51111849'),
	'identifierSchemeCodeUnspsc190501.51111850': __('identifierSchemeCodeUnspsc190501.51111850'),
	'identifierSchemeCodeUnspsc190501.51111851': __('identifierSchemeCodeUnspsc190501.51111851'),
	'identifierSchemeCodeUnspsc190501.51111853': __('identifierSchemeCodeUnspsc190501.51111853'),
	'identifierSchemeCodeUnspsc190501.51111854': __('identifierSchemeCodeUnspsc190501.51111854'),
	'identifierSchemeCodeUnspsc190501.51111855': __('identifierSchemeCodeUnspsc190501.51111855'),
	'identifierSchemeCodeUnspsc190501.51111856': __('identifierSchemeCodeUnspsc190501.51111856'),
	'identifierSchemeCodeUnspsc190501.51111857': __('identifierSchemeCodeUnspsc190501.51111857'),
	'identifierSchemeCodeUnspsc190501.51111858': __('identifierSchemeCodeUnspsc190501.51111858'),
	'identifierSchemeCodeUnspsc190501.51111859': __('identifierSchemeCodeUnspsc190501.51111859'),
	'identifierSchemeCodeUnspsc190501.51111860': __('identifierSchemeCodeUnspsc190501.51111860'),
	'identifierSchemeCodeUnspsc190501.51111861': __('identifierSchemeCodeUnspsc190501.51111861'),
	'identifierSchemeCodeUnspsc190501.51111862': __('identifierSchemeCodeUnspsc190501.51111862'),
	'identifierSchemeCodeUnspsc190501.51111863': __('identifierSchemeCodeUnspsc190501.51111863'),
	'identifierSchemeCodeUnspsc190501.51111866': __('identifierSchemeCodeUnspsc190501.51111866'),
	'identifierSchemeCodeUnspsc190501.51111867': __('identifierSchemeCodeUnspsc190501.51111867'),
	'identifierSchemeCodeUnspsc190501.51111868': __('identifierSchemeCodeUnspsc190501.51111868'),
	'identifierSchemeCodeUnspsc190501.51111869': __('identifierSchemeCodeUnspsc190501.51111869'),
	'identifierSchemeCodeUnspsc190501.51111870': __('identifierSchemeCodeUnspsc190501.51111870'),
	'identifierSchemeCodeUnspsc190501.51111871': __('identifierSchemeCodeUnspsc190501.51111871'),
	'identifierSchemeCodeUnspsc190501.51111872': __('identifierSchemeCodeUnspsc190501.51111872'),
	'identifierSchemeCodeUnspsc190501.51111873': __('identifierSchemeCodeUnspsc190501.51111873'),
	'identifierSchemeCodeUnspsc190501.51111874': __('identifierSchemeCodeUnspsc190501.51111874'),
	'identifierSchemeCodeUnspsc190501.51111875': __('identifierSchemeCodeUnspsc190501.51111875'),
	'identifierSchemeCodeUnspsc190501.51111876': __('identifierSchemeCodeUnspsc190501.51111876'),
	'identifierSchemeCodeUnspsc190501.51111900': __('identifierSchemeCodeUnspsc190501.51111900'),
	'identifierSchemeCodeUnspsc190501.51111901': __('identifierSchemeCodeUnspsc190501.51111901'),
	'identifierSchemeCodeUnspsc190501.51111904': __('identifierSchemeCodeUnspsc190501.51111904'),
	'identifierSchemeCodeUnspsc190501.51111905': __('identifierSchemeCodeUnspsc190501.51111905'),
	'identifierSchemeCodeUnspsc190501.51111906': __('identifierSchemeCodeUnspsc190501.51111906'),
	'identifierSchemeCodeUnspsc190501.51111907': __('identifierSchemeCodeUnspsc190501.51111907'),
	'identifierSchemeCodeUnspsc190501.51111908': __('identifierSchemeCodeUnspsc190501.51111908'),
	'identifierSchemeCodeUnspsc190501.51111909': __('identifierSchemeCodeUnspsc190501.51111909'),
	'identifierSchemeCodeUnspsc190501.51111910': __('identifierSchemeCodeUnspsc190501.51111910'),
	'identifierSchemeCodeUnspsc190501.51111911': __('identifierSchemeCodeUnspsc190501.51111911'),
	'identifierSchemeCodeUnspsc190501.51111912': __('identifierSchemeCodeUnspsc190501.51111912'),
	'identifierSchemeCodeUnspsc190501.51111913': __('identifierSchemeCodeUnspsc190501.51111913'),
	'identifierSchemeCodeUnspsc190501.51112000': __('identifierSchemeCodeUnspsc190501.51112000'),
	'identifierSchemeCodeUnspsc190501.51112001': __('identifierSchemeCodeUnspsc190501.51112001'),
	'identifierSchemeCodeUnspsc190501.51112002': __('identifierSchemeCodeUnspsc190501.51112002'),
	'identifierSchemeCodeUnspsc190501.51112003': __('identifierSchemeCodeUnspsc190501.51112003'),
	'identifierSchemeCodeUnspsc190501.51112004': __('identifierSchemeCodeUnspsc190501.51112004'),
	'identifierSchemeCodeUnspsc190501.51112005': __('identifierSchemeCodeUnspsc190501.51112005'),
	'identifierSchemeCodeUnspsc190501.51112006': __('identifierSchemeCodeUnspsc190501.51112006'),
	'identifierSchemeCodeUnspsc190501.51112007': __('identifierSchemeCodeUnspsc190501.51112007'),
	'identifierSchemeCodeUnspsc190501.51112008': __('identifierSchemeCodeUnspsc190501.51112008'),
	'identifierSchemeCodeUnspsc190501.51112009': __('identifierSchemeCodeUnspsc190501.51112009'),
	'identifierSchemeCodeUnspsc190501.51112010': __('identifierSchemeCodeUnspsc190501.51112010'),
	'identifierSchemeCodeUnspsc190501.51112011': __('identifierSchemeCodeUnspsc190501.51112011'),
	'identifierSchemeCodeUnspsc190501.51112012': __('identifierSchemeCodeUnspsc190501.51112012'),
	'identifierSchemeCodeUnspsc190501.51112013': __('identifierSchemeCodeUnspsc190501.51112013'),
	'identifierSchemeCodeUnspsc190501.51112014': __('identifierSchemeCodeUnspsc190501.51112014'),
	'identifierSchemeCodeUnspsc190501.51112015': __('identifierSchemeCodeUnspsc190501.51112015'),
	'identifierSchemeCodeUnspsc190501.51112016': __('identifierSchemeCodeUnspsc190501.51112016'),
	'identifierSchemeCodeUnspsc190501.51112017': __('identifierSchemeCodeUnspsc190501.51112017'),
	'identifierSchemeCodeUnspsc190501.51112018': __('identifierSchemeCodeUnspsc190501.51112018'),
	'identifierSchemeCodeUnspsc190501.51112019': __('identifierSchemeCodeUnspsc190501.51112019'),
	'identifierSchemeCodeUnspsc190501.51112100': __('identifierSchemeCodeUnspsc190501.51112100'),
	'identifierSchemeCodeUnspsc190501.51112101': __('identifierSchemeCodeUnspsc190501.51112101'),
	'identifierSchemeCodeUnspsc190501.51112102': __('identifierSchemeCodeUnspsc190501.51112102'),
	'identifierSchemeCodeUnspsc190501.51112103': __('identifierSchemeCodeUnspsc190501.51112103'),
	'identifierSchemeCodeUnspsc190501.51112104': __('identifierSchemeCodeUnspsc190501.51112104'),
	'identifierSchemeCodeUnspsc190501.51112200': __('identifierSchemeCodeUnspsc190501.51112200'),
	'identifierSchemeCodeUnspsc190501.51112201': __('identifierSchemeCodeUnspsc190501.51112201'),
	'identifierSchemeCodeUnspsc190501.51112202': __('identifierSchemeCodeUnspsc190501.51112202'),
	'identifierSchemeCodeUnspsc190501.51112203': __('identifierSchemeCodeUnspsc190501.51112203'),
	'identifierSchemeCodeUnspsc190501.51112204': __('identifierSchemeCodeUnspsc190501.51112204'),
	'identifierSchemeCodeUnspsc190501.51112205': __('identifierSchemeCodeUnspsc190501.51112205'),
	'identifierSchemeCodeUnspsc190501.51112206': __('identifierSchemeCodeUnspsc190501.51112206'),
	'identifierSchemeCodeUnspsc190501.51112300': __('identifierSchemeCodeUnspsc190501.51112300'),
	'identifierSchemeCodeUnspsc190501.51112301': __('identifierSchemeCodeUnspsc190501.51112301'),
	'identifierSchemeCodeUnspsc190501.51112302': __('identifierSchemeCodeUnspsc190501.51112302'),
	'identifierSchemeCodeUnspsc190501.51112303': __('identifierSchemeCodeUnspsc190501.51112303'),
	'identifierSchemeCodeUnspsc190501.51112304': __('identifierSchemeCodeUnspsc190501.51112304'),
	'identifierSchemeCodeUnspsc190501.51112305': __('identifierSchemeCodeUnspsc190501.51112305'),
	'identifierSchemeCodeUnspsc190501.51112306': __('identifierSchemeCodeUnspsc190501.51112306'),
	'identifierSchemeCodeUnspsc190501.51112400': __('identifierSchemeCodeUnspsc190501.51112400'),
	'identifierSchemeCodeUnspsc190501.51112401': __('identifierSchemeCodeUnspsc190501.51112401'),
	'identifierSchemeCodeUnspsc190501.51112402': __('identifierSchemeCodeUnspsc190501.51112402'),
	'identifierSchemeCodeUnspsc190501.51112403': __('identifierSchemeCodeUnspsc190501.51112403'),
	'identifierSchemeCodeUnspsc190501.51112404': __('identifierSchemeCodeUnspsc190501.51112404'),
	'identifierSchemeCodeUnspsc190501.51112405': __('identifierSchemeCodeUnspsc190501.51112405'),
	'identifierSchemeCodeUnspsc190501.51112500': __('identifierSchemeCodeUnspsc190501.51112500'),
	'identifierSchemeCodeUnspsc190501.51112501': __('identifierSchemeCodeUnspsc190501.51112501'),
	'identifierSchemeCodeUnspsc190501.51112502': __('identifierSchemeCodeUnspsc190501.51112502'),
	'identifierSchemeCodeUnspsc190501.51112503': __('identifierSchemeCodeUnspsc190501.51112503'),
	'identifierSchemeCodeUnspsc190501.51112504': __('identifierSchemeCodeUnspsc190501.51112504'),
	'identifierSchemeCodeUnspsc190501.51112505': __('identifierSchemeCodeUnspsc190501.51112505'),
	'identifierSchemeCodeUnspsc190501.51112506': __('identifierSchemeCodeUnspsc190501.51112506'),
	'identifierSchemeCodeUnspsc190501.51112507': __('identifierSchemeCodeUnspsc190501.51112507'),
	'identifierSchemeCodeUnspsc190501.51112508': __('identifierSchemeCodeUnspsc190501.51112508'),
	'identifierSchemeCodeUnspsc190501.51112509': __('identifierSchemeCodeUnspsc190501.51112509'),
	'identifierSchemeCodeUnspsc190501.51112510': __('identifierSchemeCodeUnspsc190501.51112510'),
	'identifierSchemeCodeUnspsc190501.51112511': __('identifierSchemeCodeUnspsc190501.51112511'),
	'identifierSchemeCodeUnspsc190501.51112512': __('identifierSchemeCodeUnspsc190501.51112512'),
	'identifierSchemeCodeUnspsc190501.51112513': __('identifierSchemeCodeUnspsc190501.51112513'),
	'identifierSchemeCodeUnspsc190501.51112514': __('identifierSchemeCodeUnspsc190501.51112514'),
	'identifierSchemeCodeUnspsc190501.51112515': __('identifierSchemeCodeUnspsc190501.51112515'),
	'identifierSchemeCodeUnspsc190501.51112516': __('identifierSchemeCodeUnspsc190501.51112516'),
	'identifierSchemeCodeUnspsc190501.51112517': __('identifierSchemeCodeUnspsc190501.51112517'),
	'identifierSchemeCodeUnspsc190501.51112518': __('identifierSchemeCodeUnspsc190501.51112518'),
	'identifierSchemeCodeUnspsc190501.51112519': __('identifierSchemeCodeUnspsc190501.51112519'),
	'identifierSchemeCodeUnspsc190501.51112520': __('identifierSchemeCodeUnspsc190501.51112520'),
	'identifierSchemeCodeUnspsc190501.51112521': __('identifierSchemeCodeUnspsc190501.51112521'),
	'identifierSchemeCodeUnspsc190501.51112522': __('identifierSchemeCodeUnspsc190501.51112522'),
	'identifierSchemeCodeUnspsc190501.51112523': __('identifierSchemeCodeUnspsc190501.51112523'),
	'identifierSchemeCodeUnspsc190501.51112524': __('identifierSchemeCodeUnspsc190501.51112524'),
	'identifierSchemeCodeUnspsc190501.51112525': __('identifierSchemeCodeUnspsc190501.51112525'),
	'identifierSchemeCodeUnspsc190501.51112526': __('identifierSchemeCodeUnspsc190501.51112526'),
	'identifierSchemeCodeUnspsc190501.51112527': __('identifierSchemeCodeUnspsc190501.51112527'),
	'identifierSchemeCodeUnspsc190501.51112528': __('identifierSchemeCodeUnspsc190501.51112528'),
	'identifierSchemeCodeUnspsc190501.51112600': __('identifierSchemeCodeUnspsc190501.51112600'),
	'identifierSchemeCodeUnspsc190501.51112601': __('identifierSchemeCodeUnspsc190501.51112601'),
	'identifierSchemeCodeUnspsc190501.51112602': __('identifierSchemeCodeUnspsc190501.51112602'),
	'identifierSchemeCodeUnspsc190501.51112603': __('identifierSchemeCodeUnspsc190501.51112603'),
	'identifierSchemeCodeUnspsc190501.51112604': __('identifierSchemeCodeUnspsc190501.51112604'),
	'identifierSchemeCodeUnspsc190501.51112605': __('identifierSchemeCodeUnspsc190501.51112605'),
	'identifierSchemeCodeUnspsc190501.51112606': __('identifierSchemeCodeUnspsc190501.51112606'),
	'identifierSchemeCodeUnspsc190501.51112607': __('identifierSchemeCodeUnspsc190501.51112607'),
	'identifierSchemeCodeUnspsc190501.51112608': __('identifierSchemeCodeUnspsc190501.51112608'),
	'identifierSchemeCodeUnspsc190501.51112609': __('identifierSchemeCodeUnspsc190501.51112609'),
	'identifierSchemeCodeUnspsc190501.51112610': __('identifierSchemeCodeUnspsc190501.51112610'),
	'identifierSchemeCodeUnspsc190501.51112611': __('identifierSchemeCodeUnspsc190501.51112611'),
	'identifierSchemeCodeUnspsc190501.51112612': __('identifierSchemeCodeUnspsc190501.51112612'),
	'identifierSchemeCodeUnspsc190501.51112613': __('identifierSchemeCodeUnspsc190501.51112613'),
	'identifierSchemeCodeUnspsc190501.51112700': __('identifierSchemeCodeUnspsc190501.51112700'),
	'identifierSchemeCodeUnspsc190501.51112701': __('identifierSchemeCodeUnspsc190501.51112701'),
	'identifierSchemeCodeUnspsc190501.51112702': __('identifierSchemeCodeUnspsc190501.51112702'),
	'identifierSchemeCodeUnspsc190501.51112703': __('identifierSchemeCodeUnspsc190501.51112703'),
	'identifierSchemeCodeUnspsc190501.51112704': __('identifierSchemeCodeUnspsc190501.51112704'),
	'identifierSchemeCodeUnspsc190501.51112705': __('identifierSchemeCodeUnspsc190501.51112705'),
	'identifierSchemeCodeUnspsc190501.51112706': __('identifierSchemeCodeUnspsc190501.51112706'),
	'identifierSchemeCodeUnspsc190501.51112707': __('identifierSchemeCodeUnspsc190501.51112707'),
	'identifierSchemeCodeUnspsc190501.51112708': __('identifierSchemeCodeUnspsc190501.51112708'),
	'identifierSchemeCodeUnspsc190501.51112800': __('identifierSchemeCodeUnspsc190501.51112800'),
	'identifierSchemeCodeUnspsc190501.51112801': __('identifierSchemeCodeUnspsc190501.51112801'),
	'identifierSchemeCodeUnspsc190501.51112802': __('identifierSchemeCodeUnspsc190501.51112802'),
	'identifierSchemeCodeUnspsc190501.51112803': __('identifierSchemeCodeUnspsc190501.51112803'),
	'identifierSchemeCodeUnspsc190501.51112804': __('identifierSchemeCodeUnspsc190501.51112804'),
	'identifierSchemeCodeUnspsc190501.51112805': __('identifierSchemeCodeUnspsc190501.51112805'),
	'identifierSchemeCodeUnspsc190501.51112900': __('identifierSchemeCodeUnspsc190501.51112900'),
	'identifierSchemeCodeUnspsc190501.51112901': __('identifierSchemeCodeUnspsc190501.51112901'),
	'identifierSchemeCodeUnspsc190501.51112902': __('identifierSchemeCodeUnspsc190501.51112902'),
	'identifierSchemeCodeUnspsc190501.51113000': __('identifierSchemeCodeUnspsc190501.51113000'),
	'identifierSchemeCodeUnspsc190501.51113001': __('identifierSchemeCodeUnspsc190501.51113001'),
	'identifierSchemeCodeUnspsc190501.51113002': __('identifierSchemeCodeUnspsc190501.51113002'),
	'identifierSchemeCodeUnspsc190501.51113100': __('identifierSchemeCodeUnspsc190501.51113100'),
	'identifierSchemeCodeUnspsc190501.51113101': __('identifierSchemeCodeUnspsc190501.51113101'),
	'identifierSchemeCodeUnspsc190501.51113102': __('identifierSchemeCodeUnspsc190501.51113102'),
	'identifierSchemeCodeUnspsc190501.51113103': __('identifierSchemeCodeUnspsc190501.51113103'),
	'identifierSchemeCodeUnspsc190501.51113104': __('identifierSchemeCodeUnspsc190501.51113104'),
	'identifierSchemeCodeUnspsc190501.51113200': __('identifierSchemeCodeUnspsc190501.51113200'),
	'identifierSchemeCodeUnspsc190501.51113201': __('identifierSchemeCodeUnspsc190501.51113201'),
	'identifierSchemeCodeUnspsc190501.51113202': __('identifierSchemeCodeUnspsc190501.51113202'),
	'identifierSchemeCodeUnspsc190501.51113203': __('identifierSchemeCodeUnspsc190501.51113203'),
	'identifierSchemeCodeUnspsc190501.51113204': __('identifierSchemeCodeUnspsc190501.51113204'),
	'identifierSchemeCodeUnspsc190501.51113205': __('identifierSchemeCodeUnspsc190501.51113205'),
	'identifierSchemeCodeUnspsc190501.51113206': __('identifierSchemeCodeUnspsc190501.51113206'),
	'identifierSchemeCodeUnspsc190501.51113207': __('identifierSchemeCodeUnspsc190501.51113207'),
	'identifierSchemeCodeUnspsc190501.51113208': __('identifierSchemeCodeUnspsc190501.51113208'),
	'identifierSchemeCodeUnspsc190501.51113300': __('identifierSchemeCodeUnspsc190501.51113300'),
	'identifierSchemeCodeUnspsc190501.51113301': __('identifierSchemeCodeUnspsc190501.51113301'),
	'identifierSchemeCodeUnspsc190501.51113302': __('identifierSchemeCodeUnspsc190501.51113302'),
	'identifierSchemeCodeUnspsc190501.51113303': __('identifierSchemeCodeUnspsc190501.51113303'),
	'identifierSchemeCodeUnspsc190501.51113400': __('identifierSchemeCodeUnspsc190501.51113400'),
	'identifierSchemeCodeUnspsc190501.51113401': __('identifierSchemeCodeUnspsc190501.51113401'),
	'identifierSchemeCodeUnspsc190501.51113402': __('identifierSchemeCodeUnspsc190501.51113402'),
	'identifierSchemeCodeUnspsc190501.51113500': __('identifierSchemeCodeUnspsc190501.51113500'),
	'identifierSchemeCodeUnspsc190501.51113501': __('identifierSchemeCodeUnspsc190501.51113501'),
	'identifierSchemeCodeUnspsc190501.51113502': __('identifierSchemeCodeUnspsc190501.51113502'),
	'identifierSchemeCodeUnspsc190501.51113600': __('identifierSchemeCodeUnspsc190501.51113600'),
	'identifierSchemeCodeUnspsc190501.51113601': __('identifierSchemeCodeUnspsc190501.51113601'),
	'identifierSchemeCodeUnspsc190501.51113602': __('identifierSchemeCodeUnspsc190501.51113602'),
	'identifierSchemeCodeUnspsc190501.51120000': __('identifierSchemeCodeUnspsc190501.51120000'),
	'identifierSchemeCodeUnspsc190501.51121500': __('identifierSchemeCodeUnspsc190501.51121500'),
	'identifierSchemeCodeUnspsc190501.51121518': __('identifierSchemeCodeUnspsc190501.51121518'),
	'identifierSchemeCodeUnspsc190501.51121536': __('identifierSchemeCodeUnspsc190501.51121536'),
	'identifierSchemeCodeUnspsc190501.51121566': __('identifierSchemeCodeUnspsc190501.51121566'),
	'identifierSchemeCodeUnspsc190501.51121583': __('identifierSchemeCodeUnspsc190501.51121583'),
	'identifierSchemeCodeUnspsc190501.51121600': __('identifierSchemeCodeUnspsc190501.51121600'),
	'identifierSchemeCodeUnspsc190501.51121604': __('identifierSchemeCodeUnspsc190501.51121604'),
	'identifierSchemeCodeUnspsc190501.51121616': __('identifierSchemeCodeUnspsc190501.51121616'),
	'identifierSchemeCodeUnspsc190501.51121617': __('identifierSchemeCodeUnspsc190501.51121617'),
	'identifierSchemeCodeUnspsc190501.51121900': __('identifierSchemeCodeUnspsc190501.51121900'),
	'identifierSchemeCodeUnspsc190501.51121907': __('identifierSchemeCodeUnspsc190501.51121907'),
	'identifierSchemeCodeUnspsc190501.51121908': __('identifierSchemeCodeUnspsc190501.51121908'),
	'identifierSchemeCodeUnspsc190501.51121910': __('identifierSchemeCodeUnspsc190501.51121910'),
	'identifierSchemeCodeUnspsc190501.51121911': __('identifierSchemeCodeUnspsc190501.51121911'),
	'identifierSchemeCodeUnspsc190501.51121912': __('identifierSchemeCodeUnspsc190501.51121912'),
	'identifierSchemeCodeUnspsc190501.51121914': __('identifierSchemeCodeUnspsc190501.51121914'),
	'identifierSchemeCodeUnspsc190501.51121915': __('identifierSchemeCodeUnspsc190501.51121915'),
	'identifierSchemeCodeUnspsc190501.51121916': __('identifierSchemeCodeUnspsc190501.51121916'),
	'identifierSchemeCodeUnspsc190501.51121917': __('identifierSchemeCodeUnspsc190501.51121917'),
	'identifierSchemeCodeUnspsc190501.51121918': __('identifierSchemeCodeUnspsc190501.51121918'),
	'identifierSchemeCodeUnspsc190501.51121919': __('identifierSchemeCodeUnspsc190501.51121919'),
	'identifierSchemeCodeUnspsc190501.51121920': __('identifierSchemeCodeUnspsc190501.51121920'),
	'identifierSchemeCodeUnspsc190501.51121921': __('identifierSchemeCodeUnspsc190501.51121921'),
	'identifierSchemeCodeUnspsc190501.51121922': __('identifierSchemeCodeUnspsc190501.51121922'),
	'identifierSchemeCodeUnspsc190501.51121923': __('identifierSchemeCodeUnspsc190501.51121923'),
	'identifierSchemeCodeUnspsc190501.51121924': __('identifierSchemeCodeUnspsc190501.51121924'),
	'identifierSchemeCodeUnspsc190501.51121925': __('identifierSchemeCodeUnspsc190501.51121925'),
	'identifierSchemeCodeUnspsc190501.51121926': __('identifierSchemeCodeUnspsc190501.51121926'),
	'identifierSchemeCodeUnspsc190501.51121927': __('identifierSchemeCodeUnspsc190501.51121927'),
	'identifierSchemeCodeUnspsc190501.51122300': __('identifierSchemeCodeUnspsc190501.51122300'),
	'identifierSchemeCodeUnspsc190501.51122301': __('identifierSchemeCodeUnspsc190501.51122301'),
	'identifierSchemeCodeUnspsc190501.51122400': __('identifierSchemeCodeUnspsc190501.51122400'),
	'identifierSchemeCodeUnspsc190501.51122401': __('identifierSchemeCodeUnspsc190501.51122401'),
	'identifierSchemeCodeUnspsc190501.51122402': __('identifierSchemeCodeUnspsc190501.51122402'),
	'identifierSchemeCodeUnspsc190501.51122403': __('identifierSchemeCodeUnspsc190501.51122403'),
	'identifierSchemeCodeUnspsc190501.51122404': __('identifierSchemeCodeUnspsc190501.51122404'),
	'identifierSchemeCodeUnspsc190501.51122405': __('identifierSchemeCodeUnspsc190501.51122405'),
	'identifierSchemeCodeUnspsc190501.51122406': __('identifierSchemeCodeUnspsc190501.51122406'),
	'identifierSchemeCodeUnspsc190501.51122500': __('identifierSchemeCodeUnspsc190501.51122500'),
	'identifierSchemeCodeUnspsc190501.51122501': __('identifierSchemeCodeUnspsc190501.51122501'),
	'identifierSchemeCodeUnspsc190501.51122600': __('identifierSchemeCodeUnspsc190501.51122600'),
	'identifierSchemeCodeUnspsc190501.51122601': __('identifierSchemeCodeUnspsc190501.51122601'),
	'identifierSchemeCodeUnspsc190501.51122602': __('identifierSchemeCodeUnspsc190501.51122602'),
	'identifierSchemeCodeUnspsc190501.51122603': __('identifierSchemeCodeUnspsc190501.51122603'),
	'identifierSchemeCodeUnspsc190501.51122604': __('identifierSchemeCodeUnspsc190501.51122604'),
	'identifierSchemeCodeUnspsc190501.51122605': __('identifierSchemeCodeUnspsc190501.51122605'),
	'identifierSchemeCodeUnspsc190501.51122700': __('identifierSchemeCodeUnspsc190501.51122700'),
	'identifierSchemeCodeUnspsc190501.51122701': __('identifierSchemeCodeUnspsc190501.51122701'),
	'identifierSchemeCodeUnspsc190501.51122702': __('identifierSchemeCodeUnspsc190501.51122702'),
	'identifierSchemeCodeUnspsc190501.51122800': __('identifierSchemeCodeUnspsc190501.51122800'),
	'identifierSchemeCodeUnspsc190501.51122801': __('identifierSchemeCodeUnspsc190501.51122801'),
	'identifierSchemeCodeUnspsc190501.51122802': __('identifierSchemeCodeUnspsc190501.51122802'),
	'identifierSchemeCodeUnspsc190501.51122803': __('identifierSchemeCodeUnspsc190501.51122803'),
	'identifierSchemeCodeUnspsc190501.51122804': __('identifierSchemeCodeUnspsc190501.51122804'),
	'identifierSchemeCodeUnspsc190501.51122805': __('identifierSchemeCodeUnspsc190501.51122805'),
	'identifierSchemeCodeUnspsc190501.51122806': __('identifierSchemeCodeUnspsc190501.51122806'),
	'identifierSchemeCodeUnspsc190501.51122807': __('identifierSchemeCodeUnspsc190501.51122807'),
	'identifierSchemeCodeUnspsc190501.51122900': __('identifierSchemeCodeUnspsc190501.51122900'),
	'identifierSchemeCodeUnspsc190501.51122901': __('identifierSchemeCodeUnspsc190501.51122901'),
	'identifierSchemeCodeUnspsc190501.51123000': __('identifierSchemeCodeUnspsc190501.51123000'),
	'identifierSchemeCodeUnspsc190501.51123001': __('identifierSchemeCodeUnspsc190501.51123001'),
	'identifierSchemeCodeUnspsc190501.51123002': __('identifierSchemeCodeUnspsc190501.51123002'),
	'identifierSchemeCodeUnspsc190501.51123003': __('identifierSchemeCodeUnspsc190501.51123003'),
	'identifierSchemeCodeUnspsc190501.51123004': __('identifierSchemeCodeUnspsc190501.51123004'),
	'identifierSchemeCodeUnspsc190501.51123005': __('identifierSchemeCodeUnspsc190501.51123005'),
	'identifierSchemeCodeUnspsc190501.51123006': __('identifierSchemeCodeUnspsc190501.51123006'),
	'identifierSchemeCodeUnspsc190501.51123100': __('identifierSchemeCodeUnspsc190501.51123100'),
	'identifierSchemeCodeUnspsc190501.51123101': __('identifierSchemeCodeUnspsc190501.51123101'),
	'identifierSchemeCodeUnspsc190501.51123102': __('identifierSchemeCodeUnspsc190501.51123102'),
	'identifierSchemeCodeUnspsc190501.51123103': __('identifierSchemeCodeUnspsc190501.51123103'),
	'identifierSchemeCodeUnspsc190501.51123104': __('identifierSchemeCodeUnspsc190501.51123104'),
	'identifierSchemeCodeUnspsc190501.51123105': __('identifierSchemeCodeUnspsc190501.51123105'),
	'identifierSchemeCodeUnspsc190501.51123106': __('identifierSchemeCodeUnspsc190501.51123106'),
	'identifierSchemeCodeUnspsc190501.51123107': __('identifierSchemeCodeUnspsc190501.51123107'),
	'identifierSchemeCodeUnspsc190501.51123108': __('identifierSchemeCodeUnspsc190501.51123108'),
	'identifierSchemeCodeUnspsc190501.51123109': __('identifierSchemeCodeUnspsc190501.51123109'),
	'identifierSchemeCodeUnspsc190501.51123110': __('identifierSchemeCodeUnspsc190501.51123110'),
	'identifierSchemeCodeUnspsc190501.51123111': __('identifierSchemeCodeUnspsc190501.51123111'),
	'identifierSchemeCodeUnspsc190501.51123112': __('identifierSchemeCodeUnspsc190501.51123112'),
	'identifierSchemeCodeUnspsc190501.51123113': __('identifierSchemeCodeUnspsc190501.51123113'),
	'identifierSchemeCodeUnspsc190501.51123114': __('identifierSchemeCodeUnspsc190501.51123114'),
	'identifierSchemeCodeUnspsc190501.51123115': __('identifierSchemeCodeUnspsc190501.51123115'),
	'identifierSchemeCodeUnspsc190501.51123116': __('identifierSchemeCodeUnspsc190501.51123116'),
	'identifierSchemeCodeUnspsc190501.51123117': __('identifierSchemeCodeUnspsc190501.51123117'),
	'identifierSchemeCodeUnspsc190501.51123118': __('identifierSchemeCodeUnspsc190501.51123118'),
	'identifierSchemeCodeUnspsc190501.51123119': __('identifierSchemeCodeUnspsc190501.51123119'),
	'identifierSchemeCodeUnspsc190501.51123120': __('identifierSchemeCodeUnspsc190501.51123120'),
	'identifierSchemeCodeUnspsc190501.51123200': __('identifierSchemeCodeUnspsc190501.51123200'),
	'identifierSchemeCodeUnspsc190501.51123201': __('identifierSchemeCodeUnspsc190501.51123201'),
	'identifierSchemeCodeUnspsc190501.51123202': __('identifierSchemeCodeUnspsc190501.51123202'),
	'identifierSchemeCodeUnspsc190501.51123203': __('identifierSchemeCodeUnspsc190501.51123203'),
	'identifierSchemeCodeUnspsc190501.51123204': __('identifierSchemeCodeUnspsc190501.51123204'),
	'identifierSchemeCodeUnspsc190501.51123205': __('identifierSchemeCodeUnspsc190501.51123205'),
	'identifierSchemeCodeUnspsc190501.51123206': __('identifierSchemeCodeUnspsc190501.51123206'),
	'identifierSchemeCodeUnspsc190501.51123207': __('identifierSchemeCodeUnspsc190501.51123207'),
	'identifierSchemeCodeUnspsc190501.51123300': __('identifierSchemeCodeUnspsc190501.51123300'),
	'identifierSchemeCodeUnspsc190501.51123301': __('identifierSchemeCodeUnspsc190501.51123301'),
	'identifierSchemeCodeUnspsc190501.51123302': __('identifierSchemeCodeUnspsc190501.51123302'),
	'identifierSchemeCodeUnspsc190501.51123400': __('identifierSchemeCodeUnspsc190501.51123400'),
	'identifierSchemeCodeUnspsc190501.51123401': __('identifierSchemeCodeUnspsc190501.51123401'),
	'identifierSchemeCodeUnspsc190501.51123402': __('identifierSchemeCodeUnspsc190501.51123402'),
	'identifierSchemeCodeUnspsc190501.51123500': __('identifierSchemeCodeUnspsc190501.51123500'),
	'identifierSchemeCodeUnspsc190501.51123501': __('identifierSchemeCodeUnspsc190501.51123501'),
	'identifierSchemeCodeUnspsc190501.51123502': __('identifierSchemeCodeUnspsc190501.51123502'),
	'identifierSchemeCodeUnspsc190501.51123600': __('identifierSchemeCodeUnspsc190501.51123600'),
	'identifierSchemeCodeUnspsc190501.51123601': __('identifierSchemeCodeUnspsc190501.51123601'),
	'identifierSchemeCodeUnspsc190501.51123602': __('identifierSchemeCodeUnspsc190501.51123602'),
	'identifierSchemeCodeUnspsc190501.51123700': __('identifierSchemeCodeUnspsc190501.51123700'),
	'identifierSchemeCodeUnspsc190501.51123701': __('identifierSchemeCodeUnspsc190501.51123701'),
	'identifierSchemeCodeUnspsc190501.51123702': __('identifierSchemeCodeUnspsc190501.51123702'),
	'identifierSchemeCodeUnspsc190501.51123703': __('identifierSchemeCodeUnspsc190501.51123703'),
	'identifierSchemeCodeUnspsc190501.51123704': __('identifierSchemeCodeUnspsc190501.51123704'),
	'identifierSchemeCodeUnspsc190501.51123705': __('identifierSchemeCodeUnspsc190501.51123705'),
	'identifierSchemeCodeUnspsc190501.51123706': __('identifierSchemeCodeUnspsc190501.51123706'),
	'identifierSchemeCodeUnspsc190501.51123707': __('identifierSchemeCodeUnspsc190501.51123707'),
	'identifierSchemeCodeUnspsc190501.51123708': __('identifierSchemeCodeUnspsc190501.51123708'),
	'identifierSchemeCodeUnspsc190501.51123709': __('identifierSchemeCodeUnspsc190501.51123709'),
	'identifierSchemeCodeUnspsc190501.51123800': __('identifierSchemeCodeUnspsc190501.51123800'),
	'identifierSchemeCodeUnspsc190501.51123801': __('identifierSchemeCodeUnspsc190501.51123801'),
	'identifierSchemeCodeUnspsc190501.51123802': __('identifierSchemeCodeUnspsc190501.51123802'),
	'identifierSchemeCodeUnspsc190501.51123900': __('identifierSchemeCodeUnspsc190501.51123900'),
	'identifierSchemeCodeUnspsc190501.51123901': __('identifierSchemeCodeUnspsc190501.51123901'),
	'identifierSchemeCodeUnspsc190501.51123902': __('identifierSchemeCodeUnspsc190501.51123902'),
	'identifierSchemeCodeUnspsc190501.51123903': __('identifierSchemeCodeUnspsc190501.51123903'),
	'identifierSchemeCodeUnspsc190501.51123904': __('identifierSchemeCodeUnspsc190501.51123904'),
	'identifierSchemeCodeUnspsc190501.51123905': __('identifierSchemeCodeUnspsc190501.51123905'),
	'identifierSchemeCodeUnspsc190501.51123906': __('identifierSchemeCodeUnspsc190501.51123906'),
	'identifierSchemeCodeUnspsc190501.51124000': __('identifierSchemeCodeUnspsc190501.51124000'),
	'identifierSchemeCodeUnspsc190501.51124001': __('identifierSchemeCodeUnspsc190501.51124001'),
	'identifierSchemeCodeUnspsc190501.51124002': __('identifierSchemeCodeUnspsc190501.51124002'),
	'identifierSchemeCodeUnspsc190501.51124003': __('identifierSchemeCodeUnspsc190501.51124003'),
	'identifierSchemeCodeUnspsc190501.51124004': __('identifierSchemeCodeUnspsc190501.51124004'),
	'identifierSchemeCodeUnspsc190501.51124005': __('identifierSchemeCodeUnspsc190501.51124005'),
	'identifierSchemeCodeUnspsc190501.51124006': __('identifierSchemeCodeUnspsc190501.51124006'),
	'identifierSchemeCodeUnspsc190501.51124007': __('identifierSchemeCodeUnspsc190501.51124007'),
	'identifierSchemeCodeUnspsc190501.51124008': __('identifierSchemeCodeUnspsc190501.51124008'),
	'identifierSchemeCodeUnspsc190501.51124009': __('identifierSchemeCodeUnspsc190501.51124009'),
	'identifierSchemeCodeUnspsc190501.51124100': __('identifierSchemeCodeUnspsc190501.51124100'),
	'identifierSchemeCodeUnspsc190501.51124101': __('identifierSchemeCodeUnspsc190501.51124101'),
	'identifierSchemeCodeUnspsc190501.51124102': __('identifierSchemeCodeUnspsc190501.51124102'),
	'identifierSchemeCodeUnspsc190501.51124103': __('identifierSchemeCodeUnspsc190501.51124103'),
	'identifierSchemeCodeUnspsc190501.51124104': __('identifierSchemeCodeUnspsc190501.51124104'),
	'identifierSchemeCodeUnspsc190501.51124200': __('identifierSchemeCodeUnspsc190501.51124200'),
	'identifierSchemeCodeUnspsc190501.51124201': __('identifierSchemeCodeUnspsc190501.51124201'),
	'identifierSchemeCodeUnspsc190501.51124202': __('identifierSchemeCodeUnspsc190501.51124202'),
	'identifierSchemeCodeUnspsc190501.51124203': __('identifierSchemeCodeUnspsc190501.51124203'),
	'identifierSchemeCodeUnspsc190501.51124300': __('identifierSchemeCodeUnspsc190501.51124300'),
	'identifierSchemeCodeUnspsc190501.51124301': __('identifierSchemeCodeUnspsc190501.51124301'),
	'identifierSchemeCodeUnspsc190501.51124302': __('identifierSchemeCodeUnspsc190501.51124302'),
	'identifierSchemeCodeUnspsc190501.51124303': __('identifierSchemeCodeUnspsc190501.51124303'),
	'identifierSchemeCodeUnspsc190501.51124304': __('identifierSchemeCodeUnspsc190501.51124304'),
	'identifierSchemeCodeUnspsc190501.51124305': __('identifierSchemeCodeUnspsc190501.51124305'),
	'identifierSchemeCodeUnspsc190501.51124306': __('identifierSchemeCodeUnspsc190501.51124306'),
	'identifierSchemeCodeUnspsc190501.51124400': __('identifierSchemeCodeUnspsc190501.51124400'),
	'identifierSchemeCodeUnspsc190501.51124401': __('identifierSchemeCodeUnspsc190501.51124401'),
	'identifierSchemeCodeUnspsc190501.51124402': __('identifierSchemeCodeUnspsc190501.51124402'),
	'identifierSchemeCodeUnspsc190501.51124403': __('identifierSchemeCodeUnspsc190501.51124403'),
	'identifierSchemeCodeUnspsc190501.51124404': __('identifierSchemeCodeUnspsc190501.51124404'),
	'identifierSchemeCodeUnspsc190501.51124405': __('identifierSchemeCodeUnspsc190501.51124405'),
	'identifierSchemeCodeUnspsc190501.51124500': __('identifierSchemeCodeUnspsc190501.51124500'),
	'identifierSchemeCodeUnspsc190501.51124501': __('identifierSchemeCodeUnspsc190501.51124501'),
	'identifierSchemeCodeUnspsc190501.51124502': __('identifierSchemeCodeUnspsc190501.51124502'),
	'identifierSchemeCodeUnspsc190501.51124503': __('identifierSchemeCodeUnspsc190501.51124503'),
	'identifierSchemeCodeUnspsc190501.51130000': __('identifierSchemeCodeUnspsc190501.51130000'),
	'identifierSchemeCodeUnspsc190501.51131500': __('identifierSchemeCodeUnspsc190501.51131500'),
	'identifierSchemeCodeUnspsc190501.51131504': __('identifierSchemeCodeUnspsc190501.51131504'),
	'identifierSchemeCodeUnspsc190501.51131506': __('identifierSchemeCodeUnspsc190501.51131506'),
	'identifierSchemeCodeUnspsc190501.51131507': __('identifierSchemeCodeUnspsc190501.51131507'),
	'identifierSchemeCodeUnspsc190501.51131508': __('identifierSchemeCodeUnspsc190501.51131508'),
	'identifierSchemeCodeUnspsc190501.51131509': __('identifierSchemeCodeUnspsc190501.51131509'),
	'identifierSchemeCodeUnspsc190501.51131510': __('identifierSchemeCodeUnspsc190501.51131510'),
	'identifierSchemeCodeUnspsc190501.51131514': __('identifierSchemeCodeUnspsc190501.51131514'),
	'identifierSchemeCodeUnspsc190501.51131515': __('identifierSchemeCodeUnspsc190501.51131515'),
	'identifierSchemeCodeUnspsc190501.51131519': __('identifierSchemeCodeUnspsc190501.51131519'),
	'identifierSchemeCodeUnspsc190501.51131520': __('identifierSchemeCodeUnspsc190501.51131520'),
	'identifierSchemeCodeUnspsc190501.51131521': __('identifierSchemeCodeUnspsc190501.51131521'),
	'identifierSchemeCodeUnspsc190501.51131522': __('identifierSchemeCodeUnspsc190501.51131522'),
	'identifierSchemeCodeUnspsc190501.51131600': __('identifierSchemeCodeUnspsc190501.51131600'),
	'identifierSchemeCodeUnspsc190501.51131602': __('identifierSchemeCodeUnspsc190501.51131602'),
	'identifierSchemeCodeUnspsc190501.51131604': __('identifierSchemeCodeUnspsc190501.51131604'),
	'identifierSchemeCodeUnspsc190501.51131605': __('identifierSchemeCodeUnspsc190501.51131605'),
	'identifierSchemeCodeUnspsc190501.51131606': __('identifierSchemeCodeUnspsc190501.51131606'),
	'identifierSchemeCodeUnspsc190501.51131607': __('identifierSchemeCodeUnspsc190501.51131607'),
	'identifierSchemeCodeUnspsc190501.51131608': __('identifierSchemeCodeUnspsc190501.51131608'),
	'identifierSchemeCodeUnspsc190501.51131609': __('identifierSchemeCodeUnspsc190501.51131609'),
	'identifierSchemeCodeUnspsc190501.51131610': __('identifierSchemeCodeUnspsc190501.51131610'),
	'identifierSchemeCodeUnspsc190501.51131611': __('identifierSchemeCodeUnspsc190501.51131611'),
	'identifierSchemeCodeUnspsc190501.51131613': __('identifierSchemeCodeUnspsc190501.51131613'),
	'identifierSchemeCodeUnspsc190501.51131614': __('identifierSchemeCodeUnspsc190501.51131614'),
	'identifierSchemeCodeUnspsc190501.51131615': __('identifierSchemeCodeUnspsc190501.51131615'),
	'identifierSchemeCodeUnspsc190501.51131617': __('identifierSchemeCodeUnspsc190501.51131617'),
	'identifierSchemeCodeUnspsc190501.51131618': __('identifierSchemeCodeUnspsc190501.51131618'),
	'identifierSchemeCodeUnspsc190501.51131619': __('identifierSchemeCodeUnspsc190501.51131619'),
	'identifierSchemeCodeUnspsc190501.51131620': __('identifierSchemeCodeUnspsc190501.51131620'),
	'identifierSchemeCodeUnspsc190501.51131621': __('identifierSchemeCodeUnspsc190501.51131621'),
	'identifierSchemeCodeUnspsc190501.51131622': __('identifierSchemeCodeUnspsc190501.51131622'),
	'identifierSchemeCodeUnspsc190501.51131623': __('identifierSchemeCodeUnspsc190501.51131623'),
	'identifierSchemeCodeUnspsc190501.51131624': __('identifierSchemeCodeUnspsc190501.51131624'),
	'identifierSchemeCodeUnspsc190501.51131625': __('identifierSchemeCodeUnspsc190501.51131625'),
	'identifierSchemeCodeUnspsc190501.51131626': __('identifierSchemeCodeUnspsc190501.51131626'),
	'identifierSchemeCodeUnspsc190501.51131627': __('identifierSchemeCodeUnspsc190501.51131627'),
	'identifierSchemeCodeUnspsc190501.51131628': __('identifierSchemeCodeUnspsc190501.51131628'),
	'identifierSchemeCodeUnspsc190501.51131629': __('identifierSchemeCodeUnspsc190501.51131629'),
	'identifierSchemeCodeUnspsc190501.51131630': __('identifierSchemeCodeUnspsc190501.51131630'),
	'identifierSchemeCodeUnspsc190501.51131631': __('identifierSchemeCodeUnspsc190501.51131631'),
	'identifierSchemeCodeUnspsc190501.51131632': __('identifierSchemeCodeUnspsc190501.51131632'),
	'identifierSchemeCodeUnspsc190501.51131633': __('identifierSchemeCodeUnspsc190501.51131633'),
	'identifierSchemeCodeUnspsc190501.51131634': __('identifierSchemeCodeUnspsc190501.51131634'),
	'identifierSchemeCodeUnspsc190501.51131635': __('identifierSchemeCodeUnspsc190501.51131635'),
	'identifierSchemeCodeUnspsc190501.51131636': __('identifierSchemeCodeUnspsc190501.51131636'),
	'identifierSchemeCodeUnspsc190501.51131637': __('identifierSchemeCodeUnspsc190501.51131637'),
	'identifierSchemeCodeUnspsc190501.51131638': __('identifierSchemeCodeUnspsc190501.51131638'),
	'identifierSchemeCodeUnspsc190501.51131639': __('identifierSchemeCodeUnspsc190501.51131639'),
	'identifierSchemeCodeUnspsc190501.51131640': __('identifierSchemeCodeUnspsc190501.51131640'),
	'identifierSchemeCodeUnspsc190501.51131641': __('identifierSchemeCodeUnspsc190501.51131641'),
	'identifierSchemeCodeUnspsc190501.51131642': __('identifierSchemeCodeUnspsc190501.51131642'),
	'identifierSchemeCodeUnspsc190501.51131643': __('identifierSchemeCodeUnspsc190501.51131643'),
	'identifierSchemeCodeUnspsc190501.51131644': __('identifierSchemeCodeUnspsc190501.51131644'),
	'identifierSchemeCodeUnspsc190501.51131645': __('identifierSchemeCodeUnspsc190501.51131645'),
	'identifierSchemeCodeUnspsc190501.51131646': __('identifierSchemeCodeUnspsc190501.51131646'),
	'identifierSchemeCodeUnspsc190501.51131647': __('identifierSchemeCodeUnspsc190501.51131647'),
	'identifierSchemeCodeUnspsc190501.51131648': __('identifierSchemeCodeUnspsc190501.51131648'),
	'identifierSchemeCodeUnspsc190501.51131649': __('identifierSchemeCodeUnspsc190501.51131649'),
	'identifierSchemeCodeUnspsc190501.51131650': __('identifierSchemeCodeUnspsc190501.51131650'),
	'identifierSchemeCodeUnspsc190501.51131651': __('identifierSchemeCodeUnspsc190501.51131651'),
	'identifierSchemeCodeUnspsc190501.51131652': __('identifierSchemeCodeUnspsc190501.51131652'),
	'identifierSchemeCodeUnspsc190501.51131653': __('identifierSchemeCodeUnspsc190501.51131653'),
	'identifierSchemeCodeUnspsc190501.51131654': __('identifierSchemeCodeUnspsc190501.51131654'),
	'identifierSchemeCodeUnspsc190501.51131655': __('identifierSchemeCodeUnspsc190501.51131655'),
	'identifierSchemeCodeUnspsc190501.51131656': __('identifierSchemeCodeUnspsc190501.51131656'),
	'identifierSchemeCodeUnspsc190501.51131657': __('identifierSchemeCodeUnspsc190501.51131657'),
	'identifierSchemeCodeUnspsc190501.51131658': __('identifierSchemeCodeUnspsc190501.51131658'),
	'identifierSchemeCodeUnspsc190501.51131659': __('identifierSchemeCodeUnspsc190501.51131659'),
	'identifierSchemeCodeUnspsc190501.51131660': __('identifierSchemeCodeUnspsc190501.51131660'),
	'identifierSchemeCodeUnspsc190501.51131661': __('identifierSchemeCodeUnspsc190501.51131661'),
	'identifierSchemeCodeUnspsc190501.51131662': __('identifierSchemeCodeUnspsc190501.51131662'),
	'identifierSchemeCodeUnspsc190501.51131663': __('identifierSchemeCodeUnspsc190501.51131663'),
	'identifierSchemeCodeUnspsc190501.51131700': __('identifierSchemeCodeUnspsc190501.51131700'),
	'identifierSchemeCodeUnspsc190501.51131701': __('identifierSchemeCodeUnspsc190501.51131701'),
	'identifierSchemeCodeUnspsc190501.51131703': __('identifierSchemeCodeUnspsc190501.51131703'),
	'identifierSchemeCodeUnspsc190501.51131704': __('identifierSchemeCodeUnspsc190501.51131704'),
	'identifierSchemeCodeUnspsc190501.51131705': __('identifierSchemeCodeUnspsc190501.51131705'),
	'identifierSchemeCodeUnspsc190501.51131706': __('identifierSchemeCodeUnspsc190501.51131706'),
	'identifierSchemeCodeUnspsc190501.51131710': __('identifierSchemeCodeUnspsc190501.51131710'),
	'identifierSchemeCodeUnspsc190501.51131711': __('identifierSchemeCodeUnspsc190501.51131711'),
	'identifierSchemeCodeUnspsc190501.51131714': __('identifierSchemeCodeUnspsc190501.51131714'),
	'identifierSchemeCodeUnspsc190501.51131716': __('identifierSchemeCodeUnspsc190501.51131716'),
	'identifierSchemeCodeUnspsc190501.51131717': __('identifierSchemeCodeUnspsc190501.51131717'),
	'identifierSchemeCodeUnspsc190501.51131718': __('identifierSchemeCodeUnspsc190501.51131718'),
	'identifierSchemeCodeUnspsc190501.51131719': __('identifierSchemeCodeUnspsc190501.51131719'),
	'identifierSchemeCodeUnspsc190501.51131720': __('identifierSchemeCodeUnspsc190501.51131720'),
	'identifierSchemeCodeUnspsc190501.51131721': __('identifierSchemeCodeUnspsc190501.51131721'),
	'identifierSchemeCodeUnspsc190501.51131722': __('identifierSchemeCodeUnspsc190501.51131722'),
	'identifierSchemeCodeUnspsc190501.51131723': __('identifierSchemeCodeUnspsc190501.51131723'),
	'identifierSchemeCodeUnspsc190501.51131724': __('identifierSchemeCodeUnspsc190501.51131724'),
	'identifierSchemeCodeUnspsc190501.51131725': __('identifierSchemeCodeUnspsc190501.51131725'),
	'identifierSchemeCodeUnspsc190501.51131726': __('identifierSchemeCodeUnspsc190501.51131726'),
	'identifierSchemeCodeUnspsc190501.51131727': __('identifierSchemeCodeUnspsc190501.51131727'),
	'identifierSchemeCodeUnspsc190501.51131728': __('identifierSchemeCodeUnspsc190501.51131728'),
	'identifierSchemeCodeUnspsc190501.51131729': __('identifierSchemeCodeUnspsc190501.51131729'),
	'identifierSchemeCodeUnspsc190501.51131730': __('identifierSchemeCodeUnspsc190501.51131730'),
	'identifierSchemeCodeUnspsc190501.51131731': __('identifierSchemeCodeUnspsc190501.51131731'),
	'identifierSchemeCodeUnspsc190501.51131732': __('identifierSchemeCodeUnspsc190501.51131732'),
	'identifierSchemeCodeUnspsc190501.51131733': __('identifierSchemeCodeUnspsc190501.51131733'),
	'identifierSchemeCodeUnspsc190501.51131734': __('identifierSchemeCodeUnspsc190501.51131734'),
	'identifierSchemeCodeUnspsc190501.51131735': __('identifierSchemeCodeUnspsc190501.51131735'),
	'identifierSchemeCodeUnspsc190501.51131736': __('identifierSchemeCodeUnspsc190501.51131736'),
	'identifierSchemeCodeUnspsc190501.51131737': __('identifierSchemeCodeUnspsc190501.51131737'),
	'identifierSchemeCodeUnspsc190501.51131738': __('identifierSchemeCodeUnspsc190501.51131738'),
	'identifierSchemeCodeUnspsc190501.51131739': __('identifierSchemeCodeUnspsc190501.51131739'),
	'identifierSchemeCodeUnspsc190501.51131740': __('identifierSchemeCodeUnspsc190501.51131740'),
	'identifierSchemeCodeUnspsc190501.51131741': __('identifierSchemeCodeUnspsc190501.51131741'),
	'identifierSchemeCodeUnspsc190501.51131742': __('identifierSchemeCodeUnspsc190501.51131742'),
	'identifierSchemeCodeUnspsc190501.51131743': __('identifierSchemeCodeUnspsc190501.51131743'),
	'identifierSchemeCodeUnspsc190501.51131744': __('identifierSchemeCodeUnspsc190501.51131744'),
	'identifierSchemeCodeUnspsc190501.51131745': __('identifierSchemeCodeUnspsc190501.51131745'),
	'identifierSchemeCodeUnspsc190501.51131746': __('identifierSchemeCodeUnspsc190501.51131746'),
	'identifierSchemeCodeUnspsc190501.51131747': __('identifierSchemeCodeUnspsc190501.51131747'),
	'identifierSchemeCodeUnspsc190501.51131748': __('identifierSchemeCodeUnspsc190501.51131748'),
	'identifierSchemeCodeUnspsc190501.51131749': __('identifierSchemeCodeUnspsc190501.51131749'),
	'identifierSchemeCodeUnspsc190501.51131750': __('identifierSchemeCodeUnspsc190501.51131750'),
	'identifierSchemeCodeUnspsc190501.51131751': __('identifierSchemeCodeUnspsc190501.51131751'),
	'identifierSchemeCodeUnspsc190501.51131752': __('identifierSchemeCodeUnspsc190501.51131752'),
	'identifierSchemeCodeUnspsc190501.51131753': __('identifierSchemeCodeUnspsc190501.51131753'),
	'identifierSchemeCodeUnspsc190501.51131754': __('identifierSchemeCodeUnspsc190501.51131754'),
	'identifierSchemeCodeUnspsc190501.51131800': __('identifierSchemeCodeUnspsc190501.51131800'),
	'identifierSchemeCodeUnspsc190501.51131801': __('identifierSchemeCodeUnspsc190501.51131801'),
	'identifierSchemeCodeUnspsc190501.51131802': __('identifierSchemeCodeUnspsc190501.51131802'),
	'identifierSchemeCodeUnspsc190501.51131803': __('identifierSchemeCodeUnspsc190501.51131803'),
	'identifierSchemeCodeUnspsc190501.51131805': __('identifierSchemeCodeUnspsc190501.51131805'),
	'identifierSchemeCodeUnspsc190501.51131806': __('identifierSchemeCodeUnspsc190501.51131806'),
	'identifierSchemeCodeUnspsc190501.51131808': __('identifierSchemeCodeUnspsc190501.51131808'),
	'identifierSchemeCodeUnspsc190501.51131809': __('identifierSchemeCodeUnspsc190501.51131809'),
	'identifierSchemeCodeUnspsc190501.51131811': __('identifierSchemeCodeUnspsc190501.51131811'),
	'identifierSchemeCodeUnspsc190501.51131812': __('identifierSchemeCodeUnspsc190501.51131812'),
	'identifierSchemeCodeUnspsc190501.51131813': __('identifierSchemeCodeUnspsc190501.51131813'),
	'identifierSchemeCodeUnspsc190501.51131814': __('identifierSchemeCodeUnspsc190501.51131814'),
	'identifierSchemeCodeUnspsc190501.51131815': __('identifierSchemeCodeUnspsc190501.51131815'),
	'identifierSchemeCodeUnspsc190501.51131816': __('identifierSchemeCodeUnspsc190501.51131816'),
	'identifierSchemeCodeUnspsc190501.51131817': __('identifierSchemeCodeUnspsc190501.51131817'),
	'identifierSchemeCodeUnspsc190501.51131818': __('identifierSchemeCodeUnspsc190501.51131818'),
	'identifierSchemeCodeUnspsc190501.51131819': __('identifierSchemeCodeUnspsc190501.51131819'),
	'identifierSchemeCodeUnspsc190501.51131820': __('identifierSchemeCodeUnspsc190501.51131820'),
	'identifierSchemeCodeUnspsc190501.51131821': __('identifierSchemeCodeUnspsc190501.51131821'),
	'identifierSchemeCodeUnspsc190501.51131822': __('identifierSchemeCodeUnspsc190501.51131822'),
	'identifierSchemeCodeUnspsc190501.51131823': __('identifierSchemeCodeUnspsc190501.51131823'),
	'identifierSchemeCodeUnspsc190501.51131824': __('identifierSchemeCodeUnspsc190501.51131824'),
	'identifierSchemeCodeUnspsc190501.51131825': __('identifierSchemeCodeUnspsc190501.51131825'),
	'identifierSchemeCodeUnspsc190501.51131826': __('identifierSchemeCodeUnspsc190501.51131826'),
	'identifierSchemeCodeUnspsc190501.51131827': __('identifierSchemeCodeUnspsc190501.51131827'),
	'identifierSchemeCodeUnspsc190501.51131900': __('identifierSchemeCodeUnspsc190501.51131900'),
	'identifierSchemeCodeUnspsc190501.51131901': __('identifierSchemeCodeUnspsc190501.51131901'),
	'identifierSchemeCodeUnspsc190501.51131904': __('identifierSchemeCodeUnspsc190501.51131904'),
	'identifierSchemeCodeUnspsc190501.51131906': __('identifierSchemeCodeUnspsc190501.51131906'),
	'identifierSchemeCodeUnspsc190501.51131907': __('identifierSchemeCodeUnspsc190501.51131907'),
	'identifierSchemeCodeUnspsc190501.51131908': __('identifierSchemeCodeUnspsc190501.51131908'),
	'identifierSchemeCodeUnspsc190501.51131909': __('identifierSchemeCodeUnspsc190501.51131909'),
	'identifierSchemeCodeUnspsc190501.51131911': __('identifierSchemeCodeUnspsc190501.51131911'),
	'identifierSchemeCodeUnspsc190501.51132000': __('identifierSchemeCodeUnspsc190501.51132000'),
	'identifierSchemeCodeUnspsc190501.51132001': __('identifierSchemeCodeUnspsc190501.51132001'),
	'identifierSchemeCodeUnspsc190501.51132100': __('identifierSchemeCodeUnspsc190501.51132100'),
	'identifierSchemeCodeUnspsc190501.51132101': __('identifierSchemeCodeUnspsc190501.51132101'),
	'identifierSchemeCodeUnspsc190501.51132102': __('identifierSchemeCodeUnspsc190501.51132102'),
	'identifierSchemeCodeUnspsc190501.51132200': __('identifierSchemeCodeUnspsc190501.51132200'),
	'identifierSchemeCodeUnspsc190501.51132201': __('identifierSchemeCodeUnspsc190501.51132201'),
	'identifierSchemeCodeUnspsc190501.51132202': __('identifierSchemeCodeUnspsc190501.51132202'),
	'identifierSchemeCodeUnspsc190501.51132203': __('identifierSchemeCodeUnspsc190501.51132203'),
	'identifierSchemeCodeUnspsc190501.51132204': __('identifierSchemeCodeUnspsc190501.51132204'),
	'identifierSchemeCodeUnspsc190501.51132205': __('identifierSchemeCodeUnspsc190501.51132205'),
	'identifierSchemeCodeUnspsc190501.51132206': __('identifierSchemeCodeUnspsc190501.51132206'),
	'identifierSchemeCodeUnspsc190501.51132207': __('identifierSchemeCodeUnspsc190501.51132207'),
	'identifierSchemeCodeUnspsc190501.51132208': __('identifierSchemeCodeUnspsc190501.51132208'),
	'identifierSchemeCodeUnspsc190501.51132209': __('identifierSchemeCodeUnspsc190501.51132209'),
	'identifierSchemeCodeUnspsc190501.51132210': __('identifierSchemeCodeUnspsc190501.51132210'),
	'identifierSchemeCodeUnspsc190501.51132211': __('identifierSchemeCodeUnspsc190501.51132211'),
	'identifierSchemeCodeUnspsc190501.51132212': __('identifierSchemeCodeUnspsc190501.51132212'),
	'identifierSchemeCodeUnspsc190501.51132213': __('identifierSchemeCodeUnspsc190501.51132213'),
	'identifierSchemeCodeUnspsc190501.51132214': __('identifierSchemeCodeUnspsc190501.51132214'),
	'identifierSchemeCodeUnspsc190501.51132215': __('identifierSchemeCodeUnspsc190501.51132215'),
	'identifierSchemeCodeUnspsc190501.51132216': __('identifierSchemeCodeUnspsc190501.51132216'),
	'identifierSchemeCodeUnspsc190501.51132217': __('identifierSchemeCodeUnspsc190501.51132217'),
	'identifierSchemeCodeUnspsc190501.51132218': __('identifierSchemeCodeUnspsc190501.51132218'),
	'identifierSchemeCodeUnspsc190501.51132219': __('identifierSchemeCodeUnspsc190501.51132219'),
	'identifierSchemeCodeUnspsc190501.51132220': __('identifierSchemeCodeUnspsc190501.51132220'),
	'identifierSchemeCodeUnspsc190501.51132221': __('identifierSchemeCodeUnspsc190501.51132221'),
	'identifierSchemeCodeUnspsc190501.51132222': __('identifierSchemeCodeUnspsc190501.51132222'),
	'identifierSchemeCodeUnspsc190501.51132223': __('identifierSchemeCodeUnspsc190501.51132223'),
	'identifierSchemeCodeUnspsc190501.51132224': __('identifierSchemeCodeUnspsc190501.51132224'),
	'identifierSchemeCodeUnspsc190501.51132225': __('identifierSchemeCodeUnspsc190501.51132225'),
	'identifierSchemeCodeUnspsc190501.51132226': __('identifierSchemeCodeUnspsc190501.51132226'),
	'identifierSchemeCodeUnspsc190501.51132227': __('identifierSchemeCodeUnspsc190501.51132227'),
	'identifierSchemeCodeUnspsc190501.51132228': __('identifierSchemeCodeUnspsc190501.51132228'),
	'identifierSchemeCodeUnspsc190501.51132229': __('identifierSchemeCodeUnspsc190501.51132229'),
	'identifierSchemeCodeUnspsc190501.51132230': __('identifierSchemeCodeUnspsc190501.51132230'),
	'identifierSchemeCodeUnspsc190501.51132231': __('identifierSchemeCodeUnspsc190501.51132231'),
	'identifierSchemeCodeUnspsc190501.51132232': __('identifierSchemeCodeUnspsc190501.51132232'),
	'identifierSchemeCodeUnspsc190501.51132233': __('identifierSchemeCodeUnspsc190501.51132233'),
	'identifierSchemeCodeUnspsc190501.51132234': __('identifierSchemeCodeUnspsc190501.51132234'),
	'identifierSchemeCodeUnspsc190501.51132235': __('identifierSchemeCodeUnspsc190501.51132235'),
	'identifierSchemeCodeUnspsc190501.51132236': __('identifierSchemeCodeUnspsc190501.51132236'),
	'identifierSchemeCodeUnspsc190501.51132237': __('identifierSchemeCodeUnspsc190501.51132237'),
	'identifierSchemeCodeUnspsc190501.51132238': __('identifierSchemeCodeUnspsc190501.51132238'),
	'identifierSchemeCodeUnspsc190501.51132239': __('identifierSchemeCodeUnspsc190501.51132239'),
	'identifierSchemeCodeUnspsc190501.51132240': __('identifierSchemeCodeUnspsc190501.51132240'),
	'identifierSchemeCodeUnspsc190501.51132241': __('identifierSchemeCodeUnspsc190501.51132241'),
	'identifierSchemeCodeUnspsc190501.51132242': __('identifierSchemeCodeUnspsc190501.51132242'),
	'identifierSchemeCodeUnspsc190501.51132243': __('identifierSchemeCodeUnspsc190501.51132243'),
	'identifierSchemeCodeUnspsc190501.51132300': __('identifierSchemeCodeUnspsc190501.51132300'),
	'identifierSchemeCodeUnspsc190501.51132301': __('identifierSchemeCodeUnspsc190501.51132301'),
	'identifierSchemeCodeUnspsc190501.51132302': __('identifierSchemeCodeUnspsc190501.51132302'),
	'identifierSchemeCodeUnspsc190501.51132303': __('identifierSchemeCodeUnspsc190501.51132303'),
	'identifierSchemeCodeUnspsc190501.51132304': __('identifierSchemeCodeUnspsc190501.51132304'),
	'identifierSchemeCodeUnspsc190501.51132305': __('identifierSchemeCodeUnspsc190501.51132305'),
	'identifierSchemeCodeUnspsc190501.51132306': __('identifierSchemeCodeUnspsc190501.51132306'),
	'identifierSchemeCodeUnspsc190501.51140000': __('identifierSchemeCodeUnspsc190501.51140000'),
	'identifierSchemeCodeUnspsc190501.51141500': __('identifierSchemeCodeUnspsc190501.51141500'),
	'identifierSchemeCodeUnspsc190501.51141501': __('identifierSchemeCodeUnspsc190501.51141501'),
	'identifierSchemeCodeUnspsc190501.51141503': __('identifierSchemeCodeUnspsc190501.51141503'),
	'identifierSchemeCodeUnspsc190501.51141504': __('identifierSchemeCodeUnspsc190501.51141504'),
	'identifierSchemeCodeUnspsc190501.51141507': __('identifierSchemeCodeUnspsc190501.51141507'),
	'identifierSchemeCodeUnspsc190501.51141508': __('identifierSchemeCodeUnspsc190501.51141508'),
	'identifierSchemeCodeUnspsc190501.51141509': __('identifierSchemeCodeUnspsc190501.51141509'),
	'identifierSchemeCodeUnspsc190501.51141513': __('identifierSchemeCodeUnspsc190501.51141513'),
	'identifierSchemeCodeUnspsc190501.51141514': __('identifierSchemeCodeUnspsc190501.51141514'),
	'identifierSchemeCodeUnspsc190501.51141515': __('identifierSchemeCodeUnspsc190501.51141515'),
	'identifierSchemeCodeUnspsc190501.51141516': __('identifierSchemeCodeUnspsc190501.51141516'),
	'identifierSchemeCodeUnspsc190501.51141518': __('identifierSchemeCodeUnspsc190501.51141518'),
	'identifierSchemeCodeUnspsc190501.51141519': __('identifierSchemeCodeUnspsc190501.51141519'),
	'identifierSchemeCodeUnspsc190501.51141520': __('identifierSchemeCodeUnspsc190501.51141520'),
	'identifierSchemeCodeUnspsc190501.51141521': __('identifierSchemeCodeUnspsc190501.51141521'),
	'identifierSchemeCodeUnspsc190501.51141522': __('identifierSchemeCodeUnspsc190501.51141522'),
	'identifierSchemeCodeUnspsc190501.51141523': __('identifierSchemeCodeUnspsc190501.51141523'),
	'identifierSchemeCodeUnspsc190501.51141524': __('identifierSchemeCodeUnspsc190501.51141524'),
	'identifierSchemeCodeUnspsc190501.51141526': __('identifierSchemeCodeUnspsc190501.51141526'),
	'identifierSchemeCodeUnspsc190501.51141528': __('identifierSchemeCodeUnspsc190501.51141528'),
	'identifierSchemeCodeUnspsc190501.51141529': __('identifierSchemeCodeUnspsc190501.51141529'),
	'identifierSchemeCodeUnspsc190501.51141531': __('identifierSchemeCodeUnspsc190501.51141531'),
	'identifierSchemeCodeUnspsc190501.51141533': __('identifierSchemeCodeUnspsc190501.51141533'),
	'identifierSchemeCodeUnspsc190501.51141535': __('identifierSchemeCodeUnspsc190501.51141535'),
	'identifierSchemeCodeUnspsc190501.51141538': __('identifierSchemeCodeUnspsc190501.51141538'),
	'identifierSchemeCodeUnspsc190501.51141544': __('identifierSchemeCodeUnspsc190501.51141544'),
	'identifierSchemeCodeUnspsc190501.51141545': __('identifierSchemeCodeUnspsc190501.51141545'),
	'identifierSchemeCodeUnspsc190501.51141546': __('identifierSchemeCodeUnspsc190501.51141546'),
	'identifierSchemeCodeUnspsc190501.51141547': __('identifierSchemeCodeUnspsc190501.51141547'),
	'identifierSchemeCodeUnspsc190501.51141548': __('identifierSchemeCodeUnspsc190501.51141548'),
	'identifierSchemeCodeUnspsc190501.51141549': __('identifierSchemeCodeUnspsc190501.51141549'),
	'identifierSchemeCodeUnspsc190501.51141550': __('identifierSchemeCodeUnspsc190501.51141550'),
	'identifierSchemeCodeUnspsc190501.51141551': __('identifierSchemeCodeUnspsc190501.51141551'),
	'identifierSchemeCodeUnspsc190501.51141552': __('identifierSchemeCodeUnspsc190501.51141552'),
	'identifierSchemeCodeUnspsc190501.51141553': __('identifierSchemeCodeUnspsc190501.51141553'),
	'identifierSchemeCodeUnspsc190501.51141554': __('identifierSchemeCodeUnspsc190501.51141554'),
	'identifierSchemeCodeUnspsc190501.51141555': __('identifierSchemeCodeUnspsc190501.51141555'),
	'identifierSchemeCodeUnspsc190501.51141556': __('identifierSchemeCodeUnspsc190501.51141556'),
	'identifierSchemeCodeUnspsc190501.51141557': __('identifierSchemeCodeUnspsc190501.51141557'),
	'identifierSchemeCodeUnspsc190501.51141558': __('identifierSchemeCodeUnspsc190501.51141558'),
	'identifierSchemeCodeUnspsc190501.51141559': __('identifierSchemeCodeUnspsc190501.51141559'),
	'identifierSchemeCodeUnspsc190501.51141560': __('identifierSchemeCodeUnspsc190501.51141560'),
	'identifierSchemeCodeUnspsc190501.51141561': __('identifierSchemeCodeUnspsc190501.51141561'),
	'identifierSchemeCodeUnspsc190501.51141562': __('identifierSchemeCodeUnspsc190501.51141562'),
	'identifierSchemeCodeUnspsc190501.51141563': __('identifierSchemeCodeUnspsc190501.51141563'),
	'identifierSchemeCodeUnspsc190501.51141564': __('identifierSchemeCodeUnspsc190501.51141564'),
	'identifierSchemeCodeUnspsc190501.51141565': __('identifierSchemeCodeUnspsc190501.51141565'),
	'identifierSchemeCodeUnspsc190501.51141566': __('identifierSchemeCodeUnspsc190501.51141566'),
	'identifierSchemeCodeUnspsc190501.51141567': __('identifierSchemeCodeUnspsc190501.51141567'),
	'identifierSchemeCodeUnspsc190501.51141568': __('identifierSchemeCodeUnspsc190501.51141568'),
	'identifierSchemeCodeUnspsc190501.51141569': __('identifierSchemeCodeUnspsc190501.51141569'),
	'identifierSchemeCodeUnspsc190501.51141570': __('identifierSchemeCodeUnspsc190501.51141570'),
	'identifierSchemeCodeUnspsc190501.51141571': __('identifierSchemeCodeUnspsc190501.51141571'),
	'identifierSchemeCodeUnspsc190501.51141572': __('identifierSchemeCodeUnspsc190501.51141572'),
	'identifierSchemeCodeUnspsc190501.51141573': __('identifierSchemeCodeUnspsc190501.51141573'),
	'identifierSchemeCodeUnspsc190501.51141574': __('identifierSchemeCodeUnspsc190501.51141574'),
	'identifierSchemeCodeUnspsc190501.51141575': __('identifierSchemeCodeUnspsc190501.51141575'),
	'identifierSchemeCodeUnspsc190501.51141576': __('identifierSchemeCodeUnspsc190501.51141576'),
	'identifierSchemeCodeUnspsc190501.51141577': __('identifierSchemeCodeUnspsc190501.51141577'),
	'identifierSchemeCodeUnspsc190501.51141578': __('identifierSchemeCodeUnspsc190501.51141578'),
	'identifierSchemeCodeUnspsc190501.51141579': __('identifierSchemeCodeUnspsc190501.51141579'),
	'identifierSchemeCodeUnspsc190501.51141580': __('identifierSchemeCodeUnspsc190501.51141580'),
	'identifierSchemeCodeUnspsc190501.51141581': __('identifierSchemeCodeUnspsc190501.51141581'),
	'identifierSchemeCodeUnspsc190501.51141582': __('identifierSchemeCodeUnspsc190501.51141582'),
	'identifierSchemeCodeUnspsc190501.51141583': __('identifierSchemeCodeUnspsc190501.51141583'),
	'identifierSchemeCodeUnspsc190501.51141584': __('identifierSchemeCodeUnspsc190501.51141584'),
	'identifierSchemeCodeUnspsc190501.51141585': __('identifierSchemeCodeUnspsc190501.51141585'),
	'identifierSchemeCodeUnspsc190501.51141586': __('identifierSchemeCodeUnspsc190501.51141586'),
	'identifierSchemeCodeUnspsc190501.51141587': __('identifierSchemeCodeUnspsc190501.51141587'),
	'identifierSchemeCodeUnspsc190501.51141588': __('identifierSchemeCodeUnspsc190501.51141588'),
	'identifierSchemeCodeUnspsc190501.51141589': __('identifierSchemeCodeUnspsc190501.51141589'),
	'identifierSchemeCodeUnspsc190501.51141590': __('identifierSchemeCodeUnspsc190501.51141590'),
	'identifierSchemeCodeUnspsc190501.51141591': __('identifierSchemeCodeUnspsc190501.51141591'),
	'identifierSchemeCodeUnspsc190501.51141592': __('identifierSchemeCodeUnspsc190501.51141592'),
	'identifierSchemeCodeUnspsc190501.51141593': __('identifierSchemeCodeUnspsc190501.51141593'),
	'identifierSchemeCodeUnspsc190501.51141594': __('identifierSchemeCodeUnspsc190501.51141594'),
	'identifierSchemeCodeUnspsc190501.51141595': __('identifierSchemeCodeUnspsc190501.51141595'),
	'identifierSchemeCodeUnspsc190501.51141596': __('identifierSchemeCodeUnspsc190501.51141596'),
	'identifierSchemeCodeUnspsc190501.51141597': __('identifierSchemeCodeUnspsc190501.51141597'),
	'identifierSchemeCodeUnspsc190501.51141598': __('identifierSchemeCodeUnspsc190501.51141598'),
	'identifierSchemeCodeUnspsc190501.51142000': __('identifierSchemeCodeUnspsc190501.51142000'),
	'identifierSchemeCodeUnspsc190501.51142005': __('identifierSchemeCodeUnspsc190501.51142005'),
	'identifierSchemeCodeUnspsc190501.51142009': __('identifierSchemeCodeUnspsc190501.51142009'),
	'identifierSchemeCodeUnspsc190501.51142014': __('identifierSchemeCodeUnspsc190501.51142014'),
	'identifierSchemeCodeUnspsc190501.51142018': __('identifierSchemeCodeUnspsc190501.51142018'),
	'identifierSchemeCodeUnspsc190501.51142019': __('identifierSchemeCodeUnspsc190501.51142019'),
	'identifierSchemeCodeUnspsc190501.51142020': __('identifierSchemeCodeUnspsc190501.51142020'),
	'identifierSchemeCodeUnspsc190501.51142021': __('identifierSchemeCodeUnspsc190501.51142021'),
	'identifierSchemeCodeUnspsc190501.51142022': __('identifierSchemeCodeUnspsc190501.51142022'),
	'identifierSchemeCodeUnspsc190501.51142023': __('identifierSchemeCodeUnspsc190501.51142023'),
	'identifierSchemeCodeUnspsc190501.51142024': __('identifierSchemeCodeUnspsc190501.51142024'),
	'identifierSchemeCodeUnspsc190501.51142025': __('identifierSchemeCodeUnspsc190501.51142025'),
	'identifierSchemeCodeUnspsc190501.51142026': __('identifierSchemeCodeUnspsc190501.51142026'),
	'identifierSchemeCodeUnspsc190501.51142027': __('identifierSchemeCodeUnspsc190501.51142027'),
	'identifierSchemeCodeUnspsc190501.51142028': __('identifierSchemeCodeUnspsc190501.51142028'),
	'identifierSchemeCodeUnspsc190501.51142029': __('identifierSchemeCodeUnspsc190501.51142029'),
	'identifierSchemeCodeUnspsc190501.51142030': __('identifierSchemeCodeUnspsc190501.51142030'),
	'identifierSchemeCodeUnspsc190501.51142031': __('identifierSchemeCodeUnspsc190501.51142031'),
	'identifierSchemeCodeUnspsc190501.51142032': __('identifierSchemeCodeUnspsc190501.51142032'),
	'identifierSchemeCodeUnspsc190501.51142033': __('identifierSchemeCodeUnspsc190501.51142033'),
	'identifierSchemeCodeUnspsc190501.51142034': __('identifierSchemeCodeUnspsc190501.51142034'),
	'identifierSchemeCodeUnspsc190501.51142035': __('identifierSchemeCodeUnspsc190501.51142035'),
	'identifierSchemeCodeUnspsc190501.51142036': __('identifierSchemeCodeUnspsc190501.51142036'),
	'identifierSchemeCodeUnspsc190501.51142037': __('identifierSchemeCodeUnspsc190501.51142037'),
	'identifierSchemeCodeUnspsc190501.51142038': __('identifierSchemeCodeUnspsc190501.51142038'),
	'identifierSchemeCodeUnspsc190501.51142039': __('identifierSchemeCodeUnspsc190501.51142039'),
	'identifierSchemeCodeUnspsc190501.51142040': __('identifierSchemeCodeUnspsc190501.51142040'),
	'identifierSchemeCodeUnspsc190501.51142041': __('identifierSchemeCodeUnspsc190501.51142041'),
	'identifierSchemeCodeUnspsc190501.51142042': __('identifierSchemeCodeUnspsc190501.51142042'),
	'identifierSchemeCodeUnspsc190501.51142043': __('identifierSchemeCodeUnspsc190501.51142043'),
	'identifierSchemeCodeUnspsc190501.51142044': __('identifierSchemeCodeUnspsc190501.51142044'),
	'identifierSchemeCodeUnspsc190501.51142045': __('identifierSchemeCodeUnspsc190501.51142045'),
	'identifierSchemeCodeUnspsc190501.51142300': __('identifierSchemeCodeUnspsc190501.51142300'),
	'identifierSchemeCodeUnspsc190501.51142301': __('identifierSchemeCodeUnspsc190501.51142301'),
	'identifierSchemeCodeUnspsc190501.51142302': __('identifierSchemeCodeUnspsc190501.51142302'),
	'identifierSchemeCodeUnspsc190501.51142303': __('identifierSchemeCodeUnspsc190501.51142303'),
	'identifierSchemeCodeUnspsc190501.51142307': __('identifierSchemeCodeUnspsc190501.51142307'),
	'identifierSchemeCodeUnspsc190501.51142308': __('identifierSchemeCodeUnspsc190501.51142308'),
	'identifierSchemeCodeUnspsc190501.51142309': __('identifierSchemeCodeUnspsc190501.51142309'),
	'identifierSchemeCodeUnspsc190501.51142310': __('identifierSchemeCodeUnspsc190501.51142310'),
	'identifierSchemeCodeUnspsc190501.51142311': __('identifierSchemeCodeUnspsc190501.51142311'),
	'identifierSchemeCodeUnspsc190501.51142312': __('identifierSchemeCodeUnspsc190501.51142312'),
	'identifierSchemeCodeUnspsc190501.51142313': __('identifierSchemeCodeUnspsc190501.51142313'),
	'identifierSchemeCodeUnspsc190501.51142314': __('identifierSchemeCodeUnspsc190501.51142314'),
	'identifierSchemeCodeUnspsc190501.51142315': __('identifierSchemeCodeUnspsc190501.51142315'),
	'identifierSchemeCodeUnspsc190501.51142316': __('identifierSchemeCodeUnspsc190501.51142316'),
	'identifierSchemeCodeUnspsc190501.51142317': __('identifierSchemeCodeUnspsc190501.51142317'),
	'identifierSchemeCodeUnspsc190501.51142318': __('identifierSchemeCodeUnspsc190501.51142318'),
	'identifierSchemeCodeUnspsc190501.51142400': __('identifierSchemeCodeUnspsc190501.51142400'),
	'identifierSchemeCodeUnspsc190501.51142401': __('identifierSchemeCodeUnspsc190501.51142401'),
	'identifierSchemeCodeUnspsc190501.51142404': __('identifierSchemeCodeUnspsc190501.51142404'),
	'identifierSchemeCodeUnspsc190501.51142405': __('identifierSchemeCodeUnspsc190501.51142405'),
	'identifierSchemeCodeUnspsc190501.51142408': __('identifierSchemeCodeUnspsc190501.51142408'),
	'identifierSchemeCodeUnspsc190501.51142411': __('identifierSchemeCodeUnspsc190501.51142411'),
	'identifierSchemeCodeUnspsc190501.51142412': __('identifierSchemeCodeUnspsc190501.51142412'),
	'identifierSchemeCodeUnspsc190501.51142413': __('identifierSchemeCodeUnspsc190501.51142413'),
	'identifierSchemeCodeUnspsc190501.51142414': __('identifierSchemeCodeUnspsc190501.51142414'),
	'identifierSchemeCodeUnspsc190501.51142415': __('identifierSchemeCodeUnspsc190501.51142415'),
	'identifierSchemeCodeUnspsc190501.51142416': __('identifierSchemeCodeUnspsc190501.51142416'),
	'identifierSchemeCodeUnspsc190501.51142417': __('identifierSchemeCodeUnspsc190501.51142417'),
	'identifierSchemeCodeUnspsc190501.51142418': __('identifierSchemeCodeUnspsc190501.51142418'),
	'identifierSchemeCodeUnspsc190501.51142419': __('identifierSchemeCodeUnspsc190501.51142419'),
	'identifierSchemeCodeUnspsc190501.51142420': __('identifierSchemeCodeUnspsc190501.51142420'),
	'identifierSchemeCodeUnspsc190501.51142421': __('identifierSchemeCodeUnspsc190501.51142421'),
	'identifierSchemeCodeUnspsc190501.51142422': __('identifierSchemeCodeUnspsc190501.51142422'),
	'identifierSchemeCodeUnspsc190501.51142423': __('identifierSchemeCodeUnspsc190501.51142423'),
	'identifierSchemeCodeUnspsc190501.51142424': __('identifierSchemeCodeUnspsc190501.51142424'),
	'identifierSchemeCodeUnspsc190501.51142425': __('identifierSchemeCodeUnspsc190501.51142425'),
	'identifierSchemeCodeUnspsc190501.51142426': __('identifierSchemeCodeUnspsc190501.51142426'),
	'identifierSchemeCodeUnspsc190501.51142427': __('identifierSchemeCodeUnspsc190501.51142427'),
	'identifierSchemeCodeUnspsc190501.51142428': __('identifierSchemeCodeUnspsc190501.51142428'),
	'identifierSchemeCodeUnspsc190501.51142429': __('identifierSchemeCodeUnspsc190501.51142429'),
	'identifierSchemeCodeUnspsc190501.51142430': __('identifierSchemeCodeUnspsc190501.51142430'),
	'identifierSchemeCodeUnspsc190501.51142431': __('identifierSchemeCodeUnspsc190501.51142431'),
	'identifierSchemeCodeUnspsc190501.51142432': __('identifierSchemeCodeUnspsc190501.51142432'),
	'identifierSchemeCodeUnspsc190501.51142433': __('identifierSchemeCodeUnspsc190501.51142433'),
	'identifierSchemeCodeUnspsc190501.51142434': __('identifierSchemeCodeUnspsc190501.51142434'),
	'identifierSchemeCodeUnspsc190501.51142435': __('identifierSchemeCodeUnspsc190501.51142435'),
	'identifierSchemeCodeUnspsc190501.51142500': __('identifierSchemeCodeUnspsc190501.51142500'),
	'identifierSchemeCodeUnspsc190501.51142501': __('identifierSchemeCodeUnspsc190501.51142501'),
	'identifierSchemeCodeUnspsc190501.51142502': __('identifierSchemeCodeUnspsc190501.51142502'),
	'identifierSchemeCodeUnspsc190501.51142503': __('identifierSchemeCodeUnspsc190501.51142503'),
	'identifierSchemeCodeUnspsc190501.51142504': __('identifierSchemeCodeUnspsc190501.51142504'),
	'identifierSchemeCodeUnspsc190501.51142507': __('identifierSchemeCodeUnspsc190501.51142507'),
	'identifierSchemeCodeUnspsc190501.51142508': __('identifierSchemeCodeUnspsc190501.51142508'),
	'identifierSchemeCodeUnspsc190501.51142509': __('identifierSchemeCodeUnspsc190501.51142509'),
	'identifierSchemeCodeUnspsc190501.51142511': __('identifierSchemeCodeUnspsc190501.51142511'),
	'identifierSchemeCodeUnspsc190501.51142512': __('identifierSchemeCodeUnspsc190501.51142512'),
	'identifierSchemeCodeUnspsc190501.51142513': __('identifierSchemeCodeUnspsc190501.51142513'),
	'identifierSchemeCodeUnspsc190501.51142515': __('identifierSchemeCodeUnspsc190501.51142515'),
	'identifierSchemeCodeUnspsc190501.51142516': __('identifierSchemeCodeUnspsc190501.51142516'),
	'identifierSchemeCodeUnspsc190501.51142517': __('identifierSchemeCodeUnspsc190501.51142517'),
	'identifierSchemeCodeUnspsc190501.51142518': __('identifierSchemeCodeUnspsc190501.51142518'),
	'identifierSchemeCodeUnspsc190501.51142519': __('identifierSchemeCodeUnspsc190501.51142519'),
	'identifierSchemeCodeUnspsc190501.51142520': __('identifierSchemeCodeUnspsc190501.51142520'),
	'identifierSchemeCodeUnspsc190501.51142521': __('identifierSchemeCodeUnspsc190501.51142521'),
	'identifierSchemeCodeUnspsc190501.51142522': __('identifierSchemeCodeUnspsc190501.51142522'),
	'identifierSchemeCodeUnspsc190501.51142523': __('identifierSchemeCodeUnspsc190501.51142523'),
	'identifierSchemeCodeUnspsc190501.51142524': __('identifierSchemeCodeUnspsc190501.51142524'),
	'identifierSchemeCodeUnspsc190501.51142525': __('identifierSchemeCodeUnspsc190501.51142525'),
	'identifierSchemeCodeUnspsc190501.51142526': __('identifierSchemeCodeUnspsc190501.51142526'),
	'identifierSchemeCodeUnspsc190501.51142527': __('identifierSchemeCodeUnspsc190501.51142527'),
	'identifierSchemeCodeUnspsc190501.51142528': __('identifierSchemeCodeUnspsc190501.51142528'),
	'identifierSchemeCodeUnspsc190501.51142529': __('identifierSchemeCodeUnspsc190501.51142529'),
	'identifierSchemeCodeUnspsc190501.51142530': __('identifierSchemeCodeUnspsc190501.51142530'),
	'identifierSchemeCodeUnspsc190501.51142531': __('identifierSchemeCodeUnspsc190501.51142531'),
	'identifierSchemeCodeUnspsc190501.51142532': __('identifierSchemeCodeUnspsc190501.51142532'),
	'identifierSchemeCodeUnspsc190501.51142533': __('identifierSchemeCodeUnspsc190501.51142533'),
	'identifierSchemeCodeUnspsc190501.51142534': __('identifierSchemeCodeUnspsc190501.51142534'),
	'identifierSchemeCodeUnspsc190501.51142535': __('identifierSchemeCodeUnspsc190501.51142535'),
	'identifierSchemeCodeUnspsc190501.51142536': __('identifierSchemeCodeUnspsc190501.51142536'),
	'identifierSchemeCodeUnspsc190501.51142537': __('identifierSchemeCodeUnspsc190501.51142537'),
	'identifierSchemeCodeUnspsc190501.51142538': __('identifierSchemeCodeUnspsc190501.51142538'),
	'identifierSchemeCodeUnspsc190501.51142539': __('identifierSchemeCodeUnspsc190501.51142539'),
	'identifierSchemeCodeUnspsc190501.51142540': __('identifierSchemeCodeUnspsc190501.51142540'),
	'identifierSchemeCodeUnspsc190501.51142541': __('identifierSchemeCodeUnspsc190501.51142541'),
	'identifierSchemeCodeUnspsc190501.51142542': __('identifierSchemeCodeUnspsc190501.51142542'),
	'identifierSchemeCodeUnspsc190501.51142543': __('identifierSchemeCodeUnspsc190501.51142543'),
	'identifierSchemeCodeUnspsc190501.51142544': __('identifierSchemeCodeUnspsc190501.51142544'),
	'identifierSchemeCodeUnspsc190501.51142545': __('identifierSchemeCodeUnspsc190501.51142545'),
	'identifierSchemeCodeUnspsc190501.51142546': __('identifierSchemeCodeUnspsc190501.51142546'),
	'identifierSchemeCodeUnspsc190501.51142547': __('identifierSchemeCodeUnspsc190501.51142547'),
	'identifierSchemeCodeUnspsc190501.51142548': __('identifierSchemeCodeUnspsc190501.51142548'),
	'identifierSchemeCodeUnspsc190501.51142549': __('identifierSchemeCodeUnspsc190501.51142549'),
	'identifierSchemeCodeUnspsc190501.51142550': __('identifierSchemeCodeUnspsc190501.51142550'),
	'identifierSchemeCodeUnspsc190501.51142551': __('identifierSchemeCodeUnspsc190501.51142551'),
	'identifierSchemeCodeUnspsc190501.51142552': __('identifierSchemeCodeUnspsc190501.51142552'),
	'identifierSchemeCodeUnspsc190501.51142553': __('identifierSchemeCodeUnspsc190501.51142553'),
	'identifierSchemeCodeUnspsc190501.51142554': __('identifierSchemeCodeUnspsc190501.51142554'),
	'identifierSchemeCodeUnspsc190501.51142555': __('identifierSchemeCodeUnspsc190501.51142555'),
	'identifierSchemeCodeUnspsc190501.51142556': __('identifierSchemeCodeUnspsc190501.51142556'),
	'identifierSchemeCodeUnspsc190501.51142557': __('identifierSchemeCodeUnspsc190501.51142557'),
	'identifierSchemeCodeUnspsc190501.51142558': __('identifierSchemeCodeUnspsc190501.51142558'),
	'identifierSchemeCodeUnspsc190501.51142559': __('identifierSchemeCodeUnspsc190501.51142559'),
	'identifierSchemeCodeUnspsc190501.51142560': __('identifierSchemeCodeUnspsc190501.51142560'),
	'identifierSchemeCodeUnspsc190501.51142561': __('identifierSchemeCodeUnspsc190501.51142561'),
	'identifierSchemeCodeUnspsc190501.51142562': __('identifierSchemeCodeUnspsc190501.51142562'),
	'identifierSchemeCodeUnspsc190501.51142563': __('identifierSchemeCodeUnspsc190501.51142563'),
	'identifierSchemeCodeUnspsc190501.51142564': __('identifierSchemeCodeUnspsc190501.51142564'),
	'identifierSchemeCodeUnspsc190501.51142565': __('identifierSchemeCodeUnspsc190501.51142565'),
	'identifierSchemeCodeUnspsc190501.51142566': __('identifierSchemeCodeUnspsc190501.51142566'),
	'identifierSchemeCodeUnspsc190501.51142567': __('identifierSchemeCodeUnspsc190501.51142567'),
	'identifierSchemeCodeUnspsc190501.51142568': __('identifierSchemeCodeUnspsc190501.51142568'),
	'identifierSchemeCodeUnspsc190501.51142569': __('identifierSchemeCodeUnspsc190501.51142569'),
	'identifierSchemeCodeUnspsc190501.51142570': __('identifierSchemeCodeUnspsc190501.51142570'),
	'identifierSchemeCodeUnspsc190501.51142571': __('identifierSchemeCodeUnspsc190501.51142571'),
	'identifierSchemeCodeUnspsc190501.51142572': __('identifierSchemeCodeUnspsc190501.51142572'),
	'identifierSchemeCodeUnspsc190501.51142573': __('identifierSchemeCodeUnspsc190501.51142573'),
	'identifierSchemeCodeUnspsc190501.51142600': __('identifierSchemeCodeUnspsc190501.51142600'),
	'identifierSchemeCodeUnspsc190501.51142611': __('identifierSchemeCodeUnspsc190501.51142611'),
	'identifierSchemeCodeUnspsc190501.51142642': __('identifierSchemeCodeUnspsc190501.51142642'),
	'identifierSchemeCodeUnspsc190501.51142643': __('identifierSchemeCodeUnspsc190501.51142643'),
	'identifierSchemeCodeUnspsc190501.51142650': __('identifierSchemeCodeUnspsc190501.51142650'),
	'identifierSchemeCodeUnspsc190501.51142658': __('identifierSchemeCodeUnspsc190501.51142658'),
	'identifierSchemeCodeUnspsc190501.51143000': __('identifierSchemeCodeUnspsc190501.51143000'),
	'identifierSchemeCodeUnspsc190501.51143001': __('identifierSchemeCodeUnspsc190501.51143001'),
	'identifierSchemeCodeUnspsc190501.51143002': __('identifierSchemeCodeUnspsc190501.51143002'),
	'identifierSchemeCodeUnspsc190501.51143003': __('identifierSchemeCodeUnspsc190501.51143003'),
	'identifierSchemeCodeUnspsc190501.51143004': __('identifierSchemeCodeUnspsc190501.51143004'),
	'identifierSchemeCodeUnspsc190501.51143005': __('identifierSchemeCodeUnspsc190501.51143005'),
	'identifierSchemeCodeUnspsc190501.51143006': __('identifierSchemeCodeUnspsc190501.51143006'),
	'identifierSchemeCodeUnspsc190501.51143007': __('identifierSchemeCodeUnspsc190501.51143007'),
	'identifierSchemeCodeUnspsc190501.51143008': __('identifierSchemeCodeUnspsc190501.51143008'),
	'identifierSchemeCodeUnspsc190501.51143009': __('identifierSchemeCodeUnspsc190501.51143009'),
	'identifierSchemeCodeUnspsc190501.51143010': __('identifierSchemeCodeUnspsc190501.51143010'),
	'identifierSchemeCodeUnspsc190501.51143011': __('identifierSchemeCodeUnspsc190501.51143011'),
	'identifierSchemeCodeUnspsc190501.51143012': __('identifierSchemeCodeUnspsc190501.51143012'),
	'identifierSchemeCodeUnspsc190501.51143013': __('identifierSchemeCodeUnspsc190501.51143013'),
	'identifierSchemeCodeUnspsc190501.51143014': __('identifierSchemeCodeUnspsc190501.51143014'),
	'identifierSchemeCodeUnspsc190501.51143015': __('identifierSchemeCodeUnspsc190501.51143015'),
	'identifierSchemeCodeUnspsc190501.51143016': __('identifierSchemeCodeUnspsc190501.51143016'),
	'identifierSchemeCodeUnspsc190501.51143017': __('identifierSchemeCodeUnspsc190501.51143017'),
	'identifierSchemeCodeUnspsc190501.51143018': __('identifierSchemeCodeUnspsc190501.51143018'),
	'identifierSchemeCodeUnspsc190501.51143019': __('identifierSchemeCodeUnspsc190501.51143019'),
	'identifierSchemeCodeUnspsc190501.51143020': __('identifierSchemeCodeUnspsc190501.51143020'),
	'identifierSchemeCodeUnspsc190501.51143021': __('identifierSchemeCodeUnspsc190501.51143021'),
	'identifierSchemeCodeUnspsc190501.51143022': __('identifierSchemeCodeUnspsc190501.51143022'),
	'identifierSchemeCodeUnspsc190501.51143023': __('identifierSchemeCodeUnspsc190501.51143023'),
	'identifierSchemeCodeUnspsc190501.51143100': __('identifierSchemeCodeUnspsc190501.51143100'),
	'identifierSchemeCodeUnspsc190501.51143101': __('identifierSchemeCodeUnspsc190501.51143101'),
	'identifierSchemeCodeUnspsc190501.51143102': __('identifierSchemeCodeUnspsc190501.51143102'),
	'identifierSchemeCodeUnspsc190501.51143103': __('identifierSchemeCodeUnspsc190501.51143103'),
	'identifierSchemeCodeUnspsc190501.51143104': __('identifierSchemeCodeUnspsc190501.51143104'),
	'identifierSchemeCodeUnspsc190501.51143105': __('identifierSchemeCodeUnspsc190501.51143105'),
	'identifierSchemeCodeUnspsc190501.51143107': __('identifierSchemeCodeUnspsc190501.51143107'),
	'identifierSchemeCodeUnspsc190501.51143108': __('identifierSchemeCodeUnspsc190501.51143108'),
	'identifierSchemeCodeUnspsc190501.51143109': __('identifierSchemeCodeUnspsc190501.51143109'),
	'identifierSchemeCodeUnspsc190501.51143110': __('identifierSchemeCodeUnspsc190501.51143110'),
	'identifierSchemeCodeUnspsc190501.51143111': __('identifierSchemeCodeUnspsc190501.51143111'),
	'identifierSchemeCodeUnspsc190501.51143200': __('identifierSchemeCodeUnspsc190501.51143200'),
	'identifierSchemeCodeUnspsc190501.51143201': __('identifierSchemeCodeUnspsc190501.51143201'),
	'identifierSchemeCodeUnspsc190501.51143202': __('identifierSchemeCodeUnspsc190501.51143202'),
	'identifierSchemeCodeUnspsc190501.51143203': __('identifierSchemeCodeUnspsc190501.51143203'),
	'identifierSchemeCodeUnspsc190501.51143204': __('identifierSchemeCodeUnspsc190501.51143204'),
	'identifierSchemeCodeUnspsc190501.51143205': __('identifierSchemeCodeUnspsc190501.51143205'),
	'identifierSchemeCodeUnspsc190501.51143206': __('identifierSchemeCodeUnspsc190501.51143206'),
	'identifierSchemeCodeUnspsc190501.51143207': __('identifierSchemeCodeUnspsc190501.51143207'),
	'identifierSchemeCodeUnspsc190501.51143208': __('identifierSchemeCodeUnspsc190501.51143208'),
	'identifierSchemeCodeUnspsc190501.51143209': __('identifierSchemeCodeUnspsc190501.51143209'),
	'identifierSchemeCodeUnspsc190501.51143210': __('identifierSchemeCodeUnspsc190501.51143210'),
	'identifierSchemeCodeUnspsc190501.51143211': __('identifierSchemeCodeUnspsc190501.51143211'),
	'identifierSchemeCodeUnspsc190501.51143212': __('identifierSchemeCodeUnspsc190501.51143212'),
	'identifierSchemeCodeUnspsc190501.51143213': __('identifierSchemeCodeUnspsc190501.51143213'),
	'identifierSchemeCodeUnspsc190501.51143214': __('identifierSchemeCodeUnspsc190501.51143214'),
	'identifierSchemeCodeUnspsc190501.51143215': __('identifierSchemeCodeUnspsc190501.51143215'),
	'identifierSchemeCodeUnspsc190501.51143216': __('identifierSchemeCodeUnspsc190501.51143216'),
	'identifierSchemeCodeUnspsc190501.51143217': __('identifierSchemeCodeUnspsc190501.51143217'),
	'identifierSchemeCodeUnspsc190501.51143218': __('identifierSchemeCodeUnspsc190501.51143218'),
	'identifierSchemeCodeUnspsc190501.51143219': __('identifierSchemeCodeUnspsc190501.51143219'),
	'identifierSchemeCodeUnspsc190501.51143220': __('identifierSchemeCodeUnspsc190501.51143220'),
	'identifierSchemeCodeUnspsc190501.51143221': __('identifierSchemeCodeUnspsc190501.51143221'),
	'identifierSchemeCodeUnspsc190501.51143222': __('identifierSchemeCodeUnspsc190501.51143222'),
	'identifierSchemeCodeUnspsc190501.51143300': __('identifierSchemeCodeUnspsc190501.51143300'),
	'identifierSchemeCodeUnspsc190501.51143301': __('identifierSchemeCodeUnspsc190501.51143301'),
	'identifierSchemeCodeUnspsc190501.51143400': __('identifierSchemeCodeUnspsc190501.51143400'),
	'identifierSchemeCodeUnspsc190501.51143401': __('identifierSchemeCodeUnspsc190501.51143401'),
	'identifierSchemeCodeUnspsc190501.51143402': __('identifierSchemeCodeUnspsc190501.51143402'),
	'identifierSchemeCodeUnspsc190501.51143403': __('identifierSchemeCodeUnspsc190501.51143403'),
	'identifierSchemeCodeUnspsc190501.51143404': __('identifierSchemeCodeUnspsc190501.51143404'),
	'identifierSchemeCodeUnspsc190501.51143405': __('identifierSchemeCodeUnspsc190501.51143405'),
	'identifierSchemeCodeUnspsc190501.51143406': __('identifierSchemeCodeUnspsc190501.51143406'),
	'identifierSchemeCodeUnspsc190501.51143407': __('identifierSchemeCodeUnspsc190501.51143407'),
	'identifierSchemeCodeUnspsc190501.51143408': __('identifierSchemeCodeUnspsc190501.51143408'),
	'identifierSchemeCodeUnspsc190501.51143500': __('identifierSchemeCodeUnspsc190501.51143500'),
	'identifierSchemeCodeUnspsc190501.51143501': __('identifierSchemeCodeUnspsc190501.51143501'),
	'identifierSchemeCodeUnspsc190501.51143502': __('identifierSchemeCodeUnspsc190501.51143502'),
	'identifierSchemeCodeUnspsc190501.51143600': __('identifierSchemeCodeUnspsc190501.51143600'),
	'identifierSchemeCodeUnspsc190501.51143601': __('identifierSchemeCodeUnspsc190501.51143601'),
	'identifierSchemeCodeUnspsc190501.51143602': __('identifierSchemeCodeUnspsc190501.51143602'),
	'identifierSchemeCodeUnspsc190501.51143700': __('identifierSchemeCodeUnspsc190501.51143700'),
	'identifierSchemeCodeUnspsc190501.51143701': __('identifierSchemeCodeUnspsc190501.51143701'),
	'identifierSchemeCodeUnspsc190501.51143702': __('identifierSchemeCodeUnspsc190501.51143702'),
	'identifierSchemeCodeUnspsc190501.51143703': __('identifierSchemeCodeUnspsc190501.51143703'),
	'identifierSchemeCodeUnspsc190501.51143704': __('identifierSchemeCodeUnspsc190501.51143704'),
	'identifierSchemeCodeUnspsc190501.51143705': __('identifierSchemeCodeUnspsc190501.51143705'),
	'identifierSchemeCodeUnspsc190501.51143706': __('identifierSchemeCodeUnspsc190501.51143706'),
	'identifierSchemeCodeUnspsc190501.51143707': __('identifierSchemeCodeUnspsc190501.51143707'),
	'identifierSchemeCodeUnspsc190501.51143708': __('identifierSchemeCodeUnspsc190501.51143708'),
	'identifierSchemeCodeUnspsc190501.51143709': __('identifierSchemeCodeUnspsc190501.51143709'),
	'identifierSchemeCodeUnspsc190501.51143710': __('identifierSchemeCodeUnspsc190501.51143710'),
	'identifierSchemeCodeUnspsc190501.51143711': __('identifierSchemeCodeUnspsc190501.51143711'),
	'identifierSchemeCodeUnspsc190501.51143712': __('identifierSchemeCodeUnspsc190501.51143712'),
	'identifierSchemeCodeUnspsc190501.51143800': __('identifierSchemeCodeUnspsc190501.51143800'),
	'identifierSchemeCodeUnspsc190501.51143801': __('identifierSchemeCodeUnspsc190501.51143801'),
	'identifierSchemeCodeUnspsc190501.51143802': __('identifierSchemeCodeUnspsc190501.51143802'),
	'identifierSchemeCodeUnspsc190501.51143900': __('identifierSchemeCodeUnspsc190501.51143900'),
	'identifierSchemeCodeUnspsc190501.51143901': __('identifierSchemeCodeUnspsc190501.51143901'),
	'identifierSchemeCodeUnspsc190501.51143902': __('identifierSchemeCodeUnspsc190501.51143902'),
	'identifierSchemeCodeUnspsc190501.51143903': __('identifierSchemeCodeUnspsc190501.51143903'),
	'identifierSchemeCodeUnspsc190501.51143904': __('identifierSchemeCodeUnspsc190501.51143904'),
	'identifierSchemeCodeUnspsc190501.51143905': __('identifierSchemeCodeUnspsc190501.51143905'),
	'identifierSchemeCodeUnspsc190501.51143906': __('identifierSchemeCodeUnspsc190501.51143906'),
	'identifierSchemeCodeUnspsc190501.51143907': __('identifierSchemeCodeUnspsc190501.51143907'),
	'identifierSchemeCodeUnspsc190501.51143908': __('identifierSchemeCodeUnspsc190501.51143908'),
	'identifierSchemeCodeUnspsc190501.51144000': __('identifierSchemeCodeUnspsc190501.51144000'),
	'identifierSchemeCodeUnspsc190501.51144001': __('identifierSchemeCodeUnspsc190501.51144001'),
	'identifierSchemeCodeUnspsc190501.51144002': __('identifierSchemeCodeUnspsc190501.51144002'),
	'identifierSchemeCodeUnspsc190501.51144003': __('identifierSchemeCodeUnspsc190501.51144003'),
	'identifierSchemeCodeUnspsc190501.51144004': __('identifierSchemeCodeUnspsc190501.51144004'),
	'identifierSchemeCodeUnspsc190501.51144005': __('identifierSchemeCodeUnspsc190501.51144005'),
	'identifierSchemeCodeUnspsc190501.51144006': __('identifierSchemeCodeUnspsc190501.51144006'),
	'identifierSchemeCodeUnspsc190501.51144007': __('identifierSchemeCodeUnspsc190501.51144007'),
	'identifierSchemeCodeUnspsc190501.51144008': __('identifierSchemeCodeUnspsc190501.51144008'),
	'identifierSchemeCodeUnspsc190501.51144009': __('identifierSchemeCodeUnspsc190501.51144009'),
	'identifierSchemeCodeUnspsc190501.51144010': __('identifierSchemeCodeUnspsc190501.51144010'),
	'identifierSchemeCodeUnspsc190501.51144011': __('identifierSchemeCodeUnspsc190501.51144011'),
	'identifierSchemeCodeUnspsc190501.51144012': __('identifierSchemeCodeUnspsc190501.51144012'),
	'identifierSchemeCodeUnspsc190501.51144013': __('identifierSchemeCodeUnspsc190501.51144013'),
	'identifierSchemeCodeUnspsc190501.51144014': __('identifierSchemeCodeUnspsc190501.51144014'),
	'identifierSchemeCodeUnspsc190501.51144015': __('identifierSchemeCodeUnspsc190501.51144015'),
	'identifierSchemeCodeUnspsc190501.51144016': __('identifierSchemeCodeUnspsc190501.51144016'),
	'identifierSchemeCodeUnspsc190501.51144017': __('identifierSchemeCodeUnspsc190501.51144017'),
	'identifierSchemeCodeUnspsc190501.51144100': __('identifierSchemeCodeUnspsc190501.51144100'),
	'identifierSchemeCodeUnspsc190501.51144101': __('identifierSchemeCodeUnspsc190501.51144101'),
	'identifierSchemeCodeUnspsc190501.51144102': __('identifierSchemeCodeUnspsc190501.51144102'),
	'identifierSchemeCodeUnspsc190501.51144103': __('identifierSchemeCodeUnspsc190501.51144103'),
	'identifierSchemeCodeUnspsc190501.51144104': __('identifierSchemeCodeUnspsc190501.51144104'),
	'identifierSchemeCodeUnspsc190501.51144105': __('identifierSchemeCodeUnspsc190501.51144105'),
	'identifierSchemeCodeUnspsc190501.51144106': __('identifierSchemeCodeUnspsc190501.51144106'),
	'identifierSchemeCodeUnspsc190501.51144107': __('identifierSchemeCodeUnspsc190501.51144107'),
	'identifierSchemeCodeUnspsc190501.51144108': __('identifierSchemeCodeUnspsc190501.51144108'),
	'identifierSchemeCodeUnspsc190501.51144109': __('identifierSchemeCodeUnspsc190501.51144109'),
	'identifierSchemeCodeUnspsc190501.51144110': __('identifierSchemeCodeUnspsc190501.51144110'),
	'identifierSchemeCodeUnspsc190501.51144111': __('identifierSchemeCodeUnspsc190501.51144111'),
	'identifierSchemeCodeUnspsc190501.51144112': __('identifierSchemeCodeUnspsc190501.51144112'),
	'identifierSchemeCodeUnspsc190501.51144113': __('identifierSchemeCodeUnspsc190501.51144113'),
	'identifierSchemeCodeUnspsc190501.51144114': __('identifierSchemeCodeUnspsc190501.51144114'),
	'identifierSchemeCodeUnspsc190501.51144115': __('identifierSchemeCodeUnspsc190501.51144115'),
	'identifierSchemeCodeUnspsc190501.51144116': __('identifierSchemeCodeUnspsc190501.51144116'),
	'identifierSchemeCodeUnspsc190501.51144117': __('identifierSchemeCodeUnspsc190501.51144117'),
	'identifierSchemeCodeUnspsc190501.51144118': __('identifierSchemeCodeUnspsc190501.51144118'),
	'identifierSchemeCodeUnspsc190501.51144119': __('identifierSchemeCodeUnspsc190501.51144119'),
	'identifierSchemeCodeUnspsc190501.51144120': __('identifierSchemeCodeUnspsc190501.51144120'),
	'identifierSchemeCodeUnspsc190501.51144121': __('identifierSchemeCodeUnspsc190501.51144121'),
	'identifierSchemeCodeUnspsc190501.51144122': __('identifierSchemeCodeUnspsc190501.51144122'),
	'identifierSchemeCodeUnspsc190501.51144123': __('identifierSchemeCodeUnspsc190501.51144123'),
	'identifierSchemeCodeUnspsc190501.51144124': __('identifierSchemeCodeUnspsc190501.51144124'),
	'identifierSchemeCodeUnspsc190501.51144125': __('identifierSchemeCodeUnspsc190501.51144125'),
	'identifierSchemeCodeUnspsc190501.51144126': __('identifierSchemeCodeUnspsc190501.51144126'),
	'identifierSchemeCodeUnspsc190501.51144127': __('identifierSchemeCodeUnspsc190501.51144127'),
	'identifierSchemeCodeUnspsc190501.51144128': __('identifierSchemeCodeUnspsc190501.51144128'),
	'identifierSchemeCodeUnspsc190501.51144129': __('identifierSchemeCodeUnspsc190501.51144129'),
	'identifierSchemeCodeUnspsc190501.51144130': __('identifierSchemeCodeUnspsc190501.51144130'),
	'identifierSchemeCodeUnspsc190501.51144131': __('identifierSchemeCodeUnspsc190501.51144131'),
	'identifierSchemeCodeUnspsc190501.51144200': __('identifierSchemeCodeUnspsc190501.51144200'),
	'identifierSchemeCodeUnspsc190501.51144201': __('identifierSchemeCodeUnspsc190501.51144201'),
	'identifierSchemeCodeUnspsc190501.51144202': __('identifierSchemeCodeUnspsc190501.51144202'),
	'identifierSchemeCodeUnspsc190501.51144203': __('identifierSchemeCodeUnspsc190501.51144203'),
	'identifierSchemeCodeUnspsc190501.51144204': __('identifierSchemeCodeUnspsc190501.51144204'),
	'identifierSchemeCodeUnspsc190501.51144300': __('identifierSchemeCodeUnspsc190501.51144300'),
	'identifierSchemeCodeUnspsc190501.51144301': __('identifierSchemeCodeUnspsc190501.51144301'),
	'identifierSchemeCodeUnspsc190501.51144302': __('identifierSchemeCodeUnspsc190501.51144302'),
	'identifierSchemeCodeUnspsc190501.51144303': __('identifierSchemeCodeUnspsc190501.51144303'),
	'identifierSchemeCodeUnspsc190501.51144304': __('identifierSchemeCodeUnspsc190501.51144304'),
	'identifierSchemeCodeUnspsc190501.51144400': __('identifierSchemeCodeUnspsc190501.51144400'),
	'identifierSchemeCodeUnspsc190501.51144401': __('identifierSchemeCodeUnspsc190501.51144401'),
	'identifierSchemeCodeUnspsc190501.51144402': __('identifierSchemeCodeUnspsc190501.51144402'),
	'identifierSchemeCodeUnspsc190501.51144403': __('identifierSchemeCodeUnspsc190501.51144403'),
	'identifierSchemeCodeUnspsc190501.51144500': __('identifierSchemeCodeUnspsc190501.51144500'),
	'identifierSchemeCodeUnspsc190501.51144501': __('identifierSchemeCodeUnspsc190501.51144501'),
	'identifierSchemeCodeUnspsc190501.51144502': __('identifierSchemeCodeUnspsc190501.51144502'),
	'identifierSchemeCodeUnspsc190501.51144503': __('identifierSchemeCodeUnspsc190501.51144503'),
	'identifierSchemeCodeUnspsc190501.51144504': __('identifierSchemeCodeUnspsc190501.51144504'),
	'identifierSchemeCodeUnspsc190501.51144600': __('identifierSchemeCodeUnspsc190501.51144600'),
	'identifierSchemeCodeUnspsc190501.51144601': __('identifierSchemeCodeUnspsc190501.51144601'),
	'identifierSchemeCodeUnspsc190501.51144602': __('identifierSchemeCodeUnspsc190501.51144602'),
	'identifierSchemeCodeUnspsc190501.51144603': __('identifierSchemeCodeUnspsc190501.51144603'),
	'identifierSchemeCodeUnspsc190501.51144700': __('identifierSchemeCodeUnspsc190501.51144700'),
	'identifierSchemeCodeUnspsc190501.51144701': __('identifierSchemeCodeUnspsc190501.51144701'),
	'identifierSchemeCodeUnspsc190501.51144702': __('identifierSchemeCodeUnspsc190501.51144702'),
	'identifierSchemeCodeUnspsc190501.51144800': __('identifierSchemeCodeUnspsc190501.51144800'),
	'identifierSchemeCodeUnspsc190501.51144801': __('identifierSchemeCodeUnspsc190501.51144801'),
	'identifierSchemeCodeUnspsc190501.51144900': __('identifierSchemeCodeUnspsc190501.51144900'),
	'identifierSchemeCodeUnspsc190501.51144901': __('identifierSchemeCodeUnspsc190501.51144901'),
	'identifierSchemeCodeUnspsc190501.51145000': __('identifierSchemeCodeUnspsc190501.51145000'),
	'identifierSchemeCodeUnspsc190501.51145001': __('identifierSchemeCodeUnspsc190501.51145001'),
	'identifierSchemeCodeUnspsc190501.51145100': __('identifierSchemeCodeUnspsc190501.51145100'),
	'identifierSchemeCodeUnspsc190501.51145101': __('identifierSchemeCodeUnspsc190501.51145101'),
	'identifierSchemeCodeUnspsc190501.51145200': __('identifierSchemeCodeUnspsc190501.51145200'),
	'identifierSchemeCodeUnspsc190501.51145201': __('identifierSchemeCodeUnspsc190501.51145201'),
	'identifierSchemeCodeUnspsc190501.51145202': __('identifierSchemeCodeUnspsc190501.51145202'),
	'identifierSchemeCodeUnspsc190501.51145300': __('identifierSchemeCodeUnspsc190501.51145300'),
	'identifierSchemeCodeUnspsc190501.51145301': __('identifierSchemeCodeUnspsc190501.51145301'),
	'identifierSchemeCodeUnspsc190501.51145302': __('identifierSchemeCodeUnspsc190501.51145302'),
	'identifierSchemeCodeUnspsc190501.51145303': __('identifierSchemeCodeUnspsc190501.51145303'),
	'identifierSchemeCodeUnspsc190501.51145304': __('identifierSchemeCodeUnspsc190501.51145304'),
	'identifierSchemeCodeUnspsc190501.51145305': __('identifierSchemeCodeUnspsc190501.51145305'),
	'identifierSchemeCodeUnspsc190501.51150000': __('identifierSchemeCodeUnspsc190501.51150000'),
	'identifierSchemeCodeUnspsc190501.51151500': __('identifierSchemeCodeUnspsc190501.51151500'),
	'identifierSchemeCodeUnspsc190501.51151501': __('identifierSchemeCodeUnspsc190501.51151501'),
	'identifierSchemeCodeUnspsc190501.51151504': __('identifierSchemeCodeUnspsc190501.51151504'),
	'identifierSchemeCodeUnspsc190501.51151505': __('identifierSchemeCodeUnspsc190501.51151505'),
	'identifierSchemeCodeUnspsc190501.51151506': __('identifierSchemeCodeUnspsc190501.51151506'),
	'identifierSchemeCodeUnspsc190501.51151507': __('identifierSchemeCodeUnspsc190501.51151507'),
	'identifierSchemeCodeUnspsc190501.51151510': __('identifierSchemeCodeUnspsc190501.51151510'),
	'identifierSchemeCodeUnspsc190501.51151512': __('identifierSchemeCodeUnspsc190501.51151512'),
	'identifierSchemeCodeUnspsc190501.51151514': __('identifierSchemeCodeUnspsc190501.51151514'),
	'identifierSchemeCodeUnspsc190501.51151516': __('identifierSchemeCodeUnspsc190501.51151516'),
	'identifierSchemeCodeUnspsc190501.51151517': __('identifierSchemeCodeUnspsc190501.51151517'),
	'identifierSchemeCodeUnspsc190501.51151519': __('identifierSchemeCodeUnspsc190501.51151519'),
	'identifierSchemeCodeUnspsc190501.51151520': __('identifierSchemeCodeUnspsc190501.51151520'),
	'identifierSchemeCodeUnspsc190501.51151521': __('identifierSchemeCodeUnspsc190501.51151521'),
	'identifierSchemeCodeUnspsc190501.51151522': __('identifierSchemeCodeUnspsc190501.51151522'),
	'identifierSchemeCodeUnspsc190501.51151523': __('identifierSchemeCodeUnspsc190501.51151523'),
	'identifierSchemeCodeUnspsc190501.51151524': __('identifierSchemeCodeUnspsc190501.51151524'),
	'identifierSchemeCodeUnspsc190501.51151525': __('identifierSchemeCodeUnspsc190501.51151525'),
	'identifierSchemeCodeUnspsc190501.51151526': __('identifierSchemeCodeUnspsc190501.51151526'),
	'identifierSchemeCodeUnspsc190501.51151527': __('identifierSchemeCodeUnspsc190501.51151527'),
	'identifierSchemeCodeUnspsc190501.51151528': __('identifierSchemeCodeUnspsc190501.51151528'),
	'identifierSchemeCodeUnspsc190501.51151529': __('identifierSchemeCodeUnspsc190501.51151529'),
	'identifierSchemeCodeUnspsc190501.51151530': __('identifierSchemeCodeUnspsc190501.51151530'),
	'identifierSchemeCodeUnspsc190501.51151531': __('identifierSchemeCodeUnspsc190501.51151531'),
	'identifierSchemeCodeUnspsc190501.51151532': __('identifierSchemeCodeUnspsc190501.51151532'),
	'identifierSchemeCodeUnspsc190501.51151533': __('identifierSchemeCodeUnspsc190501.51151533'),
	'identifierSchemeCodeUnspsc190501.51151534': __('identifierSchemeCodeUnspsc190501.51151534'),
	'identifierSchemeCodeUnspsc190501.51151535': __('identifierSchemeCodeUnspsc190501.51151535'),
	'identifierSchemeCodeUnspsc190501.51151536': __('identifierSchemeCodeUnspsc190501.51151536'),
	'identifierSchemeCodeUnspsc190501.51151537': __('identifierSchemeCodeUnspsc190501.51151537'),
	'identifierSchemeCodeUnspsc190501.51151538': __('identifierSchemeCodeUnspsc190501.51151538'),
	'identifierSchemeCodeUnspsc190501.51151539': __('identifierSchemeCodeUnspsc190501.51151539'),
	'identifierSchemeCodeUnspsc190501.51151540': __('identifierSchemeCodeUnspsc190501.51151540'),
	'identifierSchemeCodeUnspsc190501.51151541': __('identifierSchemeCodeUnspsc190501.51151541'),
	'identifierSchemeCodeUnspsc190501.51151542': __('identifierSchemeCodeUnspsc190501.51151542'),
	'identifierSchemeCodeUnspsc190501.51151543': __('identifierSchemeCodeUnspsc190501.51151543'),
	'identifierSchemeCodeUnspsc190501.51151544': __('identifierSchemeCodeUnspsc190501.51151544'),
	'identifierSchemeCodeUnspsc190501.51151545': __('identifierSchemeCodeUnspsc190501.51151545'),
	'identifierSchemeCodeUnspsc190501.51151546': __('identifierSchemeCodeUnspsc190501.51151546'),
	'identifierSchemeCodeUnspsc190501.51151547': __('identifierSchemeCodeUnspsc190501.51151547'),
	'identifierSchemeCodeUnspsc190501.51151548': __('identifierSchemeCodeUnspsc190501.51151548'),
	'identifierSchemeCodeUnspsc190501.51151549': __('identifierSchemeCodeUnspsc190501.51151549'),
	'identifierSchemeCodeUnspsc190501.51151550': __('identifierSchemeCodeUnspsc190501.51151550'),
	'identifierSchemeCodeUnspsc190501.51151551': __('identifierSchemeCodeUnspsc190501.51151551'),
	'identifierSchemeCodeUnspsc190501.51151552': __('identifierSchemeCodeUnspsc190501.51151552'),
	'identifierSchemeCodeUnspsc190501.51151600': __('identifierSchemeCodeUnspsc190501.51151600'),
	'identifierSchemeCodeUnspsc190501.51151602': __('identifierSchemeCodeUnspsc190501.51151602'),
	'identifierSchemeCodeUnspsc190501.51151603': __('identifierSchemeCodeUnspsc190501.51151603'),
	'identifierSchemeCodeUnspsc190501.51151604': __('identifierSchemeCodeUnspsc190501.51151604'),
	'identifierSchemeCodeUnspsc190501.51151606': __('identifierSchemeCodeUnspsc190501.51151606'),
	'identifierSchemeCodeUnspsc190501.51151607': __('identifierSchemeCodeUnspsc190501.51151607'),
	'identifierSchemeCodeUnspsc190501.51151608': __('identifierSchemeCodeUnspsc190501.51151608'),
	'identifierSchemeCodeUnspsc190501.51151609': __('identifierSchemeCodeUnspsc190501.51151609'),
	'identifierSchemeCodeUnspsc190501.51151611': __('identifierSchemeCodeUnspsc190501.51151611'),
	'identifierSchemeCodeUnspsc190501.51151612': __('identifierSchemeCodeUnspsc190501.51151612'),
	'identifierSchemeCodeUnspsc190501.51151614': __('identifierSchemeCodeUnspsc190501.51151614'),
	'identifierSchemeCodeUnspsc190501.51151616': __('identifierSchemeCodeUnspsc190501.51151616'),
	'identifierSchemeCodeUnspsc190501.51151617': __('identifierSchemeCodeUnspsc190501.51151617'),
	'identifierSchemeCodeUnspsc190501.51151618': __('identifierSchemeCodeUnspsc190501.51151618'),
	'identifierSchemeCodeUnspsc190501.51151619': __('identifierSchemeCodeUnspsc190501.51151619'),
	'identifierSchemeCodeUnspsc190501.51151620': __('identifierSchemeCodeUnspsc190501.51151620'),
	'identifierSchemeCodeUnspsc190501.51151621': __('identifierSchemeCodeUnspsc190501.51151621'),
	'identifierSchemeCodeUnspsc190501.51151622': __('identifierSchemeCodeUnspsc190501.51151622'),
	'identifierSchemeCodeUnspsc190501.51151623': __('identifierSchemeCodeUnspsc190501.51151623'),
	'identifierSchemeCodeUnspsc190501.51151624': __('identifierSchemeCodeUnspsc190501.51151624'),
	'identifierSchemeCodeUnspsc190501.51151625': __('identifierSchemeCodeUnspsc190501.51151625'),
	'identifierSchemeCodeUnspsc190501.51151626': __('identifierSchemeCodeUnspsc190501.51151626'),
	'identifierSchemeCodeUnspsc190501.51151627': __('identifierSchemeCodeUnspsc190501.51151627'),
	'identifierSchemeCodeUnspsc190501.51151628': __('identifierSchemeCodeUnspsc190501.51151628'),
	'identifierSchemeCodeUnspsc190501.51151629': __('identifierSchemeCodeUnspsc190501.51151629'),
	'identifierSchemeCodeUnspsc190501.51151630': __('identifierSchemeCodeUnspsc190501.51151630'),
	'identifierSchemeCodeUnspsc190501.51151631': __('identifierSchemeCodeUnspsc190501.51151631'),
	'identifierSchemeCodeUnspsc190501.51151632': __('identifierSchemeCodeUnspsc190501.51151632'),
	'identifierSchemeCodeUnspsc190501.51151633': __('identifierSchemeCodeUnspsc190501.51151633'),
	'identifierSchemeCodeUnspsc190501.51151634': __('identifierSchemeCodeUnspsc190501.51151634'),
	'identifierSchemeCodeUnspsc190501.51151635': __('identifierSchemeCodeUnspsc190501.51151635'),
	'identifierSchemeCodeUnspsc190501.51151636': __('identifierSchemeCodeUnspsc190501.51151636'),
	'identifierSchemeCodeUnspsc190501.51151637': __('identifierSchemeCodeUnspsc190501.51151637'),
	'identifierSchemeCodeUnspsc190501.51151638': __('identifierSchemeCodeUnspsc190501.51151638'),
	'identifierSchemeCodeUnspsc190501.51151639': __('identifierSchemeCodeUnspsc190501.51151639'),
	'identifierSchemeCodeUnspsc190501.51151640': __('identifierSchemeCodeUnspsc190501.51151640'),
	'identifierSchemeCodeUnspsc190501.51151641': __('identifierSchemeCodeUnspsc190501.51151641'),
	'identifierSchemeCodeUnspsc190501.51151642': __('identifierSchemeCodeUnspsc190501.51151642'),
	'identifierSchemeCodeUnspsc190501.51151643': __('identifierSchemeCodeUnspsc190501.51151643'),
	'identifierSchemeCodeUnspsc190501.51151644': __('identifierSchemeCodeUnspsc190501.51151644'),
	'identifierSchemeCodeUnspsc190501.51151645': __('identifierSchemeCodeUnspsc190501.51151645'),
	'identifierSchemeCodeUnspsc190501.51151646': __('identifierSchemeCodeUnspsc190501.51151646'),
	'identifierSchemeCodeUnspsc190501.51151647': __('identifierSchemeCodeUnspsc190501.51151647'),
	'identifierSchemeCodeUnspsc190501.51151648': __('identifierSchemeCodeUnspsc190501.51151648'),
	'identifierSchemeCodeUnspsc190501.51151649': __('identifierSchemeCodeUnspsc190501.51151649'),
	'identifierSchemeCodeUnspsc190501.51151650': __('identifierSchemeCodeUnspsc190501.51151650'),
	'identifierSchemeCodeUnspsc190501.51151651': __('identifierSchemeCodeUnspsc190501.51151651'),
	'identifierSchemeCodeUnspsc190501.51151652': __('identifierSchemeCodeUnspsc190501.51151652'),
	'identifierSchemeCodeUnspsc190501.51151653': __('identifierSchemeCodeUnspsc190501.51151653'),
	'identifierSchemeCodeUnspsc190501.51151654': __('identifierSchemeCodeUnspsc190501.51151654'),
	'identifierSchemeCodeUnspsc190501.51151655': __('identifierSchemeCodeUnspsc190501.51151655'),
	'identifierSchemeCodeUnspsc190501.51151656': __('identifierSchemeCodeUnspsc190501.51151656'),
	'identifierSchemeCodeUnspsc190501.51151657': __('identifierSchemeCodeUnspsc190501.51151657'),
	'identifierSchemeCodeUnspsc190501.51151658': __('identifierSchemeCodeUnspsc190501.51151658'),
	'identifierSchemeCodeUnspsc190501.51151659': __('identifierSchemeCodeUnspsc190501.51151659'),
	'identifierSchemeCodeUnspsc190501.51151660': __('identifierSchemeCodeUnspsc190501.51151660'),
	'identifierSchemeCodeUnspsc190501.51151661': __('identifierSchemeCodeUnspsc190501.51151661'),
	'identifierSchemeCodeUnspsc190501.51151662': __('identifierSchemeCodeUnspsc190501.51151662'),
	'identifierSchemeCodeUnspsc190501.51151663': __('identifierSchemeCodeUnspsc190501.51151663'),
	'identifierSchemeCodeUnspsc190501.51151664': __('identifierSchemeCodeUnspsc190501.51151664'),
	'identifierSchemeCodeUnspsc190501.51151665': __('identifierSchemeCodeUnspsc190501.51151665'),
	'identifierSchemeCodeUnspsc190501.51151666': __('identifierSchemeCodeUnspsc190501.51151666'),
	'identifierSchemeCodeUnspsc190501.51151667': __('identifierSchemeCodeUnspsc190501.51151667'),
	'identifierSchemeCodeUnspsc190501.51151668': __('identifierSchemeCodeUnspsc190501.51151668'),
	'identifierSchemeCodeUnspsc190501.51151669': __('identifierSchemeCodeUnspsc190501.51151669'),
	'identifierSchemeCodeUnspsc190501.51151670': __('identifierSchemeCodeUnspsc190501.51151670'),
	'identifierSchemeCodeUnspsc190501.51151671': __('identifierSchemeCodeUnspsc190501.51151671'),
	'identifierSchemeCodeUnspsc190501.51151672': __('identifierSchemeCodeUnspsc190501.51151672'),
	'identifierSchemeCodeUnspsc190501.51151673': __('identifierSchemeCodeUnspsc190501.51151673'),
	'identifierSchemeCodeUnspsc190501.51151900': __('identifierSchemeCodeUnspsc190501.51151900'),
	'identifierSchemeCodeUnspsc190501.51151902': __('identifierSchemeCodeUnspsc190501.51151902'),
	'identifierSchemeCodeUnspsc190501.51151904': __('identifierSchemeCodeUnspsc190501.51151904'),
	'identifierSchemeCodeUnspsc190501.51151905': __('identifierSchemeCodeUnspsc190501.51151905'),
	'identifierSchemeCodeUnspsc190501.51151912': __('identifierSchemeCodeUnspsc190501.51151912'),
	'identifierSchemeCodeUnspsc190501.51151921': __('identifierSchemeCodeUnspsc190501.51151921'),
	'identifierSchemeCodeUnspsc190501.51151922': __('identifierSchemeCodeUnspsc190501.51151922'),
	'identifierSchemeCodeUnspsc190501.51152000': __('identifierSchemeCodeUnspsc190501.51152000'),
	'identifierSchemeCodeUnspsc190501.51152001': __('identifierSchemeCodeUnspsc190501.51152001'),
	'identifierSchemeCodeUnspsc190501.51152003': __('identifierSchemeCodeUnspsc190501.51152003'),
	'identifierSchemeCodeUnspsc190501.51152004': __('identifierSchemeCodeUnspsc190501.51152004'),
	'identifierSchemeCodeUnspsc190501.51152005': __('identifierSchemeCodeUnspsc190501.51152005'),
	'identifierSchemeCodeUnspsc190501.51152006': __('identifierSchemeCodeUnspsc190501.51152006'),
	'identifierSchemeCodeUnspsc190501.51152007': __('identifierSchemeCodeUnspsc190501.51152007'),
	'identifierSchemeCodeUnspsc190501.51152008': __('identifierSchemeCodeUnspsc190501.51152008'),
	'identifierSchemeCodeUnspsc190501.51152009': __('identifierSchemeCodeUnspsc190501.51152009'),
	'identifierSchemeCodeUnspsc190501.51152010': __('identifierSchemeCodeUnspsc190501.51152010'),
	'identifierSchemeCodeUnspsc190501.51152011': __('identifierSchemeCodeUnspsc190501.51152011'),
	'identifierSchemeCodeUnspsc190501.51152012': __('identifierSchemeCodeUnspsc190501.51152012'),
	'identifierSchemeCodeUnspsc190501.51152013': __('identifierSchemeCodeUnspsc190501.51152013'),
	'identifierSchemeCodeUnspsc190501.51152014': __('identifierSchemeCodeUnspsc190501.51152014'),
	'identifierSchemeCodeUnspsc190501.51152015': __('identifierSchemeCodeUnspsc190501.51152015'),
	'identifierSchemeCodeUnspsc190501.51152016': __('identifierSchemeCodeUnspsc190501.51152016'),
	'identifierSchemeCodeUnspsc190501.51152017': __('identifierSchemeCodeUnspsc190501.51152017'),
	'identifierSchemeCodeUnspsc190501.51152018': __('identifierSchemeCodeUnspsc190501.51152018'),
	'identifierSchemeCodeUnspsc190501.51152019': __('identifierSchemeCodeUnspsc190501.51152019'),
	'identifierSchemeCodeUnspsc190501.51152020': __('identifierSchemeCodeUnspsc190501.51152020'),
	'identifierSchemeCodeUnspsc190501.51152021': __('identifierSchemeCodeUnspsc190501.51152021'),
	'identifierSchemeCodeUnspsc190501.51152022': __('identifierSchemeCodeUnspsc190501.51152022'),
	'identifierSchemeCodeUnspsc190501.51152023': __('identifierSchemeCodeUnspsc190501.51152023'),
	'identifierSchemeCodeUnspsc190501.51152024': __('identifierSchemeCodeUnspsc190501.51152024'),
	'identifierSchemeCodeUnspsc190501.51152025': __('identifierSchemeCodeUnspsc190501.51152025'),
	'identifierSchemeCodeUnspsc190501.51152026': __('identifierSchemeCodeUnspsc190501.51152026'),
	'identifierSchemeCodeUnspsc190501.51152027': __('identifierSchemeCodeUnspsc190501.51152027'),
	'identifierSchemeCodeUnspsc190501.51152028': __('identifierSchemeCodeUnspsc190501.51152028'),
	'identifierSchemeCodeUnspsc190501.51152029': __('identifierSchemeCodeUnspsc190501.51152029'),
	'identifierSchemeCodeUnspsc190501.51152030': __('identifierSchemeCodeUnspsc190501.51152030'),
	'identifierSchemeCodeUnspsc190501.51152031': __('identifierSchemeCodeUnspsc190501.51152031'),
	'identifierSchemeCodeUnspsc190501.51152032': __('identifierSchemeCodeUnspsc190501.51152032'),
	'identifierSchemeCodeUnspsc190501.51152033': __('identifierSchemeCodeUnspsc190501.51152033'),
	'identifierSchemeCodeUnspsc190501.51152034': __('identifierSchemeCodeUnspsc190501.51152034'),
	'identifierSchemeCodeUnspsc190501.51152035': __('identifierSchemeCodeUnspsc190501.51152035'),
	'identifierSchemeCodeUnspsc190501.51152036': __('identifierSchemeCodeUnspsc190501.51152036'),
	'identifierSchemeCodeUnspsc190501.51152037': __('identifierSchemeCodeUnspsc190501.51152037'),
	'identifierSchemeCodeUnspsc190501.51152038': __('identifierSchemeCodeUnspsc190501.51152038'),
	'identifierSchemeCodeUnspsc190501.51152039': __('identifierSchemeCodeUnspsc190501.51152039'),
	'identifierSchemeCodeUnspsc190501.51152040': __('identifierSchemeCodeUnspsc190501.51152040'),
	'identifierSchemeCodeUnspsc190501.51152041': __('identifierSchemeCodeUnspsc190501.51152041'),
	'identifierSchemeCodeUnspsc190501.51152042': __('identifierSchemeCodeUnspsc190501.51152042'),
	'identifierSchemeCodeUnspsc190501.51152043': __('identifierSchemeCodeUnspsc190501.51152043'),
	'identifierSchemeCodeUnspsc190501.51152044': __('identifierSchemeCodeUnspsc190501.51152044'),
	'identifierSchemeCodeUnspsc190501.51152046': __('identifierSchemeCodeUnspsc190501.51152046'),
	'identifierSchemeCodeUnspsc190501.51152047': __('identifierSchemeCodeUnspsc190501.51152047'),
	'identifierSchemeCodeUnspsc190501.51152048': __('identifierSchemeCodeUnspsc190501.51152048'),
	'identifierSchemeCodeUnspsc190501.51152049': __('identifierSchemeCodeUnspsc190501.51152049'),
	'identifierSchemeCodeUnspsc190501.51152050': __('identifierSchemeCodeUnspsc190501.51152050'),
	'identifierSchemeCodeUnspsc190501.51152051': __('identifierSchemeCodeUnspsc190501.51152051'),
	'identifierSchemeCodeUnspsc190501.51152052': __('identifierSchemeCodeUnspsc190501.51152052'),
	'identifierSchemeCodeUnspsc190501.51152053': __('identifierSchemeCodeUnspsc190501.51152053'),
	'identifierSchemeCodeUnspsc190501.51152054': __('identifierSchemeCodeUnspsc190501.51152054'),
	'identifierSchemeCodeUnspsc190501.51152055': __('identifierSchemeCodeUnspsc190501.51152055'),
	'identifierSchemeCodeUnspsc190501.51152056': __('identifierSchemeCodeUnspsc190501.51152056'),
	'identifierSchemeCodeUnspsc190501.51152057': __('identifierSchemeCodeUnspsc190501.51152057'),
	'identifierSchemeCodeUnspsc190501.51152058': __('identifierSchemeCodeUnspsc190501.51152058'),
	'identifierSchemeCodeUnspsc190501.51152059': __('identifierSchemeCodeUnspsc190501.51152059'),
	'identifierSchemeCodeUnspsc190501.51152060': __('identifierSchemeCodeUnspsc190501.51152060'),
	'identifierSchemeCodeUnspsc190501.51152061': __('identifierSchemeCodeUnspsc190501.51152061'),
	'identifierSchemeCodeUnspsc190501.51152062': __('identifierSchemeCodeUnspsc190501.51152062'),
	'identifierSchemeCodeUnspsc190501.51152063': __('identifierSchemeCodeUnspsc190501.51152063'),
	'identifierSchemeCodeUnspsc190501.51152064': __('identifierSchemeCodeUnspsc190501.51152064'),
	'identifierSchemeCodeUnspsc190501.51152065': __('identifierSchemeCodeUnspsc190501.51152065'),
	'identifierSchemeCodeUnspsc190501.51152066': __('identifierSchemeCodeUnspsc190501.51152066'),
	'identifierSchemeCodeUnspsc190501.51152067': __('identifierSchemeCodeUnspsc190501.51152067'),
	'identifierSchemeCodeUnspsc190501.51152068': __('identifierSchemeCodeUnspsc190501.51152068'),
	'identifierSchemeCodeUnspsc190501.51152069': __('identifierSchemeCodeUnspsc190501.51152069'),
	'identifierSchemeCodeUnspsc190501.51152070': __('identifierSchemeCodeUnspsc190501.51152070'),
	'identifierSchemeCodeUnspsc190501.51152071': __('identifierSchemeCodeUnspsc190501.51152071'),
	'identifierSchemeCodeUnspsc190501.51152072': __('identifierSchemeCodeUnspsc190501.51152072'),
	'identifierSchemeCodeUnspsc190501.51152073': __('identifierSchemeCodeUnspsc190501.51152073'),
	'identifierSchemeCodeUnspsc190501.51152074': __('identifierSchemeCodeUnspsc190501.51152074'),
	'identifierSchemeCodeUnspsc190501.51152075': __('identifierSchemeCodeUnspsc190501.51152075'),
	'identifierSchemeCodeUnspsc190501.51152076': __('identifierSchemeCodeUnspsc190501.51152076'),
	'identifierSchemeCodeUnspsc190501.51152100': __('identifierSchemeCodeUnspsc190501.51152100'),
	'identifierSchemeCodeUnspsc190501.51152101': __('identifierSchemeCodeUnspsc190501.51152101'),
	'identifierSchemeCodeUnspsc190501.51152102': __('identifierSchemeCodeUnspsc190501.51152102'),
	'identifierSchemeCodeUnspsc190501.51152103': __('identifierSchemeCodeUnspsc190501.51152103'),
	'identifierSchemeCodeUnspsc190501.51152104': __('identifierSchemeCodeUnspsc190501.51152104'),
	'identifierSchemeCodeUnspsc190501.51152105': __('identifierSchemeCodeUnspsc190501.51152105'),
	'identifierSchemeCodeUnspsc190501.51152106': __('identifierSchemeCodeUnspsc190501.51152106'),
	'identifierSchemeCodeUnspsc190501.51152107': __('identifierSchemeCodeUnspsc190501.51152107'),
	'identifierSchemeCodeUnspsc190501.51152108': __('identifierSchemeCodeUnspsc190501.51152108'),
	'identifierSchemeCodeUnspsc190501.51152109': __('identifierSchemeCodeUnspsc190501.51152109'),
	'identifierSchemeCodeUnspsc190501.51152110': __('identifierSchemeCodeUnspsc190501.51152110'),
	'identifierSchemeCodeUnspsc190501.51152111': __('identifierSchemeCodeUnspsc190501.51152111'),
	'identifierSchemeCodeUnspsc190501.51152112': __('identifierSchemeCodeUnspsc190501.51152112'),
	'identifierSchemeCodeUnspsc190501.51152113': __('identifierSchemeCodeUnspsc190501.51152113'),
	'identifierSchemeCodeUnspsc190501.51152114': __('identifierSchemeCodeUnspsc190501.51152114'),
	'identifierSchemeCodeUnspsc190501.51152115': __('identifierSchemeCodeUnspsc190501.51152115'),
	'identifierSchemeCodeUnspsc190501.51152116': __('identifierSchemeCodeUnspsc190501.51152116'),
	'identifierSchemeCodeUnspsc190501.51152117': __('identifierSchemeCodeUnspsc190501.51152117'),
	'identifierSchemeCodeUnspsc190501.51152200': __('identifierSchemeCodeUnspsc190501.51152200'),
	'identifierSchemeCodeUnspsc190501.51152201': __('identifierSchemeCodeUnspsc190501.51152201'),
	'identifierSchemeCodeUnspsc190501.51152300': __('identifierSchemeCodeUnspsc190501.51152300'),
	'identifierSchemeCodeUnspsc190501.51152301': __('identifierSchemeCodeUnspsc190501.51152301'),
	'identifierSchemeCodeUnspsc190501.51152302': __('identifierSchemeCodeUnspsc190501.51152302'),
	'identifierSchemeCodeUnspsc190501.51152400': __('identifierSchemeCodeUnspsc190501.51152400'),
	'identifierSchemeCodeUnspsc190501.51152401': __('identifierSchemeCodeUnspsc190501.51152401'),
	'identifierSchemeCodeUnspsc190501.51152402': __('identifierSchemeCodeUnspsc190501.51152402'),
	'identifierSchemeCodeUnspsc190501.51152403': __('identifierSchemeCodeUnspsc190501.51152403'),
	'identifierSchemeCodeUnspsc190501.51152404': __('identifierSchemeCodeUnspsc190501.51152404'),
	'identifierSchemeCodeUnspsc190501.51160000': __('identifierSchemeCodeUnspsc190501.51160000'),
	'identifierSchemeCodeUnspsc190501.51161500': __('identifierSchemeCodeUnspsc190501.51161500'),
	'identifierSchemeCodeUnspsc190501.51161513': __('identifierSchemeCodeUnspsc190501.51161513'),
	'identifierSchemeCodeUnspsc190501.51161515': __('identifierSchemeCodeUnspsc190501.51161515'),
	'identifierSchemeCodeUnspsc190501.51161517': __('identifierSchemeCodeUnspsc190501.51161517'),
	'identifierSchemeCodeUnspsc190501.51161518': __('identifierSchemeCodeUnspsc190501.51161518'),
	'identifierSchemeCodeUnspsc190501.51161519': __('identifierSchemeCodeUnspsc190501.51161519'),
	'identifierSchemeCodeUnspsc190501.51161521': __('identifierSchemeCodeUnspsc190501.51161521'),
	'identifierSchemeCodeUnspsc190501.51161524': __('identifierSchemeCodeUnspsc190501.51161524'),
	'identifierSchemeCodeUnspsc190501.51161526': __('identifierSchemeCodeUnspsc190501.51161526'),
	'identifierSchemeCodeUnspsc190501.51161527': __('identifierSchemeCodeUnspsc190501.51161527'),
	'identifierSchemeCodeUnspsc190501.51161528': __('identifierSchemeCodeUnspsc190501.51161528'),
	'identifierSchemeCodeUnspsc190501.51161529': __('identifierSchemeCodeUnspsc190501.51161529'),
	'identifierSchemeCodeUnspsc190501.51161530': __('identifierSchemeCodeUnspsc190501.51161530'),
	'identifierSchemeCodeUnspsc190501.51161531': __('identifierSchemeCodeUnspsc190501.51161531'),
	'identifierSchemeCodeUnspsc190501.51161532': __('identifierSchemeCodeUnspsc190501.51161532'),
	'identifierSchemeCodeUnspsc190501.51161533': __('identifierSchemeCodeUnspsc190501.51161533'),
	'identifierSchemeCodeUnspsc190501.51161534': __('identifierSchemeCodeUnspsc190501.51161534'),
	'identifierSchemeCodeUnspsc190501.51161535': __('identifierSchemeCodeUnspsc190501.51161535'),
	'identifierSchemeCodeUnspsc190501.51161536': __('identifierSchemeCodeUnspsc190501.51161536'),
	'identifierSchemeCodeUnspsc190501.51161537': __('identifierSchemeCodeUnspsc190501.51161537'),
	'identifierSchemeCodeUnspsc190501.51161538': __('identifierSchemeCodeUnspsc190501.51161538'),
	'identifierSchemeCodeUnspsc190501.51161539': __('identifierSchemeCodeUnspsc190501.51161539'),
	'identifierSchemeCodeUnspsc190501.51161540': __('identifierSchemeCodeUnspsc190501.51161540'),
	'identifierSchemeCodeUnspsc190501.51161541': __('identifierSchemeCodeUnspsc190501.51161541'),
	'identifierSchemeCodeUnspsc190501.51161542': __('identifierSchemeCodeUnspsc190501.51161542'),
	'identifierSchemeCodeUnspsc190501.51161543': __('identifierSchemeCodeUnspsc190501.51161543'),
	'identifierSchemeCodeUnspsc190501.51161544': __('identifierSchemeCodeUnspsc190501.51161544'),
	'identifierSchemeCodeUnspsc190501.51161545': __('identifierSchemeCodeUnspsc190501.51161545'),
	'identifierSchemeCodeUnspsc190501.51161546': __('identifierSchemeCodeUnspsc190501.51161546'),
	'identifierSchemeCodeUnspsc190501.51161547': __('identifierSchemeCodeUnspsc190501.51161547'),
	'identifierSchemeCodeUnspsc190501.51161548': __('identifierSchemeCodeUnspsc190501.51161548'),
	'identifierSchemeCodeUnspsc190501.51161549': __('identifierSchemeCodeUnspsc190501.51161549'),
	'identifierSchemeCodeUnspsc190501.51161550': __('identifierSchemeCodeUnspsc190501.51161550'),
	'identifierSchemeCodeUnspsc190501.51161551': __('identifierSchemeCodeUnspsc190501.51161551'),
	'identifierSchemeCodeUnspsc190501.51161552': __('identifierSchemeCodeUnspsc190501.51161552'),
	'identifierSchemeCodeUnspsc190501.51161553': __('identifierSchemeCodeUnspsc190501.51161553'),
	'identifierSchemeCodeUnspsc190501.51161554': __('identifierSchemeCodeUnspsc190501.51161554'),
	'identifierSchemeCodeUnspsc190501.51161555': __('identifierSchemeCodeUnspsc190501.51161555'),
	'identifierSchemeCodeUnspsc190501.51161556': __('identifierSchemeCodeUnspsc190501.51161556'),
	'identifierSchemeCodeUnspsc190501.51161557': __('identifierSchemeCodeUnspsc190501.51161557'),
	'identifierSchemeCodeUnspsc190501.51161558': __('identifierSchemeCodeUnspsc190501.51161558'),
	'identifierSchemeCodeUnspsc190501.51161559': __('identifierSchemeCodeUnspsc190501.51161559'),
	'identifierSchemeCodeUnspsc190501.51161560': __('identifierSchemeCodeUnspsc190501.51161560'),
	'identifierSchemeCodeUnspsc190501.51161561': __('identifierSchemeCodeUnspsc190501.51161561'),
	'identifierSchemeCodeUnspsc190501.51161562': __('identifierSchemeCodeUnspsc190501.51161562'),
	'identifierSchemeCodeUnspsc190501.51161563': __('identifierSchemeCodeUnspsc190501.51161563'),
	'identifierSchemeCodeUnspsc190501.51161564': __('identifierSchemeCodeUnspsc190501.51161564'),
	'identifierSchemeCodeUnspsc190501.51161565': __('identifierSchemeCodeUnspsc190501.51161565'),
	'identifierSchemeCodeUnspsc190501.51161566': __('identifierSchemeCodeUnspsc190501.51161566'),
	'identifierSchemeCodeUnspsc190501.51161567': __('identifierSchemeCodeUnspsc190501.51161567'),
	'identifierSchemeCodeUnspsc190501.51161568': __('identifierSchemeCodeUnspsc190501.51161568'),
	'identifierSchemeCodeUnspsc190501.51161569': __('identifierSchemeCodeUnspsc190501.51161569'),
	'identifierSchemeCodeUnspsc190501.51161570': __('identifierSchemeCodeUnspsc190501.51161570'),
	'identifierSchemeCodeUnspsc190501.51161571': __('identifierSchemeCodeUnspsc190501.51161571'),
	'identifierSchemeCodeUnspsc190501.51161572': __('identifierSchemeCodeUnspsc190501.51161572'),
	'identifierSchemeCodeUnspsc190501.51161573': __('identifierSchemeCodeUnspsc190501.51161573'),
	'identifierSchemeCodeUnspsc190501.51161574': __('identifierSchemeCodeUnspsc190501.51161574'),
	'identifierSchemeCodeUnspsc190501.51161700': __('identifierSchemeCodeUnspsc190501.51161700'),
	'identifierSchemeCodeUnspsc190501.51161702': __('identifierSchemeCodeUnspsc190501.51161702'),
	'identifierSchemeCodeUnspsc190501.51161704': __('identifierSchemeCodeUnspsc190501.51161704'),
	'identifierSchemeCodeUnspsc190501.51161705': __('identifierSchemeCodeUnspsc190501.51161705'),
	'identifierSchemeCodeUnspsc190501.51161708': __('identifierSchemeCodeUnspsc190501.51161708'),
	'identifierSchemeCodeUnspsc190501.51161709': __('identifierSchemeCodeUnspsc190501.51161709'),
	'identifierSchemeCodeUnspsc190501.51161710': __('identifierSchemeCodeUnspsc190501.51161710'),
	'identifierSchemeCodeUnspsc190501.51161711': __('identifierSchemeCodeUnspsc190501.51161711'),
	'identifierSchemeCodeUnspsc190501.51161712': __('identifierSchemeCodeUnspsc190501.51161712'),
	'identifierSchemeCodeUnspsc190501.51161713': __('identifierSchemeCodeUnspsc190501.51161713'),
	'identifierSchemeCodeUnspsc190501.51161714': __('identifierSchemeCodeUnspsc190501.51161714'),
	'identifierSchemeCodeUnspsc190501.51161715': __('identifierSchemeCodeUnspsc190501.51161715'),
	'identifierSchemeCodeUnspsc190501.51161716': __('identifierSchemeCodeUnspsc190501.51161716'),
	'identifierSchemeCodeUnspsc190501.51161717': __('identifierSchemeCodeUnspsc190501.51161717'),
	'identifierSchemeCodeUnspsc190501.51161718': __('identifierSchemeCodeUnspsc190501.51161718'),
	'identifierSchemeCodeUnspsc190501.51161719': __('identifierSchemeCodeUnspsc190501.51161719'),
	'identifierSchemeCodeUnspsc190501.51161720': __('identifierSchemeCodeUnspsc190501.51161720'),
	'identifierSchemeCodeUnspsc190501.51161721': __('identifierSchemeCodeUnspsc190501.51161721'),
	'identifierSchemeCodeUnspsc190501.51161722': __('identifierSchemeCodeUnspsc190501.51161722'),
	'identifierSchemeCodeUnspsc190501.51161723': __('identifierSchemeCodeUnspsc190501.51161723'),
	'identifierSchemeCodeUnspsc190501.51161724': __('identifierSchemeCodeUnspsc190501.51161724'),
	'identifierSchemeCodeUnspsc190501.51161725': __('identifierSchemeCodeUnspsc190501.51161725'),
	'identifierSchemeCodeUnspsc190501.51161726': __('identifierSchemeCodeUnspsc190501.51161726'),
	'identifierSchemeCodeUnspsc190501.51161727': __('identifierSchemeCodeUnspsc190501.51161727'),
	'identifierSchemeCodeUnspsc190501.51161728': __('identifierSchemeCodeUnspsc190501.51161728'),
	'identifierSchemeCodeUnspsc190501.51161729': __('identifierSchemeCodeUnspsc190501.51161729'),
	'identifierSchemeCodeUnspsc190501.51161730': __('identifierSchemeCodeUnspsc190501.51161730'),
	'identifierSchemeCodeUnspsc190501.51161731': __('identifierSchemeCodeUnspsc190501.51161731'),
	'identifierSchemeCodeUnspsc190501.51161800': __('identifierSchemeCodeUnspsc190501.51161800'),
	'identifierSchemeCodeUnspsc190501.51161812': __('identifierSchemeCodeUnspsc190501.51161812'),
	'identifierSchemeCodeUnspsc190501.51161823': __('identifierSchemeCodeUnspsc190501.51161823'),
	'identifierSchemeCodeUnspsc190501.51161883': __('identifierSchemeCodeUnspsc190501.51161883'),
	'identifierSchemeCodeUnspsc190501.51161884': __('identifierSchemeCodeUnspsc190501.51161884'),
	'identifierSchemeCodeUnspsc190501.51162000': __('identifierSchemeCodeUnspsc190501.51162000'),
	'identifierSchemeCodeUnspsc190501.51162001': __('identifierSchemeCodeUnspsc190501.51162001'),
	'identifierSchemeCodeUnspsc190501.51162002': __('identifierSchemeCodeUnspsc190501.51162002'),
	'identifierSchemeCodeUnspsc190501.51162003': __('identifierSchemeCodeUnspsc190501.51162003'),
	'identifierSchemeCodeUnspsc190501.51162004': __('identifierSchemeCodeUnspsc190501.51162004'),
	'identifierSchemeCodeUnspsc190501.51162005': __('identifierSchemeCodeUnspsc190501.51162005'),
	'identifierSchemeCodeUnspsc190501.51162006': __('identifierSchemeCodeUnspsc190501.51162006'),
	'identifierSchemeCodeUnspsc190501.51162007': __('identifierSchemeCodeUnspsc190501.51162007'),
	'identifierSchemeCodeUnspsc190501.51162008': __('identifierSchemeCodeUnspsc190501.51162008'),
	'identifierSchemeCodeUnspsc190501.51162009': __('identifierSchemeCodeUnspsc190501.51162009'),
	'identifierSchemeCodeUnspsc190501.51162010': __('identifierSchemeCodeUnspsc190501.51162010'),
	'identifierSchemeCodeUnspsc190501.51162011': __('identifierSchemeCodeUnspsc190501.51162011'),
	'identifierSchemeCodeUnspsc190501.51162012': __('identifierSchemeCodeUnspsc190501.51162012'),
	'identifierSchemeCodeUnspsc190501.51162013': __('identifierSchemeCodeUnspsc190501.51162013'),
	'identifierSchemeCodeUnspsc190501.51162014': __('identifierSchemeCodeUnspsc190501.51162014'),
	'identifierSchemeCodeUnspsc190501.51162015': __('identifierSchemeCodeUnspsc190501.51162015'),
	'identifierSchemeCodeUnspsc190501.51162016': __('identifierSchemeCodeUnspsc190501.51162016'),
	'identifierSchemeCodeUnspsc190501.51162017': __('identifierSchemeCodeUnspsc190501.51162017'),
	'identifierSchemeCodeUnspsc190501.51162018': __('identifierSchemeCodeUnspsc190501.51162018'),
	'identifierSchemeCodeUnspsc190501.51162019': __('identifierSchemeCodeUnspsc190501.51162019'),
	'identifierSchemeCodeUnspsc190501.51162020': __('identifierSchemeCodeUnspsc190501.51162020'),
	'identifierSchemeCodeUnspsc190501.51162021': __('identifierSchemeCodeUnspsc190501.51162021'),
	'identifierSchemeCodeUnspsc190501.51162022': __('identifierSchemeCodeUnspsc190501.51162022'),
	'identifierSchemeCodeUnspsc190501.51162023': __('identifierSchemeCodeUnspsc190501.51162023'),
	'identifierSchemeCodeUnspsc190501.51162024': __('identifierSchemeCodeUnspsc190501.51162024'),
	'identifierSchemeCodeUnspsc190501.51162025': __('identifierSchemeCodeUnspsc190501.51162025'),
	'identifierSchemeCodeUnspsc190501.51162026': __('identifierSchemeCodeUnspsc190501.51162026'),
	'identifierSchemeCodeUnspsc190501.51162027': __('identifierSchemeCodeUnspsc190501.51162027'),
	'identifierSchemeCodeUnspsc190501.51162028': __('identifierSchemeCodeUnspsc190501.51162028'),
	'identifierSchemeCodeUnspsc190501.51162029': __('identifierSchemeCodeUnspsc190501.51162029'),
	'identifierSchemeCodeUnspsc190501.51162030': __('identifierSchemeCodeUnspsc190501.51162030'),
	'identifierSchemeCodeUnspsc190501.51162031': __('identifierSchemeCodeUnspsc190501.51162031'),
	'identifierSchemeCodeUnspsc190501.51162032': __('identifierSchemeCodeUnspsc190501.51162032'),
	'identifierSchemeCodeUnspsc190501.51162033': __('identifierSchemeCodeUnspsc190501.51162033'),
	'identifierSchemeCodeUnspsc190501.51162034': __('identifierSchemeCodeUnspsc190501.51162034'),
	'identifierSchemeCodeUnspsc190501.51162035': __('identifierSchemeCodeUnspsc190501.51162035'),
	'identifierSchemeCodeUnspsc190501.51162036': __('identifierSchemeCodeUnspsc190501.51162036'),
	'identifierSchemeCodeUnspsc190501.51162100': __('identifierSchemeCodeUnspsc190501.51162100'),
	'identifierSchemeCodeUnspsc190501.51162101': __('identifierSchemeCodeUnspsc190501.51162101'),
	'identifierSchemeCodeUnspsc190501.51162102': __('identifierSchemeCodeUnspsc190501.51162102'),
	'identifierSchemeCodeUnspsc190501.51162103': __('identifierSchemeCodeUnspsc190501.51162103'),
	'identifierSchemeCodeUnspsc190501.51162200': __('identifierSchemeCodeUnspsc190501.51162200'),
	'identifierSchemeCodeUnspsc190501.51162201': __('identifierSchemeCodeUnspsc190501.51162201'),
	'identifierSchemeCodeUnspsc190501.51162202': __('identifierSchemeCodeUnspsc190501.51162202'),
	'identifierSchemeCodeUnspsc190501.51162203': __('identifierSchemeCodeUnspsc190501.51162203'),
	'identifierSchemeCodeUnspsc190501.51162204': __('identifierSchemeCodeUnspsc190501.51162204'),
	'identifierSchemeCodeUnspsc190501.51162205': __('identifierSchemeCodeUnspsc190501.51162205'),
	'identifierSchemeCodeUnspsc190501.51162206': __('identifierSchemeCodeUnspsc190501.51162206'),
	'identifierSchemeCodeUnspsc190501.51162207': __('identifierSchemeCodeUnspsc190501.51162207'),
	'identifierSchemeCodeUnspsc190501.51162208': __('identifierSchemeCodeUnspsc190501.51162208'),
	'identifierSchemeCodeUnspsc190501.51162209': __('identifierSchemeCodeUnspsc190501.51162209'),
	'identifierSchemeCodeUnspsc190501.51162210': __('identifierSchemeCodeUnspsc190501.51162210'),
	'identifierSchemeCodeUnspsc190501.51162300': __('identifierSchemeCodeUnspsc190501.51162300'),
	'identifierSchemeCodeUnspsc190501.51162301': __('identifierSchemeCodeUnspsc190501.51162301'),
	'identifierSchemeCodeUnspsc190501.51162302': __('identifierSchemeCodeUnspsc190501.51162302'),
	'identifierSchemeCodeUnspsc190501.51162303': __('identifierSchemeCodeUnspsc190501.51162303'),
	'identifierSchemeCodeUnspsc190501.51162304': __('identifierSchemeCodeUnspsc190501.51162304'),
	'identifierSchemeCodeUnspsc190501.51162305': __('identifierSchemeCodeUnspsc190501.51162305'),
	'identifierSchemeCodeUnspsc190501.51162306': __('identifierSchemeCodeUnspsc190501.51162306'),
	'identifierSchemeCodeUnspsc190501.51162307': __('identifierSchemeCodeUnspsc190501.51162307'),
	'identifierSchemeCodeUnspsc190501.51162308': __('identifierSchemeCodeUnspsc190501.51162308'),
	'identifierSchemeCodeUnspsc190501.51162309': __('identifierSchemeCodeUnspsc190501.51162309'),
	'identifierSchemeCodeUnspsc190501.51162310': __('identifierSchemeCodeUnspsc190501.51162310'),
	'identifierSchemeCodeUnspsc190501.51162311': __('identifierSchemeCodeUnspsc190501.51162311'),
	'identifierSchemeCodeUnspsc190501.51162312': __('identifierSchemeCodeUnspsc190501.51162312'),
	'identifierSchemeCodeUnspsc190501.51162313': __('identifierSchemeCodeUnspsc190501.51162313'),
	'identifierSchemeCodeUnspsc190501.51162314': __('identifierSchemeCodeUnspsc190501.51162314'),
	'identifierSchemeCodeUnspsc190501.51162315': __('identifierSchemeCodeUnspsc190501.51162315'),
	'identifierSchemeCodeUnspsc190501.51162316': __('identifierSchemeCodeUnspsc190501.51162316'),
	'identifierSchemeCodeUnspsc190501.51162317': __('identifierSchemeCodeUnspsc190501.51162317'),
	'identifierSchemeCodeUnspsc190501.51162318': __('identifierSchemeCodeUnspsc190501.51162318'),
	'identifierSchemeCodeUnspsc190501.51162319': __('identifierSchemeCodeUnspsc190501.51162319'),
	'identifierSchemeCodeUnspsc190501.51162320': __('identifierSchemeCodeUnspsc190501.51162320'),
	'identifierSchemeCodeUnspsc190501.51162321': __('identifierSchemeCodeUnspsc190501.51162321'),
	'identifierSchemeCodeUnspsc190501.51162322': __('identifierSchemeCodeUnspsc190501.51162322'),
	'identifierSchemeCodeUnspsc190501.51162323': __('identifierSchemeCodeUnspsc190501.51162323'),
	'identifierSchemeCodeUnspsc190501.51162324': __('identifierSchemeCodeUnspsc190501.51162324'),
	'identifierSchemeCodeUnspsc190501.51162325': __('identifierSchemeCodeUnspsc190501.51162325'),
	'identifierSchemeCodeUnspsc190501.51162326': __('identifierSchemeCodeUnspsc190501.51162326'),
	'identifierSchemeCodeUnspsc190501.51162327': __('identifierSchemeCodeUnspsc190501.51162327'),
	'identifierSchemeCodeUnspsc190501.51162328': __('identifierSchemeCodeUnspsc190501.51162328'),
	'identifierSchemeCodeUnspsc190501.51162329': __('identifierSchemeCodeUnspsc190501.51162329'),
	'identifierSchemeCodeUnspsc190501.51162330': __('identifierSchemeCodeUnspsc190501.51162330'),
	'identifierSchemeCodeUnspsc190501.51162331': __('identifierSchemeCodeUnspsc190501.51162331'),
	'identifierSchemeCodeUnspsc190501.51162332': __('identifierSchemeCodeUnspsc190501.51162332'),
	'identifierSchemeCodeUnspsc190501.51162333': __('identifierSchemeCodeUnspsc190501.51162333'),
	'identifierSchemeCodeUnspsc190501.51162334': __('identifierSchemeCodeUnspsc190501.51162334'),
	'identifierSchemeCodeUnspsc190501.51162335': __('identifierSchemeCodeUnspsc190501.51162335'),
	'identifierSchemeCodeUnspsc190501.51162336': __('identifierSchemeCodeUnspsc190501.51162336'),
	'identifierSchemeCodeUnspsc190501.51162337': __('identifierSchemeCodeUnspsc190501.51162337'),
	'identifierSchemeCodeUnspsc190501.51162338': __('identifierSchemeCodeUnspsc190501.51162338'),
	'identifierSchemeCodeUnspsc190501.51162339': __('identifierSchemeCodeUnspsc190501.51162339'),
	'identifierSchemeCodeUnspsc190501.51162340': __('identifierSchemeCodeUnspsc190501.51162340'),
	'identifierSchemeCodeUnspsc190501.51162341': __('identifierSchemeCodeUnspsc190501.51162341'),
	'identifierSchemeCodeUnspsc190501.51162342': __('identifierSchemeCodeUnspsc190501.51162342'),
	'identifierSchemeCodeUnspsc190501.51162343': __('identifierSchemeCodeUnspsc190501.51162343'),
	'identifierSchemeCodeUnspsc190501.51162344': __('identifierSchemeCodeUnspsc190501.51162344'),
	'identifierSchemeCodeUnspsc190501.51162345': __('identifierSchemeCodeUnspsc190501.51162345'),
	'identifierSchemeCodeUnspsc190501.51162346': __('identifierSchemeCodeUnspsc190501.51162346'),
	'identifierSchemeCodeUnspsc190501.51162347': __('identifierSchemeCodeUnspsc190501.51162347'),
	'identifierSchemeCodeUnspsc190501.51162348': __('identifierSchemeCodeUnspsc190501.51162348'),
	'identifierSchemeCodeUnspsc190501.51162349': __('identifierSchemeCodeUnspsc190501.51162349'),
	'identifierSchemeCodeUnspsc190501.51162350': __('identifierSchemeCodeUnspsc190501.51162350'),
	'identifierSchemeCodeUnspsc190501.51162351': __('identifierSchemeCodeUnspsc190501.51162351'),
	'identifierSchemeCodeUnspsc190501.51162352': __('identifierSchemeCodeUnspsc190501.51162352'),
	'identifierSchemeCodeUnspsc190501.51162353': __('identifierSchemeCodeUnspsc190501.51162353'),
	'identifierSchemeCodeUnspsc190501.51162354': __('identifierSchemeCodeUnspsc190501.51162354'),
	'identifierSchemeCodeUnspsc190501.51162355': __('identifierSchemeCodeUnspsc190501.51162355'),
	'identifierSchemeCodeUnspsc190501.51162356': __('identifierSchemeCodeUnspsc190501.51162356'),
	'identifierSchemeCodeUnspsc190501.51162400': __('identifierSchemeCodeUnspsc190501.51162400'),
	'identifierSchemeCodeUnspsc190501.51162401': __('identifierSchemeCodeUnspsc190501.51162401'),
	'identifierSchemeCodeUnspsc190501.51162402': __('identifierSchemeCodeUnspsc190501.51162402'),
	'identifierSchemeCodeUnspsc190501.51162403': __('identifierSchemeCodeUnspsc190501.51162403'),
	'identifierSchemeCodeUnspsc190501.51162404': __('identifierSchemeCodeUnspsc190501.51162404'),
	'identifierSchemeCodeUnspsc190501.51162405': __('identifierSchemeCodeUnspsc190501.51162405'),
	'identifierSchemeCodeUnspsc190501.51162406': __('identifierSchemeCodeUnspsc190501.51162406'),
	'identifierSchemeCodeUnspsc190501.51162407': __('identifierSchemeCodeUnspsc190501.51162407'),
	'identifierSchemeCodeUnspsc190501.51162408': __('identifierSchemeCodeUnspsc190501.51162408'),
	'identifierSchemeCodeUnspsc190501.51162409': __('identifierSchemeCodeUnspsc190501.51162409'),
	'identifierSchemeCodeUnspsc190501.51162410': __('identifierSchemeCodeUnspsc190501.51162410'),
	'identifierSchemeCodeUnspsc190501.51162411': __('identifierSchemeCodeUnspsc190501.51162411'),
	'identifierSchemeCodeUnspsc190501.51162412': __('identifierSchemeCodeUnspsc190501.51162412'),
	'identifierSchemeCodeUnspsc190501.51162413': __('identifierSchemeCodeUnspsc190501.51162413'),
	'identifierSchemeCodeUnspsc190501.51162414': __('identifierSchemeCodeUnspsc190501.51162414'),
	'identifierSchemeCodeUnspsc190501.51162415': __('identifierSchemeCodeUnspsc190501.51162415'),
	'identifierSchemeCodeUnspsc190501.51162416': __('identifierSchemeCodeUnspsc190501.51162416'),
	'identifierSchemeCodeUnspsc190501.51162417': __('identifierSchemeCodeUnspsc190501.51162417'),
	'identifierSchemeCodeUnspsc190501.51162418': __('identifierSchemeCodeUnspsc190501.51162418'),
	'identifierSchemeCodeUnspsc190501.51162419': __('identifierSchemeCodeUnspsc190501.51162419'),
	'identifierSchemeCodeUnspsc190501.51162500': __('identifierSchemeCodeUnspsc190501.51162500'),
	'identifierSchemeCodeUnspsc190501.51162501': __('identifierSchemeCodeUnspsc190501.51162501'),
	'identifierSchemeCodeUnspsc190501.51162502': __('identifierSchemeCodeUnspsc190501.51162502'),
	'identifierSchemeCodeUnspsc190501.51162503': __('identifierSchemeCodeUnspsc190501.51162503'),
	'identifierSchemeCodeUnspsc190501.51162504': __('identifierSchemeCodeUnspsc190501.51162504'),
	'identifierSchemeCodeUnspsc190501.51162505': __('identifierSchemeCodeUnspsc190501.51162505'),
	'identifierSchemeCodeUnspsc190501.51162506': __('identifierSchemeCodeUnspsc190501.51162506'),
	'identifierSchemeCodeUnspsc190501.51162507': __('identifierSchemeCodeUnspsc190501.51162507'),
	'identifierSchemeCodeUnspsc190501.51162508': __('identifierSchemeCodeUnspsc190501.51162508'),
	'identifierSchemeCodeUnspsc190501.51162509': __('identifierSchemeCodeUnspsc190501.51162509'),
	'identifierSchemeCodeUnspsc190501.51162510': __('identifierSchemeCodeUnspsc190501.51162510'),
	'identifierSchemeCodeUnspsc190501.51162511': __('identifierSchemeCodeUnspsc190501.51162511'),
	'identifierSchemeCodeUnspsc190501.51162600': __('identifierSchemeCodeUnspsc190501.51162600'),
	'identifierSchemeCodeUnspsc190501.51162601': __('identifierSchemeCodeUnspsc190501.51162601'),
	'identifierSchemeCodeUnspsc190501.51162602': __('identifierSchemeCodeUnspsc190501.51162602'),
	'identifierSchemeCodeUnspsc190501.51162603': __('identifierSchemeCodeUnspsc190501.51162603'),
	'identifierSchemeCodeUnspsc190501.51162604': __('identifierSchemeCodeUnspsc190501.51162604'),
	'identifierSchemeCodeUnspsc190501.51162605': __('identifierSchemeCodeUnspsc190501.51162605'),
	'identifierSchemeCodeUnspsc190501.51162606': __('identifierSchemeCodeUnspsc190501.51162606'),
	'identifierSchemeCodeUnspsc190501.51162607': __('identifierSchemeCodeUnspsc190501.51162607'),
	'identifierSchemeCodeUnspsc190501.51162608': __('identifierSchemeCodeUnspsc190501.51162608'),
	'identifierSchemeCodeUnspsc190501.51162609': __('identifierSchemeCodeUnspsc190501.51162609'),
	'identifierSchemeCodeUnspsc190501.51162610': __('identifierSchemeCodeUnspsc190501.51162610'),
	'identifierSchemeCodeUnspsc190501.51162611': __('identifierSchemeCodeUnspsc190501.51162611'),
	'identifierSchemeCodeUnspsc190501.51162612': __('identifierSchemeCodeUnspsc190501.51162612'),
	'identifierSchemeCodeUnspsc190501.51162613': __('identifierSchemeCodeUnspsc190501.51162613'),
	'identifierSchemeCodeUnspsc190501.51162614': __('identifierSchemeCodeUnspsc190501.51162614'),
	'identifierSchemeCodeUnspsc190501.51162615': __('identifierSchemeCodeUnspsc190501.51162615'),
	'identifierSchemeCodeUnspsc190501.51162616': __('identifierSchemeCodeUnspsc190501.51162616'),
	'identifierSchemeCodeUnspsc190501.51162617': __('identifierSchemeCodeUnspsc190501.51162617'),
	'identifierSchemeCodeUnspsc190501.51162618': __('identifierSchemeCodeUnspsc190501.51162618'),
	'identifierSchemeCodeUnspsc190501.51162619': __('identifierSchemeCodeUnspsc190501.51162619'),
	'identifierSchemeCodeUnspsc190501.51162620': __('identifierSchemeCodeUnspsc190501.51162620'),
	'identifierSchemeCodeUnspsc190501.51162621': __('identifierSchemeCodeUnspsc190501.51162621'),
	'identifierSchemeCodeUnspsc190501.51162622': __('identifierSchemeCodeUnspsc190501.51162622'),
	'identifierSchemeCodeUnspsc190501.51162623': __('identifierSchemeCodeUnspsc190501.51162623'),
	'identifierSchemeCodeUnspsc190501.51162624': __('identifierSchemeCodeUnspsc190501.51162624'),
	'identifierSchemeCodeUnspsc190501.51162625': __('identifierSchemeCodeUnspsc190501.51162625'),
	'identifierSchemeCodeUnspsc190501.51162626': __('identifierSchemeCodeUnspsc190501.51162626'),
	'identifierSchemeCodeUnspsc190501.51162627': __('identifierSchemeCodeUnspsc190501.51162627'),
	'identifierSchemeCodeUnspsc190501.51162628': __('identifierSchemeCodeUnspsc190501.51162628'),
	'identifierSchemeCodeUnspsc190501.51162629': __('identifierSchemeCodeUnspsc190501.51162629'),
	'identifierSchemeCodeUnspsc190501.51162630': __('identifierSchemeCodeUnspsc190501.51162630'),
	'identifierSchemeCodeUnspsc190501.51162631': __('identifierSchemeCodeUnspsc190501.51162631'),
	'identifierSchemeCodeUnspsc190501.51162632': __('identifierSchemeCodeUnspsc190501.51162632'),
	'identifierSchemeCodeUnspsc190501.51162633': __('identifierSchemeCodeUnspsc190501.51162633'),
	'identifierSchemeCodeUnspsc190501.51162634': __('identifierSchemeCodeUnspsc190501.51162634'),
	'identifierSchemeCodeUnspsc190501.51162635': __('identifierSchemeCodeUnspsc190501.51162635'),
	'identifierSchemeCodeUnspsc190501.51162636': __('identifierSchemeCodeUnspsc190501.51162636'),
	'identifierSchemeCodeUnspsc190501.51162637': __('identifierSchemeCodeUnspsc190501.51162637'),
	'identifierSchemeCodeUnspsc190501.51162638': __('identifierSchemeCodeUnspsc190501.51162638'),
	'identifierSchemeCodeUnspsc190501.51162639': __('identifierSchemeCodeUnspsc190501.51162639'),
	'identifierSchemeCodeUnspsc190501.51162640': __('identifierSchemeCodeUnspsc190501.51162640'),
	'identifierSchemeCodeUnspsc190501.51162641': __('identifierSchemeCodeUnspsc190501.51162641'),
	'identifierSchemeCodeUnspsc190501.51162642': __('identifierSchemeCodeUnspsc190501.51162642'),
	'identifierSchemeCodeUnspsc190501.51162643': __('identifierSchemeCodeUnspsc190501.51162643'),
	'identifierSchemeCodeUnspsc190501.51162644': __('identifierSchemeCodeUnspsc190501.51162644'),
	'identifierSchemeCodeUnspsc190501.51162645': __('identifierSchemeCodeUnspsc190501.51162645'),
	'identifierSchemeCodeUnspsc190501.51162646': __('identifierSchemeCodeUnspsc190501.51162646'),
	'identifierSchemeCodeUnspsc190501.51162647': __('identifierSchemeCodeUnspsc190501.51162647'),
	'identifierSchemeCodeUnspsc190501.51162648': __('identifierSchemeCodeUnspsc190501.51162648'),
	'identifierSchemeCodeUnspsc190501.51162649': __('identifierSchemeCodeUnspsc190501.51162649'),
	'identifierSchemeCodeUnspsc190501.51162650': __('identifierSchemeCodeUnspsc190501.51162650'),
	'identifierSchemeCodeUnspsc190501.51162651': __('identifierSchemeCodeUnspsc190501.51162651'),
	'identifierSchemeCodeUnspsc190501.51162652': __('identifierSchemeCodeUnspsc190501.51162652'),
	'identifierSchemeCodeUnspsc190501.51162653': __('identifierSchemeCodeUnspsc190501.51162653'),
	'identifierSchemeCodeUnspsc190501.51162654': __('identifierSchemeCodeUnspsc190501.51162654'),
	'identifierSchemeCodeUnspsc190501.51162655': __('identifierSchemeCodeUnspsc190501.51162655'),
	'identifierSchemeCodeUnspsc190501.51162700': __('identifierSchemeCodeUnspsc190501.51162700'),
	'identifierSchemeCodeUnspsc190501.51162701': __('identifierSchemeCodeUnspsc190501.51162701'),
	'identifierSchemeCodeUnspsc190501.51162702': __('identifierSchemeCodeUnspsc190501.51162702'),
	'identifierSchemeCodeUnspsc190501.51162703': __('identifierSchemeCodeUnspsc190501.51162703'),
	'identifierSchemeCodeUnspsc190501.51162704': __('identifierSchemeCodeUnspsc190501.51162704'),
	'identifierSchemeCodeUnspsc190501.51162705': __('identifierSchemeCodeUnspsc190501.51162705'),
	'identifierSchemeCodeUnspsc190501.51162706': __('identifierSchemeCodeUnspsc190501.51162706'),
	'identifierSchemeCodeUnspsc190501.51162707': __('identifierSchemeCodeUnspsc190501.51162707'),
	'identifierSchemeCodeUnspsc190501.51162708': __('identifierSchemeCodeUnspsc190501.51162708'),
	'identifierSchemeCodeUnspsc190501.51162709': __('identifierSchemeCodeUnspsc190501.51162709'),
	'identifierSchemeCodeUnspsc190501.51162710': __('identifierSchemeCodeUnspsc190501.51162710'),
	'identifierSchemeCodeUnspsc190501.51162711': __('identifierSchemeCodeUnspsc190501.51162711'),
	'identifierSchemeCodeUnspsc190501.51162712': __('identifierSchemeCodeUnspsc190501.51162712'),
	'identifierSchemeCodeUnspsc190501.51162713': __('identifierSchemeCodeUnspsc190501.51162713'),
	'identifierSchemeCodeUnspsc190501.51162714': __('identifierSchemeCodeUnspsc190501.51162714'),
	'identifierSchemeCodeUnspsc190501.51162715': __('identifierSchemeCodeUnspsc190501.51162715'),
	'identifierSchemeCodeUnspsc190501.51162716': __('identifierSchemeCodeUnspsc190501.51162716'),
	'identifierSchemeCodeUnspsc190501.51162717': __('identifierSchemeCodeUnspsc190501.51162717'),
	'identifierSchemeCodeUnspsc190501.51162718': __('identifierSchemeCodeUnspsc190501.51162718'),
	'identifierSchemeCodeUnspsc190501.51162719': __('identifierSchemeCodeUnspsc190501.51162719'),
	'identifierSchemeCodeUnspsc190501.51162720': __('identifierSchemeCodeUnspsc190501.51162720'),
	'identifierSchemeCodeUnspsc190501.51162721': __('identifierSchemeCodeUnspsc190501.51162721'),
	'identifierSchemeCodeUnspsc190501.51162722': __('identifierSchemeCodeUnspsc190501.51162722'),
	'identifierSchemeCodeUnspsc190501.51162723': __('identifierSchemeCodeUnspsc190501.51162723'),
	'identifierSchemeCodeUnspsc190501.51162724': __('identifierSchemeCodeUnspsc190501.51162724'),
	'identifierSchemeCodeUnspsc190501.51162725': __('identifierSchemeCodeUnspsc190501.51162725'),
	'identifierSchemeCodeUnspsc190501.51162726': __('identifierSchemeCodeUnspsc190501.51162726'),
	'identifierSchemeCodeUnspsc190501.51162727': __('identifierSchemeCodeUnspsc190501.51162727'),
	'identifierSchemeCodeUnspsc190501.51162728': __('identifierSchemeCodeUnspsc190501.51162728'),
	'identifierSchemeCodeUnspsc190501.51162729': __('identifierSchemeCodeUnspsc190501.51162729'),
	'identifierSchemeCodeUnspsc190501.51162730': __('identifierSchemeCodeUnspsc190501.51162730'),
	'identifierSchemeCodeUnspsc190501.51162731': __('identifierSchemeCodeUnspsc190501.51162731'),
	'identifierSchemeCodeUnspsc190501.51162732': __('identifierSchemeCodeUnspsc190501.51162732'),
	'identifierSchemeCodeUnspsc190501.51162733': __('identifierSchemeCodeUnspsc190501.51162733'),
	'identifierSchemeCodeUnspsc190501.51162734': __('identifierSchemeCodeUnspsc190501.51162734'),
	'identifierSchemeCodeUnspsc190501.51162735': __('identifierSchemeCodeUnspsc190501.51162735'),
	'identifierSchemeCodeUnspsc190501.51162736': __('identifierSchemeCodeUnspsc190501.51162736'),
	'identifierSchemeCodeUnspsc190501.51162737': __('identifierSchemeCodeUnspsc190501.51162737'),
	'identifierSchemeCodeUnspsc190501.51162738': __('identifierSchemeCodeUnspsc190501.51162738'),
	'identifierSchemeCodeUnspsc190501.51162739': __('identifierSchemeCodeUnspsc190501.51162739'),
	'identifierSchemeCodeUnspsc190501.51162740': __('identifierSchemeCodeUnspsc190501.51162740'),
	'identifierSchemeCodeUnspsc190501.51162741': __('identifierSchemeCodeUnspsc190501.51162741'),
	'identifierSchemeCodeUnspsc190501.51162742': __('identifierSchemeCodeUnspsc190501.51162742'),
	'identifierSchemeCodeUnspsc190501.51162743': __('identifierSchemeCodeUnspsc190501.51162743'),
	'identifierSchemeCodeUnspsc190501.51162744': __('identifierSchemeCodeUnspsc190501.51162744'),
	'identifierSchemeCodeUnspsc190501.51162745': __('identifierSchemeCodeUnspsc190501.51162745'),
	'identifierSchemeCodeUnspsc190501.51162746': __('identifierSchemeCodeUnspsc190501.51162746'),
	'identifierSchemeCodeUnspsc190501.51162747': __('identifierSchemeCodeUnspsc190501.51162747'),
	'identifierSchemeCodeUnspsc190501.51170000': __('identifierSchemeCodeUnspsc190501.51170000'),
	'identifierSchemeCodeUnspsc190501.51171500': __('identifierSchemeCodeUnspsc190501.51171500'),
	'identifierSchemeCodeUnspsc190501.51171501': __('identifierSchemeCodeUnspsc190501.51171501'),
	'identifierSchemeCodeUnspsc190501.51171502': __('identifierSchemeCodeUnspsc190501.51171502'),
	'identifierSchemeCodeUnspsc190501.51171505': __('identifierSchemeCodeUnspsc190501.51171505'),
	'identifierSchemeCodeUnspsc190501.51171507': __('identifierSchemeCodeUnspsc190501.51171507'),
	'identifierSchemeCodeUnspsc190501.51171508': __('identifierSchemeCodeUnspsc190501.51171508'),
	'identifierSchemeCodeUnspsc190501.51171510': __('identifierSchemeCodeUnspsc190501.51171510'),
	'identifierSchemeCodeUnspsc190501.51171511': __('identifierSchemeCodeUnspsc190501.51171511'),
	'identifierSchemeCodeUnspsc190501.51171513': __('identifierSchemeCodeUnspsc190501.51171513'),
	'identifierSchemeCodeUnspsc190501.51171514': __('identifierSchemeCodeUnspsc190501.51171514'),
	'identifierSchemeCodeUnspsc190501.51171515': __('identifierSchemeCodeUnspsc190501.51171515'),
	'identifierSchemeCodeUnspsc190501.51171516': __('identifierSchemeCodeUnspsc190501.51171516'),
	'identifierSchemeCodeUnspsc190501.51171517': __('identifierSchemeCodeUnspsc190501.51171517'),
	'identifierSchemeCodeUnspsc190501.51171518': __('identifierSchemeCodeUnspsc190501.51171518'),
	'identifierSchemeCodeUnspsc190501.51171519': __('identifierSchemeCodeUnspsc190501.51171519'),
	'identifierSchemeCodeUnspsc190501.51171520': __('identifierSchemeCodeUnspsc190501.51171520'),
	'identifierSchemeCodeUnspsc190501.51171521': __('identifierSchemeCodeUnspsc190501.51171521'),
	'identifierSchemeCodeUnspsc190501.51171522': __('identifierSchemeCodeUnspsc190501.51171522'),
	'identifierSchemeCodeUnspsc190501.51171523': __('identifierSchemeCodeUnspsc190501.51171523'),
	'identifierSchemeCodeUnspsc190501.51171524': __('identifierSchemeCodeUnspsc190501.51171524'),
	'identifierSchemeCodeUnspsc190501.51171525': __('identifierSchemeCodeUnspsc190501.51171525'),
	'identifierSchemeCodeUnspsc190501.51171526': __('identifierSchemeCodeUnspsc190501.51171526'),
	'identifierSchemeCodeUnspsc190501.51171527': __('identifierSchemeCodeUnspsc190501.51171527'),
	'identifierSchemeCodeUnspsc190501.51171528': __('identifierSchemeCodeUnspsc190501.51171528'),
	'identifierSchemeCodeUnspsc190501.51171529': __('identifierSchemeCodeUnspsc190501.51171529'),
	'identifierSchemeCodeUnspsc190501.51171530': __('identifierSchemeCodeUnspsc190501.51171530'),
	'identifierSchemeCodeUnspsc190501.51171531': __('identifierSchemeCodeUnspsc190501.51171531'),
	'identifierSchemeCodeUnspsc190501.51171600': __('identifierSchemeCodeUnspsc190501.51171600'),
	'identifierSchemeCodeUnspsc190501.51171602': __('identifierSchemeCodeUnspsc190501.51171602'),
	'identifierSchemeCodeUnspsc190501.51171603': __('identifierSchemeCodeUnspsc190501.51171603'),
	'identifierSchemeCodeUnspsc190501.51171605': __('identifierSchemeCodeUnspsc190501.51171605'),
	'identifierSchemeCodeUnspsc190501.51171607': __('identifierSchemeCodeUnspsc190501.51171607'),
	'identifierSchemeCodeUnspsc190501.51171608': __('identifierSchemeCodeUnspsc190501.51171608'),
	'identifierSchemeCodeUnspsc190501.51171610': __('identifierSchemeCodeUnspsc190501.51171610'),
	'identifierSchemeCodeUnspsc190501.51171613': __('identifierSchemeCodeUnspsc190501.51171613'),
	'identifierSchemeCodeUnspsc190501.51171614': __('identifierSchemeCodeUnspsc190501.51171614'),
	'identifierSchemeCodeUnspsc190501.51171615': __('identifierSchemeCodeUnspsc190501.51171615'),
	'identifierSchemeCodeUnspsc190501.51171616': __('identifierSchemeCodeUnspsc190501.51171616'),
	'identifierSchemeCodeUnspsc190501.51171617': __('identifierSchemeCodeUnspsc190501.51171617'),
	'identifierSchemeCodeUnspsc190501.51171622': __('identifierSchemeCodeUnspsc190501.51171622'),
	'identifierSchemeCodeUnspsc190501.51171623': __('identifierSchemeCodeUnspsc190501.51171623'),
	'identifierSchemeCodeUnspsc190501.51171627': __('identifierSchemeCodeUnspsc190501.51171627'),
	'identifierSchemeCodeUnspsc190501.51171628': __('identifierSchemeCodeUnspsc190501.51171628'),
	'identifierSchemeCodeUnspsc190501.51171629': __('identifierSchemeCodeUnspsc190501.51171629'),
	'identifierSchemeCodeUnspsc190501.51171631': __('identifierSchemeCodeUnspsc190501.51171631'),
	'identifierSchemeCodeUnspsc190501.51171633': __('identifierSchemeCodeUnspsc190501.51171633'),
	'identifierSchemeCodeUnspsc190501.51171634': __('identifierSchemeCodeUnspsc190501.51171634'),
	'identifierSchemeCodeUnspsc190501.51171635': __('identifierSchemeCodeUnspsc190501.51171635'),
	'identifierSchemeCodeUnspsc190501.51171636': __('identifierSchemeCodeUnspsc190501.51171636'),
	'identifierSchemeCodeUnspsc190501.51171637': __('identifierSchemeCodeUnspsc190501.51171637'),
	'identifierSchemeCodeUnspsc190501.51171638': __('identifierSchemeCodeUnspsc190501.51171638'),
	'identifierSchemeCodeUnspsc190501.51171639': __('identifierSchemeCodeUnspsc190501.51171639'),
	'identifierSchemeCodeUnspsc190501.51171640': __('identifierSchemeCodeUnspsc190501.51171640'),
	'identifierSchemeCodeUnspsc190501.51171641': __('identifierSchemeCodeUnspsc190501.51171641'),
	'identifierSchemeCodeUnspsc190501.51171642': __('identifierSchemeCodeUnspsc190501.51171642'),
	'identifierSchemeCodeUnspsc190501.51171643': __('identifierSchemeCodeUnspsc190501.51171643'),
	'identifierSchemeCodeUnspsc190501.51171644': __('identifierSchemeCodeUnspsc190501.51171644'),
	'identifierSchemeCodeUnspsc190501.51171645': __('identifierSchemeCodeUnspsc190501.51171645'),
	'identifierSchemeCodeUnspsc190501.51171646': __('identifierSchemeCodeUnspsc190501.51171646'),
	'identifierSchemeCodeUnspsc190501.51171647': __('identifierSchemeCodeUnspsc190501.51171647'),
	'identifierSchemeCodeUnspsc190501.51171648': __('identifierSchemeCodeUnspsc190501.51171648'),
	'identifierSchemeCodeUnspsc190501.51171649': __('identifierSchemeCodeUnspsc190501.51171649'),
	'identifierSchemeCodeUnspsc190501.51171650': __('identifierSchemeCodeUnspsc190501.51171650'),
	'identifierSchemeCodeUnspsc190501.51171651': __('identifierSchemeCodeUnspsc190501.51171651'),
	'identifierSchemeCodeUnspsc190501.51171652': __('identifierSchemeCodeUnspsc190501.51171652'),
	'identifierSchemeCodeUnspsc190501.51171653': __('identifierSchemeCodeUnspsc190501.51171653'),
	'identifierSchemeCodeUnspsc190501.51171654': __('identifierSchemeCodeUnspsc190501.51171654'),
	'identifierSchemeCodeUnspsc190501.51171655': __('identifierSchemeCodeUnspsc190501.51171655'),
	'identifierSchemeCodeUnspsc190501.51171656': __('identifierSchemeCodeUnspsc190501.51171656'),
	'identifierSchemeCodeUnspsc190501.51171700': __('identifierSchemeCodeUnspsc190501.51171700'),
	'identifierSchemeCodeUnspsc190501.51171701': __('identifierSchemeCodeUnspsc190501.51171701'),
	'identifierSchemeCodeUnspsc190501.51171702': __('identifierSchemeCodeUnspsc190501.51171702'),
	'identifierSchemeCodeUnspsc190501.51171703': __('identifierSchemeCodeUnspsc190501.51171703'),
	'identifierSchemeCodeUnspsc190501.51171704': __('identifierSchemeCodeUnspsc190501.51171704'),
	'identifierSchemeCodeUnspsc190501.51171708': __('identifierSchemeCodeUnspsc190501.51171708'),
	'identifierSchemeCodeUnspsc190501.51171709': __('identifierSchemeCodeUnspsc190501.51171709'),
	'identifierSchemeCodeUnspsc190501.51171710': __('identifierSchemeCodeUnspsc190501.51171710'),
	'identifierSchemeCodeUnspsc190501.51171712': __('identifierSchemeCodeUnspsc190501.51171712'),
	'identifierSchemeCodeUnspsc190501.51171713': __('identifierSchemeCodeUnspsc190501.51171713'),
	'identifierSchemeCodeUnspsc190501.51171714': __('identifierSchemeCodeUnspsc190501.51171714'),
	'identifierSchemeCodeUnspsc190501.51171715': __('identifierSchemeCodeUnspsc190501.51171715'),
	'identifierSchemeCodeUnspsc190501.51171716': __('identifierSchemeCodeUnspsc190501.51171716'),
	'identifierSchemeCodeUnspsc190501.51171717': __('identifierSchemeCodeUnspsc190501.51171717'),
	'identifierSchemeCodeUnspsc190501.51171718': __('identifierSchemeCodeUnspsc190501.51171718'),
	'identifierSchemeCodeUnspsc190501.51171719': __('identifierSchemeCodeUnspsc190501.51171719'),
	'identifierSchemeCodeUnspsc190501.51171720': __('identifierSchemeCodeUnspsc190501.51171720'),
	'identifierSchemeCodeUnspsc190501.51171721': __('identifierSchemeCodeUnspsc190501.51171721'),
	'identifierSchemeCodeUnspsc190501.51171722': __('identifierSchemeCodeUnspsc190501.51171722'),
	'identifierSchemeCodeUnspsc190501.51171723': __('identifierSchemeCodeUnspsc190501.51171723'),
	'identifierSchemeCodeUnspsc190501.51171724': __('identifierSchemeCodeUnspsc190501.51171724'),
	'identifierSchemeCodeUnspsc190501.51171725': __('identifierSchemeCodeUnspsc190501.51171725'),
	'identifierSchemeCodeUnspsc190501.51171726': __('identifierSchemeCodeUnspsc190501.51171726'),
	'identifierSchemeCodeUnspsc190501.51171800': __('identifierSchemeCodeUnspsc190501.51171800'),
	'identifierSchemeCodeUnspsc190501.51171805': __('identifierSchemeCodeUnspsc190501.51171805'),
	'identifierSchemeCodeUnspsc190501.51171806': __('identifierSchemeCodeUnspsc190501.51171806'),
	'identifierSchemeCodeUnspsc190501.51171807': __('identifierSchemeCodeUnspsc190501.51171807'),
	'identifierSchemeCodeUnspsc190501.51171808': __('identifierSchemeCodeUnspsc190501.51171808'),
	'identifierSchemeCodeUnspsc190501.51171809': __('identifierSchemeCodeUnspsc190501.51171809'),
	'identifierSchemeCodeUnspsc190501.51171811': __('identifierSchemeCodeUnspsc190501.51171811'),
	'identifierSchemeCodeUnspsc190501.51171812': __('identifierSchemeCodeUnspsc190501.51171812'),
	'identifierSchemeCodeUnspsc190501.51171813': __('identifierSchemeCodeUnspsc190501.51171813'),
	'identifierSchemeCodeUnspsc190501.51171814': __('identifierSchemeCodeUnspsc190501.51171814'),
	'identifierSchemeCodeUnspsc190501.51171816': __('identifierSchemeCodeUnspsc190501.51171816'),
	'identifierSchemeCodeUnspsc190501.51171819': __('identifierSchemeCodeUnspsc190501.51171819'),
	'identifierSchemeCodeUnspsc190501.51171820': __('identifierSchemeCodeUnspsc190501.51171820'),
	'identifierSchemeCodeUnspsc190501.51171822': __('identifierSchemeCodeUnspsc190501.51171822'),
	'identifierSchemeCodeUnspsc190501.51171823': __('identifierSchemeCodeUnspsc190501.51171823'),
	'identifierSchemeCodeUnspsc190501.51171824': __('identifierSchemeCodeUnspsc190501.51171824'),
	'identifierSchemeCodeUnspsc190501.51171825': __('identifierSchemeCodeUnspsc190501.51171825'),
	'identifierSchemeCodeUnspsc190501.51171826': __('identifierSchemeCodeUnspsc190501.51171826'),
	'identifierSchemeCodeUnspsc190501.51171827': __('identifierSchemeCodeUnspsc190501.51171827'),
	'identifierSchemeCodeUnspsc190501.51171828': __('identifierSchemeCodeUnspsc190501.51171828'),
	'identifierSchemeCodeUnspsc190501.51171829': __('identifierSchemeCodeUnspsc190501.51171829'),
	'identifierSchemeCodeUnspsc190501.51171830': __('identifierSchemeCodeUnspsc190501.51171830'),
	'identifierSchemeCodeUnspsc190501.51171831': __('identifierSchemeCodeUnspsc190501.51171831'),
	'identifierSchemeCodeUnspsc190501.51171832': __('identifierSchemeCodeUnspsc190501.51171832'),
	'identifierSchemeCodeUnspsc190501.51171833': __('identifierSchemeCodeUnspsc190501.51171833'),
	'identifierSchemeCodeUnspsc190501.51171834': __('identifierSchemeCodeUnspsc190501.51171834'),
	'identifierSchemeCodeUnspsc190501.51171835': __('identifierSchemeCodeUnspsc190501.51171835'),
	'identifierSchemeCodeUnspsc190501.51171836': __('identifierSchemeCodeUnspsc190501.51171836'),
	'identifierSchemeCodeUnspsc190501.51171837': __('identifierSchemeCodeUnspsc190501.51171837'),
	'identifierSchemeCodeUnspsc190501.51171838': __('identifierSchemeCodeUnspsc190501.51171838'),
	'identifierSchemeCodeUnspsc190501.51171839': __('identifierSchemeCodeUnspsc190501.51171839'),
	'identifierSchemeCodeUnspsc190501.51171840': __('identifierSchemeCodeUnspsc190501.51171840'),
	'identifierSchemeCodeUnspsc190501.51171841': __('identifierSchemeCodeUnspsc190501.51171841'),
	'identifierSchemeCodeUnspsc190501.51171842': __('identifierSchemeCodeUnspsc190501.51171842'),
	'identifierSchemeCodeUnspsc190501.51171843': __('identifierSchemeCodeUnspsc190501.51171843'),
	'identifierSchemeCodeUnspsc190501.51171844': __('identifierSchemeCodeUnspsc190501.51171844'),
	'identifierSchemeCodeUnspsc190501.51171845': __('identifierSchemeCodeUnspsc190501.51171845'),
	'identifierSchemeCodeUnspsc190501.51171846': __('identifierSchemeCodeUnspsc190501.51171846'),
	'identifierSchemeCodeUnspsc190501.51171847': __('identifierSchemeCodeUnspsc190501.51171847'),
	'identifierSchemeCodeUnspsc190501.51171848': __('identifierSchemeCodeUnspsc190501.51171848'),
	'identifierSchemeCodeUnspsc190501.51171849': __('identifierSchemeCodeUnspsc190501.51171849'),
	'identifierSchemeCodeUnspsc190501.51171850': __('identifierSchemeCodeUnspsc190501.51171850'),
	'identifierSchemeCodeUnspsc190501.51171851': __('identifierSchemeCodeUnspsc190501.51171851'),
	'identifierSchemeCodeUnspsc190501.51171852': __('identifierSchemeCodeUnspsc190501.51171852'),
	'identifierSchemeCodeUnspsc190501.51171853': __('identifierSchemeCodeUnspsc190501.51171853'),
	'identifierSchemeCodeUnspsc190501.51171854': __('identifierSchemeCodeUnspsc190501.51171854'),
	'identifierSchemeCodeUnspsc190501.51171855': __('identifierSchemeCodeUnspsc190501.51171855'),
	'identifierSchemeCodeUnspsc190501.51171856': __('identifierSchemeCodeUnspsc190501.51171856'),
	'identifierSchemeCodeUnspsc190501.51171857': __('identifierSchemeCodeUnspsc190501.51171857'),
	'identifierSchemeCodeUnspsc190501.51171900': __('identifierSchemeCodeUnspsc190501.51171900'),
	'identifierSchemeCodeUnspsc190501.51171901': __('identifierSchemeCodeUnspsc190501.51171901'),
	'identifierSchemeCodeUnspsc190501.51171902': __('identifierSchemeCodeUnspsc190501.51171902'),
	'identifierSchemeCodeUnspsc190501.51171903': __('identifierSchemeCodeUnspsc190501.51171903'),
	'identifierSchemeCodeUnspsc190501.51171905': __('identifierSchemeCodeUnspsc190501.51171905'),
	'identifierSchemeCodeUnspsc190501.51171906': __('identifierSchemeCodeUnspsc190501.51171906'),
	'identifierSchemeCodeUnspsc190501.51171909': __('identifierSchemeCodeUnspsc190501.51171909'),
	'identifierSchemeCodeUnspsc190501.51171910': __('identifierSchemeCodeUnspsc190501.51171910'),
	'identifierSchemeCodeUnspsc190501.51171911': __('identifierSchemeCodeUnspsc190501.51171911'),
	'identifierSchemeCodeUnspsc190501.51171915': __('identifierSchemeCodeUnspsc190501.51171915'),
	'identifierSchemeCodeUnspsc190501.51171916': __('identifierSchemeCodeUnspsc190501.51171916'),
	'identifierSchemeCodeUnspsc190501.51171917': __('identifierSchemeCodeUnspsc190501.51171917'),
	'identifierSchemeCodeUnspsc190501.51171922': __('identifierSchemeCodeUnspsc190501.51171922'),
	'identifierSchemeCodeUnspsc190501.51171925': __('identifierSchemeCodeUnspsc190501.51171925'),
	'identifierSchemeCodeUnspsc190501.51171926': __('identifierSchemeCodeUnspsc190501.51171926'),
	'identifierSchemeCodeUnspsc190501.51171927': __('identifierSchemeCodeUnspsc190501.51171927'),
	'identifierSchemeCodeUnspsc190501.51171928': __('identifierSchemeCodeUnspsc190501.51171928'),
	'identifierSchemeCodeUnspsc190501.51171929': __('identifierSchemeCodeUnspsc190501.51171929'),
	'identifierSchemeCodeUnspsc190501.51171930': __('identifierSchemeCodeUnspsc190501.51171930'),
	'identifierSchemeCodeUnspsc190501.51171931': __('identifierSchemeCodeUnspsc190501.51171931'),
	'identifierSchemeCodeUnspsc190501.51171932': __('identifierSchemeCodeUnspsc190501.51171932'),
	'identifierSchemeCodeUnspsc190501.51171933': __('identifierSchemeCodeUnspsc190501.51171933'),
	'identifierSchemeCodeUnspsc190501.51171934': __('identifierSchemeCodeUnspsc190501.51171934'),
	'identifierSchemeCodeUnspsc190501.51171935': __('identifierSchemeCodeUnspsc190501.51171935'),
	'identifierSchemeCodeUnspsc190501.51171936': __('identifierSchemeCodeUnspsc190501.51171936'),
	'identifierSchemeCodeUnspsc190501.51171937': __('identifierSchemeCodeUnspsc190501.51171937'),
	'identifierSchemeCodeUnspsc190501.51171938': __('identifierSchemeCodeUnspsc190501.51171938'),
	'identifierSchemeCodeUnspsc190501.51171939': __('identifierSchemeCodeUnspsc190501.51171939'),
	'identifierSchemeCodeUnspsc190501.51171940': __('identifierSchemeCodeUnspsc190501.51171940'),
	'identifierSchemeCodeUnspsc190501.51171941': __('identifierSchemeCodeUnspsc190501.51171941'),
	'identifierSchemeCodeUnspsc190501.51171942': __('identifierSchemeCodeUnspsc190501.51171942'),
	'identifierSchemeCodeUnspsc190501.51171943': __('identifierSchemeCodeUnspsc190501.51171943'),
	'identifierSchemeCodeUnspsc190501.51171944': __('identifierSchemeCodeUnspsc190501.51171944'),
	'identifierSchemeCodeUnspsc190501.51171945': __('identifierSchemeCodeUnspsc190501.51171945'),
	'identifierSchemeCodeUnspsc190501.51171946': __('identifierSchemeCodeUnspsc190501.51171946'),
	'identifierSchemeCodeUnspsc190501.51171947': __('identifierSchemeCodeUnspsc190501.51171947'),
	'identifierSchemeCodeUnspsc190501.51171948': __('identifierSchemeCodeUnspsc190501.51171948'),
	'identifierSchemeCodeUnspsc190501.51171949': __('identifierSchemeCodeUnspsc190501.51171949'),
	'identifierSchemeCodeUnspsc190501.51171950': __('identifierSchemeCodeUnspsc190501.51171950'),
	'identifierSchemeCodeUnspsc190501.51171951': __('identifierSchemeCodeUnspsc190501.51171951'),
	'identifierSchemeCodeUnspsc190501.51171952': __('identifierSchemeCodeUnspsc190501.51171952'),
	'identifierSchemeCodeUnspsc190501.51171953': __('identifierSchemeCodeUnspsc190501.51171953'),
	'identifierSchemeCodeUnspsc190501.51171954': __('identifierSchemeCodeUnspsc190501.51171954'),
	'identifierSchemeCodeUnspsc190501.51171955': __('identifierSchemeCodeUnspsc190501.51171955'),
	'identifierSchemeCodeUnspsc190501.51171956': __('identifierSchemeCodeUnspsc190501.51171956'),
	'identifierSchemeCodeUnspsc190501.51171957': __('identifierSchemeCodeUnspsc190501.51171957'),
	'identifierSchemeCodeUnspsc190501.51171958': __('identifierSchemeCodeUnspsc190501.51171958'),
	'identifierSchemeCodeUnspsc190501.51171959': __('identifierSchemeCodeUnspsc190501.51171959'),
	'identifierSchemeCodeUnspsc190501.51171960': __('identifierSchemeCodeUnspsc190501.51171960'),
	'identifierSchemeCodeUnspsc190501.51171961': __('identifierSchemeCodeUnspsc190501.51171961'),
	'identifierSchemeCodeUnspsc190501.51171962': __('identifierSchemeCodeUnspsc190501.51171962'),
	'identifierSchemeCodeUnspsc190501.51171963': __('identifierSchemeCodeUnspsc190501.51171963'),
	'identifierSchemeCodeUnspsc190501.51171964': __('identifierSchemeCodeUnspsc190501.51171964'),
	'identifierSchemeCodeUnspsc190501.51171965': __('identifierSchemeCodeUnspsc190501.51171965'),
	'identifierSchemeCodeUnspsc190501.51171966': __('identifierSchemeCodeUnspsc190501.51171966'),
	'identifierSchemeCodeUnspsc190501.51171967': __('identifierSchemeCodeUnspsc190501.51171967'),
	'identifierSchemeCodeUnspsc190501.51171968': __('identifierSchemeCodeUnspsc190501.51171968'),
	'identifierSchemeCodeUnspsc190501.51171969': __('identifierSchemeCodeUnspsc190501.51171969'),
	'identifierSchemeCodeUnspsc190501.51171970': __('identifierSchemeCodeUnspsc190501.51171970'),
	'identifierSchemeCodeUnspsc190501.51171971': __('identifierSchemeCodeUnspsc190501.51171971'),
	'identifierSchemeCodeUnspsc190501.51171972': __('identifierSchemeCodeUnspsc190501.51171972'),
	'identifierSchemeCodeUnspsc190501.51171973': __('identifierSchemeCodeUnspsc190501.51171973'),
	'identifierSchemeCodeUnspsc190501.51171974': __('identifierSchemeCodeUnspsc190501.51171974'),
	'identifierSchemeCodeUnspsc190501.51171975': __('identifierSchemeCodeUnspsc190501.51171975'),
	'identifierSchemeCodeUnspsc190501.51171976': __('identifierSchemeCodeUnspsc190501.51171976'),
	'identifierSchemeCodeUnspsc190501.51171977': __('identifierSchemeCodeUnspsc190501.51171977'),
	'identifierSchemeCodeUnspsc190501.51171978': __('identifierSchemeCodeUnspsc190501.51171978'),
	'identifierSchemeCodeUnspsc190501.51171979': __('identifierSchemeCodeUnspsc190501.51171979'),
	'identifierSchemeCodeUnspsc190501.51171980': __('identifierSchemeCodeUnspsc190501.51171980'),
	'identifierSchemeCodeUnspsc190501.51171981': __('identifierSchemeCodeUnspsc190501.51171981'),
	'identifierSchemeCodeUnspsc190501.51171982': __('identifierSchemeCodeUnspsc190501.51171982'),
	'identifierSchemeCodeUnspsc190501.51171983': __('identifierSchemeCodeUnspsc190501.51171983'),
	'identifierSchemeCodeUnspsc190501.51172000': __('identifierSchemeCodeUnspsc190501.51172000'),
	'identifierSchemeCodeUnspsc190501.51172001': __('identifierSchemeCodeUnspsc190501.51172001'),
	'identifierSchemeCodeUnspsc190501.51172004': __('identifierSchemeCodeUnspsc190501.51172004'),
	'identifierSchemeCodeUnspsc190501.51172005': __('identifierSchemeCodeUnspsc190501.51172005'),
	'identifierSchemeCodeUnspsc190501.51172006': __('identifierSchemeCodeUnspsc190501.51172006'),
	'identifierSchemeCodeUnspsc190501.51172007': __('identifierSchemeCodeUnspsc190501.51172007'),
	'identifierSchemeCodeUnspsc190501.51172008': __('identifierSchemeCodeUnspsc190501.51172008'),
	'identifierSchemeCodeUnspsc190501.51172009': __('identifierSchemeCodeUnspsc190501.51172009'),
	'identifierSchemeCodeUnspsc190501.51172010': __('identifierSchemeCodeUnspsc190501.51172010'),
	'identifierSchemeCodeUnspsc190501.51172011': __('identifierSchemeCodeUnspsc190501.51172011'),
	'identifierSchemeCodeUnspsc190501.51172012': __('identifierSchemeCodeUnspsc190501.51172012'),
	'identifierSchemeCodeUnspsc190501.51172013': __('identifierSchemeCodeUnspsc190501.51172013'),
	'identifierSchemeCodeUnspsc190501.51172014': __('identifierSchemeCodeUnspsc190501.51172014'),
	'identifierSchemeCodeUnspsc190501.51172015': __('identifierSchemeCodeUnspsc190501.51172015'),
	'identifierSchemeCodeUnspsc190501.51172016': __('identifierSchemeCodeUnspsc190501.51172016'),
	'identifierSchemeCodeUnspsc190501.51172017': __('identifierSchemeCodeUnspsc190501.51172017'),
	'identifierSchemeCodeUnspsc190501.51172018': __('identifierSchemeCodeUnspsc190501.51172018'),
	'identifierSchemeCodeUnspsc190501.51172019': __('identifierSchemeCodeUnspsc190501.51172019'),
	'identifierSchemeCodeUnspsc190501.51172100': __('identifierSchemeCodeUnspsc190501.51172100'),
	'identifierSchemeCodeUnspsc190501.51172103': __('identifierSchemeCodeUnspsc190501.51172103'),
	'identifierSchemeCodeUnspsc190501.51172105': __('identifierSchemeCodeUnspsc190501.51172105'),
	'identifierSchemeCodeUnspsc190501.51172108': __('identifierSchemeCodeUnspsc190501.51172108'),
	'identifierSchemeCodeUnspsc190501.51172110': __('identifierSchemeCodeUnspsc190501.51172110'),
	'identifierSchemeCodeUnspsc190501.51172111': __('identifierSchemeCodeUnspsc190501.51172111'),
	'identifierSchemeCodeUnspsc190501.51172112': __('identifierSchemeCodeUnspsc190501.51172112'),
	'identifierSchemeCodeUnspsc190501.51172113': __('identifierSchemeCodeUnspsc190501.51172113'),
	'identifierSchemeCodeUnspsc190501.51172114': __('identifierSchemeCodeUnspsc190501.51172114'),
	'identifierSchemeCodeUnspsc190501.51172115': __('identifierSchemeCodeUnspsc190501.51172115'),
	'identifierSchemeCodeUnspsc190501.51172116': __('identifierSchemeCodeUnspsc190501.51172116'),
	'identifierSchemeCodeUnspsc190501.51172117': __('identifierSchemeCodeUnspsc190501.51172117'),
	'identifierSchemeCodeUnspsc190501.51172118': __('identifierSchemeCodeUnspsc190501.51172118'),
	'identifierSchemeCodeUnspsc190501.51172119': __('identifierSchemeCodeUnspsc190501.51172119'),
	'identifierSchemeCodeUnspsc190501.51172120': __('identifierSchemeCodeUnspsc190501.51172120'),
	'identifierSchemeCodeUnspsc190501.51172121': __('identifierSchemeCodeUnspsc190501.51172121'),
	'identifierSchemeCodeUnspsc190501.51172122': __('identifierSchemeCodeUnspsc190501.51172122'),
	'identifierSchemeCodeUnspsc190501.51172123': __('identifierSchemeCodeUnspsc190501.51172123'),
	'identifierSchemeCodeUnspsc190501.51172124': __('identifierSchemeCodeUnspsc190501.51172124'),
	'identifierSchemeCodeUnspsc190501.51172125': __('identifierSchemeCodeUnspsc190501.51172125'),
	'identifierSchemeCodeUnspsc190501.51172126': __('identifierSchemeCodeUnspsc190501.51172126'),
	'identifierSchemeCodeUnspsc190501.51172127': __('identifierSchemeCodeUnspsc190501.51172127'),
	'identifierSchemeCodeUnspsc190501.51172128': __('identifierSchemeCodeUnspsc190501.51172128'),
	'identifierSchemeCodeUnspsc190501.51172129': __('identifierSchemeCodeUnspsc190501.51172129'),
	'identifierSchemeCodeUnspsc190501.51172130': __('identifierSchemeCodeUnspsc190501.51172130'),
	'identifierSchemeCodeUnspsc190501.51172131': __('identifierSchemeCodeUnspsc190501.51172131'),
	'identifierSchemeCodeUnspsc190501.51172132': __('identifierSchemeCodeUnspsc190501.51172132'),
	'identifierSchemeCodeUnspsc190501.51172133': __('identifierSchemeCodeUnspsc190501.51172133'),
	'identifierSchemeCodeUnspsc190501.51172134': __('identifierSchemeCodeUnspsc190501.51172134'),
	'identifierSchemeCodeUnspsc190501.51172135': __('identifierSchemeCodeUnspsc190501.51172135'),
	'identifierSchemeCodeUnspsc190501.51172136': __('identifierSchemeCodeUnspsc190501.51172136'),
	'identifierSchemeCodeUnspsc190501.51172137': __('identifierSchemeCodeUnspsc190501.51172137'),
	'identifierSchemeCodeUnspsc190501.51172138': __('identifierSchemeCodeUnspsc190501.51172138'),
	'identifierSchemeCodeUnspsc190501.51172139': __('identifierSchemeCodeUnspsc190501.51172139'),
	'identifierSchemeCodeUnspsc190501.51172140': __('identifierSchemeCodeUnspsc190501.51172140'),
	'identifierSchemeCodeUnspsc190501.51172141': __('identifierSchemeCodeUnspsc190501.51172141'),
	'identifierSchemeCodeUnspsc190501.51172142': __('identifierSchemeCodeUnspsc190501.51172142'),
	'identifierSchemeCodeUnspsc190501.51172143': __('identifierSchemeCodeUnspsc190501.51172143'),
	'identifierSchemeCodeUnspsc190501.51172144': __('identifierSchemeCodeUnspsc190501.51172144'),
	'identifierSchemeCodeUnspsc190501.51172145': __('identifierSchemeCodeUnspsc190501.51172145'),
	'identifierSchemeCodeUnspsc190501.51172146': __('identifierSchemeCodeUnspsc190501.51172146'),
	'identifierSchemeCodeUnspsc190501.51172147': __('identifierSchemeCodeUnspsc190501.51172147'),
	'identifierSchemeCodeUnspsc190501.51172148': __('identifierSchemeCodeUnspsc190501.51172148'),
	'identifierSchemeCodeUnspsc190501.51172149': __('identifierSchemeCodeUnspsc190501.51172149'),
	'identifierSchemeCodeUnspsc190501.51172150': __('identifierSchemeCodeUnspsc190501.51172150'),
	'identifierSchemeCodeUnspsc190501.51172151': __('identifierSchemeCodeUnspsc190501.51172151'),
	'identifierSchemeCodeUnspsc190501.51172152': __('identifierSchemeCodeUnspsc190501.51172152'),
	'identifierSchemeCodeUnspsc190501.51172153': __('identifierSchemeCodeUnspsc190501.51172153'),
	'identifierSchemeCodeUnspsc190501.51172154': __('identifierSchemeCodeUnspsc190501.51172154'),
	'identifierSchemeCodeUnspsc190501.51172155': __('identifierSchemeCodeUnspsc190501.51172155'),
	'identifierSchemeCodeUnspsc190501.51172156': __('identifierSchemeCodeUnspsc190501.51172156'),
	'identifierSchemeCodeUnspsc190501.51172157': __('identifierSchemeCodeUnspsc190501.51172157'),
	'identifierSchemeCodeUnspsc190501.51172158': __('identifierSchemeCodeUnspsc190501.51172158'),
	'identifierSchemeCodeUnspsc190501.51172159': __('identifierSchemeCodeUnspsc190501.51172159'),
	'identifierSchemeCodeUnspsc190501.51172160': __('identifierSchemeCodeUnspsc190501.51172160'),
	'identifierSchemeCodeUnspsc190501.51172161': __('identifierSchemeCodeUnspsc190501.51172161'),
	'identifierSchemeCodeUnspsc190501.51172162': __('identifierSchemeCodeUnspsc190501.51172162'),
	'identifierSchemeCodeUnspsc190501.51172163': __('identifierSchemeCodeUnspsc190501.51172163'),
	'identifierSchemeCodeUnspsc190501.51172164': __('identifierSchemeCodeUnspsc190501.51172164'),
	'identifierSchemeCodeUnspsc190501.51172165': __('identifierSchemeCodeUnspsc190501.51172165'),
	'identifierSchemeCodeUnspsc190501.51172166': __('identifierSchemeCodeUnspsc190501.51172166'),
	'identifierSchemeCodeUnspsc190501.51172167': __('identifierSchemeCodeUnspsc190501.51172167'),
	'identifierSchemeCodeUnspsc190501.51172168': __('identifierSchemeCodeUnspsc190501.51172168'),
	'identifierSchemeCodeUnspsc190501.51172169': __('identifierSchemeCodeUnspsc190501.51172169'),
	'identifierSchemeCodeUnspsc190501.51172170': __('identifierSchemeCodeUnspsc190501.51172170'),
	'identifierSchemeCodeUnspsc190501.51172171': __('identifierSchemeCodeUnspsc190501.51172171'),
	'identifierSchemeCodeUnspsc190501.51172172': __('identifierSchemeCodeUnspsc190501.51172172'),
	'identifierSchemeCodeUnspsc190501.51172173': __('identifierSchemeCodeUnspsc190501.51172173'),
	'identifierSchemeCodeUnspsc190501.51172174': __('identifierSchemeCodeUnspsc190501.51172174'),
	'identifierSchemeCodeUnspsc190501.51172175': __('identifierSchemeCodeUnspsc190501.51172175'),
	'identifierSchemeCodeUnspsc190501.51172176': __('identifierSchemeCodeUnspsc190501.51172176'),
	'identifierSchemeCodeUnspsc190501.51172177': __('identifierSchemeCodeUnspsc190501.51172177'),
	'identifierSchemeCodeUnspsc190501.51172178': __('identifierSchemeCodeUnspsc190501.51172178'),
	'identifierSchemeCodeUnspsc190501.51172179': __('identifierSchemeCodeUnspsc190501.51172179'),
	'identifierSchemeCodeUnspsc190501.51172180': __('identifierSchemeCodeUnspsc190501.51172180'),
	'identifierSchemeCodeUnspsc190501.51172181': __('identifierSchemeCodeUnspsc190501.51172181'),
	'identifierSchemeCodeUnspsc190501.51172182': __('identifierSchemeCodeUnspsc190501.51172182'),
	'identifierSchemeCodeUnspsc190501.51172183': __('identifierSchemeCodeUnspsc190501.51172183'),
	'identifierSchemeCodeUnspsc190501.51172184': __('identifierSchemeCodeUnspsc190501.51172184'),
	'identifierSchemeCodeUnspsc190501.51172200': __('identifierSchemeCodeUnspsc190501.51172200'),
	'identifierSchemeCodeUnspsc190501.51172201': __('identifierSchemeCodeUnspsc190501.51172201'),
	'identifierSchemeCodeUnspsc190501.51172202': __('identifierSchemeCodeUnspsc190501.51172202'),
	'identifierSchemeCodeUnspsc190501.51172300': __('identifierSchemeCodeUnspsc190501.51172300'),
	'identifierSchemeCodeUnspsc190501.51172301': __('identifierSchemeCodeUnspsc190501.51172301'),
	'identifierSchemeCodeUnspsc190501.51172302': __('identifierSchemeCodeUnspsc190501.51172302'),
	'identifierSchemeCodeUnspsc190501.51172303': __('identifierSchemeCodeUnspsc190501.51172303'),
	'identifierSchemeCodeUnspsc190501.51172304': __('identifierSchemeCodeUnspsc190501.51172304'),
	'identifierSchemeCodeUnspsc190501.51172305': __('identifierSchemeCodeUnspsc190501.51172305'),
	'identifierSchemeCodeUnspsc190501.51172306': __('identifierSchemeCodeUnspsc190501.51172306'),
	'identifierSchemeCodeUnspsc190501.51172307': __('identifierSchemeCodeUnspsc190501.51172307'),
	'identifierSchemeCodeUnspsc190501.51172308': __('identifierSchemeCodeUnspsc190501.51172308'),
	'identifierSchemeCodeUnspsc190501.51172309': __('identifierSchemeCodeUnspsc190501.51172309'),
	'identifierSchemeCodeUnspsc190501.51172310': __('identifierSchemeCodeUnspsc190501.51172310'),
	'identifierSchemeCodeUnspsc190501.51172311': __('identifierSchemeCodeUnspsc190501.51172311'),
	'identifierSchemeCodeUnspsc190501.51172312': __('identifierSchemeCodeUnspsc190501.51172312'),
	'identifierSchemeCodeUnspsc190501.51172313': __('identifierSchemeCodeUnspsc190501.51172313'),
	'identifierSchemeCodeUnspsc190501.51172314': __('identifierSchemeCodeUnspsc190501.51172314'),
	'identifierSchemeCodeUnspsc190501.51172315': __('identifierSchemeCodeUnspsc190501.51172315'),
	'identifierSchemeCodeUnspsc190501.51172316': __('identifierSchemeCodeUnspsc190501.51172316'),
	'identifierSchemeCodeUnspsc190501.51172317': __('identifierSchemeCodeUnspsc190501.51172317'),
	'identifierSchemeCodeUnspsc190501.51172318': __('identifierSchemeCodeUnspsc190501.51172318'),
	'identifierSchemeCodeUnspsc190501.51172319': __('identifierSchemeCodeUnspsc190501.51172319'),
	'identifierSchemeCodeUnspsc190501.51172320': __('identifierSchemeCodeUnspsc190501.51172320'),
	'identifierSchemeCodeUnspsc190501.51172321': __('identifierSchemeCodeUnspsc190501.51172321'),
	'identifierSchemeCodeUnspsc190501.51172322': __('identifierSchemeCodeUnspsc190501.51172322'),
	'identifierSchemeCodeUnspsc190501.51172323': __('identifierSchemeCodeUnspsc190501.51172323'),
	'identifierSchemeCodeUnspsc190501.51172324': __('identifierSchemeCodeUnspsc190501.51172324'),
	'identifierSchemeCodeUnspsc190501.51172325': __('identifierSchemeCodeUnspsc190501.51172325'),
	'identifierSchemeCodeUnspsc190501.51172326': __('identifierSchemeCodeUnspsc190501.51172326'),
	'identifierSchemeCodeUnspsc190501.51172327': __('identifierSchemeCodeUnspsc190501.51172327'),
	'identifierSchemeCodeUnspsc190501.51172328': __('identifierSchemeCodeUnspsc190501.51172328'),
	'identifierSchemeCodeUnspsc190501.51172329': __('identifierSchemeCodeUnspsc190501.51172329'),
	'identifierSchemeCodeUnspsc190501.51172330': __('identifierSchemeCodeUnspsc190501.51172330'),
	'identifierSchemeCodeUnspsc190501.51172331': __('identifierSchemeCodeUnspsc190501.51172331'),
	'identifierSchemeCodeUnspsc190501.51172332': __('identifierSchemeCodeUnspsc190501.51172332'),
	'identifierSchemeCodeUnspsc190501.51172333': __('identifierSchemeCodeUnspsc190501.51172333'),
	'identifierSchemeCodeUnspsc190501.51172334': __('identifierSchemeCodeUnspsc190501.51172334'),
	'identifierSchemeCodeUnspsc190501.51172335': __('identifierSchemeCodeUnspsc190501.51172335'),
	'identifierSchemeCodeUnspsc190501.51172400': __('identifierSchemeCodeUnspsc190501.51172400'),
	'identifierSchemeCodeUnspsc190501.51172401': __('identifierSchemeCodeUnspsc190501.51172401'),
	'identifierSchemeCodeUnspsc190501.51172402': __('identifierSchemeCodeUnspsc190501.51172402'),
	'identifierSchemeCodeUnspsc190501.51172403': __('identifierSchemeCodeUnspsc190501.51172403'),
	'identifierSchemeCodeUnspsc190501.51172404': __('identifierSchemeCodeUnspsc190501.51172404'),
	'identifierSchemeCodeUnspsc190501.51172405': __('identifierSchemeCodeUnspsc190501.51172405'),
	'identifierSchemeCodeUnspsc190501.51172406': __('identifierSchemeCodeUnspsc190501.51172406'),
	'identifierSchemeCodeUnspsc190501.51172407': __('identifierSchemeCodeUnspsc190501.51172407'),
	'identifierSchemeCodeUnspsc190501.51172408': __('identifierSchemeCodeUnspsc190501.51172408'),
	'identifierSchemeCodeUnspsc190501.51172409': __('identifierSchemeCodeUnspsc190501.51172409'),
	'identifierSchemeCodeUnspsc190501.51172410': __('identifierSchemeCodeUnspsc190501.51172410'),
	'identifierSchemeCodeUnspsc190501.51172411': __('identifierSchemeCodeUnspsc190501.51172411'),
	'identifierSchemeCodeUnspsc190501.51172412': __('identifierSchemeCodeUnspsc190501.51172412'),
	'identifierSchemeCodeUnspsc190501.51172413': __('identifierSchemeCodeUnspsc190501.51172413'),
	'identifierSchemeCodeUnspsc190501.51172414': __('identifierSchemeCodeUnspsc190501.51172414'),
	'identifierSchemeCodeUnspsc190501.51172415': __('identifierSchemeCodeUnspsc190501.51172415'),
	'identifierSchemeCodeUnspsc190501.51172416': __('identifierSchemeCodeUnspsc190501.51172416'),
	'identifierSchemeCodeUnspsc190501.51172417': __('identifierSchemeCodeUnspsc190501.51172417'),
	'identifierSchemeCodeUnspsc190501.51172418': __('identifierSchemeCodeUnspsc190501.51172418'),
	'identifierSchemeCodeUnspsc190501.51172419': __('identifierSchemeCodeUnspsc190501.51172419'),
	'identifierSchemeCodeUnspsc190501.51172420': __('identifierSchemeCodeUnspsc190501.51172420'),
	'identifierSchemeCodeUnspsc190501.51172421': __('identifierSchemeCodeUnspsc190501.51172421'),
	'identifierSchemeCodeUnspsc190501.51172422': __('identifierSchemeCodeUnspsc190501.51172422'),
	'identifierSchemeCodeUnspsc190501.51172423': __('identifierSchemeCodeUnspsc190501.51172423'),
	'identifierSchemeCodeUnspsc190501.51172424': __('identifierSchemeCodeUnspsc190501.51172424'),
	'identifierSchemeCodeUnspsc190501.51172425': __('identifierSchemeCodeUnspsc190501.51172425'),
	'identifierSchemeCodeUnspsc190501.51172426': __('identifierSchemeCodeUnspsc190501.51172426'),
	'identifierSchemeCodeUnspsc190501.51172427': __('identifierSchemeCodeUnspsc190501.51172427'),
	'identifierSchemeCodeUnspsc190501.51172428': __('identifierSchemeCodeUnspsc190501.51172428'),
	'identifierSchemeCodeUnspsc190501.51172429': __('identifierSchemeCodeUnspsc190501.51172429'),
	'identifierSchemeCodeUnspsc190501.51172430': __('identifierSchemeCodeUnspsc190501.51172430'),
	'identifierSchemeCodeUnspsc190501.51172431': __('identifierSchemeCodeUnspsc190501.51172431'),
	'identifierSchemeCodeUnspsc190501.51172432': __('identifierSchemeCodeUnspsc190501.51172432'),
	'identifierSchemeCodeUnspsc190501.51172433': __('identifierSchemeCodeUnspsc190501.51172433'),
	'identifierSchemeCodeUnspsc190501.51172434': __('identifierSchemeCodeUnspsc190501.51172434'),
	'identifierSchemeCodeUnspsc190501.51172435': __('identifierSchemeCodeUnspsc190501.51172435'),
	'identifierSchemeCodeUnspsc190501.51172436': __('identifierSchemeCodeUnspsc190501.51172436'),
	'identifierSchemeCodeUnspsc190501.51172437': __('identifierSchemeCodeUnspsc190501.51172437'),
	'identifierSchemeCodeUnspsc190501.51172438': __('identifierSchemeCodeUnspsc190501.51172438'),
	'identifierSchemeCodeUnspsc190501.51172439': __('identifierSchemeCodeUnspsc190501.51172439'),
	'identifierSchemeCodeUnspsc190501.51172440': __('identifierSchemeCodeUnspsc190501.51172440'),
	'identifierSchemeCodeUnspsc190501.51172441': __('identifierSchemeCodeUnspsc190501.51172441'),
	'identifierSchemeCodeUnspsc190501.51172442': __('identifierSchemeCodeUnspsc190501.51172442'),
	'identifierSchemeCodeUnspsc190501.51172443': __('identifierSchemeCodeUnspsc190501.51172443'),
	'identifierSchemeCodeUnspsc190501.51172444': __('identifierSchemeCodeUnspsc190501.51172444'),
	'identifierSchemeCodeUnspsc190501.51172445': __('identifierSchemeCodeUnspsc190501.51172445'),
	'identifierSchemeCodeUnspsc190501.51172446': __('identifierSchemeCodeUnspsc190501.51172446'),
	'identifierSchemeCodeUnspsc190501.51172447': __('identifierSchemeCodeUnspsc190501.51172447'),
	'identifierSchemeCodeUnspsc190501.51172448': __('identifierSchemeCodeUnspsc190501.51172448'),
	'identifierSchemeCodeUnspsc190501.51172449': __('identifierSchemeCodeUnspsc190501.51172449'),
	'identifierSchemeCodeUnspsc190501.51172450': __('identifierSchemeCodeUnspsc190501.51172450'),
	'identifierSchemeCodeUnspsc190501.51172451': __('identifierSchemeCodeUnspsc190501.51172451'),
	'identifierSchemeCodeUnspsc190501.51172452': __('identifierSchemeCodeUnspsc190501.51172452'),
	'identifierSchemeCodeUnspsc190501.51172453': __('identifierSchemeCodeUnspsc190501.51172453'),
	'identifierSchemeCodeUnspsc190501.51172454': __('identifierSchemeCodeUnspsc190501.51172454'),
	'identifierSchemeCodeUnspsc190501.51172455': __('identifierSchemeCodeUnspsc190501.51172455'),
	'identifierSchemeCodeUnspsc190501.51172456': __('identifierSchemeCodeUnspsc190501.51172456'),
	'identifierSchemeCodeUnspsc190501.51172457': __('identifierSchemeCodeUnspsc190501.51172457'),
	'identifierSchemeCodeUnspsc190501.51172458': __('identifierSchemeCodeUnspsc190501.51172458'),
	'identifierSchemeCodeUnspsc190501.51172459': __('identifierSchemeCodeUnspsc190501.51172459'),
	'identifierSchemeCodeUnspsc190501.51172460': __('identifierSchemeCodeUnspsc190501.51172460'),
	'identifierSchemeCodeUnspsc190501.51172461': __('identifierSchemeCodeUnspsc190501.51172461'),
	'identifierSchemeCodeUnspsc190501.51172462': __('identifierSchemeCodeUnspsc190501.51172462'),
	'identifierSchemeCodeUnspsc190501.51172463': __('identifierSchemeCodeUnspsc190501.51172463'),
	'identifierSchemeCodeUnspsc190501.51172464': __('identifierSchemeCodeUnspsc190501.51172464'),
	'identifierSchemeCodeUnspsc190501.51172465': __('identifierSchemeCodeUnspsc190501.51172465'),
	'identifierSchemeCodeUnspsc190501.51172466': __('identifierSchemeCodeUnspsc190501.51172466'),
	'identifierSchemeCodeUnspsc190501.51172467': __('identifierSchemeCodeUnspsc190501.51172467'),
	'identifierSchemeCodeUnspsc190501.51172468': __('identifierSchemeCodeUnspsc190501.51172468'),
	'identifierSchemeCodeUnspsc190501.51172469': __('identifierSchemeCodeUnspsc190501.51172469'),
	'identifierSchemeCodeUnspsc190501.51172470': __('identifierSchemeCodeUnspsc190501.51172470'),
	'identifierSchemeCodeUnspsc190501.51172471': __('identifierSchemeCodeUnspsc190501.51172471'),
	'identifierSchemeCodeUnspsc190501.51172472': __('identifierSchemeCodeUnspsc190501.51172472'),
	'identifierSchemeCodeUnspsc190501.51172473': __('identifierSchemeCodeUnspsc190501.51172473'),
	'identifierSchemeCodeUnspsc190501.51172474': __('identifierSchemeCodeUnspsc190501.51172474'),
	'identifierSchemeCodeUnspsc190501.51172475': __('identifierSchemeCodeUnspsc190501.51172475'),
	'identifierSchemeCodeUnspsc190501.51172476': __('identifierSchemeCodeUnspsc190501.51172476'),
	'identifierSchemeCodeUnspsc190501.51172477': __('identifierSchemeCodeUnspsc190501.51172477'),
	'identifierSchemeCodeUnspsc190501.51172478': __('identifierSchemeCodeUnspsc190501.51172478'),
	'identifierSchemeCodeUnspsc190501.51172479': __('identifierSchemeCodeUnspsc190501.51172479'),
	'identifierSchemeCodeUnspsc190501.51172480': __('identifierSchemeCodeUnspsc190501.51172480'),
	'identifierSchemeCodeUnspsc190501.51172500': __('identifierSchemeCodeUnspsc190501.51172500'),
	'identifierSchemeCodeUnspsc190501.51172501': __('identifierSchemeCodeUnspsc190501.51172501'),
	'identifierSchemeCodeUnspsc190501.51172502': __('identifierSchemeCodeUnspsc190501.51172502'),
	'identifierSchemeCodeUnspsc190501.51172503': __('identifierSchemeCodeUnspsc190501.51172503'),
	'identifierSchemeCodeUnspsc190501.51172504': __('identifierSchemeCodeUnspsc190501.51172504'),
	'identifierSchemeCodeUnspsc190501.51172505': __('identifierSchemeCodeUnspsc190501.51172505'),
	'identifierSchemeCodeUnspsc190501.51172506': __('identifierSchemeCodeUnspsc190501.51172506'),
	'identifierSchemeCodeUnspsc190501.51172600': __('identifierSchemeCodeUnspsc190501.51172600'),
	'identifierSchemeCodeUnspsc190501.51172601': __('identifierSchemeCodeUnspsc190501.51172601'),
	'identifierSchemeCodeUnspsc190501.51172602': __('identifierSchemeCodeUnspsc190501.51172602'),
	'identifierSchemeCodeUnspsc190501.51172700': __('identifierSchemeCodeUnspsc190501.51172700'),
	'identifierSchemeCodeUnspsc190501.51172701': __('identifierSchemeCodeUnspsc190501.51172701'),
	'identifierSchemeCodeUnspsc190501.51172702': __('identifierSchemeCodeUnspsc190501.51172702'),
	'identifierSchemeCodeUnspsc190501.51172703': __('identifierSchemeCodeUnspsc190501.51172703'),
	'identifierSchemeCodeUnspsc190501.51172704': __('identifierSchemeCodeUnspsc190501.51172704'),
	'identifierSchemeCodeUnspsc190501.51172705': __('identifierSchemeCodeUnspsc190501.51172705'),
	'identifierSchemeCodeUnspsc190501.51172706': __('identifierSchemeCodeUnspsc190501.51172706'),
	'identifierSchemeCodeUnspsc190501.51172707': __('identifierSchemeCodeUnspsc190501.51172707'),
	'identifierSchemeCodeUnspsc190501.51172708': __('identifierSchemeCodeUnspsc190501.51172708'),
	'identifierSchemeCodeUnspsc190501.51172709': __('identifierSchemeCodeUnspsc190501.51172709'),
	'identifierSchemeCodeUnspsc190501.51172710': __('identifierSchemeCodeUnspsc190501.51172710'),
	'identifierSchemeCodeUnspsc190501.51172711': __('identifierSchemeCodeUnspsc190501.51172711'),
	'identifierSchemeCodeUnspsc190501.51172712': __('identifierSchemeCodeUnspsc190501.51172712'),
	'identifierSchemeCodeUnspsc190501.51172713': __('identifierSchemeCodeUnspsc190501.51172713'),
	'identifierSchemeCodeUnspsc190501.51172800': __('identifierSchemeCodeUnspsc190501.51172800'),
	'identifierSchemeCodeUnspsc190501.51172801': __('identifierSchemeCodeUnspsc190501.51172801'),
	'identifierSchemeCodeUnspsc190501.51172802': __('identifierSchemeCodeUnspsc190501.51172802'),
	'identifierSchemeCodeUnspsc190501.51172803': __('identifierSchemeCodeUnspsc190501.51172803'),
	'identifierSchemeCodeUnspsc190501.51172804': __('identifierSchemeCodeUnspsc190501.51172804'),
	'identifierSchemeCodeUnspsc190501.51172805': __('identifierSchemeCodeUnspsc190501.51172805'),
	'identifierSchemeCodeUnspsc190501.51172806': __('identifierSchemeCodeUnspsc190501.51172806'),
	'identifierSchemeCodeUnspsc190501.51172807': __('identifierSchemeCodeUnspsc190501.51172807'),
	'identifierSchemeCodeUnspsc190501.51172808': __('identifierSchemeCodeUnspsc190501.51172808'),
	'identifierSchemeCodeUnspsc190501.51172809': __('identifierSchemeCodeUnspsc190501.51172809'),
	'identifierSchemeCodeUnspsc190501.51172810': __('identifierSchemeCodeUnspsc190501.51172810'),
	'identifierSchemeCodeUnspsc190501.51172811': __('identifierSchemeCodeUnspsc190501.51172811'),
	'identifierSchemeCodeUnspsc190501.51172812': __('identifierSchemeCodeUnspsc190501.51172812'),
	'identifierSchemeCodeUnspsc190501.51172813': __('identifierSchemeCodeUnspsc190501.51172813'),
	'identifierSchemeCodeUnspsc190501.51172814': __('identifierSchemeCodeUnspsc190501.51172814'),
	'identifierSchemeCodeUnspsc190501.51172815': __('identifierSchemeCodeUnspsc190501.51172815'),
	'identifierSchemeCodeUnspsc190501.51172816': __('identifierSchemeCodeUnspsc190501.51172816'),
	'identifierSchemeCodeUnspsc190501.51172817': __('identifierSchemeCodeUnspsc190501.51172817'),
	'identifierSchemeCodeUnspsc190501.51172818': __('identifierSchemeCodeUnspsc190501.51172818'),
	'identifierSchemeCodeUnspsc190501.51172819': __('identifierSchemeCodeUnspsc190501.51172819'),
	'identifierSchemeCodeUnspsc190501.51172820': __('identifierSchemeCodeUnspsc190501.51172820'),
	'identifierSchemeCodeUnspsc190501.51172821': __('identifierSchemeCodeUnspsc190501.51172821'),
	'identifierSchemeCodeUnspsc190501.51172822': __('identifierSchemeCodeUnspsc190501.51172822'),
	'identifierSchemeCodeUnspsc190501.51172823': __('identifierSchemeCodeUnspsc190501.51172823'),
	'identifierSchemeCodeUnspsc190501.51172824': __('identifierSchemeCodeUnspsc190501.51172824'),
	'identifierSchemeCodeUnspsc190501.51172825': __('identifierSchemeCodeUnspsc190501.51172825'),
	'identifierSchemeCodeUnspsc190501.51172826': __('identifierSchemeCodeUnspsc190501.51172826'),
	'identifierSchemeCodeUnspsc190501.51172827': __('identifierSchemeCodeUnspsc190501.51172827'),
	'identifierSchemeCodeUnspsc190501.51172828': __('identifierSchemeCodeUnspsc190501.51172828'),
	'identifierSchemeCodeUnspsc190501.51172829': __('identifierSchemeCodeUnspsc190501.51172829'),
	'identifierSchemeCodeUnspsc190501.51172830': __('identifierSchemeCodeUnspsc190501.51172830'),
	'identifierSchemeCodeUnspsc190501.51172831': __('identifierSchemeCodeUnspsc190501.51172831'),
	'identifierSchemeCodeUnspsc190501.51172832': __('identifierSchemeCodeUnspsc190501.51172832'),
	'identifierSchemeCodeUnspsc190501.51172833': __('identifierSchemeCodeUnspsc190501.51172833'),
	'identifierSchemeCodeUnspsc190501.51172834': __('identifierSchemeCodeUnspsc190501.51172834'),
	'identifierSchemeCodeUnspsc190501.51172835': __('identifierSchemeCodeUnspsc190501.51172835'),
	'identifierSchemeCodeUnspsc190501.51172836': __('identifierSchemeCodeUnspsc190501.51172836'),
	'identifierSchemeCodeUnspsc190501.51172837': __('identifierSchemeCodeUnspsc190501.51172837'),
	'identifierSchemeCodeUnspsc190501.51172838': __('identifierSchemeCodeUnspsc190501.51172838'),
	'identifierSchemeCodeUnspsc190501.51172900': __('identifierSchemeCodeUnspsc190501.51172900'),
	'identifierSchemeCodeUnspsc190501.51172901': __('identifierSchemeCodeUnspsc190501.51172901'),
	'identifierSchemeCodeUnspsc190501.51172902': __('identifierSchemeCodeUnspsc190501.51172902'),
	'identifierSchemeCodeUnspsc190501.51172903': __('identifierSchemeCodeUnspsc190501.51172903'),
	'identifierSchemeCodeUnspsc190501.51172904': __('identifierSchemeCodeUnspsc190501.51172904'),
	'identifierSchemeCodeUnspsc190501.51172905': __('identifierSchemeCodeUnspsc190501.51172905'),
	'identifierSchemeCodeUnspsc190501.51172906': __('identifierSchemeCodeUnspsc190501.51172906'),
	'identifierSchemeCodeUnspsc190501.51172907': __('identifierSchemeCodeUnspsc190501.51172907'),
	'identifierSchemeCodeUnspsc190501.51172908': __('identifierSchemeCodeUnspsc190501.51172908'),
	'identifierSchemeCodeUnspsc190501.51173000': __('identifierSchemeCodeUnspsc190501.51173000'),
	'identifierSchemeCodeUnspsc190501.51173001': __('identifierSchemeCodeUnspsc190501.51173001'),
	'identifierSchemeCodeUnspsc190501.51173002': __('identifierSchemeCodeUnspsc190501.51173002'),
	'identifierSchemeCodeUnspsc190501.51173003': __('identifierSchemeCodeUnspsc190501.51173003'),
	'identifierSchemeCodeUnspsc190501.51173004': __('identifierSchemeCodeUnspsc190501.51173004'),
	'identifierSchemeCodeUnspsc190501.51173005': __('identifierSchemeCodeUnspsc190501.51173005'),
	'identifierSchemeCodeUnspsc190501.51173006': __('identifierSchemeCodeUnspsc190501.51173006'),
	'identifierSchemeCodeUnspsc190501.51173007': __('identifierSchemeCodeUnspsc190501.51173007'),
	'identifierSchemeCodeUnspsc190501.51173008': __('identifierSchemeCodeUnspsc190501.51173008'),
	'identifierSchemeCodeUnspsc190501.51173100': __('identifierSchemeCodeUnspsc190501.51173100'),
	'identifierSchemeCodeUnspsc190501.51173101': __('identifierSchemeCodeUnspsc190501.51173101'),
	'identifierSchemeCodeUnspsc190501.51173102': __('identifierSchemeCodeUnspsc190501.51173102'),
	'identifierSchemeCodeUnspsc190501.51173103': __('identifierSchemeCodeUnspsc190501.51173103'),
	'identifierSchemeCodeUnspsc190501.51173200': __('identifierSchemeCodeUnspsc190501.51173200'),
	'identifierSchemeCodeUnspsc190501.51173201': __('identifierSchemeCodeUnspsc190501.51173201'),
	'identifierSchemeCodeUnspsc190501.51173202': __('identifierSchemeCodeUnspsc190501.51173202'),
	'identifierSchemeCodeUnspsc190501.51173203': __('identifierSchemeCodeUnspsc190501.51173203'),
	'identifierSchemeCodeUnspsc190501.51173204': __('identifierSchemeCodeUnspsc190501.51173204'),
	'identifierSchemeCodeUnspsc190501.51173205': __('identifierSchemeCodeUnspsc190501.51173205'),
	'identifierSchemeCodeUnspsc190501.51173206': __('identifierSchemeCodeUnspsc190501.51173206'),
	'identifierSchemeCodeUnspsc190501.51173207': __('identifierSchemeCodeUnspsc190501.51173207'),
	'identifierSchemeCodeUnspsc190501.51173208': __('identifierSchemeCodeUnspsc190501.51173208'),
	'identifierSchemeCodeUnspsc190501.51173209': __('identifierSchemeCodeUnspsc190501.51173209'),
	'identifierSchemeCodeUnspsc190501.51173210': __('identifierSchemeCodeUnspsc190501.51173210'),
	'identifierSchemeCodeUnspsc190501.51173211': __('identifierSchemeCodeUnspsc190501.51173211'),
	'identifierSchemeCodeUnspsc190501.51173212': __('identifierSchemeCodeUnspsc190501.51173212'),
	'identifierSchemeCodeUnspsc190501.51173213': __('identifierSchemeCodeUnspsc190501.51173213'),
	'identifierSchemeCodeUnspsc190501.51173214': __('identifierSchemeCodeUnspsc190501.51173214'),
	'identifierSchemeCodeUnspsc190501.51173215': __('identifierSchemeCodeUnspsc190501.51173215'),
	'identifierSchemeCodeUnspsc190501.51173216': __('identifierSchemeCodeUnspsc190501.51173216'),
	'identifierSchemeCodeUnspsc190501.51173217': __('identifierSchemeCodeUnspsc190501.51173217'),
	'identifierSchemeCodeUnspsc190501.51173218': __('identifierSchemeCodeUnspsc190501.51173218'),
	'identifierSchemeCodeUnspsc190501.51173219': __('identifierSchemeCodeUnspsc190501.51173219'),
	'identifierSchemeCodeUnspsc190501.51173220': __('identifierSchemeCodeUnspsc190501.51173220'),
	'identifierSchemeCodeUnspsc190501.51173221': __('identifierSchemeCodeUnspsc190501.51173221'),
	'identifierSchemeCodeUnspsc190501.51173222': __('identifierSchemeCodeUnspsc190501.51173222'),
	'identifierSchemeCodeUnspsc190501.51173223': __('identifierSchemeCodeUnspsc190501.51173223'),
	'identifierSchemeCodeUnspsc190501.51173224': __('identifierSchemeCodeUnspsc190501.51173224'),
	'identifierSchemeCodeUnspsc190501.51173225': __('identifierSchemeCodeUnspsc190501.51173225'),
	'identifierSchemeCodeUnspsc190501.51173226': __('identifierSchemeCodeUnspsc190501.51173226'),
	'identifierSchemeCodeUnspsc190501.51173227': __('identifierSchemeCodeUnspsc190501.51173227'),
	'identifierSchemeCodeUnspsc190501.51173228': __('identifierSchemeCodeUnspsc190501.51173228'),
	'identifierSchemeCodeUnspsc190501.51173229': __('identifierSchemeCodeUnspsc190501.51173229'),
	'identifierSchemeCodeUnspsc190501.51173230': __('identifierSchemeCodeUnspsc190501.51173230'),
	'identifierSchemeCodeUnspsc190501.51173231': __('identifierSchemeCodeUnspsc190501.51173231'),
	'identifierSchemeCodeUnspsc190501.51173232': __('identifierSchemeCodeUnspsc190501.51173232'),
	'identifierSchemeCodeUnspsc190501.51173233': __('identifierSchemeCodeUnspsc190501.51173233'),
	'identifierSchemeCodeUnspsc190501.51173300': __('identifierSchemeCodeUnspsc190501.51173300'),
	'identifierSchemeCodeUnspsc190501.51173301': __('identifierSchemeCodeUnspsc190501.51173301'),
	'identifierSchemeCodeUnspsc190501.51180000': __('identifierSchemeCodeUnspsc190501.51180000'),
	'identifierSchemeCodeUnspsc190501.51181500': __('identifierSchemeCodeUnspsc190501.51181500'),
	'identifierSchemeCodeUnspsc190501.51181526': __('identifierSchemeCodeUnspsc190501.51181526'),
	'identifierSchemeCodeUnspsc190501.51181538': __('identifierSchemeCodeUnspsc190501.51181538'),
	'identifierSchemeCodeUnspsc190501.51181539': __('identifierSchemeCodeUnspsc190501.51181539'),
	'identifierSchemeCodeUnspsc190501.51181546': __('identifierSchemeCodeUnspsc190501.51181546'),
	'identifierSchemeCodeUnspsc190501.51181548': __('identifierSchemeCodeUnspsc190501.51181548'),
	'identifierSchemeCodeUnspsc190501.51181553': __('identifierSchemeCodeUnspsc190501.51181553'),
	'identifierSchemeCodeUnspsc190501.51181565': __('identifierSchemeCodeUnspsc190501.51181565'),
	'identifierSchemeCodeUnspsc190501.51181576': __('identifierSchemeCodeUnspsc190501.51181576'),
	'identifierSchemeCodeUnspsc190501.51181577': __('identifierSchemeCodeUnspsc190501.51181577'),
	'identifierSchemeCodeUnspsc190501.51181600': __('identifierSchemeCodeUnspsc190501.51181600'),
	'identifierSchemeCodeUnspsc190501.51181602': __('identifierSchemeCodeUnspsc190501.51181602'),
	'identifierSchemeCodeUnspsc190501.51181603': __('identifierSchemeCodeUnspsc190501.51181603'),
	'identifierSchemeCodeUnspsc190501.51181604': __('identifierSchemeCodeUnspsc190501.51181604'),
	'identifierSchemeCodeUnspsc190501.51181605': __('identifierSchemeCodeUnspsc190501.51181605'),
	'identifierSchemeCodeUnspsc190501.51181606': __('identifierSchemeCodeUnspsc190501.51181606'),
	'identifierSchemeCodeUnspsc190501.51181607': __('identifierSchemeCodeUnspsc190501.51181607'),
	'identifierSchemeCodeUnspsc190501.51181608': __('identifierSchemeCodeUnspsc190501.51181608'),
	'identifierSchemeCodeUnspsc190501.51181610': __('identifierSchemeCodeUnspsc190501.51181610'),
	'identifierSchemeCodeUnspsc190501.51181611': __('identifierSchemeCodeUnspsc190501.51181611'),
	'identifierSchemeCodeUnspsc190501.51181612': __('identifierSchemeCodeUnspsc190501.51181612'),
	'identifierSchemeCodeUnspsc190501.51181613': __('identifierSchemeCodeUnspsc190501.51181613'),
	'identifierSchemeCodeUnspsc190501.51181614': __('identifierSchemeCodeUnspsc190501.51181614'),
	'identifierSchemeCodeUnspsc190501.51181615': __('identifierSchemeCodeUnspsc190501.51181615'),
	'identifierSchemeCodeUnspsc190501.51181616': __('identifierSchemeCodeUnspsc190501.51181616'),
	'identifierSchemeCodeUnspsc190501.51181617': __('identifierSchemeCodeUnspsc190501.51181617'),
	'identifierSchemeCodeUnspsc190501.51181618': __('identifierSchemeCodeUnspsc190501.51181618'),
	'identifierSchemeCodeUnspsc190501.51181619': __('identifierSchemeCodeUnspsc190501.51181619'),
	'identifierSchemeCodeUnspsc190501.51181620': __('identifierSchemeCodeUnspsc190501.51181620'),
	'identifierSchemeCodeUnspsc190501.51181621': __('identifierSchemeCodeUnspsc190501.51181621'),
	'identifierSchemeCodeUnspsc190501.51181622': __('identifierSchemeCodeUnspsc190501.51181622'),
	'identifierSchemeCodeUnspsc190501.51181623': __('identifierSchemeCodeUnspsc190501.51181623'),
	'identifierSchemeCodeUnspsc190501.51181624': __('identifierSchemeCodeUnspsc190501.51181624'),
	'identifierSchemeCodeUnspsc190501.51181625': __('identifierSchemeCodeUnspsc190501.51181625'),
	'identifierSchemeCodeUnspsc190501.51181626': __('identifierSchemeCodeUnspsc190501.51181626'),
	'identifierSchemeCodeUnspsc190501.51181627': __('identifierSchemeCodeUnspsc190501.51181627'),
	'identifierSchemeCodeUnspsc190501.51181628': __('identifierSchemeCodeUnspsc190501.51181628'),
	'identifierSchemeCodeUnspsc190501.51181629': __('identifierSchemeCodeUnspsc190501.51181629'),
	'identifierSchemeCodeUnspsc190501.51181630': __('identifierSchemeCodeUnspsc190501.51181630'),
	'identifierSchemeCodeUnspsc190501.51181631': __('identifierSchemeCodeUnspsc190501.51181631'),
	'identifierSchemeCodeUnspsc190501.51181900': __('identifierSchemeCodeUnspsc190501.51181900'),
	'identifierSchemeCodeUnspsc190501.51181901': __('identifierSchemeCodeUnspsc190501.51181901'),
	'identifierSchemeCodeUnspsc190501.51181905': __('identifierSchemeCodeUnspsc190501.51181905'),
	'identifierSchemeCodeUnspsc190501.51181906': __('identifierSchemeCodeUnspsc190501.51181906'),
	'identifierSchemeCodeUnspsc190501.51181912': __('identifierSchemeCodeUnspsc190501.51181912'),
	'identifierSchemeCodeUnspsc190501.51181914': __('identifierSchemeCodeUnspsc190501.51181914'),
	'identifierSchemeCodeUnspsc190501.51181915': __('identifierSchemeCodeUnspsc190501.51181915'),
	'identifierSchemeCodeUnspsc190501.51181916': __('identifierSchemeCodeUnspsc190501.51181916'),
	'identifierSchemeCodeUnspsc190501.51181917': __('identifierSchemeCodeUnspsc190501.51181917'),
	'identifierSchemeCodeUnspsc190501.51181918': __('identifierSchemeCodeUnspsc190501.51181918'),
	'identifierSchemeCodeUnspsc190501.51181919': __('identifierSchemeCodeUnspsc190501.51181919'),
	'identifierSchemeCodeUnspsc190501.51181920': __('identifierSchemeCodeUnspsc190501.51181920'),
	'identifierSchemeCodeUnspsc190501.51181921': __('identifierSchemeCodeUnspsc190501.51181921'),
	'identifierSchemeCodeUnspsc190501.51181922': __('identifierSchemeCodeUnspsc190501.51181922'),
	'identifierSchemeCodeUnspsc190501.51181923': __('identifierSchemeCodeUnspsc190501.51181923'),
	'identifierSchemeCodeUnspsc190501.51181924': __('identifierSchemeCodeUnspsc190501.51181924'),
	'identifierSchemeCodeUnspsc190501.51181925': __('identifierSchemeCodeUnspsc190501.51181925'),
	'identifierSchemeCodeUnspsc190501.51181926': __('identifierSchemeCodeUnspsc190501.51181926'),
	'identifierSchemeCodeUnspsc190501.51181927': __('identifierSchemeCodeUnspsc190501.51181927'),
	'identifierSchemeCodeUnspsc190501.51181928': __('identifierSchemeCodeUnspsc190501.51181928'),
	'identifierSchemeCodeUnspsc190501.51181929': __('identifierSchemeCodeUnspsc190501.51181929'),
	'identifierSchemeCodeUnspsc190501.51181930': __('identifierSchemeCodeUnspsc190501.51181930'),
	'identifierSchemeCodeUnspsc190501.51182000': __('identifierSchemeCodeUnspsc190501.51182000'),
	'identifierSchemeCodeUnspsc190501.51182001': __('identifierSchemeCodeUnspsc190501.51182001'),
	'identifierSchemeCodeUnspsc190501.51182002': __('identifierSchemeCodeUnspsc190501.51182002'),
	'identifierSchemeCodeUnspsc190501.51182006': __('identifierSchemeCodeUnspsc190501.51182006'),
	'identifierSchemeCodeUnspsc190501.51182007': __('identifierSchemeCodeUnspsc190501.51182007'),
	'identifierSchemeCodeUnspsc190501.51182008': __('identifierSchemeCodeUnspsc190501.51182008'),
	'identifierSchemeCodeUnspsc190501.51182009': __('identifierSchemeCodeUnspsc190501.51182009'),
	'identifierSchemeCodeUnspsc190501.51182010': __('identifierSchemeCodeUnspsc190501.51182010'),
	'identifierSchemeCodeUnspsc190501.51182012': __('identifierSchemeCodeUnspsc190501.51182012'),
	'identifierSchemeCodeUnspsc190501.51182013': __('identifierSchemeCodeUnspsc190501.51182013'),
	'identifierSchemeCodeUnspsc190501.51182014': __('identifierSchemeCodeUnspsc190501.51182014'),
	'identifierSchemeCodeUnspsc190501.51182015': __('identifierSchemeCodeUnspsc190501.51182015'),
	'identifierSchemeCodeUnspsc190501.51182016': __('identifierSchemeCodeUnspsc190501.51182016'),
	'identifierSchemeCodeUnspsc190501.51182017': __('identifierSchemeCodeUnspsc190501.51182017'),
	'identifierSchemeCodeUnspsc190501.51182018': __('identifierSchemeCodeUnspsc190501.51182018'),
	'identifierSchemeCodeUnspsc190501.51182019': __('identifierSchemeCodeUnspsc190501.51182019'),
	'identifierSchemeCodeUnspsc190501.51182020': __('identifierSchemeCodeUnspsc190501.51182020'),
	'identifierSchemeCodeUnspsc190501.51182021': __('identifierSchemeCodeUnspsc190501.51182021'),
	'identifierSchemeCodeUnspsc190501.51182022': __('identifierSchemeCodeUnspsc190501.51182022'),
	'identifierSchemeCodeUnspsc190501.51182023': __('identifierSchemeCodeUnspsc190501.51182023'),
	'identifierSchemeCodeUnspsc190501.51182024': __('identifierSchemeCodeUnspsc190501.51182024'),
	'identifierSchemeCodeUnspsc190501.51182025': __('identifierSchemeCodeUnspsc190501.51182025'),
	'identifierSchemeCodeUnspsc190501.51182026': __('identifierSchemeCodeUnspsc190501.51182026'),
	'identifierSchemeCodeUnspsc190501.51182027': __('identifierSchemeCodeUnspsc190501.51182027'),
	'identifierSchemeCodeUnspsc190501.51182028': __('identifierSchemeCodeUnspsc190501.51182028'),
	'identifierSchemeCodeUnspsc190501.51182029': __('identifierSchemeCodeUnspsc190501.51182029'),
	'identifierSchemeCodeUnspsc190501.51182030': __('identifierSchemeCodeUnspsc190501.51182030'),
	'identifierSchemeCodeUnspsc190501.51182031': __('identifierSchemeCodeUnspsc190501.51182031'),
	'identifierSchemeCodeUnspsc190501.51182032': __('identifierSchemeCodeUnspsc190501.51182032'),
	'identifierSchemeCodeUnspsc190501.51182033': __('identifierSchemeCodeUnspsc190501.51182033'),
	'identifierSchemeCodeUnspsc190501.51182034': __('identifierSchemeCodeUnspsc190501.51182034'),
	'identifierSchemeCodeUnspsc190501.51182035': __('identifierSchemeCodeUnspsc190501.51182035'),
	'identifierSchemeCodeUnspsc190501.51182036': __('identifierSchemeCodeUnspsc190501.51182036'),
	'identifierSchemeCodeUnspsc190501.51182037': __('identifierSchemeCodeUnspsc190501.51182037'),
	'identifierSchemeCodeUnspsc190501.51182038': __('identifierSchemeCodeUnspsc190501.51182038'),
	'identifierSchemeCodeUnspsc190501.51182039': __('identifierSchemeCodeUnspsc190501.51182039'),
	'identifierSchemeCodeUnspsc190501.51182040': __('identifierSchemeCodeUnspsc190501.51182040'),
	'identifierSchemeCodeUnspsc190501.51182041': __('identifierSchemeCodeUnspsc190501.51182041'),
	'identifierSchemeCodeUnspsc190501.51182042': __('identifierSchemeCodeUnspsc190501.51182042'),
	'identifierSchemeCodeUnspsc190501.51182043': __('identifierSchemeCodeUnspsc190501.51182043'),
	'identifierSchemeCodeUnspsc190501.51182044': __('identifierSchemeCodeUnspsc190501.51182044'),
	'identifierSchemeCodeUnspsc190501.51182045': __('identifierSchemeCodeUnspsc190501.51182045'),
	'identifierSchemeCodeUnspsc190501.51182046': __('identifierSchemeCodeUnspsc190501.51182046'),
	'identifierSchemeCodeUnspsc190501.51182047': __('identifierSchemeCodeUnspsc190501.51182047'),
	'identifierSchemeCodeUnspsc190501.51182048': __('identifierSchemeCodeUnspsc190501.51182048'),
	'identifierSchemeCodeUnspsc190501.51182049': __('identifierSchemeCodeUnspsc190501.51182049'),
	'identifierSchemeCodeUnspsc190501.51182050': __('identifierSchemeCodeUnspsc190501.51182050'),
	'identifierSchemeCodeUnspsc190501.51182051': __('identifierSchemeCodeUnspsc190501.51182051'),
	'identifierSchemeCodeUnspsc190501.51182052': __('identifierSchemeCodeUnspsc190501.51182052'),
	'identifierSchemeCodeUnspsc190501.51182053': __('identifierSchemeCodeUnspsc190501.51182053'),
	'identifierSchemeCodeUnspsc190501.51182054': __('identifierSchemeCodeUnspsc190501.51182054'),
	'identifierSchemeCodeUnspsc190501.51182055': __('identifierSchemeCodeUnspsc190501.51182055'),
	'identifierSchemeCodeUnspsc190501.51182056': __('identifierSchemeCodeUnspsc190501.51182056'),
	'identifierSchemeCodeUnspsc190501.51182057': __('identifierSchemeCodeUnspsc190501.51182057'),
	'identifierSchemeCodeUnspsc190501.51182058': __('identifierSchemeCodeUnspsc190501.51182058'),
	'identifierSchemeCodeUnspsc190501.51182059': __('identifierSchemeCodeUnspsc190501.51182059'),
	'identifierSchemeCodeUnspsc190501.51182060': __('identifierSchemeCodeUnspsc190501.51182060'),
	'identifierSchemeCodeUnspsc190501.51182061': __('identifierSchemeCodeUnspsc190501.51182061'),
	'identifierSchemeCodeUnspsc190501.51182062': __('identifierSchemeCodeUnspsc190501.51182062'),
	'identifierSchemeCodeUnspsc190501.51182063': __('identifierSchemeCodeUnspsc190501.51182063'),
	'identifierSchemeCodeUnspsc190501.51182064': __('identifierSchemeCodeUnspsc190501.51182064'),
	'identifierSchemeCodeUnspsc190501.51182065': __('identifierSchemeCodeUnspsc190501.51182065'),
	'identifierSchemeCodeUnspsc190501.51182066': __('identifierSchemeCodeUnspsc190501.51182066'),
	'identifierSchemeCodeUnspsc190501.51182067': __('identifierSchemeCodeUnspsc190501.51182067'),
	'identifierSchemeCodeUnspsc190501.51182068': __('identifierSchemeCodeUnspsc190501.51182068'),
	'identifierSchemeCodeUnspsc190501.51182069': __('identifierSchemeCodeUnspsc190501.51182069'),
	'identifierSchemeCodeUnspsc190501.51182070': __('identifierSchemeCodeUnspsc190501.51182070'),
	'identifierSchemeCodeUnspsc190501.51182071': __('identifierSchemeCodeUnspsc190501.51182071'),
	'identifierSchemeCodeUnspsc190501.51182072': __('identifierSchemeCodeUnspsc190501.51182072'),
	'identifierSchemeCodeUnspsc190501.51182073': __('identifierSchemeCodeUnspsc190501.51182073'),
	'identifierSchemeCodeUnspsc190501.51182074': __('identifierSchemeCodeUnspsc190501.51182074'),
	'identifierSchemeCodeUnspsc190501.51182075': __('identifierSchemeCodeUnspsc190501.51182075'),
	'identifierSchemeCodeUnspsc190501.51182076': __('identifierSchemeCodeUnspsc190501.51182076'),
	'identifierSchemeCodeUnspsc190501.51182077': __('identifierSchemeCodeUnspsc190501.51182077'),
	'identifierSchemeCodeUnspsc190501.51182078': __('identifierSchemeCodeUnspsc190501.51182078'),
	'identifierSchemeCodeUnspsc190501.51182079': __('identifierSchemeCodeUnspsc190501.51182079'),
	'identifierSchemeCodeUnspsc190501.51182100': __('identifierSchemeCodeUnspsc190501.51182100'),
	'identifierSchemeCodeUnspsc190501.51182101': __('identifierSchemeCodeUnspsc190501.51182101'),
	'identifierSchemeCodeUnspsc190501.51182102': __('identifierSchemeCodeUnspsc190501.51182102'),
	'identifierSchemeCodeUnspsc190501.51182103': __('identifierSchemeCodeUnspsc190501.51182103'),
	'identifierSchemeCodeUnspsc190501.51182104': __('identifierSchemeCodeUnspsc190501.51182104'),
	'identifierSchemeCodeUnspsc190501.51182105': __('identifierSchemeCodeUnspsc190501.51182105'),
	'identifierSchemeCodeUnspsc190501.51182106': __('identifierSchemeCodeUnspsc190501.51182106'),
	'identifierSchemeCodeUnspsc190501.51182107': __('identifierSchemeCodeUnspsc190501.51182107'),
	'identifierSchemeCodeUnspsc190501.51182108': __('identifierSchemeCodeUnspsc190501.51182108'),
	'identifierSchemeCodeUnspsc190501.51182109': __('identifierSchemeCodeUnspsc190501.51182109'),
	'identifierSchemeCodeUnspsc190501.51182110': __('identifierSchemeCodeUnspsc190501.51182110'),
	'identifierSchemeCodeUnspsc190501.51182200': __('identifierSchemeCodeUnspsc190501.51182200'),
	'identifierSchemeCodeUnspsc190501.51182201': __('identifierSchemeCodeUnspsc190501.51182201'),
	'identifierSchemeCodeUnspsc190501.51182202': __('identifierSchemeCodeUnspsc190501.51182202'),
	'identifierSchemeCodeUnspsc190501.51182203': __('identifierSchemeCodeUnspsc190501.51182203'),
	'identifierSchemeCodeUnspsc190501.51182204': __('identifierSchemeCodeUnspsc190501.51182204'),
	'identifierSchemeCodeUnspsc190501.51182205': __('identifierSchemeCodeUnspsc190501.51182205'),
	'identifierSchemeCodeUnspsc190501.51182206': __('identifierSchemeCodeUnspsc190501.51182206'),
	'identifierSchemeCodeUnspsc190501.51182207': __('identifierSchemeCodeUnspsc190501.51182207'),
	'identifierSchemeCodeUnspsc190501.51182208': __('identifierSchemeCodeUnspsc190501.51182208'),
	'identifierSchemeCodeUnspsc190501.51182209': __('identifierSchemeCodeUnspsc190501.51182209'),
	'identifierSchemeCodeUnspsc190501.51182210': __('identifierSchemeCodeUnspsc190501.51182210'),
	'identifierSchemeCodeUnspsc190501.51182211': __('identifierSchemeCodeUnspsc190501.51182211'),
	'identifierSchemeCodeUnspsc190501.51182212': __('identifierSchemeCodeUnspsc190501.51182212'),
	'identifierSchemeCodeUnspsc190501.51182213': __('identifierSchemeCodeUnspsc190501.51182213'),
	'identifierSchemeCodeUnspsc190501.51182214': __('identifierSchemeCodeUnspsc190501.51182214'),
	'identifierSchemeCodeUnspsc190501.51182215': __('identifierSchemeCodeUnspsc190501.51182215'),
	'identifierSchemeCodeUnspsc190501.51182216': __('identifierSchemeCodeUnspsc190501.51182216'),
	'identifierSchemeCodeUnspsc190501.51182217': __('identifierSchemeCodeUnspsc190501.51182217'),
	'identifierSchemeCodeUnspsc190501.51182218': __('identifierSchemeCodeUnspsc190501.51182218'),
	'identifierSchemeCodeUnspsc190501.51182219': __('identifierSchemeCodeUnspsc190501.51182219'),
	'identifierSchemeCodeUnspsc190501.51182220': __('identifierSchemeCodeUnspsc190501.51182220'),
	'identifierSchemeCodeUnspsc190501.51182221': __('identifierSchemeCodeUnspsc190501.51182221'),
	'identifierSchemeCodeUnspsc190501.51182222': __('identifierSchemeCodeUnspsc190501.51182222'),
	'identifierSchemeCodeUnspsc190501.51182223': __('identifierSchemeCodeUnspsc190501.51182223'),
	'identifierSchemeCodeUnspsc190501.51182300': __('identifierSchemeCodeUnspsc190501.51182300'),
	'identifierSchemeCodeUnspsc190501.51182301': __('identifierSchemeCodeUnspsc190501.51182301'),
	'identifierSchemeCodeUnspsc190501.51182302': __('identifierSchemeCodeUnspsc190501.51182302'),
	'identifierSchemeCodeUnspsc190501.51182303': __('identifierSchemeCodeUnspsc190501.51182303'),
	'identifierSchemeCodeUnspsc190501.51182305': __('identifierSchemeCodeUnspsc190501.51182305'),
	'identifierSchemeCodeUnspsc190501.51182306': __('identifierSchemeCodeUnspsc190501.51182306'),
	'identifierSchemeCodeUnspsc190501.51182400': __('identifierSchemeCodeUnspsc190501.51182400'),
	'identifierSchemeCodeUnspsc190501.51182401': __('identifierSchemeCodeUnspsc190501.51182401'),
	'identifierSchemeCodeUnspsc190501.51182405': __('identifierSchemeCodeUnspsc190501.51182405'),
	'identifierSchemeCodeUnspsc190501.51182406': __('identifierSchemeCodeUnspsc190501.51182406'),
	'identifierSchemeCodeUnspsc190501.51182408': __('identifierSchemeCodeUnspsc190501.51182408'),
	'identifierSchemeCodeUnspsc190501.51182410': __('identifierSchemeCodeUnspsc190501.51182410'),
	'identifierSchemeCodeUnspsc190501.51182412': __('identifierSchemeCodeUnspsc190501.51182412'),
	'identifierSchemeCodeUnspsc190501.51182413': __('identifierSchemeCodeUnspsc190501.51182413'),
	'identifierSchemeCodeUnspsc190501.51182415': __('identifierSchemeCodeUnspsc190501.51182415'),
	'identifierSchemeCodeUnspsc190501.51182416': __('identifierSchemeCodeUnspsc190501.51182416'),
	'identifierSchemeCodeUnspsc190501.51182417': __('identifierSchemeCodeUnspsc190501.51182417'),
	'identifierSchemeCodeUnspsc190501.51182418': __('identifierSchemeCodeUnspsc190501.51182418'),
	'identifierSchemeCodeUnspsc190501.51182423': __('identifierSchemeCodeUnspsc190501.51182423'),
	'identifierSchemeCodeUnspsc190501.51182425': __('identifierSchemeCodeUnspsc190501.51182425'),
	'identifierSchemeCodeUnspsc190501.51182426': __('identifierSchemeCodeUnspsc190501.51182426'),
	'identifierSchemeCodeUnspsc190501.51182427': __('identifierSchemeCodeUnspsc190501.51182427'),
	'identifierSchemeCodeUnspsc190501.51182428': __('identifierSchemeCodeUnspsc190501.51182428'),
	'identifierSchemeCodeUnspsc190501.51182429': __('identifierSchemeCodeUnspsc190501.51182429'),
	'identifierSchemeCodeUnspsc190501.51182430': __('identifierSchemeCodeUnspsc190501.51182430'),
	'identifierSchemeCodeUnspsc190501.51182431': __('identifierSchemeCodeUnspsc190501.51182431'),
	'identifierSchemeCodeUnspsc190501.51182432': __('identifierSchemeCodeUnspsc190501.51182432'),
	'identifierSchemeCodeUnspsc190501.51182433': __('identifierSchemeCodeUnspsc190501.51182433'),
	'identifierSchemeCodeUnspsc190501.51182434': __('identifierSchemeCodeUnspsc190501.51182434'),
	'identifierSchemeCodeUnspsc190501.51182435': __('identifierSchemeCodeUnspsc190501.51182435'),
	'identifierSchemeCodeUnspsc190501.51182436': __('identifierSchemeCodeUnspsc190501.51182436'),
	'identifierSchemeCodeUnspsc190501.51182437': __('identifierSchemeCodeUnspsc190501.51182437'),
	'identifierSchemeCodeUnspsc190501.51182438': __('identifierSchemeCodeUnspsc190501.51182438'),
	'identifierSchemeCodeUnspsc190501.51182439': __('identifierSchemeCodeUnspsc190501.51182439'),
	'identifierSchemeCodeUnspsc190501.51182440': __('identifierSchemeCodeUnspsc190501.51182440'),
	'identifierSchemeCodeUnspsc190501.51182500': __('identifierSchemeCodeUnspsc190501.51182500'),
	'identifierSchemeCodeUnspsc190501.51182501': __('identifierSchemeCodeUnspsc190501.51182501'),
	'identifierSchemeCodeUnspsc190501.51182600': __('identifierSchemeCodeUnspsc190501.51182600'),
	'identifierSchemeCodeUnspsc190501.51182601': __('identifierSchemeCodeUnspsc190501.51182601'),
	'identifierSchemeCodeUnspsc190501.51182700': __('identifierSchemeCodeUnspsc190501.51182700'),
	'identifierSchemeCodeUnspsc190501.51182701': __('identifierSchemeCodeUnspsc190501.51182701'),
	'identifierSchemeCodeUnspsc190501.51182702': __('identifierSchemeCodeUnspsc190501.51182702'),
	'identifierSchemeCodeUnspsc190501.51182703': __('identifierSchemeCodeUnspsc190501.51182703'),
	'identifierSchemeCodeUnspsc190501.51182704': __('identifierSchemeCodeUnspsc190501.51182704'),
	'identifierSchemeCodeUnspsc190501.51182705': __('identifierSchemeCodeUnspsc190501.51182705'),
	'identifierSchemeCodeUnspsc190501.51182706': __('identifierSchemeCodeUnspsc190501.51182706'),
	'identifierSchemeCodeUnspsc190501.51182707': __('identifierSchemeCodeUnspsc190501.51182707'),
	'identifierSchemeCodeUnspsc190501.51182708': __('identifierSchemeCodeUnspsc190501.51182708'),
	'identifierSchemeCodeUnspsc190501.51182709': __('identifierSchemeCodeUnspsc190501.51182709'),
	'identifierSchemeCodeUnspsc190501.51182800': __('identifierSchemeCodeUnspsc190501.51182800'),
	'identifierSchemeCodeUnspsc190501.51182801': __('identifierSchemeCodeUnspsc190501.51182801'),
	'identifierSchemeCodeUnspsc190501.51182900': __('identifierSchemeCodeUnspsc190501.51182900'),
	'identifierSchemeCodeUnspsc190501.51182901': __('identifierSchemeCodeUnspsc190501.51182901'),
	'identifierSchemeCodeUnspsc190501.51183000': __('identifierSchemeCodeUnspsc190501.51183000'),
	'identifierSchemeCodeUnspsc190501.51183001': __('identifierSchemeCodeUnspsc190501.51183001'),
	'identifierSchemeCodeUnspsc190501.51183100': __('identifierSchemeCodeUnspsc190501.51183100'),
	'identifierSchemeCodeUnspsc190501.51183101': __('identifierSchemeCodeUnspsc190501.51183101'),
	'identifierSchemeCodeUnspsc190501.51183102': __('identifierSchemeCodeUnspsc190501.51183102'),
	'identifierSchemeCodeUnspsc190501.51183103': __('identifierSchemeCodeUnspsc190501.51183103'),
	'identifierSchemeCodeUnspsc190501.51183104': __('identifierSchemeCodeUnspsc190501.51183104'),
	'identifierSchemeCodeUnspsc190501.51183105': __('identifierSchemeCodeUnspsc190501.51183105'),
	'identifierSchemeCodeUnspsc190501.51183106': __('identifierSchemeCodeUnspsc190501.51183106'),
	'identifierSchemeCodeUnspsc190501.51183107': __('identifierSchemeCodeUnspsc190501.51183107'),
	'identifierSchemeCodeUnspsc190501.51183108': __('identifierSchemeCodeUnspsc190501.51183108'),
	'identifierSchemeCodeUnspsc190501.51183109': __('identifierSchemeCodeUnspsc190501.51183109'),
	'identifierSchemeCodeUnspsc190501.51183110': __('identifierSchemeCodeUnspsc190501.51183110'),
	'identifierSchemeCodeUnspsc190501.51183111': __('identifierSchemeCodeUnspsc190501.51183111'),
	'identifierSchemeCodeUnspsc190501.51183112': __('identifierSchemeCodeUnspsc190501.51183112'),
	'identifierSchemeCodeUnspsc190501.51183113': __('identifierSchemeCodeUnspsc190501.51183113'),
	'identifierSchemeCodeUnspsc190501.51183200': __('identifierSchemeCodeUnspsc190501.51183200'),
	'identifierSchemeCodeUnspsc190501.51183201': __('identifierSchemeCodeUnspsc190501.51183201'),
	'identifierSchemeCodeUnspsc190501.51183202': __('identifierSchemeCodeUnspsc190501.51183202'),
	'identifierSchemeCodeUnspsc190501.51183203': __('identifierSchemeCodeUnspsc190501.51183203'),
	'identifierSchemeCodeUnspsc190501.51183204': __('identifierSchemeCodeUnspsc190501.51183204'),
	'identifierSchemeCodeUnspsc190501.51183300': __('identifierSchemeCodeUnspsc190501.51183300'),
	'identifierSchemeCodeUnspsc190501.51183301': __('identifierSchemeCodeUnspsc190501.51183301'),
	'identifierSchemeCodeUnspsc190501.51183302': __('identifierSchemeCodeUnspsc190501.51183302'),
	'identifierSchemeCodeUnspsc190501.51183303': __('identifierSchemeCodeUnspsc190501.51183303'),
	'identifierSchemeCodeUnspsc190501.51183304': __('identifierSchemeCodeUnspsc190501.51183304'),
	'identifierSchemeCodeUnspsc190501.51183305': __('identifierSchemeCodeUnspsc190501.51183305'),
	'identifierSchemeCodeUnspsc190501.51183306': __('identifierSchemeCodeUnspsc190501.51183306'),
	'identifierSchemeCodeUnspsc190501.51183307': __('identifierSchemeCodeUnspsc190501.51183307'),
	'identifierSchemeCodeUnspsc190501.51183308': __('identifierSchemeCodeUnspsc190501.51183308'),
	'identifierSchemeCodeUnspsc190501.51183309': __('identifierSchemeCodeUnspsc190501.51183309'),
	'identifierSchemeCodeUnspsc190501.51183400': __('identifierSchemeCodeUnspsc190501.51183400'),
	'identifierSchemeCodeUnspsc190501.51183401': __('identifierSchemeCodeUnspsc190501.51183401'),
	'identifierSchemeCodeUnspsc190501.51183402': __('identifierSchemeCodeUnspsc190501.51183402'),
	'identifierSchemeCodeUnspsc190501.51183403': __('identifierSchemeCodeUnspsc190501.51183403'),
	'identifierSchemeCodeUnspsc190501.51183404': __('identifierSchemeCodeUnspsc190501.51183404'),
	'identifierSchemeCodeUnspsc190501.51183405': __('identifierSchemeCodeUnspsc190501.51183405'),
	'identifierSchemeCodeUnspsc190501.51183406': __('identifierSchemeCodeUnspsc190501.51183406'),
	'identifierSchemeCodeUnspsc190501.51183407': __('identifierSchemeCodeUnspsc190501.51183407'),
	'identifierSchemeCodeUnspsc190501.51183500': __('identifierSchemeCodeUnspsc190501.51183500'),
	'identifierSchemeCodeUnspsc190501.51183501': __('identifierSchemeCodeUnspsc190501.51183501'),
	'identifierSchemeCodeUnspsc190501.51183502': __('identifierSchemeCodeUnspsc190501.51183502'),
	'identifierSchemeCodeUnspsc190501.51183503': __('identifierSchemeCodeUnspsc190501.51183503'),
	'identifierSchemeCodeUnspsc190501.51183504': __('identifierSchemeCodeUnspsc190501.51183504'),
	'identifierSchemeCodeUnspsc190501.51183505': __('identifierSchemeCodeUnspsc190501.51183505'),
	'identifierSchemeCodeUnspsc190501.51183506': __('identifierSchemeCodeUnspsc190501.51183506'),
	'identifierSchemeCodeUnspsc190501.51183507': __('identifierSchemeCodeUnspsc190501.51183507'),
	'identifierSchemeCodeUnspsc190501.51183508': __('identifierSchemeCodeUnspsc190501.51183508'),
	'identifierSchemeCodeUnspsc190501.51183509': __('identifierSchemeCodeUnspsc190501.51183509'),
	'identifierSchemeCodeUnspsc190501.51183510': __('identifierSchemeCodeUnspsc190501.51183510'),
	'identifierSchemeCodeUnspsc190501.51183511': __('identifierSchemeCodeUnspsc190501.51183511'),
	'identifierSchemeCodeUnspsc190501.51183512': __('identifierSchemeCodeUnspsc190501.51183512'),
	'identifierSchemeCodeUnspsc190501.51183513': __('identifierSchemeCodeUnspsc190501.51183513'),
	'identifierSchemeCodeUnspsc190501.51183600': __('identifierSchemeCodeUnspsc190501.51183600'),
	'identifierSchemeCodeUnspsc190501.51183601': __('identifierSchemeCodeUnspsc190501.51183601'),
	'identifierSchemeCodeUnspsc190501.51183602': __('identifierSchemeCodeUnspsc190501.51183602'),
	'identifierSchemeCodeUnspsc190501.51183603': __('identifierSchemeCodeUnspsc190501.51183603'),
	'identifierSchemeCodeUnspsc190501.51183604': __('identifierSchemeCodeUnspsc190501.51183604'),
	'identifierSchemeCodeUnspsc190501.51183605': __('identifierSchemeCodeUnspsc190501.51183605'),
	'identifierSchemeCodeUnspsc190501.51183606': __('identifierSchemeCodeUnspsc190501.51183606'),
	'identifierSchemeCodeUnspsc190501.51183607': __('identifierSchemeCodeUnspsc190501.51183607'),
	'identifierSchemeCodeUnspsc190501.51183608': __('identifierSchemeCodeUnspsc190501.51183608'),
	'identifierSchemeCodeUnspsc190501.51183609': __('identifierSchemeCodeUnspsc190501.51183609'),
	'identifierSchemeCodeUnspsc190501.51183610': __('identifierSchemeCodeUnspsc190501.51183610'),
	'identifierSchemeCodeUnspsc190501.51183611': __('identifierSchemeCodeUnspsc190501.51183611'),
	'identifierSchemeCodeUnspsc190501.51183612': __('identifierSchemeCodeUnspsc190501.51183612'),
	'identifierSchemeCodeUnspsc190501.51183613': __('identifierSchemeCodeUnspsc190501.51183613'),
	'identifierSchemeCodeUnspsc190501.51183614': __('identifierSchemeCodeUnspsc190501.51183614'),
	'identifierSchemeCodeUnspsc190501.51183615': __('identifierSchemeCodeUnspsc190501.51183615'),
	'identifierSchemeCodeUnspsc190501.51183616': __('identifierSchemeCodeUnspsc190501.51183616'),
	'identifierSchemeCodeUnspsc190501.51183617': __('identifierSchemeCodeUnspsc190501.51183617'),
	'identifierSchemeCodeUnspsc190501.51183618': __('identifierSchemeCodeUnspsc190501.51183618'),
	'identifierSchemeCodeUnspsc190501.51183619': __('identifierSchemeCodeUnspsc190501.51183619'),
	'identifierSchemeCodeUnspsc190501.51183620': __('identifierSchemeCodeUnspsc190501.51183620'),
	'identifierSchemeCodeUnspsc190501.51183621': __('identifierSchemeCodeUnspsc190501.51183621'),
	'identifierSchemeCodeUnspsc190501.51183700': __('identifierSchemeCodeUnspsc190501.51183700'),
	'identifierSchemeCodeUnspsc190501.51183701': __('identifierSchemeCodeUnspsc190501.51183701'),
	'identifierSchemeCodeUnspsc190501.51183702': __('identifierSchemeCodeUnspsc190501.51183702'),
	'identifierSchemeCodeUnspsc190501.51183703': __('identifierSchemeCodeUnspsc190501.51183703'),
	'identifierSchemeCodeUnspsc190501.51183704': __('identifierSchemeCodeUnspsc190501.51183704'),
	'identifierSchemeCodeUnspsc190501.51183705': __('identifierSchemeCodeUnspsc190501.51183705'),
	'identifierSchemeCodeUnspsc190501.51183706': __('identifierSchemeCodeUnspsc190501.51183706'),
	'identifierSchemeCodeUnspsc190501.51183707': __('identifierSchemeCodeUnspsc190501.51183707'),
	'identifierSchemeCodeUnspsc190501.51183708': __('identifierSchemeCodeUnspsc190501.51183708'),
	'identifierSchemeCodeUnspsc190501.51183709': __('identifierSchemeCodeUnspsc190501.51183709'),
	'identifierSchemeCodeUnspsc190501.51183800': __('identifierSchemeCodeUnspsc190501.51183800'),
	'identifierSchemeCodeUnspsc190501.51183801': __('identifierSchemeCodeUnspsc190501.51183801'),
	'identifierSchemeCodeUnspsc190501.51183802': __('identifierSchemeCodeUnspsc190501.51183802'),
	'identifierSchemeCodeUnspsc190501.51183803': __('identifierSchemeCodeUnspsc190501.51183803'),
	'identifierSchemeCodeUnspsc190501.51183900': __('identifierSchemeCodeUnspsc190501.51183900'),
	'identifierSchemeCodeUnspsc190501.51183901': __('identifierSchemeCodeUnspsc190501.51183901'),
	'identifierSchemeCodeUnspsc190501.51183902': __('identifierSchemeCodeUnspsc190501.51183902'),
	'identifierSchemeCodeUnspsc190501.51183903': __('identifierSchemeCodeUnspsc190501.51183903'),
	'identifierSchemeCodeUnspsc190501.51184000': __('identifierSchemeCodeUnspsc190501.51184000'),
	'identifierSchemeCodeUnspsc190501.51184001': __('identifierSchemeCodeUnspsc190501.51184001'),
	'identifierSchemeCodeUnspsc190501.51184002': __('identifierSchemeCodeUnspsc190501.51184002'),
	'identifierSchemeCodeUnspsc190501.51184003': __('identifierSchemeCodeUnspsc190501.51184003'),
	'identifierSchemeCodeUnspsc190501.51184004': __('identifierSchemeCodeUnspsc190501.51184004'),
	'identifierSchemeCodeUnspsc190501.51184005': __('identifierSchemeCodeUnspsc190501.51184005'),
	'identifierSchemeCodeUnspsc190501.51184006': __('identifierSchemeCodeUnspsc190501.51184006'),
	'identifierSchemeCodeUnspsc190501.51184007': __('identifierSchemeCodeUnspsc190501.51184007'),
	'identifierSchemeCodeUnspsc190501.51184008': __('identifierSchemeCodeUnspsc190501.51184008'),
	'identifierSchemeCodeUnspsc190501.51184009': __('identifierSchemeCodeUnspsc190501.51184009'),
	'identifierSchemeCodeUnspsc190501.51184010': __('identifierSchemeCodeUnspsc190501.51184010'),
	'identifierSchemeCodeUnspsc190501.51184011': __('identifierSchemeCodeUnspsc190501.51184011'),
	'identifierSchemeCodeUnspsc190501.51184012': __('identifierSchemeCodeUnspsc190501.51184012'),
	'identifierSchemeCodeUnspsc190501.51184013': __('identifierSchemeCodeUnspsc190501.51184013'),
	'identifierSchemeCodeUnspsc190501.51184014': __('identifierSchemeCodeUnspsc190501.51184014'),
	'identifierSchemeCodeUnspsc190501.51184015': __('identifierSchemeCodeUnspsc190501.51184015'),
	'identifierSchemeCodeUnspsc190501.51184016': __('identifierSchemeCodeUnspsc190501.51184016'),
	'identifierSchemeCodeUnspsc190501.51190000': __('identifierSchemeCodeUnspsc190501.51190000'),
	'identifierSchemeCodeUnspsc190501.51191500': __('identifierSchemeCodeUnspsc190501.51191500'),
	'identifierSchemeCodeUnspsc190501.51191501': __('identifierSchemeCodeUnspsc190501.51191501'),
	'identifierSchemeCodeUnspsc190501.51191502': __('identifierSchemeCodeUnspsc190501.51191502'),
	'identifierSchemeCodeUnspsc190501.51191503': __('identifierSchemeCodeUnspsc190501.51191503'),
	'identifierSchemeCodeUnspsc190501.51191504': __('identifierSchemeCodeUnspsc190501.51191504'),
	'identifierSchemeCodeUnspsc190501.51191505': __('identifierSchemeCodeUnspsc190501.51191505'),
	'identifierSchemeCodeUnspsc190501.51191506': __('identifierSchemeCodeUnspsc190501.51191506'),
	'identifierSchemeCodeUnspsc190501.51191507': __('identifierSchemeCodeUnspsc190501.51191507'),
	'identifierSchemeCodeUnspsc190501.51191508': __('identifierSchemeCodeUnspsc190501.51191508'),
	'identifierSchemeCodeUnspsc190501.51191509': __('identifierSchemeCodeUnspsc190501.51191509'),
	'identifierSchemeCodeUnspsc190501.51191511': __('identifierSchemeCodeUnspsc190501.51191511'),
	'identifierSchemeCodeUnspsc190501.51191515': __('identifierSchemeCodeUnspsc190501.51191515'),
	'identifierSchemeCodeUnspsc190501.51191516': __('identifierSchemeCodeUnspsc190501.51191516'),
	'identifierSchemeCodeUnspsc190501.51191517': __('identifierSchemeCodeUnspsc190501.51191517'),
	'identifierSchemeCodeUnspsc190501.51191518': __('identifierSchemeCodeUnspsc190501.51191518'),
	'identifierSchemeCodeUnspsc190501.51191519': __('identifierSchemeCodeUnspsc190501.51191519'),
	'identifierSchemeCodeUnspsc190501.51191520': __('identifierSchemeCodeUnspsc190501.51191520'),
	'identifierSchemeCodeUnspsc190501.51191523': __('identifierSchemeCodeUnspsc190501.51191523'),
	'identifierSchemeCodeUnspsc190501.51191524': __('identifierSchemeCodeUnspsc190501.51191524'),
	'identifierSchemeCodeUnspsc190501.51191526': __('identifierSchemeCodeUnspsc190501.51191526'),
	'identifierSchemeCodeUnspsc190501.51191527': __('identifierSchemeCodeUnspsc190501.51191527'),
	'identifierSchemeCodeUnspsc190501.51191528': __('identifierSchemeCodeUnspsc190501.51191528'),
	'identifierSchemeCodeUnspsc190501.51191529': __('identifierSchemeCodeUnspsc190501.51191529'),
	'identifierSchemeCodeUnspsc190501.51191530': __('identifierSchemeCodeUnspsc190501.51191530'),
	'identifierSchemeCodeUnspsc190501.51191531': __('identifierSchemeCodeUnspsc190501.51191531'),
	'identifierSchemeCodeUnspsc190501.51191532': __('identifierSchemeCodeUnspsc190501.51191532'),
	'identifierSchemeCodeUnspsc190501.51191533': __('identifierSchemeCodeUnspsc190501.51191533'),
	'identifierSchemeCodeUnspsc190501.51191534': __('identifierSchemeCodeUnspsc190501.51191534'),
	'identifierSchemeCodeUnspsc190501.51191535': __('identifierSchemeCodeUnspsc190501.51191535'),
	'identifierSchemeCodeUnspsc190501.51191536': __('identifierSchemeCodeUnspsc190501.51191536'),
	'identifierSchemeCodeUnspsc190501.51191537': __('identifierSchemeCodeUnspsc190501.51191537'),
	'identifierSchemeCodeUnspsc190501.51191538': __('identifierSchemeCodeUnspsc190501.51191538'),
	'identifierSchemeCodeUnspsc190501.51191539': __('identifierSchemeCodeUnspsc190501.51191539'),
	'identifierSchemeCodeUnspsc190501.51191540': __('identifierSchemeCodeUnspsc190501.51191540'),
	'identifierSchemeCodeUnspsc190501.51191541': __('identifierSchemeCodeUnspsc190501.51191541'),
	'identifierSchemeCodeUnspsc190501.51191542': __('identifierSchemeCodeUnspsc190501.51191542'),
	'identifierSchemeCodeUnspsc190501.51191543': __('identifierSchemeCodeUnspsc190501.51191543'),
	'identifierSchemeCodeUnspsc190501.51191544': __('identifierSchemeCodeUnspsc190501.51191544'),
	'identifierSchemeCodeUnspsc190501.51191545': __('identifierSchemeCodeUnspsc190501.51191545'),
	'identifierSchemeCodeUnspsc190501.51191546': __('identifierSchemeCodeUnspsc190501.51191546'),
	'identifierSchemeCodeUnspsc190501.51191547': __('identifierSchemeCodeUnspsc190501.51191547'),
	'identifierSchemeCodeUnspsc190501.51191548': __('identifierSchemeCodeUnspsc190501.51191548'),
	'identifierSchemeCodeUnspsc190501.51191549': __('identifierSchemeCodeUnspsc190501.51191549'),
	'identifierSchemeCodeUnspsc190501.51191550': __('identifierSchemeCodeUnspsc190501.51191550'),
	'identifierSchemeCodeUnspsc190501.51191551': __('identifierSchemeCodeUnspsc190501.51191551'),
	'identifierSchemeCodeUnspsc190501.51191552': __('identifierSchemeCodeUnspsc190501.51191552'),
	'identifierSchemeCodeUnspsc190501.51191553': __('identifierSchemeCodeUnspsc190501.51191553'),
	'identifierSchemeCodeUnspsc190501.51191554': __('identifierSchemeCodeUnspsc190501.51191554'),
	'identifierSchemeCodeUnspsc190501.51191555': __('identifierSchemeCodeUnspsc190501.51191555'),
	'identifierSchemeCodeUnspsc190501.51191556': __('identifierSchemeCodeUnspsc190501.51191556'),
	'identifierSchemeCodeUnspsc190501.51191557': __('identifierSchemeCodeUnspsc190501.51191557'),
	'identifierSchemeCodeUnspsc190501.51191558': __('identifierSchemeCodeUnspsc190501.51191558'),
	'identifierSchemeCodeUnspsc190501.51191559': __('identifierSchemeCodeUnspsc190501.51191559'),
	'identifierSchemeCodeUnspsc190501.51191560': __('identifierSchemeCodeUnspsc190501.51191560'),
	'identifierSchemeCodeUnspsc190501.51191561': __('identifierSchemeCodeUnspsc190501.51191561'),
	'identifierSchemeCodeUnspsc190501.51191562': __('identifierSchemeCodeUnspsc190501.51191562'),
	'identifierSchemeCodeUnspsc190501.51191563': __('identifierSchemeCodeUnspsc190501.51191563'),
	'identifierSchemeCodeUnspsc190501.51191564': __('identifierSchemeCodeUnspsc190501.51191564'),
	'identifierSchemeCodeUnspsc190501.51191565': __('identifierSchemeCodeUnspsc190501.51191565'),
	'identifierSchemeCodeUnspsc190501.51191566': __('identifierSchemeCodeUnspsc190501.51191566'),
	'identifierSchemeCodeUnspsc190501.51191567': __('identifierSchemeCodeUnspsc190501.51191567'),
	'identifierSchemeCodeUnspsc190501.51191568': __('identifierSchemeCodeUnspsc190501.51191568'),
	'identifierSchemeCodeUnspsc190501.51191569': __('identifierSchemeCodeUnspsc190501.51191569'),
	'identifierSchemeCodeUnspsc190501.51191570': __('identifierSchemeCodeUnspsc190501.51191570'),
	'identifierSchemeCodeUnspsc190501.51191571': __('identifierSchemeCodeUnspsc190501.51191571'),
	'identifierSchemeCodeUnspsc190501.51191572': __('identifierSchemeCodeUnspsc190501.51191572'),
	'identifierSchemeCodeUnspsc190501.51191573': __('identifierSchemeCodeUnspsc190501.51191573'),
	'identifierSchemeCodeUnspsc190501.51191574': __('identifierSchemeCodeUnspsc190501.51191574'),
	'identifierSchemeCodeUnspsc190501.51191575': __('identifierSchemeCodeUnspsc190501.51191575'),
	'identifierSchemeCodeUnspsc190501.51191576': __('identifierSchemeCodeUnspsc190501.51191576'),
	'identifierSchemeCodeUnspsc190501.51191577': __('identifierSchemeCodeUnspsc190501.51191577'),
	'identifierSchemeCodeUnspsc190501.51191578': __('identifierSchemeCodeUnspsc190501.51191578'),
	'identifierSchemeCodeUnspsc190501.51191579': __('identifierSchemeCodeUnspsc190501.51191579'),
	'identifierSchemeCodeUnspsc190501.51191580': __('identifierSchemeCodeUnspsc190501.51191580'),
	'identifierSchemeCodeUnspsc190501.51191581': __('identifierSchemeCodeUnspsc190501.51191581'),
	'identifierSchemeCodeUnspsc190501.51191582': __('identifierSchemeCodeUnspsc190501.51191582'),
	'identifierSchemeCodeUnspsc190501.51191583': __('identifierSchemeCodeUnspsc190501.51191583'),
	'identifierSchemeCodeUnspsc190501.51191600': __('identifierSchemeCodeUnspsc190501.51191600'),
	'identifierSchemeCodeUnspsc190501.51191602': __('identifierSchemeCodeUnspsc190501.51191602'),
	'identifierSchemeCodeUnspsc190501.51191603': __('identifierSchemeCodeUnspsc190501.51191603'),
	'identifierSchemeCodeUnspsc190501.51191604': __('identifierSchemeCodeUnspsc190501.51191604'),
	'identifierSchemeCodeUnspsc190501.51191605': __('identifierSchemeCodeUnspsc190501.51191605'),
	'identifierSchemeCodeUnspsc190501.51191606': __('identifierSchemeCodeUnspsc190501.51191606'),
	'identifierSchemeCodeUnspsc190501.51191700': __('identifierSchemeCodeUnspsc190501.51191700'),
	'identifierSchemeCodeUnspsc190501.51191701': __('identifierSchemeCodeUnspsc190501.51191701'),
	'identifierSchemeCodeUnspsc190501.51191704': __('identifierSchemeCodeUnspsc190501.51191704'),
	'identifierSchemeCodeUnspsc190501.51191705': __('identifierSchemeCodeUnspsc190501.51191705'),
	'identifierSchemeCodeUnspsc190501.51191706': __('identifierSchemeCodeUnspsc190501.51191706'),
	'identifierSchemeCodeUnspsc190501.51191707': __('identifierSchemeCodeUnspsc190501.51191707'),
	'identifierSchemeCodeUnspsc190501.51191800': __('identifierSchemeCodeUnspsc190501.51191800'),
	'identifierSchemeCodeUnspsc190501.51191802': __('identifierSchemeCodeUnspsc190501.51191802'),
	'identifierSchemeCodeUnspsc190501.51191804': __('identifierSchemeCodeUnspsc190501.51191804'),
	'identifierSchemeCodeUnspsc190501.51191805': __('identifierSchemeCodeUnspsc190501.51191805'),
	'identifierSchemeCodeUnspsc190501.51191900': __('identifierSchemeCodeUnspsc190501.51191900'),
	'identifierSchemeCodeUnspsc190501.51191904': __('identifierSchemeCodeUnspsc190501.51191904'),
	'identifierSchemeCodeUnspsc190501.51191909': __('identifierSchemeCodeUnspsc190501.51191909'),
	'identifierSchemeCodeUnspsc190501.51191912': __('identifierSchemeCodeUnspsc190501.51191912'),
	'identifierSchemeCodeUnspsc190501.51191913': __('identifierSchemeCodeUnspsc190501.51191913'),
	'identifierSchemeCodeUnspsc190501.51191914': __('identifierSchemeCodeUnspsc190501.51191914'),
	'identifierSchemeCodeUnspsc190501.51191915': __('identifierSchemeCodeUnspsc190501.51191915'),
	'identifierSchemeCodeUnspsc190501.51191916': __('identifierSchemeCodeUnspsc190501.51191916'),
	'identifierSchemeCodeUnspsc190501.51191917': __('identifierSchemeCodeUnspsc190501.51191917'),
	'identifierSchemeCodeUnspsc190501.51191918': __('identifierSchemeCodeUnspsc190501.51191918'),
	'identifierSchemeCodeUnspsc190501.51191919': __('identifierSchemeCodeUnspsc190501.51191919'),
	'identifierSchemeCodeUnspsc190501.51191920': __('identifierSchemeCodeUnspsc190501.51191920'),
	'identifierSchemeCodeUnspsc190501.51191921': __('identifierSchemeCodeUnspsc190501.51191921'),
	'identifierSchemeCodeUnspsc190501.51191922': __('identifierSchemeCodeUnspsc190501.51191922'),
	'identifierSchemeCodeUnspsc190501.51191923': __('identifierSchemeCodeUnspsc190501.51191923'),
	'identifierSchemeCodeUnspsc190501.51191924': __('identifierSchemeCodeUnspsc190501.51191924'),
	'identifierSchemeCodeUnspsc190501.51191925': __('identifierSchemeCodeUnspsc190501.51191925'),
	'identifierSchemeCodeUnspsc190501.51191926': __('identifierSchemeCodeUnspsc190501.51191926'),
	'identifierSchemeCodeUnspsc190501.51191928': __('identifierSchemeCodeUnspsc190501.51191928'),
	'identifierSchemeCodeUnspsc190501.51191930': __('identifierSchemeCodeUnspsc190501.51191930'),
	'identifierSchemeCodeUnspsc190501.51191932': __('identifierSchemeCodeUnspsc190501.51191932'),
	'identifierSchemeCodeUnspsc190501.51191934': __('identifierSchemeCodeUnspsc190501.51191934'),
	'identifierSchemeCodeUnspsc190501.51191936': __('identifierSchemeCodeUnspsc190501.51191936'),
	'identifierSchemeCodeUnspsc190501.51191938': __('identifierSchemeCodeUnspsc190501.51191938'),
	'identifierSchemeCodeUnspsc190501.51191940': __('identifierSchemeCodeUnspsc190501.51191940'),
	'identifierSchemeCodeUnspsc190501.51191942': __('identifierSchemeCodeUnspsc190501.51191942'),
	'identifierSchemeCodeUnspsc190501.51191944': __('identifierSchemeCodeUnspsc190501.51191944'),
	'identifierSchemeCodeUnspsc190501.51191946': __('identifierSchemeCodeUnspsc190501.51191946'),
	'identifierSchemeCodeUnspsc190501.51191948': __('identifierSchemeCodeUnspsc190501.51191948'),
	'identifierSchemeCodeUnspsc190501.51191950': __('identifierSchemeCodeUnspsc190501.51191950'),
	'identifierSchemeCodeUnspsc190501.51191952': __('identifierSchemeCodeUnspsc190501.51191952'),
	'identifierSchemeCodeUnspsc190501.51191954': __('identifierSchemeCodeUnspsc190501.51191954'),
	'identifierSchemeCodeUnspsc190501.51191956': __('identifierSchemeCodeUnspsc190501.51191956'),
	'identifierSchemeCodeUnspsc190501.51191958': __('identifierSchemeCodeUnspsc190501.51191958'),
	'identifierSchemeCodeUnspsc190501.51191960': __('identifierSchemeCodeUnspsc190501.51191960'),
	'identifierSchemeCodeUnspsc190501.51191962': __('identifierSchemeCodeUnspsc190501.51191962'),
	'identifierSchemeCodeUnspsc190501.51191964': __('identifierSchemeCodeUnspsc190501.51191964'),
	'identifierSchemeCodeUnspsc190501.51192000': __('identifierSchemeCodeUnspsc190501.51192000'),
	'identifierSchemeCodeUnspsc190501.51192001': __('identifierSchemeCodeUnspsc190501.51192001'),
	'identifierSchemeCodeUnspsc190501.51192002': __('identifierSchemeCodeUnspsc190501.51192002'),
	'identifierSchemeCodeUnspsc190501.51192003': __('identifierSchemeCodeUnspsc190501.51192003'),
	'identifierSchemeCodeUnspsc190501.51192004': __('identifierSchemeCodeUnspsc190501.51192004'),
	'identifierSchemeCodeUnspsc190501.51192005': __('identifierSchemeCodeUnspsc190501.51192005'),
	'identifierSchemeCodeUnspsc190501.51192006': __('identifierSchemeCodeUnspsc190501.51192006'),
	'identifierSchemeCodeUnspsc190501.51192007': __('identifierSchemeCodeUnspsc190501.51192007'),
	'identifierSchemeCodeUnspsc190501.51192008': __('identifierSchemeCodeUnspsc190501.51192008'),
	'identifierSchemeCodeUnspsc190501.51192009': __('identifierSchemeCodeUnspsc190501.51192009'),
	'identifierSchemeCodeUnspsc190501.51192010': __('identifierSchemeCodeUnspsc190501.51192010'),
	'identifierSchemeCodeUnspsc190501.51192011': __('identifierSchemeCodeUnspsc190501.51192011'),
	'identifierSchemeCodeUnspsc190501.51192012': __('identifierSchemeCodeUnspsc190501.51192012'),
	'identifierSchemeCodeUnspsc190501.51192013': __('identifierSchemeCodeUnspsc190501.51192013'),
	'identifierSchemeCodeUnspsc190501.51192014': __('identifierSchemeCodeUnspsc190501.51192014'),
	'identifierSchemeCodeUnspsc190501.51192015': __('identifierSchemeCodeUnspsc190501.51192015'),
	'identifierSchemeCodeUnspsc190501.51192016': __('identifierSchemeCodeUnspsc190501.51192016'),
	'identifierSchemeCodeUnspsc190501.51192017': __('identifierSchemeCodeUnspsc190501.51192017'),
	'identifierSchemeCodeUnspsc190501.51192018': __('identifierSchemeCodeUnspsc190501.51192018'),
	'identifierSchemeCodeUnspsc190501.51192019': __('identifierSchemeCodeUnspsc190501.51192019'),
	'identifierSchemeCodeUnspsc190501.51192020': __('identifierSchemeCodeUnspsc190501.51192020'),
	'identifierSchemeCodeUnspsc190501.51192100': __('identifierSchemeCodeUnspsc190501.51192100'),
	'identifierSchemeCodeUnspsc190501.51192101': __('identifierSchemeCodeUnspsc190501.51192101'),
	'identifierSchemeCodeUnspsc190501.51192200': __('identifierSchemeCodeUnspsc190501.51192200'),
	'identifierSchemeCodeUnspsc190501.51192201': __('identifierSchemeCodeUnspsc190501.51192201'),
	'identifierSchemeCodeUnspsc190501.51192202': __('identifierSchemeCodeUnspsc190501.51192202'),
	'identifierSchemeCodeUnspsc190501.51192203': __('identifierSchemeCodeUnspsc190501.51192203'),
	'identifierSchemeCodeUnspsc190501.51192204': __('identifierSchemeCodeUnspsc190501.51192204'),
	'identifierSchemeCodeUnspsc190501.51192300': __('identifierSchemeCodeUnspsc190501.51192300'),
	'identifierSchemeCodeUnspsc190501.51192301': __('identifierSchemeCodeUnspsc190501.51192301'),
	'identifierSchemeCodeUnspsc190501.51192302': __('identifierSchemeCodeUnspsc190501.51192302'),
	'identifierSchemeCodeUnspsc190501.51192303': __('identifierSchemeCodeUnspsc190501.51192303'),
	'identifierSchemeCodeUnspsc190501.51192304': __('identifierSchemeCodeUnspsc190501.51192304'),
	'identifierSchemeCodeUnspsc190501.51192305': __('identifierSchemeCodeUnspsc190501.51192305'),
	'identifierSchemeCodeUnspsc190501.51192306': __('identifierSchemeCodeUnspsc190501.51192306'),
	'identifierSchemeCodeUnspsc190501.51192307': __('identifierSchemeCodeUnspsc190501.51192307'),
	'identifierSchemeCodeUnspsc190501.51192308': __('identifierSchemeCodeUnspsc190501.51192308'),
	'identifierSchemeCodeUnspsc190501.51192309': __('identifierSchemeCodeUnspsc190501.51192309'),
	'identifierSchemeCodeUnspsc190501.51192310': __('identifierSchemeCodeUnspsc190501.51192310'),
	'identifierSchemeCodeUnspsc190501.51192311': __('identifierSchemeCodeUnspsc190501.51192311'),
	'identifierSchemeCodeUnspsc190501.51192312': __('identifierSchemeCodeUnspsc190501.51192312'),
	'identifierSchemeCodeUnspsc190501.51192313': __('identifierSchemeCodeUnspsc190501.51192313'),
	'identifierSchemeCodeUnspsc190501.51192314': __('identifierSchemeCodeUnspsc190501.51192314'),
	'identifierSchemeCodeUnspsc190501.51192400': __('identifierSchemeCodeUnspsc190501.51192400'),
	'identifierSchemeCodeUnspsc190501.51192401': __('identifierSchemeCodeUnspsc190501.51192401'),
	'identifierSchemeCodeUnspsc190501.51192402': __('identifierSchemeCodeUnspsc190501.51192402'),
	'identifierSchemeCodeUnspsc190501.51192403': __('identifierSchemeCodeUnspsc190501.51192403'),
	'identifierSchemeCodeUnspsc190501.51192404': __('identifierSchemeCodeUnspsc190501.51192404'),
	'identifierSchemeCodeUnspsc190501.51192405': __('identifierSchemeCodeUnspsc190501.51192405'),
	'identifierSchemeCodeUnspsc190501.51192406': __('identifierSchemeCodeUnspsc190501.51192406'),
	'identifierSchemeCodeUnspsc190501.51192407': __('identifierSchemeCodeUnspsc190501.51192407'),
	'identifierSchemeCodeUnspsc190501.51192408': __('identifierSchemeCodeUnspsc190501.51192408'),
	'identifierSchemeCodeUnspsc190501.51192409': __('identifierSchemeCodeUnspsc190501.51192409'),
	'identifierSchemeCodeUnspsc190501.51192410': __('identifierSchemeCodeUnspsc190501.51192410'),
	'identifierSchemeCodeUnspsc190501.51192411': __('identifierSchemeCodeUnspsc190501.51192411'),
	'identifierSchemeCodeUnspsc190501.51192412': __('identifierSchemeCodeUnspsc190501.51192412'),
	'identifierSchemeCodeUnspsc190501.51192413': __('identifierSchemeCodeUnspsc190501.51192413'),
	'identifierSchemeCodeUnspsc190501.51192414': __('identifierSchemeCodeUnspsc190501.51192414'),
	'identifierSchemeCodeUnspsc190501.51192415': __('identifierSchemeCodeUnspsc190501.51192415'),
	'identifierSchemeCodeUnspsc190501.51192416': __('identifierSchemeCodeUnspsc190501.51192416'),
	'identifierSchemeCodeUnspsc190501.51192417': __('identifierSchemeCodeUnspsc190501.51192417'),
	'identifierSchemeCodeUnspsc190501.51192418': __('identifierSchemeCodeUnspsc190501.51192418'),
	'identifierSchemeCodeUnspsc190501.51192419': __('identifierSchemeCodeUnspsc190501.51192419'),
	'identifierSchemeCodeUnspsc190501.51192420': __('identifierSchemeCodeUnspsc190501.51192420'),
	'identifierSchemeCodeUnspsc190501.51192421': __('identifierSchemeCodeUnspsc190501.51192421'),
	'identifierSchemeCodeUnspsc190501.51192422': __('identifierSchemeCodeUnspsc190501.51192422'),
	'identifierSchemeCodeUnspsc190501.51192423': __('identifierSchemeCodeUnspsc190501.51192423'),
	'identifierSchemeCodeUnspsc190501.51192424': __('identifierSchemeCodeUnspsc190501.51192424'),
	'identifierSchemeCodeUnspsc190501.51192425': __('identifierSchemeCodeUnspsc190501.51192425'),
	'identifierSchemeCodeUnspsc190501.51192426': __('identifierSchemeCodeUnspsc190501.51192426'),
	'identifierSchemeCodeUnspsc190501.51192427': __('identifierSchemeCodeUnspsc190501.51192427'),
	'identifierSchemeCodeUnspsc190501.51192428': __('identifierSchemeCodeUnspsc190501.51192428'),
	'identifierSchemeCodeUnspsc190501.51192429': __('identifierSchemeCodeUnspsc190501.51192429'),
	'identifierSchemeCodeUnspsc190501.51192430': __('identifierSchemeCodeUnspsc190501.51192430'),
	'identifierSchemeCodeUnspsc190501.51192431': __('identifierSchemeCodeUnspsc190501.51192431'),
	'identifierSchemeCodeUnspsc190501.51192432': __('identifierSchemeCodeUnspsc190501.51192432'),
	'identifierSchemeCodeUnspsc190501.51192433': __('identifierSchemeCodeUnspsc190501.51192433'),
	'identifierSchemeCodeUnspsc190501.51192434': __('identifierSchemeCodeUnspsc190501.51192434'),
	'identifierSchemeCodeUnspsc190501.51192435': __('identifierSchemeCodeUnspsc190501.51192435'),
	'identifierSchemeCodeUnspsc190501.51192436': __('identifierSchemeCodeUnspsc190501.51192436'),
	'identifierSchemeCodeUnspsc190501.51192437': __('identifierSchemeCodeUnspsc190501.51192437'),
	'identifierSchemeCodeUnspsc190501.51192438': __('identifierSchemeCodeUnspsc190501.51192438'),
	'identifierSchemeCodeUnspsc190501.51192439': __('identifierSchemeCodeUnspsc190501.51192439'),
	'identifierSchemeCodeUnspsc190501.51192440': __('identifierSchemeCodeUnspsc190501.51192440'),
	'identifierSchemeCodeUnspsc190501.51192441': __('identifierSchemeCodeUnspsc190501.51192441'),
	'identifierSchemeCodeUnspsc190501.51192442': __('identifierSchemeCodeUnspsc190501.51192442'),
	'identifierSchemeCodeUnspsc190501.51192443': __('identifierSchemeCodeUnspsc190501.51192443'),
	'identifierSchemeCodeUnspsc190501.51192444': __('identifierSchemeCodeUnspsc190501.51192444'),
	'identifierSchemeCodeUnspsc190501.51192445': __('identifierSchemeCodeUnspsc190501.51192445'),
	'identifierSchemeCodeUnspsc190501.51192446': __('identifierSchemeCodeUnspsc190501.51192446'),
	'identifierSchemeCodeUnspsc190501.51192447': __('identifierSchemeCodeUnspsc190501.51192447'),
	'identifierSchemeCodeUnspsc190501.51192448': __('identifierSchemeCodeUnspsc190501.51192448'),
	'identifierSchemeCodeUnspsc190501.51192449': __('identifierSchemeCodeUnspsc190501.51192449'),
	'identifierSchemeCodeUnspsc190501.51192450': __('identifierSchemeCodeUnspsc190501.51192450'),
	'identifierSchemeCodeUnspsc190501.51192451': __('identifierSchemeCodeUnspsc190501.51192451'),
	'identifierSchemeCodeUnspsc190501.51192452': __('identifierSchemeCodeUnspsc190501.51192452'),
	'identifierSchemeCodeUnspsc190501.51192453': __('identifierSchemeCodeUnspsc190501.51192453'),
	'identifierSchemeCodeUnspsc190501.51192454': __('identifierSchemeCodeUnspsc190501.51192454'),
	'identifierSchemeCodeUnspsc190501.51192455': __('identifierSchemeCodeUnspsc190501.51192455'),
	'identifierSchemeCodeUnspsc190501.51192456': __('identifierSchemeCodeUnspsc190501.51192456'),
	'identifierSchemeCodeUnspsc190501.51192457': __('identifierSchemeCodeUnspsc190501.51192457'),
	'identifierSchemeCodeUnspsc190501.51192500': __('identifierSchemeCodeUnspsc190501.51192500'),
	'identifierSchemeCodeUnspsc190501.51192501': __('identifierSchemeCodeUnspsc190501.51192501'),
	'identifierSchemeCodeUnspsc190501.51192600': __('identifierSchemeCodeUnspsc190501.51192600'),
	'identifierSchemeCodeUnspsc190501.51192601': __('identifierSchemeCodeUnspsc190501.51192601'),
	'identifierSchemeCodeUnspsc190501.51200000': __('identifierSchemeCodeUnspsc190501.51200000'),
	'identifierSchemeCodeUnspsc190501.51201500': __('identifierSchemeCodeUnspsc190501.51201500'),
	'identifierSchemeCodeUnspsc190501.51201516': __('identifierSchemeCodeUnspsc190501.51201516'),
	'identifierSchemeCodeUnspsc190501.51201517': __('identifierSchemeCodeUnspsc190501.51201517'),
	'identifierSchemeCodeUnspsc190501.51201523': __('identifierSchemeCodeUnspsc190501.51201523'),
	'identifierSchemeCodeUnspsc190501.51201539': __('identifierSchemeCodeUnspsc190501.51201539'),
	'identifierSchemeCodeUnspsc190501.51201544': __('identifierSchemeCodeUnspsc190501.51201544'),
	'identifierSchemeCodeUnspsc190501.51201545': __('identifierSchemeCodeUnspsc190501.51201545'),
	'identifierSchemeCodeUnspsc190501.51201547': __('identifierSchemeCodeUnspsc190501.51201547'),
	'identifierSchemeCodeUnspsc190501.51201564': __('identifierSchemeCodeUnspsc190501.51201564'),
	'identifierSchemeCodeUnspsc190501.51201569': __('identifierSchemeCodeUnspsc190501.51201569'),
	'identifierSchemeCodeUnspsc190501.51201600': __('identifierSchemeCodeUnspsc190501.51201600'),
	'identifierSchemeCodeUnspsc190501.51201601': __('identifierSchemeCodeUnspsc190501.51201601'),
	'identifierSchemeCodeUnspsc190501.51201602': __('identifierSchemeCodeUnspsc190501.51201602'),
	'identifierSchemeCodeUnspsc190501.51201604': __('identifierSchemeCodeUnspsc190501.51201604'),
	'identifierSchemeCodeUnspsc190501.51201605': __('identifierSchemeCodeUnspsc190501.51201605'),
	'identifierSchemeCodeUnspsc190501.51201606': __('identifierSchemeCodeUnspsc190501.51201606'),
	'identifierSchemeCodeUnspsc190501.51201607': __('identifierSchemeCodeUnspsc190501.51201607'),
	'identifierSchemeCodeUnspsc190501.51201608': __('identifierSchemeCodeUnspsc190501.51201608'),
	'identifierSchemeCodeUnspsc190501.51201609': __('identifierSchemeCodeUnspsc190501.51201609'),
	'identifierSchemeCodeUnspsc190501.51201610': __('identifierSchemeCodeUnspsc190501.51201610'),
	'identifierSchemeCodeUnspsc190501.51201612': __('identifierSchemeCodeUnspsc190501.51201612'),
	'identifierSchemeCodeUnspsc190501.51201613': __('identifierSchemeCodeUnspsc190501.51201613'),
	'identifierSchemeCodeUnspsc190501.51201614': __('identifierSchemeCodeUnspsc190501.51201614'),
	'identifierSchemeCodeUnspsc190501.51201615': __('identifierSchemeCodeUnspsc190501.51201615'),
	'identifierSchemeCodeUnspsc190501.51201616': __('identifierSchemeCodeUnspsc190501.51201616'),
	'identifierSchemeCodeUnspsc190501.51201617': __('identifierSchemeCodeUnspsc190501.51201617'),
	'identifierSchemeCodeUnspsc190501.51201618': __('identifierSchemeCodeUnspsc190501.51201618'),
	'identifierSchemeCodeUnspsc190501.51201619': __('identifierSchemeCodeUnspsc190501.51201619'),
	'identifierSchemeCodeUnspsc190501.51201620': __('identifierSchemeCodeUnspsc190501.51201620'),
	'identifierSchemeCodeUnspsc190501.51201621': __('identifierSchemeCodeUnspsc190501.51201621'),
	'identifierSchemeCodeUnspsc190501.51201623': __('identifierSchemeCodeUnspsc190501.51201623'),
	'identifierSchemeCodeUnspsc190501.51201624': __('identifierSchemeCodeUnspsc190501.51201624'),
	'identifierSchemeCodeUnspsc190501.51201625': __('identifierSchemeCodeUnspsc190501.51201625'),
	'identifierSchemeCodeUnspsc190501.51201626': __('identifierSchemeCodeUnspsc190501.51201626'),
	'identifierSchemeCodeUnspsc190501.51201627': __('identifierSchemeCodeUnspsc190501.51201627'),
	'identifierSchemeCodeUnspsc190501.51201628': __('identifierSchemeCodeUnspsc190501.51201628'),
	'identifierSchemeCodeUnspsc190501.51201629': __('identifierSchemeCodeUnspsc190501.51201629'),
	'identifierSchemeCodeUnspsc190501.51201631': __('identifierSchemeCodeUnspsc190501.51201631'),
	'identifierSchemeCodeUnspsc190501.51201632': __('identifierSchemeCodeUnspsc190501.51201632'),
	'identifierSchemeCodeUnspsc190501.51201633': __('identifierSchemeCodeUnspsc190501.51201633'),
	'identifierSchemeCodeUnspsc190501.51201634': __('identifierSchemeCodeUnspsc190501.51201634'),
	'identifierSchemeCodeUnspsc190501.51201636': __('identifierSchemeCodeUnspsc190501.51201636'),
	'identifierSchemeCodeUnspsc190501.51201638': __('identifierSchemeCodeUnspsc190501.51201638'),
	'identifierSchemeCodeUnspsc190501.51201639': __('identifierSchemeCodeUnspsc190501.51201639'),
	'identifierSchemeCodeUnspsc190501.51201646': __('identifierSchemeCodeUnspsc190501.51201646'),
	'identifierSchemeCodeUnspsc190501.51201647': __('identifierSchemeCodeUnspsc190501.51201647'),
	'identifierSchemeCodeUnspsc190501.51201648': __('identifierSchemeCodeUnspsc190501.51201648'),
	'identifierSchemeCodeUnspsc190501.51201649': __('identifierSchemeCodeUnspsc190501.51201649'),
	'identifierSchemeCodeUnspsc190501.51201700': __('identifierSchemeCodeUnspsc190501.51201700'),
	'identifierSchemeCodeUnspsc190501.51201702': __('identifierSchemeCodeUnspsc190501.51201702'),
	'identifierSchemeCodeUnspsc190501.51201703': __('identifierSchemeCodeUnspsc190501.51201703'),
	'identifierSchemeCodeUnspsc190501.51201704': __('identifierSchemeCodeUnspsc190501.51201704'),
	'identifierSchemeCodeUnspsc190501.51201705': __('identifierSchemeCodeUnspsc190501.51201705'),
	'identifierSchemeCodeUnspsc190501.51201800': __('identifierSchemeCodeUnspsc190501.51201800'),
	'identifierSchemeCodeUnspsc190501.51201801': __('identifierSchemeCodeUnspsc190501.51201801'),
	'identifierSchemeCodeUnspsc190501.51201802': __('identifierSchemeCodeUnspsc190501.51201802'),
	'identifierSchemeCodeUnspsc190501.51201803': __('identifierSchemeCodeUnspsc190501.51201803'),
	'identifierSchemeCodeUnspsc190501.51201805': __('identifierSchemeCodeUnspsc190501.51201805'),
	'identifierSchemeCodeUnspsc190501.51201806': __('identifierSchemeCodeUnspsc190501.51201806'),
	'identifierSchemeCodeUnspsc190501.51201808': __('identifierSchemeCodeUnspsc190501.51201808'),
	'identifierSchemeCodeUnspsc190501.51201810': __('identifierSchemeCodeUnspsc190501.51201810'),
	'identifierSchemeCodeUnspsc190501.51201811': __('identifierSchemeCodeUnspsc190501.51201811'),
	'identifierSchemeCodeUnspsc190501.51201812': __('identifierSchemeCodeUnspsc190501.51201812'),
	'identifierSchemeCodeUnspsc190501.51201813': __('identifierSchemeCodeUnspsc190501.51201813'),
	'identifierSchemeCodeUnspsc190501.51201814': __('identifierSchemeCodeUnspsc190501.51201814'),
	'identifierSchemeCodeUnspsc190501.51201815': __('identifierSchemeCodeUnspsc190501.51201815'),
	'identifierSchemeCodeUnspsc190501.51201816': __('identifierSchemeCodeUnspsc190501.51201816'),
	'identifierSchemeCodeUnspsc190501.51201817': __('identifierSchemeCodeUnspsc190501.51201817'),
	'identifierSchemeCodeUnspsc190501.51201818': __('identifierSchemeCodeUnspsc190501.51201818'),
	'identifierSchemeCodeUnspsc190501.51201819': __('identifierSchemeCodeUnspsc190501.51201819'),
	'identifierSchemeCodeUnspsc190501.51201820': __('identifierSchemeCodeUnspsc190501.51201820'),
	'identifierSchemeCodeUnspsc190501.51201821': __('identifierSchemeCodeUnspsc190501.51201821'),
	'identifierSchemeCodeUnspsc190501.51201822': __('identifierSchemeCodeUnspsc190501.51201822'),
	'identifierSchemeCodeUnspsc190501.51201823': __('identifierSchemeCodeUnspsc190501.51201823'),
	'identifierSchemeCodeUnspsc190501.51201824': __('identifierSchemeCodeUnspsc190501.51201824'),
	'identifierSchemeCodeUnspsc190501.51201825': __('identifierSchemeCodeUnspsc190501.51201825'),
	'identifierSchemeCodeUnspsc190501.51201826': __('identifierSchemeCodeUnspsc190501.51201826'),
	'identifierSchemeCodeUnspsc190501.51201827': __('identifierSchemeCodeUnspsc190501.51201827'),
	'identifierSchemeCodeUnspsc190501.51201828': __('identifierSchemeCodeUnspsc190501.51201828'),
	'identifierSchemeCodeUnspsc190501.51201829': __('identifierSchemeCodeUnspsc190501.51201829'),
	'identifierSchemeCodeUnspsc190501.51201830': __('identifierSchemeCodeUnspsc190501.51201830'),
	'identifierSchemeCodeUnspsc190501.51201900': __('identifierSchemeCodeUnspsc190501.51201900'),
	'identifierSchemeCodeUnspsc190501.51201901': __('identifierSchemeCodeUnspsc190501.51201901'),
	'identifierSchemeCodeUnspsc190501.51202000': __('identifierSchemeCodeUnspsc190501.51202000'),
	'identifierSchemeCodeUnspsc190501.51202001': __('identifierSchemeCodeUnspsc190501.51202001'),
	'identifierSchemeCodeUnspsc190501.51202002': __('identifierSchemeCodeUnspsc190501.51202002'),
	'identifierSchemeCodeUnspsc190501.51202003': __('identifierSchemeCodeUnspsc190501.51202003'),
	'identifierSchemeCodeUnspsc190501.51202004': __('identifierSchemeCodeUnspsc190501.51202004'),
	'identifierSchemeCodeUnspsc190501.51202005': __('identifierSchemeCodeUnspsc190501.51202005'),
	'identifierSchemeCodeUnspsc190501.51202100': __('identifierSchemeCodeUnspsc190501.51202100'),
	'identifierSchemeCodeUnspsc190501.51202101': __('identifierSchemeCodeUnspsc190501.51202101'),
	'identifierSchemeCodeUnspsc190501.51202200': __('identifierSchemeCodeUnspsc190501.51202200'),
	'identifierSchemeCodeUnspsc190501.51202201': __('identifierSchemeCodeUnspsc190501.51202201'),
	'identifierSchemeCodeUnspsc190501.51202202': __('identifierSchemeCodeUnspsc190501.51202202'),
	'identifierSchemeCodeUnspsc190501.51202203': __('identifierSchemeCodeUnspsc190501.51202203'),
	'identifierSchemeCodeUnspsc190501.51202204': __('identifierSchemeCodeUnspsc190501.51202204'),
	'identifierSchemeCodeUnspsc190501.51202205': __('identifierSchemeCodeUnspsc190501.51202205'),
	'identifierSchemeCodeUnspsc190501.51202206': __('identifierSchemeCodeUnspsc190501.51202206'),
	'identifierSchemeCodeUnspsc190501.51202207': __('identifierSchemeCodeUnspsc190501.51202207'),
	'identifierSchemeCodeUnspsc190501.51202300': __('identifierSchemeCodeUnspsc190501.51202300'),
	'identifierSchemeCodeUnspsc190501.51202301': __('identifierSchemeCodeUnspsc190501.51202301'),
	'identifierSchemeCodeUnspsc190501.51202302': __('identifierSchemeCodeUnspsc190501.51202302'),
	'identifierSchemeCodeUnspsc190501.51202303': __('identifierSchemeCodeUnspsc190501.51202303'),
	'identifierSchemeCodeUnspsc190501.51202304': __('identifierSchemeCodeUnspsc190501.51202304'),
	'identifierSchemeCodeUnspsc190501.51202305': __('identifierSchemeCodeUnspsc190501.51202305'),
	'identifierSchemeCodeUnspsc190501.51202306': __('identifierSchemeCodeUnspsc190501.51202306'),
	'identifierSchemeCodeUnspsc190501.51202400': __('identifierSchemeCodeUnspsc190501.51202400'),
	'identifierSchemeCodeUnspsc190501.51202401': __('identifierSchemeCodeUnspsc190501.51202401'),
	'identifierSchemeCodeUnspsc190501.51202402': __('identifierSchemeCodeUnspsc190501.51202402'),
	'identifierSchemeCodeUnspsc190501.51202403': __('identifierSchemeCodeUnspsc190501.51202403'),
	'identifierSchemeCodeUnspsc190501.51202404': __('identifierSchemeCodeUnspsc190501.51202404'),
	'identifierSchemeCodeUnspsc190501.51202405': __('identifierSchemeCodeUnspsc190501.51202405'),
	'identifierSchemeCodeUnspsc190501.51202406': __('identifierSchemeCodeUnspsc190501.51202406'),
	'identifierSchemeCodeUnspsc190501.51202407': __('identifierSchemeCodeUnspsc190501.51202407'),
	'identifierSchemeCodeUnspsc190501.51202408': __('identifierSchemeCodeUnspsc190501.51202408'),
	'identifierSchemeCodeUnspsc190501.51202409': __('identifierSchemeCodeUnspsc190501.51202409'),
	'identifierSchemeCodeUnspsc190501.51202410': __('identifierSchemeCodeUnspsc190501.51202410'),
	'identifierSchemeCodeUnspsc190501.51202411': __('identifierSchemeCodeUnspsc190501.51202411'),
	'identifierSchemeCodeUnspsc190501.51202412': __('identifierSchemeCodeUnspsc190501.51202412'),
	'identifierSchemeCodeUnspsc190501.51202413': __('identifierSchemeCodeUnspsc190501.51202413'),
	'identifierSchemeCodeUnspsc190501.51202414': __('identifierSchemeCodeUnspsc190501.51202414'),
	'identifierSchemeCodeUnspsc190501.51202500': __('identifierSchemeCodeUnspsc190501.51202500'),
	'identifierSchemeCodeUnspsc190501.51202501': __('identifierSchemeCodeUnspsc190501.51202501'),
	'identifierSchemeCodeUnspsc190501.51202502': __('identifierSchemeCodeUnspsc190501.51202502'),
	'identifierSchemeCodeUnspsc190501.51202503': __('identifierSchemeCodeUnspsc190501.51202503'),
	'identifierSchemeCodeUnspsc190501.51202504': __('identifierSchemeCodeUnspsc190501.51202504'),
	'identifierSchemeCodeUnspsc190501.51202505': __('identifierSchemeCodeUnspsc190501.51202505'),
	'identifierSchemeCodeUnspsc190501.51202600': __('identifierSchemeCodeUnspsc190501.51202600'),
	'identifierSchemeCodeUnspsc190501.51202601': __('identifierSchemeCodeUnspsc190501.51202601'),
	'identifierSchemeCodeUnspsc190501.51202602': __('identifierSchemeCodeUnspsc190501.51202602'),
	'identifierSchemeCodeUnspsc190501.51202603': __('identifierSchemeCodeUnspsc190501.51202603'),
	'identifierSchemeCodeUnspsc190501.51202700': __('identifierSchemeCodeUnspsc190501.51202700'),
	'identifierSchemeCodeUnspsc190501.51202701': __('identifierSchemeCodeUnspsc190501.51202701'),
	'identifierSchemeCodeUnspsc190501.51202800': __('identifierSchemeCodeUnspsc190501.51202800'),
	'identifierSchemeCodeUnspsc190501.51202801': __('identifierSchemeCodeUnspsc190501.51202801'),
	'identifierSchemeCodeUnspsc190501.51202802': __('identifierSchemeCodeUnspsc190501.51202802'),
	'identifierSchemeCodeUnspsc190501.51202803': __('identifierSchemeCodeUnspsc190501.51202803'),
	'identifierSchemeCodeUnspsc190501.51202804': __('identifierSchemeCodeUnspsc190501.51202804'),
	'identifierSchemeCodeUnspsc190501.51202805': __('identifierSchemeCodeUnspsc190501.51202805'),
	'identifierSchemeCodeUnspsc190501.51202806': __('identifierSchemeCodeUnspsc190501.51202806'),
	'identifierSchemeCodeUnspsc190501.51202807': __('identifierSchemeCodeUnspsc190501.51202807'),
	'identifierSchemeCodeUnspsc190501.51202900': __('identifierSchemeCodeUnspsc190501.51202900'),
	'identifierSchemeCodeUnspsc190501.51202901': __('identifierSchemeCodeUnspsc190501.51202901'),
	'identifierSchemeCodeUnspsc190501.51202902': __('identifierSchemeCodeUnspsc190501.51202902'),
	'identifierSchemeCodeUnspsc190501.51203000': __('identifierSchemeCodeUnspsc190501.51203000'),
	'identifierSchemeCodeUnspsc190501.51203001': __('identifierSchemeCodeUnspsc190501.51203001'),
	'identifierSchemeCodeUnspsc190501.51203002': __('identifierSchemeCodeUnspsc190501.51203002'),
	'identifierSchemeCodeUnspsc190501.51203003': __('identifierSchemeCodeUnspsc190501.51203003'),
	'identifierSchemeCodeUnspsc190501.51203100': __('identifierSchemeCodeUnspsc190501.51203100'),
	'identifierSchemeCodeUnspsc190501.51203101': __('identifierSchemeCodeUnspsc190501.51203101'),
	'identifierSchemeCodeUnspsc190501.51203102': __('identifierSchemeCodeUnspsc190501.51203102'),
	'identifierSchemeCodeUnspsc190501.51203103': __('identifierSchemeCodeUnspsc190501.51203103'),
	'identifierSchemeCodeUnspsc190501.51203104': __('identifierSchemeCodeUnspsc190501.51203104'),
	'identifierSchemeCodeUnspsc190501.51203200': __('identifierSchemeCodeUnspsc190501.51203200'),
	'identifierSchemeCodeUnspsc190501.51203201': __('identifierSchemeCodeUnspsc190501.51203201'),
	'identifierSchemeCodeUnspsc190501.51203202': __('identifierSchemeCodeUnspsc190501.51203202'),
	'identifierSchemeCodeUnspsc190501.51203203': __('identifierSchemeCodeUnspsc190501.51203203'),
	'identifierSchemeCodeUnspsc190501.51203204': __('identifierSchemeCodeUnspsc190501.51203204'),
	'identifierSchemeCodeUnspsc190501.51203300': __('identifierSchemeCodeUnspsc190501.51203300'),
	'identifierSchemeCodeUnspsc190501.51203301': __('identifierSchemeCodeUnspsc190501.51203301'),
	'identifierSchemeCodeUnspsc190501.51203302': __('identifierSchemeCodeUnspsc190501.51203302'),
	'identifierSchemeCodeUnspsc190501.51203303': __('identifierSchemeCodeUnspsc190501.51203303'),
	'identifierSchemeCodeUnspsc190501.51203400': __('identifierSchemeCodeUnspsc190501.51203400'),
	'identifierSchemeCodeUnspsc190501.51203401': __('identifierSchemeCodeUnspsc190501.51203401'),
	'identifierSchemeCodeUnspsc190501.51203402': __('identifierSchemeCodeUnspsc190501.51203402'),
	'identifierSchemeCodeUnspsc190501.51203403': __('identifierSchemeCodeUnspsc190501.51203403'),
	'identifierSchemeCodeUnspsc190501.51203404': __('identifierSchemeCodeUnspsc190501.51203404'),
	'identifierSchemeCodeUnspsc190501.51203405': __('identifierSchemeCodeUnspsc190501.51203405'),
	'identifierSchemeCodeUnspsc190501.51203500': __('identifierSchemeCodeUnspsc190501.51203500'),
	'identifierSchemeCodeUnspsc190501.51203501': __('identifierSchemeCodeUnspsc190501.51203501'),
	'identifierSchemeCodeUnspsc190501.51203600': __('identifierSchemeCodeUnspsc190501.51203600'),
	'identifierSchemeCodeUnspsc190501.51203601': __('identifierSchemeCodeUnspsc190501.51203601'),
	'identifierSchemeCodeUnspsc190501.51203602': __('identifierSchemeCodeUnspsc190501.51203602'),
	'identifierSchemeCodeUnspsc190501.51203603': __('identifierSchemeCodeUnspsc190501.51203603'),
	'identifierSchemeCodeUnspsc190501.51203604': __('identifierSchemeCodeUnspsc190501.51203604'),
	'identifierSchemeCodeUnspsc190501.51203605': __('identifierSchemeCodeUnspsc190501.51203605'),
	'identifierSchemeCodeUnspsc190501.51203606': __('identifierSchemeCodeUnspsc190501.51203606'),
	'identifierSchemeCodeUnspsc190501.51203700': __('identifierSchemeCodeUnspsc190501.51203700'),
	'identifierSchemeCodeUnspsc190501.51203701': __('identifierSchemeCodeUnspsc190501.51203701'),
	'identifierSchemeCodeUnspsc190501.51203800': __('identifierSchemeCodeUnspsc190501.51203800'),
	'identifierSchemeCodeUnspsc190501.51203801': __('identifierSchemeCodeUnspsc190501.51203801'),
	'identifierSchemeCodeUnspsc190501.51203802': __('identifierSchemeCodeUnspsc190501.51203802'),
	'identifierSchemeCodeUnspsc190501.51203900': __('identifierSchemeCodeUnspsc190501.51203900'),
	'identifierSchemeCodeUnspsc190501.51203901': __('identifierSchemeCodeUnspsc190501.51203901'),
	'identifierSchemeCodeUnspsc190501.51203902': __('identifierSchemeCodeUnspsc190501.51203902'),
	'identifierSchemeCodeUnspsc190501.51203903': __('identifierSchemeCodeUnspsc190501.51203903'),
	'identifierSchemeCodeUnspsc190501.51204000': __('identifierSchemeCodeUnspsc190501.51204000'),
	'identifierSchemeCodeUnspsc190501.51204001': __('identifierSchemeCodeUnspsc190501.51204001'),
	'identifierSchemeCodeUnspsc190501.51204100': __('identifierSchemeCodeUnspsc190501.51204100'),
	'identifierSchemeCodeUnspsc190501.51204101': __('identifierSchemeCodeUnspsc190501.51204101'),
	'identifierSchemeCodeUnspsc190501.51204102': __('identifierSchemeCodeUnspsc190501.51204102'),
	'identifierSchemeCodeUnspsc190501.51204200': __('identifierSchemeCodeUnspsc190501.51204200'),
	'identifierSchemeCodeUnspsc190501.51204201': __('identifierSchemeCodeUnspsc190501.51204201'),
	'identifierSchemeCodeUnspsc190501.51204202': __('identifierSchemeCodeUnspsc190501.51204202'),
	'identifierSchemeCodeUnspsc190501.51204203': __('identifierSchemeCodeUnspsc190501.51204203'),
	'identifierSchemeCodeUnspsc190501.51204204': __('identifierSchemeCodeUnspsc190501.51204204'),
	'identifierSchemeCodeUnspsc190501.51204205': __('identifierSchemeCodeUnspsc190501.51204205'),
	'identifierSchemeCodeUnspsc190501.51240000': __('identifierSchemeCodeUnspsc190501.51240000'),
	'identifierSchemeCodeUnspsc190501.51241000': __('identifierSchemeCodeUnspsc190501.51241000'),
	'identifierSchemeCodeUnspsc190501.51241001': __('identifierSchemeCodeUnspsc190501.51241001'),
	'identifierSchemeCodeUnspsc190501.51241002': __('identifierSchemeCodeUnspsc190501.51241002'),
	'identifierSchemeCodeUnspsc190501.51241100': __('identifierSchemeCodeUnspsc190501.51241100'),
	'identifierSchemeCodeUnspsc190501.51241103': __('identifierSchemeCodeUnspsc190501.51241103'),
	'identifierSchemeCodeUnspsc190501.51241104': __('identifierSchemeCodeUnspsc190501.51241104'),
	'identifierSchemeCodeUnspsc190501.51241105': __('identifierSchemeCodeUnspsc190501.51241105'),
	'identifierSchemeCodeUnspsc190501.51241106': __('identifierSchemeCodeUnspsc190501.51241106'),
	'identifierSchemeCodeUnspsc190501.51241107': __('identifierSchemeCodeUnspsc190501.51241107'),
	'identifierSchemeCodeUnspsc190501.51241108': __('identifierSchemeCodeUnspsc190501.51241108'),
	'identifierSchemeCodeUnspsc190501.51241110': __('identifierSchemeCodeUnspsc190501.51241110'),
	'identifierSchemeCodeUnspsc190501.51241113': __('identifierSchemeCodeUnspsc190501.51241113'),
	'identifierSchemeCodeUnspsc190501.51241117': __('identifierSchemeCodeUnspsc190501.51241117'),
	'identifierSchemeCodeUnspsc190501.51241118': __('identifierSchemeCodeUnspsc190501.51241118'),
	'identifierSchemeCodeUnspsc190501.51241120': __('identifierSchemeCodeUnspsc190501.51241120'),
	'identifierSchemeCodeUnspsc190501.51241122': __('identifierSchemeCodeUnspsc190501.51241122'),
	'identifierSchemeCodeUnspsc190501.51241123': __('identifierSchemeCodeUnspsc190501.51241123'),
	'identifierSchemeCodeUnspsc190501.51241124': __('identifierSchemeCodeUnspsc190501.51241124'),
	'identifierSchemeCodeUnspsc190501.51241125': __('identifierSchemeCodeUnspsc190501.51241125'),
	'identifierSchemeCodeUnspsc190501.51241126': __('identifierSchemeCodeUnspsc190501.51241126'),
	'identifierSchemeCodeUnspsc190501.51241127': __('identifierSchemeCodeUnspsc190501.51241127'),
	'identifierSchemeCodeUnspsc190501.51241128': __('identifierSchemeCodeUnspsc190501.51241128'),
	'identifierSchemeCodeUnspsc190501.51241129': __('identifierSchemeCodeUnspsc190501.51241129'),
	'identifierSchemeCodeUnspsc190501.51241131': __('identifierSchemeCodeUnspsc190501.51241131'),
	'identifierSchemeCodeUnspsc190501.51241132': __('identifierSchemeCodeUnspsc190501.51241132'),
	'identifierSchemeCodeUnspsc190501.51241133': __('identifierSchemeCodeUnspsc190501.51241133'),
	'identifierSchemeCodeUnspsc190501.51241134': __('identifierSchemeCodeUnspsc190501.51241134'),
	'identifierSchemeCodeUnspsc190501.51241135': __('identifierSchemeCodeUnspsc190501.51241135'),
	'identifierSchemeCodeUnspsc190501.51241136': __('identifierSchemeCodeUnspsc190501.51241136'),
	'identifierSchemeCodeUnspsc190501.51241137': __('identifierSchemeCodeUnspsc190501.51241137'),
	'identifierSchemeCodeUnspsc190501.51241138': __('identifierSchemeCodeUnspsc190501.51241138'),
	'identifierSchemeCodeUnspsc190501.51241200': __('identifierSchemeCodeUnspsc190501.51241200'),
	'identifierSchemeCodeUnspsc190501.51241205': __('identifierSchemeCodeUnspsc190501.51241205'),
	'identifierSchemeCodeUnspsc190501.51241206': __('identifierSchemeCodeUnspsc190501.51241206'),
	'identifierSchemeCodeUnspsc190501.51241207': __('identifierSchemeCodeUnspsc190501.51241207'),
	'identifierSchemeCodeUnspsc190501.51241209': __('identifierSchemeCodeUnspsc190501.51241209'),
	'identifierSchemeCodeUnspsc190501.51241213': __('identifierSchemeCodeUnspsc190501.51241213'),
	'identifierSchemeCodeUnspsc190501.51241215': __('identifierSchemeCodeUnspsc190501.51241215'),
	'identifierSchemeCodeUnspsc190501.51241216': __('identifierSchemeCodeUnspsc190501.51241216'),
	'identifierSchemeCodeUnspsc190501.51241218': __('identifierSchemeCodeUnspsc190501.51241218'),
	'identifierSchemeCodeUnspsc190501.51241219': __('identifierSchemeCodeUnspsc190501.51241219'),
	'identifierSchemeCodeUnspsc190501.51241221': __('identifierSchemeCodeUnspsc190501.51241221'),
	'identifierSchemeCodeUnspsc190501.51241222': __('identifierSchemeCodeUnspsc190501.51241222'),
	'identifierSchemeCodeUnspsc190501.51241223': __('identifierSchemeCodeUnspsc190501.51241223'),
	'identifierSchemeCodeUnspsc190501.51241224': __('identifierSchemeCodeUnspsc190501.51241224'),
	'identifierSchemeCodeUnspsc190501.51241225': __('identifierSchemeCodeUnspsc190501.51241225'),
	'identifierSchemeCodeUnspsc190501.51241226': __('identifierSchemeCodeUnspsc190501.51241226'),
	'identifierSchemeCodeUnspsc190501.51241227': __('identifierSchemeCodeUnspsc190501.51241227'),
	'identifierSchemeCodeUnspsc190501.51241228': __('identifierSchemeCodeUnspsc190501.51241228'),
	'identifierSchemeCodeUnspsc190501.51241229': __('identifierSchemeCodeUnspsc190501.51241229'),
	'identifierSchemeCodeUnspsc190501.51241232': __('identifierSchemeCodeUnspsc190501.51241232'),
	'identifierSchemeCodeUnspsc190501.51241235': __('identifierSchemeCodeUnspsc190501.51241235'),
	'identifierSchemeCodeUnspsc190501.51241236': __('identifierSchemeCodeUnspsc190501.51241236'),
	'identifierSchemeCodeUnspsc190501.51241237': __('identifierSchemeCodeUnspsc190501.51241237'),
	'identifierSchemeCodeUnspsc190501.51241239': __('identifierSchemeCodeUnspsc190501.51241239'),
	'identifierSchemeCodeUnspsc190501.51241240': __('identifierSchemeCodeUnspsc190501.51241240'),
	'identifierSchemeCodeUnspsc190501.51241241': __('identifierSchemeCodeUnspsc190501.51241241'),
	'identifierSchemeCodeUnspsc190501.51241242': __('identifierSchemeCodeUnspsc190501.51241242'),
	'identifierSchemeCodeUnspsc190501.51241243': __('identifierSchemeCodeUnspsc190501.51241243'),
	'identifierSchemeCodeUnspsc190501.51241244': __('identifierSchemeCodeUnspsc190501.51241244'),
	'identifierSchemeCodeUnspsc190501.51241245': __('identifierSchemeCodeUnspsc190501.51241245'),
	'identifierSchemeCodeUnspsc190501.51241246': __('identifierSchemeCodeUnspsc190501.51241246'),
	'identifierSchemeCodeUnspsc190501.51241247': __('identifierSchemeCodeUnspsc190501.51241247'),
	'identifierSchemeCodeUnspsc190501.51241248': __('identifierSchemeCodeUnspsc190501.51241248'),
	'identifierSchemeCodeUnspsc190501.51241249': __('identifierSchemeCodeUnspsc190501.51241249'),
	'identifierSchemeCodeUnspsc190501.51241250': __('identifierSchemeCodeUnspsc190501.51241250'),
	'identifierSchemeCodeUnspsc190501.51241251': __('identifierSchemeCodeUnspsc190501.51241251'),
	'identifierSchemeCodeUnspsc190501.51241252': __('identifierSchemeCodeUnspsc190501.51241252'),
	'identifierSchemeCodeUnspsc190501.51241253': __('identifierSchemeCodeUnspsc190501.51241253'),
	'identifierSchemeCodeUnspsc190501.51241254': __('identifierSchemeCodeUnspsc190501.51241254'),
	'identifierSchemeCodeUnspsc190501.51241255': __('identifierSchemeCodeUnspsc190501.51241255'),
	'identifierSchemeCodeUnspsc190501.51241256': __('identifierSchemeCodeUnspsc190501.51241256'),
	'identifierSchemeCodeUnspsc190501.51241257': __('identifierSchemeCodeUnspsc190501.51241257'),
	'identifierSchemeCodeUnspsc190501.51241258': __('identifierSchemeCodeUnspsc190501.51241258'),
	'identifierSchemeCodeUnspsc190501.51241259': __('identifierSchemeCodeUnspsc190501.51241259'),
	'identifierSchemeCodeUnspsc190501.51241260': __('identifierSchemeCodeUnspsc190501.51241260'),
	'identifierSchemeCodeUnspsc190501.51241261': __('identifierSchemeCodeUnspsc190501.51241261'),
	'identifierSchemeCodeUnspsc190501.51241300': __('identifierSchemeCodeUnspsc190501.51241300'),
	'identifierSchemeCodeUnspsc190501.51241301': __('identifierSchemeCodeUnspsc190501.51241301'),
	'identifierSchemeCodeUnspsc190501.51241302': __('identifierSchemeCodeUnspsc190501.51241302'),
	'identifierSchemeCodeUnspsc190501.51241304': __('identifierSchemeCodeUnspsc190501.51241304'),
	'identifierSchemeCodeUnspsc190501.51241305': __('identifierSchemeCodeUnspsc190501.51241305'),
	'identifierSchemeCodeUnspsc190501.51241306': __('identifierSchemeCodeUnspsc190501.51241306'),
	'identifierSchemeCodeUnspsc190501.51241307': __('identifierSchemeCodeUnspsc190501.51241307'),
	'identifierSchemeCodeUnspsc190501.51241308': __('identifierSchemeCodeUnspsc190501.51241308'),
	'identifierSchemeCodeUnspsc190501.51241309': __('identifierSchemeCodeUnspsc190501.51241309'),
	'identifierSchemeCodeUnspsc190501.51241310': __('identifierSchemeCodeUnspsc190501.51241310'),
	'identifierSchemeCodeUnspsc190501.51241311': __('identifierSchemeCodeUnspsc190501.51241311'),
	'identifierSchemeCodeUnspsc190501.51241400': __('identifierSchemeCodeUnspsc190501.51241400'),
	'identifierSchemeCodeUnspsc190501.51241401': __('identifierSchemeCodeUnspsc190501.51241401'),
	'identifierSchemeCodeUnspsc190501.51241402': __('identifierSchemeCodeUnspsc190501.51241402'),
	'identifierSchemeCodeUnspsc190501.51241403': __('identifierSchemeCodeUnspsc190501.51241403'),
	'identifierSchemeCodeUnspsc190501.51241404': __('identifierSchemeCodeUnspsc190501.51241404'),
	'identifierSchemeCodeUnspsc190501.51241405': __('identifierSchemeCodeUnspsc190501.51241405'),
	'identifierSchemeCodeUnspsc190501.51241406': __('identifierSchemeCodeUnspsc190501.51241406'),
	'identifierSchemeCodeUnspsc190501.51241407': __('identifierSchemeCodeUnspsc190501.51241407'),
	'identifierSchemeCodeUnspsc190501.51241408': __('identifierSchemeCodeUnspsc190501.51241408'),
	'identifierSchemeCodeUnspsc190501.51241409': __('identifierSchemeCodeUnspsc190501.51241409'),
	'identifierSchemeCodeUnspsc190501.51241410': __('identifierSchemeCodeUnspsc190501.51241410'),
	'identifierSchemeCodeUnspsc190501.51241411': __('identifierSchemeCodeUnspsc190501.51241411'),
	'identifierSchemeCodeUnspsc190501.51241412': __('identifierSchemeCodeUnspsc190501.51241412'),
	'identifierSchemeCodeUnspsc190501.51241413': __('identifierSchemeCodeUnspsc190501.51241413'),
	'identifierSchemeCodeUnspsc190501.51241414': __('identifierSchemeCodeUnspsc190501.51241414'),
	'identifierSchemeCodeUnspsc190501.51241415': __('identifierSchemeCodeUnspsc190501.51241415'),
	'identifierSchemeCodeUnspsc190501.51241416': __('identifierSchemeCodeUnspsc190501.51241416'),
	'identifierSchemeCodeUnspsc190501.51241417': __('identifierSchemeCodeUnspsc190501.51241417'),
	'identifierSchemeCodeUnspsc190501.51241418': __('identifierSchemeCodeUnspsc190501.51241418'),
	'identifierSchemeCodeUnspsc190501.51241419': __('identifierSchemeCodeUnspsc190501.51241419'),
	'identifierSchemeCodeUnspsc190501.51241500': __('identifierSchemeCodeUnspsc190501.51241500'),
	'identifierSchemeCodeUnspsc190501.51241501': __('identifierSchemeCodeUnspsc190501.51241501'),
	'identifierSchemeCodeUnspsc190501.51241502': __('identifierSchemeCodeUnspsc190501.51241502'),
	'identifierSchemeCodeUnspsc190501.51241503': __('identifierSchemeCodeUnspsc190501.51241503'),
	'identifierSchemeCodeUnspsc190501.51241504': __('identifierSchemeCodeUnspsc190501.51241504'),
	'identifierSchemeCodeUnspsc190501.51241505': __('identifierSchemeCodeUnspsc190501.51241505'),
	'identifierSchemeCodeUnspsc190501.51241506': __('identifierSchemeCodeUnspsc190501.51241506'),
	'identifierSchemeCodeUnspsc190501.51241507': __('identifierSchemeCodeUnspsc190501.51241507'),
	'identifierSchemeCodeUnspsc190501.51241508': __('identifierSchemeCodeUnspsc190501.51241508'),
	'identifierSchemeCodeUnspsc190501.51241509': __('identifierSchemeCodeUnspsc190501.51241509'),
	'identifierSchemeCodeUnspsc190501.51241510': __('identifierSchemeCodeUnspsc190501.51241510'),
	'identifierSchemeCodeUnspsc190501.51241511': __('identifierSchemeCodeUnspsc190501.51241511'),
	'identifierSchemeCodeUnspsc190501.51241512': __('identifierSchemeCodeUnspsc190501.51241512'),
	'identifierSchemeCodeUnspsc190501.51241513': __('identifierSchemeCodeUnspsc190501.51241513'),
	'identifierSchemeCodeUnspsc190501.51241514': __('identifierSchemeCodeUnspsc190501.51241514'),
	'identifierSchemeCodeUnspsc190501.51241515': __('identifierSchemeCodeUnspsc190501.51241515'),
	'identifierSchemeCodeUnspsc190501.51241516': __('identifierSchemeCodeUnspsc190501.51241516'),
	'identifierSchemeCodeUnspsc190501.51241517': __('identifierSchemeCodeUnspsc190501.51241517'),
	'identifierSchemeCodeUnspsc190501.51241518': __('identifierSchemeCodeUnspsc190501.51241518'),
	'identifierSchemeCodeUnspsc190501.51241519': __('identifierSchemeCodeUnspsc190501.51241519'),
	'identifierSchemeCodeUnspsc190501.51241520': __('identifierSchemeCodeUnspsc190501.51241520'),
	'identifierSchemeCodeUnspsc190501.51241522': __('identifierSchemeCodeUnspsc190501.51241522'),
	'identifierSchemeCodeUnspsc190501.51241523': __('identifierSchemeCodeUnspsc190501.51241523'),
	'identifierSchemeCodeUnspsc190501.51241524': __('identifierSchemeCodeUnspsc190501.51241524'),
	'identifierSchemeCodeUnspsc190501.51241525': __('identifierSchemeCodeUnspsc190501.51241525'),
	'identifierSchemeCodeUnspsc190501.51241526': __('identifierSchemeCodeUnspsc190501.51241526'),
	'identifierSchemeCodeUnspsc190501.51241527': __('identifierSchemeCodeUnspsc190501.51241527'),
	'identifierSchemeCodeUnspsc190501.51241528': __('identifierSchemeCodeUnspsc190501.51241528'),
	'identifierSchemeCodeUnspsc190501.51241529': __('identifierSchemeCodeUnspsc190501.51241529'),
	'identifierSchemeCodeUnspsc190501.51241530': __('identifierSchemeCodeUnspsc190501.51241530'),
	'identifierSchemeCodeUnspsc190501.51241531': __('identifierSchemeCodeUnspsc190501.51241531'),
	'identifierSchemeCodeUnspsc190501.51241532': __('identifierSchemeCodeUnspsc190501.51241532'),
	'identifierSchemeCodeUnspsc190501.51241533': __('identifierSchemeCodeUnspsc190501.51241533'),
	'identifierSchemeCodeUnspsc190501.51241534': __('identifierSchemeCodeUnspsc190501.51241534'),
	'identifierSchemeCodeUnspsc190501.51241535': __('identifierSchemeCodeUnspsc190501.51241535'),
	'identifierSchemeCodeUnspsc190501.51241536': __('identifierSchemeCodeUnspsc190501.51241536'),
	'identifierSchemeCodeUnspsc190501.51241537': __('identifierSchemeCodeUnspsc190501.51241537'),
	'identifierSchemeCodeUnspsc190501.51241538': __('identifierSchemeCodeUnspsc190501.51241538'),
	'identifierSchemeCodeUnspsc190501.51241539': __('identifierSchemeCodeUnspsc190501.51241539'),
	'identifierSchemeCodeUnspsc190501.51241540': __('identifierSchemeCodeUnspsc190501.51241540'),
	'identifierSchemeCodeUnspsc190501.51241542': __('identifierSchemeCodeUnspsc190501.51241542'),
	'identifierSchemeCodeUnspsc190501.51241543': __('identifierSchemeCodeUnspsc190501.51241543'),
	'identifierSchemeCodeUnspsc190501.51241544': __('identifierSchemeCodeUnspsc190501.51241544'),
	'identifierSchemeCodeUnspsc190501.51241545': __('identifierSchemeCodeUnspsc190501.51241545'),
	'identifierSchemeCodeUnspsc190501.51241546': __('identifierSchemeCodeUnspsc190501.51241546'),
	'identifierSchemeCodeUnspsc190501.51241547': __('identifierSchemeCodeUnspsc190501.51241547'),
	'identifierSchemeCodeUnspsc190501.51241548': __('identifierSchemeCodeUnspsc190501.51241548'),
	'identifierSchemeCodeUnspsc190501.51241549': __('identifierSchemeCodeUnspsc190501.51241549'),
	'identifierSchemeCodeUnspsc190501.51241550': __('identifierSchemeCodeUnspsc190501.51241550'),
	'identifierSchemeCodeUnspsc190501.51241551': __('identifierSchemeCodeUnspsc190501.51241551'),
	'identifierSchemeCodeUnspsc190501.51241552': __('identifierSchemeCodeUnspsc190501.51241552'),
	'identifierSchemeCodeUnspsc190501.51241553': __('identifierSchemeCodeUnspsc190501.51241553'),
	'identifierSchemeCodeUnspsc190501.51241554': __('identifierSchemeCodeUnspsc190501.51241554'),
	'identifierSchemeCodeUnspsc190501.51241600': __('identifierSchemeCodeUnspsc190501.51241600'),
	'identifierSchemeCodeUnspsc190501.51241601': __('identifierSchemeCodeUnspsc190501.51241601'),
	'identifierSchemeCodeUnspsc190501.51241602': __('identifierSchemeCodeUnspsc190501.51241602'),
	'identifierSchemeCodeUnspsc190501.51241603': __('identifierSchemeCodeUnspsc190501.51241603'),
	'identifierSchemeCodeUnspsc190501.51241604': __('identifierSchemeCodeUnspsc190501.51241604'),
	'identifierSchemeCodeUnspsc190501.51241605': __('identifierSchemeCodeUnspsc190501.51241605'),
	'identifierSchemeCodeUnspsc190501.51241606': __('identifierSchemeCodeUnspsc190501.51241606'),
	'identifierSchemeCodeUnspsc190501.51241607': __('identifierSchemeCodeUnspsc190501.51241607'),
	'identifierSchemeCodeUnspsc190501.51241608': __('identifierSchemeCodeUnspsc190501.51241608'),
	'identifierSchemeCodeUnspsc190501.51241609': __('identifierSchemeCodeUnspsc190501.51241609'),
	'identifierSchemeCodeUnspsc190501.51241610': __('identifierSchemeCodeUnspsc190501.51241610'),
	'identifierSchemeCodeUnspsc190501.51241611': __('identifierSchemeCodeUnspsc190501.51241611'),
	'identifierSchemeCodeUnspsc190501.51241612': __('identifierSchemeCodeUnspsc190501.51241612'),
	'identifierSchemeCodeUnspsc190501.51241613': __('identifierSchemeCodeUnspsc190501.51241613'),
	'identifierSchemeCodeUnspsc190501.51241614': __('identifierSchemeCodeUnspsc190501.51241614'),
	'identifierSchemeCodeUnspsc190501.51241615': __('identifierSchemeCodeUnspsc190501.51241615'),
	'identifierSchemeCodeUnspsc190501.51241700': __('identifierSchemeCodeUnspsc190501.51241700'),
	'identifierSchemeCodeUnspsc190501.51241701': __('identifierSchemeCodeUnspsc190501.51241701'),
	'identifierSchemeCodeUnspsc190501.51241702': __('identifierSchemeCodeUnspsc190501.51241702'),
	'identifierSchemeCodeUnspsc190501.51241703': __('identifierSchemeCodeUnspsc190501.51241703'),
	'identifierSchemeCodeUnspsc190501.51241704': __('identifierSchemeCodeUnspsc190501.51241704'),
	'identifierSchemeCodeUnspsc190501.51241705': __('identifierSchemeCodeUnspsc190501.51241705'),
	'identifierSchemeCodeUnspsc190501.51241706': __('identifierSchemeCodeUnspsc190501.51241706'),
	'identifierSchemeCodeUnspsc190501.51241707': __('identifierSchemeCodeUnspsc190501.51241707'),
	'identifierSchemeCodeUnspsc190501.51241708': __('identifierSchemeCodeUnspsc190501.51241708'),
	'identifierSchemeCodeUnspsc190501.51241709': __('identifierSchemeCodeUnspsc190501.51241709'),
	'identifierSchemeCodeUnspsc190501.51241710': __('identifierSchemeCodeUnspsc190501.51241710'),
	'identifierSchemeCodeUnspsc190501.51241711': __('identifierSchemeCodeUnspsc190501.51241711'),
	'identifierSchemeCodeUnspsc190501.51241712': __('identifierSchemeCodeUnspsc190501.51241712'),
	'identifierSchemeCodeUnspsc190501.51241800': __('identifierSchemeCodeUnspsc190501.51241800'),
	'identifierSchemeCodeUnspsc190501.51241801': __('identifierSchemeCodeUnspsc190501.51241801'),
	'identifierSchemeCodeUnspsc190501.51241802': __('identifierSchemeCodeUnspsc190501.51241802'),
	'identifierSchemeCodeUnspsc190501.51241803': __('identifierSchemeCodeUnspsc190501.51241803'),
	'identifierSchemeCodeUnspsc190501.51241804': __('identifierSchemeCodeUnspsc190501.51241804'),
	'identifierSchemeCodeUnspsc190501.51241805': __('identifierSchemeCodeUnspsc190501.51241805'),
	'identifierSchemeCodeUnspsc190501.51241806': __('identifierSchemeCodeUnspsc190501.51241806'),
	'identifierSchemeCodeUnspsc190501.51241807': __('identifierSchemeCodeUnspsc190501.51241807'),
	'identifierSchemeCodeUnspsc190501.51241808': __('identifierSchemeCodeUnspsc190501.51241808'),
	'identifierSchemeCodeUnspsc190501.51241809': __('identifierSchemeCodeUnspsc190501.51241809'),
	'identifierSchemeCodeUnspsc190501.51241810': __('identifierSchemeCodeUnspsc190501.51241810'),
	'identifierSchemeCodeUnspsc190501.51241811': __('identifierSchemeCodeUnspsc190501.51241811'),
	'identifierSchemeCodeUnspsc190501.51241812': __('identifierSchemeCodeUnspsc190501.51241812'),
	'identifierSchemeCodeUnspsc190501.51241813': __('identifierSchemeCodeUnspsc190501.51241813'),
	'identifierSchemeCodeUnspsc190501.51241814': __('identifierSchemeCodeUnspsc190501.51241814'),
	'identifierSchemeCodeUnspsc190501.51241815': __('identifierSchemeCodeUnspsc190501.51241815'),
	'identifierSchemeCodeUnspsc190501.51241816': __('identifierSchemeCodeUnspsc190501.51241816'),
	'identifierSchemeCodeUnspsc190501.51241817': __('identifierSchemeCodeUnspsc190501.51241817'),
	'identifierSchemeCodeUnspsc190501.51241818': __('identifierSchemeCodeUnspsc190501.51241818'),
	'identifierSchemeCodeUnspsc190501.51241819': __('identifierSchemeCodeUnspsc190501.51241819'),
	'identifierSchemeCodeUnspsc190501.51241820': __('identifierSchemeCodeUnspsc190501.51241820'),
	'identifierSchemeCodeUnspsc190501.51241821': __('identifierSchemeCodeUnspsc190501.51241821'),
	'identifierSchemeCodeUnspsc190501.51241822': __('identifierSchemeCodeUnspsc190501.51241822'),
	'identifierSchemeCodeUnspsc190501.51241823': __('identifierSchemeCodeUnspsc190501.51241823'),
	'identifierSchemeCodeUnspsc190501.51241824': __('identifierSchemeCodeUnspsc190501.51241824'),
	'identifierSchemeCodeUnspsc190501.51241825': __('identifierSchemeCodeUnspsc190501.51241825'),
	'identifierSchemeCodeUnspsc190501.51241826': __('identifierSchemeCodeUnspsc190501.51241826'),
	'identifierSchemeCodeUnspsc190501.51241827': __('identifierSchemeCodeUnspsc190501.51241827'),
	'identifierSchemeCodeUnspsc190501.51241828': __('identifierSchemeCodeUnspsc190501.51241828'),
	'identifierSchemeCodeUnspsc190501.51241829': __('identifierSchemeCodeUnspsc190501.51241829'),
	'identifierSchemeCodeUnspsc190501.51241830': __('identifierSchemeCodeUnspsc190501.51241830'),
	'identifierSchemeCodeUnspsc190501.51241831': __('identifierSchemeCodeUnspsc190501.51241831'),
	'identifierSchemeCodeUnspsc190501.51241832': __('identifierSchemeCodeUnspsc190501.51241832'),
	'identifierSchemeCodeUnspsc190501.51241833': __('identifierSchemeCodeUnspsc190501.51241833'),
	'identifierSchemeCodeUnspsc190501.51241834': __('identifierSchemeCodeUnspsc190501.51241834'),
	'identifierSchemeCodeUnspsc190501.51241835': __('identifierSchemeCodeUnspsc190501.51241835'),
	'identifierSchemeCodeUnspsc190501.51241836': __('identifierSchemeCodeUnspsc190501.51241836'),
	'identifierSchemeCodeUnspsc190501.51241837': __('identifierSchemeCodeUnspsc190501.51241837'),
	'identifierSchemeCodeUnspsc190501.51241838': __('identifierSchemeCodeUnspsc190501.51241838'),
	'identifierSchemeCodeUnspsc190501.51241839': __('identifierSchemeCodeUnspsc190501.51241839'),
	'identifierSchemeCodeUnspsc190501.51241840': __('identifierSchemeCodeUnspsc190501.51241840'),
	'identifierSchemeCodeUnspsc190501.51241841': __('identifierSchemeCodeUnspsc190501.51241841'),
	'identifierSchemeCodeUnspsc190501.51241842': __('identifierSchemeCodeUnspsc190501.51241842'),
	'identifierSchemeCodeUnspsc190501.51241843': __('identifierSchemeCodeUnspsc190501.51241843'),
	'identifierSchemeCodeUnspsc190501.51241844': __('identifierSchemeCodeUnspsc190501.51241844'),
	'identifierSchemeCodeUnspsc190501.51241845': __('identifierSchemeCodeUnspsc190501.51241845'),
	'identifierSchemeCodeUnspsc190501.51241846': __('identifierSchemeCodeUnspsc190501.51241846'),
	'identifierSchemeCodeUnspsc190501.51241847': __('identifierSchemeCodeUnspsc190501.51241847'),
	'identifierSchemeCodeUnspsc190501.51241848': __('identifierSchemeCodeUnspsc190501.51241848'),
	'identifierSchemeCodeUnspsc190501.51241849': __('identifierSchemeCodeUnspsc190501.51241849'),
	'identifierSchemeCodeUnspsc190501.51241850': __('identifierSchemeCodeUnspsc190501.51241850'),
	'identifierSchemeCodeUnspsc190501.51241851': __('identifierSchemeCodeUnspsc190501.51241851'),
	'identifierSchemeCodeUnspsc190501.51241852': __('identifierSchemeCodeUnspsc190501.51241852'),
	'identifierSchemeCodeUnspsc190501.51241853': __('identifierSchemeCodeUnspsc190501.51241853'),
	'identifierSchemeCodeUnspsc190501.51241854': __('identifierSchemeCodeUnspsc190501.51241854'),
	'identifierSchemeCodeUnspsc190501.51241855': __('identifierSchemeCodeUnspsc190501.51241855'),
	'identifierSchemeCodeUnspsc190501.51241856': __('identifierSchemeCodeUnspsc190501.51241856'),
	'identifierSchemeCodeUnspsc190501.51241857': __('identifierSchemeCodeUnspsc190501.51241857'),
	'identifierSchemeCodeUnspsc190501.51241858': __('identifierSchemeCodeUnspsc190501.51241858'),
	'identifierSchemeCodeUnspsc190501.51241859': __('identifierSchemeCodeUnspsc190501.51241859'),
	'identifierSchemeCodeUnspsc190501.51241860': __('identifierSchemeCodeUnspsc190501.51241860'),
	'identifierSchemeCodeUnspsc190501.51241861': __('identifierSchemeCodeUnspsc190501.51241861'),
	'identifierSchemeCodeUnspsc190501.51241862': __('identifierSchemeCodeUnspsc190501.51241862'),
	'identifierSchemeCodeUnspsc190501.51241863': __('identifierSchemeCodeUnspsc190501.51241863'),
	'identifierSchemeCodeUnspsc190501.51241864': __('identifierSchemeCodeUnspsc190501.51241864'),
	'identifierSchemeCodeUnspsc190501.51241865': __('identifierSchemeCodeUnspsc190501.51241865'),
	'identifierSchemeCodeUnspsc190501.51241866': __('identifierSchemeCodeUnspsc190501.51241866'),
	'identifierSchemeCodeUnspsc190501.51241867': __('identifierSchemeCodeUnspsc190501.51241867'),
	'identifierSchemeCodeUnspsc190501.51241868': __('identifierSchemeCodeUnspsc190501.51241868'),
	'identifierSchemeCodeUnspsc190501.51241869': __('identifierSchemeCodeUnspsc190501.51241869'),
	'identifierSchemeCodeUnspsc190501.51241870': __('identifierSchemeCodeUnspsc190501.51241870'),
	'identifierSchemeCodeUnspsc190501.51241871': __('identifierSchemeCodeUnspsc190501.51241871'),
	'identifierSchemeCodeUnspsc190501.51241872': __('identifierSchemeCodeUnspsc190501.51241872'),
	'identifierSchemeCodeUnspsc190501.51241873': __('identifierSchemeCodeUnspsc190501.51241873'),
	'identifierSchemeCodeUnspsc190501.51241874': __('identifierSchemeCodeUnspsc190501.51241874'),
	'identifierSchemeCodeUnspsc190501.51241875': __('identifierSchemeCodeUnspsc190501.51241875'),
	'identifierSchemeCodeUnspsc190501.51241876': __('identifierSchemeCodeUnspsc190501.51241876'),
	'identifierSchemeCodeUnspsc190501.51241877': __('identifierSchemeCodeUnspsc190501.51241877'),
	'identifierSchemeCodeUnspsc190501.51241878': __('identifierSchemeCodeUnspsc190501.51241878'),
	'identifierSchemeCodeUnspsc190501.51241879': __('identifierSchemeCodeUnspsc190501.51241879'),
	'identifierSchemeCodeUnspsc190501.51241880': __('identifierSchemeCodeUnspsc190501.51241880'),
	'identifierSchemeCodeUnspsc190501.51241881': __('identifierSchemeCodeUnspsc190501.51241881'),
	'identifierSchemeCodeUnspsc190501.51241882': __('identifierSchemeCodeUnspsc190501.51241882'),
	'identifierSchemeCodeUnspsc190501.51241883': __('identifierSchemeCodeUnspsc190501.51241883'),
	'identifierSchemeCodeUnspsc190501.51241885': __('identifierSchemeCodeUnspsc190501.51241885'),
	'identifierSchemeCodeUnspsc190501.51241900': __('identifierSchemeCodeUnspsc190501.51241900'),
	'identifierSchemeCodeUnspsc190501.51241901': __('identifierSchemeCodeUnspsc190501.51241901'),
	'identifierSchemeCodeUnspsc190501.51241902': __('identifierSchemeCodeUnspsc190501.51241902'),
	'identifierSchemeCodeUnspsc190501.51241903': __('identifierSchemeCodeUnspsc190501.51241903'),
	'identifierSchemeCodeUnspsc190501.51241904': __('identifierSchemeCodeUnspsc190501.51241904'),
	'identifierSchemeCodeUnspsc190501.51241905': __('identifierSchemeCodeUnspsc190501.51241905'),
	'identifierSchemeCodeUnspsc190501.51241906': __('identifierSchemeCodeUnspsc190501.51241906'),
	'identifierSchemeCodeUnspsc190501.51241907': __('identifierSchemeCodeUnspsc190501.51241907'),
	'identifierSchemeCodeUnspsc190501.51241908': __('identifierSchemeCodeUnspsc190501.51241908'),
	'identifierSchemeCodeUnspsc190501.51241909': __('identifierSchemeCodeUnspsc190501.51241909'),
	'identifierSchemeCodeUnspsc190501.51241910': __('identifierSchemeCodeUnspsc190501.51241910'),
	'identifierSchemeCodeUnspsc190501.51241911': __('identifierSchemeCodeUnspsc190501.51241911'),
	'identifierSchemeCodeUnspsc190501.51241912': __('identifierSchemeCodeUnspsc190501.51241912'),
	'identifierSchemeCodeUnspsc190501.51241913': __('identifierSchemeCodeUnspsc190501.51241913'),
	'identifierSchemeCodeUnspsc190501.51241914': __('identifierSchemeCodeUnspsc190501.51241914'),
	'identifierSchemeCodeUnspsc190501.51241915': __('identifierSchemeCodeUnspsc190501.51241915'),
	'identifierSchemeCodeUnspsc190501.51241916': __('identifierSchemeCodeUnspsc190501.51241916'),
	'identifierSchemeCodeUnspsc190501.51241917': __('identifierSchemeCodeUnspsc190501.51241917'),
	'identifierSchemeCodeUnspsc190501.51241918': __('identifierSchemeCodeUnspsc190501.51241918'),
	'identifierSchemeCodeUnspsc190501.51241919': __('identifierSchemeCodeUnspsc190501.51241919'),
	'identifierSchemeCodeUnspsc190501.51241920': __('identifierSchemeCodeUnspsc190501.51241920'),
	'identifierSchemeCodeUnspsc190501.51241921': __('identifierSchemeCodeUnspsc190501.51241921'),
	'identifierSchemeCodeUnspsc190501.51241922': __('identifierSchemeCodeUnspsc190501.51241922'),
	'identifierSchemeCodeUnspsc190501.51241923': __('identifierSchemeCodeUnspsc190501.51241923'),
	'identifierSchemeCodeUnspsc190501.51241924': __('identifierSchemeCodeUnspsc190501.51241924'),
	'identifierSchemeCodeUnspsc190501.51241925': __('identifierSchemeCodeUnspsc190501.51241925'),
	'identifierSchemeCodeUnspsc190501.51241926': __('identifierSchemeCodeUnspsc190501.51241926'),
	'identifierSchemeCodeUnspsc190501.51241927': __('identifierSchemeCodeUnspsc190501.51241927'),
	'identifierSchemeCodeUnspsc190501.51241928': __('identifierSchemeCodeUnspsc190501.51241928'),
	'identifierSchemeCodeUnspsc190501.51241929': __('identifierSchemeCodeUnspsc190501.51241929'),
	'identifierSchemeCodeUnspsc190501.51242000': __('identifierSchemeCodeUnspsc190501.51242000'),
	'identifierSchemeCodeUnspsc190501.51242001': __('identifierSchemeCodeUnspsc190501.51242001'),
	'identifierSchemeCodeUnspsc190501.51242002': __('identifierSchemeCodeUnspsc190501.51242002'),
	'identifierSchemeCodeUnspsc190501.51242003': __('identifierSchemeCodeUnspsc190501.51242003'),
	'identifierSchemeCodeUnspsc190501.51242004': __('identifierSchemeCodeUnspsc190501.51242004'),
	'identifierSchemeCodeUnspsc190501.51242005': __('identifierSchemeCodeUnspsc190501.51242005'),
	'identifierSchemeCodeUnspsc190501.51242006': __('identifierSchemeCodeUnspsc190501.51242006'),
	'identifierSchemeCodeUnspsc190501.51242007': __('identifierSchemeCodeUnspsc190501.51242007'),
	'identifierSchemeCodeUnspsc190501.51242008': __('identifierSchemeCodeUnspsc190501.51242008'),
	'identifierSchemeCodeUnspsc190501.51242009': __('identifierSchemeCodeUnspsc190501.51242009'),
	'identifierSchemeCodeUnspsc190501.51242010': __('identifierSchemeCodeUnspsc190501.51242010'),
	'identifierSchemeCodeUnspsc190501.51242011': __('identifierSchemeCodeUnspsc190501.51242011'),
	'identifierSchemeCodeUnspsc190501.51242012': __('identifierSchemeCodeUnspsc190501.51242012'),
	'identifierSchemeCodeUnspsc190501.51242013': __('identifierSchemeCodeUnspsc190501.51242013'),
	'identifierSchemeCodeUnspsc190501.51242100': __('identifierSchemeCodeUnspsc190501.51242100'),
	'identifierSchemeCodeUnspsc190501.51242101': __('identifierSchemeCodeUnspsc190501.51242101'),
	'identifierSchemeCodeUnspsc190501.51242102': __('identifierSchemeCodeUnspsc190501.51242102'),
	'identifierSchemeCodeUnspsc190501.51242103': __('identifierSchemeCodeUnspsc190501.51242103'),
	'identifierSchemeCodeUnspsc190501.51242104': __('identifierSchemeCodeUnspsc190501.51242104'),
	'identifierSchemeCodeUnspsc190501.51242200': __('identifierSchemeCodeUnspsc190501.51242200'),
	'identifierSchemeCodeUnspsc190501.51242201': __('identifierSchemeCodeUnspsc190501.51242201'),
	'identifierSchemeCodeUnspsc190501.51242202': __('identifierSchemeCodeUnspsc190501.51242202'),
	'identifierSchemeCodeUnspsc190501.51242203': __('identifierSchemeCodeUnspsc190501.51242203'),
	'identifierSchemeCodeUnspsc190501.51242204': __('identifierSchemeCodeUnspsc190501.51242204'),
	'identifierSchemeCodeUnspsc190501.51242205': __('identifierSchemeCodeUnspsc190501.51242205'),
	'identifierSchemeCodeUnspsc190501.51242206': __('identifierSchemeCodeUnspsc190501.51242206'),
	'identifierSchemeCodeUnspsc190501.51242207': __('identifierSchemeCodeUnspsc190501.51242207'),
	'identifierSchemeCodeUnspsc190501.51242208': __('identifierSchemeCodeUnspsc190501.51242208'),
	'identifierSchemeCodeUnspsc190501.51242300': __('identifierSchemeCodeUnspsc190501.51242300'),
	'identifierSchemeCodeUnspsc190501.51242301': __('identifierSchemeCodeUnspsc190501.51242301'),
	'identifierSchemeCodeUnspsc190501.51242302': __('identifierSchemeCodeUnspsc190501.51242302'),
	'identifierSchemeCodeUnspsc190501.51242303': __('identifierSchemeCodeUnspsc190501.51242303'),
	'identifierSchemeCodeUnspsc190501.51242304': __('identifierSchemeCodeUnspsc190501.51242304'),
	'identifierSchemeCodeUnspsc190501.51242305': __('identifierSchemeCodeUnspsc190501.51242305'),
	'identifierSchemeCodeUnspsc190501.51242306': __('identifierSchemeCodeUnspsc190501.51242306'),
	'identifierSchemeCodeUnspsc190501.51242307': __('identifierSchemeCodeUnspsc190501.51242307'),
	'identifierSchemeCodeUnspsc190501.51242308': __('identifierSchemeCodeUnspsc190501.51242308'),
	'identifierSchemeCodeUnspsc190501.51242309': __('identifierSchemeCodeUnspsc190501.51242309'),
	'identifierSchemeCodeUnspsc190501.51242310': __('identifierSchemeCodeUnspsc190501.51242310'),
	'identifierSchemeCodeUnspsc190501.51242311': __('identifierSchemeCodeUnspsc190501.51242311'),
	'identifierSchemeCodeUnspsc190501.51242312': __('identifierSchemeCodeUnspsc190501.51242312'),
	'identifierSchemeCodeUnspsc190501.51242313': __('identifierSchemeCodeUnspsc190501.51242313'),
	'identifierSchemeCodeUnspsc190501.51242314': __('identifierSchemeCodeUnspsc190501.51242314'),
	'identifierSchemeCodeUnspsc190501.51242315': __('identifierSchemeCodeUnspsc190501.51242315'),
	'identifierSchemeCodeUnspsc190501.51242316': __('identifierSchemeCodeUnspsc190501.51242316'),
	'identifierSchemeCodeUnspsc190501.51260000': __('identifierSchemeCodeUnspsc190501.51260000'),
	'identifierSchemeCodeUnspsc190501.51261500': __('identifierSchemeCodeUnspsc190501.51261500'),
	'identifierSchemeCodeUnspsc190501.51261501': __('identifierSchemeCodeUnspsc190501.51261501'),
	'identifierSchemeCodeUnspsc190501.51261502': __('identifierSchemeCodeUnspsc190501.51261502'),
	'identifierSchemeCodeUnspsc190501.51261503': __('identifierSchemeCodeUnspsc190501.51261503'),
	'identifierSchemeCodeUnspsc190501.51261504': __('identifierSchemeCodeUnspsc190501.51261504'),
	'identifierSchemeCodeUnspsc190501.51261505': __('identifierSchemeCodeUnspsc190501.51261505'),
	'identifierSchemeCodeUnspsc190501.51261600': __('identifierSchemeCodeUnspsc190501.51261600'),
	'identifierSchemeCodeUnspsc190501.51261601': __('identifierSchemeCodeUnspsc190501.51261601'),
	'identifierSchemeCodeUnspsc190501.51261602': __('identifierSchemeCodeUnspsc190501.51261602'),
	'identifierSchemeCodeUnspsc190501.51261603': __('identifierSchemeCodeUnspsc190501.51261603'),
	'identifierSchemeCodeUnspsc190501.51261700': __('identifierSchemeCodeUnspsc190501.51261700'),
	'identifierSchemeCodeUnspsc190501.51261701': __('identifierSchemeCodeUnspsc190501.51261701'),
	'identifierSchemeCodeUnspsc190501.51261702': __('identifierSchemeCodeUnspsc190501.51261702'),
	'identifierSchemeCodeUnspsc190501.51261703': __('identifierSchemeCodeUnspsc190501.51261703'),
	'identifierSchemeCodeUnspsc190501.51261704': __('identifierSchemeCodeUnspsc190501.51261704'),
	'identifierSchemeCodeUnspsc190501.51261705': __('identifierSchemeCodeUnspsc190501.51261705'),
	'identifierSchemeCodeUnspsc190501.51261706': __('identifierSchemeCodeUnspsc190501.51261706'),
	'identifierSchemeCodeUnspsc190501.51261800': __('identifierSchemeCodeUnspsc190501.51261800'),
	'identifierSchemeCodeUnspsc190501.51261801': __('identifierSchemeCodeUnspsc190501.51261801'),
	'identifierSchemeCodeUnspsc190501.51261802': __('identifierSchemeCodeUnspsc190501.51261802'),
	'identifierSchemeCodeUnspsc190501.51262000': __('identifierSchemeCodeUnspsc190501.51262000'),
	'identifierSchemeCodeUnspsc190501.51262001': __('identifierSchemeCodeUnspsc190501.51262001'),
	'identifierSchemeCodeUnspsc190501.51262002': __('identifierSchemeCodeUnspsc190501.51262002'),
	'identifierSchemeCodeUnspsc190501.51262003': __('identifierSchemeCodeUnspsc190501.51262003'),
	'identifierSchemeCodeUnspsc190501.51262004': __('identifierSchemeCodeUnspsc190501.51262004'),
	'identifierSchemeCodeUnspsc190501.51262005': __('identifierSchemeCodeUnspsc190501.51262005'),
	'identifierSchemeCodeUnspsc190501.51262006': __('identifierSchemeCodeUnspsc190501.51262006'),
	'identifierSchemeCodeUnspsc190501.51262007': __('identifierSchemeCodeUnspsc190501.51262007'),
	'identifierSchemeCodeUnspsc190501.51262100': __('identifierSchemeCodeUnspsc190501.51262100'),
	'identifierSchemeCodeUnspsc190501.51262101': __('identifierSchemeCodeUnspsc190501.51262101'),
	'identifierSchemeCodeUnspsc190501.51262102': __('identifierSchemeCodeUnspsc190501.51262102'),
	'identifierSchemeCodeUnspsc190501.51262103': __('identifierSchemeCodeUnspsc190501.51262103'),
	'identifierSchemeCodeUnspsc190501.51262104': __('identifierSchemeCodeUnspsc190501.51262104'),
	'identifierSchemeCodeUnspsc190501.51262200': __('identifierSchemeCodeUnspsc190501.51262200'),
	'identifierSchemeCodeUnspsc190501.51262201': __('identifierSchemeCodeUnspsc190501.51262201'),
	'identifierSchemeCodeUnspsc190501.51262202': __('identifierSchemeCodeUnspsc190501.51262202'),
	'identifierSchemeCodeUnspsc190501.51262203': __('identifierSchemeCodeUnspsc190501.51262203'),
	'identifierSchemeCodeUnspsc190501.51262204': __('identifierSchemeCodeUnspsc190501.51262204'),
	'identifierSchemeCodeUnspsc190501.51262205': __('identifierSchemeCodeUnspsc190501.51262205'),
	'identifierSchemeCodeUnspsc190501.51262300': __('identifierSchemeCodeUnspsc190501.51262300'),
	'identifierSchemeCodeUnspsc190501.51262301': __('identifierSchemeCodeUnspsc190501.51262301'),
	'identifierSchemeCodeUnspsc190501.51262302': __('identifierSchemeCodeUnspsc190501.51262302'),
	'identifierSchemeCodeUnspsc190501.51262303': __('identifierSchemeCodeUnspsc190501.51262303'),
	'identifierSchemeCodeUnspsc190501.51262304': __('identifierSchemeCodeUnspsc190501.51262304'),
	'identifierSchemeCodeUnspsc190501.51262305': __('identifierSchemeCodeUnspsc190501.51262305'),
	'identifierSchemeCodeUnspsc190501.51262306': __('identifierSchemeCodeUnspsc190501.51262306'),
	'identifierSchemeCodeUnspsc190501.51262307': __('identifierSchemeCodeUnspsc190501.51262307'),
	'identifierSchemeCodeUnspsc190501.51262400': __('identifierSchemeCodeUnspsc190501.51262400'),
	'identifierSchemeCodeUnspsc190501.51262401': __('identifierSchemeCodeUnspsc190501.51262401'),
	'identifierSchemeCodeUnspsc190501.51262402': __('identifierSchemeCodeUnspsc190501.51262402'),
	'identifierSchemeCodeUnspsc190501.51262403': __('identifierSchemeCodeUnspsc190501.51262403'),
	'identifierSchemeCodeUnspsc190501.51262404': __('identifierSchemeCodeUnspsc190501.51262404'),
	'identifierSchemeCodeUnspsc190501.51262406': __('identifierSchemeCodeUnspsc190501.51262406'),
	'identifierSchemeCodeUnspsc190501.51262407': __('identifierSchemeCodeUnspsc190501.51262407'),
	'identifierSchemeCodeUnspsc190501.51262408': __('identifierSchemeCodeUnspsc190501.51262408'),
	'identifierSchemeCodeUnspsc190501.51262409': __('identifierSchemeCodeUnspsc190501.51262409'),
	'identifierSchemeCodeUnspsc190501.51262410': __('identifierSchemeCodeUnspsc190501.51262410'),
	'identifierSchemeCodeUnspsc190501.51262411': __('identifierSchemeCodeUnspsc190501.51262411'),
	'identifierSchemeCodeUnspsc190501.51262412': __('identifierSchemeCodeUnspsc190501.51262412'),
	'identifierSchemeCodeUnspsc190501.51262413': __('identifierSchemeCodeUnspsc190501.51262413'),
	'identifierSchemeCodeUnspsc190501.51262414': __('identifierSchemeCodeUnspsc190501.51262414'),
	'identifierSchemeCodeUnspsc190501.51262500': __('identifierSchemeCodeUnspsc190501.51262500'),
	'identifierSchemeCodeUnspsc190501.51262501': __('identifierSchemeCodeUnspsc190501.51262501'),
	'identifierSchemeCodeUnspsc190501.51262502': __('identifierSchemeCodeUnspsc190501.51262502'),
	'identifierSchemeCodeUnspsc190501.51262503': __('identifierSchemeCodeUnspsc190501.51262503'),
	'identifierSchemeCodeUnspsc190501.51262504': __('identifierSchemeCodeUnspsc190501.51262504'),
	'identifierSchemeCodeUnspsc190501.51262505': __('identifierSchemeCodeUnspsc190501.51262505'),
	'identifierSchemeCodeUnspsc190501.51262506': __('identifierSchemeCodeUnspsc190501.51262506'),
	'identifierSchemeCodeUnspsc190501.51262507': __('identifierSchemeCodeUnspsc190501.51262507'),
	'identifierSchemeCodeUnspsc190501.51262600': __('identifierSchemeCodeUnspsc190501.51262600'),
	'identifierSchemeCodeUnspsc190501.51262601': __('identifierSchemeCodeUnspsc190501.51262601'),
	'identifierSchemeCodeUnspsc190501.51262602': __('identifierSchemeCodeUnspsc190501.51262602'),
	'identifierSchemeCodeUnspsc190501.51262603': __('identifierSchemeCodeUnspsc190501.51262603'),
	'identifierSchemeCodeUnspsc190501.51262604': __('identifierSchemeCodeUnspsc190501.51262604'),
	'identifierSchemeCodeUnspsc190501.51262605': __('identifierSchemeCodeUnspsc190501.51262605'),
	'identifierSchemeCodeUnspsc190501.51262606': __('identifierSchemeCodeUnspsc190501.51262606'),
	'identifierSchemeCodeUnspsc190501.51262607': __('identifierSchemeCodeUnspsc190501.51262607'),
	'identifierSchemeCodeUnspsc190501.51262608': __('identifierSchemeCodeUnspsc190501.51262608'),
	'identifierSchemeCodeUnspsc190501.51262609': __('identifierSchemeCodeUnspsc190501.51262609'),
	'identifierSchemeCodeUnspsc190501.51262700': __('identifierSchemeCodeUnspsc190501.51262700'),
	'identifierSchemeCodeUnspsc190501.51262701': __('identifierSchemeCodeUnspsc190501.51262701'),
	'identifierSchemeCodeUnspsc190501.51262702': __('identifierSchemeCodeUnspsc190501.51262702'),
	'identifierSchemeCodeUnspsc190501.51262703': __('identifierSchemeCodeUnspsc190501.51262703'),
	'identifierSchemeCodeUnspsc190501.51262900': __('identifierSchemeCodeUnspsc190501.51262900'),
	'identifierSchemeCodeUnspsc190501.51262901': __('identifierSchemeCodeUnspsc190501.51262901'),
	'identifierSchemeCodeUnspsc190501.51262902': __('identifierSchemeCodeUnspsc190501.51262902'),
	'identifierSchemeCodeUnspsc190501.51262903': __('identifierSchemeCodeUnspsc190501.51262903'),
	'identifierSchemeCodeUnspsc190501.51262904': __('identifierSchemeCodeUnspsc190501.51262904'),
	'identifierSchemeCodeUnspsc190501.51262905': __('identifierSchemeCodeUnspsc190501.51262905'),
	'identifierSchemeCodeUnspsc190501.51262906': __('identifierSchemeCodeUnspsc190501.51262906'),
	'identifierSchemeCodeUnspsc190501.51262907': __('identifierSchemeCodeUnspsc190501.51262907'),
	'identifierSchemeCodeUnspsc190501.51262908': __('identifierSchemeCodeUnspsc190501.51262908'),
	'identifierSchemeCodeUnspsc190501.51262909': __('identifierSchemeCodeUnspsc190501.51262909'),
	'identifierSchemeCodeUnspsc190501.51262910': __('identifierSchemeCodeUnspsc190501.51262910'),
	'identifierSchemeCodeUnspsc190501.51262911': __('identifierSchemeCodeUnspsc190501.51262911'),
	'identifierSchemeCodeUnspsc190501.51262912': __('identifierSchemeCodeUnspsc190501.51262912'),
	'identifierSchemeCodeUnspsc190501.51262913': __('identifierSchemeCodeUnspsc190501.51262913'),
	'identifierSchemeCodeUnspsc190501.51262914': __('identifierSchemeCodeUnspsc190501.51262914'),
	'identifierSchemeCodeUnspsc190501.51263000': __('identifierSchemeCodeUnspsc190501.51263000'),
	'identifierSchemeCodeUnspsc190501.51263001': __('identifierSchemeCodeUnspsc190501.51263001'),
	'identifierSchemeCodeUnspsc190501.51263002': __('identifierSchemeCodeUnspsc190501.51263002'),
	'identifierSchemeCodeUnspsc190501.51263003': __('identifierSchemeCodeUnspsc190501.51263003'),
	'identifierSchemeCodeUnspsc190501.51263004': __('identifierSchemeCodeUnspsc190501.51263004'),
	'identifierSchemeCodeUnspsc190501.51263005': __('identifierSchemeCodeUnspsc190501.51263005'),
	'identifierSchemeCodeUnspsc190501.51263006': __('identifierSchemeCodeUnspsc190501.51263006'),
	'identifierSchemeCodeUnspsc190501.51263007': __('identifierSchemeCodeUnspsc190501.51263007'),
	'identifierSchemeCodeUnspsc190501.51263008': __('identifierSchemeCodeUnspsc190501.51263008'),
	'identifierSchemeCodeUnspsc190501.51263009': __('identifierSchemeCodeUnspsc190501.51263009'),
	'identifierSchemeCodeUnspsc190501.51263010': __('identifierSchemeCodeUnspsc190501.51263010'),
	'identifierSchemeCodeUnspsc190501.51263011': __('identifierSchemeCodeUnspsc190501.51263011'),
	'identifierSchemeCodeUnspsc190501.51263012': __('identifierSchemeCodeUnspsc190501.51263012'),
	'identifierSchemeCodeUnspsc190501.51263013': __('identifierSchemeCodeUnspsc190501.51263013'),
	'identifierSchemeCodeUnspsc190501.51263014': __('identifierSchemeCodeUnspsc190501.51263014'),
	'identifierSchemeCodeUnspsc190501.51263015': __('identifierSchemeCodeUnspsc190501.51263015'),
	'identifierSchemeCodeUnspsc190501.51263016': __('identifierSchemeCodeUnspsc190501.51263016'),
	'identifierSchemeCodeUnspsc190501.51263017': __('identifierSchemeCodeUnspsc190501.51263017'),
	'identifierSchemeCodeUnspsc190501.51263100': __('identifierSchemeCodeUnspsc190501.51263100'),
	'identifierSchemeCodeUnspsc190501.51263101': __('identifierSchemeCodeUnspsc190501.51263101'),
	'identifierSchemeCodeUnspsc190501.51263102': __('identifierSchemeCodeUnspsc190501.51263102'),
	'identifierSchemeCodeUnspsc190501.51263103': __('identifierSchemeCodeUnspsc190501.51263103'),
	'identifierSchemeCodeUnspsc190501.51263104': __('identifierSchemeCodeUnspsc190501.51263104'),
	'identifierSchemeCodeUnspsc190501.51263105': __('identifierSchemeCodeUnspsc190501.51263105'),
	'identifierSchemeCodeUnspsc190501.51263106': __('identifierSchemeCodeUnspsc190501.51263106'),
	'identifierSchemeCodeUnspsc190501.51263107': __('identifierSchemeCodeUnspsc190501.51263107'),
	'identifierSchemeCodeUnspsc190501.51263108': __('identifierSchemeCodeUnspsc190501.51263108'),
	'identifierSchemeCodeUnspsc190501.51263109': __('identifierSchemeCodeUnspsc190501.51263109'),
	'identifierSchemeCodeUnspsc190501.51263110': __('identifierSchemeCodeUnspsc190501.51263110'),
	'identifierSchemeCodeUnspsc190501.51263111': __('identifierSchemeCodeUnspsc190501.51263111'),
	'identifierSchemeCodeUnspsc190501.51263112': __('identifierSchemeCodeUnspsc190501.51263112'),
	'identifierSchemeCodeUnspsc190501.51263113': __('identifierSchemeCodeUnspsc190501.51263113'),
	'identifierSchemeCodeUnspsc190501.51263114': __('identifierSchemeCodeUnspsc190501.51263114'),
	'identifierSchemeCodeUnspsc190501.51263115': __('identifierSchemeCodeUnspsc190501.51263115'),
	'identifierSchemeCodeUnspsc190501.51263116': __('identifierSchemeCodeUnspsc190501.51263116'),
	'identifierSchemeCodeUnspsc190501.51263117': __('identifierSchemeCodeUnspsc190501.51263117'),
	'identifierSchemeCodeUnspsc190501.51263118': __('identifierSchemeCodeUnspsc190501.51263118'),
	'identifierSchemeCodeUnspsc190501.51263119': __('identifierSchemeCodeUnspsc190501.51263119'),
	'identifierSchemeCodeUnspsc190501.51263120': __('identifierSchemeCodeUnspsc190501.51263120'),
	'identifierSchemeCodeUnspsc190501.51263121': __('identifierSchemeCodeUnspsc190501.51263121'),
	'identifierSchemeCodeUnspsc190501.51263122': __('identifierSchemeCodeUnspsc190501.51263122'),
	'identifierSchemeCodeUnspsc190501.51263123': __('identifierSchemeCodeUnspsc190501.51263123'),
	'identifierSchemeCodeUnspsc190501.51263124': __('identifierSchemeCodeUnspsc190501.51263124'),
	'identifierSchemeCodeUnspsc190501.51263125': __('identifierSchemeCodeUnspsc190501.51263125'),
	'identifierSchemeCodeUnspsc190501.51263126': __('identifierSchemeCodeUnspsc190501.51263126'),
	'identifierSchemeCodeUnspsc190501.51263127': __('identifierSchemeCodeUnspsc190501.51263127'),
	'identifierSchemeCodeUnspsc190501.51263128': __('identifierSchemeCodeUnspsc190501.51263128'),
	'identifierSchemeCodeUnspsc190501.51263129': __('identifierSchemeCodeUnspsc190501.51263129'),
	'identifierSchemeCodeUnspsc190501.51263130': __('identifierSchemeCodeUnspsc190501.51263130'),
	'identifierSchemeCodeUnspsc190501.51263131': __('identifierSchemeCodeUnspsc190501.51263131'),
	'identifierSchemeCodeUnspsc190501.51263132': __('identifierSchemeCodeUnspsc190501.51263132'),
	'identifierSchemeCodeUnspsc190501.51263133': __('identifierSchemeCodeUnspsc190501.51263133'),
	'identifierSchemeCodeUnspsc190501.51263134': __('identifierSchemeCodeUnspsc190501.51263134'),
	'identifierSchemeCodeUnspsc190501.51263135': __('identifierSchemeCodeUnspsc190501.51263135'),
	'identifierSchemeCodeUnspsc190501.51263136': __('identifierSchemeCodeUnspsc190501.51263136'),
	'identifierSchemeCodeUnspsc190501.51263137': __('identifierSchemeCodeUnspsc190501.51263137'),
	'identifierSchemeCodeUnspsc190501.51263138': __('identifierSchemeCodeUnspsc190501.51263138'),
	'identifierSchemeCodeUnspsc190501.51263139': __('identifierSchemeCodeUnspsc190501.51263139'),
	'identifierSchemeCodeUnspsc190501.51263140': __('identifierSchemeCodeUnspsc190501.51263140'),
	'identifierSchemeCodeUnspsc190501.51263141': __('identifierSchemeCodeUnspsc190501.51263141'),
	'identifierSchemeCodeUnspsc190501.51263142': __('identifierSchemeCodeUnspsc190501.51263142'),
	'identifierSchemeCodeUnspsc190501.51263143': __('identifierSchemeCodeUnspsc190501.51263143'),
	'identifierSchemeCodeUnspsc190501.51263144': __('identifierSchemeCodeUnspsc190501.51263144'),
	'identifierSchemeCodeUnspsc190501.51263145': __('identifierSchemeCodeUnspsc190501.51263145'),
	'identifierSchemeCodeUnspsc190501.51263146': __('identifierSchemeCodeUnspsc190501.51263146'),
	'identifierSchemeCodeUnspsc190501.51263147': __('identifierSchemeCodeUnspsc190501.51263147'),
	'identifierSchemeCodeUnspsc190501.51263148': __('identifierSchemeCodeUnspsc190501.51263148'),
	'identifierSchemeCodeUnspsc190501.51263149': __('identifierSchemeCodeUnspsc190501.51263149'),
	'identifierSchemeCodeUnspsc190501.51263150': __('identifierSchemeCodeUnspsc190501.51263150'),
	'identifierSchemeCodeUnspsc190501.51263151': __('identifierSchemeCodeUnspsc190501.51263151'),
	'identifierSchemeCodeUnspsc190501.51263152': __('identifierSchemeCodeUnspsc190501.51263152'),
	'identifierSchemeCodeUnspsc190501.51263153': __('identifierSchemeCodeUnspsc190501.51263153'),
	'identifierSchemeCodeUnspsc190501.51263154': __('identifierSchemeCodeUnspsc190501.51263154'),
	'identifierSchemeCodeUnspsc190501.51263155': __('identifierSchemeCodeUnspsc190501.51263155'),
	'identifierSchemeCodeUnspsc190501.51263156': __('identifierSchemeCodeUnspsc190501.51263156'),
	'identifierSchemeCodeUnspsc190501.51263157': __('identifierSchemeCodeUnspsc190501.51263157'),
	'identifierSchemeCodeUnspsc190501.51263158': __('identifierSchemeCodeUnspsc190501.51263158'),
	'identifierSchemeCodeUnspsc190501.51263159': __('identifierSchemeCodeUnspsc190501.51263159'),
	'identifierSchemeCodeUnspsc190501.51263160': __('identifierSchemeCodeUnspsc190501.51263160'),
	'identifierSchemeCodeUnspsc190501.51263161': __('identifierSchemeCodeUnspsc190501.51263161'),
	'identifierSchemeCodeUnspsc190501.51263162': __('identifierSchemeCodeUnspsc190501.51263162'),
	'identifierSchemeCodeUnspsc190501.51263163': __('identifierSchemeCodeUnspsc190501.51263163'),
	'identifierSchemeCodeUnspsc190501.51263164': __('identifierSchemeCodeUnspsc190501.51263164'),
	'identifierSchemeCodeUnspsc190501.51263165': __('identifierSchemeCodeUnspsc190501.51263165'),
	'identifierSchemeCodeUnspsc190501.51263166': __('identifierSchemeCodeUnspsc190501.51263166'),
	'identifierSchemeCodeUnspsc190501.51263167': __('identifierSchemeCodeUnspsc190501.51263167'),
	'identifierSchemeCodeUnspsc190501.51263168': __('identifierSchemeCodeUnspsc190501.51263168'),
	'identifierSchemeCodeUnspsc190501.51263169': __('identifierSchemeCodeUnspsc190501.51263169'),
	'identifierSchemeCodeUnspsc190501.51263170': __('identifierSchemeCodeUnspsc190501.51263170'),
	'identifierSchemeCodeUnspsc190501.51263171': __('identifierSchemeCodeUnspsc190501.51263171'),
	'identifierSchemeCodeUnspsc190501.51263172': __('identifierSchemeCodeUnspsc190501.51263172'),
	'identifierSchemeCodeUnspsc190501.51263173': __('identifierSchemeCodeUnspsc190501.51263173'),
	'identifierSchemeCodeUnspsc190501.51263174': __('identifierSchemeCodeUnspsc190501.51263174'),
	'identifierSchemeCodeUnspsc190501.51263175': __('identifierSchemeCodeUnspsc190501.51263175'),
	'identifierSchemeCodeUnspsc190501.51263300': __('identifierSchemeCodeUnspsc190501.51263300'),
	'identifierSchemeCodeUnspsc190501.51263301': __('identifierSchemeCodeUnspsc190501.51263301'),
	'identifierSchemeCodeUnspsc190501.51263302': __('identifierSchemeCodeUnspsc190501.51263302'),
	'identifierSchemeCodeUnspsc190501.51263303': __('identifierSchemeCodeUnspsc190501.51263303'),
	'identifierSchemeCodeUnspsc190501.51263304': __('identifierSchemeCodeUnspsc190501.51263304'),
	'identifierSchemeCodeUnspsc190501.51263305': __('identifierSchemeCodeUnspsc190501.51263305'),
	'identifierSchemeCodeUnspsc190501.51263306': __('identifierSchemeCodeUnspsc190501.51263306'),
	'identifierSchemeCodeUnspsc190501.51263307': __('identifierSchemeCodeUnspsc190501.51263307'),
	'identifierSchemeCodeUnspsc190501.51263308': __('identifierSchemeCodeUnspsc190501.51263308'),
	'identifierSchemeCodeUnspsc190501.51263309': __('identifierSchemeCodeUnspsc190501.51263309'),
	'identifierSchemeCodeUnspsc190501.51263310': __('identifierSchemeCodeUnspsc190501.51263310'),
	'identifierSchemeCodeUnspsc190501.51263311': __('identifierSchemeCodeUnspsc190501.51263311'),
	'identifierSchemeCodeUnspsc190501.51263312': __('identifierSchemeCodeUnspsc190501.51263312'),
	'identifierSchemeCodeUnspsc190501.51263313': __('identifierSchemeCodeUnspsc190501.51263313'),
	'identifierSchemeCodeUnspsc190501.51263314': __('identifierSchemeCodeUnspsc190501.51263314'),
	'identifierSchemeCodeUnspsc190501.51263315': __('identifierSchemeCodeUnspsc190501.51263315'),
	'identifierSchemeCodeUnspsc190501.51263400': __('identifierSchemeCodeUnspsc190501.51263400'),
	'identifierSchemeCodeUnspsc190501.51263401': __('identifierSchemeCodeUnspsc190501.51263401'),
	'identifierSchemeCodeUnspsc190501.51263402': __('identifierSchemeCodeUnspsc190501.51263402'),
	'identifierSchemeCodeUnspsc190501.51263403': __('identifierSchemeCodeUnspsc190501.51263403'),
	'identifierSchemeCodeUnspsc190501.51263404': __('identifierSchemeCodeUnspsc190501.51263404'),
	'identifierSchemeCodeUnspsc190501.51263405': __('identifierSchemeCodeUnspsc190501.51263405'),
	'identifierSchemeCodeUnspsc190501.51263406': __('identifierSchemeCodeUnspsc190501.51263406'),
	'identifierSchemeCodeUnspsc190501.51263407': __('identifierSchemeCodeUnspsc190501.51263407'),
	'identifierSchemeCodeUnspsc190501.51263500': __('identifierSchemeCodeUnspsc190501.51263500'),
	'identifierSchemeCodeUnspsc190501.51263501': __('identifierSchemeCodeUnspsc190501.51263501'),
	'identifierSchemeCodeUnspsc190501.51263502': __('identifierSchemeCodeUnspsc190501.51263502'),
	'identifierSchemeCodeUnspsc190501.51263503': __('identifierSchemeCodeUnspsc190501.51263503'),
	'identifierSchemeCodeUnspsc190501.51263504': __('identifierSchemeCodeUnspsc190501.51263504'),
	'identifierSchemeCodeUnspsc190501.51263600': __('identifierSchemeCodeUnspsc190501.51263600'),
	'identifierSchemeCodeUnspsc190501.51263601': __('identifierSchemeCodeUnspsc190501.51263601'),
	'identifierSchemeCodeUnspsc190501.51263602': __('identifierSchemeCodeUnspsc190501.51263602'),
	'identifierSchemeCodeUnspsc190501.51263603': __('identifierSchemeCodeUnspsc190501.51263603'),
	'identifierSchemeCodeUnspsc190501.51263604': __('identifierSchemeCodeUnspsc190501.51263604'),
	'identifierSchemeCodeUnspsc190501.51263700': __('identifierSchemeCodeUnspsc190501.51263700'),
	'identifierSchemeCodeUnspsc190501.51263701': __('identifierSchemeCodeUnspsc190501.51263701'),
	'identifierSchemeCodeUnspsc190501.51263702': __('identifierSchemeCodeUnspsc190501.51263702'),
	'identifierSchemeCodeUnspsc190501.51263703': __('identifierSchemeCodeUnspsc190501.51263703'),
	'identifierSchemeCodeUnspsc190501.51263704': __('identifierSchemeCodeUnspsc190501.51263704'),
	'identifierSchemeCodeUnspsc190501.51263705': __('identifierSchemeCodeUnspsc190501.51263705'),
	'identifierSchemeCodeUnspsc190501.51270000': __('identifierSchemeCodeUnspsc190501.51270000'),
	'identifierSchemeCodeUnspsc190501.51271500': __('identifierSchemeCodeUnspsc190501.51271500'),
	'identifierSchemeCodeUnspsc190501.51271501': __('identifierSchemeCodeUnspsc190501.51271501'),
	'identifierSchemeCodeUnspsc190501.51271502': __('identifierSchemeCodeUnspsc190501.51271502'),
	'identifierSchemeCodeUnspsc190501.51271503': __('identifierSchemeCodeUnspsc190501.51271503'),
	'identifierSchemeCodeUnspsc190501.51271504': __('identifierSchemeCodeUnspsc190501.51271504'),
	'identifierSchemeCodeUnspsc190501.51271505': __('identifierSchemeCodeUnspsc190501.51271505'),
	'identifierSchemeCodeUnspsc190501.51271600': __('identifierSchemeCodeUnspsc190501.51271600'),
	'identifierSchemeCodeUnspsc190501.51271601': __('identifierSchemeCodeUnspsc190501.51271601'),
	'identifierSchemeCodeUnspsc190501.51271602': __('identifierSchemeCodeUnspsc190501.51271602'),
	'identifierSchemeCodeUnspsc190501.51271603': __('identifierSchemeCodeUnspsc190501.51271603'),
	'identifierSchemeCodeUnspsc190501.51271604': __('identifierSchemeCodeUnspsc190501.51271604'),
	'identifierSchemeCodeUnspsc190501.51271605': __('identifierSchemeCodeUnspsc190501.51271605'),
	'identifierSchemeCodeUnspsc190501.51271606': __('identifierSchemeCodeUnspsc190501.51271606'),
	'identifierSchemeCodeUnspsc190501.51271607': __('identifierSchemeCodeUnspsc190501.51271607'),
	'identifierSchemeCodeUnspsc190501.51271608': __('identifierSchemeCodeUnspsc190501.51271608'),
	'identifierSchemeCodeUnspsc190501.51271609': __('identifierSchemeCodeUnspsc190501.51271609'),
	'identifierSchemeCodeUnspsc190501.51271610': __('identifierSchemeCodeUnspsc190501.51271610'),
	'identifierSchemeCodeUnspsc190501.51271611': __('identifierSchemeCodeUnspsc190501.51271611'),
	'identifierSchemeCodeUnspsc190501.51271612': __('identifierSchemeCodeUnspsc190501.51271612'),
	'identifierSchemeCodeUnspsc190501.51271613': __('identifierSchemeCodeUnspsc190501.51271613'),
	'identifierSchemeCodeUnspsc190501.51271614': __('identifierSchemeCodeUnspsc190501.51271614'),
	'identifierSchemeCodeUnspsc190501.51271615': __('identifierSchemeCodeUnspsc190501.51271615'),
	'identifierSchemeCodeUnspsc190501.51271616': __('identifierSchemeCodeUnspsc190501.51271616'),
	'identifierSchemeCodeUnspsc190501.51271617': __('identifierSchemeCodeUnspsc190501.51271617'),
	'identifierSchemeCodeUnspsc190501.51271618': __('identifierSchemeCodeUnspsc190501.51271618'),
	'identifierSchemeCodeUnspsc190501.51271619': __('identifierSchemeCodeUnspsc190501.51271619'),
	'identifierSchemeCodeUnspsc190501.51271620': __('identifierSchemeCodeUnspsc190501.51271620'),
	'identifierSchemeCodeUnspsc190501.51271621': __('identifierSchemeCodeUnspsc190501.51271621'),
	'identifierSchemeCodeUnspsc190501.51271622': __('identifierSchemeCodeUnspsc190501.51271622'),
	'identifierSchemeCodeUnspsc190501.51271623': __('identifierSchemeCodeUnspsc190501.51271623'),
	'identifierSchemeCodeUnspsc190501.51271624': __('identifierSchemeCodeUnspsc190501.51271624'),
	'identifierSchemeCodeUnspsc190501.51271625': __('identifierSchemeCodeUnspsc190501.51271625'),
	'identifierSchemeCodeUnspsc190501.51271626': __('identifierSchemeCodeUnspsc190501.51271626'),
	'identifierSchemeCodeUnspsc190501.51271627': __('identifierSchemeCodeUnspsc190501.51271627'),
	'identifierSchemeCodeUnspsc190501.51271628': __('identifierSchemeCodeUnspsc190501.51271628'),
	'identifierSchemeCodeUnspsc190501.51271629': __('identifierSchemeCodeUnspsc190501.51271629'),
	'identifierSchemeCodeUnspsc190501.51271630': __('identifierSchemeCodeUnspsc190501.51271630'),
	'identifierSchemeCodeUnspsc190501.51271631': __('identifierSchemeCodeUnspsc190501.51271631'),
	'identifierSchemeCodeUnspsc190501.51271632': __('identifierSchemeCodeUnspsc190501.51271632'),
	'identifierSchemeCodeUnspsc190501.51271633': __('identifierSchemeCodeUnspsc190501.51271633'),
	'identifierSchemeCodeUnspsc190501.51271634': __('identifierSchemeCodeUnspsc190501.51271634'),
	'identifierSchemeCodeUnspsc190501.51271635': __('identifierSchemeCodeUnspsc190501.51271635'),
	'identifierSchemeCodeUnspsc190501.51271636': __('identifierSchemeCodeUnspsc190501.51271636'),
	'identifierSchemeCodeUnspsc190501.51271700': __('identifierSchemeCodeUnspsc190501.51271700'),
	'identifierSchemeCodeUnspsc190501.51271701': __('identifierSchemeCodeUnspsc190501.51271701'),
	'identifierSchemeCodeUnspsc190501.51271702': __('identifierSchemeCodeUnspsc190501.51271702'),
	'identifierSchemeCodeUnspsc190501.51271703': __('identifierSchemeCodeUnspsc190501.51271703'),
	'identifierSchemeCodeUnspsc190501.51271704': __('identifierSchemeCodeUnspsc190501.51271704'),
	'identifierSchemeCodeUnspsc190501.51271705': __('identifierSchemeCodeUnspsc190501.51271705'),
	'identifierSchemeCodeUnspsc190501.51271706': __('identifierSchemeCodeUnspsc190501.51271706'),
	'identifierSchemeCodeUnspsc190501.51271707': __('identifierSchemeCodeUnspsc190501.51271707'),
	'identifierSchemeCodeUnspsc190501.51271800': __('identifierSchemeCodeUnspsc190501.51271800'),
	'identifierSchemeCodeUnspsc190501.51271801': __('identifierSchemeCodeUnspsc190501.51271801'),
	'identifierSchemeCodeUnspsc190501.51271802': __('identifierSchemeCodeUnspsc190501.51271802'),
	'identifierSchemeCodeUnspsc190501.51271803': __('identifierSchemeCodeUnspsc190501.51271803'),
	'identifierSchemeCodeUnspsc190501.51271804': __('identifierSchemeCodeUnspsc190501.51271804'),
	'identifierSchemeCodeUnspsc190501.51271805': __('identifierSchemeCodeUnspsc190501.51271805'),
	'identifierSchemeCodeUnspsc190501.51271806': __('identifierSchemeCodeUnspsc190501.51271806'),
	'identifierSchemeCodeUnspsc190501.51271807': __('identifierSchemeCodeUnspsc190501.51271807'),
	'identifierSchemeCodeUnspsc190501.51271900': __('identifierSchemeCodeUnspsc190501.51271900'),
	'identifierSchemeCodeUnspsc190501.51271901': __('identifierSchemeCodeUnspsc190501.51271901'),
	'identifierSchemeCodeUnspsc190501.51271902': __('identifierSchemeCodeUnspsc190501.51271902'),
	'identifierSchemeCodeUnspsc190501.51271904': __('identifierSchemeCodeUnspsc190501.51271904'),
	'identifierSchemeCodeUnspsc190501.51271905': __('identifierSchemeCodeUnspsc190501.51271905'),
	'identifierSchemeCodeUnspsc190501.51271906': __('identifierSchemeCodeUnspsc190501.51271906'),
	'identifierSchemeCodeUnspsc190501.51271907': __('identifierSchemeCodeUnspsc190501.51271907'),
	'identifierSchemeCodeUnspsc190501.51271908': __('identifierSchemeCodeUnspsc190501.51271908'),
	'identifierSchemeCodeUnspsc190501.51271909': __('identifierSchemeCodeUnspsc190501.51271909'),
	'identifierSchemeCodeUnspsc190501.51271910': __('identifierSchemeCodeUnspsc190501.51271910'),
	'identifierSchemeCodeUnspsc190501.51271911': __('identifierSchemeCodeUnspsc190501.51271911'),
	'identifierSchemeCodeUnspsc190501.51271912': __('identifierSchemeCodeUnspsc190501.51271912'),
	'identifierSchemeCodeUnspsc190501.51271913': __('identifierSchemeCodeUnspsc190501.51271913'),
	'identifierSchemeCodeUnspsc190501.51271914': __('identifierSchemeCodeUnspsc190501.51271914'),
	'identifierSchemeCodeUnspsc190501.51271915': __('identifierSchemeCodeUnspsc190501.51271915'),
	'identifierSchemeCodeUnspsc190501.51271916': __('identifierSchemeCodeUnspsc190501.51271916'),
	'identifierSchemeCodeUnspsc190501.51271917': __('identifierSchemeCodeUnspsc190501.51271917'),
	'identifierSchemeCodeUnspsc190501.51271918': __('identifierSchemeCodeUnspsc190501.51271918'),
	'identifierSchemeCodeUnspsc190501.51271919': __('identifierSchemeCodeUnspsc190501.51271919'),
	'identifierSchemeCodeUnspsc190501.51271920': __('identifierSchemeCodeUnspsc190501.51271920'),
	'identifierSchemeCodeUnspsc190501.51271921': __('identifierSchemeCodeUnspsc190501.51271921'),
	'identifierSchemeCodeUnspsc190501.51271922': __('identifierSchemeCodeUnspsc190501.51271922'),
	'identifierSchemeCodeUnspsc190501.51271923': __('identifierSchemeCodeUnspsc190501.51271923'),
	'identifierSchemeCodeUnspsc190501.51271924': __('identifierSchemeCodeUnspsc190501.51271924'),
	'identifierSchemeCodeUnspsc190501.51271925': __('identifierSchemeCodeUnspsc190501.51271925'),
	'identifierSchemeCodeUnspsc190501.51272000': __('identifierSchemeCodeUnspsc190501.51272000'),
	'identifierSchemeCodeUnspsc190501.51272001': __('identifierSchemeCodeUnspsc190501.51272001'),
	'identifierSchemeCodeUnspsc190501.51272002': __('identifierSchemeCodeUnspsc190501.51272002'),
	'identifierSchemeCodeUnspsc190501.51272003': __('identifierSchemeCodeUnspsc190501.51272003'),
	'identifierSchemeCodeUnspsc190501.51272004': __('identifierSchemeCodeUnspsc190501.51272004'),
	'identifierSchemeCodeUnspsc190501.51272005': __('identifierSchemeCodeUnspsc190501.51272005'),
	'identifierSchemeCodeUnspsc190501.51272006': __('identifierSchemeCodeUnspsc190501.51272006'),
	'identifierSchemeCodeUnspsc190501.51272100': __('identifierSchemeCodeUnspsc190501.51272100'),
	'identifierSchemeCodeUnspsc190501.51272101': __('identifierSchemeCodeUnspsc190501.51272101'),
	'identifierSchemeCodeUnspsc190501.51272102': __('identifierSchemeCodeUnspsc190501.51272102'),
	'identifierSchemeCodeUnspsc190501.51272103': __('identifierSchemeCodeUnspsc190501.51272103'),
	'identifierSchemeCodeUnspsc190501.51272104': __('identifierSchemeCodeUnspsc190501.51272104'),
	'identifierSchemeCodeUnspsc190501.51272105': __('identifierSchemeCodeUnspsc190501.51272105'),
	'identifierSchemeCodeUnspsc190501.51272106': __('identifierSchemeCodeUnspsc190501.51272106'),
	'identifierSchemeCodeUnspsc190501.51272107': __('identifierSchemeCodeUnspsc190501.51272107'),
	'identifierSchemeCodeUnspsc190501.51272108': __('identifierSchemeCodeUnspsc190501.51272108'),
	'identifierSchemeCodeUnspsc190501.51272109': __('identifierSchemeCodeUnspsc190501.51272109'),
	'identifierSchemeCodeUnspsc190501.51272110': __('identifierSchemeCodeUnspsc190501.51272110'),
	'identifierSchemeCodeUnspsc190501.51272111': __('identifierSchemeCodeUnspsc190501.51272111'),
	'identifierSchemeCodeUnspsc190501.51272112': __('identifierSchemeCodeUnspsc190501.51272112'),
	'identifierSchemeCodeUnspsc190501.51272113': __('identifierSchemeCodeUnspsc190501.51272113'),
	'identifierSchemeCodeUnspsc190501.51272114': __('identifierSchemeCodeUnspsc190501.51272114'),
	'identifierSchemeCodeUnspsc190501.51272115': __('identifierSchemeCodeUnspsc190501.51272115'),
	'identifierSchemeCodeUnspsc190501.51272116': __('identifierSchemeCodeUnspsc190501.51272116'),
	'identifierSchemeCodeUnspsc190501.51272200': __('identifierSchemeCodeUnspsc190501.51272200'),
	'identifierSchemeCodeUnspsc190501.51272201': __('identifierSchemeCodeUnspsc190501.51272201'),
	'identifierSchemeCodeUnspsc190501.51272202': __('identifierSchemeCodeUnspsc190501.51272202'),
	'identifierSchemeCodeUnspsc190501.51272203': __('identifierSchemeCodeUnspsc190501.51272203'),
	'identifierSchemeCodeUnspsc190501.51272204': __('identifierSchemeCodeUnspsc190501.51272204'),
	'identifierSchemeCodeUnspsc190501.51272205': __('identifierSchemeCodeUnspsc190501.51272205'),
	'identifierSchemeCodeUnspsc190501.51272206': __('identifierSchemeCodeUnspsc190501.51272206'),
	'identifierSchemeCodeUnspsc190501.51272207': __('identifierSchemeCodeUnspsc190501.51272207'),
	'identifierSchemeCodeUnspsc190501.51272208': __('identifierSchemeCodeUnspsc190501.51272208'),
	'identifierSchemeCodeUnspsc190501.51272300': __('identifierSchemeCodeUnspsc190501.51272300'),
	'identifierSchemeCodeUnspsc190501.51272301': __('identifierSchemeCodeUnspsc190501.51272301'),
	'identifierSchemeCodeUnspsc190501.51272302': __('identifierSchemeCodeUnspsc190501.51272302'),
	'identifierSchemeCodeUnspsc190501.51272303': __('identifierSchemeCodeUnspsc190501.51272303'),
	'identifierSchemeCodeUnspsc190501.51272304': __('identifierSchemeCodeUnspsc190501.51272304'),
	'identifierSchemeCodeUnspsc190501.51272305': __('identifierSchemeCodeUnspsc190501.51272305'),
	'identifierSchemeCodeUnspsc190501.51272306': __('identifierSchemeCodeUnspsc190501.51272306'),
	'identifierSchemeCodeUnspsc190501.51272307': __('identifierSchemeCodeUnspsc190501.51272307'),
	'identifierSchemeCodeUnspsc190501.51272400': __('identifierSchemeCodeUnspsc190501.51272400'),
	'identifierSchemeCodeUnspsc190501.51272401': __('identifierSchemeCodeUnspsc190501.51272401'),
	'identifierSchemeCodeUnspsc190501.51272402': __('identifierSchemeCodeUnspsc190501.51272402'),
	'identifierSchemeCodeUnspsc190501.51272403': __('identifierSchemeCodeUnspsc190501.51272403'),
	'identifierSchemeCodeUnspsc190501.51272404': __('identifierSchemeCodeUnspsc190501.51272404'),
	'identifierSchemeCodeUnspsc190501.51272405': __('identifierSchemeCodeUnspsc190501.51272405'),
	'identifierSchemeCodeUnspsc190501.51272406': __('identifierSchemeCodeUnspsc190501.51272406'),
	'identifierSchemeCodeUnspsc190501.51272407': __('identifierSchemeCodeUnspsc190501.51272407'),
	'identifierSchemeCodeUnspsc190501.51272408': __('identifierSchemeCodeUnspsc190501.51272408'),
	'identifierSchemeCodeUnspsc190501.51272500': __('identifierSchemeCodeUnspsc190501.51272500'),
	'identifierSchemeCodeUnspsc190501.51272501': __('identifierSchemeCodeUnspsc190501.51272501'),
	'identifierSchemeCodeUnspsc190501.51272502': __('identifierSchemeCodeUnspsc190501.51272502'),
	'identifierSchemeCodeUnspsc190501.51272503': __('identifierSchemeCodeUnspsc190501.51272503'),
	'identifierSchemeCodeUnspsc190501.51272504': __('identifierSchemeCodeUnspsc190501.51272504'),
	'identifierSchemeCodeUnspsc190501.51272600': __('identifierSchemeCodeUnspsc190501.51272600'),
	'identifierSchemeCodeUnspsc190501.51272601': __('identifierSchemeCodeUnspsc190501.51272601'),
	'identifierSchemeCodeUnspsc190501.51272602': __('identifierSchemeCodeUnspsc190501.51272602'),
	'identifierSchemeCodeUnspsc190501.51272603': __('identifierSchemeCodeUnspsc190501.51272603'),
	'identifierSchemeCodeUnspsc190501.51272604': __('identifierSchemeCodeUnspsc190501.51272604'),
	'identifierSchemeCodeUnspsc190501.51272605': __('identifierSchemeCodeUnspsc190501.51272605'),
	'identifierSchemeCodeUnspsc190501.51272700': __('identifierSchemeCodeUnspsc190501.51272700'),
	'identifierSchemeCodeUnspsc190501.51272701': __('identifierSchemeCodeUnspsc190501.51272701'),
	'identifierSchemeCodeUnspsc190501.51272702': __('identifierSchemeCodeUnspsc190501.51272702'),
	'identifierSchemeCodeUnspsc190501.51272703': __('identifierSchemeCodeUnspsc190501.51272703'),
	'identifierSchemeCodeUnspsc190501.51272704': __('identifierSchemeCodeUnspsc190501.51272704'),
	'identifierSchemeCodeUnspsc190501.51272705': __('identifierSchemeCodeUnspsc190501.51272705'),
	'identifierSchemeCodeUnspsc190501.51272706': __('identifierSchemeCodeUnspsc190501.51272706'),
	'identifierSchemeCodeUnspsc190501.51272707': __('identifierSchemeCodeUnspsc190501.51272707'),
	'identifierSchemeCodeUnspsc190501.51272708': __('identifierSchemeCodeUnspsc190501.51272708'),
	'identifierSchemeCodeUnspsc190501.51272709': __('identifierSchemeCodeUnspsc190501.51272709'),
	'identifierSchemeCodeUnspsc190501.51272900': __('identifierSchemeCodeUnspsc190501.51272900'),
	'identifierSchemeCodeUnspsc190501.51272901': __('identifierSchemeCodeUnspsc190501.51272901'),
	'identifierSchemeCodeUnspsc190501.51272902': __('identifierSchemeCodeUnspsc190501.51272902'),
	'identifierSchemeCodeUnspsc190501.51272903': __('identifierSchemeCodeUnspsc190501.51272903'),
	'identifierSchemeCodeUnspsc190501.51273000': __('identifierSchemeCodeUnspsc190501.51273000'),
	'identifierSchemeCodeUnspsc190501.51273001': __('identifierSchemeCodeUnspsc190501.51273001'),
	'identifierSchemeCodeUnspsc190501.51273002': __('identifierSchemeCodeUnspsc190501.51273002'),
	'identifierSchemeCodeUnspsc190501.51273003': __('identifierSchemeCodeUnspsc190501.51273003'),
	'identifierSchemeCodeUnspsc190501.51273004': __('identifierSchemeCodeUnspsc190501.51273004'),
	'identifierSchemeCodeUnspsc190501.51273005': __('identifierSchemeCodeUnspsc190501.51273005'),
	'identifierSchemeCodeUnspsc190501.51273006': __('identifierSchemeCodeUnspsc190501.51273006'),
	'identifierSchemeCodeUnspsc190501.51273007': __('identifierSchemeCodeUnspsc190501.51273007'),
	'identifierSchemeCodeUnspsc190501.51273008': __('identifierSchemeCodeUnspsc190501.51273008'),
	'identifierSchemeCodeUnspsc190501.51273009': __('identifierSchemeCodeUnspsc190501.51273009'),
	'identifierSchemeCodeUnspsc190501.51273010': __('identifierSchemeCodeUnspsc190501.51273010'),
	'identifierSchemeCodeUnspsc190501.51273011': __('identifierSchemeCodeUnspsc190501.51273011'),
	'identifierSchemeCodeUnspsc190501.51273012': __('identifierSchemeCodeUnspsc190501.51273012'),
	'identifierSchemeCodeUnspsc190501.51273013': __('identifierSchemeCodeUnspsc190501.51273013'),
	'identifierSchemeCodeUnspsc190501.51273014': __('identifierSchemeCodeUnspsc190501.51273014'),
	'identifierSchemeCodeUnspsc190501.51273015': __('identifierSchemeCodeUnspsc190501.51273015'),
	'identifierSchemeCodeUnspsc190501.51273016': __('identifierSchemeCodeUnspsc190501.51273016'),
	'identifierSchemeCodeUnspsc190501.51273017': __('identifierSchemeCodeUnspsc190501.51273017'),
	'identifierSchemeCodeUnspsc190501.51273018': __('identifierSchemeCodeUnspsc190501.51273018'),
	'identifierSchemeCodeUnspsc190501.51273019': __('identifierSchemeCodeUnspsc190501.51273019'),
	'identifierSchemeCodeUnspsc190501.51273020': __('identifierSchemeCodeUnspsc190501.51273020'),
	'identifierSchemeCodeUnspsc190501.51273021': __('identifierSchemeCodeUnspsc190501.51273021'),
	'identifierSchemeCodeUnspsc190501.51273022': __('identifierSchemeCodeUnspsc190501.51273022'),
	'identifierSchemeCodeUnspsc190501.51273023': __('identifierSchemeCodeUnspsc190501.51273023'),
	'identifierSchemeCodeUnspsc190501.51273024': __('identifierSchemeCodeUnspsc190501.51273024'),
	'identifierSchemeCodeUnspsc190501.51273100': __('identifierSchemeCodeUnspsc190501.51273100'),
	'identifierSchemeCodeUnspsc190501.51273101': __('identifierSchemeCodeUnspsc190501.51273101'),
	'identifierSchemeCodeUnspsc190501.51273102': __('identifierSchemeCodeUnspsc190501.51273102'),
	'identifierSchemeCodeUnspsc190501.51273103': __('identifierSchemeCodeUnspsc190501.51273103'),
	'identifierSchemeCodeUnspsc190501.51273104': __('identifierSchemeCodeUnspsc190501.51273104'),
	'identifierSchemeCodeUnspsc190501.51273105': __('identifierSchemeCodeUnspsc190501.51273105'),
	'identifierSchemeCodeUnspsc190501.51273300': __('identifierSchemeCodeUnspsc190501.51273300'),
	'identifierSchemeCodeUnspsc190501.51273301': __('identifierSchemeCodeUnspsc190501.51273301'),
	'identifierSchemeCodeUnspsc190501.51273302': __('identifierSchemeCodeUnspsc190501.51273302'),
	'identifierSchemeCodeUnspsc190501.51273303': __('identifierSchemeCodeUnspsc190501.51273303'),
	'identifierSchemeCodeUnspsc190501.51273304': __('identifierSchemeCodeUnspsc190501.51273304'),
	'identifierSchemeCodeUnspsc190501.51273305': __('identifierSchemeCodeUnspsc190501.51273305'),
	'identifierSchemeCodeUnspsc190501.51273600': __('identifierSchemeCodeUnspsc190501.51273600'),
	'identifierSchemeCodeUnspsc190501.51273601': __('identifierSchemeCodeUnspsc190501.51273601'),
	'identifierSchemeCodeUnspsc190501.51273602': __('identifierSchemeCodeUnspsc190501.51273602'),
	'identifierSchemeCodeUnspsc190501.51273603': __('identifierSchemeCodeUnspsc190501.51273603'),
	'identifierSchemeCodeUnspsc190501.51273604': __('identifierSchemeCodeUnspsc190501.51273604'),
	'identifierSchemeCodeUnspsc190501.51273605': __('identifierSchemeCodeUnspsc190501.51273605'),
	'identifierSchemeCodeUnspsc190501.51273606': __('identifierSchemeCodeUnspsc190501.51273606'),
	'identifierSchemeCodeUnspsc190501.51273607': __('identifierSchemeCodeUnspsc190501.51273607'),
	'identifierSchemeCodeUnspsc190501.51273608': __('identifierSchemeCodeUnspsc190501.51273608'),
	'identifierSchemeCodeUnspsc190501.51273609': __('identifierSchemeCodeUnspsc190501.51273609'),
	'identifierSchemeCodeUnspsc190501.51273800': __('identifierSchemeCodeUnspsc190501.51273800'),
	'identifierSchemeCodeUnspsc190501.51273801': __('identifierSchemeCodeUnspsc190501.51273801'),
	'identifierSchemeCodeUnspsc190501.51273802': __('identifierSchemeCodeUnspsc190501.51273802'),
	'identifierSchemeCodeUnspsc190501.51273803': __('identifierSchemeCodeUnspsc190501.51273803'),
	'identifierSchemeCodeUnspsc190501.51273804': __('identifierSchemeCodeUnspsc190501.51273804'),
	'identifierSchemeCodeUnspsc190501.51273805': __('identifierSchemeCodeUnspsc190501.51273805'),
	'identifierSchemeCodeUnspsc190501.51273806': __('identifierSchemeCodeUnspsc190501.51273806'),
	'identifierSchemeCodeUnspsc190501.51273807': __('identifierSchemeCodeUnspsc190501.51273807'),
	'identifierSchemeCodeUnspsc190501.51273808': __('identifierSchemeCodeUnspsc190501.51273808'),
	'identifierSchemeCodeUnspsc190501.51273809': __('identifierSchemeCodeUnspsc190501.51273809'),
	'identifierSchemeCodeUnspsc190501.51273810': __('identifierSchemeCodeUnspsc190501.51273810'),
	'identifierSchemeCodeUnspsc190501.51273811': __('identifierSchemeCodeUnspsc190501.51273811'),
	'identifierSchemeCodeUnspsc190501.51273812': __('identifierSchemeCodeUnspsc190501.51273812'),
	'identifierSchemeCodeUnspsc190501.51273813': __('identifierSchemeCodeUnspsc190501.51273813'),
	'identifierSchemeCodeUnspsc190501.51273814': __('identifierSchemeCodeUnspsc190501.51273814'),
	'identifierSchemeCodeUnspsc190501.51273815': __('identifierSchemeCodeUnspsc190501.51273815'),
	'identifierSchemeCodeUnspsc190501.51273816': __('identifierSchemeCodeUnspsc190501.51273816'),
	'identifierSchemeCodeUnspsc190501.51273817': __('identifierSchemeCodeUnspsc190501.51273817'),
	'identifierSchemeCodeUnspsc190501.51273818': __('identifierSchemeCodeUnspsc190501.51273818'),
	'identifierSchemeCodeUnspsc190501.51273819': __('identifierSchemeCodeUnspsc190501.51273819'),
	'identifierSchemeCodeUnspsc190501.51273820': __('identifierSchemeCodeUnspsc190501.51273820'),
	'identifierSchemeCodeUnspsc190501.51273821': __('identifierSchemeCodeUnspsc190501.51273821'),
	'identifierSchemeCodeUnspsc190501.51273822': __('identifierSchemeCodeUnspsc190501.51273822'),
	'identifierSchemeCodeUnspsc190501.51273823': __('identifierSchemeCodeUnspsc190501.51273823'),
	'identifierSchemeCodeUnspsc190501.51273824': __('identifierSchemeCodeUnspsc190501.51273824'),
	'identifierSchemeCodeUnspsc190501.51273825': __('identifierSchemeCodeUnspsc190501.51273825'),
	'identifierSchemeCodeUnspsc190501.51273826': __('identifierSchemeCodeUnspsc190501.51273826'),
	'identifierSchemeCodeUnspsc190501.51273827': __('identifierSchemeCodeUnspsc190501.51273827'),
	'identifierSchemeCodeUnspsc190501.51273828': __('identifierSchemeCodeUnspsc190501.51273828'),
	'identifierSchemeCodeUnspsc190501.51273829': __('identifierSchemeCodeUnspsc190501.51273829'),
	'identifierSchemeCodeUnspsc190501.51273830': __('identifierSchemeCodeUnspsc190501.51273830'),
	'identifierSchemeCodeUnspsc190501.51273900': __('identifierSchemeCodeUnspsc190501.51273900'),
	'identifierSchemeCodeUnspsc190501.51273901': __('identifierSchemeCodeUnspsc190501.51273901'),
	'identifierSchemeCodeUnspsc190501.51273902': __('identifierSchemeCodeUnspsc190501.51273902'),
	'identifierSchemeCodeUnspsc190501.51273903': __('identifierSchemeCodeUnspsc190501.51273903'),
	'identifierSchemeCodeUnspsc190501.51273904': __('identifierSchemeCodeUnspsc190501.51273904'),
	'identifierSchemeCodeUnspsc190501.51273905': __('identifierSchemeCodeUnspsc190501.51273905'),
	'identifierSchemeCodeUnspsc190501.51273906': __('identifierSchemeCodeUnspsc190501.51273906'),
	'identifierSchemeCodeUnspsc190501.51273907': __('identifierSchemeCodeUnspsc190501.51273907'),
	'identifierSchemeCodeUnspsc190501.51273908': __('identifierSchemeCodeUnspsc190501.51273908'),
	'identifierSchemeCodeUnspsc190501.51273909': __('identifierSchemeCodeUnspsc190501.51273909'),
	'identifierSchemeCodeUnspsc190501.51273910': __('identifierSchemeCodeUnspsc190501.51273910'),
	'identifierSchemeCodeUnspsc190501.51273911': __('identifierSchemeCodeUnspsc190501.51273911'),
	'identifierSchemeCodeUnspsc190501.51273912': __('identifierSchemeCodeUnspsc190501.51273912'),
	'identifierSchemeCodeUnspsc190501.51273913': __('identifierSchemeCodeUnspsc190501.51273913'),
	'identifierSchemeCodeUnspsc190501.51273914': __('identifierSchemeCodeUnspsc190501.51273914'),
	'identifierSchemeCodeUnspsc190501.51273915': __('identifierSchemeCodeUnspsc190501.51273915'),
	'identifierSchemeCodeUnspsc190501.51273916': __('identifierSchemeCodeUnspsc190501.51273916'),
	'identifierSchemeCodeUnspsc190501.51273917': __('identifierSchemeCodeUnspsc190501.51273917'),
	'identifierSchemeCodeUnspsc190501.51273918': __('identifierSchemeCodeUnspsc190501.51273918'),
	'identifierSchemeCodeUnspsc190501.51273919': __('identifierSchemeCodeUnspsc190501.51273919'),
	'identifierSchemeCodeUnspsc190501.51273920': __('identifierSchemeCodeUnspsc190501.51273920'),
	'identifierSchemeCodeUnspsc190501.51273921': __('identifierSchemeCodeUnspsc190501.51273921'),
	'identifierSchemeCodeUnspsc190501.51273922': __('identifierSchemeCodeUnspsc190501.51273922'),
	'identifierSchemeCodeUnspsc190501.51273923': __('identifierSchemeCodeUnspsc190501.51273923'),
	'identifierSchemeCodeUnspsc190501.51273924': __('identifierSchemeCodeUnspsc190501.51273924'),
	'identifierSchemeCodeUnspsc190501.51273925': __('identifierSchemeCodeUnspsc190501.51273925'),
	'identifierSchemeCodeUnspsc190501.51273926': __('identifierSchemeCodeUnspsc190501.51273926'),
	'identifierSchemeCodeUnspsc190501.51273927': __('identifierSchemeCodeUnspsc190501.51273927'),
	'identifierSchemeCodeUnspsc190501.51273928': __('identifierSchemeCodeUnspsc190501.51273928'),
	'identifierSchemeCodeUnspsc190501.51273929': __('identifierSchemeCodeUnspsc190501.51273929'),
	'identifierSchemeCodeUnspsc190501.51273930': __('identifierSchemeCodeUnspsc190501.51273930'),
	'identifierSchemeCodeUnspsc190501.51273931': __('identifierSchemeCodeUnspsc190501.51273931'),
	'identifierSchemeCodeUnspsc190501.51273932': __('identifierSchemeCodeUnspsc190501.51273932'),
	'identifierSchemeCodeUnspsc190501.51273933': __('identifierSchemeCodeUnspsc190501.51273933'),
	'identifierSchemeCodeUnspsc190501.51273934': __('identifierSchemeCodeUnspsc190501.51273934'),
	'identifierSchemeCodeUnspsc190501.51273935': __('identifierSchemeCodeUnspsc190501.51273935'),
	'identifierSchemeCodeUnspsc190501.51273936': __('identifierSchemeCodeUnspsc190501.51273936'),
	'identifierSchemeCodeUnspsc190501.51273937': __('identifierSchemeCodeUnspsc190501.51273937'),
	'identifierSchemeCodeUnspsc190501.51273938': __('identifierSchemeCodeUnspsc190501.51273938'),
	'identifierSchemeCodeUnspsc190501.51273939': __('identifierSchemeCodeUnspsc190501.51273939'),
	'identifierSchemeCodeUnspsc190501.51273940': __('identifierSchemeCodeUnspsc190501.51273940'),
	'identifierSchemeCodeUnspsc190501.51273941': __('identifierSchemeCodeUnspsc190501.51273941'),
	'identifierSchemeCodeUnspsc190501.51273942': __('identifierSchemeCodeUnspsc190501.51273942'),
	'identifierSchemeCodeUnspsc190501.51273943': __('identifierSchemeCodeUnspsc190501.51273943'),
	'identifierSchemeCodeUnspsc190501.51273944': __('identifierSchemeCodeUnspsc190501.51273944'),
	'identifierSchemeCodeUnspsc190501.51273945': __('identifierSchemeCodeUnspsc190501.51273945'),
	'identifierSchemeCodeUnspsc190501.51273946': __('identifierSchemeCodeUnspsc190501.51273946'),
	'identifierSchemeCodeUnspsc190501.51273947': __('identifierSchemeCodeUnspsc190501.51273947'),
	'identifierSchemeCodeUnspsc190501.51273948': __('identifierSchemeCodeUnspsc190501.51273948'),
	'identifierSchemeCodeUnspsc190501.51273949': __('identifierSchemeCodeUnspsc190501.51273949'),
	'identifierSchemeCodeUnspsc190501.51280000': __('identifierSchemeCodeUnspsc190501.51280000'),
	'identifierSchemeCodeUnspsc190501.51281500': __('identifierSchemeCodeUnspsc190501.51281500'),
	'identifierSchemeCodeUnspsc190501.51281501': __('identifierSchemeCodeUnspsc190501.51281501'),
	'identifierSchemeCodeUnspsc190501.51281502': __('identifierSchemeCodeUnspsc190501.51281502'),
	'identifierSchemeCodeUnspsc190501.51281503': __('identifierSchemeCodeUnspsc190501.51281503'),
	'identifierSchemeCodeUnspsc190501.51281504': __('identifierSchemeCodeUnspsc190501.51281504'),
	'identifierSchemeCodeUnspsc190501.51281505': __('identifierSchemeCodeUnspsc190501.51281505'),
	'identifierSchemeCodeUnspsc190501.51281506': __('identifierSchemeCodeUnspsc190501.51281506'),
	'identifierSchemeCodeUnspsc190501.51281507': __('identifierSchemeCodeUnspsc190501.51281507'),
	'identifierSchemeCodeUnspsc190501.51281508': __('identifierSchemeCodeUnspsc190501.51281508'),
	'identifierSchemeCodeUnspsc190501.51281509': __('identifierSchemeCodeUnspsc190501.51281509'),
	'identifierSchemeCodeUnspsc190501.51281510': __('identifierSchemeCodeUnspsc190501.51281510'),
	'identifierSchemeCodeUnspsc190501.51281511': __('identifierSchemeCodeUnspsc190501.51281511'),
	'identifierSchemeCodeUnspsc190501.51281512': __('identifierSchemeCodeUnspsc190501.51281512'),
	'identifierSchemeCodeUnspsc190501.51281513': __('identifierSchemeCodeUnspsc190501.51281513'),
	'identifierSchemeCodeUnspsc190501.51281514': __('identifierSchemeCodeUnspsc190501.51281514'),
	'identifierSchemeCodeUnspsc190501.51281515': __('identifierSchemeCodeUnspsc190501.51281515'),
	'identifierSchemeCodeUnspsc190501.51281516': __('identifierSchemeCodeUnspsc190501.51281516'),
	'identifierSchemeCodeUnspsc190501.51281517': __('identifierSchemeCodeUnspsc190501.51281517'),
	'identifierSchemeCodeUnspsc190501.51281600': __('identifierSchemeCodeUnspsc190501.51281600'),
	'identifierSchemeCodeUnspsc190501.51281601': __('identifierSchemeCodeUnspsc190501.51281601'),
	'identifierSchemeCodeUnspsc190501.51281602': __('identifierSchemeCodeUnspsc190501.51281602'),
	'identifierSchemeCodeUnspsc190501.51281603': __('identifierSchemeCodeUnspsc190501.51281603'),
	'identifierSchemeCodeUnspsc190501.51281604': __('identifierSchemeCodeUnspsc190501.51281604'),
	'identifierSchemeCodeUnspsc190501.51281605': __('identifierSchemeCodeUnspsc190501.51281605'),
	'identifierSchemeCodeUnspsc190501.51281606': __('identifierSchemeCodeUnspsc190501.51281606'),
	'identifierSchemeCodeUnspsc190501.51281607': __('identifierSchemeCodeUnspsc190501.51281607'),
	'identifierSchemeCodeUnspsc190501.51281608': __('identifierSchemeCodeUnspsc190501.51281608'),
	'identifierSchemeCodeUnspsc190501.51281609': __('identifierSchemeCodeUnspsc190501.51281609'),
	'identifierSchemeCodeUnspsc190501.51281610': __('identifierSchemeCodeUnspsc190501.51281610'),
	'identifierSchemeCodeUnspsc190501.51281611': __('identifierSchemeCodeUnspsc190501.51281611'),
	'identifierSchemeCodeUnspsc190501.51281612': __('identifierSchemeCodeUnspsc190501.51281612'),
	'identifierSchemeCodeUnspsc190501.51281613': __('identifierSchemeCodeUnspsc190501.51281613'),
	'identifierSchemeCodeUnspsc190501.51281614': __('identifierSchemeCodeUnspsc190501.51281614'),
	'identifierSchemeCodeUnspsc190501.51281615': __('identifierSchemeCodeUnspsc190501.51281615'),
	'identifierSchemeCodeUnspsc190501.51281616': __('identifierSchemeCodeUnspsc190501.51281616'),
	'identifierSchemeCodeUnspsc190501.51281617': __('identifierSchemeCodeUnspsc190501.51281617'),
	'identifierSchemeCodeUnspsc190501.51281618': __('identifierSchemeCodeUnspsc190501.51281618'),
	'identifierSchemeCodeUnspsc190501.51281620': __('identifierSchemeCodeUnspsc190501.51281620'),
	'identifierSchemeCodeUnspsc190501.51281621': __('identifierSchemeCodeUnspsc190501.51281621'),
	'identifierSchemeCodeUnspsc190501.51281622': __('identifierSchemeCodeUnspsc190501.51281622'),
	'identifierSchemeCodeUnspsc190501.51281623': __('identifierSchemeCodeUnspsc190501.51281623'),
	'identifierSchemeCodeUnspsc190501.51281624': __('identifierSchemeCodeUnspsc190501.51281624'),
	'identifierSchemeCodeUnspsc190501.51281625': __('identifierSchemeCodeUnspsc190501.51281625'),
	'identifierSchemeCodeUnspsc190501.51281626': __('identifierSchemeCodeUnspsc190501.51281626'),
	'identifierSchemeCodeUnspsc190501.51281627': __('identifierSchemeCodeUnspsc190501.51281627'),
	'identifierSchemeCodeUnspsc190501.51281628': __('identifierSchemeCodeUnspsc190501.51281628'),
	'identifierSchemeCodeUnspsc190501.51281629': __('identifierSchemeCodeUnspsc190501.51281629'),
	'identifierSchemeCodeUnspsc190501.51281630': __('identifierSchemeCodeUnspsc190501.51281630'),
	'identifierSchemeCodeUnspsc190501.51281631': __('identifierSchemeCodeUnspsc190501.51281631'),
	'identifierSchemeCodeUnspsc190501.51281632': __('identifierSchemeCodeUnspsc190501.51281632'),
	'identifierSchemeCodeUnspsc190501.51281633': __('identifierSchemeCodeUnspsc190501.51281633'),
	'identifierSchemeCodeUnspsc190501.51281634': __('identifierSchemeCodeUnspsc190501.51281634'),
	'identifierSchemeCodeUnspsc190501.51281635': __('identifierSchemeCodeUnspsc190501.51281635'),
	'identifierSchemeCodeUnspsc190501.51281636': __('identifierSchemeCodeUnspsc190501.51281636'),
	'identifierSchemeCodeUnspsc190501.51281637': __('identifierSchemeCodeUnspsc190501.51281637'),
	'identifierSchemeCodeUnspsc190501.51281638': __('identifierSchemeCodeUnspsc190501.51281638'),
	'identifierSchemeCodeUnspsc190501.51281639': __('identifierSchemeCodeUnspsc190501.51281639'),
	'identifierSchemeCodeUnspsc190501.51281640': __('identifierSchemeCodeUnspsc190501.51281640'),
	'identifierSchemeCodeUnspsc190501.51281641': __('identifierSchemeCodeUnspsc190501.51281641'),
	'identifierSchemeCodeUnspsc190501.51281642': __('identifierSchemeCodeUnspsc190501.51281642'),
	'identifierSchemeCodeUnspsc190501.51281643': __('identifierSchemeCodeUnspsc190501.51281643'),
	'identifierSchemeCodeUnspsc190501.51281644': __('identifierSchemeCodeUnspsc190501.51281644'),
	'identifierSchemeCodeUnspsc190501.51281645': __('identifierSchemeCodeUnspsc190501.51281645'),
	'identifierSchemeCodeUnspsc190501.51281646': __('identifierSchemeCodeUnspsc190501.51281646'),
	'identifierSchemeCodeUnspsc190501.51281647': __('identifierSchemeCodeUnspsc190501.51281647'),
	'identifierSchemeCodeUnspsc190501.51281648': __('identifierSchemeCodeUnspsc190501.51281648'),
	'identifierSchemeCodeUnspsc190501.51281649': __('identifierSchemeCodeUnspsc190501.51281649'),
	'identifierSchemeCodeUnspsc190501.51281650': __('identifierSchemeCodeUnspsc190501.51281650'),
	'identifierSchemeCodeUnspsc190501.51281651': __('identifierSchemeCodeUnspsc190501.51281651'),
	'identifierSchemeCodeUnspsc190501.51281652': __('identifierSchemeCodeUnspsc190501.51281652'),
	'identifierSchemeCodeUnspsc190501.51281653': __('identifierSchemeCodeUnspsc190501.51281653'),
	'identifierSchemeCodeUnspsc190501.51281654': __('identifierSchemeCodeUnspsc190501.51281654'),
	'identifierSchemeCodeUnspsc190501.51281655': __('identifierSchemeCodeUnspsc190501.51281655'),
	'identifierSchemeCodeUnspsc190501.51281656': __('identifierSchemeCodeUnspsc190501.51281656'),
	'identifierSchemeCodeUnspsc190501.51281657': __('identifierSchemeCodeUnspsc190501.51281657'),
	'identifierSchemeCodeUnspsc190501.51281658': __('identifierSchemeCodeUnspsc190501.51281658'),
	'identifierSchemeCodeUnspsc190501.51281659': __('identifierSchemeCodeUnspsc190501.51281659'),
	'identifierSchemeCodeUnspsc190501.51281660': __('identifierSchemeCodeUnspsc190501.51281660'),
	'identifierSchemeCodeUnspsc190501.51281661': __('identifierSchemeCodeUnspsc190501.51281661'),
	'identifierSchemeCodeUnspsc190501.51281662': __('identifierSchemeCodeUnspsc190501.51281662'),
	'identifierSchemeCodeUnspsc190501.51281663': __('identifierSchemeCodeUnspsc190501.51281663'),
	'identifierSchemeCodeUnspsc190501.51281664': __('identifierSchemeCodeUnspsc190501.51281664'),
	'identifierSchemeCodeUnspsc190501.51281665': __('identifierSchemeCodeUnspsc190501.51281665'),
	'identifierSchemeCodeUnspsc190501.51281666': __('identifierSchemeCodeUnspsc190501.51281666'),
	'identifierSchemeCodeUnspsc190501.51281667': __('identifierSchemeCodeUnspsc190501.51281667'),
	'identifierSchemeCodeUnspsc190501.51281668': __('identifierSchemeCodeUnspsc190501.51281668'),
	'identifierSchemeCodeUnspsc190501.51281669': __('identifierSchemeCodeUnspsc190501.51281669'),
	'identifierSchemeCodeUnspsc190501.51281670': __('identifierSchemeCodeUnspsc190501.51281670'),
	'identifierSchemeCodeUnspsc190501.51281671': __('identifierSchemeCodeUnspsc190501.51281671'),
	'identifierSchemeCodeUnspsc190501.51281672': __('identifierSchemeCodeUnspsc190501.51281672'),
	'identifierSchemeCodeUnspsc190501.51281673': __('identifierSchemeCodeUnspsc190501.51281673'),
	'identifierSchemeCodeUnspsc190501.51281674': __('identifierSchemeCodeUnspsc190501.51281674'),
	'identifierSchemeCodeUnspsc190501.51281675': __('identifierSchemeCodeUnspsc190501.51281675'),
	'identifierSchemeCodeUnspsc190501.51281676': __('identifierSchemeCodeUnspsc190501.51281676'),
	'identifierSchemeCodeUnspsc190501.51281677': __('identifierSchemeCodeUnspsc190501.51281677'),
	'identifierSchemeCodeUnspsc190501.51281678': __('identifierSchemeCodeUnspsc190501.51281678'),
	'identifierSchemeCodeUnspsc190501.51281679': __('identifierSchemeCodeUnspsc190501.51281679'),
	'identifierSchemeCodeUnspsc190501.51281680': __('identifierSchemeCodeUnspsc190501.51281680'),
	'identifierSchemeCodeUnspsc190501.51281681': __('identifierSchemeCodeUnspsc190501.51281681'),
	'identifierSchemeCodeUnspsc190501.51281682': __('identifierSchemeCodeUnspsc190501.51281682'),
	'identifierSchemeCodeUnspsc190501.51281683': __('identifierSchemeCodeUnspsc190501.51281683'),
	'identifierSchemeCodeUnspsc190501.51281700': __('identifierSchemeCodeUnspsc190501.51281700'),
	'identifierSchemeCodeUnspsc190501.51281701': __('identifierSchemeCodeUnspsc190501.51281701'),
	'identifierSchemeCodeUnspsc190501.51281702': __('identifierSchemeCodeUnspsc190501.51281702'),
	'identifierSchemeCodeUnspsc190501.51281703': __('identifierSchemeCodeUnspsc190501.51281703'),
	'identifierSchemeCodeUnspsc190501.51281704': __('identifierSchemeCodeUnspsc190501.51281704'),
	'identifierSchemeCodeUnspsc190501.51281705': __('identifierSchemeCodeUnspsc190501.51281705'),
	'identifierSchemeCodeUnspsc190501.51281706': __('identifierSchemeCodeUnspsc190501.51281706'),
	'identifierSchemeCodeUnspsc190501.51281707': __('identifierSchemeCodeUnspsc190501.51281707'),
	'identifierSchemeCodeUnspsc190501.51281708': __('identifierSchemeCodeUnspsc190501.51281708'),
	'identifierSchemeCodeUnspsc190501.51281709': __('identifierSchemeCodeUnspsc190501.51281709'),
	'identifierSchemeCodeUnspsc190501.51281710': __('identifierSchemeCodeUnspsc190501.51281710'),
	'identifierSchemeCodeUnspsc190501.51281711': __('identifierSchemeCodeUnspsc190501.51281711'),
	'identifierSchemeCodeUnspsc190501.51281712': __('identifierSchemeCodeUnspsc190501.51281712'),
	'identifierSchemeCodeUnspsc190501.51281713': __('identifierSchemeCodeUnspsc190501.51281713'),
	'identifierSchemeCodeUnspsc190501.51281714': __('identifierSchemeCodeUnspsc190501.51281714'),
	'identifierSchemeCodeUnspsc190501.51281715': __('identifierSchemeCodeUnspsc190501.51281715'),
	'identifierSchemeCodeUnspsc190501.51281716': __('identifierSchemeCodeUnspsc190501.51281716'),
	'identifierSchemeCodeUnspsc190501.51281717': __('identifierSchemeCodeUnspsc190501.51281717'),
	'identifierSchemeCodeUnspsc190501.51281718': __('identifierSchemeCodeUnspsc190501.51281718'),
	'identifierSchemeCodeUnspsc190501.51281719': __('identifierSchemeCodeUnspsc190501.51281719'),
	'identifierSchemeCodeUnspsc190501.51281720': __('identifierSchemeCodeUnspsc190501.51281720'),
	'identifierSchemeCodeUnspsc190501.51281800': __('identifierSchemeCodeUnspsc190501.51281800'),
	'identifierSchemeCodeUnspsc190501.51281801': __('identifierSchemeCodeUnspsc190501.51281801'),
	'identifierSchemeCodeUnspsc190501.51281802': __('identifierSchemeCodeUnspsc190501.51281802'),
	'identifierSchemeCodeUnspsc190501.51281803': __('identifierSchemeCodeUnspsc190501.51281803'),
	'identifierSchemeCodeUnspsc190501.51281804': __('identifierSchemeCodeUnspsc190501.51281804'),
	'identifierSchemeCodeUnspsc190501.51281805': __('identifierSchemeCodeUnspsc190501.51281805'),
	'identifierSchemeCodeUnspsc190501.51281806': __('identifierSchemeCodeUnspsc190501.51281806'),
	'identifierSchemeCodeUnspsc190501.51281807': __('identifierSchemeCodeUnspsc190501.51281807'),
	'identifierSchemeCodeUnspsc190501.51281808': __('identifierSchemeCodeUnspsc190501.51281808'),
	'identifierSchemeCodeUnspsc190501.51281809': __('identifierSchemeCodeUnspsc190501.51281809'),
	'identifierSchemeCodeUnspsc190501.51281810': __('identifierSchemeCodeUnspsc190501.51281810'),
	'identifierSchemeCodeUnspsc190501.51281811': __('identifierSchemeCodeUnspsc190501.51281811'),
	'identifierSchemeCodeUnspsc190501.51281812': __('identifierSchemeCodeUnspsc190501.51281812'),
	'identifierSchemeCodeUnspsc190501.51281813': __('identifierSchemeCodeUnspsc190501.51281813'),
	'identifierSchemeCodeUnspsc190501.51281814': __('identifierSchemeCodeUnspsc190501.51281814'),
	'identifierSchemeCodeUnspsc190501.51281815': __('identifierSchemeCodeUnspsc190501.51281815'),
	'identifierSchemeCodeUnspsc190501.51281816': __('identifierSchemeCodeUnspsc190501.51281816'),
	'identifierSchemeCodeUnspsc190501.51281817': __('identifierSchemeCodeUnspsc190501.51281817'),
	'identifierSchemeCodeUnspsc190501.51281818': __('identifierSchemeCodeUnspsc190501.51281818'),
	'identifierSchemeCodeUnspsc190501.51281819': __('identifierSchemeCodeUnspsc190501.51281819'),
	'identifierSchemeCodeUnspsc190501.51281820': __('identifierSchemeCodeUnspsc190501.51281820'),
	'identifierSchemeCodeUnspsc190501.51281821': __('identifierSchemeCodeUnspsc190501.51281821'),
	'identifierSchemeCodeUnspsc190501.51281822': __('identifierSchemeCodeUnspsc190501.51281822'),
	'identifierSchemeCodeUnspsc190501.51281823': __('identifierSchemeCodeUnspsc190501.51281823'),
	'identifierSchemeCodeUnspsc190501.51281824': __('identifierSchemeCodeUnspsc190501.51281824'),
	'identifierSchemeCodeUnspsc190501.51281900': __('identifierSchemeCodeUnspsc190501.51281900'),
	'identifierSchemeCodeUnspsc190501.51281901': __('identifierSchemeCodeUnspsc190501.51281901'),
	'identifierSchemeCodeUnspsc190501.51281902': __('identifierSchemeCodeUnspsc190501.51281902'),
	'identifierSchemeCodeUnspsc190501.51281903': __('identifierSchemeCodeUnspsc190501.51281903'),
	'identifierSchemeCodeUnspsc190501.51281904': __('identifierSchemeCodeUnspsc190501.51281904'),
	'identifierSchemeCodeUnspsc190501.51281905': __('identifierSchemeCodeUnspsc190501.51281905'),
	'identifierSchemeCodeUnspsc190501.51281906': __('identifierSchemeCodeUnspsc190501.51281906'),
	'identifierSchemeCodeUnspsc190501.51281907': __('identifierSchemeCodeUnspsc190501.51281907'),
	'identifierSchemeCodeUnspsc190501.51281908': __('identifierSchemeCodeUnspsc190501.51281908'),
	'identifierSchemeCodeUnspsc190501.51281909': __('identifierSchemeCodeUnspsc190501.51281909'),
	'identifierSchemeCodeUnspsc190501.51281910': __('identifierSchemeCodeUnspsc190501.51281910'),
	'identifierSchemeCodeUnspsc190501.51281911': __('identifierSchemeCodeUnspsc190501.51281911'),
	'identifierSchemeCodeUnspsc190501.51281912': __('identifierSchemeCodeUnspsc190501.51281912'),
	'identifierSchemeCodeUnspsc190501.51281913': __('identifierSchemeCodeUnspsc190501.51281913'),
	'identifierSchemeCodeUnspsc190501.51282000': __('identifierSchemeCodeUnspsc190501.51282000'),
	'identifierSchemeCodeUnspsc190501.51282001': __('identifierSchemeCodeUnspsc190501.51282001'),
	'identifierSchemeCodeUnspsc190501.51282002': __('identifierSchemeCodeUnspsc190501.51282002'),
	'identifierSchemeCodeUnspsc190501.51282003': __('identifierSchemeCodeUnspsc190501.51282003'),
	'identifierSchemeCodeUnspsc190501.51282004': __('identifierSchemeCodeUnspsc190501.51282004'),
	'identifierSchemeCodeUnspsc190501.51282005': __('identifierSchemeCodeUnspsc190501.51282005'),
	'identifierSchemeCodeUnspsc190501.51282006': __('identifierSchemeCodeUnspsc190501.51282006'),
	'identifierSchemeCodeUnspsc190501.51282007': __('identifierSchemeCodeUnspsc190501.51282007'),
	'identifierSchemeCodeUnspsc190501.51282008': __('identifierSchemeCodeUnspsc190501.51282008'),
	'identifierSchemeCodeUnspsc190501.51282009': __('identifierSchemeCodeUnspsc190501.51282009'),
	'identifierSchemeCodeUnspsc190501.51282010': __('identifierSchemeCodeUnspsc190501.51282010'),
	'identifierSchemeCodeUnspsc190501.51282011': __('identifierSchemeCodeUnspsc190501.51282011'),
	'identifierSchemeCodeUnspsc190501.51282012': __('identifierSchemeCodeUnspsc190501.51282012'),
	'identifierSchemeCodeUnspsc190501.51282100': __('identifierSchemeCodeUnspsc190501.51282100'),
	'identifierSchemeCodeUnspsc190501.51282101': __('identifierSchemeCodeUnspsc190501.51282101'),
	'identifierSchemeCodeUnspsc190501.51282102': __('identifierSchemeCodeUnspsc190501.51282102'),
	'identifierSchemeCodeUnspsc190501.51282103': __('identifierSchemeCodeUnspsc190501.51282103'),
	'identifierSchemeCodeUnspsc190501.51282104': __('identifierSchemeCodeUnspsc190501.51282104'),
	'identifierSchemeCodeUnspsc190501.51282105': __('identifierSchemeCodeUnspsc190501.51282105'),
	'identifierSchemeCodeUnspsc190501.51282106': __('identifierSchemeCodeUnspsc190501.51282106'),
	'identifierSchemeCodeUnspsc190501.51282107': __('identifierSchemeCodeUnspsc190501.51282107'),
	'identifierSchemeCodeUnspsc190501.51282108': __('identifierSchemeCodeUnspsc190501.51282108'),
	'identifierSchemeCodeUnspsc190501.51282109': __('identifierSchemeCodeUnspsc190501.51282109'),
	'identifierSchemeCodeUnspsc190501.51282110': __('identifierSchemeCodeUnspsc190501.51282110'),
	'identifierSchemeCodeUnspsc190501.51282111': __('identifierSchemeCodeUnspsc190501.51282111'),
	'identifierSchemeCodeUnspsc190501.51282112': __('identifierSchemeCodeUnspsc190501.51282112'),
	'identifierSchemeCodeUnspsc190501.51282113': __('identifierSchemeCodeUnspsc190501.51282113'),
	'identifierSchemeCodeUnspsc190501.51282114': __('identifierSchemeCodeUnspsc190501.51282114'),
	'identifierSchemeCodeUnspsc190501.51282115': __('identifierSchemeCodeUnspsc190501.51282115'),
	'identifierSchemeCodeUnspsc190501.51282116': __('identifierSchemeCodeUnspsc190501.51282116'),
	'identifierSchemeCodeUnspsc190501.51282117': __('identifierSchemeCodeUnspsc190501.51282117'),
	'identifierSchemeCodeUnspsc190501.51282118': __('identifierSchemeCodeUnspsc190501.51282118'),
	'identifierSchemeCodeUnspsc190501.51282119': __('identifierSchemeCodeUnspsc190501.51282119'),
	'identifierSchemeCodeUnspsc190501.51282120': __('identifierSchemeCodeUnspsc190501.51282120'),
	'identifierSchemeCodeUnspsc190501.51282121': __('identifierSchemeCodeUnspsc190501.51282121'),
	'identifierSchemeCodeUnspsc190501.51282122': __('identifierSchemeCodeUnspsc190501.51282122'),
	'identifierSchemeCodeUnspsc190501.51282123': __('identifierSchemeCodeUnspsc190501.51282123'),
	'identifierSchemeCodeUnspsc190501.51282124': __('identifierSchemeCodeUnspsc190501.51282124'),
	'identifierSchemeCodeUnspsc190501.51282125': __('identifierSchemeCodeUnspsc190501.51282125'),
	'identifierSchemeCodeUnspsc190501.51282126': __('identifierSchemeCodeUnspsc190501.51282126'),
	'identifierSchemeCodeUnspsc190501.51282127': __('identifierSchemeCodeUnspsc190501.51282127'),
	'identifierSchemeCodeUnspsc190501.51282128': __('identifierSchemeCodeUnspsc190501.51282128'),
	'identifierSchemeCodeUnspsc190501.51282129': __('identifierSchemeCodeUnspsc190501.51282129'),
	'identifierSchemeCodeUnspsc190501.51282130': __('identifierSchemeCodeUnspsc190501.51282130'),
	'identifierSchemeCodeUnspsc190501.51282131': __('identifierSchemeCodeUnspsc190501.51282131'),
	'identifierSchemeCodeUnspsc190501.51282132': __('identifierSchemeCodeUnspsc190501.51282132'),
	'identifierSchemeCodeUnspsc190501.51282133': __('identifierSchemeCodeUnspsc190501.51282133'),
	'identifierSchemeCodeUnspsc190501.51282134': __('identifierSchemeCodeUnspsc190501.51282134'),
	'identifierSchemeCodeUnspsc190501.51282135': __('identifierSchemeCodeUnspsc190501.51282135'),
	'identifierSchemeCodeUnspsc190501.51282136': __('identifierSchemeCodeUnspsc190501.51282136'),
	'identifierSchemeCodeUnspsc190501.51282137': __('identifierSchemeCodeUnspsc190501.51282137'),
	'identifierSchemeCodeUnspsc190501.51282138': __('identifierSchemeCodeUnspsc190501.51282138'),
	'identifierSchemeCodeUnspsc190501.51282139': __('identifierSchemeCodeUnspsc190501.51282139'),
	'identifierSchemeCodeUnspsc190501.51282140': __('identifierSchemeCodeUnspsc190501.51282140'),
	'identifierSchemeCodeUnspsc190501.51282200': __('identifierSchemeCodeUnspsc190501.51282200'),
	'identifierSchemeCodeUnspsc190501.51282201': __('identifierSchemeCodeUnspsc190501.51282201'),
	'identifierSchemeCodeUnspsc190501.51282202': __('identifierSchemeCodeUnspsc190501.51282202'),
	'identifierSchemeCodeUnspsc190501.51282203': __('identifierSchemeCodeUnspsc190501.51282203'),
	'identifierSchemeCodeUnspsc190501.51282204': __('identifierSchemeCodeUnspsc190501.51282204'),
	'identifierSchemeCodeUnspsc190501.51282205': __('identifierSchemeCodeUnspsc190501.51282205'),
	'identifierSchemeCodeUnspsc190501.51282206': __('identifierSchemeCodeUnspsc190501.51282206'),
	'identifierSchemeCodeUnspsc190501.51282207': __('identifierSchemeCodeUnspsc190501.51282207'),
	'identifierSchemeCodeUnspsc190501.51282208': __('identifierSchemeCodeUnspsc190501.51282208'),
	'identifierSchemeCodeUnspsc190501.51282209': __('identifierSchemeCodeUnspsc190501.51282209'),
	'identifierSchemeCodeUnspsc190501.51282300': __('identifierSchemeCodeUnspsc190501.51282300'),
	'identifierSchemeCodeUnspsc190501.51282301': __('identifierSchemeCodeUnspsc190501.51282301'),
	'identifierSchemeCodeUnspsc190501.51282302': __('identifierSchemeCodeUnspsc190501.51282302'),
	'identifierSchemeCodeUnspsc190501.51282303': __('identifierSchemeCodeUnspsc190501.51282303'),
	'identifierSchemeCodeUnspsc190501.51282304': __('identifierSchemeCodeUnspsc190501.51282304'),
	'identifierSchemeCodeUnspsc190501.51282305': __('identifierSchemeCodeUnspsc190501.51282305'),
	'identifierSchemeCodeUnspsc190501.51282306': __('identifierSchemeCodeUnspsc190501.51282306'),
	'identifierSchemeCodeUnspsc190501.51282307': __('identifierSchemeCodeUnspsc190501.51282307'),
	'identifierSchemeCodeUnspsc190501.51282308': __('identifierSchemeCodeUnspsc190501.51282308'),
	'identifierSchemeCodeUnspsc190501.51282309': __('identifierSchemeCodeUnspsc190501.51282309'),
	'identifierSchemeCodeUnspsc190501.51282310': __('identifierSchemeCodeUnspsc190501.51282310'),
	'identifierSchemeCodeUnspsc190501.51282311': __('identifierSchemeCodeUnspsc190501.51282311'),
	'identifierSchemeCodeUnspsc190501.51282312': __('identifierSchemeCodeUnspsc190501.51282312'),
	'identifierSchemeCodeUnspsc190501.51282313': __('identifierSchemeCodeUnspsc190501.51282313'),
	'identifierSchemeCodeUnspsc190501.51282314': __('identifierSchemeCodeUnspsc190501.51282314'),
	'identifierSchemeCodeUnspsc190501.51282315': __('identifierSchemeCodeUnspsc190501.51282315'),
	'identifierSchemeCodeUnspsc190501.51282316': __('identifierSchemeCodeUnspsc190501.51282316'),
	'identifierSchemeCodeUnspsc190501.51282317': __('identifierSchemeCodeUnspsc190501.51282317'),
	'identifierSchemeCodeUnspsc190501.51282318': __('identifierSchemeCodeUnspsc190501.51282318'),
	'identifierSchemeCodeUnspsc190501.51282319': __('identifierSchemeCodeUnspsc190501.51282319'),
	'identifierSchemeCodeUnspsc190501.51282320': __('identifierSchemeCodeUnspsc190501.51282320'),
	'identifierSchemeCodeUnspsc190501.51282321': __('identifierSchemeCodeUnspsc190501.51282321'),
	'identifierSchemeCodeUnspsc190501.51282322': __('identifierSchemeCodeUnspsc190501.51282322'),
	'identifierSchemeCodeUnspsc190501.51282323': __('identifierSchemeCodeUnspsc190501.51282323'),
	'identifierSchemeCodeUnspsc190501.51282324': __('identifierSchemeCodeUnspsc190501.51282324'),
	'identifierSchemeCodeUnspsc190501.51282325': __('identifierSchemeCodeUnspsc190501.51282325'),
	'identifierSchemeCodeUnspsc190501.51282326': __('identifierSchemeCodeUnspsc190501.51282326'),
	'identifierSchemeCodeUnspsc190501.51282327': __('identifierSchemeCodeUnspsc190501.51282327'),
	'identifierSchemeCodeUnspsc190501.51282328': __('identifierSchemeCodeUnspsc190501.51282328'),
	'identifierSchemeCodeUnspsc190501.51282329': __('identifierSchemeCodeUnspsc190501.51282329'),
	'identifierSchemeCodeUnspsc190501.51282400': __('identifierSchemeCodeUnspsc190501.51282400'),
	'identifierSchemeCodeUnspsc190501.51282401': __('identifierSchemeCodeUnspsc190501.51282401'),
	'identifierSchemeCodeUnspsc190501.51282402': __('identifierSchemeCodeUnspsc190501.51282402'),
	'identifierSchemeCodeUnspsc190501.51282403': __('identifierSchemeCodeUnspsc190501.51282403'),
	'identifierSchemeCodeUnspsc190501.51282404': __('identifierSchemeCodeUnspsc190501.51282404'),
	'identifierSchemeCodeUnspsc190501.51282405': __('identifierSchemeCodeUnspsc190501.51282405'),
	'identifierSchemeCodeUnspsc190501.51282406': __('identifierSchemeCodeUnspsc190501.51282406'),
	'identifierSchemeCodeUnspsc190501.51282407': __('identifierSchemeCodeUnspsc190501.51282407'),
	'identifierSchemeCodeUnspsc190501.51282408': __('identifierSchemeCodeUnspsc190501.51282408'),
	'identifierSchemeCodeUnspsc190501.51282409': __('identifierSchemeCodeUnspsc190501.51282409'),
	'identifierSchemeCodeUnspsc190501.51282410': __('identifierSchemeCodeUnspsc190501.51282410'),
	'identifierSchemeCodeUnspsc190501.51282411': __('identifierSchemeCodeUnspsc190501.51282411'),
	'identifierSchemeCodeUnspsc190501.51282412': __('identifierSchemeCodeUnspsc190501.51282412'),
	'identifierSchemeCodeUnspsc190501.51282413': __('identifierSchemeCodeUnspsc190501.51282413'),
	'identifierSchemeCodeUnspsc190501.51282414': __('identifierSchemeCodeUnspsc190501.51282414'),
	'identifierSchemeCodeUnspsc190501.51282415': __('identifierSchemeCodeUnspsc190501.51282415'),
	'identifierSchemeCodeUnspsc190501.51282416': __('identifierSchemeCodeUnspsc190501.51282416'),
	'identifierSchemeCodeUnspsc190501.51282417': __('identifierSchemeCodeUnspsc190501.51282417'),
	'identifierSchemeCodeUnspsc190501.51282418': __('identifierSchemeCodeUnspsc190501.51282418'),
	'identifierSchemeCodeUnspsc190501.51282419': __('identifierSchemeCodeUnspsc190501.51282419'),
	'identifierSchemeCodeUnspsc190501.51282420': __('identifierSchemeCodeUnspsc190501.51282420'),
	'identifierSchemeCodeUnspsc190501.51282421': __('identifierSchemeCodeUnspsc190501.51282421'),
	'identifierSchemeCodeUnspsc190501.51282422': __('identifierSchemeCodeUnspsc190501.51282422'),
	'identifierSchemeCodeUnspsc190501.51282423': __('identifierSchemeCodeUnspsc190501.51282423'),
	'identifierSchemeCodeUnspsc190501.51282424': __('identifierSchemeCodeUnspsc190501.51282424'),
	'identifierSchemeCodeUnspsc190501.51282425': __('identifierSchemeCodeUnspsc190501.51282425'),
	'identifierSchemeCodeUnspsc190501.51282426': __('identifierSchemeCodeUnspsc190501.51282426'),
	'identifierSchemeCodeUnspsc190501.51282427': __('identifierSchemeCodeUnspsc190501.51282427'),
	'identifierSchemeCodeUnspsc190501.51282428': __('identifierSchemeCodeUnspsc190501.51282428'),
	'identifierSchemeCodeUnspsc190501.51282500': __('identifierSchemeCodeUnspsc190501.51282500'),
	'identifierSchemeCodeUnspsc190501.51282501': __('identifierSchemeCodeUnspsc190501.51282501'),
	'identifierSchemeCodeUnspsc190501.51282502': __('identifierSchemeCodeUnspsc190501.51282502'),
	'identifierSchemeCodeUnspsc190501.51282503': __('identifierSchemeCodeUnspsc190501.51282503'),
	'identifierSchemeCodeUnspsc190501.51282504': __('identifierSchemeCodeUnspsc190501.51282504'),
	'identifierSchemeCodeUnspsc190501.51282505': __('identifierSchemeCodeUnspsc190501.51282505'),
	'identifierSchemeCodeUnspsc190501.51282506': __('identifierSchemeCodeUnspsc190501.51282506'),
	'identifierSchemeCodeUnspsc190501.51282507': __('identifierSchemeCodeUnspsc190501.51282507'),
	'identifierSchemeCodeUnspsc190501.51282508': __('identifierSchemeCodeUnspsc190501.51282508'),
	'identifierSchemeCodeUnspsc190501.51282509': __('identifierSchemeCodeUnspsc190501.51282509'),
	'identifierSchemeCodeUnspsc190501.51282510': __('identifierSchemeCodeUnspsc190501.51282510'),
	'identifierSchemeCodeUnspsc190501.51282511': __('identifierSchemeCodeUnspsc190501.51282511'),
	'identifierSchemeCodeUnspsc190501.51282513': __('identifierSchemeCodeUnspsc190501.51282513'),
	'identifierSchemeCodeUnspsc190501.51282514': __('identifierSchemeCodeUnspsc190501.51282514'),
	'identifierSchemeCodeUnspsc190501.51282516': __('identifierSchemeCodeUnspsc190501.51282516'),
	'identifierSchemeCodeUnspsc190501.51282517': __('identifierSchemeCodeUnspsc190501.51282517'),
	'identifierSchemeCodeUnspsc190501.51282518': __('identifierSchemeCodeUnspsc190501.51282518'),
	'identifierSchemeCodeUnspsc190501.51282519': __('identifierSchemeCodeUnspsc190501.51282519'),
	'identifierSchemeCodeUnspsc190501.51282520': __('identifierSchemeCodeUnspsc190501.51282520'),
	'identifierSchemeCodeUnspsc190501.51282521': __('identifierSchemeCodeUnspsc190501.51282521'),
	'identifierSchemeCodeUnspsc190501.51282522': __('identifierSchemeCodeUnspsc190501.51282522'),
	'identifierSchemeCodeUnspsc190501.51282523': __('identifierSchemeCodeUnspsc190501.51282523'),
	'identifierSchemeCodeUnspsc190501.51282524': __('identifierSchemeCodeUnspsc190501.51282524'),
	'identifierSchemeCodeUnspsc190501.51282525': __('identifierSchemeCodeUnspsc190501.51282525'),
	'identifierSchemeCodeUnspsc190501.51282526': __('identifierSchemeCodeUnspsc190501.51282526'),
	'identifierSchemeCodeUnspsc190501.51282527': __('identifierSchemeCodeUnspsc190501.51282527'),
	'identifierSchemeCodeUnspsc190501.51282528': __('identifierSchemeCodeUnspsc190501.51282528'),
	'identifierSchemeCodeUnspsc190501.51282529': __('identifierSchemeCodeUnspsc190501.51282529'),
	'identifierSchemeCodeUnspsc190501.51282530': __('identifierSchemeCodeUnspsc190501.51282530'),
	'identifierSchemeCodeUnspsc190501.51282531': __('identifierSchemeCodeUnspsc190501.51282531'),
	'identifierSchemeCodeUnspsc190501.51282532': __('identifierSchemeCodeUnspsc190501.51282532'),
	'identifierSchemeCodeUnspsc190501.51282533': __('identifierSchemeCodeUnspsc190501.51282533'),
	'identifierSchemeCodeUnspsc190501.51282534': __('identifierSchemeCodeUnspsc190501.51282534'),
	'identifierSchemeCodeUnspsc190501.51282600': __('identifierSchemeCodeUnspsc190501.51282600'),
	'identifierSchemeCodeUnspsc190501.51282601': __('identifierSchemeCodeUnspsc190501.51282601'),
	'identifierSchemeCodeUnspsc190501.51282602': __('identifierSchemeCodeUnspsc190501.51282602'),
	'identifierSchemeCodeUnspsc190501.51282603': __('identifierSchemeCodeUnspsc190501.51282603'),
	'identifierSchemeCodeUnspsc190501.51282604': __('identifierSchemeCodeUnspsc190501.51282604'),
	'identifierSchemeCodeUnspsc190501.51282605': __('identifierSchemeCodeUnspsc190501.51282605'),
	'identifierSchemeCodeUnspsc190501.51282606': __('identifierSchemeCodeUnspsc190501.51282606'),
	'identifierSchemeCodeUnspsc190501.51282607': __('identifierSchemeCodeUnspsc190501.51282607'),
	'identifierSchemeCodeUnspsc190501.51282608': __('identifierSchemeCodeUnspsc190501.51282608'),
	'identifierSchemeCodeUnspsc190501.51282609': __('identifierSchemeCodeUnspsc190501.51282609'),
	'identifierSchemeCodeUnspsc190501.51282610': __('identifierSchemeCodeUnspsc190501.51282610'),
	'identifierSchemeCodeUnspsc190501.51282611': __('identifierSchemeCodeUnspsc190501.51282611'),
	'identifierSchemeCodeUnspsc190501.51282612': __('identifierSchemeCodeUnspsc190501.51282612'),
	'identifierSchemeCodeUnspsc190501.51282613': __('identifierSchemeCodeUnspsc190501.51282613'),
	'identifierSchemeCodeUnspsc190501.51282614': __('identifierSchemeCodeUnspsc190501.51282614'),
	'identifierSchemeCodeUnspsc190501.51282615': __('identifierSchemeCodeUnspsc190501.51282615'),
	'identifierSchemeCodeUnspsc190501.51282700': __('identifierSchemeCodeUnspsc190501.51282700'),
	'identifierSchemeCodeUnspsc190501.51282701': __('identifierSchemeCodeUnspsc190501.51282701'),
	'identifierSchemeCodeUnspsc190501.51282702': __('identifierSchemeCodeUnspsc190501.51282702'),
	'identifierSchemeCodeUnspsc190501.51282703': __('identifierSchemeCodeUnspsc190501.51282703'),
	'identifierSchemeCodeUnspsc190501.51282704': __('identifierSchemeCodeUnspsc190501.51282704'),
	'identifierSchemeCodeUnspsc190501.51282705': __('identifierSchemeCodeUnspsc190501.51282705'),
	'identifierSchemeCodeUnspsc190501.51282800': __('identifierSchemeCodeUnspsc190501.51282800'),
	'identifierSchemeCodeUnspsc190501.51282801': __('identifierSchemeCodeUnspsc190501.51282801'),
	'identifierSchemeCodeUnspsc190501.51282802': __('identifierSchemeCodeUnspsc190501.51282802'),
	'identifierSchemeCodeUnspsc190501.51282803': __('identifierSchemeCodeUnspsc190501.51282803'),
	'identifierSchemeCodeUnspsc190501.51282804': __('identifierSchemeCodeUnspsc190501.51282804'),
	'identifierSchemeCodeUnspsc190501.51282805': __('identifierSchemeCodeUnspsc190501.51282805'),
	'identifierSchemeCodeUnspsc190501.51282806': __('identifierSchemeCodeUnspsc190501.51282806'),
	'identifierSchemeCodeUnspsc190501.51282807': __('identifierSchemeCodeUnspsc190501.51282807'),
	'identifierSchemeCodeUnspsc190501.51282808': __('identifierSchemeCodeUnspsc190501.51282808'),
	'identifierSchemeCodeUnspsc190501.51282809': __('identifierSchemeCodeUnspsc190501.51282809'),
	'identifierSchemeCodeUnspsc190501.51282810': __('identifierSchemeCodeUnspsc190501.51282810'),
	'identifierSchemeCodeUnspsc190501.51282811': __('identifierSchemeCodeUnspsc190501.51282811'),
	'identifierSchemeCodeUnspsc190501.51282812': __('identifierSchemeCodeUnspsc190501.51282812'),
	'identifierSchemeCodeUnspsc190501.51282900': __('identifierSchemeCodeUnspsc190501.51282900'),
	'identifierSchemeCodeUnspsc190501.51282901': __('identifierSchemeCodeUnspsc190501.51282901'),
	'identifierSchemeCodeUnspsc190501.51282902': __('identifierSchemeCodeUnspsc190501.51282902'),
	'identifierSchemeCodeUnspsc190501.51282903': __('identifierSchemeCodeUnspsc190501.51282903'),
	'identifierSchemeCodeUnspsc190501.51282904': __('identifierSchemeCodeUnspsc190501.51282904'),
	'identifierSchemeCodeUnspsc190501.51282905': __('identifierSchemeCodeUnspsc190501.51282905'),
	'identifierSchemeCodeUnspsc190501.51282906': __('identifierSchemeCodeUnspsc190501.51282906'),
	'identifierSchemeCodeUnspsc190501.51282907': __('identifierSchemeCodeUnspsc190501.51282907'),
	'identifierSchemeCodeUnspsc190501.51282908': __('identifierSchemeCodeUnspsc190501.51282908'),
	'identifierSchemeCodeUnspsc190501.51282909': __('identifierSchemeCodeUnspsc190501.51282909'),
	'identifierSchemeCodeUnspsc190501.51282910': __('identifierSchemeCodeUnspsc190501.51282910'),
	'identifierSchemeCodeUnspsc190501.51282911': __('identifierSchemeCodeUnspsc190501.51282911'),
	'identifierSchemeCodeUnspsc190501.51282912': __('identifierSchemeCodeUnspsc190501.51282912'),
	'identifierSchemeCodeUnspsc190501.51282913': __('identifierSchemeCodeUnspsc190501.51282913'),
	'identifierSchemeCodeUnspsc190501.51282914': __('identifierSchemeCodeUnspsc190501.51282914'),
	'identifierSchemeCodeUnspsc190501.51282915': __('identifierSchemeCodeUnspsc190501.51282915'),
	'identifierSchemeCodeUnspsc190501.51282916': __('identifierSchemeCodeUnspsc190501.51282916'),
	'identifierSchemeCodeUnspsc190501.51282917': __('identifierSchemeCodeUnspsc190501.51282917'),
	'identifierSchemeCodeUnspsc190501.51282918': __('identifierSchemeCodeUnspsc190501.51282918'),
	'identifierSchemeCodeUnspsc190501.51282919': __('identifierSchemeCodeUnspsc190501.51282919'),
	'identifierSchemeCodeUnspsc190501.51282920': __('identifierSchemeCodeUnspsc190501.51282920'),
	'identifierSchemeCodeUnspsc190501.51282921': __('identifierSchemeCodeUnspsc190501.51282921'),
	'identifierSchemeCodeUnspsc190501.51282922': __('identifierSchemeCodeUnspsc190501.51282922'),
	'identifierSchemeCodeUnspsc190501.51282923': __('identifierSchemeCodeUnspsc190501.51282923'),
	'identifierSchemeCodeUnspsc190501.51282924': __('identifierSchemeCodeUnspsc190501.51282924'),
	'identifierSchemeCodeUnspsc190501.51282925': __('identifierSchemeCodeUnspsc190501.51282925'),
	'identifierSchemeCodeUnspsc190501.51282926': __('identifierSchemeCodeUnspsc190501.51282926'),
	'identifierSchemeCodeUnspsc190501.51282927': __('identifierSchemeCodeUnspsc190501.51282927'),
	'identifierSchemeCodeUnspsc190501.51282928': __('identifierSchemeCodeUnspsc190501.51282928'),
	'identifierSchemeCodeUnspsc190501.51282929': __('identifierSchemeCodeUnspsc190501.51282929'),
	'identifierSchemeCodeUnspsc190501.51282930': __('identifierSchemeCodeUnspsc190501.51282930'),
	'identifierSchemeCodeUnspsc190501.51282931': __('identifierSchemeCodeUnspsc190501.51282931'),
	'identifierSchemeCodeUnspsc190501.51282932': __('identifierSchemeCodeUnspsc190501.51282932'),
	'identifierSchemeCodeUnspsc190501.51282933': __('identifierSchemeCodeUnspsc190501.51282933'),
	'identifierSchemeCodeUnspsc190501.51282934': __('identifierSchemeCodeUnspsc190501.51282934'),
	'identifierSchemeCodeUnspsc190501.51282935': __('identifierSchemeCodeUnspsc190501.51282935'),
	'identifierSchemeCodeUnspsc190501.51282936': __('identifierSchemeCodeUnspsc190501.51282936'),
	'identifierSchemeCodeUnspsc190501.51282937': __('identifierSchemeCodeUnspsc190501.51282937'),
	'identifierSchemeCodeUnspsc190501.51282938': __('identifierSchemeCodeUnspsc190501.51282938'),
	'identifierSchemeCodeUnspsc190501.51282939': __('identifierSchemeCodeUnspsc190501.51282939'),
	'identifierSchemeCodeUnspsc190501.51282940': __('identifierSchemeCodeUnspsc190501.51282940'),
	'identifierSchemeCodeUnspsc190501.51282941': __('identifierSchemeCodeUnspsc190501.51282941'),
	'identifierSchemeCodeUnspsc190501.51282942': __('identifierSchemeCodeUnspsc190501.51282942'),
	'identifierSchemeCodeUnspsc190501.51282943': __('identifierSchemeCodeUnspsc190501.51282943'),
	'identifierSchemeCodeUnspsc190501.51282944': __('identifierSchemeCodeUnspsc190501.51282944'),
	'identifierSchemeCodeUnspsc190501.51282945': __('identifierSchemeCodeUnspsc190501.51282945'),
	'identifierSchemeCodeUnspsc190501.51282946': __('identifierSchemeCodeUnspsc190501.51282946'),
	'identifierSchemeCodeUnspsc190501.51282947': __('identifierSchemeCodeUnspsc190501.51282947'),
	'identifierSchemeCodeUnspsc190501.51282948': __('identifierSchemeCodeUnspsc190501.51282948'),
	'identifierSchemeCodeUnspsc190501.51282949': __('identifierSchemeCodeUnspsc190501.51282949'),
	'identifierSchemeCodeUnspsc190501.51282950': __('identifierSchemeCodeUnspsc190501.51282950'),
	'identifierSchemeCodeUnspsc190501.51282951': __('identifierSchemeCodeUnspsc190501.51282951'),
	'identifierSchemeCodeUnspsc190501.51282952': __('identifierSchemeCodeUnspsc190501.51282952'),
	'identifierSchemeCodeUnspsc190501.51282953': __('identifierSchemeCodeUnspsc190501.51282953'),
	'identifierSchemeCodeUnspsc190501.51282954': __('identifierSchemeCodeUnspsc190501.51282954'),
	'identifierSchemeCodeUnspsc190501.51282955': __('identifierSchemeCodeUnspsc190501.51282955'),
	'identifierSchemeCodeUnspsc190501.51282956': __('identifierSchemeCodeUnspsc190501.51282956'),
	'identifierSchemeCodeUnspsc190501.51282957': __('identifierSchemeCodeUnspsc190501.51282957'),
	'identifierSchemeCodeUnspsc190501.51283000': __('identifierSchemeCodeUnspsc190501.51283000'),
	'identifierSchemeCodeUnspsc190501.51283001': __('identifierSchemeCodeUnspsc190501.51283001'),
	'identifierSchemeCodeUnspsc190501.51283002': __('identifierSchemeCodeUnspsc190501.51283002'),
	'identifierSchemeCodeUnspsc190501.51283003': __('identifierSchemeCodeUnspsc190501.51283003'),
	'identifierSchemeCodeUnspsc190501.51283004': __('identifierSchemeCodeUnspsc190501.51283004'),
	'identifierSchemeCodeUnspsc190501.51283005': __('identifierSchemeCodeUnspsc190501.51283005'),
	'identifierSchemeCodeUnspsc190501.51283006': __('identifierSchemeCodeUnspsc190501.51283006'),
	'identifierSchemeCodeUnspsc190501.51283007': __('identifierSchemeCodeUnspsc190501.51283007'),
	'identifierSchemeCodeUnspsc190501.51283100': __('identifierSchemeCodeUnspsc190501.51283100'),
	'identifierSchemeCodeUnspsc190501.51283101': __('identifierSchemeCodeUnspsc190501.51283101'),
	'identifierSchemeCodeUnspsc190501.51283102': __('identifierSchemeCodeUnspsc190501.51283102'),
	'identifierSchemeCodeUnspsc190501.51283103': __('identifierSchemeCodeUnspsc190501.51283103'),
	'identifierSchemeCodeUnspsc190501.51283104': __('identifierSchemeCodeUnspsc190501.51283104'),
	'identifierSchemeCodeUnspsc190501.51283105': __('identifierSchemeCodeUnspsc190501.51283105'),
	'identifierSchemeCodeUnspsc190501.51283106': __('identifierSchemeCodeUnspsc190501.51283106'),
	'identifierSchemeCodeUnspsc190501.51283107': __('identifierSchemeCodeUnspsc190501.51283107'),
	'identifierSchemeCodeUnspsc190501.51283108': __('identifierSchemeCodeUnspsc190501.51283108'),
	'identifierSchemeCodeUnspsc190501.51283109': __('identifierSchemeCodeUnspsc190501.51283109'),
	'identifierSchemeCodeUnspsc190501.51283110': __('identifierSchemeCodeUnspsc190501.51283110'),
	'identifierSchemeCodeUnspsc190501.51283111': __('identifierSchemeCodeUnspsc190501.51283111'),
	'identifierSchemeCodeUnspsc190501.51283112': __('identifierSchemeCodeUnspsc190501.51283112'),
	'identifierSchemeCodeUnspsc190501.51283200': __('identifierSchemeCodeUnspsc190501.51283200'),
	'identifierSchemeCodeUnspsc190501.51283201': __('identifierSchemeCodeUnspsc190501.51283201'),
	'identifierSchemeCodeUnspsc190501.51283202': __('identifierSchemeCodeUnspsc190501.51283202'),
	'identifierSchemeCodeUnspsc190501.51283203': __('identifierSchemeCodeUnspsc190501.51283203'),
	'identifierSchemeCodeUnspsc190501.51283204': __('identifierSchemeCodeUnspsc190501.51283204'),
	'identifierSchemeCodeUnspsc190501.51283205': __('identifierSchemeCodeUnspsc190501.51283205'),
	'identifierSchemeCodeUnspsc190501.51283206': __('identifierSchemeCodeUnspsc190501.51283206'),
	'identifierSchemeCodeUnspsc190501.51283207': __('identifierSchemeCodeUnspsc190501.51283207'),
	'identifierSchemeCodeUnspsc190501.51283208': __('identifierSchemeCodeUnspsc190501.51283208'),
	'identifierSchemeCodeUnspsc190501.51283209': __('identifierSchemeCodeUnspsc190501.51283209'),
	'identifierSchemeCodeUnspsc190501.51283210': __('identifierSchemeCodeUnspsc190501.51283210'),
	'identifierSchemeCodeUnspsc190501.51283211': __('identifierSchemeCodeUnspsc190501.51283211'),
	'identifierSchemeCodeUnspsc190501.51283212': __('identifierSchemeCodeUnspsc190501.51283212'),
	'identifierSchemeCodeUnspsc190501.51283213': __('identifierSchemeCodeUnspsc190501.51283213'),
	'identifierSchemeCodeUnspsc190501.51283214': __('identifierSchemeCodeUnspsc190501.51283214'),
	'identifierSchemeCodeUnspsc190501.51283215': __('identifierSchemeCodeUnspsc190501.51283215'),
	'identifierSchemeCodeUnspsc190501.51283216': __('identifierSchemeCodeUnspsc190501.51283216'),
	'identifierSchemeCodeUnspsc190501.51283217': __('identifierSchemeCodeUnspsc190501.51283217'),
	'identifierSchemeCodeUnspsc190501.51283218': __('identifierSchemeCodeUnspsc190501.51283218'),
	'identifierSchemeCodeUnspsc190501.51283219': __('identifierSchemeCodeUnspsc190501.51283219'),
	'identifierSchemeCodeUnspsc190501.51283220': __('identifierSchemeCodeUnspsc190501.51283220'),
	'identifierSchemeCodeUnspsc190501.51283221': __('identifierSchemeCodeUnspsc190501.51283221'),
	'identifierSchemeCodeUnspsc190501.51283222': __('identifierSchemeCodeUnspsc190501.51283222'),
	'identifierSchemeCodeUnspsc190501.51283223': __('identifierSchemeCodeUnspsc190501.51283223'),
	'identifierSchemeCodeUnspsc190501.51283224': __('identifierSchemeCodeUnspsc190501.51283224'),
	'identifierSchemeCodeUnspsc190501.51283225': __('identifierSchemeCodeUnspsc190501.51283225'),
	'identifierSchemeCodeUnspsc190501.51283300': __('identifierSchemeCodeUnspsc190501.51283300'),
	'identifierSchemeCodeUnspsc190501.51283301': __('identifierSchemeCodeUnspsc190501.51283301'),
	'identifierSchemeCodeUnspsc190501.51283302': __('identifierSchemeCodeUnspsc190501.51283302'),
	'identifierSchemeCodeUnspsc190501.51283303': __('identifierSchemeCodeUnspsc190501.51283303'),
	'identifierSchemeCodeUnspsc190501.51283304': __('identifierSchemeCodeUnspsc190501.51283304'),
	'identifierSchemeCodeUnspsc190501.51283305': __('identifierSchemeCodeUnspsc190501.51283305'),
	'identifierSchemeCodeUnspsc190501.51283306': __('identifierSchemeCodeUnspsc190501.51283306'),
	'identifierSchemeCodeUnspsc190501.51283400': __('identifierSchemeCodeUnspsc190501.51283400'),
	'identifierSchemeCodeUnspsc190501.51283401': __('identifierSchemeCodeUnspsc190501.51283401'),
	'identifierSchemeCodeUnspsc190501.51283402': __('identifierSchemeCodeUnspsc190501.51283402'),
	'identifierSchemeCodeUnspsc190501.51283403': __('identifierSchemeCodeUnspsc190501.51283403'),
	'identifierSchemeCodeUnspsc190501.51283404': __('identifierSchemeCodeUnspsc190501.51283404'),
	'identifierSchemeCodeUnspsc190501.51283405': __('identifierSchemeCodeUnspsc190501.51283405'),
	'identifierSchemeCodeUnspsc190501.51283406': __('identifierSchemeCodeUnspsc190501.51283406'),
	'identifierSchemeCodeUnspsc190501.51283407': __('identifierSchemeCodeUnspsc190501.51283407'),
	'identifierSchemeCodeUnspsc190501.51283408': __('identifierSchemeCodeUnspsc190501.51283408'),
	'identifierSchemeCodeUnspsc190501.51283409': __('identifierSchemeCodeUnspsc190501.51283409'),
	'identifierSchemeCodeUnspsc190501.51283410': __('identifierSchemeCodeUnspsc190501.51283410'),
	'identifierSchemeCodeUnspsc190501.51283411': __('identifierSchemeCodeUnspsc190501.51283411'),
	'identifierSchemeCodeUnspsc190501.51283412': __('identifierSchemeCodeUnspsc190501.51283412'),
	'identifierSchemeCodeUnspsc190501.51283413': __('identifierSchemeCodeUnspsc190501.51283413'),
	'identifierSchemeCodeUnspsc190501.51283414': __('identifierSchemeCodeUnspsc190501.51283414'),
	'identifierSchemeCodeUnspsc190501.51283415': __('identifierSchemeCodeUnspsc190501.51283415'),
	'identifierSchemeCodeUnspsc190501.51283416': __('identifierSchemeCodeUnspsc190501.51283416'),
	'identifierSchemeCodeUnspsc190501.51283417': __('identifierSchemeCodeUnspsc190501.51283417'),
	'identifierSchemeCodeUnspsc190501.51283418': __('identifierSchemeCodeUnspsc190501.51283418'),
	'identifierSchemeCodeUnspsc190501.51283419': __('identifierSchemeCodeUnspsc190501.51283419'),
	'identifierSchemeCodeUnspsc190501.51283420': __('identifierSchemeCodeUnspsc190501.51283420'),
	'identifierSchemeCodeUnspsc190501.51283421': __('identifierSchemeCodeUnspsc190501.51283421'),
	'identifierSchemeCodeUnspsc190501.51283422': __('identifierSchemeCodeUnspsc190501.51283422'),
	'identifierSchemeCodeUnspsc190501.51283423': __('identifierSchemeCodeUnspsc190501.51283423'),
	'identifierSchemeCodeUnspsc190501.51283500': __('identifierSchemeCodeUnspsc190501.51283500'),
	'identifierSchemeCodeUnspsc190501.51283501': __('identifierSchemeCodeUnspsc190501.51283501'),
	'identifierSchemeCodeUnspsc190501.51283502': __('identifierSchemeCodeUnspsc190501.51283502'),
	'identifierSchemeCodeUnspsc190501.51283503': __('identifierSchemeCodeUnspsc190501.51283503'),
	'identifierSchemeCodeUnspsc190501.51283504': __('identifierSchemeCodeUnspsc190501.51283504'),
	'identifierSchemeCodeUnspsc190501.51283505': __('identifierSchemeCodeUnspsc190501.51283505'),
	'identifierSchemeCodeUnspsc190501.51283506': __('identifierSchemeCodeUnspsc190501.51283506'),
	'identifierSchemeCodeUnspsc190501.51283507': __('identifierSchemeCodeUnspsc190501.51283507'),
	'identifierSchemeCodeUnspsc190501.51283508': __('identifierSchemeCodeUnspsc190501.51283508'),
	'identifierSchemeCodeUnspsc190501.51283509': __('identifierSchemeCodeUnspsc190501.51283509'),
	'identifierSchemeCodeUnspsc190501.51283510': __('identifierSchemeCodeUnspsc190501.51283510'),
	'identifierSchemeCodeUnspsc190501.51283511': __('identifierSchemeCodeUnspsc190501.51283511'),
	'identifierSchemeCodeUnspsc190501.51283512': __('identifierSchemeCodeUnspsc190501.51283512'),
	'identifierSchemeCodeUnspsc190501.51283513': __('identifierSchemeCodeUnspsc190501.51283513'),
	'identifierSchemeCodeUnspsc190501.51283514': __('identifierSchemeCodeUnspsc190501.51283514'),
	'identifierSchemeCodeUnspsc190501.51283515': __('identifierSchemeCodeUnspsc190501.51283515'),
	'identifierSchemeCodeUnspsc190501.51283516': __('identifierSchemeCodeUnspsc190501.51283516'),
	'identifierSchemeCodeUnspsc190501.51283517': __('identifierSchemeCodeUnspsc190501.51283517'),
	'identifierSchemeCodeUnspsc190501.51283600': __('identifierSchemeCodeUnspsc190501.51283600'),
	'identifierSchemeCodeUnspsc190501.51283601': __('identifierSchemeCodeUnspsc190501.51283601'),
	'identifierSchemeCodeUnspsc190501.51283602': __('identifierSchemeCodeUnspsc190501.51283602'),
	'identifierSchemeCodeUnspsc190501.51283603': __('identifierSchemeCodeUnspsc190501.51283603'),
	'identifierSchemeCodeUnspsc190501.51283604': __('identifierSchemeCodeUnspsc190501.51283604'),
	'identifierSchemeCodeUnspsc190501.51283605': __('identifierSchemeCodeUnspsc190501.51283605'),
	'identifierSchemeCodeUnspsc190501.51283606': __('identifierSchemeCodeUnspsc190501.51283606'),
	'identifierSchemeCodeUnspsc190501.51283607': __('identifierSchemeCodeUnspsc190501.51283607'),
	'identifierSchemeCodeUnspsc190501.51283608': __('identifierSchemeCodeUnspsc190501.51283608'),
	'identifierSchemeCodeUnspsc190501.51283609': __('identifierSchemeCodeUnspsc190501.51283609'),
	'identifierSchemeCodeUnspsc190501.51283610': __('identifierSchemeCodeUnspsc190501.51283610'),
	'identifierSchemeCodeUnspsc190501.51283611': __('identifierSchemeCodeUnspsc190501.51283611'),
	'identifierSchemeCodeUnspsc190501.51283612': __('identifierSchemeCodeUnspsc190501.51283612'),
	'identifierSchemeCodeUnspsc190501.51283700': __('identifierSchemeCodeUnspsc190501.51283700'),
	'identifierSchemeCodeUnspsc190501.51283701': __('identifierSchemeCodeUnspsc190501.51283701'),
	'identifierSchemeCodeUnspsc190501.51283702': __('identifierSchemeCodeUnspsc190501.51283702'),
	'identifierSchemeCodeUnspsc190501.51283703': __('identifierSchemeCodeUnspsc190501.51283703'),
	'identifierSchemeCodeUnspsc190501.51283704': __('identifierSchemeCodeUnspsc190501.51283704'),
	'identifierSchemeCodeUnspsc190501.51283705': __('identifierSchemeCodeUnspsc190501.51283705'),
	'identifierSchemeCodeUnspsc190501.51283706': __('identifierSchemeCodeUnspsc190501.51283706'),
	'identifierSchemeCodeUnspsc190501.51283707': __('identifierSchemeCodeUnspsc190501.51283707'),
	'identifierSchemeCodeUnspsc190501.51283708': __('identifierSchemeCodeUnspsc190501.51283708'),
	'identifierSchemeCodeUnspsc190501.51283709': __('identifierSchemeCodeUnspsc190501.51283709'),
	'identifierSchemeCodeUnspsc190501.51283710': __('identifierSchemeCodeUnspsc190501.51283710'),
	'identifierSchemeCodeUnspsc190501.51283711': __('identifierSchemeCodeUnspsc190501.51283711'),
	'identifierSchemeCodeUnspsc190501.51283712': __('identifierSchemeCodeUnspsc190501.51283712'),
	'identifierSchemeCodeUnspsc190501.51283713': __('identifierSchemeCodeUnspsc190501.51283713'),
	'identifierSchemeCodeUnspsc190501.51283714': __('identifierSchemeCodeUnspsc190501.51283714'),
	'identifierSchemeCodeUnspsc190501.51283715': __('identifierSchemeCodeUnspsc190501.51283715'),
	'identifierSchemeCodeUnspsc190501.51283716': __('identifierSchemeCodeUnspsc190501.51283716'),
	'identifierSchemeCodeUnspsc190501.51283717': __('identifierSchemeCodeUnspsc190501.51283717'),
	'identifierSchemeCodeUnspsc190501.51283718': __('identifierSchemeCodeUnspsc190501.51283718'),
	'identifierSchemeCodeUnspsc190501.51283719': __('identifierSchemeCodeUnspsc190501.51283719'),
	'identifierSchemeCodeUnspsc190501.51283720': __('identifierSchemeCodeUnspsc190501.51283720'),
	'identifierSchemeCodeUnspsc190501.51283721': __('identifierSchemeCodeUnspsc190501.51283721'),
	'identifierSchemeCodeUnspsc190501.51283722': __('identifierSchemeCodeUnspsc190501.51283722'),
	'identifierSchemeCodeUnspsc190501.51283723': __('identifierSchemeCodeUnspsc190501.51283723'),
	'identifierSchemeCodeUnspsc190501.51283724': __('identifierSchemeCodeUnspsc190501.51283724'),
	'identifierSchemeCodeUnspsc190501.51283725': __('identifierSchemeCodeUnspsc190501.51283725'),
	'identifierSchemeCodeUnspsc190501.51283726': __('identifierSchemeCodeUnspsc190501.51283726'),
	'identifierSchemeCodeUnspsc190501.51283727': __('identifierSchemeCodeUnspsc190501.51283727'),
	'identifierSchemeCodeUnspsc190501.51283728': __('identifierSchemeCodeUnspsc190501.51283728'),
	'identifierSchemeCodeUnspsc190501.51283800': __('identifierSchemeCodeUnspsc190501.51283800'),
	'identifierSchemeCodeUnspsc190501.51283801': __('identifierSchemeCodeUnspsc190501.51283801'),
	'identifierSchemeCodeUnspsc190501.51283802': __('identifierSchemeCodeUnspsc190501.51283802'),
	'identifierSchemeCodeUnspsc190501.51283803': __('identifierSchemeCodeUnspsc190501.51283803'),
	'identifierSchemeCodeUnspsc190501.51283804': __('identifierSchemeCodeUnspsc190501.51283804'),
	'identifierSchemeCodeUnspsc190501.51283805': __('identifierSchemeCodeUnspsc190501.51283805'),
	'identifierSchemeCodeUnspsc190501.51283900': __('identifierSchemeCodeUnspsc190501.51283900'),
	'identifierSchemeCodeUnspsc190501.51283901': __('identifierSchemeCodeUnspsc190501.51283901'),
	'identifierSchemeCodeUnspsc190501.51283902': __('identifierSchemeCodeUnspsc190501.51283902'),
	'identifierSchemeCodeUnspsc190501.51283903': __('identifierSchemeCodeUnspsc190501.51283903'),
	'identifierSchemeCodeUnspsc190501.51283904': __('identifierSchemeCodeUnspsc190501.51283904'),
	'identifierSchemeCodeUnspsc190501.51283905': __('identifierSchemeCodeUnspsc190501.51283905'),
	'identifierSchemeCodeUnspsc190501.51283906': __('identifierSchemeCodeUnspsc190501.51283906'),
	'identifierSchemeCodeUnspsc190501.51283907': __('identifierSchemeCodeUnspsc190501.51283907'),
	'identifierSchemeCodeUnspsc190501.51283908': __('identifierSchemeCodeUnspsc190501.51283908'),
	'identifierSchemeCodeUnspsc190501.51283909': __('identifierSchemeCodeUnspsc190501.51283909'),
	'identifierSchemeCodeUnspsc190501.51283910': __('identifierSchemeCodeUnspsc190501.51283910'),
	'identifierSchemeCodeUnspsc190501.51283911': __('identifierSchemeCodeUnspsc190501.51283911'),
	'identifierSchemeCodeUnspsc190501.51283912': __('identifierSchemeCodeUnspsc190501.51283912'),
	'identifierSchemeCodeUnspsc190501.51283913': __('identifierSchemeCodeUnspsc190501.51283913'),
	'identifierSchemeCodeUnspsc190501.51283914': __('identifierSchemeCodeUnspsc190501.51283914'),
	'identifierSchemeCodeUnspsc190501.51283915': __('identifierSchemeCodeUnspsc190501.51283915'),
	'identifierSchemeCodeUnspsc190501.51283916': __('identifierSchemeCodeUnspsc190501.51283916'),
	'identifierSchemeCodeUnspsc190501.51283917': __('identifierSchemeCodeUnspsc190501.51283917'),
	'identifierSchemeCodeUnspsc190501.51283918': __('identifierSchemeCodeUnspsc190501.51283918'),
	'identifierSchemeCodeUnspsc190501.51283919': __('identifierSchemeCodeUnspsc190501.51283919'),
	'identifierSchemeCodeUnspsc190501.51283920': __('identifierSchemeCodeUnspsc190501.51283920'),
	'identifierSchemeCodeUnspsc190501.51283921': __('identifierSchemeCodeUnspsc190501.51283921'),
	'identifierSchemeCodeUnspsc190501.51283922': __('identifierSchemeCodeUnspsc190501.51283922'),
	'identifierSchemeCodeUnspsc190501.51283923': __('identifierSchemeCodeUnspsc190501.51283923'),
	'identifierSchemeCodeUnspsc190501.51283924': __('identifierSchemeCodeUnspsc190501.51283924'),
	'identifierSchemeCodeUnspsc190501.51283925': __('identifierSchemeCodeUnspsc190501.51283925'),
	'identifierSchemeCodeUnspsc190501.51283926': __('identifierSchemeCodeUnspsc190501.51283926'),
	'identifierSchemeCodeUnspsc190501.51283927': __('identifierSchemeCodeUnspsc190501.51283927'),
	'identifierSchemeCodeUnspsc190501.51283928': __('identifierSchemeCodeUnspsc190501.51283928'),
	'identifierSchemeCodeUnspsc190501.51283929': __('identifierSchemeCodeUnspsc190501.51283929'),
	'identifierSchemeCodeUnspsc190501.51283930': __('identifierSchemeCodeUnspsc190501.51283930'),
	'identifierSchemeCodeUnspsc190501.51283931': __('identifierSchemeCodeUnspsc190501.51283931'),
	'identifierSchemeCodeUnspsc190501.51283932': __('identifierSchemeCodeUnspsc190501.51283932'),
	'identifierSchemeCodeUnspsc190501.51283933': __('identifierSchemeCodeUnspsc190501.51283933'),
	'identifierSchemeCodeUnspsc190501.51283934': __('identifierSchemeCodeUnspsc190501.51283934'),
	'identifierSchemeCodeUnspsc190501.51283935': __('identifierSchemeCodeUnspsc190501.51283935'),
	'identifierSchemeCodeUnspsc190501.51283936': __('identifierSchemeCodeUnspsc190501.51283936'),
	'identifierSchemeCodeUnspsc190501.51283937': __('identifierSchemeCodeUnspsc190501.51283937'),
	'identifierSchemeCodeUnspsc190501.51283938': __('identifierSchemeCodeUnspsc190501.51283938'),
	'identifierSchemeCodeUnspsc190501.51283939': __('identifierSchemeCodeUnspsc190501.51283939'),
	'identifierSchemeCodeUnspsc190501.51283940': __('identifierSchemeCodeUnspsc190501.51283940'),
	'identifierSchemeCodeUnspsc190501.51283941': __('identifierSchemeCodeUnspsc190501.51283941'),
	'identifierSchemeCodeUnspsc190501.51283942': __('identifierSchemeCodeUnspsc190501.51283942'),
	'identifierSchemeCodeUnspsc190501.51283943': __('identifierSchemeCodeUnspsc190501.51283943'),
	'identifierSchemeCodeUnspsc190501.51283944': __('identifierSchemeCodeUnspsc190501.51283944'),
	'identifierSchemeCodeUnspsc190501.51283945': __('identifierSchemeCodeUnspsc190501.51283945'),
	'identifierSchemeCodeUnspsc190501.51283946': __('identifierSchemeCodeUnspsc190501.51283946'),
	'identifierSchemeCodeUnspsc190501.51283947': __('identifierSchemeCodeUnspsc190501.51283947'),
	'identifierSchemeCodeUnspsc190501.51283948': __('identifierSchemeCodeUnspsc190501.51283948'),
	'identifierSchemeCodeUnspsc190501.51283949': __('identifierSchemeCodeUnspsc190501.51283949'),
	'identifierSchemeCodeUnspsc190501.51283950': __('identifierSchemeCodeUnspsc190501.51283950'),
	'identifierSchemeCodeUnspsc190501.51283951': __('identifierSchemeCodeUnspsc190501.51283951'),
	'identifierSchemeCodeUnspsc190501.51283952': __('identifierSchemeCodeUnspsc190501.51283952'),
	'identifierSchemeCodeUnspsc190501.51283953': __('identifierSchemeCodeUnspsc190501.51283953'),
	'identifierSchemeCodeUnspsc190501.51283954': __('identifierSchemeCodeUnspsc190501.51283954'),
	'identifierSchemeCodeUnspsc190501.51283955': __('identifierSchemeCodeUnspsc190501.51283955'),
	'identifierSchemeCodeUnspsc190501.51283956': __('identifierSchemeCodeUnspsc190501.51283956'),
	'identifierSchemeCodeUnspsc190501.51283957': __('identifierSchemeCodeUnspsc190501.51283957'),
	'identifierSchemeCodeUnspsc190501.51283958': __('identifierSchemeCodeUnspsc190501.51283958'),
	'identifierSchemeCodeUnspsc190501.51283959': __('identifierSchemeCodeUnspsc190501.51283959'),
	'identifierSchemeCodeUnspsc190501.51283960': __('identifierSchemeCodeUnspsc190501.51283960'),
	'identifierSchemeCodeUnspsc190501.51283961': __('identifierSchemeCodeUnspsc190501.51283961'),
	'identifierSchemeCodeUnspsc190501.51283962': __('identifierSchemeCodeUnspsc190501.51283962'),
	'identifierSchemeCodeUnspsc190501.51283963': __('identifierSchemeCodeUnspsc190501.51283963'),
	'identifierSchemeCodeUnspsc190501.51284000': __('identifierSchemeCodeUnspsc190501.51284000'),
	'identifierSchemeCodeUnspsc190501.51284001': __('identifierSchemeCodeUnspsc190501.51284001'),
	'identifierSchemeCodeUnspsc190501.51284002': __('identifierSchemeCodeUnspsc190501.51284002'),
	'identifierSchemeCodeUnspsc190501.51284003': __('identifierSchemeCodeUnspsc190501.51284003'),
	'identifierSchemeCodeUnspsc190501.51284004': __('identifierSchemeCodeUnspsc190501.51284004'),
	'identifierSchemeCodeUnspsc190501.51284005': __('identifierSchemeCodeUnspsc190501.51284005'),
	'identifierSchemeCodeUnspsc190501.51284006': __('identifierSchemeCodeUnspsc190501.51284006'),
	'identifierSchemeCodeUnspsc190501.51284007': __('identifierSchemeCodeUnspsc190501.51284007'),
	'identifierSchemeCodeUnspsc190501.51284008': __('identifierSchemeCodeUnspsc190501.51284008'),
	'identifierSchemeCodeUnspsc190501.51284009': __('identifierSchemeCodeUnspsc190501.51284009'),
	'identifierSchemeCodeUnspsc190501.51284010': __('identifierSchemeCodeUnspsc190501.51284010'),
	'identifierSchemeCodeUnspsc190501.51284011': __('identifierSchemeCodeUnspsc190501.51284011'),
	'identifierSchemeCodeUnspsc190501.51284012': __('identifierSchemeCodeUnspsc190501.51284012'),
	'identifierSchemeCodeUnspsc190501.51284013': __('identifierSchemeCodeUnspsc190501.51284013'),
	'identifierSchemeCodeUnspsc190501.51284014': __('identifierSchemeCodeUnspsc190501.51284014'),
	'identifierSchemeCodeUnspsc190501.51284015': __('identifierSchemeCodeUnspsc190501.51284015'),
	'identifierSchemeCodeUnspsc190501.51284016': __('identifierSchemeCodeUnspsc190501.51284016'),
	'identifierSchemeCodeUnspsc190501.51284017': __('identifierSchemeCodeUnspsc190501.51284017'),
	'identifierSchemeCodeUnspsc190501.51284018': __('identifierSchemeCodeUnspsc190501.51284018'),
	'identifierSchemeCodeUnspsc190501.51284019': __('identifierSchemeCodeUnspsc190501.51284019'),
	'identifierSchemeCodeUnspsc190501.51284020': __('identifierSchemeCodeUnspsc190501.51284020'),
	'identifierSchemeCodeUnspsc190501.51284021': __('identifierSchemeCodeUnspsc190501.51284021'),
	'identifierSchemeCodeUnspsc190501.51284022': __('identifierSchemeCodeUnspsc190501.51284022'),
	'identifierSchemeCodeUnspsc190501.51284023': __('identifierSchemeCodeUnspsc190501.51284023'),
	'identifierSchemeCodeUnspsc190501.51284024': __('identifierSchemeCodeUnspsc190501.51284024'),
	'identifierSchemeCodeUnspsc190501.51284025': __('identifierSchemeCodeUnspsc190501.51284025'),
	'identifierSchemeCodeUnspsc190501.51284026': __('identifierSchemeCodeUnspsc190501.51284026'),
	'identifierSchemeCodeUnspsc190501.51284027': __('identifierSchemeCodeUnspsc190501.51284027'),
	'identifierSchemeCodeUnspsc190501.51284028': __('identifierSchemeCodeUnspsc190501.51284028'),
	'identifierSchemeCodeUnspsc190501.51284029': __('identifierSchemeCodeUnspsc190501.51284029'),
	'identifierSchemeCodeUnspsc190501.51284030': __('identifierSchemeCodeUnspsc190501.51284030'),
	'identifierSchemeCodeUnspsc190501.51284031': __('identifierSchemeCodeUnspsc190501.51284031'),
	'identifierSchemeCodeUnspsc190501.51284032': __('identifierSchemeCodeUnspsc190501.51284032'),
	'identifierSchemeCodeUnspsc190501.51284033': __('identifierSchemeCodeUnspsc190501.51284033'),
	'identifierSchemeCodeUnspsc190501.51284034': __('identifierSchemeCodeUnspsc190501.51284034'),
	'identifierSchemeCodeUnspsc190501.51284035': __('identifierSchemeCodeUnspsc190501.51284035'),
	'identifierSchemeCodeUnspsc190501.51284036': __('identifierSchemeCodeUnspsc190501.51284036'),
	'identifierSchemeCodeUnspsc190501.51284037': __('identifierSchemeCodeUnspsc190501.51284037'),
	'identifierSchemeCodeUnspsc190501.51284038': __('identifierSchemeCodeUnspsc190501.51284038'),
	'identifierSchemeCodeUnspsc190501.51284039': __('identifierSchemeCodeUnspsc190501.51284039'),
	'identifierSchemeCodeUnspsc190501.51284040': __('identifierSchemeCodeUnspsc190501.51284040'),
	'identifierSchemeCodeUnspsc190501.51284041': __('identifierSchemeCodeUnspsc190501.51284041'),
	'identifierSchemeCodeUnspsc190501.51284042': __('identifierSchemeCodeUnspsc190501.51284042'),
	'identifierSchemeCodeUnspsc190501.51284043': __('identifierSchemeCodeUnspsc190501.51284043'),
	'identifierSchemeCodeUnspsc190501.51284044': __('identifierSchemeCodeUnspsc190501.51284044'),
	'identifierSchemeCodeUnspsc190501.51284045': __('identifierSchemeCodeUnspsc190501.51284045'),
	'identifierSchemeCodeUnspsc190501.51284046': __('identifierSchemeCodeUnspsc190501.51284046'),
	'identifierSchemeCodeUnspsc190501.51284047': __('identifierSchemeCodeUnspsc190501.51284047'),
	'identifierSchemeCodeUnspsc190501.51284100': __('identifierSchemeCodeUnspsc190501.51284100'),
	'identifierSchemeCodeUnspsc190501.51284101': __('identifierSchemeCodeUnspsc190501.51284101'),
	'identifierSchemeCodeUnspsc190501.51284102': __('identifierSchemeCodeUnspsc190501.51284102'),
	'identifierSchemeCodeUnspsc190501.51284103': __('identifierSchemeCodeUnspsc190501.51284103'),
	'identifierSchemeCodeUnspsc190501.51284104': __('identifierSchemeCodeUnspsc190501.51284104'),
	'identifierSchemeCodeUnspsc190501.51284105': __('identifierSchemeCodeUnspsc190501.51284105'),
	'identifierSchemeCodeUnspsc190501.51284106': __('identifierSchemeCodeUnspsc190501.51284106'),
	'identifierSchemeCodeUnspsc190501.51284107': __('identifierSchemeCodeUnspsc190501.51284107'),
	'identifierSchemeCodeUnspsc190501.51284108': __('identifierSchemeCodeUnspsc190501.51284108'),
	'identifierSchemeCodeUnspsc190501.51284109': __('identifierSchemeCodeUnspsc190501.51284109'),
	'identifierSchemeCodeUnspsc190501.51284110': __('identifierSchemeCodeUnspsc190501.51284110'),
	'identifierSchemeCodeUnspsc190501.51284111': __('identifierSchemeCodeUnspsc190501.51284111'),
	'identifierSchemeCodeUnspsc190501.51284112': __('identifierSchemeCodeUnspsc190501.51284112'),
	'identifierSchemeCodeUnspsc190501.51284113': __('identifierSchemeCodeUnspsc190501.51284113'),
	'identifierSchemeCodeUnspsc190501.51284114': __('identifierSchemeCodeUnspsc190501.51284114'),
	'identifierSchemeCodeUnspsc190501.51284115': __('identifierSchemeCodeUnspsc190501.51284115'),
	'identifierSchemeCodeUnspsc190501.51284116': __('identifierSchemeCodeUnspsc190501.51284116'),
	'identifierSchemeCodeUnspsc190501.51284117': __('identifierSchemeCodeUnspsc190501.51284117'),
	'identifierSchemeCodeUnspsc190501.51284118': __('identifierSchemeCodeUnspsc190501.51284118'),
	'identifierSchemeCodeUnspsc190501.51284119': __('identifierSchemeCodeUnspsc190501.51284119'),
	'identifierSchemeCodeUnspsc190501.51284120': __('identifierSchemeCodeUnspsc190501.51284120'),
	'identifierSchemeCodeUnspsc190501.51284121': __('identifierSchemeCodeUnspsc190501.51284121'),
	'identifierSchemeCodeUnspsc190501.51284122': __('identifierSchemeCodeUnspsc190501.51284122'),
	'identifierSchemeCodeUnspsc190501.51284123': __('identifierSchemeCodeUnspsc190501.51284123'),
	'identifierSchemeCodeUnspsc190501.51284124': __('identifierSchemeCodeUnspsc190501.51284124'),
	'identifierSchemeCodeUnspsc190501.51284125': __('identifierSchemeCodeUnspsc190501.51284125'),
	'identifierSchemeCodeUnspsc190501.51284126': __('identifierSchemeCodeUnspsc190501.51284126'),
	'identifierSchemeCodeUnspsc190501.51284127': __('identifierSchemeCodeUnspsc190501.51284127'),
	'identifierSchemeCodeUnspsc190501.51284128': __('identifierSchemeCodeUnspsc190501.51284128'),
	'identifierSchemeCodeUnspsc190501.51284129': __('identifierSchemeCodeUnspsc190501.51284129'),
	'identifierSchemeCodeUnspsc190501.51284130': __('identifierSchemeCodeUnspsc190501.51284130'),
	'identifierSchemeCodeUnspsc190501.51284131': __('identifierSchemeCodeUnspsc190501.51284131'),
	'identifierSchemeCodeUnspsc190501.51284132': __('identifierSchemeCodeUnspsc190501.51284132'),
	'identifierSchemeCodeUnspsc190501.51284133': __('identifierSchemeCodeUnspsc190501.51284133'),
	'identifierSchemeCodeUnspsc190501.51284134': __('identifierSchemeCodeUnspsc190501.51284134'),
	'identifierSchemeCodeUnspsc190501.51284135': __('identifierSchemeCodeUnspsc190501.51284135'),
	'identifierSchemeCodeUnspsc190501.51284136': __('identifierSchemeCodeUnspsc190501.51284136'),
	'identifierSchemeCodeUnspsc190501.51284137': __('identifierSchemeCodeUnspsc190501.51284137'),
	'identifierSchemeCodeUnspsc190501.51284138': __('identifierSchemeCodeUnspsc190501.51284138'),
	'identifierSchemeCodeUnspsc190501.51284139': __('identifierSchemeCodeUnspsc190501.51284139'),
	'identifierSchemeCodeUnspsc190501.51284140': __('identifierSchemeCodeUnspsc190501.51284140'),
	'identifierSchemeCodeUnspsc190501.51284141': __('identifierSchemeCodeUnspsc190501.51284141'),
	'identifierSchemeCodeUnspsc190501.51284142': __('identifierSchemeCodeUnspsc190501.51284142'),
	'identifierSchemeCodeUnspsc190501.51284143': __('identifierSchemeCodeUnspsc190501.51284143'),
	'identifierSchemeCodeUnspsc190501.51284144': __('identifierSchemeCodeUnspsc190501.51284144'),
	'identifierSchemeCodeUnspsc190501.51284145': __('identifierSchemeCodeUnspsc190501.51284145'),
	'identifierSchemeCodeUnspsc190501.51284146': __('identifierSchemeCodeUnspsc190501.51284146'),
	'identifierSchemeCodeUnspsc190501.51284147': __('identifierSchemeCodeUnspsc190501.51284147'),
	'identifierSchemeCodeUnspsc190501.51284148': __('identifierSchemeCodeUnspsc190501.51284148'),
	'identifierSchemeCodeUnspsc190501.51284149': __('identifierSchemeCodeUnspsc190501.51284149'),
	'identifierSchemeCodeUnspsc190501.51284150': __('identifierSchemeCodeUnspsc190501.51284150'),
	'identifierSchemeCodeUnspsc190501.51284300': __('identifierSchemeCodeUnspsc190501.51284300'),
	'identifierSchemeCodeUnspsc190501.51284301': __('identifierSchemeCodeUnspsc190501.51284301'),
	'identifierSchemeCodeUnspsc190501.51284302': __('identifierSchemeCodeUnspsc190501.51284302'),
	'identifierSchemeCodeUnspsc190501.51284303': __('identifierSchemeCodeUnspsc190501.51284303'),
	'identifierSchemeCodeUnspsc190501.51284304': __('identifierSchemeCodeUnspsc190501.51284304'),
	'identifierSchemeCodeUnspsc190501.51284305': __('identifierSchemeCodeUnspsc190501.51284305'),
	'identifierSchemeCodeUnspsc190501.51284306': __('identifierSchemeCodeUnspsc190501.51284306'),
	'identifierSchemeCodeUnspsc190501.51284307': __('identifierSchemeCodeUnspsc190501.51284307'),
	'identifierSchemeCodeUnspsc190501.51284400': __('identifierSchemeCodeUnspsc190501.51284400'),
	'identifierSchemeCodeUnspsc190501.51284401': __('identifierSchemeCodeUnspsc190501.51284401'),
	'identifierSchemeCodeUnspsc190501.51284402': __('identifierSchemeCodeUnspsc190501.51284402'),
	'identifierSchemeCodeUnspsc190501.51284403': __('identifierSchemeCodeUnspsc190501.51284403'),
	'identifierSchemeCodeUnspsc190501.51284500': __('identifierSchemeCodeUnspsc190501.51284500'),
	'identifierSchemeCodeUnspsc190501.51284501': __('identifierSchemeCodeUnspsc190501.51284501'),
	'identifierSchemeCodeUnspsc190501.51284502': __('identifierSchemeCodeUnspsc190501.51284502'),
	'identifierSchemeCodeUnspsc190501.51284503': __('identifierSchemeCodeUnspsc190501.51284503'),
	'identifierSchemeCodeUnspsc190501.51284504': __('identifierSchemeCodeUnspsc190501.51284504'),
	'identifierSchemeCodeUnspsc190501.51284505': __('identifierSchemeCodeUnspsc190501.51284505'),
	'identifierSchemeCodeUnspsc190501.51284506': __('identifierSchemeCodeUnspsc190501.51284506'),
	'identifierSchemeCodeUnspsc190501.51284507': __('identifierSchemeCodeUnspsc190501.51284507'),
	'identifierSchemeCodeUnspsc190501.51284508': __('identifierSchemeCodeUnspsc190501.51284508'),
	'identifierSchemeCodeUnspsc190501.51284509': __('identifierSchemeCodeUnspsc190501.51284509'),
	'identifierSchemeCodeUnspsc190501.51284600': __('identifierSchemeCodeUnspsc190501.51284600'),
	'identifierSchemeCodeUnspsc190501.51284601': __('identifierSchemeCodeUnspsc190501.51284601'),
	'identifierSchemeCodeUnspsc190501.51284602': __('identifierSchemeCodeUnspsc190501.51284602'),
	'identifierSchemeCodeUnspsc190501.51284603': __('identifierSchemeCodeUnspsc190501.51284603'),
	'identifierSchemeCodeUnspsc190501.51284604': __('identifierSchemeCodeUnspsc190501.51284604'),
	'identifierSchemeCodeUnspsc190501.51284605': __('identifierSchemeCodeUnspsc190501.51284605'),
	'identifierSchemeCodeUnspsc190501.51284606': __('identifierSchemeCodeUnspsc190501.51284606'),
	'identifierSchemeCodeUnspsc190501.51284607': __('identifierSchemeCodeUnspsc190501.51284607'),
	'identifierSchemeCodeUnspsc190501.51284608': __('identifierSchemeCodeUnspsc190501.51284608'),
	'identifierSchemeCodeUnspsc190501.51284609': __('identifierSchemeCodeUnspsc190501.51284609'),
	'identifierSchemeCodeUnspsc190501.51284610': __('identifierSchemeCodeUnspsc190501.51284610'),
	'identifierSchemeCodeUnspsc190501.51284611': __('identifierSchemeCodeUnspsc190501.51284611'),
	'identifierSchemeCodeUnspsc190501.51284612': __('identifierSchemeCodeUnspsc190501.51284612'),
	'identifierSchemeCodeUnspsc190501.51284613': __('identifierSchemeCodeUnspsc190501.51284613'),
	'identifierSchemeCodeUnspsc190501.51284700': __('identifierSchemeCodeUnspsc190501.51284700'),
	'identifierSchemeCodeUnspsc190501.51284701': __('identifierSchemeCodeUnspsc190501.51284701'),
	'identifierSchemeCodeUnspsc190501.51284703': __('identifierSchemeCodeUnspsc190501.51284703'),
	'identifierSchemeCodeUnspsc190501.51284704': __('identifierSchemeCodeUnspsc190501.51284704'),
	'identifierSchemeCodeUnspsc190501.51284705': __('identifierSchemeCodeUnspsc190501.51284705'),
	'identifierSchemeCodeUnspsc190501.51284706': __('identifierSchemeCodeUnspsc190501.51284706'),
	'identifierSchemeCodeUnspsc190501.51284708': __('identifierSchemeCodeUnspsc190501.51284708'),
	'identifierSchemeCodeUnspsc190501.51284709': __('identifierSchemeCodeUnspsc190501.51284709'),
	'identifierSchemeCodeUnspsc190501.51284710': __('identifierSchemeCodeUnspsc190501.51284710'),
	'identifierSchemeCodeUnspsc190501.51284711': __('identifierSchemeCodeUnspsc190501.51284711'),
	'identifierSchemeCodeUnspsc190501.51284712': __('identifierSchemeCodeUnspsc190501.51284712'),
	'identifierSchemeCodeUnspsc190501.51284713': __('identifierSchemeCodeUnspsc190501.51284713'),
	'identifierSchemeCodeUnspsc190501.51284714': __('identifierSchemeCodeUnspsc190501.51284714'),
	'identifierSchemeCodeUnspsc190501.51284715': __('identifierSchemeCodeUnspsc190501.51284715'),
	'identifierSchemeCodeUnspsc190501.51284716': __('identifierSchemeCodeUnspsc190501.51284716'),
	'identifierSchemeCodeUnspsc190501.51284717': __('identifierSchemeCodeUnspsc190501.51284717'),
	'identifierSchemeCodeUnspsc190501.51284800': __('identifierSchemeCodeUnspsc190501.51284800'),
	'identifierSchemeCodeUnspsc190501.51284801': __('identifierSchemeCodeUnspsc190501.51284801'),
	'identifierSchemeCodeUnspsc190501.51284802': __('identifierSchemeCodeUnspsc190501.51284802'),
	'identifierSchemeCodeUnspsc190501.51284803': __('identifierSchemeCodeUnspsc190501.51284803'),
	'identifierSchemeCodeUnspsc190501.51284804': __('identifierSchemeCodeUnspsc190501.51284804'),
	'identifierSchemeCodeUnspsc190501.51284805': __('identifierSchemeCodeUnspsc190501.51284805'),
	'identifierSchemeCodeUnspsc190501.51284806': __('identifierSchemeCodeUnspsc190501.51284806'),
	'identifierSchemeCodeUnspsc190501.51284807': __('identifierSchemeCodeUnspsc190501.51284807'),
	'identifierSchemeCodeUnspsc190501.51284900': __('identifierSchemeCodeUnspsc190501.51284900'),
	'identifierSchemeCodeUnspsc190501.51284901': __('identifierSchemeCodeUnspsc190501.51284901'),
	'identifierSchemeCodeUnspsc190501.51284902': __('identifierSchemeCodeUnspsc190501.51284902'),
	'identifierSchemeCodeUnspsc190501.51284903': __('identifierSchemeCodeUnspsc190501.51284903'),
	'identifierSchemeCodeUnspsc190501.51284904': __('identifierSchemeCodeUnspsc190501.51284904'),
	'identifierSchemeCodeUnspsc190501.51284905': __('identifierSchemeCodeUnspsc190501.51284905'),
	'identifierSchemeCodeUnspsc190501.51284906': __('identifierSchemeCodeUnspsc190501.51284906'),
	'identifierSchemeCodeUnspsc190501.51284907': __('identifierSchemeCodeUnspsc190501.51284907'),
	'identifierSchemeCodeUnspsc190501.51284908': __('identifierSchemeCodeUnspsc190501.51284908'),
	'identifierSchemeCodeUnspsc190501.51284909': __('identifierSchemeCodeUnspsc190501.51284909'),
	'identifierSchemeCodeUnspsc190501.51284910': __('identifierSchemeCodeUnspsc190501.51284910'),
	'identifierSchemeCodeUnspsc190501.51284911': __('identifierSchemeCodeUnspsc190501.51284911'),
	'identifierSchemeCodeUnspsc190501.51285000': __('identifierSchemeCodeUnspsc190501.51285000'),
	'identifierSchemeCodeUnspsc190501.51285001': __('identifierSchemeCodeUnspsc190501.51285001'),
	'identifierSchemeCodeUnspsc190501.51285002': __('identifierSchemeCodeUnspsc190501.51285002'),
	'identifierSchemeCodeUnspsc190501.51285003': __('identifierSchemeCodeUnspsc190501.51285003'),
	'identifierSchemeCodeUnspsc190501.51285100': __('identifierSchemeCodeUnspsc190501.51285100'),
	'identifierSchemeCodeUnspsc190501.51285101': __('identifierSchemeCodeUnspsc190501.51285101'),
	'identifierSchemeCodeUnspsc190501.51285102': __('identifierSchemeCodeUnspsc190501.51285102'),
	'identifierSchemeCodeUnspsc190501.51285103': __('identifierSchemeCodeUnspsc190501.51285103'),
	'identifierSchemeCodeUnspsc190501.51285200': __('identifierSchemeCodeUnspsc190501.51285200'),
	'identifierSchemeCodeUnspsc190501.51285201': __('identifierSchemeCodeUnspsc190501.51285201'),
	'identifierSchemeCodeUnspsc190501.51285202': __('identifierSchemeCodeUnspsc190501.51285202'),
	'identifierSchemeCodeUnspsc190501.51285203': __('identifierSchemeCodeUnspsc190501.51285203'),
	'identifierSchemeCodeUnspsc190501.51285204': __('identifierSchemeCodeUnspsc190501.51285204'),
	'identifierSchemeCodeUnspsc190501.51285205': __('identifierSchemeCodeUnspsc190501.51285205'),
	'identifierSchemeCodeUnspsc190501.51285206': __('identifierSchemeCodeUnspsc190501.51285206'),
	'identifierSchemeCodeUnspsc190501.51285300': __('identifierSchemeCodeUnspsc190501.51285300'),
	'identifierSchemeCodeUnspsc190501.51285301': __('identifierSchemeCodeUnspsc190501.51285301'),
	'identifierSchemeCodeUnspsc190501.51285302': __('identifierSchemeCodeUnspsc190501.51285302'),
	'identifierSchemeCodeUnspsc190501.51285303': __('identifierSchemeCodeUnspsc190501.51285303'),
	'identifierSchemeCodeUnspsc190501.51285304': __('identifierSchemeCodeUnspsc190501.51285304'),
	'identifierSchemeCodeUnspsc190501.51285305': __('identifierSchemeCodeUnspsc190501.51285305'),
	'identifierSchemeCodeUnspsc190501.51285306': __('identifierSchemeCodeUnspsc190501.51285306'),
	'identifierSchemeCodeUnspsc190501.51285307': __('identifierSchemeCodeUnspsc190501.51285307'),
	'identifierSchemeCodeUnspsc190501.51285308': __('identifierSchemeCodeUnspsc190501.51285308'),
	'identifierSchemeCodeUnspsc190501.51285309': __('identifierSchemeCodeUnspsc190501.51285309'),
	'identifierSchemeCodeUnspsc190501.51285310': __('identifierSchemeCodeUnspsc190501.51285310'),
	'identifierSchemeCodeUnspsc190501.51285311': __('identifierSchemeCodeUnspsc190501.51285311'),
	'identifierSchemeCodeUnspsc190501.51285312': __('identifierSchemeCodeUnspsc190501.51285312'),
	'identifierSchemeCodeUnspsc190501.51285313': __('identifierSchemeCodeUnspsc190501.51285313'),
	'identifierSchemeCodeUnspsc190501.51285314': __('identifierSchemeCodeUnspsc190501.51285314'),
	'identifierSchemeCodeUnspsc190501.51285315': __('identifierSchemeCodeUnspsc190501.51285315'),
	'identifierSchemeCodeUnspsc190501.51285316': __('identifierSchemeCodeUnspsc190501.51285316'),
	'identifierSchemeCodeUnspsc190501.51285317': __('identifierSchemeCodeUnspsc190501.51285317'),
	'identifierSchemeCodeUnspsc190501.51285318': __('identifierSchemeCodeUnspsc190501.51285318'),
	'identifierSchemeCodeUnspsc190501.51285319': __('identifierSchemeCodeUnspsc190501.51285319'),
	'identifierSchemeCodeUnspsc190501.51285400': __('identifierSchemeCodeUnspsc190501.51285400'),
	'identifierSchemeCodeUnspsc190501.51285401': __('identifierSchemeCodeUnspsc190501.51285401'),
	'identifierSchemeCodeUnspsc190501.51285402': __('identifierSchemeCodeUnspsc190501.51285402'),
	'identifierSchemeCodeUnspsc190501.51285403': __('identifierSchemeCodeUnspsc190501.51285403'),
	'identifierSchemeCodeUnspsc190501.51285404': __('identifierSchemeCodeUnspsc190501.51285404'),
	'identifierSchemeCodeUnspsc190501.51285405': __('identifierSchemeCodeUnspsc190501.51285405'),
	'identifierSchemeCodeUnspsc190501.51285406': __('identifierSchemeCodeUnspsc190501.51285406'),
	'identifierSchemeCodeUnspsc190501.51285407': __('identifierSchemeCodeUnspsc190501.51285407'),
	'identifierSchemeCodeUnspsc190501.51285408': __('identifierSchemeCodeUnspsc190501.51285408'),
	'identifierSchemeCodeUnspsc190501.51285409': __('identifierSchemeCodeUnspsc190501.51285409'),
	'identifierSchemeCodeUnspsc190501.51285500': __('identifierSchemeCodeUnspsc190501.51285500'),
	'identifierSchemeCodeUnspsc190501.51285501': __('identifierSchemeCodeUnspsc190501.51285501'),
	'identifierSchemeCodeUnspsc190501.51285502': __('identifierSchemeCodeUnspsc190501.51285502'),
	'identifierSchemeCodeUnspsc190501.51285503': __('identifierSchemeCodeUnspsc190501.51285503'),
	'identifierSchemeCodeUnspsc190501.51285504': __('identifierSchemeCodeUnspsc190501.51285504'),
	'identifierSchemeCodeUnspsc190501.51285505': __('identifierSchemeCodeUnspsc190501.51285505'),
	'identifierSchemeCodeUnspsc190501.51285506': __('identifierSchemeCodeUnspsc190501.51285506'),
	'identifierSchemeCodeUnspsc190501.51285507': __('identifierSchemeCodeUnspsc190501.51285507'),
	'identifierSchemeCodeUnspsc190501.51285600': __('identifierSchemeCodeUnspsc190501.51285600'),
	'identifierSchemeCodeUnspsc190501.51285601': __('identifierSchemeCodeUnspsc190501.51285601'),
	'identifierSchemeCodeUnspsc190501.51285602': __('identifierSchemeCodeUnspsc190501.51285602'),
	'identifierSchemeCodeUnspsc190501.51285603': __('identifierSchemeCodeUnspsc190501.51285603'),
	'identifierSchemeCodeUnspsc190501.51285604': __('identifierSchemeCodeUnspsc190501.51285604'),
	'identifierSchemeCodeUnspsc190501.51285605': __('identifierSchemeCodeUnspsc190501.51285605'),
	'identifierSchemeCodeUnspsc190501.51285606': __('identifierSchemeCodeUnspsc190501.51285606'),
	'identifierSchemeCodeUnspsc190501.51285607': __('identifierSchemeCodeUnspsc190501.51285607'),
	'identifierSchemeCodeUnspsc190501.51285700': __('identifierSchemeCodeUnspsc190501.51285700'),
	'identifierSchemeCodeUnspsc190501.51285701': __('identifierSchemeCodeUnspsc190501.51285701'),
	'identifierSchemeCodeUnspsc190501.51285702': __('identifierSchemeCodeUnspsc190501.51285702'),
	'identifierSchemeCodeUnspsc190501.51285800': __('identifierSchemeCodeUnspsc190501.51285800'),
	'identifierSchemeCodeUnspsc190501.51285801': __('identifierSchemeCodeUnspsc190501.51285801'),
	'identifierSchemeCodeUnspsc190501.51285802': __('identifierSchemeCodeUnspsc190501.51285802'),
	'identifierSchemeCodeUnspsc190501.51285803': __('identifierSchemeCodeUnspsc190501.51285803'),
	'identifierSchemeCodeUnspsc190501.51285804': __('identifierSchemeCodeUnspsc190501.51285804'),
	'identifierSchemeCodeUnspsc190501.51285805': __('identifierSchemeCodeUnspsc190501.51285805'),
	'identifierSchemeCodeUnspsc190501.51285806': __('identifierSchemeCodeUnspsc190501.51285806'),
	'identifierSchemeCodeUnspsc190501.51285900': __('identifierSchemeCodeUnspsc190501.51285900'),
	'identifierSchemeCodeUnspsc190501.51285901': __('identifierSchemeCodeUnspsc190501.51285901'),
	'identifierSchemeCodeUnspsc190501.51285902': __('identifierSchemeCodeUnspsc190501.51285902'),
	'identifierSchemeCodeUnspsc190501.51285903': __('identifierSchemeCodeUnspsc190501.51285903'),
	'identifierSchemeCodeUnspsc190501.51285904': __('identifierSchemeCodeUnspsc190501.51285904'),
	'identifierSchemeCodeUnspsc190501.51285905': __('identifierSchemeCodeUnspsc190501.51285905'),
	'identifierSchemeCodeUnspsc190501.51285906': __('identifierSchemeCodeUnspsc190501.51285906'),
	'identifierSchemeCodeUnspsc190501.51285907': __('identifierSchemeCodeUnspsc190501.51285907'),
	'identifierSchemeCodeUnspsc190501.51285908': __('identifierSchemeCodeUnspsc190501.51285908'),
	'identifierSchemeCodeUnspsc190501.51286000': __('identifierSchemeCodeUnspsc190501.51286000'),
	'identifierSchemeCodeUnspsc190501.51286001': __('identifierSchemeCodeUnspsc190501.51286001'),
	'identifierSchemeCodeUnspsc190501.51286100': __('identifierSchemeCodeUnspsc190501.51286100'),
	'identifierSchemeCodeUnspsc190501.51286101': __('identifierSchemeCodeUnspsc190501.51286101'),
	'identifierSchemeCodeUnspsc190501.51286102': __('identifierSchemeCodeUnspsc190501.51286102'),
	'identifierSchemeCodeUnspsc190501.51286200': __('identifierSchemeCodeUnspsc190501.51286200'),
	'identifierSchemeCodeUnspsc190501.51286201': __('identifierSchemeCodeUnspsc190501.51286201'),
	'identifierSchemeCodeUnspsc190501.51286202': __('identifierSchemeCodeUnspsc190501.51286202'),
	'identifierSchemeCodeUnspsc190501.51286203': __('identifierSchemeCodeUnspsc190501.51286203'),
	'identifierSchemeCodeUnspsc190501.51286204': __('identifierSchemeCodeUnspsc190501.51286204'),
	'identifierSchemeCodeUnspsc190501.51286205': __('identifierSchemeCodeUnspsc190501.51286205'),
	'identifierSchemeCodeUnspsc190501.51286206': __('identifierSchemeCodeUnspsc190501.51286206'),
	'identifierSchemeCodeUnspsc190501.51286207': __('identifierSchemeCodeUnspsc190501.51286207'),
	'identifierSchemeCodeUnspsc190501.51286208': __('identifierSchemeCodeUnspsc190501.51286208'),
	'identifierSchemeCodeUnspsc190501.51286300': __('identifierSchemeCodeUnspsc190501.51286300'),
	'identifierSchemeCodeUnspsc190501.51286301': __('identifierSchemeCodeUnspsc190501.51286301'),
	'identifierSchemeCodeUnspsc190501.51286302': __('identifierSchemeCodeUnspsc190501.51286302'),
	'identifierSchemeCodeUnspsc190501.51286400': __('identifierSchemeCodeUnspsc190501.51286400'),
	'identifierSchemeCodeUnspsc190501.51286401': __('identifierSchemeCodeUnspsc190501.51286401'),
	'identifierSchemeCodeUnspsc190501.51286402': __('identifierSchemeCodeUnspsc190501.51286402'),
	'identifierSchemeCodeUnspsc190501.51286500': __('identifierSchemeCodeUnspsc190501.51286500'),
	'identifierSchemeCodeUnspsc190501.51286501': __('identifierSchemeCodeUnspsc190501.51286501'),
	'identifierSchemeCodeUnspsc190501.51286502': __('identifierSchemeCodeUnspsc190501.51286502'),
	'identifierSchemeCodeUnspsc190501.51286503': __('identifierSchemeCodeUnspsc190501.51286503'),
	'identifierSchemeCodeUnspsc190501.51286504': __('identifierSchemeCodeUnspsc190501.51286504'),
	'identifierSchemeCodeUnspsc190501.51286505': __('identifierSchemeCodeUnspsc190501.51286505'),
	'identifierSchemeCodeUnspsc190501.51286600': __('identifierSchemeCodeUnspsc190501.51286600'),
	'identifierSchemeCodeUnspsc190501.51286601': __('identifierSchemeCodeUnspsc190501.51286601'),
	'identifierSchemeCodeUnspsc190501.51286602': __('identifierSchemeCodeUnspsc190501.51286602'),
	'identifierSchemeCodeUnspsc190501.51286700': __('identifierSchemeCodeUnspsc190501.51286700'),
	'identifierSchemeCodeUnspsc190501.51286701': __('identifierSchemeCodeUnspsc190501.51286701'),
	'identifierSchemeCodeUnspsc190501.51286702': __('identifierSchemeCodeUnspsc190501.51286702'),
	'identifierSchemeCodeUnspsc190501.51286703': __('identifierSchemeCodeUnspsc190501.51286703'),
	'identifierSchemeCodeUnspsc190501.51286704': __('identifierSchemeCodeUnspsc190501.51286704'),
	'identifierSchemeCodeUnspsc190501.51286705': __('identifierSchemeCodeUnspsc190501.51286705'),
	'identifierSchemeCodeUnspsc190501.51286706': __('identifierSchemeCodeUnspsc190501.51286706'),
	'identifierSchemeCodeUnspsc190501.51286707': __('identifierSchemeCodeUnspsc190501.51286707'),
	'identifierSchemeCodeUnspsc190501.51286708': __('identifierSchemeCodeUnspsc190501.51286708'),
	'identifierSchemeCodeUnspsc190501.51286800': __('identifierSchemeCodeUnspsc190501.51286800'),
	'identifierSchemeCodeUnspsc190501.51286801': __('identifierSchemeCodeUnspsc190501.51286801'),
	'identifierSchemeCodeUnspsc190501.51286802': __('identifierSchemeCodeUnspsc190501.51286802'),
	'identifierSchemeCodeUnspsc190501.51286803': __('identifierSchemeCodeUnspsc190501.51286803'),
	'identifierSchemeCodeUnspsc190501.51286804': __('identifierSchemeCodeUnspsc190501.51286804'),
	'identifierSchemeCodeUnspsc190501.51286900': __('identifierSchemeCodeUnspsc190501.51286900'),
	'identifierSchemeCodeUnspsc190501.51286901': __('identifierSchemeCodeUnspsc190501.51286901'),
	'identifierSchemeCodeUnspsc190501.51286902': __('identifierSchemeCodeUnspsc190501.51286902'),
	'identifierSchemeCodeUnspsc190501.51286903': __('identifierSchemeCodeUnspsc190501.51286903'),
	'identifierSchemeCodeUnspsc190501.51286904': __('identifierSchemeCodeUnspsc190501.51286904'),
	'identifierSchemeCodeUnspsc190501.51286905': __('identifierSchemeCodeUnspsc190501.51286905'),
	'identifierSchemeCodeUnspsc190501.51286906': __('identifierSchemeCodeUnspsc190501.51286906'),
	'identifierSchemeCodeUnspsc190501.51287000': __('identifierSchemeCodeUnspsc190501.51287000'),
	'identifierSchemeCodeUnspsc190501.51287001': __('identifierSchemeCodeUnspsc190501.51287001'),
	'identifierSchemeCodeUnspsc190501.51287002': __('identifierSchemeCodeUnspsc190501.51287002'),
	'identifierSchemeCodeUnspsc190501.51287003': __('identifierSchemeCodeUnspsc190501.51287003'),
	'identifierSchemeCodeUnspsc190501.51287004': __('identifierSchemeCodeUnspsc190501.51287004'),
	'identifierSchemeCodeUnspsc190501.51287005': __('identifierSchemeCodeUnspsc190501.51287005'),
	'identifierSchemeCodeUnspsc190501.51287006': __('identifierSchemeCodeUnspsc190501.51287006'),
	'identifierSchemeCodeUnspsc190501.51287007': __('identifierSchemeCodeUnspsc190501.51287007'),
	'identifierSchemeCodeUnspsc190501.51287008': __('identifierSchemeCodeUnspsc190501.51287008'),
	'identifierSchemeCodeUnspsc190501.51287009': __('identifierSchemeCodeUnspsc190501.51287009'),
	'identifierSchemeCodeUnspsc190501.51287010': __('identifierSchemeCodeUnspsc190501.51287010'),
	'identifierSchemeCodeUnspsc190501.51287011': __('identifierSchemeCodeUnspsc190501.51287011'),
	'identifierSchemeCodeUnspsc190501.51290000': __('identifierSchemeCodeUnspsc190501.51290000'),
	'identifierSchemeCodeUnspsc190501.51291500': __('identifierSchemeCodeUnspsc190501.51291500'),
	'identifierSchemeCodeUnspsc190501.51291501': __('identifierSchemeCodeUnspsc190501.51291501'),
	'identifierSchemeCodeUnspsc190501.51291502': __('identifierSchemeCodeUnspsc190501.51291502'),
	'identifierSchemeCodeUnspsc190501.51291503': __('identifierSchemeCodeUnspsc190501.51291503'),
	'identifierSchemeCodeUnspsc190501.51291504': __('identifierSchemeCodeUnspsc190501.51291504'),
	'identifierSchemeCodeUnspsc190501.51291505': __('identifierSchemeCodeUnspsc190501.51291505'),
	'identifierSchemeCodeUnspsc190501.51291506': __('identifierSchemeCodeUnspsc190501.51291506'),
	'identifierSchemeCodeUnspsc190501.51291507': __('identifierSchemeCodeUnspsc190501.51291507'),
	'identifierSchemeCodeUnspsc190501.51291508': __('identifierSchemeCodeUnspsc190501.51291508'),
	'identifierSchemeCodeUnspsc190501.51291509': __('identifierSchemeCodeUnspsc190501.51291509'),
	'identifierSchemeCodeUnspsc190501.51291510': __('identifierSchemeCodeUnspsc190501.51291510'),
	'identifierSchemeCodeUnspsc190501.51291511': __('identifierSchemeCodeUnspsc190501.51291511'),
	'identifierSchemeCodeUnspsc190501.51291512': __('identifierSchemeCodeUnspsc190501.51291512'),
	'identifierSchemeCodeUnspsc190501.51291513': __('identifierSchemeCodeUnspsc190501.51291513'),
	'identifierSchemeCodeUnspsc190501.51291514': __('identifierSchemeCodeUnspsc190501.51291514'),
	'identifierSchemeCodeUnspsc190501.51291515': __('identifierSchemeCodeUnspsc190501.51291515'),
	'identifierSchemeCodeUnspsc190501.51291516': __('identifierSchemeCodeUnspsc190501.51291516'),
	'identifierSchemeCodeUnspsc190501.51291600': __('identifierSchemeCodeUnspsc190501.51291600'),
	'identifierSchemeCodeUnspsc190501.51291601': __('identifierSchemeCodeUnspsc190501.51291601'),
	'identifierSchemeCodeUnspsc190501.51291602': __('identifierSchemeCodeUnspsc190501.51291602'),
	'identifierSchemeCodeUnspsc190501.51291603': __('identifierSchemeCodeUnspsc190501.51291603'),
	'identifierSchemeCodeUnspsc190501.51291700': __('identifierSchemeCodeUnspsc190501.51291700'),
	'identifierSchemeCodeUnspsc190501.51291701': __('identifierSchemeCodeUnspsc190501.51291701'),
	'identifierSchemeCodeUnspsc190501.51291702': __('identifierSchemeCodeUnspsc190501.51291702'),
	'identifierSchemeCodeUnspsc190501.51291703': __('identifierSchemeCodeUnspsc190501.51291703'),
	'identifierSchemeCodeUnspsc190501.51291704': __('identifierSchemeCodeUnspsc190501.51291704'),
	'identifierSchemeCodeUnspsc190501.51291705': __('identifierSchemeCodeUnspsc190501.51291705'),
	'identifierSchemeCodeUnspsc190501.51291706': __('identifierSchemeCodeUnspsc190501.51291706'),
	'identifierSchemeCodeUnspsc190501.51291707': __('identifierSchemeCodeUnspsc190501.51291707'),
	'identifierSchemeCodeUnspsc190501.51291708': __('identifierSchemeCodeUnspsc190501.51291708'),
	'identifierSchemeCodeUnspsc190501.51291709': __('identifierSchemeCodeUnspsc190501.51291709'),
	'identifierSchemeCodeUnspsc190501.51291710': __('identifierSchemeCodeUnspsc190501.51291710'),
	'identifierSchemeCodeUnspsc190501.51291711': __('identifierSchemeCodeUnspsc190501.51291711'),
	'identifierSchemeCodeUnspsc190501.51291712': __('identifierSchemeCodeUnspsc190501.51291712'),
	'identifierSchemeCodeUnspsc190501.51291800': __('identifierSchemeCodeUnspsc190501.51291800'),
	'identifierSchemeCodeUnspsc190501.51291801': __('identifierSchemeCodeUnspsc190501.51291801'),
	'identifierSchemeCodeUnspsc190501.51291802': __('identifierSchemeCodeUnspsc190501.51291802'),
	'identifierSchemeCodeUnspsc190501.51291803': __('identifierSchemeCodeUnspsc190501.51291803'),
	'identifierSchemeCodeUnspsc190501.51291804': __('identifierSchemeCodeUnspsc190501.51291804'),
	'identifierSchemeCodeUnspsc190501.51291805': __('identifierSchemeCodeUnspsc190501.51291805'),
	'identifierSchemeCodeUnspsc190501.51291806': __('identifierSchemeCodeUnspsc190501.51291806'),
	'identifierSchemeCodeUnspsc190501.51291900': __('identifierSchemeCodeUnspsc190501.51291900'),
	'identifierSchemeCodeUnspsc190501.51291901': __('identifierSchemeCodeUnspsc190501.51291901'),
	'identifierSchemeCodeUnspsc190501.51291902': __('identifierSchemeCodeUnspsc190501.51291902'),
	'identifierSchemeCodeUnspsc190501.51291903': __('identifierSchemeCodeUnspsc190501.51291903'),
	'identifierSchemeCodeUnspsc190501.51291904': __('identifierSchemeCodeUnspsc190501.51291904'),
	'identifierSchemeCodeUnspsc190501.51291905': __('identifierSchemeCodeUnspsc190501.51291905'),
	'identifierSchemeCodeUnspsc190501.51291906': __('identifierSchemeCodeUnspsc190501.51291906'),
	'identifierSchemeCodeUnspsc190501.51291907': __('identifierSchemeCodeUnspsc190501.51291907'),
	'identifierSchemeCodeUnspsc190501.51292000': __('identifierSchemeCodeUnspsc190501.51292000'),
	'identifierSchemeCodeUnspsc190501.51292001': __('identifierSchemeCodeUnspsc190501.51292001'),
	'identifierSchemeCodeUnspsc190501.51292002': __('identifierSchemeCodeUnspsc190501.51292002'),
	'identifierSchemeCodeUnspsc190501.51292003': __('identifierSchemeCodeUnspsc190501.51292003'),
	'identifierSchemeCodeUnspsc190501.51292004': __('identifierSchemeCodeUnspsc190501.51292004'),
	'identifierSchemeCodeUnspsc190501.51292005': __('identifierSchemeCodeUnspsc190501.51292005'),
	'identifierSchemeCodeUnspsc190501.51292006': __('identifierSchemeCodeUnspsc190501.51292006'),
	'identifierSchemeCodeUnspsc190501.51292007': __('identifierSchemeCodeUnspsc190501.51292007'),
	'identifierSchemeCodeUnspsc190501.51292008': __('identifierSchemeCodeUnspsc190501.51292008'),
	'identifierSchemeCodeUnspsc190501.51292009': __('identifierSchemeCodeUnspsc190501.51292009'),
	'identifierSchemeCodeUnspsc190501.51292010': __('identifierSchemeCodeUnspsc190501.51292010'),
	'identifierSchemeCodeUnspsc190501.51292011': __('identifierSchemeCodeUnspsc190501.51292011'),
	'identifierSchemeCodeUnspsc190501.51292012': __('identifierSchemeCodeUnspsc190501.51292012'),
	'identifierSchemeCodeUnspsc190501.51292013': __('identifierSchemeCodeUnspsc190501.51292013'),
	'identifierSchemeCodeUnspsc190501.51292014': __('identifierSchemeCodeUnspsc190501.51292014'),
	'identifierSchemeCodeUnspsc190501.51292015': __('identifierSchemeCodeUnspsc190501.51292015'),
	'identifierSchemeCodeUnspsc190501.51292016': __('identifierSchemeCodeUnspsc190501.51292016'),
	'identifierSchemeCodeUnspsc190501.51292017': __('identifierSchemeCodeUnspsc190501.51292017'),
	'identifierSchemeCodeUnspsc190501.51292018': __('identifierSchemeCodeUnspsc190501.51292018'),
	'identifierSchemeCodeUnspsc190501.51292019': __('identifierSchemeCodeUnspsc190501.51292019'),
	'identifierSchemeCodeUnspsc190501.51292020': __('identifierSchemeCodeUnspsc190501.51292020'),
	'identifierSchemeCodeUnspsc190501.51292021': __('identifierSchemeCodeUnspsc190501.51292021'),
	'identifierSchemeCodeUnspsc190501.51292022': __('identifierSchemeCodeUnspsc190501.51292022'),
	'identifierSchemeCodeUnspsc190501.51292023': __('identifierSchemeCodeUnspsc190501.51292023'),
	'identifierSchemeCodeUnspsc190501.51292024': __('identifierSchemeCodeUnspsc190501.51292024'),
	'identifierSchemeCodeUnspsc190501.51292025': __('identifierSchemeCodeUnspsc190501.51292025'),
	'identifierSchemeCodeUnspsc190501.51292026': __('identifierSchemeCodeUnspsc190501.51292026'),
	'identifierSchemeCodeUnspsc190501.51292027': __('identifierSchemeCodeUnspsc190501.51292027'),
	'identifierSchemeCodeUnspsc190501.51292028': __('identifierSchemeCodeUnspsc190501.51292028'),
	'identifierSchemeCodeUnspsc190501.51292029': __('identifierSchemeCodeUnspsc190501.51292029'),
	'identifierSchemeCodeUnspsc190501.51292030': __('identifierSchemeCodeUnspsc190501.51292030'),
	'identifierSchemeCodeUnspsc190501.51292100': __('identifierSchemeCodeUnspsc190501.51292100'),
	'identifierSchemeCodeUnspsc190501.51292101': __('identifierSchemeCodeUnspsc190501.51292101'),
	'identifierSchemeCodeUnspsc190501.51292102': __('identifierSchemeCodeUnspsc190501.51292102'),
	'identifierSchemeCodeUnspsc190501.51292103': __('identifierSchemeCodeUnspsc190501.51292103'),
	'identifierSchemeCodeUnspsc190501.51292104': __('identifierSchemeCodeUnspsc190501.51292104'),
	'identifierSchemeCodeUnspsc190501.51292105': __('identifierSchemeCodeUnspsc190501.51292105'),
	'identifierSchemeCodeUnspsc190501.51292106': __('identifierSchemeCodeUnspsc190501.51292106'),
	'identifierSchemeCodeUnspsc190501.51292107': __('identifierSchemeCodeUnspsc190501.51292107'),
	'identifierSchemeCodeUnspsc190501.51292200': __('identifierSchemeCodeUnspsc190501.51292200'),
	'identifierSchemeCodeUnspsc190501.51292201': __('identifierSchemeCodeUnspsc190501.51292201'),
	'identifierSchemeCodeUnspsc190501.51292202': __('identifierSchemeCodeUnspsc190501.51292202'),
	'identifierSchemeCodeUnspsc190501.51292203': __('identifierSchemeCodeUnspsc190501.51292203'),
	'identifierSchemeCodeUnspsc190501.51292204': __('identifierSchemeCodeUnspsc190501.51292204'),
	'identifierSchemeCodeUnspsc190501.51292205': __('identifierSchemeCodeUnspsc190501.51292205'),
	'identifierSchemeCodeUnspsc190501.51292206': __('identifierSchemeCodeUnspsc190501.51292206'),
	'identifierSchemeCodeUnspsc190501.51292207': __('identifierSchemeCodeUnspsc190501.51292207'),
	'identifierSchemeCodeUnspsc190501.51292208': __('identifierSchemeCodeUnspsc190501.51292208'),
	'identifierSchemeCodeUnspsc190501.51292300': __('identifierSchemeCodeUnspsc190501.51292300'),
	'identifierSchemeCodeUnspsc190501.51292301': __('identifierSchemeCodeUnspsc190501.51292301'),
	'identifierSchemeCodeUnspsc190501.51292302': __('identifierSchemeCodeUnspsc190501.51292302'),
	'identifierSchemeCodeUnspsc190501.51292303': __('identifierSchemeCodeUnspsc190501.51292303'),
	'identifierSchemeCodeUnspsc190501.51292304': __('identifierSchemeCodeUnspsc190501.51292304'),
	'identifierSchemeCodeUnspsc190501.51292305': __('identifierSchemeCodeUnspsc190501.51292305'),
	'identifierSchemeCodeUnspsc190501.51292306': __('identifierSchemeCodeUnspsc190501.51292306'),
	'identifierSchemeCodeUnspsc190501.51292400': __('identifierSchemeCodeUnspsc190501.51292400'),
	'identifierSchemeCodeUnspsc190501.51292401': __('identifierSchemeCodeUnspsc190501.51292401'),
	'identifierSchemeCodeUnspsc190501.51292402': __('identifierSchemeCodeUnspsc190501.51292402'),
	'identifierSchemeCodeUnspsc190501.51292403': __('identifierSchemeCodeUnspsc190501.51292403'),
	'identifierSchemeCodeUnspsc190501.51292500': __('identifierSchemeCodeUnspsc190501.51292500'),
	'identifierSchemeCodeUnspsc190501.51292501': __('identifierSchemeCodeUnspsc190501.51292501'),
	'identifierSchemeCodeUnspsc190501.51292502': __('identifierSchemeCodeUnspsc190501.51292502'),
	'identifierSchemeCodeUnspsc190501.51292600': __('identifierSchemeCodeUnspsc190501.51292600'),
	'identifierSchemeCodeUnspsc190501.51292601': __('identifierSchemeCodeUnspsc190501.51292601'),
	'identifierSchemeCodeUnspsc190501.51292602': __('identifierSchemeCodeUnspsc190501.51292602'),
	'identifierSchemeCodeUnspsc190501.51292603': __('identifierSchemeCodeUnspsc190501.51292603'),
	'identifierSchemeCodeUnspsc190501.51292604': __('identifierSchemeCodeUnspsc190501.51292604'),
	'identifierSchemeCodeUnspsc190501.51292605': __('identifierSchemeCodeUnspsc190501.51292605'),
	'identifierSchemeCodeUnspsc190501.51292606': __('identifierSchemeCodeUnspsc190501.51292606'),
	'identifierSchemeCodeUnspsc190501.51292607': __('identifierSchemeCodeUnspsc190501.51292607'),
	'identifierSchemeCodeUnspsc190501.51292700': __('identifierSchemeCodeUnspsc190501.51292700'),
	'identifierSchemeCodeUnspsc190501.51292701': __('identifierSchemeCodeUnspsc190501.51292701'),
	'identifierSchemeCodeUnspsc190501.51292702': __('identifierSchemeCodeUnspsc190501.51292702'),
	'identifierSchemeCodeUnspsc190501.51292703': __('identifierSchemeCodeUnspsc190501.51292703'),
	'identifierSchemeCodeUnspsc190501.51292704': __('identifierSchemeCodeUnspsc190501.51292704'),
	'identifierSchemeCodeUnspsc190501.51292705': __('identifierSchemeCodeUnspsc190501.51292705'),
	'identifierSchemeCodeUnspsc190501.51292706': __('identifierSchemeCodeUnspsc190501.51292706'),
	'identifierSchemeCodeUnspsc190501.51292707': __('identifierSchemeCodeUnspsc190501.51292707'),
	'identifierSchemeCodeUnspsc190501.51292800': __('identifierSchemeCodeUnspsc190501.51292800'),
	'identifierSchemeCodeUnspsc190501.51292801': __('identifierSchemeCodeUnspsc190501.51292801'),
	'identifierSchemeCodeUnspsc190501.51292802': __('identifierSchemeCodeUnspsc190501.51292802'),
	'identifierSchemeCodeUnspsc190501.51292803': __('identifierSchemeCodeUnspsc190501.51292803'),
	'identifierSchemeCodeUnspsc190501.51292804': __('identifierSchemeCodeUnspsc190501.51292804'),
	'identifierSchemeCodeUnspsc190501.51292805': __('identifierSchemeCodeUnspsc190501.51292805'),
	'identifierSchemeCodeUnspsc190501.51292806': __('identifierSchemeCodeUnspsc190501.51292806'),
	'identifierSchemeCodeUnspsc190501.51292900': __('identifierSchemeCodeUnspsc190501.51292900'),
	'identifierSchemeCodeUnspsc190501.51292901': __('identifierSchemeCodeUnspsc190501.51292901'),
	'identifierSchemeCodeUnspsc190501.51292902': __('identifierSchemeCodeUnspsc190501.51292902'),
	'identifierSchemeCodeUnspsc190501.51292903': __('identifierSchemeCodeUnspsc190501.51292903'),
	'identifierSchemeCodeUnspsc190501.51292904': __('identifierSchemeCodeUnspsc190501.51292904'),
	'identifierSchemeCodeUnspsc190501.51292905': __('identifierSchemeCodeUnspsc190501.51292905'),
	'identifierSchemeCodeUnspsc190501.51292906': __('identifierSchemeCodeUnspsc190501.51292906'),
	'identifierSchemeCodeUnspsc190501.51292907': __('identifierSchemeCodeUnspsc190501.51292907'),
	'identifierSchemeCodeUnspsc190501.51292908': __('identifierSchemeCodeUnspsc190501.51292908'),
	'identifierSchemeCodeUnspsc190501.51292909': __('identifierSchemeCodeUnspsc190501.51292909'),
	'identifierSchemeCodeUnspsc190501.51292910': __('identifierSchemeCodeUnspsc190501.51292910'),
	'identifierSchemeCodeUnspsc190501.51292911': __('identifierSchemeCodeUnspsc190501.51292911'),
	'identifierSchemeCodeUnspsc190501.51292912': __('identifierSchemeCodeUnspsc190501.51292912'),
	'identifierSchemeCodeUnspsc190501.51292913': __('identifierSchemeCodeUnspsc190501.51292913'),
	'identifierSchemeCodeUnspsc190501.51292914': __('identifierSchemeCodeUnspsc190501.51292914'),
	'identifierSchemeCodeUnspsc190501.51292915': __('identifierSchemeCodeUnspsc190501.51292915'),
	'identifierSchemeCodeUnspsc190501.51292916': __('identifierSchemeCodeUnspsc190501.51292916'),
	'identifierSchemeCodeUnspsc190501.51292917': __('identifierSchemeCodeUnspsc190501.51292917'),
	'identifierSchemeCodeUnspsc190501.51292918': __('identifierSchemeCodeUnspsc190501.51292918'),
	'identifierSchemeCodeUnspsc190501.51292919': __('identifierSchemeCodeUnspsc190501.51292919'),
	'identifierSchemeCodeUnspsc190501.51292920': __('identifierSchemeCodeUnspsc190501.51292920'),
	'identifierSchemeCodeUnspsc190501.51292921': __('identifierSchemeCodeUnspsc190501.51292921'),
	'identifierSchemeCodeUnspsc190501.51292922': __('identifierSchemeCodeUnspsc190501.51292922'),
	'identifierSchemeCodeUnspsc190501.51292923': __('identifierSchemeCodeUnspsc190501.51292923'),
	'identifierSchemeCodeUnspsc190501.51292924': __('identifierSchemeCodeUnspsc190501.51292924'),
	'identifierSchemeCodeUnspsc190501.51292925': __('identifierSchemeCodeUnspsc190501.51292925'),
	'identifierSchemeCodeUnspsc190501.51292926': __('identifierSchemeCodeUnspsc190501.51292926'),
	'identifierSchemeCodeUnspsc190501.51292927': __('identifierSchemeCodeUnspsc190501.51292927'),
	'identifierSchemeCodeUnspsc190501.51292928': __('identifierSchemeCodeUnspsc190501.51292928'),
	'identifierSchemeCodeUnspsc190501.51292929': __('identifierSchemeCodeUnspsc190501.51292929'),
	'identifierSchemeCodeUnspsc190501.51293000': __('identifierSchemeCodeUnspsc190501.51293000'),
	'identifierSchemeCodeUnspsc190501.51293001': __('identifierSchemeCodeUnspsc190501.51293001'),
	'identifierSchemeCodeUnspsc190501.51293002': __('identifierSchemeCodeUnspsc190501.51293002'),
	'identifierSchemeCodeUnspsc190501.51293003': __('identifierSchemeCodeUnspsc190501.51293003'),
	'identifierSchemeCodeUnspsc190501.51293004': __('identifierSchemeCodeUnspsc190501.51293004'),
	'identifierSchemeCodeUnspsc190501.51293005': __('identifierSchemeCodeUnspsc190501.51293005'),
	'identifierSchemeCodeUnspsc190501.51293006': __('identifierSchemeCodeUnspsc190501.51293006'),
	'identifierSchemeCodeUnspsc190501.51293007': __('identifierSchemeCodeUnspsc190501.51293007'),
	'identifierSchemeCodeUnspsc190501.51293008': __('identifierSchemeCodeUnspsc190501.51293008'),
	'identifierSchemeCodeUnspsc190501.51293009': __('identifierSchemeCodeUnspsc190501.51293009'),
	'identifierSchemeCodeUnspsc190501.51293010': __('identifierSchemeCodeUnspsc190501.51293010'),
	'identifierSchemeCodeUnspsc190501.51293011': __('identifierSchemeCodeUnspsc190501.51293011'),
	'identifierSchemeCodeUnspsc190501.51293012': __('identifierSchemeCodeUnspsc190501.51293012'),
	'identifierSchemeCodeUnspsc190501.51293013': __('identifierSchemeCodeUnspsc190501.51293013'),
	'identifierSchemeCodeUnspsc190501.51293014': __('identifierSchemeCodeUnspsc190501.51293014'),
	'identifierSchemeCodeUnspsc190501.51293015': __('identifierSchemeCodeUnspsc190501.51293015'),
	'identifierSchemeCodeUnspsc190501.51293016': __('identifierSchemeCodeUnspsc190501.51293016'),
	'identifierSchemeCodeUnspsc190501.51293017': __('identifierSchemeCodeUnspsc190501.51293017'),
	'identifierSchemeCodeUnspsc190501.51293100': __('identifierSchemeCodeUnspsc190501.51293100'),
	'identifierSchemeCodeUnspsc190501.51293101': __('identifierSchemeCodeUnspsc190501.51293101'),
	'identifierSchemeCodeUnspsc190501.51293102': __('identifierSchemeCodeUnspsc190501.51293102'),
	'identifierSchemeCodeUnspsc190501.51293200': __('identifierSchemeCodeUnspsc190501.51293200'),
	'identifierSchemeCodeUnspsc190501.51293201': __('identifierSchemeCodeUnspsc190501.51293201'),
	'identifierSchemeCodeUnspsc190501.51293202': __('identifierSchemeCodeUnspsc190501.51293202'),
	'identifierSchemeCodeUnspsc190501.51293203': __('identifierSchemeCodeUnspsc190501.51293203'),
	'identifierSchemeCodeUnspsc190501.51293204': __('identifierSchemeCodeUnspsc190501.51293204'),
	'identifierSchemeCodeUnspsc190501.51293205': __('identifierSchemeCodeUnspsc190501.51293205'),
	'identifierSchemeCodeUnspsc190501.51293206': __('identifierSchemeCodeUnspsc190501.51293206'),
	'identifierSchemeCodeUnspsc190501.51293400': __('identifierSchemeCodeUnspsc190501.51293400'),
	'identifierSchemeCodeUnspsc190501.51293401': __('identifierSchemeCodeUnspsc190501.51293401'),
	'identifierSchemeCodeUnspsc190501.51293402': __('identifierSchemeCodeUnspsc190501.51293402'),
	'identifierSchemeCodeUnspsc190501.51293403': __('identifierSchemeCodeUnspsc190501.51293403'),
	'identifierSchemeCodeUnspsc190501.51293404': __('identifierSchemeCodeUnspsc190501.51293404'),
	'identifierSchemeCodeUnspsc190501.51293405': __('identifierSchemeCodeUnspsc190501.51293405'),
	'identifierSchemeCodeUnspsc190501.51293406': __('identifierSchemeCodeUnspsc190501.51293406'),
	'identifierSchemeCodeUnspsc190501.51293407': __('identifierSchemeCodeUnspsc190501.51293407'),
	'identifierSchemeCodeUnspsc190501.51293500': __('identifierSchemeCodeUnspsc190501.51293500'),
	'identifierSchemeCodeUnspsc190501.51293501': __('identifierSchemeCodeUnspsc190501.51293501'),
	'identifierSchemeCodeUnspsc190501.51293502': __('identifierSchemeCodeUnspsc190501.51293502'),
	'identifierSchemeCodeUnspsc190501.51293503': __('identifierSchemeCodeUnspsc190501.51293503'),
	'identifierSchemeCodeUnspsc190501.51293600': __('identifierSchemeCodeUnspsc190501.51293600'),
	'identifierSchemeCodeUnspsc190501.51293601': __('identifierSchemeCodeUnspsc190501.51293601'),
	'identifierSchemeCodeUnspsc190501.51293602': __('identifierSchemeCodeUnspsc190501.51293602'),
	'identifierSchemeCodeUnspsc190501.51293603': __('identifierSchemeCodeUnspsc190501.51293603'),
	'identifierSchemeCodeUnspsc190501.51293604': __('identifierSchemeCodeUnspsc190501.51293604'),
	'identifierSchemeCodeUnspsc190501.51293605': __('identifierSchemeCodeUnspsc190501.51293605'),
	'identifierSchemeCodeUnspsc190501.51293606': __('identifierSchemeCodeUnspsc190501.51293606'),
	'identifierSchemeCodeUnspsc190501.51293607': __('identifierSchemeCodeUnspsc190501.51293607'),
	'identifierSchemeCodeUnspsc190501.51293608': __('identifierSchemeCodeUnspsc190501.51293608'),
	'identifierSchemeCodeUnspsc190501.51293609': __('identifierSchemeCodeUnspsc190501.51293609'),
	'identifierSchemeCodeUnspsc190501.51293610': __('identifierSchemeCodeUnspsc190501.51293610'),
	'identifierSchemeCodeUnspsc190501.51293611': __('identifierSchemeCodeUnspsc190501.51293611'),
	'identifierSchemeCodeUnspsc190501.51293612': __('identifierSchemeCodeUnspsc190501.51293612'),
	'identifierSchemeCodeUnspsc190501.51293613': __('identifierSchemeCodeUnspsc190501.51293613'),
	'identifierSchemeCodeUnspsc190501.51293614': __('identifierSchemeCodeUnspsc190501.51293614'),
	'identifierSchemeCodeUnspsc190501.51293615': __('identifierSchemeCodeUnspsc190501.51293615'),
	'identifierSchemeCodeUnspsc190501.51293700': __('identifierSchemeCodeUnspsc190501.51293700'),
	'identifierSchemeCodeUnspsc190501.51293701': __('identifierSchemeCodeUnspsc190501.51293701'),
	'identifierSchemeCodeUnspsc190501.51293702': __('identifierSchemeCodeUnspsc190501.51293702'),
	'identifierSchemeCodeUnspsc190501.51293703': __('identifierSchemeCodeUnspsc190501.51293703'),
	'identifierSchemeCodeUnspsc190501.51293800': __('identifierSchemeCodeUnspsc190501.51293800'),
	'identifierSchemeCodeUnspsc190501.51293801': __('identifierSchemeCodeUnspsc190501.51293801'),
	'identifierSchemeCodeUnspsc190501.51293802': __('identifierSchemeCodeUnspsc190501.51293802'),
	'identifierSchemeCodeUnspsc190501.51293803': __('identifierSchemeCodeUnspsc190501.51293803'),
	'identifierSchemeCodeUnspsc190501.51293900': __('identifierSchemeCodeUnspsc190501.51293900'),
	'identifierSchemeCodeUnspsc190501.51293901': __('identifierSchemeCodeUnspsc190501.51293901'),
	'identifierSchemeCodeUnspsc190501.51293902': __('identifierSchemeCodeUnspsc190501.51293902'),
	'identifierSchemeCodeUnspsc190501.51294000': __('identifierSchemeCodeUnspsc190501.51294000'),
	'identifierSchemeCodeUnspsc190501.51294001': __('identifierSchemeCodeUnspsc190501.51294001'),
	'identifierSchemeCodeUnspsc190501.51294002': __('identifierSchemeCodeUnspsc190501.51294002'),
	'identifierSchemeCodeUnspsc190501.51294100': __('identifierSchemeCodeUnspsc190501.51294100'),
	'identifierSchemeCodeUnspsc190501.51294101': __('identifierSchemeCodeUnspsc190501.51294101'),
	'identifierSchemeCodeUnspsc190501.51294102': __('identifierSchemeCodeUnspsc190501.51294102'),
	'identifierSchemeCodeUnspsc190501.51294200': __('identifierSchemeCodeUnspsc190501.51294200'),
	'identifierSchemeCodeUnspsc190501.51294201': __('identifierSchemeCodeUnspsc190501.51294201'),
	'identifierSchemeCodeUnspsc190501.51294202': __('identifierSchemeCodeUnspsc190501.51294202'),
	'identifierSchemeCodeUnspsc190501.51294203': __('identifierSchemeCodeUnspsc190501.51294203'),
	'identifierSchemeCodeUnspsc190501.51294204': __('identifierSchemeCodeUnspsc190501.51294204'),
	'identifierSchemeCodeUnspsc190501.51294205': __('identifierSchemeCodeUnspsc190501.51294205'),
	'identifierSchemeCodeUnspsc190501.51294206': __('identifierSchemeCodeUnspsc190501.51294206'),
	'identifierSchemeCodeUnspsc190501.51294207': __('identifierSchemeCodeUnspsc190501.51294207'),
	'identifierSchemeCodeUnspsc190501.51294208': __('identifierSchemeCodeUnspsc190501.51294208'),
	'identifierSchemeCodeUnspsc190501.51294209': __('identifierSchemeCodeUnspsc190501.51294209'),
	'identifierSchemeCodeUnspsc190501.51294210': __('identifierSchemeCodeUnspsc190501.51294210'),
	'identifierSchemeCodeUnspsc190501.51294211': __('identifierSchemeCodeUnspsc190501.51294211'),
	'identifierSchemeCodeUnspsc190501.51294300': __('identifierSchemeCodeUnspsc190501.51294300'),
	'identifierSchemeCodeUnspsc190501.51294301': __('identifierSchemeCodeUnspsc190501.51294301'),
	'identifierSchemeCodeUnspsc190501.51294302': __('identifierSchemeCodeUnspsc190501.51294302'),
	'identifierSchemeCodeUnspsc190501.51294303': __('identifierSchemeCodeUnspsc190501.51294303'),
	'identifierSchemeCodeUnspsc190501.51294304': __('identifierSchemeCodeUnspsc190501.51294304'),
	'identifierSchemeCodeUnspsc190501.51294305': __('identifierSchemeCodeUnspsc190501.51294305'),
	'identifierSchemeCodeUnspsc190501.51294400': __('identifierSchemeCodeUnspsc190501.51294400'),
	'identifierSchemeCodeUnspsc190501.51294401': __('identifierSchemeCodeUnspsc190501.51294401'),
	'identifierSchemeCodeUnspsc190501.51294402': __('identifierSchemeCodeUnspsc190501.51294402'),
	'identifierSchemeCodeUnspsc190501.51294403': __('identifierSchemeCodeUnspsc190501.51294403'),
	'identifierSchemeCodeUnspsc190501.51294404': __('identifierSchemeCodeUnspsc190501.51294404'),
	'identifierSchemeCodeUnspsc190501.51294405': __('identifierSchemeCodeUnspsc190501.51294405'),
	'identifierSchemeCodeUnspsc190501.51294406': __('identifierSchemeCodeUnspsc190501.51294406'),
	'identifierSchemeCodeUnspsc190501.51294407': __('identifierSchemeCodeUnspsc190501.51294407'),
	'identifierSchemeCodeUnspsc190501.51294408': __('identifierSchemeCodeUnspsc190501.51294408'),
	'identifierSchemeCodeUnspsc190501.51294409': __('identifierSchemeCodeUnspsc190501.51294409'),
	'identifierSchemeCodeUnspsc190501.51294410': __('identifierSchemeCodeUnspsc190501.51294410'),
	'identifierSchemeCodeUnspsc190501.51294411': __('identifierSchemeCodeUnspsc190501.51294411'),
	'identifierSchemeCodeUnspsc190501.51294412': __('identifierSchemeCodeUnspsc190501.51294412'),
	'identifierSchemeCodeUnspsc190501.51294413': __('identifierSchemeCodeUnspsc190501.51294413'),
	'identifierSchemeCodeUnspsc190501.51294414': __('identifierSchemeCodeUnspsc190501.51294414'),
	'identifierSchemeCodeUnspsc190501.51294415': __('identifierSchemeCodeUnspsc190501.51294415'),
	'identifierSchemeCodeUnspsc190501.51294416': __('identifierSchemeCodeUnspsc190501.51294416'),
	'identifierSchemeCodeUnspsc190501.51294417': __('identifierSchemeCodeUnspsc190501.51294417'),
	'identifierSchemeCodeUnspsc190501.51294418': __('identifierSchemeCodeUnspsc190501.51294418'),
	'identifierSchemeCodeUnspsc190501.51294419': __('identifierSchemeCodeUnspsc190501.51294419'),
	'identifierSchemeCodeUnspsc190501.51294420': __('identifierSchemeCodeUnspsc190501.51294420'),
	'identifierSchemeCodeUnspsc190501.51294421': __('identifierSchemeCodeUnspsc190501.51294421'),
	'identifierSchemeCodeUnspsc190501.51294422': __('identifierSchemeCodeUnspsc190501.51294422'),
	'identifierSchemeCodeUnspsc190501.51294500': __('identifierSchemeCodeUnspsc190501.51294500'),
	'identifierSchemeCodeUnspsc190501.51294501': __('identifierSchemeCodeUnspsc190501.51294501'),
	'identifierSchemeCodeUnspsc190501.51294502': __('identifierSchemeCodeUnspsc190501.51294502'),
	'identifierSchemeCodeUnspsc190501.51294503': __('identifierSchemeCodeUnspsc190501.51294503'),
	'identifierSchemeCodeUnspsc190501.51294504': __('identifierSchemeCodeUnspsc190501.51294504'),
	'identifierSchemeCodeUnspsc190501.51294505': __('identifierSchemeCodeUnspsc190501.51294505'),
	'identifierSchemeCodeUnspsc190501.51294506': __('identifierSchemeCodeUnspsc190501.51294506'),
	'identifierSchemeCodeUnspsc190501.51294507': __('identifierSchemeCodeUnspsc190501.51294507'),
	'identifierSchemeCodeUnspsc190501.51294508': __('identifierSchemeCodeUnspsc190501.51294508'),
	'identifierSchemeCodeUnspsc190501.51294509': __('identifierSchemeCodeUnspsc190501.51294509'),
	'identifierSchemeCodeUnspsc190501.51294510': __('identifierSchemeCodeUnspsc190501.51294510'),
	'identifierSchemeCodeUnspsc190501.51294511': __('identifierSchemeCodeUnspsc190501.51294511'),
	'identifierSchemeCodeUnspsc190501.51294512': __('identifierSchemeCodeUnspsc190501.51294512'),
	'identifierSchemeCodeUnspsc190501.51294513': __('identifierSchemeCodeUnspsc190501.51294513'),
	'identifierSchemeCodeUnspsc190501.51294514': __('identifierSchemeCodeUnspsc190501.51294514'),
	'identifierSchemeCodeUnspsc190501.51294515': __('identifierSchemeCodeUnspsc190501.51294515'),
	'identifierSchemeCodeUnspsc190501.51294516': __('identifierSchemeCodeUnspsc190501.51294516'),
	'identifierSchemeCodeUnspsc190501.51294517': __('identifierSchemeCodeUnspsc190501.51294517'),
	'identifierSchemeCodeUnspsc190501.51294518': __('identifierSchemeCodeUnspsc190501.51294518'),
	'identifierSchemeCodeUnspsc190501.51294519': __('identifierSchemeCodeUnspsc190501.51294519'),
	'identifierSchemeCodeUnspsc190501.51294520': __('identifierSchemeCodeUnspsc190501.51294520'),
	'identifierSchemeCodeUnspsc190501.51294521': __('identifierSchemeCodeUnspsc190501.51294521'),
	'identifierSchemeCodeUnspsc190501.51294522': __('identifierSchemeCodeUnspsc190501.51294522'),
	'identifierSchemeCodeUnspsc190501.51294523': __('identifierSchemeCodeUnspsc190501.51294523'),
	'identifierSchemeCodeUnspsc190501.51294600': __('identifierSchemeCodeUnspsc190501.51294600'),
	'identifierSchemeCodeUnspsc190501.51294601': __('identifierSchemeCodeUnspsc190501.51294601'),
	'identifierSchemeCodeUnspsc190501.51294602': __('identifierSchemeCodeUnspsc190501.51294602'),
	'identifierSchemeCodeUnspsc190501.51294603': __('identifierSchemeCodeUnspsc190501.51294603'),
	'identifierSchemeCodeUnspsc190501.51294700': __('identifierSchemeCodeUnspsc190501.51294700'),
	'identifierSchemeCodeUnspsc190501.51294701': __('identifierSchemeCodeUnspsc190501.51294701'),
	'identifierSchemeCodeUnspsc190501.51294702': __('identifierSchemeCodeUnspsc190501.51294702'),
	'identifierSchemeCodeUnspsc190501.51294703': __('identifierSchemeCodeUnspsc190501.51294703'),
	'identifierSchemeCodeUnspsc190501.51294800': __('identifierSchemeCodeUnspsc190501.51294800'),
	'identifierSchemeCodeUnspsc190501.51294801': __('identifierSchemeCodeUnspsc190501.51294801'),
	'identifierSchemeCodeUnspsc190501.51294802': __('identifierSchemeCodeUnspsc190501.51294802'),
	'identifierSchemeCodeUnspsc190501.51294803': __('identifierSchemeCodeUnspsc190501.51294803'),
	'identifierSchemeCodeUnspsc190501.51294900': __('identifierSchemeCodeUnspsc190501.51294900'),
	'identifierSchemeCodeUnspsc190501.51294901': __('identifierSchemeCodeUnspsc190501.51294901'),
	'identifierSchemeCodeUnspsc190501.51294902': __('identifierSchemeCodeUnspsc190501.51294902'),
	'identifierSchemeCodeUnspsc190501.51294903': __('identifierSchemeCodeUnspsc190501.51294903'),
	'identifierSchemeCodeUnspsc190501.51294904': __('identifierSchemeCodeUnspsc190501.51294904'),
	'identifierSchemeCodeUnspsc190501.51294905': __('identifierSchemeCodeUnspsc190501.51294905'),
	'identifierSchemeCodeUnspsc190501.51294906': __('identifierSchemeCodeUnspsc190501.51294906'),
	'identifierSchemeCodeUnspsc190501.51294907': __('identifierSchemeCodeUnspsc190501.51294907'),
	'identifierSchemeCodeUnspsc190501.51294908': __('identifierSchemeCodeUnspsc190501.51294908'),
	'identifierSchemeCodeUnspsc190501.51294909': __('identifierSchemeCodeUnspsc190501.51294909'),
	'identifierSchemeCodeUnspsc190501.51294910': __('identifierSchemeCodeUnspsc190501.51294910'),
	'identifierSchemeCodeUnspsc190501.51295000': __('identifierSchemeCodeUnspsc190501.51295000'),
	'identifierSchemeCodeUnspsc190501.51295001': __('identifierSchemeCodeUnspsc190501.51295001'),
	'identifierSchemeCodeUnspsc190501.51295002': __('identifierSchemeCodeUnspsc190501.51295002'),
	'identifierSchemeCodeUnspsc190501.51295100': __('identifierSchemeCodeUnspsc190501.51295100'),
	'identifierSchemeCodeUnspsc190501.51295101': __('identifierSchemeCodeUnspsc190501.51295101'),
	'identifierSchemeCodeUnspsc190501.51295102': __('identifierSchemeCodeUnspsc190501.51295102'),
	'identifierSchemeCodeUnspsc190501.51295103': __('identifierSchemeCodeUnspsc190501.51295103'),
	'identifierSchemeCodeUnspsc190501.51295104': __('identifierSchemeCodeUnspsc190501.51295104'),
	'identifierSchemeCodeUnspsc190501.51295105': __('identifierSchemeCodeUnspsc190501.51295105'),
	'identifierSchemeCodeUnspsc190501.51295106': __('identifierSchemeCodeUnspsc190501.51295106'),
	'identifierSchemeCodeUnspsc190501.51295107': __('identifierSchemeCodeUnspsc190501.51295107'),
	'identifierSchemeCodeUnspsc190501.51295108': __('identifierSchemeCodeUnspsc190501.51295108'),
	'identifierSchemeCodeUnspsc190501.51295300': __('identifierSchemeCodeUnspsc190501.51295300'),
	'identifierSchemeCodeUnspsc190501.51295301': __('identifierSchemeCodeUnspsc190501.51295301'),
	'identifierSchemeCodeUnspsc190501.51295302': __('identifierSchemeCodeUnspsc190501.51295302'),
	'identifierSchemeCodeUnspsc190501.51295303': __('identifierSchemeCodeUnspsc190501.51295303'),
	'identifierSchemeCodeUnspsc190501.51300000': __('identifierSchemeCodeUnspsc190501.51300000'),
	'identifierSchemeCodeUnspsc190501.51301600': __('identifierSchemeCodeUnspsc190501.51301600'),
	'identifierSchemeCodeUnspsc190501.51301601': __('identifierSchemeCodeUnspsc190501.51301601'),
	'identifierSchemeCodeUnspsc190501.51301602': __('identifierSchemeCodeUnspsc190501.51301602'),
	'identifierSchemeCodeUnspsc190501.51301603': __('identifierSchemeCodeUnspsc190501.51301603'),
	'identifierSchemeCodeUnspsc190501.51301700': __('identifierSchemeCodeUnspsc190501.51301700'),
	'identifierSchemeCodeUnspsc190501.51301701': __('identifierSchemeCodeUnspsc190501.51301701'),
	'identifierSchemeCodeUnspsc190501.51301702': __('identifierSchemeCodeUnspsc190501.51301702'),
	'identifierSchemeCodeUnspsc190501.51301800': __('identifierSchemeCodeUnspsc190501.51301800'),
	'identifierSchemeCodeUnspsc190501.51301801': __('identifierSchemeCodeUnspsc190501.51301801'),
	'identifierSchemeCodeUnspsc190501.51301802': __('identifierSchemeCodeUnspsc190501.51301802'),
	'identifierSchemeCodeUnspsc190501.51301803': __('identifierSchemeCodeUnspsc190501.51301803'),
	'identifierSchemeCodeUnspsc190501.51301804': __('identifierSchemeCodeUnspsc190501.51301804'),
	'identifierSchemeCodeUnspsc190501.51301900': __('identifierSchemeCodeUnspsc190501.51301900'),
	'identifierSchemeCodeUnspsc190501.51301901': __('identifierSchemeCodeUnspsc190501.51301901'),
	'identifierSchemeCodeUnspsc190501.51301902': __('identifierSchemeCodeUnspsc190501.51301902'),
	'identifierSchemeCodeUnspsc190501.51301903': __('identifierSchemeCodeUnspsc190501.51301903'),
	'identifierSchemeCodeUnspsc190501.51301904': __('identifierSchemeCodeUnspsc190501.51301904'),
	'identifierSchemeCodeUnspsc190501.51301905': __('identifierSchemeCodeUnspsc190501.51301905'),
	'identifierSchemeCodeUnspsc190501.51302000': __('identifierSchemeCodeUnspsc190501.51302000'),
	'identifierSchemeCodeUnspsc190501.51302001': __('identifierSchemeCodeUnspsc190501.51302001'),
	'identifierSchemeCodeUnspsc190501.51302002': __('identifierSchemeCodeUnspsc190501.51302002'),
	'identifierSchemeCodeUnspsc190501.51302003': __('identifierSchemeCodeUnspsc190501.51302003'),
	'identifierSchemeCodeUnspsc190501.51302004': __('identifierSchemeCodeUnspsc190501.51302004'),
	'identifierSchemeCodeUnspsc190501.51302005': __('identifierSchemeCodeUnspsc190501.51302005'),
	'identifierSchemeCodeUnspsc190501.51302100': __('identifierSchemeCodeUnspsc190501.51302100'),
	'identifierSchemeCodeUnspsc190501.51302101': __('identifierSchemeCodeUnspsc190501.51302101'),
	'identifierSchemeCodeUnspsc190501.51302102': __('identifierSchemeCodeUnspsc190501.51302102'),
	'identifierSchemeCodeUnspsc190501.51302103': __('identifierSchemeCodeUnspsc190501.51302103'),
	'identifierSchemeCodeUnspsc190501.51302300': __('identifierSchemeCodeUnspsc190501.51302300'),
	'identifierSchemeCodeUnspsc190501.51302301': __('identifierSchemeCodeUnspsc190501.51302301'),
	'identifierSchemeCodeUnspsc190501.51302302': __('identifierSchemeCodeUnspsc190501.51302302'),
	'identifierSchemeCodeUnspsc190501.51302303': __('identifierSchemeCodeUnspsc190501.51302303'),
	'identifierSchemeCodeUnspsc190501.51302304': __('identifierSchemeCodeUnspsc190501.51302304'),
	'identifierSchemeCodeUnspsc190501.51302305': __('identifierSchemeCodeUnspsc190501.51302305'),
	'identifierSchemeCodeUnspsc190501.51302306': __('identifierSchemeCodeUnspsc190501.51302306'),
	'identifierSchemeCodeUnspsc190501.51302307': __('identifierSchemeCodeUnspsc190501.51302307'),
	'identifierSchemeCodeUnspsc190501.51302308': __('identifierSchemeCodeUnspsc190501.51302308'),
	'identifierSchemeCodeUnspsc190501.51302309': __('identifierSchemeCodeUnspsc190501.51302309'),
	'identifierSchemeCodeUnspsc190501.51302310': __('identifierSchemeCodeUnspsc190501.51302310'),
	'identifierSchemeCodeUnspsc190501.51302311': __('identifierSchemeCodeUnspsc190501.51302311'),
	'identifierSchemeCodeUnspsc190501.51302312': __('identifierSchemeCodeUnspsc190501.51302312'),
	'identifierSchemeCodeUnspsc190501.51302313': __('identifierSchemeCodeUnspsc190501.51302313'),
	'identifierSchemeCodeUnspsc190501.51302314': __('identifierSchemeCodeUnspsc190501.51302314'),
	'identifierSchemeCodeUnspsc190501.51302315': __('identifierSchemeCodeUnspsc190501.51302315'),
	'identifierSchemeCodeUnspsc190501.51302316': __('identifierSchemeCodeUnspsc190501.51302316'),
	'identifierSchemeCodeUnspsc190501.51302317': __('identifierSchemeCodeUnspsc190501.51302317'),
	'identifierSchemeCodeUnspsc190501.51302318': __('identifierSchemeCodeUnspsc190501.51302318'),
	'identifierSchemeCodeUnspsc190501.51302319': __('identifierSchemeCodeUnspsc190501.51302319'),
	'identifierSchemeCodeUnspsc190501.51302320': __('identifierSchemeCodeUnspsc190501.51302320'),
	'identifierSchemeCodeUnspsc190501.51302321': __('identifierSchemeCodeUnspsc190501.51302321'),
	'identifierSchemeCodeUnspsc190501.51302322': __('identifierSchemeCodeUnspsc190501.51302322'),
	'identifierSchemeCodeUnspsc190501.51302323': __('identifierSchemeCodeUnspsc190501.51302323'),
	'identifierSchemeCodeUnspsc190501.51302324': __('identifierSchemeCodeUnspsc190501.51302324'),
	'identifierSchemeCodeUnspsc190501.51302325': __('identifierSchemeCodeUnspsc190501.51302325'),
	'identifierSchemeCodeUnspsc190501.51302326': __('identifierSchemeCodeUnspsc190501.51302326'),
	'identifierSchemeCodeUnspsc190501.51302327': __('identifierSchemeCodeUnspsc190501.51302327'),
	'identifierSchemeCodeUnspsc190501.51302328': __('identifierSchemeCodeUnspsc190501.51302328'),
	'identifierSchemeCodeUnspsc190501.51302329': __('identifierSchemeCodeUnspsc190501.51302329'),
	'identifierSchemeCodeUnspsc190501.51302330': __('identifierSchemeCodeUnspsc190501.51302330'),
	'identifierSchemeCodeUnspsc190501.51302331': __('identifierSchemeCodeUnspsc190501.51302331'),
	'identifierSchemeCodeUnspsc190501.51302332': __('identifierSchemeCodeUnspsc190501.51302332'),
	'identifierSchemeCodeUnspsc190501.51302333': __('identifierSchemeCodeUnspsc190501.51302333'),
	'identifierSchemeCodeUnspsc190501.51302334': __('identifierSchemeCodeUnspsc190501.51302334'),
	'identifierSchemeCodeUnspsc190501.51302335': __('identifierSchemeCodeUnspsc190501.51302335'),
	'identifierSchemeCodeUnspsc190501.51302336': __('identifierSchemeCodeUnspsc190501.51302336'),
	'identifierSchemeCodeUnspsc190501.51302337': __('identifierSchemeCodeUnspsc190501.51302337'),
	'identifierSchemeCodeUnspsc190501.51302338': __('identifierSchemeCodeUnspsc190501.51302338'),
	'identifierSchemeCodeUnspsc190501.51302339': __('identifierSchemeCodeUnspsc190501.51302339'),
	'identifierSchemeCodeUnspsc190501.51302340': __('identifierSchemeCodeUnspsc190501.51302340'),
	'identifierSchemeCodeUnspsc190501.51302341': __('identifierSchemeCodeUnspsc190501.51302341'),
	'identifierSchemeCodeUnspsc190501.51302342': __('identifierSchemeCodeUnspsc190501.51302342'),
	'identifierSchemeCodeUnspsc190501.51302400': __('identifierSchemeCodeUnspsc190501.51302400'),
	'identifierSchemeCodeUnspsc190501.51302401': __('identifierSchemeCodeUnspsc190501.51302401'),
	'identifierSchemeCodeUnspsc190501.51302402': __('identifierSchemeCodeUnspsc190501.51302402'),
	'identifierSchemeCodeUnspsc190501.51302403': __('identifierSchemeCodeUnspsc190501.51302403'),
	'identifierSchemeCodeUnspsc190501.51302404': __('identifierSchemeCodeUnspsc190501.51302404'),
	'identifierSchemeCodeUnspsc190501.51302405': __('identifierSchemeCodeUnspsc190501.51302405'),
	'identifierSchemeCodeUnspsc190501.51302500': __('identifierSchemeCodeUnspsc190501.51302500'),
	'identifierSchemeCodeUnspsc190501.51302501': __('identifierSchemeCodeUnspsc190501.51302501'),
	'identifierSchemeCodeUnspsc190501.51302502': __('identifierSchemeCodeUnspsc190501.51302502'),
	'identifierSchemeCodeUnspsc190501.51302503': __('identifierSchemeCodeUnspsc190501.51302503'),
	'identifierSchemeCodeUnspsc190501.51302600': __('identifierSchemeCodeUnspsc190501.51302600'),
	'identifierSchemeCodeUnspsc190501.51302601': __('identifierSchemeCodeUnspsc190501.51302601'),
	'identifierSchemeCodeUnspsc190501.51302602': __('identifierSchemeCodeUnspsc190501.51302602'),
	'identifierSchemeCodeUnspsc190501.51302603': __('identifierSchemeCodeUnspsc190501.51302603'),
	'identifierSchemeCodeUnspsc190501.51302604': __('identifierSchemeCodeUnspsc190501.51302604'),
	'identifierSchemeCodeUnspsc190501.51302605': __('identifierSchemeCodeUnspsc190501.51302605'),
	'identifierSchemeCodeUnspsc190501.51302700': __('identifierSchemeCodeUnspsc190501.51302700'),
	'identifierSchemeCodeUnspsc190501.51302701': __('identifierSchemeCodeUnspsc190501.51302701'),
	'identifierSchemeCodeUnspsc190501.51302702': __('identifierSchemeCodeUnspsc190501.51302702'),
	'identifierSchemeCodeUnspsc190501.51302900': __('identifierSchemeCodeUnspsc190501.51302900'),
	'identifierSchemeCodeUnspsc190501.51302901': __('identifierSchemeCodeUnspsc190501.51302901'),
	'identifierSchemeCodeUnspsc190501.51302902': __('identifierSchemeCodeUnspsc190501.51302902'),
	'identifierSchemeCodeUnspsc190501.51302903': __('identifierSchemeCodeUnspsc190501.51302903'),
	'identifierSchemeCodeUnspsc190501.51303000': __('identifierSchemeCodeUnspsc190501.51303000'),
	'identifierSchemeCodeUnspsc190501.51303001': __('identifierSchemeCodeUnspsc190501.51303001'),
	'identifierSchemeCodeUnspsc190501.51303002': __('identifierSchemeCodeUnspsc190501.51303002'),
	'identifierSchemeCodeUnspsc190501.51303100': __('identifierSchemeCodeUnspsc190501.51303100'),
	'identifierSchemeCodeUnspsc190501.51303101': __('identifierSchemeCodeUnspsc190501.51303101'),
	'identifierSchemeCodeUnspsc190501.51303102': __('identifierSchemeCodeUnspsc190501.51303102'),
	'identifierSchemeCodeUnspsc190501.51303103': __('identifierSchemeCodeUnspsc190501.51303103'),
	'identifierSchemeCodeUnspsc190501.51303104': __('identifierSchemeCodeUnspsc190501.51303104'),
	'identifierSchemeCodeUnspsc190501.51303200': __('identifierSchemeCodeUnspsc190501.51303200'),
	'identifierSchemeCodeUnspsc190501.51303201': __('identifierSchemeCodeUnspsc190501.51303201'),
	'identifierSchemeCodeUnspsc190501.51303202': __('identifierSchemeCodeUnspsc190501.51303202'),
	'identifierSchemeCodeUnspsc190501.51303203': __('identifierSchemeCodeUnspsc190501.51303203'),
	'identifierSchemeCodeUnspsc190501.51303204': __('identifierSchemeCodeUnspsc190501.51303204'),
	'identifierSchemeCodeUnspsc190501.51303205': __('identifierSchemeCodeUnspsc190501.51303205'),
	'identifierSchemeCodeUnspsc190501.51303206': __('identifierSchemeCodeUnspsc190501.51303206'),
	'identifierSchemeCodeUnspsc190501.51303300': __('identifierSchemeCodeUnspsc190501.51303300'),
	'identifierSchemeCodeUnspsc190501.51303301': __('identifierSchemeCodeUnspsc190501.51303301'),
	'identifierSchemeCodeUnspsc190501.51303302': __('identifierSchemeCodeUnspsc190501.51303302'),
	'identifierSchemeCodeUnspsc190501.51303303': __('identifierSchemeCodeUnspsc190501.51303303'),
	'identifierSchemeCodeUnspsc190501.51303304': __('identifierSchemeCodeUnspsc190501.51303304'),
	'identifierSchemeCodeUnspsc190501.51303400': __('identifierSchemeCodeUnspsc190501.51303400'),
	'identifierSchemeCodeUnspsc190501.51303401': __('identifierSchemeCodeUnspsc190501.51303401'),
	'identifierSchemeCodeUnspsc190501.51303402': __('identifierSchemeCodeUnspsc190501.51303402'),
	'identifierSchemeCodeUnspsc190501.51303403': __('identifierSchemeCodeUnspsc190501.51303403'),
	'identifierSchemeCodeUnspsc190501.51303404': __('identifierSchemeCodeUnspsc190501.51303404'),
	'identifierSchemeCodeUnspsc190501.51303406': __('identifierSchemeCodeUnspsc190501.51303406'),
	'identifierSchemeCodeUnspsc190501.51303407': __('identifierSchemeCodeUnspsc190501.51303407'),
	'identifierSchemeCodeUnspsc190501.51303600': __('identifierSchemeCodeUnspsc190501.51303600'),
	'identifierSchemeCodeUnspsc190501.51303601': __('identifierSchemeCodeUnspsc190501.51303601'),
	'identifierSchemeCodeUnspsc190501.51303602': __('identifierSchemeCodeUnspsc190501.51303602'),
	'identifierSchemeCodeUnspsc190501.51303603': __('identifierSchemeCodeUnspsc190501.51303603'),
	'identifierSchemeCodeUnspsc190501.51303604': __('identifierSchemeCodeUnspsc190501.51303604'),
	'identifierSchemeCodeUnspsc190501.51303605': __('identifierSchemeCodeUnspsc190501.51303605'),
	'identifierSchemeCodeUnspsc190501.51303606': __('identifierSchemeCodeUnspsc190501.51303606'),
	'identifierSchemeCodeUnspsc190501.51303607': __('identifierSchemeCodeUnspsc190501.51303607'),
	'identifierSchemeCodeUnspsc190501.51303608': __('identifierSchemeCodeUnspsc190501.51303608'),
	'identifierSchemeCodeUnspsc190501.51303609': __('identifierSchemeCodeUnspsc190501.51303609'),
	'identifierSchemeCodeUnspsc190501.51303610': __('identifierSchemeCodeUnspsc190501.51303610'),
	'identifierSchemeCodeUnspsc190501.51303611': __('identifierSchemeCodeUnspsc190501.51303611'),
	'identifierSchemeCodeUnspsc190501.51303612': __('identifierSchemeCodeUnspsc190501.51303612'),
	'identifierSchemeCodeUnspsc190501.51303613': __('identifierSchemeCodeUnspsc190501.51303613'),
	'identifierSchemeCodeUnspsc190501.51303614': __('identifierSchemeCodeUnspsc190501.51303614'),
	'identifierSchemeCodeUnspsc190501.51303615': __('identifierSchemeCodeUnspsc190501.51303615'),
	'identifierSchemeCodeUnspsc190501.51303616': __('identifierSchemeCodeUnspsc190501.51303616'),
	'identifierSchemeCodeUnspsc190501.51303700': __('identifierSchemeCodeUnspsc190501.51303700'),
	'identifierSchemeCodeUnspsc190501.51303701': __('identifierSchemeCodeUnspsc190501.51303701'),
	'identifierSchemeCodeUnspsc190501.51310000': __('identifierSchemeCodeUnspsc190501.51310000'),
	'identifierSchemeCodeUnspsc190501.51311600': __('identifierSchemeCodeUnspsc190501.51311600'),
	'identifierSchemeCodeUnspsc190501.51311601': __('identifierSchemeCodeUnspsc190501.51311601'),
	'identifierSchemeCodeUnspsc190501.51311602': __('identifierSchemeCodeUnspsc190501.51311602'),
	'identifierSchemeCodeUnspsc190501.51311603': __('identifierSchemeCodeUnspsc190501.51311603'),
	'identifierSchemeCodeUnspsc190501.51311604': __('identifierSchemeCodeUnspsc190501.51311604'),
	'identifierSchemeCodeUnspsc190501.51311605': __('identifierSchemeCodeUnspsc190501.51311605'),
	'identifierSchemeCodeUnspsc190501.51311606': __('identifierSchemeCodeUnspsc190501.51311606'),
	'identifierSchemeCodeUnspsc190501.51311607': __('identifierSchemeCodeUnspsc190501.51311607'),
	'identifierSchemeCodeUnspsc190501.51311608': __('identifierSchemeCodeUnspsc190501.51311608'),
	'identifierSchemeCodeUnspsc190501.51311609': __('identifierSchemeCodeUnspsc190501.51311609'),
	'identifierSchemeCodeUnspsc190501.51311610': __('identifierSchemeCodeUnspsc190501.51311610'),
	'identifierSchemeCodeUnspsc190501.51311611': __('identifierSchemeCodeUnspsc190501.51311611'),
	'identifierSchemeCodeUnspsc190501.51311700': __('identifierSchemeCodeUnspsc190501.51311700'),
	'identifierSchemeCodeUnspsc190501.51311701': __('identifierSchemeCodeUnspsc190501.51311701'),
	'identifierSchemeCodeUnspsc190501.51311702': __('identifierSchemeCodeUnspsc190501.51311702'),
	'identifierSchemeCodeUnspsc190501.51311703': __('identifierSchemeCodeUnspsc190501.51311703'),
	'identifierSchemeCodeUnspsc190501.51311704': __('identifierSchemeCodeUnspsc190501.51311704'),
	'identifierSchemeCodeUnspsc190501.51311705': __('identifierSchemeCodeUnspsc190501.51311705'),
	'identifierSchemeCodeUnspsc190501.51311706': __('identifierSchemeCodeUnspsc190501.51311706'),
	'identifierSchemeCodeUnspsc190501.51311800': __('identifierSchemeCodeUnspsc190501.51311800'),
	'identifierSchemeCodeUnspsc190501.51311801': __('identifierSchemeCodeUnspsc190501.51311801'),
	'identifierSchemeCodeUnspsc190501.51311802': __('identifierSchemeCodeUnspsc190501.51311802'),
	'identifierSchemeCodeUnspsc190501.51311803': __('identifierSchemeCodeUnspsc190501.51311803'),
	'identifierSchemeCodeUnspsc190501.51311804': __('identifierSchemeCodeUnspsc190501.51311804'),
	'identifierSchemeCodeUnspsc190501.51311805': __('identifierSchemeCodeUnspsc190501.51311805'),
	'identifierSchemeCodeUnspsc190501.51311806': __('identifierSchemeCodeUnspsc190501.51311806'),
	'identifierSchemeCodeUnspsc190501.51311807': __('identifierSchemeCodeUnspsc190501.51311807'),
	'identifierSchemeCodeUnspsc190501.51311808': __('identifierSchemeCodeUnspsc190501.51311808'),
	'identifierSchemeCodeUnspsc190501.51311809': __('identifierSchemeCodeUnspsc190501.51311809'),
	'identifierSchemeCodeUnspsc190501.51311810': __('identifierSchemeCodeUnspsc190501.51311810'),
	'identifierSchemeCodeUnspsc190501.51311811': __('identifierSchemeCodeUnspsc190501.51311811'),
	'identifierSchemeCodeUnspsc190501.51311812': __('identifierSchemeCodeUnspsc190501.51311812'),
	'identifierSchemeCodeUnspsc190501.51311900': __('identifierSchemeCodeUnspsc190501.51311900'),
	'identifierSchemeCodeUnspsc190501.51311901': __('identifierSchemeCodeUnspsc190501.51311901'),
	'identifierSchemeCodeUnspsc190501.51311902': __('identifierSchemeCodeUnspsc190501.51311902'),
	'identifierSchemeCodeUnspsc190501.51312000': __('identifierSchemeCodeUnspsc190501.51312000'),
	'identifierSchemeCodeUnspsc190501.51312001': __('identifierSchemeCodeUnspsc190501.51312001'),
	'identifierSchemeCodeUnspsc190501.51312002': __('identifierSchemeCodeUnspsc190501.51312002'),
	'identifierSchemeCodeUnspsc190501.51312100': __('identifierSchemeCodeUnspsc190501.51312100'),
	'identifierSchemeCodeUnspsc190501.51312101': __('identifierSchemeCodeUnspsc190501.51312101'),
	'identifierSchemeCodeUnspsc190501.51312102': __('identifierSchemeCodeUnspsc190501.51312102'),
	'identifierSchemeCodeUnspsc190501.51312103': __('identifierSchemeCodeUnspsc190501.51312103'),
	'identifierSchemeCodeUnspsc190501.51312104': __('identifierSchemeCodeUnspsc190501.51312104'),
	'identifierSchemeCodeUnspsc190501.51312105': __('identifierSchemeCodeUnspsc190501.51312105'),
	'identifierSchemeCodeUnspsc190501.51312106': __('identifierSchemeCodeUnspsc190501.51312106'),
	'identifierSchemeCodeUnspsc190501.51312107': __('identifierSchemeCodeUnspsc190501.51312107'),
	'identifierSchemeCodeUnspsc190501.51312108': __('identifierSchemeCodeUnspsc190501.51312108'),
	'identifierSchemeCodeUnspsc190501.51312109': __('identifierSchemeCodeUnspsc190501.51312109'),
	'identifierSchemeCodeUnspsc190501.51312200': __('identifierSchemeCodeUnspsc190501.51312200'),
	'identifierSchemeCodeUnspsc190501.51312201': __('identifierSchemeCodeUnspsc190501.51312201'),
	'identifierSchemeCodeUnspsc190501.51312202': __('identifierSchemeCodeUnspsc190501.51312202'),
	'identifierSchemeCodeUnspsc190501.51312203': __('identifierSchemeCodeUnspsc190501.51312203'),
	'identifierSchemeCodeUnspsc190501.51312204': __('identifierSchemeCodeUnspsc190501.51312204'),
	'identifierSchemeCodeUnspsc190501.51312205': __('identifierSchemeCodeUnspsc190501.51312205'),
	'identifierSchemeCodeUnspsc190501.51312206': __('identifierSchemeCodeUnspsc190501.51312206'),
	'identifierSchemeCodeUnspsc190501.51312207': __('identifierSchemeCodeUnspsc190501.51312207'),
	'identifierSchemeCodeUnspsc190501.51312208': __('identifierSchemeCodeUnspsc190501.51312208'),
	'identifierSchemeCodeUnspsc190501.51312209': __('identifierSchemeCodeUnspsc190501.51312209'),
	'identifierSchemeCodeUnspsc190501.51312210': __('identifierSchemeCodeUnspsc190501.51312210'),
	'identifierSchemeCodeUnspsc190501.51312300': __('identifierSchemeCodeUnspsc190501.51312300'),
	'identifierSchemeCodeUnspsc190501.51312301': __('identifierSchemeCodeUnspsc190501.51312301'),
	'identifierSchemeCodeUnspsc190501.51312302': __('identifierSchemeCodeUnspsc190501.51312302'),
	'identifierSchemeCodeUnspsc190501.51312303': __('identifierSchemeCodeUnspsc190501.51312303'),
	'identifierSchemeCodeUnspsc190501.51312304': __('identifierSchemeCodeUnspsc190501.51312304'),
	'identifierSchemeCodeUnspsc190501.51312305': __('identifierSchemeCodeUnspsc190501.51312305'),
	'identifierSchemeCodeUnspsc190501.51312306': __('identifierSchemeCodeUnspsc190501.51312306'),
	'identifierSchemeCodeUnspsc190501.51312307': __('identifierSchemeCodeUnspsc190501.51312307'),
	'identifierSchemeCodeUnspsc190501.51312308': __('identifierSchemeCodeUnspsc190501.51312308'),
	'identifierSchemeCodeUnspsc190501.51312309': __('identifierSchemeCodeUnspsc190501.51312309'),
	'identifierSchemeCodeUnspsc190501.51312310': __('identifierSchemeCodeUnspsc190501.51312310'),
	'identifierSchemeCodeUnspsc190501.51312311': __('identifierSchemeCodeUnspsc190501.51312311'),
	'identifierSchemeCodeUnspsc190501.51312312': __('identifierSchemeCodeUnspsc190501.51312312'),
	'identifierSchemeCodeUnspsc190501.51312313': __('identifierSchemeCodeUnspsc190501.51312313'),
	'identifierSchemeCodeUnspsc190501.51312314': __('identifierSchemeCodeUnspsc190501.51312314'),
	'identifierSchemeCodeUnspsc190501.51312315': __('identifierSchemeCodeUnspsc190501.51312315'),
	'identifierSchemeCodeUnspsc190501.51312400': __('identifierSchemeCodeUnspsc190501.51312400'),
	'identifierSchemeCodeUnspsc190501.51312401': __('identifierSchemeCodeUnspsc190501.51312401'),
	'identifierSchemeCodeUnspsc190501.51312402': __('identifierSchemeCodeUnspsc190501.51312402'),
	'identifierSchemeCodeUnspsc190501.51312403': __('identifierSchemeCodeUnspsc190501.51312403'),
	'identifierSchemeCodeUnspsc190501.51312404': __('identifierSchemeCodeUnspsc190501.51312404'),
	'identifierSchemeCodeUnspsc190501.51312405': __('identifierSchemeCodeUnspsc190501.51312405'),
	'identifierSchemeCodeUnspsc190501.51312406': __('identifierSchemeCodeUnspsc190501.51312406'),
	'identifierSchemeCodeUnspsc190501.51312407': __('identifierSchemeCodeUnspsc190501.51312407'),
	'identifierSchemeCodeUnspsc190501.51312408': __('identifierSchemeCodeUnspsc190501.51312408'),
	'identifierSchemeCodeUnspsc190501.51312409': __('identifierSchemeCodeUnspsc190501.51312409'),
	'identifierSchemeCodeUnspsc190501.51312500': __('identifierSchemeCodeUnspsc190501.51312500'),
	'identifierSchemeCodeUnspsc190501.51312501': __('identifierSchemeCodeUnspsc190501.51312501'),
	'identifierSchemeCodeUnspsc190501.51312502': __('identifierSchemeCodeUnspsc190501.51312502'),
	'identifierSchemeCodeUnspsc190501.51312503': __('identifierSchemeCodeUnspsc190501.51312503'),
	'identifierSchemeCodeUnspsc190501.51312504': __('identifierSchemeCodeUnspsc190501.51312504'),
	'identifierSchemeCodeUnspsc190501.51312505': __('identifierSchemeCodeUnspsc190501.51312505'),
	'identifierSchemeCodeUnspsc190501.51312506': __('identifierSchemeCodeUnspsc190501.51312506'),
	'identifierSchemeCodeUnspsc190501.51312507': __('identifierSchemeCodeUnspsc190501.51312507'),
	'identifierSchemeCodeUnspsc190501.51312508': __('identifierSchemeCodeUnspsc190501.51312508'),
	'identifierSchemeCodeUnspsc190501.51312600': __('identifierSchemeCodeUnspsc190501.51312600'),
	'identifierSchemeCodeUnspsc190501.51312601': __('identifierSchemeCodeUnspsc190501.51312601'),
	'identifierSchemeCodeUnspsc190501.51312602': __('identifierSchemeCodeUnspsc190501.51312602'),
	'identifierSchemeCodeUnspsc190501.51312603': __('identifierSchemeCodeUnspsc190501.51312603'),
	'identifierSchemeCodeUnspsc190501.51312604': __('identifierSchemeCodeUnspsc190501.51312604'),
	'identifierSchemeCodeUnspsc190501.51312605': __('identifierSchemeCodeUnspsc190501.51312605'),
	'identifierSchemeCodeUnspsc190501.51312606': __('identifierSchemeCodeUnspsc190501.51312606'),
	'identifierSchemeCodeUnspsc190501.51312607': __('identifierSchemeCodeUnspsc190501.51312607'),
	'identifierSchemeCodeUnspsc190501.51312608': __('identifierSchemeCodeUnspsc190501.51312608'),
	'identifierSchemeCodeUnspsc190501.51312609': __('identifierSchemeCodeUnspsc190501.51312609'),
	'identifierSchemeCodeUnspsc190501.51312610': __('identifierSchemeCodeUnspsc190501.51312610'),
	'identifierSchemeCodeUnspsc190501.51312611': __('identifierSchemeCodeUnspsc190501.51312611'),
	'identifierSchemeCodeUnspsc190501.51312612': __('identifierSchemeCodeUnspsc190501.51312612'),
	'identifierSchemeCodeUnspsc190501.51312700': __('identifierSchemeCodeUnspsc190501.51312700'),
	'identifierSchemeCodeUnspsc190501.51312701': __('identifierSchemeCodeUnspsc190501.51312701'),
	'identifierSchemeCodeUnspsc190501.51312702': __('identifierSchemeCodeUnspsc190501.51312702'),
	'identifierSchemeCodeUnspsc190501.51312703': __('identifierSchemeCodeUnspsc190501.51312703'),
	'identifierSchemeCodeUnspsc190501.51312704': __('identifierSchemeCodeUnspsc190501.51312704'),
	'identifierSchemeCodeUnspsc190501.51312705': __('identifierSchemeCodeUnspsc190501.51312705'),
	'identifierSchemeCodeUnspsc190501.51312706': __('identifierSchemeCodeUnspsc190501.51312706'),
	'identifierSchemeCodeUnspsc190501.51312800': __('identifierSchemeCodeUnspsc190501.51312800'),
	'identifierSchemeCodeUnspsc190501.51312801': __('identifierSchemeCodeUnspsc190501.51312801'),
	'identifierSchemeCodeUnspsc190501.51312802': __('identifierSchemeCodeUnspsc190501.51312802'),
	'identifierSchemeCodeUnspsc190501.51312803': __('identifierSchemeCodeUnspsc190501.51312803'),
	'identifierSchemeCodeUnspsc190501.51312804': __('identifierSchemeCodeUnspsc190501.51312804'),
	'identifierSchemeCodeUnspsc190501.51312805': __('identifierSchemeCodeUnspsc190501.51312805'),
	'identifierSchemeCodeUnspsc190501.51312806': __('identifierSchemeCodeUnspsc190501.51312806'),
	'identifierSchemeCodeUnspsc190501.51312807': __('identifierSchemeCodeUnspsc190501.51312807'),
	'identifierSchemeCodeUnspsc190501.51312808': __('identifierSchemeCodeUnspsc190501.51312808'),
	'identifierSchemeCodeUnspsc190501.51312809': __('identifierSchemeCodeUnspsc190501.51312809'),
	'identifierSchemeCodeUnspsc190501.51312810': __('identifierSchemeCodeUnspsc190501.51312810'),
	'identifierSchemeCodeUnspsc190501.51312811': __('identifierSchemeCodeUnspsc190501.51312811'),
	'identifierSchemeCodeUnspsc190501.51312812': __('identifierSchemeCodeUnspsc190501.51312812'),
	'identifierSchemeCodeUnspsc190501.51312813': __('identifierSchemeCodeUnspsc190501.51312813'),
	'identifierSchemeCodeUnspsc190501.51312900': __('identifierSchemeCodeUnspsc190501.51312900'),
	'identifierSchemeCodeUnspsc190501.51312901': __('identifierSchemeCodeUnspsc190501.51312901'),
	'identifierSchemeCodeUnspsc190501.51312902': __('identifierSchemeCodeUnspsc190501.51312902'),
	'identifierSchemeCodeUnspsc190501.51313000': __('identifierSchemeCodeUnspsc190501.51313000'),
	'identifierSchemeCodeUnspsc190501.51313001': __('identifierSchemeCodeUnspsc190501.51313001'),
	'identifierSchemeCodeUnspsc190501.51313002': __('identifierSchemeCodeUnspsc190501.51313002'),
	'identifierSchemeCodeUnspsc190501.51313003': __('identifierSchemeCodeUnspsc190501.51313003'),
	'identifierSchemeCodeUnspsc190501.51313004': __('identifierSchemeCodeUnspsc190501.51313004'),
	'identifierSchemeCodeUnspsc190501.51313100': __('identifierSchemeCodeUnspsc190501.51313100'),
	'identifierSchemeCodeUnspsc190501.51313101': __('identifierSchemeCodeUnspsc190501.51313101'),
	'identifierSchemeCodeUnspsc190501.51313102': __('identifierSchemeCodeUnspsc190501.51313102'),
	'identifierSchemeCodeUnspsc190501.51313103': __('identifierSchemeCodeUnspsc190501.51313103'),
	'identifierSchemeCodeUnspsc190501.51313104': __('identifierSchemeCodeUnspsc190501.51313104'),
	'identifierSchemeCodeUnspsc190501.51313105': __('identifierSchemeCodeUnspsc190501.51313105'),
	'identifierSchemeCodeUnspsc190501.51313106': __('identifierSchemeCodeUnspsc190501.51313106'),
	'identifierSchemeCodeUnspsc190501.51313107': __('identifierSchemeCodeUnspsc190501.51313107'),
	'identifierSchemeCodeUnspsc190501.51313108': __('identifierSchemeCodeUnspsc190501.51313108'),
	'identifierSchemeCodeUnspsc190501.51313109': __('identifierSchemeCodeUnspsc190501.51313109'),
	'identifierSchemeCodeUnspsc190501.51313110': __('identifierSchemeCodeUnspsc190501.51313110'),
	'identifierSchemeCodeUnspsc190501.51313111': __('identifierSchemeCodeUnspsc190501.51313111'),
	'identifierSchemeCodeUnspsc190501.51313112': __('identifierSchemeCodeUnspsc190501.51313112'),
	'identifierSchemeCodeUnspsc190501.51313113': __('identifierSchemeCodeUnspsc190501.51313113'),
	'identifierSchemeCodeUnspsc190501.51313114': __('identifierSchemeCodeUnspsc190501.51313114'),
	'identifierSchemeCodeUnspsc190501.51313115': __('identifierSchemeCodeUnspsc190501.51313115'),
	'identifierSchemeCodeUnspsc190501.51313116': __('identifierSchemeCodeUnspsc190501.51313116'),
	'identifierSchemeCodeUnspsc190501.51313117': __('identifierSchemeCodeUnspsc190501.51313117'),
	'identifierSchemeCodeUnspsc190501.51313118': __('identifierSchemeCodeUnspsc190501.51313118'),
	'identifierSchemeCodeUnspsc190501.51313119': __('identifierSchemeCodeUnspsc190501.51313119'),
	'identifierSchemeCodeUnspsc190501.51313120': __('identifierSchemeCodeUnspsc190501.51313120'),
	'identifierSchemeCodeUnspsc190501.51313200': __('identifierSchemeCodeUnspsc190501.51313200'),
	'identifierSchemeCodeUnspsc190501.51313201': __('identifierSchemeCodeUnspsc190501.51313201'),
	'identifierSchemeCodeUnspsc190501.51313202': __('identifierSchemeCodeUnspsc190501.51313202'),
	'identifierSchemeCodeUnspsc190501.51313203': __('identifierSchemeCodeUnspsc190501.51313203'),
	'identifierSchemeCodeUnspsc190501.51313204': __('identifierSchemeCodeUnspsc190501.51313204'),
	'identifierSchemeCodeUnspsc190501.51313205': __('identifierSchemeCodeUnspsc190501.51313205'),
	'identifierSchemeCodeUnspsc190501.51313206': __('identifierSchemeCodeUnspsc190501.51313206'),
	'identifierSchemeCodeUnspsc190501.51313207': __('identifierSchemeCodeUnspsc190501.51313207'),
	'identifierSchemeCodeUnspsc190501.51313208': __('identifierSchemeCodeUnspsc190501.51313208'),
	'identifierSchemeCodeUnspsc190501.51313209': __('identifierSchemeCodeUnspsc190501.51313209'),
	'identifierSchemeCodeUnspsc190501.51313210': __('identifierSchemeCodeUnspsc190501.51313210'),
	'identifierSchemeCodeUnspsc190501.51313211': __('identifierSchemeCodeUnspsc190501.51313211'),
	'identifierSchemeCodeUnspsc190501.51313212': __('identifierSchemeCodeUnspsc190501.51313212'),
	'identifierSchemeCodeUnspsc190501.51313213': __('identifierSchemeCodeUnspsc190501.51313213'),
	'identifierSchemeCodeUnspsc190501.51313214': __('identifierSchemeCodeUnspsc190501.51313214'),
	'identifierSchemeCodeUnspsc190501.51313215': __('identifierSchemeCodeUnspsc190501.51313215'),
	'identifierSchemeCodeUnspsc190501.51313216': __('identifierSchemeCodeUnspsc190501.51313216'),
	'identifierSchemeCodeUnspsc190501.51313217': __('identifierSchemeCodeUnspsc190501.51313217'),
	'identifierSchemeCodeUnspsc190501.51313218': __('identifierSchemeCodeUnspsc190501.51313218'),
	'identifierSchemeCodeUnspsc190501.51313219': __('identifierSchemeCodeUnspsc190501.51313219'),
	'identifierSchemeCodeUnspsc190501.51313220': __('identifierSchemeCodeUnspsc190501.51313220'),
	'identifierSchemeCodeUnspsc190501.51313300': __('identifierSchemeCodeUnspsc190501.51313300'),
	'identifierSchemeCodeUnspsc190501.51313301': __('identifierSchemeCodeUnspsc190501.51313301'),
	'identifierSchemeCodeUnspsc190501.51313302': __('identifierSchemeCodeUnspsc190501.51313302'),
	'identifierSchemeCodeUnspsc190501.51313303': __('identifierSchemeCodeUnspsc190501.51313303'),
	'identifierSchemeCodeUnspsc190501.51313304': __('identifierSchemeCodeUnspsc190501.51313304'),
	'identifierSchemeCodeUnspsc190501.51313305': __('identifierSchemeCodeUnspsc190501.51313305'),
	'identifierSchemeCodeUnspsc190501.51313306': __('identifierSchemeCodeUnspsc190501.51313306'),
	'identifierSchemeCodeUnspsc190501.51313307': __('identifierSchemeCodeUnspsc190501.51313307'),
	'identifierSchemeCodeUnspsc190501.51313308': __('identifierSchemeCodeUnspsc190501.51313308'),
	'identifierSchemeCodeUnspsc190501.51313309': __('identifierSchemeCodeUnspsc190501.51313309'),
	'identifierSchemeCodeUnspsc190501.51313400': __('identifierSchemeCodeUnspsc190501.51313400'),
	'identifierSchemeCodeUnspsc190501.51313401': __('identifierSchemeCodeUnspsc190501.51313401'),
	'identifierSchemeCodeUnspsc190501.51313402': __('identifierSchemeCodeUnspsc190501.51313402'),
	'identifierSchemeCodeUnspsc190501.51313403': __('identifierSchemeCodeUnspsc190501.51313403'),
	'identifierSchemeCodeUnspsc190501.51313404': __('identifierSchemeCodeUnspsc190501.51313404'),
	'identifierSchemeCodeUnspsc190501.51313405': __('identifierSchemeCodeUnspsc190501.51313405'),
	'identifierSchemeCodeUnspsc190501.51313406': __('identifierSchemeCodeUnspsc190501.51313406'),
	'identifierSchemeCodeUnspsc190501.51313407': __('identifierSchemeCodeUnspsc190501.51313407'),
	'identifierSchemeCodeUnspsc190501.51313409': __('identifierSchemeCodeUnspsc190501.51313409'),
	'identifierSchemeCodeUnspsc190501.51313410': __('identifierSchemeCodeUnspsc190501.51313410'),
	'identifierSchemeCodeUnspsc190501.51313411': __('identifierSchemeCodeUnspsc190501.51313411'),
	'identifierSchemeCodeUnspsc190501.51313412': __('identifierSchemeCodeUnspsc190501.51313412'),
	'identifierSchemeCodeUnspsc190501.51313413': __('identifierSchemeCodeUnspsc190501.51313413'),
	'identifierSchemeCodeUnspsc190501.51313414': __('identifierSchemeCodeUnspsc190501.51313414'),
	'identifierSchemeCodeUnspsc190501.51313415': __('identifierSchemeCodeUnspsc190501.51313415'),
	'identifierSchemeCodeUnspsc190501.51313416': __('identifierSchemeCodeUnspsc190501.51313416'),
	'identifierSchemeCodeUnspsc190501.51313417': __('identifierSchemeCodeUnspsc190501.51313417'),
	'identifierSchemeCodeUnspsc190501.51313418': __('identifierSchemeCodeUnspsc190501.51313418'),
	'identifierSchemeCodeUnspsc190501.51313419': __('identifierSchemeCodeUnspsc190501.51313419'),
	'identifierSchemeCodeUnspsc190501.51313420': __('identifierSchemeCodeUnspsc190501.51313420'),
	'identifierSchemeCodeUnspsc190501.51313421': __('identifierSchemeCodeUnspsc190501.51313421'),
	'identifierSchemeCodeUnspsc190501.51313422': __('identifierSchemeCodeUnspsc190501.51313422'),
	'identifierSchemeCodeUnspsc190501.51313423': __('identifierSchemeCodeUnspsc190501.51313423'),
	'identifierSchemeCodeUnspsc190501.51313424': __('identifierSchemeCodeUnspsc190501.51313424'),
	'identifierSchemeCodeUnspsc190501.51313425': __('identifierSchemeCodeUnspsc190501.51313425'),
	'identifierSchemeCodeUnspsc190501.51313426': __('identifierSchemeCodeUnspsc190501.51313426'),
	'identifierSchemeCodeUnspsc190501.51313427': __('identifierSchemeCodeUnspsc190501.51313427'),
	'identifierSchemeCodeUnspsc190501.51313428': __('identifierSchemeCodeUnspsc190501.51313428'),
	'identifierSchemeCodeUnspsc190501.51313429': __('identifierSchemeCodeUnspsc190501.51313429'),
	'identifierSchemeCodeUnspsc190501.51313430': __('identifierSchemeCodeUnspsc190501.51313430'),
	'identifierSchemeCodeUnspsc190501.51313431': __('identifierSchemeCodeUnspsc190501.51313431'),
	'identifierSchemeCodeUnspsc190501.51313432': __('identifierSchemeCodeUnspsc190501.51313432'),
	'identifierSchemeCodeUnspsc190501.51313433': __('identifierSchemeCodeUnspsc190501.51313433'),
	'identifierSchemeCodeUnspsc190501.51313434': __('identifierSchemeCodeUnspsc190501.51313434'),
	'identifierSchemeCodeUnspsc190501.51313435': __('identifierSchemeCodeUnspsc190501.51313435'),
	'identifierSchemeCodeUnspsc190501.51313436': __('identifierSchemeCodeUnspsc190501.51313436'),
	'identifierSchemeCodeUnspsc190501.51313437': __('identifierSchemeCodeUnspsc190501.51313437'),
	'identifierSchemeCodeUnspsc190501.51313438': __('identifierSchemeCodeUnspsc190501.51313438'),
	'identifierSchemeCodeUnspsc190501.51313439': __('identifierSchemeCodeUnspsc190501.51313439'),
	'identifierSchemeCodeUnspsc190501.51313440': __('identifierSchemeCodeUnspsc190501.51313440'),
	'identifierSchemeCodeUnspsc190501.51313441': __('identifierSchemeCodeUnspsc190501.51313441'),
	'identifierSchemeCodeUnspsc190501.51313442': __('identifierSchemeCodeUnspsc190501.51313442'),
	'identifierSchemeCodeUnspsc190501.51313500': __('identifierSchemeCodeUnspsc190501.51313500'),
	'identifierSchemeCodeUnspsc190501.51313501': __('identifierSchemeCodeUnspsc190501.51313501'),
	'identifierSchemeCodeUnspsc190501.51313502': __('identifierSchemeCodeUnspsc190501.51313502'),
	'identifierSchemeCodeUnspsc190501.51313503': __('identifierSchemeCodeUnspsc190501.51313503'),
	'identifierSchemeCodeUnspsc190501.51313504': __('identifierSchemeCodeUnspsc190501.51313504'),
	'identifierSchemeCodeUnspsc190501.51313505': __('identifierSchemeCodeUnspsc190501.51313505'),
	'identifierSchemeCodeUnspsc190501.51313506': __('identifierSchemeCodeUnspsc190501.51313506'),
	'identifierSchemeCodeUnspsc190501.51313507': __('identifierSchemeCodeUnspsc190501.51313507'),
	'identifierSchemeCodeUnspsc190501.51313508': __('identifierSchemeCodeUnspsc190501.51313508'),
	'identifierSchemeCodeUnspsc190501.51313509': __('identifierSchemeCodeUnspsc190501.51313509'),
	'identifierSchemeCodeUnspsc190501.51313510': __('identifierSchemeCodeUnspsc190501.51313510'),
	'identifierSchemeCodeUnspsc190501.51313511': __('identifierSchemeCodeUnspsc190501.51313511'),
	'identifierSchemeCodeUnspsc190501.51313512': __('identifierSchemeCodeUnspsc190501.51313512'),
	'identifierSchemeCodeUnspsc190501.51313513': __('identifierSchemeCodeUnspsc190501.51313513'),
	'identifierSchemeCodeUnspsc190501.51313600': __('identifierSchemeCodeUnspsc190501.51313600'),
	'identifierSchemeCodeUnspsc190501.51313601': __('identifierSchemeCodeUnspsc190501.51313601'),
	'identifierSchemeCodeUnspsc190501.51313602': __('identifierSchemeCodeUnspsc190501.51313602'),
	'identifierSchemeCodeUnspsc190501.51313700': __('identifierSchemeCodeUnspsc190501.51313700'),
	'identifierSchemeCodeUnspsc190501.51313701': __('identifierSchemeCodeUnspsc190501.51313701'),
	'identifierSchemeCodeUnspsc190501.51313702': __('identifierSchemeCodeUnspsc190501.51313702'),
	'identifierSchemeCodeUnspsc190501.51313703': __('identifierSchemeCodeUnspsc190501.51313703'),
	'identifierSchemeCodeUnspsc190501.51313704': __('identifierSchemeCodeUnspsc190501.51313704'),
	'identifierSchemeCodeUnspsc190501.51313705': __('identifierSchemeCodeUnspsc190501.51313705'),
	'identifierSchemeCodeUnspsc190501.51313706': __('identifierSchemeCodeUnspsc190501.51313706'),
	'identifierSchemeCodeUnspsc190501.51313707': __('identifierSchemeCodeUnspsc190501.51313707'),
	'identifierSchemeCodeUnspsc190501.51313708': __('identifierSchemeCodeUnspsc190501.51313708'),
	'identifierSchemeCodeUnspsc190501.51313709': __('identifierSchemeCodeUnspsc190501.51313709'),
	'identifierSchemeCodeUnspsc190501.51313710': __('identifierSchemeCodeUnspsc190501.51313710'),
	'identifierSchemeCodeUnspsc190501.51313800': __('identifierSchemeCodeUnspsc190501.51313800'),
	'identifierSchemeCodeUnspsc190501.51313801': __('identifierSchemeCodeUnspsc190501.51313801'),
	'identifierSchemeCodeUnspsc190501.51313802': __('identifierSchemeCodeUnspsc190501.51313802'),
	'identifierSchemeCodeUnspsc190501.51313900': __('identifierSchemeCodeUnspsc190501.51313900'),
	'identifierSchemeCodeUnspsc190501.51313901': __('identifierSchemeCodeUnspsc190501.51313901'),
	'identifierSchemeCodeUnspsc190501.51313902': __('identifierSchemeCodeUnspsc190501.51313902'),
	'identifierSchemeCodeUnspsc190501.51314100': __('identifierSchemeCodeUnspsc190501.51314100'),
	'identifierSchemeCodeUnspsc190501.51314101': __('identifierSchemeCodeUnspsc190501.51314101'),
	'identifierSchemeCodeUnspsc190501.51314102': __('identifierSchemeCodeUnspsc190501.51314102'),
	'identifierSchemeCodeUnspsc190501.51314103': __('identifierSchemeCodeUnspsc190501.51314103'),
	'identifierSchemeCodeUnspsc190501.51314104': __('identifierSchemeCodeUnspsc190501.51314104'),
	'identifierSchemeCodeUnspsc190501.51314200': __('identifierSchemeCodeUnspsc190501.51314200'),
	'identifierSchemeCodeUnspsc190501.51314201': __('identifierSchemeCodeUnspsc190501.51314201'),
	'identifierSchemeCodeUnspsc190501.51314202': __('identifierSchemeCodeUnspsc190501.51314202'),
	'identifierSchemeCodeUnspsc190501.51314203': __('identifierSchemeCodeUnspsc190501.51314203'),
	'identifierSchemeCodeUnspsc190501.51314300': __('identifierSchemeCodeUnspsc190501.51314300'),
	'identifierSchemeCodeUnspsc190501.51314301': __('identifierSchemeCodeUnspsc190501.51314301'),
	'identifierSchemeCodeUnspsc190501.51314302': __('identifierSchemeCodeUnspsc190501.51314302'),
	'identifierSchemeCodeUnspsc190501.51314303': __('identifierSchemeCodeUnspsc190501.51314303'),
	'identifierSchemeCodeUnspsc190501.51314304': __('identifierSchemeCodeUnspsc190501.51314304'),
	'identifierSchemeCodeUnspsc190501.51314305': __('identifierSchemeCodeUnspsc190501.51314305'),
	'identifierSchemeCodeUnspsc190501.51314306': __('identifierSchemeCodeUnspsc190501.51314306'),
	'identifierSchemeCodeUnspsc190501.51314307': __('identifierSchemeCodeUnspsc190501.51314307'),
	'identifierSchemeCodeUnspsc190501.51314308': __('identifierSchemeCodeUnspsc190501.51314308'),
	'identifierSchemeCodeUnspsc190501.51314309': __('identifierSchemeCodeUnspsc190501.51314309'),
	'identifierSchemeCodeUnspsc190501.51314310': __('identifierSchemeCodeUnspsc190501.51314310'),
	'identifierSchemeCodeUnspsc190501.51314311': __('identifierSchemeCodeUnspsc190501.51314311'),
	'identifierSchemeCodeUnspsc190501.51314312': __('identifierSchemeCodeUnspsc190501.51314312'),
	'identifierSchemeCodeUnspsc190501.51314313': __('identifierSchemeCodeUnspsc190501.51314313'),
	'identifierSchemeCodeUnspsc190501.51314314': __('identifierSchemeCodeUnspsc190501.51314314'),
	'identifierSchemeCodeUnspsc190501.51314315': __('identifierSchemeCodeUnspsc190501.51314315'),
	'identifierSchemeCodeUnspsc190501.51314316': __('identifierSchemeCodeUnspsc190501.51314316'),
	'identifierSchemeCodeUnspsc190501.51314317': __('identifierSchemeCodeUnspsc190501.51314317'),
	'identifierSchemeCodeUnspsc190501.51314318': __('identifierSchemeCodeUnspsc190501.51314318'),
	'identifierSchemeCodeUnspsc190501.51314319': __('identifierSchemeCodeUnspsc190501.51314319'),
	'identifierSchemeCodeUnspsc190501.51314320': __('identifierSchemeCodeUnspsc190501.51314320'),
	'identifierSchemeCodeUnspsc190501.51314321': __('identifierSchemeCodeUnspsc190501.51314321'),
	'identifierSchemeCodeUnspsc190501.51314322': __('identifierSchemeCodeUnspsc190501.51314322'),
	'identifierSchemeCodeUnspsc190501.51314323': __('identifierSchemeCodeUnspsc190501.51314323'),
	'identifierSchemeCodeUnspsc190501.51314324': __('identifierSchemeCodeUnspsc190501.51314324'),
	'identifierSchemeCodeUnspsc190501.51314325': __('identifierSchemeCodeUnspsc190501.51314325'),
	'identifierSchemeCodeUnspsc190501.51314326': __('identifierSchemeCodeUnspsc190501.51314326'),
	'identifierSchemeCodeUnspsc190501.51314327': __('identifierSchemeCodeUnspsc190501.51314327'),
	'identifierSchemeCodeUnspsc190501.51314328': __('identifierSchemeCodeUnspsc190501.51314328'),
	'identifierSchemeCodeUnspsc190501.51314329': __('identifierSchemeCodeUnspsc190501.51314329'),
	'identifierSchemeCodeUnspsc190501.51314330': __('identifierSchemeCodeUnspsc190501.51314330'),
	'identifierSchemeCodeUnspsc190501.51314331': __('identifierSchemeCodeUnspsc190501.51314331'),
	'identifierSchemeCodeUnspsc190501.51314332': __('identifierSchemeCodeUnspsc190501.51314332'),
	'identifierSchemeCodeUnspsc190501.51314333': __('identifierSchemeCodeUnspsc190501.51314333'),
	'identifierSchemeCodeUnspsc190501.51314334': __('identifierSchemeCodeUnspsc190501.51314334'),
	'identifierSchemeCodeUnspsc190501.51314335': __('identifierSchemeCodeUnspsc190501.51314335'),
	'identifierSchemeCodeUnspsc190501.51314336': __('identifierSchemeCodeUnspsc190501.51314336'),
	'identifierSchemeCodeUnspsc190501.51314337': __('identifierSchemeCodeUnspsc190501.51314337'),
	'identifierSchemeCodeUnspsc190501.51314338': __('identifierSchemeCodeUnspsc190501.51314338'),
	'identifierSchemeCodeUnspsc190501.51314339': __('identifierSchemeCodeUnspsc190501.51314339'),
	'identifierSchemeCodeUnspsc190501.51314340': __('identifierSchemeCodeUnspsc190501.51314340'),
	'identifierSchemeCodeUnspsc190501.51314341': __('identifierSchemeCodeUnspsc190501.51314341'),
	'identifierSchemeCodeUnspsc190501.51314342': __('identifierSchemeCodeUnspsc190501.51314342'),
	'identifierSchemeCodeUnspsc190501.51314343': __('identifierSchemeCodeUnspsc190501.51314343'),
	'identifierSchemeCodeUnspsc190501.51314345': __('identifierSchemeCodeUnspsc190501.51314345'),
	'identifierSchemeCodeUnspsc190501.51314346': __('identifierSchemeCodeUnspsc190501.51314346'),
	'identifierSchemeCodeUnspsc190501.51314347': __('identifierSchemeCodeUnspsc190501.51314347'),
	'identifierSchemeCodeUnspsc190501.51314348': __('identifierSchemeCodeUnspsc190501.51314348'),
	'identifierSchemeCodeUnspsc190501.51314349': __('identifierSchemeCodeUnspsc190501.51314349'),
	'identifierSchemeCodeUnspsc190501.51314350': __('identifierSchemeCodeUnspsc190501.51314350'),
	'identifierSchemeCodeUnspsc190501.51314351': __('identifierSchemeCodeUnspsc190501.51314351'),
	'identifierSchemeCodeUnspsc190501.51314352': __('identifierSchemeCodeUnspsc190501.51314352'),
	'identifierSchemeCodeUnspsc190501.51314353': __('identifierSchemeCodeUnspsc190501.51314353'),
	'identifierSchemeCodeUnspsc190501.51314354': __('identifierSchemeCodeUnspsc190501.51314354'),
	'identifierSchemeCodeUnspsc190501.51314355': __('identifierSchemeCodeUnspsc190501.51314355'),
	'identifierSchemeCodeUnspsc190501.51314356': __('identifierSchemeCodeUnspsc190501.51314356'),
	'identifierSchemeCodeUnspsc190501.51314357': __('identifierSchemeCodeUnspsc190501.51314357'),
	'identifierSchemeCodeUnspsc190501.51314358': __('identifierSchemeCodeUnspsc190501.51314358'),
	'identifierSchemeCodeUnspsc190501.51314359': __('identifierSchemeCodeUnspsc190501.51314359'),
	'identifierSchemeCodeUnspsc190501.51314360': __('identifierSchemeCodeUnspsc190501.51314360'),
	'identifierSchemeCodeUnspsc190501.51314361': __('identifierSchemeCodeUnspsc190501.51314361'),
	'identifierSchemeCodeUnspsc190501.51314362': __('identifierSchemeCodeUnspsc190501.51314362'),
	'identifierSchemeCodeUnspsc190501.51314363': __('identifierSchemeCodeUnspsc190501.51314363'),
	'identifierSchemeCodeUnspsc190501.51314364': __('identifierSchemeCodeUnspsc190501.51314364'),
	'identifierSchemeCodeUnspsc190501.51314365': __('identifierSchemeCodeUnspsc190501.51314365'),
	'identifierSchemeCodeUnspsc190501.51314366': __('identifierSchemeCodeUnspsc190501.51314366'),
	'identifierSchemeCodeUnspsc190501.51314367': __('identifierSchemeCodeUnspsc190501.51314367'),
	'identifierSchemeCodeUnspsc190501.51314368': __('identifierSchemeCodeUnspsc190501.51314368'),
	'identifierSchemeCodeUnspsc190501.51314369': __('identifierSchemeCodeUnspsc190501.51314369'),
	'identifierSchemeCodeUnspsc190501.51314370': __('identifierSchemeCodeUnspsc190501.51314370'),
	'identifierSchemeCodeUnspsc190501.51314371': __('identifierSchemeCodeUnspsc190501.51314371'),
	'identifierSchemeCodeUnspsc190501.51314372': __('identifierSchemeCodeUnspsc190501.51314372'),
	'identifierSchemeCodeUnspsc190501.51314373': __('identifierSchemeCodeUnspsc190501.51314373'),
	'identifierSchemeCodeUnspsc190501.51314374': __('identifierSchemeCodeUnspsc190501.51314374'),
	'identifierSchemeCodeUnspsc190501.51314375': __('identifierSchemeCodeUnspsc190501.51314375'),
	'identifierSchemeCodeUnspsc190501.51314376': __('identifierSchemeCodeUnspsc190501.51314376'),
	'identifierSchemeCodeUnspsc190501.51314377': __('identifierSchemeCodeUnspsc190501.51314377'),
	'identifierSchemeCodeUnspsc190501.51314378': __('identifierSchemeCodeUnspsc190501.51314378'),
	'identifierSchemeCodeUnspsc190501.51314379': __('identifierSchemeCodeUnspsc190501.51314379'),
	'identifierSchemeCodeUnspsc190501.51314380': __('identifierSchemeCodeUnspsc190501.51314380'),
	'identifierSchemeCodeUnspsc190501.51314381': __('identifierSchemeCodeUnspsc190501.51314381'),
	'identifierSchemeCodeUnspsc190501.51314382': __('identifierSchemeCodeUnspsc190501.51314382'),
	'identifierSchemeCodeUnspsc190501.51314383': __('identifierSchemeCodeUnspsc190501.51314383'),
	'identifierSchemeCodeUnspsc190501.51314384': __('identifierSchemeCodeUnspsc190501.51314384'),
	'identifierSchemeCodeUnspsc190501.51314385': __('identifierSchemeCodeUnspsc190501.51314385'),
	'identifierSchemeCodeUnspsc190501.51314386': __('identifierSchemeCodeUnspsc190501.51314386'),
	'identifierSchemeCodeUnspsc190501.51314387': __('identifierSchemeCodeUnspsc190501.51314387'),
	'identifierSchemeCodeUnspsc190501.51314388': __('identifierSchemeCodeUnspsc190501.51314388'),
	'identifierSchemeCodeUnspsc190501.51314389': __('identifierSchemeCodeUnspsc190501.51314389'),
	'identifierSchemeCodeUnspsc190501.51314390': __('identifierSchemeCodeUnspsc190501.51314390'),
	'identifierSchemeCodeUnspsc190501.51314391': __('identifierSchemeCodeUnspsc190501.51314391'),
	'identifierSchemeCodeUnspsc190501.51320000': __('identifierSchemeCodeUnspsc190501.51320000'),
	'identifierSchemeCodeUnspsc190501.51321500': __('identifierSchemeCodeUnspsc190501.51321500'),
	'identifierSchemeCodeUnspsc190501.51321501': __('identifierSchemeCodeUnspsc190501.51321501'),
	'identifierSchemeCodeUnspsc190501.51321502': __('identifierSchemeCodeUnspsc190501.51321502'),
	'identifierSchemeCodeUnspsc190501.51321600': __('identifierSchemeCodeUnspsc190501.51321600'),
	'identifierSchemeCodeUnspsc190501.51321601': __('identifierSchemeCodeUnspsc190501.51321601'),
	'identifierSchemeCodeUnspsc190501.51321602': __('identifierSchemeCodeUnspsc190501.51321602'),
	'identifierSchemeCodeUnspsc190501.51321603': __('identifierSchemeCodeUnspsc190501.51321603'),
	'identifierSchemeCodeUnspsc190501.51321604': __('identifierSchemeCodeUnspsc190501.51321604'),
	'identifierSchemeCodeUnspsc190501.51321605': __('identifierSchemeCodeUnspsc190501.51321605'),
	'identifierSchemeCodeUnspsc190501.51321606': __('identifierSchemeCodeUnspsc190501.51321606'),
	'identifierSchemeCodeUnspsc190501.51321607': __('identifierSchemeCodeUnspsc190501.51321607'),
	'identifierSchemeCodeUnspsc190501.51321700': __('identifierSchemeCodeUnspsc190501.51321700'),
	'identifierSchemeCodeUnspsc190501.51321701': __('identifierSchemeCodeUnspsc190501.51321701'),
	'identifierSchemeCodeUnspsc190501.51321702': __('identifierSchemeCodeUnspsc190501.51321702'),
	'identifierSchemeCodeUnspsc190501.51321703': __('identifierSchemeCodeUnspsc190501.51321703'),
	'identifierSchemeCodeUnspsc190501.51321704': __('identifierSchemeCodeUnspsc190501.51321704'),
	'identifierSchemeCodeUnspsc190501.51321705': __('identifierSchemeCodeUnspsc190501.51321705'),
	'identifierSchemeCodeUnspsc190501.51321706': __('identifierSchemeCodeUnspsc190501.51321706'),
	'identifierSchemeCodeUnspsc190501.51321707': __('identifierSchemeCodeUnspsc190501.51321707'),
	'identifierSchemeCodeUnspsc190501.51321708': __('identifierSchemeCodeUnspsc190501.51321708'),
	'identifierSchemeCodeUnspsc190501.51321709': __('identifierSchemeCodeUnspsc190501.51321709'),
	'identifierSchemeCodeUnspsc190501.51321710': __('identifierSchemeCodeUnspsc190501.51321710'),
	'identifierSchemeCodeUnspsc190501.51321711': __('identifierSchemeCodeUnspsc190501.51321711'),
	'identifierSchemeCodeUnspsc190501.51321712': __('identifierSchemeCodeUnspsc190501.51321712'),
	'identifierSchemeCodeUnspsc190501.51321713': __('identifierSchemeCodeUnspsc190501.51321713'),
	'identifierSchemeCodeUnspsc190501.51321714': __('identifierSchemeCodeUnspsc190501.51321714'),
	'identifierSchemeCodeUnspsc190501.51321800': __('identifierSchemeCodeUnspsc190501.51321800'),
	'identifierSchemeCodeUnspsc190501.51321801': __('identifierSchemeCodeUnspsc190501.51321801'),
	'identifierSchemeCodeUnspsc190501.51321802': __('identifierSchemeCodeUnspsc190501.51321802'),
	'identifierSchemeCodeUnspsc190501.51321803': __('identifierSchemeCodeUnspsc190501.51321803'),
	'identifierSchemeCodeUnspsc190501.51321804': __('identifierSchemeCodeUnspsc190501.51321804'),
	'identifierSchemeCodeUnspsc190501.51321805': __('identifierSchemeCodeUnspsc190501.51321805'),
	'identifierSchemeCodeUnspsc190501.51321806': __('identifierSchemeCodeUnspsc190501.51321806'),
	'identifierSchemeCodeUnspsc190501.51321807': __('identifierSchemeCodeUnspsc190501.51321807'),
	'identifierSchemeCodeUnspsc190501.51321808': __('identifierSchemeCodeUnspsc190501.51321808'),
	'identifierSchemeCodeUnspsc190501.51321809': __('identifierSchemeCodeUnspsc190501.51321809'),
	'identifierSchemeCodeUnspsc190501.51321810': __('identifierSchemeCodeUnspsc190501.51321810'),
	'identifierSchemeCodeUnspsc190501.51321811': __('identifierSchemeCodeUnspsc190501.51321811'),
	'identifierSchemeCodeUnspsc190501.51321812': __('identifierSchemeCodeUnspsc190501.51321812'),
	'identifierSchemeCodeUnspsc190501.51321813': __('identifierSchemeCodeUnspsc190501.51321813'),
	'identifierSchemeCodeUnspsc190501.51321814': __('identifierSchemeCodeUnspsc190501.51321814'),
	'identifierSchemeCodeUnspsc190501.51321815': __('identifierSchemeCodeUnspsc190501.51321815'),
	'identifierSchemeCodeUnspsc190501.51321816': __('identifierSchemeCodeUnspsc190501.51321816'),
	'identifierSchemeCodeUnspsc190501.51321817': __('identifierSchemeCodeUnspsc190501.51321817'),
	'identifierSchemeCodeUnspsc190501.51321900': __('identifierSchemeCodeUnspsc190501.51321900'),
	'identifierSchemeCodeUnspsc190501.51321901': __('identifierSchemeCodeUnspsc190501.51321901'),
	'identifierSchemeCodeUnspsc190501.51321902': __('identifierSchemeCodeUnspsc190501.51321902'),
	'identifierSchemeCodeUnspsc190501.51321903': __('identifierSchemeCodeUnspsc190501.51321903'),
	'identifierSchemeCodeUnspsc190501.51322000': __('identifierSchemeCodeUnspsc190501.51322000'),
	'identifierSchemeCodeUnspsc190501.51322001': __('identifierSchemeCodeUnspsc190501.51322001'),
	'identifierSchemeCodeUnspsc190501.51322003': __('identifierSchemeCodeUnspsc190501.51322003'),
	'identifierSchemeCodeUnspsc190501.51322004': __('identifierSchemeCodeUnspsc190501.51322004'),
	'identifierSchemeCodeUnspsc190501.51322005': __('identifierSchemeCodeUnspsc190501.51322005'),
	'identifierSchemeCodeUnspsc190501.51322006': __('identifierSchemeCodeUnspsc190501.51322006'),
	'identifierSchemeCodeUnspsc190501.51322007': __('identifierSchemeCodeUnspsc190501.51322007'),
	'identifierSchemeCodeUnspsc190501.51322100': __('identifierSchemeCodeUnspsc190501.51322100'),
	'identifierSchemeCodeUnspsc190501.51322101': __('identifierSchemeCodeUnspsc190501.51322101'),
	'identifierSchemeCodeUnspsc190501.51322102': __('identifierSchemeCodeUnspsc190501.51322102'),
	'identifierSchemeCodeUnspsc190501.51322200': __('identifierSchemeCodeUnspsc190501.51322200'),
	'identifierSchemeCodeUnspsc190501.51322201': __('identifierSchemeCodeUnspsc190501.51322201'),
	'identifierSchemeCodeUnspsc190501.51322202': __('identifierSchemeCodeUnspsc190501.51322202'),
	'identifierSchemeCodeUnspsc190501.51322203': __('identifierSchemeCodeUnspsc190501.51322203'),
	'identifierSchemeCodeUnspsc190501.51322204': __('identifierSchemeCodeUnspsc190501.51322204'),
	'identifierSchemeCodeUnspsc190501.51322205': __('identifierSchemeCodeUnspsc190501.51322205'),
	'identifierSchemeCodeUnspsc190501.51322206': __('identifierSchemeCodeUnspsc190501.51322206'),
	'identifierSchemeCodeUnspsc190501.51322300': __('identifierSchemeCodeUnspsc190501.51322300'),
	'identifierSchemeCodeUnspsc190501.51322301': __('identifierSchemeCodeUnspsc190501.51322301'),
	'identifierSchemeCodeUnspsc190501.51322302': __('identifierSchemeCodeUnspsc190501.51322302'),
	'identifierSchemeCodeUnspsc190501.51322400': __('identifierSchemeCodeUnspsc190501.51322400'),
	'identifierSchemeCodeUnspsc190501.51322401': __('identifierSchemeCodeUnspsc190501.51322401'),
	'identifierSchemeCodeUnspsc190501.51322402': __('identifierSchemeCodeUnspsc190501.51322402'),
	'identifierSchemeCodeUnspsc190501.51322403': __('identifierSchemeCodeUnspsc190501.51322403'),
	'identifierSchemeCodeUnspsc190501.51322404': __('identifierSchemeCodeUnspsc190501.51322404'),
	'identifierSchemeCodeUnspsc190501.51322405': __('identifierSchemeCodeUnspsc190501.51322405'),
	'identifierSchemeCodeUnspsc190501.51322500': __('identifierSchemeCodeUnspsc190501.51322500'),
	'identifierSchemeCodeUnspsc190501.51322501': __('identifierSchemeCodeUnspsc190501.51322501'),
	'identifierSchemeCodeUnspsc190501.51322502': __('identifierSchemeCodeUnspsc190501.51322502'),
	'identifierSchemeCodeUnspsc190501.51322503': __('identifierSchemeCodeUnspsc190501.51322503'),
	'identifierSchemeCodeUnspsc190501.51322504': __('identifierSchemeCodeUnspsc190501.51322504'),
	'identifierSchemeCodeUnspsc190501.51322600': __('identifierSchemeCodeUnspsc190501.51322600'),
	'identifierSchemeCodeUnspsc190501.51322601': __('identifierSchemeCodeUnspsc190501.51322601'),
	'identifierSchemeCodeUnspsc190501.51322602': __('identifierSchemeCodeUnspsc190501.51322602'),
	'identifierSchemeCodeUnspsc190501.51322603': __('identifierSchemeCodeUnspsc190501.51322603'),
	'identifierSchemeCodeUnspsc190501.51322604': __('identifierSchemeCodeUnspsc190501.51322604'),
	'identifierSchemeCodeUnspsc190501.51322700': __('identifierSchemeCodeUnspsc190501.51322700'),
	'identifierSchemeCodeUnspsc190501.51322701': __('identifierSchemeCodeUnspsc190501.51322701'),
	'identifierSchemeCodeUnspsc190501.51322702': __('identifierSchemeCodeUnspsc190501.51322702'),
	'identifierSchemeCodeUnspsc190501.51322703': __('identifierSchemeCodeUnspsc190501.51322703'),
	'identifierSchemeCodeUnspsc190501.51322704': __('identifierSchemeCodeUnspsc190501.51322704'),
	'identifierSchemeCodeUnspsc190501.51322705': __('identifierSchemeCodeUnspsc190501.51322705'),
	'identifierSchemeCodeUnspsc190501.51322800': __('identifierSchemeCodeUnspsc190501.51322800'),
	'identifierSchemeCodeUnspsc190501.51322801': __('identifierSchemeCodeUnspsc190501.51322801'),
	'identifierSchemeCodeUnspsc190501.51322802': __('identifierSchemeCodeUnspsc190501.51322802'),
	'identifierSchemeCodeUnspsc190501.51322803': __('identifierSchemeCodeUnspsc190501.51322803'),
	'identifierSchemeCodeUnspsc190501.51322804': __('identifierSchemeCodeUnspsc190501.51322804'),
	'identifierSchemeCodeUnspsc190501.51322900': __('identifierSchemeCodeUnspsc190501.51322900'),
	'identifierSchemeCodeUnspsc190501.51322901': __('identifierSchemeCodeUnspsc190501.51322901'),
	'identifierSchemeCodeUnspsc190501.51322902': __('identifierSchemeCodeUnspsc190501.51322902'),
	'identifierSchemeCodeUnspsc190501.51322903': __('identifierSchemeCodeUnspsc190501.51322903'),
	'identifierSchemeCodeUnspsc190501.51322904': __('identifierSchemeCodeUnspsc190501.51322904'),
	'identifierSchemeCodeUnspsc190501.51323000': __('identifierSchemeCodeUnspsc190501.51323000'),
	'identifierSchemeCodeUnspsc190501.51323001': __('identifierSchemeCodeUnspsc190501.51323001'),
	'identifierSchemeCodeUnspsc190501.51323002': __('identifierSchemeCodeUnspsc190501.51323002'),
	'identifierSchemeCodeUnspsc190501.51323003': __('identifierSchemeCodeUnspsc190501.51323003'),
	'identifierSchemeCodeUnspsc190501.51323004': __('identifierSchemeCodeUnspsc190501.51323004'),
	'identifierSchemeCodeUnspsc190501.51323005': __('identifierSchemeCodeUnspsc190501.51323005'),
	'identifierSchemeCodeUnspsc190501.51323006': __('identifierSchemeCodeUnspsc190501.51323006'),
	'identifierSchemeCodeUnspsc190501.51323007': __('identifierSchemeCodeUnspsc190501.51323007'),
	'identifierSchemeCodeUnspsc190501.51323008': __('identifierSchemeCodeUnspsc190501.51323008'),
	'identifierSchemeCodeUnspsc190501.51323100': __('identifierSchemeCodeUnspsc190501.51323100'),
	'identifierSchemeCodeUnspsc190501.51323101': __('identifierSchemeCodeUnspsc190501.51323101'),
	'identifierSchemeCodeUnspsc190501.51323102': __('identifierSchemeCodeUnspsc190501.51323102'),
	'identifierSchemeCodeUnspsc190501.51323103': __('identifierSchemeCodeUnspsc190501.51323103'),
	'identifierSchemeCodeUnspsc190501.51323104': __('identifierSchemeCodeUnspsc190501.51323104'),
	'identifierSchemeCodeUnspsc190501.51323105': __('identifierSchemeCodeUnspsc190501.51323105'),
	'identifierSchemeCodeUnspsc190501.51323106': __('identifierSchemeCodeUnspsc190501.51323106'),
	'identifierSchemeCodeUnspsc190501.51323107': __('identifierSchemeCodeUnspsc190501.51323107'),
	'identifierSchemeCodeUnspsc190501.51323108': __('identifierSchemeCodeUnspsc190501.51323108'),
	'identifierSchemeCodeUnspsc190501.51323109': __('identifierSchemeCodeUnspsc190501.51323109'),
	'identifierSchemeCodeUnspsc190501.51323200': __('identifierSchemeCodeUnspsc190501.51323200'),
	'identifierSchemeCodeUnspsc190501.51323201': __('identifierSchemeCodeUnspsc190501.51323201'),
	'identifierSchemeCodeUnspsc190501.51323202': __('identifierSchemeCodeUnspsc190501.51323202'),
	'identifierSchemeCodeUnspsc190501.51323203': __('identifierSchemeCodeUnspsc190501.51323203'),
	'identifierSchemeCodeUnspsc190501.51323204': __('identifierSchemeCodeUnspsc190501.51323204'),
	'identifierSchemeCodeUnspsc190501.51323205': __('identifierSchemeCodeUnspsc190501.51323205'),
	'identifierSchemeCodeUnspsc190501.51330000': __('identifierSchemeCodeUnspsc190501.51330000'),
	'identifierSchemeCodeUnspsc190501.51331500': __('identifierSchemeCodeUnspsc190501.51331500'),
	'identifierSchemeCodeUnspsc190501.51331501': __('identifierSchemeCodeUnspsc190501.51331501'),
	'identifierSchemeCodeUnspsc190501.51331502': __('identifierSchemeCodeUnspsc190501.51331502'),
	'identifierSchemeCodeUnspsc190501.51331600': __('identifierSchemeCodeUnspsc190501.51331600'),
	'identifierSchemeCodeUnspsc190501.51331601': __('identifierSchemeCodeUnspsc190501.51331601'),
	'identifierSchemeCodeUnspsc190501.51331602': __('identifierSchemeCodeUnspsc190501.51331602'),
	'identifierSchemeCodeUnspsc190501.51331603': __('identifierSchemeCodeUnspsc190501.51331603'),
	'identifierSchemeCodeUnspsc190501.51331604': __('identifierSchemeCodeUnspsc190501.51331604'),
	'identifierSchemeCodeUnspsc190501.51331605': __('identifierSchemeCodeUnspsc190501.51331605'),
	'identifierSchemeCodeUnspsc190501.51331606': __('identifierSchemeCodeUnspsc190501.51331606'),
	'identifierSchemeCodeUnspsc190501.51331607': __('identifierSchemeCodeUnspsc190501.51331607'),
	'identifierSchemeCodeUnspsc190501.51331700': __('identifierSchemeCodeUnspsc190501.51331700'),
	'identifierSchemeCodeUnspsc190501.51331701': __('identifierSchemeCodeUnspsc190501.51331701'),
	'identifierSchemeCodeUnspsc190501.51331702': __('identifierSchemeCodeUnspsc190501.51331702'),
	'identifierSchemeCodeUnspsc190501.51331703': __('identifierSchemeCodeUnspsc190501.51331703'),
	'identifierSchemeCodeUnspsc190501.51331704': __('identifierSchemeCodeUnspsc190501.51331704'),
	'identifierSchemeCodeUnspsc190501.51331705': __('identifierSchemeCodeUnspsc190501.51331705'),
	'identifierSchemeCodeUnspsc190501.51331706': __('identifierSchemeCodeUnspsc190501.51331706'),
	'identifierSchemeCodeUnspsc190501.51331707': __('identifierSchemeCodeUnspsc190501.51331707'),
	'identifierSchemeCodeUnspsc190501.51331900': __('identifierSchemeCodeUnspsc190501.51331900'),
	'identifierSchemeCodeUnspsc190501.51331901': __('identifierSchemeCodeUnspsc190501.51331901'),
	'identifierSchemeCodeUnspsc190501.51331902': __('identifierSchemeCodeUnspsc190501.51331902'),
	'identifierSchemeCodeUnspsc190501.51332000': __('identifierSchemeCodeUnspsc190501.51332000'),
	'identifierSchemeCodeUnspsc190501.51332001': __('identifierSchemeCodeUnspsc190501.51332001'),
	'identifierSchemeCodeUnspsc190501.51332002': __('identifierSchemeCodeUnspsc190501.51332002'),
	'identifierSchemeCodeUnspsc190501.51332003': __('identifierSchemeCodeUnspsc190501.51332003'),
	'identifierSchemeCodeUnspsc190501.51332004': __('identifierSchemeCodeUnspsc190501.51332004'),
	'identifierSchemeCodeUnspsc190501.51332005': __('identifierSchemeCodeUnspsc190501.51332005'),
	'identifierSchemeCodeUnspsc190501.51332006': __('identifierSchemeCodeUnspsc190501.51332006'),
	'identifierSchemeCodeUnspsc190501.51332100': __('identifierSchemeCodeUnspsc190501.51332100'),
	'identifierSchemeCodeUnspsc190501.51332101': __('identifierSchemeCodeUnspsc190501.51332101'),
	'identifierSchemeCodeUnspsc190501.51332102': __('identifierSchemeCodeUnspsc190501.51332102'),
	'identifierSchemeCodeUnspsc190501.51332103': __('identifierSchemeCodeUnspsc190501.51332103'),
	'identifierSchemeCodeUnspsc190501.51332104': __('identifierSchemeCodeUnspsc190501.51332104'),
	'identifierSchemeCodeUnspsc190501.51332200': __('identifierSchemeCodeUnspsc190501.51332200'),
	'identifierSchemeCodeUnspsc190501.51332201': __('identifierSchemeCodeUnspsc190501.51332201'),
	'identifierSchemeCodeUnspsc190501.51332202': __('identifierSchemeCodeUnspsc190501.51332202'),
	'identifierSchemeCodeUnspsc190501.51332203': __('identifierSchemeCodeUnspsc190501.51332203'),
	'identifierSchemeCodeUnspsc190501.51332204': __('identifierSchemeCodeUnspsc190501.51332204'),
	'identifierSchemeCodeUnspsc190501.51332205': __('identifierSchemeCodeUnspsc190501.51332205'),
	'identifierSchemeCodeUnspsc190501.51332206': __('identifierSchemeCodeUnspsc190501.51332206'),
	'identifierSchemeCodeUnspsc190501.51332207': __('identifierSchemeCodeUnspsc190501.51332207'),
	'identifierSchemeCodeUnspsc190501.51332300': __('identifierSchemeCodeUnspsc190501.51332300'),
	'identifierSchemeCodeUnspsc190501.51332301': __('identifierSchemeCodeUnspsc190501.51332301'),
	'identifierSchemeCodeUnspsc190501.51332302': __('identifierSchemeCodeUnspsc190501.51332302'),
	'identifierSchemeCodeUnspsc190501.51332303': __('identifierSchemeCodeUnspsc190501.51332303'),
	'identifierSchemeCodeUnspsc190501.51332304': __('identifierSchemeCodeUnspsc190501.51332304'),
	'identifierSchemeCodeUnspsc190501.51332305': __('identifierSchemeCodeUnspsc190501.51332305'),
	'identifierSchemeCodeUnspsc190501.51332306': __('identifierSchemeCodeUnspsc190501.51332306'),
	'identifierSchemeCodeUnspsc190501.51332400': __('identifierSchemeCodeUnspsc190501.51332400'),
	'identifierSchemeCodeUnspsc190501.51332401': __('identifierSchemeCodeUnspsc190501.51332401'),
	'identifierSchemeCodeUnspsc190501.51332402': __('identifierSchemeCodeUnspsc190501.51332402'),
	'identifierSchemeCodeUnspsc190501.51332500': __('identifierSchemeCodeUnspsc190501.51332500'),
	'identifierSchemeCodeUnspsc190501.51332501': __('identifierSchemeCodeUnspsc190501.51332501'),
	'identifierSchemeCodeUnspsc190501.51332502': __('identifierSchemeCodeUnspsc190501.51332502'),
	'identifierSchemeCodeUnspsc190501.51332503': __('identifierSchemeCodeUnspsc190501.51332503'),
	'identifierSchemeCodeUnspsc190501.51332504': __('identifierSchemeCodeUnspsc190501.51332504'),
	'identifierSchemeCodeUnspsc190501.51332505': __('identifierSchemeCodeUnspsc190501.51332505'),
	'identifierSchemeCodeUnspsc190501.51332506': __('identifierSchemeCodeUnspsc190501.51332506'),
	'identifierSchemeCodeUnspsc190501.51332507': __('identifierSchemeCodeUnspsc190501.51332507'),
	'identifierSchemeCodeUnspsc190501.51332508': __('identifierSchemeCodeUnspsc190501.51332508'),
	'identifierSchemeCodeUnspsc190501.51332509': __('identifierSchemeCodeUnspsc190501.51332509'),
	'identifierSchemeCodeUnspsc190501.51332510': __('identifierSchemeCodeUnspsc190501.51332510'),
	'identifierSchemeCodeUnspsc190501.51332511': __('identifierSchemeCodeUnspsc190501.51332511'),
	'identifierSchemeCodeUnspsc190501.51332512': __('identifierSchemeCodeUnspsc190501.51332512'),
	'identifierSchemeCodeUnspsc190501.51332513': __('identifierSchemeCodeUnspsc190501.51332513'),
	'identifierSchemeCodeUnspsc190501.51332514': __('identifierSchemeCodeUnspsc190501.51332514'),
	'identifierSchemeCodeUnspsc190501.51332515': __('identifierSchemeCodeUnspsc190501.51332515'),
	'identifierSchemeCodeUnspsc190501.51332516': __('identifierSchemeCodeUnspsc190501.51332516'),
	'identifierSchemeCodeUnspsc190501.51332517': __('identifierSchemeCodeUnspsc190501.51332517'),
	'identifierSchemeCodeUnspsc190501.51332518': __('identifierSchemeCodeUnspsc190501.51332518'),
	'identifierSchemeCodeUnspsc190501.51332519': __('identifierSchemeCodeUnspsc190501.51332519'),
	'identifierSchemeCodeUnspsc190501.51332520': __('identifierSchemeCodeUnspsc190501.51332520'),
	'identifierSchemeCodeUnspsc190501.51332521': __('identifierSchemeCodeUnspsc190501.51332521'),
	'identifierSchemeCodeUnspsc190501.51332522': __('identifierSchemeCodeUnspsc190501.51332522'),
	'identifierSchemeCodeUnspsc190501.51332523': __('identifierSchemeCodeUnspsc190501.51332523'),
	'identifierSchemeCodeUnspsc190501.51332524': __('identifierSchemeCodeUnspsc190501.51332524'),
	'identifierSchemeCodeUnspsc190501.51332525': __('identifierSchemeCodeUnspsc190501.51332525'),
	'identifierSchemeCodeUnspsc190501.51332526': __('identifierSchemeCodeUnspsc190501.51332526'),
	'identifierSchemeCodeUnspsc190501.51332527': __('identifierSchemeCodeUnspsc190501.51332527'),
	'identifierSchemeCodeUnspsc190501.51332700': __('identifierSchemeCodeUnspsc190501.51332700'),
	'identifierSchemeCodeUnspsc190501.51332701': __('identifierSchemeCodeUnspsc190501.51332701'),
	'identifierSchemeCodeUnspsc190501.51332702': __('identifierSchemeCodeUnspsc190501.51332702'),
	'identifierSchemeCodeUnspsc190501.51332703': __('identifierSchemeCodeUnspsc190501.51332703'),
	'identifierSchemeCodeUnspsc190501.51332704': __('identifierSchemeCodeUnspsc190501.51332704'),
	'identifierSchemeCodeUnspsc190501.51332705': __('identifierSchemeCodeUnspsc190501.51332705'),
	'identifierSchemeCodeUnspsc190501.51332706': __('identifierSchemeCodeUnspsc190501.51332706'),
	'identifierSchemeCodeUnspsc190501.51332800': __('identifierSchemeCodeUnspsc190501.51332800'),
	'identifierSchemeCodeUnspsc190501.51332801': __('identifierSchemeCodeUnspsc190501.51332801'),
	'identifierSchemeCodeUnspsc190501.51332802': __('identifierSchemeCodeUnspsc190501.51332802'),
	'identifierSchemeCodeUnspsc190501.51332803': __('identifierSchemeCodeUnspsc190501.51332803'),
	'identifierSchemeCodeUnspsc190501.51332804': __('identifierSchemeCodeUnspsc190501.51332804'),
	'identifierSchemeCodeUnspsc190501.51332805': __('identifierSchemeCodeUnspsc190501.51332805'),
	'identifierSchemeCodeUnspsc190501.51332806': __('identifierSchemeCodeUnspsc190501.51332806'),
	'identifierSchemeCodeUnspsc190501.51332807': __('identifierSchemeCodeUnspsc190501.51332807'),
	'identifierSchemeCodeUnspsc190501.51332808': __('identifierSchemeCodeUnspsc190501.51332808'),
	'identifierSchemeCodeUnspsc190501.51332809': __('identifierSchemeCodeUnspsc190501.51332809'),
	'identifierSchemeCodeUnspsc190501.51332810': __('identifierSchemeCodeUnspsc190501.51332810'),
	'identifierSchemeCodeUnspsc190501.51332811': __('identifierSchemeCodeUnspsc190501.51332811'),
	'identifierSchemeCodeUnspsc190501.51332812': __('identifierSchemeCodeUnspsc190501.51332812'),
	'identifierSchemeCodeUnspsc190501.51332813': __('identifierSchemeCodeUnspsc190501.51332813'),
	'identifierSchemeCodeUnspsc190501.51332814': __('identifierSchemeCodeUnspsc190501.51332814'),
	'identifierSchemeCodeUnspsc190501.51332815': __('identifierSchemeCodeUnspsc190501.51332815'),
	'identifierSchemeCodeUnspsc190501.51332816': __('identifierSchemeCodeUnspsc190501.51332816'),
	'identifierSchemeCodeUnspsc190501.51332817': __('identifierSchemeCodeUnspsc190501.51332817'),
	'identifierSchemeCodeUnspsc190501.51332818': __('identifierSchemeCodeUnspsc190501.51332818'),
	'identifierSchemeCodeUnspsc190501.51332819': __('identifierSchemeCodeUnspsc190501.51332819'),
	'identifierSchemeCodeUnspsc190501.51332820': __('identifierSchemeCodeUnspsc190501.51332820'),
	'identifierSchemeCodeUnspsc190501.51332821': __('identifierSchemeCodeUnspsc190501.51332821'),
	'identifierSchemeCodeUnspsc190501.51332822': __('identifierSchemeCodeUnspsc190501.51332822'),
	'identifierSchemeCodeUnspsc190501.51332823': __('identifierSchemeCodeUnspsc190501.51332823'),
	'identifierSchemeCodeUnspsc190501.51332824': __('identifierSchemeCodeUnspsc190501.51332824'),
	'identifierSchemeCodeUnspsc190501.51332825': __('identifierSchemeCodeUnspsc190501.51332825'),
	'identifierSchemeCodeUnspsc190501.51332826': __('identifierSchemeCodeUnspsc190501.51332826'),
	'identifierSchemeCodeUnspsc190501.51332827': __('identifierSchemeCodeUnspsc190501.51332827'),
	'identifierSchemeCodeUnspsc190501.51332828': __('identifierSchemeCodeUnspsc190501.51332828'),
	'identifierSchemeCodeUnspsc190501.51332829': __('identifierSchemeCodeUnspsc190501.51332829'),
	'identifierSchemeCodeUnspsc190501.51332830': __('identifierSchemeCodeUnspsc190501.51332830'),
	'identifierSchemeCodeUnspsc190501.51332831': __('identifierSchemeCodeUnspsc190501.51332831'),
	'identifierSchemeCodeUnspsc190501.51332832': __('identifierSchemeCodeUnspsc190501.51332832'),
	'identifierSchemeCodeUnspsc190501.51332833': __('identifierSchemeCodeUnspsc190501.51332833'),
	'identifierSchemeCodeUnspsc190501.51332834': __('identifierSchemeCodeUnspsc190501.51332834'),
	'identifierSchemeCodeUnspsc190501.51332835': __('identifierSchemeCodeUnspsc190501.51332835'),
	'identifierSchemeCodeUnspsc190501.51332836': __('identifierSchemeCodeUnspsc190501.51332836'),
	'identifierSchemeCodeUnspsc190501.51332837': __('identifierSchemeCodeUnspsc190501.51332837'),
	'identifierSchemeCodeUnspsc190501.51332838': __('identifierSchemeCodeUnspsc190501.51332838'),
	'identifierSchemeCodeUnspsc190501.51332839': __('identifierSchemeCodeUnspsc190501.51332839'),
	'identifierSchemeCodeUnspsc190501.51332840': __('identifierSchemeCodeUnspsc190501.51332840'),
	'identifierSchemeCodeUnspsc190501.51332841': __('identifierSchemeCodeUnspsc190501.51332841'),
	'identifierSchemeCodeUnspsc190501.51332842': __('identifierSchemeCodeUnspsc190501.51332842'),
	'identifierSchemeCodeUnspsc190501.51332843': __('identifierSchemeCodeUnspsc190501.51332843'),
	'identifierSchemeCodeUnspsc190501.51332844': __('identifierSchemeCodeUnspsc190501.51332844'),
	'identifierSchemeCodeUnspsc190501.51332845': __('identifierSchemeCodeUnspsc190501.51332845'),
	'identifierSchemeCodeUnspsc190501.51332846': __('identifierSchemeCodeUnspsc190501.51332846'),
	'identifierSchemeCodeUnspsc190501.51332847': __('identifierSchemeCodeUnspsc190501.51332847'),
	'identifierSchemeCodeUnspsc190501.51332848': __('identifierSchemeCodeUnspsc190501.51332848'),
	'identifierSchemeCodeUnspsc190501.51332849': __('identifierSchemeCodeUnspsc190501.51332849'),
	'identifierSchemeCodeUnspsc190501.51332850': __('identifierSchemeCodeUnspsc190501.51332850'),
	'identifierSchemeCodeUnspsc190501.51332851': __('identifierSchemeCodeUnspsc190501.51332851'),
	'identifierSchemeCodeUnspsc190501.51332852': __('identifierSchemeCodeUnspsc190501.51332852'),
	'identifierSchemeCodeUnspsc190501.51332853': __('identifierSchemeCodeUnspsc190501.51332853'),
	'identifierSchemeCodeUnspsc190501.51332854': __('identifierSchemeCodeUnspsc190501.51332854'),
	'identifierSchemeCodeUnspsc190501.51332855': __('identifierSchemeCodeUnspsc190501.51332855'),
	'identifierSchemeCodeUnspsc190501.51332856': __('identifierSchemeCodeUnspsc190501.51332856'),
	'identifierSchemeCodeUnspsc190501.51332857': __('identifierSchemeCodeUnspsc190501.51332857'),
	'identifierSchemeCodeUnspsc190501.51332858': __('identifierSchemeCodeUnspsc190501.51332858'),
	'identifierSchemeCodeUnspsc190501.51332859': __('identifierSchemeCodeUnspsc190501.51332859'),
	'identifierSchemeCodeUnspsc190501.51332860': __('identifierSchemeCodeUnspsc190501.51332860'),
	'identifierSchemeCodeUnspsc190501.51332861': __('identifierSchemeCodeUnspsc190501.51332861'),
	'identifierSchemeCodeUnspsc190501.51332862': __('identifierSchemeCodeUnspsc190501.51332862'),
	'identifierSchemeCodeUnspsc190501.51332863': __('identifierSchemeCodeUnspsc190501.51332863'),
	'identifierSchemeCodeUnspsc190501.51332864': __('identifierSchemeCodeUnspsc190501.51332864'),
	'identifierSchemeCodeUnspsc190501.51332865': __('identifierSchemeCodeUnspsc190501.51332865'),
	'identifierSchemeCodeUnspsc190501.51332866': __('identifierSchemeCodeUnspsc190501.51332866'),
	'identifierSchemeCodeUnspsc190501.51332867': __('identifierSchemeCodeUnspsc190501.51332867'),
	'identifierSchemeCodeUnspsc190501.51332868': __('identifierSchemeCodeUnspsc190501.51332868'),
	'identifierSchemeCodeUnspsc190501.51332869': __('identifierSchemeCodeUnspsc190501.51332869'),
	'identifierSchemeCodeUnspsc190501.51332870': __('identifierSchemeCodeUnspsc190501.51332870'),
	'identifierSchemeCodeUnspsc190501.51332871': __('identifierSchemeCodeUnspsc190501.51332871'),
	'identifierSchemeCodeUnspsc190501.51332872': __('identifierSchemeCodeUnspsc190501.51332872'),
	'identifierSchemeCodeUnspsc190501.51332873': __('identifierSchemeCodeUnspsc190501.51332873'),
	'identifierSchemeCodeUnspsc190501.51332874': __('identifierSchemeCodeUnspsc190501.51332874'),
	'identifierSchemeCodeUnspsc190501.51332875': __('identifierSchemeCodeUnspsc190501.51332875'),
	'identifierSchemeCodeUnspsc190501.51332876': __('identifierSchemeCodeUnspsc190501.51332876'),
	'identifierSchemeCodeUnspsc190501.51332877': __('identifierSchemeCodeUnspsc190501.51332877'),
	'identifierSchemeCodeUnspsc190501.51332878': __('identifierSchemeCodeUnspsc190501.51332878'),
	'identifierSchemeCodeUnspsc190501.51332879': __('identifierSchemeCodeUnspsc190501.51332879'),
	'identifierSchemeCodeUnspsc190501.51332880': __('identifierSchemeCodeUnspsc190501.51332880'),
	'identifierSchemeCodeUnspsc190501.51332881': __('identifierSchemeCodeUnspsc190501.51332881'),
	'identifierSchemeCodeUnspsc190501.51332882': __('identifierSchemeCodeUnspsc190501.51332882'),
	'identifierSchemeCodeUnspsc190501.51332883': __('identifierSchemeCodeUnspsc190501.51332883'),
	'identifierSchemeCodeUnspsc190501.51332900': __('identifierSchemeCodeUnspsc190501.51332900'),
	'identifierSchemeCodeUnspsc190501.51332901': __('identifierSchemeCodeUnspsc190501.51332901'),
	'identifierSchemeCodeUnspsc190501.51332902': __('identifierSchemeCodeUnspsc190501.51332902'),
	'identifierSchemeCodeUnspsc190501.51332903': __('identifierSchemeCodeUnspsc190501.51332903'),
	'identifierSchemeCodeUnspsc190501.51332904': __('identifierSchemeCodeUnspsc190501.51332904'),
	'identifierSchemeCodeUnspsc190501.51332905': __('identifierSchemeCodeUnspsc190501.51332905'),
	'identifierSchemeCodeUnspsc190501.51333000': __('identifierSchemeCodeUnspsc190501.51333000'),
	'identifierSchemeCodeUnspsc190501.51333001': __('identifierSchemeCodeUnspsc190501.51333001'),
	'identifierSchemeCodeUnspsc190501.51333002': __('identifierSchemeCodeUnspsc190501.51333002'),
	'identifierSchemeCodeUnspsc190501.51333003': __('identifierSchemeCodeUnspsc190501.51333003'),
	'identifierSchemeCodeUnspsc190501.51333004': __('identifierSchemeCodeUnspsc190501.51333004'),
	'identifierSchemeCodeUnspsc190501.51333005': __('identifierSchemeCodeUnspsc190501.51333005'),
	'identifierSchemeCodeUnspsc190501.51333006': __('identifierSchemeCodeUnspsc190501.51333006'),
	'identifierSchemeCodeUnspsc190501.51333100': __('identifierSchemeCodeUnspsc190501.51333100'),
	'identifierSchemeCodeUnspsc190501.51333101': __('identifierSchemeCodeUnspsc190501.51333101'),
	'identifierSchemeCodeUnspsc190501.51333102': __('identifierSchemeCodeUnspsc190501.51333102'),
	'identifierSchemeCodeUnspsc190501.51333103': __('identifierSchemeCodeUnspsc190501.51333103'),
	'identifierSchemeCodeUnspsc190501.51333104': __('identifierSchemeCodeUnspsc190501.51333104'),
	'identifierSchemeCodeUnspsc190501.51333105': __('identifierSchemeCodeUnspsc190501.51333105'),
	'identifierSchemeCodeUnspsc190501.51333106': __('identifierSchemeCodeUnspsc190501.51333106'),
	'identifierSchemeCodeUnspsc190501.51333107': __('identifierSchemeCodeUnspsc190501.51333107'),
	'identifierSchemeCodeUnspsc190501.51333108': __('identifierSchemeCodeUnspsc190501.51333108'),
	'identifierSchemeCodeUnspsc190501.51333200': __('identifierSchemeCodeUnspsc190501.51333200'),
	'identifierSchemeCodeUnspsc190501.51333201': __('identifierSchemeCodeUnspsc190501.51333201'),
	'identifierSchemeCodeUnspsc190501.51333202': __('identifierSchemeCodeUnspsc190501.51333202'),
	'identifierSchemeCodeUnspsc190501.51333400': __('identifierSchemeCodeUnspsc190501.51333400'),
	'identifierSchemeCodeUnspsc190501.51333401': __('identifierSchemeCodeUnspsc190501.51333401'),
	'identifierSchemeCodeUnspsc190501.51333402': __('identifierSchemeCodeUnspsc190501.51333402'),
	'identifierSchemeCodeUnspsc190501.51333403': __('identifierSchemeCodeUnspsc190501.51333403'),
	'identifierSchemeCodeUnspsc190501.51333404': __('identifierSchemeCodeUnspsc190501.51333404'),
	'identifierSchemeCodeUnspsc190501.51333405': __('identifierSchemeCodeUnspsc190501.51333405'),
	'identifierSchemeCodeUnspsc190501.51333406': __('identifierSchemeCodeUnspsc190501.51333406'),
	'identifierSchemeCodeUnspsc190501.51333407': __('identifierSchemeCodeUnspsc190501.51333407'),
	'identifierSchemeCodeUnspsc190501.51333408': __('identifierSchemeCodeUnspsc190501.51333408'),
	'identifierSchemeCodeUnspsc190501.51333409': __('identifierSchemeCodeUnspsc190501.51333409'),
	'identifierSchemeCodeUnspsc190501.51333410': __('identifierSchemeCodeUnspsc190501.51333410'),
	'identifierSchemeCodeUnspsc190501.51333411': __('identifierSchemeCodeUnspsc190501.51333411'),
	'identifierSchemeCodeUnspsc190501.51333412': __('identifierSchemeCodeUnspsc190501.51333412'),
	'identifierSchemeCodeUnspsc190501.51333413': __('identifierSchemeCodeUnspsc190501.51333413'),
	'identifierSchemeCodeUnspsc190501.51333414': __('identifierSchemeCodeUnspsc190501.51333414'),
	'identifierSchemeCodeUnspsc190501.51333415': __('identifierSchemeCodeUnspsc190501.51333415'),
	'identifierSchemeCodeUnspsc190501.51333416': __('identifierSchemeCodeUnspsc190501.51333416'),
	'identifierSchemeCodeUnspsc190501.51333500': __('identifierSchemeCodeUnspsc190501.51333500'),
	'identifierSchemeCodeUnspsc190501.51333501': __('identifierSchemeCodeUnspsc190501.51333501'),
	'identifierSchemeCodeUnspsc190501.51333502': __('identifierSchemeCodeUnspsc190501.51333502'),
	'identifierSchemeCodeUnspsc190501.51333503': __('identifierSchemeCodeUnspsc190501.51333503'),
	'identifierSchemeCodeUnspsc190501.51333504': __('identifierSchemeCodeUnspsc190501.51333504'),
	'identifierSchemeCodeUnspsc190501.51333505': __('identifierSchemeCodeUnspsc190501.51333505'),
	'identifierSchemeCodeUnspsc190501.51333506': __('identifierSchemeCodeUnspsc190501.51333506'),
	'identifierSchemeCodeUnspsc190501.51333507': __('identifierSchemeCodeUnspsc190501.51333507'),
	'identifierSchemeCodeUnspsc190501.51333508': __('identifierSchemeCodeUnspsc190501.51333508'),
	'identifierSchemeCodeUnspsc190501.51333509': __('identifierSchemeCodeUnspsc190501.51333509'),
	'identifierSchemeCodeUnspsc190501.51333510': __('identifierSchemeCodeUnspsc190501.51333510'),
	'identifierSchemeCodeUnspsc190501.51333511': __('identifierSchemeCodeUnspsc190501.51333511'),
	'identifierSchemeCodeUnspsc190501.51333512': __('identifierSchemeCodeUnspsc190501.51333512'),
	'identifierSchemeCodeUnspsc190501.51333513': __('identifierSchemeCodeUnspsc190501.51333513'),
	'identifierSchemeCodeUnspsc190501.51333514': __('identifierSchemeCodeUnspsc190501.51333514'),
	'identifierSchemeCodeUnspsc190501.51333600': __('identifierSchemeCodeUnspsc190501.51333600'),
	'identifierSchemeCodeUnspsc190501.51333601': __('identifierSchemeCodeUnspsc190501.51333601'),
	'identifierSchemeCodeUnspsc190501.51333602': __('identifierSchemeCodeUnspsc190501.51333602'),
	'identifierSchemeCodeUnspsc190501.51333700': __('identifierSchemeCodeUnspsc190501.51333700'),
	'identifierSchemeCodeUnspsc190501.51333701': __('identifierSchemeCodeUnspsc190501.51333701'),
	'identifierSchemeCodeUnspsc190501.51333702': __('identifierSchemeCodeUnspsc190501.51333702'),
	'identifierSchemeCodeUnspsc190501.51333703': __('identifierSchemeCodeUnspsc190501.51333703'),
	'identifierSchemeCodeUnspsc190501.51333704': __('identifierSchemeCodeUnspsc190501.51333704'),
	'identifierSchemeCodeUnspsc190501.51333705': __('identifierSchemeCodeUnspsc190501.51333705'),
	'identifierSchemeCodeUnspsc190501.51333706': __('identifierSchemeCodeUnspsc190501.51333706'),
	'identifierSchemeCodeUnspsc190501.51333707': __('identifierSchemeCodeUnspsc190501.51333707'),
	'identifierSchemeCodeUnspsc190501.51333900': __('identifierSchemeCodeUnspsc190501.51333900'),
	'identifierSchemeCodeUnspsc190501.51333901': __('identifierSchemeCodeUnspsc190501.51333901'),
	'identifierSchemeCodeUnspsc190501.51333902': __('identifierSchemeCodeUnspsc190501.51333902'),
	'identifierSchemeCodeUnspsc190501.51333903': __('identifierSchemeCodeUnspsc190501.51333903'),
	'identifierSchemeCodeUnspsc190501.51334000': __('identifierSchemeCodeUnspsc190501.51334000'),
	'identifierSchemeCodeUnspsc190501.51334001': __('identifierSchemeCodeUnspsc190501.51334001'),
	'identifierSchemeCodeUnspsc190501.51334002': __('identifierSchemeCodeUnspsc190501.51334002'),
	'identifierSchemeCodeUnspsc190501.51334003': __('identifierSchemeCodeUnspsc190501.51334003'),
	'identifierSchemeCodeUnspsc190501.51334004': __('identifierSchemeCodeUnspsc190501.51334004'),
	'identifierSchemeCodeUnspsc190501.51334005': __('identifierSchemeCodeUnspsc190501.51334005'),
	'identifierSchemeCodeUnspsc190501.51334100': __('identifierSchemeCodeUnspsc190501.51334100'),
	'identifierSchemeCodeUnspsc190501.51334101': __('identifierSchemeCodeUnspsc190501.51334101'),
	'identifierSchemeCodeUnspsc190501.51334102': __('identifierSchemeCodeUnspsc190501.51334102'),
	'identifierSchemeCodeUnspsc190501.51334103': __('identifierSchemeCodeUnspsc190501.51334103'),
	'identifierSchemeCodeUnspsc190501.51334104': __('identifierSchemeCodeUnspsc190501.51334104'),
	'identifierSchemeCodeUnspsc190501.51334105': __('identifierSchemeCodeUnspsc190501.51334105'),
	'identifierSchemeCodeUnspsc190501.51334106': __('identifierSchemeCodeUnspsc190501.51334106'),
	'identifierSchemeCodeUnspsc190501.51334200': __('identifierSchemeCodeUnspsc190501.51334200'),
	'identifierSchemeCodeUnspsc190501.51334201': __('identifierSchemeCodeUnspsc190501.51334201'),
	'identifierSchemeCodeUnspsc190501.51334202': __('identifierSchemeCodeUnspsc190501.51334202'),
	'identifierSchemeCodeUnspsc190501.51334203': __('identifierSchemeCodeUnspsc190501.51334203'),
	'identifierSchemeCodeUnspsc190501.51334204': __('identifierSchemeCodeUnspsc190501.51334204'),
	'identifierSchemeCodeUnspsc190501.51334205': __('identifierSchemeCodeUnspsc190501.51334205'),
	'identifierSchemeCodeUnspsc190501.51334206': __('identifierSchemeCodeUnspsc190501.51334206'),
	'identifierSchemeCodeUnspsc190501.51334207': __('identifierSchemeCodeUnspsc190501.51334207'),
	'identifierSchemeCodeUnspsc190501.51334208': __('identifierSchemeCodeUnspsc190501.51334208'),
	'identifierSchemeCodeUnspsc190501.51334300': __('identifierSchemeCodeUnspsc190501.51334300'),
	'identifierSchemeCodeUnspsc190501.51334301': __('identifierSchemeCodeUnspsc190501.51334301'),
	'identifierSchemeCodeUnspsc190501.51334302': __('identifierSchemeCodeUnspsc190501.51334302'),
	'identifierSchemeCodeUnspsc190501.51334303': __('identifierSchemeCodeUnspsc190501.51334303'),
	'identifierSchemeCodeUnspsc190501.51334304': __('identifierSchemeCodeUnspsc190501.51334304'),
	'identifierSchemeCodeUnspsc190501.51334305': __('identifierSchemeCodeUnspsc190501.51334305'),
	'identifierSchemeCodeUnspsc190501.51334306': __('identifierSchemeCodeUnspsc190501.51334306'),
	'identifierSchemeCodeUnspsc190501.51334307': __('identifierSchemeCodeUnspsc190501.51334307'),
	'identifierSchemeCodeUnspsc190501.51334308': __('identifierSchemeCodeUnspsc190501.51334308'),
	'identifierSchemeCodeUnspsc190501.51334309': __('identifierSchemeCodeUnspsc190501.51334309'),
	'identifierSchemeCodeUnspsc190501.51334310': __('identifierSchemeCodeUnspsc190501.51334310'),
	'identifierSchemeCodeUnspsc190501.51334311': __('identifierSchemeCodeUnspsc190501.51334311'),
	'identifierSchemeCodeUnspsc190501.51334312': __('identifierSchemeCodeUnspsc190501.51334312'),
	'identifierSchemeCodeUnspsc190501.51334313': __('identifierSchemeCodeUnspsc190501.51334313'),
	'identifierSchemeCodeUnspsc190501.51334314': __('identifierSchemeCodeUnspsc190501.51334314'),
	'identifierSchemeCodeUnspsc190501.51334315': __('identifierSchemeCodeUnspsc190501.51334315'),
	'identifierSchemeCodeUnspsc190501.51334316': __('identifierSchemeCodeUnspsc190501.51334316'),
	'identifierSchemeCodeUnspsc190501.51334400': __('identifierSchemeCodeUnspsc190501.51334400'),
	'identifierSchemeCodeUnspsc190501.51334401': __('identifierSchemeCodeUnspsc190501.51334401'),
	'identifierSchemeCodeUnspsc190501.51334402': __('identifierSchemeCodeUnspsc190501.51334402'),
	'identifierSchemeCodeUnspsc190501.51334403': __('identifierSchemeCodeUnspsc190501.51334403'),
	'identifierSchemeCodeUnspsc190501.51334404': __('identifierSchemeCodeUnspsc190501.51334404'),
	'identifierSchemeCodeUnspsc190501.51334405': __('identifierSchemeCodeUnspsc190501.51334405'),
	'identifierSchemeCodeUnspsc190501.51334406': __('identifierSchemeCodeUnspsc190501.51334406'),
	'identifierSchemeCodeUnspsc190501.51334407': __('identifierSchemeCodeUnspsc190501.51334407'),
	'identifierSchemeCodeUnspsc190501.51334408': __('identifierSchemeCodeUnspsc190501.51334408'),
	'identifierSchemeCodeUnspsc190501.51334409': __('identifierSchemeCodeUnspsc190501.51334409'),
	'identifierSchemeCodeUnspsc190501.51334410': __('identifierSchemeCodeUnspsc190501.51334410'),
	'identifierSchemeCodeUnspsc190501.51334411': __('identifierSchemeCodeUnspsc190501.51334411'),
	'identifierSchemeCodeUnspsc190501.51334412': __('identifierSchemeCodeUnspsc190501.51334412'),
	'identifierSchemeCodeUnspsc190501.51334413': __('identifierSchemeCodeUnspsc190501.51334413'),
	'identifierSchemeCodeUnspsc190501.51334414': __('identifierSchemeCodeUnspsc190501.51334414'),
	'identifierSchemeCodeUnspsc190501.51334415': __('identifierSchemeCodeUnspsc190501.51334415'),
	'identifierSchemeCodeUnspsc190501.51334416': __('identifierSchemeCodeUnspsc190501.51334416'),
	'identifierSchemeCodeUnspsc190501.51334417': __('identifierSchemeCodeUnspsc190501.51334417'),
	'identifierSchemeCodeUnspsc190501.51334418': __('identifierSchemeCodeUnspsc190501.51334418'),
	'identifierSchemeCodeUnspsc190501.51334419': __('identifierSchemeCodeUnspsc190501.51334419'),
	'identifierSchemeCodeUnspsc190501.51334420': __('identifierSchemeCodeUnspsc190501.51334420'),
	'identifierSchemeCodeUnspsc190501.51334421': __('identifierSchemeCodeUnspsc190501.51334421'),
	'identifierSchemeCodeUnspsc190501.51334500': __('identifierSchemeCodeUnspsc190501.51334500'),
	'identifierSchemeCodeUnspsc190501.51334501': __('identifierSchemeCodeUnspsc190501.51334501'),
	'identifierSchemeCodeUnspsc190501.51334502': __('identifierSchemeCodeUnspsc190501.51334502'),
	'identifierSchemeCodeUnspsc190501.51334503': __('identifierSchemeCodeUnspsc190501.51334503'),
	'identifierSchemeCodeUnspsc190501.51334504': __('identifierSchemeCodeUnspsc190501.51334504'),
	'identifierSchemeCodeUnspsc190501.51334505': __('identifierSchemeCodeUnspsc190501.51334505'),
	'identifierSchemeCodeUnspsc190501.51334506': __('identifierSchemeCodeUnspsc190501.51334506'),
	'identifierSchemeCodeUnspsc190501.51334507': __('identifierSchemeCodeUnspsc190501.51334507'),
	'identifierSchemeCodeUnspsc190501.51334508': __('identifierSchemeCodeUnspsc190501.51334508'),
	'identifierSchemeCodeUnspsc190501.51334600': __('identifierSchemeCodeUnspsc190501.51334600'),
	'identifierSchemeCodeUnspsc190501.51334601': __('identifierSchemeCodeUnspsc190501.51334601'),
	'identifierSchemeCodeUnspsc190501.51340000': __('identifierSchemeCodeUnspsc190501.51340000'),
	'identifierSchemeCodeUnspsc190501.51341500': __('identifierSchemeCodeUnspsc190501.51341500'),
	'identifierSchemeCodeUnspsc190501.51341501': __('identifierSchemeCodeUnspsc190501.51341501'),
	'identifierSchemeCodeUnspsc190501.51341502': __('identifierSchemeCodeUnspsc190501.51341502'),
	'identifierSchemeCodeUnspsc190501.51341503': __('identifierSchemeCodeUnspsc190501.51341503'),
	'identifierSchemeCodeUnspsc190501.51341504': __('identifierSchemeCodeUnspsc190501.51341504'),
	'identifierSchemeCodeUnspsc190501.51341505': __('identifierSchemeCodeUnspsc190501.51341505'),
	'identifierSchemeCodeUnspsc190501.51341506': __('identifierSchemeCodeUnspsc190501.51341506'),
	'identifierSchemeCodeUnspsc190501.51341600': __('identifierSchemeCodeUnspsc190501.51341600'),
	'identifierSchemeCodeUnspsc190501.51341601': __('identifierSchemeCodeUnspsc190501.51341601'),
	'identifierSchemeCodeUnspsc190501.51341602': __('identifierSchemeCodeUnspsc190501.51341602'),
	'identifierSchemeCodeUnspsc190501.51341603': __('identifierSchemeCodeUnspsc190501.51341603'),
	'identifierSchemeCodeUnspsc190501.51341604': __('identifierSchemeCodeUnspsc190501.51341604'),
	'identifierSchemeCodeUnspsc190501.51341605': __('identifierSchemeCodeUnspsc190501.51341605'),
	'identifierSchemeCodeUnspsc190501.51341606': __('identifierSchemeCodeUnspsc190501.51341606'),
	'identifierSchemeCodeUnspsc190501.51341700': __('identifierSchemeCodeUnspsc190501.51341700'),
	'identifierSchemeCodeUnspsc190501.51341701': __('identifierSchemeCodeUnspsc190501.51341701'),
	'identifierSchemeCodeUnspsc190501.51341702': __('identifierSchemeCodeUnspsc190501.51341702'),
	'identifierSchemeCodeUnspsc190501.51341800': __('identifierSchemeCodeUnspsc190501.51341800'),
	'identifierSchemeCodeUnspsc190501.51341801': __('identifierSchemeCodeUnspsc190501.51341801'),
	'identifierSchemeCodeUnspsc190501.51341802': __('identifierSchemeCodeUnspsc190501.51341802'),
	'identifierSchemeCodeUnspsc190501.51341803': __('identifierSchemeCodeUnspsc190501.51341803'),
	'identifierSchemeCodeUnspsc190501.51341804': __('identifierSchemeCodeUnspsc190501.51341804'),
	'identifierSchemeCodeUnspsc190501.51341805': __('identifierSchemeCodeUnspsc190501.51341805'),
	'identifierSchemeCodeUnspsc190501.51341900': __('identifierSchemeCodeUnspsc190501.51341900'),
	'identifierSchemeCodeUnspsc190501.51341901': __('identifierSchemeCodeUnspsc190501.51341901'),
	'identifierSchemeCodeUnspsc190501.51341902': __('identifierSchemeCodeUnspsc190501.51341902'),
	'identifierSchemeCodeUnspsc190501.51341903': __('identifierSchemeCodeUnspsc190501.51341903'),
	'identifierSchemeCodeUnspsc190501.51341904': __('identifierSchemeCodeUnspsc190501.51341904'),
	'identifierSchemeCodeUnspsc190501.51342000': __('identifierSchemeCodeUnspsc190501.51342000'),
	'identifierSchemeCodeUnspsc190501.51342001': __('identifierSchemeCodeUnspsc190501.51342001'),
	'identifierSchemeCodeUnspsc190501.51342002': __('identifierSchemeCodeUnspsc190501.51342002'),
	'identifierSchemeCodeUnspsc190501.51342003': __('identifierSchemeCodeUnspsc190501.51342003'),
	'identifierSchemeCodeUnspsc190501.51342100': __('identifierSchemeCodeUnspsc190501.51342100'),
	'identifierSchemeCodeUnspsc190501.51342101': __('identifierSchemeCodeUnspsc190501.51342101'),
	'identifierSchemeCodeUnspsc190501.51342102': __('identifierSchemeCodeUnspsc190501.51342102'),
	'identifierSchemeCodeUnspsc190501.51342103': __('identifierSchemeCodeUnspsc190501.51342103'),
	'identifierSchemeCodeUnspsc190501.51342300': __('identifierSchemeCodeUnspsc190501.51342300'),
	'identifierSchemeCodeUnspsc190501.51342301': __('identifierSchemeCodeUnspsc190501.51342301'),
	'identifierSchemeCodeUnspsc190501.51342302': __('identifierSchemeCodeUnspsc190501.51342302'),
	'identifierSchemeCodeUnspsc190501.51342303': __('identifierSchemeCodeUnspsc190501.51342303'),
	'identifierSchemeCodeUnspsc190501.51342304': __('identifierSchemeCodeUnspsc190501.51342304'),
	'identifierSchemeCodeUnspsc190501.51342305': __('identifierSchemeCodeUnspsc190501.51342305'),
	'identifierSchemeCodeUnspsc190501.51342306': __('identifierSchemeCodeUnspsc190501.51342306'),
	'identifierSchemeCodeUnspsc190501.51342307': __('identifierSchemeCodeUnspsc190501.51342307'),
	'identifierSchemeCodeUnspsc190501.51342308': __('identifierSchemeCodeUnspsc190501.51342308'),
	'identifierSchemeCodeUnspsc190501.51342309': __('identifierSchemeCodeUnspsc190501.51342309'),
	'identifierSchemeCodeUnspsc190501.51342310': __('identifierSchemeCodeUnspsc190501.51342310'),
	'identifierSchemeCodeUnspsc190501.51342311': __('identifierSchemeCodeUnspsc190501.51342311'),
	'identifierSchemeCodeUnspsc190501.51342312': __('identifierSchemeCodeUnspsc190501.51342312'),
	'identifierSchemeCodeUnspsc190501.51342313': __('identifierSchemeCodeUnspsc190501.51342313'),
	'identifierSchemeCodeUnspsc190501.51342314': __('identifierSchemeCodeUnspsc190501.51342314'),
	'identifierSchemeCodeUnspsc190501.51342315': __('identifierSchemeCodeUnspsc190501.51342315'),
	'identifierSchemeCodeUnspsc190501.51342316': __('identifierSchemeCodeUnspsc190501.51342316'),
	'identifierSchemeCodeUnspsc190501.51342400': __('identifierSchemeCodeUnspsc190501.51342400'),
	'identifierSchemeCodeUnspsc190501.51342401': __('identifierSchemeCodeUnspsc190501.51342401'),
	'identifierSchemeCodeUnspsc190501.51342402': __('identifierSchemeCodeUnspsc190501.51342402'),
	'identifierSchemeCodeUnspsc190501.51342403': __('identifierSchemeCodeUnspsc190501.51342403'),
	'identifierSchemeCodeUnspsc190501.51342404': __('identifierSchemeCodeUnspsc190501.51342404'),
	'identifierSchemeCodeUnspsc190501.51342500': __('identifierSchemeCodeUnspsc190501.51342500'),
	'identifierSchemeCodeUnspsc190501.51342501': __('identifierSchemeCodeUnspsc190501.51342501'),
	'identifierSchemeCodeUnspsc190501.51342502': __('identifierSchemeCodeUnspsc190501.51342502'),
	'identifierSchemeCodeUnspsc190501.51342503': __('identifierSchemeCodeUnspsc190501.51342503'),
	'identifierSchemeCodeUnspsc190501.51342504': __('identifierSchemeCodeUnspsc190501.51342504'),
	'identifierSchemeCodeUnspsc190501.51342505': __('identifierSchemeCodeUnspsc190501.51342505'),
	'identifierSchemeCodeUnspsc190501.51342506': __('identifierSchemeCodeUnspsc190501.51342506'),
	'identifierSchemeCodeUnspsc190501.51342600': __('identifierSchemeCodeUnspsc190501.51342600'),
	'identifierSchemeCodeUnspsc190501.51342601': __('identifierSchemeCodeUnspsc190501.51342601'),
	'identifierSchemeCodeUnspsc190501.51342602': __('identifierSchemeCodeUnspsc190501.51342602'),
	'identifierSchemeCodeUnspsc190501.51342603': __('identifierSchemeCodeUnspsc190501.51342603'),
	'identifierSchemeCodeUnspsc190501.51342700': __('identifierSchemeCodeUnspsc190501.51342700'),
	'identifierSchemeCodeUnspsc190501.51342701': __('identifierSchemeCodeUnspsc190501.51342701'),
	'identifierSchemeCodeUnspsc190501.51342702': __('identifierSchemeCodeUnspsc190501.51342702'),
	'identifierSchemeCodeUnspsc190501.51342703': __('identifierSchemeCodeUnspsc190501.51342703'),
	'identifierSchemeCodeUnspsc190501.51342704': __('identifierSchemeCodeUnspsc190501.51342704'),
	'identifierSchemeCodeUnspsc190501.51342705': __('identifierSchemeCodeUnspsc190501.51342705'),
	'identifierSchemeCodeUnspsc190501.51342706': __('identifierSchemeCodeUnspsc190501.51342706'),
	'identifierSchemeCodeUnspsc190501.51342707': __('identifierSchemeCodeUnspsc190501.51342707'),
	'identifierSchemeCodeUnspsc190501.51342708': __('identifierSchemeCodeUnspsc190501.51342708'),
	'identifierSchemeCodeUnspsc190501.51342709': __('identifierSchemeCodeUnspsc190501.51342709'),
	'identifierSchemeCodeUnspsc190501.51342710': __('identifierSchemeCodeUnspsc190501.51342710'),
	'identifierSchemeCodeUnspsc190501.51342711': __('identifierSchemeCodeUnspsc190501.51342711'),
	'identifierSchemeCodeUnspsc190501.51342712': __('identifierSchemeCodeUnspsc190501.51342712'),
	'identifierSchemeCodeUnspsc190501.51342713': __('identifierSchemeCodeUnspsc190501.51342713'),
	'identifierSchemeCodeUnspsc190501.51342714': __('identifierSchemeCodeUnspsc190501.51342714'),
	'identifierSchemeCodeUnspsc190501.51342800': __('identifierSchemeCodeUnspsc190501.51342800'),
	'identifierSchemeCodeUnspsc190501.51342801': __('identifierSchemeCodeUnspsc190501.51342801'),
	'identifierSchemeCodeUnspsc190501.51342802': __('identifierSchemeCodeUnspsc190501.51342802'),
	'identifierSchemeCodeUnspsc190501.51342803': __('identifierSchemeCodeUnspsc190501.51342803'),
	'identifierSchemeCodeUnspsc190501.51342804': __('identifierSchemeCodeUnspsc190501.51342804'),
	'identifierSchemeCodeUnspsc190501.51342805': __('identifierSchemeCodeUnspsc190501.51342805'),
	'identifierSchemeCodeUnspsc190501.51342900': __('identifierSchemeCodeUnspsc190501.51342900'),
	'identifierSchemeCodeUnspsc190501.51342901': __('identifierSchemeCodeUnspsc190501.51342901'),
	'identifierSchemeCodeUnspsc190501.51342902': __('identifierSchemeCodeUnspsc190501.51342902'),
	'identifierSchemeCodeUnspsc190501.51342903': __('identifierSchemeCodeUnspsc190501.51342903'),
	'identifierSchemeCodeUnspsc190501.51342904': __('identifierSchemeCodeUnspsc190501.51342904'),
	'identifierSchemeCodeUnspsc190501.51342905': __('identifierSchemeCodeUnspsc190501.51342905'),
	'identifierSchemeCodeUnspsc190501.51342906': __('identifierSchemeCodeUnspsc190501.51342906'),
	'identifierSchemeCodeUnspsc190501.51342907': __('identifierSchemeCodeUnspsc190501.51342907'),
	'identifierSchemeCodeUnspsc190501.51343000': __('identifierSchemeCodeUnspsc190501.51343000'),
	'identifierSchemeCodeUnspsc190501.51343001': __('identifierSchemeCodeUnspsc190501.51343001'),
	'identifierSchemeCodeUnspsc190501.51343002': __('identifierSchemeCodeUnspsc190501.51343002'),
	'identifierSchemeCodeUnspsc190501.51343003': __('identifierSchemeCodeUnspsc190501.51343003'),
	'identifierSchemeCodeUnspsc190501.51343004': __('identifierSchemeCodeUnspsc190501.51343004'),
	'identifierSchemeCodeUnspsc190501.51343005': __('identifierSchemeCodeUnspsc190501.51343005'),
	'identifierSchemeCodeUnspsc190501.51343006': __('identifierSchemeCodeUnspsc190501.51343006'),
	'identifierSchemeCodeUnspsc190501.51343007': __('identifierSchemeCodeUnspsc190501.51343007'),
	'identifierSchemeCodeUnspsc190501.51343008': __('identifierSchemeCodeUnspsc190501.51343008'),
	'identifierSchemeCodeUnspsc190501.51343100': __('identifierSchemeCodeUnspsc190501.51343100'),
	'identifierSchemeCodeUnspsc190501.51343101': __('identifierSchemeCodeUnspsc190501.51343101'),
	'identifierSchemeCodeUnspsc190501.51343102': __('identifierSchemeCodeUnspsc190501.51343102'),
	'identifierSchemeCodeUnspsc190501.51343103': __('identifierSchemeCodeUnspsc190501.51343103'),
	'identifierSchemeCodeUnspsc190501.51343104': __('identifierSchemeCodeUnspsc190501.51343104'),
	'identifierSchemeCodeUnspsc190501.51343105': __('identifierSchemeCodeUnspsc190501.51343105'),
	'identifierSchemeCodeUnspsc190501.51343106': __('identifierSchemeCodeUnspsc190501.51343106'),
	'identifierSchemeCodeUnspsc190501.51343107': __('identifierSchemeCodeUnspsc190501.51343107'),
	'identifierSchemeCodeUnspsc190501.51343108': __('identifierSchemeCodeUnspsc190501.51343108'),
	'identifierSchemeCodeUnspsc190501.51343109': __('identifierSchemeCodeUnspsc190501.51343109'),
	'identifierSchemeCodeUnspsc190501.51343110': __('identifierSchemeCodeUnspsc190501.51343110'),
	'identifierSchemeCodeUnspsc190501.51343111': __('identifierSchemeCodeUnspsc190501.51343111'),
	'identifierSchemeCodeUnspsc190501.51343112': __('identifierSchemeCodeUnspsc190501.51343112'),
	'identifierSchemeCodeUnspsc190501.51343113': __('identifierSchemeCodeUnspsc190501.51343113'),
	'identifierSchemeCodeUnspsc190501.51343115': __('identifierSchemeCodeUnspsc190501.51343115'),
	'identifierSchemeCodeUnspsc190501.51343116': __('identifierSchemeCodeUnspsc190501.51343116'),
	'identifierSchemeCodeUnspsc190501.51343117': __('identifierSchemeCodeUnspsc190501.51343117'),
	'identifierSchemeCodeUnspsc190501.51343118': __('identifierSchemeCodeUnspsc190501.51343118'),
	'identifierSchemeCodeUnspsc190501.51343200': __('identifierSchemeCodeUnspsc190501.51343200'),
	'identifierSchemeCodeUnspsc190501.51343201': __('identifierSchemeCodeUnspsc190501.51343201'),
	'identifierSchemeCodeUnspsc190501.51343202': __('identifierSchemeCodeUnspsc190501.51343202'),
	'identifierSchemeCodeUnspsc190501.51343203': __('identifierSchemeCodeUnspsc190501.51343203'),
	'identifierSchemeCodeUnspsc190501.51343300': __('identifierSchemeCodeUnspsc190501.51343300'),
	'identifierSchemeCodeUnspsc190501.51343301': __('identifierSchemeCodeUnspsc190501.51343301'),
	'identifierSchemeCodeUnspsc190501.51343302': __('identifierSchemeCodeUnspsc190501.51343302'),
	'identifierSchemeCodeUnspsc190501.51343303': __('identifierSchemeCodeUnspsc190501.51343303'),
	'identifierSchemeCodeUnspsc190501.51343304': __('identifierSchemeCodeUnspsc190501.51343304'),
	'identifierSchemeCodeUnspsc190501.51343305': __('identifierSchemeCodeUnspsc190501.51343305'),
	'identifierSchemeCodeUnspsc190501.51343306': __('identifierSchemeCodeUnspsc190501.51343306'),
	'identifierSchemeCodeUnspsc190501.51343307': __('identifierSchemeCodeUnspsc190501.51343307'),
	'identifierSchemeCodeUnspsc190501.51343308': __('identifierSchemeCodeUnspsc190501.51343308'),
	'identifierSchemeCodeUnspsc190501.51343309': __('identifierSchemeCodeUnspsc190501.51343309'),
	'identifierSchemeCodeUnspsc190501.51343310': __('identifierSchemeCodeUnspsc190501.51343310'),
	'identifierSchemeCodeUnspsc190501.51343400': __('identifierSchemeCodeUnspsc190501.51343400'),
	'identifierSchemeCodeUnspsc190501.51343401': __('identifierSchemeCodeUnspsc190501.51343401'),
	'identifierSchemeCodeUnspsc190501.51343402': __('identifierSchemeCodeUnspsc190501.51343402'),
	'identifierSchemeCodeUnspsc190501.51343403': __('identifierSchemeCodeUnspsc190501.51343403'),
	'identifierSchemeCodeUnspsc190501.51343404': __('identifierSchemeCodeUnspsc190501.51343404'),
	'identifierSchemeCodeUnspsc190501.51343405': __('identifierSchemeCodeUnspsc190501.51343405'),
	'identifierSchemeCodeUnspsc190501.51343406': __('identifierSchemeCodeUnspsc190501.51343406'),
	'identifierSchemeCodeUnspsc190501.51343500': __('identifierSchemeCodeUnspsc190501.51343500'),
	'identifierSchemeCodeUnspsc190501.51343501': __('identifierSchemeCodeUnspsc190501.51343501'),
	'identifierSchemeCodeUnspsc190501.51343502': __('identifierSchemeCodeUnspsc190501.51343502'),
	'identifierSchemeCodeUnspsc190501.51343503': __('identifierSchemeCodeUnspsc190501.51343503'),
	'identifierSchemeCodeUnspsc190501.51343504': __('identifierSchemeCodeUnspsc190501.51343504'),
	'identifierSchemeCodeUnspsc190501.51343505': __('identifierSchemeCodeUnspsc190501.51343505'),
	'identifierSchemeCodeUnspsc190501.51343506': __('identifierSchemeCodeUnspsc190501.51343506'),
	'identifierSchemeCodeUnspsc190501.51343507': __('identifierSchemeCodeUnspsc190501.51343507'),
	'identifierSchemeCodeUnspsc190501.51343508': __('identifierSchemeCodeUnspsc190501.51343508'),
	'identifierSchemeCodeUnspsc190501.51343509': __('identifierSchemeCodeUnspsc190501.51343509'),
	'identifierSchemeCodeUnspsc190501.51343510': __('identifierSchemeCodeUnspsc190501.51343510'),
	'identifierSchemeCodeUnspsc190501.51343511': __('identifierSchemeCodeUnspsc190501.51343511'),
	'identifierSchemeCodeUnspsc190501.51343512': __('identifierSchemeCodeUnspsc190501.51343512'),
	'identifierSchemeCodeUnspsc190501.51343600': __('identifierSchemeCodeUnspsc190501.51343600'),
	'identifierSchemeCodeUnspsc190501.51343601': __('identifierSchemeCodeUnspsc190501.51343601'),
	'identifierSchemeCodeUnspsc190501.51343602': __('identifierSchemeCodeUnspsc190501.51343602'),
	'identifierSchemeCodeUnspsc190501.51343700': __('identifierSchemeCodeUnspsc190501.51343700'),
	'identifierSchemeCodeUnspsc190501.51343701': __('identifierSchemeCodeUnspsc190501.51343701'),
	'identifierSchemeCodeUnspsc190501.51343702': __('identifierSchemeCodeUnspsc190501.51343702'),
	'identifierSchemeCodeUnspsc190501.51343703': __('identifierSchemeCodeUnspsc190501.51343703'),
	'identifierSchemeCodeUnspsc190501.51343704': __('identifierSchemeCodeUnspsc190501.51343704'),
	'identifierSchemeCodeUnspsc190501.51343705': __('identifierSchemeCodeUnspsc190501.51343705'),
	'identifierSchemeCodeUnspsc190501.51343800': __('identifierSchemeCodeUnspsc190501.51343800'),
	'identifierSchemeCodeUnspsc190501.51343801': __('identifierSchemeCodeUnspsc190501.51343801'),
	'identifierSchemeCodeUnspsc190501.51343802': __('identifierSchemeCodeUnspsc190501.51343802'),
	'identifierSchemeCodeUnspsc190501.51343803': __('identifierSchemeCodeUnspsc190501.51343803'),
	'identifierSchemeCodeUnspsc190501.51343804': __('identifierSchemeCodeUnspsc190501.51343804'),
	'identifierSchemeCodeUnspsc190501.51343805': __('identifierSchemeCodeUnspsc190501.51343805'),
	'identifierSchemeCodeUnspsc190501.51343806': __('identifierSchemeCodeUnspsc190501.51343806'),
	'identifierSchemeCodeUnspsc190501.51343807': __('identifierSchemeCodeUnspsc190501.51343807'),
	'identifierSchemeCodeUnspsc190501.51350000': __('identifierSchemeCodeUnspsc190501.51350000'),
	'identifierSchemeCodeUnspsc190501.51351500': __('identifierSchemeCodeUnspsc190501.51351500'),
	'identifierSchemeCodeUnspsc190501.51351501': __('identifierSchemeCodeUnspsc190501.51351501'),
	'identifierSchemeCodeUnspsc190501.51351502': __('identifierSchemeCodeUnspsc190501.51351502'),
	'identifierSchemeCodeUnspsc190501.51351503': __('identifierSchemeCodeUnspsc190501.51351503'),
	'identifierSchemeCodeUnspsc190501.51351504': __('identifierSchemeCodeUnspsc190501.51351504'),
	'identifierSchemeCodeUnspsc190501.51351505': __('identifierSchemeCodeUnspsc190501.51351505'),
	'identifierSchemeCodeUnspsc190501.51351506': __('identifierSchemeCodeUnspsc190501.51351506'),
	'identifierSchemeCodeUnspsc190501.51351507': __('identifierSchemeCodeUnspsc190501.51351507'),
	'identifierSchemeCodeUnspsc190501.51351508': __('identifierSchemeCodeUnspsc190501.51351508'),
	'identifierSchemeCodeUnspsc190501.51351509': __('identifierSchemeCodeUnspsc190501.51351509'),
	'identifierSchemeCodeUnspsc190501.51351510': __('identifierSchemeCodeUnspsc190501.51351510'),
	'identifierSchemeCodeUnspsc190501.51351511': __('identifierSchemeCodeUnspsc190501.51351511'),
	'identifierSchemeCodeUnspsc190501.51351512': __('identifierSchemeCodeUnspsc190501.51351512'),
	'identifierSchemeCodeUnspsc190501.51351513': __('identifierSchemeCodeUnspsc190501.51351513'),
	'identifierSchemeCodeUnspsc190501.51351514': __('identifierSchemeCodeUnspsc190501.51351514'),
	'identifierSchemeCodeUnspsc190501.51351515': __('identifierSchemeCodeUnspsc190501.51351515'),
	'identifierSchemeCodeUnspsc190501.51351516': __('identifierSchemeCodeUnspsc190501.51351516'),
	'identifierSchemeCodeUnspsc190501.51351517': __('identifierSchemeCodeUnspsc190501.51351517'),
	'identifierSchemeCodeUnspsc190501.51351518': __('identifierSchemeCodeUnspsc190501.51351518'),
	'identifierSchemeCodeUnspsc190501.51351519': __('identifierSchemeCodeUnspsc190501.51351519'),
	'identifierSchemeCodeUnspsc190501.51351520': __('identifierSchemeCodeUnspsc190501.51351520'),
	'identifierSchemeCodeUnspsc190501.51351521': __('identifierSchemeCodeUnspsc190501.51351521'),
	'identifierSchemeCodeUnspsc190501.51351522': __('identifierSchemeCodeUnspsc190501.51351522'),
	'identifierSchemeCodeUnspsc190501.51351523': __('identifierSchemeCodeUnspsc190501.51351523'),
	'identifierSchemeCodeUnspsc190501.51351524': __('identifierSchemeCodeUnspsc190501.51351524'),
	'identifierSchemeCodeUnspsc190501.51351525': __('identifierSchemeCodeUnspsc190501.51351525'),
	'identifierSchemeCodeUnspsc190501.51351526': __('identifierSchemeCodeUnspsc190501.51351526'),
	'identifierSchemeCodeUnspsc190501.51351527': __('identifierSchemeCodeUnspsc190501.51351527'),
	'identifierSchemeCodeUnspsc190501.51351528': __('identifierSchemeCodeUnspsc190501.51351528'),
	'identifierSchemeCodeUnspsc190501.51351529': __('identifierSchemeCodeUnspsc190501.51351529'),
	'identifierSchemeCodeUnspsc190501.51351600': __('identifierSchemeCodeUnspsc190501.51351600'),
	'identifierSchemeCodeUnspsc190501.51351601': __('identifierSchemeCodeUnspsc190501.51351601'),
	'identifierSchemeCodeUnspsc190501.51351602': __('identifierSchemeCodeUnspsc190501.51351602'),
	'identifierSchemeCodeUnspsc190501.51351603': __('identifierSchemeCodeUnspsc190501.51351603'),
	'identifierSchemeCodeUnspsc190501.51351604': __('identifierSchemeCodeUnspsc190501.51351604'),
	'identifierSchemeCodeUnspsc190501.51351605': __('identifierSchemeCodeUnspsc190501.51351605'),
	'identifierSchemeCodeUnspsc190501.51351606': __('identifierSchemeCodeUnspsc190501.51351606'),
	'identifierSchemeCodeUnspsc190501.51351607': __('identifierSchemeCodeUnspsc190501.51351607'),
	'identifierSchemeCodeUnspsc190501.51351608': __('identifierSchemeCodeUnspsc190501.51351608'),
	'identifierSchemeCodeUnspsc190501.51351609': __('identifierSchemeCodeUnspsc190501.51351609'),
	'identifierSchemeCodeUnspsc190501.51351610': __('identifierSchemeCodeUnspsc190501.51351610'),
	'identifierSchemeCodeUnspsc190501.51351611': __('identifierSchemeCodeUnspsc190501.51351611'),
	'identifierSchemeCodeUnspsc190501.51351612': __('identifierSchemeCodeUnspsc190501.51351612'),
	'identifierSchemeCodeUnspsc190501.51351613': __('identifierSchemeCodeUnspsc190501.51351613'),
	'identifierSchemeCodeUnspsc190501.51351614': __('identifierSchemeCodeUnspsc190501.51351614'),
	'identifierSchemeCodeUnspsc190501.51351615': __('identifierSchemeCodeUnspsc190501.51351615'),
	'identifierSchemeCodeUnspsc190501.51351616': __('identifierSchemeCodeUnspsc190501.51351616'),
	'identifierSchemeCodeUnspsc190501.51351617': __('identifierSchemeCodeUnspsc190501.51351617'),
	'identifierSchemeCodeUnspsc190501.51351618': __('identifierSchemeCodeUnspsc190501.51351618'),
	'identifierSchemeCodeUnspsc190501.51351619': __('identifierSchemeCodeUnspsc190501.51351619'),
	'identifierSchemeCodeUnspsc190501.51351620': __('identifierSchemeCodeUnspsc190501.51351620'),
	'identifierSchemeCodeUnspsc190501.51351621': __('identifierSchemeCodeUnspsc190501.51351621'),
	'identifierSchemeCodeUnspsc190501.51351622': __('identifierSchemeCodeUnspsc190501.51351622'),
	'identifierSchemeCodeUnspsc190501.51351623': __('identifierSchemeCodeUnspsc190501.51351623'),
	'identifierSchemeCodeUnspsc190501.51351624': __('identifierSchemeCodeUnspsc190501.51351624'),
	'identifierSchemeCodeUnspsc190501.51351625': __('identifierSchemeCodeUnspsc190501.51351625'),
	'identifierSchemeCodeUnspsc190501.51351626': __('identifierSchemeCodeUnspsc190501.51351626'),
	'identifierSchemeCodeUnspsc190501.51351627': __('identifierSchemeCodeUnspsc190501.51351627'),
	'identifierSchemeCodeUnspsc190501.51351628': __('identifierSchemeCodeUnspsc190501.51351628'),
	'identifierSchemeCodeUnspsc190501.51351629': __('identifierSchemeCodeUnspsc190501.51351629'),
	'identifierSchemeCodeUnspsc190501.51351630': __('identifierSchemeCodeUnspsc190501.51351630'),
	'identifierSchemeCodeUnspsc190501.51351631': __('identifierSchemeCodeUnspsc190501.51351631'),
	'identifierSchemeCodeUnspsc190501.51351632': __('identifierSchemeCodeUnspsc190501.51351632'),
	'identifierSchemeCodeUnspsc190501.51351633': __('identifierSchemeCodeUnspsc190501.51351633'),
	'identifierSchemeCodeUnspsc190501.51351634': __('identifierSchemeCodeUnspsc190501.51351634'),
	'identifierSchemeCodeUnspsc190501.51351700': __('identifierSchemeCodeUnspsc190501.51351700'),
	'identifierSchemeCodeUnspsc190501.51351701': __('identifierSchemeCodeUnspsc190501.51351701'),
	'identifierSchemeCodeUnspsc190501.51351702': __('identifierSchemeCodeUnspsc190501.51351702'),
	'identifierSchemeCodeUnspsc190501.51351703': __('identifierSchemeCodeUnspsc190501.51351703'),
	'identifierSchemeCodeUnspsc190501.51351704': __('identifierSchemeCodeUnspsc190501.51351704'),
	'identifierSchemeCodeUnspsc190501.51351705': __('identifierSchemeCodeUnspsc190501.51351705'),
	'identifierSchemeCodeUnspsc190501.51351706': __('identifierSchemeCodeUnspsc190501.51351706'),
	'identifierSchemeCodeUnspsc190501.51351707': __('identifierSchemeCodeUnspsc190501.51351707'),
	'identifierSchemeCodeUnspsc190501.51351800': __('identifierSchemeCodeUnspsc190501.51351800'),
	'identifierSchemeCodeUnspsc190501.51351801': __('identifierSchemeCodeUnspsc190501.51351801'),
	'identifierSchemeCodeUnspsc190501.51351802': __('identifierSchemeCodeUnspsc190501.51351802'),
	'identifierSchemeCodeUnspsc190501.51351803': __('identifierSchemeCodeUnspsc190501.51351803'),
	'identifierSchemeCodeUnspsc190501.51351804': __('identifierSchemeCodeUnspsc190501.51351804'),
	'identifierSchemeCodeUnspsc190501.51351900': __('identifierSchemeCodeUnspsc190501.51351900'),
	'identifierSchemeCodeUnspsc190501.51351901': __('identifierSchemeCodeUnspsc190501.51351901'),
	'identifierSchemeCodeUnspsc190501.51351902': __('identifierSchemeCodeUnspsc190501.51351902'),
	'identifierSchemeCodeUnspsc190501.51351903': __('identifierSchemeCodeUnspsc190501.51351903'),
	'identifierSchemeCodeUnspsc190501.51351904': __('identifierSchemeCodeUnspsc190501.51351904'),
	'identifierSchemeCodeUnspsc190501.51351905': __('identifierSchemeCodeUnspsc190501.51351905'),
	'identifierSchemeCodeUnspsc190501.51351906': __('identifierSchemeCodeUnspsc190501.51351906'),
	'identifierSchemeCodeUnspsc190501.51351907': __('identifierSchemeCodeUnspsc190501.51351907'),
	'identifierSchemeCodeUnspsc190501.51351908': __('identifierSchemeCodeUnspsc190501.51351908'),
	'identifierSchemeCodeUnspsc190501.51351909': __('identifierSchemeCodeUnspsc190501.51351909'),
	'identifierSchemeCodeUnspsc190501.51351910': __('identifierSchemeCodeUnspsc190501.51351910'),
	'identifierSchemeCodeUnspsc190501.51351911': __('identifierSchemeCodeUnspsc190501.51351911'),
	'identifierSchemeCodeUnspsc190501.51351912': __('identifierSchemeCodeUnspsc190501.51351912'),
	'identifierSchemeCodeUnspsc190501.51351913': __('identifierSchemeCodeUnspsc190501.51351913'),
	'identifierSchemeCodeUnspsc190501.51351914': __('identifierSchemeCodeUnspsc190501.51351914'),
	'identifierSchemeCodeUnspsc190501.51351915': __('identifierSchemeCodeUnspsc190501.51351915'),
	'identifierSchemeCodeUnspsc190501.51352000': __('identifierSchemeCodeUnspsc190501.51352000'),
	'identifierSchemeCodeUnspsc190501.51352001': __('identifierSchemeCodeUnspsc190501.51352001'),
	'identifierSchemeCodeUnspsc190501.51352002': __('identifierSchemeCodeUnspsc190501.51352002'),
	'identifierSchemeCodeUnspsc190501.51352003': __('identifierSchemeCodeUnspsc190501.51352003'),
	'identifierSchemeCodeUnspsc190501.51352004': __('identifierSchemeCodeUnspsc190501.51352004'),
	'identifierSchemeCodeUnspsc190501.51352005': __('identifierSchemeCodeUnspsc190501.51352005'),
	'identifierSchemeCodeUnspsc190501.51352006': __('identifierSchemeCodeUnspsc190501.51352006'),
	'identifierSchemeCodeUnspsc190501.51352007': __('identifierSchemeCodeUnspsc190501.51352007'),
	'identifierSchemeCodeUnspsc190501.51352008': __('identifierSchemeCodeUnspsc190501.51352008'),
	'identifierSchemeCodeUnspsc190501.51352009': __('identifierSchemeCodeUnspsc190501.51352009'),
	'identifierSchemeCodeUnspsc190501.51352010': __('identifierSchemeCodeUnspsc190501.51352010'),
	'identifierSchemeCodeUnspsc190501.51352011': __('identifierSchemeCodeUnspsc190501.51352011'),
	'identifierSchemeCodeUnspsc190501.51352012': __('identifierSchemeCodeUnspsc190501.51352012'),
	'identifierSchemeCodeUnspsc190501.51352013': __('identifierSchemeCodeUnspsc190501.51352013'),
	'identifierSchemeCodeUnspsc190501.51352100': __('identifierSchemeCodeUnspsc190501.51352100'),
	'identifierSchemeCodeUnspsc190501.51352101': __('identifierSchemeCodeUnspsc190501.51352101'),
	'identifierSchemeCodeUnspsc190501.51352102': __('identifierSchemeCodeUnspsc190501.51352102'),
	'identifierSchemeCodeUnspsc190501.51352103': __('identifierSchemeCodeUnspsc190501.51352103'),
	'identifierSchemeCodeUnspsc190501.51352104': __('identifierSchemeCodeUnspsc190501.51352104'),
	'identifierSchemeCodeUnspsc190501.51352200': __('identifierSchemeCodeUnspsc190501.51352200'),
	'identifierSchemeCodeUnspsc190501.51352201': __('identifierSchemeCodeUnspsc190501.51352201'),
	'identifierSchemeCodeUnspsc190501.51352202': __('identifierSchemeCodeUnspsc190501.51352202'),
	'identifierSchemeCodeUnspsc190501.51352203': __('identifierSchemeCodeUnspsc190501.51352203'),
	'identifierSchemeCodeUnspsc190501.51352204': __('identifierSchemeCodeUnspsc190501.51352204'),
	'identifierSchemeCodeUnspsc190501.51352205': __('identifierSchemeCodeUnspsc190501.51352205'),
	'identifierSchemeCodeUnspsc190501.51352206': __('identifierSchemeCodeUnspsc190501.51352206'),
	'identifierSchemeCodeUnspsc190501.51352207': __('identifierSchemeCodeUnspsc190501.51352207'),
	'identifierSchemeCodeUnspsc190501.51352208': __('identifierSchemeCodeUnspsc190501.51352208'),
	'identifierSchemeCodeUnspsc190501.51352209': __('identifierSchemeCodeUnspsc190501.51352209'),
	'identifierSchemeCodeUnspsc190501.51352210': __('identifierSchemeCodeUnspsc190501.51352210'),
	'identifierSchemeCodeUnspsc190501.51352211': __('identifierSchemeCodeUnspsc190501.51352211'),
	'identifierSchemeCodeUnspsc190501.51352212': __('identifierSchemeCodeUnspsc190501.51352212'),
	'identifierSchemeCodeUnspsc190501.51352213': __('identifierSchemeCodeUnspsc190501.51352213'),
	'identifierSchemeCodeUnspsc190501.51352300': __('identifierSchemeCodeUnspsc190501.51352300'),
	'identifierSchemeCodeUnspsc190501.51352301': __('identifierSchemeCodeUnspsc190501.51352301'),
	'identifierSchemeCodeUnspsc190501.51352302': __('identifierSchemeCodeUnspsc190501.51352302'),
	'identifierSchemeCodeUnspsc190501.51352303': __('identifierSchemeCodeUnspsc190501.51352303'),
	'identifierSchemeCodeUnspsc190501.51352304': __('identifierSchemeCodeUnspsc190501.51352304'),
	'identifierSchemeCodeUnspsc190501.51352305': __('identifierSchemeCodeUnspsc190501.51352305'),
	'identifierSchemeCodeUnspsc190501.51352306': __('identifierSchemeCodeUnspsc190501.51352306'),
	'identifierSchemeCodeUnspsc190501.51352307': __('identifierSchemeCodeUnspsc190501.51352307'),
	'identifierSchemeCodeUnspsc190501.51352308': __('identifierSchemeCodeUnspsc190501.51352308'),
	'identifierSchemeCodeUnspsc190501.51352309': __('identifierSchemeCodeUnspsc190501.51352309'),
	'identifierSchemeCodeUnspsc190501.51352310': __('identifierSchemeCodeUnspsc190501.51352310'),
	'identifierSchemeCodeUnspsc190501.51352311': __('identifierSchemeCodeUnspsc190501.51352311'),
	'identifierSchemeCodeUnspsc190501.51352400': __('identifierSchemeCodeUnspsc190501.51352400'),
	'identifierSchemeCodeUnspsc190501.51352401': __('identifierSchemeCodeUnspsc190501.51352401'),
	'identifierSchemeCodeUnspsc190501.51352402': __('identifierSchemeCodeUnspsc190501.51352402'),
	'identifierSchemeCodeUnspsc190501.51352403': __('identifierSchemeCodeUnspsc190501.51352403'),
	'identifierSchemeCodeUnspsc190501.51352404': __('identifierSchemeCodeUnspsc190501.51352404'),
	'identifierSchemeCodeUnspsc190501.51352405': __('identifierSchemeCodeUnspsc190501.51352405'),
	'identifierSchemeCodeUnspsc190501.51352406': __('identifierSchemeCodeUnspsc190501.51352406'),
	'identifierSchemeCodeUnspsc190501.51352407': __('identifierSchemeCodeUnspsc190501.51352407'),
	'identifierSchemeCodeUnspsc190501.51352408': __('identifierSchemeCodeUnspsc190501.51352408'),
	'identifierSchemeCodeUnspsc190501.51352409': __('identifierSchemeCodeUnspsc190501.51352409'),
	'identifierSchemeCodeUnspsc190501.51352410': __('identifierSchemeCodeUnspsc190501.51352410'),
	'identifierSchemeCodeUnspsc190501.51352411': __('identifierSchemeCodeUnspsc190501.51352411'),
	'identifierSchemeCodeUnspsc190501.51352412': __('identifierSchemeCodeUnspsc190501.51352412'),
	'identifierSchemeCodeUnspsc190501.51352413': __('identifierSchemeCodeUnspsc190501.51352413'),
	'identifierSchemeCodeUnspsc190501.51352414': __('identifierSchemeCodeUnspsc190501.51352414'),
	'identifierSchemeCodeUnspsc190501.51352415': __('identifierSchemeCodeUnspsc190501.51352415'),
	'identifierSchemeCodeUnspsc190501.51352416': __('identifierSchemeCodeUnspsc190501.51352416'),
	'identifierSchemeCodeUnspsc190501.51352417': __('identifierSchemeCodeUnspsc190501.51352417'),
	'identifierSchemeCodeUnspsc190501.51352418': __('identifierSchemeCodeUnspsc190501.51352418'),
	'identifierSchemeCodeUnspsc190501.51352419': __('identifierSchemeCodeUnspsc190501.51352419'),
	'identifierSchemeCodeUnspsc190501.51352420': __('identifierSchemeCodeUnspsc190501.51352420'),
	'identifierSchemeCodeUnspsc190501.51352421': __('identifierSchemeCodeUnspsc190501.51352421'),
	'identifierSchemeCodeUnspsc190501.51352422': __('identifierSchemeCodeUnspsc190501.51352422'),
	'identifierSchemeCodeUnspsc190501.51352423': __('identifierSchemeCodeUnspsc190501.51352423'),
	'identifierSchemeCodeUnspsc190501.51352424': __('identifierSchemeCodeUnspsc190501.51352424'),
	'identifierSchemeCodeUnspsc190501.51352425': __('identifierSchemeCodeUnspsc190501.51352425'),
	'identifierSchemeCodeUnspsc190501.51352426': __('identifierSchemeCodeUnspsc190501.51352426'),
	'identifierSchemeCodeUnspsc190501.51352427': __('identifierSchemeCodeUnspsc190501.51352427'),
	'identifierSchemeCodeUnspsc190501.51352428': __('identifierSchemeCodeUnspsc190501.51352428'),
	'identifierSchemeCodeUnspsc190501.51352429': __('identifierSchemeCodeUnspsc190501.51352429'),
	'identifierSchemeCodeUnspsc190501.51352430': __('identifierSchemeCodeUnspsc190501.51352430'),
	'identifierSchemeCodeUnspsc190501.51352431': __('identifierSchemeCodeUnspsc190501.51352431'),
	'identifierSchemeCodeUnspsc190501.51352432': __('identifierSchemeCodeUnspsc190501.51352432'),
	'identifierSchemeCodeUnspsc190501.51352433': __('identifierSchemeCodeUnspsc190501.51352433'),
	'identifierSchemeCodeUnspsc190501.51352434': __('identifierSchemeCodeUnspsc190501.51352434'),
	'identifierSchemeCodeUnspsc190501.51360000': __('identifierSchemeCodeUnspsc190501.51360000'),
	'identifierSchemeCodeUnspsc190501.51361500': __('identifierSchemeCodeUnspsc190501.51361500'),
	'identifierSchemeCodeUnspsc190501.51361501': __('identifierSchemeCodeUnspsc190501.51361501'),
	'identifierSchemeCodeUnspsc190501.51361502': __('identifierSchemeCodeUnspsc190501.51361502'),
	'identifierSchemeCodeUnspsc190501.51361503': __('identifierSchemeCodeUnspsc190501.51361503'),
	'identifierSchemeCodeUnspsc190501.51361504': __('identifierSchemeCodeUnspsc190501.51361504'),
	'identifierSchemeCodeUnspsc190501.51361505': __('identifierSchemeCodeUnspsc190501.51361505'),
	'identifierSchemeCodeUnspsc190501.51361506': __('identifierSchemeCodeUnspsc190501.51361506'),
	'identifierSchemeCodeUnspsc190501.51361507': __('identifierSchemeCodeUnspsc190501.51361507'),
	'identifierSchemeCodeUnspsc190501.51361600': __('identifierSchemeCodeUnspsc190501.51361600'),
	'identifierSchemeCodeUnspsc190501.51361601': __('identifierSchemeCodeUnspsc190501.51361601'),
	'identifierSchemeCodeUnspsc190501.51361602': __('identifierSchemeCodeUnspsc190501.51361602'),
	'identifierSchemeCodeUnspsc190501.51361603': __('identifierSchemeCodeUnspsc190501.51361603'),
	'identifierSchemeCodeUnspsc190501.51361604': __('identifierSchemeCodeUnspsc190501.51361604'),
	'identifierSchemeCodeUnspsc190501.51361700': __('identifierSchemeCodeUnspsc190501.51361700'),
	'identifierSchemeCodeUnspsc190501.51361701': __('identifierSchemeCodeUnspsc190501.51361701'),
	'identifierSchemeCodeUnspsc190501.51361702': __('identifierSchemeCodeUnspsc190501.51361702'),
	'identifierSchemeCodeUnspsc190501.51361703': __('identifierSchemeCodeUnspsc190501.51361703'),
	'identifierSchemeCodeUnspsc190501.51361704': __('identifierSchemeCodeUnspsc190501.51361704'),
	'identifierSchemeCodeUnspsc190501.51361705': __('identifierSchemeCodeUnspsc190501.51361705'),
	'identifierSchemeCodeUnspsc190501.51361706': __('identifierSchemeCodeUnspsc190501.51361706'),
	'identifierSchemeCodeUnspsc190501.51361800': __('identifierSchemeCodeUnspsc190501.51361800'),
	'identifierSchemeCodeUnspsc190501.51361801': __('identifierSchemeCodeUnspsc190501.51361801'),
	'identifierSchemeCodeUnspsc190501.51361802': __('identifierSchemeCodeUnspsc190501.51361802'),
	'identifierSchemeCodeUnspsc190501.51361803': __('identifierSchemeCodeUnspsc190501.51361803'),
	'identifierSchemeCodeUnspsc190501.51361804': __('identifierSchemeCodeUnspsc190501.51361804'),
	'identifierSchemeCodeUnspsc190501.51361805': __('identifierSchemeCodeUnspsc190501.51361805'),
	'identifierSchemeCodeUnspsc190501.51361806': __('identifierSchemeCodeUnspsc190501.51361806'),
	'identifierSchemeCodeUnspsc190501.51361807': __('identifierSchemeCodeUnspsc190501.51361807'),
	'identifierSchemeCodeUnspsc190501.51361808': __('identifierSchemeCodeUnspsc190501.51361808'),
	'identifierSchemeCodeUnspsc190501.51361809': __('identifierSchemeCodeUnspsc190501.51361809'),
	'identifierSchemeCodeUnspsc190501.51361810': __('identifierSchemeCodeUnspsc190501.51361810'),
	'identifierSchemeCodeUnspsc190501.51361900': __('identifierSchemeCodeUnspsc190501.51361900'),
	'identifierSchemeCodeUnspsc190501.51361901': __('identifierSchemeCodeUnspsc190501.51361901'),
	'identifierSchemeCodeUnspsc190501.51361902': __('identifierSchemeCodeUnspsc190501.51361902'),
	'identifierSchemeCodeUnspsc190501.51361903': __('identifierSchemeCodeUnspsc190501.51361903'),
	'identifierSchemeCodeUnspsc190501.51361904': __('identifierSchemeCodeUnspsc190501.51361904'),
	'identifierSchemeCodeUnspsc190501.51361905': __('identifierSchemeCodeUnspsc190501.51361905'),
	'identifierSchemeCodeUnspsc190501.51361906': __('identifierSchemeCodeUnspsc190501.51361906'),
	'identifierSchemeCodeUnspsc190501.51361907': __('identifierSchemeCodeUnspsc190501.51361907'),
	'identifierSchemeCodeUnspsc190501.51361908': __('identifierSchemeCodeUnspsc190501.51361908'),
	'identifierSchemeCodeUnspsc190501.51361909': __('identifierSchemeCodeUnspsc190501.51361909'),
	'identifierSchemeCodeUnspsc190501.51361910': __('identifierSchemeCodeUnspsc190501.51361910'),
	'identifierSchemeCodeUnspsc190501.51361911': __('identifierSchemeCodeUnspsc190501.51361911'),
	'identifierSchemeCodeUnspsc190501.51361912': __('identifierSchemeCodeUnspsc190501.51361912'),
	'identifierSchemeCodeUnspsc190501.51361913': __('identifierSchemeCodeUnspsc190501.51361913'),
	'identifierSchemeCodeUnspsc190501.51361914': __('identifierSchemeCodeUnspsc190501.51361914'),
	'identifierSchemeCodeUnspsc190501.51361915': __('identifierSchemeCodeUnspsc190501.51361915'),
	'identifierSchemeCodeUnspsc190501.51361916': __('identifierSchemeCodeUnspsc190501.51361916'),
	'identifierSchemeCodeUnspsc190501.51361917': __('identifierSchemeCodeUnspsc190501.51361917'),
	'identifierSchemeCodeUnspsc190501.51361918': __('identifierSchemeCodeUnspsc190501.51361918'),
	'identifierSchemeCodeUnspsc190501.51361919': __('identifierSchemeCodeUnspsc190501.51361919'),
	'identifierSchemeCodeUnspsc190501.51361920': __('identifierSchemeCodeUnspsc190501.51361920'),
	'identifierSchemeCodeUnspsc190501.51361921': __('identifierSchemeCodeUnspsc190501.51361921'),
	'identifierSchemeCodeUnspsc190501.51361922': __('identifierSchemeCodeUnspsc190501.51361922'),
	'identifierSchemeCodeUnspsc190501.51361923': __('identifierSchemeCodeUnspsc190501.51361923'),
	'identifierSchemeCodeUnspsc190501.51361924': __('identifierSchemeCodeUnspsc190501.51361924'),
	'identifierSchemeCodeUnspsc190501.51361925': __('identifierSchemeCodeUnspsc190501.51361925'),
	'identifierSchemeCodeUnspsc190501.51361926': __('identifierSchemeCodeUnspsc190501.51361926'),
	'identifierSchemeCodeUnspsc190501.51361927': __('identifierSchemeCodeUnspsc190501.51361927'),
	'identifierSchemeCodeUnspsc190501.51361928': __('identifierSchemeCodeUnspsc190501.51361928'),
	'identifierSchemeCodeUnspsc190501.51361929': __('identifierSchemeCodeUnspsc190501.51361929'),
	'identifierSchemeCodeUnspsc190501.51361930': __('identifierSchemeCodeUnspsc190501.51361930'),
	'identifierSchemeCodeUnspsc190501.51361931': __('identifierSchemeCodeUnspsc190501.51361931'),
	'identifierSchemeCodeUnspsc190501.51361932': __('identifierSchemeCodeUnspsc190501.51361932'),
	'identifierSchemeCodeUnspsc190501.51361933': __('identifierSchemeCodeUnspsc190501.51361933'),
	'identifierSchemeCodeUnspsc190501.51361934': __('identifierSchemeCodeUnspsc190501.51361934'),
	'identifierSchemeCodeUnspsc190501.51361935': __('identifierSchemeCodeUnspsc190501.51361935'),
	'identifierSchemeCodeUnspsc190501.51361936': __('identifierSchemeCodeUnspsc190501.51361936'),
	'identifierSchemeCodeUnspsc190501.51361937': __('identifierSchemeCodeUnspsc190501.51361937'),
	'identifierSchemeCodeUnspsc190501.51361938': __('identifierSchemeCodeUnspsc190501.51361938'),
	'identifierSchemeCodeUnspsc190501.51361939': __('identifierSchemeCodeUnspsc190501.51361939'),
	'identifierSchemeCodeUnspsc190501.51361940': __('identifierSchemeCodeUnspsc190501.51361940'),
	'identifierSchemeCodeUnspsc190501.51361941': __('identifierSchemeCodeUnspsc190501.51361941'),
	'identifierSchemeCodeUnspsc190501.51362000': __('identifierSchemeCodeUnspsc190501.51362000'),
	'identifierSchemeCodeUnspsc190501.51362001': __('identifierSchemeCodeUnspsc190501.51362001'),
	'identifierSchemeCodeUnspsc190501.51362002': __('identifierSchemeCodeUnspsc190501.51362002'),
	'identifierSchemeCodeUnspsc190501.51362003': __('identifierSchemeCodeUnspsc190501.51362003'),
	'identifierSchemeCodeUnspsc190501.51362004': __('identifierSchemeCodeUnspsc190501.51362004'),
	'identifierSchemeCodeUnspsc190501.51362005': __('identifierSchemeCodeUnspsc190501.51362005'),
	'identifierSchemeCodeUnspsc190501.51362006': __('identifierSchemeCodeUnspsc190501.51362006'),
	'identifierSchemeCodeUnspsc190501.51362007': __('identifierSchemeCodeUnspsc190501.51362007'),
	'identifierSchemeCodeUnspsc190501.51362008': __('identifierSchemeCodeUnspsc190501.51362008'),
	'identifierSchemeCodeUnspsc190501.51362100': __('identifierSchemeCodeUnspsc190501.51362100'),
	'identifierSchemeCodeUnspsc190501.51362101': __('identifierSchemeCodeUnspsc190501.51362101'),
	'identifierSchemeCodeUnspsc190501.51362102': __('identifierSchemeCodeUnspsc190501.51362102'),
	'identifierSchemeCodeUnspsc190501.51362103': __('identifierSchemeCodeUnspsc190501.51362103'),
	'identifierSchemeCodeUnspsc190501.51362200': __('identifierSchemeCodeUnspsc190501.51362200'),
	'identifierSchemeCodeUnspsc190501.51362201': __('identifierSchemeCodeUnspsc190501.51362201'),
	'identifierSchemeCodeUnspsc190501.51362202': __('identifierSchemeCodeUnspsc190501.51362202'),
	'identifierSchemeCodeUnspsc190501.51362203': __('identifierSchemeCodeUnspsc190501.51362203'),
	'identifierSchemeCodeUnspsc190501.51362204': __('identifierSchemeCodeUnspsc190501.51362204'),
	'identifierSchemeCodeUnspsc190501.51362300': __('identifierSchemeCodeUnspsc190501.51362300'),
	'identifierSchemeCodeUnspsc190501.51362301': __('identifierSchemeCodeUnspsc190501.51362301'),
	'identifierSchemeCodeUnspsc190501.51362302': __('identifierSchemeCodeUnspsc190501.51362302'),
	'identifierSchemeCodeUnspsc190501.51362303': __('identifierSchemeCodeUnspsc190501.51362303'),
	'identifierSchemeCodeUnspsc190501.51362304': __('identifierSchemeCodeUnspsc190501.51362304'),
	'identifierSchemeCodeUnspsc190501.51362305': __('identifierSchemeCodeUnspsc190501.51362305'),
	'identifierSchemeCodeUnspsc190501.51362306': __('identifierSchemeCodeUnspsc190501.51362306'),
	'identifierSchemeCodeUnspsc190501.51362307': __('identifierSchemeCodeUnspsc190501.51362307'),
	'identifierSchemeCodeUnspsc190501.51362400': __('identifierSchemeCodeUnspsc190501.51362400'),
	'identifierSchemeCodeUnspsc190501.51362401': __('identifierSchemeCodeUnspsc190501.51362401'),
	'identifierSchemeCodeUnspsc190501.51362402': __('identifierSchemeCodeUnspsc190501.51362402'),
	'identifierSchemeCodeUnspsc190501.51362403': __('identifierSchemeCodeUnspsc190501.51362403'),
	'identifierSchemeCodeUnspsc190501.51362404': __('identifierSchemeCodeUnspsc190501.51362404'),
	'identifierSchemeCodeUnspsc190501.51362405': __('identifierSchemeCodeUnspsc190501.51362405'),
	'identifierSchemeCodeUnspsc190501.51362406': __('identifierSchemeCodeUnspsc190501.51362406'),
	'identifierSchemeCodeUnspsc190501.51362407': __('identifierSchemeCodeUnspsc190501.51362407'),
	'identifierSchemeCodeUnspsc190501.51362500': __('identifierSchemeCodeUnspsc190501.51362500'),
	'identifierSchemeCodeUnspsc190501.51362501': __('identifierSchemeCodeUnspsc190501.51362501'),
	'identifierSchemeCodeUnspsc190501.51362502': __('identifierSchemeCodeUnspsc190501.51362502'),
	'identifierSchemeCodeUnspsc190501.51362503': __('identifierSchemeCodeUnspsc190501.51362503'),
	'identifierSchemeCodeUnspsc190501.51362504': __('identifierSchemeCodeUnspsc190501.51362504'),
	'identifierSchemeCodeUnspsc190501.51362505': __('identifierSchemeCodeUnspsc190501.51362505'),
	'identifierSchemeCodeUnspsc190501.51362506': __('identifierSchemeCodeUnspsc190501.51362506'),
	'identifierSchemeCodeUnspsc190501.51362507': __('identifierSchemeCodeUnspsc190501.51362507'),
	'identifierSchemeCodeUnspsc190501.51362508': __('identifierSchemeCodeUnspsc190501.51362508'),
	'identifierSchemeCodeUnspsc190501.51362509': __('identifierSchemeCodeUnspsc190501.51362509'),
	'identifierSchemeCodeUnspsc190501.51362510': __('identifierSchemeCodeUnspsc190501.51362510'),
	'identifierSchemeCodeUnspsc190501.51362600': __('identifierSchemeCodeUnspsc190501.51362600'),
	'identifierSchemeCodeUnspsc190501.51362601': __('identifierSchemeCodeUnspsc190501.51362601'),
	'identifierSchemeCodeUnspsc190501.51362602': __('identifierSchemeCodeUnspsc190501.51362602'),
	'identifierSchemeCodeUnspsc190501.51362603': __('identifierSchemeCodeUnspsc190501.51362603'),
	'identifierSchemeCodeUnspsc190501.51362700': __('identifierSchemeCodeUnspsc190501.51362700'),
	'identifierSchemeCodeUnspsc190501.51362701': __('identifierSchemeCodeUnspsc190501.51362701'),
	'identifierSchemeCodeUnspsc190501.51362702': __('identifierSchemeCodeUnspsc190501.51362702'),
	'identifierSchemeCodeUnspsc190501.51362800': __('identifierSchemeCodeUnspsc190501.51362800'),
	'identifierSchemeCodeUnspsc190501.51362801': __('identifierSchemeCodeUnspsc190501.51362801'),
	'identifierSchemeCodeUnspsc190501.51362802': __('identifierSchemeCodeUnspsc190501.51362802'),
	'identifierSchemeCodeUnspsc190501.51362803': __('identifierSchemeCodeUnspsc190501.51362803'),
	'identifierSchemeCodeUnspsc190501.51363000': __('identifierSchemeCodeUnspsc190501.51363000'),
	'identifierSchemeCodeUnspsc190501.51363001': __('identifierSchemeCodeUnspsc190501.51363001'),
	'identifierSchemeCodeUnspsc190501.51363002': __('identifierSchemeCodeUnspsc190501.51363002'),
	'identifierSchemeCodeUnspsc190501.51363003': __('identifierSchemeCodeUnspsc190501.51363003'),
	'identifierSchemeCodeUnspsc190501.51363004': __('identifierSchemeCodeUnspsc190501.51363004'),
	'identifierSchemeCodeUnspsc190501.51363100': __('identifierSchemeCodeUnspsc190501.51363100'),
	'identifierSchemeCodeUnspsc190501.51363101': __('identifierSchemeCodeUnspsc190501.51363101'),
	'identifierSchemeCodeUnspsc190501.51363102': __('identifierSchemeCodeUnspsc190501.51363102'),
	'identifierSchemeCodeUnspsc190501.51363103': __('identifierSchemeCodeUnspsc190501.51363103'),
	'identifierSchemeCodeUnspsc190501.51363104': __('identifierSchemeCodeUnspsc190501.51363104'),
	'identifierSchemeCodeUnspsc190501.51363200': __('identifierSchemeCodeUnspsc190501.51363200'),
	'identifierSchemeCodeUnspsc190501.51363201': __('identifierSchemeCodeUnspsc190501.51363201'),
	'identifierSchemeCodeUnspsc190501.51363202': __('identifierSchemeCodeUnspsc190501.51363202'),
	'identifierSchemeCodeUnspsc190501.51363300': __('identifierSchemeCodeUnspsc190501.51363300'),
	'identifierSchemeCodeUnspsc190501.51363301': __('identifierSchemeCodeUnspsc190501.51363301'),
	'identifierSchemeCodeUnspsc190501.51363302': __('identifierSchemeCodeUnspsc190501.51363302'),
	'identifierSchemeCodeUnspsc190501.51363303': __('identifierSchemeCodeUnspsc190501.51363303'),
	'identifierSchemeCodeUnspsc190501.51363304': __('identifierSchemeCodeUnspsc190501.51363304'),
	'identifierSchemeCodeUnspsc190501.51363400': __('identifierSchemeCodeUnspsc190501.51363400'),
	'identifierSchemeCodeUnspsc190501.51363401': __('identifierSchemeCodeUnspsc190501.51363401'),
	'identifierSchemeCodeUnspsc190501.51363402': __('identifierSchemeCodeUnspsc190501.51363402'),
	'identifierSchemeCodeUnspsc190501.51363500': __('identifierSchemeCodeUnspsc190501.51363500'),
	'identifierSchemeCodeUnspsc190501.51363501': __('identifierSchemeCodeUnspsc190501.51363501'),
	'identifierSchemeCodeUnspsc190501.51363502': __('identifierSchemeCodeUnspsc190501.51363502'),
	'identifierSchemeCodeUnspsc190501.51363503': __('identifierSchemeCodeUnspsc190501.51363503'),
	'identifierSchemeCodeUnspsc190501.51363504': __('identifierSchemeCodeUnspsc190501.51363504'),
	'identifierSchemeCodeUnspsc190501.51363505': __('identifierSchemeCodeUnspsc190501.51363505'),
	'identifierSchemeCodeUnspsc190501.51363600': __('identifierSchemeCodeUnspsc190501.51363600'),
	'identifierSchemeCodeUnspsc190501.51363601': __('identifierSchemeCodeUnspsc190501.51363601'),
	'identifierSchemeCodeUnspsc190501.51363602': __('identifierSchemeCodeUnspsc190501.51363602'),
	'identifierSchemeCodeUnspsc190501.51370000': __('identifierSchemeCodeUnspsc190501.51370000'),
	'identifierSchemeCodeUnspsc190501.51371500': __('identifierSchemeCodeUnspsc190501.51371500'),
	'identifierSchemeCodeUnspsc190501.51371501': __('identifierSchemeCodeUnspsc190501.51371501'),
	'identifierSchemeCodeUnspsc190501.51371502': __('identifierSchemeCodeUnspsc190501.51371502'),
	'identifierSchemeCodeUnspsc190501.51371503': __('identifierSchemeCodeUnspsc190501.51371503'),
	'identifierSchemeCodeUnspsc190501.51371504': __('identifierSchemeCodeUnspsc190501.51371504'),
	'identifierSchemeCodeUnspsc190501.51371505': __('identifierSchemeCodeUnspsc190501.51371505'),
	'identifierSchemeCodeUnspsc190501.51371506': __('identifierSchemeCodeUnspsc190501.51371506'),
	'identifierSchemeCodeUnspsc190501.51371507': __('identifierSchemeCodeUnspsc190501.51371507'),
	'identifierSchemeCodeUnspsc190501.51371508': __('identifierSchemeCodeUnspsc190501.51371508'),
	'identifierSchemeCodeUnspsc190501.51371509': __('identifierSchemeCodeUnspsc190501.51371509'),
	'identifierSchemeCodeUnspsc190501.51371510': __('identifierSchemeCodeUnspsc190501.51371510'),
	'identifierSchemeCodeUnspsc190501.51371600': __('identifierSchemeCodeUnspsc190501.51371600'),
	'identifierSchemeCodeUnspsc190501.51371601': __('identifierSchemeCodeUnspsc190501.51371601'),
	'identifierSchemeCodeUnspsc190501.51371602': __('identifierSchemeCodeUnspsc190501.51371602'),
	'identifierSchemeCodeUnspsc190501.51371603': __('identifierSchemeCodeUnspsc190501.51371603'),
	'identifierSchemeCodeUnspsc190501.51371604': __('identifierSchemeCodeUnspsc190501.51371604'),
	'identifierSchemeCodeUnspsc190501.51371700': __('identifierSchemeCodeUnspsc190501.51371700'),
	'identifierSchemeCodeUnspsc190501.51371701': __('identifierSchemeCodeUnspsc190501.51371701'),
	'identifierSchemeCodeUnspsc190501.51371702': __('identifierSchemeCodeUnspsc190501.51371702'),
	'identifierSchemeCodeUnspsc190501.51371703': __('identifierSchemeCodeUnspsc190501.51371703'),
	'identifierSchemeCodeUnspsc190501.51371800': __('identifierSchemeCodeUnspsc190501.51371800'),
	'identifierSchemeCodeUnspsc190501.51371801': __('identifierSchemeCodeUnspsc190501.51371801'),
	'identifierSchemeCodeUnspsc190501.51371802': __('identifierSchemeCodeUnspsc190501.51371802'),
	'identifierSchemeCodeUnspsc190501.51371803': __('identifierSchemeCodeUnspsc190501.51371803'),
	'identifierSchemeCodeUnspsc190501.51371804': __('identifierSchemeCodeUnspsc190501.51371804'),
	'identifierSchemeCodeUnspsc190501.51371805': __('identifierSchemeCodeUnspsc190501.51371805'),
	'identifierSchemeCodeUnspsc190501.51371806': __('identifierSchemeCodeUnspsc190501.51371806'),
	'identifierSchemeCodeUnspsc190501.51371807': __('identifierSchemeCodeUnspsc190501.51371807'),
	'identifierSchemeCodeUnspsc190501.51371900': __('identifierSchemeCodeUnspsc190501.51371900'),
	'identifierSchemeCodeUnspsc190501.51371901': __('identifierSchemeCodeUnspsc190501.51371901'),
	'identifierSchemeCodeUnspsc190501.51371902': __('identifierSchemeCodeUnspsc190501.51371902'),
	'identifierSchemeCodeUnspsc190501.51371903': __('identifierSchemeCodeUnspsc190501.51371903'),
	'identifierSchemeCodeUnspsc190501.51371904': __('identifierSchemeCodeUnspsc190501.51371904'),
	'identifierSchemeCodeUnspsc190501.51371905': __('identifierSchemeCodeUnspsc190501.51371905'),
	'identifierSchemeCodeUnspsc190501.51371906': __('identifierSchemeCodeUnspsc190501.51371906'),
	'identifierSchemeCodeUnspsc190501.51371907': __('identifierSchemeCodeUnspsc190501.51371907'),
	'identifierSchemeCodeUnspsc190501.51371908': __('identifierSchemeCodeUnspsc190501.51371908'),
	'identifierSchemeCodeUnspsc190501.51371909': __('identifierSchemeCodeUnspsc190501.51371909'),
	'identifierSchemeCodeUnspsc190501.51371910': __('identifierSchemeCodeUnspsc190501.51371910'),
	'identifierSchemeCodeUnspsc190501.51372000': __('identifierSchemeCodeUnspsc190501.51372000'),
	'identifierSchemeCodeUnspsc190501.51372001': __('identifierSchemeCodeUnspsc190501.51372001'),
	'identifierSchemeCodeUnspsc190501.51372002': __('identifierSchemeCodeUnspsc190501.51372002'),
	'identifierSchemeCodeUnspsc190501.51372003': __('identifierSchemeCodeUnspsc190501.51372003'),
	'identifierSchemeCodeUnspsc190501.51372004': __('identifierSchemeCodeUnspsc190501.51372004'),
	'identifierSchemeCodeUnspsc190501.51372005': __('identifierSchemeCodeUnspsc190501.51372005'),
	'identifierSchemeCodeUnspsc190501.51372006': __('identifierSchemeCodeUnspsc190501.51372006'),
	'identifierSchemeCodeUnspsc190501.51372007': __('identifierSchemeCodeUnspsc190501.51372007'),
	'identifierSchemeCodeUnspsc190501.51372008': __('identifierSchemeCodeUnspsc190501.51372008'),
	'identifierSchemeCodeUnspsc190501.51372009': __('identifierSchemeCodeUnspsc190501.51372009'),
	'identifierSchemeCodeUnspsc190501.51372010': __('identifierSchemeCodeUnspsc190501.51372010'),
	'identifierSchemeCodeUnspsc190501.51372011': __('identifierSchemeCodeUnspsc190501.51372011'),
	'identifierSchemeCodeUnspsc190501.51372012': __('identifierSchemeCodeUnspsc190501.51372012'),
	'identifierSchemeCodeUnspsc190501.51372013': __('identifierSchemeCodeUnspsc190501.51372013'),
	'identifierSchemeCodeUnspsc190501.51372014': __('identifierSchemeCodeUnspsc190501.51372014'),
	'identifierSchemeCodeUnspsc190501.51372015': __('identifierSchemeCodeUnspsc190501.51372015'),
	'identifierSchemeCodeUnspsc190501.51372016': __('identifierSchemeCodeUnspsc190501.51372016'),
	'identifierSchemeCodeUnspsc190501.51372017': __('identifierSchemeCodeUnspsc190501.51372017'),
	'identifierSchemeCodeUnspsc190501.51372018': __('identifierSchemeCodeUnspsc190501.51372018'),
	'identifierSchemeCodeUnspsc190501.51372019': __('identifierSchemeCodeUnspsc190501.51372019'),
	'identifierSchemeCodeUnspsc190501.51372020': __('identifierSchemeCodeUnspsc190501.51372020'),
	'identifierSchemeCodeUnspsc190501.51372021': __('identifierSchemeCodeUnspsc190501.51372021'),
	'identifierSchemeCodeUnspsc190501.51372022': __('identifierSchemeCodeUnspsc190501.51372022'),
	'identifierSchemeCodeUnspsc190501.51372023': __('identifierSchemeCodeUnspsc190501.51372023'),
	'identifierSchemeCodeUnspsc190501.51372024': __('identifierSchemeCodeUnspsc190501.51372024'),
	'identifierSchemeCodeUnspsc190501.51372025': __('identifierSchemeCodeUnspsc190501.51372025'),
	'identifierSchemeCodeUnspsc190501.51372026': __('identifierSchemeCodeUnspsc190501.51372026'),
	'identifierSchemeCodeUnspsc190501.51372027': __('identifierSchemeCodeUnspsc190501.51372027'),
	'identifierSchemeCodeUnspsc190501.51372028': __('identifierSchemeCodeUnspsc190501.51372028'),
	'identifierSchemeCodeUnspsc190501.51372029': __('identifierSchemeCodeUnspsc190501.51372029'),
	'identifierSchemeCodeUnspsc190501.51372030': __('identifierSchemeCodeUnspsc190501.51372030'),
	'identifierSchemeCodeUnspsc190501.51372031': __('identifierSchemeCodeUnspsc190501.51372031'),
	'identifierSchemeCodeUnspsc190501.51372032': __('identifierSchemeCodeUnspsc190501.51372032'),
	'identifierSchemeCodeUnspsc190501.51372033': __('identifierSchemeCodeUnspsc190501.51372033'),
	'identifierSchemeCodeUnspsc190501.51372034': __('identifierSchemeCodeUnspsc190501.51372034'),
	'identifierSchemeCodeUnspsc190501.51372035': __('identifierSchemeCodeUnspsc190501.51372035'),
	'identifierSchemeCodeUnspsc190501.51372036': __('identifierSchemeCodeUnspsc190501.51372036'),
	'identifierSchemeCodeUnspsc190501.51372037': __('identifierSchemeCodeUnspsc190501.51372037'),
	'identifierSchemeCodeUnspsc190501.51372038': __('identifierSchemeCodeUnspsc190501.51372038'),
	'identifierSchemeCodeUnspsc190501.51372039': __('identifierSchemeCodeUnspsc190501.51372039'),
	'identifierSchemeCodeUnspsc190501.51372040': __('identifierSchemeCodeUnspsc190501.51372040'),
	'identifierSchemeCodeUnspsc190501.51372041': __('identifierSchemeCodeUnspsc190501.51372041'),
	'identifierSchemeCodeUnspsc190501.51372042': __('identifierSchemeCodeUnspsc190501.51372042'),
	'identifierSchemeCodeUnspsc190501.51372100': __('identifierSchemeCodeUnspsc190501.51372100'),
	'identifierSchemeCodeUnspsc190501.51372101': __('identifierSchemeCodeUnspsc190501.51372101'),
	'identifierSchemeCodeUnspsc190501.51372102': __('identifierSchemeCodeUnspsc190501.51372102'),
	'identifierSchemeCodeUnspsc190501.51372103': __('identifierSchemeCodeUnspsc190501.51372103'),
	'identifierSchemeCodeUnspsc190501.51372104': __('identifierSchemeCodeUnspsc190501.51372104'),
	'identifierSchemeCodeUnspsc190501.51372105': __('identifierSchemeCodeUnspsc190501.51372105'),
	'identifierSchemeCodeUnspsc190501.51372106': __('identifierSchemeCodeUnspsc190501.51372106'),
	'identifierSchemeCodeUnspsc190501.51372107': __('identifierSchemeCodeUnspsc190501.51372107'),
	'identifierSchemeCodeUnspsc190501.51372108': __('identifierSchemeCodeUnspsc190501.51372108'),
	'identifierSchemeCodeUnspsc190501.51372109': __('identifierSchemeCodeUnspsc190501.51372109'),
	'identifierSchemeCodeUnspsc190501.51372110': __('identifierSchemeCodeUnspsc190501.51372110'),
	'identifierSchemeCodeUnspsc190501.51372111': __('identifierSchemeCodeUnspsc190501.51372111'),
	'identifierSchemeCodeUnspsc190501.51372112': __('identifierSchemeCodeUnspsc190501.51372112'),
	'identifierSchemeCodeUnspsc190501.51372113': __('identifierSchemeCodeUnspsc190501.51372113'),
	'identifierSchemeCodeUnspsc190501.51372114': __('identifierSchemeCodeUnspsc190501.51372114'),
	'identifierSchemeCodeUnspsc190501.51372115': __('identifierSchemeCodeUnspsc190501.51372115'),
	'identifierSchemeCodeUnspsc190501.51372116': __('identifierSchemeCodeUnspsc190501.51372116'),
	'identifierSchemeCodeUnspsc190501.51372117': __('identifierSchemeCodeUnspsc190501.51372117'),
	'identifierSchemeCodeUnspsc190501.51372118': __('identifierSchemeCodeUnspsc190501.51372118'),
	'identifierSchemeCodeUnspsc190501.51372119': __('identifierSchemeCodeUnspsc190501.51372119'),
	'identifierSchemeCodeUnspsc190501.51372120': __('identifierSchemeCodeUnspsc190501.51372120'),
	'identifierSchemeCodeUnspsc190501.51372121': __('identifierSchemeCodeUnspsc190501.51372121'),
	'identifierSchemeCodeUnspsc190501.51372122': __('identifierSchemeCodeUnspsc190501.51372122'),
	'identifierSchemeCodeUnspsc190501.51372123': __('identifierSchemeCodeUnspsc190501.51372123'),
	'identifierSchemeCodeUnspsc190501.51372124': __('identifierSchemeCodeUnspsc190501.51372124'),
	'identifierSchemeCodeUnspsc190501.51372125': __('identifierSchemeCodeUnspsc190501.51372125'),
	'identifierSchemeCodeUnspsc190501.51372126': __('identifierSchemeCodeUnspsc190501.51372126'),
	'identifierSchemeCodeUnspsc190501.51372127': __('identifierSchemeCodeUnspsc190501.51372127'),
	'identifierSchemeCodeUnspsc190501.51372128': __('identifierSchemeCodeUnspsc190501.51372128'),
	'identifierSchemeCodeUnspsc190501.51372129': __('identifierSchemeCodeUnspsc190501.51372129'),
	'identifierSchemeCodeUnspsc190501.51372200': __('identifierSchemeCodeUnspsc190501.51372200'),
	'identifierSchemeCodeUnspsc190501.51372201': __('identifierSchemeCodeUnspsc190501.51372201'),
	'identifierSchemeCodeUnspsc190501.51372202': __('identifierSchemeCodeUnspsc190501.51372202'),
	'identifierSchemeCodeUnspsc190501.51372203': __('identifierSchemeCodeUnspsc190501.51372203'),
	'identifierSchemeCodeUnspsc190501.51372204': __('identifierSchemeCodeUnspsc190501.51372204'),
	'identifierSchemeCodeUnspsc190501.51372205': __('identifierSchemeCodeUnspsc190501.51372205'),
	'identifierSchemeCodeUnspsc190501.51372206': __('identifierSchemeCodeUnspsc190501.51372206'),
	'identifierSchemeCodeUnspsc190501.51372207': __('identifierSchemeCodeUnspsc190501.51372207'),
	'identifierSchemeCodeUnspsc190501.51372208': __('identifierSchemeCodeUnspsc190501.51372208'),
	'identifierSchemeCodeUnspsc190501.51372209': __('identifierSchemeCodeUnspsc190501.51372209'),
	'identifierSchemeCodeUnspsc190501.51372300': __('identifierSchemeCodeUnspsc190501.51372300'),
	'identifierSchemeCodeUnspsc190501.51372301': __('identifierSchemeCodeUnspsc190501.51372301'),
	'identifierSchemeCodeUnspsc190501.51372302': __('identifierSchemeCodeUnspsc190501.51372302'),
	'identifierSchemeCodeUnspsc190501.51372303': __('identifierSchemeCodeUnspsc190501.51372303'),
	'identifierSchemeCodeUnspsc190501.51372304': __('identifierSchemeCodeUnspsc190501.51372304'),
	'identifierSchemeCodeUnspsc190501.51372305': __('identifierSchemeCodeUnspsc190501.51372305'),
	'identifierSchemeCodeUnspsc190501.51372306': __('identifierSchemeCodeUnspsc190501.51372306'),
	'identifierSchemeCodeUnspsc190501.51372307': __('identifierSchemeCodeUnspsc190501.51372307'),
	'identifierSchemeCodeUnspsc190501.51372308': __('identifierSchemeCodeUnspsc190501.51372308'),
	'identifierSchemeCodeUnspsc190501.51372309': __('identifierSchemeCodeUnspsc190501.51372309'),
	'identifierSchemeCodeUnspsc190501.51372310': __('identifierSchemeCodeUnspsc190501.51372310'),
	'identifierSchemeCodeUnspsc190501.51372311': __('identifierSchemeCodeUnspsc190501.51372311'),
	'identifierSchemeCodeUnspsc190501.51372312': __('identifierSchemeCodeUnspsc190501.51372312'),
	'identifierSchemeCodeUnspsc190501.51372313': __('identifierSchemeCodeUnspsc190501.51372313'),
	'identifierSchemeCodeUnspsc190501.51372314': __('identifierSchemeCodeUnspsc190501.51372314'),
	'identifierSchemeCodeUnspsc190501.51372315': __('identifierSchemeCodeUnspsc190501.51372315'),
	'identifierSchemeCodeUnspsc190501.51372316': __('identifierSchemeCodeUnspsc190501.51372316'),
	'identifierSchemeCodeUnspsc190501.51372317': __('identifierSchemeCodeUnspsc190501.51372317'),
	'identifierSchemeCodeUnspsc190501.51372318': __('identifierSchemeCodeUnspsc190501.51372318'),
	'identifierSchemeCodeUnspsc190501.51372319': __('identifierSchemeCodeUnspsc190501.51372319'),
	'identifierSchemeCodeUnspsc190501.51372320': __('identifierSchemeCodeUnspsc190501.51372320'),
	'identifierSchemeCodeUnspsc190501.51372321': __('identifierSchemeCodeUnspsc190501.51372321'),
	'identifierSchemeCodeUnspsc190501.51372322': __('identifierSchemeCodeUnspsc190501.51372322'),
	'identifierSchemeCodeUnspsc190501.51372323': __('identifierSchemeCodeUnspsc190501.51372323'),
	'identifierSchemeCodeUnspsc190501.51372324': __('identifierSchemeCodeUnspsc190501.51372324'),
	'identifierSchemeCodeUnspsc190501.51372325': __('identifierSchemeCodeUnspsc190501.51372325'),
	'identifierSchemeCodeUnspsc190501.51372326': __('identifierSchemeCodeUnspsc190501.51372326'),
	'identifierSchemeCodeUnspsc190501.51372400': __('identifierSchemeCodeUnspsc190501.51372400'),
	'identifierSchemeCodeUnspsc190501.51372402': __('identifierSchemeCodeUnspsc190501.51372402'),
	'identifierSchemeCodeUnspsc190501.51372500': __('identifierSchemeCodeUnspsc190501.51372500'),
	'identifierSchemeCodeUnspsc190501.51372501': __('identifierSchemeCodeUnspsc190501.51372501'),
	'identifierSchemeCodeUnspsc190501.51372600': __('identifierSchemeCodeUnspsc190501.51372600'),
	'identifierSchemeCodeUnspsc190501.51372601': __('identifierSchemeCodeUnspsc190501.51372601'),
	'identifierSchemeCodeUnspsc190501.51372602': __('identifierSchemeCodeUnspsc190501.51372602'),
	'identifierSchemeCodeUnspsc190501.51372603': __('identifierSchemeCodeUnspsc190501.51372603'),
	'identifierSchemeCodeUnspsc190501.51372604': __('identifierSchemeCodeUnspsc190501.51372604'),
	'identifierSchemeCodeUnspsc190501.51372605': __('identifierSchemeCodeUnspsc190501.51372605'),
	'identifierSchemeCodeUnspsc190501.51372606': __('identifierSchemeCodeUnspsc190501.51372606'),
	'identifierSchemeCodeUnspsc190501.51372700': __('identifierSchemeCodeUnspsc190501.51372700'),
	'identifierSchemeCodeUnspsc190501.51372701': __('identifierSchemeCodeUnspsc190501.51372701'),
	'identifierSchemeCodeUnspsc190501.51372702': __('identifierSchemeCodeUnspsc190501.51372702'),
	'identifierSchemeCodeUnspsc190501.51372703': __('identifierSchemeCodeUnspsc190501.51372703'),
	'identifierSchemeCodeUnspsc190501.51372800': __('identifierSchemeCodeUnspsc190501.51372800'),
	'identifierSchemeCodeUnspsc190501.51372801': __('identifierSchemeCodeUnspsc190501.51372801'),
	'identifierSchemeCodeUnspsc190501.51372802': __('identifierSchemeCodeUnspsc190501.51372802'),
	'identifierSchemeCodeUnspsc190501.51372803': __('identifierSchemeCodeUnspsc190501.51372803'),
	'identifierSchemeCodeUnspsc190501.51372804': __('identifierSchemeCodeUnspsc190501.51372804'),
	'identifierSchemeCodeUnspsc190501.51372805': __('identifierSchemeCodeUnspsc190501.51372805'),
	'identifierSchemeCodeUnspsc190501.51372806': __('identifierSchemeCodeUnspsc190501.51372806'),
	'identifierSchemeCodeUnspsc190501.51372807': __('identifierSchemeCodeUnspsc190501.51372807'),
	'identifierSchemeCodeUnspsc190501.51372900': __('identifierSchemeCodeUnspsc190501.51372900'),
	'identifierSchemeCodeUnspsc190501.51372901': __('identifierSchemeCodeUnspsc190501.51372901'),
	'identifierSchemeCodeUnspsc190501.51372902': __('identifierSchemeCodeUnspsc190501.51372902'),
	'identifierSchemeCodeUnspsc190501.51372903': __('identifierSchemeCodeUnspsc190501.51372903'),
	'identifierSchemeCodeUnspsc190501.51372904': __('identifierSchemeCodeUnspsc190501.51372904'),
	'identifierSchemeCodeUnspsc190501.51373000': __('identifierSchemeCodeUnspsc190501.51373000'),
	'identifierSchemeCodeUnspsc190501.51373001': __('identifierSchemeCodeUnspsc190501.51373001'),
	'identifierSchemeCodeUnspsc190501.51373002': __('identifierSchemeCodeUnspsc190501.51373002'),
	'identifierSchemeCodeUnspsc190501.51373003': __('identifierSchemeCodeUnspsc190501.51373003'),
	'identifierSchemeCodeUnspsc190501.51373004': __('identifierSchemeCodeUnspsc190501.51373004'),
	'identifierSchemeCodeUnspsc190501.51373005': __('identifierSchemeCodeUnspsc190501.51373005'),
	'identifierSchemeCodeUnspsc190501.51373006': __('identifierSchemeCodeUnspsc190501.51373006'),
	'identifierSchemeCodeUnspsc190501.51373007': __('identifierSchemeCodeUnspsc190501.51373007'),
	'identifierSchemeCodeUnspsc190501.51373008': __('identifierSchemeCodeUnspsc190501.51373008'),
	'identifierSchemeCodeUnspsc190501.51373100': __('identifierSchemeCodeUnspsc190501.51373100'),
	'identifierSchemeCodeUnspsc190501.51373101': __('identifierSchemeCodeUnspsc190501.51373101'),
	'identifierSchemeCodeUnspsc190501.51373102': __('identifierSchemeCodeUnspsc190501.51373102'),
	'identifierSchemeCodeUnspsc190501.51373103': __('identifierSchemeCodeUnspsc190501.51373103'),
	'identifierSchemeCodeUnspsc190501.51373200': __('identifierSchemeCodeUnspsc190501.51373200'),
	'identifierSchemeCodeUnspsc190501.51373201': __('identifierSchemeCodeUnspsc190501.51373201'),
	'identifierSchemeCodeUnspsc190501.51373202': __('identifierSchemeCodeUnspsc190501.51373202'),
	'identifierSchemeCodeUnspsc190501.51373203': __('identifierSchemeCodeUnspsc190501.51373203'),
	'identifierSchemeCodeUnspsc190501.51373204': __('identifierSchemeCodeUnspsc190501.51373204'),
	'identifierSchemeCodeUnspsc190501.51373300': __('identifierSchemeCodeUnspsc190501.51373300'),
	'identifierSchemeCodeUnspsc190501.51373301': __('identifierSchemeCodeUnspsc190501.51373301'),
	'identifierSchemeCodeUnspsc190501.51373302': __('identifierSchemeCodeUnspsc190501.51373302'),
	'identifierSchemeCodeUnspsc190501.51373303': __('identifierSchemeCodeUnspsc190501.51373303'),
	'identifierSchemeCodeUnspsc190501.51373304': __('identifierSchemeCodeUnspsc190501.51373304'),
	'identifierSchemeCodeUnspsc190501.51373305': __('identifierSchemeCodeUnspsc190501.51373305'),
	'identifierSchemeCodeUnspsc190501.51373306': __('identifierSchemeCodeUnspsc190501.51373306'),
	'identifierSchemeCodeUnspsc190501.51373307': __('identifierSchemeCodeUnspsc190501.51373307'),
	'identifierSchemeCodeUnspsc190501.51373308': __('identifierSchemeCodeUnspsc190501.51373308'),
	'identifierSchemeCodeUnspsc190501.51373309': __('identifierSchemeCodeUnspsc190501.51373309'),
	'identifierSchemeCodeUnspsc190501.51373310': __('identifierSchemeCodeUnspsc190501.51373310'),
	'identifierSchemeCodeUnspsc190501.51373311': __('identifierSchemeCodeUnspsc190501.51373311'),
	'identifierSchemeCodeUnspsc190501.51373312': __('identifierSchemeCodeUnspsc190501.51373312'),
	'identifierSchemeCodeUnspsc190501.51373313': __('identifierSchemeCodeUnspsc190501.51373313'),
	'identifierSchemeCodeUnspsc190501.51373314': __('identifierSchemeCodeUnspsc190501.51373314'),
	'identifierSchemeCodeUnspsc190501.51373315': __('identifierSchemeCodeUnspsc190501.51373315'),
	'identifierSchemeCodeUnspsc190501.51373316': __('identifierSchemeCodeUnspsc190501.51373316'),
	'identifierSchemeCodeUnspsc190501.51373317': __('identifierSchemeCodeUnspsc190501.51373317'),
	'identifierSchemeCodeUnspsc190501.51373318': __('identifierSchemeCodeUnspsc190501.51373318'),
	'identifierSchemeCodeUnspsc190501.51373319': __('identifierSchemeCodeUnspsc190501.51373319'),
	'identifierSchemeCodeUnspsc190501.51373320': __('identifierSchemeCodeUnspsc190501.51373320'),
	'identifierSchemeCodeUnspsc190501.51373321': __('identifierSchemeCodeUnspsc190501.51373321'),
	'identifierSchemeCodeUnspsc190501.51373322': __('identifierSchemeCodeUnspsc190501.51373322'),
	'identifierSchemeCodeUnspsc190501.51373323': __('identifierSchemeCodeUnspsc190501.51373323'),
	'identifierSchemeCodeUnspsc190501.51373324': __('identifierSchemeCodeUnspsc190501.51373324'),
	'identifierSchemeCodeUnspsc190501.51373325': __('identifierSchemeCodeUnspsc190501.51373325'),
	'identifierSchemeCodeUnspsc190501.51373326': __('identifierSchemeCodeUnspsc190501.51373326'),
	'identifierSchemeCodeUnspsc190501.51373327': __('identifierSchemeCodeUnspsc190501.51373327'),
	'identifierSchemeCodeUnspsc190501.51373328': __('identifierSchemeCodeUnspsc190501.51373328'),
	'identifierSchemeCodeUnspsc190501.51373329': __('identifierSchemeCodeUnspsc190501.51373329'),
	'identifierSchemeCodeUnspsc190501.51373330': __('identifierSchemeCodeUnspsc190501.51373330'),
	'identifierSchemeCodeUnspsc190501.51373331': __('identifierSchemeCodeUnspsc190501.51373331'),
	'identifierSchemeCodeUnspsc190501.51373332': __('identifierSchemeCodeUnspsc190501.51373332'),
	'identifierSchemeCodeUnspsc190501.51373333': __('identifierSchemeCodeUnspsc190501.51373333'),
	'identifierSchemeCodeUnspsc190501.51373334': __('identifierSchemeCodeUnspsc190501.51373334'),
	'identifierSchemeCodeUnspsc190501.51373335': __('identifierSchemeCodeUnspsc190501.51373335'),
	'identifierSchemeCodeUnspsc190501.51373336': __('identifierSchemeCodeUnspsc190501.51373336'),
	'identifierSchemeCodeUnspsc190501.51373337': __('identifierSchemeCodeUnspsc190501.51373337'),
	'identifierSchemeCodeUnspsc190501.51373338': __('identifierSchemeCodeUnspsc190501.51373338'),
	'identifierSchemeCodeUnspsc190501.51373339': __('identifierSchemeCodeUnspsc190501.51373339'),
	'identifierSchemeCodeUnspsc190501.51373340': __('identifierSchemeCodeUnspsc190501.51373340'),
	'identifierSchemeCodeUnspsc190501.51373400': __('identifierSchemeCodeUnspsc190501.51373400'),
	'identifierSchemeCodeUnspsc190501.51373401': __('identifierSchemeCodeUnspsc190501.51373401'),
	'identifierSchemeCodeUnspsc190501.51373402': __('identifierSchemeCodeUnspsc190501.51373402'),
	'identifierSchemeCodeUnspsc190501.51373403': __('identifierSchemeCodeUnspsc190501.51373403'),
	'identifierSchemeCodeUnspsc190501.51373404': __('identifierSchemeCodeUnspsc190501.51373404'),
	'identifierSchemeCodeUnspsc190501.51373405': __('identifierSchemeCodeUnspsc190501.51373405'),
	'identifierSchemeCodeUnspsc190501.51373406': __('identifierSchemeCodeUnspsc190501.51373406'),
	'identifierSchemeCodeUnspsc190501.51373407': __('identifierSchemeCodeUnspsc190501.51373407'),
	'identifierSchemeCodeUnspsc190501.51373408': __('identifierSchemeCodeUnspsc190501.51373408'),
	'identifierSchemeCodeUnspsc190501.51373409': __('identifierSchemeCodeUnspsc190501.51373409'),
	'identifierSchemeCodeUnspsc190501.51373410': __('identifierSchemeCodeUnspsc190501.51373410'),
	'identifierSchemeCodeUnspsc190501.51373411': __('identifierSchemeCodeUnspsc190501.51373411'),
	'identifierSchemeCodeUnspsc190501.51373412': __('identifierSchemeCodeUnspsc190501.51373412'),
	'identifierSchemeCodeUnspsc190501.51373413': __('identifierSchemeCodeUnspsc190501.51373413'),
	'identifierSchemeCodeUnspsc190501.51373414': __('identifierSchemeCodeUnspsc190501.51373414'),
	'identifierSchemeCodeUnspsc190501.51373415': __('identifierSchemeCodeUnspsc190501.51373415'),
	'identifierSchemeCodeUnspsc190501.51373416': __('identifierSchemeCodeUnspsc190501.51373416'),
	'identifierSchemeCodeUnspsc190501.51373417': __('identifierSchemeCodeUnspsc190501.51373417'),
	'identifierSchemeCodeUnspsc190501.51373418': __('identifierSchemeCodeUnspsc190501.51373418'),
	'identifierSchemeCodeUnspsc190501.51373419': __('identifierSchemeCodeUnspsc190501.51373419'),
	'identifierSchemeCodeUnspsc190501.51373420': __('identifierSchemeCodeUnspsc190501.51373420'),
	'identifierSchemeCodeUnspsc190501.51373421': __('identifierSchemeCodeUnspsc190501.51373421'),
	'identifierSchemeCodeUnspsc190501.51373422': __('identifierSchemeCodeUnspsc190501.51373422'),
	'identifierSchemeCodeUnspsc190501.51373423': __('identifierSchemeCodeUnspsc190501.51373423'),
	'identifierSchemeCodeUnspsc190501.51373424': __('identifierSchemeCodeUnspsc190501.51373424'),
	'identifierSchemeCodeUnspsc190501.51373425': __('identifierSchemeCodeUnspsc190501.51373425'),
	'identifierSchemeCodeUnspsc190501.51373426': __('identifierSchemeCodeUnspsc190501.51373426'),
	'identifierSchemeCodeUnspsc190501.51373427': __('identifierSchemeCodeUnspsc190501.51373427'),
	'identifierSchemeCodeUnspsc190501.51373428': __('identifierSchemeCodeUnspsc190501.51373428'),
	'identifierSchemeCodeUnspsc190501.51373429': __('identifierSchemeCodeUnspsc190501.51373429'),
	'identifierSchemeCodeUnspsc190501.51373430': __('identifierSchemeCodeUnspsc190501.51373430'),
	'identifierSchemeCodeUnspsc190501.51373431': __('identifierSchemeCodeUnspsc190501.51373431'),
	'identifierSchemeCodeUnspsc190501.51373432': __('identifierSchemeCodeUnspsc190501.51373432'),
	'identifierSchemeCodeUnspsc190501.51373433': __('identifierSchemeCodeUnspsc190501.51373433'),
	'identifierSchemeCodeUnspsc190501.51373434': __('identifierSchemeCodeUnspsc190501.51373434'),
	'identifierSchemeCodeUnspsc190501.51373435': __('identifierSchemeCodeUnspsc190501.51373435'),
	'identifierSchemeCodeUnspsc190501.51373436': __('identifierSchemeCodeUnspsc190501.51373436'),
	'identifierSchemeCodeUnspsc190501.51373437': __('identifierSchemeCodeUnspsc190501.51373437'),
	'identifierSchemeCodeUnspsc190501.51373438': __('identifierSchemeCodeUnspsc190501.51373438'),
	'identifierSchemeCodeUnspsc190501.51373439': __('identifierSchemeCodeUnspsc190501.51373439'),
	'identifierSchemeCodeUnspsc190501.51373440': __('identifierSchemeCodeUnspsc190501.51373440'),
	'identifierSchemeCodeUnspsc190501.51373441': __('identifierSchemeCodeUnspsc190501.51373441'),
	'identifierSchemeCodeUnspsc190501.51373442': __('identifierSchemeCodeUnspsc190501.51373442'),
	'identifierSchemeCodeUnspsc190501.51373443': __('identifierSchemeCodeUnspsc190501.51373443'),
	'identifierSchemeCodeUnspsc190501.51373444': __('identifierSchemeCodeUnspsc190501.51373444'),
	'identifierSchemeCodeUnspsc190501.51373445': __('identifierSchemeCodeUnspsc190501.51373445'),
	'identifierSchemeCodeUnspsc190501.51373446': __('identifierSchemeCodeUnspsc190501.51373446'),
	'identifierSchemeCodeUnspsc190501.51373447': __('identifierSchemeCodeUnspsc190501.51373447'),
	'identifierSchemeCodeUnspsc190501.51373448': __('identifierSchemeCodeUnspsc190501.51373448'),
	'identifierSchemeCodeUnspsc190501.51373449': __('identifierSchemeCodeUnspsc190501.51373449'),
	'identifierSchemeCodeUnspsc190501.51373450': __('identifierSchemeCodeUnspsc190501.51373450'),
	'identifierSchemeCodeUnspsc190501.51373451': __('identifierSchemeCodeUnspsc190501.51373451'),
	'identifierSchemeCodeUnspsc190501.51373452': __('identifierSchemeCodeUnspsc190501.51373452'),
	'identifierSchemeCodeUnspsc190501.51373453': __('identifierSchemeCodeUnspsc190501.51373453'),
	'identifierSchemeCodeUnspsc190501.51373454': __('identifierSchemeCodeUnspsc190501.51373454'),
	'identifierSchemeCodeUnspsc190501.51373455': __('identifierSchemeCodeUnspsc190501.51373455'),
	'identifierSchemeCodeUnspsc190501.51373456': __('identifierSchemeCodeUnspsc190501.51373456'),
	'identifierSchemeCodeUnspsc190501.51373457': __('identifierSchemeCodeUnspsc190501.51373457'),
	'identifierSchemeCodeUnspsc190501.51373458': __('identifierSchemeCodeUnspsc190501.51373458'),
	'identifierSchemeCodeUnspsc190501.51373459': __('identifierSchemeCodeUnspsc190501.51373459'),
	'identifierSchemeCodeUnspsc190501.51373460': __('identifierSchemeCodeUnspsc190501.51373460'),
	'identifierSchemeCodeUnspsc190501.51373461': __('identifierSchemeCodeUnspsc190501.51373461'),
	'identifierSchemeCodeUnspsc190501.51373462': __('identifierSchemeCodeUnspsc190501.51373462'),
	'identifierSchemeCodeUnspsc190501.51373463': __('identifierSchemeCodeUnspsc190501.51373463'),
	'identifierSchemeCodeUnspsc190501.51373464': __('identifierSchemeCodeUnspsc190501.51373464'),
	'identifierSchemeCodeUnspsc190501.51373465': __('identifierSchemeCodeUnspsc190501.51373465'),
	'identifierSchemeCodeUnspsc190501.51373466': __('identifierSchemeCodeUnspsc190501.51373466'),
	'identifierSchemeCodeUnspsc190501.51373467': __('identifierSchemeCodeUnspsc190501.51373467'),
	'identifierSchemeCodeUnspsc190501.51373468': __('identifierSchemeCodeUnspsc190501.51373468'),
	'identifierSchemeCodeUnspsc190501.51373469': __('identifierSchemeCodeUnspsc190501.51373469'),
	'identifierSchemeCodeUnspsc190501.51373470': __('identifierSchemeCodeUnspsc190501.51373470'),
	'identifierSchemeCodeUnspsc190501.51373471': __('identifierSchemeCodeUnspsc190501.51373471'),
	'identifierSchemeCodeUnspsc190501.51373472': __('identifierSchemeCodeUnspsc190501.51373472'),
	'identifierSchemeCodeUnspsc190501.51373473': __('identifierSchemeCodeUnspsc190501.51373473'),
	'identifierSchemeCodeUnspsc190501.51373474': __('identifierSchemeCodeUnspsc190501.51373474'),
	'identifierSchemeCodeUnspsc190501.51373475': __('identifierSchemeCodeUnspsc190501.51373475'),
	'identifierSchemeCodeUnspsc190501.51373476': __('identifierSchemeCodeUnspsc190501.51373476'),
	'identifierSchemeCodeUnspsc190501.51373477': __('identifierSchemeCodeUnspsc190501.51373477'),
	'identifierSchemeCodeUnspsc190501.51373478': __('identifierSchemeCodeUnspsc190501.51373478'),
	'identifierSchemeCodeUnspsc190501.51380000': __('identifierSchemeCodeUnspsc190501.51380000'),
	'identifierSchemeCodeUnspsc190501.51381500': __('identifierSchemeCodeUnspsc190501.51381500'),
	'identifierSchemeCodeUnspsc190501.51381501': __('identifierSchemeCodeUnspsc190501.51381501'),
	'identifierSchemeCodeUnspsc190501.51381502': __('identifierSchemeCodeUnspsc190501.51381502'),
	'identifierSchemeCodeUnspsc190501.51381503': __('identifierSchemeCodeUnspsc190501.51381503'),
	'identifierSchemeCodeUnspsc190501.51381504': __('identifierSchemeCodeUnspsc190501.51381504'),
	'identifierSchemeCodeUnspsc190501.51381505': __('identifierSchemeCodeUnspsc190501.51381505'),
	'identifierSchemeCodeUnspsc190501.51381506': __('identifierSchemeCodeUnspsc190501.51381506'),
	'identifierSchemeCodeUnspsc190501.51381507': __('identifierSchemeCodeUnspsc190501.51381507'),
	'identifierSchemeCodeUnspsc190501.51381508': __('identifierSchemeCodeUnspsc190501.51381508'),
	'identifierSchemeCodeUnspsc190501.51381509': __('identifierSchemeCodeUnspsc190501.51381509'),
	'identifierSchemeCodeUnspsc190501.51381510': __('identifierSchemeCodeUnspsc190501.51381510'),
	'identifierSchemeCodeUnspsc190501.51381511': __('identifierSchemeCodeUnspsc190501.51381511'),
	'identifierSchemeCodeUnspsc190501.51381512': __('identifierSchemeCodeUnspsc190501.51381512'),
	'identifierSchemeCodeUnspsc190501.51381513': __('identifierSchemeCodeUnspsc190501.51381513'),
	'identifierSchemeCodeUnspsc190501.51381514': __('identifierSchemeCodeUnspsc190501.51381514'),
	'identifierSchemeCodeUnspsc190501.51381515': __('identifierSchemeCodeUnspsc190501.51381515'),
	'identifierSchemeCodeUnspsc190501.51381516': __('identifierSchemeCodeUnspsc190501.51381516'),
	'identifierSchemeCodeUnspsc190501.51381600': __('identifierSchemeCodeUnspsc190501.51381600'),
	'identifierSchemeCodeUnspsc190501.51381601': __('identifierSchemeCodeUnspsc190501.51381601'),
	'identifierSchemeCodeUnspsc190501.51381602': __('identifierSchemeCodeUnspsc190501.51381602'),
	'identifierSchemeCodeUnspsc190501.51381700': __('identifierSchemeCodeUnspsc190501.51381700'),
	'identifierSchemeCodeUnspsc190501.51381701': __('identifierSchemeCodeUnspsc190501.51381701'),
	'identifierSchemeCodeUnspsc190501.51381702': __('identifierSchemeCodeUnspsc190501.51381702'),
	'identifierSchemeCodeUnspsc190501.51381703': __('identifierSchemeCodeUnspsc190501.51381703'),
	'identifierSchemeCodeUnspsc190501.51381704': __('identifierSchemeCodeUnspsc190501.51381704'),
	'identifierSchemeCodeUnspsc190501.51381705': __('identifierSchemeCodeUnspsc190501.51381705'),
	'identifierSchemeCodeUnspsc190501.51381800': __('identifierSchemeCodeUnspsc190501.51381800'),
	'identifierSchemeCodeUnspsc190501.51381801': __('identifierSchemeCodeUnspsc190501.51381801'),
	'identifierSchemeCodeUnspsc190501.51381802': __('identifierSchemeCodeUnspsc190501.51381802'),
	'identifierSchemeCodeUnspsc190501.51381803': __('identifierSchemeCodeUnspsc190501.51381803'),
	'identifierSchemeCodeUnspsc190501.51381900': __('identifierSchemeCodeUnspsc190501.51381900'),
	'identifierSchemeCodeUnspsc190501.51381901': __('identifierSchemeCodeUnspsc190501.51381901'),
	'identifierSchemeCodeUnspsc190501.51381902': __('identifierSchemeCodeUnspsc190501.51381902'),
	'identifierSchemeCodeUnspsc190501.51381903': __('identifierSchemeCodeUnspsc190501.51381903'),
	'identifierSchemeCodeUnspsc190501.51381904': __('identifierSchemeCodeUnspsc190501.51381904'),
	'identifierSchemeCodeUnspsc190501.51381905': __('identifierSchemeCodeUnspsc190501.51381905'),
	'identifierSchemeCodeUnspsc190501.51381906': __('identifierSchemeCodeUnspsc190501.51381906'),
	'identifierSchemeCodeUnspsc190501.51381907': __('identifierSchemeCodeUnspsc190501.51381907'),
	'identifierSchemeCodeUnspsc190501.51381908': __('identifierSchemeCodeUnspsc190501.51381908'),
	'identifierSchemeCodeUnspsc190501.51381909': __('identifierSchemeCodeUnspsc190501.51381909'),
	'identifierSchemeCodeUnspsc190501.51381910': __('identifierSchemeCodeUnspsc190501.51381910'),
	'identifierSchemeCodeUnspsc190501.51382000': __('identifierSchemeCodeUnspsc190501.51382000'),
	'identifierSchemeCodeUnspsc190501.51382001': __('identifierSchemeCodeUnspsc190501.51382001'),
	'identifierSchemeCodeUnspsc190501.51382002': __('identifierSchemeCodeUnspsc190501.51382002'),
	'identifierSchemeCodeUnspsc190501.51382003': __('identifierSchemeCodeUnspsc190501.51382003'),
	'identifierSchemeCodeUnspsc190501.51382004': __('identifierSchemeCodeUnspsc190501.51382004'),
	'identifierSchemeCodeUnspsc190501.51382100': __('identifierSchemeCodeUnspsc190501.51382100'),
	'identifierSchemeCodeUnspsc190501.51382101': __('identifierSchemeCodeUnspsc190501.51382101'),
	'identifierSchemeCodeUnspsc190501.51382102': __('identifierSchemeCodeUnspsc190501.51382102'),
	'identifierSchemeCodeUnspsc190501.51382103': __('identifierSchemeCodeUnspsc190501.51382103'),
	'identifierSchemeCodeUnspsc190501.51382200': __('identifierSchemeCodeUnspsc190501.51382200'),
	'identifierSchemeCodeUnspsc190501.51382201': __('identifierSchemeCodeUnspsc190501.51382201'),
	'identifierSchemeCodeUnspsc190501.51382202': __('identifierSchemeCodeUnspsc190501.51382202'),
	'identifierSchemeCodeUnspsc190501.51382300': __('identifierSchemeCodeUnspsc190501.51382300'),
	'identifierSchemeCodeUnspsc190501.51382301': __('identifierSchemeCodeUnspsc190501.51382301'),
	'identifierSchemeCodeUnspsc190501.51382302': __('identifierSchemeCodeUnspsc190501.51382302'),
	'identifierSchemeCodeUnspsc190501.51382303': __('identifierSchemeCodeUnspsc190501.51382303'),
	'identifierSchemeCodeUnspsc190501.51382304': __('identifierSchemeCodeUnspsc190501.51382304'),
	'identifierSchemeCodeUnspsc190501.51382305': __('identifierSchemeCodeUnspsc190501.51382305'),
	'identifierSchemeCodeUnspsc190501.51382400': __('identifierSchemeCodeUnspsc190501.51382400'),
	'identifierSchemeCodeUnspsc190501.51382401': __('identifierSchemeCodeUnspsc190501.51382401'),
	'identifierSchemeCodeUnspsc190501.51382402': __('identifierSchemeCodeUnspsc190501.51382402'),
	'identifierSchemeCodeUnspsc190501.51382403': __('identifierSchemeCodeUnspsc190501.51382403'),
	'identifierSchemeCodeUnspsc190501.51382404': __('identifierSchemeCodeUnspsc190501.51382404'),
	'identifierSchemeCodeUnspsc190501.51382405': __('identifierSchemeCodeUnspsc190501.51382405'),
	'identifierSchemeCodeUnspsc190501.51382406': __('identifierSchemeCodeUnspsc190501.51382406'),
	'identifierSchemeCodeUnspsc190501.51382500': __('identifierSchemeCodeUnspsc190501.51382500'),
	'identifierSchemeCodeUnspsc190501.51382501': __('identifierSchemeCodeUnspsc190501.51382501'),
	'identifierSchemeCodeUnspsc190501.51382502': __('identifierSchemeCodeUnspsc190501.51382502'),
	'identifierSchemeCodeUnspsc190501.51382503': __('identifierSchemeCodeUnspsc190501.51382503'),
	'identifierSchemeCodeUnspsc190501.51382504': __('identifierSchemeCodeUnspsc190501.51382504'),
	'identifierSchemeCodeUnspsc190501.51382600': __('identifierSchemeCodeUnspsc190501.51382600'),
	'identifierSchemeCodeUnspsc190501.51382601': __('identifierSchemeCodeUnspsc190501.51382601'),
	'identifierSchemeCodeUnspsc190501.51382602': __('identifierSchemeCodeUnspsc190501.51382602'),
	'identifierSchemeCodeUnspsc190501.51382603': __('identifierSchemeCodeUnspsc190501.51382603'),
	'identifierSchemeCodeUnspsc190501.51382700': __('identifierSchemeCodeUnspsc190501.51382700'),
	'identifierSchemeCodeUnspsc190501.51382701': __('identifierSchemeCodeUnspsc190501.51382701'),
	'identifierSchemeCodeUnspsc190501.51382702': __('identifierSchemeCodeUnspsc190501.51382702'),
	'identifierSchemeCodeUnspsc190501.51382703': __('identifierSchemeCodeUnspsc190501.51382703'),
	'identifierSchemeCodeUnspsc190501.51382704': __('identifierSchemeCodeUnspsc190501.51382704'),
	'identifierSchemeCodeUnspsc190501.51382705': __('identifierSchemeCodeUnspsc190501.51382705'),
	'identifierSchemeCodeUnspsc190501.51382800': __('identifierSchemeCodeUnspsc190501.51382800'),
	'identifierSchemeCodeUnspsc190501.51382801': __('identifierSchemeCodeUnspsc190501.51382801'),
	'identifierSchemeCodeUnspsc190501.51382802': __('identifierSchemeCodeUnspsc190501.51382802'),
	'identifierSchemeCodeUnspsc190501.51382803': __('identifierSchemeCodeUnspsc190501.51382803'),
	'identifierSchemeCodeUnspsc190501.51382804': __('identifierSchemeCodeUnspsc190501.51382804'),
	'identifierSchemeCodeUnspsc190501.51382900': __('identifierSchemeCodeUnspsc190501.51382900'),
	'identifierSchemeCodeUnspsc190501.51382901': __('identifierSchemeCodeUnspsc190501.51382901'),
	'identifierSchemeCodeUnspsc190501.51382902': __('identifierSchemeCodeUnspsc190501.51382902'),
	'identifierSchemeCodeUnspsc190501.51382903': __('identifierSchemeCodeUnspsc190501.51382903'),
	'identifierSchemeCodeUnspsc190501.51382904': __('identifierSchemeCodeUnspsc190501.51382904'),
	'identifierSchemeCodeUnspsc190501.51382905': __('identifierSchemeCodeUnspsc190501.51382905'),
	'identifierSchemeCodeUnspsc190501.51382906': __('identifierSchemeCodeUnspsc190501.51382906'),
	'identifierSchemeCodeUnspsc190501.51383000': __('identifierSchemeCodeUnspsc190501.51383000'),
	'identifierSchemeCodeUnspsc190501.51383001': __('identifierSchemeCodeUnspsc190501.51383001'),
	'identifierSchemeCodeUnspsc190501.51383002': __('identifierSchemeCodeUnspsc190501.51383002'),
	'identifierSchemeCodeUnspsc190501.51383003': __('identifierSchemeCodeUnspsc190501.51383003'),
	'identifierSchemeCodeUnspsc190501.51383100': __('identifierSchemeCodeUnspsc190501.51383100'),
	'identifierSchemeCodeUnspsc190501.51383101': __('identifierSchemeCodeUnspsc190501.51383101'),
	'identifierSchemeCodeUnspsc190501.51383102': __('identifierSchemeCodeUnspsc190501.51383102'),
	'identifierSchemeCodeUnspsc190501.51383103': __('identifierSchemeCodeUnspsc190501.51383103'),
	'identifierSchemeCodeUnspsc190501.51383200': __('identifierSchemeCodeUnspsc190501.51383200'),
	'identifierSchemeCodeUnspsc190501.51383201': __('identifierSchemeCodeUnspsc190501.51383201'),
	'identifierSchemeCodeUnspsc190501.51383202': __('identifierSchemeCodeUnspsc190501.51383202'),
	'identifierSchemeCodeUnspsc190501.51383300': __('identifierSchemeCodeUnspsc190501.51383300'),
	'identifierSchemeCodeUnspsc190501.51383301': __('identifierSchemeCodeUnspsc190501.51383301'),
	'identifierSchemeCodeUnspsc190501.51383302': __('identifierSchemeCodeUnspsc190501.51383302'),
	'identifierSchemeCodeUnspsc190501.51383303': __('identifierSchemeCodeUnspsc190501.51383303'),
	'identifierSchemeCodeUnspsc190501.51383304': __('identifierSchemeCodeUnspsc190501.51383304'),
	'identifierSchemeCodeUnspsc190501.51383305': __('identifierSchemeCodeUnspsc190501.51383305'),
	'identifierSchemeCodeUnspsc190501.51383306': __('identifierSchemeCodeUnspsc190501.51383306'),
	'identifierSchemeCodeUnspsc190501.51383307': __('identifierSchemeCodeUnspsc190501.51383307'),
	'identifierSchemeCodeUnspsc190501.51383308': __('identifierSchemeCodeUnspsc190501.51383308'),
	'identifierSchemeCodeUnspsc190501.51383309': __('identifierSchemeCodeUnspsc190501.51383309'),
	'identifierSchemeCodeUnspsc190501.51383310': __('identifierSchemeCodeUnspsc190501.51383310'),
	'identifierSchemeCodeUnspsc190501.51383311': __('identifierSchemeCodeUnspsc190501.51383311'),
	'identifierSchemeCodeUnspsc190501.51383312': __('identifierSchemeCodeUnspsc190501.51383312'),
	'identifierSchemeCodeUnspsc190501.51383313': __('identifierSchemeCodeUnspsc190501.51383313'),
	'identifierSchemeCodeUnspsc190501.51383314': __('identifierSchemeCodeUnspsc190501.51383314'),
	'identifierSchemeCodeUnspsc190501.51383315': __('identifierSchemeCodeUnspsc190501.51383315'),
	'identifierSchemeCodeUnspsc190501.51383316': __('identifierSchemeCodeUnspsc190501.51383316'),
	'identifierSchemeCodeUnspsc190501.51383317': __('identifierSchemeCodeUnspsc190501.51383317'),
	'identifierSchemeCodeUnspsc190501.51383400': __('identifierSchemeCodeUnspsc190501.51383400'),
	'identifierSchemeCodeUnspsc190501.51383401': __('identifierSchemeCodeUnspsc190501.51383401'),
	'identifierSchemeCodeUnspsc190501.51383402': __('identifierSchemeCodeUnspsc190501.51383402'),
	'identifierSchemeCodeUnspsc190501.51383403': __('identifierSchemeCodeUnspsc190501.51383403'),
	'identifierSchemeCodeUnspsc190501.51383404': __('identifierSchemeCodeUnspsc190501.51383404'),
	'identifierSchemeCodeUnspsc190501.51383405': __('identifierSchemeCodeUnspsc190501.51383405'),
	'identifierSchemeCodeUnspsc190501.51383406': __('identifierSchemeCodeUnspsc190501.51383406'),
	'identifierSchemeCodeUnspsc190501.51383500': __('identifierSchemeCodeUnspsc190501.51383500'),
	'identifierSchemeCodeUnspsc190501.51383501': __('identifierSchemeCodeUnspsc190501.51383501'),
	'identifierSchemeCodeUnspsc190501.51383502': __('identifierSchemeCodeUnspsc190501.51383502'),
	'identifierSchemeCodeUnspsc190501.51383503': __('identifierSchemeCodeUnspsc190501.51383503'),
	'identifierSchemeCodeUnspsc190501.51383504': __('identifierSchemeCodeUnspsc190501.51383504'),
	'identifierSchemeCodeUnspsc190501.51383505': __('identifierSchemeCodeUnspsc190501.51383505'),
	'identifierSchemeCodeUnspsc190501.51383506': __('identifierSchemeCodeUnspsc190501.51383506'),
	'identifierSchemeCodeUnspsc190501.51383507': __('identifierSchemeCodeUnspsc190501.51383507'),
	'identifierSchemeCodeUnspsc190501.51383508': __('identifierSchemeCodeUnspsc190501.51383508'),
	'identifierSchemeCodeUnspsc190501.51383509': __('identifierSchemeCodeUnspsc190501.51383509'),
	'identifierSchemeCodeUnspsc190501.51383510': __('identifierSchemeCodeUnspsc190501.51383510'),
	'identifierSchemeCodeUnspsc190501.51383511': __('identifierSchemeCodeUnspsc190501.51383511'),
	'identifierSchemeCodeUnspsc190501.51383512': __('identifierSchemeCodeUnspsc190501.51383512'),
	'identifierSchemeCodeUnspsc190501.51383700': __('identifierSchemeCodeUnspsc190501.51383700'),
	'identifierSchemeCodeUnspsc190501.51383701': __('identifierSchemeCodeUnspsc190501.51383701'),
	'identifierSchemeCodeUnspsc190501.51383702': __('identifierSchemeCodeUnspsc190501.51383702'),
	'identifierSchemeCodeUnspsc190501.51383703': __('identifierSchemeCodeUnspsc190501.51383703'),
	'identifierSchemeCodeUnspsc190501.51383704': __('identifierSchemeCodeUnspsc190501.51383704'),
	'identifierSchemeCodeUnspsc190501.51383705': __('identifierSchemeCodeUnspsc190501.51383705'),
	'identifierSchemeCodeUnspsc190501.51383706': __('identifierSchemeCodeUnspsc190501.51383706'),
	'identifierSchemeCodeUnspsc190501.51383800': __('identifierSchemeCodeUnspsc190501.51383800'),
	'identifierSchemeCodeUnspsc190501.51383801': __('identifierSchemeCodeUnspsc190501.51383801'),
	'identifierSchemeCodeUnspsc190501.51383802': __('identifierSchemeCodeUnspsc190501.51383802'),
	'identifierSchemeCodeUnspsc190501.51383900': __('identifierSchemeCodeUnspsc190501.51383900'),
	'identifierSchemeCodeUnspsc190501.51383901': __('identifierSchemeCodeUnspsc190501.51383901'),
	'identifierSchemeCodeUnspsc190501.51383902': __('identifierSchemeCodeUnspsc190501.51383902'),
	'identifierSchemeCodeUnspsc190501.51383903': __('identifierSchemeCodeUnspsc190501.51383903'),
	'identifierSchemeCodeUnspsc190501.51383904': __('identifierSchemeCodeUnspsc190501.51383904'),
	'identifierSchemeCodeUnspsc190501.51383905': __('identifierSchemeCodeUnspsc190501.51383905'),
	'identifierSchemeCodeUnspsc190501.51383906': __('identifierSchemeCodeUnspsc190501.51383906'),
	'identifierSchemeCodeUnspsc190501.51384000': __('identifierSchemeCodeUnspsc190501.51384000'),
	'identifierSchemeCodeUnspsc190501.51384001': __('identifierSchemeCodeUnspsc190501.51384001'),
	'identifierSchemeCodeUnspsc190501.51384002': __('identifierSchemeCodeUnspsc190501.51384002'),
	'identifierSchemeCodeUnspsc190501.51384003': __('identifierSchemeCodeUnspsc190501.51384003'),
	'identifierSchemeCodeUnspsc190501.51384004': __('identifierSchemeCodeUnspsc190501.51384004'),
	'identifierSchemeCodeUnspsc190501.51384100': __('identifierSchemeCodeUnspsc190501.51384100'),
	'identifierSchemeCodeUnspsc190501.51384101': __('identifierSchemeCodeUnspsc190501.51384101'),
	'identifierSchemeCodeUnspsc190501.51384102': __('identifierSchemeCodeUnspsc190501.51384102'),
	'identifierSchemeCodeUnspsc190501.51384103': __('identifierSchemeCodeUnspsc190501.51384103'),
	'identifierSchemeCodeUnspsc190501.51384200': __('identifierSchemeCodeUnspsc190501.51384200'),
	'identifierSchemeCodeUnspsc190501.51384201': __('identifierSchemeCodeUnspsc190501.51384201'),
	'identifierSchemeCodeUnspsc190501.51384202': __('identifierSchemeCodeUnspsc190501.51384202'),
	'identifierSchemeCodeUnspsc190501.51384203': __('identifierSchemeCodeUnspsc190501.51384203'),
	'identifierSchemeCodeUnspsc190501.51384204': __('identifierSchemeCodeUnspsc190501.51384204'),
	'identifierSchemeCodeUnspsc190501.51384205': __('identifierSchemeCodeUnspsc190501.51384205'),
	'identifierSchemeCodeUnspsc190501.51384206': __('identifierSchemeCodeUnspsc190501.51384206'),
	'identifierSchemeCodeUnspsc190501.51384207': __('identifierSchemeCodeUnspsc190501.51384207'),
	'identifierSchemeCodeUnspsc190501.51384208': __('identifierSchemeCodeUnspsc190501.51384208'),
	'identifierSchemeCodeUnspsc190501.51384209': __('identifierSchemeCodeUnspsc190501.51384209'),
	'identifierSchemeCodeUnspsc190501.51384210': __('identifierSchemeCodeUnspsc190501.51384210'),
	'identifierSchemeCodeUnspsc190501.51384300': __('identifierSchemeCodeUnspsc190501.51384300'),
	'identifierSchemeCodeUnspsc190501.51384301': __('identifierSchemeCodeUnspsc190501.51384301'),
	'identifierSchemeCodeUnspsc190501.51384302': __('identifierSchemeCodeUnspsc190501.51384302'),
	'identifierSchemeCodeUnspsc190501.51384303': __('identifierSchemeCodeUnspsc190501.51384303'),
	'identifierSchemeCodeUnspsc190501.51384500': __('identifierSchemeCodeUnspsc190501.51384500'),
	'identifierSchemeCodeUnspsc190501.51384501': __('identifierSchemeCodeUnspsc190501.51384501'),
	'identifierSchemeCodeUnspsc190501.51384502': __('identifierSchemeCodeUnspsc190501.51384502'),
	'identifierSchemeCodeUnspsc190501.51384503': __('identifierSchemeCodeUnspsc190501.51384503'),
	'identifierSchemeCodeUnspsc190501.51384504': __('identifierSchemeCodeUnspsc190501.51384504'),
	'identifierSchemeCodeUnspsc190501.51384505': __('identifierSchemeCodeUnspsc190501.51384505'),
	'identifierSchemeCodeUnspsc190501.51384506': __('identifierSchemeCodeUnspsc190501.51384506'),
	'identifierSchemeCodeUnspsc190501.51384507': __('identifierSchemeCodeUnspsc190501.51384507'),
	'identifierSchemeCodeUnspsc190501.51384508': __('identifierSchemeCodeUnspsc190501.51384508'),
	'identifierSchemeCodeUnspsc190501.51384509': __('identifierSchemeCodeUnspsc190501.51384509'),
	'identifierSchemeCodeUnspsc190501.51384510': __('identifierSchemeCodeUnspsc190501.51384510'),
	'identifierSchemeCodeUnspsc190501.51384511': __('identifierSchemeCodeUnspsc190501.51384511'),
	'identifierSchemeCodeUnspsc190501.51384512': __('identifierSchemeCodeUnspsc190501.51384512'),
	'identifierSchemeCodeUnspsc190501.51384513': __('identifierSchemeCodeUnspsc190501.51384513'),
	'identifierSchemeCodeUnspsc190501.51384514': __('identifierSchemeCodeUnspsc190501.51384514'),
	'identifierSchemeCodeUnspsc190501.51384515': __('identifierSchemeCodeUnspsc190501.51384515'),
	'identifierSchemeCodeUnspsc190501.51384516': __('identifierSchemeCodeUnspsc190501.51384516'),
	'identifierSchemeCodeUnspsc190501.51384517': __('identifierSchemeCodeUnspsc190501.51384517'),
	'identifierSchemeCodeUnspsc190501.51384518': __('identifierSchemeCodeUnspsc190501.51384518'),
	'identifierSchemeCodeUnspsc190501.51384519': __('identifierSchemeCodeUnspsc190501.51384519'),
	'identifierSchemeCodeUnspsc190501.51384520': __('identifierSchemeCodeUnspsc190501.51384520'),
	'identifierSchemeCodeUnspsc190501.51384521': __('identifierSchemeCodeUnspsc190501.51384521'),
	'identifierSchemeCodeUnspsc190501.51384522': __('identifierSchemeCodeUnspsc190501.51384522'),
	'identifierSchemeCodeUnspsc190501.51384523': __('identifierSchemeCodeUnspsc190501.51384523'),
	'identifierSchemeCodeUnspsc190501.51384524': __('identifierSchemeCodeUnspsc190501.51384524'),
	'identifierSchemeCodeUnspsc190501.51384525': __('identifierSchemeCodeUnspsc190501.51384525'),
	'identifierSchemeCodeUnspsc190501.51384526': __('identifierSchemeCodeUnspsc190501.51384526'),
	'identifierSchemeCodeUnspsc190501.51384527': __('identifierSchemeCodeUnspsc190501.51384527'),
	'identifierSchemeCodeUnspsc190501.51384528': __('identifierSchemeCodeUnspsc190501.51384528'),
	'identifierSchemeCodeUnspsc190501.51384529': __('identifierSchemeCodeUnspsc190501.51384529'),
	'identifierSchemeCodeUnspsc190501.51384530': __('identifierSchemeCodeUnspsc190501.51384530'),
	'identifierSchemeCodeUnspsc190501.51384531': __('identifierSchemeCodeUnspsc190501.51384531'),
	'identifierSchemeCodeUnspsc190501.51384532': __('identifierSchemeCodeUnspsc190501.51384532'),
	'identifierSchemeCodeUnspsc190501.51384533': __('identifierSchemeCodeUnspsc190501.51384533'),
	'identifierSchemeCodeUnspsc190501.51384534': __('identifierSchemeCodeUnspsc190501.51384534'),
	'identifierSchemeCodeUnspsc190501.51384600': __('identifierSchemeCodeUnspsc190501.51384600'),
	'identifierSchemeCodeUnspsc190501.51384601': __('identifierSchemeCodeUnspsc190501.51384601'),
	'identifierSchemeCodeUnspsc190501.51384602': __('identifierSchemeCodeUnspsc190501.51384602'),
	'identifierSchemeCodeUnspsc190501.51384603': __('identifierSchemeCodeUnspsc190501.51384603'),
	'identifierSchemeCodeUnspsc190501.51384604': __('identifierSchemeCodeUnspsc190501.51384604'),
	'identifierSchemeCodeUnspsc190501.51384605': __('identifierSchemeCodeUnspsc190501.51384605'),
	'identifierSchemeCodeUnspsc190501.51384606': __('identifierSchemeCodeUnspsc190501.51384606'),
	'identifierSchemeCodeUnspsc190501.51384607': __('identifierSchemeCodeUnspsc190501.51384607'),
	'identifierSchemeCodeUnspsc190501.51384608': __('identifierSchemeCodeUnspsc190501.51384608'),
	'identifierSchemeCodeUnspsc190501.51384609': __('identifierSchemeCodeUnspsc190501.51384609'),
	'identifierSchemeCodeUnspsc190501.51384610': __('identifierSchemeCodeUnspsc190501.51384610'),
	'identifierSchemeCodeUnspsc190501.51384611': __('identifierSchemeCodeUnspsc190501.51384611'),
	'identifierSchemeCodeUnspsc190501.51384612': __('identifierSchemeCodeUnspsc190501.51384612'),
	'identifierSchemeCodeUnspsc190501.51384613': __('identifierSchemeCodeUnspsc190501.51384613'),
	'identifierSchemeCodeUnspsc190501.51384614': __('identifierSchemeCodeUnspsc190501.51384614'),
	'identifierSchemeCodeUnspsc190501.51384615': __('identifierSchemeCodeUnspsc190501.51384615'),
	'identifierSchemeCodeUnspsc190501.51384616': __('identifierSchemeCodeUnspsc190501.51384616'),
	'identifierSchemeCodeUnspsc190501.51384617': __('identifierSchemeCodeUnspsc190501.51384617'),
	'identifierSchemeCodeUnspsc190501.51384618': __('identifierSchemeCodeUnspsc190501.51384618'),
	'identifierSchemeCodeUnspsc190501.51384619': __('identifierSchemeCodeUnspsc190501.51384619'),
	'identifierSchemeCodeUnspsc190501.51384620': __('identifierSchemeCodeUnspsc190501.51384620'),
	'identifierSchemeCodeUnspsc190501.51384621': __('identifierSchemeCodeUnspsc190501.51384621'),
	'identifierSchemeCodeUnspsc190501.51384622': __('identifierSchemeCodeUnspsc190501.51384622'),
	'identifierSchemeCodeUnspsc190501.51384623': __('identifierSchemeCodeUnspsc190501.51384623'),
	'identifierSchemeCodeUnspsc190501.51384624': __('identifierSchemeCodeUnspsc190501.51384624'),
	'identifierSchemeCodeUnspsc190501.51384625': __('identifierSchemeCodeUnspsc190501.51384625'),
	'identifierSchemeCodeUnspsc190501.51384626': __('identifierSchemeCodeUnspsc190501.51384626'),
	'identifierSchemeCodeUnspsc190501.51384627': __('identifierSchemeCodeUnspsc190501.51384627'),
	'identifierSchemeCodeUnspsc190501.51384628': __('identifierSchemeCodeUnspsc190501.51384628'),
	'identifierSchemeCodeUnspsc190501.51384629': __('identifierSchemeCodeUnspsc190501.51384629'),
	'identifierSchemeCodeUnspsc190501.51384630': __('identifierSchemeCodeUnspsc190501.51384630'),
	'identifierSchemeCodeUnspsc190501.51384631': __('identifierSchemeCodeUnspsc190501.51384631'),
	'identifierSchemeCodeUnspsc190501.51384632': __('identifierSchemeCodeUnspsc190501.51384632'),
	'identifierSchemeCodeUnspsc190501.51384633': __('identifierSchemeCodeUnspsc190501.51384633'),
	'identifierSchemeCodeUnspsc190501.51384700': __('identifierSchemeCodeUnspsc190501.51384700'),
	'identifierSchemeCodeUnspsc190501.51384701': __('identifierSchemeCodeUnspsc190501.51384701'),
	'identifierSchemeCodeUnspsc190501.51384702': __('identifierSchemeCodeUnspsc190501.51384702'),
	'identifierSchemeCodeUnspsc190501.51384703': __('identifierSchemeCodeUnspsc190501.51384703'),
	'identifierSchemeCodeUnspsc190501.51384800': __('identifierSchemeCodeUnspsc190501.51384800'),
	'identifierSchemeCodeUnspsc190501.51384801': __('identifierSchemeCodeUnspsc190501.51384801'),
	'identifierSchemeCodeUnspsc190501.51384802': __('identifierSchemeCodeUnspsc190501.51384802'),
	'identifierSchemeCodeUnspsc190501.51384803': __('identifierSchemeCodeUnspsc190501.51384803'),
	'identifierSchemeCodeUnspsc190501.51384900': __('identifierSchemeCodeUnspsc190501.51384900'),
	'identifierSchemeCodeUnspsc190501.51384901': __('identifierSchemeCodeUnspsc190501.51384901'),
	'identifierSchemeCodeUnspsc190501.51384902': __('identifierSchemeCodeUnspsc190501.51384902'),
	'identifierSchemeCodeUnspsc190501.51384903': __('identifierSchemeCodeUnspsc190501.51384903'),
	'identifierSchemeCodeUnspsc190501.51384904': __('identifierSchemeCodeUnspsc190501.51384904'),
	'identifierSchemeCodeUnspsc190501.51385000': __('identifierSchemeCodeUnspsc190501.51385000'),
	'identifierSchemeCodeUnspsc190501.51385001': __('identifierSchemeCodeUnspsc190501.51385001'),
	'identifierSchemeCodeUnspsc190501.51385002': __('identifierSchemeCodeUnspsc190501.51385002'),
	'identifierSchemeCodeUnspsc190501.51385003': __('identifierSchemeCodeUnspsc190501.51385003'),
	'identifierSchemeCodeUnspsc190501.51385004': __('identifierSchemeCodeUnspsc190501.51385004'),
	'identifierSchemeCodeUnspsc190501.51385005': __('identifierSchemeCodeUnspsc190501.51385005'),
	'identifierSchemeCodeUnspsc190501.51385100': __('identifierSchemeCodeUnspsc190501.51385100'),
	'identifierSchemeCodeUnspsc190501.51385101': __('identifierSchemeCodeUnspsc190501.51385101'),
	'identifierSchemeCodeUnspsc190501.51385102': __('identifierSchemeCodeUnspsc190501.51385102'),
	'identifierSchemeCodeUnspsc190501.51385103': __('identifierSchemeCodeUnspsc190501.51385103'),
	'identifierSchemeCodeUnspsc190501.51385200': __('identifierSchemeCodeUnspsc190501.51385200'),
	'identifierSchemeCodeUnspsc190501.51385201': __('identifierSchemeCodeUnspsc190501.51385201'),
	'identifierSchemeCodeUnspsc190501.51385202': __('identifierSchemeCodeUnspsc190501.51385202'),
	'identifierSchemeCodeUnspsc190501.51385300': __('identifierSchemeCodeUnspsc190501.51385300'),
	'identifierSchemeCodeUnspsc190501.51385301': __('identifierSchemeCodeUnspsc190501.51385301'),
	'identifierSchemeCodeUnspsc190501.51385400': __('identifierSchemeCodeUnspsc190501.51385400'),
	'identifierSchemeCodeUnspsc190501.51385401': __('identifierSchemeCodeUnspsc190501.51385401'),
	'identifierSchemeCodeUnspsc190501.51385402': __('identifierSchemeCodeUnspsc190501.51385402'),
	'identifierSchemeCodeUnspsc190501.51385403': __('identifierSchemeCodeUnspsc190501.51385403'),
	'identifierSchemeCodeUnspsc190501.51385404': __('identifierSchemeCodeUnspsc190501.51385404'),
	'identifierSchemeCodeUnspsc190501.51385405': __('identifierSchemeCodeUnspsc190501.51385405'),
	'identifierSchemeCodeUnspsc190501.51385500': __('identifierSchemeCodeUnspsc190501.51385500'),
	'identifierSchemeCodeUnspsc190501.51385501': __('identifierSchemeCodeUnspsc190501.51385501'),
	'identifierSchemeCodeUnspsc190501.51385502': __('identifierSchemeCodeUnspsc190501.51385502'),
	'identifierSchemeCodeUnspsc190501.51385600': __('identifierSchemeCodeUnspsc190501.51385600'),
	'identifierSchemeCodeUnspsc190501.51385601': __('identifierSchemeCodeUnspsc190501.51385601'),
	'identifierSchemeCodeUnspsc190501.51385602': __('identifierSchemeCodeUnspsc190501.51385602'),
	'identifierSchemeCodeUnspsc190501.51385603': __('identifierSchemeCodeUnspsc190501.51385603'),
	'identifierSchemeCodeUnspsc190501.51385604': __('identifierSchemeCodeUnspsc190501.51385604'),
	'identifierSchemeCodeUnspsc190501.51385605': __('identifierSchemeCodeUnspsc190501.51385605'),
	'identifierSchemeCodeUnspsc190501.51385606': __('identifierSchemeCodeUnspsc190501.51385606'),
	'identifierSchemeCodeUnspsc190501.51385607': __('identifierSchemeCodeUnspsc190501.51385607'),
	'identifierSchemeCodeUnspsc190501.51385608': __('identifierSchemeCodeUnspsc190501.51385608'),
	'identifierSchemeCodeUnspsc190501.51385609': __('identifierSchemeCodeUnspsc190501.51385609'),
	'identifierSchemeCodeUnspsc190501.51385610': __('identifierSchemeCodeUnspsc190501.51385610'),
	'identifierSchemeCodeUnspsc190501.51385611': __('identifierSchemeCodeUnspsc190501.51385611'),
	'identifierSchemeCodeUnspsc190501.51385612': __('identifierSchemeCodeUnspsc190501.51385612'),
	'identifierSchemeCodeUnspsc190501.51385613': __('identifierSchemeCodeUnspsc190501.51385613'),
	'identifierSchemeCodeUnspsc190501.51385614': __('identifierSchemeCodeUnspsc190501.51385614'),
	'identifierSchemeCodeUnspsc190501.51385615': __('identifierSchemeCodeUnspsc190501.51385615'),
	'identifierSchemeCodeUnspsc190501.51385616': __('identifierSchemeCodeUnspsc190501.51385616'),
	'identifierSchemeCodeUnspsc190501.51385617': __('identifierSchemeCodeUnspsc190501.51385617'),
	'identifierSchemeCodeUnspsc190501.51385700': __('identifierSchemeCodeUnspsc190501.51385700'),
	'identifierSchemeCodeUnspsc190501.51385701': __('identifierSchemeCodeUnspsc190501.51385701'),
	'identifierSchemeCodeUnspsc190501.51385702': __('identifierSchemeCodeUnspsc190501.51385702'),
	'identifierSchemeCodeUnspsc190501.51385703': __('identifierSchemeCodeUnspsc190501.51385703'),
	'identifierSchemeCodeUnspsc190501.51385800': __('identifierSchemeCodeUnspsc190501.51385800'),
	'identifierSchemeCodeUnspsc190501.51385801': __('identifierSchemeCodeUnspsc190501.51385801'),
	'identifierSchemeCodeUnspsc190501.51385802': __('identifierSchemeCodeUnspsc190501.51385802'),
	'identifierSchemeCodeUnspsc190501.51385803': __('identifierSchemeCodeUnspsc190501.51385803'),
	'identifierSchemeCodeUnspsc190501.51385804': __('identifierSchemeCodeUnspsc190501.51385804'),
	'identifierSchemeCodeUnspsc190501.51385805': __('identifierSchemeCodeUnspsc190501.51385805'),
	'identifierSchemeCodeUnspsc190501.51385806': __('identifierSchemeCodeUnspsc190501.51385806'),
	'identifierSchemeCodeUnspsc190501.51385807': __('identifierSchemeCodeUnspsc190501.51385807'),
	'identifierSchemeCodeUnspsc190501.51385808': __('identifierSchemeCodeUnspsc190501.51385808'),
	'identifierSchemeCodeUnspsc190501.51385809': __('identifierSchemeCodeUnspsc190501.51385809'),
	'identifierSchemeCodeUnspsc190501.51385900': __('identifierSchemeCodeUnspsc190501.51385900'),
	'identifierSchemeCodeUnspsc190501.51385901': __('identifierSchemeCodeUnspsc190501.51385901'),
	'identifierSchemeCodeUnspsc190501.51385902': __('identifierSchemeCodeUnspsc190501.51385902'),
	'identifierSchemeCodeUnspsc190501.51385903': __('identifierSchemeCodeUnspsc190501.51385903'),
	'identifierSchemeCodeUnspsc190501.51385904': __('identifierSchemeCodeUnspsc190501.51385904'),
	'identifierSchemeCodeUnspsc190501.51386000': __('identifierSchemeCodeUnspsc190501.51386000'),
	'identifierSchemeCodeUnspsc190501.51386001': __('identifierSchemeCodeUnspsc190501.51386001'),
	'identifierSchemeCodeUnspsc190501.51386002': __('identifierSchemeCodeUnspsc190501.51386002'),
	'identifierSchemeCodeUnspsc190501.51386003': __('identifierSchemeCodeUnspsc190501.51386003'),
	'identifierSchemeCodeUnspsc190501.51386004': __('identifierSchemeCodeUnspsc190501.51386004'),
	'identifierSchemeCodeUnspsc190501.51386005': __('identifierSchemeCodeUnspsc190501.51386005'),
	'identifierSchemeCodeUnspsc190501.51386006': __('identifierSchemeCodeUnspsc190501.51386006'),
	'identifierSchemeCodeUnspsc190501.51386007': __('identifierSchemeCodeUnspsc190501.51386007'),
	'identifierSchemeCodeUnspsc190501.51386008': __('identifierSchemeCodeUnspsc190501.51386008'),
	'identifierSchemeCodeUnspsc190501.51386009': __('identifierSchemeCodeUnspsc190501.51386009'),
	'identifierSchemeCodeUnspsc190501.51386010': __('identifierSchemeCodeUnspsc190501.51386010'),
	'identifierSchemeCodeUnspsc190501.51386011': __('identifierSchemeCodeUnspsc190501.51386011'),
	'identifierSchemeCodeUnspsc190501.51386012': __('identifierSchemeCodeUnspsc190501.51386012'),
	'identifierSchemeCodeUnspsc190501.51386013': __('identifierSchemeCodeUnspsc190501.51386013'),
	'identifierSchemeCodeUnspsc190501.51386014': __('identifierSchemeCodeUnspsc190501.51386014'),
	'identifierSchemeCodeUnspsc190501.51386015': __('identifierSchemeCodeUnspsc190501.51386015'),
	'identifierSchemeCodeUnspsc190501.51386016': __('identifierSchemeCodeUnspsc190501.51386016'),
	'identifierSchemeCodeUnspsc190501.51386017': __('identifierSchemeCodeUnspsc190501.51386017'),
	'identifierSchemeCodeUnspsc190501.51386100': __('identifierSchemeCodeUnspsc190501.51386100'),
	'identifierSchemeCodeUnspsc190501.51386101': __('identifierSchemeCodeUnspsc190501.51386101'),
	'identifierSchemeCodeUnspsc190501.51386102': __('identifierSchemeCodeUnspsc190501.51386102'),
	'identifierSchemeCodeUnspsc190501.51386200': __('identifierSchemeCodeUnspsc190501.51386200'),
	'identifierSchemeCodeUnspsc190501.51386201': __('identifierSchemeCodeUnspsc190501.51386201'),
	'identifierSchemeCodeUnspsc190501.51386202': __('identifierSchemeCodeUnspsc190501.51386202'),
	'identifierSchemeCodeUnspsc190501.51386203': __('identifierSchemeCodeUnspsc190501.51386203'),
	'identifierSchemeCodeUnspsc190501.51386204': __('identifierSchemeCodeUnspsc190501.51386204'),
	'identifierSchemeCodeUnspsc190501.51386205': __('identifierSchemeCodeUnspsc190501.51386205'),
	'identifierSchemeCodeUnspsc190501.51386300': __('identifierSchemeCodeUnspsc190501.51386300'),
	'identifierSchemeCodeUnspsc190501.51386301': __('identifierSchemeCodeUnspsc190501.51386301'),
	'identifierSchemeCodeUnspsc190501.51386302': __('identifierSchemeCodeUnspsc190501.51386302'),
	'identifierSchemeCodeUnspsc190501.51386400': __('identifierSchemeCodeUnspsc190501.51386400'),
	'identifierSchemeCodeUnspsc190501.51386401': __('identifierSchemeCodeUnspsc190501.51386401'),
	'identifierSchemeCodeUnspsc190501.51386402': __('identifierSchemeCodeUnspsc190501.51386402'),
	'identifierSchemeCodeUnspsc190501.51386403': __('identifierSchemeCodeUnspsc190501.51386403'),
	'identifierSchemeCodeUnspsc190501.51386404': __('identifierSchemeCodeUnspsc190501.51386404'),
	'identifierSchemeCodeUnspsc190501.51386500': __('identifierSchemeCodeUnspsc190501.51386500'),
	'identifierSchemeCodeUnspsc190501.51386501': __('identifierSchemeCodeUnspsc190501.51386501'),
	'identifierSchemeCodeUnspsc190501.51386502': __('identifierSchemeCodeUnspsc190501.51386502'),
	'identifierSchemeCodeUnspsc190501.51386600': __('identifierSchemeCodeUnspsc190501.51386600'),
	'identifierSchemeCodeUnspsc190501.51386601': __('identifierSchemeCodeUnspsc190501.51386601'),
	'identifierSchemeCodeUnspsc190501.51386602': __('identifierSchemeCodeUnspsc190501.51386602'),
	'identifierSchemeCodeUnspsc190501.51386603': __('identifierSchemeCodeUnspsc190501.51386603'),
	'identifierSchemeCodeUnspsc190501.51386604': __('identifierSchemeCodeUnspsc190501.51386604'),
	'identifierSchemeCodeUnspsc190501.51386605': __('identifierSchemeCodeUnspsc190501.51386605'),
	'identifierSchemeCodeUnspsc190501.51386606': __('identifierSchemeCodeUnspsc190501.51386606'),
	'identifierSchemeCodeUnspsc190501.51386607': __('identifierSchemeCodeUnspsc190501.51386607'),
	'identifierSchemeCodeUnspsc190501.51386608': __('identifierSchemeCodeUnspsc190501.51386608'),
	'identifierSchemeCodeUnspsc190501.51386609': __('identifierSchemeCodeUnspsc190501.51386609'),
	'identifierSchemeCodeUnspsc190501.51386610': __('identifierSchemeCodeUnspsc190501.51386610'),
	'identifierSchemeCodeUnspsc190501.51386611': __('identifierSchemeCodeUnspsc190501.51386611'),
	'identifierSchemeCodeUnspsc190501.51386612': __('identifierSchemeCodeUnspsc190501.51386612'),
	'identifierSchemeCodeUnspsc190501.51386613': __('identifierSchemeCodeUnspsc190501.51386613'),
	'identifierSchemeCodeUnspsc190501.51390000': __('identifierSchemeCodeUnspsc190501.51390000'),
	'identifierSchemeCodeUnspsc190501.51391500': __('identifierSchemeCodeUnspsc190501.51391500'),
	'identifierSchemeCodeUnspsc190501.51391501': __('identifierSchemeCodeUnspsc190501.51391501'),
	'identifierSchemeCodeUnspsc190501.51391502': __('identifierSchemeCodeUnspsc190501.51391502'),
	'identifierSchemeCodeUnspsc190501.51391503': __('identifierSchemeCodeUnspsc190501.51391503'),
	'identifierSchemeCodeUnspsc190501.51391600': __('identifierSchemeCodeUnspsc190501.51391600'),
	'identifierSchemeCodeUnspsc190501.51391601': __('identifierSchemeCodeUnspsc190501.51391601'),
	'identifierSchemeCodeUnspsc190501.51391602': __('identifierSchemeCodeUnspsc190501.51391602'),
	'identifierSchemeCodeUnspsc190501.51391603': __('identifierSchemeCodeUnspsc190501.51391603'),
	'identifierSchemeCodeUnspsc190501.51391604': __('identifierSchemeCodeUnspsc190501.51391604'),
	'identifierSchemeCodeUnspsc190501.51391605': __('identifierSchemeCodeUnspsc190501.51391605'),
	'identifierSchemeCodeUnspsc190501.51391606': __('identifierSchemeCodeUnspsc190501.51391606'),
	'identifierSchemeCodeUnspsc190501.51391607': __('identifierSchemeCodeUnspsc190501.51391607'),
	'identifierSchemeCodeUnspsc190501.51391608': __('identifierSchemeCodeUnspsc190501.51391608'),
	'identifierSchemeCodeUnspsc190501.51391609': __('identifierSchemeCodeUnspsc190501.51391609'),
	'identifierSchemeCodeUnspsc190501.51391610': __('identifierSchemeCodeUnspsc190501.51391610'),
	'identifierSchemeCodeUnspsc190501.51391611': __('identifierSchemeCodeUnspsc190501.51391611'),
	'identifierSchemeCodeUnspsc190501.51391612': __('identifierSchemeCodeUnspsc190501.51391612'),
	'identifierSchemeCodeUnspsc190501.51391613': __('identifierSchemeCodeUnspsc190501.51391613'),
	'identifierSchemeCodeUnspsc190501.51391614': __('identifierSchemeCodeUnspsc190501.51391614'),
	'identifierSchemeCodeUnspsc190501.51391615': __('identifierSchemeCodeUnspsc190501.51391615'),
	'identifierSchemeCodeUnspsc190501.51391616': __('identifierSchemeCodeUnspsc190501.51391616'),
	'identifierSchemeCodeUnspsc190501.51391617': __('identifierSchemeCodeUnspsc190501.51391617'),
	'identifierSchemeCodeUnspsc190501.51391618': __('identifierSchemeCodeUnspsc190501.51391618'),
	'identifierSchemeCodeUnspsc190501.51391619': __('identifierSchemeCodeUnspsc190501.51391619'),
	'identifierSchemeCodeUnspsc190501.51391700': __('identifierSchemeCodeUnspsc190501.51391700'),
	'identifierSchemeCodeUnspsc190501.51391701': __('identifierSchemeCodeUnspsc190501.51391701'),
	'identifierSchemeCodeUnspsc190501.51391702': __('identifierSchemeCodeUnspsc190501.51391702'),
	'identifierSchemeCodeUnspsc190501.51391703': __('identifierSchemeCodeUnspsc190501.51391703'),
	'identifierSchemeCodeUnspsc190501.51391704': __('identifierSchemeCodeUnspsc190501.51391704'),
	'identifierSchemeCodeUnspsc190501.51391705': __('identifierSchemeCodeUnspsc190501.51391705'),
	'identifierSchemeCodeUnspsc190501.51391706': __('identifierSchemeCodeUnspsc190501.51391706'),
	'identifierSchemeCodeUnspsc190501.51391707': __('identifierSchemeCodeUnspsc190501.51391707'),
	'identifierSchemeCodeUnspsc190501.51391708': __('identifierSchemeCodeUnspsc190501.51391708'),
	'identifierSchemeCodeUnspsc190501.51391709': __('identifierSchemeCodeUnspsc190501.51391709'),
	'identifierSchemeCodeUnspsc190501.51391710': __('identifierSchemeCodeUnspsc190501.51391710'),
	'identifierSchemeCodeUnspsc190501.51391711': __('identifierSchemeCodeUnspsc190501.51391711'),
	'identifierSchemeCodeUnspsc190501.51391712': __('identifierSchemeCodeUnspsc190501.51391712'),
	'identifierSchemeCodeUnspsc190501.51391713': __('identifierSchemeCodeUnspsc190501.51391713'),
	'identifierSchemeCodeUnspsc190501.51391714': __('identifierSchemeCodeUnspsc190501.51391714'),
	'identifierSchemeCodeUnspsc190501.51391715': __('identifierSchemeCodeUnspsc190501.51391715'),
	'identifierSchemeCodeUnspsc190501.51391716': __('identifierSchemeCodeUnspsc190501.51391716'),
	'identifierSchemeCodeUnspsc190501.51391717': __('identifierSchemeCodeUnspsc190501.51391717'),
	'identifierSchemeCodeUnspsc190501.51391718': __('identifierSchemeCodeUnspsc190501.51391718'),
	'identifierSchemeCodeUnspsc190501.51391719': __('identifierSchemeCodeUnspsc190501.51391719'),
	'identifierSchemeCodeUnspsc190501.51391720': __('identifierSchemeCodeUnspsc190501.51391720'),
	'identifierSchemeCodeUnspsc190501.51391721': __('identifierSchemeCodeUnspsc190501.51391721'),
	'identifierSchemeCodeUnspsc190501.51391722': __('identifierSchemeCodeUnspsc190501.51391722'),
	'identifierSchemeCodeUnspsc190501.51391723': __('identifierSchemeCodeUnspsc190501.51391723'),
	'identifierSchemeCodeUnspsc190501.51391724': __('identifierSchemeCodeUnspsc190501.51391724'),
	'identifierSchemeCodeUnspsc190501.51391725': __('identifierSchemeCodeUnspsc190501.51391725'),
	'identifierSchemeCodeUnspsc190501.51391726': __('identifierSchemeCodeUnspsc190501.51391726'),
	'identifierSchemeCodeUnspsc190501.51391727': __('identifierSchemeCodeUnspsc190501.51391727'),
	'identifierSchemeCodeUnspsc190501.51391728': __('identifierSchemeCodeUnspsc190501.51391728'),
	'identifierSchemeCodeUnspsc190501.51391729': __('identifierSchemeCodeUnspsc190501.51391729'),
	'identifierSchemeCodeUnspsc190501.51391730': __('identifierSchemeCodeUnspsc190501.51391730'),
	'identifierSchemeCodeUnspsc190501.51391731': __('identifierSchemeCodeUnspsc190501.51391731'),
	'identifierSchemeCodeUnspsc190501.51391732': __('identifierSchemeCodeUnspsc190501.51391732'),
	'identifierSchemeCodeUnspsc190501.51391733': __('identifierSchemeCodeUnspsc190501.51391733'),
	'identifierSchemeCodeUnspsc190501.51391734': __('identifierSchemeCodeUnspsc190501.51391734'),
	'identifierSchemeCodeUnspsc190501.51391735': __('identifierSchemeCodeUnspsc190501.51391735'),
	'identifierSchemeCodeUnspsc190501.51391736': __('identifierSchemeCodeUnspsc190501.51391736'),
	'identifierSchemeCodeUnspsc190501.51391737': __('identifierSchemeCodeUnspsc190501.51391737'),
	'identifierSchemeCodeUnspsc190501.51391738': __('identifierSchemeCodeUnspsc190501.51391738'),
	'identifierSchemeCodeUnspsc190501.51391739': __('identifierSchemeCodeUnspsc190501.51391739'),
	'identifierSchemeCodeUnspsc190501.51391740': __('identifierSchemeCodeUnspsc190501.51391740'),
	'identifierSchemeCodeUnspsc190501.51391741': __('identifierSchemeCodeUnspsc190501.51391741'),
	'identifierSchemeCodeUnspsc190501.51391742': __('identifierSchemeCodeUnspsc190501.51391742'),
	'identifierSchemeCodeUnspsc190501.51391743': __('identifierSchemeCodeUnspsc190501.51391743'),
	'identifierSchemeCodeUnspsc190501.51391744': __('identifierSchemeCodeUnspsc190501.51391744'),
	'identifierSchemeCodeUnspsc190501.51391745': __('identifierSchemeCodeUnspsc190501.51391745'),
	'identifierSchemeCodeUnspsc190501.51391746': __('identifierSchemeCodeUnspsc190501.51391746'),
	'identifierSchemeCodeUnspsc190501.51391747': __('identifierSchemeCodeUnspsc190501.51391747'),
	'identifierSchemeCodeUnspsc190501.51391749': __('identifierSchemeCodeUnspsc190501.51391749'),
	'identifierSchemeCodeUnspsc190501.51391800': __('identifierSchemeCodeUnspsc190501.51391800'),
	'identifierSchemeCodeUnspsc190501.51391801': __('identifierSchemeCodeUnspsc190501.51391801'),
	'identifierSchemeCodeUnspsc190501.51391802': __('identifierSchemeCodeUnspsc190501.51391802'),
	'identifierSchemeCodeUnspsc190501.51391803': __('identifierSchemeCodeUnspsc190501.51391803'),
	'identifierSchemeCodeUnspsc190501.51391804': __('identifierSchemeCodeUnspsc190501.51391804'),
	'identifierSchemeCodeUnspsc190501.51391805': __('identifierSchemeCodeUnspsc190501.51391805'),
	'identifierSchemeCodeUnspsc190501.51391806': __('identifierSchemeCodeUnspsc190501.51391806'),
	'identifierSchemeCodeUnspsc190501.51391807': __('identifierSchemeCodeUnspsc190501.51391807'),
	'identifierSchemeCodeUnspsc190501.51391808': __('identifierSchemeCodeUnspsc190501.51391808'),
	'identifierSchemeCodeUnspsc190501.51391809': __('identifierSchemeCodeUnspsc190501.51391809'),
	'identifierSchemeCodeUnspsc190501.51391810': __('identifierSchemeCodeUnspsc190501.51391810'),
	'identifierSchemeCodeUnspsc190501.51391811': __('identifierSchemeCodeUnspsc190501.51391811'),
	'identifierSchemeCodeUnspsc190501.51391812': __('identifierSchemeCodeUnspsc190501.51391812'),
	'identifierSchemeCodeUnspsc190501.51391813': __('identifierSchemeCodeUnspsc190501.51391813'),
	'identifierSchemeCodeUnspsc190501.51391900': __('identifierSchemeCodeUnspsc190501.51391900'),
	'identifierSchemeCodeUnspsc190501.51391901': __('identifierSchemeCodeUnspsc190501.51391901'),
	'identifierSchemeCodeUnspsc190501.51391902': __('identifierSchemeCodeUnspsc190501.51391902'),
	'identifierSchemeCodeUnspsc190501.51391903': __('identifierSchemeCodeUnspsc190501.51391903'),
	'identifierSchemeCodeUnspsc190501.51391904': __('identifierSchemeCodeUnspsc190501.51391904'),
	'identifierSchemeCodeUnspsc190501.51392000': __('identifierSchemeCodeUnspsc190501.51392000'),
	'identifierSchemeCodeUnspsc190501.51392001': __('identifierSchemeCodeUnspsc190501.51392001'),
	'identifierSchemeCodeUnspsc190501.51392002': __('identifierSchemeCodeUnspsc190501.51392002'),
	'identifierSchemeCodeUnspsc190501.51392003': __('identifierSchemeCodeUnspsc190501.51392003'),
	'identifierSchemeCodeUnspsc190501.51392004': __('identifierSchemeCodeUnspsc190501.51392004'),
	'identifierSchemeCodeUnspsc190501.51392005': __('identifierSchemeCodeUnspsc190501.51392005'),
	'identifierSchemeCodeUnspsc190501.51392200': __('identifierSchemeCodeUnspsc190501.51392200'),
	'identifierSchemeCodeUnspsc190501.51392201': __('identifierSchemeCodeUnspsc190501.51392201'),
	'identifierSchemeCodeUnspsc190501.51392202': __('identifierSchemeCodeUnspsc190501.51392202'),
	'identifierSchemeCodeUnspsc190501.51392203': __('identifierSchemeCodeUnspsc190501.51392203'),
	'identifierSchemeCodeUnspsc190501.51392204': __('identifierSchemeCodeUnspsc190501.51392204'),
	'identifierSchemeCodeUnspsc190501.51392205': __('identifierSchemeCodeUnspsc190501.51392205'),
	'identifierSchemeCodeUnspsc190501.51392206': __('identifierSchemeCodeUnspsc190501.51392206'),
	'identifierSchemeCodeUnspsc190501.51392207': __('identifierSchemeCodeUnspsc190501.51392207'),
	'identifierSchemeCodeUnspsc190501.51392208': __('identifierSchemeCodeUnspsc190501.51392208'),
	'identifierSchemeCodeUnspsc190501.51392209': __('identifierSchemeCodeUnspsc190501.51392209'),
	'identifierSchemeCodeUnspsc190501.51392210': __('identifierSchemeCodeUnspsc190501.51392210'),
	'identifierSchemeCodeUnspsc190501.51392211': __('identifierSchemeCodeUnspsc190501.51392211'),
	'identifierSchemeCodeUnspsc190501.51392212': __('identifierSchemeCodeUnspsc190501.51392212'),
	'identifierSchemeCodeUnspsc190501.51392213': __('identifierSchemeCodeUnspsc190501.51392213'),
	'identifierSchemeCodeUnspsc190501.51392214': __('identifierSchemeCodeUnspsc190501.51392214'),
	'identifierSchemeCodeUnspsc190501.51392215': __('identifierSchemeCodeUnspsc190501.51392215'),
	'identifierSchemeCodeUnspsc190501.51392216': __('identifierSchemeCodeUnspsc190501.51392216'),
	'identifierSchemeCodeUnspsc190501.51392217': __('identifierSchemeCodeUnspsc190501.51392217'),
	'identifierSchemeCodeUnspsc190501.51392218': __('identifierSchemeCodeUnspsc190501.51392218'),
	'identifierSchemeCodeUnspsc190501.51392219': __('identifierSchemeCodeUnspsc190501.51392219'),
	'identifierSchemeCodeUnspsc190501.51392220': __('identifierSchemeCodeUnspsc190501.51392220'),
	'identifierSchemeCodeUnspsc190501.51392221': __('identifierSchemeCodeUnspsc190501.51392221'),
	'identifierSchemeCodeUnspsc190501.51392222': __('identifierSchemeCodeUnspsc190501.51392222'),
	'identifierSchemeCodeUnspsc190501.51392223': __('identifierSchemeCodeUnspsc190501.51392223'),
	'identifierSchemeCodeUnspsc190501.51392224': __('identifierSchemeCodeUnspsc190501.51392224'),
	'identifierSchemeCodeUnspsc190501.51392225': __('identifierSchemeCodeUnspsc190501.51392225'),
	'identifierSchemeCodeUnspsc190501.51392226': __('identifierSchemeCodeUnspsc190501.51392226'),
	'identifierSchemeCodeUnspsc190501.51392227': __('identifierSchemeCodeUnspsc190501.51392227'),
	'identifierSchemeCodeUnspsc190501.51392228': __('identifierSchemeCodeUnspsc190501.51392228'),
	'identifierSchemeCodeUnspsc190501.51392229': __('identifierSchemeCodeUnspsc190501.51392229'),
	'identifierSchemeCodeUnspsc190501.51392230': __('identifierSchemeCodeUnspsc190501.51392230'),
	'identifierSchemeCodeUnspsc190501.51392300': __('identifierSchemeCodeUnspsc190501.51392300'),
	'identifierSchemeCodeUnspsc190501.51392301': __('identifierSchemeCodeUnspsc190501.51392301'),
	'identifierSchemeCodeUnspsc190501.51392302': __('identifierSchemeCodeUnspsc190501.51392302'),
	'identifierSchemeCodeUnspsc190501.51392303': __('identifierSchemeCodeUnspsc190501.51392303'),
	'identifierSchemeCodeUnspsc190501.51392304': __('identifierSchemeCodeUnspsc190501.51392304'),
	'identifierSchemeCodeUnspsc190501.51392305': __('identifierSchemeCodeUnspsc190501.51392305'),
	'identifierSchemeCodeUnspsc190501.51392306': __('identifierSchemeCodeUnspsc190501.51392306'),
	'identifierSchemeCodeUnspsc190501.51392307': __('identifierSchemeCodeUnspsc190501.51392307'),
	'identifierSchemeCodeUnspsc190501.51392400': __('identifierSchemeCodeUnspsc190501.51392400'),
	'identifierSchemeCodeUnspsc190501.51392401': __('identifierSchemeCodeUnspsc190501.51392401'),
	'identifierSchemeCodeUnspsc190501.51392402': __('identifierSchemeCodeUnspsc190501.51392402'),
	'identifierSchemeCodeUnspsc190501.51392403': __('identifierSchemeCodeUnspsc190501.51392403'),
	'identifierSchemeCodeUnspsc190501.51392404': __('identifierSchemeCodeUnspsc190501.51392404'),
	'identifierSchemeCodeUnspsc190501.51392405': __('identifierSchemeCodeUnspsc190501.51392405'),
	'identifierSchemeCodeUnspsc190501.51392406': __('identifierSchemeCodeUnspsc190501.51392406'),
	'identifierSchemeCodeUnspsc190501.51392407': __('identifierSchemeCodeUnspsc190501.51392407'),
	'identifierSchemeCodeUnspsc190501.51392408': __('identifierSchemeCodeUnspsc190501.51392408'),
	'identifierSchemeCodeUnspsc190501.51392409': __('identifierSchemeCodeUnspsc190501.51392409'),
	'identifierSchemeCodeUnspsc190501.51392410': __('identifierSchemeCodeUnspsc190501.51392410'),
	'identifierSchemeCodeUnspsc190501.51392411': __('identifierSchemeCodeUnspsc190501.51392411'),
	'identifierSchemeCodeUnspsc190501.51392412': __('identifierSchemeCodeUnspsc190501.51392412'),
	'identifierSchemeCodeUnspsc190501.51392413': __('identifierSchemeCodeUnspsc190501.51392413'),
	'identifierSchemeCodeUnspsc190501.51392414': __('identifierSchemeCodeUnspsc190501.51392414'),
	'identifierSchemeCodeUnspsc190501.51392415': __('identifierSchemeCodeUnspsc190501.51392415'),
	'identifierSchemeCodeUnspsc190501.51392416': __('identifierSchemeCodeUnspsc190501.51392416'),
	'identifierSchemeCodeUnspsc190501.51392500': __('identifierSchemeCodeUnspsc190501.51392500'),
	'identifierSchemeCodeUnspsc190501.51392501': __('identifierSchemeCodeUnspsc190501.51392501'),
	'identifierSchemeCodeUnspsc190501.51392502': __('identifierSchemeCodeUnspsc190501.51392502'),
	'identifierSchemeCodeUnspsc190501.51392503': __('identifierSchemeCodeUnspsc190501.51392503'),
	'identifierSchemeCodeUnspsc190501.51392504': __('identifierSchemeCodeUnspsc190501.51392504'),
	'identifierSchemeCodeUnspsc190501.51392505': __('identifierSchemeCodeUnspsc190501.51392505'),
	'identifierSchemeCodeUnspsc190501.51392506': __('identifierSchemeCodeUnspsc190501.51392506'),
	'identifierSchemeCodeUnspsc190501.51392507': __('identifierSchemeCodeUnspsc190501.51392507'),
	'identifierSchemeCodeUnspsc190501.51392508': __('identifierSchemeCodeUnspsc190501.51392508'),
	'identifierSchemeCodeUnspsc190501.51392600': __('identifierSchemeCodeUnspsc190501.51392600'),
	'identifierSchemeCodeUnspsc190501.51392601': __('identifierSchemeCodeUnspsc190501.51392601'),
	'identifierSchemeCodeUnspsc190501.51392602': __('identifierSchemeCodeUnspsc190501.51392602'),
	'identifierSchemeCodeUnspsc190501.51392603': __('identifierSchemeCodeUnspsc190501.51392603'),
	'identifierSchemeCodeUnspsc190501.51392604': __('identifierSchemeCodeUnspsc190501.51392604'),
	'identifierSchemeCodeUnspsc190501.51392605': __('identifierSchemeCodeUnspsc190501.51392605'),
	'identifierSchemeCodeUnspsc190501.51392606': __('identifierSchemeCodeUnspsc190501.51392606'),
	'identifierSchemeCodeUnspsc190501.51392607': __('identifierSchemeCodeUnspsc190501.51392607'),
	'identifierSchemeCodeUnspsc190501.51392608': __('identifierSchemeCodeUnspsc190501.51392608'),
	'identifierSchemeCodeUnspsc190501.51392609': __('identifierSchemeCodeUnspsc190501.51392609'),
	'identifierSchemeCodeUnspsc190501.51392610': __('identifierSchemeCodeUnspsc190501.51392610'),
	'identifierSchemeCodeUnspsc190501.51392611': __('identifierSchemeCodeUnspsc190501.51392611'),
	'identifierSchemeCodeUnspsc190501.51392612': __('identifierSchemeCodeUnspsc190501.51392612'),
	'identifierSchemeCodeUnspsc190501.51392700': __('identifierSchemeCodeUnspsc190501.51392700'),
	'identifierSchemeCodeUnspsc190501.51392701': __('identifierSchemeCodeUnspsc190501.51392701'),
	'identifierSchemeCodeUnspsc190501.51392702': __('identifierSchemeCodeUnspsc190501.51392702'),
	'identifierSchemeCodeUnspsc190501.51392800': __('identifierSchemeCodeUnspsc190501.51392800'),
	'identifierSchemeCodeUnspsc190501.51392801': __('identifierSchemeCodeUnspsc190501.51392801'),
	'identifierSchemeCodeUnspsc190501.51392802': __('identifierSchemeCodeUnspsc190501.51392802'),
	'identifierSchemeCodeUnspsc190501.51392803': __('identifierSchemeCodeUnspsc190501.51392803'),
	'identifierSchemeCodeUnspsc190501.51392804': __('identifierSchemeCodeUnspsc190501.51392804'),
	'identifierSchemeCodeUnspsc190501.51392805': __('identifierSchemeCodeUnspsc190501.51392805'),
	'identifierSchemeCodeUnspsc190501.51392806': __('identifierSchemeCodeUnspsc190501.51392806'),
	'identifierSchemeCodeUnspsc190501.51392900': __('identifierSchemeCodeUnspsc190501.51392900'),
	'identifierSchemeCodeUnspsc190501.51392901': __('identifierSchemeCodeUnspsc190501.51392901'),
	'identifierSchemeCodeUnspsc190501.51392902': __('identifierSchemeCodeUnspsc190501.51392902'),
	'identifierSchemeCodeUnspsc190501.51392903': __('identifierSchemeCodeUnspsc190501.51392903'),
	'identifierSchemeCodeUnspsc190501.51392904': __('identifierSchemeCodeUnspsc190501.51392904'),
	'identifierSchemeCodeUnspsc190501.51393000': __('identifierSchemeCodeUnspsc190501.51393000'),
	'identifierSchemeCodeUnspsc190501.51393001': __('identifierSchemeCodeUnspsc190501.51393001'),
	'identifierSchemeCodeUnspsc190501.51393002': __('identifierSchemeCodeUnspsc190501.51393002'),
	'identifierSchemeCodeUnspsc190501.51393003': __('identifierSchemeCodeUnspsc190501.51393003'),
	'identifierSchemeCodeUnspsc190501.51393004': __('identifierSchemeCodeUnspsc190501.51393004'),
	'identifierSchemeCodeUnspsc190501.51393100': __('identifierSchemeCodeUnspsc190501.51393100'),
	'identifierSchemeCodeUnspsc190501.51393101': __('identifierSchemeCodeUnspsc190501.51393101'),
	'identifierSchemeCodeUnspsc190501.51393102': __('identifierSchemeCodeUnspsc190501.51393102'),
	'identifierSchemeCodeUnspsc190501.51393103': __('identifierSchemeCodeUnspsc190501.51393103'),
	'identifierSchemeCodeUnspsc190501.51393104': __('identifierSchemeCodeUnspsc190501.51393104'),
	'identifierSchemeCodeUnspsc190501.51393105': __('identifierSchemeCodeUnspsc190501.51393105'),
	'identifierSchemeCodeUnspsc190501.51393106': __('identifierSchemeCodeUnspsc190501.51393106'),
	'identifierSchemeCodeUnspsc190501.51393107': __('identifierSchemeCodeUnspsc190501.51393107'),
	'identifierSchemeCodeUnspsc190501.51393200': __('identifierSchemeCodeUnspsc190501.51393200'),
	'identifierSchemeCodeUnspsc190501.51393201': __('identifierSchemeCodeUnspsc190501.51393201'),
	'identifierSchemeCodeUnspsc190501.51393202': __('identifierSchemeCodeUnspsc190501.51393202'),
	'identifierSchemeCodeUnspsc190501.51393203': __('identifierSchemeCodeUnspsc190501.51393203'),
	'identifierSchemeCodeUnspsc190501.51393204': __('identifierSchemeCodeUnspsc190501.51393204'),
	'identifierSchemeCodeUnspsc190501.51393205': __('identifierSchemeCodeUnspsc190501.51393205'),
	'identifierSchemeCodeUnspsc190501.51393300': __('identifierSchemeCodeUnspsc190501.51393300'),
	'identifierSchemeCodeUnspsc190501.51393301': __('identifierSchemeCodeUnspsc190501.51393301'),
	'identifierSchemeCodeUnspsc190501.51393302': __('identifierSchemeCodeUnspsc190501.51393302'),
	'identifierSchemeCodeUnspsc190501.51393303': __('identifierSchemeCodeUnspsc190501.51393303'),
	'identifierSchemeCodeUnspsc190501.51393304': __('identifierSchemeCodeUnspsc190501.51393304'),
	'identifierSchemeCodeUnspsc190501.51393305': __('identifierSchemeCodeUnspsc190501.51393305'),
	'identifierSchemeCodeUnspsc190501.51393306': __('identifierSchemeCodeUnspsc190501.51393306'),
	'identifierSchemeCodeUnspsc190501.51393307': __('identifierSchemeCodeUnspsc190501.51393307'),
	'identifierSchemeCodeUnspsc190501.51393308': __('identifierSchemeCodeUnspsc190501.51393308'),
	'identifierSchemeCodeUnspsc190501.51393309': __('identifierSchemeCodeUnspsc190501.51393309'),
	'identifierSchemeCodeUnspsc190501.51393310': __('identifierSchemeCodeUnspsc190501.51393310'),
	'identifierSchemeCodeUnspsc190501.51393311': __('identifierSchemeCodeUnspsc190501.51393311'),
	'identifierSchemeCodeUnspsc190501.51393312': __('identifierSchemeCodeUnspsc190501.51393312'),
	'identifierSchemeCodeUnspsc190501.51393313': __('identifierSchemeCodeUnspsc190501.51393313'),
	'identifierSchemeCodeUnspsc190501.51393314': __('identifierSchemeCodeUnspsc190501.51393314'),
	'identifierSchemeCodeUnspsc190501.51393315': __('identifierSchemeCodeUnspsc190501.51393315'),
	'identifierSchemeCodeUnspsc190501.51393316': __('identifierSchemeCodeUnspsc190501.51393316'),
	'identifierSchemeCodeUnspsc190501.51393317': __('identifierSchemeCodeUnspsc190501.51393317'),
	'identifierSchemeCodeUnspsc190501.51393318': __('identifierSchemeCodeUnspsc190501.51393318'),
	'identifierSchemeCodeUnspsc190501.51393319': __('identifierSchemeCodeUnspsc190501.51393319'),
	'identifierSchemeCodeUnspsc190501.51393320': __('identifierSchemeCodeUnspsc190501.51393320'),
	'identifierSchemeCodeUnspsc190501.51393400': __('identifierSchemeCodeUnspsc190501.51393400'),
	'identifierSchemeCodeUnspsc190501.51393401': __('identifierSchemeCodeUnspsc190501.51393401'),
	'identifierSchemeCodeUnspsc190501.51393402': __('identifierSchemeCodeUnspsc190501.51393402'),
	'identifierSchemeCodeUnspsc190501.51393403': __('identifierSchemeCodeUnspsc190501.51393403'),
	'identifierSchemeCodeUnspsc190501.51393404': __('identifierSchemeCodeUnspsc190501.51393404'),
	'identifierSchemeCodeUnspsc190501.51393405': __('identifierSchemeCodeUnspsc190501.51393405'),
	'identifierSchemeCodeUnspsc190501.51393406': __('identifierSchemeCodeUnspsc190501.51393406'),
	'identifierSchemeCodeUnspsc190501.51393407': __('identifierSchemeCodeUnspsc190501.51393407'),
	'identifierSchemeCodeUnspsc190501.51393408': __('identifierSchemeCodeUnspsc190501.51393408'),
	'identifierSchemeCodeUnspsc190501.51393409': __('identifierSchemeCodeUnspsc190501.51393409'),
	'identifierSchemeCodeUnspsc190501.51393410': __('identifierSchemeCodeUnspsc190501.51393410'),
	'identifierSchemeCodeUnspsc190501.51393411': __('identifierSchemeCodeUnspsc190501.51393411'),
	'identifierSchemeCodeUnspsc190501.51393412': __('identifierSchemeCodeUnspsc190501.51393412'),
	'identifierSchemeCodeUnspsc190501.51393600': __('identifierSchemeCodeUnspsc190501.51393600'),
	'identifierSchemeCodeUnspsc190501.51393601': __('identifierSchemeCodeUnspsc190501.51393601'),
	'identifierSchemeCodeUnspsc190501.51393602': __('identifierSchemeCodeUnspsc190501.51393602'),
	'identifierSchemeCodeUnspsc190501.51393603': __('identifierSchemeCodeUnspsc190501.51393603'),
	'identifierSchemeCodeUnspsc190501.51393604': __('identifierSchemeCodeUnspsc190501.51393604'),
	'identifierSchemeCodeUnspsc190501.51393605': __('identifierSchemeCodeUnspsc190501.51393605'),
	'identifierSchemeCodeUnspsc190501.51393606': __('identifierSchemeCodeUnspsc190501.51393606'),
	'identifierSchemeCodeUnspsc190501.51393607': __('identifierSchemeCodeUnspsc190501.51393607'),
	'identifierSchemeCodeUnspsc190501.51393700': __('identifierSchemeCodeUnspsc190501.51393700'),
	'identifierSchemeCodeUnspsc190501.51393701': __('identifierSchemeCodeUnspsc190501.51393701'),
	'identifierSchemeCodeUnspsc190501.51393702': __('identifierSchemeCodeUnspsc190501.51393702'),
	'identifierSchemeCodeUnspsc190501.51393703': __('identifierSchemeCodeUnspsc190501.51393703'),
	'identifierSchemeCodeUnspsc190501.51393704': __('identifierSchemeCodeUnspsc190501.51393704'),
	'identifierSchemeCodeUnspsc190501.51393800': __('identifierSchemeCodeUnspsc190501.51393800'),
	'identifierSchemeCodeUnspsc190501.51393801': __('identifierSchemeCodeUnspsc190501.51393801'),
	'identifierSchemeCodeUnspsc190501.51393802': __('identifierSchemeCodeUnspsc190501.51393802'),
	'identifierSchemeCodeUnspsc190501.51400000': __('identifierSchemeCodeUnspsc190501.51400000'),
	'identifierSchemeCodeUnspsc190501.51401500': __('identifierSchemeCodeUnspsc190501.51401500'),
	'identifierSchemeCodeUnspsc190501.51401501': __('identifierSchemeCodeUnspsc190501.51401501'),
	'identifierSchemeCodeUnspsc190501.51401502': __('identifierSchemeCodeUnspsc190501.51401502'),
	'identifierSchemeCodeUnspsc190501.51401503': __('identifierSchemeCodeUnspsc190501.51401503'),
	'identifierSchemeCodeUnspsc190501.51401504': __('identifierSchemeCodeUnspsc190501.51401504'),
	'identifierSchemeCodeUnspsc190501.51401505': __('identifierSchemeCodeUnspsc190501.51401505'),
	'identifierSchemeCodeUnspsc190501.51401506': __('identifierSchemeCodeUnspsc190501.51401506'),
	'identifierSchemeCodeUnspsc190501.51401507': __('identifierSchemeCodeUnspsc190501.51401507'),
	'identifierSchemeCodeUnspsc190501.51401508': __('identifierSchemeCodeUnspsc190501.51401508'),
	'identifierSchemeCodeUnspsc190501.51401509': __('identifierSchemeCodeUnspsc190501.51401509'),
	'identifierSchemeCodeUnspsc190501.51401510': __('identifierSchemeCodeUnspsc190501.51401510'),
	'identifierSchemeCodeUnspsc190501.51401511': __('identifierSchemeCodeUnspsc190501.51401511'),
	'identifierSchemeCodeUnspsc190501.51401512': __('identifierSchemeCodeUnspsc190501.51401512'),
	'identifierSchemeCodeUnspsc190501.51401513': __('identifierSchemeCodeUnspsc190501.51401513'),
	'identifierSchemeCodeUnspsc190501.51401514': __('identifierSchemeCodeUnspsc190501.51401514'),
	'identifierSchemeCodeUnspsc190501.51401515': __('identifierSchemeCodeUnspsc190501.51401515'),
	'identifierSchemeCodeUnspsc190501.51401516': __('identifierSchemeCodeUnspsc190501.51401516'),
	'identifierSchemeCodeUnspsc190501.51401517': __('identifierSchemeCodeUnspsc190501.51401517'),
	'identifierSchemeCodeUnspsc190501.51401518': __('identifierSchemeCodeUnspsc190501.51401518'),
	'identifierSchemeCodeUnspsc190501.51401519': __('identifierSchemeCodeUnspsc190501.51401519'),
	'identifierSchemeCodeUnspsc190501.51401520': __('identifierSchemeCodeUnspsc190501.51401520'),
	'identifierSchemeCodeUnspsc190501.51401521': __('identifierSchemeCodeUnspsc190501.51401521'),
	'identifierSchemeCodeUnspsc190501.51401522': __('identifierSchemeCodeUnspsc190501.51401522'),
	'identifierSchemeCodeUnspsc190501.51401523': __('identifierSchemeCodeUnspsc190501.51401523'),
	'identifierSchemeCodeUnspsc190501.51401524': __('identifierSchemeCodeUnspsc190501.51401524'),
	'identifierSchemeCodeUnspsc190501.51401525': __('identifierSchemeCodeUnspsc190501.51401525'),
	'identifierSchemeCodeUnspsc190501.51401526': __('identifierSchemeCodeUnspsc190501.51401526'),
	'identifierSchemeCodeUnspsc190501.51401527': __('identifierSchemeCodeUnspsc190501.51401527'),
	'identifierSchemeCodeUnspsc190501.51401528': __('identifierSchemeCodeUnspsc190501.51401528'),
	'identifierSchemeCodeUnspsc190501.51401529': __('identifierSchemeCodeUnspsc190501.51401529'),
	'identifierSchemeCodeUnspsc190501.51401530': __('identifierSchemeCodeUnspsc190501.51401530'),
	'identifierSchemeCodeUnspsc190501.51401531': __('identifierSchemeCodeUnspsc190501.51401531'),
	'identifierSchemeCodeUnspsc190501.51401532': __('identifierSchemeCodeUnspsc190501.51401532'),
	'identifierSchemeCodeUnspsc190501.51401533': __('identifierSchemeCodeUnspsc190501.51401533'),
	'identifierSchemeCodeUnspsc190501.51401535': __('identifierSchemeCodeUnspsc190501.51401535'),
	'identifierSchemeCodeUnspsc190501.51401536': __('identifierSchemeCodeUnspsc190501.51401536'),
	'identifierSchemeCodeUnspsc190501.51401537': __('identifierSchemeCodeUnspsc190501.51401537'),
	'identifierSchemeCodeUnspsc190501.51401538': __('identifierSchemeCodeUnspsc190501.51401538'),
	'identifierSchemeCodeUnspsc190501.51401539': __('identifierSchemeCodeUnspsc190501.51401539'),
	'identifierSchemeCodeUnspsc190501.51401540': __('identifierSchemeCodeUnspsc190501.51401540'),
	'identifierSchemeCodeUnspsc190501.51401541': __('identifierSchemeCodeUnspsc190501.51401541'),
	'identifierSchemeCodeUnspsc190501.51401542': __('identifierSchemeCodeUnspsc190501.51401542'),
	'identifierSchemeCodeUnspsc190501.51401543': __('identifierSchemeCodeUnspsc190501.51401543'),
	'identifierSchemeCodeUnspsc190501.51401544': __('identifierSchemeCodeUnspsc190501.51401544'),
	'identifierSchemeCodeUnspsc190501.51401545': __('identifierSchemeCodeUnspsc190501.51401545'),
	'identifierSchemeCodeUnspsc190501.51401546': __('identifierSchemeCodeUnspsc190501.51401546'),
	'identifierSchemeCodeUnspsc190501.51401547': __('identifierSchemeCodeUnspsc190501.51401547'),
	'identifierSchemeCodeUnspsc190501.51401548': __('identifierSchemeCodeUnspsc190501.51401548'),
	'identifierSchemeCodeUnspsc190501.51401549': __('identifierSchemeCodeUnspsc190501.51401549'),
	'identifierSchemeCodeUnspsc190501.51401550': __('identifierSchemeCodeUnspsc190501.51401550'),
	'identifierSchemeCodeUnspsc190501.51401551': __('identifierSchemeCodeUnspsc190501.51401551'),
	'identifierSchemeCodeUnspsc190501.51401552': __('identifierSchemeCodeUnspsc190501.51401552'),
	'identifierSchemeCodeUnspsc190501.51401553': __('identifierSchemeCodeUnspsc190501.51401553'),
	'identifierSchemeCodeUnspsc190501.51401554': __('identifierSchemeCodeUnspsc190501.51401554'),
	'identifierSchemeCodeUnspsc190501.51401555': __('identifierSchemeCodeUnspsc190501.51401555'),
	'identifierSchemeCodeUnspsc190501.51401556': __('identifierSchemeCodeUnspsc190501.51401556'),
	'identifierSchemeCodeUnspsc190501.51401557': __('identifierSchemeCodeUnspsc190501.51401557'),
	'identifierSchemeCodeUnspsc190501.51401558': __('identifierSchemeCodeUnspsc190501.51401558'),
	'identifierSchemeCodeUnspsc190501.51401559': __('identifierSchemeCodeUnspsc190501.51401559'),
	'identifierSchemeCodeUnspsc190501.51401560': __('identifierSchemeCodeUnspsc190501.51401560'),
	'identifierSchemeCodeUnspsc190501.51401561': __('identifierSchemeCodeUnspsc190501.51401561'),
	'identifierSchemeCodeUnspsc190501.51401562': __('identifierSchemeCodeUnspsc190501.51401562'),
	'identifierSchemeCodeUnspsc190501.51401563': __('identifierSchemeCodeUnspsc190501.51401563'),
	'identifierSchemeCodeUnspsc190501.51401564': __('identifierSchemeCodeUnspsc190501.51401564'),
	'identifierSchemeCodeUnspsc190501.51401565': __('identifierSchemeCodeUnspsc190501.51401565'),
	'identifierSchemeCodeUnspsc190501.51401566': __('identifierSchemeCodeUnspsc190501.51401566'),
	'identifierSchemeCodeUnspsc190501.51401567': __('identifierSchemeCodeUnspsc190501.51401567'),
	'identifierSchemeCodeUnspsc190501.51401568': __('identifierSchemeCodeUnspsc190501.51401568'),
	'identifierSchemeCodeUnspsc190501.51401569': __('identifierSchemeCodeUnspsc190501.51401569'),
	'identifierSchemeCodeUnspsc190501.51401570': __('identifierSchemeCodeUnspsc190501.51401570'),
	'identifierSchemeCodeUnspsc190501.51401600': __('identifierSchemeCodeUnspsc190501.51401600'),
	'identifierSchemeCodeUnspsc190501.51401601': __('identifierSchemeCodeUnspsc190501.51401601'),
	'identifierSchemeCodeUnspsc190501.51401602': __('identifierSchemeCodeUnspsc190501.51401602'),
	'identifierSchemeCodeUnspsc190501.51401603': __('identifierSchemeCodeUnspsc190501.51401603'),
	'identifierSchemeCodeUnspsc190501.51401604': __('identifierSchemeCodeUnspsc190501.51401604'),
	'identifierSchemeCodeUnspsc190501.51401700': __('identifierSchemeCodeUnspsc190501.51401700'),
	'identifierSchemeCodeUnspsc190501.51401701': __('identifierSchemeCodeUnspsc190501.51401701'),
	'identifierSchemeCodeUnspsc190501.51401702': __('identifierSchemeCodeUnspsc190501.51401702'),
	'identifierSchemeCodeUnspsc190501.51401703': __('identifierSchemeCodeUnspsc190501.51401703'),
	'identifierSchemeCodeUnspsc190501.51401704': __('identifierSchemeCodeUnspsc190501.51401704'),
	'identifierSchemeCodeUnspsc190501.51401705': __('identifierSchemeCodeUnspsc190501.51401705'),
	'identifierSchemeCodeUnspsc190501.51401706': __('identifierSchemeCodeUnspsc190501.51401706'),
	'identifierSchemeCodeUnspsc190501.51401707': __('identifierSchemeCodeUnspsc190501.51401707'),
	'identifierSchemeCodeUnspsc190501.51401708': __('identifierSchemeCodeUnspsc190501.51401708'),
	'identifierSchemeCodeUnspsc190501.51401709': __('identifierSchemeCodeUnspsc190501.51401709'),
	'identifierSchemeCodeUnspsc190501.51401710': __('identifierSchemeCodeUnspsc190501.51401710'),
	'identifierSchemeCodeUnspsc190501.51401800': __('identifierSchemeCodeUnspsc190501.51401800'),
	'identifierSchemeCodeUnspsc190501.51401801': __('identifierSchemeCodeUnspsc190501.51401801'),
	'identifierSchemeCodeUnspsc190501.51401802': __('identifierSchemeCodeUnspsc190501.51401802'),
	'identifierSchemeCodeUnspsc190501.51401803': __('identifierSchemeCodeUnspsc190501.51401803'),
	'identifierSchemeCodeUnspsc190501.51401900': __('identifierSchemeCodeUnspsc190501.51401900'),
	'identifierSchemeCodeUnspsc190501.51401901': __('identifierSchemeCodeUnspsc190501.51401901'),
	'identifierSchemeCodeUnspsc190501.51401902': __('identifierSchemeCodeUnspsc190501.51401902'),
	'identifierSchemeCodeUnspsc190501.51401903': __('identifierSchemeCodeUnspsc190501.51401903'),
	'identifierSchemeCodeUnspsc190501.51401904': __('identifierSchemeCodeUnspsc190501.51401904'),
	'identifierSchemeCodeUnspsc190501.51401905': __('identifierSchemeCodeUnspsc190501.51401905'),
	'identifierSchemeCodeUnspsc190501.51401906': __('identifierSchemeCodeUnspsc190501.51401906'),
	'identifierSchemeCodeUnspsc190501.51402000': __('identifierSchemeCodeUnspsc190501.51402000'),
	'identifierSchemeCodeUnspsc190501.51402001': __('identifierSchemeCodeUnspsc190501.51402001'),
	'identifierSchemeCodeUnspsc190501.51402002': __('identifierSchemeCodeUnspsc190501.51402002'),
	'identifierSchemeCodeUnspsc190501.51402003': __('identifierSchemeCodeUnspsc190501.51402003'),
	'identifierSchemeCodeUnspsc190501.51402004': __('identifierSchemeCodeUnspsc190501.51402004'),
	'identifierSchemeCodeUnspsc190501.51402100': __('identifierSchemeCodeUnspsc190501.51402100'),
	'identifierSchemeCodeUnspsc190501.51402101': __('identifierSchemeCodeUnspsc190501.51402101'),
	'identifierSchemeCodeUnspsc190501.51402102': __('identifierSchemeCodeUnspsc190501.51402102'),
	'identifierSchemeCodeUnspsc190501.51402200': __('identifierSchemeCodeUnspsc190501.51402200'),
	'identifierSchemeCodeUnspsc190501.51402201': __('identifierSchemeCodeUnspsc190501.51402201'),
	'identifierSchemeCodeUnspsc190501.51402202': __('identifierSchemeCodeUnspsc190501.51402202'),
	'identifierSchemeCodeUnspsc190501.51402203': __('identifierSchemeCodeUnspsc190501.51402203'),
	'identifierSchemeCodeUnspsc190501.51402204': __('identifierSchemeCodeUnspsc190501.51402204'),
	'identifierSchemeCodeUnspsc190501.51402205': __('identifierSchemeCodeUnspsc190501.51402205'),
	'identifierSchemeCodeUnspsc190501.51402206': __('identifierSchemeCodeUnspsc190501.51402206'),
	'identifierSchemeCodeUnspsc190501.51402207': __('identifierSchemeCodeUnspsc190501.51402207'),
	'identifierSchemeCodeUnspsc190501.51402300': __('identifierSchemeCodeUnspsc190501.51402300'),
	'identifierSchemeCodeUnspsc190501.51402301': __('identifierSchemeCodeUnspsc190501.51402301'),
	'identifierSchemeCodeUnspsc190501.51402302': __('identifierSchemeCodeUnspsc190501.51402302'),
	'identifierSchemeCodeUnspsc190501.51402303': __('identifierSchemeCodeUnspsc190501.51402303'),
	'identifierSchemeCodeUnspsc190501.51402304': __('identifierSchemeCodeUnspsc190501.51402304'),
	'identifierSchemeCodeUnspsc190501.51402305': __('identifierSchemeCodeUnspsc190501.51402305'),
	'identifierSchemeCodeUnspsc190501.51402306': __('identifierSchemeCodeUnspsc190501.51402306'),
	'identifierSchemeCodeUnspsc190501.51402307': __('identifierSchemeCodeUnspsc190501.51402307'),
	'identifierSchemeCodeUnspsc190501.51402308': __('identifierSchemeCodeUnspsc190501.51402308'),
	'identifierSchemeCodeUnspsc190501.51402309': __('identifierSchemeCodeUnspsc190501.51402309'),
	'identifierSchemeCodeUnspsc190501.51402310': __('identifierSchemeCodeUnspsc190501.51402310'),
	'identifierSchemeCodeUnspsc190501.51402400': __('identifierSchemeCodeUnspsc190501.51402400'),
	'identifierSchemeCodeUnspsc190501.51402401': __('identifierSchemeCodeUnspsc190501.51402401'),
	'identifierSchemeCodeUnspsc190501.51402402': __('identifierSchemeCodeUnspsc190501.51402402'),
	'identifierSchemeCodeUnspsc190501.51402403': __('identifierSchemeCodeUnspsc190501.51402403'),
	'identifierSchemeCodeUnspsc190501.51402404': __('identifierSchemeCodeUnspsc190501.51402404'),
	'identifierSchemeCodeUnspsc190501.51402500': __('identifierSchemeCodeUnspsc190501.51402500'),
	'identifierSchemeCodeUnspsc190501.51402501': __('identifierSchemeCodeUnspsc190501.51402501'),
	'identifierSchemeCodeUnspsc190501.51402502': __('identifierSchemeCodeUnspsc190501.51402502'),
	'identifierSchemeCodeUnspsc190501.51402600': __('identifierSchemeCodeUnspsc190501.51402600'),
	'identifierSchemeCodeUnspsc190501.51402601': __('identifierSchemeCodeUnspsc190501.51402601'),
	'identifierSchemeCodeUnspsc190501.51402602': __('identifierSchemeCodeUnspsc190501.51402602'),
	'identifierSchemeCodeUnspsc190501.51402603': __('identifierSchemeCodeUnspsc190501.51402603'),
	'identifierSchemeCodeUnspsc190501.51410000': __('identifierSchemeCodeUnspsc190501.51410000'),
	'identifierSchemeCodeUnspsc190501.51411500': __('identifierSchemeCodeUnspsc190501.51411500'),
	'identifierSchemeCodeUnspsc190501.51411501': __('identifierSchemeCodeUnspsc190501.51411501'),
	'identifierSchemeCodeUnspsc190501.51411502': __('identifierSchemeCodeUnspsc190501.51411502'),
	'identifierSchemeCodeUnspsc190501.51411503': __('identifierSchemeCodeUnspsc190501.51411503'),
	'identifierSchemeCodeUnspsc190501.51411504': __('identifierSchemeCodeUnspsc190501.51411504'),
	'identifierSchemeCodeUnspsc190501.51411505': __('identifierSchemeCodeUnspsc190501.51411505'),
	'identifierSchemeCodeUnspsc190501.51411600': __('identifierSchemeCodeUnspsc190501.51411600'),
	'identifierSchemeCodeUnspsc190501.51411601': __('identifierSchemeCodeUnspsc190501.51411601'),
	'identifierSchemeCodeUnspsc190501.51411602': __('identifierSchemeCodeUnspsc190501.51411602'),
	'identifierSchemeCodeUnspsc190501.51411603': __('identifierSchemeCodeUnspsc190501.51411603'),
	'identifierSchemeCodeUnspsc190501.51411604': __('identifierSchemeCodeUnspsc190501.51411604'),
	'identifierSchemeCodeUnspsc190501.51411605': __('identifierSchemeCodeUnspsc190501.51411605'),
	'identifierSchemeCodeUnspsc190501.51411606': __('identifierSchemeCodeUnspsc190501.51411606'),
	'identifierSchemeCodeUnspsc190501.51411607': __('identifierSchemeCodeUnspsc190501.51411607'),
	'identifierSchemeCodeUnspsc190501.51411608': __('identifierSchemeCodeUnspsc190501.51411608'),
	'identifierSchemeCodeUnspsc190501.51411609': __('identifierSchemeCodeUnspsc190501.51411609'),
	'identifierSchemeCodeUnspsc190501.51411610': __('identifierSchemeCodeUnspsc190501.51411610'),
	'identifierSchemeCodeUnspsc190501.51411611': __('identifierSchemeCodeUnspsc190501.51411611'),
	'identifierSchemeCodeUnspsc190501.51411612': __('identifierSchemeCodeUnspsc190501.51411612'),
	'identifierSchemeCodeUnspsc190501.51411613': __('identifierSchemeCodeUnspsc190501.51411613'),
	'identifierSchemeCodeUnspsc190501.51411614': __('identifierSchemeCodeUnspsc190501.51411614'),
	'identifierSchemeCodeUnspsc190501.51411615': __('identifierSchemeCodeUnspsc190501.51411615'),
	'identifierSchemeCodeUnspsc190501.51411616': __('identifierSchemeCodeUnspsc190501.51411616'),
	'identifierSchemeCodeUnspsc190501.51411700': __('identifierSchemeCodeUnspsc190501.51411700'),
	'identifierSchemeCodeUnspsc190501.51411701': __('identifierSchemeCodeUnspsc190501.51411701'),
	'identifierSchemeCodeUnspsc190501.51411702': __('identifierSchemeCodeUnspsc190501.51411702'),
	'identifierSchemeCodeUnspsc190501.51411703': __('identifierSchemeCodeUnspsc190501.51411703'),
	'identifierSchemeCodeUnspsc190501.51411704': __('identifierSchemeCodeUnspsc190501.51411704'),
	'identifierSchemeCodeUnspsc190501.51411705': __('identifierSchemeCodeUnspsc190501.51411705'),
	'identifierSchemeCodeUnspsc190501.51411706': __('identifierSchemeCodeUnspsc190501.51411706'),
	'identifierSchemeCodeUnspsc190501.51411707': __('identifierSchemeCodeUnspsc190501.51411707'),
	'identifierSchemeCodeUnspsc190501.51411708': __('identifierSchemeCodeUnspsc190501.51411708'),
	'identifierSchemeCodeUnspsc190501.51411709': __('identifierSchemeCodeUnspsc190501.51411709'),
	'identifierSchemeCodeUnspsc190501.51411710': __('identifierSchemeCodeUnspsc190501.51411710'),
	'identifierSchemeCodeUnspsc190501.51411711': __('identifierSchemeCodeUnspsc190501.51411711'),
	'identifierSchemeCodeUnspsc190501.51411712': __('identifierSchemeCodeUnspsc190501.51411712'),
	'identifierSchemeCodeUnspsc190501.51411713': __('identifierSchemeCodeUnspsc190501.51411713'),
	'identifierSchemeCodeUnspsc190501.51411714': __('identifierSchemeCodeUnspsc190501.51411714'),
	'identifierSchemeCodeUnspsc190501.51411800': __('identifierSchemeCodeUnspsc190501.51411800'),
	'identifierSchemeCodeUnspsc190501.51411801': __('identifierSchemeCodeUnspsc190501.51411801'),
	'identifierSchemeCodeUnspsc190501.51411802': __('identifierSchemeCodeUnspsc190501.51411802'),
	'identifierSchemeCodeUnspsc190501.51411803': __('identifierSchemeCodeUnspsc190501.51411803'),
	'identifierSchemeCodeUnspsc190501.51411804': __('identifierSchemeCodeUnspsc190501.51411804'),
	'identifierSchemeCodeUnspsc190501.51411805': __('identifierSchemeCodeUnspsc190501.51411805'),
	'identifierSchemeCodeUnspsc190501.51411806': __('identifierSchemeCodeUnspsc190501.51411806'),
	'identifierSchemeCodeUnspsc190501.51411900': __('identifierSchemeCodeUnspsc190501.51411900'),
	'identifierSchemeCodeUnspsc190501.51411901': __('identifierSchemeCodeUnspsc190501.51411901'),
	'identifierSchemeCodeUnspsc190501.51411902': __('identifierSchemeCodeUnspsc190501.51411902'),
	'identifierSchemeCodeUnspsc190501.51411903': __('identifierSchemeCodeUnspsc190501.51411903'),
	'identifierSchemeCodeUnspsc190501.51411904': __('identifierSchemeCodeUnspsc190501.51411904'),
	'identifierSchemeCodeUnspsc190501.51411905': __('identifierSchemeCodeUnspsc190501.51411905'),
	'identifierSchemeCodeUnspsc190501.51411906': __('identifierSchemeCodeUnspsc190501.51411906'),
	'identifierSchemeCodeUnspsc190501.51412000': __('identifierSchemeCodeUnspsc190501.51412000'),
	'identifierSchemeCodeUnspsc190501.51412001': __('identifierSchemeCodeUnspsc190501.51412001'),
	'identifierSchemeCodeUnspsc190501.51412002': __('identifierSchemeCodeUnspsc190501.51412002'),
	'identifierSchemeCodeUnspsc190501.51412003': __('identifierSchemeCodeUnspsc190501.51412003'),
	'identifierSchemeCodeUnspsc190501.51412004': __('identifierSchemeCodeUnspsc190501.51412004'),
	'identifierSchemeCodeUnspsc190501.51412005': __('identifierSchemeCodeUnspsc190501.51412005'),
	'identifierSchemeCodeUnspsc190501.51412006': __('identifierSchemeCodeUnspsc190501.51412006'),
	'identifierSchemeCodeUnspsc190501.51412007': __('identifierSchemeCodeUnspsc190501.51412007'),
	'identifierSchemeCodeUnspsc190501.51412009': __('identifierSchemeCodeUnspsc190501.51412009'),
	'identifierSchemeCodeUnspsc190501.51412010': __('identifierSchemeCodeUnspsc190501.51412010'),
	'identifierSchemeCodeUnspsc190501.51412011': __('identifierSchemeCodeUnspsc190501.51412011'),
	'identifierSchemeCodeUnspsc190501.51412012': __('identifierSchemeCodeUnspsc190501.51412012'),
	'identifierSchemeCodeUnspsc190501.51412013': __('identifierSchemeCodeUnspsc190501.51412013'),
	'identifierSchemeCodeUnspsc190501.51412014': __('identifierSchemeCodeUnspsc190501.51412014'),
	'identifierSchemeCodeUnspsc190501.51412015': __('identifierSchemeCodeUnspsc190501.51412015'),
	'identifierSchemeCodeUnspsc190501.51412016': __('identifierSchemeCodeUnspsc190501.51412016'),
	'identifierSchemeCodeUnspsc190501.51412100': __('identifierSchemeCodeUnspsc190501.51412100'),
	'identifierSchemeCodeUnspsc190501.51412101': __('identifierSchemeCodeUnspsc190501.51412101'),
	'identifierSchemeCodeUnspsc190501.51412102': __('identifierSchemeCodeUnspsc190501.51412102'),
	'identifierSchemeCodeUnspsc190501.51412103': __('identifierSchemeCodeUnspsc190501.51412103'),
	'identifierSchemeCodeUnspsc190501.51412300': __('identifierSchemeCodeUnspsc190501.51412300'),
	'identifierSchemeCodeUnspsc190501.51412301': __('identifierSchemeCodeUnspsc190501.51412301'),
	'identifierSchemeCodeUnspsc190501.51412302': __('identifierSchemeCodeUnspsc190501.51412302'),
	'identifierSchemeCodeUnspsc190501.51412400': __('identifierSchemeCodeUnspsc190501.51412400'),
	'identifierSchemeCodeUnspsc190501.51412401': __('identifierSchemeCodeUnspsc190501.51412401'),
	'identifierSchemeCodeUnspsc190501.51412402': __('identifierSchemeCodeUnspsc190501.51412402'),
	'identifierSchemeCodeUnspsc190501.51412403': __('identifierSchemeCodeUnspsc190501.51412403'),
	'identifierSchemeCodeUnspsc190501.51412404': __('identifierSchemeCodeUnspsc190501.51412404'),
	'identifierSchemeCodeUnspsc190501.51412500': __('identifierSchemeCodeUnspsc190501.51412500'),
	'identifierSchemeCodeUnspsc190501.51412501': __('identifierSchemeCodeUnspsc190501.51412501'),
	'identifierSchemeCodeUnspsc190501.51412502': __('identifierSchemeCodeUnspsc190501.51412502'),
	'identifierSchemeCodeUnspsc190501.51412503': __('identifierSchemeCodeUnspsc190501.51412503'),
	'identifierSchemeCodeUnspsc190501.51412600': __('identifierSchemeCodeUnspsc190501.51412600'),
	'identifierSchemeCodeUnspsc190501.51412601': __('identifierSchemeCodeUnspsc190501.51412601'),
	'identifierSchemeCodeUnspsc190501.51412602': __('identifierSchemeCodeUnspsc190501.51412602'),
	'identifierSchemeCodeUnspsc190501.51412603': __('identifierSchemeCodeUnspsc190501.51412603'),
	'identifierSchemeCodeUnspsc190501.51412604': __('identifierSchemeCodeUnspsc190501.51412604'),
	'identifierSchemeCodeUnspsc190501.51412605': __('identifierSchemeCodeUnspsc190501.51412605'),
	'identifierSchemeCodeUnspsc190501.51412606': __('identifierSchemeCodeUnspsc190501.51412606'),
	'identifierSchemeCodeUnspsc190501.51412700': __('identifierSchemeCodeUnspsc190501.51412700'),
	'identifierSchemeCodeUnspsc190501.51412701': __('identifierSchemeCodeUnspsc190501.51412701'),
	'identifierSchemeCodeUnspsc190501.51412702': __('identifierSchemeCodeUnspsc190501.51412702'),
	'identifierSchemeCodeUnspsc190501.51412703': __('identifierSchemeCodeUnspsc190501.51412703'),
	'identifierSchemeCodeUnspsc190501.51412704': __('identifierSchemeCodeUnspsc190501.51412704'),
	'identifierSchemeCodeUnspsc190501.51412800': __('identifierSchemeCodeUnspsc190501.51412800'),
	'identifierSchemeCodeUnspsc190501.51412801': __('identifierSchemeCodeUnspsc190501.51412801'),
	'identifierSchemeCodeUnspsc190501.51412802': __('identifierSchemeCodeUnspsc190501.51412802'),
	'identifierSchemeCodeUnspsc190501.51412803': __('identifierSchemeCodeUnspsc190501.51412803'),
	'identifierSchemeCodeUnspsc190501.51412900': __('identifierSchemeCodeUnspsc190501.51412900'),
	'identifierSchemeCodeUnspsc190501.51412901': __('identifierSchemeCodeUnspsc190501.51412901'),
	'identifierSchemeCodeUnspsc190501.51412902': __('identifierSchemeCodeUnspsc190501.51412902'),
	'identifierSchemeCodeUnspsc190501.51412903': __('identifierSchemeCodeUnspsc190501.51412903'),
	'identifierSchemeCodeUnspsc190501.51413000': __('identifierSchemeCodeUnspsc190501.51413000'),
	'identifierSchemeCodeUnspsc190501.51413001': __('identifierSchemeCodeUnspsc190501.51413001'),
	'identifierSchemeCodeUnspsc190501.51413002': __('identifierSchemeCodeUnspsc190501.51413002'),
	'identifierSchemeCodeUnspsc190501.51413003': __('identifierSchemeCodeUnspsc190501.51413003'),
	'identifierSchemeCodeUnspsc190501.51413004': __('identifierSchemeCodeUnspsc190501.51413004'),
	'identifierSchemeCodeUnspsc190501.51413005': __('identifierSchemeCodeUnspsc190501.51413005'),
	'identifierSchemeCodeUnspsc190501.51413006': __('identifierSchemeCodeUnspsc190501.51413006'),
	'identifierSchemeCodeUnspsc190501.51413100': __('identifierSchemeCodeUnspsc190501.51413100'),
	'identifierSchemeCodeUnspsc190501.51413101': __('identifierSchemeCodeUnspsc190501.51413101'),
	'identifierSchemeCodeUnspsc190501.51413102': __('identifierSchemeCodeUnspsc190501.51413102'),
	'identifierSchemeCodeUnspsc190501.51413103': __('identifierSchemeCodeUnspsc190501.51413103'),
	'identifierSchemeCodeUnspsc190501.51413104': __('identifierSchemeCodeUnspsc190501.51413104'),
	'identifierSchemeCodeUnspsc190501.51413105': __('identifierSchemeCodeUnspsc190501.51413105'),
	'identifierSchemeCodeUnspsc190501.51413106': __('identifierSchemeCodeUnspsc190501.51413106'),
	'identifierSchemeCodeUnspsc190501.51413107': __('identifierSchemeCodeUnspsc190501.51413107'),
	'identifierSchemeCodeUnspsc190501.51413108': __('identifierSchemeCodeUnspsc190501.51413108'),
	'identifierSchemeCodeUnspsc190501.51413200': __('identifierSchemeCodeUnspsc190501.51413200'),
	'identifierSchemeCodeUnspsc190501.51413201': __('identifierSchemeCodeUnspsc190501.51413201'),
	'identifierSchemeCodeUnspsc190501.51413202': __('identifierSchemeCodeUnspsc190501.51413202'),
	'identifierSchemeCodeUnspsc190501.51413203': __('identifierSchemeCodeUnspsc190501.51413203'),
	'identifierSchemeCodeUnspsc190501.51413204': __('identifierSchemeCodeUnspsc190501.51413204'),
	'identifierSchemeCodeUnspsc190501.51413205': __('identifierSchemeCodeUnspsc190501.51413205'),
	'identifierSchemeCodeUnspsc190501.51413206': __('identifierSchemeCodeUnspsc190501.51413206'),
	'identifierSchemeCodeUnspsc190501.51413207': __('identifierSchemeCodeUnspsc190501.51413207'),
	'identifierSchemeCodeUnspsc190501.51413208': __('identifierSchemeCodeUnspsc190501.51413208'),
	'identifierSchemeCodeUnspsc190501.51413209': __('identifierSchemeCodeUnspsc190501.51413209'),
	'identifierSchemeCodeUnspsc190501.51413210': __('identifierSchemeCodeUnspsc190501.51413210'),
	'identifierSchemeCodeUnspsc190501.51413211': __('identifierSchemeCodeUnspsc190501.51413211'),
	'identifierSchemeCodeUnspsc190501.51413212': __('identifierSchemeCodeUnspsc190501.51413212'),
	'identifierSchemeCodeUnspsc190501.51413213': __('identifierSchemeCodeUnspsc190501.51413213'),
	'identifierSchemeCodeUnspsc190501.51413300': __('identifierSchemeCodeUnspsc190501.51413300'),
	'identifierSchemeCodeUnspsc190501.51413301': __('identifierSchemeCodeUnspsc190501.51413301'),
	'identifierSchemeCodeUnspsc190501.51413302': __('identifierSchemeCodeUnspsc190501.51413302'),
	'identifierSchemeCodeUnspsc190501.51413303': __('identifierSchemeCodeUnspsc190501.51413303'),
	'identifierSchemeCodeUnspsc190501.51413400': __('identifierSchemeCodeUnspsc190501.51413400'),
	'identifierSchemeCodeUnspsc190501.51413401': __('identifierSchemeCodeUnspsc190501.51413401'),
	'identifierSchemeCodeUnspsc190501.51413402': __('identifierSchemeCodeUnspsc190501.51413402'),
	'identifierSchemeCodeUnspsc190501.51413403': __('identifierSchemeCodeUnspsc190501.51413403'),
	'identifierSchemeCodeUnspsc190501.51413404': __('identifierSchemeCodeUnspsc190501.51413404'),
	'identifierSchemeCodeUnspsc190501.51413500': __('identifierSchemeCodeUnspsc190501.51413500'),
	'identifierSchemeCodeUnspsc190501.51413501': __('identifierSchemeCodeUnspsc190501.51413501'),
	'identifierSchemeCodeUnspsc190501.51413502': __('identifierSchemeCodeUnspsc190501.51413502'),
	'identifierSchemeCodeUnspsc190501.51413503': __('identifierSchemeCodeUnspsc190501.51413503'),
	'identifierSchemeCodeUnspsc190501.51413504': __('identifierSchemeCodeUnspsc190501.51413504'),
	'identifierSchemeCodeUnspsc190501.51413505': __('identifierSchemeCodeUnspsc190501.51413505'),
	'identifierSchemeCodeUnspsc190501.51413506': __('identifierSchemeCodeUnspsc190501.51413506'),
	'identifierSchemeCodeUnspsc190501.51413600': __('identifierSchemeCodeUnspsc190501.51413600'),
	'identifierSchemeCodeUnspsc190501.51413601': __('identifierSchemeCodeUnspsc190501.51413601'),
	'identifierSchemeCodeUnspsc190501.51413602': __('identifierSchemeCodeUnspsc190501.51413602'),
	'identifierSchemeCodeUnspsc190501.51413603': __('identifierSchemeCodeUnspsc190501.51413603'),
	'identifierSchemeCodeUnspsc190501.51413604': __('identifierSchemeCodeUnspsc190501.51413604'),
	'identifierSchemeCodeUnspsc190501.51413700': __('identifierSchemeCodeUnspsc190501.51413700'),
	'identifierSchemeCodeUnspsc190501.51413701': __('identifierSchemeCodeUnspsc190501.51413701'),
	'identifierSchemeCodeUnspsc190501.51413702': __('identifierSchemeCodeUnspsc190501.51413702'),
	'identifierSchemeCodeUnspsc190501.51413703': __('identifierSchemeCodeUnspsc190501.51413703'),
	'identifierSchemeCodeUnspsc190501.51413800': __('identifierSchemeCodeUnspsc190501.51413800'),
	'identifierSchemeCodeUnspsc190501.51413801': __('identifierSchemeCodeUnspsc190501.51413801'),
	'identifierSchemeCodeUnspsc190501.51413900': __('identifierSchemeCodeUnspsc190501.51413900'),
	'identifierSchemeCodeUnspsc190501.51413901': __('identifierSchemeCodeUnspsc190501.51413901'),
	'identifierSchemeCodeUnspsc190501.51413902': __('identifierSchemeCodeUnspsc190501.51413902'),
	'identifierSchemeCodeUnspsc190501.51413903': __('identifierSchemeCodeUnspsc190501.51413903'),
	'identifierSchemeCodeUnspsc190501.51413904': __('identifierSchemeCodeUnspsc190501.51413904'),
	'identifierSchemeCodeUnspsc190501.51413905': __('identifierSchemeCodeUnspsc190501.51413905'),
	'identifierSchemeCodeUnspsc190501.51413906': __('identifierSchemeCodeUnspsc190501.51413906'),
	'identifierSchemeCodeUnspsc190501.51413907': __('identifierSchemeCodeUnspsc190501.51413907'),
	'identifierSchemeCodeUnspsc190501.51413908': __('identifierSchemeCodeUnspsc190501.51413908'),
	'identifierSchemeCodeUnspsc190501.51413909': __('identifierSchemeCodeUnspsc190501.51413909'),
	'identifierSchemeCodeUnspsc190501.51413910': __('identifierSchemeCodeUnspsc190501.51413910'),
	'identifierSchemeCodeUnspsc190501.51413911': __('identifierSchemeCodeUnspsc190501.51413911'),
	'identifierSchemeCodeUnspsc190501.51413912': __('identifierSchemeCodeUnspsc190501.51413912'),
	'identifierSchemeCodeUnspsc190501.51413913': __('identifierSchemeCodeUnspsc190501.51413913'),
	'identifierSchemeCodeUnspsc190501.51414000': __('identifierSchemeCodeUnspsc190501.51414000'),
	'identifierSchemeCodeUnspsc190501.51414001': __('identifierSchemeCodeUnspsc190501.51414001'),
	'identifierSchemeCodeUnspsc190501.51414002': __('identifierSchemeCodeUnspsc190501.51414002'),
	'identifierSchemeCodeUnspsc190501.51414100': __('identifierSchemeCodeUnspsc190501.51414100'),
	'identifierSchemeCodeUnspsc190501.51414101': __('identifierSchemeCodeUnspsc190501.51414101'),
	'identifierSchemeCodeUnspsc190501.51414102': __('identifierSchemeCodeUnspsc190501.51414102'),
	'identifierSchemeCodeUnspsc190501.51420000': __('identifierSchemeCodeUnspsc190501.51420000'),
	'identifierSchemeCodeUnspsc190501.51421500': __('identifierSchemeCodeUnspsc190501.51421500'),
	'identifierSchemeCodeUnspsc190501.51421501': __('identifierSchemeCodeUnspsc190501.51421501'),
	'identifierSchemeCodeUnspsc190501.51421502': __('identifierSchemeCodeUnspsc190501.51421502'),
	'identifierSchemeCodeUnspsc190501.51421503': __('identifierSchemeCodeUnspsc190501.51421503'),
	'identifierSchemeCodeUnspsc190501.51421504': __('identifierSchemeCodeUnspsc190501.51421504'),
	'identifierSchemeCodeUnspsc190501.51421505': __('identifierSchemeCodeUnspsc190501.51421505'),
	'identifierSchemeCodeUnspsc190501.51421506': __('identifierSchemeCodeUnspsc190501.51421506'),
	'identifierSchemeCodeUnspsc190501.51421507': __('identifierSchemeCodeUnspsc190501.51421507'),
	'identifierSchemeCodeUnspsc190501.51421600': __('identifierSchemeCodeUnspsc190501.51421600'),
	'identifierSchemeCodeUnspsc190501.51421601': __('identifierSchemeCodeUnspsc190501.51421601'),
	'identifierSchemeCodeUnspsc190501.51421602': __('identifierSchemeCodeUnspsc190501.51421602'),
	'identifierSchemeCodeUnspsc190501.51421603': __('identifierSchemeCodeUnspsc190501.51421603'),
	'identifierSchemeCodeUnspsc190501.51421604': __('identifierSchemeCodeUnspsc190501.51421604'),
	'identifierSchemeCodeUnspsc190501.51421605': __('identifierSchemeCodeUnspsc190501.51421605'),
	'identifierSchemeCodeUnspsc190501.51421606': __('identifierSchemeCodeUnspsc190501.51421606'),
	'identifierSchemeCodeUnspsc190501.51421607': __('identifierSchemeCodeUnspsc190501.51421607'),
	'identifierSchemeCodeUnspsc190501.51421608': __('identifierSchemeCodeUnspsc190501.51421608'),
	'identifierSchemeCodeUnspsc190501.51421700': __('identifierSchemeCodeUnspsc190501.51421700'),
	'identifierSchemeCodeUnspsc190501.51421701': __('identifierSchemeCodeUnspsc190501.51421701'),
	'identifierSchemeCodeUnspsc190501.51421702': __('identifierSchemeCodeUnspsc190501.51421702'),
	'identifierSchemeCodeUnspsc190501.51421800': __('identifierSchemeCodeUnspsc190501.51421800'),
	'identifierSchemeCodeUnspsc190501.51421801': __('identifierSchemeCodeUnspsc190501.51421801'),
	'identifierSchemeCodeUnspsc190501.51421802': __('identifierSchemeCodeUnspsc190501.51421802'),
	'identifierSchemeCodeUnspsc190501.51421900': __('identifierSchemeCodeUnspsc190501.51421900'),
	'identifierSchemeCodeUnspsc190501.51421901': __('identifierSchemeCodeUnspsc190501.51421901'),
	'identifierSchemeCodeUnspsc190501.51421902': __('identifierSchemeCodeUnspsc190501.51421902'),
	'identifierSchemeCodeUnspsc190501.51421903': __('identifierSchemeCodeUnspsc190501.51421903'),
	'identifierSchemeCodeUnspsc190501.51421904': __('identifierSchemeCodeUnspsc190501.51421904'),
	'identifierSchemeCodeUnspsc190501.51421905': __('identifierSchemeCodeUnspsc190501.51421905'),
	'identifierSchemeCodeUnspsc190501.51421906': __('identifierSchemeCodeUnspsc190501.51421906'),
	'identifierSchemeCodeUnspsc190501.51421907': __('identifierSchemeCodeUnspsc190501.51421907'),
	'identifierSchemeCodeUnspsc190501.51422000': __('identifierSchemeCodeUnspsc190501.51422000'),
	'identifierSchemeCodeUnspsc190501.51422001': __('identifierSchemeCodeUnspsc190501.51422001'),
	'identifierSchemeCodeUnspsc190501.51422002': __('identifierSchemeCodeUnspsc190501.51422002'),
	'identifierSchemeCodeUnspsc190501.51422003': __('identifierSchemeCodeUnspsc190501.51422003'),
	'identifierSchemeCodeUnspsc190501.51422100': __('identifierSchemeCodeUnspsc190501.51422100'),
	'identifierSchemeCodeUnspsc190501.51422101': __('identifierSchemeCodeUnspsc190501.51422101'),
	'identifierSchemeCodeUnspsc190501.51422102': __('identifierSchemeCodeUnspsc190501.51422102'),
	'identifierSchemeCodeUnspsc190501.51422103': __('identifierSchemeCodeUnspsc190501.51422103'),
	'identifierSchemeCodeUnspsc190501.51422104': __('identifierSchemeCodeUnspsc190501.51422104'),
	'identifierSchemeCodeUnspsc190501.51422105': __('identifierSchemeCodeUnspsc190501.51422105'),
	'identifierSchemeCodeUnspsc190501.51422106': __('identifierSchemeCodeUnspsc190501.51422106'),
	'identifierSchemeCodeUnspsc190501.51422107': __('identifierSchemeCodeUnspsc190501.51422107'),
	'identifierSchemeCodeUnspsc190501.51422108': __('identifierSchemeCodeUnspsc190501.51422108'),
	'identifierSchemeCodeUnspsc190501.51422109': __('identifierSchemeCodeUnspsc190501.51422109'),
	'identifierSchemeCodeUnspsc190501.51422110': __('identifierSchemeCodeUnspsc190501.51422110'),
	'identifierSchemeCodeUnspsc190501.51422111': __('identifierSchemeCodeUnspsc190501.51422111'),
	'identifierSchemeCodeUnspsc190501.51422112': __('identifierSchemeCodeUnspsc190501.51422112'),
	'identifierSchemeCodeUnspsc190501.51422113': __('identifierSchemeCodeUnspsc190501.51422113'),
	'identifierSchemeCodeUnspsc190501.51422114': __('identifierSchemeCodeUnspsc190501.51422114'),
	'identifierSchemeCodeUnspsc190501.51422115': __('identifierSchemeCodeUnspsc190501.51422115'),
	'identifierSchemeCodeUnspsc190501.51422116': __('identifierSchemeCodeUnspsc190501.51422116'),
	'identifierSchemeCodeUnspsc190501.51422117': __('identifierSchemeCodeUnspsc190501.51422117'),
	'identifierSchemeCodeUnspsc190501.51422118': __('identifierSchemeCodeUnspsc190501.51422118'),
	'identifierSchemeCodeUnspsc190501.51422119': __('identifierSchemeCodeUnspsc190501.51422119'),
	'identifierSchemeCodeUnspsc190501.51422120': __('identifierSchemeCodeUnspsc190501.51422120'),
	'identifierSchemeCodeUnspsc190501.51422121': __('identifierSchemeCodeUnspsc190501.51422121'),
	'identifierSchemeCodeUnspsc190501.51422122': __('identifierSchemeCodeUnspsc190501.51422122'),
	'identifierSchemeCodeUnspsc190501.51422123': __('identifierSchemeCodeUnspsc190501.51422123'),
	'identifierSchemeCodeUnspsc190501.51422200': __('identifierSchemeCodeUnspsc190501.51422200'),
	'identifierSchemeCodeUnspsc190501.51422201': __('identifierSchemeCodeUnspsc190501.51422201'),
	'identifierSchemeCodeUnspsc190501.51422202': __('identifierSchemeCodeUnspsc190501.51422202'),
	'identifierSchemeCodeUnspsc190501.51422203': __('identifierSchemeCodeUnspsc190501.51422203'),
	'identifierSchemeCodeUnspsc190501.51422204': __('identifierSchemeCodeUnspsc190501.51422204'),
	'identifierSchemeCodeUnspsc190501.51422205': __('identifierSchemeCodeUnspsc190501.51422205'),
	'identifierSchemeCodeUnspsc190501.51422206': __('identifierSchemeCodeUnspsc190501.51422206'),
	'identifierSchemeCodeUnspsc190501.51422207': __('identifierSchemeCodeUnspsc190501.51422207'),
	'identifierSchemeCodeUnspsc190501.51422208': __('identifierSchemeCodeUnspsc190501.51422208'),
	'identifierSchemeCodeUnspsc190501.51422209': __('identifierSchemeCodeUnspsc190501.51422209'),
	'identifierSchemeCodeUnspsc190501.51422210': __('identifierSchemeCodeUnspsc190501.51422210'),
	'identifierSchemeCodeUnspsc190501.51422211': __('identifierSchemeCodeUnspsc190501.51422211'),
	'identifierSchemeCodeUnspsc190501.51422212': __('identifierSchemeCodeUnspsc190501.51422212'),
	'identifierSchemeCodeUnspsc190501.51422213': __('identifierSchemeCodeUnspsc190501.51422213'),
	'identifierSchemeCodeUnspsc190501.51422214': __('identifierSchemeCodeUnspsc190501.51422214'),
	'identifierSchemeCodeUnspsc190501.51422215': __('identifierSchemeCodeUnspsc190501.51422215'),
	'identifierSchemeCodeUnspsc190501.51422216': __('identifierSchemeCodeUnspsc190501.51422216'),
	'identifierSchemeCodeUnspsc190501.51422300': __('identifierSchemeCodeUnspsc190501.51422300'),
	'identifierSchemeCodeUnspsc190501.51422301': __('identifierSchemeCodeUnspsc190501.51422301'),
	'identifierSchemeCodeUnspsc190501.51422302': __('identifierSchemeCodeUnspsc190501.51422302'),
	'identifierSchemeCodeUnspsc190501.51422303': __('identifierSchemeCodeUnspsc190501.51422303'),
	'identifierSchemeCodeUnspsc190501.51422304': __('identifierSchemeCodeUnspsc190501.51422304'),
	'identifierSchemeCodeUnspsc190501.51422305': __('identifierSchemeCodeUnspsc190501.51422305'),
	'identifierSchemeCodeUnspsc190501.51422306': __('identifierSchemeCodeUnspsc190501.51422306'),
	'identifierSchemeCodeUnspsc190501.51422307': __('identifierSchemeCodeUnspsc190501.51422307'),
	'identifierSchemeCodeUnspsc190501.51422308': __('identifierSchemeCodeUnspsc190501.51422308'),
	'identifierSchemeCodeUnspsc190501.51422309': __('identifierSchemeCodeUnspsc190501.51422309'),
	'identifierSchemeCodeUnspsc190501.51422310': __('identifierSchemeCodeUnspsc190501.51422310'),
	'identifierSchemeCodeUnspsc190501.51422311': __('identifierSchemeCodeUnspsc190501.51422311'),
	'identifierSchemeCodeUnspsc190501.51422312': __('identifierSchemeCodeUnspsc190501.51422312'),
	'identifierSchemeCodeUnspsc190501.51422313': __('identifierSchemeCodeUnspsc190501.51422313'),
	'identifierSchemeCodeUnspsc190501.51422314': __('identifierSchemeCodeUnspsc190501.51422314'),
	'identifierSchemeCodeUnspsc190501.51422315': __('identifierSchemeCodeUnspsc190501.51422315'),
	'identifierSchemeCodeUnspsc190501.51422316': __('identifierSchemeCodeUnspsc190501.51422316'),
	'identifierSchemeCodeUnspsc190501.51422317': __('identifierSchemeCodeUnspsc190501.51422317'),
	'identifierSchemeCodeUnspsc190501.51422318': __('identifierSchemeCodeUnspsc190501.51422318'),
	'identifierSchemeCodeUnspsc190501.51422319': __('identifierSchemeCodeUnspsc190501.51422319'),
	'identifierSchemeCodeUnspsc190501.51422320': __('identifierSchemeCodeUnspsc190501.51422320'),
	'identifierSchemeCodeUnspsc190501.51422321': __('identifierSchemeCodeUnspsc190501.51422321'),
	'identifierSchemeCodeUnspsc190501.51422322': __('identifierSchemeCodeUnspsc190501.51422322'),
	'identifierSchemeCodeUnspsc190501.51422323': __('identifierSchemeCodeUnspsc190501.51422323'),
	'identifierSchemeCodeUnspsc190501.51422324': __('identifierSchemeCodeUnspsc190501.51422324'),
	'identifierSchemeCodeUnspsc190501.51422325': __('identifierSchemeCodeUnspsc190501.51422325'),
	'identifierSchemeCodeUnspsc190501.51422326': __('identifierSchemeCodeUnspsc190501.51422326'),
	'identifierSchemeCodeUnspsc190501.51422327': __('identifierSchemeCodeUnspsc190501.51422327'),
	'identifierSchemeCodeUnspsc190501.51422328': __('identifierSchemeCodeUnspsc190501.51422328'),
	'identifierSchemeCodeUnspsc190501.51422329': __('identifierSchemeCodeUnspsc190501.51422329'),
	'identifierSchemeCodeUnspsc190501.51422330': __('identifierSchemeCodeUnspsc190501.51422330'),
	'identifierSchemeCodeUnspsc190501.51422331': __('identifierSchemeCodeUnspsc190501.51422331'),
	'identifierSchemeCodeUnspsc190501.51422332': __('identifierSchemeCodeUnspsc190501.51422332'),
	'identifierSchemeCodeUnspsc190501.51422333': __('identifierSchemeCodeUnspsc190501.51422333'),
	'identifierSchemeCodeUnspsc190501.51422334': __('identifierSchemeCodeUnspsc190501.51422334'),
	'identifierSchemeCodeUnspsc190501.51422335': __('identifierSchemeCodeUnspsc190501.51422335'),
	'identifierSchemeCodeUnspsc190501.51422336': __('identifierSchemeCodeUnspsc190501.51422336'),
	'identifierSchemeCodeUnspsc190501.51422337': __('identifierSchemeCodeUnspsc190501.51422337'),
	'identifierSchemeCodeUnspsc190501.51422338': __('identifierSchemeCodeUnspsc190501.51422338'),
	'identifierSchemeCodeUnspsc190501.51422339': __('identifierSchemeCodeUnspsc190501.51422339'),
	'identifierSchemeCodeUnspsc190501.51422340': __('identifierSchemeCodeUnspsc190501.51422340'),
	'identifierSchemeCodeUnspsc190501.51422341': __('identifierSchemeCodeUnspsc190501.51422341'),
	'identifierSchemeCodeUnspsc190501.51422342': __('identifierSchemeCodeUnspsc190501.51422342'),
	'identifierSchemeCodeUnspsc190501.51422343': __('identifierSchemeCodeUnspsc190501.51422343'),
	'identifierSchemeCodeUnspsc190501.51422344': __('identifierSchemeCodeUnspsc190501.51422344'),
	'identifierSchemeCodeUnspsc190501.51422345': __('identifierSchemeCodeUnspsc190501.51422345'),
	'identifierSchemeCodeUnspsc190501.51422346': __('identifierSchemeCodeUnspsc190501.51422346'),
	'identifierSchemeCodeUnspsc190501.51422347': __('identifierSchemeCodeUnspsc190501.51422347'),
	'identifierSchemeCodeUnspsc190501.51422348': __('identifierSchemeCodeUnspsc190501.51422348'),
	'identifierSchemeCodeUnspsc190501.51422349': __('identifierSchemeCodeUnspsc190501.51422349'),
	'identifierSchemeCodeUnspsc190501.51422350': __('identifierSchemeCodeUnspsc190501.51422350'),
	'identifierSchemeCodeUnspsc190501.51422351': __('identifierSchemeCodeUnspsc190501.51422351'),
	'identifierSchemeCodeUnspsc190501.51422352': __('identifierSchemeCodeUnspsc190501.51422352'),
	'identifierSchemeCodeUnspsc190501.51422353': __('identifierSchemeCodeUnspsc190501.51422353'),
	'identifierSchemeCodeUnspsc190501.51422354': __('identifierSchemeCodeUnspsc190501.51422354'),
	'identifierSchemeCodeUnspsc190501.51422355': __('identifierSchemeCodeUnspsc190501.51422355'),
	'identifierSchemeCodeUnspsc190501.51422356': __('identifierSchemeCodeUnspsc190501.51422356'),
	'identifierSchemeCodeUnspsc190501.51422357': __('identifierSchemeCodeUnspsc190501.51422357'),
	'identifierSchemeCodeUnspsc190501.51422358': __('identifierSchemeCodeUnspsc190501.51422358'),
	'identifierSchemeCodeUnspsc190501.51422359': __('identifierSchemeCodeUnspsc190501.51422359'),
	'identifierSchemeCodeUnspsc190501.51422360': __('identifierSchemeCodeUnspsc190501.51422360'),
	'identifierSchemeCodeUnspsc190501.51422361': __('identifierSchemeCodeUnspsc190501.51422361'),
	'identifierSchemeCodeUnspsc190501.51422362': __('identifierSchemeCodeUnspsc190501.51422362'),
	'identifierSchemeCodeUnspsc190501.51422363': __('identifierSchemeCodeUnspsc190501.51422363'),
	'identifierSchemeCodeUnspsc190501.51422364': __('identifierSchemeCodeUnspsc190501.51422364'),
	'identifierSchemeCodeUnspsc190501.51422365': __('identifierSchemeCodeUnspsc190501.51422365'),
	'identifierSchemeCodeUnspsc190501.51422366': __('identifierSchemeCodeUnspsc190501.51422366'),
	'identifierSchemeCodeUnspsc190501.51422367': __('identifierSchemeCodeUnspsc190501.51422367'),
	'identifierSchemeCodeUnspsc190501.51422368': __('identifierSchemeCodeUnspsc190501.51422368'),
	'identifierSchemeCodeUnspsc190501.51422369': __('identifierSchemeCodeUnspsc190501.51422369'),
	'identifierSchemeCodeUnspsc190501.51422370': __('identifierSchemeCodeUnspsc190501.51422370'),
	'identifierSchemeCodeUnspsc190501.51422400': __('identifierSchemeCodeUnspsc190501.51422400'),
	'identifierSchemeCodeUnspsc190501.51422401': __('identifierSchemeCodeUnspsc190501.51422401'),
	'identifierSchemeCodeUnspsc190501.51422402': __('identifierSchemeCodeUnspsc190501.51422402'),
	'identifierSchemeCodeUnspsc190501.51422403': __('identifierSchemeCodeUnspsc190501.51422403'),
	'identifierSchemeCodeUnspsc190501.51422404': __('identifierSchemeCodeUnspsc190501.51422404'),
	'identifierSchemeCodeUnspsc190501.51422405': __('identifierSchemeCodeUnspsc190501.51422405'),
	'identifierSchemeCodeUnspsc190501.51422406': __('identifierSchemeCodeUnspsc190501.51422406'),
	'identifierSchemeCodeUnspsc190501.51422407': __('identifierSchemeCodeUnspsc190501.51422407'),
	'identifierSchemeCodeUnspsc190501.51422408': __('identifierSchemeCodeUnspsc190501.51422408'),
	'identifierSchemeCodeUnspsc190501.51422409': __('identifierSchemeCodeUnspsc190501.51422409'),
	'identifierSchemeCodeUnspsc190501.51422410': __('identifierSchemeCodeUnspsc190501.51422410'),
	'identifierSchemeCodeUnspsc190501.51422411': __('identifierSchemeCodeUnspsc190501.51422411'),
	'identifierSchemeCodeUnspsc190501.51422412': __('identifierSchemeCodeUnspsc190501.51422412'),
	'identifierSchemeCodeUnspsc190501.51422413': __('identifierSchemeCodeUnspsc190501.51422413'),
	'identifierSchemeCodeUnspsc190501.51422414': __('identifierSchemeCodeUnspsc190501.51422414'),
	'identifierSchemeCodeUnspsc190501.51422415': __('identifierSchemeCodeUnspsc190501.51422415'),
	'identifierSchemeCodeUnspsc190501.51422416': __('identifierSchemeCodeUnspsc190501.51422416'),
	'identifierSchemeCodeUnspsc190501.51422417': __('identifierSchemeCodeUnspsc190501.51422417'),
	'identifierSchemeCodeUnspsc190501.51422418': __('identifierSchemeCodeUnspsc190501.51422418'),
	'identifierSchemeCodeUnspsc190501.51422419': __('identifierSchemeCodeUnspsc190501.51422419'),
	'identifierSchemeCodeUnspsc190501.51422420': __('identifierSchemeCodeUnspsc190501.51422420'),
	'identifierSchemeCodeUnspsc190501.51422421': __('identifierSchemeCodeUnspsc190501.51422421'),
	'identifierSchemeCodeUnspsc190501.51422422': __('identifierSchemeCodeUnspsc190501.51422422'),
	'identifierSchemeCodeUnspsc190501.51422423': __('identifierSchemeCodeUnspsc190501.51422423'),
	'identifierSchemeCodeUnspsc190501.51422424': __('identifierSchemeCodeUnspsc190501.51422424'),
	'identifierSchemeCodeUnspsc190501.51422425': __('identifierSchemeCodeUnspsc190501.51422425'),
	'identifierSchemeCodeUnspsc190501.51422426': __('identifierSchemeCodeUnspsc190501.51422426'),
	'identifierSchemeCodeUnspsc190501.51422427': __('identifierSchemeCodeUnspsc190501.51422427'),
	'identifierSchemeCodeUnspsc190501.51422428': __('identifierSchemeCodeUnspsc190501.51422428'),
	'identifierSchemeCodeUnspsc190501.51422429': __('identifierSchemeCodeUnspsc190501.51422429'),
	'identifierSchemeCodeUnspsc190501.51422430': __('identifierSchemeCodeUnspsc190501.51422430'),
	'identifierSchemeCodeUnspsc190501.51422431': __('identifierSchemeCodeUnspsc190501.51422431'),
	'identifierSchemeCodeUnspsc190501.51422432': __('identifierSchemeCodeUnspsc190501.51422432'),
	'identifierSchemeCodeUnspsc190501.51422433': __('identifierSchemeCodeUnspsc190501.51422433'),
	'identifierSchemeCodeUnspsc190501.51422434': __('identifierSchemeCodeUnspsc190501.51422434'),
	'identifierSchemeCodeUnspsc190501.51422435': __('identifierSchemeCodeUnspsc190501.51422435'),
	'identifierSchemeCodeUnspsc190501.51422436': __('identifierSchemeCodeUnspsc190501.51422436'),
	'identifierSchemeCodeUnspsc190501.51422437': __('identifierSchemeCodeUnspsc190501.51422437'),
	'identifierSchemeCodeUnspsc190501.51422500': __('identifierSchemeCodeUnspsc190501.51422500'),
	'identifierSchemeCodeUnspsc190501.51422501': __('identifierSchemeCodeUnspsc190501.51422501'),
	'identifierSchemeCodeUnspsc190501.51422502': __('identifierSchemeCodeUnspsc190501.51422502'),
	'identifierSchemeCodeUnspsc190501.51422503': __('identifierSchemeCodeUnspsc190501.51422503'),
	'identifierSchemeCodeUnspsc190501.51422504': __('identifierSchemeCodeUnspsc190501.51422504'),
	'identifierSchemeCodeUnspsc190501.51422505': __('identifierSchemeCodeUnspsc190501.51422505'),
	'identifierSchemeCodeUnspsc190501.51422506': __('identifierSchemeCodeUnspsc190501.51422506'),
	'identifierSchemeCodeUnspsc190501.51422507': __('identifierSchemeCodeUnspsc190501.51422507'),
	'identifierSchemeCodeUnspsc190501.51422508': __('identifierSchemeCodeUnspsc190501.51422508'),
	'identifierSchemeCodeUnspsc190501.51422509': __('identifierSchemeCodeUnspsc190501.51422509'),
	'identifierSchemeCodeUnspsc190501.51422510': __('identifierSchemeCodeUnspsc190501.51422510'),
	'identifierSchemeCodeUnspsc190501.51422511': __('identifierSchemeCodeUnspsc190501.51422511'),
	'identifierSchemeCodeUnspsc190501.51422512': __('identifierSchemeCodeUnspsc190501.51422512'),
	'identifierSchemeCodeUnspsc190501.51422600': __('identifierSchemeCodeUnspsc190501.51422600'),
	'identifierSchemeCodeUnspsc190501.51422601': __('identifierSchemeCodeUnspsc190501.51422601'),
	'identifierSchemeCodeUnspsc190501.51430000': __('identifierSchemeCodeUnspsc190501.51430000'),
	'identifierSchemeCodeUnspsc190501.51431500': __('identifierSchemeCodeUnspsc190501.51431500'),
	'identifierSchemeCodeUnspsc190501.51431501': __('identifierSchemeCodeUnspsc190501.51431501'),
	'identifierSchemeCodeUnspsc190501.51431502': __('identifierSchemeCodeUnspsc190501.51431502'),
	'identifierSchemeCodeUnspsc190501.51431503': __('identifierSchemeCodeUnspsc190501.51431503'),
	'identifierSchemeCodeUnspsc190501.51431504': __('identifierSchemeCodeUnspsc190501.51431504'),
	'identifierSchemeCodeUnspsc190501.51431505': __('identifierSchemeCodeUnspsc190501.51431505'),
	'identifierSchemeCodeUnspsc190501.51431506': __('identifierSchemeCodeUnspsc190501.51431506'),
	'identifierSchemeCodeUnspsc190501.51431507': __('identifierSchemeCodeUnspsc190501.51431507'),
	'identifierSchemeCodeUnspsc190501.51431508': __('identifierSchemeCodeUnspsc190501.51431508'),
	'identifierSchemeCodeUnspsc190501.51431509': __('identifierSchemeCodeUnspsc190501.51431509'),
	'identifierSchemeCodeUnspsc190501.51431510': __('identifierSchemeCodeUnspsc190501.51431510'),
	'identifierSchemeCodeUnspsc190501.51431600': __('identifierSchemeCodeUnspsc190501.51431600'),
	'identifierSchemeCodeUnspsc190501.51431601': __('identifierSchemeCodeUnspsc190501.51431601'),
	'identifierSchemeCodeUnspsc190501.51431602': __('identifierSchemeCodeUnspsc190501.51431602'),
	'identifierSchemeCodeUnspsc190501.51431603': __('identifierSchemeCodeUnspsc190501.51431603'),
	'identifierSchemeCodeUnspsc190501.51431604': __('identifierSchemeCodeUnspsc190501.51431604'),
	'identifierSchemeCodeUnspsc190501.51431605': __('identifierSchemeCodeUnspsc190501.51431605'),
	'identifierSchemeCodeUnspsc190501.51431606': __('identifierSchemeCodeUnspsc190501.51431606'),
	'identifierSchemeCodeUnspsc190501.51431607': __('identifierSchemeCodeUnspsc190501.51431607'),
	'identifierSchemeCodeUnspsc190501.51431608': __('identifierSchemeCodeUnspsc190501.51431608'),
	'identifierSchemeCodeUnspsc190501.51431609': __('identifierSchemeCodeUnspsc190501.51431609'),
	'identifierSchemeCodeUnspsc190501.51431610': __('identifierSchemeCodeUnspsc190501.51431610'),
	'identifierSchemeCodeUnspsc190501.51431611': __('identifierSchemeCodeUnspsc190501.51431611'),
	'identifierSchemeCodeUnspsc190501.51431612': __('identifierSchemeCodeUnspsc190501.51431612'),
	'identifierSchemeCodeUnspsc190501.51431613': __('identifierSchemeCodeUnspsc190501.51431613'),
	'identifierSchemeCodeUnspsc190501.51431614': __('identifierSchemeCodeUnspsc190501.51431614'),
	'identifierSchemeCodeUnspsc190501.51431700': __('identifierSchemeCodeUnspsc190501.51431700'),
	'identifierSchemeCodeUnspsc190501.51431701': __('identifierSchemeCodeUnspsc190501.51431701'),
	'identifierSchemeCodeUnspsc190501.51431702': __('identifierSchemeCodeUnspsc190501.51431702'),
	'identifierSchemeCodeUnspsc190501.51431703': __('identifierSchemeCodeUnspsc190501.51431703'),
	'identifierSchemeCodeUnspsc190501.51431704': __('identifierSchemeCodeUnspsc190501.51431704'),
	'identifierSchemeCodeUnspsc190501.51431705': __('identifierSchemeCodeUnspsc190501.51431705'),
	'identifierSchemeCodeUnspsc190501.51431706': __('identifierSchemeCodeUnspsc190501.51431706'),
	'identifierSchemeCodeUnspsc190501.51431707': __('identifierSchemeCodeUnspsc190501.51431707'),
	'identifierSchemeCodeUnspsc190501.51431708': __('identifierSchemeCodeUnspsc190501.51431708'),
	'identifierSchemeCodeUnspsc190501.51431709': __('identifierSchemeCodeUnspsc190501.51431709'),
	'identifierSchemeCodeUnspsc190501.51431800': __('identifierSchemeCodeUnspsc190501.51431800'),
	'identifierSchemeCodeUnspsc190501.51431801': __('identifierSchemeCodeUnspsc190501.51431801'),
	'identifierSchemeCodeUnspsc190501.51431802': __('identifierSchemeCodeUnspsc190501.51431802'),
	'identifierSchemeCodeUnspsc190501.51431803': __('identifierSchemeCodeUnspsc190501.51431803'),
	'identifierSchemeCodeUnspsc190501.51431804': __('identifierSchemeCodeUnspsc190501.51431804'),
	'identifierSchemeCodeUnspsc190501.51431805': __('identifierSchemeCodeUnspsc190501.51431805'),
	'identifierSchemeCodeUnspsc190501.51431806': __('identifierSchemeCodeUnspsc190501.51431806'),
	'identifierSchemeCodeUnspsc190501.51431807': __('identifierSchemeCodeUnspsc190501.51431807'),
	'identifierSchemeCodeUnspsc190501.51431900': __('identifierSchemeCodeUnspsc190501.51431900'),
	'identifierSchemeCodeUnspsc190501.51431901': __('identifierSchemeCodeUnspsc190501.51431901'),
	'identifierSchemeCodeUnspsc190501.51431902': __('identifierSchemeCodeUnspsc190501.51431902'),
	'identifierSchemeCodeUnspsc190501.51431903': __('identifierSchemeCodeUnspsc190501.51431903'),
	'identifierSchemeCodeUnspsc190501.51431904': __('identifierSchemeCodeUnspsc190501.51431904'),
	'identifierSchemeCodeUnspsc190501.51431905': __('identifierSchemeCodeUnspsc190501.51431905'),
	'identifierSchemeCodeUnspsc190501.51431906': __('identifierSchemeCodeUnspsc190501.51431906'),
	'identifierSchemeCodeUnspsc190501.51432000': __('identifierSchemeCodeUnspsc190501.51432000'),
	'identifierSchemeCodeUnspsc190501.51432001': __('identifierSchemeCodeUnspsc190501.51432001'),
	'identifierSchemeCodeUnspsc190501.51432002': __('identifierSchemeCodeUnspsc190501.51432002'),
	'identifierSchemeCodeUnspsc190501.51432003': __('identifierSchemeCodeUnspsc190501.51432003'),
	'identifierSchemeCodeUnspsc190501.51432004': __('identifierSchemeCodeUnspsc190501.51432004'),
	'identifierSchemeCodeUnspsc190501.51432005': __('identifierSchemeCodeUnspsc190501.51432005'),
	'identifierSchemeCodeUnspsc190501.51432006': __('identifierSchemeCodeUnspsc190501.51432006'),
	'identifierSchemeCodeUnspsc190501.51432007': __('identifierSchemeCodeUnspsc190501.51432007'),
	'identifierSchemeCodeUnspsc190501.51432100': __('identifierSchemeCodeUnspsc190501.51432100'),
	'identifierSchemeCodeUnspsc190501.51432101': __('identifierSchemeCodeUnspsc190501.51432101'),
	'identifierSchemeCodeUnspsc190501.51432102': __('identifierSchemeCodeUnspsc190501.51432102'),
	'identifierSchemeCodeUnspsc190501.51432103': __('identifierSchemeCodeUnspsc190501.51432103'),
	'identifierSchemeCodeUnspsc190501.51432104': __('identifierSchemeCodeUnspsc190501.51432104'),
	'identifierSchemeCodeUnspsc190501.51432105': __('identifierSchemeCodeUnspsc190501.51432105'),
	'identifierSchemeCodeUnspsc190501.51432106': __('identifierSchemeCodeUnspsc190501.51432106'),
	'identifierSchemeCodeUnspsc190501.51432107': __('identifierSchemeCodeUnspsc190501.51432107'),
	'identifierSchemeCodeUnspsc190501.51432108': __('identifierSchemeCodeUnspsc190501.51432108'),
	'identifierSchemeCodeUnspsc190501.51432109': __('identifierSchemeCodeUnspsc190501.51432109'),
	'identifierSchemeCodeUnspsc190501.51432110': __('identifierSchemeCodeUnspsc190501.51432110'),
	'identifierSchemeCodeUnspsc190501.51432111': __('identifierSchemeCodeUnspsc190501.51432111'),
	'identifierSchemeCodeUnspsc190501.51432112': __('identifierSchemeCodeUnspsc190501.51432112'),
	'identifierSchemeCodeUnspsc190501.51432113': __('identifierSchemeCodeUnspsc190501.51432113'),
	'identifierSchemeCodeUnspsc190501.51432200': __('identifierSchemeCodeUnspsc190501.51432200'),
	'identifierSchemeCodeUnspsc190501.51432201': __('identifierSchemeCodeUnspsc190501.51432201'),
	'identifierSchemeCodeUnspsc190501.51432202': __('identifierSchemeCodeUnspsc190501.51432202'),
	'identifierSchemeCodeUnspsc190501.51432203': __('identifierSchemeCodeUnspsc190501.51432203'),
	'identifierSchemeCodeUnspsc190501.51432204': __('identifierSchemeCodeUnspsc190501.51432204'),
	'identifierSchemeCodeUnspsc190501.51432205': __('identifierSchemeCodeUnspsc190501.51432205'),
	'identifierSchemeCodeUnspsc190501.51432206': __('identifierSchemeCodeUnspsc190501.51432206'),
	'identifierSchemeCodeUnspsc190501.51432207': __('identifierSchemeCodeUnspsc190501.51432207'),
	'identifierSchemeCodeUnspsc190501.51432300': __('identifierSchemeCodeUnspsc190501.51432300'),
	'identifierSchemeCodeUnspsc190501.51432301': __('identifierSchemeCodeUnspsc190501.51432301'),
	'identifierSchemeCodeUnspsc190501.51432302': __('identifierSchemeCodeUnspsc190501.51432302'),
	'identifierSchemeCodeUnspsc190501.51432303': __('identifierSchemeCodeUnspsc190501.51432303'),
	'identifierSchemeCodeUnspsc190501.51432304': __('identifierSchemeCodeUnspsc190501.51432304'),
	'identifierSchemeCodeUnspsc190501.51432305': __('identifierSchemeCodeUnspsc190501.51432305'),
	'identifierSchemeCodeUnspsc190501.51432306': __('identifierSchemeCodeUnspsc190501.51432306'),
	'identifierSchemeCodeUnspsc190501.51432307': __('identifierSchemeCodeUnspsc190501.51432307'),
	'identifierSchemeCodeUnspsc190501.51432308': __('identifierSchemeCodeUnspsc190501.51432308'),
	'identifierSchemeCodeUnspsc190501.51432309': __('identifierSchemeCodeUnspsc190501.51432309'),
	'identifierSchemeCodeUnspsc190501.51432310': __('identifierSchemeCodeUnspsc190501.51432310'),
	'identifierSchemeCodeUnspsc190501.51432311': __('identifierSchemeCodeUnspsc190501.51432311'),
	'identifierSchemeCodeUnspsc190501.51432312': __('identifierSchemeCodeUnspsc190501.51432312'),
	'identifierSchemeCodeUnspsc190501.51432313': __('identifierSchemeCodeUnspsc190501.51432313'),
	'identifierSchemeCodeUnspsc190501.51432314': __('identifierSchemeCodeUnspsc190501.51432314'),
	'identifierSchemeCodeUnspsc190501.51432315': __('identifierSchemeCodeUnspsc190501.51432315'),
	'identifierSchemeCodeUnspsc190501.51432400': __('identifierSchemeCodeUnspsc190501.51432400'),
	'identifierSchemeCodeUnspsc190501.51432401': __('identifierSchemeCodeUnspsc190501.51432401'),
	'identifierSchemeCodeUnspsc190501.51432402': __('identifierSchemeCodeUnspsc190501.51432402'),
	'identifierSchemeCodeUnspsc190501.51432403': __('identifierSchemeCodeUnspsc190501.51432403'),
	'identifierSchemeCodeUnspsc190501.51432404': __('identifierSchemeCodeUnspsc190501.51432404'),
	'identifierSchemeCodeUnspsc190501.51432405': __('identifierSchemeCodeUnspsc190501.51432405'),
	'identifierSchemeCodeUnspsc190501.51432406': __('identifierSchemeCodeUnspsc190501.51432406'),
	'identifierSchemeCodeUnspsc190501.51432407': __('identifierSchemeCodeUnspsc190501.51432407'),
	'identifierSchemeCodeUnspsc190501.51432408': __('identifierSchemeCodeUnspsc190501.51432408'),
	'identifierSchemeCodeUnspsc190501.51432500': __('identifierSchemeCodeUnspsc190501.51432500'),
	'identifierSchemeCodeUnspsc190501.51432501': __('identifierSchemeCodeUnspsc190501.51432501'),
	'identifierSchemeCodeUnspsc190501.51432502': __('identifierSchemeCodeUnspsc190501.51432502'),
	'identifierSchemeCodeUnspsc190501.51432503': __('identifierSchemeCodeUnspsc190501.51432503'),
	'identifierSchemeCodeUnspsc190501.51432504': __('identifierSchemeCodeUnspsc190501.51432504'),
	'identifierSchemeCodeUnspsc190501.51432505': __('identifierSchemeCodeUnspsc190501.51432505'),
	'identifierSchemeCodeUnspsc190501.51432506': __('identifierSchemeCodeUnspsc190501.51432506'),
	'identifierSchemeCodeUnspsc190501.51432507': __('identifierSchemeCodeUnspsc190501.51432507'),
	'identifierSchemeCodeUnspsc190501.51432508': __('identifierSchemeCodeUnspsc190501.51432508'),
	'identifierSchemeCodeUnspsc190501.51432509': __('identifierSchemeCodeUnspsc190501.51432509'),
	'identifierSchemeCodeUnspsc190501.51432510': __('identifierSchemeCodeUnspsc190501.51432510'),
	'identifierSchemeCodeUnspsc190501.51432511': __('identifierSchemeCodeUnspsc190501.51432511'),
	'identifierSchemeCodeUnspsc190501.51432600': __('identifierSchemeCodeUnspsc190501.51432600'),
	'identifierSchemeCodeUnspsc190501.51432601': __('identifierSchemeCodeUnspsc190501.51432601'),
	'identifierSchemeCodeUnspsc190501.51432602': __('identifierSchemeCodeUnspsc190501.51432602'),
	'identifierSchemeCodeUnspsc190501.51432603': __('identifierSchemeCodeUnspsc190501.51432603'),
	'identifierSchemeCodeUnspsc190501.51432604': __('identifierSchemeCodeUnspsc190501.51432604'),
	'identifierSchemeCodeUnspsc190501.51432605': __('identifierSchemeCodeUnspsc190501.51432605'),
	'identifierSchemeCodeUnspsc190501.51432606': __('identifierSchemeCodeUnspsc190501.51432606'),
	'identifierSchemeCodeUnspsc190501.51432607': __('identifierSchemeCodeUnspsc190501.51432607'),
	'identifierSchemeCodeUnspsc190501.51432608': __('identifierSchemeCodeUnspsc190501.51432608'),
	'identifierSchemeCodeUnspsc190501.51432609': __('identifierSchemeCodeUnspsc190501.51432609'),
	'identifierSchemeCodeUnspsc190501.51432700': __('identifierSchemeCodeUnspsc190501.51432700'),
	'identifierSchemeCodeUnspsc190501.51432701': __('identifierSchemeCodeUnspsc190501.51432701'),
	'identifierSchemeCodeUnspsc190501.51432702': __('identifierSchemeCodeUnspsc190501.51432702'),
	'identifierSchemeCodeUnspsc190501.51432703': __('identifierSchemeCodeUnspsc190501.51432703'),
	'identifierSchemeCodeUnspsc190501.51432704': __('identifierSchemeCodeUnspsc190501.51432704'),
	'identifierSchemeCodeUnspsc190501.51432705': __('identifierSchemeCodeUnspsc190501.51432705'),
	'identifierSchemeCodeUnspsc190501.51432706': __('identifierSchemeCodeUnspsc190501.51432706'),
	'identifierSchemeCodeUnspsc190501.51432800': __('identifierSchemeCodeUnspsc190501.51432800'),
	'identifierSchemeCodeUnspsc190501.51432801': __('identifierSchemeCodeUnspsc190501.51432801'),
	'identifierSchemeCodeUnspsc190501.51432802': __('identifierSchemeCodeUnspsc190501.51432802'),
	'identifierSchemeCodeUnspsc190501.51432803': __('identifierSchemeCodeUnspsc190501.51432803'),
	'identifierSchemeCodeUnspsc190501.51432804': __('identifierSchemeCodeUnspsc190501.51432804'),
	'identifierSchemeCodeUnspsc190501.51432805': __('identifierSchemeCodeUnspsc190501.51432805'),
	'identifierSchemeCodeUnspsc190501.51432806': __('identifierSchemeCodeUnspsc190501.51432806'),
	'identifierSchemeCodeUnspsc190501.51432807': __('identifierSchemeCodeUnspsc190501.51432807'),
	'identifierSchemeCodeUnspsc190501.51432808': __('identifierSchemeCodeUnspsc190501.51432808'),
	'identifierSchemeCodeUnspsc190501.51432809': __('identifierSchemeCodeUnspsc190501.51432809'),
	'identifierSchemeCodeUnspsc190501.51432810': __('identifierSchemeCodeUnspsc190501.51432810'),
	'identifierSchemeCodeUnspsc190501.51432811': __('identifierSchemeCodeUnspsc190501.51432811'),
	'identifierSchemeCodeUnspsc190501.51432900': __('identifierSchemeCodeUnspsc190501.51432900'),
	'identifierSchemeCodeUnspsc190501.51432901': __('identifierSchemeCodeUnspsc190501.51432901'),
	'identifierSchemeCodeUnspsc190501.51432902': __('identifierSchemeCodeUnspsc190501.51432902'),
	'identifierSchemeCodeUnspsc190501.51432903': __('identifierSchemeCodeUnspsc190501.51432903'),
	'identifierSchemeCodeUnspsc190501.51432904': __('identifierSchemeCodeUnspsc190501.51432904'),
	'identifierSchemeCodeUnspsc190501.51433000': __('identifierSchemeCodeUnspsc190501.51433000'),
	'identifierSchemeCodeUnspsc190501.51433001': __('identifierSchemeCodeUnspsc190501.51433001'),
	'identifierSchemeCodeUnspsc190501.51433002': __('identifierSchemeCodeUnspsc190501.51433002'),
	'identifierSchemeCodeUnspsc190501.51433003': __('identifierSchemeCodeUnspsc190501.51433003'),
	'identifierSchemeCodeUnspsc190501.51433004': __('identifierSchemeCodeUnspsc190501.51433004'),
	'identifierSchemeCodeUnspsc190501.51433100': __('identifierSchemeCodeUnspsc190501.51433100'),
	'identifierSchemeCodeUnspsc190501.51433101': __('identifierSchemeCodeUnspsc190501.51433101'),
	'identifierSchemeCodeUnspsc190501.51433102': __('identifierSchemeCodeUnspsc190501.51433102'),
	'identifierSchemeCodeUnspsc190501.51433103': __('identifierSchemeCodeUnspsc190501.51433103'),
	'identifierSchemeCodeUnspsc190501.51433104': __('identifierSchemeCodeUnspsc190501.51433104'),
	'identifierSchemeCodeUnspsc190501.51433200': __('identifierSchemeCodeUnspsc190501.51433200'),
	'identifierSchemeCodeUnspsc190501.51433201': __('identifierSchemeCodeUnspsc190501.51433201'),
	'identifierSchemeCodeUnspsc190501.51433202': __('identifierSchemeCodeUnspsc190501.51433202'),
	'identifierSchemeCodeUnspsc190501.51433300': __('identifierSchemeCodeUnspsc190501.51433300'),
	'identifierSchemeCodeUnspsc190501.51433301': __('identifierSchemeCodeUnspsc190501.51433301'),
	'identifierSchemeCodeUnspsc190501.51433302': __('identifierSchemeCodeUnspsc190501.51433302'),
	'identifierSchemeCodeUnspsc190501.51433303': __('identifierSchemeCodeUnspsc190501.51433303'),
	'identifierSchemeCodeUnspsc190501.51433304': __('identifierSchemeCodeUnspsc190501.51433304'),
	'identifierSchemeCodeUnspsc190501.51433305': __('identifierSchemeCodeUnspsc190501.51433305'),
	'identifierSchemeCodeUnspsc190501.51433400': __('identifierSchemeCodeUnspsc190501.51433400'),
	'identifierSchemeCodeUnspsc190501.51433401': __('identifierSchemeCodeUnspsc190501.51433401'),
	'identifierSchemeCodeUnspsc190501.51433402': __('identifierSchemeCodeUnspsc190501.51433402'),
	'identifierSchemeCodeUnspsc190501.51433403': __('identifierSchemeCodeUnspsc190501.51433403'),
	'identifierSchemeCodeUnspsc190501.51433404': __('identifierSchemeCodeUnspsc190501.51433404'),
	'identifierSchemeCodeUnspsc190501.51433405': __('identifierSchemeCodeUnspsc190501.51433405'),
	'identifierSchemeCodeUnspsc190501.51433406': __('identifierSchemeCodeUnspsc190501.51433406'),
	'identifierSchemeCodeUnspsc190501.51433407': __('identifierSchemeCodeUnspsc190501.51433407'),
	'identifierSchemeCodeUnspsc190501.51433408': __('identifierSchemeCodeUnspsc190501.51433408'),
	'identifierSchemeCodeUnspsc190501.51433500': __('identifierSchemeCodeUnspsc190501.51433500'),
	'identifierSchemeCodeUnspsc190501.51433501': __('identifierSchemeCodeUnspsc190501.51433501'),
	'identifierSchemeCodeUnspsc190501.51433502': __('identifierSchemeCodeUnspsc190501.51433502'),
	'identifierSchemeCodeUnspsc190501.51433503': __('identifierSchemeCodeUnspsc190501.51433503'),
	'identifierSchemeCodeUnspsc190501.51433504': __('identifierSchemeCodeUnspsc190501.51433504'),
	'identifierSchemeCodeUnspsc190501.51433505': __('identifierSchemeCodeUnspsc190501.51433505'),
	'identifierSchemeCodeUnspsc190501.51433506': __('identifierSchemeCodeUnspsc190501.51433506'),
	'identifierSchemeCodeUnspsc190501.51433507': __('identifierSchemeCodeUnspsc190501.51433507'),
	'identifierSchemeCodeUnspsc190501.51433508': __('identifierSchemeCodeUnspsc190501.51433508'),
	'identifierSchemeCodeUnspsc190501.51433509': __('identifierSchemeCodeUnspsc190501.51433509'),
	'identifierSchemeCodeUnspsc190501.51433600': __('identifierSchemeCodeUnspsc190501.51433600'),
	'identifierSchemeCodeUnspsc190501.51433601': __('identifierSchemeCodeUnspsc190501.51433601'),
	'identifierSchemeCodeUnspsc190501.51433602': __('identifierSchemeCodeUnspsc190501.51433602'),
	'identifierSchemeCodeUnspsc190501.51433603': __('identifierSchemeCodeUnspsc190501.51433603'),
	'identifierSchemeCodeUnspsc190501.51433604': __('identifierSchemeCodeUnspsc190501.51433604'),
	'identifierSchemeCodeUnspsc190501.51433605': __('identifierSchemeCodeUnspsc190501.51433605'),
	'identifierSchemeCodeUnspsc190501.51433606': __('identifierSchemeCodeUnspsc190501.51433606'),
	'identifierSchemeCodeUnspsc190501.51433607': __('identifierSchemeCodeUnspsc190501.51433607'),
	'identifierSchemeCodeUnspsc190501.51433608': __('identifierSchemeCodeUnspsc190501.51433608'),
	'identifierSchemeCodeUnspsc190501.51433700': __('identifierSchemeCodeUnspsc190501.51433700'),
	'identifierSchemeCodeUnspsc190501.51433701': __('identifierSchemeCodeUnspsc190501.51433701'),
	'identifierSchemeCodeUnspsc190501.51433702': __('identifierSchemeCodeUnspsc190501.51433702'),
	'identifierSchemeCodeUnspsc190501.51433703': __('identifierSchemeCodeUnspsc190501.51433703'),
	'identifierSchemeCodeUnspsc190501.51433704': __('identifierSchemeCodeUnspsc190501.51433704'),
	'identifierSchemeCodeUnspsc190501.51433705': __('identifierSchemeCodeUnspsc190501.51433705'),
	'identifierSchemeCodeUnspsc190501.51433706': __('identifierSchemeCodeUnspsc190501.51433706'),
	'identifierSchemeCodeUnspsc190501.51433800': __('identifierSchemeCodeUnspsc190501.51433800'),
	'identifierSchemeCodeUnspsc190501.51433801': __('identifierSchemeCodeUnspsc190501.51433801'),
	'identifierSchemeCodeUnspsc190501.51433802': __('identifierSchemeCodeUnspsc190501.51433802'),
	'identifierSchemeCodeUnspsc190501.51433803': __('identifierSchemeCodeUnspsc190501.51433803'),
	'identifierSchemeCodeUnspsc190501.51433804': __('identifierSchemeCodeUnspsc190501.51433804'),
	'identifierSchemeCodeUnspsc190501.51433805': __('identifierSchemeCodeUnspsc190501.51433805'),
	'identifierSchemeCodeUnspsc190501.51433806': __('identifierSchemeCodeUnspsc190501.51433806'),
	'identifierSchemeCodeUnspsc190501.51433807': __('identifierSchemeCodeUnspsc190501.51433807'),
	'identifierSchemeCodeUnspsc190501.51433900': __('identifierSchemeCodeUnspsc190501.51433900'),
	'identifierSchemeCodeUnspsc190501.51433901': __('identifierSchemeCodeUnspsc190501.51433901'),
	'identifierSchemeCodeUnspsc190501.51433902': __('identifierSchemeCodeUnspsc190501.51433902'),
	'identifierSchemeCodeUnspsc190501.51433903': __('identifierSchemeCodeUnspsc190501.51433903'),
	'identifierSchemeCodeUnspsc190501.51433904': __('identifierSchemeCodeUnspsc190501.51433904'),
	'identifierSchemeCodeUnspsc190501.51433905': __('identifierSchemeCodeUnspsc190501.51433905'),
	'identifierSchemeCodeUnspsc190501.51433906': __('identifierSchemeCodeUnspsc190501.51433906'),
	'identifierSchemeCodeUnspsc190501.51433907': __('identifierSchemeCodeUnspsc190501.51433907'),
	'identifierSchemeCodeUnspsc190501.51433908': __('identifierSchemeCodeUnspsc190501.51433908'),
	'identifierSchemeCodeUnspsc190501.51433909': __('identifierSchemeCodeUnspsc190501.51433909'),
	'identifierSchemeCodeUnspsc190501.51433910': __('identifierSchemeCodeUnspsc190501.51433910'),
	'identifierSchemeCodeUnspsc190501.51433911': __('identifierSchemeCodeUnspsc190501.51433911'),
	'identifierSchemeCodeUnspsc190501.51433912': __('identifierSchemeCodeUnspsc190501.51433912'),
	'identifierSchemeCodeUnspsc190501.51433913': __('identifierSchemeCodeUnspsc190501.51433913'),
	'identifierSchemeCodeUnspsc190501.51433914': __('identifierSchemeCodeUnspsc190501.51433914'),
	'identifierSchemeCodeUnspsc190501.51433915': __('identifierSchemeCodeUnspsc190501.51433915'),
	'identifierSchemeCodeUnspsc190501.51433916': __('identifierSchemeCodeUnspsc190501.51433916'),
	'identifierSchemeCodeUnspsc190501.51433917': __('identifierSchemeCodeUnspsc190501.51433917'),
	'identifierSchemeCodeUnspsc190501.51433918': __('identifierSchemeCodeUnspsc190501.51433918'),
	'identifierSchemeCodeUnspsc190501.51433919': __('identifierSchemeCodeUnspsc190501.51433919'),
	'identifierSchemeCodeUnspsc190501.51433920': __('identifierSchemeCodeUnspsc190501.51433920'),
	'identifierSchemeCodeUnspsc190501.51433921': __('identifierSchemeCodeUnspsc190501.51433921'),
	'identifierSchemeCodeUnspsc190501.51433922': __('identifierSchemeCodeUnspsc190501.51433922'),
	'identifierSchemeCodeUnspsc190501.51433923': __('identifierSchemeCodeUnspsc190501.51433923'),
	'identifierSchemeCodeUnspsc190501.51433924': __('identifierSchemeCodeUnspsc190501.51433924'),
	'identifierSchemeCodeUnspsc190501.51433925': __('identifierSchemeCodeUnspsc190501.51433925'),
	'identifierSchemeCodeUnspsc190501.51433927': __('identifierSchemeCodeUnspsc190501.51433927'),
	'identifierSchemeCodeUnspsc190501.51433928': __('identifierSchemeCodeUnspsc190501.51433928'),
	'identifierSchemeCodeUnspsc190501.51433929': __('identifierSchemeCodeUnspsc190501.51433929'),
	'identifierSchemeCodeUnspsc190501.51433930': __('identifierSchemeCodeUnspsc190501.51433930'),
	'identifierSchemeCodeUnspsc190501.51433931': __('identifierSchemeCodeUnspsc190501.51433931'),
	'identifierSchemeCodeUnspsc190501.51433932': __('identifierSchemeCodeUnspsc190501.51433932'),
	'identifierSchemeCodeUnspsc190501.51433933': __('identifierSchemeCodeUnspsc190501.51433933'),
	'identifierSchemeCodeUnspsc190501.51433934': __('identifierSchemeCodeUnspsc190501.51433934'),
	'identifierSchemeCodeUnspsc190501.51433935': __('identifierSchemeCodeUnspsc190501.51433935'),
	'identifierSchemeCodeUnspsc190501.51433936': __('identifierSchemeCodeUnspsc190501.51433936'),
	'identifierSchemeCodeUnspsc190501.51433937': __('identifierSchemeCodeUnspsc190501.51433937'),
	'identifierSchemeCodeUnspsc190501.51433938': __('identifierSchemeCodeUnspsc190501.51433938'),
	'identifierSchemeCodeUnspsc190501.51433939': __('identifierSchemeCodeUnspsc190501.51433939'),
	'identifierSchemeCodeUnspsc190501.51434000': __('identifierSchemeCodeUnspsc190501.51434000'),
	'identifierSchemeCodeUnspsc190501.51434001': __('identifierSchemeCodeUnspsc190501.51434001'),
	'identifierSchemeCodeUnspsc190501.51434002': __('identifierSchemeCodeUnspsc190501.51434002'),
	'identifierSchemeCodeUnspsc190501.51434003': __('identifierSchemeCodeUnspsc190501.51434003'),
	'identifierSchemeCodeUnspsc190501.51434004': __('identifierSchemeCodeUnspsc190501.51434004'),
	'identifierSchemeCodeUnspsc190501.51434005': __('identifierSchemeCodeUnspsc190501.51434005'),
	'identifierSchemeCodeUnspsc190501.51434006': __('identifierSchemeCodeUnspsc190501.51434006'),
	'identifierSchemeCodeUnspsc190501.51434007': __('identifierSchemeCodeUnspsc190501.51434007'),
	'identifierSchemeCodeUnspsc190501.51434008': __('identifierSchemeCodeUnspsc190501.51434008'),
	'identifierSchemeCodeUnspsc190501.51434100': __('identifierSchemeCodeUnspsc190501.51434100'),
	'identifierSchemeCodeUnspsc190501.51434101': __('identifierSchemeCodeUnspsc190501.51434101'),
	'identifierSchemeCodeUnspsc190501.51434102': __('identifierSchemeCodeUnspsc190501.51434102'),
	'identifierSchemeCodeUnspsc190501.51434103': __('identifierSchemeCodeUnspsc190501.51434103'),
	'identifierSchemeCodeUnspsc190501.51434104': __('identifierSchemeCodeUnspsc190501.51434104'),
	'identifierSchemeCodeUnspsc190501.51434105': __('identifierSchemeCodeUnspsc190501.51434105'),
	'identifierSchemeCodeUnspsc190501.51434106': __('identifierSchemeCodeUnspsc190501.51434106'),
	'identifierSchemeCodeUnspsc190501.51434107': __('identifierSchemeCodeUnspsc190501.51434107'),
	'identifierSchemeCodeUnspsc190501.51434108': __('identifierSchemeCodeUnspsc190501.51434108'),
	'identifierSchemeCodeUnspsc190501.51434200': __('identifierSchemeCodeUnspsc190501.51434200'),
	'identifierSchemeCodeUnspsc190501.51434201': __('identifierSchemeCodeUnspsc190501.51434201'),
	'identifierSchemeCodeUnspsc190501.51434202': __('identifierSchemeCodeUnspsc190501.51434202'),
	'identifierSchemeCodeUnspsc190501.51434203': __('identifierSchemeCodeUnspsc190501.51434203'),
	'identifierSchemeCodeUnspsc190501.51434204': __('identifierSchemeCodeUnspsc190501.51434204'),
	'identifierSchemeCodeUnspsc190501.51434205': __('identifierSchemeCodeUnspsc190501.51434205'),
	'identifierSchemeCodeUnspsc190501.51434206': __('identifierSchemeCodeUnspsc190501.51434206'),
	'identifierSchemeCodeUnspsc190501.51434207': __('identifierSchemeCodeUnspsc190501.51434207'),
	'identifierSchemeCodeUnspsc190501.51434300': __('identifierSchemeCodeUnspsc190501.51434300'),
	'identifierSchemeCodeUnspsc190501.51434301': __('identifierSchemeCodeUnspsc190501.51434301'),
	'identifierSchemeCodeUnspsc190501.51434302': __('identifierSchemeCodeUnspsc190501.51434302'),
	'identifierSchemeCodeUnspsc190501.51434303': __('identifierSchemeCodeUnspsc190501.51434303'),
	'identifierSchemeCodeUnspsc190501.51434304': __('identifierSchemeCodeUnspsc190501.51434304'),
	'identifierSchemeCodeUnspsc190501.51434305': __('identifierSchemeCodeUnspsc190501.51434305'),
	'identifierSchemeCodeUnspsc190501.51434400': __('identifierSchemeCodeUnspsc190501.51434400'),
	'identifierSchemeCodeUnspsc190501.51434401': __('identifierSchemeCodeUnspsc190501.51434401'),
	'identifierSchemeCodeUnspsc190501.51434402': __('identifierSchemeCodeUnspsc190501.51434402'),
	'identifierSchemeCodeUnspsc190501.51434403': __('identifierSchemeCodeUnspsc190501.51434403'),
	'identifierSchemeCodeUnspsc190501.51434500': __('identifierSchemeCodeUnspsc190501.51434500'),
	'identifierSchemeCodeUnspsc190501.51434502': __('identifierSchemeCodeUnspsc190501.51434502'),
	'identifierSchemeCodeUnspsc190501.51434600': __('identifierSchemeCodeUnspsc190501.51434600'),
	'identifierSchemeCodeUnspsc190501.51434601': __('identifierSchemeCodeUnspsc190501.51434601'),
	'identifierSchemeCodeUnspsc190501.51434602': __('identifierSchemeCodeUnspsc190501.51434602'),
	'identifierSchemeCodeUnspsc190501.51434603': __('identifierSchemeCodeUnspsc190501.51434603'),
	'identifierSchemeCodeUnspsc190501.51434604': __('identifierSchemeCodeUnspsc190501.51434604'),
	'identifierSchemeCodeUnspsc190501.51434605': __('identifierSchemeCodeUnspsc190501.51434605'),
	'identifierSchemeCodeUnspsc190501.51434700': __('identifierSchemeCodeUnspsc190501.51434700'),
	'identifierSchemeCodeUnspsc190501.51434701': __('identifierSchemeCodeUnspsc190501.51434701'),
	'identifierSchemeCodeUnspsc190501.51434702': __('identifierSchemeCodeUnspsc190501.51434702'),
	'identifierSchemeCodeUnspsc190501.51434703': __('identifierSchemeCodeUnspsc190501.51434703'),
	'identifierSchemeCodeUnspsc190501.51434800': __('identifierSchemeCodeUnspsc190501.51434800'),
	'identifierSchemeCodeUnspsc190501.51434801': __('identifierSchemeCodeUnspsc190501.51434801'),
	'identifierSchemeCodeUnspsc190501.51434802': __('identifierSchemeCodeUnspsc190501.51434802'),
	'identifierSchemeCodeUnspsc190501.51434803': __('identifierSchemeCodeUnspsc190501.51434803'),
	'identifierSchemeCodeUnspsc190501.51434804': __('identifierSchemeCodeUnspsc190501.51434804'),
	'identifierSchemeCodeUnspsc190501.51434805': __('identifierSchemeCodeUnspsc190501.51434805'),
	'identifierSchemeCodeUnspsc190501.51434806': __('identifierSchemeCodeUnspsc190501.51434806'),
	'identifierSchemeCodeUnspsc190501.51434807': __('identifierSchemeCodeUnspsc190501.51434807'),
	'identifierSchemeCodeUnspsc190501.51434808': __('identifierSchemeCodeUnspsc190501.51434808'),
	'identifierSchemeCodeUnspsc190501.51434809': __('identifierSchemeCodeUnspsc190501.51434809'),
	'identifierSchemeCodeUnspsc190501.51434810': __('identifierSchemeCodeUnspsc190501.51434810'),
	'identifierSchemeCodeUnspsc190501.51434811': __('identifierSchemeCodeUnspsc190501.51434811'),
	'identifierSchemeCodeUnspsc190501.51434812': __('identifierSchemeCodeUnspsc190501.51434812'),
	'identifierSchemeCodeUnspsc190501.51434813': __('identifierSchemeCodeUnspsc190501.51434813'),
	'identifierSchemeCodeUnspsc190501.51434814': __('identifierSchemeCodeUnspsc190501.51434814'),
	'identifierSchemeCodeUnspsc190501.51434815': __('identifierSchemeCodeUnspsc190501.51434815'),
	'identifierSchemeCodeUnspsc190501.51434816': __('identifierSchemeCodeUnspsc190501.51434816'),
	'identifierSchemeCodeUnspsc190501.51434817': __('identifierSchemeCodeUnspsc190501.51434817'),
	'identifierSchemeCodeUnspsc190501.51434818': __('identifierSchemeCodeUnspsc190501.51434818'),
	'identifierSchemeCodeUnspsc190501.51434819': __('identifierSchemeCodeUnspsc190501.51434819'),
	'identifierSchemeCodeUnspsc190501.51434820': __('identifierSchemeCodeUnspsc190501.51434820'),
	'identifierSchemeCodeUnspsc190501.51434821': __('identifierSchemeCodeUnspsc190501.51434821'),
	'identifierSchemeCodeUnspsc190501.51434900': __('identifierSchemeCodeUnspsc190501.51434900'),
	'identifierSchemeCodeUnspsc190501.51434901': __('identifierSchemeCodeUnspsc190501.51434901'),
	'identifierSchemeCodeUnspsc190501.51434902': __('identifierSchemeCodeUnspsc190501.51434902'),
	'identifierSchemeCodeUnspsc190501.51434903': __('identifierSchemeCodeUnspsc190501.51434903'),
	'identifierSchemeCodeUnspsc190501.51434904': __('identifierSchemeCodeUnspsc190501.51434904'),
	'identifierSchemeCodeUnspsc190501.51434905': __('identifierSchemeCodeUnspsc190501.51434905'),
	'identifierSchemeCodeUnspsc190501.51434906': __('identifierSchemeCodeUnspsc190501.51434906'),
	'identifierSchemeCodeUnspsc190501.51434907': __('identifierSchemeCodeUnspsc190501.51434907'),
	'identifierSchemeCodeUnspsc190501.51434908': __('identifierSchemeCodeUnspsc190501.51434908'),
	'identifierSchemeCodeUnspsc190501.51434909': __('identifierSchemeCodeUnspsc190501.51434909'),
	'identifierSchemeCodeUnspsc190501.51434910': __('identifierSchemeCodeUnspsc190501.51434910'),
	'identifierSchemeCodeUnspsc190501.51434911': __('identifierSchemeCodeUnspsc190501.51434911'),
	'identifierSchemeCodeUnspsc190501.51434912': __('identifierSchemeCodeUnspsc190501.51434912'),
	'identifierSchemeCodeUnspsc190501.51434913': __('identifierSchemeCodeUnspsc190501.51434913'),
	'identifierSchemeCodeUnspsc190501.51434914': __('identifierSchemeCodeUnspsc190501.51434914'),
	'identifierSchemeCodeUnspsc190501.51434915': __('identifierSchemeCodeUnspsc190501.51434915'),
	'identifierSchemeCodeUnspsc190501.51434916': __('identifierSchemeCodeUnspsc190501.51434916'),
	'identifierSchemeCodeUnspsc190501.51434917': __('identifierSchemeCodeUnspsc190501.51434917'),
	'identifierSchemeCodeUnspsc190501.51434918': __('identifierSchemeCodeUnspsc190501.51434918'),
	'identifierSchemeCodeUnspsc190501.51434919': __('identifierSchemeCodeUnspsc190501.51434919'),
	'identifierSchemeCodeUnspsc190501.51434920': __('identifierSchemeCodeUnspsc190501.51434920'),
	'identifierSchemeCodeUnspsc190501.51434921': __('identifierSchemeCodeUnspsc190501.51434921'),
	'identifierSchemeCodeUnspsc190501.51434922': __('identifierSchemeCodeUnspsc190501.51434922'),
	'identifierSchemeCodeUnspsc190501.51434923': __('identifierSchemeCodeUnspsc190501.51434923'),
	'identifierSchemeCodeUnspsc190501.51434924': __('identifierSchemeCodeUnspsc190501.51434924'),
	'identifierSchemeCodeUnspsc190501.51434925': __('identifierSchemeCodeUnspsc190501.51434925'),
	'identifierSchemeCodeUnspsc190501.51434926': __('identifierSchemeCodeUnspsc190501.51434926'),
	'identifierSchemeCodeUnspsc190501.51434927': __('identifierSchemeCodeUnspsc190501.51434927'),
	'identifierSchemeCodeUnspsc190501.51434928': __('identifierSchemeCodeUnspsc190501.51434928'),
	'identifierSchemeCodeUnspsc190501.51434929': __('identifierSchemeCodeUnspsc190501.51434929'),
	'identifierSchemeCodeUnspsc190501.51434930': __('identifierSchemeCodeUnspsc190501.51434930'),
	'identifierSchemeCodeUnspsc190501.51434931': __('identifierSchemeCodeUnspsc190501.51434931'),
	'identifierSchemeCodeUnspsc190501.51434932': __('identifierSchemeCodeUnspsc190501.51434932'),
	'identifierSchemeCodeUnspsc190501.51434933': __('identifierSchemeCodeUnspsc190501.51434933'),
	'identifierSchemeCodeUnspsc190501.51434934': __('identifierSchemeCodeUnspsc190501.51434934'),
	'identifierSchemeCodeUnspsc190501.51434935': __('identifierSchemeCodeUnspsc190501.51434935'),
	'identifierSchemeCodeUnspsc190501.51434936': __('identifierSchemeCodeUnspsc190501.51434936'),
	'identifierSchemeCodeUnspsc190501.51434937': __('identifierSchemeCodeUnspsc190501.51434937'),
	'identifierSchemeCodeUnspsc190501.51434938': __('identifierSchemeCodeUnspsc190501.51434938'),
	'identifierSchemeCodeUnspsc190501.51434939': __('identifierSchemeCodeUnspsc190501.51434939'),
	'identifierSchemeCodeUnspsc190501.51434940': __('identifierSchemeCodeUnspsc190501.51434940'),
	'identifierSchemeCodeUnspsc190501.51434941': __('identifierSchemeCodeUnspsc190501.51434941'),
	'identifierSchemeCodeUnspsc190501.51434942': __('identifierSchemeCodeUnspsc190501.51434942'),
	'identifierSchemeCodeUnspsc190501.51434943': __('identifierSchemeCodeUnspsc190501.51434943'),
	'identifierSchemeCodeUnspsc190501.51434944': __('identifierSchemeCodeUnspsc190501.51434944'),
	'identifierSchemeCodeUnspsc190501.51434945': __('identifierSchemeCodeUnspsc190501.51434945'),
	'identifierSchemeCodeUnspsc190501.51434946': __('identifierSchemeCodeUnspsc190501.51434946'),
	'identifierSchemeCodeUnspsc190501.51434947': __('identifierSchemeCodeUnspsc190501.51434947'),
	'identifierSchemeCodeUnspsc190501.51434948': __('identifierSchemeCodeUnspsc190501.51434948'),
	'identifierSchemeCodeUnspsc190501.51434949': __('identifierSchemeCodeUnspsc190501.51434949'),
	'identifierSchemeCodeUnspsc190501.51434950': __('identifierSchemeCodeUnspsc190501.51434950'),
	'identifierSchemeCodeUnspsc190501.51434951': __('identifierSchemeCodeUnspsc190501.51434951'),
	'identifierSchemeCodeUnspsc190501.51434952': __('identifierSchemeCodeUnspsc190501.51434952'),
	'identifierSchemeCodeUnspsc190501.51434953': __('identifierSchemeCodeUnspsc190501.51434953'),
	'identifierSchemeCodeUnspsc190501.51434954': __('identifierSchemeCodeUnspsc190501.51434954'),
	'identifierSchemeCodeUnspsc190501.51434955': __('identifierSchemeCodeUnspsc190501.51434955'),
	'identifierSchemeCodeUnspsc190501.51434956': __('identifierSchemeCodeUnspsc190501.51434956'),
	'identifierSchemeCodeUnspsc190501.51434957': __('identifierSchemeCodeUnspsc190501.51434957'),
	'identifierSchemeCodeUnspsc190501.51435000': __('identifierSchemeCodeUnspsc190501.51435000'),
	'identifierSchemeCodeUnspsc190501.51435001': __('identifierSchemeCodeUnspsc190501.51435001'),
	'identifierSchemeCodeUnspsc190501.51435002': __('identifierSchemeCodeUnspsc190501.51435002'),
	'identifierSchemeCodeUnspsc190501.51440000': __('identifierSchemeCodeUnspsc190501.51440000'),
	'identifierSchemeCodeUnspsc190501.51441500': __('identifierSchemeCodeUnspsc190501.51441500'),
	'identifierSchemeCodeUnspsc190501.51441520': __('identifierSchemeCodeUnspsc190501.51441520'),
	'identifierSchemeCodeUnspsc190501.51441523': __('identifierSchemeCodeUnspsc190501.51441523'),
	'identifierSchemeCodeUnspsc190501.51441549': __('identifierSchemeCodeUnspsc190501.51441549'),
	'identifierSchemeCodeUnspsc190501.51441562': __('identifierSchemeCodeUnspsc190501.51441562'),
	'identifierSchemeCodeUnspsc190501.51441577': __('identifierSchemeCodeUnspsc190501.51441577'),
	'identifierSchemeCodeUnspsc190501.51441579': __('identifierSchemeCodeUnspsc190501.51441579'),
	'identifierSchemeCodeUnspsc190501.51441600': __('identifierSchemeCodeUnspsc190501.51441600'),
	'identifierSchemeCodeUnspsc190501.51441601': __('identifierSchemeCodeUnspsc190501.51441601'),
	'identifierSchemeCodeUnspsc190501.51441603': __('identifierSchemeCodeUnspsc190501.51441603'),
	'identifierSchemeCodeUnspsc190501.51441605': __('identifierSchemeCodeUnspsc190501.51441605'),
	'identifierSchemeCodeUnspsc190501.51441606': __('identifierSchemeCodeUnspsc190501.51441606'),
	'identifierSchemeCodeUnspsc190501.51441608': __('identifierSchemeCodeUnspsc190501.51441608'),
	'identifierSchemeCodeUnspsc190501.51441611': __('identifierSchemeCodeUnspsc190501.51441611'),
	'identifierSchemeCodeUnspsc190501.51441612': __('identifierSchemeCodeUnspsc190501.51441612'),
	'identifierSchemeCodeUnspsc190501.51441614': __('identifierSchemeCodeUnspsc190501.51441614'),
	'identifierSchemeCodeUnspsc190501.51441617': __('identifierSchemeCodeUnspsc190501.51441617'),
	'identifierSchemeCodeUnspsc190501.51441618': __('identifierSchemeCodeUnspsc190501.51441618'),
	'identifierSchemeCodeUnspsc190501.51441700': __('identifierSchemeCodeUnspsc190501.51441700'),
	'identifierSchemeCodeUnspsc190501.51441701': __('identifierSchemeCodeUnspsc190501.51441701'),
	'identifierSchemeCodeUnspsc190501.51441702': __('identifierSchemeCodeUnspsc190501.51441702'),
	'identifierSchemeCodeUnspsc190501.51441703': __('identifierSchemeCodeUnspsc190501.51441703'),
	'identifierSchemeCodeUnspsc190501.51441704': __('identifierSchemeCodeUnspsc190501.51441704'),
	'identifierSchemeCodeUnspsc190501.51441705': __('identifierSchemeCodeUnspsc190501.51441705'),
	'identifierSchemeCodeUnspsc190501.51441706': __('identifierSchemeCodeUnspsc190501.51441706'),
	'identifierSchemeCodeUnspsc190501.51441707': __('identifierSchemeCodeUnspsc190501.51441707'),
	'identifierSchemeCodeUnspsc190501.51441708': __('identifierSchemeCodeUnspsc190501.51441708'),
	'identifierSchemeCodeUnspsc190501.51441709': __('identifierSchemeCodeUnspsc190501.51441709'),
	'identifierSchemeCodeUnspsc190501.51441710': __('identifierSchemeCodeUnspsc190501.51441710'),
	'identifierSchemeCodeUnspsc190501.51441711': __('identifierSchemeCodeUnspsc190501.51441711'),
	'identifierSchemeCodeUnspsc190501.51441712': __('identifierSchemeCodeUnspsc190501.51441712'),
	'identifierSchemeCodeUnspsc190501.51441713': __('identifierSchemeCodeUnspsc190501.51441713'),
	'identifierSchemeCodeUnspsc190501.51441714': __('identifierSchemeCodeUnspsc190501.51441714'),
	'identifierSchemeCodeUnspsc190501.51441715': __('identifierSchemeCodeUnspsc190501.51441715'),
	'identifierSchemeCodeUnspsc190501.51441800': __('identifierSchemeCodeUnspsc190501.51441800'),
	'identifierSchemeCodeUnspsc190501.51441801': __('identifierSchemeCodeUnspsc190501.51441801'),
	'identifierSchemeCodeUnspsc190501.51441802': __('identifierSchemeCodeUnspsc190501.51441802'),
	'identifierSchemeCodeUnspsc190501.51441900': __('identifierSchemeCodeUnspsc190501.51441900'),
	'identifierSchemeCodeUnspsc190501.51441901': __('identifierSchemeCodeUnspsc190501.51441901'),
	'identifierSchemeCodeUnspsc190501.51441902': __('identifierSchemeCodeUnspsc190501.51441902'),
	'identifierSchemeCodeUnspsc190501.51441903': __('identifierSchemeCodeUnspsc190501.51441903'),
	'identifierSchemeCodeUnspsc190501.51441904': __('identifierSchemeCodeUnspsc190501.51441904'),
	'identifierSchemeCodeUnspsc190501.51441905': __('identifierSchemeCodeUnspsc190501.51441905'),
	'identifierSchemeCodeUnspsc190501.51441906': __('identifierSchemeCodeUnspsc190501.51441906'),
	'identifierSchemeCodeUnspsc190501.51441907': __('identifierSchemeCodeUnspsc190501.51441907'),
	'identifierSchemeCodeUnspsc190501.51442000': __('identifierSchemeCodeUnspsc190501.51442000'),
	'identifierSchemeCodeUnspsc190501.51442001': __('identifierSchemeCodeUnspsc190501.51442001'),
	'identifierSchemeCodeUnspsc190501.51442002': __('identifierSchemeCodeUnspsc190501.51442002'),
	'identifierSchemeCodeUnspsc190501.51442003': __('identifierSchemeCodeUnspsc190501.51442003'),
	'identifierSchemeCodeUnspsc190501.51442004': __('identifierSchemeCodeUnspsc190501.51442004'),
	'identifierSchemeCodeUnspsc190501.51442005': __('identifierSchemeCodeUnspsc190501.51442005'),
	'identifierSchemeCodeUnspsc190501.51442006': __('identifierSchemeCodeUnspsc190501.51442006'),
	'identifierSchemeCodeUnspsc190501.51442007': __('identifierSchemeCodeUnspsc190501.51442007'),
	'identifierSchemeCodeUnspsc190501.51442100': __('identifierSchemeCodeUnspsc190501.51442100'),
	'identifierSchemeCodeUnspsc190501.51442101': __('identifierSchemeCodeUnspsc190501.51442101'),
	'identifierSchemeCodeUnspsc190501.51442102': __('identifierSchemeCodeUnspsc190501.51442102'),
	'identifierSchemeCodeUnspsc190501.51442200': __('identifierSchemeCodeUnspsc190501.51442200'),
	'identifierSchemeCodeUnspsc190501.51442201': __('identifierSchemeCodeUnspsc190501.51442201'),
	'identifierSchemeCodeUnspsc190501.51442202': __('identifierSchemeCodeUnspsc190501.51442202'),
	'identifierSchemeCodeUnspsc190501.51442203': __('identifierSchemeCodeUnspsc190501.51442203'),
	'identifierSchemeCodeUnspsc190501.51442204': __('identifierSchemeCodeUnspsc190501.51442204'),
	'identifierSchemeCodeUnspsc190501.51442205': __('identifierSchemeCodeUnspsc190501.51442205'),
	'identifierSchemeCodeUnspsc190501.51442206': __('identifierSchemeCodeUnspsc190501.51442206'),
	'identifierSchemeCodeUnspsc190501.51442207': __('identifierSchemeCodeUnspsc190501.51442207'),
	'identifierSchemeCodeUnspsc190501.51442208': __('identifierSchemeCodeUnspsc190501.51442208'),
	'identifierSchemeCodeUnspsc190501.51442209': __('identifierSchemeCodeUnspsc190501.51442209'),
	'identifierSchemeCodeUnspsc190501.51442210': __('identifierSchemeCodeUnspsc190501.51442210'),
	'identifierSchemeCodeUnspsc190501.51442211': __('identifierSchemeCodeUnspsc190501.51442211'),
	'identifierSchemeCodeUnspsc190501.51442212': __('identifierSchemeCodeUnspsc190501.51442212'),
	'identifierSchemeCodeUnspsc190501.51442300': __('identifierSchemeCodeUnspsc190501.51442300'),
	'identifierSchemeCodeUnspsc190501.51442301': __('identifierSchemeCodeUnspsc190501.51442301'),
	'identifierSchemeCodeUnspsc190501.51442302': __('identifierSchemeCodeUnspsc190501.51442302'),
	'identifierSchemeCodeUnspsc190501.51442303': __('identifierSchemeCodeUnspsc190501.51442303'),
	'identifierSchemeCodeUnspsc190501.51442304': __('identifierSchemeCodeUnspsc190501.51442304'),
	'identifierSchemeCodeUnspsc190501.51442305': __('identifierSchemeCodeUnspsc190501.51442305'),
	'identifierSchemeCodeUnspsc190501.51442306': __('identifierSchemeCodeUnspsc190501.51442306'),
	'identifierSchemeCodeUnspsc190501.51442400': __('identifierSchemeCodeUnspsc190501.51442400'),
	'identifierSchemeCodeUnspsc190501.51442402': __('identifierSchemeCodeUnspsc190501.51442402'),
	'identifierSchemeCodeUnspsc190501.51442403': __('identifierSchemeCodeUnspsc190501.51442403'),
	'identifierSchemeCodeUnspsc190501.51442404': __('identifierSchemeCodeUnspsc190501.51442404'),
	'identifierSchemeCodeUnspsc190501.51442405': __('identifierSchemeCodeUnspsc190501.51442405'),
	'identifierSchemeCodeUnspsc190501.51442406': __('identifierSchemeCodeUnspsc190501.51442406'),
	'identifierSchemeCodeUnspsc190501.51442407': __('identifierSchemeCodeUnspsc190501.51442407'),
	'identifierSchemeCodeUnspsc190501.51442408': __('identifierSchemeCodeUnspsc190501.51442408'),
	'identifierSchemeCodeUnspsc190501.51442409': __('identifierSchemeCodeUnspsc190501.51442409'),
	'identifierSchemeCodeUnspsc190501.51442410': __('identifierSchemeCodeUnspsc190501.51442410'),
	'identifierSchemeCodeUnspsc190501.51442411': __('identifierSchemeCodeUnspsc190501.51442411'),
	'identifierSchemeCodeUnspsc190501.51442412': __('identifierSchemeCodeUnspsc190501.51442412'),
	'identifierSchemeCodeUnspsc190501.51442413': __('identifierSchemeCodeUnspsc190501.51442413'),
	'identifierSchemeCodeUnspsc190501.51442414': __('identifierSchemeCodeUnspsc190501.51442414'),
	'identifierSchemeCodeUnspsc190501.51442415': __('identifierSchemeCodeUnspsc190501.51442415'),
	'identifierSchemeCodeUnspsc190501.51442416': __('identifierSchemeCodeUnspsc190501.51442416'),
	'identifierSchemeCodeUnspsc190501.51442417': __('identifierSchemeCodeUnspsc190501.51442417'),
	'identifierSchemeCodeUnspsc190501.51442418': __('identifierSchemeCodeUnspsc190501.51442418'),
	'identifierSchemeCodeUnspsc190501.51442419': __('identifierSchemeCodeUnspsc190501.51442419'),
	'identifierSchemeCodeUnspsc190501.51442420': __('identifierSchemeCodeUnspsc190501.51442420'),
	'identifierSchemeCodeUnspsc190501.51442421': __('identifierSchemeCodeUnspsc190501.51442421'),
	'identifierSchemeCodeUnspsc190501.51442422': __('identifierSchemeCodeUnspsc190501.51442422'),
	'identifierSchemeCodeUnspsc190501.51442423': __('identifierSchemeCodeUnspsc190501.51442423'),
	'identifierSchemeCodeUnspsc190501.51442424': __('identifierSchemeCodeUnspsc190501.51442424'),
	'identifierSchemeCodeUnspsc190501.51442425': __('identifierSchemeCodeUnspsc190501.51442425'),
	'identifierSchemeCodeUnspsc190501.51442426': __('identifierSchemeCodeUnspsc190501.51442426'),
	'identifierSchemeCodeUnspsc190501.51442427': __('identifierSchemeCodeUnspsc190501.51442427'),
	'identifierSchemeCodeUnspsc190501.51442428': __('identifierSchemeCodeUnspsc190501.51442428'),
	'identifierSchemeCodeUnspsc190501.51442429': __('identifierSchemeCodeUnspsc190501.51442429'),
	'identifierSchemeCodeUnspsc190501.51442430': __('identifierSchemeCodeUnspsc190501.51442430'),
	'identifierSchemeCodeUnspsc190501.51442431': __('identifierSchemeCodeUnspsc190501.51442431'),
	'identifierSchemeCodeUnspsc190501.51442432': __('identifierSchemeCodeUnspsc190501.51442432'),
	'identifierSchemeCodeUnspsc190501.51442433': __('identifierSchemeCodeUnspsc190501.51442433'),
	'identifierSchemeCodeUnspsc190501.51442434': __('identifierSchemeCodeUnspsc190501.51442434'),
	'identifierSchemeCodeUnspsc190501.51442435': __('identifierSchemeCodeUnspsc190501.51442435'),
	'identifierSchemeCodeUnspsc190501.51442436': __('identifierSchemeCodeUnspsc190501.51442436'),
	'identifierSchemeCodeUnspsc190501.51442437': __('identifierSchemeCodeUnspsc190501.51442437'),
	'identifierSchemeCodeUnspsc190501.51442438': __('identifierSchemeCodeUnspsc190501.51442438'),
	'identifierSchemeCodeUnspsc190501.51442439': __('identifierSchemeCodeUnspsc190501.51442439'),
	'identifierSchemeCodeUnspsc190501.51442440': __('identifierSchemeCodeUnspsc190501.51442440'),
	'identifierSchemeCodeUnspsc190501.51442441': __('identifierSchemeCodeUnspsc190501.51442441'),
	'identifierSchemeCodeUnspsc190501.51442442': __('identifierSchemeCodeUnspsc190501.51442442'),
	'identifierSchemeCodeUnspsc190501.51442500': __('identifierSchemeCodeUnspsc190501.51442500'),
	'identifierSchemeCodeUnspsc190501.51442501': __('identifierSchemeCodeUnspsc190501.51442501'),
	'identifierSchemeCodeUnspsc190501.51442600': __('identifierSchemeCodeUnspsc190501.51442600'),
	'identifierSchemeCodeUnspsc190501.51442601': __('identifierSchemeCodeUnspsc190501.51442601'),
	'identifierSchemeCodeUnspsc190501.51442602': __('identifierSchemeCodeUnspsc190501.51442602'),
	'identifierSchemeCodeUnspsc190501.51442603': __('identifierSchemeCodeUnspsc190501.51442603'),
	'identifierSchemeCodeUnspsc190501.51442604': __('identifierSchemeCodeUnspsc190501.51442604'),
	'identifierSchemeCodeUnspsc190501.51442700': __('identifierSchemeCodeUnspsc190501.51442700'),
	'identifierSchemeCodeUnspsc190501.51442701': __('identifierSchemeCodeUnspsc190501.51442701'),
	'identifierSchemeCodeUnspsc190501.51442702': __('identifierSchemeCodeUnspsc190501.51442702'),
	'identifierSchemeCodeUnspsc190501.51442703': __('identifierSchemeCodeUnspsc190501.51442703'),
	'identifierSchemeCodeUnspsc190501.51442704': __('identifierSchemeCodeUnspsc190501.51442704'),
	'identifierSchemeCodeUnspsc190501.51442705': __('identifierSchemeCodeUnspsc190501.51442705'),
	'identifierSchemeCodeUnspsc190501.51442706': __('identifierSchemeCodeUnspsc190501.51442706'),
	'identifierSchemeCodeUnspsc190501.51442707': __('identifierSchemeCodeUnspsc190501.51442707'),
	'identifierSchemeCodeUnspsc190501.51442708': __('identifierSchemeCodeUnspsc190501.51442708'),
	'identifierSchemeCodeUnspsc190501.51442709': __('identifierSchemeCodeUnspsc190501.51442709'),
	'identifierSchemeCodeUnspsc190501.51442710': __('identifierSchemeCodeUnspsc190501.51442710'),
	'identifierSchemeCodeUnspsc190501.51442711': __('identifierSchemeCodeUnspsc190501.51442711'),
	'identifierSchemeCodeUnspsc190501.51442712': __('identifierSchemeCodeUnspsc190501.51442712'),
	'identifierSchemeCodeUnspsc190501.51442713': __('identifierSchemeCodeUnspsc190501.51442713'),
	'identifierSchemeCodeUnspsc190501.51442714': __('identifierSchemeCodeUnspsc190501.51442714'),
	'identifierSchemeCodeUnspsc190501.51442715': __('identifierSchemeCodeUnspsc190501.51442715'),
	'identifierSchemeCodeUnspsc190501.51442716': __('identifierSchemeCodeUnspsc190501.51442716'),
	'identifierSchemeCodeUnspsc190501.51442717': __('identifierSchemeCodeUnspsc190501.51442717'),
	'identifierSchemeCodeUnspsc190501.51442718': __('identifierSchemeCodeUnspsc190501.51442718'),
	'identifierSchemeCodeUnspsc190501.51442719': __('identifierSchemeCodeUnspsc190501.51442719'),
	'identifierSchemeCodeUnspsc190501.51442720': __('identifierSchemeCodeUnspsc190501.51442720'),
	'identifierSchemeCodeUnspsc190501.51442721': __('identifierSchemeCodeUnspsc190501.51442721'),
	'identifierSchemeCodeUnspsc190501.51442722': __('identifierSchemeCodeUnspsc190501.51442722'),
	'identifierSchemeCodeUnspsc190501.51442723': __('identifierSchemeCodeUnspsc190501.51442723'),
	'identifierSchemeCodeUnspsc190501.51442724': __('identifierSchemeCodeUnspsc190501.51442724'),
	'identifierSchemeCodeUnspsc190501.51442725': __('identifierSchemeCodeUnspsc190501.51442725'),
	'identifierSchemeCodeUnspsc190501.51442726': __('identifierSchemeCodeUnspsc190501.51442726'),
	'identifierSchemeCodeUnspsc190501.51450000': __('identifierSchemeCodeUnspsc190501.51450000'),
	'identifierSchemeCodeUnspsc190501.51451500': __('identifierSchemeCodeUnspsc190501.51451500'),
	'identifierSchemeCodeUnspsc190501.51451501': __('identifierSchemeCodeUnspsc190501.51451501'),
	'identifierSchemeCodeUnspsc190501.51451502': __('identifierSchemeCodeUnspsc190501.51451502'),
	'identifierSchemeCodeUnspsc190501.51451600': __('identifierSchemeCodeUnspsc190501.51451600'),
	'identifierSchemeCodeUnspsc190501.51451601': __('identifierSchemeCodeUnspsc190501.51451601'),
	'identifierSchemeCodeUnspsc190501.51451602': __('identifierSchemeCodeUnspsc190501.51451602'),
	'identifierSchemeCodeUnspsc190501.51451603': __('identifierSchemeCodeUnspsc190501.51451603'),
	'identifierSchemeCodeUnspsc190501.51451604': __('identifierSchemeCodeUnspsc190501.51451604'),
	'identifierSchemeCodeUnspsc190501.51451605': __('identifierSchemeCodeUnspsc190501.51451605'),
	'identifierSchemeCodeUnspsc190501.51451606': __('identifierSchemeCodeUnspsc190501.51451606'),
	'identifierSchemeCodeUnspsc190501.51451608': __('identifierSchemeCodeUnspsc190501.51451608'),
	'identifierSchemeCodeUnspsc190501.51451609': __('identifierSchemeCodeUnspsc190501.51451609'),
	'identifierSchemeCodeUnspsc190501.51451610': __('identifierSchemeCodeUnspsc190501.51451610'),
	'identifierSchemeCodeUnspsc190501.51451611': __('identifierSchemeCodeUnspsc190501.51451611'),
	'identifierSchemeCodeUnspsc190501.51451612': __('identifierSchemeCodeUnspsc190501.51451612'),
	'identifierSchemeCodeUnspsc190501.51451613': __('identifierSchemeCodeUnspsc190501.51451613'),
	'identifierSchemeCodeUnspsc190501.51451700': __('identifierSchemeCodeUnspsc190501.51451700'),
	'identifierSchemeCodeUnspsc190501.51451701': __('identifierSchemeCodeUnspsc190501.51451701'),
	'identifierSchemeCodeUnspsc190501.51451702': __('identifierSchemeCodeUnspsc190501.51451702'),
	'identifierSchemeCodeUnspsc190501.51451703': __('identifierSchemeCodeUnspsc190501.51451703'),
	'identifierSchemeCodeUnspsc190501.51451704': __('identifierSchemeCodeUnspsc190501.51451704'),
	'identifierSchemeCodeUnspsc190501.51451705': __('identifierSchemeCodeUnspsc190501.51451705'),
	'identifierSchemeCodeUnspsc190501.51451706': __('identifierSchemeCodeUnspsc190501.51451706'),
	'identifierSchemeCodeUnspsc190501.51451800': __('identifierSchemeCodeUnspsc190501.51451800'),
	'identifierSchemeCodeUnspsc190501.51451801': __('identifierSchemeCodeUnspsc190501.51451801'),
	'identifierSchemeCodeUnspsc190501.51451802': __('identifierSchemeCodeUnspsc190501.51451802'),
	'identifierSchemeCodeUnspsc190501.51451803': __('identifierSchemeCodeUnspsc190501.51451803'),
	'identifierSchemeCodeUnspsc190501.51451804': __('identifierSchemeCodeUnspsc190501.51451804'),
	'identifierSchemeCodeUnspsc190501.51451805': __('identifierSchemeCodeUnspsc190501.51451805'),
	'identifierSchemeCodeUnspsc190501.51451806': __('identifierSchemeCodeUnspsc190501.51451806'),
	'identifierSchemeCodeUnspsc190501.51451807': __('identifierSchemeCodeUnspsc190501.51451807'),
	'identifierSchemeCodeUnspsc190501.51451900': __('identifierSchemeCodeUnspsc190501.51451900'),
	'identifierSchemeCodeUnspsc190501.51451901': __('identifierSchemeCodeUnspsc190501.51451901'),
	'identifierSchemeCodeUnspsc190501.51451902': __('identifierSchemeCodeUnspsc190501.51451902'),
	'identifierSchemeCodeUnspsc190501.51451903': __('identifierSchemeCodeUnspsc190501.51451903'),
	'identifierSchemeCodeUnspsc190501.51451904': __('identifierSchemeCodeUnspsc190501.51451904'),
	'identifierSchemeCodeUnspsc190501.51451905': __('identifierSchemeCodeUnspsc190501.51451905'),
	'identifierSchemeCodeUnspsc190501.51451906': __('identifierSchemeCodeUnspsc190501.51451906'),
	'identifierSchemeCodeUnspsc190501.51451907': __('identifierSchemeCodeUnspsc190501.51451907'),
	'identifierSchemeCodeUnspsc190501.51451908': __('identifierSchemeCodeUnspsc190501.51451908'),
	'identifierSchemeCodeUnspsc190501.51451909': __('identifierSchemeCodeUnspsc190501.51451909'),
	'identifierSchemeCodeUnspsc190501.51451910': __('identifierSchemeCodeUnspsc190501.51451910'),
	'identifierSchemeCodeUnspsc190501.51451911': __('identifierSchemeCodeUnspsc190501.51451911'),
	'identifierSchemeCodeUnspsc190501.51452000': __('identifierSchemeCodeUnspsc190501.51452000'),
	'identifierSchemeCodeUnspsc190501.51452001': __('identifierSchemeCodeUnspsc190501.51452001'),
	'identifierSchemeCodeUnspsc190501.51452002': __('identifierSchemeCodeUnspsc190501.51452002'),
	'identifierSchemeCodeUnspsc190501.51452003': __('identifierSchemeCodeUnspsc190501.51452003'),
	'identifierSchemeCodeUnspsc190501.51452004': __('identifierSchemeCodeUnspsc190501.51452004'),
	'identifierSchemeCodeUnspsc190501.51452005': __('identifierSchemeCodeUnspsc190501.51452005'),
	'identifierSchemeCodeUnspsc190501.51452006': __('identifierSchemeCodeUnspsc190501.51452006'),
	'identifierSchemeCodeUnspsc190501.51452007': __('identifierSchemeCodeUnspsc190501.51452007'),
	'identifierSchemeCodeUnspsc190501.51452100': __('identifierSchemeCodeUnspsc190501.51452100'),
	'identifierSchemeCodeUnspsc190501.51452101': __('identifierSchemeCodeUnspsc190501.51452101'),
	'identifierSchemeCodeUnspsc190501.51452102': __('identifierSchemeCodeUnspsc190501.51452102'),
	'identifierSchemeCodeUnspsc190501.51452200': __('identifierSchemeCodeUnspsc190501.51452200'),
	'identifierSchemeCodeUnspsc190501.51452201': __('identifierSchemeCodeUnspsc190501.51452201'),
	'identifierSchemeCodeUnspsc190501.51452202': __('identifierSchemeCodeUnspsc190501.51452202'),
	'identifierSchemeCodeUnspsc190501.51452203': __('identifierSchemeCodeUnspsc190501.51452203'),
	'identifierSchemeCodeUnspsc190501.51452300': __('identifierSchemeCodeUnspsc190501.51452300'),
	'identifierSchemeCodeUnspsc190501.51452301': __('identifierSchemeCodeUnspsc190501.51452301'),
	'identifierSchemeCodeUnspsc190501.51452302': __('identifierSchemeCodeUnspsc190501.51452302'),
	'identifierSchemeCodeUnspsc190501.51452303': __('identifierSchemeCodeUnspsc190501.51452303'),
	'identifierSchemeCodeUnspsc190501.51452304': __('identifierSchemeCodeUnspsc190501.51452304'),
	'identifierSchemeCodeUnspsc190501.51452305': __('identifierSchemeCodeUnspsc190501.51452305'),
	'identifierSchemeCodeUnspsc190501.51452306': __('identifierSchemeCodeUnspsc190501.51452306'),
	'identifierSchemeCodeUnspsc190501.51452307': __('identifierSchemeCodeUnspsc190501.51452307'),
	'identifierSchemeCodeUnspsc190501.51452308': __('identifierSchemeCodeUnspsc190501.51452308'),
	'identifierSchemeCodeUnspsc190501.51452400': __('identifierSchemeCodeUnspsc190501.51452400'),
	'identifierSchemeCodeUnspsc190501.51452401': __('identifierSchemeCodeUnspsc190501.51452401'),
	'identifierSchemeCodeUnspsc190501.51452402': __('identifierSchemeCodeUnspsc190501.51452402'),
	'identifierSchemeCodeUnspsc190501.51452403': __('identifierSchemeCodeUnspsc190501.51452403'),
	'identifierSchemeCodeUnspsc190501.51452404': __('identifierSchemeCodeUnspsc190501.51452404'),
	'identifierSchemeCodeUnspsc190501.51452405': __('identifierSchemeCodeUnspsc190501.51452405'),
	'identifierSchemeCodeUnspsc190501.51452500': __('identifierSchemeCodeUnspsc190501.51452500'),
	'identifierSchemeCodeUnspsc190501.51452501': __('identifierSchemeCodeUnspsc190501.51452501'),
	'identifierSchemeCodeUnspsc190501.51452502': __('identifierSchemeCodeUnspsc190501.51452502'),
	'identifierSchemeCodeUnspsc190501.51452503': __('identifierSchemeCodeUnspsc190501.51452503'),
	'identifierSchemeCodeUnspsc190501.51452504': __('identifierSchemeCodeUnspsc190501.51452504'),
	'identifierSchemeCodeUnspsc190501.51452600': __('identifierSchemeCodeUnspsc190501.51452600'),
	'identifierSchemeCodeUnspsc190501.51452601': __('identifierSchemeCodeUnspsc190501.51452601'),
	'identifierSchemeCodeUnspsc190501.51452602': __('identifierSchemeCodeUnspsc190501.51452602'),
	'identifierSchemeCodeUnspsc190501.51452603': __('identifierSchemeCodeUnspsc190501.51452603'),
	'identifierSchemeCodeUnspsc190501.51452700': __('identifierSchemeCodeUnspsc190501.51452700'),
	'identifierSchemeCodeUnspsc190501.51452701': __('identifierSchemeCodeUnspsc190501.51452701'),
	'identifierSchemeCodeUnspsc190501.51452702': __('identifierSchemeCodeUnspsc190501.51452702'),
	'identifierSchemeCodeUnspsc190501.51452800': __('identifierSchemeCodeUnspsc190501.51452800'),
	'identifierSchemeCodeUnspsc190501.51452801': __('identifierSchemeCodeUnspsc190501.51452801'),
	'identifierSchemeCodeUnspsc190501.51452802': __('identifierSchemeCodeUnspsc190501.51452802'),
	'identifierSchemeCodeUnspsc190501.51452803': __('identifierSchemeCodeUnspsc190501.51452803'),
	'identifierSchemeCodeUnspsc190501.51452804': __('identifierSchemeCodeUnspsc190501.51452804'),
	'identifierSchemeCodeUnspsc190501.51452900': __('identifierSchemeCodeUnspsc190501.51452900'),
	'identifierSchemeCodeUnspsc190501.51452901': __('identifierSchemeCodeUnspsc190501.51452901'),
	'identifierSchemeCodeUnspsc190501.51452902': __('identifierSchemeCodeUnspsc190501.51452902'),
	'identifierSchemeCodeUnspsc190501.51452903': __('identifierSchemeCodeUnspsc190501.51452903'),
	'identifierSchemeCodeUnspsc190501.51452904': __('identifierSchemeCodeUnspsc190501.51452904'),
	'identifierSchemeCodeUnspsc190501.51452905': __('identifierSchemeCodeUnspsc190501.51452905'),
	'identifierSchemeCodeUnspsc190501.51452906': __('identifierSchemeCodeUnspsc190501.51452906'),
	'identifierSchemeCodeUnspsc190501.51453000': __('identifierSchemeCodeUnspsc190501.51453000'),
	'identifierSchemeCodeUnspsc190501.51453001': __('identifierSchemeCodeUnspsc190501.51453001'),
	'identifierSchemeCodeUnspsc190501.51453002': __('identifierSchemeCodeUnspsc190501.51453002'),
	'identifierSchemeCodeUnspsc190501.51453003': __('identifierSchemeCodeUnspsc190501.51453003'),
	'identifierSchemeCodeUnspsc190501.51453004': __('identifierSchemeCodeUnspsc190501.51453004'),
	'identifierSchemeCodeUnspsc190501.51453005': __('identifierSchemeCodeUnspsc190501.51453005'),
	'identifierSchemeCodeUnspsc190501.51453006': __('identifierSchemeCodeUnspsc190501.51453006'),
	'identifierSchemeCodeUnspsc190501.51453007': __('identifierSchemeCodeUnspsc190501.51453007'),
	'identifierSchemeCodeUnspsc190501.51453008': __('identifierSchemeCodeUnspsc190501.51453008'),
	'identifierSchemeCodeUnspsc190501.51453009': __('identifierSchemeCodeUnspsc190501.51453009'),
	'identifierSchemeCodeUnspsc190501.51453010': __('identifierSchemeCodeUnspsc190501.51453010'),
	'identifierSchemeCodeUnspsc190501.51453011': __('identifierSchemeCodeUnspsc190501.51453011'),
	'identifierSchemeCodeUnspsc190501.51453012': __('identifierSchemeCodeUnspsc190501.51453012'),
	'identifierSchemeCodeUnspsc190501.51453013': __('identifierSchemeCodeUnspsc190501.51453013'),
	'identifierSchemeCodeUnspsc190501.51453014': __('identifierSchemeCodeUnspsc190501.51453014'),
	'identifierSchemeCodeUnspsc190501.51453015': __('identifierSchemeCodeUnspsc190501.51453015'),
	'identifierSchemeCodeUnspsc190501.51453016': __('identifierSchemeCodeUnspsc190501.51453016'),
	'identifierSchemeCodeUnspsc190501.51453017': __('identifierSchemeCodeUnspsc190501.51453017'),
	'identifierSchemeCodeUnspsc190501.51453018': __('identifierSchemeCodeUnspsc190501.51453018'),
	'identifierSchemeCodeUnspsc190501.51453019': __('identifierSchemeCodeUnspsc190501.51453019'),
	'identifierSchemeCodeUnspsc190501.51453020': __('identifierSchemeCodeUnspsc190501.51453020'),
	'identifierSchemeCodeUnspsc190501.51453100': __('identifierSchemeCodeUnspsc190501.51453100'),
	'identifierSchemeCodeUnspsc190501.51453101': __('identifierSchemeCodeUnspsc190501.51453101'),
	'identifierSchemeCodeUnspsc190501.51453102': __('identifierSchemeCodeUnspsc190501.51453102'),
	'identifierSchemeCodeUnspsc190501.51453103': __('identifierSchemeCodeUnspsc190501.51453103'),
	'identifierSchemeCodeUnspsc190501.51453104': __('identifierSchemeCodeUnspsc190501.51453104'),
	'identifierSchemeCodeUnspsc190501.51453105': __('identifierSchemeCodeUnspsc190501.51453105'),
	'identifierSchemeCodeUnspsc190501.51453106': __('identifierSchemeCodeUnspsc190501.51453106'),
	'identifierSchemeCodeUnspsc190501.51453107': __('identifierSchemeCodeUnspsc190501.51453107'),
	'identifierSchemeCodeUnspsc190501.51453200': __('identifierSchemeCodeUnspsc190501.51453200'),
	'identifierSchemeCodeUnspsc190501.51453201': __('identifierSchemeCodeUnspsc190501.51453201'),
	'identifierSchemeCodeUnspsc190501.51453202': __('identifierSchemeCodeUnspsc190501.51453202'),
	'identifierSchemeCodeUnspsc190501.51453203': __('identifierSchemeCodeUnspsc190501.51453203'),
	'identifierSchemeCodeUnspsc190501.51453204': __('identifierSchemeCodeUnspsc190501.51453204'),
	'identifierSchemeCodeUnspsc190501.51453205': __('identifierSchemeCodeUnspsc190501.51453205'),
	'identifierSchemeCodeUnspsc190501.51453206': __('identifierSchemeCodeUnspsc190501.51453206'),
	'identifierSchemeCodeUnspsc190501.51453300': __('identifierSchemeCodeUnspsc190501.51453300'),
	'identifierSchemeCodeUnspsc190501.51453301': __('identifierSchemeCodeUnspsc190501.51453301'),
	'identifierSchemeCodeUnspsc190501.51453302': __('identifierSchemeCodeUnspsc190501.51453302'),
	'identifierSchemeCodeUnspsc190501.51453303': __('identifierSchemeCodeUnspsc190501.51453303'),
	'identifierSchemeCodeUnspsc190501.51453400': __('identifierSchemeCodeUnspsc190501.51453400'),
	'identifierSchemeCodeUnspsc190501.51453401': __('identifierSchemeCodeUnspsc190501.51453401'),
	'identifierSchemeCodeUnspsc190501.51453402': __('identifierSchemeCodeUnspsc190501.51453402'),
	'identifierSchemeCodeUnspsc190501.51453403': __('identifierSchemeCodeUnspsc190501.51453403'),
	'identifierSchemeCodeUnspsc190501.51453404': __('identifierSchemeCodeUnspsc190501.51453404'),
	'identifierSchemeCodeUnspsc190501.51453405': __('identifierSchemeCodeUnspsc190501.51453405'),
	'identifierSchemeCodeUnspsc190501.51453406': __('identifierSchemeCodeUnspsc190501.51453406'),
	'identifierSchemeCodeUnspsc190501.51453500': __('identifierSchemeCodeUnspsc190501.51453500'),
	'identifierSchemeCodeUnspsc190501.51453501': __('identifierSchemeCodeUnspsc190501.51453501'),
	'identifierSchemeCodeUnspsc190501.51453502': __('identifierSchemeCodeUnspsc190501.51453502'),
	'identifierSchemeCodeUnspsc190501.51453600': __('identifierSchemeCodeUnspsc190501.51453600'),
	'identifierSchemeCodeUnspsc190501.51453601': __('identifierSchemeCodeUnspsc190501.51453601'),
	'identifierSchemeCodeUnspsc190501.51453602': __('identifierSchemeCodeUnspsc190501.51453602'),
	'identifierSchemeCodeUnspsc190501.51453700': __('identifierSchemeCodeUnspsc190501.51453700'),
	'identifierSchemeCodeUnspsc190501.51453701': __('identifierSchemeCodeUnspsc190501.51453701'),
	'identifierSchemeCodeUnspsc190501.51453702': __('identifierSchemeCodeUnspsc190501.51453702'),
	'identifierSchemeCodeUnspsc190501.51453703': __('identifierSchemeCodeUnspsc190501.51453703'),
	'identifierSchemeCodeUnspsc190501.51453704': __('identifierSchemeCodeUnspsc190501.51453704'),
	'identifierSchemeCodeUnspsc190501.51460000': __('identifierSchemeCodeUnspsc190501.51460000'),
	'identifierSchemeCodeUnspsc190501.51461500': __('identifierSchemeCodeUnspsc190501.51461500'),
	'identifierSchemeCodeUnspsc190501.51461501': __('identifierSchemeCodeUnspsc190501.51461501'),
	'identifierSchemeCodeUnspsc190501.51461502': __('identifierSchemeCodeUnspsc190501.51461502'),
	'identifierSchemeCodeUnspsc190501.51461503': __('identifierSchemeCodeUnspsc190501.51461503'),
	'identifierSchemeCodeUnspsc190501.51461504': __('identifierSchemeCodeUnspsc190501.51461504'),
	'identifierSchemeCodeUnspsc190501.51461505': __('identifierSchemeCodeUnspsc190501.51461505'),
	'identifierSchemeCodeUnspsc190501.51461506': __('identifierSchemeCodeUnspsc190501.51461506'),
	'identifierSchemeCodeUnspsc190501.51461507': __('identifierSchemeCodeUnspsc190501.51461507'),
	'identifierSchemeCodeUnspsc190501.51461508': __('identifierSchemeCodeUnspsc190501.51461508'),
	'identifierSchemeCodeUnspsc190501.51461509': __('identifierSchemeCodeUnspsc190501.51461509'),
	'identifierSchemeCodeUnspsc190501.51461510': __('identifierSchemeCodeUnspsc190501.51461510'),
	'identifierSchemeCodeUnspsc190501.51461511': __('identifierSchemeCodeUnspsc190501.51461511'),
	'identifierSchemeCodeUnspsc190501.51461512': __('identifierSchemeCodeUnspsc190501.51461512'),
	'identifierSchemeCodeUnspsc190501.51461513': __('identifierSchemeCodeUnspsc190501.51461513'),
	'identifierSchemeCodeUnspsc190501.51461514': __('identifierSchemeCodeUnspsc190501.51461514'),
	'identifierSchemeCodeUnspsc190501.51461515': __('identifierSchemeCodeUnspsc190501.51461515'),
	'identifierSchemeCodeUnspsc190501.51461516': __('identifierSchemeCodeUnspsc190501.51461516'),
	'identifierSchemeCodeUnspsc190501.51461517': __('identifierSchemeCodeUnspsc190501.51461517'),
	'identifierSchemeCodeUnspsc190501.51461518': __('identifierSchemeCodeUnspsc190501.51461518'),
	'identifierSchemeCodeUnspsc190501.51461519': __('identifierSchemeCodeUnspsc190501.51461519'),
	'identifierSchemeCodeUnspsc190501.51461520': __('identifierSchemeCodeUnspsc190501.51461520'),
	'identifierSchemeCodeUnspsc190501.51461521': __('identifierSchemeCodeUnspsc190501.51461521'),
	'identifierSchemeCodeUnspsc190501.51461522': __('identifierSchemeCodeUnspsc190501.51461522'),
	'identifierSchemeCodeUnspsc190501.51461523': __('identifierSchemeCodeUnspsc190501.51461523'),
	'identifierSchemeCodeUnspsc190501.51461524': __('identifierSchemeCodeUnspsc190501.51461524'),
	'identifierSchemeCodeUnspsc190501.51461525': __('identifierSchemeCodeUnspsc190501.51461525'),
	'identifierSchemeCodeUnspsc190501.51461526': __('identifierSchemeCodeUnspsc190501.51461526'),
	'identifierSchemeCodeUnspsc190501.51461527': __('identifierSchemeCodeUnspsc190501.51461527'),
	'identifierSchemeCodeUnspsc190501.51461528': __('identifierSchemeCodeUnspsc190501.51461528'),
	'identifierSchemeCodeUnspsc190501.51461529': __('identifierSchemeCodeUnspsc190501.51461529'),
	'identifierSchemeCodeUnspsc190501.51461530': __('identifierSchemeCodeUnspsc190501.51461530'),
	'identifierSchemeCodeUnspsc190501.51461531': __('identifierSchemeCodeUnspsc190501.51461531'),
	'identifierSchemeCodeUnspsc190501.51461532': __('identifierSchemeCodeUnspsc190501.51461532'),
	'identifierSchemeCodeUnspsc190501.51461533': __('identifierSchemeCodeUnspsc190501.51461533'),
	'identifierSchemeCodeUnspsc190501.51461534': __('identifierSchemeCodeUnspsc190501.51461534'),
	'identifierSchemeCodeUnspsc190501.51461535': __('identifierSchemeCodeUnspsc190501.51461535'),
	'identifierSchemeCodeUnspsc190501.51461536': __('identifierSchemeCodeUnspsc190501.51461536'),
	'identifierSchemeCodeUnspsc190501.51461537': __('identifierSchemeCodeUnspsc190501.51461537'),
	'identifierSchemeCodeUnspsc190501.51461538': __('identifierSchemeCodeUnspsc190501.51461538'),
	'identifierSchemeCodeUnspsc190501.51461539': __('identifierSchemeCodeUnspsc190501.51461539'),
	'identifierSchemeCodeUnspsc190501.51461540': __('identifierSchemeCodeUnspsc190501.51461540'),
	'identifierSchemeCodeUnspsc190501.51461541': __('identifierSchemeCodeUnspsc190501.51461541'),
	'identifierSchemeCodeUnspsc190501.51461542': __('identifierSchemeCodeUnspsc190501.51461542'),
	'identifierSchemeCodeUnspsc190501.51461543': __('identifierSchemeCodeUnspsc190501.51461543'),
	'identifierSchemeCodeUnspsc190501.51461600': __('identifierSchemeCodeUnspsc190501.51461600'),
	'identifierSchemeCodeUnspsc190501.51461601': __('identifierSchemeCodeUnspsc190501.51461601'),
	'identifierSchemeCodeUnspsc190501.51461602': __('identifierSchemeCodeUnspsc190501.51461602'),
	'identifierSchemeCodeUnspsc190501.51461603': __('identifierSchemeCodeUnspsc190501.51461603'),
	'identifierSchemeCodeUnspsc190501.51461604': __('identifierSchemeCodeUnspsc190501.51461604'),
	'identifierSchemeCodeUnspsc190501.51461605': __('identifierSchemeCodeUnspsc190501.51461605'),
	'identifierSchemeCodeUnspsc190501.51470000': __('identifierSchemeCodeUnspsc190501.51470000'),
	'identifierSchemeCodeUnspsc190501.51471500': __('identifierSchemeCodeUnspsc190501.51471500'),
	'identifierSchemeCodeUnspsc190501.51471501': __('identifierSchemeCodeUnspsc190501.51471501'),
	'identifierSchemeCodeUnspsc190501.51471502': __('identifierSchemeCodeUnspsc190501.51471502'),
	'identifierSchemeCodeUnspsc190501.51471503': __('identifierSchemeCodeUnspsc190501.51471503'),
	'identifierSchemeCodeUnspsc190501.51471504': __('identifierSchemeCodeUnspsc190501.51471504'),
	'identifierSchemeCodeUnspsc190501.51471505': __('identifierSchemeCodeUnspsc190501.51471505'),
	'identifierSchemeCodeUnspsc190501.51471600': __('identifierSchemeCodeUnspsc190501.51471600'),
	'identifierSchemeCodeUnspsc190501.51471601': __('identifierSchemeCodeUnspsc190501.51471601'),
	'identifierSchemeCodeUnspsc190501.51471602': __('identifierSchemeCodeUnspsc190501.51471602'),
	'identifierSchemeCodeUnspsc190501.51471700': __('identifierSchemeCodeUnspsc190501.51471700'),
	'identifierSchemeCodeUnspsc190501.51471701': __('identifierSchemeCodeUnspsc190501.51471701'),
	'identifierSchemeCodeUnspsc190501.51471702': __('identifierSchemeCodeUnspsc190501.51471702'),
	'identifierSchemeCodeUnspsc190501.51471703': __('identifierSchemeCodeUnspsc190501.51471703'),
	'identifierSchemeCodeUnspsc190501.51471704': __('identifierSchemeCodeUnspsc190501.51471704'),
	'identifierSchemeCodeUnspsc190501.51471800': __('identifierSchemeCodeUnspsc190501.51471800'),
	'identifierSchemeCodeUnspsc190501.51471801': __('identifierSchemeCodeUnspsc190501.51471801'),
	'identifierSchemeCodeUnspsc190501.51471802': __('identifierSchemeCodeUnspsc190501.51471802'),
	'identifierSchemeCodeUnspsc190501.51471803': __('identifierSchemeCodeUnspsc190501.51471803'),
	'identifierSchemeCodeUnspsc190501.51471804': __('identifierSchemeCodeUnspsc190501.51471804'),
	'identifierSchemeCodeUnspsc190501.51471805': __('identifierSchemeCodeUnspsc190501.51471805'),
	'identifierSchemeCodeUnspsc190501.51471806': __('identifierSchemeCodeUnspsc190501.51471806'),
	'identifierSchemeCodeUnspsc190501.51471807': __('identifierSchemeCodeUnspsc190501.51471807'),
	'identifierSchemeCodeUnspsc190501.51471808': __('identifierSchemeCodeUnspsc190501.51471808'),
	'identifierSchemeCodeUnspsc190501.51471809': __('identifierSchemeCodeUnspsc190501.51471809'),
	'identifierSchemeCodeUnspsc190501.51471811': __('identifierSchemeCodeUnspsc190501.51471811'),
	'identifierSchemeCodeUnspsc190501.51471900': __('identifierSchemeCodeUnspsc190501.51471900'),
	'identifierSchemeCodeUnspsc190501.51471901': __('identifierSchemeCodeUnspsc190501.51471901'),
	'identifierSchemeCodeUnspsc190501.51471902': __('identifierSchemeCodeUnspsc190501.51471902'),
	'identifierSchemeCodeUnspsc190501.51471903': __('identifierSchemeCodeUnspsc190501.51471903'),
	'identifierSchemeCodeUnspsc190501.51471904': __('identifierSchemeCodeUnspsc190501.51471904'),
	'identifierSchemeCodeUnspsc190501.51472000': __('identifierSchemeCodeUnspsc190501.51472000'),
	'identifierSchemeCodeUnspsc190501.51472001': __('identifierSchemeCodeUnspsc190501.51472001'),
	'identifierSchemeCodeUnspsc190501.51472002': __('identifierSchemeCodeUnspsc190501.51472002'),
	'identifierSchemeCodeUnspsc190501.51472100': __('identifierSchemeCodeUnspsc190501.51472100'),
	'identifierSchemeCodeUnspsc190501.51472101': __('identifierSchemeCodeUnspsc190501.51472101'),
	'identifierSchemeCodeUnspsc190501.51472102': __('identifierSchemeCodeUnspsc190501.51472102'),
	'identifierSchemeCodeUnspsc190501.51472103': __('identifierSchemeCodeUnspsc190501.51472103'),
	'identifierSchemeCodeUnspsc190501.51472104': __('identifierSchemeCodeUnspsc190501.51472104'),
	'identifierSchemeCodeUnspsc190501.51472105': __('identifierSchemeCodeUnspsc190501.51472105'),
	'identifierSchemeCodeUnspsc190501.51472300': __('identifierSchemeCodeUnspsc190501.51472300'),
	'identifierSchemeCodeUnspsc190501.51472301': __('identifierSchemeCodeUnspsc190501.51472301'),
	'identifierSchemeCodeUnspsc190501.51472302': __('identifierSchemeCodeUnspsc190501.51472302'),
	'identifierSchemeCodeUnspsc190501.51472303': __('identifierSchemeCodeUnspsc190501.51472303'),
	'identifierSchemeCodeUnspsc190501.51472304': __('identifierSchemeCodeUnspsc190501.51472304'),
	'identifierSchemeCodeUnspsc190501.51472400': __('identifierSchemeCodeUnspsc190501.51472400'),
	'identifierSchemeCodeUnspsc190501.51472401': __('identifierSchemeCodeUnspsc190501.51472401'),
	'identifierSchemeCodeUnspsc190501.51472402': __('identifierSchemeCodeUnspsc190501.51472402'),
	'identifierSchemeCodeUnspsc190501.51472500': __('identifierSchemeCodeUnspsc190501.51472500'),
	'identifierSchemeCodeUnspsc190501.51472502': __('identifierSchemeCodeUnspsc190501.51472502'),
	'identifierSchemeCodeUnspsc190501.51472600': __('identifierSchemeCodeUnspsc190501.51472600'),
	'identifierSchemeCodeUnspsc190501.51472601': __('identifierSchemeCodeUnspsc190501.51472601'),
	'identifierSchemeCodeUnspsc190501.51472602': __('identifierSchemeCodeUnspsc190501.51472602'),
	'identifierSchemeCodeUnspsc190501.51472700': __('identifierSchemeCodeUnspsc190501.51472700'),
	'identifierSchemeCodeUnspsc190501.51472702': __('identifierSchemeCodeUnspsc190501.51472702'),
	'identifierSchemeCodeUnspsc190501.51472800': __('identifierSchemeCodeUnspsc190501.51472800'),
	'identifierSchemeCodeUnspsc190501.51472801': __('identifierSchemeCodeUnspsc190501.51472801'),
	'identifierSchemeCodeUnspsc190501.51472802': __('identifierSchemeCodeUnspsc190501.51472802'),
	'identifierSchemeCodeUnspsc190501.51472803': __('identifierSchemeCodeUnspsc190501.51472803'),
	'identifierSchemeCodeUnspsc190501.51472804': __('identifierSchemeCodeUnspsc190501.51472804'),
	'identifierSchemeCodeUnspsc190501.51472805': __('identifierSchemeCodeUnspsc190501.51472805'),
	'identifierSchemeCodeUnspsc190501.51472806': __('identifierSchemeCodeUnspsc190501.51472806'),
	'identifierSchemeCodeUnspsc190501.51472807': __('identifierSchemeCodeUnspsc190501.51472807'),
	'identifierSchemeCodeUnspsc190501.51472808': __('identifierSchemeCodeUnspsc190501.51472808'),
	'identifierSchemeCodeUnspsc190501.51472900': __('identifierSchemeCodeUnspsc190501.51472900'),
	'identifierSchemeCodeUnspsc190501.51472901': __('identifierSchemeCodeUnspsc190501.51472901'),
	'identifierSchemeCodeUnspsc190501.51472902': __('identifierSchemeCodeUnspsc190501.51472902'),
	'identifierSchemeCodeUnspsc190501.51473000': __('identifierSchemeCodeUnspsc190501.51473000'),
	'identifierSchemeCodeUnspsc190501.51473001': __('identifierSchemeCodeUnspsc190501.51473001'),
	'identifierSchemeCodeUnspsc190501.51473002': __('identifierSchemeCodeUnspsc190501.51473002'),
	'identifierSchemeCodeUnspsc190501.51473003': __('identifierSchemeCodeUnspsc190501.51473003'),
	'identifierSchemeCodeUnspsc190501.51473004': __('identifierSchemeCodeUnspsc190501.51473004'),
	'identifierSchemeCodeUnspsc190501.51473005': __('identifierSchemeCodeUnspsc190501.51473005'),
	'identifierSchemeCodeUnspsc190501.51473006': __('identifierSchemeCodeUnspsc190501.51473006'),
	'identifierSchemeCodeUnspsc190501.51473007': __('identifierSchemeCodeUnspsc190501.51473007'),
	'identifierSchemeCodeUnspsc190501.51473008': __('identifierSchemeCodeUnspsc190501.51473008'),
	'identifierSchemeCodeUnspsc190501.51473009': __('identifierSchemeCodeUnspsc190501.51473009'),
	'identifierSchemeCodeUnspsc190501.51473010': __('identifierSchemeCodeUnspsc190501.51473010'),
	'identifierSchemeCodeUnspsc190501.51473011': __('identifierSchemeCodeUnspsc190501.51473011'),
	'identifierSchemeCodeUnspsc190501.51473012': __('identifierSchemeCodeUnspsc190501.51473012'),
	'identifierSchemeCodeUnspsc190501.51473013': __('identifierSchemeCodeUnspsc190501.51473013'),
	'identifierSchemeCodeUnspsc190501.51473014': __('identifierSchemeCodeUnspsc190501.51473014'),
	'identifierSchemeCodeUnspsc190501.51473016': __('identifierSchemeCodeUnspsc190501.51473016'),
	'identifierSchemeCodeUnspsc190501.51473018': __('identifierSchemeCodeUnspsc190501.51473018'),
	'identifierSchemeCodeUnspsc190501.51473020': __('identifierSchemeCodeUnspsc190501.51473020'),
	'identifierSchemeCodeUnspsc190501.51473022': __('identifierSchemeCodeUnspsc190501.51473022'),
	'identifierSchemeCodeUnspsc190501.51473024': __('identifierSchemeCodeUnspsc190501.51473024'),
	'identifierSchemeCodeUnspsc190501.51473026': __('identifierSchemeCodeUnspsc190501.51473026'),
	'identifierSchemeCodeUnspsc190501.51473028': __('identifierSchemeCodeUnspsc190501.51473028'),
	'identifierSchemeCodeUnspsc190501.51473029': __('identifierSchemeCodeUnspsc190501.51473029'),
	'identifierSchemeCodeUnspsc190501.51473100': __('identifierSchemeCodeUnspsc190501.51473100'),
	'identifierSchemeCodeUnspsc190501.51473101': __('identifierSchemeCodeUnspsc190501.51473101'),
	'identifierSchemeCodeUnspsc190501.51473102': __('identifierSchemeCodeUnspsc190501.51473102'),
	'identifierSchemeCodeUnspsc190501.51473103': __('identifierSchemeCodeUnspsc190501.51473103'),
	'identifierSchemeCodeUnspsc190501.51473104': __('identifierSchemeCodeUnspsc190501.51473104'),
	'identifierSchemeCodeUnspsc190501.51473105': __('identifierSchemeCodeUnspsc190501.51473105'),
	'identifierSchemeCodeUnspsc190501.51473106': __('identifierSchemeCodeUnspsc190501.51473106'),
	'identifierSchemeCodeUnspsc190501.51473200': __('identifierSchemeCodeUnspsc190501.51473200'),
	'identifierSchemeCodeUnspsc190501.51473201': __('identifierSchemeCodeUnspsc190501.51473201'),
	'identifierSchemeCodeUnspsc190501.51473202': __('identifierSchemeCodeUnspsc190501.51473202'),
	'identifierSchemeCodeUnspsc190501.51473203': __('identifierSchemeCodeUnspsc190501.51473203'),
	'identifierSchemeCodeUnspsc190501.51473300': __('identifierSchemeCodeUnspsc190501.51473300'),
	'identifierSchemeCodeUnspsc190501.51473301': __('identifierSchemeCodeUnspsc190501.51473301'),
	'identifierSchemeCodeUnspsc190501.51473302': __('identifierSchemeCodeUnspsc190501.51473302'),
	'identifierSchemeCodeUnspsc190501.51473303': __('identifierSchemeCodeUnspsc190501.51473303'),
	'identifierSchemeCodeUnspsc190501.51473304': __('identifierSchemeCodeUnspsc190501.51473304'),
	'identifierSchemeCodeUnspsc190501.51473305': __('identifierSchemeCodeUnspsc190501.51473305'),
	'identifierSchemeCodeUnspsc190501.51473306': __('identifierSchemeCodeUnspsc190501.51473306'),
	'identifierSchemeCodeUnspsc190501.51473307': __('identifierSchemeCodeUnspsc190501.51473307'),
	'identifierSchemeCodeUnspsc190501.51473308': __('identifierSchemeCodeUnspsc190501.51473308'),
	'identifierSchemeCodeUnspsc190501.51473309': __('identifierSchemeCodeUnspsc190501.51473309'),
	'identifierSchemeCodeUnspsc190501.51473400': __('identifierSchemeCodeUnspsc190501.51473400'),
	'identifierSchemeCodeUnspsc190501.51473401': __('identifierSchemeCodeUnspsc190501.51473401'),
	'identifierSchemeCodeUnspsc190501.51473402': __('identifierSchemeCodeUnspsc190501.51473402'),
	'identifierSchemeCodeUnspsc190501.51473403': __('identifierSchemeCodeUnspsc190501.51473403'),
	'identifierSchemeCodeUnspsc190501.51473404': __('identifierSchemeCodeUnspsc190501.51473404'),
	'identifierSchemeCodeUnspsc190501.51473500': __('identifierSchemeCodeUnspsc190501.51473500'),
	'identifierSchemeCodeUnspsc190501.51473501': __('identifierSchemeCodeUnspsc190501.51473501'),
	'identifierSchemeCodeUnspsc190501.51473502': __('identifierSchemeCodeUnspsc190501.51473502'),
	'identifierSchemeCodeUnspsc190501.51473503': __('identifierSchemeCodeUnspsc190501.51473503'),
	'identifierSchemeCodeUnspsc190501.51473504': __('identifierSchemeCodeUnspsc190501.51473504'),
	'identifierSchemeCodeUnspsc190501.51473600': __('identifierSchemeCodeUnspsc190501.51473600'),
	'identifierSchemeCodeUnspsc190501.51473601': __('identifierSchemeCodeUnspsc190501.51473601'),
	'identifierSchemeCodeUnspsc190501.51473602': __('identifierSchemeCodeUnspsc190501.51473602'),
	'identifierSchemeCodeUnspsc190501.51473603': __('identifierSchemeCodeUnspsc190501.51473603'),
	'identifierSchemeCodeUnspsc190501.51473700': __('identifierSchemeCodeUnspsc190501.51473700'),
	'identifierSchemeCodeUnspsc190501.51473701': __('identifierSchemeCodeUnspsc190501.51473701'),
	'identifierSchemeCodeUnspsc190501.51473702': __('identifierSchemeCodeUnspsc190501.51473702'),
	'identifierSchemeCodeUnspsc190501.51473703': __('identifierSchemeCodeUnspsc190501.51473703'),
	'identifierSchemeCodeUnspsc190501.51473704': __('identifierSchemeCodeUnspsc190501.51473704'),
	'identifierSchemeCodeUnspsc190501.51473800': __('identifierSchemeCodeUnspsc190501.51473800'),
	'identifierSchemeCodeUnspsc190501.51473801': __('identifierSchemeCodeUnspsc190501.51473801'),
	'identifierSchemeCodeUnspsc190501.51473802': __('identifierSchemeCodeUnspsc190501.51473802'),
	'identifierSchemeCodeUnspsc190501.51473900': __('identifierSchemeCodeUnspsc190501.51473900'),
	'identifierSchemeCodeUnspsc190501.51473901': __('identifierSchemeCodeUnspsc190501.51473901'),
	'identifierSchemeCodeUnspsc190501.51473902': __('identifierSchemeCodeUnspsc190501.51473902'),
	'identifierSchemeCodeUnspsc190501.51473903': __('identifierSchemeCodeUnspsc190501.51473903'),
	'identifierSchemeCodeUnspsc190501.51473904': __('identifierSchemeCodeUnspsc190501.51473904'),
	'identifierSchemeCodeUnspsc190501.51473905': __('identifierSchemeCodeUnspsc190501.51473905'),
	'identifierSchemeCodeUnspsc190501.51473906': __('identifierSchemeCodeUnspsc190501.51473906'),
	'identifierSchemeCodeUnspsc190501.51473907': __('identifierSchemeCodeUnspsc190501.51473907'),
	'identifierSchemeCodeUnspsc190501.51473908': __('identifierSchemeCodeUnspsc190501.51473908'),
	'identifierSchemeCodeUnspsc190501.51473909': __('identifierSchemeCodeUnspsc190501.51473909'),
	'identifierSchemeCodeUnspsc190501.51473910': __('identifierSchemeCodeUnspsc190501.51473910'),
	'identifierSchemeCodeUnspsc190501.51474000': __('identifierSchemeCodeUnspsc190501.51474000'),
	'identifierSchemeCodeUnspsc190501.51474001': __('identifierSchemeCodeUnspsc190501.51474001'),
	'identifierSchemeCodeUnspsc190501.51474002': __('identifierSchemeCodeUnspsc190501.51474002'),
	'identifierSchemeCodeUnspsc190501.52000000': __('identifierSchemeCodeUnspsc190501.52000000'),
	'identifierSchemeCodeUnspsc190501.52100000': __('identifierSchemeCodeUnspsc190501.52100000'),
	'identifierSchemeCodeUnspsc190501.52101500': __('identifierSchemeCodeUnspsc190501.52101500'),
	'identifierSchemeCodeUnspsc190501.52101501': __('identifierSchemeCodeUnspsc190501.52101501'),
	'identifierSchemeCodeUnspsc190501.52101502': __('identifierSchemeCodeUnspsc190501.52101502'),
	'identifierSchemeCodeUnspsc190501.52101503': __('identifierSchemeCodeUnspsc190501.52101503'),
	'identifierSchemeCodeUnspsc190501.52101504': __('identifierSchemeCodeUnspsc190501.52101504'),
	'identifierSchemeCodeUnspsc190501.52101505': __('identifierSchemeCodeUnspsc190501.52101505'),
	'identifierSchemeCodeUnspsc190501.52101506': __('identifierSchemeCodeUnspsc190501.52101506'),
	'identifierSchemeCodeUnspsc190501.52101507': __('identifierSchemeCodeUnspsc190501.52101507'),
	'identifierSchemeCodeUnspsc190501.52101508': __('identifierSchemeCodeUnspsc190501.52101508'),
	'identifierSchemeCodeUnspsc190501.52101509': __('identifierSchemeCodeUnspsc190501.52101509'),
	'identifierSchemeCodeUnspsc190501.52101510': __('identifierSchemeCodeUnspsc190501.52101510'),
	'identifierSchemeCodeUnspsc190501.52101511': __('identifierSchemeCodeUnspsc190501.52101511'),
	'identifierSchemeCodeUnspsc190501.52101512': __('identifierSchemeCodeUnspsc190501.52101512'),
	'identifierSchemeCodeUnspsc190501.52101513': __('identifierSchemeCodeUnspsc190501.52101513'),
	'identifierSchemeCodeUnspsc190501.52101514': __('identifierSchemeCodeUnspsc190501.52101514'),
	'identifierSchemeCodeUnspsc190501.52101515': __('identifierSchemeCodeUnspsc190501.52101515'),
	'identifierSchemeCodeUnspsc190501.52101516': __('identifierSchemeCodeUnspsc190501.52101516'),
	'identifierSchemeCodeUnspsc190501.52120000': __('identifierSchemeCodeUnspsc190501.52120000'),
	'identifierSchemeCodeUnspsc190501.52121500': __('identifierSchemeCodeUnspsc190501.52121500'),
	'identifierSchemeCodeUnspsc190501.52121501': __('identifierSchemeCodeUnspsc190501.52121501'),
	'identifierSchemeCodeUnspsc190501.52121502': __('identifierSchemeCodeUnspsc190501.52121502'),
	'identifierSchemeCodeUnspsc190501.52121503': __('identifierSchemeCodeUnspsc190501.52121503'),
	'identifierSchemeCodeUnspsc190501.52121504': __('identifierSchemeCodeUnspsc190501.52121504'),
	'identifierSchemeCodeUnspsc190501.52121505': __('identifierSchemeCodeUnspsc190501.52121505'),
	'identifierSchemeCodeUnspsc190501.52121506': __('identifierSchemeCodeUnspsc190501.52121506'),
	'identifierSchemeCodeUnspsc190501.52121507': __('identifierSchemeCodeUnspsc190501.52121507'),
	'identifierSchemeCodeUnspsc190501.52121508': __('identifierSchemeCodeUnspsc190501.52121508'),
	'identifierSchemeCodeUnspsc190501.52121509': __('identifierSchemeCodeUnspsc190501.52121509'),
	'identifierSchemeCodeUnspsc190501.52121510': __('identifierSchemeCodeUnspsc190501.52121510'),
	'identifierSchemeCodeUnspsc190501.52121511': __('identifierSchemeCodeUnspsc190501.52121511'),
	'identifierSchemeCodeUnspsc190501.52121512': __('identifierSchemeCodeUnspsc190501.52121512'),
	'identifierSchemeCodeUnspsc190501.52121513': __('identifierSchemeCodeUnspsc190501.52121513'),
	'identifierSchemeCodeUnspsc190501.52121514': __('identifierSchemeCodeUnspsc190501.52121514'),
	'identifierSchemeCodeUnspsc190501.52121515': __('identifierSchemeCodeUnspsc190501.52121515'),
	'identifierSchemeCodeUnspsc190501.52121600': __('identifierSchemeCodeUnspsc190501.52121600'),
	'identifierSchemeCodeUnspsc190501.52121601': __('identifierSchemeCodeUnspsc190501.52121601'),
	'identifierSchemeCodeUnspsc190501.52121602': __('identifierSchemeCodeUnspsc190501.52121602'),
	'identifierSchemeCodeUnspsc190501.52121603': __('identifierSchemeCodeUnspsc190501.52121603'),
	'identifierSchemeCodeUnspsc190501.52121604': __('identifierSchemeCodeUnspsc190501.52121604'),
	'identifierSchemeCodeUnspsc190501.52121605': __('identifierSchemeCodeUnspsc190501.52121605'),
	'identifierSchemeCodeUnspsc190501.52121606': __('identifierSchemeCodeUnspsc190501.52121606'),
	'identifierSchemeCodeUnspsc190501.52121607': __('identifierSchemeCodeUnspsc190501.52121607'),
	'identifierSchemeCodeUnspsc190501.52121608': __('identifierSchemeCodeUnspsc190501.52121608'),
	'identifierSchemeCodeUnspsc190501.52121700': __('identifierSchemeCodeUnspsc190501.52121700'),
	'identifierSchemeCodeUnspsc190501.52121701': __('identifierSchemeCodeUnspsc190501.52121701'),
	'identifierSchemeCodeUnspsc190501.52121702': __('identifierSchemeCodeUnspsc190501.52121702'),
	'identifierSchemeCodeUnspsc190501.52121703': __('identifierSchemeCodeUnspsc190501.52121703'),
	'identifierSchemeCodeUnspsc190501.52121704': __('identifierSchemeCodeUnspsc190501.52121704'),
	'identifierSchemeCodeUnspsc190501.52121705': __('identifierSchemeCodeUnspsc190501.52121705'),
	'identifierSchemeCodeUnspsc190501.52130000': __('identifierSchemeCodeUnspsc190501.52130000'),
	'identifierSchemeCodeUnspsc190501.52131500': __('identifierSchemeCodeUnspsc190501.52131500'),
	'identifierSchemeCodeUnspsc190501.52131501': __('identifierSchemeCodeUnspsc190501.52131501'),
	'identifierSchemeCodeUnspsc190501.52131503': __('identifierSchemeCodeUnspsc190501.52131503'),
	'identifierSchemeCodeUnspsc190501.52131600': __('identifierSchemeCodeUnspsc190501.52131600'),
	'identifierSchemeCodeUnspsc190501.52131601': __('identifierSchemeCodeUnspsc190501.52131601'),
	'identifierSchemeCodeUnspsc190501.52131602': __('identifierSchemeCodeUnspsc190501.52131602'),
	'identifierSchemeCodeUnspsc190501.52131603': __('identifierSchemeCodeUnspsc190501.52131603'),
	'identifierSchemeCodeUnspsc190501.52131604': __('identifierSchemeCodeUnspsc190501.52131604'),
	'identifierSchemeCodeUnspsc190501.52131700': __('identifierSchemeCodeUnspsc190501.52131700'),
	'identifierSchemeCodeUnspsc190501.52131701': __('identifierSchemeCodeUnspsc190501.52131701'),
	'identifierSchemeCodeUnspsc190501.52131702': __('identifierSchemeCodeUnspsc190501.52131702'),
	'identifierSchemeCodeUnspsc190501.52131703': __('identifierSchemeCodeUnspsc190501.52131703'),
	'identifierSchemeCodeUnspsc190501.52131704': __('identifierSchemeCodeUnspsc190501.52131704'),
	'identifierSchemeCodeUnspsc190501.52131705': __('identifierSchemeCodeUnspsc190501.52131705'),
	'identifierSchemeCodeUnspsc190501.52140000': __('identifierSchemeCodeUnspsc190501.52140000'),
	'identifierSchemeCodeUnspsc190501.52141500': __('identifierSchemeCodeUnspsc190501.52141500'),
	'identifierSchemeCodeUnspsc190501.52141501': __('identifierSchemeCodeUnspsc190501.52141501'),
	'identifierSchemeCodeUnspsc190501.52141502': __('identifierSchemeCodeUnspsc190501.52141502'),
	'identifierSchemeCodeUnspsc190501.52141503': __('identifierSchemeCodeUnspsc190501.52141503'),
	'identifierSchemeCodeUnspsc190501.52141504': __('identifierSchemeCodeUnspsc190501.52141504'),
	'identifierSchemeCodeUnspsc190501.52141505': __('identifierSchemeCodeUnspsc190501.52141505'),
	'identifierSchemeCodeUnspsc190501.52141506': __('identifierSchemeCodeUnspsc190501.52141506'),
	'identifierSchemeCodeUnspsc190501.52141507': __('identifierSchemeCodeUnspsc190501.52141507'),
	'identifierSchemeCodeUnspsc190501.52141508': __('identifierSchemeCodeUnspsc190501.52141508'),
	'identifierSchemeCodeUnspsc190501.52141509': __('identifierSchemeCodeUnspsc190501.52141509'),
	'identifierSchemeCodeUnspsc190501.52141510': __('identifierSchemeCodeUnspsc190501.52141510'),
	'identifierSchemeCodeUnspsc190501.52141511': __('identifierSchemeCodeUnspsc190501.52141511'),
	'identifierSchemeCodeUnspsc190501.52141512': __('identifierSchemeCodeUnspsc190501.52141512'),
	'identifierSchemeCodeUnspsc190501.52141513': __('identifierSchemeCodeUnspsc190501.52141513'),
	'identifierSchemeCodeUnspsc190501.52141514': __('identifierSchemeCodeUnspsc190501.52141514'),
	'identifierSchemeCodeUnspsc190501.52141515': __('identifierSchemeCodeUnspsc190501.52141515'),
	'identifierSchemeCodeUnspsc190501.52141516': __('identifierSchemeCodeUnspsc190501.52141516'),
	'identifierSchemeCodeUnspsc190501.52141517': __('identifierSchemeCodeUnspsc190501.52141517'),
	'identifierSchemeCodeUnspsc190501.52141518': __('identifierSchemeCodeUnspsc190501.52141518'),
	'identifierSchemeCodeUnspsc190501.52141519': __('identifierSchemeCodeUnspsc190501.52141519'),
	'identifierSchemeCodeUnspsc190501.52141520': __('identifierSchemeCodeUnspsc190501.52141520'),
	'identifierSchemeCodeUnspsc190501.52141521': __('identifierSchemeCodeUnspsc190501.52141521'),
	'identifierSchemeCodeUnspsc190501.52141522': __('identifierSchemeCodeUnspsc190501.52141522'),
	'identifierSchemeCodeUnspsc190501.52141523': __('identifierSchemeCodeUnspsc190501.52141523'),
	'identifierSchemeCodeUnspsc190501.52141524': __('identifierSchemeCodeUnspsc190501.52141524'),
	'identifierSchemeCodeUnspsc190501.52141525': __('identifierSchemeCodeUnspsc190501.52141525'),
	'identifierSchemeCodeUnspsc190501.52141526': __('identifierSchemeCodeUnspsc190501.52141526'),
	'identifierSchemeCodeUnspsc190501.52141527': __('identifierSchemeCodeUnspsc190501.52141527'),
	'identifierSchemeCodeUnspsc190501.52141528': __('identifierSchemeCodeUnspsc190501.52141528'),
	'identifierSchemeCodeUnspsc190501.52141529': __('identifierSchemeCodeUnspsc190501.52141529'),
	'identifierSchemeCodeUnspsc190501.52141530': __('identifierSchemeCodeUnspsc190501.52141530'),
	'identifierSchemeCodeUnspsc190501.52141531': __('identifierSchemeCodeUnspsc190501.52141531'),
	'identifierSchemeCodeUnspsc190501.52141532': __('identifierSchemeCodeUnspsc190501.52141532'),
	'identifierSchemeCodeUnspsc190501.52141533': __('identifierSchemeCodeUnspsc190501.52141533'),
	'identifierSchemeCodeUnspsc190501.52141534': __('identifierSchemeCodeUnspsc190501.52141534'),
	'identifierSchemeCodeUnspsc190501.52141535': __('identifierSchemeCodeUnspsc190501.52141535'),
	'identifierSchemeCodeUnspsc190501.52141536': __('identifierSchemeCodeUnspsc190501.52141536'),
	'identifierSchemeCodeUnspsc190501.52141537': __('identifierSchemeCodeUnspsc190501.52141537'),
	'identifierSchemeCodeUnspsc190501.52141538': __('identifierSchemeCodeUnspsc190501.52141538'),
	'identifierSchemeCodeUnspsc190501.52141539': __('identifierSchemeCodeUnspsc190501.52141539'),
	'identifierSchemeCodeUnspsc190501.52141540': __('identifierSchemeCodeUnspsc190501.52141540'),
	'identifierSchemeCodeUnspsc190501.52141541': __('identifierSchemeCodeUnspsc190501.52141541'),
	'identifierSchemeCodeUnspsc190501.52141542': __('identifierSchemeCodeUnspsc190501.52141542'),
	'identifierSchemeCodeUnspsc190501.52141543': __('identifierSchemeCodeUnspsc190501.52141543'),
	'identifierSchemeCodeUnspsc190501.52141544': __('identifierSchemeCodeUnspsc190501.52141544'),
	'identifierSchemeCodeUnspsc190501.52141545': __('identifierSchemeCodeUnspsc190501.52141545'),
	'identifierSchemeCodeUnspsc190501.52141546': __('identifierSchemeCodeUnspsc190501.52141546'),
	'identifierSchemeCodeUnspsc190501.52141547': __('identifierSchemeCodeUnspsc190501.52141547'),
	'identifierSchemeCodeUnspsc190501.52141548': __('identifierSchemeCodeUnspsc190501.52141548'),
	'identifierSchemeCodeUnspsc190501.52141549': __('identifierSchemeCodeUnspsc190501.52141549'),
	'identifierSchemeCodeUnspsc190501.52141550': __('identifierSchemeCodeUnspsc190501.52141550'),
	'identifierSchemeCodeUnspsc190501.52141551': __('identifierSchemeCodeUnspsc190501.52141551'),
	'identifierSchemeCodeUnspsc190501.52141552': __('identifierSchemeCodeUnspsc190501.52141552'),
	'identifierSchemeCodeUnspsc190501.52141553': __('identifierSchemeCodeUnspsc190501.52141553'),
	'identifierSchemeCodeUnspsc190501.52141554': __('identifierSchemeCodeUnspsc190501.52141554'),
	'identifierSchemeCodeUnspsc190501.52141555': __('identifierSchemeCodeUnspsc190501.52141555'),
	'identifierSchemeCodeUnspsc190501.52141556': __('identifierSchemeCodeUnspsc190501.52141556'),
	'identifierSchemeCodeUnspsc190501.52141557': __('identifierSchemeCodeUnspsc190501.52141557'),
	'identifierSchemeCodeUnspsc190501.52141558': __('identifierSchemeCodeUnspsc190501.52141558'),
	'identifierSchemeCodeUnspsc190501.52141559': __('identifierSchemeCodeUnspsc190501.52141559'),
	'identifierSchemeCodeUnspsc190501.52141560': __('identifierSchemeCodeUnspsc190501.52141560'),
	'identifierSchemeCodeUnspsc190501.52141561': __('identifierSchemeCodeUnspsc190501.52141561'),
	'identifierSchemeCodeUnspsc190501.52141562': __('identifierSchemeCodeUnspsc190501.52141562'),
	'identifierSchemeCodeUnspsc190501.52141563': __('identifierSchemeCodeUnspsc190501.52141563'),
	'identifierSchemeCodeUnspsc190501.52141600': __('identifierSchemeCodeUnspsc190501.52141600'),
	'identifierSchemeCodeUnspsc190501.52141601': __('identifierSchemeCodeUnspsc190501.52141601'),
	'identifierSchemeCodeUnspsc190501.52141602': __('identifierSchemeCodeUnspsc190501.52141602'),
	'identifierSchemeCodeUnspsc190501.52141603': __('identifierSchemeCodeUnspsc190501.52141603'),
	'identifierSchemeCodeUnspsc190501.52141604': __('identifierSchemeCodeUnspsc190501.52141604'),
	'identifierSchemeCodeUnspsc190501.52141605': __('identifierSchemeCodeUnspsc190501.52141605'),
	'identifierSchemeCodeUnspsc190501.52141606': __('identifierSchemeCodeUnspsc190501.52141606'),
	'identifierSchemeCodeUnspsc190501.52141607': __('identifierSchemeCodeUnspsc190501.52141607'),
	'identifierSchemeCodeUnspsc190501.52141608': __('identifierSchemeCodeUnspsc190501.52141608'),
	'identifierSchemeCodeUnspsc190501.52141609': __('identifierSchemeCodeUnspsc190501.52141609'),
	'identifierSchemeCodeUnspsc190501.52141610': __('identifierSchemeCodeUnspsc190501.52141610'),
	'identifierSchemeCodeUnspsc190501.52141611': __('identifierSchemeCodeUnspsc190501.52141611'),
	'identifierSchemeCodeUnspsc190501.52141612': __('identifierSchemeCodeUnspsc190501.52141612'),
	'identifierSchemeCodeUnspsc190501.52141613': __('identifierSchemeCodeUnspsc190501.52141613'),
	'identifierSchemeCodeUnspsc190501.52141700': __('identifierSchemeCodeUnspsc190501.52141700'),
	'identifierSchemeCodeUnspsc190501.52141701': __('identifierSchemeCodeUnspsc190501.52141701'),
	'identifierSchemeCodeUnspsc190501.52141703': __('identifierSchemeCodeUnspsc190501.52141703'),
	'identifierSchemeCodeUnspsc190501.52141704': __('identifierSchemeCodeUnspsc190501.52141704'),
	'identifierSchemeCodeUnspsc190501.52141705': __('identifierSchemeCodeUnspsc190501.52141705'),
	'identifierSchemeCodeUnspsc190501.52141706': __('identifierSchemeCodeUnspsc190501.52141706'),
	'identifierSchemeCodeUnspsc190501.52141707': __('identifierSchemeCodeUnspsc190501.52141707'),
	'identifierSchemeCodeUnspsc190501.52141708': __('identifierSchemeCodeUnspsc190501.52141708'),
	'identifierSchemeCodeUnspsc190501.52141709': __('identifierSchemeCodeUnspsc190501.52141709'),
	'identifierSchemeCodeUnspsc190501.52141800': __('identifierSchemeCodeUnspsc190501.52141800'),
	'identifierSchemeCodeUnspsc190501.52141801': __('identifierSchemeCodeUnspsc190501.52141801'),
	'identifierSchemeCodeUnspsc190501.52141802': __('identifierSchemeCodeUnspsc190501.52141802'),
	'identifierSchemeCodeUnspsc190501.52141803': __('identifierSchemeCodeUnspsc190501.52141803'),
	'identifierSchemeCodeUnspsc190501.52141804': __('identifierSchemeCodeUnspsc190501.52141804'),
	'identifierSchemeCodeUnspsc190501.52141805': __('identifierSchemeCodeUnspsc190501.52141805'),
	'identifierSchemeCodeUnspsc190501.52141806': __('identifierSchemeCodeUnspsc190501.52141806'),
	'identifierSchemeCodeUnspsc190501.52141807': __('identifierSchemeCodeUnspsc190501.52141807'),
	'identifierSchemeCodeUnspsc190501.52150000': __('identifierSchemeCodeUnspsc190501.52150000'),
	'identifierSchemeCodeUnspsc190501.52151500': __('identifierSchemeCodeUnspsc190501.52151500'),
	'identifierSchemeCodeUnspsc190501.52151501': __('identifierSchemeCodeUnspsc190501.52151501'),
	'identifierSchemeCodeUnspsc190501.52151502': __('identifierSchemeCodeUnspsc190501.52151502'),
	'identifierSchemeCodeUnspsc190501.52151503': __('identifierSchemeCodeUnspsc190501.52151503'),
	'identifierSchemeCodeUnspsc190501.52151504': __('identifierSchemeCodeUnspsc190501.52151504'),
	'identifierSchemeCodeUnspsc190501.52151505': __('identifierSchemeCodeUnspsc190501.52151505'),
	'identifierSchemeCodeUnspsc190501.52151506': __('identifierSchemeCodeUnspsc190501.52151506'),
	'identifierSchemeCodeUnspsc190501.52151507': __('identifierSchemeCodeUnspsc190501.52151507'),
	'identifierSchemeCodeUnspsc190501.52151600': __('identifierSchemeCodeUnspsc190501.52151600'),
	'identifierSchemeCodeUnspsc190501.52151601': __('identifierSchemeCodeUnspsc190501.52151601'),
	'identifierSchemeCodeUnspsc190501.52151602': __('identifierSchemeCodeUnspsc190501.52151602'),
	'identifierSchemeCodeUnspsc190501.52151603': __('identifierSchemeCodeUnspsc190501.52151603'),
	'identifierSchemeCodeUnspsc190501.52151604': __('identifierSchemeCodeUnspsc190501.52151604'),
	'identifierSchemeCodeUnspsc190501.52151605': __('identifierSchemeCodeUnspsc190501.52151605'),
	'identifierSchemeCodeUnspsc190501.52151606': __('identifierSchemeCodeUnspsc190501.52151606'),
	'identifierSchemeCodeUnspsc190501.52151607': __('identifierSchemeCodeUnspsc190501.52151607'),
	'identifierSchemeCodeUnspsc190501.52151608': __('identifierSchemeCodeUnspsc190501.52151608'),
	'identifierSchemeCodeUnspsc190501.52151609': __('identifierSchemeCodeUnspsc190501.52151609'),
	'identifierSchemeCodeUnspsc190501.52151610': __('identifierSchemeCodeUnspsc190501.52151610'),
	'identifierSchemeCodeUnspsc190501.52151611': __('identifierSchemeCodeUnspsc190501.52151611'),
	'identifierSchemeCodeUnspsc190501.52151612': __('identifierSchemeCodeUnspsc190501.52151612'),
	'identifierSchemeCodeUnspsc190501.52151613': __('identifierSchemeCodeUnspsc190501.52151613'),
	'identifierSchemeCodeUnspsc190501.52151614': __('identifierSchemeCodeUnspsc190501.52151614'),
	'identifierSchemeCodeUnspsc190501.52151615': __('identifierSchemeCodeUnspsc190501.52151615'),
	'identifierSchemeCodeUnspsc190501.52151616': __('identifierSchemeCodeUnspsc190501.52151616'),
	'identifierSchemeCodeUnspsc190501.52151617': __('identifierSchemeCodeUnspsc190501.52151617'),
	'identifierSchemeCodeUnspsc190501.52151618': __('identifierSchemeCodeUnspsc190501.52151618'),
	'identifierSchemeCodeUnspsc190501.52151619': __('identifierSchemeCodeUnspsc190501.52151619'),
	'identifierSchemeCodeUnspsc190501.52151620': __('identifierSchemeCodeUnspsc190501.52151620'),
	'identifierSchemeCodeUnspsc190501.52151621': __('identifierSchemeCodeUnspsc190501.52151621'),
	'identifierSchemeCodeUnspsc190501.52151622': __('identifierSchemeCodeUnspsc190501.52151622'),
	'identifierSchemeCodeUnspsc190501.52151623': __('identifierSchemeCodeUnspsc190501.52151623'),
	'identifierSchemeCodeUnspsc190501.52151624': __('identifierSchemeCodeUnspsc190501.52151624'),
	'identifierSchemeCodeUnspsc190501.52151625': __('identifierSchemeCodeUnspsc190501.52151625'),
	'identifierSchemeCodeUnspsc190501.52151626': __('identifierSchemeCodeUnspsc190501.52151626'),
	'identifierSchemeCodeUnspsc190501.52151627': __('identifierSchemeCodeUnspsc190501.52151627'),
	'identifierSchemeCodeUnspsc190501.52151628': __('identifierSchemeCodeUnspsc190501.52151628'),
	'identifierSchemeCodeUnspsc190501.52151629': __('identifierSchemeCodeUnspsc190501.52151629'),
	'identifierSchemeCodeUnspsc190501.52151630': __('identifierSchemeCodeUnspsc190501.52151630'),
	'identifierSchemeCodeUnspsc190501.52151631': __('identifierSchemeCodeUnspsc190501.52151631'),
	'identifierSchemeCodeUnspsc190501.52151632': __('identifierSchemeCodeUnspsc190501.52151632'),
	'identifierSchemeCodeUnspsc190501.52151633': __('identifierSchemeCodeUnspsc190501.52151633'),
	'identifierSchemeCodeUnspsc190501.52151634': __('identifierSchemeCodeUnspsc190501.52151634'),
	'identifierSchemeCodeUnspsc190501.52151635': __('identifierSchemeCodeUnspsc190501.52151635'),
	'identifierSchemeCodeUnspsc190501.52151636': __('identifierSchemeCodeUnspsc190501.52151636'),
	'identifierSchemeCodeUnspsc190501.52151637': __('identifierSchemeCodeUnspsc190501.52151637'),
	'identifierSchemeCodeUnspsc190501.52151638': __('identifierSchemeCodeUnspsc190501.52151638'),
	'identifierSchemeCodeUnspsc190501.52151639': __('identifierSchemeCodeUnspsc190501.52151639'),
	'identifierSchemeCodeUnspsc190501.52151640': __('identifierSchemeCodeUnspsc190501.52151640'),
	'identifierSchemeCodeUnspsc190501.52151641': __('identifierSchemeCodeUnspsc190501.52151641'),
	'identifierSchemeCodeUnspsc190501.52151642': __('identifierSchemeCodeUnspsc190501.52151642'),
	'identifierSchemeCodeUnspsc190501.52151643': __('identifierSchemeCodeUnspsc190501.52151643'),
	'identifierSchemeCodeUnspsc190501.52151644': __('identifierSchemeCodeUnspsc190501.52151644'),
	'identifierSchemeCodeUnspsc190501.52151645': __('identifierSchemeCodeUnspsc190501.52151645'),
	'identifierSchemeCodeUnspsc190501.52151646': __('identifierSchemeCodeUnspsc190501.52151646'),
	'identifierSchemeCodeUnspsc190501.52151647': __('identifierSchemeCodeUnspsc190501.52151647'),
	'identifierSchemeCodeUnspsc190501.52151648': __('identifierSchemeCodeUnspsc190501.52151648'),
	'identifierSchemeCodeUnspsc190501.52151649': __('identifierSchemeCodeUnspsc190501.52151649'),
	'identifierSchemeCodeUnspsc190501.52151650': __('identifierSchemeCodeUnspsc190501.52151650'),
	'identifierSchemeCodeUnspsc190501.52151651': __('identifierSchemeCodeUnspsc190501.52151651'),
	'identifierSchemeCodeUnspsc190501.52151652': __('identifierSchemeCodeUnspsc190501.52151652'),
	'identifierSchemeCodeUnspsc190501.52151653': __('identifierSchemeCodeUnspsc190501.52151653'),
	'identifierSchemeCodeUnspsc190501.52151654': __('identifierSchemeCodeUnspsc190501.52151654'),
	'identifierSchemeCodeUnspsc190501.52151655': __('identifierSchemeCodeUnspsc190501.52151655'),
	'identifierSchemeCodeUnspsc190501.52151656': __('identifierSchemeCodeUnspsc190501.52151656'),
	'identifierSchemeCodeUnspsc190501.52151657': __('identifierSchemeCodeUnspsc190501.52151657'),
	'identifierSchemeCodeUnspsc190501.52151658': __('identifierSchemeCodeUnspsc190501.52151658'),
	'identifierSchemeCodeUnspsc190501.52151659': __('identifierSchemeCodeUnspsc190501.52151659'),
	'identifierSchemeCodeUnspsc190501.52151660': __('identifierSchemeCodeUnspsc190501.52151660'),
	'identifierSchemeCodeUnspsc190501.52151661': __('identifierSchemeCodeUnspsc190501.52151661'),
	'identifierSchemeCodeUnspsc190501.52151662': __('identifierSchemeCodeUnspsc190501.52151662'),
	'identifierSchemeCodeUnspsc190501.52151663': __('identifierSchemeCodeUnspsc190501.52151663'),
	'identifierSchemeCodeUnspsc190501.52151664': __('identifierSchemeCodeUnspsc190501.52151664'),
	'identifierSchemeCodeUnspsc190501.52151665': __('identifierSchemeCodeUnspsc190501.52151665'),
	'identifierSchemeCodeUnspsc190501.52151666': __('identifierSchemeCodeUnspsc190501.52151666'),
	'identifierSchemeCodeUnspsc190501.52151667': __('identifierSchemeCodeUnspsc190501.52151667'),
	'identifierSchemeCodeUnspsc190501.52151668': __('identifierSchemeCodeUnspsc190501.52151668'),
	'identifierSchemeCodeUnspsc190501.52151700': __('identifierSchemeCodeUnspsc190501.52151700'),
	'identifierSchemeCodeUnspsc190501.52151701': __('identifierSchemeCodeUnspsc190501.52151701'),
	'identifierSchemeCodeUnspsc190501.52151702': __('identifierSchemeCodeUnspsc190501.52151702'),
	'identifierSchemeCodeUnspsc190501.52151703': __('identifierSchemeCodeUnspsc190501.52151703'),
	'identifierSchemeCodeUnspsc190501.52151704': __('identifierSchemeCodeUnspsc190501.52151704'),
	'identifierSchemeCodeUnspsc190501.52151705': __('identifierSchemeCodeUnspsc190501.52151705'),
	'identifierSchemeCodeUnspsc190501.52151706': __('identifierSchemeCodeUnspsc190501.52151706'),
	'identifierSchemeCodeUnspsc190501.52151707': __('identifierSchemeCodeUnspsc190501.52151707'),
	'identifierSchemeCodeUnspsc190501.52151708': __('identifierSchemeCodeUnspsc190501.52151708'),
	'identifierSchemeCodeUnspsc190501.52151709': __('identifierSchemeCodeUnspsc190501.52151709'),
	'identifierSchemeCodeUnspsc190501.52151800': __('identifierSchemeCodeUnspsc190501.52151800'),
	'identifierSchemeCodeUnspsc190501.52151801': __('identifierSchemeCodeUnspsc190501.52151801'),
	'identifierSchemeCodeUnspsc190501.52151802': __('identifierSchemeCodeUnspsc190501.52151802'),
	'identifierSchemeCodeUnspsc190501.52151803': __('identifierSchemeCodeUnspsc190501.52151803'),
	'identifierSchemeCodeUnspsc190501.52151804': __('identifierSchemeCodeUnspsc190501.52151804'),
	'identifierSchemeCodeUnspsc190501.52151805': __('identifierSchemeCodeUnspsc190501.52151805'),
	'identifierSchemeCodeUnspsc190501.52151806': __('identifierSchemeCodeUnspsc190501.52151806'),
	'identifierSchemeCodeUnspsc190501.52151807': __('identifierSchemeCodeUnspsc190501.52151807'),
	'identifierSchemeCodeUnspsc190501.52151808': __('identifierSchemeCodeUnspsc190501.52151808'),
	'identifierSchemeCodeUnspsc190501.52151809': __('identifierSchemeCodeUnspsc190501.52151809'),
	'identifierSchemeCodeUnspsc190501.52151810': __('identifierSchemeCodeUnspsc190501.52151810'),
	'identifierSchemeCodeUnspsc190501.52151811': __('identifierSchemeCodeUnspsc190501.52151811'),
	'identifierSchemeCodeUnspsc190501.52151812': __('identifierSchemeCodeUnspsc190501.52151812'),
	'identifierSchemeCodeUnspsc190501.52151813': __('identifierSchemeCodeUnspsc190501.52151813'),
	'identifierSchemeCodeUnspsc190501.52151814': __('identifierSchemeCodeUnspsc190501.52151814'),
	'identifierSchemeCodeUnspsc190501.52151815': __('identifierSchemeCodeUnspsc190501.52151815'),
	'identifierSchemeCodeUnspsc190501.52151900': __('identifierSchemeCodeUnspsc190501.52151900'),
	'identifierSchemeCodeUnspsc190501.52151901': __('identifierSchemeCodeUnspsc190501.52151901'),
	'identifierSchemeCodeUnspsc190501.52151902': __('identifierSchemeCodeUnspsc190501.52151902'),
	'identifierSchemeCodeUnspsc190501.52151903': __('identifierSchemeCodeUnspsc190501.52151903'),
	'identifierSchemeCodeUnspsc190501.52151904': __('identifierSchemeCodeUnspsc190501.52151904'),
	'identifierSchemeCodeUnspsc190501.52151905': __('identifierSchemeCodeUnspsc190501.52151905'),
	'identifierSchemeCodeUnspsc190501.52151906': __('identifierSchemeCodeUnspsc190501.52151906'),
	'identifierSchemeCodeUnspsc190501.52151907': __('identifierSchemeCodeUnspsc190501.52151907'),
	'identifierSchemeCodeUnspsc190501.52151908': __('identifierSchemeCodeUnspsc190501.52151908'),
	'identifierSchemeCodeUnspsc190501.52151909': __('identifierSchemeCodeUnspsc190501.52151909'),
	'identifierSchemeCodeUnspsc190501.52152000': __('identifierSchemeCodeUnspsc190501.52152000'),
	'identifierSchemeCodeUnspsc190501.52152001': __('identifierSchemeCodeUnspsc190501.52152001'),
	'identifierSchemeCodeUnspsc190501.52152002': __('identifierSchemeCodeUnspsc190501.52152002'),
	'identifierSchemeCodeUnspsc190501.52152003': __('identifierSchemeCodeUnspsc190501.52152003'),
	'identifierSchemeCodeUnspsc190501.52152004': __('identifierSchemeCodeUnspsc190501.52152004'),
	'identifierSchemeCodeUnspsc190501.52152005': __('identifierSchemeCodeUnspsc190501.52152005'),
	'identifierSchemeCodeUnspsc190501.52152006': __('identifierSchemeCodeUnspsc190501.52152006'),
	'identifierSchemeCodeUnspsc190501.52152007': __('identifierSchemeCodeUnspsc190501.52152007'),
	'identifierSchemeCodeUnspsc190501.52152008': __('identifierSchemeCodeUnspsc190501.52152008'),
	'identifierSchemeCodeUnspsc190501.52152009': __('identifierSchemeCodeUnspsc190501.52152009'),
	'identifierSchemeCodeUnspsc190501.52152010': __('identifierSchemeCodeUnspsc190501.52152010'),
	'identifierSchemeCodeUnspsc190501.52152011': __('identifierSchemeCodeUnspsc190501.52152011'),
	'identifierSchemeCodeUnspsc190501.52152012': __('identifierSchemeCodeUnspsc190501.52152012'),
	'identifierSchemeCodeUnspsc190501.52152013': __('identifierSchemeCodeUnspsc190501.52152013'),
	'identifierSchemeCodeUnspsc190501.52152014': __('identifierSchemeCodeUnspsc190501.52152014'),
	'identifierSchemeCodeUnspsc190501.52152015': __('identifierSchemeCodeUnspsc190501.52152015'),
	'identifierSchemeCodeUnspsc190501.52152016': __('identifierSchemeCodeUnspsc190501.52152016'),
	'identifierSchemeCodeUnspsc190501.52152017': __('identifierSchemeCodeUnspsc190501.52152017'),
	'identifierSchemeCodeUnspsc190501.52152018': __('identifierSchemeCodeUnspsc190501.52152018'),
	'identifierSchemeCodeUnspsc190501.52152019': __('identifierSchemeCodeUnspsc190501.52152019'),
	'identifierSchemeCodeUnspsc190501.52152100': __('identifierSchemeCodeUnspsc190501.52152100'),
	'identifierSchemeCodeUnspsc190501.52152101': __('identifierSchemeCodeUnspsc190501.52152101'),
	'identifierSchemeCodeUnspsc190501.52152102': __('identifierSchemeCodeUnspsc190501.52152102'),
	'identifierSchemeCodeUnspsc190501.52152103': __('identifierSchemeCodeUnspsc190501.52152103'),
	'identifierSchemeCodeUnspsc190501.52152104': __('identifierSchemeCodeUnspsc190501.52152104'),
	'identifierSchemeCodeUnspsc190501.52152105': __('identifierSchemeCodeUnspsc190501.52152105'),
	'identifierSchemeCodeUnspsc190501.52152106': __('identifierSchemeCodeUnspsc190501.52152106'),
	'identifierSchemeCodeUnspsc190501.52152200': __('identifierSchemeCodeUnspsc190501.52152200'),
	'identifierSchemeCodeUnspsc190501.52152201': __('identifierSchemeCodeUnspsc190501.52152201'),
	'identifierSchemeCodeUnspsc190501.52152202': __('identifierSchemeCodeUnspsc190501.52152202'),
	'identifierSchemeCodeUnspsc190501.52152203': __('identifierSchemeCodeUnspsc190501.52152203'),
	'identifierSchemeCodeUnspsc190501.52152204': __('identifierSchemeCodeUnspsc190501.52152204'),
	'identifierSchemeCodeUnspsc190501.52152300': __('identifierSchemeCodeUnspsc190501.52152300'),
	'identifierSchemeCodeUnspsc190501.52152301': __('identifierSchemeCodeUnspsc190501.52152301'),
	'identifierSchemeCodeUnspsc190501.52160000': __('identifierSchemeCodeUnspsc190501.52160000'),
	'identifierSchemeCodeUnspsc190501.52161500': __('identifierSchemeCodeUnspsc190501.52161500'),
	'identifierSchemeCodeUnspsc190501.52161502': __('identifierSchemeCodeUnspsc190501.52161502'),
	'identifierSchemeCodeUnspsc190501.52161505': __('identifierSchemeCodeUnspsc190501.52161505'),
	'identifierSchemeCodeUnspsc190501.52161507': __('identifierSchemeCodeUnspsc190501.52161507'),
	'identifierSchemeCodeUnspsc190501.52161508': __('identifierSchemeCodeUnspsc190501.52161508'),
	'identifierSchemeCodeUnspsc190501.52161509': __('identifierSchemeCodeUnspsc190501.52161509'),
	'identifierSchemeCodeUnspsc190501.52161510': __('identifierSchemeCodeUnspsc190501.52161510'),
	'identifierSchemeCodeUnspsc190501.52161511': __('identifierSchemeCodeUnspsc190501.52161511'),
	'identifierSchemeCodeUnspsc190501.52161512': __('identifierSchemeCodeUnspsc190501.52161512'),
	'identifierSchemeCodeUnspsc190501.52161513': __('identifierSchemeCodeUnspsc190501.52161513'),
	'identifierSchemeCodeUnspsc190501.52161514': __('identifierSchemeCodeUnspsc190501.52161514'),
	'identifierSchemeCodeUnspsc190501.52161515': __('identifierSchemeCodeUnspsc190501.52161515'),
	'identifierSchemeCodeUnspsc190501.52161516': __('identifierSchemeCodeUnspsc190501.52161516'),
	'identifierSchemeCodeUnspsc190501.52161517': __('identifierSchemeCodeUnspsc190501.52161517'),
	'identifierSchemeCodeUnspsc190501.52161518': __('identifierSchemeCodeUnspsc190501.52161518'),
	'identifierSchemeCodeUnspsc190501.52161520': __('identifierSchemeCodeUnspsc190501.52161520'),
	'identifierSchemeCodeUnspsc190501.52161521': __('identifierSchemeCodeUnspsc190501.52161521'),
	'identifierSchemeCodeUnspsc190501.52161522': __('identifierSchemeCodeUnspsc190501.52161522'),
	'identifierSchemeCodeUnspsc190501.52161523': __('identifierSchemeCodeUnspsc190501.52161523'),
	'identifierSchemeCodeUnspsc190501.52161524': __('identifierSchemeCodeUnspsc190501.52161524'),
	'identifierSchemeCodeUnspsc190501.52161525': __('identifierSchemeCodeUnspsc190501.52161525'),
	'identifierSchemeCodeUnspsc190501.52161526': __('identifierSchemeCodeUnspsc190501.52161526'),
	'identifierSchemeCodeUnspsc190501.52161527': __('identifierSchemeCodeUnspsc190501.52161527'),
	'identifierSchemeCodeUnspsc190501.52161529': __('identifierSchemeCodeUnspsc190501.52161529'),
	'identifierSchemeCodeUnspsc190501.52161531': __('identifierSchemeCodeUnspsc190501.52161531'),
	'identifierSchemeCodeUnspsc190501.52161532': __('identifierSchemeCodeUnspsc190501.52161532'),
	'identifierSchemeCodeUnspsc190501.52161533': __('identifierSchemeCodeUnspsc190501.52161533'),
	'identifierSchemeCodeUnspsc190501.52161534': __('identifierSchemeCodeUnspsc190501.52161534'),
	'identifierSchemeCodeUnspsc190501.52161535': __('identifierSchemeCodeUnspsc190501.52161535'),
	'identifierSchemeCodeUnspsc190501.52161536': __('identifierSchemeCodeUnspsc190501.52161536'),
	'identifierSchemeCodeUnspsc190501.52161537': __('identifierSchemeCodeUnspsc190501.52161537'),
	'identifierSchemeCodeUnspsc190501.52161538': __('identifierSchemeCodeUnspsc190501.52161538'),
	'identifierSchemeCodeUnspsc190501.52161539': __('identifierSchemeCodeUnspsc190501.52161539'),
	'identifierSchemeCodeUnspsc190501.52161540': __('identifierSchemeCodeUnspsc190501.52161540'),
	'identifierSchemeCodeUnspsc190501.52161541': __('identifierSchemeCodeUnspsc190501.52161541'),
	'identifierSchemeCodeUnspsc190501.52161542': __('identifierSchemeCodeUnspsc190501.52161542'),
	'identifierSchemeCodeUnspsc190501.52161543': __('identifierSchemeCodeUnspsc190501.52161543'),
	'identifierSchemeCodeUnspsc190501.52161544': __('identifierSchemeCodeUnspsc190501.52161544'),
	'identifierSchemeCodeUnspsc190501.52161545': __('identifierSchemeCodeUnspsc190501.52161545'),
	'identifierSchemeCodeUnspsc190501.52161546': __('identifierSchemeCodeUnspsc190501.52161546'),
	'identifierSchemeCodeUnspsc190501.52161547': __('identifierSchemeCodeUnspsc190501.52161547'),
	'identifierSchemeCodeUnspsc190501.52161548': __('identifierSchemeCodeUnspsc190501.52161548'),
	'identifierSchemeCodeUnspsc190501.52161549': __('identifierSchemeCodeUnspsc190501.52161549'),
	'identifierSchemeCodeUnspsc190501.52161550': __('identifierSchemeCodeUnspsc190501.52161550'),
	'identifierSchemeCodeUnspsc190501.52161551': __('identifierSchemeCodeUnspsc190501.52161551'),
	'identifierSchemeCodeUnspsc190501.52161552': __('identifierSchemeCodeUnspsc190501.52161552'),
	'identifierSchemeCodeUnspsc190501.52161553': __('identifierSchemeCodeUnspsc190501.52161553'),
	'identifierSchemeCodeUnspsc190501.52161554': __('identifierSchemeCodeUnspsc190501.52161554'),
	'identifierSchemeCodeUnspsc190501.52161555': __('identifierSchemeCodeUnspsc190501.52161555'),
	'identifierSchemeCodeUnspsc190501.52161556': __('identifierSchemeCodeUnspsc190501.52161556'),
	'identifierSchemeCodeUnspsc190501.52161557': __('identifierSchemeCodeUnspsc190501.52161557'),
	'identifierSchemeCodeUnspsc190501.52161558': __('identifierSchemeCodeUnspsc190501.52161558'),
	'identifierSchemeCodeUnspsc190501.52161559': __('identifierSchemeCodeUnspsc190501.52161559'),
	'identifierSchemeCodeUnspsc190501.52161560': __('identifierSchemeCodeUnspsc190501.52161560'),
	'identifierSchemeCodeUnspsc190501.52161561': __('identifierSchemeCodeUnspsc190501.52161561'),
	'identifierSchemeCodeUnspsc190501.52161562': __('identifierSchemeCodeUnspsc190501.52161562'),
	'identifierSchemeCodeUnspsc190501.52161563': __('identifierSchemeCodeUnspsc190501.52161563'),
	'identifierSchemeCodeUnspsc190501.52161600': __('identifierSchemeCodeUnspsc190501.52161600'),
	'identifierSchemeCodeUnspsc190501.52161601': __('identifierSchemeCodeUnspsc190501.52161601'),
	'identifierSchemeCodeUnspsc190501.52161602': __('identifierSchemeCodeUnspsc190501.52161602'),
	'identifierSchemeCodeUnspsc190501.52161603': __('identifierSchemeCodeUnspsc190501.52161603'),
	'identifierSchemeCodeUnspsc190501.52161604': __('identifierSchemeCodeUnspsc190501.52161604'),
	'identifierSchemeCodeUnspsc190501.52161605': __('identifierSchemeCodeUnspsc190501.52161605'),
	'identifierSchemeCodeUnspsc190501.52161606': __('identifierSchemeCodeUnspsc190501.52161606'),
	'identifierSchemeCodeUnspsc190501.52161607': __('identifierSchemeCodeUnspsc190501.52161607'),
	'identifierSchemeCodeUnspsc190501.52161608': __('identifierSchemeCodeUnspsc190501.52161608'),
	'identifierSchemeCodeUnspsc190501.52161609': __('identifierSchemeCodeUnspsc190501.52161609'),
	'identifierSchemeCodeUnspsc190501.52161610': __('identifierSchemeCodeUnspsc190501.52161610'),
	'identifierSchemeCodeUnspsc190501.52161611': __('identifierSchemeCodeUnspsc190501.52161611'),
	'identifierSchemeCodeUnspsc190501.52170000': __('identifierSchemeCodeUnspsc190501.52170000'),
	'identifierSchemeCodeUnspsc190501.52171000': __('identifierSchemeCodeUnspsc190501.52171000'),
	'identifierSchemeCodeUnspsc190501.52171001': __('identifierSchemeCodeUnspsc190501.52171001'),
	'identifierSchemeCodeUnspsc190501.52171002': __('identifierSchemeCodeUnspsc190501.52171002'),
	'identifierSchemeCodeUnspsc190501.53000000': __('identifierSchemeCodeUnspsc190501.53000000'),
	'identifierSchemeCodeUnspsc190501.53100000': __('identifierSchemeCodeUnspsc190501.53100000'),
	'identifierSchemeCodeUnspsc190501.53101500': __('identifierSchemeCodeUnspsc190501.53101500'),
	'identifierSchemeCodeUnspsc190501.53101501': __('identifierSchemeCodeUnspsc190501.53101501'),
	'identifierSchemeCodeUnspsc190501.53101502': __('identifierSchemeCodeUnspsc190501.53101502'),
	'identifierSchemeCodeUnspsc190501.53101503': __('identifierSchemeCodeUnspsc190501.53101503'),
	'identifierSchemeCodeUnspsc190501.53101504': __('identifierSchemeCodeUnspsc190501.53101504'),
	'identifierSchemeCodeUnspsc190501.53101505': __('identifierSchemeCodeUnspsc190501.53101505'),
	'identifierSchemeCodeUnspsc190501.53101600': __('identifierSchemeCodeUnspsc190501.53101600'),
	'identifierSchemeCodeUnspsc190501.53101601': __('identifierSchemeCodeUnspsc190501.53101601'),
	'identifierSchemeCodeUnspsc190501.53101602': __('identifierSchemeCodeUnspsc190501.53101602'),
	'identifierSchemeCodeUnspsc190501.53101603': __('identifierSchemeCodeUnspsc190501.53101603'),
	'identifierSchemeCodeUnspsc190501.53101604': __('identifierSchemeCodeUnspsc190501.53101604'),
	'identifierSchemeCodeUnspsc190501.53101605': __('identifierSchemeCodeUnspsc190501.53101605'),
	'identifierSchemeCodeUnspsc190501.53101700': __('identifierSchemeCodeUnspsc190501.53101700'),
	'identifierSchemeCodeUnspsc190501.53101701': __('identifierSchemeCodeUnspsc190501.53101701'),
	'identifierSchemeCodeUnspsc190501.53101702': __('identifierSchemeCodeUnspsc190501.53101702'),
	'identifierSchemeCodeUnspsc190501.53101703': __('identifierSchemeCodeUnspsc190501.53101703'),
	'identifierSchemeCodeUnspsc190501.53101704': __('identifierSchemeCodeUnspsc190501.53101704'),
	'identifierSchemeCodeUnspsc190501.53101705': __('identifierSchemeCodeUnspsc190501.53101705'),
	'identifierSchemeCodeUnspsc190501.53101800': __('identifierSchemeCodeUnspsc190501.53101800'),
	'identifierSchemeCodeUnspsc190501.53101801': __('identifierSchemeCodeUnspsc190501.53101801'),
	'identifierSchemeCodeUnspsc190501.53101802': __('identifierSchemeCodeUnspsc190501.53101802'),
	'identifierSchemeCodeUnspsc190501.53101803': __('identifierSchemeCodeUnspsc190501.53101803'),
	'identifierSchemeCodeUnspsc190501.53101804': __('identifierSchemeCodeUnspsc190501.53101804'),
	'identifierSchemeCodeUnspsc190501.53101805': __('identifierSchemeCodeUnspsc190501.53101805'),
	'identifierSchemeCodeUnspsc190501.53101806': __('identifierSchemeCodeUnspsc190501.53101806'),
	'identifierSchemeCodeUnspsc190501.53101900': __('identifierSchemeCodeUnspsc190501.53101900'),
	'identifierSchemeCodeUnspsc190501.53101901': __('identifierSchemeCodeUnspsc190501.53101901'),
	'identifierSchemeCodeUnspsc190501.53101902': __('identifierSchemeCodeUnspsc190501.53101902'),
	'identifierSchemeCodeUnspsc190501.53101903': __('identifierSchemeCodeUnspsc190501.53101903'),
	'identifierSchemeCodeUnspsc190501.53101904': __('identifierSchemeCodeUnspsc190501.53101904'),
	'identifierSchemeCodeUnspsc190501.53101905': __('identifierSchemeCodeUnspsc190501.53101905'),
	'identifierSchemeCodeUnspsc190501.53102000': __('identifierSchemeCodeUnspsc190501.53102000'),
	'identifierSchemeCodeUnspsc190501.53102001': __('identifierSchemeCodeUnspsc190501.53102001'),
	'identifierSchemeCodeUnspsc190501.53102002': __('identifierSchemeCodeUnspsc190501.53102002'),
	'identifierSchemeCodeUnspsc190501.53102003': __('identifierSchemeCodeUnspsc190501.53102003'),
	'identifierSchemeCodeUnspsc190501.53102100': __('identifierSchemeCodeUnspsc190501.53102100'),
	'identifierSchemeCodeUnspsc190501.53102101': __('identifierSchemeCodeUnspsc190501.53102101'),
	'identifierSchemeCodeUnspsc190501.53102102': __('identifierSchemeCodeUnspsc190501.53102102'),
	'identifierSchemeCodeUnspsc190501.53102103': __('identifierSchemeCodeUnspsc190501.53102103'),
	'identifierSchemeCodeUnspsc190501.53102104': __('identifierSchemeCodeUnspsc190501.53102104'),
	'identifierSchemeCodeUnspsc190501.53102105': __('identifierSchemeCodeUnspsc190501.53102105'),
	'identifierSchemeCodeUnspsc190501.53102200': __('identifierSchemeCodeUnspsc190501.53102200'),
	'identifierSchemeCodeUnspsc190501.53102201': __('identifierSchemeCodeUnspsc190501.53102201'),
	'identifierSchemeCodeUnspsc190501.53102202': __('identifierSchemeCodeUnspsc190501.53102202'),
	'identifierSchemeCodeUnspsc190501.53102203': __('identifierSchemeCodeUnspsc190501.53102203'),
	'identifierSchemeCodeUnspsc190501.53102204': __('identifierSchemeCodeUnspsc190501.53102204'),
	'identifierSchemeCodeUnspsc190501.53102205': __('identifierSchemeCodeUnspsc190501.53102205'),
	'identifierSchemeCodeUnspsc190501.53102300': __('identifierSchemeCodeUnspsc190501.53102300'),
	'identifierSchemeCodeUnspsc190501.53102301': __('identifierSchemeCodeUnspsc190501.53102301'),
	'identifierSchemeCodeUnspsc190501.53102302': __('identifierSchemeCodeUnspsc190501.53102302'),
	'identifierSchemeCodeUnspsc190501.53102303': __('identifierSchemeCodeUnspsc190501.53102303'),
	'identifierSchemeCodeUnspsc190501.53102304': __('identifierSchemeCodeUnspsc190501.53102304'),
	'identifierSchemeCodeUnspsc190501.53102305': __('identifierSchemeCodeUnspsc190501.53102305'),
	'identifierSchemeCodeUnspsc190501.53102306': __('identifierSchemeCodeUnspsc190501.53102306'),
	'identifierSchemeCodeUnspsc190501.53102307': __('identifierSchemeCodeUnspsc190501.53102307'),
	'identifierSchemeCodeUnspsc190501.53102308': __('identifierSchemeCodeUnspsc190501.53102308'),
	'identifierSchemeCodeUnspsc190501.53102309': __('identifierSchemeCodeUnspsc190501.53102309'),
	'identifierSchemeCodeUnspsc190501.53102310': __('identifierSchemeCodeUnspsc190501.53102310'),
	'identifierSchemeCodeUnspsc190501.53102400': __('identifierSchemeCodeUnspsc190501.53102400'),
	'identifierSchemeCodeUnspsc190501.53102401': __('identifierSchemeCodeUnspsc190501.53102401'),
	'identifierSchemeCodeUnspsc190501.53102402': __('identifierSchemeCodeUnspsc190501.53102402'),
	'identifierSchemeCodeUnspsc190501.53102403': __('identifierSchemeCodeUnspsc190501.53102403'),
	'identifierSchemeCodeUnspsc190501.53102404': __('identifierSchemeCodeUnspsc190501.53102404'),
	'identifierSchemeCodeUnspsc190501.53102500': __('identifierSchemeCodeUnspsc190501.53102500'),
	'identifierSchemeCodeUnspsc190501.53102501': __('identifierSchemeCodeUnspsc190501.53102501'),
	'identifierSchemeCodeUnspsc190501.53102502': __('identifierSchemeCodeUnspsc190501.53102502'),
	'identifierSchemeCodeUnspsc190501.53102503': __('identifierSchemeCodeUnspsc190501.53102503'),
	'identifierSchemeCodeUnspsc190501.53102504': __('identifierSchemeCodeUnspsc190501.53102504'),
	'identifierSchemeCodeUnspsc190501.53102505': __('identifierSchemeCodeUnspsc190501.53102505'),
	'identifierSchemeCodeUnspsc190501.53102506': __('identifierSchemeCodeUnspsc190501.53102506'),
	'identifierSchemeCodeUnspsc190501.53102507': __('identifierSchemeCodeUnspsc190501.53102507'),
	'identifierSchemeCodeUnspsc190501.53102508': __('identifierSchemeCodeUnspsc190501.53102508'),
	'identifierSchemeCodeUnspsc190501.53102509': __('identifierSchemeCodeUnspsc190501.53102509'),
	'identifierSchemeCodeUnspsc190501.53102510': __('identifierSchemeCodeUnspsc190501.53102510'),
	'identifierSchemeCodeUnspsc190501.53102511': __('identifierSchemeCodeUnspsc190501.53102511'),
	'identifierSchemeCodeUnspsc190501.53102512': __('identifierSchemeCodeUnspsc190501.53102512'),
	'identifierSchemeCodeUnspsc190501.53102513': __('identifierSchemeCodeUnspsc190501.53102513'),
	'identifierSchemeCodeUnspsc190501.53102514': __('identifierSchemeCodeUnspsc190501.53102514'),
	'identifierSchemeCodeUnspsc190501.53102515': __('identifierSchemeCodeUnspsc190501.53102515'),
	'identifierSchemeCodeUnspsc190501.53102516': __('identifierSchemeCodeUnspsc190501.53102516'),
	'identifierSchemeCodeUnspsc190501.53102517': __('identifierSchemeCodeUnspsc190501.53102517'),
	'identifierSchemeCodeUnspsc190501.53102518': __('identifierSchemeCodeUnspsc190501.53102518'),
	'identifierSchemeCodeUnspsc190501.53102519': __('identifierSchemeCodeUnspsc190501.53102519'),
	'identifierSchemeCodeUnspsc190501.53102520': __('identifierSchemeCodeUnspsc190501.53102520'),
	'identifierSchemeCodeUnspsc190501.53102521': __('identifierSchemeCodeUnspsc190501.53102521'),
	'identifierSchemeCodeUnspsc190501.53102600': __('identifierSchemeCodeUnspsc190501.53102600'),
	'identifierSchemeCodeUnspsc190501.53102601': __('identifierSchemeCodeUnspsc190501.53102601'),
	'identifierSchemeCodeUnspsc190501.53102602': __('identifierSchemeCodeUnspsc190501.53102602'),
	'identifierSchemeCodeUnspsc190501.53102603': __('identifierSchemeCodeUnspsc190501.53102603'),
	'identifierSchemeCodeUnspsc190501.53102604': __('identifierSchemeCodeUnspsc190501.53102604'),
	'identifierSchemeCodeUnspsc190501.53102605': __('identifierSchemeCodeUnspsc190501.53102605'),
	'identifierSchemeCodeUnspsc190501.53102606': __('identifierSchemeCodeUnspsc190501.53102606'),
	'identifierSchemeCodeUnspsc190501.53102700': __('identifierSchemeCodeUnspsc190501.53102700'),
	'identifierSchemeCodeUnspsc190501.53102701': __('identifierSchemeCodeUnspsc190501.53102701'),
	'identifierSchemeCodeUnspsc190501.53102702': __('identifierSchemeCodeUnspsc190501.53102702'),
	'identifierSchemeCodeUnspsc190501.53102703': __('identifierSchemeCodeUnspsc190501.53102703'),
	'identifierSchemeCodeUnspsc190501.53102704': __('identifierSchemeCodeUnspsc190501.53102704'),
	'identifierSchemeCodeUnspsc190501.53102705': __('identifierSchemeCodeUnspsc190501.53102705'),
	'identifierSchemeCodeUnspsc190501.53102706': __('identifierSchemeCodeUnspsc190501.53102706'),
	'identifierSchemeCodeUnspsc190501.53102707': __('identifierSchemeCodeUnspsc190501.53102707'),
	'identifierSchemeCodeUnspsc190501.53102708': __('identifierSchemeCodeUnspsc190501.53102708'),
	'identifierSchemeCodeUnspsc190501.53102709': __('identifierSchemeCodeUnspsc190501.53102709'),
	'identifierSchemeCodeUnspsc190501.53102710': __('identifierSchemeCodeUnspsc190501.53102710'),
	'identifierSchemeCodeUnspsc190501.53102711': __('identifierSchemeCodeUnspsc190501.53102711'),
	'identifierSchemeCodeUnspsc190501.53102712': __('identifierSchemeCodeUnspsc190501.53102712'),
	'identifierSchemeCodeUnspsc190501.53102713': __('identifierSchemeCodeUnspsc190501.53102713'),
	'identifierSchemeCodeUnspsc190501.53102714': __('identifierSchemeCodeUnspsc190501.53102714'),
	'identifierSchemeCodeUnspsc190501.53102715': __('identifierSchemeCodeUnspsc190501.53102715'),
	'identifierSchemeCodeUnspsc190501.53102716': __('identifierSchemeCodeUnspsc190501.53102716'),
	'identifierSchemeCodeUnspsc190501.53102717': __('identifierSchemeCodeUnspsc190501.53102717'),
	'identifierSchemeCodeUnspsc190501.53102718': __('identifierSchemeCodeUnspsc190501.53102718'),
	'identifierSchemeCodeUnspsc190501.53102800': __('identifierSchemeCodeUnspsc190501.53102800'),
	'identifierSchemeCodeUnspsc190501.53102801': __('identifierSchemeCodeUnspsc190501.53102801'),
	'identifierSchemeCodeUnspsc190501.53102802': __('identifierSchemeCodeUnspsc190501.53102802'),
	'identifierSchemeCodeUnspsc190501.53102803': __('identifierSchemeCodeUnspsc190501.53102803'),
	'identifierSchemeCodeUnspsc190501.53102804': __('identifierSchemeCodeUnspsc190501.53102804'),
	'identifierSchemeCodeUnspsc190501.53102805': __('identifierSchemeCodeUnspsc190501.53102805'),
	'identifierSchemeCodeUnspsc190501.53102900': __('identifierSchemeCodeUnspsc190501.53102900'),
	'identifierSchemeCodeUnspsc190501.53102901': __('identifierSchemeCodeUnspsc190501.53102901'),
	'identifierSchemeCodeUnspsc190501.53102902': __('identifierSchemeCodeUnspsc190501.53102902'),
	'identifierSchemeCodeUnspsc190501.53102903': __('identifierSchemeCodeUnspsc190501.53102903'),
	'identifierSchemeCodeUnspsc190501.53102904': __('identifierSchemeCodeUnspsc190501.53102904'),
	'identifierSchemeCodeUnspsc190501.53103000': __('identifierSchemeCodeUnspsc190501.53103000'),
	'identifierSchemeCodeUnspsc190501.53103001': __('identifierSchemeCodeUnspsc190501.53103001'),
	'identifierSchemeCodeUnspsc190501.53103002': __('identifierSchemeCodeUnspsc190501.53103002'),
	'identifierSchemeCodeUnspsc190501.53103003': __('identifierSchemeCodeUnspsc190501.53103003'),
	'identifierSchemeCodeUnspsc190501.53103100': __('identifierSchemeCodeUnspsc190501.53103100'),
	'identifierSchemeCodeUnspsc190501.53103101': __('identifierSchemeCodeUnspsc190501.53103101'),
	'identifierSchemeCodeUnspsc190501.53103102': __('identifierSchemeCodeUnspsc190501.53103102'),
	'identifierSchemeCodeUnspsc190501.53103200': __('identifierSchemeCodeUnspsc190501.53103200'),
	'identifierSchemeCodeUnspsc190501.53103201': __('identifierSchemeCodeUnspsc190501.53103201'),
	'identifierSchemeCodeUnspsc190501.53110000': __('identifierSchemeCodeUnspsc190501.53110000'),
	'identifierSchemeCodeUnspsc190501.53111500': __('identifierSchemeCodeUnspsc190501.53111500'),
	'identifierSchemeCodeUnspsc190501.53111501': __('identifierSchemeCodeUnspsc190501.53111501'),
	'identifierSchemeCodeUnspsc190501.53111502': __('identifierSchemeCodeUnspsc190501.53111502'),
	'identifierSchemeCodeUnspsc190501.53111503': __('identifierSchemeCodeUnspsc190501.53111503'),
	'identifierSchemeCodeUnspsc190501.53111504': __('identifierSchemeCodeUnspsc190501.53111504'),
	'identifierSchemeCodeUnspsc190501.53111505': __('identifierSchemeCodeUnspsc190501.53111505'),
	'identifierSchemeCodeUnspsc190501.53111600': __('identifierSchemeCodeUnspsc190501.53111600'),
	'identifierSchemeCodeUnspsc190501.53111601': __('identifierSchemeCodeUnspsc190501.53111601'),
	'identifierSchemeCodeUnspsc190501.53111602': __('identifierSchemeCodeUnspsc190501.53111602'),
	'identifierSchemeCodeUnspsc190501.53111603': __('identifierSchemeCodeUnspsc190501.53111603'),
	'identifierSchemeCodeUnspsc190501.53111604': __('identifierSchemeCodeUnspsc190501.53111604'),
	'identifierSchemeCodeUnspsc190501.53111605': __('identifierSchemeCodeUnspsc190501.53111605'),
	'identifierSchemeCodeUnspsc190501.53111700': __('identifierSchemeCodeUnspsc190501.53111700'),
	'identifierSchemeCodeUnspsc190501.53111701': __('identifierSchemeCodeUnspsc190501.53111701'),
	'identifierSchemeCodeUnspsc190501.53111702': __('identifierSchemeCodeUnspsc190501.53111702'),
	'identifierSchemeCodeUnspsc190501.53111703': __('identifierSchemeCodeUnspsc190501.53111703'),
	'identifierSchemeCodeUnspsc190501.53111704': __('identifierSchemeCodeUnspsc190501.53111704'),
	'identifierSchemeCodeUnspsc190501.53111705': __('identifierSchemeCodeUnspsc190501.53111705'),
	'identifierSchemeCodeUnspsc190501.53111800': __('identifierSchemeCodeUnspsc190501.53111800'),
	'identifierSchemeCodeUnspsc190501.53111801': __('identifierSchemeCodeUnspsc190501.53111801'),
	'identifierSchemeCodeUnspsc190501.53111802': __('identifierSchemeCodeUnspsc190501.53111802'),
	'identifierSchemeCodeUnspsc190501.53111803': __('identifierSchemeCodeUnspsc190501.53111803'),
	'identifierSchemeCodeUnspsc190501.53111804': __('identifierSchemeCodeUnspsc190501.53111804'),
	'identifierSchemeCodeUnspsc190501.53111805': __('identifierSchemeCodeUnspsc190501.53111805'),
	'identifierSchemeCodeUnspsc190501.53111900': __('identifierSchemeCodeUnspsc190501.53111900'),
	'identifierSchemeCodeUnspsc190501.53111901': __('identifierSchemeCodeUnspsc190501.53111901'),
	'identifierSchemeCodeUnspsc190501.53111902': __('identifierSchemeCodeUnspsc190501.53111902'),
	'identifierSchemeCodeUnspsc190501.53111903': __('identifierSchemeCodeUnspsc190501.53111903'),
	'identifierSchemeCodeUnspsc190501.53111904': __('identifierSchemeCodeUnspsc190501.53111904'),
	'identifierSchemeCodeUnspsc190501.53111905': __('identifierSchemeCodeUnspsc190501.53111905'),
	'identifierSchemeCodeUnspsc190501.53112000': __('identifierSchemeCodeUnspsc190501.53112000'),
	'identifierSchemeCodeUnspsc190501.53112001': __('identifierSchemeCodeUnspsc190501.53112001'),
	'identifierSchemeCodeUnspsc190501.53112002': __('identifierSchemeCodeUnspsc190501.53112002'),
	'identifierSchemeCodeUnspsc190501.53112003': __('identifierSchemeCodeUnspsc190501.53112003'),
	'identifierSchemeCodeUnspsc190501.53112004': __('identifierSchemeCodeUnspsc190501.53112004'),
	'identifierSchemeCodeUnspsc190501.53112005': __('identifierSchemeCodeUnspsc190501.53112005'),
	'identifierSchemeCodeUnspsc190501.53112006': __('identifierSchemeCodeUnspsc190501.53112006'),
	'identifierSchemeCodeUnspsc190501.53112100': __('identifierSchemeCodeUnspsc190501.53112100'),
	'identifierSchemeCodeUnspsc190501.53112101': __('identifierSchemeCodeUnspsc190501.53112101'),
	'identifierSchemeCodeUnspsc190501.53112102': __('identifierSchemeCodeUnspsc190501.53112102'),
	'identifierSchemeCodeUnspsc190501.53112103': __('identifierSchemeCodeUnspsc190501.53112103'),
	'identifierSchemeCodeUnspsc190501.53112104': __('identifierSchemeCodeUnspsc190501.53112104'),
	'identifierSchemeCodeUnspsc190501.53112105': __('identifierSchemeCodeUnspsc190501.53112105'),
	'identifierSchemeCodeUnspsc190501.53120000': __('identifierSchemeCodeUnspsc190501.53120000'),
	'identifierSchemeCodeUnspsc190501.53121500': __('identifierSchemeCodeUnspsc190501.53121500'),
	'identifierSchemeCodeUnspsc190501.53121501': __('identifierSchemeCodeUnspsc190501.53121501'),
	'identifierSchemeCodeUnspsc190501.53121502': __('identifierSchemeCodeUnspsc190501.53121502'),
	'identifierSchemeCodeUnspsc190501.53121503': __('identifierSchemeCodeUnspsc190501.53121503'),
	'identifierSchemeCodeUnspsc190501.53121600': __('identifierSchemeCodeUnspsc190501.53121600'),
	'identifierSchemeCodeUnspsc190501.53121601': __('identifierSchemeCodeUnspsc190501.53121601'),
	'identifierSchemeCodeUnspsc190501.53121602': __('identifierSchemeCodeUnspsc190501.53121602'),
	'identifierSchemeCodeUnspsc190501.53121603': __('identifierSchemeCodeUnspsc190501.53121603'),
	'identifierSchemeCodeUnspsc190501.53121605': __('identifierSchemeCodeUnspsc190501.53121605'),
	'identifierSchemeCodeUnspsc190501.53121606': __('identifierSchemeCodeUnspsc190501.53121606'),
	'identifierSchemeCodeUnspsc190501.53121607': __('identifierSchemeCodeUnspsc190501.53121607'),
	'identifierSchemeCodeUnspsc190501.53121608': __('identifierSchemeCodeUnspsc190501.53121608'),
	'identifierSchemeCodeUnspsc190501.53121700': __('identifierSchemeCodeUnspsc190501.53121700'),
	'identifierSchemeCodeUnspsc190501.53121701': __('identifierSchemeCodeUnspsc190501.53121701'),
	'identifierSchemeCodeUnspsc190501.53121702': __('identifierSchemeCodeUnspsc190501.53121702'),
	'identifierSchemeCodeUnspsc190501.53121704': __('identifierSchemeCodeUnspsc190501.53121704'),
	'identifierSchemeCodeUnspsc190501.53121705': __('identifierSchemeCodeUnspsc190501.53121705'),
	'identifierSchemeCodeUnspsc190501.53121706': __('identifierSchemeCodeUnspsc190501.53121706'),
	'identifierSchemeCodeUnspsc190501.53121800': __('identifierSchemeCodeUnspsc190501.53121800'),
	'identifierSchemeCodeUnspsc190501.53121801': __('identifierSchemeCodeUnspsc190501.53121801'),
	'identifierSchemeCodeUnspsc190501.53121802': __('identifierSchemeCodeUnspsc190501.53121802'),
	'identifierSchemeCodeUnspsc190501.53121803': __('identifierSchemeCodeUnspsc190501.53121803'),
	'identifierSchemeCodeUnspsc190501.53121804': __('identifierSchemeCodeUnspsc190501.53121804'),
	'identifierSchemeCodeUnspsc190501.53130000': __('identifierSchemeCodeUnspsc190501.53130000'),
	'identifierSchemeCodeUnspsc190501.53131500': __('identifierSchemeCodeUnspsc190501.53131500'),
	'identifierSchemeCodeUnspsc190501.53131501': __('identifierSchemeCodeUnspsc190501.53131501'),
	'identifierSchemeCodeUnspsc190501.53131502': __('identifierSchemeCodeUnspsc190501.53131502'),
	'identifierSchemeCodeUnspsc190501.53131503': __('identifierSchemeCodeUnspsc190501.53131503'),
	'identifierSchemeCodeUnspsc190501.53131504': __('identifierSchemeCodeUnspsc190501.53131504'),
	'identifierSchemeCodeUnspsc190501.53131505': __('identifierSchemeCodeUnspsc190501.53131505'),
	'identifierSchemeCodeUnspsc190501.53131506': __('identifierSchemeCodeUnspsc190501.53131506'),
	'identifierSchemeCodeUnspsc190501.53131507': __('identifierSchemeCodeUnspsc190501.53131507'),
	'identifierSchemeCodeUnspsc190501.53131508': __('identifierSchemeCodeUnspsc190501.53131508'),
	'identifierSchemeCodeUnspsc190501.53131509': __('identifierSchemeCodeUnspsc190501.53131509'),
	'identifierSchemeCodeUnspsc190501.53131510': __('identifierSchemeCodeUnspsc190501.53131510'),
	'identifierSchemeCodeUnspsc190501.53131600': __('identifierSchemeCodeUnspsc190501.53131600'),
	'identifierSchemeCodeUnspsc190501.53131601': __('identifierSchemeCodeUnspsc190501.53131601'),
	'identifierSchemeCodeUnspsc190501.53131602': __('identifierSchemeCodeUnspsc190501.53131602'),
	'identifierSchemeCodeUnspsc190501.53131603': __('identifierSchemeCodeUnspsc190501.53131603'),
	'identifierSchemeCodeUnspsc190501.53131604': __('identifierSchemeCodeUnspsc190501.53131604'),
	'identifierSchemeCodeUnspsc190501.53131605': __('identifierSchemeCodeUnspsc190501.53131605'),
	'identifierSchemeCodeUnspsc190501.53131606': __('identifierSchemeCodeUnspsc190501.53131606'),
	'identifierSchemeCodeUnspsc190501.53131607': __('identifierSchemeCodeUnspsc190501.53131607'),
	'identifierSchemeCodeUnspsc190501.53131608': __('identifierSchemeCodeUnspsc190501.53131608'),
	'identifierSchemeCodeUnspsc190501.53131609': __('identifierSchemeCodeUnspsc190501.53131609'),
	'identifierSchemeCodeUnspsc190501.53131610': __('identifierSchemeCodeUnspsc190501.53131610'),
	'identifierSchemeCodeUnspsc190501.53131611': __('identifierSchemeCodeUnspsc190501.53131611'),
	'identifierSchemeCodeUnspsc190501.53131612': __('identifierSchemeCodeUnspsc190501.53131612'),
	'identifierSchemeCodeUnspsc190501.53131613': __('identifierSchemeCodeUnspsc190501.53131613'),
	'identifierSchemeCodeUnspsc190501.53131614': __('identifierSchemeCodeUnspsc190501.53131614'),
	'identifierSchemeCodeUnspsc190501.53131615': __('identifierSchemeCodeUnspsc190501.53131615'),
	'identifierSchemeCodeUnspsc190501.53131616': __('identifierSchemeCodeUnspsc190501.53131616'),
	'identifierSchemeCodeUnspsc190501.53131617': __('identifierSchemeCodeUnspsc190501.53131617'),
	'identifierSchemeCodeUnspsc190501.53131618': __('identifierSchemeCodeUnspsc190501.53131618'),
	'identifierSchemeCodeUnspsc190501.53131619': __('identifierSchemeCodeUnspsc190501.53131619'),
	'identifierSchemeCodeUnspsc190501.53131620': __('identifierSchemeCodeUnspsc190501.53131620'),
	'identifierSchemeCodeUnspsc190501.53131621': __('identifierSchemeCodeUnspsc190501.53131621'),
	'identifierSchemeCodeUnspsc190501.53131622': __('identifierSchemeCodeUnspsc190501.53131622'),
	'identifierSchemeCodeUnspsc190501.53131623': __('identifierSchemeCodeUnspsc190501.53131623'),
	'identifierSchemeCodeUnspsc190501.53131624': __('identifierSchemeCodeUnspsc190501.53131624'),
	'identifierSchemeCodeUnspsc190501.53131625': __('identifierSchemeCodeUnspsc190501.53131625'),
	'identifierSchemeCodeUnspsc190501.53131626': __('identifierSchemeCodeUnspsc190501.53131626'),
	'identifierSchemeCodeUnspsc190501.53131627': __('identifierSchemeCodeUnspsc190501.53131627'),
	'identifierSchemeCodeUnspsc190501.53131628': __('identifierSchemeCodeUnspsc190501.53131628'),
	'identifierSchemeCodeUnspsc190501.53131629': __('identifierSchemeCodeUnspsc190501.53131629'),
	'identifierSchemeCodeUnspsc190501.53131630': __('identifierSchemeCodeUnspsc190501.53131630'),
	'identifierSchemeCodeUnspsc190501.53131631': __('identifierSchemeCodeUnspsc190501.53131631'),
	'identifierSchemeCodeUnspsc190501.53131632': __('identifierSchemeCodeUnspsc190501.53131632'),
	'identifierSchemeCodeUnspsc190501.53131633': __('identifierSchemeCodeUnspsc190501.53131633'),
	'identifierSchemeCodeUnspsc190501.53131634': __('identifierSchemeCodeUnspsc190501.53131634'),
	'identifierSchemeCodeUnspsc190501.53131635': __('identifierSchemeCodeUnspsc190501.53131635'),
	'identifierSchemeCodeUnspsc190501.53131636': __('identifierSchemeCodeUnspsc190501.53131636'),
	'identifierSchemeCodeUnspsc190501.53131637': __('identifierSchemeCodeUnspsc190501.53131637'),
	'identifierSchemeCodeUnspsc190501.53131638': __('identifierSchemeCodeUnspsc190501.53131638'),
	'identifierSchemeCodeUnspsc190501.53131639': __('identifierSchemeCodeUnspsc190501.53131639'),
	'identifierSchemeCodeUnspsc190501.53131640': __('identifierSchemeCodeUnspsc190501.53131640'),
	'identifierSchemeCodeUnspsc190501.53131641': __('identifierSchemeCodeUnspsc190501.53131641'),
	'identifierSchemeCodeUnspsc190501.53131642': __('identifierSchemeCodeUnspsc190501.53131642'),
	'identifierSchemeCodeUnspsc190501.53131643': __('identifierSchemeCodeUnspsc190501.53131643'),
	'identifierSchemeCodeUnspsc190501.53131644': __('identifierSchemeCodeUnspsc190501.53131644'),
	'identifierSchemeCodeUnspsc190501.53131645': __('identifierSchemeCodeUnspsc190501.53131645'),
	'identifierSchemeCodeUnspsc190501.53131646': __('identifierSchemeCodeUnspsc190501.53131646'),
	'identifierSchemeCodeUnspsc190501.53131647': __('identifierSchemeCodeUnspsc190501.53131647'),
	'identifierSchemeCodeUnspsc190501.53131648': __('identifierSchemeCodeUnspsc190501.53131648'),
	'identifierSchemeCodeUnspsc190501.53131649': __('identifierSchemeCodeUnspsc190501.53131649'),
	'identifierSchemeCodeUnspsc190501.53131650': __('identifierSchemeCodeUnspsc190501.53131650'),
	'identifierSchemeCodeUnspsc190501.53131651': __('identifierSchemeCodeUnspsc190501.53131651'),
	'identifierSchemeCodeUnspsc190501.53131700': __('identifierSchemeCodeUnspsc190501.53131700'),
	'identifierSchemeCodeUnspsc190501.53131701': __('identifierSchemeCodeUnspsc190501.53131701'),
	'identifierSchemeCodeUnspsc190501.53131702': __('identifierSchemeCodeUnspsc190501.53131702'),
	'identifierSchemeCodeUnspsc190501.53131800': __('identifierSchemeCodeUnspsc190501.53131800'),
	'identifierSchemeCodeUnspsc190501.53131801': __('identifierSchemeCodeUnspsc190501.53131801'),
	'identifierSchemeCodeUnspsc190501.53131802': __('identifierSchemeCodeUnspsc190501.53131802'),
	'identifierSchemeCodeUnspsc190501.53140000': __('identifierSchemeCodeUnspsc190501.53140000'),
	'identifierSchemeCodeUnspsc190501.53141500': __('identifierSchemeCodeUnspsc190501.53141500'),
	'identifierSchemeCodeUnspsc190501.53141501': __('identifierSchemeCodeUnspsc190501.53141501'),
	'identifierSchemeCodeUnspsc190501.53141502': __('identifierSchemeCodeUnspsc190501.53141502'),
	'identifierSchemeCodeUnspsc190501.53141503': __('identifierSchemeCodeUnspsc190501.53141503'),
	'identifierSchemeCodeUnspsc190501.53141504': __('identifierSchemeCodeUnspsc190501.53141504'),
	'identifierSchemeCodeUnspsc190501.53141505': __('identifierSchemeCodeUnspsc190501.53141505'),
	'identifierSchemeCodeUnspsc190501.53141506': __('identifierSchemeCodeUnspsc190501.53141506'),
	'identifierSchemeCodeUnspsc190501.53141507': __('identifierSchemeCodeUnspsc190501.53141507'),
	'identifierSchemeCodeUnspsc190501.53141508': __('identifierSchemeCodeUnspsc190501.53141508'),
	'identifierSchemeCodeUnspsc190501.53141600': __('identifierSchemeCodeUnspsc190501.53141600'),
	'identifierSchemeCodeUnspsc190501.53141601': __('identifierSchemeCodeUnspsc190501.53141601'),
	'identifierSchemeCodeUnspsc190501.53141602': __('identifierSchemeCodeUnspsc190501.53141602'),
	'identifierSchemeCodeUnspsc190501.53141603': __('identifierSchemeCodeUnspsc190501.53141603'),
	'identifierSchemeCodeUnspsc190501.53141604': __('identifierSchemeCodeUnspsc190501.53141604'),
	'identifierSchemeCodeUnspsc190501.53141605': __('identifierSchemeCodeUnspsc190501.53141605'),
	'identifierSchemeCodeUnspsc190501.53141606': __('identifierSchemeCodeUnspsc190501.53141606'),
	'identifierSchemeCodeUnspsc190501.53141607': __('identifierSchemeCodeUnspsc190501.53141607'),
	'identifierSchemeCodeUnspsc190501.53141608': __('identifierSchemeCodeUnspsc190501.53141608'),
	'identifierSchemeCodeUnspsc190501.53141609': __('identifierSchemeCodeUnspsc190501.53141609'),
	'identifierSchemeCodeUnspsc190501.53141610': __('identifierSchemeCodeUnspsc190501.53141610'),
	'identifierSchemeCodeUnspsc190501.53141611': __('identifierSchemeCodeUnspsc190501.53141611'),
	'identifierSchemeCodeUnspsc190501.53141612': __('identifierSchemeCodeUnspsc190501.53141612'),
	'identifierSchemeCodeUnspsc190501.53141614': __('identifierSchemeCodeUnspsc190501.53141614'),
	'identifierSchemeCodeUnspsc190501.53141615': __('identifierSchemeCodeUnspsc190501.53141615'),
	'identifierSchemeCodeUnspsc190501.53141616': __('identifierSchemeCodeUnspsc190501.53141616'),
	'identifierSchemeCodeUnspsc190501.53141617': __('identifierSchemeCodeUnspsc190501.53141617'),
	'identifierSchemeCodeUnspsc190501.53141618': __('identifierSchemeCodeUnspsc190501.53141618'),
	'identifierSchemeCodeUnspsc190501.53141619': __('identifierSchemeCodeUnspsc190501.53141619'),
	'identifierSchemeCodeUnspsc190501.53141620': __('identifierSchemeCodeUnspsc190501.53141620'),
	'identifierSchemeCodeUnspsc190501.53141621': __('identifierSchemeCodeUnspsc190501.53141621'),
	'identifierSchemeCodeUnspsc190501.53141622': __('identifierSchemeCodeUnspsc190501.53141622'),
	'identifierSchemeCodeUnspsc190501.53141623': __('identifierSchemeCodeUnspsc190501.53141623'),
	'identifierSchemeCodeUnspsc190501.53141624': __('identifierSchemeCodeUnspsc190501.53141624'),
	'identifierSchemeCodeUnspsc190501.53141625': __('identifierSchemeCodeUnspsc190501.53141625'),
	'identifierSchemeCodeUnspsc190501.53141626': __('identifierSchemeCodeUnspsc190501.53141626'),
	'identifierSchemeCodeUnspsc190501.53141627': __('identifierSchemeCodeUnspsc190501.53141627'),
	'identifierSchemeCodeUnspsc190501.53141628': __('identifierSchemeCodeUnspsc190501.53141628'),
	'identifierSchemeCodeUnspsc190501.53141629': __('identifierSchemeCodeUnspsc190501.53141629'),
	'identifierSchemeCodeUnspsc190501.53141630': __('identifierSchemeCodeUnspsc190501.53141630'),
	'identifierSchemeCodeUnspsc190501.53141631': __('identifierSchemeCodeUnspsc190501.53141631'),
	'identifierSchemeCodeUnspsc190501.54000000': __('identifierSchemeCodeUnspsc190501.54000000'),
	'identifierSchemeCodeUnspsc190501.54100000': __('identifierSchemeCodeUnspsc190501.54100000'),
	'identifierSchemeCodeUnspsc190501.54101500': __('identifierSchemeCodeUnspsc190501.54101500'),
	'identifierSchemeCodeUnspsc190501.54101501': __('identifierSchemeCodeUnspsc190501.54101501'),
	'identifierSchemeCodeUnspsc190501.54101502': __('identifierSchemeCodeUnspsc190501.54101502'),
	'identifierSchemeCodeUnspsc190501.54101503': __('identifierSchemeCodeUnspsc190501.54101503'),
	'identifierSchemeCodeUnspsc190501.54101504': __('identifierSchemeCodeUnspsc190501.54101504'),
	'identifierSchemeCodeUnspsc190501.54101505': __('identifierSchemeCodeUnspsc190501.54101505'),
	'identifierSchemeCodeUnspsc190501.54101506': __('identifierSchemeCodeUnspsc190501.54101506'),
	'identifierSchemeCodeUnspsc190501.54101507': __('identifierSchemeCodeUnspsc190501.54101507'),
	'identifierSchemeCodeUnspsc190501.54101508': __('identifierSchemeCodeUnspsc190501.54101508'),
	'identifierSchemeCodeUnspsc190501.54101509': __('identifierSchemeCodeUnspsc190501.54101509'),
	'identifierSchemeCodeUnspsc190501.54101510': __('identifierSchemeCodeUnspsc190501.54101510'),
	'identifierSchemeCodeUnspsc190501.54101511': __('identifierSchemeCodeUnspsc190501.54101511'),
	'identifierSchemeCodeUnspsc190501.54101512': __('identifierSchemeCodeUnspsc190501.54101512'),
	'identifierSchemeCodeUnspsc190501.54101513': __('identifierSchemeCodeUnspsc190501.54101513'),
	'identifierSchemeCodeUnspsc190501.54101514': __('identifierSchemeCodeUnspsc190501.54101514'),
	'identifierSchemeCodeUnspsc190501.54101600': __('identifierSchemeCodeUnspsc190501.54101600'),
	'identifierSchemeCodeUnspsc190501.54101601': __('identifierSchemeCodeUnspsc190501.54101601'),
	'identifierSchemeCodeUnspsc190501.54101602': __('identifierSchemeCodeUnspsc190501.54101602'),
	'identifierSchemeCodeUnspsc190501.54101603': __('identifierSchemeCodeUnspsc190501.54101603'),
	'identifierSchemeCodeUnspsc190501.54101604': __('identifierSchemeCodeUnspsc190501.54101604'),
	'identifierSchemeCodeUnspsc190501.54101605': __('identifierSchemeCodeUnspsc190501.54101605'),
	'identifierSchemeCodeUnspsc190501.54101700': __('identifierSchemeCodeUnspsc190501.54101700'),
	'identifierSchemeCodeUnspsc190501.54101701': __('identifierSchemeCodeUnspsc190501.54101701'),
	'identifierSchemeCodeUnspsc190501.54101702': __('identifierSchemeCodeUnspsc190501.54101702'),
	'identifierSchemeCodeUnspsc190501.54101703': __('identifierSchemeCodeUnspsc190501.54101703'),
	'identifierSchemeCodeUnspsc190501.54101704': __('identifierSchemeCodeUnspsc190501.54101704'),
	'identifierSchemeCodeUnspsc190501.54101705': __('identifierSchemeCodeUnspsc190501.54101705'),
	'identifierSchemeCodeUnspsc190501.54101706': __('identifierSchemeCodeUnspsc190501.54101706'),
	'identifierSchemeCodeUnspsc190501.54110000': __('identifierSchemeCodeUnspsc190501.54110000'),
	'identifierSchemeCodeUnspsc190501.54111500': __('identifierSchemeCodeUnspsc190501.54111500'),
	'identifierSchemeCodeUnspsc190501.54111501': __('identifierSchemeCodeUnspsc190501.54111501'),
	'identifierSchemeCodeUnspsc190501.54111502': __('identifierSchemeCodeUnspsc190501.54111502'),
	'identifierSchemeCodeUnspsc190501.54111503': __('identifierSchemeCodeUnspsc190501.54111503'),
	'identifierSchemeCodeUnspsc190501.54111504': __('identifierSchemeCodeUnspsc190501.54111504'),
	'identifierSchemeCodeUnspsc190501.54111505': __('identifierSchemeCodeUnspsc190501.54111505'),
	'identifierSchemeCodeUnspsc190501.54111600': __('identifierSchemeCodeUnspsc190501.54111600'),
	'identifierSchemeCodeUnspsc190501.54111601': __('identifierSchemeCodeUnspsc190501.54111601'),
	'identifierSchemeCodeUnspsc190501.54111602': __('identifierSchemeCodeUnspsc190501.54111602'),
	'identifierSchemeCodeUnspsc190501.54111603': __('identifierSchemeCodeUnspsc190501.54111603'),
	'identifierSchemeCodeUnspsc190501.54111604': __('identifierSchemeCodeUnspsc190501.54111604'),
	'identifierSchemeCodeUnspsc190501.54111605': __('identifierSchemeCodeUnspsc190501.54111605'),
	'identifierSchemeCodeUnspsc190501.54111606': __('identifierSchemeCodeUnspsc190501.54111606'),
	'identifierSchemeCodeUnspsc190501.54111700': __('identifierSchemeCodeUnspsc190501.54111700'),
	'identifierSchemeCodeUnspsc190501.54111701': __('identifierSchemeCodeUnspsc190501.54111701'),
	'identifierSchemeCodeUnspsc190501.54111702': __('identifierSchemeCodeUnspsc190501.54111702'),
	'identifierSchemeCodeUnspsc190501.54111703': __('identifierSchemeCodeUnspsc190501.54111703'),
	'identifierSchemeCodeUnspsc190501.54111704': __('identifierSchemeCodeUnspsc190501.54111704'),
	'identifierSchemeCodeUnspsc190501.54111705': __('identifierSchemeCodeUnspsc190501.54111705'),
	'identifierSchemeCodeUnspsc190501.54111706': __('identifierSchemeCodeUnspsc190501.54111706'),
	'identifierSchemeCodeUnspsc190501.54111707': __('identifierSchemeCodeUnspsc190501.54111707'),
	'identifierSchemeCodeUnspsc190501.54111708': __('identifierSchemeCodeUnspsc190501.54111708'),
	'identifierSchemeCodeUnspsc190501.54111709': __('identifierSchemeCodeUnspsc190501.54111709'),
	'identifierSchemeCodeUnspsc190501.54111710': __('identifierSchemeCodeUnspsc190501.54111710'),
	'identifierSchemeCodeUnspsc190501.54120000': __('identifierSchemeCodeUnspsc190501.54120000'),
	'identifierSchemeCodeUnspsc190501.54121500': __('identifierSchemeCodeUnspsc190501.54121500'),
	'identifierSchemeCodeUnspsc190501.54121501': __('identifierSchemeCodeUnspsc190501.54121501'),
	'identifierSchemeCodeUnspsc190501.54121502': __('identifierSchemeCodeUnspsc190501.54121502'),
	'identifierSchemeCodeUnspsc190501.54121503': __('identifierSchemeCodeUnspsc190501.54121503'),
	'identifierSchemeCodeUnspsc190501.54121504': __('identifierSchemeCodeUnspsc190501.54121504'),
	'identifierSchemeCodeUnspsc190501.54121600': __('identifierSchemeCodeUnspsc190501.54121600'),
	'identifierSchemeCodeUnspsc190501.54121601': __('identifierSchemeCodeUnspsc190501.54121601'),
	'identifierSchemeCodeUnspsc190501.54121602': __('identifierSchemeCodeUnspsc190501.54121602'),
	'identifierSchemeCodeUnspsc190501.54121603': __('identifierSchemeCodeUnspsc190501.54121603'),
	'identifierSchemeCodeUnspsc190501.54121700': __('identifierSchemeCodeUnspsc190501.54121700'),
	'identifierSchemeCodeUnspsc190501.54121701': __('identifierSchemeCodeUnspsc190501.54121701'),
	'identifierSchemeCodeUnspsc190501.54121702': __('identifierSchemeCodeUnspsc190501.54121702'),
	'identifierSchemeCodeUnspsc190501.54121800': __('identifierSchemeCodeUnspsc190501.54121800'),
	'identifierSchemeCodeUnspsc190501.54121801': __('identifierSchemeCodeUnspsc190501.54121801'),
	'identifierSchemeCodeUnspsc190501.54121802': __('identifierSchemeCodeUnspsc190501.54121802'),
	'identifierSchemeCodeUnspsc190501.55000000': __('identifierSchemeCodeUnspsc190501.55000000'),
	'identifierSchemeCodeUnspsc190501.55100000': __('identifierSchemeCodeUnspsc190501.55100000'),
	'identifierSchemeCodeUnspsc190501.55101500': __('identifierSchemeCodeUnspsc190501.55101500'),
	'identifierSchemeCodeUnspsc190501.55101501': __('identifierSchemeCodeUnspsc190501.55101501'),
	'identifierSchemeCodeUnspsc190501.55101502': __('identifierSchemeCodeUnspsc190501.55101502'),
	'identifierSchemeCodeUnspsc190501.55101503': __('identifierSchemeCodeUnspsc190501.55101503'),
	'identifierSchemeCodeUnspsc190501.55101504': __('identifierSchemeCodeUnspsc190501.55101504'),
	'identifierSchemeCodeUnspsc190501.55101505': __('identifierSchemeCodeUnspsc190501.55101505'),
	'identifierSchemeCodeUnspsc190501.55101506': __('identifierSchemeCodeUnspsc190501.55101506'),
	'identifierSchemeCodeUnspsc190501.55101507': __('identifierSchemeCodeUnspsc190501.55101507'),
	'identifierSchemeCodeUnspsc190501.55101509': __('identifierSchemeCodeUnspsc190501.55101509'),
	'identifierSchemeCodeUnspsc190501.55101510': __('identifierSchemeCodeUnspsc190501.55101510'),
	'identifierSchemeCodeUnspsc190501.55101513': __('identifierSchemeCodeUnspsc190501.55101513'),
	'identifierSchemeCodeUnspsc190501.55101514': __('identifierSchemeCodeUnspsc190501.55101514'),
	'identifierSchemeCodeUnspsc190501.55101515': __('identifierSchemeCodeUnspsc190501.55101515'),
	'identifierSchemeCodeUnspsc190501.55101516': __('identifierSchemeCodeUnspsc190501.55101516'),
	'identifierSchemeCodeUnspsc190501.55101517': __('identifierSchemeCodeUnspsc190501.55101517'),
	'identifierSchemeCodeUnspsc190501.55101518': __('identifierSchemeCodeUnspsc190501.55101518'),
	'identifierSchemeCodeUnspsc190501.55101519': __('identifierSchemeCodeUnspsc190501.55101519'),
	'identifierSchemeCodeUnspsc190501.55101520': __('identifierSchemeCodeUnspsc190501.55101520'),
	'identifierSchemeCodeUnspsc190501.55101521': __('identifierSchemeCodeUnspsc190501.55101521'),
	'identifierSchemeCodeUnspsc190501.55101522': __('identifierSchemeCodeUnspsc190501.55101522'),
	'identifierSchemeCodeUnspsc190501.55101523': __('identifierSchemeCodeUnspsc190501.55101523'),
	'identifierSchemeCodeUnspsc190501.55101524': __('identifierSchemeCodeUnspsc190501.55101524'),
	'identifierSchemeCodeUnspsc190501.55101525': __('identifierSchemeCodeUnspsc190501.55101525'),
	'identifierSchemeCodeUnspsc190501.55101526': __('identifierSchemeCodeUnspsc190501.55101526'),
	'identifierSchemeCodeUnspsc190501.55101527': __('identifierSchemeCodeUnspsc190501.55101527'),
	'identifierSchemeCodeUnspsc190501.55101528': __('identifierSchemeCodeUnspsc190501.55101528'),
	'identifierSchemeCodeUnspsc190501.55101529': __('identifierSchemeCodeUnspsc190501.55101529'),
	'identifierSchemeCodeUnspsc190501.55101530': __('identifierSchemeCodeUnspsc190501.55101530'),
	'identifierSchemeCodeUnspsc190501.55101531': __('identifierSchemeCodeUnspsc190501.55101531'),
	'identifierSchemeCodeUnspsc190501.55101532': __('identifierSchemeCodeUnspsc190501.55101532'),
	'identifierSchemeCodeUnspsc190501.55110000': __('identifierSchemeCodeUnspsc190501.55110000'),
	'identifierSchemeCodeUnspsc190501.55111500': __('identifierSchemeCodeUnspsc190501.55111500'),
	'identifierSchemeCodeUnspsc190501.55111501': __('identifierSchemeCodeUnspsc190501.55111501'),
	'identifierSchemeCodeUnspsc190501.55111502': __('identifierSchemeCodeUnspsc190501.55111502'),
	'identifierSchemeCodeUnspsc190501.55111503': __('identifierSchemeCodeUnspsc190501.55111503'),
	'identifierSchemeCodeUnspsc190501.55111504': __('identifierSchemeCodeUnspsc190501.55111504'),
	'identifierSchemeCodeUnspsc190501.55111505': __('identifierSchemeCodeUnspsc190501.55111505'),
	'identifierSchemeCodeUnspsc190501.55111506': __('identifierSchemeCodeUnspsc190501.55111506'),
	'identifierSchemeCodeUnspsc190501.55111507': __('identifierSchemeCodeUnspsc190501.55111507'),
	'identifierSchemeCodeUnspsc190501.55111508': __('identifierSchemeCodeUnspsc190501.55111508'),
	'identifierSchemeCodeUnspsc190501.55111509': __('identifierSchemeCodeUnspsc190501.55111509'),
	'identifierSchemeCodeUnspsc190501.55111510': __('identifierSchemeCodeUnspsc190501.55111510'),
	'identifierSchemeCodeUnspsc190501.55111511': __('identifierSchemeCodeUnspsc190501.55111511'),
	'identifierSchemeCodeUnspsc190501.55111512': __('identifierSchemeCodeUnspsc190501.55111512'),
	'identifierSchemeCodeUnspsc190501.55111513': __('identifierSchemeCodeUnspsc190501.55111513'),
	'identifierSchemeCodeUnspsc190501.55111514': __('identifierSchemeCodeUnspsc190501.55111514'),
	'identifierSchemeCodeUnspsc190501.55111515': __('identifierSchemeCodeUnspsc190501.55111515'),
	'identifierSchemeCodeUnspsc190501.55111516': __('identifierSchemeCodeUnspsc190501.55111516'),
	'identifierSchemeCodeUnspsc190501.55111600': __('identifierSchemeCodeUnspsc190501.55111600'),
	'identifierSchemeCodeUnspsc190501.55111601': __('identifierSchemeCodeUnspsc190501.55111601'),
	'identifierSchemeCodeUnspsc190501.55120000': __('identifierSchemeCodeUnspsc190501.55120000'),
	'identifierSchemeCodeUnspsc190501.55121500': __('identifierSchemeCodeUnspsc190501.55121500'),
	'identifierSchemeCodeUnspsc190501.55121501': __('identifierSchemeCodeUnspsc190501.55121501'),
	'identifierSchemeCodeUnspsc190501.55121502': __('identifierSchemeCodeUnspsc190501.55121502'),
	'identifierSchemeCodeUnspsc190501.55121503': __('identifierSchemeCodeUnspsc190501.55121503'),
	'identifierSchemeCodeUnspsc190501.55121504': __('identifierSchemeCodeUnspsc190501.55121504'),
	'identifierSchemeCodeUnspsc190501.55121505': __('identifierSchemeCodeUnspsc190501.55121505'),
	'identifierSchemeCodeUnspsc190501.55121506': __('identifierSchemeCodeUnspsc190501.55121506'),
	'identifierSchemeCodeUnspsc190501.55121507': __('identifierSchemeCodeUnspsc190501.55121507'),
	'identifierSchemeCodeUnspsc190501.55121508': __('identifierSchemeCodeUnspsc190501.55121508'),
	'identifierSchemeCodeUnspsc190501.55121509': __('identifierSchemeCodeUnspsc190501.55121509'),
	'identifierSchemeCodeUnspsc190501.55121510': __('identifierSchemeCodeUnspsc190501.55121510'),
	'identifierSchemeCodeUnspsc190501.55121511': __('identifierSchemeCodeUnspsc190501.55121511'),
	'identifierSchemeCodeUnspsc190501.55121512': __('identifierSchemeCodeUnspsc190501.55121512'),
	'identifierSchemeCodeUnspsc190501.55121513': __('identifierSchemeCodeUnspsc190501.55121513'),
	'identifierSchemeCodeUnspsc190501.55121600': __('identifierSchemeCodeUnspsc190501.55121600'),
	'identifierSchemeCodeUnspsc190501.55121601': __('identifierSchemeCodeUnspsc190501.55121601'),
	'identifierSchemeCodeUnspsc190501.55121602': __('identifierSchemeCodeUnspsc190501.55121602'),
	'identifierSchemeCodeUnspsc190501.55121604': __('identifierSchemeCodeUnspsc190501.55121604'),
	'identifierSchemeCodeUnspsc190501.55121605': __('identifierSchemeCodeUnspsc190501.55121605'),
	'identifierSchemeCodeUnspsc190501.55121606': __('identifierSchemeCodeUnspsc190501.55121606'),
	'identifierSchemeCodeUnspsc190501.55121607': __('identifierSchemeCodeUnspsc190501.55121607'),
	'identifierSchemeCodeUnspsc190501.55121608': __('identifierSchemeCodeUnspsc190501.55121608'),
	'identifierSchemeCodeUnspsc190501.55121609': __('identifierSchemeCodeUnspsc190501.55121609'),
	'identifierSchemeCodeUnspsc190501.55121610': __('identifierSchemeCodeUnspsc190501.55121610'),
	'identifierSchemeCodeUnspsc190501.55121611': __('identifierSchemeCodeUnspsc190501.55121611'),
	'identifierSchemeCodeUnspsc190501.55121612': __('identifierSchemeCodeUnspsc190501.55121612'),
	'identifierSchemeCodeUnspsc190501.55121613': __('identifierSchemeCodeUnspsc190501.55121613'),
	'identifierSchemeCodeUnspsc190501.55121614': __('identifierSchemeCodeUnspsc190501.55121614'),
	'identifierSchemeCodeUnspsc190501.55121615': __('identifierSchemeCodeUnspsc190501.55121615'),
	'identifierSchemeCodeUnspsc190501.55121616': __('identifierSchemeCodeUnspsc190501.55121616'),
	'identifierSchemeCodeUnspsc190501.55121617': __('identifierSchemeCodeUnspsc190501.55121617'),
	'identifierSchemeCodeUnspsc190501.55121618': __('identifierSchemeCodeUnspsc190501.55121618'),
	'identifierSchemeCodeUnspsc190501.55121619': __('identifierSchemeCodeUnspsc190501.55121619'),
	'identifierSchemeCodeUnspsc190501.55121620': __('identifierSchemeCodeUnspsc190501.55121620'),
	'identifierSchemeCodeUnspsc190501.55121621': __('identifierSchemeCodeUnspsc190501.55121621'),
	'identifierSchemeCodeUnspsc190501.55121622': __('identifierSchemeCodeUnspsc190501.55121622'),
	'identifierSchemeCodeUnspsc190501.55121623': __('identifierSchemeCodeUnspsc190501.55121623'),
	'identifierSchemeCodeUnspsc190501.55121624': __('identifierSchemeCodeUnspsc190501.55121624'),
	'identifierSchemeCodeUnspsc190501.55121700': __('identifierSchemeCodeUnspsc190501.55121700'),
	'identifierSchemeCodeUnspsc190501.55121701': __('identifierSchemeCodeUnspsc190501.55121701'),
	'identifierSchemeCodeUnspsc190501.55121702': __('identifierSchemeCodeUnspsc190501.55121702'),
	'identifierSchemeCodeUnspsc190501.55121703': __('identifierSchemeCodeUnspsc190501.55121703'),
	'identifierSchemeCodeUnspsc190501.55121704': __('identifierSchemeCodeUnspsc190501.55121704'),
	'identifierSchemeCodeUnspsc190501.55121705': __('identifierSchemeCodeUnspsc190501.55121705'),
	'identifierSchemeCodeUnspsc190501.55121706': __('identifierSchemeCodeUnspsc190501.55121706'),
	'identifierSchemeCodeUnspsc190501.55121707': __('identifierSchemeCodeUnspsc190501.55121707'),
	'identifierSchemeCodeUnspsc190501.55121708': __('identifierSchemeCodeUnspsc190501.55121708'),
	'identifierSchemeCodeUnspsc190501.55121710': __('identifierSchemeCodeUnspsc190501.55121710'),
	'identifierSchemeCodeUnspsc190501.55121712': __('identifierSchemeCodeUnspsc190501.55121712'),
	'identifierSchemeCodeUnspsc190501.55121713': __('identifierSchemeCodeUnspsc190501.55121713'),
	'identifierSchemeCodeUnspsc190501.55121714': __('identifierSchemeCodeUnspsc190501.55121714'),
	'identifierSchemeCodeUnspsc190501.55121715': __('identifierSchemeCodeUnspsc190501.55121715'),
	'identifierSchemeCodeUnspsc190501.55121716': __('identifierSchemeCodeUnspsc190501.55121716'),
	'identifierSchemeCodeUnspsc190501.55121717': __('identifierSchemeCodeUnspsc190501.55121717'),
	'identifierSchemeCodeUnspsc190501.55121718': __('identifierSchemeCodeUnspsc190501.55121718'),
	'identifierSchemeCodeUnspsc190501.55121719': __('identifierSchemeCodeUnspsc190501.55121719'),
	'identifierSchemeCodeUnspsc190501.55121720': __('identifierSchemeCodeUnspsc190501.55121720'),
	'identifierSchemeCodeUnspsc190501.55121721': __('identifierSchemeCodeUnspsc190501.55121721'),
	'identifierSchemeCodeUnspsc190501.55121725': __('identifierSchemeCodeUnspsc190501.55121725'),
	'identifierSchemeCodeUnspsc190501.55121726': __('identifierSchemeCodeUnspsc190501.55121726'),
	'identifierSchemeCodeUnspsc190501.55121728': __('identifierSchemeCodeUnspsc190501.55121728'),
	'identifierSchemeCodeUnspsc190501.55121729': __('identifierSchemeCodeUnspsc190501.55121729'),
	'identifierSchemeCodeUnspsc190501.55121730': __('identifierSchemeCodeUnspsc190501.55121730'),
	'identifierSchemeCodeUnspsc190501.55121731': __('identifierSchemeCodeUnspsc190501.55121731'),
	'identifierSchemeCodeUnspsc190501.55121732': __('identifierSchemeCodeUnspsc190501.55121732'),
	'identifierSchemeCodeUnspsc190501.55121733': __('identifierSchemeCodeUnspsc190501.55121733'),
	'identifierSchemeCodeUnspsc190501.55121734': __('identifierSchemeCodeUnspsc190501.55121734'),
	'identifierSchemeCodeUnspsc190501.55121735': __('identifierSchemeCodeUnspsc190501.55121735'),
	'identifierSchemeCodeUnspsc190501.55121736': __('identifierSchemeCodeUnspsc190501.55121736'),
	'identifierSchemeCodeUnspsc190501.55121737': __('identifierSchemeCodeUnspsc190501.55121737'),
	'identifierSchemeCodeUnspsc190501.55121800': __('identifierSchemeCodeUnspsc190501.55121800'),
	'identifierSchemeCodeUnspsc190501.55121801': __('identifierSchemeCodeUnspsc190501.55121801'),
	'identifierSchemeCodeUnspsc190501.55121802': __('identifierSchemeCodeUnspsc190501.55121802'),
	'identifierSchemeCodeUnspsc190501.55121803': __('identifierSchemeCodeUnspsc190501.55121803'),
	'identifierSchemeCodeUnspsc190501.55121804': __('identifierSchemeCodeUnspsc190501.55121804'),
	'identifierSchemeCodeUnspsc190501.55121806': __('identifierSchemeCodeUnspsc190501.55121806'),
	'identifierSchemeCodeUnspsc190501.55121807': __('identifierSchemeCodeUnspsc190501.55121807'),
	'identifierSchemeCodeUnspsc190501.55121808': __('identifierSchemeCodeUnspsc190501.55121808'),
	'identifierSchemeCodeUnspsc190501.55121900': __('identifierSchemeCodeUnspsc190501.55121900'),
	'identifierSchemeCodeUnspsc190501.55121901': __('identifierSchemeCodeUnspsc190501.55121901'),
	'identifierSchemeCodeUnspsc190501.55121902': __('identifierSchemeCodeUnspsc190501.55121902'),
	'identifierSchemeCodeUnspsc190501.55121903': __('identifierSchemeCodeUnspsc190501.55121903'),
	'identifierSchemeCodeUnspsc190501.55121904': __('identifierSchemeCodeUnspsc190501.55121904'),
	'identifierSchemeCodeUnspsc190501.55121905': __('identifierSchemeCodeUnspsc190501.55121905'),
	'identifierSchemeCodeUnspsc190501.55121906': __('identifierSchemeCodeUnspsc190501.55121906'),
	'identifierSchemeCodeUnspsc190501.55121907': __('identifierSchemeCodeUnspsc190501.55121907'),
	'identifierSchemeCodeUnspsc190501.55121908': __('identifierSchemeCodeUnspsc190501.55121908'),
	'identifierSchemeCodeUnspsc190501.55122000': __('identifierSchemeCodeUnspsc190501.55122000'),
	'identifierSchemeCodeUnspsc190501.55122001': __('identifierSchemeCodeUnspsc190501.55122001'),
	'identifierSchemeCodeUnspsc190501.55122002': __('identifierSchemeCodeUnspsc190501.55122002'),
	'identifierSchemeCodeUnspsc190501.55122003': __('identifierSchemeCodeUnspsc190501.55122003'),
	'identifierSchemeCodeUnspsc190501.56000000': __('identifierSchemeCodeUnspsc190501.56000000'),
	'identifierSchemeCodeUnspsc190501.56100000': __('identifierSchemeCodeUnspsc190501.56100000'),
	'identifierSchemeCodeUnspsc190501.56101500': __('identifierSchemeCodeUnspsc190501.56101500'),
	'identifierSchemeCodeUnspsc190501.56101501': __('identifierSchemeCodeUnspsc190501.56101501'),
	'identifierSchemeCodeUnspsc190501.56101502': __('identifierSchemeCodeUnspsc190501.56101502'),
	'identifierSchemeCodeUnspsc190501.56101503': __('identifierSchemeCodeUnspsc190501.56101503'),
	'identifierSchemeCodeUnspsc190501.56101504': __('identifierSchemeCodeUnspsc190501.56101504'),
	'identifierSchemeCodeUnspsc190501.56101505': __('identifierSchemeCodeUnspsc190501.56101505'),
	'identifierSchemeCodeUnspsc190501.56101506': __('identifierSchemeCodeUnspsc190501.56101506'),
	'identifierSchemeCodeUnspsc190501.56101507': __('identifierSchemeCodeUnspsc190501.56101507'),
	'identifierSchemeCodeUnspsc190501.56101508': __('identifierSchemeCodeUnspsc190501.56101508'),
	'identifierSchemeCodeUnspsc190501.56101509': __('identifierSchemeCodeUnspsc190501.56101509'),
	'identifierSchemeCodeUnspsc190501.56101510': __('identifierSchemeCodeUnspsc190501.56101510'),
	'identifierSchemeCodeUnspsc190501.56101513': __('identifierSchemeCodeUnspsc190501.56101513'),
	'identifierSchemeCodeUnspsc190501.56101514': __('identifierSchemeCodeUnspsc190501.56101514'),
	'identifierSchemeCodeUnspsc190501.56101515': __('identifierSchemeCodeUnspsc190501.56101515'),
	'identifierSchemeCodeUnspsc190501.56101516': __('identifierSchemeCodeUnspsc190501.56101516'),
	'identifierSchemeCodeUnspsc190501.56101518': __('identifierSchemeCodeUnspsc190501.56101518'),
	'identifierSchemeCodeUnspsc190501.56101519': __('identifierSchemeCodeUnspsc190501.56101519'),
	'identifierSchemeCodeUnspsc190501.56101520': __('identifierSchemeCodeUnspsc190501.56101520'),
	'identifierSchemeCodeUnspsc190501.56101521': __('identifierSchemeCodeUnspsc190501.56101521'),
	'identifierSchemeCodeUnspsc190501.56101522': __('identifierSchemeCodeUnspsc190501.56101522'),
	'identifierSchemeCodeUnspsc190501.56101523': __('identifierSchemeCodeUnspsc190501.56101523'),
	'identifierSchemeCodeUnspsc190501.56101524': __('identifierSchemeCodeUnspsc190501.56101524'),
	'identifierSchemeCodeUnspsc190501.56101525': __('identifierSchemeCodeUnspsc190501.56101525'),
	'identifierSchemeCodeUnspsc190501.56101526': __('identifierSchemeCodeUnspsc190501.56101526'),
	'identifierSchemeCodeUnspsc190501.56101527': __('identifierSchemeCodeUnspsc190501.56101527'),
	'identifierSchemeCodeUnspsc190501.56101528': __('identifierSchemeCodeUnspsc190501.56101528'),
	'identifierSchemeCodeUnspsc190501.56101529': __('identifierSchemeCodeUnspsc190501.56101529'),
	'identifierSchemeCodeUnspsc190501.56101530': __('identifierSchemeCodeUnspsc190501.56101530'),
	'identifierSchemeCodeUnspsc190501.56101531': __('identifierSchemeCodeUnspsc190501.56101531'),
	'identifierSchemeCodeUnspsc190501.56101532': __('identifierSchemeCodeUnspsc190501.56101532'),
	'identifierSchemeCodeUnspsc190501.56101533': __('identifierSchemeCodeUnspsc190501.56101533'),
	'identifierSchemeCodeUnspsc190501.56101535': __('identifierSchemeCodeUnspsc190501.56101535'),
	'identifierSchemeCodeUnspsc190501.56101536': __('identifierSchemeCodeUnspsc190501.56101536'),
	'identifierSchemeCodeUnspsc190501.56101537': __('identifierSchemeCodeUnspsc190501.56101537'),
	'identifierSchemeCodeUnspsc190501.56101538': __('identifierSchemeCodeUnspsc190501.56101538'),
	'identifierSchemeCodeUnspsc190501.56101539': __('identifierSchemeCodeUnspsc190501.56101539'),
	'identifierSchemeCodeUnspsc190501.56101540': __('identifierSchemeCodeUnspsc190501.56101540'),
	'identifierSchemeCodeUnspsc190501.56101541': __('identifierSchemeCodeUnspsc190501.56101541'),
	'identifierSchemeCodeUnspsc190501.56101542': __('identifierSchemeCodeUnspsc190501.56101542'),
	'identifierSchemeCodeUnspsc190501.56101543': __('identifierSchemeCodeUnspsc190501.56101543'),
	'identifierSchemeCodeUnspsc190501.56101544': __('identifierSchemeCodeUnspsc190501.56101544'),
	'identifierSchemeCodeUnspsc190501.56101545': __('identifierSchemeCodeUnspsc190501.56101545'),
	'identifierSchemeCodeUnspsc190501.56101546': __('identifierSchemeCodeUnspsc190501.56101546'),
	'identifierSchemeCodeUnspsc190501.56101600': __('identifierSchemeCodeUnspsc190501.56101600'),
	'identifierSchemeCodeUnspsc190501.56101601': __('identifierSchemeCodeUnspsc190501.56101601'),
	'identifierSchemeCodeUnspsc190501.56101602': __('identifierSchemeCodeUnspsc190501.56101602'),
	'identifierSchemeCodeUnspsc190501.56101603': __('identifierSchemeCodeUnspsc190501.56101603'),
	'identifierSchemeCodeUnspsc190501.56101604': __('identifierSchemeCodeUnspsc190501.56101604'),
	'identifierSchemeCodeUnspsc190501.56101605': __('identifierSchemeCodeUnspsc190501.56101605'),
	'identifierSchemeCodeUnspsc190501.56101606': __('identifierSchemeCodeUnspsc190501.56101606'),
	'identifierSchemeCodeUnspsc190501.56101607': __('identifierSchemeCodeUnspsc190501.56101607'),
	'identifierSchemeCodeUnspsc190501.56101608': __('identifierSchemeCodeUnspsc190501.56101608'),
	'identifierSchemeCodeUnspsc190501.56101609': __('identifierSchemeCodeUnspsc190501.56101609'),
	'identifierSchemeCodeUnspsc190501.56101610': __('identifierSchemeCodeUnspsc190501.56101610'),
	'identifierSchemeCodeUnspsc190501.56101700': __('identifierSchemeCodeUnspsc190501.56101700'),
	'identifierSchemeCodeUnspsc190501.56101701': __('identifierSchemeCodeUnspsc190501.56101701'),
	'identifierSchemeCodeUnspsc190501.56101702': __('identifierSchemeCodeUnspsc190501.56101702'),
	'identifierSchemeCodeUnspsc190501.56101703': __('identifierSchemeCodeUnspsc190501.56101703'),
	'identifierSchemeCodeUnspsc190501.56101704': __('identifierSchemeCodeUnspsc190501.56101704'),
	'identifierSchemeCodeUnspsc190501.56101705': __('identifierSchemeCodeUnspsc190501.56101705'),
	'identifierSchemeCodeUnspsc190501.56101706': __('identifierSchemeCodeUnspsc190501.56101706'),
	'identifierSchemeCodeUnspsc190501.56101707': __('identifierSchemeCodeUnspsc190501.56101707'),
	'identifierSchemeCodeUnspsc190501.56101708': __('identifierSchemeCodeUnspsc190501.56101708'),
	'identifierSchemeCodeUnspsc190501.56101710': __('identifierSchemeCodeUnspsc190501.56101710'),
	'identifierSchemeCodeUnspsc190501.56101711': __('identifierSchemeCodeUnspsc190501.56101711'),
	'identifierSchemeCodeUnspsc190501.56101712': __('identifierSchemeCodeUnspsc190501.56101712'),
	'identifierSchemeCodeUnspsc190501.56101713': __('identifierSchemeCodeUnspsc190501.56101713'),
	'identifierSchemeCodeUnspsc190501.56101714': __('identifierSchemeCodeUnspsc190501.56101714'),
	'identifierSchemeCodeUnspsc190501.56101715': __('identifierSchemeCodeUnspsc190501.56101715'),
	'identifierSchemeCodeUnspsc190501.56101716': __('identifierSchemeCodeUnspsc190501.56101716'),
	'identifierSchemeCodeUnspsc190501.56101717': __('identifierSchemeCodeUnspsc190501.56101717'),
	'identifierSchemeCodeUnspsc190501.56101718': __('identifierSchemeCodeUnspsc190501.56101718'),
	'identifierSchemeCodeUnspsc190501.56101719': __('identifierSchemeCodeUnspsc190501.56101719'),
	'identifierSchemeCodeUnspsc190501.56101800': __('identifierSchemeCodeUnspsc190501.56101800'),
	'identifierSchemeCodeUnspsc190501.56101803': __('identifierSchemeCodeUnspsc190501.56101803'),
	'identifierSchemeCodeUnspsc190501.56101804': __('identifierSchemeCodeUnspsc190501.56101804'),
	'identifierSchemeCodeUnspsc190501.56101805': __('identifierSchemeCodeUnspsc190501.56101805'),
	'identifierSchemeCodeUnspsc190501.56101806': __('identifierSchemeCodeUnspsc190501.56101806'),
	'identifierSchemeCodeUnspsc190501.56101807': __('identifierSchemeCodeUnspsc190501.56101807'),
	'identifierSchemeCodeUnspsc190501.56101808': __('identifierSchemeCodeUnspsc190501.56101808'),
	'identifierSchemeCodeUnspsc190501.56101809': __('identifierSchemeCodeUnspsc190501.56101809'),
	'identifierSchemeCodeUnspsc190501.56101810': __('identifierSchemeCodeUnspsc190501.56101810'),
	'identifierSchemeCodeUnspsc190501.56101811': __('identifierSchemeCodeUnspsc190501.56101811'),
	'identifierSchemeCodeUnspsc190501.56101812': __('identifierSchemeCodeUnspsc190501.56101812'),
	'identifierSchemeCodeUnspsc190501.56101813': __('identifierSchemeCodeUnspsc190501.56101813'),
	'identifierSchemeCodeUnspsc190501.56101900': __('identifierSchemeCodeUnspsc190501.56101900'),
	'identifierSchemeCodeUnspsc190501.56101901': __('identifierSchemeCodeUnspsc190501.56101901'),
	'identifierSchemeCodeUnspsc190501.56101902': __('identifierSchemeCodeUnspsc190501.56101902'),
	'identifierSchemeCodeUnspsc190501.56101903': __('identifierSchemeCodeUnspsc190501.56101903'),
	'identifierSchemeCodeUnspsc190501.56101904': __('identifierSchemeCodeUnspsc190501.56101904'),
	'identifierSchemeCodeUnspsc190501.56101905': __('identifierSchemeCodeUnspsc190501.56101905'),
	'identifierSchemeCodeUnspsc190501.56101906': __('identifierSchemeCodeUnspsc190501.56101906'),
	'identifierSchemeCodeUnspsc190501.56101907': __('identifierSchemeCodeUnspsc190501.56101907'),
	'identifierSchemeCodeUnspsc190501.56110000': __('identifierSchemeCodeUnspsc190501.56110000'),
	'identifierSchemeCodeUnspsc190501.56111500': __('identifierSchemeCodeUnspsc190501.56111500'),
	'identifierSchemeCodeUnspsc190501.56111501': __('identifierSchemeCodeUnspsc190501.56111501'),
	'identifierSchemeCodeUnspsc190501.56111502': __('identifierSchemeCodeUnspsc190501.56111502'),
	'identifierSchemeCodeUnspsc190501.56111503': __('identifierSchemeCodeUnspsc190501.56111503'),
	'identifierSchemeCodeUnspsc190501.56111504': __('identifierSchemeCodeUnspsc190501.56111504'),
	'identifierSchemeCodeUnspsc190501.56111505': __('identifierSchemeCodeUnspsc190501.56111505'),
	'identifierSchemeCodeUnspsc190501.56111506': __('identifierSchemeCodeUnspsc190501.56111506'),
	'identifierSchemeCodeUnspsc190501.56111507': __('identifierSchemeCodeUnspsc190501.56111507'),
	'identifierSchemeCodeUnspsc190501.56111508': __('identifierSchemeCodeUnspsc190501.56111508'),
	'identifierSchemeCodeUnspsc190501.56111509': __('identifierSchemeCodeUnspsc190501.56111509'),
	'identifierSchemeCodeUnspsc190501.56111510': __('identifierSchemeCodeUnspsc190501.56111510'),
	'identifierSchemeCodeUnspsc190501.56111511': __('identifierSchemeCodeUnspsc190501.56111511'),
	'identifierSchemeCodeUnspsc190501.56111512': __('identifierSchemeCodeUnspsc190501.56111512'),
	'identifierSchemeCodeUnspsc190501.56111513': __('identifierSchemeCodeUnspsc190501.56111513'),
	'identifierSchemeCodeUnspsc190501.56111514': __('identifierSchemeCodeUnspsc190501.56111514'),
	'identifierSchemeCodeUnspsc190501.56111600': __('identifierSchemeCodeUnspsc190501.56111600'),
	'identifierSchemeCodeUnspsc190501.56111601': __('identifierSchemeCodeUnspsc190501.56111601'),
	'identifierSchemeCodeUnspsc190501.56111602': __('identifierSchemeCodeUnspsc190501.56111602'),
	'identifierSchemeCodeUnspsc190501.56111603': __('identifierSchemeCodeUnspsc190501.56111603'),
	'identifierSchemeCodeUnspsc190501.56111604': __('identifierSchemeCodeUnspsc190501.56111604'),
	'identifierSchemeCodeUnspsc190501.56111605': __('identifierSchemeCodeUnspsc190501.56111605'),
	'identifierSchemeCodeUnspsc190501.56111606': __('identifierSchemeCodeUnspsc190501.56111606'),
	'identifierSchemeCodeUnspsc190501.56111700': __('identifierSchemeCodeUnspsc190501.56111700'),
	'identifierSchemeCodeUnspsc190501.56111701': __('identifierSchemeCodeUnspsc190501.56111701'),
	'identifierSchemeCodeUnspsc190501.56111702': __('identifierSchemeCodeUnspsc190501.56111702'),
	'identifierSchemeCodeUnspsc190501.56111703': __('identifierSchemeCodeUnspsc190501.56111703'),
	'identifierSchemeCodeUnspsc190501.56111704': __('identifierSchemeCodeUnspsc190501.56111704'),
	'identifierSchemeCodeUnspsc190501.56111705': __('identifierSchemeCodeUnspsc190501.56111705'),
	'identifierSchemeCodeUnspsc190501.56111706': __('identifierSchemeCodeUnspsc190501.56111706'),
	'identifierSchemeCodeUnspsc190501.56111707': __('identifierSchemeCodeUnspsc190501.56111707'),
	'identifierSchemeCodeUnspsc190501.56111800': __('identifierSchemeCodeUnspsc190501.56111800'),
	'identifierSchemeCodeUnspsc190501.56111801': __('identifierSchemeCodeUnspsc190501.56111801'),
	'identifierSchemeCodeUnspsc190501.56111802': __('identifierSchemeCodeUnspsc190501.56111802'),
	'identifierSchemeCodeUnspsc190501.56111803': __('identifierSchemeCodeUnspsc190501.56111803'),
	'identifierSchemeCodeUnspsc190501.56111804': __('identifierSchemeCodeUnspsc190501.56111804'),
	'identifierSchemeCodeUnspsc190501.56111805': __('identifierSchemeCodeUnspsc190501.56111805'),
	'identifierSchemeCodeUnspsc190501.56111806': __('identifierSchemeCodeUnspsc190501.56111806'),
	'identifierSchemeCodeUnspsc190501.56111900': __('identifierSchemeCodeUnspsc190501.56111900'),
	'identifierSchemeCodeUnspsc190501.56111901': __('identifierSchemeCodeUnspsc190501.56111901'),
	'identifierSchemeCodeUnspsc190501.56111902': __('identifierSchemeCodeUnspsc190501.56111902'),
	'identifierSchemeCodeUnspsc190501.56111903': __('identifierSchemeCodeUnspsc190501.56111903'),
	'identifierSchemeCodeUnspsc190501.56111904': __('identifierSchemeCodeUnspsc190501.56111904'),
	'identifierSchemeCodeUnspsc190501.56111905': __('identifierSchemeCodeUnspsc190501.56111905'),
	'identifierSchemeCodeUnspsc190501.56111906': __('identifierSchemeCodeUnspsc190501.56111906'),
	'identifierSchemeCodeUnspsc190501.56111907': __('identifierSchemeCodeUnspsc190501.56111907'),
	'identifierSchemeCodeUnspsc190501.56111908': __('identifierSchemeCodeUnspsc190501.56111908'),
	'identifierSchemeCodeUnspsc190501.56112000': __('identifierSchemeCodeUnspsc190501.56112000'),
	'identifierSchemeCodeUnspsc190501.56112001': __('identifierSchemeCodeUnspsc190501.56112001'),
	'identifierSchemeCodeUnspsc190501.56112002': __('identifierSchemeCodeUnspsc190501.56112002'),
	'identifierSchemeCodeUnspsc190501.56112003': __('identifierSchemeCodeUnspsc190501.56112003'),
	'identifierSchemeCodeUnspsc190501.56112004': __('identifierSchemeCodeUnspsc190501.56112004'),
	'identifierSchemeCodeUnspsc190501.56112005': __('identifierSchemeCodeUnspsc190501.56112005'),
	'identifierSchemeCodeUnspsc190501.56112100': __('identifierSchemeCodeUnspsc190501.56112100'),
	'identifierSchemeCodeUnspsc190501.56112101': __('identifierSchemeCodeUnspsc190501.56112101'),
	'identifierSchemeCodeUnspsc190501.56112102': __('identifierSchemeCodeUnspsc190501.56112102'),
	'identifierSchemeCodeUnspsc190501.56112103': __('identifierSchemeCodeUnspsc190501.56112103'),
	'identifierSchemeCodeUnspsc190501.56112104': __('identifierSchemeCodeUnspsc190501.56112104'),
	'identifierSchemeCodeUnspsc190501.56112105': __('identifierSchemeCodeUnspsc190501.56112105'),
	'identifierSchemeCodeUnspsc190501.56112106': __('identifierSchemeCodeUnspsc190501.56112106'),
	'identifierSchemeCodeUnspsc190501.56112107': __('identifierSchemeCodeUnspsc190501.56112107'),
	'identifierSchemeCodeUnspsc190501.56112108': __('identifierSchemeCodeUnspsc190501.56112108'),
	'identifierSchemeCodeUnspsc190501.56112109': __('identifierSchemeCodeUnspsc190501.56112109'),
	'identifierSchemeCodeUnspsc190501.56112110': __('identifierSchemeCodeUnspsc190501.56112110'),
	'identifierSchemeCodeUnspsc190501.56112111': __('identifierSchemeCodeUnspsc190501.56112111'),
	'identifierSchemeCodeUnspsc190501.56112200': __('identifierSchemeCodeUnspsc190501.56112200'),
	'identifierSchemeCodeUnspsc190501.56112201': __('identifierSchemeCodeUnspsc190501.56112201'),
	'identifierSchemeCodeUnspsc190501.56112202': __('identifierSchemeCodeUnspsc190501.56112202'),
	'identifierSchemeCodeUnspsc190501.56112203': __('identifierSchemeCodeUnspsc190501.56112203'),
	'identifierSchemeCodeUnspsc190501.56112204': __('identifierSchemeCodeUnspsc190501.56112204'),
	'identifierSchemeCodeUnspsc190501.56112205': __('identifierSchemeCodeUnspsc190501.56112205'),
	'identifierSchemeCodeUnspsc190501.56112206': __('identifierSchemeCodeUnspsc190501.56112206'),
	'identifierSchemeCodeUnspsc190501.56112300': __('identifierSchemeCodeUnspsc190501.56112300'),
	'identifierSchemeCodeUnspsc190501.56112301': __('identifierSchemeCodeUnspsc190501.56112301'),
	'identifierSchemeCodeUnspsc190501.56112302': __('identifierSchemeCodeUnspsc190501.56112302'),
	'identifierSchemeCodeUnspsc190501.56112303': __('identifierSchemeCodeUnspsc190501.56112303'),
	'identifierSchemeCodeUnspsc190501.56112304': __('identifierSchemeCodeUnspsc190501.56112304'),
	'identifierSchemeCodeUnspsc190501.56120000': __('identifierSchemeCodeUnspsc190501.56120000'),
	'identifierSchemeCodeUnspsc190501.56121000': __('identifierSchemeCodeUnspsc190501.56121000'),
	'identifierSchemeCodeUnspsc190501.56121001': __('identifierSchemeCodeUnspsc190501.56121001'),
	'identifierSchemeCodeUnspsc190501.56121002': __('identifierSchemeCodeUnspsc190501.56121002'),
	'identifierSchemeCodeUnspsc190501.56121003': __('identifierSchemeCodeUnspsc190501.56121003'),
	'identifierSchemeCodeUnspsc190501.56121004': __('identifierSchemeCodeUnspsc190501.56121004'),
	'identifierSchemeCodeUnspsc190501.56121005': __('identifierSchemeCodeUnspsc190501.56121005'),
	'identifierSchemeCodeUnspsc190501.56121006': __('identifierSchemeCodeUnspsc190501.56121006'),
	'identifierSchemeCodeUnspsc190501.56121007': __('identifierSchemeCodeUnspsc190501.56121007'),
	'identifierSchemeCodeUnspsc190501.56121008': __('identifierSchemeCodeUnspsc190501.56121008'),
	'identifierSchemeCodeUnspsc190501.56121009': __('identifierSchemeCodeUnspsc190501.56121009'),
	'identifierSchemeCodeUnspsc190501.56121010': __('identifierSchemeCodeUnspsc190501.56121010'),
	'identifierSchemeCodeUnspsc190501.56121011': __('identifierSchemeCodeUnspsc190501.56121011'),
	'identifierSchemeCodeUnspsc190501.56121012': __('identifierSchemeCodeUnspsc190501.56121012'),
	'identifierSchemeCodeUnspsc190501.56121014': __('identifierSchemeCodeUnspsc190501.56121014'),
	'identifierSchemeCodeUnspsc190501.56121015': __('identifierSchemeCodeUnspsc190501.56121015'),
	'identifierSchemeCodeUnspsc190501.56121100': __('identifierSchemeCodeUnspsc190501.56121100'),
	'identifierSchemeCodeUnspsc190501.56121101': __('identifierSchemeCodeUnspsc190501.56121101'),
	'identifierSchemeCodeUnspsc190501.56121102': __('identifierSchemeCodeUnspsc190501.56121102'),
	'identifierSchemeCodeUnspsc190501.56121200': __('identifierSchemeCodeUnspsc190501.56121200'),
	'identifierSchemeCodeUnspsc190501.56121201': __('identifierSchemeCodeUnspsc190501.56121201'),
	'identifierSchemeCodeUnspsc190501.56121300': __('identifierSchemeCodeUnspsc190501.56121300'),
	'identifierSchemeCodeUnspsc190501.56121301': __('identifierSchemeCodeUnspsc190501.56121301'),
	'identifierSchemeCodeUnspsc190501.56121302': __('identifierSchemeCodeUnspsc190501.56121302'),
	'identifierSchemeCodeUnspsc190501.56121303': __('identifierSchemeCodeUnspsc190501.56121303'),
	'identifierSchemeCodeUnspsc190501.56121304': __('identifierSchemeCodeUnspsc190501.56121304'),
	'identifierSchemeCodeUnspsc190501.56121400': __('identifierSchemeCodeUnspsc190501.56121400'),
	'identifierSchemeCodeUnspsc190501.56121401': __('identifierSchemeCodeUnspsc190501.56121401'),
	'identifierSchemeCodeUnspsc190501.56121402': __('identifierSchemeCodeUnspsc190501.56121402'),
	'identifierSchemeCodeUnspsc190501.56121403': __('identifierSchemeCodeUnspsc190501.56121403'),
	'identifierSchemeCodeUnspsc190501.56121500': __('identifierSchemeCodeUnspsc190501.56121500'),
	'identifierSchemeCodeUnspsc190501.56121501': __('identifierSchemeCodeUnspsc190501.56121501'),
	'identifierSchemeCodeUnspsc190501.56121502': __('identifierSchemeCodeUnspsc190501.56121502'),
	'identifierSchemeCodeUnspsc190501.56121503': __('identifierSchemeCodeUnspsc190501.56121503'),
	'identifierSchemeCodeUnspsc190501.56121504': __('identifierSchemeCodeUnspsc190501.56121504'),
	'identifierSchemeCodeUnspsc190501.56121505': __('identifierSchemeCodeUnspsc190501.56121505'),
	'identifierSchemeCodeUnspsc190501.56121506': __('identifierSchemeCodeUnspsc190501.56121506'),
	'identifierSchemeCodeUnspsc190501.56121507': __('identifierSchemeCodeUnspsc190501.56121507'),
	'identifierSchemeCodeUnspsc190501.56121508': __('identifierSchemeCodeUnspsc190501.56121508'),
	'identifierSchemeCodeUnspsc190501.56121509': __('identifierSchemeCodeUnspsc190501.56121509'),
	'identifierSchemeCodeUnspsc190501.56121510': __('identifierSchemeCodeUnspsc190501.56121510'),
	'identifierSchemeCodeUnspsc190501.56121600': __('identifierSchemeCodeUnspsc190501.56121600'),
	'identifierSchemeCodeUnspsc190501.56121601': __('identifierSchemeCodeUnspsc190501.56121601'),
	'identifierSchemeCodeUnspsc190501.56121602': __('identifierSchemeCodeUnspsc190501.56121602'),
	'identifierSchemeCodeUnspsc190501.56121603': __('identifierSchemeCodeUnspsc190501.56121603'),
	'identifierSchemeCodeUnspsc190501.56121604': __('identifierSchemeCodeUnspsc190501.56121604'),
	'identifierSchemeCodeUnspsc190501.56121605': __('identifierSchemeCodeUnspsc190501.56121605'),
	'identifierSchemeCodeUnspsc190501.56121606': __('identifierSchemeCodeUnspsc190501.56121606'),
	'identifierSchemeCodeUnspsc190501.56121607': __('identifierSchemeCodeUnspsc190501.56121607'),
	'identifierSchemeCodeUnspsc190501.56121608': __('identifierSchemeCodeUnspsc190501.56121608'),
	'identifierSchemeCodeUnspsc190501.56121609': __('identifierSchemeCodeUnspsc190501.56121609'),
	'identifierSchemeCodeUnspsc190501.56121610': __('identifierSchemeCodeUnspsc190501.56121610'),
	'identifierSchemeCodeUnspsc190501.56121611': __('identifierSchemeCodeUnspsc190501.56121611'),
	'identifierSchemeCodeUnspsc190501.56121700': __('identifierSchemeCodeUnspsc190501.56121700'),
	'identifierSchemeCodeUnspsc190501.56121701': __('identifierSchemeCodeUnspsc190501.56121701'),
	'identifierSchemeCodeUnspsc190501.56121702': __('identifierSchemeCodeUnspsc190501.56121702'),
	'identifierSchemeCodeUnspsc190501.56121703': __('identifierSchemeCodeUnspsc190501.56121703'),
	'identifierSchemeCodeUnspsc190501.56121704': __('identifierSchemeCodeUnspsc190501.56121704'),
	'identifierSchemeCodeUnspsc190501.56121800': __('identifierSchemeCodeUnspsc190501.56121800'),
	'identifierSchemeCodeUnspsc190501.56121801': __('identifierSchemeCodeUnspsc190501.56121801'),
	'identifierSchemeCodeUnspsc190501.56121802': __('identifierSchemeCodeUnspsc190501.56121802'),
	'identifierSchemeCodeUnspsc190501.56121803': __('identifierSchemeCodeUnspsc190501.56121803'),
	'identifierSchemeCodeUnspsc190501.56121804': __('identifierSchemeCodeUnspsc190501.56121804'),
	'identifierSchemeCodeUnspsc190501.56121805': __('identifierSchemeCodeUnspsc190501.56121805'),
	'identifierSchemeCodeUnspsc190501.56121900': __('identifierSchemeCodeUnspsc190501.56121900'),
	'identifierSchemeCodeUnspsc190501.56121901': __('identifierSchemeCodeUnspsc190501.56121901'),
	'identifierSchemeCodeUnspsc190501.56121902': __('identifierSchemeCodeUnspsc190501.56121902'),
	'identifierSchemeCodeUnspsc190501.56121903': __('identifierSchemeCodeUnspsc190501.56121903'),
	'identifierSchemeCodeUnspsc190501.56122000': __('identifierSchemeCodeUnspsc190501.56122000'),
	'identifierSchemeCodeUnspsc190501.56122001': __('identifierSchemeCodeUnspsc190501.56122001'),
	'identifierSchemeCodeUnspsc190501.56122002': __('identifierSchemeCodeUnspsc190501.56122002'),
	'identifierSchemeCodeUnspsc190501.56122003': __('identifierSchemeCodeUnspsc190501.56122003'),
	'identifierSchemeCodeUnspsc190501.56122004': __('identifierSchemeCodeUnspsc190501.56122004'),
	'identifierSchemeCodeUnspsc190501.56130000': __('identifierSchemeCodeUnspsc190501.56130000'),
	'identifierSchemeCodeUnspsc190501.56131500': __('identifierSchemeCodeUnspsc190501.56131500'),
	'identifierSchemeCodeUnspsc190501.56131501': __('identifierSchemeCodeUnspsc190501.56131501'),
	'identifierSchemeCodeUnspsc190501.56131502': __('identifierSchemeCodeUnspsc190501.56131502'),
	'identifierSchemeCodeUnspsc190501.56131503': __('identifierSchemeCodeUnspsc190501.56131503'),
	'identifierSchemeCodeUnspsc190501.56131504': __('identifierSchemeCodeUnspsc190501.56131504'),
	'identifierSchemeCodeUnspsc190501.56131600': __('identifierSchemeCodeUnspsc190501.56131600'),
	'identifierSchemeCodeUnspsc190501.56131601': __('identifierSchemeCodeUnspsc190501.56131601'),
	'identifierSchemeCodeUnspsc190501.56131602': __('identifierSchemeCodeUnspsc190501.56131602'),
	'identifierSchemeCodeUnspsc190501.56131603': __('identifierSchemeCodeUnspsc190501.56131603'),
	'identifierSchemeCodeUnspsc190501.56131604': __('identifierSchemeCodeUnspsc190501.56131604'),
	'identifierSchemeCodeUnspsc190501.56131605': __('identifierSchemeCodeUnspsc190501.56131605'),
	'identifierSchemeCodeUnspsc190501.56131606': __('identifierSchemeCodeUnspsc190501.56131606'),
	'identifierSchemeCodeUnspsc190501.56131607': __('identifierSchemeCodeUnspsc190501.56131607'),
	'identifierSchemeCodeUnspsc190501.56131700': __('identifierSchemeCodeUnspsc190501.56131700'),
	'identifierSchemeCodeUnspsc190501.56131701': __('identifierSchemeCodeUnspsc190501.56131701'),
	'identifierSchemeCodeUnspsc190501.56131702': __('identifierSchemeCodeUnspsc190501.56131702'),
	'identifierSchemeCodeUnspsc190501.56131703': __('identifierSchemeCodeUnspsc190501.56131703'),
	'identifierSchemeCodeUnspsc190501.56140000': __('identifierSchemeCodeUnspsc190501.56140000'),
	'identifierSchemeCodeUnspsc190501.56141500': __('identifierSchemeCodeUnspsc190501.56141500'),
	'identifierSchemeCodeUnspsc190501.56141501': __('identifierSchemeCodeUnspsc190501.56141501'),
	'identifierSchemeCodeUnspsc190501.56141502': __('identifierSchemeCodeUnspsc190501.56141502'),
	'identifierSchemeCodeUnspsc190501.56141503': __('identifierSchemeCodeUnspsc190501.56141503'),
	'identifierSchemeCodeUnspsc190501.56141504': __('identifierSchemeCodeUnspsc190501.56141504'),
	'identifierSchemeCodeUnspsc190501.56141600': __('identifierSchemeCodeUnspsc190501.56141600'),
	'identifierSchemeCodeUnspsc190501.56141601': __('identifierSchemeCodeUnspsc190501.56141601'),
	'identifierSchemeCodeUnspsc190501.56141602': __('identifierSchemeCodeUnspsc190501.56141602'),
	'identifierSchemeCodeUnspsc190501.56141603': __('identifierSchemeCodeUnspsc190501.56141603'),
	'identifierSchemeCodeUnspsc190501.56141604': __('identifierSchemeCodeUnspsc190501.56141604'),
	'identifierSchemeCodeUnspsc190501.56141700': __('identifierSchemeCodeUnspsc190501.56141700'),
	'identifierSchemeCodeUnspsc190501.56141701': __('identifierSchemeCodeUnspsc190501.56141701'),
	'identifierSchemeCodeUnspsc190501.56141702': __('identifierSchemeCodeUnspsc190501.56141702'),
	'identifierSchemeCodeUnspsc190501.56141703': __('identifierSchemeCodeUnspsc190501.56141703'),
	'identifierSchemeCodeUnspsc190501.56141800': __('identifierSchemeCodeUnspsc190501.56141800'),
	'identifierSchemeCodeUnspsc190501.56141801': __('identifierSchemeCodeUnspsc190501.56141801'),
	'identifierSchemeCodeUnspsc190501.56141802': __('identifierSchemeCodeUnspsc190501.56141802'),
	'identifierSchemeCodeUnspsc190501.56141803': __('identifierSchemeCodeUnspsc190501.56141803'),
	'identifierSchemeCodeUnspsc190501.60000000': __('identifierSchemeCodeUnspsc190501.60000000'),
	'identifierSchemeCodeUnspsc190501.60100000': __('identifierSchemeCodeUnspsc190501.60100000'),
	'identifierSchemeCodeUnspsc190501.60101000': __('identifierSchemeCodeUnspsc190501.60101000'),
	'identifierSchemeCodeUnspsc190501.60101001': __('identifierSchemeCodeUnspsc190501.60101001'),
	'identifierSchemeCodeUnspsc190501.60101002': __('identifierSchemeCodeUnspsc190501.60101002'),
	'identifierSchemeCodeUnspsc190501.60101003': __('identifierSchemeCodeUnspsc190501.60101003'),
	'identifierSchemeCodeUnspsc190501.60101004': __('identifierSchemeCodeUnspsc190501.60101004'),
	'identifierSchemeCodeUnspsc190501.60101005': __('identifierSchemeCodeUnspsc190501.60101005'),
	'identifierSchemeCodeUnspsc190501.60101006': __('identifierSchemeCodeUnspsc190501.60101006'),
	'identifierSchemeCodeUnspsc190501.60101007': __('identifierSchemeCodeUnspsc190501.60101007'),
	'identifierSchemeCodeUnspsc190501.60101008': __('identifierSchemeCodeUnspsc190501.60101008'),
	'identifierSchemeCodeUnspsc190501.60101009': __('identifierSchemeCodeUnspsc190501.60101009'),
	'identifierSchemeCodeUnspsc190501.60101010': __('identifierSchemeCodeUnspsc190501.60101010'),
	'identifierSchemeCodeUnspsc190501.60101100': __('identifierSchemeCodeUnspsc190501.60101100'),
	'identifierSchemeCodeUnspsc190501.60101101': __('identifierSchemeCodeUnspsc190501.60101101'),
	'identifierSchemeCodeUnspsc190501.60101102': __('identifierSchemeCodeUnspsc190501.60101102'),
	'identifierSchemeCodeUnspsc190501.60101103': __('identifierSchemeCodeUnspsc190501.60101103'),
	'identifierSchemeCodeUnspsc190501.60101104': __('identifierSchemeCodeUnspsc190501.60101104'),
	'identifierSchemeCodeUnspsc190501.60101200': __('identifierSchemeCodeUnspsc190501.60101200'),
	'identifierSchemeCodeUnspsc190501.60101201': __('identifierSchemeCodeUnspsc190501.60101201'),
	'identifierSchemeCodeUnspsc190501.60101202': __('identifierSchemeCodeUnspsc190501.60101202'),
	'identifierSchemeCodeUnspsc190501.60101203': __('identifierSchemeCodeUnspsc190501.60101203'),
	'identifierSchemeCodeUnspsc190501.60101204': __('identifierSchemeCodeUnspsc190501.60101204'),
	'identifierSchemeCodeUnspsc190501.60101205': __('identifierSchemeCodeUnspsc190501.60101205'),
	'identifierSchemeCodeUnspsc190501.60101300': __('identifierSchemeCodeUnspsc190501.60101300'),
	'identifierSchemeCodeUnspsc190501.60101301': __('identifierSchemeCodeUnspsc190501.60101301'),
	'identifierSchemeCodeUnspsc190501.60101302': __('identifierSchemeCodeUnspsc190501.60101302'),
	'identifierSchemeCodeUnspsc190501.60101304': __('identifierSchemeCodeUnspsc190501.60101304'),
	'identifierSchemeCodeUnspsc190501.60101305': __('identifierSchemeCodeUnspsc190501.60101305'),
	'identifierSchemeCodeUnspsc190501.60101306': __('identifierSchemeCodeUnspsc190501.60101306'),
	'identifierSchemeCodeUnspsc190501.60101307': __('identifierSchemeCodeUnspsc190501.60101307'),
	'identifierSchemeCodeUnspsc190501.60101308': __('identifierSchemeCodeUnspsc190501.60101308'),
	'identifierSchemeCodeUnspsc190501.60101309': __('identifierSchemeCodeUnspsc190501.60101309'),
	'identifierSchemeCodeUnspsc190501.60101310': __('identifierSchemeCodeUnspsc190501.60101310'),
	'identifierSchemeCodeUnspsc190501.60101311': __('identifierSchemeCodeUnspsc190501.60101311'),
	'identifierSchemeCodeUnspsc190501.60101312': __('identifierSchemeCodeUnspsc190501.60101312'),
	'identifierSchemeCodeUnspsc190501.60101313': __('identifierSchemeCodeUnspsc190501.60101313'),
	'identifierSchemeCodeUnspsc190501.60101314': __('identifierSchemeCodeUnspsc190501.60101314'),
	'identifierSchemeCodeUnspsc190501.60101315': __('identifierSchemeCodeUnspsc190501.60101315'),
	'identifierSchemeCodeUnspsc190501.60101316': __('identifierSchemeCodeUnspsc190501.60101316'),
	'identifierSchemeCodeUnspsc190501.60101317': __('identifierSchemeCodeUnspsc190501.60101317'),
	'identifierSchemeCodeUnspsc190501.60101318': __('identifierSchemeCodeUnspsc190501.60101318'),
	'identifierSchemeCodeUnspsc190501.60101319': __('identifierSchemeCodeUnspsc190501.60101319'),
	'identifierSchemeCodeUnspsc190501.60101320': __('identifierSchemeCodeUnspsc190501.60101320'),
	'identifierSchemeCodeUnspsc190501.60101321': __('identifierSchemeCodeUnspsc190501.60101321'),
	'identifierSchemeCodeUnspsc190501.60101322': __('identifierSchemeCodeUnspsc190501.60101322'),
	'identifierSchemeCodeUnspsc190501.60101323': __('identifierSchemeCodeUnspsc190501.60101323'),
	'identifierSchemeCodeUnspsc190501.60101324': __('identifierSchemeCodeUnspsc190501.60101324'),
	'identifierSchemeCodeUnspsc190501.60101325': __('identifierSchemeCodeUnspsc190501.60101325'),
	'identifierSchemeCodeUnspsc190501.60101326': __('identifierSchemeCodeUnspsc190501.60101326'),
	'identifierSchemeCodeUnspsc190501.60101327': __('identifierSchemeCodeUnspsc190501.60101327'),
	'identifierSchemeCodeUnspsc190501.60101328': __('identifierSchemeCodeUnspsc190501.60101328'),
	'identifierSchemeCodeUnspsc190501.60101329': __('identifierSchemeCodeUnspsc190501.60101329'),
	'identifierSchemeCodeUnspsc190501.60101330': __('identifierSchemeCodeUnspsc190501.60101330'),
	'identifierSchemeCodeUnspsc190501.60101331': __('identifierSchemeCodeUnspsc190501.60101331'),
	'identifierSchemeCodeUnspsc190501.60101400': __('identifierSchemeCodeUnspsc190501.60101400'),
	'identifierSchemeCodeUnspsc190501.60101401': __('identifierSchemeCodeUnspsc190501.60101401'),
	'identifierSchemeCodeUnspsc190501.60101402': __('identifierSchemeCodeUnspsc190501.60101402'),
	'identifierSchemeCodeUnspsc190501.60101403': __('identifierSchemeCodeUnspsc190501.60101403'),
	'identifierSchemeCodeUnspsc190501.60101404': __('identifierSchemeCodeUnspsc190501.60101404'),
	'identifierSchemeCodeUnspsc190501.60101405': __('identifierSchemeCodeUnspsc190501.60101405'),
	'identifierSchemeCodeUnspsc190501.60101600': __('identifierSchemeCodeUnspsc190501.60101600'),
	'identifierSchemeCodeUnspsc190501.60101601': __('identifierSchemeCodeUnspsc190501.60101601'),
	'identifierSchemeCodeUnspsc190501.60101602': __('identifierSchemeCodeUnspsc190501.60101602'),
	'identifierSchemeCodeUnspsc190501.60101603': __('identifierSchemeCodeUnspsc190501.60101603'),
	'identifierSchemeCodeUnspsc190501.60101604': __('identifierSchemeCodeUnspsc190501.60101604'),
	'identifierSchemeCodeUnspsc190501.60101605': __('identifierSchemeCodeUnspsc190501.60101605'),
	'identifierSchemeCodeUnspsc190501.60101606': __('identifierSchemeCodeUnspsc190501.60101606'),
	'identifierSchemeCodeUnspsc190501.60101607': __('identifierSchemeCodeUnspsc190501.60101607'),
	'identifierSchemeCodeUnspsc190501.60101608': __('identifierSchemeCodeUnspsc190501.60101608'),
	'identifierSchemeCodeUnspsc190501.60101609': __('identifierSchemeCodeUnspsc190501.60101609'),
	'identifierSchemeCodeUnspsc190501.60101610': __('identifierSchemeCodeUnspsc190501.60101610'),
	'identifierSchemeCodeUnspsc190501.60101700': __('identifierSchemeCodeUnspsc190501.60101700'),
	'identifierSchemeCodeUnspsc190501.60101701': __('identifierSchemeCodeUnspsc190501.60101701'),
	'identifierSchemeCodeUnspsc190501.60101702': __('identifierSchemeCodeUnspsc190501.60101702'),
	'identifierSchemeCodeUnspsc190501.60101703': __('identifierSchemeCodeUnspsc190501.60101703'),
	'identifierSchemeCodeUnspsc190501.60101704': __('identifierSchemeCodeUnspsc190501.60101704'),
	'identifierSchemeCodeUnspsc190501.60101705': __('identifierSchemeCodeUnspsc190501.60101705'),
	'identifierSchemeCodeUnspsc190501.60101706': __('identifierSchemeCodeUnspsc190501.60101706'),
	'identifierSchemeCodeUnspsc190501.60101707': __('identifierSchemeCodeUnspsc190501.60101707'),
	'identifierSchemeCodeUnspsc190501.60101708': __('identifierSchemeCodeUnspsc190501.60101708'),
	'identifierSchemeCodeUnspsc190501.60101709': __('identifierSchemeCodeUnspsc190501.60101709'),
	'identifierSchemeCodeUnspsc190501.60101710': __('identifierSchemeCodeUnspsc190501.60101710'),
	'identifierSchemeCodeUnspsc190501.60101711': __('identifierSchemeCodeUnspsc190501.60101711'),
	'identifierSchemeCodeUnspsc190501.60101712': __('identifierSchemeCodeUnspsc190501.60101712'),
	'identifierSchemeCodeUnspsc190501.60101713': __('identifierSchemeCodeUnspsc190501.60101713'),
	'identifierSchemeCodeUnspsc190501.60101714': __('identifierSchemeCodeUnspsc190501.60101714'),
	'identifierSchemeCodeUnspsc190501.60101715': __('identifierSchemeCodeUnspsc190501.60101715'),
	'identifierSchemeCodeUnspsc190501.60101716': __('identifierSchemeCodeUnspsc190501.60101716'),
	'identifierSchemeCodeUnspsc190501.60101717': __('identifierSchemeCodeUnspsc190501.60101717'),
	'identifierSchemeCodeUnspsc190501.60101718': __('identifierSchemeCodeUnspsc190501.60101718'),
	'identifierSchemeCodeUnspsc190501.60101719': __('identifierSchemeCodeUnspsc190501.60101719'),
	'identifierSchemeCodeUnspsc190501.60101720': __('identifierSchemeCodeUnspsc190501.60101720'),
	'identifierSchemeCodeUnspsc190501.60101721': __('identifierSchemeCodeUnspsc190501.60101721'),
	'identifierSchemeCodeUnspsc190501.60101722': __('identifierSchemeCodeUnspsc190501.60101722'),
	'identifierSchemeCodeUnspsc190501.60101723': __('identifierSchemeCodeUnspsc190501.60101723'),
	'identifierSchemeCodeUnspsc190501.60101724': __('identifierSchemeCodeUnspsc190501.60101724'),
	'identifierSchemeCodeUnspsc190501.60101725': __('identifierSchemeCodeUnspsc190501.60101725'),
	'identifierSchemeCodeUnspsc190501.60101726': __('identifierSchemeCodeUnspsc190501.60101726'),
	'identifierSchemeCodeUnspsc190501.60101727': __('identifierSchemeCodeUnspsc190501.60101727'),
	'identifierSchemeCodeUnspsc190501.60101728': __('identifierSchemeCodeUnspsc190501.60101728'),
	'identifierSchemeCodeUnspsc190501.60101729': __('identifierSchemeCodeUnspsc190501.60101729'),
	'identifierSchemeCodeUnspsc190501.60101730': __('identifierSchemeCodeUnspsc190501.60101730'),
	'identifierSchemeCodeUnspsc190501.60101731': __('identifierSchemeCodeUnspsc190501.60101731'),
	'identifierSchemeCodeUnspsc190501.60101732': __('identifierSchemeCodeUnspsc190501.60101732'),
	'identifierSchemeCodeUnspsc190501.60101733': __('identifierSchemeCodeUnspsc190501.60101733'),
	'identifierSchemeCodeUnspsc190501.60101734': __('identifierSchemeCodeUnspsc190501.60101734'),
	'identifierSchemeCodeUnspsc190501.60101800': __('identifierSchemeCodeUnspsc190501.60101800'),
	'identifierSchemeCodeUnspsc190501.60101801': __('identifierSchemeCodeUnspsc190501.60101801'),
	'identifierSchemeCodeUnspsc190501.60101802': __('identifierSchemeCodeUnspsc190501.60101802'),
	'identifierSchemeCodeUnspsc190501.60101803': __('identifierSchemeCodeUnspsc190501.60101803'),
	'identifierSchemeCodeUnspsc190501.60101804': __('identifierSchemeCodeUnspsc190501.60101804'),
	'identifierSchemeCodeUnspsc190501.60101805': __('identifierSchemeCodeUnspsc190501.60101805'),
	'identifierSchemeCodeUnspsc190501.60101806': __('identifierSchemeCodeUnspsc190501.60101806'),
	'identifierSchemeCodeUnspsc190501.60101807': __('identifierSchemeCodeUnspsc190501.60101807'),
	'identifierSchemeCodeUnspsc190501.60101808': __('identifierSchemeCodeUnspsc190501.60101808'),
	'identifierSchemeCodeUnspsc190501.60101809': __('identifierSchemeCodeUnspsc190501.60101809'),
	'identifierSchemeCodeUnspsc190501.60101810': __('identifierSchemeCodeUnspsc190501.60101810'),
	'identifierSchemeCodeUnspsc190501.60101811': __('identifierSchemeCodeUnspsc190501.60101811'),
	'identifierSchemeCodeUnspsc190501.60101900': __('identifierSchemeCodeUnspsc190501.60101900'),
	'identifierSchemeCodeUnspsc190501.60101901': __('identifierSchemeCodeUnspsc190501.60101901'),
	'identifierSchemeCodeUnspsc190501.60101902': __('identifierSchemeCodeUnspsc190501.60101902'),
	'identifierSchemeCodeUnspsc190501.60101903': __('identifierSchemeCodeUnspsc190501.60101903'),
	'identifierSchemeCodeUnspsc190501.60101904': __('identifierSchemeCodeUnspsc190501.60101904'),
	'identifierSchemeCodeUnspsc190501.60101905': __('identifierSchemeCodeUnspsc190501.60101905'),
	'identifierSchemeCodeUnspsc190501.60101906': __('identifierSchemeCodeUnspsc190501.60101906'),
	'identifierSchemeCodeUnspsc190501.60101907': __('identifierSchemeCodeUnspsc190501.60101907'),
	'identifierSchemeCodeUnspsc190501.60101908': __('identifierSchemeCodeUnspsc190501.60101908'),
	'identifierSchemeCodeUnspsc190501.60101909': __('identifierSchemeCodeUnspsc190501.60101909'),
	'identifierSchemeCodeUnspsc190501.60101910': __('identifierSchemeCodeUnspsc190501.60101910'),
	'identifierSchemeCodeUnspsc190501.60101911': __('identifierSchemeCodeUnspsc190501.60101911'),
	'identifierSchemeCodeUnspsc190501.60102000': __('identifierSchemeCodeUnspsc190501.60102000'),
	'identifierSchemeCodeUnspsc190501.60102001': __('identifierSchemeCodeUnspsc190501.60102001'),
	'identifierSchemeCodeUnspsc190501.60102002': __('identifierSchemeCodeUnspsc190501.60102002'),
	'identifierSchemeCodeUnspsc190501.60102003': __('identifierSchemeCodeUnspsc190501.60102003'),
	'identifierSchemeCodeUnspsc190501.60102004': __('identifierSchemeCodeUnspsc190501.60102004'),
	'identifierSchemeCodeUnspsc190501.60102005': __('identifierSchemeCodeUnspsc190501.60102005'),
	'identifierSchemeCodeUnspsc190501.60102006': __('identifierSchemeCodeUnspsc190501.60102006'),
	'identifierSchemeCodeUnspsc190501.60102007': __('identifierSchemeCodeUnspsc190501.60102007'),
	'identifierSchemeCodeUnspsc190501.60102100': __('identifierSchemeCodeUnspsc190501.60102100'),
	'identifierSchemeCodeUnspsc190501.60102101': __('identifierSchemeCodeUnspsc190501.60102101'),
	'identifierSchemeCodeUnspsc190501.60102102': __('identifierSchemeCodeUnspsc190501.60102102'),
	'identifierSchemeCodeUnspsc190501.60102103': __('identifierSchemeCodeUnspsc190501.60102103'),
	'identifierSchemeCodeUnspsc190501.60102104': __('identifierSchemeCodeUnspsc190501.60102104'),
	'identifierSchemeCodeUnspsc190501.60102105': __('identifierSchemeCodeUnspsc190501.60102105'),
	'identifierSchemeCodeUnspsc190501.60102106': __('identifierSchemeCodeUnspsc190501.60102106'),
	'identifierSchemeCodeUnspsc190501.60102200': __('identifierSchemeCodeUnspsc190501.60102200'),
	'identifierSchemeCodeUnspsc190501.60102201': __('identifierSchemeCodeUnspsc190501.60102201'),
	'identifierSchemeCodeUnspsc190501.60102202': __('identifierSchemeCodeUnspsc190501.60102202'),
	'identifierSchemeCodeUnspsc190501.60102203': __('identifierSchemeCodeUnspsc190501.60102203'),
	'identifierSchemeCodeUnspsc190501.60102204': __('identifierSchemeCodeUnspsc190501.60102204'),
	'identifierSchemeCodeUnspsc190501.60102205': __('identifierSchemeCodeUnspsc190501.60102205'),
	'identifierSchemeCodeUnspsc190501.60102206': __('identifierSchemeCodeUnspsc190501.60102206'),
	'identifierSchemeCodeUnspsc190501.60102300': __('identifierSchemeCodeUnspsc190501.60102300'),
	'identifierSchemeCodeUnspsc190501.60102301': __('identifierSchemeCodeUnspsc190501.60102301'),
	'identifierSchemeCodeUnspsc190501.60102302': __('identifierSchemeCodeUnspsc190501.60102302'),
	'identifierSchemeCodeUnspsc190501.60102303': __('identifierSchemeCodeUnspsc190501.60102303'),
	'identifierSchemeCodeUnspsc190501.60102304': __('identifierSchemeCodeUnspsc190501.60102304'),
	'identifierSchemeCodeUnspsc190501.60102305': __('identifierSchemeCodeUnspsc190501.60102305'),
	'identifierSchemeCodeUnspsc190501.60102306': __('identifierSchemeCodeUnspsc190501.60102306'),
	'identifierSchemeCodeUnspsc190501.60102307': __('identifierSchemeCodeUnspsc190501.60102307'),
	'identifierSchemeCodeUnspsc190501.60102308': __('identifierSchemeCodeUnspsc190501.60102308'),
	'identifierSchemeCodeUnspsc190501.60102309': __('identifierSchemeCodeUnspsc190501.60102309'),
	'identifierSchemeCodeUnspsc190501.60102310': __('identifierSchemeCodeUnspsc190501.60102310'),
	'identifierSchemeCodeUnspsc190501.60102311': __('identifierSchemeCodeUnspsc190501.60102311'),
	'identifierSchemeCodeUnspsc190501.60102312': __('identifierSchemeCodeUnspsc190501.60102312'),
	'identifierSchemeCodeUnspsc190501.60102400': __('identifierSchemeCodeUnspsc190501.60102400'),
	'identifierSchemeCodeUnspsc190501.60102401': __('identifierSchemeCodeUnspsc190501.60102401'),
	'identifierSchemeCodeUnspsc190501.60102402': __('identifierSchemeCodeUnspsc190501.60102402'),
	'identifierSchemeCodeUnspsc190501.60102403': __('identifierSchemeCodeUnspsc190501.60102403'),
	'identifierSchemeCodeUnspsc190501.60102404': __('identifierSchemeCodeUnspsc190501.60102404'),
	'identifierSchemeCodeUnspsc190501.60102405': __('identifierSchemeCodeUnspsc190501.60102405'),
	'identifierSchemeCodeUnspsc190501.60102406': __('identifierSchemeCodeUnspsc190501.60102406'),
	'identifierSchemeCodeUnspsc190501.60102407': __('identifierSchemeCodeUnspsc190501.60102407'),
	'identifierSchemeCodeUnspsc190501.60102408': __('identifierSchemeCodeUnspsc190501.60102408'),
	'identifierSchemeCodeUnspsc190501.60102409': __('identifierSchemeCodeUnspsc190501.60102409'),
	'identifierSchemeCodeUnspsc190501.60102410': __('identifierSchemeCodeUnspsc190501.60102410'),
	'identifierSchemeCodeUnspsc190501.60102411': __('identifierSchemeCodeUnspsc190501.60102411'),
	'identifierSchemeCodeUnspsc190501.60102412': __('identifierSchemeCodeUnspsc190501.60102412'),
	'identifierSchemeCodeUnspsc190501.60102413': __('identifierSchemeCodeUnspsc190501.60102413'),
	'identifierSchemeCodeUnspsc190501.60102414': __('identifierSchemeCodeUnspsc190501.60102414'),
	'identifierSchemeCodeUnspsc190501.60102500': __('identifierSchemeCodeUnspsc190501.60102500'),
	'identifierSchemeCodeUnspsc190501.60102501': __('identifierSchemeCodeUnspsc190501.60102501'),
	'identifierSchemeCodeUnspsc190501.60102502': __('identifierSchemeCodeUnspsc190501.60102502'),
	'identifierSchemeCodeUnspsc190501.60102503': __('identifierSchemeCodeUnspsc190501.60102503'),
	'identifierSchemeCodeUnspsc190501.60102504': __('identifierSchemeCodeUnspsc190501.60102504'),
	'identifierSchemeCodeUnspsc190501.60102505': __('identifierSchemeCodeUnspsc190501.60102505'),
	'identifierSchemeCodeUnspsc190501.60102506': __('identifierSchemeCodeUnspsc190501.60102506'),
	'identifierSchemeCodeUnspsc190501.60102507': __('identifierSchemeCodeUnspsc190501.60102507'),
	'identifierSchemeCodeUnspsc190501.60102508': __('identifierSchemeCodeUnspsc190501.60102508'),
	'identifierSchemeCodeUnspsc190501.60102509': __('identifierSchemeCodeUnspsc190501.60102509'),
	'identifierSchemeCodeUnspsc190501.60102510': __('identifierSchemeCodeUnspsc190501.60102510'),
	'identifierSchemeCodeUnspsc190501.60102511': __('identifierSchemeCodeUnspsc190501.60102511'),
	'identifierSchemeCodeUnspsc190501.60102512': __('identifierSchemeCodeUnspsc190501.60102512'),
	'identifierSchemeCodeUnspsc190501.60102513': __('identifierSchemeCodeUnspsc190501.60102513'),
	'identifierSchemeCodeUnspsc190501.60102600': __('identifierSchemeCodeUnspsc190501.60102600'),
	'identifierSchemeCodeUnspsc190501.60102601': __('identifierSchemeCodeUnspsc190501.60102601'),
	'identifierSchemeCodeUnspsc190501.60102602': __('identifierSchemeCodeUnspsc190501.60102602'),
	'identifierSchemeCodeUnspsc190501.60102603': __('identifierSchemeCodeUnspsc190501.60102603'),
	'identifierSchemeCodeUnspsc190501.60102604': __('identifierSchemeCodeUnspsc190501.60102604'),
	'identifierSchemeCodeUnspsc190501.60102605': __('identifierSchemeCodeUnspsc190501.60102605'),
	'identifierSchemeCodeUnspsc190501.60102606': __('identifierSchemeCodeUnspsc190501.60102606'),
	'identifierSchemeCodeUnspsc190501.60102607': __('identifierSchemeCodeUnspsc190501.60102607'),
	'identifierSchemeCodeUnspsc190501.60102608': __('identifierSchemeCodeUnspsc190501.60102608'),
	'identifierSchemeCodeUnspsc190501.60102609': __('identifierSchemeCodeUnspsc190501.60102609'),
	'identifierSchemeCodeUnspsc190501.60102610': __('identifierSchemeCodeUnspsc190501.60102610'),
	'identifierSchemeCodeUnspsc190501.60102611': __('identifierSchemeCodeUnspsc190501.60102611'),
	'identifierSchemeCodeUnspsc190501.60102612': __('identifierSchemeCodeUnspsc190501.60102612'),
	'identifierSchemeCodeUnspsc190501.60102613': __('identifierSchemeCodeUnspsc190501.60102613'),
	'identifierSchemeCodeUnspsc190501.60102614': __('identifierSchemeCodeUnspsc190501.60102614'),
	'identifierSchemeCodeUnspsc190501.60102700': __('identifierSchemeCodeUnspsc190501.60102700'),
	'identifierSchemeCodeUnspsc190501.60102701': __('identifierSchemeCodeUnspsc190501.60102701'),
	'identifierSchemeCodeUnspsc190501.60102702': __('identifierSchemeCodeUnspsc190501.60102702'),
	'identifierSchemeCodeUnspsc190501.60102703': __('identifierSchemeCodeUnspsc190501.60102703'),
	'identifierSchemeCodeUnspsc190501.60102704': __('identifierSchemeCodeUnspsc190501.60102704'),
	'identifierSchemeCodeUnspsc190501.60102705': __('identifierSchemeCodeUnspsc190501.60102705'),
	'identifierSchemeCodeUnspsc190501.60102706': __('identifierSchemeCodeUnspsc190501.60102706'),
	'identifierSchemeCodeUnspsc190501.60102707': __('identifierSchemeCodeUnspsc190501.60102707'),
	'identifierSchemeCodeUnspsc190501.60102708': __('identifierSchemeCodeUnspsc190501.60102708'),
	'identifierSchemeCodeUnspsc190501.60102709': __('identifierSchemeCodeUnspsc190501.60102709'),
	'identifierSchemeCodeUnspsc190501.60102710': __('identifierSchemeCodeUnspsc190501.60102710'),
	'identifierSchemeCodeUnspsc190501.60102711': __('identifierSchemeCodeUnspsc190501.60102711'),
	'identifierSchemeCodeUnspsc190501.60102712': __('identifierSchemeCodeUnspsc190501.60102712'),
	'identifierSchemeCodeUnspsc190501.60102713': __('identifierSchemeCodeUnspsc190501.60102713'),
	'identifierSchemeCodeUnspsc190501.60102714': __('identifierSchemeCodeUnspsc190501.60102714'),
	'identifierSchemeCodeUnspsc190501.60102715': __('identifierSchemeCodeUnspsc190501.60102715'),
	'identifierSchemeCodeUnspsc190501.60102717': __('identifierSchemeCodeUnspsc190501.60102717'),
	'identifierSchemeCodeUnspsc190501.60102718': __('identifierSchemeCodeUnspsc190501.60102718'),
	'identifierSchemeCodeUnspsc190501.60102800': __('identifierSchemeCodeUnspsc190501.60102800'),
	'identifierSchemeCodeUnspsc190501.60102801': __('identifierSchemeCodeUnspsc190501.60102801'),
	'identifierSchemeCodeUnspsc190501.60102802': __('identifierSchemeCodeUnspsc190501.60102802'),
	'identifierSchemeCodeUnspsc190501.60102803': __('identifierSchemeCodeUnspsc190501.60102803'),
	'identifierSchemeCodeUnspsc190501.60102804': __('identifierSchemeCodeUnspsc190501.60102804'),
	'identifierSchemeCodeUnspsc190501.60102805': __('identifierSchemeCodeUnspsc190501.60102805'),
	'identifierSchemeCodeUnspsc190501.60102806': __('identifierSchemeCodeUnspsc190501.60102806'),
	'identifierSchemeCodeUnspsc190501.60102807': __('identifierSchemeCodeUnspsc190501.60102807'),
	'identifierSchemeCodeUnspsc190501.60102900': __('identifierSchemeCodeUnspsc190501.60102900'),
	'identifierSchemeCodeUnspsc190501.60102901': __('identifierSchemeCodeUnspsc190501.60102901'),
	'identifierSchemeCodeUnspsc190501.60102902': __('identifierSchemeCodeUnspsc190501.60102902'),
	'identifierSchemeCodeUnspsc190501.60102903': __('identifierSchemeCodeUnspsc190501.60102903'),
	'identifierSchemeCodeUnspsc190501.60102904': __('identifierSchemeCodeUnspsc190501.60102904'),
	'identifierSchemeCodeUnspsc190501.60102905': __('identifierSchemeCodeUnspsc190501.60102905'),
	'identifierSchemeCodeUnspsc190501.60102906': __('identifierSchemeCodeUnspsc190501.60102906'),
	'identifierSchemeCodeUnspsc190501.60102907': __('identifierSchemeCodeUnspsc190501.60102907'),
	'identifierSchemeCodeUnspsc190501.60102908': __('identifierSchemeCodeUnspsc190501.60102908'),
	'identifierSchemeCodeUnspsc190501.60102909': __('identifierSchemeCodeUnspsc190501.60102909'),
	'identifierSchemeCodeUnspsc190501.60102910': __('identifierSchemeCodeUnspsc190501.60102910'),
	'identifierSchemeCodeUnspsc190501.60102911': __('identifierSchemeCodeUnspsc190501.60102911'),
	'identifierSchemeCodeUnspsc190501.60102912': __('identifierSchemeCodeUnspsc190501.60102912'),
	'identifierSchemeCodeUnspsc190501.60102913': __('identifierSchemeCodeUnspsc190501.60102913'),
	'identifierSchemeCodeUnspsc190501.60102914': __('identifierSchemeCodeUnspsc190501.60102914'),
	'identifierSchemeCodeUnspsc190501.60102915': __('identifierSchemeCodeUnspsc190501.60102915'),
	'identifierSchemeCodeUnspsc190501.60102916': __('identifierSchemeCodeUnspsc190501.60102916'),
	'identifierSchemeCodeUnspsc190501.60102917': __('identifierSchemeCodeUnspsc190501.60102917'),
	'identifierSchemeCodeUnspsc190501.60103000': __('identifierSchemeCodeUnspsc190501.60103000'),
	'identifierSchemeCodeUnspsc190501.60103001': __('identifierSchemeCodeUnspsc190501.60103001'),
	'identifierSchemeCodeUnspsc190501.60103002': __('identifierSchemeCodeUnspsc190501.60103002'),
	'identifierSchemeCodeUnspsc190501.60103003': __('identifierSchemeCodeUnspsc190501.60103003'),
	'identifierSchemeCodeUnspsc190501.60103004': __('identifierSchemeCodeUnspsc190501.60103004'),
	'identifierSchemeCodeUnspsc190501.60103005': __('identifierSchemeCodeUnspsc190501.60103005'),
	'identifierSchemeCodeUnspsc190501.60103006': __('identifierSchemeCodeUnspsc190501.60103006'),
	'identifierSchemeCodeUnspsc190501.60103007': __('identifierSchemeCodeUnspsc190501.60103007'),
	'identifierSchemeCodeUnspsc190501.60103008': __('identifierSchemeCodeUnspsc190501.60103008'),
	'identifierSchemeCodeUnspsc190501.60103009': __('identifierSchemeCodeUnspsc190501.60103009'),
	'identifierSchemeCodeUnspsc190501.60103010': __('identifierSchemeCodeUnspsc190501.60103010'),
	'identifierSchemeCodeUnspsc190501.60103012': __('identifierSchemeCodeUnspsc190501.60103012'),
	'identifierSchemeCodeUnspsc190501.60103013': __('identifierSchemeCodeUnspsc190501.60103013'),
	'identifierSchemeCodeUnspsc190501.60103100': __('identifierSchemeCodeUnspsc190501.60103100'),
	'identifierSchemeCodeUnspsc190501.60103101': __('identifierSchemeCodeUnspsc190501.60103101'),
	'identifierSchemeCodeUnspsc190501.60103102': __('identifierSchemeCodeUnspsc190501.60103102'),
	'identifierSchemeCodeUnspsc190501.60103103': __('identifierSchemeCodeUnspsc190501.60103103'),
	'identifierSchemeCodeUnspsc190501.60103104': __('identifierSchemeCodeUnspsc190501.60103104'),
	'identifierSchemeCodeUnspsc190501.60103105': __('identifierSchemeCodeUnspsc190501.60103105'),
	'identifierSchemeCodeUnspsc190501.60103106': __('identifierSchemeCodeUnspsc190501.60103106'),
	'identifierSchemeCodeUnspsc190501.60103107': __('identifierSchemeCodeUnspsc190501.60103107'),
	'identifierSchemeCodeUnspsc190501.60103108': __('identifierSchemeCodeUnspsc190501.60103108'),
	'identifierSchemeCodeUnspsc190501.60103109': __('identifierSchemeCodeUnspsc190501.60103109'),
	'identifierSchemeCodeUnspsc190501.60103110': __('identifierSchemeCodeUnspsc190501.60103110'),
	'identifierSchemeCodeUnspsc190501.60103111': __('identifierSchemeCodeUnspsc190501.60103111'),
	'identifierSchemeCodeUnspsc190501.60103112': __('identifierSchemeCodeUnspsc190501.60103112'),
	'identifierSchemeCodeUnspsc190501.60103200': __('identifierSchemeCodeUnspsc190501.60103200'),
	'identifierSchemeCodeUnspsc190501.60103201': __('identifierSchemeCodeUnspsc190501.60103201'),
	'identifierSchemeCodeUnspsc190501.60103202': __('identifierSchemeCodeUnspsc190501.60103202'),
	'identifierSchemeCodeUnspsc190501.60103203': __('identifierSchemeCodeUnspsc190501.60103203'),
	'identifierSchemeCodeUnspsc190501.60103204': __('identifierSchemeCodeUnspsc190501.60103204'),
	'identifierSchemeCodeUnspsc190501.60103300': __('identifierSchemeCodeUnspsc190501.60103300'),
	'identifierSchemeCodeUnspsc190501.60103301': __('identifierSchemeCodeUnspsc190501.60103301'),
	'identifierSchemeCodeUnspsc190501.60103302': __('identifierSchemeCodeUnspsc190501.60103302'),
	'identifierSchemeCodeUnspsc190501.60103303': __('identifierSchemeCodeUnspsc190501.60103303'),
	'identifierSchemeCodeUnspsc190501.60103400': __('identifierSchemeCodeUnspsc190501.60103400'),
	'identifierSchemeCodeUnspsc190501.60103401': __('identifierSchemeCodeUnspsc190501.60103401'),
	'identifierSchemeCodeUnspsc190501.60103402': __('identifierSchemeCodeUnspsc190501.60103402'),
	'identifierSchemeCodeUnspsc190501.60103403': __('identifierSchemeCodeUnspsc190501.60103403'),
	'identifierSchemeCodeUnspsc190501.60103404': __('identifierSchemeCodeUnspsc190501.60103404'),
	'identifierSchemeCodeUnspsc190501.60103405': __('identifierSchemeCodeUnspsc190501.60103405'),
	'identifierSchemeCodeUnspsc190501.60103406': __('identifierSchemeCodeUnspsc190501.60103406'),
	'identifierSchemeCodeUnspsc190501.60103407': __('identifierSchemeCodeUnspsc190501.60103407'),
	'identifierSchemeCodeUnspsc190501.60103408': __('identifierSchemeCodeUnspsc190501.60103408'),
	'identifierSchemeCodeUnspsc190501.60103409': __('identifierSchemeCodeUnspsc190501.60103409'),
	'identifierSchemeCodeUnspsc190501.60103410': __('identifierSchemeCodeUnspsc190501.60103410'),
	'identifierSchemeCodeUnspsc190501.60103500': __('identifierSchemeCodeUnspsc190501.60103500'),
	'identifierSchemeCodeUnspsc190501.60103501': __('identifierSchemeCodeUnspsc190501.60103501'),
	'identifierSchemeCodeUnspsc190501.60103502': __('identifierSchemeCodeUnspsc190501.60103502'),
	'identifierSchemeCodeUnspsc190501.60103503': __('identifierSchemeCodeUnspsc190501.60103503'),
	'identifierSchemeCodeUnspsc190501.60103504': __('identifierSchemeCodeUnspsc190501.60103504'),
	'identifierSchemeCodeUnspsc190501.60103600': __('identifierSchemeCodeUnspsc190501.60103600'),
	'identifierSchemeCodeUnspsc190501.60103601': __('identifierSchemeCodeUnspsc190501.60103601'),
	'identifierSchemeCodeUnspsc190501.60103602': __('identifierSchemeCodeUnspsc190501.60103602'),
	'identifierSchemeCodeUnspsc190501.60103603': __('identifierSchemeCodeUnspsc190501.60103603'),
	'identifierSchemeCodeUnspsc190501.60103604': __('identifierSchemeCodeUnspsc190501.60103604'),
	'identifierSchemeCodeUnspsc190501.60103605': __('identifierSchemeCodeUnspsc190501.60103605'),
	'identifierSchemeCodeUnspsc190501.60103606': __('identifierSchemeCodeUnspsc190501.60103606'),
	'identifierSchemeCodeUnspsc190501.60103700': __('identifierSchemeCodeUnspsc190501.60103700'),
	'identifierSchemeCodeUnspsc190501.60103701': __('identifierSchemeCodeUnspsc190501.60103701'),
	'identifierSchemeCodeUnspsc190501.60103702': __('identifierSchemeCodeUnspsc190501.60103702'),
	'identifierSchemeCodeUnspsc190501.60103703': __('identifierSchemeCodeUnspsc190501.60103703'),
	'identifierSchemeCodeUnspsc190501.60103704': __('identifierSchemeCodeUnspsc190501.60103704'),
	'identifierSchemeCodeUnspsc190501.60103705': __('identifierSchemeCodeUnspsc190501.60103705'),
	'identifierSchemeCodeUnspsc190501.60103706': __('identifierSchemeCodeUnspsc190501.60103706'),
	'identifierSchemeCodeUnspsc190501.60103800': __('identifierSchemeCodeUnspsc190501.60103800'),
	'identifierSchemeCodeUnspsc190501.60103801': __('identifierSchemeCodeUnspsc190501.60103801'),
	'identifierSchemeCodeUnspsc190501.60103802': __('identifierSchemeCodeUnspsc190501.60103802'),
	'identifierSchemeCodeUnspsc190501.60103803': __('identifierSchemeCodeUnspsc190501.60103803'),
	'identifierSchemeCodeUnspsc190501.60103804': __('identifierSchemeCodeUnspsc190501.60103804'),
	'identifierSchemeCodeUnspsc190501.60103805': __('identifierSchemeCodeUnspsc190501.60103805'),
	'identifierSchemeCodeUnspsc190501.60103806': __('identifierSchemeCodeUnspsc190501.60103806'),
	'identifierSchemeCodeUnspsc190501.60103807': __('identifierSchemeCodeUnspsc190501.60103807'),
	'identifierSchemeCodeUnspsc190501.60103808': __('identifierSchemeCodeUnspsc190501.60103808'),
	'identifierSchemeCodeUnspsc190501.60103809': __('identifierSchemeCodeUnspsc190501.60103809'),
	'identifierSchemeCodeUnspsc190501.60103900': __('identifierSchemeCodeUnspsc190501.60103900'),
	'identifierSchemeCodeUnspsc190501.60103903': __('identifierSchemeCodeUnspsc190501.60103903'),
	'identifierSchemeCodeUnspsc190501.60103904': __('identifierSchemeCodeUnspsc190501.60103904'),
	'identifierSchemeCodeUnspsc190501.60103905': __('identifierSchemeCodeUnspsc190501.60103905'),
	'identifierSchemeCodeUnspsc190501.60103906': __('identifierSchemeCodeUnspsc190501.60103906'),
	'identifierSchemeCodeUnspsc190501.60103907': __('identifierSchemeCodeUnspsc190501.60103907'),
	'identifierSchemeCodeUnspsc190501.60103908': __('identifierSchemeCodeUnspsc190501.60103908'),
	'identifierSchemeCodeUnspsc190501.60103909': __('identifierSchemeCodeUnspsc190501.60103909'),
	'identifierSchemeCodeUnspsc190501.60103911': __('identifierSchemeCodeUnspsc190501.60103911'),
	'identifierSchemeCodeUnspsc190501.60103915': __('identifierSchemeCodeUnspsc190501.60103915'),
	'identifierSchemeCodeUnspsc190501.60103916': __('identifierSchemeCodeUnspsc190501.60103916'),
	'identifierSchemeCodeUnspsc190501.60103918': __('identifierSchemeCodeUnspsc190501.60103918'),
	'identifierSchemeCodeUnspsc190501.60103919': __('identifierSchemeCodeUnspsc190501.60103919'),
	'identifierSchemeCodeUnspsc190501.60103920': __('identifierSchemeCodeUnspsc190501.60103920'),
	'identifierSchemeCodeUnspsc190501.60103921': __('identifierSchemeCodeUnspsc190501.60103921'),
	'identifierSchemeCodeUnspsc190501.60103922': __('identifierSchemeCodeUnspsc190501.60103922'),
	'identifierSchemeCodeUnspsc190501.60103923': __('identifierSchemeCodeUnspsc190501.60103923'),
	'identifierSchemeCodeUnspsc190501.60103924': __('identifierSchemeCodeUnspsc190501.60103924'),
	'identifierSchemeCodeUnspsc190501.60103925': __('identifierSchemeCodeUnspsc190501.60103925'),
	'identifierSchemeCodeUnspsc190501.60103926': __('identifierSchemeCodeUnspsc190501.60103926'),
	'identifierSchemeCodeUnspsc190501.60103927': __('identifierSchemeCodeUnspsc190501.60103927'),
	'identifierSchemeCodeUnspsc190501.60103928': __('identifierSchemeCodeUnspsc190501.60103928'),
	'identifierSchemeCodeUnspsc190501.60103929': __('identifierSchemeCodeUnspsc190501.60103929'),
	'identifierSchemeCodeUnspsc190501.60103930': __('identifierSchemeCodeUnspsc190501.60103930'),
	'identifierSchemeCodeUnspsc190501.60103931': __('identifierSchemeCodeUnspsc190501.60103931'),
	'identifierSchemeCodeUnspsc190501.60103932': __('identifierSchemeCodeUnspsc190501.60103932'),
	'identifierSchemeCodeUnspsc190501.60103933': __('identifierSchemeCodeUnspsc190501.60103933'),
	'identifierSchemeCodeUnspsc190501.60103934': __('identifierSchemeCodeUnspsc190501.60103934'),
	'identifierSchemeCodeUnspsc190501.60103936': __('identifierSchemeCodeUnspsc190501.60103936'),
	'identifierSchemeCodeUnspsc190501.60104000': __('identifierSchemeCodeUnspsc190501.60104000'),
	'identifierSchemeCodeUnspsc190501.60104001': __('identifierSchemeCodeUnspsc190501.60104001'),
	'identifierSchemeCodeUnspsc190501.60104002': __('identifierSchemeCodeUnspsc190501.60104002'),
	'identifierSchemeCodeUnspsc190501.60104003': __('identifierSchemeCodeUnspsc190501.60104003'),
	'identifierSchemeCodeUnspsc190501.60104004': __('identifierSchemeCodeUnspsc190501.60104004'),
	'identifierSchemeCodeUnspsc190501.60104005': __('identifierSchemeCodeUnspsc190501.60104005'),
	'identifierSchemeCodeUnspsc190501.60104006': __('identifierSchemeCodeUnspsc190501.60104006'),
	'identifierSchemeCodeUnspsc190501.60104007': __('identifierSchemeCodeUnspsc190501.60104007'),
	'identifierSchemeCodeUnspsc190501.60104008': __('identifierSchemeCodeUnspsc190501.60104008'),
	'identifierSchemeCodeUnspsc190501.60104100': __('identifierSchemeCodeUnspsc190501.60104100'),
	'identifierSchemeCodeUnspsc190501.60104101': __('identifierSchemeCodeUnspsc190501.60104101'),
	'identifierSchemeCodeUnspsc190501.60104102': __('identifierSchemeCodeUnspsc190501.60104102'),
	'identifierSchemeCodeUnspsc190501.60104103': __('identifierSchemeCodeUnspsc190501.60104103'),
	'identifierSchemeCodeUnspsc190501.60104104': __('identifierSchemeCodeUnspsc190501.60104104'),
	'identifierSchemeCodeUnspsc190501.60104105': __('identifierSchemeCodeUnspsc190501.60104105'),
	'identifierSchemeCodeUnspsc190501.60104106': __('identifierSchemeCodeUnspsc190501.60104106'),
	'identifierSchemeCodeUnspsc190501.60104107': __('identifierSchemeCodeUnspsc190501.60104107'),
	'identifierSchemeCodeUnspsc190501.60104200': __('identifierSchemeCodeUnspsc190501.60104200'),
	'identifierSchemeCodeUnspsc190501.60104201': __('identifierSchemeCodeUnspsc190501.60104201'),
	'identifierSchemeCodeUnspsc190501.60104202': __('identifierSchemeCodeUnspsc190501.60104202'),
	'identifierSchemeCodeUnspsc190501.60104203': __('identifierSchemeCodeUnspsc190501.60104203'),
	'identifierSchemeCodeUnspsc190501.60104204': __('identifierSchemeCodeUnspsc190501.60104204'),
	'identifierSchemeCodeUnspsc190501.60104300': __('identifierSchemeCodeUnspsc190501.60104300'),
	'identifierSchemeCodeUnspsc190501.60104301': __('identifierSchemeCodeUnspsc190501.60104301'),
	'identifierSchemeCodeUnspsc190501.60104302': __('identifierSchemeCodeUnspsc190501.60104302'),
	'identifierSchemeCodeUnspsc190501.60104303': __('identifierSchemeCodeUnspsc190501.60104303'),
	'identifierSchemeCodeUnspsc190501.60104304': __('identifierSchemeCodeUnspsc190501.60104304'),
	'identifierSchemeCodeUnspsc190501.60104305': __('identifierSchemeCodeUnspsc190501.60104305'),
	'identifierSchemeCodeUnspsc190501.60104306': __('identifierSchemeCodeUnspsc190501.60104306'),
	'identifierSchemeCodeUnspsc190501.60104307': __('identifierSchemeCodeUnspsc190501.60104307'),
	'identifierSchemeCodeUnspsc190501.60104308': __('identifierSchemeCodeUnspsc190501.60104308'),
	'identifierSchemeCodeUnspsc190501.60104309': __('identifierSchemeCodeUnspsc190501.60104309'),
	'identifierSchemeCodeUnspsc190501.60104400': __('identifierSchemeCodeUnspsc190501.60104400'),
	'identifierSchemeCodeUnspsc190501.60104401': __('identifierSchemeCodeUnspsc190501.60104401'),
	'identifierSchemeCodeUnspsc190501.60104402': __('identifierSchemeCodeUnspsc190501.60104402'),
	'identifierSchemeCodeUnspsc190501.60104403': __('identifierSchemeCodeUnspsc190501.60104403'),
	'identifierSchemeCodeUnspsc190501.60104404': __('identifierSchemeCodeUnspsc190501.60104404'),
	'identifierSchemeCodeUnspsc190501.60104405': __('identifierSchemeCodeUnspsc190501.60104405'),
	'identifierSchemeCodeUnspsc190501.60104406': __('identifierSchemeCodeUnspsc190501.60104406'),
	'identifierSchemeCodeUnspsc190501.60104407': __('identifierSchemeCodeUnspsc190501.60104407'),
	'identifierSchemeCodeUnspsc190501.60104408': __('identifierSchemeCodeUnspsc190501.60104408'),
	'identifierSchemeCodeUnspsc190501.60104409': __('identifierSchemeCodeUnspsc190501.60104409'),
	'identifierSchemeCodeUnspsc190501.60104410': __('identifierSchemeCodeUnspsc190501.60104410'),
	'identifierSchemeCodeUnspsc190501.60104411': __('identifierSchemeCodeUnspsc190501.60104411'),
	'identifierSchemeCodeUnspsc190501.60104412': __('identifierSchemeCodeUnspsc190501.60104412'),
	'identifierSchemeCodeUnspsc190501.60104413': __('identifierSchemeCodeUnspsc190501.60104413'),
	'identifierSchemeCodeUnspsc190501.60104414': __('identifierSchemeCodeUnspsc190501.60104414'),
	'identifierSchemeCodeUnspsc190501.60104415': __('identifierSchemeCodeUnspsc190501.60104415'),
	'identifierSchemeCodeUnspsc190501.60104416': __('identifierSchemeCodeUnspsc190501.60104416'),
	'identifierSchemeCodeUnspsc190501.60104500': __('identifierSchemeCodeUnspsc190501.60104500'),
	'identifierSchemeCodeUnspsc190501.60104501': __('identifierSchemeCodeUnspsc190501.60104501'),
	'identifierSchemeCodeUnspsc190501.60104502': __('identifierSchemeCodeUnspsc190501.60104502'),
	'identifierSchemeCodeUnspsc190501.60104503': __('identifierSchemeCodeUnspsc190501.60104503'),
	'identifierSchemeCodeUnspsc190501.60104504': __('identifierSchemeCodeUnspsc190501.60104504'),
	'identifierSchemeCodeUnspsc190501.60104505': __('identifierSchemeCodeUnspsc190501.60104505'),
	'identifierSchemeCodeUnspsc190501.60104506': __('identifierSchemeCodeUnspsc190501.60104506'),
	'identifierSchemeCodeUnspsc190501.60104507': __('identifierSchemeCodeUnspsc190501.60104507'),
	'identifierSchemeCodeUnspsc190501.60104508': __('identifierSchemeCodeUnspsc190501.60104508'),
	'identifierSchemeCodeUnspsc190501.60104509': __('identifierSchemeCodeUnspsc190501.60104509'),
	'identifierSchemeCodeUnspsc190501.60104511': __('identifierSchemeCodeUnspsc190501.60104511'),
	'identifierSchemeCodeUnspsc190501.60104512': __('identifierSchemeCodeUnspsc190501.60104512'),
	'identifierSchemeCodeUnspsc190501.60104600': __('identifierSchemeCodeUnspsc190501.60104600'),
	'identifierSchemeCodeUnspsc190501.60104601': __('identifierSchemeCodeUnspsc190501.60104601'),
	'identifierSchemeCodeUnspsc190501.60104602': __('identifierSchemeCodeUnspsc190501.60104602'),
	'identifierSchemeCodeUnspsc190501.60104604': __('identifierSchemeCodeUnspsc190501.60104604'),
	'identifierSchemeCodeUnspsc190501.60104605': __('identifierSchemeCodeUnspsc190501.60104605'),
	'identifierSchemeCodeUnspsc190501.60104606': __('identifierSchemeCodeUnspsc190501.60104606'),
	'identifierSchemeCodeUnspsc190501.60104607': __('identifierSchemeCodeUnspsc190501.60104607'),
	'identifierSchemeCodeUnspsc190501.60104608': __('identifierSchemeCodeUnspsc190501.60104608'),
	'identifierSchemeCodeUnspsc190501.60104609': __('identifierSchemeCodeUnspsc190501.60104609'),
	'identifierSchemeCodeUnspsc190501.60104610': __('identifierSchemeCodeUnspsc190501.60104610'),
	'identifierSchemeCodeUnspsc190501.60104611': __('identifierSchemeCodeUnspsc190501.60104611'),
	'identifierSchemeCodeUnspsc190501.60104612': __('identifierSchemeCodeUnspsc190501.60104612'),
	'identifierSchemeCodeUnspsc190501.60104613': __('identifierSchemeCodeUnspsc190501.60104613'),
	'identifierSchemeCodeUnspsc190501.60104614': __('identifierSchemeCodeUnspsc190501.60104614'),
	'identifierSchemeCodeUnspsc190501.60104615': __('identifierSchemeCodeUnspsc190501.60104615'),
	'identifierSchemeCodeUnspsc190501.60104616': __('identifierSchemeCodeUnspsc190501.60104616'),
	'identifierSchemeCodeUnspsc190501.60104617': __('identifierSchemeCodeUnspsc190501.60104617'),
	'identifierSchemeCodeUnspsc190501.60104618': __('identifierSchemeCodeUnspsc190501.60104618'),
	'identifierSchemeCodeUnspsc190501.60104619': __('identifierSchemeCodeUnspsc190501.60104619'),
	'identifierSchemeCodeUnspsc190501.60104620': __('identifierSchemeCodeUnspsc190501.60104620'),
	'identifierSchemeCodeUnspsc190501.60104700': __('identifierSchemeCodeUnspsc190501.60104700'),
	'identifierSchemeCodeUnspsc190501.60104701': __('identifierSchemeCodeUnspsc190501.60104701'),
	'identifierSchemeCodeUnspsc190501.60104702': __('identifierSchemeCodeUnspsc190501.60104702'),
	'identifierSchemeCodeUnspsc190501.60104703': __('identifierSchemeCodeUnspsc190501.60104703'),
	'identifierSchemeCodeUnspsc190501.60104704': __('identifierSchemeCodeUnspsc190501.60104704'),
	'identifierSchemeCodeUnspsc190501.60104705': __('identifierSchemeCodeUnspsc190501.60104705'),
	'identifierSchemeCodeUnspsc190501.60104706': __('identifierSchemeCodeUnspsc190501.60104706'),
	'identifierSchemeCodeUnspsc190501.60104707': __('identifierSchemeCodeUnspsc190501.60104707'),
	'identifierSchemeCodeUnspsc190501.60104708': __('identifierSchemeCodeUnspsc190501.60104708'),
	'identifierSchemeCodeUnspsc190501.60104709': __('identifierSchemeCodeUnspsc190501.60104709'),
	'identifierSchemeCodeUnspsc190501.60104710': __('identifierSchemeCodeUnspsc190501.60104710'),
	'identifierSchemeCodeUnspsc190501.60104711': __('identifierSchemeCodeUnspsc190501.60104711'),
	'identifierSchemeCodeUnspsc190501.60104712': __('identifierSchemeCodeUnspsc190501.60104712'),
	'identifierSchemeCodeUnspsc190501.60104713': __('identifierSchemeCodeUnspsc190501.60104713'),
	'identifierSchemeCodeUnspsc190501.60104714': __('identifierSchemeCodeUnspsc190501.60104714'),
	'identifierSchemeCodeUnspsc190501.60104715': __('identifierSchemeCodeUnspsc190501.60104715'),
	'identifierSchemeCodeUnspsc190501.60104716': __('identifierSchemeCodeUnspsc190501.60104716'),
	'identifierSchemeCodeUnspsc190501.60104717': __('identifierSchemeCodeUnspsc190501.60104717'),
	'identifierSchemeCodeUnspsc190501.60104718': __('identifierSchemeCodeUnspsc190501.60104718'),
	'identifierSchemeCodeUnspsc190501.60104719': __('identifierSchemeCodeUnspsc190501.60104719'),
	'identifierSchemeCodeUnspsc190501.60104720': __('identifierSchemeCodeUnspsc190501.60104720'),
	'identifierSchemeCodeUnspsc190501.60104721': __('identifierSchemeCodeUnspsc190501.60104721'),
	'identifierSchemeCodeUnspsc190501.60104722': __('identifierSchemeCodeUnspsc190501.60104722'),
	'identifierSchemeCodeUnspsc190501.60104723': __('identifierSchemeCodeUnspsc190501.60104723'),
	'identifierSchemeCodeUnspsc190501.60104724': __('identifierSchemeCodeUnspsc190501.60104724'),
	'identifierSchemeCodeUnspsc190501.60104726': __('identifierSchemeCodeUnspsc190501.60104726'),
	'identifierSchemeCodeUnspsc190501.60104800': __('identifierSchemeCodeUnspsc190501.60104800'),
	'identifierSchemeCodeUnspsc190501.60104801': __('identifierSchemeCodeUnspsc190501.60104801'),
	'identifierSchemeCodeUnspsc190501.60104802': __('identifierSchemeCodeUnspsc190501.60104802'),
	'identifierSchemeCodeUnspsc190501.60104803': __('identifierSchemeCodeUnspsc190501.60104803'),
	'identifierSchemeCodeUnspsc190501.60104804': __('identifierSchemeCodeUnspsc190501.60104804'),
	'identifierSchemeCodeUnspsc190501.60104805': __('identifierSchemeCodeUnspsc190501.60104805'),
	'identifierSchemeCodeUnspsc190501.60104806': __('identifierSchemeCodeUnspsc190501.60104806'),
	'identifierSchemeCodeUnspsc190501.60104807': __('identifierSchemeCodeUnspsc190501.60104807'),
	'identifierSchemeCodeUnspsc190501.60104808': __('identifierSchemeCodeUnspsc190501.60104808'),
	'identifierSchemeCodeUnspsc190501.60104809': __('identifierSchemeCodeUnspsc190501.60104809'),
	'identifierSchemeCodeUnspsc190501.60104810': __('identifierSchemeCodeUnspsc190501.60104810'),
	'identifierSchemeCodeUnspsc190501.60104811': __('identifierSchemeCodeUnspsc190501.60104811'),
	'identifierSchemeCodeUnspsc190501.60104812': __('identifierSchemeCodeUnspsc190501.60104812'),
	'identifierSchemeCodeUnspsc190501.60104813': __('identifierSchemeCodeUnspsc190501.60104813'),
	'identifierSchemeCodeUnspsc190501.60104814': __('identifierSchemeCodeUnspsc190501.60104814'),
	'identifierSchemeCodeUnspsc190501.60104815': __('identifierSchemeCodeUnspsc190501.60104815'),
	'identifierSchemeCodeUnspsc190501.60104816': __('identifierSchemeCodeUnspsc190501.60104816'),
	'identifierSchemeCodeUnspsc190501.60104817': __('identifierSchemeCodeUnspsc190501.60104817'),
	'identifierSchemeCodeUnspsc190501.60104818': __('identifierSchemeCodeUnspsc190501.60104818'),
	'identifierSchemeCodeUnspsc190501.60104819': __('identifierSchemeCodeUnspsc190501.60104819'),
	'identifierSchemeCodeUnspsc190501.60104820': __('identifierSchemeCodeUnspsc190501.60104820'),
	'identifierSchemeCodeUnspsc190501.60104821': __('identifierSchemeCodeUnspsc190501.60104821'),
	'identifierSchemeCodeUnspsc190501.60104822': __('identifierSchemeCodeUnspsc190501.60104822'),
	'identifierSchemeCodeUnspsc190501.60104823': __('identifierSchemeCodeUnspsc190501.60104823'),
	'identifierSchemeCodeUnspsc190501.60104824': __('identifierSchemeCodeUnspsc190501.60104824'),
	'identifierSchemeCodeUnspsc190501.60104825': __('identifierSchemeCodeUnspsc190501.60104825'),
	'identifierSchemeCodeUnspsc190501.60104826': __('identifierSchemeCodeUnspsc190501.60104826'),
	'identifierSchemeCodeUnspsc190501.60104827': __('identifierSchemeCodeUnspsc190501.60104827'),
	'identifierSchemeCodeUnspsc190501.60104900': __('identifierSchemeCodeUnspsc190501.60104900'),
	'identifierSchemeCodeUnspsc190501.60104901': __('identifierSchemeCodeUnspsc190501.60104901'),
	'identifierSchemeCodeUnspsc190501.60104902': __('identifierSchemeCodeUnspsc190501.60104902'),
	'identifierSchemeCodeUnspsc190501.60104903': __('identifierSchemeCodeUnspsc190501.60104903'),
	'identifierSchemeCodeUnspsc190501.60104904': __('identifierSchemeCodeUnspsc190501.60104904'),
	'identifierSchemeCodeUnspsc190501.60104905': __('identifierSchemeCodeUnspsc190501.60104905'),
	'identifierSchemeCodeUnspsc190501.60104906': __('identifierSchemeCodeUnspsc190501.60104906'),
	'identifierSchemeCodeUnspsc190501.60104907': __('identifierSchemeCodeUnspsc190501.60104907'),
	'identifierSchemeCodeUnspsc190501.60104908': __('identifierSchemeCodeUnspsc190501.60104908'),
	'identifierSchemeCodeUnspsc190501.60104909': __('identifierSchemeCodeUnspsc190501.60104909'),
	'identifierSchemeCodeUnspsc190501.60104910': __('identifierSchemeCodeUnspsc190501.60104910'),
	'identifierSchemeCodeUnspsc190501.60104911': __('identifierSchemeCodeUnspsc190501.60104911'),
	'identifierSchemeCodeUnspsc190501.60104912': __('identifierSchemeCodeUnspsc190501.60104912'),
	'identifierSchemeCodeUnspsc190501.60104913': __('identifierSchemeCodeUnspsc190501.60104913'),
	'identifierSchemeCodeUnspsc190501.60104914': __('identifierSchemeCodeUnspsc190501.60104914'),
	'identifierSchemeCodeUnspsc190501.60104915': __('identifierSchemeCodeUnspsc190501.60104915'),
	'identifierSchemeCodeUnspsc190501.60104916': __('identifierSchemeCodeUnspsc190501.60104916'),
	'identifierSchemeCodeUnspsc190501.60104917': __('identifierSchemeCodeUnspsc190501.60104917'),
	'identifierSchemeCodeUnspsc190501.60104918': __('identifierSchemeCodeUnspsc190501.60104918'),
	'identifierSchemeCodeUnspsc190501.60104919': __('identifierSchemeCodeUnspsc190501.60104919'),
	'identifierSchemeCodeUnspsc190501.60104920': __('identifierSchemeCodeUnspsc190501.60104920'),
	'identifierSchemeCodeUnspsc190501.60104921': __('identifierSchemeCodeUnspsc190501.60104921'),
	'identifierSchemeCodeUnspsc190501.60104922': __('identifierSchemeCodeUnspsc190501.60104922'),
	'identifierSchemeCodeUnspsc190501.60104923': __('identifierSchemeCodeUnspsc190501.60104923'),
	'identifierSchemeCodeUnspsc190501.60104924': __('identifierSchemeCodeUnspsc190501.60104924'),
	'identifierSchemeCodeUnspsc190501.60104925': __('identifierSchemeCodeUnspsc190501.60104925'),
	'identifierSchemeCodeUnspsc190501.60104926': __('identifierSchemeCodeUnspsc190501.60104926'),
	'identifierSchemeCodeUnspsc190501.60104927': __('identifierSchemeCodeUnspsc190501.60104927'),
	'identifierSchemeCodeUnspsc190501.60104928': __('identifierSchemeCodeUnspsc190501.60104928'),
	'identifierSchemeCodeUnspsc190501.60104929': __('identifierSchemeCodeUnspsc190501.60104929'),
	'identifierSchemeCodeUnspsc190501.60104930': __('identifierSchemeCodeUnspsc190501.60104930'),
	'identifierSchemeCodeUnspsc190501.60105000': __('identifierSchemeCodeUnspsc190501.60105000'),
	'identifierSchemeCodeUnspsc190501.60105001': __('identifierSchemeCodeUnspsc190501.60105001'),
	'identifierSchemeCodeUnspsc190501.60105002': __('identifierSchemeCodeUnspsc190501.60105002'),
	'identifierSchemeCodeUnspsc190501.60105003': __('identifierSchemeCodeUnspsc190501.60105003'),
	'identifierSchemeCodeUnspsc190501.60105004': __('identifierSchemeCodeUnspsc190501.60105004'),
	'identifierSchemeCodeUnspsc190501.60105005': __('identifierSchemeCodeUnspsc190501.60105005'),
	'identifierSchemeCodeUnspsc190501.60105006': __('identifierSchemeCodeUnspsc190501.60105006'),
	'identifierSchemeCodeUnspsc190501.60105100': __('identifierSchemeCodeUnspsc190501.60105100'),
	'identifierSchemeCodeUnspsc190501.60105101': __('identifierSchemeCodeUnspsc190501.60105101'),
	'identifierSchemeCodeUnspsc190501.60105102': __('identifierSchemeCodeUnspsc190501.60105102'),
	'identifierSchemeCodeUnspsc190501.60105103': __('identifierSchemeCodeUnspsc190501.60105103'),
	'identifierSchemeCodeUnspsc190501.60105104': __('identifierSchemeCodeUnspsc190501.60105104'),
	'identifierSchemeCodeUnspsc190501.60105200': __('identifierSchemeCodeUnspsc190501.60105200'),
	'identifierSchemeCodeUnspsc190501.60105201': __('identifierSchemeCodeUnspsc190501.60105201'),
	'identifierSchemeCodeUnspsc190501.60105202': __('identifierSchemeCodeUnspsc190501.60105202'),
	'identifierSchemeCodeUnspsc190501.60105203': __('identifierSchemeCodeUnspsc190501.60105203'),
	'identifierSchemeCodeUnspsc190501.60105300': __('identifierSchemeCodeUnspsc190501.60105300'),
	'identifierSchemeCodeUnspsc190501.60105301': __('identifierSchemeCodeUnspsc190501.60105301'),
	'identifierSchemeCodeUnspsc190501.60105302': __('identifierSchemeCodeUnspsc190501.60105302'),
	'identifierSchemeCodeUnspsc190501.60105303': __('identifierSchemeCodeUnspsc190501.60105303'),
	'identifierSchemeCodeUnspsc190501.60105304': __('identifierSchemeCodeUnspsc190501.60105304'),
	'identifierSchemeCodeUnspsc190501.60105305': __('identifierSchemeCodeUnspsc190501.60105305'),
	'identifierSchemeCodeUnspsc190501.60105306': __('identifierSchemeCodeUnspsc190501.60105306'),
	'identifierSchemeCodeUnspsc190501.60105307': __('identifierSchemeCodeUnspsc190501.60105307'),
	'identifierSchemeCodeUnspsc190501.60105308': __('identifierSchemeCodeUnspsc190501.60105308'),
	'identifierSchemeCodeUnspsc190501.60105309': __('identifierSchemeCodeUnspsc190501.60105309'),
	'identifierSchemeCodeUnspsc190501.60105400': __('identifierSchemeCodeUnspsc190501.60105400'),
	'identifierSchemeCodeUnspsc190501.60105401': __('identifierSchemeCodeUnspsc190501.60105401'),
	'identifierSchemeCodeUnspsc190501.60105402': __('identifierSchemeCodeUnspsc190501.60105402'),
	'identifierSchemeCodeUnspsc190501.60105403': __('identifierSchemeCodeUnspsc190501.60105403'),
	'identifierSchemeCodeUnspsc190501.60105404': __('identifierSchemeCodeUnspsc190501.60105404'),
	'identifierSchemeCodeUnspsc190501.60105405': __('identifierSchemeCodeUnspsc190501.60105405'),
	'identifierSchemeCodeUnspsc190501.60105406': __('identifierSchemeCodeUnspsc190501.60105406'),
	'identifierSchemeCodeUnspsc190501.60105407': __('identifierSchemeCodeUnspsc190501.60105407'),
	'identifierSchemeCodeUnspsc190501.60105408': __('identifierSchemeCodeUnspsc190501.60105408'),
	'identifierSchemeCodeUnspsc190501.60105409': __('identifierSchemeCodeUnspsc190501.60105409'),
	'identifierSchemeCodeUnspsc190501.60105410': __('identifierSchemeCodeUnspsc190501.60105410'),
	'identifierSchemeCodeUnspsc190501.60105411': __('identifierSchemeCodeUnspsc190501.60105411'),
	'identifierSchemeCodeUnspsc190501.60105412': __('identifierSchemeCodeUnspsc190501.60105412'),
	'identifierSchemeCodeUnspsc190501.60105413': __('identifierSchemeCodeUnspsc190501.60105413'),
	'identifierSchemeCodeUnspsc190501.60105414': __('identifierSchemeCodeUnspsc190501.60105414'),
	'identifierSchemeCodeUnspsc190501.60105415': __('identifierSchemeCodeUnspsc190501.60105415'),
	'identifierSchemeCodeUnspsc190501.60105416': __('identifierSchemeCodeUnspsc190501.60105416'),
	'identifierSchemeCodeUnspsc190501.60105417': __('identifierSchemeCodeUnspsc190501.60105417'),
	'identifierSchemeCodeUnspsc190501.60105418': __('identifierSchemeCodeUnspsc190501.60105418'),
	'identifierSchemeCodeUnspsc190501.60105419': __('identifierSchemeCodeUnspsc190501.60105419'),
	'identifierSchemeCodeUnspsc190501.60105420': __('identifierSchemeCodeUnspsc190501.60105420'),
	'identifierSchemeCodeUnspsc190501.60105421': __('identifierSchemeCodeUnspsc190501.60105421'),
	'identifierSchemeCodeUnspsc190501.60105422': __('identifierSchemeCodeUnspsc190501.60105422'),
	'identifierSchemeCodeUnspsc190501.60105423': __('identifierSchemeCodeUnspsc190501.60105423'),
	'identifierSchemeCodeUnspsc190501.60105424': __('identifierSchemeCodeUnspsc190501.60105424'),
	'identifierSchemeCodeUnspsc190501.60105425': __('identifierSchemeCodeUnspsc190501.60105425'),
	'identifierSchemeCodeUnspsc190501.60105426': __('identifierSchemeCodeUnspsc190501.60105426'),
	'identifierSchemeCodeUnspsc190501.60105427': __('identifierSchemeCodeUnspsc190501.60105427'),
	'identifierSchemeCodeUnspsc190501.60105428': __('identifierSchemeCodeUnspsc190501.60105428'),
	'identifierSchemeCodeUnspsc190501.60105429': __('identifierSchemeCodeUnspsc190501.60105429'),
	'identifierSchemeCodeUnspsc190501.60105500': __('identifierSchemeCodeUnspsc190501.60105500'),
	'identifierSchemeCodeUnspsc190501.60105501': __('identifierSchemeCodeUnspsc190501.60105501'),
	'identifierSchemeCodeUnspsc190501.60105502': __('identifierSchemeCodeUnspsc190501.60105502'),
	'identifierSchemeCodeUnspsc190501.60105503': __('identifierSchemeCodeUnspsc190501.60105503'),
	'identifierSchemeCodeUnspsc190501.60105504': __('identifierSchemeCodeUnspsc190501.60105504'),
	'identifierSchemeCodeUnspsc190501.60105505': __('identifierSchemeCodeUnspsc190501.60105505'),
	'identifierSchemeCodeUnspsc190501.60105600': __('identifierSchemeCodeUnspsc190501.60105600'),
	'identifierSchemeCodeUnspsc190501.60105601': __('identifierSchemeCodeUnspsc190501.60105601'),
	'identifierSchemeCodeUnspsc190501.60105602': __('identifierSchemeCodeUnspsc190501.60105602'),
	'identifierSchemeCodeUnspsc190501.60105603': __('identifierSchemeCodeUnspsc190501.60105603'),
	'identifierSchemeCodeUnspsc190501.60105604': __('identifierSchemeCodeUnspsc190501.60105604'),
	'identifierSchemeCodeUnspsc190501.60105605': __('identifierSchemeCodeUnspsc190501.60105605'),
	'identifierSchemeCodeUnspsc190501.60105606': __('identifierSchemeCodeUnspsc190501.60105606'),
	'identifierSchemeCodeUnspsc190501.60105607': __('identifierSchemeCodeUnspsc190501.60105607'),
	'identifierSchemeCodeUnspsc190501.60105608': __('identifierSchemeCodeUnspsc190501.60105608'),
	'identifierSchemeCodeUnspsc190501.60105609': __('identifierSchemeCodeUnspsc190501.60105609'),
	'identifierSchemeCodeUnspsc190501.60105610': __('identifierSchemeCodeUnspsc190501.60105610'),
	'identifierSchemeCodeUnspsc190501.60105611': __('identifierSchemeCodeUnspsc190501.60105611'),
	'identifierSchemeCodeUnspsc190501.60105612': __('identifierSchemeCodeUnspsc190501.60105612'),
	'identifierSchemeCodeUnspsc190501.60105613': __('identifierSchemeCodeUnspsc190501.60105613'),
	'identifierSchemeCodeUnspsc190501.60105614': __('identifierSchemeCodeUnspsc190501.60105614'),
	'identifierSchemeCodeUnspsc190501.60105615': __('identifierSchemeCodeUnspsc190501.60105615'),
	'identifierSchemeCodeUnspsc190501.60105616': __('identifierSchemeCodeUnspsc190501.60105616'),
	'identifierSchemeCodeUnspsc190501.60105617': __('identifierSchemeCodeUnspsc190501.60105617'),
	'identifierSchemeCodeUnspsc190501.60105618': __('identifierSchemeCodeUnspsc190501.60105618'),
	'identifierSchemeCodeUnspsc190501.60105619': __('identifierSchemeCodeUnspsc190501.60105619'),
	'identifierSchemeCodeUnspsc190501.60105620': __('identifierSchemeCodeUnspsc190501.60105620'),
	'identifierSchemeCodeUnspsc190501.60105621': __('identifierSchemeCodeUnspsc190501.60105621'),
	'identifierSchemeCodeUnspsc190501.60105622': __('identifierSchemeCodeUnspsc190501.60105622'),
	'identifierSchemeCodeUnspsc190501.60105623': __('identifierSchemeCodeUnspsc190501.60105623'),
	'identifierSchemeCodeUnspsc190501.60105624': __('identifierSchemeCodeUnspsc190501.60105624'),
	'identifierSchemeCodeUnspsc190501.60105625': __('identifierSchemeCodeUnspsc190501.60105625'),
	'identifierSchemeCodeUnspsc190501.60105626': __('identifierSchemeCodeUnspsc190501.60105626'),
	'identifierSchemeCodeUnspsc190501.60105700': __('identifierSchemeCodeUnspsc190501.60105700'),
	'identifierSchemeCodeUnspsc190501.60105701': __('identifierSchemeCodeUnspsc190501.60105701'),
	'identifierSchemeCodeUnspsc190501.60105702': __('identifierSchemeCodeUnspsc190501.60105702'),
	'identifierSchemeCodeUnspsc190501.60105703': __('identifierSchemeCodeUnspsc190501.60105703'),
	'identifierSchemeCodeUnspsc190501.60105704': __('identifierSchemeCodeUnspsc190501.60105704'),
	'identifierSchemeCodeUnspsc190501.60105705': __('identifierSchemeCodeUnspsc190501.60105705'),
	'identifierSchemeCodeUnspsc190501.60105800': __('identifierSchemeCodeUnspsc190501.60105800'),
	'identifierSchemeCodeUnspsc190501.60105801': __('identifierSchemeCodeUnspsc190501.60105801'),
	'identifierSchemeCodeUnspsc190501.60105802': __('identifierSchemeCodeUnspsc190501.60105802'),
	'identifierSchemeCodeUnspsc190501.60105803': __('identifierSchemeCodeUnspsc190501.60105803'),
	'identifierSchemeCodeUnspsc190501.60105804': __('identifierSchemeCodeUnspsc190501.60105804'),
	'identifierSchemeCodeUnspsc190501.60105805': __('identifierSchemeCodeUnspsc190501.60105805'),
	'identifierSchemeCodeUnspsc190501.60105806': __('identifierSchemeCodeUnspsc190501.60105806'),
	'identifierSchemeCodeUnspsc190501.60105807': __('identifierSchemeCodeUnspsc190501.60105807'),
	'identifierSchemeCodeUnspsc190501.60105808': __('identifierSchemeCodeUnspsc190501.60105808'),
	'identifierSchemeCodeUnspsc190501.60105809': __('identifierSchemeCodeUnspsc190501.60105809'),
	'identifierSchemeCodeUnspsc190501.60105810': __('identifierSchemeCodeUnspsc190501.60105810'),
	'identifierSchemeCodeUnspsc190501.60105811': __('identifierSchemeCodeUnspsc190501.60105811'),
	'identifierSchemeCodeUnspsc190501.60105900': __('identifierSchemeCodeUnspsc190501.60105900'),
	'identifierSchemeCodeUnspsc190501.60105901': __('identifierSchemeCodeUnspsc190501.60105901'),
	'identifierSchemeCodeUnspsc190501.60105902': __('identifierSchemeCodeUnspsc190501.60105902'),
	'identifierSchemeCodeUnspsc190501.60105903': __('identifierSchemeCodeUnspsc190501.60105903'),
	'identifierSchemeCodeUnspsc190501.60105904': __('identifierSchemeCodeUnspsc190501.60105904'),
	'identifierSchemeCodeUnspsc190501.60105905': __('identifierSchemeCodeUnspsc190501.60105905'),
	'identifierSchemeCodeUnspsc190501.60105906': __('identifierSchemeCodeUnspsc190501.60105906'),
	'identifierSchemeCodeUnspsc190501.60105907': __('identifierSchemeCodeUnspsc190501.60105907'),
	'identifierSchemeCodeUnspsc190501.60105908': __('identifierSchemeCodeUnspsc190501.60105908'),
	'identifierSchemeCodeUnspsc190501.60105909': __('identifierSchemeCodeUnspsc190501.60105909'),
	'identifierSchemeCodeUnspsc190501.60105910': __('identifierSchemeCodeUnspsc190501.60105910'),
	'identifierSchemeCodeUnspsc190501.60105911': __('identifierSchemeCodeUnspsc190501.60105911'),
	'identifierSchemeCodeUnspsc190501.60105912': __('identifierSchemeCodeUnspsc190501.60105912'),
	'identifierSchemeCodeUnspsc190501.60105913': __('identifierSchemeCodeUnspsc190501.60105913'),
	'identifierSchemeCodeUnspsc190501.60105914': __('identifierSchemeCodeUnspsc190501.60105914'),
	'identifierSchemeCodeUnspsc190501.60105915': __('identifierSchemeCodeUnspsc190501.60105915'),
	'identifierSchemeCodeUnspsc190501.60105916': __('identifierSchemeCodeUnspsc190501.60105916'),
	'identifierSchemeCodeUnspsc190501.60105917': __('identifierSchemeCodeUnspsc190501.60105917'),
	'identifierSchemeCodeUnspsc190501.60105918': __('identifierSchemeCodeUnspsc190501.60105918'),
	'identifierSchemeCodeUnspsc190501.60105919': __('identifierSchemeCodeUnspsc190501.60105919'),
	'identifierSchemeCodeUnspsc190501.60106000': __('identifierSchemeCodeUnspsc190501.60106000'),
	'identifierSchemeCodeUnspsc190501.60106001': __('identifierSchemeCodeUnspsc190501.60106001'),
	'identifierSchemeCodeUnspsc190501.60106002': __('identifierSchemeCodeUnspsc190501.60106002'),
	'identifierSchemeCodeUnspsc190501.60106003': __('identifierSchemeCodeUnspsc190501.60106003'),
	'identifierSchemeCodeUnspsc190501.60106004': __('identifierSchemeCodeUnspsc190501.60106004'),
	'identifierSchemeCodeUnspsc190501.60106100': __('identifierSchemeCodeUnspsc190501.60106100'),
	'identifierSchemeCodeUnspsc190501.60106101': __('identifierSchemeCodeUnspsc190501.60106101'),
	'identifierSchemeCodeUnspsc190501.60106102': __('identifierSchemeCodeUnspsc190501.60106102'),
	'identifierSchemeCodeUnspsc190501.60106103': __('identifierSchemeCodeUnspsc190501.60106103'),
	'identifierSchemeCodeUnspsc190501.60106104': __('identifierSchemeCodeUnspsc190501.60106104'),
	'identifierSchemeCodeUnspsc190501.60106105': __('identifierSchemeCodeUnspsc190501.60106105'),
	'identifierSchemeCodeUnspsc190501.60106106': __('identifierSchemeCodeUnspsc190501.60106106'),
	'identifierSchemeCodeUnspsc190501.60106107': __('identifierSchemeCodeUnspsc190501.60106107'),
	'identifierSchemeCodeUnspsc190501.60106108': __('identifierSchemeCodeUnspsc190501.60106108'),
	'identifierSchemeCodeUnspsc190501.60106109': __('identifierSchemeCodeUnspsc190501.60106109'),
	'identifierSchemeCodeUnspsc190501.60106200': __('identifierSchemeCodeUnspsc190501.60106200'),
	'identifierSchemeCodeUnspsc190501.60106201': __('identifierSchemeCodeUnspsc190501.60106201'),
	'identifierSchemeCodeUnspsc190501.60106202': __('identifierSchemeCodeUnspsc190501.60106202'),
	'identifierSchemeCodeUnspsc190501.60106203': __('identifierSchemeCodeUnspsc190501.60106203'),
	'identifierSchemeCodeUnspsc190501.60106204': __('identifierSchemeCodeUnspsc190501.60106204'),
	'identifierSchemeCodeUnspsc190501.60106205': __('identifierSchemeCodeUnspsc190501.60106205'),
	'identifierSchemeCodeUnspsc190501.60106206': __('identifierSchemeCodeUnspsc190501.60106206'),
	'identifierSchemeCodeUnspsc190501.60106207': __('identifierSchemeCodeUnspsc190501.60106207'),
	'identifierSchemeCodeUnspsc190501.60106208': __('identifierSchemeCodeUnspsc190501.60106208'),
	'identifierSchemeCodeUnspsc190501.60106209': __('identifierSchemeCodeUnspsc190501.60106209'),
	'identifierSchemeCodeUnspsc190501.60106210': __('identifierSchemeCodeUnspsc190501.60106210'),
	'identifierSchemeCodeUnspsc190501.60106211': __('identifierSchemeCodeUnspsc190501.60106211'),
	'identifierSchemeCodeUnspsc190501.60106212': __('identifierSchemeCodeUnspsc190501.60106212'),
	'identifierSchemeCodeUnspsc190501.60106213': __('identifierSchemeCodeUnspsc190501.60106213'),
	'identifierSchemeCodeUnspsc190501.60106214': __('identifierSchemeCodeUnspsc190501.60106214'),
	'identifierSchemeCodeUnspsc190501.60106215': __('identifierSchemeCodeUnspsc190501.60106215'),
	'identifierSchemeCodeUnspsc190501.60106300': __('identifierSchemeCodeUnspsc190501.60106300'),
	'identifierSchemeCodeUnspsc190501.60106301': __('identifierSchemeCodeUnspsc190501.60106301'),
	'identifierSchemeCodeUnspsc190501.60106302': __('identifierSchemeCodeUnspsc190501.60106302'),
	'identifierSchemeCodeUnspsc190501.60106400': __('identifierSchemeCodeUnspsc190501.60106400'),
	'identifierSchemeCodeUnspsc190501.60106401': __('identifierSchemeCodeUnspsc190501.60106401'),
	'identifierSchemeCodeUnspsc190501.60106402': __('identifierSchemeCodeUnspsc190501.60106402'),
	'identifierSchemeCodeUnspsc190501.60106500': __('identifierSchemeCodeUnspsc190501.60106500'),
	'identifierSchemeCodeUnspsc190501.60106501': __('identifierSchemeCodeUnspsc190501.60106501'),
	'identifierSchemeCodeUnspsc190501.60106502': __('identifierSchemeCodeUnspsc190501.60106502'),
	'identifierSchemeCodeUnspsc190501.60106503': __('identifierSchemeCodeUnspsc190501.60106503'),
	'identifierSchemeCodeUnspsc190501.60106504': __('identifierSchemeCodeUnspsc190501.60106504'),
	'identifierSchemeCodeUnspsc190501.60106505': __('identifierSchemeCodeUnspsc190501.60106505'),
	'identifierSchemeCodeUnspsc190501.60106506': __('identifierSchemeCodeUnspsc190501.60106506'),
	'identifierSchemeCodeUnspsc190501.60106507': __('identifierSchemeCodeUnspsc190501.60106507'),
	'identifierSchemeCodeUnspsc190501.60106600': __('identifierSchemeCodeUnspsc190501.60106600'),
	'identifierSchemeCodeUnspsc190501.60106601': __('identifierSchemeCodeUnspsc190501.60106601'),
	'identifierSchemeCodeUnspsc190501.60106602': __('identifierSchemeCodeUnspsc190501.60106602'),
	'identifierSchemeCodeUnspsc190501.60106603': __('identifierSchemeCodeUnspsc190501.60106603'),
	'identifierSchemeCodeUnspsc190501.60106604': __('identifierSchemeCodeUnspsc190501.60106604'),
	'identifierSchemeCodeUnspsc190501.60106605': __('identifierSchemeCodeUnspsc190501.60106605'),
	'identifierSchemeCodeUnspsc190501.60106606': __('identifierSchemeCodeUnspsc190501.60106606'),
	'identifierSchemeCodeUnspsc190501.60106607': __('identifierSchemeCodeUnspsc190501.60106607'),
	'identifierSchemeCodeUnspsc190501.60106608': __('identifierSchemeCodeUnspsc190501.60106608'),
	'identifierSchemeCodeUnspsc190501.60106609': __('identifierSchemeCodeUnspsc190501.60106609'),
	'identifierSchemeCodeUnspsc190501.60106610': __('identifierSchemeCodeUnspsc190501.60106610'),
	'identifierSchemeCodeUnspsc190501.60106611': __('identifierSchemeCodeUnspsc190501.60106611'),
	'identifierSchemeCodeUnspsc190501.60106612': __('identifierSchemeCodeUnspsc190501.60106612'),
	'identifierSchemeCodeUnspsc190501.60110000': __('identifierSchemeCodeUnspsc190501.60110000'),
	'identifierSchemeCodeUnspsc190501.60111000': __('identifierSchemeCodeUnspsc190501.60111000'),
	'identifierSchemeCodeUnspsc190501.60111001': __('identifierSchemeCodeUnspsc190501.60111001'),
	'identifierSchemeCodeUnspsc190501.60111002': __('identifierSchemeCodeUnspsc190501.60111002'),
	'identifierSchemeCodeUnspsc190501.60111003': __('identifierSchemeCodeUnspsc190501.60111003'),
	'identifierSchemeCodeUnspsc190501.60111004': __('identifierSchemeCodeUnspsc190501.60111004'),
	'identifierSchemeCodeUnspsc190501.60111005': __('identifierSchemeCodeUnspsc190501.60111005'),
	'identifierSchemeCodeUnspsc190501.60111100': __('identifierSchemeCodeUnspsc190501.60111100'),
	'identifierSchemeCodeUnspsc190501.60111101': __('identifierSchemeCodeUnspsc190501.60111101'),
	'identifierSchemeCodeUnspsc190501.60111102': __('identifierSchemeCodeUnspsc190501.60111102'),
	'identifierSchemeCodeUnspsc190501.60111103': __('identifierSchemeCodeUnspsc190501.60111103'),
	'identifierSchemeCodeUnspsc190501.60111104': __('identifierSchemeCodeUnspsc190501.60111104'),
	'identifierSchemeCodeUnspsc190501.60111105': __('identifierSchemeCodeUnspsc190501.60111105'),
	'identifierSchemeCodeUnspsc190501.60111106': __('identifierSchemeCodeUnspsc190501.60111106'),
	'identifierSchemeCodeUnspsc190501.60111107': __('identifierSchemeCodeUnspsc190501.60111107'),
	'identifierSchemeCodeUnspsc190501.60111108': __('identifierSchemeCodeUnspsc190501.60111108'),
	'identifierSchemeCodeUnspsc190501.60111109': __('identifierSchemeCodeUnspsc190501.60111109'),
	'identifierSchemeCodeUnspsc190501.60111200': __('identifierSchemeCodeUnspsc190501.60111200'),
	'identifierSchemeCodeUnspsc190501.60111201': __('identifierSchemeCodeUnspsc190501.60111201'),
	'identifierSchemeCodeUnspsc190501.60111202': __('identifierSchemeCodeUnspsc190501.60111202'),
	'identifierSchemeCodeUnspsc190501.60111203': __('identifierSchemeCodeUnspsc190501.60111203'),
	'identifierSchemeCodeUnspsc190501.60111204': __('identifierSchemeCodeUnspsc190501.60111204'),
	'identifierSchemeCodeUnspsc190501.60111205': __('identifierSchemeCodeUnspsc190501.60111205'),
	'identifierSchemeCodeUnspsc190501.60111206': __('identifierSchemeCodeUnspsc190501.60111206'),
	'identifierSchemeCodeUnspsc190501.60111207': __('identifierSchemeCodeUnspsc190501.60111207'),
	'identifierSchemeCodeUnspsc190501.60111208': __('identifierSchemeCodeUnspsc190501.60111208'),
	'identifierSchemeCodeUnspsc190501.60111300': __('identifierSchemeCodeUnspsc190501.60111300'),
	'identifierSchemeCodeUnspsc190501.60111301': __('identifierSchemeCodeUnspsc190501.60111301'),
	'identifierSchemeCodeUnspsc190501.60111302': __('identifierSchemeCodeUnspsc190501.60111302'),
	'identifierSchemeCodeUnspsc190501.60111303': __('identifierSchemeCodeUnspsc190501.60111303'),
	'identifierSchemeCodeUnspsc190501.60111304': __('identifierSchemeCodeUnspsc190501.60111304'),
	'identifierSchemeCodeUnspsc190501.60111305': __('identifierSchemeCodeUnspsc190501.60111305'),
	'identifierSchemeCodeUnspsc190501.60111306': __('identifierSchemeCodeUnspsc190501.60111306'),
	'identifierSchemeCodeUnspsc190501.60111400': __('identifierSchemeCodeUnspsc190501.60111400'),
	'identifierSchemeCodeUnspsc190501.60111401': __('identifierSchemeCodeUnspsc190501.60111401'),
	'identifierSchemeCodeUnspsc190501.60111402': __('identifierSchemeCodeUnspsc190501.60111402'),
	'identifierSchemeCodeUnspsc190501.60111403': __('identifierSchemeCodeUnspsc190501.60111403'),
	'identifierSchemeCodeUnspsc190501.60111404': __('identifierSchemeCodeUnspsc190501.60111404'),
	'identifierSchemeCodeUnspsc190501.60111405': __('identifierSchemeCodeUnspsc190501.60111405'),
	'identifierSchemeCodeUnspsc190501.60111407': __('identifierSchemeCodeUnspsc190501.60111407'),
	'identifierSchemeCodeUnspsc190501.60111408': __('identifierSchemeCodeUnspsc190501.60111408'),
	'identifierSchemeCodeUnspsc190501.60111409': __('identifierSchemeCodeUnspsc190501.60111409'),
	'identifierSchemeCodeUnspsc190501.60111410': __('identifierSchemeCodeUnspsc190501.60111410'),
	'identifierSchemeCodeUnspsc190501.60111411': __('identifierSchemeCodeUnspsc190501.60111411'),
	'identifierSchemeCodeUnspsc190501.60120000': __('identifierSchemeCodeUnspsc190501.60120000'),
	'identifierSchemeCodeUnspsc190501.60121000': __('identifierSchemeCodeUnspsc190501.60121000'),
	'identifierSchemeCodeUnspsc190501.60121001': __('identifierSchemeCodeUnspsc190501.60121001'),
	'identifierSchemeCodeUnspsc190501.60121002': __('identifierSchemeCodeUnspsc190501.60121002'),
	'identifierSchemeCodeUnspsc190501.60121003': __('identifierSchemeCodeUnspsc190501.60121003'),
	'identifierSchemeCodeUnspsc190501.60121004': __('identifierSchemeCodeUnspsc190501.60121004'),
	'identifierSchemeCodeUnspsc190501.60121005': __('identifierSchemeCodeUnspsc190501.60121005'),
	'identifierSchemeCodeUnspsc190501.60121006': __('identifierSchemeCodeUnspsc190501.60121006'),
	'identifierSchemeCodeUnspsc190501.60121007': __('identifierSchemeCodeUnspsc190501.60121007'),
	'identifierSchemeCodeUnspsc190501.60121008': __('identifierSchemeCodeUnspsc190501.60121008'),
	'identifierSchemeCodeUnspsc190501.60121009': __('identifierSchemeCodeUnspsc190501.60121009'),
	'identifierSchemeCodeUnspsc190501.60121010': __('identifierSchemeCodeUnspsc190501.60121010'),
	'identifierSchemeCodeUnspsc190501.60121011': __('identifierSchemeCodeUnspsc190501.60121011'),
	'identifierSchemeCodeUnspsc190501.60121012': __('identifierSchemeCodeUnspsc190501.60121012'),
	'identifierSchemeCodeUnspsc190501.60121013': __('identifierSchemeCodeUnspsc190501.60121013'),
	'identifierSchemeCodeUnspsc190501.60121014': __('identifierSchemeCodeUnspsc190501.60121014'),
	'identifierSchemeCodeUnspsc190501.60121015': __('identifierSchemeCodeUnspsc190501.60121015'),
	'identifierSchemeCodeUnspsc190501.60121016': __('identifierSchemeCodeUnspsc190501.60121016'),
	'identifierSchemeCodeUnspsc190501.60121100': __('identifierSchemeCodeUnspsc190501.60121100'),
	'identifierSchemeCodeUnspsc190501.60121101': __('identifierSchemeCodeUnspsc190501.60121101'),
	'identifierSchemeCodeUnspsc190501.60121102': __('identifierSchemeCodeUnspsc190501.60121102'),
	'identifierSchemeCodeUnspsc190501.60121103': __('identifierSchemeCodeUnspsc190501.60121103'),
	'identifierSchemeCodeUnspsc190501.60121104': __('identifierSchemeCodeUnspsc190501.60121104'),
	'identifierSchemeCodeUnspsc190501.60121105': __('identifierSchemeCodeUnspsc190501.60121105'),
	'identifierSchemeCodeUnspsc190501.60121106': __('identifierSchemeCodeUnspsc190501.60121106'),
	'identifierSchemeCodeUnspsc190501.60121107': __('identifierSchemeCodeUnspsc190501.60121107'),
	'identifierSchemeCodeUnspsc190501.60121108': __('identifierSchemeCodeUnspsc190501.60121108'),
	'identifierSchemeCodeUnspsc190501.60121109': __('identifierSchemeCodeUnspsc190501.60121109'),
	'identifierSchemeCodeUnspsc190501.60121110': __('identifierSchemeCodeUnspsc190501.60121110'),
	'identifierSchemeCodeUnspsc190501.60121111': __('identifierSchemeCodeUnspsc190501.60121111'),
	'identifierSchemeCodeUnspsc190501.60121112': __('identifierSchemeCodeUnspsc190501.60121112'),
	'identifierSchemeCodeUnspsc190501.60121113': __('identifierSchemeCodeUnspsc190501.60121113'),
	'identifierSchemeCodeUnspsc190501.60121114': __('identifierSchemeCodeUnspsc190501.60121114'),
	'identifierSchemeCodeUnspsc190501.60121115': __('identifierSchemeCodeUnspsc190501.60121115'),
	'identifierSchemeCodeUnspsc190501.60121116': __('identifierSchemeCodeUnspsc190501.60121116'),
	'identifierSchemeCodeUnspsc190501.60121117': __('identifierSchemeCodeUnspsc190501.60121117'),
	'identifierSchemeCodeUnspsc190501.60121118': __('identifierSchemeCodeUnspsc190501.60121118'),
	'identifierSchemeCodeUnspsc190501.60121119': __('identifierSchemeCodeUnspsc190501.60121119'),
	'identifierSchemeCodeUnspsc190501.60121120': __('identifierSchemeCodeUnspsc190501.60121120'),
	'identifierSchemeCodeUnspsc190501.60121121': __('identifierSchemeCodeUnspsc190501.60121121'),
	'identifierSchemeCodeUnspsc190501.60121123': __('identifierSchemeCodeUnspsc190501.60121123'),
	'identifierSchemeCodeUnspsc190501.60121124': __('identifierSchemeCodeUnspsc190501.60121124'),
	'identifierSchemeCodeUnspsc190501.60121125': __('identifierSchemeCodeUnspsc190501.60121125'),
	'identifierSchemeCodeUnspsc190501.60121126': __('identifierSchemeCodeUnspsc190501.60121126'),
	'identifierSchemeCodeUnspsc190501.60121127': __('identifierSchemeCodeUnspsc190501.60121127'),
	'identifierSchemeCodeUnspsc190501.60121128': __('identifierSchemeCodeUnspsc190501.60121128'),
	'identifierSchemeCodeUnspsc190501.60121129': __('identifierSchemeCodeUnspsc190501.60121129'),
	'identifierSchemeCodeUnspsc190501.60121130': __('identifierSchemeCodeUnspsc190501.60121130'),
	'identifierSchemeCodeUnspsc190501.60121131': __('identifierSchemeCodeUnspsc190501.60121131'),
	'identifierSchemeCodeUnspsc190501.60121132': __('identifierSchemeCodeUnspsc190501.60121132'),
	'identifierSchemeCodeUnspsc190501.60121133': __('identifierSchemeCodeUnspsc190501.60121133'),
	'identifierSchemeCodeUnspsc190501.60121134': __('identifierSchemeCodeUnspsc190501.60121134'),
	'identifierSchemeCodeUnspsc190501.60121135': __('identifierSchemeCodeUnspsc190501.60121135'),
	'identifierSchemeCodeUnspsc190501.60121136': __('identifierSchemeCodeUnspsc190501.60121136'),
	'identifierSchemeCodeUnspsc190501.60121137': __('identifierSchemeCodeUnspsc190501.60121137'),
	'identifierSchemeCodeUnspsc190501.60121138': __('identifierSchemeCodeUnspsc190501.60121138'),
	'identifierSchemeCodeUnspsc190501.60121139': __('identifierSchemeCodeUnspsc190501.60121139'),
	'identifierSchemeCodeUnspsc190501.60121140': __('identifierSchemeCodeUnspsc190501.60121140'),
	'identifierSchemeCodeUnspsc190501.60121141': __('identifierSchemeCodeUnspsc190501.60121141'),
	'identifierSchemeCodeUnspsc190501.60121142': __('identifierSchemeCodeUnspsc190501.60121142'),
	'identifierSchemeCodeUnspsc190501.60121143': __('identifierSchemeCodeUnspsc190501.60121143'),
	'identifierSchemeCodeUnspsc190501.60121144': __('identifierSchemeCodeUnspsc190501.60121144'),
	'identifierSchemeCodeUnspsc190501.60121145': __('identifierSchemeCodeUnspsc190501.60121145'),
	'identifierSchemeCodeUnspsc190501.60121146': __('identifierSchemeCodeUnspsc190501.60121146'),
	'identifierSchemeCodeUnspsc190501.60121147': __('identifierSchemeCodeUnspsc190501.60121147'),
	'identifierSchemeCodeUnspsc190501.60121148': __('identifierSchemeCodeUnspsc190501.60121148'),
	'identifierSchemeCodeUnspsc190501.60121149': __('identifierSchemeCodeUnspsc190501.60121149'),
	'identifierSchemeCodeUnspsc190501.60121150': __('identifierSchemeCodeUnspsc190501.60121150'),
	'identifierSchemeCodeUnspsc190501.60121151': __('identifierSchemeCodeUnspsc190501.60121151'),
	'identifierSchemeCodeUnspsc190501.60121152': __('identifierSchemeCodeUnspsc190501.60121152'),
	'identifierSchemeCodeUnspsc190501.60121153': __('identifierSchemeCodeUnspsc190501.60121153'),
	'identifierSchemeCodeUnspsc190501.60121154': __('identifierSchemeCodeUnspsc190501.60121154'),
	'identifierSchemeCodeUnspsc190501.60121155': __('identifierSchemeCodeUnspsc190501.60121155'),
	'identifierSchemeCodeUnspsc190501.60121156': __('identifierSchemeCodeUnspsc190501.60121156'),
	'identifierSchemeCodeUnspsc190501.60121157': __('identifierSchemeCodeUnspsc190501.60121157'),
	'identifierSchemeCodeUnspsc190501.60121158': __('identifierSchemeCodeUnspsc190501.60121158'),
	'identifierSchemeCodeUnspsc190501.60121200': __('identifierSchemeCodeUnspsc190501.60121200'),
	'identifierSchemeCodeUnspsc190501.60121201': __('identifierSchemeCodeUnspsc190501.60121201'),
	'identifierSchemeCodeUnspsc190501.60121202': __('identifierSchemeCodeUnspsc190501.60121202'),
	'identifierSchemeCodeUnspsc190501.60121203': __('identifierSchemeCodeUnspsc190501.60121203'),
	'identifierSchemeCodeUnspsc190501.60121204': __('identifierSchemeCodeUnspsc190501.60121204'),
	'identifierSchemeCodeUnspsc190501.60121205': __('identifierSchemeCodeUnspsc190501.60121205'),
	'identifierSchemeCodeUnspsc190501.60121206': __('identifierSchemeCodeUnspsc190501.60121206'),
	'identifierSchemeCodeUnspsc190501.60121207': __('identifierSchemeCodeUnspsc190501.60121207'),
	'identifierSchemeCodeUnspsc190501.60121208': __('identifierSchemeCodeUnspsc190501.60121208'),
	'identifierSchemeCodeUnspsc190501.60121209': __('identifierSchemeCodeUnspsc190501.60121209'),
	'identifierSchemeCodeUnspsc190501.60121210': __('identifierSchemeCodeUnspsc190501.60121210'),
	'identifierSchemeCodeUnspsc190501.60121211': __('identifierSchemeCodeUnspsc190501.60121211'),
	'identifierSchemeCodeUnspsc190501.60121212': __('identifierSchemeCodeUnspsc190501.60121212'),
	'identifierSchemeCodeUnspsc190501.60121213': __('identifierSchemeCodeUnspsc190501.60121213'),
	'identifierSchemeCodeUnspsc190501.60121214': __('identifierSchemeCodeUnspsc190501.60121214'),
	'identifierSchemeCodeUnspsc190501.60121215': __('identifierSchemeCodeUnspsc190501.60121215'),
	'identifierSchemeCodeUnspsc190501.60121216': __('identifierSchemeCodeUnspsc190501.60121216'),
	'identifierSchemeCodeUnspsc190501.60121217': __('identifierSchemeCodeUnspsc190501.60121217'),
	'identifierSchemeCodeUnspsc190501.60121218': __('identifierSchemeCodeUnspsc190501.60121218'),
	'identifierSchemeCodeUnspsc190501.60121219': __('identifierSchemeCodeUnspsc190501.60121219'),
	'identifierSchemeCodeUnspsc190501.60121220': __('identifierSchemeCodeUnspsc190501.60121220'),
	'identifierSchemeCodeUnspsc190501.60121221': __('identifierSchemeCodeUnspsc190501.60121221'),
	'identifierSchemeCodeUnspsc190501.60121222': __('identifierSchemeCodeUnspsc190501.60121222'),
	'identifierSchemeCodeUnspsc190501.60121223': __('identifierSchemeCodeUnspsc190501.60121223'),
	'identifierSchemeCodeUnspsc190501.60121224': __('identifierSchemeCodeUnspsc190501.60121224'),
	'identifierSchemeCodeUnspsc190501.60121225': __('identifierSchemeCodeUnspsc190501.60121225'),
	'identifierSchemeCodeUnspsc190501.60121226': __('identifierSchemeCodeUnspsc190501.60121226'),
	'identifierSchemeCodeUnspsc190501.60121227': __('identifierSchemeCodeUnspsc190501.60121227'),
	'identifierSchemeCodeUnspsc190501.60121228': __('identifierSchemeCodeUnspsc190501.60121228'),
	'identifierSchemeCodeUnspsc190501.60121229': __('identifierSchemeCodeUnspsc190501.60121229'),
	'identifierSchemeCodeUnspsc190501.60121230': __('identifierSchemeCodeUnspsc190501.60121230'),
	'identifierSchemeCodeUnspsc190501.60121231': __('identifierSchemeCodeUnspsc190501.60121231'),
	'identifierSchemeCodeUnspsc190501.60121232': __('identifierSchemeCodeUnspsc190501.60121232'),
	'identifierSchemeCodeUnspsc190501.60121233': __('identifierSchemeCodeUnspsc190501.60121233'),
	'identifierSchemeCodeUnspsc190501.60121234': __('identifierSchemeCodeUnspsc190501.60121234'),
	'identifierSchemeCodeUnspsc190501.60121235': __('identifierSchemeCodeUnspsc190501.60121235'),
	'identifierSchemeCodeUnspsc190501.60121236': __('identifierSchemeCodeUnspsc190501.60121236'),
	'identifierSchemeCodeUnspsc190501.60121237': __('identifierSchemeCodeUnspsc190501.60121237'),
	'identifierSchemeCodeUnspsc190501.60121238': __('identifierSchemeCodeUnspsc190501.60121238'),
	'identifierSchemeCodeUnspsc190501.60121239': __('identifierSchemeCodeUnspsc190501.60121239'),
	'identifierSchemeCodeUnspsc190501.60121241': __('identifierSchemeCodeUnspsc190501.60121241'),
	'identifierSchemeCodeUnspsc190501.60121242': __('identifierSchemeCodeUnspsc190501.60121242'),
	'identifierSchemeCodeUnspsc190501.60121243': __('identifierSchemeCodeUnspsc190501.60121243'),
	'identifierSchemeCodeUnspsc190501.60121244': __('identifierSchemeCodeUnspsc190501.60121244'),
	'identifierSchemeCodeUnspsc190501.60121245': __('identifierSchemeCodeUnspsc190501.60121245'),
	'identifierSchemeCodeUnspsc190501.60121246': __('identifierSchemeCodeUnspsc190501.60121246'),
	'identifierSchemeCodeUnspsc190501.60121247': __('identifierSchemeCodeUnspsc190501.60121247'),
	'identifierSchemeCodeUnspsc190501.60121248': __('identifierSchemeCodeUnspsc190501.60121248'),
	'identifierSchemeCodeUnspsc190501.60121249': __('identifierSchemeCodeUnspsc190501.60121249'),
	'identifierSchemeCodeUnspsc190501.60121250': __('identifierSchemeCodeUnspsc190501.60121250'),
	'identifierSchemeCodeUnspsc190501.60121251': __('identifierSchemeCodeUnspsc190501.60121251'),
	'identifierSchemeCodeUnspsc190501.60121252': __('identifierSchemeCodeUnspsc190501.60121252'),
	'identifierSchemeCodeUnspsc190501.60121253': __('identifierSchemeCodeUnspsc190501.60121253'),
	'identifierSchemeCodeUnspsc190501.60121300': __('identifierSchemeCodeUnspsc190501.60121300'),
	'identifierSchemeCodeUnspsc190501.60121301': __('identifierSchemeCodeUnspsc190501.60121301'),
	'identifierSchemeCodeUnspsc190501.60121302': __('identifierSchemeCodeUnspsc190501.60121302'),
	'identifierSchemeCodeUnspsc190501.60121303': __('identifierSchemeCodeUnspsc190501.60121303'),
	'identifierSchemeCodeUnspsc190501.60121304': __('identifierSchemeCodeUnspsc190501.60121304'),
	'identifierSchemeCodeUnspsc190501.60121305': __('identifierSchemeCodeUnspsc190501.60121305'),
	'identifierSchemeCodeUnspsc190501.60121306': __('identifierSchemeCodeUnspsc190501.60121306'),
	'identifierSchemeCodeUnspsc190501.60121400': __('identifierSchemeCodeUnspsc190501.60121400'),
	'identifierSchemeCodeUnspsc190501.60121401': __('identifierSchemeCodeUnspsc190501.60121401'),
	'identifierSchemeCodeUnspsc190501.60121402': __('identifierSchemeCodeUnspsc190501.60121402'),
	'identifierSchemeCodeUnspsc190501.60121403': __('identifierSchemeCodeUnspsc190501.60121403'),
	'identifierSchemeCodeUnspsc190501.60121404': __('identifierSchemeCodeUnspsc190501.60121404'),
	'identifierSchemeCodeUnspsc190501.60121405': __('identifierSchemeCodeUnspsc190501.60121405'),
	'identifierSchemeCodeUnspsc190501.60121406': __('identifierSchemeCodeUnspsc190501.60121406'),
	'identifierSchemeCodeUnspsc190501.60121407': __('identifierSchemeCodeUnspsc190501.60121407'),
	'identifierSchemeCodeUnspsc190501.60121408': __('identifierSchemeCodeUnspsc190501.60121408'),
	'identifierSchemeCodeUnspsc190501.60121409': __('identifierSchemeCodeUnspsc190501.60121409'),
	'identifierSchemeCodeUnspsc190501.60121410': __('identifierSchemeCodeUnspsc190501.60121410'),
	'identifierSchemeCodeUnspsc190501.60121411': __('identifierSchemeCodeUnspsc190501.60121411'),
	'identifierSchemeCodeUnspsc190501.60121412': __('identifierSchemeCodeUnspsc190501.60121412'),
	'identifierSchemeCodeUnspsc190501.60121413': __('identifierSchemeCodeUnspsc190501.60121413'),
	'identifierSchemeCodeUnspsc190501.60121414': __('identifierSchemeCodeUnspsc190501.60121414'),
	'identifierSchemeCodeUnspsc190501.60121415': __('identifierSchemeCodeUnspsc190501.60121415'),
	'identifierSchemeCodeUnspsc190501.60121500': __('identifierSchemeCodeUnspsc190501.60121500'),
	'identifierSchemeCodeUnspsc190501.60121501': __('identifierSchemeCodeUnspsc190501.60121501'),
	'identifierSchemeCodeUnspsc190501.60121502': __('identifierSchemeCodeUnspsc190501.60121502'),
	'identifierSchemeCodeUnspsc190501.60121503': __('identifierSchemeCodeUnspsc190501.60121503'),
	'identifierSchemeCodeUnspsc190501.60121504': __('identifierSchemeCodeUnspsc190501.60121504'),
	'identifierSchemeCodeUnspsc190501.60121505': __('identifierSchemeCodeUnspsc190501.60121505'),
	'identifierSchemeCodeUnspsc190501.60121506': __('identifierSchemeCodeUnspsc190501.60121506'),
	'identifierSchemeCodeUnspsc190501.60121507': __('identifierSchemeCodeUnspsc190501.60121507'),
	'identifierSchemeCodeUnspsc190501.60121508': __('identifierSchemeCodeUnspsc190501.60121508'),
	'identifierSchemeCodeUnspsc190501.60121509': __('identifierSchemeCodeUnspsc190501.60121509'),
	'identifierSchemeCodeUnspsc190501.60121510': __('identifierSchemeCodeUnspsc190501.60121510'),
	'identifierSchemeCodeUnspsc190501.60121511': __('identifierSchemeCodeUnspsc190501.60121511'),
	'identifierSchemeCodeUnspsc190501.60121512': __('identifierSchemeCodeUnspsc190501.60121512'),
	'identifierSchemeCodeUnspsc190501.60121513': __('identifierSchemeCodeUnspsc190501.60121513'),
	'identifierSchemeCodeUnspsc190501.60121514': __('identifierSchemeCodeUnspsc190501.60121514'),
	'identifierSchemeCodeUnspsc190501.60121515': __('identifierSchemeCodeUnspsc190501.60121515'),
	'identifierSchemeCodeUnspsc190501.60121516': __('identifierSchemeCodeUnspsc190501.60121516'),
	'identifierSchemeCodeUnspsc190501.60121517': __('identifierSchemeCodeUnspsc190501.60121517'),
	'identifierSchemeCodeUnspsc190501.60121518': __('identifierSchemeCodeUnspsc190501.60121518'),
	'identifierSchemeCodeUnspsc190501.60121519': __('identifierSchemeCodeUnspsc190501.60121519'),
	'identifierSchemeCodeUnspsc190501.60121520': __('identifierSchemeCodeUnspsc190501.60121520'),
	'identifierSchemeCodeUnspsc190501.60121521': __('identifierSchemeCodeUnspsc190501.60121521'),
	'identifierSchemeCodeUnspsc190501.60121522': __('identifierSchemeCodeUnspsc190501.60121522'),
	'identifierSchemeCodeUnspsc190501.60121523': __('identifierSchemeCodeUnspsc190501.60121523'),
	'identifierSchemeCodeUnspsc190501.60121524': __('identifierSchemeCodeUnspsc190501.60121524'),
	'identifierSchemeCodeUnspsc190501.60121525': __('identifierSchemeCodeUnspsc190501.60121525'),
	'identifierSchemeCodeUnspsc190501.60121526': __('identifierSchemeCodeUnspsc190501.60121526'),
	'identifierSchemeCodeUnspsc190501.60121531': __('identifierSchemeCodeUnspsc190501.60121531'),
	'identifierSchemeCodeUnspsc190501.60121532': __('identifierSchemeCodeUnspsc190501.60121532'),
	'identifierSchemeCodeUnspsc190501.60121533': __('identifierSchemeCodeUnspsc190501.60121533'),
	'identifierSchemeCodeUnspsc190501.60121534': __('identifierSchemeCodeUnspsc190501.60121534'),
	'identifierSchemeCodeUnspsc190501.60121535': __('identifierSchemeCodeUnspsc190501.60121535'),
	'identifierSchemeCodeUnspsc190501.60121536': __('identifierSchemeCodeUnspsc190501.60121536'),
	'identifierSchemeCodeUnspsc190501.60121537': __('identifierSchemeCodeUnspsc190501.60121537'),
	'identifierSchemeCodeUnspsc190501.60121538': __('identifierSchemeCodeUnspsc190501.60121538'),
	'identifierSchemeCodeUnspsc190501.60121539': __('identifierSchemeCodeUnspsc190501.60121539'),
	'identifierSchemeCodeUnspsc190501.60121540': __('identifierSchemeCodeUnspsc190501.60121540'),
	'identifierSchemeCodeUnspsc190501.60121600': __('identifierSchemeCodeUnspsc190501.60121600'),
	'identifierSchemeCodeUnspsc190501.60121601': __('identifierSchemeCodeUnspsc190501.60121601'),
	'identifierSchemeCodeUnspsc190501.60121602': __('identifierSchemeCodeUnspsc190501.60121602'),
	'identifierSchemeCodeUnspsc190501.60121603': __('identifierSchemeCodeUnspsc190501.60121603'),
	'identifierSchemeCodeUnspsc190501.60121604': __('identifierSchemeCodeUnspsc190501.60121604'),
	'identifierSchemeCodeUnspsc190501.60121605': __('identifierSchemeCodeUnspsc190501.60121605'),
	'identifierSchemeCodeUnspsc190501.60121606': __('identifierSchemeCodeUnspsc190501.60121606'),
	'identifierSchemeCodeUnspsc190501.60121700': __('identifierSchemeCodeUnspsc190501.60121700'),
	'identifierSchemeCodeUnspsc190501.60121701': __('identifierSchemeCodeUnspsc190501.60121701'),
	'identifierSchemeCodeUnspsc190501.60121702': __('identifierSchemeCodeUnspsc190501.60121702'),
	'identifierSchemeCodeUnspsc190501.60121703': __('identifierSchemeCodeUnspsc190501.60121703'),
	'identifierSchemeCodeUnspsc190501.60121704': __('identifierSchemeCodeUnspsc190501.60121704'),
	'identifierSchemeCodeUnspsc190501.60121705': __('identifierSchemeCodeUnspsc190501.60121705'),
	'identifierSchemeCodeUnspsc190501.60121706': __('identifierSchemeCodeUnspsc190501.60121706'),
	'identifierSchemeCodeUnspsc190501.60121707': __('identifierSchemeCodeUnspsc190501.60121707'),
	'identifierSchemeCodeUnspsc190501.60121708': __('identifierSchemeCodeUnspsc190501.60121708'),
	'identifierSchemeCodeUnspsc190501.60121709': __('identifierSchemeCodeUnspsc190501.60121709'),
	'identifierSchemeCodeUnspsc190501.60121710': __('identifierSchemeCodeUnspsc190501.60121710'),
	'identifierSchemeCodeUnspsc190501.60121711': __('identifierSchemeCodeUnspsc190501.60121711'),
	'identifierSchemeCodeUnspsc190501.60121712': __('identifierSchemeCodeUnspsc190501.60121712'),
	'identifierSchemeCodeUnspsc190501.60121713': __('identifierSchemeCodeUnspsc190501.60121713'),
	'identifierSchemeCodeUnspsc190501.60121714': __('identifierSchemeCodeUnspsc190501.60121714'),
	'identifierSchemeCodeUnspsc190501.60121715': __('identifierSchemeCodeUnspsc190501.60121715'),
	'identifierSchemeCodeUnspsc190501.60121716': __('identifierSchemeCodeUnspsc190501.60121716'),
	'identifierSchemeCodeUnspsc190501.60121717': __('identifierSchemeCodeUnspsc190501.60121717'),
	'identifierSchemeCodeUnspsc190501.60121718': __('identifierSchemeCodeUnspsc190501.60121718'),
	'identifierSchemeCodeUnspsc190501.60121800': __('identifierSchemeCodeUnspsc190501.60121800'),
	'identifierSchemeCodeUnspsc190501.60121801': __('identifierSchemeCodeUnspsc190501.60121801'),
	'identifierSchemeCodeUnspsc190501.60121802': __('identifierSchemeCodeUnspsc190501.60121802'),
	'identifierSchemeCodeUnspsc190501.60121803': __('identifierSchemeCodeUnspsc190501.60121803'),
	'identifierSchemeCodeUnspsc190501.60121804': __('identifierSchemeCodeUnspsc190501.60121804'),
	'identifierSchemeCodeUnspsc190501.60121805': __('identifierSchemeCodeUnspsc190501.60121805'),
	'identifierSchemeCodeUnspsc190501.60121806': __('identifierSchemeCodeUnspsc190501.60121806'),
	'identifierSchemeCodeUnspsc190501.60121807': __('identifierSchemeCodeUnspsc190501.60121807'),
	'identifierSchemeCodeUnspsc190501.60121808': __('identifierSchemeCodeUnspsc190501.60121808'),
	'identifierSchemeCodeUnspsc190501.60121809': __('identifierSchemeCodeUnspsc190501.60121809'),
	'identifierSchemeCodeUnspsc190501.60121810': __('identifierSchemeCodeUnspsc190501.60121810'),
	'identifierSchemeCodeUnspsc190501.60121811': __('identifierSchemeCodeUnspsc190501.60121811'),
	'identifierSchemeCodeUnspsc190501.60121812': __('identifierSchemeCodeUnspsc190501.60121812'),
	'identifierSchemeCodeUnspsc190501.60121813': __('identifierSchemeCodeUnspsc190501.60121813'),
	'identifierSchemeCodeUnspsc190501.60121814': __('identifierSchemeCodeUnspsc190501.60121814'),
	'identifierSchemeCodeUnspsc190501.60121900': __('identifierSchemeCodeUnspsc190501.60121900'),
	'identifierSchemeCodeUnspsc190501.60121901': __('identifierSchemeCodeUnspsc190501.60121901'),
	'identifierSchemeCodeUnspsc190501.60121902': __('identifierSchemeCodeUnspsc190501.60121902'),
	'identifierSchemeCodeUnspsc190501.60121903': __('identifierSchemeCodeUnspsc190501.60121903'),
	'identifierSchemeCodeUnspsc190501.60121904': __('identifierSchemeCodeUnspsc190501.60121904'),
	'identifierSchemeCodeUnspsc190501.60121905': __('identifierSchemeCodeUnspsc190501.60121905'),
	'identifierSchemeCodeUnspsc190501.60121906': __('identifierSchemeCodeUnspsc190501.60121906'),
	'identifierSchemeCodeUnspsc190501.60121907': __('identifierSchemeCodeUnspsc190501.60121907'),
	'identifierSchemeCodeUnspsc190501.60121908': __('identifierSchemeCodeUnspsc190501.60121908'),
	'identifierSchemeCodeUnspsc190501.60121909': __('identifierSchemeCodeUnspsc190501.60121909'),
	'identifierSchemeCodeUnspsc190501.60121910': __('identifierSchemeCodeUnspsc190501.60121910'),
	'identifierSchemeCodeUnspsc190501.60121911': __('identifierSchemeCodeUnspsc190501.60121911'),
	'identifierSchemeCodeUnspsc190501.60121912': __('identifierSchemeCodeUnspsc190501.60121912'),
	'identifierSchemeCodeUnspsc190501.60122000': __('identifierSchemeCodeUnspsc190501.60122000'),
	'identifierSchemeCodeUnspsc190501.60122002': __('identifierSchemeCodeUnspsc190501.60122002'),
	'identifierSchemeCodeUnspsc190501.60122003': __('identifierSchemeCodeUnspsc190501.60122003'),
	'identifierSchemeCodeUnspsc190501.60122004': __('identifierSchemeCodeUnspsc190501.60122004'),
	'identifierSchemeCodeUnspsc190501.60122005': __('identifierSchemeCodeUnspsc190501.60122005'),
	'identifierSchemeCodeUnspsc190501.60122006': __('identifierSchemeCodeUnspsc190501.60122006'),
	'identifierSchemeCodeUnspsc190501.60122007': __('identifierSchemeCodeUnspsc190501.60122007'),
	'identifierSchemeCodeUnspsc190501.60122008': __('identifierSchemeCodeUnspsc190501.60122008'),
	'identifierSchemeCodeUnspsc190501.60122009': __('identifierSchemeCodeUnspsc190501.60122009'),
	'identifierSchemeCodeUnspsc190501.60122100': __('identifierSchemeCodeUnspsc190501.60122100'),
	'identifierSchemeCodeUnspsc190501.60122101': __('identifierSchemeCodeUnspsc190501.60122101'),
	'identifierSchemeCodeUnspsc190501.60122102': __('identifierSchemeCodeUnspsc190501.60122102'),
	'identifierSchemeCodeUnspsc190501.60122103': __('identifierSchemeCodeUnspsc190501.60122103'),
	'identifierSchemeCodeUnspsc190501.60122200': __('identifierSchemeCodeUnspsc190501.60122200'),
	'identifierSchemeCodeUnspsc190501.60122201': __('identifierSchemeCodeUnspsc190501.60122201'),
	'identifierSchemeCodeUnspsc190501.60122202': __('identifierSchemeCodeUnspsc190501.60122202'),
	'identifierSchemeCodeUnspsc190501.60122203': __('identifierSchemeCodeUnspsc190501.60122203'),
	'identifierSchemeCodeUnspsc190501.60122204': __('identifierSchemeCodeUnspsc190501.60122204'),
	'identifierSchemeCodeUnspsc190501.60122300': __('identifierSchemeCodeUnspsc190501.60122300'),
	'identifierSchemeCodeUnspsc190501.60122301': __('identifierSchemeCodeUnspsc190501.60122301'),
	'identifierSchemeCodeUnspsc190501.60122302': __('identifierSchemeCodeUnspsc190501.60122302'),
	'identifierSchemeCodeUnspsc190501.60122400': __('identifierSchemeCodeUnspsc190501.60122400'),
	'identifierSchemeCodeUnspsc190501.60122401': __('identifierSchemeCodeUnspsc190501.60122401'),
	'identifierSchemeCodeUnspsc190501.60122402': __('identifierSchemeCodeUnspsc190501.60122402'),
	'identifierSchemeCodeUnspsc190501.60122500': __('identifierSchemeCodeUnspsc190501.60122500'),
	'identifierSchemeCodeUnspsc190501.60122501': __('identifierSchemeCodeUnspsc190501.60122501'),
	'identifierSchemeCodeUnspsc190501.60122502': __('identifierSchemeCodeUnspsc190501.60122502'),
	'identifierSchemeCodeUnspsc190501.60122503': __('identifierSchemeCodeUnspsc190501.60122503'),
	'identifierSchemeCodeUnspsc190501.60122504': __('identifierSchemeCodeUnspsc190501.60122504'),
	'identifierSchemeCodeUnspsc190501.60122506': __('identifierSchemeCodeUnspsc190501.60122506'),
	'identifierSchemeCodeUnspsc190501.60122507': __('identifierSchemeCodeUnspsc190501.60122507'),
	'identifierSchemeCodeUnspsc190501.60122508': __('identifierSchemeCodeUnspsc190501.60122508'),
	'identifierSchemeCodeUnspsc190501.60122509': __('identifierSchemeCodeUnspsc190501.60122509'),
	'identifierSchemeCodeUnspsc190501.60122510': __('identifierSchemeCodeUnspsc190501.60122510'),
	'identifierSchemeCodeUnspsc190501.60122600': __('identifierSchemeCodeUnspsc190501.60122600'),
	'identifierSchemeCodeUnspsc190501.60122601': __('identifierSchemeCodeUnspsc190501.60122601'),
	'identifierSchemeCodeUnspsc190501.60122602': __('identifierSchemeCodeUnspsc190501.60122602'),
	'identifierSchemeCodeUnspsc190501.60122603': __('identifierSchemeCodeUnspsc190501.60122603'),
	'identifierSchemeCodeUnspsc190501.60122604': __('identifierSchemeCodeUnspsc190501.60122604'),
	'identifierSchemeCodeUnspsc190501.60122700': __('identifierSchemeCodeUnspsc190501.60122700'),
	'identifierSchemeCodeUnspsc190501.60122701': __('identifierSchemeCodeUnspsc190501.60122701'),
	'identifierSchemeCodeUnspsc190501.60122702': __('identifierSchemeCodeUnspsc190501.60122702'),
	'identifierSchemeCodeUnspsc190501.60122703': __('identifierSchemeCodeUnspsc190501.60122703'),
	'identifierSchemeCodeUnspsc190501.60122704': __('identifierSchemeCodeUnspsc190501.60122704'),
	'identifierSchemeCodeUnspsc190501.60122800': __('identifierSchemeCodeUnspsc190501.60122800'),
	'identifierSchemeCodeUnspsc190501.60122801': __('identifierSchemeCodeUnspsc190501.60122801'),
	'identifierSchemeCodeUnspsc190501.60122900': __('identifierSchemeCodeUnspsc190501.60122900'),
	'identifierSchemeCodeUnspsc190501.60122901': __('identifierSchemeCodeUnspsc190501.60122901'),
	'identifierSchemeCodeUnspsc190501.60122902': __('identifierSchemeCodeUnspsc190501.60122902'),
	'identifierSchemeCodeUnspsc190501.60122903': __('identifierSchemeCodeUnspsc190501.60122903'),
	'identifierSchemeCodeUnspsc190501.60122904': __('identifierSchemeCodeUnspsc190501.60122904'),
	'identifierSchemeCodeUnspsc190501.60122905': __('identifierSchemeCodeUnspsc190501.60122905'),
	'identifierSchemeCodeUnspsc190501.60122906': __('identifierSchemeCodeUnspsc190501.60122906'),
	'identifierSchemeCodeUnspsc190501.60122907': __('identifierSchemeCodeUnspsc190501.60122907'),
	'identifierSchemeCodeUnspsc190501.60122908': __('identifierSchemeCodeUnspsc190501.60122908'),
	'identifierSchemeCodeUnspsc190501.60122909': __('identifierSchemeCodeUnspsc190501.60122909'),
	'identifierSchemeCodeUnspsc190501.60123000': __('identifierSchemeCodeUnspsc190501.60123000'),
	'identifierSchemeCodeUnspsc190501.60123001': __('identifierSchemeCodeUnspsc190501.60123001'),
	'identifierSchemeCodeUnspsc190501.60123002': __('identifierSchemeCodeUnspsc190501.60123002'),
	'identifierSchemeCodeUnspsc190501.60123100': __('identifierSchemeCodeUnspsc190501.60123100'),
	'identifierSchemeCodeUnspsc190501.60123101': __('identifierSchemeCodeUnspsc190501.60123101'),
	'identifierSchemeCodeUnspsc190501.60123102': __('identifierSchemeCodeUnspsc190501.60123102'),
	'identifierSchemeCodeUnspsc190501.60123103': __('identifierSchemeCodeUnspsc190501.60123103'),
	'identifierSchemeCodeUnspsc190501.60123200': __('identifierSchemeCodeUnspsc190501.60123200'),
	'identifierSchemeCodeUnspsc190501.60123201': __('identifierSchemeCodeUnspsc190501.60123201'),
	'identifierSchemeCodeUnspsc190501.60123202': __('identifierSchemeCodeUnspsc190501.60123202'),
	'identifierSchemeCodeUnspsc190501.60123203': __('identifierSchemeCodeUnspsc190501.60123203'),
	'identifierSchemeCodeUnspsc190501.60123204': __('identifierSchemeCodeUnspsc190501.60123204'),
	'identifierSchemeCodeUnspsc190501.60123300': __('identifierSchemeCodeUnspsc190501.60123300'),
	'identifierSchemeCodeUnspsc190501.60123301': __('identifierSchemeCodeUnspsc190501.60123301'),
	'identifierSchemeCodeUnspsc190501.60123302': __('identifierSchemeCodeUnspsc190501.60123302'),
	'identifierSchemeCodeUnspsc190501.60123303': __('identifierSchemeCodeUnspsc190501.60123303'),
	'identifierSchemeCodeUnspsc190501.60123400': __('identifierSchemeCodeUnspsc190501.60123400'),
	'identifierSchemeCodeUnspsc190501.60123401': __('identifierSchemeCodeUnspsc190501.60123401'),
	'identifierSchemeCodeUnspsc190501.60123402': __('identifierSchemeCodeUnspsc190501.60123402'),
	'identifierSchemeCodeUnspsc190501.60123403': __('identifierSchemeCodeUnspsc190501.60123403'),
	'identifierSchemeCodeUnspsc190501.60123500': __('identifierSchemeCodeUnspsc190501.60123500'),
	'identifierSchemeCodeUnspsc190501.60123501': __('identifierSchemeCodeUnspsc190501.60123501'),
	'identifierSchemeCodeUnspsc190501.60123502': __('identifierSchemeCodeUnspsc190501.60123502'),
	'identifierSchemeCodeUnspsc190501.60123600': __('identifierSchemeCodeUnspsc190501.60123600'),
	'identifierSchemeCodeUnspsc190501.60123601': __('identifierSchemeCodeUnspsc190501.60123601'),
	'identifierSchemeCodeUnspsc190501.60123602': __('identifierSchemeCodeUnspsc190501.60123602'),
	'identifierSchemeCodeUnspsc190501.60123603': __('identifierSchemeCodeUnspsc190501.60123603'),
	'identifierSchemeCodeUnspsc190501.60123604': __('identifierSchemeCodeUnspsc190501.60123604'),
	'identifierSchemeCodeUnspsc190501.60123605': __('identifierSchemeCodeUnspsc190501.60123605'),
	'identifierSchemeCodeUnspsc190501.60123606': __('identifierSchemeCodeUnspsc190501.60123606'),
	'identifierSchemeCodeUnspsc190501.60123700': __('identifierSchemeCodeUnspsc190501.60123700'),
	'identifierSchemeCodeUnspsc190501.60123701': __('identifierSchemeCodeUnspsc190501.60123701'),
	'identifierSchemeCodeUnspsc190501.60123702': __('identifierSchemeCodeUnspsc190501.60123702'),
	'identifierSchemeCodeUnspsc190501.60123703': __('identifierSchemeCodeUnspsc190501.60123703'),
	'identifierSchemeCodeUnspsc190501.60123800': __('identifierSchemeCodeUnspsc190501.60123800'),
	'identifierSchemeCodeUnspsc190501.60123801': __('identifierSchemeCodeUnspsc190501.60123801'),
	'identifierSchemeCodeUnspsc190501.60123802': __('identifierSchemeCodeUnspsc190501.60123802'),
	'identifierSchemeCodeUnspsc190501.60123900': __('identifierSchemeCodeUnspsc190501.60123900'),
	'identifierSchemeCodeUnspsc190501.60123901': __('identifierSchemeCodeUnspsc190501.60123901'),
	'identifierSchemeCodeUnspsc190501.60124000': __('identifierSchemeCodeUnspsc190501.60124000'),
	'identifierSchemeCodeUnspsc190501.60124001': __('identifierSchemeCodeUnspsc190501.60124001'),
	'identifierSchemeCodeUnspsc190501.60124002': __('identifierSchemeCodeUnspsc190501.60124002'),
	'identifierSchemeCodeUnspsc190501.60124100': __('identifierSchemeCodeUnspsc190501.60124100'),
	'identifierSchemeCodeUnspsc190501.60124101': __('identifierSchemeCodeUnspsc190501.60124101'),
	'identifierSchemeCodeUnspsc190501.60124102': __('identifierSchemeCodeUnspsc190501.60124102'),
	'identifierSchemeCodeUnspsc190501.60124200': __('identifierSchemeCodeUnspsc190501.60124200'),
	'identifierSchemeCodeUnspsc190501.60124201': __('identifierSchemeCodeUnspsc190501.60124201'),
	'identifierSchemeCodeUnspsc190501.60124300': __('identifierSchemeCodeUnspsc190501.60124300'),
	'identifierSchemeCodeUnspsc190501.60124301': __('identifierSchemeCodeUnspsc190501.60124301'),
	'identifierSchemeCodeUnspsc190501.60124302': __('identifierSchemeCodeUnspsc190501.60124302'),
	'identifierSchemeCodeUnspsc190501.60124303': __('identifierSchemeCodeUnspsc190501.60124303'),
	'identifierSchemeCodeUnspsc190501.60124304': __('identifierSchemeCodeUnspsc190501.60124304'),
	'identifierSchemeCodeUnspsc190501.60124305': __('identifierSchemeCodeUnspsc190501.60124305'),
	'identifierSchemeCodeUnspsc190501.60124306': __('identifierSchemeCodeUnspsc190501.60124306'),
	'identifierSchemeCodeUnspsc190501.60124307': __('identifierSchemeCodeUnspsc190501.60124307'),
	'identifierSchemeCodeUnspsc190501.60124308': __('identifierSchemeCodeUnspsc190501.60124308'),
	'identifierSchemeCodeUnspsc190501.60124309': __('identifierSchemeCodeUnspsc190501.60124309'),
	'identifierSchemeCodeUnspsc190501.60124310': __('identifierSchemeCodeUnspsc190501.60124310'),
	'identifierSchemeCodeUnspsc190501.60124311': __('identifierSchemeCodeUnspsc190501.60124311'),
	'identifierSchemeCodeUnspsc190501.60124312': __('identifierSchemeCodeUnspsc190501.60124312'),
	'identifierSchemeCodeUnspsc190501.60124313': __('identifierSchemeCodeUnspsc190501.60124313'),
	'identifierSchemeCodeUnspsc190501.60124314': __('identifierSchemeCodeUnspsc190501.60124314'),
	'identifierSchemeCodeUnspsc190501.60124315': __('identifierSchemeCodeUnspsc190501.60124315'),
	'identifierSchemeCodeUnspsc190501.60124316': __('identifierSchemeCodeUnspsc190501.60124316'),
	'identifierSchemeCodeUnspsc190501.60124317': __('identifierSchemeCodeUnspsc190501.60124317'),
	'identifierSchemeCodeUnspsc190501.60124318': __('identifierSchemeCodeUnspsc190501.60124318'),
	'identifierSchemeCodeUnspsc190501.60124319': __('identifierSchemeCodeUnspsc190501.60124319'),
	'identifierSchemeCodeUnspsc190501.60124320': __('identifierSchemeCodeUnspsc190501.60124320'),
	'identifierSchemeCodeUnspsc190501.60124321': __('identifierSchemeCodeUnspsc190501.60124321'),
	'identifierSchemeCodeUnspsc190501.60124322': __('identifierSchemeCodeUnspsc190501.60124322'),
	'identifierSchemeCodeUnspsc190501.60124323': __('identifierSchemeCodeUnspsc190501.60124323'),
	'identifierSchemeCodeUnspsc190501.60124324': __('identifierSchemeCodeUnspsc190501.60124324'),
	'identifierSchemeCodeUnspsc190501.60124325': __('identifierSchemeCodeUnspsc190501.60124325'),
	'identifierSchemeCodeUnspsc190501.60124400': __('identifierSchemeCodeUnspsc190501.60124400'),
	'identifierSchemeCodeUnspsc190501.60124401': __('identifierSchemeCodeUnspsc190501.60124401'),
	'identifierSchemeCodeUnspsc190501.60124402': __('identifierSchemeCodeUnspsc190501.60124402'),
	'identifierSchemeCodeUnspsc190501.60124403': __('identifierSchemeCodeUnspsc190501.60124403'),
	'identifierSchemeCodeUnspsc190501.60124404': __('identifierSchemeCodeUnspsc190501.60124404'),
	'identifierSchemeCodeUnspsc190501.60124406': __('identifierSchemeCodeUnspsc190501.60124406'),
	'identifierSchemeCodeUnspsc190501.60124407': __('identifierSchemeCodeUnspsc190501.60124407'),
	'identifierSchemeCodeUnspsc190501.60124408': __('identifierSchemeCodeUnspsc190501.60124408'),
	'identifierSchemeCodeUnspsc190501.60124409': __('identifierSchemeCodeUnspsc190501.60124409'),
	'identifierSchemeCodeUnspsc190501.60124410': __('identifierSchemeCodeUnspsc190501.60124410'),
	'identifierSchemeCodeUnspsc190501.60124411': __('identifierSchemeCodeUnspsc190501.60124411'),
	'identifierSchemeCodeUnspsc190501.60124412': __('identifierSchemeCodeUnspsc190501.60124412'),
	'identifierSchemeCodeUnspsc190501.60124500': __('identifierSchemeCodeUnspsc190501.60124500'),
	'identifierSchemeCodeUnspsc190501.60124501': __('identifierSchemeCodeUnspsc190501.60124501'),
	'identifierSchemeCodeUnspsc190501.60124502': __('identifierSchemeCodeUnspsc190501.60124502'),
	'identifierSchemeCodeUnspsc190501.60124503': __('identifierSchemeCodeUnspsc190501.60124503'),
	'identifierSchemeCodeUnspsc190501.60124504': __('identifierSchemeCodeUnspsc190501.60124504'),
	'identifierSchemeCodeUnspsc190501.60124505': __('identifierSchemeCodeUnspsc190501.60124505'),
	'identifierSchemeCodeUnspsc190501.60124506': __('identifierSchemeCodeUnspsc190501.60124506'),
	'identifierSchemeCodeUnspsc190501.60124507': __('identifierSchemeCodeUnspsc190501.60124507'),
	'identifierSchemeCodeUnspsc190501.60124508': __('identifierSchemeCodeUnspsc190501.60124508'),
	'identifierSchemeCodeUnspsc190501.60124509': __('identifierSchemeCodeUnspsc190501.60124509'),
	'identifierSchemeCodeUnspsc190501.60124510': __('identifierSchemeCodeUnspsc190501.60124510'),
	'identifierSchemeCodeUnspsc190501.60124511': __('identifierSchemeCodeUnspsc190501.60124511'),
	'identifierSchemeCodeUnspsc190501.60124512': __('identifierSchemeCodeUnspsc190501.60124512'),
	'identifierSchemeCodeUnspsc190501.60124513': __('identifierSchemeCodeUnspsc190501.60124513'),
	'identifierSchemeCodeUnspsc190501.60124514': __('identifierSchemeCodeUnspsc190501.60124514'),
	'identifierSchemeCodeUnspsc190501.60124515': __('identifierSchemeCodeUnspsc190501.60124515'),
	'identifierSchemeCodeUnspsc190501.60130000': __('identifierSchemeCodeUnspsc190501.60130000'),
	'identifierSchemeCodeUnspsc190501.60131000': __('identifierSchemeCodeUnspsc190501.60131000'),
	'identifierSchemeCodeUnspsc190501.60131001': __('identifierSchemeCodeUnspsc190501.60131001'),
	'identifierSchemeCodeUnspsc190501.60131002': __('identifierSchemeCodeUnspsc190501.60131002'),
	'identifierSchemeCodeUnspsc190501.60131003': __('identifierSchemeCodeUnspsc190501.60131003'),
	'identifierSchemeCodeUnspsc190501.60131004': __('identifierSchemeCodeUnspsc190501.60131004'),
	'identifierSchemeCodeUnspsc190501.60131005': __('identifierSchemeCodeUnspsc190501.60131005'),
	'identifierSchemeCodeUnspsc190501.60131006': __('identifierSchemeCodeUnspsc190501.60131006'),
	'identifierSchemeCodeUnspsc190501.60131007': __('identifierSchemeCodeUnspsc190501.60131007'),
	'identifierSchemeCodeUnspsc190501.60131100': __('identifierSchemeCodeUnspsc190501.60131100'),
	'identifierSchemeCodeUnspsc190501.60131101': __('identifierSchemeCodeUnspsc190501.60131101'),
	'identifierSchemeCodeUnspsc190501.60131102': __('identifierSchemeCodeUnspsc190501.60131102'),
	'identifierSchemeCodeUnspsc190501.60131103': __('identifierSchemeCodeUnspsc190501.60131103'),
	'identifierSchemeCodeUnspsc190501.60131104': __('identifierSchemeCodeUnspsc190501.60131104'),
	'identifierSchemeCodeUnspsc190501.60131105': __('identifierSchemeCodeUnspsc190501.60131105'),
	'identifierSchemeCodeUnspsc190501.60131106': __('identifierSchemeCodeUnspsc190501.60131106'),
	'identifierSchemeCodeUnspsc190501.60131107': __('identifierSchemeCodeUnspsc190501.60131107'),
	'identifierSchemeCodeUnspsc190501.60131108': __('identifierSchemeCodeUnspsc190501.60131108'),
	'identifierSchemeCodeUnspsc190501.60131109': __('identifierSchemeCodeUnspsc190501.60131109'),
	'identifierSchemeCodeUnspsc190501.60131110': __('identifierSchemeCodeUnspsc190501.60131110'),
	'identifierSchemeCodeUnspsc190501.60131111': __('identifierSchemeCodeUnspsc190501.60131111'),
	'identifierSchemeCodeUnspsc190501.60131112': __('identifierSchemeCodeUnspsc190501.60131112'),
	'identifierSchemeCodeUnspsc190501.60131113': __('identifierSchemeCodeUnspsc190501.60131113'),
	'identifierSchemeCodeUnspsc190501.60131114': __('identifierSchemeCodeUnspsc190501.60131114'),
	'identifierSchemeCodeUnspsc190501.60131115': __('identifierSchemeCodeUnspsc190501.60131115'),
	'identifierSchemeCodeUnspsc190501.60131116': __('identifierSchemeCodeUnspsc190501.60131116'),
	'identifierSchemeCodeUnspsc190501.60131200': __('identifierSchemeCodeUnspsc190501.60131200'),
	'identifierSchemeCodeUnspsc190501.60131201': __('identifierSchemeCodeUnspsc190501.60131201'),
	'identifierSchemeCodeUnspsc190501.60131202': __('identifierSchemeCodeUnspsc190501.60131202'),
	'identifierSchemeCodeUnspsc190501.60131203': __('identifierSchemeCodeUnspsc190501.60131203'),
	'identifierSchemeCodeUnspsc190501.60131204': __('identifierSchemeCodeUnspsc190501.60131204'),
	'identifierSchemeCodeUnspsc190501.60131205': __('identifierSchemeCodeUnspsc190501.60131205'),
	'identifierSchemeCodeUnspsc190501.60131206': __('identifierSchemeCodeUnspsc190501.60131206'),
	'identifierSchemeCodeUnspsc190501.60131207': __('identifierSchemeCodeUnspsc190501.60131207'),
	'identifierSchemeCodeUnspsc190501.60131208': __('identifierSchemeCodeUnspsc190501.60131208'),
	'identifierSchemeCodeUnspsc190501.60131209': __('identifierSchemeCodeUnspsc190501.60131209'),
	'identifierSchemeCodeUnspsc190501.60131210': __('identifierSchemeCodeUnspsc190501.60131210'),
	'identifierSchemeCodeUnspsc190501.60131211': __('identifierSchemeCodeUnspsc190501.60131211'),
	'identifierSchemeCodeUnspsc190501.60131212': __('identifierSchemeCodeUnspsc190501.60131212'),
	'identifierSchemeCodeUnspsc190501.60131213': __('identifierSchemeCodeUnspsc190501.60131213'),
	'identifierSchemeCodeUnspsc190501.60131214': __('identifierSchemeCodeUnspsc190501.60131214'),
	'identifierSchemeCodeUnspsc190501.60131215': __('identifierSchemeCodeUnspsc190501.60131215'),
	'identifierSchemeCodeUnspsc190501.60131216': __('identifierSchemeCodeUnspsc190501.60131216'),
	'identifierSchemeCodeUnspsc190501.60131217': __('identifierSchemeCodeUnspsc190501.60131217'),
	'identifierSchemeCodeUnspsc190501.60131218': __('identifierSchemeCodeUnspsc190501.60131218'),
	'identifierSchemeCodeUnspsc190501.60131219': __('identifierSchemeCodeUnspsc190501.60131219'),
	'identifierSchemeCodeUnspsc190501.60131220': __('identifierSchemeCodeUnspsc190501.60131220'),
	'identifierSchemeCodeUnspsc190501.60131221': __('identifierSchemeCodeUnspsc190501.60131221'),
	'identifierSchemeCodeUnspsc190501.60131222': __('identifierSchemeCodeUnspsc190501.60131222'),
	'identifierSchemeCodeUnspsc190501.60131223': __('identifierSchemeCodeUnspsc190501.60131223'),
	'identifierSchemeCodeUnspsc190501.60131224': __('identifierSchemeCodeUnspsc190501.60131224'),
	'identifierSchemeCodeUnspsc190501.60131225': __('identifierSchemeCodeUnspsc190501.60131225'),
	'identifierSchemeCodeUnspsc190501.60131226': __('identifierSchemeCodeUnspsc190501.60131226'),
	'identifierSchemeCodeUnspsc190501.60131227': __('identifierSchemeCodeUnspsc190501.60131227'),
	'identifierSchemeCodeUnspsc190501.60131228': __('identifierSchemeCodeUnspsc190501.60131228'),
	'identifierSchemeCodeUnspsc190501.60131229': __('identifierSchemeCodeUnspsc190501.60131229'),
	'identifierSchemeCodeUnspsc190501.60131230': __('identifierSchemeCodeUnspsc190501.60131230'),
	'identifierSchemeCodeUnspsc190501.60131231': __('identifierSchemeCodeUnspsc190501.60131231'),
	'identifierSchemeCodeUnspsc190501.60131232': __('identifierSchemeCodeUnspsc190501.60131232'),
	'identifierSchemeCodeUnspsc190501.60131233': __('identifierSchemeCodeUnspsc190501.60131233'),
	'identifierSchemeCodeUnspsc190501.60131234': __('identifierSchemeCodeUnspsc190501.60131234'),
	'identifierSchemeCodeUnspsc190501.60131235': __('identifierSchemeCodeUnspsc190501.60131235'),
	'identifierSchemeCodeUnspsc190501.60131236': __('identifierSchemeCodeUnspsc190501.60131236'),
	'identifierSchemeCodeUnspsc190501.60131237': __('identifierSchemeCodeUnspsc190501.60131237'),
	'identifierSchemeCodeUnspsc190501.60131300': __('identifierSchemeCodeUnspsc190501.60131300'),
	'identifierSchemeCodeUnspsc190501.60131301': __('identifierSchemeCodeUnspsc190501.60131301'),
	'identifierSchemeCodeUnspsc190501.60131302': __('identifierSchemeCodeUnspsc190501.60131302'),
	'identifierSchemeCodeUnspsc190501.60131303': __('identifierSchemeCodeUnspsc190501.60131303'),
	'identifierSchemeCodeUnspsc190501.60131304': __('identifierSchemeCodeUnspsc190501.60131304'),
	'identifierSchemeCodeUnspsc190501.60131305': __('identifierSchemeCodeUnspsc190501.60131305'),
	'identifierSchemeCodeUnspsc190501.60131306': __('identifierSchemeCodeUnspsc190501.60131306'),
	'identifierSchemeCodeUnspsc190501.60131307': __('identifierSchemeCodeUnspsc190501.60131307'),
	'identifierSchemeCodeUnspsc190501.60131308': __('identifierSchemeCodeUnspsc190501.60131308'),
	'identifierSchemeCodeUnspsc190501.60131309': __('identifierSchemeCodeUnspsc190501.60131309'),
	'identifierSchemeCodeUnspsc190501.60131310': __('identifierSchemeCodeUnspsc190501.60131310'),
	'identifierSchemeCodeUnspsc190501.60131311': __('identifierSchemeCodeUnspsc190501.60131311'),
	'identifierSchemeCodeUnspsc190501.60131312': __('identifierSchemeCodeUnspsc190501.60131312'),
	'identifierSchemeCodeUnspsc190501.60131313': __('identifierSchemeCodeUnspsc190501.60131313'),
	'identifierSchemeCodeUnspsc190501.60131314': __('identifierSchemeCodeUnspsc190501.60131314'),
	'identifierSchemeCodeUnspsc190501.60131315': __('identifierSchemeCodeUnspsc190501.60131315'),
	'identifierSchemeCodeUnspsc190501.60131316': __('identifierSchemeCodeUnspsc190501.60131316'),
	'identifierSchemeCodeUnspsc190501.60131317': __('identifierSchemeCodeUnspsc190501.60131317'),
	'identifierSchemeCodeUnspsc190501.60131318': __('identifierSchemeCodeUnspsc190501.60131318'),
	'identifierSchemeCodeUnspsc190501.60131319': __('identifierSchemeCodeUnspsc190501.60131319'),
	'identifierSchemeCodeUnspsc190501.60131320': __('identifierSchemeCodeUnspsc190501.60131320'),
	'identifierSchemeCodeUnspsc190501.60131321': __('identifierSchemeCodeUnspsc190501.60131321'),
	'identifierSchemeCodeUnspsc190501.60131322': __('identifierSchemeCodeUnspsc190501.60131322'),
	'identifierSchemeCodeUnspsc190501.60131323': __('identifierSchemeCodeUnspsc190501.60131323'),
	'identifierSchemeCodeUnspsc190501.60131324': __('identifierSchemeCodeUnspsc190501.60131324'),
	'identifierSchemeCodeUnspsc190501.60131325': __('identifierSchemeCodeUnspsc190501.60131325'),
	'identifierSchemeCodeUnspsc190501.60131326': __('identifierSchemeCodeUnspsc190501.60131326'),
	'identifierSchemeCodeUnspsc190501.60131327': __('identifierSchemeCodeUnspsc190501.60131327'),
	'identifierSchemeCodeUnspsc190501.60131328': __('identifierSchemeCodeUnspsc190501.60131328'),
	'identifierSchemeCodeUnspsc190501.60131329': __('identifierSchemeCodeUnspsc190501.60131329'),
	'identifierSchemeCodeUnspsc190501.60131330': __('identifierSchemeCodeUnspsc190501.60131330'),
	'identifierSchemeCodeUnspsc190501.60131331': __('identifierSchemeCodeUnspsc190501.60131331'),
	'identifierSchemeCodeUnspsc190501.60131332': __('identifierSchemeCodeUnspsc190501.60131332'),
	'identifierSchemeCodeUnspsc190501.60131333': __('identifierSchemeCodeUnspsc190501.60131333'),
	'identifierSchemeCodeUnspsc190501.60131400': __('identifierSchemeCodeUnspsc190501.60131400'),
	'identifierSchemeCodeUnspsc190501.60131401': __('identifierSchemeCodeUnspsc190501.60131401'),
	'identifierSchemeCodeUnspsc190501.60131402': __('identifierSchemeCodeUnspsc190501.60131402'),
	'identifierSchemeCodeUnspsc190501.60131403': __('identifierSchemeCodeUnspsc190501.60131403'),
	'identifierSchemeCodeUnspsc190501.60131404': __('identifierSchemeCodeUnspsc190501.60131404'),
	'identifierSchemeCodeUnspsc190501.60131405': __('identifierSchemeCodeUnspsc190501.60131405'),
	'identifierSchemeCodeUnspsc190501.60131406': __('identifierSchemeCodeUnspsc190501.60131406'),
	'identifierSchemeCodeUnspsc190501.60131407': __('identifierSchemeCodeUnspsc190501.60131407'),
	'identifierSchemeCodeUnspsc190501.60131408': __('identifierSchemeCodeUnspsc190501.60131408'),
	'identifierSchemeCodeUnspsc190501.60131409': __('identifierSchemeCodeUnspsc190501.60131409'),
	'identifierSchemeCodeUnspsc190501.60131410': __('identifierSchemeCodeUnspsc190501.60131410'),
	'identifierSchemeCodeUnspsc190501.60131411': __('identifierSchemeCodeUnspsc190501.60131411'),
	'identifierSchemeCodeUnspsc190501.60131412': __('identifierSchemeCodeUnspsc190501.60131412'),
	'identifierSchemeCodeUnspsc190501.60131413': __('identifierSchemeCodeUnspsc190501.60131413'),
	'identifierSchemeCodeUnspsc190501.60131414': __('identifierSchemeCodeUnspsc190501.60131414'),
	'identifierSchemeCodeUnspsc190501.60131415': __('identifierSchemeCodeUnspsc190501.60131415'),
	'identifierSchemeCodeUnspsc190501.60131416': __('identifierSchemeCodeUnspsc190501.60131416'),
	'identifierSchemeCodeUnspsc190501.60131417': __('identifierSchemeCodeUnspsc190501.60131417'),
	'identifierSchemeCodeUnspsc190501.60131418': __('identifierSchemeCodeUnspsc190501.60131418'),
	'identifierSchemeCodeUnspsc190501.60131419': __('identifierSchemeCodeUnspsc190501.60131419'),
	'identifierSchemeCodeUnspsc190501.60131420': __('identifierSchemeCodeUnspsc190501.60131420'),
	'identifierSchemeCodeUnspsc190501.60131421': __('identifierSchemeCodeUnspsc190501.60131421'),
	'identifierSchemeCodeUnspsc190501.60131422': __('identifierSchemeCodeUnspsc190501.60131422'),
	'identifierSchemeCodeUnspsc190501.60131423': __('identifierSchemeCodeUnspsc190501.60131423'),
	'identifierSchemeCodeUnspsc190501.60131424': __('identifierSchemeCodeUnspsc190501.60131424'),
	'identifierSchemeCodeUnspsc190501.60131425': __('identifierSchemeCodeUnspsc190501.60131425'),
	'identifierSchemeCodeUnspsc190501.60131426': __('identifierSchemeCodeUnspsc190501.60131426'),
	'identifierSchemeCodeUnspsc190501.60131427': __('identifierSchemeCodeUnspsc190501.60131427'),
	'identifierSchemeCodeUnspsc190501.60131428': __('identifierSchemeCodeUnspsc190501.60131428'),
	'identifierSchemeCodeUnspsc190501.60131429': __('identifierSchemeCodeUnspsc190501.60131429'),
	'identifierSchemeCodeUnspsc190501.60131430': __('identifierSchemeCodeUnspsc190501.60131430'),
	'identifierSchemeCodeUnspsc190501.60131431': __('identifierSchemeCodeUnspsc190501.60131431'),
	'identifierSchemeCodeUnspsc190501.60131432': __('identifierSchemeCodeUnspsc190501.60131432'),
	'identifierSchemeCodeUnspsc190501.60131433': __('identifierSchemeCodeUnspsc190501.60131433'),
	'identifierSchemeCodeUnspsc190501.60131434': __('identifierSchemeCodeUnspsc190501.60131434'),
	'identifierSchemeCodeUnspsc190501.60131435': __('identifierSchemeCodeUnspsc190501.60131435'),
	'identifierSchemeCodeUnspsc190501.60131436': __('identifierSchemeCodeUnspsc190501.60131436'),
	'identifierSchemeCodeUnspsc190501.60131437': __('identifierSchemeCodeUnspsc190501.60131437'),
	'identifierSchemeCodeUnspsc190501.60131438': __('identifierSchemeCodeUnspsc190501.60131438'),
	'identifierSchemeCodeUnspsc190501.60131439': __('identifierSchemeCodeUnspsc190501.60131439'),
	'identifierSchemeCodeUnspsc190501.60131440': __('identifierSchemeCodeUnspsc190501.60131440'),
	'identifierSchemeCodeUnspsc190501.60131441': __('identifierSchemeCodeUnspsc190501.60131441'),
	'identifierSchemeCodeUnspsc190501.60131442': __('identifierSchemeCodeUnspsc190501.60131442'),
	'identifierSchemeCodeUnspsc190501.60131443': __('identifierSchemeCodeUnspsc190501.60131443'),
	'identifierSchemeCodeUnspsc190501.60131444': __('identifierSchemeCodeUnspsc190501.60131444'),
	'identifierSchemeCodeUnspsc190501.60131445': __('identifierSchemeCodeUnspsc190501.60131445'),
	'identifierSchemeCodeUnspsc190501.60131446': __('identifierSchemeCodeUnspsc190501.60131446'),
	'identifierSchemeCodeUnspsc190501.60131447': __('identifierSchemeCodeUnspsc190501.60131447'),
	'identifierSchemeCodeUnspsc190501.60131448': __('identifierSchemeCodeUnspsc190501.60131448'),
	'identifierSchemeCodeUnspsc190501.60131449': __('identifierSchemeCodeUnspsc190501.60131449'),
	'identifierSchemeCodeUnspsc190501.60131450': __('identifierSchemeCodeUnspsc190501.60131450'),
	'identifierSchemeCodeUnspsc190501.60131451': __('identifierSchemeCodeUnspsc190501.60131451'),
	'identifierSchemeCodeUnspsc190501.60131452': __('identifierSchemeCodeUnspsc190501.60131452'),
	'identifierSchemeCodeUnspsc190501.60131453': __('identifierSchemeCodeUnspsc190501.60131453'),
	'identifierSchemeCodeUnspsc190501.60131454': __('identifierSchemeCodeUnspsc190501.60131454'),
	'identifierSchemeCodeUnspsc190501.60131455': __('identifierSchemeCodeUnspsc190501.60131455'),
	'identifierSchemeCodeUnspsc190501.60131456': __('identifierSchemeCodeUnspsc190501.60131456'),
	'identifierSchemeCodeUnspsc190501.60131457': __('identifierSchemeCodeUnspsc190501.60131457'),
	'identifierSchemeCodeUnspsc190501.60131458': __('identifierSchemeCodeUnspsc190501.60131458'),
	'identifierSchemeCodeUnspsc190501.60131459': __('identifierSchemeCodeUnspsc190501.60131459'),
	'identifierSchemeCodeUnspsc190501.60131460': __('identifierSchemeCodeUnspsc190501.60131460'),
	'identifierSchemeCodeUnspsc190501.60131461': __('identifierSchemeCodeUnspsc190501.60131461'),
	'identifierSchemeCodeUnspsc190501.60131462': __('identifierSchemeCodeUnspsc190501.60131462'),
	'identifierSchemeCodeUnspsc190501.60131463': __('identifierSchemeCodeUnspsc190501.60131463'),
	'identifierSchemeCodeUnspsc190501.60131464': __('identifierSchemeCodeUnspsc190501.60131464'),
	'identifierSchemeCodeUnspsc190501.60131465': __('identifierSchemeCodeUnspsc190501.60131465'),
	'identifierSchemeCodeUnspsc190501.60131466': __('identifierSchemeCodeUnspsc190501.60131466'),
	'identifierSchemeCodeUnspsc190501.60131467': __('identifierSchemeCodeUnspsc190501.60131467'),
	'identifierSchemeCodeUnspsc190501.60131500': __('identifierSchemeCodeUnspsc190501.60131500'),
	'identifierSchemeCodeUnspsc190501.60131501': __('identifierSchemeCodeUnspsc190501.60131501'),
	'identifierSchemeCodeUnspsc190501.60131502': __('identifierSchemeCodeUnspsc190501.60131502'),
	'identifierSchemeCodeUnspsc190501.60131503': __('identifierSchemeCodeUnspsc190501.60131503'),
	'identifierSchemeCodeUnspsc190501.60131504': __('identifierSchemeCodeUnspsc190501.60131504'),
	'identifierSchemeCodeUnspsc190501.60131505': __('identifierSchemeCodeUnspsc190501.60131505'),
	'identifierSchemeCodeUnspsc190501.60131506': __('identifierSchemeCodeUnspsc190501.60131506'),
	'identifierSchemeCodeUnspsc190501.60131507': __('identifierSchemeCodeUnspsc190501.60131507'),
	'identifierSchemeCodeUnspsc190501.60131508': __('identifierSchemeCodeUnspsc190501.60131508'),
	'identifierSchemeCodeUnspsc190501.60131509': __('identifierSchemeCodeUnspsc190501.60131509'),
	'identifierSchemeCodeUnspsc190501.60131510': __('identifierSchemeCodeUnspsc190501.60131510'),
	'identifierSchemeCodeUnspsc190501.60131511': __('identifierSchemeCodeUnspsc190501.60131511'),
	'identifierSchemeCodeUnspsc190501.60131512': __('identifierSchemeCodeUnspsc190501.60131512'),
	'identifierSchemeCodeUnspsc190501.60131513': __('identifierSchemeCodeUnspsc190501.60131513'),
	'identifierSchemeCodeUnspsc190501.60131514': __('identifierSchemeCodeUnspsc190501.60131514'),
	'identifierSchemeCodeUnspsc190501.60131515': __('identifierSchemeCodeUnspsc190501.60131515'),
	'identifierSchemeCodeUnspsc190501.60131516': __('identifierSchemeCodeUnspsc190501.60131516'),
	'identifierSchemeCodeUnspsc190501.60131517': __('identifierSchemeCodeUnspsc190501.60131517'),
	'identifierSchemeCodeUnspsc190501.60131518': __('identifierSchemeCodeUnspsc190501.60131518'),
	'identifierSchemeCodeUnspsc190501.60131519': __('identifierSchemeCodeUnspsc190501.60131519'),
	'identifierSchemeCodeUnspsc190501.60131520': __('identifierSchemeCodeUnspsc190501.60131520'),
	'identifierSchemeCodeUnspsc190501.60131600': __('identifierSchemeCodeUnspsc190501.60131600'),
	'identifierSchemeCodeUnspsc190501.60131601': __('identifierSchemeCodeUnspsc190501.60131601'),
	'identifierSchemeCodeUnspsc190501.60131700': __('identifierSchemeCodeUnspsc190501.60131700'),
	'identifierSchemeCodeUnspsc190501.60131701': __('identifierSchemeCodeUnspsc190501.60131701'),
	'identifierSchemeCodeUnspsc190501.60131702': __('identifierSchemeCodeUnspsc190501.60131702'),
	'identifierSchemeCodeUnspsc190501.60131703': __('identifierSchemeCodeUnspsc190501.60131703'),
	'identifierSchemeCodeUnspsc190501.60131800': __('identifierSchemeCodeUnspsc190501.60131800'),
	'identifierSchemeCodeUnspsc190501.60131801': __('identifierSchemeCodeUnspsc190501.60131801'),
	'identifierSchemeCodeUnspsc190501.60131802': __('identifierSchemeCodeUnspsc190501.60131802'),
	'identifierSchemeCodeUnspsc190501.60131803': __('identifierSchemeCodeUnspsc190501.60131803'),
	'identifierSchemeCodeUnspsc190501.60140000': __('identifierSchemeCodeUnspsc190501.60140000'),
	'identifierSchemeCodeUnspsc190501.60141000': __('identifierSchemeCodeUnspsc190501.60141000'),
	'identifierSchemeCodeUnspsc190501.60141001': __('identifierSchemeCodeUnspsc190501.60141001'),
	'identifierSchemeCodeUnspsc190501.60141002': __('identifierSchemeCodeUnspsc190501.60141002'),
	'identifierSchemeCodeUnspsc190501.60141003': __('identifierSchemeCodeUnspsc190501.60141003'),
	'identifierSchemeCodeUnspsc190501.60141004': __('identifierSchemeCodeUnspsc190501.60141004'),
	'identifierSchemeCodeUnspsc190501.60141005': __('identifierSchemeCodeUnspsc190501.60141005'),
	'identifierSchemeCodeUnspsc190501.60141006': __('identifierSchemeCodeUnspsc190501.60141006'),
	'identifierSchemeCodeUnspsc190501.60141007': __('identifierSchemeCodeUnspsc190501.60141007'),
	'identifierSchemeCodeUnspsc190501.60141008': __('identifierSchemeCodeUnspsc190501.60141008'),
	'identifierSchemeCodeUnspsc190501.60141009': __('identifierSchemeCodeUnspsc190501.60141009'),
	'identifierSchemeCodeUnspsc190501.60141010': __('identifierSchemeCodeUnspsc190501.60141010'),
	'identifierSchemeCodeUnspsc190501.60141011': __('identifierSchemeCodeUnspsc190501.60141011'),
	'identifierSchemeCodeUnspsc190501.60141012': __('identifierSchemeCodeUnspsc190501.60141012'),
	'identifierSchemeCodeUnspsc190501.60141013': __('identifierSchemeCodeUnspsc190501.60141013'),
	'identifierSchemeCodeUnspsc190501.60141014': __('identifierSchemeCodeUnspsc190501.60141014'),
	'identifierSchemeCodeUnspsc190501.60141015': __('identifierSchemeCodeUnspsc190501.60141015'),
	'identifierSchemeCodeUnspsc190501.60141016': __('identifierSchemeCodeUnspsc190501.60141016'),
	'identifierSchemeCodeUnspsc190501.60141017': __('identifierSchemeCodeUnspsc190501.60141017'),
	'identifierSchemeCodeUnspsc190501.60141018': __('identifierSchemeCodeUnspsc190501.60141018'),
	'identifierSchemeCodeUnspsc190501.60141019': __('identifierSchemeCodeUnspsc190501.60141019'),
	'identifierSchemeCodeUnspsc190501.60141020': __('identifierSchemeCodeUnspsc190501.60141020'),
	'identifierSchemeCodeUnspsc190501.60141021': __('identifierSchemeCodeUnspsc190501.60141021'),
	'identifierSchemeCodeUnspsc190501.60141022': __('identifierSchemeCodeUnspsc190501.60141022'),
	'identifierSchemeCodeUnspsc190501.60141023': __('identifierSchemeCodeUnspsc190501.60141023'),
	'identifierSchemeCodeUnspsc190501.60141024': __('identifierSchemeCodeUnspsc190501.60141024'),
	'identifierSchemeCodeUnspsc190501.60141025': __('identifierSchemeCodeUnspsc190501.60141025'),
	'identifierSchemeCodeUnspsc190501.60141026': __('identifierSchemeCodeUnspsc190501.60141026'),
	'identifierSchemeCodeUnspsc190501.60141100': __('identifierSchemeCodeUnspsc190501.60141100'),
	'identifierSchemeCodeUnspsc190501.60141101': __('identifierSchemeCodeUnspsc190501.60141101'),
	'identifierSchemeCodeUnspsc190501.60141102': __('identifierSchemeCodeUnspsc190501.60141102'),
	'identifierSchemeCodeUnspsc190501.60141103': __('identifierSchemeCodeUnspsc190501.60141103'),
	'identifierSchemeCodeUnspsc190501.60141104': __('identifierSchemeCodeUnspsc190501.60141104'),
	'identifierSchemeCodeUnspsc190501.60141105': __('identifierSchemeCodeUnspsc190501.60141105'),
	'identifierSchemeCodeUnspsc190501.60141106': __('identifierSchemeCodeUnspsc190501.60141106'),
	'identifierSchemeCodeUnspsc190501.60141107': __('identifierSchemeCodeUnspsc190501.60141107'),
	'identifierSchemeCodeUnspsc190501.60141108': __('identifierSchemeCodeUnspsc190501.60141108'),
	'identifierSchemeCodeUnspsc190501.60141109': __('identifierSchemeCodeUnspsc190501.60141109'),
	'identifierSchemeCodeUnspsc190501.60141110': __('identifierSchemeCodeUnspsc190501.60141110'),
	'identifierSchemeCodeUnspsc190501.60141111': __('identifierSchemeCodeUnspsc190501.60141111'),
	'identifierSchemeCodeUnspsc190501.60141112': __('identifierSchemeCodeUnspsc190501.60141112'),
	'identifierSchemeCodeUnspsc190501.60141113': __('identifierSchemeCodeUnspsc190501.60141113'),
	'identifierSchemeCodeUnspsc190501.60141114': __('identifierSchemeCodeUnspsc190501.60141114'),
	'identifierSchemeCodeUnspsc190501.60141115': __('identifierSchemeCodeUnspsc190501.60141115'),
	'identifierSchemeCodeUnspsc190501.60141116': __('identifierSchemeCodeUnspsc190501.60141116'),
	'identifierSchemeCodeUnspsc190501.60141117': __('identifierSchemeCodeUnspsc190501.60141117'),
	'identifierSchemeCodeUnspsc190501.60141118': __('identifierSchemeCodeUnspsc190501.60141118'),
	'identifierSchemeCodeUnspsc190501.60141200': __('identifierSchemeCodeUnspsc190501.60141200'),
	'identifierSchemeCodeUnspsc190501.60141201': __('identifierSchemeCodeUnspsc190501.60141201'),
	'identifierSchemeCodeUnspsc190501.60141202': __('identifierSchemeCodeUnspsc190501.60141202'),
	'identifierSchemeCodeUnspsc190501.60141203': __('identifierSchemeCodeUnspsc190501.60141203'),
	'identifierSchemeCodeUnspsc190501.60141204': __('identifierSchemeCodeUnspsc190501.60141204'),
	'identifierSchemeCodeUnspsc190501.60141205': __('identifierSchemeCodeUnspsc190501.60141205'),
	'identifierSchemeCodeUnspsc190501.60141300': __('identifierSchemeCodeUnspsc190501.60141300'),
	'identifierSchemeCodeUnspsc190501.60141302': __('identifierSchemeCodeUnspsc190501.60141302'),
	'identifierSchemeCodeUnspsc190501.60141303': __('identifierSchemeCodeUnspsc190501.60141303'),
	'identifierSchemeCodeUnspsc190501.60141304': __('identifierSchemeCodeUnspsc190501.60141304'),
	'identifierSchemeCodeUnspsc190501.60141305': __('identifierSchemeCodeUnspsc190501.60141305'),
	'identifierSchemeCodeUnspsc190501.60141306': __('identifierSchemeCodeUnspsc190501.60141306'),
	'identifierSchemeCodeUnspsc190501.60141307': __('identifierSchemeCodeUnspsc190501.60141307'),
	'identifierSchemeCodeUnspsc190501.60141400': __('identifierSchemeCodeUnspsc190501.60141400'),
	'identifierSchemeCodeUnspsc190501.60141401': __('identifierSchemeCodeUnspsc190501.60141401'),
	'identifierSchemeCodeUnspsc190501.60141402': __('identifierSchemeCodeUnspsc190501.60141402'),
	'identifierSchemeCodeUnspsc190501.60141403': __('identifierSchemeCodeUnspsc190501.60141403'),
	'identifierSchemeCodeUnspsc190501.60141404': __('identifierSchemeCodeUnspsc190501.60141404'),
	'identifierSchemeCodeUnspsc190501.60141405': __('identifierSchemeCodeUnspsc190501.60141405'),
	'identifierSchemeCodeUnspsc190501.64000000': __('identifierSchemeCodeUnspsc190501.64000000'),
	'identifierSchemeCodeUnspsc190501.64100000': __('identifierSchemeCodeUnspsc190501.64100000'),
	'identifierSchemeCodeUnspsc190501.64101500': __('identifierSchemeCodeUnspsc190501.64101500'),
	'identifierSchemeCodeUnspsc190501.64101501': __('identifierSchemeCodeUnspsc190501.64101501'),
	'identifierSchemeCodeUnspsc190501.64101502': __('identifierSchemeCodeUnspsc190501.64101502'),
	'identifierSchemeCodeUnspsc190501.64101600': __('identifierSchemeCodeUnspsc190501.64101600'),
	'identifierSchemeCodeUnspsc190501.64101601': __('identifierSchemeCodeUnspsc190501.64101601'),
	'identifierSchemeCodeUnspsc190501.64101602': __('identifierSchemeCodeUnspsc190501.64101602'),
	'identifierSchemeCodeUnspsc190501.64101603': __('identifierSchemeCodeUnspsc190501.64101603'),
	'identifierSchemeCodeUnspsc190501.64101700': __('identifierSchemeCodeUnspsc190501.64101700'),
	'identifierSchemeCodeUnspsc190501.64101701': __('identifierSchemeCodeUnspsc190501.64101701'),
	'identifierSchemeCodeUnspsc190501.64101702': __('identifierSchemeCodeUnspsc190501.64101702'),
	'identifierSchemeCodeUnspsc190501.64101703': __('identifierSchemeCodeUnspsc190501.64101703'),
	'identifierSchemeCodeUnspsc190501.64101704': __('identifierSchemeCodeUnspsc190501.64101704'),
	'identifierSchemeCodeUnspsc190501.64101800': __('identifierSchemeCodeUnspsc190501.64101800'),
	'identifierSchemeCodeUnspsc190501.64101801': __('identifierSchemeCodeUnspsc190501.64101801'),
	'identifierSchemeCodeUnspsc190501.64101802': __('identifierSchemeCodeUnspsc190501.64101802'),
	'identifierSchemeCodeUnspsc190501.64101900': __('identifierSchemeCodeUnspsc190501.64101900'),
	'identifierSchemeCodeUnspsc190501.64101901': __('identifierSchemeCodeUnspsc190501.64101901'),
	'identifierSchemeCodeUnspsc190501.64101902': __('identifierSchemeCodeUnspsc190501.64101902'),
	'identifierSchemeCodeUnspsc190501.64101903': __('identifierSchemeCodeUnspsc190501.64101903'),
	'identifierSchemeCodeUnspsc190501.64101904': __('identifierSchemeCodeUnspsc190501.64101904'),
	'identifierSchemeCodeUnspsc190501.64101905': __('identifierSchemeCodeUnspsc190501.64101905'),
	'identifierSchemeCodeUnspsc190501.64101906': __('identifierSchemeCodeUnspsc190501.64101906'),
	'identifierSchemeCodeUnspsc190501.64101907': __('identifierSchemeCodeUnspsc190501.64101907'),
	'identifierSchemeCodeUnspsc190501.64101908': __('identifierSchemeCodeUnspsc190501.64101908'),
	'identifierSchemeCodeUnspsc190501.64101909': __('identifierSchemeCodeUnspsc190501.64101909'),
	'identifierSchemeCodeUnspsc190501.64101910': __('identifierSchemeCodeUnspsc190501.64101910'),
	'identifierSchemeCodeUnspsc190501.64101911': __('identifierSchemeCodeUnspsc190501.64101911'),
	'identifierSchemeCodeUnspsc190501.64101912': __('identifierSchemeCodeUnspsc190501.64101912'),
	'identifierSchemeCodeUnspsc190501.64102000': __('identifierSchemeCodeUnspsc190501.64102000'),
	'identifierSchemeCodeUnspsc190501.64102001': __('identifierSchemeCodeUnspsc190501.64102001'),
	'identifierSchemeCodeUnspsc190501.64102002': __('identifierSchemeCodeUnspsc190501.64102002'),
	'identifierSchemeCodeUnspsc190501.64102003': __('identifierSchemeCodeUnspsc190501.64102003'),
	'identifierSchemeCodeUnspsc190501.64102004': __('identifierSchemeCodeUnspsc190501.64102004'),
	'identifierSchemeCodeUnspsc190501.64102005': __('identifierSchemeCodeUnspsc190501.64102005'),
	'identifierSchemeCodeUnspsc190501.64102006': __('identifierSchemeCodeUnspsc190501.64102006'),
	'identifierSchemeCodeUnspsc190501.64102007': __('identifierSchemeCodeUnspsc190501.64102007'),
	'identifierSchemeCodeUnspsc190501.64102008': __('identifierSchemeCodeUnspsc190501.64102008'),
	'identifierSchemeCodeUnspsc190501.64110000': __('identifierSchemeCodeUnspsc190501.64110000'),
	'identifierSchemeCodeUnspsc190501.64111500': __('identifierSchemeCodeUnspsc190501.64111500'),
	'identifierSchemeCodeUnspsc190501.64111501': __('identifierSchemeCodeUnspsc190501.64111501'),
	'identifierSchemeCodeUnspsc190501.64111502': __('identifierSchemeCodeUnspsc190501.64111502'),
	'identifierSchemeCodeUnspsc190501.64111503': __('identifierSchemeCodeUnspsc190501.64111503'),
	'identifierSchemeCodeUnspsc190501.64111504': __('identifierSchemeCodeUnspsc190501.64111504'),
	'identifierSchemeCodeUnspsc190501.64111505': __('identifierSchemeCodeUnspsc190501.64111505'),
	'identifierSchemeCodeUnspsc190501.64111506': __('identifierSchemeCodeUnspsc190501.64111506'),
	'identifierSchemeCodeUnspsc190501.64111507': __('identifierSchemeCodeUnspsc190501.64111507'),
	'identifierSchemeCodeUnspsc190501.64111600': __('identifierSchemeCodeUnspsc190501.64111600'),
	'identifierSchemeCodeUnspsc190501.64111601': __('identifierSchemeCodeUnspsc190501.64111601'),
	'identifierSchemeCodeUnspsc190501.64111602': __('identifierSchemeCodeUnspsc190501.64111602'),
	'identifierSchemeCodeUnspsc190501.64111603': __('identifierSchemeCodeUnspsc190501.64111603'),
	'identifierSchemeCodeUnspsc190501.64111604': __('identifierSchemeCodeUnspsc190501.64111604'),
	'identifierSchemeCodeUnspsc190501.64111605': __('identifierSchemeCodeUnspsc190501.64111605'),
	'identifierSchemeCodeUnspsc190501.64111606': __('identifierSchemeCodeUnspsc190501.64111606'),
	'identifierSchemeCodeUnspsc190501.64111607': __('identifierSchemeCodeUnspsc190501.64111607'),
	'identifierSchemeCodeUnspsc190501.64111608': __('identifierSchemeCodeUnspsc190501.64111608'),
	'identifierSchemeCodeUnspsc190501.64111700': __('identifierSchemeCodeUnspsc190501.64111700'),
	'identifierSchemeCodeUnspsc190501.64111701': __('identifierSchemeCodeUnspsc190501.64111701'),
	'identifierSchemeCodeUnspsc190501.64111702': __('identifierSchemeCodeUnspsc190501.64111702'),
	'identifierSchemeCodeUnspsc190501.64111703': __('identifierSchemeCodeUnspsc190501.64111703'),
	'identifierSchemeCodeUnspsc190501.64111704': __('identifierSchemeCodeUnspsc190501.64111704'),
	'identifierSchemeCodeUnspsc190501.64111705': __('identifierSchemeCodeUnspsc190501.64111705'),
	'identifierSchemeCodeUnspsc190501.64111706': __('identifierSchemeCodeUnspsc190501.64111706'),
	'identifierSchemeCodeUnspsc190501.64111707': __('identifierSchemeCodeUnspsc190501.64111707'),
	'identifierSchemeCodeUnspsc190501.64111708': __('identifierSchemeCodeUnspsc190501.64111708'),
	'identifierSchemeCodeUnspsc190501.64111709': __('identifierSchemeCodeUnspsc190501.64111709'),
	'identifierSchemeCodeUnspsc190501.64111710': __('identifierSchemeCodeUnspsc190501.64111710'),
	'identifierSchemeCodeUnspsc190501.64111711': __('identifierSchemeCodeUnspsc190501.64111711'),
	'identifierSchemeCodeUnspsc190501.64111712': __('identifierSchemeCodeUnspsc190501.64111712'),
	'identifierSchemeCodeUnspsc190501.64111800': __('identifierSchemeCodeUnspsc190501.64111800'),
	'identifierSchemeCodeUnspsc190501.64111801': __('identifierSchemeCodeUnspsc190501.64111801'),
	'identifierSchemeCodeUnspsc190501.64111802': __('identifierSchemeCodeUnspsc190501.64111802'),
	'identifierSchemeCodeUnspsc190501.64111803': __('identifierSchemeCodeUnspsc190501.64111803'),
	'identifierSchemeCodeUnspsc190501.64111804': __('identifierSchemeCodeUnspsc190501.64111804'),
	'identifierSchemeCodeUnspsc190501.64111900': __('identifierSchemeCodeUnspsc190501.64111900'),
	'identifierSchemeCodeUnspsc190501.64111901': __('identifierSchemeCodeUnspsc190501.64111901'),
	'identifierSchemeCodeUnspsc190501.64111902': __('identifierSchemeCodeUnspsc190501.64111902'),
	'identifierSchemeCodeUnspsc190501.64111903': __('identifierSchemeCodeUnspsc190501.64111903'),
	'identifierSchemeCodeUnspsc190501.64111904': __('identifierSchemeCodeUnspsc190501.64111904'),
	'identifierSchemeCodeUnspsc190501.64111905': __('identifierSchemeCodeUnspsc190501.64111905'),
	'identifierSchemeCodeUnspsc190501.64111906': __('identifierSchemeCodeUnspsc190501.64111906'),
	'identifierSchemeCodeUnspsc190501.64120000': __('identifierSchemeCodeUnspsc190501.64120000'),
	'identifierSchemeCodeUnspsc190501.64121500': __('identifierSchemeCodeUnspsc190501.64121500'),
	'identifierSchemeCodeUnspsc190501.64121501': __('identifierSchemeCodeUnspsc190501.64121501'),
	'identifierSchemeCodeUnspsc190501.64121502': __('identifierSchemeCodeUnspsc190501.64121502'),
	'identifierSchemeCodeUnspsc190501.64121503': __('identifierSchemeCodeUnspsc190501.64121503'),
	'identifierSchemeCodeUnspsc190501.64121504': __('identifierSchemeCodeUnspsc190501.64121504'),
	'identifierSchemeCodeUnspsc190501.64121505': __('identifierSchemeCodeUnspsc190501.64121505'),
	'identifierSchemeCodeUnspsc190501.64121506': __('identifierSchemeCodeUnspsc190501.64121506'),
	'identifierSchemeCodeUnspsc190501.64121507': __('identifierSchemeCodeUnspsc190501.64121507'),
	'identifierSchemeCodeUnspsc190501.64121508': __('identifierSchemeCodeUnspsc190501.64121508'),
	'identifierSchemeCodeUnspsc190501.64121509': __('identifierSchemeCodeUnspsc190501.64121509'),
	'identifierSchemeCodeUnspsc190501.64121510': __('identifierSchemeCodeUnspsc190501.64121510'),
	'identifierSchemeCodeUnspsc190501.64121511': __('identifierSchemeCodeUnspsc190501.64121511'),
	'identifierSchemeCodeUnspsc190501.64121512': __('identifierSchemeCodeUnspsc190501.64121512'),
	'identifierSchemeCodeUnspsc190501.64121513': __('identifierSchemeCodeUnspsc190501.64121513'),
	'identifierSchemeCodeUnspsc190501.64121514': __('identifierSchemeCodeUnspsc190501.64121514'),
	'identifierSchemeCodeUnspsc190501.64122000': __('identifierSchemeCodeUnspsc190501.64122000'),
	'identifierSchemeCodeUnspsc190501.64122001': __('identifierSchemeCodeUnspsc190501.64122001'),
	'identifierSchemeCodeUnspsc190501.64122002': __('identifierSchemeCodeUnspsc190501.64122002'),
	'identifierSchemeCodeUnspsc190501.64122003': __('identifierSchemeCodeUnspsc190501.64122003'),
	'identifierSchemeCodeUnspsc190501.64122004': __('identifierSchemeCodeUnspsc190501.64122004'),
	'identifierSchemeCodeUnspsc190501.64122005': __('identifierSchemeCodeUnspsc190501.64122005'),
	'identifierSchemeCodeUnspsc190501.64122006': __('identifierSchemeCodeUnspsc190501.64122006'),
	'identifierSchemeCodeUnspsc190501.64122007': __('identifierSchemeCodeUnspsc190501.64122007'),
	'identifierSchemeCodeUnspsc190501.64122100': __('identifierSchemeCodeUnspsc190501.64122100'),
	'identifierSchemeCodeUnspsc190501.64122101': __('identifierSchemeCodeUnspsc190501.64122101'),
	'identifierSchemeCodeUnspsc190501.64122102': __('identifierSchemeCodeUnspsc190501.64122102'),
	'identifierSchemeCodeUnspsc190501.64122200': __('identifierSchemeCodeUnspsc190501.64122200'),
	'identifierSchemeCodeUnspsc190501.64122201': __('identifierSchemeCodeUnspsc190501.64122201'),
	'identifierSchemeCodeUnspsc190501.64122202': __('identifierSchemeCodeUnspsc190501.64122202'),
	'identifierSchemeCodeUnspsc190501.64130000': __('identifierSchemeCodeUnspsc190501.64130000'),
	'identifierSchemeCodeUnspsc190501.64131500': __('identifierSchemeCodeUnspsc190501.64131500'),
	'identifierSchemeCodeUnspsc190501.64131501': __('identifierSchemeCodeUnspsc190501.64131501'),
	'identifierSchemeCodeUnspsc190501.64131502': __('identifierSchemeCodeUnspsc190501.64131502'),
	'identifierSchemeCodeUnspsc190501.64131600': __('identifierSchemeCodeUnspsc190501.64131600'),
	'identifierSchemeCodeUnspsc190501.64131601': __('identifierSchemeCodeUnspsc190501.64131601'),
	'identifierSchemeCodeUnspsc190501.64131602': __('identifierSchemeCodeUnspsc190501.64131602'),
	'identifierSchemeCodeUnspsc190501.64131603': __('identifierSchemeCodeUnspsc190501.64131603'),
	'identifierSchemeCodeUnspsc190501.64131604': __('identifierSchemeCodeUnspsc190501.64131604'),
	'identifierSchemeCodeUnspsc190501.64131605': __('identifierSchemeCodeUnspsc190501.64131605'),
	'identifierSchemeCodeUnspsc190501.64131606': __('identifierSchemeCodeUnspsc190501.64131606'),
	'identifierSchemeCodeUnspsc190501.64131607': __('identifierSchemeCodeUnspsc190501.64131607'),
	'identifierSchemeCodeUnspsc190501.64140000': __('identifierSchemeCodeUnspsc190501.64140000'),
	'identifierSchemeCodeUnspsc190501.64141500': __('identifierSchemeCodeUnspsc190501.64141500'),
	'identifierSchemeCodeUnspsc190501.64141501': __('identifierSchemeCodeUnspsc190501.64141501'),
	'identifierSchemeCodeUnspsc190501.64141502': __('identifierSchemeCodeUnspsc190501.64141502'),
	'identifierSchemeCodeUnspsc190501.64141503': __('identifierSchemeCodeUnspsc190501.64141503'),
	'identifierSchemeCodeUnspsc190501.64141504': __('identifierSchemeCodeUnspsc190501.64141504'),
	'identifierSchemeCodeUnspsc190501.64141600': __('identifierSchemeCodeUnspsc190501.64141600'),
	'identifierSchemeCodeUnspsc190501.64141601': __('identifierSchemeCodeUnspsc190501.64141601'),
	'identifierSchemeCodeUnspsc190501.64141602': __('identifierSchemeCodeUnspsc190501.64141602'),
	'identifierSchemeCodeUnspsc190501.64141603': __('identifierSchemeCodeUnspsc190501.64141603'),
	'identifierSchemeCodeUnspsc190501.64141604': __('identifierSchemeCodeUnspsc190501.64141604'),
	'identifierSchemeCodeUnspsc190501.64141700': __('identifierSchemeCodeUnspsc190501.64141700'),
	'identifierSchemeCodeUnspsc190501.64141701': __('identifierSchemeCodeUnspsc190501.64141701'),
	'identifierSchemeCodeUnspsc190501.70000000': __('identifierSchemeCodeUnspsc190501.70000000'),
	'identifierSchemeCodeUnspsc190501.70100000': __('identifierSchemeCodeUnspsc190501.70100000'),
	'identifierSchemeCodeUnspsc190501.70101500': __('identifierSchemeCodeUnspsc190501.70101500'),
	'identifierSchemeCodeUnspsc190501.70101501': __('identifierSchemeCodeUnspsc190501.70101501'),
	'identifierSchemeCodeUnspsc190501.70101502': __('identifierSchemeCodeUnspsc190501.70101502'),
	'identifierSchemeCodeUnspsc190501.70101503': __('identifierSchemeCodeUnspsc190501.70101503'),
	'identifierSchemeCodeUnspsc190501.70101504': __('identifierSchemeCodeUnspsc190501.70101504'),
	'identifierSchemeCodeUnspsc190501.70101505': __('identifierSchemeCodeUnspsc190501.70101505'),
	'identifierSchemeCodeUnspsc190501.70101506': __('identifierSchemeCodeUnspsc190501.70101506'),
	'identifierSchemeCodeUnspsc190501.70101507': __('identifierSchemeCodeUnspsc190501.70101507'),
	'identifierSchemeCodeUnspsc190501.70101508': __('identifierSchemeCodeUnspsc190501.70101508'),
	'identifierSchemeCodeUnspsc190501.70101509': __('identifierSchemeCodeUnspsc190501.70101509'),
	'identifierSchemeCodeUnspsc190501.70101510': __('identifierSchemeCodeUnspsc190501.70101510'),
	'identifierSchemeCodeUnspsc190501.70101600': __('identifierSchemeCodeUnspsc190501.70101600'),
	'identifierSchemeCodeUnspsc190501.70101601': __('identifierSchemeCodeUnspsc190501.70101601'),
	'identifierSchemeCodeUnspsc190501.70101602': __('identifierSchemeCodeUnspsc190501.70101602'),
	'identifierSchemeCodeUnspsc190501.70101603': __('identifierSchemeCodeUnspsc190501.70101603'),
	'identifierSchemeCodeUnspsc190501.70101604': __('identifierSchemeCodeUnspsc190501.70101604'),
	'identifierSchemeCodeUnspsc190501.70101605': __('identifierSchemeCodeUnspsc190501.70101605'),
	'identifierSchemeCodeUnspsc190501.70101606': __('identifierSchemeCodeUnspsc190501.70101606'),
	'identifierSchemeCodeUnspsc190501.70101607': __('identifierSchemeCodeUnspsc190501.70101607'),
	'identifierSchemeCodeUnspsc190501.70101700': __('identifierSchemeCodeUnspsc190501.70101700'),
	'identifierSchemeCodeUnspsc190501.70101701': __('identifierSchemeCodeUnspsc190501.70101701'),
	'identifierSchemeCodeUnspsc190501.70101702': __('identifierSchemeCodeUnspsc190501.70101702'),
	'identifierSchemeCodeUnspsc190501.70101703': __('identifierSchemeCodeUnspsc190501.70101703'),
	'identifierSchemeCodeUnspsc190501.70101704': __('identifierSchemeCodeUnspsc190501.70101704'),
	'identifierSchemeCodeUnspsc190501.70101800': __('identifierSchemeCodeUnspsc190501.70101800'),
	'identifierSchemeCodeUnspsc190501.70101801': __('identifierSchemeCodeUnspsc190501.70101801'),
	'identifierSchemeCodeUnspsc190501.70101802': __('identifierSchemeCodeUnspsc190501.70101802'),
	'identifierSchemeCodeUnspsc190501.70101803': __('identifierSchemeCodeUnspsc190501.70101803'),
	'identifierSchemeCodeUnspsc190501.70101804': __('identifierSchemeCodeUnspsc190501.70101804'),
	'identifierSchemeCodeUnspsc190501.70101805': __('identifierSchemeCodeUnspsc190501.70101805'),
	'identifierSchemeCodeUnspsc190501.70101806': __('identifierSchemeCodeUnspsc190501.70101806'),
	'identifierSchemeCodeUnspsc190501.70101900': __('identifierSchemeCodeUnspsc190501.70101900'),
	'identifierSchemeCodeUnspsc190501.70101901': __('identifierSchemeCodeUnspsc190501.70101901'),
	'identifierSchemeCodeUnspsc190501.70101902': __('identifierSchemeCodeUnspsc190501.70101902'),
	'identifierSchemeCodeUnspsc190501.70101903': __('identifierSchemeCodeUnspsc190501.70101903'),
	'identifierSchemeCodeUnspsc190501.70101904': __('identifierSchemeCodeUnspsc190501.70101904'),
	'identifierSchemeCodeUnspsc190501.70101905': __('identifierSchemeCodeUnspsc190501.70101905'),
	'identifierSchemeCodeUnspsc190501.70110000': __('identifierSchemeCodeUnspsc190501.70110000'),
	'identifierSchemeCodeUnspsc190501.70111500': __('identifierSchemeCodeUnspsc190501.70111500'),
	'identifierSchemeCodeUnspsc190501.70111501': __('identifierSchemeCodeUnspsc190501.70111501'),
	'identifierSchemeCodeUnspsc190501.70111502': __('identifierSchemeCodeUnspsc190501.70111502'),
	'identifierSchemeCodeUnspsc190501.70111503': __('identifierSchemeCodeUnspsc190501.70111503'),
	'identifierSchemeCodeUnspsc190501.70111504': __('identifierSchemeCodeUnspsc190501.70111504'),
	'identifierSchemeCodeUnspsc190501.70111505': __('identifierSchemeCodeUnspsc190501.70111505'),
	'identifierSchemeCodeUnspsc190501.70111506': __('identifierSchemeCodeUnspsc190501.70111506'),
	'identifierSchemeCodeUnspsc190501.70111507': __('identifierSchemeCodeUnspsc190501.70111507'),
	'identifierSchemeCodeUnspsc190501.70111508': __('identifierSchemeCodeUnspsc190501.70111508'),
	'identifierSchemeCodeUnspsc190501.70111600': __('identifierSchemeCodeUnspsc190501.70111600'),
	'identifierSchemeCodeUnspsc190501.70111601': __('identifierSchemeCodeUnspsc190501.70111601'),
	'identifierSchemeCodeUnspsc190501.70111602': __('identifierSchemeCodeUnspsc190501.70111602'),
	'identifierSchemeCodeUnspsc190501.70111603': __('identifierSchemeCodeUnspsc190501.70111603'),
	'identifierSchemeCodeUnspsc190501.70111700': __('identifierSchemeCodeUnspsc190501.70111700'),
	'identifierSchemeCodeUnspsc190501.70111701': __('identifierSchemeCodeUnspsc190501.70111701'),
	'identifierSchemeCodeUnspsc190501.70111702': __('identifierSchemeCodeUnspsc190501.70111702'),
	'identifierSchemeCodeUnspsc190501.70111703': __('identifierSchemeCodeUnspsc190501.70111703'),
	'identifierSchemeCodeUnspsc190501.70111704': __('identifierSchemeCodeUnspsc190501.70111704'),
	'identifierSchemeCodeUnspsc190501.70111705': __('identifierSchemeCodeUnspsc190501.70111705'),
	'identifierSchemeCodeUnspsc190501.70111706': __('identifierSchemeCodeUnspsc190501.70111706'),
	'identifierSchemeCodeUnspsc190501.70111707': __('identifierSchemeCodeUnspsc190501.70111707'),
	'identifierSchemeCodeUnspsc190501.70111708': __('identifierSchemeCodeUnspsc190501.70111708'),
	'identifierSchemeCodeUnspsc190501.70111709': __('identifierSchemeCodeUnspsc190501.70111709'),
	'identifierSchemeCodeUnspsc190501.70111710': __('identifierSchemeCodeUnspsc190501.70111710'),
	'identifierSchemeCodeUnspsc190501.70111711': __('identifierSchemeCodeUnspsc190501.70111711'),
	'identifierSchemeCodeUnspsc190501.70111712': __('identifierSchemeCodeUnspsc190501.70111712'),
	'identifierSchemeCodeUnspsc190501.70111713': __('identifierSchemeCodeUnspsc190501.70111713'),
	'identifierSchemeCodeUnspsc190501.70120000': __('identifierSchemeCodeUnspsc190501.70120000'),
	'identifierSchemeCodeUnspsc190501.70121500': __('identifierSchemeCodeUnspsc190501.70121500'),
	'identifierSchemeCodeUnspsc190501.70121501': __('identifierSchemeCodeUnspsc190501.70121501'),
	'identifierSchemeCodeUnspsc190501.70121502': __('identifierSchemeCodeUnspsc190501.70121502'),
	'identifierSchemeCodeUnspsc190501.70121503': __('identifierSchemeCodeUnspsc190501.70121503'),
	'identifierSchemeCodeUnspsc190501.70121504': __('identifierSchemeCodeUnspsc190501.70121504'),
	'identifierSchemeCodeUnspsc190501.70121505': __('identifierSchemeCodeUnspsc190501.70121505'),
	'identifierSchemeCodeUnspsc190501.70121600': __('identifierSchemeCodeUnspsc190501.70121600'),
	'identifierSchemeCodeUnspsc190501.70121601': __('identifierSchemeCodeUnspsc190501.70121601'),
	'identifierSchemeCodeUnspsc190501.70121602': __('identifierSchemeCodeUnspsc190501.70121602'),
	'identifierSchemeCodeUnspsc190501.70121603': __('identifierSchemeCodeUnspsc190501.70121603'),
	'identifierSchemeCodeUnspsc190501.70121604': __('identifierSchemeCodeUnspsc190501.70121604'),
	'identifierSchemeCodeUnspsc190501.70121605': __('identifierSchemeCodeUnspsc190501.70121605'),
	'identifierSchemeCodeUnspsc190501.70121606': __('identifierSchemeCodeUnspsc190501.70121606'),
	'identifierSchemeCodeUnspsc190501.70121607': __('identifierSchemeCodeUnspsc190501.70121607'),
	'identifierSchemeCodeUnspsc190501.70121608': __('identifierSchemeCodeUnspsc190501.70121608'),
	'identifierSchemeCodeUnspsc190501.70121610': __('identifierSchemeCodeUnspsc190501.70121610'),
	'identifierSchemeCodeUnspsc190501.70121700': __('identifierSchemeCodeUnspsc190501.70121700'),
	'identifierSchemeCodeUnspsc190501.70121701': __('identifierSchemeCodeUnspsc190501.70121701'),
	'identifierSchemeCodeUnspsc190501.70121702': __('identifierSchemeCodeUnspsc190501.70121702'),
	'identifierSchemeCodeUnspsc190501.70121703': __('identifierSchemeCodeUnspsc190501.70121703'),
	'identifierSchemeCodeUnspsc190501.70121704': __('identifierSchemeCodeUnspsc190501.70121704'),
	'identifierSchemeCodeUnspsc190501.70121705': __('identifierSchemeCodeUnspsc190501.70121705'),
	'identifierSchemeCodeUnspsc190501.70121706': __('identifierSchemeCodeUnspsc190501.70121706'),
	'identifierSchemeCodeUnspsc190501.70121800': __('identifierSchemeCodeUnspsc190501.70121800'),
	'identifierSchemeCodeUnspsc190501.70121801': __('identifierSchemeCodeUnspsc190501.70121801'),
	'identifierSchemeCodeUnspsc190501.70121802': __('identifierSchemeCodeUnspsc190501.70121802'),
	'identifierSchemeCodeUnspsc190501.70121803': __('identifierSchemeCodeUnspsc190501.70121803'),
	'identifierSchemeCodeUnspsc190501.70121900': __('identifierSchemeCodeUnspsc190501.70121900'),
	'identifierSchemeCodeUnspsc190501.70121901': __('identifierSchemeCodeUnspsc190501.70121901'),
	'identifierSchemeCodeUnspsc190501.70121902': __('identifierSchemeCodeUnspsc190501.70121902'),
	'identifierSchemeCodeUnspsc190501.70121903': __('identifierSchemeCodeUnspsc190501.70121903'),
	'identifierSchemeCodeUnspsc190501.70122000': __('identifierSchemeCodeUnspsc190501.70122000'),
	'identifierSchemeCodeUnspsc190501.70122001': __('identifierSchemeCodeUnspsc190501.70122001'),
	'identifierSchemeCodeUnspsc190501.70122002': __('identifierSchemeCodeUnspsc190501.70122002'),
	'identifierSchemeCodeUnspsc190501.70122003': __('identifierSchemeCodeUnspsc190501.70122003'),
	'identifierSchemeCodeUnspsc190501.70122004': __('identifierSchemeCodeUnspsc190501.70122004'),
	'identifierSchemeCodeUnspsc190501.70122005': __('identifierSchemeCodeUnspsc190501.70122005'),
	'identifierSchemeCodeUnspsc190501.70122006': __('identifierSchemeCodeUnspsc190501.70122006'),
	'identifierSchemeCodeUnspsc190501.70122007': __('identifierSchemeCodeUnspsc190501.70122007'),
	'identifierSchemeCodeUnspsc190501.70122008': __('identifierSchemeCodeUnspsc190501.70122008'),
	'identifierSchemeCodeUnspsc190501.70122009': __('identifierSchemeCodeUnspsc190501.70122009'),
	'identifierSchemeCodeUnspsc190501.70122010': __('identifierSchemeCodeUnspsc190501.70122010'),
	'identifierSchemeCodeUnspsc190501.70123000': __('identifierSchemeCodeUnspsc190501.70123000'),
	'identifierSchemeCodeUnspsc190501.70123001': __('identifierSchemeCodeUnspsc190501.70123001'),
	'identifierSchemeCodeUnspsc190501.70130000': __('identifierSchemeCodeUnspsc190501.70130000'),
	'identifierSchemeCodeUnspsc190501.70131500': __('identifierSchemeCodeUnspsc190501.70131500'),
	'identifierSchemeCodeUnspsc190501.70131501': __('identifierSchemeCodeUnspsc190501.70131501'),
	'identifierSchemeCodeUnspsc190501.70131502': __('identifierSchemeCodeUnspsc190501.70131502'),
	'identifierSchemeCodeUnspsc190501.70131503': __('identifierSchemeCodeUnspsc190501.70131503'),
	'identifierSchemeCodeUnspsc190501.70131504': __('identifierSchemeCodeUnspsc190501.70131504'),
	'identifierSchemeCodeUnspsc190501.70131505': __('identifierSchemeCodeUnspsc190501.70131505'),
	'identifierSchemeCodeUnspsc190501.70131506': __('identifierSchemeCodeUnspsc190501.70131506'),
	'identifierSchemeCodeUnspsc190501.70131600': __('identifierSchemeCodeUnspsc190501.70131600'),
	'identifierSchemeCodeUnspsc190501.70131601': __('identifierSchemeCodeUnspsc190501.70131601'),
	'identifierSchemeCodeUnspsc190501.70131602': __('identifierSchemeCodeUnspsc190501.70131602'),
	'identifierSchemeCodeUnspsc190501.70131603': __('identifierSchemeCodeUnspsc190501.70131603'),
	'identifierSchemeCodeUnspsc190501.70131604': __('identifierSchemeCodeUnspsc190501.70131604'),
	'identifierSchemeCodeUnspsc190501.70131605': __('identifierSchemeCodeUnspsc190501.70131605'),
	'identifierSchemeCodeUnspsc190501.70131700': __('identifierSchemeCodeUnspsc190501.70131700'),
	'identifierSchemeCodeUnspsc190501.70131701': __('identifierSchemeCodeUnspsc190501.70131701'),
	'identifierSchemeCodeUnspsc190501.70131702': __('identifierSchemeCodeUnspsc190501.70131702'),
	'identifierSchemeCodeUnspsc190501.70131703': __('identifierSchemeCodeUnspsc190501.70131703'),
	'identifierSchemeCodeUnspsc190501.70131704': __('identifierSchemeCodeUnspsc190501.70131704'),
	'identifierSchemeCodeUnspsc190501.70131705': __('identifierSchemeCodeUnspsc190501.70131705'),
	'identifierSchemeCodeUnspsc190501.70131706': __('identifierSchemeCodeUnspsc190501.70131706'),
	'identifierSchemeCodeUnspsc190501.70131707': __('identifierSchemeCodeUnspsc190501.70131707'),
	'identifierSchemeCodeUnspsc190501.70131708': __('identifierSchemeCodeUnspsc190501.70131708'),
	'identifierSchemeCodeUnspsc190501.70140000': __('identifierSchemeCodeUnspsc190501.70140000'),
	'identifierSchemeCodeUnspsc190501.70141500': __('identifierSchemeCodeUnspsc190501.70141500'),
	'identifierSchemeCodeUnspsc190501.70141501': __('identifierSchemeCodeUnspsc190501.70141501'),
	'identifierSchemeCodeUnspsc190501.70141502': __('identifierSchemeCodeUnspsc190501.70141502'),
	'identifierSchemeCodeUnspsc190501.70141503': __('identifierSchemeCodeUnspsc190501.70141503'),
	'identifierSchemeCodeUnspsc190501.70141504': __('identifierSchemeCodeUnspsc190501.70141504'),
	'identifierSchemeCodeUnspsc190501.70141505': __('identifierSchemeCodeUnspsc190501.70141505'),
	'identifierSchemeCodeUnspsc190501.70141506': __('identifierSchemeCodeUnspsc190501.70141506'),
	'identifierSchemeCodeUnspsc190501.70141507': __('identifierSchemeCodeUnspsc190501.70141507'),
	'identifierSchemeCodeUnspsc190501.70141508': __('identifierSchemeCodeUnspsc190501.70141508'),
	'identifierSchemeCodeUnspsc190501.70141509': __('identifierSchemeCodeUnspsc190501.70141509'),
	'identifierSchemeCodeUnspsc190501.70141510': __('identifierSchemeCodeUnspsc190501.70141510'),
	'identifierSchemeCodeUnspsc190501.70141511': __('identifierSchemeCodeUnspsc190501.70141511'),
	'identifierSchemeCodeUnspsc190501.70141512': __('identifierSchemeCodeUnspsc190501.70141512'),
	'identifierSchemeCodeUnspsc190501.70141513': __('identifierSchemeCodeUnspsc190501.70141513'),
	'identifierSchemeCodeUnspsc190501.70141514': __('identifierSchemeCodeUnspsc190501.70141514'),
	'identifierSchemeCodeUnspsc190501.70141515': __('identifierSchemeCodeUnspsc190501.70141515'),
	'identifierSchemeCodeUnspsc190501.70141516': __('identifierSchemeCodeUnspsc190501.70141516'),
	'identifierSchemeCodeUnspsc190501.70141517': __('identifierSchemeCodeUnspsc190501.70141517'),
	'identifierSchemeCodeUnspsc190501.70141518': __('identifierSchemeCodeUnspsc190501.70141518'),
	'identifierSchemeCodeUnspsc190501.70141519': __('identifierSchemeCodeUnspsc190501.70141519'),
	'identifierSchemeCodeUnspsc190501.70141520': __('identifierSchemeCodeUnspsc190501.70141520'),
	'identifierSchemeCodeUnspsc190501.70141600': __('identifierSchemeCodeUnspsc190501.70141600'),
	'identifierSchemeCodeUnspsc190501.70141601': __('identifierSchemeCodeUnspsc190501.70141601'),
	'identifierSchemeCodeUnspsc190501.70141602': __('identifierSchemeCodeUnspsc190501.70141602'),
	'identifierSchemeCodeUnspsc190501.70141603': __('identifierSchemeCodeUnspsc190501.70141603'),
	'identifierSchemeCodeUnspsc190501.70141604': __('identifierSchemeCodeUnspsc190501.70141604'),
	'identifierSchemeCodeUnspsc190501.70141605': __('identifierSchemeCodeUnspsc190501.70141605'),
	'identifierSchemeCodeUnspsc190501.70141606': __('identifierSchemeCodeUnspsc190501.70141606'),
	'identifierSchemeCodeUnspsc190501.70141607': __('identifierSchemeCodeUnspsc190501.70141607'),
	'identifierSchemeCodeUnspsc190501.70141608': __('identifierSchemeCodeUnspsc190501.70141608'),
	'identifierSchemeCodeUnspsc190501.70141700': __('identifierSchemeCodeUnspsc190501.70141700'),
	'identifierSchemeCodeUnspsc190501.70141701': __('identifierSchemeCodeUnspsc190501.70141701'),
	'identifierSchemeCodeUnspsc190501.70141702': __('identifierSchemeCodeUnspsc190501.70141702'),
	'identifierSchemeCodeUnspsc190501.70141703': __('identifierSchemeCodeUnspsc190501.70141703'),
	'identifierSchemeCodeUnspsc190501.70141704': __('identifierSchemeCodeUnspsc190501.70141704'),
	'identifierSchemeCodeUnspsc190501.70141705': __('identifierSchemeCodeUnspsc190501.70141705'),
	'identifierSchemeCodeUnspsc190501.70141706': __('identifierSchemeCodeUnspsc190501.70141706'),
	'identifierSchemeCodeUnspsc190501.70141707': __('identifierSchemeCodeUnspsc190501.70141707'),
	'identifierSchemeCodeUnspsc190501.70141708': __('identifierSchemeCodeUnspsc190501.70141708'),
	'identifierSchemeCodeUnspsc190501.70141709': __('identifierSchemeCodeUnspsc190501.70141709'),
	'identifierSchemeCodeUnspsc190501.70141710': __('identifierSchemeCodeUnspsc190501.70141710'),
	'identifierSchemeCodeUnspsc190501.70141800': __('identifierSchemeCodeUnspsc190501.70141800'),
	'identifierSchemeCodeUnspsc190501.70141801': __('identifierSchemeCodeUnspsc190501.70141801'),
	'identifierSchemeCodeUnspsc190501.70141802': __('identifierSchemeCodeUnspsc190501.70141802'),
	'identifierSchemeCodeUnspsc190501.70141803': __('identifierSchemeCodeUnspsc190501.70141803'),
	'identifierSchemeCodeUnspsc190501.70141804': __('identifierSchemeCodeUnspsc190501.70141804'),
	'identifierSchemeCodeUnspsc190501.70141900': __('identifierSchemeCodeUnspsc190501.70141900'),
	'identifierSchemeCodeUnspsc190501.70141901': __('identifierSchemeCodeUnspsc190501.70141901'),
	'identifierSchemeCodeUnspsc190501.70141902': __('identifierSchemeCodeUnspsc190501.70141902'),
	'identifierSchemeCodeUnspsc190501.70141903': __('identifierSchemeCodeUnspsc190501.70141903'),
	'identifierSchemeCodeUnspsc190501.70141904': __('identifierSchemeCodeUnspsc190501.70141904'),
	'identifierSchemeCodeUnspsc190501.70142000': __('identifierSchemeCodeUnspsc190501.70142000'),
	'identifierSchemeCodeUnspsc190501.70142001': __('identifierSchemeCodeUnspsc190501.70142001'),
	'identifierSchemeCodeUnspsc190501.70142002': __('identifierSchemeCodeUnspsc190501.70142002'),
	'identifierSchemeCodeUnspsc190501.70142003': __('identifierSchemeCodeUnspsc190501.70142003'),
	'identifierSchemeCodeUnspsc190501.70142004': __('identifierSchemeCodeUnspsc190501.70142004'),
	'identifierSchemeCodeUnspsc190501.70142005': __('identifierSchemeCodeUnspsc190501.70142005'),
	'identifierSchemeCodeUnspsc190501.70142006': __('identifierSchemeCodeUnspsc190501.70142006'),
	'identifierSchemeCodeUnspsc190501.70142007': __('identifierSchemeCodeUnspsc190501.70142007'),
	'identifierSchemeCodeUnspsc190501.70142008': __('identifierSchemeCodeUnspsc190501.70142008'),
	'identifierSchemeCodeUnspsc190501.70142009': __('identifierSchemeCodeUnspsc190501.70142009'),
	'identifierSchemeCodeUnspsc190501.70142010': __('identifierSchemeCodeUnspsc190501.70142010'),
	'identifierSchemeCodeUnspsc190501.70142011': __('identifierSchemeCodeUnspsc190501.70142011'),
	'identifierSchemeCodeUnspsc190501.70150000': __('identifierSchemeCodeUnspsc190501.70150000'),
	'identifierSchemeCodeUnspsc190501.70151500': __('identifierSchemeCodeUnspsc190501.70151500'),
	'identifierSchemeCodeUnspsc190501.70151501': __('identifierSchemeCodeUnspsc190501.70151501'),
	'identifierSchemeCodeUnspsc190501.70151502': __('identifierSchemeCodeUnspsc190501.70151502'),
	'identifierSchemeCodeUnspsc190501.70151503': __('identifierSchemeCodeUnspsc190501.70151503'),
	'identifierSchemeCodeUnspsc190501.70151504': __('identifierSchemeCodeUnspsc190501.70151504'),
	'identifierSchemeCodeUnspsc190501.70151505': __('identifierSchemeCodeUnspsc190501.70151505'),
	'identifierSchemeCodeUnspsc190501.70151506': __('identifierSchemeCodeUnspsc190501.70151506'),
	'identifierSchemeCodeUnspsc190501.70151507': __('identifierSchemeCodeUnspsc190501.70151507'),
	'identifierSchemeCodeUnspsc190501.70151508': __('identifierSchemeCodeUnspsc190501.70151508'),
	'identifierSchemeCodeUnspsc190501.70151509': __('identifierSchemeCodeUnspsc190501.70151509'),
	'identifierSchemeCodeUnspsc190501.70151510': __('identifierSchemeCodeUnspsc190501.70151510'),
	'identifierSchemeCodeUnspsc190501.70151600': __('identifierSchemeCodeUnspsc190501.70151600'),
	'identifierSchemeCodeUnspsc190501.70151601': __('identifierSchemeCodeUnspsc190501.70151601'),
	'identifierSchemeCodeUnspsc190501.70151602': __('identifierSchemeCodeUnspsc190501.70151602'),
	'identifierSchemeCodeUnspsc190501.70151603': __('identifierSchemeCodeUnspsc190501.70151603'),
	'identifierSchemeCodeUnspsc190501.70151604': __('identifierSchemeCodeUnspsc190501.70151604'),
	'identifierSchemeCodeUnspsc190501.70151605': __('identifierSchemeCodeUnspsc190501.70151605'),
	'identifierSchemeCodeUnspsc190501.70151606': __('identifierSchemeCodeUnspsc190501.70151606'),
	'identifierSchemeCodeUnspsc190501.70151700': __('identifierSchemeCodeUnspsc190501.70151700'),
	'identifierSchemeCodeUnspsc190501.70151701': __('identifierSchemeCodeUnspsc190501.70151701'),
	'identifierSchemeCodeUnspsc190501.70151702': __('identifierSchemeCodeUnspsc190501.70151702'),
	'identifierSchemeCodeUnspsc190501.70151703': __('identifierSchemeCodeUnspsc190501.70151703'),
	'identifierSchemeCodeUnspsc190501.70151704': __('identifierSchemeCodeUnspsc190501.70151704'),
	'identifierSchemeCodeUnspsc190501.70151705': __('identifierSchemeCodeUnspsc190501.70151705'),
	'identifierSchemeCodeUnspsc190501.70151706': __('identifierSchemeCodeUnspsc190501.70151706'),
	'identifierSchemeCodeUnspsc190501.70151707': __('identifierSchemeCodeUnspsc190501.70151707'),
	'identifierSchemeCodeUnspsc190501.70151800': __('identifierSchemeCodeUnspsc190501.70151800'),
	'identifierSchemeCodeUnspsc190501.70151801': __('identifierSchemeCodeUnspsc190501.70151801'),
	'identifierSchemeCodeUnspsc190501.70151802': __('identifierSchemeCodeUnspsc190501.70151802'),
	'identifierSchemeCodeUnspsc190501.70151803': __('identifierSchemeCodeUnspsc190501.70151803'),
	'identifierSchemeCodeUnspsc190501.70151804': __('identifierSchemeCodeUnspsc190501.70151804'),
	'identifierSchemeCodeUnspsc190501.70151805': __('identifierSchemeCodeUnspsc190501.70151805'),
	'identifierSchemeCodeUnspsc190501.70151806': __('identifierSchemeCodeUnspsc190501.70151806'),
	'identifierSchemeCodeUnspsc190501.70151807': __('identifierSchemeCodeUnspsc190501.70151807'),
	'identifierSchemeCodeUnspsc190501.70151900': __('identifierSchemeCodeUnspsc190501.70151900'),
	'identifierSchemeCodeUnspsc190501.70151901': __('identifierSchemeCodeUnspsc190501.70151901'),
	'identifierSchemeCodeUnspsc190501.70151902': __('identifierSchemeCodeUnspsc190501.70151902'),
	'identifierSchemeCodeUnspsc190501.70151903': __('identifierSchemeCodeUnspsc190501.70151903'),
	'identifierSchemeCodeUnspsc190501.70151904': __('identifierSchemeCodeUnspsc190501.70151904'),
	'identifierSchemeCodeUnspsc190501.70151905': __('identifierSchemeCodeUnspsc190501.70151905'),
	'identifierSchemeCodeUnspsc190501.70151906': __('identifierSchemeCodeUnspsc190501.70151906'),
	'identifierSchemeCodeUnspsc190501.70151907': __('identifierSchemeCodeUnspsc190501.70151907'),
	'identifierSchemeCodeUnspsc190501.70151909': __('identifierSchemeCodeUnspsc190501.70151909'),
	'identifierSchemeCodeUnspsc190501.70151910': __('identifierSchemeCodeUnspsc190501.70151910'),
	'identifierSchemeCodeUnspsc190501.70160000': __('identifierSchemeCodeUnspsc190501.70160000'),
	'identifierSchemeCodeUnspsc190501.70161500': __('identifierSchemeCodeUnspsc190501.70161500'),
	'identifierSchemeCodeUnspsc190501.70161501': __('identifierSchemeCodeUnspsc190501.70161501'),
	'identifierSchemeCodeUnspsc190501.70161600': __('identifierSchemeCodeUnspsc190501.70161600'),
	'identifierSchemeCodeUnspsc190501.70161601': __('identifierSchemeCodeUnspsc190501.70161601'),
	'identifierSchemeCodeUnspsc190501.70161700': __('identifierSchemeCodeUnspsc190501.70161700'),
	'identifierSchemeCodeUnspsc190501.70161701': __('identifierSchemeCodeUnspsc190501.70161701'),
	'identifierSchemeCodeUnspsc190501.70161702': __('identifierSchemeCodeUnspsc190501.70161702'),
	'identifierSchemeCodeUnspsc190501.70161703': __('identifierSchemeCodeUnspsc190501.70161703'),
	'identifierSchemeCodeUnspsc190501.70161704': __('identifierSchemeCodeUnspsc190501.70161704'),
	'identifierSchemeCodeUnspsc190501.70161705': __('identifierSchemeCodeUnspsc190501.70161705'),
	'identifierSchemeCodeUnspsc190501.70161706': __('identifierSchemeCodeUnspsc190501.70161706'),
	'identifierSchemeCodeUnspsc190501.70161707': __('identifierSchemeCodeUnspsc190501.70161707'),
	'identifierSchemeCodeUnspsc190501.70161708': __('identifierSchemeCodeUnspsc190501.70161708'),
	'identifierSchemeCodeUnspsc190501.70161709': __('identifierSchemeCodeUnspsc190501.70161709'),
	'identifierSchemeCodeUnspsc190501.70161710': __('identifierSchemeCodeUnspsc190501.70161710'),
	'identifierSchemeCodeUnspsc190501.70161711': __('identifierSchemeCodeUnspsc190501.70161711'),
	'identifierSchemeCodeUnspsc190501.70170000': __('identifierSchemeCodeUnspsc190501.70170000'),
	'identifierSchemeCodeUnspsc190501.70171500': __('identifierSchemeCodeUnspsc190501.70171500'),
	'identifierSchemeCodeUnspsc190501.70171501': __('identifierSchemeCodeUnspsc190501.70171501'),
	'identifierSchemeCodeUnspsc190501.70171502': __('identifierSchemeCodeUnspsc190501.70171502'),
	'identifierSchemeCodeUnspsc190501.70171503': __('identifierSchemeCodeUnspsc190501.70171503'),
	'identifierSchemeCodeUnspsc190501.70171504': __('identifierSchemeCodeUnspsc190501.70171504'),
	'identifierSchemeCodeUnspsc190501.70171505': __('identifierSchemeCodeUnspsc190501.70171505'),
	'identifierSchemeCodeUnspsc190501.70171506': __('identifierSchemeCodeUnspsc190501.70171506'),
	'identifierSchemeCodeUnspsc190501.70171507': __('identifierSchemeCodeUnspsc190501.70171507'),
	'identifierSchemeCodeUnspsc190501.70171600': __('identifierSchemeCodeUnspsc190501.70171600'),
	'identifierSchemeCodeUnspsc190501.70171601': __('identifierSchemeCodeUnspsc190501.70171601'),
	'identifierSchemeCodeUnspsc190501.70171602': __('identifierSchemeCodeUnspsc190501.70171602'),
	'identifierSchemeCodeUnspsc190501.70171603': __('identifierSchemeCodeUnspsc190501.70171603'),
	'identifierSchemeCodeUnspsc190501.70171604': __('identifierSchemeCodeUnspsc190501.70171604'),
	'identifierSchemeCodeUnspsc190501.70171605': __('identifierSchemeCodeUnspsc190501.70171605'),
	'identifierSchemeCodeUnspsc190501.70171606': __('identifierSchemeCodeUnspsc190501.70171606'),
	'identifierSchemeCodeUnspsc190501.70171607': __('identifierSchemeCodeUnspsc190501.70171607'),
	'identifierSchemeCodeUnspsc190501.70171700': __('identifierSchemeCodeUnspsc190501.70171700'),
	'identifierSchemeCodeUnspsc190501.70171701': __('identifierSchemeCodeUnspsc190501.70171701'),
	'identifierSchemeCodeUnspsc190501.70171702': __('identifierSchemeCodeUnspsc190501.70171702'),
	'identifierSchemeCodeUnspsc190501.70171703': __('identifierSchemeCodeUnspsc190501.70171703'),
	'identifierSchemeCodeUnspsc190501.70171704': __('identifierSchemeCodeUnspsc190501.70171704'),
	'identifierSchemeCodeUnspsc190501.70171705': __('identifierSchemeCodeUnspsc190501.70171705'),
	'identifierSchemeCodeUnspsc190501.70171706': __('identifierSchemeCodeUnspsc190501.70171706'),
	'identifierSchemeCodeUnspsc190501.70171707': __('identifierSchemeCodeUnspsc190501.70171707'),
	'identifierSchemeCodeUnspsc190501.70171708': __('identifierSchemeCodeUnspsc190501.70171708'),
	'identifierSchemeCodeUnspsc190501.70171709': __('identifierSchemeCodeUnspsc190501.70171709'),
	'identifierSchemeCodeUnspsc190501.70171710': __('identifierSchemeCodeUnspsc190501.70171710'),
	'identifierSchemeCodeUnspsc190501.70171800': __('identifierSchemeCodeUnspsc190501.70171800'),
	'identifierSchemeCodeUnspsc190501.70171801': __('identifierSchemeCodeUnspsc190501.70171801'),
	'identifierSchemeCodeUnspsc190501.70171802': __('identifierSchemeCodeUnspsc190501.70171802'),
	'identifierSchemeCodeUnspsc190501.70171803': __('identifierSchemeCodeUnspsc190501.70171803'),
	'identifierSchemeCodeUnspsc190501.71000000': __('identifierSchemeCodeUnspsc190501.71000000'),
	'identifierSchemeCodeUnspsc190501.71100000': __('identifierSchemeCodeUnspsc190501.71100000'),
	'identifierSchemeCodeUnspsc190501.71101500': __('identifierSchemeCodeUnspsc190501.71101500'),
	'identifierSchemeCodeUnspsc190501.71101501': __('identifierSchemeCodeUnspsc190501.71101501'),
	'identifierSchemeCodeUnspsc190501.71101502': __('identifierSchemeCodeUnspsc190501.71101502'),
	'identifierSchemeCodeUnspsc190501.71101600': __('identifierSchemeCodeUnspsc190501.71101600'),
	'identifierSchemeCodeUnspsc190501.71101601': __('identifierSchemeCodeUnspsc190501.71101601'),
	'identifierSchemeCodeUnspsc190501.71101602': __('identifierSchemeCodeUnspsc190501.71101602'),
	'identifierSchemeCodeUnspsc190501.71101603': __('identifierSchemeCodeUnspsc190501.71101603'),
	'identifierSchemeCodeUnspsc190501.71101604': __('identifierSchemeCodeUnspsc190501.71101604'),
	'identifierSchemeCodeUnspsc190501.71101605': __('identifierSchemeCodeUnspsc190501.71101605'),
	'identifierSchemeCodeUnspsc190501.71101606': __('identifierSchemeCodeUnspsc190501.71101606'),
	'identifierSchemeCodeUnspsc190501.71101607': __('identifierSchemeCodeUnspsc190501.71101607'),
	'identifierSchemeCodeUnspsc190501.71101608': __('identifierSchemeCodeUnspsc190501.71101608'),
	'identifierSchemeCodeUnspsc190501.71101609': __('identifierSchemeCodeUnspsc190501.71101609'),
	'identifierSchemeCodeUnspsc190501.71101700': __('identifierSchemeCodeUnspsc190501.71101700'),
	'identifierSchemeCodeUnspsc190501.71101701': __('identifierSchemeCodeUnspsc190501.71101701'),
	'identifierSchemeCodeUnspsc190501.71101702': __('identifierSchemeCodeUnspsc190501.71101702'),
	'identifierSchemeCodeUnspsc190501.71101703': __('identifierSchemeCodeUnspsc190501.71101703'),
	'identifierSchemeCodeUnspsc190501.71101704': __('identifierSchemeCodeUnspsc190501.71101704'),
	'identifierSchemeCodeUnspsc190501.71101705': __('identifierSchemeCodeUnspsc190501.71101705'),
	'identifierSchemeCodeUnspsc190501.71101706': __('identifierSchemeCodeUnspsc190501.71101706'),
	'identifierSchemeCodeUnspsc190501.71101707': __('identifierSchemeCodeUnspsc190501.71101707'),
	'identifierSchemeCodeUnspsc190501.71101708': __('identifierSchemeCodeUnspsc190501.71101708'),
	'identifierSchemeCodeUnspsc190501.71101709': __('identifierSchemeCodeUnspsc190501.71101709'),
	'identifierSchemeCodeUnspsc190501.71101710': __('identifierSchemeCodeUnspsc190501.71101710'),
	'identifierSchemeCodeUnspsc190501.71110000': __('identifierSchemeCodeUnspsc190501.71110000'),
	'identifierSchemeCodeUnspsc190501.71112000': __('identifierSchemeCodeUnspsc190501.71112000'),
	'identifierSchemeCodeUnspsc190501.71112001': __('identifierSchemeCodeUnspsc190501.71112001'),
	'identifierSchemeCodeUnspsc190501.71112002': __('identifierSchemeCodeUnspsc190501.71112002'),
	'identifierSchemeCodeUnspsc190501.71112003': __('identifierSchemeCodeUnspsc190501.71112003'),
	'identifierSchemeCodeUnspsc190501.71112004': __('identifierSchemeCodeUnspsc190501.71112004'),
	'identifierSchemeCodeUnspsc190501.71112005': __('identifierSchemeCodeUnspsc190501.71112005'),
	'identifierSchemeCodeUnspsc190501.71112006': __('identifierSchemeCodeUnspsc190501.71112006'),
	'identifierSchemeCodeUnspsc190501.71112007': __('identifierSchemeCodeUnspsc190501.71112007'),
	'identifierSchemeCodeUnspsc190501.71112008': __('identifierSchemeCodeUnspsc190501.71112008'),
	'identifierSchemeCodeUnspsc190501.71112009': __('identifierSchemeCodeUnspsc190501.71112009'),
	'identifierSchemeCodeUnspsc190501.71112010': __('identifierSchemeCodeUnspsc190501.71112010'),
	'identifierSchemeCodeUnspsc190501.71112011': __('identifierSchemeCodeUnspsc190501.71112011'),
	'identifierSchemeCodeUnspsc190501.71112012': __('identifierSchemeCodeUnspsc190501.71112012'),
	'identifierSchemeCodeUnspsc190501.71112013': __('identifierSchemeCodeUnspsc190501.71112013'),
	'identifierSchemeCodeUnspsc190501.71112014': __('identifierSchemeCodeUnspsc190501.71112014'),
	'identifierSchemeCodeUnspsc190501.71112015': __('identifierSchemeCodeUnspsc190501.71112015'),
	'identifierSchemeCodeUnspsc190501.71112017': __('identifierSchemeCodeUnspsc190501.71112017'),
	'identifierSchemeCodeUnspsc190501.71112018': __('identifierSchemeCodeUnspsc190501.71112018'),
	'identifierSchemeCodeUnspsc190501.71112019': __('identifierSchemeCodeUnspsc190501.71112019'),
	'identifierSchemeCodeUnspsc190501.71112020': __('identifierSchemeCodeUnspsc190501.71112020'),
	'identifierSchemeCodeUnspsc190501.71112021': __('identifierSchemeCodeUnspsc190501.71112021'),
	'identifierSchemeCodeUnspsc190501.71112022': __('identifierSchemeCodeUnspsc190501.71112022'),
	'identifierSchemeCodeUnspsc190501.71112023': __('identifierSchemeCodeUnspsc190501.71112023'),
	'identifierSchemeCodeUnspsc190501.71112024': __('identifierSchemeCodeUnspsc190501.71112024'),
	'identifierSchemeCodeUnspsc190501.71112025': __('identifierSchemeCodeUnspsc190501.71112025'),
	'identifierSchemeCodeUnspsc190501.71112026': __('identifierSchemeCodeUnspsc190501.71112026'),
	'identifierSchemeCodeUnspsc190501.71112027': __('identifierSchemeCodeUnspsc190501.71112027'),
	'identifierSchemeCodeUnspsc190501.71112028': __('identifierSchemeCodeUnspsc190501.71112028'),
	'identifierSchemeCodeUnspsc190501.71112029': __('identifierSchemeCodeUnspsc190501.71112029'),
	'identifierSchemeCodeUnspsc190501.71112030': __('identifierSchemeCodeUnspsc190501.71112030'),
	'identifierSchemeCodeUnspsc190501.71112031': __('identifierSchemeCodeUnspsc190501.71112031'),
	'identifierSchemeCodeUnspsc190501.71112100': __('identifierSchemeCodeUnspsc190501.71112100'),
	'identifierSchemeCodeUnspsc190501.71112101': __('identifierSchemeCodeUnspsc190501.71112101'),
	'identifierSchemeCodeUnspsc190501.71112102': __('identifierSchemeCodeUnspsc190501.71112102'),
	'identifierSchemeCodeUnspsc190501.71112103': __('identifierSchemeCodeUnspsc190501.71112103'),
	'identifierSchemeCodeUnspsc190501.71112105': __('identifierSchemeCodeUnspsc190501.71112105'),
	'identifierSchemeCodeUnspsc190501.71112106': __('identifierSchemeCodeUnspsc190501.71112106'),
	'identifierSchemeCodeUnspsc190501.71112107': __('identifierSchemeCodeUnspsc190501.71112107'),
	'identifierSchemeCodeUnspsc190501.71112108': __('identifierSchemeCodeUnspsc190501.71112108'),
	'identifierSchemeCodeUnspsc190501.71112109': __('identifierSchemeCodeUnspsc190501.71112109'),
	'identifierSchemeCodeUnspsc190501.71112110': __('identifierSchemeCodeUnspsc190501.71112110'),
	'identifierSchemeCodeUnspsc190501.71112111': __('identifierSchemeCodeUnspsc190501.71112111'),
	'identifierSchemeCodeUnspsc190501.71112112': __('identifierSchemeCodeUnspsc190501.71112112'),
	'identifierSchemeCodeUnspsc190501.71112113': __('identifierSchemeCodeUnspsc190501.71112113'),
	'identifierSchemeCodeUnspsc190501.71112114': __('identifierSchemeCodeUnspsc190501.71112114'),
	'identifierSchemeCodeUnspsc190501.71112115': __('identifierSchemeCodeUnspsc190501.71112115'),
	'identifierSchemeCodeUnspsc190501.71112116': __('identifierSchemeCodeUnspsc190501.71112116'),
	'identifierSchemeCodeUnspsc190501.71112117': __('identifierSchemeCodeUnspsc190501.71112117'),
	'identifierSchemeCodeUnspsc190501.71112119': __('identifierSchemeCodeUnspsc190501.71112119'),
	'identifierSchemeCodeUnspsc190501.71112120': __('identifierSchemeCodeUnspsc190501.71112120'),
	'identifierSchemeCodeUnspsc190501.71112121': __('identifierSchemeCodeUnspsc190501.71112121'),
	'identifierSchemeCodeUnspsc190501.71112122': __('identifierSchemeCodeUnspsc190501.71112122'),
	'identifierSchemeCodeUnspsc190501.71112123': __('identifierSchemeCodeUnspsc190501.71112123'),
	'identifierSchemeCodeUnspsc190501.71112200': __('identifierSchemeCodeUnspsc190501.71112200'),
	'identifierSchemeCodeUnspsc190501.71112202': __('identifierSchemeCodeUnspsc190501.71112202'),
	'identifierSchemeCodeUnspsc190501.71112203': __('identifierSchemeCodeUnspsc190501.71112203'),
	'identifierSchemeCodeUnspsc190501.71112204': __('identifierSchemeCodeUnspsc190501.71112204'),
	'identifierSchemeCodeUnspsc190501.71112205': __('identifierSchemeCodeUnspsc190501.71112205'),
	'identifierSchemeCodeUnspsc190501.71112206': __('identifierSchemeCodeUnspsc190501.71112206'),
	'identifierSchemeCodeUnspsc190501.71112300': __('identifierSchemeCodeUnspsc190501.71112300'),
	'identifierSchemeCodeUnspsc190501.71112301': __('identifierSchemeCodeUnspsc190501.71112301'),
	'identifierSchemeCodeUnspsc190501.71112302': __('identifierSchemeCodeUnspsc190501.71112302'),
	'identifierSchemeCodeUnspsc190501.71112303': __('identifierSchemeCodeUnspsc190501.71112303'),
	'identifierSchemeCodeUnspsc190501.71112322': __('identifierSchemeCodeUnspsc190501.71112322'),
	'identifierSchemeCodeUnspsc190501.71112323': __('identifierSchemeCodeUnspsc190501.71112323'),
	'identifierSchemeCodeUnspsc190501.71112324': __('identifierSchemeCodeUnspsc190501.71112324'),
	'identifierSchemeCodeUnspsc190501.71112325': __('identifierSchemeCodeUnspsc190501.71112325'),
	'identifierSchemeCodeUnspsc190501.71112326': __('identifierSchemeCodeUnspsc190501.71112326'),
	'identifierSchemeCodeUnspsc190501.71112327': __('identifierSchemeCodeUnspsc190501.71112327'),
	'identifierSchemeCodeUnspsc190501.71112328': __('identifierSchemeCodeUnspsc190501.71112328'),
	'identifierSchemeCodeUnspsc190501.71112329': __('identifierSchemeCodeUnspsc190501.71112329'),
	'identifierSchemeCodeUnspsc190501.71112330': __('identifierSchemeCodeUnspsc190501.71112330'),
	'identifierSchemeCodeUnspsc190501.71120000': __('identifierSchemeCodeUnspsc190501.71120000'),
	'identifierSchemeCodeUnspsc190501.71121000': __('identifierSchemeCodeUnspsc190501.71121000'),
	'identifierSchemeCodeUnspsc190501.71121001': __('identifierSchemeCodeUnspsc190501.71121001'),
	'identifierSchemeCodeUnspsc190501.71121002': __('identifierSchemeCodeUnspsc190501.71121002'),
	'identifierSchemeCodeUnspsc190501.71121008': __('identifierSchemeCodeUnspsc190501.71121008'),
	'identifierSchemeCodeUnspsc190501.71121009': __('identifierSchemeCodeUnspsc190501.71121009'),
	'identifierSchemeCodeUnspsc190501.71121010': __('identifierSchemeCodeUnspsc190501.71121010'),
	'identifierSchemeCodeUnspsc190501.71121011': __('identifierSchemeCodeUnspsc190501.71121011'),
	'identifierSchemeCodeUnspsc190501.71121012': __('identifierSchemeCodeUnspsc190501.71121012'),
	'identifierSchemeCodeUnspsc190501.71121016': __('identifierSchemeCodeUnspsc190501.71121016'),
	'identifierSchemeCodeUnspsc190501.71121017': __('identifierSchemeCodeUnspsc190501.71121017'),
	'identifierSchemeCodeUnspsc190501.71121018': __('identifierSchemeCodeUnspsc190501.71121018'),
	'identifierSchemeCodeUnspsc190501.71121024': __('identifierSchemeCodeUnspsc190501.71121024'),
	'identifierSchemeCodeUnspsc190501.71121025': __('identifierSchemeCodeUnspsc190501.71121025'),
	'identifierSchemeCodeUnspsc190501.71121100': __('identifierSchemeCodeUnspsc190501.71121100'),
	'identifierSchemeCodeUnspsc190501.71121101': __('identifierSchemeCodeUnspsc190501.71121101'),
	'identifierSchemeCodeUnspsc190501.71121102': __('identifierSchemeCodeUnspsc190501.71121102'),
	'identifierSchemeCodeUnspsc190501.71121103': __('identifierSchemeCodeUnspsc190501.71121103'),
	'identifierSchemeCodeUnspsc190501.71121104': __('identifierSchemeCodeUnspsc190501.71121104'),
	'identifierSchemeCodeUnspsc190501.71121105': __('identifierSchemeCodeUnspsc190501.71121105'),
	'identifierSchemeCodeUnspsc190501.71121106': __('identifierSchemeCodeUnspsc190501.71121106'),
	'identifierSchemeCodeUnspsc190501.71121107': __('identifierSchemeCodeUnspsc190501.71121107'),
	'identifierSchemeCodeUnspsc190501.71121108': __('identifierSchemeCodeUnspsc190501.71121108'),
	'identifierSchemeCodeUnspsc190501.71121109': __('identifierSchemeCodeUnspsc190501.71121109'),
	'identifierSchemeCodeUnspsc190501.71121110': __('identifierSchemeCodeUnspsc190501.71121110'),
	'identifierSchemeCodeUnspsc190501.71121111': __('identifierSchemeCodeUnspsc190501.71121111'),
	'identifierSchemeCodeUnspsc190501.71121112': __('identifierSchemeCodeUnspsc190501.71121112'),
	'identifierSchemeCodeUnspsc190501.71121113': __('identifierSchemeCodeUnspsc190501.71121113'),
	'identifierSchemeCodeUnspsc190501.71121114': __('identifierSchemeCodeUnspsc190501.71121114'),
	'identifierSchemeCodeUnspsc190501.71121115': __('identifierSchemeCodeUnspsc190501.71121115'),
	'identifierSchemeCodeUnspsc190501.71121116': __('identifierSchemeCodeUnspsc190501.71121116'),
	'identifierSchemeCodeUnspsc190501.71121117': __('identifierSchemeCodeUnspsc190501.71121117'),
	'identifierSchemeCodeUnspsc190501.71121118': __('identifierSchemeCodeUnspsc190501.71121118'),
	'identifierSchemeCodeUnspsc190501.71121119': __('identifierSchemeCodeUnspsc190501.71121119'),
	'identifierSchemeCodeUnspsc190501.71121120': __('identifierSchemeCodeUnspsc190501.71121120'),
	'identifierSchemeCodeUnspsc190501.71121121': __('identifierSchemeCodeUnspsc190501.71121121'),
	'identifierSchemeCodeUnspsc190501.71121122': __('identifierSchemeCodeUnspsc190501.71121122'),
	'identifierSchemeCodeUnspsc190501.71121123': __('identifierSchemeCodeUnspsc190501.71121123'),
	'identifierSchemeCodeUnspsc190501.71121200': __('identifierSchemeCodeUnspsc190501.71121200'),
	'identifierSchemeCodeUnspsc190501.71121201': __('identifierSchemeCodeUnspsc190501.71121201'),
	'identifierSchemeCodeUnspsc190501.71121202': __('identifierSchemeCodeUnspsc190501.71121202'),
	'identifierSchemeCodeUnspsc190501.71121203': __('identifierSchemeCodeUnspsc190501.71121203'),
	'identifierSchemeCodeUnspsc190501.71121204': __('identifierSchemeCodeUnspsc190501.71121204'),
	'identifierSchemeCodeUnspsc190501.71121205': __('identifierSchemeCodeUnspsc190501.71121205'),
	'identifierSchemeCodeUnspsc190501.71121206': __('identifierSchemeCodeUnspsc190501.71121206'),
	'identifierSchemeCodeUnspsc190501.71121207': __('identifierSchemeCodeUnspsc190501.71121207'),
	'identifierSchemeCodeUnspsc190501.71121208': __('identifierSchemeCodeUnspsc190501.71121208'),
	'identifierSchemeCodeUnspsc190501.71121209': __('identifierSchemeCodeUnspsc190501.71121209'),
	'identifierSchemeCodeUnspsc190501.71121210': __('identifierSchemeCodeUnspsc190501.71121210'),
	'identifierSchemeCodeUnspsc190501.71121211': __('identifierSchemeCodeUnspsc190501.71121211'),
	'identifierSchemeCodeUnspsc190501.71121300': __('identifierSchemeCodeUnspsc190501.71121300'),
	'identifierSchemeCodeUnspsc190501.71121301': __('identifierSchemeCodeUnspsc190501.71121301'),
	'identifierSchemeCodeUnspsc190501.71121302': __('identifierSchemeCodeUnspsc190501.71121302'),
	'identifierSchemeCodeUnspsc190501.71121303': __('identifierSchemeCodeUnspsc190501.71121303'),
	'identifierSchemeCodeUnspsc190501.71121304': __('identifierSchemeCodeUnspsc190501.71121304'),
	'identifierSchemeCodeUnspsc190501.71121305': __('identifierSchemeCodeUnspsc190501.71121305'),
	'identifierSchemeCodeUnspsc190501.71121307': __('identifierSchemeCodeUnspsc190501.71121307'),
	'identifierSchemeCodeUnspsc190501.71121309': __('identifierSchemeCodeUnspsc190501.71121309'),
	'identifierSchemeCodeUnspsc190501.71121310': __('identifierSchemeCodeUnspsc190501.71121310'),
	'identifierSchemeCodeUnspsc190501.71121400': __('identifierSchemeCodeUnspsc190501.71121400'),
	'identifierSchemeCodeUnspsc190501.71121401': __('identifierSchemeCodeUnspsc190501.71121401'),
	'identifierSchemeCodeUnspsc190501.71121402': __('identifierSchemeCodeUnspsc190501.71121402'),
	'identifierSchemeCodeUnspsc190501.71121403': __('identifierSchemeCodeUnspsc190501.71121403'),
	'identifierSchemeCodeUnspsc190501.71121404': __('identifierSchemeCodeUnspsc190501.71121404'),
	'identifierSchemeCodeUnspsc190501.71121405': __('identifierSchemeCodeUnspsc190501.71121405'),
	'identifierSchemeCodeUnspsc190501.71121406': __('identifierSchemeCodeUnspsc190501.71121406'),
	'identifierSchemeCodeUnspsc190501.71121407': __('identifierSchemeCodeUnspsc190501.71121407'),
	'identifierSchemeCodeUnspsc190501.71121408': __('identifierSchemeCodeUnspsc190501.71121408'),
	'identifierSchemeCodeUnspsc190501.71121500': __('identifierSchemeCodeUnspsc190501.71121500'),
	'identifierSchemeCodeUnspsc190501.71121501': __('identifierSchemeCodeUnspsc190501.71121501'),
	'identifierSchemeCodeUnspsc190501.71121502': __('identifierSchemeCodeUnspsc190501.71121502'),
	'identifierSchemeCodeUnspsc190501.71121503': __('identifierSchemeCodeUnspsc190501.71121503'),
	'identifierSchemeCodeUnspsc190501.71121504': __('identifierSchemeCodeUnspsc190501.71121504'),
	'identifierSchemeCodeUnspsc190501.71121505': __('identifierSchemeCodeUnspsc190501.71121505'),
	'identifierSchemeCodeUnspsc190501.71121506': __('identifierSchemeCodeUnspsc190501.71121506'),
	'identifierSchemeCodeUnspsc190501.71121507': __('identifierSchemeCodeUnspsc190501.71121507'),
	'identifierSchemeCodeUnspsc190501.71121508': __('identifierSchemeCodeUnspsc190501.71121508'),
	'identifierSchemeCodeUnspsc190501.71121509': __('identifierSchemeCodeUnspsc190501.71121509'),
	'identifierSchemeCodeUnspsc190501.71121510': __('identifierSchemeCodeUnspsc190501.71121510'),
	'identifierSchemeCodeUnspsc190501.71121511': __('identifierSchemeCodeUnspsc190501.71121511'),
	'identifierSchemeCodeUnspsc190501.71121512': __('identifierSchemeCodeUnspsc190501.71121512'),
	'identifierSchemeCodeUnspsc190501.71121513': __('identifierSchemeCodeUnspsc190501.71121513'),
	'identifierSchemeCodeUnspsc190501.71121514': __('identifierSchemeCodeUnspsc190501.71121514'),
	'identifierSchemeCodeUnspsc190501.71121515': __('identifierSchemeCodeUnspsc190501.71121515'),
	'identifierSchemeCodeUnspsc190501.71121516': __('identifierSchemeCodeUnspsc190501.71121516'),
	'identifierSchemeCodeUnspsc190501.71121600': __('identifierSchemeCodeUnspsc190501.71121600'),
	'identifierSchemeCodeUnspsc190501.71121601': __('identifierSchemeCodeUnspsc190501.71121601'),
	'identifierSchemeCodeUnspsc190501.71121602': __('identifierSchemeCodeUnspsc190501.71121602'),
	'identifierSchemeCodeUnspsc190501.71121603': __('identifierSchemeCodeUnspsc190501.71121603'),
	'identifierSchemeCodeUnspsc190501.71121604': __('identifierSchemeCodeUnspsc190501.71121604'),
	'identifierSchemeCodeUnspsc190501.71121605': __('identifierSchemeCodeUnspsc190501.71121605'),
	'identifierSchemeCodeUnspsc190501.71121606': __('identifierSchemeCodeUnspsc190501.71121606'),
	'identifierSchemeCodeUnspsc190501.71121607': __('identifierSchemeCodeUnspsc190501.71121607'),
	'identifierSchemeCodeUnspsc190501.71121608': __('identifierSchemeCodeUnspsc190501.71121608'),
	'identifierSchemeCodeUnspsc190501.71121610': __('identifierSchemeCodeUnspsc190501.71121610'),
	'identifierSchemeCodeUnspsc190501.71121611': __('identifierSchemeCodeUnspsc190501.71121611'),
	'identifierSchemeCodeUnspsc190501.71121612': __('identifierSchemeCodeUnspsc190501.71121612'),
	'identifierSchemeCodeUnspsc190501.71121613': __('identifierSchemeCodeUnspsc190501.71121613'),
	'identifierSchemeCodeUnspsc190501.71121614': __('identifierSchemeCodeUnspsc190501.71121614'),
	'identifierSchemeCodeUnspsc190501.71121615': __('identifierSchemeCodeUnspsc190501.71121615'),
	'identifierSchemeCodeUnspsc190501.71121616': __('identifierSchemeCodeUnspsc190501.71121616'),
	'identifierSchemeCodeUnspsc190501.71121617': __('identifierSchemeCodeUnspsc190501.71121617'),
	'identifierSchemeCodeUnspsc190501.71121618': __('identifierSchemeCodeUnspsc190501.71121618'),
	'identifierSchemeCodeUnspsc190501.71121619': __('identifierSchemeCodeUnspsc190501.71121619'),
	'identifierSchemeCodeUnspsc190501.71121620': __('identifierSchemeCodeUnspsc190501.71121620'),
	'identifierSchemeCodeUnspsc190501.71121621': __('identifierSchemeCodeUnspsc190501.71121621'),
	'identifierSchemeCodeUnspsc190501.71121622': __('identifierSchemeCodeUnspsc190501.71121622'),
	'identifierSchemeCodeUnspsc190501.71121623': __('identifierSchemeCodeUnspsc190501.71121623'),
	'identifierSchemeCodeUnspsc190501.71121624': __('identifierSchemeCodeUnspsc190501.71121624'),
	'identifierSchemeCodeUnspsc190501.71121625': __('identifierSchemeCodeUnspsc190501.71121625'),
	'identifierSchemeCodeUnspsc190501.71121626': __('identifierSchemeCodeUnspsc190501.71121626'),
	'identifierSchemeCodeUnspsc190501.71121627': __('identifierSchemeCodeUnspsc190501.71121627'),
	'identifierSchemeCodeUnspsc190501.71121628': __('identifierSchemeCodeUnspsc190501.71121628'),
	'identifierSchemeCodeUnspsc190501.71121629': __('identifierSchemeCodeUnspsc190501.71121629'),
	'identifierSchemeCodeUnspsc190501.71121630': __('identifierSchemeCodeUnspsc190501.71121630'),
	'identifierSchemeCodeUnspsc190501.71121631': __('identifierSchemeCodeUnspsc190501.71121631'),
	'identifierSchemeCodeUnspsc190501.71121632': __('identifierSchemeCodeUnspsc190501.71121632'),
	'identifierSchemeCodeUnspsc190501.71121633': __('identifierSchemeCodeUnspsc190501.71121633'),
	'identifierSchemeCodeUnspsc190501.71121634': __('identifierSchemeCodeUnspsc190501.71121634'),
	'identifierSchemeCodeUnspsc190501.71121635': __('identifierSchemeCodeUnspsc190501.71121635'),
	'identifierSchemeCodeUnspsc190501.71121636': __('identifierSchemeCodeUnspsc190501.71121636'),
	'identifierSchemeCodeUnspsc190501.71121637': __('identifierSchemeCodeUnspsc190501.71121637'),
	'identifierSchemeCodeUnspsc190501.71121638': __('identifierSchemeCodeUnspsc190501.71121638'),
	'identifierSchemeCodeUnspsc190501.71121639': __('identifierSchemeCodeUnspsc190501.71121639'),
	'identifierSchemeCodeUnspsc190501.71121640': __('identifierSchemeCodeUnspsc190501.71121640'),
	'identifierSchemeCodeUnspsc190501.71121641': __('identifierSchemeCodeUnspsc190501.71121641'),
	'identifierSchemeCodeUnspsc190501.71121700': __('identifierSchemeCodeUnspsc190501.71121700'),
	'identifierSchemeCodeUnspsc190501.71121701': __('identifierSchemeCodeUnspsc190501.71121701'),
	'identifierSchemeCodeUnspsc190501.71121702': __('identifierSchemeCodeUnspsc190501.71121702'),
	'identifierSchemeCodeUnspsc190501.71121703': __('identifierSchemeCodeUnspsc190501.71121703'),
	'identifierSchemeCodeUnspsc190501.71121704': __('identifierSchemeCodeUnspsc190501.71121704'),
	'identifierSchemeCodeUnspsc190501.71121705': __('identifierSchemeCodeUnspsc190501.71121705'),
	'identifierSchemeCodeUnspsc190501.71121706': __('identifierSchemeCodeUnspsc190501.71121706'),
	'identifierSchemeCodeUnspsc190501.71121800': __('identifierSchemeCodeUnspsc190501.71121800'),
	'identifierSchemeCodeUnspsc190501.71121805': __('identifierSchemeCodeUnspsc190501.71121805'),
	'identifierSchemeCodeUnspsc190501.71121806': __('identifierSchemeCodeUnspsc190501.71121806'),
	'identifierSchemeCodeUnspsc190501.71121807': __('identifierSchemeCodeUnspsc190501.71121807'),
	'identifierSchemeCodeUnspsc190501.71121808': __('identifierSchemeCodeUnspsc190501.71121808'),
	'identifierSchemeCodeUnspsc190501.71121809': __('identifierSchemeCodeUnspsc190501.71121809'),
	'identifierSchemeCodeUnspsc190501.71121810': __('identifierSchemeCodeUnspsc190501.71121810'),
	'identifierSchemeCodeUnspsc190501.71121811': __('identifierSchemeCodeUnspsc190501.71121811'),
	'identifierSchemeCodeUnspsc190501.71121812': __('identifierSchemeCodeUnspsc190501.71121812'),
	'identifierSchemeCodeUnspsc190501.71121900': __('identifierSchemeCodeUnspsc190501.71121900'),
	'identifierSchemeCodeUnspsc190501.71121901': __('identifierSchemeCodeUnspsc190501.71121901'),
	'identifierSchemeCodeUnspsc190501.71121902': __('identifierSchemeCodeUnspsc190501.71121902'),
	'identifierSchemeCodeUnspsc190501.71121903': __('identifierSchemeCodeUnspsc190501.71121903'),
	'identifierSchemeCodeUnspsc190501.71121904': __('identifierSchemeCodeUnspsc190501.71121904'),
	'identifierSchemeCodeUnspsc190501.71121905': __('identifierSchemeCodeUnspsc190501.71121905'),
	'identifierSchemeCodeUnspsc190501.71122000': __('identifierSchemeCodeUnspsc190501.71122000'),
	'identifierSchemeCodeUnspsc190501.71122001': __('identifierSchemeCodeUnspsc190501.71122001'),
	'identifierSchemeCodeUnspsc190501.71122002': __('identifierSchemeCodeUnspsc190501.71122002'),
	'identifierSchemeCodeUnspsc190501.71122003': __('identifierSchemeCodeUnspsc190501.71122003'),
	'identifierSchemeCodeUnspsc190501.71122004': __('identifierSchemeCodeUnspsc190501.71122004'),
	'identifierSchemeCodeUnspsc190501.71122005': __('identifierSchemeCodeUnspsc190501.71122005'),
	'identifierSchemeCodeUnspsc190501.71122006': __('identifierSchemeCodeUnspsc190501.71122006'),
	'identifierSchemeCodeUnspsc190501.71122100': __('identifierSchemeCodeUnspsc190501.71122100'),
	'identifierSchemeCodeUnspsc190501.71122101': __('identifierSchemeCodeUnspsc190501.71122101'),
	'identifierSchemeCodeUnspsc190501.71122102': __('identifierSchemeCodeUnspsc190501.71122102'),
	'identifierSchemeCodeUnspsc190501.71122103': __('identifierSchemeCodeUnspsc190501.71122103'),
	'identifierSchemeCodeUnspsc190501.71122104': __('identifierSchemeCodeUnspsc190501.71122104'),
	'identifierSchemeCodeUnspsc190501.71122105': __('identifierSchemeCodeUnspsc190501.71122105'),
	'identifierSchemeCodeUnspsc190501.71122107': __('identifierSchemeCodeUnspsc190501.71122107'),
	'identifierSchemeCodeUnspsc190501.71122108': __('identifierSchemeCodeUnspsc190501.71122108'),
	'identifierSchemeCodeUnspsc190501.71122109': __('identifierSchemeCodeUnspsc190501.71122109'),
	'identifierSchemeCodeUnspsc190501.71122110': __('identifierSchemeCodeUnspsc190501.71122110'),
	'identifierSchemeCodeUnspsc190501.71122111': __('identifierSchemeCodeUnspsc190501.71122111'),
	'identifierSchemeCodeUnspsc190501.71122112': __('identifierSchemeCodeUnspsc190501.71122112'),
	'identifierSchemeCodeUnspsc190501.71122113': __('identifierSchemeCodeUnspsc190501.71122113'),
	'identifierSchemeCodeUnspsc190501.71122114': __('identifierSchemeCodeUnspsc190501.71122114'),
	'identifierSchemeCodeUnspsc190501.71122115': __('identifierSchemeCodeUnspsc190501.71122115'),
	'identifierSchemeCodeUnspsc190501.71122116': __('identifierSchemeCodeUnspsc190501.71122116'),
	'identifierSchemeCodeUnspsc190501.71122200': __('identifierSchemeCodeUnspsc190501.71122200'),
	'identifierSchemeCodeUnspsc190501.71122201': __('identifierSchemeCodeUnspsc190501.71122201'),
	'identifierSchemeCodeUnspsc190501.71122202': __('identifierSchemeCodeUnspsc190501.71122202'),
	'identifierSchemeCodeUnspsc190501.71122203': __('identifierSchemeCodeUnspsc190501.71122203'),
	'identifierSchemeCodeUnspsc190501.71122204': __('identifierSchemeCodeUnspsc190501.71122204'),
	'identifierSchemeCodeUnspsc190501.71122205': __('identifierSchemeCodeUnspsc190501.71122205'),
	'identifierSchemeCodeUnspsc190501.71122206': __('identifierSchemeCodeUnspsc190501.71122206'),
	'identifierSchemeCodeUnspsc190501.71122207': __('identifierSchemeCodeUnspsc190501.71122207'),
	'identifierSchemeCodeUnspsc190501.71122300': __('identifierSchemeCodeUnspsc190501.71122300'),
	'identifierSchemeCodeUnspsc190501.71122301': __('identifierSchemeCodeUnspsc190501.71122301'),
	'identifierSchemeCodeUnspsc190501.71122302': __('identifierSchemeCodeUnspsc190501.71122302'),
	'identifierSchemeCodeUnspsc190501.71122303': __('identifierSchemeCodeUnspsc190501.71122303'),
	'identifierSchemeCodeUnspsc190501.71122304': __('identifierSchemeCodeUnspsc190501.71122304'),
	'identifierSchemeCodeUnspsc190501.71122305': __('identifierSchemeCodeUnspsc190501.71122305'),
	'identifierSchemeCodeUnspsc190501.71122306': __('identifierSchemeCodeUnspsc190501.71122306'),
	'identifierSchemeCodeUnspsc190501.71122307': __('identifierSchemeCodeUnspsc190501.71122307'),
	'identifierSchemeCodeUnspsc190501.71122308': __('identifierSchemeCodeUnspsc190501.71122308'),
	'identifierSchemeCodeUnspsc190501.71122309': __('identifierSchemeCodeUnspsc190501.71122309'),
	'identifierSchemeCodeUnspsc190501.71122310': __('identifierSchemeCodeUnspsc190501.71122310'),
	'identifierSchemeCodeUnspsc190501.71122311': __('identifierSchemeCodeUnspsc190501.71122311'),
	'identifierSchemeCodeUnspsc190501.71122312': __('identifierSchemeCodeUnspsc190501.71122312'),
	'identifierSchemeCodeUnspsc190501.71122400': __('identifierSchemeCodeUnspsc190501.71122400'),
	'identifierSchemeCodeUnspsc190501.71122407': __('identifierSchemeCodeUnspsc190501.71122407'),
	'identifierSchemeCodeUnspsc190501.71122408': __('identifierSchemeCodeUnspsc190501.71122408'),
	'identifierSchemeCodeUnspsc190501.71122409': __('identifierSchemeCodeUnspsc190501.71122409'),
	'identifierSchemeCodeUnspsc190501.71122410': __('identifierSchemeCodeUnspsc190501.71122410'),
	'identifierSchemeCodeUnspsc190501.71122500': __('identifierSchemeCodeUnspsc190501.71122500'),
	'identifierSchemeCodeUnspsc190501.71122501': __('identifierSchemeCodeUnspsc190501.71122501'),
	'identifierSchemeCodeUnspsc190501.71122502': __('identifierSchemeCodeUnspsc190501.71122502'),
	'identifierSchemeCodeUnspsc190501.71122503': __('identifierSchemeCodeUnspsc190501.71122503'),
	'identifierSchemeCodeUnspsc190501.71122504': __('identifierSchemeCodeUnspsc190501.71122504'),
	'identifierSchemeCodeUnspsc190501.71122505': __('identifierSchemeCodeUnspsc190501.71122505'),
	'identifierSchemeCodeUnspsc190501.71122506': __('identifierSchemeCodeUnspsc190501.71122506'),
	'identifierSchemeCodeUnspsc190501.71122600': __('identifierSchemeCodeUnspsc190501.71122600'),
	'identifierSchemeCodeUnspsc190501.71122601': __('identifierSchemeCodeUnspsc190501.71122601'),
	'identifierSchemeCodeUnspsc190501.71122602': __('identifierSchemeCodeUnspsc190501.71122602'),
	'identifierSchemeCodeUnspsc190501.71122605': __('identifierSchemeCodeUnspsc190501.71122605'),
	'identifierSchemeCodeUnspsc190501.71122606': __('identifierSchemeCodeUnspsc190501.71122606'),
	'identifierSchemeCodeUnspsc190501.71122608': __('identifierSchemeCodeUnspsc190501.71122608'),
	'identifierSchemeCodeUnspsc190501.71122610': __('identifierSchemeCodeUnspsc190501.71122610'),
	'identifierSchemeCodeUnspsc190501.71122614': __('identifierSchemeCodeUnspsc190501.71122614'),
	'identifierSchemeCodeUnspsc190501.71122615': __('identifierSchemeCodeUnspsc190501.71122615'),
	'identifierSchemeCodeUnspsc190501.71122616': __('identifierSchemeCodeUnspsc190501.71122616'),
	'identifierSchemeCodeUnspsc190501.71122700': __('identifierSchemeCodeUnspsc190501.71122700'),
	'identifierSchemeCodeUnspsc190501.71122701': __('identifierSchemeCodeUnspsc190501.71122701'),
	'identifierSchemeCodeUnspsc190501.71122702': __('identifierSchemeCodeUnspsc190501.71122702'),
	'identifierSchemeCodeUnspsc190501.71122703': __('identifierSchemeCodeUnspsc190501.71122703'),
	'identifierSchemeCodeUnspsc190501.71122704': __('identifierSchemeCodeUnspsc190501.71122704'),
	'identifierSchemeCodeUnspsc190501.71122705': __('identifierSchemeCodeUnspsc190501.71122705'),
	'identifierSchemeCodeUnspsc190501.71122706': __('identifierSchemeCodeUnspsc190501.71122706'),
	'identifierSchemeCodeUnspsc190501.71122707': __('identifierSchemeCodeUnspsc190501.71122707'),
	'identifierSchemeCodeUnspsc190501.71122708': __('identifierSchemeCodeUnspsc190501.71122708'),
	'identifierSchemeCodeUnspsc190501.71122709': __('identifierSchemeCodeUnspsc190501.71122709'),
	'identifierSchemeCodeUnspsc190501.71122710': __('identifierSchemeCodeUnspsc190501.71122710'),
	'identifierSchemeCodeUnspsc190501.71122711': __('identifierSchemeCodeUnspsc190501.71122711'),
	'identifierSchemeCodeUnspsc190501.71122712': __('identifierSchemeCodeUnspsc190501.71122712'),
	'identifierSchemeCodeUnspsc190501.71122800': __('identifierSchemeCodeUnspsc190501.71122800'),
	'identifierSchemeCodeUnspsc190501.71122801': __('identifierSchemeCodeUnspsc190501.71122801'),
	'identifierSchemeCodeUnspsc190501.71122802': __('identifierSchemeCodeUnspsc190501.71122802'),
	'identifierSchemeCodeUnspsc190501.71122803': __('identifierSchemeCodeUnspsc190501.71122803'),
	'identifierSchemeCodeUnspsc190501.71122804': __('identifierSchemeCodeUnspsc190501.71122804'),
	'identifierSchemeCodeUnspsc190501.71122805': __('identifierSchemeCodeUnspsc190501.71122805'),
	'identifierSchemeCodeUnspsc190501.71122806': __('identifierSchemeCodeUnspsc190501.71122806'),
	'identifierSchemeCodeUnspsc190501.71122807': __('identifierSchemeCodeUnspsc190501.71122807'),
	'identifierSchemeCodeUnspsc190501.71122808': __('identifierSchemeCodeUnspsc190501.71122808'),
	'identifierSchemeCodeUnspsc190501.71122810': __('identifierSchemeCodeUnspsc190501.71122810'),
	'identifierSchemeCodeUnspsc190501.71122900': __('identifierSchemeCodeUnspsc190501.71122900'),
	'identifierSchemeCodeUnspsc190501.71122901': __('identifierSchemeCodeUnspsc190501.71122901'),
	'identifierSchemeCodeUnspsc190501.71122902': __('identifierSchemeCodeUnspsc190501.71122902'),
	'identifierSchemeCodeUnspsc190501.71122903': __('identifierSchemeCodeUnspsc190501.71122903'),
	'identifierSchemeCodeUnspsc190501.71122904': __('identifierSchemeCodeUnspsc190501.71122904'),
	'identifierSchemeCodeUnspsc190501.71122905': __('identifierSchemeCodeUnspsc190501.71122905'),
	'identifierSchemeCodeUnspsc190501.71123000': __('identifierSchemeCodeUnspsc190501.71123000'),
	'identifierSchemeCodeUnspsc190501.71123001': __('identifierSchemeCodeUnspsc190501.71123001'),
	'identifierSchemeCodeUnspsc190501.71123002': __('identifierSchemeCodeUnspsc190501.71123002'),
	'identifierSchemeCodeUnspsc190501.71123003': __('identifierSchemeCodeUnspsc190501.71123003'),
	'identifierSchemeCodeUnspsc190501.71123004': __('identifierSchemeCodeUnspsc190501.71123004'),
	'identifierSchemeCodeUnspsc190501.71123005': __('identifierSchemeCodeUnspsc190501.71123005'),
	'identifierSchemeCodeUnspsc190501.71123006': __('identifierSchemeCodeUnspsc190501.71123006'),
	'identifierSchemeCodeUnspsc190501.71123007': __('identifierSchemeCodeUnspsc190501.71123007'),
	'identifierSchemeCodeUnspsc190501.71130000': __('identifierSchemeCodeUnspsc190501.71130000'),
	'identifierSchemeCodeUnspsc190501.71131000': __('identifierSchemeCodeUnspsc190501.71131000'),
	'identifierSchemeCodeUnspsc190501.71131001': __('identifierSchemeCodeUnspsc190501.71131001'),
	'identifierSchemeCodeUnspsc190501.71131002': __('identifierSchemeCodeUnspsc190501.71131002'),
	'identifierSchemeCodeUnspsc190501.71131003': __('identifierSchemeCodeUnspsc190501.71131003'),
	'identifierSchemeCodeUnspsc190501.71131004': __('identifierSchemeCodeUnspsc190501.71131004'),
	'identifierSchemeCodeUnspsc190501.71131005': __('identifierSchemeCodeUnspsc190501.71131005'),
	'identifierSchemeCodeUnspsc190501.71131006': __('identifierSchemeCodeUnspsc190501.71131006'),
	'identifierSchemeCodeUnspsc190501.71131007': __('identifierSchemeCodeUnspsc190501.71131007'),
	'identifierSchemeCodeUnspsc190501.71131008': __('identifierSchemeCodeUnspsc190501.71131008'),
	'identifierSchemeCodeUnspsc190501.71131009': __('identifierSchemeCodeUnspsc190501.71131009'),
	'identifierSchemeCodeUnspsc190501.71131010': __('identifierSchemeCodeUnspsc190501.71131010'),
	'identifierSchemeCodeUnspsc190501.71131011': __('identifierSchemeCodeUnspsc190501.71131011'),
	'identifierSchemeCodeUnspsc190501.71131012': __('identifierSchemeCodeUnspsc190501.71131012'),
	'identifierSchemeCodeUnspsc190501.71131013': __('identifierSchemeCodeUnspsc190501.71131013'),
	'identifierSchemeCodeUnspsc190501.71131014': __('identifierSchemeCodeUnspsc190501.71131014'),
	'identifierSchemeCodeUnspsc190501.71131015': __('identifierSchemeCodeUnspsc190501.71131015'),
	'identifierSchemeCodeUnspsc190501.71131016': __('identifierSchemeCodeUnspsc190501.71131016'),
	'identifierSchemeCodeUnspsc190501.71131018': __('identifierSchemeCodeUnspsc190501.71131018'),
	'identifierSchemeCodeUnspsc190501.71131019': __('identifierSchemeCodeUnspsc190501.71131019'),
	'identifierSchemeCodeUnspsc190501.71131100': __('identifierSchemeCodeUnspsc190501.71131100'),
	'identifierSchemeCodeUnspsc190501.71131101': __('identifierSchemeCodeUnspsc190501.71131101'),
	'identifierSchemeCodeUnspsc190501.71131102': __('identifierSchemeCodeUnspsc190501.71131102'),
	'identifierSchemeCodeUnspsc190501.71131103': __('identifierSchemeCodeUnspsc190501.71131103'),
	'identifierSchemeCodeUnspsc190501.71131104': __('identifierSchemeCodeUnspsc190501.71131104'),
	'identifierSchemeCodeUnspsc190501.71131105': __('identifierSchemeCodeUnspsc190501.71131105'),
	'identifierSchemeCodeUnspsc190501.71131106': __('identifierSchemeCodeUnspsc190501.71131106'),
	'identifierSchemeCodeUnspsc190501.71131107': __('identifierSchemeCodeUnspsc190501.71131107'),
	'identifierSchemeCodeUnspsc190501.71131108': __('identifierSchemeCodeUnspsc190501.71131108'),
	'identifierSchemeCodeUnspsc190501.71131109': __('identifierSchemeCodeUnspsc190501.71131109'),
	'identifierSchemeCodeUnspsc190501.71131110': __('identifierSchemeCodeUnspsc190501.71131110'),
	'identifierSchemeCodeUnspsc190501.71131111': __('identifierSchemeCodeUnspsc190501.71131111'),
	'identifierSchemeCodeUnspsc190501.71131200': __('identifierSchemeCodeUnspsc190501.71131200'),
	'identifierSchemeCodeUnspsc190501.71131201': __('identifierSchemeCodeUnspsc190501.71131201'),
	'identifierSchemeCodeUnspsc190501.71131300': __('identifierSchemeCodeUnspsc190501.71131300'),
	'identifierSchemeCodeUnspsc190501.71131301': __('identifierSchemeCodeUnspsc190501.71131301'),
	'identifierSchemeCodeUnspsc190501.71131302': __('identifierSchemeCodeUnspsc190501.71131302'),
	'identifierSchemeCodeUnspsc190501.71131303': __('identifierSchemeCodeUnspsc190501.71131303'),
	'identifierSchemeCodeUnspsc190501.71131304': __('identifierSchemeCodeUnspsc190501.71131304'),
	'identifierSchemeCodeUnspsc190501.71131305': __('identifierSchemeCodeUnspsc190501.71131305'),
	'identifierSchemeCodeUnspsc190501.71131306': __('identifierSchemeCodeUnspsc190501.71131306'),
	'identifierSchemeCodeUnspsc190501.71131307': __('identifierSchemeCodeUnspsc190501.71131307'),
	'identifierSchemeCodeUnspsc190501.71131308': __('identifierSchemeCodeUnspsc190501.71131308'),
	'identifierSchemeCodeUnspsc190501.71131309': __('identifierSchemeCodeUnspsc190501.71131309'),
	'identifierSchemeCodeUnspsc190501.71131310': __('identifierSchemeCodeUnspsc190501.71131310'),
	'identifierSchemeCodeUnspsc190501.71131311': __('identifierSchemeCodeUnspsc190501.71131311'),
	'identifierSchemeCodeUnspsc190501.71131312': __('identifierSchemeCodeUnspsc190501.71131312'),
	'identifierSchemeCodeUnspsc190501.71131313': __('identifierSchemeCodeUnspsc190501.71131313'),
	'identifierSchemeCodeUnspsc190501.71131400': __('identifierSchemeCodeUnspsc190501.71131400'),
	'identifierSchemeCodeUnspsc190501.71131401': __('identifierSchemeCodeUnspsc190501.71131401'),
	'identifierSchemeCodeUnspsc190501.71131402': __('identifierSchemeCodeUnspsc190501.71131402'),
	'identifierSchemeCodeUnspsc190501.71131403': __('identifierSchemeCodeUnspsc190501.71131403'),
	'identifierSchemeCodeUnspsc190501.71131404': __('identifierSchemeCodeUnspsc190501.71131404'),
	'identifierSchemeCodeUnspsc190501.71131405': __('identifierSchemeCodeUnspsc190501.71131405'),
	'identifierSchemeCodeUnspsc190501.71131406': __('identifierSchemeCodeUnspsc190501.71131406'),
	'identifierSchemeCodeUnspsc190501.71131407': __('identifierSchemeCodeUnspsc190501.71131407'),
	'identifierSchemeCodeUnspsc190501.71131408': __('identifierSchemeCodeUnspsc190501.71131408'),
	'identifierSchemeCodeUnspsc190501.71131409': __('identifierSchemeCodeUnspsc190501.71131409'),
	'identifierSchemeCodeUnspsc190501.71131410': __('identifierSchemeCodeUnspsc190501.71131410'),
	'identifierSchemeCodeUnspsc190501.71131411': __('identifierSchemeCodeUnspsc190501.71131411'),
	'identifierSchemeCodeUnspsc190501.71131412': __('identifierSchemeCodeUnspsc190501.71131412'),
	'identifierSchemeCodeUnspsc190501.71131413': __('identifierSchemeCodeUnspsc190501.71131413'),
	'identifierSchemeCodeUnspsc190501.71131414': __('identifierSchemeCodeUnspsc190501.71131414'),
	'identifierSchemeCodeUnspsc190501.71131415': __('identifierSchemeCodeUnspsc190501.71131415'),
	'identifierSchemeCodeUnspsc190501.71131416': __('identifierSchemeCodeUnspsc190501.71131416'),
	'identifierSchemeCodeUnspsc190501.71131417': __('identifierSchemeCodeUnspsc190501.71131417'),
	'identifierSchemeCodeUnspsc190501.71131418': __('identifierSchemeCodeUnspsc190501.71131418'),
	'identifierSchemeCodeUnspsc190501.71131419': __('identifierSchemeCodeUnspsc190501.71131419'),
	'identifierSchemeCodeUnspsc190501.71131420': __('identifierSchemeCodeUnspsc190501.71131420'),
	'identifierSchemeCodeUnspsc190501.71140000': __('identifierSchemeCodeUnspsc190501.71140000'),
	'identifierSchemeCodeUnspsc190501.71141000': __('identifierSchemeCodeUnspsc190501.71141000'),
	'identifierSchemeCodeUnspsc190501.71141001': __('identifierSchemeCodeUnspsc190501.71141001'),
	'identifierSchemeCodeUnspsc190501.71141002': __('identifierSchemeCodeUnspsc190501.71141002'),
	'identifierSchemeCodeUnspsc190501.71141003': __('identifierSchemeCodeUnspsc190501.71141003'),
	'identifierSchemeCodeUnspsc190501.71141004': __('identifierSchemeCodeUnspsc190501.71141004'),
	'identifierSchemeCodeUnspsc190501.71141005': __('identifierSchemeCodeUnspsc190501.71141005'),
	'identifierSchemeCodeUnspsc190501.71141006': __('identifierSchemeCodeUnspsc190501.71141006'),
	'identifierSchemeCodeUnspsc190501.71141007': __('identifierSchemeCodeUnspsc190501.71141007'),
	'identifierSchemeCodeUnspsc190501.71141008': __('identifierSchemeCodeUnspsc190501.71141008'),
	'identifierSchemeCodeUnspsc190501.71141100': __('identifierSchemeCodeUnspsc190501.71141100'),
	'identifierSchemeCodeUnspsc190501.71141101': __('identifierSchemeCodeUnspsc190501.71141101'),
	'identifierSchemeCodeUnspsc190501.71141102': __('identifierSchemeCodeUnspsc190501.71141102'),
	'identifierSchemeCodeUnspsc190501.71141103': __('identifierSchemeCodeUnspsc190501.71141103'),
	'identifierSchemeCodeUnspsc190501.71141104': __('identifierSchemeCodeUnspsc190501.71141104'),
	'identifierSchemeCodeUnspsc190501.71141200': __('identifierSchemeCodeUnspsc190501.71141200'),
	'identifierSchemeCodeUnspsc190501.71141201': __('identifierSchemeCodeUnspsc190501.71141201'),
	'identifierSchemeCodeUnspsc190501.71141202': __('identifierSchemeCodeUnspsc190501.71141202'),
	'identifierSchemeCodeUnspsc190501.71150000': __('identifierSchemeCodeUnspsc190501.71150000'),
	'identifierSchemeCodeUnspsc190501.71151000': __('identifierSchemeCodeUnspsc190501.71151000'),
	'identifierSchemeCodeUnspsc190501.71151001': __('identifierSchemeCodeUnspsc190501.71151001'),
	'identifierSchemeCodeUnspsc190501.71151002': __('identifierSchemeCodeUnspsc190501.71151002'),
	'identifierSchemeCodeUnspsc190501.71151003': __('identifierSchemeCodeUnspsc190501.71151003'),
	'identifierSchemeCodeUnspsc190501.71151004': __('identifierSchemeCodeUnspsc190501.71151004'),
	'identifierSchemeCodeUnspsc190501.71151005': __('identifierSchemeCodeUnspsc190501.71151005'),
	'identifierSchemeCodeUnspsc190501.71151007': __('identifierSchemeCodeUnspsc190501.71151007'),
	'identifierSchemeCodeUnspsc190501.71151100': __('identifierSchemeCodeUnspsc190501.71151100'),
	'identifierSchemeCodeUnspsc190501.71151101': __('identifierSchemeCodeUnspsc190501.71151101'),
	'identifierSchemeCodeUnspsc190501.71151102': __('identifierSchemeCodeUnspsc190501.71151102'),
	'identifierSchemeCodeUnspsc190501.71151103': __('identifierSchemeCodeUnspsc190501.71151103'),
	'identifierSchemeCodeUnspsc190501.71151104': __('identifierSchemeCodeUnspsc190501.71151104'),
	'identifierSchemeCodeUnspsc190501.71151105': __('identifierSchemeCodeUnspsc190501.71151105'),
	'identifierSchemeCodeUnspsc190501.71151106': __('identifierSchemeCodeUnspsc190501.71151106'),
	'identifierSchemeCodeUnspsc190501.71151200': __('identifierSchemeCodeUnspsc190501.71151200'),
	'identifierSchemeCodeUnspsc190501.71151201': __('identifierSchemeCodeUnspsc190501.71151201'),
	'identifierSchemeCodeUnspsc190501.71151202': __('identifierSchemeCodeUnspsc190501.71151202'),
	'identifierSchemeCodeUnspsc190501.71151203': __('identifierSchemeCodeUnspsc190501.71151203'),
	'identifierSchemeCodeUnspsc190501.71151300': __('identifierSchemeCodeUnspsc190501.71151300'),
	'identifierSchemeCodeUnspsc190501.71151301': __('identifierSchemeCodeUnspsc190501.71151301'),
	'identifierSchemeCodeUnspsc190501.71151302': __('identifierSchemeCodeUnspsc190501.71151302'),
	'identifierSchemeCodeUnspsc190501.71151303': __('identifierSchemeCodeUnspsc190501.71151303'),
	'identifierSchemeCodeUnspsc190501.71151304': __('identifierSchemeCodeUnspsc190501.71151304'),
	'identifierSchemeCodeUnspsc190501.71151305': __('identifierSchemeCodeUnspsc190501.71151305'),
	'identifierSchemeCodeUnspsc190501.71151306': __('identifierSchemeCodeUnspsc190501.71151306'),
	'identifierSchemeCodeUnspsc190501.71151307': __('identifierSchemeCodeUnspsc190501.71151307'),
	'identifierSchemeCodeUnspsc190501.71151308': __('identifierSchemeCodeUnspsc190501.71151308'),
	'identifierSchemeCodeUnspsc190501.71151309': __('identifierSchemeCodeUnspsc190501.71151309'),
	'identifierSchemeCodeUnspsc190501.71151310': __('identifierSchemeCodeUnspsc190501.71151310'),
	'identifierSchemeCodeUnspsc190501.71151311': __('identifierSchemeCodeUnspsc190501.71151311'),
	'identifierSchemeCodeUnspsc190501.71151315': __('identifierSchemeCodeUnspsc190501.71151315'),
	'identifierSchemeCodeUnspsc190501.71151316': __('identifierSchemeCodeUnspsc190501.71151316'),
	'identifierSchemeCodeUnspsc190501.71151317': __('identifierSchemeCodeUnspsc190501.71151317'),
	'identifierSchemeCodeUnspsc190501.71151318': __('identifierSchemeCodeUnspsc190501.71151318'),
	'identifierSchemeCodeUnspsc190501.71151319': __('identifierSchemeCodeUnspsc190501.71151319'),
	'identifierSchemeCodeUnspsc190501.71151320': __('identifierSchemeCodeUnspsc190501.71151320'),
	'identifierSchemeCodeUnspsc190501.71151321': __('identifierSchemeCodeUnspsc190501.71151321'),
	'identifierSchemeCodeUnspsc190501.71151322': __('identifierSchemeCodeUnspsc190501.71151322'),
	'identifierSchemeCodeUnspsc190501.71151323': __('identifierSchemeCodeUnspsc190501.71151323'),
	'identifierSchemeCodeUnspsc190501.71151324': __('identifierSchemeCodeUnspsc190501.71151324'),
	'identifierSchemeCodeUnspsc190501.71151325': __('identifierSchemeCodeUnspsc190501.71151325'),
	'identifierSchemeCodeUnspsc190501.71151326': __('identifierSchemeCodeUnspsc190501.71151326'),
	'identifierSchemeCodeUnspsc190501.71151327': __('identifierSchemeCodeUnspsc190501.71151327'),
	'identifierSchemeCodeUnspsc190501.71151400': __('identifierSchemeCodeUnspsc190501.71151400'),
	'identifierSchemeCodeUnspsc190501.71151401': __('identifierSchemeCodeUnspsc190501.71151401'),
	'identifierSchemeCodeUnspsc190501.71151402': __('identifierSchemeCodeUnspsc190501.71151402'),
	'identifierSchemeCodeUnspsc190501.71151403': __('identifierSchemeCodeUnspsc190501.71151403'),
	'identifierSchemeCodeUnspsc190501.71151404': __('identifierSchemeCodeUnspsc190501.71151404'),
	'identifierSchemeCodeUnspsc190501.71151405': __('identifierSchemeCodeUnspsc190501.71151405'),
	'identifierSchemeCodeUnspsc190501.71151406': __('identifierSchemeCodeUnspsc190501.71151406'),
	'identifierSchemeCodeUnspsc190501.71160000': __('identifierSchemeCodeUnspsc190501.71160000'),
	'identifierSchemeCodeUnspsc190501.71161000': __('identifierSchemeCodeUnspsc190501.71161000'),
	'identifierSchemeCodeUnspsc190501.71161001': __('identifierSchemeCodeUnspsc190501.71161001'),
	'identifierSchemeCodeUnspsc190501.71161002': __('identifierSchemeCodeUnspsc190501.71161002'),
	'identifierSchemeCodeUnspsc190501.71161003': __('identifierSchemeCodeUnspsc190501.71161003'),
	'identifierSchemeCodeUnspsc190501.71161004': __('identifierSchemeCodeUnspsc190501.71161004'),
	'identifierSchemeCodeUnspsc190501.71161005': __('identifierSchemeCodeUnspsc190501.71161005'),
	'identifierSchemeCodeUnspsc190501.71161006': __('identifierSchemeCodeUnspsc190501.71161006'),
	'identifierSchemeCodeUnspsc190501.71161007': __('identifierSchemeCodeUnspsc190501.71161007'),
	'identifierSchemeCodeUnspsc190501.71161008': __('identifierSchemeCodeUnspsc190501.71161008'),
	'identifierSchemeCodeUnspsc190501.71161100': __('identifierSchemeCodeUnspsc190501.71161100'),
	'identifierSchemeCodeUnspsc190501.71161101': __('identifierSchemeCodeUnspsc190501.71161101'),
	'identifierSchemeCodeUnspsc190501.71161102': __('identifierSchemeCodeUnspsc190501.71161102'),
	'identifierSchemeCodeUnspsc190501.71161103': __('identifierSchemeCodeUnspsc190501.71161103'),
	'identifierSchemeCodeUnspsc190501.71161104': __('identifierSchemeCodeUnspsc190501.71161104'),
	'identifierSchemeCodeUnspsc190501.71161105': __('identifierSchemeCodeUnspsc190501.71161105'),
	'identifierSchemeCodeUnspsc190501.71161106': __('identifierSchemeCodeUnspsc190501.71161106'),
	'identifierSchemeCodeUnspsc190501.71161107': __('identifierSchemeCodeUnspsc190501.71161107'),
	'identifierSchemeCodeUnspsc190501.71161109': __('identifierSchemeCodeUnspsc190501.71161109'),
	'identifierSchemeCodeUnspsc190501.71161110': __('identifierSchemeCodeUnspsc190501.71161110'),
	'identifierSchemeCodeUnspsc190501.71161111': __('identifierSchemeCodeUnspsc190501.71161111'),
	'identifierSchemeCodeUnspsc190501.71161200': __('identifierSchemeCodeUnspsc190501.71161200'),
	'identifierSchemeCodeUnspsc190501.71161201': __('identifierSchemeCodeUnspsc190501.71161201'),
	'identifierSchemeCodeUnspsc190501.71161202': __('identifierSchemeCodeUnspsc190501.71161202'),
	'identifierSchemeCodeUnspsc190501.71161203': __('identifierSchemeCodeUnspsc190501.71161203'),
	'identifierSchemeCodeUnspsc190501.71161204': __('identifierSchemeCodeUnspsc190501.71161204'),
	'identifierSchemeCodeUnspsc190501.71161205': __('identifierSchemeCodeUnspsc190501.71161205'),
	'identifierSchemeCodeUnspsc190501.71161206': __('identifierSchemeCodeUnspsc190501.71161206'),
	'identifierSchemeCodeUnspsc190501.71161300': __('identifierSchemeCodeUnspsc190501.71161300'),
	'identifierSchemeCodeUnspsc190501.71161301': __('identifierSchemeCodeUnspsc190501.71161301'),
	'identifierSchemeCodeUnspsc190501.71161302': __('identifierSchemeCodeUnspsc190501.71161302'),
	'identifierSchemeCodeUnspsc190501.71161303': __('identifierSchemeCodeUnspsc190501.71161303'),
	'identifierSchemeCodeUnspsc190501.71161304': __('identifierSchemeCodeUnspsc190501.71161304'),
	'identifierSchemeCodeUnspsc190501.71161305': __('identifierSchemeCodeUnspsc190501.71161305'),
	'identifierSchemeCodeUnspsc190501.71161306': __('identifierSchemeCodeUnspsc190501.71161306'),
	'identifierSchemeCodeUnspsc190501.71161307': __('identifierSchemeCodeUnspsc190501.71161307'),
	'identifierSchemeCodeUnspsc190501.71161308': __('identifierSchemeCodeUnspsc190501.71161308'),
	'identifierSchemeCodeUnspsc190501.71161400': __('identifierSchemeCodeUnspsc190501.71161400'),
	'identifierSchemeCodeUnspsc190501.71161402': __('identifierSchemeCodeUnspsc190501.71161402'),
	'identifierSchemeCodeUnspsc190501.71161403': __('identifierSchemeCodeUnspsc190501.71161403'),
	'identifierSchemeCodeUnspsc190501.71161405': __('identifierSchemeCodeUnspsc190501.71161405'),
	'identifierSchemeCodeUnspsc190501.71161407': __('identifierSchemeCodeUnspsc190501.71161407'),
	'identifierSchemeCodeUnspsc190501.71161408': __('identifierSchemeCodeUnspsc190501.71161408'),
	'identifierSchemeCodeUnspsc190501.71161409': __('identifierSchemeCodeUnspsc190501.71161409'),
	'identifierSchemeCodeUnspsc190501.71161410': __('identifierSchemeCodeUnspsc190501.71161410'),
	'identifierSchemeCodeUnspsc190501.71161411': __('identifierSchemeCodeUnspsc190501.71161411'),
	'identifierSchemeCodeUnspsc190501.71161413': __('identifierSchemeCodeUnspsc190501.71161413'),
	'identifierSchemeCodeUnspsc190501.71161500': __('identifierSchemeCodeUnspsc190501.71161500'),
	'identifierSchemeCodeUnspsc190501.71161503': __('identifierSchemeCodeUnspsc190501.71161503'),
	'identifierSchemeCodeUnspsc190501.71161600': __('identifierSchemeCodeUnspsc190501.71161600'),
	'identifierSchemeCodeUnspsc190501.71161601': __('identifierSchemeCodeUnspsc190501.71161601'),
	'identifierSchemeCodeUnspsc190501.71161602': __('identifierSchemeCodeUnspsc190501.71161602'),
	'identifierSchemeCodeUnspsc190501.71161603': __('identifierSchemeCodeUnspsc190501.71161603'),
	'identifierSchemeCodeUnspsc190501.71161604': __('identifierSchemeCodeUnspsc190501.71161604'),
	'identifierSchemeCodeUnspsc190501.71161605': __('identifierSchemeCodeUnspsc190501.71161605'),
	'identifierSchemeCodeUnspsc190501.71161606': __('identifierSchemeCodeUnspsc190501.71161606'),
	'identifierSchemeCodeUnspsc190501.72000000': __('identifierSchemeCodeUnspsc190501.72000000'),
	'identifierSchemeCodeUnspsc190501.72100000': __('identifierSchemeCodeUnspsc190501.72100000'),
	'identifierSchemeCodeUnspsc190501.72101500': __('identifierSchemeCodeUnspsc190501.72101500'),
	'identifierSchemeCodeUnspsc190501.72101501': __('identifierSchemeCodeUnspsc190501.72101501'),
	'identifierSchemeCodeUnspsc190501.72101504': __('identifierSchemeCodeUnspsc190501.72101504'),
	'identifierSchemeCodeUnspsc190501.72101505': __('identifierSchemeCodeUnspsc190501.72101505'),
	'identifierSchemeCodeUnspsc190501.72101506': __('identifierSchemeCodeUnspsc190501.72101506'),
	'identifierSchemeCodeUnspsc190501.72101507': __('identifierSchemeCodeUnspsc190501.72101507'),
	'identifierSchemeCodeUnspsc190501.72101508': __('identifierSchemeCodeUnspsc190501.72101508'),
	'identifierSchemeCodeUnspsc190501.72101509': __('identifierSchemeCodeUnspsc190501.72101509'),
	'identifierSchemeCodeUnspsc190501.72101510': __('identifierSchemeCodeUnspsc190501.72101510'),
	'identifierSchemeCodeUnspsc190501.72101511': __('identifierSchemeCodeUnspsc190501.72101511'),
	'identifierSchemeCodeUnspsc190501.72101512': __('identifierSchemeCodeUnspsc190501.72101512'),
	'identifierSchemeCodeUnspsc190501.72101513': __('identifierSchemeCodeUnspsc190501.72101513'),
	'identifierSchemeCodeUnspsc190501.72101514': __('identifierSchemeCodeUnspsc190501.72101514'),
	'identifierSchemeCodeUnspsc190501.72101515': __('identifierSchemeCodeUnspsc190501.72101515'),
	'identifierSchemeCodeUnspsc190501.72101516': __('identifierSchemeCodeUnspsc190501.72101516'),
	'identifierSchemeCodeUnspsc190501.72101517': __('identifierSchemeCodeUnspsc190501.72101517'),
	'identifierSchemeCodeUnspsc190501.72101518': __('identifierSchemeCodeUnspsc190501.72101518'),
	'identifierSchemeCodeUnspsc190501.72101519': __('identifierSchemeCodeUnspsc190501.72101519'),
	'identifierSchemeCodeUnspsc190501.72101520': __('identifierSchemeCodeUnspsc190501.72101520'),
	'identifierSchemeCodeUnspsc190501.72101521': __('identifierSchemeCodeUnspsc190501.72101521'),
	'identifierSchemeCodeUnspsc190501.72102100': __('identifierSchemeCodeUnspsc190501.72102100'),
	'identifierSchemeCodeUnspsc190501.72102101': __('identifierSchemeCodeUnspsc190501.72102101'),
	'identifierSchemeCodeUnspsc190501.72102102': __('identifierSchemeCodeUnspsc190501.72102102'),
	'identifierSchemeCodeUnspsc190501.72102103': __('identifierSchemeCodeUnspsc190501.72102103'),
	'identifierSchemeCodeUnspsc190501.72102104': __('identifierSchemeCodeUnspsc190501.72102104'),
	'identifierSchemeCodeUnspsc190501.72102105': __('identifierSchemeCodeUnspsc190501.72102105'),
	'identifierSchemeCodeUnspsc190501.72102106': __('identifierSchemeCodeUnspsc190501.72102106'),
	'identifierSchemeCodeUnspsc190501.72102900': __('identifierSchemeCodeUnspsc190501.72102900'),
	'identifierSchemeCodeUnspsc190501.72102902': __('identifierSchemeCodeUnspsc190501.72102902'),
	'identifierSchemeCodeUnspsc190501.72102903': __('identifierSchemeCodeUnspsc190501.72102903'),
	'identifierSchemeCodeUnspsc190501.72102905': __('identifierSchemeCodeUnspsc190501.72102905'),
	'identifierSchemeCodeUnspsc190501.72102906': __('identifierSchemeCodeUnspsc190501.72102906'),
	'identifierSchemeCodeUnspsc190501.72103100': __('identifierSchemeCodeUnspsc190501.72103100'),
	'identifierSchemeCodeUnspsc190501.72103101': __('identifierSchemeCodeUnspsc190501.72103101'),
	'identifierSchemeCodeUnspsc190501.72103102': __('identifierSchemeCodeUnspsc190501.72103102'),
	'identifierSchemeCodeUnspsc190501.72103103': __('identifierSchemeCodeUnspsc190501.72103103'),
	'identifierSchemeCodeUnspsc190501.72103104': __('identifierSchemeCodeUnspsc190501.72103104'),
	'identifierSchemeCodeUnspsc190501.72103300': __('identifierSchemeCodeUnspsc190501.72103300'),
	'identifierSchemeCodeUnspsc190501.72103301': __('identifierSchemeCodeUnspsc190501.72103301'),
	'identifierSchemeCodeUnspsc190501.72103302': __('identifierSchemeCodeUnspsc190501.72103302'),
	'identifierSchemeCodeUnspsc190501.72103304': __('identifierSchemeCodeUnspsc190501.72103304'),
	'identifierSchemeCodeUnspsc190501.72103305': __('identifierSchemeCodeUnspsc190501.72103305'),
	'identifierSchemeCodeUnspsc190501.72110000': __('identifierSchemeCodeUnspsc190501.72110000'),
	'identifierSchemeCodeUnspsc190501.72111000': __('identifierSchemeCodeUnspsc190501.72111000'),
	'identifierSchemeCodeUnspsc190501.72111001': __('identifierSchemeCodeUnspsc190501.72111001'),
	'identifierSchemeCodeUnspsc190501.72111002': __('identifierSchemeCodeUnspsc190501.72111002'),
	'identifierSchemeCodeUnspsc190501.72111003': __('identifierSchemeCodeUnspsc190501.72111003'),
	'identifierSchemeCodeUnspsc190501.72111004': __('identifierSchemeCodeUnspsc190501.72111004'),
	'identifierSchemeCodeUnspsc190501.72111005': __('identifierSchemeCodeUnspsc190501.72111005'),
	'identifierSchemeCodeUnspsc190501.72111006': __('identifierSchemeCodeUnspsc190501.72111006'),
	'identifierSchemeCodeUnspsc190501.72111007': __('identifierSchemeCodeUnspsc190501.72111007'),
	'identifierSchemeCodeUnspsc190501.72111008': __('identifierSchemeCodeUnspsc190501.72111008'),
	'identifierSchemeCodeUnspsc190501.72111100': __('identifierSchemeCodeUnspsc190501.72111100'),
	'identifierSchemeCodeUnspsc190501.72111101': __('identifierSchemeCodeUnspsc190501.72111101'),
	'identifierSchemeCodeUnspsc190501.72111102': __('identifierSchemeCodeUnspsc190501.72111102'),
	'identifierSchemeCodeUnspsc190501.72111103': __('identifierSchemeCodeUnspsc190501.72111103'),
	'identifierSchemeCodeUnspsc190501.72111104': __('identifierSchemeCodeUnspsc190501.72111104'),
	'identifierSchemeCodeUnspsc190501.72111105': __('identifierSchemeCodeUnspsc190501.72111105'),
	'identifierSchemeCodeUnspsc190501.72111106': __('identifierSchemeCodeUnspsc190501.72111106'),
	'identifierSchemeCodeUnspsc190501.72111107': __('identifierSchemeCodeUnspsc190501.72111107'),
	'identifierSchemeCodeUnspsc190501.72111108': __('identifierSchemeCodeUnspsc190501.72111108'),
	'identifierSchemeCodeUnspsc190501.72111109': __('identifierSchemeCodeUnspsc190501.72111109'),
	'identifierSchemeCodeUnspsc190501.72111110': __('identifierSchemeCodeUnspsc190501.72111110'),
	'identifierSchemeCodeUnspsc190501.72111111': __('identifierSchemeCodeUnspsc190501.72111111'),
	'identifierSchemeCodeUnspsc190501.72120000': __('identifierSchemeCodeUnspsc190501.72120000'),
	'identifierSchemeCodeUnspsc190501.72121000': __('identifierSchemeCodeUnspsc190501.72121000'),
	'identifierSchemeCodeUnspsc190501.72121001': __('identifierSchemeCodeUnspsc190501.72121001'),
	'identifierSchemeCodeUnspsc190501.72121002': __('identifierSchemeCodeUnspsc190501.72121002'),
	'identifierSchemeCodeUnspsc190501.72121003': __('identifierSchemeCodeUnspsc190501.72121003'),
	'identifierSchemeCodeUnspsc190501.72121004': __('identifierSchemeCodeUnspsc190501.72121004'),
	'identifierSchemeCodeUnspsc190501.72121005': __('identifierSchemeCodeUnspsc190501.72121005'),
	'identifierSchemeCodeUnspsc190501.72121006': __('identifierSchemeCodeUnspsc190501.72121006'),
	'identifierSchemeCodeUnspsc190501.72121007': __('identifierSchemeCodeUnspsc190501.72121007'),
	'identifierSchemeCodeUnspsc190501.72121008': __('identifierSchemeCodeUnspsc190501.72121008'),
	'identifierSchemeCodeUnspsc190501.72121100': __('identifierSchemeCodeUnspsc190501.72121100'),
	'identifierSchemeCodeUnspsc190501.72121101': __('identifierSchemeCodeUnspsc190501.72121101'),
	'identifierSchemeCodeUnspsc190501.72121102': __('identifierSchemeCodeUnspsc190501.72121102'),
	'identifierSchemeCodeUnspsc190501.72121103': __('identifierSchemeCodeUnspsc190501.72121103'),
	'identifierSchemeCodeUnspsc190501.72121104': __('identifierSchemeCodeUnspsc190501.72121104'),
	'identifierSchemeCodeUnspsc190501.72121105': __('identifierSchemeCodeUnspsc190501.72121105'),
	'identifierSchemeCodeUnspsc190501.72121200': __('identifierSchemeCodeUnspsc190501.72121200'),
	'identifierSchemeCodeUnspsc190501.72121201': __('identifierSchemeCodeUnspsc190501.72121201'),
	'identifierSchemeCodeUnspsc190501.72121202': __('identifierSchemeCodeUnspsc190501.72121202'),
	'identifierSchemeCodeUnspsc190501.72121203': __('identifierSchemeCodeUnspsc190501.72121203'),
	'identifierSchemeCodeUnspsc190501.72121300': __('identifierSchemeCodeUnspsc190501.72121300'),
	'identifierSchemeCodeUnspsc190501.72121301': __('identifierSchemeCodeUnspsc190501.72121301'),
	'identifierSchemeCodeUnspsc190501.72121302': __('identifierSchemeCodeUnspsc190501.72121302'),
	'identifierSchemeCodeUnspsc190501.72121400': __('identifierSchemeCodeUnspsc190501.72121400'),
	'identifierSchemeCodeUnspsc190501.72121401': __('identifierSchemeCodeUnspsc190501.72121401'),
	'identifierSchemeCodeUnspsc190501.72121402': __('identifierSchemeCodeUnspsc190501.72121402'),
	'identifierSchemeCodeUnspsc190501.72121403': __('identifierSchemeCodeUnspsc190501.72121403'),
	'identifierSchemeCodeUnspsc190501.72121404': __('identifierSchemeCodeUnspsc190501.72121404'),
	'identifierSchemeCodeUnspsc190501.72121405': __('identifierSchemeCodeUnspsc190501.72121405'),
	'identifierSchemeCodeUnspsc190501.72121406': __('identifierSchemeCodeUnspsc190501.72121406'),
	'identifierSchemeCodeUnspsc190501.72121407': __('identifierSchemeCodeUnspsc190501.72121407'),
	'identifierSchemeCodeUnspsc190501.72121408': __('identifierSchemeCodeUnspsc190501.72121408'),
	'identifierSchemeCodeUnspsc190501.72121409': __('identifierSchemeCodeUnspsc190501.72121409'),
	'identifierSchemeCodeUnspsc190501.72121410': __('identifierSchemeCodeUnspsc190501.72121410'),
	'identifierSchemeCodeUnspsc190501.72121500': __('identifierSchemeCodeUnspsc190501.72121500'),
	'identifierSchemeCodeUnspsc190501.72121501': __('identifierSchemeCodeUnspsc190501.72121501'),
	'identifierSchemeCodeUnspsc190501.72121502': __('identifierSchemeCodeUnspsc190501.72121502'),
	'identifierSchemeCodeUnspsc190501.72121503': __('identifierSchemeCodeUnspsc190501.72121503'),
	'identifierSchemeCodeUnspsc190501.72121504': __('identifierSchemeCodeUnspsc190501.72121504'),
	'identifierSchemeCodeUnspsc190501.72121505': __('identifierSchemeCodeUnspsc190501.72121505'),
	'identifierSchemeCodeUnspsc190501.72121506': __('identifierSchemeCodeUnspsc190501.72121506'),
	'identifierSchemeCodeUnspsc190501.72121507': __('identifierSchemeCodeUnspsc190501.72121507'),
	'identifierSchemeCodeUnspsc190501.72121508': __('identifierSchemeCodeUnspsc190501.72121508'),
	'identifierSchemeCodeUnspsc190501.72121509': __('identifierSchemeCodeUnspsc190501.72121509'),
	'identifierSchemeCodeUnspsc190501.72121510': __('identifierSchemeCodeUnspsc190501.72121510'),
	'identifierSchemeCodeUnspsc190501.72121511': __('identifierSchemeCodeUnspsc190501.72121511'),
	'identifierSchemeCodeUnspsc190501.72121512': __('identifierSchemeCodeUnspsc190501.72121512'),
	'identifierSchemeCodeUnspsc190501.72121513': __('identifierSchemeCodeUnspsc190501.72121513'),
	'identifierSchemeCodeUnspsc190501.72121514': __('identifierSchemeCodeUnspsc190501.72121514'),
	'identifierSchemeCodeUnspsc190501.72121515': __('identifierSchemeCodeUnspsc190501.72121515'),
	'identifierSchemeCodeUnspsc190501.72121516': __('identifierSchemeCodeUnspsc190501.72121516'),
	'identifierSchemeCodeUnspsc190501.72121517': __('identifierSchemeCodeUnspsc190501.72121517'),
	'identifierSchemeCodeUnspsc190501.72140000': __('identifierSchemeCodeUnspsc190501.72140000'),
	'identifierSchemeCodeUnspsc190501.72141000': __('identifierSchemeCodeUnspsc190501.72141000'),
	'identifierSchemeCodeUnspsc190501.72141001': __('identifierSchemeCodeUnspsc190501.72141001'),
	'identifierSchemeCodeUnspsc190501.72141002': __('identifierSchemeCodeUnspsc190501.72141002'),
	'identifierSchemeCodeUnspsc190501.72141003': __('identifierSchemeCodeUnspsc190501.72141003'),
	'identifierSchemeCodeUnspsc190501.72141004': __('identifierSchemeCodeUnspsc190501.72141004'),
	'identifierSchemeCodeUnspsc190501.72141100': __('identifierSchemeCodeUnspsc190501.72141100'),
	'identifierSchemeCodeUnspsc190501.72141101': __('identifierSchemeCodeUnspsc190501.72141101'),
	'identifierSchemeCodeUnspsc190501.72141102': __('identifierSchemeCodeUnspsc190501.72141102'),
	'identifierSchemeCodeUnspsc190501.72141103': __('identifierSchemeCodeUnspsc190501.72141103'),
	'identifierSchemeCodeUnspsc190501.72141104': __('identifierSchemeCodeUnspsc190501.72141104'),
	'identifierSchemeCodeUnspsc190501.72141105': __('identifierSchemeCodeUnspsc190501.72141105'),
	'identifierSchemeCodeUnspsc190501.72141106': __('identifierSchemeCodeUnspsc190501.72141106'),
	'identifierSchemeCodeUnspsc190501.72141107': __('identifierSchemeCodeUnspsc190501.72141107'),
	'identifierSchemeCodeUnspsc190501.72141108': __('identifierSchemeCodeUnspsc190501.72141108'),
	'identifierSchemeCodeUnspsc190501.72141109': __('identifierSchemeCodeUnspsc190501.72141109'),
	'identifierSchemeCodeUnspsc190501.72141110': __('identifierSchemeCodeUnspsc190501.72141110'),
	'identifierSchemeCodeUnspsc190501.72141111': __('identifierSchemeCodeUnspsc190501.72141111'),
	'identifierSchemeCodeUnspsc190501.72141112': __('identifierSchemeCodeUnspsc190501.72141112'),
	'identifierSchemeCodeUnspsc190501.72141113': __('identifierSchemeCodeUnspsc190501.72141113'),
	'identifierSchemeCodeUnspsc190501.72141114': __('identifierSchemeCodeUnspsc190501.72141114'),
	'identifierSchemeCodeUnspsc190501.72141115': __('identifierSchemeCodeUnspsc190501.72141115'),
	'identifierSchemeCodeUnspsc190501.72141116': __('identifierSchemeCodeUnspsc190501.72141116'),
	'identifierSchemeCodeUnspsc190501.72141117': __('identifierSchemeCodeUnspsc190501.72141117'),
	'identifierSchemeCodeUnspsc190501.72141118': __('identifierSchemeCodeUnspsc190501.72141118'),
	'identifierSchemeCodeUnspsc190501.72141119': __('identifierSchemeCodeUnspsc190501.72141119'),
	'identifierSchemeCodeUnspsc190501.72141120': __('identifierSchemeCodeUnspsc190501.72141120'),
	'identifierSchemeCodeUnspsc190501.72141121': __('identifierSchemeCodeUnspsc190501.72141121'),
	'identifierSchemeCodeUnspsc190501.72141122': __('identifierSchemeCodeUnspsc190501.72141122'),
	'identifierSchemeCodeUnspsc190501.72141123': __('identifierSchemeCodeUnspsc190501.72141123'),
	'identifierSchemeCodeUnspsc190501.72141124': __('identifierSchemeCodeUnspsc190501.72141124'),
	'identifierSchemeCodeUnspsc190501.72141125': __('identifierSchemeCodeUnspsc190501.72141125'),
	'identifierSchemeCodeUnspsc190501.72141126': __('identifierSchemeCodeUnspsc190501.72141126'),
	'identifierSchemeCodeUnspsc190501.72141127': __('identifierSchemeCodeUnspsc190501.72141127'),
	'identifierSchemeCodeUnspsc190501.72141128': __('identifierSchemeCodeUnspsc190501.72141128'),
	'identifierSchemeCodeUnspsc190501.72141129': __('identifierSchemeCodeUnspsc190501.72141129'),
	'identifierSchemeCodeUnspsc190501.72141130': __('identifierSchemeCodeUnspsc190501.72141130'),
	'identifierSchemeCodeUnspsc190501.72141200': __('identifierSchemeCodeUnspsc190501.72141200'),
	'identifierSchemeCodeUnspsc190501.72141201': __('identifierSchemeCodeUnspsc190501.72141201'),
	'identifierSchemeCodeUnspsc190501.72141202': __('identifierSchemeCodeUnspsc190501.72141202'),
	'identifierSchemeCodeUnspsc190501.72141203': __('identifierSchemeCodeUnspsc190501.72141203'),
	'identifierSchemeCodeUnspsc190501.72141204': __('identifierSchemeCodeUnspsc190501.72141204'),
	'identifierSchemeCodeUnspsc190501.72141205': __('identifierSchemeCodeUnspsc190501.72141205'),
	'identifierSchemeCodeUnspsc190501.72141206': __('identifierSchemeCodeUnspsc190501.72141206'),
	'identifierSchemeCodeUnspsc190501.72141207': __('identifierSchemeCodeUnspsc190501.72141207'),
	'identifierSchemeCodeUnspsc190501.72141209': __('identifierSchemeCodeUnspsc190501.72141209'),
	'identifierSchemeCodeUnspsc190501.72141210': __('identifierSchemeCodeUnspsc190501.72141210'),
	'identifierSchemeCodeUnspsc190501.72141211': __('identifierSchemeCodeUnspsc190501.72141211'),
	'identifierSchemeCodeUnspsc190501.72141212': __('identifierSchemeCodeUnspsc190501.72141212'),
	'identifierSchemeCodeUnspsc190501.72141213': __('identifierSchemeCodeUnspsc190501.72141213'),
	'identifierSchemeCodeUnspsc190501.72141214': __('identifierSchemeCodeUnspsc190501.72141214'),
	'identifierSchemeCodeUnspsc190501.72141215': __('identifierSchemeCodeUnspsc190501.72141215'),
	'identifierSchemeCodeUnspsc190501.72141216': __('identifierSchemeCodeUnspsc190501.72141216'),
	'identifierSchemeCodeUnspsc190501.72141400': __('identifierSchemeCodeUnspsc190501.72141400'),
	'identifierSchemeCodeUnspsc190501.72141401': __('identifierSchemeCodeUnspsc190501.72141401'),
	'identifierSchemeCodeUnspsc190501.72141402': __('identifierSchemeCodeUnspsc190501.72141402'),
	'identifierSchemeCodeUnspsc190501.72141500': __('identifierSchemeCodeUnspsc190501.72141500'),
	'identifierSchemeCodeUnspsc190501.72141502': __('identifierSchemeCodeUnspsc190501.72141502'),
	'identifierSchemeCodeUnspsc190501.72141503': __('identifierSchemeCodeUnspsc190501.72141503'),
	'identifierSchemeCodeUnspsc190501.72141504': __('identifierSchemeCodeUnspsc190501.72141504'),
	'identifierSchemeCodeUnspsc190501.72141505': __('identifierSchemeCodeUnspsc190501.72141505'),
	'identifierSchemeCodeUnspsc190501.72141507': __('identifierSchemeCodeUnspsc190501.72141507'),
	'identifierSchemeCodeUnspsc190501.72141508': __('identifierSchemeCodeUnspsc190501.72141508'),
	'identifierSchemeCodeUnspsc190501.72141509': __('identifierSchemeCodeUnspsc190501.72141509'),
	'identifierSchemeCodeUnspsc190501.72141510': __('identifierSchemeCodeUnspsc190501.72141510'),
	'identifierSchemeCodeUnspsc190501.72141511': __('identifierSchemeCodeUnspsc190501.72141511'),
	'identifierSchemeCodeUnspsc190501.72141600': __('identifierSchemeCodeUnspsc190501.72141600'),
	'identifierSchemeCodeUnspsc190501.72141601': __('identifierSchemeCodeUnspsc190501.72141601'),
	'identifierSchemeCodeUnspsc190501.72141602': __('identifierSchemeCodeUnspsc190501.72141602'),
	'identifierSchemeCodeUnspsc190501.72141603': __('identifierSchemeCodeUnspsc190501.72141603'),
	'identifierSchemeCodeUnspsc190501.72141604': __('identifierSchemeCodeUnspsc190501.72141604'),
	'identifierSchemeCodeUnspsc190501.72141605': __('identifierSchemeCodeUnspsc190501.72141605'),
	'identifierSchemeCodeUnspsc190501.72141700': __('identifierSchemeCodeUnspsc190501.72141700'),
	'identifierSchemeCodeUnspsc190501.72141701': __('identifierSchemeCodeUnspsc190501.72141701'),
	'identifierSchemeCodeUnspsc190501.72141702': __('identifierSchemeCodeUnspsc190501.72141702'),
	'identifierSchemeCodeUnspsc190501.72150000': __('identifierSchemeCodeUnspsc190501.72150000'),
	'identifierSchemeCodeUnspsc190501.72151000': __('identifierSchemeCodeUnspsc190501.72151000'),
	'identifierSchemeCodeUnspsc190501.72151001': __('identifierSchemeCodeUnspsc190501.72151001'),
	'identifierSchemeCodeUnspsc190501.72151002': __('identifierSchemeCodeUnspsc190501.72151002'),
	'identifierSchemeCodeUnspsc190501.72151003': __('identifierSchemeCodeUnspsc190501.72151003'),
	'identifierSchemeCodeUnspsc190501.72151004': __('identifierSchemeCodeUnspsc190501.72151004'),
	'identifierSchemeCodeUnspsc190501.72151005': __('identifierSchemeCodeUnspsc190501.72151005'),
	'identifierSchemeCodeUnspsc190501.72151006': __('identifierSchemeCodeUnspsc190501.72151006'),
	'identifierSchemeCodeUnspsc190501.72151100': __('identifierSchemeCodeUnspsc190501.72151100'),
	'identifierSchemeCodeUnspsc190501.72151101': __('identifierSchemeCodeUnspsc190501.72151101'),
	'identifierSchemeCodeUnspsc190501.72151102': __('identifierSchemeCodeUnspsc190501.72151102'),
	'identifierSchemeCodeUnspsc190501.72151103': __('identifierSchemeCodeUnspsc190501.72151103'),
	'identifierSchemeCodeUnspsc190501.72151200': __('identifierSchemeCodeUnspsc190501.72151200'),
	'identifierSchemeCodeUnspsc190501.72151201': __('identifierSchemeCodeUnspsc190501.72151201'),
	'identifierSchemeCodeUnspsc190501.72151202': __('identifierSchemeCodeUnspsc190501.72151202'),
	'identifierSchemeCodeUnspsc190501.72151203': __('identifierSchemeCodeUnspsc190501.72151203'),
	'identifierSchemeCodeUnspsc190501.72151204': __('identifierSchemeCodeUnspsc190501.72151204'),
	'identifierSchemeCodeUnspsc190501.72151205': __('identifierSchemeCodeUnspsc190501.72151205'),
	'identifierSchemeCodeUnspsc190501.72151206': __('identifierSchemeCodeUnspsc190501.72151206'),
	'identifierSchemeCodeUnspsc190501.72151207': __('identifierSchemeCodeUnspsc190501.72151207'),
	'identifierSchemeCodeUnspsc190501.72151300': __('identifierSchemeCodeUnspsc190501.72151300'),
	'identifierSchemeCodeUnspsc190501.72151301': __('identifierSchemeCodeUnspsc190501.72151301'),
	'identifierSchemeCodeUnspsc190501.72151302': __('identifierSchemeCodeUnspsc190501.72151302'),
	'identifierSchemeCodeUnspsc190501.72151303': __('identifierSchemeCodeUnspsc190501.72151303'),
	'identifierSchemeCodeUnspsc190501.72151304': __('identifierSchemeCodeUnspsc190501.72151304'),
	'identifierSchemeCodeUnspsc190501.72151305': __('identifierSchemeCodeUnspsc190501.72151305'),
	'identifierSchemeCodeUnspsc190501.72151306': __('identifierSchemeCodeUnspsc190501.72151306'),
	'identifierSchemeCodeUnspsc190501.72151307': __('identifierSchemeCodeUnspsc190501.72151307'),
	'identifierSchemeCodeUnspsc190501.72151308': __('identifierSchemeCodeUnspsc190501.72151308'),
	'identifierSchemeCodeUnspsc190501.72151400': __('identifierSchemeCodeUnspsc190501.72151400'),
	'identifierSchemeCodeUnspsc190501.72151401': __('identifierSchemeCodeUnspsc190501.72151401'),
	'identifierSchemeCodeUnspsc190501.72151402': __('identifierSchemeCodeUnspsc190501.72151402'),
	'identifierSchemeCodeUnspsc190501.72151500': __('identifierSchemeCodeUnspsc190501.72151500'),
	'identifierSchemeCodeUnspsc190501.72151501': __('identifierSchemeCodeUnspsc190501.72151501'),
	'identifierSchemeCodeUnspsc190501.72151502': __('identifierSchemeCodeUnspsc190501.72151502'),
	'identifierSchemeCodeUnspsc190501.72151503': __('identifierSchemeCodeUnspsc190501.72151503'),
	'identifierSchemeCodeUnspsc190501.72151504': __('identifierSchemeCodeUnspsc190501.72151504'),
	'identifierSchemeCodeUnspsc190501.72151505': __('identifierSchemeCodeUnspsc190501.72151505'),
	'identifierSchemeCodeUnspsc190501.72151506': __('identifierSchemeCodeUnspsc190501.72151506'),
	'identifierSchemeCodeUnspsc190501.72151507': __('identifierSchemeCodeUnspsc190501.72151507'),
	'identifierSchemeCodeUnspsc190501.72151508': __('identifierSchemeCodeUnspsc190501.72151508'),
	'identifierSchemeCodeUnspsc190501.72151509': __('identifierSchemeCodeUnspsc190501.72151509'),
	'identifierSchemeCodeUnspsc190501.72151510': __('identifierSchemeCodeUnspsc190501.72151510'),
	'identifierSchemeCodeUnspsc190501.72151511': __('identifierSchemeCodeUnspsc190501.72151511'),
	'identifierSchemeCodeUnspsc190501.72151514': __('identifierSchemeCodeUnspsc190501.72151514'),
	'identifierSchemeCodeUnspsc190501.72151515': __('identifierSchemeCodeUnspsc190501.72151515'),
	'identifierSchemeCodeUnspsc190501.72151600': __('identifierSchemeCodeUnspsc190501.72151600'),
	'identifierSchemeCodeUnspsc190501.72151601': __('identifierSchemeCodeUnspsc190501.72151601'),
	'identifierSchemeCodeUnspsc190501.72151602': __('identifierSchemeCodeUnspsc190501.72151602'),
	'identifierSchemeCodeUnspsc190501.72151603': __('identifierSchemeCodeUnspsc190501.72151603'),
	'identifierSchemeCodeUnspsc190501.72151604': __('identifierSchemeCodeUnspsc190501.72151604'),
	'identifierSchemeCodeUnspsc190501.72151605': __('identifierSchemeCodeUnspsc190501.72151605'),
	'identifierSchemeCodeUnspsc190501.72151606': __('identifierSchemeCodeUnspsc190501.72151606'),
	'identifierSchemeCodeUnspsc190501.72151607': __('identifierSchemeCodeUnspsc190501.72151607'),
	'identifierSchemeCodeUnspsc190501.72151608': __('identifierSchemeCodeUnspsc190501.72151608'),
	'identifierSchemeCodeUnspsc190501.72151609': __('identifierSchemeCodeUnspsc190501.72151609'),
	'identifierSchemeCodeUnspsc190501.72151700': __('identifierSchemeCodeUnspsc190501.72151700'),
	'identifierSchemeCodeUnspsc190501.72151701': __('identifierSchemeCodeUnspsc190501.72151701'),
	'identifierSchemeCodeUnspsc190501.72151702': __('identifierSchemeCodeUnspsc190501.72151702'),
	'identifierSchemeCodeUnspsc190501.72151703': __('identifierSchemeCodeUnspsc190501.72151703'),
	'identifierSchemeCodeUnspsc190501.72151704': __('identifierSchemeCodeUnspsc190501.72151704'),
	'identifierSchemeCodeUnspsc190501.72151800': __('identifierSchemeCodeUnspsc190501.72151800'),
	'identifierSchemeCodeUnspsc190501.72151801': __('identifierSchemeCodeUnspsc190501.72151801'),
	'identifierSchemeCodeUnspsc190501.72151802': __('identifierSchemeCodeUnspsc190501.72151802'),
	'identifierSchemeCodeUnspsc190501.72151803': __('identifierSchemeCodeUnspsc190501.72151803'),
	'identifierSchemeCodeUnspsc190501.72151900': __('identifierSchemeCodeUnspsc190501.72151900'),
	'identifierSchemeCodeUnspsc190501.72151901': __('identifierSchemeCodeUnspsc190501.72151901'),
	'identifierSchemeCodeUnspsc190501.72151903': __('identifierSchemeCodeUnspsc190501.72151903'),
	'identifierSchemeCodeUnspsc190501.72151904': __('identifierSchemeCodeUnspsc190501.72151904'),
	'identifierSchemeCodeUnspsc190501.72151905': __('identifierSchemeCodeUnspsc190501.72151905'),
	'identifierSchemeCodeUnspsc190501.72151906': __('identifierSchemeCodeUnspsc190501.72151906'),
	'identifierSchemeCodeUnspsc190501.72151907': __('identifierSchemeCodeUnspsc190501.72151907'),
	'identifierSchemeCodeUnspsc190501.72151908': __('identifierSchemeCodeUnspsc190501.72151908'),
	'identifierSchemeCodeUnspsc190501.72151909': __('identifierSchemeCodeUnspsc190501.72151909'),
	'identifierSchemeCodeUnspsc190501.72151910': __('identifierSchemeCodeUnspsc190501.72151910'),
	'identifierSchemeCodeUnspsc190501.72151911': __('identifierSchemeCodeUnspsc190501.72151911'),
	'identifierSchemeCodeUnspsc190501.72152000': __('identifierSchemeCodeUnspsc190501.72152000'),
	'identifierSchemeCodeUnspsc190501.72152001': __('identifierSchemeCodeUnspsc190501.72152001'),
	'identifierSchemeCodeUnspsc190501.72152002': __('identifierSchemeCodeUnspsc190501.72152002'),
	'identifierSchemeCodeUnspsc190501.72152003': __('identifierSchemeCodeUnspsc190501.72152003'),
	'identifierSchemeCodeUnspsc190501.72152004': __('identifierSchemeCodeUnspsc190501.72152004'),
	'identifierSchemeCodeUnspsc190501.72152005': __('identifierSchemeCodeUnspsc190501.72152005'),
	'identifierSchemeCodeUnspsc190501.72152100': __('identifierSchemeCodeUnspsc190501.72152100'),
	'identifierSchemeCodeUnspsc190501.72152101': __('identifierSchemeCodeUnspsc190501.72152101'),
	'identifierSchemeCodeUnspsc190501.72152102': __('identifierSchemeCodeUnspsc190501.72152102'),
	'identifierSchemeCodeUnspsc190501.72152103': __('identifierSchemeCodeUnspsc190501.72152103'),
	'identifierSchemeCodeUnspsc190501.72152104': __('identifierSchemeCodeUnspsc190501.72152104'),
	'identifierSchemeCodeUnspsc190501.72152200': __('identifierSchemeCodeUnspsc190501.72152200'),
	'identifierSchemeCodeUnspsc190501.72152201': __('identifierSchemeCodeUnspsc190501.72152201'),
	'identifierSchemeCodeUnspsc190501.72152202': __('identifierSchemeCodeUnspsc190501.72152202'),
	'identifierSchemeCodeUnspsc190501.72152203': __('identifierSchemeCodeUnspsc190501.72152203'),
	'identifierSchemeCodeUnspsc190501.72152204': __('identifierSchemeCodeUnspsc190501.72152204'),
	'identifierSchemeCodeUnspsc190501.72152300': __('identifierSchemeCodeUnspsc190501.72152300'),
	'identifierSchemeCodeUnspsc190501.72152301': __('identifierSchemeCodeUnspsc190501.72152301'),
	'identifierSchemeCodeUnspsc190501.72152302': __('identifierSchemeCodeUnspsc190501.72152302'),
	'identifierSchemeCodeUnspsc190501.72152303': __('identifierSchemeCodeUnspsc190501.72152303'),
	'identifierSchemeCodeUnspsc190501.72152400': __('identifierSchemeCodeUnspsc190501.72152400'),
	'identifierSchemeCodeUnspsc190501.72152401': __('identifierSchemeCodeUnspsc190501.72152401'),
	'identifierSchemeCodeUnspsc190501.72152402': __('identifierSchemeCodeUnspsc190501.72152402'),
	'identifierSchemeCodeUnspsc190501.72152403': __('identifierSchemeCodeUnspsc190501.72152403'),
	'identifierSchemeCodeUnspsc190501.72152404': __('identifierSchemeCodeUnspsc190501.72152404'),
	'identifierSchemeCodeUnspsc190501.72152405': __('identifierSchemeCodeUnspsc190501.72152405'),
	'identifierSchemeCodeUnspsc190501.72152500': __('identifierSchemeCodeUnspsc190501.72152500'),
	'identifierSchemeCodeUnspsc190501.72152501': __('identifierSchemeCodeUnspsc190501.72152501'),
	'identifierSchemeCodeUnspsc190501.72152502': __('identifierSchemeCodeUnspsc190501.72152502'),
	'identifierSchemeCodeUnspsc190501.72152503': __('identifierSchemeCodeUnspsc190501.72152503'),
	'identifierSchemeCodeUnspsc190501.72152504': __('identifierSchemeCodeUnspsc190501.72152504'),
	'identifierSchemeCodeUnspsc190501.72152505': __('identifierSchemeCodeUnspsc190501.72152505'),
	'identifierSchemeCodeUnspsc190501.72152506': __('identifierSchemeCodeUnspsc190501.72152506'),
	'identifierSchemeCodeUnspsc190501.72152507': __('identifierSchemeCodeUnspsc190501.72152507'),
	'identifierSchemeCodeUnspsc190501.72152508': __('identifierSchemeCodeUnspsc190501.72152508'),
	'identifierSchemeCodeUnspsc190501.72152509': __('identifierSchemeCodeUnspsc190501.72152509'),
	'identifierSchemeCodeUnspsc190501.72152600': __('identifierSchemeCodeUnspsc190501.72152600'),
	'identifierSchemeCodeUnspsc190501.72152601': __('identifierSchemeCodeUnspsc190501.72152601'),
	'identifierSchemeCodeUnspsc190501.72152602': __('identifierSchemeCodeUnspsc190501.72152602'),
	'identifierSchemeCodeUnspsc190501.72152603': __('identifierSchemeCodeUnspsc190501.72152603'),
	'identifierSchemeCodeUnspsc190501.72152604': __('identifierSchemeCodeUnspsc190501.72152604'),
	'identifierSchemeCodeUnspsc190501.72152605': __('identifierSchemeCodeUnspsc190501.72152605'),
	'identifierSchemeCodeUnspsc190501.72152606': __('identifierSchemeCodeUnspsc190501.72152606'),
	'identifierSchemeCodeUnspsc190501.72152607': __('identifierSchemeCodeUnspsc190501.72152607'),
	'identifierSchemeCodeUnspsc190501.72152700': __('identifierSchemeCodeUnspsc190501.72152700'),
	'identifierSchemeCodeUnspsc190501.72152701': __('identifierSchemeCodeUnspsc190501.72152701'),
	'identifierSchemeCodeUnspsc190501.72152702': __('identifierSchemeCodeUnspsc190501.72152702'),
	'identifierSchemeCodeUnspsc190501.72152703': __('identifierSchemeCodeUnspsc190501.72152703'),
	'identifierSchemeCodeUnspsc190501.72152704': __('identifierSchemeCodeUnspsc190501.72152704'),
	'identifierSchemeCodeUnspsc190501.72152705': __('identifierSchemeCodeUnspsc190501.72152705'),
	'identifierSchemeCodeUnspsc190501.72152706': __('identifierSchemeCodeUnspsc190501.72152706'),
	'identifierSchemeCodeUnspsc190501.72152707': __('identifierSchemeCodeUnspsc190501.72152707'),
	'identifierSchemeCodeUnspsc190501.72152708': __('identifierSchemeCodeUnspsc190501.72152708'),
	'identifierSchemeCodeUnspsc190501.72152709': __('identifierSchemeCodeUnspsc190501.72152709'),
	'identifierSchemeCodeUnspsc190501.72152710': __('identifierSchemeCodeUnspsc190501.72152710'),
	'identifierSchemeCodeUnspsc190501.72152711': __('identifierSchemeCodeUnspsc190501.72152711'),
	'identifierSchemeCodeUnspsc190501.72152800': __('identifierSchemeCodeUnspsc190501.72152800'),
	'identifierSchemeCodeUnspsc190501.72152801': __('identifierSchemeCodeUnspsc190501.72152801'),
	'identifierSchemeCodeUnspsc190501.72152802': __('identifierSchemeCodeUnspsc190501.72152802'),
	'identifierSchemeCodeUnspsc190501.72152900': __('identifierSchemeCodeUnspsc190501.72152900'),
	'identifierSchemeCodeUnspsc190501.72152901': __('identifierSchemeCodeUnspsc190501.72152901'),
	'identifierSchemeCodeUnspsc190501.72152902': __('identifierSchemeCodeUnspsc190501.72152902'),
	'identifierSchemeCodeUnspsc190501.72152903': __('identifierSchemeCodeUnspsc190501.72152903'),
	'identifierSchemeCodeUnspsc190501.72152904': __('identifierSchemeCodeUnspsc190501.72152904'),
	'identifierSchemeCodeUnspsc190501.72152905': __('identifierSchemeCodeUnspsc190501.72152905'),
	'identifierSchemeCodeUnspsc190501.72152906': __('identifierSchemeCodeUnspsc190501.72152906'),
	'identifierSchemeCodeUnspsc190501.72152907': __('identifierSchemeCodeUnspsc190501.72152907'),
	'identifierSchemeCodeUnspsc190501.72152908': __('identifierSchemeCodeUnspsc190501.72152908'),
	'identifierSchemeCodeUnspsc190501.72152909': __('identifierSchemeCodeUnspsc190501.72152909'),
	'identifierSchemeCodeUnspsc190501.72153000': __('identifierSchemeCodeUnspsc190501.72153000'),
	'identifierSchemeCodeUnspsc190501.72153001': __('identifierSchemeCodeUnspsc190501.72153001'),
	'identifierSchemeCodeUnspsc190501.72153002': __('identifierSchemeCodeUnspsc190501.72153002'),
	'identifierSchemeCodeUnspsc190501.72153100': __('identifierSchemeCodeUnspsc190501.72153100'),
	'identifierSchemeCodeUnspsc190501.72153101': __('identifierSchemeCodeUnspsc190501.72153101'),
	'identifierSchemeCodeUnspsc190501.72153102': __('identifierSchemeCodeUnspsc190501.72153102'),
	'identifierSchemeCodeUnspsc190501.72153103': __('identifierSchemeCodeUnspsc190501.72153103'),
	'identifierSchemeCodeUnspsc190501.72153104': __('identifierSchemeCodeUnspsc190501.72153104'),
	'identifierSchemeCodeUnspsc190501.72153105': __('identifierSchemeCodeUnspsc190501.72153105'),
	'identifierSchemeCodeUnspsc190501.72153106': __('identifierSchemeCodeUnspsc190501.72153106'),
	'identifierSchemeCodeUnspsc190501.72153107': __('identifierSchemeCodeUnspsc190501.72153107'),
	'identifierSchemeCodeUnspsc190501.72153108': __('identifierSchemeCodeUnspsc190501.72153108'),
	'identifierSchemeCodeUnspsc190501.72153200': __('identifierSchemeCodeUnspsc190501.72153200'),
	'identifierSchemeCodeUnspsc190501.72153201': __('identifierSchemeCodeUnspsc190501.72153201'),
	'identifierSchemeCodeUnspsc190501.72153202': __('identifierSchemeCodeUnspsc190501.72153202'),
	'identifierSchemeCodeUnspsc190501.72153203': __('identifierSchemeCodeUnspsc190501.72153203'),
	'identifierSchemeCodeUnspsc190501.72153204': __('identifierSchemeCodeUnspsc190501.72153204'),
	'identifierSchemeCodeUnspsc190501.72153205': __('identifierSchemeCodeUnspsc190501.72153205'),
	'identifierSchemeCodeUnspsc190501.72153206': __('identifierSchemeCodeUnspsc190501.72153206'),
	'identifierSchemeCodeUnspsc190501.72153207': __('identifierSchemeCodeUnspsc190501.72153207'),
	'identifierSchemeCodeUnspsc190501.72153208': __('identifierSchemeCodeUnspsc190501.72153208'),
	'identifierSchemeCodeUnspsc190501.72153209': __('identifierSchemeCodeUnspsc190501.72153209'),
	'identifierSchemeCodeUnspsc190501.72153300': __('identifierSchemeCodeUnspsc190501.72153300'),
	'identifierSchemeCodeUnspsc190501.72153301': __('identifierSchemeCodeUnspsc190501.72153301'),
	'identifierSchemeCodeUnspsc190501.72153302': __('identifierSchemeCodeUnspsc190501.72153302'),
	'identifierSchemeCodeUnspsc190501.72153303': __('identifierSchemeCodeUnspsc190501.72153303'),
	'identifierSchemeCodeUnspsc190501.72153400': __('identifierSchemeCodeUnspsc190501.72153400'),
	'identifierSchemeCodeUnspsc190501.72153401': __('identifierSchemeCodeUnspsc190501.72153401'),
	'identifierSchemeCodeUnspsc190501.72153402': __('identifierSchemeCodeUnspsc190501.72153402'),
	'identifierSchemeCodeUnspsc190501.72153500': __('identifierSchemeCodeUnspsc190501.72153500'),
	'identifierSchemeCodeUnspsc190501.72153501': __('identifierSchemeCodeUnspsc190501.72153501'),
	'identifierSchemeCodeUnspsc190501.72153502': __('identifierSchemeCodeUnspsc190501.72153502'),
	'identifierSchemeCodeUnspsc190501.72153503': __('identifierSchemeCodeUnspsc190501.72153503'),
	'identifierSchemeCodeUnspsc190501.72153504': __('identifierSchemeCodeUnspsc190501.72153504'),
	'identifierSchemeCodeUnspsc190501.72153505': __('identifierSchemeCodeUnspsc190501.72153505'),
	'identifierSchemeCodeUnspsc190501.72153506': __('identifierSchemeCodeUnspsc190501.72153506'),
	'identifierSchemeCodeUnspsc190501.72153507': __('identifierSchemeCodeUnspsc190501.72153507'),
	'identifierSchemeCodeUnspsc190501.72153600': __('identifierSchemeCodeUnspsc190501.72153600'),
	'identifierSchemeCodeUnspsc190501.72153601': __('identifierSchemeCodeUnspsc190501.72153601'),
	'identifierSchemeCodeUnspsc190501.72153602': __('identifierSchemeCodeUnspsc190501.72153602'),
	'identifierSchemeCodeUnspsc190501.72153603': __('identifierSchemeCodeUnspsc190501.72153603'),
	'identifierSchemeCodeUnspsc190501.72153604': __('identifierSchemeCodeUnspsc190501.72153604'),
	'identifierSchemeCodeUnspsc190501.72153605': __('identifierSchemeCodeUnspsc190501.72153605'),
	'identifierSchemeCodeUnspsc190501.72153606': __('identifierSchemeCodeUnspsc190501.72153606'),
	'identifierSchemeCodeUnspsc190501.72153607': __('identifierSchemeCodeUnspsc190501.72153607'),
	'identifierSchemeCodeUnspsc190501.72153608': __('identifierSchemeCodeUnspsc190501.72153608'),
	'identifierSchemeCodeUnspsc190501.72153609': __('identifierSchemeCodeUnspsc190501.72153609'),
	'identifierSchemeCodeUnspsc190501.72153610': __('identifierSchemeCodeUnspsc190501.72153610'),
	'identifierSchemeCodeUnspsc190501.72153611': __('identifierSchemeCodeUnspsc190501.72153611'),
	'identifierSchemeCodeUnspsc190501.72153612': __('identifierSchemeCodeUnspsc190501.72153612'),
	'identifierSchemeCodeUnspsc190501.72153613': __('identifierSchemeCodeUnspsc190501.72153613'),
	'identifierSchemeCodeUnspsc190501.72153700': __('identifierSchemeCodeUnspsc190501.72153700'),
	'identifierSchemeCodeUnspsc190501.72153701': __('identifierSchemeCodeUnspsc190501.72153701'),
	'identifierSchemeCodeUnspsc190501.72153702': __('identifierSchemeCodeUnspsc190501.72153702'),
	'identifierSchemeCodeUnspsc190501.72153900': __('identifierSchemeCodeUnspsc190501.72153900'),
	'identifierSchemeCodeUnspsc190501.72153901': __('identifierSchemeCodeUnspsc190501.72153901'),
	'identifierSchemeCodeUnspsc190501.72153902': __('identifierSchemeCodeUnspsc190501.72153902'),
	'identifierSchemeCodeUnspsc190501.72154000': __('identifierSchemeCodeUnspsc190501.72154000'),
	'identifierSchemeCodeUnspsc190501.72154001': __('identifierSchemeCodeUnspsc190501.72154001'),
	'identifierSchemeCodeUnspsc190501.72154002': __('identifierSchemeCodeUnspsc190501.72154002'),
	'identifierSchemeCodeUnspsc190501.72154003': __('identifierSchemeCodeUnspsc190501.72154003'),
	'identifierSchemeCodeUnspsc190501.72154004': __('identifierSchemeCodeUnspsc190501.72154004'),
	'identifierSchemeCodeUnspsc190501.72154005': __('identifierSchemeCodeUnspsc190501.72154005'),
	'identifierSchemeCodeUnspsc190501.72154006': __('identifierSchemeCodeUnspsc190501.72154006'),
	'identifierSchemeCodeUnspsc190501.72154007': __('identifierSchemeCodeUnspsc190501.72154007'),
	'identifierSchemeCodeUnspsc190501.72154008': __('identifierSchemeCodeUnspsc190501.72154008'),
	'identifierSchemeCodeUnspsc190501.72154009': __('identifierSchemeCodeUnspsc190501.72154009'),
	'identifierSchemeCodeUnspsc190501.72154010': __('identifierSchemeCodeUnspsc190501.72154010'),
	'identifierSchemeCodeUnspsc190501.72154011': __('identifierSchemeCodeUnspsc190501.72154011'),
	'identifierSchemeCodeUnspsc190501.72154012': __('identifierSchemeCodeUnspsc190501.72154012'),
	'identifierSchemeCodeUnspsc190501.72154013': __('identifierSchemeCodeUnspsc190501.72154013'),
	'identifierSchemeCodeUnspsc190501.72154014': __('identifierSchemeCodeUnspsc190501.72154014'),
	'identifierSchemeCodeUnspsc190501.72154015': __('identifierSchemeCodeUnspsc190501.72154015'),
	'identifierSchemeCodeUnspsc190501.72154016': __('identifierSchemeCodeUnspsc190501.72154016'),
	'identifierSchemeCodeUnspsc190501.72154017': __('identifierSchemeCodeUnspsc190501.72154017'),
	'identifierSchemeCodeUnspsc190501.72154018': __('identifierSchemeCodeUnspsc190501.72154018'),
	'identifierSchemeCodeUnspsc190501.72154019': __('identifierSchemeCodeUnspsc190501.72154019'),
	'identifierSchemeCodeUnspsc190501.72154020': __('identifierSchemeCodeUnspsc190501.72154020'),
	'identifierSchemeCodeUnspsc190501.72154021': __('identifierSchemeCodeUnspsc190501.72154021'),
	'identifierSchemeCodeUnspsc190501.72154022': __('identifierSchemeCodeUnspsc190501.72154022'),
	'identifierSchemeCodeUnspsc190501.72154023': __('identifierSchemeCodeUnspsc190501.72154023'),
	'identifierSchemeCodeUnspsc190501.72154024': __('identifierSchemeCodeUnspsc190501.72154024'),
	'identifierSchemeCodeUnspsc190501.72154025': __('identifierSchemeCodeUnspsc190501.72154025'),
	'identifierSchemeCodeUnspsc190501.72154026': __('identifierSchemeCodeUnspsc190501.72154026'),
	'identifierSchemeCodeUnspsc190501.72154027': __('identifierSchemeCodeUnspsc190501.72154027'),
	'identifierSchemeCodeUnspsc190501.72154028': __('identifierSchemeCodeUnspsc190501.72154028'),
	'identifierSchemeCodeUnspsc190501.72154029': __('identifierSchemeCodeUnspsc190501.72154029'),
	'identifierSchemeCodeUnspsc190501.72154030': __('identifierSchemeCodeUnspsc190501.72154030'),
	'identifierSchemeCodeUnspsc190501.72154031': __('identifierSchemeCodeUnspsc190501.72154031'),
	'identifierSchemeCodeUnspsc190501.72154032': __('identifierSchemeCodeUnspsc190501.72154032'),
	'identifierSchemeCodeUnspsc190501.72154033': __('identifierSchemeCodeUnspsc190501.72154033'),
	'identifierSchemeCodeUnspsc190501.72154034': __('identifierSchemeCodeUnspsc190501.72154034'),
	'identifierSchemeCodeUnspsc190501.72154035': __('identifierSchemeCodeUnspsc190501.72154035'),
	'identifierSchemeCodeUnspsc190501.72154036': __('identifierSchemeCodeUnspsc190501.72154036'),
	'identifierSchemeCodeUnspsc190501.72154037': __('identifierSchemeCodeUnspsc190501.72154037'),
	'identifierSchemeCodeUnspsc190501.72154038': __('identifierSchemeCodeUnspsc190501.72154038'),
	'identifierSchemeCodeUnspsc190501.72154039': __('identifierSchemeCodeUnspsc190501.72154039'),
	'identifierSchemeCodeUnspsc190501.72154040': __('identifierSchemeCodeUnspsc190501.72154040'),
	'identifierSchemeCodeUnspsc190501.72154041': __('identifierSchemeCodeUnspsc190501.72154041'),
	'identifierSchemeCodeUnspsc190501.72154042': __('identifierSchemeCodeUnspsc190501.72154042'),
	'identifierSchemeCodeUnspsc190501.72154043': __('identifierSchemeCodeUnspsc190501.72154043'),
	'identifierSchemeCodeUnspsc190501.72154044': __('identifierSchemeCodeUnspsc190501.72154044'),
	'identifierSchemeCodeUnspsc190501.72154045': __('identifierSchemeCodeUnspsc190501.72154045'),
	'identifierSchemeCodeUnspsc190501.72154046': __('identifierSchemeCodeUnspsc190501.72154046'),
	'identifierSchemeCodeUnspsc190501.72154047': __('identifierSchemeCodeUnspsc190501.72154047'),
	'identifierSchemeCodeUnspsc190501.72154048': __('identifierSchemeCodeUnspsc190501.72154048'),
	'identifierSchemeCodeUnspsc190501.72154049': __('identifierSchemeCodeUnspsc190501.72154049'),
	'identifierSchemeCodeUnspsc190501.72154050': __('identifierSchemeCodeUnspsc190501.72154050'),
	'identifierSchemeCodeUnspsc190501.72154051': __('identifierSchemeCodeUnspsc190501.72154051'),
	'identifierSchemeCodeUnspsc190501.72154052': __('identifierSchemeCodeUnspsc190501.72154052'),
	'identifierSchemeCodeUnspsc190501.72154053': __('identifierSchemeCodeUnspsc190501.72154053'),
	'identifierSchemeCodeUnspsc190501.72154054': __('identifierSchemeCodeUnspsc190501.72154054'),
	'identifierSchemeCodeUnspsc190501.72154055': __('identifierSchemeCodeUnspsc190501.72154055'),
	'identifierSchemeCodeUnspsc190501.72154056': __('identifierSchemeCodeUnspsc190501.72154056'),
	'identifierSchemeCodeUnspsc190501.72154057': __('identifierSchemeCodeUnspsc190501.72154057'),
	'identifierSchemeCodeUnspsc190501.72154058': __('identifierSchemeCodeUnspsc190501.72154058'),
	'identifierSchemeCodeUnspsc190501.72154059': __('identifierSchemeCodeUnspsc190501.72154059'),
	'identifierSchemeCodeUnspsc190501.72154060': __('identifierSchemeCodeUnspsc190501.72154060'),
	'identifierSchemeCodeUnspsc190501.72154061': __('identifierSchemeCodeUnspsc190501.72154061'),
	'identifierSchemeCodeUnspsc190501.72154062': __('identifierSchemeCodeUnspsc190501.72154062'),
	'identifierSchemeCodeUnspsc190501.72154063': __('identifierSchemeCodeUnspsc190501.72154063'),
	'identifierSchemeCodeUnspsc190501.72154064': __('identifierSchemeCodeUnspsc190501.72154064'),
	'identifierSchemeCodeUnspsc190501.72154065': __('identifierSchemeCodeUnspsc190501.72154065'),
	'identifierSchemeCodeUnspsc190501.72154066': __('identifierSchemeCodeUnspsc190501.72154066'),
	'identifierSchemeCodeUnspsc190501.72154067': __('identifierSchemeCodeUnspsc190501.72154067'),
	'identifierSchemeCodeUnspsc190501.72154068': __('identifierSchemeCodeUnspsc190501.72154068'),
	'identifierSchemeCodeUnspsc190501.72154069': __('identifierSchemeCodeUnspsc190501.72154069'),
	'identifierSchemeCodeUnspsc190501.72154100': __('identifierSchemeCodeUnspsc190501.72154100'),
	'identifierSchemeCodeUnspsc190501.72154101': __('identifierSchemeCodeUnspsc190501.72154101'),
	'identifierSchemeCodeUnspsc190501.72154102': __('identifierSchemeCodeUnspsc190501.72154102'),
	'identifierSchemeCodeUnspsc190501.72154103': __('identifierSchemeCodeUnspsc190501.72154103'),
	'identifierSchemeCodeUnspsc190501.72154104': __('identifierSchemeCodeUnspsc190501.72154104'),
	'identifierSchemeCodeUnspsc190501.72154105': __('identifierSchemeCodeUnspsc190501.72154105'),
	'identifierSchemeCodeUnspsc190501.72154106': __('identifierSchemeCodeUnspsc190501.72154106'),
	'identifierSchemeCodeUnspsc190501.72154107': __('identifierSchemeCodeUnspsc190501.72154107'),
	'identifierSchemeCodeUnspsc190501.72154108': __('identifierSchemeCodeUnspsc190501.72154108'),
	'identifierSchemeCodeUnspsc190501.72154109': __('identifierSchemeCodeUnspsc190501.72154109'),
	'identifierSchemeCodeUnspsc190501.72154110': __('identifierSchemeCodeUnspsc190501.72154110'),
	'identifierSchemeCodeUnspsc190501.72154200': __('identifierSchemeCodeUnspsc190501.72154200'),
	'identifierSchemeCodeUnspsc190501.72154201': __('identifierSchemeCodeUnspsc190501.72154201'),
	'identifierSchemeCodeUnspsc190501.72154300': __('identifierSchemeCodeUnspsc190501.72154300'),
	'identifierSchemeCodeUnspsc190501.72154301': __('identifierSchemeCodeUnspsc190501.72154301'),
	'identifierSchemeCodeUnspsc190501.72154302': __('identifierSchemeCodeUnspsc190501.72154302'),
	'identifierSchemeCodeUnspsc190501.72154303': __('identifierSchemeCodeUnspsc190501.72154303'),
	'identifierSchemeCodeUnspsc190501.72154400': __('identifierSchemeCodeUnspsc190501.72154400'),
	'identifierSchemeCodeUnspsc190501.72154401': __('identifierSchemeCodeUnspsc190501.72154401'),
	'identifierSchemeCodeUnspsc190501.72154402': __('identifierSchemeCodeUnspsc190501.72154402'),
	'identifierSchemeCodeUnspsc190501.72154500': __('identifierSchemeCodeUnspsc190501.72154500'),
	'identifierSchemeCodeUnspsc190501.72154501': __('identifierSchemeCodeUnspsc190501.72154501'),
	'identifierSchemeCodeUnspsc190501.72154502': __('identifierSchemeCodeUnspsc190501.72154502'),
	'identifierSchemeCodeUnspsc190501.72154503': __('identifierSchemeCodeUnspsc190501.72154503'),
	'identifierSchemeCodeUnspsc190501.72154600': __('identifierSchemeCodeUnspsc190501.72154600'),
	'identifierSchemeCodeUnspsc190501.72154601': __('identifierSchemeCodeUnspsc190501.72154601'),
	'identifierSchemeCodeUnspsc190501.72154602': __('identifierSchemeCodeUnspsc190501.72154602'),
	'identifierSchemeCodeUnspsc190501.72154603': __('identifierSchemeCodeUnspsc190501.72154603'),
	'identifierSchemeCodeUnspsc190501.72154604': __('identifierSchemeCodeUnspsc190501.72154604'),
	'identifierSchemeCodeUnspsc190501.72154605': __('identifierSchemeCodeUnspsc190501.72154605'),
	'identifierSchemeCodeUnspsc190501.73000000': __('identifierSchemeCodeUnspsc190501.73000000'),
	'identifierSchemeCodeUnspsc190501.73100000': __('identifierSchemeCodeUnspsc190501.73100000'),
	'identifierSchemeCodeUnspsc190501.73101500': __('identifierSchemeCodeUnspsc190501.73101500'),
	'identifierSchemeCodeUnspsc190501.73101501': __('identifierSchemeCodeUnspsc190501.73101501'),
	'identifierSchemeCodeUnspsc190501.73101502': __('identifierSchemeCodeUnspsc190501.73101502'),
	'identifierSchemeCodeUnspsc190501.73101503': __('identifierSchemeCodeUnspsc190501.73101503'),
	'identifierSchemeCodeUnspsc190501.73101504': __('identifierSchemeCodeUnspsc190501.73101504'),
	'identifierSchemeCodeUnspsc190501.73101505': __('identifierSchemeCodeUnspsc190501.73101505'),
	'identifierSchemeCodeUnspsc190501.73101600': __('identifierSchemeCodeUnspsc190501.73101600'),
	'identifierSchemeCodeUnspsc190501.73101601': __('identifierSchemeCodeUnspsc190501.73101601'),
	'identifierSchemeCodeUnspsc190501.73101602': __('identifierSchemeCodeUnspsc190501.73101602'),
	'identifierSchemeCodeUnspsc190501.73101603': __('identifierSchemeCodeUnspsc190501.73101603'),
	'identifierSchemeCodeUnspsc190501.73101604': __('identifierSchemeCodeUnspsc190501.73101604'),
	'identifierSchemeCodeUnspsc190501.73101605': __('identifierSchemeCodeUnspsc190501.73101605'),
	'identifierSchemeCodeUnspsc190501.73101606': __('identifierSchemeCodeUnspsc190501.73101606'),
	'identifierSchemeCodeUnspsc190501.73101607': __('identifierSchemeCodeUnspsc190501.73101607'),
	'identifierSchemeCodeUnspsc190501.73101608': __('identifierSchemeCodeUnspsc190501.73101608'),
	'identifierSchemeCodeUnspsc190501.73101609': __('identifierSchemeCodeUnspsc190501.73101609'),
	'identifierSchemeCodeUnspsc190501.73101610': __('identifierSchemeCodeUnspsc190501.73101610'),
	'identifierSchemeCodeUnspsc190501.73101611': __('identifierSchemeCodeUnspsc190501.73101611'),
	'identifierSchemeCodeUnspsc190501.73101612': __('identifierSchemeCodeUnspsc190501.73101612'),
	'identifierSchemeCodeUnspsc190501.73101613': __('identifierSchemeCodeUnspsc190501.73101613'),
	'identifierSchemeCodeUnspsc190501.73101614': __('identifierSchemeCodeUnspsc190501.73101614'),
	'identifierSchemeCodeUnspsc190501.73101700': __('identifierSchemeCodeUnspsc190501.73101700'),
	'identifierSchemeCodeUnspsc190501.73101701': __('identifierSchemeCodeUnspsc190501.73101701'),
	'identifierSchemeCodeUnspsc190501.73101702': __('identifierSchemeCodeUnspsc190501.73101702'),
	'identifierSchemeCodeUnspsc190501.73101703': __('identifierSchemeCodeUnspsc190501.73101703'),
	'identifierSchemeCodeUnspsc190501.73101800': __('identifierSchemeCodeUnspsc190501.73101800'),
	'identifierSchemeCodeUnspsc190501.73101801': __('identifierSchemeCodeUnspsc190501.73101801'),
	'identifierSchemeCodeUnspsc190501.73101802': __('identifierSchemeCodeUnspsc190501.73101802'),
	'identifierSchemeCodeUnspsc190501.73101900': __('identifierSchemeCodeUnspsc190501.73101900'),
	'identifierSchemeCodeUnspsc190501.73101901': __('identifierSchemeCodeUnspsc190501.73101901'),
	'identifierSchemeCodeUnspsc190501.73101902': __('identifierSchemeCodeUnspsc190501.73101902'),
	'identifierSchemeCodeUnspsc190501.73101903': __('identifierSchemeCodeUnspsc190501.73101903'),
	'identifierSchemeCodeUnspsc190501.73110000': __('identifierSchemeCodeUnspsc190501.73110000'),
	'identifierSchemeCodeUnspsc190501.73111500': __('identifierSchemeCodeUnspsc190501.73111500'),
	'identifierSchemeCodeUnspsc190501.73111501': __('identifierSchemeCodeUnspsc190501.73111501'),
	'identifierSchemeCodeUnspsc190501.73111502': __('identifierSchemeCodeUnspsc190501.73111502'),
	'identifierSchemeCodeUnspsc190501.73111503': __('identifierSchemeCodeUnspsc190501.73111503'),
	'identifierSchemeCodeUnspsc190501.73111504': __('identifierSchemeCodeUnspsc190501.73111504'),
	'identifierSchemeCodeUnspsc190501.73111505': __('identifierSchemeCodeUnspsc190501.73111505'),
	'identifierSchemeCodeUnspsc190501.73111506': __('identifierSchemeCodeUnspsc190501.73111506'),
	'identifierSchemeCodeUnspsc190501.73111507': __('identifierSchemeCodeUnspsc190501.73111507'),
	'identifierSchemeCodeUnspsc190501.73111600': __('identifierSchemeCodeUnspsc190501.73111600'),
	'identifierSchemeCodeUnspsc190501.73111601': __('identifierSchemeCodeUnspsc190501.73111601'),
	'identifierSchemeCodeUnspsc190501.73111602': __('identifierSchemeCodeUnspsc190501.73111602'),
	'identifierSchemeCodeUnspsc190501.73111603': __('identifierSchemeCodeUnspsc190501.73111603'),
	'identifierSchemeCodeUnspsc190501.73111604': __('identifierSchemeCodeUnspsc190501.73111604'),
	'identifierSchemeCodeUnspsc190501.73120000': __('identifierSchemeCodeUnspsc190501.73120000'),
	'identifierSchemeCodeUnspsc190501.73121500': __('identifierSchemeCodeUnspsc190501.73121500'),
	'identifierSchemeCodeUnspsc190501.73121501': __('identifierSchemeCodeUnspsc190501.73121501'),
	'identifierSchemeCodeUnspsc190501.73121502': __('identifierSchemeCodeUnspsc190501.73121502'),
	'identifierSchemeCodeUnspsc190501.73121503': __('identifierSchemeCodeUnspsc190501.73121503'),
	'identifierSchemeCodeUnspsc190501.73121504': __('identifierSchemeCodeUnspsc190501.73121504'),
	'identifierSchemeCodeUnspsc190501.73121505': __('identifierSchemeCodeUnspsc190501.73121505'),
	'identifierSchemeCodeUnspsc190501.73121506': __('identifierSchemeCodeUnspsc190501.73121506'),
	'identifierSchemeCodeUnspsc190501.73121507': __('identifierSchemeCodeUnspsc190501.73121507'),
	'identifierSchemeCodeUnspsc190501.73121508': __('identifierSchemeCodeUnspsc190501.73121508'),
	'identifierSchemeCodeUnspsc190501.73121509': __('identifierSchemeCodeUnspsc190501.73121509'),
	'identifierSchemeCodeUnspsc190501.73121600': __('identifierSchemeCodeUnspsc190501.73121600'),
	'identifierSchemeCodeUnspsc190501.73121601': __('identifierSchemeCodeUnspsc190501.73121601'),
	'identifierSchemeCodeUnspsc190501.73121602': __('identifierSchemeCodeUnspsc190501.73121602'),
	'identifierSchemeCodeUnspsc190501.73121603': __('identifierSchemeCodeUnspsc190501.73121603'),
	'identifierSchemeCodeUnspsc190501.73121606': __('identifierSchemeCodeUnspsc190501.73121606'),
	'identifierSchemeCodeUnspsc190501.73121607': __('identifierSchemeCodeUnspsc190501.73121607'),
	'identifierSchemeCodeUnspsc190501.73121608': __('identifierSchemeCodeUnspsc190501.73121608'),
	'identifierSchemeCodeUnspsc190501.73121610': __('identifierSchemeCodeUnspsc190501.73121610'),
	'identifierSchemeCodeUnspsc190501.73121611': __('identifierSchemeCodeUnspsc190501.73121611'),
	'identifierSchemeCodeUnspsc190501.73121612': __('identifierSchemeCodeUnspsc190501.73121612'),
	'identifierSchemeCodeUnspsc190501.73121613': __('identifierSchemeCodeUnspsc190501.73121613'),
	'identifierSchemeCodeUnspsc190501.73121614': __('identifierSchemeCodeUnspsc190501.73121614'),
	'identifierSchemeCodeUnspsc190501.73121800': __('identifierSchemeCodeUnspsc190501.73121800'),
	'identifierSchemeCodeUnspsc190501.73121801': __('identifierSchemeCodeUnspsc190501.73121801'),
	'identifierSchemeCodeUnspsc190501.73121802': __('identifierSchemeCodeUnspsc190501.73121802'),
	'identifierSchemeCodeUnspsc190501.73121803': __('identifierSchemeCodeUnspsc190501.73121803'),
	'identifierSchemeCodeUnspsc190501.73121804': __('identifierSchemeCodeUnspsc190501.73121804'),
	'identifierSchemeCodeUnspsc190501.73121805': __('identifierSchemeCodeUnspsc190501.73121805'),
	'identifierSchemeCodeUnspsc190501.73121806': __('identifierSchemeCodeUnspsc190501.73121806'),
	'identifierSchemeCodeUnspsc190501.73121807': __('identifierSchemeCodeUnspsc190501.73121807'),
	'identifierSchemeCodeUnspsc190501.73130000': __('identifierSchemeCodeUnspsc190501.73130000'),
	'identifierSchemeCodeUnspsc190501.73131500': __('identifierSchemeCodeUnspsc190501.73131500'),
	'identifierSchemeCodeUnspsc190501.73131501': __('identifierSchemeCodeUnspsc190501.73131501'),
	'identifierSchemeCodeUnspsc190501.73131502': __('identifierSchemeCodeUnspsc190501.73131502'),
	'identifierSchemeCodeUnspsc190501.73131503': __('identifierSchemeCodeUnspsc190501.73131503'),
	'identifierSchemeCodeUnspsc190501.73131504': __('identifierSchemeCodeUnspsc190501.73131504'),
	'identifierSchemeCodeUnspsc190501.73131505': __('identifierSchemeCodeUnspsc190501.73131505'),
	'identifierSchemeCodeUnspsc190501.73131506': __('identifierSchemeCodeUnspsc190501.73131506'),
	'identifierSchemeCodeUnspsc190501.73131507': __('identifierSchemeCodeUnspsc190501.73131507'),
	'identifierSchemeCodeUnspsc190501.73131508': __('identifierSchemeCodeUnspsc190501.73131508'),
	'identifierSchemeCodeUnspsc190501.73131600': __('identifierSchemeCodeUnspsc190501.73131600'),
	'identifierSchemeCodeUnspsc190501.73131601': __('identifierSchemeCodeUnspsc190501.73131601'),
	'identifierSchemeCodeUnspsc190501.73131602': __('identifierSchemeCodeUnspsc190501.73131602'),
	'identifierSchemeCodeUnspsc190501.73131603': __('identifierSchemeCodeUnspsc190501.73131603'),
	'identifierSchemeCodeUnspsc190501.73131604': __('identifierSchemeCodeUnspsc190501.73131604'),
	'identifierSchemeCodeUnspsc190501.73131605': __('identifierSchemeCodeUnspsc190501.73131605'),
	'identifierSchemeCodeUnspsc190501.73131606': __('identifierSchemeCodeUnspsc190501.73131606'),
	'identifierSchemeCodeUnspsc190501.73131607': __('identifierSchemeCodeUnspsc190501.73131607'),
	'identifierSchemeCodeUnspsc190501.73131608': __('identifierSchemeCodeUnspsc190501.73131608'),
	'identifierSchemeCodeUnspsc190501.73131700': __('identifierSchemeCodeUnspsc190501.73131700'),
	'identifierSchemeCodeUnspsc190501.73131701': __('identifierSchemeCodeUnspsc190501.73131701'),
	'identifierSchemeCodeUnspsc190501.73131702': __('identifierSchemeCodeUnspsc190501.73131702'),
	'identifierSchemeCodeUnspsc190501.73131703': __('identifierSchemeCodeUnspsc190501.73131703'),
	'identifierSchemeCodeUnspsc190501.73131800': __('identifierSchemeCodeUnspsc190501.73131800'),
	'identifierSchemeCodeUnspsc190501.73131801': __('identifierSchemeCodeUnspsc190501.73131801'),
	'identifierSchemeCodeUnspsc190501.73131802': __('identifierSchemeCodeUnspsc190501.73131802'),
	'identifierSchemeCodeUnspsc190501.73131803': __('identifierSchemeCodeUnspsc190501.73131803'),
	'identifierSchemeCodeUnspsc190501.73131804': __('identifierSchemeCodeUnspsc190501.73131804'),
	'identifierSchemeCodeUnspsc190501.73131900': __('identifierSchemeCodeUnspsc190501.73131900'),
	'identifierSchemeCodeUnspsc190501.73131902': __('identifierSchemeCodeUnspsc190501.73131902'),
	'identifierSchemeCodeUnspsc190501.73131903': __('identifierSchemeCodeUnspsc190501.73131903'),
	'identifierSchemeCodeUnspsc190501.73131904': __('identifierSchemeCodeUnspsc190501.73131904'),
	'identifierSchemeCodeUnspsc190501.73131905': __('identifierSchemeCodeUnspsc190501.73131905'),
	'identifierSchemeCodeUnspsc190501.73131906': __('identifierSchemeCodeUnspsc190501.73131906'),
	'identifierSchemeCodeUnspsc190501.73140000': __('identifierSchemeCodeUnspsc190501.73140000'),
	'identifierSchemeCodeUnspsc190501.73141500': __('identifierSchemeCodeUnspsc190501.73141500'),
	'identifierSchemeCodeUnspsc190501.73141501': __('identifierSchemeCodeUnspsc190501.73141501'),
	'identifierSchemeCodeUnspsc190501.73141502': __('identifierSchemeCodeUnspsc190501.73141502'),
	'identifierSchemeCodeUnspsc190501.73141503': __('identifierSchemeCodeUnspsc190501.73141503'),
	'identifierSchemeCodeUnspsc190501.73141504': __('identifierSchemeCodeUnspsc190501.73141504'),
	'identifierSchemeCodeUnspsc190501.73141505': __('identifierSchemeCodeUnspsc190501.73141505'),
	'identifierSchemeCodeUnspsc190501.73141506': __('identifierSchemeCodeUnspsc190501.73141506'),
	'identifierSchemeCodeUnspsc190501.73141507': __('identifierSchemeCodeUnspsc190501.73141507'),
	'identifierSchemeCodeUnspsc190501.73141508': __('identifierSchemeCodeUnspsc190501.73141508'),
	'identifierSchemeCodeUnspsc190501.73141600': __('identifierSchemeCodeUnspsc190501.73141600'),
	'identifierSchemeCodeUnspsc190501.73141601': __('identifierSchemeCodeUnspsc190501.73141601'),
	'identifierSchemeCodeUnspsc190501.73141602': __('identifierSchemeCodeUnspsc190501.73141602'),
	'identifierSchemeCodeUnspsc190501.73141700': __('identifierSchemeCodeUnspsc190501.73141700'),
	'identifierSchemeCodeUnspsc190501.73141701': __('identifierSchemeCodeUnspsc190501.73141701'),
	'identifierSchemeCodeUnspsc190501.73141702': __('identifierSchemeCodeUnspsc190501.73141702'),
	'identifierSchemeCodeUnspsc190501.73141703': __('identifierSchemeCodeUnspsc190501.73141703'),
	'identifierSchemeCodeUnspsc190501.73141704': __('identifierSchemeCodeUnspsc190501.73141704'),
	'identifierSchemeCodeUnspsc190501.73141705': __('identifierSchemeCodeUnspsc190501.73141705'),
	'identifierSchemeCodeUnspsc190501.73141706': __('identifierSchemeCodeUnspsc190501.73141706'),
	'identifierSchemeCodeUnspsc190501.73141707': __('identifierSchemeCodeUnspsc190501.73141707'),
	'identifierSchemeCodeUnspsc190501.73141708': __('identifierSchemeCodeUnspsc190501.73141708'),
	'identifierSchemeCodeUnspsc190501.73141709': __('identifierSchemeCodeUnspsc190501.73141709'),
	'identifierSchemeCodeUnspsc190501.73141710': __('identifierSchemeCodeUnspsc190501.73141710'),
	'identifierSchemeCodeUnspsc190501.73141711': __('identifierSchemeCodeUnspsc190501.73141711'),
	'identifierSchemeCodeUnspsc190501.73141712': __('identifierSchemeCodeUnspsc190501.73141712'),
	'identifierSchemeCodeUnspsc190501.73141713': __('identifierSchemeCodeUnspsc190501.73141713'),
	'identifierSchemeCodeUnspsc190501.73141714': __('identifierSchemeCodeUnspsc190501.73141714'),
	'identifierSchemeCodeUnspsc190501.73141715': __('identifierSchemeCodeUnspsc190501.73141715'),
	'identifierSchemeCodeUnspsc190501.73150000': __('identifierSchemeCodeUnspsc190501.73150000'),
	'identifierSchemeCodeUnspsc190501.73151500': __('identifierSchemeCodeUnspsc190501.73151500'),
	'identifierSchemeCodeUnspsc190501.73151501': __('identifierSchemeCodeUnspsc190501.73151501'),
	'identifierSchemeCodeUnspsc190501.73151502': __('identifierSchemeCodeUnspsc190501.73151502'),
	'identifierSchemeCodeUnspsc190501.73151503': __('identifierSchemeCodeUnspsc190501.73151503'),
	'identifierSchemeCodeUnspsc190501.73151504': __('identifierSchemeCodeUnspsc190501.73151504'),
	'identifierSchemeCodeUnspsc190501.73151505': __('identifierSchemeCodeUnspsc190501.73151505'),
	'identifierSchemeCodeUnspsc190501.73151506': __('identifierSchemeCodeUnspsc190501.73151506'),
	'identifierSchemeCodeUnspsc190501.73151600': __('identifierSchemeCodeUnspsc190501.73151600'),
	'identifierSchemeCodeUnspsc190501.73151601': __('identifierSchemeCodeUnspsc190501.73151601'),
	'identifierSchemeCodeUnspsc190501.73151602': __('identifierSchemeCodeUnspsc190501.73151602'),
	'identifierSchemeCodeUnspsc190501.73151603': __('identifierSchemeCodeUnspsc190501.73151603'),
	'identifierSchemeCodeUnspsc190501.73151604': __('identifierSchemeCodeUnspsc190501.73151604'),
	'identifierSchemeCodeUnspsc190501.73151605': __('identifierSchemeCodeUnspsc190501.73151605'),
	'identifierSchemeCodeUnspsc190501.73151606': __('identifierSchemeCodeUnspsc190501.73151606'),
	'identifierSchemeCodeUnspsc190501.73151607': __('identifierSchemeCodeUnspsc190501.73151607'),
	'identifierSchemeCodeUnspsc190501.73151608': __('identifierSchemeCodeUnspsc190501.73151608'),
	'identifierSchemeCodeUnspsc190501.73151700': __('identifierSchemeCodeUnspsc190501.73151700'),
	'identifierSchemeCodeUnspsc190501.73151701': __('identifierSchemeCodeUnspsc190501.73151701'),
	'identifierSchemeCodeUnspsc190501.73151702': __('identifierSchemeCodeUnspsc190501.73151702'),
	'identifierSchemeCodeUnspsc190501.73151703': __('identifierSchemeCodeUnspsc190501.73151703'),
	'identifierSchemeCodeUnspsc190501.73151704': __('identifierSchemeCodeUnspsc190501.73151704'),
	'identifierSchemeCodeUnspsc190501.73151705': __('identifierSchemeCodeUnspsc190501.73151705'),
	'identifierSchemeCodeUnspsc190501.73151800': __('identifierSchemeCodeUnspsc190501.73151800'),
	'identifierSchemeCodeUnspsc190501.73151801': __('identifierSchemeCodeUnspsc190501.73151801'),
	'identifierSchemeCodeUnspsc190501.73151802': __('identifierSchemeCodeUnspsc190501.73151802'),
	'identifierSchemeCodeUnspsc190501.73151803': __('identifierSchemeCodeUnspsc190501.73151803'),
	'identifierSchemeCodeUnspsc190501.73151804': __('identifierSchemeCodeUnspsc190501.73151804'),
	'identifierSchemeCodeUnspsc190501.73151805': __('identifierSchemeCodeUnspsc190501.73151805'),
	'identifierSchemeCodeUnspsc190501.73151900': __('identifierSchemeCodeUnspsc190501.73151900'),
	'identifierSchemeCodeUnspsc190501.73151901': __('identifierSchemeCodeUnspsc190501.73151901'),
	'identifierSchemeCodeUnspsc190501.73151902': __('identifierSchemeCodeUnspsc190501.73151902'),
	'identifierSchemeCodeUnspsc190501.73151903': __('identifierSchemeCodeUnspsc190501.73151903'),
	'identifierSchemeCodeUnspsc190501.73151904': __('identifierSchemeCodeUnspsc190501.73151904'),
	'identifierSchemeCodeUnspsc190501.73151905': __('identifierSchemeCodeUnspsc190501.73151905'),
	'identifierSchemeCodeUnspsc190501.73151906': __('identifierSchemeCodeUnspsc190501.73151906'),
	'identifierSchemeCodeUnspsc190501.73151907': __('identifierSchemeCodeUnspsc190501.73151907'),
	'identifierSchemeCodeUnspsc190501.73152000': __('identifierSchemeCodeUnspsc190501.73152000'),
	'identifierSchemeCodeUnspsc190501.73152001': __('identifierSchemeCodeUnspsc190501.73152001'),
	'identifierSchemeCodeUnspsc190501.73152002': __('identifierSchemeCodeUnspsc190501.73152002'),
	'identifierSchemeCodeUnspsc190501.73152003': __('identifierSchemeCodeUnspsc190501.73152003'),
	'identifierSchemeCodeUnspsc190501.73152004': __('identifierSchemeCodeUnspsc190501.73152004'),
	'identifierSchemeCodeUnspsc190501.73152100': __('identifierSchemeCodeUnspsc190501.73152100'),
	'identifierSchemeCodeUnspsc190501.73152101': __('identifierSchemeCodeUnspsc190501.73152101'),
	'identifierSchemeCodeUnspsc190501.73152102': __('identifierSchemeCodeUnspsc190501.73152102'),
	'identifierSchemeCodeUnspsc190501.73152103': __('identifierSchemeCodeUnspsc190501.73152103'),
	'identifierSchemeCodeUnspsc190501.73152104': __('identifierSchemeCodeUnspsc190501.73152104'),
	'identifierSchemeCodeUnspsc190501.73152105': __('identifierSchemeCodeUnspsc190501.73152105'),
	'identifierSchemeCodeUnspsc190501.73152106': __('identifierSchemeCodeUnspsc190501.73152106'),
	'identifierSchemeCodeUnspsc190501.73152107': __('identifierSchemeCodeUnspsc190501.73152107'),
	'identifierSchemeCodeUnspsc190501.73152108': __('identifierSchemeCodeUnspsc190501.73152108'),
	'identifierSchemeCodeUnspsc190501.73152109': __('identifierSchemeCodeUnspsc190501.73152109'),
	'identifierSchemeCodeUnspsc190501.73152112': __('identifierSchemeCodeUnspsc190501.73152112'),
	'identifierSchemeCodeUnspsc190501.73160000': __('identifierSchemeCodeUnspsc190501.73160000'),
	'identifierSchemeCodeUnspsc190501.73161500': __('identifierSchemeCodeUnspsc190501.73161500'),
	'identifierSchemeCodeUnspsc190501.73161501': __('identifierSchemeCodeUnspsc190501.73161501'),
	'identifierSchemeCodeUnspsc190501.73161502': __('identifierSchemeCodeUnspsc190501.73161502'),
	'identifierSchemeCodeUnspsc190501.73161503': __('identifierSchemeCodeUnspsc190501.73161503'),
	'identifierSchemeCodeUnspsc190501.73161504': __('identifierSchemeCodeUnspsc190501.73161504'),
	'identifierSchemeCodeUnspsc190501.73161505': __('identifierSchemeCodeUnspsc190501.73161505'),
	'identifierSchemeCodeUnspsc190501.73161506': __('identifierSchemeCodeUnspsc190501.73161506'),
	'identifierSchemeCodeUnspsc190501.73161507': __('identifierSchemeCodeUnspsc190501.73161507'),
	'identifierSchemeCodeUnspsc190501.73161508': __('identifierSchemeCodeUnspsc190501.73161508'),
	'identifierSchemeCodeUnspsc190501.73161509': __('identifierSchemeCodeUnspsc190501.73161509'),
	'identifierSchemeCodeUnspsc190501.73161510': __('identifierSchemeCodeUnspsc190501.73161510'),
	'identifierSchemeCodeUnspsc190501.73161511': __('identifierSchemeCodeUnspsc190501.73161511'),
	'identifierSchemeCodeUnspsc190501.73161512': __('identifierSchemeCodeUnspsc190501.73161512'),
	'identifierSchemeCodeUnspsc190501.73161513': __('identifierSchemeCodeUnspsc190501.73161513'),
	'identifierSchemeCodeUnspsc190501.73161514': __('identifierSchemeCodeUnspsc190501.73161514'),
	'identifierSchemeCodeUnspsc190501.73161515': __('identifierSchemeCodeUnspsc190501.73161515'),
	'identifierSchemeCodeUnspsc190501.73161516': __('identifierSchemeCodeUnspsc190501.73161516'),
	'identifierSchemeCodeUnspsc190501.73161517': __('identifierSchemeCodeUnspsc190501.73161517'),
	'identifierSchemeCodeUnspsc190501.73161518': __('identifierSchemeCodeUnspsc190501.73161518'),
	'identifierSchemeCodeUnspsc190501.73161519': __('identifierSchemeCodeUnspsc190501.73161519'),
	'identifierSchemeCodeUnspsc190501.73161600': __('identifierSchemeCodeUnspsc190501.73161600'),
	'identifierSchemeCodeUnspsc190501.73161601': __('identifierSchemeCodeUnspsc190501.73161601'),
	'identifierSchemeCodeUnspsc190501.73161602': __('identifierSchemeCodeUnspsc190501.73161602'),
	'identifierSchemeCodeUnspsc190501.73161603': __('identifierSchemeCodeUnspsc190501.73161603'),
	'identifierSchemeCodeUnspsc190501.73161604': __('identifierSchemeCodeUnspsc190501.73161604'),
	'identifierSchemeCodeUnspsc190501.73161605': __('identifierSchemeCodeUnspsc190501.73161605'),
	'identifierSchemeCodeUnspsc190501.73161606': __('identifierSchemeCodeUnspsc190501.73161606'),
	'identifierSchemeCodeUnspsc190501.73161607': __('identifierSchemeCodeUnspsc190501.73161607'),
	'identifierSchemeCodeUnspsc190501.73170000': __('identifierSchemeCodeUnspsc190501.73170000'),
	'identifierSchemeCodeUnspsc190501.73171500': __('identifierSchemeCodeUnspsc190501.73171500'),
	'identifierSchemeCodeUnspsc190501.73171501': __('identifierSchemeCodeUnspsc190501.73171501'),
	'identifierSchemeCodeUnspsc190501.73171502': __('identifierSchemeCodeUnspsc190501.73171502'),
	'identifierSchemeCodeUnspsc190501.73171503': __('identifierSchemeCodeUnspsc190501.73171503'),
	'identifierSchemeCodeUnspsc190501.73171504': __('identifierSchemeCodeUnspsc190501.73171504'),
	'identifierSchemeCodeUnspsc190501.73171505': __('identifierSchemeCodeUnspsc190501.73171505'),
	'identifierSchemeCodeUnspsc190501.73171506': __('identifierSchemeCodeUnspsc190501.73171506'),
	'identifierSchemeCodeUnspsc190501.73171507': __('identifierSchemeCodeUnspsc190501.73171507'),
	'identifierSchemeCodeUnspsc190501.73171508': __('identifierSchemeCodeUnspsc190501.73171508'),
	'identifierSchemeCodeUnspsc190501.73171510': __('identifierSchemeCodeUnspsc190501.73171510'),
	'identifierSchemeCodeUnspsc190501.73171511': __('identifierSchemeCodeUnspsc190501.73171511'),
	'identifierSchemeCodeUnspsc190501.73171512': __('identifierSchemeCodeUnspsc190501.73171512'),
	'identifierSchemeCodeUnspsc190501.73171513': __('identifierSchemeCodeUnspsc190501.73171513'),
	'identifierSchemeCodeUnspsc190501.73171600': __('identifierSchemeCodeUnspsc190501.73171600'),
	'identifierSchemeCodeUnspsc190501.73171601': __('identifierSchemeCodeUnspsc190501.73171601'),
	'identifierSchemeCodeUnspsc190501.73171602': __('identifierSchemeCodeUnspsc190501.73171602'),
	'identifierSchemeCodeUnspsc190501.73171603': __('identifierSchemeCodeUnspsc190501.73171603'),
	'identifierSchemeCodeUnspsc190501.73171604': __('identifierSchemeCodeUnspsc190501.73171604'),
	'identifierSchemeCodeUnspsc190501.73171605': __('identifierSchemeCodeUnspsc190501.73171605'),
	'identifierSchemeCodeUnspsc190501.73171606': __('identifierSchemeCodeUnspsc190501.73171606'),
	'identifierSchemeCodeUnspsc190501.73180000': __('identifierSchemeCodeUnspsc190501.73180000'),
	'identifierSchemeCodeUnspsc190501.73181000': __('identifierSchemeCodeUnspsc190501.73181000'),
	'identifierSchemeCodeUnspsc190501.73181001': __('identifierSchemeCodeUnspsc190501.73181001'),
	'identifierSchemeCodeUnspsc190501.73181002': __('identifierSchemeCodeUnspsc190501.73181002'),
	'identifierSchemeCodeUnspsc190501.73181003': __('identifierSchemeCodeUnspsc190501.73181003'),
	'identifierSchemeCodeUnspsc190501.73181004': __('identifierSchemeCodeUnspsc190501.73181004'),
	'identifierSchemeCodeUnspsc190501.73181005': __('identifierSchemeCodeUnspsc190501.73181005'),
	'identifierSchemeCodeUnspsc190501.73181006': __('identifierSchemeCodeUnspsc190501.73181006'),
	'identifierSchemeCodeUnspsc190501.73181007': __('identifierSchemeCodeUnspsc190501.73181007'),
	'identifierSchemeCodeUnspsc190501.73181008': __('identifierSchemeCodeUnspsc190501.73181008'),
	'identifierSchemeCodeUnspsc190501.73181009': __('identifierSchemeCodeUnspsc190501.73181009'),
	'identifierSchemeCodeUnspsc190501.73181010': __('identifierSchemeCodeUnspsc190501.73181010'),
	'identifierSchemeCodeUnspsc190501.73181011': __('identifierSchemeCodeUnspsc190501.73181011'),
	'identifierSchemeCodeUnspsc190501.73181012': __('identifierSchemeCodeUnspsc190501.73181012'),
	'identifierSchemeCodeUnspsc190501.73181013': __('identifierSchemeCodeUnspsc190501.73181013'),
	'identifierSchemeCodeUnspsc190501.73181014': __('identifierSchemeCodeUnspsc190501.73181014'),
	'identifierSchemeCodeUnspsc190501.73181015': __('identifierSchemeCodeUnspsc190501.73181015'),
	'identifierSchemeCodeUnspsc190501.73181016': __('identifierSchemeCodeUnspsc190501.73181016'),
	'identifierSchemeCodeUnspsc190501.73181017': __('identifierSchemeCodeUnspsc190501.73181017'),
	'identifierSchemeCodeUnspsc190501.73181018': __('identifierSchemeCodeUnspsc190501.73181018'),
	'identifierSchemeCodeUnspsc190501.73181019': __('identifierSchemeCodeUnspsc190501.73181019'),
	'identifierSchemeCodeUnspsc190501.73181020': __('identifierSchemeCodeUnspsc190501.73181020'),
	'identifierSchemeCodeUnspsc190501.73181021': __('identifierSchemeCodeUnspsc190501.73181021'),
	'identifierSchemeCodeUnspsc190501.73181022': __('identifierSchemeCodeUnspsc190501.73181022'),
	'identifierSchemeCodeUnspsc190501.73181023': __('identifierSchemeCodeUnspsc190501.73181023'),
	'identifierSchemeCodeUnspsc190501.73181100': __('identifierSchemeCodeUnspsc190501.73181100'),
	'identifierSchemeCodeUnspsc190501.73181101': __('identifierSchemeCodeUnspsc190501.73181101'),
	'identifierSchemeCodeUnspsc190501.73181102': __('identifierSchemeCodeUnspsc190501.73181102'),
	'identifierSchemeCodeUnspsc190501.73181103': __('identifierSchemeCodeUnspsc190501.73181103'),
	'identifierSchemeCodeUnspsc190501.73181104': __('identifierSchemeCodeUnspsc190501.73181104'),
	'identifierSchemeCodeUnspsc190501.73181105': __('identifierSchemeCodeUnspsc190501.73181105'),
	'identifierSchemeCodeUnspsc190501.73181106': __('identifierSchemeCodeUnspsc190501.73181106'),
	'identifierSchemeCodeUnspsc190501.73181107': __('identifierSchemeCodeUnspsc190501.73181107'),
	'identifierSchemeCodeUnspsc190501.73181108': __('identifierSchemeCodeUnspsc190501.73181108'),
	'identifierSchemeCodeUnspsc190501.73181109': __('identifierSchemeCodeUnspsc190501.73181109'),
	'identifierSchemeCodeUnspsc190501.73181110': __('identifierSchemeCodeUnspsc190501.73181110'),
	'identifierSchemeCodeUnspsc190501.73181111': __('identifierSchemeCodeUnspsc190501.73181111'),
	'identifierSchemeCodeUnspsc190501.73181112': __('identifierSchemeCodeUnspsc190501.73181112'),
	'identifierSchemeCodeUnspsc190501.73181113': __('identifierSchemeCodeUnspsc190501.73181113'),
	'identifierSchemeCodeUnspsc190501.73181114': __('identifierSchemeCodeUnspsc190501.73181114'),
	'identifierSchemeCodeUnspsc190501.73181115': __('identifierSchemeCodeUnspsc190501.73181115'),
	'identifierSchemeCodeUnspsc190501.73181116': __('identifierSchemeCodeUnspsc190501.73181116'),
	'identifierSchemeCodeUnspsc190501.73181117': __('identifierSchemeCodeUnspsc190501.73181117'),
	'identifierSchemeCodeUnspsc190501.73181118': __('identifierSchemeCodeUnspsc190501.73181118'),
	'identifierSchemeCodeUnspsc190501.73181119': __('identifierSchemeCodeUnspsc190501.73181119'),
	'identifierSchemeCodeUnspsc190501.73181120': __('identifierSchemeCodeUnspsc190501.73181120'),
	'identifierSchemeCodeUnspsc190501.73181121': __('identifierSchemeCodeUnspsc190501.73181121'),
	'identifierSchemeCodeUnspsc190501.73181122': __('identifierSchemeCodeUnspsc190501.73181122'),
	'identifierSchemeCodeUnspsc190501.73181123': __('identifierSchemeCodeUnspsc190501.73181123'),
	'identifierSchemeCodeUnspsc190501.73181124': __('identifierSchemeCodeUnspsc190501.73181124'),
	'identifierSchemeCodeUnspsc190501.73181125': __('identifierSchemeCodeUnspsc190501.73181125'),
	'identifierSchemeCodeUnspsc190501.73181200': __('identifierSchemeCodeUnspsc190501.73181200'),
	'identifierSchemeCodeUnspsc190501.73181201': __('identifierSchemeCodeUnspsc190501.73181201'),
	'identifierSchemeCodeUnspsc190501.73181202': __('identifierSchemeCodeUnspsc190501.73181202'),
	'identifierSchemeCodeUnspsc190501.73181203': __('identifierSchemeCodeUnspsc190501.73181203'),
	'identifierSchemeCodeUnspsc190501.73181204': __('identifierSchemeCodeUnspsc190501.73181204'),
	'identifierSchemeCodeUnspsc190501.73181205': __('identifierSchemeCodeUnspsc190501.73181205'),
	'identifierSchemeCodeUnspsc190501.73181206': __('identifierSchemeCodeUnspsc190501.73181206'),
	'identifierSchemeCodeUnspsc190501.73181300': __('identifierSchemeCodeUnspsc190501.73181300'),
	'identifierSchemeCodeUnspsc190501.73181301': __('identifierSchemeCodeUnspsc190501.73181301'),
	'identifierSchemeCodeUnspsc190501.73181302': __('identifierSchemeCodeUnspsc190501.73181302'),
	'identifierSchemeCodeUnspsc190501.73181303': __('identifierSchemeCodeUnspsc190501.73181303'),
	'identifierSchemeCodeUnspsc190501.73181304': __('identifierSchemeCodeUnspsc190501.73181304'),
	'identifierSchemeCodeUnspsc190501.73181305': __('identifierSchemeCodeUnspsc190501.73181305'),
	'identifierSchemeCodeUnspsc190501.73181306': __('identifierSchemeCodeUnspsc190501.73181306'),
	'identifierSchemeCodeUnspsc190501.73181307': __('identifierSchemeCodeUnspsc190501.73181307'),
	'identifierSchemeCodeUnspsc190501.73181308': __('identifierSchemeCodeUnspsc190501.73181308'),
	'identifierSchemeCodeUnspsc190501.73181309': __('identifierSchemeCodeUnspsc190501.73181309'),
	'identifierSchemeCodeUnspsc190501.73181310': __('identifierSchemeCodeUnspsc190501.73181310'),
	'identifierSchemeCodeUnspsc190501.73181311': __('identifierSchemeCodeUnspsc190501.73181311'),
	'identifierSchemeCodeUnspsc190501.73181312': __('identifierSchemeCodeUnspsc190501.73181312'),
	'identifierSchemeCodeUnspsc190501.73181313': __('identifierSchemeCodeUnspsc190501.73181313'),
	'identifierSchemeCodeUnspsc190501.73181314': __('identifierSchemeCodeUnspsc190501.73181314'),
	'identifierSchemeCodeUnspsc190501.73181900': __('identifierSchemeCodeUnspsc190501.73181900'),
	'identifierSchemeCodeUnspsc190501.73181901': __('identifierSchemeCodeUnspsc190501.73181901'),
	'identifierSchemeCodeUnspsc190501.73181902': __('identifierSchemeCodeUnspsc190501.73181902'),
	'identifierSchemeCodeUnspsc190501.73181903': __('identifierSchemeCodeUnspsc190501.73181903'),
	'identifierSchemeCodeUnspsc190501.73181904': __('identifierSchemeCodeUnspsc190501.73181904'),
	'identifierSchemeCodeUnspsc190501.73181905': __('identifierSchemeCodeUnspsc190501.73181905'),
	'identifierSchemeCodeUnspsc190501.73181906': __('identifierSchemeCodeUnspsc190501.73181906'),
	'identifierSchemeCodeUnspsc190501.73181907': __('identifierSchemeCodeUnspsc190501.73181907'),
	'identifierSchemeCodeUnspsc190501.73181908': __('identifierSchemeCodeUnspsc190501.73181908'),
	'identifierSchemeCodeUnspsc190501.76000000': __('identifierSchemeCodeUnspsc190501.76000000'),
	'identifierSchemeCodeUnspsc190501.76100000': __('identifierSchemeCodeUnspsc190501.76100000'),
	'identifierSchemeCodeUnspsc190501.76101500': __('identifierSchemeCodeUnspsc190501.76101500'),
	'identifierSchemeCodeUnspsc190501.76101501': __('identifierSchemeCodeUnspsc190501.76101501'),
	'identifierSchemeCodeUnspsc190501.76101502': __('identifierSchemeCodeUnspsc190501.76101502'),
	'identifierSchemeCodeUnspsc190501.76101503': __('identifierSchemeCodeUnspsc190501.76101503'),
	'identifierSchemeCodeUnspsc190501.76101600': __('identifierSchemeCodeUnspsc190501.76101600'),
	'identifierSchemeCodeUnspsc190501.76101601': __('identifierSchemeCodeUnspsc190501.76101601'),
	'identifierSchemeCodeUnspsc190501.76101602': __('identifierSchemeCodeUnspsc190501.76101602'),
	'identifierSchemeCodeUnspsc190501.76101603': __('identifierSchemeCodeUnspsc190501.76101603'),
	'identifierSchemeCodeUnspsc190501.76101604': __('identifierSchemeCodeUnspsc190501.76101604'),
	'identifierSchemeCodeUnspsc190501.76101605': __('identifierSchemeCodeUnspsc190501.76101605'),
	'identifierSchemeCodeUnspsc190501.76101606': __('identifierSchemeCodeUnspsc190501.76101606'),
	'identifierSchemeCodeUnspsc190501.76110000': __('identifierSchemeCodeUnspsc190501.76110000'),
	'identifierSchemeCodeUnspsc190501.76111500': __('identifierSchemeCodeUnspsc190501.76111500'),
	'identifierSchemeCodeUnspsc190501.76111501': __('identifierSchemeCodeUnspsc190501.76111501'),
	'identifierSchemeCodeUnspsc190501.76111503': __('identifierSchemeCodeUnspsc190501.76111503'),
	'identifierSchemeCodeUnspsc190501.76111504': __('identifierSchemeCodeUnspsc190501.76111504'),
	'identifierSchemeCodeUnspsc190501.76111505': __('identifierSchemeCodeUnspsc190501.76111505'),
	'identifierSchemeCodeUnspsc190501.76111506': __('identifierSchemeCodeUnspsc190501.76111506'),
	'identifierSchemeCodeUnspsc190501.76111507': __('identifierSchemeCodeUnspsc190501.76111507'),
	'identifierSchemeCodeUnspsc190501.76111600': __('identifierSchemeCodeUnspsc190501.76111600'),
	'identifierSchemeCodeUnspsc190501.76111601': __('identifierSchemeCodeUnspsc190501.76111601'),
	'identifierSchemeCodeUnspsc190501.76111602': __('identifierSchemeCodeUnspsc190501.76111602'),
	'identifierSchemeCodeUnspsc190501.76111603': __('identifierSchemeCodeUnspsc190501.76111603'),
	'identifierSchemeCodeUnspsc190501.76111604': __('identifierSchemeCodeUnspsc190501.76111604'),
	'identifierSchemeCodeUnspsc190501.76111605': __('identifierSchemeCodeUnspsc190501.76111605'),
	'identifierSchemeCodeUnspsc190501.76111800': __('identifierSchemeCodeUnspsc190501.76111800'),
	'identifierSchemeCodeUnspsc190501.76111801': __('identifierSchemeCodeUnspsc190501.76111801'),
	'identifierSchemeCodeUnspsc190501.76120000': __('identifierSchemeCodeUnspsc190501.76120000'),
	'identifierSchemeCodeUnspsc190501.76121500': __('identifierSchemeCodeUnspsc190501.76121500'),
	'identifierSchemeCodeUnspsc190501.76121501': __('identifierSchemeCodeUnspsc190501.76121501'),
	'identifierSchemeCodeUnspsc190501.76121502': __('identifierSchemeCodeUnspsc190501.76121502'),
	'identifierSchemeCodeUnspsc190501.76121503': __('identifierSchemeCodeUnspsc190501.76121503'),
	'identifierSchemeCodeUnspsc190501.76121504': __('identifierSchemeCodeUnspsc190501.76121504'),
	'identifierSchemeCodeUnspsc190501.76121505': __('identifierSchemeCodeUnspsc190501.76121505'),
	'identifierSchemeCodeUnspsc190501.76121506': __('identifierSchemeCodeUnspsc190501.76121506'),
	'identifierSchemeCodeUnspsc190501.76121507': __('identifierSchemeCodeUnspsc190501.76121507'),
	'identifierSchemeCodeUnspsc190501.76121600': __('identifierSchemeCodeUnspsc190501.76121600'),
	'identifierSchemeCodeUnspsc190501.76121601': __('identifierSchemeCodeUnspsc190501.76121601'),
	'identifierSchemeCodeUnspsc190501.76121602': __('identifierSchemeCodeUnspsc190501.76121602'),
	'identifierSchemeCodeUnspsc190501.76121603': __('identifierSchemeCodeUnspsc190501.76121603'),
	'identifierSchemeCodeUnspsc190501.76121604': __('identifierSchemeCodeUnspsc190501.76121604'),
	'identifierSchemeCodeUnspsc190501.76121700': __('identifierSchemeCodeUnspsc190501.76121700'),
	'identifierSchemeCodeUnspsc190501.76121701': __('identifierSchemeCodeUnspsc190501.76121701'),
	'identifierSchemeCodeUnspsc190501.76121702': __('identifierSchemeCodeUnspsc190501.76121702'),
	'identifierSchemeCodeUnspsc190501.76121800': __('identifierSchemeCodeUnspsc190501.76121800'),
	'identifierSchemeCodeUnspsc190501.76121900': __('identifierSchemeCodeUnspsc190501.76121900'),
	'identifierSchemeCodeUnspsc190501.76121901': __('identifierSchemeCodeUnspsc190501.76121901'),
	'identifierSchemeCodeUnspsc190501.76121902': __('identifierSchemeCodeUnspsc190501.76121902'),
	'identifierSchemeCodeUnspsc190501.76121903': __('identifierSchemeCodeUnspsc190501.76121903'),
	'identifierSchemeCodeUnspsc190501.76121904': __('identifierSchemeCodeUnspsc190501.76121904'),
	'identifierSchemeCodeUnspsc190501.76121905': __('identifierSchemeCodeUnspsc190501.76121905'),
	'identifierSchemeCodeUnspsc190501.76122000': __('identifierSchemeCodeUnspsc190501.76122000'),
	'identifierSchemeCodeUnspsc190501.76122001': __('identifierSchemeCodeUnspsc190501.76122001'),
	'identifierSchemeCodeUnspsc190501.76122002': __('identifierSchemeCodeUnspsc190501.76122002'),
	'identifierSchemeCodeUnspsc190501.76122003': __('identifierSchemeCodeUnspsc190501.76122003'),
	'identifierSchemeCodeUnspsc190501.76122004': __('identifierSchemeCodeUnspsc190501.76122004'),
	'identifierSchemeCodeUnspsc190501.76122100': __('identifierSchemeCodeUnspsc190501.76122100'),
	'identifierSchemeCodeUnspsc190501.76122101': __('identifierSchemeCodeUnspsc190501.76122101'),
	'identifierSchemeCodeUnspsc190501.76122102': __('identifierSchemeCodeUnspsc190501.76122102'),
	'identifierSchemeCodeUnspsc190501.76122103': __('identifierSchemeCodeUnspsc190501.76122103'),
	'identifierSchemeCodeUnspsc190501.76122200': __('identifierSchemeCodeUnspsc190501.76122200'),
	'identifierSchemeCodeUnspsc190501.76122201': __('identifierSchemeCodeUnspsc190501.76122201'),
	'identifierSchemeCodeUnspsc190501.76122202': __('identifierSchemeCodeUnspsc190501.76122202'),
	'identifierSchemeCodeUnspsc190501.76122203': __('identifierSchemeCodeUnspsc190501.76122203'),
	'identifierSchemeCodeUnspsc190501.76122300': __('identifierSchemeCodeUnspsc190501.76122300'),
	'identifierSchemeCodeUnspsc190501.76122301': __('identifierSchemeCodeUnspsc190501.76122301'),
	'identifierSchemeCodeUnspsc190501.76122302': __('identifierSchemeCodeUnspsc190501.76122302'),
	'identifierSchemeCodeUnspsc190501.76122303': __('identifierSchemeCodeUnspsc190501.76122303'),
	'identifierSchemeCodeUnspsc190501.76122304': __('identifierSchemeCodeUnspsc190501.76122304'),
	'identifierSchemeCodeUnspsc190501.76122305': __('identifierSchemeCodeUnspsc190501.76122305'),
	'identifierSchemeCodeUnspsc190501.76122306': __('identifierSchemeCodeUnspsc190501.76122306'),
	'identifierSchemeCodeUnspsc190501.76122307': __('identifierSchemeCodeUnspsc190501.76122307'),
	'identifierSchemeCodeUnspsc190501.76122308': __('identifierSchemeCodeUnspsc190501.76122308'),
	'identifierSchemeCodeUnspsc190501.76122309': __('identifierSchemeCodeUnspsc190501.76122309'),
	'identifierSchemeCodeUnspsc190501.76122310': __('identifierSchemeCodeUnspsc190501.76122310'),
	'identifierSchemeCodeUnspsc190501.76122311': __('identifierSchemeCodeUnspsc190501.76122311'),
	'identifierSchemeCodeUnspsc190501.76122312': __('identifierSchemeCodeUnspsc190501.76122312'),
	'identifierSchemeCodeUnspsc190501.76122313': __('identifierSchemeCodeUnspsc190501.76122313'),
	'identifierSchemeCodeUnspsc190501.76122314': __('identifierSchemeCodeUnspsc190501.76122314'),
	'identifierSchemeCodeUnspsc190501.76122315': __('identifierSchemeCodeUnspsc190501.76122315'),
	'identifierSchemeCodeUnspsc190501.76122400': __('identifierSchemeCodeUnspsc190501.76122400'),
	'identifierSchemeCodeUnspsc190501.76122401': __('identifierSchemeCodeUnspsc190501.76122401'),
	'identifierSchemeCodeUnspsc190501.76122402': __('identifierSchemeCodeUnspsc190501.76122402'),
	'identifierSchemeCodeUnspsc190501.76122403': __('identifierSchemeCodeUnspsc190501.76122403'),
	'identifierSchemeCodeUnspsc190501.76122404': __('identifierSchemeCodeUnspsc190501.76122404'),
	'identifierSchemeCodeUnspsc190501.76122405': __('identifierSchemeCodeUnspsc190501.76122405'),
	'identifierSchemeCodeUnspsc190501.76122406': __('identifierSchemeCodeUnspsc190501.76122406'),
	'identifierSchemeCodeUnspsc190501.76122407': __('identifierSchemeCodeUnspsc190501.76122407'),
	'identifierSchemeCodeUnspsc190501.76122408': __('identifierSchemeCodeUnspsc190501.76122408'),
	'identifierSchemeCodeUnspsc190501.76130000': __('identifierSchemeCodeUnspsc190501.76130000'),
	'identifierSchemeCodeUnspsc190501.76131500': __('identifierSchemeCodeUnspsc190501.76131500'),
	'identifierSchemeCodeUnspsc190501.76131501': __('identifierSchemeCodeUnspsc190501.76131501'),
	'identifierSchemeCodeUnspsc190501.76131502': __('identifierSchemeCodeUnspsc190501.76131502'),
	'identifierSchemeCodeUnspsc190501.76131600': __('identifierSchemeCodeUnspsc190501.76131600'),
	'identifierSchemeCodeUnspsc190501.76131601': __('identifierSchemeCodeUnspsc190501.76131601'),
	'identifierSchemeCodeUnspsc190501.76131602': __('identifierSchemeCodeUnspsc190501.76131602'),
	'identifierSchemeCodeUnspsc190501.76131700': __('identifierSchemeCodeUnspsc190501.76131700'),
	'identifierSchemeCodeUnspsc190501.76131701': __('identifierSchemeCodeUnspsc190501.76131701'),
	'identifierSchemeCodeUnspsc190501.76131702': __('identifierSchemeCodeUnspsc190501.76131702'),
	'identifierSchemeCodeUnspsc190501.77000000': __('identifierSchemeCodeUnspsc190501.77000000'),
	'identifierSchemeCodeUnspsc190501.77100000': __('identifierSchemeCodeUnspsc190501.77100000'),
	'identifierSchemeCodeUnspsc190501.77101500': __('identifierSchemeCodeUnspsc190501.77101500'),
	'identifierSchemeCodeUnspsc190501.77101501': __('identifierSchemeCodeUnspsc190501.77101501'),
	'identifierSchemeCodeUnspsc190501.77101502': __('identifierSchemeCodeUnspsc190501.77101502'),
	'identifierSchemeCodeUnspsc190501.77101503': __('identifierSchemeCodeUnspsc190501.77101503'),
	'identifierSchemeCodeUnspsc190501.77101504': __('identifierSchemeCodeUnspsc190501.77101504'),
	'identifierSchemeCodeUnspsc190501.77101505': __('identifierSchemeCodeUnspsc190501.77101505'),
	'identifierSchemeCodeUnspsc190501.77101506': __('identifierSchemeCodeUnspsc190501.77101506'),
	'identifierSchemeCodeUnspsc190501.77101600': __('identifierSchemeCodeUnspsc190501.77101600'),
	'identifierSchemeCodeUnspsc190501.77101601': __('identifierSchemeCodeUnspsc190501.77101601'),
	'identifierSchemeCodeUnspsc190501.77101602': __('identifierSchemeCodeUnspsc190501.77101602'),
	'identifierSchemeCodeUnspsc190501.77101603': __('identifierSchemeCodeUnspsc190501.77101603'),
	'identifierSchemeCodeUnspsc190501.77101604': __('identifierSchemeCodeUnspsc190501.77101604'),
	'identifierSchemeCodeUnspsc190501.77101605': __('identifierSchemeCodeUnspsc190501.77101605'),
	'identifierSchemeCodeUnspsc190501.77101700': __('identifierSchemeCodeUnspsc190501.77101700'),
	'identifierSchemeCodeUnspsc190501.77101701': __('identifierSchemeCodeUnspsc190501.77101701'),
	'identifierSchemeCodeUnspsc190501.77101702': __('identifierSchemeCodeUnspsc190501.77101702'),
	'identifierSchemeCodeUnspsc190501.77101703': __('identifierSchemeCodeUnspsc190501.77101703'),
	'identifierSchemeCodeUnspsc190501.77101704': __('identifierSchemeCodeUnspsc190501.77101704'),
	'identifierSchemeCodeUnspsc190501.77101705': __('identifierSchemeCodeUnspsc190501.77101705'),
	'identifierSchemeCodeUnspsc190501.77101706': __('identifierSchemeCodeUnspsc190501.77101706'),
	'identifierSchemeCodeUnspsc190501.77101707': __('identifierSchemeCodeUnspsc190501.77101707'),
	'identifierSchemeCodeUnspsc190501.77101800': __('identifierSchemeCodeUnspsc190501.77101800'),
	'identifierSchemeCodeUnspsc190501.77101801': __('identifierSchemeCodeUnspsc190501.77101801'),
	'identifierSchemeCodeUnspsc190501.77101802': __('identifierSchemeCodeUnspsc190501.77101802'),
	'identifierSchemeCodeUnspsc190501.77101803': __('identifierSchemeCodeUnspsc190501.77101803'),
	'identifierSchemeCodeUnspsc190501.77101804': __('identifierSchemeCodeUnspsc190501.77101804'),
	'identifierSchemeCodeUnspsc190501.77101805': __('identifierSchemeCodeUnspsc190501.77101805'),
	'identifierSchemeCodeUnspsc190501.77101806': __('identifierSchemeCodeUnspsc190501.77101806'),
	'identifierSchemeCodeUnspsc190501.77101900': __('identifierSchemeCodeUnspsc190501.77101900'),
	'identifierSchemeCodeUnspsc190501.77101901': __('identifierSchemeCodeUnspsc190501.77101901'),
	'identifierSchemeCodeUnspsc190501.77101902': __('identifierSchemeCodeUnspsc190501.77101902'),
	'identifierSchemeCodeUnspsc190501.77101903': __('identifierSchemeCodeUnspsc190501.77101903'),
	'identifierSchemeCodeUnspsc190501.77101904': __('identifierSchemeCodeUnspsc190501.77101904'),
	'identifierSchemeCodeUnspsc190501.77101905': __('identifierSchemeCodeUnspsc190501.77101905'),
	'identifierSchemeCodeUnspsc190501.77101906': __('identifierSchemeCodeUnspsc190501.77101906'),
	'identifierSchemeCodeUnspsc190501.77101907': __('identifierSchemeCodeUnspsc190501.77101907'),
	'identifierSchemeCodeUnspsc190501.77101908': __('identifierSchemeCodeUnspsc190501.77101908'),
	'identifierSchemeCodeUnspsc190501.77101909': __('identifierSchemeCodeUnspsc190501.77101909'),
	'identifierSchemeCodeUnspsc190501.77101910': __('identifierSchemeCodeUnspsc190501.77101910'),
	'identifierSchemeCodeUnspsc190501.77102000': __('identifierSchemeCodeUnspsc190501.77102000'),
	'identifierSchemeCodeUnspsc190501.77102001': __('identifierSchemeCodeUnspsc190501.77102001'),
	'identifierSchemeCodeUnspsc190501.77102002': __('identifierSchemeCodeUnspsc190501.77102002'),
	'identifierSchemeCodeUnspsc190501.77102003': __('identifierSchemeCodeUnspsc190501.77102003'),
	'identifierSchemeCodeUnspsc190501.77102004': __('identifierSchemeCodeUnspsc190501.77102004'),
	'identifierSchemeCodeUnspsc190501.77110000': __('identifierSchemeCodeUnspsc190501.77110000'),
	'identifierSchemeCodeUnspsc190501.77111500': __('identifierSchemeCodeUnspsc190501.77111500'),
	'identifierSchemeCodeUnspsc190501.77111501': __('identifierSchemeCodeUnspsc190501.77111501'),
	'identifierSchemeCodeUnspsc190501.77111502': __('identifierSchemeCodeUnspsc190501.77111502'),
	'identifierSchemeCodeUnspsc190501.77111503': __('identifierSchemeCodeUnspsc190501.77111503'),
	'identifierSchemeCodeUnspsc190501.77111504': __('identifierSchemeCodeUnspsc190501.77111504'),
	'identifierSchemeCodeUnspsc190501.77111505': __('identifierSchemeCodeUnspsc190501.77111505'),
	'identifierSchemeCodeUnspsc190501.77111506': __('identifierSchemeCodeUnspsc190501.77111506'),
	'identifierSchemeCodeUnspsc190501.77111507': __('identifierSchemeCodeUnspsc190501.77111507'),
	'identifierSchemeCodeUnspsc190501.77111508': __('identifierSchemeCodeUnspsc190501.77111508'),
	'identifierSchemeCodeUnspsc190501.77111600': __('identifierSchemeCodeUnspsc190501.77111600'),
	'identifierSchemeCodeUnspsc190501.77111601': __('identifierSchemeCodeUnspsc190501.77111601'),
	'identifierSchemeCodeUnspsc190501.77111602': __('identifierSchemeCodeUnspsc190501.77111602'),
	'identifierSchemeCodeUnspsc190501.77111603': __('identifierSchemeCodeUnspsc190501.77111603'),
	'identifierSchemeCodeUnspsc190501.77120000': __('identifierSchemeCodeUnspsc190501.77120000'),
	'identifierSchemeCodeUnspsc190501.77121500': __('identifierSchemeCodeUnspsc190501.77121500'),
	'identifierSchemeCodeUnspsc190501.77121501': __('identifierSchemeCodeUnspsc190501.77121501'),
	'identifierSchemeCodeUnspsc190501.77121502': __('identifierSchemeCodeUnspsc190501.77121502'),
	'identifierSchemeCodeUnspsc190501.77121503': __('identifierSchemeCodeUnspsc190501.77121503'),
	'identifierSchemeCodeUnspsc190501.77121504': __('identifierSchemeCodeUnspsc190501.77121504'),
	'identifierSchemeCodeUnspsc190501.77121505': __('identifierSchemeCodeUnspsc190501.77121505'),
	'identifierSchemeCodeUnspsc190501.77121506': __('identifierSchemeCodeUnspsc190501.77121506'),
	'identifierSchemeCodeUnspsc190501.77121507': __('identifierSchemeCodeUnspsc190501.77121507'),
	'identifierSchemeCodeUnspsc190501.77121508': __('identifierSchemeCodeUnspsc190501.77121508'),
	'identifierSchemeCodeUnspsc190501.77121509': __('identifierSchemeCodeUnspsc190501.77121509'),
	'identifierSchemeCodeUnspsc190501.77121600': __('identifierSchemeCodeUnspsc190501.77121600'),
	'identifierSchemeCodeUnspsc190501.77121601': __('identifierSchemeCodeUnspsc190501.77121601'),
	'identifierSchemeCodeUnspsc190501.77121602': __('identifierSchemeCodeUnspsc190501.77121602'),
	'identifierSchemeCodeUnspsc190501.77121603': __('identifierSchemeCodeUnspsc190501.77121603'),
	'identifierSchemeCodeUnspsc190501.77121604': __('identifierSchemeCodeUnspsc190501.77121604'),
	'identifierSchemeCodeUnspsc190501.77121605': __('identifierSchemeCodeUnspsc190501.77121605'),
	'identifierSchemeCodeUnspsc190501.77121606': __('identifierSchemeCodeUnspsc190501.77121606'),
	'identifierSchemeCodeUnspsc190501.77121607': __('identifierSchemeCodeUnspsc190501.77121607'),
	'identifierSchemeCodeUnspsc190501.77121608': __('identifierSchemeCodeUnspsc190501.77121608'),
	'identifierSchemeCodeUnspsc190501.77121609': __('identifierSchemeCodeUnspsc190501.77121609'),
	'identifierSchemeCodeUnspsc190501.77121610': __('identifierSchemeCodeUnspsc190501.77121610'),
	'identifierSchemeCodeUnspsc190501.77121700': __('identifierSchemeCodeUnspsc190501.77121700'),
	'identifierSchemeCodeUnspsc190501.77121701': __('identifierSchemeCodeUnspsc190501.77121701'),
	'identifierSchemeCodeUnspsc190501.77121702': __('identifierSchemeCodeUnspsc190501.77121702'),
	'identifierSchemeCodeUnspsc190501.77121703': __('identifierSchemeCodeUnspsc190501.77121703'),
	'identifierSchemeCodeUnspsc190501.77121704': __('identifierSchemeCodeUnspsc190501.77121704'),
	'identifierSchemeCodeUnspsc190501.77121705': __('identifierSchemeCodeUnspsc190501.77121705'),
	'identifierSchemeCodeUnspsc190501.77121706': __('identifierSchemeCodeUnspsc190501.77121706'),
	'identifierSchemeCodeUnspsc190501.77121707': __('identifierSchemeCodeUnspsc190501.77121707'),
	'identifierSchemeCodeUnspsc190501.77121708': __('identifierSchemeCodeUnspsc190501.77121708'),
	'identifierSchemeCodeUnspsc190501.77121709': __('identifierSchemeCodeUnspsc190501.77121709'),
	'identifierSchemeCodeUnspsc190501.77130000': __('identifierSchemeCodeUnspsc190501.77130000'),
	'identifierSchemeCodeUnspsc190501.77131500': __('identifierSchemeCodeUnspsc190501.77131500'),
	'identifierSchemeCodeUnspsc190501.77131501': __('identifierSchemeCodeUnspsc190501.77131501'),
	'identifierSchemeCodeUnspsc190501.77131502': __('identifierSchemeCodeUnspsc190501.77131502'),
	'identifierSchemeCodeUnspsc190501.77131503': __('identifierSchemeCodeUnspsc190501.77131503'),
	'identifierSchemeCodeUnspsc190501.77131600': __('identifierSchemeCodeUnspsc190501.77131600'),
	'identifierSchemeCodeUnspsc190501.77131601': __('identifierSchemeCodeUnspsc190501.77131601'),
	'identifierSchemeCodeUnspsc190501.77131602': __('identifierSchemeCodeUnspsc190501.77131602'),
	'identifierSchemeCodeUnspsc190501.77131603': __('identifierSchemeCodeUnspsc190501.77131603'),
	'identifierSchemeCodeUnspsc190501.77131604': __('identifierSchemeCodeUnspsc190501.77131604'),
	'identifierSchemeCodeUnspsc190501.77131700': __('identifierSchemeCodeUnspsc190501.77131700'),
	'identifierSchemeCodeUnspsc190501.77131701': __('identifierSchemeCodeUnspsc190501.77131701'),
	'identifierSchemeCodeUnspsc190501.77131702': __('identifierSchemeCodeUnspsc190501.77131702'),
	'identifierSchemeCodeUnspsc190501.77140000': __('identifierSchemeCodeUnspsc190501.77140000'),
	'identifierSchemeCodeUnspsc190501.77141500': __('identifierSchemeCodeUnspsc190501.77141500'),
	'identifierSchemeCodeUnspsc190501.77141501': __('identifierSchemeCodeUnspsc190501.77141501'),
	'identifierSchemeCodeUnspsc190501.77141502': __('identifierSchemeCodeUnspsc190501.77141502'),
	'identifierSchemeCodeUnspsc190501.77141503': __('identifierSchemeCodeUnspsc190501.77141503'),
	'identifierSchemeCodeUnspsc190501.77141504': __('identifierSchemeCodeUnspsc190501.77141504'),
	'identifierSchemeCodeUnspsc190501.77141505': __('identifierSchemeCodeUnspsc190501.77141505'),
	'identifierSchemeCodeUnspsc190501.77141600': __('identifierSchemeCodeUnspsc190501.77141600'),
	'identifierSchemeCodeUnspsc190501.77141601': __('identifierSchemeCodeUnspsc190501.77141601'),
	'identifierSchemeCodeUnspsc190501.77141602': __('identifierSchemeCodeUnspsc190501.77141602'),
	'identifierSchemeCodeUnspsc190501.77141603': __('identifierSchemeCodeUnspsc190501.77141603'),
	'identifierSchemeCodeUnspsc190501.77141604': __('identifierSchemeCodeUnspsc190501.77141604'),
	'identifierSchemeCodeUnspsc190501.77141605': __('identifierSchemeCodeUnspsc190501.77141605'),
	'identifierSchemeCodeUnspsc190501.77141700': __('identifierSchemeCodeUnspsc190501.77141700'),
	'identifierSchemeCodeUnspsc190501.77141701': __('identifierSchemeCodeUnspsc190501.77141701'),
	'identifierSchemeCodeUnspsc190501.77141702': __('identifierSchemeCodeUnspsc190501.77141702'),
	'identifierSchemeCodeUnspsc190501.77141703': __('identifierSchemeCodeUnspsc190501.77141703'),
	'identifierSchemeCodeUnspsc190501.77141704': __('identifierSchemeCodeUnspsc190501.77141704'),
	'identifierSchemeCodeUnspsc190501.77141705': __('identifierSchemeCodeUnspsc190501.77141705'),
	'identifierSchemeCodeUnspsc190501.78000000': __('identifierSchemeCodeUnspsc190501.78000000'),
	'identifierSchemeCodeUnspsc190501.78100000': __('identifierSchemeCodeUnspsc190501.78100000'),
	'identifierSchemeCodeUnspsc190501.78101500': __('identifierSchemeCodeUnspsc190501.78101500'),
	'identifierSchemeCodeUnspsc190501.78101501': __('identifierSchemeCodeUnspsc190501.78101501'),
	'identifierSchemeCodeUnspsc190501.78101502': __('identifierSchemeCodeUnspsc190501.78101502'),
	'identifierSchemeCodeUnspsc190501.78101503': __('identifierSchemeCodeUnspsc190501.78101503'),
	'identifierSchemeCodeUnspsc190501.78101504': __('identifierSchemeCodeUnspsc190501.78101504'),
	'identifierSchemeCodeUnspsc190501.78101505': __('identifierSchemeCodeUnspsc190501.78101505'),
	'identifierSchemeCodeUnspsc190501.78101600': __('identifierSchemeCodeUnspsc190501.78101600'),
	'identifierSchemeCodeUnspsc190501.78101601': __('identifierSchemeCodeUnspsc190501.78101601'),
	'identifierSchemeCodeUnspsc190501.78101602': __('identifierSchemeCodeUnspsc190501.78101602'),
	'identifierSchemeCodeUnspsc190501.78101603': __('identifierSchemeCodeUnspsc190501.78101603'),
	'identifierSchemeCodeUnspsc190501.78101604': __('identifierSchemeCodeUnspsc190501.78101604'),
	'identifierSchemeCodeUnspsc190501.78101605': __('identifierSchemeCodeUnspsc190501.78101605'),
	'identifierSchemeCodeUnspsc190501.78101606': __('identifierSchemeCodeUnspsc190501.78101606'),
	'identifierSchemeCodeUnspsc190501.78101607': __('identifierSchemeCodeUnspsc190501.78101607'),
	'identifierSchemeCodeUnspsc190501.78101608': __('identifierSchemeCodeUnspsc190501.78101608'),
	'identifierSchemeCodeUnspsc190501.78101609': __('identifierSchemeCodeUnspsc190501.78101609'),
	'identifierSchemeCodeUnspsc190501.78101610': __('identifierSchemeCodeUnspsc190501.78101610'),
	'identifierSchemeCodeUnspsc190501.78101700': __('identifierSchemeCodeUnspsc190501.78101700'),
	'identifierSchemeCodeUnspsc190501.78101701': __('identifierSchemeCodeUnspsc190501.78101701'),
	'identifierSchemeCodeUnspsc190501.78101702': __('identifierSchemeCodeUnspsc190501.78101702'),
	'identifierSchemeCodeUnspsc190501.78101703': __('identifierSchemeCodeUnspsc190501.78101703'),
	'identifierSchemeCodeUnspsc190501.78101704': __('identifierSchemeCodeUnspsc190501.78101704'),
	'identifierSchemeCodeUnspsc190501.78101705': __('identifierSchemeCodeUnspsc190501.78101705'),
	'identifierSchemeCodeUnspsc190501.78101706': __('identifierSchemeCodeUnspsc190501.78101706'),
	'identifierSchemeCodeUnspsc190501.78101707': __('identifierSchemeCodeUnspsc190501.78101707'),
	'identifierSchemeCodeUnspsc190501.78101708': __('identifierSchemeCodeUnspsc190501.78101708'),
	'identifierSchemeCodeUnspsc190501.78101709': __('identifierSchemeCodeUnspsc190501.78101709'),
	'identifierSchemeCodeUnspsc190501.78101710': __('identifierSchemeCodeUnspsc190501.78101710'),
	'identifierSchemeCodeUnspsc190501.78101711': __('identifierSchemeCodeUnspsc190501.78101711'),
	'identifierSchemeCodeUnspsc190501.78101800': __('identifierSchemeCodeUnspsc190501.78101800'),
	'identifierSchemeCodeUnspsc190501.78101801': __('identifierSchemeCodeUnspsc190501.78101801'),
	'identifierSchemeCodeUnspsc190501.78101802': __('identifierSchemeCodeUnspsc190501.78101802'),
	'identifierSchemeCodeUnspsc190501.78101803': __('identifierSchemeCodeUnspsc190501.78101803'),
	'identifierSchemeCodeUnspsc190501.78101804': __('identifierSchemeCodeUnspsc190501.78101804'),
	'identifierSchemeCodeUnspsc190501.78101805': __('identifierSchemeCodeUnspsc190501.78101805'),
	'identifierSchemeCodeUnspsc190501.78101806': __('identifierSchemeCodeUnspsc190501.78101806'),
	'identifierSchemeCodeUnspsc190501.78101807': __('identifierSchemeCodeUnspsc190501.78101807'),
	'identifierSchemeCodeUnspsc190501.78101808': __('identifierSchemeCodeUnspsc190501.78101808'),
	'identifierSchemeCodeUnspsc190501.78101809': __('identifierSchemeCodeUnspsc190501.78101809'),
	'identifierSchemeCodeUnspsc190501.78101810': __('identifierSchemeCodeUnspsc190501.78101810'),
	'identifierSchemeCodeUnspsc190501.78101900': __('identifierSchemeCodeUnspsc190501.78101900'),
	'identifierSchemeCodeUnspsc190501.78101901': __('identifierSchemeCodeUnspsc190501.78101901'),
	'identifierSchemeCodeUnspsc190501.78101902': __('identifierSchemeCodeUnspsc190501.78101902'),
	'identifierSchemeCodeUnspsc190501.78101903': __('identifierSchemeCodeUnspsc190501.78101903'),
	'identifierSchemeCodeUnspsc190501.78101904': __('identifierSchemeCodeUnspsc190501.78101904'),
	'identifierSchemeCodeUnspsc190501.78101905': __('identifierSchemeCodeUnspsc190501.78101905'),
	'identifierSchemeCodeUnspsc190501.78102000': __('identifierSchemeCodeUnspsc190501.78102000'),
	'identifierSchemeCodeUnspsc190501.78102001': __('identifierSchemeCodeUnspsc190501.78102001'),
	'identifierSchemeCodeUnspsc190501.78102002': __('identifierSchemeCodeUnspsc190501.78102002'),
	'identifierSchemeCodeUnspsc190501.78102100': __('identifierSchemeCodeUnspsc190501.78102100'),
	'identifierSchemeCodeUnspsc190501.78102101': __('identifierSchemeCodeUnspsc190501.78102101'),
	'identifierSchemeCodeUnspsc190501.78102102': __('identifierSchemeCodeUnspsc190501.78102102'),
	'identifierSchemeCodeUnspsc190501.78102103': __('identifierSchemeCodeUnspsc190501.78102103'),
	'identifierSchemeCodeUnspsc190501.78102200': __('identifierSchemeCodeUnspsc190501.78102200'),
	'identifierSchemeCodeUnspsc190501.78102201': __('identifierSchemeCodeUnspsc190501.78102201'),
	'identifierSchemeCodeUnspsc190501.78102202': __('identifierSchemeCodeUnspsc190501.78102202'),
	'identifierSchemeCodeUnspsc190501.78102203': __('identifierSchemeCodeUnspsc190501.78102203'),
	'identifierSchemeCodeUnspsc190501.78102204': __('identifierSchemeCodeUnspsc190501.78102204'),
	'identifierSchemeCodeUnspsc190501.78102205': __('identifierSchemeCodeUnspsc190501.78102205'),
	'identifierSchemeCodeUnspsc190501.78102206': __('identifierSchemeCodeUnspsc190501.78102206'),
	'identifierSchemeCodeUnspsc190501.78110000': __('identifierSchemeCodeUnspsc190501.78110000'),
	'identifierSchemeCodeUnspsc190501.78111500': __('identifierSchemeCodeUnspsc190501.78111500'),
	'identifierSchemeCodeUnspsc190501.78111501': __('identifierSchemeCodeUnspsc190501.78111501'),
	'identifierSchemeCodeUnspsc190501.78111502': __('identifierSchemeCodeUnspsc190501.78111502'),
	'identifierSchemeCodeUnspsc190501.78111503': __('identifierSchemeCodeUnspsc190501.78111503'),
	'identifierSchemeCodeUnspsc190501.78111504': __('identifierSchemeCodeUnspsc190501.78111504'),
	'identifierSchemeCodeUnspsc190501.78111505': __('identifierSchemeCodeUnspsc190501.78111505'),
	'identifierSchemeCodeUnspsc190501.78111506': __('identifierSchemeCodeUnspsc190501.78111506'),
	'identifierSchemeCodeUnspsc190501.78111600': __('identifierSchemeCodeUnspsc190501.78111600'),
	'identifierSchemeCodeUnspsc190501.78111601': __('identifierSchemeCodeUnspsc190501.78111601'),
	'identifierSchemeCodeUnspsc190501.78111602': __('identifierSchemeCodeUnspsc190501.78111602'),
	'identifierSchemeCodeUnspsc190501.78111603': __('identifierSchemeCodeUnspsc190501.78111603'),
	'identifierSchemeCodeUnspsc190501.78111604': __('identifierSchemeCodeUnspsc190501.78111604'),
	'identifierSchemeCodeUnspsc190501.78111700': __('identifierSchemeCodeUnspsc190501.78111700'),
	'identifierSchemeCodeUnspsc190501.78111701': __('identifierSchemeCodeUnspsc190501.78111701'),
	'identifierSchemeCodeUnspsc190501.78111702': __('identifierSchemeCodeUnspsc190501.78111702'),
	'identifierSchemeCodeUnspsc190501.78111703': __('identifierSchemeCodeUnspsc190501.78111703'),
	'identifierSchemeCodeUnspsc190501.78111704': __('identifierSchemeCodeUnspsc190501.78111704'),
	'identifierSchemeCodeUnspsc190501.78111705': __('identifierSchemeCodeUnspsc190501.78111705'),
	'identifierSchemeCodeUnspsc190501.78111706': __('identifierSchemeCodeUnspsc190501.78111706'),
	'identifierSchemeCodeUnspsc190501.78111707': __('identifierSchemeCodeUnspsc190501.78111707'),
	'identifierSchemeCodeUnspsc190501.78111800': __('identifierSchemeCodeUnspsc190501.78111800'),
	'identifierSchemeCodeUnspsc190501.78111802': __('identifierSchemeCodeUnspsc190501.78111802'),
	'identifierSchemeCodeUnspsc190501.78111803': __('identifierSchemeCodeUnspsc190501.78111803'),
	'identifierSchemeCodeUnspsc190501.78111804': __('identifierSchemeCodeUnspsc190501.78111804'),
	'identifierSchemeCodeUnspsc190501.78111807': __('identifierSchemeCodeUnspsc190501.78111807'),
	'identifierSchemeCodeUnspsc190501.78111808': __('identifierSchemeCodeUnspsc190501.78111808'),
	'identifierSchemeCodeUnspsc190501.78111809': __('identifierSchemeCodeUnspsc190501.78111809'),
	'identifierSchemeCodeUnspsc190501.78111810': __('identifierSchemeCodeUnspsc190501.78111810'),
	'identifierSchemeCodeUnspsc190501.78111811': __('identifierSchemeCodeUnspsc190501.78111811'),
	'identifierSchemeCodeUnspsc190501.78111812': __('identifierSchemeCodeUnspsc190501.78111812'),
	'identifierSchemeCodeUnspsc190501.78111813': __('identifierSchemeCodeUnspsc190501.78111813'),
	'identifierSchemeCodeUnspsc190501.78111814': __('identifierSchemeCodeUnspsc190501.78111814'),
	'identifierSchemeCodeUnspsc190501.78111815': __('identifierSchemeCodeUnspsc190501.78111815'),
	'identifierSchemeCodeUnspsc190501.78111900': __('identifierSchemeCodeUnspsc190501.78111900'),
	'identifierSchemeCodeUnspsc190501.78111901': __('identifierSchemeCodeUnspsc190501.78111901'),
	'identifierSchemeCodeUnspsc190501.78112000': __('identifierSchemeCodeUnspsc190501.78112000'),
	'identifierSchemeCodeUnspsc190501.78112001': __('identifierSchemeCodeUnspsc190501.78112001'),
	'identifierSchemeCodeUnspsc190501.78120000': __('identifierSchemeCodeUnspsc190501.78120000'),
	'identifierSchemeCodeUnspsc190501.78121500': __('identifierSchemeCodeUnspsc190501.78121500'),
	'identifierSchemeCodeUnspsc190501.78121501': __('identifierSchemeCodeUnspsc190501.78121501'),
	'identifierSchemeCodeUnspsc190501.78121502': __('identifierSchemeCodeUnspsc190501.78121502'),
	'identifierSchemeCodeUnspsc190501.78121600': __('identifierSchemeCodeUnspsc190501.78121600'),
	'identifierSchemeCodeUnspsc190501.78121601': __('identifierSchemeCodeUnspsc190501.78121601'),
	'identifierSchemeCodeUnspsc190501.78121602': __('identifierSchemeCodeUnspsc190501.78121602'),
	'identifierSchemeCodeUnspsc190501.78121603': __('identifierSchemeCodeUnspsc190501.78121603'),
	'identifierSchemeCodeUnspsc190501.78121604': __('identifierSchemeCodeUnspsc190501.78121604'),
	'identifierSchemeCodeUnspsc190501.78130000': __('identifierSchemeCodeUnspsc190501.78130000'),
	'identifierSchemeCodeUnspsc190501.78131500': __('identifierSchemeCodeUnspsc190501.78131500'),
	'identifierSchemeCodeUnspsc190501.78131501': __('identifierSchemeCodeUnspsc190501.78131501'),
	'identifierSchemeCodeUnspsc190501.78131502': __('identifierSchemeCodeUnspsc190501.78131502'),
	'identifierSchemeCodeUnspsc190501.78131600': __('identifierSchemeCodeUnspsc190501.78131600'),
	'identifierSchemeCodeUnspsc190501.78131601': __('identifierSchemeCodeUnspsc190501.78131601'),
	'identifierSchemeCodeUnspsc190501.78131602': __('identifierSchemeCodeUnspsc190501.78131602'),
	'identifierSchemeCodeUnspsc190501.78131603': __('identifierSchemeCodeUnspsc190501.78131603'),
	'identifierSchemeCodeUnspsc190501.78131700': __('identifierSchemeCodeUnspsc190501.78131700'),
	'identifierSchemeCodeUnspsc190501.78131701': __('identifierSchemeCodeUnspsc190501.78131701'),
	'identifierSchemeCodeUnspsc190501.78131702': __('identifierSchemeCodeUnspsc190501.78131702'),
	'identifierSchemeCodeUnspsc190501.78131800': __('identifierSchemeCodeUnspsc190501.78131800'),
	'identifierSchemeCodeUnspsc190501.78131801': __('identifierSchemeCodeUnspsc190501.78131801'),
	'identifierSchemeCodeUnspsc190501.78131802': __('identifierSchemeCodeUnspsc190501.78131802'),
	'identifierSchemeCodeUnspsc190501.78131803': __('identifierSchemeCodeUnspsc190501.78131803'),
	'identifierSchemeCodeUnspsc190501.78131804': __('identifierSchemeCodeUnspsc190501.78131804'),
	'identifierSchemeCodeUnspsc190501.78131805': __('identifierSchemeCodeUnspsc190501.78131805'),
	'identifierSchemeCodeUnspsc190501.78131806': __('identifierSchemeCodeUnspsc190501.78131806'),
	'identifierSchemeCodeUnspsc190501.78131807': __('identifierSchemeCodeUnspsc190501.78131807'),
	'identifierSchemeCodeUnspsc190501.78140000': __('identifierSchemeCodeUnspsc190501.78140000'),
	'identifierSchemeCodeUnspsc190501.78141500': __('identifierSchemeCodeUnspsc190501.78141500'),
	'identifierSchemeCodeUnspsc190501.78141501': __('identifierSchemeCodeUnspsc190501.78141501'),
	'identifierSchemeCodeUnspsc190501.78141502': __('identifierSchemeCodeUnspsc190501.78141502'),
	'identifierSchemeCodeUnspsc190501.78141503': __('identifierSchemeCodeUnspsc190501.78141503'),
	'identifierSchemeCodeUnspsc190501.78141504': __('identifierSchemeCodeUnspsc190501.78141504'),
	'identifierSchemeCodeUnspsc190501.78141505': __('identifierSchemeCodeUnspsc190501.78141505'),
	'identifierSchemeCodeUnspsc190501.78141600': __('identifierSchemeCodeUnspsc190501.78141600'),
	'identifierSchemeCodeUnspsc190501.78141601': __('identifierSchemeCodeUnspsc190501.78141601'),
	'identifierSchemeCodeUnspsc190501.78141602': __('identifierSchemeCodeUnspsc190501.78141602'),
	'identifierSchemeCodeUnspsc190501.78141603': __('identifierSchemeCodeUnspsc190501.78141603'),
	'identifierSchemeCodeUnspsc190501.78141700': __('identifierSchemeCodeUnspsc190501.78141700'),
	'identifierSchemeCodeUnspsc190501.78141701': __('identifierSchemeCodeUnspsc190501.78141701'),
	'identifierSchemeCodeUnspsc190501.78141702': __('identifierSchemeCodeUnspsc190501.78141702'),
	'identifierSchemeCodeUnspsc190501.78141703': __('identifierSchemeCodeUnspsc190501.78141703'),
	'identifierSchemeCodeUnspsc190501.78141704': __('identifierSchemeCodeUnspsc190501.78141704'),
	'identifierSchemeCodeUnspsc190501.78141800': __('identifierSchemeCodeUnspsc190501.78141800'),
	'identifierSchemeCodeUnspsc190501.78141801': __('identifierSchemeCodeUnspsc190501.78141801'),
	'identifierSchemeCodeUnspsc190501.78141802': __('identifierSchemeCodeUnspsc190501.78141802'),
	'identifierSchemeCodeUnspsc190501.78141803': __('identifierSchemeCodeUnspsc190501.78141803'),
	'identifierSchemeCodeUnspsc190501.78141804': __('identifierSchemeCodeUnspsc190501.78141804'),
	'identifierSchemeCodeUnspsc190501.78141805': __('identifierSchemeCodeUnspsc190501.78141805'),
	'identifierSchemeCodeUnspsc190501.78141806': __('identifierSchemeCodeUnspsc190501.78141806'),
	'identifierSchemeCodeUnspsc190501.78141807': __('identifierSchemeCodeUnspsc190501.78141807'),
	'identifierSchemeCodeUnspsc190501.78141900': __('identifierSchemeCodeUnspsc190501.78141900'),
	'identifierSchemeCodeUnspsc190501.78141901': __('identifierSchemeCodeUnspsc190501.78141901'),
	'identifierSchemeCodeUnspsc190501.78141902': __('identifierSchemeCodeUnspsc190501.78141902'),
	'identifierSchemeCodeUnspsc190501.78141903': __('identifierSchemeCodeUnspsc190501.78141903'),
	'identifierSchemeCodeUnspsc190501.78142000': __('identifierSchemeCodeUnspsc190501.78142000'),
	'identifierSchemeCodeUnspsc190501.78142001': __('identifierSchemeCodeUnspsc190501.78142001'),
	'identifierSchemeCodeUnspsc190501.78142002': __('identifierSchemeCodeUnspsc190501.78142002'),
	'identifierSchemeCodeUnspsc190501.78142003': __('identifierSchemeCodeUnspsc190501.78142003'),
	'identifierSchemeCodeUnspsc190501.78142004': __('identifierSchemeCodeUnspsc190501.78142004'),
	'identifierSchemeCodeUnspsc190501.78180000': __('identifierSchemeCodeUnspsc190501.78180000'),
	'identifierSchemeCodeUnspsc190501.78181500': __('identifierSchemeCodeUnspsc190501.78181500'),
	'identifierSchemeCodeUnspsc190501.78181501': __('identifierSchemeCodeUnspsc190501.78181501'),
	'identifierSchemeCodeUnspsc190501.78181502': __('identifierSchemeCodeUnspsc190501.78181502'),
	'identifierSchemeCodeUnspsc190501.78181503': __('identifierSchemeCodeUnspsc190501.78181503'),
	'identifierSchemeCodeUnspsc190501.78181505': __('identifierSchemeCodeUnspsc190501.78181505'),
	'identifierSchemeCodeUnspsc190501.78181506': __('identifierSchemeCodeUnspsc190501.78181506'),
	'identifierSchemeCodeUnspsc190501.78181507': __('identifierSchemeCodeUnspsc190501.78181507'),
	'identifierSchemeCodeUnspsc190501.78181508': __('identifierSchemeCodeUnspsc190501.78181508'),
	'identifierSchemeCodeUnspsc190501.78181509': __('identifierSchemeCodeUnspsc190501.78181509'),
	'identifierSchemeCodeUnspsc190501.78181510': __('identifierSchemeCodeUnspsc190501.78181510'),
	'identifierSchemeCodeUnspsc190501.78181511': __('identifierSchemeCodeUnspsc190501.78181511'),
	'identifierSchemeCodeUnspsc190501.78181600': __('identifierSchemeCodeUnspsc190501.78181600'),
	'identifierSchemeCodeUnspsc190501.78181601': __('identifierSchemeCodeUnspsc190501.78181601'),
	'identifierSchemeCodeUnspsc190501.78181700': __('identifierSchemeCodeUnspsc190501.78181700'),
	'identifierSchemeCodeUnspsc190501.78181701': __('identifierSchemeCodeUnspsc190501.78181701'),
	'identifierSchemeCodeUnspsc190501.78181702': __('identifierSchemeCodeUnspsc190501.78181702'),
	'identifierSchemeCodeUnspsc190501.78181703': __('identifierSchemeCodeUnspsc190501.78181703'),
	'identifierSchemeCodeUnspsc190501.78181800': __('identifierSchemeCodeUnspsc190501.78181800'),
	'identifierSchemeCodeUnspsc190501.78181803': __('identifierSchemeCodeUnspsc190501.78181803'),
	'identifierSchemeCodeUnspsc190501.78181804': __('identifierSchemeCodeUnspsc190501.78181804'),
	'identifierSchemeCodeUnspsc190501.78181805': __('identifierSchemeCodeUnspsc190501.78181805'),
	'identifierSchemeCodeUnspsc190501.78181806': __('identifierSchemeCodeUnspsc190501.78181806'),
	'identifierSchemeCodeUnspsc190501.78181807': __('identifierSchemeCodeUnspsc190501.78181807'),
	'identifierSchemeCodeUnspsc190501.78181808': __('identifierSchemeCodeUnspsc190501.78181808'),
	'identifierSchemeCodeUnspsc190501.78181809': __('identifierSchemeCodeUnspsc190501.78181809'),
	'identifierSchemeCodeUnspsc190501.78181810': __('identifierSchemeCodeUnspsc190501.78181810'),
	'identifierSchemeCodeUnspsc190501.78181811': __('identifierSchemeCodeUnspsc190501.78181811'),
	'identifierSchemeCodeUnspsc190501.78181812': __('identifierSchemeCodeUnspsc190501.78181812'),
	'identifierSchemeCodeUnspsc190501.78181813': __('identifierSchemeCodeUnspsc190501.78181813'),
	'identifierSchemeCodeUnspsc190501.78181814': __('identifierSchemeCodeUnspsc190501.78181814'),
	'identifierSchemeCodeUnspsc190501.78181815': __('identifierSchemeCodeUnspsc190501.78181815'),
	'identifierSchemeCodeUnspsc190501.78181816': __('identifierSchemeCodeUnspsc190501.78181816'),
	'identifierSchemeCodeUnspsc190501.78181817': __('identifierSchemeCodeUnspsc190501.78181817'),
	'identifierSchemeCodeUnspsc190501.78181818': __('identifierSchemeCodeUnspsc190501.78181818'),
	'identifierSchemeCodeUnspsc190501.78181819': __('identifierSchemeCodeUnspsc190501.78181819'),
	'identifierSchemeCodeUnspsc190501.78181820': __('identifierSchemeCodeUnspsc190501.78181820'),
	'identifierSchemeCodeUnspsc190501.78181821': __('identifierSchemeCodeUnspsc190501.78181821'),
	'identifierSchemeCodeUnspsc190501.78181822': __('identifierSchemeCodeUnspsc190501.78181822'),
	'identifierSchemeCodeUnspsc190501.78181823': __('identifierSchemeCodeUnspsc190501.78181823'),
	'identifierSchemeCodeUnspsc190501.78181824': __('identifierSchemeCodeUnspsc190501.78181824'),
	'identifierSchemeCodeUnspsc190501.78181825': __('identifierSchemeCodeUnspsc190501.78181825'),
	'identifierSchemeCodeUnspsc190501.78181826': __('identifierSchemeCodeUnspsc190501.78181826'),
	'identifierSchemeCodeUnspsc190501.78181827': __('identifierSchemeCodeUnspsc190501.78181827'),
	'identifierSchemeCodeUnspsc190501.78181828': __('identifierSchemeCodeUnspsc190501.78181828'),
	'identifierSchemeCodeUnspsc190501.78181829': __('identifierSchemeCodeUnspsc190501.78181829'),
	'identifierSchemeCodeUnspsc190501.78181830': __('identifierSchemeCodeUnspsc190501.78181830'),
	'identifierSchemeCodeUnspsc190501.78181831': __('identifierSchemeCodeUnspsc190501.78181831'),
	'identifierSchemeCodeUnspsc190501.78181832': __('identifierSchemeCodeUnspsc190501.78181832'),
	'identifierSchemeCodeUnspsc190501.78181833': __('identifierSchemeCodeUnspsc190501.78181833'),
	'identifierSchemeCodeUnspsc190501.78181834': __('identifierSchemeCodeUnspsc190501.78181834'),
	'identifierSchemeCodeUnspsc190501.78181835': __('identifierSchemeCodeUnspsc190501.78181835'),
	'identifierSchemeCodeUnspsc190501.78181836': __('identifierSchemeCodeUnspsc190501.78181836'),
	'identifierSchemeCodeUnspsc190501.78181837': __('identifierSchemeCodeUnspsc190501.78181837'),
	'identifierSchemeCodeUnspsc190501.78181838': __('identifierSchemeCodeUnspsc190501.78181838'),
	'identifierSchemeCodeUnspsc190501.78181839': __('identifierSchemeCodeUnspsc190501.78181839'),
	'identifierSchemeCodeUnspsc190501.78181840': __('identifierSchemeCodeUnspsc190501.78181840'),
	'identifierSchemeCodeUnspsc190501.78181841': __('identifierSchemeCodeUnspsc190501.78181841'),
	'identifierSchemeCodeUnspsc190501.78181900': __('identifierSchemeCodeUnspsc190501.78181900'),
	'identifierSchemeCodeUnspsc190501.78181901': __('identifierSchemeCodeUnspsc190501.78181901'),
	'identifierSchemeCodeUnspsc190501.78182000': __('identifierSchemeCodeUnspsc190501.78182000'),
	'identifierSchemeCodeUnspsc190501.78182001': __('identifierSchemeCodeUnspsc190501.78182001'),
	'identifierSchemeCodeUnspsc190501.78182002': __('identifierSchemeCodeUnspsc190501.78182002'),
	'identifierSchemeCodeUnspsc190501.80000000': __('identifierSchemeCodeUnspsc190501.80000000'),
	'identifierSchemeCodeUnspsc190501.80100000': __('identifierSchemeCodeUnspsc190501.80100000'),
	'identifierSchemeCodeUnspsc190501.80101500': __('identifierSchemeCodeUnspsc190501.80101500'),
	'identifierSchemeCodeUnspsc190501.80101501': __('identifierSchemeCodeUnspsc190501.80101501'),
	'identifierSchemeCodeUnspsc190501.80101502': __('identifierSchemeCodeUnspsc190501.80101502'),
	'identifierSchemeCodeUnspsc190501.80101503': __('identifierSchemeCodeUnspsc190501.80101503'),
	'identifierSchemeCodeUnspsc190501.80101504': __('identifierSchemeCodeUnspsc190501.80101504'),
	'identifierSchemeCodeUnspsc190501.80101505': __('identifierSchemeCodeUnspsc190501.80101505'),
	'identifierSchemeCodeUnspsc190501.80101506': __('identifierSchemeCodeUnspsc190501.80101506'),
	'identifierSchemeCodeUnspsc190501.80101507': __('identifierSchemeCodeUnspsc190501.80101507'),
	'identifierSchemeCodeUnspsc190501.80101508': __('identifierSchemeCodeUnspsc190501.80101508'),
	'identifierSchemeCodeUnspsc190501.80101509': __('identifierSchemeCodeUnspsc190501.80101509'),
	'identifierSchemeCodeUnspsc190501.80101510': __('identifierSchemeCodeUnspsc190501.80101510'),
	'identifierSchemeCodeUnspsc190501.80101511': __('identifierSchemeCodeUnspsc190501.80101511'),
	'identifierSchemeCodeUnspsc190501.80101512': __('identifierSchemeCodeUnspsc190501.80101512'),
	'identifierSchemeCodeUnspsc190501.80101513': __('identifierSchemeCodeUnspsc190501.80101513'),
	'identifierSchemeCodeUnspsc190501.80101600': __('identifierSchemeCodeUnspsc190501.80101600'),
	'identifierSchemeCodeUnspsc190501.80101601': __('identifierSchemeCodeUnspsc190501.80101601'),
	'identifierSchemeCodeUnspsc190501.80101602': __('identifierSchemeCodeUnspsc190501.80101602'),
	'identifierSchemeCodeUnspsc190501.80101603': __('identifierSchemeCodeUnspsc190501.80101603'),
	'identifierSchemeCodeUnspsc190501.80101604': __('identifierSchemeCodeUnspsc190501.80101604'),
	'identifierSchemeCodeUnspsc190501.80101605': __('identifierSchemeCodeUnspsc190501.80101605'),
	'identifierSchemeCodeUnspsc190501.80101606': __('identifierSchemeCodeUnspsc190501.80101606'),
	'identifierSchemeCodeUnspsc190501.80101607': __('identifierSchemeCodeUnspsc190501.80101607'),
	'identifierSchemeCodeUnspsc190501.80101700': __('identifierSchemeCodeUnspsc190501.80101700'),
	'identifierSchemeCodeUnspsc190501.80101701': __('identifierSchemeCodeUnspsc190501.80101701'),
	'identifierSchemeCodeUnspsc190501.80101702': __('identifierSchemeCodeUnspsc190501.80101702'),
	'identifierSchemeCodeUnspsc190501.80101703': __('identifierSchemeCodeUnspsc190501.80101703'),
	'identifierSchemeCodeUnspsc190501.80101704': __('identifierSchemeCodeUnspsc190501.80101704'),
	'identifierSchemeCodeUnspsc190501.80101705': __('identifierSchemeCodeUnspsc190501.80101705'),
	'identifierSchemeCodeUnspsc190501.80101706': __('identifierSchemeCodeUnspsc190501.80101706'),
	'identifierSchemeCodeUnspsc190501.80101707': __('identifierSchemeCodeUnspsc190501.80101707'),
	'identifierSchemeCodeUnspsc190501.80101708': __('identifierSchemeCodeUnspsc190501.80101708'),
	'identifierSchemeCodeUnspsc190501.80110000': __('identifierSchemeCodeUnspsc190501.80110000'),
	'identifierSchemeCodeUnspsc190501.80111500': __('identifierSchemeCodeUnspsc190501.80111500'),
	'identifierSchemeCodeUnspsc190501.80111501': __('identifierSchemeCodeUnspsc190501.80111501'),
	'identifierSchemeCodeUnspsc190501.80111502': __('identifierSchemeCodeUnspsc190501.80111502'),
	'identifierSchemeCodeUnspsc190501.80111503': __('identifierSchemeCodeUnspsc190501.80111503'),
	'identifierSchemeCodeUnspsc190501.80111504': __('identifierSchemeCodeUnspsc190501.80111504'),
	'identifierSchemeCodeUnspsc190501.80111505': __('identifierSchemeCodeUnspsc190501.80111505'),
	'identifierSchemeCodeUnspsc190501.80111506': __('identifierSchemeCodeUnspsc190501.80111506'),
	'identifierSchemeCodeUnspsc190501.80111507': __('identifierSchemeCodeUnspsc190501.80111507'),
	'identifierSchemeCodeUnspsc190501.80111508': __('identifierSchemeCodeUnspsc190501.80111508'),
	'identifierSchemeCodeUnspsc190501.80111509': __('identifierSchemeCodeUnspsc190501.80111509'),
	'identifierSchemeCodeUnspsc190501.80111510': __('identifierSchemeCodeUnspsc190501.80111510'),
	'identifierSchemeCodeUnspsc190501.80111511': __('identifierSchemeCodeUnspsc190501.80111511'),
	'identifierSchemeCodeUnspsc190501.80111600': __('identifierSchemeCodeUnspsc190501.80111600'),
	'identifierSchemeCodeUnspsc190501.80111601': __('identifierSchemeCodeUnspsc190501.80111601'),
	'identifierSchemeCodeUnspsc190501.80111602': __('identifierSchemeCodeUnspsc190501.80111602'),
	'identifierSchemeCodeUnspsc190501.80111603': __('identifierSchemeCodeUnspsc190501.80111603'),
	'identifierSchemeCodeUnspsc190501.80111604': __('identifierSchemeCodeUnspsc190501.80111604'),
	'identifierSchemeCodeUnspsc190501.80111605': __('identifierSchemeCodeUnspsc190501.80111605'),
	'identifierSchemeCodeUnspsc190501.80111606': __('identifierSchemeCodeUnspsc190501.80111606'),
	'identifierSchemeCodeUnspsc190501.80111607': __('identifierSchemeCodeUnspsc190501.80111607'),
	'identifierSchemeCodeUnspsc190501.80111608': __('identifierSchemeCodeUnspsc190501.80111608'),
	'identifierSchemeCodeUnspsc190501.80111609': __('identifierSchemeCodeUnspsc190501.80111609'),
	'identifierSchemeCodeUnspsc190501.80111610': __('identifierSchemeCodeUnspsc190501.80111610'),
	'identifierSchemeCodeUnspsc190501.80111611': __('identifierSchemeCodeUnspsc190501.80111611'),
	'identifierSchemeCodeUnspsc190501.80111612': __('identifierSchemeCodeUnspsc190501.80111612'),
	'identifierSchemeCodeUnspsc190501.80111613': __('identifierSchemeCodeUnspsc190501.80111613'),
	'identifierSchemeCodeUnspsc190501.80111614': __('identifierSchemeCodeUnspsc190501.80111614'),
	'identifierSchemeCodeUnspsc190501.80111615': __('identifierSchemeCodeUnspsc190501.80111615'),
	'identifierSchemeCodeUnspsc190501.80111616': __('identifierSchemeCodeUnspsc190501.80111616'),
	'identifierSchemeCodeUnspsc190501.80111617': __('identifierSchemeCodeUnspsc190501.80111617'),
	'identifierSchemeCodeUnspsc190501.80111618': __('identifierSchemeCodeUnspsc190501.80111618'),
	'identifierSchemeCodeUnspsc190501.80111619': __('identifierSchemeCodeUnspsc190501.80111619'),
	'identifierSchemeCodeUnspsc190501.80111620': __('identifierSchemeCodeUnspsc190501.80111620'),
	'identifierSchemeCodeUnspsc190501.80111621': __('identifierSchemeCodeUnspsc190501.80111621'),
	'identifierSchemeCodeUnspsc190501.80111622': __('identifierSchemeCodeUnspsc190501.80111622'),
	'identifierSchemeCodeUnspsc190501.80111623': __('identifierSchemeCodeUnspsc190501.80111623'),
	'identifierSchemeCodeUnspsc190501.80111624': __('identifierSchemeCodeUnspsc190501.80111624'),
	'identifierSchemeCodeUnspsc190501.80111625': __('identifierSchemeCodeUnspsc190501.80111625'),
	'identifierSchemeCodeUnspsc190501.80111700': __('identifierSchemeCodeUnspsc190501.80111700'),
	'identifierSchemeCodeUnspsc190501.80111701': __('identifierSchemeCodeUnspsc190501.80111701'),
	'identifierSchemeCodeUnspsc190501.80111702': __('identifierSchemeCodeUnspsc190501.80111702'),
	'identifierSchemeCodeUnspsc190501.80111703': __('identifierSchemeCodeUnspsc190501.80111703'),
	'identifierSchemeCodeUnspsc190501.80111704': __('identifierSchemeCodeUnspsc190501.80111704'),
	'identifierSchemeCodeUnspsc190501.80111705': __('identifierSchemeCodeUnspsc190501.80111705'),
	'identifierSchemeCodeUnspsc190501.80111706': __('identifierSchemeCodeUnspsc190501.80111706'),
	'identifierSchemeCodeUnspsc190501.80111707': __('identifierSchemeCodeUnspsc190501.80111707'),
	'identifierSchemeCodeUnspsc190501.80111708': __('identifierSchemeCodeUnspsc190501.80111708'),
	'identifierSchemeCodeUnspsc190501.80111709': __('identifierSchemeCodeUnspsc190501.80111709'),
	'identifierSchemeCodeUnspsc190501.80111710': __('identifierSchemeCodeUnspsc190501.80111710'),
	'identifierSchemeCodeUnspsc190501.80111711': __('identifierSchemeCodeUnspsc190501.80111711'),
	'identifierSchemeCodeUnspsc190501.80111712': __('identifierSchemeCodeUnspsc190501.80111712'),
	'identifierSchemeCodeUnspsc190501.80111713': __('identifierSchemeCodeUnspsc190501.80111713'),
	'identifierSchemeCodeUnspsc190501.80111714': __('identifierSchemeCodeUnspsc190501.80111714'),
	'identifierSchemeCodeUnspsc190501.80111715': __('identifierSchemeCodeUnspsc190501.80111715'),
	'identifierSchemeCodeUnspsc190501.80111716': __('identifierSchemeCodeUnspsc190501.80111716'),
	'identifierSchemeCodeUnspsc190501.80111717': __('identifierSchemeCodeUnspsc190501.80111717'),
	'identifierSchemeCodeUnspsc190501.80111718': __('identifierSchemeCodeUnspsc190501.80111718'),
	'identifierSchemeCodeUnspsc190501.80111719': __('identifierSchemeCodeUnspsc190501.80111719'),
	'identifierSchemeCodeUnspsc190501.80120000': __('identifierSchemeCodeUnspsc190501.80120000'),
	'identifierSchemeCodeUnspsc190501.80121500': __('identifierSchemeCodeUnspsc190501.80121500'),
	'identifierSchemeCodeUnspsc190501.80121501': __('identifierSchemeCodeUnspsc190501.80121501'),
	'identifierSchemeCodeUnspsc190501.80121502': __('identifierSchemeCodeUnspsc190501.80121502'),
	'identifierSchemeCodeUnspsc190501.80121503': __('identifierSchemeCodeUnspsc190501.80121503'),
	'identifierSchemeCodeUnspsc190501.80121600': __('identifierSchemeCodeUnspsc190501.80121600'),
	'identifierSchemeCodeUnspsc190501.80121601': __('identifierSchemeCodeUnspsc190501.80121601'),
	'identifierSchemeCodeUnspsc190501.80121602': __('identifierSchemeCodeUnspsc190501.80121602'),
	'identifierSchemeCodeUnspsc190501.80121603': __('identifierSchemeCodeUnspsc190501.80121603'),
	'identifierSchemeCodeUnspsc190501.80121604': __('identifierSchemeCodeUnspsc190501.80121604'),
	'identifierSchemeCodeUnspsc190501.80121605': __('identifierSchemeCodeUnspsc190501.80121605'),
	'identifierSchemeCodeUnspsc190501.80121606': __('identifierSchemeCodeUnspsc190501.80121606'),
	'identifierSchemeCodeUnspsc190501.80121607': __('identifierSchemeCodeUnspsc190501.80121607'),
	'identifierSchemeCodeUnspsc190501.80121608': __('identifierSchemeCodeUnspsc190501.80121608'),
	'identifierSchemeCodeUnspsc190501.80121609': __('identifierSchemeCodeUnspsc190501.80121609'),
	'identifierSchemeCodeUnspsc190501.80121610': __('identifierSchemeCodeUnspsc190501.80121610'),
	'identifierSchemeCodeUnspsc190501.80121611': __('identifierSchemeCodeUnspsc190501.80121611'),
	'identifierSchemeCodeUnspsc190501.80121700': __('identifierSchemeCodeUnspsc190501.80121700'),
	'identifierSchemeCodeUnspsc190501.80121701': __('identifierSchemeCodeUnspsc190501.80121701'),
	'identifierSchemeCodeUnspsc190501.80121702': __('identifierSchemeCodeUnspsc190501.80121702'),
	'identifierSchemeCodeUnspsc190501.80121703': __('identifierSchemeCodeUnspsc190501.80121703'),
	'identifierSchemeCodeUnspsc190501.80121704': __('identifierSchemeCodeUnspsc190501.80121704'),
	'identifierSchemeCodeUnspsc190501.80121705': __('identifierSchemeCodeUnspsc190501.80121705'),
	'identifierSchemeCodeUnspsc190501.80121706': __('identifierSchemeCodeUnspsc190501.80121706'),
	'identifierSchemeCodeUnspsc190501.80121707': __('identifierSchemeCodeUnspsc190501.80121707'),
	'identifierSchemeCodeUnspsc190501.80121708': __('identifierSchemeCodeUnspsc190501.80121708'),
	'identifierSchemeCodeUnspsc190501.80121800': __('identifierSchemeCodeUnspsc190501.80121800'),
	'identifierSchemeCodeUnspsc190501.80121801': __('identifierSchemeCodeUnspsc190501.80121801'),
	'identifierSchemeCodeUnspsc190501.80121802': __('identifierSchemeCodeUnspsc190501.80121802'),
	'identifierSchemeCodeUnspsc190501.80121803': __('identifierSchemeCodeUnspsc190501.80121803'),
	'identifierSchemeCodeUnspsc190501.80121804': __('identifierSchemeCodeUnspsc190501.80121804'),
	'identifierSchemeCodeUnspsc190501.80121900': __('identifierSchemeCodeUnspsc190501.80121900'),
	'identifierSchemeCodeUnspsc190501.80121901': __('identifierSchemeCodeUnspsc190501.80121901'),
	'identifierSchemeCodeUnspsc190501.80121902': __('identifierSchemeCodeUnspsc190501.80121902'),
	'identifierSchemeCodeUnspsc190501.80121903': __('identifierSchemeCodeUnspsc190501.80121903'),
	'identifierSchemeCodeUnspsc190501.80121904': __('identifierSchemeCodeUnspsc190501.80121904'),
	'identifierSchemeCodeUnspsc190501.80122000': __('identifierSchemeCodeUnspsc190501.80122000'),
	'identifierSchemeCodeUnspsc190501.80122001': __('identifierSchemeCodeUnspsc190501.80122001'),
	'identifierSchemeCodeUnspsc190501.80122002': __('identifierSchemeCodeUnspsc190501.80122002'),
	'identifierSchemeCodeUnspsc190501.80122100': __('identifierSchemeCodeUnspsc190501.80122100'),
	'identifierSchemeCodeUnspsc190501.80122101': __('identifierSchemeCodeUnspsc190501.80122101'),
	'identifierSchemeCodeUnspsc190501.80122102': __('identifierSchemeCodeUnspsc190501.80122102'),
	'identifierSchemeCodeUnspsc190501.80122103': __('identifierSchemeCodeUnspsc190501.80122103'),
	'identifierSchemeCodeUnspsc190501.80122200': __('identifierSchemeCodeUnspsc190501.80122200'),
	'identifierSchemeCodeUnspsc190501.80122201': __('identifierSchemeCodeUnspsc190501.80122201'),
	'identifierSchemeCodeUnspsc190501.80122202': __('identifierSchemeCodeUnspsc190501.80122202'),
	'identifierSchemeCodeUnspsc190501.80122300': __('identifierSchemeCodeUnspsc190501.80122300'),
	'identifierSchemeCodeUnspsc190501.80122301': __('identifierSchemeCodeUnspsc190501.80122301'),
	'identifierSchemeCodeUnspsc190501.80130000': __('identifierSchemeCodeUnspsc190501.80130000'),
	'identifierSchemeCodeUnspsc190501.80131500': __('identifierSchemeCodeUnspsc190501.80131500'),
	'identifierSchemeCodeUnspsc190501.80131501': __('identifierSchemeCodeUnspsc190501.80131501'),
	'identifierSchemeCodeUnspsc190501.80131502': __('identifierSchemeCodeUnspsc190501.80131502'),
	'identifierSchemeCodeUnspsc190501.80131503': __('identifierSchemeCodeUnspsc190501.80131503'),
	'identifierSchemeCodeUnspsc190501.80131504': __('identifierSchemeCodeUnspsc190501.80131504'),
	'identifierSchemeCodeUnspsc190501.80131505': __('identifierSchemeCodeUnspsc190501.80131505'),
	'identifierSchemeCodeUnspsc190501.80131506': __('identifierSchemeCodeUnspsc190501.80131506'),
	'identifierSchemeCodeUnspsc190501.80131600': __('identifierSchemeCodeUnspsc190501.80131600'),
	'identifierSchemeCodeUnspsc190501.80131601': __('identifierSchemeCodeUnspsc190501.80131601'),
	'identifierSchemeCodeUnspsc190501.80131602': __('identifierSchemeCodeUnspsc190501.80131602'),
	'identifierSchemeCodeUnspsc190501.80131603': __('identifierSchemeCodeUnspsc190501.80131603'),
	'identifierSchemeCodeUnspsc190501.80131604': __('identifierSchemeCodeUnspsc190501.80131604'),
	'identifierSchemeCodeUnspsc190501.80131605': __('identifierSchemeCodeUnspsc190501.80131605'),
	'identifierSchemeCodeUnspsc190501.80131700': __('identifierSchemeCodeUnspsc190501.80131700'),
	'identifierSchemeCodeUnspsc190501.80131701': __('identifierSchemeCodeUnspsc190501.80131701'),
	'identifierSchemeCodeUnspsc190501.80131702': __('identifierSchemeCodeUnspsc190501.80131702'),
	'identifierSchemeCodeUnspsc190501.80131703': __('identifierSchemeCodeUnspsc190501.80131703'),
	'identifierSchemeCodeUnspsc190501.80131800': __('identifierSchemeCodeUnspsc190501.80131800'),
	'identifierSchemeCodeUnspsc190501.80131801': __('identifierSchemeCodeUnspsc190501.80131801'),
	'identifierSchemeCodeUnspsc190501.80131802': __('identifierSchemeCodeUnspsc190501.80131802'),
	'identifierSchemeCodeUnspsc190501.80131803': __('identifierSchemeCodeUnspsc190501.80131803'),
	'identifierSchemeCodeUnspsc190501.80140000': __('identifierSchemeCodeUnspsc190501.80140000'),
	'identifierSchemeCodeUnspsc190501.80141500': __('identifierSchemeCodeUnspsc190501.80141500'),
	'identifierSchemeCodeUnspsc190501.80141501': __('identifierSchemeCodeUnspsc190501.80141501'),
	'identifierSchemeCodeUnspsc190501.80141502': __('identifierSchemeCodeUnspsc190501.80141502'),
	'identifierSchemeCodeUnspsc190501.80141503': __('identifierSchemeCodeUnspsc190501.80141503'),
	'identifierSchemeCodeUnspsc190501.80141504': __('identifierSchemeCodeUnspsc190501.80141504'),
	'identifierSchemeCodeUnspsc190501.80141505': __('identifierSchemeCodeUnspsc190501.80141505'),
	'identifierSchemeCodeUnspsc190501.80141506': __('identifierSchemeCodeUnspsc190501.80141506'),
	'identifierSchemeCodeUnspsc190501.80141507': __('identifierSchemeCodeUnspsc190501.80141507'),
	'identifierSchemeCodeUnspsc190501.80141508': __('identifierSchemeCodeUnspsc190501.80141508'),
	'identifierSchemeCodeUnspsc190501.80141509': __('identifierSchemeCodeUnspsc190501.80141509'),
	'identifierSchemeCodeUnspsc190501.80141510': __('identifierSchemeCodeUnspsc190501.80141510'),
	'identifierSchemeCodeUnspsc190501.80141511': __('identifierSchemeCodeUnspsc190501.80141511'),
	'identifierSchemeCodeUnspsc190501.80141512': __('identifierSchemeCodeUnspsc190501.80141512'),
	'identifierSchemeCodeUnspsc190501.80141513': __('identifierSchemeCodeUnspsc190501.80141513'),
	'identifierSchemeCodeUnspsc190501.80141514': __('identifierSchemeCodeUnspsc190501.80141514'),
	'identifierSchemeCodeUnspsc190501.80141600': __('identifierSchemeCodeUnspsc190501.80141600'),
	'identifierSchemeCodeUnspsc190501.80141601': __('identifierSchemeCodeUnspsc190501.80141601'),
	'identifierSchemeCodeUnspsc190501.80141603': __('identifierSchemeCodeUnspsc190501.80141603'),
	'identifierSchemeCodeUnspsc190501.80141604': __('identifierSchemeCodeUnspsc190501.80141604'),
	'identifierSchemeCodeUnspsc190501.80141605': __('identifierSchemeCodeUnspsc190501.80141605'),
	'identifierSchemeCodeUnspsc190501.80141606': __('identifierSchemeCodeUnspsc190501.80141606'),
	'identifierSchemeCodeUnspsc190501.80141607': __('identifierSchemeCodeUnspsc190501.80141607'),
	'identifierSchemeCodeUnspsc190501.80141609': __('identifierSchemeCodeUnspsc190501.80141609'),
	'identifierSchemeCodeUnspsc190501.80141610': __('identifierSchemeCodeUnspsc190501.80141610'),
	'identifierSchemeCodeUnspsc190501.80141611': __('identifierSchemeCodeUnspsc190501.80141611'),
	'identifierSchemeCodeUnspsc190501.80141612': __('identifierSchemeCodeUnspsc190501.80141612'),
	'identifierSchemeCodeUnspsc190501.80141613': __('identifierSchemeCodeUnspsc190501.80141613'),
	'identifierSchemeCodeUnspsc190501.80141615': __('identifierSchemeCodeUnspsc190501.80141615'),
	'identifierSchemeCodeUnspsc190501.80141616': __('identifierSchemeCodeUnspsc190501.80141616'),
	'identifierSchemeCodeUnspsc190501.80141617': __('identifierSchemeCodeUnspsc190501.80141617'),
	'identifierSchemeCodeUnspsc190501.80141618': __('identifierSchemeCodeUnspsc190501.80141618'),
	'identifierSchemeCodeUnspsc190501.80141619': __('identifierSchemeCodeUnspsc190501.80141619'),
	'identifierSchemeCodeUnspsc190501.80141620': __('identifierSchemeCodeUnspsc190501.80141620'),
	'identifierSchemeCodeUnspsc190501.80141621': __('identifierSchemeCodeUnspsc190501.80141621'),
	'identifierSchemeCodeUnspsc190501.80141622': __('identifierSchemeCodeUnspsc190501.80141622'),
	'identifierSchemeCodeUnspsc190501.80141623': __('identifierSchemeCodeUnspsc190501.80141623'),
	'identifierSchemeCodeUnspsc190501.80141624': __('identifierSchemeCodeUnspsc190501.80141624'),
	'identifierSchemeCodeUnspsc190501.80141625': __('identifierSchemeCodeUnspsc190501.80141625'),
	'identifierSchemeCodeUnspsc190501.80141626': __('identifierSchemeCodeUnspsc190501.80141626'),
	'identifierSchemeCodeUnspsc190501.80141627': __('identifierSchemeCodeUnspsc190501.80141627'),
	'identifierSchemeCodeUnspsc190501.80141628': __('identifierSchemeCodeUnspsc190501.80141628'),
	'identifierSchemeCodeUnspsc190501.80141629': __('identifierSchemeCodeUnspsc190501.80141629'),
	'identifierSchemeCodeUnspsc190501.80141630': __('identifierSchemeCodeUnspsc190501.80141630'),
	'identifierSchemeCodeUnspsc190501.80141700': __('identifierSchemeCodeUnspsc190501.80141700'),
	'identifierSchemeCodeUnspsc190501.80141701': __('identifierSchemeCodeUnspsc190501.80141701'),
	'identifierSchemeCodeUnspsc190501.80141702': __('identifierSchemeCodeUnspsc190501.80141702'),
	'identifierSchemeCodeUnspsc190501.80141703': __('identifierSchemeCodeUnspsc190501.80141703'),
	'identifierSchemeCodeUnspsc190501.80141704': __('identifierSchemeCodeUnspsc190501.80141704'),
	'identifierSchemeCodeUnspsc190501.80141705': __('identifierSchemeCodeUnspsc190501.80141705'),
	'identifierSchemeCodeUnspsc190501.80141706': __('identifierSchemeCodeUnspsc190501.80141706'),
	'identifierSchemeCodeUnspsc190501.80141800': __('identifierSchemeCodeUnspsc190501.80141800'),
	'identifierSchemeCodeUnspsc190501.80141801': __('identifierSchemeCodeUnspsc190501.80141801'),
	'identifierSchemeCodeUnspsc190501.80141802': __('identifierSchemeCodeUnspsc190501.80141802'),
	'identifierSchemeCodeUnspsc190501.80141803': __('identifierSchemeCodeUnspsc190501.80141803'),
	'identifierSchemeCodeUnspsc190501.80141900': __('identifierSchemeCodeUnspsc190501.80141900'),
	'identifierSchemeCodeUnspsc190501.80141901': __('identifierSchemeCodeUnspsc190501.80141901'),
	'identifierSchemeCodeUnspsc190501.80141902': __('identifierSchemeCodeUnspsc190501.80141902'),
	'identifierSchemeCodeUnspsc190501.80141903': __('identifierSchemeCodeUnspsc190501.80141903'),
	'identifierSchemeCodeUnspsc190501.80150000': __('identifierSchemeCodeUnspsc190501.80150000'),
	'identifierSchemeCodeUnspsc190501.80151500': __('identifierSchemeCodeUnspsc190501.80151500'),
	'identifierSchemeCodeUnspsc190501.80151501': __('identifierSchemeCodeUnspsc190501.80151501'),
	'identifierSchemeCodeUnspsc190501.80151502': __('identifierSchemeCodeUnspsc190501.80151502'),
	'identifierSchemeCodeUnspsc190501.80151503': __('identifierSchemeCodeUnspsc190501.80151503'),
	'identifierSchemeCodeUnspsc190501.80151504': __('identifierSchemeCodeUnspsc190501.80151504'),
	'identifierSchemeCodeUnspsc190501.80151505': __('identifierSchemeCodeUnspsc190501.80151505'),
	'identifierSchemeCodeUnspsc190501.80151600': __('identifierSchemeCodeUnspsc190501.80151600'),
	'identifierSchemeCodeUnspsc190501.80151601': __('identifierSchemeCodeUnspsc190501.80151601'),
	'identifierSchemeCodeUnspsc190501.80151602': __('identifierSchemeCodeUnspsc190501.80151602'),
	'identifierSchemeCodeUnspsc190501.80151603': __('identifierSchemeCodeUnspsc190501.80151603'),
	'identifierSchemeCodeUnspsc190501.80151604': __('identifierSchemeCodeUnspsc190501.80151604'),
	'identifierSchemeCodeUnspsc190501.80151605': __('identifierSchemeCodeUnspsc190501.80151605'),
	'identifierSchemeCodeUnspsc190501.80160000': __('identifierSchemeCodeUnspsc190501.80160000'),
	'identifierSchemeCodeUnspsc190501.80161500': __('identifierSchemeCodeUnspsc190501.80161500'),
	'identifierSchemeCodeUnspsc190501.80161501': __('identifierSchemeCodeUnspsc190501.80161501'),
	'identifierSchemeCodeUnspsc190501.80161502': __('identifierSchemeCodeUnspsc190501.80161502'),
	'identifierSchemeCodeUnspsc190501.80161503': __('identifierSchemeCodeUnspsc190501.80161503'),
	'identifierSchemeCodeUnspsc190501.80161504': __('identifierSchemeCodeUnspsc190501.80161504'),
	'identifierSchemeCodeUnspsc190501.80161505': __('identifierSchemeCodeUnspsc190501.80161505'),
	'identifierSchemeCodeUnspsc190501.80161506': __('identifierSchemeCodeUnspsc190501.80161506'),
	'identifierSchemeCodeUnspsc190501.80161507': __('identifierSchemeCodeUnspsc190501.80161507'),
	'identifierSchemeCodeUnspsc190501.80161508': __('identifierSchemeCodeUnspsc190501.80161508'),
	'identifierSchemeCodeUnspsc190501.80161600': __('identifierSchemeCodeUnspsc190501.80161600'),
	'identifierSchemeCodeUnspsc190501.80161601': __('identifierSchemeCodeUnspsc190501.80161601'),
	'identifierSchemeCodeUnspsc190501.80161602': __('identifierSchemeCodeUnspsc190501.80161602'),
	'identifierSchemeCodeUnspsc190501.80161603': __('identifierSchemeCodeUnspsc190501.80161603'),
	'identifierSchemeCodeUnspsc190501.80161700': __('identifierSchemeCodeUnspsc190501.80161700'),
	'identifierSchemeCodeUnspsc190501.80161701': __('identifierSchemeCodeUnspsc190501.80161701'),
	'identifierSchemeCodeUnspsc190501.80161702': __('identifierSchemeCodeUnspsc190501.80161702'),
	'identifierSchemeCodeUnspsc190501.80161703': __('identifierSchemeCodeUnspsc190501.80161703'),
	'identifierSchemeCodeUnspsc190501.80161800': __('identifierSchemeCodeUnspsc190501.80161800'),
	'identifierSchemeCodeUnspsc190501.80161801': __('identifierSchemeCodeUnspsc190501.80161801'),
	'identifierSchemeCodeUnspsc190501.80170000': __('identifierSchemeCodeUnspsc190501.80170000'),
	'identifierSchemeCodeUnspsc190501.80171500': __('identifierSchemeCodeUnspsc190501.80171500'),
	'identifierSchemeCodeUnspsc190501.80171501': __('identifierSchemeCodeUnspsc190501.80171501'),
	'identifierSchemeCodeUnspsc190501.80171502': __('identifierSchemeCodeUnspsc190501.80171502'),
	'identifierSchemeCodeUnspsc190501.80171503': __('identifierSchemeCodeUnspsc190501.80171503'),
	'identifierSchemeCodeUnspsc190501.80171504': __('identifierSchemeCodeUnspsc190501.80171504'),
	'identifierSchemeCodeUnspsc190501.80171505': __('identifierSchemeCodeUnspsc190501.80171505'),
	'identifierSchemeCodeUnspsc190501.80171600': __('identifierSchemeCodeUnspsc190501.80171600'),
	'identifierSchemeCodeUnspsc190501.80171601': __('identifierSchemeCodeUnspsc190501.80171601'),
	'identifierSchemeCodeUnspsc190501.80171602': __('identifierSchemeCodeUnspsc190501.80171602'),
	'identifierSchemeCodeUnspsc190501.80171603': __('identifierSchemeCodeUnspsc190501.80171603'),
	'identifierSchemeCodeUnspsc190501.80171604': __('identifierSchemeCodeUnspsc190501.80171604'),
	'identifierSchemeCodeUnspsc190501.80171605': __('identifierSchemeCodeUnspsc190501.80171605'),
	'identifierSchemeCodeUnspsc190501.80171700': __('identifierSchemeCodeUnspsc190501.80171700'),
	'identifierSchemeCodeUnspsc190501.80171701': __('identifierSchemeCodeUnspsc190501.80171701'),
	'identifierSchemeCodeUnspsc190501.80171702': __('identifierSchemeCodeUnspsc190501.80171702'),
	'identifierSchemeCodeUnspsc190501.80171800': __('identifierSchemeCodeUnspsc190501.80171800'),
	'identifierSchemeCodeUnspsc190501.80171801': __('identifierSchemeCodeUnspsc190501.80171801'),
	'identifierSchemeCodeUnspsc190501.80171802': __('identifierSchemeCodeUnspsc190501.80171802'),
	'identifierSchemeCodeUnspsc190501.80171803': __('identifierSchemeCodeUnspsc190501.80171803'),
	'identifierSchemeCodeUnspsc190501.80171900': __('identifierSchemeCodeUnspsc190501.80171900'),
	'identifierSchemeCodeUnspsc190501.80171901': __('identifierSchemeCodeUnspsc190501.80171901'),
	'identifierSchemeCodeUnspsc190501.80171902': __('identifierSchemeCodeUnspsc190501.80171902'),
	'identifierSchemeCodeUnspsc190501.80171903': __('identifierSchemeCodeUnspsc190501.80171903'),
	'identifierSchemeCodeUnspsc190501.80171904': __('identifierSchemeCodeUnspsc190501.80171904'),
	'identifierSchemeCodeUnspsc190501.80171905': __('identifierSchemeCodeUnspsc190501.80171905'),
	'identifierSchemeCodeUnspsc190501.80171906': __('identifierSchemeCodeUnspsc190501.80171906'),
	'identifierSchemeCodeUnspsc190501.80171907': __('identifierSchemeCodeUnspsc190501.80171907'),
	'identifierSchemeCodeUnspsc190501.80171908': __('identifierSchemeCodeUnspsc190501.80171908'),
	'identifierSchemeCodeUnspsc190501.80171909': __('identifierSchemeCodeUnspsc190501.80171909'),
	'identifierSchemeCodeUnspsc190501.80172000': __('identifierSchemeCodeUnspsc190501.80172000'),
	'identifierSchemeCodeUnspsc190501.80172001': __('identifierSchemeCodeUnspsc190501.80172001'),
	'identifierSchemeCodeUnspsc190501.80172002': __('identifierSchemeCodeUnspsc190501.80172002'),
	'identifierSchemeCodeUnspsc190501.80172003': __('identifierSchemeCodeUnspsc190501.80172003'),
	'identifierSchemeCodeUnspsc190501.80172100': __('identifierSchemeCodeUnspsc190501.80172100'),
	'identifierSchemeCodeUnspsc190501.80172101': __('identifierSchemeCodeUnspsc190501.80172101'),
	'identifierSchemeCodeUnspsc190501.80172102': __('identifierSchemeCodeUnspsc190501.80172102'),
	'identifierSchemeCodeUnspsc190501.80172103': __('identifierSchemeCodeUnspsc190501.80172103'),
	'identifierSchemeCodeUnspsc190501.80172104': __('identifierSchemeCodeUnspsc190501.80172104'),
	'identifierSchemeCodeUnspsc190501.81000000': __('identifierSchemeCodeUnspsc190501.81000000'),
	'identifierSchemeCodeUnspsc190501.81100000': __('identifierSchemeCodeUnspsc190501.81100000'),
	'identifierSchemeCodeUnspsc190501.81101500': __('identifierSchemeCodeUnspsc190501.81101500'),
	'identifierSchemeCodeUnspsc190501.81101501': __('identifierSchemeCodeUnspsc190501.81101501'),
	'identifierSchemeCodeUnspsc190501.81101502': __('identifierSchemeCodeUnspsc190501.81101502'),
	'identifierSchemeCodeUnspsc190501.81101503': __('identifierSchemeCodeUnspsc190501.81101503'),
	'identifierSchemeCodeUnspsc190501.81101505': __('identifierSchemeCodeUnspsc190501.81101505'),
	'identifierSchemeCodeUnspsc190501.81101506': __('identifierSchemeCodeUnspsc190501.81101506'),
	'identifierSchemeCodeUnspsc190501.81101507': __('identifierSchemeCodeUnspsc190501.81101507'),
	'identifierSchemeCodeUnspsc190501.81101508': __('identifierSchemeCodeUnspsc190501.81101508'),
	'identifierSchemeCodeUnspsc190501.81101509': __('identifierSchemeCodeUnspsc190501.81101509'),
	'identifierSchemeCodeUnspsc190501.81101510': __('identifierSchemeCodeUnspsc190501.81101510'),
	'identifierSchemeCodeUnspsc190501.81101511': __('identifierSchemeCodeUnspsc190501.81101511'),
	'identifierSchemeCodeUnspsc190501.81101512': __('identifierSchemeCodeUnspsc190501.81101512'),
	'identifierSchemeCodeUnspsc190501.81101513': __('identifierSchemeCodeUnspsc190501.81101513'),
	'identifierSchemeCodeUnspsc190501.81101514': __('identifierSchemeCodeUnspsc190501.81101514'),
	'identifierSchemeCodeUnspsc190501.81101515': __('identifierSchemeCodeUnspsc190501.81101515'),
	'identifierSchemeCodeUnspsc190501.81101516': __('identifierSchemeCodeUnspsc190501.81101516'),
	'identifierSchemeCodeUnspsc190501.81101517': __('identifierSchemeCodeUnspsc190501.81101517'),
	'identifierSchemeCodeUnspsc190501.81101518': __('identifierSchemeCodeUnspsc190501.81101518'),
	'identifierSchemeCodeUnspsc190501.81101519': __('identifierSchemeCodeUnspsc190501.81101519'),
	'identifierSchemeCodeUnspsc190501.81101520': __('identifierSchemeCodeUnspsc190501.81101520'),
	'identifierSchemeCodeUnspsc190501.81101521': __('identifierSchemeCodeUnspsc190501.81101521'),
	'identifierSchemeCodeUnspsc190501.81101522': __('identifierSchemeCodeUnspsc190501.81101522'),
	'identifierSchemeCodeUnspsc190501.81101523': __('identifierSchemeCodeUnspsc190501.81101523'),
	'identifierSchemeCodeUnspsc190501.81101524': __('identifierSchemeCodeUnspsc190501.81101524'),
	'identifierSchemeCodeUnspsc190501.81101525': __('identifierSchemeCodeUnspsc190501.81101525'),
	'identifierSchemeCodeUnspsc190501.81101526': __('identifierSchemeCodeUnspsc190501.81101526'),
	'identifierSchemeCodeUnspsc190501.81101527': __('identifierSchemeCodeUnspsc190501.81101527'),
	'identifierSchemeCodeUnspsc190501.81101528': __('identifierSchemeCodeUnspsc190501.81101528'),
	'identifierSchemeCodeUnspsc190501.81101529': __('identifierSchemeCodeUnspsc190501.81101529'),
	'identifierSchemeCodeUnspsc190501.81101530': __('identifierSchemeCodeUnspsc190501.81101530'),
	'identifierSchemeCodeUnspsc190501.81101600': __('identifierSchemeCodeUnspsc190501.81101600'),
	'identifierSchemeCodeUnspsc190501.81101601': __('identifierSchemeCodeUnspsc190501.81101601'),
	'identifierSchemeCodeUnspsc190501.81101602': __('identifierSchemeCodeUnspsc190501.81101602'),
	'identifierSchemeCodeUnspsc190501.81101603': __('identifierSchemeCodeUnspsc190501.81101603'),
	'identifierSchemeCodeUnspsc190501.81101604': __('identifierSchemeCodeUnspsc190501.81101604'),
	'identifierSchemeCodeUnspsc190501.81101605': __('identifierSchemeCodeUnspsc190501.81101605'),
	'identifierSchemeCodeUnspsc190501.81101606': __('identifierSchemeCodeUnspsc190501.81101606'),
	'identifierSchemeCodeUnspsc190501.81101607': __('identifierSchemeCodeUnspsc190501.81101607'),
	'identifierSchemeCodeUnspsc190501.81101700': __('identifierSchemeCodeUnspsc190501.81101700'),
	'identifierSchemeCodeUnspsc190501.81101701': __('identifierSchemeCodeUnspsc190501.81101701'),
	'identifierSchemeCodeUnspsc190501.81101702': __('identifierSchemeCodeUnspsc190501.81101702'),
	'identifierSchemeCodeUnspsc190501.81101703': __('identifierSchemeCodeUnspsc190501.81101703'),
	'identifierSchemeCodeUnspsc190501.81101706': __('identifierSchemeCodeUnspsc190501.81101706'),
	'identifierSchemeCodeUnspsc190501.81101707': __('identifierSchemeCodeUnspsc190501.81101707'),
	'identifierSchemeCodeUnspsc190501.81101710': __('identifierSchemeCodeUnspsc190501.81101710'),
	'identifierSchemeCodeUnspsc190501.81101711': __('identifierSchemeCodeUnspsc190501.81101711'),
	'identifierSchemeCodeUnspsc190501.81101713': __('identifierSchemeCodeUnspsc190501.81101713'),
	'identifierSchemeCodeUnspsc190501.81101800': __('identifierSchemeCodeUnspsc190501.81101800'),
	'identifierSchemeCodeUnspsc190501.81101801': __('identifierSchemeCodeUnspsc190501.81101801'),
	'identifierSchemeCodeUnspsc190501.81101802': __('identifierSchemeCodeUnspsc190501.81101802'),
	'identifierSchemeCodeUnspsc190501.81101900': __('identifierSchemeCodeUnspsc190501.81101900'),
	'identifierSchemeCodeUnspsc190501.81101902': __('identifierSchemeCodeUnspsc190501.81101902'),
	'identifierSchemeCodeUnspsc190501.81102000': __('identifierSchemeCodeUnspsc190501.81102000'),
	'identifierSchemeCodeUnspsc190501.81102001': __('identifierSchemeCodeUnspsc190501.81102001'),
	'identifierSchemeCodeUnspsc190501.81102100': __('identifierSchemeCodeUnspsc190501.81102100'),
	'identifierSchemeCodeUnspsc190501.81102101': __('identifierSchemeCodeUnspsc190501.81102101'),
	'identifierSchemeCodeUnspsc190501.81102200': __('identifierSchemeCodeUnspsc190501.81102200'),
	'identifierSchemeCodeUnspsc190501.81102201': __('identifierSchemeCodeUnspsc190501.81102201'),
	'identifierSchemeCodeUnspsc190501.81102202': __('identifierSchemeCodeUnspsc190501.81102202'),
	'identifierSchemeCodeUnspsc190501.81102203': __('identifierSchemeCodeUnspsc190501.81102203'),
	'identifierSchemeCodeUnspsc190501.81102300': __('identifierSchemeCodeUnspsc190501.81102300'),
	'identifierSchemeCodeUnspsc190501.81102301': __('identifierSchemeCodeUnspsc190501.81102301'),
	'identifierSchemeCodeUnspsc190501.81102302': __('identifierSchemeCodeUnspsc190501.81102302'),
	'identifierSchemeCodeUnspsc190501.81102400': __('identifierSchemeCodeUnspsc190501.81102400'),
	'identifierSchemeCodeUnspsc190501.81102401': __('identifierSchemeCodeUnspsc190501.81102401'),
	'identifierSchemeCodeUnspsc190501.81102402': __('identifierSchemeCodeUnspsc190501.81102402'),
	'identifierSchemeCodeUnspsc190501.81102500': __('identifierSchemeCodeUnspsc190501.81102500'),
	'identifierSchemeCodeUnspsc190501.81102501': __('identifierSchemeCodeUnspsc190501.81102501'),
	'identifierSchemeCodeUnspsc190501.81102502': __('identifierSchemeCodeUnspsc190501.81102502'),
	'identifierSchemeCodeUnspsc190501.81102503': __('identifierSchemeCodeUnspsc190501.81102503'),
	'identifierSchemeCodeUnspsc190501.81102600': __('identifierSchemeCodeUnspsc190501.81102600'),
	'identifierSchemeCodeUnspsc190501.81102601': __('identifierSchemeCodeUnspsc190501.81102601'),
	'identifierSchemeCodeUnspsc190501.81102700': __('identifierSchemeCodeUnspsc190501.81102700'),
	'identifierSchemeCodeUnspsc190501.81102701': __('identifierSchemeCodeUnspsc190501.81102701'),
	'identifierSchemeCodeUnspsc190501.81102702': __('identifierSchemeCodeUnspsc190501.81102702'),
	'identifierSchemeCodeUnspsc190501.81102800': __('identifierSchemeCodeUnspsc190501.81102800'),
	'identifierSchemeCodeUnspsc190501.81102801': __('identifierSchemeCodeUnspsc190501.81102801'),
	'identifierSchemeCodeUnspsc190501.81102802': __('identifierSchemeCodeUnspsc190501.81102802'),
	'identifierSchemeCodeUnspsc190501.81102803': __('identifierSchemeCodeUnspsc190501.81102803'),
	'identifierSchemeCodeUnspsc190501.81102804': __('identifierSchemeCodeUnspsc190501.81102804'),
	'identifierSchemeCodeUnspsc190501.81102805': __('identifierSchemeCodeUnspsc190501.81102805'),
	'identifierSchemeCodeUnspsc190501.81102806': __('identifierSchemeCodeUnspsc190501.81102806'),
	'identifierSchemeCodeUnspsc190501.81102807': __('identifierSchemeCodeUnspsc190501.81102807'),
	'identifierSchemeCodeUnspsc190501.81102808': __('identifierSchemeCodeUnspsc190501.81102808'),
	'identifierSchemeCodeUnspsc190501.81102809': __('identifierSchemeCodeUnspsc190501.81102809'),
	'identifierSchemeCodeUnspsc190501.81102810': __('identifierSchemeCodeUnspsc190501.81102810'),
	'identifierSchemeCodeUnspsc190501.81102811': __('identifierSchemeCodeUnspsc190501.81102811'),
	'identifierSchemeCodeUnspsc190501.81110000': __('identifierSchemeCodeUnspsc190501.81110000'),
	'identifierSchemeCodeUnspsc190501.81111500': __('identifierSchemeCodeUnspsc190501.81111500'),
	'identifierSchemeCodeUnspsc190501.81111501': __('identifierSchemeCodeUnspsc190501.81111501'),
	'identifierSchemeCodeUnspsc190501.81111502': __('identifierSchemeCodeUnspsc190501.81111502'),
	'identifierSchemeCodeUnspsc190501.81111503': __('identifierSchemeCodeUnspsc190501.81111503'),
	'identifierSchemeCodeUnspsc190501.81111504': __('identifierSchemeCodeUnspsc190501.81111504'),
	'identifierSchemeCodeUnspsc190501.81111505': __('identifierSchemeCodeUnspsc190501.81111505'),
	'identifierSchemeCodeUnspsc190501.81111506': __('identifierSchemeCodeUnspsc190501.81111506'),
	'identifierSchemeCodeUnspsc190501.81111507': __('identifierSchemeCodeUnspsc190501.81111507'),
	'identifierSchemeCodeUnspsc190501.81111508': __('identifierSchemeCodeUnspsc190501.81111508'),
	'identifierSchemeCodeUnspsc190501.81111509': __('identifierSchemeCodeUnspsc190501.81111509'),
	'identifierSchemeCodeUnspsc190501.81111510': __('identifierSchemeCodeUnspsc190501.81111510'),
	'identifierSchemeCodeUnspsc190501.81111511': __('identifierSchemeCodeUnspsc190501.81111511'),
	'identifierSchemeCodeUnspsc190501.81111512': __('identifierSchemeCodeUnspsc190501.81111512'),
	'identifierSchemeCodeUnspsc190501.81111600': __('identifierSchemeCodeUnspsc190501.81111600'),
	'identifierSchemeCodeUnspsc190501.81111601': __('identifierSchemeCodeUnspsc190501.81111601'),
	'identifierSchemeCodeUnspsc190501.81111602': __('identifierSchemeCodeUnspsc190501.81111602'),
	'identifierSchemeCodeUnspsc190501.81111603': __('identifierSchemeCodeUnspsc190501.81111603'),
	'identifierSchemeCodeUnspsc190501.81111604': __('identifierSchemeCodeUnspsc190501.81111604'),
	'identifierSchemeCodeUnspsc190501.81111605': __('identifierSchemeCodeUnspsc190501.81111605'),
	'identifierSchemeCodeUnspsc190501.81111606': __('identifierSchemeCodeUnspsc190501.81111606'),
	'identifierSchemeCodeUnspsc190501.81111607': __('identifierSchemeCodeUnspsc190501.81111607'),
	'identifierSchemeCodeUnspsc190501.81111608': __('identifierSchemeCodeUnspsc190501.81111608'),
	'identifierSchemeCodeUnspsc190501.81111609': __('identifierSchemeCodeUnspsc190501.81111609'),
	'identifierSchemeCodeUnspsc190501.81111610': __('identifierSchemeCodeUnspsc190501.81111610'),
	'identifierSchemeCodeUnspsc190501.81111611': __('identifierSchemeCodeUnspsc190501.81111611'),
	'identifierSchemeCodeUnspsc190501.81111612': __('identifierSchemeCodeUnspsc190501.81111612'),
	'identifierSchemeCodeUnspsc190501.81111613': __('identifierSchemeCodeUnspsc190501.81111613'),
	'identifierSchemeCodeUnspsc190501.81111700': __('identifierSchemeCodeUnspsc190501.81111700'),
	'identifierSchemeCodeUnspsc190501.81111701': __('identifierSchemeCodeUnspsc190501.81111701'),
	'identifierSchemeCodeUnspsc190501.81111702': __('identifierSchemeCodeUnspsc190501.81111702'),
	'identifierSchemeCodeUnspsc190501.81111703': __('identifierSchemeCodeUnspsc190501.81111703'),
	'identifierSchemeCodeUnspsc190501.81111704': __('identifierSchemeCodeUnspsc190501.81111704'),
	'identifierSchemeCodeUnspsc190501.81111705': __('identifierSchemeCodeUnspsc190501.81111705'),
	'identifierSchemeCodeUnspsc190501.81111706': __('identifierSchemeCodeUnspsc190501.81111706'),
	'identifierSchemeCodeUnspsc190501.81111707': __('identifierSchemeCodeUnspsc190501.81111707'),
	'identifierSchemeCodeUnspsc190501.81111708': __('identifierSchemeCodeUnspsc190501.81111708'),
	'identifierSchemeCodeUnspsc190501.81111709': __('identifierSchemeCodeUnspsc190501.81111709'),
	'identifierSchemeCodeUnspsc190501.81111710': __('identifierSchemeCodeUnspsc190501.81111710'),
	'identifierSchemeCodeUnspsc190501.81111800': __('identifierSchemeCodeUnspsc190501.81111800'),
	'identifierSchemeCodeUnspsc190501.81111801': __('identifierSchemeCodeUnspsc190501.81111801'),
	'identifierSchemeCodeUnspsc190501.81111802': __('identifierSchemeCodeUnspsc190501.81111802'),
	'identifierSchemeCodeUnspsc190501.81111803': __('identifierSchemeCodeUnspsc190501.81111803'),
	'identifierSchemeCodeUnspsc190501.81111804': __('identifierSchemeCodeUnspsc190501.81111804'),
	'identifierSchemeCodeUnspsc190501.81111805': __('identifierSchemeCodeUnspsc190501.81111805'),
	'identifierSchemeCodeUnspsc190501.81111806': __('identifierSchemeCodeUnspsc190501.81111806'),
	'identifierSchemeCodeUnspsc190501.81111808': __('identifierSchemeCodeUnspsc190501.81111808'),
	'identifierSchemeCodeUnspsc190501.81111809': __('identifierSchemeCodeUnspsc190501.81111809'),
	'identifierSchemeCodeUnspsc190501.81111810': __('identifierSchemeCodeUnspsc190501.81111810'),
	'identifierSchemeCodeUnspsc190501.81111811': __('identifierSchemeCodeUnspsc190501.81111811'),
	'identifierSchemeCodeUnspsc190501.81111812': __('identifierSchemeCodeUnspsc190501.81111812'),
	'identifierSchemeCodeUnspsc190501.81111814': __('identifierSchemeCodeUnspsc190501.81111814'),
	'identifierSchemeCodeUnspsc190501.81111818': __('identifierSchemeCodeUnspsc190501.81111818'),
	'identifierSchemeCodeUnspsc190501.81111819': __('identifierSchemeCodeUnspsc190501.81111819'),
	'identifierSchemeCodeUnspsc190501.81111820': __('identifierSchemeCodeUnspsc190501.81111820'),
	'identifierSchemeCodeUnspsc190501.81111900': __('identifierSchemeCodeUnspsc190501.81111900'),
	'identifierSchemeCodeUnspsc190501.81111901': __('identifierSchemeCodeUnspsc190501.81111901'),
	'identifierSchemeCodeUnspsc190501.81111902': __('identifierSchemeCodeUnspsc190501.81111902'),
	'identifierSchemeCodeUnspsc190501.81112000': __('identifierSchemeCodeUnspsc190501.81112000'),
	'identifierSchemeCodeUnspsc190501.81112001': __('identifierSchemeCodeUnspsc190501.81112001'),
	'identifierSchemeCodeUnspsc190501.81112002': __('identifierSchemeCodeUnspsc190501.81112002'),
	'identifierSchemeCodeUnspsc190501.81112003': __('identifierSchemeCodeUnspsc190501.81112003'),
	'identifierSchemeCodeUnspsc190501.81112004': __('identifierSchemeCodeUnspsc190501.81112004'),
	'identifierSchemeCodeUnspsc190501.81112005': __('identifierSchemeCodeUnspsc190501.81112005'),
	'identifierSchemeCodeUnspsc190501.81112006': __('identifierSchemeCodeUnspsc190501.81112006'),
	'identifierSchemeCodeUnspsc190501.81112007': __('identifierSchemeCodeUnspsc190501.81112007'),
	'identifierSchemeCodeUnspsc190501.81112008': __('identifierSchemeCodeUnspsc190501.81112008'),
	'identifierSchemeCodeUnspsc190501.81112009': __('identifierSchemeCodeUnspsc190501.81112009'),
	'identifierSchemeCodeUnspsc190501.81112010': __('identifierSchemeCodeUnspsc190501.81112010'),
	'identifierSchemeCodeUnspsc190501.81112100': __('identifierSchemeCodeUnspsc190501.81112100'),
	'identifierSchemeCodeUnspsc190501.81112101': __('identifierSchemeCodeUnspsc190501.81112101'),
	'identifierSchemeCodeUnspsc190501.81112102': __('identifierSchemeCodeUnspsc190501.81112102'),
	'identifierSchemeCodeUnspsc190501.81112103': __('identifierSchemeCodeUnspsc190501.81112103'),
	'identifierSchemeCodeUnspsc190501.81112104': __('identifierSchemeCodeUnspsc190501.81112104'),
	'identifierSchemeCodeUnspsc190501.81112105': __('identifierSchemeCodeUnspsc190501.81112105'),
	'identifierSchemeCodeUnspsc190501.81112106': __('identifierSchemeCodeUnspsc190501.81112106'),
	'identifierSchemeCodeUnspsc190501.81112107': __('identifierSchemeCodeUnspsc190501.81112107'),
	'identifierSchemeCodeUnspsc190501.81112200': __('identifierSchemeCodeUnspsc190501.81112200'),
	'identifierSchemeCodeUnspsc190501.81112201': __('identifierSchemeCodeUnspsc190501.81112201'),
	'identifierSchemeCodeUnspsc190501.81112202': __('identifierSchemeCodeUnspsc190501.81112202'),
	'identifierSchemeCodeUnspsc190501.81112203': __('identifierSchemeCodeUnspsc190501.81112203'),
	'identifierSchemeCodeUnspsc190501.81112204': __('identifierSchemeCodeUnspsc190501.81112204'),
	'identifierSchemeCodeUnspsc190501.81112205': __('identifierSchemeCodeUnspsc190501.81112205'),
	'identifierSchemeCodeUnspsc190501.81112206': __('identifierSchemeCodeUnspsc190501.81112206'),
	'identifierSchemeCodeUnspsc190501.81112207': __('identifierSchemeCodeUnspsc190501.81112207'),
	'identifierSchemeCodeUnspsc190501.81112208': __('identifierSchemeCodeUnspsc190501.81112208'),
	'identifierSchemeCodeUnspsc190501.81112209': __('identifierSchemeCodeUnspsc190501.81112209'),
	'identifierSchemeCodeUnspsc190501.81112210': __('identifierSchemeCodeUnspsc190501.81112210'),
	'identifierSchemeCodeUnspsc190501.81112211': __('identifierSchemeCodeUnspsc190501.81112211'),
	'identifierSchemeCodeUnspsc190501.81112212': __('identifierSchemeCodeUnspsc190501.81112212'),
	'identifierSchemeCodeUnspsc190501.81112213': __('identifierSchemeCodeUnspsc190501.81112213'),
	'identifierSchemeCodeUnspsc190501.81112214': __('identifierSchemeCodeUnspsc190501.81112214'),
	'identifierSchemeCodeUnspsc190501.81112215': __('identifierSchemeCodeUnspsc190501.81112215'),
	'identifierSchemeCodeUnspsc190501.81112216': __('identifierSchemeCodeUnspsc190501.81112216'),
	'identifierSchemeCodeUnspsc190501.81112217': __('identifierSchemeCodeUnspsc190501.81112217'),
	'identifierSchemeCodeUnspsc190501.81112218': __('identifierSchemeCodeUnspsc190501.81112218'),
	'identifierSchemeCodeUnspsc190501.81112219': __('identifierSchemeCodeUnspsc190501.81112219'),
	'identifierSchemeCodeUnspsc190501.81112220': __('identifierSchemeCodeUnspsc190501.81112220'),
	'identifierSchemeCodeUnspsc190501.81112221': __('identifierSchemeCodeUnspsc190501.81112221'),
	'identifierSchemeCodeUnspsc190501.81112222': __('identifierSchemeCodeUnspsc190501.81112222'),
	'identifierSchemeCodeUnspsc190501.81112300': __('identifierSchemeCodeUnspsc190501.81112300'),
	'identifierSchemeCodeUnspsc190501.81112301': __('identifierSchemeCodeUnspsc190501.81112301'),
	'identifierSchemeCodeUnspsc190501.81112302': __('identifierSchemeCodeUnspsc190501.81112302'),
	'identifierSchemeCodeUnspsc190501.81112303': __('identifierSchemeCodeUnspsc190501.81112303'),
	'identifierSchemeCodeUnspsc190501.81112304': __('identifierSchemeCodeUnspsc190501.81112304'),
	'identifierSchemeCodeUnspsc190501.81112305': __('identifierSchemeCodeUnspsc190501.81112305'),
	'identifierSchemeCodeUnspsc190501.81112306': __('identifierSchemeCodeUnspsc190501.81112306'),
	'identifierSchemeCodeUnspsc190501.81112307': __('identifierSchemeCodeUnspsc190501.81112307'),
	'identifierSchemeCodeUnspsc190501.81112308': __('identifierSchemeCodeUnspsc190501.81112308'),
	'identifierSchemeCodeUnspsc190501.81112309': __('identifierSchemeCodeUnspsc190501.81112309'),
	'identifierSchemeCodeUnspsc190501.81112310': __('identifierSchemeCodeUnspsc190501.81112310'),
	'identifierSchemeCodeUnspsc190501.81112400': __('identifierSchemeCodeUnspsc190501.81112400'),
	'identifierSchemeCodeUnspsc190501.81112401': __('identifierSchemeCodeUnspsc190501.81112401'),
	'identifierSchemeCodeUnspsc190501.81112500': __('identifierSchemeCodeUnspsc190501.81112500'),
	'identifierSchemeCodeUnspsc190501.81112501': __('identifierSchemeCodeUnspsc190501.81112501'),
	'identifierSchemeCodeUnspsc190501.81112502': __('identifierSchemeCodeUnspsc190501.81112502'),
	'identifierSchemeCodeUnspsc190501.81120000': __('identifierSchemeCodeUnspsc190501.81120000'),
	'identifierSchemeCodeUnspsc190501.81121500': __('identifierSchemeCodeUnspsc190501.81121500'),
	'identifierSchemeCodeUnspsc190501.81121501': __('identifierSchemeCodeUnspsc190501.81121501'),
	'identifierSchemeCodeUnspsc190501.81121502': __('identifierSchemeCodeUnspsc190501.81121502'),
	'identifierSchemeCodeUnspsc190501.81121503': __('identifierSchemeCodeUnspsc190501.81121503'),
	'identifierSchemeCodeUnspsc190501.81121504': __('identifierSchemeCodeUnspsc190501.81121504'),
	'identifierSchemeCodeUnspsc190501.81121505': __('identifierSchemeCodeUnspsc190501.81121505'),
	'identifierSchemeCodeUnspsc190501.81121600': __('identifierSchemeCodeUnspsc190501.81121600'),
	'identifierSchemeCodeUnspsc190501.81121601': __('identifierSchemeCodeUnspsc190501.81121601'),
	'identifierSchemeCodeUnspsc190501.81121602': __('identifierSchemeCodeUnspsc190501.81121602'),
	'identifierSchemeCodeUnspsc190501.81121603': __('identifierSchemeCodeUnspsc190501.81121603'),
	'identifierSchemeCodeUnspsc190501.81121604': __('identifierSchemeCodeUnspsc190501.81121604'),
	'identifierSchemeCodeUnspsc190501.81121605': __('identifierSchemeCodeUnspsc190501.81121605'),
	'identifierSchemeCodeUnspsc190501.81121606': __('identifierSchemeCodeUnspsc190501.81121606'),
	'identifierSchemeCodeUnspsc190501.81121607': __('identifierSchemeCodeUnspsc190501.81121607'),
	'identifierSchemeCodeUnspsc190501.81130000': __('identifierSchemeCodeUnspsc190501.81130000'),
	'identifierSchemeCodeUnspsc190501.81131500': __('identifierSchemeCodeUnspsc190501.81131500'),
	'identifierSchemeCodeUnspsc190501.81131501': __('identifierSchemeCodeUnspsc190501.81131501'),
	'identifierSchemeCodeUnspsc190501.81131502': __('identifierSchemeCodeUnspsc190501.81131502'),
	'identifierSchemeCodeUnspsc190501.81131503': __('identifierSchemeCodeUnspsc190501.81131503'),
	'identifierSchemeCodeUnspsc190501.81131504': __('identifierSchemeCodeUnspsc190501.81131504'),
	'identifierSchemeCodeUnspsc190501.81131505': __('identifierSchemeCodeUnspsc190501.81131505'),
	'identifierSchemeCodeUnspsc190501.81140000': __('identifierSchemeCodeUnspsc190501.81140000'),
	'identifierSchemeCodeUnspsc190501.81141500': __('identifierSchemeCodeUnspsc190501.81141500'),
	'identifierSchemeCodeUnspsc190501.81141501': __('identifierSchemeCodeUnspsc190501.81141501'),
	'identifierSchemeCodeUnspsc190501.81141502': __('identifierSchemeCodeUnspsc190501.81141502'),
	'identifierSchemeCodeUnspsc190501.81141503': __('identifierSchemeCodeUnspsc190501.81141503'),
	'identifierSchemeCodeUnspsc190501.81141504': __('identifierSchemeCodeUnspsc190501.81141504'),
	'identifierSchemeCodeUnspsc190501.81141505': __('identifierSchemeCodeUnspsc190501.81141505'),
	'identifierSchemeCodeUnspsc190501.81141506': __('identifierSchemeCodeUnspsc190501.81141506'),
	'identifierSchemeCodeUnspsc190501.81141600': __('identifierSchemeCodeUnspsc190501.81141600'),
	'identifierSchemeCodeUnspsc190501.81141601': __('identifierSchemeCodeUnspsc190501.81141601'),
	'identifierSchemeCodeUnspsc190501.81141602': __('identifierSchemeCodeUnspsc190501.81141602'),
	'identifierSchemeCodeUnspsc190501.81141603': __('identifierSchemeCodeUnspsc190501.81141603'),
	'identifierSchemeCodeUnspsc190501.81141604': __('identifierSchemeCodeUnspsc190501.81141604'),
	'identifierSchemeCodeUnspsc190501.81141605': __('identifierSchemeCodeUnspsc190501.81141605'),
	'identifierSchemeCodeUnspsc190501.81141606': __('identifierSchemeCodeUnspsc190501.81141606'),
	'identifierSchemeCodeUnspsc190501.81141700': __('identifierSchemeCodeUnspsc190501.81141700'),
	'identifierSchemeCodeUnspsc190501.81141701': __('identifierSchemeCodeUnspsc190501.81141701'),
	'identifierSchemeCodeUnspsc190501.81141702': __('identifierSchemeCodeUnspsc190501.81141702'),
	'identifierSchemeCodeUnspsc190501.81141703': __('identifierSchemeCodeUnspsc190501.81141703'),
	'identifierSchemeCodeUnspsc190501.81141704': __('identifierSchemeCodeUnspsc190501.81141704'),
	'identifierSchemeCodeUnspsc190501.81141800': __('identifierSchemeCodeUnspsc190501.81141800'),
	'identifierSchemeCodeUnspsc190501.81141801': __('identifierSchemeCodeUnspsc190501.81141801'),
	'identifierSchemeCodeUnspsc190501.81141802': __('identifierSchemeCodeUnspsc190501.81141802'),
	'identifierSchemeCodeUnspsc190501.81141803': __('identifierSchemeCodeUnspsc190501.81141803'),
	'identifierSchemeCodeUnspsc190501.81141804': __('identifierSchemeCodeUnspsc190501.81141804'),
	'identifierSchemeCodeUnspsc190501.81141805': __('identifierSchemeCodeUnspsc190501.81141805'),
	'identifierSchemeCodeUnspsc190501.81141806': __('identifierSchemeCodeUnspsc190501.81141806'),
	'identifierSchemeCodeUnspsc190501.81141807': __('identifierSchemeCodeUnspsc190501.81141807'),
	'identifierSchemeCodeUnspsc190501.81141900': __('identifierSchemeCodeUnspsc190501.81141900'),
	'identifierSchemeCodeUnspsc190501.81141901': __('identifierSchemeCodeUnspsc190501.81141901'),
	'identifierSchemeCodeUnspsc190501.81141902': __('identifierSchemeCodeUnspsc190501.81141902'),
	'identifierSchemeCodeUnspsc190501.81150000': __('identifierSchemeCodeUnspsc190501.81150000'),
	'identifierSchemeCodeUnspsc190501.81151500': __('identifierSchemeCodeUnspsc190501.81151500'),
	'identifierSchemeCodeUnspsc190501.81151501': __('identifierSchemeCodeUnspsc190501.81151501'),
	'identifierSchemeCodeUnspsc190501.81151502': __('identifierSchemeCodeUnspsc190501.81151502'),
	'identifierSchemeCodeUnspsc190501.81151503': __('identifierSchemeCodeUnspsc190501.81151503'),
	'identifierSchemeCodeUnspsc190501.81151600': __('identifierSchemeCodeUnspsc190501.81151600'),
	'identifierSchemeCodeUnspsc190501.81151601': __('identifierSchemeCodeUnspsc190501.81151601'),
	'identifierSchemeCodeUnspsc190501.81151602': __('identifierSchemeCodeUnspsc190501.81151602'),
	'identifierSchemeCodeUnspsc190501.81151603': __('identifierSchemeCodeUnspsc190501.81151603'),
	'identifierSchemeCodeUnspsc190501.81151604': __('identifierSchemeCodeUnspsc190501.81151604'),
	'identifierSchemeCodeUnspsc190501.81151700': __('identifierSchemeCodeUnspsc190501.81151700'),
	'identifierSchemeCodeUnspsc190501.81151701': __('identifierSchemeCodeUnspsc190501.81151701'),
	'identifierSchemeCodeUnspsc190501.81151702': __('identifierSchemeCodeUnspsc190501.81151702'),
	'identifierSchemeCodeUnspsc190501.81151703': __('identifierSchemeCodeUnspsc190501.81151703'),
	'identifierSchemeCodeUnspsc190501.81151704': __('identifierSchemeCodeUnspsc190501.81151704'),
	'identifierSchemeCodeUnspsc190501.81151705': __('identifierSchemeCodeUnspsc190501.81151705'),
	'identifierSchemeCodeUnspsc190501.81151800': __('identifierSchemeCodeUnspsc190501.81151800'),
	'identifierSchemeCodeUnspsc190501.81151801': __('identifierSchemeCodeUnspsc190501.81151801'),
	'identifierSchemeCodeUnspsc190501.81151802': __('identifierSchemeCodeUnspsc190501.81151802'),
	'identifierSchemeCodeUnspsc190501.81151803': __('identifierSchemeCodeUnspsc190501.81151803'),
	'identifierSchemeCodeUnspsc190501.81151804': __('identifierSchemeCodeUnspsc190501.81151804'),
	'identifierSchemeCodeUnspsc190501.81151805': __('identifierSchemeCodeUnspsc190501.81151805'),
	'identifierSchemeCodeUnspsc190501.81151806': __('identifierSchemeCodeUnspsc190501.81151806'),
	'identifierSchemeCodeUnspsc190501.81151900': __('identifierSchemeCodeUnspsc190501.81151900'),
	'identifierSchemeCodeUnspsc190501.81151901': __('identifierSchemeCodeUnspsc190501.81151901'),
	'identifierSchemeCodeUnspsc190501.81151902': __('identifierSchemeCodeUnspsc190501.81151902'),
	'identifierSchemeCodeUnspsc190501.81151903': __('identifierSchemeCodeUnspsc190501.81151903'),
	'identifierSchemeCodeUnspsc190501.81151904': __('identifierSchemeCodeUnspsc190501.81151904'),
	'identifierSchemeCodeUnspsc190501.81160000': __('identifierSchemeCodeUnspsc190501.81160000'),
	'identifierSchemeCodeUnspsc190501.81161500': __('identifierSchemeCodeUnspsc190501.81161500'),
	'identifierSchemeCodeUnspsc190501.81161501': __('identifierSchemeCodeUnspsc190501.81161501'),
	'identifierSchemeCodeUnspsc190501.81161502': __('identifierSchemeCodeUnspsc190501.81161502'),
	'identifierSchemeCodeUnspsc190501.81161503': __('identifierSchemeCodeUnspsc190501.81161503'),
	'identifierSchemeCodeUnspsc190501.81161600': __('identifierSchemeCodeUnspsc190501.81161600'),
	'identifierSchemeCodeUnspsc190501.81161601': __('identifierSchemeCodeUnspsc190501.81161601'),
	'identifierSchemeCodeUnspsc190501.81161700': __('identifierSchemeCodeUnspsc190501.81161700'),
	'identifierSchemeCodeUnspsc190501.81161701': __('identifierSchemeCodeUnspsc190501.81161701'),
	'identifierSchemeCodeUnspsc190501.81161702': __('identifierSchemeCodeUnspsc190501.81161702'),
	'identifierSchemeCodeUnspsc190501.81161703': __('identifierSchemeCodeUnspsc190501.81161703'),
	'identifierSchemeCodeUnspsc190501.81161704': __('identifierSchemeCodeUnspsc190501.81161704'),
	'identifierSchemeCodeUnspsc190501.81161705': __('identifierSchemeCodeUnspsc190501.81161705'),
	'identifierSchemeCodeUnspsc190501.81161706': __('identifierSchemeCodeUnspsc190501.81161706'),
	'identifierSchemeCodeUnspsc190501.81161707': __('identifierSchemeCodeUnspsc190501.81161707'),
	'identifierSchemeCodeUnspsc190501.81161708': __('identifierSchemeCodeUnspsc190501.81161708'),
	'identifierSchemeCodeUnspsc190501.81161709': __('identifierSchemeCodeUnspsc190501.81161709'),
	'identifierSchemeCodeUnspsc190501.81161710': __('identifierSchemeCodeUnspsc190501.81161710'),
	'identifierSchemeCodeUnspsc190501.81161711': __('identifierSchemeCodeUnspsc190501.81161711'),
	'identifierSchemeCodeUnspsc190501.81161712': __('identifierSchemeCodeUnspsc190501.81161712'),
	'identifierSchemeCodeUnspsc190501.81161800': __('identifierSchemeCodeUnspsc190501.81161800'),
	'identifierSchemeCodeUnspsc190501.81161801': __('identifierSchemeCodeUnspsc190501.81161801'),
	'identifierSchemeCodeUnspsc190501.81170000': __('identifierSchemeCodeUnspsc190501.81170000'),
	'identifierSchemeCodeUnspsc190501.81171500': __('identifierSchemeCodeUnspsc190501.81171500'),
	'identifierSchemeCodeUnspsc190501.81171501': __('identifierSchemeCodeUnspsc190501.81171501'),
	'identifierSchemeCodeUnspsc190501.81171600': __('identifierSchemeCodeUnspsc190501.81171600'),
	'identifierSchemeCodeUnspsc190501.81171601': __('identifierSchemeCodeUnspsc190501.81171601'),
	'identifierSchemeCodeUnspsc190501.81171700': __('identifierSchemeCodeUnspsc190501.81171700'),
	'identifierSchemeCodeUnspsc190501.81171701': __('identifierSchemeCodeUnspsc190501.81171701'),
	'identifierSchemeCodeUnspsc190501.81171702': __('identifierSchemeCodeUnspsc190501.81171702'),
	'identifierSchemeCodeUnspsc190501.81171800': __('identifierSchemeCodeUnspsc190501.81171800'),
	'identifierSchemeCodeUnspsc190501.81171801': __('identifierSchemeCodeUnspsc190501.81171801'),
	'identifierSchemeCodeUnspsc190501.81171900': __('identifierSchemeCodeUnspsc190501.81171900'),
	'identifierSchemeCodeUnspsc190501.81171901': __('identifierSchemeCodeUnspsc190501.81171901'),
	'identifierSchemeCodeUnspsc190501.82000000': __('identifierSchemeCodeUnspsc190501.82000000'),
	'identifierSchemeCodeUnspsc190501.82100000': __('identifierSchemeCodeUnspsc190501.82100000'),
	'identifierSchemeCodeUnspsc190501.82101500': __('identifierSchemeCodeUnspsc190501.82101500'),
	'identifierSchemeCodeUnspsc190501.82101501': __('identifierSchemeCodeUnspsc190501.82101501'),
	'identifierSchemeCodeUnspsc190501.82101502': __('identifierSchemeCodeUnspsc190501.82101502'),
	'identifierSchemeCodeUnspsc190501.82101503': __('identifierSchemeCodeUnspsc190501.82101503'),
	'identifierSchemeCodeUnspsc190501.82101504': __('identifierSchemeCodeUnspsc190501.82101504'),
	'identifierSchemeCodeUnspsc190501.82101505': __('identifierSchemeCodeUnspsc190501.82101505'),
	'identifierSchemeCodeUnspsc190501.82101506': __('identifierSchemeCodeUnspsc190501.82101506'),
	'identifierSchemeCodeUnspsc190501.82101507': __('identifierSchemeCodeUnspsc190501.82101507'),
	'identifierSchemeCodeUnspsc190501.82101508': __('identifierSchemeCodeUnspsc190501.82101508'),
	'identifierSchemeCodeUnspsc190501.82101600': __('identifierSchemeCodeUnspsc190501.82101600'),
	'identifierSchemeCodeUnspsc190501.82101601': __('identifierSchemeCodeUnspsc190501.82101601'),
	'identifierSchemeCodeUnspsc190501.82101602': __('identifierSchemeCodeUnspsc190501.82101602'),
	'identifierSchemeCodeUnspsc190501.82101603': __('identifierSchemeCodeUnspsc190501.82101603'),
	'identifierSchemeCodeUnspsc190501.82101604': __('identifierSchemeCodeUnspsc190501.82101604'),
	'identifierSchemeCodeUnspsc190501.82101605': __('identifierSchemeCodeUnspsc190501.82101605'),
	'identifierSchemeCodeUnspsc190501.82101606': __('identifierSchemeCodeUnspsc190501.82101606'),
	'identifierSchemeCodeUnspsc190501.82101700': __('identifierSchemeCodeUnspsc190501.82101700'),
	'identifierSchemeCodeUnspsc190501.82101701': __('identifierSchemeCodeUnspsc190501.82101701'),
	'identifierSchemeCodeUnspsc190501.82101702': __('identifierSchemeCodeUnspsc190501.82101702'),
	'identifierSchemeCodeUnspsc190501.82101800': __('identifierSchemeCodeUnspsc190501.82101800'),
	'identifierSchemeCodeUnspsc190501.82101801': __('identifierSchemeCodeUnspsc190501.82101801'),
	'identifierSchemeCodeUnspsc190501.82101802': __('identifierSchemeCodeUnspsc190501.82101802'),
	'identifierSchemeCodeUnspsc190501.82101900': __('identifierSchemeCodeUnspsc190501.82101900'),
	'identifierSchemeCodeUnspsc190501.82101901': __('identifierSchemeCodeUnspsc190501.82101901'),
	'identifierSchemeCodeUnspsc190501.82101902': __('identifierSchemeCodeUnspsc190501.82101902'),
	'identifierSchemeCodeUnspsc190501.82101903': __('identifierSchemeCodeUnspsc190501.82101903'),
	'identifierSchemeCodeUnspsc190501.82101904': __('identifierSchemeCodeUnspsc190501.82101904'),
	'identifierSchemeCodeUnspsc190501.82101905': __('identifierSchemeCodeUnspsc190501.82101905'),
	'identifierSchemeCodeUnspsc190501.82110000': __('identifierSchemeCodeUnspsc190501.82110000'),
	'identifierSchemeCodeUnspsc190501.82111500': __('identifierSchemeCodeUnspsc190501.82111500'),
	'identifierSchemeCodeUnspsc190501.82111501': __('identifierSchemeCodeUnspsc190501.82111501'),
	'identifierSchemeCodeUnspsc190501.82111502': __('identifierSchemeCodeUnspsc190501.82111502'),
	'identifierSchemeCodeUnspsc190501.82111503': __('identifierSchemeCodeUnspsc190501.82111503'),
	'identifierSchemeCodeUnspsc190501.82111600': __('identifierSchemeCodeUnspsc190501.82111600'),
	'identifierSchemeCodeUnspsc190501.82111601': __('identifierSchemeCodeUnspsc190501.82111601'),
	'identifierSchemeCodeUnspsc190501.82111602': __('identifierSchemeCodeUnspsc190501.82111602'),
	'identifierSchemeCodeUnspsc190501.82111603': __('identifierSchemeCodeUnspsc190501.82111603'),
	'identifierSchemeCodeUnspsc190501.82111604': __('identifierSchemeCodeUnspsc190501.82111604'),
	'identifierSchemeCodeUnspsc190501.82111700': __('identifierSchemeCodeUnspsc190501.82111700'),
	'identifierSchemeCodeUnspsc190501.82111701': __('identifierSchemeCodeUnspsc190501.82111701'),
	'identifierSchemeCodeUnspsc190501.82111702': __('identifierSchemeCodeUnspsc190501.82111702'),
	'identifierSchemeCodeUnspsc190501.82111703': __('identifierSchemeCodeUnspsc190501.82111703'),
	'identifierSchemeCodeUnspsc190501.82111704': __('identifierSchemeCodeUnspsc190501.82111704'),
	'identifierSchemeCodeUnspsc190501.82111705': __('identifierSchemeCodeUnspsc190501.82111705'),
	'identifierSchemeCodeUnspsc190501.82111800': __('identifierSchemeCodeUnspsc190501.82111800'),
	'identifierSchemeCodeUnspsc190501.82111801': __('identifierSchemeCodeUnspsc190501.82111801'),
	'identifierSchemeCodeUnspsc190501.82111802': __('identifierSchemeCodeUnspsc190501.82111802'),
	'identifierSchemeCodeUnspsc190501.82111803': __('identifierSchemeCodeUnspsc190501.82111803'),
	'identifierSchemeCodeUnspsc190501.82111804': __('identifierSchemeCodeUnspsc190501.82111804'),
	'identifierSchemeCodeUnspsc190501.82111900': __('identifierSchemeCodeUnspsc190501.82111900'),
	'identifierSchemeCodeUnspsc190501.82111901': __('identifierSchemeCodeUnspsc190501.82111901'),
	'identifierSchemeCodeUnspsc190501.82111902': __('identifierSchemeCodeUnspsc190501.82111902'),
	'identifierSchemeCodeUnspsc190501.82111903': __('identifierSchemeCodeUnspsc190501.82111903'),
	'identifierSchemeCodeUnspsc190501.82111904': __('identifierSchemeCodeUnspsc190501.82111904'),
	'identifierSchemeCodeUnspsc190501.82112000': __('identifierSchemeCodeUnspsc190501.82112000'),
	'identifierSchemeCodeUnspsc190501.82112001': __('identifierSchemeCodeUnspsc190501.82112001'),
	'identifierSchemeCodeUnspsc190501.82112002': __('identifierSchemeCodeUnspsc190501.82112002'),
	'identifierSchemeCodeUnspsc190501.82112003': __('identifierSchemeCodeUnspsc190501.82112003'),
	'identifierSchemeCodeUnspsc190501.82112004': __('identifierSchemeCodeUnspsc190501.82112004'),
	'identifierSchemeCodeUnspsc190501.82112005': __('identifierSchemeCodeUnspsc190501.82112005'),
	'identifierSchemeCodeUnspsc190501.82112006': __('identifierSchemeCodeUnspsc190501.82112006'),
	'identifierSchemeCodeUnspsc190501.82112007': __('identifierSchemeCodeUnspsc190501.82112007'),
	'identifierSchemeCodeUnspsc190501.82112008': __('identifierSchemeCodeUnspsc190501.82112008'),
	'identifierSchemeCodeUnspsc190501.82112009': __('identifierSchemeCodeUnspsc190501.82112009'),
	'identifierSchemeCodeUnspsc190501.82112010': __('identifierSchemeCodeUnspsc190501.82112010'),
	'identifierSchemeCodeUnspsc190501.82112011': __('identifierSchemeCodeUnspsc190501.82112011'),
	'identifierSchemeCodeUnspsc190501.82112012': __('identifierSchemeCodeUnspsc190501.82112012'),
	'identifierSchemeCodeUnspsc190501.82112013': __('identifierSchemeCodeUnspsc190501.82112013'),
	'identifierSchemeCodeUnspsc190501.82112014': __('identifierSchemeCodeUnspsc190501.82112014'),
	'identifierSchemeCodeUnspsc190501.82112015': __('identifierSchemeCodeUnspsc190501.82112015'),
	'identifierSchemeCodeUnspsc190501.82112016': __('identifierSchemeCodeUnspsc190501.82112016'),
	'identifierSchemeCodeUnspsc190501.82112017': __('identifierSchemeCodeUnspsc190501.82112017'),
	'identifierSchemeCodeUnspsc190501.82112018': __('identifierSchemeCodeUnspsc190501.82112018'),
	'identifierSchemeCodeUnspsc190501.82112019': __('identifierSchemeCodeUnspsc190501.82112019'),
	'identifierSchemeCodeUnspsc190501.82112020': __('identifierSchemeCodeUnspsc190501.82112020'),
	'identifierSchemeCodeUnspsc190501.82112021': __('identifierSchemeCodeUnspsc190501.82112021'),
	'identifierSchemeCodeUnspsc190501.82112022': __('identifierSchemeCodeUnspsc190501.82112022'),
	'identifierSchemeCodeUnspsc190501.82112023': __('identifierSchemeCodeUnspsc190501.82112023'),
	'identifierSchemeCodeUnspsc190501.82112024': __('identifierSchemeCodeUnspsc190501.82112024'),
	'identifierSchemeCodeUnspsc190501.82112025': __('identifierSchemeCodeUnspsc190501.82112025'),
	'identifierSchemeCodeUnspsc190501.82112026': __('identifierSchemeCodeUnspsc190501.82112026'),
	'identifierSchemeCodeUnspsc190501.82112027': __('identifierSchemeCodeUnspsc190501.82112027'),
	'identifierSchemeCodeUnspsc190501.82112028': __('identifierSchemeCodeUnspsc190501.82112028'),
	'identifierSchemeCodeUnspsc190501.82112029': __('identifierSchemeCodeUnspsc190501.82112029'),
	'identifierSchemeCodeUnspsc190501.82112030': __('identifierSchemeCodeUnspsc190501.82112030'),
	'identifierSchemeCodeUnspsc190501.82112031': __('identifierSchemeCodeUnspsc190501.82112031'),
	'identifierSchemeCodeUnspsc190501.82112032': __('identifierSchemeCodeUnspsc190501.82112032'),
	'identifierSchemeCodeUnspsc190501.82112033': __('identifierSchemeCodeUnspsc190501.82112033'),
	'identifierSchemeCodeUnspsc190501.82112034': __('identifierSchemeCodeUnspsc190501.82112034'),
	'identifierSchemeCodeUnspsc190501.82112035': __('identifierSchemeCodeUnspsc190501.82112035'),
	'identifierSchemeCodeUnspsc190501.82112036': __('identifierSchemeCodeUnspsc190501.82112036'),
	'identifierSchemeCodeUnspsc190501.82112037': __('identifierSchemeCodeUnspsc190501.82112037'),
	'identifierSchemeCodeUnspsc190501.82112038': __('identifierSchemeCodeUnspsc190501.82112038'),
	'identifierSchemeCodeUnspsc190501.82112039': __('identifierSchemeCodeUnspsc190501.82112039'),
	'identifierSchemeCodeUnspsc190501.82112040': __('identifierSchemeCodeUnspsc190501.82112040'),
	'identifierSchemeCodeUnspsc190501.82112041': __('identifierSchemeCodeUnspsc190501.82112041'),
	'identifierSchemeCodeUnspsc190501.82112042': __('identifierSchemeCodeUnspsc190501.82112042'),
	'identifierSchemeCodeUnspsc190501.82112043': __('identifierSchemeCodeUnspsc190501.82112043'),
	'identifierSchemeCodeUnspsc190501.82112044': __('identifierSchemeCodeUnspsc190501.82112044'),
	'identifierSchemeCodeUnspsc190501.82112045': __('identifierSchemeCodeUnspsc190501.82112045'),
	'identifierSchemeCodeUnspsc190501.82112046': __('identifierSchemeCodeUnspsc190501.82112046'),
	'identifierSchemeCodeUnspsc190501.82112047': __('identifierSchemeCodeUnspsc190501.82112047'),
	'identifierSchemeCodeUnspsc190501.82112048': __('identifierSchemeCodeUnspsc190501.82112048'),
	'identifierSchemeCodeUnspsc190501.82112049': __('identifierSchemeCodeUnspsc190501.82112049'),
	'identifierSchemeCodeUnspsc190501.82112050': __('identifierSchemeCodeUnspsc190501.82112050'),
	'identifierSchemeCodeUnspsc190501.82112051': __('identifierSchemeCodeUnspsc190501.82112051'),
	'identifierSchemeCodeUnspsc190501.82112052': __('identifierSchemeCodeUnspsc190501.82112052'),
	'identifierSchemeCodeUnspsc190501.82112053': __('identifierSchemeCodeUnspsc190501.82112053'),
	'identifierSchemeCodeUnspsc190501.82112054': __('identifierSchemeCodeUnspsc190501.82112054'),
	'identifierSchemeCodeUnspsc190501.82112055': __('identifierSchemeCodeUnspsc190501.82112055'),
	'identifierSchemeCodeUnspsc190501.82112056': __('identifierSchemeCodeUnspsc190501.82112056'),
	'identifierSchemeCodeUnspsc190501.82112057': __('identifierSchemeCodeUnspsc190501.82112057'),
	'identifierSchemeCodeUnspsc190501.82112058': __('identifierSchemeCodeUnspsc190501.82112058'),
	'identifierSchemeCodeUnspsc190501.82112059': __('identifierSchemeCodeUnspsc190501.82112059'),
	'identifierSchemeCodeUnspsc190501.82112060': __('identifierSchemeCodeUnspsc190501.82112060'),
	'identifierSchemeCodeUnspsc190501.82112061': __('identifierSchemeCodeUnspsc190501.82112061'),
	'identifierSchemeCodeUnspsc190501.82112062': __('identifierSchemeCodeUnspsc190501.82112062'),
	'identifierSchemeCodeUnspsc190501.82112063': __('identifierSchemeCodeUnspsc190501.82112063'),
	'identifierSchemeCodeUnspsc190501.82112064': __('identifierSchemeCodeUnspsc190501.82112064'),
	'identifierSchemeCodeUnspsc190501.82112065': __('identifierSchemeCodeUnspsc190501.82112065'),
	'identifierSchemeCodeUnspsc190501.82112066': __('identifierSchemeCodeUnspsc190501.82112066'),
	'identifierSchemeCodeUnspsc190501.82112067': __('identifierSchemeCodeUnspsc190501.82112067'),
	'identifierSchemeCodeUnspsc190501.82120000': __('identifierSchemeCodeUnspsc190501.82120000'),
	'identifierSchemeCodeUnspsc190501.82121500': __('identifierSchemeCodeUnspsc190501.82121500'),
	'identifierSchemeCodeUnspsc190501.82121501': __('identifierSchemeCodeUnspsc190501.82121501'),
	'identifierSchemeCodeUnspsc190501.82121502': __('identifierSchemeCodeUnspsc190501.82121502'),
	'identifierSchemeCodeUnspsc190501.82121503': __('identifierSchemeCodeUnspsc190501.82121503'),
	'identifierSchemeCodeUnspsc190501.82121504': __('identifierSchemeCodeUnspsc190501.82121504'),
	'identifierSchemeCodeUnspsc190501.82121505': __('identifierSchemeCodeUnspsc190501.82121505'),
	'identifierSchemeCodeUnspsc190501.82121506': __('identifierSchemeCodeUnspsc190501.82121506'),
	'identifierSchemeCodeUnspsc190501.82121507': __('identifierSchemeCodeUnspsc190501.82121507'),
	'identifierSchemeCodeUnspsc190501.82121508': __('identifierSchemeCodeUnspsc190501.82121508'),
	'identifierSchemeCodeUnspsc190501.82121509': __('identifierSchemeCodeUnspsc190501.82121509'),
	'identifierSchemeCodeUnspsc190501.82121510': __('identifierSchemeCodeUnspsc190501.82121510'),
	'identifierSchemeCodeUnspsc190501.82121511': __('identifierSchemeCodeUnspsc190501.82121511'),
	'identifierSchemeCodeUnspsc190501.82121512': __('identifierSchemeCodeUnspsc190501.82121512'),
	'identifierSchemeCodeUnspsc190501.82121600': __('identifierSchemeCodeUnspsc190501.82121600'),
	'identifierSchemeCodeUnspsc190501.82121601': __('identifierSchemeCodeUnspsc190501.82121601'),
	'identifierSchemeCodeUnspsc190501.82121602': __('identifierSchemeCodeUnspsc190501.82121602'),
	'identifierSchemeCodeUnspsc190501.82121603': __('identifierSchemeCodeUnspsc190501.82121603'),
	'identifierSchemeCodeUnspsc190501.82121700': __('identifierSchemeCodeUnspsc190501.82121700'),
	'identifierSchemeCodeUnspsc190501.82121701': __('identifierSchemeCodeUnspsc190501.82121701'),
	'identifierSchemeCodeUnspsc190501.82121702': __('identifierSchemeCodeUnspsc190501.82121702'),
	'identifierSchemeCodeUnspsc190501.82121800': __('identifierSchemeCodeUnspsc190501.82121800'),
	'identifierSchemeCodeUnspsc190501.82121801': __('identifierSchemeCodeUnspsc190501.82121801'),
	'identifierSchemeCodeUnspsc190501.82121802': __('identifierSchemeCodeUnspsc190501.82121802'),
	'identifierSchemeCodeUnspsc190501.82121900': __('identifierSchemeCodeUnspsc190501.82121900'),
	'identifierSchemeCodeUnspsc190501.82121901': __('identifierSchemeCodeUnspsc190501.82121901'),
	'identifierSchemeCodeUnspsc190501.82121902': __('identifierSchemeCodeUnspsc190501.82121902'),
	'identifierSchemeCodeUnspsc190501.82121903': __('identifierSchemeCodeUnspsc190501.82121903'),
	'identifierSchemeCodeUnspsc190501.82121904': __('identifierSchemeCodeUnspsc190501.82121904'),
	'identifierSchemeCodeUnspsc190501.82121905': __('identifierSchemeCodeUnspsc190501.82121905'),
	'identifierSchemeCodeUnspsc190501.82121906': __('identifierSchemeCodeUnspsc190501.82121906'),
	'identifierSchemeCodeUnspsc190501.82121907': __('identifierSchemeCodeUnspsc190501.82121907'),
	'identifierSchemeCodeUnspsc190501.82121908': __('identifierSchemeCodeUnspsc190501.82121908'),
	'identifierSchemeCodeUnspsc190501.82130000': __('identifierSchemeCodeUnspsc190501.82130000'),
	'identifierSchemeCodeUnspsc190501.82131500': __('identifierSchemeCodeUnspsc190501.82131500'),
	'identifierSchemeCodeUnspsc190501.82131501': __('identifierSchemeCodeUnspsc190501.82131501'),
	'identifierSchemeCodeUnspsc190501.82131502': __('identifierSchemeCodeUnspsc190501.82131502'),
	'identifierSchemeCodeUnspsc190501.82131503': __('identifierSchemeCodeUnspsc190501.82131503'),
	'identifierSchemeCodeUnspsc190501.82131504': __('identifierSchemeCodeUnspsc190501.82131504'),
	'identifierSchemeCodeUnspsc190501.82131505': __('identifierSchemeCodeUnspsc190501.82131505'),
	'identifierSchemeCodeUnspsc190501.82131600': __('identifierSchemeCodeUnspsc190501.82131600'),
	'identifierSchemeCodeUnspsc190501.82131601': __('identifierSchemeCodeUnspsc190501.82131601'),
	'identifierSchemeCodeUnspsc190501.82131602': __('identifierSchemeCodeUnspsc190501.82131602'),
	'identifierSchemeCodeUnspsc190501.82131603': __('identifierSchemeCodeUnspsc190501.82131603'),
	'identifierSchemeCodeUnspsc190501.82131604': __('identifierSchemeCodeUnspsc190501.82131604'),
	'identifierSchemeCodeUnspsc190501.82140000': __('identifierSchemeCodeUnspsc190501.82140000'),
	'identifierSchemeCodeUnspsc190501.82141500': __('identifierSchemeCodeUnspsc190501.82141500'),
	'identifierSchemeCodeUnspsc190501.82141501': __('identifierSchemeCodeUnspsc190501.82141501'),
	'identifierSchemeCodeUnspsc190501.82141502': __('identifierSchemeCodeUnspsc190501.82141502'),
	'identifierSchemeCodeUnspsc190501.82141503': __('identifierSchemeCodeUnspsc190501.82141503'),
	'identifierSchemeCodeUnspsc190501.82141504': __('identifierSchemeCodeUnspsc190501.82141504'),
	'identifierSchemeCodeUnspsc190501.82141505': __('identifierSchemeCodeUnspsc190501.82141505'),
	'identifierSchemeCodeUnspsc190501.82141506': __('identifierSchemeCodeUnspsc190501.82141506'),
	'identifierSchemeCodeUnspsc190501.82141507': __('identifierSchemeCodeUnspsc190501.82141507'),
	'identifierSchemeCodeUnspsc190501.82141600': __('identifierSchemeCodeUnspsc190501.82141600'),
	'identifierSchemeCodeUnspsc190501.82141601': __('identifierSchemeCodeUnspsc190501.82141601'),
	'identifierSchemeCodeUnspsc190501.82141602': __('identifierSchemeCodeUnspsc190501.82141602'),
	'identifierSchemeCodeUnspsc190501.82150000': __('identifierSchemeCodeUnspsc190501.82150000'),
	'identifierSchemeCodeUnspsc190501.82151500': __('identifierSchemeCodeUnspsc190501.82151500'),
	'identifierSchemeCodeUnspsc190501.82151501': __('identifierSchemeCodeUnspsc190501.82151501'),
	'identifierSchemeCodeUnspsc190501.82151502': __('identifierSchemeCodeUnspsc190501.82151502'),
	'identifierSchemeCodeUnspsc190501.82151503': __('identifierSchemeCodeUnspsc190501.82151503'),
	'identifierSchemeCodeUnspsc190501.82151504': __('identifierSchemeCodeUnspsc190501.82151504'),
	'identifierSchemeCodeUnspsc190501.82151505': __('identifierSchemeCodeUnspsc190501.82151505'),
	'identifierSchemeCodeUnspsc190501.82151506': __('identifierSchemeCodeUnspsc190501.82151506'),
	'identifierSchemeCodeUnspsc190501.82151507': __('identifierSchemeCodeUnspsc190501.82151507'),
	'identifierSchemeCodeUnspsc190501.82151508': __('identifierSchemeCodeUnspsc190501.82151508'),
	'identifierSchemeCodeUnspsc190501.82151509': __('identifierSchemeCodeUnspsc190501.82151509'),
	'identifierSchemeCodeUnspsc190501.82151510': __('identifierSchemeCodeUnspsc190501.82151510'),
	'identifierSchemeCodeUnspsc190501.82151511': __('identifierSchemeCodeUnspsc190501.82151511'),
	'identifierSchemeCodeUnspsc190501.82151512': __('identifierSchemeCodeUnspsc190501.82151512'),
	'identifierSchemeCodeUnspsc190501.82151600': __('identifierSchemeCodeUnspsc190501.82151600'),
	'identifierSchemeCodeUnspsc190501.82151601': __('identifierSchemeCodeUnspsc190501.82151601'),
	'identifierSchemeCodeUnspsc190501.82151602': __('identifierSchemeCodeUnspsc190501.82151602'),
	'identifierSchemeCodeUnspsc190501.82151603': __('identifierSchemeCodeUnspsc190501.82151603'),
	'identifierSchemeCodeUnspsc190501.82151604': __('identifierSchemeCodeUnspsc190501.82151604'),
	'identifierSchemeCodeUnspsc190501.82151700': __('identifierSchemeCodeUnspsc190501.82151700'),
	'identifierSchemeCodeUnspsc190501.82151701': __('identifierSchemeCodeUnspsc190501.82151701'),
	'identifierSchemeCodeUnspsc190501.82151702': __('identifierSchemeCodeUnspsc190501.82151702'),
	'identifierSchemeCodeUnspsc190501.82151703': __('identifierSchemeCodeUnspsc190501.82151703'),
	'identifierSchemeCodeUnspsc190501.82151704': __('identifierSchemeCodeUnspsc190501.82151704'),
	'identifierSchemeCodeUnspsc190501.82151705': __('identifierSchemeCodeUnspsc190501.82151705'),
	'identifierSchemeCodeUnspsc190501.82151706': __('identifierSchemeCodeUnspsc190501.82151706'),
	'identifierSchemeCodeUnspsc190501.82160000': __('identifierSchemeCodeUnspsc190501.82160000'),
	'identifierSchemeCodeUnspsc190501.82161500': __('identifierSchemeCodeUnspsc190501.82161500'),
	'identifierSchemeCodeUnspsc190501.82161501': __('identifierSchemeCodeUnspsc190501.82161501'),
	'identifierSchemeCodeUnspsc190501.82161502': __('identifierSchemeCodeUnspsc190501.82161502'),
	'identifierSchemeCodeUnspsc190501.82161503': __('identifierSchemeCodeUnspsc190501.82161503'),
	'identifierSchemeCodeUnspsc190501.82161504': __('identifierSchemeCodeUnspsc190501.82161504'),
	'identifierSchemeCodeUnspsc190501.82161505': __('identifierSchemeCodeUnspsc190501.82161505'),
	'identifierSchemeCodeUnspsc190501.83000000': __('identifierSchemeCodeUnspsc190501.83000000'),
	'identifierSchemeCodeUnspsc190501.83100000': __('identifierSchemeCodeUnspsc190501.83100000'),
	'identifierSchemeCodeUnspsc190501.83101500': __('identifierSchemeCodeUnspsc190501.83101500'),
	'identifierSchemeCodeUnspsc190501.83101501': __('identifierSchemeCodeUnspsc190501.83101501'),
	'identifierSchemeCodeUnspsc190501.83101502': __('identifierSchemeCodeUnspsc190501.83101502'),
	'identifierSchemeCodeUnspsc190501.83101503': __('identifierSchemeCodeUnspsc190501.83101503'),
	'identifierSchemeCodeUnspsc190501.83101504': __('identifierSchemeCodeUnspsc190501.83101504'),
	'identifierSchemeCodeUnspsc190501.83101505': __('identifierSchemeCodeUnspsc190501.83101505'),
	'identifierSchemeCodeUnspsc190501.83101506': __('identifierSchemeCodeUnspsc190501.83101506'),
	'identifierSchemeCodeUnspsc190501.83101507': __('identifierSchemeCodeUnspsc190501.83101507'),
	'identifierSchemeCodeUnspsc190501.83101508': __('identifierSchemeCodeUnspsc190501.83101508'),
	'identifierSchemeCodeUnspsc190501.83101509': __('identifierSchemeCodeUnspsc190501.83101509'),
	'identifierSchemeCodeUnspsc190501.83101510': __('identifierSchemeCodeUnspsc190501.83101510'),
	'identifierSchemeCodeUnspsc190501.83101600': __('identifierSchemeCodeUnspsc190501.83101600'),
	'identifierSchemeCodeUnspsc190501.83101601': __('identifierSchemeCodeUnspsc190501.83101601'),
	'identifierSchemeCodeUnspsc190501.83101602': __('identifierSchemeCodeUnspsc190501.83101602'),
	'identifierSchemeCodeUnspsc190501.83101603': __('identifierSchemeCodeUnspsc190501.83101603'),
	'identifierSchemeCodeUnspsc190501.83101604': __('identifierSchemeCodeUnspsc190501.83101604'),
	'identifierSchemeCodeUnspsc190501.83101605': __('identifierSchemeCodeUnspsc190501.83101605'),
	'identifierSchemeCodeUnspsc190501.83101800': __('identifierSchemeCodeUnspsc190501.83101800'),
	'identifierSchemeCodeUnspsc190501.83101801': __('identifierSchemeCodeUnspsc190501.83101801'),
	'identifierSchemeCodeUnspsc190501.83101802': __('identifierSchemeCodeUnspsc190501.83101802'),
	'identifierSchemeCodeUnspsc190501.83101803': __('identifierSchemeCodeUnspsc190501.83101803'),
	'identifierSchemeCodeUnspsc190501.83101804': __('identifierSchemeCodeUnspsc190501.83101804'),
	'identifierSchemeCodeUnspsc190501.83101805': __('identifierSchemeCodeUnspsc190501.83101805'),
	'identifierSchemeCodeUnspsc190501.83101806': __('identifierSchemeCodeUnspsc190501.83101806'),
	'identifierSchemeCodeUnspsc190501.83101807': __('identifierSchemeCodeUnspsc190501.83101807'),
	'identifierSchemeCodeUnspsc190501.83101808': __('identifierSchemeCodeUnspsc190501.83101808'),
	'identifierSchemeCodeUnspsc190501.83101900': __('identifierSchemeCodeUnspsc190501.83101900'),
	'identifierSchemeCodeUnspsc190501.83101901': __('identifierSchemeCodeUnspsc190501.83101901'),
	'identifierSchemeCodeUnspsc190501.83101902': __('identifierSchemeCodeUnspsc190501.83101902'),
	'identifierSchemeCodeUnspsc190501.83101903': __('identifierSchemeCodeUnspsc190501.83101903'),
	'identifierSchemeCodeUnspsc190501.83110000': __('identifierSchemeCodeUnspsc190501.83110000'),
	'identifierSchemeCodeUnspsc190501.83111500': __('identifierSchemeCodeUnspsc190501.83111500'),
	'identifierSchemeCodeUnspsc190501.83111501': __('identifierSchemeCodeUnspsc190501.83111501'),
	'identifierSchemeCodeUnspsc190501.83111502': __('identifierSchemeCodeUnspsc190501.83111502'),
	'identifierSchemeCodeUnspsc190501.83111503': __('identifierSchemeCodeUnspsc190501.83111503'),
	'identifierSchemeCodeUnspsc190501.83111504': __('identifierSchemeCodeUnspsc190501.83111504'),
	'identifierSchemeCodeUnspsc190501.83111505': __('identifierSchemeCodeUnspsc190501.83111505'),
	'identifierSchemeCodeUnspsc190501.83111506': __('identifierSchemeCodeUnspsc190501.83111506'),
	'identifierSchemeCodeUnspsc190501.83111507': __('identifierSchemeCodeUnspsc190501.83111507'),
	'identifierSchemeCodeUnspsc190501.83111508': __('identifierSchemeCodeUnspsc190501.83111508'),
	'identifierSchemeCodeUnspsc190501.83111510': __('identifierSchemeCodeUnspsc190501.83111510'),
	'identifierSchemeCodeUnspsc190501.83111511': __('identifierSchemeCodeUnspsc190501.83111511'),
	'identifierSchemeCodeUnspsc190501.83111600': __('identifierSchemeCodeUnspsc190501.83111600'),
	'identifierSchemeCodeUnspsc190501.83111601': __('identifierSchemeCodeUnspsc190501.83111601'),
	'identifierSchemeCodeUnspsc190501.83111602': __('identifierSchemeCodeUnspsc190501.83111602'),
	'identifierSchemeCodeUnspsc190501.83111603': __('identifierSchemeCodeUnspsc190501.83111603'),
	'identifierSchemeCodeUnspsc190501.83111604': __('identifierSchemeCodeUnspsc190501.83111604'),
	'identifierSchemeCodeUnspsc190501.83111605': __('identifierSchemeCodeUnspsc190501.83111605'),
	'identifierSchemeCodeUnspsc190501.83111700': __('identifierSchemeCodeUnspsc190501.83111700'),
	'identifierSchemeCodeUnspsc190501.83111701': __('identifierSchemeCodeUnspsc190501.83111701'),
	'identifierSchemeCodeUnspsc190501.83111702': __('identifierSchemeCodeUnspsc190501.83111702'),
	'identifierSchemeCodeUnspsc190501.83111703': __('identifierSchemeCodeUnspsc190501.83111703'),
	'identifierSchemeCodeUnspsc190501.83111800': __('identifierSchemeCodeUnspsc190501.83111800'),
	'identifierSchemeCodeUnspsc190501.83111801': __('identifierSchemeCodeUnspsc190501.83111801'),
	'identifierSchemeCodeUnspsc190501.83111802': __('identifierSchemeCodeUnspsc190501.83111802'),
	'identifierSchemeCodeUnspsc190501.83111803': __('identifierSchemeCodeUnspsc190501.83111803'),
	'identifierSchemeCodeUnspsc190501.83111804': __('identifierSchemeCodeUnspsc190501.83111804'),
	'identifierSchemeCodeUnspsc190501.83111900': __('identifierSchemeCodeUnspsc190501.83111900'),
	'identifierSchemeCodeUnspsc190501.83111901': __('identifierSchemeCodeUnspsc190501.83111901'),
	'identifierSchemeCodeUnspsc190501.83111902': __('identifierSchemeCodeUnspsc190501.83111902'),
	'identifierSchemeCodeUnspsc190501.83111903': __('identifierSchemeCodeUnspsc190501.83111903'),
	'identifierSchemeCodeUnspsc190501.83111904': __('identifierSchemeCodeUnspsc190501.83111904'),
	'identifierSchemeCodeUnspsc190501.83111905': __('identifierSchemeCodeUnspsc190501.83111905'),
	'identifierSchemeCodeUnspsc190501.83112200': __('identifierSchemeCodeUnspsc190501.83112200'),
	'identifierSchemeCodeUnspsc190501.83112201': __('identifierSchemeCodeUnspsc190501.83112201'),
	'identifierSchemeCodeUnspsc190501.83112202': __('identifierSchemeCodeUnspsc190501.83112202'),
	'identifierSchemeCodeUnspsc190501.83112203': __('identifierSchemeCodeUnspsc190501.83112203'),
	'identifierSchemeCodeUnspsc190501.83112204': __('identifierSchemeCodeUnspsc190501.83112204'),
	'identifierSchemeCodeUnspsc190501.83112205': __('identifierSchemeCodeUnspsc190501.83112205'),
	'identifierSchemeCodeUnspsc190501.83112206': __('identifierSchemeCodeUnspsc190501.83112206'),
	'identifierSchemeCodeUnspsc190501.83112300': __('identifierSchemeCodeUnspsc190501.83112300'),
	'identifierSchemeCodeUnspsc190501.83112301': __('identifierSchemeCodeUnspsc190501.83112301'),
	'identifierSchemeCodeUnspsc190501.83112302': __('identifierSchemeCodeUnspsc190501.83112302'),
	'identifierSchemeCodeUnspsc190501.83112303': __('identifierSchemeCodeUnspsc190501.83112303'),
	'identifierSchemeCodeUnspsc190501.83112304': __('identifierSchemeCodeUnspsc190501.83112304'),
	'identifierSchemeCodeUnspsc190501.83112400': __('identifierSchemeCodeUnspsc190501.83112400'),
	'identifierSchemeCodeUnspsc190501.83112401': __('identifierSchemeCodeUnspsc190501.83112401'),
	'identifierSchemeCodeUnspsc190501.83112402': __('identifierSchemeCodeUnspsc190501.83112402'),
	'identifierSchemeCodeUnspsc190501.83112403': __('identifierSchemeCodeUnspsc190501.83112403'),
	'identifierSchemeCodeUnspsc190501.83112404': __('identifierSchemeCodeUnspsc190501.83112404'),
	'identifierSchemeCodeUnspsc190501.83112405': __('identifierSchemeCodeUnspsc190501.83112405'),
	'identifierSchemeCodeUnspsc190501.83112406': __('identifierSchemeCodeUnspsc190501.83112406'),
	'identifierSchemeCodeUnspsc190501.83112500': __('identifierSchemeCodeUnspsc190501.83112500'),
	'identifierSchemeCodeUnspsc190501.83112501': __('identifierSchemeCodeUnspsc190501.83112501'),
	'identifierSchemeCodeUnspsc190501.83112502': __('identifierSchemeCodeUnspsc190501.83112502'),
	'identifierSchemeCodeUnspsc190501.83112503': __('identifierSchemeCodeUnspsc190501.83112503'),
	'identifierSchemeCodeUnspsc190501.83112504': __('identifierSchemeCodeUnspsc190501.83112504'),
	'identifierSchemeCodeUnspsc190501.83112505': __('identifierSchemeCodeUnspsc190501.83112505'),
	'identifierSchemeCodeUnspsc190501.83112506': __('identifierSchemeCodeUnspsc190501.83112506'),
	'identifierSchemeCodeUnspsc190501.83112600': __('identifierSchemeCodeUnspsc190501.83112600'),
	'identifierSchemeCodeUnspsc190501.83112601': __('identifierSchemeCodeUnspsc190501.83112601'),
	'identifierSchemeCodeUnspsc190501.83112602': __('identifierSchemeCodeUnspsc190501.83112602'),
	'identifierSchemeCodeUnspsc190501.83112603': __('identifierSchemeCodeUnspsc190501.83112603'),
	'identifierSchemeCodeUnspsc190501.83112604': __('identifierSchemeCodeUnspsc190501.83112604'),
	'identifierSchemeCodeUnspsc190501.83112605': __('identifierSchemeCodeUnspsc190501.83112605'),
	'identifierSchemeCodeUnspsc190501.83112606': __('identifierSchemeCodeUnspsc190501.83112606'),
	'identifierSchemeCodeUnspsc190501.83120000': __('identifierSchemeCodeUnspsc190501.83120000'),
	'identifierSchemeCodeUnspsc190501.83121500': __('identifierSchemeCodeUnspsc190501.83121500'),
	'identifierSchemeCodeUnspsc190501.83121501': __('identifierSchemeCodeUnspsc190501.83121501'),
	'identifierSchemeCodeUnspsc190501.83121502': __('identifierSchemeCodeUnspsc190501.83121502'),
	'identifierSchemeCodeUnspsc190501.83121503': __('identifierSchemeCodeUnspsc190501.83121503'),
	'identifierSchemeCodeUnspsc190501.83121504': __('identifierSchemeCodeUnspsc190501.83121504'),
	'identifierSchemeCodeUnspsc190501.83121600': __('identifierSchemeCodeUnspsc190501.83121600'),
	'identifierSchemeCodeUnspsc190501.83121601': __('identifierSchemeCodeUnspsc190501.83121601'),
	'identifierSchemeCodeUnspsc190501.83121602': __('identifierSchemeCodeUnspsc190501.83121602'),
	'identifierSchemeCodeUnspsc190501.83121603': __('identifierSchemeCodeUnspsc190501.83121603'),
	'identifierSchemeCodeUnspsc190501.83121604': __('identifierSchemeCodeUnspsc190501.83121604'),
	'identifierSchemeCodeUnspsc190501.83121605': __('identifierSchemeCodeUnspsc190501.83121605'),
	'identifierSchemeCodeUnspsc190501.83121606': __('identifierSchemeCodeUnspsc190501.83121606'),
	'identifierSchemeCodeUnspsc190501.83121700': __('identifierSchemeCodeUnspsc190501.83121700'),
	'identifierSchemeCodeUnspsc190501.83121701': __('identifierSchemeCodeUnspsc190501.83121701'),
	'identifierSchemeCodeUnspsc190501.83121702': __('identifierSchemeCodeUnspsc190501.83121702'),
	'identifierSchemeCodeUnspsc190501.83121703': __('identifierSchemeCodeUnspsc190501.83121703'),
	'identifierSchemeCodeUnspsc190501.83121704': __('identifierSchemeCodeUnspsc190501.83121704'),
	'identifierSchemeCodeUnspsc190501.84000000': __('identifierSchemeCodeUnspsc190501.84000000'),
	'identifierSchemeCodeUnspsc190501.84100000': __('identifierSchemeCodeUnspsc190501.84100000'),
	'identifierSchemeCodeUnspsc190501.84101500': __('identifierSchemeCodeUnspsc190501.84101500'),
	'identifierSchemeCodeUnspsc190501.84101501': __('identifierSchemeCodeUnspsc190501.84101501'),
	'identifierSchemeCodeUnspsc190501.84101502': __('identifierSchemeCodeUnspsc190501.84101502'),
	'identifierSchemeCodeUnspsc190501.84101503': __('identifierSchemeCodeUnspsc190501.84101503'),
	'identifierSchemeCodeUnspsc190501.84101600': __('identifierSchemeCodeUnspsc190501.84101600'),
	'identifierSchemeCodeUnspsc190501.84101601': __('identifierSchemeCodeUnspsc190501.84101601'),
	'identifierSchemeCodeUnspsc190501.84101602': __('identifierSchemeCodeUnspsc190501.84101602'),
	'identifierSchemeCodeUnspsc190501.84101603': __('identifierSchemeCodeUnspsc190501.84101603'),
	'identifierSchemeCodeUnspsc190501.84101604': __('identifierSchemeCodeUnspsc190501.84101604'),
	'identifierSchemeCodeUnspsc190501.84101700': __('identifierSchemeCodeUnspsc190501.84101700'),
	'identifierSchemeCodeUnspsc190501.84101701': __('identifierSchemeCodeUnspsc190501.84101701'),
	'identifierSchemeCodeUnspsc190501.84101702': __('identifierSchemeCodeUnspsc190501.84101702'),
	'identifierSchemeCodeUnspsc190501.84101703': __('identifierSchemeCodeUnspsc190501.84101703'),
	'identifierSchemeCodeUnspsc190501.84101704': __('identifierSchemeCodeUnspsc190501.84101704'),
	'identifierSchemeCodeUnspsc190501.84101705': __('identifierSchemeCodeUnspsc190501.84101705'),
	'identifierSchemeCodeUnspsc190501.84110000': __('identifierSchemeCodeUnspsc190501.84110000'),
	'identifierSchemeCodeUnspsc190501.84111500': __('identifierSchemeCodeUnspsc190501.84111500'),
	'identifierSchemeCodeUnspsc190501.84111501': __('identifierSchemeCodeUnspsc190501.84111501'),
	'identifierSchemeCodeUnspsc190501.84111502': __('identifierSchemeCodeUnspsc190501.84111502'),
	'identifierSchemeCodeUnspsc190501.84111503': __('identifierSchemeCodeUnspsc190501.84111503'),
	'identifierSchemeCodeUnspsc190501.84111504': __('identifierSchemeCodeUnspsc190501.84111504'),
	'identifierSchemeCodeUnspsc190501.84111505': __('identifierSchemeCodeUnspsc190501.84111505'),
	'identifierSchemeCodeUnspsc190501.84111506': __('identifierSchemeCodeUnspsc190501.84111506'),
	'identifierSchemeCodeUnspsc190501.84111507': __('identifierSchemeCodeUnspsc190501.84111507'),
	'identifierSchemeCodeUnspsc190501.84111508': __('identifierSchemeCodeUnspsc190501.84111508'),
	'identifierSchemeCodeUnspsc190501.84111600': __('identifierSchemeCodeUnspsc190501.84111600'),
	'identifierSchemeCodeUnspsc190501.84111601': __('identifierSchemeCodeUnspsc190501.84111601'),
	'identifierSchemeCodeUnspsc190501.84111602': __('identifierSchemeCodeUnspsc190501.84111602'),
	'identifierSchemeCodeUnspsc190501.84111603': __('identifierSchemeCodeUnspsc190501.84111603'),
	'identifierSchemeCodeUnspsc190501.84111700': __('identifierSchemeCodeUnspsc190501.84111700'),
	'identifierSchemeCodeUnspsc190501.84111701': __('identifierSchemeCodeUnspsc190501.84111701'),
	'identifierSchemeCodeUnspsc190501.84111702': __('identifierSchemeCodeUnspsc190501.84111702'),
	'identifierSchemeCodeUnspsc190501.84111703': __('identifierSchemeCodeUnspsc190501.84111703'),
	'identifierSchemeCodeUnspsc190501.84111800': __('identifierSchemeCodeUnspsc190501.84111800'),
	'identifierSchemeCodeUnspsc190501.84111801': __('identifierSchemeCodeUnspsc190501.84111801'),
	'identifierSchemeCodeUnspsc190501.84111802': __('identifierSchemeCodeUnspsc190501.84111802'),
	'identifierSchemeCodeUnspsc190501.84120000': __('identifierSchemeCodeUnspsc190501.84120000'),
	'identifierSchemeCodeUnspsc190501.84121500': __('identifierSchemeCodeUnspsc190501.84121500'),
	'identifierSchemeCodeUnspsc190501.84121501': __('identifierSchemeCodeUnspsc190501.84121501'),
	'identifierSchemeCodeUnspsc190501.84121502': __('identifierSchemeCodeUnspsc190501.84121502'),
	'identifierSchemeCodeUnspsc190501.84121503': __('identifierSchemeCodeUnspsc190501.84121503'),
	'identifierSchemeCodeUnspsc190501.84121504': __('identifierSchemeCodeUnspsc190501.84121504'),
	'identifierSchemeCodeUnspsc190501.84121600': __('identifierSchemeCodeUnspsc190501.84121600'),
	'identifierSchemeCodeUnspsc190501.84121601': __('identifierSchemeCodeUnspsc190501.84121601'),
	'identifierSchemeCodeUnspsc190501.84121602': __('identifierSchemeCodeUnspsc190501.84121602'),
	'identifierSchemeCodeUnspsc190501.84121603': __('identifierSchemeCodeUnspsc190501.84121603'),
	'identifierSchemeCodeUnspsc190501.84121604': __('identifierSchemeCodeUnspsc190501.84121604'),
	'identifierSchemeCodeUnspsc190501.84121605': __('identifierSchemeCodeUnspsc190501.84121605'),
	'identifierSchemeCodeUnspsc190501.84121606': __('identifierSchemeCodeUnspsc190501.84121606'),
	'identifierSchemeCodeUnspsc190501.84121607': __('identifierSchemeCodeUnspsc190501.84121607'),
	'identifierSchemeCodeUnspsc190501.84121700': __('identifierSchemeCodeUnspsc190501.84121700'),
	'identifierSchemeCodeUnspsc190501.84121701': __('identifierSchemeCodeUnspsc190501.84121701'),
	'identifierSchemeCodeUnspsc190501.84121702': __('identifierSchemeCodeUnspsc190501.84121702'),
	'identifierSchemeCodeUnspsc190501.84121703': __('identifierSchemeCodeUnspsc190501.84121703'),
	'identifierSchemeCodeUnspsc190501.84121704': __('identifierSchemeCodeUnspsc190501.84121704'),
	'identifierSchemeCodeUnspsc190501.84121705': __('identifierSchemeCodeUnspsc190501.84121705'),
	'identifierSchemeCodeUnspsc190501.84121706': __('identifierSchemeCodeUnspsc190501.84121706'),
	'identifierSchemeCodeUnspsc190501.84121800': __('identifierSchemeCodeUnspsc190501.84121800'),
	'identifierSchemeCodeUnspsc190501.84121801': __('identifierSchemeCodeUnspsc190501.84121801'),
	'identifierSchemeCodeUnspsc190501.84121802': __('identifierSchemeCodeUnspsc190501.84121802'),
	'identifierSchemeCodeUnspsc190501.84121803': __('identifierSchemeCodeUnspsc190501.84121803'),
	'identifierSchemeCodeUnspsc190501.84121804': __('identifierSchemeCodeUnspsc190501.84121804'),
	'identifierSchemeCodeUnspsc190501.84121805': __('identifierSchemeCodeUnspsc190501.84121805'),
	'identifierSchemeCodeUnspsc190501.84121806': __('identifierSchemeCodeUnspsc190501.84121806'),
	'identifierSchemeCodeUnspsc190501.84121900': __('identifierSchemeCodeUnspsc190501.84121900'),
	'identifierSchemeCodeUnspsc190501.84121901': __('identifierSchemeCodeUnspsc190501.84121901'),
	'identifierSchemeCodeUnspsc190501.84121902': __('identifierSchemeCodeUnspsc190501.84121902'),
	'identifierSchemeCodeUnspsc190501.84121903': __('identifierSchemeCodeUnspsc190501.84121903'),
	'identifierSchemeCodeUnspsc190501.84122000': __('identifierSchemeCodeUnspsc190501.84122000'),
	'identifierSchemeCodeUnspsc190501.84122001': __('identifierSchemeCodeUnspsc190501.84122001'),
	'identifierSchemeCodeUnspsc190501.84130000': __('identifierSchemeCodeUnspsc190501.84130000'),
	'identifierSchemeCodeUnspsc190501.84131500': __('identifierSchemeCodeUnspsc190501.84131500'),
	'identifierSchemeCodeUnspsc190501.84131501': __('identifierSchemeCodeUnspsc190501.84131501'),
	'identifierSchemeCodeUnspsc190501.84131502': __('identifierSchemeCodeUnspsc190501.84131502'),
	'identifierSchemeCodeUnspsc190501.84131503': __('identifierSchemeCodeUnspsc190501.84131503'),
	'identifierSchemeCodeUnspsc190501.84131504': __('identifierSchemeCodeUnspsc190501.84131504'),
	'identifierSchemeCodeUnspsc190501.84131505': __('identifierSchemeCodeUnspsc190501.84131505'),
	'identifierSchemeCodeUnspsc190501.84131506': __('identifierSchemeCodeUnspsc190501.84131506'),
	'identifierSchemeCodeUnspsc190501.84131507': __('identifierSchemeCodeUnspsc190501.84131507'),
	'identifierSchemeCodeUnspsc190501.84131508': __('identifierSchemeCodeUnspsc190501.84131508'),
	'identifierSchemeCodeUnspsc190501.84131509': __('identifierSchemeCodeUnspsc190501.84131509'),
	'identifierSchemeCodeUnspsc190501.84131510': __('identifierSchemeCodeUnspsc190501.84131510'),
	'identifierSchemeCodeUnspsc190501.84131511': __('identifierSchemeCodeUnspsc190501.84131511'),
	'identifierSchemeCodeUnspsc190501.84131512': __('identifierSchemeCodeUnspsc190501.84131512'),
	'identifierSchemeCodeUnspsc190501.84131513': __('identifierSchemeCodeUnspsc190501.84131513'),
	'identifierSchemeCodeUnspsc190501.84131514': __('identifierSchemeCodeUnspsc190501.84131514'),
	'identifierSchemeCodeUnspsc190501.84131515': __('identifierSchemeCodeUnspsc190501.84131515'),
	'identifierSchemeCodeUnspsc190501.84131516': __('identifierSchemeCodeUnspsc190501.84131516'),
	'identifierSchemeCodeUnspsc190501.84131517': __('identifierSchemeCodeUnspsc190501.84131517'),
	'identifierSchemeCodeUnspsc190501.84131518': __('identifierSchemeCodeUnspsc190501.84131518'),
	'identifierSchemeCodeUnspsc190501.84131519': __('identifierSchemeCodeUnspsc190501.84131519'),
	'identifierSchemeCodeUnspsc190501.84131600': __('identifierSchemeCodeUnspsc190501.84131600'),
	'identifierSchemeCodeUnspsc190501.84131601': __('identifierSchemeCodeUnspsc190501.84131601'),
	'identifierSchemeCodeUnspsc190501.84131602': __('identifierSchemeCodeUnspsc190501.84131602'),
	'identifierSchemeCodeUnspsc190501.84131603': __('identifierSchemeCodeUnspsc190501.84131603'),
	'identifierSchemeCodeUnspsc190501.84131604': __('identifierSchemeCodeUnspsc190501.84131604'),
	'identifierSchemeCodeUnspsc190501.84131605': __('identifierSchemeCodeUnspsc190501.84131605'),
	'identifierSchemeCodeUnspsc190501.84131606': __('identifierSchemeCodeUnspsc190501.84131606'),
	'identifierSchemeCodeUnspsc190501.84131607': __('identifierSchemeCodeUnspsc190501.84131607'),
	'identifierSchemeCodeUnspsc190501.84131608': __('identifierSchemeCodeUnspsc190501.84131608'),
	'identifierSchemeCodeUnspsc190501.84131609': __('identifierSchemeCodeUnspsc190501.84131609'),
	'identifierSchemeCodeUnspsc190501.84131610': __('identifierSchemeCodeUnspsc190501.84131610'),
	'identifierSchemeCodeUnspsc190501.84131611': __('identifierSchemeCodeUnspsc190501.84131611'),
	'identifierSchemeCodeUnspsc190501.84131700': __('identifierSchemeCodeUnspsc190501.84131700'),
	'identifierSchemeCodeUnspsc190501.84131701': __('identifierSchemeCodeUnspsc190501.84131701'),
	'identifierSchemeCodeUnspsc190501.84131702': __('identifierSchemeCodeUnspsc190501.84131702'),
	'identifierSchemeCodeUnspsc190501.84131800': __('identifierSchemeCodeUnspsc190501.84131800'),
	'identifierSchemeCodeUnspsc190501.84131801': __('identifierSchemeCodeUnspsc190501.84131801'),
	'identifierSchemeCodeUnspsc190501.84131802': __('identifierSchemeCodeUnspsc190501.84131802'),
	'identifierSchemeCodeUnspsc190501.84140000': __('identifierSchemeCodeUnspsc190501.84140000'),
	'identifierSchemeCodeUnspsc190501.84141500': __('identifierSchemeCodeUnspsc190501.84141500'),
	'identifierSchemeCodeUnspsc190501.84141501': __('identifierSchemeCodeUnspsc190501.84141501'),
	'identifierSchemeCodeUnspsc190501.84141502': __('identifierSchemeCodeUnspsc190501.84141502'),
	'identifierSchemeCodeUnspsc190501.84141503': __('identifierSchemeCodeUnspsc190501.84141503'),
	'identifierSchemeCodeUnspsc190501.84141600': __('identifierSchemeCodeUnspsc190501.84141600'),
	'identifierSchemeCodeUnspsc190501.84141601': __('identifierSchemeCodeUnspsc190501.84141601'),
	'identifierSchemeCodeUnspsc190501.84141602': __('identifierSchemeCodeUnspsc190501.84141602'),
	'identifierSchemeCodeUnspsc190501.84141700': __('identifierSchemeCodeUnspsc190501.84141700'),
	'identifierSchemeCodeUnspsc190501.84141701': __('identifierSchemeCodeUnspsc190501.84141701'),
	'identifierSchemeCodeUnspsc190501.84141702': __('identifierSchemeCodeUnspsc190501.84141702'),
	'identifierSchemeCodeUnspsc190501.85000000': __('identifierSchemeCodeUnspsc190501.85000000'),
	'identifierSchemeCodeUnspsc190501.85100000': __('identifierSchemeCodeUnspsc190501.85100000'),
	'identifierSchemeCodeUnspsc190501.85101500': __('identifierSchemeCodeUnspsc190501.85101500'),
	'identifierSchemeCodeUnspsc190501.85101501': __('identifierSchemeCodeUnspsc190501.85101501'),
	'identifierSchemeCodeUnspsc190501.85101502': __('identifierSchemeCodeUnspsc190501.85101502'),
	'identifierSchemeCodeUnspsc190501.85101503': __('identifierSchemeCodeUnspsc190501.85101503'),
	'identifierSchemeCodeUnspsc190501.85101504': __('identifierSchemeCodeUnspsc190501.85101504'),
	'identifierSchemeCodeUnspsc190501.85101505': __('identifierSchemeCodeUnspsc190501.85101505'),
	'identifierSchemeCodeUnspsc190501.85101506': __('identifierSchemeCodeUnspsc190501.85101506'),
	'identifierSchemeCodeUnspsc190501.85101507': __('identifierSchemeCodeUnspsc190501.85101507'),
	'identifierSchemeCodeUnspsc190501.85101508': __('identifierSchemeCodeUnspsc190501.85101508'),
	'identifierSchemeCodeUnspsc190501.85101509': __('identifierSchemeCodeUnspsc190501.85101509'),
	'identifierSchemeCodeUnspsc190501.85101600': __('identifierSchemeCodeUnspsc190501.85101600'),
	'identifierSchemeCodeUnspsc190501.85101601': __('identifierSchemeCodeUnspsc190501.85101601'),
	'identifierSchemeCodeUnspsc190501.85101602': __('identifierSchemeCodeUnspsc190501.85101602'),
	'identifierSchemeCodeUnspsc190501.85101603': __('identifierSchemeCodeUnspsc190501.85101603'),
	'identifierSchemeCodeUnspsc190501.85101604': __('identifierSchemeCodeUnspsc190501.85101604'),
	'identifierSchemeCodeUnspsc190501.85101605': __('identifierSchemeCodeUnspsc190501.85101605'),
	'identifierSchemeCodeUnspsc190501.85101700': __('identifierSchemeCodeUnspsc190501.85101700'),
	'identifierSchemeCodeUnspsc190501.85101701': __('identifierSchemeCodeUnspsc190501.85101701'),
	'identifierSchemeCodeUnspsc190501.85101702': __('identifierSchemeCodeUnspsc190501.85101702'),
	'identifierSchemeCodeUnspsc190501.85101703': __('identifierSchemeCodeUnspsc190501.85101703'),
	'identifierSchemeCodeUnspsc190501.85101704': __('identifierSchemeCodeUnspsc190501.85101704'),
	'identifierSchemeCodeUnspsc190501.85101705': __('identifierSchemeCodeUnspsc190501.85101705'),
	'identifierSchemeCodeUnspsc190501.85101706': __('identifierSchemeCodeUnspsc190501.85101706'),
	'identifierSchemeCodeUnspsc190501.85101707': __('identifierSchemeCodeUnspsc190501.85101707'),
	'identifierSchemeCodeUnspsc190501.85110000': __('identifierSchemeCodeUnspsc190501.85110000'),
	'identifierSchemeCodeUnspsc190501.85111500': __('identifierSchemeCodeUnspsc190501.85111500'),
	'identifierSchemeCodeUnspsc190501.85111501': __('identifierSchemeCodeUnspsc190501.85111501'),
	'identifierSchemeCodeUnspsc190501.85111502': __('identifierSchemeCodeUnspsc190501.85111502'),
	'identifierSchemeCodeUnspsc190501.85111503': __('identifierSchemeCodeUnspsc190501.85111503'),
	'identifierSchemeCodeUnspsc190501.85111504': __('identifierSchemeCodeUnspsc190501.85111504'),
	'identifierSchemeCodeUnspsc190501.85111505': __('identifierSchemeCodeUnspsc190501.85111505'),
	'identifierSchemeCodeUnspsc190501.85111506': __('identifierSchemeCodeUnspsc190501.85111506'),
	'identifierSchemeCodeUnspsc190501.85111507': __('identifierSchemeCodeUnspsc190501.85111507'),
	'identifierSchemeCodeUnspsc190501.85111508': __('identifierSchemeCodeUnspsc190501.85111508'),
	'identifierSchemeCodeUnspsc190501.85111509': __('identifierSchemeCodeUnspsc190501.85111509'),
	'identifierSchemeCodeUnspsc190501.85111510': __('identifierSchemeCodeUnspsc190501.85111510'),
	'identifierSchemeCodeUnspsc190501.85111511': __('identifierSchemeCodeUnspsc190501.85111511'),
	'identifierSchemeCodeUnspsc190501.85111512': __('identifierSchemeCodeUnspsc190501.85111512'),
	'identifierSchemeCodeUnspsc190501.85111513': __('identifierSchemeCodeUnspsc190501.85111513'),
	'identifierSchemeCodeUnspsc190501.85111514': __('identifierSchemeCodeUnspsc190501.85111514'),
	'identifierSchemeCodeUnspsc190501.85111600': __('identifierSchemeCodeUnspsc190501.85111600'),
	'identifierSchemeCodeUnspsc190501.85111601': __('identifierSchemeCodeUnspsc190501.85111601'),
	'identifierSchemeCodeUnspsc190501.85111602': __('identifierSchemeCodeUnspsc190501.85111602'),
	'identifierSchemeCodeUnspsc190501.85111603': __('identifierSchemeCodeUnspsc190501.85111603'),
	'identifierSchemeCodeUnspsc190501.85111604': __('identifierSchemeCodeUnspsc190501.85111604'),
	'identifierSchemeCodeUnspsc190501.85111605': __('identifierSchemeCodeUnspsc190501.85111605'),
	'identifierSchemeCodeUnspsc190501.85111606': __('identifierSchemeCodeUnspsc190501.85111606'),
	'identifierSchemeCodeUnspsc190501.85111607': __('identifierSchemeCodeUnspsc190501.85111607'),
	'identifierSchemeCodeUnspsc190501.85111608': __('identifierSchemeCodeUnspsc190501.85111608'),
	'identifierSchemeCodeUnspsc190501.85111609': __('identifierSchemeCodeUnspsc190501.85111609'),
	'identifierSchemeCodeUnspsc190501.85111610': __('identifierSchemeCodeUnspsc190501.85111610'),
	'identifierSchemeCodeUnspsc190501.85111611': __('identifierSchemeCodeUnspsc190501.85111611'),
	'identifierSchemeCodeUnspsc190501.85111612': __('identifierSchemeCodeUnspsc190501.85111612'),
	'identifierSchemeCodeUnspsc190501.85111613': __('identifierSchemeCodeUnspsc190501.85111613'),
	'identifierSchemeCodeUnspsc190501.85111614': __('identifierSchemeCodeUnspsc190501.85111614'),
	'identifierSchemeCodeUnspsc190501.85111615': __('identifierSchemeCodeUnspsc190501.85111615'),
	'identifierSchemeCodeUnspsc190501.85111616': __('identifierSchemeCodeUnspsc190501.85111616'),
	'identifierSchemeCodeUnspsc190501.85111617': __('identifierSchemeCodeUnspsc190501.85111617'),
	'identifierSchemeCodeUnspsc190501.85111700': __('identifierSchemeCodeUnspsc190501.85111700'),
	'identifierSchemeCodeUnspsc190501.85111701': __('identifierSchemeCodeUnspsc190501.85111701'),
	'identifierSchemeCodeUnspsc190501.85111702': __('identifierSchemeCodeUnspsc190501.85111702'),
	'identifierSchemeCodeUnspsc190501.85111703': __('identifierSchemeCodeUnspsc190501.85111703'),
	'identifierSchemeCodeUnspsc190501.85111704': __('identifierSchemeCodeUnspsc190501.85111704'),
	'identifierSchemeCodeUnspsc190501.85120000': __('identifierSchemeCodeUnspsc190501.85120000'),
	'identifierSchemeCodeUnspsc190501.85121500': __('identifierSchemeCodeUnspsc190501.85121500'),
	'identifierSchemeCodeUnspsc190501.85121501': __('identifierSchemeCodeUnspsc190501.85121501'),
	'identifierSchemeCodeUnspsc190501.85121502': __('identifierSchemeCodeUnspsc190501.85121502'),
	'identifierSchemeCodeUnspsc190501.85121503': __('identifierSchemeCodeUnspsc190501.85121503'),
	'identifierSchemeCodeUnspsc190501.85121504': __('identifierSchemeCodeUnspsc190501.85121504'),
	'identifierSchemeCodeUnspsc190501.85121600': __('identifierSchemeCodeUnspsc190501.85121600'),
	'identifierSchemeCodeUnspsc190501.85121601': __('identifierSchemeCodeUnspsc190501.85121601'),
	'identifierSchemeCodeUnspsc190501.85121602': __('identifierSchemeCodeUnspsc190501.85121602'),
	'identifierSchemeCodeUnspsc190501.85121603': __('identifierSchemeCodeUnspsc190501.85121603'),
	'identifierSchemeCodeUnspsc190501.85121604': __('identifierSchemeCodeUnspsc190501.85121604'),
	'identifierSchemeCodeUnspsc190501.85121605': __('identifierSchemeCodeUnspsc190501.85121605'),
	'identifierSchemeCodeUnspsc190501.85121606': __('identifierSchemeCodeUnspsc190501.85121606'),
	'identifierSchemeCodeUnspsc190501.85121607': __('identifierSchemeCodeUnspsc190501.85121607'),
	'identifierSchemeCodeUnspsc190501.85121608': __('identifierSchemeCodeUnspsc190501.85121608'),
	'identifierSchemeCodeUnspsc190501.85121609': __('identifierSchemeCodeUnspsc190501.85121609'),
	'identifierSchemeCodeUnspsc190501.85121610': __('identifierSchemeCodeUnspsc190501.85121610'),
	'identifierSchemeCodeUnspsc190501.85121611': __('identifierSchemeCodeUnspsc190501.85121611'),
	'identifierSchemeCodeUnspsc190501.85121612': __('identifierSchemeCodeUnspsc190501.85121612'),
	'identifierSchemeCodeUnspsc190501.85121613': __('identifierSchemeCodeUnspsc190501.85121613'),
	'identifierSchemeCodeUnspsc190501.85121614': __('identifierSchemeCodeUnspsc190501.85121614'),
	'identifierSchemeCodeUnspsc190501.85121615': __('identifierSchemeCodeUnspsc190501.85121615'),
	'identifierSchemeCodeUnspsc190501.85121616': __('identifierSchemeCodeUnspsc190501.85121616'),
	'identifierSchemeCodeUnspsc190501.85121700': __('identifierSchemeCodeUnspsc190501.85121700'),
	'identifierSchemeCodeUnspsc190501.85121701': __('identifierSchemeCodeUnspsc190501.85121701'),
	'identifierSchemeCodeUnspsc190501.85121702': __('identifierSchemeCodeUnspsc190501.85121702'),
	'identifierSchemeCodeUnspsc190501.85121703': __('identifierSchemeCodeUnspsc190501.85121703'),
	'identifierSchemeCodeUnspsc190501.85121704': __('identifierSchemeCodeUnspsc190501.85121704'),
	'identifierSchemeCodeUnspsc190501.85121705': __('identifierSchemeCodeUnspsc190501.85121705'),
	'identifierSchemeCodeUnspsc190501.85121706': __('identifierSchemeCodeUnspsc190501.85121706'),
	'identifierSchemeCodeUnspsc190501.85121800': __('identifierSchemeCodeUnspsc190501.85121800'),
	'identifierSchemeCodeUnspsc190501.85121801': __('identifierSchemeCodeUnspsc190501.85121801'),
	'identifierSchemeCodeUnspsc190501.85121802': __('identifierSchemeCodeUnspsc190501.85121802'),
	'identifierSchemeCodeUnspsc190501.85121803': __('identifierSchemeCodeUnspsc190501.85121803'),
	'identifierSchemeCodeUnspsc190501.85121804': __('identifierSchemeCodeUnspsc190501.85121804'),
	'identifierSchemeCodeUnspsc190501.85121805': __('identifierSchemeCodeUnspsc190501.85121805'),
	'identifierSchemeCodeUnspsc190501.85121806': __('identifierSchemeCodeUnspsc190501.85121806'),
	'identifierSchemeCodeUnspsc190501.85121807': __('identifierSchemeCodeUnspsc190501.85121807'),
	'identifierSchemeCodeUnspsc190501.85121808': __('identifierSchemeCodeUnspsc190501.85121808'),
	'identifierSchemeCodeUnspsc190501.85121809': __('identifierSchemeCodeUnspsc190501.85121809'),
	'identifierSchemeCodeUnspsc190501.85121810': __('identifierSchemeCodeUnspsc190501.85121810'),
	'identifierSchemeCodeUnspsc190501.85121811': __('identifierSchemeCodeUnspsc190501.85121811'),
	'identifierSchemeCodeUnspsc190501.85121900': __('identifierSchemeCodeUnspsc190501.85121900'),
	'identifierSchemeCodeUnspsc190501.85121901': __('identifierSchemeCodeUnspsc190501.85121901'),
	'identifierSchemeCodeUnspsc190501.85121902': __('identifierSchemeCodeUnspsc190501.85121902'),
	'identifierSchemeCodeUnspsc190501.85122000': __('identifierSchemeCodeUnspsc190501.85122000'),
	'identifierSchemeCodeUnspsc190501.85122001': __('identifierSchemeCodeUnspsc190501.85122001'),
	'identifierSchemeCodeUnspsc190501.85122002': __('identifierSchemeCodeUnspsc190501.85122002'),
	'identifierSchemeCodeUnspsc190501.85122003': __('identifierSchemeCodeUnspsc190501.85122003'),
	'identifierSchemeCodeUnspsc190501.85122004': __('identifierSchemeCodeUnspsc190501.85122004'),
	'identifierSchemeCodeUnspsc190501.85122005': __('identifierSchemeCodeUnspsc190501.85122005'),
	'identifierSchemeCodeUnspsc190501.85122100': __('identifierSchemeCodeUnspsc190501.85122100'),
	'identifierSchemeCodeUnspsc190501.85122101': __('identifierSchemeCodeUnspsc190501.85122101'),
	'identifierSchemeCodeUnspsc190501.85122102': __('identifierSchemeCodeUnspsc190501.85122102'),
	'identifierSchemeCodeUnspsc190501.85122103': __('identifierSchemeCodeUnspsc190501.85122103'),
	'identifierSchemeCodeUnspsc190501.85122104': __('identifierSchemeCodeUnspsc190501.85122104'),
	'identifierSchemeCodeUnspsc190501.85122105': __('identifierSchemeCodeUnspsc190501.85122105'),
	'identifierSchemeCodeUnspsc190501.85122106': __('identifierSchemeCodeUnspsc190501.85122106'),
	'identifierSchemeCodeUnspsc190501.85122107': __('identifierSchemeCodeUnspsc190501.85122107'),
	'identifierSchemeCodeUnspsc190501.85122108': __('identifierSchemeCodeUnspsc190501.85122108'),
	'identifierSchemeCodeUnspsc190501.85122109': __('identifierSchemeCodeUnspsc190501.85122109'),
	'identifierSchemeCodeUnspsc190501.85122200': __('identifierSchemeCodeUnspsc190501.85122200'),
	'identifierSchemeCodeUnspsc190501.85122201': __('identifierSchemeCodeUnspsc190501.85122201'),
	'identifierSchemeCodeUnspsc190501.85130000': __('identifierSchemeCodeUnspsc190501.85130000'),
	'identifierSchemeCodeUnspsc190501.85131500': __('identifierSchemeCodeUnspsc190501.85131500'),
	'identifierSchemeCodeUnspsc190501.85131501': __('identifierSchemeCodeUnspsc190501.85131501'),
	'identifierSchemeCodeUnspsc190501.85131502': __('identifierSchemeCodeUnspsc190501.85131502'),
	'identifierSchemeCodeUnspsc190501.85131503': __('identifierSchemeCodeUnspsc190501.85131503'),
	'identifierSchemeCodeUnspsc190501.85131504': __('identifierSchemeCodeUnspsc190501.85131504'),
	'identifierSchemeCodeUnspsc190501.85131505': __('identifierSchemeCodeUnspsc190501.85131505'),
	'identifierSchemeCodeUnspsc190501.85131600': __('identifierSchemeCodeUnspsc190501.85131600'),
	'identifierSchemeCodeUnspsc190501.85131601': __('identifierSchemeCodeUnspsc190501.85131601'),
	'identifierSchemeCodeUnspsc190501.85131602': __('identifierSchemeCodeUnspsc190501.85131602'),
	'identifierSchemeCodeUnspsc190501.85131603': __('identifierSchemeCodeUnspsc190501.85131603'),
	'identifierSchemeCodeUnspsc190501.85131604': __('identifierSchemeCodeUnspsc190501.85131604'),
	'identifierSchemeCodeUnspsc190501.85131700': __('identifierSchemeCodeUnspsc190501.85131700'),
	'identifierSchemeCodeUnspsc190501.85131701': __('identifierSchemeCodeUnspsc190501.85131701'),
	'identifierSchemeCodeUnspsc190501.85131702': __('identifierSchemeCodeUnspsc190501.85131702'),
	'identifierSchemeCodeUnspsc190501.85131703': __('identifierSchemeCodeUnspsc190501.85131703'),
	'identifierSchemeCodeUnspsc190501.85131704': __('identifierSchemeCodeUnspsc190501.85131704'),
	'identifierSchemeCodeUnspsc190501.85131705': __('identifierSchemeCodeUnspsc190501.85131705'),
	'identifierSchemeCodeUnspsc190501.85131706': __('identifierSchemeCodeUnspsc190501.85131706'),
	'identifierSchemeCodeUnspsc190501.85131707': __('identifierSchemeCodeUnspsc190501.85131707'),
	'identifierSchemeCodeUnspsc190501.85131708': __('identifierSchemeCodeUnspsc190501.85131708'),
	'identifierSchemeCodeUnspsc190501.85131709': __('identifierSchemeCodeUnspsc190501.85131709'),
	'identifierSchemeCodeUnspsc190501.85131710': __('identifierSchemeCodeUnspsc190501.85131710'),
	'identifierSchemeCodeUnspsc190501.85131711': __('identifierSchemeCodeUnspsc190501.85131711'),
	'identifierSchemeCodeUnspsc190501.85131712': __('identifierSchemeCodeUnspsc190501.85131712'),
	'identifierSchemeCodeUnspsc190501.85131713': __('identifierSchemeCodeUnspsc190501.85131713'),
	'identifierSchemeCodeUnspsc190501.85140000': __('identifierSchemeCodeUnspsc190501.85140000'),
	'identifierSchemeCodeUnspsc190501.85141500': __('identifierSchemeCodeUnspsc190501.85141500'),
	'identifierSchemeCodeUnspsc190501.85141501': __('identifierSchemeCodeUnspsc190501.85141501'),
	'identifierSchemeCodeUnspsc190501.85141502': __('identifierSchemeCodeUnspsc190501.85141502'),
	'identifierSchemeCodeUnspsc190501.85141503': __('identifierSchemeCodeUnspsc190501.85141503'),
	'identifierSchemeCodeUnspsc190501.85141504': __('identifierSchemeCodeUnspsc190501.85141504'),
	'identifierSchemeCodeUnspsc190501.85141600': __('identifierSchemeCodeUnspsc190501.85141600'),
	'identifierSchemeCodeUnspsc190501.85141601': __('identifierSchemeCodeUnspsc190501.85141601'),
	'identifierSchemeCodeUnspsc190501.85141602': __('identifierSchemeCodeUnspsc190501.85141602'),
	'identifierSchemeCodeUnspsc190501.85141603': __('identifierSchemeCodeUnspsc190501.85141603'),
	'identifierSchemeCodeUnspsc190501.85141700': __('identifierSchemeCodeUnspsc190501.85141700'),
	'identifierSchemeCodeUnspsc190501.85141701': __('identifierSchemeCodeUnspsc190501.85141701'),
	'identifierSchemeCodeUnspsc190501.85141702': __('identifierSchemeCodeUnspsc190501.85141702'),
	'identifierSchemeCodeUnspsc190501.85150000': __('identifierSchemeCodeUnspsc190501.85150000'),
	'identifierSchemeCodeUnspsc190501.85151500': __('identifierSchemeCodeUnspsc190501.85151500'),
	'identifierSchemeCodeUnspsc190501.85151501': __('identifierSchemeCodeUnspsc190501.85151501'),
	'identifierSchemeCodeUnspsc190501.85151502': __('identifierSchemeCodeUnspsc190501.85151502'),
	'identifierSchemeCodeUnspsc190501.85151503': __('identifierSchemeCodeUnspsc190501.85151503'),
	'identifierSchemeCodeUnspsc190501.85151504': __('identifierSchemeCodeUnspsc190501.85151504'),
	'identifierSchemeCodeUnspsc190501.85151505': __('identifierSchemeCodeUnspsc190501.85151505'),
	'identifierSchemeCodeUnspsc190501.85151506': __('identifierSchemeCodeUnspsc190501.85151506'),
	'identifierSchemeCodeUnspsc190501.85151507': __('identifierSchemeCodeUnspsc190501.85151507'),
	'identifierSchemeCodeUnspsc190501.85151508': __('identifierSchemeCodeUnspsc190501.85151508'),
	'identifierSchemeCodeUnspsc190501.85151509': __('identifierSchemeCodeUnspsc190501.85151509'),
	'identifierSchemeCodeUnspsc190501.85151600': __('identifierSchemeCodeUnspsc190501.85151600'),
	'identifierSchemeCodeUnspsc190501.85151601': __('identifierSchemeCodeUnspsc190501.85151601'),
	'identifierSchemeCodeUnspsc190501.85151602': __('identifierSchemeCodeUnspsc190501.85151602'),
	'identifierSchemeCodeUnspsc190501.85151603': __('identifierSchemeCodeUnspsc190501.85151603'),
	'identifierSchemeCodeUnspsc190501.85151604': __('identifierSchemeCodeUnspsc190501.85151604'),
	'identifierSchemeCodeUnspsc190501.85151605': __('identifierSchemeCodeUnspsc190501.85151605'),
	'identifierSchemeCodeUnspsc190501.85151606': __('identifierSchemeCodeUnspsc190501.85151606'),
	'identifierSchemeCodeUnspsc190501.85151607': __('identifierSchemeCodeUnspsc190501.85151607'),
	'identifierSchemeCodeUnspsc190501.85151700': __('identifierSchemeCodeUnspsc190501.85151700'),
	'identifierSchemeCodeUnspsc190501.85151701': __('identifierSchemeCodeUnspsc190501.85151701'),
	'identifierSchemeCodeUnspsc190501.85151702': __('identifierSchemeCodeUnspsc190501.85151702'),
	'identifierSchemeCodeUnspsc190501.85151703': __('identifierSchemeCodeUnspsc190501.85151703'),
	'identifierSchemeCodeUnspsc190501.85151704': __('identifierSchemeCodeUnspsc190501.85151704'),
	'identifierSchemeCodeUnspsc190501.85151705': __('identifierSchemeCodeUnspsc190501.85151705'),
	'identifierSchemeCodeUnspsc190501.85160000': __('identifierSchemeCodeUnspsc190501.85160000'),
	'identifierSchemeCodeUnspsc190501.85161500': __('identifierSchemeCodeUnspsc190501.85161500'),
	'identifierSchemeCodeUnspsc190501.85161501': __('identifierSchemeCodeUnspsc190501.85161501'),
	'identifierSchemeCodeUnspsc190501.85161502': __('identifierSchemeCodeUnspsc190501.85161502'),
	'identifierSchemeCodeUnspsc190501.85161503': __('identifierSchemeCodeUnspsc190501.85161503'),
	'identifierSchemeCodeUnspsc190501.85161504': __('identifierSchemeCodeUnspsc190501.85161504'),
	'identifierSchemeCodeUnspsc190501.85161505': __('identifierSchemeCodeUnspsc190501.85161505'),
	'identifierSchemeCodeUnspsc190501.85170000': __('identifierSchemeCodeUnspsc190501.85170000'),
	'identifierSchemeCodeUnspsc190501.85171500': __('identifierSchemeCodeUnspsc190501.85171500'),
	'identifierSchemeCodeUnspsc190501.85171501': __('identifierSchemeCodeUnspsc190501.85171501'),
	'identifierSchemeCodeUnspsc190501.85171600': __('identifierSchemeCodeUnspsc190501.85171600'),
	'identifierSchemeCodeUnspsc190501.85171601': __('identifierSchemeCodeUnspsc190501.85171601'),
	'identifierSchemeCodeUnspsc190501.85171700': __('identifierSchemeCodeUnspsc190501.85171700'),
	'identifierSchemeCodeUnspsc190501.85171701': __('identifierSchemeCodeUnspsc190501.85171701'),
	'identifierSchemeCodeUnspsc190501.85210000': __('identifierSchemeCodeUnspsc190501.85210000'),
	'identifierSchemeCodeUnspsc190501.85211500': __('identifierSchemeCodeUnspsc190501.85211500'),
	'identifierSchemeCodeUnspsc190501.85211501': __('identifierSchemeCodeUnspsc190501.85211501'),
	'identifierSchemeCodeUnspsc190501.85211502': __('identifierSchemeCodeUnspsc190501.85211502'),
	'identifierSchemeCodeUnspsc190501.85211600': __('identifierSchemeCodeUnspsc190501.85211600'),
	'identifierSchemeCodeUnspsc190501.85211601': __('identifierSchemeCodeUnspsc190501.85211601'),
	'identifierSchemeCodeUnspsc190501.85211602': __('identifierSchemeCodeUnspsc190501.85211602'),
	'identifierSchemeCodeUnspsc190501.85211603': __('identifierSchemeCodeUnspsc190501.85211603'),
	'identifierSchemeCodeUnspsc190501.85211604': __('identifierSchemeCodeUnspsc190501.85211604'),
	'identifierSchemeCodeUnspsc190501.85211700': __('identifierSchemeCodeUnspsc190501.85211700'),
	'identifierSchemeCodeUnspsc190501.85211701': __('identifierSchemeCodeUnspsc190501.85211701'),
	'identifierSchemeCodeUnspsc190501.85211702': __('identifierSchemeCodeUnspsc190501.85211702'),
	'identifierSchemeCodeUnspsc190501.85211703': __('identifierSchemeCodeUnspsc190501.85211703'),
	'identifierSchemeCodeUnspsc190501.85211704': __('identifierSchemeCodeUnspsc190501.85211704'),
	'identifierSchemeCodeUnspsc190501.85211705': __('identifierSchemeCodeUnspsc190501.85211705'),
	'identifierSchemeCodeUnspsc190501.85211706': __('identifierSchemeCodeUnspsc190501.85211706'),
	'identifierSchemeCodeUnspsc190501.85211707': __('identifierSchemeCodeUnspsc190501.85211707'),
	'identifierSchemeCodeUnspsc190501.85211708': __('identifierSchemeCodeUnspsc190501.85211708'),
	'identifierSchemeCodeUnspsc190501.85211800': __('identifierSchemeCodeUnspsc190501.85211800'),
	'identifierSchemeCodeUnspsc190501.85211801': __('identifierSchemeCodeUnspsc190501.85211801'),
	'identifierSchemeCodeUnspsc190501.85211802': __('identifierSchemeCodeUnspsc190501.85211802'),
	'identifierSchemeCodeUnspsc190501.85211803': __('identifierSchemeCodeUnspsc190501.85211803'),
	'identifierSchemeCodeUnspsc190501.85211804': __('identifierSchemeCodeUnspsc190501.85211804'),
	'identifierSchemeCodeUnspsc190501.85211805': __('identifierSchemeCodeUnspsc190501.85211805'),
	'identifierSchemeCodeUnspsc190501.85211806': __('identifierSchemeCodeUnspsc190501.85211806'),
	'identifierSchemeCodeUnspsc190501.85211807': __('identifierSchemeCodeUnspsc190501.85211807'),
	'identifierSchemeCodeUnspsc190501.85211808': __('identifierSchemeCodeUnspsc190501.85211808'),
	'identifierSchemeCodeUnspsc190501.85211809': __('identifierSchemeCodeUnspsc190501.85211809'),
	'identifierSchemeCodeUnspsc190501.85211810': __('identifierSchemeCodeUnspsc190501.85211810'),
	'identifierSchemeCodeUnspsc190501.85211811': __('identifierSchemeCodeUnspsc190501.85211811'),
	'identifierSchemeCodeUnspsc190501.85211812': __('identifierSchemeCodeUnspsc190501.85211812'),
	'identifierSchemeCodeUnspsc190501.85211813': __('identifierSchemeCodeUnspsc190501.85211813'),
	'identifierSchemeCodeUnspsc190501.85211814': __('identifierSchemeCodeUnspsc190501.85211814'),
	'identifierSchemeCodeUnspsc190501.85211815': __('identifierSchemeCodeUnspsc190501.85211815'),
	'identifierSchemeCodeUnspsc190501.85211900': __('identifierSchemeCodeUnspsc190501.85211900'),
	'identifierSchemeCodeUnspsc190501.85211901': __('identifierSchemeCodeUnspsc190501.85211901'),
	'identifierSchemeCodeUnspsc190501.85211902': __('identifierSchemeCodeUnspsc190501.85211902'),
	'identifierSchemeCodeUnspsc190501.85211903': __('identifierSchemeCodeUnspsc190501.85211903'),
	'identifierSchemeCodeUnspsc190501.85211904': __('identifierSchemeCodeUnspsc190501.85211904'),
	'identifierSchemeCodeUnspsc190501.85211905': __('identifierSchemeCodeUnspsc190501.85211905'),
	'identifierSchemeCodeUnspsc190501.85212000': __('identifierSchemeCodeUnspsc190501.85212000'),
	'identifierSchemeCodeUnspsc190501.85212001': __('identifierSchemeCodeUnspsc190501.85212001'),
	'identifierSchemeCodeUnspsc190501.85212002': __('identifierSchemeCodeUnspsc190501.85212002'),
	'identifierSchemeCodeUnspsc190501.85212003': __('identifierSchemeCodeUnspsc190501.85212003'),
	'identifierSchemeCodeUnspsc190501.85212004': __('identifierSchemeCodeUnspsc190501.85212004'),
	'identifierSchemeCodeUnspsc190501.85212005': __('identifierSchemeCodeUnspsc190501.85212005'),
	'identifierSchemeCodeUnspsc190501.85212006': __('identifierSchemeCodeUnspsc190501.85212006'),
	'identifierSchemeCodeUnspsc190501.85212007': __('identifierSchemeCodeUnspsc190501.85212007'),
	'identifierSchemeCodeUnspsc190501.85212008': __('identifierSchemeCodeUnspsc190501.85212008'),
	'identifierSchemeCodeUnspsc190501.85212100': __('identifierSchemeCodeUnspsc190501.85212100'),
	'identifierSchemeCodeUnspsc190501.85212101': __('identifierSchemeCodeUnspsc190501.85212101'),
	'identifierSchemeCodeUnspsc190501.85212102': __('identifierSchemeCodeUnspsc190501.85212102'),
	'identifierSchemeCodeUnspsc190501.85212103': __('identifierSchemeCodeUnspsc190501.85212103'),
	'identifierSchemeCodeUnspsc190501.85212104': __('identifierSchemeCodeUnspsc190501.85212104'),
	'identifierSchemeCodeUnspsc190501.85212105': __('identifierSchemeCodeUnspsc190501.85212105'),
	'identifierSchemeCodeUnspsc190501.85212106': __('identifierSchemeCodeUnspsc190501.85212106'),
	'identifierSchemeCodeUnspsc190501.85212107': __('identifierSchemeCodeUnspsc190501.85212107'),
	'identifierSchemeCodeUnspsc190501.85212200': __('identifierSchemeCodeUnspsc190501.85212200'),
	'identifierSchemeCodeUnspsc190501.85212201': __('identifierSchemeCodeUnspsc190501.85212201'),
	'identifierSchemeCodeUnspsc190501.85212202': __('identifierSchemeCodeUnspsc190501.85212202'),
	'identifierSchemeCodeUnspsc190501.85212203': __('identifierSchemeCodeUnspsc190501.85212203'),
	'identifierSchemeCodeUnspsc190501.85212204': __('identifierSchemeCodeUnspsc190501.85212204'),
	'identifierSchemeCodeUnspsc190501.85212205': __('identifierSchemeCodeUnspsc190501.85212205'),
	'identifierSchemeCodeUnspsc190501.85212206': __('identifierSchemeCodeUnspsc190501.85212206'),
	'identifierSchemeCodeUnspsc190501.85212207': __('identifierSchemeCodeUnspsc190501.85212207'),
	'identifierSchemeCodeUnspsc190501.85212300': __('identifierSchemeCodeUnspsc190501.85212300'),
	'identifierSchemeCodeUnspsc190501.85212301': __('identifierSchemeCodeUnspsc190501.85212301'),
	'identifierSchemeCodeUnspsc190501.85212302': __('identifierSchemeCodeUnspsc190501.85212302'),
	'identifierSchemeCodeUnspsc190501.85212303': __('identifierSchemeCodeUnspsc190501.85212303'),
	'identifierSchemeCodeUnspsc190501.85212304': __('identifierSchemeCodeUnspsc190501.85212304'),
	'identifierSchemeCodeUnspsc190501.85212305': __('identifierSchemeCodeUnspsc190501.85212305'),
	'identifierSchemeCodeUnspsc190501.85212306': __('identifierSchemeCodeUnspsc190501.85212306'),
	'identifierSchemeCodeUnspsc190501.85212307': __('identifierSchemeCodeUnspsc190501.85212307'),
	'identifierSchemeCodeUnspsc190501.85212308': __('identifierSchemeCodeUnspsc190501.85212308'),
	'identifierSchemeCodeUnspsc190501.85212309': __('identifierSchemeCodeUnspsc190501.85212309'),
	'identifierSchemeCodeUnspsc190501.85212400': __('identifierSchemeCodeUnspsc190501.85212400'),
	'identifierSchemeCodeUnspsc190501.85212401': __('identifierSchemeCodeUnspsc190501.85212401'),
	'identifierSchemeCodeUnspsc190501.85212402': __('identifierSchemeCodeUnspsc190501.85212402'),
	'identifierSchemeCodeUnspsc190501.85212403': __('identifierSchemeCodeUnspsc190501.85212403'),
	'identifierSchemeCodeUnspsc190501.85212404': __('identifierSchemeCodeUnspsc190501.85212404'),
	'identifierSchemeCodeUnspsc190501.85212405': __('identifierSchemeCodeUnspsc190501.85212405'),
	'identifierSchemeCodeUnspsc190501.85212500': __('identifierSchemeCodeUnspsc190501.85212500'),
	'identifierSchemeCodeUnspsc190501.85212501': __('identifierSchemeCodeUnspsc190501.85212501'),
	'identifierSchemeCodeUnspsc190501.85212502': __('identifierSchemeCodeUnspsc190501.85212502'),
	'identifierSchemeCodeUnspsc190501.85212503': __('identifierSchemeCodeUnspsc190501.85212503'),
	'identifierSchemeCodeUnspsc190501.85212504': __('identifierSchemeCodeUnspsc190501.85212504'),
	'identifierSchemeCodeUnspsc190501.85212600': __('identifierSchemeCodeUnspsc190501.85212600'),
	'identifierSchemeCodeUnspsc190501.85212601': __('identifierSchemeCodeUnspsc190501.85212601'),
	'identifierSchemeCodeUnspsc190501.85212602': __('identifierSchemeCodeUnspsc190501.85212602'),
	'identifierSchemeCodeUnspsc190501.85212603': __('identifierSchemeCodeUnspsc190501.85212603'),
	'identifierSchemeCodeUnspsc190501.85212604': __('identifierSchemeCodeUnspsc190501.85212604'),
	'identifierSchemeCodeUnspsc190501.85212700': __('identifierSchemeCodeUnspsc190501.85212700'),
	'identifierSchemeCodeUnspsc190501.85212701': __('identifierSchemeCodeUnspsc190501.85212701'),
	'identifierSchemeCodeUnspsc190501.85212702': __('identifierSchemeCodeUnspsc190501.85212702'),
	'identifierSchemeCodeUnspsc190501.85212703': __('identifierSchemeCodeUnspsc190501.85212703'),
	'identifierSchemeCodeUnspsc190501.85212704': __('identifierSchemeCodeUnspsc190501.85212704'),
	'identifierSchemeCodeUnspsc190501.85212800': __('identifierSchemeCodeUnspsc190501.85212800'),
	'identifierSchemeCodeUnspsc190501.85212801': __('identifierSchemeCodeUnspsc190501.85212801'),
	'identifierSchemeCodeUnspsc190501.85212802': __('identifierSchemeCodeUnspsc190501.85212802'),
	'identifierSchemeCodeUnspsc190501.85212900': __('identifierSchemeCodeUnspsc190501.85212900'),
	'identifierSchemeCodeUnspsc190501.85212901': __('identifierSchemeCodeUnspsc190501.85212901'),
	'identifierSchemeCodeUnspsc190501.85212902': __('identifierSchemeCodeUnspsc190501.85212902'),
	'identifierSchemeCodeUnspsc190501.85213000': __('identifierSchemeCodeUnspsc190501.85213000'),
	'identifierSchemeCodeUnspsc190501.85213001': __('identifierSchemeCodeUnspsc190501.85213001'),
	'identifierSchemeCodeUnspsc190501.85213002': __('identifierSchemeCodeUnspsc190501.85213002'),
	'identifierSchemeCodeUnspsc190501.85213100': __('identifierSchemeCodeUnspsc190501.85213100'),
	'identifierSchemeCodeUnspsc190501.85213101': __('identifierSchemeCodeUnspsc190501.85213101'),
	'identifierSchemeCodeUnspsc190501.85213102': __('identifierSchemeCodeUnspsc190501.85213102'),
	'identifierSchemeCodeUnspsc190501.85213103': __('identifierSchemeCodeUnspsc190501.85213103'),
	'identifierSchemeCodeUnspsc190501.85213200': __('identifierSchemeCodeUnspsc190501.85213200'),
	'identifierSchemeCodeUnspsc190501.85213201': __('identifierSchemeCodeUnspsc190501.85213201'),
	'identifierSchemeCodeUnspsc190501.85213202': __('identifierSchemeCodeUnspsc190501.85213202'),
	'identifierSchemeCodeUnspsc190501.85213203': __('identifierSchemeCodeUnspsc190501.85213203'),
	'identifierSchemeCodeUnspsc190501.85213204': __('identifierSchemeCodeUnspsc190501.85213204'),
	'identifierSchemeCodeUnspsc190501.85213205': __('identifierSchemeCodeUnspsc190501.85213205'),
	'identifierSchemeCodeUnspsc190501.85213206': __('identifierSchemeCodeUnspsc190501.85213206'),
	'identifierSchemeCodeUnspsc190501.85213300': __('identifierSchemeCodeUnspsc190501.85213300'),
	'identifierSchemeCodeUnspsc190501.85213301': __('identifierSchemeCodeUnspsc190501.85213301'),
	'identifierSchemeCodeUnspsc190501.85213302': __('identifierSchemeCodeUnspsc190501.85213302'),
	'identifierSchemeCodeUnspsc190501.85213303': __('identifierSchemeCodeUnspsc190501.85213303'),
	'identifierSchemeCodeUnspsc190501.85213400': __('identifierSchemeCodeUnspsc190501.85213400'),
	'identifierSchemeCodeUnspsc190501.85213401': __('identifierSchemeCodeUnspsc190501.85213401'),
	'identifierSchemeCodeUnspsc190501.85213402': __('identifierSchemeCodeUnspsc190501.85213402'),
	'identifierSchemeCodeUnspsc190501.85213500': __('identifierSchemeCodeUnspsc190501.85213500'),
	'identifierSchemeCodeUnspsc190501.85213501': __('identifierSchemeCodeUnspsc190501.85213501'),
	'identifierSchemeCodeUnspsc190501.85213502': __('identifierSchemeCodeUnspsc190501.85213502'),
	'identifierSchemeCodeUnspsc190501.85213600': __('identifierSchemeCodeUnspsc190501.85213600'),
	'identifierSchemeCodeUnspsc190501.85213601': __('identifierSchemeCodeUnspsc190501.85213601'),
	'identifierSchemeCodeUnspsc190501.85213602': __('identifierSchemeCodeUnspsc190501.85213602'),
	'identifierSchemeCodeUnspsc190501.85213700': __('identifierSchemeCodeUnspsc190501.85213700'),
	'identifierSchemeCodeUnspsc190501.85213701': __('identifierSchemeCodeUnspsc190501.85213701'),
	'identifierSchemeCodeUnspsc190501.85213702': __('identifierSchemeCodeUnspsc190501.85213702'),
	'identifierSchemeCodeUnspsc190501.85213703': __('identifierSchemeCodeUnspsc190501.85213703'),
	'identifierSchemeCodeUnspsc190501.85213800': __('identifierSchemeCodeUnspsc190501.85213800'),
	'identifierSchemeCodeUnspsc190501.85213801': __('identifierSchemeCodeUnspsc190501.85213801'),
	'identifierSchemeCodeUnspsc190501.85213802': __('identifierSchemeCodeUnspsc190501.85213802'),
	'identifierSchemeCodeUnspsc190501.85213803': __('identifierSchemeCodeUnspsc190501.85213803'),
	'identifierSchemeCodeUnspsc190501.85213804': __('identifierSchemeCodeUnspsc190501.85213804'),
	'identifierSchemeCodeUnspsc190501.85213805': __('identifierSchemeCodeUnspsc190501.85213805'),
	'identifierSchemeCodeUnspsc190501.85213900': __('identifierSchemeCodeUnspsc190501.85213900'),
	'identifierSchemeCodeUnspsc190501.85213901': __('identifierSchemeCodeUnspsc190501.85213901'),
	'identifierSchemeCodeUnspsc190501.85213902': __('identifierSchemeCodeUnspsc190501.85213902'),
	'identifierSchemeCodeUnspsc190501.85213903': __('identifierSchemeCodeUnspsc190501.85213903'),
	'identifierSchemeCodeUnspsc190501.85213904': __('identifierSchemeCodeUnspsc190501.85213904'),
	'identifierSchemeCodeUnspsc190501.85214000': __('identifierSchemeCodeUnspsc190501.85214000'),
	'identifierSchemeCodeUnspsc190501.85214001': __('identifierSchemeCodeUnspsc190501.85214001'),
	'identifierSchemeCodeUnspsc190501.85214002': __('identifierSchemeCodeUnspsc190501.85214002'),
	'identifierSchemeCodeUnspsc190501.85214003': __('identifierSchemeCodeUnspsc190501.85214003'),
	'identifierSchemeCodeUnspsc190501.85214100': __('identifierSchemeCodeUnspsc190501.85214100'),
	'identifierSchemeCodeUnspsc190501.85214101': __('identifierSchemeCodeUnspsc190501.85214101'),
	'identifierSchemeCodeUnspsc190501.85214102': __('identifierSchemeCodeUnspsc190501.85214102'),
	'identifierSchemeCodeUnspsc190501.85214103': __('identifierSchemeCodeUnspsc190501.85214103'),
	'identifierSchemeCodeUnspsc190501.85214104': __('identifierSchemeCodeUnspsc190501.85214104'),
	'identifierSchemeCodeUnspsc190501.85214200': __('identifierSchemeCodeUnspsc190501.85214200'),
	'identifierSchemeCodeUnspsc190501.85214201': __('identifierSchemeCodeUnspsc190501.85214201'),
	'identifierSchemeCodeUnspsc190501.85214202': __('identifierSchemeCodeUnspsc190501.85214202'),
	'identifierSchemeCodeUnspsc190501.85214300': __('identifierSchemeCodeUnspsc190501.85214300'),
	'identifierSchemeCodeUnspsc190501.85214301': __('identifierSchemeCodeUnspsc190501.85214301'),
	'identifierSchemeCodeUnspsc190501.85214302': __('identifierSchemeCodeUnspsc190501.85214302'),
	'identifierSchemeCodeUnspsc190501.85214400': __('identifierSchemeCodeUnspsc190501.85214400'),
	'identifierSchemeCodeUnspsc190501.85214401': __('identifierSchemeCodeUnspsc190501.85214401'),
	'identifierSchemeCodeUnspsc190501.85214402': __('identifierSchemeCodeUnspsc190501.85214402'),
	'identifierSchemeCodeUnspsc190501.85214403': __('identifierSchemeCodeUnspsc190501.85214403'),
	'identifierSchemeCodeUnspsc190501.85214404': __('identifierSchemeCodeUnspsc190501.85214404'),
	'identifierSchemeCodeUnspsc190501.85214405': __('identifierSchemeCodeUnspsc190501.85214405'),
	'identifierSchemeCodeUnspsc190501.85214406': __('identifierSchemeCodeUnspsc190501.85214406'),
	'identifierSchemeCodeUnspsc190501.85214500': __('identifierSchemeCodeUnspsc190501.85214500'),
	'identifierSchemeCodeUnspsc190501.85214501': __('identifierSchemeCodeUnspsc190501.85214501'),
	'identifierSchemeCodeUnspsc190501.85214502': __('identifierSchemeCodeUnspsc190501.85214502'),
	'identifierSchemeCodeUnspsc190501.85214503': __('identifierSchemeCodeUnspsc190501.85214503'),
	'identifierSchemeCodeUnspsc190501.85214504': __('identifierSchemeCodeUnspsc190501.85214504'),
	'identifierSchemeCodeUnspsc190501.85214505': __('identifierSchemeCodeUnspsc190501.85214505'),
	'identifierSchemeCodeUnspsc190501.85214600': __('identifierSchemeCodeUnspsc190501.85214600'),
	'identifierSchemeCodeUnspsc190501.85214601': __('identifierSchemeCodeUnspsc190501.85214601'),
	'identifierSchemeCodeUnspsc190501.85214602': __('identifierSchemeCodeUnspsc190501.85214602'),
	'identifierSchemeCodeUnspsc190501.85214603': __('identifierSchemeCodeUnspsc190501.85214603'),
	'identifierSchemeCodeUnspsc190501.85214604': __('identifierSchemeCodeUnspsc190501.85214604'),
	'identifierSchemeCodeUnspsc190501.85214700': __('identifierSchemeCodeUnspsc190501.85214700'),
	'identifierSchemeCodeUnspsc190501.85214701': __('identifierSchemeCodeUnspsc190501.85214701'),
	'identifierSchemeCodeUnspsc190501.85214702': __('identifierSchemeCodeUnspsc190501.85214702'),
	'identifierSchemeCodeUnspsc190501.85214703': __('identifierSchemeCodeUnspsc190501.85214703'),
	'identifierSchemeCodeUnspsc190501.85214704': __('identifierSchemeCodeUnspsc190501.85214704'),
	'identifierSchemeCodeUnspsc190501.85214800': __('identifierSchemeCodeUnspsc190501.85214800'),
	'identifierSchemeCodeUnspsc190501.85214801': __('identifierSchemeCodeUnspsc190501.85214801'),
	'identifierSchemeCodeUnspsc190501.85214802': __('identifierSchemeCodeUnspsc190501.85214802'),
	'identifierSchemeCodeUnspsc190501.85214803': __('identifierSchemeCodeUnspsc190501.85214803'),
	'identifierSchemeCodeUnspsc190501.85214804': __('identifierSchemeCodeUnspsc190501.85214804'),
	'identifierSchemeCodeUnspsc190501.85214805': __('identifierSchemeCodeUnspsc190501.85214805'),
	'identifierSchemeCodeUnspsc190501.85214806': __('identifierSchemeCodeUnspsc190501.85214806'),
	'identifierSchemeCodeUnspsc190501.85214900': __('identifierSchemeCodeUnspsc190501.85214900'),
	'identifierSchemeCodeUnspsc190501.85214901': __('identifierSchemeCodeUnspsc190501.85214901'),
	'identifierSchemeCodeUnspsc190501.85214902': __('identifierSchemeCodeUnspsc190501.85214902'),
	'identifierSchemeCodeUnspsc190501.85214903': __('identifierSchemeCodeUnspsc190501.85214903'),
	'identifierSchemeCodeUnspsc190501.85215000': __('identifierSchemeCodeUnspsc190501.85215000'),
	'identifierSchemeCodeUnspsc190501.85215001': __('identifierSchemeCodeUnspsc190501.85215001'),
	'identifierSchemeCodeUnspsc190501.85215002': __('identifierSchemeCodeUnspsc190501.85215002'),
	'identifierSchemeCodeUnspsc190501.85215003': __('identifierSchemeCodeUnspsc190501.85215003'),
	'identifierSchemeCodeUnspsc190501.85215100': __('identifierSchemeCodeUnspsc190501.85215100'),
	'identifierSchemeCodeUnspsc190501.85215101': __('identifierSchemeCodeUnspsc190501.85215101'),
	'identifierSchemeCodeUnspsc190501.85215102': __('identifierSchemeCodeUnspsc190501.85215102'),
	'identifierSchemeCodeUnspsc190501.85215300': __('identifierSchemeCodeUnspsc190501.85215300'),
	'identifierSchemeCodeUnspsc190501.85215301': __('identifierSchemeCodeUnspsc190501.85215301'),
	'identifierSchemeCodeUnspsc190501.85215302': __('identifierSchemeCodeUnspsc190501.85215302'),
	'identifierSchemeCodeUnspsc190501.85215303': __('identifierSchemeCodeUnspsc190501.85215303'),
	'identifierSchemeCodeUnspsc190501.85215304': __('identifierSchemeCodeUnspsc190501.85215304'),
	'identifierSchemeCodeUnspsc190501.85215305': __('identifierSchemeCodeUnspsc190501.85215305'),
	'identifierSchemeCodeUnspsc190501.85215306': __('identifierSchemeCodeUnspsc190501.85215306'),
	'identifierSchemeCodeUnspsc190501.85215307': __('identifierSchemeCodeUnspsc190501.85215307'),
	'identifierSchemeCodeUnspsc190501.85215400': __('identifierSchemeCodeUnspsc190501.85215400'),
	'identifierSchemeCodeUnspsc190501.85215401': __('identifierSchemeCodeUnspsc190501.85215401'),
	'identifierSchemeCodeUnspsc190501.85215402': __('identifierSchemeCodeUnspsc190501.85215402'),
	'identifierSchemeCodeUnspsc190501.85215403': __('identifierSchemeCodeUnspsc190501.85215403'),
	'identifierSchemeCodeUnspsc190501.85215404': __('identifierSchemeCodeUnspsc190501.85215404'),
	'identifierSchemeCodeUnspsc190501.85215500': __('identifierSchemeCodeUnspsc190501.85215500'),
	'identifierSchemeCodeUnspsc190501.85215501': __('identifierSchemeCodeUnspsc190501.85215501'),
	'identifierSchemeCodeUnspsc190501.85215502': __('identifierSchemeCodeUnspsc190501.85215502'),
	'identifierSchemeCodeUnspsc190501.85215600': __('identifierSchemeCodeUnspsc190501.85215600'),
	'identifierSchemeCodeUnspsc190501.85215601': __('identifierSchemeCodeUnspsc190501.85215601'),
	'identifierSchemeCodeUnspsc190501.85215602': __('identifierSchemeCodeUnspsc190501.85215602'),
	'identifierSchemeCodeUnspsc190501.85215700': __('identifierSchemeCodeUnspsc190501.85215700'),
	'identifierSchemeCodeUnspsc190501.85215701': __('identifierSchemeCodeUnspsc190501.85215701'),
	'identifierSchemeCodeUnspsc190501.85215702': __('identifierSchemeCodeUnspsc190501.85215702'),
	'identifierSchemeCodeUnspsc190501.85215800': __('identifierSchemeCodeUnspsc190501.85215800'),
	'identifierSchemeCodeUnspsc190501.85215801': __('identifierSchemeCodeUnspsc190501.85215801'),
	'identifierSchemeCodeUnspsc190501.85215802': __('identifierSchemeCodeUnspsc190501.85215802'),
	'identifierSchemeCodeUnspsc190501.85215803': __('identifierSchemeCodeUnspsc190501.85215803'),
	'identifierSchemeCodeUnspsc190501.85215804': __('identifierSchemeCodeUnspsc190501.85215804'),
	'identifierSchemeCodeUnspsc190501.85215900': __('identifierSchemeCodeUnspsc190501.85215900'),
	'identifierSchemeCodeUnspsc190501.85215901': __('identifierSchemeCodeUnspsc190501.85215901'),
	'identifierSchemeCodeUnspsc190501.85215902': __('identifierSchemeCodeUnspsc190501.85215902'),
	'identifierSchemeCodeUnspsc190501.85215903': __('identifierSchemeCodeUnspsc190501.85215903'),
	'identifierSchemeCodeUnspsc190501.85215904': __('identifierSchemeCodeUnspsc190501.85215904'),
	'identifierSchemeCodeUnspsc190501.85215905': __('identifierSchemeCodeUnspsc190501.85215905'),
	'identifierSchemeCodeUnspsc190501.85215906': __('identifierSchemeCodeUnspsc190501.85215906'),
	'identifierSchemeCodeUnspsc190501.85216000': __('identifierSchemeCodeUnspsc190501.85216000'),
	'identifierSchemeCodeUnspsc190501.85216001': __('identifierSchemeCodeUnspsc190501.85216001'),
	'identifierSchemeCodeUnspsc190501.85216002': __('identifierSchemeCodeUnspsc190501.85216002'),
	'identifierSchemeCodeUnspsc190501.85216003': __('identifierSchemeCodeUnspsc190501.85216003'),
	'identifierSchemeCodeUnspsc190501.85216004': __('identifierSchemeCodeUnspsc190501.85216004'),
	'identifierSchemeCodeUnspsc190501.85216100': __('identifierSchemeCodeUnspsc190501.85216100'),
	'identifierSchemeCodeUnspsc190501.85216101': __('identifierSchemeCodeUnspsc190501.85216101'),
	'identifierSchemeCodeUnspsc190501.85216102': __('identifierSchemeCodeUnspsc190501.85216102'),
	'identifierSchemeCodeUnspsc190501.85216103': __('identifierSchemeCodeUnspsc190501.85216103'),
	'identifierSchemeCodeUnspsc190501.85216200': __('identifierSchemeCodeUnspsc190501.85216200'),
	'identifierSchemeCodeUnspsc190501.85216201': __('identifierSchemeCodeUnspsc190501.85216201'),
	'identifierSchemeCodeUnspsc190501.85216202': __('identifierSchemeCodeUnspsc190501.85216202'),
	'identifierSchemeCodeUnspsc190501.85216300': __('identifierSchemeCodeUnspsc190501.85216300'),
	'identifierSchemeCodeUnspsc190501.85216301': __('identifierSchemeCodeUnspsc190501.85216301'),
	'identifierSchemeCodeUnspsc190501.85216302': __('identifierSchemeCodeUnspsc190501.85216302'),
	'identifierSchemeCodeUnspsc190501.85216303': __('identifierSchemeCodeUnspsc190501.85216303'),
	'identifierSchemeCodeUnspsc190501.85216304': __('identifierSchemeCodeUnspsc190501.85216304'),
	'identifierSchemeCodeUnspsc190501.85216305': __('identifierSchemeCodeUnspsc190501.85216305'),
	'identifierSchemeCodeUnspsc190501.85216306': __('identifierSchemeCodeUnspsc190501.85216306'),
	'identifierSchemeCodeUnspsc190501.85216307': __('identifierSchemeCodeUnspsc190501.85216307'),
	'identifierSchemeCodeUnspsc190501.85216308': __('identifierSchemeCodeUnspsc190501.85216308'),
	'identifierSchemeCodeUnspsc190501.85216309': __('identifierSchemeCodeUnspsc190501.85216309'),
	'identifierSchemeCodeUnspsc190501.85216310': __('identifierSchemeCodeUnspsc190501.85216310'),
	'identifierSchemeCodeUnspsc190501.85216311': __('identifierSchemeCodeUnspsc190501.85216311'),
	'identifierSchemeCodeUnspsc190501.85216400': __('identifierSchemeCodeUnspsc190501.85216400'),
	'identifierSchemeCodeUnspsc190501.85216401': __('identifierSchemeCodeUnspsc190501.85216401'),
	'identifierSchemeCodeUnspsc190501.85216402': __('identifierSchemeCodeUnspsc190501.85216402'),
	'identifierSchemeCodeUnspsc190501.85216403': __('identifierSchemeCodeUnspsc190501.85216403'),
	'identifierSchemeCodeUnspsc190501.85216500': __('identifierSchemeCodeUnspsc190501.85216500'),
	'identifierSchemeCodeUnspsc190501.85216501': __('identifierSchemeCodeUnspsc190501.85216501'),
	'identifierSchemeCodeUnspsc190501.85216502': __('identifierSchemeCodeUnspsc190501.85216502'),
	'identifierSchemeCodeUnspsc190501.85216600': __('identifierSchemeCodeUnspsc190501.85216600'),
	'identifierSchemeCodeUnspsc190501.85216601': __('identifierSchemeCodeUnspsc190501.85216601'),
	'identifierSchemeCodeUnspsc190501.85216602': __('identifierSchemeCodeUnspsc190501.85216602'),
	'identifierSchemeCodeUnspsc190501.85216603': __('identifierSchemeCodeUnspsc190501.85216603'),
	'identifierSchemeCodeUnspsc190501.85216604': __('identifierSchemeCodeUnspsc190501.85216604'),
	'identifierSchemeCodeUnspsc190501.85216605': __('identifierSchemeCodeUnspsc190501.85216605'),
	'identifierSchemeCodeUnspsc190501.85216606': __('identifierSchemeCodeUnspsc190501.85216606'),
	'identifierSchemeCodeUnspsc190501.85216607': __('identifierSchemeCodeUnspsc190501.85216607'),
	'identifierSchemeCodeUnspsc190501.85216608': __('identifierSchemeCodeUnspsc190501.85216608'),
	'identifierSchemeCodeUnspsc190501.85216609': __('identifierSchemeCodeUnspsc190501.85216609'),
	'identifierSchemeCodeUnspsc190501.85216610': __('identifierSchemeCodeUnspsc190501.85216610'),
	'identifierSchemeCodeUnspsc190501.85216611': __('identifierSchemeCodeUnspsc190501.85216611'),
	'identifierSchemeCodeUnspsc190501.85216612': __('identifierSchemeCodeUnspsc190501.85216612'),
	'identifierSchemeCodeUnspsc190501.85216613': __('identifierSchemeCodeUnspsc190501.85216613'),
	'identifierSchemeCodeUnspsc190501.85216614': __('identifierSchemeCodeUnspsc190501.85216614'),
	'identifierSchemeCodeUnspsc190501.85216615': __('identifierSchemeCodeUnspsc190501.85216615'),
	'identifierSchemeCodeUnspsc190501.85216616': __('identifierSchemeCodeUnspsc190501.85216616'),
	'identifierSchemeCodeUnspsc190501.85216617': __('identifierSchemeCodeUnspsc190501.85216617'),
	'identifierSchemeCodeUnspsc190501.85216618': __('identifierSchemeCodeUnspsc190501.85216618'),
	'identifierSchemeCodeUnspsc190501.85216619': __('identifierSchemeCodeUnspsc190501.85216619'),
	'identifierSchemeCodeUnspsc190501.85216620': __('identifierSchemeCodeUnspsc190501.85216620'),
	'identifierSchemeCodeUnspsc190501.85216621': __('identifierSchemeCodeUnspsc190501.85216621'),
	'identifierSchemeCodeUnspsc190501.85216622': __('identifierSchemeCodeUnspsc190501.85216622'),
	'identifierSchemeCodeUnspsc190501.85216623': __('identifierSchemeCodeUnspsc190501.85216623'),
	'identifierSchemeCodeUnspsc190501.85216624': __('identifierSchemeCodeUnspsc190501.85216624'),
	'identifierSchemeCodeUnspsc190501.85216700': __('identifierSchemeCodeUnspsc190501.85216700'),
	'identifierSchemeCodeUnspsc190501.85216701': __('identifierSchemeCodeUnspsc190501.85216701'),
	'identifierSchemeCodeUnspsc190501.85216702': __('identifierSchemeCodeUnspsc190501.85216702'),
	'identifierSchemeCodeUnspsc190501.85216703': __('identifierSchemeCodeUnspsc190501.85216703'),
	'identifierSchemeCodeUnspsc190501.85216704': __('identifierSchemeCodeUnspsc190501.85216704'),
	'identifierSchemeCodeUnspsc190501.85216705': __('identifierSchemeCodeUnspsc190501.85216705'),
	'identifierSchemeCodeUnspsc190501.85216706': __('identifierSchemeCodeUnspsc190501.85216706'),
	'identifierSchemeCodeUnspsc190501.85216707': __('identifierSchemeCodeUnspsc190501.85216707'),
	'identifierSchemeCodeUnspsc190501.85216708': __('identifierSchemeCodeUnspsc190501.85216708'),
	'identifierSchemeCodeUnspsc190501.85216709': __('identifierSchemeCodeUnspsc190501.85216709'),
	'identifierSchemeCodeUnspsc190501.85216710': __('identifierSchemeCodeUnspsc190501.85216710'),
	'identifierSchemeCodeUnspsc190501.85216711': __('identifierSchemeCodeUnspsc190501.85216711'),
	'identifierSchemeCodeUnspsc190501.85216712': __('identifierSchemeCodeUnspsc190501.85216712'),
	'identifierSchemeCodeUnspsc190501.85216800': __('identifierSchemeCodeUnspsc190501.85216800'),
	'identifierSchemeCodeUnspsc190501.85216801': __('identifierSchemeCodeUnspsc190501.85216801'),
	'identifierSchemeCodeUnspsc190501.85216802': __('identifierSchemeCodeUnspsc190501.85216802'),
	'identifierSchemeCodeUnspsc190501.85216803': __('identifierSchemeCodeUnspsc190501.85216803'),
	'identifierSchemeCodeUnspsc190501.85216804': __('identifierSchemeCodeUnspsc190501.85216804'),
	'identifierSchemeCodeUnspsc190501.85216805': __('identifierSchemeCodeUnspsc190501.85216805'),
	'identifierSchemeCodeUnspsc190501.85216806': __('identifierSchemeCodeUnspsc190501.85216806'),
	'identifierSchemeCodeUnspsc190501.85216807': __('identifierSchemeCodeUnspsc190501.85216807'),
	'identifierSchemeCodeUnspsc190501.85216808': __('identifierSchemeCodeUnspsc190501.85216808'),
	'identifierSchemeCodeUnspsc190501.85216809': __('identifierSchemeCodeUnspsc190501.85216809'),
	'identifierSchemeCodeUnspsc190501.85216810': __('identifierSchemeCodeUnspsc190501.85216810'),
	'identifierSchemeCodeUnspsc190501.85216811': __('identifierSchemeCodeUnspsc190501.85216811'),
	'identifierSchemeCodeUnspsc190501.85216812': __('identifierSchemeCodeUnspsc190501.85216812'),
	'identifierSchemeCodeUnspsc190501.85216813': __('identifierSchemeCodeUnspsc190501.85216813'),
	'identifierSchemeCodeUnspsc190501.85216814': __('identifierSchemeCodeUnspsc190501.85216814'),
	'identifierSchemeCodeUnspsc190501.85216900': __('identifierSchemeCodeUnspsc190501.85216900'),
	'identifierSchemeCodeUnspsc190501.85216901': __('identifierSchemeCodeUnspsc190501.85216901'),
	'identifierSchemeCodeUnspsc190501.85216902': __('identifierSchemeCodeUnspsc190501.85216902'),
	'identifierSchemeCodeUnspsc190501.85216903': __('identifierSchemeCodeUnspsc190501.85216903'),
	'identifierSchemeCodeUnspsc190501.85216904': __('identifierSchemeCodeUnspsc190501.85216904'),
	'identifierSchemeCodeUnspsc190501.85216905': __('identifierSchemeCodeUnspsc190501.85216905'),
	'identifierSchemeCodeUnspsc190501.85217000': __('identifierSchemeCodeUnspsc190501.85217000'),
	'identifierSchemeCodeUnspsc190501.85217001': __('identifierSchemeCodeUnspsc190501.85217001'),
	'identifierSchemeCodeUnspsc190501.85217002': __('identifierSchemeCodeUnspsc190501.85217002'),
	'identifierSchemeCodeUnspsc190501.85217003': __('identifierSchemeCodeUnspsc190501.85217003'),
	'identifierSchemeCodeUnspsc190501.85217004': __('identifierSchemeCodeUnspsc190501.85217004'),
	'identifierSchemeCodeUnspsc190501.85217005': __('identifierSchemeCodeUnspsc190501.85217005'),
	'identifierSchemeCodeUnspsc190501.85217006': __('identifierSchemeCodeUnspsc190501.85217006'),
	'identifierSchemeCodeUnspsc190501.85217007': __('identifierSchemeCodeUnspsc190501.85217007'),
	'identifierSchemeCodeUnspsc190501.85217008': __('identifierSchemeCodeUnspsc190501.85217008'),
	'identifierSchemeCodeUnspsc190501.85217009': __('identifierSchemeCodeUnspsc190501.85217009'),
	'identifierSchemeCodeUnspsc190501.85217010': __('identifierSchemeCodeUnspsc190501.85217010'),
	'identifierSchemeCodeUnspsc190501.85217011': __('identifierSchemeCodeUnspsc190501.85217011'),
	'identifierSchemeCodeUnspsc190501.85217012': __('identifierSchemeCodeUnspsc190501.85217012'),
	'identifierSchemeCodeUnspsc190501.85217100': __('identifierSchemeCodeUnspsc190501.85217100'),
	'identifierSchemeCodeUnspsc190501.85217101': __('identifierSchemeCodeUnspsc190501.85217101'),
	'identifierSchemeCodeUnspsc190501.85217102': __('identifierSchemeCodeUnspsc190501.85217102'),
	'identifierSchemeCodeUnspsc190501.85217103': __('identifierSchemeCodeUnspsc190501.85217103'),
	'identifierSchemeCodeUnspsc190501.85217104': __('identifierSchemeCodeUnspsc190501.85217104'),
	'identifierSchemeCodeUnspsc190501.85217105': __('identifierSchemeCodeUnspsc190501.85217105'),
	'identifierSchemeCodeUnspsc190501.85217106': __('identifierSchemeCodeUnspsc190501.85217106'),
	'identifierSchemeCodeUnspsc190501.85217107': __('identifierSchemeCodeUnspsc190501.85217107'),
	'identifierSchemeCodeUnspsc190501.85217108': __('identifierSchemeCodeUnspsc190501.85217108'),
	'identifierSchemeCodeUnspsc190501.85217109': __('identifierSchemeCodeUnspsc190501.85217109'),
	'identifierSchemeCodeUnspsc190501.85217110': __('identifierSchemeCodeUnspsc190501.85217110'),
	'identifierSchemeCodeUnspsc190501.85217111': __('identifierSchemeCodeUnspsc190501.85217111'),
	'identifierSchemeCodeUnspsc190501.85217112': __('identifierSchemeCodeUnspsc190501.85217112'),
	'identifierSchemeCodeUnspsc190501.85217113': __('identifierSchemeCodeUnspsc190501.85217113'),
	'identifierSchemeCodeUnspsc190501.85217114': __('identifierSchemeCodeUnspsc190501.85217114'),
	'identifierSchemeCodeUnspsc190501.85217115': __('identifierSchemeCodeUnspsc190501.85217115'),
	'identifierSchemeCodeUnspsc190501.85217116': __('identifierSchemeCodeUnspsc190501.85217116'),
	'identifierSchemeCodeUnspsc190501.85217200': __('identifierSchemeCodeUnspsc190501.85217200'),
	'identifierSchemeCodeUnspsc190501.85217201': __('identifierSchemeCodeUnspsc190501.85217201'),
	'identifierSchemeCodeUnspsc190501.85217202': __('identifierSchemeCodeUnspsc190501.85217202'),
	'identifierSchemeCodeUnspsc190501.85217203': __('identifierSchemeCodeUnspsc190501.85217203'),
	'identifierSchemeCodeUnspsc190501.85217300': __('identifierSchemeCodeUnspsc190501.85217300'),
	'identifierSchemeCodeUnspsc190501.85217301': __('identifierSchemeCodeUnspsc190501.85217301'),
	'identifierSchemeCodeUnspsc190501.85217302': __('identifierSchemeCodeUnspsc190501.85217302'),
	'identifierSchemeCodeUnspsc190501.85217303': __('identifierSchemeCodeUnspsc190501.85217303'),
	'identifierSchemeCodeUnspsc190501.85217304': __('identifierSchemeCodeUnspsc190501.85217304'),
	'identifierSchemeCodeUnspsc190501.85217305': __('identifierSchemeCodeUnspsc190501.85217305'),
	'identifierSchemeCodeUnspsc190501.85217400': __('identifierSchemeCodeUnspsc190501.85217400'),
	'identifierSchemeCodeUnspsc190501.85217401': __('identifierSchemeCodeUnspsc190501.85217401'),
	'identifierSchemeCodeUnspsc190501.85217402': __('identifierSchemeCodeUnspsc190501.85217402'),
	'identifierSchemeCodeUnspsc190501.85217500': __('identifierSchemeCodeUnspsc190501.85217500'),
	'identifierSchemeCodeUnspsc190501.85217501': __('identifierSchemeCodeUnspsc190501.85217501'),
	'identifierSchemeCodeUnspsc190501.85217502': __('identifierSchemeCodeUnspsc190501.85217502'),
	'identifierSchemeCodeUnspsc190501.85217503': __('identifierSchemeCodeUnspsc190501.85217503'),
	'identifierSchemeCodeUnspsc190501.85217504': __('identifierSchemeCodeUnspsc190501.85217504'),
	'identifierSchemeCodeUnspsc190501.85217600': __('identifierSchemeCodeUnspsc190501.85217600'),
	'identifierSchemeCodeUnspsc190501.85217601': __('identifierSchemeCodeUnspsc190501.85217601'),
	'identifierSchemeCodeUnspsc190501.85217602': __('identifierSchemeCodeUnspsc190501.85217602'),
	'identifierSchemeCodeUnspsc190501.85217603': __('identifierSchemeCodeUnspsc190501.85217603'),
	'identifierSchemeCodeUnspsc190501.85217604': __('identifierSchemeCodeUnspsc190501.85217604'),
	'identifierSchemeCodeUnspsc190501.85217605': __('identifierSchemeCodeUnspsc190501.85217605'),
	'identifierSchemeCodeUnspsc190501.85220000': __('identifierSchemeCodeUnspsc190501.85220000'),
	'identifierSchemeCodeUnspsc190501.85221500': __('identifierSchemeCodeUnspsc190501.85221500'),
	'identifierSchemeCodeUnspsc190501.85221501': __('identifierSchemeCodeUnspsc190501.85221501'),
	'identifierSchemeCodeUnspsc190501.85221502': __('identifierSchemeCodeUnspsc190501.85221502'),
	'identifierSchemeCodeUnspsc190501.85221503': __('identifierSchemeCodeUnspsc190501.85221503'),
	'identifierSchemeCodeUnspsc190501.85221504': __('identifierSchemeCodeUnspsc190501.85221504'),
	'identifierSchemeCodeUnspsc190501.85221505': __('identifierSchemeCodeUnspsc190501.85221505'),
	'identifierSchemeCodeUnspsc190501.85221506': __('identifierSchemeCodeUnspsc190501.85221506'),
	'identifierSchemeCodeUnspsc190501.85221507': __('identifierSchemeCodeUnspsc190501.85221507'),
	'identifierSchemeCodeUnspsc190501.85221600': __('identifierSchemeCodeUnspsc190501.85221600'),
	'identifierSchemeCodeUnspsc190501.85221601': __('identifierSchemeCodeUnspsc190501.85221601'),
	'identifierSchemeCodeUnspsc190501.85221602': __('identifierSchemeCodeUnspsc190501.85221602'),
	'identifierSchemeCodeUnspsc190501.85221603': __('identifierSchemeCodeUnspsc190501.85221603'),
	'identifierSchemeCodeUnspsc190501.85221604': __('identifierSchemeCodeUnspsc190501.85221604'),
	'identifierSchemeCodeUnspsc190501.85221700': __('identifierSchemeCodeUnspsc190501.85221700'),
	'identifierSchemeCodeUnspsc190501.85221701': __('identifierSchemeCodeUnspsc190501.85221701'),
	'identifierSchemeCodeUnspsc190501.85221702': __('identifierSchemeCodeUnspsc190501.85221702'),
	'identifierSchemeCodeUnspsc190501.85221703': __('identifierSchemeCodeUnspsc190501.85221703'),
	'identifierSchemeCodeUnspsc190501.85221704': __('identifierSchemeCodeUnspsc190501.85221704'),
	'identifierSchemeCodeUnspsc190501.85221705': __('identifierSchemeCodeUnspsc190501.85221705'),
	'identifierSchemeCodeUnspsc190501.85221706': __('identifierSchemeCodeUnspsc190501.85221706'),
	'identifierSchemeCodeUnspsc190501.85221707': __('identifierSchemeCodeUnspsc190501.85221707'),
	'identifierSchemeCodeUnspsc190501.85221800': __('identifierSchemeCodeUnspsc190501.85221800'),
	'identifierSchemeCodeUnspsc190501.85221801': __('identifierSchemeCodeUnspsc190501.85221801'),
	'identifierSchemeCodeUnspsc190501.85221802': __('identifierSchemeCodeUnspsc190501.85221802'),
	'identifierSchemeCodeUnspsc190501.85221803': __('identifierSchemeCodeUnspsc190501.85221803'),
	'identifierSchemeCodeUnspsc190501.85221804': __('identifierSchemeCodeUnspsc190501.85221804'),
	'identifierSchemeCodeUnspsc190501.85221805': __('identifierSchemeCodeUnspsc190501.85221805'),
	'identifierSchemeCodeUnspsc190501.85221900': __('identifierSchemeCodeUnspsc190501.85221900'),
	'identifierSchemeCodeUnspsc190501.85221901': __('identifierSchemeCodeUnspsc190501.85221901'),
	'identifierSchemeCodeUnspsc190501.85221902': __('identifierSchemeCodeUnspsc190501.85221902'),
	'identifierSchemeCodeUnspsc190501.85221903': __('identifierSchemeCodeUnspsc190501.85221903'),
	'identifierSchemeCodeUnspsc190501.85221904': __('identifierSchemeCodeUnspsc190501.85221904'),
	'identifierSchemeCodeUnspsc190501.85222000': __('identifierSchemeCodeUnspsc190501.85222000'),
	'identifierSchemeCodeUnspsc190501.85222001': __('identifierSchemeCodeUnspsc190501.85222001'),
	'identifierSchemeCodeUnspsc190501.85222002': __('identifierSchemeCodeUnspsc190501.85222002'),
	'identifierSchemeCodeUnspsc190501.85222003': __('identifierSchemeCodeUnspsc190501.85222003'),
	'identifierSchemeCodeUnspsc190501.85222004': __('identifierSchemeCodeUnspsc190501.85222004'),
	'identifierSchemeCodeUnspsc190501.85222100': __('identifierSchemeCodeUnspsc190501.85222100'),
	'identifierSchemeCodeUnspsc190501.85222101': __('identifierSchemeCodeUnspsc190501.85222101'),
	'identifierSchemeCodeUnspsc190501.85222102': __('identifierSchemeCodeUnspsc190501.85222102'),
	'identifierSchemeCodeUnspsc190501.85222200': __('identifierSchemeCodeUnspsc190501.85222200'),
	'identifierSchemeCodeUnspsc190501.85222201': __('identifierSchemeCodeUnspsc190501.85222201'),
	'identifierSchemeCodeUnspsc190501.85222202': __('identifierSchemeCodeUnspsc190501.85222202'),
	'identifierSchemeCodeUnspsc190501.85222203': __('identifierSchemeCodeUnspsc190501.85222203'),
	'identifierSchemeCodeUnspsc190501.85222300': __('identifierSchemeCodeUnspsc190501.85222300'),
	'identifierSchemeCodeUnspsc190501.85222301': __('identifierSchemeCodeUnspsc190501.85222301'),
	'identifierSchemeCodeUnspsc190501.85222302': __('identifierSchemeCodeUnspsc190501.85222302'),
	'identifierSchemeCodeUnspsc190501.85222303': __('identifierSchemeCodeUnspsc190501.85222303'),
	'identifierSchemeCodeUnspsc190501.85222400': __('identifierSchemeCodeUnspsc190501.85222400'),
	'identifierSchemeCodeUnspsc190501.85222401': __('identifierSchemeCodeUnspsc190501.85222401'),
	'identifierSchemeCodeUnspsc190501.85222402': __('identifierSchemeCodeUnspsc190501.85222402'),
	'identifierSchemeCodeUnspsc190501.85222403': __('identifierSchemeCodeUnspsc190501.85222403'),
	'identifierSchemeCodeUnspsc190501.85222500': __('identifierSchemeCodeUnspsc190501.85222500'),
	'identifierSchemeCodeUnspsc190501.85222501': __('identifierSchemeCodeUnspsc190501.85222501'),
	'identifierSchemeCodeUnspsc190501.85222502': __('identifierSchemeCodeUnspsc190501.85222502'),
	'identifierSchemeCodeUnspsc190501.85222503': __('identifierSchemeCodeUnspsc190501.85222503'),
	'identifierSchemeCodeUnspsc190501.85222504': __('identifierSchemeCodeUnspsc190501.85222504'),
	'identifierSchemeCodeUnspsc190501.85222505': __('identifierSchemeCodeUnspsc190501.85222505'),
	'identifierSchemeCodeUnspsc190501.85222600': __('identifierSchemeCodeUnspsc190501.85222600'),
	'identifierSchemeCodeUnspsc190501.85222601': __('identifierSchemeCodeUnspsc190501.85222601'),
	'identifierSchemeCodeUnspsc190501.85222602': __('identifierSchemeCodeUnspsc190501.85222602'),
	'identifierSchemeCodeUnspsc190501.85222603': __('identifierSchemeCodeUnspsc190501.85222603'),
	'identifierSchemeCodeUnspsc190501.85222604': __('identifierSchemeCodeUnspsc190501.85222604'),
	'identifierSchemeCodeUnspsc190501.85222605': __('identifierSchemeCodeUnspsc190501.85222605'),
	'identifierSchemeCodeUnspsc190501.85222700': __('identifierSchemeCodeUnspsc190501.85222700'),
	'identifierSchemeCodeUnspsc190501.85222701': __('identifierSchemeCodeUnspsc190501.85222701'),
	'identifierSchemeCodeUnspsc190501.85222702': __('identifierSchemeCodeUnspsc190501.85222702'),
	'identifierSchemeCodeUnspsc190501.85222800': __('identifierSchemeCodeUnspsc190501.85222800'),
	'identifierSchemeCodeUnspsc190501.85222801': __('identifierSchemeCodeUnspsc190501.85222801'),
	'identifierSchemeCodeUnspsc190501.85222802': __('identifierSchemeCodeUnspsc190501.85222802'),
	'identifierSchemeCodeUnspsc190501.85222803': __('identifierSchemeCodeUnspsc190501.85222803'),
	'identifierSchemeCodeUnspsc190501.85222804': __('identifierSchemeCodeUnspsc190501.85222804'),
	'identifierSchemeCodeUnspsc190501.85222805': __('identifierSchemeCodeUnspsc190501.85222805'),
	'identifierSchemeCodeUnspsc190501.85222806': __('identifierSchemeCodeUnspsc190501.85222806'),
	'identifierSchemeCodeUnspsc190501.85222900': __('identifierSchemeCodeUnspsc190501.85222900'),
	'identifierSchemeCodeUnspsc190501.85222901': __('identifierSchemeCodeUnspsc190501.85222901'),
	'identifierSchemeCodeUnspsc190501.85222902': __('identifierSchemeCodeUnspsc190501.85222902'),
	'identifierSchemeCodeUnspsc190501.85222903': __('identifierSchemeCodeUnspsc190501.85222903'),
	'identifierSchemeCodeUnspsc190501.85222904': __('identifierSchemeCodeUnspsc190501.85222904'),
	'identifierSchemeCodeUnspsc190501.85222905': __('identifierSchemeCodeUnspsc190501.85222905'),
	'identifierSchemeCodeUnspsc190501.85223000': __('identifierSchemeCodeUnspsc190501.85223000'),
	'identifierSchemeCodeUnspsc190501.85223001': __('identifierSchemeCodeUnspsc190501.85223001'),
	'identifierSchemeCodeUnspsc190501.85223002': __('identifierSchemeCodeUnspsc190501.85223002'),
	'identifierSchemeCodeUnspsc190501.85223100': __('identifierSchemeCodeUnspsc190501.85223100'),
	'identifierSchemeCodeUnspsc190501.85223101': __('identifierSchemeCodeUnspsc190501.85223101'),
	'identifierSchemeCodeUnspsc190501.85223102': __('identifierSchemeCodeUnspsc190501.85223102'),
	'identifierSchemeCodeUnspsc190501.85223103': __('identifierSchemeCodeUnspsc190501.85223103'),
	'identifierSchemeCodeUnspsc190501.85223104': __('identifierSchemeCodeUnspsc190501.85223104'),
	'identifierSchemeCodeUnspsc190501.85223105': __('identifierSchemeCodeUnspsc190501.85223105'),
	'identifierSchemeCodeUnspsc190501.85223200': __('identifierSchemeCodeUnspsc190501.85223200'),
	'identifierSchemeCodeUnspsc190501.85223201': __('identifierSchemeCodeUnspsc190501.85223201'),
	'identifierSchemeCodeUnspsc190501.85223202': __('identifierSchemeCodeUnspsc190501.85223202'),
	'identifierSchemeCodeUnspsc190501.85223203': __('identifierSchemeCodeUnspsc190501.85223203'),
	'identifierSchemeCodeUnspsc190501.85223204': __('identifierSchemeCodeUnspsc190501.85223204'),
	'identifierSchemeCodeUnspsc190501.85223205': __('identifierSchemeCodeUnspsc190501.85223205'),
	'identifierSchemeCodeUnspsc190501.85223300': __('identifierSchemeCodeUnspsc190501.85223300'),
	'identifierSchemeCodeUnspsc190501.85223301': __('identifierSchemeCodeUnspsc190501.85223301'),
	'identifierSchemeCodeUnspsc190501.85223302': __('identifierSchemeCodeUnspsc190501.85223302'),
	'identifierSchemeCodeUnspsc190501.85223303': __('identifierSchemeCodeUnspsc190501.85223303'),
	'identifierSchemeCodeUnspsc190501.85223304': __('identifierSchemeCodeUnspsc190501.85223304'),
	'identifierSchemeCodeUnspsc190501.85223305': __('identifierSchemeCodeUnspsc190501.85223305'),
	'identifierSchemeCodeUnspsc190501.85223306': __('identifierSchemeCodeUnspsc190501.85223306'),
	'identifierSchemeCodeUnspsc190501.85223307': __('identifierSchemeCodeUnspsc190501.85223307'),
	'identifierSchemeCodeUnspsc190501.85223308': __('identifierSchemeCodeUnspsc190501.85223308'),
	'identifierSchemeCodeUnspsc190501.85223400': __('identifierSchemeCodeUnspsc190501.85223400'),
	'identifierSchemeCodeUnspsc190501.85223401': __('identifierSchemeCodeUnspsc190501.85223401'),
	'identifierSchemeCodeUnspsc190501.85223402': __('identifierSchemeCodeUnspsc190501.85223402'),
	'identifierSchemeCodeUnspsc190501.85223403': __('identifierSchemeCodeUnspsc190501.85223403'),
	'identifierSchemeCodeUnspsc190501.85223404': __('identifierSchemeCodeUnspsc190501.85223404'),
	'identifierSchemeCodeUnspsc190501.85223405': __('identifierSchemeCodeUnspsc190501.85223405'),
	'identifierSchemeCodeUnspsc190501.85223406': __('identifierSchemeCodeUnspsc190501.85223406'),
	'identifierSchemeCodeUnspsc190501.85223407': __('identifierSchemeCodeUnspsc190501.85223407'),
	'identifierSchemeCodeUnspsc190501.85223408': __('identifierSchemeCodeUnspsc190501.85223408'),
	'identifierSchemeCodeUnspsc190501.85223409': __('identifierSchemeCodeUnspsc190501.85223409'),
	'identifierSchemeCodeUnspsc190501.85223500': __('identifierSchemeCodeUnspsc190501.85223500'),
	'identifierSchemeCodeUnspsc190501.85223501': __('identifierSchemeCodeUnspsc190501.85223501'),
	'identifierSchemeCodeUnspsc190501.85223502': __('identifierSchemeCodeUnspsc190501.85223502'),
	'identifierSchemeCodeUnspsc190501.85223503': __('identifierSchemeCodeUnspsc190501.85223503'),
	'identifierSchemeCodeUnspsc190501.85223504': __('identifierSchemeCodeUnspsc190501.85223504'),
	'identifierSchemeCodeUnspsc190501.85223600': __('identifierSchemeCodeUnspsc190501.85223600'),
	'identifierSchemeCodeUnspsc190501.85223601': __('identifierSchemeCodeUnspsc190501.85223601'),
	'identifierSchemeCodeUnspsc190501.85223602': __('identifierSchemeCodeUnspsc190501.85223602'),
	'identifierSchemeCodeUnspsc190501.85223603': __('identifierSchemeCodeUnspsc190501.85223603'),
	'identifierSchemeCodeUnspsc190501.85223604': __('identifierSchemeCodeUnspsc190501.85223604'),
	'identifierSchemeCodeUnspsc190501.85223605': __('identifierSchemeCodeUnspsc190501.85223605'),
	'identifierSchemeCodeUnspsc190501.85223606': __('identifierSchemeCodeUnspsc190501.85223606'),
	'identifierSchemeCodeUnspsc190501.85223700': __('identifierSchemeCodeUnspsc190501.85223700'),
	'identifierSchemeCodeUnspsc190501.85223701': __('identifierSchemeCodeUnspsc190501.85223701'),
	'identifierSchemeCodeUnspsc190501.85223702': __('identifierSchemeCodeUnspsc190501.85223702'),
	'identifierSchemeCodeUnspsc190501.85223800': __('identifierSchemeCodeUnspsc190501.85223800'),
	'identifierSchemeCodeUnspsc190501.85223801': __('identifierSchemeCodeUnspsc190501.85223801'),
	'identifierSchemeCodeUnspsc190501.85223802': __('identifierSchemeCodeUnspsc190501.85223802'),
	'identifierSchemeCodeUnspsc190501.85223803': __('identifierSchemeCodeUnspsc190501.85223803'),
	'identifierSchemeCodeUnspsc190501.85223900': __('identifierSchemeCodeUnspsc190501.85223900'),
	'identifierSchemeCodeUnspsc190501.85223901': __('identifierSchemeCodeUnspsc190501.85223901'),
	'identifierSchemeCodeUnspsc190501.85223902': __('identifierSchemeCodeUnspsc190501.85223902'),
	'identifierSchemeCodeUnspsc190501.85223903': __('identifierSchemeCodeUnspsc190501.85223903'),
	'identifierSchemeCodeUnspsc190501.85223904': __('identifierSchemeCodeUnspsc190501.85223904'),
	'identifierSchemeCodeUnspsc190501.85223905': __('identifierSchemeCodeUnspsc190501.85223905'),
	'identifierSchemeCodeUnspsc190501.85223906': __('identifierSchemeCodeUnspsc190501.85223906'),
	'identifierSchemeCodeUnspsc190501.85223907': __('identifierSchemeCodeUnspsc190501.85223907'),
	'identifierSchemeCodeUnspsc190501.85224000': __('identifierSchemeCodeUnspsc190501.85224000'),
	'identifierSchemeCodeUnspsc190501.85224001': __('identifierSchemeCodeUnspsc190501.85224001'),
	'identifierSchemeCodeUnspsc190501.85224002': __('identifierSchemeCodeUnspsc190501.85224002'),
	'identifierSchemeCodeUnspsc190501.85224003': __('identifierSchemeCodeUnspsc190501.85224003'),
	'identifierSchemeCodeUnspsc190501.85224004': __('identifierSchemeCodeUnspsc190501.85224004'),
	'identifierSchemeCodeUnspsc190501.85224100': __('identifierSchemeCodeUnspsc190501.85224100'),
	'identifierSchemeCodeUnspsc190501.85224101': __('identifierSchemeCodeUnspsc190501.85224101'),
	'identifierSchemeCodeUnspsc190501.85224102': __('identifierSchemeCodeUnspsc190501.85224102'),
	'identifierSchemeCodeUnspsc190501.85224103': __('identifierSchemeCodeUnspsc190501.85224103'),
	'identifierSchemeCodeUnspsc190501.85224104': __('identifierSchemeCodeUnspsc190501.85224104'),
	'identifierSchemeCodeUnspsc190501.85224105': __('identifierSchemeCodeUnspsc190501.85224105'),
	'identifierSchemeCodeUnspsc190501.85224106': __('identifierSchemeCodeUnspsc190501.85224106'),
	'identifierSchemeCodeUnspsc190501.85224107': __('identifierSchemeCodeUnspsc190501.85224107'),
	'identifierSchemeCodeUnspsc190501.85224108': __('identifierSchemeCodeUnspsc190501.85224108'),
	'identifierSchemeCodeUnspsc190501.85224200': __('identifierSchemeCodeUnspsc190501.85224200'),
	'identifierSchemeCodeUnspsc190501.85224201': __('identifierSchemeCodeUnspsc190501.85224201'),
	'identifierSchemeCodeUnspsc190501.85224202': __('identifierSchemeCodeUnspsc190501.85224202'),
	'identifierSchemeCodeUnspsc190501.85224203': __('identifierSchemeCodeUnspsc190501.85224203'),
	'identifierSchemeCodeUnspsc190501.85224204': __('identifierSchemeCodeUnspsc190501.85224204'),
	'identifierSchemeCodeUnspsc190501.85224205': __('identifierSchemeCodeUnspsc190501.85224205'),
	'identifierSchemeCodeUnspsc190501.85224300': __('identifierSchemeCodeUnspsc190501.85224300'),
	'identifierSchemeCodeUnspsc190501.85224301': __('identifierSchemeCodeUnspsc190501.85224301'),
	'identifierSchemeCodeUnspsc190501.85224302': __('identifierSchemeCodeUnspsc190501.85224302'),
	'identifierSchemeCodeUnspsc190501.85224303': __('identifierSchemeCodeUnspsc190501.85224303'),
	'identifierSchemeCodeUnspsc190501.85224304': __('identifierSchemeCodeUnspsc190501.85224304'),
	'identifierSchemeCodeUnspsc190501.85224400': __('identifierSchemeCodeUnspsc190501.85224400'),
	'identifierSchemeCodeUnspsc190501.85224401': __('identifierSchemeCodeUnspsc190501.85224401'),
	'identifierSchemeCodeUnspsc190501.85224402': __('identifierSchemeCodeUnspsc190501.85224402'),
	'identifierSchemeCodeUnspsc190501.85224403': __('identifierSchemeCodeUnspsc190501.85224403'),
	'identifierSchemeCodeUnspsc190501.85224404': __('identifierSchemeCodeUnspsc190501.85224404'),
	'identifierSchemeCodeUnspsc190501.85224405': __('identifierSchemeCodeUnspsc190501.85224405'),
	'identifierSchemeCodeUnspsc190501.85224406': __('identifierSchemeCodeUnspsc190501.85224406'),
	'identifierSchemeCodeUnspsc190501.85224407': __('identifierSchemeCodeUnspsc190501.85224407'),
	'identifierSchemeCodeUnspsc190501.85224408': __('identifierSchemeCodeUnspsc190501.85224408'),
	'identifierSchemeCodeUnspsc190501.85224500': __('identifierSchemeCodeUnspsc190501.85224500'),
	'identifierSchemeCodeUnspsc190501.85224501': __('identifierSchemeCodeUnspsc190501.85224501'),
	'identifierSchemeCodeUnspsc190501.85224502': __('identifierSchemeCodeUnspsc190501.85224502'),
	'identifierSchemeCodeUnspsc190501.85224503': __('identifierSchemeCodeUnspsc190501.85224503'),
	'identifierSchemeCodeUnspsc190501.85224504': __('identifierSchemeCodeUnspsc190501.85224504'),
	'identifierSchemeCodeUnspsc190501.85224505': __('identifierSchemeCodeUnspsc190501.85224505'),
	'identifierSchemeCodeUnspsc190501.85224506': __('identifierSchemeCodeUnspsc190501.85224506'),
	'identifierSchemeCodeUnspsc190501.85224507': __('identifierSchemeCodeUnspsc190501.85224507'),
	'identifierSchemeCodeUnspsc190501.85224508': __('identifierSchemeCodeUnspsc190501.85224508'),
	'identifierSchemeCodeUnspsc190501.85250000': __('identifierSchemeCodeUnspsc190501.85250000'),
	'identifierSchemeCodeUnspsc190501.85251500': __('identifierSchemeCodeUnspsc190501.85251500'),
	'identifierSchemeCodeUnspsc190501.85251501': __('identifierSchemeCodeUnspsc190501.85251501'),
	'identifierSchemeCodeUnspsc190501.85251502': __('identifierSchemeCodeUnspsc190501.85251502'),
	'identifierSchemeCodeUnspsc190501.85251503': __('identifierSchemeCodeUnspsc190501.85251503'),
	'identifierSchemeCodeUnspsc190501.85251504': __('identifierSchemeCodeUnspsc190501.85251504'),
	'identifierSchemeCodeUnspsc190501.85251505': __('identifierSchemeCodeUnspsc190501.85251505'),
	'identifierSchemeCodeUnspsc190501.85251506': __('identifierSchemeCodeUnspsc190501.85251506'),
	'identifierSchemeCodeUnspsc190501.85251507': __('identifierSchemeCodeUnspsc190501.85251507'),
	'identifierSchemeCodeUnspsc190501.85251508': __('identifierSchemeCodeUnspsc190501.85251508'),
	'identifierSchemeCodeUnspsc190501.85251509': __('identifierSchemeCodeUnspsc190501.85251509'),
	'identifierSchemeCodeUnspsc190501.85251510': __('identifierSchemeCodeUnspsc190501.85251510'),
	'identifierSchemeCodeUnspsc190501.85251511': __('identifierSchemeCodeUnspsc190501.85251511'),
	'identifierSchemeCodeUnspsc190501.85251512': __('identifierSchemeCodeUnspsc190501.85251512'),
	'identifierSchemeCodeUnspsc190501.85251513': __('identifierSchemeCodeUnspsc190501.85251513'),
	'identifierSchemeCodeUnspsc190501.85251514': __('identifierSchemeCodeUnspsc190501.85251514'),
	'identifierSchemeCodeUnspsc190501.85251515': __('identifierSchemeCodeUnspsc190501.85251515'),
	'identifierSchemeCodeUnspsc190501.85251516': __('identifierSchemeCodeUnspsc190501.85251516'),
	'identifierSchemeCodeUnspsc190501.85251517': __('identifierSchemeCodeUnspsc190501.85251517'),
	'identifierSchemeCodeUnspsc190501.85251518': __('identifierSchemeCodeUnspsc190501.85251518'),
	'identifierSchemeCodeUnspsc190501.85251519': __('identifierSchemeCodeUnspsc190501.85251519'),
	'identifierSchemeCodeUnspsc190501.85251520': __('identifierSchemeCodeUnspsc190501.85251520'),
	'identifierSchemeCodeUnspsc190501.85251521': __('identifierSchemeCodeUnspsc190501.85251521'),
	'identifierSchemeCodeUnspsc190501.85251522': __('identifierSchemeCodeUnspsc190501.85251522'),
	'identifierSchemeCodeUnspsc190501.85251523': __('identifierSchemeCodeUnspsc190501.85251523'),
	'identifierSchemeCodeUnspsc190501.85251524': __('identifierSchemeCodeUnspsc190501.85251524'),
	'identifierSchemeCodeUnspsc190501.85251525': __('identifierSchemeCodeUnspsc190501.85251525'),
	'identifierSchemeCodeUnspsc190501.85251526': __('identifierSchemeCodeUnspsc190501.85251526'),
	'identifierSchemeCodeUnspsc190501.85251527': __('identifierSchemeCodeUnspsc190501.85251527'),
	'identifierSchemeCodeUnspsc190501.85251528': __('identifierSchemeCodeUnspsc190501.85251528'),
	'identifierSchemeCodeUnspsc190501.85251529': __('identifierSchemeCodeUnspsc190501.85251529'),
	'identifierSchemeCodeUnspsc190501.85251530': __('identifierSchemeCodeUnspsc190501.85251530'),
	'identifierSchemeCodeUnspsc190501.85251531': __('identifierSchemeCodeUnspsc190501.85251531'),
	'identifierSchemeCodeUnspsc190501.85251532': __('identifierSchemeCodeUnspsc190501.85251532'),
	'identifierSchemeCodeUnspsc190501.85251533': __('identifierSchemeCodeUnspsc190501.85251533'),
	'identifierSchemeCodeUnspsc190501.85251534': __('identifierSchemeCodeUnspsc190501.85251534'),
	'identifierSchemeCodeUnspsc190501.85251535': __('identifierSchemeCodeUnspsc190501.85251535'),
	'identifierSchemeCodeUnspsc190501.85251536': __('identifierSchemeCodeUnspsc190501.85251536'),
	'identifierSchemeCodeUnspsc190501.85251537': __('identifierSchemeCodeUnspsc190501.85251537'),
	'identifierSchemeCodeUnspsc190501.85251538': __('identifierSchemeCodeUnspsc190501.85251538'),
	'identifierSchemeCodeUnspsc190501.85251539': __('identifierSchemeCodeUnspsc190501.85251539'),
	'identifierSchemeCodeUnspsc190501.85251600': __('identifierSchemeCodeUnspsc190501.85251600'),
	'identifierSchemeCodeUnspsc190501.85251601': __('identifierSchemeCodeUnspsc190501.85251601'),
	'identifierSchemeCodeUnspsc190501.85251602': __('identifierSchemeCodeUnspsc190501.85251602'),
	'identifierSchemeCodeUnspsc190501.85251603': __('identifierSchemeCodeUnspsc190501.85251603'),
	'identifierSchemeCodeUnspsc190501.85251604': __('identifierSchemeCodeUnspsc190501.85251604'),
	'identifierSchemeCodeUnspsc190501.85251605': __('identifierSchemeCodeUnspsc190501.85251605'),
	'identifierSchemeCodeUnspsc190501.85251606': __('identifierSchemeCodeUnspsc190501.85251606'),
	'identifierSchemeCodeUnspsc190501.85251607': __('identifierSchemeCodeUnspsc190501.85251607'),
	'identifierSchemeCodeUnspsc190501.85251608': __('identifierSchemeCodeUnspsc190501.85251608'),
	'identifierSchemeCodeUnspsc190501.85251609': __('identifierSchemeCodeUnspsc190501.85251609'),
	'identifierSchemeCodeUnspsc190501.85251610': __('identifierSchemeCodeUnspsc190501.85251610'),
	'identifierSchemeCodeUnspsc190501.85251611': __('identifierSchemeCodeUnspsc190501.85251611'),
	'identifierSchemeCodeUnspsc190501.85251612': __('identifierSchemeCodeUnspsc190501.85251612'),
	'identifierSchemeCodeUnspsc190501.85251613': __('identifierSchemeCodeUnspsc190501.85251613'),
	'identifierSchemeCodeUnspsc190501.85251614': __('identifierSchemeCodeUnspsc190501.85251614'),
	'identifierSchemeCodeUnspsc190501.85251615': __('identifierSchemeCodeUnspsc190501.85251615'),
	'identifierSchemeCodeUnspsc190501.85251616': __('identifierSchemeCodeUnspsc190501.85251616'),
	'identifierSchemeCodeUnspsc190501.85251617': __('identifierSchemeCodeUnspsc190501.85251617'),
	'identifierSchemeCodeUnspsc190501.85251618': __('identifierSchemeCodeUnspsc190501.85251618'),
	'identifierSchemeCodeUnspsc190501.85251619': __('identifierSchemeCodeUnspsc190501.85251619'),
	'identifierSchemeCodeUnspsc190501.85251620': __('identifierSchemeCodeUnspsc190501.85251620'),
	'identifierSchemeCodeUnspsc190501.85251621': __('identifierSchemeCodeUnspsc190501.85251621'),
	'identifierSchemeCodeUnspsc190501.85251622': __('identifierSchemeCodeUnspsc190501.85251622'),
	'identifierSchemeCodeUnspsc190501.85251623': __('identifierSchemeCodeUnspsc190501.85251623'),
	'identifierSchemeCodeUnspsc190501.85251624': __('identifierSchemeCodeUnspsc190501.85251624'),
	'identifierSchemeCodeUnspsc190501.85251625': __('identifierSchemeCodeUnspsc190501.85251625'),
	'identifierSchemeCodeUnspsc190501.85251626': __('identifierSchemeCodeUnspsc190501.85251626'),
	'identifierSchemeCodeUnspsc190501.85251627': __('identifierSchemeCodeUnspsc190501.85251627'),
	'identifierSchemeCodeUnspsc190501.85251628': __('identifierSchemeCodeUnspsc190501.85251628'),
	'identifierSchemeCodeUnspsc190501.85251629': __('identifierSchemeCodeUnspsc190501.85251629'),
	'identifierSchemeCodeUnspsc190501.85251630': __('identifierSchemeCodeUnspsc190501.85251630'),
	'identifierSchemeCodeUnspsc190501.85251631': __('identifierSchemeCodeUnspsc190501.85251631'),
	'identifierSchemeCodeUnspsc190501.85251632': __('identifierSchemeCodeUnspsc190501.85251632'),
	'identifierSchemeCodeUnspsc190501.85251633': __('identifierSchemeCodeUnspsc190501.85251633'),
	'identifierSchemeCodeUnspsc190501.85251634': __('identifierSchemeCodeUnspsc190501.85251634'),
	'identifierSchemeCodeUnspsc190501.85251635': __('identifierSchemeCodeUnspsc190501.85251635'),
	'identifierSchemeCodeUnspsc190501.85251636': __('identifierSchemeCodeUnspsc190501.85251636'),
	'identifierSchemeCodeUnspsc190501.85251637': __('identifierSchemeCodeUnspsc190501.85251637'),
	'identifierSchemeCodeUnspsc190501.85251638': __('identifierSchemeCodeUnspsc190501.85251638'),
	'identifierSchemeCodeUnspsc190501.85251639': __('identifierSchemeCodeUnspsc190501.85251639'),
	'identifierSchemeCodeUnspsc190501.85251640': __('identifierSchemeCodeUnspsc190501.85251640'),
	'identifierSchemeCodeUnspsc190501.85251641': __('identifierSchemeCodeUnspsc190501.85251641'),
	'identifierSchemeCodeUnspsc190501.85251642': __('identifierSchemeCodeUnspsc190501.85251642'),
	'identifierSchemeCodeUnspsc190501.85251643': __('identifierSchemeCodeUnspsc190501.85251643'),
	'identifierSchemeCodeUnspsc190501.85251644': __('identifierSchemeCodeUnspsc190501.85251644'),
	'identifierSchemeCodeUnspsc190501.85251645': __('identifierSchemeCodeUnspsc190501.85251645'),
	'identifierSchemeCodeUnspsc190501.85251646': __('identifierSchemeCodeUnspsc190501.85251646'),
	'identifierSchemeCodeUnspsc190501.85251700': __('identifierSchemeCodeUnspsc190501.85251700'),
	'identifierSchemeCodeUnspsc190501.85251701': __('identifierSchemeCodeUnspsc190501.85251701'),
	'identifierSchemeCodeUnspsc190501.85251702': __('identifierSchemeCodeUnspsc190501.85251702'),
	'identifierSchemeCodeUnspsc190501.85251703': __('identifierSchemeCodeUnspsc190501.85251703'),
	'identifierSchemeCodeUnspsc190501.85251704': __('identifierSchemeCodeUnspsc190501.85251704'),
	'identifierSchemeCodeUnspsc190501.85251705': __('identifierSchemeCodeUnspsc190501.85251705'),
	'identifierSchemeCodeUnspsc190501.85251706': __('identifierSchemeCodeUnspsc190501.85251706'),
	'identifierSchemeCodeUnspsc190501.85251707': __('identifierSchemeCodeUnspsc190501.85251707'),
	'identifierSchemeCodeUnspsc190501.85251708': __('identifierSchemeCodeUnspsc190501.85251708'),
	'identifierSchemeCodeUnspsc190501.85251709': __('identifierSchemeCodeUnspsc190501.85251709'),
	'identifierSchemeCodeUnspsc190501.85251710': __('identifierSchemeCodeUnspsc190501.85251710'),
	'identifierSchemeCodeUnspsc190501.85251711': __('identifierSchemeCodeUnspsc190501.85251711'),
	'identifierSchemeCodeUnspsc190501.85251712': __('identifierSchemeCodeUnspsc190501.85251712'),
	'identifierSchemeCodeUnspsc190501.85251713': __('identifierSchemeCodeUnspsc190501.85251713'),
	'identifierSchemeCodeUnspsc190501.85251714': __('identifierSchemeCodeUnspsc190501.85251714'),
	'identifierSchemeCodeUnspsc190501.85251715': __('identifierSchemeCodeUnspsc190501.85251715'),
	'identifierSchemeCodeUnspsc190501.85251716': __('identifierSchemeCodeUnspsc190501.85251716'),
	'identifierSchemeCodeUnspsc190501.85251717': __('identifierSchemeCodeUnspsc190501.85251717'),
	'identifierSchemeCodeUnspsc190501.85251718': __('identifierSchemeCodeUnspsc190501.85251718'),
	'identifierSchemeCodeUnspsc190501.85251719': __('identifierSchemeCodeUnspsc190501.85251719'),
	'identifierSchemeCodeUnspsc190501.85251720': __('identifierSchemeCodeUnspsc190501.85251720'),
	'identifierSchemeCodeUnspsc190501.85251721': __('identifierSchemeCodeUnspsc190501.85251721'),
	'identifierSchemeCodeUnspsc190501.85251722': __('identifierSchemeCodeUnspsc190501.85251722'),
	'identifierSchemeCodeUnspsc190501.85251723': __('identifierSchemeCodeUnspsc190501.85251723'),
	'identifierSchemeCodeUnspsc190501.85251724': __('identifierSchemeCodeUnspsc190501.85251724'),
	'identifierSchemeCodeUnspsc190501.85251800': __('identifierSchemeCodeUnspsc190501.85251800'),
	'identifierSchemeCodeUnspsc190501.85251801': __('identifierSchemeCodeUnspsc190501.85251801'),
	'identifierSchemeCodeUnspsc190501.85251802': __('identifierSchemeCodeUnspsc190501.85251802'),
	'identifierSchemeCodeUnspsc190501.85251803': __('identifierSchemeCodeUnspsc190501.85251803'),
	'identifierSchemeCodeUnspsc190501.85251804': __('identifierSchemeCodeUnspsc190501.85251804'),
	'identifierSchemeCodeUnspsc190501.85251805': __('identifierSchemeCodeUnspsc190501.85251805'),
	'identifierSchemeCodeUnspsc190501.85251806': __('identifierSchemeCodeUnspsc190501.85251806'),
	'identifierSchemeCodeUnspsc190501.85251807': __('identifierSchemeCodeUnspsc190501.85251807'),
	'identifierSchemeCodeUnspsc190501.85251808': __('identifierSchemeCodeUnspsc190501.85251808'),
	'identifierSchemeCodeUnspsc190501.85251809': __('identifierSchemeCodeUnspsc190501.85251809'),
	'identifierSchemeCodeUnspsc190501.85251810': __('identifierSchemeCodeUnspsc190501.85251810'),
	'identifierSchemeCodeUnspsc190501.85251811': __('identifierSchemeCodeUnspsc190501.85251811'),
	'identifierSchemeCodeUnspsc190501.85251900': __('identifierSchemeCodeUnspsc190501.85251900'),
	'identifierSchemeCodeUnspsc190501.85251901': __('identifierSchemeCodeUnspsc190501.85251901'),
	'identifierSchemeCodeUnspsc190501.85251902': __('identifierSchemeCodeUnspsc190501.85251902'),
	'identifierSchemeCodeUnspsc190501.85251903': __('identifierSchemeCodeUnspsc190501.85251903'),
	'identifierSchemeCodeUnspsc190501.85251904': __('identifierSchemeCodeUnspsc190501.85251904'),
	'identifierSchemeCodeUnspsc190501.85251905': __('identifierSchemeCodeUnspsc190501.85251905'),
	'identifierSchemeCodeUnspsc190501.85251906': __('identifierSchemeCodeUnspsc190501.85251906'),
	'identifierSchemeCodeUnspsc190501.85251907': __('identifierSchemeCodeUnspsc190501.85251907'),
	'identifierSchemeCodeUnspsc190501.85251908': __('identifierSchemeCodeUnspsc190501.85251908'),
	'identifierSchemeCodeUnspsc190501.85251909': __('identifierSchemeCodeUnspsc190501.85251909'),
	'identifierSchemeCodeUnspsc190501.85251910': __('identifierSchemeCodeUnspsc190501.85251910'),
	'identifierSchemeCodeUnspsc190501.85251911': __('identifierSchemeCodeUnspsc190501.85251911'),
	'identifierSchemeCodeUnspsc190501.85251912': __('identifierSchemeCodeUnspsc190501.85251912'),
	'identifierSchemeCodeUnspsc190501.85251913': __('identifierSchemeCodeUnspsc190501.85251913'),
	'identifierSchemeCodeUnspsc190501.85251914': __('identifierSchemeCodeUnspsc190501.85251914'),
	'identifierSchemeCodeUnspsc190501.85251915': __('identifierSchemeCodeUnspsc190501.85251915'),
	'identifierSchemeCodeUnspsc190501.85251916': __('identifierSchemeCodeUnspsc190501.85251916'),
	'identifierSchemeCodeUnspsc190501.85252000': __('identifierSchemeCodeUnspsc190501.85252000'),
	'identifierSchemeCodeUnspsc190501.85252001': __('identifierSchemeCodeUnspsc190501.85252001'),
	'identifierSchemeCodeUnspsc190501.85252002': __('identifierSchemeCodeUnspsc190501.85252002'),
	'identifierSchemeCodeUnspsc190501.85252003': __('identifierSchemeCodeUnspsc190501.85252003'),
	'identifierSchemeCodeUnspsc190501.85252004': __('identifierSchemeCodeUnspsc190501.85252004'),
	'identifierSchemeCodeUnspsc190501.85252005': __('identifierSchemeCodeUnspsc190501.85252005'),
	'identifierSchemeCodeUnspsc190501.85252006': __('identifierSchemeCodeUnspsc190501.85252006'),
	'identifierSchemeCodeUnspsc190501.85252007': __('identifierSchemeCodeUnspsc190501.85252007'),
	'identifierSchemeCodeUnspsc190501.85252008': __('identifierSchemeCodeUnspsc190501.85252008'),
	'identifierSchemeCodeUnspsc190501.85252009': __('identifierSchemeCodeUnspsc190501.85252009'),
	'identifierSchemeCodeUnspsc190501.85252010': __('identifierSchemeCodeUnspsc190501.85252010'),
	'identifierSchemeCodeUnspsc190501.85252011': __('identifierSchemeCodeUnspsc190501.85252011'),
	'identifierSchemeCodeUnspsc190501.85252012': __('identifierSchemeCodeUnspsc190501.85252012'),
	'identifierSchemeCodeUnspsc190501.85252013': __('identifierSchemeCodeUnspsc190501.85252013'),
	'identifierSchemeCodeUnspsc190501.85252014': __('identifierSchemeCodeUnspsc190501.85252014'),
	'identifierSchemeCodeUnspsc190501.85252015': __('identifierSchemeCodeUnspsc190501.85252015'),
	'identifierSchemeCodeUnspsc190501.85252100': __('identifierSchemeCodeUnspsc190501.85252100'),
	'identifierSchemeCodeUnspsc190501.85252101': __('identifierSchemeCodeUnspsc190501.85252101'),
	'identifierSchemeCodeUnspsc190501.85252102': __('identifierSchemeCodeUnspsc190501.85252102'),
	'identifierSchemeCodeUnspsc190501.85252103': __('identifierSchemeCodeUnspsc190501.85252103'),
	'identifierSchemeCodeUnspsc190501.85252104': __('identifierSchemeCodeUnspsc190501.85252104'),
	'identifierSchemeCodeUnspsc190501.85252105': __('identifierSchemeCodeUnspsc190501.85252105'),
	'identifierSchemeCodeUnspsc190501.85252106': __('identifierSchemeCodeUnspsc190501.85252106'),
	'identifierSchemeCodeUnspsc190501.85252107': __('identifierSchemeCodeUnspsc190501.85252107'),
	'identifierSchemeCodeUnspsc190501.85252108': __('identifierSchemeCodeUnspsc190501.85252108'),
	'identifierSchemeCodeUnspsc190501.85252200': __('identifierSchemeCodeUnspsc190501.85252200'),
	'identifierSchemeCodeUnspsc190501.85252201': __('identifierSchemeCodeUnspsc190501.85252201'),
	'identifierSchemeCodeUnspsc190501.85252202': __('identifierSchemeCodeUnspsc190501.85252202'),
	'identifierSchemeCodeUnspsc190501.85252203': __('identifierSchemeCodeUnspsc190501.85252203'),
	'identifierSchemeCodeUnspsc190501.85252204': __('identifierSchemeCodeUnspsc190501.85252204'),
	'identifierSchemeCodeUnspsc190501.85252205': __('identifierSchemeCodeUnspsc190501.85252205'),
	'identifierSchemeCodeUnspsc190501.85252206': __('identifierSchemeCodeUnspsc190501.85252206'),
	'identifierSchemeCodeUnspsc190501.85252207': __('identifierSchemeCodeUnspsc190501.85252207'),
	'identifierSchemeCodeUnspsc190501.85252208': __('identifierSchemeCodeUnspsc190501.85252208'),
	'identifierSchemeCodeUnspsc190501.85252209': __('identifierSchemeCodeUnspsc190501.85252209'),
	'identifierSchemeCodeUnspsc190501.85252210': __('identifierSchemeCodeUnspsc190501.85252210'),
	'identifierSchemeCodeUnspsc190501.85252211': __('identifierSchemeCodeUnspsc190501.85252211'),
	'identifierSchemeCodeUnspsc190501.85252212': __('identifierSchemeCodeUnspsc190501.85252212'),
	'identifierSchemeCodeUnspsc190501.85252213': __('identifierSchemeCodeUnspsc190501.85252213'),
	'identifierSchemeCodeUnspsc190501.85252214': __('identifierSchemeCodeUnspsc190501.85252214'),
	'identifierSchemeCodeUnspsc190501.85252215': __('identifierSchemeCodeUnspsc190501.85252215'),
	'identifierSchemeCodeUnspsc190501.85252216': __('identifierSchemeCodeUnspsc190501.85252216'),
	'identifierSchemeCodeUnspsc190501.85252217': __('identifierSchemeCodeUnspsc190501.85252217'),
	'identifierSchemeCodeUnspsc190501.85252300': __('identifierSchemeCodeUnspsc190501.85252300'),
	'identifierSchemeCodeUnspsc190501.85252301': __('identifierSchemeCodeUnspsc190501.85252301'),
	'identifierSchemeCodeUnspsc190501.85252302': __('identifierSchemeCodeUnspsc190501.85252302'),
	'identifierSchemeCodeUnspsc190501.85252303': __('identifierSchemeCodeUnspsc190501.85252303'),
	'identifierSchemeCodeUnspsc190501.85252304': __('identifierSchemeCodeUnspsc190501.85252304'),
	'identifierSchemeCodeUnspsc190501.85252305': __('identifierSchemeCodeUnspsc190501.85252305'),
	'identifierSchemeCodeUnspsc190501.85252306': __('identifierSchemeCodeUnspsc190501.85252306'),
	'identifierSchemeCodeUnspsc190501.85252307': __('identifierSchemeCodeUnspsc190501.85252307'),
	'identifierSchemeCodeUnspsc190501.85252308': __('identifierSchemeCodeUnspsc190501.85252308'),
	'identifierSchemeCodeUnspsc190501.85252309': __('identifierSchemeCodeUnspsc190501.85252309'),
	'identifierSchemeCodeUnspsc190501.85252310': __('identifierSchemeCodeUnspsc190501.85252310'),
	'identifierSchemeCodeUnspsc190501.85252311': __('identifierSchemeCodeUnspsc190501.85252311'),
	'identifierSchemeCodeUnspsc190501.85252312': __('identifierSchemeCodeUnspsc190501.85252312'),
	'identifierSchemeCodeUnspsc190501.85252313': __('identifierSchemeCodeUnspsc190501.85252313'),
	'identifierSchemeCodeUnspsc190501.85252314': __('identifierSchemeCodeUnspsc190501.85252314'),
	'identifierSchemeCodeUnspsc190501.85252315': __('identifierSchemeCodeUnspsc190501.85252315'),
	'identifierSchemeCodeUnspsc190501.85252316': __('identifierSchemeCodeUnspsc190501.85252316'),
	'identifierSchemeCodeUnspsc190501.85252317': __('identifierSchemeCodeUnspsc190501.85252317'),
	'identifierSchemeCodeUnspsc190501.85252318': __('identifierSchemeCodeUnspsc190501.85252318'),
	'identifierSchemeCodeUnspsc190501.85252319': __('identifierSchemeCodeUnspsc190501.85252319'),
	'identifierSchemeCodeUnspsc190501.85252400': __('identifierSchemeCodeUnspsc190501.85252400'),
	'identifierSchemeCodeUnspsc190501.85252401': __('identifierSchemeCodeUnspsc190501.85252401'),
	'identifierSchemeCodeUnspsc190501.85252402': __('identifierSchemeCodeUnspsc190501.85252402'),
	'identifierSchemeCodeUnspsc190501.85252403': __('identifierSchemeCodeUnspsc190501.85252403'),
	'identifierSchemeCodeUnspsc190501.85252404': __('identifierSchemeCodeUnspsc190501.85252404'),
	'identifierSchemeCodeUnspsc190501.85252405': __('identifierSchemeCodeUnspsc190501.85252405'),
	'identifierSchemeCodeUnspsc190501.85252406': __('identifierSchemeCodeUnspsc190501.85252406'),
	'identifierSchemeCodeUnspsc190501.85252407': __('identifierSchemeCodeUnspsc190501.85252407'),
	'identifierSchemeCodeUnspsc190501.85252408': __('identifierSchemeCodeUnspsc190501.85252408'),
	'identifierSchemeCodeUnspsc190501.85252409': __('identifierSchemeCodeUnspsc190501.85252409'),
	'identifierSchemeCodeUnspsc190501.85252410': __('identifierSchemeCodeUnspsc190501.85252410'),
	'identifierSchemeCodeUnspsc190501.85252411': __('identifierSchemeCodeUnspsc190501.85252411'),
	'identifierSchemeCodeUnspsc190501.85252412': __('identifierSchemeCodeUnspsc190501.85252412'),
	'identifierSchemeCodeUnspsc190501.85252500': __('identifierSchemeCodeUnspsc190501.85252500'),
	'identifierSchemeCodeUnspsc190501.85252501': __('identifierSchemeCodeUnspsc190501.85252501'),
	'identifierSchemeCodeUnspsc190501.85252502': __('identifierSchemeCodeUnspsc190501.85252502'),
	'identifierSchemeCodeUnspsc190501.85252503': __('identifierSchemeCodeUnspsc190501.85252503'),
	'identifierSchemeCodeUnspsc190501.85252504': __('identifierSchemeCodeUnspsc190501.85252504'),
	'identifierSchemeCodeUnspsc190501.85252505': __('identifierSchemeCodeUnspsc190501.85252505'),
	'identifierSchemeCodeUnspsc190501.85252506': __('identifierSchemeCodeUnspsc190501.85252506'),
	'identifierSchemeCodeUnspsc190501.85252507': __('identifierSchemeCodeUnspsc190501.85252507'),
	'identifierSchemeCodeUnspsc190501.85252508': __('identifierSchemeCodeUnspsc190501.85252508'),
	'identifierSchemeCodeUnspsc190501.85252509': __('identifierSchemeCodeUnspsc190501.85252509'),
	'identifierSchemeCodeUnspsc190501.85252510': __('identifierSchemeCodeUnspsc190501.85252510'),
	'identifierSchemeCodeUnspsc190501.85252511': __('identifierSchemeCodeUnspsc190501.85252511'),
	'identifierSchemeCodeUnspsc190501.85252512': __('identifierSchemeCodeUnspsc190501.85252512'),
	'identifierSchemeCodeUnspsc190501.85252513': __('identifierSchemeCodeUnspsc190501.85252513'),
	'identifierSchemeCodeUnspsc190501.85252514': __('identifierSchemeCodeUnspsc190501.85252514'),
	'identifierSchemeCodeUnspsc190501.85252515': __('identifierSchemeCodeUnspsc190501.85252515'),
	'identifierSchemeCodeUnspsc190501.85252516': __('identifierSchemeCodeUnspsc190501.85252516'),
	'identifierSchemeCodeUnspsc190501.85252517': __('identifierSchemeCodeUnspsc190501.85252517'),
	'identifierSchemeCodeUnspsc190501.85252518': __('identifierSchemeCodeUnspsc190501.85252518'),
	'identifierSchemeCodeUnspsc190501.85252519': __('identifierSchemeCodeUnspsc190501.85252519'),
	'identifierSchemeCodeUnspsc190501.85252520': __('identifierSchemeCodeUnspsc190501.85252520'),
	'identifierSchemeCodeUnspsc190501.85252521': __('identifierSchemeCodeUnspsc190501.85252521'),
	'identifierSchemeCodeUnspsc190501.85252522': __('identifierSchemeCodeUnspsc190501.85252522'),
	'identifierSchemeCodeUnspsc190501.85252523': __('identifierSchemeCodeUnspsc190501.85252523'),
	'identifierSchemeCodeUnspsc190501.85252524': __('identifierSchemeCodeUnspsc190501.85252524'),
	'identifierSchemeCodeUnspsc190501.85252600': __('identifierSchemeCodeUnspsc190501.85252600'),
	'identifierSchemeCodeUnspsc190501.85252601': __('identifierSchemeCodeUnspsc190501.85252601'),
	'identifierSchemeCodeUnspsc190501.85252602': __('identifierSchemeCodeUnspsc190501.85252602'),
	'identifierSchemeCodeUnspsc190501.85252603': __('identifierSchemeCodeUnspsc190501.85252603'),
	'identifierSchemeCodeUnspsc190501.85252604': __('identifierSchemeCodeUnspsc190501.85252604'),
	'identifierSchemeCodeUnspsc190501.85252605': __('identifierSchemeCodeUnspsc190501.85252605'),
	'identifierSchemeCodeUnspsc190501.85252606': __('identifierSchemeCodeUnspsc190501.85252606'),
	'identifierSchemeCodeUnspsc190501.85252607': __('identifierSchemeCodeUnspsc190501.85252607'),
	'identifierSchemeCodeUnspsc190501.85252700': __('identifierSchemeCodeUnspsc190501.85252700'),
	'identifierSchemeCodeUnspsc190501.85252701': __('identifierSchemeCodeUnspsc190501.85252701'),
	'identifierSchemeCodeUnspsc190501.85252702': __('identifierSchemeCodeUnspsc190501.85252702'),
	'identifierSchemeCodeUnspsc190501.85252703': __('identifierSchemeCodeUnspsc190501.85252703'),
	'identifierSchemeCodeUnspsc190501.85252704': __('identifierSchemeCodeUnspsc190501.85252704'),
	'identifierSchemeCodeUnspsc190501.85252705': __('identifierSchemeCodeUnspsc190501.85252705'),
	'identifierSchemeCodeUnspsc190501.85252706': __('identifierSchemeCodeUnspsc190501.85252706'),
	'identifierSchemeCodeUnspsc190501.85252707': __('identifierSchemeCodeUnspsc190501.85252707'),
	'identifierSchemeCodeUnspsc190501.85252708': __('identifierSchemeCodeUnspsc190501.85252708'),
	'identifierSchemeCodeUnspsc190501.85252709': __('identifierSchemeCodeUnspsc190501.85252709'),
	'identifierSchemeCodeUnspsc190501.85252710': __('identifierSchemeCodeUnspsc190501.85252710'),
	'identifierSchemeCodeUnspsc190501.85252711': __('identifierSchemeCodeUnspsc190501.85252711'),
	'identifierSchemeCodeUnspsc190501.85252712': __('identifierSchemeCodeUnspsc190501.85252712'),
	'identifierSchemeCodeUnspsc190501.85252713': __('identifierSchemeCodeUnspsc190501.85252713'),
	'identifierSchemeCodeUnspsc190501.85252800': __('identifierSchemeCodeUnspsc190501.85252800'),
	'identifierSchemeCodeUnspsc190501.85252801': __('identifierSchemeCodeUnspsc190501.85252801'),
	'identifierSchemeCodeUnspsc190501.85252802': __('identifierSchemeCodeUnspsc190501.85252802'),
	'identifierSchemeCodeUnspsc190501.85252803': __('identifierSchemeCodeUnspsc190501.85252803'),
	'identifierSchemeCodeUnspsc190501.85252804': __('identifierSchemeCodeUnspsc190501.85252804'),
	'identifierSchemeCodeUnspsc190501.85252805': __('identifierSchemeCodeUnspsc190501.85252805'),
	'identifierSchemeCodeUnspsc190501.85252806': __('identifierSchemeCodeUnspsc190501.85252806'),
	'identifierSchemeCodeUnspsc190501.85252807': __('identifierSchemeCodeUnspsc190501.85252807'),
	'identifierSchemeCodeUnspsc190501.85252808': __('identifierSchemeCodeUnspsc190501.85252808'),
	'identifierSchemeCodeUnspsc190501.85252809': __('identifierSchemeCodeUnspsc190501.85252809'),
	'identifierSchemeCodeUnspsc190501.85252810': __('identifierSchemeCodeUnspsc190501.85252810'),
	'identifierSchemeCodeUnspsc190501.85252811': __('identifierSchemeCodeUnspsc190501.85252811'),
	'identifierSchemeCodeUnspsc190501.85252812': __('identifierSchemeCodeUnspsc190501.85252812'),
	'identifierSchemeCodeUnspsc190501.85252813': __('identifierSchemeCodeUnspsc190501.85252813'),
	'identifierSchemeCodeUnspsc190501.85252814': __('identifierSchemeCodeUnspsc190501.85252814'),
	'identifierSchemeCodeUnspsc190501.85252815': __('identifierSchemeCodeUnspsc190501.85252815'),
	'identifierSchemeCodeUnspsc190501.85252816': __('identifierSchemeCodeUnspsc190501.85252816'),
	'identifierSchemeCodeUnspsc190501.85252817': __('identifierSchemeCodeUnspsc190501.85252817'),
	'identifierSchemeCodeUnspsc190501.85252818': __('identifierSchemeCodeUnspsc190501.85252818'),
	'identifierSchemeCodeUnspsc190501.85252819': __('identifierSchemeCodeUnspsc190501.85252819'),
	'identifierSchemeCodeUnspsc190501.85252820': __('identifierSchemeCodeUnspsc190501.85252820'),
	'identifierSchemeCodeUnspsc190501.85252821': __('identifierSchemeCodeUnspsc190501.85252821'),
	'identifierSchemeCodeUnspsc190501.85252822': __('identifierSchemeCodeUnspsc190501.85252822'),
	'identifierSchemeCodeUnspsc190501.85252823': __('identifierSchemeCodeUnspsc190501.85252823'),
	'identifierSchemeCodeUnspsc190501.85252824': __('identifierSchemeCodeUnspsc190501.85252824'),
	'identifierSchemeCodeUnspsc190501.85252825': __('identifierSchemeCodeUnspsc190501.85252825'),
	'identifierSchemeCodeUnspsc190501.85252826': __('identifierSchemeCodeUnspsc190501.85252826'),
	'identifierSchemeCodeUnspsc190501.85252827': __('identifierSchemeCodeUnspsc190501.85252827'),
	'identifierSchemeCodeUnspsc190501.85252828': __('identifierSchemeCodeUnspsc190501.85252828'),
	'identifierSchemeCodeUnspsc190501.85252829': __('identifierSchemeCodeUnspsc190501.85252829'),
	'identifierSchemeCodeUnspsc190501.85252830': __('identifierSchemeCodeUnspsc190501.85252830'),
	'identifierSchemeCodeUnspsc190501.85252831': __('identifierSchemeCodeUnspsc190501.85252831'),
	'identifierSchemeCodeUnspsc190501.85252832': __('identifierSchemeCodeUnspsc190501.85252832'),
	'identifierSchemeCodeUnspsc190501.85252833': __('identifierSchemeCodeUnspsc190501.85252833'),
	'identifierSchemeCodeUnspsc190501.85252834': __('identifierSchemeCodeUnspsc190501.85252834'),
	'identifierSchemeCodeUnspsc190501.85252835': __('identifierSchemeCodeUnspsc190501.85252835'),
	'identifierSchemeCodeUnspsc190501.85252836': __('identifierSchemeCodeUnspsc190501.85252836'),
	'identifierSchemeCodeUnspsc190501.85252837': __('identifierSchemeCodeUnspsc190501.85252837'),
	'identifierSchemeCodeUnspsc190501.85252838': __('identifierSchemeCodeUnspsc190501.85252838'),
	'identifierSchemeCodeUnspsc190501.85252839': __('identifierSchemeCodeUnspsc190501.85252839'),
	'identifierSchemeCodeUnspsc190501.85252840': __('identifierSchemeCodeUnspsc190501.85252840'),
	'identifierSchemeCodeUnspsc190501.85252841': __('identifierSchemeCodeUnspsc190501.85252841'),
	'identifierSchemeCodeUnspsc190501.85252842': __('identifierSchemeCodeUnspsc190501.85252842'),
	'identifierSchemeCodeUnspsc190501.85252843': __('identifierSchemeCodeUnspsc190501.85252843'),
	'identifierSchemeCodeUnspsc190501.85252844': __('identifierSchemeCodeUnspsc190501.85252844'),
	'identifierSchemeCodeUnspsc190501.85252845': __('identifierSchemeCodeUnspsc190501.85252845'),
	'identifierSchemeCodeUnspsc190501.85252846': __('identifierSchemeCodeUnspsc190501.85252846'),
	'identifierSchemeCodeUnspsc190501.85252847': __('identifierSchemeCodeUnspsc190501.85252847'),
	'identifierSchemeCodeUnspsc190501.85252848': __('identifierSchemeCodeUnspsc190501.85252848'),
	'identifierSchemeCodeUnspsc190501.85252849': __('identifierSchemeCodeUnspsc190501.85252849'),
	'identifierSchemeCodeUnspsc190501.85252850': __('identifierSchemeCodeUnspsc190501.85252850'),
	'identifierSchemeCodeUnspsc190501.85252851': __('identifierSchemeCodeUnspsc190501.85252851'),
	'identifierSchemeCodeUnspsc190501.85252852': __('identifierSchemeCodeUnspsc190501.85252852'),
	'identifierSchemeCodeUnspsc190501.85252853': __('identifierSchemeCodeUnspsc190501.85252853'),
	'identifierSchemeCodeUnspsc190501.85252854': __('identifierSchemeCodeUnspsc190501.85252854'),
	'identifierSchemeCodeUnspsc190501.85252855': __('identifierSchemeCodeUnspsc190501.85252855'),
	'identifierSchemeCodeUnspsc190501.85252856': __('identifierSchemeCodeUnspsc190501.85252856'),
	'identifierSchemeCodeUnspsc190501.85252857': __('identifierSchemeCodeUnspsc190501.85252857'),
	'identifierSchemeCodeUnspsc190501.85252858': __('identifierSchemeCodeUnspsc190501.85252858'),
	'identifierSchemeCodeUnspsc190501.85252859': __('identifierSchemeCodeUnspsc190501.85252859'),
	'identifierSchemeCodeUnspsc190501.85252860': __('identifierSchemeCodeUnspsc190501.85252860'),
	'identifierSchemeCodeUnspsc190501.85252861': __('identifierSchemeCodeUnspsc190501.85252861'),
	'identifierSchemeCodeUnspsc190501.85252862': __('identifierSchemeCodeUnspsc190501.85252862'),
	'identifierSchemeCodeUnspsc190501.85252863': __('identifierSchemeCodeUnspsc190501.85252863'),
	'identifierSchemeCodeUnspsc190501.85252900': __('identifierSchemeCodeUnspsc190501.85252900'),
	'identifierSchemeCodeUnspsc190501.85252901': __('identifierSchemeCodeUnspsc190501.85252901'),
	'identifierSchemeCodeUnspsc190501.85252902': __('identifierSchemeCodeUnspsc190501.85252902'),
	'identifierSchemeCodeUnspsc190501.85252903': __('identifierSchemeCodeUnspsc190501.85252903'),
	'identifierSchemeCodeUnspsc190501.85253000': __('identifierSchemeCodeUnspsc190501.85253000'),
	'identifierSchemeCodeUnspsc190501.85253001': __('identifierSchemeCodeUnspsc190501.85253001'),
	'identifierSchemeCodeUnspsc190501.85253002': __('identifierSchemeCodeUnspsc190501.85253002'),
	'identifierSchemeCodeUnspsc190501.85253003': __('identifierSchemeCodeUnspsc190501.85253003'),
	'identifierSchemeCodeUnspsc190501.85253004': __('identifierSchemeCodeUnspsc190501.85253004'),
	'identifierSchemeCodeUnspsc190501.85253005': __('identifierSchemeCodeUnspsc190501.85253005'),
	'identifierSchemeCodeUnspsc190501.85253100': __('identifierSchemeCodeUnspsc190501.85253100'),
	'identifierSchemeCodeUnspsc190501.85253101': __('identifierSchemeCodeUnspsc190501.85253101'),
	'identifierSchemeCodeUnspsc190501.85253102': __('identifierSchemeCodeUnspsc190501.85253102'),
	'identifierSchemeCodeUnspsc190501.85253103': __('identifierSchemeCodeUnspsc190501.85253103'),
	'identifierSchemeCodeUnspsc190501.85253200': __('identifierSchemeCodeUnspsc190501.85253200'),
	'identifierSchemeCodeUnspsc190501.85253201': __('identifierSchemeCodeUnspsc190501.85253201'),
	'identifierSchemeCodeUnspsc190501.85253202': __('identifierSchemeCodeUnspsc190501.85253202'),
	'identifierSchemeCodeUnspsc190501.85253203': __('identifierSchemeCodeUnspsc190501.85253203'),
	'identifierSchemeCodeUnspsc190501.85253204': __('identifierSchemeCodeUnspsc190501.85253204'),
	'identifierSchemeCodeUnspsc190501.85253205': __('identifierSchemeCodeUnspsc190501.85253205'),
	'identifierSchemeCodeUnspsc190501.85253206': __('identifierSchemeCodeUnspsc190501.85253206'),
	'identifierSchemeCodeUnspsc190501.85253207': __('identifierSchemeCodeUnspsc190501.85253207'),
	'identifierSchemeCodeUnspsc190501.85253300': __('identifierSchemeCodeUnspsc190501.85253300'),
	'identifierSchemeCodeUnspsc190501.85253301': __('identifierSchemeCodeUnspsc190501.85253301'),
	'identifierSchemeCodeUnspsc190501.85253302': __('identifierSchemeCodeUnspsc190501.85253302'),
	'identifierSchemeCodeUnspsc190501.85253303': __('identifierSchemeCodeUnspsc190501.85253303'),
	'identifierSchemeCodeUnspsc190501.85253304': __('identifierSchemeCodeUnspsc190501.85253304'),
	'identifierSchemeCodeUnspsc190501.85253305': __('identifierSchemeCodeUnspsc190501.85253305'),
	'identifierSchemeCodeUnspsc190501.85253306': __('identifierSchemeCodeUnspsc190501.85253306'),
	'identifierSchemeCodeUnspsc190501.85253307': __('identifierSchemeCodeUnspsc190501.85253307'),
	'identifierSchemeCodeUnspsc190501.85253400': __('identifierSchemeCodeUnspsc190501.85253400'),
	'identifierSchemeCodeUnspsc190501.85253401': __('identifierSchemeCodeUnspsc190501.85253401'),
	'identifierSchemeCodeUnspsc190501.85253402': __('identifierSchemeCodeUnspsc190501.85253402'),
	'identifierSchemeCodeUnspsc190501.85253500': __('identifierSchemeCodeUnspsc190501.85253500'),
	'identifierSchemeCodeUnspsc190501.85253501': __('identifierSchemeCodeUnspsc190501.85253501'),
	'identifierSchemeCodeUnspsc190501.85253502': __('identifierSchemeCodeUnspsc190501.85253502'),
	'identifierSchemeCodeUnspsc190501.85253600': __('identifierSchemeCodeUnspsc190501.85253600'),
	'identifierSchemeCodeUnspsc190501.85253601': __('identifierSchemeCodeUnspsc190501.85253601'),
	'identifierSchemeCodeUnspsc190501.85253602': __('identifierSchemeCodeUnspsc190501.85253602'),
	'identifierSchemeCodeUnspsc190501.85253603': __('identifierSchemeCodeUnspsc190501.85253603'),
	'identifierSchemeCodeUnspsc190501.85253604': __('identifierSchemeCodeUnspsc190501.85253604'),
	'identifierSchemeCodeUnspsc190501.85253605': __('identifierSchemeCodeUnspsc190501.85253605'),
	'identifierSchemeCodeUnspsc190501.85253700': __('identifierSchemeCodeUnspsc190501.85253700'),
	'identifierSchemeCodeUnspsc190501.85253701': __('identifierSchemeCodeUnspsc190501.85253701'),
	'identifierSchemeCodeUnspsc190501.85253702': __('identifierSchemeCodeUnspsc190501.85253702'),
	'identifierSchemeCodeUnspsc190501.85253800': __('identifierSchemeCodeUnspsc190501.85253800'),
	'identifierSchemeCodeUnspsc190501.85253801': __('identifierSchemeCodeUnspsc190501.85253801'),
	'identifierSchemeCodeUnspsc190501.85253802': __('identifierSchemeCodeUnspsc190501.85253802'),
	'identifierSchemeCodeUnspsc190501.85253803': __('identifierSchemeCodeUnspsc190501.85253803'),
	'identifierSchemeCodeUnspsc190501.85253804': __('identifierSchemeCodeUnspsc190501.85253804'),
	'identifierSchemeCodeUnspsc190501.85253805': __('identifierSchemeCodeUnspsc190501.85253805'),
	'identifierSchemeCodeUnspsc190501.85253806': __('identifierSchemeCodeUnspsc190501.85253806'),
	'identifierSchemeCodeUnspsc190501.85253807': __('identifierSchemeCodeUnspsc190501.85253807'),
	'identifierSchemeCodeUnspsc190501.85253900': __('identifierSchemeCodeUnspsc190501.85253900'),
	'identifierSchemeCodeUnspsc190501.85253901': __('identifierSchemeCodeUnspsc190501.85253901'),
	'identifierSchemeCodeUnspsc190501.85253902': __('identifierSchemeCodeUnspsc190501.85253902'),
	'identifierSchemeCodeUnspsc190501.85253903': __('identifierSchemeCodeUnspsc190501.85253903'),
	'identifierSchemeCodeUnspsc190501.85253904': __('identifierSchemeCodeUnspsc190501.85253904'),
	'identifierSchemeCodeUnspsc190501.85253905': __('identifierSchemeCodeUnspsc190501.85253905'),
	'identifierSchemeCodeUnspsc190501.85253906': __('identifierSchemeCodeUnspsc190501.85253906'),
	'identifierSchemeCodeUnspsc190501.85253907': __('identifierSchemeCodeUnspsc190501.85253907'),
	'identifierSchemeCodeUnspsc190501.85253908': __('identifierSchemeCodeUnspsc190501.85253908'),
	'identifierSchemeCodeUnspsc190501.85253909': __('identifierSchemeCodeUnspsc190501.85253909'),
	'identifierSchemeCodeUnspsc190501.85254000': __('identifierSchemeCodeUnspsc190501.85254000'),
	'identifierSchemeCodeUnspsc190501.85254001': __('identifierSchemeCodeUnspsc190501.85254001'),
	'identifierSchemeCodeUnspsc190501.85254002': __('identifierSchemeCodeUnspsc190501.85254002'),
	'identifierSchemeCodeUnspsc190501.85254003': __('identifierSchemeCodeUnspsc190501.85254003'),
	'identifierSchemeCodeUnspsc190501.85254004': __('identifierSchemeCodeUnspsc190501.85254004'),
	'identifierSchemeCodeUnspsc190501.85254005': __('identifierSchemeCodeUnspsc190501.85254005'),
	'identifierSchemeCodeUnspsc190501.85254006': __('identifierSchemeCodeUnspsc190501.85254006'),
	'identifierSchemeCodeUnspsc190501.85254007': __('identifierSchemeCodeUnspsc190501.85254007'),
	'identifierSchemeCodeUnspsc190501.85254100': __('identifierSchemeCodeUnspsc190501.85254100'),
	'identifierSchemeCodeUnspsc190501.85254102': __('identifierSchemeCodeUnspsc190501.85254102'),
	'identifierSchemeCodeUnspsc190501.85254104': __('identifierSchemeCodeUnspsc190501.85254104'),
	'identifierSchemeCodeUnspsc190501.85254106': __('identifierSchemeCodeUnspsc190501.85254106'),
	'identifierSchemeCodeUnspsc190501.85254108': __('identifierSchemeCodeUnspsc190501.85254108'),
	'identifierSchemeCodeUnspsc190501.85254200': __('identifierSchemeCodeUnspsc190501.85254200'),
	'identifierSchemeCodeUnspsc190501.85254201': __('identifierSchemeCodeUnspsc190501.85254201'),
	'identifierSchemeCodeUnspsc190501.85254202': __('identifierSchemeCodeUnspsc190501.85254202'),
	'identifierSchemeCodeUnspsc190501.85254203': __('identifierSchemeCodeUnspsc190501.85254203'),
	'identifierSchemeCodeUnspsc190501.85254300': __('identifierSchemeCodeUnspsc190501.85254300'),
	'identifierSchemeCodeUnspsc190501.85254301': __('identifierSchemeCodeUnspsc190501.85254301'),
	'identifierSchemeCodeUnspsc190501.85254302': __('identifierSchemeCodeUnspsc190501.85254302'),
	'identifierSchemeCodeUnspsc190501.85254303': __('identifierSchemeCodeUnspsc190501.85254303'),
	'identifierSchemeCodeUnspsc190501.85254304': __('identifierSchemeCodeUnspsc190501.85254304'),
	'identifierSchemeCodeUnspsc190501.85254305': __('identifierSchemeCodeUnspsc190501.85254305'),
	'identifierSchemeCodeUnspsc190501.85254306': __('identifierSchemeCodeUnspsc190501.85254306'),
	'identifierSchemeCodeUnspsc190501.85254307': __('identifierSchemeCodeUnspsc190501.85254307'),
	'identifierSchemeCodeUnspsc190501.85254308': __('identifierSchemeCodeUnspsc190501.85254308'),
	'identifierSchemeCodeUnspsc190501.85254309': __('identifierSchemeCodeUnspsc190501.85254309'),
	'identifierSchemeCodeUnspsc190501.85254400': __('identifierSchemeCodeUnspsc190501.85254400'),
	'identifierSchemeCodeUnspsc190501.85254401': __('identifierSchemeCodeUnspsc190501.85254401'),
	'identifierSchemeCodeUnspsc190501.85254402': __('identifierSchemeCodeUnspsc190501.85254402'),
	'identifierSchemeCodeUnspsc190501.85254403': __('identifierSchemeCodeUnspsc190501.85254403'),
	'identifierSchemeCodeUnspsc190501.85254404': __('identifierSchemeCodeUnspsc190501.85254404'),
	'identifierSchemeCodeUnspsc190501.85254405': __('identifierSchemeCodeUnspsc190501.85254405'),
	'identifierSchemeCodeUnspsc190501.85254406': __('identifierSchemeCodeUnspsc190501.85254406'),
	'identifierSchemeCodeUnspsc190501.85254500': __('identifierSchemeCodeUnspsc190501.85254500'),
	'identifierSchemeCodeUnspsc190501.85254501': __('identifierSchemeCodeUnspsc190501.85254501'),
	'identifierSchemeCodeUnspsc190501.85254502': __('identifierSchemeCodeUnspsc190501.85254502'),
	'identifierSchemeCodeUnspsc190501.85254600': __('identifierSchemeCodeUnspsc190501.85254600'),
	'identifierSchemeCodeUnspsc190501.85254601': __('identifierSchemeCodeUnspsc190501.85254601'),
	'identifierSchemeCodeUnspsc190501.85254602': __('identifierSchemeCodeUnspsc190501.85254602'),
	'identifierSchemeCodeUnspsc190501.85254700': __('identifierSchemeCodeUnspsc190501.85254700'),
	'identifierSchemeCodeUnspsc190501.85254701': __('identifierSchemeCodeUnspsc190501.85254701'),
	'identifierSchemeCodeUnspsc190501.85254702': __('identifierSchemeCodeUnspsc190501.85254702'),
	'identifierSchemeCodeUnspsc190501.85254800': __('identifierSchemeCodeUnspsc190501.85254800'),
	'identifierSchemeCodeUnspsc190501.85254801': __('identifierSchemeCodeUnspsc190501.85254801'),
	'identifierSchemeCodeUnspsc190501.85254802': __('identifierSchemeCodeUnspsc190501.85254802'),
	'identifierSchemeCodeUnspsc190501.85254803': __('identifierSchemeCodeUnspsc190501.85254803'),
	'identifierSchemeCodeUnspsc190501.85254804': __('identifierSchemeCodeUnspsc190501.85254804'),
	'identifierSchemeCodeUnspsc190501.85254805': __('identifierSchemeCodeUnspsc190501.85254805'),
	'identifierSchemeCodeUnspsc190501.85254806': __('identifierSchemeCodeUnspsc190501.85254806'),
	'identifierSchemeCodeUnspsc190501.85254807': __('identifierSchemeCodeUnspsc190501.85254807'),
	'identifierSchemeCodeUnspsc190501.85254900': __('identifierSchemeCodeUnspsc190501.85254900'),
	'identifierSchemeCodeUnspsc190501.85254901': __('identifierSchemeCodeUnspsc190501.85254901'),
	'identifierSchemeCodeUnspsc190501.85254902': __('identifierSchemeCodeUnspsc190501.85254902'),
	'identifierSchemeCodeUnspsc190501.85254903': __('identifierSchemeCodeUnspsc190501.85254903'),
	'identifierSchemeCodeUnspsc190501.85254904': __('identifierSchemeCodeUnspsc190501.85254904'),
	'identifierSchemeCodeUnspsc190501.85254905': __('identifierSchemeCodeUnspsc190501.85254905'),
	'identifierSchemeCodeUnspsc190501.85254906': __('identifierSchemeCodeUnspsc190501.85254906'),
	'identifierSchemeCodeUnspsc190501.85254907': __('identifierSchemeCodeUnspsc190501.85254907'),
	'identifierSchemeCodeUnspsc190501.85255000': __('identifierSchemeCodeUnspsc190501.85255000'),
	'identifierSchemeCodeUnspsc190501.85255001': __('identifierSchemeCodeUnspsc190501.85255001'),
	'identifierSchemeCodeUnspsc190501.85255002': __('identifierSchemeCodeUnspsc190501.85255002'),
	'identifierSchemeCodeUnspsc190501.85255003': __('identifierSchemeCodeUnspsc190501.85255003'),
	'identifierSchemeCodeUnspsc190501.85255100': __('identifierSchemeCodeUnspsc190501.85255100'),
	'identifierSchemeCodeUnspsc190501.85255101': __('identifierSchemeCodeUnspsc190501.85255101'),
	'identifierSchemeCodeUnspsc190501.85255102': __('identifierSchemeCodeUnspsc190501.85255102'),
	'identifierSchemeCodeUnspsc190501.85255200': __('identifierSchemeCodeUnspsc190501.85255200'),
	'identifierSchemeCodeUnspsc190501.85255201': __('identifierSchemeCodeUnspsc190501.85255201'),
	'identifierSchemeCodeUnspsc190501.85255202': __('identifierSchemeCodeUnspsc190501.85255202'),
	'identifierSchemeCodeUnspsc190501.85255203': __('identifierSchemeCodeUnspsc190501.85255203'),
	'identifierSchemeCodeUnspsc190501.85255300': __('identifierSchemeCodeUnspsc190501.85255300'),
	'identifierSchemeCodeUnspsc190501.85255301': __('identifierSchemeCodeUnspsc190501.85255301'),
	'identifierSchemeCodeUnspsc190501.85255302': __('identifierSchemeCodeUnspsc190501.85255302'),
	'identifierSchemeCodeUnspsc190501.85255303': __('identifierSchemeCodeUnspsc190501.85255303'),
	'identifierSchemeCodeUnspsc190501.85255304': __('identifierSchemeCodeUnspsc190501.85255304'),
	'identifierSchemeCodeUnspsc190501.85255305': __('identifierSchemeCodeUnspsc190501.85255305'),
	'identifierSchemeCodeUnspsc190501.85255400': __('identifierSchemeCodeUnspsc190501.85255400'),
	'identifierSchemeCodeUnspsc190501.85255401': __('identifierSchemeCodeUnspsc190501.85255401'),
	'identifierSchemeCodeUnspsc190501.85255402': __('identifierSchemeCodeUnspsc190501.85255402'),
	'identifierSchemeCodeUnspsc190501.85255403': __('identifierSchemeCodeUnspsc190501.85255403'),
	'identifierSchemeCodeUnspsc190501.85255404': __('identifierSchemeCodeUnspsc190501.85255404'),
	'identifierSchemeCodeUnspsc190501.85255405': __('identifierSchemeCodeUnspsc190501.85255405'),
	'identifierSchemeCodeUnspsc190501.85255500': __('identifierSchemeCodeUnspsc190501.85255500'),
	'identifierSchemeCodeUnspsc190501.85255501': __('identifierSchemeCodeUnspsc190501.85255501'),
	'identifierSchemeCodeUnspsc190501.85255502': __('identifierSchemeCodeUnspsc190501.85255502'),
	'identifierSchemeCodeUnspsc190501.85255503': __('identifierSchemeCodeUnspsc190501.85255503'),
	'identifierSchemeCodeUnspsc190501.85255504': __('identifierSchemeCodeUnspsc190501.85255504'),
	'identifierSchemeCodeUnspsc190501.85255505': __('identifierSchemeCodeUnspsc190501.85255505'),
	'identifierSchemeCodeUnspsc190501.85255506': __('identifierSchemeCodeUnspsc190501.85255506'),
	'identifierSchemeCodeUnspsc190501.85255600': __('identifierSchemeCodeUnspsc190501.85255600'),
	'identifierSchemeCodeUnspsc190501.85255601': __('identifierSchemeCodeUnspsc190501.85255601'),
	'identifierSchemeCodeUnspsc190501.85255602': __('identifierSchemeCodeUnspsc190501.85255602'),
	'identifierSchemeCodeUnspsc190501.85255700': __('identifierSchemeCodeUnspsc190501.85255700'),
	'identifierSchemeCodeUnspsc190501.85255701': __('identifierSchemeCodeUnspsc190501.85255701'),
	'identifierSchemeCodeUnspsc190501.85255702': __('identifierSchemeCodeUnspsc190501.85255702'),
	'identifierSchemeCodeUnspsc190501.85255703': __('identifierSchemeCodeUnspsc190501.85255703'),
	'identifierSchemeCodeUnspsc190501.85255704': __('identifierSchemeCodeUnspsc190501.85255704'),
	'identifierSchemeCodeUnspsc190501.85255800': __('identifierSchemeCodeUnspsc190501.85255800'),
	'identifierSchemeCodeUnspsc190501.85255801': __('identifierSchemeCodeUnspsc190501.85255801'),
	'identifierSchemeCodeUnspsc190501.85255802': __('identifierSchemeCodeUnspsc190501.85255802'),
	'identifierSchemeCodeUnspsc190501.85255803': __('identifierSchemeCodeUnspsc190501.85255803'),
	'identifierSchemeCodeUnspsc190501.85255804': __('identifierSchemeCodeUnspsc190501.85255804'),
	'identifierSchemeCodeUnspsc190501.85255805': __('identifierSchemeCodeUnspsc190501.85255805'),
	'identifierSchemeCodeUnspsc190501.85255806': __('identifierSchemeCodeUnspsc190501.85255806'),
	'identifierSchemeCodeUnspsc190501.85255807': __('identifierSchemeCodeUnspsc190501.85255807'),
	'identifierSchemeCodeUnspsc190501.85255808': __('identifierSchemeCodeUnspsc190501.85255808'),
	'identifierSchemeCodeUnspsc190501.85255900': __('identifierSchemeCodeUnspsc190501.85255900'),
	'identifierSchemeCodeUnspsc190501.85255901': __('identifierSchemeCodeUnspsc190501.85255901'),
	'identifierSchemeCodeUnspsc190501.85255902': __('identifierSchemeCodeUnspsc190501.85255902'),
	'identifierSchemeCodeUnspsc190501.85256000': __('identifierSchemeCodeUnspsc190501.85256000'),
	'identifierSchemeCodeUnspsc190501.85256001': __('identifierSchemeCodeUnspsc190501.85256001'),
	'identifierSchemeCodeUnspsc190501.85256002': __('identifierSchemeCodeUnspsc190501.85256002'),
	'identifierSchemeCodeUnspsc190501.85256003': __('identifierSchemeCodeUnspsc190501.85256003'),
	'identifierSchemeCodeUnspsc190501.85256004': __('identifierSchemeCodeUnspsc190501.85256004'),
	'identifierSchemeCodeUnspsc190501.85256005': __('identifierSchemeCodeUnspsc190501.85256005'),
	'identifierSchemeCodeUnspsc190501.85256006': __('identifierSchemeCodeUnspsc190501.85256006'),
	'identifierSchemeCodeUnspsc190501.85256007': __('identifierSchemeCodeUnspsc190501.85256007'),
	'identifierSchemeCodeUnspsc190501.85256100': __('identifierSchemeCodeUnspsc190501.85256100'),
	'identifierSchemeCodeUnspsc190501.85256101': __('identifierSchemeCodeUnspsc190501.85256101'),
	'identifierSchemeCodeUnspsc190501.85256102': __('identifierSchemeCodeUnspsc190501.85256102'),
	'identifierSchemeCodeUnspsc190501.85256103': __('identifierSchemeCodeUnspsc190501.85256103'),
	'identifierSchemeCodeUnspsc190501.85256104': __('identifierSchemeCodeUnspsc190501.85256104'),
	'identifierSchemeCodeUnspsc190501.85256105': __('identifierSchemeCodeUnspsc190501.85256105'),
	'identifierSchemeCodeUnspsc190501.85256200': __('identifierSchemeCodeUnspsc190501.85256200'),
	'identifierSchemeCodeUnspsc190501.85256201': __('identifierSchemeCodeUnspsc190501.85256201'),
	'identifierSchemeCodeUnspsc190501.85256202': __('identifierSchemeCodeUnspsc190501.85256202'),
	'identifierSchemeCodeUnspsc190501.85256203': __('identifierSchemeCodeUnspsc190501.85256203'),
	'identifierSchemeCodeUnspsc190501.85256300': __('identifierSchemeCodeUnspsc190501.85256300'),
	'identifierSchemeCodeUnspsc190501.85256301': __('identifierSchemeCodeUnspsc190501.85256301'),
	'identifierSchemeCodeUnspsc190501.85256302': __('identifierSchemeCodeUnspsc190501.85256302'),
	'identifierSchemeCodeUnspsc190501.85256400': __('identifierSchemeCodeUnspsc190501.85256400'),
	'identifierSchemeCodeUnspsc190501.85256401': __('identifierSchemeCodeUnspsc190501.85256401'),
	'identifierSchemeCodeUnspsc190501.85256402': __('identifierSchemeCodeUnspsc190501.85256402'),
	'identifierSchemeCodeUnspsc190501.85256403': __('identifierSchemeCodeUnspsc190501.85256403'),
	'identifierSchemeCodeUnspsc190501.85256404': __('identifierSchemeCodeUnspsc190501.85256404'),
	'identifierSchemeCodeUnspsc190501.85256405': __('identifierSchemeCodeUnspsc190501.85256405'),
	'identifierSchemeCodeUnspsc190501.85256500': __('identifierSchemeCodeUnspsc190501.85256500'),
	'identifierSchemeCodeUnspsc190501.85256501': __('identifierSchemeCodeUnspsc190501.85256501'),
	'identifierSchemeCodeUnspsc190501.85256502': __('identifierSchemeCodeUnspsc190501.85256502'),
	'identifierSchemeCodeUnspsc190501.85256600': __('identifierSchemeCodeUnspsc190501.85256600'),
	'identifierSchemeCodeUnspsc190501.85256601': __('identifierSchemeCodeUnspsc190501.85256601'),
	'identifierSchemeCodeUnspsc190501.85256602': __('identifierSchemeCodeUnspsc190501.85256602'),
	'identifierSchemeCodeUnspsc190501.85256603': __('identifierSchemeCodeUnspsc190501.85256603'),
	'identifierSchemeCodeUnspsc190501.85256604': __('identifierSchemeCodeUnspsc190501.85256604'),
	'identifierSchemeCodeUnspsc190501.85256700': __('identifierSchemeCodeUnspsc190501.85256700'),
	'identifierSchemeCodeUnspsc190501.85256701': __('identifierSchemeCodeUnspsc190501.85256701'),
	'identifierSchemeCodeUnspsc190501.85256702': __('identifierSchemeCodeUnspsc190501.85256702'),
	'identifierSchemeCodeUnspsc190501.85256703': __('identifierSchemeCodeUnspsc190501.85256703'),
	'identifierSchemeCodeUnspsc190501.85256704': __('identifierSchemeCodeUnspsc190501.85256704'),
	'identifierSchemeCodeUnspsc190501.85256705': __('identifierSchemeCodeUnspsc190501.85256705'),
	'identifierSchemeCodeUnspsc190501.85256706': __('identifierSchemeCodeUnspsc190501.85256706'),
	'identifierSchemeCodeUnspsc190501.85256707': __('identifierSchemeCodeUnspsc190501.85256707'),
	'identifierSchemeCodeUnspsc190501.85256708': __('identifierSchemeCodeUnspsc190501.85256708'),
	'identifierSchemeCodeUnspsc190501.85256800': __('identifierSchemeCodeUnspsc190501.85256800'),
	'identifierSchemeCodeUnspsc190501.85256801': __('identifierSchemeCodeUnspsc190501.85256801'),
	'identifierSchemeCodeUnspsc190501.85256802': __('identifierSchemeCodeUnspsc190501.85256802'),
	'identifierSchemeCodeUnspsc190501.85256803': __('identifierSchemeCodeUnspsc190501.85256803'),
	'identifierSchemeCodeUnspsc190501.85256804': __('identifierSchemeCodeUnspsc190501.85256804'),
	'identifierSchemeCodeUnspsc190501.85256805': __('identifierSchemeCodeUnspsc190501.85256805'),
	'identifierSchemeCodeUnspsc190501.85256900': __('identifierSchemeCodeUnspsc190501.85256900'),
	'identifierSchemeCodeUnspsc190501.85256901': __('identifierSchemeCodeUnspsc190501.85256901'),
	'identifierSchemeCodeUnspsc190501.85256902': __('identifierSchemeCodeUnspsc190501.85256902'),
	'identifierSchemeCodeUnspsc190501.85256903': __('identifierSchemeCodeUnspsc190501.85256903'),
	'identifierSchemeCodeUnspsc190501.85256904': __('identifierSchemeCodeUnspsc190501.85256904'),
	'identifierSchemeCodeUnspsc190501.85256905': __('identifierSchemeCodeUnspsc190501.85256905'),
	'identifierSchemeCodeUnspsc190501.85256906': __('identifierSchemeCodeUnspsc190501.85256906'),
	'identifierSchemeCodeUnspsc190501.85257000': __('identifierSchemeCodeUnspsc190501.85257000'),
	'identifierSchemeCodeUnspsc190501.85257001': __('identifierSchemeCodeUnspsc190501.85257001'),
	'identifierSchemeCodeUnspsc190501.85257002': __('identifierSchemeCodeUnspsc190501.85257002'),
	'identifierSchemeCodeUnspsc190501.85257003': __('identifierSchemeCodeUnspsc190501.85257003'),
	'identifierSchemeCodeUnspsc190501.85257004': __('identifierSchemeCodeUnspsc190501.85257004'),
	'identifierSchemeCodeUnspsc190501.85257005': __('identifierSchemeCodeUnspsc190501.85257005'),
	'identifierSchemeCodeUnspsc190501.85257006': __('identifierSchemeCodeUnspsc190501.85257006'),
	'identifierSchemeCodeUnspsc190501.85260000': __('identifierSchemeCodeUnspsc190501.85260000'),
	'identifierSchemeCodeUnspsc190501.85261500': __('identifierSchemeCodeUnspsc190501.85261500'),
	'identifierSchemeCodeUnspsc190501.85261501': __('identifierSchemeCodeUnspsc190501.85261501'),
	'identifierSchemeCodeUnspsc190501.85261502': __('identifierSchemeCodeUnspsc190501.85261502'),
	'identifierSchemeCodeUnspsc190501.85261503': __('identifierSchemeCodeUnspsc190501.85261503'),
	'identifierSchemeCodeUnspsc190501.85261600': __('identifierSchemeCodeUnspsc190501.85261600'),
	'identifierSchemeCodeUnspsc190501.85261601': __('identifierSchemeCodeUnspsc190501.85261601'),
	'identifierSchemeCodeUnspsc190501.85261602': __('identifierSchemeCodeUnspsc190501.85261602'),
	'identifierSchemeCodeUnspsc190501.85261700': __('identifierSchemeCodeUnspsc190501.85261700'),
	'identifierSchemeCodeUnspsc190501.85261701': __('identifierSchemeCodeUnspsc190501.85261701'),
	'identifierSchemeCodeUnspsc190501.85261702': __('identifierSchemeCodeUnspsc190501.85261702'),
	'identifierSchemeCodeUnspsc190501.85261703': __('identifierSchemeCodeUnspsc190501.85261703'),
	'identifierSchemeCodeUnspsc190501.85261704': __('identifierSchemeCodeUnspsc190501.85261704'),
	'identifierSchemeCodeUnspsc190501.85261705': __('identifierSchemeCodeUnspsc190501.85261705'),
	'identifierSchemeCodeUnspsc190501.85261706': __('identifierSchemeCodeUnspsc190501.85261706'),
	'identifierSchemeCodeUnspsc190501.85261800': __('identifierSchemeCodeUnspsc190501.85261800'),
	'identifierSchemeCodeUnspsc190501.85261801': __('identifierSchemeCodeUnspsc190501.85261801'),
	'identifierSchemeCodeUnspsc190501.85261802': __('identifierSchemeCodeUnspsc190501.85261802'),
	'identifierSchemeCodeUnspsc190501.85261803': __('identifierSchemeCodeUnspsc190501.85261803'),
	'identifierSchemeCodeUnspsc190501.85261900': __('identifierSchemeCodeUnspsc190501.85261900'),
	'identifierSchemeCodeUnspsc190501.85261901': __('identifierSchemeCodeUnspsc190501.85261901'),
	'identifierSchemeCodeUnspsc190501.85261902': __('identifierSchemeCodeUnspsc190501.85261902'),
	'identifierSchemeCodeUnspsc190501.85261903': __('identifierSchemeCodeUnspsc190501.85261903'),
	'identifierSchemeCodeUnspsc190501.85261904': __('identifierSchemeCodeUnspsc190501.85261904'),
	'identifierSchemeCodeUnspsc190501.85261905': __('identifierSchemeCodeUnspsc190501.85261905'),
	'identifierSchemeCodeUnspsc190501.85261906': __('identifierSchemeCodeUnspsc190501.85261906'),
	'identifierSchemeCodeUnspsc190501.85262000': __('identifierSchemeCodeUnspsc190501.85262000'),
	'identifierSchemeCodeUnspsc190501.85262001': __('identifierSchemeCodeUnspsc190501.85262001'),
	'identifierSchemeCodeUnspsc190501.85262002': __('identifierSchemeCodeUnspsc190501.85262002'),
	'identifierSchemeCodeUnspsc190501.85262003': __('identifierSchemeCodeUnspsc190501.85262003'),
	'identifierSchemeCodeUnspsc190501.85262004': __('identifierSchemeCodeUnspsc190501.85262004'),
	'identifierSchemeCodeUnspsc190501.85262005': __('identifierSchemeCodeUnspsc190501.85262005'),
	'identifierSchemeCodeUnspsc190501.85262100': __('identifierSchemeCodeUnspsc190501.85262100'),
	'identifierSchemeCodeUnspsc190501.85262101': __('identifierSchemeCodeUnspsc190501.85262101'),
	'identifierSchemeCodeUnspsc190501.85262102': __('identifierSchemeCodeUnspsc190501.85262102'),
	'identifierSchemeCodeUnspsc190501.85262200': __('identifierSchemeCodeUnspsc190501.85262200'),
	'identifierSchemeCodeUnspsc190501.85262201': __('identifierSchemeCodeUnspsc190501.85262201'),
	'identifierSchemeCodeUnspsc190501.85262202': __('identifierSchemeCodeUnspsc190501.85262202'),
	'identifierSchemeCodeUnspsc190501.85262203': __('identifierSchemeCodeUnspsc190501.85262203'),
	'identifierSchemeCodeUnspsc190501.85262204': __('identifierSchemeCodeUnspsc190501.85262204'),
	'identifierSchemeCodeUnspsc190501.85262205': __('identifierSchemeCodeUnspsc190501.85262205'),
	'identifierSchemeCodeUnspsc190501.85262206': __('identifierSchemeCodeUnspsc190501.85262206'),
	'identifierSchemeCodeUnspsc190501.85262207': __('identifierSchemeCodeUnspsc190501.85262207'),
	'identifierSchemeCodeUnspsc190501.85262208': __('identifierSchemeCodeUnspsc190501.85262208'),
	'identifierSchemeCodeUnspsc190501.85262300': __('identifierSchemeCodeUnspsc190501.85262300'),
	'identifierSchemeCodeUnspsc190501.85262301': __('identifierSchemeCodeUnspsc190501.85262301'),
	'identifierSchemeCodeUnspsc190501.85262302': __('identifierSchemeCodeUnspsc190501.85262302'),
	'identifierSchemeCodeUnspsc190501.85262303': __('identifierSchemeCodeUnspsc190501.85262303'),
	'identifierSchemeCodeUnspsc190501.85262304': __('identifierSchemeCodeUnspsc190501.85262304'),
	'identifierSchemeCodeUnspsc190501.85262305': __('identifierSchemeCodeUnspsc190501.85262305'),
	'identifierSchemeCodeUnspsc190501.85262306': __('identifierSchemeCodeUnspsc190501.85262306'),
	'identifierSchemeCodeUnspsc190501.85262307': __('identifierSchemeCodeUnspsc190501.85262307'),
	'identifierSchemeCodeUnspsc190501.85262308': __('identifierSchemeCodeUnspsc190501.85262308'),
	'identifierSchemeCodeUnspsc190501.85262400': __('identifierSchemeCodeUnspsc190501.85262400'),
	'identifierSchemeCodeUnspsc190501.85262401': __('identifierSchemeCodeUnspsc190501.85262401'),
	'identifierSchemeCodeUnspsc190501.85262402': __('identifierSchemeCodeUnspsc190501.85262402'),
	'identifierSchemeCodeUnspsc190501.85262403': __('identifierSchemeCodeUnspsc190501.85262403'),
	'identifierSchemeCodeUnspsc190501.85262404': __('identifierSchemeCodeUnspsc190501.85262404'),
	'identifierSchemeCodeUnspsc190501.85262405': __('identifierSchemeCodeUnspsc190501.85262405'),
	'identifierSchemeCodeUnspsc190501.85262406': __('identifierSchemeCodeUnspsc190501.85262406'),
	'identifierSchemeCodeUnspsc190501.85262407': __('identifierSchemeCodeUnspsc190501.85262407'),
	'identifierSchemeCodeUnspsc190501.85262408': __('identifierSchemeCodeUnspsc190501.85262408'),
	'identifierSchemeCodeUnspsc190501.85262500': __('identifierSchemeCodeUnspsc190501.85262500'),
	'identifierSchemeCodeUnspsc190501.85262501': __('identifierSchemeCodeUnspsc190501.85262501'),
	'identifierSchemeCodeUnspsc190501.85262502': __('identifierSchemeCodeUnspsc190501.85262502'),
	'identifierSchemeCodeUnspsc190501.85262503': __('identifierSchemeCodeUnspsc190501.85262503'),
	'identifierSchemeCodeUnspsc190501.85262600': __('identifierSchemeCodeUnspsc190501.85262600'),
	'identifierSchemeCodeUnspsc190501.85262601': __('identifierSchemeCodeUnspsc190501.85262601'),
	'identifierSchemeCodeUnspsc190501.85262602': __('identifierSchemeCodeUnspsc190501.85262602'),
	'identifierSchemeCodeUnspsc190501.85262603': __('identifierSchemeCodeUnspsc190501.85262603'),
	'identifierSchemeCodeUnspsc190501.85262700': __('identifierSchemeCodeUnspsc190501.85262700'),
	'identifierSchemeCodeUnspsc190501.85262701': __('identifierSchemeCodeUnspsc190501.85262701'),
	'identifierSchemeCodeUnspsc190501.85262702': __('identifierSchemeCodeUnspsc190501.85262702'),
	'identifierSchemeCodeUnspsc190501.85262703': __('identifierSchemeCodeUnspsc190501.85262703'),
	'identifierSchemeCodeUnspsc190501.85262800': __('identifierSchemeCodeUnspsc190501.85262800'),
	'identifierSchemeCodeUnspsc190501.85262801': __('identifierSchemeCodeUnspsc190501.85262801'),
	'identifierSchemeCodeUnspsc190501.85262802': __('identifierSchemeCodeUnspsc190501.85262802'),
	'identifierSchemeCodeUnspsc190501.85262803': __('identifierSchemeCodeUnspsc190501.85262803'),
	'identifierSchemeCodeUnspsc190501.85262900': __('identifierSchemeCodeUnspsc190501.85262900'),
	'identifierSchemeCodeUnspsc190501.85262901': __('identifierSchemeCodeUnspsc190501.85262901'),
	'identifierSchemeCodeUnspsc190501.85262902': __('identifierSchemeCodeUnspsc190501.85262902'),
	'identifierSchemeCodeUnspsc190501.85262903': __('identifierSchemeCodeUnspsc190501.85262903'),
	'identifierSchemeCodeUnspsc190501.85262904': __('identifierSchemeCodeUnspsc190501.85262904'),
	'identifierSchemeCodeUnspsc190501.85262905': __('identifierSchemeCodeUnspsc190501.85262905'),
	'identifierSchemeCodeUnspsc190501.85263000': __('identifierSchemeCodeUnspsc190501.85263000'),
	'identifierSchemeCodeUnspsc190501.85263001': __('identifierSchemeCodeUnspsc190501.85263001'),
	'identifierSchemeCodeUnspsc190501.85263002': __('identifierSchemeCodeUnspsc190501.85263002'),
	'identifierSchemeCodeUnspsc190501.85263100': __('identifierSchemeCodeUnspsc190501.85263100'),
	'identifierSchemeCodeUnspsc190501.85263101': __('identifierSchemeCodeUnspsc190501.85263101'),
	'identifierSchemeCodeUnspsc190501.85263102': __('identifierSchemeCodeUnspsc190501.85263102'),
	'identifierSchemeCodeUnspsc190501.85263103': __('identifierSchemeCodeUnspsc190501.85263103'),
	'identifierSchemeCodeUnspsc190501.85263104': __('identifierSchemeCodeUnspsc190501.85263104'),
	'identifierSchemeCodeUnspsc190501.85263200': __('identifierSchemeCodeUnspsc190501.85263200'),
	'identifierSchemeCodeUnspsc190501.85263201': __('identifierSchemeCodeUnspsc190501.85263201'),
	'identifierSchemeCodeUnspsc190501.85263202': __('identifierSchemeCodeUnspsc190501.85263202'),
	'identifierSchemeCodeUnspsc190501.85263203': __('identifierSchemeCodeUnspsc190501.85263203'),
	'identifierSchemeCodeUnspsc190501.85263204': __('identifierSchemeCodeUnspsc190501.85263204'),
	'identifierSchemeCodeUnspsc190501.85263300': __('identifierSchemeCodeUnspsc190501.85263300'),
	'identifierSchemeCodeUnspsc190501.85263301': __('identifierSchemeCodeUnspsc190501.85263301'),
	'identifierSchemeCodeUnspsc190501.85263302': __('identifierSchemeCodeUnspsc190501.85263302'),
	'identifierSchemeCodeUnspsc190501.85263400': __('identifierSchemeCodeUnspsc190501.85263400'),
	'identifierSchemeCodeUnspsc190501.85263401': __('identifierSchemeCodeUnspsc190501.85263401'),
	'identifierSchemeCodeUnspsc190501.85263402': __('identifierSchemeCodeUnspsc190501.85263402'),
	'identifierSchemeCodeUnspsc190501.85263500': __('identifierSchemeCodeUnspsc190501.85263500'),
	'identifierSchemeCodeUnspsc190501.85263501': __('identifierSchemeCodeUnspsc190501.85263501'),
	'identifierSchemeCodeUnspsc190501.85263502': __('identifierSchemeCodeUnspsc190501.85263502'),
	'identifierSchemeCodeUnspsc190501.85263600': __('identifierSchemeCodeUnspsc190501.85263600'),
	'identifierSchemeCodeUnspsc190501.85263601': __('identifierSchemeCodeUnspsc190501.85263601'),
	'identifierSchemeCodeUnspsc190501.85263602': __('identifierSchemeCodeUnspsc190501.85263602'),
	'identifierSchemeCodeUnspsc190501.85263700': __('identifierSchemeCodeUnspsc190501.85263700'),
	'identifierSchemeCodeUnspsc190501.85263701': __('identifierSchemeCodeUnspsc190501.85263701'),
	'identifierSchemeCodeUnspsc190501.85263702': __('identifierSchemeCodeUnspsc190501.85263702'),
	'identifierSchemeCodeUnspsc190501.85263703': __('identifierSchemeCodeUnspsc190501.85263703'),
	'identifierSchemeCodeUnspsc190501.85263800': __('identifierSchemeCodeUnspsc190501.85263800'),
	'identifierSchemeCodeUnspsc190501.85263801': __('identifierSchemeCodeUnspsc190501.85263801'),
	'identifierSchemeCodeUnspsc190501.85263802': __('identifierSchemeCodeUnspsc190501.85263802'),
	'identifierSchemeCodeUnspsc190501.85263900': __('identifierSchemeCodeUnspsc190501.85263900'),
	'identifierSchemeCodeUnspsc190501.85263901': __('identifierSchemeCodeUnspsc190501.85263901'),
	'identifierSchemeCodeUnspsc190501.85263902': __('identifierSchemeCodeUnspsc190501.85263902'),
	'identifierSchemeCodeUnspsc190501.85263903': __('identifierSchemeCodeUnspsc190501.85263903'),
	'identifierSchemeCodeUnspsc190501.85264000': __('identifierSchemeCodeUnspsc190501.85264000'),
	'identifierSchemeCodeUnspsc190501.85264001': __('identifierSchemeCodeUnspsc190501.85264001'),
	'identifierSchemeCodeUnspsc190501.85264002': __('identifierSchemeCodeUnspsc190501.85264002'),
	'identifierSchemeCodeUnspsc190501.85264003': __('identifierSchemeCodeUnspsc190501.85264003'),
	'identifierSchemeCodeUnspsc190501.85264100': __('identifierSchemeCodeUnspsc190501.85264100'),
	'identifierSchemeCodeUnspsc190501.85264101': __('identifierSchemeCodeUnspsc190501.85264101'),
	'identifierSchemeCodeUnspsc190501.85264102': __('identifierSchemeCodeUnspsc190501.85264102'),
	'identifierSchemeCodeUnspsc190501.85264103': __('identifierSchemeCodeUnspsc190501.85264103'),
	'identifierSchemeCodeUnspsc190501.85264104': __('identifierSchemeCodeUnspsc190501.85264104'),
	'identifierSchemeCodeUnspsc190501.85264105': __('identifierSchemeCodeUnspsc190501.85264105'),
	'identifierSchemeCodeUnspsc190501.85264106': __('identifierSchemeCodeUnspsc190501.85264106'),
	'identifierSchemeCodeUnspsc190501.85264107': __('identifierSchemeCodeUnspsc190501.85264107'),
	'identifierSchemeCodeUnspsc190501.85264200': __('identifierSchemeCodeUnspsc190501.85264200'),
	'identifierSchemeCodeUnspsc190501.85264201': __('identifierSchemeCodeUnspsc190501.85264201'),
	'identifierSchemeCodeUnspsc190501.85264202': __('identifierSchemeCodeUnspsc190501.85264202'),
	'identifierSchemeCodeUnspsc190501.85264300': __('identifierSchemeCodeUnspsc190501.85264300'),
	'identifierSchemeCodeUnspsc190501.85264301': __('identifierSchemeCodeUnspsc190501.85264301'),
	'identifierSchemeCodeUnspsc190501.85264302': __('identifierSchemeCodeUnspsc190501.85264302'),
	'identifierSchemeCodeUnspsc190501.85264303': __('identifierSchemeCodeUnspsc190501.85264303'),
	'identifierSchemeCodeUnspsc190501.85264304': __('identifierSchemeCodeUnspsc190501.85264304'),
	'identifierSchemeCodeUnspsc190501.85264305': __('identifierSchemeCodeUnspsc190501.85264305'),
	'identifierSchemeCodeUnspsc190501.85264306': __('identifierSchemeCodeUnspsc190501.85264306'),
	'identifierSchemeCodeUnspsc190501.85264307': __('identifierSchemeCodeUnspsc190501.85264307'),
	'identifierSchemeCodeUnspsc190501.85264308': __('identifierSchemeCodeUnspsc190501.85264308'),
	'identifierSchemeCodeUnspsc190501.85264400': __('identifierSchemeCodeUnspsc190501.85264400'),
	'identifierSchemeCodeUnspsc190501.85264401': __('identifierSchemeCodeUnspsc190501.85264401'),
	'identifierSchemeCodeUnspsc190501.85264402': __('identifierSchemeCodeUnspsc190501.85264402'),
	'identifierSchemeCodeUnspsc190501.85264403': __('identifierSchemeCodeUnspsc190501.85264403'),
	'identifierSchemeCodeUnspsc190501.85264404': __('identifierSchemeCodeUnspsc190501.85264404'),
	'identifierSchemeCodeUnspsc190501.85264405': __('identifierSchemeCodeUnspsc190501.85264405'),
	'identifierSchemeCodeUnspsc190501.85264406': __('identifierSchemeCodeUnspsc190501.85264406'),
	'identifierSchemeCodeUnspsc190501.85264407': __('identifierSchemeCodeUnspsc190501.85264407'),
	'identifierSchemeCodeUnspsc190501.85264408': __('identifierSchemeCodeUnspsc190501.85264408'),
	'identifierSchemeCodeUnspsc190501.85264409': __('identifierSchemeCodeUnspsc190501.85264409'),
	'identifierSchemeCodeUnspsc190501.85264410': __('identifierSchemeCodeUnspsc190501.85264410'),
	'identifierSchemeCodeUnspsc190501.85264411': __('identifierSchemeCodeUnspsc190501.85264411'),
	'identifierSchemeCodeUnspsc190501.85264500': __('identifierSchemeCodeUnspsc190501.85264500'),
	'identifierSchemeCodeUnspsc190501.85264501': __('identifierSchemeCodeUnspsc190501.85264501'),
	'identifierSchemeCodeUnspsc190501.85264502': __('identifierSchemeCodeUnspsc190501.85264502'),
	'identifierSchemeCodeUnspsc190501.85264600': __('identifierSchemeCodeUnspsc190501.85264600'),
	'identifierSchemeCodeUnspsc190501.85264601': __('identifierSchemeCodeUnspsc190501.85264601'),
	'identifierSchemeCodeUnspsc190501.85264602': __('identifierSchemeCodeUnspsc190501.85264602'),
	'identifierSchemeCodeUnspsc190501.85264603': __('identifierSchemeCodeUnspsc190501.85264603'),
	'identifierSchemeCodeUnspsc190501.85264604': __('identifierSchemeCodeUnspsc190501.85264604'),
	'identifierSchemeCodeUnspsc190501.85264700': __('identifierSchemeCodeUnspsc190501.85264700'),
	'identifierSchemeCodeUnspsc190501.85264701': __('identifierSchemeCodeUnspsc190501.85264701'),
	'identifierSchemeCodeUnspsc190501.85264702': __('identifierSchemeCodeUnspsc190501.85264702'),
	'identifierSchemeCodeUnspsc190501.85264703': __('identifierSchemeCodeUnspsc190501.85264703'),
	'identifierSchemeCodeUnspsc190501.85264800': __('identifierSchemeCodeUnspsc190501.85264800'),
	'identifierSchemeCodeUnspsc190501.85264801': __('identifierSchemeCodeUnspsc190501.85264801'),
	'identifierSchemeCodeUnspsc190501.85264802': __('identifierSchemeCodeUnspsc190501.85264802'),
	'identifierSchemeCodeUnspsc190501.85264803': __('identifierSchemeCodeUnspsc190501.85264803'),
	'identifierSchemeCodeUnspsc190501.85264804': __('identifierSchemeCodeUnspsc190501.85264804'),
	'identifierSchemeCodeUnspsc190501.85264805': __('identifierSchemeCodeUnspsc190501.85264805'),
	'identifierSchemeCodeUnspsc190501.85264900': __('identifierSchemeCodeUnspsc190501.85264900'),
	'identifierSchemeCodeUnspsc190501.85264901': __('identifierSchemeCodeUnspsc190501.85264901'),
	'identifierSchemeCodeUnspsc190501.85264902': __('identifierSchemeCodeUnspsc190501.85264902'),
	'identifierSchemeCodeUnspsc190501.85264903': __('identifierSchemeCodeUnspsc190501.85264903'),
	'identifierSchemeCodeUnspsc190501.85264904': __('identifierSchemeCodeUnspsc190501.85264904'),
	'identifierSchemeCodeUnspsc190501.85264905': __('identifierSchemeCodeUnspsc190501.85264905'),
	'identifierSchemeCodeUnspsc190501.85264906': __('identifierSchemeCodeUnspsc190501.85264906'),
	'identifierSchemeCodeUnspsc190501.85264907': __('identifierSchemeCodeUnspsc190501.85264907'),
	'identifierSchemeCodeUnspsc190501.85264908': __('identifierSchemeCodeUnspsc190501.85264908'),
	'identifierSchemeCodeUnspsc190501.85264909': __('identifierSchemeCodeUnspsc190501.85264909'),
	'identifierSchemeCodeUnspsc190501.85265000': __('identifierSchemeCodeUnspsc190501.85265000'),
	'identifierSchemeCodeUnspsc190501.85265001': __('identifierSchemeCodeUnspsc190501.85265001'),
	'identifierSchemeCodeUnspsc190501.85265002': __('identifierSchemeCodeUnspsc190501.85265002'),
	'identifierSchemeCodeUnspsc190501.85265100': __('identifierSchemeCodeUnspsc190501.85265100'),
	'identifierSchemeCodeUnspsc190501.85265101': __('identifierSchemeCodeUnspsc190501.85265101'),
	'identifierSchemeCodeUnspsc190501.85265102': __('identifierSchemeCodeUnspsc190501.85265102'),
	'identifierSchemeCodeUnspsc190501.85265200': __('identifierSchemeCodeUnspsc190501.85265200'),
	'identifierSchemeCodeUnspsc190501.85265201': __('identifierSchemeCodeUnspsc190501.85265201'),
	'identifierSchemeCodeUnspsc190501.85265202': __('identifierSchemeCodeUnspsc190501.85265202'),
	'identifierSchemeCodeUnspsc190501.85265203': __('identifierSchemeCodeUnspsc190501.85265203'),
	'identifierSchemeCodeUnspsc190501.85265204': __('identifierSchemeCodeUnspsc190501.85265204'),
	'identifierSchemeCodeUnspsc190501.85265300': __('identifierSchemeCodeUnspsc190501.85265300'),
	'identifierSchemeCodeUnspsc190501.85265301': __('identifierSchemeCodeUnspsc190501.85265301'),
	'identifierSchemeCodeUnspsc190501.85265302': __('identifierSchemeCodeUnspsc190501.85265302'),
	'identifierSchemeCodeUnspsc190501.85265400': __('identifierSchemeCodeUnspsc190501.85265400'),
	'identifierSchemeCodeUnspsc190501.85265401': __('identifierSchemeCodeUnspsc190501.85265401'),
	'identifierSchemeCodeUnspsc190501.85265402': __('identifierSchemeCodeUnspsc190501.85265402'),
	'identifierSchemeCodeUnspsc190501.85265500': __('identifierSchemeCodeUnspsc190501.85265500'),
	'identifierSchemeCodeUnspsc190501.85265501': __('identifierSchemeCodeUnspsc190501.85265501'),
	'identifierSchemeCodeUnspsc190501.85265502': __('identifierSchemeCodeUnspsc190501.85265502'),
	'identifierSchemeCodeUnspsc190501.85265600': __('identifierSchemeCodeUnspsc190501.85265600'),
	'identifierSchemeCodeUnspsc190501.85265601': __('identifierSchemeCodeUnspsc190501.85265601'),
	'identifierSchemeCodeUnspsc190501.85265602': __('identifierSchemeCodeUnspsc190501.85265602'),
	'identifierSchemeCodeUnspsc190501.85265603': __('identifierSchemeCodeUnspsc190501.85265603'),
	'identifierSchemeCodeUnspsc190501.85265604': __('identifierSchemeCodeUnspsc190501.85265604'),
	'identifierSchemeCodeUnspsc190501.85265605': __('identifierSchemeCodeUnspsc190501.85265605'),
	'identifierSchemeCodeUnspsc190501.85265700': __('identifierSchemeCodeUnspsc190501.85265700'),
	'identifierSchemeCodeUnspsc190501.85265701': __('identifierSchemeCodeUnspsc190501.85265701'),
	'identifierSchemeCodeUnspsc190501.85265702': __('identifierSchemeCodeUnspsc190501.85265702'),
	'identifierSchemeCodeUnspsc190501.85265800': __('identifierSchemeCodeUnspsc190501.85265800'),
	'identifierSchemeCodeUnspsc190501.85265801': __('identifierSchemeCodeUnspsc190501.85265801'),
	'identifierSchemeCodeUnspsc190501.85265802': __('identifierSchemeCodeUnspsc190501.85265802'),
	'identifierSchemeCodeUnspsc190501.85265803': __('identifierSchemeCodeUnspsc190501.85265803'),
	'identifierSchemeCodeUnspsc190501.85265804': __('identifierSchemeCodeUnspsc190501.85265804'),
	'identifierSchemeCodeUnspsc190501.85265805': __('identifierSchemeCodeUnspsc190501.85265805'),
	'identifierSchemeCodeUnspsc190501.85265900': __('identifierSchemeCodeUnspsc190501.85265900'),
	'identifierSchemeCodeUnspsc190501.85265901': __('identifierSchemeCodeUnspsc190501.85265901'),
	'identifierSchemeCodeUnspsc190501.85265902': __('identifierSchemeCodeUnspsc190501.85265902'),
	'identifierSchemeCodeUnspsc190501.85265903': __('identifierSchemeCodeUnspsc190501.85265903'),
	'identifierSchemeCodeUnspsc190501.85265904': __('identifierSchemeCodeUnspsc190501.85265904'),
	'identifierSchemeCodeUnspsc190501.85265905': __('identifierSchemeCodeUnspsc190501.85265905'),
	'identifierSchemeCodeUnspsc190501.85265906': __('identifierSchemeCodeUnspsc190501.85265906'),
	'identifierSchemeCodeUnspsc190501.85266000': __('identifierSchemeCodeUnspsc190501.85266000'),
	'identifierSchemeCodeUnspsc190501.85266001': __('identifierSchemeCodeUnspsc190501.85266001'),
	'identifierSchemeCodeUnspsc190501.85266002': __('identifierSchemeCodeUnspsc190501.85266002'),
	'identifierSchemeCodeUnspsc190501.85266003': __('identifierSchemeCodeUnspsc190501.85266003'),
	'identifierSchemeCodeUnspsc190501.85266004': __('identifierSchemeCodeUnspsc190501.85266004'),
	'identifierSchemeCodeUnspsc190501.85266005': __('identifierSchemeCodeUnspsc190501.85266005'),
	'identifierSchemeCodeUnspsc190501.85266100': __('identifierSchemeCodeUnspsc190501.85266100'),
	'identifierSchemeCodeUnspsc190501.85266101': __('identifierSchemeCodeUnspsc190501.85266101'),
	'identifierSchemeCodeUnspsc190501.85266102': __('identifierSchemeCodeUnspsc190501.85266102'),
	'identifierSchemeCodeUnspsc190501.85266103': __('identifierSchemeCodeUnspsc190501.85266103'),
	'identifierSchemeCodeUnspsc190501.85266104': __('identifierSchemeCodeUnspsc190501.85266104'),
	'identifierSchemeCodeUnspsc190501.85266105': __('identifierSchemeCodeUnspsc190501.85266105'),
	'identifierSchemeCodeUnspsc190501.85266106': __('identifierSchemeCodeUnspsc190501.85266106'),
	'identifierSchemeCodeUnspsc190501.85266107': __('identifierSchemeCodeUnspsc190501.85266107'),
	'identifierSchemeCodeUnspsc190501.85266108': __('identifierSchemeCodeUnspsc190501.85266108'),
	'identifierSchemeCodeUnspsc190501.85266200': __('identifierSchemeCodeUnspsc190501.85266200'),
	'identifierSchemeCodeUnspsc190501.85266201': __('identifierSchemeCodeUnspsc190501.85266201'),
	'identifierSchemeCodeUnspsc190501.85266202': __('identifierSchemeCodeUnspsc190501.85266202'),
	'identifierSchemeCodeUnspsc190501.85266300': __('identifierSchemeCodeUnspsc190501.85266300'),
	'identifierSchemeCodeUnspsc190501.85266301': __('identifierSchemeCodeUnspsc190501.85266301'),
	'identifierSchemeCodeUnspsc190501.85266302': __('identifierSchemeCodeUnspsc190501.85266302'),
	'identifierSchemeCodeUnspsc190501.85266303': __('identifierSchemeCodeUnspsc190501.85266303'),
	'identifierSchemeCodeUnspsc190501.85266304': __('identifierSchemeCodeUnspsc190501.85266304'),
	'identifierSchemeCodeUnspsc190501.85266400': __('identifierSchemeCodeUnspsc190501.85266400'),
	'identifierSchemeCodeUnspsc190501.85266401': __('identifierSchemeCodeUnspsc190501.85266401'),
	'identifierSchemeCodeUnspsc190501.85266402': __('identifierSchemeCodeUnspsc190501.85266402'),
	'identifierSchemeCodeUnspsc190501.85266403': __('identifierSchemeCodeUnspsc190501.85266403'),
	'identifierSchemeCodeUnspsc190501.85270000': __('identifierSchemeCodeUnspsc190501.85270000'),
	'identifierSchemeCodeUnspsc190501.85271500': __('identifierSchemeCodeUnspsc190501.85271500'),
	'identifierSchemeCodeUnspsc190501.85271501': __('identifierSchemeCodeUnspsc190501.85271501'),
	'identifierSchemeCodeUnspsc190501.85271502': __('identifierSchemeCodeUnspsc190501.85271502'),
	'identifierSchemeCodeUnspsc190501.85271503': __('identifierSchemeCodeUnspsc190501.85271503'),
	'identifierSchemeCodeUnspsc190501.85271504': __('identifierSchemeCodeUnspsc190501.85271504'),
	'identifierSchemeCodeUnspsc190501.85271505': __('identifierSchemeCodeUnspsc190501.85271505'),
	'identifierSchemeCodeUnspsc190501.85271506': __('identifierSchemeCodeUnspsc190501.85271506'),
	'identifierSchemeCodeUnspsc190501.85271507': __('identifierSchemeCodeUnspsc190501.85271507'),
	'identifierSchemeCodeUnspsc190501.85271508': __('identifierSchemeCodeUnspsc190501.85271508'),
	'identifierSchemeCodeUnspsc190501.85271509': __('identifierSchemeCodeUnspsc190501.85271509'),
	'identifierSchemeCodeUnspsc190501.85271510': __('identifierSchemeCodeUnspsc190501.85271510'),
	'identifierSchemeCodeUnspsc190501.85271511': __('identifierSchemeCodeUnspsc190501.85271511'),
	'identifierSchemeCodeUnspsc190501.85271512': __('identifierSchemeCodeUnspsc190501.85271512'),
	'identifierSchemeCodeUnspsc190501.85271600': __('identifierSchemeCodeUnspsc190501.85271600'),
	'identifierSchemeCodeUnspsc190501.85271601': __('identifierSchemeCodeUnspsc190501.85271601'),
	'identifierSchemeCodeUnspsc190501.85271602': __('identifierSchemeCodeUnspsc190501.85271602'),
	'identifierSchemeCodeUnspsc190501.85271700': __('identifierSchemeCodeUnspsc190501.85271700'),
	'identifierSchemeCodeUnspsc190501.85271701': __('identifierSchemeCodeUnspsc190501.85271701'),
	'identifierSchemeCodeUnspsc190501.85271702': __('identifierSchemeCodeUnspsc190501.85271702'),
	'identifierSchemeCodeUnspsc190501.85271703': __('identifierSchemeCodeUnspsc190501.85271703'),
	'identifierSchemeCodeUnspsc190501.85271704': __('identifierSchemeCodeUnspsc190501.85271704'),
	'identifierSchemeCodeUnspsc190501.85271705': __('identifierSchemeCodeUnspsc190501.85271705'),
	'identifierSchemeCodeUnspsc190501.85271706': __('identifierSchemeCodeUnspsc190501.85271706'),
	'identifierSchemeCodeUnspsc190501.85271707': __('identifierSchemeCodeUnspsc190501.85271707'),
	'identifierSchemeCodeUnspsc190501.85271708': __('identifierSchemeCodeUnspsc190501.85271708'),
	'identifierSchemeCodeUnspsc190501.85271800': __('identifierSchemeCodeUnspsc190501.85271800'),
	'identifierSchemeCodeUnspsc190501.85271801': __('identifierSchemeCodeUnspsc190501.85271801'),
	'identifierSchemeCodeUnspsc190501.85271802': __('identifierSchemeCodeUnspsc190501.85271802'),
	'identifierSchemeCodeUnspsc190501.85271803': __('identifierSchemeCodeUnspsc190501.85271803'),
	'identifierSchemeCodeUnspsc190501.85271900': __('identifierSchemeCodeUnspsc190501.85271900'),
	'identifierSchemeCodeUnspsc190501.85271901': __('identifierSchemeCodeUnspsc190501.85271901'),
	'identifierSchemeCodeUnspsc190501.85271902': __('identifierSchemeCodeUnspsc190501.85271902'),
	'identifierSchemeCodeUnspsc190501.85271903': __('identifierSchemeCodeUnspsc190501.85271903'),
	'identifierSchemeCodeUnspsc190501.85271904': __('identifierSchemeCodeUnspsc190501.85271904'),
	'identifierSchemeCodeUnspsc190501.85271905': __('identifierSchemeCodeUnspsc190501.85271905'),
	'identifierSchemeCodeUnspsc190501.85271906': __('identifierSchemeCodeUnspsc190501.85271906'),
	'identifierSchemeCodeUnspsc190501.85271907': __('identifierSchemeCodeUnspsc190501.85271907'),
	'identifierSchemeCodeUnspsc190501.85271908': __('identifierSchemeCodeUnspsc190501.85271908'),
	'identifierSchemeCodeUnspsc190501.85271909': __('identifierSchemeCodeUnspsc190501.85271909'),
	'identifierSchemeCodeUnspsc190501.85272000': __('identifierSchemeCodeUnspsc190501.85272000'),
	'identifierSchemeCodeUnspsc190501.85272001': __('identifierSchemeCodeUnspsc190501.85272001'),
	'identifierSchemeCodeUnspsc190501.85272002': __('identifierSchemeCodeUnspsc190501.85272002'),
	'identifierSchemeCodeUnspsc190501.85272003': __('identifierSchemeCodeUnspsc190501.85272003'),
	'identifierSchemeCodeUnspsc190501.85272004': __('identifierSchemeCodeUnspsc190501.85272004'),
	'identifierSchemeCodeUnspsc190501.85272005': __('identifierSchemeCodeUnspsc190501.85272005'),
	'identifierSchemeCodeUnspsc190501.85272006': __('identifierSchemeCodeUnspsc190501.85272006'),
	'identifierSchemeCodeUnspsc190501.85272007': __('identifierSchemeCodeUnspsc190501.85272007'),
	'identifierSchemeCodeUnspsc190501.85272008': __('identifierSchemeCodeUnspsc190501.85272008'),
	'identifierSchemeCodeUnspsc190501.85272100': __('identifierSchemeCodeUnspsc190501.85272100'),
	'identifierSchemeCodeUnspsc190501.85272101': __('identifierSchemeCodeUnspsc190501.85272101'),
	'identifierSchemeCodeUnspsc190501.85272102': __('identifierSchemeCodeUnspsc190501.85272102'),
	'identifierSchemeCodeUnspsc190501.85272103': __('identifierSchemeCodeUnspsc190501.85272103'),
	'identifierSchemeCodeUnspsc190501.85272104': __('identifierSchemeCodeUnspsc190501.85272104'),
	'identifierSchemeCodeUnspsc190501.85272105': __('identifierSchemeCodeUnspsc190501.85272105'),
	'identifierSchemeCodeUnspsc190501.85272106': __('identifierSchemeCodeUnspsc190501.85272106'),
	'identifierSchemeCodeUnspsc190501.85272107': __('identifierSchemeCodeUnspsc190501.85272107'),
	'identifierSchemeCodeUnspsc190501.85272108': __('identifierSchemeCodeUnspsc190501.85272108'),
	'identifierSchemeCodeUnspsc190501.85272200': __('identifierSchemeCodeUnspsc190501.85272200'),
	'identifierSchemeCodeUnspsc190501.85272201': __('identifierSchemeCodeUnspsc190501.85272201'),
	'identifierSchemeCodeUnspsc190501.85272202': __('identifierSchemeCodeUnspsc190501.85272202'),
	'identifierSchemeCodeUnspsc190501.85272203': __('identifierSchemeCodeUnspsc190501.85272203'),
	'identifierSchemeCodeUnspsc190501.85272204': __('identifierSchemeCodeUnspsc190501.85272204'),
	'identifierSchemeCodeUnspsc190501.85272205': __('identifierSchemeCodeUnspsc190501.85272205'),
	'identifierSchemeCodeUnspsc190501.85272206': __('identifierSchemeCodeUnspsc190501.85272206'),
	'identifierSchemeCodeUnspsc190501.85272207': __('identifierSchemeCodeUnspsc190501.85272207'),
	'identifierSchemeCodeUnspsc190501.85272208': __('identifierSchemeCodeUnspsc190501.85272208'),
	'identifierSchemeCodeUnspsc190501.85272300': __('identifierSchemeCodeUnspsc190501.85272300'),
	'identifierSchemeCodeUnspsc190501.85272301': __('identifierSchemeCodeUnspsc190501.85272301'),
	'identifierSchemeCodeUnspsc190501.85272302': __('identifierSchemeCodeUnspsc190501.85272302'),
	'identifierSchemeCodeUnspsc190501.85272303': __('identifierSchemeCodeUnspsc190501.85272303'),
	'identifierSchemeCodeUnspsc190501.85272304': __('identifierSchemeCodeUnspsc190501.85272304'),
	'identifierSchemeCodeUnspsc190501.85272305': __('identifierSchemeCodeUnspsc190501.85272305'),
	'identifierSchemeCodeUnspsc190501.85272306': __('identifierSchemeCodeUnspsc190501.85272306'),
	'identifierSchemeCodeUnspsc190501.85272307': __('identifierSchemeCodeUnspsc190501.85272307'),
	'identifierSchemeCodeUnspsc190501.85272400': __('identifierSchemeCodeUnspsc190501.85272400'),
	'identifierSchemeCodeUnspsc190501.85272401': __('identifierSchemeCodeUnspsc190501.85272401'),
	'identifierSchemeCodeUnspsc190501.85272402': __('identifierSchemeCodeUnspsc190501.85272402'),
	'identifierSchemeCodeUnspsc190501.85272403': __('identifierSchemeCodeUnspsc190501.85272403'),
	'identifierSchemeCodeUnspsc190501.85272404': __('identifierSchemeCodeUnspsc190501.85272404'),
	'identifierSchemeCodeUnspsc190501.85272405': __('identifierSchemeCodeUnspsc190501.85272405'),
	'identifierSchemeCodeUnspsc190501.85272406': __('identifierSchemeCodeUnspsc190501.85272406'),
	'identifierSchemeCodeUnspsc190501.85272407': __('identifierSchemeCodeUnspsc190501.85272407'),
	'identifierSchemeCodeUnspsc190501.85272408': __('identifierSchemeCodeUnspsc190501.85272408'),
	'identifierSchemeCodeUnspsc190501.85272500': __('identifierSchemeCodeUnspsc190501.85272500'),
	'identifierSchemeCodeUnspsc190501.85272501': __('identifierSchemeCodeUnspsc190501.85272501'),
	'identifierSchemeCodeUnspsc190501.85272502': __('identifierSchemeCodeUnspsc190501.85272502'),
	'identifierSchemeCodeUnspsc190501.85272503': __('identifierSchemeCodeUnspsc190501.85272503'),
	'identifierSchemeCodeUnspsc190501.85272504': __('identifierSchemeCodeUnspsc190501.85272504'),
	'identifierSchemeCodeUnspsc190501.85272505': __('identifierSchemeCodeUnspsc190501.85272505'),
	'identifierSchemeCodeUnspsc190501.85272506': __('identifierSchemeCodeUnspsc190501.85272506'),
	'identifierSchemeCodeUnspsc190501.85272507': __('identifierSchemeCodeUnspsc190501.85272507'),
	'identifierSchemeCodeUnspsc190501.85272508': __('identifierSchemeCodeUnspsc190501.85272508'),
	'identifierSchemeCodeUnspsc190501.85272600': __('identifierSchemeCodeUnspsc190501.85272600'),
	'identifierSchemeCodeUnspsc190501.85272601': __('identifierSchemeCodeUnspsc190501.85272601'),
	'identifierSchemeCodeUnspsc190501.85272602': __('identifierSchemeCodeUnspsc190501.85272602'),
	'identifierSchemeCodeUnspsc190501.85272603': __('identifierSchemeCodeUnspsc190501.85272603'),
	'identifierSchemeCodeUnspsc190501.85272604': __('identifierSchemeCodeUnspsc190501.85272604'),
	'identifierSchemeCodeUnspsc190501.85272605': __('identifierSchemeCodeUnspsc190501.85272605'),
	'identifierSchemeCodeUnspsc190501.85272606': __('identifierSchemeCodeUnspsc190501.85272606'),
	'identifierSchemeCodeUnspsc190501.85272607': __('identifierSchemeCodeUnspsc190501.85272607'),
	'identifierSchemeCodeUnspsc190501.85272608': __('identifierSchemeCodeUnspsc190501.85272608'),
	'identifierSchemeCodeUnspsc190501.85272700': __('identifierSchemeCodeUnspsc190501.85272700'),
	'identifierSchemeCodeUnspsc190501.85272701': __('identifierSchemeCodeUnspsc190501.85272701'),
	'identifierSchemeCodeUnspsc190501.85272702': __('identifierSchemeCodeUnspsc190501.85272702'),
	'identifierSchemeCodeUnspsc190501.85272703': __('identifierSchemeCodeUnspsc190501.85272703'),
	'identifierSchemeCodeUnspsc190501.85272704': __('identifierSchemeCodeUnspsc190501.85272704'),
	'identifierSchemeCodeUnspsc190501.85272705': __('identifierSchemeCodeUnspsc190501.85272705'),
	'identifierSchemeCodeUnspsc190501.85272706': __('identifierSchemeCodeUnspsc190501.85272706'),
	'identifierSchemeCodeUnspsc190501.85272707': __('identifierSchemeCodeUnspsc190501.85272707'),
	'identifierSchemeCodeUnspsc190501.85272800': __('identifierSchemeCodeUnspsc190501.85272800'),
	'identifierSchemeCodeUnspsc190501.85272801': __('identifierSchemeCodeUnspsc190501.85272801'),
	'identifierSchemeCodeUnspsc190501.85272802': __('identifierSchemeCodeUnspsc190501.85272802'),
	'identifierSchemeCodeUnspsc190501.85272803': __('identifierSchemeCodeUnspsc190501.85272803'),
	'identifierSchemeCodeUnspsc190501.85272900': __('identifierSchemeCodeUnspsc190501.85272900'),
	'identifierSchemeCodeUnspsc190501.85272901': __('identifierSchemeCodeUnspsc190501.85272901'),
	'identifierSchemeCodeUnspsc190501.85272902': __('identifierSchemeCodeUnspsc190501.85272902'),
	'identifierSchemeCodeUnspsc190501.85272903': __('identifierSchemeCodeUnspsc190501.85272903'),
	'identifierSchemeCodeUnspsc190501.85273000': __('identifierSchemeCodeUnspsc190501.85273000'),
	'identifierSchemeCodeUnspsc190501.85273001': __('identifierSchemeCodeUnspsc190501.85273001'),
	'identifierSchemeCodeUnspsc190501.85273002': __('identifierSchemeCodeUnspsc190501.85273002'),
	'identifierSchemeCodeUnspsc190501.85273003': __('identifierSchemeCodeUnspsc190501.85273003'),
	'identifierSchemeCodeUnspsc190501.85273100': __('identifierSchemeCodeUnspsc190501.85273100'),
	'identifierSchemeCodeUnspsc190501.85273101': __('identifierSchemeCodeUnspsc190501.85273101'),
	'identifierSchemeCodeUnspsc190501.85273102': __('identifierSchemeCodeUnspsc190501.85273102'),
	'identifierSchemeCodeUnspsc190501.85273103': __('identifierSchemeCodeUnspsc190501.85273103'),
	'identifierSchemeCodeUnspsc190501.85273200': __('identifierSchemeCodeUnspsc190501.85273200'),
	'identifierSchemeCodeUnspsc190501.85273201': __('identifierSchemeCodeUnspsc190501.85273201'),
	'identifierSchemeCodeUnspsc190501.85273202': __('identifierSchemeCodeUnspsc190501.85273202'),
	'identifierSchemeCodeUnspsc190501.85273203': __('identifierSchemeCodeUnspsc190501.85273203'),
	'identifierSchemeCodeUnspsc190501.85273204': __('identifierSchemeCodeUnspsc190501.85273204'),
	'identifierSchemeCodeUnspsc190501.85273205': __('identifierSchemeCodeUnspsc190501.85273205'),
	'identifierSchemeCodeUnspsc190501.85273206': __('identifierSchemeCodeUnspsc190501.85273206'),
	'identifierSchemeCodeUnspsc190501.85273207': __('identifierSchemeCodeUnspsc190501.85273207'),
	'identifierSchemeCodeUnspsc190501.85273208': __('identifierSchemeCodeUnspsc190501.85273208'),
	'identifierSchemeCodeUnspsc190501.85273300': __('identifierSchemeCodeUnspsc190501.85273300'),
	'identifierSchemeCodeUnspsc190501.85273301': __('identifierSchemeCodeUnspsc190501.85273301'),
	'identifierSchemeCodeUnspsc190501.85273302': __('identifierSchemeCodeUnspsc190501.85273302'),
	'identifierSchemeCodeUnspsc190501.85273303': __('identifierSchemeCodeUnspsc190501.85273303'),
	'identifierSchemeCodeUnspsc190501.85273304': __('identifierSchemeCodeUnspsc190501.85273304'),
	'identifierSchemeCodeUnspsc190501.85273400': __('identifierSchemeCodeUnspsc190501.85273400'),
	'identifierSchemeCodeUnspsc190501.85273401': __('identifierSchemeCodeUnspsc190501.85273401'),
	'identifierSchemeCodeUnspsc190501.85273402': __('identifierSchemeCodeUnspsc190501.85273402'),
	'identifierSchemeCodeUnspsc190501.85273403': __('identifierSchemeCodeUnspsc190501.85273403'),
	'identifierSchemeCodeUnspsc190501.85273404': __('identifierSchemeCodeUnspsc190501.85273404'),
	'identifierSchemeCodeUnspsc190501.85273405': __('identifierSchemeCodeUnspsc190501.85273405'),
	'identifierSchemeCodeUnspsc190501.85273500': __('identifierSchemeCodeUnspsc190501.85273500'),
	'identifierSchemeCodeUnspsc190501.85273501': __('identifierSchemeCodeUnspsc190501.85273501'),
	'identifierSchemeCodeUnspsc190501.85273502': __('identifierSchemeCodeUnspsc190501.85273502'),
	'identifierSchemeCodeUnspsc190501.85273600': __('identifierSchemeCodeUnspsc190501.85273600'),
	'identifierSchemeCodeUnspsc190501.85273601': __('identifierSchemeCodeUnspsc190501.85273601'),
	'identifierSchemeCodeUnspsc190501.85273602': __('identifierSchemeCodeUnspsc190501.85273602'),
	'identifierSchemeCodeUnspsc190501.85273603': __('identifierSchemeCodeUnspsc190501.85273603'),
	'identifierSchemeCodeUnspsc190501.85273700': __('identifierSchemeCodeUnspsc190501.85273700'),
	'identifierSchemeCodeUnspsc190501.85273701': __('identifierSchemeCodeUnspsc190501.85273701'),
	'identifierSchemeCodeUnspsc190501.85273702': __('identifierSchemeCodeUnspsc190501.85273702'),
	'identifierSchemeCodeUnspsc190501.85273703': __('identifierSchemeCodeUnspsc190501.85273703'),
	'identifierSchemeCodeUnspsc190501.85273800': __('identifierSchemeCodeUnspsc190501.85273800'),
	'identifierSchemeCodeUnspsc190501.85273801': __('identifierSchemeCodeUnspsc190501.85273801'),
	'identifierSchemeCodeUnspsc190501.85273802': __('identifierSchemeCodeUnspsc190501.85273802'),
	'identifierSchemeCodeUnspsc190501.85273803': __('identifierSchemeCodeUnspsc190501.85273803'),
	'identifierSchemeCodeUnspsc190501.85273900': __('identifierSchemeCodeUnspsc190501.85273900'),
	'identifierSchemeCodeUnspsc190501.85273901': __('identifierSchemeCodeUnspsc190501.85273901'),
	'identifierSchemeCodeUnspsc190501.85273902': __('identifierSchemeCodeUnspsc190501.85273902'),
	'identifierSchemeCodeUnspsc190501.85273903': __('identifierSchemeCodeUnspsc190501.85273903'),
	'identifierSchemeCodeUnspsc190501.85274000': __('identifierSchemeCodeUnspsc190501.85274000'),
	'identifierSchemeCodeUnspsc190501.85274001': __('identifierSchemeCodeUnspsc190501.85274001'),
	'identifierSchemeCodeUnspsc190501.85274002': __('identifierSchemeCodeUnspsc190501.85274002'),
	'identifierSchemeCodeUnspsc190501.85274003': __('identifierSchemeCodeUnspsc190501.85274003'),
	'identifierSchemeCodeUnspsc190501.85274004': __('identifierSchemeCodeUnspsc190501.85274004'),
	'identifierSchemeCodeUnspsc190501.85274005': __('identifierSchemeCodeUnspsc190501.85274005'),
	'identifierSchemeCodeUnspsc190501.85274006': __('identifierSchemeCodeUnspsc190501.85274006'),
	'identifierSchemeCodeUnspsc190501.85274007': __('identifierSchemeCodeUnspsc190501.85274007'),
	'identifierSchemeCodeUnspsc190501.85274008': __('identifierSchemeCodeUnspsc190501.85274008'),
	'identifierSchemeCodeUnspsc190501.85274100': __('identifierSchemeCodeUnspsc190501.85274100'),
	'identifierSchemeCodeUnspsc190501.85274101': __('identifierSchemeCodeUnspsc190501.85274101'),
	'identifierSchemeCodeUnspsc190501.85274102': __('identifierSchemeCodeUnspsc190501.85274102'),
	'identifierSchemeCodeUnspsc190501.85274103': __('identifierSchemeCodeUnspsc190501.85274103'),
	'identifierSchemeCodeUnspsc190501.85274104': __('identifierSchemeCodeUnspsc190501.85274104'),
	'identifierSchemeCodeUnspsc190501.85274105': __('identifierSchemeCodeUnspsc190501.85274105'),
	'identifierSchemeCodeUnspsc190501.85274200': __('identifierSchemeCodeUnspsc190501.85274200'),
	'identifierSchemeCodeUnspsc190501.85274201': __('identifierSchemeCodeUnspsc190501.85274201'),
	'identifierSchemeCodeUnspsc190501.85274202': __('identifierSchemeCodeUnspsc190501.85274202'),
	'identifierSchemeCodeUnspsc190501.85274300': __('identifierSchemeCodeUnspsc190501.85274300'),
	'identifierSchemeCodeUnspsc190501.85274301': __('identifierSchemeCodeUnspsc190501.85274301'),
	'identifierSchemeCodeUnspsc190501.85274302': __('identifierSchemeCodeUnspsc190501.85274302'),
	'identifierSchemeCodeUnspsc190501.85274303': __('identifierSchemeCodeUnspsc190501.85274303'),
	'identifierSchemeCodeUnspsc190501.85274304': __('identifierSchemeCodeUnspsc190501.85274304'),
	'identifierSchemeCodeUnspsc190501.85274400': __('identifierSchemeCodeUnspsc190501.85274400'),
	'identifierSchemeCodeUnspsc190501.85274401': __('identifierSchemeCodeUnspsc190501.85274401'),
	'identifierSchemeCodeUnspsc190501.85274402': __('identifierSchemeCodeUnspsc190501.85274402'),
	'identifierSchemeCodeUnspsc190501.85274403': __('identifierSchemeCodeUnspsc190501.85274403'),
	'identifierSchemeCodeUnspsc190501.85274404': __('identifierSchemeCodeUnspsc190501.85274404'),
	'identifierSchemeCodeUnspsc190501.85274405': __('identifierSchemeCodeUnspsc190501.85274405'),
	'identifierSchemeCodeUnspsc190501.85274406': __('identifierSchemeCodeUnspsc190501.85274406'),
	'identifierSchemeCodeUnspsc190501.85274500': __('identifierSchemeCodeUnspsc190501.85274500'),
	'identifierSchemeCodeUnspsc190501.85274501': __('identifierSchemeCodeUnspsc190501.85274501'),
	'identifierSchemeCodeUnspsc190501.85274502': __('identifierSchemeCodeUnspsc190501.85274502'),
	'identifierSchemeCodeUnspsc190501.85274503': __('identifierSchemeCodeUnspsc190501.85274503'),
	'identifierSchemeCodeUnspsc190501.85274504': __('identifierSchemeCodeUnspsc190501.85274504'),
	'identifierSchemeCodeUnspsc190501.85274505': __('identifierSchemeCodeUnspsc190501.85274505'),
	'identifierSchemeCodeUnspsc190501.85274506': __('identifierSchemeCodeUnspsc190501.85274506'),
	'identifierSchemeCodeUnspsc190501.85274507': __('identifierSchemeCodeUnspsc190501.85274507'),
	'identifierSchemeCodeUnspsc190501.85274508': __('identifierSchemeCodeUnspsc190501.85274508'),
	'identifierSchemeCodeUnspsc190501.85274600': __('identifierSchemeCodeUnspsc190501.85274600'),
	'identifierSchemeCodeUnspsc190501.85274601': __('identifierSchemeCodeUnspsc190501.85274601'),
	'identifierSchemeCodeUnspsc190501.85274602': __('identifierSchemeCodeUnspsc190501.85274602'),
	'identifierSchemeCodeUnspsc190501.85274603': __('identifierSchemeCodeUnspsc190501.85274603'),
	'identifierSchemeCodeUnspsc190501.85274604': __('identifierSchemeCodeUnspsc190501.85274604'),
	'identifierSchemeCodeUnspsc190501.85274605': __('identifierSchemeCodeUnspsc190501.85274605'),
	'identifierSchemeCodeUnspsc190501.85274606': __('identifierSchemeCodeUnspsc190501.85274606'),
	'identifierSchemeCodeUnspsc190501.85274607': __('identifierSchemeCodeUnspsc190501.85274607'),
	'identifierSchemeCodeUnspsc190501.85274608': __('identifierSchemeCodeUnspsc190501.85274608'),
	'identifierSchemeCodeUnspsc190501.85274700': __('identifierSchemeCodeUnspsc190501.85274700'),
	'identifierSchemeCodeUnspsc190501.85274701': __('identifierSchemeCodeUnspsc190501.85274701'),
	'identifierSchemeCodeUnspsc190501.85274702': __('identifierSchemeCodeUnspsc190501.85274702'),
	'identifierSchemeCodeUnspsc190501.85274800': __('identifierSchemeCodeUnspsc190501.85274800'),
	'identifierSchemeCodeUnspsc190501.85274801': __('identifierSchemeCodeUnspsc190501.85274801'),
	'identifierSchemeCodeUnspsc190501.85274802': __('identifierSchemeCodeUnspsc190501.85274802'),
	'identifierSchemeCodeUnspsc190501.85274803': __('identifierSchemeCodeUnspsc190501.85274803'),
	'identifierSchemeCodeUnspsc190501.85274804': __('identifierSchemeCodeUnspsc190501.85274804'),
	'identifierSchemeCodeUnspsc190501.85274900': __('identifierSchemeCodeUnspsc190501.85274900'),
	'identifierSchemeCodeUnspsc190501.85274901': __('identifierSchemeCodeUnspsc190501.85274901'),
	'identifierSchemeCodeUnspsc190501.85274902': __('identifierSchemeCodeUnspsc190501.85274902'),
	'identifierSchemeCodeUnspsc190501.85274903': __('identifierSchemeCodeUnspsc190501.85274903'),
	'identifierSchemeCodeUnspsc190501.85275000': __('identifierSchemeCodeUnspsc190501.85275000'),
	'identifierSchemeCodeUnspsc190501.85275001': __('identifierSchemeCodeUnspsc190501.85275001'),
	'identifierSchemeCodeUnspsc190501.85275002': __('identifierSchemeCodeUnspsc190501.85275002'),
	'identifierSchemeCodeUnspsc190501.85275003': __('identifierSchemeCodeUnspsc190501.85275003'),
	'identifierSchemeCodeUnspsc190501.85275004': __('identifierSchemeCodeUnspsc190501.85275004'),
	'identifierSchemeCodeUnspsc190501.85275005': __('identifierSchemeCodeUnspsc190501.85275005'),
	'identifierSchemeCodeUnspsc190501.85275006': __('identifierSchemeCodeUnspsc190501.85275006'),
	'identifierSchemeCodeUnspsc190501.85275007': __('identifierSchemeCodeUnspsc190501.85275007'),
	'identifierSchemeCodeUnspsc190501.85275100': __('identifierSchemeCodeUnspsc190501.85275100'),
	'identifierSchemeCodeUnspsc190501.85275101': __('identifierSchemeCodeUnspsc190501.85275101'),
	'identifierSchemeCodeUnspsc190501.85275102': __('identifierSchemeCodeUnspsc190501.85275102'),
	'identifierSchemeCodeUnspsc190501.85275103': __('identifierSchemeCodeUnspsc190501.85275103'),
	'identifierSchemeCodeUnspsc190501.85275200': __('identifierSchemeCodeUnspsc190501.85275200'),
	'identifierSchemeCodeUnspsc190501.85275201': __('identifierSchemeCodeUnspsc190501.85275201'),
	'identifierSchemeCodeUnspsc190501.85275202': __('identifierSchemeCodeUnspsc190501.85275202'),
	'identifierSchemeCodeUnspsc190501.85275300': __('identifierSchemeCodeUnspsc190501.85275300'),
	'identifierSchemeCodeUnspsc190501.85275301': __('identifierSchemeCodeUnspsc190501.85275301'),
	'identifierSchemeCodeUnspsc190501.85275302': __('identifierSchemeCodeUnspsc190501.85275302'),
	'identifierSchemeCodeUnspsc190501.85275303': __('identifierSchemeCodeUnspsc190501.85275303'),
	'identifierSchemeCodeUnspsc190501.85275400': __('identifierSchemeCodeUnspsc190501.85275400'),
	'identifierSchemeCodeUnspsc190501.85275401': __('identifierSchemeCodeUnspsc190501.85275401'),
	'identifierSchemeCodeUnspsc190501.85275402': __('identifierSchemeCodeUnspsc190501.85275402'),
	'identifierSchemeCodeUnspsc190501.85275403': __('identifierSchemeCodeUnspsc190501.85275403'),
	'identifierSchemeCodeUnspsc190501.85275500': __('identifierSchemeCodeUnspsc190501.85275500'),
	'identifierSchemeCodeUnspsc190501.85275501': __('identifierSchemeCodeUnspsc190501.85275501'),
	'identifierSchemeCodeUnspsc190501.85275502': __('identifierSchemeCodeUnspsc190501.85275502'),
	'identifierSchemeCodeUnspsc190501.85275503': __('identifierSchemeCodeUnspsc190501.85275503'),
	'identifierSchemeCodeUnspsc190501.85275600': __('identifierSchemeCodeUnspsc190501.85275600'),
	'identifierSchemeCodeUnspsc190501.85275601': __('identifierSchemeCodeUnspsc190501.85275601'),
	'identifierSchemeCodeUnspsc190501.85275602': __('identifierSchemeCodeUnspsc190501.85275602'),
	'identifierSchemeCodeUnspsc190501.85275603': __('identifierSchemeCodeUnspsc190501.85275603'),
	'identifierSchemeCodeUnspsc190501.85275700': __('identifierSchemeCodeUnspsc190501.85275700'),
	'identifierSchemeCodeUnspsc190501.85275701': __('identifierSchemeCodeUnspsc190501.85275701'),
	'identifierSchemeCodeUnspsc190501.85275702': __('identifierSchemeCodeUnspsc190501.85275702'),
	'identifierSchemeCodeUnspsc190501.85275703': __('identifierSchemeCodeUnspsc190501.85275703'),
	'identifierSchemeCodeUnspsc190501.85275704': __('identifierSchemeCodeUnspsc190501.85275704'),
	'identifierSchemeCodeUnspsc190501.85275800': __('identifierSchemeCodeUnspsc190501.85275800'),
	'identifierSchemeCodeUnspsc190501.85275801': __('identifierSchemeCodeUnspsc190501.85275801'),
	'identifierSchemeCodeUnspsc190501.85275802': __('identifierSchemeCodeUnspsc190501.85275802'),
	'identifierSchemeCodeUnspsc190501.85275803': __('identifierSchemeCodeUnspsc190501.85275803'),
	'identifierSchemeCodeUnspsc190501.85275804': __('identifierSchemeCodeUnspsc190501.85275804'),
	'identifierSchemeCodeUnspsc190501.85275900': __('identifierSchemeCodeUnspsc190501.85275900'),
	'identifierSchemeCodeUnspsc190501.85275901': __('identifierSchemeCodeUnspsc190501.85275901'),
	'identifierSchemeCodeUnspsc190501.85275902': __('identifierSchemeCodeUnspsc190501.85275902'),
	'identifierSchemeCodeUnspsc190501.85275903': __('identifierSchemeCodeUnspsc190501.85275903'),
	'identifierSchemeCodeUnspsc190501.85275904': __('identifierSchemeCodeUnspsc190501.85275904'),
	'identifierSchemeCodeUnspsc190501.85275905': __('identifierSchemeCodeUnspsc190501.85275905'),
	'identifierSchemeCodeUnspsc190501.85276000': __('identifierSchemeCodeUnspsc190501.85276000'),
	'identifierSchemeCodeUnspsc190501.85276001': __('identifierSchemeCodeUnspsc190501.85276001'),
	'identifierSchemeCodeUnspsc190501.85276002': __('identifierSchemeCodeUnspsc190501.85276002'),
	'identifierSchemeCodeUnspsc190501.85276100': __('identifierSchemeCodeUnspsc190501.85276100'),
	'identifierSchemeCodeUnspsc190501.85276101': __('identifierSchemeCodeUnspsc190501.85276101'),
	'identifierSchemeCodeUnspsc190501.85276102': __('identifierSchemeCodeUnspsc190501.85276102'),
	'identifierSchemeCodeUnspsc190501.85276103': __('identifierSchemeCodeUnspsc190501.85276103'),
	'identifierSchemeCodeUnspsc190501.85276104': __('identifierSchemeCodeUnspsc190501.85276104'),
	'identifierSchemeCodeUnspsc190501.85276105': __('identifierSchemeCodeUnspsc190501.85276105'),
	'identifierSchemeCodeUnspsc190501.85276200': __('identifierSchemeCodeUnspsc190501.85276200'),
	'identifierSchemeCodeUnspsc190501.85276201': __('identifierSchemeCodeUnspsc190501.85276201'),
	'identifierSchemeCodeUnspsc190501.85276202': __('identifierSchemeCodeUnspsc190501.85276202'),
	'identifierSchemeCodeUnspsc190501.85276203': __('identifierSchemeCodeUnspsc190501.85276203'),
	'identifierSchemeCodeUnspsc190501.85276204': __('identifierSchemeCodeUnspsc190501.85276204'),
	'identifierSchemeCodeUnspsc190501.85276300': __('identifierSchemeCodeUnspsc190501.85276300'),
	'identifierSchemeCodeUnspsc190501.85276301': __('identifierSchemeCodeUnspsc190501.85276301'),
	'identifierSchemeCodeUnspsc190501.85276302': __('identifierSchemeCodeUnspsc190501.85276302'),
	'identifierSchemeCodeUnspsc190501.85276303': __('identifierSchemeCodeUnspsc190501.85276303'),
	'identifierSchemeCodeUnspsc190501.85276304': __('identifierSchemeCodeUnspsc190501.85276304'),
	'identifierSchemeCodeUnspsc190501.85276305': __('identifierSchemeCodeUnspsc190501.85276305'),
	'identifierSchemeCodeUnspsc190501.85276306': __('identifierSchemeCodeUnspsc190501.85276306'),
	'identifierSchemeCodeUnspsc190501.85276307': __('identifierSchemeCodeUnspsc190501.85276307'),
	'identifierSchemeCodeUnspsc190501.85276308': __('identifierSchemeCodeUnspsc190501.85276308'),
	'identifierSchemeCodeUnspsc190501.85276309': __('identifierSchemeCodeUnspsc190501.85276309'),
	'identifierSchemeCodeUnspsc190501.85276310': __('identifierSchemeCodeUnspsc190501.85276310'),
	'identifierSchemeCodeUnspsc190501.85276400': __('identifierSchemeCodeUnspsc190501.85276400'),
	'identifierSchemeCodeUnspsc190501.85276401': __('identifierSchemeCodeUnspsc190501.85276401'),
	'identifierSchemeCodeUnspsc190501.85276402': __('identifierSchemeCodeUnspsc190501.85276402'),
	'identifierSchemeCodeUnspsc190501.85276403': __('identifierSchemeCodeUnspsc190501.85276403'),
	'identifierSchemeCodeUnspsc190501.85280000': __('identifierSchemeCodeUnspsc190501.85280000'),
	'identifierSchemeCodeUnspsc190501.85281500': __('identifierSchemeCodeUnspsc190501.85281500'),
	'identifierSchemeCodeUnspsc190501.85281501': __('identifierSchemeCodeUnspsc190501.85281501'),
	'identifierSchemeCodeUnspsc190501.85281502': __('identifierSchemeCodeUnspsc190501.85281502'),
	'identifierSchemeCodeUnspsc190501.85281503': __('identifierSchemeCodeUnspsc190501.85281503'),
	'identifierSchemeCodeUnspsc190501.85281504': __('identifierSchemeCodeUnspsc190501.85281504'),
	'identifierSchemeCodeUnspsc190501.85281505': __('identifierSchemeCodeUnspsc190501.85281505'),
	'identifierSchemeCodeUnspsc190501.85281506': __('identifierSchemeCodeUnspsc190501.85281506'),
	'identifierSchemeCodeUnspsc190501.85281600': __('identifierSchemeCodeUnspsc190501.85281600'),
	'identifierSchemeCodeUnspsc190501.85281601': __('identifierSchemeCodeUnspsc190501.85281601'),
	'identifierSchemeCodeUnspsc190501.85281602': __('identifierSchemeCodeUnspsc190501.85281602'),
	'identifierSchemeCodeUnspsc190501.85281603': __('identifierSchemeCodeUnspsc190501.85281603'),
	'identifierSchemeCodeUnspsc190501.85281700': __('identifierSchemeCodeUnspsc190501.85281700'),
	'identifierSchemeCodeUnspsc190501.85281701': __('identifierSchemeCodeUnspsc190501.85281701'),
	'identifierSchemeCodeUnspsc190501.85281702': __('identifierSchemeCodeUnspsc190501.85281702'),
	'identifierSchemeCodeUnspsc190501.85281703': __('identifierSchemeCodeUnspsc190501.85281703'),
	'identifierSchemeCodeUnspsc190501.85281704': __('identifierSchemeCodeUnspsc190501.85281704'),
	'identifierSchemeCodeUnspsc190501.85281800': __('identifierSchemeCodeUnspsc190501.85281800'),
	'identifierSchemeCodeUnspsc190501.85281801': __('identifierSchemeCodeUnspsc190501.85281801'),
	'identifierSchemeCodeUnspsc190501.85281802': __('identifierSchemeCodeUnspsc190501.85281802'),
	'identifierSchemeCodeUnspsc190501.85281900': __('identifierSchemeCodeUnspsc190501.85281900'),
	'identifierSchemeCodeUnspsc190501.85281901': __('identifierSchemeCodeUnspsc190501.85281901'),
	'identifierSchemeCodeUnspsc190501.85281902': __('identifierSchemeCodeUnspsc190501.85281902'),
	'identifierSchemeCodeUnspsc190501.85282000': __('identifierSchemeCodeUnspsc190501.85282000'),
	'identifierSchemeCodeUnspsc190501.85282001': __('identifierSchemeCodeUnspsc190501.85282001'),
	'identifierSchemeCodeUnspsc190501.85282002': __('identifierSchemeCodeUnspsc190501.85282002'),
	'identifierSchemeCodeUnspsc190501.85282003': __('identifierSchemeCodeUnspsc190501.85282003'),
	'identifierSchemeCodeUnspsc190501.85282004': __('identifierSchemeCodeUnspsc190501.85282004'),
	'identifierSchemeCodeUnspsc190501.85282005': __('identifierSchemeCodeUnspsc190501.85282005'),
	'identifierSchemeCodeUnspsc190501.85282100': __('identifierSchemeCodeUnspsc190501.85282100'),
	'identifierSchemeCodeUnspsc190501.85282101': __('identifierSchemeCodeUnspsc190501.85282101'),
	'identifierSchemeCodeUnspsc190501.85282102': __('identifierSchemeCodeUnspsc190501.85282102'),
	'identifierSchemeCodeUnspsc190501.85282200': __('identifierSchemeCodeUnspsc190501.85282200'),
	'identifierSchemeCodeUnspsc190501.85282201': __('identifierSchemeCodeUnspsc190501.85282201'),
	'identifierSchemeCodeUnspsc190501.85282202': __('identifierSchemeCodeUnspsc190501.85282202'),
	'identifierSchemeCodeUnspsc190501.85282300': __('identifierSchemeCodeUnspsc190501.85282300'),
	'identifierSchemeCodeUnspsc190501.85282301': __('identifierSchemeCodeUnspsc190501.85282301'),
	'identifierSchemeCodeUnspsc190501.85282302': __('identifierSchemeCodeUnspsc190501.85282302'),
	'identifierSchemeCodeUnspsc190501.85282400': __('identifierSchemeCodeUnspsc190501.85282400'),
	'identifierSchemeCodeUnspsc190501.85282401': __('identifierSchemeCodeUnspsc190501.85282401'),
	'identifierSchemeCodeUnspsc190501.85282402': __('identifierSchemeCodeUnspsc190501.85282402'),
	'identifierSchemeCodeUnspsc190501.85282403': __('identifierSchemeCodeUnspsc190501.85282403'),
	'identifierSchemeCodeUnspsc190501.85282500': __('identifierSchemeCodeUnspsc190501.85282500'),
	'identifierSchemeCodeUnspsc190501.85282501': __('identifierSchemeCodeUnspsc190501.85282501'),
	'identifierSchemeCodeUnspsc190501.85282502': __('identifierSchemeCodeUnspsc190501.85282502'),
	'identifierSchemeCodeUnspsc190501.85282503': __('identifierSchemeCodeUnspsc190501.85282503'),
	'identifierSchemeCodeUnspsc190501.85282600': __('identifierSchemeCodeUnspsc190501.85282600'),
	'identifierSchemeCodeUnspsc190501.85282601': __('identifierSchemeCodeUnspsc190501.85282601'),
	'identifierSchemeCodeUnspsc190501.85282602': __('identifierSchemeCodeUnspsc190501.85282602'),
	'identifierSchemeCodeUnspsc190501.85282603': __('identifierSchemeCodeUnspsc190501.85282603'),
	'identifierSchemeCodeUnspsc190501.85282604': __('identifierSchemeCodeUnspsc190501.85282604'),
	'identifierSchemeCodeUnspsc190501.85282605': __('identifierSchemeCodeUnspsc190501.85282605'),
	'identifierSchemeCodeUnspsc190501.85282606': __('identifierSchemeCodeUnspsc190501.85282606'),
	'identifierSchemeCodeUnspsc190501.85282700': __('identifierSchemeCodeUnspsc190501.85282700'),
	'identifierSchemeCodeUnspsc190501.85282701': __('identifierSchemeCodeUnspsc190501.85282701'),
	'identifierSchemeCodeUnspsc190501.85282702': __('identifierSchemeCodeUnspsc190501.85282702'),
	'identifierSchemeCodeUnspsc190501.85282703': __('identifierSchemeCodeUnspsc190501.85282703'),
	'identifierSchemeCodeUnspsc190501.85282704': __('identifierSchemeCodeUnspsc190501.85282704'),
	'identifierSchemeCodeUnspsc190501.85282800': __('identifierSchemeCodeUnspsc190501.85282800'),
	'identifierSchemeCodeUnspsc190501.85282801': __('identifierSchemeCodeUnspsc190501.85282801'),
	'identifierSchemeCodeUnspsc190501.85282802': __('identifierSchemeCodeUnspsc190501.85282802'),
	'identifierSchemeCodeUnspsc190501.85282900': __('identifierSchemeCodeUnspsc190501.85282900'),
	'identifierSchemeCodeUnspsc190501.85282901': __('identifierSchemeCodeUnspsc190501.85282901'),
	'identifierSchemeCodeUnspsc190501.85282902': __('identifierSchemeCodeUnspsc190501.85282902'),
	'identifierSchemeCodeUnspsc190501.85283000': __('identifierSchemeCodeUnspsc190501.85283000'),
	'identifierSchemeCodeUnspsc190501.85283001': __('identifierSchemeCodeUnspsc190501.85283001'),
	'identifierSchemeCodeUnspsc190501.85283002': __('identifierSchemeCodeUnspsc190501.85283002'),
	'identifierSchemeCodeUnspsc190501.85283003': __('identifierSchemeCodeUnspsc190501.85283003'),
	'identifierSchemeCodeUnspsc190501.85283004': __('identifierSchemeCodeUnspsc190501.85283004'),
	'identifierSchemeCodeUnspsc190501.85283100': __('identifierSchemeCodeUnspsc190501.85283100'),
	'identifierSchemeCodeUnspsc190501.85283101': __('identifierSchemeCodeUnspsc190501.85283101'),
	'identifierSchemeCodeUnspsc190501.85283102': __('identifierSchemeCodeUnspsc190501.85283102'),
	'identifierSchemeCodeUnspsc190501.85283103': __('identifierSchemeCodeUnspsc190501.85283103'),
	'identifierSchemeCodeUnspsc190501.85283104': __('identifierSchemeCodeUnspsc190501.85283104'),
	'identifierSchemeCodeUnspsc190501.85283105': __('identifierSchemeCodeUnspsc190501.85283105'),
	'identifierSchemeCodeUnspsc190501.85283106': __('identifierSchemeCodeUnspsc190501.85283106'),
	'identifierSchemeCodeUnspsc190501.85283107': __('identifierSchemeCodeUnspsc190501.85283107'),
	'identifierSchemeCodeUnspsc190501.85283108': __('identifierSchemeCodeUnspsc190501.85283108'),
	'identifierSchemeCodeUnspsc190501.85283200': __('identifierSchemeCodeUnspsc190501.85283200'),
	'identifierSchemeCodeUnspsc190501.85283201': __('identifierSchemeCodeUnspsc190501.85283201'),
	'identifierSchemeCodeUnspsc190501.85283202': __('identifierSchemeCodeUnspsc190501.85283202'),
	'identifierSchemeCodeUnspsc190501.85283203': __('identifierSchemeCodeUnspsc190501.85283203'),
	'identifierSchemeCodeUnspsc190501.85283204': __('identifierSchemeCodeUnspsc190501.85283204'),
	'identifierSchemeCodeUnspsc190501.85283205': __('identifierSchemeCodeUnspsc190501.85283205'),
	'identifierSchemeCodeUnspsc190501.85283300': __('identifierSchemeCodeUnspsc190501.85283300'),
	'identifierSchemeCodeUnspsc190501.85283301': __('identifierSchemeCodeUnspsc190501.85283301'),
	'identifierSchemeCodeUnspsc190501.85283302': __('identifierSchemeCodeUnspsc190501.85283302'),
	'identifierSchemeCodeUnspsc190501.85283303': __('identifierSchemeCodeUnspsc190501.85283303'),
	'identifierSchemeCodeUnspsc190501.85283400': __('identifierSchemeCodeUnspsc190501.85283400'),
	'identifierSchemeCodeUnspsc190501.85283401': __('identifierSchemeCodeUnspsc190501.85283401'),
	'identifierSchemeCodeUnspsc190501.85283402': __('identifierSchemeCodeUnspsc190501.85283402'),
	'identifierSchemeCodeUnspsc190501.85283403': __('identifierSchemeCodeUnspsc190501.85283403'),
	'identifierSchemeCodeUnspsc190501.85283404': __('identifierSchemeCodeUnspsc190501.85283404'),
	'identifierSchemeCodeUnspsc190501.85283500': __('identifierSchemeCodeUnspsc190501.85283500'),
	'identifierSchemeCodeUnspsc190501.85283501': __('identifierSchemeCodeUnspsc190501.85283501'),
	'identifierSchemeCodeUnspsc190501.85283502': __('identifierSchemeCodeUnspsc190501.85283502'),
	'identifierSchemeCodeUnspsc190501.85283503': __('identifierSchemeCodeUnspsc190501.85283503'),
	'identifierSchemeCodeUnspsc190501.85283600': __('identifierSchemeCodeUnspsc190501.85283600'),
	'identifierSchemeCodeUnspsc190501.85283601': __('identifierSchemeCodeUnspsc190501.85283601'),
	'identifierSchemeCodeUnspsc190501.85283602': __('identifierSchemeCodeUnspsc190501.85283602'),
	'identifierSchemeCodeUnspsc190501.85283603': __('identifierSchemeCodeUnspsc190501.85283603'),
	'identifierSchemeCodeUnspsc190501.85283604': __('identifierSchemeCodeUnspsc190501.85283604'),
	'identifierSchemeCodeUnspsc190501.85283605': __('identifierSchemeCodeUnspsc190501.85283605'),
	'identifierSchemeCodeUnspsc190501.85283700': __('identifierSchemeCodeUnspsc190501.85283700'),
	'identifierSchemeCodeUnspsc190501.85283701': __('identifierSchemeCodeUnspsc190501.85283701'),
	'identifierSchemeCodeUnspsc190501.85283702': __('identifierSchemeCodeUnspsc190501.85283702'),
	'identifierSchemeCodeUnspsc190501.85283703': __('identifierSchemeCodeUnspsc190501.85283703'),
	'identifierSchemeCodeUnspsc190501.85283704': __('identifierSchemeCodeUnspsc190501.85283704'),
	'identifierSchemeCodeUnspsc190501.85283705': __('identifierSchemeCodeUnspsc190501.85283705'),
	'identifierSchemeCodeUnspsc190501.85283706': __('identifierSchemeCodeUnspsc190501.85283706'),
	'identifierSchemeCodeUnspsc190501.85283707': __('identifierSchemeCodeUnspsc190501.85283707'),
	'identifierSchemeCodeUnspsc190501.85283800': __('identifierSchemeCodeUnspsc190501.85283800'),
	'identifierSchemeCodeUnspsc190501.85283801': __('identifierSchemeCodeUnspsc190501.85283801'),
	'identifierSchemeCodeUnspsc190501.85283802': __('identifierSchemeCodeUnspsc190501.85283802'),
	'identifierSchemeCodeUnspsc190501.85283803': __('identifierSchemeCodeUnspsc190501.85283803'),
	'identifierSchemeCodeUnspsc190501.85283804': __('identifierSchemeCodeUnspsc190501.85283804'),
	'identifierSchemeCodeUnspsc190501.85283805': __('identifierSchemeCodeUnspsc190501.85283805'),
	'identifierSchemeCodeUnspsc190501.85283900': __('identifierSchemeCodeUnspsc190501.85283900'),
	'identifierSchemeCodeUnspsc190501.85283901': __('identifierSchemeCodeUnspsc190501.85283901'),
	'identifierSchemeCodeUnspsc190501.85283902': __('identifierSchemeCodeUnspsc190501.85283902'),
	'identifierSchemeCodeUnspsc190501.85284000': __('identifierSchemeCodeUnspsc190501.85284000'),
	'identifierSchemeCodeUnspsc190501.85284001': __('identifierSchemeCodeUnspsc190501.85284001'),
	'identifierSchemeCodeUnspsc190501.85284002': __('identifierSchemeCodeUnspsc190501.85284002'),
	'identifierSchemeCodeUnspsc190501.85284003': __('identifierSchemeCodeUnspsc190501.85284003'),
	'identifierSchemeCodeUnspsc190501.85284004': __('identifierSchemeCodeUnspsc190501.85284004'),
	'identifierSchemeCodeUnspsc190501.85284005': __('identifierSchemeCodeUnspsc190501.85284005'),
	'identifierSchemeCodeUnspsc190501.85284100': __('identifierSchemeCodeUnspsc190501.85284100'),
	'identifierSchemeCodeUnspsc190501.85284101': __('identifierSchemeCodeUnspsc190501.85284101'),
	'identifierSchemeCodeUnspsc190501.85284102': __('identifierSchemeCodeUnspsc190501.85284102'),
	'identifierSchemeCodeUnspsc190501.85284103': __('identifierSchemeCodeUnspsc190501.85284103'),
	'identifierSchemeCodeUnspsc190501.85284104': __('identifierSchemeCodeUnspsc190501.85284104'),
	'identifierSchemeCodeUnspsc190501.85284105': __('identifierSchemeCodeUnspsc190501.85284105'),
	'identifierSchemeCodeUnspsc190501.85284200': __('identifierSchemeCodeUnspsc190501.85284200'),
	'identifierSchemeCodeUnspsc190501.85284201': __('identifierSchemeCodeUnspsc190501.85284201'),
	'identifierSchemeCodeUnspsc190501.85284202': __('identifierSchemeCodeUnspsc190501.85284202'),
	'identifierSchemeCodeUnspsc190501.85284203': __('identifierSchemeCodeUnspsc190501.85284203'),
	'identifierSchemeCodeUnspsc190501.85284204': __('identifierSchemeCodeUnspsc190501.85284204'),
	'identifierSchemeCodeUnspsc190501.85284300': __('identifierSchemeCodeUnspsc190501.85284300'),
	'identifierSchemeCodeUnspsc190501.85284301': __('identifierSchemeCodeUnspsc190501.85284301'),
	'identifierSchemeCodeUnspsc190501.85284302': __('identifierSchemeCodeUnspsc190501.85284302'),
	'identifierSchemeCodeUnspsc190501.85284303': __('identifierSchemeCodeUnspsc190501.85284303'),
	'identifierSchemeCodeUnspsc190501.85284304': __('identifierSchemeCodeUnspsc190501.85284304'),
	'identifierSchemeCodeUnspsc190501.85284305': __('identifierSchemeCodeUnspsc190501.85284305'),
	'identifierSchemeCodeUnspsc190501.85284400': __('identifierSchemeCodeUnspsc190501.85284400'),
	'identifierSchemeCodeUnspsc190501.85284401': __('identifierSchemeCodeUnspsc190501.85284401'),
	'identifierSchemeCodeUnspsc190501.85284402': __('identifierSchemeCodeUnspsc190501.85284402'),
	'identifierSchemeCodeUnspsc190501.85284403': __('identifierSchemeCodeUnspsc190501.85284403'),
	'identifierSchemeCodeUnspsc190501.85284500': __('identifierSchemeCodeUnspsc190501.85284500'),
	'identifierSchemeCodeUnspsc190501.85284501': __('identifierSchemeCodeUnspsc190501.85284501'),
	'identifierSchemeCodeUnspsc190501.85284502': __('identifierSchemeCodeUnspsc190501.85284502'),
	'identifierSchemeCodeUnspsc190501.85284503': __('identifierSchemeCodeUnspsc190501.85284503'),
	'identifierSchemeCodeUnspsc190501.85284504': __('identifierSchemeCodeUnspsc190501.85284504'),
	'identifierSchemeCodeUnspsc190501.85284600': __('identifierSchemeCodeUnspsc190501.85284600'),
	'identifierSchemeCodeUnspsc190501.85284601': __('identifierSchemeCodeUnspsc190501.85284601'),
	'identifierSchemeCodeUnspsc190501.85284602': __('identifierSchemeCodeUnspsc190501.85284602'),
	'identifierSchemeCodeUnspsc190501.85284603': __('identifierSchemeCodeUnspsc190501.85284603'),
	'identifierSchemeCodeUnspsc190501.85284604': __('identifierSchemeCodeUnspsc190501.85284604'),
	'identifierSchemeCodeUnspsc190501.85284605': __('identifierSchemeCodeUnspsc190501.85284605'),
	'identifierSchemeCodeUnspsc190501.85284606': __('identifierSchemeCodeUnspsc190501.85284606'),
	'identifierSchemeCodeUnspsc190501.85284607': __('identifierSchemeCodeUnspsc190501.85284607'),
	'identifierSchemeCodeUnspsc190501.85284700': __('identifierSchemeCodeUnspsc190501.85284700'),
	'identifierSchemeCodeUnspsc190501.85284701': __('identifierSchemeCodeUnspsc190501.85284701'),
	'identifierSchemeCodeUnspsc190501.85284702': __('identifierSchemeCodeUnspsc190501.85284702'),
	'identifierSchemeCodeUnspsc190501.85284703': __('identifierSchemeCodeUnspsc190501.85284703'),
	'identifierSchemeCodeUnspsc190501.85284800': __('identifierSchemeCodeUnspsc190501.85284800'),
	'identifierSchemeCodeUnspsc190501.85284801': __('identifierSchemeCodeUnspsc190501.85284801'),
	'identifierSchemeCodeUnspsc190501.85284802': __('identifierSchemeCodeUnspsc190501.85284802'),
	'identifierSchemeCodeUnspsc190501.85284803': __('identifierSchemeCodeUnspsc190501.85284803'),
	'identifierSchemeCodeUnspsc190501.85284804': __('identifierSchemeCodeUnspsc190501.85284804'),
	'identifierSchemeCodeUnspsc190501.85284900': __('identifierSchemeCodeUnspsc190501.85284900'),
	'identifierSchemeCodeUnspsc190501.85284901': __('identifierSchemeCodeUnspsc190501.85284901'),
	'identifierSchemeCodeUnspsc190501.85284902': __('identifierSchemeCodeUnspsc190501.85284902'),
	'identifierSchemeCodeUnspsc190501.85285000': __('identifierSchemeCodeUnspsc190501.85285000'),
	'identifierSchemeCodeUnspsc190501.85285001': __('identifierSchemeCodeUnspsc190501.85285001'),
	'identifierSchemeCodeUnspsc190501.85285002': __('identifierSchemeCodeUnspsc190501.85285002'),
	'identifierSchemeCodeUnspsc190501.85285100': __('identifierSchemeCodeUnspsc190501.85285100'),
	'identifierSchemeCodeUnspsc190501.85285101': __('identifierSchemeCodeUnspsc190501.85285101'),
	'identifierSchemeCodeUnspsc190501.85285102': __('identifierSchemeCodeUnspsc190501.85285102'),
	'identifierSchemeCodeUnspsc190501.85285103': __('identifierSchemeCodeUnspsc190501.85285103'),
	'identifierSchemeCodeUnspsc190501.85285104': __('identifierSchemeCodeUnspsc190501.85285104'),
	'identifierSchemeCodeUnspsc190501.85285105': __('identifierSchemeCodeUnspsc190501.85285105'),
	'identifierSchemeCodeUnspsc190501.85285200': __('identifierSchemeCodeUnspsc190501.85285200'),
	'identifierSchemeCodeUnspsc190501.85285201': __('identifierSchemeCodeUnspsc190501.85285201'),
	'identifierSchemeCodeUnspsc190501.85285202': __('identifierSchemeCodeUnspsc190501.85285202'),
	'identifierSchemeCodeUnspsc190501.85285203': __('identifierSchemeCodeUnspsc190501.85285203'),
	'identifierSchemeCodeUnspsc190501.85285300': __('identifierSchemeCodeUnspsc190501.85285300'),
	'identifierSchemeCodeUnspsc190501.85285301': __('identifierSchemeCodeUnspsc190501.85285301'),
	'identifierSchemeCodeUnspsc190501.85285302': __('identifierSchemeCodeUnspsc190501.85285302'),
	'identifierSchemeCodeUnspsc190501.85285303': __('identifierSchemeCodeUnspsc190501.85285303'),
	'identifierSchemeCodeUnspsc190501.85285400': __('identifierSchemeCodeUnspsc190501.85285400'),
	'identifierSchemeCodeUnspsc190501.85285401': __('identifierSchemeCodeUnspsc190501.85285401'),
	'identifierSchemeCodeUnspsc190501.85285402': __('identifierSchemeCodeUnspsc190501.85285402'),
	'identifierSchemeCodeUnspsc190501.85285403': __('identifierSchemeCodeUnspsc190501.85285403'),
	'identifierSchemeCodeUnspsc190501.85285500': __('identifierSchemeCodeUnspsc190501.85285500'),
	'identifierSchemeCodeUnspsc190501.85285501': __('identifierSchemeCodeUnspsc190501.85285501'),
	'identifierSchemeCodeUnspsc190501.85285502': __('identifierSchemeCodeUnspsc190501.85285502'),
	'identifierSchemeCodeUnspsc190501.85285503': __('identifierSchemeCodeUnspsc190501.85285503'),
	'identifierSchemeCodeUnspsc190501.85285504': __('identifierSchemeCodeUnspsc190501.85285504'),
	'identifierSchemeCodeUnspsc190501.85285505': __('identifierSchemeCodeUnspsc190501.85285505'),
	'identifierSchemeCodeUnspsc190501.85285600': __('identifierSchemeCodeUnspsc190501.85285600'),
	'identifierSchemeCodeUnspsc190501.85285601': __('identifierSchemeCodeUnspsc190501.85285601'),
	'identifierSchemeCodeUnspsc190501.85285602': __('identifierSchemeCodeUnspsc190501.85285602'),
	'identifierSchemeCodeUnspsc190501.85285603': __('identifierSchemeCodeUnspsc190501.85285603'),
	'identifierSchemeCodeUnspsc190501.85285604': __('identifierSchemeCodeUnspsc190501.85285604'),
	'identifierSchemeCodeUnspsc190501.85285605': __('identifierSchemeCodeUnspsc190501.85285605'),
	'identifierSchemeCodeUnspsc190501.85285700': __('identifierSchemeCodeUnspsc190501.85285700'),
	'identifierSchemeCodeUnspsc190501.85285701': __('identifierSchemeCodeUnspsc190501.85285701'),
	'identifierSchemeCodeUnspsc190501.85285702': __('identifierSchemeCodeUnspsc190501.85285702'),
	'identifierSchemeCodeUnspsc190501.85285800': __('identifierSchemeCodeUnspsc190501.85285800'),
	'identifierSchemeCodeUnspsc190501.85285801': __('identifierSchemeCodeUnspsc190501.85285801'),
	'identifierSchemeCodeUnspsc190501.85285802': __('identifierSchemeCodeUnspsc190501.85285802'),
	'identifierSchemeCodeUnspsc190501.85285803': __('identifierSchemeCodeUnspsc190501.85285803'),
	'identifierSchemeCodeUnspsc190501.85285804': __('identifierSchemeCodeUnspsc190501.85285804'),
	'identifierSchemeCodeUnspsc190501.85285900': __('identifierSchemeCodeUnspsc190501.85285900'),
	'identifierSchemeCodeUnspsc190501.85285901': __('identifierSchemeCodeUnspsc190501.85285901'),
	'identifierSchemeCodeUnspsc190501.85285902': __('identifierSchemeCodeUnspsc190501.85285902'),
	'identifierSchemeCodeUnspsc190501.85285903': __('identifierSchemeCodeUnspsc190501.85285903'),
	'identifierSchemeCodeUnspsc190501.85285904': __('identifierSchemeCodeUnspsc190501.85285904'),
	'identifierSchemeCodeUnspsc190501.85286000': __('identifierSchemeCodeUnspsc190501.85286000'),
	'identifierSchemeCodeUnspsc190501.85286001': __('identifierSchemeCodeUnspsc190501.85286001'),
	'identifierSchemeCodeUnspsc190501.85286002': __('identifierSchemeCodeUnspsc190501.85286002'),
	'identifierSchemeCodeUnspsc190501.85286003': __('identifierSchemeCodeUnspsc190501.85286003'),
	'identifierSchemeCodeUnspsc190501.85286004': __('identifierSchemeCodeUnspsc190501.85286004'),
	'identifierSchemeCodeUnspsc190501.85286005': __('identifierSchemeCodeUnspsc190501.85286005'),
	'identifierSchemeCodeUnspsc190501.85286100': __('identifierSchemeCodeUnspsc190501.85286100'),
	'identifierSchemeCodeUnspsc190501.85286101': __('identifierSchemeCodeUnspsc190501.85286101'),
	'identifierSchemeCodeUnspsc190501.85286102': __('identifierSchemeCodeUnspsc190501.85286102'),
	'identifierSchemeCodeUnspsc190501.85286103': __('identifierSchemeCodeUnspsc190501.85286103'),
	'identifierSchemeCodeUnspsc190501.85286104': __('identifierSchemeCodeUnspsc190501.85286104'),
	'identifierSchemeCodeUnspsc190501.85286200': __('identifierSchemeCodeUnspsc190501.85286200'),
	'identifierSchemeCodeUnspsc190501.85286201': __('identifierSchemeCodeUnspsc190501.85286201'),
	'identifierSchemeCodeUnspsc190501.85286202': __('identifierSchemeCodeUnspsc190501.85286202'),
	'identifierSchemeCodeUnspsc190501.85286203': __('identifierSchemeCodeUnspsc190501.85286203'),
	'identifierSchemeCodeUnspsc190501.85286204': __('identifierSchemeCodeUnspsc190501.85286204'),
	'identifierSchemeCodeUnspsc190501.85286205': __('identifierSchemeCodeUnspsc190501.85286205'),
	'identifierSchemeCodeUnspsc190501.85286206': __('identifierSchemeCodeUnspsc190501.85286206'),
	'identifierSchemeCodeUnspsc190501.85286300': __('identifierSchemeCodeUnspsc190501.85286300'),
	'identifierSchemeCodeUnspsc190501.85286301': __('identifierSchemeCodeUnspsc190501.85286301'),
	'identifierSchemeCodeUnspsc190501.85286302': __('identifierSchemeCodeUnspsc190501.85286302'),
	'identifierSchemeCodeUnspsc190501.85286400': __('identifierSchemeCodeUnspsc190501.85286400'),
	'identifierSchemeCodeUnspsc190501.85286401': __('identifierSchemeCodeUnspsc190501.85286401'),
	'identifierSchemeCodeUnspsc190501.85286402': __('identifierSchemeCodeUnspsc190501.85286402'),
	'identifierSchemeCodeUnspsc190501.85286403': __('identifierSchemeCodeUnspsc190501.85286403'),
	'identifierSchemeCodeUnspsc190501.85290000': __('identifierSchemeCodeUnspsc190501.85290000'),
	'identifierSchemeCodeUnspsc190501.85291500': __('identifierSchemeCodeUnspsc190501.85291500'),
	'identifierSchemeCodeUnspsc190501.85291501': __('identifierSchemeCodeUnspsc190501.85291501'),
	'identifierSchemeCodeUnspsc190501.85291502': __('identifierSchemeCodeUnspsc190501.85291502'),
	'identifierSchemeCodeUnspsc190501.85291600': __('identifierSchemeCodeUnspsc190501.85291600'),
	'identifierSchemeCodeUnspsc190501.85291601': __('identifierSchemeCodeUnspsc190501.85291601'),
	'identifierSchemeCodeUnspsc190501.85291602': __('identifierSchemeCodeUnspsc190501.85291602'),
	'identifierSchemeCodeUnspsc190501.85291700': __('identifierSchemeCodeUnspsc190501.85291700'),
	'identifierSchemeCodeUnspsc190501.85291701': __('identifierSchemeCodeUnspsc190501.85291701'),
	'identifierSchemeCodeUnspsc190501.85291702': __('identifierSchemeCodeUnspsc190501.85291702'),
	'identifierSchemeCodeUnspsc190501.85291703': __('identifierSchemeCodeUnspsc190501.85291703'),
	'identifierSchemeCodeUnspsc190501.85291704': __('identifierSchemeCodeUnspsc190501.85291704'),
	'identifierSchemeCodeUnspsc190501.85291705': __('identifierSchemeCodeUnspsc190501.85291705'),
	'identifierSchemeCodeUnspsc190501.85291706': __('identifierSchemeCodeUnspsc190501.85291706'),
	'identifierSchemeCodeUnspsc190501.85291707': __('identifierSchemeCodeUnspsc190501.85291707'),
	'identifierSchemeCodeUnspsc190501.85291800': __('identifierSchemeCodeUnspsc190501.85291800'),
	'identifierSchemeCodeUnspsc190501.85291801': __('identifierSchemeCodeUnspsc190501.85291801'),
	'identifierSchemeCodeUnspsc190501.85291802': __('identifierSchemeCodeUnspsc190501.85291802'),
	'identifierSchemeCodeUnspsc190501.85291803': __('identifierSchemeCodeUnspsc190501.85291803'),
	'identifierSchemeCodeUnspsc190501.85291804': __('identifierSchemeCodeUnspsc190501.85291804'),
	'identifierSchemeCodeUnspsc190501.85291900': __('identifierSchemeCodeUnspsc190501.85291900'),
	'identifierSchemeCodeUnspsc190501.85291901': __('identifierSchemeCodeUnspsc190501.85291901'),
	'identifierSchemeCodeUnspsc190501.85291902': __('identifierSchemeCodeUnspsc190501.85291902'),
	'identifierSchemeCodeUnspsc190501.85291903': __('identifierSchemeCodeUnspsc190501.85291903'),
	'identifierSchemeCodeUnspsc190501.85291904': __('identifierSchemeCodeUnspsc190501.85291904'),
	'identifierSchemeCodeUnspsc190501.85291905': __('identifierSchemeCodeUnspsc190501.85291905'),
	'identifierSchemeCodeUnspsc190501.85291906': __('identifierSchemeCodeUnspsc190501.85291906'),
	'identifierSchemeCodeUnspsc190501.85292000': __('identifierSchemeCodeUnspsc190501.85292000'),
	'identifierSchemeCodeUnspsc190501.85292001': __('identifierSchemeCodeUnspsc190501.85292001'),
	'identifierSchemeCodeUnspsc190501.85292002': __('identifierSchemeCodeUnspsc190501.85292002'),
	'identifierSchemeCodeUnspsc190501.85292003': __('identifierSchemeCodeUnspsc190501.85292003'),
	'identifierSchemeCodeUnspsc190501.85292100': __('identifierSchemeCodeUnspsc190501.85292100'),
	'identifierSchemeCodeUnspsc190501.85292101': __('identifierSchemeCodeUnspsc190501.85292101'),
	'identifierSchemeCodeUnspsc190501.85292102': __('identifierSchemeCodeUnspsc190501.85292102'),
	'identifierSchemeCodeUnspsc190501.85292103': __('identifierSchemeCodeUnspsc190501.85292103'),
	'identifierSchemeCodeUnspsc190501.85292104': __('identifierSchemeCodeUnspsc190501.85292104'),
	'identifierSchemeCodeUnspsc190501.85292105': __('identifierSchemeCodeUnspsc190501.85292105'),
	'identifierSchemeCodeUnspsc190501.85292106': __('identifierSchemeCodeUnspsc190501.85292106'),
	'identifierSchemeCodeUnspsc190501.85292107': __('identifierSchemeCodeUnspsc190501.85292107'),
	'identifierSchemeCodeUnspsc190501.85292108': __('identifierSchemeCodeUnspsc190501.85292108'),
	'identifierSchemeCodeUnspsc190501.85292109': __('identifierSchemeCodeUnspsc190501.85292109'),
	'identifierSchemeCodeUnspsc190501.85292110': __('identifierSchemeCodeUnspsc190501.85292110'),
	'identifierSchemeCodeUnspsc190501.85292111': __('identifierSchemeCodeUnspsc190501.85292111'),
	'identifierSchemeCodeUnspsc190501.85292200': __('identifierSchemeCodeUnspsc190501.85292200'),
	'identifierSchemeCodeUnspsc190501.85292201': __('identifierSchemeCodeUnspsc190501.85292201'),
	'identifierSchemeCodeUnspsc190501.85292202': __('identifierSchemeCodeUnspsc190501.85292202'),
	'identifierSchemeCodeUnspsc190501.85292300': __('identifierSchemeCodeUnspsc190501.85292300'),
	'identifierSchemeCodeUnspsc190501.85292301': __('identifierSchemeCodeUnspsc190501.85292301'),
	'identifierSchemeCodeUnspsc190501.85292302': __('identifierSchemeCodeUnspsc190501.85292302'),
	'identifierSchemeCodeUnspsc190501.85292303': __('identifierSchemeCodeUnspsc190501.85292303'),
	'identifierSchemeCodeUnspsc190501.85292304': __('identifierSchemeCodeUnspsc190501.85292304'),
	'identifierSchemeCodeUnspsc190501.85292400': __('identifierSchemeCodeUnspsc190501.85292400'),
	'identifierSchemeCodeUnspsc190501.85292401': __('identifierSchemeCodeUnspsc190501.85292401'),
	'identifierSchemeCodeUnspsc190501.85292402': __('identifierSchemeCodeUnspsc190501.85292402'),
	'identifierSchemeCodeUnspsc190501.85292403': __('identifierSchemeCodeUnspsc190501.85292403'),
	'identifierSchemeCodeUnspsc190501.85292404': __('identifierSchemeCodeUnspsc190501.85292404'),
	'identifierSchemeCodeUnspsc190501.85292405': __('identifierSchemeCodeUnspsc190501.85292405'),
	'identifierSchemeCodeUnspsc190501.85292406': __('identifierSchemeCodeUnspsc190501.85292406'),
	'identifierSchemeCodeUnspsc190501.85292407': __('identifierSchemeCodeUnspsc190501.85292407'),
	'identifierSchemeCodeUnspsc190501.85292408': __('identifierSchemeCodeUnspsc190501.85292408'),
	'identifierSchemeCodeUnspsc190501.85292409': __('identifierSchemeCodeUnspsc190501.85292409'),
	'identifierSchemeCodeUnspsc190501.85292500': __('identifierSchemeCodeUnspsc190501.85292500'),
	'identifierSchemeCodeUnspsc190501.85292501': __('identifierSchemeCodeUnspsc190501.85292501'),
	'identifierSchemeCodeUnspsc190501.85292502': __('identifierSchemeCodeUnspsc190501.85292502'),
	'identifierSchemeCodeUnspsc190501.85292503': __('identifierSchemeCodeUnspsc190501.85292503'),
	'identifierSchemeCodeUnspsc190501.85292600': __('identifierSchemeCodeUnspsc190501.85292600'),
	'identifierSchemeCodeUnspsc190501.85292601': __('identifierSchemeCodeUnspsc190501.85292601'),
	'identifierSchemeCodeUnspsc190501.85292602': __('identifierSchemeCodeUnspsc190501.85292602'),
	'identifierSchemeCodeUnspsc190501.85292603': __('identifierSchemeCodeUnspsc190501.85292603'),
	'identifierSchemeCodeUnspsc190501.85292604': __('identifierSchemeCodeUnspsc190501.85292604'),
	'identifierSchemeCodeUnspsc190501.85292605': __('identifierSchemeCodeUnspsc190501.85292605'),
	'identifierSchemeCodeUnspsc190501.85292700': __('identifierSchemeCodeUnspsc190501.85292700'),
	'identifierSchemeCodeUnspsc190501.85292701': __('identifierSchemeCodeUnspsc190501.85292701'),
	'identifierSchemeCodeUnspsc190501.85292702': __('identifierSchemeCodeUnspsc190501.85292702'),
	'identifierSchemeCodeUnspsc190501.85292703': __('identifierSchemeCodeUnspsc190501.85292703'),
	'identifierSchemeCodeUnspsc190501.85292704': __('identifierSchemeCodeUnspsc190501.85292704'),
	'identifierSchemeCodeUnspsc190501.85292705': __('identifierSchemeCodeUnspsc190501.85292705'),
	'identifierSchemeCodeUnspsc190501.85292706': __('identifierSchemeCodeUnspsc190501.85292706'),
	'identifierSchemeCodeUnspsc190501.85292707': __('identifierSchemeCodeUnspsc190501.85292707'),
	'identifierSchemeCodeUnspsc190501.85292708': __('identifierSchemeCodeUnspsc190501.85292708'),
	'identifierSchemeCodeUnspsc190501.85292709': __('identifierSchemeCodeUnspsc190501.85292709'),
	'identifierSchemeCodeUnspsc190501.85292800': __('identifierSchemeCodeUnspsc190501.85292800'),
	'identifierSchemeCodeUnspsc190501.85292801': __('identifierSchemeCodeUnspsc190501.85292801'),
	'identifierSchemeCodeUnspsc190501.85292802': __('identifierSchemeCodeUnspsc190501.85292802'),
	'identifierSchemeCodeUnspsc190501.85292900': __('identifierSchemeCodeUnspsc190501.85292900'),
	'identifierSchemeCodeUnspsc190501.85292901': __('identifierSchemeCodeUnspsc190501.85292901'),
	'identifierSchemeCodeUnspsc190501.85292902': __('identifierSchemeCodeUnspsc190501.85292902'),
	'identifierSchemeCodeUnspsc190501.85292903': __('identifierSchemeCodeUnspsc190501.85292903'),
	'identifierSchemeCodeUnspsc190501.85293000': __('identifierSchemeCodeUnspsc190501.85293000'),
	'identifierSchemeCodeUnspsc190501.85293001': __('identifierSchemeCodeUnspsc190501.85293001'),
	'identifierSchemeCodeUnspsc190501.85293002': __('identifierSchemeCodeUnspsc190501.85293002'),
	'identifierSchemeCodeUnspsc190501.85293003': __('identifierSchemeCodeUnspsc190501.85293003'),
	'identifierSchemeCodeUnspsc190501.85293004': __('identifierSchemeCodeUnspsc190501.85293004'),
	'identifierSchemeCodeUnspsc190501.85293005': __('identifierSchemeCodeUnspsc190501.85293005'),
	'identifierSchemeCodeUnspsc190501.85293006': __('identifierSchemeCodeUnspsc190501.85293006'),
	'identifierSchemeCodeUnspsc190501.85293100': __('identifierSchemeCodeUnspsc190501.85293100'),
	'identifierSchemeCodeUnspsc190501.85293101': __('identifierSchemeCodeUnspsc190501.85293101'),
	'identifierSchemeCodeUnspsc190501.85293102': __('identifierSchemeCodeUnspsc190501.85293102'),
	'identifierSchemeCodeUnspsc190501.85293103': __('identifierSchemeCodeUnspsc190501.85293103'),
	'identifierSchemeCodeUnspsc190501.85293104': __('identifierSchemeCodeUnspsc190501.85293104'),
	'identifierSchemeCodeUnspsc190501.85293105': __('identifierSchemeCodeUnspsc190501.85293105'),
	'identifierSchemeCodeUnspsc190501.85293200': __('identifierSchemeCodeUnspsc190501.85293200'),
	'identifierSchemeCodeUnspsc190501.85293201': __('identifierSchemeCodeUnspsc190501.85293201'),
	'identifierSchemeCodeUnspsc190501.85293202': __('identifierSchemeCodeUnspsc190501.85293202'),
	'identifierSchemeCodeUnspsc190501.85293203': __('identifierSchemeCodeUnspsc190501.85293203'),
	'identifierSchemeCodeUnspsc190501.85293204': __('identifierSchemeCodeUnspsc190501.85293204'),
	'identifierSchemeCodeUnspsc190501.85293205': __('identifierSchemeCodeUnspsc190501.85293205'),
	'identifierSchemeCodeUnspsc190501.85293206': __('identifierSchemeCodeUnspsc190501.85293206'),
	'identifierSchemeCodeUnspsc190501.85293207': __('identifierSchemeCodeUnspsc190501.85293207'),
	'identifierSchemeCodeUnspsc190501.85293208': __('identifierSchemeCodeUnspsc190501.85293208'),
	'identifierSchemeCodeUnspsc190501.85293209': __('identifierSchemeCodeUnspsc190501.85293209'),
	'identifierSchemeCodeUnspsc190501.85293210': __('identifierSchemeCodeUnspsc190501.85293210'),
	'identifierSchemeCodeUnspsc190501.85293300': __('identifierSchemeCodeUnspsc190501.85293300'),
	'identifierSchemeCodeUnspsc190501.85293301': __('identifierSchemeCodeUnspsc190501.85293301'),
	'identifierSchemeCodeUnspsc190501.85293302': __('identifierSchemeCodeUnspsc190501.85293302'),
	'identifierSchemeCodeUnspsc190501.85293303': __('identifierSchemeCodeUnspsc190501.85293303'),
	'identifierSchemeCodeUnspsc190501.85293304': __('identifierSchemeCodeUnspsc190501.85293304'),
	'identifierSchemeCodeUnspsc190501.85293305': __('identifierSchemeCodeUnspsc190501.85293305'),
	'identifierSchemeCodeUnspsc190501.85293306': __('identifierSchemeCodeUnspsc190501.85293306'),
	'identifierSchemeCodeUnspsc190501.85293307': __('identifierSchemeCodeUnspsc190501.85293307'),
	'identifierSchemeCodeUnspsc190501.85293400': __('identifierSchemeCodeUnspsc190501.85293400'),
	'identifierSchemeCodeUnspsc190501.85293401': __('identifierSchemeCodeUnspsc190501.85293401'),
	'identifierSchemeCodeUnspsc190501.85293402': __('identifierSchemeCodeUnspsc190501.85293402'),
	'identifierSchemeCodeUnspsc190501.85293403': __('identifierSchemeCodeUnspsc190501.85293403'),
	'identifierSchemeCodeUnspsc190501.85293500': __('identifierSchemeCodeUnspsc190501.85293500'),
	'identifierSchemeCodeUnspsc190501.85293501': __('identifierSchemeCodeUnspsc190501.85293501'),
	'identifierSchemeCodeUnspsc190501.85293502': __('identifierSchemeCodeUnspsc190501.85293502'),
	'identifierSchemeCodeUnspsc190501.85293503': __('identifierSchemeCodeUnspsc190501.85293503'),
	'identifierSchemeCodeUnspsc190501.85293504': __('identifierSchemeCodeUnspsc190501.85293504'),
	'identifierSchemeCodeUnspsc190501.85293505': __('identifierSchemeCodeUnspsc190501.85293505'),
	'identifierSchemeCodeUnspsc190501.85293506': __('identifierSchemeCodeUnspsc190501.85293506'),
	'identifierSchemeCodeUnspsc190501.85293600': __('identifierSchemeCodeUnspsc190501.85293600'),
	'identifierSchemeCodeUnspsc190501.85293601': __('identifierSchemeCodeUnspsc190501.85293601'),
	'identifierSchemeCodeUnspsc190501.85293602': __('identifierSchemeCodeUnspsc190501.85293602'),
	'identifierSchemeCodeUnspsc190501.85293700': __('identifierSchemeCodeUnspsc190501.85293700'),
	'identifierSchemeCodeUnspsc190501.85293701': __('identifierSchemeCodeUnspsc190501.85293701'),
	'identifierSchemeCodeUnspsc190501.85293702': __('identifierSchemeCodeUnspsc190501.85293702'),
	'identifierSchemeCodeUnspsc190501.85293703': __('identifierSchemeCodeUnspsc190501.85293703'),
	'identifierSchemeCodeUnspsc190501.85293704': __('identifierSchemeCodeUnspsc190501.85293704'),
	'identifierSchemeCodeUnspsc190501.85293705': __('identifierSchemeCodeUnspsc190501.85293705'),
	'identifierSchemeCodeUnspsc190501.85293800': __('identifierSchemeCodeUnspsc190501.85293800'),
	'identifierSchemeCodeUnspsc190501.85293801': __('identifierSchemeCodeUnspsc190501.85293801'),
	'identifierSchemeCodeUnspsc190501.85293802': __('identifierSchemeCodeUnspsc190501.85293802'),
	'identifierSchemeCodeUnspsc190501.85293803': __('identifierSchemeCodeUnspsc190501.85293803'),
	'identifierSchemeCodeUnspsc190501.85293804': __('identifierSchemeCodeUnspsc190501.85293804'),
	'identifierSchemeCodeUnspsc190501.85293805': __('identifierSchemeCodeUnspsc190501.85293805'),
	'identifierSchemeCodeUnspsc190501.85293806': __('identifierSchemeCodeUnspsc190501.85293806'),
	'identifierSchemeCodeUnspsc190501.85293807': __('identifierSchemeCodeUnspsc190501.85293807'),
	'identifierSchemeCodeUnspsc190501.85293808': __('identifierSchemeCodeUnspsc190501.85293808'),
	'identifierSchemeCodeUnspsc190501.85293809': __('identifierSchemeCodeUnspsc190501.85293809'),
	'identifierSchemeCodeUnspsc190501.85293810': __('identifierSchemeCodeUnspsc190501.85293810'),
	'identifierSchemeCodeUnspsc190501.85293811': __('identifierSchemeCodeUnspsc190501.85293811'),
	'identifierSchemeCodeUnspsc190501.85293900': __('identifierSchemeCodeUnspsc190501.85293900'),
	'identifierSchemeCodeUnspsc190501.85293901': __('identifierSchemeCodeUnspsc190501.85293901'),
	'identifierSchemeCodeUnspsc190501.85293902': __('identifierSchemeCodeUnspsc190501.85293902'),
	'identifierSchemeCodeUnspsc190501.85293903': __('identifierSchemeCodeUnspsc190501.85293903'),
	'identifierSchemeCodeUnspsc190501.85294000': __('identifierSchemeCodeUnspsc190501.85294000'),
	'identifierSchemeCodeUnspsc190501.85294001': __('identifierSchemeCodeUnspsc190501.85294001'),
	'identifierSchemeCodeUnspsc190501.85294002': __('identifierSchemeCodeUnspsc190501.85294002'),
	'identifierSchemeCodeUnspsc190501.85294003': __('identifierSchemeCodeUnspsc190501.85294003'),
	'identifierSchemeCodeUnspsc190501.85294004': __('identifierSchemeCodeUnspsc190501.85294004'),
	'identifierSchemeCodeUnspsc190501.85294005': __('identifierSchemeCodeUnspsc190501.85294005'),
	'identifierSchemeCodeUnspsc190501.85294006': __('identifierSchemeCodeUnspsc190501.85294006'),
	'identifierSchemeCodeUnspsc190501.85294100': __('identifierSchemeCodeUnspsc190501.85294100'),
	'identifierSchemeCodeUnspsc190501.85294101': __('identifierSchemeCodeUnspsc190501.85294101'),
	'identifierSchemeCodeUnspsc190501.85294102': __('identifierSchemeCodeUnspsc190501.85294102'),
	'identifierSchemeCodeUnspsc190501.85294103': __('identifierSchemeCodeUnspsc190501.85294103'),
	'identifierSchemeCodeUnspsc190501.85294104': __('identifierSchemeCodeUnspsc190501.85294104'),
	'identifierSchemeCodeUnspsc190501.85294105': __('identifierSchemeCodeUnspsc190501.85294105'),
	'identifierSchemeCodeUnspsc190501.85294106': __('identifierSchemeCodeUnspsc190501.85294106'),
	'identifierSchemeCodeUnspsc190501.85294200': __('identifierSchemeCodeUnspsc190501.85294200'),
	'identifierSchemeCodeUnspsc190501.85294201': __('identifierSchemeCodeUnspsc190501.85294201'),
	'identifierSchemeCodeUnspsc190501.85294202': __('identifierSchemeCodeUnspsc190501.85294202'),
	'identifierSchemeCodeUnspsc190501.85294203': __('identifierSchemeCodeUnspsc190501.85294203'),
	'identifierSchemeCodeUnspsc190501.85294204': __('identifierSchemeCodeUnspsc190501.85294204'),
	'identifierSchemeCodeUnspsc190501.85294205': __('identifierSchemeCodeUnspsc190501.85294205'),
	'identifierSchemeCodeUnspsc190501.85294206': __('identifierSchemeCodeUnspsc190501.85294206'),
	'identifierSchemeCodeUnspsc190501.85294207': __('identifierSchemeCodeUnspsc190501.85294207'),
	'identifierSchemeCodeUnspsc190501.85294300': __('identifierSchemeCodeUnspsc190501.85294300'),
	'identifierSchemeCodeUnspsc190501.85294301': __('identifierSchemeCodeUnspsc190501.85294301'),
	'identifierSchemeCodeUnspsc190501.85294302': __('identifierSchemeCodeUnspsc190501.85294302'),
	'identifierSchemeCodeUnspsc190501.85294303': __('identifierSchemeCodeUnspsc190501.85294303'),
	'identifierSchemeCodeUnspsc190501.85300000': __('identifierSchemeCodeUnspsc190501.85300000'),
	'identifierSchemeCodeUnspsc190501.85301500': __('identifierSchemeCodeUnspsc190501.85301500'),
	'identifierSchemeCodeUnspsc190501.85301501': __('identifierSchemeCodeUnspsc190501.85301501'),
	'identifierSchemeCodeUnspsc190501.85301502': __('identifierSchemeCodeUnspsc190501.85301502'),
	'identifierSchemeCodeUnspsc190501.85301503': __('identifierSchemeCodeUnspsc190501.85301503'),
	'identifierSchemeCodeUnspsc190501.85301504': __('identifierSchemeCodeUnspsc190501.85301504'),
	'identifierSchemeCodeUnspsc190501.85301505': __('identifierSchemeCodeUnspsc190501.85301505'),
	'identifierSchemeCodeUnspsc190501.85301506': __('identifierSchemeCodeUnspsc190501.85301506'),
	'identifierSchemeCodeUnspsc190501.85301507': __('identifierSchemeCodeUnspsc190501.85301507'),
	'identifierSchemeCodeUnspsc190501.85301600': __('identifierSchemeCodeUnspsc190501.85301600'),
	'identifierSchemeCodeUnspsc190501.85301601': __('identifierSchemeCodeUnspsc190501.85301601'),
	'identifierSchemeCodeUnspsc190501.85301602': __('identifierSchemeCodeUnspsc190501.85301602'),
	'identifierSchemeCodeUnspsc190501.85301603': __('identifierSchemeCodeUnspsc190501.85301603'),
	'identifierSchemeCodeUnspsc190501.85301604': __('identifierSchemeCodeUnspsc190501.85301604'),
	'identifierSchemeCodeUnspsc190501.85301605': __('identifierSchemeCodeUnspsc190501.85301605'),
	'identifierSchemeCodeUnspsc190501.85301606': __('identifierSchemeCodeUnspsc190501.85301606'),
	'identifierSchemeCodeUnspsc190501.85301607': __('identifierSchemeCodeUnspsc190501.85301607'),
	'identifierSchemeCodeUnspsc190501.85301700': __('identifierSchemeCodeUnspsc190501.85301700'),
	'identifierSchemeCodeUnspsc190501.85301701': __('identifierSchemeCodeUnspsc190501.85301701'),
	'identifierSchemeCodeUnspsc190501.85301702': __('identifierSchemeCodeUnspsc190501.85301702'),
	'identifierSchemeCodeUnspsc190501.85301703': __('identifierSchemeCodeUnspsc190501.85301703'),
	'identifierSchemeCodeUnspsc190501.85301800': __('identifierSchemeCodeUnspsc190501.85301800'),
	'identifierSchemeCodeUnspsc190501.85301801': __('identifierSchemeCodeUnspsc190501.85301801'),
	'identifierSchemeCodeUnspsc190501.85301802': __('identifierSchemeCodeUnspsc190501.85301802'),
	'identifierSchemeCodeUnspsc190501.85301803': __('identifierSchemeCodeUnspsc190501.85301803'),
	'identifierSchemeCodeUnspsc190501.85301900': __('identifierSchemeCodeUnspsc190501.85301900'),
	'identifierSchemeCodeUnspsc190501.85301901': __('identifierSchemeCodeUnspsc190501.85301901'),
	'identifierSchemeCodeUnspsc190501.85301902': __('identifierSchemeCodeUnspsc190501.85301902'),
	'identifierSchemeCodeUnspsc190501.85301903': __('identifierSchemeCodeUnspsc190501.85301903'),
	'identifierSchemeCodeUnspsc190501.85302000': __('identifierSchemeCodeUnspsc190501.85302000'),
	'identifierSchemeCodeUnspsc190501.85302001': __('identifierSchemeCodeUnspsc190501.85302001'),
	'identifierSchemeCodeUnspsc190501.85302002': __('identifierSchemeCodeUnspsc190501.85302002'),
	'identifierSchemeCodeUnspsc190501.85302003': __('identifierSchemeCodeUnspsc190501.85302003'),
	'identifierSchemeCodeUnspsc190501.85302004': __('identifierSchemeCodeUnspsc190501.85302004'),
	'identifierSchemeCodeUnspsc190501.85302100': __('identifierSchemeCodeUnspsc190501.85302100'),
	'identifierSchemeCodeUnspsc190501.85302101': __('identifierSchemeCodeUnspsc190501.85302101'),
	'identifierSchemeCodeUnspsc190501.85302102': __('identifierSchemeCodeUnspsc190501.85302102'),
	'identifierSchemeCodeUnspsc190501.85302103': __('identifierSchemeCodeUnspsc190501.85302103'),
	'identifierSchemeCodeUnspsc190501.85302104': __('identifierSchemeCodeUnspsc190501.85302104'),
	'identifierSchemeCodeUnspsc190501.85302200': __('identifierSchemeCodeUnspsc190501.85302200'),
	'identifierSchemeCodeUnspsc190501.85302201': __('identifierSchemeCodeUnspsc190501.85302201'),
	'identifierSchemeCodeUnspsc190501.85302202': __('identifierSchemeCodeUnspsc190501.85302202'),
	'identifierSchemeCodeUnspsc190501.85302203': __('identifierSchemeCodeUnspsc190501.85302203'),
	'identifierSchemeCodeUnspsc190501.85302204': __('identifierSchemeCodeUnspsc190501.85302204'),
	'identifierSchemeCodeUnspsc190501.85302205': __('identifierSchemeCodeUnspsc190501.85302205'),
	'identifierSchemeCodeUnspsc190501.85302300': __('identifierSchemeCodeUnspsc190501.85302300'),
	'identifierSchemeCodeUnspsc190501.85302301': __('identifierSchemeCodeUnspsc190501.85302301'),
	'identifierSchemeCodeUnspsc190501.85302302': __('identifierSchemeCodeUnspsc190501.85302302'),
	'identifierSchemeCodeUnspsc190501.85302303': __('identifierSchemeCodeUnspsc190501.85302303'),
	'identifierSchemeCodeUnspsc190501.85302304': __('identifierSchemeCodeUnspsc190501.85302304'),
	'identifierSchemeCodeUnspsc190501.85310000': __('identifierSchemeCodeUnspsc190501.85310000'),
	'identifierSchemeCodeUnspsc190501.85311500': __('identifierSchemeCodeUnspsc190501.85311500'),
	'identifierSchemeCodeUnspsc190501.85311501': __('identifierSchemeCodeUnspsc190501.85311501'),
	'identifierSchemeCodeUnspsc190501.85311502': __('identifierSchemeCodeUnspsc190501.85311502'),
	'identifierSchemeCodeUnspsc190501.85311503': __('identifierSchemeCodeUnspsc190501.85311503'),
	'identifierSchemeCodeUnspsc190501.85311600': __('identifierSchemeCodeUnspsc190501.85311600'),
	'identifierSchemeCodeUnspsc190501.85311601': __('identifierSchemeCodeUnspsc190501.85311601'),
	'identifierSchemeCodeUnspsc190501.85311602': __('identifierSchemeCodeUnspsc190501.85311602'),
	'identifierSchemeCodeUnspsc190501.85311700': __('identifierSchemeCodeUnspsc190501.85311700'),
	'identifierSchemeCodeUnspsc190501.85311701': __('identifierSchemeCodeUnspsc190501.85311701'),
	'identifierSchemeCodeUnspsc190501.85311702': __('identifierSchemeCodeUnspsc190501.85311702'),
	'identifierSchemeCodeUnspsc190501.85311703': __('identifierSchemeCodeUnspsc190501.85311703'),
	'identifierSchemeCodeUnspsc190501.85311800': __('identifierSchemeCodeUnspsc190501.85311800'),
	'identifierSchemeCodeUnspsc190501.85311801': __('identifierSchemeCodeUnspsc190501.85311801'),
	'identifierSchemeCodeUnspsc190501.85311802': __('identifierSchemeCodeUnspsc190501.85311802'),
	'identifierSchemeCodeUnspsc190501.85311803': __('identifierSchemeCodeUnspsc190501.85311803'),
	'identifierSchemeCodeUnspsc190501.85311900': __('identifierSchemeCodeUnspsc190501.85311900'),
	'identifierSchemeCodeUnspsc190501.85311901': __('identifierSchemeCodeUnspsc190501.85311901'),
	'identifierSchemeCodeUnspsc190501.85311902': __('identifierSchemeCodeUnspsc190501.85311902'),
	'identifierSchemeCodeUnspsc190501.85311903': __('identifierSchemeCodeUnspsc190501.85311903'),
	'identifierSchemeCodeUnspsc190501.85312000': __('identifierSchemeCodeUnspsc190501.85312000'),
	'identifierSchemeCodeUnspsc190501.85312001': __('identifierSchemeCodeUnspsc190501.85312001'),
	'identifierSchemeCodeUnspsc190501.85312002': __('identifierSchemeCodeUnspsc190501.85312002'),
	'identifierSchemeCodeUnspsc190501.85312003': __('identifierSchemeCodeUnspsc190501.85312003'),
	'identifierSchemeCodeUnspsc190501.85312004': __('identifierSchemeCodeUnspsc190501.85312004'),
	'identifierSchemeCodeUnspsc190501.85312100': __('identifierSchemeCodeUnspsc190501.85312100'),
	'identifierSchemeCodeUnspsc190501.85312101': __('identifierSchemeCodeUnspsc190501.85312101'),
	'identifierSchemeCodeUnspsc190501.85312102': __('identifierSchemeCodeUnspsc190501.85312102'),
	'identifierSchemeCodeUnspsc190501.85312200': __('identifierSchemeCodeUnspsc190501.85312200'),
	'identifierSchemeCodeUnspsc190501.85312201': __('identifierSchemeCodeUnspsc190501.85312201'),
	'identifierSchemeCodeUnspsc190501.85312202': __('identifierSchemeCodeUnspsc190501.85312202'),
	'identifierSchemeCodeUnspsc190501.85312203': __('identifierSchemeCodeUnspsc190501.85312203'),
	'identifierSchemeCodeUnspsc190501.85312300': __('identifierSchemeCodeUnspsc190501.85312300'),
	'identifierSchemeCodeUnspsc190501.85312301': __('identifierSchemeCodeUnspsc190501.85312301'),
	'identifierSchemeCodeUnspsc190501.85312302': __('identifierSchemeCodeUnspsc190501.85312302'),
	'identifierSchemeCodeUnspsc190501.85312400': __('identifierSchemeCodeUnspsc190501.85312400'),
	'identifierSchemeCodeUnspsc190501.85312401': __('identifierSchemeCodeUnspsc190501.85312401'),
	'identifierSchemeCodeUnspsc190501.85312402': __('identifierSchemeCodeUnspsc190501.85312402'),
	'identifierSchemeCodeUnspsc190501.85312403': __('identifierSchemeCodeUnspsc190501.85312403'),
	'identifierSchemeCodeUnspsc190501.85312500': __('identifierSchemeCodeUnspsc190501.85312500'),
	'identifierSchemeCodeUnspsc190501.85312501': __('identifierSchemeCodeUnspsc190501.85312501'),
	'identifierSchemeCodeUnspsc190501.85312502': __('identifierSchemeCodeUnspsc190501.85312502'),
	'identifierSchemeCodeUnspsc190501.85312600': __('identifierSchemeCodeUnspsc190501.85312600'),
	'identifierSchemeCodeUnspsc190501.85312601': __('identifierSchemeCodeUnspsc190501.85312601'),
	'identifierSchemeCodeUnspsc190501.85312602': __('identifierSchemeCodeUnspsc190501.85312602'),
	'identifierSchemeCodeUnspsc190501.85312700': __('identifierSchemeCodeUnspsc190501.85312700'),
	'identifierSchemeCodeUnspsc190501.85312701': __('identifierSchemeCodeUnspsc190501.85312701'),
	'identifierSchemeCodeUnspsc190501.85312702': __('identifierSchemeCodeUnspsc190501.85312702'),
	'identifierSchemeCodeUnspsc190501.85312703': __('identifierSchemeCodeUnspsc190501.85312703'),
	'identifierSchemeCodeUnspsc190501.85312800': __('identifierSchemeCodeUnspsc190501.85312800'),
	'identifierSchemeCodeUnspsc190501.85312801': __('identifierSchemeCodeUnspsc190501.85312801'),
	'identifierSchemeCodeUnspsc190501.85312802': __('identifierSchemeCodeUnspsc190501.85312802'),
	'identifierSchemeCodeUnspsc190501.85312900': __('identifierSchemeCodeUnspsc190501.85312900'),
	'identifierSchemeCodeUnspsc190501.85312901': __('identifierSchemeCodeUnspsc190501.85312901'),
	'identifierSchemeCodeUnspsc190501.85312902': __('identifierSchemeCodeUnspsc190501.85312902'),
	'identifierSchemeCodeUnspsc190501.85312903': __('identifierSchemeCodeUnspsc190501.85312903'),
	'identifierSchemeCodeUnspsc190501.85312904': __('identifierSchemeCodeUnspsc190501.85312904'),
	'identifierSchemeCodeUnspsc190501.85312905': __('identifierSchemeCodeUnspsc190501.85312905'),
	'identifierSchemeCodeUnspsc190501.85312906': __('identifierSchemeCodeUnspsc190501.85312906'),
	'identifierSchemeCodeUnspsc190501.85312907': __('identifierSchemeCodeUnspsc190501.85312907'),
	'identifierSchemeCodeUnspsc190501.85313000': __('identifierSchemeCodeUnspsc190501.85313000'),
	'identifierSchemeCodeUnspsc190501.85313001': __('identifierSchemeCodeUnspsc190501.85313001'),
	'identifierSchemeCodeUnspsc190501.85313002': __('identifierSchemeCodeUnspsc190501.85313002'),
	'identifierSchemeCodeUnspsc190501.85313100': __('identifierSchemeCodeUnspsc190501.85313100'),
	'identifierSchemeCodeUnspsc190501.85313101': __('identifierSchemeCodeUnspsc190501.85313101'),
	'identifierSchemeCodeUnspsc190501.85313102': __('identifierSchemeCodeUnspsc190501.85313102'),
	'identifierSchemeCodeUnspsc190501.85313103': __('identifierSchemeCodeUnspsc190501.85313103'),
	'identifierSchemeCodeUnspsc190501.85313104': __('identifierSchemeCodeUnspsc190501.85313104'),
	'identifierSchemeCodeUnspsc190501.85313105': __('identifierSchemeCodeUnspsc190501.85313105'),
	'identifierSchemeCodeUnspsc190501.85313106': __('identifierSchemeCodeUnspsc190501.85313106'),
	'identifierSchemeCodeUnspsc190501.85313107': __('identifierSchemeCodeUnspsc190501.85313107'),
	'identifierSchemeCodeUnspsc190501.85313200': __('identifierSchemeCodeUnspsc190501.85313200'),
	'identifierSchemeCodeUnspsc190501.85313201': __('identifierSchemeCodeUnspsc190501.85313201'),
	'identifierSchemeCodeUnspsc190501.85313202': __('identifierSchemeCodeUnspsc190501.85313202'),
	'identifierSchemeCodeUnspsc190501.85313203': __('identifierSchemeCodeUnspsc190501.85313203'),
	'identifierSchemeCodeUnspsc190501.85313204': __('identifierSchemeCodeUnspsc190501.85313204'),
	'identifierSchemeCodeUnspsc190501.85313300': __('identifierSchemeCodeUnspsc190501.85313300'),
	'identifierSchemeCodeUnspsc190501.85313301': __('identifierSchemeCodeUnspsc190501.85313301'),
	'identifierSchemeCodeUnspsc190501.85313302': __('identifierSchemeCodeUnspsc190501.85313302'),
	'identifierSchemeCodeUnspsc190501.85313400': __('identifierSchemeCodeUnspsc190501.85313400'),
	'identifierSchemeCodeUnspsc190501.85313401': __('identifierSchemeCodeUnspsc190501.85313401'),
	'identifierSchemeCodeUnspsc190501.85313402': __('identifierSchemeCodeUnspsc190501.85313402'),
	'identifierSchemeCodeUnspsc190501.85313500': __('identifierSchemeCodeUnspsc190501.85313500'),
	'identifierSchemeCodeUnspsc190501.85313501': __('identifierSchemeCodeUnspsc190501.85313501'),
	'identifierSchemeCodeUnspsc190501.85313502': __('identifierSchemeCodeUnspsc190501.85313502'),
	'identifierSchemeCodeUnspsc190501.85313503': __('identifierSchemeCodeUnspsc190501.85313503'),
	'identifierSchemeCodeUnspsc190501.85313504': __('identifierSchemeCodeUnspsc190501.85313504'),
	'identifierSchemeCodeUnspsc190501.85313600': __('identifierSchemeCodeUnspsc190501.85313600'),
	'identifierSchemeCodeUnspsc190501.85313601': __('identifierSchemeCodeUnspsc190501.85313601'),
	'identifierSchemeCodeUnspsc190501.85313602': __('identifierSchemeCodeUnspsc190501.85313602'),
	'identifierSchemeCodeUnspsc190501.85313603': __('identifierSchemeCodeUnspsc190501.85313603'),
	'identifierSchemeCodeUnspsc190501.85313700': __('identifierSchemeCodeUnspsc190501.85313700'),
	'identifierSchemeCodeUnspsc190501.85313701': __('identifierSchemeCodeUnspsc190501.85313701'),
	'identifierSchemeCodeUnspsc190501.85313702': __('identifierSchemeCodeUnspsc190501.85313702'),
	'identifierSchemeCodeUnspsc190501.85313703': __('identifierSchemeCodeUnspsc190501.85313703'),
	'identifierSchemeCodeUnspsc190501.85313800': __('identifierSchemeCodeUnspsc190501.85313800'),
	'identifierSchemeCodeUnspsc190501.85313801': __('identifierSchemeCodeUnspsc190501.85313801'),
	'identifierSchemeCodeUnspsc190501.85313802': __('identifierSchemeCodeUnspsc190501.85313802'),
	'identifierSchemeCodeUnspsc190501.85313803': __('identifierSchemeCodeUnspsc190501.85313803'),
	'identifierSchemeCodeUnspsc190501.85313900': __('identifierSchemeCodeUnspsc190501.85313900'),
	'identifierSchemeCodeUnspsc190501.85313901': __('identifierSchemeCodeUnspsc190501.85313901'),
	'identifierSchemeCodeUnspsc190501.85313902': __('identifierSchemeCodeUnspsc190501.85313902'),
	'identifierSchemeCodeUnspsc190501.85314000': __('identifierSchemeCodeUnspsc190501.85314000'),
	'identifierSchemeCodeUnspsc190501.85314001': __('identifierSchemeCodeUnspsc190501.85314001'),
	'identifierSchemeCodeUnspsc190501.85314002': __('identifierSchemeCodeUnspsc190501.85314002'),
	'identifierSchemeCodeUnspsc190501.85314003': __('identifierSchemeCodeUnspsc190501.85314003'),
	'identifierSchemeCodeUnspsc190501.85314004': __('identifierSchemeCodeUnspsc190501.85314004'),
	'identifierSchemeCodeUnspsc190501.85314005': __('identifierSchemeCodeUnspsc190501.85314005'),
	'identifierSchemeCodeUnspsc190501.85314100': __('identifierSchemeCodeUnspsc190501.85314100'),
	'identifierSchemeCodeUnspsc190501.85314101': __('identifierSchemeCodeUnspsc190501.85314101'),
	'identifierSchemeCodeUnspsc190501.85314102': __('identifierSchemeCodeUnspsc190501.85314102'),
	'identifierSchemeCodeUnspsc190501.85314200': __('identifierSchemeCodeUnspsc190501.85314200'),
	'identifierSchemeCodeUnspsc190501.85314201': __('identifierSchemeCodeUnspsc190501.85314201'),
	'identifierSchemeCodeUnspsc190501.85314202': __('identifierSchemeCodeUnspsc190501.85314202'),
	'identifierSchemeCodeUnspsc190501.85314203': __('identifierSchemeCodeUnspsc190501.85314203'),
	'identifierSchemeCodeUnspsc190501.85314204': __('identifierSchemeCodeUnspsc190501.85314204'),
	'identifierSchemeCodeUnspsc190501.85314205': __('identifierSchemeCodeUnspsc190501.85314205'),
	'identifierSchemeCodeUnspsc190501.85314300': __('identifierSchemeCodeUnspsc190501.85314300'),
	'identifierSchemeCodeUnspsc190501.85314301': __('identifierSchemeCodeUnspsc190501.85314301'),
	'identifierSchemeCodeUnspsc190501.85314302': __('identifierSchemeCodeUnspsc190501.85314302'),
	'identifierSchemeCodeUnspsc190501.85314303': __('identifierSchemeCodeUnspsc190501.85314303'),
	'identifierSchemeCodeUnspsc190501.85314304': __('identifierSchemeCodeUnspsc190501.85314304'),
	'identifierSchemeCodeUnspsc190501.85314305': __('identifierSchemeCodeUnspsc190501.85314305'),
	'identifierSchemeCodeUnspsc190501.85314400': __('identifierSchemeCodeUnspsc190501.85314400'),
	'identifierSchemeCodeUnspsc190501.85314401': __('identifierSchemeCodeUnspsc190501.85314401'),
	'identifierSchemeCodeUnspsc190501.85314402': __('identifierSchemeCodeUnspsc190501.85314402'),
	'identifierSchemeCodeUnspsc190501.85314500': __('identifierSchemeCodeUnspsc190501.85314500'),
	'identifierSchemeCodeUnspsc190501.85314501': __('identifierSchemeCodeUnspsc190501.85314501'),
	'identifierSchemeCodeUnspsc190501.85314502': __('identifierSchemeCodeUnspsc190501.85314502'),
	'identifierSchemeCodeUnspsc190501.85314503': __('identifierSchemeCodeUnspsc190501.85314503'),
	'identifierSchemeCodeUnspsc190501.85314600': __('identifierSchemeCodeUnspsc190501.85314600'),
	'identifierSchemeCodeUnspsc190501.85314601': __('identifierSchemeCodeUnspsc190501.85314601'),
	'identifierSchemeCodeUnspsc190501.85314602': __('identifierSchemeCodeUnspsc190501.85314602'),
	'identifierSchemeCodeUnspsc190501.85314603': __('identifierSchemeCodeUnspsc190501.85314603'),
	'identifierSchemeCodeUnspsc190501.85314604': __('identifierSchemeCodeUnspsc190501.85314604'),
	'identifierSchemeCodeUnspsc190501.85314605': __('identifierSchemeCodeUnspsc190501.85314605'),
	'identifierSchemeCodeUnspsc190501.85314700': __('identifierSchemeCodeUnspsc190501.85314700'),
	'identifierSchemeCodeUnspsc190501.85314701': __('identifierSchemeCodeUnspsc190501.85314701'),
	'identifierSchemeCodeUnspsc190501.85314702': __('identifierSchemeCodeUnspsc190501.85314702'),
	'identifierSchemeCodeUnspsc190501.85314703': __('identifierSchemeCodeUnspsc190501.85314703'),
	'identifierSchemeCodeUnspsc190501.85314704': __('identifierSchemeCodeUnspsc190501.85314704'),
	'identifierSchemeCodeUnspsc190501.85314705': __('identifierSchemeCodeUnspsc190501.85314705'),
	'identifierSchemeCodeUnspsc190501.85314800': __('identifierSchemeCodeUnspsc190501.85314800'),
	'identifierSchemeCodeUnspsc190501.85314801': __('identifierSchemeCodeUnspsc190501.85314801'),
	'identifierSchemeCodeUnspsc190501.85314802': __('identifierSchemeCodeUnspsc190501.85314802'),
	'identifierSchemeCodeUnspsc190501.85314900': __('identifierSchemeCodeUnspsc190501.85314900'),
	'identifierSchemeCodeUnspsc190501.85314901': __('identifierSchemeCodeUnspsc190501.85314901'),
	'identifierSchemeCodeUnspsc190501.85314902': __('identifierSchemeCodeUnspsc190501.85314902'),
	'identifierSchemeCodeUnspsc190501.85314903': __('identifierSchemeCodeUnspsc190501.85314903'),
	'identifierSchemeCodeUnspsc190501.85315000': __('identifierSchemeCodeUnspsc190501.85315000'),
	'identifierSchemeCodeUnspsc190501.85315001': __('identifierSchemeCodeUnspsc190501.85315001'),
	'identifierSchemeCodeUnspsc190501.85315002': __('identifierSchemeCodeUnspsc190501.85315002'),
	'identifierSchemeCodeUnspsc190501.85315003': __('identifierSchemeCodeUnspsc190501.85315003'),
	'identifierSchemeCodeUnspsc190501.85315004': __('identifierSchemeCodeUnspsc190501.85315004'),
	'identifierSchemeCodeUnspsc190501.85315005': __('identifierSchemeCodeUnspsc190501.85315005'),
	'identifierSchemeCodeUnspsc190501.85315006': __('identifierSchemeCodeUnspsc190501.85315006'),
	'identifierSchemeCodeUnspsc190501.85315100': __('identifierSchemeCodeUnspsc190501.85315100'),
	'identifierSchemeCodeUnspsc190501.85315101': __('identifierSchemeCodeUnspsc190501.85315101'),
	'identifierSchemeCodeUnspsc190501.85315102': __('identifierSchemeCodeUnspsc190501.85315102'),
	'identifierSchemeCodeUnspsc190501.85315103': __('identifierSchemeCodeUnspsc190501.85315103'),
	'identifierSchemeCodeUnspsc190501.85315104': __('identifierSchemeCodeUnspsc190501.85315104'),
	'identifierSchemeCodeUnspsc190501.85315105': __('identifierSchemeCodeUnspsc190501.85315105'),
	'identifierSchemeCodeUnspsc190501.85315106': __('identifierSchemeCodeUnspsc190501.85315106'),
	'identifierSchemeCodeUnspsc190501.85315200': __('identifierSchemeCodeUnspsc190501.85315200'),
	'identifierSchemeCodeUnspsc190501.85315201': __('identifierSchemeCodeUnspsc190501.85315201'),
	'identifierSchemeCodeUnspsc190501.85315202': __('identifierSchemeCodeUnspsc190501.85315202'),
	'identifierSchemeCodeUnspsc190501.85315300': __('identifierSchemeCodeUnspsc190501.85315300'),
	'identifierSchemeCodeUnspsc190501.85315301': __('identifierSchemeCodeUnspsc190501.85315301'),
	'identifierSchemeCodeUnspsc190501.85315302': __('identifierSchemeCodeUnspsc190501.85315302'),
	'identifierSchemeCodeUnspsc190501.85315303': __('identifierSchemeCodeUnspsc190501.85315303'),
	'identifierSchemeCodeUnspsc190501.85315304': __('identifierSchemeCodeUnspsc190501.85315304'),
	'identifierSchemeCodeUnspsc190501.85315305': __('identifierSchemeCodeUnspsc190501.85315305'),
	'identifierSchemeCodeUnspsc190501.85315400': __('identifierSchemeCodeUnspsc190501.85315400'),
	'identifierSchemeCodeUnspsc190501.85315401': __('identifierSchemeCodeUnspsc190501.85315401'),
	'identifierSchemeCodeUnspsc190501.85315402': __('identifierSchemeCodeUnspsc190501.85315402'),
	'identifierSchemeCodeUnspsc190501.85315403': __('identifierSchemeCodeUnspsc190501.85315403'),
	'identifierSchemeCodeUnspsc190501.85315404': __('identifierSchemeCodeUnspsc190501.85315404'),
	'identifierSchemeCodeUnspsc190501.85315500': __('identifierSchemeCodeUnspsc190501.85315500'),
	'identifierSchemeCodeUnspsc190501.85315501': __('identifierSchemeCodeUnspsc190501.85315501'),
	'identifierSchemeCodeUnspsc190501.85315502': __('identifierSchemeCodeUnspsc190501.85315502'),
	'identifierSchemeCodeUnspsc190501.85315503': __('identifierSchemeCodeUnspsc190501.85315503'),
	'identifierSchemeCodeUnspsc190501.85315504': __('identifierSchemeCodeUnspsc190501.85315504'),
	'identifierSchemeCodeUnspsc190501.85315505': __('identifierSchemeCodeUnspsc190501.85315505'),
	'identifierSchemeCodeUnspsc190501.85315600': __('identifierSchemeCodeUnspsc190501.85315600'),
	'identifierSchemeCodeUnspsc190501.85315601': __('identifierSchemeCodeUnspsc190501.85315601'),
	'identifierSchemeCodeUnspsc190501.85315602': __('identifierSchemeCodeUnspsc190501.85315602'),
	'identifierSchemeCodeUnspsc190501.85315603': __('identifierSchemeCodeUnspsc190501.85315603'),
	'identifierSchemeCodeUnspsc190501.85315604': __('identifierSchemeCodeUnspsc190501.85315604'),
	'identifierSchemeCodeUnspsc190501.85315605': __('identifierSchemeCodeUnspsc190501.85315605'),
	'identifierSchemeCodeUnspsc190501.85315606': __('identifierSchemeCodeUnspsc190501.85315606'),
	'identifierSchemeCodeUnspsc190501.85315607': __('identifierSchemeCodeUnspsc190501.85315607'),
	'identifierSchemeCodeUnspsc190501.85315608': __('identifierSchemeCodeUnspsc190501.85315608'),
	'identifierSchemeCodeUnspsc190501.85315609': __('identifierSchemeCodeUnspsc190501.85315609'),
	'identifierSchemeCodeUnspsc190501.85315700': __('identifierSchemeCodeUnspsc190501.85315700'),
	'identifierSchemeCodeUnspsc190501.85315701': __('identifierSchemeCodeUnspsc190501.85315701'),
	'identifierSchemeCodeUnspsc190501.85315702': __('identifierSchemeCodeUnspsc190501.85315702'),
	'identifierSchemeCodeUnspsc190501.85315703': __('identifierSchemeCodeUnspsc190501.85315703'),
	'identifierSchemeCodeUnspsc190501.85315800': __('identifierSchemeCodeUnspsc190501.85315800'),
	'identifierSchemeCodeUnspsc190501.85315801': __('identifierSchemeCodeUnspsc190501.85315801'),
	'identifierSchemeCodeUnspsc190501.85315802': __('identifierSchemeCodeUnspsc190501.85315802'),
	'identifierSchemeCodeUnspsc190501.85315803': __('identifierSchemeCodeUnspsc190501.85315803'),
	'identifierSchemeCodeUnspsc190501.85315900': __('identifierSchemeCodeUnspsc190501.85315900'),
	'identifierSchemeCodeUnspsc190501.85315901': __('identifierSchemeCodeUnspsc190501.85315901'),
	'identifierSchemeCodeUnspsc190501.85315902': __('identifierSchemeCodeUnspsc190501.85315902'),
	'identifierSchemeCodeUnspsc190501.85315903': __('identifierSchemeCodeUnspsc190501.85315903'),
	'identifierSchemeCodeUnspsc190501.85315904': __('identifierSchemeCodeUnspsc190501.85315904'),
	'identifierSchemeCodeUnspsc190501.85315905': __('identifierSchemeCodeUnspsc190501.85315905'),
	'identifierSchemeCodeUnspsc190501.85315906': __('identifierSchemeCodeUnspsc190501.85315906'),
	'identifierSchemeCodeUnspsc190501.85315907': __('identifierSchemeCodeUnspsc190501.85315907'),
	'identifierSchemeCodeUnspsc190501.85315908': __('identifierSchemeCodeUnspsc190501.85315908'),
	'identifierSchemeCodeUnspsc190501.85316000': __('identifierSchemeCodeUnspsc190501.85316000'),
	'identifierSchemeCodeUnspsc190501.85316001': __('identifierSchemeCodeUnspsc190501.85316001'),
	'identifierSchemeCodeUnspsc190501.85316002': __('identifierSchemeCodeUnspsc190501.85316002'),
	'identifierSchemeCodeUnspsc190501.85316003': __('identifierSchemeCodeUnspsc190501.85316003'),
	'identifierSchemeCodeUnspsc190501.85316004': __('identifierSchemeCodeUnspsc190501.85316004'),
	'identifierSchemeCodeUnspsc190501.85316005': __('identifierSchemeCodeUnspsc190501.85316005'),
	'identifierSchemeCodeUnspsc190501.85316006': __('identifierSchemeCodeUnspsc190501.85316006'),
	'identifierSchemeCodeUnspsc190501.85316100': __('identifierSchemeCodeUnspsc190501.85316100'),
	'identifierSchemeCodeUnspsc190501.85316101': __('identifierSchemeCodeUnspsc190501.85316101'),
	'identifierSchemeCodeUnspsc190501.85316102': __('identifierSchemeCodeUnspsc190501.85316102'),
	'identifierSchemeCodeUnspsc190501.85316200': __('identifierSchemeCodeUnspsc190501.85316200'),
	'identifierSchemeCodeUnspsc190501.85316201': __('identifierSchemeCodeUnspsc190501.85316201'),
	'identifierSchemeCodeUnspsc190501.85316202': __('identifierSchemeCodeUnspsc190501.85316202'),
	'identifierSchemeCodeUnspsc190501.85316203': __('identifierSchemeCodeUnspsc190501.85316203'),
	'identifierSchemeCodeUnspsc190501.85316204': __('identifierSchemeCodeUnspsc190501.85316204'),
	'identifierSchemeCodeUnspsc190501.85316300': __('identifierSchemeCodeUnspsc190501.85316300'),
	'identifierSchemeCodeUnspsc190501.85316301': __('identifierSchemeCodeUnspsc190501.85316301'),
	'identifierSchemeCodeUnspsc190501.85316302': __('identifierSchemeCodeUnspsc190501.85316302'),
	'identifierSchemeCodeUnspsc190501.85316303': __('identifierSchemeCodeUnspsc190501.85316303'),
	'identifierSchemeCodeUnspsc190501.85316304': __('identifierSchemeCodeUnspsc190501.85316304'),
	'identifierSchemeCodeUnspsc190501.85316305': __('identifierSchemeCodeUnspsc190501.85316305'),
	'identifierSchemeCodeUnspsc190501.85316306': __('identifierSchemeCodeUnspsc190501.85316306'),
	'identifierSchemeCodeUnspsc190501.85316400': __('identifierSchemeCodeUnspsc190501.85316400'),
	'identifierSchemeCodeUnspsc190501.85316401': __('identifierSchemeCodeUnspsc190501.85316401'),
	'identifierSchemeCodeUnspsc190501.85316402': __('identifierSchemeCodeUnspsc190501.85316402'),
	'identifierSchemeCodeUnspsc190501.85316500': __('identifierSchemeCodeUnspsc190501.85316500'),
	'identifierSchemeCodeUnspsc190501.85316501': __('identifierSchemeCodeUnspsc190501.85316501'),
	'identifierSchemeCodeUnspsc190501.85316502': __('identifierSchemeCodeUnspsc190501.85316502'),
	'identifierSchemeCodeUnspsc190501.85316600': __('identifierSchemeCodeUnspsc190501.85316600'),
	'identifierSchemeCodeUnspsc190501.85316601': __('identifierSchemeCodeUnspsc190501.85316601'),
	'identifierSchemeCodeUnspsc190501.85316602': __('identifierSchemeCodeUnspsc190501.85316602'),
	'identifierSchemeCodeUnspsc190501.85316603': __('identifierSchemeCodeUnspsc190501.85316603'),
	'identifierSchemeCodeUnspsc190501.85316604': __('identifierSchemeCodeUnspsc190501.85316604'),
	'identifierSchemeCodeUnspsc190501.85316700': __('identifierSchemeCodeUnspsc190501.85316700'),
	'identifierSchemeCodeUnspsc190501.85316701': __('identifierSchemeCodeUnspsc190501.85316701'),
	'identifierSchemeCodeUnspsc190501.85316702': __('identifierSchemeCodeUnspsc190501.85316702'),
	'identifierSchemeCodeUnspsc190501.85316703': __('identifierSchemeCodeUnspsc190501.85316703'),
	'identifierSchemeCodeUnspsc190501.85316800': __('identifierSchemeCodeUnspsc190501.85316800'),
	'identifierSchemeCodeUnspsc190501.85316801': __('identifierSchemeCodeUnspsc190501.85316801'),
	'identifierSchemeCodeUnspsc190501.85316802': __('identifierSchemeCodeUnspsc190501.85316802'),
	'identifierSchemeCodeUnspsc190501.85316900': __('identifierSchemeCodeUnspsc190501.85316900'),
	'identifierSchemeCodeUnspsc190501.85316901': __('identifierSchemeCodeUnspsc190501.85316901'),
	'identifierSchemeCodeUnspsc190501.85316902': __('identifierSchemeCodeUnspsc190501.85316902'),
	'identifierSchemeCodeUnspsc190501.85316903': __('identifierSchemeCodeUnspsc190501.85316903'),
	'identifierSchemeCodeUnspsc190501.85316904': __('identifierSchemeCodeUnspsc190501.85316904'),
	'identifierSchemeCodeUnspsc190501.85316905': __('identifierSchemeCodeUnspsc190501.85316905'),
	'identifierSchemeCodeUnspsc190501.85317000': __('identifierSchemeCodeUnspsc190501.85317000'),
	'identifierSchemeCodeUnspsc190501.85317001': __('identifierSchemeCodeUnspsc190501.85317001'),
	'identifierSchemeCodeUnspsc190501.85317002': __('identifierSchemeCodeUnspsc190501.85317002'),
	'identifierSchemeCodeUnspsc190501.85317003': __('identifierSchemeCodeUnspsc190501.85317003'),
	'identifierSchemeCodeUnspsc190501.85317100': __('identifierSchemeCodeUnspsc190501.85317100'),
	'identifierSchemeCodeUnspsc190501.85317101': __('identifierSchemeCodeUnspsc190501.85317101'),
	'identifierSchemeCodeUnspsc190501.85317102': __('identifierSchemeCodeUnspsc190501.85317102'),
	'identifierSchemeCodeUnspsc190501.85317103': __('identifierSchemeCodeUnspsc190501.85317103'),
	'identifierSchemeCodeUnspsc190501.85317200': __('identifierSchemeCodeUnspsc190501.85317200'),
	'identifierSchemeCodeUnspsc190501.85317201': __('identifierSchemeCodeUnspsc190501.85317201'),
	'identifierSchemeCodeUnspsc190501.85317202': __('identifierSchemeCodeUnspsc190501.85317202'),
	'identifierSchemeCodeUnspsc190501.85317203': __('identifierSchemeCodeUnspsc190501.85317203'),
	'identifierSchemeCodeUnspsc190501.85317300': __('identifierSchemeCodeUnspsc190501.85317300'),
	'identifierSchemeCodeUnspsc190501.85317301': __('identifierSchemeCodeUnspsc190501.85317301'),
	'identifierSchemeCodeUnspsc190501.85317302': __('identifierSchemeCodeUnspsc190501.85317302'),
	'identifierSchemeCodeUnspsc190501.85320000': __('identifierSchemeCodeUnspsc190501.85320000'),
	'identifierSchemeCodeUnspsc190501.85321500': __('identifierSchemeCodeUnspsc190501.85321500'),
	'identifierSchemeCodeUnspsc190501.85321501': __('identifierSchemeCodeUnspsc190501.85321501'),
	'identifierSchemeCodeUnspsc190501.85321502': __('identifierSchemeCodeUnspsc190501.85321502'),
	'identifierSchemeCodeUnspsc190501.85321503': __('identifierSchemeCodeUnspsc190501.85321503'),
	'identifierSchemeCodeUnspsc190501.85321504': __('identifierSchemeCodeUnspsc190501.85321504'),
	'identifierSchemeCodeUnspsc190501.85321505': __('identifierSchemeCodeUnspsc190501.85321505'),
	'identifierSchemeCodeUnspsc190501.85321506': __('identifierSchemeCodeUnspsc190501.85321506'),
	'identifierSchemeCodeUnspsc190501.85321507': __('identifierSchemeCodeUnspsc190501.85321507'),
	'identifierSchemeCodeUnspsc190501.85321508': __('identifierSchemeCodeUnspsc190501.85321508'),
	'identifierSchemeCodeUnspsc190501.85321600': __('identifierSchemeCodeUnspsc190501.85321600'),
	'identifierSchemeCodeUnspsc190501.85321601': __('identifierSchemeCodeUnspsc190501.85321601'),
	'identifierSchemeCodeUnspsc190501.85321602': __('identifierSchemeCodeUnspsc190501.85321602'),
	'identifierSchemeCodeUnspsc190501.85321603': __('identifierSchemeCodeUnspsc190501.85321603'),
	'identifierSchemeCodeUnspsc190501.85321604': __('identifierSchemeCodeUnspsc190501.85321604'),
	'identifierSchemeCodeUnspsc190501.85321700': __('identifierSchemeCodeUnspsc190501.85321700'),
	'identifierSchemeCodeUnspsc190501.85321701': __('identifierSchemeCodeUnspsc190501.85321701'),
	'identifierSchemeCodeUnspsc190501.85321702': __('identifierSchemeCodeUnspsc190501.85321702'),
	'identifierSchemeCodeUnspsc190501.85321800': __('identifierSchemeCodeUnspsc190501.85321800'),
	'identifierSchemeCodeUnspsc190501.85321801': __('identifierSchemeCodeUnspsc190501.85321801'),
	'identifierSchemeCodeUnspsc190501.85321802': __('identifierSchemeCodeUnspsc190501.85321802'),
	'identifierSchemeCodeUnspsc190501.85321803': __('identifierSchemeCodeUnspsc190501.85321803'),
	'identifierSchemeCodeUnspsc190501.85321804': __('identifierSchemeCodeUnspsc190501.85321804'),
	'identifierSchemeCodeUnspsc190501.85321900': __('identifierSchemeCodeUnspsc190501.85321900'),
	'identifierSchemeCodeUnspsc190501.85321901': __('identifierSchemeCodeUnspsc190501.85321901'),
	'identifierSchemeCodeUnspsc190501.85321902': __('identifierSchemeCodeUnspsc190501.85321902'),
	'identifierSchemeCodeUnspsc190501.85322000': __('identifierSchemeCodeUnspsc190501.85322000'),
	'identifierSchemeCodeUnspsc190501.85322001': __('identifierSchemeCodeUnspsc190501.85322001'),
	'identifierSchemeCodeUnspsc190501.85322002': __('identifierSchemeCodeUnspsc190501.85322002'),
	'identifierSchemeCodeUnspsc190501.85322003': __('identifierSchemeCodeUnspsc190501.85322003'),
	'identifierSchemeCodeUnspsc190501.85322004': __('identifierSchemeCodeUnspsc190501.85322004'),
	'identifierSchemeCodeUnspsc190501.85322100': __('identifierSchemeCodeUnspsc190501.85322100'),
	'identifierSchemeCodeUnspsc190501.85322101': __('identifierSchemeCodeUnspsc190501.85322101'),
	'identifierSchemeCodeUnspsc190501.85322102': __('identifierSchemeCodeUnspsc190501.85322102'),
	'identifierSchemeCodeUnspsc190501.85322103': __('identifierSchemeCodeUnspsc190501.85322103'),
	'identifierSchemeCodeUnspsc190501.85322104': __('identifierSchemeCodeUnspsc190501.85322104'),
	'identifierSchemeCodeUnspsc190501.85322105': __('identifierSchemeCodeUnspsc190501.85322105'),
	'identifierSchemeCodeUnspsc190501.85322200': __('identifierSchemeCodeUnspsc190501.85322200'),
	'identifierSchemeCodeUnspsc190501.85322201': __('identifierSchemeCodeUnspsc190501.85322201'),
	'identifierSchemeCodeUnspsc190501.85322202': __('identifierSchemeCodeUnspsc190501.85322202'),
	'identifierSchemeCodeUnspsc190501.85322300': __('identifierSchemeCodeUnspsc190501.85322300'),
	'identifierSchemeCodeUnspsc190501.85322301': __('identifierSchemeCodeUnspsc190501.85322301'),
	'identifierSchemeCodeUnspsc190501.85322302': __('identifierSchemeCodeUnspsc190501.85322302'),
	'identifierSchemeCodeUnspsc190501.85322303': __('identifierSchemeCodeUnspsc190501.85322303'),
	'identifierSchemeCodeUnspsc190501.85322304': __('identifierSchemeCodeUnspsc190501.85322304'),
	'identifierSchemeCodeUnspsc190501.85322305': __('identifierSchemeCodeUnspsc190501.85322305'),
	'identifierSchemeCodeUnspsc190501.85322400': __('identifierSchemeCodeUnspsc190501.85322400'),
	'identifierSchemeCodeUnspsc190501.85322401': __('identifierSchemeCodeUnspsc190501.85322401'),
	'identifierSchemeCodeUnspsc190501.85322402': __('identifierSchemeCodeUnspsc190501.85322402'),
	'identifierSchemeCodeUnspsc190501.85322403': __('identifierSchemeCodeUnspsc190501.85322403'),
	'identifierSchemeCodeUnspsc190501.85322404': __('identifierSchemeCodeUnspsc190501.85322404'),
	'identifierSchemeCodeUnspsc190501.85322500': __('identifierSchemeCodeUnspsc190501.85322500'),
	'identifierSchemeCodeUnspsc190501.85322501': __('identifierSchemeCodeUnspsc190501.85322501'),
	'identifierSchemeCodeUnspsc190501.85322502': __('identifierSchemeCodeUnspsc190501.85322502'),
	'identifierSchemeCodeUnspsc190501.85322503': __('identifierSchemeCodeUnspsc190501.85322503'),
	'identifierSchemeCodeUnspsc190501.85322600': __('identifierSchemeCodeUnspsc190501.85322600'),
	'identifierSchemeCodeUnspsc190501.85322601': __('identifierSchemeCodeUnspsc190501.85322601'),
	'identifierSchemeCodeUnspsc190501.85322602': __('identifierSchemeCodeUnspsc190501.85322602'),
	'identifierSchemeCodeUnspsc190501.85322700': __('identifierSchemeCodeUnspsc190501.85322700'),
	'identifierSchemeCodeUnspsc190501.85322701': __('identifierSchemeCodeUnspsc190501.85322701'),
	'identifierSchemeCodeUnspsc190501.85322702': __('identifierSchemeCodeUnspsc190501.85322702'),
	'identifierSchemeCodeUnspsc190501.85322703': __('identifierSchemeCodeUnspsc190501.85322703'),
	'identifierSchemeCodeUnspsc190501.85322704': __('identifierSchemeCodeUnspsc190501.85322704'),
	'identifierSchemeCodeUnspsc190501.85322705': __('identifierSchemeCodeUnspsc190501.85322705'),
	'identifierSchemeCodeUnspsc190501.85322706': __('identifierSchemeCodeUnspsc190501.85322706'),
	'identifierSchemeCodeUnspsc190501.85322800': __('identifierSchemeCodeUnspsc190501.85322800'),
	'identifierSchemeCodeUnspsc190501.85322801': __('identifierSchemeCodeUnspsc190501.85322801'),
	'identifierSchemeCodeUnspsc190501.85322802': __('identifierSchemeCodeUnspsc190501.85322802'),
	'identifierSchemeCodeUnspsc190501.85322900': __('identifierSchemeCodeUnspsc190501.85322900'),
	'identifierSchemeCodeUnspsc190501.85322901': __('identifierSchemeCodeUnspsc190501.85322901'),
	'identifierSchemeCodeUnspsc190501.85322902': __('identifierSchemeCodeUnspsc190501.85322902'),
	'identifierSchemeCodeUnspsc190501.85323000': __('identifierSchemeCodeUnspsc190501.85323000'),
	'identifierSchemeCodeUnspsc190501.85323001': __('identifierSchemeCodeUnspsc190501.85323001'),
	'identifierSchemeCodeUnspsc190501.85323002': __('identifierSchemeCodeUnspsc190501.85323002'),
	'identifierSchemeCodeUnspsc190501.85323003': __('identifierSchemeCodeUnspsc190501.85323003'),
	'identifierSchemeCodeUnspsc190501.85323004': __('identifierSchemeCodeUnspsc190501.85323004'),
	'identifierSchemeCodeUnspsc190501.85323005': __('identifierSchemeCodeUnspsc190501.85323005'),
	'identifierSchemeCodeUnspsc190501.85323006': __('identifierSchemeCodeUnspsc190501.85323006'),
	'identifierSchemeCodeUnspsc190501.85323007': __('identifierSchemeCodeUnspsc190501.85323007'),
	'identifierSchemeCodeUnspsc190501.85323008': __('identifierSchemeCodeUnspsc190501.85323008'),
	'identifierSchemeCodeUnspsc190501.85323100': __('identifierSchemeCodeUnspsc190501.85323100'),
	'identifierSchemeCodeUnspsc190501.85323101': __('identifierSchemeCodeUnspsc190501.85323101'),
	'identifierSchemeCodeUnspsc190501.85323102': __('identifierSchemeCodeUnspsc190501.85323102'),
	'identifierSchemeCodeUnspsc190501.85323103': __('identifierSchemeCodeUnspsc190501.85323103'),
	'identifierSchemeCodeUnspsc190501.85323104': __('identifierSchemeCodeUnspsc190501.85323104'),
	'identifierSchemeCodeUnspsc190501.85323105': __('identifierSchemeCodeUnspsc190501.85323105'),
	'identifierSchemeCodeUnspsc190501.85323106': __('identifierSchemeCodeUnspsc190501.85323106'),
	'identifierSchemeCodeUnspsc190501.85323107': __('identifierSchemeCodeUnspsc190501.85323107'),
	'identifierSchemeCodeUnspsc190501.85323108': __('identifierSchemeCodeUnspsc190501.85323108'),
	'identifierSchemeCodeUnspsc190501.85323200': __('identifierSchemeCodeUnspsc190501.85323200'),
	'identifierSchemeCodeUnspsc190501.85323201': __('identifierSchemeCodeUnspsc190501.85323201'),
	'identifierSchemeCodeUnspsc190501.85323202': __('identifierSchemeCodeUnspsc190501.85323202'),
	'identifierSchemeCodeUnspsc190501.85323203': __('identifierSchemeCodeUnspsc190501.85323203'),
	'identifierSchemeCodeUnspsc190501.85323204': __('identifierSchemeCodeUnspsc190501.85323204'),
	'identifierSchemeCodeUnspsc190501.85323205': __('identifierSchemeCodeUnspsc190501.85323205'),
	'identifierSchemeCodeUnspsc190501.85323300': __('identifierSchemeCodeUnspsc190501.85323300'),
	'identifierSchemeCodeUnspsc190501.85323301': __('identifierSchemeCodeUnspsc190501.85323301'),
	'identifierSchemeCodeUnspsc190501.85323302': __('identifierSchemeCodeUnspsc190501.85323302'),
	'identifierSchemeCodeUnspsc190501.85323303': __('identifierSchemeCodeUnspsc190501.85323303'),
	'identifierSchemeCodeUnspsc190501.85323400': __('identifierSchemeCodeUnspsc190501.85323400'),
	'identifierSchemeCodeUnspsc190501.85323401': __('identifierSchemeCodeUnspsc190501.85323401'),
	'identifierSchemeCodeUnspsc190501.85323402': __('identifierSchemeCodeUnspsc190501.85323402'),
	'identifierSchemeCodeUnspsc190501.85323500': __('identifierSchemeCodeUnspsc190501.85323500'),
	'identifierSchemeCodeUnspsc190501.85323501': __('identifierSchemeCodeUnspsc190501.85323501'),
	'identifierSchemeCodeUnspsc190501.85323502': __('identifierSchemeCodeUnspsc190501.85323502'),
	'identifierSchemeCodeUnspsc190501.85323503': __('identifierSchemeCodeUnspsc190501.85323503'),
	'identifierSchemeCodeUnspsc190501.85323504': __('identifierSchemeCodeUnspsc190501.85323504'),
	'identifierSchemeCodeUnspsc190501.85323600': __('identifierSchemeCodeUnspsc190501.85323600'),
	'identifierSchemeCodeUnspsc190501.85323601': __('identifierSchemeCodeUnspsc190501.85323601'),
	'identifierSchemeCodeUnspsc190501.85323602': __('identifierSchemeCodeUnspsc190501.85323602'),
	'identifierSchemeCodeUnspsc190501.85323603': __('identifierSchemeCodeUnspsc190501.85323603'),
	'identifierSchemeCodeUnspsc190501.85330000': __('identifierSchemeCodeUnspsc190501.85330000'),
	'identifierSchemeCodeUnspsc190501.85331500': __('identifierSchemeCodeUnspsc190501.85331500'),
	'identifierSchemeCodeUnspsc190501.85331501': __('identifierSchemeCodeUnspsc190501.85331501'),
	'identifierSchemeCodeUnspsc190501.85331502': __('identifierSchemeCodeUnspsc190501.85331502'),
	'identifierSchemeCodeUnspsc190501.85331503': __('identifierSchemeCodeUnspsc190501.85331503'),
	'identifierSchemeCodeUnspsc190501.85331504': __('identifierSchemeCodeUnspsc190501.85331504'),
	'identifierSchemeCodeUnspsc190501.85331505': __('identifierSchemeCodeUnspsc190501.85331505'),
	'identifierSchemeCodeUnspsc190501.85331506': __('identifierSchemeCodeUnspsc190501.85331506'),
	'identifierSchemeCodeUnspsc190501.85331507': __('identifierSchemeCodeUnspsc190501.85331507'),
	'identifierSchemeCodeUnspsc190501.85331600': __('identifierSchemeCodeUnspsc190501.85331600'),
	'identifierSchemeCodeUnspsc190501.85331601': __('identifierSchemeCodeUnspsc190501.85331601'),
	'identifierSchemeCodeUnspsc190501.85331602': __('identifierSchemeCodeUnspsc190501.85331602'),
	'identifierSchemeCodeUnspsc190501.85331700': __('identifierSchemeCodeUnspsc190501.85331700'),
	'identifierSchemeCodeUnspsc190501.85331701': __('identifierSchemeCodeUnspsc190501.85331701'),
	'identifierSchemeCodeUnspsc190501.85331702': __('identifierSchemeCodeUnspsc190501.85331702'),
	'identifierSchemeCodeUnspsc190501.85331703': __('identifierSchemeCodeUnspsc190501.85331703'),
	'identifierSchemeCodeUnspsc190501.85331704': __('identifierSchemeCodeUnspsc190501.85331704'),
	'identifierSchemeCodeUnspsc190501.85331705': __('identifierSchemeCodeUnspsc190501.85331705'),
	'identifierSchemeCodeUnspsc190501.85331706': __('identifierSchemeCodeUnspsc190501.85331706'),
	'identifierSchemeCodeUnspsc190501.85331800': __('identifierSchemeCodeUnspsc190501.85331800'),
	'identifierSchemeCodeUnspsc190501.85331801': __('identifierSchemeCodeUnspsc190501.85331801'),
	'identifierSchemeCodeUnspsc190501.85331802': __('identifierSchemeCodeUnspsc190501.85331802'),
	'identifierSchemeCodeUnspsc190501.85331803': __('identifierSchemeCodeUnspsc190501.85331803'),
	'identifierSchemeCodeUnspsc190501.85331804': __('identifierSchemeCodeUnspsc190501.85331804'),
	'identifierSchemeCodeUnspsc190501.85331805': __('identifierSchemeCodeUnspsc190501.85331805'),
	'identifierSchemeCodeUnspsc190501.85331806': __('identifierSchemeCodeUnspsc190501.85331806'),
	'identifierSchemeCodeUnspsc190501.85331807': __('identifierSchemeCodeUnspsc190501.85331807'),
	'identifierSchemeCodeUnspsc190501.85331808': __('identifierSchemeCodeUnspsc190501.85331808'),
	'identifierSchemeCodeUnspsc190501.85331900': __('identifierSchemeCodeUnspsc190501.85331900'),
	'identifierSchemeCodeUnspsc190501.85331901': __('identifierSchemeCodeUnspsc190501.85331901'),
	'identifierSchemeCodeUnspsc190501.85331902': __('identifierSchemeCodeUnspsc190501.85331902'),
	'identifierSchemeCodeUnspsc190501.85331903': __('identifierSchemeCodeUnspsc190501.85331903'),
	'identifierSchemeCodeUnspsc190501.85331904': __('identifierSchemeCodeUnspsc190501.85331904'),
	'identifierSchemeCodeUnspsc190501.85331905': __('identifierSchemeCodeUnspsc190501.85331905'),
	'identifierSchemeCodeUnspsc190501.85331906': __('identifierSchemeCodeUnspsc190501.85331906'),
	'identifierSchemeCodeUnspsc190501.85331907': __('identifierSchemeCodeUnspsc190501.85331907'),
	'identifierSchemeCodeUnspsc190501.85331908': __('identifierSchemeCodeUnspsc190501.85331908'),
	'identifierSchemeCodeUnspsc190501.85331909': __('identifierSchemeCodeUnspsc190501.85331909'),
	'identifierSchemeCodeUnspsc190501.85332000': __('identifierSchemeCodeUnspsc190501.85332000'),
	'identifierSchemeCodeUnspsc190501.85332001': __('identifierSchemeCodeUnspsc190501.85332001'),
	'identifierSchemeCodeUnspsc190501.85332002': __('identifierSchemeCodeUnspsc190501.85332002'),
	'identifierSchemeCodeUnspsc190501.85332003': __('identifierSchemeCodeUnspsc190501.85332003'),
	'identifierSchemeCodeUnspsc190501.85332004': __('identifierSchemeCodeUnspsc190501.85332004'),
	'identifierSchemeCodeUnspsc190501.85332005': __('identifierSchemeCodeUnspsc190501.85332005'),
	'identifierSchemeCodeUnspsc190501.85332100': __('identifierSchemeCodeUnspsc190501.85332100'),
	'identifierSchemeCodeUnspsc190501.85332101': __('identifierSchemeCodeUnspsc190501.85332101'),
	'identifierSchemeCodeUnspsc190501.85332102': __('identifierSchemeCodeUnspsc190501.85332102'),
	'identifierSchemeCodeUnspsc190501.85332103': __('identifierSchemeCodeUnspsc190501.85332103'),
	'identifierSchemeCodeUnspsc190501.85332104': __('identifierSchemeCodeUnspsc190501.85332104'),
	'identifierSchemeCodeUnspsc190501.85332200': __('identifierSchemeCodeUnspsc190501.85332200'),
	'identifierSchemeCodeUnspsc190501.85332201': __('identifierSchemeCodeUnspsc190501.85332201'),
	'identifierSchemeCodeUnspsc190501.85332202': __('identifierSchemeCodeUnspsc190501.85332202'),
	'identifierSchemeCodeUnspsc190501.85332203': __('identifierSchemeCodeUnspsc190501.85332203'),
	'identifierSchemeCodeUnspsc190501.85332204': __('identifierSchemeCodeUnspsc190501.85332204'),
	'identifierSchemeCodeUnspsc190501.85332205': __('identifierSchemeCodeUnspsc190501.85332205'),
	'identifierSchemeCodeUnspsc190501.85332206': __('identifierSchemeCodeUnspsc190501.85332206'),
	'identifierSchemeCodeUnspsc190501.85332300': __('identifierSchemeCodeUnspsc190501.85332300'),
	'identifierSchemeCodeUnspsc190501.85332301': __('identifierSchemeCodeUnspsc190501.85332301'),
	'identifierSchemeCodeUnspsc190501.85332302': __('identifierSchemeCodeUnspsc190501.85332302'),
	'identifierSchemeCodeUnspsc190501.85332303': __('identifierSchemeCodeUnspsc190501.85332303'),
	'identifierSchemeCodeUnspsc190501.85332304': __('identifierSchemeCodeUnspsc190501.85332304'),
	'identifierSchemeCodeUnspsc190501.85332400': __('identifierSchemeCodeUnspsc190501.85332400'),
	'identifierSchemeCodeUnspsc190501.85332401': __('identifierSchemeCodeUnspsc190501.85332401'),
	'identifierSchemeCodeUnspsc190501.85332402': __('identifierSchemeCodeUnspsc190501.85332402'),
	'identifierSchemeCodeUnspsc190501.85332500': __('identifierSchemeCodeUnspsc190501.85332500'),
	'identifierSchemeCodeUnspsc190501.85332501': __('identifierSchemeCodeUnspsc190501.85332501'),
	'identifierSchemeCodeUnspsc190501.85332502': __('identifierSchemeCodeUnspsc190501.85332502'),
	'identifierSchemeCodeUnspsc190501.85332503': __('identifierSchemeCodeUnspsc190501.85332503'),
	'identifierSchemeCodeUnspsc190501.85332504': __('identifierSchemeCodeUnspsc190501.85332504'),
	'identifierSchemeCodeUnspsc190501.85332600': __('identifierSchemeCodeUnspsc190501.85332600'),
	'identifierSchemeCodeUnspsc190501.85332601': __('identifierSchemeCodeUnspsc190501.85332601'),
	'identifierSchemeCodeUnspsc190501.85332602': __('identifierSchemeCodeUnspsc190501.85332602'),
	'identifierSchemeCodeUnspsc190501.85332603': __('identifierSchemeCodeUnspsc190501.85332603'),
	'identifierSchemeCodeUnspsc190501.85332604': __('identifierSchemeCodeUnspsc190501.85332604'),
	'identifierSchemeCodeUnspsc190501.85332605': __('identifierSchemeCodeUnspsc190501.85332605'),
	'identifierSchemeCodeUnspsc190501.85332606': __('identifierSchemeCodeUnspsc190501.85332606'),
	'identifierSchemeCodeUnspsc190501.85332607': __('identifierSchemeCodeUnspsc190501.85332607'),
	'identifierSchemeCodeUnspsc190501.85332608': __('identifierSchemeCodeUnspsc190501.85332608'),
	'identifierSchemeCodeUnspsc190501.85332700': __('identifierSchemeCodeUnspsc190501.85332700'),
	'identifierSchemeCodeUnspsc190501.85332701': __('identifierSchemeCodeUnspsc190501.85332701'),
	'identifierSchemeCodeUnspsc190501.85332702': __('identifierSchemeCodeUnspsc190501.85332702'),
	'identifierSchemeCodeUnspsc190501.85332703': __('identifierSchemeCodeUnspsc190501.85332703'),
	'identifierSchemeCodeUnspsc190501.85332800': __('identifierSchemeCodeUnspsc190501.85332800'),
	'identifierSchemeCodeUnspsc190501.85332801': __('identifierSchemeCodeUnspsc190501.85332801'),
	'identifierSchemeCodeUnspsc190501.85332802': __('identifierSchemeCodeUnspsc190501.85332802'),
	'identifierSchemeCodeUnspsc190501.85332803': __('identifierSchemeCodeUnspsc190501.85332803'),
	'identifierSchemeCodeUnspsc190501.85332804': __('identifierSchemeCodeUnspsc190501.85332804'),
	'identifierSchemeCodeUnspsc190501.85332805': __('identifierSchemeCodeUnspsc190501.85332805'),
	'identifierSchemeCodeUnspsc190501.85332806': __('identifierSchemeCodeUnspsc190501.85332806'),
	'identifierSchemeCodeUnspsc190501.85332900': __('identifierSchemeCodeUnspsc190501.85332900'),
	'identifierSchemeCodeUnspsc190501.85332901': __('identifierSchemeCodeUnspsc190501.85332901'),
	'identifierSchemeCodeUnspsc190501.85332902': __('identifierSchemeCodeUnspsc190501.85332902'),
	'identifierSchemeCodeUnspsc190501.85332903': __('identifierSchemeCodeUnspsc190501.85332903'),
	'identifierSchemeCodeUnspsc190501.85332904': __('identifierSchemeCodeUnspsc190501.85332904'),
	'identifierSchemeCodeUnspsc190501.85332905': __('identifierSchemeCodeUnspsc190501.85332905'),
	'identifierSchemeCodeUnspsc190501.85332906': __('identifierSchemeCodeUnspsc190501.85332906'),
	'identifierSchemeCodeUnspsc190501.85332907': __('identifierSchemeCodeUnspsc190501.85332907'),
	'identifierSchemeCodeUnspsc190501.85333000': __('identifierSchemeCodeUnspsc190501.85333000'),
	'identifierSchemeCodeUnspsc190501.85333001': __('identifierSchemeCodeUnspsc190501.85333001'),
	'identifierSchemeCodeUnspsc190501.85333002': __('identifierSchemeCodeUnspsc190501.85333002'),
	'identifierSchemeCodeUnspsc190501.85333100': __('identifierSchemeCodeUnspsc190501.85333100'),
	'identifierSchemeCodeUnspsc190501.85333101': __('identifierSchemeCodeUnspsc190501.85333101'),
	'identifierSchemeCodeUnspsc190501.85333102': __('identifierSchemeCodeUnspsc190501.85333102'),
	'identifierSchemeCodeUnspsc190501.85333103': __('identifierSchemeCodeUnspsc190501.85333103'),
	'identifierSchemeCodeUnspsc190501.85333104': __('identifierSchemeCodeUnspsc190501.85333104'),
	'identifierSchemeCodeUnspsc190501.85333105': __('identifierSchemeCodeUnspsc190501.85333105'),
	'identifierSchemeCodeUnspsc190501.85333106': __('identifierSchemeCodeUnspsc190501.85333106'),
	'identifierSchemeCodeUnspsc190501.85333107': __('identifierSchemeCodeUnspsc190501.85333107'),
	'identifierSchemeCodeUnspsc190501.85333108': __('identifierSchemeCodeUnspsc190501.85333108'),
	'identifierSchemeCodeUnspsc190501.85333109': __('identifierSchemeCodeUnspsc190501.85333109'),
	'identifierSchemeCodeUnspsc190501.85333110': __('identifierSchemeCodeUnspsc190501.85333110'),
	'identifierSchemeCodeUnspsc190501.85333111': __('identifierSchemeCodeUnspsc190501.85333111'),
	'identifierSchemeCodeUnspsc190501.85333200': __('identifierSchemeCodeUnspsc190501.85333200'),
	'identifierSchemeCodeUnspsc190501.85333201': __('identifierSchemeCodeUnspsc190501.85333201'),
	'identifierSchemeCodeUnspsc190501.85333202': __('identifierSchemeCodeUnspsc190501.85333202'),
	'identifierSchemeCodeUnspsc190501.85333203': __('identifierSchemeCodeUnspsc190501.85333203'),
	'identifierSchemeCodeUnspsc190501.85333204': __('identifierSchemeCodeUnspsc190501.85333204'),
	'identifierSchemeCodeUnspsc190501.85333205': __('identifierSchemeCodeUnspsc190501.85333205'),
	'identifierSchemeCodeUnspsc190501.85333206': __('identifierSchemeCodeUnspsc190501.85333206'),
	'identifierSchemeCodeUnspsc190501.85333300': __('identifierSchemeCodeUnspsc190501.85333300'),
	'identifierSchemeCodeUnspsc190501.85333301': __('identifierSchemeCodeUnspsc190501.85333301'),
	'identifierSchemeCodeUnspsc190501.85333302': __('identifierSchemeCodeUnspsc190501.85333302'),
	'identifierSchemeCodeUnspsc190501.85333303': __('identifierSchemeCodeUnspsc190501.85333303'),
	'identifierSchemeCodeUnspsc190501.85333304': __('identifierSchemeCodeUnspsc190501.85333304'),
	'identifierSchemeCodeUnspsc190501.85333305': __('identifierSchemeCodeUnspsc190501.85333305'),
	'identifierSchemeCodeUnspsc190501.85333400': __('identifierSchemeCodeUnspsc190501.85333400'),
	'identifierSchemeCodeUnspsc190501.85333401': __('identifierSchemeCodeUnspsc190501.85333401'),
	'identifierSchemeCodeUnspsc190501.85333402': __('identifierSchemeCodeUnspsc190501.85333402'),
	'identifierSchemeCodeUnspsc190501.85333403': __('identifierSchemeCodeUnspsc190501.85333403'),
	'identifierSchemeCodeUnspsc190501.85333404': __('identifierSchemeCodeUnspsc190501.85333404'),
	'identifierSchemeCodeUnspsc190501.85333405': __('identifierSchemeCodeUnspsc190501.85333405'),
	'identifierSchemeCodeUnspsc190501.85333500': __('identifierSchemeCodeUnspsc190501.85333500'),
	'identifierSchemeCodeUnspsc190501.85333501': __('identifierSchemeCodeUnspsc190501.85333501'),
	'identifierSchemeCodeUnspsc190501.85333502': __('identifierSchemeCodeUnspsc190501.85333502'),
	'identifierSchemeCodeUnspsc190501.85333503': __('identifierSchemeCodeUnspsc190501.85333503'),
	'identifierSchemeCodeUnspsc190501.85333504': __('identifierSchemeCodeUnspsc190501.85333504'),
	'identifierSchemeCodeUnspsc190501.85333505': __('identifierSchemeCodeUnspsc190501.85333505'),
	'identifierSchemeCodeUnspsc190501.85333506': __('identifierSchemeCodeUnspsc190501.85333506'),
	'identifierSchemeCodeUnspsc190501.85333600': __('identifierSchemeCodeUnspsc190501.85333600'),
	'identifierSchemeCodeUnspsc190501.85333601': __('identifierSchemeCodeUnspsc190501.85333601'),
	'identifierSchemeCodeUnspsc190501.85333602': __('identifierSchemeCodeUnspsc190501.85333602'),
	'identifierSchemeCodeUnspsc190501.85333603': __('identifierSchemeCodeUnspsc190501.85333603'),
	'identifierSchemeCodeUnspsc190501.85333604': __('identifierSchemeCodeUnspsc190501.85333604'),
	'identifierSchemeCodeUnspsc190501.85333605': __('identifierSchemeCodeUnspsc190501.85333605'),
	'identifierSchemeCodeUnspsc190501.85333606': __('identifierSchemeCodeUnspsc190501.85333606'),
	'identifierSchemeCodeUnspsc190501.85333607': __('identifierSchemeCodeUnspsc190501.85333607'),
	'identifierSchemeCodeUnspsc190501.85333700': __('identifierSchemeCodeUnspsc190501.85333700'),
	'identifierSchemeCodeUnspsc190501.85333701': __('identifierSchemeCodeUnspsc190501.85333701'),
	'identifierSchemeCodeUnspsc190501.85333800': __('identifierSchemeCodeUnspsc190501.85333800'),
	'identifierSchemeCodeUnspsc190501.85333801': __('identifierSchemeCodeUnspsc190501.85333801'),
	'identifierSchemeCodeUnspsc190501.85333802': __('identifierSchemeCodeUnspsc190501.85333802'),
	'identifierSchemeCodeUnspsc190501.85333803': __('identifierSchemeCodeUnspsc190501.85333803'),
	'identifierSchemeCodeUnspsc190501.85333804': __('identifierSchemeCodeUnspsc190501.85333804'),
	'identifierSchemeCodeUnspsc190501.85333900': __('identifierSchemeCodeUnspsc190501.85333900'),
	'identifierSchemeCodeUnspsc190501.85333901': __('identifierSchemeCodeUnspsc190501.85333901'),
	'identifierSchemeCodeUnspsc190501.85333902': __('identifierSchemeCodeUnspsc190501.85333902'),
	'identifierSchemeCodeUnspsc190501.85333903': __('identifierSchemeCodeUnspsc190501.85333903'),
	'identifierSchemeCodeUnspsc190501.85334000': __('identifierSchemeCodeUnspsc190501.85334000'),
	'identifierSchemeCodeUnspsc190501.85334001': __('identifierSchemeCodeUnspsc190501.85334001'),
	'identifierSchemeCodeUnspsc190501.85334002': __('identifierSchemeCodeUnspsc190501.85334002'),
	'identifierSchemeCodeUnspsc190501.85334003': __('identifierSchemeCodeUnspsc190501.85334003'),
	'identifierSchemeCodeUnspsc190501.85334100': __('identifierSchemeCodeUnspsc190501.85334100'),
	'identifierSchemeCodeUnspsc190501.85334101': __('identifierSchemeCodeUnspsc190501.85334101'),
	'identifierSchemeCodeUnspsc190501.85334102': __('identifierSchemeCodeUnspsc190501.85334102'),
	'identifierSchemeCodeUnspsc190501.85334103': __('identifierSchemeCodeUnspsc190501.85334103'),
	'identifierSchemeCodeUnspsc190501.85334200': __('identifierSchemeCodeUnspsc190501.85334200'),
	'identifierSchemeCodeUnspsc190501.85334201': __('identifierSchemeCodeUnspsc190501.85334201'),
	'identifierSchemeCodeUnspsc190501.85334202': __('identifierSchemeCodeUnspsc190501.85334202'),
	'identifierSchemeCodeUnspsc190501.85334203': __('identifierSchemeCodeUnspsc190501.85334203'),
	'identifierSchemeCodeUnspsc190501.85334204': __('identifierSchemeCodeUnspsc190501.85334204'),
	'identifierSchemeCodeUnspsc190501.85334205': __('identifierSchemeCodeUnspsc190501.85334205'),
	'identifierSchemeCodeUnspsc190501.85334206': __('identifierSchemeCodeUnspsc190501.85334206'),
	'identifierSchemeCodeUnspsc190501.85334300': __('identifierSchemeCodeUnspsc190501.85334300'),
	'identifierSchemeCodeUnspsc190501.85334301': __('identifierSchemeCodeUnspsc190501.85334301'),
	'identifierSchemeCodeUnspsc190501.85334302': __('identifierSchemeCodeUnspsc190501.85334302'),
	'identifierSchemeCodeUnspsc190501.85334303': __('identifierSchemeCodeUnspsc190501.85334303'),
	'identifierSchemeCodeUnspsc190501.85334400': __('identifierSchemeCodeUnspsc190501.85334400'),
	'identifierSchemeCodeUnspsc190501.85334401': __('identifierSchemeCodeUnspsc190501.85334401'),
	'identifierSchemeCodeUnspsc190501.85334402': __('identifierSchemeCodeUnspsc190501.85334402'),
	'identifierSchemeCodeUnspsc190501.85334500': __('identifierSchemeCodeUnspsc190501.85334500'),
	'identifierSchemeCodeUnspsc190501.85334501': __('identifierSchemeCodeUnspsc190501.85334501'),
	'identifierSchemeCodeUnspsc190501.85334502': __('identifierSchemeCodeUnspsc190501.85334502'),
	'identifierSchemeCodeUnspsc190501.85334503': __('identifierSchemeCodeUnspsc190501.85334503'),
	'identifierSchemeCodeUnspsc190501.85334504': __('identifierSchemeCodeUnspsc190501.85334504'),
	'identifierSchemeCodeUnspsc190501.85334505': __('identifierSchemeCodeUnspsc190501.85334505'),
	'identifierSchemeCodeUnspsc190501.85334600': __('identifierSchemeCodeUnspsc190501.85334600'),
	'identifierSchemeCodeUnspsc190501.85334601': __('identifierSchemeCodeUnspsc190501.85334601'),
	'identifierSchemeCodeUnspsc190501.85334602': __('identifierSchemeCodeUnspsc190501.85334602'),
	'identifierSchemeCodeUnspsc190501.85334603': __('identifierSchemeCodeUnspsc190501.85334603'),
	'identifierSchemeCodeUnspsc190501.85334604': __('identifierSchemeCodeUnspsc190501.85334604'),
	'identifierSchemeCodeUnspsc190501.85334700': __('identifierSchemeCodeUnspsc190501.85334700'),
	'identifierSchemeCodeUnspsc190501.85334701': __('identifierSchemeCodeUnspsc190501.85334701'),
	'identifierSchemeCodeUnspsc190501.85334702': __('identifierSchemeCodeUnspsc190501.85334702'),
	'identifierSchemeCodeUnspsc190501.85334703': __('identifierSchemeCodeUnspsc190501.85334703'),
	'identifierSchemeCodeUnspsc190501.85334800': __('identifierSchemeCodeUnspsc190501.85334800'),
	'identifierSchemeCodeUnspsc190501.85334801': __('identifierSchemeCodeUnspsc190501.85334801'),
	'identifierSchemeCodeUnspsc190501.85334802': __('identifierSchemeCodeUnspsc190501.85334802'),
	'identifierSchemeCodeUnspsc190501.85334803': __('identifierSchemeCodeUnspsc190501.85334803'),
	'identifierSchemeCodeUnspsc190501.85334804': __('identifierSchemeCodeUnspsc190501.85334804'),
	'identifierSchemeCodeUnspsc190501.85334805': __('identifierSchemeCodeUnspsc190501.85334805'),
	'identifierSchemeCodeUnspsc190501.85334900': __('identifierSchemeCodeUnspsc190501.85334900'),
	'identifierSchemeCodeUnspsc190501.85334901': __('identifierSchemeCodeUnspsc190501.85334901'),
	'identifierSchemeCodeUnspsc190501.85334902': __('identifierSchemeCodeUnspsc190501.85334902'),
	'identifierSchemeCodeUnspsc190501.85334903': __('identifierSchemeCodeUnspsc190501.85334903'),
	'identifierSchemeCodeUnspsc190501.85334904': __('identifierSchemeCodeUnspsc190501.85334904'),
	'identifierSchemeCodeUnspsc190501.85334905': __('identifierSchemeCodeUnspsc190501.85334905'),
	'identifierSchemeCodeUnspsc190501.85334906': __('identifierSchemeCodeUnspsc190501.85334906'),
	'identifierSchemeCodeUnspsc190501.85335000': __('identifierSchemeCodeUnspsc190501.85335000'),
	'identifierSchemeCodeUnspsc190501.85335001': __('identifierSchemeCodeUnspsc190501.85335001'),
	'identifierSchemeCodeUnspsc190501.85335002': __('identifierSchemeCodeUnspsc190501.85335002'),
	'identifierSchemeCodeUnspsc190501.85335100': __('identifierSchemeCodeUnspsc190501.85335100'),
	'identifierSchemeCodeUnspsc190501.85335101': __('identifierSchemeCodeUnspsc190501.85335101'),
	'identifierSchemeCodeUnspsc190501.85335102': __('identifierSchemeCodeUnspsc190501.85335102'),
	'identifierSchemeCodeUnspsc190501.85335103': __('identifierSchemeCodeUnspsc190501.85335103'),
	'identifierSchemeCodeUnspsc190501.85335200': __('identifierSchemeCodeUnspsc190501.85335200'),
	'identifierSchemeCodeUnspsc190501.85335201': __('identifierSchemeCodeUnspsc190501.85335201'),
	'identifierSchemeCodeUnspsc190501.85335202': __('identifierSchemeCodeUnspsc190501.85335202'),
	'identifierSchemeCodeUnspsc190501.85335203': __('identifierSchemeCodeUnspsc190501.85335203'),
	'identifierSchemeCodeUnspsc190501.85335204': __('identifierSchemeCodeUnspsc190501.85335204'),
	'identifierSchemeCodeUnspsc190501.85335205': __('identifierSchemeCodeUnspsc190501.85335205'),
	'identifierSchemeCodeUnspsc190501.85335300': __('identifierSchemeCodeUnspsc190501.85335300'),
	'identifierSchemeCodeUnspsc190501.85335301': __('identifierSchemeCodeUnspsc190501.85335301'),
	'identifierSchemeCodeUnspsc190501.85335302': __('identifierSchemeCodeUnspsc190501.85335302'),
	'identifierSchemeCodeUnspsc190501.85335303': __('identifierSchemeCodeUnspsc190501.85335303'),
	'identifierSchemeCodeUnspsc190501.85335304': __('identifierSchemeCodeUnspsc190501.85335304'),
	'identifierSchemeCodeUnspsc190501.85335305': __('identifierSchemeCodeUnspsc190501.85335305'),
	'identifierSchemeCodeUnspsc190501.85335400': __('identifierSchemeCodeUnspsc190501.85335400'),
	'identifierSchemeCodeUnspsc190501.85335401': __('identifierSchemeCodeUnspsc190501.85335401'),
	'identifierSchemeCodeUnspsc190501.85335402': __('identifierSchemeCodeUnspsc190501.85335402'),
	'identifierSchemeCodeUnspsc190501.85335403': __('identifierSchemeCodeUnspsc190501.85335403'),
	'identifierSchemeCodeUnspsc190501.85335404': __('identifierSchemeCodeUnspsc190501.85335404'),
	'identifierSchemeCodeUnspsc190501.85335405': __('identifierSchemeCodeUnspsc190501.85335405'),
	'identifierSchemeCodeUnspsc190501.85335406': __('identifierSchemeCodeUnspsc190501.85335406'),
	'identifierSchemeCodeUnspsc190501.85335407': __('identifierSchemeCodeUnspsc190501.85335407'),
	'identifierSchemeCodeUnspsc190501.85335408': __('identifierSchemeCodeUnspsc190501.85335408'),
	'identifierSchemeCodeUnspsc190501.85335500': __('identifierSchemeCodeUnspsc190501.85335500'),
	'identifierSchemeCodeUnspsc190501.85335501': __('identifierSchemeCodeUnspsc190501.85335501'),
	'identifierSchemeCodeUnspsc190501.85335502': __('identifierSchemeCodeUnspsc190501.85335502'),
	'identifierSchemeCodeUnspsc190501.85335503': __('identifierSchemeCodeUnspsc190501.85335503'),
	'identifierSchemeCodeUnspsc190501.85335504': __('identifierSchemeCodeUnspsc190501.85335504'),
	'identifierSchemeCodeUnspsc190501.85335505': __('identifierSchemeCodeUnspsc190501.85335505'),
	'identifierSchemeCodeUnspsc190501.85335506': __('identifierSchemeCodeUnspsc190501.85335506'),
	'identifierSchemeCodeUnspsc190501.85335600': __('identifierSchemeCodeUnspsc190501.85335600'),
	'identifierSchemeCodeUnspsc190501.85335601': __('identifierSchemeCodeUnspsc190501.85335601'),
	'identifierSchemeCodeUnspsc190501.85335602': __('identifierSchemeCodeUnspsc190501.85335602'),
	'identifierSchemeCodeUnspsc190501.85335603': __('identifierSchemeCodeUnspsc190501.85335603'),
	'identifierSchemeCodeUnspsc190501.85335604': __('identifierSchemeCodeUnspsc190501.85335604'),
	'identifierSchemeCodeUnspsc190501.85335605': __('identifierSchemeCodeUnspsc190501.85335605'),
	'identifierSchemeCodeUnspsc190501.85335606': __('identifierSchemeCodeUnspsc190501.85335606'),
	'identifierSchemeCodeUnspsc190501.85335700': __('identifierSchemeCodeUnspsc190501.85335700'),
	'identifierSchemeCodeUnspsc190501.85335701': __('identifierSchemeCodeUnspsc190501.85335701'),
	'identifierSchemeCodeUnspsc190501.85335702': __('identifierSchemeCodeUnspsc190501.85335702'),
	'identifierSchemeCodeUnspsc190501.85335703': __('identifierSchemeCodeUnspsc190501.85335703'),
	'identifierSchemeCodeUnspsc190501.85335800': __('identifierSchemeCodeUnspsc190501.85335800'),
	'identifierSchemeCodeUnspsc190501.85335801': __('identifierSchemeCodeUnspsc190501.85335801'),
	'identifierSchemeCodeUnspsc190501.85335802': __('identifierSchemeCodeUnspsc190501.85335802'),
	'identifierSchemeCodeUnspsc190501.85335803': __('identifierSchemeCodeUnspsc190501.85335803'),
	'identifierSchemeCodeUnspsc190501.85335804': __('identifierSchemeCodeUnspsc190501.85335804'),
	'identifierSchemeCodeUnspsc190501.85335900': __('identifierSchemeCodeUnspsc190501.85335900'),
	'identifierSchemeCodeUnspsc190501.85335901': __('identifierSchemeCodeUnspsc190501.85335901'),
	'identifierSchemeCodeUnspsc190501.85335902': __('identifierSchemeCodeUnspsc190501.85335902'),
	'identifierSchemeCodeUnspsc190501.85335903': __('identifierSchemeCodeUnspsc190501.85335903'),
	'identifierSchemeCodeUnspsc190501.85335904': __('identifierSchemeCodeUnspsc190501.85335904'),
	'identifierSchemeCodeUnspsc190501.85335905': __('identifierSchemeCodeUnspsc190501.85335905'),
	'identifierSchemeCodeUnspsc190501.85336000': __('identifierSchemeCodeUnspsc190501.85336000'),
	'identifierSchemeCodeUnspsc190501.85336001': __('identifierSchemeCodeUnspsc190501.85336001'),
	'identifierSchemeCodeUnspsc190501.85336002': __('identifierSchemeCodeUnspsc190501.85336002'),
	'identifierSchemeCodeUnspsc190501.85336003': __('identifierSchemeCodeUnspsc190501.85336003'),
	'identifierSchemeCodeUnspsc190501.85336004': __('identifierSchemeCodeUnspsc190501.85336004'),
	'identifierSchemeCodeUnspsc190501.85336005': __('identifierSchemeCodeUnspsc190501.85336005'),
	'identifierSchemeCodeUnspsc190501.85336006': __('identifierSchemeCodeUnspsc190501.85336006'),
	'identifierSchemeCodeUnspsc190501.85336007': __('identifierSchemeCodeUnspsc190501.85336007'),
	'identifierSchemeCodeUnspsc190501.85336100': __('identifierSchemeCodeUnspsc190501.85336100'),
	'identifierSchemeCodeUnspsc190501.85336101': __('identifierSchemeCodeUnspsc190501.85336101'),
	'identifierSchemeCodeUnspsc190501.85336102': __('identifierSchemeCodeUnspsc190501.85336102'),
	'identifierSchemeCodeUnspsc190501.85336200': __('identifierSchemeCodeUnspsc190501.85336200'),
	'identifierSchemeCodeUnspsc190501.85336201': __('identifierSchemeCodeUnspsc190501.85336201'),
	'identifierSchemeCodeUnspsc190501.85336202': __('identifierSchemeCodeUnspsc190501.85336202'),
	'identifierSchemeCodeUnspsc190501.85336203': __('identifierSchemeCodeUnspsc190501.85336203'),
	'identifierSchemeCodeUnspsc190501.85336204': __('identifierSchemeCodeUnspsc190501.85336204'),
	'identifierSchemeCodeUnspsc190501.85336205': __('identifierSchemeCodeUnspsc190501.85336205'),
	'identifierSchemeCodeUnspsc190501.85336300': __('identifierSchemeCodeUnspsc190501.85336300'),
	'identifierSchemeCodeUnspsc190501.85336301': __('identifierSchemeCodeUnspsc190501.85336301'),
	'identifierSchemeCodeUnspsc190501.85336302': __('identifierSchemeCodeUnspsc190501.85336302'),
	'identifierSchemeCodeUnspsc190501.85336303': __('identifierSchemeCodeUnspsc190501.85336303'),
	'identifierSchemeCodeUnspsc190501.85336304': __('identifierSchemeCodeUnspsc190501.85336304'),
	'identifierSchemeCodeUnspsc190501.85336400': __('identifierSchemeCodeUnspsc190501.85336400'),
	'identifierSchemeCodeUnspsc190501.85336401': __('identifierSchemeCodeUnspsc190501.85336401'),
	'identifierSchemeCodeUnspsc190501.85336402': __('identifierSchemeCodeUnspsc190501.85336402'),
	'identifierSchemeCodeUnspsc190501.85336403': __('identifierSchemeCodeUnspsc190501.85336403'),
	'identifierSchemeCodeUnspsc190501.85336404': __('identifierSchemeCodeUnspsc190501.85336404'),
	'identifierSchemeCodeUnspsc190501.85336405': __('identifierSchemeCodeUnspsc190501.85336405'),
	'identifierSchemeCodeUnspsc190501.85336406': __('identifierSchemeCodeUnspsc190501.85336406'),
	'identifierSchemeCodeUnspsc190501.85336407': __('identifierSchemeCodeUnspsc190501.85336407'),
	'identifierSchemeCodeUnspsc190501.85336500': __('identifierSchemeCodeUnspsc190501.85336500'),
	'identifierSchemeCodeUnspsc190501.85336501': __('identifierSchemeCodeUnspsc190501.85336501'),
	'identifierSchemeCodeUnspsc190501.85336502': __('identifierSchemeCodeUnspsc190501.85336502'),
	'identifierSchemeCodeUnspsc190501.85336503': __('identifierSchemeCodeUnspsc190501.85336503'),
	'identifierSchemeCodeUnspsc190501.85336504': __('identifierSchemeCodeUnspsc190501.85336504'),
	'identifierSchemeCodeUnspsc190501.85336505': __('identifierSchemeCodeUnspsc190501.85336505'),
	'identifierSchemeCodeUnspsc190501.85336600': __('identifierSchemeCodeUnspsc190501.85336600'),
	'identifierSchemeCodeUnspsc190501.85336601': __('identifierSchemeCodeUnspsc190501.85336601'),
	'identifierSchemeCodeUnspsc190501.85336602': __('identifierSchemeCodeUnspsc190501.85336602'),
	'identifierSchemeCodeUnspsc190501.85336700': __('identifierSchemeCodeUnspsc190501.85336700'),
	'identifierSchemeCodeUnspsc190501.85336701': __('identifierSchemeCodeUnspsc190501.85336701'),
	'identifierSchemeCodeUnspsc190501.85336702': __('identifierSchemeCodeUnspsc190501.85336702'),
	'identifierSchemeCodeUnspsc190501.85336703': __('identifierSchemeCodeUnspsc190501.85336703'),
	'identifierSchemeCodeUnspsc190501.85336704': __('identifierSchemeCodeUnspsc190501.85336704'),
	'identifierSchemeCodeUnspsc190501.85336705': __('identifierSchemeCodeUnspsc190501.85336705'),
	'identifierSchemeCodeUnspsc190501.85336800': __('identifierSchemeCodeUnspsc190501.85336800'),
	'identifierSchemeCodeUnspsc190501.85336801': __('identifierSchemeCodeUnspsc190501.85336801'),
	'identifierSchemeCodeUnspsc190501.85336802': __('identifierSchemeCodeUnspsc190501.85336802'),
	'identifierSchemeCodeUnspsc190501.85336803': __('identifierSchemeCodeUnspsc190501.85336803'),
	'identifierSchemeCodeUnspsc190501.85336804': __('identifierSchemeCodeUnspsc190501.85336804'),
	'identifierSchemeCodeUnspsc190501.85336805': __('identifierSchemeCodeUnspsc190501.85336805'),
	'identifierSchemeCodeUnspsc190501.85336806': __('identifierSchemeCodeUnspsc190501.85336806'),
	'identifierSchemeCodeUnspsc190501.85336900': __('identifierSchemeCodeUnspsc190501.85336900'),
	'identifierSchemeCodeUnspsc190501.85336901': __('identifierSchemeCodeUnspsc190501.85336901'),
	'identifierSchemeCodeUnspsc190501.85336902': __('identifierSchemeCodeUnspsc190501.85336902'),
	'identifierSchemeCodeUnspsc190501.85336903': __('identifierSchemeCodeUnspsc190501.85336903'),
	'identifierSchemeCodeUnspsc190501.85336904': __('identifierSchemeCodeUnspsc190501.85336904'),
	'identifierSchemeCodeUnspsc190501.85337000': __('identifierSchemeCodeUnspsc190501.85337000'),
	'identifierSchemeCodeUnspsc190501.85337001': __('identifierSchemeCodeUnspsc190501.85337001'),
	'identifierSchemeCodeUnspsc190501.85337002': __('identifierSchemeCodeUnspsc190501.85337002'),
	'identifierSchemeCodeUnspsc190501.85337003': __('identifierSchemeCodeUnspsc190501.85337003'),
	'identifierSchemeCodeUnspsc190501.85337100': __('identifierSchemeCodeUnspsc190501.85337100'),
	'identifierSchemeCodeUnspsc190501.85337101': __('identifierSchemeCodeUnspsc190501.85337101'),
	'identifierSchemeCodeUnspsc190501.85337102': __('identifierSchemeCodeUnspsc190501.85337102'),
	'identifierSchemeCodeUnspsc190501.85337103': __('identifierSchemeCodeUnspsc190501.85337103'),
	'identifierSchemeCodeUnspsc190501.85337200': __('identifierSchemeCodeUnspsc190501.85337200'),
	'identifierSchemeCodeUnspsc190501.85337201': __('identifierSchemeCodeUnspsc190501.85337201'),
	'identifierSchemeCodeUnspsc190501.85337202': __('identifierSchemeCodeUnspsc190501.85337202'),
	'identifierSchemeCodeUnspsc190501.85337203': __('identifierSchemeCodeUnspsc190501.85337203'),
	'identifierSchemeCodeUnspsc190501.85337204': __('identifierSchemeCodeUnspsc190501.85337204'),
	'identifierSchemeCodeUnspsc190501.85337205': __('identifierSchemeCodeUnspsc190501.85337205'),
	'identifierSchemeCodeUnspsc190501.85337300': __('identifierSchemeCodeUnspsc190501.85337300'),
	'identifierSchemeCodeUnspsc190501.85337301': __('identifierSchemeCodeUnspsc190501.85337301'),
	'identifierSchemeCodeUnspsc190501.85337302': __('identifierSchemeCodeUnspsc190501.85337302'),
	'identifierSchemeCodeUnspsc190501.85337400': __('identifierSchemeCodeUnspsc190501.85337400'),
	'identifierSchemeCodeUnspsc190501.85337401': __('identifierSchemeCodeUnspsc190501.85337401'),
	'identifierSchemeCodeUnspsc190501.85337402': __('identifierSchemeCodeUnspsc190501.85337402'),
	'identifierSchemeCodeUnspsc190501.85340000': __('identifierSchemeCodeUnspsc190501.85340000'),
	'identifierSchemeCodeUnspsc190501.85341500': __('identifierSchemeCodeUnspsc190501.85341500'),
	'identifierSchemeCodeUnspsc190501.85341501': __('identifierSchemeCodeUnspsc190501.85341501'),
	'identifierSchemeCodeUnspsc190501.85341502': __('identifierSchemeCodeUnspsc190501.85341502'),
	'identifierSchemeCodeUnspsc190501.85341503': __('identifierSchemeCodeUnspsc190501.85341503'),
	'identifierSchemeCodeUnspsc190501.85341504': __('identifierSchemeCodeUnspsc190501.85341504'),
	'identifierSchemeCodeUnspsc190501.85341505': __('identifierSchemeCodeUnspsc190501.85341505'),
	'identifierSchemeCodeUnspsc190501.85341600': __('identifierSchemeCodeUnspsc190501.85341600'),
	'identifierSchemeCodeUnspsc190501.85341601': __('identifierSchemeCodeUnspsc190501.85341601'),
	'identifierSchemeCodeUnspsc190501.85341602': __('identifierSchemeCodeUnspsc190501.85341602'),
	'identifierSchemeCodeUnspsc190501.85341603': __('identifierSchemeCodeUnspsc190501.85341603'),
	'identifierSchemeCodeUnspsc190501.85341700': __('identifierSchemeCodeUnspsc190501.85341700'),
	'identifierSchemeCodeUnspsc190501.85341701': __('identifierSchemeCodeUnspsc190501.85341701'),
	'identifierSchemeCodeUnspsc190501.85341702': __('identifierSchemeCodeUnspsc190501.85341702'),
	'identifierSchemeCodeUnspsc190501.85341703': __('identifierSchemeCodeUnspsc190501.85341703'),
	'identifierSchemeCodeUnspsc190501.85341800': __('identifierSchemeCodeUnspsc190501.85341800'),
	'identifierSchemeCodeUnspsc190501.85341801': __('identifierSchemeCodeUnspsc190501.85341801'),
	'identifierSchemeCodeUnspsc190501.85341802': __('identifierSchemeCodeUnspsc190501.85341802'),
	'identifierSchemeCodeUnspsc190501.85341803': __('identifierSchemeCodeUnspsc190501.85341803'),
	'identifierSchemeCodeUnspsc190501.85341900': __('identifierSchemeCodeUnspsc190501.85341900'),
	'identifierSchemeCodeUnspsc190501.85341901': __('identifierSchemeCodeUnspsc190501.85341901'),
	'identifierSchemeCodeUnspsc190501.85341902': __('identifierSchemeCodeUnspsc190501.85341902'),
	'identifierSchemeCodeUnspsc190501.85341903': __('identifierSchemeCodeUnspsc190501.85341903'),
	'identifierSchemeCodeUnspsc190501.85341904': __('identifierSchemeCodeUnspsc190501.85341904'),
	'identifierSchemeCodeUnspsc190501.85341905': __('identifierSchemeCodeUnspsc190501.85341905'),
	'identifierSchemeCodeUnspsc190501.85341906': __('identifierSchemeCodeUnspsc190501.85341906'),
	'identifierSchemeCodeUnspsc190501.85342000': __('identifierSchemeCodeUnspsc190501.85342000'),
	'identifierSchemeCodeUnspsc190501.85342001': __('identifierSchemeCodeUnspsc190501.85342001'),
	'identifierSchemeCodeUnspsc190501.85342002': __('identifierSchemeCodeUnspsc190501.85342002'),
	'identifierSchemeCodeUnspsc190501.85342003': __('identifierSchemeCodeUnspsc190501.85342003'),
	'identifierSchemeCodeUnspsc190501.85342004': __('identifierSchemeCodeUnspsc190501.85342004'),
	'identifierSchemeCodeUnspsc190501.85342005': __('identifierSchemeCodeUnspsc190501.85342005'),
	'identifierSchemeCodeUnspsc190501.85342006': __('identifierSchemeCodeUnspsc190501.85342006'),
	'identifierSchemeCodeUnspsc190501.85342100': __('identifierSchemeCodeUnspsc190501.85342100'),
	'identifierSchemeCodeUnspsc190501.85342101': __('identifierSchemeCodeUnspsc190501.85342101'),
	'identifierSchemeCodeUnspsc190501.85342102': __('identifierSchemeCodeUnspsc190501.85342102'),
	'identifierSchemeCodeUnspsc190501.85342200': __('identifierSchemeCodeUnspsc190501.85342200'),
	'identifierSchemeCodeUnspsc190501.85342201': __('identifierSchemeCodeUnspsc190501.85342201'),
	'identifierSchemeCodeUnspsc190501.85342202': __('identifierSchemeCodeUnspsc190501.85342202'),
	'identifierSchemeCodeUnspsc190501.85342203': __('identifierSchemeCodeUnspsc190501.85342203'),
	'identifierSchemeCodeUnspsc190501.85342204': __('identifierSchemeCodeUnspsc190501.85342204'),
	'identifierSchemeCodeUnspsc190501.85342205': __('identifierSchemeCodeUnspsc190501.85342205'),
	'identifierSchemeCodeUnspsc190501.85342206': __('identifierSchemeCodeUnspsc190501.85342206'),
	'identifierSchemeCodeUnspsc190501.85342300': __('identifierSchemeCodeUnspsc190501.85342300'),
	'identifierSchemeCodeUnspsc190501.85342301': __('identifierSchemeCodeUnspsc190501.85342301'),
	'identifierSchemeCodeUnspsc190501.85342302': __('identifierSchemeCodeUnspsc190501.85342302'),
	'identifierSchemeCodeUnspsc190501.85342400': __('identifierSchemeCodeUnspsc190501.85342400'),
	'identifierSchemeCodeUnspsc190501.85342401': __('identifierSchemeCodeUnspsc190501.85342401'),
	'identifierSchemeCodeUnspsc190501.85342402': __('identifierSchemeCodeUnspsc190501.85342402'),
	'identifierSchemeCodeUnspsc190501.85342403': __('identifierSchemeCodeUnspsc190501.85342403'),
	'identifierSchemeCodeUnspsc190501.85342404': __('identifierSchemeCodeUnspsc190501.85342404'),
	'identifierSchemeCodeUnspsc190501.85342500': __('identifierSchemeCodeUnspsc190501.85342500'),
	'identifierSchemeCodeUnspsc190501.85342501': __('identifierSchemeCodeUnspsc190501.85342501'),
	'identifierSchemeCodeUnspsc190501.85342502': __('identifierSchemeCodeUnspsc190501.85342502'),
	'identifierSchemeCodeUnspsc190501.85342600': __('identifierSchemeCodeUnspsc190501.85342600'),
	'identifierSchemeCodeUnspsc190501.85342601': __('identifierSchemeCodeUnspsc190501.85342601'),
	'identifierSchemeCodeUnspsc190501.85342602': __('identifierSchemeCodeUnspsc190501.85342602'),
	'identifierSchemeCodeUnspsc190501.85342700': __('identifierSchemeCodeUnspsc190501.85342700'),
	'identifierSchemeCodeUnspsc190501.85342701': __('identifierSchemeCodeUnspsc190501.85342701'),
	'identifierSchemeCodeUnspsc190501.85342702': __('identifierSchemeCodeUnspsc190501.85342702'),
	'identifierSchemeCodeUnspsc190501.85342703': __('identifierSchemeCodeUnspsc190501.85342703'),
	'identifierSchemeCodeUnspsc190501.85342800': __('identifierSchemeCodeUnspsc190501.85342800'),
	'identifierSchemeCodeUnspsc190501.85342801': __('identifierSchemeCodeUnspsc190501.85342801'),
	'identifierSchemeCodeUnspsc190501.85342802': __('identifierSchemeCodeUnspsc190501.85342802'),
	'identifierSchemeCodeUnspsc190501.85342803': __('identifierSchemeCodeUnspsc190501.85342803'),
	'identifierSchemeCodeUnspsc190501.85342804': __('identifierSchemeCodeUnspsc190501.85342804'),
	'identifierSchemeCodeUnspsc190501.85342900': __('identifierSchemeCodeUnspsc190501.85342900'),
	'identifierSchemeCodeUnspsc190501.85342901': __('identifierSchemeCodeUnspsc190501.85342901'),
	'identifierSchemeCodeUnspsc190501.85342902': __('identifierSchemeCodeUnspsc190501.85342902'),
	'identifierSchemeCodeUnspsc190501.85343000': __('identifierSchemeCodeUnspsc190501.85343000'),
	'identifierSchemeCodeUnspsc190501.85343001': __('identifierSchemeCodeUnspsc190501.85343001'),
	'identifierSchemeCodeUnspsc190501.85343002': __('identifierSchemeCodeUnspsc190501.85343002'),
	'identifierSchemeCodeUnspsc190501.85343003': __('identifierSchemeCodeUnspsc190501.85343003'),
	'identifierSchemeCodeUnspsc190501.85343004': __('identifierSchemeCodeUnspsc190501.85343004'),
	'identifierSchemeCodeUnspsc190501.85343100': __('identifierSchemeCodeUnspsc190501.85343100'),
	'identifierSchemeCodeUnspsc190501.85343101': __('identifierSchemeCodeUnspsc190501.85343101'),
	'identifierSchemeCodeUnspsc190501.85343102': __('identifierSchemeCodeUnspsc190501.85343102'),
	'identifierSchemeCodeUnspsc190501.85343103': __('identifierSchemeCodeUnspsc190501.85343103'),
	'identifierSchemeCodeUnspsc190501.85343200': __('identifierSchemeCodeUnspsc190501.85343200'),
	'identifierSchemeCodeUnspsc190501.85343201': __('identifierSchemeCodeUnspsc190501.85343201'),
	'identifierSchemeCodeUnspsc190501.85343202': __('identifierSchemeCodeUnspsc190501.85343202'),
	'identifierSchemeCodeUnspsc190501.85343203': __('identifierSchemeCodeUnspsc190501.85343203'),
	'identifierSchemeCodeUnspsc190501.85343300': __('identifierSchemeCodeUnspsc190501.85343300'),
	'identifierSchemeCodeUnspsc190501.85343301': __('identifierSchemeCodeUnspsc190501.85343301'),
	'identifierSchemeCodeUnspsc190501.85343302': __('identifierSchemeCodeUnspsc190501.85343302'),
	'identifierSchemeCodeUnspsc190501.85343303': __('identifierSchemeCodeUnspsc190501.85343303'),
	'identifierSchemeCodeUnspsc190501.85343400': __('identifierSchemeCodeUnspsc190501.85343400'),
	'identifierSchemeCodeUnspsc190501.85343401': __('identifierSchemeCodeUnspsc190501.85343401'),
	'identifierSchemeCodeUnspsc190501.85343402': __('identifierSchemeCodeUnspsc190501.85343402'),
	'identifierSchemeCodeUnspsc190501.85343403': __('identifierSchemeCodeUnspsc190501.85343403'),
	'identifierSchemeCodeUnspsc190501.85343404': __('identifierSchemeCodeUnspsc190501.85343404'),
	'identifierSchemeCodeUnspsc190501.85343405': __('identifierSchemeCodeUnspsc190501.85343405'),
	'identifierSchemeCodeUnspsc190501.85343406': __('identifierSchemeCodeUnspsc190501.85343406'),
	'identifierSchemeCodeUnspsc190501.85343407': __('identifierSchemeCodeUnspsc190501.85343407'),
	'identifierSchemeCodeUnspsc190501.85343500': __('identifierSchemeCodeUnspsc190501.85343500'),
	'identifierSchemeCodeUnspsc190501.85343501': __('identifierSchemeCodeUnspsc190501.85343501'),
	'identifierSchemeCodeUnspsc190501.85343502': __('identifierSchemeCodeUnspsc190501.85343502'),
	'identifierSchemeCodeUnspsc190501.85343503': __('identifierSchemeCodeUnspsc190501.85343503'),
	'identifierSchemeCodeUnspsc190501.85343504': __('identifierSchemeCodeUnspsc190501.85343504'),
	'identifierSchemeCodeUnspsc190501.85343505': __('identifierSchemeCodeUnspsc190501.85343505'),
	'identifierSchemeCodeUnspsc190501.85343506': __('identifierSchemeCodeUnspsc190501.85343506'),
	'identifierSchemeCodeUnspsc190501.85343507': __('identifierSchemeCodeUnspsc190501.85343507'),
	'identifierSchemeCodeUnspsc190501.85343508': __('identifierSchemeCodeUnspsc190501.85343508'),
	'identifierSchemeCodeUnspsc190501.85343600': __('identifierSchemeCodeUnspsc190501.85343600'),
	'identifierSchemeCodeUnspsc190501.85343601': __('identifierSchemeCodeUnspsc190501.85343601'),
	'identifierSchemeCodeUnspsc190501.85343602': __('identifierSchemeCodeUnspsc190501.85343602'),
	'identifierSchemeCodeUnspsc190501.85343700': __('identifierSchemeCodeUnspsc190501.85343700'),
	'identifierSchemeCodeUnspsc190501.85343701': __('identifierSchemeCodeUnspsc190501.85343701'),
	'identifierSchemeCodeUnspsc190501.85343702': __('identifierSchemeCodeUnspsc190501.85343702'),
	'identifierSchemeCodeUnspsc190501.85343800': __('identifierSchemeCodeUnspsc190501.85343800'),
	'identifierSchemeCodeUnspsc190501.85343801': __('identifierSchemeCodeUnspsc190501.85343801'),
	'identifierSchemeCodeUnspsc190501.85343802': __('identifierSchemeCodeUnspsc190501.85343802'),
	'identifierSchemeCodeUnspsc190501.85343900': __('identifierSchemeCodeUnspsc190501.85343900'),
	'identifierSchemeCodeUnspsc190501.85343901': __('identifierSchemeCodeUnspsc190501.85343901'),
	'identifierSchemeCodeUnspsc190501.85343902': __('identifierSchemeCodeUnspsc190501.85343902'),
	'identifierSchemeCodeUnspsc190501.85343903': __('identifierSchemeCodeUnspsc190501.85343903'),
	'identifierSchemeCodeUnspsc190501.85344000': __('identifierSchemeCodeUnspsc190501.85344000'),
	'identifierSchemeCodeUnspsc190501.85344001': __('identifierSchemeCodeUnspsc190501.85344001'),
	'identifierSchemeCodeUnspsc190501.85344002': __('identifierSchemeCodeUnspsc190501.85344002'),
	'identifierSchemeCodeUnspsc190501.85344003': __('identifierSchemeCodeUnspsc190501.85344003'),
	'identifierSchemeCodeUnspsc190501.85344004': __('identifierSchemeCodeUnspsc190501.85344004'),
	'identifierSchemeCodeUnspsc190501.85344100': __('identifierSchemeCodeUnspsc190501.85344100'),
	'identifierSchemeCodeUnspsc190501.85344101': __('identifierSchemeCodeUnspsc190501.85344101'),
	'identifierSchemeCodeUnspsc190501.85344102': __('identifierSchemeCodeUnspsc190501.85344102'),
	'identifierSchemeCodeUnspsc190501.85344200': __('identifierSchemeCodeUnspsc190501.85344200'),
	'identifierSchemeCodeUnspsc190501.85344201': __('identifierSchemeCodeUnspsc190501.85344201'),
	'identifierSchemeCodeUnspsc190501.85344202': __('identifierSchemeCodeUnspsc190501.85344202'),
	'identifierSchemeCodeUnspsc190501.85344300': __('identifierSchemeCodeUnspsc190501.85344300'),
	'identifierSchemeCodeUnspsc190501.85344301': __('identifierSchemeCodeUnspsc190501.85344301'),
	'identifierSchemeCodeUnspsc190501.85344302': __('identifierSchemeCodeUnspsc190501.85344302'),
	'identifierSchemeCodeUnspsc190501.85344303': __('identifierSchemeCodeUnspsc190501.85344303'),
	'identifierSchemeCodeUnspsc190501.85344400': __('identifierSchemeCodeUnspsc190501.85344400'),
	'identifierSchemeCodeUnspsc190501.85344401': __('identifierSchemeCodeUnspsc190501.85344401'),
	'identifierSchemeCodeUnspsc190501.85344402': __('identifierSchemeCodeUnspsc190501.85344402'),
	'identifierSchemeCodeUnspsc190501.85344403': __('identifierSchemeCodeUnspsc190501.85344403'),
	'identifierSchemeCodeUnspsc190501.85344404': __('identifierSchemeCodeUnspsc190501.85344404'),
	'identifierSchemeCodeUnspsc190501.85344500': __('identifierSchemeCodeUnspsc190501.85344500'),
	'identifierSchemeCodeUnspsc190501.85344501': __('identifierSchemeCodeUnspsc190501.85344501'),
	'identifierSchemeCodeUnspsc190501.85344502': __('identifierSchemeCodeUnspsc190501.85344502'),
	'identifierSchemeCodeUnspsc190501.85344503': __('identifierSchemeCodeUnspsc190501.85344503'),
	'identifierSchemeCodeUnspsc190501.85344504': __('identifierSchemeCodeUnspsc190501.85344504'),
	'identifierSchemeCodeUnspsc190501.85350000': __('identifierSchemeCodeUnspsc190501.85350000'),
	'identifierSchemeCodeUnspsc190501.85351500': __('identifierSchemeCodeUnspsc190501.85351500'),
	'identifierSchemeCodeUnspsc190501.85351501': __('identifierSchemeCodeUnspsc190501.85351501'),
	'identifierSchemeCodeUnspsc190501.85351502': __('identifierSchemeCodeUnspsc190501.85351502'),
	'identifierSchemeCodeUnspsc190501.85351503': __('identifierSchemeCodeUnspsc190501.85351503'),
	'identifierSchemeCodeUnspsc190501.85351600': __('identifierSchemeCodeUnspsc190501.85351600'),
	'identifierSchemeCodeUnspsc190501.85351601': __('identifierSchemeCodeUnspsc190501.85351601'),
	'identifierSchemeCodeUnspsc190501.85351602': __('identifierSchemeCodeUnspsc190501.85351602'),
	'identifierSchemeCodeUnspsc190501.85351603': __('identifierSchemeCodeUnspsc190501.85351603'),
	'identifierSchemeCodeUnspsc190501.85351604': __('identifierSchemeCodeUnspsc190501.85351604'),
	'identifierSchemeCodeUnspsc190501.85351605': __('identifierSchemeCodeUnspsc190501.85351605'),
	'identifierSchemeCodeUnspsc190501.85351700': __('identifierSchemeCodeUnspsc190501.85351700'),
	'identifierSchemeCodeUnspsc190501.85351701': __('identifierSchemeCodeUnspsc190501.85351701'),
	'identifierSchemeCodeUnspsc190501.85351702': __('identifierSchemeCodeUnspsc190501.85351702'),
	'identifierSchemeCodeUnspsc190501.85351703': __('identifierSchemeCodeUnspsc190501.85351703'),
	'identifierSchemeCodeUnspsc190501.85351800': __('identifierSchemeCodeUnspsc190501.85351800'),
	'identifierSchemeCodeUnspsc190501.85351801': __('identifierSchemeCodeUnspsc190501.85351801'),
	'identifierSchemeCodeUnspsc190501.85351802': __('identifierSchemeCodeUnspsc190501.85351802'),
	'identifierSchemeCodeUnspsc190501.85351803': __('identifierSchemeCodeUnspsc190501.85351803'),
	'identifierSchemeCodeUnspsc190501.85351804': __('identifierSchemeCodeUnspsc190501.85351804'),
	'identifierSchemeCodeUnspsc190501.85351900': __('identifierSchemeCodeUnspsc190501.85351900'),
	'identifierSchemeCodeUnspsc190501.85351901': __('identifierSchemeCodeUnspsc190501.85351901'),
	'identifierSchemeCodeUnspsc190501.85351902': __('identifierSchemeCodeUnspsc190501.85351902'),
	'identifierSchemeCodeUnspsc190501.85352000': __('identifierSchemeCodeUnspsc190501.85352000'),
	'identifierSchemeCodeUnspsc190501.85352001': __('identifierSchemeCodeUnspsc190501.85352001'),
	'identifierSchemeCodeUnspsc190501.85352002': __('identifierSchemeCodeUnspsc190501.85352002'),
	'identifierSchemeCodeUnspsc190501.85352100': __('identifierSchemeCodeUnspsc190501.85352100'),
	'identifierSchemeCodeUnspsc190501.85352101': __('identifierSchemeCodeUnspsc190501.85352101'),
	'identifierSchemeCodeUnspsc190501.85352102': __('identifierSchemeCodeUnspsc190501.85352102'),
	'identifierSchemeCodeUnspsc190501.85352103': __('identifierSchemeCodeUnspsc190501.85352103'),
	'identifierSchemeCodeUnspsc190501.85352104': __('identifierSchemeCodeUnspsc190501.85352104'),
	'identifierSchemeCodeUnspsc190501.85352105': __('identifierSchemeCodeUnspsc190501.85352105'),
	'identifierSchemeCodeUnspsc190501.85352106': __('identifierSchemeCodeUnspsc190501.85352106'),
	'identifierSchemeCodeUnspsc190501.85352200': __('identifierSchemeCodeUnspsc190501.85352200'),
	'identifierSchemeCodeUnspsc190501.85352201': __('identifierSchemeCodeUnspsc190501.85352201'),
	'identifierSchemeCodeUnspsc190501.85352202': __('identifierSchemeCodeUnspsc190501.85352202'),
	'identifierSchemeCodeUnspsc190501.85352300': __('identifierSchemeCodeUnspsc190501.85352300'),
	'identifierSchemeCodeUnspsc190501.85352301': __('identifierSchemeCodeUnspsc190501.85352301'),
	'identifierSchemeCodeUnspsc190501.85352302': __('identifierSchemeCodeUnspsc190501.85352302'),
	'identifierSchemeCodeUnspsc190501.85352303': __('identifierSchemeCodeUnspsc190501.85352303'),
	'identifierSchemeCodeUnspsc190501.85352304': __('identifierSchemeCodeUnspsc190501.85352304'),
	'identifierSchemeCodeUnspsc190501.85352400': __('identifierSchemeCodeUnspsc190501.85352400'),
	'identifierSchemeCodeUnspsc190501.85352401': __('identifierSchemeCodeUnspsc190501.85352401'),
	'identifierSchemeCodeUnspsc190501.85352402': __('identifierSchemeCodeUnspsc190501.85352402'),
	'identifierSchemeCodeUnspsc190501.85352500': __('identifierSchemeCodeUnspsc190501.85352500'),
	'identifierSchemeCodeUnspsc190501.85352501': __('identifierSchemeCodeUnspsc190501.85352501'),
	'identifierSchemeCodeUnspsc190501.85352502': __('identifierSchemeCodeUnspsc190501.85352502'),
	'identifierSchemeCodeUnspsc190501.85352503': __('identifierSchemeCodeUnspsc190501.85352503'),
	'identifierSchemeCodeUnspsc190501.85352504': __('identifierSchemeCodeUnspsc190501.85352504'),
	'identifierSchemeCodeUnspsc190501.85352505': __('identifierSchemeCodeUnspsc190501.85352505'),
	'identifierSchemeCodeUnspsc190501.85352506': __('identifierSchemeCodeUnspsc190501.85352506'),
	'identifierSchemeCodeUnspsc190501.85352507': __('identifierSchemeCodeUnspsc190501.85352507'),
	'identifierSchemeCodeUnspsc190501.85352508': __('identifierSchemeCodeUnspsc190501.85352508'),
	'identifierSchemeCodeUnspsc190501.85352509': __('identifierSchemeCodeUnspsc190501.85352509'),
	'identifierSchemeCodeUnspsc190501.85352510': __('identifierSchemeCodeUnspsc190501.85352510'),
	'identifierSchemeCodeUnspsc190501.85352511': __('identifierSchemeCodeUnspsc190501.85352511'),
	'identifierSchemeCodeUnspsc190501.85352512': __('identifierSchemeCodeUnspsc190501.85352512'),
	'identifierSchemeCodeUnspsc190501.85352513': __('identifierSchemeCodeUnspsc190501.85352513'),
	'identifierSchemeCodeUnspsc190501.85352514': __('identifierSchemeCodeUnspsc190501.85352514'),
	'identifierSchemeCodeUnspsc190501.85352515': __('identifierSchemeCodeUnspsc190501.85352515'),
	'identifierSchemeCodeUnspsc190501.85352516': __('identifierSchemeCodeUnspsc190501.85352516'),
	'identifierSchemeCodeUnspsc190501.85352517': __('identifierSchemeCodeUnspsc190501.85352517'),
	'identifierSchemeCodeUnspsc190501.85352518': __('identifierSchemeCodeUnspsc190501.85352518'),
	'identifierSchemeCodeUnspsc190501.85352519': __('identifierSchemeCodeUnspsc190501.85352519'),
	'identifierSchemeCodeUnspsc190501.85352520': __('identifierSchemeCodeUnspsc190501.85352520'),
	'identifierSchemeCodeUnspsc190501.85352521': __('identifierSchemeCodeUnspsc190501.85352521'),
	'identifierSchemeCodeUnspsc190501.85352522': __('identifierSchemeCodeUnspsc190501.85352522'),
	'identifierSchemeCodeUnspsc190501.85352523': __('identifierSchemeCodeUnspsc190501.85352523'),
	'identifierSchemeCodeUnspsc190501.85352524': __('identifierSchemeCodeUnspsc190501.85352524'),
	'identifierSchemeCodeUnspsc190501.85352525': __('identifierSchemeCodeUnspsc190501.85352525'),
	'identifierSchemeCodeUnspsc190501.85352526': __('identifierSchemeCodeUnspsc190501.85352526'),
	'identifierSchemeCodeUnspsc190501.85352527': __('identifierSchemeCodeUnspsc190501.85352527'),
	'identifierSchemeCodeUnspsc190501.85352528': __('identifierSchemeCodeUnspsc190501.85352528'),
	'identifierSchemeCodeUnspsc190501.85352529': __('identifierSchemeCodeUnspsc190501.85352529'),
	'identifierSchemeCodeUnspsc190501.85352600': __('identifierSchemeCodeUnspsc190501.85352600'),
	'identifierSchemeCodeUnspsc190501.85352601': __('identifierSchemeCodeUnspsc190501.85352601'),
	'identifierSchemeCodeUnspsc190501.85352602': __('identifierSchemeCodeUnspsc190501.85352602'),
	'identifierSchemeCodeUnspsc190501.85352700': __('identifierSchemeCodeUnspsc190501.85352700'),
	'identifierSchemeCodeUnspsc190501.85352701': __('identifierSchemeCodeUnspsc190501.85352701'),
	'identifierSchemeCodeUnspsc190501.85352702': __('identifierSchemeCodeUnspsc190501.85352702'),
	'identifierSchemeCodeUnspsc190501.85352703': __('identifierSchemeCodeUnspsc190501.85352703'),
	'identifierSchemeCodeUnspsc190501.85352800': __('identifierSchemeCodeUnspsc190501.85352800'),
	'identifierSchemeCodeUnspsc190501.85352801': __('identifierSchemeCodeUnspsc190501.85352801'),
	'identifierSchemeCodeUnspsc190501.85352802': __('identifierSchemeCodeUnspsc190501.85352802'),
	'identifierSchemeCodeUnspsc190501.85352803': __('identifierSchemeCodeUnspsc190501.85352803'),
	'identifierSchemeCodeUnspsc190501.85352804': __('identifierSchemeCodeUnspsc190501.85352804'),
	'identifierSchemeCodeUnspsc190501.85352805': __('identifierSchemeCodeUnspsc190501.85352805'),
	'identifierSchemeCodeUnspsc190501.85352806': __('identifierSchemeCodeUnspsc190501.85352806'),
	'identifierSchemeCodeUnspsc190501.85352900': __('identifierSchemeCodeUnspsc190501.85352900'),
	'identifierSchemeCodeUnspsc190501.85352901': __('identifierSchemeCodeUnspsc190501.85352901'),
	'identifierSchemeCodeUnspsc190501.85352902': __('identifierSchemeCodeUnspsc190501.85352902'),
	'identifierSchemeCodeUnspsc190501.85352903': __('identifierSchemeCodeUnspsc190501.85352903'),
	'identifierSchemeCodeUnspsc190501.85352904': __('identifierSchemeCodeUnspsc190501.85352904'),
	'identifierSchemeCodeUnspsc190501.85352905': __('identifierSchemeCodeUnspsc190501.85352905'),
	'identifierSchemeCodeUnspsc190501.85352906': __('identifierSchemeCodeUnspsc190501.85352906'),
	'identifierSchemeCodeUnspsc190501.85353000': __('identifierSchemeCodeUnspsc190501.85353000'),
	'identifierSchemeCodeUnspsc190501.85353001': __('identifierSchemeCodeUnspsc190501.85353001'),
	'identifierSchemeCodeUnspsc190501.85353002': __('identifierSchemeCodeUnspsc190501.85353002'),
	'identifierSchemeCodeUnspsc190501.85353003': __('identifierSchemeCodeUnspsc190501.85353003'),
	'identifierSchemeCodeUnspsc190501.85353004': __('identifierSchemeCodeUnspsc190501.85353004'),
	'identifierSchemeCodeUnspsc190501.85353100': __('identifierSchemeCodeUnspsc190501.85353100'),
	'identifierSchemeCodeUnspsc190501.85353101': __('identifierSchemeCodeUnspsc190501.85353101'),
	'identifierSchemeCodeUnspsc190501.85353102': __('identifierSchemeCodeUnspsc190501.85353102'),
	'identifierSchemeCodeUnspsc190501.85353103': __('identifierSchemeCodeUnspsc190501.85353103'),
	'identifierSchemeCodeUnspsc190501.85353104': __('identifierSchemeCodeUnspsc190501.85353104'),
	'identifierSchemeCodeUnspsc190501.85353105': __('identifierSchemeCodeUnspsc190501.85353105'),
	'identifierSchemeCodeUnspsc190501.85353200': __('identifierSchemeCodeUnspsc190501.85353200'),
	'identifierSchemeCodeUnspsc190501.85353201': __('identifierSchemeCodeUnspsc190501.85353201'),
	'identifierSchemeCodeUnspsc190501.85353202': __('identifierSchemeCodeUnspsc190501.85353202'),
	'identifierSchemeCodeUnspsc190501.85353203': __('identifierSchemeCodeUnspsc190501.85353203'),
	'identifierSchemeCodeUnspsc190501.85353204': __('identifierSchemeCodeUnspsc190501.85353204'),
	'identifierSchemeCodeUnspsc190501.85353205': __('identifierSchemeCodeUnspsc190501.85353205'),
	'identifierSchemeCodeUnspsc190501.85353206': __('identifierSchemeCodeUnspsc190501.85353206'),
	'identifierSchemeCodeUnspsc190501.85353207': __('identifierSchemeCodeUnspsc190501.85353207'),
	'identifierSchemeCodeUnspsc190501.85353300': __('identifierSchemeCodeUnspsc190501.85353300'),
	'identifierSchemeCodeUnspsc190501.85353301': __('identifierSchemeCodeUnspsc190501.85353301'),
	'identifierSchemeCodeUnspsc190501.85353302': __('identifierSchemeCodeUnspsc190501.85353302'),
	'identifierSchemeCodeUnspsc190501.85353303': __('identifierSchemeCodeUnspsc190501.85353303'),
	'identifierSchemeCodeUnspsc190501.85353400': __('identifierSchemeCodeUnspsc190501.85353400'),
	'identifierSchemeCodeUnspsc190501.85353401': __('identifierSchemeCodeUnspsc190501.85353401'),
	'identifierSchemeCodeUnspsc190501.85353402': __('identifierSchemeCodeUnspsc190501.85353402'),
	'identifierSchemeCodeUnspsc190501.85353403': __('identifierSchemeCodeUnspsc190501.85353403'),
	'identifierSchemeCodeUnspsc190501.85353404': __('identifierSchemeCodeUnspsc190501.85353404'),
	'identifierSchemeCodeUnspsc190501.85353405': __('identifierSchemeCodeUnspsc190501.85353405'),
	'identifierSchemeCodeUnspsc190501.85353500': __('identifierSchemeCodeUnspsc190501.85353500'),
	'identifierSchemeCodeUnspsc190501.85353501': __('identifierSchemeCodeUnspsc190501.85353501'),
	'identifierSchemeCodeUnspsc190501.85353502': __('identifierSchemeCodeUnspsc190501.85353502'),
	'identifierSchemeCodeUnspsc190501.85353503': __('identifierSchemeCodeUnspsc190501.85353503'),
	'identifierSchemeCodeUnspsc190501.85353600': __('identifierSchemeCodeUnspsc190501.85353600'),
	'identifierSchemeCodeUnspsc190501.85353601': __('identifierSchemeCodeUnspsc190501.85353601'),
	'identifierSchemeCodeUnspsc190501.85353602': __('identifierSchemeCodeUnspsc190501.85353602'),
	'identifierSchemeCodeUnspsc190501.85353603': __('identifierSchemeCodeUnspsc190501.85353603'),
	'identifierSchemeCodeUnspsc190501.85353604': __('identifierSchemeCodeUnspsc190501.85353604'),
	'identifierSchemeCodeUnspsc190501.85353605': __('identifierSchemeCodeUnspsc190501.85353605'),
	'identifierSchemeCodeUnspsc190501.85353700': __('identifierSchemeCodeUnspsc190501.85353700'),
	'identifierSchemeCodeUnspsc190501.85353701': __('identifierSchemeCodeUnspsc190501.85353701'),
	'identifierSchemeCodeUnspsc190501.85353702': __('identifierSchemeCodeUnspsc190501.85353702'),
	'identifierSchemeCodeUnspsc190501.85353703': __('identifierSchemeCodeUnspsc190501.85353703'),
	'identifierSchemeCodeUnspsc190501.85353704': __('identifierSchemeCodeUnspsc190501.85353704'),
	'identifierSchemeCodeUnspsc190501.85353705': __('identifierSchemeCodeUnspsc190501.85353705'),
	'identifierSchemeCodeUnspsc190501.85353706': __('identifierSchemeCodeUnspsc190501.85353706'),
	'identifierSchemeCodeUnspsc190501.85353707': __('identifierSchemeCodeUnspsc190501.85353707'),
	'identifierSchemeCodeUnspsc190501.85353800': __('identifierSchemeCodeUnspsc190501.85353800'),
	'identifierSchemeCodeUnspsc190501.85353801': __('identifierSchemeCodeUnspsc190501.85353801'),
	'identifierSchemeCodeUnspsc190501.85353802': __('identifierSchemeCodeUnspsc190501.85353802'),
	'identifierSchemeCodeUnspsc190501.85353900': __('identifierSchemeCodeUnspsc190501.85353900'),
	'identifierSchemeCodeUnspsc190501.85353901': __('identifierSchemeCodeUnspsc190501.85353901'),
	'identifierSchemeCodeUnspsc190501.85353902': __('identifierSchemeCodeUnspsc190501.85353902'),
	'identifierSchemeCodeUnspsc190501.85353903': __('identifierSchemeCodeUnspsc190501.85353903'),
	'identifierSchemeCodeUnspsc190501.85353904': __('identifierSchemeCodeUnspsc190501.85353904'),
	'identifierSchemeCodeUnspsc190501.85353905': __('identifierSchemeCodeUnspsc190501.85353905'),
	'identifierSchemeCodeUnspsc190501.85353906': __('identifierSchemeCodeUnspsc190501.85353906'),
	'identifierSchemeCodeUnspsc190501.85353907': __('identifierSchemeCodeUnspsc190501.85353907'),
	'identifierSchemeCodeUnspsc190501.85354000': __('identifierSchemeCodeUnspsc190501.85354000'),
	'identifierSchemeCodeUnspsc190501.85354001': __('identifierSchemeCodeUnspsc190501.85354001'),
	'identifierSchemeCodeUnspsc190501.85354002': __('identifierSchemeCodeUnspsc190501.85354002'),
	'identifierSchemeCodeUnspsc190501.85354003': __('identifierSchemeCodeUnspsc190501.85354003'),
	'identifierSchemeCodeUnspsc190501.85354004': __('identifierSchemeCodeUnspsc190501.85354004'),
	'identifierSchemeCodeUnspsc190501.85354100': __('identifierSchemeCodeUnspsc190501.85354100'),
	'identifierSchemeCodeUnspsc190501.85354101': __('identifierSchemeCodeUnspsc190501.85354101'),
	'identifierSchemeCodeUnspsc190501.85354102': __('identifierSchemeCodeUnspsc190501.85354102'),
	'identifierSchemeCodeUnspsc190501.85354103': __('identifierSchemeCodeUnspsc190501.85354103'),
	'identifierSchemeCodeUnspsc190501.85354200': __('identifierSchemeCodeUnspsc190501.85354200'),
	'identifierSchemeCodeUnspsc190501.85354201': __('identifierSchemeCodeUnspsc190501.85354201'),
	'identifierSchemeCodeUnspsc190501.85354202': __('identifierSchemeCodeUnspsc190501.85354202'),
	'identifierSchemeCodeUnspsc190501.85354203': __('identifierSchemeCodeUnspsc190501.85354203'),
	'identifierSchemeCodeUnspsc190501.85354204': __('identifierSchemeCodeUnspsc190501.85354204'),
	'identifierSchemeCodeUnspsc190501.85354205': __('identifierSchemeCodeUnspsc190501.85354205'),
	'identifierSchemeCodeUnspsc190501.85354300': __('identifierSchemeCodeUnspsc190501.85354300'),
	'identifierSchemeCodeUnspsc190501.85354301': __('identifierSchemeCodeUnspsc190501.85354301'),
	'identifierSchemeCodeUnspsc190501.85354302': __('identifierSchemeCodeUnspsc190501.85354302'),
	'identifierSchemeCodeUnspsc190501.85354400': __('identifierSchemeCodeUnspsc190501.85354400'),
	'identifierSchemeCodeUnspsc190501.85354401': __('identifierSchemeCodeUnspsc190501.85354401'),
	'identifierSchemeCodeUnspsc190501.85354402': __('identifierSchemeCodeUnspsc190501.85354402'),
	'identifierSchemeCodeUnspsc190501.85354403': __('identifierSchemeCodeUnspsc190501.85354403'),
	'identifierSchemeCodeUnspsc190501.85354404': __('identifierSchemeCodeUnspsc190501.85354404'),
	'identifierSchemeCodeUnspsc190501.85354500': __('identifierSchemeCodeUnspsc190501.85354500'),
	'identifierSchemeCodeUnspsc190501.85354501': __('identifierSchemeCodeUnspsc190501.85354501'),
	'identifierSchemeCodeUnspsc190501.85354502': __('identifierSchemeCodeUnspsc190501.85354502'),
	'identifierSchemeCodeUnspsc190501.85354503': __('identifierSchemeCodeUnspsc190501.85354503'),
	'identifierSchemeCodeUnspsc190501.85354504': __('identifierSchemeCodeUnspsc190501.85354504'),
	'identifierSchemeCodeUnspsc190501.85354505': __('identifierSchemeCodeUnspsc190501.85354505'),
	'identifierSchemeCodeUnspsc190501.85354600': __('identifierSchemeCodeUnspsc190501.85354600'),
	'identifierSchemeCodeUnspsc190501.85354601': __('identifierSchemeCodeUnspsc190501.85354601'),
	'identifierSchemeCodeUnspsc190501.85354602': __('identifierSchemeCodeUnspsc190501.85354602'),
	'identifierSchemeCodeUnspsc190501.85354603': __('identifierSchemeCodeUnspsc190501.85354603'),
	'identifierSchemeCodeUnspsc190501.85354700': __('identifierSchemeCodeUnspsc190501.85354700'),
	'identifierSchemeCodeUnspsc190501.85354701': __('identifierSchemeCodeUnspsc190501.85354701'),
	'identifierSchemeCodeUnspsc190501.85354702': __('identifierSchemeCodeUnspsc190501.85354702'),
	'identifierSchemeCodeUnspsc190501.85354703': __('identifierSchemeCodeUnspsc190501.85354703'),
	'identifierSchemeCodeUnspsc190501.85354800': __('identifierSchemeCodeUnspsc190501.85354800'),
	'identifierSchemeCodeUnspsc190501.85354801': __('identifierSchemeCodeUnspsc190501.85354801'),
	'identifierSchemeCodeUnspsc190501.85354802': __('identifierSchemeCodeUnspsc190501.85354802'),
	'identifierSchemeCodeUnspsc190501.85354803': __('identifierSchemeCodeUnspsc190501.85354803'),
	'identifierSchemeCodeUnspsc190501.85354900': __('identifierSchemeCodeUnspsc190501.85354900'),
	'identifierSchemeCodeUnspsc190501.85354901': __('identifierSchemeCodeUnspsc190501.85354901'),
	'identifierSchemeCodeUnspsc190501.85354902': __('identifierSchemeCodeUnspsc190501.85354902'),
	'identifierSchemeCodeUnspsc190501.85354903': __('identifierSchemeCodeUnspsc190501.85354903'),
	'identifierSchemeCodeUnspsc190501.85354904': __('identifierSchemeCodeUnspsc190501.85354904'),
	'identifierSchemeCodeUnspsc190501.85355000': __('identifierSchemeCodeUnspsc190501.85355000'),
	'identifierSchemeCodeUnspsc190501.85355001': __('identifierSchemeCodeUnspsc190501.85355001'),
	'identifierSchemeCodeUnspsc190501.85355002': __('identifierSchemeCodeUnspsc190501.85355002'),
	'identifierSchemeCodeUnspsc190501.85355003': __('identifierSchemeCodeUnspsc190501.85355003'),
	'identifierSchemeCodeUnspsc190501.85355004': __('identifierSchemeCodeUnspsc190501.85355004'),
	'identifierSchemeCodeUnspsc190501.85355005': __('identifierSchemeCodeUnspsc190501.85355005'),
	'identifierSchemeCodeUnspsc190501.85355006': __('identifierSchemeCodeUnspsc190501.85355006'),
	'identifierSchemeCodeUnspsc190501.85355007': __('identifierSchemeCodeUnspsc190501.85355007'),
	'identifierSchemeCodeUnspsc190501.85355008': __('identifierSchemeCodeUnspsc190501.85355008'),
	'identifierSchemeCodeUnspsc190501.85355009': __('identifierSchemeCodeUnspsc190501.85355009'),
	'identifierSchemeCodeUnspsc190501.85355100': __('identifierSchemeCodeUnspsc190501.85355100'),
	'identifierSchemeCodeUnspsc190501.85355101': __('identifierSchemeCodeUnspsc190501.85355101'),
	'identifierSchemeCodeUnspsc190501.85355102': __('identifierSchemeCodeUnspsc190501.85355102'),
	'identifierSchemeCodeUnspsc190501.85355103': __('identifierSchemeCodeUnspsc190501.85355103'),
	'identifierSchemeCodeUnspsc190501.85355200': __('identifierSchemeCodeUnspsc190501.85355200'),
	'identifierSchemeCodeUnspsc190501.85355201': __('identifierSchemeCodeUnspsc190501.85355201'),
	'identifierSchemeCodeUnspsc190501.85355202': __('identifierSchemeCodeUnspsc190501.85355202'),
	'identifierSchemeCodeUnspsc190501.85355203': __('identifierSchemeCodeUnspsc190501.85355203'),
	'identifierSchemeCodeUnspsc190501.85355204': __('identifierSchemeCodeUnspsc190501.85355204'),
	'identifierSchemeCodeUnspsc190501.85355300': __('identifierSchemeCodeUnspsc190501.85355300'),
	'identifierSchemeCodeUnspsc190501.85355301': __('identifierSchemeCodeUnspsc190501.85355301'),
	'identifierSchemeCodeUnspsc190501.85355302': __('identifierSchemeCodeUnspsc190501.85355302'),
	'identifierSchemeCodeUnspsc190501.85355303': __('identifierSchemeCodeUnspsc190501.85355303'),
	'identifierSchemeCodeUnspsc190501.85355304': __('identifierSchemeCodeUnspsc190501.85355304'),
	'identifierSchemeCodeUnspsc190501.85355400': __('identifierSchemeCodeUnspsc190501.85355400'),
	'identifierSchemeCodeUnspsc190501.85355401': __('identifierSchemeCodeUnspsc190501.85355401'),
	'identifierSchemeCodeUnspsc190501.85355402': __('identifierSchemeCodeUnspsc190501.85355402'),
	'identifierSchemeCodeUnspsc190501.85355403': __('identifierSchemeCodeUnspsc190501.85355403'),
	'identifierSchemeCodeUnspsc190501.85355404': __('identifierSchemeCodeUnspsc190501.85355404'),
	'identifierSchemeCodeUnspsc190501.85355500': __('identifierSchemeCodeUnspsc190501.85355500'),
	'identifierSchemeCodeUnspsc190501.85355501': __('identifierSchemeCodeUnspsc190501.85355501'),
	'identifierSchemeCodeUnspsc190501.85355502': __('identifierSchemeCodeUnspsc190501.85355502'),
	'identifierSchemeCodeUnspsc190501.85355503': __('identifierSchemeCodeUnspsc190501.85355503'),
	'identifierSchemeCodeUnspsc190501.85355504': __('identifierSchemeCodeUnspsc190501.85355504'),
	'identifierSchemeCodeUnspsc190501.85355505': __('identifierSchemeCodeUnspsc190501.85355505'),
	'identifierSchemeCodeUnspsc190501.85355506': __('identifierSchemeCodeUnspsc190501.85355506'),
	'identifierSchemeCodeUnspsc190501.85355507': __('identifierSchemeCodeUnspsc190501.85355507'),
	'identifierSchemeCodeUnspsc190501.85355508': __('identifierSchemeCodeUnspsc190501.85355508'),
	'identifierSchemeCodeUnspsc190501.85355600': __('identifierSchemeCodeUnspsc190501.85355600'),
	'identifierSchemeCodeUnspsc190501.85355601': __('identifierSchemeCodeUnspsc190501.85355601'),
	'identifierSchemeCodeUnspsc190501.85355602': __('identifierSchemeCodeUnspsc190501.85355602'),
	'identifierSchemeCodeUnspsc190501.85355603': __('identifierSchemeCodeUnspsc190501.85355603'),
	'identifierSchemeCodeUnspsc190501.85355700': __('identifierSchemeCodeUnspsc190501.85355700'),
	'identifierSchemeCodeUnspsc190501.85355701': __('identifierSchemeCodeUnspsc190501.85355701'),
	'identifierSchemeCodeUnspsc190501.85355702': __('identifierSchemeCodeUnspsc190501.85355702'),
	'identifierSchemeCodeUnspsc190501.85355800': __('identifierSchemeCodeUnspsc190501.85355800'),
	'identifierSchemeCodeUnspsc190501.85355801': __('identifierSchemeCodeUnspsc190501.85355801'),
	'identifierSchemeCodeUnspsc190501.85355802': __('identifierSchemeCodeUnspsc190501.85355802'),
	'identifierSchemeCodeUnspsc190501.85355803': __('identifierSchemeCodeUnspsc190501.85355803'),
	'identifierSchemeCodeUnspsc190501.85355804': __('identifierSchemeCodeUnspsc190501.85355804'),
	'identifierSchemeCodeUnspsc190501.85355805': __('identifierSchemeCodeUnspsc190501.85355805'),
	'identifierSchemeCodeUnspsc190501.85355806': __('identifierSchemeCodeUnspsc190501.85355806'),
	'identifierSchemeCodeUnspsc190501.85355807': __('identifierSchemeCodeUnspsc190501.85355807'),
	'identifierSchemeCodeUnspsc190501.85355900': __('identifierSchemeCodeUnspsc190501.85355900'),
	'identifierSchemeCodeUnspsc190501.85355901': __('identifierSchemeCodeUnspsc190501.85355901'),
	'identifierSchemeCodeUnspsc190501.85355902': __('identifierSchemeCodeUnspsc190501.85355902'),
	'identifierSchemeCodeUnspsc190501.85356000': __('identifierSchemeCodeUnspsc190501.85356000'),
	'identifierSchemeCodeUnspsc190501.85356001': __('identifierSchemeCodeUnspsc190501.85356001'),
	'identifierSchemeCodeUnspsc190501.85356002': __('identifierSchemeCodeUnspsc190501.85356002'),
	'identifierSchemeCodeUnspsc190501.85356003': __('identifierSchemeCodeUnspsc190501.85356003'),
	'identifierSchemeCodeUnspsc190501.85356004': __('identifierSchemeCodeUnspsc190501.85356004'),
	'identifierSchemeCodeUnspsc190501.85356005': __('identifierSchemeCodeUnspsc190501.85356005'),
	'identifierSchemeCodeUnspsc190501.85360000': __('identifierSchemeCodeUnspsc190501.85360000'),
	'identifierSchemeCodeUnspsc190501.85361500': __('identifierSchemeCodeUnspsc190501.85361500'),
	'identifierSchemeCodeUnspsc190501.85361501': __('identifierSchemeCodeUnspsc190501.85361501'),
	'identifierSchemeCodeUnspsc190501.85361502': __('identifierSchemeCodeUnspsc190501.85361502'),
	'identifierSchemeCodeUnspsc190501.85361503': __('identifierSchemeCodeUnspsc190501.85361503'),
	'identifierSchemeCodeUnspsc190501.85361504': __('identifierSchemeCodeUnspsc190501.85361504'),
	'identifierSchemeCodeUnspsc190501.85361505': __('identifierSchemeCodeUnspsc190501.85361505'),
	'identifierSchemeCodeUnspsc190501.85361506': __('identifierSchemeCodeUnspsc190501.85361506'),
	'identifierSchemeCodeUnspsc190501.85361507': __('identifierSchemeCodeUnspsc190501.85361507'),
	'identifierSchemeCodeUnspsc190501.85361508': __('identifierSchemeCodeUnspsc190501.85361508'),
	'identifierSchemeCodeUnspsc190501.85361509': __('identifierSchemeCodeUnspsc190501.85361509'),
	'identifierSchemeCodeUnspsc190501.85361600': __('identifierSchemeCodeUnspsc190501.85361600'),
	'identifierSchemeCodeUnspsc190501.85361601': __('identifierSchemeCodeUnspsc190501.85361601'),
	'identifierSchemeCodeUnspsc190501.85361602': __('identifierSchemeCodeUnspsc190501.85361602'),
	'identifierSchemeCodeUnspsc190501.85361603': __('identifierSchemeCodeUnspsc190501.85361603'),
	'identifierSchemeCodeUnspsc190501.85361604': __('identifierSchemeCodeUnspsc190501.85361604'),
	'identifierSchemeCodeUnspsc190501.85361605': __('identifierSchemeCodeUnspsc190501.85361605'),
	'identifierSchemeCodeUnspsc190501.85361606': __('identifierSchemeCodeUnspsc190501.85361606'),
	'identifierSchemeCodeUnspsc190501.85361607': __('identifierSchemeCodeUnspsc190501.85361607'),
	'identifierSchemeCodeUnspsc190501.85361608': __('identifierSchemeCodeUnspsc190501.85361608'),
	'identifierSchemeCodeUnspsc190501.85361609': __('identifierSchemeCodeUnspsc190501.85361609'),
	'identifierSchemeCodeUnspsc190501.85361700': __('identifierSchemeCodeUnspsc190501.85361700'),
	'identifierSchemeCodeUnspsc190501.85361701': __('identifierSchemeCodeUnspsc190501.85361701'),
	'identifierSchemeCodeUnspsc190501.85361702': __('identifierSchemeCodeUnspsc190501.85361702'),
	'identifierSchemeCodeUnspsc190501.85361703': __('identifierSchemeCodeUnspsc190501.85361703'),
	'identifierSchemeCodeUnspsc190501.85361704': __('identifierSchemeCodeUnspsc190501.85361704'),
	'identifierSchemeCodeUnspsc190501.85361705': __('identifierSchemeCodeUnspsc190501.85361705'),
	'identifierSchemeCodeUnspsc190501.85361706': __('identifierSchemeCodeUnspsc190501.85361706'),
	'identifierSchemeCodeUnspsc190501.85361707': __('identifierSchemeCodeUnspsc190501.85361707'),
	'identifierSchemeCodeUnspsc190501.85361708': __('identifierSchemeCodeUnspsc190501.85361708'),
	'identifierSchemeCodeUnspsc190501.85361709': __('identifierSchemeCodeUnspsc190501.85361709'),
	'identifierSchemeCodeUnspsc190501.85361800': __('identifierSchemeCodeUnspsc190501.85361800'),
	'identifierSchemeCodeUnspsc190501.85361801': __('identifierSchemeCodeUnspsc190501.85361801'),
	'identifierSchemeCodeUnspsc190501.85361802': __('identifierSchemeCodeUnspsc190501.85361802'),
	'identifierSchemeCodeUnspsc190501.85361803': __('identifierSchemeCodeUnspsc190501.85361803'),
	'identifierSchemeCodeUnspsc190501.85361804': __('identifierSchemeCodeUnspsc190501.85361804'),
	'identifierSchemeCodeUnspsc190501.85361805': __('identifierSchemeCodeUnspsc190501.85361805'),
	'identifierSchemeCodeUnspsc190501.85361806': __('identifierSchemeCodeUnspsc190501.85361806'),
	'identifierSchemeCodeUnspsc190501.85361807': __('identifierSchemeCodeUnspsc190501.85361807'),
	'identifierSchemeCodeUnspsc190501.85361808': __('identifierSchemeCodeUnspsc190501.85361808'),
	'identifierSchemeCodeUnspsc190501.85361809': __('identifierSchemeCodeUnspsc190501.85361809'),
	'identifierSchemeCodeUnspsc190501.85361900': __('identifierSchemeCodeUnspsc190501.85361900'),
	'identifierSchemeCodeUnspsc190501.85361901': __('identifierSchemeCodeUnspsc190501.85361901'),
	'identifierSchemeCodeUnspsc190501.85361902': __('identifierSchemeCodeUnspsc190501.85361902'),
	'identifierSchemeCodeUnspsc190501.85361903': __('identifierSchemeCodeUnspsc190501.85361903'),
	'identifierSchemeCodeUnspsc190501.85361904': __('identifierSchemeCodeUnspsc190501.85361904'),
	'identifierSchemeCodeUnspsc190501.85361905': __('identifierSchemeCodeUnspsc190501.85361905'),
	'identifierSchemeCodeUnspsc190501.85361906': __('identifierSchemeCodeUnspsc190501.85361906'),
	'identifierSchemeCodeUnspsc190501.85361907': __('identifierSchemeCodeUnspsc190501.85361907'),
	'identifierSchemeCodeUnspsc190501.85361908': __('identifierSchemeCodeUnspsc190501.85361908'),
	'identifierSchemeCodeUnspsc190501.85361909': __('identifierSchemeCodeUnspsc190501.85361909'),
	'identifierSchemeCodeUnspsc190501.85362000': __('identifierSchemeCodeUnspsc190501.85362000'),
	'identifierSchemeCodeUnspsc190501.85362001': __('identifierSchemeCodeUnspsc190501.85362001'),
	'identifierSchemeCodeUnspsc190501.85362002': __('identifierSchemeCodeUnspsc190501.85362002'),
	'identifierSchemeCodeUnspsc190501.85362003': __('identifierSchemeCodeUnspsc190501.85362003'),
	'identifierSchemeCodeUnspsc190501.85362004': __('identifierSchemeCodeUnspsc190501.85362004'),
	'identifierSchemeCodeUnspsc190501.85362005': __('identifierSchemeCodeUnspsc190501.85362005'),
	'identifierSchemeCodeUnspsc190501.85362006': __('identifierSchemeCodeUnspsc190501.85362006'),
	'identifierSchemeCodeUnspsc190501.85362007': __('identifierSchemeCodeUnspsc190501.85362007'),
	'identifierSchemeCodeUnspsc190501.85362008': __('identifierSchemeCodeUnspsc190501.85362008'),
	'identifierSchemeCodeUnspsc190501.85362009': __('identifierSchemeCodeUnspsc190501.85362009'),
	'identifierSchemeCodeUnspsc190501.85362100': __('identifierSchemeCodeUnspsc190501.85362100'),
	'identifierSchemeCodeUnspsc190501.85362101': __('identifierSchemeCodeUnspsc190501.85362101'),
	'identifierSchemeCodeUnspsc190501.85362102': __('identifierSchemeCodeUnspsc190501.85362102'),
	'identifierSchemeCodeUnspsc190501.85362103': __('identifierSchemeCodeUnspsc190501.85362103'),
	'identifierSchemeCodeUnspsc190501.85362104': __('identifierSchemeCodeUnspsc190501.85362104'),
	'identifierSchemeCodeUnspsc190501.85362200': __('identifierSchemeCodeUnspsc190501.85362200'),
	'identifierSchemeCodeUnspsc190501.85362201': __('identifierSchemeCodeUnspsc190501.85362201'),
	'identifierSchemeCodeUnspsc190501.85362202': __('identifierSchemeCodeUnspsc190501.85362202'),
	'identifierSchemeCodeUnspsc190501.85362203': __('identifierSchemeCodeUnspsc190501.85362203'),
	'identifierSchemeCodeUnspsc190501.85362204': __('identifierSchemeCodeUnspsc190501.85362204'),
	'identifierSchemeCodeUnspsc190501.85362300': __('identifierSchemeCodeUnspsc190501.85362300'),
	'identifierSchemeCodeUnspsc190501.85362301': __('identifierSchemeCodeUnspsc190501.85362301'),
	'identifierSchemeCodeUnspsc190501.85362302': __('identifierSchemeCodeUnspsc190501.85362302'),
	'identifierSchemeCodeUnspsc190501.85362303': __('identifierSchemeCodeUnspsc190501.85362303'),
	'identifierSchemeCodeUnspsc190501.85362304': __('identifierSchemeCodeUnspsc190501.85362304'),
	'identifierSchemeCodeUnspsc190501.85362305': __('identifierSchemeCodeUnspsc190501.85362305'),
	'identifierSchemeCodeUnspsc190501.85362306': __('identifierSchemeCodeUnspsc190501.85362306'),
	'identifierSchemeCodeUnspsc190501.85362400': __('identifierSchemeCodeUnspsc190501.85362400'),
	'identifierSchemeCodeUnspsc190501.85362401': __('identifierSchemeCodeUnspsc190501.85362401'),
	'identifierSchemeCodeUnspsc190501.85362402': __('identifierSchemeCodeUnspsc190501.85362402'),
	'identifierSchemeCodeUnspsc190501.85362500': __('identifierSchemeCodeUnspsc190501.85362500'),
	'identifierSchemeCodeUnspsc190501.85362501': __('identifierSchemeCodeUnspsc190501.85362501'),
	'identifierSchemeCodeUnspsc190501.85362502': __('identifierSchemeCodeUnspsc190501.85362502'),
	'identifierSchemeCodeUnspsc190501.85362600': __('identifierSchemeCodeUnspsc190501.85362600'),
	'identifierSchemeCodeUnspsc190501.85362601': __('identifierSchemeCodeUnspsc190501.85362601'),
	'identifierSchemeCodeUnspsc190501.85362602': __('identifierSchemeCodeUnspsc190501.85362602'),
	'identifierSchemeCodeUnspsc190501.85362603': __('identifierSchemeCodeUnspsc190501.85362603'),
	'identifierSchemeCodeUnspsc190501.85362604': __('identifierSchemeCodeUnspsc190501.85362604'),
	'identifierSchemeCodeUnspsc190501.85362605': __('identifierSchemeCodeUnspsc190501.85362605'),
	'identifierSchemeCodeUnspsc190501.85362700': __('identifierSchemeCodeUnspsc190501.85362700'),
	'identifierSchemeCodeUnspsc190501.85362701': __('identifierSchemeCodeUnspsc190501.85362701'),
	'identifierSchemeCodeUnspsc190501.85362702': __('identifierSchemeCodeUnspsc190501.85362702'),
	'identifierSchemeCodeUnspsc190501.85362703': __('identifierSchemeCodeUnspsc190501.85362703'),
	'identifierSchemeCodeUnspsc190501.85362800': __('identifierSchemeCodeUnspsc190501.85362800'),
	'identifierSchemeCodeUnspsc190501.85362801': __('identifierSchemeCodeUnspsc190501.85362801'),
	'identifierSchemeCodeUnspsc190501.85362802': __('identifierSchemeCodeUnspsc190501.85362802'),
	'identifierSchemeCodeUnspsc190501.85362803': __('identifierSchemeCodeUnspsc190501.85362803'),
	'identifierSchemeCodeUnspsc190501.85362804': __('identifierSchemeCodeUnspsc190501.85362804'),
	'identifierSchemeCodeUnspsc190501.85362805': __('identifierSchemeCodeUnspsc190501.85362805'),
	'identifierSchemeCodeUnspsc190501.85362900': __('identifierSchemeCodeUnspsc190501.85362900'),
	'identifierSchemeCodeUnspsc190501.85362901': __('identifierSchemeCodeUnspsc190501.85362901'),
	'identifierSchemeCodeUnspsc190501.85362902': __('identifierSchemeCodeUnspsc190501.85362902'),
	'identifierSchemeCodeUnspsc190501.85362903': __('identifierSchemeCodeUnspsc190501.85362903'),
	'identifierSchemeCodeUnspsc190501.85363000': __('identifierSchemeCodeUnspsc190501.85363000'),
	'identifierSchemeCodeUnspsc190501.85363001': __('identifierSchemeCodeUnspsc190501.85363001'),
	'identifierSchemeCodeUnspsc190501.85363002': __('identifierSchemeCodeUnspsc190501.85363002'),
	'identifierSchemeCodeUnspsc190501.85363100': __('identifierSchemeCodeUnspsc190501.85363100'),
	'identifierSchemeCodeUnspsc190501.85363101': __('identifierSchemeCodeUnspsc190501.85363101'),
	'identifierSchemeCodeUnspsc190501.85363102': __('identifierSchemeCodeUnspsc190501.85363102'),
	'identifierSchemeCodeUnspsc190501.85363200': __('identifierSchemeCodeUnspsc190501.85363200'),
	'identifierSchemeCodeUnspsc190501.85363201': __('identifierSchemeCodeUnspsc190501.85363201'),
	'identifierSchemeCodeUnspsc190501.85363202': __('identifierSchemeCodeUnspsc190501.85363202'),
	'identifierSchemeCodeUnspsc190501.85363300': __('identifierSchemeCodeUnspsc190501.85363300'),
	'identifierSchemeCodeUnspsc190501.85363301': __('identifierSchemeCodeUnspsc190501.85363301'),
	'identifierSchemeCodeUnspsc190501.85363302': __('identifierSchemeCodeUnspsc190501.85363302'),
	'identifierSchemeCodeUnspsc190501.85363400': __('identifierSchemeCodeUnspsc190501.85363400'),
	'identifierSchemeCodeUnspsc190501.85363401': __('identifierSchemeCodeUnspsc190501.85363401'),
	'identifierSchemeCodeUnspsc190501.85363402': __('identifierSchemeCodeUnspsc190501.85363402'),
	'identifierSchemeCodeUnspsc190501.85363403': __('identifierSchemeCodeUnspsc190501.85363403'),
	'identifierSchemeCodeUnspsc190501.85363500': __('identifierSchemeCodeUnspsc190501.85363500'),
	'identifierSchemeCodeUnspsc190501.85363501': __('identifierSchemeCodeUnspsc190501.85363501'),
	'identifierSchemeCodeUnspsc190501.85363502': __('identifierSchemeCodeUnspsc190501.85363502'),
	'identifierSchemeCodeUnspsc190501.85363503': __('identifierSchemeCodeUnspsc190501.85363503'),
	'identifierSchemeCodeUnspsc190501.85363504': __('identifierSchemeCodeUnspsc190501.85363504'),
	'identifierSchemeCodeUnspsc190501.85363505': __('identifierSchemeCodeUnspsc190501.85363505'),
	'identifierSchemeCodeUnspsc190501.85363600': __('identifierSchemeCodeUnspsc190501.85363600'),
	'identifierSchemeCodeUnspsc190501.85363601': __('identifierSchemeCodeUnspsc190501.85363601'),
	'identifierSchemeCodeUnspsc190501.85363602': __('identifierSchemeCodeUnspsc190501.85363602'),
	'identifierSchemeCodeUnspsc190501.85363603': __('identifierSchemeCodeUnspsc190501.85363603'),
	'identifierSchemeCodeUnspsc190501.85363604': __('identifierSchemeCodeUnspsc190501.85363604'),
	'identifierSchemeCodeUnspsc190501.85363700': __('identifierSchemeCodeUnspsc190501.85363700'),
	'identifierSchemeCodeUnspsc190501.85363701': __('identifierSchemeCodeUnspsc190501.85363701'),
	'identifierSchemeCodeUnspsc190501.85363702': __('identifierSchemeCodeUnspsc190501.85363702'),
	'identifierSchemeCodeUnspsc190501.85363800': __('identifierSchemeCodeUnspsc190501.85363800'),
	'identifierSchemeCodeUnspsc190501.85363801': __('identifierSchemeCodeUnspsc190501.85363801'),
	'identifierSchemeCodeUnspsc190501.85363802': __('identifierSchemeCodeUnspsc190501.85363802'),
	'identifierSchemeCodeUnspsc190501.85363803': __('identifierSchemeCodeUnspsc190501.85363803'),
	'identifierSchemeCodeUnspsc190501.85363804': __('identifierSchemeCodeUnspsc190501.85363804'),
	'identifierSchemeCodeUnspsc190501.85363900': __('identifierSchemeCodeUnspsc190501.85363900'),
	'identifierSchemeCodeUnspsc190501.85363901': __('identifierSchemeCodeUnspsc190501.85363901'),
	'identifierSchemeCodeUnspsc190501.85363902': __('identifierSchemeCodeUnspsc190501.85363902'),
	'identifierSchemeCodeUnspsc190501.85364000': __('identifierSchemeCodeUnspsc190501.85364000'),
	'identifierSchemeCodeUnspsc190501.85364001': __('identifierSchemeCodeUnspsc190501.85364001'),
	'identifierSchemeCodeUnspsc190501.85364002': __('identifierSchemeCodeUnspsc190501.85364002'),
	'identifierSchemeCodeUnspsc190501.85364003': __('identifierSchemeCodeUnspsc190501.85364003'),
	'identifierSchemeCodeUnspsc190501.85364004': __('identifierSchemeCodeUnspsc190501.85364004'),
	'identifierSchemeCodeUnspsc190501.85364100': __('identifierSchemeCodeUnspsc190501.85364100'),
	'identifierSchemeCodeUnspsc190501.85364101': __('identifierSchemeCodeUnspsc190501.85364101'),
	'identifierSchemeCodeUnspsc190501.85364102': __('identifierSchemeCodeUnspsc190501.85364102'),
	'identifierSchemeCodeUnspsc190501.85364103': __('identifierSchemeCodeUnspsc190501.85364103'),
	'identifierSchemeCodeUnspsc190501.85364104': __('identifierSchemeCodeUnspsc190501.85364104'),
	'identifierSchemeCodeUnspsc190501.85364105': __('identifierSchemeCodeUnspsc190501.85364105'),
	'identifierSchemeCodeUnspsc190501.85364200': __('identifierSchemeCodeUnspsc190501.85364200'),
	'identifierSchemeCodeUnspsc190501.85364201': __('identifierSchemeCodeUnspsc190501.85364201'),
	'identifierSchemeCodeUnspsc190501.85364202': __('identifierSchemeCodeUnspsc190501.85364202'),
	'identifierSchemeCodeUnspsc190501.85364203': __('identifierSchemeCodeUnspsc190501.85364203'),
	'identifierSchemeCodeUnspsc190501.85364300': __('identifierSchemeCodeUnspsc190501.85364300'),
	'identifierSchemeCodeUnspsc190501.85364301': __('identifierSchemeCodeUnspsc190501.85364301'),
	'identifierSchemeCodeUnspsc190501.85364302': __('identifierSchemeCodeUnspsc190501.85364302'),
	'identifierSchemeCodeUnspsc190501.85364400': __('identifierSchemeCodeUnspsc190501.85364400'),
	'identifierSchemeCodeUnspsc190501.85364401': __('identifierSchemeCodeUnspsc190501.85364401'),
	'identifierSchemeCodeUnspsc190501.85364402': __('identifierSchemeCodeUnspsc190501.85364402'),
	'identifierSchemeCodeUnspsc190501.85364500': __('identifierSchemeCodeUnspsc190501.85364500'),
	'identifierSchemeCodeUnspsc190501.85364501': __('identifierSchemeCodeUnspsc190501.85364501'),
	'identifierSchemeCodeUnspsc190501.85364502': __('identifierSchemeCodeUnspsc190501.85364502'),
	'identifierSchemeCodeUnspsc190501.85364503': __('identifierSchemeCodeUnspsc190501.85364503'),
	'identifierSchemeCodeUnspsc190501.85364504': __('identifierSchemeCodeUnspsc190501.85364504'),
	'identifierSchemeCodeUnspsc190501.85364505': __('identifierSchemeCodeUnspsc190501.85364505'),
	'identifierSchemeCodeUnspsc190501.85364506': __('identifierSchemeCodeUnspsc190501.85364506'),
	'identifierSchemeCodeUnspsc190501.85364600': __('identifierSchemeCodeUnspsc190501.85364600'),
	'identifierSchemeCodeUnspsc190501.85364601': __('identifierSchemeCodeUnspsc190501.85364601'),
	'identifierSchemeCodeUnspsc190501.85364602': __('identifierSchemeCodeUnspsc190501.85364602'),
	'identifierSchemeCodeUnspsc190501.85364603': __('identifierSchemeCodeUnspsc190501.85364603'),
	'identifierSchemeCodeUnspsc190501.85364700': __('identifierSchemeCodeUnspsc190501.85364700'),
	'identifierSchemeCodeUnspsc190501.85364701': __('identifierSchemeCodeUnspsc190501.85364701'),
	'identifierSchemeCodeUnspsc190501.85364702': __('identifierSchemeCodeUnspsc190501.85364702'),
	'identifierSchemeCodeUnspsc190501.85364800': __('identifierSchemeCodeUnspsc190501.85364800'),
	'identifierSchemeCodeUnspsc190501.85364801': __('identifierSchemeCodeUnspsc190501.85364801'),
	'identifierSchemeCodeUnspsc190501.85364802': __('identifierSchemeCodeUnspsc190501.85364802'),
	'identifierSchemeCodeUnspsc190501.85364803': __('identifierSchemeCodeUnspsc190501.85364803'),
	'identifierSchemeCodeUnspsc190501.85364804': __('identifierSchemeCodeUnspsc190501.85364804'),
	'identifierSchemeCodeUnspsc190501.85364805': __('identifierSchemeCodeUnspsc190501.85364805'),
	'identifierSchemeCodeUnspsc190501.85364900': __('identifierSchemeCodeUnspsc190501.85364900'),
	'identifierSchemeCodeUnspsc190501.85364901': __('identifierSchemeCodeUnspsc190501.85364901'),
	'identifierSchemeCodeUnspsc190501.85364902': __('identifierSchemeCodeUnspsc190501.85364902'),
	'identifierSchemeCodeUnspsc190501.85364903': __('identifierSchemeCodeUnspsc190501.85364903'),
	'identifierSchemeCodeUnspsc190501.85364904': __('identifierSchemeCodeUnspsc190501.85364904'),
	'identifierSchemeCodeUnspsc190501.85364905': __('identifierSchemeCodeUnspsc190501.85364905'),
	'identifierSchemeCodeUnspsc190501.85364906': __('identifierSchemeCodeUnspsc190501.85364906'),
	'identifierSchemeCodeUnspsc190501.85364907': __('identifierSchemeCodeUnspsc190501.85364907'),
	'identifierSchemeCodeUnspsc190501.85365000': __('identifierSchemeCodeUnspsc190501.85365000'),
	'identifierSchemeCodeUnspsc190501.85365001': __('identifierSchemeCodeUnspsc190501.85365001'),
	'identifierSchemeCodeUnspsc190501.85365002': __('identifierSchemeCodeUnspsc190501.85365002'),
	'identifierSchemeCodeUnspsc190501.85365100': __('identifierSchemeCodeUnspsc190501.85365100'),
	'identifierSchemeCodeUnspsc190501.85365101': __('identifierSchemeCodeUnspsc190501.85365101'),
	'identifierSchemeCodeUnspsc190501.85365102': __('identifierSchemeCodeUnspsc190501.85365102'),
	'identifierSchemeCodeUnspsc190501.85365103': __('identifierSchemeCodeUnspsc190501.85365103'),
	'identifierSchemeCodeUnspsc190501.85365200': __('identifierSchemeCodeUnspsc190501.85365200'),
	'identifierSchemeCodeUnspsc190501.85365201': __('identifierSchemeCodeUnspsc190501.85365201'),
	'identifierSchemeCodeUnspsc190501.85365202': __('identifierSchemeCodeUnspsc190501.85365202'),
	'identifierSchemeCodeUnspsc190501.85365203': __('identifierSchemeCodeUnspsc190501.85365203'),
	'identifierSchemeCodeUnspsc190501.85365204': __('identifierSchemeCodeUnspsc190501.85365204'),
	'identifierSchemeCodeUnspsc190501.85365205': __('identifierSchemeCodeUnspsc190501.85365205'),
	'identifierSchemeCodeUnspsc190501.85365206': __('identifierSchemeCodeUnspsc190501.85365206'),
	'identifierSchemeCodeUnspsc190501.85365207': __('identifierSchemeCodeUnspsc190501.85365207'),
	'identifierSchemeCodeUnspsc190501.85365208': __('identifierSchemeCodeUnspsc190501.85365208'),
	'identifierSchemeCodeUnspsc190501.85365209': __('identifierSchemeCodeUnspsc190501.85365209'),
	'identifierSchemeCodeUnspsc190501.85365210': __('identifierSchemeCodeUnspsc190501.85365210'),
	'identifierSchemeCodeUnspsc190501.85365300': __('identifierSchemeCodeUnspsc190501.85365300'),
	'identifierSchemeCodeUnspsc190501.85365301': __('identifierSchemeCodeUnspsc190501.85365301'),
	'identifierSchemeCodeUnspsc190501.85365302': __('identifierSchemeCodeUnspsc190501.85365302'),
	'identifierSchemeCodeUnspsc190501.85365400': __('identifierSchemeCodeUnspsc190501.85365400'),
	'identifierSchemeCodeUnspsc190501.85365401': __('identifierSchemeCodeUnspsc190501.85365401'),
	'identifierSchemeCodeUnspsc190501.85365402': __('identifierSchemeCodeUnspsc190501.85365402'),
	'identifierSchemeCodeUnspsc190501.85365403': __('identifierSchemeCodeUnspsc190501.85365403'),
	'identifierSchemeCodeUnspsc190501.85365404': __('identifierSchemeCodeUnspsc190501.85365404'),
	'identifierSchemeCodeUnspsc190501.85365405': __('identifierSchemeCodeUnspsc190501.85365405'),
	'identifierSchemeCodeUnspsc190501.85365406': __('identifierSchemeCodeUnspsc190501.85365406'),
	'identifierSchemeCodeUnspsc190501.85365407': __('identifierSchemeCodeUnspsc190501.85365407'),
	'identifierSchemeCodeUnspsc190501.85365500': __('identifierSchemeCodeUnspsc190501.85365500'),
	'identifierSchemeCodeUnspsc190501.85365501': __('identifierSchemeCodeUnspsc190501.85365501'),
	'identifierSchemeCodeUnspsc190501.85365502': __('identifierSchemeCodeUnspsc190501.85365502'),
	'identifierSchemeCodeUnspsc190501.85365503': __('identifierSchemeCodeUnspsc190501.85365503'),
	'identifierSchemeCodeUnspsc190501.85365504': __('identifierSchemeCodeUnspsc190501.85365504'),
	'identifierSchemeCodeUnspsc190501.85365505': __('identifierSchemeCodeUnspsc190501.85365505'),
	'identifierSchemeCodeUnspsc190501.85365506': __('identifierSchemeCodeUnspsc190501.85365506'),
	'identifierSchemeCodeUnspsc190501.85365507': __('identifierSchemeCodeUnspsc190501.85365507'),
	'identifierSchemeCodeUnspsc190501.85365600': __('identifierSchemeCodeUnspsc190501.85365600'),
	'identifierSchemeCodeUnspsc190501.85365601': __('identifierSchemeCodeUnspsc190501.85365601'),
	'identifierSchemeCodeUnspsc190501.85365602': __('identifierSchemeCodeUnspsc190501.85365602'),
	'identifierSchemeCodeUnspsc190501.85365603': __('identifierSchemeCodeUnspsc190501.85365603'),
	'identifierSchemeCodeUnspsc190501.85365604': __('identifierSchemeCodeUnspsc190501.85365604'),
	'identifierSchemeCodeUnspsc190501.85365605': __('identifierSchemeCodeUnspsc190501.85365605'),
	'identifierSchemeCodeUnspsc190501.85365606': __('identifierSchemeCodeUnspsc190501.85365606'),
	'identifierSchemeCodeUnspsc190501.85365700': __('identifierSchemeCodeUnspsc190501.85365700'),
	'identifierSchemeCodeUnspsc190501.85365701': __('identifierSchemeCodeUnspsc190501.85365701'),
	'identifierSchemeCodeUnspsc190501.85365702': __('identifierSchemeCodeUnspsc190501.85365702'),
	'identifierSchemeCodeUnspsc190501.85365703': __('identifierSchemeCodeUnspsc190501.85365703'),
	'identifierSchemeCodeUnspsc190501.85365704': __('identifierSchemeCodeUnspsc190501.85365704'),
	'identifierSchemeCodeUnspsc190501.85365800': __('identifierSchemeCodeUnspsc190501.85365800'),
	'identifierSchemeCodeUnspsc190501.85365801': __('identifierSchemeCodeUnspsc190501.85365801'),
	'identifierSchemeCodeUnspsc190501.85365802': __('identifierSchemeCodeUnspsc190501.85365802'),
	'identifierSchemeCodeUnspsc190501.85365803': __('identifierSchemeCodeUnspsc190501.85365803'),
	'identifierSchemeCodeUnspsc190501.85365804': __('identifierSchemeCodeUnspsc190501.85365804'),
	'identifierSchemeCodeUnspsc190501.85365805': __('identifierSchemeCodeUnspsc190501.85365805'),
	'identifierSchemeCodeUnspsc190501.85365806': __('identifierSchemeCodeUnspsc190501.85365806'),
	'identifierSchemeCodeUnspsc190501.85365807': __('identifierSchemeCodeUnspsc190501.85365807'),
	'identifierSchemeCodeUnspsc190501.85365900': __('identifierSchemeCodeUnspsc190501.85365900'),
	'identifierSchemeCodeUnspsc190501.85365901': __('identifierSchemeCodeUnspsc190501.85365901'),
	'identifierSchemeCodeUnspsc190501.85365902': __('identifierSchemeCodeUnspsc190501.85365902'),
	'identifierSchemeCodeUnspsc190501.85365903': __('identifierSchemeCodeUnspsc190501.85365903'),
	'identifierSchemeCodeUnspsc190501.85365904': __('identifierSchemeCodeUnspsc190501.85365904'),
	'identifierSchemeCodeUnspsc190501.85366000': __('identifierSchemeCodeUnspsc190501.85366000'),
	'identifierSchemeCodeUnspsc190501.85366001': __('identifierSchemeCodeUnspsc190501.85366001'),
	'identifierSchemeCodeUnspsc190501.85366002': __('identifierSchemeCodeUnspsc190501.85366002'),
	'identifierSchemeCodeUnspsc190501.85366003': __('identifierSchemeCodeUnspsc190501.85366003'),
	'identifierSchemeCodeUnspsc190501.85366004': __('identifierSchemeCodeUnspsc190501.85366004'),
	'identifierSchemeCodeUnspsc190501.85366005': __('identifierSchemeCodeUnspsc190501.85366005'),
	'identifierSchemeCodeUnspsc190501.85366006': __('identifierSchemeCodeUnspsc190501.85366006'),
	'identifierSchemeCodeUnspsc190501.85366007': __('identifierSchemeCodeUnspsc190501.85366007'),
	'identifierSchemeCodeUnspsc190501.85366008': __('identifierSchemeCodeUnspsc190501.85366008'),
	'identifierSchemeCodeUnspsc190501.85366009': __('identifierSchemeCodeUnspsc190501.85366009'),
	'identifierSchemeCodeUnspsc190501.85366100': __('identifierSchemeCodeUnspsc190501.85366100'),
	'identifierSchemeCodeUnspsc190501.85366101': __('identifierSchemeCodeUnspsc190501.85366101'),
	'identifierSchemeCodeUnspsc190501.85366102': __('identifierSchemeCodeUnspsc190501.85366102'),
	'identifierSchemeCodeUnspsc190501.85366200': __('identifierSchemeCodeUnspsc190501.85366200'),
	'identifierSchemeCodeUnspsc190501.85366201': __('identifierSchemeCodeUnspsc190501.85366201'),
	'identifierSchemeCodeUnspsc190501.85366202': __('identifierSchemeCodeUnspsc190501.85366202'),
	'identifierSchemeCodeUnspsc190501.85366203': __('identifierSchemeCodeUnspsc190501.85366203'),
	'identifierSchemeCodeUnspsc190501.85366204': __('identifierSchemeCodeUnspsc190501.85366204'),
	'identifierSchemeCodeUnspsc190501.85366205': __('identifierSchemeCodeUnspsc190501.85366205'),
	'identifierSchemeCodeUnspsc190501.85366300': __('identifierSchemeCodeUnspsc190501.85366300'),
	'identifierSchemeCodeUnspsc190501.85366301': __('identifierSchemeCodeUnspsc190501.85366301'),
	'identifierSchemeCodeUnspsc190501.85366302': __('identifierSchemeCodeUnspsc190501.85366302'),
	'identifierSchemeCodeUnspsc190501.85366303': __('identifierSchemeCodeUnspsc190501.85366303'),
	'identifierSchemeCodeUnspsc190501.85366304': __('identifierSchemeCodeUnspsc190501.85366304'),
	'identifierSchemeCodeUnspsc190501.85366305': __('identifierSchemeCodeUnspsc190501.85366305'),
	'identifierSchemeCodeUnspsc190501.85366306': __('identifierSchemeCodeUnspsc190501.85366306'),
	'identifierSchemeCodeUnspsc190501.85366307': __('identifierSchemeCodeUnspsc190501.85366307'),
	'identifierSchemeCodeUnspsc190501.85366400': __('identifierSchemeCodeUnspsc190501.85366400'),
	'identifierSchemeCodeUnspsc190501.85366401': __('identifierSchemeCodeUnspsc190501.85366401'),
	'identifierSchemeCodeUnspsc190501.85366402': __('identifierSchemeCodeUnspsc190501.85366402'),
	'identifierSchemeCodeUnspsc190501.85366403': __('identifierSchemeCodeUnspsc190501.85366403'),
	'identifierSchemeCodeUnspsc190501.85366404': __('identifierSchemeCodeUnspsc190501.85366404'),
	'identifierSchemeCodeUnspsc190501.85366405': __('identifierSchemeCodeUnspsc190501.85366405'),
	'identifierSchemeCodeUnspsc190501.85366406': __('identifierSchemeCodeUnspsc190501.85366406'),
	'identifierSchemeCodeUnspsc190501.85366500': __('identifierSchemeCodeUnspsc190501.85366500'),
	'identifierSchemeCodeUnspsc190501.85366501': __('identifierSchemeCodeUnspsc190501.85366501'),
	'identifierSchemeCodeUnspsc190501.85366502': __('identifierSchemeCodeUnspsc190501.85366502'),
	'identifierSchemeCodeUnspsc190501.85366503': __('identifierSchemeCodeUnspsc190501.85366503'),
	'identifierSchemeCodeUnspsc190501.85366504': __('identifierSchemeCodeUnspsc190501.85366504'),
	'identifierSchemeCodeUnspsc190501.85366600': __('identifierSchemeCodeUnspsc190501.85366600'),
	'identifierSchemeCodeUnspsc190501.85366601': __('identifierSchemeCodeUnspsc190501.85366601'),
	'identifierSchemeCodeUnspsc190501.85366602': __('identifierSchemeCodeUnspsc190501.85366602'),
	'identifierSchemeCodeUnspsc190501.85366603': __('identifierSchemeCodeUnspsc190501.85366603'),
	'identifierSchemeCodeUnspsc190501.85366604': __('identifierSchemeCodeUnspsc190501.85366604'),
	'identifierSchemeCodeUnspsc190501.85366605': __('identifierSchemeCodeUnspsc190501.85366605'),
	'identifierSchemeCodeUnspsc190501.85366700': __('identifierSchemeCodeUnspsc190501.85366700'),
	'identifierSchemeCodeUnspsc190501.85366701': __('identifierSchemeCodeUnspsc190501.85366701'),
	'identifierSchemeCodeUnspsc190501.85366702': __('identifierSchemeCodeUnspsc190501.85366702'),
	'identifierSchemeCodeUnspsc190501.85366703': __('identifierSchemeCodeUnspsc190501.85366703'),
	'identifierSchemeCodeUnspsc190501.85366704': __('identifierSchemeCodeUnspsc190501.85366704'),
	'identifierSchemeCodeUnspsc190501.85366705': __('identifierSchemeCodeUnspsc190501.85366705'),
	'identifierSchemeCodeUnspsc190501.85366706': __('identifierSchemeCodeUnspsc190501.85366706'),
	'identifierSchemeCodeUnspsc190501.85366800': __('identifierSchemeCodeUnspsc190501.85366800'),
	'identifierSchemeCodeUnspsc190501.85366801': __('identifierSchemeCodeUnspsc190501.85366801'),
	'identifierSchemeCodeUnspsc190501.85366802': __('identifierSchemeCodeUnspsc190501.85366802'),
	'identifierSchemeCodeUnspsc190501.85366803': __('identifierSchemeCodeUnspsc190501.85366803'),
	'identifierSchemeCodeUnspsc190501.85366804': __('identifierSchemeCodeUnspsc190501.85366804'),
	'identifierSchemeCodeUnspsc190501.85366805': __('identifierSchemeCodeUnspsc190501.85366805'),
	'identifierSchemeCodeUnspsc190501.85366900': __('identifierSchemeCodeUnspsc190501.85366900'),
	'identifierSchemeCodeUnspsc190501.85366901': __('identifierSchemeCodeUnspsc190501.85366901'),
	'identifierSchemeCodeUnspsc190501.85366902': __('identifierSchemeCodeUnspsc190501.85366902'),
	'identifierSchemeCodeUnspsc190501.85366903': __('identifierSchemeCodeUnspsc190501.85366903'),
	'identifierSchemeCodeUnspsc190501.85366904': __('identifierSchemeCodeUnspsc190501.85366904'),
	'identifierSchemeCodeUnspsc190501.85366905': __('identifierSchemeCodeUnspsc190501.85366905'),
	'identifierSchemeCodeUnspsc190501.85367000': __('identifierSchemeCodeUnspsc190501.85367000'),
	'identifierSchemeCodeUnspsc190501.85367001': __('identifierSchemeCodeUnspsc190501.85367001'),
	'identifierSchemeCodeUnspsc190501.85367002': __('identifierSchemeCodeUnspsc190501.85367002'),
	'identifierSchemeCodeUnspsc190501.85367003': __('identifierSchemeCodeUnspsc190501.85367003'),
	'identifierSchemeCodeUnspsc190501.85367004': __('identifierSchemeCodeUnspsc190501.85367004'),
	'identifierSchemeCodeUnspsc190501.85367100': __('identifierSchemeCodeUnspsc190501.85367100'),
	'identifierSchemeCodeUnspsc190501.85367101': __('identifierSchemeCodeUnspsc190501.85367101'),
	'identifierSchemeCodeUnspsc190501.85367102': __('identifierSchemeCodeUnspsc190501.85367102'),
	'identifierSchemeCodeUnspsc190501.85367103': __('identifierSchemeCodeUnspsc190501.85367103'),
	'identifierSchemeCodeUnspsc190501.85367104': __('identifierSchemeCodeUnspsc190501.85367104'),
	'identifierSchemeCodeUnspsc190501.85367105': __('identifierSchemeCodeUnspsc190501.85367105'),
	'identifierSchemeCodeUnspsc190501.85367106': __('identifierSchemeCodeUnspsc190501.85367106'),
	'identifierSchemeCodeUnspsc190501.85370000': __('identifierSchemeCodeUnspsc190501.85370000'),
	'identifierSchemeCodeUnspsc190501.85371500': __('identifierSchemeCodeUnspsc190501.85371500'),
	'identifierSchemeCodeUnspsc190501.85371501': __('identifierSchemeCodeUnspsc190501.85371501'),
	'identifierSchemeCodeUnspsc190501.85371502': __('identifierSchemeCodeUnspsc190501.85371502'),
	'identifierSchemeCodeUnspsc190501.85371600': __('identifierSchemeCodeUnspsc190501.85371600'),
	'identifierSchemeCodeUnspsc190501.85371601': __('identifierSchemeCodeUnspsc190501.85371601'),
	'identifierSchemeCodeUnspsc190501.85371602': __('identifierSchemeCodeUnspsc190501.85371602'),
	'identifierSchemeCodeUnspsc190501.85371603': __('identifierSchemeCodeUnspsc190501.85371603'),
	'identifierSchemeCodeUnspsc190501.85371604': __('identifierSchemeCodeUnspsc190501.85371604'),
	'identifierSchemeCodeUnspsc190501.85371605': __('identifierSchemeCodeUnspsc190501.85371605'),
	'identifierSchemeCodeUnspsc190501.85371700': __('identifierSchemeCodeUnspsc190501.85371700'),
	'identifierSchemeCodeUnspsc190501.85371701': __('identifierSchemeCodeUnspsc190501.85371701'),
	'identifierSchemeCodeUnspsc190501.85371702': __('identifierSchemeCodeUnspsc190501.85371702'),
	'identifierSchemeCodeUnspsc190501.85371703': __('identifierSchemeCodeUnspsc190501.85371703'),
	'identifierSchemeCodeUnspsc190501.85371704': __('identifierSchemeCodeUnspsc190501.85371704'),
	'identifierSchemeCodeUnspsc190501.85371705': __('identifierSchemeCodeUnspsc190501.85371705'),
	'identifierSchemeCodeUnspsc190501.85371706': __('identifierSchemeCodeUnspsc190501.85371706'),
	'identifierSchemeCodeUnspsc190501.85371800': __('identifierSchemeCodeUnspsc190501.85371800'),
	'identifierSchemeCodeUnspsc190501.85371801': __('identifierSchemeCodeUnspsc190501.85371801'),
	'identifierSchemeCodeUnspsc190501.85371802': __('identifierSchemeCodeUnspsc190501.85371802'),
	'identifierSchemeCodeUnspsc190501.85371803': __('identifierSchemeCodeUnspsc190501.85371803'),
	'identifierSchemeCodeUnspsc190501.85371804': __('identifierSchemeCodeUnspsc190501.85371804'),
	'identifierSchemeCodeUnspsc190501.85371805': __('identifierSchemeCodeUnspsc190501.85371805'),
	'identifierSchemeCodeUnspsc190501.85371806': __('identifierSchemeCodeUnspsc190501.85371806'),
	'identifierSchemeCodeUnspsc190501.85371807': __('identifierSchemeCodeUnspsc190501.85371807'),
	'identifierSchemeCodeUnspsc190501.85371900': __('identifierSchemeCodeUnspsc190501.85371900'),
	'identifierSchemeCodeUnspsc190501.85371901': __('identifierSchemeCodeUnspsc190501.85371901'),
	'identifierSchemeCodeUnspsc190501.85371902': __('identifierSchemeCodeUnspsc190501.85371902'),
	'identifierSchemeCodeUnspsc190501.85371903': __('identifierSchemeCodeUnspsc190501.85371903'),
	'identifierSchemeCodeUnspsc190501.85371904': __('identifierSchemeCodeUnspsc190501.85371904'),
	'identifierSchemeCodeUnspsc190501.85371905': __('identifierSchemeCodeUnspsc190501.85371905'),
	'identifierSchemeCodeUnspsc190501.85372000': __('identifierSchemeCodeUnspsc190501.85372000'),
	'identifierSchemeCodeUnspsc190501.85372001': __('identifierSchemeCodeUnspsc190501.85372001'),
	'identifierSchemeCodeUnspsc190501.85372002': __('identifierSchemeCodeUnspsc190501.85372002'),
	'identifierSchemeCodeUnspsc190501.85372003': __('identifierSchemeCodeUnspsc190501.85372003'),
	'identifierSchemeCodeUnspsc190501.85372004': __('identifierSchemeCodeUnspsc190501.85372004'),
	'identifierSchemeCodeUnspsc190501.85372005': __('identifierSchemeCodeUnspsc190501.85372005'),
	'identifierSchemeCodeUnspsc190501.85372006': __('identifierSchemeCodeUnspsc190501.85372006'),
	'identifierSchemeCodeUnspsc190501.85372007': __('identifierSchemeCodeUnspsc190501.85372007'),
	'identifierSchemeCodeUnspsc190501.85372008': __('identifierSchemeCodeUnspsc190501.85372008'),
	'identifierSchemeCodeUnspsc190501.85372100': __('identifierSchemeCodeUnspsc190501.85372100'),
	'identifierSchemeCodeUnspsc190501.85372101': __('identifierSchemeCodeUnspsc190501.85372101'),
	'identifierSchemeCodeUnspsc190501.85372102': __('identifierSchemeCodeUnspsc190501.85372102'),
	'identifierSchemeCodeUnspsc190501.85372103': __('identifierSchemeCodeUnspsc190501.85372103'),
	'identifierSchemeCodeUnspsc190501.85372104': __('identifierSchemeCodeUnspsc190501.85372104'),
	'identifierSchemeCodeUnspsc190501.85372200': __('identifierSchemeCodeUnspsc190501.85372200'),
	'identifierSchemeCodeUnspsc190501.85372201': __('identifierSchemeCodeUnspsc190501.85372201'),
	'identifierSchemeCodeUnspsc190501.85372202': __('identifierSchemeCodeUnspsc190501.85372202'),
	'identifierSchemeCodeUnspsc190501.85372203': __('identifierSchemeCodeUnspsc190501.85372203'),
	'identifierSchemeCodeUnspsc190501.85372204': __('identifierSchemeCodeUnspsc190501.85372204'),
	'identifierSchemeCodeUnspsc190501.85372205': __('identifierSchemeCodeUnspsc190501.85372205'),
	'identifierSchemeCodeUnspsc190501.85372206': __('identifierSchemeCodeUnspsc190501.85372206'),
	'identifierSchemeCodeUnspsc190501.85372207': __('identifierSchemeCodeUnspsc190501.85372207'),
	'identifierSchemeCodeUnspsc190501.85372208': __('identifierSchemeCodeUnspsc190501.85372208'),
	'identifierSchemeCodeUnspsc190501.85372209': __('identifierSchemeCodeUnspsc190501.85372209'),
	'identifierSchemeCodeUnspsc190501.85372210': __('identifierSchemeCodeUnspsc190501.85372210'),
	'identifierSchemeCodeUnspsc190501.85372211': __('identifierSchemeCodeUnspsc190501.85372211'),
	'identifierSchemeCodeUnspsc190501.85372212': __('identifierSchemeCodeUnspsc190501.85372212'),
	'identifierSchemeCodeUnspsc190501.85372300': __('identifierSchemeCodeUnspsc190501.85372300'),
	'identifierSchemeCodeUnspsc190501.85372301': __('identifierSchemeCodeUnspsc190501.85372301'),
	'identifierSchemeCodeUnspsc190501.85372302': __('identifierSchemeCodeUnspsc190501.85372302'),
	'identifierSchemeCodeUnspsc190501.85372303': __('identifierSchemeCodeUnspsc190501.85372303'),
	'identifierSchemeCodeUnspsc190501.85372304': __('identifierSchemeCodeUnspsc190501.85372304'),
	'identifierSchemeCodeUnspsc190501.85372305': __('identifierSchemeCodeUnspsc190501.85372305'),
	'identifierSchemeCodeUnspsc190501.85372400': __('identifierSchemeCodeUnspsc190501.85372400'),
	'identifierSchemeCodeUnspsc190501.85372401': __('identifierSchemeCodeUnspsc190501.85372401'),
	'identifierSchemeCodeUnspsc190501.85372402': __('identifierSchemeCodeUnspsc190501.85372402'),
	'identifierSchemeCodeUnspsc190501.85372403': __('identifierSchemeCodeUnspsc190501.85372403'),
	'identifierSchemeCodeUnspsc190501.85372500': __('identifierSchemeCodeUnspsc190501.85372500'),
	'identifierSchemeCodeUnspsc190501.85372501': __('identifierSchemeCodeUnspsc190501.85372501'),
	'identifierSchemeCodeUnspsc190501.85372502': __('identifierSchemeCodeUnspsc190501.85372502'),
	'identifierSchemeCodeUnspsc190501.85372600': __('identifierSchemeCodeUnspsc190501.85372600'),
	'identifierSchemeCodeUnspsc190501.85372601': __('identifierSchemeCodeUnspsc190501.85372601'),
	'identifierSchemeCodeUnspsc190501.85372602': __('identifierSchemeCodeUnspsc190501.85372602'),
	'identifierSchemeCodeUnspsc190501.85372700': __('identifierSchemeCodeUnspsc190501.85372700'),
	'identifierSchemeCodeUnspsc190501.85372701': __('identifierSchemeCodeUnspsc190501.85372701'),
	'identifierSchemeCodeUnspsc190501.85372702': __('identifierSchemeCodeUnspsc190501.85372702'),
	'identifierSchemeCodeUnspsc190501.85372800': __('identifierSchemeCodeUnspsc190501.85372800'),
	'identifierSchemeCodeUnspsc190501.85372801': __('identifierSchemeCodeUnspsc190501.85372801'),
	'identifierSchemeCodeUnspsc190501.85372802': __('identifierSchemeCodeUnspsc190501.85372802'),
	'identifierSchemeCodeUnspsc190501.85372803': __('identifierSchemeCodeUnspsc190501.85372803'),
	'identifierSchemeCodeUnspsc190501.85372804': __('identifierSchemeCodeUnspsc190501.85372804'),
	'identifierSchemeCodeUnspsc190501.85372805': __('identifierSchemeCodeUnspsc190501.85372805'),
	'identifierSchemeCodeUnspsc190501.85372900': __('identifierSchemeCodeUnspsc190501.85372900'),
	'identifierSchemeCodeUnspsc190501.85372901': __('identifierSchemeCodeUnspsc190501.85372901'),
	'identifierSchemeCodeUnspsc190501.85372902': __('identifierSchemeCodeUnspsc190501.85372902'),
	'identifierSchemeCodeUnspsc190501.85372903': __('identifierSchemeCodeUnspsc190501.85372903'),
	'identifierSchemeCodeUnspsc190501.85372904': __('identifierSchemeCodeUnspsc190501.85372904'),
	'identifierSchemeCodeUnspsc190501.85373000': __('identifierSchemeCodeUnspsc190501.85373000'),
	'identifierSchemeCodeUnspsc190501.85373001': __('identifierSchemeCodeUnspsc190501.85373001'),
	'identifierSchemeCodeUnspsc190501.85373002': __('identifierSchemeCodeUnspsc190501.85373002'),
	'identifierSchemeCodeUnspsc190501.85373003': __('identifierSchemeCodeUnspsc190501.85373003'),
	'identifierSchemeCodeUnspsc190501.85373004': __('identifierSchemeCodeUnspsc190501.85373004'),
	'identifierSchemeCodeUnspsc190501.85373005': __('identifierSchemeCodeUnspsc190501.85373005'),
	'identifierSchemeCodeUnspsc190501.85373100': __('identifierSchemeCodeUnspsc190501.85373100'),
	'identifierSchemeCodeUnspsc190501.85373101': __('identifierSchemeCodeUnspsc190501.85373101'),
	'identifierSchemeCodeUnspsc190501.85373102': __('identifierSchemeCodeUnspsc190501.85373102'),
	'identifierSchemeCodeUnspsc190501.85373103': __('identifierSchemeCodeUnspsc190501.85373103'),
	'identifierSchemeCodeUnspsc190501.85373104': __('identifierSchemeCodeUnspsc190501.85373104'),
	'identifierSchemeCodeUnspsc190501.85373105': __('identifierSchemeCodeUnspsc190501.85373105'),
	'identifierSchemeCodeUnspsc190501.85373106': __('identifierSchemeCodeUnspsc190501.85373106'),
	'identifierSchemeCodeUnspsc190501.85373107': __('identifierSchemeCodeUnspsc190501.85373107'),
	'identifierSchemeCodeUnspsc190501.85373108': __('identifierSchemeCodeUnspsc190501.85373108'),
	'identifierSchemeCodeUnspsc190501.85373109': __('identifierSchemeCodeUnspsc190501.85373109'),
	'identifierSchemeCodeUnspsc190501.85373110': __('identifierSchemeCodeUnspsc190501.85373110'),
	'identifierSchemeCodeUnspsc190501.85373111': __('identifierSchemeCodeUnspsc190501.85373111'),
	'identifierSchemeCodeUnspsc190501.85373112': __('identifierSchemeCodeUnspsc190501.85373112'),
	'identifierSchemeCodeUnspsc190501.85373200': __('identifierSchemeCodeUnspsc190501.85373200'),
	'identifierSchemeCodeUnspsc190501.85373201': __('identifierSchemeCodeUnspsc190501.85373201'),
	'identifierSchemeCodeUnspsc190501.85373202': __('identifierSchemeCodeUnspsc190501.85373202'),
	'identifierSchemeCodeUnspsc190501.85373203': __('identifierSchemeCodeUnspsc190501.85373203'),
	'identifierSchemeCodeUnspsc190501.85373204': __('identifierSchemeCodeUnspsc190501.85373204'),
	'identifierSchemeCodeUnspsc190501.85373300': __('identifierSchemeCodeUnspsc190501.85373300'),
	'identifierSchemeCodeUnspsc190501.85373301': __('identifierSchemeCodeUnspsc190501.85373301'),
	'identifierSchemeCodeUnspsc190501.85373302': __('identifierSchemeCodeUnspsc190501.85373302'),
	'identifierSchemeCodeUnspsc190501.85373303': __('identifierSchemeCodeUnspsc190501.85373303'),
	'identifierSchemeCodeUnspsc190501.85373304': __('identifierSchemeCodeUnspsc190501.85373304'),
	'identifierSchemeCodeUnspsc190501.85373305': __('identifierSchemeCodeUnspsc190501.85373305'),
	'identifierSchemeCodeUnspsc190501.85373306': __('identifierSchemeCodeUnspsc190501.85373306'),
	'identifierSchemeCodeUnspsc190501.85373307': __('identifierSchemeCodeUnspsc190501.85373307'),
	'identifierSchemeCodeUnspsc190501.85373400': __('identifierSchemeCodeUnspsc190501.85373400'),
	'identifierSchemeCodeUnspsc190501.85373401': __('identifierSchemeCodeUnspsc190501.85373401'),
	'identifierSchemeCodeUnspsc190501.85373402': __('identifierSchemeCodeUnspsc190501.85373402'),
	'identifierSchemeCodeUnspsc190501.85373500': __('identifierSchemeCodeUnspsc190501.85373500'),
	'identifierSchemeCodeUnspsc190501.85373501': __('identifierSchemeCodeUnspsc190501.85373501'),
	'identifierSchemeCodeUnspsc190501.85373502': __('identifierSchemeCodeUnspsc190501.85373502'),
	'identifierSchemeCodeUnspsc190501.85373600': __('identifierSchemeCodeUnspsc190501.85373600'),
	'identifierSchemeCodeUnspsc190501.85373601': __('identifierSchemeCodeUnspsc190501.85373601'),
	'identifierSchemeCodeUnspsc190501.85373602': __('identifierSchemeCodeUnspsc190501.85373602'),
	'identifierSchemeCodeUnspsc190501.85373603': __('identifierSchemeCodeUnspsc190501.85373603'),
	'identifierSchemeCodeUnspsc190501.85373700': __('identifierSchemeCodeUnspsc190501.85373700'),
	'identifierSchemeCodeUnspsc190501.85373701': __('identifierSchemeCodeUnspsc190501.85373701'),
	'identifierSchemeCodeUnspsc190501.85373702': __('identifierSchemeCodeUnspsc190501.85373702'),
	'identifierSchemeCodeUnspsc190501.85373703': __('identifierSchemeCodeUnspsc190501.85373703'),
	'identifierSchemeCodeUnspsc190501.85373704': __('identifierSchemeCodeUnspsc190501.85373704'),
	'identifierSchemeCodeUnspsc190501.85373705': __('identifierSchemeCodeUnspsc190501.85373705'),
	'identifierSchemeCodeUnspsc190501.85373706': __('identifierSchemeCodeUnspsc190501.85373706'),
	'identifierSchemeCodeUnspsc190501.85373707': __('identifierSchemeCodeUnspsc190501.85373707'),
	'identifierSchemeCodeUnspsc190501.85373800': __('identifierSchemeCodeUnspsc190501.85373800'),
	'identifierSchemeCodeUnspsc190501.85373801': __('identifierSchemeCodeUnspsc190501.85373801'),
	'identifierSchemeCodeUnspsc190501.85373802': __('identifierSchemeCodeUnspsc190501.85373802'),
	'identifierSchemeCodeUnspsc190501.85373803': __('identifierSchemeCodeUnspsc190501.85373803'),
	'identifierSchemeCodeUnspsc190501.85373900': __('identifierSchemeCodeUnspsc190501.85373900'),
	'identifierSchemeCodeUnspsc190501.85373901': __('identifierSchemeCodeUnspsc190501.85373901'),
	'identifierSchemeCodeUnspsc190501.85373902': __('identifierSchemeCodeUnspsc190501.85373902'),
	'identifierSchemeCodeUnspsc190501.85374000': __('identifierSchemeCodeUnspsc190501.85374000'),
	'identifierSchemeCodeUnspsc190501.85374001': __('identifierSchemeCodeUnspsc190501.85374001'),
	'identifierSchemeCodeUnspsc190501.85374002': __('identifierSchemeCodeUnspsc190501.85374002'),
	'identifierSchemeCodeUnspsc190501.85374100': __('identifierSchemeCodeUnspsc190501.85374100'),
	'identifierSchemeCodeUnspsc190501.85374101': __('identifierSchemeCodeUnspsc190501.85374101'),
	'identifierSchemeCodeUnspsc190501.85374102': __('identifierSchemeCodeUnspsc190501.85374102'),
	'identifierSchemeCodeUnspsc190501.85374103': __('identifierSchemeCodeUnspsc190501.85374103'),
	'identifierSchemeCodeUnspsc190501.85374104': __('identifierSchemeCodeUnspsc190501.85374104'),
	'identifierSchemeCodeUnspsc190501.85374200': __('identifierSchemeCodeUnspsc190501.85374200'),
	'identifierSchemeCodeUnspsc190501.85374201': __('identifierSchemeCodeUnspsc190501.85374201'),
	'identifierSchemeCodeUnspsc190501.85374202': __('identifierSchemeCodeUnspsc190501.85374202'),
	'identifierSchemeCodeUnspsc190501.85374300': __('identifierSchemeCodeUnspsc190501.85374300'),
	'identifierSchemeCodeUnspsc190501.85374301': __('identifierSchemeCodeUnspsc190501.85374301'),
	'identifierSchemeCodeUnspsc190501.85374302': __('identifierSchemeCodeUnspsc190501.85374302'),
	'identifierSchemeCodeUnspsc190501.85374303': __('identifierSchemeCodeUnspsc190501.85374303'),
	'identifierSchemeCodeUnspsc190501.85374304': __('identifierSchemeCodeUnspsc190501.85374304'),
	'identifierSchemeCodeUnspsc190501.85374400': __('identifierSchemeCodeUnspsc190501.85374400'),
	'identifierSchemeCodeUnspsc190501.85374401': __('identifierSchemeCodeUnspsc190501.85374401'),
	'identifierSchemeCodeUnspsc190501.85374402': __('identifierSchemeCodeUnspsc190501.85374402'),
	'identifierSchemeCodeUnspsc190501.85374403': __('identifierSchemeCodeUnspsc190501.85374403'),
	'identifierSchemeCodeUnspsc190501.85374500': __('identifierSchemeCodeUnspsc190501.85374500'),
	'identifierSchemeCodeUnspsc190501.85374501': __('identifierSchemeCodeUnspsc190501.85374501'),
	'identifierSchemeCodeUnspsc190501.85374502': __('identifierSchemeCodeUnspsc190501.85374502'),
	'identifierSchemeCodeUnspsc190501.85374503': __('identifierSchemeCodeUnspsc190501.85374503'),
	'identifierSchemeCodeUnspsc190501.85374504': __('identifierSchemeCodeUnspsc190501.85374504'),
	'identifierSchemeCodeUnspsc190501.85374505': __('identifierSchemeCodeUnspsc190501.85374505'),
	'identifierSchemeCodeUnspsc190501.85374600': __('identifierSchemeCodeUnspsc190501.85374600'),
	'identifierSchemeCodeUnspsc190501.85374601': __('identifierSchemeCodeUnspsc190501.85374601'),
	'identifierSchemeCodeUnspsc190501.85374602': __('identifierSchemeCodeUnspsc190501.85374602'),
	'identifierSchemeCodeUnspsc190501.85374603': __('identifierSchemeCodeUnspsc190501.85374603'),
	'identifierSchemeCodeUnspsc190501.85374604': __('identifierSchemeCodeUnspsc190501.85374604'),
	'identifierSchemeCodeUnspsc190501.85374700': __('identifierSchemeCodeUnspsc190501.85374700'),
	'identifierSchemeCodeUnspsc190501.85374701': __('identifierSchemeCodeUnspsc190501.85374701'),
	'identifierSchemeCodeUnspsc190501.85374702': __('identifierSchemeCodeUnspsc190501.85374702'),
	'identifierSchemeCodeUnspsc190501.85374703': __('identifierSchemeCodeUnspsc190501.85374703'),
	'identifierSchemeCodeUnspsc190501.85374800': __('identifierSchemeCodeUnspsc190501.85374800'),
	'identifierSchemeCodeUnspsc190501.85374801': __('identifierSchemeCodeUnspsc190501.85374801'),
	'identifierSchemeCodeUnspsc190501.85374802': __('identifierSchemeCodeUnspsc190501.85374802'),
	'identifierSchemeCodeUnspsc190501.85374803': __('identifierSchemeCodeUnspsc190501.85374803'),
	'identifierSchemeCodeUnspsc190501.85374804': __('identifierSchemeCodeUnspsc190501.85374804'),
	'identifierSchemeCodeUnspsc190501.85374805': __('identifierSchemeCodeUnspsc190501.85374805'),
	'identifierSchemeCodeUnspsc190501.85374806': __('identifierSchemeCodeUnspsc190501.85374806'),
	'identifierSchemeCodeUnspsc190501.85374807': __('identifierSchemeCodeUnspsc190501.85374807'),
	'identifierSchemeCodeUnspsc190501.85374900': __('identifierSchemeCodeUnspsc190501.85374900'),
	'identifierSchemeCodeUnspsc190501.85374901': __('identifierSchemeCodeUnspsc190501.85374901'),
	'identifierSchemeCodeUnspsc190501.85374902': __('identifierSchemeCodeUnspsc190501.85374902'),
	'identifierSchemeCodeUnspsc190501.85375000': __('identifierSchemeCodeUnspsc190501.85375000'),
	'identifierSchemeCodeUnspsc190501.85375001': __('identifierSchemeCodeUnspsc190501.85375001'),
	'identifierSchemeCodeUnspsc190501.85375002': __('identifierSchemeCodeUnspsc190501.85375002'),
	'identifierSchemeCodeUnspsc190501.85375003': __('identifierSchemeCodeUnspsc190501.85375003'),
	'identifierSchemeCodeUnspsc190501.85375004': __('identifierSchemeCodeUnspsc190501.85375004'),
	'identifierSchemeCodeUnspsc190501.85375100': __('identifierSchemeCodeUnspsc190501.85375100'),
	'identifierSchemeCodeUnspsc190501.85375101': __('identifierSchemeCodeUnspsc190501.85375101'),
	'identifierSchemeCodeUnspsc190501.85375102': __('identifierSchemeCodeUnspsc190501.85375102'),
	'identifierSchemeCodeUnspsc190501.85375103': __('identifierSchemeCodeUnspsc190501.85375103'),
	'identifierSchemeCodeUnspsc190501.85375104': __('identifierSchemeCodeUnspsc190501.85375104'),
	'identifierSchemeCodeUnspsc190501.85375105': __('identifierSchemeCodeUnspsc190501.85375105'),
	'identifierSchemeCodeUnspsc190501.85375106': __('identifierSchemeCodeUnspsc190501.85375106'),
	'identifierSchemeCodeUnspsc190501.85375200': __('identifierSchemeCodeUnspsc190501.85375200'),
	'identifierSchemeCodeUnspsc190501.85375201': __('identifierSchemeCodeUnspsc190501.85375201'),
	'identifierSchemeCodeUnspsc190501.85375202': __('identifierSchemeCodeUnspsc190501.85375202'),
	'identifierSchemeCodeUnspsc190501.85375203': __('identifierSchemeCodeUnspsc190501.85375203'),
	'identifierSchemeCodeUnspsc190501.85375204': __('identifierSchemeCodeUnspsc190501.85375204'),
	'identifierSchemeCodeUnspsc190501.85375300': __('identifierSchemeCodeUnspsc190501.85375300'),
	'identifierSchemeCodeUnspsc190501.85375301': __('identifierSchemeCodeUnspsc190501.85375301'),
	'identifierSchemeCodeUnspsc190501.85375302': __('identifierSchemeCodeUnspsc190501.85375302'),
	'identifierSchemeCodeUnspsc190501.85375303': __('identifierSchemeCodeUnspsc190501.85375303'),
	'identifierSchemeCodeUnspsc190501.85375304': __('identifierSchemeCodeUnspsc190501.85375304'),
	'identifierSchemeCodeUnspsc190501.85375400': __('identifierSchemeCodeUnspsc190501.85375400'),
	'identifierSchemeCodeUnspsc190501.85375401': __('identifierSchemeCodeUnspsc190501.85375401'),
	'identifierSchemeCodeUnspsc190501.85375402': __('identifierSchemeCodeUnspsc190501.85375402'),
	'identifierSchemeCodeUnspsc190501.85375403': __('identifierSchemeCodeUnspsc190501.85375403'),
	'identifierSchemeCodeUnspsc190501.85375500': __('identifierSchemeCodeUnspsc190501.85375500'),
	'identifierSchemeCodeUnspsc190501.85375501': __('identifierSchemeCodeUnspsc190501.85375501'),
	'identifierSchemeCodeUnspsc190501.85375502': __('identifierSchemeCodeUnspsc190501.85375502'),
	'identifierSchemeCodeUnspsc190501.85375503': __('identifierSchemeCodeUnspsc190501.85375503'),
	'identifierSchemeCodeUnspsc190501.85375504': __('identifierSchemeCodeUnspsc190501.85375504'),
	'identifierSchemeCodeUnspsc190501.85375505': __('identifierSchemeCodeUnspsc190501.85375505'),
	'identifierSchemeCodeUnspsc190501.85375600': __('identifierSchemeCodeUnspsc190501.85375600'),
	'identifierSchemeCodeUnspsc190501.85375601': __('identifierSchemeCodeUnspsc190501.85375601'),
	'identifierSchemeCodeUnspsc190501.85375602': __('identifierSchemeCodeUnspsc190501.85375602'),
	'identifierSchemeCodeUnspsc190501.85375603': __('identifierSchemeCodeUnspsc190501.85375603'),
	'identifierSchemeCodeUnspsc190501.85375700': __('identifierSchemeCodeUnspsc190501.85375700'),
	'identifierSchemeCodeUnspsc190501.85375701': __('identifierSchemeCodeUnspsc190501.85375701'),
	'identifierSchemeCodeUnspsc190501.85375702': __('identifierSchemeCodeUnspsc190501.85375702'),
	'identifierSchemeCodeUnspsc190501.85375703': __('identifierSchemeCodeUnspsc190501.85375703'),
	'identifierSchemeCodeUnspsc190501.85375704': __('identifierSchemeCodeUnspsc190501.85375704'),
	'identifierSchemeCodeUnspsc190501.85375705': __('identifierSchemeCodeUnspsc190501.85375705'),
	'identifierSchemeCodeUnspsc190501.85375800': __('identifierSchemeCodeUnspsc190501.85375800'),
	'identifierSchemeCodeUnspsc190501.85375801': __('identifierSchemeCodeUnspsc190501.85375801'),
	'identifierSchemeCodeUnspsc190501.85375802': __('identifierSchemeCodeUnspsc190501.85375802'),
	'identifierSchemeCodeUnspsc190501.85375900': __('identifierSchemeCodeUnspsc190501.85375900'),
	'identifierSchemeCodeUnspsc190501.85375901': __('identifierSchemeCodeUnspsc190501.85375901'),
	'identifierSchemeCodeUnspsc190501.85375902': __('identifierSchemeCodeUnspsc190501.85375902'),
	'identifierSchemeCodeUnspsc190501.85375903': __('identifierSchemeCodeUnspsc190501.85375903'),
	'identifierSchemeCodeUnspsc190501.85375904': __('identifierSchemeCodeUnspsc190501.85375904'),
	'identifierSchemeCodeUnspsc190501.85375905': __('identifierSchemeCodeUnspsc190501.85375905'),
	'identifierSchemeCodeUnspsc190501.85375906': __('identifierSchemeCodeUnspsc190501.85375906'),
	'identifierSchemeCodeUnspsc190501.85376000': __('identifierSchemeCodeUnspsc190501.85376000'),
	'identifierSchemeCodeUnspsc190501.85376001': __('identifierSchemeCodeUnspsc190501.85376001'),
	'identifierSchemeCodeUnspsc190501.85376002': __('identifierSchemeCodeUnspsc190501.85376002'),
	'identifierSchemeCodeUnspsc190501.85376003': __('identifierSchemeCodeUnspsc190501.85376003'),
	'identifierSchemeCodeUnspsc190501.85376004': __('identifierSchemeCodeUnspsc190501.85376004'),
	'identifierSchemeCodeUnspsc190501.85376005': __('identifierSchemeCodeUnspsc190501.85376005'),
	'identifierSchemeCodeUnspsc190501.85376006': __('identifierSchemeCodeUnspsc190501.85376006'),
	'identifierSchemeCodeUnspsc190501.85376007': __('identifierSchemeCodeUnspsc190501.85376007'),
	'identifierSchemeCodeUnspsc190501.85376008': __('identifierSchemeCodeUnspsc190501.85376008'),
	'identifierSchemeCodeUnspsc190501.85376100': __('identifierSchemeCodeUnspsc190501.85376100'),
	'identifierSchemeCodeUnspsc190501.85376101': __('identifierSchemeCodeUnspsc190501.85376101'),
	'identifierSchemeCodeUnspsc190501.85376102': __('identifierSchemeCodeUnspsc190501.85376102'),
	'identifierSchemeCodeUnspsc190501.85376103': __('identifierSchemeCodeUnspsc190501.85376103'),
	'identifierSchemeCodeUnspsc190501.85376104': __('identifierSchemeCodeUnspsc190501.85376104'),
	'identifierSchemeCodeUnspsc190501.85376105': __('identifierSchemeCodeUnspsc190501.85376105'),
	'identifierSchemeCodeUnspsc190501.85376200': __('identifierSchemeCodeUnspsc190501.85376200'),
	'identifierSchemeCodeUnspsc190501.85376201': __('identifierSchemeCodeUnspsc190501.85376201'),
	'identifierSchemeCodeUnspsc190501.85376202': __('identifierSchemeCodeUnspsc190501.85376202'),
	'identifierSchemeCodeUnspsc190501.85376203': __('identifierSchemeCodeUnspsc190501.85376203'),
	'identifierSchemeCodeUnspsc190501.85376204': __('identifierSchemeCodeUnspsc190501.85376204'),
	'identifierSchemeCodeUnspsc190501.85376205': __('identifierSchemeCodeUnspsc190501.85376205'),
	'identifierSchemeCodeUnspsc190501.85376300': __('identifierSchemeCodeUnspsc190501.85376300'),
	'identifierSchemeCodeUnspsc190501.85376301': __('identifierSchemeCodeUnspsc190501.85376301'),
	'identifierSchemeCodeUnspsc190501.85376302': __('identifierSchemeCodeUnspsc190501.85376302'),
	'identifierSchemeCodeUnspsc190501.85376303': __('identifierSchemeCodeUnspsc190501.85376303'),
	'identifierSchemeCodeUnspsc190501.85376304': __('identifierSchemeCodeUnspsc190501.85376304'),
	'identifierSchemeCodeUnspsc190501.85376305': __('identifierSchemeCodeUnspsc190501.85376305'),
	'identifierSchemeCodeUnspsc190501.85376306': __('identifierSchemeCodeUnspsc190501.85376306'),
	'identifierSchemeCodeUnspsc190501.85376400': __('identifierSchemeCodeUnspsc190501.85376400'),
	'identifierSchemeCodeUnspsc190501.85376401': __('identifierSchemeCodeUnspsc190501.85376401'),
	'identifierSchemeCodeUnspsc190501.85376402': __('identifierSchemeCodeUnspsc190501.85376402'),
	'identifierSchemeCodeUnspsc190501.85376403': __('identifierSchemeCodeUnspsc190501.85376403'),
	'identifierSchemeCodeUnspsc190501.85376404': __('identifierSchemeCodeUnspsc190501.85376404'),
	'identifierSchemeCodeUnspsc190501.85376405': __('identifierSchemeCodeUnspsc190501.85376405'),
	'identifierSchemeCodeUnspsc190501.85376406': __('identifierSchemeCodeUnspsc190501.85376406'),
	'identifierSchemeCodeUnspsc190501.85376407': __('identifierSchemeCodeUnspsc190501.85376407'),
	'identifierSchemeCodeUnspsc190501.85376500': __('identifierSchemeCodeUnspsc190501.85376500'),
	'identifierSchemeCodeUnspsc190501.85376501': __('identifierSchemeCodeUnspsc190501.85376501'),
	'identifierSchemeCodeUnspsc190501.85376502': __('identifierSchemeCodeUnspsc190501.85376502'),
	'identifierSchemeCodeUnspsc190501.85376600': __('identifierSchemeCodeUnspsc190501.85376600'),
	'identifierSchemeCodeUnspsc190501.85376601': __('identifierSchemeCodeUnspsc190501.85376601'),
	'identifierSchemeCodeUnspsc190501.85376602': __('identifierSchemeCodeUnspsc190501.85376602'),
	'identifierSchemeCodeUnspsc190501.85376603': __('identifierSchemeCodeUnspsc190501.85376603'),
	'identifierSchemeCodeUnspsc190501.85376604': __('identifierSchemeCodeUnspsc190501.85376604'),
	'identifierSchemeCodeUnspsc190501.85376605': __('identifierSchemeCodeUnspsc190501.85376605'),
	'identifierSchemeCodeUnspsc190501.85376700': __('identifierSchemeCodeUnspsc190501.85376700'),
	'identifierSchemeCodeUnspsc190501.85376701': __('identifierSchemeCodeUnspsc190501.85376701'),
	'identifierSchemeCodeUnspsc190501.85376702': __('identifierSchemeCodeUnspsc190501.85376702'),
	'identifierSchemeCodeUnspsc190501.85376703': __('identifierSchemeCodeUnspsc190501.85376703'),
	'identifierSchemeCodeUnspsc190501.85376800': __('identifierSchemeCodeUnspsc190501.85376800'),
	'identifierSchemeCodeUnspsc190501.85376801': __('identifierSchemeCodeUnspsc190501.85376801'),
	'identifierSchemeCodeUnspsc190501.85376802': __('identifierSchemeCodeUnspsc190501.85376802'),
	'identifierSchemeCodeUnspsc190501.85376803': __('identifierSchemeCodeUnspsc190501.85376803'),
	'identifierSchemeCodeUnspsc190501.85376804': __('identifierSchemeCodeUnspsc190501.85376804'),
	'identifierSchemeCodeUnspsc190501.85376805': __('identifierSchemeCodeUnspsc190501.85376805'),
	'identifierSchemeCodeUnspsc190501.85376806': __('identifierSchemeCodeUnspsc190501.85376806'),
	'identifierSchemeCodeUnspsc190501.85376900': __('identifierSchemeCodeUnspsc190501.85376900'),
	'identifierSchemeCodeUnspsc190501.85376901': __('identifierSchemeCodeUnspsc190501.85376901'),
	'identifierSchemeCodeUnspsc190501.85376902': __('identifierSchemeCodeUnspsc190501.85376902'),
	'identifierSchemeCodeUnspsc190501.85376903': __('identifierSchemeCodeUnspsc190501.85376903'),
	'identifierSchemeCodeUnspsc190501.85376904': __('identifierSchemeCodeUnspsc190501.85376904'),
	'identifierSchemeCodeUnspsc190501.85377000': __('identifierSchemeCodeUnspsc190501.85377000'),
	'identifierSchemeCodeUnspsc190501.85377001': __('identifierSchemeCodeUnspsc190501.85377001'),
	'identifierSchemeCodeUnspsc190501.85377002': __('identifierSchemeCodeUnspsc190501.85377002'),
	'identifierSchemeCodeUnspsc190501.85377003': __('identifierSchemeCodeUnspsc190501.85377003'),
	'identifierSchemeCodeUnspsc190501.85377004': __('identifierSchemeCodeUnspsc190501.85377004'),
	'identifierSchemeCodeUnspsc190501.85377100': __('identifierSchemeCodeUnspsc190501.85377100'),
	'identifierSchemeCodeUnspsc190501.85377101': __('identifierSchemeCodeUnspsc190501.85377101'),
	'identifierSchemeCodeUnspsc190501.85377102': __('identifierSchemeCodeUnspsc190501.85377102'),
	'identifierSchemeCodeUnspsc190501.85377103': __('identifierSchemeCodeUnspsc190501.85377103'),
	'identifierSchemeCodeUnspsc190501.85377104': __('identifierSchemeCodeUnspsc190501.85377104'),
	'identifierSchemeCodeUnspsc190501.85377105': __('identifierSchemeCodeUnspsc190501.85377105'),
	'identifierSchemeCodeUnspsc190501.85377200': __('identifierSchemeCodeUnspsc190501.85377200'),
	'identifierSchemeCodeUnspsc190501.85377201': __('identifierSchemeCodeUnspsc190501.85377201'),
	'identifierSchemeCodeUnspsc190501.85377202': __('identifierSchemeCodeUnspsc190501.85377202'),
	'identifierSchemeCodeUnspsc190501.85377203': __('identifierSchemeCodeUnspsc190501.85377203'),
	'identifierSchemeCodeUnspsc190501.85377204': __('identifierSchemeCodeUnspsc190501.85377204'),
	'identifierSchemeCodeUnspsc190501.85377205': __('identifierSchemeCodeUnspsc190501.85377205'),
	'identifierSchemeCodeUnspsc190501.85377300': __('identifierSchemeCodeUnspsc190501.85377300'),
	'identifierSchemeCodeUnspsc190501.85377301': __('identifierSchemeCodeUnspsc190501.85377301'),
	'identifierSchemeCodeUnspsc190501.85377302': __('identifierSchemeCodeUnspsc190501.85377302'),
	'identifierSchemeCodeUnspsc190501.85377303': __('identifierSchemeCodeUnspsc190501.85377303'),
	'identifierSchemeCodeUnspsc190501.85377304': __('identifierSchemeCodeUnspsc190501.85377304'),
	'identifierSchemeCodeUnspsc190501.85377305': __('identifierSchemeCodeUnspsc190501.85377305'),
	'identifierSchemeCodeUnspsc190501.85377306': __('identifierSchemeCodeUnspsc190501.85377306'),
	'identifierSchemeCodeUnspsc190501.85377307': __('identifierSchemeCodeUnspsc190501.85377307'),
	'identifierSchemeCodeUnspsc190501.85377308': __('identifierSchemeCodeUnspsc190501.85377308'),
	'identifierSchemeCodeUnspsc190501.85377309': __('identifierSchemeCodeUnspsc190501.85377309'),
	'identifierSchemeCodeUnspsc190501.85377310': __('identifierSchemeCodeUnspsc190501.85377310'),
	'identifierSchemeCodeUnspsc190501.85377311': __('identifierSchemeCodeUnspsc190501.85377311'),
	'identifierSchemeCodeUnspsc190501.85377400': __('identifierSchemeCodeUnspsc190501.85377400'),
	'identifierSchemeCodeUnspsc190501.85377401': __('identifierSchemeCodeUnspsc190501.85377401'),
	'identifierSchemeCodeUnspsc190501.85377402': __('identifierSchemeCodeUnspsc190501.85377402'),
	'identifierSchemeCodeUnspsc190501.85377403': __('identifierSchemeCodeUnspsc190501.85377403'),
	'identifierSchemeCodeUnspsc190501.85377500': __('identifierSchemeCodeUnspsc190501.85377500'),
	'identifierSchemeCodeUnspsc190501.85377501': __('identifierSchemeCodeUnspsc190501.85377501'),
	'identifierSchemeCodeUnspsc190501.85377502': __('identifierSchemeCodeUnspsc190501.85377502'),
	'identifierSchemeCodeUnspsc190501.85377503': __('identifierSchemeCodeUnspsc190501.85377503'),
	'identifierSchemeCodeUnspsc190501.85377504': __('identifierSchemeCodeUnspsc190501.85377504'),
	'identifierSchemeCodeUnspsc190501.85377600': __('identifierSchemeCodeUnspsc190501.85377600'),
	'identifierSchemeCodeUnspsc190501.85377601': __('identifierSchemeCodeUnspsc190501.85377601'),
	'identifierSchemeCodeUnspsc190501.85377602': __('identifierSchemeCodeUnspsc190501.85377602'),
	'identifierSchemeCodeUnspsc190501.85377603': __('identifierSchemeCodeUnspsc190501.85377603'),
	'identifierSchemeCodeUnspsc190501.85377604': __('identifierSchemeCodeUnspsc190501.85377604'),
	'identifierSchemeCodeUnspsc190501.85377605': __('identifierSchemeCodeUnspsc190501.85377605'),
	'identifierSchemeCodeUnspsc190501.85377700': __('identifierSchemeCodeUnspsc190501.85377700'),
	'identifierSchemeCodeUnspsc190501.85377701': __('identifierSchemeCodeUnspsc190501.85377701'),
	'identifierSchemeCodeUnspsc190501.85377702': __('identifierSchemeCodeUnspsc190501.85377702'),
	'identifierSchemeCodeUnspsc190501.85377703': __('identifierSchemeCodeUnspsc190501.85377703'),
	'identifierSchemeCodeUnspsc190501.85377704': __('identifierSchemeCodeUnspsc190501.85377704'),
	'identifierSchemeCodeUnspsc190501.85377705': __('identifierSchemeCodeUnspsc190501.85377705'),
	'identifierSchemeCodeUnspsc190501.85377706': __('identifierSchemeCodeUnspsc190501.85377706'),
	'identifierSchemeCodeUnspsc190501.85377800': __('identifierSchemeCodeUnspsc190501.85377800'),
	'identifierSchemeCodeUnspsc190501.85377801': __('identifierSchemeCodeUnspsc190501.85377801'),
	'identifierSchemeCodeUnspsc190501.85377802': __('identifierSchemeCodeUnspsc190501.85377802'),
	'identifierSchemeCodeUnspsc190501.85377803': __('identifierSchemeCodeUnspsc190501.85377803'),
	'identifierSchemeCodeUnspsc190501.85377804': __('identifierSchemeCodeUnspsc190501.85377804'),
	'identifierSchemeCodeUnspsc190501.85377805': __('identifierSchemeCodeUnspsc190501.85377805'),
	'identifierSchemeCodeUnspsc190501.85377806': __('identifierSchemeCodeUnspsc190501.85377806'),
	'identifierSchemeCodeUnspsc190501.85377807': __('identifierSchemeCodeUnspsc190501.85377807'),
	'identifierSchemeCodeUnspsc190501.85377808': __('identifierSchemeCodeUnspsc190501.85377808'),
	'identifierSchemeCodeUnspsc190501.85380000': __('identifierSchemeCodeUnspsc190501.85380000'),
	'identifierSchemeCodeUnspsc190501.85381500': __('identifierSchemeCodeUnspsc190501.85381500'),
	'identifierSchemeCodeUnspsc190501.85381501': __('identifierSchemeCodeUnspsc190501.85381501'),
	'identifierSchemeCodeUnspsc190501.85381502': __('identifierSchemeCodeUnspsc190501.85381502'),
	'identifierSchemeCodeUnspsc190501.85381503': __('identifierSchemeCodeUnspsc190501.85381503'),
	'identifierSchemeCodeUnspsc190501.85381504': __('identifierSchemeCodeUnspsc190501.85381504'),
	'identifierSchemeCodeUnspsc190501.85381505': __('identifierSchemeCodeUnspsc190501.85381505'),
	'identifierSchemeCodeUnspsc190501.85381506': __('identifierSchemeCodeUnspsc190501.85381506'),
	'identifierSchemeCodeUnspsc190501.85381600': __('identifierSchemeCodeUnspsc190501.85381600'),
	'identifierSchemeCodeUnspsc190501.85381601': __('identifierSchemeCodeUnspsc190501.85381601'),
	'identifierSchemeCodeUnspsc190501.85381602': __('identifierSchemeCodeUnspsc190501.85381602'),
	'identifierSchemeCodeUnspsc190501.85381603': __('identifierSchemeCodeUnspsc190501.85381603'),
	'identifierSchemeCodeUnspsc190501.85381604': __('identifierSchemeCodeUnspsc190501.85381604'),
	'identifierSchemeCodeUnspsc190501.85381605': __('identifierSchemeCodeUnspsc190501.85381605'),
	'identifierSchemeCodeUnspsc190501.85381606': __('identifierSchemeCodeUnspsc190501.85381606'),
	'identifierSchemeCodeUnspsc190501.85381607': __('identifierSchemeCodeUnspsc190501.85381607'),
	'identifierSchemeCodeUnspsc190501.85381608': __('identifierSchemeCodeUnspsc190501.85381608'),
	'identifierSchemeCodeUnspsc190501.85381700': __('identifierSchemeCodeUnspsc190501.85381700'),
	'identifierSchemeCodeUnspsc190501.85381701': __('identifierSchemeCodeUnspsc190501.85381701'),
	'identifierSchemeCodeUnspsc190501.85381702': __('identifierSchemeCodeUnspsc190501.85381702'),
	'identifierSchemeCodeUnspsc190501.85381703': __('identifierSchemeCodeUnspsc190501.85381703'),
	'identifierSchemeCodeUnspsc190501.85381704': __('identifierSchemeCodeUnspsc190501.85381704'),
	'identifierSchemeCodeUnspsc190501.85381800': __('identifierSchemeCodeUnspsc190501.85381800'),
	'identifierSchemeCodeUnspsc190501.85381801': __('identifierSchemeCodeUnspsc190501.85381801'),
	'identifierSchemeCodeUnspsc190501.85381802': __('identifierSchemeCodeUnspsc190501.85381802'),
	'identifierSchemeCodeUnspsc190501.85381803': __('identifierSchemeCodeUnspsc190501.85381803'),
	'identifierSchemeCodeUnspsc190501.85381804': __('identifierSchemeCodeUnspsc190501.85381804'),
	'identifierSchemeCodeUnspsc190501.85381805': __('identifierSchemeCodeUnspsc190501.85381805'),
	'identifierSchemeCodeUnspsc190501.85381806': __('identifierSchemeCodeUnspsc190501.85381806'),
	'identifierSchemeCodeUnspsc190501.85381900': __('identifierSchemeCodeUnspsc190501.85381900'),
	'identifierSchemeCodeUnspsc190501.85381901': __('identifierSchemeCodeUnspsc190501.85381901'),
	'identifierSchemeCodeUnspsc190501.85381902': __('identifierSchemeCodeUnspsc190501.85381902'),
	'identifierSchemeCodeUnspsc190501.85381903': __('identifierSchemeCodeUnspsc190501.85381903'),
	'identifierSchemeCodeUnspsc190501.85381904': __('identifierSchemeCodeUnspsc190501.85381904'),
	'identifierSchemeCodeUnspsc190501.85381905': __('identifierSchemeCodeUnspsc190501.85381905'),
	'identifierSchemeCodeUnspsc190501.85381906': __('identifierSchemeCodeUnspsc190501.85381906'),
	'identifierSchemeCodeUnspsc190501.85381907': __('identifierSchemeCodeUnspsc190501.85381907'),
	'identifierSchemeCodeUnspsc190501.85382000': __('identifierSchemeCodeUnspsc190501.85382000'),
	'identifierSchemeCodeUnspsc190501.85382001': __('identifierSchemeCodeUnspsc190501.85382001'),
	'identifierSchemeCodeUnspsc190501.85382002': __('identifierSchemeCodeUnspsc190501.85382002'),
	'identifierSchemeCodeUnspsc190501.85382003': __('identifierSchemeCodeUnspsc190501.85382003'),
	'identifierSchemeCodeUnspsc190501.85382100': __('identifierSchemeCodeUnspsc190501.85382100'),
	'identifierSchemeCodeUnspsc190501.85382101': __('identifierSchemeCodeUnspsc190501.85382101'),
	'identifierSchemeCodeUnspsc190501.85382102': __('identifierSchemeCodeUnspsc190501.85382102'),
	'identifierSchemeCodeUnspsc190501.85382200': __('identifierSchemeCodeUnspsc190501.85382200'),
	'identifierSchemeCodeUnspsc190501.85382201': __('identifierSchemeCodeUnspsc190501.85382201'),
	'identifierSchemeCodeUnspsc190501.85382202': __('identifierSchemeCodeUnspsc190501.85382202'),
	'identifierSchemeCodeUnspsc190501.85382300': __('identifierSchemeCodeUnspsc190501.85382300'),
	'identifierSchemeCodeUnspsc190501.85382301': __('identifierSchemeCodeUnspsc190501.85382301'),
	'identifierSchemeCodeUnspsc190501.85382302': __('identifierSchemeCodeUnspsc190501.85382302'),
	'identifierSchemeCodeUnspsc190501.85382303': __('identifierSchemeCodeUnspsc190501.85382303'),
	'identifierSchemeCodeUnspsc190501.85382304': __('identifierSchemeCodeUnspsc190501.85382304'),
	'identifierSchemeCodeUnspsc190501.85382305': __('identifierSchemeCodeUnspsc190501.85382305'),
	'identifierSchemeCodeUnspsc190501.85382400': __('identifierSchemeCodeUnspsc190501.85382400'),
	'identifierSchemeCodeUnspsc190501.85382401': __('identifierSchemeCodeUnspsc190501.85382401'),
	'identifierSchemeCodeUnspsc190501.85382402': __('identifierSchemeCodeUnspsc190501.85382402'),
	'identifierSchemeCodeUnspsc190501.85382403': __('identifierSchemeCodeUnspsc190501.85382403'),
	'identifierSchemeCodeUnspsc190501.85382500': __('identifierSchemeCodeUnspsc190501.85382500'),
	'identifierSchemeCodeUnspsc190501.85382501': __('identifierSchemeCodeUnspsc190501.85382501'),
	'identifierSchemeCodeUnspsc190501.85382502': __('identifierSchemeCodeUnspsc190501.85382502'),
	'identifierSchemeCodeUnspsc190501.85382503': __('identifierSchemeCodeUnspsc190501.85382503'),
	'identifierSchemeCodeUnspsc190501.85382504': __('identifierSchemeCodeUnspsc190501.85382504'),
	'identifierSchemeCodeUnspsc190501.85382505': __('identifierSchemeCodeUnspsc190501.85382505'),
	'identifierSchemeCodeUnspsc190501.85382600': __('identifierSchemeCodeUnspsc190501.85382600'),
	'identifierSchemeCodeUnspsc190501.85382601': __('identifierSchemeCodeUnspsc190501.85382601'),
	'identifierSchemeCodeUnspsc190501.85382602': __('identifierSchemeCodeUnspsc190501.85382602'),
	'identifierSchemeCodeUnspsc190501.85382603': __('identifierSchemeCodeUnspsc190501.85382603'),
	'identifierSchemeCodeUnspsc190501.85382700': __('identifierSchemeCodeUnspsc190501.85382700'),
	'identifierSchemeCodeUnspsc190501.85382701': __('identifierSchemeCodeUnspsc190501.85382701'),
	'identifierSchemeCodeUnspsc190501.85382702': __('identifierSchemeCodeUnspsc190501.85382702'),
	'identifierSchemeCodeUnspsc190501.85382703': __('identifierSchemeCodeUnspsc190501.85382703'),
	'identifierSchemeCodeUnspsc190501.85382800': __('identifierSchemeCodeUnspsc190501.85382800'),
	'identifierSchemeCodeUnspsc190501.85382801': __('identifierSchemeCodeUnspsc190501.85382801'),
	'identifierSchemeCodeUnspsc190501.85382802': __('identifierSchemeCodeUnspsc190501.85382802'),
	'identifierSchemeCodeUnspsc190501.85382803': __('identifierSchemeCodeUnspsc190501.85382803'),
	'identifierSchemeCodeUnspsc190501.85382804': __('identifierSchemeCodeUnspsc190501.85382804'),
	'identifierSchemeCodeUnspsc190501.85382805': __('identifierSchemeCodeUnspsc190501.85382805'),
	'identifierSchemeCodeUnspsc190501.85382806': __('identifierSchemeCodeUnspsc190501.85382806'),
	'identifierSchemeCodeUnspsc190501.85382807': __('identifierSchemeCodeUnspsc190501.85382807'),
	'identifierSchemeCodeUnspsc190501.85382900': __('identifierSchemeCodeUnspsc190501.85382900'),
	'identifierSchemeCodeUnspsc190501.85382901': __('identifierSchemeCodeUnspsc190501.85382901'),
	'identifierSchemeCodeUnspsc190501.85382902': __('identifierSchemeCodeUnspsc190501.85382902'),
	'identifierSchemeCodeUnspsc190501.85383000': __('identifierSchemeCodeUnspsc190501.85383000'),
	'identifierSchemeCodeUnspsc190501.85383001': __('identifierSchemeCodeUnspsc190501.85383001'),
	'identifierSchemeCodeUnspsc190501.85383002': __('identifierSchemeCodeUnspsc190501.85383002'),
	'identifierSchemeCodeUnspsc190501.85383100': __('identifierSchemeCodeUnspsc190501.85383100'),
	'identifierSchemeCodeUnspsc190501.85383101': __('identifierSchemeCodeUnspsc190501.85383101'),
	'identifierSchemeCodeUnspsc190501.85383102': __('identifierSchemeCodeUnspsc190501.85383102'),
	'identifierSchemeCodeUnspsc190501.85383200': __('identifierSchemeCodeUnspsc190501.85383200'),
	'identifierSchemeCodeUnspsc190501.85383201': __('identifierSchemeCodeUnspsc190501.85383201'),
	'identifierSchemeCodeUnspsc190501.85383202': __('identifierSchemeCodeUnspsc190501.85383202'),
	'identifierSchemeCodeUnspsc190501.85383203': __('identifierSchemeCodeUnspsc190501.85383203'),
	'identifierSchemeCodeUnspsc190501.85383204': __('identifierSchemeCodeUnspsc190501.85383204'),
	'identifierSchemeCodeUnspsc190501.85383205': __('identifierSchemeCodeUnspsc190501.85383205'),
	'identifierSchemeCodeUnspsc190501.85383206': __('identifierSchemeCodeUnspsc190501.85383206'),
	'identifierSchemeCodeUnspsc190501.85383300': __('identifierSchemeCodeUnspsc190501.85383300'),
	'identifierSchemeCodeUnspsc190501.85383301': __('identifierSchemeCodeUnspsc190501.85383301'),
	'identifierSchemeCodeUnspsc190501.85383302': __('identifierSchemeCodeUnspsc190501.85383302'),
	'identifierSchemeCodeUnspsc190501.85383303': __('identifierSchemeCodeUnspsc190501.85383303'),
	'identifierSchemeCodeUnspsc190501.85383304': __('identifierSchemeCodeUnspsc190501.85383304'),
	'identifierSchemeCodeUnspsc190501.85383400': __('identifierSchemeCodeUnspsc190501.85383400'),
	'identifierSchemeCodeUnspsc190501.85383401': __('identifierSchemeCodeUnspsc190501.85383401'),
	'identifierSchemeCodeUnspsc190501.85383402': __('identifierSchemeCodeUnspsc190501.85383402'),
	'identifierSchemeCodeUnspsc190501.85383403': __('identifierSchemeCodeUnspsc190501.85383403'),
	'identifierSchemeCodeUnspsc190501.85383404': __('identifierSchemeCodeUnspsc190501.85383404'),
	'identifierSchemeCodeUnspsc190501.85383500': __('identifierSchemeCodeUnspsc190501.85383500'),
	'identifierSchemeCodeUnspsc190501.85383501': __('identifierSchemeCodeUnspsc190501.85383501'),
	'identifierSchemeCodeUnspsc190501.85383502': __('identifierSchemeCodeUnspsc190501.85383502'),
	'identifierSchemeCodeUnspsc190501.85383600': __('identifierSchemeCodeUnspsc190501.85383600'),
	'identifierSchemeCodeUnspsc190501.85383601': __('identifierSchemeCodeUnspsc190501.85383601'),
	'identifierSchemeCodeUnspsc190501.85383602': __('identifierSchemeCodeUnspsc190501.85383602'),
	'identifierSchemeCodeUnspsc190501.85383700': __('identifierSchemeCodeUnspsc190501.85383700'),
	'identifierSchemeCodeUnspsc190501.85383701': __('identifierSchemeCodeUnspsc190501.85383701'),
	'identifierSchemeCodeUnspsc190501.85383702': __('identifierSchemeCodeUnspsc190501.85383702'),
	'identifierSchemeCodeUnspsc190501.85383703': __('identifierSchemeCodeUnspsc190501.85383703'),
	'identifierSchemeCodeUnspsc190501.85383704': __('identifierSchemeCodeUnspsc190501.85383704'),
	'identifierSchemeCodeUnspsc190501.85383705': __('identifierSchemeCodeUnspsc190501.85383705'),
	'identifierSchemeCodeUnspsc190501.85383706': __('identifierSchemeCodeUnspsc190501.85383706'),
	'identifierSchemeCodeUnspsc190501.85383707': __('identifierSchemeCodeUnspsc190501.85383707'),
	'identifierSchemeCodeUnspsc190501.85383708': __('identifierSchemeCodeUnspsc190501.85383708'),
	'identifierSchemeCodeUnspsc190501.85383709': __('identifierSchemeCodeUnspsc190501.85383709'),
	'identifierSchemeCodeUnspsc190501.85383800': __('identifierSchemeCodeUnspsc190501.85383800'),
	'identifierSchemeCodeUnspsc190501.85383801': __('identifierSchemeCodeUnspsc190501.85383801'),
	'identifierSchemeCodeUnspsc190501.85383802': __('identifierSchemeCodeUnspsc190501.85383802'),
	'identifierSchemeCodeUnspsc190501.85383803': __('identifierSchemeCodeUnspsc190501.85383803'),
	'identifierSchemeCodeUnspsc190501.85383804': __('identifierSchemeCodeUnspsc190501.85383804'),
	'identifierSchemeCodeUnspsc190501.85383900': __('identifierSchemeCodeUnspsc190501.85383900'),
	'identifierSchemeCodeUnspsc190501.85383901': __('identifierSchemeCodeUnspsc190501.85383901'),
	'identifierSchemeCodeUnspsc190501.85383902': __('identifierSchemeCodeUnspsc190501.85383902'),
	'identifierSchemeCodeUnspsc190501.85383903': __('identifierSchemeCodeUnspsc190501.85383903'),
	'identifierSchemeCodeUnspsc190501.85383904': __('identifierSchemeCodeUnspsc190501.85383904'),
	'identifierSchemeCodeUnspsc190501.85383905': __('identifierSchemeCodeUnspsc190501.85383905'),
	'identifierSchemeCodeUnspsc190501.85383906': __('identifierSchemeCodeUnspsc190501.85383906'),
	'identifierSchemeCodeUnspsc190501.85384000': __('identifierSchemeCodeUnspsc190501.85384000'),
	'identifierSchemeCodeUnspsc190501.85384001': __('identifierSchemeCodeUnspsc190501.85384001'),
	'identifierSchemeCodeUnspsc190501.85384002': __('identifierSchemeCodeUnspsc190501.85384002'),
	'identifierSchemeCodeUnspsc190501.85384003': __('identifierSchemeCodeUnspsc190501.85384003'),
	'identifierSchemeCodeUnspsc190501.85384100': __('identifierSchemeCodeUnspsc190501.85384100'),
	'identifierSchemeCodeUnspsc190501.85384101': __('identifierSchemeCodeUnspsc190501.85384101'),
	'identifierSchemeCodeUnspsc190501.85384102': __('identifierSchemeCodeUnspsc190501.85384102'),
	'identifierSchemeCodeUnspsc190501.85384103': __('identifierSchemeCodeUnspsc190501.85384103'),
	'identifierSchemeCodeUnspsc190501.85384104': __('identifierSchemeCodeUnspsc190501.85384104'),
	'identifierSchemeCodeUnspsc190501.85384105': __('identifierSchemeCodeUnspsc190501.85384105'),
	'identifierSchemeCodeUnspsc190501.85384200': __('identifierSchemeCodeUnspsc190501.85384200'),
	'identifierSchemeCodeUnspsc190501.85384201': __('identifierSchemeCodeUnspsc190501.85384201'),
	'identifierSchemeCodeUnspsc190501.85384202': __('identifierSchemeCodeUnspsc190501.85384202'),
	'identifierSchemeCodeUnspsc190501.85384203': __('identifierSchemeCodeUnspsc190501.85384203'),
	'identifierSchemeCodeUnspsc190501.85384204': __('identifierSchemeCodeUnspsc190501.85384204'),
	'identifierSchemeCodeUnspsc190501.85384205': __('identifierSchemeCodeUnspsc190501.85384205'),
	'identifierSchemeCodeUnspsc190501.85384206': __('identifierSchemeCodeUnspsc190501.85384206'),
	'identifierSchemeCodeUnspsc190501.85384207': __('identifierSchemeCodeUnspsc190501.85384207'),
	'identifierSchemeCodeUnspsc190501.85384300': __('identifierSchemeCodeUnspsc190501.85384300'),
	'identifierSchemeCodeUnspsc190501.85384301': __('identifierSchemeCodeUnspsc190501.85384301'),
	'identifierSchemeCodeUnspsc190501.85384302': __('identifierSchemeCodeUnspsc190501.85384302'),
	'identifierSchemeCodeUnspsc190501.85384303': __('identifierSchemeCodeUnspsc190501.85384303'),
	'identifierSchemeCodeUnspsc190501.85384304': __('identifierSchemeCodeUnspsc190501.85384304'),
	'identifierSchemeCodeUnspsc190501.85384305': __('identifierSchemeCodeUnspsc190501.85384305'),
	'identifierSchemeCodeUnspsc190501.85384306': __('identifierSchemeCodeUnspsc190501.85384306'),
	'identifierSchemeCodeUnspsc190501.85384400': __('identifierSchemeCodeUnspsc190501.85384400'),
	'identifierSchemeCodeUnspsc190501.85384401': __('identifierSchemeCodeUnspsc190501.85384401'),
	'identifierSchemeCodeUnspsc190501.85384402': __('identifierSchemeCodeUnspsc190501.85384402'),
	'identifierSchemeCodeUnspsc190501.85384403': __('identifierSchemeCodeUnspsc190501.85384403'),
	'identifierSchemeCodeUnspsc190501.85384404': __('identifierSchemeCodeUnspsc190501.85384404'),
	'identifierSchemeCodeUnspsc190501.85384405': __('identifierSchemeCodeUnspsc190501.85384405'),
	'identifierSchemeCodeUnspsc190501.85384406': __('identifierSchemeCodeUnspsc190501.85384406'),
	'identifierSchemeCodeUnspsc190501.85384500': __('identifierSchemeCodeUnspsc190501.85384500'),
	'identifierSchemeCodeUnspsc190501.85384501': __('identifierSchemeCodeUnspsc190501.85384501'),
	'identifierSchemeCodeUnspsc190501.85384502': __('identifierSchemeCodeUnspsc190501.85384502'),
	'identifierSchemeCodeUnspsc190501.85384600': __('identifierSchemeCodeUnspsc190501.85384600'),
	'identifierSchemeCodeUnspsc190501.85384601': __('identifierSchemeCodeUnspsc190501.85384601'),
	'identifierSchemeCodeUnspsc190501.85384602': __('identifierSchemeCodeUnspsc190501.85384602'),
	'identifierSchemeCodeUnspsc190501.85384700': __('identifierSchemeCodeUnspsc190501.85384700'),
	'identifierSchemeCodeUnspsc190501.85384701': __('identifierSchemeCodeUnspsc190501.85384701'),
	'identifierSchemeCodeUnspsc190501.85384702': __('identifierSchemeCodeUnspsc190501.85384702'),
	'identifierSchemeCodeUnspsc190501.85384703': __('identifierSchemeCodeUnspsc190501.85384703'),
	'identifierSchemeCodeUnspsc190501.85384704': __('identifierSchemeCodeUnspsc190501.85384704'),
	'identifierSchemeCodeUnspsc190501.85384705': __('identifierSchemeCodeUnspsc190501.85384705'),
	'identifierSchemeCodeUnspsc190501.85384800': __('identifierSchemeCodeUnspsc190501.85384800'),
	'identifierSchemeCodeUnspsc190501.85384801': __('identifierSchemeCodeUnspsc190501.85384801'),
	'identifierSchemeCodeUnspsc190501.85384802': __('identifierSchemeCodeUnspsc190501.85384802'),
	'identifierSchemeCodeUnspsc190501.85384803': __('identifierSchemeCodeUnspsc190501.85384803'),
	'identifierSchemeCodeUnspsc190501.85384900': __('identifierSchemeCodeUnspsc190501.85384900'),
	'identifierSchemeCodeUnspsc190501.85384901': __('identifierSchemeCodeUnspsc190501.85384901'),
	'identifierSchemeCodeUnspsc190501.85384902': __('identifierSchemeCodeUnspsc190501.85384902'),
	'identifierSchemeCodeUnspsc190501.85384903': __('identifierSchemeCodeUnspsc190501.85384903'),
	'identifierSchemeCodeUnspsc190501.85384904': __('identifierSchemeCodeUnspsc190501.85384904'),
	'identifierSchemeCodeUnspsc190501.85384905': __('identifierSchemeCodeUnspsc190501.85384905'),
	'identifierSchemeCodeUnspsc190501.85385000': __('identifierSchemeCodeUnspsc190501.85385000'),
	'identifierSchemeCodeUnspsc190501.85385001': __('identifierSchemeCodeUnspsc190501.85385001'),
	'identifierSchemeCodeUnspsc190501.85385002': __('identifierSchemeCodeUnspsc190501.85385002'),
	'identifierSchemeCodeUnspsc190501.85385003': __('identifierSchemeCodeUnspsc190501.85385003'),
	'identifierSchemeCodeUnspsc190501.85385100': __('identifierSchemeCodeUnspsc190501.85385100'),
	'identifierSchemeCodeUnspsc190501.85385101': __('identifierSchemeCodeUnspsc190501.85385101'),
	'identifierSchemeCodeUnspsc190501.85385102': __('identifierSchemeCodeUnspsc190501.85385102'),
	'identifierSchemeCodeUnspsc190501.85385103': __('identifierSchemeCodeUnspsc190501.85385103'),
	'identifierSchemeCodeUnspsc190501.85385104': __('identifierSchemeCodeUnspsc190501.85385104'),
	'identifierSchemeCodeUnspsc190501.85385200': __('identifierSchemeCodeUnspsc190501.85385200'),
	'identifierSchemeCodeUnspsc190501.85385201': __('identifierSchemeCodeUnspsc190501.85385201'),
	'identifierSchemeCodeUnspsc190501.85385202': __('identifierSchemeCodeUnspsc190501.85385202'),
	'identifierSchemeCodeUnspsc190501.85385300': __('identifierSchemeCodeUnspsc190501.85385300'),
	'identifierSchemeCodeUnspsc190501.85385301': __('identifierSchemeCodeUnspsc190501.85385301'),
	'identifierSchemeCodeUnspsc190501.85385302': __('identifierSchemeCodeUnspsc190501.85385302'),
	'identifierSchemeCodeUnspsc190501.85385303': __('identifierSchemeCodeUnspsc190501.85385303'),
	'identifierSchemeCodeUnspsc190501.85385304': __('identifierSchemeCodeUnspsc190501.85385304'),
	'identifierSchemeCodeUnspsc190501.85385305': __('identifierSchemeCodeUnspsc190501.85385305'),
	'identifierSchemeCodeUnspsc190501.85385400': __('identifierSchemeCodeUnspsc190501.85385400'),
	'identifierSchemeCodeUnspsc190501.85385401': __('identifierSchemeCodeUnspsc190501.85385401'),
	'identifierSchemeCodeUnspsc190501.85385402': __('identifierSchemeCodeUnspsc190501.85385402'),
	'identifierSchemeCodeUnspsc190501.85385403': __('identifierSchemeCodeUnspsc190501.85385403'),
	'identifierSchemeCodeUnspsc190501.85385500': __('identifierSchemeCodeUnspsc190501.85385500'),
	'identifierSchemeCodeUnspsc190501.85385501': __('identifierSchemeCodeUnspsc190501.85385501'),
	'identifierSchemeCodeUnspsc190501.85385502': __('identifierSchemeCodeUnspsc190501.85385502'),
	'identifierSchemeCodeUnspsc190501.85385503': __('identifierSchemeCodeUnspsc190501.85385503'),
	'identifierSchemeCodeUnspsc190501.85385600': __('identifierSchemeCodeUnspsc190501.85385600'),
	'identifierSchemeCodeUnspsc190501.85385601': __('identifierSchemeCodeUnspsc190501.85385601'),
	'identifierSchemeCodeUnspsc190501.85385602': __('identifierSchemeCodeUnspsc190501.85385602'),
	'identifierSchemeCodeUnspsc190501.85385700': __('identifierSchemeCodeUnspsc190501.85385700'),
	'identifierSchemeCodeUnspsc190501.85385701': __('identifierSchemeCodeUnspsc190501.85385701'),
	'identifierSchemeCodeUnspsc190501.85385702': __('identifierSchemeCodeUnspsc190501.85385702'),
	'identifierSchemeCodeUnspsc190501.85385703': __('identifierSchemeCodeUnspsc190501.85385703'),
	'identifierSchemeCodeUnspsc190501.85385704': __('identifierSchemeCodeUnspsc190501.85385704'),
	'identifierSchemeCodeUnspsc190501.85385705': __('identifierSchemeCodeUnspsc190501.85385705'),
	'identifierSchemeCodeUnspsc190501.85385706': __('identifierSchemeCodeUnspsc190501.85385706'),
	'identifierSchemeCodeUnspsc190501.85385800': __('identifierSchemeCodeUnspsc190501.85385800'),
	'identifierSchemeCodeUnspsc190501.85385801': __('identifierSchemeCodeUnspsc190501.85385801'),
	'identifierSchemeCodeUnspsc190501.85385802': __('identifierSchemeCodeUnspsc190501.85385802'),
	'identifierSchemeCodeUnspsc190501.85385803': __('identifierSchemeCodeUnspsc190501.85385803'),
	'identifierSchemeCodeUnspsc190501.85385804': __('identifierSchemeCodeUnspsc190501.85385804'),
	'identifierSchemeCodeUnspsc190501.85385805': __('identifierSchemeCodeUnspsc190501.85385805'),
	'identifierSchemeCodeUnspsc190501.85385806': __('identifierSchemeCodeUnspsc190501.85385806'),
	'identifierSchemeCodeUnspsc190501.85385807': __('identifierSchemeCodeUnspsc190501.85385807'),
	'identifierSchemeCodeUnspsc190501.85385900': __('identifierSchemeCodeUnspsc190501.85385900'),
	'identifierSchemeCodeUnspsc190501.85385901': __('identifierSchemeCodeUnspsc190501.85385901'),
	'identifierSchemeCodeUnspsc190501.85385902': __('identifierSchemeCodeUnspsc190501.85385902'),
	'identifierSchemeCodeUnspsc190501.85385903': __('identifierSchemeCodeUnspsc190501.85385903'),
	'identifierSchemeCodeUnspsc190501.85385904': __('identifierSchemeCodeUnspsc190501.85385904'),
	'identifierSchemeCodeUnspsc190501.85385905': __('identifierSchemeCodeUnspsc190501.85385905'),
	'identifierSchemeCodeUnspsc190501.85385906': __('identifierSchemeCodeUnspsc190501.85385906'),
	'identifierSchemeCodeUnspsc190501.85385907': __('identifierSchemeCodeUnspsc190501.85385907'),
	'identifierSchemeCodeUnspsc190501.85386000': __('identifierSchemeCodeUnspsc190501.85386000'),
	'identifierSchemeCodeUnspsc190501.85386001': __('identifierSchemeCodeUnspsc190501.85386001'),
	'identifierSchemeCodeUnspsc190501.85386002': __('identifierSchemeCodeUnspsc190501.85386002'),
	'identifierSchemeCodeUnspsc190501.85386003': __('identifierSchemeCodeUnspsc190501.85386003'),
	'identifierSchemeCodeUnspsc190501.85386100': __('identifierSchemeCodeUnspsc190501.85386100'),
	'identifierSchemeCodeUnspsc190501.85386101': __('identifierSchemeCodeUnspsc190501.85386101'),
	'identifierSchemeCodeUnspsc190501.85386102': __('identifierSchemeCodeUnspsc190501.85386102'),
	'identifierSchemeCodeUnspsc190501.85386103': __('identifierSchemeCodeUnspsc190501.85386103'),
	'identifierSchemeCodeUnspsc190501.85386104': __('identifierSchemeCodeUnspsc190501.85386104'),
	'identifierSchemeCodeUnspsc190501.85386105': __('identifierSchemeCodeUnspsc190501.85386105'),
	'identifierSchemeCodeUnspsc190501.85386106': __('identifierSchemeCodeUnspsc190501.85386106'),
	'identifierSchemeCodeUnspsc190501.85390000': __('identifierSchemeCodeUnspsc190501.85390000'),
	'identifierSchemeCodeUnspsc190501.85391500': __('identifierSchemeCodeUnspsc190501.85391500'),
	'identifierSchemeCodeUnspsc190501.85391501': __('identifierSchemeCodeUnspsc190501.85391501'),
	'identifierSchemeCodeUnspsc190501.85391502': __('identifierSchemeCodeUnspsc190501.85391502'),
	'identifierSchemeCodeUnspsc190501.85391503': __('identifierSchemeCodeUnspsc190501.85391503'),
	'identifierSchemeCodeUnspsc190501.85391504': __('identifierSchemeCodeUnspsc190501.85391504'),
	'identifierSchemeCodeUnspsc190501.85391505': __('identifierSchemeCodeUnspsc190501.85391505'),
	'identifierSchemeCodeUnspsc190501.85391506': __('identifierSchemeCodeUnspsc190501.85391506'),
	'identifierSchemeCodeUnspsc190501.85391600': __('identifierSchemeCodeUnspsc190501.85391600'),
	'identifierSchemeCodeUnspsc190501.85391601': __('identifierSchemeCodeUnspsc190501.85391601'),
	'identifierSchemeCodeUnspsc190501.85391602': __('identifierSchemeCodeUnspsc190501.85391602'),
	'identifierSchemeCodeUnspsc190501.85391603': __('identifierSchemeCodeUnspsc190501.85391603'),
	'identifierSchemeCodeUnspsc190501.85391700': __('identifierSchemeCodeUnspsc190501.85391700'),
	'identifierSchemeCodeUnspsc190501.85391701': __('identifierSchemeCodeUnspsc190501.85391701'),
	'identifierSchemeCodeUnspsc190501.85391702': __('identifierSchemeCodeUnspsc190501.85391702'),
	'identifierSchemeCodeUnspsc190501.85391703': __('identifierSchemeCodeUnspsc190501.85391703'),
	'identifierSchemeCodeUnspsc190501.85391704': __('identifierSchemeCodeUnspsc190501.85391704'),
	'identifierSchemeCodeUnspsc190501.85391800': __('identifierSchemeCodeUnspsc190501.85391800'),
	'identifierSchemeCodeUnspsc190501.85391801': __('identifierSchemeCodeUnspsc190501.85391801'),
	'identifierSchemeCodeUnspsc190501.85391802': __('identifierSchemeCodeUnspsc190501.85391802'),
	'identifierSchemeCodeUnspsc190501.85391803': __('identifierSchemeCodeUnspsc190501.85391803'),
	'identifierSchemeCodeUnspsc190501.85391900': __('identifierSchemeCodeUnspsc190501.85391900'),
	'identifierSchemeCodeUnspsc190501.85391901': __('identifierSchemeCodeUnspsc190501.85391901'),
	'identifierSchemeCodeUnspsc190501.85391902': __('identifierSchemeCodeUnspsc190501.85391902'),
	'identifierSchemeCodeUnspsc190501.85391903': __('identifierSchemeCodeUnspsc190501.85391903'),
	'identifierSchemeCodeUnspsc190501.85392000': __('identifierSchemeCodeUnspsc190501.85392000'),
	'identifierSchemeCodeUnspsc190501.85392001': __('identifierSchemeCodeUnspsc190501.85392001'),
	'identifierSchemeCodeUnspsc190501.85392002': __('identifierSchemeCodeUnspsc190501.85392002'),
	'identifierSchemeCodeUnspsc190501.85392003': __('identifierSchemeCodeUnspsc190501.85392003'),
	'identifierSchemeCodeUnspsc190501.85392004': __('identifierSchemeCodeUnspsc190501.85392004'),
	'identifierSchemeCodeUnspsc190501.85392005': __('identifierSchemeCodeUnspsc190501.85392005'),
	'identifierSchemeCodeUnspsc190501.85392006': __('identifierSchemeCodeUnspsc190501.85392006'),
	'identifierSchemeCodeUnspsc190501.85392100': __('identifierSchemeCodeUnspsc190501.85392100'),
	'identifierSchemeCodeUnspsc190501.85392101': __('identifierSchemeCodeUnspsc190501.85392101'),
	'identifierSchemeCodeUnspsc190501.85392102': __('identifierSchemeCodeUnspsc190501.85392102'),
	'identifierSchemeCodeUnspsc190501.85392103': __('identifierSchemeCodeUnspsc190501.85392103'),
	'identifierSchemeCodeUnspsc190501.85392104': __('identifierSchemeCodeUnspsc190501.85392104'),
	'identifierSchemeCodeUnspsc190501.85392200': __('identifierSchemeCodeUnspsc190501.85392200'),
	'identifierSchemeCodeUnspsc190501.85392201': __('identifierSchemeCodeUnspsc190501.85392201'),
	'identifierSchemeCodeUnspsc190501.85392202': __('identifierSchemeCodeUnspsc190501.85392202'),
	'identifierSchemeCodeUnspsc190501.85392203': __('identifierSchemeCodeUnspsc190501.85392203'),
	'identifierSchemeCodeUnspsc190501.85392204': __('identifierSchemeCodeUnspsc190501.85392204'),
	'identifierSchemeCodeUnspsc190501.85392300': __('identifierSchemeCodeUnspsc190501.85392300'),
	'identifierSchemeCodeUnspsc190501.85392301': __('identifierSchemeCodeUnspsc190501.85392301'),
	'identifierSchemeCodeUnspsc190501.85392302': __('identifierSchemeCodeUnspsc190501.85392302'),
	'identifierSchemeCodeUnspsc190501.85392303': __('identifierSchemeCodeUnspsc190501.85392303'),
	'identifierSchemeCodeUnspsc190501.85392304': __('identifierSchemeCodeUnspsc190501.85392304'),
	'identifierSchemeCodeUnspsc190501.85392305': __('identifierSchemeCodeUnspsc190501.85392305'),
	'identifierSchemeCodeUnspsc190501.85392306': __('identifierSchemeCodeUnspsc190501.85392306'),
	'identifierSchemeCodeUnspsc190501.85392307': __('identifierSchemeCodeUnspsc190501.85392307'),
	'identifierSchemeCodeUnspsc190501.85392308': __('identifierSchemeCodeUnspsc190501.85392308'),
	'identifierSchemeCodeUnspsc190501.85392309': __('identifierSchemeCodeUnspsc190501.85392309'),
	'identifierSchemeCodeUnspsc190501.85392310': __('identifierSchemeCodeUnspsc190501.85392310'),
	'identifierSchemeCodeUnspsc190501.85392311': __('identifierSchemeCodeUnspsc190501.85392311'),
	'identifierSchemeCodeUnspsc190501.85392312': __('identifierSchemeCodeUnspsc190501.85392312'),
	'identifierSchemeCodeUnspsc190501.85392313': __('identifierSchemeCodeUnspsc190501.85392313'),
	'identifierSchemeCodeUnspsc190501.85392314': __('identifierSchemeCodeUnspsc190501.85392314'),
	'identifierSchemeCodeUnspsc190501.85392315': __('identifierSchemeCodeUnspsc190501.85392315'),
	'identifierSchemeCodeUnspsc190501.85392316': __('identifierSchemeCodeUnspsc190501.85392316'),
	'identifierSchemeCodeUnspsc190501.85392317': __('identifierSchemeCodeUnspsc190501.85392317'),
	'identifierSchemeCodeUnspsc190501.85392318': __('identifierSchemeCodeUnspsc190501.85392318'),
	'identifierSchemeCodeUnspsc190501.85392319': __('identifierSchemeCodeUnspsc190501.85392319'),
	'identifierSchemeCodeUnspsc190501.85392320': __('identifierSchemeCodeUnspsc190501.85392320'),
	'identifierSchemeCodeUnspsc190501.85392400': __('identifierSchemeCodeUnspsc190501.85392400'),
	'identifierSchemeCodeUnspsc190501.85392401': __('identifierSchemeCodeUnspsc190501.85392401'),
	'identifierSchemeCodeUnspsc190501.85392402': __('identifierSchemeCodeUnspsc190501.85392402'),
	'identifierSchemeCodeUnspsc190501.85392403': __('identifierSchemeCodeUnspsc190501.85392403'),
	'identifierSchemeCodeUnspsc190501.85392500': __('identifierSchemeCodeUnspsc190501.85392500'),
	'identifierSchemeCodeUnspsc190501.85392501': __('identifierSchemeCodeUnspsc190501.85392501'),
	'identifierSchemeCodeUnspsc190501.85392502': __('identifierSchemeCodeUnspsc190501.85392502'),
	'identifierSchemeCodeUnspsc190501.85392600': __('identifierSchemeCodeUnspsc190501.85392600'),
	'identifierSchemeCodeUnspsc190501.85392601': __('identifierSchemeCodeUnspsc190501.85392601'),
	'identifierSchemeCodeUnspsc190501.85392602': __('identifierSchemeCodeUnspsc190501.85392602'),
	'identifierSchemeCodeUnspsc190501.85392700': __('identifierSchemeCodeUnspsc190501.85392700'),
	'identifierSchemeCodeUnspsc190501.85392701': __('identifierSchemeCodeUnspsc190501.85392701'),
	'identifierSchemeCodeUnspsc190501.85392702': __('identifierSchemeCodeUnspsc190501.85392702'),
	'identifierSchemeCodeUnspsc190501.85392703': __('identifierSchemeCodeUnspsc190501.85392703'),
	'identifierSchemeCodeUnspsc190501.85392704': __('identifierSchemeCodeUnspsc190501.85392704'),
	'identifierSchemeCodeUnspsc190501.85392705': __('identifierSchemeCodeUnspsc190501.85392705'),
	'identifierSchemeCodeUnspsc190501.85392800': __('identifierSchemeCodeUnspsc190501.85392800'),
	'identifierSchemeCodeUnspsc190501.85392801': __('identifierSchemeCodeUnspsc190501.85392801'),
	'identifierSchemeCodeUnspsc190501.85392802': __('identifierSchemeCodeUnspsc190501.85392802'),
	'identifierSchemeCodeUnspsc190501.85392803': __('identifierSchemeCodeUnspsc190501.85392803'),
	'identifierSchemeCodeUnspsc190501.85392804': __('identifierSchemeCodeUnspsc190501.85392804'),
	'identifierSchemeCodeUnspsc190501.85392900': __('identifierSchemeCodeUnspsc190501.85392900'),
	'identifierSchemeCodeUnspsc190501.85392901': __('identifierSchemeCodeUnspsc190501.85392901'),
	'identifierSchemeCodeUnspsc190501.85392902': __('identifierSchemeCodeUnspsc190501.85392902'),
	'identifierSchemeCodeUnspsc190501.85392903': __('identifierSchemeCodeUnspsc190501.85392903'),
	'identifierSchemeCodeUnspsc190501.85392904': __('identifierSchemeCodeUnspsc190501.85392904'),
	'identifierSchemeCodeUnspsc190501.85393000': __('identifierSchemeCodeUnspsc190501.85393000'),
	'identifierSchemeCodeUnspsc190501.85393001': __('identifierSchemeCodeUnspsc190501.85393001'),
	'identifierSchemeCodeUnspsc190501.85393002': __('identifierSchemeCodeUnspsc190501.85393002'),
	'identifierSchemeCodeUnspsc190501.85393003': __('identifierSchemeCodeUnspsc190501.85393003'),
	'identifierSchemeCodeUnspsc190501.85393004': __('identifierSchemeCodeUnspsc190501.85393004'),
	'identifierSchemeCodeUnspsc190501.85393005': __('identifierSchemeCodeUnspsc190501.85393005'),
	'identifierSchemeCodeUnspsc190501.85393006': __('identifierSchemeCodeUnspsc190501.85393006'),
	'identifierSchemeCodeUnspsc190501.85393007': __('identifierSchemeCodeUnspsc190501.85393007'),
	'identifierSchemeCodeUnspsc190501.85400000': __('identifierSchemeCodeUnspsc190501.85400000'),
	'identifierSchemeCodeUnspsc190501.85401500': __('identifierSchemeCodeUnspsc190501.85401500'),
	'identifierSchemeCodeUnspsc190501.85401501': __('identifierSchemeCodeUnspsc190501.85401501'),
	'identifierSchemeCodeUnspsc190501.85401502': __('identifierSchemeCodeUnspsc190501.85401502'),
	'identifierSchemeCodeUnspsc190501.85401503': __('identifierSchemeCodeUnspsc190501.85401503'),
	'identifierSchemeCodeUnspsc190501.85401504': __('identifierSchemeCodeUnspsc190501.85401504'),
	'identifierSchemeCodeUnspsc190501.85401700': __('identifierSchemeCodeUnspsc190501.85401700'),
	'identifierSchemeCodeUnspsc190501.85401701': __('identifierSchemeCodeUnspsc190501.85401701'),
	'identifierSchemeCodeUnspsc190501.85401702': __('identifierSchemeCodeUnspsc190501.85401702'),
	'identifierSchemeCodeUnspsc190501.85401800': __('identifierSchemeCodeUnspsc190501.85401800'),
	'identifierSchemeCodeUnspsc190501.85401801': __('identifierSchemeCodeUnspsc190501.85401801'),
	'identifierSchemeCodeUnspsc190501.85401802': __('identifierSchemeCodeUnspsc190501.85401802'),
	'identifierSchemeCodeUnspsc190501.85401803': __('identifierSchemeCodeUnspsc190501.85401803'),
	'identifierSchemeCodeUnspsc190501.85401804': __('identifierSchemeCodeUnspsc190501.85401804'),
	'identifierSchemeCodeUnspsc190501.85401805': __('identifierSchemeCodeUnspsc190501.85401805'),
	'identifierSchemeCodeUnspsc190501.85401806': __('identifierSchemeCodeUnspsc190501.85401806'),
	'identifierSchemeCodeUnspsc190501.85401900': __('identifierSchemeCodeUnspsc190501.85401900'),
	'identifierSchemeCodeUnspsc190501.85401901': __('identifierSchemeCodeUnspsc190501.85401901'),
	'identifierSchemeCodeUnspsc190501.85401902': __('identifierSchemeCodeUnspsc190501.85401902'),
	'identifierSchemeCodeUnspsc190501.85401903': __('identifierSchemeCodeUnspsc190501.85401903'),
	'identifierSchemeCodeUnspsc190501.85401904': __('identifierSchemeCodeUnspsc190501.85401904'),
	'identifierSchemeCodeUnspsc190501.85401905': __('identifierSchemeCodeUnspsc190501.85401905'),
	'identifierSchemeCodeUnspsc190501.85401906': __('identifierSchemeCodeUnspsc190501.85401906'),
	'identifierSchemeCodeUnspsc190501.85401907': __('identifierSchemeCodeUnspsc190501.85401907'),
	'identifierSchemeCodeUnspsc190501.85401908': __('identifierSchemeCodeUnspsc190501.85401908'),
	'identifierSchemeCodeUnspsc190501.85402000': __('identifierSchemeCodeUnspsc190501.85402000'),
	'identifierSchemeCodeUnspsc190501.85402001': __('identifierSchemeCodeUnspsc190501.85402001'),
	'identifierSchemeCodeUnspsc190501.85402002': __('identifierSchemeCodeUnspsc190501.85402002'),
	'identifierSchemeCodeUnspsc190501.85402003': __('identifierSchemeCodeUnspsc190501.85402003'),
	'identifierSchemeCodeUnspsc190501.85402004': __('identifierSchemeCodeUnspsc190501.85402004'),
	'identifierSchemeCodeUnspsc190501.85402005': __('identifierSchemeCodeUnspsc190501.85402005'),
	'identifierSchemeCodeUnspsc190501.85402100': __('identifierSchemeCodeUnspsc190501.85402100'),
	'identifierSchemeCodeUnspsc190501.85402101': __('identifierSchemeCodeUnspsc190501.85402101'),
	'identifierSchemeCodeUnspsc190501.85402102': __('identifierSchemeCodeUnspsc190501.85402102'),
	'identifierSchemeCodeUnspsc190501.85402103': __('identifierSchemeCodeUnspsc190501.85402103'),
	'identifierSchemeCodeUnspsc190501.85402104': __('identifierSchemeCodeUnspsc190501.85402104'),
	'identifierSchemeCodeUnspsc190501.85402105': __('identifierSchemeCodeUnspsc190501.85402105'),
	'identifierSchemeCodeUnspsc190501.85402106': __('identifierSchemeCodeUnspsc190501.85402106'),
	'identifierSchemeCodeUnspsc190501.85402200': __('identifierSchemeCodeUnspsc190501.85402200'),
	'identifierSchemeCodeUnspsc190501.85402201': __('identifierSchemeCodeUnspsc190501.85402201'),
	'identifierSchemeCodeUnspsc190501.85402202': __('identifierSchemeCodeUnspsc190501.85402202'),
	'identifierSchemeCodeUnspsc190501.85402203': __('identifierSchemeCodeUnspsc190501.85402203'),
	'identifierSchemeCodeUnspsc190501.85402300': __('identifierSchemeCodeUnspsc190501.85402300'),
	'identifierSchemeCodeUnspsc190501.85402301': __('identifierSchemeCodeUnspsc190501.85402301'),
	'identifierSchemeCodeUnspsc190501.85402302': __('identifierSchemeCodeUnspsc190501.85402302'),
	'identifierSchemeCodeUnspsc190501.85402303': __('identifierSchemeCodeUnspsc190501.85402303'),
	'identifierSchemeCodeUnspsc190501.85402304': __('identifierSchemeCodeUnspsc190501.85402304'),
	'identifierSchemeCodeUnspsc190501.85402400': __('identifierSchemeCodeUnspsc190501.85402400'),
	'identifierSchemeCodeUnspsc190501.85402401': __('identifierSchemeCodeUnspsc190501.85402401'),
	'identifierSchemeCodeUnspsc190501.85402402': __('identifierSchemeCodeUnspsc190501.85402402'),
	'identifierSchemeCodeUnspsc190501.85402403': __('identifierSchemeCodeUnspsc190501.85402403'),
	'identifierSchemeCodeUnspsc190501.85402404': __('identifierSchemeCodeUnspsc190501.85402404'),
	'identifierSchemeCodeUnspsc190501.85402500': __('identifierSchemeCodeUnspsc190501.85402500'),
	'identifierSchemeCodeUnspsc190501.85402501': __('identifierSchemeCodeUnspsc190501.85402501'),
	'identifierSchemeCodeUnspsc190501.85402502': __('identifierSchemeCodeUnspsc190501.85402502'),
	'identifierSchemeCodeUnspsc190501.85402503': __('identifierSchemeCodeUnspsc190501.85402503'),
	'identifierSchemeCodeUnspsc190501.85402504': __('identifierSchemeCodeUnspsc190501.85402504'),
	'identifierSchemeCodeUnspsc190501.85402505': __('identifierSchemeCodeUnspsc190501.85402505'),
	'identifierSchemeCodeUnspsc190501.85402506': __('identifierSchemeCodeUnspsc190501.85402506'),
	'identifierSchemeCodeUnspsc190501.85402600': __('identifierSchemeCodeUnspsc190501.85402600'),
	'identifierSchemeCodeUnspsc190501.85402601': __('identifierSchemeCodeUnspsc190501.85402601'),
	'identifierSchemeCodeUnspsc190501.85402602': __('identifierSchemeCodeUnspsc190501.85402602'),
	'identifierSchemeCodeUnspsc190501.85402603': __('identifierSchemeCodeUnspsc190501.85402603'),
	'identifierSchemeCodeUnspsc190501.85402604': __('identifierSchemeCodeUnspsc190501.85402604'),
	'identifierSchemeCodeUnspsc190501.85402605': __('identifierSchemeCodeUnspsc190501.85402605'),
	'identifierSchemeCodeUnspsc190501.85402700': __('identifierSchemeCodeUnspsc190501.85402700'),
	'identifierSchemeCodeUnspsc190501.85402701': __('identifierSchemeCodeUnspsc190501.85402701'),
	'identifierSchemeCodeUnspsc190501.85402702': __('identifierSchemeCodeUnspsc190501.85402702'),
	'identifierSchemeCodeUnspsc190501.85402703': __('identifierSchemeCodeUnspsc190501.85402703'),
	'identifierSchemeCodeUnspsc190501.85402704': __('identifierSchemeCodeUnspsc190501.85402704'),
	'identifierSchemeCodeUnspsc190501.85402705': __('identifierSchemeCodeUnspsc190501.85402705'),
	'identifierSchemeCodeUnspsc190501.85402706': __('identifierSchemeCodeUnspsc190501.85402706'),
	'identifierSchemeCodeUnspsc190501.85402707': __('identifierSchemeCodeUnspsc190501.85402707'),
	'identifierSchemeCodeUnspsc190501.85402800': __('identifierSchemeCodeUnspsc190501.85402800'),
	'identifierSchemeCodeUnspsc190501.85402801': __('identifierSchemeCodeUnspsc190501.85402801'),
	'identifierSchemeCodeUnspsc190501.85402802': __('identifierSchemeCodeUnspsc190501.85402802'),
	'identifierSchemeCodeUnspsc190501.85402803': __('identifierSchemeCodeUnspsc190501.85402803'),
	'identifierSchemeCodeUnspsc190501.85402804': __('identifierSchemeCodeUnspsc190501.85402804'),
	'identifierSchemeCodeUnspsc190501.85402805': __('identifierSchemeCodeUnspsc190501.85402805'),
	'identifierSchemeCodeUnspsc190501.85402806': __('identifierSchemeCodeUnspsc190501.85402806'),
	'identifierSchemeCodeUnspsc190501.85402900': __('identifierSchemeCodeUnspsc190501.85402900'),
	'identifierSchemeCodeUnspsc190501.85402901': __('identifierSchemeCodeUnspsc190501.85402901'),
	'identifierSchemeCodeUnspsc190501.85402902': __('identifierSchemeCodeUnspsc190501.85402902'),
	'identifierSchemeCodeUnspsc190501.85402903': __('identifierSchemeCodeUnspsc190501.85402903'),
	'identifierSchemeCodeUnspsc190501.85402904': __('identifierSchemeCodeUnspsc190501.85402904'),
	'identifierSchemeCodeUnspsc190501.85402905': __('identifierSchemeCodeUnspsc190501.85402905'),
	'identifierSchemeCodeUnspsc190501.85402906': __('identifierSchemeCodeUnspsc190501.85402906'),
	'identifierSchemeCodeUnspsc190501.85403000': __('identifierSchemeCodeUnspsc190501.85403000'),
	'identifierSchemeCodeUnspsc190501.85403001': __('identifierSchemeCodeUnspsc190501.85403001'),
	'identifierSchemeCodeUnspsc190501.85403002': __('identifierSchemeCodeUnspsc190501.85403002'),
	'identifierSchemeCodeUnspsc190501.85403003': __('identifierSchemeCodeUnspsc190501.85403003'),
	'identifierSchemeCodeUnspsc190501.85403004': __('identifierSchemeCodeUnspsc190501.85403004'),
	'identifierSchemeCodeUnspsc190501.85403100': __('identifierSchemeCodeUnspsc190501.85403100'),
	'identifierSchemeCodeUnspsc190501.85403101': __('identifierSchemeCodeUnspsc190501.85403101'),
	'identifierSchemeCodeUnspsc190501.85403102': __('identifierSchemeCodeUnspsc190501.85403102'),
	'identifierSchemeCodeUnspsc190501.85403200': __('identifierSchemeCodeUnspsc190501.85403200'),
	'identifierSchemeCodeUnspsc190501.85403201': __('identifierSchemeCodeUnspsc190501.85403201'),
	'identifierSchemeCodeUnspsc190501.85403202': __('identifierSchemeCodeUnspsc190501.85403202'),
	'identifierSchemeCodeUnspsc190501.85403203': __('identifierSchemeCodeUnspsc190501.85403203'),
	'identifierSchemeCodeUnspsc190501.85403204': __('identifierSchemeCodeUnspsc190501.85403204'),
	'identifierSchemeCodeUnspsc190501.85403300': __('identifierSchemeCodeUnspsc190501.85403300'),
	'identifierSchemeCodeUnspsc190501.85403301': __('identifierSchemeCodeUnspsc190501.85403301'),
	'identifierSchemeCodeUnspsc190501.85403302': __('identifierSchemeCodeUnspsc190501.85403302'),
	'identifierSchemeCodeUnspsc190501.85403303': __('identifierSchemeCodeUnspsc190501.85403303'),
	'identifierSchemeCodeUnspsc190501.85403304': __('identifierSchemeCodeUnspsc190501.85403304'),
	'identifierSchemeCodeUnspsc190501.85403305': __('identifierSchemeCodeUnspsc190501.85403305'),
	'identifierSchemeCodeUnspsc190501.85403400': __('identifierSchemeCodeUnspsc190501.85403400'),
	'identifierSchemeCodeUnspsc190501.85403401': __('identifierSchemeCodeUnspsc190501.85403401'),
	'identifierSchemeCodeUnspsc190501.85403402': __('identifierSchemeCodeUnspsc190501.85403402'),
	'identifierSchemeCodeUnspsc190501.85403403': __('identifierSchemeCodeUnspsc190501.85403403'),
	'identifierSchemeCodeUnspsc190501.85403500': __('identifierSchemeCodeUnspsc190501.85403500'),
	'identifierSchemeCodeUnspsc190501.85403501': __('identifierSchemeCodeUnspsc190501.85403501'),
	'identifierSchemeCodeUnspsc190501.85403502': __('identifierSchemeCodeUnspsc190501.85403502'),
	'identifierSchemeCodeUnspsc190501.85403503': __('identifierSchemeCodeUnspsc190501.85403503'),
	'identifierSchemeCodeUnspsc190501.85403504': __('identifierSchemeCodeUnspsc190501.85403504'),
	'identifierSchemeCodeUnspsc190501.85403505': __('identifierSchemeCodeUnspsc190501.85403505'),
	'identifierSchemeCodeUnspsc190501.85403506': __('identifierSchemeCodeUnspsc190501.85403506'),
	'identifierSchemeCodeUnspsc190501.85403507': __('identifierSchemeCodeUnspsc190501.85403507'),
	'identifierSchemeCodeUnspsc190501.85403600': __('identifierSchemeCodeUnspsc190501.85403600'),
	'identifierSchemeCodeUnspsc190501.85403601': __('identifierSchemeCodeUnspsc190501.85403601'),
	'identifierSchemeCodeUnspsc190501.85403602': __('identifierSchemeCodeUnspsc190501.85403602'),
	'identifierSchemeCodeUnspsc190501.85403700': __('identifierSchemeCodeUnspsc190501.85403700'),
	'identifierSchemeCodeUnspsc190501.85403701': __('identifierSchemeCodeUnspsc190501.85403701'),
	'identifierSchemeCodeUnspsc190501.85403702': __('identifierSchemeCodeUnspsc190501.85403702'),
	'identifierSchemeCodeUnspsc190501.85403703': __('identifierSchemeCodeUnspsc190501.85403703'),
	'identifierSchemeCodeUnspsc190501.85403704': __('identifierSchemeCodeUnspsc190501.85403704'),
	'identifierSchemeCodeUnspsc190501.85403800': __('identifierSchemeCodeUnspsc190501.85403800'),
	'identifierSchemeCodeUnspsc190501.85403801': __('identifierSchemeCodeUnspsc190501.85403801'),
	'identifierSchemeCodeUnspsc190501.85403802': __('identifierSchemeCodeUnspsc190501.85403802'),
	'identifierSchemeCodeUnspsc190501.85403803': __('identifierSchemeCodeUnspsc190501.85403803'),
	'identifierSchemeCodeUnspsc190501.85403900': __('identifierSchemeCodeUnspsc190501.85403900'),
	'identifierSchemeCodeUnspsc190501.85403901': __('identifierSchemeCodeUnspsc190501.85403901'),
	'identifierSchemeCodeUnspsc190501.85403902': __('identifierSchemeCodeUnspsc190501.85403902'),
	'identifierSchemeCodeUnspsc190501.85403903': __('identifierSchemeCodeUnspsc190501.85403903'),
	'identifierSchemeCodeUnspsc190501.85403904': __('identifierSchemeCodeUnspsc190501.85403904'),
	'identifierSchemeCodeUnspsc190501.85403905': __('identifierSchemeCodeUnspsc190501.85403905'),
	'identifierSchemeCodeUnspsc190501.85403906': __('identifierSchemeCodeUnspsc190501.85403906'),
	'identifierSchemeCodeUnspsc190501.85404000': __('identifierSchemeCodeUnspsc190501.85404000'),
	'identifierSchemeCodeUnspsc190501.85404001': __('identifierSchemeCodeUnspsc190501.85404001'),
	'identifierSchemeCodeUnspsc190501.85404002': __('identifierSchemeCodeUnspsc190501.85404002'),
	'identifierSchemeCodeUnspsc190501.85404003': __('identifierSchemeCodeUnspsc190501.85404003'),
	'identifierSchemeCodeUnspsc190501.85404004': __('identifierSchemeCodeUnspsc190501.85404004'),
	'identifierSchemeCodeUnspsc190501.85404100': __('identifierSchemeCodeUnspsc190501.85404100'),
	'identifierSchemeCodeUnspsc190501.85404101': __('identifierSchemeCodeUnspsc190501.85404101'),
	'identifierSchemeCodeUnspsc190501.85404102': __('identifierSchemeCodeUnspsc190501.85404102'),
	'identifierSchemeCodeUnspsc190501.85404103': __('identifierSchemeCodeUnspsc190501.85404103'),
	'identifierSchemeCodeUnspsc190501.85404104': __('identifierSchemeCodeUnspsc190501.85404104'),
	'identifierSchemeCodeUnspsc190501.85404105': __('identifierSchemeCodeUnspsc190501.85404105'),
	'identifierSchemeCodeUnspsc190501.85404106': __('identifierSchemeCodeUnspsc190501.85404106'),
	'identifierSchemeCodeUnspsc190501.85404107': __('identifierSchemeCodeUnspsc190501.85404107'),
	'identifierSchemeCodeUnspsc190501.85404200': __('identifierSchemeCodeUnspsc190501.85404200'),
	'identifierSchemeCodeUnspsc190501.85404201': __('identifierSchemeCodeUnspsc190501.85404201'),
	'identifierSchemeCodeUnspsc190501.85404202': __('identifierSchemeCodeUnspsc190501.85404202'),
	'identifierSchemeCodeUnspsc190501.85404300': __('identifierSchemeCodeUnspsc190501.85404300'),
	'identifierSchemeCodeUnspsc190501.85404301': __('identifierSchemeCodeUnspsc190501.85404301'),
	'identifierSchemeCodeUnspsc190501.85404302': __('identifierSchemeCodeUnspsc190501.85404302'),
	'identifierSchemeCodeUnspsc190501.85404303': __('identifierSchemeCodeUnspsc190501.85404303'),
	'identifierSchemeCodeUnspsc190501.85404400': __('identifierSchemeCodeUnspsc190501.85404400'),
	'identifierSchemeCodeUnspsc190501.85404401': __('identifierSchemeCodeUnspsc190501.85404401'),
	'identifierSchemeCodeUnspsc190501.85404402': __('identifierSchemeCodeUnspsc190501.85404402'),
	'identifierSchemeCodeUnspsc190501.85404403': __('identifierSchemeCodeUnspsc190501.85404403'),
	'identifierSchemeCodeUnspsc190501.85404404': __('identifierSchemeCodeUnspsc190501.85404404'),
	'identifierSchemeCodeUnspsc190501.85404500': __('identifierSchemeCodeUnspsc190501.85404500'),
	'identifierSchemeCodeUnspsc190501.85404501': __('identifierSchemeCodeUnspsc190501.85404501'),
	'identifierSchemeCodeUnspsc190501.85404502': __('identifierSchemeCodeUnspsc190501.85404502'),
	'identifierSchemeCodeUnspsc190501.85404503': __('identifierSchemeCodeUnspsc190501.85404503'),
	'identifierSchemeCodeUnspsc190501.85404504': __('identifierSchemeCodeUnspsc190501.85404504'),
	'identifierSchemeCodeUnspsc190501.85404505': __('identifierSchemeCodeUnspsc190501.85404505'),
	'identifierSchemeCodeUnspsc190501.85404506': __('identifierSchemeCodeUnspsc190501.85404506'),
	'identifierSchemeCodeUnspsc190501.85404507': __('identifierSchemeCodeUnspsc190501.85404507'),
	'identifierSchemeCodeUnspsc190501.85404600': __('identifierSchemeCodeUnspsc190501.85404600'),
	'identifierSchemeCodeUnspsc190501.85404601': __('identifierSchemeCodeUnspsc190501.85404601'),
	'identifierSchemeCodeUnspsc190501.85404602': __('identifierSchemeCodeUnspsc190501.85404602'),
	'identifierSchemeCodeUnspsc190501.85404603': __('identifierSchemeCodeUnspsc190501.85404603'),
	'identifierSchemeCodeUnspsc190501.85404604': __('identifierSchemeCodeUnspsc190501.85404604'),
	'identifierSchemeCodeUnspsc190501.85404605': __('identifierSchemeCodeUnspsc190501.85404605'),
	'identifierSchemeCodeUnspsc190501.85404700': __('identifierSchemeCodeUnspsc190501.85404700'),
	'identifierSchemeCodeUnspsc190501.85404701': __('identifierSchemeCodeUnspsc190501.85404701'),
	'identifierSchemeCodeUnspsc190501.85404702': __('identifierSchemeCodeUnspsc190501.85404702'),
	'identifierSchemeCodeUnspsc190501.85404703': __('identifierSchemeCodeUnspsc190501.85404703'),
	'identifierSchemeCodeUnspsc190501.85404800': __('identifierSchemeCodeUnspsc190501.85404800'),
	'identifierSchemeCodeUnspsc190501.85404801': __('identifierSchemeCodeUnspsc190501.85404801'),
	'identifierSchemeCodeUnspsc190501.85404802': __('identifierSchemeCodeUnspsc190501.85404802'),
	'identifierSchemeCodeUnspsc190501.85404803': __('identifierSchemeCodeUnspsc190501.85404803'),
	'identifierSchemeCodeUnspsc190501.85404804': __('identifierSchemeCodeUnspsc190501.85404804'),
	'identifierSchemeCodeUnspsc190501.85404805': __('identifierSchemeCodeUnspsc190501.85404805'),
	'identifierSchemeCodeUnspsc190501.85404900': __('identifierSchemeCodeUnspsc190501.85404900'),
	'identifierSchemeCodeUnspsc190501.85404901': __('identifierSchemeCodeUnspsc190501.85404901'),
	'identifierSchemeCodeUnspsc190501.85404902': __('identifierSchemeCodeUnspsc190501.85404902'),
	'identifierSchemeCodeUnspsc190501.85404903': __('identifierSchemeCodeUnspsc190501.85404903'),
	'identifierSchemeCodeUnspsc190501.85404904': __('identifierSchemeCodeUnspsc190501.85404904'),
	'identifierSchemeCodeUnspsc190501.85404905': __('identifierSchemeCodeUnspsc190501.85404905'),
	'identifierSchemeCodeUnspsc190501.85404906': __('identifierSchemeCodeUnspsc190501.85404906'),
	'identifierSchemeCodeUnspsc190501.85404907': __('identifierSchemeCodeUnspsc190501.85404907'),
	'identifierSchemeCodeUnspsc190501.85405000': __('identifierSchemeCodeUnspsc190501.85405000'),
	'identifierSchemeCodeUnspsc190501.85405001': __('identifierSchemeCodeUnspsc190501.85405001'),
	'identifierSchemeCodeUnspsc190501.85405002': __('identifierSchemeCodeUnspsc190501.85405002'),
	'identifierSchemeCodeUnspsc190501.85405003': __('identifierSchemeCodeUnspsc190501.85405003'),
	'identifierSchemeCodeUnspsc190501.85405004': __('identifierSchemeCodeUnspsc190501.85405004'),
	'identifierSchemeCodeUnspsc190501.85405005': __('identifierSchemeCodeUnspsc190501.85405005'),
	'identifierSchemeCodeUnspsc190501.85405006': __('identifierSchemeCodeUnspsc190501.85405006'),
	'identifierSchemeCodeUnspsc190501.85405100': __('identifierSchemeCodeUnspsc190501.85405100'),
	'identifierSchemeCodeUnspsc190501.85405101': __('identifierSchemeCodeUnspsc190501.85405101'),
	'identifierSchemeCodeUnspsc190501.85405102': __('identifierSchemeCodeUnspsc190501.85405102'),
	'identifierSchemeCodeUnspsc190501.85405103': __('identifierSchemeCodeUnspsc190501.85405103'),
	'identifierSchemeCodeUnspsc190501.85405200': __('identifierSchemeCodeUnspsc190501.85405200'),
	'identifierSchemeCodeUnspsc190501.85405201': __('identifierSchemeCodeUnspsc190501.85405201'),
	'identifierSchemeCodeUnspsc190501.85405202': __('identifierSchemeCodeUnspsc190501.85405202'),
	'identifierSchemeCodeUnspsc190501.85405203': __('identifierSchemeCodeUnspsc190501.85405203'),
	'identifierSchemeCodeUnspsc190501.85405204': __('identifierSchemeCodeUnspsc190501.85405204'),
	'identifierSchemeCodeUnspsc190501.85405205': __('identifierSchemeCodeUnspsc190501.85405205'),
	'identifierSchemeCodeUnspsc190501.85405300': __('identifierSchemeCodeUnspsc190501.85405300'),
	'identifierSchemeCodeUnspsc190501.85405301': __('identifierSchemeCodeUnspsc190501.85405301'),
	'identifierSchemeCodeUnspsc190501.85405302': __('identifierSchemeCodeUnspsc190501.85405302'),
	'identifierSchemeCodeUnspsc190501.85405303': __('identifierSchemeCodeUnspsc190501.85405303'),
	'identifierSchemeCodeUnspsc190501.85405304': __('identifierSchemeCodeUnspsc190501.85405304'),
	'identifierSchemeCodeUnspsc190501.85405400': __('identifierSchemeCodeUnspsc190501.85405400'),
	'identifierSchemeCodeUnspsc190501.85405401': __('identifierSchemeCodeUnspsc190501.85405401'),
	'identifierSchemeCodeUnspsc190501.85405402': __('identifierSchemeCodeUnspsc190501.85405402'),
	'identifierSchemeCodeUnspsc190501.85405403': __('identifierSchemeCodeUnspsc190501.85405403'),
	'identifierSchemeCodeUnspsc190501.85405404': __('identifierSchemeCodeUnspsc190501.85405404'),
	'identifierSchemeCodeUnspsc190501.85405405': __('identifierSchemeCodeUnspsc190501.85405405'),
	'identifierSchemeCodeUnspsc190501.85405500': __('identifierSchemeCodeUnspsc190501.85405500'),
	'identifierSchemeCodeUnspsc190501.85405501': __('identifierSchemeCodeUnspsc190501.85405501'),
	'identifierSchemeCodeUnspsc190501.85405502': __('identifierSchemeCodeUnspsc190501.85405502'),
	'identifierSchemeCodeUnspsc190501.85405503': __('identifierSchemeCodeUnspsc190501.85405503'),
	'identifierSchemeCodeUnspsc190501.85405504': __('identifierSchemeCodeUnspsc190501.85405504'),
	'identifierSchemeCodeUnspsc190501.85405505': __('identifierSchemeCodeUnspsc190501.85405505'),
	'identifierSchemeCodeUnspsc190501.85405600': __('identifierSchemeCodeUnspsc190501.85405600'),
	'identifierSchemeCodeUnspsc190501.85405601': __('identifierSchemeCodeUnspsc190501.85405601'),
	'identifierSchemeCodeUnspsc190501.85405602': __('identifierSchemeCodeUnspsc190501.85405602'),
	'identifierSchemeCodeUnspsc190501.85405603': __('identifierSchemeCodeUnspsc190501.85405603'),
	'identifierSchemeCodeUnspsc190501.85405604': __('identifierSchemeCodeUnspsc190501.85405604'),
	'identifierSchemeCodeUnspsc190501.85405605': __('identifierSchemeCodeUnspsc190501.85405605'),
	'identifierSchemeCodeUnspsc190501.85405606': __('identifierSchemeCodeUnspsc190501.85405606'),
	'identifierSchemeCodeUnspsc190501.85405607': __('identifierSchemeCodeUnspsc190501.85405607'),
	'identifierSchemeCodeUnspsc190501.85405700': __('identifierSchemeCodeUnspsc190501.85405700'),
	'identifierSchemeCodeUnspsc190501.85405701': __('identifierSchemeCodeUnspsc190501.85405701'),
	'identifierSchemeCodeUnspsc190501.85405702': __('identifierSchemeCodeUnspsc190501.85405702'),
	'identifierSchemeCodeUnspsc190501.85405703': __('identifierSchemeCodeUnspsc190501.85405703'),
	'identifierSchemeCodeUnspsc190501.85405704': __('identifierSchemeCodeUnspsc190501.85405704'),
	'identifierSchemeCodeUnspsc190501.85405800': __('identifierSchemeCodeUnspsc190501.85405800'),
	'identifierSchemeCodeUnspsc190501.85405801': __('identifierSchemeCodeUnspsc190501.85405801'),
	'identifierSchemeCodeUnspsc190501.85405802': __('identifierSchemeCodeUnspsc190501.85405802'),
	'identifierSchemeCodeUnspsc190501.85405803': __('identifierSchemeCodeUnspsc190501.85405803'),
	'identifierSchemeCodeUnspsc190501.85405804': __('identifierSchemeCodeUnspsc190501.85405804'),
	'identifierSchemeCodeUnspsc190501.85405805': __('identifierSchemeCodeUnspsc190501.85405805'),
	'identifierSchemeCodeUnspsc190501.85405806': __('identifierSchemeCodeUnspsc190501.85405806'),
	'identifierSchemeCodeUnspsc190501.85405900': __('identifierSchemeCodeUnspsc190501.85405900'),
	'identifierSchemeCodeUnspsc190501.85405901': __('identifierSchemeCodeUnspsc190501.85405901'),
	'identifierSchemeCodeUnspsc190501.85405902': __('identifierSchemeCodeUnspsc190501.85405902'),
	'identifierSchemeCodeUnspsc190501.85405903': __('identifierSchemeCodeUnspsc190501.85405903'),
	'identifierSchemeCodeUnspsc190501.85405904': __('identifierSchemeCodeUnspsc190501.85405904'),
	'identifierSchemeCodeUnspsc190501.85405905': __('identifierSchemeCodeUnspsc190501.85405905'),
	'identifierSchemeCodeUnspsc190501.85406000': __('identifierSchemeCodeUnspsc190501.85406000'),
	'identifierSchemeCodeUnspsc190501.85406001': __('identifierSchemeCodeUnspsc190501.85406001'),
	'identifierSchemeCodeUnspsc190501.85406002': __('identifierSchemeCodeUnspsc190501.85406002'),
	'identifierSchemeCodeUnspsc190501.85406003': __('identifierSchemeCodeUnspsc190501.85406003'),
	'identifierSchemeCodeUnspsc190501.85406004': __('identifierSchemeCodeUnspsc190501.85406004'),
	'identifierSchemeCodeUnspsc190501.85406005': __('identifierSchemeCodeUnspsc190501.85406005'),
	'identifierSchemeCodeUnspsc190501.85406006': __('identifierSchemeCodeUnspsc190501.85406006'),
	'identifierSchemeCodeUnspsc190501.85406100': __('identifierSchemeCodeUnspsc190501.85406100'),
	'identifierSchemeCodeUnspsc190501.85406101': __('identifierSchemeCodeUnspsc190501.85406101'),
	'identifierSchemeCodeUnspsc190501.85406102': __('identifierSchemeCodeUnspsc190501.85406102'),
	'identifierSchemeCodeUnspsc190501.85406103': __('identifierSchemeCodeUnspsc190501.85406103'),
	'identifierSchemeCodeUnspsc190501.85406104': __('identifierSchemeCodeUnspsc190501.85406104'),
	'identifierSchemeCodeUnspsc190501.85406105': __('identifierSchemeCodeUnspsc190501.85406105'),
	'identifierSchemeCodeUnspsc190501.85406106': __('identifierSchemeCodeUnspsc190501.85406106'),
	'identifierSchemeCodeUnspsc190501.85406107': __('identifierSchemeCodeUnspsc190501.85406107'),
	'identifierSchemeCodeUnspsc190501.85406200': __('identifierSchemeCodeUnspsc190501.85406200'),
	'identifierSchemeCodeUnspsc190501.85406201': __('identifierSchemeCodeUnspsc190501.85406201'),
	'identifierSchemeCodeUnspsc190501.85406202': __('identifierSchemeCodeUnspsc190501.85406202'),
	'identifierSchemeCodeUnspsc190501.85406203': __('identifierSchemeCodeUnspsc190501.85406203'),
	'identifierSchemeCodeUnspsc190501.85406204': __('identifierSchemeCodeUnspsc190501.85406204'),
	'identifierSchemeCodeUnspsc190501.85406205': __('identifierSchemeCodeUnspsc190501.85406205'),
	'identifierSchemeCodeUnspsc190501.85406300': __('identifierSchemeCodeUnspsc190501.85406300'),
	'identifierSchemeCodeUnspsc190501.85406301': __('identifierSchemeCodeUnspsc190501.85406301'),
	'identifierSchemeCodeUnspsc190501.85406302': __('identifierSchemeCodeUnspsc190501.85406302'),
	'identifierSchemeCodeUnspsc190501.85406303': __('identifierSchemeCodeUnspsc190501.85406303'),
	'identifierSchemeCodeUnspsc190501.85406400': __('identifierSchemeCodeUnspsc190501.85406400'),
	'identifierSchemeCodeUnspsc190501.85406401': __('identifierSchemeCodeUnspsc190501.85406401'),
	'identifierSchemeCodeUnspsc190501.85406402': __('identifierSchemeCodeUnspsc190501.85406402'),
	'identifierSchemeCodeUnspsc190501.85406403': __('identifierSchemeCodeUnspsc190501.85406403'),
	'identifierSchemeCodeUnspsc190501.85406404': __('identifierSchemeCodeUnspsc190501.85406404'),
	'identifierSchemeCodeUnspsc190501.85406405': __('identifierSchemeCodeUnspsc190501.85406405'),
	'identifierSchemeCodeUnspsc190501.85406500': __('identifierSchemeCodeUnspsc190501.85406500'),
	'identifierSchemeCodeUnspsc190501.85406501': __('identifierSchemeCodeUnspsc190501.85406501'),
	'identifierSchemeCodeUnspsc190501.85406502': __('identifierSchemeCodeUnspsc190501.85406502'),
	'identifierSchemeCodeUnspsc190501.85406503': __('identifierSchemeCodeUnspsc190501.85406503'),
	'identifierSchemeCodeUnspsc190501.85406504': __('identifierSchemeCodeUnspsc190501.85406504'),
	'identifierSchemeCodeUnspsc190501.85406505': __('identifierSchemeCodeUnspsc190501.85406505'),
	'identifierSchemeCodeUnspsc190501.85406506': __('identifierSchemeCodeUnspsc190501.85406506'),
	'identifierSchemeCodeUnspsc190501.85406507': __('identifierSchemeCodeUnspsc190501.85406507'),
	'identifierSchemeCodeUnspsc190501.85406600': __('identifierSchemeCodeUnspsc190501.85406600'),
	'identifierSchemeCodeUnspsc190501.85406601': __('identifierSchemeCodeUnspsc190501.85406601'),
	'identifierSchemeCodeUnspsc190501.85406602': __('identifierSchemeCodeUnspsc190501.85406602'),
	'identifierSchemeCodeUnspsc190501.85406603': __('identifierSchemeCodeUnspsc190501.85406603'),
	'identifierSchemeCodeUnspsc190501.85406604': __('identifierSchemeCodeUnspsc190501.85406604'),
	'identifierSchemeCodeUnspsc190501.85406605': __('identifierSchemeCodeUnspsc190501.85406605'),
	'identifierSchemeCodeUnspsc190501.85406606': __('identifierSchemeCodeUnspsc190501.85406606'),
	'identifierSchemeCodeUnspsc190501.85406607': __('identifierSchemeCodeUnspsc190501.85406607'),
	'identifierSchemeCodeUnspsc190501.85406608': __('identifierSchemeCodeUnspsc190501.85406608'),
	'identifierSchemeCodeUnspsc190501.85406700': __('identifierSchemeCodeUnspsc190501.85406700'),
	'identifierSchemeCodeUnspsc190501.85406701': __('identifierSchemeCodeUnspsc190501.85406701'),
	'identifierSchemeCodeUnspsc190501.85406702': __('identifierSchemeCodeUnspsc190501.85406702'),
	'identifierSchemeCodeUnspsc190501.85406800': __('identifierSchemeCodeUnspsc190501.85406800'),
	'identifierSchemeCodeUnspsc190501.85406801': __('identifierSchemeCodeUnspsc190501.85406801'),
	'identifierSchemeCodeUnspsc190501.85406802': __('identifierSchemeCodeUnspsc190501.85406802'),
	'identifierSchemeCodeUnspsc190501.85406803': __('identifierSchemeCodeUnspsc190501.85406803'),
	'identifierSchemeCodeUnspsc190501.85406804': __('identifierSchemeCodeUnspsc190501.85406804'),
	'identifierSchemeCodeUnspsc190501.85406805': __('identifierSchemeCodeUnspsc190501.85406805'),
	'identifierSchemeCodeUnspsc190501.85406806': __('identifierSchemeCodeUnspsc190501.85406806'),
	'identifierSchemeCodeUnspsc190501.85406900': __('identifierSchemeCodeUnspsc190501.85406900'),
	'identifierSchemeCodeUnspsc190501.85406901': __('identifierSchemeCodeUnspsc190501.85406901'),
	'identifierSchemeCodeUnspsc190501.85406902': __('identifierSchemeCodeUnspsc190501.85406902'),
	'identifierSchemeCodeUnspsc190501.85406903': __('identifierSchemeCodeUnspsc190501.85406903'),
	'identifierSchemeCodeUnspsc190501.85406904': __('identifierSchemeCodeUnspsc190501.85406904'),
	'identifierSchemeCodeUnspsc190501.85406905': __('identifierSchemeCodeUnspsc190501.85406905'),
	'identifierSchemeCodeUnspsc190501.85406906': __('identifierSchemeCodeUnspsc190501.85406906'),
	'identifierSchemeCodeUnspsc190501.85407000': __('identifierSchemeCodeUnspsc190501.85407000'),
	'identifierSchemeCodeUnspsc190501.85407001': __('identifierSchemeCodeUnspsc190501.85407001'),
	'identifierSchemeCodeUnspsc190501.85407002': __('identifierSchemeCodeUnspsc190501.85407002'),
	'identifierSchemeCodeUnspsc190501.85407003': __('identifierSchemeCodeUnspsc190501.85407003'),
	'identifierSchemeCodeUnspsc190501.85407004': __('identifierSchemeCodeUnspsc190501.85407004'),
	'identifierSchemeCodeUnspsc190501.85407005': __('identifierSchemeCodeUnspsc190501.85407005'),
	'identifierSchemeCodeUnspsc190501.85407006': __('identifierSchemeCodeUnspsc190501.85407006'),
	'identifierSchemeCodeUnspsc190501.85407007': __('identifierSchemeCodeUnspsc190501.85407007'),
	'identifierSchemeCodeUnspsc190501.85407008': __('identifierSchemeCodeUnspsc190501.85407008'),
	'identifierSchemeCodeUnspsc190501.85407100': __('identifierSchemeCodeUnspsc190501.85407100'),
	'identifierSchemeCodeUnspsc190501.85407101': __('identifierSchemeCodeUnspsc190501.85407101'),
	'identifierSchemeCodeUnspsc190501.85407102': __('identifierSchemeCodeUnspsc190501.85407102'),
	'identifierSchemeCodeUnspsc190501.85407103': __('identifierSchemeCodeUnspsc190501.85407103'),
	'identifierSchemeCodeUnspsc190501.85407104': __('identifierSchemeCodeUnspsc190501.85407104'),
	'identifierSchemeCodeUnspsc190501.85407105': __('identifierSchemeCodeUnspsc190501.85407105'),
	'identifierSchemeCodeUnspsc190501.85407106': __('identifierSchemeCodeUnspsc190501.85407106'),
	'identifierSchemeCodeUnspsc190501.85407107': __('identifierSchemeCodeUnspsc190501.85407107'),
	'identifierSchemeCodeUnspsc190501.85407200': __('identifierSchemeCodeUnspsc190501.85407200'),
	'identifierSchemeCodeUnspsc190501.85407201': __('identifierSchemeCodeUnspsc190501.85407201'),
	'identifierSchemeCodeUnspsc190501.85407202': __('identifierSchemeCodeUnspsc190501.85407202'),
	'identifierSchemeCodeUnspsc190501.85407203': __('identifierSchemeCodeUnspsc190501.85407203'),
	'identifierSchemeCodeUnspsc190501.85407204': __('identifierSchemeCodeUnspsc190501.85407204'),
	'identifierSchemeCodeUnspsc190501.85407205': __('identifierSchemeCodeUnspsc190501.85407205'),
	'identifierSchemeCodeUnspsc190501.85407300': __('identifierSchemeCodeUnspsc190501.85407300'),
	'identifierSchemeCodeUnspsc190501.85407301': __('identifierSchemeCodeUnspsc190501.85407301'),
	'identifierSchemeCodeUnspsc190501.85407302': __('identifierSchemeCodeUnspsc190501.85407302'),
	'identifierSchemeCodeUnspsc190501.85407303': __('identifierSchemeCodeUnspsc190501.85407303'),
	'identifierSchemeCodeUnspsc190501.85407304': __('identifierSchemeCodeUnspsc190501.85407304'),
	'identifierSchemeCodeUnspsc190501.85407305': __('identifierSchemeCodeUnspsc190501.85407305'),
	'identifierSchemeCodeUnspsc190501.85407400': __('identifierSchemeCodeUnspsc190501.85407400'),
	'identifierSchemeCodeUnspsc190501.85407401': __('identifierSchemeCodeUnspsc190501.85407401'),
	'identifierSchemeCodeUnspsc190501.85407402': __('identifierSchemeCodeUnspsc190501.85407402'),
	'identifierSchemeCodeUnspsc190501.85407403': __('identifierSchemeCodeUnspsc190501.85407403'),
	'identifierSchemeCodeUnspsc190501.85407500': __('identifierSchemeCodeUnspsc190501.85407500'),
	'identifierSchemeCodeUnspsc190501.85407501': __('identifierSchemeCodeUnspsc190501.85407501'),
	'identifierSchemeCodeUnspsc190501.85407502': __('identifierSchemeCodeUnspsc190501.85407502'),
	'identifierSchemeCodeUnspsc190501.85407503': __('identifierSchemeCodeUnspsc190501.85407503'),
	'identifierSchemeCodeUnspsc190501.85407504': __('identifierSchemeCodeUnspsc190501.85407504'),
	'identifierSchemeCodeUnspsc190501.85407505': __('identifierSchemeCodeUnspsc190501.85407505'),
	'identifierSchemeCodeUnspsc190501.85407506': __('identifierSchemeCodeUnspsc190501.85407506'),
	'identifierSchemeCodeUnspsc190501.85407600': __('identifierSchemeCodeUnspsc190501.85407600'),
	'identifierSchemeCodeUnspsc190501.85407601': __('identifierSchemeCodeUnspsc190501.85407601'),
	'identifierSchemeCodeUnspsc190501.85407602': __('identifierSchemeCodeUnspsc190501.85407602'),
	'identifierSchemeCodeUnspsc190501.85407603': __('identifierSchemeCodeUnspsc190501.85407603'),
	'identifierSchemeCodeUnspsc190501.85407604': __('identifierSchemeCodeUnspsc190501.85407604'),
	'identifierSchemeCodeUnspsc190501.85407700': __('identifierSchemeCodeUnspsc190501.85407700'),
	'identifierSchemeCodeUnspsc190501.85407701': __('identifierSchemeCodeUnspsc190501.85407701'),
	'identifierSchemeCodeUnspsc190501.85407702': __('identifierSchemeCodeUnspsc190501.85407702'),
	'identifierSchemeCodeUnspsc190501.85407703': __('identifierSchemeCodeUnspsc190501.85407703'),
	'identifierSchemeCodeUnspsc190501.85407704': __('identifierSchemeCodeUnspsc190501.85407704'),
	'identifierSchemeCodeUnspsc190501.85407800': __('identifierSchemeCodeUnspsc190501.85407800'),
	'identifierSchemeCodeUnspsc190501.85407801': __('identifierSchemeCodeUnspsc190501.85407801'),
	'identifierSchemeCodeUnspsc190501.85407802': __('identifierSchemeCodeUnspsc190501.85407802'),
	'identifierSchemeCodeUnspsc190501.85407900': __('identifierSchemeCodeUnspsc190501.85407900'),
	'identifierSchemeCodeUnspsc190501.85407901': __('identifierSchemeCodeUnspsc190501.85407901'),
	'identifierSchemeCodeUnspsc190501.85407902': __('identifierSchemeCodeUnspsc190501.85407902'),
	'identifierSchemeCodeUnspsc190501.85407903': __('identifierSchemeCodeUnspsc190501.85407903'),
	'identifierSchemeCodeUnspsc190501.85408000': __('identifierSchemeCodeUnspsc190501.85408000'),
	'identifierSchemeCodeUnspsc190501.85408001': __('identifierSchemeCodeUnspsc190501.85408001'),
	'identifierSchemeCodeUnspsc190501.85408002': __('identifierSchemeCodeUnspsc190501.85408002'),
	'identifierSchemeCodeUnspsc190501.85408003': __('identifierSchemeCodeUnspsc190501.85408003'),
	'identifierSchemeCodeUnspsc190501.85408004': __('identifierSchemeCodeUnspsc190501.85408004'),
	'identifierSchemeCodeUnspsc190501.85408005': __('identifierSchemeCodeUnspsc190501.85408005'),
	'identifierSchemeCodeUnspsc190501.85408100': __('identifierSchemeCodeUnspsc190501.85408100'),
	'identifierSchemeCodeUnspsc190501.85408101': __('identifierSchemeCodeUnspsc190501.85408101'),
	'identifierSchemeCodeUnspsc190501.85408102': __('identifierSchemeCodeUnspsc190501.85408102'),
	'identifierSchemeCodeUnspsc190501.85408103': __('identifierSchemeCodeUnspsc190501.85408103'),
	'identifierSchemeCodeUnspsc190501.85408104': __('identifierSchemeCodeUnspsc190501.85408104'),
	'identifierSchemeCodeUnspsc190501.85408200': __('identifierSchemeCodeUnspsc190501.85408200'),
	'identifierSchemeCodeUnspsc190501.85408201': __('identifierSchemeCodeUnspsc190501.85408201'),
	'identifierSchemeCodeUnspsc190501.85408202': __('identifierSchemeCodeUnspsc190501.85408202'),
	'identifierSchemeCodeUnspsc190501.85408203': __('identifierSchemeCodeUnspsc190501.85408203'),
	'identifierSchemeCodeUnspsc190501.85408300': __('identifierSchemeCodeUnspsc190501.85408300'),
	'identifierSchemeCodeUnspsc190501.85408301': __('identifierSchemeCodeUnspsc190501.85408301'),
	'identifierSchemeCodeUnspsc190501.85408302': __('identifierSchemeCodeUnspsc190501.85408302'),
	'identifierSchemeCodeUnspsc190501.85408303': __('identifierSchemeCodeUnspsc190501.85408303'),
	'identifierSchemeCodeUnspsc190501.85408304': __('identifierSchemeCodeUnspsc190501.85408304'),
	'identifierSchemeCodeUnspsc190501.85408305': __('identifierSchemeCodeUnspsc190501.85408305'),
	'identifierSchemeCodeUnspsc190501.85408306': __('identifierSchemeCodeUnspsc190501.85408306'),
	'identifierSchemeCodeUnspsc190501.85408307': __('identifierSchemeCodeUnspsc190501.85408307'),
	'identifierSchemeCodeUnspsc190501.85408308': __('identifierSchemeCodeUnspsc190501.85408308'),
	'identifierSchemeCodeUnspsc190501.85408309': __('identifierSchemeCodeUnspsc190501.85408309'),
	'identifierSchemeCodeUnspsc190501.85408310': __('identifierSchemeCodeUnspsc190501.85408310'),
	'identifierSchemeCodeUnspsc190501.85408311': __('identifierSchemeCodeUnspsc190501.85408311'),
	'identifierSchemeCodeUnspsc190501.85408312': __('identifierSchemeCodeUnspsc190501.85408312'),
	'identifierSchemeCodeUnspsc190501.85408313': __('identifierSchemeCodeUnspsc190501.85408313'),
	'identifierSchemeCodeUnspsc190501.85408314': __('identifierSchemeCodeUnspsc190501.85408314'),
	'identifierSchemeCodeUnspsc190501.85408400': __('identifierSchemeCodeUnspsc190501.85408400'),
	'identifierSchemeCodeUnspsc190501.85408401': __('identifierSchemeCodeUnspsc190501.85408401'),
	'identifierSchemeCodeUnspsc190501.85408402': __('identifierSchemeCodeUnspsc190501.85408402'),
	'identifierSchemeCodeUnspsc190501.85408403': __('identifierSchemeCodeUnspsc190501.85408403'),
	'identifierSchemeCodeUnspsc190501.85408404': __('identifierSchemeCodeUnspsc190501.85408404'),
	'identifierSchemeCodeUnspsc190501.85408405': __('identifierSchemeCodeUnspsc190501.85408405'),
	'identifierSchemeCodeUnspsc190501.85408406': __('identifierSchemeCodeUnspsc190501.85408406'),
	'identifierSchemeCodeUnspsc190501.85408500': __('identifierSchemeCodeUnspsc190501.85408500'),
	'identifierSchemeCodeUnspsc190501.85408501': __('identifierSchemeCodeUnspsc190501.85408501'),
	'identifierSchemeCodeUnspsc190501.85408502': __('identifierSchemeCodeUnspsc190501.85408502'),
	'identifierSchemeCodeUnspsc190501.85408503': __('identifierSchemeCodeUnspsc190501.85408503'),
	'identifierSchemeCodeUnspsc190501.85408504': __('identifierSchemeCodeUnspsc190501.85408504'),
	'identifierSchemeCodeUnspsc190501.85408505': __('identifierSchemeCodeUnspsc190501.85408505'),
	'identifierSchemeCodeUnspsc190501.85408506': __('identifierSchemeCodeUnspsc190501.85408506'),
	'identifierSchemeCodeUnspsc190501.85408600': __('identifierSchemeCodeUnspsc190501.85408600'),
	'identifierSchemeCodeUnspsc190501.85408601': __('identifierSchemeCodeUnspsc190501.85408601'),
	'identifierSchemeCodeUnspsc190501.85408602': __('identifierSchemeCodeUnspsc190501.85408602'),
	'identifierSchemeCodeUnspsc190501.85408603': __('identifierSchemeCodeUnspsc190501.85408603'),
	'identifierSchemeCodeUnspsc190501.85408604': __('identifierSchemeCodeUnspsc190501.85408604'),
	'identifierSchemeCodeUnspsc190501.85408700': __('identifierSchemeCodeUnspsc190501.85408700'),
	'identifierSchemeCodeUnspsc190501.85408701': __('identifierSchemeCodeUnspsc190501.85408701'),
	'identifierSchemeCodeUnspsc190501.85408702': __('identifierSchemeCodeUnspsc190501.85408702'),
	'identifierSchemeCodeUnspsc190501.85408703': __('identifierSchemeCodeUnspsc190501.85408703'),
	'identifierSchemeCodeUnspsc190501.85408704': __('identifierSchemeCodeUnspsc190501.85408704'),
	'identifierSchemeCodeUnspsc190501.85408800': __('identifierSchemeCodeUnspsc190501.85408800'),
	'identifierSchemeCodeUnspsc190501.85408801': __('identifierSchemeCodeUnspsc190501.85408801'),
	'identifierSchemeCodeUnspsc190501.85408802': __('identifierSchemeCodeUnspsc190501.85408802'),
	'identifierSchemeCodeUnspsc190501.85408803': __('identifierSchemeCodeUnspsc190501.85408803'),
	'identifierSchemeCodeUnspsc190501.85408804': __('identifierSchemeCodeUnspsc190501.85408804'),
	'identifierSchemeCodeUnspsc190501.85408805': __('identifierSchemeCodeUnspsc190501.85408805'),
	'identifierSchemeCodeUnspsc190501.85408806': __('identifierSchemeCodeUnspsc190501.85408806'),
	'identifierSchemeCodeUnspsc190501.85408900': __('identifierSchemeCodeUnspsc190501.85408900'),
	'identifierSchemeCodeUnspsc190501.85408901': __('identifierSchemeCodeUnspsc190501.85408901'),
	'identifierSchemeCodeUnspsc190501.85408902': __('identifierSchemeCodeUnspsc190501.85408902'),
	'identifierSchemeCodeUnspsc190501.85408903': __('identifierSchemeCodeUnspsc190501.85408903'),
	'identifierSchemeCodeUnspsc190501.85409000': __('identifierSchemeCodeUnspsc190501.85409000'),
	'identifierSchemeCodeUnspsc190501.85409001': __('identifierSchemeCodeUnspsc190501.85409001'),
	'identifierSchemeCodeUnspsc190501.85409002': __('identifierSchemeCodeUnspsc190501.85409002'),
	'identifierSchemeCodeUnspsc190501.85409003': __('identifierSchemeCodeUnspsc190501.85409003'),
	'identifierSchemeCodeUnspsc190501.85409004': __('identifierSchemeCodeUnspsc190501.85409004'),
	'identifierSchemeCodeUnspsc190501.85409005': __('identifierSchemeCodeUnspsc190501.85409005'),
	'identifierSchemeCodeUnspsc190501.85409100': __('identifierSchemeCodeUnspsc190501.85409100'),
	'identifierSchemeCodeUnspsc190501.85409101': __('identifierSchemeCodeUnspsc190501.85409101'),
	'identifierSchemeCodeUnspsc190501.85409102': __('identifierSchemeCodeUnspsc190501.85409102'),
	'identifierSchemeCodeUnspsc190501.85409103': __('identifierSchemeCodeUnspsc190501.85409103'),
	'identifierSchemeCodeUnspsc190501.85409104': __('identifierSchemeCodeUnspsc190501.85409104'),
	'identifierSchemeCodeUnspsc190501.85409105': __('identifierSchemeCodeUnspsc190501.85409105'),
	'identifierSchemeCodeUnspsc190501.85409200': __('identifierSchemeCodeUnspsc190501.85409200'),
	'identifierSchemeCodeUnspsc190501.85409201': __('identifierSchemeCodeUnspsc190501.85409201'),
	'identifierSchemeCodeUnspsc190501.85409202': __('identifierSchemeCodeUnspsc190501.85409202'),
	'identifierSchemeCodeUnspsc190501.85409203': __('identifierSchemeCodeUnspsc190501.85409203'),
	'identifierSchemeCodeUnspsc190501.85409204': __('identifierSchemeCodeUnspsc190501.85409204'),
	'identifierSchemeCodeUnspsc190501.85409205': __('identifierSchemeCodeUnspsc190501.85409205'),
	'identifierSchemeCodeUnspsc190501.85409300': __('identifierSchemeCodeUnspsc190501.85409300'),
	'identifierSchemeCodeUnspsc190501.85409301': __('identifierSchemeCodeUnspsc190501.85409301'),
	'identifierSchemeCodeUnspsc190501.85409302': __('identifierSchemeCodeUnspsc190501.85409302'),
	'identifierSchemeCodeUnspsc190501.85409303': __('identifierSchemeCodeUnspsc190501.85409303'),
	'identifierSchemeCodeUnspsc190501.85409304': __('identifierSchemeCodeUnspsc190501.85409304'),
	'identifierSchemeCodeUnspsc190501.85409305': __('identifierSchemeCodeUnspsc190501.85409305'),
	'identifierSchemeCodeUnspsc190501.85409306': __('identifierSchemeCodeUnspsc190501.85409306'),
	'identifierSchemeCodeUnspsc190501.85409307': __('identifierSchemeCodeUnspsc190501.85409307'),
	'identifierSchemeCodeUnspsc190501.85409400': __('identifierSchemeCodeUnspsc190501.85409400'),
	'identifierSchemeCodeUnspsc190501.85409401': __('identifierSchemeCodeUnspsc190501.85409401'),
	'identifierSchemeCodeUnspsc190501.85409402': __('identifierSchemeCodeUnspsc190501.85409402'),
	'identifierSchemeCodeUnspsc190501.85409403': __('identifierSchemeCodeUnspsc190501.85409403'),
	'identifierSchemeCodeUnspsc190501.85409404': __('identifierSchemeCodeUnspsc190501.85409404'),
	'identifierSchemeCodeUnspsc190501.85409405': __('identifierSchemeCodeUnspsc190501.85409405'),
	'identifierSchemeCodeUnspsc190501.85409406': __('identifierSchemeCodeUnspsc190501.85409406'),
	'identifierSchemeCodeUnspsc190501.85409407': __('identifierSchemeCodeUnspsc190501.85409407'),
	'identifierSchemeCodeUnspsc190501.85409500': __('identifierSchemeCodeUnspsc190501.85409500'),
	'identifierSchemeCodeUnspsc190501.85409501': __('identifierSchemeCodeUnspsc190501.85409501'),
	'identifierSchemeCodeUnspsc190501.85409502': __('identifierSchemeCodeUnspsc190501.85409502'),
	'identifierSchemeCodeUnspsc190501.85409503': __('identifierSchemeCodeUnspsc190501.85409503'),
	'identifierSchemeCodeUnspsc190501.85409504': __('identifierSchemeCodeUnspsc190501.85409504'),
	'identifierSchemeCodeUnspsc190501.85409505': __('identifierSchemeCodeUnspsc190501.85409505'),
	'identifierSchemeCodeUnspsc190501.86000000': __('identifierSchemeCodeUnspsc190501.86000000'),
	'identifierSchemeCodeUnspsc190501.86100000': __('identifierSchemeCodeUnspsc190501.86100000'),
	'identifierSchemeCodeUnspsc190501.86101500': __('identifierSchemeCodeUnspsc190501.86101500'),
	'identifierSchemeCodeUnspsc190501.86101501': __('identifierSchemeCodeUnspsc190501.86101501'),
	'identifierSchemeCodeUnspsc190501.86101502': __('identifierSchemeCodeUnspsc190501.86101502'),
	'identifierSchemeCodeUnspsc190501.86101503': __('identifierSchemeCodeUnspsc190501.86101503'),
	'identifierSchemeCodeUnspsc190501.86101504': __('identifierSchemeCodeUnspsc190501.86101504'),
	'identifierSchemeCodeUnspsc190501.86101505': __('identifierSchemeCodeUnspsc190501.86101505'),
	'identifierSchemeCodeUnspsc190501.86101506': __('identifierSchemeCodeUnspsc190501.86101506'),
	'identifierSchemeCodeUnspsc190501.86101507': __('identifierSchemeCodeUnspsc190501.86101507'),
	'identifierSchemeCodeUnspsc190501.86101508': __('identifierSchemeCodeUnspsc190501.86101508'),
	'identifierSchemeCodeUnspsc190501.86101509': __('identifierSchemeCodeUnspsc190501.86101509'),
	'identifierSchemeCodeUnspsc190501.86101600': __('identifierSchemeCodeUnspsc190501.86101600'),
	'identifierSchemeCodeUnspsc190501.86101601': __('identifierSchemeCodeUnspsc190501.86101601'),
	'identifierSchemeCodeUnspsc190501.86101602': __('identifierSchemeCodeUnspsc190501.86101602'),
	'identifierSchemeCodeUnspsc190501.86101603': __('identifierSchemeCodeUnspsc190501.86101603'),
	'identifierSchemeCodeUnspsc190501.86101604': __('identifierSchemeCodeUnspsc190501.86101604'),
	'identifierSchemeCodeUnspsc190501.86101605': __('identifierSchemeCodeUnspsc190501.86101605'),
	'identifierSchemeCodeUnspsc190501.86101606': __('identifierSchemeCodeUnspsc190501.86101606'),
	'identifierSchemeCodeUnspsc190501.86101607': __('identifierSchemeCodeUnspsc190501.86101607'),
	'identifierSchemeCodeUnspsc190501.86101608': __('identifierSchemeCodeUnspsc190501.86101608'),
	'identifierSchemeCodeUnspsc190501.86101609': __('identifierSchemeCodeUnspsc190501.86101609'),
	'identifierSchemeCodeUnspsc190501.86101610': __('identifierSchemeCodeUnspsc190501.86101610'),
	'identifierSchemeCodeUnspsc190501.86101700': __('identifierSchemeCodeUnspsc190501.86101700'),
	'identifierSchemeCodeUnspsc190501.86101701': __('identifierSchemeCodeUnspsc190501.86101701'),
	'identifierSchemeCodeUnspsc190501.86101702': __('identifierSchemeCodeUnspsc190501.86101702'),
	'identifierSchemeCodeUnspsc190501.86101703': __('identifierSchemeCodeUnspsc190501.86101703'),
	'identifierSchemeCodeUnspsc190501.86101704': __('identifierSchemeCodeUnspsc190501.86101704'),
	'identifierSchemeCodeUnspsc190501.86101705': __('identifierSchemeCodeUnspsc190501.86101705'),
	'identifierSchemeCodeUnspsc190501.86101706': __('identifierSchemeCodeUnspsc190501.86101706'),
	'identifierSchemeCodeUnspsc190501.86101707': __('identifierSchemeCodeUnspsc190501.86101707'),
	'identifierSchemeCodeUnspsc190501.86101708': __('identifierSchemeCodeUnspsc190501.86101708'),
	'identifierSchemeCodeUnspsc190501.86101709': __('identifierSchemeCodeUnspsc190501.86101709'),
	'identifierSchemeCodeUnspsc190501.86101710': __('identifierSchemeCodeUnspsc190501.86101710'),
	'identifierSchemeCodeUnspsc190501.86101711': __('identifierSchemeCodeUnspsc190501.86101711'),
	'identifierSchemeCodeUnspsc190501.86101712': __('identifierSchemeCodeUnspsc190501.86101712'),
	'identifierSchemeCodeUnspsc190501.86101713': __('identifierSchemeCodeUnspsc190501.86101713'),
	'identifierSchemeCodeUnspsc190501.86101714': __('identifierSchemeCodeUnspsc190501.86101714'),
	'identifierSchemeCodeUnspsc190501.86101715': __('identifierSchemeCodeUnspsc190501.86101715'),
	'identifierSchemeCodeUnspsc190501.86101716': __('identifierSchemeCodeUnspsc190501.86101716'),
	'identifierSchemeCodeUnspsc190501.86101717': __('identifierSchemeCodeUnspsc190501.86101717'),
	'identifierSchemeCodeUnspsc190501.86101800': __('identifierSchemeCodeUnspsc190501.86101800'),
	'identifierSchemeCodeUnspsc190501.86101801': __('identifierSchemeCodeUnspsc190501.86101801'),
	'identifierSchemeCodeUnspsc190501.86101802': __('identifierSchemeCodeUnspsc190501.86101802'),
	'identifierSchemeCodeUnspsc190501.86101803': __('identifierSchemeCodeUnspsc190501.86101803'),
	'identifierSchemeCodeUnspsc190501.86101804': __('identifierSchemeCodeUnspsc190501.86101804'),
	'identifierSchemeCodeUnspsc190501.86101805': __('identifierSchemeCodeUnspsc190501.86101805'),
	'identifierSchemeCodeUnspsc190501.86101806': __('identifierSchemeCodeUnspsc190501.86101806'),
	'identifierSchemeCodeUnspsc190501.86101807': __('identifierSchemeCodeUnspsc190501.86101807'),
	'identifierSchemeCodeUnspsc190501.86101808': __('identifierSchemeCodeUnspsc190501.86101808'),
	'identifierSchemeCodeUnspsc190501.86101809': __('identifierSchemeCodeUnspsc190501.86101809'),
	'identifierSchemeCodeUnspsc190501.86101810': __('identifierSchemeCodeUnspsc190501.86101810'),
	'identifierSchemeCodeUnspsc190501.86110000': __('identifierSchemeCodeUnspsc190501.86110000'),
	'identifierSchemeCodeUnspsc190501.86111500': __('identifierSchemeCodeUnspsc190501.86111500'),
	'identifierSchemeCodeUnspsc190501.86111501': __('identifierSchemeCodeUnspsc190501.86111501'),
	'identifierSchemeCodeUnspsc190501.86111502': __('identifierSchemeCodeUnspsc190501.86111502'),
	'identifierSchemeCodeUnspsc190501.86111503': __('identifierSchemeCodeUnspsc190501.86111503'),
	'identifierSchemeCodeUnspsc190501.86111504': __('identifierSchemeCodeUnspsc190501.86111504'),
	'identifierSchemeCodeUnspsc190501.86111505': __('identifierSchemeCodeUnspsc190501.86111505'),
	'identifierSchemeCodeUnspsc190501.86111600': __('identifierSchemeCodeUnspsc190501.86111600'),
	'identifierSchemeCodeUnspsc190501.86111601': __('identifierSchemeCodeUnspsc190501.86111601'),
	'identifierSchemeCodeUnspsc190501.86111602': __('identifierSchemeCodeUnspsc190501.86111602'),
	'identifierSchemeCodeUnspsc190501.86111603': __('identifierSchemeCodeUnspsc190501.86111603'),
	'identifierSchemeCodeUnspsc190501.86111604': __('identifierSchemeCodeUnspsc190501.86111604'),
	'identifierSchemeCodeUnspsc190501.86111700': __('identifierSchemeCodeUnspsc190501.86111700'),
	'identifierSchemeCodeUnspsc190501.86111701': __('identifierSchemeCodeUnspsc190501.86111701'),
	'identifierSchemeCodeUnspsc190501.86111702': __('identifierSchemeCodeUnspsc190501.86111702'),
	'identifierSchemeCodeUnspsc190501.86111800': __('identifierSchemeCodeUnspsc190501.86111800'),
	'identifierSchemeCodeUnspsc190501.86111801': __('identifierSchemeCodeUnspsc190501.86111801'),
	'identifierSchemeCodeUnspsc190501.86111802': __('identifierSchemeCodeUnspsc190501.86111802'),
	'identifierSchemeCodeUnspsc190501.86120000': __('identifierSchemeCodeUnspsc190501.86120000'),
	'identifierSchemeCodeUnspsc190501.86121500': __('identifierSchemeCodeUnspsc190501.86121500'),
	'identifierSchemeCodeUnspsc190501.86121501': __('identifierSchemeCodeUnspsc190501.86121501'),
	'identifierSchemeCodeUnspsc190501.86121502': __('identifierSchemeCodeUnspsc190501.86121502'),
	'identifierSchemeCodeUnspsc190501.86121503': __('identifierSchemeCodeUnspsc190501.86121503'),
	'identifierSchemeCodeUnspsc190501.86121504': __('identifierSchemeCodeUnspsc190501.86121504'),
	'identifierSchemeCodeUnspsc190501.86121600': __('identifierSchemeCodeUnspsc190501.86121600'),
	'identifierSchemeCodeUnspsc190501.86121601': __('identifierSchemeCodeUnspsc190501.86121601'),
	'identifierSchemeCodeUnspsc190501.86121602': __('identifierSchemeCodeUnspsc190501.86121602'),
	'identifierSchemeCodeUnspsc190501.86121700': __('identifierSchemeCodeUnspsc190501.86121700'),
	'identifierSchemeCodeUnspsc190501.86121701': __('identifierSchemeCodeUnspsc190501.86121701'),
	'identifierSchemeCodeUnspsc190501.86121702': __('identifierSchemeCodeUnspsc190501.86121702'),
	'identifierSchemeCodeUnspsc190501.86121800': __('identifierSchemeCodeUnspsc190501.86121800'),
	'identifierSchemeCodeUnspsc190501.86121802': __('identifierSchemeCodeUnspsc190501.86121802'),
	'identifierSchemeCodeUnspsc190501.86121803': __('identifierSchemeCodeUnspsc190501.86121803'),
	'identifierSchemeCodeUnspsc190501.86121804': __('identifierSchemeCodeUnspsc190501.86121804'),
	'identifierSchemeCodeUnspsc190501.86130000': __('identifierSchemeCodeUnspsc190501.86130000'),
	'identifierSchemeCodeUnspsc190501.86131500': __('identifierSchemeCodeUnspsc190501.86131500'),
	'identifierSchemeCodeUnspsc190501.86131501': __('identifierSchemeCodeUnspsc190501.86131501'),
	'identifierSchemeCodeUnspsc190501.86131502': __('identifierSchemeCodeUnspsc190501.86131502'),
	'identifierSchemeCodeUnspsc190501.86131503': __('identifierSchemeCodeUnspsc190501.86131503'),
	'identifierSchemeCodeUnspsc190501.86131504': __('identifierSchemeCodeUnspsc190501.86131504'),
	'identifierSchemeCodeUnspsc190501.86131600': __('identifierSchemeCodeUnspsc190501.86131600'),
	'identifierSchemeCodeUnspsc190501.86131601': __('identifierSchemeCodeUnspsc190501.86131601'),
	'identifierSchemeCodeUnspsc190501.86131602': __('identifierSchemeCodeUnspsc190501.86131602'),
	'identifierSchemeCodeUnspsc190501.86131603': __('identifierSchemeCodeUnspsc190501.86131603'),
	'identifierSchemeCodeUnspsc190501.86131700': __('identifierSchemeCodeUnspsc190501.86131700'),
	'identifierSchemeCodeUnspsc190501.86131701': __('identifierSchemeCodeUnspsc190501.86131701'),
	'identifierSchemeCodeUnspsc190501.86131702': __('identifierSchemeCodeUnspsc190501.86131702'),
	'identifierSchemeCodeUnspsc190501.86131703': __('identifierSchemeCodeUnspsc190501.86131703'),
	'identifierSchemeCodeUnspsc190501.86131800': __('identifierSchemeCodeUnspsc190501.86131800'),
	'identifierSchemeCodeUnspsc190501.86131801': __('identifierSchemeCodeUnspsc190501.86131801'),
	'identifierSchemeCodeUnspsc190501.86131802': __('identifierSchemeCodeUnspsc190501.86131802'),
	'identifierSchemeCodeUnspsc190501.86131803': __('identifierSchemeCodeUnspsc190501.86131803'),
	'identifierSchemeCodeUnspsc190501.86131804': __('identifierSchemeCodeUnspsc190501.86131804'),
	'identifierSchemeCodeUnspsc190501.86131900': __('identifierSchemeCodeUnspsc190501.86131900'),
	'identifierSchemeCodeUnspsc190501.86131901': __('identifierSchemeCodeUnspsc190501.86131901'),
	'identifierSchemeCodeUnspsc190501.86131902': __('identifierSchemeCodeUnspsc190501.86131902'),
	'identifierSchemeCodeUnspsc190501.86131903': __('identifierSchemeCodeUnspsc190501.86131903'),
	'identifierSchemeCodeUnspsc190501.86131904': __('identifierSchemeCodeUnspsc190501.86131904'),
	'identifierSchemeCodeUnspsc190501.86132000': __('identifierSchemeCodeUnspsc190501.86132000'),
	'identifierSchemeCodeUnspsc190501.86132001': __('identifierSchemeCodeUnspsc190501.86132001'),
	'identifierSchemeCodeUnspsc190501.86132100': __('identifierSchemeCodeUnspsc190501.86132100'),
	'identifierSchemeCodeUnspsc190501.86132101': __('identifierSchemeCodeUnspsc190501.86132101'),
	'identifierSchemeCodeUnspsc190501.86132102': __('identifierSchemeCodeUnspsc190501.86132102'),
	'identifierSchemeCodeUnspsc190501.86132200': __('identifierSchemeCodeUnspsc190501.86132200'),
	'identifierSchemeCodeUnspsc190501.86132201': __('identifierSchemeCodeUnspsc190501.86132201'),
	'identifierSchemeCodeUnspsc190501.86132202': __('identifierSchemeCodeUnspsc190501.86132202'),
	'identifierSchemeCodeUnspsc190501.86140000': __('identifierSchemeCodeUnspsc190501.86140000'),
	'identifierSchemeCodeUnspsc190501.86141500': __('identifierSchemeCodeUnspsc190501.86141500'),
	'identifierSchemeCodeUnspsc190501.86141501': __('identifierSchemeCodeUnspsc190501.86141501'),
	'identifierSchemeCodeUnspsc190501.86141502': __('identifierSchemeCodeUnspsc190501.86141502'),
	'identifierSchemeCodeUnspsc190501.86141503': __('identifierSchemeCodeUnspsc190501.86141503'),
	'identifierSchemeCodeUnspsc190501.86141504': __('identifierSchemeCodeUnspsc190501.86141504'),
	'identifierSchemeCodeUnspsc190501.86141600': __('identifierSchemeCodeUnspsc190501.86141600'),
	'identifierSchemeCodeUnspsc190501.86141601': __('identifierSchemeCodeUnspsc190501.86141601'),
	'identifierSchemeCodeUnspsc190501.86141602': __('identifierSchemeCodeUnspsc190501.86141602'),
	'identifierSchemeCodeUnspsc190501.86141603': __('identifierSchemeCodeUnspsc190501.86141603'),
	'identifierSchemeCodeUnspsc190501.86141700': __('identifierSchemeCodeUnspsc190501.86141700'),
	'identifierSchemeCodeUnspsc190501.86141701': __('identifierSchemeCodeUnspsc190501.86141701'),
	'identifierSchemeCodeUnspsc190501.86141702': __('identifierSchemeCodeUnspsc190501.86141702'),
	'identifierSchemeCodeUnspsc190501.86141703': __('identifierSchemeCodeUnspsc190501.86141703'),
	'identifierSchemeCodeUnspsc190501.86141704': __('identifierSchemeCodeUnspsc190501.86141704'),
	'identifierSchemeCodeUnspsc190501.90000000': __('identifierSchemeCodeUnspsc190501.90000000'),
	'identifierSchemeCodeUnspsc190501.90100000': __('identifierSchemeCodeUnspsc190501.90100000'),
	'identifierSchemeCodeUnspsc190501.90101500': __('identifierSchemeCodeUnspsc190501.90101500'),
	'identifierSchemeCodeUnspsc190501.90101501': __('identifierSchemeCodeUnspsc190501.90101501'),
	'identifierSchemeCodeUnspsc190501.90101502': __('identifierSchemeCodeUnspsc190501.90101502'),
	'identifierSchemeCodeUnspsc190501.90101503': __('identifierSchemeCodeUnspsc190501.90101503'),
	'identifierSchemeCodeUnspsc190501.90101504': __('identifierSchemeCodeUnspsc190501.90101504'),
	'identifierSchemeCodeUnspsc190501.90101600': __('identifierSchemeCodeUnspsc190501.90101600'),
	'identifierSchemeCodeUnspsc190501.90101601': __('identifierSchemeCodeUnspsc190501.90101601'),
	'identifierSchemeCodeUnspsc190501.90101602': __('identifierSchemeCodeUnspsc190501.90101602'),
	'identifierSchemeCodeUnspsc190501.90101603': __('identifierSchemeCodeUnspsc190501.90101603'),
	'identifierSchemeCodeUnspsc190501.90101604': __('identifierSchemeCodeUnspsc190501.90101604'),
	'identifierSchemeCodeUnspsc190501.90101605': __('identifierSchemeCodeUnspsc190501.90101605'),
	'identifierSchemeCodeUnspsc190501.90101700': __('identifierSchemeCodeUnspsc190501.90101700'),
	'identifierSchemeCodeUnspsc190501.90101701': __('identifierSchemeCodeUnspsc190501.90101701'),
	'identifierSchemeCodeUnspsc190501.90101800': __('identifierSchemeCodeUnspsc190501.90101800'),
	'identifierSchemeCodeUnspsc190501.90101801': __('identifierSchemeCodeUnspsc190501.90101801'),
	'identifierSchemeCodeUnspsc190501.90101802': __('identifierSchemeCodeUnspsc190501.90101802'),
	'identifierSchemeCodeUnspsc190501.90101900': __('identifierSchemeCodeUnspsc190501.90101900'),
	'identifierSchemeCodeUnspsc190501.90101901': __('identifierSchemeCodeUnspsc190501.90101901'),
	'identifierSchemeCodeUnspsc190501.90101902': __('identifierSchemeCodeUnspsc190501.90101902'),
	'identifierSchemeCodeUnspsc190501.90101903': __('identifierSchemeCodeUnspsc190501.90101903'),
	'identifierSchemeCodeUnspsc190501.90101904': __('identifierSchemeCodeUnspsc190501.90101904'),
	'identifierSchemeCodeUnspsc190501.90101905': __('identifierSchemeCodeUnspsc190501.90101905'),
	'identifierSchemeCodeUnspsc190501.90101906': __('identifierSchemeCodeUnspsc190501.90101906'),
	'identifierSchemeCodeUnspsc190501.90101907': __('identifierSchemeCodeUnspsc190501.90101907'),
	'identifierSchemeCodeUnspsc190501.90101908': __('identifierSchemeCodeUnspsc190501.90101908'),
	'identifierSchemeCodeUnspsc190501.90110000': __('identifierSchemeCodeUnspsc190501.90110000'),
	'identifierSchemeCodeUnspsc190501.90111500': __('identifierSchemeCodeUnspsc190501.90111500'),
	'identifierSchemeCodeUnspsc190501.90111501': __('identifierSchemeCodeUnspsc190501.90111501'),
	'identifierSchemeCodeUnspsc190501.90111502': __('identifierSchemeCodeUnspsc190501.90111502'),
	'identifierSchemeCodeUnspsc190501.90111503': __('identifierSchemeCodeUnspsc190501.90111503'),
	'identifierSchemeCodeUnspsc190501.90111504': __('identifierSchemeCodeUnspsc190501.90111504'),
	'identifierSchemeCodeUnspsc190501.90111600': __('identifierSchemeCodeUnspsc190501.90111600'),
	'identifierSchemeCodeUnspsc190501.90111601': __('identifierSchemeCodeUnspsc190501.90111601'),
	'identifierSchemeCodeUnspsc190501.90111602': __('identifierSchemeCodeUnspsc190501.90111602'),
	'identifierSchemeCodeUnspsc190501.90111603': __('identifierSchemeCodeUnspsc190501.90111603'),
	'identifierSchemeCodeUnspsc190501.90111604': __('identifierSchemeCodeUnspsc190501.90111604'),
	'identifierSchemeCodeUnspsc190501.90111700': __('identifierSchemeCodeUnspsc190501.90111700'),
	'identifierSchemeCodeUnspsc190501.90111701': __('identifierSchemeCodeUnspsc190501.90111701'),
	'identifierSchemeCodeUnspsc190501.90111702': __('identifierSchemeCodeUnspsc190501.90111702'),
	'identifierSchemeCodeUnspsc190501.90111703': __('identifierSchemeCodeUnspsc190501.90111703'),
	'identifierSchemeCodeUnspsc190501.90111800': __('identifierSchemeCodeUnspsc190501.90111800'),
	'identifierSchemeCodeUnspsc190501.90111801': __('identifierSchemeCodeUnspsc190501.90111801'),
	'identifierSchemeCodeUnspsc190501.90111802': __('identifierSchemeCodeUnspsc190501.90111802'),
	'identifierSchemeCodeUnspsc190501.90111803': __('identifierSchemeCodeUnspsc190501.90111803'),
	'identifierSchemeCodeUnspsc190501.90111900': __('identifierSchemeCodeUnspsc190501.90111900'),
	'identifierSchemeCodeUnspsc190501.90111901': __('identifierSchemeCodeUnspsc190501.90111901'),
	'identifierSchemeCodeUnspsc190501.90111902': __('identifierSchemeCodeUnspsc190501.90111902'),
	'identifierSchemeCodeUnspsc190501.90120000': __('identifierSchemeCodeUnspsc190501.90120000'),
	'identifierSchemeCodeUnspsc190501.90121500': __('identifierSchemeCodeUnspsc190501.90121500'),
	'identifierSchemeCodeUnspsc190501.90121501': __('identifierSchemeCodeUnspsc190501.90121501'),
	'identifierSchemeCodeUnspsc190501.90121502': __('identifierSchemeCodeUnspsc190501.90121502'),
	'identifierSchemeCodeUnspsc190501.90121503': __('identifierSchemeCodeUnspsc190501.90121503'),
	'identifierSchemeCodeUnspsc190501.90121600': __('identifierSchemeCodeUnspsc190501.90121600'),
	'identifierSchemeCodeUnspsc190501.90121601': __('identifierSchemeCodeUnspsc190501.90121601'),
	'identifierSchemeCodeUnspsc190501.90121602': __('identifierSchemeCodeUnspsc190501.90121602'),
	'identifierSchemeCodeUnspsc190501.90121603': __('identifierSchemeCodeUnspsc190501.90121603'),
	'identifierSchemeCodeUnspsc190501.90121700': __('identifierSchemeCodeUnspsc190501.90121700'),
	'identifierSchemeCodeUnspsc190501.90121701': __('identifierSchemeCodeUnspsc190501.90121701'),
	'identifierSchemeCodeUnspsc190501.90121702': __('identifierSchemeCodeUnspsc190501.90121702'),
	'identifierSchemeCodeUnspsc190501.90121800': __('identifierSchemeCodeUnspsc190501.90121800'),
	'identifierSchemeCodeUnspsc190501.90121801': __('identifierSchemeCodeUnspsc190501.90121801'),
	'identifierSchemeCodeUnspsc190501.90130000': __('identifierSchemeCodeUnspsc190501.90130000'),
	'identifierSchemeCodeUnspsc190501.90131500': __('identifierSchemeCodeUnspsc190501.90131500'),
	'identifierSchemeCodeUnspsc190501.90131501': __('identifierSchemeCodeUnspsc190501.90131501'),
	'identifierSchemeCodeUnspsc190501.90131502': __('identifierSchemeCodeUnspsc190501.90131502'),
	'identifierSchemeCodeUnspsc190501.90131503': __('identifierSchemeCodeUnspsc190501.90131503'),
	'identifierSchemeCodeUnspsc190501.90131504': __('identifierSchemeCodeUnspsc190501.90131504'),
	'identifierSchemeCodeUnspsc190501.90131600': __('identifierSchemeCodeUnspsc190501.90131600'),
	'identifierSchemeCodeUnspsc190501.90131601': __('identifierSchemeCodeUnspsc190501.90131601'),
	'identifierSchemeCodeUnspsc190501.90131602': __('identifierSchemeCodeUnspsc190501.90131602'),
	'identifierSchemeCodeUnspsc190501.90131603': __('identifierSchemeCodeUnspsc190501.90131603'),
	'identifierSchemeCodeUnspsc190501.90140000': __('identifierSchemeCodeUnspsc190501.90140000'),
	'identifierSchemeCodeUnspsc190501.90141500': __('identifierSchemeCodeUnspsc190501.90141500'),
	'identifierSchemeCodeUnspsc190501.90141501': __('identifierSchemeCodeUnspsc190501.90141501'),
	'identifierSchemeCodeUnspsc190501.90141502': __('identifierSchemeCodeUnspsc190501.90141502'),
	'identifierSchemeCodeUnspsc190501.90141503': __('identifierSchemeCodeUnspsc190501.90141503'),
	'identifierSchemeCodeUnspsc190501.90141600': __('identifierSchemeCodeUnspsc190501.90141600'),
	'identifierSchemeCodeUnspsc190501.90141601': __('identifierSchemeCodeUnspsc190501.90141601'),
	'identifierSchemeCodeUnspsc190501.90141602': __('identifierSchemeCodeUnspsc190501.90141602'),
	'identifierSchemeCodeUnspsc190501.90141603': __('identifierSchemeCodeUnspsc190501.90141603'),
	'identifierSchemeCodeUnspsc190501.90141700': __('identifierSchemeCodeUnspsc190501.90141700'),
	'identifierSchemeCodeUnspsc190501.90141701': __('identifierSchemeCodeUnspsc190501.90141701'),
	'identifierSchemeCodeUnspsc190501.90141702': __('identifierSchemeCodeUnspsc190501.90141702'),
	'identifierSchemeCodeUnspsc190501.90141703': __('identifierSchemeCodeUnspsc190501.90141703'),
	'identifierSchemeCodeUnspsc190501.90150000': __('identifierSchemeCodeUnspsc190501.90150000'),
	'identifierSchemeCodeUnspsc190501.90151500': __('identifierSchemeCodeUnspsc190501.90151500'),
	'identifierSchemeCodeUnspsc190501.90151501': __('identifierSchemeCodeUnspsc190501.90151501'),
	'identifierSchemeCodeUnspsc190501.90151502': __('identifierSchemeCodeUnspsc190501.90151502'),
	'identifierSchemeCodeUnspsc190501.90151503': __('identifierSchemeCodeUnspsc190501.90151503'),
	'identifierSchemeCodeUnspsc190501.90151600': __('identifierSchemeCodeUnspsc190501.90151600'),
	'identifierSchemeCodeUnspsc190501.90151601': __('identifierSchemeCodeUnspsc190501.90151601'),
	'identifierSchemeCodeUnspsc190501.90151602': __('identifierSchemeCodeUnspsc190501.90151602'),
	'identifierSchemeCodeUnspsc190501.90151603': __('identifierSchemeCodeUnspsc190501.90151603'),
	'identifierSchemeCodeUnspsc190501.90151700': __('identifierSchemeCodeUnspsc190501.90151700'),
	'identifierSchemeCodeUnspsc190501.90151701': __('identifierSchemeCodeUnspsc190501.90151701'),
	'identifierSchemeCodeUnspsc190501.90151702': __('identifierSchemeCodeUnspsc190501.90151702'),
	'identifierSchemeCodeUnspsc190501.90151703': __('identifierSchemeCodeUnspsc190501.90151703'),
	'identifierSchemeCodeUnspsc190501.90151800': __('identifierSchemeCodeUnspsc190501.90151800'),
	'identifierSchemeCodeUnspsc190501.90151801': __('identifierSchemeCodeUnspsc190501.90151801'),
	'identifierSchemeCodeUnspsc190501.90151802': __('identifierSchemeCodeUnspsc190501.90151802'),
	'identifierSchemeCodeUnspsc190501.90151803': __('identifierSchemeCodeUnspsc190501.90151803'),
	'identifierSchemeCodeUnspsc190501.90151900': __('identifierSchemeCodeUnspsc190501.90151900'),
	'identifierSchemeCodeUnspsc190501.90151901': __('identifierSchemeCodeUnspsc190501.90151901'),
	'identifierSchemeCodeUnspsc190501.90151902': __('identifierSchemeCodeUnspsc190501.90151902'),
	'identifierSchemeCodeUnspsc190501.90151903': __('identifierSchemeCodeUnspsc190501.90151903'),
	'identifierSchemeCodeUnspsc190501.90152000': __('identifierSchemeCodeUnspsc190501.90152000'),
	'identifierSchemeCodeUnspsc190501.90152001': __('identifierSchemeCodeUnspsc190501.90152001'),
	'identifierSchemeCodeUnspsc190501.90152002': __('identifierSchemeCodeUnspsc190501.90152002'),
	'identifierSchemeCodeUnspsc190501.90152100': __('identifierSchemeCodeUnspsc190501.90152100'),
	'identifierSchemeCodeUnspsc190501.90152101': __('identifierSchemeCodeUnspsc190501.90152101'),
	'identifierSchemeCodeUnspsc190501.91000000': __('identifierSchemeCodeUnspsc190501.91000000'),
	'identifierSchemeCodeUnspsc190501.91100000': __('identifierSchemeCodeUnspsc190501.91100000'),
	'identifierSchemeCodeUnspsc190501.91101500': __('identifierSchemeCodeUnspsc190501.91101500'),
	'identifierSchemeCodeUnspsc190501.91101501': __('identifierSchemeCodeUnspsc190501.91101501'),
	'identifierSchemeCodeUnspsc190501.91101502': __('identifierSchemeCodeUnspsc190501.91101502'),
	'identifierSchemeCodeUnspsc190501.91101503': __('identifierSchemeCodeUnspsc190501.91101503'),
	'identifierSchemeCodeUnspsc190501.91101504': __('identifierSchemeCodeUnspsc190501.91101504'),
	'identifierSchemeCodeUnspsc190501.91101505': __('identifierSchemeCodeUnspsc190501.91101505'),
	'identifierSchemeCodeUnspsc190501.91101600': __('identifierSchemeCodeUnspsc190501.91101600'),
	'identifierSchemeCodeUnspsc190501.91101601': __('identifierSchemeCodeUnspsc190501.91101601'),
	'identifierSchemeCodeUnspsc190501.91101602': __('identifierSchemeCodeUnspsc190501.91101602'),
	'identifierSchemeCodeUnspsc190501.91101603': __('identifierSchemeCodeUnspsc190501.91101603'),
	'identifierSchemeCodeUnspsc190501.91101604': __('identifierSchemeCodeUnspsc190501.91101604'),
	'identifierSchemeCodeUnspsc190501.91101605': __('identifierSchemeCodeUnspsc190501.91101605'),
	'identifierSchemeCodeUnspsc190501.91101606': __('identifierSchemeCodeUnspsc190501.91101606'),
	'identifierSchemeCodeUnspsc190501.91101700': __('identifierSchemeCodeUnspsc190501.91101700'),
	'identifierSchemeCodeUnspsc190501.91101701': __('identifierSchemeCodeUnspsc190501.91101701'),
	'identifierSchemeCodeUnspsc190501.91101702': __('identifierSchemeCodeUnspsc190501.91101702'),
	'identifierSchemeCodeUnspsc190501.91101800': __('identifierSchemeCodeUnspsc190501.91101800'),
	'identifierSchemeCodeUnspsc190501.91101801': __('identifierSchemeCodeUnspsc190501.91101801'),
	'identifierSchemeCodeUnspsc190501.91101802': __('identifierSchemeCodeUnspsc190501.91101802'),
	'identifierSchemeCodeUnspsc190501.91101803': __('identifierSchemeCodeUnspsc190501.91101803'),
	'identifierSchemeCodeUnspsc190501.91101900': __('identifierSchemeCodeUnspsc190501.91101900'),
	'identifierSchemeCodeUnspsc190501.91101901': __('identifierSchemeCodeUnspsc190501.91101901'),
	'identifierSchemeCodeUnspsc190501.91101902': __('identifierSchemeCodeUnspsc190501.91101902'),
	'identifierSchemeCodeUnspsc190501.91101903': __('identifierSchemeCodeUnspsc190501.91101903'),
	'identifierSchemeCodeUnspsc190501.91101904': __('identifierSchemeCodeUnspsc190501.91101904'),
	'identifierSchemeCodeUnspsc190501.91110000': __('identifierSchemeCodeUnspsc190501.91110000'),
	'identifierSchemeCodeUnspsc190501.91111500': __('identifierSchemeCodeUnspsc190501.91111500'),
	'identifierSchemeCodeUnspsc190501.91111501': __('identifierSchemeCodeUnspsc190501.91111501'),
	'identifierSchemeCodeUnspsc190501.91111502': __('identifierSchemeCodeUnspsc190501.91111502'),
	'identifierSchemeCodeUnspsc190501.91111503': __('identifierSchemeCodeUnspsc190501.91111503'),
	'identifierSchemeCodeUnspsc190501.91111504': __('identifierSchemeCodeUnspsc190501.91111504'),
	'identifierSchemeCodeUnspsc190501.91111600': __('identifierSchemeCodeUnspsc190501.91111600'),
	'identifierSchemeCodeUnspsc190501.91111601': __('identifierSchemeCodeUnspsc190501.91111601'),
	'identifierSchemeCodeUnspsc190501.91111602': __('identifierSchemeCodeUnspsc190501.91111602'),
	'identifierSchemeCodeUnspsc190501.91111603': __('identifierSchemeCodeUnspsc190501.91111603'),
	'identifierSchemeCodeUnspsc190501.91111700': __('identifierSchemeCodeUnspsc190501.91111700'),
	'identifierSchemeCodeUnspsc190501.91111701': __('identifierSchemeCodeUnspsc190501.91111701'),
	'identifierSchemeCodeUnspsc190501.91111702': __('identifierSchemeCodeUnspsc190501.91111702'),
	'identifierSchemeCodeUnspsc190501.91111703': __('identifierSchemeCodeUnspsc190501.91111703'),
	'identifierSchemeCodeUnspsc190501.91111800': __('identifierSchemeCodeUnspsc190501.91111800'),
	'identifierSchemeCodeUnspsc190501.91111801': __('identifierSchemeCodeUnspsc190501.91111801'),
	'identifierSchemeCodeUnspsc190501.91111802': __('identifierSchemeCodeUnspsc190501.91111802'),
	'identifierSchemeCodeUnspsc190501.91111803': __('identifierSchemeCodeUnspsc190501.91111803'),
	'identifierSchemeCodeUnspsc190501.91111804': __('identifierSchemeCodeUnspsc190501.91111804'),
	'identifierSchemeCodeUnspsc190501.91111900': __('identifierSchemeCodeUnspsc190501.91111900'),
	'identifierSchemeCodeUnspsc190501.91111901': __('identifierSchemeCodeUnspsc190501.91111901'),
	'identifierSchemeCodeUnspsc190501.91111902': __('identifierSchemeCodeUnspsc190501.91111902'),
	'identifierSchemeCodeUnspsc190501.91111903': __('identifierSchemeCodeUnspsc190501.91111903'),
	'identifierSchemeCodeUnspsc190501.91111904': __('identifierSchemeCodeUnspsc190501.91111904'),
	'identifierSchemeCodeUnspsc190501.92000000': __('identifierSchemeCodeUnspsc190501.92000000'),
	'identifierSchemeCodeUnspsc190501.92100000': __('identifierSchemeCodeUnspsc190501.92100000'),
	'identifierSchemeCodeUnspsc190501.92101500': __('identifierSchemeCodeUnspsc190501.92101500'),
	'identifierSchemeCodeUnspsc190501.92101501': __('identifierSchemeCodeUnspsc190501.92101501'),
	'identifierSchemeCodeUnspsc190501.92101502': __('identifierSchemeCodeUnspsc190501.92101502'),
	'identifierSchemeCodeUnspsc190501.92101503': __('identifierSchemeCodeUnspsc190501.92101503'),
	'identifierSchemeCodeUnspsc190501.92101504': __('identifierSchemeCodeUnspsc190501.92101504'),
	'identifierSchemeCodeUnspsc190501.92101505': __('identifierSchemeCodeUnspsc190501.92101505'),
	'identifierSchemeCodeUnspsc190501.92101600': __('identifierSchemeCodeUnspsc190501.92101600'),
	'identifierSchemeCodeUnspsc190501.92101601': __('identifierSchemeCodeUnspsc190501.92101601'),
	'identifierSchemeCodeUnspsc190501.92101602': __('identifierSchemeCodeUnspsc190501.92101602'),
	'identifierSchemeCodeUnspsc190501.92101603': __('identifierSchemeCodeUnspsc190501.92101603'),
	'identifierSchemeCodeUnspsc190501.92101604': __('identifierSchemeCodeUnspsc190501.92101604'),
	'identifierSchemeCodeUnspsc190501.92101700': __('identifierSchemeCodeUnspsc190501.92101700'),
	'identifierSchemeCodeUnspsc190501.92101701': __('identifierSchemeCodeUnspsc190501.92101701'),
	'identifierSchemeCodeUnspsc190501.92101702': __('identifierSchemeCodeUnspsc190501.92101702'),
	'identifierSchemeCodeUnspsc190501.92101703': __('identifierSchemeCodeUnspsc190501.92101703'),
	'identifierSchemeCodeUnspsc190501.92101704': __('identifierSchemeCodeUnspsc190501.92101704'),
	'identifierSchemeCodeUnspsc190501.92101800': __('identifierSchemeCodeUnspsc190501.92101800'),
	'identifierSchemeCodeUnspsc190501.92101801': __('identifierSchemeCodeUnspsc190501.92101801'),
	'identifierSchemeCodeUnspsc190501.92101802': __('identifierSchemeCodeUnspsc190501.92101802'),
	'identifierSchemeCodeUnspsc190501.92101803': __('identifierSchemeCodeUnspsc190501.92101803'),
	'identifierSchemeCodeUnspsc190501.92101804': __('identifierSchemeCodeUnspsc190501.92101804'),
	'identifierSchemeCodeUnspsc190501.92101805': __('identifierSchemeCodeUnspsc190501.92101805'),
	'identifierSchemeCodeUnspsc190501.92101900': __('identifierSchemeCodeUnspsc190501.92101900'),
	'identifierSchemeCodeUnspsc190501.92101901': __('identifierSchemeCodeUnspsc190501.92101901'),
	'identifierSchemeCodeUnspsc190501.92101902': __('identifierSchemeCodeUnspsc190501.92101902'),
	'identifierSchemeCodeUnspsc190501.92101903': __('identifierSchemeCodeUnspsc190501.92101903'),
	'identifierSchemeCodeUnspsc190501.92101904': __('identifierSchemeCodeUnspsc190501.92101904'),
	'identifierSchemeCodeUnspsc190501.92110000': __('identifierSchemeCodeUnspsc190501.92110000'),
	'identifierSchemeCodeUnspsc190501.92111500': __('identifierSchemeCodeUnspsc190501.92111500'),
	'identifierSchemeCodeUnspsc190501.92111501': __('identifierSchemeCodeUnspsc190501.92111501'),
	'identifierSchemeCodeUnspsc190501.92111502': __('identifierSchemeCodeUnspsc190501.92111502'),
	'identifierSchemeCodeUnspsc190501.92111503': __('identifierSchemeCodeUnspsc190501.92111503'),
	'identifierSchemeCodeUnspsc190501.92111504': __('identifierSchemeCodeUnspsc190501.92111504'),
	'identifierSchemeCodeUnspsc190501.92111505': __('identifierSchemeCodeUnspsc190501.92111505'),
	'identifierSchemeCodeUnspsc190501.92111506': __('identifierSchemeCodeUnspsc190501.92111506'),
	'identifierSchemeCodeUnspsc190501.92111507': __('identifierSchemeCodeUnspsc190501.92111507'),
	'identifierSchemeCodeUnspsc190501.92111600': __('identifierSchemeCodeUnspsc190501.92111600'),
	'identifierSchemeCodeUnspsc190501.92111601': __('identifierSchemeCodeUnspsc190501.92111601'),
	'identifierSchemeCodeUnspsc190501.92111602': __('identifierSchemeCodeUnspsc190501.92111602'),
	'identifierSchemeCodeUnspsc190501.92111603': __('identifierSchemeCodeUnspsc190501.92111603'),
	'identifierSchemeCodeUnspsc190501.92111604': __('identifierSchemeCodeUnspsc190501.92111604'),
	'identifierSchemeCodeUnspsc190501.92111605': __('identifierSchemeCodeUnspsc190501.92111605'),
	'identifierSchemeCodeUnspsc190501.92111606': __('identifierSchemeCodeUnspsc190501.92111606'),
	'identifierSchemeCodeUnspsc190501.92111607': __('identifierSchemeCodeUnspsc190501.92111607'),
	'identifierSchemeCodeUnspsc190501.92111608': __('identifierSchemeCodeUnspsc190501.92111608'),
	'identifierSchemeCodeUnspsc190501.92111609': __('identifierSchemeCodeUnspsc190501.92111609'),
	'identifierSchemeCodeUnspsc190501.92111610': __('identifierSchemeCodeUnspsc190501.92111610'),
	'identifierSchemeCodeUnspsc190501.92111611': __('identifierSchemeCodeUnspsc190501.92111611'),
	'identifierSchemeCodeUnspsc190501.92111612': __('identifierSchemeCodeUnspsc190501.92111612'),
	'identifierSchemeCodeUnspsc190501.92111613': __('identifierSchemeCodeUnspsc190501.92111613'),
	'identifierSchemeCodeUnspsc190501.92111614': __('identifierSchemeCodeUnspsc190501.92111614'),
	'identifierSchemeCodeUnspsc190501.92111615': __('identifierSchemeCodeUnspsc190501.92111615'),
	'identifierSchemeCodeUnspsc190501.92111616': __('identifierSchemeCodeUnspsc190501.92111616'),
	'identifierSchemeCodeUnspsc190501.92111700': __('identifierSchemeCodeUnspsc190501.92111700'),
	'identifierSchemeCodeUnspsc190501.92111701': __('identifierSchemeCodeUnspsc190501.92111701'),
	'identifierSchemeCodeUnspsc190501.92111702': __('identifierSchemeCodeUnspsc190501.92111702'),
	'identifierSchemeCodeUnspsc190501.92111703': __('identifierSchemeCodeUnspsc190501.92111703'),
	'identifierSchemeCodeUnspsc190501.92111704': __('identifierSchemeCodeUnspsc190501.92111704'),
	'identifierSchemeCodeUnspsc190501.92111705': __('identifierSchemeCodeUnspsc190501.92111705'),
	'identifierSchemeCodeUnspsc190501.92111706': __('identifierSchemeCodeUnspsc190501.92111706'),
	'identifierSchemeCodeUnspsc190501.92111707': __('identifierSchemeCodeUnspsc190501.92111707'),
	'identifierSchemeCodeUnspsc190501.92111708': __('identifierSchemeCodeUnspsc190501.92111708'),
	'identifierSchemeCodeUnspsc190501.92111800': __('identifierSchemeCodeUnspsc190501.92111800'),
	'identifierSchemeCodeUnspsc190501.92111801': __('identifierSchemeCodeUnspsc190501.92111801'),
	'identifierSchemeCodeUnspsc190501.92111802': __('identifierSchemeCodeUnspsc190501.92111802'),
	'identifierSchemeCodeUnspsc190501.92111803': __('identifierSchemeCodeUnspsc190501.92111803'),
	'identifierSchemeCodeUnspsc190501.92111804': __('identifierSchemeCodeUnspsc190501.92111804'),
	'identifierSchemeCodeUnspsc190501.92111805': __('identifierSchemeCodeUnspsc190501.92111805'),
	'identifierSchemeCodeUnspsc190501.92111806': __('identifierSchemeCodeUnspsc190501.92111806'),
	'identifierSchemeCodeUnspsc190501.92111807': __('identifierSchemeCodeUnspsc190501.92111807'),
	'identifierSchemeCodeUnspsc190501.92111808': __('identifierSchemeCodeUnspsc190501.92111808'),
	'identifierSchemeCodeUnspsc190501.92111809': __('identifierSchemeCodeUnspsc190501.92111809'),
	'identifierSchemeCodeUnspsc190501.92111810': __('identifierSchemeCodeUnspsc190501.92111810'),
	'identifierSchemeCodeUnspsc190501.92111900': __('identifierSchemeCodeUnspsc190501.92111900'),
	'identifierSchemeCodeUnspsc190501.92111901': __('identifierSchemeCodeUnspsc190501.92111901'),
	'identifierSchemeCodeUnspsc190501.92111902': __('identifierSchemeCodeUnspsc190501.92111902'),
	'identifierSchemeCodeUnspsc190501.92111903': __('identifierSchemeCodeUnspsc190501.92111903'),
	'identifierSchemeCodeUnspsc190501.92111904': __('identifierSchemeCodeUnspsc190501.92111904'),
	'identifierSchemeCodeUnspsc190501.92111905': __('identifierSchemeCodeUnspsc190501.92111905'),
	'identifierSchemeCodeUnspsc190501.92112000': __('identifierSchemeCodeUnspsc190501.92112000'),
	'identifierSchemeCodeUnspsc190501.92112001': __('identifierSchemeCodeUnspsc190501.92112001'),
	'identifierSchemeCodeUnspsc190501.92112002': __('identifierSchemeCodeUnspsc190501.92112002'),
	'identifierSchemeCodeUnspsc190501.92112003': __('identifierSchemeCodeUnspsc190501.92112003'),
	'identifierSchemeCodeUnspsc190501.92112004': __('identifierSchemeCodeUnspsc190501.92112004'),
	'identifierSchemeCodeUnspsc190501.92112005': __('identifierSchemeCodeUnspsc190501.92112005'),
	'identifierSchemeCodeUnspsc190501.92112100': __('identifierSchemeCodeUnspsc190501.92112100'),
	'identifierSchemeCodeUnspsc190501.92112101': __('identifierSchemeCodeUnspsc190501.92112101'),
	'identifierSchemeCodeUnspsc190501.92112102': __('identifierSchemeCodeUnspsc190501.92112102'),
	'identifierSchemeCodeUnspsc190501.92112103': __('identifierSchemeCodeUnspsc190501.92112103'),
	'identifierSchemeCodeUnspsc190501.92112200': __('identifierSchemeCodeUnspsc190501.92112200'),
	'identifierSchemeCodeUnspsc190501.92112201': __('identifierSchemeCodeUnspsc190501.92112201'),
	'identifierSchemeCodeUnspsc190501.92112202': __('identifierSchemeCodeUnspsc190501.92112202'),
	'identifierSchemeCodeUnspsc190501.92112203': __('identifierSchemeCodeUnspsc190501.92112203'),
	'identifierSchemeCodeUnspsc190501.92112204': __('identifierSchemeCodeUnspsc190501.92112204'),
	'identifierSchemeCodeUnspsc190501.92112205': __('identifierSchemeCodeUnspsc190501.92112205'),
	'identifierSchemeCodeUnspsc190501.92112206': __('identifierSchemeCodeUnspsc190501.92112206'),
	'identifierSchemeCodeUnspsc190501.92112207': __('identifierSchemeCodeUnspsc190501.92112207'),
	'identifierSchemeCodeUnspsc190501.92112300': __('identifierSchemeCodeUnspsc190501.92112300'),
	'identifierSchemeCodeUnspsc190501.92112301': __('identifierSchemeCodeUnspsc190501.92112301'),
	'identifierSchemeCodeUnspsc190501.92112302': __('identifierSchemeCodeUnspsc190501.92112302'),
	'identifierSchemeCodeUnspsc190501.92112303': __('identifierSchemeCodeUnspsc190501.92112303'),
	'identifierSchemeCodeUnspsc190501.92112400': __('identifierSchemeCodeUnspsc190501.92112400'),
	'identifierSchemeCodeUnspsc190501.92112401': __('identifierSchemeCodeUnspsc190501.92112401'),
	'identifierSchemeCodeUnspsc190501.92112402': __('identifierSchemeCodeUnspsc190501.92112402'),
	'identifierSchemeCodeUnspsc190501.92112403': __('identifierSchemeCodeUnspsc190501.92112403'),
	'identifierSchemeCodeUnspsc190501.92112404': __('identifierSchemeCodeUnspsc190501.92112404'),
	'identifierSchemeCodeUnspsc190501.92112405': __('identifierSchemeCodeUnspsc190501.92112405'),
	'identifierSchemeCodeUnspsc190501.92120000': __('identifierSchemeCodeUnspsc190501.92120000'),
	'identifierSchemeCodeUnspsc190501.92121500': __('identifierSchemeCodeUnspsc190501.92121500'),
	'identifierSchemeCodeUnspsc190501.92121502': __('identifierSchemeCodeUnspsc190501.92121502'),
	'identifierSchemeCodeUnspsc190501.92121503': __('identifierSchemeCodeUnspsc190501.92121503'),
	'identifierSchemeCodeUnspsc190501.92121504': __('identifierSchemeCodeUnspsc190501.92121504'),
	'identifierSchemeCodeUnspsc190501.92121600': __('identifierSchemeCodeUnspsc190501.92121600'),
	'identifierSchemeCodeUnspsc190501.92121601': __('identifierSchemeCodeUnspsc190501.92121601'),
	'identifierSchemeCodeUnspsc190501.92121602': __('identifierSchemeCodeUnspsc190501.92121602'),
	'identifierSchemeCodeUnspsc190501.92121603': __('identifierSchemeCodeUnspsc190501.92121603'),
	'identifierSchemeCodeUnspsc190501.92121604': __('identifierSchemeCodeUnspsc190501.92121604'),
	'identifierSchemeCodeUnspsc190501.92121700': __('identifierSchemeCodeUnspsc190501.92121700'),
	'identifierSchemeCodeUnspsc190501.92121701': __('identifierSchemeCodeUnspsc190501.92121701'),
	'identifierSchemeCodeUnspsc190501.92121702': __('identifierSchemeCodeUnspsc190501.92121702'),
	'identifierSchemeCodeUnspsc190501.92121703': __('identifierSchemeCodeUnspsc190501.92121703'),
	'identifierSchemeCodeUnspsc190501.92121704': __('identifierSchemeCodeUnspsc190501.92121704'),
	'identifierSchemeCodeUnspsc190501.92121800': __('identifierSchemeCodeUnspsc190501.92121800'),
	'identifierSchemeCodeUnspsc190501.92121801': __('identifierSchemeCodeUnspsc190501.92121801'),
	'identifierSchemeCodeUnspsc190501.92121802': __('identifierSchemeCodeUnspsc190501.92121802'),
	'identifierSchemeCodeUnspsc190501.92121803': __('identifierSchemeCodeUnspsc190501.92121803'),
	'identifierSchemeCodeUnspsc190501.92121900': __('identifierSchemeCodeUnspsc190501.92121900'),
	'identifierSchemeCodeUnspsc190501.92121901': __('identifierSchemeCodeUnspsc190501.92121901'),
	'identifierSchemeCodeUnspsc190501.93000000': __('identifierSchemeCodeUnspsc190501.93000000'),
	'identifierSchemeCodeUnspsc190501.93100000': __('identifierSchemeCodeUnspsc190501.93100000'),
	'identifierSchemeCodeUnspsc190501.93101500': __('identifierSchemeCodeUnspsc190501.93101500'),
	'identifierSchemeCodeUnspsc190501.93101501': __('identifierSchemeCodeUnspsc190501.93101501'),
	'identifierSchemeCodeUnspsc190501.93101502': __('identifierSchemeCodeUnspsc190501.93101502'),
	'identifierSchemeCodeUnspsc190501.93101503': __('identifierSchemeCodeUnspsc190501.93101503'),
	'identifierSchemeCodeUnspsc190501.93101504': __('identifierSchemeCodeUnspsc190501.93101504'),
	'identifierSchemeCodeUnspsc190501.93101505': __('identifierSchemeCodeUnspsc190501.93101505'),
	'identifierSchemeCodeUnspsc190501.93101506': __('identifierSchemeCodeUnspsc190501.93101506'),
	'identifierSchemeCodeUnspsc190501.93101600': __('identifierSchemeCodeUnspsc190501.93101600'),
	'identifierSchemeCodeUnspsc190501.93101601': __('identifierSchemeCodeUnspsc190501.93101601'),
	'identifierSchemeCodeUnspsc190501.93101602': __('identifierSchemeCodeUnspsc190501.93101602'),
	'identifierSchemeCodeUnspsc190501.93101603': __('identifierSchemeCodeUnspsc190501.93101603'),
	'identifierSchemeCodeUnspsc190501.93101604': __('identifierSchemeCodeUnspsc190501.93101604'),
	'identifierSchemeCodeUnspsc190501.93101605': __('identifierSchemeCodeUnspsc190501.93101605'),
	'identifierSchemeCodeUnspsc190501.93101606': __('identifierSchemeCodeUnspsc190501.93101606'),
	'identifierSchemeCodeUnspsc190501.93101607': __('identifierSchemeCodeUnspsc190501.93101607'),
	'identifierSchemeCodeUnspsc190501.93101608': __('identifierSchemeCodeUnspsc190501.93101608'),
	'identifierSchemeCodeUnspsc190501.93101700': __('identifierSchemeCodeUnspsc190501.93101700'),
	'identifierSchemeCodeUnspsc190501.93101701': __('identifierSchemeCodeUnspsc190501.93101701'),
	'identifierSchemeCodeUnspsc190501.93101702': __('identifierSchemeCodeUnspsc190501.93101702'),
	'identifierSchemeCodeUnspsc190501.93101703': __('identifierSchemeCodeUnspsc190501.93101703'),
	'identifierSchemeCodeUnspsc190501.93101704': __('identifierSchemeCodeUnspsc190501.93101704'),
	'identifierSchemeCodeUnspsc190501.93101705': __('identifierSchemeCodeUnspsc190501.93101705'),
	'identifierSchemeCodeUnspsc190501.93101706': __('identifierSchemeCodeUnspsc190501.93101706'),
	'identifierSchemeCodeUnspsc190501.93101707': __('identifierSchemeCodeUnspsc190501.93101707'),
	'identifierSchemeCodeUnspsc190501.93110000': __('identifierSchemeCodeUnspsc190501.93110000'),
	'identifierSchemeCodeUnspsc190501.93111500': __('identifierSchemeCodeUnspsc190501.93111500'),
	'identifierSchemeCodeUnspsc190501.93111501': __('identifierSchemeCodeUnspsc190501.93111501'),
	'identifierSchemeCodeUnspsc190501.93111502': __('identifierSchemeCodeUnspsc190501.93111502'),
	'identifierSchemeCodeUnspsc190501.93111503': __('identifierSchemeCodeUnspsc190501.93111503'),
	'identifierSchemeCodeUnspsc190501.93111504': __('identifierSchemeCodeUnspsc190501.93111504'),
	'identifierSchemeCodeUnspsc190501.93111505': __('identifierSchemeCodeUnspsc190501.93111505'),
	'identifierSchemeCodeUnspsc190501.93111506': __('identifierSchemeCodeUnspsc190501.93111506'),
	'identifierSchemeCodeUnspsc190501.93111507': __('identifierSchemeCodeUnspsc190501.93111507'),
	'identifierSchemeCodeUnspsc190501.93111600': __('identifierSchemeCodeUnspsc190501.93111600'),
	'identifierSchemeCodeUnspsc190501.93111601': __('identifierSchemeCodeUnspsc190501.93111601'),
	'identifierSchemeCodeUnspsc190501.93111602': __('identifierSchemeCodeUnspsc190501.93111602'),
	'identifierSchemeCodeUnspsc190501.93111603': __('identifierSchemeCodeUnspsc190501.93111603'),
	'identifierSchemeCodeUnspsc190501.93111604': __('identifierSchemeCodeUnspsc190501.93111604'),
	'identifierSchemeCodeUnspsc190501.93111605': __('identifierSchemeCodeUnspsc190501.93111605'),
	'identifierSchemeCodeUnspsc190501.93111606': __('identifierSchemeCodeUnspsc190501.93111606'),
	'identifierSchemeCodeUnspsc190501.93111607': __('identifierSchemeCodeUnspsc190501.93111607'),
	'identifierSchemeCodeUnspsc190501.93111608': __('identifierSchemeCodeUnspsc190501.93111608'),
	'identifierSchemeCodeUnspsc190501.93120000': __('identifierSchemeCodeUnspsc190501.93120000'),
	'identifierSchemeCodeUnspsc190501.93121500': __('identifierSchemeCodeUnspsc190501.93121500'),
	'identifierSchemeCodeUnspsc190501.93121501': __('identifierSchemeCodeUnspsc190501.93121501'),
	'identifierSchemeCodeUnspsc190501.93121502': __('identifierSchemeCodeUnspsc190501.93121502'),
	'identifierSchemeCodeUnspsc190501.93121503': __('identifierSchemeCodeUnspsc190501.93121503'),
	'identifierSchemeCodeUnspsc190501.93121504': __('identifierSchemeCodeUnspsc190501.93121504'),
	'identifierSchemeCodeUnspsc190501.93121505': __('identifierSchemeCodeUnspsc190501.93121505'),
	'identifierSchemeCodeUnspsc190501.93121506': __('identifierSchemeCodeUnspsc190501.93121506'),
	'identifierSchemeCodeUnspsc190501.93121507': __('identifierSchemeCodeUnspsc190501.93121507'),
	'identifierSchemeCodeUnspsc190501.93121508': __('identifierSchemeCodeUnspsc190501.93121508'),
	'identifierSchemeCodeUnspsc190501.93121509': __('identifierSchemeCodeUnspsc190501.93121509'),
	'identifierSchemeCodeUnspsc190501.93121600': __('identifierSchemeCodeUnspsc190501.93121600'),
	'identifierSchemeCodeUnspsc190501.93121601': __('identifierSchemeCodeUnspsc190501.93121601'),
	'identifierSchemeCodeUnspsc190501.93121602': __('identifierSchemeCodeUnspsc190501.93121602'),
	'identifierSchemeCodeUnspsc190501.93121603': __('identifierSchemeCodeUnspsc190501.93121603'),
	'identifierSchemeCodeUnspsc190501.93121604': __('identifierSchemeCodeUnspsc190501.93121604'),
	'identifierSchemeCodeUnspsc190501.93121605': __('identifierSchemeCodeUnspsc190501.93121605'),
	'identifierSchemeCodeUnspsc190501.93121606': __('identifierSchemeCodeUnspsc190501.93121606'),
	'identifierSchemeCodeUnspsc190501.93121607': __('identifierSchemeCodeUnspsc190501.93121607'),
	'identifierSchemeCodeUnspsc190501.93121608': __('identifierSchemeCodeUnspsc190501.93121608'),
	'identifierSchemeCodeUnspsc190501.93121609': __('identifierSchemeCodeUnspsc190501.93121609'),
	'identifierSchemeCodeUnspsc190501.93121610': __('identifierSchemeCodeUnspsc190501.93121610'),
	'identifierSchemeCodeUnspsc190501.93121611': __('identifierSchemeCodeUnspsc190501.93121611'),
	'identifierSchemeCodeUnspsc190501.93121612': __('identifierSchemeCodeUnspsc190501.93121612'),
	'identifierSchemeCodeUnspsc190501.93121613': __('identifierSchemeCodeUnspsc190501.93121613'),
	'identifierSchemeCodeUnspsc190501.93121614': __('identifierSchemeCodeUnspsc190501.93121614'),
	'identifierSchemeCodeUnspsc190501.93121615': __('identifierSchemeCodeUnspsc190501.93121615'),
	'identifierSchemeCodeUnspsc190501.93121700': __('identifierSchemeCodeUnspsc190501.93121700'),
	'identifierSchemeCodeUnspsc190501.93121701': __('identifierSchemeCodeUnspsc190501.93121701'),
	'identifierSchemeCodeUnspsc190501.93121702': __('identifierSchemeCodeUnspsc190501.93121702'),
	'identifierSchemeCodeUnspsc190501.93121703': __('identifierSchemeCodeUnspsc190501.93121703'),
	'identifierSchemeCodeUnspsc190501.93121704': __('identifierSchemeCodeUnspsc190501.93121704'),
	'identifierSchemeCodeUnspsc190501.93121705': __('identifierSchemeCodeUnspsc190501.93121705'),
	'identifierSchemeCodeUnspsc190501.93121706': __('identifierSchemeCodeUnspsc190501.93121706'),
	'identifierSchemeCodeUnspsc190501.93121707': __('identifierSchemeCodeUnspsc190501.93121707'),
	'identifierSchemeCodeUnspsc190501.93121708': __('identifierSchemeCodeUnspsc190501.93121708'),
	'identifierSchemeCodeUnspsc190501.93121709': __('identifierSchemeCodeUnspsc190501.93121709'),
	'identifierSchemeCodeUnspsc190501.93121710': __('identifierSchemeCodeUnspsc190501.93121710'),
	'identifierSchemeCodeUnspsc190501.93121711': __('identifierSchemeCodeUnspsc190501.93121711'),
	'identifierSchemeCodeUnspsc190501.93130000': __('identifierSchemeCodeUnspsc190501.93130000'),
	'identifierSchemeCodeUnspsc190501.93131500': __('identifierSchemeCodeUnspsc190501.93131500'),
	'identifierSchemeCodeUnspsc190501.93131501': __('identifierSchemeCodeUnspsc190501.93131501'),
	'identifierSchemeCodeUnspsc190501.93131502': __('identifierSchemeCodeUnspsc190501.93131502'),
	'identifierSchemeCodeUnspsc190501.93131503': __('identifierSchemeCodeUnspsc190501.93131503'),
	'identifierSchemeCodeUnspsc190501.93131504': __('identifierSchemeCodeUnspsc190501.93131504'),
	'identifierSchemeCodeUnspsc190501.93131505': __('identifierSchemeCodeUnspsc190501.93131505'),
	'identifierSchemeCodeUnspsc190501.93131506': __('identifierSchemeCodeUnspsc190501.93131506'),
	'identifierSchemeCodeUnspsc190501.93131507': __('identifierSchemeCodeUnspsc190501.93131507'),
	'identifierSchemeCodeUnspsc190501.93131600': __('identifierSchemeCodeUnspsc190501.93131600'),
	'identifierSchemeCodeUnspsc190501.93131601': __('identifierSchemeCodeUnspsc190501.93131601'),
	'identifierSchemeCodeUnspsc190501.93131602': __('identifierSchemeCodeUnspsc190501.93131602'),
	'identifierSchemeCodeUnspsc190501.93131603': __('identifierSchemeCodeUnspsc190501.93131603'),
	'identifierSchemeCodeUnspsc190501.93131604': __('identifierSchemeCodeUnspsc190501.93131604'),
	'identifierSchemeCodeUnspsc190501.93131605': __('identifierSchemeCodeUnspsc190501.93131605'),
	'identifierSchemeCodeUnspsc190501.93131606': __('identifierSchemeCodeUnspsc190501.93131606'),
	'identifierSchemeCodeUnspsc190501.93131607': __('identifierSchemeCodeUnspsc190501.93131607'),
	'identifierSchemeCodeUnspsc190501.93131608': __('identifierSchemeCodeUnspsc190501.93131608'),
	'identifierSchemeCodeUnspsc190501.93131609': __('identifierSchemeCodeUnspsc190501.93131609'),
	'identifierSchemeCodeUnspsc190501.93131610': __('identifierSchemeCodeUnspsc190501.93131610'),
	'identifierSchemeCodeUnspsc190501.93131611': __('identifierSchemeCodeUnspsc190501.93131611'),
	'identifierSchemeCodeUnspsc190501.93131612': __('identifierSchemeCodeUnspsc190501.93131612'),
	'identifierSchemeCodeUnspsc190501.93131613': __('identifierSchemeCodeUnspsc190501.93131613'),
	'identifierSchemeCodeUnspsc190501.93131700': __('identifierSchemeCodeUnspsc190501.93131700'),
	'identifierSchemeCodeUnspsc190501.93131701': __('identifierSchemeCodeUnspsc190501.93131701'),
	'identifierSchemeCodeUnspsc190501.93131702': __('identifierSchemeCodeUnspsc190501.93131702'),
	'identifierSchemeCodeUnspsc190501.93131703': __('identifierSchemeCodeUnspsc190501.93131703'),
	'identifierSchemeCodeUnspsc190501.93131704': __('identifierSchemeCodeUnspsc190501.93131704'),
	'identifierSchemeCodeUnspsc190501.93131705': __('identifierSchemeCodeUnspsc190501.93131705'),
	'identifierSchemeCodeUnspsc190501.93131800': __('identifierSchemeCodeUnspsc190501.93131800'),
	'identifierSchemeCodeUnspsc190501.93131801': __('identifierSchemeCodeUnspsc190501.93131801'),
	'identifierSchemeCodeUnspsc190501.93131802': __('identifierSchemeCodeUnspsc190501.93131802'),
	'identifierSchemeCodeUnspsc190501.93131803': __('identifierSchemeCodeUnspsc190501.93131803'),
	'identifierSchemeCodeUnspsc190501.93140000': __('identifierSchemeCodeUnspsc190501.93140000'),
	'identifierSchemeCodeUnspsc190501.93141500': __('identifierSchemeCodeUnspsc190501.93141500'),
	'identifierSchemeCodeUnspsc190501.93141501': __('identifierSchemeCodeUnspsc190501.93141501'),
	'identifierSchemeCodeUnspsc190501.93141502': __('identifierSchemeCodeUnspsc190501.93141502'),
	'identifierSchemeCodeUnspsc190501.93141503': __('identifierSchemeCodeUnspsc190501.93141503'),
	'identifierSchemeCodeUnspsc190501.93141504': __('identifierSchemeCodeUnspsc190501.93141504'),
	'identifierSchemeCodeUnspsc190501.93141505': __('identifierSchemeCodeUnspsc190501.93141505'),
	'identifierSchemeCodeUnspsc190501.93141506': __('identifierSchemeCodeUnspsc190501.93141506'),
	'identifierSchemeCodeUnspsc190501.93141507': __('identifierSchemeCodeUnspsc190501.93141507'),
	'identifierSchemeCodeUnspsc190501.93141508': __('identifierSchemeCodeUnspsc190501.93141508'),
	'identifierSchemeCodeUnspsc190501.93141509': __('identifierSchemeCodeUnspsc190501.93141509'),
	'identifierSchemeCodeUnspsc190501.93141510': __('identifierSchemeCodeUnspsc190501.93141510'),
	'identifierSchemeCodeUnspsc190501.93141511': __('identifierSchemeCodeUnspsc190501.93141511'),
	'identifierSchemeCodeUnspsc190501.93141512': __('identifierSchemeCodeUnspsc190501.93141512'),
	'identifierSchemeCodeUnspsc190501.93141513': __('identifierSchemeCodeUnspsc190501.93141513'),
	'identifierSchemeCodeUnspsc190501.93141514': __('identifierSchemeCodeUnspsc190501.93141514'),
	'identifierSchemeCodeUnspsc190501.93141515': __('identifierSchemeCodeUnspsc190501.93141515'),
	'identifierSchemeCodeUnspsc190501.93141600': __('identifierSchemeCodeUnspsc190501.93141600'),
	'identifierSchemeCodeUnspsc190501.93141601': __('identifierSchemeCodeUnspsc190501.93141601'),
	'identifierSchemeCodeUnspsc190501.93141602': __('identifierSchemeCodeUnspsc190501.93141602'),
	'identifierSchemeCodeUnspsc190501.93141603': __('identifierSchemeCodeUnspsc190501.93141603'),
	'identifierSchemeCodeUnspsc190501.93141604': __('identifierSchemeCodeUnspsc190501.93141604'),
	'identifierSchemeCodeUnspsc190501.93141605': __('identifierSchemeCodeUnspsc190501.93141605'),
	'identifierSchemeCodeUnspsc190501.93141606': __('identifierSchemeCodeUnspsc190501.93141606'),
	'identifierSchemeCodeUnspsc190501.93141607': __('identifierSchemeCodeUnspsc190501.93141607'),
	'identifierSchemeCodeUnspsc190501.93141608': __('identifierSchemeCodeUnspsc190501.93141608'),
	'identifierSchemeCodeUnspsc190501.93141609': __('identifierSchemeCodeUnspsc190501.93141609'),
	'identifierSchemeCodeUnspsc190501.93141610': __('identifierSchemeCodeUnspsc190501.93141610'),
	'identifierSchemeCodeUnspsc190501.93141611': __('identifierSchemeCodeUnspsc190501.93141611'),
	'identifierSchemeCodeUnspsc190501.93141612': __('identifierSchemeCodeUnspsc190501.93141612'),
	'identifierSchemeCodeUnspsc190501.93141613': __('identifierSchemeCodeUnspsc190501.93141613'),
	'identifierSchemeCodeUnspsc190501.93141700': __('identifierSchemeCodeUnspsc190501.93141700'),
	'identifierSchemeCodeUnspsc190501.93141701': __('identifierSchemeCodeUnspsc190501.93141701'),
	'identifierSchemeCodeUnspsc190501.93141702': __('identifierSchemeCodeUnspsc190501.93141702'),
	'identifierSchemeCodeUnspsc190501.93141703': __('identifierSchemeCodeUnspsc190501.93141703'),
	'identifierSchemeCodeUnspsc190501.93141704': __('identifierSchemeCodeUnspsc190501.93141704'),
	'identifierSchemeCodeUnspsc190501.93141705': __('identifierSchemeCodeUnspsc190501.93141705'),
	'identifierSchemeCodeUnspsc190501.93141706': __('identifierSchemeCodeUnspsc190501.93141706'),
	'identifierSchemeCodeUnspsc190501.93141707': __('identifierSchemeCodeUnspsc190501.93141707'),
	'identifierSchemeCodeUnspsc190501.93141708': __('identifierSchemeCodeUnspsc190501.93141708'),
	'identifierSchemeCodeUnspsc190501.93141709': __('identifierSchemeCodeUnspsc190501.93141709'),
	'identifierSchemeCodeUnspsc190501.93141710': __('identifierSchemeCodeUnspsc190501.93141710'),
	'identifierSchemeCodeUnspsc190501.93141711': __('identifierSchemeCodeUnspsc190501.93141711'),
	'identifierSchemeCodeUnspsc190501.93141712': __('identifierSchemeCodeUnspsc190501.93141712'),
	'identifierSchemeCodeUnspsc190501.93141713': __('identifierSchemeCodeUnspsc190501.93141713'),
	'identifierSchemeCodeUnspsc190501.93141714': __('identifierSchemeCodeUnspsc190501.93141714'),
	'identifierSchemeCodeUnspsc190501.93141715': __('identifierSchemeCodeUnspsc190501.93141715'),
	'identifierSchemeCodeUnspsc190501.93141716': __('identifierSchemeCodeUnspsc190501.93141716'),
	'identifierSchemeCodeUnspsc190501.93141717': __('identifierSchemeCodeUnspsc190501.93141717'),
	'identifierSchemeCodeUnspsc190501.93141718': __('identifierSchemeCodeUnspsc190501.93141718'),
	'identifierSchemeCodeUnspsc190501.93141800': __('identifierSchemeCodeUnspsc190501.93141800'),
	'identifierSchemeCodeUnspsc190501.93141801': __('identifierSchemeCodeUnspsc190501.93141801'),
	'identifierSchemeCodeUnspsc190501.93141802': __('identifierSchemeCodeUnspsc190501.93141802'),
	'identifierSchemeCodeUnspsc190501.93141803': __('identifierSchemeCodeUnspsc190501.93141803'),
	'identifierSchemeCodeUnspsc190501.93141804': __('identifierSchemeCodeUnspsc190501.93141804'),
	'identifierSchemeCodeUnspsc190501.93141805': __('identifierSchemeCodeUnspsc190501.93141805'),
	'identifierSchemeCodeUnspsc190501.93141806': __('identifierSchemeCodeUnspsc190501.93141806'),
	'identifierSchemeCodeUnspsc190501.93141807': __('identifierSchemeCodeUnspsc190501.93141807'),
	'identifierSchemeCodeUnspsc190501.93141808': __('identifierSchemeCodeUnspsc190501.93141808'),
	'identifierSchemeCodeUnspsc190501.93141810': __('identifierSchemeCodeUnspsc190501.93141810'),
	'identifierSchemeCodeUnspsc190501.93141811': __('identifierSchemeCodeUnspsc190501.93141811'),
	'identifierSchemeCodeUnspsc190501.93141812': __('identifierSchemeCodeUnspsc190501.93141812'),
	'identifierSchemeCodeUnspsc190501.93141813': __('identifierSchemeCodeUnspsc190501.93141813'),
	'identifierSchemeCodeUnspsc190501.93141814': __('identifierSchemeCodeUnspsc190501.93141814'),
	'identifierSchemeCodeUnspsc190501.93141900': __('identifierSchemeCodeUnspsc190501.93141900'),
	'identifierSchemeCodeUnspsc190501.93141901': __('identifierSchemeCodeUnspsc190501.93141901'),
	'identifierSchemeCodeUnspsc190501.93141902': __('identifierSchemeCodeUnspsc190501.93141902'),
	'identifierSchemeCodeUnspsc190501.93141903': __('identifierSchemeCodeUnspsc190501.93141903'),
	'identifierSchemeCodeUnspsc190501.93141904': __('identifierSchemeCodeUnspsc190501.93141904'),
	'identifierSchemeCodeUnspsc190501.93141905': __('identifierSchemeCodeUnspsc190501.93141905'),
	'identifierSchemeCodeUnspsc190501.93141906': __('identifierSchemeCodeUnspsc190501.93141906'),
	'identifierSchemeCodeUnspsc190501.93141907': __('identifierSchemeCodeUnspsc190501.93141907'),
	'identifierSchemeCodeUnspsc190501.93141908': __('identifierSchemeCodeUnspsc190501.93141908'),
	'identifierSchemeCodeUnspsc190501.93141909': __('identifierSchemeCodeUnspsc190501.93141909'),
	'identifierSchemeCodeUnspsc190501.93141910': __('identifierSchemeCodeUnspsc190501.93141910'),
	'identifierSchemeCodeUnspsc190501.93142000': __('identifierSchemeCodeUnspsc190501.93142000'),
	'identifierSchemeCodeUnspsc190501.93142001': __('identifierSchemeCodeUnspsc190501.93142001'),
	'identifierSchemeCodeUnspsc190501.93142002': __('identifierSchemeCodeUnspsc190501.93142002'),
	'identifierSchemeCodeUnspsc190501.93142003': __('identifierSchemeCodeUnspsc190501.93142003'),
	'identifierSchemeCodeUnspsc190501.93142004': __('identifierSchemeCodeUnspsc190501.93142004'),
	'identifierSchemeCodeUnspsc190501.93142005': __('identifierSchemeCodeUnspsc190501.93142005'),
	'identifierSchemeCodeUnspsc190501.93142006': __('identifierSchemeCodeUnspsc190501.93142006'),
	'identifierSchemeCodeUnspsc190501.93142007': __('identifierSchemeCodeUnspsc190501.93142007'),
	'identifierSchemeCodeUnspsc190501.93142008': __('identifierSchemeCodeUnspsc190501.93142008'),
	'identifierSchemeCodeUnspsc190501.93142009': __('identifierSchemeCodeUnspsc190501.93142009'),
	'identifierSchemeCodeUnspsc190501.93142100': __('identifierSchemeCodeUnspsc190501.93142100'),
	'identifierSchemeCodeUnspsc190501.93142101': __('identifierSchemeCodeUnspsc190501.93142101'),
	'identifierSchemeCodeUnspsc190501.93142102': __('identifierSchemeCodeUnspsc190501.93142102'),
	'identifierSchemeCodeUnspsc190501.93142103': __('identifierSchemeCodeUnspsc190501.93142103'),
	'identifierSchemeCodeUnspsc190501.93142104': __('identifierSchemeCodeUnspsc190501.93142104'),
	'identifierSchemeCodeUnspsc190501.93150000': __('identifierSchemeCodeUnspsc190501.93150000'),
	'identifierSchemeCodeUnspsc190501.93151500': __('identifierSchemeCodeUnspsc190501.93151500'),
	'identifierSchemeCodeUnspsc190501.93151501': __('identifierSchemeCodeUnspsc190501.93151501'),
	'identifierSchemeCodeUnspsc190501.93151502': __('identifierSchemeCodeUnspsc190501.93151502'),
	'identifierSchemeCodeUnspsc190501.93151503': __('identifierSchemeCodeUnspsc190501.93151503'),
	'identifierSchemeCodeUnspsc190501.93151504': __('identifierSchemeCodeUnspsc190501.93151504'),
	'identifierSchemeCodeUnspsc190501.93151505': __('identifierSchemeCodeUnspsc190501.93151505'),
	'identifierSchemeCodeUnspsc190501.93151506': __('identifierSchemeCodeUnspsc190501.93151506'),
	'identifierSchemeCodeUnspsc190501.93151507': __('identifierSchemeCodeUnspsc190501.93151507'),
	'identifierSchemeCodeUnspsc190501.93151508': __('identifierSchemeCodeUnspsc190501.93151508'),
	'identifierSchemeCodeUnspsc190501.93151509': __('identifierSchemeCodeUnspsc190501.93151509'),
	'identifierSchemeCodeUnspsc190501.93151510': __('identifierSchemeCodeUnspsc190501.93151510'),
	'identifierSchemeCodeUnspsc190501.93151511': __('identifierSchemeCodeUnspsc190501.93151511'),
	'identifierSchemeCodeUnspsc190501.93151512': __('identifierSchemeCodeUnspsc190501.93151512'),
	'identifierSchemeCodeUnspsc190501.93151513': __('identifierSchemeCodeUnspsc190501.93151513'),
	'identifierSchemeCodeUnspsc190501.93151514': __('identifierSchemeCodeUnspsc190501.93151514'),
	'identifierSchemeCodeUnspsc190501.93151515': __('identifierSchemeCodeUnspsc190501.93151515'),
	'identifierSchemeCodeUnspsc190501.93151516': __('identifierSchemeCodeUnspsc190501.93151516'),
	'identifierSchemeCodeUnspsc190501.93151517': __('identifierSchemeCodeUnspsc190501.93151517'),
	'identifierSchemeCodeUnspsc190501.93151518': __('identifierSchemeCodeUnspsc190501.93151518'),
	'identifierSchemeCodeUnspsc190501.93151519': __('identifierSchemeCodeUnspsc190501.93151519'),
	'identifierSchemeCodeUnspsc190501.93151600': __('identifierSchemeCodeUnspsc190501.93151600'),
	'identifierSchemeCodeUnspsc190501.93151601': __('identifierSchemeCodeUnspsc190501.93151601'),
	'identifierSchemeCodeUnspsc190501.93151602': __('identifierSchemeCodeUnspsc190501.93151602'),
	'identifierSchemeCodeUnspsc190501.93151603': __('identifierSchemeCodeUnspsc190501.93151603'),
	'identifierSchemeCodeUnspsc190501.93151604': __('identifierSchemeCodeUnspsc190501.93151604'),
	'identifierSchemeCodeUnspsc190501.93151605': __('identifierSchemeCodeUnspsc190501.93151605'),
	'identifierSchemeCodeUnspsc190501.93151606': __('identifierSchemeCodeUnspsc190501.93151606'),
	'identifierSchemeCodeUnspsc190501.93151607': __('identifierSchemeCodeUnspsc190501.93151607'),
	'identifierSchemeCodeUnspsc190501.93151608': __('identifierSchemeCodeUnspsc190501.93151608'),
	'identifierSchemeCodeUnspsc190501.93151609': __('identifierSchemeCodeUnspsc190501.93151609'),
	'identifierSchemeCodeUnspsc190501.93151610': __('identifierSchemeCodeUnspsc190501.93151610'),
	'identifierSchemeCodeUnspsc190501.93151611': __('identifierSchemeCodeUnspsc190501.93151611'),
	'identifierSchemeCodeUnspsc190501.93151700': __('identifierSchemeCodeUnspsc190501.93151700'),
	'identifierSchemeCodeUnspsc190501.93151701': __('identifierSchemeCodeUnspsc190501.93151701'),
	'identifierSchemeCodeUnspsc190501.93151702': __('identifierSchemeCodeUnspsc190501.93151702'),
	'identifierSchemeCodeUnspsc190501.93160000': __('identifierSchemeCodeUnspsc190501.93160000'),
	'identifierSchemeCodeUnspsc190501.93161500': __('identifierSchemeCodeUnspsc190501.93161500'),
	'identifierSchemeCodeUnspsc190501.93161501': __('identifierSchemeCodeUnspsc190501.93161501'),
	'identifierSchemeCodeUnspsc190501.93161502': __('identifierSchemeCodeUnspsc190501.93161502'),
	'identifierSchemeCodeUnspsc190501.93161503': __('identifierSchemeCodeUnspsc190501.93161503'),
	'identifierSchemeCodeUnspsc190501.93161504': __('identifierSchemeCodeUnspsc190501.93161504'),
	'identifierSchemeCodeUnspsc190501.93161600': __('identifierSchemeCodeUnspsc190501.93161600'),
	'identifierSchemeCodeUnspsc190501.93161601': __('identifierSchemeCodeUnspsc190501.93161601'),
	'identifierSchemeCodeUnspsc190501.93161602': __('identifierSchemeCodeUnspsc190501.93161602'),
	'identifierSchemeCodeUnspsc190501.93161603': __('identifierSchemeCodeUnspsc190501.93161603'),
	'identifierSchemeCodeUnspsc190501.93161604': __('identifierSchemeCodeUnspsc190501.93161604'),
	'identifierSchemeCodeUnspsc190501.93161605': __('identifierSchemeCodeUnspsc190501.93161605'),
	'identifierSchemeCodeUnspsc190501.93161606': __('identifierSchemeCodeUnspsc190501.93161606'),
	'identifierSchemeCodeUnspsc190501.93161607': __('identifierSchemeCodeUnspsc190501.93161607'),
	'identifierSchemeCodeUnspsc190501.93161608': __('identifierSchemeCodeUnspsc190501.93161608'),
	'identifierSchemeCodeUnspsc190501.93161609': __('identifierSchemeCodeUnspsc190501.93161609'),
	'identifierSchemeCodeUnspsc190501.93161700': __('identifierSchemeCodeUnspsc190501.93161700'),
	'identifierSchemeCodeUnspsc190501.93161701': __('identifierSchemeCodeUnspsc190501.93161701'),
	'identifierSchemeCodeUnspsc190501.93161702': __('identifierSchemeCodeUnspsc190501.93161702'),
	'identifierSchemeCodeUnspsc190501.93161703': __('identifierSchemeCodeUnspsc190501.93161703'),
	'identifierSchemeCodeUnspsc190501.93161704': __('identifierSchemeCodeUnspsc190501.93161704'),
	'identifierSchemeCodeUnspsc190501.93161800': __('identifierSchemeCodeUnspsc190501.93161800'),
	'identifierSchemeCodeUnspsc190501.93161801': __('identifierSchemeCodeUnspsc190501.93161801'),
	'identifierSchemeCodeUnspsc190501.93161802': __('identifierSchemeCodeUnspsc190501.93161802'),
	'identifierSchemeCodeUnspsc190501.93161803': __('identifierSchemeCodeUnspsc190501.93161803'),
	'identifierSchemeCodeUnspsc190501.93161804': __('identifierSchemeCodeUnspsc190501.93161804'),
	'identifierSchemeCodeUnspsc190501.93161805': __('identifierSchemeCodeUnspsc190501.93161805'),
	'identifierSchemeCodeUnspsc190501.93161806': __('identifierSchemeCodeUnspsc190501.93161806'),
	'identifierSchemeCodeUnspsc190501.93161807': __('identifierSchemeCodeUnspsc190501.93161807'),
	'identifierSchemeCodeUnspsc190501.93161808': __('identifierSchemeCodeUnspsc190501.93161808'),
	'identifierSchemeCodeUnspsc190501.93170000': __('identifierSchemeCodeUnspsc190501.93170000'),
	'identifierSchemeCodeUnspsc190501.93171500': __('identifierSchemeCodeUnspsc190501.93171500'),
	'identifierSchemeCodeUnspsc190501.93171501': __('identifierSchemeCodeUnspsc190501.93171501'),
	'identifierSchemeCodeUnspsc190501.93171502': __('identifierSchemeCodeUnspsc190501.93171502'),
	'identifierSchemeCodeUnspsc190501.93171503': __('identifierSchemeCodeUnspsc190501.93171503'),
	'identifierSchemeCodeUnspsc190501.93171504': __('identifierSchemeCodeUnspsc190501.93171504'),
	'identifierSchemeCodeUnspsc190501.93171600': __('identifierSchemeCodeUnspsc190501.93171600'),
	'identifierSchemeCodeUnspsc190501.93171601': __('identifierSchemeCodeUnspsc190501.93171601'),
	'identifierSchemeCodeUnspsc190501.93171602': __('identifierSchemeCodeUnspsc190501.93171602'),
	'identifierSchemeCodeUnspsc190501.93171603': __('identifierSchemeCodeUnspsc190501.93171603'),
	'identifierSchemeCodeUnspsc190501.93171604': __('identifierSchemeCodeUnspsc190501.93171604'),
	'identifierSchemeCodeUnspsc190501.93171700': __('identifierSchemeCodeUnspsc190501.93171700'),
	'identifierSchemeCodeUnspsc190501.93171701': __('identifierSchemeCodeUnspsc190501.93171701'),
	'identifierSchemeCodeUnspsc190501.93171702': __('identifierSchemeCodeUnspsc190501.93171702'),
	'identifierSchemeCodeUnspsc190501.93171703': __('identifierSchemeCodeUnspsc190501.93171703'),
	'identifierSchemeCodeUnspsc190501.93171800': __('identifierSchemeCodeUnspsc190501.93171800'),
	'identifierSchemeCodeUnspsc190501.93171801': __('identifierSchemeCodeUnspsc190501.93171801'),
	'identifierSchemeCodeUnspsc190501.93171802': __('identifierSchemeCodeUnspsc190501.93171802'),
	'identifierSchemeCodeUnspsc190501.93171803': __('identifierSchemeCodeUnspsc190501.93171803'),
	'identifierSchemeCodeUnspsc190501.94000000': __('identifierSchemeCodeUnspsc190501.94000000'),
	'identifierSchemeCodeUnspsc190501.94100000': __('identifierSchemeCodeUnspsc190501.94100000'),
	'identifierSchemeCodeUnspsc190501.94101500': __('identifierSchemeCodeUnspsc190501.94101500'),
	'identifierSchemeCodeUnspsc190501.94101501': __('identifierSchemeCodeUnspsc190501.94101501'),
	'identifierSchemeCodeUnspsc190501.94101502': __('identifierSchemeCodeUnspsc190501.94101502'),
	'identifierSchemeCodeUnspsc190501.94101503': __('identifierSchemeCodeUnspsc190501.94101503'),
	'identifierSchemeCodeUnspsc190501.94101504': __('identifierSchemeCodeUnspsc190501.94101504'),
	'identifierSchemeCodeUnspsc190501.94101505': __('identifierSchemeCodeUnspsc190501.94101505'),
	'identifierSchemeCodeUnspsc190501.94101600': __('identifierSchemeCodeUnspsc190501.94101600'),
	'identifierSchemeCodeUnspsc190501.94101601': __('identifierSchemeCodeUnspsc190501.94101601'),
	'identifierSchemeCodeUnspsc190501.94101602': __('identifierSchemeCodeUnspsc190501.94101602'),
	'identifierSchemeCodeUnspsc190501.94101603': __('identifierSchemeCodeUnspsc190501.94101603'),
	'identifierSchemeCodeUnspsc190501.94101604': __('identifierSchemeCodeUnspsc190501.94101604'),
	'identifierSchemeCodeUnspsc190501.94101605': __('identifierSchemeCodeUnspsc190501.94101605'),
	'identifierSchemeCodeUnspsc190501.94101606': __('identifierSchemeCodeUnspsc190501.94101606'),
	'identifierSchemeCodeUnspsc190501.94101607': __('identifierSchemeCodeUnspsc190501.94101607'),
	'identifierSchemeCodeUnspsc190501.94101608': __('identifierSchemeCodeUnspsc190501.94101608'),
	'identifierSchemeCodeUnspsc190501.94101609': __('identifierSchemeCodeUnspsc190501.94101609'),
	'identifierSchemeCodeUnspsc190501.94101610': __('identifierSchemeCodeUnspsc190501.94101610'),
	'identifierSchemeCodeUnspsc190501.94101700': __('identifierSchemeCodeUnspsc190501.94101700'),
	'identifierSchemeCodeUnspsc190501.94101701': __('identifierSchemeCodeUnspsc190501.94101701'),
	'identifierSchemeCodeUnspsc190501.94101702': __('identifierSchemeCodeUnspsc190501.94101702'),
	'identifierSchemeCodeUnspsc190501.94101703': __('identifierSchemeCodeUnspsc190501.94101703'),
	'identifierSchemeCodeUnspsc190501.94101704': __('identifierSchemeCodeUnspsc190501.94101704'),
	'identifierSchemeCodeUnspsc190501.94101705': __('identifierSchemeCodeUnspsc190501.94101705'),
	'identifierSchemeCodeUnspsc190501.94101800': __('identifierSchemeCodeUnspsc190501.94101800'),
	'identifierSchemeCodeUnspsc190501.94101801': __('identifierSchemeCodeUnspsc190501.94101801'),
	'identifierSchemeCodeUnspsc190501.94101802': __('identifierSchemeCodeUnspsc190501.94101802'),
	'identifierSchemeCodeUnspsc190501.94101803': __('identifierSchemeCodeUnspsc190501.94101803'),
	'identifierSchemeCodeUnspsc190501.94101804': __('identifierSchemeCodeUnspsc190501.94101804'),
	'identifierSchemeCodeUnspsc190501.94101805': __('identifierSchemeCodeUnspsc190501.94101805'),
	'identifierSchemeCodeUnspsc190501.94101806': __('identifierSchemeCodeUnspsc190501.94101806'),
	'identifierSchemeCodeUnspsc190501.94101807': __('identifierSchemeCodeUnspsc190501.94101807'),
	'identifierSchemeCodeUnspsc190501.94101808': __('identifierSchemeCodeUnspsc190501.94101808'),
	'identifierSchemeCodeUnspsc190501.94101809': __('identifierSchemeCodeUnspsc190501.94101809'),
	'identifierSchemeCodeUnspsc190501.94101810': __('identifierSchemeCodeUnspsc190501.94101810'),
	'identifierSchemeCodeUnspsc190501.94110000': __('identifierSchemeCodeUnspsc190501.94110000'),
	'identifierSchemeCodeUnspsc190501.94111700': __('identifierSchemeCodeUnspsc190501.94111700'),
	'identifierSchemeCodeUnspsc190501.94111701': __('identifierSchemeCodeUnspsc190501.94111701'),
	'identifierSchemeCodeUnspsc190501.94111702': __('identifierSchemeCodeUnspsc190501.94111702'),
	'identifierSchemeCodeUnspsc190501.94111703': __('identifierSchemeCodeUnspsc190501.94111703'),
	'identifierSchemeCodeUnspsc190501.94111704': __('identifierSchemeCodeUnspsc190501.94111704'),
	'identifierSchemeCodeUnspsc190501.94111800': __('identifierSchemeCodeUnspsc190501.94111800'),
	'identifierSchemeCodeUnspsc190501.94111801': __('identifierSchemeCodeUnspsc190501.94111801'),
	'identifierSchemeCodeUnspsc190501.94111802': __('identifierSchemeCodeUnspsc190501.94111802'),
	'identifierSchemeCodeUnspsc190501.94111803': __('identifierSchemeCodeUnspsc190501.94111803'),
	'identifierSchemeCodeUnspsc190501.94111804': __('identifierSchemeCodeUnspsc190501.94111804'),
	'identifierSchemeCodeUnspsc190501.94111900': __('identifierSchemeCodeUnspsc190501.94111900'),
	'identifierSchemeCodeUnspsc190501.94111901': __('identifierSchemeCodeUnspsc190501.94111901'),
	'identifierSchemeCodeUnspsc190501.94111902': __('identifierSchemeCodeUnspsc190501.94111902'),
	'identifierSchemeCodeUnspsc190501.94111903': __('identifierSchemeCodeUnspsc190501.94111903'),
	'identifierSchemeCodeUnspsc190501.94112000': __('identifierSchemeCodeUnspsc190501.94112000'),
	'identifierSchemeCodeUnspsc190501.94112001': __('identifierSchemeCodeUnspsc190501.94112001'),
	'identifierSchemeCodeUnspsc190501.94112002': __('identifierSchemeCodeUnspsc190501.94112002'),
	'identifierSchemeCodeUnspsc190501.94112003': __('identifierSchemeCodeUnspsc190501.94112003'),
	'identifierSchemeCodeUnspsc190501.94112004': __('identifierSchemeCodeUnspsc190501.94112004'),
	'identifierSchemeCodeUnspsc190501.94112005': __('identifierSchemeCodeUnspsc190501.94112005'),
	'identifierSchemeCodeUnspsc190501.94120000': __('identifierSchemeCodeUnspsc190501.94120000'),
	'identifierSchemeCodeUnspsc190501.94121500': __('identifierSchemeCodeUnspsc190501.94121500'),
	'identifierSchemeCodeUnspsc190501.94121501': __('identifierSchemeCodeUnspsc190501.94121501'),
	'identifierSchemeCodeUnspsc190501.94121502': __('identifierSchemeCodeUnspsc190501.94121502'),
	'identifierSchemeCodeUnspsc190501.94121503': __('identifierSchemeCodeUnspsc190501.94121503'),
	'identifierSchemeCodeUnspsc190501.94121504': __('identifierSchemeCodeUnspsc190501.94121504'),
	'identifierSchemeCodeUnspsc190501.94121505': __('identifierSchemeCodeUnspsc190501.94121505'),
	'identifierSchemeCodeUnspsc190501.94121506': __('identifierSchemeCodeUnspsc190501.94121506'),
	'identifierSchemeCodeUnspsc190501.94121507': __('identifierSchemeCodeUnspsc190501.94121507'),
	'identifierSchemeCodeUnspsc190501.94121508': __('identifierSchemeCodeUnspsc190501.94121508'),
	'identifierSchemeCodeUnspsc190501.94121509': __('identifierSchemeCodeUnspsc190501.94121509'),
	'identifierSchemeCodeUnspsc190501.94121510': __('identifierSchemeCodeUnspsc190501.94121510'),
	'identifierSchemeCodeUnspsc190501.94121511': __('identifierSchemeCodeUnspsc190501.94121511'),
	'identifierSchemeCodeUnspsc190501.94121512': __('identifierSchemeCodeUnspsc190501.94121512'),
	'identifierSchemeCodeUnspsc190501.94121513': __('identifierSchemeCodeUnspsc190501.94121513'),
	'identifierSchemeCodeUnspsc190501.94121514': __('identifierSchemeCodeUnspsc190501.94121514'),
	'identifierSchemeCodeUnspsc190501.94121600': __('identifierSchemeCodeUnspsc190501.94121600'),
	'identifierSchemeCodeUnspsc190501.94121601': __('identifierSchemeCodeUnspsc190501.94121601'),
	'identifierSchemeCodeUnspsc190501.94121602': __('identifierSchemeCodeUnspsc190501.94121602'),
	'identifierSchemeCodeUnspsc190501.94121603': __('identifierSchemeCodeUnspsc190501.94121603'),
	'identifierSchemeCodeUnspsc190501.94121604': __('identifierSchemeCodeUnspsc190501.94121604'),
	'identifierSchemeCodeUnspsc190501.94121605': __('identifierSchemeCodeUnspsc190501.94121605'),
	'identifierSchemeCodeUnspsc190501.94121606': __('identifierSchemeCodeUnspsc190501.94121606'),
	'identifierSchemeCodeUnspsc190501.94121607': __('identifierSchemeCodeUnspsc190501.94121607'),
	'identifierSchemeCodeUnspsc190501.94121700': __('identifierSchemeCodeUnspsc190501.94121700'),
	'identifierSchemeCodeUnspsc190501.94121701': __('identifierSchemeCodeUnspsc190501.94121701'),
	'identifierSchemeCodeUnspsc190501.94121702': __('identifierSchemeCodeUnspsc190501.94121702'),
	'identifierSchemeCodeUnspsc190501.94121703': __('identifierSchemeCodeUnspsc190501.94121703'),
	'identifierSchemeCodeUnspsc190501.94121704': __('identifierSchemeCodeUnspsc190501.94121704'),
	'identifierSchemeCodeUnspsc190501.94121800': __('identifierSchemeCodeUnspsc190501.94121800'),
	'identifierSchemeCodeUnspsc190501.94121801': __('identifierSchemeCodeUnspsc190501.94121801'),
	'identifierSchemeCodeUnspsc190501.94121802': __('identifierSchemeCodeUnspsc190501.94121802'),
	'identifierSchemeCodeUnspsc190501.94121803': __('identifierSchemeCodeUnspsc190501.94121803'),
	'identifierSchemeCodeUnspsc190501.94121804': __('identifierSchemeCodeUnspsc190501.94121804'),
	'identifierSchemeCodeUnspsc190501.94121805': __('identifierSchemeCodeUnspsc190501.94121805'),
	'identifierSchemeCodeUnspsc190501.94130000': __('identifierSchemeCodeUnspsc190501.94130000'),
	'identifierSchemeCodeUnspsc190501.94131500': __('identifierSchemeCodeUnspsc190501.94131500'),
	'identifierSchemeCodeUnspsc190501.94131501': __('identifierSchemeCodeUnspsc190501.94131501'),
	'identifierSchemeCodeUnspsc190501.94131502': __('identifierSchemeCodeUnspsc190501.94131502'),
	'identifierSchemeCodeUnspsc190501.94131503': __('identifierSchemeCodeUnspsc190501.94131503'),
	'identifierSchemeCodeUnspsc190501.94131504': __('identifierSchemeCodeUnspsc190501.94131504'),
	'identifierSchemeCodeUnspsc190501.94131600': __('identifierSchemeCodeUnspsc190501.94131600'),
	'identifierSchemeCodeUnspsc190501.94131601': __('identifierSchemeCodeUnspsc190501.94131601'),
	'identifierSchemeCodeUnspsc190501.94131602': __('identifierSchemeCodeUnspsc190501.94131602'),
	'identifierSchemeCodeUnspsc190501.94131603': __('identifierSchemeCodeUnspsc190501.94131603'),
	'identifierSchemeCodeUnspsc190501.94131604': __('identifierSchemeCodeUnspsc190501.94131604'),
	'identifierSchemeCodeUnspsc190501.94131605': __('identifierSchemeCodeUnspsc190501.94131605'),
	'identifierSchemeCodeUnspsc190501.94131606': __('identifierSchemeCodeUnspsc190501.94131606'),
	'identifierSchemeCodeUnspsc190501.94131607': __('identifierSchemeCodeUnspsc190501.94131607'),
	'identifierSchemeCodeUnspsc190501.94131608': __('identifierSchemeCodeUnspsc190501.94131608'),
	'identifierSchemeCodeUnspsc190501.94131700': __('identifierSchemeCodeUnspsc190501.94131700'),
	'identifierSchemeCodeUnspsc190501.94131701': __('identifierSchemeCodeUnspsc190501.94131701'),
	'identifierSchemeCodeUnspsc190501.94131702': __('identifierSchemeCodeUnspsc190501.94131702'),
	'identifierSchemeCodeUnspsc190501.94131703': __('identifierSchemeCodeUnspsc190501.94131703'),
	'identifierSchemeCodeUnspsc190501.94131704': __('identifierSchemeCodeUnspsc190501.94131704'),
	'identifierSchemeCodeUnspsc190501.94131800': __('identifierSchemeCodeUnspsc190501.94131800'),
	'identifierSchemeCodeUnspsc190501.94131801': __('identifierSchemeCodeUnspsc190501.94131801'),
	'identifierSchemeCodeUnspsc190501.94131802': __('identifierSchemeCodeUnspsc190501.94131802'),
	'identifierSchemeCodeUnspsc190501.94131803': __('identifierSchemeCodeUnspsc190501.94131803'),
	'identifierSchemeCodeUnspsc190501.94131804': __('identifierSchemeCodeUnspsc190501.94131804'),
	'identifierSchemeCodeUnspsc190501.94131805': __('identifierSchemeCodeUnspsc190501.94131805'),
	'identifierSchemeCodeUnspsc190501.94131900': __('identifierSchemeCodeUnspsc190501.94131900'),
	'identifierSchemeCodeUnspsc190501.94131901': __('identifierSchemeCodeUnspsc190501.94131901'),
	'identifierSchemeCodeUnspsc190501.94131902': __('identifierSchemeCodeUnspsc190501.94131902'),
	'identifierSchemeCodeUnspsc190501.94131903': __('identifierSchemeCodeUnspsc190501.94131903'),
	'identifierSchemeCodeUnspsc190501.94132000': __('identifierSchemeCodeUnspsc190501.94132000'),
	'identifierSchemeCodeUnspsc190501.94132001': __('identifierSchemeCodeUnspsc190501.94132001'),
	'identifierSchemeCodeUnspsc190501.94132002': __('identifierSchemeCodeUnspsc190501.94132002'),
	'identifierSchemeCodeUnspsc190501.94132003': __('identifierSchemeCodeUnspsc190501.94132003'),
	'identifierSchemeCodeUnspsc190501.94132004': __('identifierSchemeCodeUnspsc190501.94132004'),
	'identifierSchemeCodeUnspsc190501.95000000': __('identifierSchemeCodeUnspsc190501.95000000'),
	'identifierSchemeCodeUnspsc190501.95100000': __('identifierSchemeCodeUnspsc190501.95100000'),
	'identifierSchemeCodeUnspsc190501.95101500': __('identifierSchemeCodeUnspsc190501.95101500'),
	'identifierSchemeCodeUnspsc190501.95101501': __('identifierSchemeCodeUnspsc190501.95101501'),
	'identifierSchemeCodeUnspsc190501.95101502': __('identifierSchemeCodeUnspsc190501.95101502'),
	'identifierSchemeCodeUnspsc190501.95101503': __('identifierSchemeCodeUnspsc190501.95101503'),
	'identifierSchemeCodeUnspsc190501.95101504': __('identifierSchemeCodeUnspsc190501.95101504'),
	'identifierSchemeCodeUnspsc190501.95101505': __('identifierSchemeCodeUnspsc190501.95101505'),
	'identifierSchemeCodeUnspsc190501.95101506': __('identifierSchemeCodeUnspsc190501.95101506'),
	'identifierSchemeCodeUnspsc190501.95101507': __('identifierSchemeCodeUnspsc190501.95101507'),
	'identifierSchemeCodeUnspsc190501.95101508': __('identifierSchemeCodeUnspsc190501.95101508'),
	'identifierSchemeCodeUnspsc190501.95101509': __('identifierSchemeCodeUnspsc190501.95101509'),
	'identifierSchemeCodeUnspsc190501.95101510': __('identifierSchemeCodeUnspsc190501.95101510'),
	'identifierSchemeCodeUnspsc190501.95101600': __('identifierSchemeCodeUnspsc190501.95101600'),
	'identifierSchemeCodeUnspsc190501.95101601': __('identifierSchemeCodeUnspsc190501.95101601'),
	'identifierSchemeCodeUnspsc190501.95101602': __('identifierSchemeCodeUnspsc190501.95101602'),
	'identifierSchemeCodeUnspsc190501.95101603': __('identifierSchemeCodeUnspsc190501.95101603'),
	'identifierSchemeCodeUnspsc190501.95101700': __('identifierSchemeCodeUnspsc190501.95101700'),
	'identifierSchemeCodeUnspsc190501.95101701': __('identifierSchemeCodeUnspsc190501.95101701'),
	'identifierSchemeCodeUnspsc190501.95101702': __('identifierSchemeCodeUnspsc190501.95101702'),
	'identifierSchemeCodeUnspsc190501.95101703': __('identifierSchemeCodeUnspsc190501.95101703'),
	'identifierSchemeCodeUnspsc190501.95101704': __('identifierSchemeCodeUnspsc190501.95101704'),
	'identifierSchemeCodeUnspsc190501.95101705': __('identifierSchemeCodeUnspsc190501.95101705'),
	'identifierSchemeCodeUnspsc190501.95101706': __('identifierSchemeCodeUnspsc190501.95101706'),
	'identifierSchemeCodeUnspsc190501.95101707': __('identifierSchemeCodeUnspsc190501.95101707'),
	'identifierSchemeCodeUnspsc190501.95101708': __('identifierSchemeCodeUnspsc190501.95101708'),
	'identifierSchemeCodeUnspsc190501.95101800': __('identifierSchemeCodeUnspsc190501.95101800'),
	'identifierSchemeCodeUnspsc190501.95101801': __('identifierSchemeCodeUnspsc190501.95101801'),
	'identifierSchemeCodeUnspsc190501.95101802': __('identifierSchemeCodeUnspsc190501.95101802'),
	'identifierSchemeCodeUnspsc190501.95101803': __('identifierSchemeCodeUnspsc190501.95101803'),
	'identifierSchemeCodeUnspsc190501.95101804': __('identifierSchemeCodeUnspsc190501.95101804'),
	'identifierSchemeCodeUnspsc190501.95101805': __('identifierSchemeCodeUnspsc190501.95101805'),
	'identifierSchemeCodeUnspsc190501.95101806': __('identifierSchemeCodeUnspsc190501.95101806'),
	'identifierSchemeCodeUnspsc190501.95101807': __('identifierSchemeCodeUnspsc190501.95101807'),
	'identifierSchemeCodeUnspsc190501.95101900': __('identifierSchemeCodeUnspsc190501.95101900'),
	'identifierSchemeCodeUnspsc190501.95101901': __('identifierSchemeCodeUnspsc190501.95101901'),
	'identifierSchemeCodeUnspsc190501.95101902': __('identifierSchemeCodeUnspsc190501.95101902'),
	'identifierSchemeCodeUnspsc190501.95101903': __('identifierSchemeCodeUnspsc190501.95101903'),
	'identifierSchemeCodeUnspsc190501.95110000': __('identifierSchemeCodeUnspsc190501.95110000'),
	'identifierSchemeCodeUnspsc190501.95111500': __('identifierSchemeCodeUnspsc190501.95111500'),
	'identifierSchemeCodeUnspsc190501.95111501': __('identifierSchemeCodeUnspsc190501.95111501'),
	'identifierSchemeCodeUnspsc190501.95111502': __('identifierSchemeCodeUnspsc190501.95111502'),
	'identifierSchemeCodeUnspsc190501.95111503': __('identifierSchemeCodeUnspsc190501.95111503'),
	'identifierSchemeCodeUnspsc190501.95111504': __('identifierSchemeCodeUnspsc190501.95111504'),
	'identifierSchemeCodeUnspsc190501.95111600': __('identifierSchemeCodeUnspsc190501.95111600'),
	'identifierSchemeCodeUnspsc190501.95111601': __('identifierSchemeCodeUnspsc190501.95111601'),
	'identifierSchemeCodeUnspsc190501.95111602': __('identifierSchemeCodeUnspsc190501.95111602'),
	'identifierSchemeCodeUnspsc190501.95111603': __('identifierSchemeCodeUnspsc190501.95111603'),
	'identifierSchemeCodeUnspsc190501.95111604': __('identifierSchemeCodeUnspsc190501.95111604'),
	'identifierSchemeCodeUnspsc190501.95111605': __('identifierSchemeCodeUnspsc190501.95111605'),
	'identifierSchemeCodeUnspsc190501.95111606': __('identifierSchemeCodeUnspsc190501.95111606'),
	'identifierSchemeCodeUnspsc190501.95111607': __('identifierSchemeCodeUnspsc190501.95111607'),
	'identifierSchemeCodeUnspsc190501.95111608': __('identifierSchemeCodeUnspsc190501.95111608'),
	'identifierSchemeCodeUnspsc190501.95111609': __('identifierSchemeCodeUnspsc190501.95111609'),
	'identifierSchemeCodeUnspsc190501.95111610': __('identifierSchemeCodeUnspsc190501.95111610'),
	'identifierSchemeCodeUnspsc190501.95111611': __('identifierSchemeCodeUnspsc190501.95111611'),
	'identifierSchemeCodeUnspsc190501.95111612': __('identifierSchemeCodeUnspsc190501.95111612'),
	'identifierSchemeCodeUnspsc190501.95111613': __('identifierSchemeCodeUnspsc190501.95111613'),
	'identifierSchemeCodeUnspsc190501.95111614': __('identifierSchemeCodeUnspsc190501.95111614'),
	'identifierSchemeCodeUnspsc190501.95111615': __('identifierSchemeCodeUnspsc190501.95111615'),
	'identifierSchemeCodeUnspsc190501.95111616': __('identifierSchemeCodeUnspsc190501.95111616'),
	'identifierSchemeCodeUnspsc190501.95111617': __('identifierSchemeCodeUnspsc190501.95111617'),
	'identifierSchemeCodeUnspsc190501.95120000': __('identifierSchemeCodeUnspsc190501.95120000'),
	'identifierSchemeCodeUnspsc190501.95121500': __('identifierSchemeCodeUnspsc190501.95121500'),
	'identifierSchemeCodeUnspsc190501.95121501': __('identifierSchemeCodeUnspsc190501.95121501'),
	'identifierSchemeCodeUnspsc190501.95121502': __('identifierSchemeCodeUnspsc190501.95121502'),
	'identifierSchemeCodeUnspsc190501.95121503': __('identifierSchemeCodeUnspsc190501.95121503'),
	'identifierSchemeCodeUnspsc190501.95121504': __('identifierSchemeCodeUnspsc190501.95121504'),
	'identifierSchemeCodeUnspsc190501.95121505': __('identifierSchemeCodeUnspsc190501.95121505'),
	'identifierSchemeCodeUnspsc190501.95121506': __('identifierSchemeCodeUnspsc190501.95121506'),
	'identifierSchemeCodeUnspsc190501.95121507': __('identifierSchemeCodeUnspsc190501.95121507'),
	'identifierSchemeCodeUnspsc190501.95121508': __('identifierSchemeCodeUnspsc190501.95121508'),
	'identifierSchemeCodeUnspsc190501.95121509': __('identifierSchemeCodeUnspsc190501.95121509'),
	'identifierSchemeCodeUnspsc190501.95121510': __('identifierSchemeCodeUnspsc190501.95121510'),
	'identifierSchemeCodeUnspsc190501.95121511': __('identifierSchemeCodeUnspsc190501.95121511'),
	'identifierSchemeCodeUnspsc190501.95121512': __('identifierSchemeCodeUnspsc190501.95121512'),
	'identifierSchemeCodeUnspsc190501.95121513': __('identifierSchemeCodeUnspsc190501.95121513'),
	'identifierSchemeCodeUnspsc190501.95121514': __('identifierSchemeCodeUnspsc190501.95121514'),
	'identifierSchemeCodeUnspsc190501.95121515': __('identifierSchemeCodeUnspsc190501.95121515'),
	'identifierSchemeCodeUnspsc190501.95121516': __('identifierSchemeCodeUnspsc190501.95121516'),
	'identifierSchemeCodeUnspsc190501.95121517': __('identifierSchemeCodeUnspsc190501.95121517'),
	'identifierSchemeCodeUnspsc190501.95121600': __('identifierSchemeCodeUnspsc190501.95121600'),
	'identifierSchemeCodeUnspsc190501.95121601': __('identifierSchemeCodeUnspsc190501.95121601'),
	'identifierSchemeCodeUnspsc190501.95121602': __('identifierSchemeCodeUnspsc190501.95121602'),
	'identifierSchemeCodeUnspsc190501.95121603': __('identifierSchemeCodeUnspsc190501.95121603'),
	'identifierSchemeCodeUnspsc190501.95121604': __('identifierSchemeCodeUnspsc190501.95121604'),
	'identifierSchemeCodeUnspsc190501.95121605': __('identifierSchemeCodeUnspsc190501.95121605'),
	'identifierSchemeCodeUnspsc190501.95121606': __('identifierSchemeCodeUnspsc190501.95121606'),
	'identifierSchemeCodeUnspsc190501.95121607': __('identifierSchemeCodeUnspsc190501.95121607'),
	'identifierSchemeCodeUnspsc190501.95121608': __('identifierSchemeCodeUnspsc190501.95121608'),
	'identifierSchemeCodeUnspsc190501.95121609': __('identifierSchemeCodeUnspsc190501.95121609'),
	'identifierSchemeCodeUnspsc190501.95121610': __('identifierSchemeCodeUnspsc190501.95121610'),
	'identifierSchemeCodeUnspsc190501.95121611': __('identifierSchemeCodeUnspsc190501.95121611'),
	'identifierSchemeCodeUnspsc190501.95121612': __('identifierSchemeCodeUnspsc190501.95121612'),
	'identifierSchemeCodeUnspsc190501.95121613': __('identifierSchemeCodeUnspsc190501.95121613'),
	'identifierSchemeCodeUnspsc190501.95121614': __('identifierSchemeCodeUnspsc190501.95121614'),
	'identifierSchemeCodeUnspsc190501.95121615': __('identifierSchemeCodeUnspsc190501.95121615'),
	'identifierSchemeCodeUnspsc190501.95121616': __('identifierSchemeCodeUnspsc190501.95121616'),
	'identifierSchemeCodeUnspsc190501.95121617': __('identifierSchemeCodeUnspsc190501.95121617'),
	'identifierSchemeCodeUnspsc190501.95121618': __('identifierSchemeCodeUnspsc190501.95121618'),
	'identifierSchemeCodeUnspsc190501.95121619': __('identifierSchemeCodeUnspsc190501.95121619'),
	'identifierSchemeCodeUnspsc190501.95121620': __('identifierSchemeCodeUnspsc190501.95121620'),
	'identifierSchemeCodeUnspsc190501.95121621': __('identifierSchemeCodeUnspsc190501.95121621'),
	'identifierSchemeCodeUnspsc190501.95121622': __('identifierSchemeCodeUnspsc190501.95121622'),
	'identifierSchemeCodeUnspsc190501.95121623': __('identifierSchemeCodeUnspsc190501.95121623'),
	'identifierSchemeCodeUnspsc190501.95121624': __('identifierSchemeCodeUnspsc190501.95121624'),
	'identifierSchemeCodeUnspsc190501.95121625': __('identifierSchemeCodeUnspsc190501.95121625'),
	'identifierSchemeCodeUnspsc190501.95121626': __('identifierSchemeCodeUnspsc190501.95121626'),
	'identifierSchemeCodeUnspsc190501.95121627': __('identifierSchemeCodeUnspsc190501.95121627'),
	'identifierSchemeCodeUnspsc190501.95121628': __('identifierSchemeCodeUnspsc190501.95121628'),
	'identifierSchemeCodeUnspsc190501.95121629': __('identifierSchemeCodeUnspsc190501.95121629'),
	'identifierSchemeCodeUnspsc190501.95121630': __('identifierSchemeCodeUnspsc190501.95121630'),
	'identifierSchemeCodeUnspsc190501.95121631': __('identifierSchemeCodeUnspsc190501.95121631'),
	'identifierSchemeCodeUnspsc190501.95121632': __('identifierSchemeCodeUnspsc190501.95121632'),
	'identifierSchemeCodeUnspsc190501.95121633': __('identifierSchemeCodeUnspsc190501.95121633'),
	'identifierSchemeCodeUnspsc190501.95121634': __('identifierSchemeCodeUnspsc190501.95121634'),
	'identifierSchemeCodeUnspsc190501.95121635': __('identifierSchemeCodeUnspsc190501.95121635'),
	'identifierSchemeCodeUnspsc190501.95121636': __('identifierSchemeCodeUnspsc190501.95121636'),
	'identifierSchemeCodeUnspsc190501.95121637': __('identifierSchemeCodeUnspsc190501.95121637'),
	'identifierSchemeCodeUnspsc190501.95121638': __('identifierSchemeCodeUnspsc190501.95121638'),
	'identifierSchemeCodeUnspsc190501.95121639': __('identifierSchemeCodeUnspsc190501.95121639'),
	'identifierSchemeCodeUnspsc190501.95121640': __('identifierSchemeCodeUnspsc190501.95121640'),
	'identifierSchemeCodeUnspsc190501.95121641': __('identifierSchemeCodeUnspsc190501.95121641'),
	'identifierSchemeCodeUnspsc190501.95121642': __('identifierSchemeCodeUnspsc190501.95121642'),
	'identifierSchemeCodeUnspsc190501.95121643': __('identifierSchemeCodeUnspsc190501.95121643'),
	'identifierSchemeCodeUnspsc190501.95121644': __('identifierSchemeCodeUnspsc190501.95121644'),
	'identifierSchemeCodeUnspsc190501.95121645': __('identifierSchemeCodeUnspsc190501.95121645'),
	'identifierSchemeCodeUnspsc190501.95121646': __('identifierSchemeCodeUnspsc190501.95121646'),
	'identifierSchemeCodeUnspsc190501.95121647': __('identifierSchemeCodeUnspsc190501.95121647'),
	'identifierSchemeCodeUnspsc190501.95121700': __('identifierSchemeCodeUnspsc190501.95121700'),
	'identifierSchemeCodeUnspsc190501.95121701': __('identifierSchemeCodeUnspsc190501.95121701'),
	'identifierSchemeCodeUnspsc190501.95121702': __('identifierSchemeCodeUnspsc190501.95121702'),
	'identifierSchemeCodeUnspsc190501.95121703': __('identifierSchemeCodeUnspsc190501.95121703'),
	'identifierSchemeCodeUnspsc190501.95121704': __('identifierSchemeCodeUnspsc190501.95121704'),
	'identifierSchemeCodeUnspsc190501.95121705': __('identifierSchemeCodeUnspsc190501.95121705'),
	'identifierSchemeCodeUnspsc190501.95121706': __('identifierSchemeCodeUnspsc190501.95121706'),
	'identifierSchemeCodeUnspsc190501.95121707': __('identifierSchemeCodeUnspsc190501.95121707'),
	'identifierSchemeCodeUnspsc190501.95121708': __('identifierSchemeCodeUnspsc190501.95121708'),
	'identifierSchemeCodeUnspsc190501.95121709': __('identifierSchemeCodeUnspsc190501.95121709'),
	'identifierSchemeCodeUnspsc190501.95121710': __('identifierSchemeCodeUnspsc190501.95121710'),
	'identifierSchemeCodeUnspsc190501.95121711': __('identifierSchemeCodeUnspsc190501.95121711'),
	'identifierSchemeCodeUnspsc190501.95121712': __('identifierSchemeCodeUnspsc190501.95121712'),
	'identifierSchemeCodeUnspsc190501.95121713': __('identifierSchemeCodeUnspsc190501.95121713'),
	'identifierSchemeCodeUnspsc190501.95121714': __('identifierSchemeCodeUnspsc190501.95121714'),
	'identifierSchemeCodeUnspsc190501.95121715': __('identifierSchemeCodeUnspsc190501.95121715'),
	'identifierSchemeCodeUnspsc190501.95121800': __('identifierSchemeCodeUnspsc190501.95121800'),
	'identifierSchemeCodeUnspsc190501.95121801': __('identifierSchemeCodeUnspsc190501.95121801'),
	'identifierSchemeCodeUnspsc190501.95121802': __('identifierSchemeCodeUnspsc190501.95121802'),
	'identifierSchemeCodeUnspsc190501.95121803': __('identifierSchemeCodeUnspsc190501.95121803'),
	'identifierSchemeCodeUnspsc190501.95121804': __('identifierSchemeCodeUnspsc190501.95121804'),
	'identifierSchemeCodeUnspsc190501.95121805': __('identifierSchemeCodeUnspsc190501.95121805'),
	'identifierSchemeCodeUnspsc190501.95121806': __('identifierSchemeCodeUnspsc190501.95121806'),
	'identifierSchemeCodeUnspsc190501.95121807': __('identifierSchemeCodeUnspsc190501.95121807'),
	'identifierSchemeCodeUnspsc190501.95121808': __('identifierSchemeCodeUnspsc190501.95121808'),
	'identifierSchemeCodeUnspsc190501.95121809': __('identifierSchemeCodeUnspsc190501.95121809'),
	'identifierSchemeCodeUnspsc190501.95121810': __('identifierSchemeCodeUnspsc190501.95121810'),
	'identifierSchemeCodeUnspsc190501.95121811': __('identifierSchemeCodeUnspsc190501.95121811'),
	'identifierSchemeCodeUnspsc190501.95121812': __('identifierSchemeCodeUnspsc190501.95121812'),
	'identifierSchemeCodeUnspsc190501.95121900': __('identifierSchemeCodeUnspsc190501.95121900'),
	'identifierSchemeCodeUnspsc190501.95121901': __('identifierSchemeCodeUnspsc190501.95121901'),
	'identifierSchemeCodeUnspsc190501.95121902': __('identifierSchemeCodeUnspsc190501.95121902'),
	'identifierSchemeCodeUnspsc190501.95121903': __('identifierSchemeCodeUnspsc190501.95121903'),
	'identifierSchemeCodeUnspsc190501.95121904': __('identifierSchemeCodeUnspsc190501.95121904'),
	'identifierSchemeCodeUnspsc190501.95121905': __('identifierSchemeCodeUnspsc190501.95121905'),
	'identifierSchemeCodeUnspsc190501.95121906': __('identifierSchemeCodeUnspsc190501.95121906'),
	'identifierSchemeCodeUnspsc190501.95121907': __('identifierSchemeCodeUnspsc190501.95121907'),
	'identifierSchemeCodeUnspsc190501.95121908': __('identifierSchemeCodeUnspsc190501.95121908'),
	'identifierSchemeCodeUnspsc190501.95121909': __('identifierSchemeCodeUnspsc190501.95121909'),
	'identifierSchemeCodeUnspsc190501.95121910': __('identifierSchemeCodeUnspsc190501.95121910'),
	'identifierSchemeCodeUnspsc190501.95121911': __('identifierSchemeCodeUnspsc190501.95121911'),
	'identifierSchemeCodeUnspsc190501.95121913': __('identifierSchemeCodeUnspsc190501.95121913'),
	'identifierSchemeCodeUnspsc190501.95122000': __('identifierSchemeCodeUnspsc190501.95122000'),
	'identifierSchemeCodeUnspsc190501.95122001': __('identifierSchemeCodeUnspsc190501.95122001'),
	'identifierSchemeCodeUnspsc190501.95122002': __('identifierSchemeCodeUnspsc190501.95122002'),
	'identifierSchemeCodeUnspsc190501.95122003': __('identifierSchemeCodeUnspsc190501.95122003'),
	'identifierSchemeCodeUnspsc190501.95122004': __('identifierSchemeCodeUnspsc190501.95122004'),
	'identifierSchemeCodeUnspsc190501.95122005': __('identifierSchemeCodeUnspsc190501.95122005'),
	'identifierSchemeCodeUnspsc190501.95122006': __('identifierSchemeCodeUnspsc190501.95122006'),
	'identifierSchemeCodeUnspsc190501.95122007': __('identifierSchemeCodeUnspsc190501.95122007'),
	'identifierSchemeCodeUnspsc190501.95122008': __('identifierSchemeCodeUnspsc190501.95122008'),
	'identifierSchemeCodeUnspsc190501.95122100': __('identifierSchemeCodeUnspsc190501.95122100'),
	'identifierSchemeCodeUnspsc190501.95122101': __('identifierSchemeCodeUnspsc190501.95122101'),
	'identifierSchemeCodeUnspsc190501.95122102': __('identifierSchemeCodeUnspsc190501.95122102'),
	'identifierSchemeCodeUnspsc190501.95122103': __('identifierSchemeCodeUnspsc190501.95122103'),
	'identifierSchemeCodeUnspsc190501.95122104': __('identifierSchemeCodeUnspsc190501.95122104'),
	'identifierSchemeCodeUnspsc190501.95122105': __('identifierSchemeCodeUnspsc190501.95122105'),
	'identifierSchemeCodeUnspsc190501.95122106': __('identifierSchemeCodeUnspsc190501.95122106'),
	'identifierSchemeCodeUnspsc190501.95122300': __('identifierSchemeCodeUnspsc190501.95122300'),
	'identifierSchemeCodeUnspsc190501.95122301': __('identifierSchemeCodeUnspsc190501.95122301'),
	'identifierSchemeCodeUnspsc190501.95122302': __('identifierSchemeCodeUnspsc190501.95122302'),
	'identifierSchemeCodeUnspsc190501.95122303': __('identifierSchemeCodeUnspsc190501.95122303'),
	'identifierSchemeCodeUnspsc190501.95122304': __('identifierSchemeCodeUnspsc190501.95122304'),
	'identifierSchemeCodeUnspsc190501.95122305': __('identifierSchemeCodeUnspsc190501.95122305'),
	'identifierSchemeCodeUnspsc190501.95122306': __('identifierSchemeCodeUnspsc190501.95122306'),
	'identifierSchemeCodeUnspsc190501.95122307': __('identifierSchemeCodeUnspsc190501.95122307'),
	'identifierSchemeCodeUnspsc190501.95122308': __('identifierSchemeCodeUnspsc190501.95122308'),
	'identifierSchemeCodeUnspsc190501.95122400': __('identifierSchemeCodeUnspsc190501.95122400'),
	'identifierSchemeCodeUnspsc190501.95122401': __('identifierSchemeCodeUnspsc190501.95122401'),
	'identifierSchemeCodeUnspsc190501.95122402': __('identifierSchemeCodeUnspsc190501.95122402'),
	'identifierSchemeCodeUnspsc190501.95122403': __('identifierSchemeCodeUnspsc190501.95122403'),
	'identifierSchemeCodeUnspsc190501.95122500': __('identifierSchemeCodeUnspsc190501.95122500'),
	'identifierSchemeCodeUnspsc190501.95122501': __('identifierSchemeCodeUnspsc190501.95122501'),
	'identifierSchemeCodeUnspsc190501.95122502': __('identifierSchemeCodeUnspsc190501.95122502'),
	'identifierSchemeCodeUnspsc190501.95122503': __('identifierSchemeCodeUnspsc190501.95122503'),
	'identifierSchemeCodeUnspsc190501.95122504': __('identifierSchemeCodeUnspsc190501.95122504'),
	'identifierSchemeCodeUnspsc190501.95122505': __('identifierSchemeCodeUnspsc190501.95122505'),
	'identifierSchemeCodeUnspsc190501.95122600': __('identifierSchemeCodeUnspsc190501.95122600'),
	'identifierSchemeCodeUnspsc190501.95122601': __('identifierSchemeCodeUnspsc190501.95122601'),
	'identifierSchemeCodeUnspsc190501.95122602': __('identifierSchemeCodeUnspsc190501.95122602'),
	'identifierSchemeCodeUnspsc190501.95122603': __('identifierSchemeCodeUnspsc190501.95122603'),
	'identifierSchemeCodeUnspsc190501.95122604': __('identifierSchemeCodeUnspsc190501.95122604'),
	'identifierSchemeCodeUnspsc190501.95122605': __('identifierSchemeCodeUnspsc190501.95122605'),
	'identifierSchemeCodeUnspsc190501.95122700': __('identifierSchemeCodeUnspsc190501.95122700'),
	'identifierSchemeCodeUnspsc190501.95122701': __('identifierSchemeCodeUnspsc190501.95122701'),
	'identifierSchemeCodeUnspsc190501.95122702': __('identifierSchemeCodeUnspsc190501.95122702'),
	'identifierSchemeCodeUnspsc190501.95122703': __('identifierSchemeCodeUnspsc190501.95122703'),
	'identifierSchemeCodeUnspsc190501.95130000': __('identifierSchemeCodeUnspsc190501.95130000'),
	'identifierSchemeCodeUnspsc190501.95131500': __('identifierSchemeCodeUnspsc190501.95131500'),
	'identifierSchemeCodeUnspsc190501.95131501': __('identifierSchemeCodeUnspsc190501.95131501'),
	'identifierSchemeCodeUnspsc190501.95131502': __('identifierSchemeCodeUnspsc190501.95131502'),
	'identifierSchemeCodeUnspsc190501.95131503': __('identifierSchemeCodeUnspsc190501.95131503'),
	'identifierSchemeCodeUnspsc190501.95131600': __('identifierSchemeCodeUnspsc190501.95131600'),
	'identifierSchemeCodeUnspsc190501.95131601': __('identifierSchemeCodeUnspsc190501.95131601'),
	'identifierSchemeCodeUnspsc190501.95131602': __('identifierSchemeCodeUnspsc190501.95131602'),
	'identifierSchemeCodeUnspsc190501.95131603': __('identifierSchemeCodeUnspsc190501.95131603'),
	'identifierSchemeCodeUnspsc190501.95131604': __('identifierSchemeCodeUnspsc190501.95131604'),
	'identifierSchemeCodeUnspsc190501.95131605': __('identifierSchemeCodeUnspsc190501.95131605'),
	'identifierSchemeCodeUnspsc190501.95131606': __('identifierSchemeCodeUnspsc190501.95131606'),
	'identifierSchemeCodeUnspsc190501.95131700': __('identifierSchemeCodeUnspsc190501.95131700'),
	'identifierSchemeCodeUnspsc190501.95131701': __('identifierSchemeCodeUnspsc190501.95131701'),
	'identifierSchemeCodeUnspsc190501.95131702': __('identifierSchemeCodeUnspsc190501.95131702'),
	'identifierSchemeCodeUnspsc190501.95140000': __('identifierSchemeCodeUnspsc190501.95140000'),
	'identifierSchemeCodeUnspsc190501.95141500': __('identifierSchemeCodeUnspsc190501.95141500'),
	'identifierSchemeCodeUnspsc190501.95141501': __('identifierSchemeCodeUnspsc190501.95141501'),
	'identifierSchemeCodeUnspsc190501.95141502': __('identifierSchemeCodeUnspsc190501.95141502'),
	'identifierSchemeCodeUnspsc190501.95141600': __('identifierSchemeCodeUnspsc190501.95141600'),
	'identifierSchemeCodeUnspsc190501.95141601': __('identifierSchemeCodeUnspsc190501.95141601'),
	'identifierSchemeCodeUnspsc190501.95141602': __('identifierSchemeCodeUnspsc190501.95141602'),
	'identifierSchemeCodeUnspsc190501.95141603': __('identifierSchemeCodeUnspsc190501.95141603'),
	'identifierSchemeCodeUnspsc190501.95141604': __('identifierSchemeCodeUnspsc190501.95141604'),
	'identifierSchemeCodeUnspsc190501.95141605': __('identifierSchemeCodeUnspsc190501.95141605'),
	'identifierSchemeCodeUnspsc190501.95141606': __('identifierSchemeCodeUnspsc190501.95141606'),
	'identifierSchemeCodeUnspsc190501.95141700': __('identifierSchemeCodeUnspsc190501.95141700'),
	'identifierSchemeCodeUnspsc190501.95141701': __('identifierSchemeCodeUnspsc190501.95141701'),
	'identifierSchemeCodeUnspsc190501.95141702': __('identifierSchemeCodeUnspsc190501.95141702'),
	'identifierSchemeCodeUnspsc190501.95141703': __('identifierSchemeCodeUnspsc190501.95141703'),
	'identifierSchemeCodeUnspsc190501.95141704': __('identifierSchemeCodeUnspsc190501.95141704'),
	'identifierSchemeCodeUnspsc190501.95141705': __('identifierSchemeCodeUnspsc190501.95141705'),
	'identifierSchemeCodeUnspsc190501.95141706': __('identifierSchemeCodeUnspsc190501.95141706'),
	'identifierSchemeCodeUnspsc190501.95141707': __('identifierSchemeCodeUnspsc190501.95141707'),
	'identifierSchemeCodeUnspsc190501.95141708': __('identifierSchemeCodeUnspsc190501.95141708'),
	'identifierSchemeCodeUnspsc190501.95141709': __('identifierSchemeCodeUnspsc190501.95141709'),
	'identifierSchemeCodeUnspsc190501.95141710': __('identifierSchemeCodeUnspsc190501.95141710'),
	'identifierSchemeCodeUnspsc190501.95141711': __('identifierSchemeCodeUnspsc190501.95141711'),
	'identifierSchemeCodeUnspsc190501.95141712': __('identifierSchemeCodeUnspsc190501.95141712'),
	'identifierSchemeCodeUnspsc190501.95141800': __('identifierSchemeCodeUnspsc190501.95141800'),
	'identifierSchemeCodeUnspsc190501.95141801': __('identifierSchemeCodeUnspsc190501.95141801'),
	'identifierSchemeCodeUnspsc190501.95141802': __('identifierSchemeCodeUnspsc190501.95141802'),
	'identifierSchemeCodeUnspsc190501.95141803': __('identifierSchemeCodeUnspsc190501.95141803'),
	'identifierSchemeCodeUnspsc190501.95141900': __('identifierSchemeCodeUnspsc190501.95141900'),
	'identifierSchemeCodeUnspsc190501.95141901': __('identifierSchemeCodeUnspsc190501.95141901'),
	'identifierSchemeCodeUnspsc190501.95141902': __('identifierSchemeCodeUnspsc190501.95141902'),
	'identifierSchemeCodeUnspsc190501.95141903': __('identifierSchemeCodeUnspsc190501.95141903'),
	'identifierSchemeCodeUnspsc190501.95141904': __('identifierSchemeCodeUnspsc190501.95141904'),
};
