import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatService } from '../../core/date-format/date-format.service';
import { Time } from '../../utils/time';

@Pipe({
	name: 'caDate',
})
export class DatePipe implements PipeTransform {

	constructor(private dateFormatService: DateFormatService) {}

	transform(date: string, format: string): string {
		if (date) {
			const dateFormat: string = this.dateFormatService.getDateFormat(format);
			return Time.fromString(date).format(dateFormat);
		} else {
			return '';
		}
	}
}
