import { Injectable, Injector } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { UserPrincipal } from './models/user-info.model';
import { UserPermissions } from './models/user-permission.model';
import { UserRoles } from './models/user-roles.model';

export enum AuthServicePermissionCheckType {
	Any,
	All,
}

@Injectable()
export abstract class AuthService {
	public beforeLogout: Subject<void> = new Subject<void>();

	public abstract initialize(): Promise<boolean>;
	public abstract isLoggedIn(): Observable<boolean>;
	protected abstract performLogout(redirect?: string): Promise<void>;
	public abstract getPermissions(): Observable<UserPermissions>;
	public abstract getUserPrincipal(): Observable<UserPrincipal>;
	public abstract getUserRoles(): Observable<UserRoles>;
	public abstract performLogIn(): void;
	public abstract clearTokens(): void;

	constructor(
		protected configService: ConfigService,
		protected injector: Injector
	) {}

	public logout(redirect?: string): Promise<void> {
		this.beforeLogout.next();
		this.beforeLogout.complete();
		this.deleteAllCookies();

		return this.performLogout(redirect);
	}

	public silentRefresh(): Promise<boolean> { return of(true).toPromise(); }

	protected deleteAllCookies() {
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		}
	}
}
