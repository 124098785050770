<header [ngClass]="isExpanded ? 'expanded' : 'collapsed'">
	<section [@headerContentCollapse]="isExpanded ? 'expanded' : 'collapsed'"
			 class="flex f-justify-content-start">
		<div *ngIf="showBackButton">
			<!-- <ca-back-button (back)="onBack()"></ca-back-button> -->
		</div>
		<div class="flex-none">
			<div class="conainer">
				<div class="row">
					<div class="col-sm-16 col-md-16">
						<div *ngIf="viewData.titleText"
							 class="title">
							{{viewData.titleText}}
							<ng-content select="[titlePostContent]"></ng-content>
						</div>
						<div *ngIf="viewData.secondaryTitle"
							 class="secondary-title">{{viewData.secondaryTitle}}</div>
						<div *ngIf="viewData.breadcrumbs && viewData.breadcrumbs.length > 0">
							<cds-breadcrumbs aria-label="Breadcrumbs">
								<ng-container *ngFor="let subpage of viewData.breadcrumbs; let i = index">
									<a *cdsBreadcrumb
										[routerLink]="subpage.routerLink ? subpage.routerLink : null"
										[ngClass]="{'active': i == viewData.breadcrumbs.length - 1}"
										[attr.aria-current]="i == viewData.breadcrumbs.length - 1 ? 'page' : null">
										{{subpage.displayName}}
									</a>
								</ng-container>
							</cds-breadcrumbs>
						</div>
					</div>
					<div class="col-sm-8 col-md-8">
						<ng-content></ng-content>
					</div>
				</div>
			</div>
		</div>
	</section>
</header>