import { NgForOf } from '@angular/common';
import { SimpleChange, SimpleChanges, Directive, OnChanges, Input } from '@angular/core';

interface NgForInChanges extends SimpleChanges {
	ngForIn?: SimpleChange;
	ngForOf?: SimpleChange;
  }

  @Directive({
	// tslint:disable-next-line: directive-selector
	selector: '[ngFor][ngForIn]',
  })
  // tslint:disable-next-line: directive-class-suffix
  export class NgForIn<T> extends NgForOf<T> implements OnChanges {

	@Input() ngForIn: any;

	ngOnChanges(changes: NgForInChanges): void {
	  if (changes.ngForIn) {
		this.ngForOf = Object.keys(this.ngForIn) as any[];

		const change = changes.ngForIn;
		const currentValue = Object.keys(change.currentValue);
		const previousValue = change.previousValue ?
							  Object.keys(change.previousValue) : undefined;
		changes.ngForOf =  new SimpleChange(previousValue, currentValue, change.firstChange);

		//super.ngOnChanges(changes);
	  }
	}
  }
