import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { FeatureTogglesService } from '../services/feature-toggles.service';

@Directive({
	selector: '[caFeatureToggle]',
})
export class FeatureToggleDirective {
	constructor(
		private _templateRef: TemplateRef<any>,
		private _viewContainer: ViewContainerRef,
		private featureTogglesService: FeatureTogglesService
		) { }

	  @Input() set caFeatureToggle(feature: string) {
		if (feature) {
			if (this.featureTogglesService.isEnabled(feature)) {
				this._viewContainer.createEmbeddedView(this._templateRef);
			  } else {
				this._viewContainer.clear();
			  }
		} else {
			this._viewContainer.createEmbeddedView(this._templateRef);
		}
	}
}
