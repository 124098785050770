import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../core/navigation/navigation.service';

@Component({
	selector: 'ca-modal-router-close',
	template: '',
})
export class ModalRouterCloseComponent {

	constructor(navigationService: NavigationService, route: ActivatedRoute) {
		while (route) {
			const outlet: string = route.routeConfig.outlet;
			if (outlet) {
				navigationService.close(outlet);
				return;
			}
			route = route.parent;
		}
	}
}
