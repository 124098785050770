import { FeatureTogglesGuard } from './../../shared/app/core/auth/feature-toggles-guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'shared/app/core/auth/auth-guard';
import { LayoutComponent } from 'shared/app/core/layout/components/layout/layout.component';
import { LoginLayoutComponent } from 'shared/app/core/layout/components/login-layout/login-layout.component';
import { MessageRouteComponent } from 'shared/app/core/layout/components/message/message-route.component';
import { ModalRouteLayoutComponent } from 'shared/app/core/modal-route-layout/modal-route-layout.component';
import { ModalRouteLayoutModule } from 'shared/app/core/modal-route-layout/modal-route-layout.module';
import { MODAL_ROUTE_START_PATH } from 'shared/app/core/navigation/navigation.model';
import { NavigationModule } from 'shared/app/core/navigation/navigation.module';
import { RouteDataModel } from 'shared/app/core/routing/route-data.model';
import { DOCUMENT_NAVIGATION_KEY, EWC_NAVIGATION, PARTNER_NAVIGATION_KEY } from './app-navigation';

const ROUTES: Routes = [
	///TODO: to be removed when issue is fixed https://fbu-git.krakow.comarch/frontend/angular-seed/issues/27
	{
		path: 'home',
		redirectTo: '',
	},
	///
	{
		path: '',
		component: LayoutComponent,
		children: [
			{
				path: 'document',
				loadChildren: () =>
					import('./document/document-routing.module').then(
						m => m.DocumentRoutingModule
					),
				canActivate: [AuthGuard],
			},
			{
				path: 'profile',
				loadChildren: () =>
					import('shared/app/profile/profile-routing.module').then(
						m => m.ProfileRoutingModule
					),
				canActivate: [AuthGuard, FeatureTogglesGuard],
				data: {
					featureName: 'page.profile',
					isAccountMenuActive: true,
				},
			},
			{
				path: 'partner',
				loadChildren: () =>
					import('./partner/partner-routing.module').then(
						m => m.PartnerRoutingModule
					),
				canActivate: [AuthGuard, FeatureTogglesGuard],
				data: {
					featureName: 'page.partners',
				},
			},
			// {
			// 	path: 'partners',
			// 	loadChildren: () =>
			// 		import('./document/document-routing.module').then(
			// 			m => m.DocumentRoutingModule
			// 		),
			// 	data: <RouteDataModel> {
			// 		//permissions: [BudgetPermission.UnmatchedDocumentForm],
			// 	},
			// 	canActivate: [AuthGuard],
			// },
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'document',
			},
		],
		canActivate: [AuthGuard],
	},
	{
		path: 'message',
		component: LoginLayoutComponent,
		children: [
			{
				path: ':messageType',
				component: MessageRouteComponent,
			},
		],
	},
	// {
	// 	path: 'login',
	// 	component: LoginLayoutComponent,
	// 	children: [
	// 		{
	// 			path: '',
	// 			loadChildren: () => import('shared/app/core/login/login-routing.module').then(m => m.LoginRoutingModule),
	// 		},
	// 	],
	// },
	{
		outlet: 'modal',
		path: MODAL_ROUTE_START_PATH,
		component: ModalRouteLayoutComponent,
		children: [
			{
				path: 'document',
				loadChildren: () =>
					import('./document/document-routing.module').then(
						m => m.DocumentModalRoutingModule
					),
				data: <RouteDataModel> {
					navigationKey: DOCUMENT_NAVIGATION_KEY,
				},
				canActivate: [AuthGuard],
			},
		],
	},
	{
		outlet: 'modal',
		path: MODAL_ROUTE_START_PATH,
		component: ModalRouteLayoutComponent,
		children: [
			{
				path: 'partner',
				loadChildren: () =>
					import('./partner/partner-routing.module').then(
						m => m.PartnerModalRoutingModule
					),
				data: <RouteDataModel> {
					navigationKey: PARTNER_NAVIGATION_KEY,
				},
				canActivate: [AuthGuard],
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(ROUTES, {
			onSameUrlNavigation: 'reload',
		}),
		ModalRouteLayoutModule,
		NavigationModule.forRoot(EWC_NAVIGATION),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
