import { __ } from 'seed/lib/i18n';

export const identifierSchemeCodeIsoIec6523 = {
	'identifierSchemeCodeIsoIec6523.0002': __('identifierSchemeCodeIsoIec6523.0002'),
	'identifierSchemeCodeIsoIec6523.0003': __('identifierSchemeCodeIsoIec6523.0003'),
	'identifierSchemeCodeIsoIec6523.0004': __('identifierSchemeCodeIsoIec6523.0004'),
	'identifierSchemeCodeIsoIec6523.0005': __('identifierSchemeCodeIsoIec6523.0005'),
	'identifierSchemeCodeIsoIec6523.0006': __('identifierSchemeCodeIsoIec6523.0006'),
	'identifierSchemeCodeIsoIec6523.0007': __('identifierSchemeCodeIsoIec6523.0007'),
	'identifierSchemeCodeIsoIec6523.0008': __('identifierSchemeCodeIsoIec6523.0008'),
	'identifierSchemeCodeIsoIec6523.0009': __('identifierSchemeCodeIsoIec6523.0009'),
	'identifierSchemeCodeIsoIec6523.0010': __('identifierSchemeCodeIsoIec6523.0010'),
	'identifierSchemeCodeIsoIec6523.0011': __('identifierSchemeCodeIsoIec6523.0011'),
	'identifierSchemeCodeIsoIec6523.0012': __('identifierSchemeCodeIsoIec6523.0012'),
	'identifierSchemeCodeIsoIec6523.0013': __('identifierSchemeCodeIsoIec6523.0013'),
	'identifierSchemeCodeIsoIec6523.0014': __('identifierSchemeCodeIsoIec6523.0014'),
	'identifierSchemeCodeIsoIec6523.0015': __('identifierSchemeCodeIsoIec6523.0015'),
	'identifierSchemeCodeIsoIec6523.0016': __('identifierSchemeCodeIsoIec6523.0016'),
	'identifierSchemeCodeIsoIec6523.0017': __('identifierSchemeCodeIsoIec6523.0017'),
	'identifierSchemeCodeIsoIec6523.0018': __('identifierSchemeCodeIsoIec6523.0018'),
	'identifierSchemeCodeIsoIec6523.0019': __('identifierSchemeCodeIsoIec6523.0019'),
	'identifierSchemeCodeIsoIec6523.0020': __('identifierSchemeCodeIsoIec6523.0020'),
	'identifierSchemeCodeIsoIec6523.0021': __('identifierSchemeCodeIsoIec6523.0021'),
	'identifierSchemeCodeIsoIec6523.0022': __('identifierSchemeCodeIsoIec6523.0022'),
	'identifierSchemeCodeIsoIec6523.0023': __('identifierSchemeCodeIsoIec6523.0023'),
	'identifierSchemeCodeIsoIec6523.0024': __('identifierSchemeCodeIsoIec6523.0024'),
	'identifierSchemeCodeIsoIec6523.0025': __('identifierSchemeCodeIsoIec6523.0025'),
	'identifierSchemeCodeIsoIec6523.0026': __('identifierSchemeCodeIsoIec6523.0026'),
	'identifierSchemeCodeIsoIec6523.0027': __('identifierSchemeCodeIsoIec6523.0027'),
	'identifierSchemeCodeIsoIec6523.0028': __('identifierSchemeCodeIsoIec6523.0028'),
	'identifierSchemeCodeIsoIec6523.0029': __('identifierSchemeCodeIsoIec6523.0029'),
	'identifierSchemeCodeIsoIec6523.0030': __('identifierSchemeCodeIsoIec6523.0030'),
	'identifierSchemeCodeIsoIec6523.0031': __('identifierSchemeCodeIsoIec6523.0031'),
	'identifierSchemeCodeIsoIec6523.0032': __('identifierSchemeCodeIsoIec6523.0032'),
	'identifierSchemeCodeIsoIec6523.0033': __('identifierSchemeCodeIsoIec6523.0033'),
	'identifierSchemeCodeIsoIec6523.0034': __('identifierSchemeCodeIsoIec6523.0034'),
	'identifierSchemeCodeIsoIec6523.0035': __('identifierSchemeCodeIsoIec6523.0035'),
	'identifierSchemeCodeIsoIec6523.0036': __('identifierSchemeCodeIsoIec6523.0036'),
	'identifierSchemeCodeIsoIec6523.0037': __('identifierSchemeCodeIsoIec6523.0037'),
	'identifierSchemeCodeIsoIec6523.0038': __('identifierSchemeCodeIsoIec6523.0038'),
	'identifierSchemeCodeIsoIec6523.0039': __('identifierSchemeCodeIsoIec6523.0039'),
	'identifierSchemeCodeIsoIec6523.0040': __('identifierSchemeCodeIsoIec6523.0040'),
	'identifierSchemeCodeIsoIec6523.0041': __('identifierSchemeCodeIsoIec6523.0041'),
	'identifierSchemeCodeIsoIec6523.0042': __('identifierSchemeCodeIsoIec6523.0042'),
	'identifierSchemeCodeIsoIec6523.0043': __('identifierSchemeCodeIsoIec6523.0043'),
	'identifierSchemeCodeIsoIec6523.0044': __('identifierSchemeCodeIsoIec6523.0044'),
	'identifierSchemeCodeIsoIec6523.0045': __('identifierSchemeCodeIsoIec6523.0045'),
	'identifierSchemeCodeIsoIec6523.0046': __('identifierSchemeCodeIsoIec6523.0046'),
	'identifierSchemeCodeIsoIec6523.0047': __('identifierSchemeCodeIsoIec6523.0047'),
	'identifierSchemeCodeIsoIec6523.0048': __('identifierSchemeCodeIsoIec6523.0048'),
	'identifierSchemeCodeIsoIec6523.0049': __('identifierSchemeCodeIsoIec6523.0049'),
	'identifierSchemeCodeIsoIec6523.0050': __('identifierSchemeCodeIsoIec6523.0050'),
	'identifierSchemeCodeIsoIec6523.0051': __('identifierSchemeCodeIsoIec6523.0051'),
	'identifierSchemeCodeIsoIec6523.0052': __('identifierSchemeCodeIsoIec6523.0052'),
	'identifierSchemeCodeIsoIec6523.0053': __('identifierSchemeCodeIsoIec6523.0053'),
	'identifierSchemeCodeIsoIec6523.0054': __('identifierSchemeCodeIsoIec6523.0054'),
	'identifierSchemeCodeIsoIec6523.0055': __('identifierSchemeCodeIsoIec6523.0055'),
	'identifierSchemeCodeIsoIec6523.0056': __('identifierSchemeCodeIsoIec6523.0056'),
	'identifierSchemeCodeIsoIec6523.0057': __('identifierSchemeCodeIsoIec6523.0057'),
	'identifierSchemeCodeIsoIec6523.0058': __('identifierSchemeCodeIsoIec6523.0058'),
	'identifierSchemeCodeIsoIec6523.0059': __('identifierSchemeCodeIsoIec6523.0059'),
	'identifierSchemeCodeIsoIec6523.0060': __('identifierSchemeCodeIsoIec6523.0060'),
	'identifierSchemeCodeIsoIec6523.0061': __('identifierSchemeCodeIsoIec6523.0061'),
	'identifierSchemeCodeIsoIec6523.0062': __('identifierSchemeCodeIsoIec6523.0062'),
	'identifierSchemeCodeIsoIec6523.0063': __('identifierSchemeCodeIsoIec6523.0063'),
	'identifierSchemeCodeIsoIec6523.0064': __('identifierSchemeCodeIsoIec6523.0064'),
	'identifierSchemeCodeIsoIec6523.0065': __('identifierSchemeCodeIsoIec6523.0065'),
	'identifierSchemeCodeIsoIec6523.0066': __('identifierSchemeCodeIsoIec6523.0066'),
	'identifierSchemeCodeIsoIec6523.0067': __('identifierSchemeCodeIsoIec6523.0067'),
	'identifierSchemeCodeIsoIec6523.0068': __('identifierSchemeCodeIsoIec6523.0068'),
	'identifierSchemeCodeIsoIec6523.0069': __('identifierSchemeCodeIsoIec6523.0069'),
	'identifierSchemeCodeIsoIec6523.0070': __('identifierSchemeCodeIsoIec6523.0070'),
	'identifierSchemeCodeIsoIec6523.0071': __('identifierSchemeCodeIsoIec6523.0071'),
	'identifierSchemeCodeIsoIec6523.0072': __('identifierSchemeCodeIsoIec6523.0072'),
	'identifierSchemeCodeIsoIec6523.0073': __('identifierSchemeCodeIsoIec6523.0073'),
	'identifierSchemeCodeIsoIec6523.0074': __('identifierSchemeCodeIsoIec6523.0074'),
	'identifierSchemeCodeIsoIec6523.0075': __('identifierSchemeCodeIsoIec6523.0075'),
	'identifierSchemeCodeIsoIec6523.0076': __('identifierSchemeCodeIsoIec6523.0076'),
	'identifierSchemeCodeIsoIec6523.0077': __('identifierSchemeCodeIsoIec6523.0077'),
	'identifierSchemeCodeIsoIec6523.0078': __('identifierSchemeCodeIsoIec6523.0078'),
	'identifierSchemeCodeIsoIec6523.0079': __('identifierSchemeCodeIsoIec6523.0079'),
	'identifierSchemeCodeIsoIec6523.0080': __('identifierSchemeCodeIsoIec6523.0080'),
	'identifierSchemeCodeIsoIec6523.0081': __('identifierSchemeCodeIsoIec6523.0081'),
	'identifierSchemeCodeIsoIec6523.0082': __('identifierSchemeCodeIsoIec6523.0082'),
	'identifierSchemeCodeIsoIec6523.0083': __('identifierSchemeCodeIsoIec6523.0083'),
	'identifierSchemeCodeIsoIec6523.0084': __('identifierSchemeCodeIsoIec6523.0084'),
	'identifierSchemeCodeIsoIec6523.0085': __('identifierSchemeCodeIsoIec6523.0085'),
	'identifierSchemeCodeIsoIec6523.0086': __('identifierSchemeCodeIsoIec6523.0086'),
	'identifierSchemeCodeIsoIec6523.0087': __('identifierSchemeCodeIsoIec6523.0087'),
	'identifierSchemeCodeIsoIec6523.0088': __('identifierSchemeCodeIsoIec6523.0088'),
	'identifierSchemeCodeIsoIec6523.0089': __('identifierSchemeCodeIsoIec6523.0089'),
	'identifierSchemeCodeIsoIec6523.0090': __('identifierSchemeCodeIsoIec6523.0090'),
	'identifierSchemeCodeIsoIec6523.0091': __('identifierSchemeCodeIsoIec6523.0091'),
	'identifierSchemeCodeIsoIec6523.0093': __('identifierSchemeCodeIsoIec6523.0093'),
	'identifierSchemeCodeIsoIec6523.0094': __('identifierSchemeCodeIsoIec6523.0094'),
	'identifierSchemeCodeIsoIec6523.0095': __('identifierSchemeCodeIsoIec6523.0095'),
	'identifierSchemeCodeIsoIec6523.0096': __('identifierSchemeCodeIsoIec6523.0096'),
	'identifierSchemeCodeIsoIec6523.0097': __('identifierSchemeCodeIsoIec6523.0097'),
	'identifierSchemeCodeIsoIec6523.0098': __('identifierSchemeCodeIsoIec6523.0098'),
	'identifierSchemeCodeIsoIec6523.0099': __('identifierSchemeCodeIsoIec6523.0099'),
	'identifierSchemeCodeIsoIec6523.0100': __('identifierSchemeCodeIsoIec6523.0100'),
	'identifierSchemeCodeIsoIec6523.0101': __('identifierSchemeCodeIsoIec6523.0101'),
	'identifierSchemeCodeIsoIec6523.0102': __('identifierSchemeCodeIsoIec6523.0102'),
	'identifierSchemeCodeIsoIec6523.0104': __('identifierSchemeCodeIsoIec6523.0104'),
	'identifierSchemeCodeIsoIec6523.0105': __('identifierSchemeCodeIsoIec6523.0105'),
	'identifierSchemeCodeIsoIec6523.0106': __('identifierSchemeCodeIsoIec6523.0106'),
	'identifierSchemeCodeIsoIec6523.0107': __('identifierSchemeCodeIsoIec6523.0107'),
	'identifierSchemeCodeIsoIec6523.0108': __('identifierSchemeCodeIsoIec6523.0108'),
	'identifierSchemeCodeIsoIec6523.0109': __('identifierSchemeCodeIsoIec6523.0109'),
	'identifierSchemeCodeIsoIec6523.0110': __('identifierSchemeCodeIsoIec6523.0110'),
	'identifierSchemeCodeIsoIec6523.0111': __('identifierSchemeCodeIsoIec6523.0111'),
	'identifierSchemeCodeIsoIec6523.0112': __('identifierSchemeCodeIsoIec6523.0112'),
	'identifierSchemeCodeIsoIec6523.0113': __('identifierSchemeCodeIsoIec6523.0113'),
	'identifierSchemeCodeIsoIec6523.0114': __('identifierSchemeCodeIsoIec6523.0114'),
	'identifierSchemeCodeIsoIec6523.0115': __('identifierSchemeCodeIsoIec6523.0115'),
	'identifierSchemeCodeIsoIec6523.0116': __('identifierSchemeCodeIsoIec6523.0116'),
	'identifierSchemeCodeIsoIec6523.0117': __('identifierSchemeCodeIsoIec6523.0117'),
	'identifierSchemeCodeIsoIec6523.0118': __('identifierSchemeCodeIsoIec6523.0118'),
	'identifierSchemeCodeIsoIec6523.0119': __('identifierSchemeCodeIsoIec6523.0119'),
	'identifierSchemeCodeIsoIec6523.0120': __('identifierSchemeCodeIsoIec6523.0120'),
	'identifierSchemeCodeIsoIec6523.0121': __('identifierSchemeCodeIsoIec6523.0121'),
	'identifierSchemeCodeIsoIec6523.0122': __('identifierSchemeCodeIsoIec6523.0122'),
	'identifierSchemeCodeIsoIec6523.0123': __('identifierSchemeCodeIsoIec6523.0123'),
	'identifierSchemeCodeIsoIec6523.0124': __('identifierSchemeCodeIsoIec6523.0124'),
	'identifierSchemeCodeIsoIec6523.0125': __('identifierSchemeCodeIsoIec6523.0125'),
	'identifierSchemeCodeIsoIec6523.0126': __('identifierSchemeCodeIsoIec6523.0126'),
	'identifierSchemeCodeIsoIec6523.0127': __('identifierSchemeCodeIsoIec6523.0127'),
	'identifierSchemeCodeIsoIec6523.0128': __('identifierSchemeCodeIsoIec6523.0128'),
	'identifierSchemeCodeIsoIec6523.0129': __('identifierSchemeCodeIsoIec6523.0129'),
	'identifierSchemeCodeIsoIec6523.0130': __('identifierSchemeCodeIsoIec6523.0130'),
	'identifierSchemeCodeIsoIec6523.0131': __('identifierSchemeCodeIsoIec6523.0131'),
	'identifierSchemeCodeIsoIec6523.0132': __('identifierSchemeCodeIsoIec6523.0132'),
	'identifierSchemeCodeIsoIec6523.0133': __('identifierSchemeCodeIsoIec6523.0133'),
	'identifierSchemeCodeIsoIec6523.0134': __('identifierSchemeCodeIsoIec6523.0134'),
	'identifierSchemeCodeIsoIec6523.0135': __('identifierSchemeCodeIsoIec6523.0135'),
	'identifierSchemeCodeIsoIec6523.0136': __('identifierSchemeCodeIsoIec6523.0136'),
	'identifierSchemeCodeIsoIec6523.0137': __('identifierSchemeCodeIsoIec6523.0137'),
	'identifierSchemeCodeIsoIec6523.0138': __('identifierSchemeCodeIsoIec6523.0138'),
	'identifierSchemeCodeIsoIec6523.0139': __('identifierSchemeCodeIsoIec6523.0139'),
	'identifierSchemeCodeIsoIec6523.0140': __('identifierSchemeCodeIsoIec6523.0140'),
	'identifierSchemeCodeIsoIec6523.0141': __('identifierSchemeCodeIsoIec6523.0141'),
	'identifierSchemeCodeIsoIec6523.0142': __('identifierSchemeCodeIsoIec6523.0142'),
	'identifierSchemeCodeIsoIec6523.0143': __('identifierSchemeCodeIsoIec6523.0143'),
	'identifierSchemeCodeIsoIec6523.0144': __('identifierSchemeCodeIsoIec6523.0144'),
	'identifierSchemeCodeIsoIec6523.0145': __('identifierSchemeCodeIsoIec6523.0145'),
	'identifierSchemeCodeIsoIec6523.0146': __('identifierSchemeCodeIsoIec6523.0146'),
	'identifierSchemeCodeIsoIec6523.0147': __('identifierSchemeCodeIsoIec6523.0147'),
	'identifierSchemeCodeIsoIec6523.0148': __('identifierSchemeCodeIsoIec6523.0148'),
	'identifierSchemeCodeIsoIec6523.0149': __('identifierSchemeCodeIsoIec6523.0149'),
	'identifierSchemeCodeIsoIec6523.0150': __('identifierSchemeCodeIsoIec6523.0150'),
	'identifierSchemeCodeIsoIec6523.0151': __('identifierSchemeCodeIsoIec6523.0151'),
	'identifierSchemeCodeIsoIec6523.0152': __('identifierSchemeCodeIsoIec6523.0152'),
	'identifierSchemeCodeIsoIec6523.0153': __('identifierSchemeCodeIsoIec6523.0153'),
	'identifierSchemeCodeIsoIec6523.0154': __('identifierSchemeCodeIsoIec6523.0154'),
	'identifierSchemeCodeIsoIec6523.0155': __('identifierSchemeCodeIsoIec6523.0155'),
	'identifierSchemeCodeIsoIec6523.0156': __('identifierSchemeCodeIsoIec6523.0156'),
	'identifierSchemeCodeIsoIec6523.0157': __('identifierSchemeCodeIsoIec6523.0157'),
	'identifierSchemeCodeIsoIec6523.0158': __('identifierSchemeCodeIsoIec6523.0158'),
	'identifierSchemeCodeIsoIec6523.0159': __('identifierSchemeCodeIsoIec6523.0159'),
	'identifierSchemeCodeIsoIec6523.0160': __('identifierSchemeCodeIsoIec6523.0160'),
	'identifierSchemeCodeIsoIec6523.0161': __('identifierSchemeCodeIsoIec6523.0161'),
	'identifierSchemeCodeIsoIec6523.0162': __('identifierSchemeCodeIsoIec6523.0162'),
	'identifierSchemeCodeIsoIec6523.0163': __('identifierSchemeCodeIsoIec6523.0163'),
	'identifierSchemeCodeIsoIec6523.0164': __('identifierSchemeCodeIsoIec6523.0164'),
	'identifierSchemeCodeIsoIec6523.0165': __('identifierSchemeCodeIsoIec6523.0165'),
	'identifierSchemeCodeIsoIec6523.0166': __('identifierSchemeCodeIsoIec6523.0166'),
	'identifierSchemeCodeIsoIec6523.0167': __('identifierSchemeCodeIsoIec6523.0167'),
	'identifierSchemeCodeIsoIec6523.0168': __('identifierSchemeCodeIsoIec6523.0168'),
	'identifierSchemeCodeIsoIec6523.0169': __('identifierSchemeCodeIsoIec6523.0169'),
	'identifierSchemeCodeIsoIec6523.0170': __('identifierSchemeCodeIsoIec6523.0170'),
	'identifierSchemeCodeIsoIec6523.0171': __('identifierSchemeCodeIsoIec6523.0171'),
	'identifierSchemeCodeIsoIec6523.0172': __('identifierSchemeCodeIsoIec6523.0172'),
	'identifierSchemeCodeIsoIec6523.0173': __('identifierSchemeCodeIsoIec6523.0173'),
	'identifierSchemeCodeIsoIec6523.0174': __('identifierSchemeCodeIsoIec6523.0174'),
	'identifierSchemeCodeIsoIec6523.0175': __('identifierSchemeCodeIsoIec6523.0175'),
	'identifierSchemeCodeIsoIec6523.0176': __('identifierSchemeCodeIsoIec6523.0176'),
	'identifierSchemeCodeIsoIec6523.0177': __('identifierSchemeCodeIsoIec6523.0177'),
	'identifierSchemeCodeIsoIec6523.0178': __('identifierSchemeCodeIsoIec6523.0178'),
	'identifierSchemeCodeIsoIec6523.0179': __('identifierSchemeCodeIsoIec6523.0179'),
	'identifierSchemeCodeIsoIec6523.0180': __('identifierSchemeCodeIsoIec6523.0180'),
	'identifierSchemeCodeIsoIec6523.0183': __('identifierSchemeCodeIsoIec6523.0183'),
	'identifierSchemeCodeIsoIec6523.0184': __('identifierSchemeCodeIsoIec6523.0184'),
	'identifierSchemeCodeIsoIec6523.0185': __('identifierSchemeCodeIsoIec6523.0185'),
	'identifierSchemeCodeIsoIec6523.0186': __('identifierSchemeCodeIsoIec6523.0186'),
	'identifierSchemeCodeIsoIec6523.0187': __('identifierSchemeCodeIsoIec6523.0187'),
	'identifierSchemeCodeIsoIec6523.0188': __('identifierSchemeCodeIsoIec6523.0188'),
	'identifierSchemeCodeIsoIec6523.0189': __('identifierSchemeCodeIsoIec6523.0189'),
	'identifierSchemeCodeIsoIec6523.0190': __('identifierSchemeCodeIsoIec6523.0190'),
	'identifierSchemeCodeIsoIec6523.0191': __('identifierSchemeCodeIsoIec6523.0191'),
	'identifierSchemeCodeIsoIec6523.0192': __('identifierSchemeCodeIsoIec6523.0192'),
	'identifierSchemeCodeIsoIec6523.0193': __('identifierSchemeCodeIsoIec6523.0193'),
	'identifierSchemeCodeIsoIec6523.0194': __('identifierSchemeCodeIsoIec6523.0194'),
	'identifierSchemeCodeIsoIec6523.0195': __('identifierSchemeCodeIsoIec6523.0195'),
	'identifierSchemeCodeIsoIec6523.0196': __('identifierSchemeCodeIsoIec6523.0196'),
	'identifierSchemeCodeIsoIec6523.0197': __('identifierSchemeCodeIsoIec6523.0197'),
	'identifierSchemeCodeIsoIec6523.0198': __('identifierSchemeCodeIsoIec6523.0198'),
	'identifierSchemeCodeIsoIec6523.0199': __('identifierSchemeCodeIsoIec6523.0199'),
	'identifierSchemeCodeIsoIec6523.0200': __('identifierSchemeCodeIsoIec6523.0200'),
	'identifierSchemeCodeIsoIec6523.0201': __('identifierSchemeCodeIsoIec6523.0201'),
	'identifierSchemeCodeIsoIec6523.0202': __('identifierSchemeCodeIsoIec6523.0202'),
	'identifierSchemeCodeIsoIec6523.0203': __('identifierSchemeCodeIsoIec6523.0203'),
	'identifierSchemeCodeIsoIec6523.0204': __('identifierSchemeCodeIsoIec6523.0204'),

	// IDENTIFIER SCHEME CODE CEF EAS
	'identifierSchemeCodeCefEas.0002': __('identifierSchemeCodeCefEas.0002'),
	'identifierSchemeCodeCefEas.0007': __('identifierSchemeCodeCefEas.0007'),
	'identifierSchemeCodeCefEas.0009': __('identifierSchemeCodeCefEas.0009'),
	'identifierSchemeCodeCefEas.0037': __('identifierSchemeCodeCefEas.0037'),
	'identifierSchemeCodeCefEas.0060': __('identifierSchemeCodeCefEas.0060'),
	'identifierSchemeCodeCefEas.0088': __('identifierSchemeCodeCefEas.0088'),
	'identifierSchemeCodeCefEas.0096': __('identifierSchemeCodeCefEas.0096'),
	'identifierSchemeCodeCefEas.0097': __('identifierSchemeCodeCefEas.0097'),
	'identifierSchemeCodeCefEas.0106': __('identifierSchemeCodeCefEas.0106'),
	'identifierSchemeCodeCefEas.0130': __('identifierSchemeCodeCefEas.0130'),
	'identifierSchemeCodeCefEas.0135': __('identifierSchemeCodeCefEas.0135'),
	'identifierSchemeCodeCefEas.0142': __('identifierSchemeCodeCefEas.0142'),
	'identifierSchemeCodeCefEas.0151': __('identifierSchemeCodeCefEas.0151'),
	'identifierSchemeCodeCefEas.0183': __('identifierSchemeCodeCefEas.0183'),
	'identifierSchemeCodeCefEas.0184': __('identifierSchemeCodeCefEas.0184'),
	'identifierSchemeCodeCefEas.0190': __('identifierSchemeCodeCefEas.0190'),
	'identifierSchemeCodeCefEas.0191': __('identifierSchemeCodeCefEas.0191'),
	'identifierSchemeCodeCefEas.0192': __('identifierSchemeCodeCefEas.0192'),
	'identifierSchemeCodeCefEas.0193': __('identifierSchemeCodeCefEas.0193'),
	'identifierSchemeCodeCefEas.0194': __('identifierSchemeCodeCefEas.0194'),
	'identifierSchemeCodeCefEas.0195': __('identifierSchemeCodeCefEas.0195'),
	'identifierSchemeCodeCefEas.0196': __('identifierSchemeCodeCefEas.0196'),
	'identifierSchemeCodeCefEas.0198': __('identifierSchemeCodeCefEas.0198'),
	'identifierSchemeCodeCefEas.0199': __('identifierSchemeCodeCefEas.0199'),
	'identifierSchemeCodeCefEas.0200': __('identifierSchemeCodeCefEas.0200'),
	'identifierSchemeCodeCefEas.0201': __('identifierSchemeCodeCefEas.0201'),
	'identifierSchemeCodeCefEas.0202': __('identifierSchemeCodeCefEas.0202'),
	'identifierSchemeCodeCefEas.0203': __('identifierSchemeCodeCefEas.0203'),
	'identifierSchemeCodeCefEas.0204': __('identifierSchemeCodeCefEas.0204'),
	'identifierSchemeCodeCefEas.0208': __('identifierSchemeCodeCefEas.0208'),
	'identifierSchemeCodeCefEas.9901': __('identifierSchemeCodeCefEas.9901'),
	'identifierSchemeCodeCefEas.9906': __('identifierSchemeCodeCefEas.9906'),
	'identifierSchemeCodeCefEas.9907': __('identifierSchemeCodeCefEas.9907'),
	'identifierSchemeCodeCefEas.9910': __('identifierSchemeCodeCefEas.9910'),
	'identifierSchemeCodeCefEas.9913': __('identifierSchemeCodeCefEas.9913'),
	'identifierSchemeCodeCefEas.9914': __('identifierSchemeCodeCefEas.9914'),
	'identifierSchemeCodeCefEas.9915': __('identifierSchemeCodeCefEas.9915'),
	'identifierSchemeCodeCefEas.9918': __('identifierSchemeCodeCefEas.9918'),
	'identifierSchemeCodeCefEas.9919': __('identifierSchemeCodeCefEas.9919'),
	'identifierSchemeCodeCefEas.9920': __('identifierSchemeCodeCefEas.9920'),
	'identifierSchemeCodeCefEas.9922': __('identifierSchemeCodeCefEas.9922'),
	'identifierSchemeCodeCefEas.9923': __('identifierSchemeCodeCefEas.9923'),
	'identifierSchemeCodeCefEas.9924': __('identifierSchemeCodeCefEas.9924'),
	'identifierSchemeCodeCefEas.9925': __('identifierSchemeCodeCefEas.9925'),
	'identifierSchemeCodeCefEas.9926': __('identifierSchemeCodeCefEas.9926'),
	'identifierSchemeCodeCefEas.9927': __('identifierSchemeCodeCefEas.9927'),
	'identifierSchemeCodeCefEas.9928': __('identifierSchemeCodeCefEas.9928'),
	'identifierSchemeCodeCefEas.9929': __('identifierSchemeCodeCefEas.9929'),
	'identifierSchemeCodeCefEas.9930': __('identifierSchemeCodeCefEas.9930'),
	'identifierSchemeCodeCefEas.9931': __('identifierSchemeCodeCefEas.9931'),
	'identifierSchemeCodeCefEas.9932': __('identifierSchemeCodeCefEas.9932'),
	'identifierSchemeCodeCefEas.9933': __('identifierSchemeCodeCefEas.9933'),
	'identifierSchemeCodeCefEas.9934': __('identifierSchemeCodeCefEas.9934'),
	'identifierSchemeCodeCefEas.9935': __('identifierSchemeCodeCefEas.9935'),
	'identifierSchemeCodeCefEas.9936': __('identifierSchemeCodeCefEas.9936'),
	'identifierSchemeCodeCefEas.9937': __('identifierSchemeCodeCefEas.9937'),
	'identifierSchemeCodeCefEas.9938': __('identifierSchemeCodeCefEas.9938'),
	'identifierSchemeCodeCefEas.9939': __('identifierSchemeCodeCefEas.9939'),
	'identifierSchemeCodeCefEas.9940': __('identifierSchemeCodeCefEas.9940'),
	'identifierSchemeCodeCefEas.9941': __('identifierSchemeCodeCefEas.9941'),
	'identifierSchemeCodeCefEas.9942': __('identifierSchemeCodeCefEas.9942'),
	'identifierSchemeCodeCefEas.9943': __('identifierSchemeCodeCefEas.9943'),
	'identifierSchemeCodeCefEas.9944': __('identifierSchemeCodeCefEas.9944'),
	'identifierSchemeCodeCefEas.9945': __('identifierSchemeCodeCefEas.9945'),
	'identifierSchemeCodeCefEas.9946': __('identifierSchemeCodeCefEas.9946'),
	'identifierSchemeCodeCefEas.9947': __('identifierSchemeCodeCefEas.9947'),
	'identifierSchemeCodeCefEas.9948': __('identifierSchemeCodeCefEas.9948'),
	'identifierSchemeCodeCefEas.9949': __('identifierSchemeCodeCefEas.9949'),
	'identifierSchemeCodeCefEas.9950': __('identifierSchemeCodeCefEas.9950'),
	'identifierSchemeCodeCefEas.9951': __('identifierSchemeCodeCefEas.9951'),
	'identifierSchemeCodeCefEas.9952': __('identifierSchemeCodeCefEas.9952'),
	'identifierSchemeCodeCefEas.9953': __('identifierSchemeCodeCefEas.9953'),
	'identifierSchemeCodeCefEas.9955': __('identifierSchemeCodeCefEas.9955'),
	'identifierSchemeCodeCefEas.9956': __('identifierSchemeCodeCefEas.9956'),
	'identifierSchemeCodeCefEas.9957': __('identifierSchemeCodeCefEas.9957'),
	'identifierSchemeCodeCefEas.9958': __('identifierSchemeCodeCefEas.9958'),
	'identifierSchemeCodeCefEas.AN': __('identifierSchemeCodeCefEas.AN'),
	'identifierSchemeCodeCefEas.AQ': __('identifierSchemeCodeCefEas.AQ'),
	'identifierSchemeCodeCefEas.AS': __('identifierSchemeCodeCefEas.AS'),
	'identifierSchemeCodeCefEas.AU': __('identifierSchemeCodeCefEas.AU'),
	'identifierSchemeCodeCefEas.EM': __('identifierSchemeCodeCefEas.EM'),
};
