import * as Pikaday from 'pikaday';
import { enableProdMode, LOCALE_ID, StaticProvider, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { config } from 'config';
import { locales } from './app/locales';
import { AppModule } from './app/app.module';
import { TRANSLATION_SERVICE_LOCALES } from '../shared/app/core/translate/translate.service';

(window as any).Pikaday = Pikaday;

if (config.prodMode) {
	enableProdMode();
}

const providers: StaticProvider[] = [
	{ provide: LOCALE_ID, useValue: config.lang },
	{ provide: TRANSLATION_SERVICE_LOCALES, useValue: locales},
];

platformBrowserDynamic(providers).bootstrapModule(AppModule, {
	defaultEncapsulation: ViewEncapsulation.None,
	providers,
});
