import { DateTimeTranslations } from '@cds/components-angular';
import { __ } from 'seed/lib/i18n';

export const cdsDateTimeTranslations: DateTimeTranslations = {
	applyButton: __('shared.dateTime.applyButton'),
	cancelButton: __('shared.dateTime.cancelButton'),
	clearButton: __('shared.dateTime.clearButton'),
	apply: __('shared.dateTime.apply'),
	cancel: __('shared.dateTime.cancel'),
	selectDateHeader: __('shared.dateTime.selectDateHeader'),
	selectDateRangeHeader: __('shared.dateTime.selectDateRangeHeader'),
	selectTimeHeader: __('shared.dateTime.selectTimeHeader'),
	dateFormat: 'd.MM.yyyy',
	dateTimeFormat: 'd.MM.yyyy H:mm:ss',
	dateRangeFormat: 'yyyy/M/d-yyyy/M/d',
	timeFormat: 'H:mm:ss',
	timeLabel: __('shared.dateTime.timeLabel'),
	timezoneLabel: __('shared.dateTime.timezoneLabel'),
	dayPeriodLabel: __('shared.dateTime.dayPeriodLabel'),
	dateInputLabel: __('shared.dateTime.dateInputLabel'),
};
