import { HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpServiceHandler, HttpServiceInterceptor } from './http-request-handler';

const BUSINESS_ERROR_HTTP_STATUS: number = 422;
const SYSTEM_ERROR_CODE: string = '-1';
const ABORT_ERROR_HTTP_STATUS: number = 0;

@Injectable()
export class ErrorHttpInterceptorService implements HttpServiceInterceptor {

	intercept(req: HttpRequest<any>,  options: {}, next: HttpServiceHandler): Observable<HttpEvent<any>> {
		return next.handle(req, options).pipe(catchError((response: HttpErrorResponse) => {
			if (response.status === BUSINESS_ERROR_HTTP_STATUS) {
				const body = response.error;
				return throwError(new BusinessHttpError(BUSINESS_ERROR_HTTP_STATUS, body.error.code, body.error.model, body.messages));
			} else if (response.status === ABORT_ERROR_HTTP_STATUS) {
				console.error(response);
				return EMPTY;
			} else {
				return throwError(new ServerHttpError(response.status, SYSTEM_ERROR_CODE));
			}
		}));
	}
}

export abstract class HttpError extends Error {
	readonly status: number;
	readonly code: string;
	readonly model: any;
	readonly messages: any[];

	constructor(status, code, model, messages) {
		super(`HttpError{ status: ${status}, code: '${code}' }`);
		this.status = status;
		this.code = code;
		this.model = model;
		this.messages = messages;
	}
}

export class BusinessHttpError extends HttpError {
	constructor(status, code, model, messages) {
		super(status, code, model, messages);
		Object.setPrototypeOf(this, BusinessHttpError.prototype);
	}
}

export class ServerHttpError extends HttpError {
	constructor(status, code) {
		super(status, code, {}, []);
		Object.setPrototypeOf(this, ServerHttpError.prototype);
	}
}

export interface HttpErrorHandlerFunction<ReturnType> {
	(error: HttpError): Observable<ReturnType>|void;
}

export interface CatchHandler<ObservableType> {
	(error: any, caught: Observable<ObservableType>): Observable<ObservableType>;
}

export function systemError<ObservableType>(status: number, handler: HttpErrorHandlerFunction<ObservableType>): CatchHandler<ObservableType> {
	return (err: any) => {
		if (err && err.status === status && err.code === SYSTEM_ERROR_CODE) {
			const result = handler(err);
			if (isObservableNotNull(result)) {
				return result;
			}
			return EMPTY;
		} else {
			return throwError(err);
		}
	};
}

export function businessError<ObservableType>(code: string, handler: HttpErrorHandlerFunction<ObservableType>): CatchHandler<ObservableType> {
	return err => {
		if (err && err.status === BUSINESS_ERROR_HTTP_STATUS && err.code === code) {
			const result = handler(err);
			if (isObservableNotNull(result)) {
				return result;
			}
			return EMPTY;
		} else {
			return throwError(err);
		}
	};
}

function isObservableNotNull<Type>(obj: Observable<Type>|void): obj is Observable<Type> {
	return obj != null;
}
