import { Injectable, InjectionToken, Inject } from '@angular/core';

export type TranslationServiceLocales = { [key: string]: string };
export const TRANSLATION_SERVICE_LOCALES = new InjectionToken<TranslationServiceLocales>('TranslateService LOCALES');

@Injectable({
	providedIn: 'root',
})
export class TranslateService {
	protected static RESOURCE_START_TAG = '_(';
	protected static RESOURCE_END_TAG = ')';
	protected static RESOURCE_NOT_FOUND_TAG = '[NOT_FOUND_IN_LOCALES]';
	protected static RESOURCE_REGEX = /\_\([^)]*\)/g;

	constructor(@Inject(TRANSLATION_SERVICE_LOCALES) private locales: TranslationServiceLocales) {}

	/**
 	 * Get resource by key if contains resource tags. Remember to add resources used by JS to locales!
	 * Plurals not working yet
	 * @param resource resource key with or without _(...) tags
	 * @param parameters resource parameters
	 */
	public getIfResource(resource: string, parameters?: object): string {
		if (this.containsResourceTag(resource)) {
			return this.get(resource, parameters);
		} else {
			return resource;
		}
	}

	/**
 	 * Get resources by keys. Remember to add resources used by JS to locales!
	 * It returns single string if one resource is in parameter and object when array
	 * Plurals not working yet
	 * @param resource resource key (or array) with or without _(...) tags
	 * @param parameters resource parameters
	 */
	public get(resource: string | string[], parameters?: Object): any {
		if (Array.isArray(resource)) {
			const result = {};

			resource.forEach(res => {
				result[this.removeResourceTag(res)] = this.get(res, parameters);
			});

			return result;
		} else if (this.locales && this.locales[resource]) {
			return this.applyParameters(this.locales && this.locales[resource], parameters);
		} else {
			let result = resource;

			this.getMatches(resource)
				.forEach(matchedResource => {
					result = this.replaceMatched(matchedResource, result);
				});

			return result;
		}
	}

	protected getMatches(resource: string): string[] {
		return resource.match(TranslateService.RESOURCE_REGEX) || [];
	}

	protected replaceMatched(matchedResource: string, resource: string, parameters?: Object): string {
		let translation = this.locales && this.locales[this.removeResourceTag(matchedResource)];

		if (!translation) {
			return `${TranslateService.RESOURCE_NOT_FOUND_TAG}${resource}`;
		}

		translation = this.applyParameters(translation, parameters);

		return resource.replace(matchedResource, translation);
	}

	protected removeResourceTag(resource: string): string {
		if (this.containsResourceTag(resource)) {
			resource = resource.substring(2, resource.length - 1);
		}

		return resource;
	}

	protected containsResourceTag(resource: string): boolean {
		return resource.startsWith(TranslateService.RESOURCE_START_TAG) && resource.endsWith(TranslateService.RESOURCE_END_TAG)
			|| this.getMatches(resource).length > 0;
	}

	protected applyParameters(translation: string, parameters: Object): string {
		if (!parameters) {
			return translation;
		}

		Object.keys(parameters)
			.forEach(param => {
				translation = this.replaceAll(translation, `{{${param}}}`, parameters[param]);
			});

		return translation;
	}

	protected replaceAll(target: string, search: string, replacement: string): string {
		return target.split(search).join(replacement);
	}
}
