<div class="footer-item clickable flex f-align-items-center gray-2" #languageMenuAnchor (click)="open=!open">
	<i class="icon-translate cds-icon--size-24"></i>
	<div class="cds-text-2 ml-3" style="flex: auto 1 0">
		{{LANGUAGES[activeLanguage]}}
	</div>
	<i class="cds-icon-caret-down cds-icon--size-24"></i>
</div>
<cds-dropdown *ngIf="open" [anchor]="languageMenuAnchor" (dismiss)="open=false" class="side-bottom-menu side-bottom-menu--2">
	<ul role="menu">
		<li *ngFor="let lang of getLanguages()" role="menuitem" (click)="onLanguageChange(lang)" tabindex="-1">
			{{LANGUAGES[lang]}}
		</li>
	</ul>
</cds-dropdown>