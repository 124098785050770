
import { HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpServiceHandler, HttpServiceInterceptor } from 'framework/app/core/http/http-request-handler';
import { HttpRequestOptions } from 'framework/app/core/http/http.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { NotificationService } from '../../notification/services/notification.service';
import { ObjectHelper } from '../helpers/object.helper';
import { HttpErrorExtended } from '../models/http-error-extended.model';
import { __ } from 'seed/lib/i18n';

@Injectable({
	providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpServiceInterceptor {
	constructor(
		protected notificationService: NotificationService,
		protected authService: AuthService,
	) {

	}

	public intercept(req: HttpRequest<any>, options: HttpRequestOptions, next: HttpServiceHandler): Observable<HttpEvent<any>> {
		return next.handle(req, options)
			.pipe(
				catchError((res: HttpErrorExtended) => {
					if (res.status >= 400) {
						this.handleError(res, options);
						this.logError(res);
					}
					throw res;
				})
			);
	}

	protected handleError(res: HttpErrorExtended, options: HttpRequestOptions): void {
		if (ObjectHelper.isEmpty(res.status)) {
			return;
		}

		if (options.throwSystemErrors && !options.throwSystemErrors.some(x => x === res.status)) {
			return;
		}

		switch (res.status) {
			case 401:
				this.authService.clearTokens();
				this.notificationService.showError(__('shared.core.session_expired'));
				break;
			case 403:
				this.notificationService.showError(__('shared.core.no_permission'));
				break;
			case 422:
				break;
			case 409:
				break;
			default:
				this.notificationService.showError();
		}
	}

	protected logError(res: HttpErrorExtended): void {
		const errorCode = `Error code ${res.status}: `;

		if (!ObjectHelper.isEmpty(res.message)) {
			return console.error(errorCode + res.message);
		}

		if (!ObjectHelper.isEmpty(res.messages)) {
			return console.error(errorCode + res.messages.join('\n'));
		}

		if (!ObjectHelper.isEmpty(res.errors)) {
			console.error(errorCode);
			return console.error(res.errors);
		}
	}
}
