import { BaseNotification } from './notification.model';
import { __ } from 'seed/lib/i18n';

export class WarningNotification extends BaseNotification {
	public get message(): string {
		return this._message || __('notification.warning');
	}

	public get duration(): number {
		return 0;
	}

	constructor(errorMessage?: string) {
		super('warning', errorMessage, __('notification.warning'));
	}
}
