import { __ } from 'seed/lib/i18n';

export const allowanceCode = {
	'allowanceCode.41': __('allowanceCode.41'),
	'allowanceCode.42': __('allowanceCode.42'),
	'allowanceCode.60': __('allowanceCode.60'),
	'allowanceCode.62': __('allowanceCode.62'),
	'allowanceCode.63': __('allowanceCode.63'),
	'allowanceCode.64': __('allowanceCode.64'),
	'allowanceCode.65': __('allowanceCode.65'),
	'allowanceCode.66': __('allowanceCode.66'),
	'allowanceCode.67': __('allowanceCode.67'),
	'allowanceCode.68': __('allowanceCode.68'),
	'allowanceCode.70': __('allowanceCode.70'),
	'allowanceCode.71': __('allowanceCode.71'),
	'allowanceCode.88': __('allowanceCode.88'),
	'allowanceCode.95': __('allowanceCode.95'),
	'allowanceCode.100': __('allowanceCode.100'),
	'allowanceCode.102': __('allowanceCode.102'),
	'allowanceCode.103': __('allowanceCode.103'),
	'allowanceCode.104': __('allowanceCode.104'),
	'allowanceCode.105': __('allowanceCode.105'),
	'allowanceCode.DO_NOT_SAVE_OTHER': __('allowanceCode.DO_NOT_SAVE_OTHER'),
};
