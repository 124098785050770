import { ToastConfig } from '@cds/components-angular';

export type NotificationStatus = 'success' | 'danger' | 'warning' | 'info';
export type NotificationPosition = 'top-right' | 'bottom-right';

export class BaseNotification {
	public showStatusIcon: boolean = true;

	public get status(): NotificationStatus {
		return this._status;
	}

	public get message(): string {
		return this._message || '';
	}

	public get header(): string {
		return this._header || '';
	}

	public get buttons(): BaseNotificationButton[] {
		return this._buttons;
	}

	public get position(): NotificationPosition {
		return this._position || 'bottom-right';
	}

	public get duration(): number {
		return !!this._duration || this._duration === 0 ? this._duration : 5000;
	}

	constructor(
		protected _status: NotificationStatus,
		protected _message: string,
		protected _header?: string,
		protected _buttons?: BaseNotificationButton[],
		protected _position?: NotificationPosition,
		protected _duration?: number
	) {

	}

	public getToastConfig(): ToastConfig {
		return {
			duration: this.duration,
			position: this.position,
		};
	}
}

export class BaseNotificationButton {
	translatedLabel?: string;

	constructor(
		public label: string,
		public value: any,
		public click?: any
	) { }
}

export interface NotificationResult {
	item: BaseNotification;
	value: any;
}
