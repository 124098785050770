import { __ } from 'seed/lib/i18n';

export const form = {
	'form.cancel.button.leave': __('form.cancel.button.leave'),
	'form.cancel.button.stay': __('form.cancel.button.stay'),
	'form.cancel.header': __('form.cancel.header'),
	'form.cancel.question': __('form.cancel.question'),
	'form.error.maxLength': __('form.error.maxLength'),
	'form.error.minLength': __('form.error.minLength'),
	'form.error.maxLengthDigit': __('form.error.maxLengthDigit'),
	'form.error.minLengthDigit': __('form.error.minLengthDigit'),
	'form.error.notUnique': __('form.error.notUnique'),
	'form.error.atLeastOneDateRequired': __('form.error.atLeastOneDateRequired'),
	'form.error.invoiceNumberExists': __('form.error.invoiceNumberExists'),
	'form.error.isoCountryPrefix': __('form.error.isoCountryPrefix'),
	'form.error.anyElementInArray': __('form.error.anyElementInArray'),
	'form.error.mutuallyExclusive': __('form.error.mutuallyExclusive'),
	'form.error.notDifferentWithFields': __('form.error.notDifferentWithFields'),
	'form.error.differentWithFields': __('form.error.differentWithFields'),
	'form.error.notExistWithFields': __('form.error.notExistWithFields'),
	'form.error.notTheSameOperationalSignInFields': __('form.error.notTheSameOperationalSignInFields'),
	'form.error.pattern.email': __('form.error.pattern.email'),
	'form.error.pattern.phone': __('form.error.pattern.phone'),
	'form.error.pattern.naturalNumber': __('form.error.pattern.naturalNumber'),
	'form.error.pattern.realNumber2Decimal': __('form.error.pattern.realNumber2Decimal'),
	'form.error.pattern.realNumber5Decimal': __('form.error.pattern.realNumber5Decimal'),
	'form.error.pattern': __('form.error.pattern'),
	'form.error.required': __('form.error.required'),
	'form.error.min': __('form.error.min'),
	'form.error.max': __('form.error.max'),
	'form.error.taxIdPrefixPostfix': __('form.error.taxIdPrefixPostfix'),
	'form.error.taxIdPrefix': __('form.error.taxIdPrefix'),
	'form.error.isoCountryPrefixDigitsLength': __('form.error.isoCountryPrefixDigitsLength'),
	'form.error.allowedValues': __('form.error.allowedValues'),
	'form.error.onlyDigts': __('form.error.onlyDigts'),
	'form.error.mustStartsWith': __('form.error.mustStartsWith'),
	'form.error.prefixedLength': __('form.error.prefixedLength'),
	'form.headers.accountingContact': __('form.headers.accountingContact'),
	'form.headers.addInvoiceLine': __('form.headers.addInvoiceLine'),
	'form.headers.editInvoiceLine': __('form.headers.editInvoiceLine'),
	'form.headers.addNote': __('form.headers.addNote'),
	'form.headers.editNote': __('form.headers.editNote'),
	'form.headers.additionalInformation': __('form.headers.additionalInformation'),
	'form.headers.addTransferDetails': __('form.headers.addTransferDetails'),
	'form.headers.allowanceDetails': __('form.headers.allowanceDetails'),
	'form.headers.allowanceDetailsAdd': __('form.headers.allowanceDetailsAdd'),
	'form.headers.allowanceDetailsEdit': __('form.headers.allowanceDetailsEdit'),
	'form.headers.attachments': __('form.headers.attachments'),
	'form.headers.buyer.electronicAddress': __('form.headers.buyer.electronicAddress'),
	'form.headers.buyer.identifier': __('form.headers.buyer.identifier'),
	'form.headers.buyer.legalNumber': __('form.headers.buyer.legalNumber'),
	'form.headers.buyer': __('form.headers.buyer'),
	'form.headers.invoiceTo': __('form.headers.invoiceTo'),
	'form.headers.cardInformationDetails': __('form.headers.cardInformationDetails'),
	'form.headers.chargeDetails': __('form.headers.chargeDetails'),
	'form.headers.chargeDetailsAdd': __('form.headers.chargeDetailsAdd'),
	'form.headers.chargeDetailsEdit': __('form.headers.chargeDetailsEdit'),
	'form.headers.creditTransferDetails': __('form.headers.creditTransferDetails'),
	'form.headers.directDebitDetails': __('form.headers.directDebitDetails'),
	'form.headers.debitTransfer': __('form.headers.debitTransfer'),
	'form.headers.paymentToBankAccount': __('form.headers.paymentToBankAccount'),
	'form.headers.editTransferDetails': __('form.headers.editTransferDetails'),
	'form.headers.errors': __('form.headers.errors'),
	'form.headers.errors.description': __('form.headers.errors.description'),
	'form.headers.errors.path': __('form.headers.errors.path'),
	'form.headers.errors.fieldName': __('form.headers.errors.fieldName'),
	'form.headers.errors.correctValue': __('form.headers.errors.correctValue'),
	'form.headers.errors.fieldValue': __('form.headers.errors.fieldValue'),
	'form.headers.inlineAllowances': __('form.headers.inlineAllowances'),
	'form.headers.invoiceAllowances': __('form.headers.invoiceAllowances'),
	'form.headers.invoiceCreditTransfers': __('form.headers.invoiceCreditTransfers'),
	'form.headers.invoiceCreditTransfersSubtitle': __('form.headers.invoiceCreditTransfersSubtitle'),
	'form.headers.invoiceDetails.additionalInvoicePaymentTerms': __('form.headers.invoiceDetails.additionalInvoicePaymentTerms'),
	'form.headers.invoiceDetails.attachments': __('form.headers.invoiceDetails.attachments'),
	'form.headers.invoiceDetails.delivery.address': __('form.headers.invoiceDetails.delivery.address'),
	'form.headers.invoiceDetails.delivery': __('form.headers.invoiceDetails.delivery'),
	'form.headers.invoiceDetails.invoiceObjectReference': __('form.headers.invoiceDetails.invoiceObjectReference'),
	'form.headers.invoiceDetails.note': __('form.headers.invoiceDetails.note'),
	'form.headers.invoiceDetails.notes': __('form.headers.invoiceDetails.notes'),
	'form.headers.invoiceDetails.otherReferences': __('form.headers.invoiceDetails.otherReferences'),
	'form.headers.invoiceDetails.precedingInvoiceReference': __('form.headers.invoiceDetails.precedingInvoiceReference'),
	'form.headers.invoiceDetails': __('form.headers.invoiceDetails'),
	'form.headers.invoiceLines': __('form.headers.invoiceLines'),
	'form.headers.invoiceLinesSubtitle': __('form.headers.invoiceLinesSubtitle'),
	'form.headers.lines.additionalReference': __('form.headers.lines.additionalReference'),
	'form.headers.lines.classification': __('form.headers.lines.classification'),
	'form.headers.payee': __('form.headers.payee'),
	'form.headers.payee.identifier': __('form.headers.payee.identifier'),
	'form.headers.payee.legalNumber': __('form.headers.payee.legalNumber'),
	'form.headers.seller.electronicAddress': __('form.headers.seller.electronicAddress'),
	'form.headers.seller.identifier': __('form.headers.seller.identifier'),
	'form.headers.seller.legalNumber': __('form.headers.seller.legalNumber'),
	'form.headers.seller': __('form.headers.seller'),
	'form.headers.invoiceFrom': __('form.headers.invoiceFrom'),
	'form.headers.standardItemCode': __('form.headers.standardItemCode'),
	'form.headers.taxRepresentative': __('form.headers.taxRepresentative'),
	'form.headers.partnerDetails': __('form.headers.partnerDetails'),
	'form.headers.paymentByPostgiro': __('form.headers.paymentByPostgiro'),
	'form.headers.referenceGiro': __('form.headers.referenceGiro'),
	'form.info.cardInformation.cardNumber': __('form.info.cardInformation.cardNumber'),
	'form.labels.allowanceLine.amount': __('form.labels.allowanceLine.amount'),
	'form.labels.allowanceLine.taxRate': __('form.labels.allowanceLine.taxRate'),
	'form.labels.allowanceOrChargeLine.basisAmount': __('form.labels.allowanceOrChargeLine.basisAmount'),
	'form.labels.allowanceOrChargeLine.percentage': __('form.labels.allowanceOrChargeLine.percentage'),
	'form.labels.allowanceOrChargeLine.reason': __('form.labels.allowanceOrChargeLine.reason'),
	'form.labels.allowanceOrChargeLine.reasonCode': __('form.labels.allowanceOrChargeLine.reasonCode'),
	'form.labels.allowanceOrChargeLine.taxCategoryCode': __('form.labels.allowanceOrChargeLine.taxCategoryCode'),
	'form.labels.attachments.addURL': __('form.labels.attachments.addURL'),
	'form.labels.attachments.document': __('form.labels.attachments.document'),
	'form.labels.attachments.documentDescription': __('form.labels.attachments.documentDescription'),
	'form.labels.attachments.documentID': __('form.labels.attachments.documentID'),
	'form.labels.attachments.documentName': __('form.labels.attachments.documentName'),
	'form.labels.attachments.documentURI': __('form.labels.attachments.documentURI'),
	'form.labels.attachments.addAttachment': __('form.labels.attachments.addAttachment'),
	'form.labels.attachments.editAttachment': __('form.labels.attachments.editAttachment'),
	'form.labels.attachments.editURL': __('form.labels.attachments.editURL'),
	'form.labels.attachments.FileName': __('form.labels.attachments.FileName'),
	'form.labels.attachments.fromDrive': __('form.labels.attachments.fromDrive'),
	'form.labels.attachments.fromURL': __('form.labels.attachments.fromURL'),
	'form.labels.attachments.attachments': __('form.labels.attachments.attachments'),
	'form.labels.attachments.information1': __('form.labels.attachments.information1', '{{1}}'),
	'form.labels.attachments.information2': __('form.labels.attachments.information2', '{{1}}'),
	'form.labels.attachments.MIME': __('form.labels.attachments.MIME'),
	'form.labels.buyer.accountingContactMail': __('form.labels.buyer.accountingContactMail'),
	'form.labels.buyer.accountingContactName': __('form.labels.buyer.accountingContactName'),
	'form.labels.buyer.accountingContactPhone': __('form.labels.buyer.accountingContactPhone'),
	'form.labels.buyer.additionalAddressInformation': __('form.labels.buyer.additionalAddressInformation'),
	'form.labels.buyer.additionalLegalInformation': __('form.labels.buyer.additionalLegalInformation'),
	'form.labels.buyer.additionalStreetAndNumber': __('form.labels.buyer.additionalStreetAndNumber'),
	'form.labels.buyer.address': __('form.labels.buyer.address'),
	'form.labels.buyer.city': __('form.labels.buyer.city'),
	'form.labels.buyer.country': __('form.labels.buyer.country'),
	'form.labels.buyer.electronicAddress': __('form.labels.buyer.electronicAddress'),
	'form.labels.buyer.electronicAddressType': __('form.labels.buyer.electronicAddressType'),
	'form.labels.buyer.fiscalNumber': __('form.labels.buyer.fiscalNumber'),
	'form.labels.buyer.identifier': __('form.labels.buyer.identifier'),
	'form.labels.buyer.identifier.type': __('form.labels.buyer.identifier.type'),
	'form.labels.buyer.legalNumber': __('form.labels.buyer.legalNumber'),
	'form.labels.buyer.legalNumber.type': __('form.labels.buyer.legalNumber.type'),
	'form.labels.buyer.name': __('form.labels.buyer.name'),
	'form.labels.buyer.postalCode': __('form.labels.buyer.postalCode'),
	'form.labels.buyer.province': __('form.labels.buyer.province'),
	'form.labels.buyer.streetAndNumber': __('form.labels.buyer.streetAndNumber'),
	'form.labels.buyer.taxId': __('form.labels.buyer.taxId'),
	'form.labels.buyer.taxIdentifier': __('form.labels.buyer.taxIdentifier'),
	'form.labels.buyer.tradingName': __('form.labels.buyer.tradingName'),
	'form.labels.cardInformation.cardHolderName': __('form.labels.cardInformation.cardHolderName'),
	'form.labels.cardInformation.cardNumber': __('form.labels.cardInformation.cardNumber'),
	'form.labels.chargeLine.amount': __('form.labels.chargeLine.amount'),
	'form.labels.chargeLine.taxRate': __('form.labels.chargeLine.taxRate'),
	'form.labels.creditTransfer.paymentAccountName': __('form.labels.creditTransfer.paymentAccountName'),
	'form.labels.creditTransfer.paymentAccountNumber': __('form.labels.creditTransfer.paymentAccountNumber'),
	'form.labels.creditTransfer.paymentServiceProvider': __('form.labels.creditTransfer.paymentServiceProvider'),
	'form.labels.creditTransferLine.paymentAccountName': __('form.labels.creditTransferLine.paymentAccountName'),
	'form.labels.creditTransferLine.paymentAccountNumber': __('form.labels.creditTransferLine.paymentAccountNumber'),
	'form.labels.directDebit.creditorReference': __('form.labels.directDebit.creditorReference'),
	'form.labels.directDebit.debitedAccountNumber': __('form.labels.directDebit.debitedAccountNumber'),
	'form.labels.directDebit.mandateReference': __('form.labels.directDebit.mandateReference'),
	'form.labels.invoiceAllowances.lineItem.ChargeAmount': __('form.labels.invoiceAllowances.lineItem.ChargeAmount'),
	'form.labels.invoiceAllowances.lineItem.Reason': __('form.labels.invoiceAllowances.lineItem.Reason'),
	'form.labels.invoiceAllowances.lineItem.TaxCategoryCode': __('form.labels.invoiceAllowances.lineItem.TaxCategoryCode'),
	'form.labels.invoiceAllowances.lineItem.Type': __('form.labels.invoiceAllowances.lineItem.Type'),
	'form.labels.invoiceDetails.additionalInvoicePaymentTerms.basisAmount': __('form.labels.invoiceDetails.additionalInvoicePaymentTerms.basisAmount'),
	'form.labels.invoiceDetails.additionalInvoicePaymentTerms.days': __('form.labels.invoiceDetails.additionalInvoicePaymentTerms.days'),
	'form.labels.invoiceDetails.additionalInvoicePaymentTerms.percentage': __('form.labels.invoiceDetails.additionalInvoicePaymentTerms.percentage'),
	'form.labels.invoiceDetails.additionalInvoicePaymentTerms.type': __('form.labels.invoiceDetails.additionalInvoicePaymentTerms.type'),
	'form.labels.invoiceDetails.buyerReference': __('form.labels.invoiceDetails.buyerReference'),
	'form.labels.invoiceDetails.currency': __('form.labels.invoiceDetails.currency'),
	'form.labels.invoiceDetails.delivery.address.city': __('form.labels.invoiceDetails.delivery.address.city'),
	'form.labels.invoiceDetails.delivery.address.country': __('form.labels.invoiceDetails.delivery.address.country'),
	'form.labels.invoiceDetails.delivery.address.postalCode': __('form.labels.invoiceDetails.delivery.address.postalCode'),
	'form.labels.invoiceDetails.delivery.address.postalCodeAndCity': __('form.labels.invoiceDetails.delivery.address.postalCodeAndCity'),
	'form.labels.invoiceDetails.delivery.address.province': __('form.labels.invoiceDetails.delivery.address.province'),
	'form.labels.invoiceDetails.delivery.address.street': __('form.labels.invoiceDetails.delivery.address.street'),
	'form.labels.invoiceDetails.delivery.deliveryDate': __('form.labels.invoiceDetails.delivery.deliveryDate'),
	'form.labels.invoiceDetails.delivery.identifier': __('form.labels.invoiceDetails.delivery.identifier'),
	'form.labels.invoiceDetails.delivery.identifierType': __('form.labels.invoiceDetails.delivery.identifierType'),
	'form.labels.invoiceDetails.delivery.invoicingPeriod': __('form.labels.invoiceDetails.delivery.invoicingPeriod'),
	'form.labels.invoiceDetails.documentType': __('form.labels.invoiceDetails.documentType'),
	'form.labels.invoiceDetails.invoiceDate': __('form.labels.invoiceDetails.invoiceDate'),
	'form.labels.invoiceDetails.invoiceNumber': __('form.labels.invoiceDetails.invoiceNumber'),
	'form.labels.invoiceDetails.invoiceObjectReference.referenceCode': __('form.labels.invoiceDetails.invoiceObjectReference.referenceCode'),
	'form.labels.invoiceDetails.invoiceObjectReference.referenceId': __('form.labels.invoiceDetails.invoiceObjectReference.referenceId'),
	'form.labels.invoiceDetails.notes': __('form.labels.invoiceDetails.notes'),
	'form.labels.invoiceDetails.notes.content': __('form.labels.invoiceDetails.notes.content'),
	'form.labels.invoiceDetails.notes.subjectCode': __('form.labels.invoiceDetails.notes.subjectCode'),
	'form.labels.invoiceDetails.otherReferences.contract': __('form.labels.invoiceDetails.otherReferences.contract'),
	'form.labels.invoiceDetails.otherReferences.despatch': __('form.labels.invoiceDetails.otherReferences.despatch'),
	'form.labels.invoiceDetails.otherReferences.project': __('form.labels.invoiceDetails.otherReferences.project'),
	'form.labels.invoiceDetails.otherReferences.pucharseOrder': __('form.labels.invoiceDetails.otherReferences.pucharseOrder'),
	'form.labels.invoiceDetails.otherReferences.receiving': __('form.labels.invoiceDetails.otherReferences.receiving'),
	'form.labels.invoiceDetails.otherReferences.salesOrder': __('form.labels.invoiceDetails.otherReferences.salesOrder'),
	'form.labels.invoiceDetails.paymentDueDate': __('form.labels.invoiceDetails.paymentDueDate'),
	'form.labels.invoiceDetails.precedingInvoiceReference.precedingInvoiceIssueDate': __('form.labels.invoiceDetails.precedingInvoiceReference.precedingInvoiceIssueDate'),
	'form.labels.invoiceDetails.precedingInvoiceReference.precedingInvoiceNumber': __('form.labels.invoiceDetails.precedingInvoiceReference.precedingInvoiceNumber'),
	'form.labels.invoiceDetails.taxPointDate': __('form.labels.invoiceDetails.taxPointDate'),
	'form.labels.invoiceDetails.taxPointDateCode': __('form.labels.invoiceDetails.taxPointDateCode'),
	'form.labels.invoiceDetails.accountingCost': __('form.labels.invoiceDetails.accountingCost'),
	'form.labels.invoiceDetails.taxCurrency': __('form.labels.invoiceDetails.taxCurrency'),
	'form.labels.invoiceDetails.documentOriginatorId': __('form.labels.invoiceDetails.documentOriginatorId'),
	'form.labels.invoiceDetails.businesProcessType': __('form.labels.invoiceDetails.businesProcessType'),
	'form.labels.invoiceDetails.name': __('form.labels.invoiceDetails.name'),
	'form.labels.invoiceDetails.additionalStreetAndNumber': __('form.labels.invoiceDetails.additionalStreetAndNumber'),
	'form.labels.invoiceDetails.additionalStreetInformation': __('form.labels.invoiceDetails.additionalStreetInformation'),
	'form.labels.invoiceDetails.invoicePaymentTerms': __('form.labels.invoiceDetails.invoicePaymentTerms'),
	'form.labels.invoiceDetails.operator': __('form.labels.invoiceDetails.operator'),
	'form.labels.invoiceDetails.serviceCode': __('form.labels.invoiceDetails.serviceCode'),
	'form.labels.invoiceDetails.commitmentNumber': __('form.labels.invoiceDetails.commitmentNumber'),
	'form.labels.invoiceLines.lineItem.addLine': __('form.labels.invoiceLines.lineItem.addLine'),
	'form.labels.invoiceLines.lineItem.baseQuantityUnitOfMeasure': __('form.labels.invoiceLines.lineItem.baseQuantityUnitOfMeasure'),
	'form.labels.invoiceLines.lineItem.buyerItemCode': __('form.labels.invoiceLines.lineItem.buyerItemCode'),
	'form.labels.invoiceLines.lineItem.buyerReference': __('form.labels.invoiceLines.lineItem.buyerReference'),
	'form.labels.invoiceLines.lineItem.countryOfOrigin': __('form.labels.invoiceLines.lineItem.countryOfOrigin'),
	'form.labels.invoiceLines.lineItem.invoiceQuantity': __('form.labels.invoiceLines.lineItem.invoiceQuantity'),
	'form.labels.invoiceLines.lineItem.invoiceUnitDiscountPrice': __('form.labels.invoiceLines.lineItem.invoiceUnitDiscountPrice'),
	'form.labels.invoiceLines.lineItem.invoiceUnitGrossPrice': __('form.labels.invoiceLines.lineItem.invoiceUnitGrossPrice'),
	'form.labels.invoiceLines.lineItem.invoiceUnitNetPrice': __('form.labels.invoiceLines.lineItem.invoiceUnitNetPrice'),
	'form.labels.invoiceLines.lineItem.itemDescription': __('form.labels.invoiceLines.lineItem.itemDescription'),
	'form.labels.invoiceLines.lineItem.itemNameAndStandardItemCodeValue': __('form.labels.invoiceLines.lineItem.itemNameAndStandardItemCodeValue'),
	'form.labels.invoiceLines.lineItem.lineNumber': __('form.labels.invoiceLines.lineItem.lineNumber'),
	'form.labels.invoiceLines.lineItem.netAmount': __('form.labels.invoiceLines.lineItem.netAmount'),
	'form.labels.invoiceLines.lineItem.orderLineNumber': __('form.labels.invoiceLines.lineItem.orderLineNumber'),
	'form.labels.invoiceLines.lineItem.priceBaseQuantity': __('form.labels.invoiceLines.lineItem.priceBaseQuantity'),
	'form.labels.invoiceLines.lineItem.standardItemCode': __('form.labels.invoiceLines.lineItem.standardItemCode'),
	'form.labels.invoiceLines.lineItem.standardItemCodeType': __('form.labels.invoiceLines.lineItem.standardItemCodeType'),
	'form.labels.invoiceLines.lineItem.standardItemCodeValue': __('form.labels.invoiceLines.lineItem.standardItemCodeValue'),
	'form.labels.invoiceLines.lineItem.supplierItemCode': __('form.labels.invoiceLines.lineItem.supplierItemCode'),
	'form.labels.invoiceLines.lineItem.taxCategoryCode': __('form.labels.invoiceLines.lineItem.taxCategoryCode'),
	'form.labels.invoiceLines.lineItem.taxCategoryCode.disableKReason': __('form.labels.invoiceLines.lineItem.taxCategoryCode.disableKReason'),
	'form.labels.invoiceLines.lineItem.taxRate': __('form.labels.invoiceLines.lineItem.taxRate'),
	'form.labels.invoiceLines.lineItem.unitOfMeasure': __('form.labels.invoiceLines.lineItem.unitOfMeasure'),
	'form.labels.invoiceLines.lineItem.note': __('form.labels.invoiceLines.lineItem.note'),
	'form.labels.invoiceLines.lineItem.invoicingPeriod': __('form.labels.invoiceLines.lineItem.invoicingPeriod'),
	'form.labels.invoiceLines.lineItem.invoicingPeriod.startDate': __('form.labels.invoiceLines.lineItem.invoicingPeriod.startDate'),
	'form.labels.invoiceLines.lineItem.invoicingPeriod.endDate': __('form.labels.invoiceLines.lineItem.invoicingPeriod.endDate'),
	'form.labels.invoiceLines.lineItem.attribute': __('form.labels.invoiceLines.lineItem.attribute'),
	'form.labels.invoiceLines.lineItem.attribute.name': __('form.labels.invoiceLines.lineItem.attribute.name'),
	'form.labels.invoiceLines.lineItem.attribute.value': __('form.labels.invoiceLines.lineItem.attribute.value'),
	'form.labels.invoiceSummary.conversionType': __('form.labels.invoiceSummary.conversionType'),
	'form.labels.invoiceSummary.totalAllowanceAmount': __('form.labels.invoiceSummary.totalAllowanceAmount'),
	'form.labels.invoiceSummary.totalChargeAmount': __('form.labels.invoiceSummary.totalChargeAmount'),
	'form.labels.invoiceSummary.totalDuePayableAmount': __('form.labels.invoiceSummary.totalDuePayableAmount'),
	'form.labels.invoiceSummary.totalGrossAmount': __('form.labels.invoiceSummary.totalGrossAmount'),
	'form.labels.invoiceSummary.totalNetAmount': __('form.labels.invoiceSummary.totalNetAmount'),
	'form.labels.invoiceSummary.totalPrepaidAmount': __('form.labels.invoiceSummary.totalPrepaidAmount'),
	'form.labels.invoiceSummary.totalRoundingAmount': __('form.labels.invoiceSummary.totalRoundingAmount'),
	'form.labels.invoiceSummary.totalTaxableBasis': __('form.labels.invoiceSummary.totalTaxableBasis'),
	'form.labels.invoiceSummary.totalTaxAmount': __('form.labels.invoiceSummary.totalTaxAmount'),
	'form.labels.invoiceSummary.totalTaxAmountInTaxCurrency': __('form.labels.invoiceSummary.totalTaxAmountInTaxCurrency'),
	'form.labels.invoiceTaxSummary.taxableAmount': __('form.labels.invoiceTaxSummary.taxableAmount'),
	'form.labels.invoiceTaxSummary.taxAmount': __('form.labels.invoiceTaxSummary.taxAmount'),
	'form.labels.invoiceTaxSummary.taxCategoryCode': __('form.labels.invoiceTaxSummary.taxCategoryCode'),
	'form.labels.invoiceTaxSummary.taxExemptionReason': __('form.labels.invoiceTaxSummary.taxExemptionReason'),
	'form.labels.invoiceTaxSummary.taxExemptionReasonCode': __('form.labels.invoiceTaxSummary.taxExemptionReasonCode'),
	'form.labels.invoiceTaxSummary.taxRate': __('form.labels.invoiceTaxSummary.taxRate'),
	'form.labels.invoiceTaxSummary.title': __('form.labels.invoiceTaxSummary.title'),
	'form.labels.invoiceLines.lineItem.additionalReference.referenceTypeCode': __('form.labels.invoiceLines.lineItem.additionalReference.referenceTypeCode'),
	'form.labels.invoiceLines.lineItem.additionalReference.issuerID': __('form.labels.invoiceLines.lineItem.additionalReference.issuerID'),
	'form.labels.invoiceLines.lineItem.classification.classCode': __('form.labels.invoiceLines.lineItem.classification.classCode'),
	'form.labels.invoiceLines.lineItem.classification.classCodeScheme': __('form.labels.invoiceLines.lineItem.classification.classCodeScheme'),
	'form.labels.invoiceLines.lineItem.classification.classCodeVersion': __('form.labels.invoiceLines.lineItem.classification.classCodeVersion'),
	'form.labels.payee.identifier': __('form.labels.payee.identifier'),
	'form.labels.payee.legalRegistrationNumber': __('form.labels.payee.legalRegistrationNumber'),
	'form.labels.payee.name': __('form.labels.payee.name'),
	'form.labels.payee.legalNumber.type': __('form.labels.payee.legalNumber.type'),
	'form.labels.payee.identifier.type': __('form.labels.payee.identifier.type'),
	'form.labels.payment.creditTransfer.IBAN': __('form.labels.payment.creditTransfer.IBAN'),
	'form.labels.payment.header': __('form.labels.payment.header'),
	'form.labels.seller.accountingContact.email': __('form.labels.seller.accountingContact.email'),
	'form.labels.seller.accountingContact.name': __('form.labels.seller.accountingContact.name'),
	'form.labels.seller.accountingContact.phone': __('form.labels.seller.accountingContact.phone'),
	'form.labels.seller.additionalAddressInformation': __('form.labels.seller.additionalAddressInformation'),
	'form.labels.seller.additionalLegalInformation': __('form.labels.seller.additionalLegalInformation'),
	'form.labels.seller.additionalStreetAndNumber': __('form.labels.seller.additionalStreetAndNumber'),
	'form.labels.seller.address': __('form.labels.seller.address'),
	'form.labels.seller.city': __('form.labels.seller.city'),
	'form.labels.seller.country': __('form.labels.seller.country'),
	'form.labels.seller.cvr': __('form.labels.seller.cvr'),
	'form.labels.seller.electronicAddress': __('form.labels.seller.electronicAddress'),
	'form.labels.seller.electronicAddressType': __('form.labels.seller.electronicAddressType'),
	'form.labels.seller.fiscalNumber': __('form.labels.seller.fiscalNumber'),
	'form.labels.seller.identifier.type': __('form.labels.seller.identifier.type'),
	'form.labels.seller.identifier': __('form.labels.seller.identifier'),
	'form.labels.seller.legalNumber.type': __('form.labels.seller.legalNumber.type'),
	'form.labels.seller.legalNumber': __('form.labels.seller.legalNumber'),
	'form.labels.seller.name': __('form.labels.seller.name'),
	'form.labels.seller.postalCode': __('form.labels.seller.postalCode'),
	'form.labels.seller.province': __('form.labels.seller.province'),
	'form.labels.seller.streetAndNumber': __('form.labels.seller.streetAndNumber'),
	'form.labels.seller.taxId': __('form.labels.seller.taxId'),
	'form.labels.seller.taxIdentifier': __('form.labels.seller.taxIdentifier'),
	'form.labels.seller.tradingName': __('form.labels.seller.tradingName'),
	'form.labels.taxRepresentative.additionalAddressInformation': __('form.labels.taxRepresentative.additionalAddressInformation'),
	'form.labels.taxRepresentative.additionalStreetAndNumber': __('form.labels.taxRepresentative.additionalStreetAndNumber'),
	'form.labels.taxRepresentative.city': __('form.labels.taxRepresentative.city'),
	'form.labels.taxRepresentative.country': __('form.labels.taxRepresentative.country'),
	'form.labels.taxRepresentative.name': __('form.labels.taxRepresentative.name'),
	'form.labels.taxRepresentative.postalCode': __('form.labels.taxRepresentative.postalCode'),
	'form.labels.taxRepresentative.province': __('form.labels.taxRepresentative.province'),
	'form.labels.taxRepresentative.remarks': __('form.labels.taxRepresentative.remarks'),
	'form.labels.taxRepresentative.street': __('form.labels.taxRepresentative.street'),
	'form.labels.taxRepresentative.taxIdentifier': __('form.labels.taxRepresentative.taxIdentifier'),
	'form.labels.debitTransfer.bankAccount': __('form.labels.debitTransfer.bankAccount'),
	'form.labels.debitTransfer.registrationAccount': __('form.labels.debitTransfer.registrationAccount'),
	'form.labels.paymentToBankAccount.bankAccount': __('form.labels.paymentToBankAccount.bankAccount'),
	'form.labels.paymentToBankAccount.registrationAccount': __('form.labels.paymentToBankAccount.registrationAccount'),
	'form.labels.invoicePayment.invoicePaymentReference': __('form.labels.invoicePayment.invoicePaymentReference'),
	'form.labels.paymentByPostgiro.paymentAccountName': __('form.labels.paymentByPostgiro.paymentAccountName'),
	'form.labels.referenceGiro.paymentAccountName': __('form.labels.referenceGiro.paymentAccountName'),
	'form.validationLabels.seller.tradingName': __('form.validationLabels.seller.tradingName'),
	'form.validationLabels.payee.name': __('form.validationLabels.payee.name'),
	'form.validationLabels.seller.identifier': __('form.validationLabels.seller.identifier'),
	'form.validationLabels.payee.identifier': __('form.validationLabels.payee.identifier'),
	'form.partnerSelect.unknown': __('form.partnerSelect.unknown'),
	'form.section.allowance.addAllowance': __('form.section.allowance.addAllowance'),
	'form.section.allowance.addCharge': __('form.section.allowance.addCharge'),
	'form.section.creditTransfer.addCreditTransfer': __('form.section.creditTransfer.addCreditTransfer'),
	'form.section.manageFields': __('form.section.manageFields'),
	'form.section.selectAll': __('form.section.selectAll'),
	'form.section.additionalActions.saveSellerData': __('form.section.additionalActions.saveSellerData'),
	'form.section.additionalActions.saveAccountingContact': __('form.section.additionalActions.saveAccountingContact'),
	'form.send.error': __('form.send.error'),
	'form.saveDraft.error': __('form.saveDraft.error'),
	'form.send.successful': __('form.send.successful'),
	'form.sendingInProgress': __('form.sendingInProgress'),
	'form.savingInProgress': __('form.savingInProgress'),
	'form.loadingInProgress': __('form.loadingInProgress'),
	'form.saveDraft.successful': __('form.saveDraft.successful'),
	'form.someValuesAreHidden': __('form.someValuesAreHidden', '{{1}}'),
	'form.step.invoiceDetails': __('form.step.invoiceDetails'),
	'form.step.invoiceLines': __('form.step.invoiceLines'),
	'form.step.invoiceParties': __('form.step.invoiceParties'),
	'form.step.invoicePayments': __('form.step.invoicePayments'),
	'form.step.invoiceSummary': __('form.step.invoiceSummary'),
	'form.step.partnerSelect': __('form.step.partnerSelect'),
	'form.switchLabel.payee': __('form.switchLabel.payee'),
	'form.switchLabel.taxRepresentative': __('form.switchLabel.taxRepresentative'),
	'form.allValuesList.header': __('form.allValuesList.header'),
	'form.allValuesList.info': __('form.allValuesList.info'),
	'form.allValuesList.seeAll': __('form.allValuesList.seeAll'),
	'form.allValuesList.column.name': __('form.allValuesList.column.name'),
	'form.allValuesList.column.status': __('form.allValuesList.column.status'),
	'form.allValuesList.column.requirements': __('form.allValuesList.column.requirements'),
	'form.allValuesList.status.available': __('form.allValuesList.status.available'),
	'form.allValuesList.status.unavailable': __('form.allValuesList.status.unavailable'),
	'form.allValuesList.requirement.filled.1': __('form.allValuesList.requirement.filled.1'),
	'form.allValuesList.requirement.filled.2': __('form.allValuesList.requirement.filled.2'),
	'form.allValuesList.requirement.filled.3': __('form.allValuesList.requirement.filled.3'),
	'form.allValuesList.requirement.filled.4': __('form.allValuesList.requirement.filled.4'),
	'form.allValuesList.requirement.filled.5': __('form.allValuesList.requirement.filled.5'),
	'form.allValuesList.requirement.notFilled.1': __('form.allValuesList.requirement.notFilled.1'),
	'form.allValuesList.requirement.oneKindOnLines.1': __('form.allValuesList.requirement.oneKindOnLines.1'),
	'form.values.allowancesAndCharges.allowance': __('form.values.allowancesAndCharges.allowance'),
	'form.values.allowancesAndCharges.charge': __('form.values.allowancesAndCharges.charge'),
};
