import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseLayoutComponent } from '../layout/components/base-layout/base-layout.component';
import { LayoutService } from '../layout/services/layout.service';
import { MenuService } from '../menu/menu.service';
import { NavigationAndRoutingService } from '../navigation/services/navigation-and-routing.service';
import { ModalRouteLayoutViewData } from './modal-route-layout-view-data.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'ca-modal-route-layout',
	templateUrl: 'modal-route-layout.component.html',
})
export class ModalRouteLayoutComponent extends BaseLayoutComponent {
	public viewData: ModalRouteLayoutViewData = {};

	public get isModal() {
		return true;
	}

	constructor(
		protected router: Router,
		protected menuService: MenuService,
		protected navigationAndRoutingService: NavigationAndRoutingService,
		protected layoutService: LayoutService,
	) {
		super(router, menuService, navigationAndRoutingService, layoutService);

	}
}
