import { animate, group, query, style, transition, trigger } from '@angular/animations';
import {
	Attribute,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	HostBinding,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { ChildrenOutletContexts, RouterOutlet } from '@angular/router';

@Component({
	selector: 'ca-modal-router-outlet',
	animations: [
		trigger('modalState', [
			transition('open => close', [
				group([
					query(':leave > router-outlet ~ *',
						animate('700ms linear', style({transform: 'translate3d(0, 0, 0)'})),
						{ optional: true }),
					query(':leave',
						animate('700ms cubic-bezier(0.165, 0.84, 0.44, 1)', style({transform: 'translate3d(0, 20%, 0)', opacity: 0})),
						{ optional: true }),
				]),
			]),
			transition('close => open', [
				query(':enter', [
						style({transform: 'translate3d(0, 20%, 0)', opacity: 0}),
						animate('700ms cubic-bezier(0.165, 0.84, 0.44, 1)', style({transform: 'translate3d(0, 0, 0)', opacity: 1})),
					],
					{ optional: true }),
			]),
		]),
	],
	template: '<ng-container #view></ng-container>',
})
export class ModalRouterOutletComponent {

	private routerOutlet: RouterOutlet;

	@HostBinding('@modalState')
	get modalState(): string {
		return (this.routerOutlet && this.routerOutlet.isActivated) ? 'open' : 'close';
	}

	@ViewChild('view', { read: ViewContainerRef, static: true })
	set view(view: ViewContainerRef) {
		this.routerOutlet = new RouterOutlet(this.parentContexts, view, this.resolver, this.name || 'modal', this.changeDetector);
	}

	constructor(
			private parentContexts: ChildrenOutletContexts,
			private resolver: ComponentFactoryResolver,
			private changeDetector: ChangeDetectorRef,
			@Attribute('name') private name: string) {}
}
