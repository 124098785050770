import { Directive, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

const SPINNER_ACTIVE_DELAY: number = 200;

@Directive({
	selector: '[caSpinner]',
})
export class SpinnerDirective implements OnInit, OnDestroy {

	@HostBinding('class.spinner-active')
	active: boolean = false;

	@HostBinding('class.spinner-closed')
	get closed() { return !this.active; }

	private subscription: Subscription;

	@HostBinding('attr.ca-spinner-container')
	@Input('caSpinner')
	name: string;

	constructor(private spinnerService: SpinnerService) {}

	ngOnInit(): void {
		this.subscription = this.spinnerService.getSpinnerEvents(this.name)
			.pipe(
				distinctUntilChanged((first, second) => first.active === second.active),
				switchMap(event => {
					const delayTime: number = event.delay != null ? event.delay : event.active ? SPINNER_ACTIVE_DELAY : 0;
					if (delayTime) {
						return of(event).pipe(delay(delayTime));
					} else {
						return of(event);
					}
				}),
			)
			.subscribe(event => this.active = event.active);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
