import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SpinnerService {

	private spinnersMap: { [key: string]: SpinnerInstance } = {};

	onStart(spinnerName: string, delay?: number) {
		const spinner = this.getSpinner(spinnerName);
		if (!spinner) return;

		if (spinner.count === 0) {
			spinner.events$.next({ active: true, delay });
		}
		spinner.count++;
	}

	onStop(spinnerName: string) {
		const spinner = this.getSpinner(spinnerName);
		if (!spinner) return;

		if (spinner.count === 1) {
			spinner.events$.next({ active: false });
		}
		spinner.count--;
		if (spinner.count < 0) {
			spinner.count = 0;
		}
	}

	onError(spinnerName: string) {
		const spinner = this.getSpinner(spinnerName);
		if (!spinner) return;

		spinner.events$.next({ active: false });
	}

	public getSpinnerEvents(name: string): Observable<SpinnerEvent> {
		return this.getSpinner(name).events$.asObservable();
	}

	private getSpinner(name: string) {
		let spinnerState = this.spinnersMap[name];
		if (!spinnerState) {
			spinnerState = this.spinnersMap[name] = new SpinnerInstance;
		}
		return spinnerState;
	}
}

class SpinnerInstance {
	count: number = 0;
	events$: BehaviorSubject<SpinnerEvent> = new BehaviorSubject({ active: false });
}

interface SpinnerEvent {
	active: boolean;
	delay?: number;
}
