<cds-app-layout>
	<header *ngIf="viewData.headerVisible">
		<ca-layout-header [viewData]="viewData"></ca-layout-header>
	</header>
	<cds-sidenav [navigation]="navigation" [toggle]="viewData.menuCollapsed" (toggleChange)="toggleMenu($event)"
		[overlay]="true" class="float cds-sidenav--shadow cds-sidenav--fit">
		<ng-template #brand>
			<header class="mt-lg collapsed-hidden">
				<i class="sprite-comarch"></i>
				<div class="logo-text">e-Invoicing Cloud</div>
			</header>
		</ng-template>
		<ng-template #footer>
				<ca-language-menu></ca-language-menu>
				<ca-account-menu [class.active]="viewData.isAccountMenuActive"></ca-account-menu>
		</ng-template>
	</cds-sidenav>
	<main class="full-width full-height overflow-y-auto">
		<router-outlet></router-outlet>
	</main>
</cds-app-layout>
<ca-notification-template-list></ca-notification-template-list>

<!-- NAVIGATION-->
<ng-template #navigation let-nav>
	<ng-container>
		<ng-container *ngIf="initialized">
			<ng-container *ngFor="let item of getEnabledNavigationItems()">
				<ng-container *ngIf="!item.children?.length">
					<a tabindex="0" [cdsNavItem]="nav" [hideOnClick]="true" routerLinkActive="active"
						[routerLink]="item.url">
						<i class="cds-icon--size-2x icon">
							<ng-container *ngTemplateOutlet="getIcon(item.icon)"></ng-container>
						</i>
						{{item.label}}
					</a>
				</ng-container>

				<ng-container *ngIf="item.children?.length> 0">
					<ng-template #subNavigation let-nav>
						<a *ngFor="let child of item.children" [cdsNavItem]="nav" routerLinkActive="active"
							[routerLink]="child.url">
							<i class="cds-icon--size-2x" [ngClass]="item.icon || 'empty-icon'">
							</i>
							{{child.label}}
						</a>
					</ng-template>
					<a tabindex="0" [cdsNavItem]="nav" [hideOnClick]="true" [expand]="subNavigation"
						routerLinkActive="active" [routerLink]="item.url">
						<i class="cds-icon--size-2x" [ngClass]="item.icon || 'empty-icon'">
						</i>
						{{item.label}}
					</a>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>

<!--ICONS-->
<ng-template #invoices>
	<svg class="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
		height="24" viewBox="0 0 24 24">
		<path id="prefix__a_invoices"
			d="M16 0H1.99C.88 0 .01.89.01 2L0 16c0 1.1.88 2 1.99 2H16c1.1 0 2-.9 2-2V2c0-1.11-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H1.99V2H16v10z" />
	</svg>
</ng-template>
<ng-template #partners>
	<svg class="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
		height="24" viewBox="0 0 24 24">
		<path id="prefix__a_invoices"
			d="M15.5 8c-1.2 0-3.07.34-4.5 1-1.43-.67-3.3-1-4.5-1C4.33 8 0 9.08 0 11.25V14h22v-2.75C22 9.08 17.67 8 15.5 8zm-4 4.5h-10v-1.25c0-.54 2.56-1.75 5-1.75s5 1.21 5 1.75v1.25zm9 0H13v-1.25c0-.46-.2-.86-.52-1.22.88-.3 1.96-.53 3.02-.53 2.44 0 5 1.21 5 1.75v1.25zM6.5 7C8.43 7 10 5.43 10 3.5S8.43 0 6.5 0 3 1.57 3 3.5 4.57 7 6.5 7zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 5.5C17.43 7 19 5.43 19 3.5S17.43 0 15.5 0 12 1.57 12 3.5 13.57 7 15.5 7zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z" />
	</svg>
</ng-template>