import { NavigationService } from './../../../../framework/app/core/navigation/navigation.service';
import { FeatureTogglesService } from '../feature-toggles/services/feature-toggles.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FeatureTogglesGuard implements CanActivate {
	constructor(private featureTogglesService: FeatureTogglesService,
		private navigationService: NavigationService) {
	}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const featureName = route.data.featureName;
		const canActivate = this.featureTogglesService.isEnabled(featureName);

		if (!canActivate) {
			this.navigationService.go('');
		}

		return canActivate;
	}
}
