import { __ } from 'seed/lib/i18n';

export const sharedLocales = {
	'shared.profile.userDetails': __('shared.profile.userDetails'),
	'shared.profile.name': __('shared.profile.name'),
	'shared.profile.surname': __('shared.profile.surname'),
	'shared.profile.title': __('shared.profile.title'),
	'shared.profile.title.details': __('shared.profile.title.details'),
	'shared.profile.title.company': __('shared.profile.title.company'),
	'shared.profile.title.password': __('shared.profile.title.password'),
	'shared.profile.breadcumbs.profile': __('shared.profile.breadcumbs.profile'),
	'shared.profile.password': __('shared.profile.password'),
	'shared.profile.password.current': __('shared.profile.password.current'),
	'shared.profile.password.new': __('shared.profile.password.new'),
	'shared.profile.password.validation.header': __('shared.profile.password.validation.header'),
	'shared.profile.password.validation.eightCharacters': __('shared.profile.password.validation.eightCharacters'),
	'shared.profile.password.validation.oneUpperLetter': __('shared.profile.password.validation.oneUpperLetter'),
	'shared.profile.password.validation.oneLowerLetter': __('shared.profile.password.validation.oneLowerLetter'),
	'shared.profile.password.validation.oneNumber': __('shared.profile.password.validation.oneNumber'),
	'shared.profile.password.validation.oneSpecialCharacter': __('shared.profile.password.validation.oneSpecialCharacter'),
	'shared.profile.dataChange.error': __('shared.profile.dataChange.error'),
	'shared.profile.dataChange.success': __('shared.profile.dataChange.success'),
	'shared.profile.passwordChange.error': __('shared.profile.passwordChange.error'),
	'shared.profile.passwordChange.success': __('shared.profile.passwordChange.success'),
	'shared.profile.companyDataChange.success': __('shared.profile.companyDataChange.success'),
	'shared.profile.companyDataChange.error': __('shared.profile.companyDataChange.error'),
	'shared.profile.accountingContactChange.success': __('shared.profile.accountingContactChange.success'),
	'shared.profile.accountingContactChange.error': __('shared.profile.accountingContactChange.error'),
	'shared.appBar.cookie_info': __('shared.appBar.cookie_info'),
	'shared.core.form_error': __('shared.core.form_error'),
	'shared.company.companyDetails.electronicAddress.header': __('shared.company.companyDetails.electronicAddress.header'),
	'shared.company.companyDetails.electronicAddress': __('shared.company.companyDetails.electronicAddress'),
	'shared.company.companyDetails.electronicAddressType': __('shared.company.companyDetails.electronicAddressType'),
	'shared.company.companyDetails.sellerIdentifier': __('shared.company.companyDetails.sellerIdentifier'),
	'shared.company.companyDetails.sellerIdentifier.identifier': __('shared.company.companyDetails.sellerIdentifier.identifier'),
	'shared.company.companyDetails.sellerIdentifier.type': __('shared.company.companyDetails.sellerIdentifier.type'),
	'shared.company.companyDetails.fiscalNumber': __('shared.company.companyDetails.fiscalNumber'),
};
