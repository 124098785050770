import { Component, Input } from '@angular/core';

@Component({
	selector: 'ca-feature-toggle',
	template: `
	<ng-container *caFeatureToggle="feature">
		<ng-content></ng-content>
	</ng-container>
	`,
})
export class FeatureToggleComponent {
	@Input() public feature: string;
}
