import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HTTP_SERVICE_INTERCEPTORS } from 'framework/app/core/http/http-request-handler';

import { HttpErrorInterceptor } from '../utils/interceptors/http-error.interceptor';

@NgModule({
	imports: [CommonModule],
	providers: [
		{
			provide: HTTP_SERVICE_INTERCEPTORS,
			multi: true,
			useClass: HttpErrorInterceptor,
		},
	],
})
export class AuthModule {}
