import { NgModule } from '@angular/core';
import { KeycloakAuthModule } from 'shared/app/core/keycloak-auth/keycloak-auth.module';
import { AuthService } from 'shared/app/core/auth/auth.service';
import { AccountModule } from 'shared/app/core/account/account.module';
import { EwcAuthService } from './ewc-auth.service';

@NgModule({
	imports: [KeycloakAuthModule, AccountModule.forRoot()],
	providers: [
		{
			provide: AuthService,
			useClass: EwcAuthService,
		},
	],
})
export class ProvidersModule {}
