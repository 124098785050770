import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DateFormatService {

	getDateFormat(formatName: string): string {
		return FORMATS_MAP[formatName];
	}
}

const DATE: string = 'dd.MM.y';
const SHORT_DATE: string = 'd.MM.yy';
const SHORT_DAY_DATE: string = 'd.MM.y';
const DATE_TIME: string = 'dd.MM.y HH:mm';
const TIME: string = 'HH:mm';
const SHORT_TIME: string = 'H:mm';
const TIME_WITH_SECONDS: string = 'HH:mm:ss';
const DAY_MONTH: string = 'dd.MM';
const SHORT_DAY_MONTH: string = 'd.MM';
const SHORT_DAY: string = 'd';

const FORMATS_MAP: {[key: string] : string} = {
	date: DATE,
	shortDate: SHORT_DATE,
	shortDayDate: SHORT_DAY_DATE,
	dateTime: DATE_TIME,
	time: TIME,
	shortTime: SHORT_TIME,
	timeWithSeconds: TIME_WITH_SECONDS,
	dayMonth: DAY_MONTH,
	shortDayMonth: SHORT_DAY_MONTH,
	shortDay: SHORT_DAY,
};
