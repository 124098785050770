import { Injectable, EventEmitter } from '@angular/core';
import { UserPrincipal } from 'shared/app/core/auth/models/user-info.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'shared/app/core/config/config.service';
import { HttpService } from 'framework/app/core/http/http.service';

export interface AccountInfo extends UserPrincipal {
	userMail: string;
	firstName: string;
	lastName: string;
	username: string;
	attributes: AccountInfoAttributes;
}

export interface AccountInfoAttributes {
	country: string[];
	phone: string[];
	company_name: string[];
	gln: string[];
	vat_id: string[];
	locale: string[];
	company_address: string[];
	postal_code: string[];
	login_first_time?: string[];
	[attribute: string]: string[];
}
@Injectable({
	providedIn: 'root',
})
export class AccountService {
	static _data: AccountInfo;
	static _dataChange = new EventEmitter<void>();

	public get data() {
		return AccountService._data;
	}
	public get dataChange() {
		return AccountService._dataChange;
	}
	static init(principal: UserPrincipal) {
		AccountService._data = principal as AccountInfo;
	}

	constructor(
		private configService: ConfigService,
		private httpService: HttpService,
	) {}

	public updateUserProfile(profile: Partial<UserPrincipal>): Observable<any> {
		const url = `${this.configService.urls.AUTH_SERVICE_URL}/realms/${this.configService.config.AUTH_REALM}/account`;

		if (profile.attributes) {
			profile.attributes = { ...this.data.attributes, ...profile.attributes };
		}

		profile = {firstName: this.data.firstName, lastName: this.data.lastName, ...profile};

		return this.httpService.post(url, {...profile}, { responseType: 'json', throwSystemErrors: [] }).pipe(map(resp => resp.json()));
	}

	public changePassword(password: { current: string; new: string; confirm: string; }): Observable<any> {
		const url = `${this.configService.urls.AUTH_SERVICE_URL}/realms/${this.configService.config.AUTH_REALM}/account/credentials/password`;
		return this.httpService.post(url, {
			currentPassword: password.current,
			newPassword: password.new,
			confirmation: password.confirm,
		}, { responseType: 'json', throwSystemErrors: [] }).pipe(map(resp => resp.json()));
	}
}
