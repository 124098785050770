import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, RouterStateSnapshot } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MessageRouteType } from '../layout/models/message-route-type.model';
import { GlobalRoutesService } from '../navigation/services/global-routes.service';
import { RouteDataModel } from '../routing/route-data.model';
import { AuthService } from './auth.service';

export const PERMISSION_ROUTE_KEY = 'permissions';
export const PERMISSION_CHECK_TYPE_ROUTE_KEY = 'permissionsCheckType';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(protected authService: AuthService,
		protected permissionsService: NgxPermissionsService,
		protected globalRoutesService: GlobalRoutesService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.authService.isLoggedIn()
			.pipe(
				flatMap(x => {
					if (!x) {
						this.authService.performLogIn();
					}

					return of(x);
				}),
				flatMap(x => {
					if (!x) {
						return of(x);
					}

					return this.checkPermissions(route)
						.pipe(
							map(hasPermission => {
								if (!hasPermission) {
									this.globalRoutesService.goToMessage(MessageRouteType.Error_403);
								}
								return hasPermission;
							}),
						);
				})
			);
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.authService.isLoggedIn();
	}

	canLoad(route: Route) {
		return this.authService.isLoggedIn();
	}

	protected checkPermissions(route: ActivatedRouteSnapshot): Observable<boolean> {
		const permissions = (<RouteDataModel> route.data).permissions;

		if (!permissions) {
			return of(true);
		} else {
			return from(this.permissionsService.hasPermission(permissions));
		}
	}
}
