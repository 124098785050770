import { HttpHandler, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { config } from 'config';
import { Observable, Observer, of } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';
import { ErrorHttpInterceptorService } from './error-http-interceptor.service';
import { HTTP_SERVICE_INTERCEPTORS, HttpRequestHandler, HttpServiceInterceptor } from './http-request-handler';
import { HttpProgressState, ProgressHttpInterceptor } from './progress-http-interceptor';

@Injectable()
export class HttpService {

	private requestHandler: HttpRequestHandler;

	constructor(
			@Inject(HttpHandler) handler: HttpHandler|HttpRequestHandler,
			private errorInterceptor: ErrorHttpInterceptorService,
			@Optional() @Inject(HTTP_SERVICE_INTERCEPTORS) private interceptors: HttpServiceInterceptor[]|null) {

		if (handler instanceof HttpRequestHandler) {
			this.requestHandler = handler;
		} else {
			this.requestHandler = new HttpRequestHandler(handler);
			this.requestHandler.addInterceptor(errorInterceptor);
			if (interceptors) {
				for (const interceptor of interceptors) {
					this.requestHandler.addInterceptor(interceptor);
				}
			}
		}
	}

	request<ResponseType>(req: HttpRequest<ResponseType>, options: HttpRequestOptions): Observable<Response<ResponseType>> {
		if (!NgZone.isInAngularZone()) {
			console.error('HTTP request is not allowed outside angular zone');
		}
		if (options.headers) {
			req = req.clone({
				headers: options.headers,
			});
		}
		return of(req).pipe(
			concatMap(req => this.requestHandler.handle(req, options)),
			filter(res => res instanceof HttpResponse),
			map(res => new Response<ResponseType>(res as HttpResponse<ResponseType>)),
		);
	}

	get<ResponseType = any>(url: string, options: HttpRequestOptions = {}): Observable<Response<ResponseType>> {
		const req: HttpRequest<ResponseType> = new HttpRequest<any>('GET', this.createUrl(url), options);
		this.appendHeaderContentType(options);
		this.appendHeaderNoCache(options);
		return this.request(req, options);
	}

	delete<ResponseType = any>(url: string, options: HttpRequestOptions = {}): Observable<Response<ResponseType>> {
		const req: HttpRequest<ResponseType> = new HttpRequest<any>('DELETE', this.createUrl(url), options);
		return this.request(req, options);
	}

	post<ResponseType = any>(url: string, body: any, options: HttpRequestOptions = {}): Observable<Response<ResponseType>> {
		const req: HttpRequest<ResponseType> = new HttpRequest<any>('POST', this.createUrl(url), body, options);
		this.appendHeaderContentType(options);
		return this.request(req, options);
	}

	upload<ResponseType = any>(url: string, body: any, options: HttpRequestOptions = {}, reporter?: Observer<HttpProgressState>): Observable<Response<ResponseType>> {
		const req: HttpRequest<ResponseType> = new HttpRequest<any>('POST', this.createUrl(url), body, options);
		let http: HttpService;
		if (reporter) {
			http = this.clone().addInterceptor(new ProgressHttpInterceptor(reporter));
		} else {
			http = this;
		}
		return http.request(req, options);
	}

	put<ResponseType = any>(url: string, body: any, options: HttpRequestOptions = {}): Observable<Response<ResponseType>> {
		const req: HttpRequest<ResponseType> = new HttpRequest<any>('PUT', this.createUrl(url), body, options);
		this.appendHeaderContentType(options);
		return this.request(req, options);
	}

	patch<ResponseType = any>(url: string, body: any, options: HttpRequestOptions = {}): Observable<Response<ResponseType>> {
		const req: HttpRequest<ResponseType> = new HttpRequest<any>('PATCH', this.createUrl(url), body, options);
		this.appendHeaderContentType(options);
		return this.request(req, options);
	}

	addInterceptor(interceptor: HttpServiceInterceptor): HttpService {
		this.requestHandler.addInterceptor(interceptor);
		return this;
	}

	clone(): HttpService {
		return new HttpService(this.requestHandler.clone(), this.errorInterceptor, this.interceptors);
	}

	createHttpParams(params: { [param: string]: any }): HttpParams {
		return Object.keys(params)
			.filter(key => params[key] != null && params[key] !== '')
			.reduce((httpParams, key) => httpParams.append(key, Array.isArray(params[key]) ? params[key].join(',') : `${params[key]}`), new HttpParams());
	}

	private createUrl(url: string): string {
		if (url.match(/^[a-zA-Z]+\:\/\//)) {
			return url;
		} else {
			return config.apiContext + url;
		}
	}

	private appendHeaderContentType(options: HttpRequestOptions): void {
		this.appendHeaders(options, 'Content-type', 'application/json; charset=utf-8');
	}

	private appendHeaderNoCache(options: HttpRequestOptions): void {
		this.appendHeaders(options, 'Pragma', 'no-cache');
	}

	private appendHeaders(options: HttpRequestOptions = {}, name: string, value: string | string[]): void {
		if (!options.headers) {
			options.headers = new HttpHeaders();
		}
		options.headers = options.headers.append(name, value);
	}
}

export class Response<ResponseType> {
	readonly status: number;

	constructor(private httpResponse: HttpResponse<ResponseType>) {
		this.status = this.httpResponse.status;
	}

	json(): ResponseType {
		return this.httpResponse.body;
	}

	text(): ResponseType {
		return this.httpResponse.body;
	}
}

export interface HttpServiceOptions {
	readonly throwSystemErrors?: number[];
	readonly spinner?: string;
}

export interface HttpRequestOptions extends HttpServiceOptions {
	readonly params?: HttpParams;
	headers?: HttpHeaders;
	readonly responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
}

export function compositeResponse<CompositeType>(mapping: { [P in keyof CompositeType]: number })
		: (reponse: Response<any>) => Partial<CompositeType> {

	const mappingKeys: (keyof CompositeType)[] = Object.keys(mapping) as any;
	return response => {
		const data: Partial<CompositeType> = {};
		for (const key of mappingKeys) {
			if (mapping[key] === response.status) {
				data[key] = response.json();
				return data;
			}
		}
		return data;
	};
}
