import { __ } from 'seed/lib/i18n';

export const currency = {
	'currency.AED': __('currency.AED'),
	'currency.AFN': __('currency.AFN'),
	'currency.ALL': __('currency.ALL'),
	'currency.AMD': __('currency.AMD'),
	'currency.ANG': __('currency.ANG'),
	'currency.AOA': __('currency.AOA'),
	'currency.ARS': __('currency.ARS'),
	'currency.AUD': __('currency.AUD'),
	'currency.AWG': __('currency.AWG'),
	'currency.AZN': __('currency.AZN'),
	'currency.BAM': __('currency.BAM'),
	'currency.BBD': __('currency.BBD'),
	'currency.BDT': __('currency.BDT'),
	'currency.BGN': __('currency.BGN'),
	'currency.BHD': __('currency.BHD'),
	'currency.BIF': __('currency.BIF'),
	'currency.BMD': __('currency.BMD'),
	'currency.BND': __('currency.BND'),
	'currency.BOB': __('currency.BOB'),
	'currency.BOV': __('currency.BOV'),
	'currency.BRL': __('currency.BRL'),
	'currency.BSD': __('currency.BSD'),
	'currency.BTN': __('currency.BTN'),
	'currency.BWP': __('currency.BWP'),
	'currency.BYN': __('currency.BYN'),
	'currency.BZD': __('currency.BZD'),
	'currency.CAD': __('currency.CAD'),
	'currency.CDF': __('currency.CDF'),
	'currency.CHE': __('currency.CHE'),
	'currency.CHF': __('currency.CHF'),
	'currency.CHW': __('currency.CHW'),
	'currency.CLF': __('currency.CLF'),
	'currency.CLP': __('currency.CLP'),
	'currency.CNY': __('currency.CNY'),
	'currency.COP': __('currency.COP'),
	'currency.COU': __('currency.COU'),
	'currency.CRC': __('currency.CRC'),
	'currency.CUC': __('currency.CUC'),
	'currency.CUP': __('currency.CUP'),
	'currency.CVE': __('currency.CVE'),
	'currency.CZK': __('currency.CZK'),
	'currency.DJF': __('currency.DJF'),
	'currency.DKK': __('currency.DKK'),
	'currency.DOP': __('currency.DOP'),
	'currency.DZD': __('currency.DZD'),
	'currency.EGP': __('currency.EGP'),
	'currency.ERN': __('currency.ERN'),
	'currency.ETB': __('currency.ETB'),
	'currency.EUR': __('currency.EUR'),
	'currency.FJD': __('currency.FJD'),
	'currency.FKP': __('currency.FKP'),
	'currency.GBP': __('currency.GBP'),
	'currency.GEL': __('currency.GEL'),
	'currency.GHS': __('currency.GHS'),
	'currency.GIP': __('currency.GIP'),
	'currency.GMD': __('currency.GMD'),
	'currency.GNF': __('currency.GNF'),
	'currency.GTQ': __('currency.GTQ'),
	'currency.GYD': __('currency.GYD'),
	'currency.HKD': __('currency.HKD'),
	'currency.HNL': __('currency.HNL'),
	'currency.HRK': __('currency.HRK'),
	'currency.HTG': __('currency.HTG'),
	'currency.HUF': __('currency.HUF'),
	'currency.IDR': __('currency.IDR'),
	'currency.ILS': __('currency.ILS'),
	'currency.INR': __('currency.INR'),
	'currency.IQD': __('currency.IQD'),
	'currency.IRR': __('currency.IRR'),
	'currency.ISK': __('currency.ISK'),
	'currency.JMD': __('currency.JMD'),
	'currency.JOD': __('currency.JOD'),
	'currency.JPY': __('currency.JPY'),
	'currency.KES': __('currency.KES'),
	'currency.KGS': __('currency.KGS'),
	'currency.KHR': __('currency.KHR'),
	'currency.KMF': __('currency.KMF'),
	'currency.KPW': __('currency.KPW'),
	'currency.KRW': __('currency.KRW'),
	'currency.KWD': __('currency.KWD'),
	'currency.KYD': __('currency.KYD'),
	'currency.KZT': __('currency.KZT'),
	'currency.LAK': __('currency.LAK'),
	'currency.LBP': __('currency.LBP'),
	'currency.LKR': __('currency.LKR'),
	'currency.LRD': __('currency.LRD'),
	'currency.LSL': __('currency.LSL'),
	'currency.LYD': __('currency.LYD'),
	'currency.MAD': __('currency.MAD'),
	'currency.MDL': __('currency.MDL'),
	'currency.MGA': __('currency.MGA'),
	'currency.MKD': __('currency.MKD'),
	'currency.MMK': __('currency.MMK'),
	'currency.MNT': __('currency.MNT'),
	'currency.MOP': __('currency.MOP'),
	'currency.MRU': __('currency.MRU'),
	'currency.MUR': __('currency.MUR'),
	'currency.MVR': __('currency.MVR'),
	'currency.MWK': __('currency.MWK'),
	'currency.MXN': __('currency.MXN'),
	'currency.MXV': __('currency.MXV'),
	'currency.MYR': __('currency.MYR'),
	'currency.MZN': __('currency.MZN'),
	'currency.NAD': __('currency.NAD'),
	'currency.NGN': __('currency.NGN'),
	'currency.NIO': __('currency.NIO'),
	'currency.NOK': __('currency.NOK'),
	'currency.NPR': __('currency.NPR'),
	'currency.NZD': __('currency.NZD'),
	'currency.OMR': __('currency.OMR'),
	'currency.PAB': __('currency.PAB'),
	'currency.PEN': __('currency.PEN'),
	'currency.PGK': __('currency.PGK'),
	'currency.PHP': __('currency.PHP'),
	'currency.PKR': __('currency.PKR'),
	'currency.PLN': __('currency.PLN'),
	'currency.PYG': __('currency.PYG'),
	'currency.QAR': __('currency.QAR'),
	'currency.RON': __('currency.RON'),
	'currency.RSD': __('currency.RSD'),
	'currency.RUB': __('currency.RUB'),
	'currency.RWF': __('currency.RWF'),
	'currency.SAR': __('currency.SAR'),
	'currency.SBD': __('currency.SBD'),
	'currency.SCR': __('currency.SCR'),
	'currency.SDG': __('currency.SDG'),
	'currency.SEK': __('currency.SEK'),
	'currency.SGD': __('currency.SGD'),
	'currency.SHP': __('currency.SHP'),
	'currency.SLL': __('currency.SLL'),
	'currency.SOS': __('currency.SOS'),
	'currency.SRD': __('currency.SRD'),
	'currency.SSP': __('currency.SSP'),
	'currency.STN': __('currency.STN'),
	'currency.SVC': __('currency.SVC'),
	'currency.SYP': __('currency.SYP'),
	'currency.SZL': __('currency.SZL'),
	'currency.THB': __('currency.THB'),
	'currency.TJS': __('currency.TJS'),
	'currency.TMT': __('currency.TMT'),
	'currency.TND': __('currency.TND'),
	'currency.TOP': __('currency.TOP'),
	'currency.TRY': __('currency.TRY'),
	'currency.TTD': __('currency.TTD'),
	'currency.TWD': __('currency.TWD'),
	'currency.TZS': __('currency.TZS'),
	'currency.UAH': __('currency.UAH'),
	'currency.UGX': __('currency.UGX'),
	'currency.USD': __('currency.USD'),
	'currency.USN': __('currency.USN'),
	'currency.UYI': __('currency.UYI'),
	'currency.UYU': __('currency.UYU'),
	'currency.UYW': __('currency.UYW'),
	'currency.UZS': __('currency.UZS'),
	'currency.VES': __('currency.VES'),
	'currency.VND': __('currency.VND'),
	'currency.VUV': __('currency.VUV'),
	'currency.WST': __('currency.WST'),
	'currency.XAF': __('currency.XAF'),
	'currency.XAG': __('currency.XAG'),
	'currency.XAU': __('currency.XAU'),
	'currency.XBA': __('currency.XBA'),
	'currency.XBB': __('currency.XBB'),
	'currency.XBC': __('currency.XBC'),
	'currency.XBD': __('currency.XBD'),
	'currency.XCD': __('currency.XCD'),
	'currency.XDR': __('currency.XDR'),
	'currency.XOF': __('currency.XOF'),
	'currency.XPD': __('currency.XPD'),
	'currency.XPF': __('currency.XPF'),
	'currency.XPT': __('currency.XPT'),
	'currency.XSU': __('currency.XSU'),
	'currency.XTS': __('currency.XTS'),
	'currency.XUA': __('currency.XUA'),
	'currency.XXX': __('currency.XXX'),
	'currency.YER': __('currency.YER'),
	'currency.ZAR': __('currency.ZAR'),
	'currency.ZMW': __('currency.ZMW'),
	'currency.ZWL': __('currency.ZWL'),
};
