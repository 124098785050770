import { Injectable } from '@angular/core';

type Features = { [feature: string]: boolean };

@Injectable({
	providedIn: 'root',
})
export class FeatureTogglesService {
	private static features: Features = {};

	public init(features: Features) {
		FeatureTogglesService.features = {... features};

	}

	public isEnabled(feature: string): boolean {
		return !!FeatureTogglesService.features[feature];
	}
}
