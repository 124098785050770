import { InjectionToken } from '@angular/core';
import { AuthServicePermissionCheckType } from '../auth/auth.service';

export const NAVIGATION = new InjectionToken<NavigationModel>('NAVIGATION');
export const MAIN_NAVIGATION_KEY: string = 'main';
export const MODAL_ROUTE_START_PATH: string = 'modal';
export const MODAL_ROUTE_OUTLET: string = 'modal';

export interface NavigationModel {
	[name: string]: NavigationItem[];
}

export interface NavigationItem {
	name: string;
	label: string;
	url: string;
	permissions?: string[];
	permissionCheckType?: AuthServicePermissionCheckType;
	icon?: string;
	featureName?: string;

	children?: NavigationItem[];
}
