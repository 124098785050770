import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export abstract class StorageService {
	abstract clear(prefix: string, usePersistenceStorage: boolean): void;
	abstract clearAll(): void;
	abstract add(key: string, data: any, usePersistentStorage: boolean);
	abstract get(key: string, usePersistenceStorage: boolean);
	abstract getAvailable(key: string);
	abstract set suffix(value: string);
}
