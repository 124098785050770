import { NavigationModel } from 'shared/app/core/navigation/navigation.model';
import { __ } from 'seed/lib/i18n';

export const DOCUMENT_NAVIGATION_KEY = 'DOCUMENT';
export const PARTNER_NAVIGATION_KEY = 'PARTNER';

export const EWC_NAVIGATION: NavigationModel = {
	main: [
		{
			name: 'DOCUMENT',
			label: __('ewc.invoices.invoices'),
			permissions: [],
			url: '/document',
			icon: 'invoices',
		},
		{
			name: 'PARTNER',
			label: __('ewc.partners.partners'),
			permissions: [],
			url: '/partner',
			icon: 'partners',
			featureName: 'page.partners',
		},
	],
};
