import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ToastModule } from '@cds/components-angular';
import { NotificationTemplateListComponent } from './components/notification-template-list/notification-template-list.component';
import { NotificationTemplateItemComponent } from './components/notification-template/notification-template-item.component';
import { NotificationTemplatesService } from './services/notification-templates.service';
import { NotificationService } from './services/notification.service';

const NG_MODULES = [
	CommonModule,
];

const SEED_MODULES = [

];

const SHARED_MODULES = [

];

const CDS_MODULES = [
	ToastModule,
];

@NgModule({
	imports: [
		NG_MODULES,
		SEED_MODULES,
		SHARED_MODULES,
		CDS_MODULES,
	],
	exports: [
		NotificationTemplateListComponent,
	],
	declarations: [
		NotificationTemplateListComponent,
		NotificationTemplateItemComponent,
	],
	providers: [
		NotificationService,
	],
})
export class NotificationModule {
	public static forRoot(): ModuleWithProviders<NotificationModule> {
		return {
			ngModule: NotificationModule,
			providers: [
				{
					provide: NotificationTemplatesService,
					useClass: NotificationTemplatesService,
				},
			],
		};
	}
}
