import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OpenSidenavEvent } from '@cds/components-angular';
import { SpinnerService } from 'framework/app/ui/spinner/spinner.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from 'shared/app/core/auth/auth.service';
import { BaseAppComponent } from 'shared/app/core/base-app/base-app.component';
import { Store } from './core/store/store';
import { AppBarService } from 'shared/app/core/app-bar/app-bar.service';
import { TranslateService } from '../../shared/app/core/translate/translate.service';

@Component({
	selector: 'ca-app',
	templateUrl: 'app.component.html',
})
export class AppComponent extends BaseAppComponent implements OnInit {
	store: Store;
	toggle: boolean = false;

	constructor(
		store: Store,
		public cdRef: ChangeDetectorRef,
		protected authService: AuthService,
		protected spinnerService: SpinnerService,
		protected permissionsService: NgxPermissionsService,
		private appBarService: AppBarService,
		private translateService: TranslateService
	) {
		super(authService, spinnerService, permissionsService);
		store.commit();
	}

	ngOnInit(): void {
	}

	onOpenSidenav(event: OpenSidenavEvent): void {
		event.preventDefault();
		this.toggle = true;
		this.cdRef.detectChanges();
	}
}
