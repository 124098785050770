import { __ } from 'seed/lib/i18n';

export const country = {
		'country.AF': __('country.AF'),
		'country.AL': __('country.AL'),
		'country.DZ': __('country.DZ'),
		'country.AS': __('country.AS'),
		'country.AD': __('country.AD'),
		'country.AO': __('country.AO'),
		'country.AI': __('country.AI'),
		'country.AQ': __('country.AQ'),
		'country.AG': __('country.AG'),
		'country.AR': __('country.AR'),
		'country.AM': __('country.AM'),
		'country.AW': __('country.AW'),
		'country.AU': __('country.AU'),
		'country.AT': __('country.AT'),
		'country.AZ': __('country.AZ'),
		'country.BS': __('country.BS'),
		'country.BH': __('country.BH'),
		'country.BD': __('country.BD'),
		'country.BB': __('country.BB'),
		'country.BY': __('country.BY'),
		'country.BE': __('country.BE'),
		'country.BZ': __('country.BZ'),
		'country.BJ': __('country.BJ'),
		'country.BM': __('country.BM'),
		'country.BT': __('country.BT'),
		'country.BO': __('country.BO'),
		'country.BQ': __('country.BQ'),
		'country.BA': __('country.BA'),
		'country.BW': __('country.BW'),
		'country.BV': __('country.BV'),
		'country.BR': __('country.BR'),
		'country.IO': __('country.IO'),
		'country.BN': __('country.BN'),
		'country.BG': __('country.BG'),
		'country.BF': __('country.BF'),
		'country.BI': __('country.BI'),
		'country.CV': __('country.CV'),
		'country.KH': __('country.KH'),
		'country.CM': __('country.CM'),
		'country.CA': __('country.CA'),
		'country.KY': __('country.KY'),
		'country.CF': __('country.CF'),
		'country.TD': __('country.TD'),
		'country.CL': __('country.CL'),
		'country.CN': __('country.CN'),
		'country.CX': __('country.CX'),
		'country.CC': __('country.CC'),
		'country.CO': __('country.CO'),
		'country.KM': __('country.KM'),
		'country.CD': __('country.CD'),
		'country.CG': __('country.CG'),
		'country.CK': __('country.CK'),
		'country.CR': __('country.CR'),
		'country.HR': __('country.HR'),
		'country.CU': __('country.CU'),
		'country.CW': __('country.CW'),
		'country.CY': __('country.CY'),
		'country.CZ': __('country.CZ'),
		'country.CI': __('country.CI'),
		'country.DK': __('country.DK'),
		'country.DJ': __('country.DJ'),
		'country.DM': __('country.DM'),
		'country.DO': __('country.DO'),
		'country.EC': __('country.EC'),
		'country.EG': __('country.EG'),
		'country.SV': __('country.SV'),
		'country.GQ': __('country.GQ'),
		'country.ER': __('country.ER'),
		'country.EE': __('country.EE'),
		'country.SZ': __('country.SZ'),
		'country.ET': __('country.ET'),
		'country.FK': __('country.FK'),
		'country.FO': __('country.FO'),
		'country.FJ': __('country.FJ'),
		'country.FI': __('country.FI'),
		'country.FR': __('country.FR'),
		'country.GF': __('country.GF'),
		'country.PF': __('country.PF'),
		'country.TF': __('country.TF'),
		'country.GA': __('country.GA'),
		'country.GM': __('country.GM'),
		'country.GE': __('country.GE'),
		'country.DE': __('country.DE'),
		'country.GH': __('country.GH'),
		'country.GI': __('country.GI'),
		'country.GR': __('country.GR'),
		'country.GL': __('country.GL'),
		'country.GD': __('country.GD'),
		'country.GP': __('country.GP'),
		'country.GU': __('country.GU'),
		'country.GT': __('country.GT'),
		'country.GG': __('country.GG'),
		'country.GN': __('country.GN'),
		'country.GW': __('country.GW'),
		'country.GY': __('country.GY'),
		'country.HT': __('country.HT'),
		'country.HM': __('country.HM'),
		'country.VA': __('country.VA'),
		'country.HN': __('country.HN'),
		'country.HK': __('country.HK'),
		'country.HU': __('country.HU'),
		'country.IS': __('country.IS'),
		'country.IN': __('country.IN'),
		'country.ID': __('country.ID'),
		'country.IR': __('country.IR'),
		'country.IQ': __('country.IQ'),
		'country.IE': __('country.IE'),
		'country.IM': __('country.IM'),
		'country.IL': __('country.IL'),
		'country.IT': __('country.IT'),
		'country.JM': __('country.JM'),
		'country.JP': __('country.JP'),
		'country.JE': __('country.JE'),
		'country.JO': __('country.JO'),
		'country.KZ': __('country.KZ'),
		'country.KE': __('country.KE'),
		'country.KI': __('country.KI'),
		'country.KP': __('country.KP'),
		'country.KR': __('country.KR'),
		'country.KW': __('country.KW'),
		'country.KG': __('country.KG'),
		'country.LA': __('country.LA'),
		'country.LV': __('country.LV'),
		'country.LB': __('country.LB'),
		'country.LS': __('country.LS'),
		'country.LR': __('country.LR'),
		'country.LY': __('country.LY'),
		'country.LI': __('country.LI'),
		'country.LT': __('country.LT'),
		'country.LU': __('country.LU'),
		'country.MO': __('country.MO'),
		'country.MG': __('country.MG'),
		'country.MW': __('country.MW'),
		'country.MY': __('country.MY'),
		'country.MV': __('country.MV'),
		'country.ML': __('country.ML'),
		'country.MT': __('country.MT'),
		'country.MH': __('country.MH'),
		'country.MQ': __('country.MQ'),
		'country.MR': __('country.MR'),
		'country.MU': __('country.MU'),
		'country.YT': __('country.YT'),
		'country.MX': __('country.MX'),
		'country.FM': __('country.FM'),
		'country.MD': __('country.MD'),
		'country.MC': __('country.MC'),
		'country.MN': __('country.MN'),
		'country.ME': __('country.ME'),
		'country.MS': __('country.MS'),
		'country.MA': __('country.MA'),
		'country.MZ': __('country.MZ'),
		'country.MM': __('country.MM'),
		'country.NA': __('country.NA'),
		'country.NR': __('country.NR'),
		'country.NP': __('country.NP'),
		'country.NL': __('country.NL'),
		'country.NC': __('country.NC'),
		'country.NZ': __('country.NZ'),
		'country.NI': __('country.NI'),
		'country.NE': __('country.NE'),
		'country.NG': __('country.NG'),
		'country.NU': __('country.NU'),
		'country.NF': __('country.NF'),
		'country.MP': __('country.MP'),
		'country.NO': __('country.NO'),
		'country.OM': __('country.OM'),
		'country.PK': __('country.PK'),
		'country.PW': __('country.PW'),
		'country.PS': __('country.PS'),
		'country.PA': __('country.PA'),
		'country.PG': __('country.PG'),
		'country.PY': __('country.PY'),
		'country.PE': __('country.PE'),
		'country.PH': __('country.PH'),
		'country.PN': __('country.PN'),
		'country.PL': __('country.PL'),
		'country.PT': __('country.PT'),
		'country.PR': __('country.PR'),
		'country.QA': __('country.QA'),
		'country.MK': __('country.MK'),
		'country.RO': __('country.RO'),
		'country.RU': __('country.RU'),
		'country.RW': __('country.RW'),
		'country.RE': __('country.RE'),
		'country.BL': __('country.BL'),
		'country.SH': __('country.SH'),
		'country.KN': __('country.KN'),
		'country.LC': __('country.LC'),
		'country.MF': __('country.MF'),
		'country.PM': __('country.PM'),
		'country.VC': __('country.VC'),
		'country.WS': __('country.WS'),
		'country.SM': __('country.SM'),
		'country.ST': __('country.ST'),
		'country.SA': __('country.SA'),
		'country.SN': __('country.SN'),
		'country.RS': __('country.RS'),
		'country.SC': __('country.SC'),
		'country.SL': __('country.SL'),
		'country.SG': __('country.SG'),
		'country.SX': __('country.SX'),
		'country.SK': __('country.SK'),
		'country.SI': __('country.SI'),
		'country.SB': __('country.SB'),
		'country.SO': __('country.SO'),
		'country.ZA': __('country.ZA'),
		'country.GS': __('country.GS'),
		'country.SS': __('country.SS'),
		'country.ES': __('country.ES'),
		'country.LK': __('country.LK'),
		'country.SD': __('country.SD'),
		'country.SR': __('country.SR'),
		'country.SJ': __('country.SJ'),
		'country.SE': __('country.SE'),
		'country.CH': __('country.CH'),
		'country.SY': __('country.SY'),
		'country.TW': __('country.TW'),
		'country.TJ': __('country.TJ'),
		'country.TZ': __('country.TZ'),
		'country.TH': __('country.TH'),
		'country.TL': __('country.TL'),
		'country.TG': __('country.TG'),
		'country.TK': __('country.TK'),
		'country.TO': __('country.TO'),
		'country.TT': __('country.TT'),
		'country.TN': __('country.TN'),
		'country.TR': __('country.TR'),
		'country.TM': __('country.TM'),
		'country.TC': __('country.TC'),
		'country.TV': __('country.TV'),
		'country.UG': __('country.UG'),
		'country.UA': __('country.UA'),
		'country.AE': __('country.AE'),
		'country.GB': __('country.GB'),
		'country.UM': __('country.UM'),
		'country.US': __('country.US'),
		'country.UY': __('country.UY'),
		'country.UZ': __('country.UZ'),
		'country.VU': __('country.VU'),
		'country.VE': __('country.VE'),
		'country.VN': __('country.VN'),
		'country.VG': __('country.VG'),
		'country.VI': __('country.VI'),
		'country.WF': __('country.WF'),
		'country.EH': __('country.EH'),
		'country.YE': __('country.YE'),
		'country.ZM': __('country.ZM'),
		'country.ZW': __('country.ZW'),
		'country.AX': __('country.AX'),
};
