import { PlatformLocation } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakFlow, KeycloakInitOptions, KeycloakTokenParsed } from 'keycloak-js';
import { BehaviorSubject, from } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth/auth.service';
import { UserPrincipal } from '../auth/models/user-info.model';
import { UserPermissions } from '../auth/models/user-permission.model';
import { ConfigService } from '../config/config.service';
import { UserRoles } from '../auth/models/user-roles.model';

@Injectable({
	providedIn: 'root',
})
export class KeycloakAuthService extends AuthService {
	protected userPermissions$ = new BehaviorSubject<UserPermissions>([]);
	protected userPrincipal$ = new BehaviorSubject<UserPrincipal>(null);
	protected userRoles$ = new BehaviorSubject<UserRoles>([]);
	protected userProfileLoaded = false;

	readonly LANG_COOKIE_NAME = 'lang';
	readonly COOKIE_LIFE_DAYS = 60;

	private appName: string;

	constructor(
		protected configService: ConfigService,
		protected keycloakService: KeycloakService,
		protected injector: Injector,
		protected platformLocation: PlatformLocation,
		private cookieService: CookieService,
	) {
		super(configService, injector);
	}

	public initialize() {
		this.appName = this.configService.config.appName;
		this.keycloakService.keycloakEvents$
			.pipe(filter((e: KeycloakEvent) => {
				return e.type === KeycloakEventType.OnAuthSuccess || e.type === KeycloakEventType.OnAuthRefreshSuccess;
			}))
			.subscribe((e: KeycloakEvent) => this.handleNewToken());

		this.keycloakService.keycloakEvents$
			.pipe(filter((e: KeycloakEvent) => {
				return e.type === KeycloakEventType.OnAuthRefreshError;
			}))
			.subscribe((e: KeycloakEvent) => console.warn('Refresh failed', e));

		return this.initKeycloak();
	}

	protected initKeycloak(): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				const subscription = this.keycloakService.keycloakEvents$.subscribe(
					(event: KeycloakEvent) => {
						if (
							event.type === KeycloakEventType.OnReady &&
							!event.args.authenticated
						) {
							this.keycloakService
								.getKeycloakInstance()
								.login({
									locale: getCookie('lang') || 'en',
								})
								.success(() => {
									resolve();
									subscription.unsubscribe();
								});
						} else {
							resolve();
							subscription.unsubscribe();
						}
					}
				);
				await this.keycloakService.init({
					config: {
						url: this.configService.urls.AUTH_SERVICE_URL,
						realm: this.configService.config.AUTH_REALM,
						clientId: this.configService.config.AUTH_CLIENT,
					},
					initOptions: {
						flow: this.configService.config.AUTH_FLOW as KeycloakFlow,
						onLoad: 'check-sso',
						responseType: this.configService.config.AUTH_FLOW === 'standard' ? 'code' : null,
						pkceMethod: this.configService.config.AUTH_FLOW === 'standard' ? 'S256' : null,
						promiseType: 'native',
						checkLoginIframe: false,
					} as KeycloakInitOptions,
					enableBearerInterceptor: true,
					bearerExcludedUrls: ['/assets', '/clients/public'],
				});

				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	public getToken() {
		return this.keycloakService.getToken();
	}

	public isLoggedIn() {
		return from(this.keycloakService.isLoggedIn());
	}

	public performLogIn(): void {
		this.initKeycloak();
	}

	protected performLogout(redirect?: string) {
		return this.keycloakService.logout(redirect);
	}

	public silentRefresh() {
		return this.keycloakService.updateToken(60);
	}

	public getPermissions() {
		return this.userPermissions$.asObservable();
	}

	public getUserPrincipal() {
		return this.userPrincipal$.asObservable();
	}

	public getUserRoles() {
		return this.userRoles$.asObservable();
	}

	protected handleNewToken() {
		if (!this.userProfileLoaded) {
			this.keycloakService.loadUserProfile().then((profile) => {
				this.userPermissions$.next([]);
				this.userPrincipal$.next(profile as UserPrincipal);
				this.userRoles$.next(this.keycloakService.getUserRoles());
				this.userProfileLoaded = true;

				try {
					const princ = profile as UserPrincipal;
					let currentLang;
					if (this.cookieService.check(this.LANG_COOKIE_NAME)) {
						currentLang = this.cookieService.get(this.LANG_COOKIE_NAME);
					}
					const newLang = princ.attributes?.locale?.[0].toLowerCase();
					if (newLang) {
						this.cookieService.set(this.LANG_COOKIE_NAME, newLang, this.COOKIE_LIFE_DAYS, `/${this.appName}`);

						if (!currentLang || newLang !== currentLang) {
							window.location.pathname = `/${this.appName}`;
						}
					}
				} catch (e) {
					console.warn('Auth lang setup error:', e);
				}
			});
		}
	}

	public clearTokens() {
		this.keycloakService.clearToken();
	}
}

function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

interface AccessToken extends KeycloakTokenParsed {
	sub: string;

	email: string;
	email_verified: boolean;

	name: string;
	family_name: string;

	company_name: string;
	gln: string;
	tax_id: string;

	locale: string;

	company_address: string;
	postal_code: string;
	country: string;

	phone: string;

}
