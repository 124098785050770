import {
	Component,
	OnInit
} from '@angular/core';
import { Store } from '../core/store/store';

@Component({
	selector: 'ca-home',
	templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit {
	name: string = 'world';
	store: Store;

	mode: string = 'desktop';
	fixed: boolean = false;
	fixed2: boolean = false;

	plain: boolean = false;
	expand: boolean = false;

	currentPage: number = 1;
	pageSize: number = 5;

	list = [];

	totalElements = this.list.length;
	page: any[];

	ngOnInit(): void {
		this.onPageChange();
	}

	constructor(
		store: Store
	) {
		this.list = [{
			id: '1',
			body: 'Body',
		}];
	}

	onPageChange(): void {
		this.page = this.list.slice(
			(this.currentPage - 1) * this.pageSize,
			this.currentPage * this.pageSize
		);
	}
}
