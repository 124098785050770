<div #accountMenuAnchor class="footer-item clickable flex f-align-items-center gray-2" (click)="open=!open">
	<div style="display: contents;">
		<i class="icon icon-perm-identity-small cds-icon--size-24"></i>
		<div class="cds-text-2 ml-3" style="flex: auto 1 0; font-weight: inherit;">
			{{user}}
		</div>
		<i class="cds-icon-caret-down cds-icon--size-24"></i>
	</div>
</div>
<cds-dropdown *ngIf="open" [anchor]="accountMenuAnchor" (dismiss)="open=false" class="side-bottom-menu side-bottom-menu--1">
	<ul role="menu" style="font-weight: normal;">
		<li role="menuitem" (click)="goToProfile(); open=!open" tabindex="-1" *caFeatureToggle="'page.profile'">
			Profile</li>
		<li role="menuitem" (click)="logout()" tabindex="-1">Logout</li>
	</ul>
</cds-dropdown>