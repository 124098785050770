import { NgModule } from '@angular/core';
import { CDS_DATE_TIME_TRANSLATIONS, CDS_PAGER_TRANSLATIONS, CDS_UPLOAD_TRANSLATIONS } from '@cds/components-angular';
import { cdsUploadTranslations } from './upload';
import { cdsDateTimeTranslations } from './date-time';
import { cdsPagerTranslations } from './pager';

@NgModule({
	imports: [],
	declarations: [],
	exports: [],
	providers: [
		{ provide: CDS_UPLOAD_TRANSLATIONS, useValue: cdsUploadTranslations },
		{ provide: CDS_DATE_TIME_TRANSLATIONS, useValue: cdsDateTimeTranslations },
		{ provide: CDS_PAGER_TRANSLATIONS, useValue: cdsPagerTranslations },
	],
})
export class CdsTranslationsModule {}
