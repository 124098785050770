import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_SERVICE_INTERCEPTORS } from '../../core/http/http-request-handler';
import { DotsSpinnerComponent } from './dots-spinner.component';
import { SpinnerHttpInterceptorService } from './spinner-http-interceptor.service';
import { SpinnerComponent } from './spinner.component';
import { SpinnerDirective } from './spinner.directive';
import { SpinnerService } from './spinner.service';

@NgModule({
	declarations: [
		DotsSpinnerComponent,
		SpinnerComponent,
		SpinnerDirective,
	],
	exports: [
		DotsSpinnerComponent,
		SpinnerComponent,
		SpinnerDirective,
	],
})
export class SpinnerModule {

	static forRoot(): ModuleWithProviders<SpinnerModule> {
		return {
			ngModule: SpinnerModule,
			providers: [
				SpinnerHttpInterceptorService,
				SpinnerService,
				{ provide: HTTP_SERVICE_INTERCEPTORS, multi: true, useExisting: SpinnerHttpInterceptorService },
			],
		};
	}
}
