import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService extends StorageService {

	suffix: string;

	add(key: string, data: any, usePersistentStorage: boolean = true) {
		key = this.suffixKey(key);
		if (usePersistentStorage) {
			if (localStorage) {
				localStorage.setItem(key, JSON.stringify(data));
			}
		} else {
			if (sessionStorage) {
				sessionStorage.setItem(key, JSON.stringify(data));
			}
		}
	}

	get(key: string, usePersistenceStorage = false): any {
		key = this.suffixKey(key);
		return usePersistenceStorage ? JSON.parse(localStorage.getItem(key)) : JSON.parse(sessionStorage.getItem(key));
	}

	getAvailable(key: string): any {
		key = this.suffixKey(key);
		const item = sessionStorage.getItem(key) || localStorage.getItem(key) || null;
		if (item) {
			return JSON.parse(item);
		}
		return item;
	}

	clear(prefix: string, usePersistenceStorage: boolean): void {
		this.clearStorage(usePersistenceStorage ? localStorage : sessionStorage, prefix);
	}

	clearAll(): void {
		sessionStorage.clear();
		localStorage.clear();
	}

	private clearStorage(storage: Storage, prefix: string) {
		for (const k in storage) {
			if (k.startsWith(prefix) && k.endsWith(this.suffix)) {
				storage.removeItem(k);
			}
		}
	}

	private suffixKey(key: string): string {
		return `${key}-${this.suffix}`;
	}
}
