import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface HttpServiceInterceptor {
	intercept(req: HttpRequest<any>, options: any, next: HttpServiceHandler): Observable<HttpEvent<any>>;
}

export interface HttpServiceHandler {
	handle(req: HttpRequest<any>, options: any): Observable<HttpEvent<any>>;
}

export const HTTP_SERVICE_INTERCEPTORS: InjectionToken<HttpServiceInterceptor> = new InjectionToken<HttpServiceInterceptor>('HttpServiceInterceptor');

export class HttpRequestHandler {

	private handler: HttpServiceHandler;

	constructor(handler: HttpHandler|HttpRequestHandler) {
		if (handler instanceof HttpRequestHandler) {
			this.handler = handler.handler;
		} else {
			this.handler = {
				handle(req: HttpRequest<any>, options: any): Observable<HttpEvent<any>> {
					return handler.handle(req);
				},
			};
		}
	}

	clone(): HttpRequestHandler {
		return new HttpRequestHandler(this);
	}

	addInterceptor(interceptor: HttpServiceInterceptor): void {
		const handler: HttpServiceHandler = this.handler;
		this.handler = {
			handle(req: HttpRequest<any>, options: any): Observable<HttpEvent<any>> {
				return interceptor.intercept(req, options, handler);
			},
		};
	}

	handle(req: HttpRequest<any>, options: any): Observable<HttpEvent<any>> {
		return this.handler.handle(req, options);
	}
}
