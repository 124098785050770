import { __ } from 'seed/lib/i18n';

export const invoiceType = {
		'invoiceType.80': __('invoiceType.80'),
		'invoiceType.81': __('invoiceType.81'),
		'invoiceType.82': __('invoiceType.82'),
		'invoiceType.83': __('invoiceType.83'),
		'invoiceType.84': __('invoiceType.84'),
		'invoiceType.326': __('invoiceType.326'),
		'invoiceType.380': __('invoiceType.380'),
		'invoiceType.381': __('invoiceType.381'),
		'invoiceType.383': __('invoiceType.383'),
		'invoiceType.384': __('invoiceType.384'),
		'invoiceType.386': __('invoiceType.386'),
		'invoiceType.389': __('invoiceType.389'),
		'invoiceType.393': __('invoiceType.393'),
		'invoiceType.395': __('invoiceType.395'),
		'invoiceType.396': __('invoiceType.396'),
		'invoiceType.532': __('invoiceType.532'),
		'invoiceType.575': __('invoiceType.575'),
		'invoiceType.623': __('invoiceType.623'),
		'invoiceType.780': __('invoiceType.780'),
};
