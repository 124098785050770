import { OnDestroy, ViewChild, Directive } from '@angular/core';
import { SpinnerService } from 'framework/app/ui/spinner/spinner.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from '../auth/auth.service';
import { MODAL_ROUTE_OUTLET } from '../navigation/navigation.model';

@Directive()
export abstract class BaseAppComponent implements OnDestroy {
	public initialized: boolean = false;
	public spinnerName: string = 'full-screen';
	public get routeModalOutletName(): string {
		return MODAL_ROUTE_OUTLET;
	}

	@ViewChild('appSpinner', { static: true })
	public set spinner(value) {
		if (value) {
			this.onSpinnerLoaded();
		}
	}

	constructor(
		protected authService: AuthService,
		protected spinnerService: SpinnerService,
		protected permissionsService: NgxPermissionsService
	) {
		this.init().then(() => {
			this.initialized = true;
			this.spinnerService.onStop(this.spinnerName);
		});
	}

	public ngOnDestroy() {}

	public onSpinnerLoaded() {
		if (!this.initialized) {
			this.spinnerService.onStart(this.spinnerName);
		}
	}

	private async init() {}
}
