import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	ToastContext,
	ToastInstance,
	ToastService,
} from '@cds/components-angular';
import { noop } from 'framework/app/utils/facade';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from 'shared/app/core/translate/translate.service';
import { ObjectHelper } from 'shared/app/core/utils/helpers/object.helper';
import {
	BaseNotification,
	NotificationResult,
} from '../../models/notification.model';
import { NotificationTemplatesService } from '../../services/notification-templates.service';

@UntilDestroy()
@Component({
	selector: 'ca-notification-template-item',
	templateUrl: 'notification-template-item.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationTemplateItemComponent
	implements OnDestroy, AfterViewInit {
	@Input() item: BaseNotification;
	@Output() notificationResult = new EventEmitter<NotificationResult>();

	@ViewChild('template', { static: true }) template: TemplateRef<
		ToastContext<void>
	>;

	public translatedHeader: string;
	public translatedBody: string;
	public toast: ToastInstance;

	public get isFooterVisible(): boolean {
		return !ObjectHelper.isEmpty(this.item.buttons);
	}

	constructor(
		protected notificationTemplatesService: NotificationTemplatesService,
		protected translateService: TranslateService,
		protected toastService: ToastService
	) {}

	public ngAfterViewInit() {
		this.notificationTemplatesService.notificationTemplates$
			.pipe(untilDestroyed(this))
			.subscribe(
				(event) =>
					event.item === this.item &&
					event.type === 'remove' &&
					this.toast &&
					this.toast.reject()
			);

		this.initLabels();
		this.initToast();
	}

	public ngOnDestroy() {}

	public onResolveValue(button: any) {
		if (button.click) {
			button.click();
		}
		this.toast.resolve(button.value);
	}

	public setToastInstance(toast: ToastInstance) {
		this.toast = toast;
	}

	protected initLabels() {
		this.translatedHeader = this.translateService.getIfResource(
			this.item.header
		);
		this.translatedBody = this.translateService.getIfResource(
			this.item.message
		);

		if (this.isFooterVisible) {
			this.item.buttons.forEach((button) => {
				button.translatedLabel = this.translateService.getIfResource(
					button.label
				);
			});
		}
	}

	protected initToast() {
		this.toastService
			.open(this.template, this.item.getToastConfig())
			.then((result) =>
				this
					? this.notificationResult.emit({
							value: result,
							item: this.item,
					  })
					: null
			)
			.catch(() =>
				this
					? this.notificationResult.emit({
							value: undefined,
							item: this.item,
					  })
					: undefined
			);
	}
}
