import { UploadTranslations } from '@cds/components-angular';
import { __ } from 'seed/lib/i18n';

export const cdsUploadTranslations: UploadTranslations = {
	dragAndDrop: __('shared.upload.drag_drop'),
	browse: __('shared.upload.or_browse'),
	remove: __('shared.upload.remove'),
	add: __('shared.upload.add'),
	pending: __('shared.upload.pending'),
	loading: __('shared.upload.loading'),
	completed: __('shared.upload.completed'),
	error: __('shared.upload.error'),
	srFileName: __('shared.upload.srFileName'),
	srUploadStatus: __('shared.upload.srUploadStatus'),
	srActions: __('shared.upload.srActions'),
};
