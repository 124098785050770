
import { trigger } from '@angular/animations';
import { Component, HostBinding, Inject } from '@angular/core';
import { CDS_DIALOG_INSTANCE, CDS_DIALOG_TRANSITIONS, DialogInstance } from '@cds/components-angular';
import { __ } from 'seed/lib/i18n';

@Component({
	template: `
		<cds-dialog class="confirm-dialog" (dialogClose)="instance.reject()">
            <cds-slot-title>
                {{title}}
            </cds-slot-title>
            <cds-slot-content>
                {{content}}
            </cds-slot-content>
            <cds-slot-footer>
				<div class="cds-dialog-buttons">
					<button cdsButton class="cds-button-text" (click)="instance.reject()">
						{{reject}}
					</button>
					<button cdsButton class="cds-button-primary" (click)="instance.resolve(true)">
						{{confirm}}
					</button>
				</div>
            </cds-slot-footer>
        </cds-dialog>
	`,
	animations: [
		trigger('animate', [ ...CDS_DIALOG_TRANSITIONS ]),
	],

})
export class ConfirmChangesDialogComponent {
	@HostBinding('@animate')
	animate = 1;

	public title = __('shared.core.routing.confirmDialog.title');
	public content = __('shared.core.routing.confirmDialog.content');
	public confirm = __('shared.core.leave');
	public reject = __('shared.core.stay');

	constructor(@Inject(CDS_DIALOG_INSTANCE) public instance: DialogInstance<unknown>) {}
}
