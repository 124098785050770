import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutModule as CdsLayoutModule, HeaderModule, SidenavModule, BreadcrumbsModule } from '@cds/components-angular';
import { HTTP_SERVICE_INTERCEPTORS } from 'framework/app/core/http/http-request-handler';
import { AccountModule } from '../account/account.module';
import { MenuModule } from '../menu/menu.module';
import { NavigationModule } from '../navigation/navigation.module';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { LayoutHeaderComponent } from './components/layout-header/layout-header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { MessageRouteComponent } from './components/message/message-route.component';
import { LayoutHttpProgressInterceptor } from './interceptors/layout-http-progress.interceptor';
import { NotificationModule } from '../notification/notification.module';
import { NgForIn } from './directives/ng-for-in.directive';

const NG_MODULES = [CommonModule, RouterModule];

const SHARED_MODULES = [AccountModule, MenuModule, NavigationModule, AccountModule.forChild(), NotificationModule];

const CDS_MODULES = [CdsLayoutModule, HeaderModule, SidenavModule, BreadcrumbsModule];

@NgModule({
	imports: [NG_MODULES, CDS_MODULES, SHARED_MODULES],
	declarations: [
		BaseLayoutComponent,
		LayoutComponent,
		LayoutHeaderComponent,
		LoginLayoutComponent,
		MessageRouteComponent,
		NgForIn,
	],
	exports: [
		LayoutComponent,
		LayoutHeaderComponent,
		LoginLayoutComponent,
		MessageRouteComponent,
		NgForIn,
	],
})
export class LayoutModule {
	public static forRoot(): ModuleWithProviders<LayoutModule> {
		return {
			ngModule: LayoutModule,
			providers: [
				{
					provide: HTTP_SERVICE_INTERCEPTORS,
					useExisting: LayoutHttpProgressInterceptor,
					multi: true,
				},
			],
		};
	}
}
