import { ItemPager } from 'framework/app/utils/item-pager';

export interface BaseListFilterDefinition {
	type: string;
	label: string;
	value: any;
	name: string;
	placeholder?: string;
	dateFormat?: string;
	comboItems?: any;
	isArray?: boolean;
}

export interface BaseListFilters {
	[name: string]: string | string[];
}

export interface BaseListDefinition<T> {
	headers: BaseListHeader<T>[];
	rows: BaseListRow<T>[];
	actions?: RowAction<T>[];
}

export interface BaseListHeader<T> {
	name: string;
	canSort?: boolean;
	label: string;
	classes?: string[];
}

export interface BaseListRow<T> {
	type: string;
	propertyName?: string;
	displayValue?: (val) => string;
	datePipe?: DatePipe;
	status?: BaseListStatusRow<T>;
	classes?: string[];
}

export interface DatePipe {
	format: string;
	timezone?: string;
}

export interface BaseListStatusRow<T> {
	displayStatus: (item: T) => string;
	showIcon: (item: T) => string;
}

export interface RowAction<T> {
	label: string;
	showIf: (item: T) => boolean;
	action: (item: T) => void;
}

export interface GroupAction {
	label: string;
	action?: () => void;
	showIf?: () => boolean;
}

export class BaseListState {
	public pager: ItemPager;
	public pageSize: number;

	constructor() {
		this.pageSize = 10;
		this.pager = new ItemPager({
			pageSize: this.pageSize,
		});
	}
}
