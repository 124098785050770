import { PagerTranslations } from '@cds/components-angular';
import { __ } from 'seed/lib/i18n';

export const cdsPagerTranslations: PagerTranslations = {
	visibleItems: __('shared.pager.visible_items', '{{1}}', '{{2}}', '{{3}}'),
	srVisibleItems: __('shared.pager.sr_visible_items', '{{1}}', '{{2}}', '{{3}}'),
	itemsPerPage: __('shared.pager.items_per_page'),
	next: __('shared.pager.next'),
	previous: __('shared.pager.previous'),
	firstPage: __('shared.pager.first_page'),
	previousPage: __('shared.pager.previous_page'),
	currentPage: __('shared.pager.current_page'),
	nextPage: __('shared.pager.next_page'),
	lastPage: __('shared.pager.last_page'),
	pageNumber: __('shared.pager.page_number', '{{1}}'),
	of: __('shared.pager.of'),
};
