export const DEFAULT_PAGE_SIZE: number = 60;

export interface ItemPagerOptions {
	pageSize?: number;
	currentPage?: number;
	totalElements?: number;
	onChange?: (pager: ItemPager) => void;
}

export class ItemPager {

	private _isFirst: boolean = false;
	private _isLast: boolean = false;
	private _totalElements: number = 0;
	private _startElement: number = 0;
	private _endElement: number = 0;
	private _pageSize: number = DEFAULT_PAGE_SIZE;
	private _currentPage: number = 1;
	private _totalPages: number = 1;
	private onChange: (pager: ItemPager) => void = () => {};

	get isFirst(): boolean {
		return this._isFirst;
	}

	get isLast(): boolean {
		return this._isLast;
	}

	get totalElements(): number {
		return this._totalElements;
	}

	get startElement(): number {
		return this._startElement;
	}

	get endElement(): number {
		return this._endElement;
	}

	get pageSize(): number {
		return this._pageSize;
	}

	get currentPage(): number {
		return this._currentPage;
	}

	set currentPage(page: number) {
		this.changePage(page);
	}

	get totalPages(): number {
		return this._totalPages;
	}

	constructor(options: ItemPagerOptions) {
		this.update(options);
	}

	first(): boolean {
		return this.changePage(1);
	}

	last(): boolean {
		return this.changePage(this._totalPages);
	}

	prev(): boolean {
		return this.changePage(this._currentPage-1);
	}

	next(): boolean {
		return this.changePage(this._currentPage+1);
	}

	changePage(page: number): boolean {
		if (page < 1 || page > this._totalPages) return false;

		this._currentPage = page;

		this.recalculate();
		this.onChange(this);
		return true;
	}

	debug(): Object {
		return {
			isFirst: this.isFirst,
			isLast: this.isLast,
			totalElements: this.totalElements,
			pageSize: this.pageSize,
			currentPage: this.currentPage,
			totalPages: this.totalPages,
		};
	}

	update(options: ItemPagerOptions): void {
		const pageSizeChanged: boolean = options.hasOwnProperty('pageSize');
		const currentPageChanged: boolean = options.hasOwnProperty('currentPage');
		const totalElementsChanged: boolean = options.hasOwnProperty('totalElements');

		let newPageSize = pageSizeChanged ? options.pageSize : this._pageSize;
		let newCurrentPage = currentPageChanged ? options.currentPage : this._currentPage;
		let newTotalElements = totalElementsChanged ? options.totalElements : this._totalElements;

		if (!currentPageChanged) {
			if (pageSizeChanged || totalElementsChanged) {
				newCurrentPage = 1;
			}
		}

		if (newPageSize < 1) {
			newPageSize = DEFAULT_PAGE_SIZE;
		}

		if (newTotalElements < 1) {
			newTotalElements = 0;
		}

		const newTotalPages = newTotalElements === 0 ? 1 : Math.ceil(newTotalElements/newPageSize);

		if (newCurrentPage < 1) {
			newCurrentPage = 1;
		} else if (newCurrentPage > newTotalPages) {
			newCurrentPage = 1;
		}

		this._pageSize = newPageSize;
		this._currentPage = newCurrentPage;
		this._totalElements = newTotalElements;
		this._totalPages = newTotalPages;
		this.onChange = options.onChange || (() => {});

		this.recalculate();
		this.onChange(this);
	}

	private recalculate(): boolean {
		let rangeChanged = false;

		const newStartElement: number = Math.min(this._totalElements, (this._currentPage - 1)*this._pageSize + 1);
		const newEndElement: number = Math.min(this._totalElements, this._currentPage*this._pageSize);

		if (newStartElement !== this._startElement || newEndElement !== this._endElement) {
			rangeChanged = true;
		}

		this._startElement = newStartElement;
		this._endElement = newEndElement;
		this._isFirst = this._currentPage === 1;
		this._isLast = this._currentPage === this._totalPages;

		return rangeChanged;
	}
}
