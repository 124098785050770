import { __ } from 'seed/lib/i18n';

export const documentReferenceTypeCode = {
	'documentReferenceTypeCode.AAA': __('documentReferenceTypeCode.AAA'),
	'documentReferenceTypeCode.AAB': __('documentReferenceTypeCode.AAB'),
	'documentReferenceTypeCode.AAC': __('documentReferenceTypeCode.AAC'),
	'documentReferenceTypeCode.AAD': __('documentReferenceTypeCode.AAD'),
	'documentReferenceTypeCode.AAE': __('documentReferenceTypeCode.AAE'),
	'documentReferenceTypeCode.AAF': __('documentReferenceTypeCode.AAF'),
	'documentReferenceTypeCode.AAG': __('documentReferenceTypeCode.AAG'),
	'documentReferenceTypeCode.AAH': __('documentReferenceTypeCode.AAH'),
	'documentReferenceTypeCode.AAI': __('documentReferenceTypeCode.AAI'),
	'documentReferenceTypeCode.AAJ': __('documentReferenceTypeCode.AAJ'),
	'documentReferenceTypeCode.AAK': __('documentReferenceTypeCode.AAK'),
	'documentReferenceTypeCode.AAL': __('documentReferenceTypeCode.AAL'),
	'documentReferenceTypeCode.AAM': __('documentReferenceTypeCode.AAM'),
	'documentReferenceTypeCode.AAN': __('documentReferenceTypeCode.AAN'),
	'documentReferenceTypeCode.AAO': __('documentReferenceTypeCode.AAO'),
	'documentReferenceTypeCode.AAP': __('documentReferenceTypeCode.AAP'),
	'documentReferenceTypeCode.AAQ': __('documentReferenceTypeCode.AAQ'),
	'documentReferenceTypeCode.AAR': __('documentReferenceTypeCode.AAR'),
	'documentReferenceTypeCode.AAS': __('documentReferenceTypeCode.AAS'),
	'documentReferenceTypeCode.AAT': __('documentReferenceTypeCode.AAT'),
	'documentReferenceTypeCode.AAU': __('documentReferenceTypeCode.AAU'),
	'documentReferenceTypeCode.AAV': __('documentReferenceTypeCode.AAV'),
	'documentReferenceTypeCode.AAW': __('documentReferenceTypeCode.AAW'),
	'documentReferenceTypeCode.AAX': __('documentReferenceTypeCode.AAX'),
	'documentReferenceTypeCode.AAY': __('documentReferenceTypeCode.AAY'),
	'documentReferenceTypeCode.AAZ': __('documentReferenceTypeCode.AAZ'),
	'documentReferenceTypeCode.ABA': __('documentReferenceTypeCode.ABA'),
	'documentReferenceTypeCode.ABB': __('documentReferenceTypeCode.ABB'),
	'documentReferenceTypeCode.ABC': __('documentReferenceTypeCode.ABC'),
	'documentReferenceTypeCode.ABD': __('documentReferenceTypeCode.ABD'),
	'documentReferenceTypeCode.ABE': __('documentReferenceTypeCode.ABE'),
	'documentReferenceTypeCode.ABF': __('documentReferenceTypeCode.ABF'),
	'documentReferenceTypeCode.ABG': __('documentReferenceTypeCode.ABG'),
	'documentReferenceTypeCode.ABH': __('documentReferenceTypeCode.ABH'),
	'documentReferenceTypeCode.ABI': __('documentReferenceTypeCode.ABI'),
	'documentReferenceTypeCode.ABJ': __('documentReferenceTypeCode.ABJ'),
	'documentReferenceTypeCode.ABK': __('documentReferenceTypeCode.ABK'),
	'documentReferenceTypeCode.ABL': __('documentReferenceTypeCode.ABL'),
	'documentReferenceTypeCode.ABM': __('documentReferenceTypeCode.ABM'),
	'documentReferenceTypeCode.ABN': __('documentReferenceTypeCode.ABN'),
	'documentReferenceTypeCode.ABO': __('documentReferenceTypeCode.ABO'),
	'documentReferenceTypeCode.ABP': __('documentReferenceTypeCode.ABP'),
	'documentReferenceTypeCode.ABQ': __('documentReferenceTypeCode.ABQ'),
	'documentReferenceTypeCode.ABR': __('documentReferenceTypeCode.ABR'),
	'documentReferenceTypeCode.ABS': __('documentReferenceTypeCode.ABS'),
	'documentReferenceTypeCode.ABT': __('documentReferenceTypeCode.ABT'),
	'documentReferenceTypeCode.ABU': __('documentReferenceTypeCode.ABU'),
	'documentReferenceTypeCode.ABV': __('documentReferenceTypeCode.ABV'),
	'documentReferenceTypeCode.ABW': __('documentReferenceTypeCode.ABW'),
	'documentReferenceTypeCode.ABX': __('documentReferenceTypeCode.ABX'),
	'documentReferenceTypeCode.ABY': __('documentReferenceTypeCode.ABY'),
	'documentReferenceTypeCode.ABZ': __('documentReferenceTypeCode.ABZ'),
	'documentReferenceTypeCode.AC': __('documentReferenceTypeCode.AC'),
	'documentReferenceTypeCode.ACA': __('documentReferenceTypeCode.ACA'),
	'documentReferenceTypeCode.ACB': __('documentReferenceTypeCode.ACB'),
	'documentReferenceTypeCode.ACC': __('documentReferenceTypeCode.ACC'),
	'documentReferenceTypeCode.ACD': __('documentReferenceTypeCode.ACD'),
	'documentReferenceTypeCode.ACE': __('documentReferenceTypeCode.ACE'),
	'documentReferenceTypeCode.ACF': __('documentReferenceTypeCode.ACF'),
	'documentReferenceTypeCode.ACG': __('documentReferenceTypeCode.ACG'),
	'documentReferenceTypeCode.ACH': __('documentReferenceTypeCode.ACH'),
	'documentReferenceTypeCode.ACI': __('documentReferenceTypeCode.ACI'),
	'documentReferenceTypeCode.ACJ': __('documentReferenceTypeCode.ACJ'),
	'documentReferenceTypeCode.ACK': __('documentReferenceTypeCode.ACK'),
	'documentReferenceTypeCode.ACL': __('documentReferenceTypeCode.ACL'),
	'documentReferenceTypeCode.ACN': __('documentReferenceTypeCode.ACN'),
	'documentReferenceTypeCode.ACO': __('documentReferenceTypeCode.ACO'),
	'documentReferenceTypeCode.ACP': __('documentReferenceTypeCode.ACP'),
	'documentReferenceTypeCode.ACQ': __('documentReferenceTypeCode.ACQ'),
	'documentReferenceTypeCode.ACR': __('documentReferenceTypeCode.ACR'),
	'documentReferenceTypeCode.ACT': __('documentReferenceTypeCode.ACT'),
	'documentReferenceTypeCode.ACU': __('documentReferenceTypeCode.ACU'),
	'documentReferenceTypeCode.ACV': __('documentReferenceTypeCode.ACV'),
	'documentReferenceTypeCode.ACW': __('documentReferenceTypeCode.ACW'),
	'documentReferenceTypeCode.ACX': __('documentReferenceTypeCode.ACX'),
	'documentReferenceTypeCode.ACY': __('documentReferenceTypeCode.ACY'),
	'documentReferenceTypeCode.ACZ': __('documentReferenceTypeCode.ACZ'),
	'documentReferenceTypeCode.ADA': __('documentReferenceTypeCode.ADA'),
	'documentReferenceTypeCode.ADB': __('documentReferenceTypeCode.ADB'),
	'documentReferenceTypeCode.ADC': __('documentReferenceTypeCode.ADC'),
	'documentReferenceTypeCode.ADD': __('documentReferenceTypeCode.ADD'),
	'documentReferenceTypeCode.ADE': __('documentReferenceTypeCode.ADE'),
	'documentReferenceTypeCode.ADF': __('documentReferenceTypeCode.ADF'),
	'documentReferenceTypeCode.ADG': __('documentReferenceTypeCode.ADG'),
	'documentReferenceTypeCode.ADI': __('documentReferenceTypeCode.ADI'),
	'documentReferenceTypeCode.ADJ': __('documentReferenceTypeCode.ADJ'),
	'documentReferenceTypeCode.ADK': __('documentReferenceTypeCode.ADK'),
	'documentReferenceTypeCode.ADL': __('documentReferenceTypeCode.ADL'),
	'documentReferenceTypeCode.ADM': __('documentReferenceTypeCode.ADM'),
	'documentReferenceTypeCode.ADN': __('documentReferenceTypeCode.ADN'),
	'documentReferenceTypeCode.ADO': __('documentReferenceTypeCode.ADO'),
	'documentReferenceTypeCode.ADP': __('documentReferenceTypeCode.ADP'),
	'documentReferenceTypeCode.ADQ': __('documentReferenceTypeCode.ADQ'),
	'documentReferenceTypeCode.ADT': __('documentReferenceTypeCode.ADT'),
	'documentReferenceTypeCode.ADU': __('documentReferenceTypeCode.ADU'),
	'documentReferenceTypeCode.ADV': __('documentReferenceTypeCode.ADV'),
	'documentReferenceTypeCode.ADW': __('documentReferenceTypeCode.ADW'),
	'documentReferenceTypeCode.ADX': __('documentReferenceTypeCode.ADX'),
	'documentReferenceTypeCode.ADY': __('documentReferenceTypeCode.ADY'),
	'documentReferenceTypeCode.ADZ': __('documentReferenceTypeCode.ADZ'),
	'documentReferenceTypeCode.AE': __('documentReferenceTypeCode.AE'),
	'documentReferenceTypeCode.AEA': __('documentReferenceTypeCode.AEA'),
	'documentReferenceTypeCode.AEB': __('documentReferenceTypeCode.AEB'),
	'documentReferenceTypeCode.AEC': __('documentReferenceTypeCode.AEC'),
	'documentReferenceTypeCode.AED': __('documentReferenceTypeCode.AED'),
	'documentReferenceTypeCode.AEE': __('documentReferenceTypeCode.AEE'),
	'documentReferenceTypeCode.AEF': __('documentReferenceTypeCode.AEF'),
	'documentReferenceTypeCode.AEG': __('documentReferenceTypeCode.AEG'),
	'documentReferenceTypeCode.AEH': __('documentReferenceTypeCode.AEH'),
	'documentReferenceTypeCode.AEI': __('documentReferenceTypeCode.AEI'),
	'documentReferenceTypeCode.AEJ': __('documentReferenceTypeCode.AEJ'),
	'documentReferenceTypeCode.AEK': __('documentReferenceTypeCode.AEK'),
	'documentReferenceTypeCode.AEL': __('documentReferenceTypeCode.AEL'),
	'documentReferenceTypeCode.AEM': __('documentReferenceTypeCode.AEM'),
	'documentReferenceTypeCode.AEN': __('documentReferenceTypeCode.AEN'),
	'documentReferenceTypeCode.AEO': __('documentReferenceTypeCode.AEO'),
	'documentReferenceTypeCode.AEP': __('documentReferenceTypeCode.AEP'),
	'documentReferenceTypeCode.AEQ': __('documentReferenceTypeCode.AEQ'),
	'documentReferenceTypeCode.AER': __('documentReferenceTypeCode.AER'),
	'documentReferenceTypeCode.AES': __('documentReferenceTypeCode.AES'),
	'documentReferenceTypeCode.AET': __('documentReferenceTypeCode.AET'),
	'documentReferenceTypeCode.AEU': __('documentReferenceTypeCode.AEU'),
	'documentReferenceTypeCode.AEV': __('documentReferenceTypeCode.AEV'),
	'documentReferenceTypeCode.AEW': __('documentReferenceTypeCode.AEW'),
	'documentReferenceTypeCode.AEX': __('documentReferenceTypeCode.AEX'),
	'documentReferenceTypeCode.AEY': __('documentReferenceTypeCode.AEY'),
	'documentReferenceTypeCode.AEZ': __('documentReferenceTypeCode.AEZ'),
	'documentReferenceTypeCode.AF': __('documentReferenceTypeCode.AF'),
	'documentReferenceTypeCode.AFA': __('documentReferenceTypeCode.AFA'),
	'documentReferenceTypeCode.AFB': __('documentReferenceTypeCode.AFB'),
	'documentReferenceTypeCode.AFC': __('documentReferenceTypeCode.AFC'),
	'documentReferenceTypeCode.AFD': __('documentReferenceTypeCode.AFD'),
	'documentReferenceTypeCode.AFE': __('documentReferenceTypeCode.AFE'),
	'documentReferenceTypeCode.AFF': __('documentReferenceTypeCode.AFF'),
	'documentReferenceTypeCode.AFG': __('documentReferenceTypeCode.AFG'),
	'documentReferenceTypeCode.AFH': __('documentReferenceTypeCode.AFH'),
	'documentReferenceTypeCode.AFI': __('documentReferenceTypeCode.AFI'),
	'documentReferenceTypeCode.AFJ': __('documentReferenceTypeCode.AFJ'),
	'documentReferenceTypeCode.AFK': __('documentReferenceTypeCode.AFK'),
	'documentReferenceTypeCode.AFL': __('documentReferenceTypeCode.AFL'),
	'documentReferenceTypeCode.AFM': __('documentReferenceTypeCode.AFM'),
	'documentReferenceTypeCode.AFN': __('documentReferenceTypeCode.AFN'),
	'documentReferenceTypeCode.AFO': __('documentReferenceTypeCode.AFO'),
	'documentReferenceTypeCode.AFP': __('documentReferenceTypeCode.AFP'),
	'documentReferenceTypeCode.AFQ': __('documentReferenceTypeCode.AFQ'),
	'documentReferenceTypeCode.AFR': __('documentReferenceTypeCode.AFR'),
	'documentReferenceTypeCode.AFS': __('documentReferenceTypeCode.AFS'),
	'documentReferenceTypeCode.AFT': __('documentReferenceTypeCode.AFT'),
	'documentReferenceTypeCode.AFU': __('documentReferenceTypeCode.AFU'),
	'documentReferenceTypeCode.AFV': __('documentReferenceTypeCode.AFV'),
	'documentReferenceTypeCode.AFW': __('documentReferenceTypeCode.AFW'),
	'documentReferenceTypeCode.AFX': __('documentReferenceTypeCode.AFX'),
	'documentReferenceTypeCode.AFY': __('documentReferenceTypeCode.AFY'),
	'documentReferenceTypeCode.AFZ': __('documentReferenceTypeCode.AFZ'),
	'documentReferenceTypeCode.AGA': __('documentReferenceTypeCode.AGA'),
	'documentReferenceTypeCode.AGB': __('documentReferenceTypeCode.AGB'),
	'documentReferenceTypeCode.AGC': __('documentReferenceTypeCode.AGC'),
	'documentReferenceTypeCode.AGD': __('documentReferenceTypeCode.AGD'),
	'documentReferenceTypeCode.AGE': __('documentReferenceTypeCode.AGE'),
	'documentReferenceTypeCode.AGF': __('documentReferenceTypeCode.AGF'),
	'documentReferenceTypeCode.AGG': __('documentReferenceTypeCode.AGG'),
	'documentReferenceTypeCode.AGH': __('documentReferenceTypeCode.AGH'),
	'documentReferenceTypeCode.AGI': __('documentReferenceTypeCode.AGI'),
	'documentReferenceTypeCode.AGJ': __('documentReferenceTypeCode.AGJ'),
	'documentReferenceTypeCode.AGK': __('documentReferenceTypeCode.AGK'),
	'documentReferenceTypeCode.AGL': __('documentReferenceTypeCode.AGL'),
	'documentReferenceTypeCode.AGM': __('documentReferenceTypeCode.AGM'),
	'documentReferenceTypeCode.AGN': __('documentReferenceTypeCode.AGN'),
	'documentReferenceTypeCode.AGO': __('documentReferenceTypeCode.AGO'),
	'documentReferenceTypeCode.AGP': __('documentReferenceTypeCode.AGP'),
	'documentReferenceTypeCode.AGQ': __('documentReferenceTypeCode.AGQ'),
	'documentReferenceTypeCode.AGR': __('documentReferenceTypeCode.AGR'),
	'documentReferenceTypeCode.AGS': __('documentReferenceTypeCode.AGS'),
	'documentReferenceTypeCode.AGT': __('documentReferenceTypeCode.AGT'),
	'documentReferenceTypeCode.AGU': __('documentReferenceTypeCode.AGU'),
	'documentReferenceTypeCode.AGV': __('documentReferenceTypeCode.AGV'),
	'documentReferenceTypeCode.AGW': __('documentReferenceTypeCode.AGW'),
	'documentReferenceTypeCode.AGX': __('documentReferenceTypeCode.AGX'),
	'documentReferenceTypeCode.AGY': __('documentReferenceTypeCode.AGY'),
	'documentReferenceTypeCode.AGZ': __('documentReferenceTypeCode.AGZ'),
	'documentReferenceTypeCode.AHA': __('documentReferenceTypeCode.AHA'),
	'documentReferenceTypeCode.AHB': __('documentReferenceTypeCode.AHB'),
	'documentReferenceTypeCode.AHC': __('documentReferenceTypeCode.AHC'),
	'documentReferenceTypeCode.AHD': __('documentReferenceTypeCode.AHD'),
	'documentReferenceTypeCode.AHE': __('documentReferenceTypeCode.AHE'),
	'documentReferenceTypeCode.AHF': __('documentReferenceTypeCode.AHF'),
	'documentReferenceTypeCode.AHG': __('documentReferenceTypeCode.AHG'),
	'documentReferenceTypeCode.AHH': __('documentReferenceTypeCode.AHH'),
	'documentReferenceTypeCode.AHI': __('documentReferenceTypeCode.AHI'),
	'documentReferenceTypeCode.AHJ': __('documentReferenceTypeCode.AHJ'),
	'documentReferenceTypeCode.AHK': __('documentReferenceTypeCode.AHK'),
	'documentReferenceTypeCode.AHL': __('documentReferenceTypeCode.AHL'),
	'documentReferenceTypeCode.AHM': __('documentReferenceTypeCode.AHM'),
	'documentReferenceTypeCode.AHN': __('documentReferenceTypeCode.AHN'),
	'documentReferenceTypeCode.AHO': __('documentReferenceTypeCode.AHO'),
	'documentReferenceTypeCode.AHP': __('documentReferenceTypeCode.AHP'),
	'documentReferenceTypeCode.AHQ': __('documentReferenceTypeCode.AHQ'),
	'documentReferenceTypeCode.AHR': __('documentReferenceTypeCode.AHR'),
	'documentReferenceTypeCode.AHS': __('documentReferenceTypeCode.AHS'),
	'documentReferenceTypeCode.AHT': __('documentReferenceTypeCode.AHT'),
	'documentReferenceTypeCode.AHU': __('documentReferenceTypeCode.AHU'),
	'documentReferenceTypeCode.AHV': __('documentReferenceTypeCode.AHV'),
	'documentReferenceTypeCode.AHX': __('documentReferenceTypeCode.AHX'),
	'documentReferenceTypeCode.AHY': __('documentReferenceTypeCode.AHY'),
	'documentReferenceTypeCode.AHZ': __('documentReferenceTypeCode.AHZ'),
	'documentReferenceTypeCode.AIA': __('documentReferenceTypeCode.AIA'),
	'documentReferenceTypeCode.AIB': __('documentReferenceTypeCode.AIB'),
	'documentReferenceTypeCode.AIC': __('documentReferenceTypeCode.AIC'),
	'documentReferenceTypeCode.AID': __('documentReferenceTypeCode.AID'),
	'documentReferenceTypeCode.AIE': __('documentReferenceTypeCode.AIE'),
	'documentReferenceTypeCode.AIF': __('documentReferenceTypeCode.AIF'),
	'documentReferenceTypeCode.AIG': __('documentReferenceTypeCode.AIG'),
	'documentReferenceTypeCode.AIH': __('documentReferenceTypeCode.AIH'),
	'documentReferenceTypeCode.AII': __('documentReferenceTypeCode.AII'),
	'documentReferenceTypeCode.AIJ': __('documentReferenceTypeCode.AIJ'),
	'documentReferenceTypeCode.AIK': __('documentReferenceTypeCode.AIK'),
	'documentReferenceTypeCode.AIL': __('documentReferenceTypeCode.AIL'),
	'documentReferenceTypeCode.AIM': __('documentReferenceTypeCode.AIM'),
	'documentReferenceTypeCode.AIN': __('documentReferenceTypeCode.AIN'),
	'documentReferenceTypeCode.AIO': __('documentReferenceTypeCode.AIO'),
	'documentReferenceTypeCode.AIP': __('documentReferenceTypeCode.AIP'),
	'documentReferenceTypeCode.AIQ': __('documentReferenceTypeCode.AIQ'),
	'documentReferenceTypeCode.AIR': __('documentReferenceTypeCode.AIR'),
	'documentReferenceTypeCode.AIS': __('documentReferenceTypeCode.AIS'),
	'documentReferenceTypeCode.AIT': __('documentReferenceTypeCode.AIT'),
	'documentReferenceTypeCode.AIU': __('documentReferenceTypeCode.AIU'),
	'documentReferenceTypeCode.AIV': __('documentReferenceTypeCode.AIV'),
	'documentReferenceTypeCode.AIW': __('documentReferenceTypeCode.AIW'),
	'documentReferenceTypeCode.AIX': __('documentReferenceTypeCode.AIX'),
	'documentReferenceTypeCode.AIY': __('documentReferenceTypeCode.AIY'),
	'documentReferenceTypeCode.AIZ': __('documentReferenceTypeCode.AIZ'),
	'documentReferenceTypeCode.AJA': __('documentReferenceTypeCode.AJA'),
	'documentReferenceTypeCode.AJB': __('documentReferenceTypeCode.AJB'),
	'documentReferenceTypeCode.AJC': __('documentReferenceTypeCode.AJC'),
	'documentReferenceTypeCode.AJD': __('documentReferenceTypeCode.AJD'),
	'documentReferenceTypeCode.AJE': __('documentReferenceTypeCode.AJE'),
	'documentReferenceTypeCode.AJF': __('documentReferenceTypeCode.AJF'),
	'documentReferenceTypeCode.AJG': __('documentReferenceTypeCode.AJG'),
	'documentReferenceTypeCode.AJH': __('documentReferenceTypeCode.AJH'),
	'documentReferenceTypeCode.AJI': __('documentReferenceTypeCode.AJI'),
	'documentReferenceTypeCode.AJJ': __('documentReferenceTypeCode.AJJ'),
	'documentReferenceTypeCode.AJK': __('documentReferenceTypeCode.AJK'),
	'documentReferenceTypeCode.AJL': __('documentReferenceTypeCode.AJL'),
	'documentReferenceTypeCode.AJM': __('documentReferenceTypeCode.AJM'),
	'documentReferenceTypeCode.AJN': __('documentReferenceTypeCode.AJN'),
	'documentReferenceTypeCode.AJO': __('documentReferenceTypeCode.AJO'),
	'documentReferenceTypeCode.AJP': __('documentReferenceTypeCode.AJP'),
	'documentReferenceTypeCode.AJQ': __('documentReferenceTypeCode.AJQ'),
	'documentReferenceTypeCode.AJR': __('documentReferenceTypeCode.AJR'),
	'documentReferenceTypeCode.AJS': __('documentReferenceTypeCode.AJS'),
	'documentReferenceTypeCode.AJT': __('documentReferenceTypeCode.AJT'),
	'documentReferenceTypeCode.AJU': __('documentReferenceTypeCode.AJU'),
	'documentReferenceTypeCode.AJV': __('documentReferenceTypeCode.AJV'),
	'documentReferenceTypeCode.AJW': __('documentReferenceTypeCode.AJW'),
	'documentReferenceTypeCode.AJX': __('documentReferenceTypeCode.AJX'),
	'documentReferenceTypeCode.AJY': __('documentReferenceTypeCode.AJY'),
	'documentReferenceTypeCode.AJZ': __('documentReferenceTypeCode.AJZ'),
	'documentReferenceTypeCode.AKA': __('documentReferenceTypeCode.AKA'),
	'documentReferenceTypeCode.AKB': __('documentReferenceTypeCode.AKB'),
	'documentReferenceTypeCode.AKC': __('documentReferenceTypeCode.AKC'),
	'documentReferenceTypeCode.AKD': __('documentReferenceTypeCode.AKD'),
	'documentReferenceTypeCode.AKE': __('documentReferenceTypeCode.AKE'),
	'documentReferenceTypeCode.AKF': __('documentReferenceTypeCode.AKF'),
	'documentReferenceTypeCode.AKG': __('documentReferenceTypeCode.AKG'),
	'documentReferenceTypeCode.AKH': __('documentReferenceTypeCode.AKH'),
	'documentReferenceTypeCode.AKI': __('documentReferenceTypeCode.AKI'),
	'documentReferenceTypeCode.AKJ': __('documentReferenceTypeCode.AKJ'),
	'documentReferenceTypeCode.AKK': __('documentReferenceTypeCode.AKK'),
	'documentReferenceTypeCode.AKL': __('documentReferenceTypeCode.AKL'),
	'documentReferenceTypeCode.AKM': __('documentReferenceTypeCode.AKM'),
	'documentReferenceTypeCode.AKN': __('documentReferenceTypeCode.AKN'),
	'documentReferenceTypeCode.AKO': __('documentReferenceTypeCode.AKO'),
	'documentReferenceTypeCode.AKP': __('documentReferenceTypeCode.AKP'),
	'documentReferenceTypeCode.AKQ': __('documentReferenceTypeCode.AKQ'),
	'documentReferenceTypeCode.AKR': __('documentReferenceTypeCode.AKR'),
	'documentReferenceTypeCode.AKS': __('documentReferenceTypeCode.AKS'),
	'documentReferenceTypeCode.AKT': __('documentReferenceTypeCode.AKT'),
	'documentReferenceTypeCode.AKU': __('documentReferenceTypeCode.AKU'),
	'documentReferenceTypeCode.AKV': __('documentReferenceTypeCode.AKV'),
	'documentReferenceTypeCode.AKW': __('documentReferenceTypeCode.AKW'),
	'documentReferenceTypeCode.AKX': __('documentReferenceTypeCode.AKX'),
	'documentReferenceTypeCode.AKY': __('documentReferenceTypeCode.AKY'),
	'documentReferenceTypeCode.AKZ': __('documentReferenceTypeCode.AKZ'),
	'documentReferenceTypeCode.ALA': __('documentReferenceTypeCode.ALA'),
	'documentReferenceTypeCode.ALB': __('documentReferenceTypeCode.ALB'),
	'documentReferenceTypeCode.ALC': __('documentReferenceTypeCode.ALC'),
	'documentReferenceTypeCode.ALD': __('documentReferenceTypeCode.ALD'),
	'documentReferenceTypeCode.ALE': __('documentReferenceTypeCode.ALE'),
	'documentReferenceTypeCode.ALF': __('documentReferenceTypeCode.ALF'),
	'documentReferenceTypeCode.ALG': __('documentReferenceTypeCode.ALG'),
	'documentReferenceTypeCode.ALH': __('documentReferenceTypeCode.ALH'),
	'documentReferenceTypeCode.ALI': __('documentReferenceTypeCode.ALI'),
	'documentReferenceTypeCode.ALJ': __('documentReferenceTypeCode.ALJ'),
	'documentReferenceTypeCode.ALK': __('documentReferenceTypeCode.ALK'),
	'documentReferenceTypeCode.ALL': __('documentReferenceTypeCode.ALL'),
	'documentReferenceTypeCode.ALM': __('documentReferenceTypeCode.ALM'),
	'documentReferenceTypeCode.ALN': __('documentReferenceTypeCode.ALN'),
	'documentReferenceTypeCode.ALO': __('documentReferenceTypeCode.ALO'),
	'documentReferenceTypeCode.ALP': __('documentReferenceTypeCode.ALP'),
	'documentReferenceTypeCode.ALQ': __('documentReferenceTypeCode.ALQ'),
	'documentReferenceTypeCode.ALR': __('documentReferenceTypeCode.ALR'),
	'documentReferenceTypeCode.ALS': __('documentReferenceTypeCode.ALS'),
	'documentReferenceTypeCode.ALT': __('documentReferenceTypeCode.ALT'),
	'documentReferenceTypeCode.ALU': __('documentReferenceTypeCode.ALU'),
	'documentReferenceTypeCode.ALV': __('documentReferenceTypeCode.ALV'),
	'documentReferenceTypeCode.ALW': __('documentReferenceTypeCode.ALW'),
	'documentReferenceTypeCode.ALX': __('documentReferenceTypeCode.ALX'),
	'documentReferenceTypeCode.ALY': __('documentReferenceTypeCode.ALY'),
	'documentReferenceTypeCode.ALZ': __('documentReferenceTypeCode.ALZ'),
	'documentReferenceTypeCode.AMA': __('documentReferenceTypeCode.AMA'),
	'documentReferenceTypeCode.AMB': __('documentReferenceTypeCode.AMB'),
	'documentReferenceTypeCode.AMC': __('documentReferenceTypeCode.AMC'),
	'documentReferenceTypeCode.AMD': __('documentReferenceTypeCode.AMD'),
	'documentReferenceTypeCode.AME': __('documentReferenceTypeCode.AME'),
	'documentReferenceTypeCode.AMF': __('documentReferenceTypeCode.AMF'),
	'documentReferenceTypeCode.AMG': __('documentReferenceTypeCode.AMG'),
	'documentReferenceTypeCode.AMH': __('documentReferenceTypeCode.AMH'),
	'documentReferenceTypeCode.AMI': __('documentReferenceTypeCode.AMI'),
	'documentReferenceTypeCode.AMJ': __('documentReferenceTypeCode.AMJ'),
	'documentReferenceTypeCode.AMK': __('documentReferenceTypeCode.AMK'),
	'documentReferenceTypeCode.AML': __('documentReferenceTypeCode.AML'),
	'documentReferenceTypeCode.AMM': __('documentReferenceTypeCode.AMM'),
	'documentReferenceTypeCode.AMN': __('documentReferenceTypeCode.AMN'),
	'documentReferenceTypeCode.AMO': __('documentReferenceTypeCode.AMO'),
	'documentReferenceTypeCode.AMP': __('documentReferenceTypeCode.AMP'),
	'documentReferenceTypeCode.AMQ': __('documentReferenceTypeCode.AMQ'),
	'documentReferenceTypeCode.AMR': __('documentReferenceTypeCode.AMR'),
	'documentReferenceTypeCode.AMS': __('documentReferenceTypeCode.AMS'),
	'documentReferenceTypeCode.AMT': __('documentReferenceTypeCode.AMT'),
	'documentReferenceTypeCode.AMU': __('documentReferenceTypeCode.AMU'),
	'documentReferenceTypeCode.AMV': __('documentReferenceTypeCode.AMV'),
	'documentReferenceTypeCode.AMW': __('documentReferenceTypeCode.AMW'),
	'documentReferenceTypeCode.AMX': __('documentReferenceTypeCode.AMX'),
	'documentReferenceTypeCode.AMY': __('documentReferenceTypeCode.AMY'),
	'documentReferenceTypeCode.AMZ': __('documentReferenceTypeCode.AMZ'),
	'documentReferenceTypeCode.ANA': __('documentReferenceTypeCode.ANA'),
	'documentReferenceTypeCode.ANB': __('documentReferenceTypeCode.ANB'),
	'documentReferenceTypeCode.ANC': __('documentReferenceTypeCode.ANC'),
	'documentReferenceTypeCode.AND': __('documentReferenceTypeCode.AND'),
	'documentReferenceTypeCode.ANE': __('documentReferenceTypeCode.ANE'),
	'documentReferenceTypeCode.ANF': __('documentReferenceTypeCode.ANF'),
	'documentReferenceTypeCode.ANG': __('documentReferenceTypeCode.ANG'),
	'documentReferenceTypeCode.ANH': __('documentReferenceTypeCode.ANH'),
	'documentReferenceTypeCode.ANI': __('documentReferenceTypeCode.ANI'),
	'documentReferenceTypeCode.ANJ': __('documentReferenceTypeCode.ANJ'),
	'documentReferenceTypeCode.ANK': __('documentReferenceTypeCode.ANK'),
	'documentReferenceTypeCode.ANL': __('documentReferenceTypeCode.ANL'),
	'documentReferenceTypeCode.ANM': __('documentReferenceTypeCode.ANM'),
	'documentReferenceTypeCode.ANN': __('documentReferenceTypeCode.ANN'),
	'documentReferenceTypeCode.ANO': __('documentReferenceTypeCode.ANO'),
	'documentReferenceTypeCode.ANP': __('documentReferenceTypeCode.ANP'),
	'documentReferenceTypeCode.ANQ': __('documentReferenceTypeCode.ANQ'),
	'documentReferenceTypeCode.ANR': __('documentReferenceTypeCode.ANR'),
	'documentReferenceTypeCode.ANS': __('documentReferenceTypeCode.ANS'),
	'documentReferenceTypeCode.ANT': __('documentReferenceTypeCode.ANT'),
	'documentReferenceTypeCode.ANU': __('documentReferenceTypeCode.ANU'),
	'documentReferenceTypeCode.ANV': __('documentReferenceTypeCode.ANV'),
	'documentReferenceTypeCode.ANW': __('documentReferenceTypeCode.ANW'),
	'documentReferenceTypeCode.ANX': __('documentReferenceTypeCode.ANX'),
	'documentReferenceTypeCode.ANY': __('documentReferenceTypeCode.ANY'),
	'documentReferenceTypeCode.AOA': __('documentReferenceTypeCode.AOA'),
	'documentReferenceTypeCode.AOD': __('documentReferenceTypeCode.AOD'),
	'documentReferenceTypeCode.AOE': __('documentReferenceTypeCode.AOE'),
	'documentReferenceTypeCode.AOF': __('documentReferenceTypeCode.AOF'),
	'documentReferenceTypeCode.AOG': __('documentReferenceTypeCode.AOG'),
	'documentReferenceTypeCode.AOH': __('documentReferenceTypeCode.AOH'),
	'documentReferenceTypeCode.AOI': __('documentReferenceTypeCode.AOI'),
	'documentReferenceTypeCode.AOJ': __('documentReferenceTypeCode.AOJ'),
	'documentReferenceTypeCode.AOK': __('documentReferenceTypeCode.AOK'),
	'documentReferenceTypeCode.AOL': __('documentReferenceTypeCode.AOL'),
	'documentReferenceTypeCode.AOM': __('documentReferenceTypeCode.AOM'),
	'documentReferenceTypeCode.AON': __('documentReferenceTypeCode.AON'),
	'documentReferenceTypeCode.AOO': __('documentReferenceTypeCode.AOO'),
	'documentReferenceTypeCode.AOP': __('documentReferenceTypeCode.AOP'),
	'documentReferenceTypeCode.AOQ': __('documentReferenceTypeCode.AOQ'),
	'documentReferenceTypeCode.AOR': __('documentReferenceTypeCode.AOR'),
	'documentReferenceTypeCode.AOS': __('documentReferenceTypeCode.AOS'),
	'documentReferenceTypeCode.AOT': __('documentReferenceTypeCode.AOT'),
	'documentReferenceTypeCode.AOU': __('documentReferenceTypeCode.AOU'),
	'documentReferenceTypeCode.AOV': __('documentReferenceTypeCode.AOV'),
	'documentReferenceTypeCode.AOW': __('documentReferenceTypeCode.AOW'),
	'documentReferenceTypeCode.AOX': __('documentReferenceTypeCode.AOX'),
	'documentReferenceTypeCode.AOY': __('documentReferenceTypeCode.AOY'),
	'documentReferenceTypeCode.AOZ': __('documentReferenceTypeCode.AOZ'),
	'documentReferenceTypeCode.AP': __('documentReferenceTypeCode.AP'),
	'documentReferenceTypeCode.APA': __('documentReferenceTypeCode.APA'),
	'documentReferenceTypeCode.APB': __('documentReferenceTypeCode.APB'),
	'documentReferenceTypeCode.APC': __('documentReferenceTypeCode.APC'),
	'documentReferenceTypeCode.APD': __('documentReferenceTypeCode.APD'),
	'documentReferenceTypeCode.APE': __('documentReferenceTypeCode.APE'),
	'documentReferenceTypeCode.APF': __('documentReferenceTypeCode.APF'),
	'documentReferenceTypeCode.APG': __('documentReferenceTypeCode.APG'),
	'documentReferenceTypeCode.APH': __('documentReferenceTypeCode.APH'),
	'documentReferenceTypeCode.API': __('documentReferenceTypeCode.API'),
	'documentReferenceTypeCode.APJ': __('documentReferenceTypeCode.APJ'),
	'documentReferenceTypeCode.APK': __('documentReferenceTypeCode.APK'),
	'documentReferenceTypeCode.APL': __('documentReferenceTypeCode.APL'),
	'documentReferenceTypeCode.APM': __('documentReferenceTypeCode.APM'),
	'documentReferenceTypeCode.APN': __('documentReferenceTypeCode.APN'),
	'documentReferenceTypeCode.APO': __('documentReferenceTypeCode.APO'),
	'documentReferenceTypeCode.APP': __('documentReferenceTypeCode.APP'),
	'documentReferenceTypeCode.APQ': __('documentReferenceTypeCode.APQ'),
	'documentReferenceTypeCode.APR': __('documentReferenceTypeCode.APR'),
	'documentReferenceTypeCode.APS': __('documentReferenceTypeCode.APS'),
	'documentReferenceTypeCode.APT': __('documentReferenceTypeCode.APT'),
	'documentReferenceTypeCode.APU': __('documentReferenceTypeCode.APU'),
	'documentReferenceTypeCode.APV': __('documentReferenceTypeCode.APV'),
	'documentReferenceTypeCode.APW': __('documentReferenceTypeCode.APW'),
	'documentReferenceTypeCode.APX': __('documentReferenceTypeCode.APX'),
	'documentReferenceTypeCode.APY': __('documentReferenceTypeCode.APY'),
	'documentReferenceTypeCode.APZ': __('documentReferenceTypeCode.APZ'),
	'documentReferenceTypeCode.AQA': __('documentReferenceTypeCode.AQA'),
	'documentReferenceTypeCode.AQB': __('documentReferenceTypeCode.AQB'),
	'documentReferenceTypeCode.AQC': __('documentReferenceTypeCode.AQC'),
	'documentReferenceTypeCode.AQD': __('documentReferenceTypeCode.AQD'),
	'documentReferenceTypeCode.AQE': __('documentReferenceTypeCode.AQE'),
	'documentReferenceTypeCode.AQF': __('documentReferenceTypeCode.AQF'),
	'documentReferenceTypeCode.AQG': __('documentReferenceTypeCode.AQG'),
	'documentReferenceTypeCode.AQH': __('documentReferenceTypeCode.AQH'),
	'documentReferenceTypeCode.AQI': __('documentReferenceTypeCode.AQI'),
	'documentReferenceTypeCode.AQJ': __('documentReferenceTypeCode.AQJ'),
	'documentReferenceTypeCode.AQK': __('documentReferenceTypeCode.AQK'),
	'documentReferenceTypeCode.AQL': __('documentReferenceTypeCode.AQL'),
	'documentReferenceTypeCode.AQM': __('documentReferenceTypeCode.AQM'),
	'documentReferenceTypeCode.AQN': __('documentReferenceTypeCode.AQN'),
	'documentReferenceTypeCode.AQO': __('documentReferenceTypeCode.AQO'),
	'documentReferenceTypeCode.AQP': __('documentReferenceTypeCode.AQP'),
	'documentReferenceTypeCode.AQQ': __('documentReferenceTypeCode.AQQ'),
	'documentReferenceTypeCode.AQR': __('documentReferenceTypeCode.AQR'),
	'documentReferenceTypeCode.AQS': __('documentReferenceTypeCode.AQS'),
	'documentReferenceTypeCode.AQT': __('documentReferenceTypeCode.AQT'),
	'documentReferenceTypeCode.AQU': __('documentReferenceTypeCode.AQU'),
	'documentReferenceTypeCode.AQV': __('documentReferenceTypeCode.AQV'),
	'documentReferenceTypeCode.AQW': __('documentReferenceTypeCode.AQW'),
	'documentReferenceTypeCode.AQX': __('documentReferenceTypeCode.AQX'),
	'documentReferenceTypeCode.AQY': __('documentReferenceTypeCode.AQY'),
	'documentReferenceTypeCode.AQZ': __('documentReferenceTypeCode.AQZ'),
	'documentReferenceTypeCode.ARA': __('documentReferenceTypeCode.ARA'),
	'documentReferenceTypeCode.ARB': __('documentReferenceTypeCode.ARB'),
	'documentReferenceTypeCode.ARC': __('documentReferenceTypeCode.ARC'),
	'documentReferenceTypeCode.ARD': __('documentReferenceTypeCode.ARD'),
	'documentReferenceTypeCode.ARE': __('documentReferenceTypeCode.ARE'),
	'documentReferenceTypeCode.ARF': __('documentReferenceTypeCode.ARF'),
	'documentReferenceTypeCode.ARG': __('documentReferenceTypeCode.ARG'),
	'documentReferenceTypeCode.ARH': __('documentReferenceTypeCode.ARH'),
	'documentReferenceTypeCode.ARI': __('documentReferenceTypeCode.ARI'),
	'documentReferenceTypeCode.ARJ': __('documentReferenceTypeCode.ARJ'),
	'documentReferenceTypeCode.ARK': __('documentReferenceTypeCode.ARK'),
	'documentReferenceTypeCode.ARL': __('documentReferenceTypeCode.ARL'),
	'documentReferenceTypeCode.ARM': __('documentReferenceTypeCode.ARM'),
	'documentReferenceTypeCode.ARN': __('documentReferenceTypeCode.ARN'),
	'documentReferenceTypeCode.ARO': __('documentReferenceTypeCode.ARO'),
	'documentReferenceTypeCode.ARP': __('documentReferenceTypeCode.ARP'),
	'documentReferenceTypeCode.ARQ': __('documentReferenceTypeCode.ARQ'),
	'documentReferenceTypeCode.ARR': __('documentReferenceTypeCode.ARR'),
	'documentReferenceTypeCode.ARS': __('documentReferenceTypeCode.ARS'),
	'documentReferenceTypeCode.ART': __('documentReferenceTypeCode.ART'),
	'documentReferenceTypeCode.ARU': __('documentReferenceTypeCode.ARU'),
	'documentReferenceTypeCode.ARV': __('documentReferenceTypeCode.ARV'),
	'documentReferenceTypeCode.ARW': __('documentReferenceTypeCode.ARW'),
	'documentReferenceTypeCode.ARX': __('documentReferenceTypeCode.ARX'),
	'documentReferenceTypeCode.ARY': __('documentReferenceTypeCode.ARY'),
	'documentReferenceTypeCode.ARZ': __('documentReferenceTypeCode.ARZ'),
	'documentReferenceTypeCode.ASA': __('documentReferenceTypeCode.ASA'),
	'documentReferenceTypeCode.ASB': __('documentReferenceTypeCode.ASB'),
	'documentReferenceTypeCode.ASC': __('documentReferenceTypeCode.ASC'),
	'documentReferenceTypeCode.ASD': __('documentReferenceTypeCode.ASD'),
	'documentReferenceTypeCode.ASE': __('documentReferenceTypeCode.ASE'),
	'documentReferenceTypeCode.ASF': __('documentReferenceTypeCode.ASF'),
	'documentReferenceTypeCode.ASG': __('documentReferenceTypeCode.ASG'),
	'documentReferenceTypeCode.ASH': __('documentReferenceTypeCode.ASH'),
	'documentReferenceTypeCode.ASI': __('documentReferenceTypeCode.ASI'),
	'documentReferenceTypeCode.ASJ': __('documentReferenceTypeCode.ASJ'),
	'documentReferenceTypeCode.ASK': __('documentReferenceTypeCode.ASK'),
	'documentReferenceTypeCode.ASL': __('documentReferenceTypeCode.ASL'),
	'documentReferenceTypeCode.ASM': __('documentReferenceTypeCode.ASM'),
	'documentReferenceTypeCode.ASN': __('documentReferenceTypeCode.ASN'),
	'documentReferenceTypeCode.ASO': __('documentReferenceTypeCode.ASO'),
	'documentReferenceTypeCode.ASP': __('documentReferenceTypeCode.ASP'),
	'documentReferenceTypeCode.ASQ': __('documentReferenceTypeCode.ASQ'),
	'documentReferenceTypeCode.ASR': __('documentReferenceTypeCode.ASR'),
	'documentReferenceTypeCode.ASS': __('documentReferenceTypeCode.ASS'),
	'documentReferenceTypeCode.AST': __('documentReferenceTypeCode.AST'),
	'documentReferenceTypeCode.ASU': __('documentReferenceTypeCode.ASU'),
	'documentReferenceTypeCode.ASV': __('documentReferenceTypeCode.ASV'),
	'documentReferenceTypeCode.ASW': __('documentReferenceTypeCode.ASW'),
	'documentReferenceTypeCode.ASX': __('documentReferenceTypeCode.ASX'),
	'documentReferenceTypeCode.ASY': __('documentReferenceTypeCode.ASY'),
	'documentReferenceTypeCode.ASZ': __('documentReferenceTypeCode.ASZ'),
	'documentReferenceTypeCode.ATA': __('documentReferenceTypeCode.ATA'),
	'documentReferenceTypeCode.ATB': __('documentReferenceTypeCode.ATB'),
	'documentReferenceTypeCode.ATC': __('documentReferenceTypeCode.ATC'),
	'documentReferenceTypeCode.ATD': __('documentReferenceTypeCode.ATD'),
	'documentReferenceTypeCode.ATE': __('documentReferenceTypeCode.ATE'),
	'documentReferenceTypeCode.ATF': __('documentReferenceTypeCode.ATF'),
	'documentReferenceTypeCode.ATG': __('documentReferenceTypeCode.ATG'),
	'documentReferenceTypeCode.ATH': __('documentReferenceTypeCode.ATH'),
	'documentReferenceTypeCode.ATI': __('documentReferenceTypeCode.ATI'),
	'documentReferenceTypeCode.ATJ': __('documentReferenceTypeCode.ATJ'),
	'documentReferenceTypeCode.ATK': __('documentReferenceTypeCode.ATK'),
	'documentReferenceTypeCode.ATL': __('documentReferenceTypeCode.ATL'),
	'documentReferenceTypeCode.ATM': __('documentReferenceTypeCode.ATM'),
	'documentReferenceTypeCode.ATN': __('documentReferenceTypeCode.ATN'),
	'documentReferenceTypeCode.ATO': __('documentReferenceTypeCode.ATO'),
	'documentReferenceTypeCode.ATP': __('documentReferenceTypeCode.ATP'),
	'documentReferenceTypeCode.ATQ': __('documentReferenceTypeCode.ATQ'),
	'documentReferenceTypeCode.ATR': __('documentReferenceTypeCode.ATR'),
	'documentReferenceTypeCode.ATS': __('documentReferenceTypeCode.ATS'),
	'documentReferenceTypeCode.ATT': __('documentReferenceTypeCode.ATT'),
	'documentReferenceTypeCode.ATU': __('documentReferenceTypeCode.ATU'),
	'documentReferenceTypeCode.ATV': __('documentReferenceTypeCode.ATV'),
	'documentReferenceTypeCode.ATW': __('documentReferenceTypeCode.ATW'),
	'documentReferenceTypeCode.ATX': __('documentReferenceTypeCode.ATX'),
	'documentReferenceTypeCode.ATY': __('documentReferenceTypeCode.ATY'),
	'documentReferenceTypeCode.ATZ': __('documentReferenceTypeCode.ATZ'),
	'documentReferenceTypeCode.AU': __('documentReferenceTypeCode.AU'),
	'documentReferenceTypeCode.AUA': __('documentReferenceTypeCode.AUA'),
	'documentReferenceTypeCode.AUB': __('documentReferenceTypeCode.AUB'),
	'documentReferenceTypeCode.AUC': __('documentReferenceTypeCode.AUC'),
	'documentReferenceTypeCode.AUD': __('documentReferenceTypeCode.AUD'),
	'documentReferenceTypeCode.AUE': __('documentReferenceTypeCode.AUE'),
	'documentReferenceTypeCode.AUF': __('documentReferenceTypeCode.AUF'),
	'documentReferenceTypeCode.AUG': __('documentReferenceTypeCode.AUG'),
	'documentReferenceTypeCode.AUH': __('documentReferenceTypeCode.AUH'),
	'documentReferenceTypeCode.AUI': __('documentReferenceTypeCode.AUI'),
	'documentReferenceTypeCode.AUJ': __('documentReferenceTypeCode.AUJ'),
	'documentReferenceTypeCode.AUK': __('documentReferenceTypeCode.AUK'),
	'documentReferenceTypeCode.AUL': __('documentReferenceTypeCode.AUL'),
	'documentReferenceTypeCode.AUM': __('documentReferenceTypeCode.AUM'),
	'documentReferenceTypeCode.AUN': __('documentReferenceTypeCode.AUN'),
	'documentReferenceTypeCode.AUO': __('documentReferenceTypeCode.AUO'),
	'documentReferenceTypeCode.AUP': __('documentReferenceTypeCode.AUP'),
	'documentReferenceTypeCode.AUQ': __('documentReferenceTypeCode.AUQ'),
	'documentReferenceTypeCode.AUR': __('documentReferenceTypeCode.AUR'),
	'documentReferenceTypeCode.AUS': __('documentReferenceTypeCode.AUS'),
	'documentReferenceTypeCode.AUT': __('documentReferenceTypeCode.AUT'),
	'documentReferenceTypeCode.AUU': __('documentReferenceTypeCode.AUU'),
	'documentReferenceTypeCode.AUV': __('documentReferenceTypeCode.AUV'),
	'documentReferenceTypeCode.AUW': __('documentReferenceTypeCode.AUW'),
	'documentReferenceTypeCode.AUX': __('documentReferenceTypeCode.AUX'),
	'documentReferenceTypeCode.AUY': __('documentReferenceTypeCode.AUY'),
	'documentReferenceTypeCode.AUZ': __('documentReferenceTypeCode.AUZ'),
	'documentReferenceTypeCode.AV': __('documentReferenceTypeCode.AV'),
	'documentReferenceTypeCode.AVA': __('documentReferenceTypeCode.AVA'),
	'documentReferenceTypeCode.AVB': __('documentReferenceTypeCode.AVB'),
	'documentReferenceTypeCode.AVC': __('documentReferenceTypeCode.AVC'),
	'documentReferenceTypeCode.AVD': __('documentReferenceTypeCode.AVD'),
	'documentReferenceTypeCode.AVE': __('documentReferenceTypeCode.AVE'),
	'documentReferenceTypeCode.AVF': __('documentReferenceTypeCode.AVF'),
	'documentReferenceTypeCode.AVG': __('documentReferenceTypeCode.AVG'),
	'documentReferenceTypeCode.AVH': __('documentReferenceTypeCode.AVH'),
	'documentReferenceTypeCode.AVI': __('documentReferenceTypeCode.AVI'),
	'documentReferenceTypeCode.AVJ': __('documentReferenceTypeCode.AVJ'),
	'documentReferenceTypeCode.AVK': __('documentReferenceTypeCode.AVK'),
	'documentReferenceTypeCode.AVL': __('documentReferenceTypeCode.AVL'),
	'documentReferenceTypeCode.AVM': __('documentReferenceTypeCode.AVM'),
	'documentReferenceTypeCode.AVN': __('documentReferenceTypeCode.AVN'),
	'documentReferenceTypeCode.AVO': __('documentReferenceTypeCode.AVO'),
	'documentReferenceTypeCode.AVP': __('documentReferenceTypeCode.AVP'),
	'documentReferenceTypeCode.AVQ': __('documentReferenceTypeCode.AVQ'),
	'documentReferenceTypeCode.AVR': __('documentReferenceTypeCode.AVR'),
	'documentReferenceTypeCode.AVS': __('documentReferenceTypeCode.AVS'),
	'documentReferenceTypeCode.AVT': __('documentReferenceTypeCode.AVT'),
	'documentReferenceTypeCode.AVU': __('documentReferenceTypeCode.AVU'),
	'documentReferenceTypeCode.AVV': __('documentReferenceTypeCode.AVV'),
	'documentReferenceTypeCode.AVW': __('documentReferenceTypeCode.AVW'),
	'documentReferenceTypeCode.AVX': __('documentReferenceTypeCode.AVX'),
	'documentReferenceTypeCode.AVY': __('documentReferenceTypeCode.AVY'),
	'documentReferenceTypeCode.AVZ': __('documentReferenceTypeCode.AVZ'),
	'documentReferenceTypeCode.AWA': __('documentReferenceTypeCode.AWA'),
	'documentReferenceTypeCode.AWB': __('documentReferenceTypeCode.AWB'),
	'documentReferenceTypeCode.AWC': __('documentReferenceTypeCode.AWC'),
	'documentReferenceTypeCode.AWD': __('documentReferenceTypeCode.AWD'),
	'documentReferenceTypeCode.AWE': __('documentReferenceTypeCode.AWE'),
	'documentReferenceTypeCode.AWF': __('documentReferenceTypeCode.AWF'),
	'documentReferenceTypeCode.AWG': __('documentReferenceTypeCode.AWG'),
	'documentReferenceTypeCode.AWH': __('documentReferenceTypeCode.AWH'),
	'documentReferenceTypeCode.AWI': __('documentReferenceTypeCode.AWI'),
	'documentReferenceTypeCode.AWJ': __('documentReferenceTypeCode.AWJ'),
	'documentReferenceTypeCode.AWK': __('documentReferenceTypeCode.AWK'),
	'documentReferenceTypeCode.AWL': __('documentReferenceTypeCode.AWL'),
	'documentReferenceTypeCode.AWM': __('documentReferenceTypeCode.AWM'),
	'documentReferenceTypeCode.AWN': __('documentReferenceTypeCode.AWN'),
	'documentReferenceTypeCode.AWO': __('documentReferenceTypeCode.AWO'),
	'documentReferenceTypeCode.AWP': __('documentReferenceTypeCode.AWP'),
	'documentReferenceTypeCode.AWQ': __('documentReferenceTypeCode.AWQ'),
	'documentReferenceTypeCode.AWR': __('documentReferenceTypeCode.AWR'),
	'documentReferenceTypeCode.AWS': __('documentReferenceTypeCode.AWS'),
	'documentReferenceTypeCode.AWT': __('documentReferenceTypeCode.AWT'),
	'documentReferenceTypeCode.AWU': __('documentReferenceTypeCode.AWU'),
	'documentReferenceTypeCode.AWV': __('documentReferenceTypeCode.AWV'),
	'documentReferenceTypeCode.AWW': __('documentReferenceTypeCode.AWW'),
	'documentReferenceTypeCode.AWX': __('documentReferenceTypeCode.AWX'),
	'documentReferenceTypeCode.AWY': __('documentReferenceTypeCode.AWY'),
	'documentReferenceTypeCode.AWZ': __('documentReferenceTypeCode.AWZ'),
	'documentReferenceTypeCode.AXA': __('documentReferenceTypeCode.AXA'),
	'documentReferenceTypeCode.AXB': __('documentReferenceTypeCode.AXB'),
	'documentReferenceTypeCode.AXC': __('documentReferenceTypeCode.AXC'),
	'documentReferenceTypeCode.AXD': __('documentReferenceTypeCode.AXD'),
	'documentReferenceTypeCode.AXE': __('documentReferenceTypeCode.AXE'),
	'documentReferenceTypeCode.AXF': __('documentReferenceTypeCode.AXF'),
	'documentReferenceTypeCode.AXG': __('documentReferenceTypeCode.AXG'),
	'documentReferenceTypeCode.AXH': __('documentReferenceTypeCode.AXH'),
	'documentReferenceTypeCode.AXI': __('documentReferenceTypeCode.AXI'),
	'documentReferenceTypeCode.AXJ': __('documentReferenceTypeCode.AXJ'),
	'documentReferenceTypeCode.AXK': __('documentReferenceTypeCode.AXK'),
	'documentReferenceTypeCode.AXL': __('documentReferenceTypeCode.AXL'),
	'documentReferenceTypeCode.AXM': __('documentReferenceTypeCode.AXM'),
	'documentReferenceTypeCode.AXN': __('documentReferenceTypeCode.AXN'),
	'documentReferenceTypeCode.AXO': __('documentReferenceTypeCode.AXO'),
	'documentReferenceTypeCode.AXP': __('documentReferenceTypeCode.AXP'),
	'documentReferenceTypeCode.AXQ': __('documentReferenceTypeCode.AXQ'),
	'documentReferenceTypeCode.AXR': __('documentReferenceTypeCode.AXR'),
	'documentReferenceTypeCode.BA': __('documentReferenceTypeCode.BA'),
	'documentReferenceTypeCode.BC': __('documentReferenceTypeCode.BC'),
	'documentReferenceTypeCode.BD': __('documentReferenceTypeCode.BD'),
	'documentReferenceTypeCode.BE': __('documentReferenceTypeCode.BE'),
	'documentReferenceTypeCode.BH': __('documentReferenceTypeCode.BH'),
	'documentReferenceTypeCode.BM': __('documentReferenceTypeCode.BM'),
	'documentReferenceTypeCode.BN': __('documentReferenceTypeCode.BN'),
	'documentReferenceTypeCode.BO': __('documentReferenceTypeCode.BO'),
	'documentReferenceTypeCode.BR': __('documentReferenceTypeCode.BR'),
	'documentReferenceTypeCode.BT': __('documentReferenceTypeCode.BT'),
	'documentReferenceTypeCode.BW': __('documentReferenceTypeCode.BW'),
	'documentReferenceTypeCode.CAS': __('documentReferenceTypeCode.CAS'),
	'documentReferenceTypeCode.CAT': __('documentReferenceTypeCode.CAT'),
	'documentReferenceTypeCode.CAU': __('documentReferenceTypeCode.CAU'),
	'documentReferenceTypeCode.CAV': __('documentReferenceTypeCode.CAV'),
	'documentReferenceTypeCode.CAW': __('documentReferenceTypeCode.CAW'),
	'documentReferenceTypeCode.CAX': __('documentReferenceTypeCode.CAX'),
	'documentReferenceTypeCode.CAY': __('documentReferenceTypeCode.CAY'),
	'documentReferenceTypeCode.CAZ': __('documentReferenceTypeCode.CAZ'),
	'documentReferenceTypeCode.CBA': __('documentReferenceTypeCode.CBA'),
	'documentReferenceTypeCode.CBB': __('documentReferenceTypeCode.CBB'),
	'documentReferenceTypeCode.CD': __('documentReferenceTypeCode.CD'),
	'documentReferenceTypeCode.CEC': __('documentReferenceTypeCode.CEC'),
	'documentReferenceTypeCode.CED': __('documentReferenceTypeCode.CED'),
	'documentReferenceTypeCode.CFE': __('documentReferenceTypeCode.CFE'),
	'documentReferenceTypeCode.CFF': __('documentReferenceTypeCode.CFF'),
	'documentReferenceTypeCode.CFO': __('documentReferenceTypeCode.CFO'),
	'documentReferenceTypeCode.CG': __('documentReferenceTypeCode.CG'),
	'documentReferenceTypeCode.CH': __('documentReferenceTypeCode.CH'),
	'documentReferenceTypeCode.CK': __('documentReferenceTypeCode.CK'),
	'documentReferenceTypeCode.CKN': __('documentReferenceTypeCode.CKN'),
	'documentReferenceTypeCode.CM': __('documentReferenceTypeCode.CM'),
	'documentReferenceTypeCode.CMR': __('documentReferenceTypeCode.CMR'),
	'documentReferenceTypeCode.CN': __('documentReferenceTypeCode.CN'),
	'documentReferenceTypeCode.CNO': __('documentReferenceTypeCode.CNO'),
	'documentReferenceTypeCode.COF': __('documentReferenceTypeCode.COF'),
	'documentReferenceTypeCode.CP': __('documentReferenceTypeCode.CP'),
	'documentReferenceTypeCode.CR': __('documentReferenceTypeCode.CR'),
	'documentReferenceTypeCode.CRN': __('documentReferenceTypeCode.CRN'),
	'documentReferenceTypeCode.CS': __('documentReferenceTypeCode.CS'),
	'documentReferenceTypeCode.CST': __('documentReferenceTypeCode.CST'),
	'documentReferenceTypeCode.CT': __('documentReferenceTypeCode.CT'),
	'documentReferenceTypeCode.CU': __('documentReferenceTypeCode.CU'),
	'documentReferenceTypeCode.CV': __('documentReferenceTypeCode.CV'),
	'documentReferenceTypeCode.CW': __('documentReferenceTypeCode.CW'),
	'documentReferenceTypeCode.CZ': __('documentReferenceTypeCode.CZ'),
	'documentReferenceTypeCode.DA': __('documentReferenceTypeCode.DA'),
	'documentReferenceTypeCode.DAN': __('documentReferenceTypeCode.DAN'),
	'documentReferenceTypeCode.DB': __('documentReferenceTypeCode.DB'),
	'documentReferenceTypeCode.DI': __('documentReferenceTypeCode.DI'),
	'documentReferenceTypeCode.DL': __('documentReferenceTypeCode.DL'),
	'documentReferenceTypeCode.DM': __('documentReferenceTypeCode.DM'),
	'documentReferenceTypeCode.DQ': __('documentReferenceTypeCode.DQ'),
	'documentReferenceTypeCode.DR': __('documentReferenceTypeCode.DR'),
	'documentReferenceTypeCode.EA': __('documentReferenceTypeCode.EA'),
	'documentReferenceTypeCode.EB': __('documentReferenceTypeCode.EB'),
	'documentReferenceTypeCode.ED': __('documentReferenceTypeCode.ED'),
	'documentReferenceTypeCode.EE': __('documentReferenceTypeCode.EE'),
	'documentReferenceTypeCode.EI': __('documentReferenceTypeCode.EI'),
	'documentReferenceTypeCode.EN': __('documentReferenceTypeCode.EN'),
	'documentReferenceTypeCode.EQ': __('documentReferenceTypeCode.EQ'),
	'documentReferenceTypeCode.ER': __('documentReferenceTypeCode.ER'),
	'documentReferenceTypeCode.ERN': __('documentReferenceTypeCode.ERN'),
	'documentReferenceTypeCode.ET': __('documentReferenceTypeCode.ET'),
	'documentReferenceTypeCode.EX': __('documentReferenceTypeCode.EX'),
	'documentReferenceTypeCode.FC': __('documentReferenceTypeCode.FC'),
	'documentReferenceTypeCode.FF': __('documentReferenceTypeCode.FF'),
	'documentReferenceTypeCode.FI': __('documentReferenceTypeCode.FI'),
	'documentReferenceTypeCode.FLW': __('documentReferenceTypeCode.FLW'),
	'documentReferenceTypeCode.FN': __('documentReferenceTypeCode.FN'),
	'documentReferenceTypeCode.FO': __('documentReferenceTypeCode.FO'),
	'documentReferenceTypeCode.FS': __('documentReferenceTypeCode.FS'),
	'documentReferenceTypeCode.FT': __('documentReferenceTypeCode.FT'),
	'documentReferenceTypeCode.FV': __('documentReferenceTypeCode.FV'),
	'documentReferenceTypeCode.FX': __('documentReferenceTypeCode.FX'),
	'documentReferenceTypeCode.GA': __('documentReferenceTypeCode.GA'),
	'documentReferenceTypeCode.GC': __('documentReferenceTypeCode.GC'),
	'documentReferenceTypeCode.GD': __('documentReferenceTypeCode.GD'),
	'documentReferenceTypeCode.GDN': __('documentReferenceTypeCode.GDN'),
	'documentReferenceTypeCode.GN': __('documentReferenceTypeCode.GN'),
	'documentReferenceTypeCode.HS': __('documentReferenceTypeCode.HS'),
	'documentReferenceTypeCode.HWB': __('documentReferenceTypeCode.HWB'),
	'documentReferenceTypeCode.IA': __('documentReferenceTypeCode.IA'),
	'documentReferenceTypeCode.IB': __('documentReferenceTypeCode.IB'),
	'documentReferenceTypeCode.ICA': __('documentReferenceTypeCode.ICA'),
	'documentReferenceTypeCode.ICE': __('documentReferenceTypeCode.ICE'),
	'documentReferenceTypeCode.ICO': __('documentReferenceTypeCode.ICO'),
	'documentReferenceTypeCode.II': __('documentReferenceTypeCode.II'),
	'documentReferenceTypeCode.IL': __('documentReferenceTypeCode.IL'),
	'documentReferenceTypeCode.INB': __('documentReferenceTypeCode.INB'),
	'documentReferenceTypeCode.INN': __('documentReferenceTypeCode.INN'),
	'documentReferenceTypeCode.INO': __('documentReferenceTypeCode.INO'),
	'documentReferenceTypeCode.IP': __('documentReferenceTypeCode.IP'),
	'documentReferenceTypeCode.IS': __('documentReferenceTypeCode.IS'),
	'documentReferenceTypeCode.IT': __('documentReferenceTypeCode.IT'),
	'documentReferenceTypeCode.IV': __('documentReferenceTypeCode.IV'),
	'documentReferenceTypeCode.JB': __('documentReferenceTypeCode.JB'),
	'documentReferenceTypeCode.JE': __('documentReferenceTypeCode.JE'),
	'documentReferenceTypeCode.LA': __('documentReferenceTypeCode.LA'),
	'documentReferenceTypeCode.LAN': __('documentReferenceTypeCode.LAN'),
	'documentReferenceTypeCode.LAR': __('documentReferenceTypeCode.LAR'),
	'documentReferenceTypeCode.LB': __('documentReferenceTypeCode.LB'),
	'documentReferenceTypeCode.LC': __('documentReferenceTypeCode.LC'),
	'documentReferenceTypeCode.LI': __('documentReferenceTypeCode.LI'),
	'documentReferenceTypeCode.LO': __('documentReferenceTypeCode.LO'),
	'documentReferenceTypeCode.LRC': __('documentReferenceTypeCode.LRC'),
	'documentReferenceTypeCode.LS': __('documentReferenceTypeCode.LS'),
	'documentReferenceTypeCode.MA': __('documentReferenceTypeCode.MA'),
	'documentReferenceTypeCode.MB': __('documentReferenceTypeCode.MB'),
	'documentReferenceTypeCode.MF': __('documentReferenceTypeCode.MF'),
	'documentReferenceTypeCode.MG': __('documentReferenceTypeCode.MG'),
	'documentReferenceTypeCode.MH': __('documentReferenceTypeCode.MH'),
	'documentReferenceTypeCode.MR': __('documentReferenceTypeCode.MR'),
	'documentReferenceTypeCode.MRN': __('documentReferenceTypeCode.MRN'),
	'documentReferenceTypeCode.MS': __('documentReferenceTypeCode.MS'),
	'documentReferenceTypeCode.MSS': __('documentReferenceTypeCode.MSS'),
	'documentReferenceTypeCode.MWB': __('documentReferenceTypeCode.MWB'),
	'documentReferenceTypeCode.NA': __('documentReferenceTypeCode.NA'),
	'documentReferenceTypeCode.NF': __('documentReferenceTypeCode.NF'),
	'documentReferenceTypeCode.OH': __('documentReferenceTypeCode.OH'),
	'documentReferenceTypeCode.OI': __('documentReferenceTypeCode.OI'),
	'documentReferenceTypeCode.ON': __('documentReferenceTypeCode.ON'),
	'documentReferenceTypeCode.OP': __('documentReferenceTypeCode.OP'),
	'documentReferenceTypeCode.OR': __('documentReferenceTypeCode.OR'),
	'documentReferenceTypeCode.PB': __('documentReferenceTypeCode.PB'),
	'documentReferenceTypeCode.PC': __('documentReferenceTypeCode.PC'),
	'documentReferenceTypeCode.PD': __('documentReferenceTypeCode.PD'),
	'documentReferenceTypeCode.PE': __('documentReferenceTypeCode.PE'),
	'documentReferenceTypeCode.PF': __('documentReferenceTypeCode.PF'),
	'documentReferenceTypeCode.PI': __('documentReferenceTypeCode.PI'),
	'documentReferenceTypeCode.PK': __('documentReferenceTypeCode.PK'),
	'documentReferenceTypeCode.PL': __('documentReferenceTypeCode.PL'),
	'documentReferenceTypeCode.POR': __('documentReferenceTypeCode.POR'),
	'documentReferenceTypeCode.PP': __('documentReferenceTypeCode.PP'),
	'documentReferenceTypeCode.PQ': __('documentReferenceTypeCode.PQ'),
	'documentReferenceTypeCode.PR': __('documentReferenceTypeCode.PR'),
	'documentReferenceTypeCode.PS': __('documentReferenceTypeCode.PS'),
	'documentReferenceTypeCode.PW': __('documentReferenceTypeCode.PW'),
	'documentReferenceTypeCode.PY': __('documentReferenceTypeCode.PY'),
	'documentReferenceTypeCode.RA': __('documentReferenceTypeCode.RA'),
	'documentReferenceTypeCode.RC': __('documentReferenceTypeCode.RC'),
	'documentReferenceTypeCode.RCN': __('documentReferenceTypeCode.RCN'),
	'documentReferenceTypeCode.RE': __('documentReferenceTypeCode.RE'),
	'documentReferenceTypeCode.REN': __('documentReferenceTypeCode.REN'),
	'documentReferenceTypeCode.RF': __('documentReferenceTypeCode.RF'),
	'documentReferenceTypeCode.RR': __('documentReferenceTypeCode.RR'),
	'documentReferenceTypeCode.RT': __('documentReferenceTypeCode.RT'),
	'documentReferenceTypeCode.SA': __('documentReferenceTypeCode.SA'),
	'documentReferenceTypeCode.SB': __('documentReferenceTypeCode.SB'),
	'documentReferenceTypeCode.SD': __('documentReferenceTypeCode.SD'),
	'documentReferenceTypeCode.SE': __('documentReferenceTypeCode.SE'),
	'documentReferenceTypeCode.SEA': __('documentReferenceTypeCode.SEA'),
	'documentReferenceTypeCode.SF': __('documentReferenceTypeCode.SF'),
	'documentReferenceTypeCode.SH': __('documentReferenceTypeCode.SH'),
	'documentReferenceTypeCode.SI': __('documentReferenceTypeCode.SI'),
	'documentReferenceTypeCode.SM': __('documentReferenceTypeCode.SM'),
	'documentReferenceTypeCode.SN': __('documentReferenceTypeCode.SN'),
	'documentReferenceTypeCode.SP': __('documentReferenceTypeCode.SP'),
	'documentReferenceTypeCode.SQ': __('documentReferenceTypeCode.SQ'),
	'documentReferenceTypeCode.SRN': __('documentReferenceTypeCode.SRN'),
	'documentReferenceTypeCode.SS': __('documentReferenceTypeCode.SS'),
	'documentReferenceTypeCode.STA': __('documentReferenceTypeCode.STA'),
	'documentReferenceTypeCode.SW': __('documentReferenceTypeCode.SW'),
	'documentReferenceTypeCode.SZ': __('documentReferenceTypeCode.SZ'),
	'documentReferenceTypeCode.TB': __('documentReferenceTypeCode.TB'),
	'documentReferenceTypeCode.TCR': __('documentReferenceTypeCode.TCR'),
	'documentReferenceTypeCode.TE': __('documentReferenceTypeCode.TE'),
	'documentReferenceTypeCode.TF': __('documentReferenceTypeCode.TF'),
	'documentReferenceTypeCode.TI': __('documentReferenceTypeCode.TI'),
	'documentReferenceTypeCode.TIN': __('documentReferenceTypeCode.TIN'),
	'documentReferenceTypeCode.TL': __('documentReferenceTypeCode.TL'),
	'documentReferenceTypeCode.TN': __('documentReferenceTypeCode.TN'),
	'documentReferenceTypeCode.TP': __('documentReferenceTypeCode.TP'),
	'documentReferenceTypeCode.UAR': __('documentReferenceTypeCode.UAR'),
	'documentReferenceTypeCode.UC': __('documentReferenceTypeCode.UC'),
	'documentReferenceTypeCode.UCN': __('documentReferenceTypeCode.UCN'),
	'documentReferenceTypeCode.UN': __('documentReferenceTypeCode.UN'),
	'documentReferenceTypeCode.UO': __('documentReferenceTypeCode.UO'),
	'documentReferenceTypeCode.URI': __('documentReferenceTypeCode.URI'),
	'documentReferenceTypeCode.VA': __('documentReferenceTypeCode.VA'),
	'documentReferenceTypeCode.VC': __('documentReferenceTypeCode.VC'),
	'documentReferenceTypeCode.VGR': __('documentReferenceTypeCode.VGR'),
	'documentReferenceTypeCode.VM': __('documentReferenceTypeCode.VM'),
	'documentReferenceTypeCode.VN': __('documentReferenceTypeCode.VN'),
	'documentReferenceTypeCode.VON': __('documentReferenceTypeCode.VON'),
	'documentReferenceTypeCode.VOR': __('documentReferenceTypeCode.VOR'),
	'documentReferenceTypeCode.VP': __('documentReferenceTypeCode.VP'),
	'documentReferenceTypeCode.VR': __('documentReferenceTypeCode.VR'),
	'documentReferenceTypeCode.VS': __('documentReferenceTypeCode.VS'),
	'documentReferenceTypeCode.VT': __('documentReferenceTypeCode.VT'),
	'documentReferenceTypeCode.VV': __('documentReferenceTypeCode.VV'),
	'documentReferenceTypeCode.WE': __('documentReferenceTypeCode.WE'),
	'documentReferenceTypeCode.WM': __('documentReferenceTypeCode.WM'),
	'documentReferenceTypeCode.WN': __('documentReferenceTypeCode.WN'),
	'documentReferenceTypeCode.WR': __('documentReferenceTypeCode.WR'),
	'documentReferenceTypeCode.WS': __('documentReferenceTypeCode.WS'),
	'documentReferenceTypeCode.WY': __('documentReferenceTypeCode.WY'),
	'documentReferenceTypeCode.XA': __('documentReferenceTypeCode.XA'),
	'documentReferenceTypeCode.XC': __('documentReferenceTypeCode.XC'),
	'documentReferenceTypeCode.XP': __('documentReferenceTypeCode.XP'),
	'documentReferenceTypeCode.ZZZ': __('documentReferenceTypeCode.ZZZ'),
};
