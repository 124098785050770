<div class="modal-layout flex f-column">
	<header class="modal-layout-header f-none">
		<span>{{viewData.titleText}}</span>
	</header>
	<main class="full-width full-height flex f-row f-auto">
		<section class="modal-layout-body f-auto container-fluid cds-grid"
			[ngClass]="viewData.menuVisible ? 'cds-grid-visible-sidenav' : ''">
			<router-outlet></router-outlet>
		</section>
	</main>
</div>