import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionaries } from 'ewc-app/app/document/common/models/document.model';
import { HttpService, Response } from 'framework/app/core/http/http.service';
import { Observable } from 'rxjs';
import { EwcConfigService } from '../../config/ewc-config.service';

@Injectable({
	providedIn: 'root',
})
export class AppConfigService {
	constructor(
		protected configService: EwcConfigService,
		private httpService: HttpService,
	) { }

	protected get appConfigUrl(): string {
		return `${this.configService.urls.APPCONFIG_SERVICE_URL}`;
	}

	public getPartnerSelectConfig(): Observable<Response<any>> {
		return this.httpService.get(`${this.appConfigUrl}/partnerSelectConfig`);
	}

	public getFeatures(): Observable<Response<any>> {
		return this.httpService.get(`${this.appConfigUrl}/features`);
	}

	public getDictionaries(dictNames: string[]): Observable<Response<Dictionaries>> {
		let params = new HttpParams();
		for (let index = 0; index < dictNames.length; index++) {
			const element = dictNames[index];
			params = params.append('dictionariesNames', element);
		}
		return this.httpService.get(`${this.appConfigUrl}/dictionaries`, { params });
	}
}
