import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComboModule, DropdownModule, InputModule, PopoverModule } from '@cds/components-angular';
import { AccountMenuComponent } from './components/account-menu/account-menu.component';
import { LanguageMenuComponent } from './components/account-menu/language-menu/language-menu.component';
import { FeatureTogglesModule } from '../feature-toggles/feature-toggles.module';
import { AccountService } from './account.service';

const ANGULAR_MODULES = [CommonModule, FormsModule, RouterModule, ReactiveFormsModule];

const CDS_MODULES = [InputModule, ComboModule, PopoverModule, DropdownModule];

const SHARED_MODULES = [FeatureTogglesModule];

@NgModule({
	imports: [ANGULAR_MODULES, CDS_MODULES, SHARED_MODULES],
	declarations: [AccountMenuComponent, LanguageMenuComponent],
	exports: [AccountMenuComponent, LanguageMenuComponent],
	providers: [AccountService],
})
export class AccountModule {
	public static forRoot(): ModuleWithProviders<AccountModule> {
		return {
			ngModule: AccountModule,
			providers: [AccountService],
		};
	}
	public static forChild(): ModuleWithProviders<AccountModule> {
		return {
			ngModule: AccountModule,
			providers: [AccountService],
		};
	}
}
