import { __ } from 'seed/lib/i18n';
import { BaseNotification } from './notification.model';

export class ErrorNotification extends BaseNotification {
	public get message(): string {
		return this._message || __('notification.error');
	}

	constructor(errorMessage?: string, title?: string) {
		super('danger', errorMessage, title || __('notification.error'));
	}
}
