import { __ } from 'seed/lib/i18n';

export const taxExemptionReason = {
	'taxExemptionReason.VATEXEU132': __('taxExemptionReason.VATEXEU132'),
	'taxExemptionReason.VATEXEU1321A': __('taxExemptionReason.VATEXEU1321A'),
	'taxExemptionReason.VATEXEU1321B': __('taxExemptionReason.VATEXEU1321B'),
	'taxExemptionReason.VATEXEU1321C': __('taxExemptionReason.VATEXEU1321C'),
	'taxExemptionReason.VATEXEU1321D': __('taxExemptionReason.VATEXEU1321D'),
	'taxExemptionReason.VATEXEU1321E': __('taxExemptionReason.VATEXEU1321E'),
	'taxExemptionReason.VATEXEU1321F': __('taxExemptionReason.VATEXEU1321F'),
	'taxExemptionReason.VATEXEU1321G': __('taxExemptionReason.VATEXEU1321G'),
	'taxExemptionReason.VATEXEU1321H': __('taxExemptionReason.VATEXEU1321H'),
	'taxExemptionReason.VATEXEU1321I': __('taxExemptionReason.VATEXEU1321I'),
	'taxExemptionReason.VATEXEU1321J': __('taxExemptionReason.VATEXEU1321J'),
	'taxExemptionReason.VATEXEU1321K': __('taxExemptionReason.VATEXEU1321K'),
	'taxExemptionReason.VATEXEU1321L': __('taxExemptionReason.VATEXEU1321L'),
	'taxExemptionReason.VATEXEU1321M': __('taxExemptionReason.VATEXEU1321M'),
	'taxExemptionReason.VATEXEU1321N': __('taxExemptionReason.VATEXEU1321N'),
	'taxExemptionReason.VATEXEU1321O': __('taxExemptionReason.VATEXEU1321O'),
	'taxExemptionReason.VATEXEU1321P': __('taxExemptionReason.VATEXEU1321P'),
	'taxExemptionReason.VATEXEU1321Q': __('taxExemptionReason.VATEXEU1321Q'),
	'taxExemptionReason.VATEXEU143': __('taxExemptionReason.VATEXEU143'),
	'taxExemptionReason.VATEXEU1431A': __('taxExemptionReason.VATEXEU1431A'),
	'taxExemptionReason.VATEXEU1431B': __('taxExemptionReason.VATEXEU1431B'),
	'taxExemptionReason.VATEXEU1431C': __('taxExemptionReason.VATEXEU1431C'),
	'taxExemptionReason.VATEXEU1431D': __('taxExemptionReason.VATEXEU1431D'),
	'taxExemptionReason.VATEXEU1431E': __('taxExemptionReason.VATEXEU1431E'),
	'taxExemptionReason.VATEXEU1431F': __('taxExemptionReason.VATEXEU1431F'),
	'taxExemptionReason.VATEXEU1431FA': __('taxExemptionReason.VATEXEU1431FA'),
	'taxExemptionReason.VATEXEU1431G': __('taxExemptionReason.VATEXEU1431G'),
	'taxExemptionReason.VATEXEU1431H': __('taxExemptionReason.VATEXEU1431H'),
	'taxExemptionReason.VATEXEU1431I': __('taxExemptionReason.VATEXEU1431I'),
	'taxExemptionReason.VATEXEU1431J': __('taxExemptionReason.VATEXEU1431J'),
	'taxExemptionReason.VATEXEU1431K': __('taxExemptionReason.VATEXEU1431K'),
	'taxExemptionReason.VATEXEU1431L': __('taxExemptionReason.VATEXEU1431L'),
	'taxExemptionReason.VATEXEU148': __('taxExemptionReason.VATEXEU148'),
	'taxExemptionReason.VATEXEU148A': __('taxExemptionReason.VATEXEU148A'),
	'taxExemptionReason.VATEXEU148B': __('taxExemptionReason.VATEXEU148B'),
	'taxExemptionReason.VATEXEU148C': __('taxExemptionReason.VATEXEU148C'),
	'taxExemptionReason.VATEXEU148D': __('taxExemptionReason.VATEXEU148D'),
	'taxExemptionReason.VATEXEU148E': __('taxExemptionReason.VATEXEU148E'),
	'taxExemptionReason.VATEXEU148F': __('taxExemptionReason.VATEXEU148F'),
	'taxExemptionReason.VATEXEU148G': __('taxExemptionReason.VATEXEU148G'),
	'taxExemptionReason.VATEXEU151': __('taxExemptionReason.VATEXEU151'),
	'taxExemptionReason.VATEXEU1511A': __('taxExemptionReason.VATEXEU1511A'),
	'taxExemptionReason.VATEXEU1511AA': __('taxExemptionReason.VATEXEU1511AA'),
	'taxExemptionReason.VATEXEU1511B': __('taxExemptionReason.VATEXEU1511B'),
	'taxExemptionReason.VATEXEU1511C': __('taxExemptionReason.VATEXEU1511C'),
	'taxExemptionReason.VATEXEU1511D': __('taxExemptionReason.VATEXEU1511D'),
	'taxExemptionReason.VATEXEU1511E': __('taxExemptionReason.VATEXEU1511E'),
	'taxExemptionReason.VATEXEU309': __('taxExemptionReason.VATEXEU309'),
	'taxExemptionReason.VATEXEUAE': __('taxExemptionReason.VATEXEUAE'),
	'taxExemptionReason.VATEXEUD': __('taxExemptionReason.VATEXEUD'),
	'taxExemptionReason.VATEXEUF': __('taxExemptionReason.VATEXEUF'),
	'taxExemptionReason.VATEXEUG': __('taxExemptionReason.VATEXEUG'),
	'taxExemptionReason.VATEXEUI': __('taxExemptionReason.VATEXEUI'),
	'taxExemptionReason.VATEXEUIC': __('taxExemptionReason.VATEXEUIC'),
	'taxExemptionReason.VATEXEUJ': __('taxExemptionReason.VATEXEUJ'),
	'taxExemptionReason.VATEXEUO': __('taxExemptionReason.VATEXEUO'),
	'taxExemptionReason.OTHER': __('taxExemptionReason.OTHER'),
};
