import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogModule } from '@cds/components-angular';
import { ConfirmChangesDialogComponent } from './confirm-changes/confirm-changes-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		DialogModule,
	],
	declarations: [
		ConfirmChangesDialogComponent,
	],
	exports: [ConfirmChangesDialogComponent],
})
export class CaRoutingModule {}
