import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ErrorHttpInterceptorService } from './error-http-interceptor.service';
import { HttpService } from './http.service';

@NgModule({
	imports: [
		HttpClientModule,
	],
})
export class HttpModule {

	static forRoot(): ModuleWithProviders<HttpModule> {
		return {
			ngModule: HttpModule,
			providers: [
				HttpService,
				ErrorHttpInterceptorService,
			],
		};
	}
}
