import { __ } from 'seed/lib/i18n';

export const chargeCode = {
	'chargeCode.AA': __('chargeCode.AA'),
	'chargeCode.AAA': __('chargeCode.AAA'),
	'chargeCode.AAC': __('chargeCode.AAC'),
	'chargeCode.AAD': __('chargeCode.AAD'),
	'chargeCode.AAE': __('chargeCode.AAE'),
	'chargeCode.AAF': __('chargeCode.AAF'),
	'chargeCode.AAH': __('chargeCode.AAH'),
	'chargeCode.AAI': __('chargeCode.AAI'),
	'chargeCode.AAS': __('chargeCode.AAS'),
	'chargeCode.AAT': __('chargeCode.AAT'),
	'chargeCode.AAV': __('chargeCode.AAV'),
	'chargeCode.AAY': __('chargeCode.AAY'),
	'chargeCode.AAZ': __('chargeCode.AAZ'),
	'chargeCode.ABA': __('chargeCode.ABA'),
	'chargeCode.ABB': __('chargeCode.ABB'),
	'chargeCode.ABC': __('chargeCode.ABC'),
	'chargeCode.ABD': __('chargeCode.ABD'),
	'chargeCode.ABF': __('chargeCode.ABF'),
	'chargeCode.ABK': __('chargeCode.ABK'),
	'chargeCode.ABL': __('chargeCode.ABL'),
	'chargeCode.ABN': __('chargeCode.ABN'),
	'chargeCode.ABR': __('chargeCode.ABR'),
	'chargeCode.ABS': __('chargeCode.ABS'),
	'chargeCode.ABT': __('chargeCode.ABT'),
	'chargeCode.ABU': __('chargeCode.ABU'),
	'chargeCode.ACF': __('chargeCode.ACF'),
	'chargeCode.ACG': __('chargeCode.ACG'),
	'chargeCode.ACH': __('chargeCode.ACH'),
	'chargeCode.ACI': __('chargeCode.ACI'),
	'chargeCode.ACJ': __('chargeCode.ACJ'),
	'chargeCode.ACK': __('chargeCode.ACK'),
	'chargeCode.ACL': __('chargeCode.ACL'),
	'chargeCode.ACM': __('chargeCode.ACM'),
	'chargeCode.ACS': __('chargeCode.ACS'),
	'chargeCode.ADC': __('chargeCode.ADC'),
	'chargeCode.ADE': __('chargeCode.ADE'),
	'chargeCode.ADJ': __('chargeCode.ADJ'),
	'chargeCode.ADK': __('chargeCode.ADK'),
	'chargeCode.ADL': __('chargeCode.ADL'),
	'chargeCode.ADM': __('chargeCode.ADM'),
	'chargeCode.ADN': __('chargeCode.ADN'),
	'chargeCode.ADO': __('chargeCode.ADO'),
	'chargeCode.ADP': __('chargeCode.ADP'),
	'chargeCode.ADQ': __('chargeCode.ADQ'),
	'chargeCode.ADR': __('chargeCode.ADR'),
	'chargeCode.ADT': __('chargeCode.ADT'),
	'chargeCode.ADW': __('chargeCode.ADW'),
	'chargeCode.ADY': __('chargeCode.ADY'),
	'chargeCode.ADZ': __('chargeCode.ADZ'),
	'chargeCode.AEA': __('chargeCode.AEA'),
	'chargeCode.AEB': __('chargeCode.AEB'),
	'chargeCode.AEC': __('chargeCode.AEC'),
	'chargeCode.AED': __('chargeCode.AED'),
	'chargeCode.AEF': __('chargeCode.AEF'),
	'chargeCode.AEH': __('chargeCode.AEH'),
	'chargeCode.AEI': __('chargeCode.AEI'),
	'chargeCode.AEJ': __('chargeCode.AEJ'),
	'chargeCode.AEK': __('chargeCode.AEK'),
	'chargeCode.AEL': __('chargeCode.AEL'),
	'chargeCode.AEM': __('chargeCode.AEM'),
	'chargeCode.AEN': __('chargeCode.AEN'),
	'chargeCode.AEO': __('chargeCode.AEO'),
	'chargeCode.AEP': __('chargeCode.AEP'),
	'chargeCode.AES': __('chargeCode.AES'),
	'chargeCode.AET': __('chargeCode.AET'),
	'chargeCode.AEU': __('chargeCode.AEU'),
	'chargeCode.AEV': __('chargeCode.AEV'),
	'chargeCode.AEW': __('chargeCode.AEW'),
	'chargeCode.AEX': __('chargeCode.AEX'),
	'chargeCode.AEY': __('chargeCode.AEY'),
	'chargeCode.AEZ': __('chargeCode.AEZ'),
	'chargeCode.AJ': __('chargeCode.AJ'),
	'chargeCode.AU': __('chargeCode.AU'),
	'chargeCode.CA': __('chargeCode.CA'),
	'chargeCode.CAB': __('chargeCode.CAB'),
	'chargeCode.CAD': __('chargeCode.CAD'),
	'chargeCode.CAE': __('chargeCode.CAE'),
	'chargeCode.CAF': __('chargeCode.CAF'),
	'chargeCode.CAI': __('chargeCode.CAI'),
	'chargeCode.CAJ': __('chargeCode.CAJ'),
	'chargeCode.CAK': __('chargeCode.CAK'),
	'chargeCode.CAL': __('chargeCode.CAL'),
	'chargeCode.CAM': __('chargeCode.CAM'),
	'chargeCode.CAN': __('chargeCode.CAN'),
	'chargeCode.CAO': __('chargeCode.CAO'),
	'chargeCode.CAP': __('chargeCode.CAP'),
	'chargeCode.CAQ': __('chargeCode.CAQ'),
	'chargeCode.CAR': __('chargeCode.CAR'),
	'chargeCode.CAS': __('chargeCode.CAS'),
	'chargeCode.CAT': __('chargeCode.CAT'),
	'chargeCode.CAU': __('chargeCode.CAU'),
	'chargeCode.CAV': __('chargeCode.CAV'),
	'chargeCode.CAW': __('chargeCode.CAW'),
	'chargeCode.CAX': __('chargeCode.CAX'),
	'chargeCode.CAY': __('chargeCode.CAY'),
	'chargeCode.CAZ': __('chargeCode.CAZ'),
	'chargeCode.CD': __('chargeCode.CD'),
	'chargeCode.CG': __('chargeCode.CG'),
	'chargeCode.CS': __('chargeCode.CS'),
	'chargeCode.CT': __('chargeCode.CT'),
	'chargeCode.DAB': __('chargeCode.DAB'),
	'chargeCode.DAC': __('chargeCode.DAC'),
	'chargeCode.DAD': __('chargeCode.DAD'),
	'chargeCode.DAF': __('chargeCode.DAF'),
	'chargeCode.DAG': __('chargeCode.DAG'),
	'chargeCode.DAH': __('chargeCode.DAH'),
	'chargeCode.DAI': __('chargeCode.DAI'),
	'chargeCode.DAJ': __('chargeCode.DAJ'),
	'chargeCode.DAK': __('chargeCode.DAK'),
	'chargeCode.DAL': __('chargeCode.DAL'),
	'chargeCode.DAM': __('chargeCode.DAM'),
	'chargeCode.DAN': __('chargeCode.DAN'),
	'chargeCode.DAO': __('chargeCode.DAO'),
	'chargeCode.DAP': __('chargeCode.DAP'),
	'chargeCode.DAQ': __('chargeCode.DAQ'),
	'chargeCode.DL': __('chargeCode.DL'),
	'chargeCode.EG': __('chargeCode.EG'),
	'chargeCode.EP': __('chargeCode.EP'),
	'chargeCode.ER': __('chargeCode.ER'),
	'chargeCode.FAA': __('chargeCode.FAA'),
	'chargeCode.FAB': __('chargeCode.FAB'),
	'chargeCode.FAC': __('chargeCode.FAC'),
	'chargeCode.FC': __('chargeCode.FC'),
	'chargeCode.FH': __('chargeCode.FH'),
	'chargeCode.FI': __('chargeCode.FI'),
	'chargeCode.GAA': __('chargeCode.GAA'),
	'chargeCode.HAA': __('chargeCode.HAA'),
	'chargeCode.HD': __('chargeCode.HD'),
	'chargeCode.HH': __('chargeCode.HH'),
	'chargeCode.IAA': __('chargeCode.IAA'),
	'chargeCode.IAB': __('chargeCode.IAB'),
	'chargeCode.ID': __('chargeCode.ID'),
	'chargeCode.IF': __('chargeCode.IF'),
	'chargeCode.IR': __('chargeCode.IR'),
	'chargeCode.IS': __('chargeCode.IS'),
	'chargeCode.KO': __('chargeCode.KO'),
	'chargeCode.L1': __('chargeCode.L1'),
	'chargeCode.LA': __('chargeCode.LA'),
	'chargeCode.LAA': __('chargeCode.LAA'),
	'chargeCode.LAB': __('chargeCode.LAB'),
	'chargeCode.LF': __('chargeCode.LF'),
	'chargeCode.MAE': __('chargeCode.MAE'),
	'chargeCode.MI': __('chargeCode.MI'),
	'chargeCode.ML': __('chargeCode.ML'),
	'chargeCode.NAA': __('chargeCode.NAA'),
	'chargeCode.OA': __('chargeCode.OA'),
	'chargeCode.PA': __('chargeCode.PA'),
	'chargeCode.PAA': __('chargeCode.PAA'),
	'chargeCode.PC': __('chargeCode.PC'),
	'chargeCode.PL': __('chargeCode.PL'),
	'chargeCode.RAB': __('chargeCode.RAB'),
	'chargeCode.RAC': __('chargeCode.RAC'),
	'chargeCode.RAD': __('chargeCode.RAD'),
	'chargeCode.RAF': __('chargeCode.RAF'),
	'chargeCode.RE': __('chargeCode.RE'),
	'chargeCode.RF': __('chargeCode.RF'),
	'chargeCode.RH': __('chargeCode.RH'),
	'chargeCode.RV': __('chargeCode.RV'),
	'chargeCode.SA': __('chargeCode.SA'),
	'chargeCode.SAA': __('chargeCode.SAA'),
	'chargeCode.SAD': __('chargeCode.SAD'),
	'chargeCode.SAE': __('chargeCode.SAE'),
	'chargeCode.SAI': __('chargeCode.SAI'),
	'chargeCode.SG': __('chargeCode.SG'),
	'chargeCode.SH': __('chargeCode.SH'),
	'chargeCode.SM': __('chargeCode.SM'),
	'chargeCode.SU': __('chargeCode.SU'),
	'chargeCode.TAB': __('chargeCode.TAB'),
	'chargeCode.TAC': __('chargeCode.TAC'),
	'chargeCode.TT': __('chargeCode.TT'),
	'chargeCode.TV': __('chargeCode.TV'),
	'chargeCode.V1': __('chargeCode.V1'),
	'chargeCode.V2': __('chargeCode.V2'),
	'chargeCode.WH': __('chargeCode.WH'),
	'chargeCode.XAA': __('chargeCode.XAA'),
	'chargeCode.YY': __('chargeCode.YY'),
	'chargeCode.ZZZ': __('chargeCode.ZZZ'),
	'chargeCode.DO_NOT_SAVE_OTHER': __('chargeCode.DO_NOT_SAVE_OTHER'),
};
