<div class="container-fluid cds-grid">
	<ng-container [ngSwitch]="messageType">
		<ng-container *ngSwitchCase="MessageRouteType.Error_500">
			<h1>500 Internal Server Error</h1>
			<h2 class="mt-lg">
				Oh no! something went wrong on our end. Please try again later
			</h2>
		</ng-container>
		
		<ng-container *ngSwitchCase="MessageRouteType.Error_404">
			<h1>404 Page not found</h1>
			<h2 class="mt-lg">
				Uh-Oh! that page was not found, please go  <button cdsButton (click)="onGoToLogin()" class="cds-button-text">back to the log in screen</button>
			</h2>
		</ng-container>
		
		<ng-container *ngSwitchCase="MessageRouteType.Error_403">
			<h1>403 Forbidden</h1>
			<h2 class="mt-lg"> Sorry, you don’t have permissions required to view this page</h2>
		</ng-container>
	</ng-container>
</div>