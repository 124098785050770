import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'framework/app/core/navigation/navigation.service';
import { throttle } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuService } from '../../../menu/menu.service';
import { LayoutViewData } from '../../models/layout-view-data.model';

const COLLAPSED_HEADER_HEIGHT = 50;
const EXPANDED_HEADER_HEIGHT = 108;
const HEADER_SECTION_OFFSET = 0;
const ANIMATION_TIME = '0.15s ease-in-out';

/** element class which triggers header collapse on scroll event */
const HEADER_COLLAPSE_TRIGGER_CLASS = 'header-scroll-collapse';

/** amount of scrolled pixels (on specific elements) from which layout will be collapsed */
const HEADER_COLLAPSE_SCROLL_THRESHOLD = 50;

/** amount of scrolled pixels (on specific elements) under which layout will be expanded */
const HEADER_EXPAND_SCROLL_THRESHOLD = 5;

@UntilDestroy()
@Component({
	selector: 'ca-layout-header',
	templateUrl: 'layout-header.component.html',
	animations: [
		trigger('headerCollapse', [
			state('expanded', style({
				height: `${EXPANDED_HEADER_HEIGHT}px`,
			})),
			state('collapsed', style({
				height: `${COLLAPSED_HEADER_HEIGHT}px`,
			})),
			transition('expanded => collapsed', animate(ANIMATION_TIME)),
			transition('collapsed => expanded', animate(ANIMATION_TIME)),
		]),

		trigger('headerContentCollapse', [
			state('expanded', style({
				'background-color': 'rgba(255,255,255,1.0)',
				'color': 'rgb(0,0,0)',
				'padding': '14px 32px 14px 32px',
				'margin-top': `${HEADER_SECTION_OFFSET}px`,
			})),
			state('collapsed', style({
				'background-color': 'rgba(255,255,255,0.0)',
				'color': 'rgb(255,255,255)',
				'padding': '0 10px 0 10px',
				'margin-top': '0',
			})),
			transition('expanded => collapsed', [animate(ANIMATION_TIME)]),
			transition('collapsed => expanded', [animate(ANIMATION_TIME)]),
		]),
	],

})
export class LayoutHeaderComponent implements OnInit, OnDestroy {
	public isExpanded: boolean = true;

	protected _viewData: LayoutViewData;
	@Input('viewData')
	set viewData(value: LayoutViewData) {
		this._viewData = value;
		this.refresh();
	}
	get viewData(): LayoutViewData {
		return this._viewData;
	}

	@Input() showBackButton: boolean = false;

	constructor(
		protected menuService: MenuService,
		protected router: Router,
		protected navigationService: NavigationService,
		protected elementRef: ElementRef,
	) {
	}

	public ngOnInit() {
		this.refresh();
		this.initScrollHandler();
	}

	public ngOnDestroy() {

	}

	public onBack() {
		this.navigationService.backInOutlet('');
	}

	public refresh() {
		this.menuService.getCurrentMenuItem()
			.pipe(untilDestroyed(this))
			.subscribe(item => {
				if (!!item && !this.viewData?.titleText) {
					this.viewData.titleText = item.label;
				}
			});
	}

	protected initScrollHandler() {
		document.addEventListener('scroll', (e) => this.onScroll(e), true);
	}

	protected lastScrolledElement: Element;
	protected onScroll(event: Event) {
		const element = <Element> event.target;
		if (element.classList && element.classList.contains(HEADER_COLLAPSE_TRIGGER_CLASS)) {
			this.lastScrolledElement = element;
			this.onCollapseScrollTriggered();
		}
	}

	protected onCollapseScrollTriggered = throttle(() => {
		if (!this.lastScrolledElement) {
			return;
		}

		if (this.isExpanded && this.lastScrolledElement.scrollTop > HEADER_COLLAPSE_SCROLL_THRESHOLD) {
			if ((this.getHeightGainAfterCollapse() + HEADER_COLLAPSE_SCROLL_THRESHOLD) < this.getAvailableScrollSpace()) {
				this.isExpanded = false;
			}
		} else if (!this.isExpanded && this.lastScrolledElement.scrollTop < HEADER_EXPAND_SCROLL_THRESHOLD) {
			this.isExpanded = true;
		}
	}, 200);

	protected getHeightGainAfterCollapse(): number {
		return this.elementRef.nativeElement.children[1].offsetHeight - HEADER_SECTION_OFFSET;
	}

	protected getAvailableScrollSpace(): number {
		return this.lastScrolledElement.scrollHeight - this.lastScrolledElement.clientHeight;
	}
}
