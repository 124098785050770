import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { MenuService } from '../../../menu/menu.service';
import { NavigationItem } from '../../../navigation/navigation.model';
import { NavigationAndRoutingService } from '../../../navigation/services/navigation-and-routing.service';
import { ObjectHelper } from '../../../utils/helpers/object.helper';
import { LayoutEvent, LayoutService } from '../../services/layout.service';
import { BaseLayoutViewData } from './base-layout-view-data.model';

@UntilDestroy()
@Component({
	template: '',
})
export class BaseLayoutComponent implements OnInit, OnDestroy {
	protected currentMenuItem?: NavigationItem;

	public viewData: BaseLayoutViewData = {};

	get isModal(): boolean {
		return false;
	}

	constructor(protected router: Router,
		protected menuService: MenuService,
		protected navigationAndRoutingService: NavigationAndRoutingService,
		protected layoutService: LayoutService,
	) {
		this.setDefaultView();
		this.initEvents();
	}

	public ngOnInit() {
		this.onRouteChange();
	}

	public ngOnDestroy() {

	}

	public onRouteChange = () => {
		if (this.isModal === this.navigationAndRoutingService.isModalRouteEnabled) {
			this.menuService.getCurrentMenuItem(this.isModal)
				.pipe(untilDestroyed(this))
				.subscribe(item => {
					this.currentMenuItem = item;

					if (this.navigationAndRoutingService.currentVisibleRouteData) {
						this.setDefaultView();
						this.refreshVisibility();
						this.refreshHeader();
					}
				});
		}
	};

	public onLayoutEvent = (event: LayoutEvent) => {
		if (this.isModal === !!event.forModal) {
			this.setViewData(event.viewData);
		}
	};

	public toggleMenu = (event) => {
		this.viewData.menuCollapsed = event;
	};

	protected initEvents() {
		this.layoutService.layoutEvents
			.pipe(untilDestroyed(this))
			.subscribe(event => this.onLayoutEvent(event));

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				untilDestroyed(this))
			.subscribe(this.onRouteChange);
	}

	protected setDefaultView() {
		let menuCollapsed = false;

		if (!!this.viewData && ObjectHelper.hasValue(this.viewData.menuCollapsed)) {
			menuCollapsed = this.viewData.menuCollapsed;
		}

		this.viewData = {
			menuVisible: true,
			headerVisible: true,
			viewMode: 'default',
			menuCollapsed,
		};
	}

	protected setViewData(viewData: BaseLayoutViewData) {
		this.viewData = { ...this.viewData, ...viewData };
	}

	protected refreshVisibility() {
		const { showMenu, customHeader, isAccountMenuActive } = this.navigationAndRoutingService.currentVisibleRouteData;
		this.viewData.menuVisible = false;
		this.viewData.headerVisible = true;
		this.viewData.isAccountMenuActive = isAccountMenuActive;

		if (showMenu === true) {
			this.viewData.menuVisible = true;
		}

		if (customHeader === true) {
			this.viewData.headerVisible = false;
		}
	}

	protected refreshHeader() {
		if (!this.viewData.titleText) {
			this.setTitleFromRoute();

			if (!this.viewData.titleText) {
				this.setTitleFromNavigation();
			}
		}
	}

	protected setTitleFromRoute() {
		this.viewData.titleText = this.navigationAndRoutingService.currentVisibleRouteData.title;
	}

	protected setTitleFromNavigation() {
		if (!!this.currentMenuItem) {
			this.viewData.titleText = this.currentMenuItem.label;
		}
	}
}
