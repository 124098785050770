import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidenavModule } from '@cds/components-angular';
import { ModalRouterModule } from 'framework/app/ui/modal-router/modal-router.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NavigationModule } from '../navigation/navigation.module';
//import { TranslateModule } from '../translate/translate.module';
import { MenuService } from './menu.service';

const NG_MODULES = [CommonModule, RouterModule];

const CDS_MODULES = [SidenavModule];

const SHARED_MODULES = [NavigationModule];

const SEED_MODULES = [ModalRouterModule];

@NgModule({
	imports: [
		NG_MODULES,
		SEED_MODULES,
		CDS_MODULES,
		SHARED_MODULES,

		NgxPermissionsModule,
	],
	providers: [MenuService],
})
export class MenuModule {}
