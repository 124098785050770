<cds-list cdsListExpandable>
	<div class="cds-list-head">
		<cds-list-row head
					  class="cds-list-row">
			<div role="cell"
				 class="cds-list-cell">ID</div>
			<div role="cell"
				 class="cds-list-cell">Date</div>
			<div role="cell"
				 class="cds-list-cell">Body</div>
		</cds-list-row>
	</div>
	<div class="cds-list-body">
		<cds-list-row *ngFor="let item of page">
			<div role="cell"
				 class="cds-list-cell">{{item.id}}</div>
			<div role="cell"
				 class="cds-list-cell">{{'2017-02-01' | caDate:'tableCell'}}</div>
			<div role="cell"
				 class="cds-list-cell">{{item.body}}</div>
		</cds-list-row>
	</div>
</cds-list>
<cds-pager [totalElements]="totalElements"
		   [(currentPage)]="currentPage"
		   [(pageSize)]="pageSize"
		   (currentPageChange)="onPageChange()"></cds-pager>
