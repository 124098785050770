import { EventEmitter, Injectable } from '@angular/core';
import { BaseStore, Trackable } from 'framework/app/core/store/base-store';
import { DraftData } from 'ewc-app/app/document/common/services/document.service';
import {
	BaseListState,
	BaseListFilters,
} from 'shared/app/core/base-form/base-list/base-list-model';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { DocumentDetails } from 'shared/app/spec/ewc-defs';
import { PartnerListChangedEvent } from '../../partner/partners-list.component';

@Injectable({
	providedIn: 'root',
})
export class Store extends BaseStore {
	readonly DELETE_DOC_UNDO_TIMEOUT = 15000;

	public documentListState: BaseListState;
	@Trackable() public documentListFilters: Readonly<BaseListFilters>;
	public formState: FormState = {};

	public modalInProgress: EventEmitter<any> = new EventEmitter();
	public needRefreshDocumentList: EventEmitter<{
		attempts?: number;
		interval?: number;
	}> = new EventEmitter();

	public partnerListState: BaseListState;
	@Trackable() public partnerListFilters: Readonly<BaseListFilters>;

	//Documents
	public attachments: string[];
	public attachmentsFromDraft: string[];
	public documentDetails: DocumentDetails;
	public documentAdded: Subject<void> = new Subject<void>();
	public partnerListChanged = new Subject<PartnerListChangedEvent>();
	public document: any;
	public configDocument: any;
	public userConfiguration: any;
	public draftData: DraftData;
	public formInitializer: any;
	public documentMetadata: any;

	constructor() {
		super();

		this.attachments = [];
		this.attachmentsFromDraft = [];
		this.documentListState = new DocumentListState();
		this.documentListFilters = {
			sortBy: '-creationDate',
		};
		this.formState = {};

		this.partnerListState = new BaseListState();
		this.partnerListFilters = {
			sortBy: 'name',
		};
	}
}

export interface FormState {
	currentLine?: FormGroup;
	documentCurrency?: string;
	documentTaxCategories?: {
		[key: string]: number;
	};
	documentTaxCurrency?: string;
}

export class DocumentListState extends BaseListState {}
