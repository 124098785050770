import { HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HttpServiceHandler, HttpServiceInterceptor } from './http-request-handler';

export interface HttpProgressState {
	progress?: number;
}

export class ProgressHttpInterceptor implements HttpServiceInterceptor {

	constructor(private reporter: Observer<HttpProgressState>) {}

	intercept(req: HttpRequest<any>, options: any, next: HttpServiceHandler): Observable<HttpEvent<any>> {
		req = req.clone({
			reportProgress: true,
		});
		return next.handle(req, options).pipe(
			tap(event => {
				if (event.type === HttpEventType.UploadProgress) {
					this.reporter.next({
						progress: event.loaded != null ? 100 * event.loaded / event.total : undefined,
					});
				}
			}),
			finalize(() => {
				this.reporter.complete();
			})
		);
	}
}
