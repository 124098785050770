<ng-template #idleDialog
			 let-idleDialog let-time="time">
	<cds-dialog (dialogClose)="idleDialog.resolve()" class="cds-dialog--medium">
		<cds-slot-title>
			Session expiring
		</cds-slot-title>
		<cds-slot-content>
			<div class="cds-subtitle-1">
				Due to longer inactivity your session is about to expire. Any unfinished work will be lost. Do you want to continue working?
			</div>
		</cds-slot-content>
		<cds-slot-footer>
			<div class="cds-dialog-buttons">
				<button cdsButton
						class="cds-button-text"
						(click)="idleDialog.reject()">
					Logout
				</button>
				<button cdsButton
						class="cds-button-primary"
						(click)="idleDialog.resolve()">
					Continue working
				</button>
			</div>
		</cds-slot-footer>
	</cds-dialog>
</ng-template>