import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'framework/app/ui/modal/modal.module';
import { AppBarComponent } from './app-bar.component';
import { AppBarContainerComponent } from './app-bar-container.component';
import { AppBarService } from './app-bar.service';

@NgModule({
	imports: [
		CommonModule,
		ModalModule,
	],
	declarations: [AppBarComponent, AppBarContainerComponent],
	exports: [AppBarComponent, AppBarContainerComponent],
	providers: [AppBarService],
})
export class AppBarModule { }
