import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ModalRouterCloseComponent } from './modal-router-close.component';
import { ModalRouterLinkDirective } from './modal-router-link.directive';
import { ModalRouterOutletComponent } from './modal-router-outlet.component';

@NgModule({
	imports: [
		RouterModule,
	],
	declarations: [
		ModalRouterOutletComponent,
		ModalRouterLinkDirective,
		ModalRouterCloseComponent,
	],
	exports: [
		ModalRouterOutletComponent,
		ModalRouterLinkDirective,
		ModalRouterCloseComponent,
	],
})
export class ModalRouterModule {}
