
import { HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpServiceHandler, HttpServiceInterceptor } from 'framework/app/core/http/http-request-handler';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LayoutEventType, LayoutService } from '../services/layout.service';

@Injectable({
	providedIn: 'root',
})
export class LayoutHttpProgressInterceptor implements HttpServiceInterceptor {
	constructor(
		protected layoutService: LayoutService,
	) {

	}

	public intercept(req: HttpRequest<any>, options: any, next: HttpServiceHandler): Observable<HttpEvent<any>> {
		this.layoutService.layoutEvents.next({
			type: LayoutEventType.LoadStart,
		});

		return next.handle(req, options)
			.pipe(
				finalize(() => {
					this.layoutService.layoutEvents.next({
						type: LayoutEventType.LoadEnd,
					});
				})
			);
	}
}
