import { Component } from '@angular/core';
import { BaseLayoutComponent } from 'shared/app/core/layout/components/base-layout/base-layout.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'ca-login-layout',
	templateUrl: 'login-layout.component.html',
})
export class LoginLayoutComponent extends BaseLayoutComponent {
	get isModal(): boolean {
		return false;
	}
}
