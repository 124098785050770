import { SelectItem } from 'shared/app/spec/ewc-defs';
import { GenericSelectItem } from '../models/generic-select-item.model';
import { transform, isArray, isPlainObject, isEmpty, mergeWith, map, find } from 'lodash';
import { TranslateService } from '../../translate/translate.service';
import { Dictionaries } from 'ewc-app/app/document/common/models/document.model';

export const mergeWithArrayConcat = <TObject, TSource>(
	object: TObject,
	source: TSource
) =>
	mergeWith(object, source, (objValue, srcValue, key) => {
		if (isArray(objValue)) {
			if (key !== 'stepsConfigs') {
				return objValue.concat(srcValue);
			} else {
				return map(objValue, (obj) =>
					mergeWithArrayConcat(
						obj,
						find(srcValue, { name: obj.name })
					)
				);
			}
		}
	});

export const buildDictionaries = (
	translateService: TranslateService,
	dictionaries: Dictionaries) => {
	if (dictionaries) {
		for (const key in dictionaries) {
			if (dictionaries.hasOwnProperty(key)) {
				const dict = dictionaries[key];
				for (const key2 in dict) {
					if (dict.hasOwnProperty(key2)) {
						dict[key2] = translateService.getIfResource(dict[key2]);
					}
				}
			}
		}
	}
};

function cleanDeep<T>(object, { emptyArrays = true,
	emptyObjects = true,
	emptyStrings = true,
	nullValues = true,
	undefinedValues = true,
} = {}) {
	return transform(object, (newObj, value, key) => {
		if (isArray(value) || isPlainObject(value)) {
			value = cleanDeep(value, { emptyArrays, emptyObjects, emptyStrings, nullValues, undefinedValues });
		}

		if (emptyArrays && isArray(value) && !value.length) {
			return;
		}

		if (emptyObjects && isPlainObject(value) && isEmpty(value)) {
			return;
		}

		if (emptyStrings && value === '') {
			return;
		}

		if (nullValues && value === null) {
			return;
		}

		if (undefinedValues && value === undefined) {
			return;
		}

		newObj[key] = value;
	}) as T;
}

export class ObjectHelper {
	public static hasValue(value: any): boolean {
		return value !== null && value !== undefined && value !== '';
	}

	public static removeUndefinedProperties<T>(object: T): T {
		return cleanDeep(object, {
			emptyArrays: false,
			emptyObjects: false,
			emptyStrings: false,
			nullValues: false,
		});
	}

	public static removeNullProperties<T>(object: T): T {
		return cleanDeep(object, {
			emptyArrays: false,
			emptyObjects: false,
			emptyStrings: false,
		});
	}

	public static removeEmptyProperties<T>(object: T): T {
		return cleanDeep(object);
	}

	/** Get property from object by string, separate with '.' to access nested properties */
	public static getProperty<T>(obj: T, propertyPath: string) {
		return propertyPath.split('.').reduce((prev: any, curr: any) => {
			return prev ? prev[curr] : null;
		}, obj);
	}

	public static wrapWithSelectItem<T>(
		obj: T,
		valueSelector: (item: T) => any,
		labelSelector: (item: T) => string
	): GenericSelectItem<T> {
		return <GenericSelectItem<T>> {
			label: labelSelector(obj),
			value: valueSelector(obj),
			data: obj,
		};
	}

	public static wrapItemsWithSelectItem<T>(
		list: T[],
		valueSelector: (item: T) => any,
		labelSelector: (item: T) => string
	): GenericSelectItem<T>[] {
		if (!list) {
			return list;
		}

		return list.map(item =>
			ObjectHelper.wrapWithSelectItem(item, valueSelector, labelSelector)
		);
	}

	public static copyProperties(source: Object, target: Object): void {
		if (!source || !target) {
			return;
		}
		Object.assign(target, source);
	}

	public static isEmpty(value: any): boolean {
		if (!ObjectHelper.hasValue(value)) {
			return true;
		}

		if (typeof value === 'string' && value === '') {
			return true;
		}

		if (value instanceof Array && value.length === 0) {
			return true;
		}

		if (
			value instanceof Object &&
			Object.keys(value).length === 0 &&
			value.constructor === Object
		) {
			return true;
		}

		return false;
	}

	public static isEmptyDeep(value: any): boolean {
		if (ObjectHelper.isEmpty(value)) {
			return true;
		}

		if (value instanceof Array) {
			return value.reduce((y, z) => {
				return y && ObjectHelper.isEmptyDeep(z);
			}, true);
		}
		if (value instanceof Object) {
			return Object.keys(value).reduce((y, z) => {
				return y && ObjectHelper.isEmptyDeep(value[z]);
			}, true);
		}

		return false;
	}

	public static isNil(value: any): boolean {
		return value === null || value === undefined;
	}

	public static toNumber(value: string | number): number {
		try {
			const result = parseInt(String(value));
			return isNaN(result) ? null : result;
		} catch {
			return null;
		}
	}

	public static compareSelectItems(
		val1: SelectItem,
		val2: SelectItem
	): boolean {
		if (!val1 || !val2) {
			return val1 === val2;
		}

		return val1 === val2 || val1.value === val2.value;
	}

	public static isSelectItem(item: SelectItem): boolean {
		return !!item && !!item.value;
	}

	public static addValueToObject(
		object: object,
		value: any,
		path: string[]
	): void {
		let objectProperty = object;

		path.forEach((pathItem, index) => {
			const isLast = index === path.length - 1;
			if (isLast) {
				objectProperty[pathItem] = value;
				return;
			}

			if (!objectProperty[pathItem]) {
				objectProperty[pathItem] = {};
			}

			objectProperty = objectProperty[pathItem];
		});
	}

	public static compareObjects(object: any, object2: any): boolean {
		return JSON.stringify(object) === JSON.stringify(object2);
	}

	public static findInTree<T>(
		items: T[],
		predicate: (item: T) => boolean,
		childrenSelector: (item: T) => T[]
	): T {
		return items.reduce((found, item) => {
			if (found) {
				return found;
			}

			if (predicate(item)) {
				return item;
			}

			const children = childrenSelector(item);
			if (children) {
				return ObjectHelper.findInTree(
					children,
					predicate,
					childrenSelector
				);
			}
		}, null);
	}

	public static findInTreeChildrenFirst<T>(
		items: T[],
		predicate: (item: T) => boolean,
		childrenSelector: (item: T) => T[]
	): T {
		return items.reduce((found, item) => {
			if (found) {
				return found;
			}

			const children = childrenSelector(item);
			return (
				(children
					? ObjectHelper.findInTree(
						children,
						predicate,
						childrenSelector
					)
					: false) || (predicate(item) ? item : null)
			);
		}, null);
	}
}
