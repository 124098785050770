import { NgModule } from '@angular/core';
import { FeatureTogglesService } from './services/feature-toggles.service';
import { FeatureToggleComponent } from './components/feature-toggle.component';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [CommonModule],
	providers: [FeatureTogglesService],
	declarations: [FeatureToggleComponent, FeatureToggleDirective],
	exports: [FeatureToggleComponent, FeatureToggleDirective],
})
export class FeatureTogglesModule {}
