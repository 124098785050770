import { __ } from 'seed/lib/i18n';

export const subjectCode = {
		'subjectCode.AAA': __('subjectCode.AAA'),
		'subjectCode.AAB': __('subjectCode.AAB'),
		'subjectCode.AAC': __('subjectCode.AAC'),
		'subjectCode.AAD': __('subjectCode.AAD'),
		'subjectCode.AAE': __('subjectCode.AAE'),
		'subjectCode.AAF': __('subjectCode.AAF'),
		'subjectCode.AAG': __('subjectCode.AAG'),
		'subjectCode.AAI': __('subjectCode.AAI'),
		'subjectCode.AAJ': __('subjectCode.AAJ'),
		'subjectCode.AAK': __('subjectCode.AAK'),
		'subjectCode.AAL': __('subjectCode.AAL'),
		'subjectCode.AAM': __('subjectCode.AAM'),
		'subjectCode.AAN': __('subjectCode.AAN'),
		'subjectCode.AAO': __('subjectCode.AAO'),
		'subjectCode.AAP': __('subjectCode.AAP'),
		'subjectCode.AAQ': __('subjectCode.AAQ'),
		'subjectCode.AAR': __('subjectCode.AAR'),
		'subjectCode.AAS': __('subjectCode.AAS'),
		'subjectCode.AAT': __('subjectCode.AAT'),
		'subjectCode.AAU': __('subjectCode.AAU'),
		'subjectCode.AAV': __('subjectCode.AAV'),
		'subjectCode.AAW': __('subjectCode.AAW'),
		'subjectCode.AAX': __('subjectCode.AAX'),
		'subjectCode.AAY': __('subjectCode.AAY'),
		'subjectCode.AAZ': __('subjectCode.AAZ'),
		'subjectCode.ABA': __('subjectCode.ABA'),
		'subjectCode.ABB': __('subjectCode.ABB'),
		'subjectCode.ABC': __('subjectCode.ABC'),
		'subjectCode.ABD': __('subjectCode.ABD'),
		'subjectCode.ABE': __('subjectCode.ABE'),
		'subjectCode.ABF': __('subjectCode.ABF'),
		'subjectCode.ABG': __('subjectCode.ABG'),
		'subjectCode.ABH': __('subjectCode.ABH'),
		'subjectCode.ABI': __('subjectCode.ABI'),
		'subjectCode.ABJ': __('subjectCode.ABJ'),
		'subjectCode.ABK': __('subjectCode.ABK'),
		'subjectCode.ABL': __('subjectCode.ABL'),
		'subjectCode.ABM': __('subjectCode.ABM'),
		'subjectCode.ABN': __('subjectCode.ABN'),
		'subjectCode.ABO': __('subjectCode.ABO'),
		'subjectCode.ABP': __('subjectCode.ABP'),
		'subjectCode.ABQ': __('subjectCode.ABQ'),
		'subjectCode.ABR': __('subjectCode.ABR'),
		'subjectCode.ABS': __('subjectCode.ABS'),
		'subjectCode.ABT': __('subjectCode.ABT'),
		'subjectCode.ABU': __('subjectCode.ABU'),
		'subjectCode.ABV': __('subjectCode.ABV'),
		'subjectCode.ABW': __('subjectCode.ABW'),
		'subjectCode.ABX': __('subjectCode.ABX'),
		'subjectCode.ABZ': __('subjectCode.ABZ'),
		'subjectCode.ACA': __('subjectCode.ACA'),
		'subjectCode.ACB': __('subjectCode.ACB'),
		'subjectCode.ACC': __('subjectCode.ACC'),
		'subjectCode.ACD': __('subjectCode.ACD'),
		'subjectCode.ACE': __('subjectCode.ACE'),
		'subjectCode.ACF': __('subjectCode.ACF'),
		'subjectCode.ACG': __('subjectCode.ACG'),
		'subjectCode.ACH': __('subjectCode.ACH'),
		'subjectCode.ACI': __('subjectCode.ACI'),
		'subjectCode.ACJ': __('subjectCode.ACJ'),
		'subjectCode.ACK': __('subjectCode.ACK'),
		'subjectCode.ACL': __('subjectCode.ACL'),
		'subjectCode.ACM': __('subjectCode.ACM'),
		'subjectCode.ACN': __('subjectCode.ACN'),
		'subjectCode.ACO': __('subjectCode.ACO'),
		'subjectCode.ACP': __('subjectCode.ACP'),
		'subjectCode.ACQ': __('subjectCode.ACQ'),
		'subjectCode.ACR': __('subjectCode.ACR'),
		'subjectCode.ACS': __('subjectCode.ACS'),
		'subjectCode.ACT': __('subjectCode.ACT'),
		'subjectCode.ACU': __('subjectCode.ACU'),
		'subjectCode.ACV': __('subjectCode.ACV'),
		'subjectCode.ACW': __('subjectCode.ACW'),
		'subjectCode.ACX': __('subjectCode.ACX'),
		'subjectCode.ACY': __('subjectCode.ACY'),
		'subjectCode.ACZ': __('subjectCode.ACZ'),
		'subjectCode.ADA': __('subjectCode.ADA'),
		'subjectCode.ADB': __('subjectCode.ADB'),
		'subjectCode.ADC': __('subjectCode.ADC'),
		'subjectCode.ADD': __('subjectCode.ADD'),
		'subjectCode.ADE': __('subjectCode.ADE'),
		'subjectCode.ADF': __('subjectCode.ADF'),
		'subjectCode.ADG': __('subjectCode.ADG'),
		'subjectCode.ADH': __('subjectCode.ADH'),
		'subjectCode.ADI': __('subjectCode.ADI'),
		'subjectCode.ADJ': __('subjectCode.ADJ'),
		'subjectCode.ADK': __('subjectCode.ADK'),
		'subjectCode.ADL': __('subjectCode.ADL'),
		'subjectCode.ADM': __('subjectCode.ADM'),
		'subjectCode.ADN': __('subjectCode.ADN'),
		'subjectCode.ADO': __('subjectCode.ADO'),
		'subjectCode.ADP': __('subjectCode.ADP'),
		'subjectCode.ADQ': __('subjectCode.ADQ'),
		'subjectCode.ADR': __('subjectCode.ADR'),
		'subjectCode.ADS': __('subjectCode.ADS'),
		'subjectCode.ADT': __('subjectCode.ADT'),
		'subjectCode.ADU': __('subjectCode.ADU'),
		'subjectCode.ADV': __('subjectCode.ADV'),
		'subjectCode.ADW': __('subjectCode.ADW'),
		'subjectCode.ADX': __('subjectCode.ADX'),
		'subjectCode.ADY': __('subjectCode.ADY'),
		'subjectCode.ADZ': __('subjectCode.ADZ'),
		'subjectCode.AEA': __('subjectCode.AEA'),
		'subjectCode.AEB': __('subjectCode.AEB'),
		'subjectCode.AEC': __('subjectCode.AEC'),
		'subjectCode.AED': __('subjectCode.AED'),
		'subjectCode.AEE': __('subjectCode.AEE'),
		'subjectCode.AEF': __('subjectCode.AEF'),
		'subjectCode.AEG': __('subjectCode.AEG'),
		'subjectCode.AEH': __('subjectCode.AEH'),
		'subjectCode.AEI': __('subjectCode.AEI'),
		'subjectCode.AEJ': __('subjectCode.AEJ'),
		'subjectCode.AEK': __('subjectCode.AEK'),
		'subjectCode.AEL': __('subjectCode.AEL'),
		'subjectCode.AEM': __('subjectCode.AEM'),
		'subjectCode.AEN': __('subjectCode.AEN'),
		'subjectCode.AEO': __('subjectCode.AEO'),
		'subjectCode.AEP': __('subjectCode.AEP'),
		'subjectCode.AEQ': __('subjectCode.AEQ'),
		'subjectCode.AER': __('subjectCode.AER'),
		'subjectCode.AES': __('subjectCode.AES'),
		'subjectCode.AET': __('subjectCode.AET'),
		'subjectCode.AEU': __('subjectCode.AEU'),
		'subjectCode.AEV': __('subjectCode.AEV'),
		'subjectCode.AEW': __('subjectCode.AEW'),
		'subjectCode.AEX': __('subjectCode.AEX'),
		'subjectCode.AEY': __('subjectCode.AEY'),
		'subjectCode.AEZ': __('subjectCode.AEZ'),
		'subjectCode.AFA': __('subjectCode.AFA'),
		'subjectCode.AFB': __('subjectCode.AFB'),
		'subjectCode.AFC': __('subjectCode.AFC'),
		'subjectCode.AFD': __('subjectCode.AFD'),
		'subjectCode.AFE': __('subjectCode.AFE'),
		'subjectCode.AFF': __('subjectCode.AFF'),
		'subjectCode.AFG': __('subjectCode.AFG'),
		'subjectCode.AFH': __('subjectCode.AFH'),
		'subjectCode.AFI': __('subjectCode.AFI'),
		'subjectCode.AFJ': __('subjectCode.AFJ'),
		'subjectCode.AFK': __('subjectCode.AFK'),
		'subjectCode.AFL': __('subjectCode.AFL'),
		'subjectCode.AFM': __('subjectCode.AFM'),
		'subjectCode.AFN': __('subjectCode.AFN'),
		'subjectCode.AFO': __('subjectCode.AFO'),
		'subjectCode.AFP': __('subjectCode.AFP'),
		'subjectCode.AFQ': __('subjectCode.AFQ'),
		'subjectCode.AFR': __('subjectCode.AFR'),
		'subjectCode.AFS': __('subjectCode.AFS'),
		'subjectCode.AFT': __('subjectCode.AFT'),
		'subjectCode.AFU': __('subjectCode.AFU'),
		'subjectCode.AFV': __('subjectCode.AFV'),
		'subjectCode.AFW': __('subjectCode.AFW'),
		'subjectCode.AFX': __('subjectCode.AFX'),
		'subjectCode.AFY': __('subjectCode.AFY'),
		'subjectCode.AFZ': __('subjectCode.AFZ'),
		'subjectCode.AGA': __('subjectCode.AGA'),
		'subjectCode.AGB': __('subjectCode.AGB'),
		'subjectCode.AGC': __('subjectCode.AGC'),
		'subjectCode.AGD': __('subjectCode.AGD'),
		'subjectCode.AGE': __('subjectCode.AGE'),
		'subjectCode.AGF': __('subjectCode.AGF'),
		'subjectCode.AGG': __('subjectCode.AGG'),
		'subjectCode.AGH': __('subjectCode.AGH'),
		'subjectCode.AGI': __('subjectCode.AGI'),
		'subjectCode.AGJ': __('subjectCode.AGJ'),
		'subjectCode.AGK': __('subjectCode.AGK'),
		'subjectCode.AGL': __('subjectCode.AGL'),
		'subjectCode.AGM': __('subjectCode.AGM'),
		'subjectCode.AGN': __('subjectCode.AGN'),
		'subjectCode.AGO': __('subjectCode.AGO'),
		'subjectCode.AGP': __('subjectCode.AGP'),
		'subjectCode.AGQ': __('subjectCode.AGQ'),
		'subjectCode.AGR': __('subjectCode.AGR'),
		'subjectCode.AGS': __('subjectCode.AGS'),
		'subjectCode.AGT': __('subjectCode.AGT'),
		'subjectCode.AGU': __('subjectCode.AGU'),
		'subjectCode.AGV': __('subjectCode.AGV'),
		'subjectCode.AGW': __('subjectCode.AGW'),
		'subjectCode.AGX': __('subjectCode.AGX'),
		'subjectCode.AGY': __('subjectCode.AGY'),
		'subjectCode.AGZ': __('subjectCode.AGZ'),
		'subjectCode.AHA': __('subjectCode.AHA'),
		'subjectCode.AHB': __('subjectCode.AHB'),
		'subjectCode.AHC': __('subjectCode.AHC'),
		'subjectCode.AHD': __('subjectCode.AHD'),
		'subjectCode.AHE': __('subjectCode.AHE'),
		'subjectCode.AHF': __('subjectCode.AHF'),
		'subjectCode.AHG': __('subjectCode.AHG'),
		'subjectCode.AHH': __('subjectCode.AHH'),
		'subjectCode.AHI': __('subjectCode.AHI'),
		'subjectCode.AHJ': __('subjectCode.AHJ'),
		'subjectCode.AHK': __('subjectCode.AHK'),
		'subjectCode.AHL': __('subjectCode.AHL'),
		'subjectCode.AHM': __('subjectCode.AHM'),
		'subjectCode.AHN': __('subjectCode.AHN'),
		'subjectCode.AHO': __('subjectCode.AHO'),
		'subjectCode.AHP': __('subjectCode.AHP'),
		'subjectCode.AHQ': __('subjectCode.AHQ'),
		'subjectCode.AHR': __('subjectCode.AHR'),
		'subjectCode.AHS': __('subjectCode.AHS'),
		'subjectCode.AHT': __('subjectCode.AHT'),
		'subjectCode.AHU': __('subjectCode.AHU'),
		'subjectCode.AHV': __('subjectCode.AHV'),
		'subjectCode.AHW': __('subjectCode.AHW'),
		'subjectCode.AHX': __('subjectCode.AHX'),
		'subjectCode.AHY': __('subjectCode.AHY'),
		'subjectCode.AHZ': __('subjectCode.AHZ'),
		'subjectCode.AIA': __('subjectCode.AIA'),
		'subjectCode.AIB': __('subjectCode.AIB'),
		'subjectCode.AIC': __('subjectCode.AIC'),
		'subjectCode.AID': __('subjectCode.AID'),
		'subjectCode.AIE': __('subjectCode.AIE'),
		'subjectCode.AIF': __('subjectCode.AIF'),
		'subjectCode.AIG': __('subjectCode.AIG'),
		'subjectCode.AIH': __('subjectCode.AIH'),
		'subjectCode.AII': __('subjectCode.AII'),
		'subjectCode.AIJ': __('subjectCode.AIJ'),
		'subjectCode.AIK': __('subjectCode.AIK'),
		'subjectCode.AIL': __('subjectCode.AIL'),
		'subjectCode.AIM': __('subjectCode.AIM'),
		'subjectCode.AIN': __('subjectCode.AIN'),
		'subjectCode.AIO': __('subjectCode.AIO'),
		'subjectCode.AIP': __('subjectCode.AIP'),
		'subjectCode.AIQ': __('subjectCode.AIQ'),
		'subjectCode.AIR': __('subjectCode.AIR'),
		'subjectCode.AIS': __('subjectCode.AIS'),
		'subjectCode.AIT': __('subjectCode.AIT'),
		'subjectCode.AIU': __('subjectCode.AIU'),
		'subjectCode.AIV': __('subjectCode.AIV'),
		'subjectCode.AIW': __('subjectCode.AIW'),
		'subjectCode.AIX': __('subjectCode.AIX'),
		'subjectCode.AIY': __('subjectCode.AIY'),
		'subjectCode.AIZ': __('subjectCode.AIZ'),
		'subjectCode.AJA': __('subjectCode.AJA'),
		'subjectCode.AJB': __('subjectCode.AJB'),
		'subjectCode.ALC': __('subjectCode.ALC'),
		'subjectCode.ALD': __('subjectCode.ALD'),
		'subjectCode.ALE': __('subjectCode.ALE'),
		'subjectCode.ALF': __('subjectCode.ALF'),
		'subjectCode.ALG': __('subjectCode.ALG'),
		'subjectCode.ALH': __('subjectCode.ALH'),
		'subjectCode.ALI': __('subjectCode.ALI'),
		'subjectCode.ALJ': __('subjectCode.ALJ'),
		'subjectCode.ALK': __('subjectCode.ALK'),
		'subjectCode.ALL': __('subjectCode.ALL'),
		'subjectCode.ALM': __('subjectCode.ALM'),
		'subjectCode.ALN': __('subjectCode.ALN'),
		'subjectCode.ALO': __('subjectCode.ALO'),
		'subjectCode.ALP': __('subjectCode.ALP'),
		'subjectCode.ALQ': __('subjectCode.ALQ'),
		'subjectCode.ARR': __('subjectCode.ARR'),
		'subjectCode.ARS': __('subjectCode.ARS'),
		'subjectCode.AUT': __('subjectCode.AUT'),
		'subjectCode.AUU': __('subjectCode.AUU'),
		'subjectCode.AUV': __('subjectCode.AUV'),
		'subjectCode.AUW': __('subjectCode.AUW'),
		'subjectCode.AUX': __('subjectCode.AUX'),
		'subjectCode.AUY': __('subjectCode.AUY'),
		'subjectCode.AUZ': __('subjectCode.AUZ'),
		'subjectCode.AVA': __('subjectCode.AVA'),
		'subjectCode.AVB': __('subjectCode.AVB'),
		'subjectCode.AVC': __('subjectCode.AVC'),
		'subjectCode.AVD': __('subjectCode.AVD'),
		'subjectCode.AVE': __('subjectCode.AVE'),
		'subjectCode.AVF': __('subjectCode.AVF'),
		'subjectCode.BAG': __('subjectCode.BAG'),
		'subjectCode.BAH': __('subjectCode.BAH'),
		'subjectCode.BAI': __('subjectCode.BAI'),
		'subjectCode.BAJ': __('subjectCode.BAJ'),
		'subjectCode.BAK': __('subjectCode.BAK'),
		'subjectCode.BAL': __('subjectCode.BAL'),
		'subjectCode.BAM': __('subjectCode.BAM'),
		'subjectCode.BAN': __('subjectCode.BAN'),
		'subjectCode.BAO': __('subjectCode.BAO'),
		'subjectCode.BAP': __('subjectCode.BAP'),
		'subjectCode.BAQ': __('subjectCode.BAQ'),
		'subjectCode.BAR': __('subjectCode.BAR'),
		'subjectCode.BAS': __('subjectCode.BAS'),
		'subjectCode.BLC': __('subjectCode.BLC'),
		'subjectCode.BLD': __('subjectCode.BLD'),
		'subjectCode.BLE': __('subjectCode.BLE'),
		'subjectCode.BLF': __('subjectCode.BLF'),
		'subjectCode.BLG': __('subjectCode.BLG'),
		'subjectCode.BLH': __('subjectCode.BLH'),
		'subjectCode.BLI': __('subjectCode.BLI'),
		'subjectCode.BLJ': __('subjectCode.BLJ'),
		'subjectCode.BLK': __('subjectCode.BLK'),
		'subjectCode.BLL': __('subjectCode.BLL'),
		'subjectCode.BLM': __('subjectCode.BLM'),
		'subjectCode.BLN': __('subjectCode.BLN'),
		'subjectCode.BLO': __('subjectCode.BLO'),
		'subjectCode.BLP': __('subjectCode.BLP'),
		'subjectCode.BLQ': __('subjectCode.BLQ'),
		'subjectCode.BLR': __('subjectCode.BLR'),
		'subjectCode.BLS': __('subjectCode.BLS'),
		'subjectCode.BLT': __('subjectCode.BLT'),
		'subjectCode.BLU': __('subjectCode.BLU'),
		'subjectCode.BLV': __('subjectCode.BLV'),
		'subjectCode.BLW': __('subjectCode.BLW'),
		'subjectCode.BLX': __('subjectCode.BLX'),
		'subjectCode.BLY': __('subjectCode.BLY'),
		'subjectCode.BLZ': __('subjectCode.BLZ'),
		'subjectCode.BMA': __('subjectCode.BMA'),
		'subjectCode.BMB': __('subjectCode.BMB'),
		'subjectCode.BMC': __('subjectCode.BMC'),
		'subjectCode.BMD': __('subjectCode.BMD'),
		'subjectCode.BME': __('subjectCode.BME'),
		'subjectCode.CCI': __('subjectCode.CCI'),
		'subjectCode.CEX': __('subjectCode.CEX'),
		'subjectCode.CHG': __('subjectCode.CHG'),
		'subjectCode.CIP': __('subjectCode.CIP'),
		'subjectCode.CLP': __('subjectCode.CLP'),
		'subjectCode.CLR': __('subjectCode.CLR'),
		'subjectCode.COI': __('subjectCode.COI'),
		'subjectCode.CUR': __('subjectCode.CUR'),
		'subjectCode.CUS': __('subjectCode.CUS'),
		'subjectCode.DAR': __('subjectCode.DAR'),
		'subjectCode.DCL': __('subjectCode.DCL'),
		'subjectCode.DEL': __('subjectCode.DEL'),
		'subjectCode.DIN': __('subjectCode.DIN'),
		'subjectCode.DOC': __('subjectCode.DOC'),
		'subjectCode.DUT': __('subjectCode.DUT'),
		'subjectCode.EUR': __('subjectCode.EUR'),
		'subjectCode.FBC': __('subjectCode.FBC'),
		'subjectCode.GBL': __('subjectCode.GBL'),
		'subjectCode.GEN': __('subjectCode.GEN'),
		'subjectCode.GS7': __('subjectCode.GS7'),
		'subjectCode.HAN': __('subjectCode.HAN'),
		'subjectCode.HAZ': __('subjectCode.HAZ'),
		'subjectCode.ICN': __('subjectCode.ICN'),
		'subjectCode.IIN': __('subjectCode.IIN'),
		'subjectCode.IMI': __('subjectCode.IMI'),
		'subjectCode.IND': __('subjectCode.IND'),
		'subjectCode.INS': __('subjectCode.INS'),
		'subjectCode.INV': __('subjectCode.INV'),
		'subjectCode.IRP': __('subjectCode.IRP'),
		'subjectCode.ITR': __('subjectCode.ITR'),
		'subjectCode.ITS': __('subjectCode.ITS'),
		'subjectCode.LAN': __('subjectCode.LAN'),
		'subjectCode.LIN': __('subjectCode.LIN'),
		'subjectCode.LOI': __('subjectCode.LOI'),
		'subjectCode.MCO': __('subjectCode.MCO'),
		'subjectCode.MDH': __('subjectCode.MDH'),
		'subjectCode.MKS': __('subjectCode.MKS'),
		'subjectCode.ORI': __('subjectCode.ORI'),
		'subjectCode.OSI': __('subjectCode.OSI'),
		'subjectCode.PAC': __('subjectCode.PAC'),
		'subjectCode.PAI': __('subjectCode.PAI'),
		'subjectCode.PAY': __('subjectCode.PAY'),
		'subjectCode.PKG': __('subjectCode.PKG'),
		'subjectCode.PKT': __('subjectCode.PKT'),
		'subjectCode.PMD': __('subjectCode.PMD'),
		'subjectCode.PMT': __('subjectCode.PMT'),
		'subjectCode.PRD': __('subjectCode.PRD'),
		'subjectCode.PRF': __('subjectCode.PRF'),
		'subjectCode.PRI': __('subjectCode.PRI'),
		'subjectCode.PUR': __('subjectCode.PUR'),
		'subjectCode.QIN': __('subjectCode.QIN'),
		'subjectCode.QQD': __('subjectCode.QQD'),
		'subjectCode.QUT': __('subjectCode.QUT'),
		'subjectCode.RAH': __('subjectCode.RAH'),
		'subjectCode.REG': __('subjectCode.REG'),
		'subjectCode.RET': __('subjectCode.RET'),
		'subjectCode.REV': __('subjectCode.REV'),
		'subjectCode.RQR': __('subjectCode.RQR'),
		'subjectCode.SAF': __('subjectCode.SAF'),
		'subjectCode.SIC': __('subjectCode.SIC'),
		'subjectCode.SIN': __('subjectCode.SIN'),
		'subjectCode.SLR': __('subjectCode.SLR'),
		'subjectCode.SPA': __('subjectCode.SPA'),
		'subjectCode.SPG': __('subjectCode.SPG'),
		'subjectCode.SPH': __('subjectCode.SPH'),
		'subjectCode.SPP': __('subjectCode.SPP'),
		'subjectCode.SPT': __('subjectCode.SPT'),
		'subjectCode.SRN': __('subjectCode.SRN'),
		'subjectCode.SSR': __('subjectCode.SSR'),
		'subjectCode.SUR': __('subjectCode.SUR'),
		'subjectCode.TCA': __('subjectCode.TCA'),
		'subjectCode.TDT': __('subjectCode.TDT'),
		'subjectCode.TRA': __('subjectCode.TRA'),
		'subjectCode.TRR': __('subjectCode.TRR'),
		'subjectCode.TXD': __('subjectCode.TXD'),
		'subjectCode.WHI': __('subjectCode.WHI'),
		'subjectCode.ZZZ': __('subjectCode.ZZZ'),
};
