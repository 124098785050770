import { __ } from 'seed/lib/i18n';
import { sharedLocales } from 'shared/app/locales';

import { form } from './form';
import { country } from './country';
import { taxExemptionReason } from './taxExemptionReason';
import { invoiceType } from './invoiceType';
import { identifierSchemeCodeUntdid7143 } from './identifierSchemeCodeUntdid7143';
import { identifierSchemeCodeUnspsc190501 } from './identifierSchemeCodeUnspsc190501';
import { currency } from './currency';
import { documentReferenceTypeCode } from './documentReferenceTypeCode';
import { subjectCode } from './subjectCode';
import { identifierSchemeCodeIsoIec6523 } from './identifierSchemeCodeIsoIec6523';
import { chargeCode } from './chargeCode';
import { allowanceCode } from './allowanceCode';
import { unitCode } from './unitCode';

// TODO: Verify if all of these HAVE to be dynamic
export const locales = {
	...sharedLocales,

	'ewc.invoice.title': __('ewc.invoice.title'),
	'ewc.invoice.buttons.send': __('ewc.invoice.buttons.send'),
	'ewc.invoice.buttons.download': __('ewc.invoice.buttons.download'),
	'ewc.invoice.buttons.disabled.invalidDocument': __('ewc.invoice.buttons.disabled.invalidDocument'),
	'ewc.invoice.combos.actions': __('ewc.invoice.combos.actions'),
	'ewc.invoice.breadcumbs.invoices': __('ewc.invoice.breadcumbs.invoices'),
	'ewc.invoice.breadcumbs.invoiceDetails': __('ewc.invoice.breadcumbs.invoiceDetails'),
	'ewc.invoice.actions.edit': __('ewc.invoice.actions.edit'),
	'ewc.invoice.actions.delete': __('ewc.invoice.actions.delete'),
	'ewc.newInvoice.comboBox.country.placeholder': __('ewc.newInvoice.comboBox.country.placeholder'),
	'ewc.newInvoice.comboBox.country.dictionaries.DE': __('ewc.newInvoice.comboBox.country.dictionaries.DE'),
	'ewc.newInvoice.comboBox.payment.placeholder': __('ewc.newInvoice.comboBox.payment.placeholder'),
	'ewc.newInvoice.comboBox.sendingMethod.placeholder': __('ewc.newInvoice.comboBox.sendingMethod.placeholder'),
	'ewc.newInvoice.comboBox.sendingMethod.dictionaries.PEPPOL': __('ewc.newInvoice.comboBox.sendingMethod.dictionaries.PEPPOL'),
	'ewc.newInvoice.comboBox.sendingMethod.dictionaries.OZG': __('ewc.newInvoice.comboBox.sendingMethod.dictionaries.OZG'),
	'ewc.newInvoice.comboBox.sendingMethod.dictionaries.ZRE': __('ewc.newInvoice.comboBox.sendingMethod.dictionaries.ZRE'),
	'ewc.newInvoice.comboBox.sendingMethod.dictionaries.EMAIL': __('ewc.newInvoice.comboBox.sendingMethod.dictionaries.EMAIL'),
	'ewc.newInvoice.comboBox.payment.dictionaries.creditTransfer': __('ewc.newInvoice.comboBox.payment.dictionaries.creditTransfer'),
	'ewc.newInvoice.comboBox.payment.dictionaries.cardInformation': __('ewc.newInvoice.comboBox.payment.dictionaries.cardInformation'),
	'ewc.newInvoice.comboBox.payment.dictionaries.directDebit': __('ewc.newInvoice.comboBox.payment.dictionaries.directDebit'),
	'ewc.newInvoice.comboBox.payment.dictionaries.instrumentNotDefined': __('ewc.newInvoice.comboBox.payment.dictionaries.instrumentNotDefined'),
	'ewc.newInvoice.comboBox.payment.dictionaries.inCash': __('ewc.newInvoice.comboBox.payment.dictionaries.inCash'),
	'ewc.newInvoice.comboBox.payment.dictionaries.debitTransfer': __('ewc.newInvoice.comboBox.payment.dictionaries.debitTransfer'),
	'ewc.newInvoice.comboBox.payment.dictionaries.paymentToBankAccount': __('ewc.newInvoice.comboBox.payment.dictionaries.paymentToBankAccount'),
	'ewc.newInvoice.comboBox.payment.dictionaries.paymentByPostgiro': __('ewc.newInvoice.comboBox.payment.dictionaries.paymentByPostgiro'),
	'ewc.newInvoice.comboBox.payment.dictionaries.sepaCreditTransfer': __('ewc.newInvoice.comboBox.payment.dictionaries.sepaCreditTransfer'),
	'ewc.newInvoice.comboBox.payment.dictionaries.sepaDirectDebit': __('ewc.newInvoice.comboBox.payment.dictionaries.sepaDirectDebit'),
	'ewc.newInvoice.comboBox.payment.dictionaries.referenceGiro': __('ewc.newInvoice.comboBox.payment.dictionaries.referenceGiro'),
	'ewc.newInvoice.comboBox.payment.dictionaries.clearingBetweenPartners': __('ewc.newInvoice.comboBox.payment.dictionaries.clearingBetweenPartners'),
	'ewc.newInvoice.comboBox.idType.placeholder': __('ewc.newInvoice.comboBox.idType.placeholder'),
	'ewc.newInvoice.comboBox.idType.dictionaries.GLN': __('ewc.newInvoice.comboBox.idType.dictionaries.GLN'),
	'ewc.newInvoice.comboBox.idType.dictionaries.LeitwegID': __('ewc.newInvoice.comboBox.idType.dictionaries.LeitwegID'),
	'ewc.newInvoice.comboBox.idType.dictionaries.TaxID': __('ewc.newInvoice.comboBox.idType.dictionaries.TaxID'),
	'ewc.newInvoice.comboBox.idType.dictionaries.PLVAT': __('ewc.newInvoice.comboBox.idType.dictionaries.PLVAT'),
	'ewc.newInvoice.comboBox.idType.dictionaries.HRVAT': __('ewc.newInvoice.comboBox.idType.dictionaries.HRVAT'),
	'ewc.newInvoice.comboBox.idType.dictionaries.FRVAT': __('ewc.newInvoice.comboBox.idType.dictionaries.FRVAT'),
	'ewc.newInvoice.comboBox.idType.dictionaries.FRSIRET': __('ewc.newInvoice.comboBox.idType.dictionaries.FRSIRET'),
	'ewc.newInvoice.comboBox.idType.dictionaries.BEEN': __('ewc.newInvoice.comboBox.idType.dictionaries.BEEN'),
	'ewc.newInvoice.comboBox.idType.dictionaries.BEVAT': __('ewc.newInvoice.comboBox.idType.dictionaries.BEVAT'),
	'ewc.newInvoice.comboBox.idType.dictionaries.BECBE': __('ewc.newInvoice.comboBox.idType.dictionaries.BECBE'),
	'ewc.newInvoice.comboBox.idType.dictionaries.UBLBE': __('ewc.newInvoice.comboBox.idType.dictionaries.UBLBE'),
	'ewc.newInvoice.comboBox.idType.dictionaries.SEORGNR': __('ewc.newInvoice.comboBox.idType.dictionaries.SEORGNR'),
	'ewc.newInvoice.comboBox.idType.dictionaries.SEVAT': __('ewc.newInvoice.comboBox.idType.dictionaries.SEVAT'),
	'ewc.newInvoice.comboBox.idType.dictionaries.NOORG': __('ewc.newInvoice.comboBox.idType.dictionaries.NOORG'),
	'ewc.newInvoice.comboBox.idType.dictionaries.NOORGNR': __('ewc.newInvoice.comboBox.idType.dictionaries.NOORGNR'),
	'ewc.newInvoice.comboBox.idType.dictionaries.NOVAT': __('ewc.newInvoice.comboBox.idType.dictionaries.NOVAT'),
	'ewc.newInvoice.comboBox.idType.dictionaries.DKP': __('ewc.newInvoice.comboBox.idType.dictionaries.DKP'),
	'ewc.newInvoice.comboBox.idType.dictionaries.DKDIGST': __('ewc.newInvoice.comboBox.idType.dictionaries.DKDIGST'),
	'ewc.newInvoice.comboBox.idType.dictionaries.DKERST': __('ewc.newInvoice.comboBox.idType.dictionaries.DKERST'),
	'ewc.newInvoice.comboBox.idType.dictionaries.DKCPR': __('ewc.newInvoice.comboBox.idType.dictionaries.DKCPR'),
	'ewc.newInvoice.comboBox.idType.dictionaries.DKCVR': __('ewc.newInvoice.comboBox.idType.dictionaries.DKCVR'),
	'ewc.newInvoice.comboBox.idType.dictionaries.DKSE': __('ewc.newInvoice.comboBox.idType.dictionaries.DKSE'),
	'ewc.newInvoice.comboBox.idType.dictionaries.DKVANS': __('ewc.newInvoice.comboBox.idType.dictionaries.DKVANS'),
	'ewc.newInvoice.comboBox.idCode.placeholder': __('ewc.newInvoice.comboBox.idCode.placeholder'),
	'ewc.newInvoice.comboBox.idCode.placeholderNoSearch': __('ewc.newInvoice.comboBox.idCode.placeholderNoSearch'),
	'ewc.newInvoice.FR.PEPPOL.additionalMessage': __('ewc.newInvoice.FR.PEPPOL.additionalMessage'),
	'ewc.invoices.list.toolbar.selectedDocument': __('ewc.invoices.list.toolbar.selectedDocument', '{{1}}', '{{2}}'),
	'ewc.language.currentLanguage': __('ewc.language.currentLanguage', '{{1}}'),
	'ewc.invoices.actions.downloadXRechnung': __('ewc.invoices.actions.downloadXRechnung'),
	'ewc.invoices.actions.exportPDF': __('ewc.invoices.actions.exportPDF'),
	'ewc.invoices.actions.details.PDF': __('ewc.invoices.actions.details.PDF'),
	'ewc.invoices.actions.details.XRechnung': __('ewc.invoices.actions.details.XRechnung'),
	'ewc.invoices.filters.search': __('ewc.invoices.filters.search'),
	'ewc.invoices.filters.creationDate': __('ewc.invoices.filters.creationDate'),
	'ewc.invoices.filters.status': __('ewc.invoices.filters.status'),
	'ewc.invoices.columns.partnerName': __('ewc.invoices.columns.partnerName'),
	'ewc.invoices.columns.documentNumber': __('ewc.invoices.columns.documentNumber'),
	'ewc.invoices.columns.creationDate': __('ewc.invoices.columns.creationDate'),
	'ewc.invoices.columns.processingDate': __('ewc.invoices.columns.processingDate'),
	'ewc.invoices.columns.status': __('ewc.invoices.columns.status'),
	'ewc.invoices.list.toolbar.selectAll': __('ewc.invoices.list.toolbar.selectAll'),
	'ewc.invoices.list.toolbar.unSelectAll': __('ewc.invoices.list.toolbar.unSelectAll'),
	'ewc.invoices.list.toolbar.donwload': __('ewc.invoices.list.toolbar.donwload'),
	'ewc.invoices.list.toolbar.delete': __('ewc.invoices.list.toolbar.delete'),
	'ewc.partners.addPartner': __('ewc.partners.addPartner'),
	'ewc.partners.columns.partnerName': __('ewc.partners.columns.partnerName'),
	'ewc.partners.columns.sendingMethod': __('ewc.partners.columns.sendingMethod'),
	'ewc.partners.columns.identifier': __('ewc.partners.columns.identifier'),
	'ewc.partners.columns.address': __('ewc.partners.columns.address'),
	'ewc.partners.details.partnerDetails.header': __('ewc.partners.details.partnerDetails.header'),
	'ewc.partners.details.partnerDetails.sendingMethod': __('ewc.partners.details.partnerDetails.sendingMethod'),
	'ewc.partners.details.partnerDetails.name': __('ewc.partners.details.partnerDetails.name'),
	'ewc.partners.details.partnerDetails.taxId': __('ewc.partners.details.partnerDetails.taxId'),
	'ewc.partners.details.partnerDetails.legalNumber': __('ewc.partners.details.partnerDetails.legalNumber'),
	'ewc.partners.details.partnerDetails.streetAndNumber': __('ewc.partners.details.partnerDetails.streetAndNumber'),
	'ewc.partners.details.partnerDetails.city': __('ewc.partners.details.partnerDetails.city'),
	'ewc.partners.details.partnerDetails.postalCode': __('ewc.partners.details.partnerDetails.postalCode'),
	'ewc.partners.details.partnerDetails.country': __('ewc.partners.details.partnerDetails.country'),
	'ewc.partners.details.electronicAddress.header': __('ewc.partners.details.electronicAddress.header'),
	'ewc.partners.details.electronicAddress.electronicAddress': __('ewc.partners.details.electronicAddress.electronicAddress'),
	'ewc.partners.details.electronicAddress.type': __('ewc.partners.details.electronicAddress.type'),
	'ewc.partners.details.identifier.header': __('ewc.partners.details.identifier.header'),
	'ewc.partners.details.identifier.identifier': __('ewc.partners.details.identifier.identifier'),
	'ewc.partners.details.identifier.type': __('ewc.partners.details.identifier.type'),
	'ewc.partners.details.contact.header': __('ewc.partners.details.contact.header'),
	'ewc.partners.details.contact.accountingContact': __('ewc.partners.details.contact.accountingContact'),
	'ewc.partners.details.contact.phone': __('ewc.partners.details.contact.phone'),
	'ewc.partners.details.contact.email': __('ewc.partners.details.contact.email'),

	'notification.error': __('notification.error'),
	'notification.warning': __('notification.warning'),
	'notification.success': __('notification.success'),
	'notification.warning.getPartner': __('notification.warning.getPartner'),
	'notification.error.getPartner': __('notification.error.getPartner'),
	'notification.success.header.deleteDocuments': __('notification.success.header.deleteDocuments'),
	'notification.success.message.deleteDocuments': __('notification.success.message.deleteDocuments', '{{1}}', '{{2}}'),
	'notification.success.button.undo.deleteDocuments': __('notification.success.button.undo.deleteDocuments'),
	'notification.error.header.deleteDocuments': __('notification.error.header.deleteDocuments'),
	'notification.error.header.noCreditTransfer': __('notification.error.header.noCreditTransfer'),
	'notification.error.message.deleteDocuments': __('notification.error.message.deleteDocuments'),
	'notification.error.message.noCreditTransfer': __('notification.error.message.noCreditTransfer'),

	'general.delete': __('general.delete'),
	'general.edit': __('general.edit'),
	'general.save': __('general.save'),
	'general.cancel': __('general.cancel'),

	...form,
	...country,
	...taxExemptionReason,
	...invoiceType,
	...currency,

	// TAX POINT DATE CODE
	'taxPointDateCode.3': __('taxPointDateCode.3'),
	'taxPointDateCode.35': __('taxPointDateCode.35'),
	'taxPointDateCode.432': __('taxPointDateCode.432'),

	...documentReferenceTypeCode,

	// PAYMENT TERM TYPE
	'paymentTermType.SKONTO': __('paymentTermType.SKONTO'),
	'paymentTermType.VERZUG': __('paymentTermType.VERZUG'),

	...subjectCode,
	...identifierSchemeCodeIsoIec6523,

	// TAX CATEGORY CODE
	'taxCategoryCode.S': __('taxCategoryCode.S'),
	'taxCategoryCode.Z': __('taxCategoryCode.Z'),
	'taxCategoryCode.E': __('taxCategoryCode.E'),
	'taxCategoryCode.AE': __('taxCategoryCode.AE'),
	'taxCategoryCode.K': __('taxCategoryCode.K'),
	'taxCategoryCode.G': __('taxCategoryCode.G'),
	'taxCategoryCode.O': __('taxCategoryCode.O'),
	'taxCategoryCode.L': __('taxCategoryCode.L'),
	'taxCategoryCode.M': __('taxCategoryCode.M'),

	...allowanceCode,
	...chargeCode,
	...unitCode,
	...identifierSchemeCodeUntdid7143,
	...identifierSchemeCodeUnspsc190501,
};
