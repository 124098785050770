import { LocationStrategy } from '@angular/common';
import { Injectable, Optional } from '@angular/core';
import { NavigationExtras, PRIMARY_OUTLET, Router, UrlSegmentGroup } from '@angular/router';
import {
	HistoryState,
	OutletHistoryState,
	RouterFlowStrategyService
} from '../router-flow/router-flow-strategy.service';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {

	constructor(
			private router: Router,
			private locationStrategy: LocationStrategy,
			@Optional() private flowStrategy: RouterFlowStrategyService) {}

	go(url: string|any[], outletName: string = PRIMARY_OUTLET, relativeUrl?: string, extras?: NavigationExtras): Promise<boolean> {
		if (!relativeUrl) {
			relativeUrl = this.router.routerState.snapshot.url;
		}
		const urlTree = this.router.parseUrl(relativeUrl);
		const children = Object.assign({}, urlTree.root.children);
		if (Array.isArray(url)) {
			children[outletName] = this.router.createUrlTree(url).root.children[PRIMARY_OUTLET] || new UrlSegmentGroup([], {});
		} else {
			children[outletName] = this.router.parseUrl(url).root.children[PRIMARY_OUTLET] || new UrlSegmentGroup([], {});
		}
		urlTree.root = new UrlSegmentGroup(urlTree.root.segments, children);
		if (extras) {
			if (extras.queryParamsHandling === 'preserve') {
				// do nothing
			} else if (extras.queryParamsHandling === 'merge') {
				Object.assign(urlTree.queryParams, extras.queryParams || {});
			} else if (extras.queryParams) {
				urlTree.queryParams = extras.queryParams;
			}
		} else {
			urlTree.queryParams = {};
		}
		return this.router.navigateByUrl(urlTree, extras);
	}

	close(outletName: string, relativeUrl?: string): Promise<boolean> {
		if (!relativeUrl) {
			return this.router.navigate([ {outlets: {[outletName]: null}} ]);
		} else {
			return this.closeOutletWithUrl(outletName, relativeUrl);
		}
	}

	back(): void {
		this.locationStrategy.back();
	}

	backInOutlet(commands: string|any[], outletName: string = PRIMARY_OUTLET): void {
		if (this.flowStrategy) {
			const state: HistoryState = this.flowStrategy.getHistoryState();
			const outletState: OutletHistoryState = state.outlets[outletName];
			if (outletState) {
				if (outletState.back > 0) {
					this.flowStrategy.historyGo(-outletState.back);
					return;
				}
			}
		} else {
			console.warn('Import RouterFlowModule to use backInOutlet functionality');
		}

		this.go(this.normalizeCommands(commands), outletName);
	}

	private closeOutletWithUrl(outletName: string, relativeUrl: string): Promise<boolean> {
		const urlTree = this.router.parseUrl(relativeUrl);
		if (urlTree.root.children[outletName]) {
			const children = Object.assign({}, urlTree.root.children);
			delete children[outletName];
			urlTree.root = new UrlSegmentGroup(urlTree.root.segments, children);
		}
		return this.router.navigateByUrl(urlTree);
	}

	private normalizeCommands(commands: string|any[]): any[] {
		if (!commands) {
			return [];
		} else if (Array.isArray(commands)) {
			return commands.reduce((parts: string[], part: string) => parts.concat(part.split('/')), []);
		} else {
			return commands.split('/');
		}
	}
}
