import { __ } from 'seed/lib/i18n';

export const unitCode = {
		'unitCode.10': __('unitCode.10'),
		'unitCode.11': __('unitCode.11'),
		'unitCode.13': __('unitCode.13'),
		'unitCode.14': __('unitCode.14'),
		'unitCode.15': __('unitCode.15'),
		'unitCode.20': __('unitCode.20'),
		'unitCode.21': __('unitCode.21'),
		'unitCode.22': __('unitCode.22'),
		'unitCode.23': __('unitCode.23'),
		'unitCode.24': __('unitCode.24'),
		'unitCode.25': __('unitCode.25'),
		'unitCode.27': __('unitCode.27'),
		'unitCode.28': __('unitCode.28'),
		'unitCode.33': __('unitCode.33'),
		'unitCode.34': __('unitCode.34'),
		'unitCode.35': __('unitCode.35'),
		'unitCode.37': __('unitCode.37'),
		'unitCode.38': __('unitCode.38'),
		'unitCode.40': __('unitCode.40'),
		'unitCode.41': __('unitCode.41'),
		'unitCode.56': __('unitCode.56'),
		'unitCode.57': __('unitCode.57'),
		'unitCode.58': __('unitCode.58'),
		'unitCode.59': __('unitCode.59'),
		'unitCode.60': __('unitCode.60'),
		'unitCode.61': __('unitCode.61'),
		'unitCode.74': __('unitCode.74'),
		'unitCode.77': __('unitCode.77'),
		'unitCode.80': __('unitCode.80'),
		'unitCode.81': __('unitCode.81'),
		'unitCode.85': __('unitCode.85'),
		'unitCode.87': __('unitCode.87'),
		'unitCode.89': __('unitCode.89'),
		'unitCode.91': __('unitCode.91'),
		'unitCode.1I': __('unitCode.1I'),
		'unitCode.2A': __('unitCode.2A'),
		'unitCode.2B': __('unitCode.2B'),
		'unitCode.2C': __('unitCode.2C'),
		'unitCode.2G': __('unitCode.2G'),
		'unitCode.2H': __('unitCode.2H'),
		'unitCode.2I': __('unitCode.2I'),
		'unitCode.2J': __('unitCode.2J'),
		'unitCode.2K': __('unitCode.2K'),
		'unitCode.2L': __('unitCode.2L'),
		'unitCode.2M': __('unitCode.2M'),
		'unitCode.2N': __('unitCode.2N'),
		'unitCode.2P': __('unitCode.2P'),
		'unitCode.2Q': __('unitCode.2Q'),
		'unitCode.2R': __('unitCode.2R'),
		'unitCode.2U': __('unitCode.2U'),
		'unitCode.2X': __('unitCode.2X'),
		'unitCode.2Y': __('unitCode.2Y'),
		'unitCode.2Z': __('unitCode.2Z'),
		'unitCode.3B': __('unitCode.3B'),
		'unitCode.3C': __('unitCode.3C'),
		'unitCode.4C': __('unitCode.4C'),
		'unitCode.4G': __('unitCode.4G'),
		'unitCode.4H': __('unitCode.4H'),
		'unitCode.4K': __('unitCode.4K'),
		'unitCode.4L': __('unitCode.4L'),
		'unitCode.4M': __('unitCode.4M'),
		'unitCode.4N': __('unitCode.4N'),
		'unitCode.4O': __('unitCode.4O'),
		'unitCode.4P': __('unitCode.4P'),
		'unitCode.4Q': __('unitCode.4Q'),
		'unitCode.4R': __('unitCode.4R'),
		'unitCode.4T': __('unitCode.4T'),
		'unitCode.4U': __('unitCode.4U'),
		'unitCode.4W': __('unitCode.4W'),
		'unitCode.4X': __('unitCode.4X'),
		'unitCode.5A': __('unitCode.5A'),
		'unitCode.5B': __('unitCode.5B'),
		'unitCode.5E': __('unitCode.5E'),
		'unitCode.5J': __('unitCode.5J'),
		'unitCode.A10': __('unitCode.A10'),
		'unitCode.A11': __('unitCode.A11'),
		'unitCode.A12': __('unitCode.A12'),
		'unitCode.A13': __('unitCode.A13'),
		'unitCode.A14': __('unitCode.A14'),
		'unitCode.A15': __('unitCode.A15'),
		'unitCode.A16': __('unitCode.A16'),
		'unitCode.A17': __('unitCode.A17'),
		'unitCode.A18': __('unitCode.A18'),
		'unitCode.A19': __('unitCode.A19'),
		'unitCode.A2': __('unitCode.A2'),
		'unitCode.A20': __('unitCode.A20'),
		'unitCode.A21': __('unitCode.A21'),
		'unitCode.A22': __('unitCode.A22'),
		'unitCode.A23': __('unitCode.A23'),
		'unitCode.A24': __('unitCode.A24'),
		'unitCode.A26': __('unitCode.A26'),
		'unitCode.A27': __('unitCode.A27'),
		'unitCode.A28': __('unitCode.A28'),
		'unitCode.A29': __('unitCode.A29'),
		'unitCode.A3': __('unitCode.A3'),
		'unitCode.A30': __('unitCode.A30'),
		'unitCode.A31': __('unitCode.A31'),
		'unitCode.A32': __('unitCode.A32'),
		'unitCode.A33': __('unitCode.A33'),
		'unitCode.A34': __('unitCode.A34'),
		'unitCode.A35': __('unitCode.A35'),
		'unitCode.A36': __('unitCode.A36'),
		'unitCode.A37': __('unitCode.A37'),
		'unitCode.A38': __('unitCode.A38'),
		'unitCode.A39': __('unitCode.A39'),
		'unitCode.A4': __('unitCode.A4'),
		'unitCode.A40': __('unitCode.A40'),
		'unitCode.A41': __('unitCode.A41'),
		'unitCode.A42': __('unitCode.A42'),
		'unitCode.A43': __('unitCode.A43'),
		'unitCode.A44': __('unitCode.A44'),
		'unitCode.A45': __('unitCode.A45'),
		'unitCode.A47': __('unitCode.A47'),
		'unitCode.A48': __('unitCode.A48'),
		'unitCode.A49': __('unitCode.A49'),
		'unitCode.A5': __('unitCode.A5'),
		'unitCode.A53': __('unitCode.A53'),
		'unitCode.A54': __('unitCode.A54'),
		'unitCode.A55': __('unitCode.A55'),
		'unitCode.A56': __('unitCode.A56'),
		'unitCode.A59': __('unitCode.A59'),
		'unitCode.A6': __('unitCode.A6'),
		'unitCode.A68': __('unitCode.A68'),
		'unitCode.A69': __('unitCode.A69'),
		'unitCode.A7': __('unitCode.A7'),
		'unitCode.A70': __('unitCode.A70'),
		'unitCode.A71': __('unitCode.A71'),
		'unitCode.A73': __('unitCode.A73'),
		'unitCode.A74': __('unitCode.A74'),
		'unitCode.A75': __('unitCode.A75'),
		'unitCode.A76': __('unitCode.A76'),
		'unitCode.A8': __('unitCode.A8'),
		'unitCode.A84': __('unitCode.A84'),
		'unitCode.A85': __('unitCode.A85'),
		'unitCode.A86': __('unitCode.A86'),
		'unitCode.A87': __('unitCode.A87'),
		'unitCode.A88': __('unitCode.A88'),
		'unitCode.A89': __('unitCode.A89'),
		'unitCode.A9': __('unitCode.A9'),
		'unitCode.A90': __('unitCode.A90'),
		'unitCode.A91': __('unitCode.A91'),
		'unitCode.A93': __('unitCode.A93'),
		'unitCode.A94': __('unitCode.A94'),
		'unitCode.A95': __('unitCode.A95'),
		'unitCode.A96': __('unitCode.A96'),
		'unitCode.A97': __('unitCode.A97'),
		'unitCode.A98': __('unitCode.A98'),
		'unitCode.A99': __('unitCode.A99'),
		'unitCode.AA': __('unitCode.AA'),
		'unitCode.AB': __('unitCode.AB'),
		'unitCode.ACR': __('unitCode.ACR'),
		'unitCode.ACT': __('unitCode.ACT'),
		'unitCode.AD': __('unitCode.AD'),
		'unitCode.AE': __('unitCode.AE'),
		'unitCode.AH': __('unitCode.AH'),
		'unitCode.AI': __('unitCode.AI'),
		'unitCode.AK': __('unitCode.AK'),
		'unitCode.AL': __('unitCode.AL'),
		'unitCode.AMH': __('unitCode.AMH'),
		'unitCode.AMP': __('unitCode.AMP'),
		'unitCode.ANN': __('unitCode.ANN'),
		'unitCode.APZ': __('unitCode.APZ'),
		'unitCode.AQ': __('unitCode.AQ'),
		'unitCode.AS': __('unitCode.AS'),
		'unitCode.ASM': __('unitCode.ASM'),
		'unitCode.ASU': __('unitCode.ASU'),
		'unitCode.ATM': __('unitCode.ATM'),
		'unitCode.AWG': __('unitCode.AWG'),
		'unitCode.AY': __('unitCode.AY'),
		'unitCode.AZ': __('unitCode.AZ'),
		'unitCode.B1': __('unitCode.B1'),
		'unitCode.B10': __('unitCode.B10'),
		'unitCode.B11': __('unitCode.B11'),
		'unitCode.B12': __('unitCode.B12'),
		'unitCode.B13': __('unitCode.B13'),
		'unitCode.B14': __('unitCode.B14'),
		'unitCode.B15': __('unitCode.B15'),
		'unitCode.B16': __('unitCode.B16'),
		'unitCode.B17': __('unitCode.B17'),
		'unitCode.B18': __('unitCode.B18'),
		'unitCode.B19': __('unitCode.B19'),
		'unitCode.B20': __('unitCode.B20'),
		'unitCode.B21': __('unitCode.B21'),
		'unitCode.B22': __('unitCode.B22'),
		'unitCode.B23': __('unitCode.B23'),
		'unitCode.B24': __('unitCode.B24'),
		'unitCode.B25': __('unitCode.B25'),
		'unitCode.B26': __('unitCode.B26'),
		'unitCode.B27': __('unitCode.B27'),
		'unitCode.B28': __('unitCode.B28'),
		'unitCode.B29': __('unitCode.B29'),
		'unitCode.B3': __('unitCode.B3'),
		'unitCode.B30': __('unitCode.B30'),
		'unitCode.B31': __('unitCode.B31'),
		'unitCode.B32': __('unitCode.B32'),
		'unitCode.B33': __('unitCode.B33'),
		'unitCode.B34': __('unitCode.B34'),
		'unitCode.B35': __('unitCode.B35'),
		'unitCode.B4': __('unitCode.B4'),
		'unitCode.B41': __('unitCode.B41'),
		'unitCode.B42': __('unitCode.B42'),
		'unitCode.B43': __('unitCode.B43'),
		'unitCode.B44': __('unitCode.B44'),
		'unitCode.B45': __('unitCode.B45'),
		'unitCode.B46': __('unitCode.B46'),
		'unitCode.B47': __('unitCode.B47'),
		'unitCode.B48': __('unitCode.B48'),
		'unitCode.B49': __('unitCode.B49'),
		'unitCode.B50': __('unitCode.B50'),
		'unitCode.B52': __('unitCode.B52'),
		'unitCode.B53': __('unitCode.B53'),
		'unitCode.B54': __('unitCode.B54'),
		'unitCode.B55': __('unitCode.B55'),
		'unitCode.B56': __('unitCode.B56'),
		'unitCode.B57': __('unitCode.B57'),
		'unitCode.B58': __('unitCode.B58'),
		'unitCode.B59': __('unitCode.B59'),
		'unitCode.B60': __('unitCode.B60'),
		'unitCode.B61': __('unitCode.B61'),
		'unitCode.B62': __('unitCode.B62'),
		'unitCode.B63': __('unitCode.B63'),
		'unitCode.B64': __('unitCode.B64'),
		'unitCode.B66': __('unitCode.B66'),
		'unitCode.B67': __('unitCode.B67'),
		'unitCode.B68': __('unitCode.B68'),
		'unitCode.B69': __('unitCode.B69'),
		'unitCode.B7': __('unitCode.B7'),
		'unitCode.B70': __('unitCode.B70'),
		'unitCode.B71': __('unitCode.B71'),
		'unitCode.B72': __('unitCode.B72'),
		'unitCode.B73': __('unitCode.B73'),
		'unitCode.B74': __('unitCode.B74'),
		'unitCode.B75': __('unitCode.B75'),
		'unitCode.B76': __('unitCode.B76'),
		'unitCode.B77': __('unitCode.B77'),
		'unitCode.B78': __('unitCode.B78'),
		'unitCode.B79': __('unitCode.B79'),
		'unitCode.B8': __('unitCode.B8'),
		'unitCode.B80': __('unitCode.B80'),
		'unitCode.B81': __('unitCode.B81'),
		'unitCode.B82': __('unitCode.B82'),
		'unitCode.B83': __('unitCode.B83'),
		'unitCode.B84': __('unitCode.B84'),
		'unitCode.B85': __('unitCode.B85'),
		'unitCode.B86': __('unitCode.B86'),
		'unitCode.B87': __('unitCode.B87'),
		'unitCode.B88': __('unitCode.B88'),
		'unitCode.B89': __('unitCode.B89'),
		'unitCode.B90': __('unitCode.B90'),
		'unitCode.B91': __('unitCode.B91'),
		'unitCode.B92': __('unitCode.B92'),
		'unitCode.B93': __('unitCode.B93'),
		'unitCode.B94': __('unitCode.B94'),
		'unitCode.B95': __('unitCode.B95'),
		'unitCode.B96': __('unitCode.B96'),
		'unitCode.B97': __('unitCode.B97'),
		'unitCode.B98': __('unitCode.B98'),
		'unitCode.B99': __('unitCode.B99'),
		'unitCode.BAR': __('unitCode.BAR'),
		'unitCode.BB': __('unitCode.BB'),
		'unitCode.BFT': __('unitCode.BFT'),
		'unitCode.BHP': __('unitCode.BHP'),
		'unitCode.BIL': __('unitCode.BIL'),
		'unitCode.BLD': __('unitCode.BLD'),
		'unitCode.BLL': __('unitCode.BLL'),
		'unitCode.BP': __('unitCode.BP'),
		'unitCode.BPM': __('unitCode.BPM'),
		'unitCode.BQL': __('unitCode.BQL'),
		'unitCode.BTU': __('unitCode.BTU'),
		'unitCode.BUA': __('unitCode.BUA'),
		'unitCode.BUI': __('unitCode.BUI'),
		'unitCode.C0': __('unitCode.C0'),
		'unitCode.C10': __('unitCode.C10'),
		'unitCode.C11': __('unitCode.C11'),
		'unitCode.C12': __('unitCode.C12'),
		'unitCode.C13': __('unitCode.C13'),
		'unitCode.C14': __('unitCode.C14'),
		'unitCode.C15': __('unitCode.C15'),
		'unitCode.C16': __('unitCode.C16'),
		'unitCode.C17': __('unitCode.C17'),
		'unitCode.C18': __('unitCode.C18'),
		'unitCode.C19': __('unitCode.C19'),
		'unitCode.C20': __('unitCode.C20'),
		'unitCode.C21': __('unitCode.C21'),
		'unitCode.C22': __('unitCode.C22'),
		'unitCode.C23': __('unitCode.C23'),
		'unitCode.C24': __('unitCode.C24'),
		'unitCode.C25': __('unitCode.C25'),
		'unitCode.C26': __('unitCode.C26'),
		'unitCode.C27': __('unitCode.C27'),
		'unitCode.C28': __('unitCode.C28'),
		'unitCode.C29': __('unitCode.C29'),
		'unitCode.C3': __('unitCode.C3'),
		'unitCode.C30': __('unitCode.C30'),
		'unitCode.C31': __('unitCode.C31'),
		'unitCode.C32': __('unitCode.C32'),
		'unitCode.C33': __('unitCode.C33'),
		'unitCode.C34': __('unitCode.C34'),
		'unitCode.C35': __('unitCode.C35'),
		'unitCode.C36': __('unitCode.C36'),
		'unitCode.C37': __('unitCode.C37'),
		'unitCode.C38': __('unitCode.C38'),
		'unitCode.C39': __('unitCode.C39'),
		'unitCode.C40': __('unitCode.C40'),
		'unitCode.C41': __('unitCode.C41'),
		'unitCode.C42': __('unitCode.C42'),
		'unitCode.C43': __('unitCode.C43'),
		'unitCode.C44': __('unitCode.C44'),
		'unitCode.C45': __('unitCode.C45'),
		'unitCode.C46': __('unitCode.C46'),
		'unitCode.C47': __('unitCode.C47'),
		'unitCode.C48': __('unitCode.C48'),
		'unitCode.C49': __('unitCode.C49'),
		'unitCode.C50': __('unitCode.C50'),
		'unitCode.C51': __('unitCode.C51'),
		'unitCode.C52': __('unitCode.C52'),
		'unitCode.C53': __('unitCode.C53'),
		'unitCode.C54': __('unitCode.C54'),
		'unitCode.C55': __('unitCode.C55'),
		'unitCode.C56': __('unitCode.C56'),
		'unitCode.C57': __('unitCode.C57'),
		'unitCode.C58': __('unitCode.C58'),
		'unitCode.C59': __('unitCode.C59'),
		'unitCode.C60': __('unitCode.C60'),
		'unitCode.C61': __('unitCode.C61'),
		'unitCode.C62': __('unitCode.C62'),
		'unitCode.C63': __('unitCode.C63'),
		'unitCode.C64': __('unitCode.C64'),
		'unitCode.C65': __('unitCode.C65'),
		'unitCode.C66': __('unitCode.C66'),
		'unitCode.C67': __('unitCode.C67'),
		'unitCode.C68': __('unitCode.C68'),
		'unitCode.C69': __('unitCode.C69'),
		'unitCode.C7': __('unitCode.C7'),
		'unitCode.C70': __('unitCode.C70'),
		'unitCode.C71': __('unitCode.C71'),
		'unitCode.C72': __('unitCode.C72'),
		'unitCode.C73': __('unitCode.C73'),
		'unitCode.C74': __('unitCode.C74'),
		'unitCode.C75': __('unitCode.C75'),
		'unitCode.C76': __('unitCode.C76'),
		'unitCode.C78': __('unitCode.C78'),
		'unitCode.C79': __('unitCode.C79'),
		'unitCode.C8': __('unitCode.C8'),
		'unitCode.C80': __('unitCode.C80'),
		'unitCode.C81': __('unitCode.C81'),
		'unitCode.C82': __('unitCode.C82'),
		'unitCode.C83': __('unitCode.C83'),
		'unitCode.C84': __('unitCode.C84'),
		'unitCode.C85': __('unitCode.C85'),
		'unitCode.C86': __('unitCode.C86'),
		'unitCode.C87': __('unitCode.C87'),
		'unitCode.C88': __('unitCode.C88'),
		'unitCode.C89': __('unitCode.C89'),
		'unitCode.C9': __('unitCode.C9'),
		'unitCode.C90': __('unitCode.C90'),
		'unitCode.C91': __('unitCode.C91'),
		'unitCode.C92': __('unitCode.C92'),
		'unitCode.C93': __('unitCode.C93'),
		'unitCode.C94': __('unitCode.C94'),
		'unitCode.C95': __('unitCode.C95'),
		'unitCode.C96': __('unitCode.C96'),
		'unitCode.C97': __('unitCode.C97'),
		'unitCode.C99': __('unitCode.C99'),
		'unitCode.CCT': __('unitCode.CCT'),
		'unitCode.CDL': __('unitCode.CDL'),
		'unitCode.CEL': __('unitCode.CEL'),
		'unitCode.CEN': __('unitCode.CEN'),
		'unitCode.CG': __('unitCode.CG'),
		'unitCode.CGM': __('unitCode.CGM'),
		'unitCode.CKG': __('unitCode.CKG'),
		'unitCode.CLF': __('unitCode.CLF'),
		'unitCode.CLT': __('unitCode.CLT'),
		'unitCode.CMK': __('unitCode.CMK'),
		'unitCode.CMQ': __('unitCode.CMQ'),
		'unitCode.CMT': __('unitCode.CMT'),
		'unitCode.CNP': __('unitCode.CNP'),
		'unitCode.CNT': __('unitCode.CNT'),
		'unitCode.COU': __('unitCode.COU'),
		'unitCode.CTG': __('unitCode.CTG'),
		'unitCode.CTM': __('unitCode.CTM'),
		'unitCode.CTN': __('unitCode.CTN'),
		'unitCode.CUR': __('unitCode.CUR'),
		'unitCode.CWA': __('unitCode.CWA'),
		'unitCode.CWI': __('unitCode.CWI'),
		'unitCode.D03': __('unitCode.D03'),
		'unitCode.D04': __('unitCode.D04'),
		'unitCode.D1': __('unitCode.D1'),
		'unitCode.D10': __('unitCode.D10'),
		'unitCode.D11': __('unitCode.D11'),
		'unitCode.D12': __('unitCode.D12'),
		'unitCode.D13': __('unitCode.D13'),
		'unitCode.D15': __('unitCode.D15'),
		'unitCode.D16': __('unitCode.D16'),
		'unitCode.D17': __('unitCode.D17'),
		'unitCode.D18': __('unitCode.D18'),
		'unitCode.D19': __('unitCode.D19'),
		'unitCode.D2': __('unitCode.D2'),
		'unitCode.D20': __('unitCode.D20'),
		'unitCode.D21': __('unitCode.D21'),
		'unitCode.D22': __('unitCode.D22'),
		'unitCode.D23': __('unitCode.D23'),
		'unitCode.D24': __('unitCode.D24'),
		'unitCode.D25': __('unitCode.D25'),
		'unitCode.D26': __('unitCode.D26'),
		'unitCode.D27': __('unitCode.D27'),
		'unitCode.D29': __('unitCode.D29'),
		'unitCode.D30': __('unitCode.D30'),
		'unitCode.D31': __('unitCode.D31'),
		'unitCode.D32': __('unitCode.D32'),
		'unitCode.D33': __('unitCode.D33'),
		'unitCode.D34': __('unitCode.D34'),
		'unitCode.D36': __('unitCode.D36'),
		'unitCode.D41': __('unitCode.D41'),
		'unitCode.D42': __('unitCode.D42'),
		'unitCode.D43': __('unitCode.D43'),
		'unitCode.D44': __('unitCode.D44'),
		'unitCode.D45': __('unitCode.D45'),
		'unitCode.D46': __('unitCode.D46'),
		'unitCode.D47': __('unitCode.D47'),
		'unitCode.D48': __('unitCode.D48'),
		'unitCode.D49': __('unitCode.D49'),
		'unitCode.D5': __('unitCode.D5'),
		'unitCode.D50': __('unitCode.D50'),
		'unitCode.D51': __('unitCode.D51'),
		'unitCode.D52': __('unitCode.D52'),
		'unitCode.D53': __('unitCode.D53'),
		'unitCode.D54': __('unitCode.D54'),
		'unitCode.D55': __('unitCode.D55'),
		'unitCode.D56': __('unitCode.D56'),
		'unitCode.D57': __('unitCode.D57'),
		'unitCode.D58': __('unitCode.D58'),
		'unitCode.D59': __('unitCode.D59'),
		'unitCode.D6': __('unitCode.D6'),
		'unitCode.D60': __('unitCode.D60'),
		'unitCode.D61': __('unitCode.D61'),
		'unitCode.D62': __('unitCode.D62'),
		'unitCode.D63': __('unitCode.D63'),
		'unitCode.D65': __('unitCode.D65'),
		'unitCode.D68': __('unitCode.D68'),
		'unitCode.D69': __('unitCode.D69'),
		'unitCode.D73': __('unitCode.D73'),
		'unitCode.D74': __('unitCode.D74'),
		'unitCode.D77': __('unitCode.D77'),
		'unitCode.D78': __('unitCode.D78'),
		'unitCode.D80': __('unitCode.D80'),
		'unitCode.D81': __('unitCode.D81'),
		'unitCode.D82': __('unitCode.D82'),
		'unitCode.D83': __('unitCode.D83'),
		'unitCode.D85': __('unitCode.D85'),
		'unitCode.D86': __('unitCode.D86'),
		'unitCode.D87': __('unitCode.D87'),
		'unitCode.D88': __('unitCode.D88'),
		'unitCode.D89': __('unitCode.D89'),
		'unitCode.D91': __('unitCode.D91'),
		'unitCode.D93': __('unitCode.D93'),
		'unitCode.D94': __('unitCode.D94'),
		'unitCode.D95': __('unitCode.D95'),
		'unitCode.DAA': __('unitCode.DAA'),
		'unitCode.DAD': __('unitCode.DAD'),
		'unitCode.DAY': __('unitCode.DAY'),
		'unitCode.DB': __('unitCode.DB'),
		'unitCode.DD': __('unitCode.DD'),
		'unitCode.DEC': __('unitCode.DEC'),
		'unitCode.DG': __('unitCode.DG'),
		'unitCode.DJ': __('unitCode.DJ'),
		'unitCode.DLT': __('unitCode.DLT'),
		'unitCode.DMA': __('unitCode.DMA'),
		'unitCode.DMK': __('unitCode.DMK'),
		'unitCode.DMO': __('unitCode.DMO'),
		'unitCode.DMQ': __('unitCode.DMQ'),
		'unitCode.DMT': __('unitCode.DMT'),
		'unitCode.DN': __('unitCode.DN'),
		'unitCode.DPC': __('unitCode.DPC'),
		'unitCode.DPR': __('unitCode.DPR'),
		'unitCode.DPT': __('unitCode.DPT'),
		'unitCode.DRA': __('unitCode.DRA'),
		'unitCode.DRI': __('unitCode.DRI'),
		'unitCode.DRL': __('unitCode.DRL'),
		'unitCode.DT': __('unitCode.DT'),
		'unitCode.DTN': __('unitCode.DTN'),
		'unitCode.DWT': __('unitCode.DWT'),
		'unitCode.DZN': __('unitCode.DZN'),
		'unitCode.DZP': __('unitCode.DZP'),
		'unitCode.E01': __('unitCode.E01'),
		'unitCode.E07': __('unitCode.E07'),
		'unitCode.E08': __('unitCode.E08'),
		'unitCode.E09': __('unitCode.E09'),
		'unitCode.E10': __('unitCode.E10'),
		'unitCode.E12': __('unitCode.E12'),
		'unitCode.E14': __('unitCode.E14'),
		'unitCode.E15': __('unitCode.E15'),
		'unitCode.E16': __('unitCode.E16'),
		'unitCode.E17': __('unitCode.E17'),
		'unitCode.E18': __('unitCode.E18'),
		'unitCode.E19': __('unitCode.E19'),
		'unitCode.E20': __('unitCode.E20'),
		'unitCode.E21': __('unitCode.E21'),
		'unitCode.E22': __('unitCode.E22'),
		'unitCode.E23': __('unitCode.E23'),
		'unitCode.E25': __('unitCode.E25'),
		'unitCode.E27': __('unitCode.E27'),
		'unitCode.E28': __('unitCode.E28'),
		'unitCode.E30': __('unitCode.E30'),
		'unitCode.E31': __('unitCode.E31'),
		'unitCode.E32': __('unitCode.E32'),
		'unitCode.E33': __('unitCode.E33'),
		'unitCode.E34': __('unitCode.E34'),
		'unitCode.E35': __('unitCode.E35'),
		'unitCode.E36': __('unitCode.E36'),
		'unitCode.E37': __('unitCode.E37'),
		'unitCode.E38': __('unitCode.E38'),
		'unitCode.E39': __('unitCode.E39'),
		'unitCode.E4': __('unitCode.E4'),
		'unitCode.E40': __('unitCode.E40'),
		'unitCode.E41': __('unitCode.E41'),
		'unitCode.E42': __('unitCode.E42'),
		'unitCode.E43': __('unitCode.E43'),
		'unitCode.E44': __('unitCode.E44'),
		'unitCode.E45': __('unitCode.E45'),
		'unitCode.E46': __('unitCode.E46'),
		'unitCode.E47': __('unitCode.E47'),
		'unitCode.E48': __('unitCode.E48'),
		'unitCode.E49': __('unitCode.E49'),
		'unitCode.E50': __('unitCode.E50'),
		'unitCode.E51': __('unitCode.E51'),
		'unitCode.E52': __('unitCode.E52'),
		'unitCode.E53': __('unitCode.E53'),
		'unitCode.E54': __('unitCode.E54'),
		'unitCode.E55': __('unitCode.E55'),
		'unitCode.E56': __('unitCode.E56'),
		'unitCode.E57': __('unitCode.E57'),
		'unitCode.E58': __('unitCode.E58'),
		'unitCode.E59': __('unitCode.E59'),
		'unitCode.E60': __('unitCode.E60'),
		'unitCode.E61': __('unitCode.E61'),
		'unitCode.E62': __('unitCode.E62'),
		'unitCode.E63': __('unitCode.E63'),
		'unitCode.E64': __('unitCode.E64'),
		'unitCode.E65': __('unitCode.E65'),
		'unitCode.E66': __('unitCode.E66'),
		'unitCode.E67': __('unitCode.E67'),
		'unitCode.E68': __('unitCode.E68'),
		'unitCode.E69': __('unitCode.E69'),
		'unitCode.E70': __('unitCode.E70'),
		'unitCode.E71': __('unitCode.E71'),
		'unitCode.E72': __('unitCode.E72'),
		'unitCode.E73': __('unitCode.E73'),
		'unitCode.E74': __('unitCode.E74'),
		'unitCode.E75': __('unitCode.E75'),
		'unitCode.E76': __('unitCode.E76'),
		'unitCode.E77': __('unitCode.E77'),
		'unitCode.E78': __('unitCode.E78'),
		'unitCode.E79': __('unitCode.E79'),
		'unitCode.E80': __('unitCode.E80'),
		'unitCode.E81': __('unitCode.E81'),
		'unitCode.E82': __('unitCode.E82'),
		'unitCode.E83': __('unitCode.E83'),
		'unitCode.E84': __('unitCode.E84'),
		'unitCode.E85': __('unitCode.E85'),
		'unitCode.E86': __('unitCode.E86'),
		'unitCode.E87': __('unitCode.E87'),
		'unitCode.E88': __('unitCode.E88'),
		'unitCode.E89': __('unitCode.E89'),
		'unitCode.E90': __('unitCode.E90'),
		'unitCode.E91': __('unitCode.E91'),
		'unitCode.E92': __('unitCode.E92'),
		'unitCode.E93': __('unitCode.E93'),
		'unitCode.E94': __('unitCode.E94'),
		'unitCode.E95': __('unitCode.E95'),
		'unitCode.E96': __('unitCode.E96'),
		'unitCode.E97': __('unitCode.E97'),
		'unitCode.E98': __('unitCode.E98'),
		'unitCode.E99': __('unitCode.E99'),
		'unitCode.EA': __('unitCode.EA'),
		'unitCode.EB': __('unitCode.EB'),
		'unitCode.EQ': __('unitCode.EQ'),
		'unitCode.F01': __('unitCode.F01'),
		'unitCode.F02': __('unitCode.F02'),
		'unitCode.F03': __('unitCode.F03'),
		'unitCode.F04': __('unitCode.F04'),
		'unitCode.F05': __('unitCode.F05'),
		'unitCode.F06': __('unitCode.F06'),
		'unitCode.F07': __('unitCode.F07'),
		'unitCode.F08': __('unitCode.F08'),
		'unitCode.F10': __('unitCode.F10'),
		'unitCode.F11': __('unitCode.F11'),
		'unitCode.F12': __('unitCode.F12'),
		'unitCode.F13': __('unitCode.F13'),
		'unitCode.F14': __('unitCode.F14'),
		'unitCode.F15': __('unitCode.F15'),
		'unitCode.F16': __('unitCode.F16'),
		'unitCode.F17': __('unitCode.F17'),
		'unitCode.F18': __('unitCode.F18'),
		'unitCode.F19': __('unitCode.F19'),
		'unitCode.F20': __('unitCode.F20'),
		'unitCode.F21': __('unitCode.F21'),
		'unitCode.F22': __('unitCode.F22'),
		'unitCode.F23': __('unitCode.F23'),
		'unitCode.F24': __('unitCode.F24'),
		'unitCode.F25': __('unitCode.F25'),
		'unitCode.F26': __('unitCode.F26'),
		'unitCode.F27': __('unitCode.F27'),
		'unitCode.F28': __('unitCode.F28'),
		'unitCode.F29': __('unitCode.F29'),
		'unitCode.F30': __('unitCode.F30'),
		'unitCode.F31': __('unitCode.F31'),
		'unitCode.F32': __('unitCode.F32'),
		'unitCode.F33': __('unitCode.F33'),
		'unitCode.F34': __('unitCode.F34'),
		'unitCode.F35': __('unitCode.F35'),
		'unitCode.F36': __('unitCode.F36'),
		'unitCode.F37': __('unitCode.F37'),
		'unitCode.F38': __('unitCode.F38'),
		'unitCode.F39': __('unitCode.F39'),
		'unitCode.F40': __('unitCode.F40'),
		'unitCode.F41': __('unitCode.F41'),
		'unitCode.F42': __('unitCode.F42'),
		'unitCode.F43': __('unitCode.F43'),
		'unitCode.F44': __('unitCode.F44'),
		'unitCode.F45': __('unitCode.F45'),
		'unitCode.F46': __('unitCode.F46'),
		'unitCode.F47': __('unitCode.F47'),
		'unitCode.F48': __('unitCode.F48'),
		'unitCode.F49': __('unitCode.F49'),
		'unitCode.F50': __('unitCode.F50'),
		'unitCode.F51': __('unitCode.F51'),
		'unitCode.F52': __('unitCode.F52'),
		'unitCode.F53': __('unitCode.F53'),
		'unitCode.F54': __('unitCode.F54'),
		'unitCode.F55': __('unitCode.F55'),
		'unitCode.F56': __('unitCode.F56'),
		'unitCode.F57': __('unitCode.F57'),
		'unitCode.F58': __('unitCode.F58'),
		'unitCode.F59': __('unitCode.F59'),
		'unitCode.F60': __('unitCode.F60'),
		'unitCode.F61': __('unitCode.F61'),
		'unitCode.F62': __('unitCode.F62'),
		'unitCode.F63': __('unitCode.F63'),
		'unitCode.F64': __('unitCode.F64'),
		'unitCode.F65': __('unitCode.F65'),
		'unitCode.F66': __('unitCode.F66'),
		'unitCode.F67': __('unitCode.F67'),
		'unitCode.F68': __('unitCode.F68'),
		'unitCode.F69': __('unitCode.F69'),
		'unitCode.F70': __('unitCode.F70'),
		'unitCode.F71': __('unitCode.F71'),
		'unitCode.F72': __('unitCode.F72'),
		'unitCode.F73': __('unitCode.F73'),
		'unitCode.F74': __('unitCode.F74'),
		'unitCode.F75': __('unitCode.F75'),
		'unitCode.F76': __('unitCode.F76'),
		'unitCode.F77': __('unitCode.F77'),
		'unitCode.F78': __('unitCode.F78'),
		'unitCode.F79': __('unitCode.F79'),
		'unitCode.F80': __('unitCode.F80'),
		'unitCode.F81': __('unitCode.F81'),
		'unitCode.F82': __('unitCode.F82'),
		'unitCode.F83': __('unitCode.F83'),
		'unitCode.F84': __('unitCode.F84'),
		'unitCode.F85': __('unitCode.F85'),
		'unitCode.F86': __('unitCode.F86'),
		'unitCode.F87': __('unitCode.F87'),
		'unitCode.F88': __('unitCode.F88'),
		'unitCode.F89': __('unitCode.F89'),
		'unitCode.F90': __('unitCode.F90'),
		'unitCode.F91': __('unitCode.F91'),
		'unitCode.F92': __('unitCode.F92'),
		'unitCode.F93': __('unitCode.F93'),
		'unitCode.F94': __('unitCode.F94'),
		'unitCode.F95': __('unitCode.F95'),
		'unitCode.F96': __('unitCode.F96'),
		'unitCode.F97': __('unitCode.F97'),
		'unitCode.F98': __('unitCode.F98'),
		'unitCode.F99': __('unitCode.F99'),
		'unitCode.FAH': __('unitCode.FAH'),
		'unitCode.FAR': __('unitCode.FAR'),
		'unitCode.FBM': __('unitCode.FBM'),
		'unitCode.FC': __('unitCode.FC'),
		'unitCode.FF': __('unitCode.FF'),
		'unitCode.FH': __('unitCode.FH'),
		'unitCode.FIT': __('unitCode.FIT'),
		'unitCode.FL': __('unitCode.FL'),
		'unitCode.FOT': __('unitCode.FOT'),
		'unitCode.FP': __('unitCode.FP'),
		'unitCode.FR': __('unitCode.FR'),
		'unitCode.FS': __('unitCode.FS'),
		'unitCode.FTK': __('unitCode.FTK'),
		'unitCode.FTQ': __('unitCode.FTQ'),
		'unitCode.G01': __('unitCode.G01'),
		'unitCode.G04': __('unitCode.G04'),
		'unitCode.G05': __('unitCode.G05'),
		'unitCode.G06': __('unitCode.G06'),
		'unitCode.G08': __('unitCode.G08'),
		'unitCode.G09': __('unitCode.G09'),
		'unitCode.G10': __('unitCode.G10'),
		'unitCode.G11': __('unitCode.G11'),
		'unitCode.G12': __('unitCode.G12'),
		'unitCode.G13': __('unitCode.G13'),
		'unitCode.G14': __('unitCode.G14'),
		'unitCode.G15': __('unitCode.G15'),
		'unitCode.G16': __('unitCode.G16'),
		'unitCode.G17': __('unitCode.G17'),
		'unitCode.G18': __('unitCode.G18'),
		'unitCode.G19': __('unitCode.G19'),
		'unitCode.G2': __('unitCode.G2'),
		'unitCode.G20': __('unitCode.G20'),
		'unitCode.G21': __('unitCode.G21'),
		'unitCode.G23': __('unitCode.G23'),
		'unitCode.G24': __('unitCode.G24'),
		'unitCode.G25': __('unitCode.G25'),
		'unitCode.G26': __('unitCode.G26'),
		'unitCode.G27': __('unitCode.G27'),
		'unitCode.G28': __('unitCode.G28'),
		'unitCode.G29': __('unitCode.G29'),
		'unitCode.G3': __('unitCode.G3'),
		'unitCode.G30': __('unitCode.G30'),
		'unitCode.G31': __('unitCode.G31'),
		'unitCode.G32': __('unitCode.G32'),
		'unitCode.G33': __('unitCode.G33'),
		'unitCode.G34': __('unitCode.G34'),
		'unitCode.G35': __('unitCode.G35'),
		'unitCode.G36': __('unitCode.G36'),
		'unitCode.G37': __('unitCode.G37'),
		'unitCode.G38': __('unitCode.G38'),
		'unitCode.G39': __('unitCode.G39'),
		'unitCode.G40': __('unitCode.G40'),
		'unitCode.G41': __('unitCode.G41'),
		'unitCode.G42': __('unitCode.G42'),
		'unitCode.G43': __('unitCode.G43'),
		'unitCode.G44': __('unitCode.G44'),
		'unitCode.G45': __('unitCode.G45'),
		'unitCode.G46': __('unitCode.G46'),
		'unitCode.G47': __('unitCode.G47'),
		'unitCode.G48': __('unitCode.G48'),
		'unitCode.G49': __('unitCode.G49'),
		'unitCode.G50': __('unitCode.G50'),
		'unitCode.G51': __('unitCode.G51'),
		'unitCode.G52': __('unitCode.G52'),
		'unitCode.G53': __('unitCode.G53'),
		'unitCode.G54': __('unitCode.G54'),
		'unitCode.G55': __('unitCode.G55'),
		'unitCode.G56': __('unitCode.G56'),
		'unitCode.G57': __('unitCode.G57'),
		'unitCode.G58': __('unitCode.G58'),
		'unitCode.G59': __('unitCode.G59'),
		'unitCode.G60': __('unitCode.G60'),
		'unitCode.G61': __('unitCode.G61'),
		'unitCode.G62': __('unitCode.G62'),
		'unitCode.G63': __('unitCode.G63'),
		'unitCode.G64': __('unitCode.G64'),
		'unitCode.G65': __('unitCode.G65'),
		'unitCode.G66': __('unitCode.G66'),
		'unitCode.G67': __('unitCode.G67'),
		'unitCode.G68': __('unitCode.G68'),
		'unitCode.G69': __('unitCode.G69'),
		'unitCode.G70': __('unitCode.G70'),
		'unitCode.G71': __('unitCode.G71'),
		'unitCode.G72': __('unitCode.G72'),
		'unitCode.G73': __('unitCode.G73'),
		'unitCode.G74': __('unitCode.G74'),
		'unitCode.G75': __('unitCode.G75'),
		'unitCode.G76': __('unitCode.G76'),
		'unitCode.G77': __('unitCode.G77'),
		'unitCode.G78': __('unitCode.G78'),
		'unitCode.G79': __('unitCode.G79'),
		'unitCode.G80': __('unitCode.G80'),
		'unitCode.G81': __('unitCode.G81'),
		'unitCode.G82': __('unitCode.G82'),
		'unitCode.G83': __('unitCode.G83'),
		'unitCode.G84': __('unitCode.G84'),
		'unitCode.G85': __('unitCode.G85'),
		'unitCode.G86': __('unitCode.G86'),
		'unitCode.G87': __('unitCode.G87'),
		'unitCode.G88': __('unitCode.G88'),
		'unitCode.G89': __('unitCode.G89'),
		'unitCode.G90': __('unitCode.G90'),
		'unitCode.G91': __('unitCode.G91'),
		'unitCode.G92': __('unitCode.G92'),
		'unitCode.G93': __('unitCode.G93'),
		'unitCode.G94': __('unitCode.G94'),
		'unitCode.G95': __('unitCode.G95'),
		'unitCode.G96': __('unitCode.G96'),
		'unitCode.G97': __('unitCode.G97'),
		'unitCode.G98': __('unitCode.G98'),
		'unitCode.G99': __('unitCode.G99'),
		'unitCode.GB': __('unitCode.GB'),
		'unitCode.GBQ': __('unitCode.GBQ'),
		'unitCode.GDW': __('unitCode.GDW'),
		'unitCode.GE': __('unitCode.GE'),
		'unitCode.GF': __('unitCode.GF'),
		'unitCode.GFI': __('unitCode.GFI'),
		'unitCode.GGR': __('unitCode.GGR'),
		'unitCode.GIA': __('unitCode.GIA'),
		'unitCode.GIC': __('unitCode.GIC'),
		'unitCode.GII': __('unitCode.GII'),
		'unitCode.GIP': __('unitCode.GIP'),
		'unitCode.GJ': __('unitCode.GJ'),
		'unitCode.GL': __('unitCode.GL'),
		'unitCode.GLD': __('unitCode.GLD'),
		'unitCode.GLI': __('unitCode.GLI'),
		'unitCode.GLL': __('unitCode.GLL'),
		'unitCode.GM': __('unitCode.GM'),
		'unitCode.GO': __('unitCode.GO'),
		'unitCode.GP': __('unitCode.GP'),
		'unitCode.GQ': __('unitCode.GQ'),
		'unitCode.GRM': __('unitCode.GRM'),
		'unitCode.GRN': __('unitCode.GRN'),
		'unitCode.GRO': __('unitCode.GRO'),
		'unitCode.GV': __('unitCode.GV'),
		'unitCode.GWH': __('unitCode.GWH'),
		'unitCode.H03': __('unitCode.H03'),
		'unitCode.H04': __('unitCode.H04'),
		'unitCode.H05': __('unitCode.H05'),
		'unitCode.H06': __('unitCode.H06'),
		'unitCode.H07': __('unitCode.H07'),
		'unitCode.H08': __('unitCode.H08'),
		'unitCode.H09': __('unitCode.H09'),
		'unitCode.H10': __('unitCode.H10'),
		'unitCode.H11': __('unitCode.H11'),
		'unitCode.H12': __('unitCode.H12'),
		'unitCode.H13': __('unitCode.H13'),
		'unitCode.H14': __('unitCode.H14'),
		'unitCode.H15': __('unitCode.H15'),
		'unitCode.H16': __('unitCode.H16'),
		'unitCode.H18': __('unitCode.H18'),
		'unitCode.H19': __('unitCode.H19'),
		'unitCode.H20': __('unitCode.H20'),
		'unitCode.H21': __('unitCode.H21'),
		'unitCode.H22': __('unitCode.H22'),
		'unitCode.H23': __('unitCode.H23'),
		'unitCode.H24': __('unitCode.H24'),
		'unitCode.H25': __('unitCode.H25'),
		'unitCode.H26': __('unitCode.H26'),
		'unitCode.H27': __('unitCode.H27'),
		'unitCode.H28': __('unitCode.H28'),
		'unitCode.H29': __('unitCode.H29'),
		'unitCode.H30': __('unitCode.H30'),
		'unitCode.H31': __('unitCode.H31'),
		'unitCode.H32': __('unitCode.H32'),
		'unitCode.H33': __('unitCode.H33'),
		'unitCode.H34': __('unitCode.H34'),
		'unitCode.H35': __('unitCode.H35'),
		'unitCode.H36': __('unitCode.H36'),
		'unitCode.H37': __('unitCode.H37'),
		'unitCode.H38': __('unitCode.H38'),
		'unitCode.H39': __('unitCode.H39'),
		'unitCode.H40': __('unitCode.H40'),
		'unitCode.H41': __('unitCode.H41'),
		'unitCode.H42': __('unitCode.H42'),
		'unitCode.H43': __('unitCode.H43'),
		'unitCode.H44': __('unitCode.H44'),
		'unitCode.H45': __('unitCode.H45'),
		'unitCode.H46': __('unitCode.H46'),
		'unitCode.H47': __('unitCode.H47'),
		'unitCode.H48': __('unitCode.H48'),
		'unitCode.H49': __('unitCode.H49'),
		'unitCode.H50': __('unitCode.H50'),
		'unitCode.H51': __('unitCode.H51'),
		'unitCode.H52': __('unitCode.H52'),
		'unitCode.H53': __('unitCode.H53'),
		'unitCode.H54': __('unitCode.H54'),
		'unitCode.H55': __('unitCode.H55'),
		'unitCode.H56': __('unitCode.H56'),
		'unitCode.H57': __('unitCode.H57'),
		'unitCode.H58': __('unitCode.H58'),
		'unitCode.H59': __('unitCode.H59'),
		'unitCode.H60': __('unitCode.H60'),
		'unitCode.H61': __('unitCode.H61'),
		'unitCode.H62': __('unitCode.H62'),
		'unitCode.H63': __('unitCode.H63'),
		'unitCode.H64': __('unitCode.H64'),
		'unitCode.H65': __('unitCode.H65'),
		'unitCode.H66': __('unitCode.H66'),
		'unitCode.H67': __('unitCode.H67'),
		'unitCode.H68': __('unitCode.H68'),
		'unitCode.H69': __('unitCode.H69'),
		'unitCode.H70': __('unitCode.H70'),
		'unitCode.H71': __('unitCode.H71'),
		'unitCode.H72': __('unitCode.H72'),
		'unitCode.H73': __('unitCode.H73'),
		'unitCode.H74': __('unitCode.H74'),
		'unitCode.H75': __('unitCode.H75'),
		'unitCode.H76': __('unitCode.H76'),
		'unitCode.H77': __('unitCode.H77'),
		'unitCode.H79': __('unitCode.H79'),
		'unitCode.H80': __('unitCode.H80'),
		'unitCode.H81': __('unitCode.H81'),
		'unitCode.H82': __('unitCode.H82'),
		'unitCode.H83': __('unitCode.H83'),
		'unitCode.H84': __('unitCode.H84'),
		'unitCode.H85': __('unitCode.H85'),
		'unitCode.H87': __('unitCode.H87'),
		'unitCode.H88': __('unitCode.H88'),
		'unitCode.H89': __('unitCode.H89'),
		'unitCode.H90': __('unitCode.H90'),
		'unitCode.H91': __('unitCode.H91'),
		'unitCode.H92': __('unitCode.H92'),
		'unitCode.H93': __('unitCode.H93'),
		'unitCode.H94': __('unitCode.H94'),
		'unitCode.H95': __('unitCode.H95'),
		'unitCode.H96': __('unitCode.H96'),
		'unitCode.H98': __('unitCode.H98'),
		'unitCode.H99': __('unitCode.H99'),
		'unitCode.HA': __('unitCode.HA'),
		'unitCode.HBA': __('unitCode.HBA'),
		'unitCode.HBX': __('unitCode.HBX'),
		'unitCode.HC': __('unitCode.HC'),
		'unitCode.HDW': __('unitCode.HDW'),
		'unitCode.HEA': __('unitCode.HEA'),
		'unitCode.HGM': __('unitCode.HGM'),
		'unitCode.HH': __('unitCode.HH'),
		'unitCode.HIU': __('unitCode.HIU'),
		'unitCode.HKM': __('unitCode.HKM'),
		'unitCode.HLT': __('unitCode.HLT'),
		'unitCode.HM': __('unitCode.HM'),
		'unitCode.HMQ': __('unitCode.HMQ'),
		'unitCode.HMT': __('unitCode.HMT'),
		'unitCode.HPA': __('unitCode.HPA'),
		'unitCode.HTZ': __('unitCode.HTZ'),
		'unitCode.HUR': __('unitCode.HUR'),
		'unitCode.IA': __('unitCode.IA'),
		'unitCode.IE': __('unitCode.IE'),
		'unitCode.INH': __('unitCode.INH'),
		'unitCode.INK': __('unitCode.INK'),
		'unitCode.INQ': __('unitCode.INQ'),
		'unitCode.ISD': __('unitCode.ISD'),
		'unitCode.IU': __('unitCode.IU'),
		'unitCode.IUG': __('unitCode.IUG'),
		'unitCode.IV': __('unitCode.IV'),
		'unitCode.J10': __('unitCode.J10'),
		'unitCode.J12': __('unitCode.J12'),
		'unitCode.J13': __('unitCode.J13'),
		'unitCode.J14': __('unitCode.J14'),
		'unitCode.J15': __('unitCode.J15'),
		'unitCode.J16': __('unitCode.J16'),
		'unitCode.J17': __('unitCode.J17'),
		'unitCode.J18': __('unitCode.J18'),
		'unitCode.J19': __('unitCode.J19'),
		'unitCode.J2': __('unitCode.J2'),
		'unitCode.J20': __('unitCode.J20'),
		'unitCode.J21': __('unitCode.J21'),
		'unitCode.J22': __('unitCode.J22'),
		'unitCode.J23': __('unitCode.J23'),
		'unitCode.J24': __('unitCode.J24'),
		'unitCode.J25': __('unitCode.J25'),
		'unitCode.J26': __('unitCode.J26'),
		'unitCode.J27': __('unitCode.J27'),
		'unitCode.J28': __('unitCode.J28'),
		'unitCode.J29': __('unitCode.J29'),
		'unitCode.J30': __('unitCode.J30'),
		'unitCode.J31': __('unitCode.J31'),
		'unitCode.J32': __('unitCode.J32'),
		'unitCode.J33': __('unitCode.J33'),
		'unitCode.J34': __('unitCode.J34'),
		'unitCode.J35': __('unitCode.J35'),
		'unitCode.J36': __('unitCode.J36'),
		'unitCode.J38': __('unitCode.J38'),
		'unitCode.J39': __('unitCode.J39'),
		'unitCode.J40': __('unitCode.J40'),
		'unitCode.J41': __('unitCode.J41'),
		'unitCode.J42': __('unitCode.J42'),
		'unitCode.J43': __('unitCode.J43'),
		'unitCode.J44': __('unitCode.J44'),
		'unitCode.J45': __('unitCode.J45'),
		'unitCode.J46': __('unitCode.J46'),
		'unitCode.J47': __('unitCode.J47'),
		'unitCode.J48': __('unitCode.J48'),
		'unitCode.J49': __('unitCode.J49'),
		'unitCode.J50': __('unitCode.J50'),
		'unitCode.J51': __('unitCode.J51'),
		'unitCode.J52': __('unitCode.J52'),
		'unitCode.J53': __('unitCode.J53'),
		'unitCode.J54': __('unitCode.J54'),
		'unitCode.J55': __('unitCode.J55'),
		'unitCode.J56': __('unitCode.J56'),
		'unitCode.J57': __('unitCode.J57'),
		'unitCode.J58': __('unitCode.J58'),
		'unitCode.J59': __('unitCode.J59'),
		'unitCode.J60': __('unitCode.J60'),
		'unitCode.J61': __('unitCode.J61'),
		'unitCode.J62': __('unitCode.J62'),
		'unitCode.J63': __('unitCode.J63'),
		'unitCode.J64': __('unitCode.J64'),
		'unitCode.J65': __('unitCode.J65'),
		'unitCode.J66': __('unitCode.J66'),
		'unitCode.J67': __('unitCode.J67'),
		'unitCode.J68': __('unitCode.J68'),
		'unitCode.J69': __('unitCode.J69'),
		'unitCode.J70': __('unitCode.J70'),
		'unitCode.J71': __('unitCode.J71'),
		'unitCode.J72': __('unitCode.J72'),
		'unitCode.J73': __('unitCode.J73'),
		'unitCode.J74': __('unitCode.J74'),
		'unitCode.J75': __('unitCode.J75'),
		'unitCode.J76': __('unitCode.J76'),
		'unitCode.J78': __('unitCode.J78'),
		'unitCode.J79': __('unitCode.J79'),
		'unitCode.J81': __('unitCode.J81'),
		'unitCode.J82': __('unitCode.J82'),
		'unitCode.J83': __('unitCode.J83'),
		'unitCode.J84': __('unitCode.J84'),
		'unitCode.J85': __('unitCode.J85'),
		'unitCode.J87': __('unitCode.J87'),
		'unitCode.J90': __('unitCode.J90'),
		'unitCode.J91': __('unitCode.J91'),
		'unitCode.J92': __('unitCode.J92'),
		'unitCode.J93': __('unitCode.J93'),
		'unitCode.J95': __('unitCode.J95'),
		'unitCode.J96': __('unitCode.J96'),
		'unitCode.J97': __('unitCode.J97'),
		'unitCode.J98': __('unitCode.J98'),
		'unitCode.J99': __('unitCode.J99'),
		'unitCode.JE': __('unitCode.JE'),
		'unitCode.JK': __('unitCode.JK'),
		'unitCode.JM': __('unitCode.JM'),
		'unitCode.JNT': __('unitCode.JNT'),
		'unitCode.JOU': __('unitCode.JOU'),
		'unitCode.JPS': __('unitCode.JPS'),
		'unitCode.JWL': __('unitCode.JWL'),
		'unitCode.K1': __('unitCode.K1'),
		'unitCode.K10': __('unitCode.K10'),
		'unitCode.K11': __('unitCode.K11'),
		'unitCode.K12': __('unitCode.K12'),
		'unitCode.K13': __('unitCode.K13'),
		'unitCode.K14': __('unitCode.K14'),
		'unitCode.K15': __('unitCode.K15'),
		'unitCode.K16': __('unitCode.K16'),
		'unitCode.K17': __('unitCode.K17'),
		'unitCode.K18': __('unitCode.K18'),
		'unitCode.K19': __('unitCode.K19'),
		'unitCode.K2': __('unitCode.K2'),
		'unitCode.K20': __('unitCode.K20'),
		'unitCode.K21': __('unitCode.K21'),
		'unitCode.K22': __('unitCode.K22'),
		'unitCode.K23': __('unitCode.K23'),
		'unitCode.K26': __('unitCode.K26'),
		'unitCode.K27': __('unitCode.K27'),
		'unitCode.K28': __('unitCode.K28'),
		'unitCode.K3': __('unitCode.K3'),
		'unitCode.K30': __('unitCode.K30'),
		'unitCode.K31': __('unitCode.K31'),
		'unitCode.K32': __('unitCode.K32'),
		'unitCode.K33': __('unitCode.K33'),
		'unitCode.K34': __('unitCode.K34'),
		'unitCode.K35': __('unitCode.K35'),
		'unitCode.K36': __('unitCode.K36'),
		'unitCode.K37': __('unitCode.K37'),
		'unitCode.K38': __('unitCode.K38'),
		'unitCode.K39': __('unitCode.K39'),
		'unitCode.K40': __('unitCode.K40'),
		'unitCode.K41': __('unitCode.K41'),
		'unitCode.K42': __('unitCode.K42'),
		'unitCode.K43': __('unitCode.K43'),
		'unitCode.K45': __('unitCode.K45'),
		'unitCode.K46': __('unitCode.K46'),
		'unitCode.K47': __('unitCode.K47'),
		'unitCode.K48': __('unitCode.K48'),
		'unitCode.K49': __('unitCode.K49'),
		'unitCode.K50': __('unitCode.K50'),
		'unitCode.K51': __('unitCode.K51'),
		'unitCode.K52': __('unitCode.K52'),
		'unitCode.K53': __('unitCode.K53'),
		'unitCode.K54': __('unitCode.K54'),
		'unitCode.K55': __('unitCode.K55'),
		'unitCode.K58': __('unitCode.K58'),
		'unitCode.K59': __('unitCode.K59'),
		'unitCode.K6': __('unitCode.K6'),
		'unitCode.K60': __('unitCode.K60'),
		'unitCode.K61': __('unitCode.K61'),
		'unitCode.K62': __('unitCode.K62'),
		'unitCode.K63': __('unitCode.K63'),
		'unitCode.K64': __('unitCode.K64'),
		'unitCode.K65': __('unitCode.K65'),
		'unitCode.K66': __('unitCode.K66'),
		'unitCode.K67': __('unitCode.K67'),
		'unitCode.K68': __('unitCode.K68'),
		'unitCode.K69': __('unitCode.K69'),
		'unitCode.K70': __('unitCode.K70'),
		'unitCode.K71': __('unitCode.K71'),
		'unitCode.K73': __('unitCode.K73'),
		'unitCode.K74': __('unitCode.K74'),
		'unitCode.K75': __('unitCode.K75'),
		'unitCode.K76': __('unitCode.K76'),
		'unitCode.K77': __('unitCode.K77'),
		'unitCode.K78': __('unitCode.K78'),
		'unitCode.K79': __('unitCode.K79'),
		'unitCode.K80': __('unitCode.K80'),
		'unitCode.K81': __('unitCode.K81'),
		'unitCode.K82': __('unitCode.K82'),
		'unitCode.K83': __('unitCode.K83'),
		'unitCode.K84': __('unitCode.K84'),
		'unitCode.K85': __('unitCode.K85'),
		'unitCode.K86': __('unitCode.K86'),
		'unitCode.K87': __('unitCode.K87'),
		'unitCode.K88': __('unitCode.K88'),
		'unitCode.K89': __('unitCode.K89'),
		'unitCode.K90': __('unitCode.K90'),
		'unitCode.K91': __('unitCode.K91'),
		'unitCode.K92': __('unitCode.K92'),
		'unitCode.K93': __('unitCode.K93'),
		'unitCode.K94': __('unitCode.K94'),
		'unitCode.K95': __('unitCode.K95'),
		'unitCode.K96': __('unitCode.K96'),
		'unitCode.K97': __('unitCode.K97'),
		'unitCode.K98': __('unitCode.K98'),
		'unitCode.K99': __('unitCode.K99'),
		'unitCode.KA': __('unitCode.KA'),
		'unitCode.KAT': __('unitCode.KAT'),
		'unitCode.KB': __('unitCode.KB'),
		'unitCode.KBA': __('unitCode.KBA'),
		'unitCode.KCC': __('unitCode.KCC'),
		'unitCode.KDW': __('unitCode.KDW'),
		'unitCode.KEL': __('unitCode.KEL'),
		'unitCode.KGM': __('unitCode.KGM'),
		'unitCode.KGS': __('unitCode.KGS'),
		'unitCode.KHY': __('unitCode.KHY'),
		'unitCode.KHZ': __('unitCode.KHZ'),
		'unitCode.KI': __('unitCode.KI'),
		'unitCode.KIC': __('unitCode.KIC'),
		'unitCode.KIP': __('unitCode.KIP'),
		'unitCode.KJ': __('unitCode.KJ'),
		'unitCode.KJO': __('unitCode.KJO'),
		'unitCode.KL': __('unitCode.KL'),
		'unitCode.KLK': __('unitCode.KLK'),
		'unitCode.KLX': __('unitCode.KLX'),
		'unitCode.KMA': __('unitCode.KMA'),
		'unitCode.KMH': __('unitCode.KMH'),
		'unitCode.KMK': __('unitCode.KMK'),
		'unitCode.KMQ': __('unitCode.KMQ'),
		'unitCode.KMT': __('unitCode.KMT'),
		'unitCode.KNI': __('unitCode.KNI'),
		'unitCode.KNM': __('unitCode.KNM'),
		'unitCode.KNS': __('unitCode.KNS'),
		'unitCode.KNT': __('unitCode.KNT'),
		'unitCode.KO': __('unitCode.KO'),
		'unitCode.KPA': __('unitCode.KPA'),
		'unitCode.KPH': __('unitCode.KPH'),
		'unitCode.KPO': __('unitCode.KPO'),
		'unitCode.KPP': __('unitCode.KPP'),
		'unitCode.KR': __('unitCode.KR'),
		'unitCode.KSD': __('unitCode.KSD'),
		'unitCode.KSH': __('unitCode.KSH'),
		'unitCode.KT': __('unitCode.KT'),
		'unitCode.KTN': __('unitCode.KTN'),
		'unitCode.KUR': __('unitCode.KUR'),
		'unitCode.KVA': __('unitCode.KVA'),
		'unitCode.KVR': __('unitCode.KVR'),
		'unitCode.KVT': __('unitCode.KVT'),
		'unitCode.KW': __('unitCode.KW'),
		'unitCode.KWH': __('unitCode.KWH'),
		'unitCode.KWN': __('unitCode.KWN'),
		'unitCode.KWO': __('unitCode.KWO'),
		'unitCode.KWS': __('unitCode.KWS'),
		'unitCode.KWT': __('unitCode.KWT'),
		'unitCode.KX': __('unitCode.KX'),
		'unitCode.L10': __('unitCode.L10'),
		'unitCode.L11': __('unitCode.L11'),
		'unitCode.L12': __('unitCode.L12'),
		'unitCode.L13': __('unitCode.L13'),
		'unitCode.L14': __('unitCode.L14'),
		'unitCode.L15': __('unitCode.L15'),
		'unitCode.L16': __('unitCode.L16'),
		'unitCode.L17': __('unitCode.L17'),
		'unitCode.L18': __('unitCode.L18'),
		'unitCode.L19': __('unitCode.L19'),
		'unitCode.L2': __('unitCode.L2'),
		'unitCode.L20': __('unitCode.L20'),
		'unitCode.L21': __('unitCode.L21'),
		'unitCode.L23': __('unitCode.L23'),
		'unitCode.L24': __('unitCode.L24'),
		'unitCode.L25': __('unitCode.L25'),
		'unitCode.L26': __('unitCode.L26'),
		'unitCode.L27': __('unitCode.L27'),
		'unitCode.L28': __('unitCode.L28'),
		'unitCode.L29': __('unitCode.L29'),
		'unitCode.L30': __('unitCode.L30'),
		'unitCode.L31': __('unitCode.L31'),
		'unitCode.L32': __('unitCode.L32'),
		'unitCode.L33': __('unitCode.L33'),
		'unitCode.L34': __('unitCode.L34'),
		'unitCode.L35': __('unitCode.L35'),
		'unitCode.L36': __('unitCode.L36'),
		'unitCode.L37': __('unitCode.L37'),
		'unitCode.L38': __('unitCode.L38'),
		'unitCode.L39': __('unitCode.L39'),
		'unitCode.L40': __('unitCode.L40'),
		'unitCode.L41': __('unitCode.L41'),
		'unitCode.L42': __('unitCode.L42'),
		'unitCode.L43': __('unitCode.L43'),
		'unitCode.L44': __('unitCode.L44'),
		'unitCode.L45': __('unitCode.L45'),
		'unitCode.L46': __('unitCode.L46'),
		'unitCode.L47': __('unitCode.L47'),
		'unitCode.L48': __('unitCode.L48'),
		'unitCode.L49': __('unitCode.L49'),
		'unitCode.L50': __('unitCode.L50'),
		'unitCode.L51': __('unitCode.L51'),
		'unitCode.L52': __('unitCode.L52'),
		'unitCode.L53': __('unitCode.L53'),
		'unitCode.L54': __('unitCode.L54'),
		'unitCode.L55': __('unitCode.L55'),
		'unitCode.L56': __('unitCode.L56'),
		'unitCode.L57': __('unitCode.L57'),
		'unitCode.L58': __('unitCode.L58'),
		'unitCode.L59': __('unitCode.L59'),
		'unitCode.L60': __('unitCode.L60'),
		'unitCode.L63': __('unitCode.L63'),
		'unitCode.L64': __('unitCode.L64'),
		'unitCode.L65': __('unitCode.L65'),
		'unitCode.L66': __('unitCode.L66'),
		'unitCode.L67': __('unitCode.L67'),
		'unitCode.L68': __('unitCode.L68'),
		'unitCode.L69': __('unitCode.L69'),
		'unitCode.L70': __('unitCode.L70'),
		'unitCode.L71': __('unitCode.L71'),
		'unitCode.L72': __('unitCode.L72'),
		'unitCode.L73': __('unitCode.L73'),
		'unitCode.L74': __('unitCode.L74'),
		'unitCode.L75': __('unitCode.L75'),
		'unitCode.L76': __('unitCode.L76'),
		'unitCode.L77': __('unitCode.L77'),
		'unitCode.L78': __('unitCode.L78'),
		'unitCode.L79': __('unitCode.L79'),
		'unitCode.L80': __('unitCode.L80'),
		'unitCode.L81': __('unitCode.L81'),
		'unitCode.L82': __('unitCode.L82'),
		'unitCode.L83': __('unitCode.L83'),
		'unitCode.L84': __('unitCode.L84'),
		'unitCode.L85': __('unitCode.L85'),
		'unitCode.L86': __('unitCode.L86'),
		'unitCode.L87': __('unitCode.L87'),
		'unitCode.L88': __('unitCode.L88'),
		'unitCode.L89': __('unitCode.L89'),
		'unitCode.L90': __('unitCode.L90'),
		'unitCode.L91': __('unitCode.L91'),
		'unitCode.L92': __('unitCode.L92'),
		'unitCode.L93': __('unitCode.L93'),
		'unitCode.L94': __('unitCode.L94'),
		'unitCode.L95': __('unitCode.L95'),
		'unitCode.L96': __('unitCode.L96'),
		'unitCode.L98': __('unitCode.L98'),
		'unitCode.L99': __('unitCode.L99'),
		'unitCode.LA': __('unitCode.LA'),
		'unitCode.LAC': __('unitCode.LAC'),
		'unitCode.LBR': __('unitCode.LBR'),
		'unitCode.LBT': __('unitCode.LBT'),
		'unitCode.LD': __('unitCode.LD'),
		'unitCode.LEF': __('unitCode.LEF'),
		'unitCode.LF': __('unitCode.LF'),
		'unitCode.LH': __('unitCode.LH'),
		'unitCode.LK': __('unitCode.LK'),
		'unitCode.LM': __('unitCode.LM'),
		'unitCode.LN': __('unitCode.LN'),
		'unitCode.LO': __('unitCode.LO'),
		'unitCode.LP': __('unitCode.LP'),
		'unitCode.LPA': __('unitCode.LPA'),
		'unitCode.LR': __('unitCode.LR'),
		'unitCode.LS': __('unitCode.LS'),
		'unitCode.LTN': __('unitCode.LTN'),
		'unitCode.LTR': __('unitCode.LTR'),
		'unitCode.LUB': __('unitCode.LUB'),
		'unitCode.LUM': __('unitCode.LUM'),
		'unitCode.LUX': __('unitCode.LUX'),
		'unitCode.LY': __('unitCode.LY'),
		'unitCode.M1': __('unitCode.M1'),
		'unitCode.M10': __('unitCode.M10'),
		'unitCode.M11': __('unitCode.M11'),
		'unitCode.M12': __('unitCode.M12'),
		'unitCode.M13': __('unitCode.M13'),
		'unitCode.M14': __('unitCode.M14'),
		'unitCode.M15': __('unitCode.M15'),
		'unitCode.M16': __('unitCode.M16'),
		'unitCode.M17': __('unitCode.M17'),
		'unitCode.M18': __('unitCode.M18'),
		'unitCode.M19': __('unitCode.M19'),
		'unitCode.M20': __('unitCode.M20'),
		'unitCode.M21': __('unitCode.M21'),
		'unitCode.M22': __('unitCode.M22'),
		'unitCode.M23': __('unitCode.M23'),
		'unitCode.M24': __('unitCode.M24'),
		'unitCode.M25': __('unitCode.M25'),
		'unitCode.M26': __('unitCode.M26'),
		'unitCode.M27': __('unitCode.M27'),
		'unitCode.M29': __('unitCode.M29'),
		'unitCode.M30': __('unitCode.M30'),
		'unitCode.M31': __('unitCode.M31'),
		'unitCode.M32': __('unitCode.M32'),
		'unitCode.M33': __('unitCode.M33'),
		'unitCode.M34': __('unitCode.M34'),
		'unitCode.M35': __('unitCode.M35'),
		'unitCode.M36': __('unitCode.M36'),
		'unitCode.M37': __('unitCode.M37'),
		'unitCode.M38': __('unitCode.M38'),
		'unitCode.M39': __('unitCode.M39'),
		'unitCode.M4': __('unitCode.M4'),
		'unitCode.M40': __('unitCode.M40'),
		'unitCode.M41': __('unitCode.M41'),
		'unitCode.M42': __('unitCode.M42'),
		'unitCode.M43': __('unitCode.M43'),
		'unitCode.M44': __('unitCode.M44'),
		'unitCode.M45': __('unitCode.M45'),
		'unitCode.M46': __('unitCode.M46'),
		'unitCode.M47': __('unitCode.M47'),
		'unitCode.M48': __('unitCode.M48'),
		'unitCode.M49': __('unitCode.M49'),
		'unitCode.M5': __('unitCode.M5'),
		'unitCode.M50': __('unitCode.M50'),
		'unitCode.M51': __('unitCode.M51'),
		'unitCode.M52': __('unitCode.M52'),
		'unitCode.M53': __('unitCode.M53'),
		'unitCode.M55': __('unitCode.M55'),
		'unitCode.M56': __('unitCode.M56'),
		'unitCode.M57': __('unitCode.M57'),
		'unitCode.M58': __('unitCode.M58'),
		'unitCode.M59': __('unitCode.M59'),
		'unitCode.M60': __('unitCode.M60'),
		'unitCode.M61': __('unitCode.M61'),
		'unitCode.M62': __('unitCode.M62'),
		'unitCode.M63': __('unitCode.M63'),
		'unitCode.M64': __('unitCode.M64'),
		'unitCode.M65': __('unitCode.M65'),
		'unitCode.M66': __('unitCode.M66'),
		'unitCode.M67': __('unitCode.M67'),
		'unitCode.M68': __('unitCode.M68'),
		'unitCode.M69': __('unitCode.M69'),
		'unitCode.M7': __('unitCode.M7'),
		'unitCode.M70': __('unitCode.M70'),
		'unitCode.M71': __('unitCode.M71'),
		'unitCode.M72': __('unitCode.M72'),
		'unitCode.M73': __('unitCode.M73'),
		'unitCode.M74': __('unitCode.M74'),
		'unitCode.M75': __('unitCode.M75'),
		'unitCode.M76': __('unitCode.M76'),
		'unitCode.M77': __('unitCode.M77'),
		'unitCode.M78': __('unitCode.M78'),
		'unitCode.M79': __('unitCode.M79'),
		'unitCode.M80': __('unitCode.M80'),
		'unitCode.M81': __('unitCode.M81'),
		'unitCode.M82': __('unitCode.M82'),
		'unitCode.M83': __('unitCode.M83'),
		'unitCode.M84': __('unitCode.M84'),
		'unitCode.M85': __('unitCode.M85'),
		'unitCode.M86': __('unitCode.M86'),
		'unitCode.M87': __('unitCode.M87'),
		'unitCode.M88': __('unitCode.M88'),
		'unitCode.M89': __('unitCode.M89'),
		'unitCode.M9': __('unitCode.M9'),
		'unitCode.M90': __('unitCode.M90'),
		'unitCode.M91': __('unitCode.M91'),
		'unitCode.M92': __('unitCode.M92'),
		'unitCode.M93': __('unitCode.M93'),
		'unitCode.M94': __('unitCode.M94'),
		'unitCode.M95': __('unitCode.M95'),
		'unitCode.M96': __('unitCode.M96'),
		'unitCode.M97': __('unitCode.M97'),
		'unitCode.M98': __('unitCode.M98'),
		'unitCode.M99': __('unitCode.M99'),
		'unitCode.MAH': __('unitCode.MAH'),
		'unitCode.MAL': __('unitCode.MAL'),
		'unitCode.MAM': __('unitCode.MAM'),
		'unitCode.MAR': __('unitCode.MAR'),
		'unitCode.MAW': __('unitCode.MAW'),
		'unitCode.MBE': __('unitCode.MBE'),
		'unitCode.MBF': __('unitCode.MBF'),
		'unitCode.MBR': __('unitCode.MBR'),
		'unitCode.MC': __('unitCode.MC'),
		'unitCode.MCU': __('unitCode.MCU'),
		'unitCode.MD': __('unitCode.MD'),
		'unitCode.MGM': __('unitCode.MGM'),
		'unitCode.MHZ': __('unitCode.MHZ'),
		'unitCode.MIK': __('unitCode.MIK'),
		'unitCode.MIL': __('unitCode.MIL'),
		'unitCode.MIN': __('unitCode.MIN'),
		'unitCode.MIO': __('unitCode.MIO'),
		'unitCode.MIU': __('unitCode.MIU'),
		'unitCode.MLD': __('unitCode.MLD'),
		'unitCode.MLT': __('unitCode.MLT'),
		'unitCode.MMK': __('unitCode.MMK'),
		'unitCode.MMQ': __('unitCode.MMQ'),
		'unitCode.MMT': __('unitCode.MMT'),
		'unitCode.MND': __('unitCode.MND'),
		'unitCode.MON': __('unitCode.MON'),
		'unitCode.MPA': __('unitCode.MPA'),
		'unitCode.MQH': __('unitCode.MQH'),
		'unitCode.MQS': __('unitCode.MQS'),
		'unitCode.MSK': __('unitCode.MSK'),
		'unitCode.MTK': __('unitCode.MTK'),
		'unitCode.MTQ': __('unitCode.MTQ'),
		'unitCode.MTR': __('unitCode.MTR'),
		'unitCode.MTS': __('unitCode.MTS'),
		'unitCode.MVA': __('unitCode.MVA'),
		'unitCode.MWH': __('unitCode.MWH'),
		'unitCode.N1': __('unitCode.N1'),
		'unitCode.N10': __('unitCode.N10'),
		'unitCode.N11': __('unitCode.N11'),
		'unitCode.N12': __('unitCode.N12'),
		'unitCode.N13': __('unitCode.N13'),
		'unitCode.N14': __('unitCode.N14'),
		'unitCode.N15': __('unitCode.N15'),
		'unitCode.N16': __('unitCode.N16'),
		'unitCode.N17': __('unitCode.N17'),
		'unitCode.N18': __('unitCode.N18'),
		'unitCode.N19': __('unitCode.N19'),
		'unitCode.N20': __('unitCode.N20'),
		'unitCode.N21': __('unitCode.N21'),
		'unitCode.N22': __('unitCode.N22'),
		'unitCode.N23': __('unitCode.N23'),
		'unitCode.N24': __('unitCode.N24'),
		'unitCode.N25': __('unitCode.N25'),
		'unitCode.N26': __('unitCode.N26'),
		'unitCode.N27': __('unitCode.N27'),
		'unitCode.N28': __('unitCode.N28'),
		'unitCode.N29': __('unitCode.N29'),
		'unitCode.N3': __('unitCode.N3'),
		'unitCode.N30': __('unitCode.N30'),
		'unitCode.N31': __('unitCode.N31'),
		'unitCode.N32': __('unitCode.N32'),
		'unitCode.N33': __('unitCode.N33'),
		'unitCode.N34': __('unitCode.N34'),
		'unitCode.N35': __('unitCode.N35'),
		'unitCode.N36': __('unitCode.N36'),
		'unitCode.N37': __('unitCode.N37'),
		'unitCode.N38': __('unitCode.N38'),
		'unitCode.N39': __('unitCode.N39'),
		'unitCode.N40': __('unitCode.N40'),
		'unitCode.N41': __('unitCode.N41'),
		'unitCode.N42': __('unitCode.N42'),
		'unitCode.N43': __('unitCode.N43'),
		'unitCode.N44': __('unitCode.N44'),
		'unitCode.N45': __('unitCode.N45'),
		'unitCode.N46': __('unitCode.N46'),
		'unitCode.N47': __('unitCode.N47'),
		'unitCode.N48': __('unitCode.N48'),
		'unitCode.N49': __('unitCode.N49'),
		'unitCode.N50': __('unitCode.N50'),
		'unitCode.N51': __('unitCode.N51'),
		'unitCode.N52': __('unitCode.N52'),
		'unitCode.N53': __('unitCode.N53'),
		'unitCode.N54': __('unitCode.N54'),
		'unitCode.N55': __('unitCode.N55'),
		'unitCode.N56': __('unitCode.N56'),
		'unitCode.N57': __('unitCode.N57'),
		'unitCode.N58': __('unitCode.N58'),
		'unitCode.N59': __('unitCode.N59'),
		'unitCode.N60': __('unitCode.N60'),
		'unitCode.N61': __('unitCode.N61'),
		'unitCode.N62': __('unitCode.N62'),
		'unitCode.N63': __('unitCode.N63'),
		'unitCode.N64': __('unitCode.N64'),
		'unitCode.N65': __('unitCode.N65'),
		'unitCode.N66': __('unitCode.N66'),
		'unitCode.N67': __('unitCode.N67'),
		'unitCode.N68': __('unitCode.N68'),
		'unitCode.N69': __('unitCode.N69'),
		'unitCode.N70': __('unitCode.N70'),
		'unitCode.N71': __('unitCode.N71'),
		'unitCode.N72': __('unitCode.N72'),
		'unitCode.N73': __('unitCode.N73'),
		'unitCode.N74': __('unitCode.N74'),
		'unitCode.N75': __('unitCode.N75'),
		'unitCode.N76': __('unitCode.N76'),
		'unitCode.N77': __('unitCode.N77'),
		'unitCode.N78': __('unitCode.N78'),
		'unitCode.N79': __('unitCode.N79'),
		'unitCode.N80': __('unitCode.N80'),
		'unitCode.N81': __('unitCode.N81'),
		'unitCode.N82': __('unitCode.N82'),
		'unitCode.N83': __('unitCode.N83'),
		'unitCode.N84': __('unitCode.N84'),
		'unitCode.N85': __('unitCode.N85'),
		'unitCode.N86': __('unitCode.N86'),
		'unitCode.N87': __('unitCode.N87'),
		'unitCode.N88': __('unitCode.N88'),
		'unitCode.N89': __('unitCode.N89'),
		'unitCode.N90': __('unitCode.N90'),
		'unitCode.N91': __('unitCode.N91'),
		'unitCode.N92': __('unitCode.N92'),
		'unitCode.N93': __('unitCode.N93'),
		'unitCode.N94': __('unitCode.N94'),
		'unitCode.N95': __('unitCode.N95'),
		'unitCode.N96': __('unitCode.N96'),
		'unitCode.N97': __('unitCode.N97'),
		'unitCode.N98': __('unitCode.N98'),
		'unitCode.N99': __('unitCode.N99'),
		'unitCode.NA': __('unitCode.NA'),
		'unitCode.NAR': __('unitCode.NAR'),
		'unitCode.NCL': __('unitCode.NCL'),
		'unitCode.NEW': __('unitCode.NEW'),
		'unitCode.NF': __('unitCode.NF'),
		'unitCode.NIL': __('unitCode.NIL'),
		'unitCode.NIU': __('unitCode.NIU'),
		'unitCode.NL': __('unitCode.NL'),
		'unitCode.NM3': __('unitCode.NM3'),
		'unitCode.NMI': __('unitCode.NMI'),
		'unitCode.NMP': __('unitCode.NMP'),
		'unitCode.NPT': __('unitCode.NPT'),
		'unitCode.NT': __('unitCode.NT'),
		'unitCode.NU': __('unitCode.NU'),
		'unitCode.NX': __('unitCode.NX'),
		'unitCode.OA': __('unitCode.OA'),
		'unitCode.ODE': __('unitCode.ODE'),
		'unitCode.ODG': __('unitCode.ODG'),
		'unitCode.ODK': __('unitCode.ODK'),
		'unitCode.ODM': __('unitCode.ODM'),
		'unitCode.OHM': __('unitCode.OHM'),
		'unitCode.ON': __('unitCode.ON'),
		'unitCode.ONZ': __('unitCode.ONZ'),
		'unitCode.OPM': __('unitCode.OPM'),
		'unitCode.OT': __('unitCode.OT'),
		'unitCode.OZA': __('unitCode.OZA'),
		'unitCode.OZI': __('unitCode.OZI'),
		'unitCode.P1': __('unitCode.P1'),
		'unitCode.P10': __('unitCode.P10'),
		'unitCode.P11': __('unitCode.P11'),
		'unitCode.P12': __('unitCode.P12'),
		'unitCode.P13': __('unitCode.P13'),
		'unitCode.P14': __('unitCode.P14'),
		'unitCode.P15': __('unitCode.P15'),
		'unitCode.P16': __('unitCode.P16'),
		'unitCode.P17': __('unitCode.P17'),
		'unitCode.P18': __('unitCode.P18'),
		'unitCode.P19': __('unitCode.P19'),
		'unitCode.P2': __('unitCode.P2'),
		'unitCode.P20': __('unitCode.P20'),
		'unitCode.P21': __('unitCode.P21'),
		'unitCode.P22': __('unitCode.P22'),
		'unitCode.P23': __('unitCode.P23'),
		'unitCode.P24': __('unitCode.P24'),
		'unitCode.P25': __('unitCode.P25'),
		'unitCode.P26': __('unitCode.P26'),
		'unitCode.P27': __('unitCode.P27'),
		'unitCode.P28': __('unitCode.P28'),
		'unitCode.P29': __('unitCode.P29'),
		'unitCode.P30': __('unitCode.P30'),
		'unitCode.P31': __('unitCode.P31'),
		'unitCode.P32': __('unitCode.P32'),
		'unitCode.P33': __('unitCode.P33'),
		'unitCode.P34': __('unitCode.P34'),
		'unitCode.P35': __('unitCode.P35'),
		'unitCode.P36': __('unitCode.P36'),
		'unitCode.P37': __('unitCode.P37'),
		'unitCode.P38': __('unitCode.P38'),
		'unitCode.P39': __('unitCode.P39'),
		'unitCode.P40': __('unitCode.P40'),
		'unitCode.P41': __('unitCode.P41'),
		'unitCode.P42': __('unitCode.P42'),
		'unitCode.P43': __('unitCode.P43'),
		'unitCode.P44': __('unitCode.P44'),
		'unitCode.P45': __('unitCode.P45'),
		'unitCode.P46': __('unitCode.P46'),
		'unitCode.P47': __('unitCode.P47'),
		'unitCode.P48': __('unitCode.P48'),
		'unitCode.P49': __('unitCode.P49'),
		'unitCode.P5': __('unitCode.P5'),
		'unitCode.P50': __('unitCode.P50'),
		'unitCode.P51': __('unitCode.P51'),
		'unitCode.P52': __('unitCode.P52'),
		'unitCode.P53': __('unitCode.P53'),
		'unitCode.P54': __('unitCode.P54'),
		'unitCode.P55': __('unitCode.P55'),
		'unitCode.P56': __('unitCode.P56'),
		'unitCode.P57': __('unitCode.P57'),
		'unitCode.P58': __('unitCode.P58'),
		'unitCode.P59': __('unitCode.P59'),
		'unitCode.P60': __('unitCode.P60'),
		'unitCode.P61': __('unitCode.P61'),
		'unitCode.P62': __('unitCode.P62'),
		'unitCode.P63': __('unitCode.P63'),
		'unitCode.P64': __('unitCode.P64'),
		'unitCode.P65': __('unitCode.P65'),
		'unitCode.P66': __('unitCode.P66'),
		'unitCode.P67': __('unitCode.P67'),
		'unitCode.P68': __('unitCode.P68'),
		'unitCode.P69': __('unitCode.P69'),
		'unitCode.P70': __('unitCode.P70'),
		'unitCode.P71': __('unitCode.P71'),
		'unitCode.P72': __('unitCode.P72'),
		'unitCode.P73': __('unitCode.P73'),
		'unitCode.P74': __('unitCode.P74'),
		'unitCode.P75': __('unitCode.P75'),
		'unitCode.P76': __('unitCode.P76'),
		'unitCode.P77': __('unitCode.P77'),
		'unitCode.P78': __('unitCode.P78'),
		'unitCode.P79': __('unitCode.P79'),
		'unitCode.P80': __('unitCode.P80'),
		'unitCode.P81': __('unitCode.P81'),
		'unitCode.P82': __('unitCode.P82'),
		'unitCode.P83': __('unitCode.P83'),
		'unitCode.P84': __('unitCode.P84'),
		'unitCode.P85': __('unitCode.P85'),
		'unitCode.P86': __('unitCode.P86'),
		'unitCode.P87': __('unitCode.P87'),
		'unitCode.P88': __('unitCode.P88'),
		'unitCode.P89': __('unitCode.P89'),
		'unitCode.P90': __('unitCode.P90'),
		'unitCode.P91': __('unitCode.P91'),
		'unitCode.P92': __('unitCode.P92'),
		'unitCode.P93': __('unitCode.P93'),
		'unitCode.P94': __('unitCode.P94'),
		'unitCode.P95': __('unitCode.P95'),
		'unitCode.P96': __('unitCode.P96'),
		'unitCode.P97': __('unitCode.P97'),
		'unitCode.P98': __('unitCode.P98'),
		'unitCode.P99': __('unitCode.P99'),
		'unitCode.PAL': __('unitCode.PAL'),
		'unitCode.PD': __('unitCode.PD'),
		'unitCode.PFL': __('unitCode.PFL'),
		'unitCode.PGL': __('unitCode.PGL'),
		'unitCode.PI': __('unitCode.PI'),
		'unitCode.PLA': __('unitCode.PLA'),
		'unitCode.PO': __('unitCode.PO'),
		'unitCode.PQ': __('unitCode.PQ'),
		'unitCode.PR': __('unitCode.PR'),
		'unitCode.PS': __('unitCode.PS'),
		'unitCode.PTD': __('unitCode.PTD'),
		'unitCode.PTI': __('unitCode.PTI'),
		'unitCode.PTL': __('unitCode.PTL'),
		'unitCode.PTN': __('unitCode.PTN'),
		'unitCode.Q10': __('unitCode.Q10'),
		'unitCode.Q11': __('unitCode.Q11'),
		'unitCode.Q12': __('unitCode.Q12'),
		'unitCode.Q13': __('unitCode.Q13'),
		'unitCode.Q14': __('unitCode.Q14'),
		'unitCode.Q15': __('unitCode.Q15'),
		'unitCode.Q16': __('unitCode.Q16'),
		'unitCode.Q17': __('unitCode.Q17'),
		'unitCode.Q18': __('unitCode.Q18'),
		'unitCode.Q19': __('unitCode.Q19'),
		'unitCode.Q20': __('unitCode.Q20'),
		'unitCode.Q21': __('unitCode.Q21'),
		'unitCode.Q22': __('unitCode.Q22'),
		'unitCode.Q23': __('unitCode.Q23'),
		'unitCode.Q24': __('unitCode.Q24'),
		'unitCode.Q25': __('unitCode.Q25'),
		'unitCode.Q26': __('unitCode.Q26'),
		'unitCode.Q27': __('unitCode.Q27'),
		'unitCode.Q28': __('unitCode.Q28'),
		'unitCode.Q29': __('unitCode.Q29'),
		'unitCode.Q30': __('unitCode.Q30'),
		'unitCode.Q31': __('unitCode.Q31'),
		'unitCode.Q32': __('unitCode.Q32'),
		'unitCode.Q33': __('unitCode.Q33'),
		'unitCode.Q34': __('unitCode.Q34'),
		'unitCode.Q35': __('unitCode.Q35'),
		'unitCode.Q36': __('unitCode.Q36'),
		'unitCode.Q37': __('unitCode.Q37'),
		'unitCode.Q38': __('unitCode.Q38'),
		'unitCode.Q39': __('unitCode.Q39'),
		'unitCode.Q40': __('unitCode.Q40'),
		'unitCode.Q41': __('unitCode.Q41'),
		'unitCode.Q42': __('unitCode.Q42'),
		'unitCode.Q3': __('unitCode.Q3'),
		'unitCode.QA': __('unitCode.QA'),
		'unitCode.QAN': __('unitCode.QAN'),
		'unitCode.QB': __('unitCode.QB'),
		'unitCode.QR': __('unitCode.QR'),
		'unitCode.QTD': __('unitCode.QTD'),
		'unitCode.QTI': __('unitCode.QTI'),
		'unitCode.QTL': __('unitCode.QTL'),
		'unitCode.QTR': __('unitCode.QTR'),
		'unitCode.R1': __('unitCode.R1'),
		'unitCode.R9': __('unitCode.R9'),
		'unitCode.RH': __('unitCode.RH'),
		'unitCode.RM': __('unitCode.RM'),
		'unitCode.ROM': __('unitCode.ROM'),
		'unitCode.RP': __('unitCode.RP'),
		'unitCode.RPM': __('unitCode.RPM'),
		'unitCode.RPS': __('unitCode.RPS'),
		'unitCode.RT': __('unitCode.RT'),
		'unitCode.S3': __('unitCode.S3'),
		'unitCode.S4': __('unitCode.S4'),
		'unitCode.SAN': __('unitCode.SAN'),
		'unitCode.SCO': __('unitCode.SCO'),
		'unitCode.SCR': __('unitCode.SCR'),
		'unitCode.SEC': __('unitCode.SEC'),
		'unitCode.SET': __('unitCode.SET'),
		'unitCode.SG': __('unitCode.SG'),
		'unitCode.SIE': __('unitCode.SIE'),
		'unitCode.SM3': __('unitCode.SM3'),
		'unitCode.SMI': __('unitCode.SMI'),
		'unitCode.SQ': __('unitCode.SQ'),
		'unitCode.SQR': __('unitCode.SQR'),
		'unitCode.SR': __('unitCode.SR'),
		'unitCode.STC': __('unitCode.STC'),
		'unitCode.STI': __('unitCode.STI'),
		'unitCode.STK': __('unitCode.STK'),
		'unitCode.STL': __('unitCode.STL'),
		'unitCode.STN': __('unitCode.STN'),
		'unitCode.STW': __('unitCode.STW'),
		'unitCode.SW': __('unitCode.SW'),
		'unitCode.SX': __('unitCode.SX'),
		'unitCode.SYR': __('unitCode.SYR'),
		'unitCode.T0': __('unitCode.T0'),
		'unitCode.T3': __('unitCode.T3'),
		'unitCode.TAH': __('unitCode.TAH'),
		'unitCode.TAN': __('unitCode.TAN'),
		'unitCode.TI': __('unitCode.TI'),
		'unitCode.TIC': __('unitCode.TIC'),
		'unitCode.TIP': __('unitCode.TIP'),
		'unitCode.TKM': __('unitCode.TKM'),
		'unitCode.TMS': __('unitCode.TMS'),
		'unitCode.TNE': __('unitCode.TNE'),
		'unitCode.TP': __('unitCode.TP'),
		'unitCode.TPI': __('unitCode.TPI'),
		'unitCode.TPR': __('unitCode.TPR'),
		'unitCode.TQD': __('unitCode.TQD'),
		'unitCode.TRL': __('unitCode.TRL'),
		'unitCode.TST': __('unitCode.TST'),
		'unitCode.TTS': __('unitCode.TTS'),
		'unitCode.U1': __('unitCode.U1'),
		'unitCode.U2': __('unitCode.U2'),
		'unitCode.UB': __('unitCode.UB'),
		'unitCode.UC': __('unitCode.UC'),
		'unitCode.VA': __('unitCode.VA'),
		'unitCode.VLT': __('unitCode.VLT'),
		'unitCode.VP': __('unitCode.VP'),
		'unitCode.W2': __('unitCode.W2'),
		'unitCode.WA': __('unitCode.WA'),
		'unitCode.WB': __('unitCode.WB'),
		'unitCode.WCD': __('unitCode.WCD'),
		'unitCode.WE': __('unitCode.WE'),
		'unitCode.WEB': __('unitCode.WEB'),
		'unitCode.WEE': __('unitCode.WEE'),
		'unitCode.WG': __('unitCode.WG'),
		'unitCode.WHR': __('unitCode.WHR'),
		'unitCode.WM': __('unitCode.WM'),
		'unitCode.WSD': __('unitCode.WSD'),
		'unitCode.WTT': __('unitCode.WTT'),
		'unitCode.X1': __('unitCode.X1'),
		'unitCode.YDK': __('unitCode.YDK'),
		'unitCode.YDQ': __('unitCode.YDQ'),
		'unitCode.YRD': __('unitCode.YRD'),
		'unitCode.Z11': __('unitCode.Z11'),
		'unitCode.ZP': __('unitCode.ZP'),
		'unitCode.ZZ': __('unitCode.ZZ'),
		'unitCode.X1A': __('unitCode.X1A'),
		'unitCode.X1B': __('unitCode.X1B'),
		'unitCode.X1D': __('unitCode.X1D'),
		'unitCode.X1F': __('unitCode.X1F'),
		'unitCode.X1G': __('unitCode.X1G'),
		'unitCode.X1W': __('unitCode.X1W'),
		'unitCode.X2C': __('unitCode.X2C'),
		'unitCode.X3A': __('unitCode.X3A'),
		'unitCode.X3H': __('unitCode.X3H'),
		'unitCode.X43': __('unitCode.X43'),
		'unitCode.X44': __('unitCode.X44'),
		'unitCode.X4A': __('unitCode.X4A'),
		'unitCode.X4B': __('unitCode.X4B'),
		'unitCode.X4C': __('unitCode.X4C'),
		'unitCode.X4D': __('unitCode.X4D'),
		'unitCode.X4F': __('unitCode.X4F'),
		'unitCode.X4G': __('unitCode.X4G'),
		'unitCode.X4H': __('unitCode.X4H'),
		'unitCode.X5H': __('unitCode.X5H'),
		'unitCode.X5L': __('unitCode.X5L'),
		'unitCode.X5M': __('unitCode.X5M'),
		'unitCode.X6H': __('unitCode.X6H'),
		'unitCode.X6P': __('unitCode.X6P'),
		'unitCode.X7A': __('unitCode.X7A'),
		'unitCode.X7B': __('unitCode.X7B'),
		'unitCode.X8A': __('unitCode.X8A'),
		'unitCode.X8B': __('unitCode.X8B'),
		'unitCode.X8C': __('unitCode.X8C'),
		'unitCode.XAA': __('unitCode.XAA'),
		'unitCode.XAB': __('unitCode.XAB'),
		'unitCode.XAC': __('unitCode.XAC'),
		'unitCode.XAD': __('unitCode.XAD'),
		'unitCode.XAE': __('unitCode.XAE'),
		'unitCode.XAF': __('unitCode.XAF'),
		'unitCode.XAG': __('unitCode.XAG'),
		'unitCode.XAH': __('unitCode.XAH'),
		'unitCode.XAI': __('unitCode.XAI'),
		'unitCode.XAJ': __('unitCode.XAJ'),
		'unitCode.XAL': __('unitCode.XAL'),
		'unitCode.XAM': __('unitCode.XAM'),
		'unitCode.XAP': __('unitCode.XAP'),
		'unitCode.XAT': __('unitCode.XAT'),
		'unitCode.XAV': __('unitCode.XAV'),
		'unitCode.XB4': __('unitCode.XB4'),
		'unitCode.XBA': __('unitCode.XBA'),
		'unitCode.XBB': __('unitCode.XBB'),
		'unitCode.XBC': __('unitCode.XBC'),
		'unitCode.XBD': __('unitCode.XBD'),
		'unitCode.XBE': __('unitCode.XBE'),
		'unitCode.XBF': __('unitCode.XBF'),
		'unitCode.XBG': __('unitCode.XBG'),
		'unitCode.XBH': __('unitCode.XBH'),
		'unitCode.XBI': __('unitCode.XBI'),
		'unitCode.XBJ': __('unitCode.XBJ'),
		'unitCode.XBK': __('unitCode.XBK'),
		'unitCode.XBL': __('unitCode.XBL'),
		'unitCode.XBM': __('unitCode.XBM'),
		'unitCode.XBN': __('unitCode.XBN'),
		'unitCode.XBO': __('unitCode.XBO'),
		'unitCode.XBP': __('unitCode.XBP'),
		'unitCode.XBQ': __('unitCode.XBQ'),
		'unitCode.XBR': __('unitCode.XBR'),
		'unitCode.XBS': __('unitCode.XBS'),
		'unitCode.XBT': __('unitCode.XBT'),
		'unitCode.XBU': __('unitCode.XBU'),
		'unitCode.XBV': __('unitCode.XBV'),
		'unitCode.XBW': __('unitCode.XBW'),
		'unitCode.XBX': __('unitCode.XBX'),
		'unitCode.XBY': __('unitCode.XBY'),
		'unitCode.XBZ': __('unitCode.XBZ'),
		'unitCode.XCA': __('unitCode.XCA'),
		'unitCode.XCB': __('unitCode.XCB'),
		'unitCode.XCC': __('unitCode.XCC'),
		'unitCode.XCD': __('unitCode.XCD'),
		'unitCode.XCE': __('unitCode.XCE'),
		'unitCode.XCF': __('unitCode.XCF'),
		'unitCode.XCG': __('unitCode.XCG'),
		'unitCode.XCH': __('unitCode.XCH'),
		'unitCode.XCI': __('unitCode.XCI'),
		'unitCode.XCJ': __('unitCode.XCJ'),
		'unitCode.XCK': __('unitCode.XCK'),
		'unitCode.XCL': __('unitCode.XCL'),
		'unitCode.XCM': __('unitCode.XCM'),
		'unitCode.XCN': __('unitCode.XCN'),
		'unitCode.XCO': __('unitCode.XCO'),
		'unitCode.XCP': __('unitCode.XCP'),
		'unitCode.XCQ': __('unitCode.XCQ'),
		'unitCode.XCR': __('unitCode.XCR'),
		'unitCode.XCS': __('unitCode.XCS'),
		'unitCode.XCT': __('unitCode.XCT'),
		'unitCode.XCU': __('unitCode.XCU'),
		'unitCode.XCV': __('unitCode.XCV'),
		'unitCode.XCW': __('unitCode.XCW'),
		'unitCode.XCX': __('unitCode.XCX'),
		'unitCode.XCY': __('unitCode.XCY'),
		'unitCode.XCZ': __('unitCode.XCZ'),
		'unitCode.XDA': __('unitCode.XDA'),
		'unitCode.XDB': __('unitCode.XDB'),
		'unitCode.XDC': __('unitCode.XDC'),
		'unitCode.XDG': __('unitCode.XDG'),
		'unitCode.XDH': __('unitCode.XDH'),
		'unitCode.XDI': __('unitCode.XDI'),
		'unitCode.XDJ': __('unitCode.XDJ'),
		'unitCode.XDK': __('unitCode.XDK'),
		'unitCode.XDL': __('unitCode.XDL'),
		'unitCode.XDM': __('unitCode.XDM'),
		'unitCode.XDN': __('unitCode.XDN'),
		'unitCode.XDP': __('unitCode.XDP'),
		'unitCode.XDR': __('unitCode.XDR'),
		'unitCode.XDS': __('unitCode.XDS'),
		'unitCode.XDT': __('unitCode.XDT'),
		'unitCode.XDU': __('unitCode.XDU'),
		'unitCode.XDV': __('unitCode.XDV'),
		'unitCode.XDW': __('unitCode.XDW'),
		'unitCode.XDX': __('unitCode.XDX'),
		'unitCode.XDY': __('unitCode.XDY'),
		'unitCode.XEC': __('unitCode.XEC'),
		'unitCode.XED': __('unitCode.XED'),
		'unitCode.XEE': __('unitCode.XEE'),
		'unitCode.XEF': __('unitCode.XEF'),
		'unitCode.XEG': __('unitCode.XEG'),
		'unitCode.XEH': __('unitCode.XEH'),
		'unitCode.XEI': __('unitCode.XEI'),
		'unitCode.XEN': __('unitCode.XEN'),
		'unitCode.XFB': __('unitCode.XFB'),
		'unitCode.XFC': __('unitCode.XFC'),
		'unitCode.XFD': __('unitCode.XFD'),
		'unitCode.XFE': __('unitCode.XFE'),
		'unitCode.XFI': __('unitCode.XFI'),
		'unitCode.XFL': __('unitCode.XFL'),
		'unitCode.XFO': __('unitCode.XFO'),
		'unitCode.XFP': __('unitCode.XFP'),
		'unitCode.XFR': __('unitCode.XFR'),
		'unitCode.XFT': __('unitCode.XFT'),
		'unitCode.XFW': __('unitCode.XFW'),
		'unitCode.XFX': __('unitCode.XFX'),
		'unitCode.XGB': __('unitCode.XGB'),
		'unitCode.XGI': __('unitCode.XGI'),
		'unitCode.XGL': __('unitCode.XGL'),
		'unitCode.XGR': __('unitCode.XGR'),
		'unitCode.XGU': __('unitCode.XGU'),
		'unitCode.XGY': __('unitCode.XGY'),
		'unitCode.XGZ': __('unitCode.XGZ'),
		'unitCode.XHA': __('unitCode.XHA'),
		'unitCode.XHB': __('unitCode.XHB'),
		'unitCode.XHC': __('unitCode.XHC'),
		'unitCode.XHG': __('unitCode.XHG'),
		'unitCode.XHN': __('unitCode.XHN'),
		'unitCode.XHR': __('unitCode.XHR'),
		'unitCode.XIA': __('unitCode.XIA'),
		'unitCode.XIB': __('unitCode.XIB'),
		'unitCode.XIC': __('unitCode.XIC'),
		'unitCode.XID': __('unitCode.XID'),
		'unitCode.XIE': __('unitCode.XIE'),
		'unitCode.XIF': __('unitCode.XIF'),
		'unitCode.XIG': __('unitCode.XIG'),
		'unitCode.XIH': __('unitCode.XIH'),
		'unitCode.XIK': __('unitCode.XIK'),
		'unitCode.XIL': __('unitCode.XIL'),
		'unitCode.XIN': __('unitCode.XIN'),
		'unitCode.XIZ': __('unitCode.XIZ'),
		'unitCode.XJB': __('unitCode.XJB'),
		'unitCode.XJC': __('unitCode.XJC'),
		'unitCode.XJG': __('unitCode.XJG'),
		'unitCode.XJR': __('unitCode.XJR'),
		'unitCode.XJT': __('unitCode.XJT'),
		'unitCode.XJY': __('unitCode.XJY'),
		'unitCode.XKG': __('unitCode.XKG'),
		'unitCode.XKI': __('unitCode.XKI'),
		'unitCode.XLE': __('unitCode.XLE'),
		'unitCode.XLG': __('unitCode.XLG'),
		'unitCode.XLT': __('unitCode.XLT'),
		'unitCode.XLU': __('unitCode.XLU'),
		'unitCode.XLV': __('unitCode.XLV'),
		'unitCode.XLZ': __('unitCode.XLZ'),
		'unitCode.XMA': __('unitCode.XMA'),
		'unitCode.XMB': __('unitCode.XMB'),
		'unitCode.XMC': __('unitCode.XMC'),
		'unitCode.XME': __('unitCode.XME'),
		'unitCode.XMR': __('unitCode.XMR'),
		'unitCode.XMS': __('unitCode.XMS'),
		'unitCode.XMT': __('unitCode.XMT'),
		'unitCode.XMW': __('unitCode.XMW'),
		'unitCode.XMX': __('unitCode.XMX'),
		'unitCode.XNA': __('unitCode.XNA'),
		'unitCode.XNE': __('unitCode.XNE'),
		'unitCode.XNF': __('unitCode.XNF'),
		'unitCode.XNG': __('unitCode.XNG'),
		'unitCode.XNS': __('unitCode.XNS'),
		'unitCode.XNT': __('unitCode.XNT'),
		'unitCode.XNU': __('unitCode.XNU'),
		'unitCode.XNV': __('unitCode.XNV'),
		'unitCode.XOA': __('unitCode.XOA'),
		'unitCode.XOB': __('unitCode.XOB'),
		'unitCode.XOC': __('unitCode.XOC'),
		'unitCode.XOD': __('unitCode.XOD'),
		'unitCode.XOE': __('unitCode.XOE'),
		'unitCode.XOF': __('unitCode.XOF'),
		'unitCode.XOK': __('unitCode.XOK'),
		'unitCode.XOT': __('unitCode.XOT'),
		'unitCode.XOU': __('unitCode.XOU'),
		'unitCode.XP2': __('unitCode.XP2'),
		'unitCode.XPA': __('unitCode.XPA'),
		'unitCode.XPB': __('unitCode.XPB'),
		'unitCode.XPC': __('unitCode.XPC'),
		'unitCode.XPD': __('unitCode.XPD'),
		'unitCode.XPE': __('unitCode.XPE'),
		'unitCode.XPF': __('unitCode.XPF'),
		'unitCode.XPG': __('unitCode.XPG'),
		'unitCode.XPH': __('unitCode.XPH'),
		'unitCode.XPI': __('unitCode.XPI'),
		'unitCode.XPJ': __('unitCode.XPJ'),
		'unitCode.XPK': __('unitCode.XPK'),
		'unitCode.XPL': __('unitCode.XPL'),
		'unitCode.XPN': __('unitCode.XPN'),
		'unitCode.XPO': __('unitCode.XPO'),
		'unitCode.XPP': __('unitCode.XPP'),
		'unitCode.XPR': __('unitCode.XPR'),
		'unitCode.XPT': __('unitCode.XPT'),
		'unitCode.XPU': __('unitCode.XPU'),
		'unitCode.XPV': __('unitCode.XPV'),
		'unitCode.XPX': __('unitCode.XPX'),
		'unitCode.XPY': __('unitCode.XPY'),
		'unitCode.XPZ': __('unitCode.XPZ'),
		'unitCode.XQA': __('unitCode.XQA'),
		'unitCode.XQB': __('unitCode.XQB'),
		'unitCode.XQC': __('unitCode.XQC'),
		'unitCode.XQD': __('unitCode.XQD'),
		'unitCode.XQF': __('unitCode.XQF'),
		'unitCode.XQG': __('unitCode.XQG'),
		'unitCode.XQH': __('unitCode.XQH'),
		'unitCode.XQJ': __('unitCode.XQJ'),
		'unitCode.XQK': __('unitCode.XQK'),
		'unitCode.XQL': __('unitCode.XQL'),
		'unitCode.XQM': __('unitCode.XQM'),
		'unitCode.XQN': __('unitCode.XQN'),
		'unitCode.XQP': __('unitCode.XQP'),
		'unitCode.XQQ': __('unitCode.XQQ'),
		'unitCode.XQR': __('unitCode.XQR'),
		'unitCode.XQS': __('unitCode.XQS'),
		'unitCode.XRD': __('unitCode.XRD'),
		'unitCode.XRG': __('unitCode.XRG'),
		'unitCode.XRJ': __('unitCode.XRJ'),
		'unitCode.XRK': __('unitCode.XRK'),
		'unitCode.XRL': __('unitCode.XRL'),
		'unitCode.XRO': __('unitCode.XRO'),
		'unitCode.XRT': __('unitCode.XRT'),
		'unitCode.XRZ': __('unitCode.XRZ'),
		'unitCode.XSA': __('unitCode.XSA'),
		'unitCode.XSB': __('unitCode.XSB'),
		'unitCode.XSC': __('unitCode.XSC'),
		'unitCode.XSD': __('unitCode.XSD'),
		'unitCode.XSE': __('unitCode.XSE'),
		'unitCode.XSH': __('unitCode.XSH'),
		'unitCode.XSI': __('unitCode.XSI'),
		'unitCode.XSK': __('unitCode.XSK'),
		'unitCode.XSL': __('unitCode.XSL'),
		'unitCode.XSM': __('unitCode.XSM'),
		'unitCode.XSO': __('unitCode.XSO'),
		'unitCode.XSP': __('unitCode.XSP'),
		'unitCode.XSS': __('unitCode.XSS'),
		'unitCode.XST': __('unitCode.XST'),
		'unitCode.XSU': __('unitCode.XSU'),
		'unitCode.XSV': __('unitCode.XSV'),
		'unitCode.XSW': __('unitCode.XSW'),
		'unitCode.XSY': __('unitCode.XSY'),
		'unitCode.XSZ': __('unitCode.XSZ'),
		'unitCode.XT1': __('unitCode.XT1'),
		'unitCode.XTB': __('unitCode.XTB'),
		'unitCode.XTC': __('unitCode.XTC'),
		'unitCode.XTD': __('unitCode.XTD'),
		'unitCode.XTE': __('unitCode.XTE'),
		'unitCode.XTG': __('unitCode.XTG'),
		'unitCode.XTI': __('unitCode.XTI'),
		'unitCode.XTK': __('unitCode.XTK'),
		'unitCode.XTL': __('unitCode.XTL'),
		'unitCode.XTN': __('unitCode.XTN'),
		'unitCode.XTO': __('unitCode.XTO'),
		'unitCode.XTR': __('unitCode.XTR'),
		'unitCode.XTS': __('unitCode.XTS'),
		'unitCode.XTT': __('unitCode.XTT'),
		'unitCode.XTU': __('unitCode.XTU'),
		'unitCode.XTV': __('unitCode.XTV'),
		'unitCode.XTW': __('unitCode.XTW'),
		'unitCode.XTY': __('unitCode.XTY'),
		'unitCode.XTZ': __('unitCode.XTZ'),
		'unitCode.XUC': __('unitCode.XUC'),
		'unitCode.XUN': __('unitCode.XUN'),
		'unitCode.XVA': __('unitCode.XVA'),
		'unitCode.XVG': __('unitCode.XVG'),
		'unitCode.XVI': __('unitCode.XVI'),
		'unitCode.XVK': __('unitCode.XVK'),
		'unitCode.XVL': __('unitCode.XVL'),
		'unitCode.XVO': __('unitCode.XVO'),
		'unitCode.XVP': __('unitCode.XVP'),
		'unitCode.XVQ': __('unitCode.XVQ'),
		'unitCode.XVN': __('unitCode.XVN'),
		'unitCode.XVR': __('unitCode.XVR'),
		'unitCode.XVS': __('unitCode.XVS'),
		'unitCode.XVY': __('unitCode.XVY'),
		'unitCode.XWA': __('unitCode.XWA'),
		'unitCode.XWB': __('unitCode.XWB'),
		'unitCode.XWC': __('unitCode.XWC'),
		'unitCode.XWD': __('unitCode.XWD'),
		'unitCode.XWF': __('unitCode.XWF'),
		'unitCode.XWG': __('unitCode.XWG'),
		'unitCode.XWH': __('unitCode.XWH'),
		'unitCode.XWJ': __('unitCode.XWJ'),
		'unitCode.XWK': __('unitCode.XWK'),
		'unitCode.XWL': __('unitCode.XWL'),
		'unitCode.XWM': __('unitCode.XWM'),
		'unitCode.XWN': __('unitCode.XWN'),
		'unitCode.XWP': __('unitCode.XWP'),
		'unitCode.XWQ': __('unitCode.XWQ'),
		'unitCode.XWR': __('unitCode.XWR'),
		'unitCode.XWS': __('unitCode.XWS'),
		'unitCode.XWT': __('unitCode.XWT'),
		'unitCode.XWU': __('unitCode.XWU'),
		'unitCode.XWV': __('unitCode.XWV'),
		'unitCode.XWW': __('unitCode.XWW'),
		'unitCode.XWX': __('unitCode.XWX'),
		'unitCode.XWY': __('unitCode.XWY'),
		'unitCode.XWZ': __('unitCode.XWZ'),
		'unitCode.XXA': __('unitCode.XXA'),
		'unitCode.XXB': __('unitCode.XXB'),
		'unitCode.XXC': __('unitCode.XXC'),
		'unitCode.XXD': __('unitCode.XXD'),
		'unitCode.XXF': __('unitCode.XXF'),
		'unitCode.XXG': __('unitCode.XXG'),
		'unitCode.XXH': __('unitCode.XXH'),
		'unitCode.XXJ': __('unitCode.XXJ'),
		'unitCode.XXK': __('unitCode.XXK'),
		'unitCode.XYA': __('unitCode.XYA'),
		'unitCode.XYB': __('unitCode.XYB'),
		'unitCode.XYC': __('unitCode.XYC'),
		'unitCode.XYD': __('unitCode.XYD'),
		'unitCode.XYF': __('unitCode.XYF'),
		'unitCode.XYG': __('unitCode.XYG'),
		'unitCode.XYH': __('unitCode.XYH'),
		'unitCode.XYJ': __('unitCode.XYJ'),
		'unitCode.XYK': __('unitCode.XYK'),
		'unitCode.XYL': __('unitCode.XYL'),
		'unitCode.XYM': __('unitCode.XYM'),
		'unitCode.XYN': __('unitCode.XYN'),
		'unitCode.XYP': __('unitCode.XYP'),
		'unitCode.XYQ': __('unitCode.XYQ'),
		'unitCode.XYR': __('unitCode.XYR'),
		'unitCode.XYS': __('unitCode.XYS'),
		'unitCode.XYT': __('unitCode.XYT'),
		'unitCode.XYV': __('unitCode.XYV'),
		'unitCode.XYW': __('unitCode.XYW'),
		'unitCode.XYX': __('unitCode.XYX'),
		'unitCode.XYY': __('unitCode.XYY'),
		'unitCode.XYZ': __('unitCode.XYZ'),
		'unitCode.XZA': __('unitCode.XZA'),
		'unitCode.XZB': __('unitCode.XZB'),
		'unitCode.XZC': __('unitCode.XZC'),
		'unitCode.XZD': __('unitCode.XZD'),
		'unitCode.XZF': __('unitCode.XZF'),
		'unitCode.XZG': __('unitCode.XZG'),
		'unitCode.XZH': __('unitCode.XZH'),
		'unitCode.XZJ': __('unitCode.XZJ'),
		'unitCode.XZK': __('unitCode.XZK'),
		'unitCode.XZL': __('unitCode.XZL'),
		'unitCode.XZM': __('unitCode.XZM'),
		'unitCode.XZN': __('unitCode.XZN'),
		'unitCode.XZP': __('unitCode.XZP'),
		'unitCode.XZQ': __('unitCode.XZQ'),
		'unitCode.XZR': __('unitCode.XZR'),
		'unitCode.XZS': __('unitCode.XZS'),
		'unitCode.XZT': __('unitCode.XZT'),
		'unitCode.XZU': __('unitCode.XZU'),
		'unitCode.XZV': __('unitCode.XZV'),
		'unitCode.XZW': __('unitCode.XZW'),
		'unitCode.XZX': __('unitCode.XZX'),
		'unitCode.XZY': __('unitCode.XZY'),
		'unitCode.XZZ': __('unitCode.XZZ'),
};
