import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { NAVIGATION, NavigationModel } from './navigation.model';
import { GlobalRoutesService } from './services/global-routes.service';
import { NavigationAndRoutingService } from './services/navigation-and-routing.service';

@NgModule({
	imports: [
		CommonModule,
	],
	providers: [
		NavigationAndRoutingService,
		GlobalRoutesService,
	],
})
export class NavigationModule {
	public static forRoot(model?: NavigationModel): ModuleWithProviders {
		return {
			ngModule: NavigationModule,
			providers: [
				{
					provide: NAVIGATION,
					useValue: model,
					multi: true,
				},
			],
		};
	}

	public static forChild(model: NavigationModel): ModuleWithProviders {
		return {
			ngModule: NavigationModule,
			providers: [
				{
					provide: NAVIGATION,
					useValue: model,
					multi: true,
				},
			],
		};
	}
}
