import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MAIN_NAVIGATION_KEY, NavigationItem } from '../navigation/navigation.model';
import { NavigationAndRoutingService } from '../navigation/services/navigation-and-routing.service';
import { TranslateService } from '../translate/translate.service';
import { ObjectHelper } from '../utils/helpers/object.helper';
import { MenuModel } from './menu.model';

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	constructor(
		protected navigationAndRoutingService: NavigationAndRoutingService,
		protected translateService: TranslateService,
		protected permissionsService: NgxPermissionsService,
		protected router: Router
	) {}

	public getCurrentMenu(fromModal: boolean = false): Observable<MenuModel> {
		if (
			fromModal &&
			!this.navigationAndRoutingService.isModalRouteEnabled
		) {
			return of(null);
		}

		const currentRouteData = fromModal
			? this.navigationAndRoutingService.modalRouteData
			: this.navigationAndRoutingService.routeData;
		const navigationKey = currentRouteData
			? currentRouteData.navigationKey
			: null;

		return this.getMenu(
			navigationKey || MAIN_NAVIGATION_KEY,
			fromModal
		).pipe(
			map(x => {
				x.isModal = this.navigationAndRoutingService.isModalRouteEnabled;
				return x;
			})
		);
	}

	public getCurrentMenuItem(
		fromModal: boolean = false
	): Observable<NavigationItem> {
		return this.getCurrentMenu(fromModal).pipe(
			map(menu => {
				if (!menu) {
					return null;
				}

				return ObjectHelper.findInTreeChildrenFirst(
					menu.items,
					x => this.router.url.includes(x.url),
					x => x.children
				);
			})
		);
	}

	public getMenu(
		key: string,
		fromModal: boolean = false
	): Observable<MenuModel> {
		return this.initMenuItems(
			this.navigationAndRoutingService.getNavigationItemsByKey(key)
		).pipe(
			map(navigationItems => {
				navigationItems.forEach(item =>
					this.applyRouteParamsToNavigationItem(
						item,
						fromModal
							? this.navigationAndRoutingService.modalRouteParams
							: this.navigationAndRoutingService.routeParams
					)
				);

				return {
					items: navigationItems,
				};
			})
		);
	}

	protected initMenuItems(
		navigationItems: NavigationItem[]
	): Observable<NavigationItem[]> {
		this.translateMenuItems(navigationItems);

		return this.checkPermissions(navigationItems).pipe(
			flatMap(navigationItems => {
				return this.initChildrenItems(navigationItems);
			})
		);
	}

	protected initChildrenItems(
		navigationItems: NavigationItem[]
	): Observable<NavigationItem[]> {
		const observables = navigationItems
			.filter(item => !!item.children && item.children.length)
			.map(item =>
				this.initMenuItems(item.children).pipe(
					map(results => {
						item.children = results;
					})
				)
			);

		if (!observables.length) {
			return of(navigationItems);
		}

		return zip(...observables).pipe(map(() => navigationItems));
	}

	protected translateMenuItems(navigationItems: NavigationItem[]) {
		navigationItems
			.forEach(item => item.label = this.translateService.getIfResource(item.label));
	}

	protected checkPermissions(
		navigationItems: NavigationItem[]
	): Observable<NavigationItem[]> {
		const observables = navigationItems
			.filter(item => !!item.permissions && item.permissions.length)
			.map(item =>
				from(
					this.permissionsService.hasPermission(item.permissions)
				).pipe(
					map(hasPermission => {
						return {
							navigationItem: item,
							hasPermission: hasPermission,
						};
					})
				)
			);

		if (observables.length === 0) {
			return of(navigationItems);
		}

		return zip(...observables).pipe(
			map(results =>
				navigationItems.filter(navigationItem => {
					const result = results.find(
						x => x.navigationItem === navigationItem
					);
					return !result || result.hasPermission;
				})
			)
		);
	}

	protected applyRouteParamsToNavigationItem(
		item: NavigationItem,
		paramMap: ParamMap
	): void {
		if (!paramMap) {
			return;
		}

		paramMap.keys.forEach(key => {
			item.url = item.url.replace(`:${key}`, paramMap.get(key));
		});
	}
}
