import { ModuleWithProviders, NgModule } from '@angular/core';
import { KeycloakAngularModule } from 'keycloak-angular';

export function storageFactory() {
	if (typeof window.localStorage !== 'undefined') {
		return window.localStorage;
	} else if (typeof window.sessionStorage !== 'undefined') {
		return window.sessionStorage;
	} else {
		throw Error(
			'KeycloakAuthModule: storageFactory error - localStorage and sessionStorage not available!'
		);
	}
}

@NgModule({
	imports: [KeycloakAngularModule],
})
export class KeycloakAuthModule {
	static forRoot(): ModuleWithProviders<KeycloakAuthModule> {
		return {
			ngModule: KeycloakAuthModule,
			providers: [
				//define this in providers.module
				// {
				// 	provide: AuthService,
				// 	useClass: OAuthAuthService,
				// },
			],
		};
	}
}
