import { Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { GlobalRoutesService } from 'shared/app/core/navigation/services/global-routes.service';
import { NavigationAndRoutingService } from 'shared/app/core/navigation/services/navigation-and-routing.service';
// import { StorageService } from 'shared/app/core/persistance/services/storage.service';
// import { TranslateService } from 'shared/app/core/translate/translate.service';
import { MessageRouteType } from '../../models/message-route-type.model';

@UntilDestroy()
@Component({
	selector: 'ca-message-route',
	templateUrl: 'message-route.component.html',
})
export class MessageRouteComponent implements OnInit, OnDestroy {
	public messageType: MessageRouteType;
	public MessageRouteType = MessageRouteType;

	constructor(
		//protected storageService: StorageService,
		protected navigationAndRoutingService: NavigationAndRoutingService,
		//protected translateService: TranslateService,
		protected globalRoutesService: GlobalRoutesService,
	) {

	}

	public ngOnDestroy() {

	}

	public ngOnInit() {
		this.refreshRouteData();

		this.navigationAndRoutingService.onRouteDataChanged
			.pipe(
				untilDestroyed(this),
				debounceTime(500)
			)
			.subscribe(() => this.refreshRouteData());
	}

	public onGoToLogin() {
		this.globalRoutesService.goToLogin(null, false);
	}

	protected refreshRouteData() {
		this.messageType = <MessageRouteType> this.navigationAndRoutingService.routeParams.get('messageType');
	}
}
