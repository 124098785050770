<ng-template #template
			 let-toast>
	{{setToastInstance(toast)}}

	<cds-toast [status]="item.status"
			   [statusIcon]="item.showStatusIcon"
			   (toastClose)="toast.resolve()">
		<header> {{translatedHeader}} </header>
		<section style="white-space: pre-line;"> {{translatedBody}} </section>
		<footer *ngIf="isFooterVisible"
				class="cds-toast-buttons">
			<button cdsButton
					*ngFor="let button of item.buttons"
					class="cds-button-text"
					(click)="onResolveValue(button)">
				{{button.translatedLabel}}
			</button>
		</footer>
	</cds-toast>
</ng-template>