import { Component, Input, OnInit } from '@angular/core';

export type AppBarType = 'danger' | 'default' | 'info' | 'warning';

export interface AppBarParams {
	cookieName?: string;
	onClose?: (event: MouseEvent) => void;
	type: AppBarType;
	text: string;
}

@Component({
	selector: 'ca-app-bar',
	templateUrl: 'app-bar.component.html',
})
export class AppBarComponent implements AppBarParams, OnInit {
	@Input()
	public cookieName?: string;
	@Input()
	public onClose?: (event: MouseEvent) => void = () => { };
	@Input()
	public text: string;
	@Input()
	public type: AppBarType;

	public typeClass?: string = '';

	ngOnInit() {
		this.typeClass = ((this?.type ?? 'default') === 'default') ? '' : `app-bar__${this.type}`;
	}
}
