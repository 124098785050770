import { Injectable, Injector } from '@angular/core';
import { PRIMARY_OUTLET, Router } from '@angular/router';
import { NavigationService } from 'framework/app/core/navigation/navigation.service';
import { MessageRouteType } from '../../layout/models/message-route-type.model';
//import { REDIRECT_URL_PARAM } from '../../login/models/login-from.model';
import { ObjectHelper } from '../../utils/helpers/object.helper';

@Injectable({
	providedIn: 'root',
})
export class GlobalRoutesService {
	public get baseHref(): string {
		return (<any> this.router).location._baseHref;
	}

	protected get currentRoute(): string {
		return window.location.pathname.split(`${this.baseHref}/`)[1];
	}

	protected get navigationService(): NavigationService {
		return this.injector.get(NavigationService);
	}

	constructor(protected injector: Injector, protected router: Router) {}

	public goToLogin(redirectUrl?: string, addRedirectUrl: boolean = true) {
		return this.goTo('login', redirectUrl, addRedirectUrl);
	}

	public goToForgottenPassword() {
		return this.navigationService.go('login/forgottenPassword');
	}

	public goToResetPassword(
		redirectUrl?: string,
		addRedirectUrl: boolean = true
	) {
		return this.goTo('login/resetPassword', redirectUrl, addRedirectUrl);
	}

	public goToRoute(url: string) {
		if (ObjectHelper.isEmpty(url)) {
			return this.goToHome();
		}

		return this.navigationService.go(url);
	}

	public goToHome() {
		return this.navigationService.go('home'); ///TODO: to be removed when issue is fixed https://fbu-git.krakow.comarch/frontend/angular-seed/issues/27
	}

	public goToMessage(messageType: MessageRouteType) {
		return this.navigationService.go(`message/${messageType.toString()}`);
	}

	private goTo(
		url: string,
		redirectUrl?: string,
		addRedirectUrl: boolean = true
	) {
		if (ObjectHelper.isEmpty(redirectUrl) && addRedirectUrl) {
			redirectUrl = this.currentRoute;
		}

		const queryParams = ObjectHelper.isEmpty(redirectUrl)
			? null
			: {
					//[REDIRECT_URL_PARAM]: redirectUrl,
			  };

		return this.navigationService.go(url, PRIMARY_OUTLET, undefined, {
			queryParams: queryParams,
		});
	}
}
