import { __ } from 'seed/lib/i18n';
import { BaseNotification } from './notification.model';

export class SuccessNotification extends BaseNotification {
	constructor(
		public _message: string = __('notification.success_message'),
		public title?: string
	) {
		super('success', _message, title || __('notification.success'));
	}
}
